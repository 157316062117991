// core deps
import { LinkTypesEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import _, { find, map } from 'lodash';

// selectors
import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatMiniUserShortHandByIdSelector } from '~/client-common/core/domain/mini-users/state/data';

// view model helpers
import {
    buildViewModel,
    allAttributeIdListsMapper,
    subdivisionAttrIdsMapper,
    allOfficerIdListsMapper,
    buildJoinValuesMapper,
} from '~/client-common/helpers/viewModelHelpers';

export default function createCadTicketsResultsSelector(baseSelector) {
    return createSelector(
        formatAttributeByIdSelector,
        formatSubdivisionAttrIdsSelector,
        formatMiniUserShortHandByIdSelector,
        baseSelector,
        (
            formatAttributeById,
            formatSubdivisionAttrIds,
            formatMiniUserShortHandById,
            currentResults
        ) =>
            map(
                currentResults,
                buildViewModel({
                    recursive: true,
                    mappers: [
                        allAttributeIdListsMapper,
                        subdivisionAttrIdsMapper,
                        allOfficerIdListsMapper,
                        buildJoinValuesMapper('primaryUnitCallSigns'),
                        buildJoinValuesMapper('involvedUnitCallSigns'),
                        // find the CAD Ticket Location
                        (obj) =>
                            _(obj)
                                .pick('involvedLocations')
                                .mapValues((locations) =>
                                    find(locations, ({ type }) => {
                                        return (
                                            type === LinkTypesEnum.LOCATION_OF_CAD_TICKET ||
                                            type === LinkTypesEnum.EVENT_LOCATION
                                        );
                                    })
                                )
                                .mapKeys(() => 'cadTicketLocation')
                                .value(),
                    ],
                    helpers: {
                        formatAttributeById,
                        formatSubdivisionAttrIds,
                        formatMiniUserShortHandById,
                    },
                })
            )
    );
}

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { iconTypes } from '../../../../legacy-redux/components/core/Icon';

export function getIconTypeForItemTypeAttrId(itemTypeAttrId: number) {
    switch (itemTypeAttrId) {
        case globalAttributes.itemType.bus:
        case globalAttributes.itemType.vehicle:
            return iconTypes.VEHICLE;
        case globalAttributes.itemType.firearm:
            return iconTypes.FIREARM;
        default:
            return iconTypes.ITEM;
    }
}

export const arcIconForItemTypeAttrId = (itemTypeAttrId: number) => {
    switch (itemTypeAttrId) {
        case globalAttributes.itemType.bus:
        case globalAttributes.itemType.vehicle:
            return 'Vehicle';
        case globalAttributes.itemType.firearm:
            return 'Firearm';
        default:
            return 'Property';
    }
};

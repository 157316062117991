import { chain } from 'lodash';

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const adminAttributeTypeCodeTypeLinksFormFieldViewModels = {
    adminAttributeTypeCodeTypeLinks: {
        type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
        key: 'adminAttributeTypeCodeTypeLinks',
        fields: buildFlatFormFieldViewModels(['codeTypeId', 'codeTypeSource', 'requiresMapping']),
    },
};

function convertFromFormModel(adminAttributeTypeCodeTypeLinks = {}, attributeType) {
    return chain(adminAttributeTypeCodeTypeLinks.adminAttributeTypeCodeTypeLinks)
        .map((link) => ({
            codeTypeId: link.codeTypeId,
            codeTypeSource: link.codeTypeSource,
            requiresMapping: link.requiresMapping,
            attributeType,
        }))
        .filter((link) => link.codeTypeId && link.codeTypeSource)
        .value();
}

function convertToFormModel(adminAttributeTypeCodeTypeLinks = {}) {
    return {
        adminAttributeTypeCodeTypeLinks,
    };
}

export default createFormModule({
    formName: formClientEnum.ATTRIBUTE_TYPE_CODE_TYPE_LINKS,
    fieldViewModels: adminAttributeTypeCodeTypeLinksFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import { compose } from 'recompose';
import { applicationSettingsSelector } from '../state/data';
import { renderOnlyIf } from '../../../../helpers/reactHelpers';

const createFeatureFlaggedComponent = (featureFlagsSelector) =>
    compose(
        connect(
            createStructuredSelector({
                featureFlags: featureFlagsSelector,
            })
        ),
        renderOnlyIf(({ flag, featureFlags, fallback }) => featureFlags[flag] || fallback)
    )(({ children, featureFlags, fallback, flag }) => (
        <>{featureFlags[flag] ? children : fallback}</>
    ));

export default createFeatureFlaggedComponent(applicationSettingsSelector);

import { AttributeTypeEnum } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { attributeOptionsByTypeForCurrentDepartmentSelector } from '../../../core/attributes/state/ui';
import { TaskStatusOption } from '../types';

export const useTaskStatusOptions = () => {
    const attributeOptionsByTypeForCurrentDepartment = useSelector(
        attributeOptionsByTypeForCurrentDepartmentSelector
    );
    const statusOptions: TaskStatusOption[] = attributeOptionsByTypeForCurrentDepartment({
        type: AttributeTypeEnum.TASK_STATUS.name,
        includeExpired: false,
        additionalIds: [],
    });

    return statusOptions;
};

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import {
    requiredString,
    requiredInt,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const taskDetailsFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'description',
        validators: {
            requiredError: requiredString,
        },
    },
    {
        key: 'assigneeRoleId',
        validators: {
            requiredError: requiredInt,
        },
    },
    {
        key: 'dueDateUtc',
    },
    {
        key: 'statusAttrId',
        validators: {
            requiredError: requiredInt,
        },
    },
]);

/* Since we only want to update the status date of a task when its status is
 * changed, we pass in the task (from the redux store) so we can compare the
 * new data in our form to the last version of the task.
 * @param  {Object} [taskDetailsFormModel]
 * @param  {Object} [existingTaskData]
 * @return {Object}
 */
function convertFromFormModel(taskDetailsFormModel = {}, existingTaskData = {}) {
    let resolvedFormData = taskDetailsFormModel;
    if (existingTaskData.statusAttrId !== taskDetailsFormModel.statusAttrId) {
        resolvedFormData = {
            ...taskDetailsFormModel,
            statusDateUtc: nowUtc(),
        };
    }
    return {
        ...existingTaskData,
        ...resolvedFormData,
    };
}

export default createFormModule({
    formName: formClientEnum.TASK_DETAILS,
    fieldViewModels: taskDetailsFormFieldViewModels,
    convertFromFormModel,
});

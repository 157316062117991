import PropTypes from 'prop-types';
import { NumberInput, NumberInputProps } from 'arc';
import React from 'react';

import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import { Field } from '~/client-common/core/fields/state/config';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../core/testIds';
import FormElement, { FormElementWidthOrLengthProps } from './FormElement';

const { connectRRFInput } = reactReduxFormHelpers;

type ValueType = string | number | undefined;

type BaseNumberInputProps = {
    value?: ValueType;
    label?: string;
    helpText?: string; // requires a label to also be present
    autoFocus?: boolean;
    isRequired?: boolean;
    fieldName?: Field;

    disabled?: boolean;
    maxLength?: number;
    placeholder?: string;
    id?: string;

    onClick?: () => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChange?: (value: ValueType) => void;
    onFocus?: (value: ValueType) => void;
    onBlur?: (value: ValueType) => void;

    loading?: boolean;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    className?: string;

    touched?: boolean;
    error?: boolean;
    textAlign?: 'left' | 'right' | 'center';
    size?: 'sm' | 'md';
    leftAddon?: NumberInputProps['leftAddon'];
    leftIcon?: NumberInputProps['leftIcon'];
    isClearable?: boolean;
    precision: NumberInputProps['precision'];
    step: NumberInputProps['step'];
    mode: 'NUMBER' | 'INTEGER';
};
type Props = BaseNumberInputProps & FormElementWidthOrLengthProps;

const contextTypes = {
    onInputChange: PropTypes.func,
    onInputFocus: PropTypes.func,
    onInputBlur: PropTypes.func,
};

function ArcNumberInput(
    props: Props,
    context: {
        onInputChange?: (value: ValueType) => void;
        onInputFocus?: (value: ValueType) => void;
        onInputBlur?: (value: ValueType) => void;
    }
) {
    const onChange = (stringValue: string) => {
        // call custom handler
        props.onChange?.(stringValue);

        // call context handler
        context.onInputChange?.(stringValue);
    };
    const onFocus = () => {
        props.onFocus?.(props.value); // call custom handler

        // call context handler
        context.onInputFocus?.(props.value);
    };
    const onBlur = () => {
        props.onBlur?.(props.value); // call custom handler

        // call context handler
        context.onInputBlur?.(props.value);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === keyCodeEnum.ENTER && props.onPressEnter) {
            props.onPressEnter(e);
        } else {
            props.onKeyDown?.(e);
        }
    };

    const error = props.touched && props.error;
    const { style, length, width, helpText, label, isRequired, fieldName, ...rest } = props;
    const formElementProps = { style, helpText, label, isRequired, fieldName };
    const formElementSizingProps = length ? { length } : { width };
    const modeProps =
        props.mode === 'INTEGER'
            ? { step: props.step ?? 1, precision: 0 }
            : { precision: props.precision ?? 2, step: props.step };

    return (
        <FormElement {...formElementProps} {...formElementSizingProps}>
            <NumberInput
                {...rest}
                {...modeProps}
                style={rest.inputStyle}
                isInvalid={error}
                value={props.value?.toString() ?? ''}
                onKeyDown={handleKeyPress}
                isDisabled={props.disabled}
                data-test-id={testIds.NUMBER_INPUT}
                isLoading={props.loading}
                onFocus={onFocus}
                onBlur={onBlur}
                // @ts-expect-error types for this component are broken. The change handler type intersection makes it impossible to
                // use the actual prop without a type error when passing a function which expects the value as string for the first
                // and as number for the second argument
                onChange={onChange}
            />
        </FormElement>
    );
}

ArcNumberInput.contextTypes = contextTypes;

// @ts-expect-error client-common to client RND-7529
export const RRFNumberInput = connectRRFInput(ArcNumberInput);

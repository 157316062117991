import { RefContextEnum } from '@mark43/rms-api';
import { compact, first, get, map } from 'lodash';
import Promise from 'bluebird';

import { createSelector } from 'reselect';
import { dateDifferenceInYears } from '~/client-common/core/dates/utils/dateRangeHelpers';
import sortCharges from '~/client-common/core/domain/charges/utils/sortCharges';
import {
    arrestForReportIdSelector,
    updateArrestBookingInfo,
} from '~/client-common/core/domain/arrests/state/data';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import {
    courtCasesByReportIdSelector,
    updateCourtCases,
} from '~/client-common/core/domain/court-cases/state/data';
import {
    getAttributesByIdsSelector,
    attributesWithParentAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { personProfileByIdSelector } from '~/client-common/core/domain/person-profiles/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { someAttributesHaveGlobalParentAttribute } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { SINGLE_CARD_KEY } from '../../utils/createCard';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { saveBoxFailure, closeBox } from '../../../../../legacy-redux/actions/boxActions';
import formsRegistry from '../../../../../core/formsRegistry';
import { refreshCourtCaseForm } from '../forms/courtCaseForm';

export const BOOKING_SIDE_PANEL_CONTEXT = { name: boxEnum.REPORT_BOOKING_SIDE_PANEL };

const formName = RefContextEnum.FORM_BOOKING_SIDE_PANEL.name;

export const defendantPersonProfileIsJuvenileForCurrentArrestByReportIdSelector = createSelector(
    arrestForReportIdSelector,
    personProfileByIdSelector,
    (arrestForReportId, personProfileById) => (reportId) => {
        if (!reportId) {
            return;
        }

        const defendantId = get(arrestForReportId(reportId), 'defendantId');
        if (!defendantId) {
            return;
        }

        return get(personProfileById(defendantId), 'isJuvenile');
    }
);

export const defendantPersonProfileAgeForCurrentArrestByReportIdSelector = createSelector(
    arrestForReportIdSelector,
    personProfileByIdSelector,
    (arrestForReportId, personProfileById) => (reportId) => {
        if (!reportId) {
            return;
        }

        const defendantId = get(arrestForReportId(reportId), 'defendantId');
        if (!defendantId) {
            return;
        }

        const dateOfBirth = get(personProfileById(defendantId), 'dateOfBirth');
        if (!dateOfBirth) {
            return;
        }

        return dateDifferenceInYears(dateOfBirth, new Date());
    }
);

export const defendantPersonProfileAgeMinForCurrentArrestByReportIdSelector = createSelector(
    arrestForReportIdSelector,
    personProfileByIdSelector,
    (arrestForReportId, personProfileById) => (reportId) => {
        if (!reportId) {
            return;
        }

        const defendantId = get(arrestForReportId(reportId), 'defendantId');
        if (!defendantId) {
            return;
        }

        return get(personProfileById(defendantId), 'ageMin');
    }
);

function sanitizeArrestData(state, form) {
    const getAttributesByIds = getAttributesByIdsSelector(state);
    const { arrest, charges } = form.getState().model;

    return {
        ...arrest,
        ...(!showLockupDetails(getAttributesByIds, charges)
            ? {
                  lockupDateUtc: null,
                  lockupLocation: null,
                  lockupNumber: null,
                  afisNumber: null,
              }
            : {}),
        ...(!get(arrest, 'juvenileTriedAsAdult')
            ? {
                  juvenileTriedAsAdultNotes: null,
              }
            : {}),
    };
}

function sanitizeChargesData(state, form) {
    const { charges } = form.getState().model;

    return map(charges, (charge) => ({
        ...charge,
        ...(!get(charge, 'dispositionAttrId')
            ? {
                  collateralBondAmountPaid: null,
                  collateralBondReceiptNum: null,
                  dispositionDateUtc: null,
              }
            : {}),
        ...(!get(charge, 'juvenileDispositionAttrId')
            ? {
                  juvenileDispositionDateUtc: null,
              }
            : {}),
    }));
}

function sanitizeCourtCasesData(state, form) {
    const { courtCases } = form.getState().model;

    const attributesWithParentAttributeId = attributesWithParentAttributeIdSelector(state);

    // For now, only Court Type is "Bail Hearing" & is unselectable/unchangable from the FE.
    // When adding a new court case, we will
    // always hardcode the court type to be "Bail Hearing"
    const bailHearingAttributeId = get(
        first(attributesWithParentAttributeId(globalAttributes.courtTypeGlobal.bailHearing)),
        'id'
    );

    const reportId = currentReportIdSelector(state);

    return map(courtCases, (courtCase) => ({
        ...courtCase,
        reportId,
        courtTypeAttrId: courtCase.courtTypeAttrId || bailHearingAttributeId,
    }));
}

export function showLockupDetails(getAttributesByIds, charges) {
    return someAttributesHaveGlobalParentAttribute(
        getAttributesByIds(compact(map(charges, 'dispositionAttrId'))),
        globalAttributes.arrestDispositionGlobal.lockup
    );
}

export function resetBookingSidePanel() {
    const form = formsRegistry.get(formName);
    if (form) {
        form.resetModel();
    }
}

export function fillBookingSidePanel() {
    return (dispatch, getState) =>
        formsRegistry.maybeDeferredOperation(formName, undefined, (form) => {
            const state = getState();
            const reportId = currentReportIdSelector(state);
            const arrest = arrestForReportIdSelector(state)(reportId);
            const charges = chargesWhereSelector(state)({ arrestId: arrest.id }) || [];
            const sortedCharges = sortCharges({ charges });
            const courtCases = courtCasesByReportIdSelector(state)(reportId);
            const personProfileIsJuvenile = defendantPersonProfileIsJuvenileForCurrentArrestByReportIdSelector(
                state
            )(reportId);
            const personProfileAge = defendantPersonProfileAgeForCurrentArrestByReportIdSelector(
                state
            )(reportId);
            const personProfileAgeMin = defendantPersonProfileAgeMinForCurrentArrestByReportIdSelector(
                state
            )(reportId);

            form.transaction(() => {
                form.set('charges', sortedCharges);
                form.set('courtCases', courtCases);
                form.set('arrest', arrest);
                form.set('defendantProfile.personProfileIsJuvenile', personProfileIsJuvenile);
                form.set('defendantProfile.personProfileAge', personProfileAge);
                form.set('defendantProfile.personProfileAgeMin', personProfileAgeMin);
            });
        });
}

export function closeBookingSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(BOOKING_SIDE_PANEL_CONTEXT));
        resetBookingSidePanel();
    };
}

export function handleSubmit() {
    return (dispatch, getState) => {
        const state = getState();
        const reportId = currentReportIdSelector(state);
        const form = formsRegistry.get(formName);

        return form
            .submit()
            .then((result) => {
                const state = getState();
                const { form: submittedForm } = result;

                submittedForm.set('arrest', sanitizeArrestData(state, submittedForm));
                submittedForm.set('charges', sanitizeChargesData(state, submittedForm));
                submittedForm.set('courtCases', sanitizeCourtCasesData(state, submittedForm));

                const { model } = submittedForm.getState();
                const { courtCases, charges, arrest } = model || {};

                return Promise.all([
                    dispatch(updateCourtCases(reportId, courtCases)).then(() => {
                        // when the booking side panel is available in the arrest report, only the 'SINGLE' version of
                        // the court case card may be rendered, i.e. there is no scenario where multiple court case
                        // cards would be rendered
                        dispatch(refreshCourtCaseForm({ reportId, index: SINGLE_CARD_KEY }));
                    }),
                    dispatch(updateArrestBookingInfo({ arrest, charges })),
                ]);
            })
            .then(() => {
                dispatch(closeBookingSidePanel());
            })
            .catch((e) => {
                // Catch errors from different thenables,
                // and handle them appropriately
                const errorMessage = e.validationResult ? e.validationResult.formErrors : e.message;

                dispatch(saveBoxFailure(BOOKING_SIDE_PANEL_CONTEXT, errorMessage));
            });
    };
}

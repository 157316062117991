import _, { first, get, map } from 'lodash';
import { createSelector } from 'reselect';

import { RoleTypeEnum, RoleTypeEnumType } from '@mark43/rms-api';
import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import {
    departmentProfileByIdSelector,
    departmentProfilesSelector,
} from '~/client-common/core/domain/department-profiles/state/data';
import { roleByIdSelector } from '~/client-common/core/domain/roles/state/data';
import { userRolesWhereSelector } from '~/client-common/core/domain/user-roles/state/data';
import { userHasAbilitySelector } from '~/client-common/core/domain/abilities/state/data';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { userProfilesByIdSelector } from '~/client-common/core/domain/user-profiles/state/data';
import { consortiumDepartmentLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/data';
import { onlyNameCountriesSelector } from '~/client-common/core/domain/countries/state/ui';
import {
    currentUserUiSelector,
    CurrentUserState,
} from '~/client-common/core/domain/current-user/state/ui';

import authActionTypes from '../../../../../legacy-redux/actions/types/authActionTypes';
import {
    bootstrapFailure,
    bootstrapStart,
    bootstrapSuccess,
} from '../../../../../legacy-redux/actions/authActions';

// SELECTORS
export const currentUserIdSelector = createSelector(
    currentUserUiSelector,
    ({ currentUserId }) => currentUserId
);

export const currentUserProfileSelector = createSelector(
    currentUserIdSelector,
    userProfilesByIdSelector,
    (currentUserId, userProfileById) => {
        return userProfileById(currentUserId);
    }
);

export const currentUserDepartmentIdSelector = createSelector(
    currentUserUiSelector,
    ({ currentUserDepartmentId }) => currentUserDepartmentId
);

export const currentUserConsortiumIdSelector = createSelector(
    currentUserDepartmentIdSelector,
    consortiumDepartmentLinksByDepartmentIdSelector,
    (currentUserDepartmentId, consortiumDepartmentLinksByDepartmentId) =>
        currentUserDepartmentId &&
        get(
            first(get(consortiumDepartmentLinksByDepartmentId, currentUserDepartmentId)),
            'consortiumId'
        )
);

export const currentUserDepartmentAgencyIdSelector = createSelector(
    currentUserUiSelector,
    ({ currentUserDepartmentAgencyId }) => currentUserDepartmentAgencyId
);

export const userIsLoggedInSelector = createSelector(
    currentUserUiSelector,
    ({ userIsLoggedIn }) => userIsLoggedIn
);

export const userIsBootstrappingSelector = createSelector(
    currentUserUiSelector,
    ({ userIsBootstrapping }) => userIsBootstrapping
);

/**
 * Get the current user's department profile.
 */
export const currentUserDepartmentProfileSelector = createSelector(
    currentUserDepartmentIdSelector,
    departmentProfileByIdSelector,
    (currentUserDepartmentId, departmentProfileById) =>
        departmentProfileById(currentUserDepartmentId)
);

export const currentUserDepartmentStatusSelector = createSelector(
    currentUserDepartmentProfileSelector,
    (currentUserDepartmentProfile) =>
        currentUserDepartmentProfile && currentUserDepartmentProfile.department.departmentStatus
);

export const currentUserDepartmentSupportsNibrsSelector = createSelector(
    currentUserDepartmentProfileSelector,
    (currentUserDepartmentProfile) => checkIfDepartmentIsNibrs(currentUserDepartmentProfile)
);

const currentUserHasRoleOfTypeSelector = createSelector(
    currentUserIdSelector,
    userRolesWhereSelector,
    roleByIdSelector,
    (currentUserId, userRolesWhere, roleById) => (hasRoleType: RoleTypeEnumType) =>
        _(userRolesWhere({ userId: currentUserId }))
            .map(({ roleId }) => roleById(roleId))
            .filter()
            .some(({ roleType }) => roleType === hasRoleType)
);

export const currentUserIsSuperUserSelector = createSelector(
    currentUserHasRoleOfTypeSelector,
    (currentUserHasRoleOfType) => currentUserHasRoleOfType(RoleTypeEnum.SUPER_USER.name)
);

export const currentUserIsSupportUserSelector = createSelector(
    currentUserHasRoleOfTypeSelector,
    (currentUserHasRoleOfType) => currentUserHasRoleOfType(RoleTypeEnum.SUPPORT.name)
);

// does the current user have ability
// UNLIKE the applicationSettingsSelector this returns a function
// should be called like "currentUserHasAbilitySelector(enum.CAN_PRINT)"
export const currentUserHasAbilitySelector = createSelector(
    currentUserIdSelector,
    userHasAbilitySelector,
    (userId, userHasAbility) =>
        _.memoize((abilityId: number) => {
            return userHasAbility(userId, abilityId);
        })
);

// Select the current user's department id if they are in a consortium, otherwise returns undefined.
export const currentDepartmentIdForUserInConsortiumSelector = createSelector(
    consortiumDepartmentLinksAvailableSelector,
    currentUserDepartmentIdSelector,
    (isUserInConsortium, currentUserDepartmentId) =>
        isUserInConsortium ? currentUserDepartmentId : undefined
);

export const currentConsortiumCadDeptIdSelector = createSelector(
    currentUserDepartmentIdSelector,
    consortiumDepartmentLinksByDepartmentIdSelector,
    departmentProfilesSelector,
    // since all departments in the consortium will have the same cadDeptId we can grab any link (in this case, the user's department's link)
    // also, if the current department is not yet in a consortium, then we have to check the department profile becase consortium links will not exist
    (currentUserDepartmentId, consortiumDepartmentLinksByDepartmentId, departmentProfiles) =>
        currentUserDepartmentId &&
        (get(
            first(consortiumDepartmentLinksByDepartmentId[currentUserDepartmentId]),
            'cadDeptId'
        ) ||
            get(departmentProfiles[currentUserDepartmentId], 'department.cadDepartmentId'))
);

// Returns (in alphabetical order) all depts associated with the account, except for the current dept.
export const associatedDepartmentProfilesSelector = createSelector(
    currentUserDepartmentIdSelector,
    departmentProfilesSelector,
    (currentUserDepartmentId, departmentProfiles) =>
        _(departmentProfiles)
            .reject({ departmentId: currentUserDepartmentId })
            .thru((arr) => sortByNaturalOrder(arr, 'displayName'))
            .value()
);

// Returns the list of countries with country names as values and display props, with the
// department country sorted on top (if selected).
export const sortedNameOnlyCountriesSelector = createSelector(
    currentUserDepartmentProfileSelector,
    onlyNameCountriesSelector,
    (currentUserDepartmentProfile, onlyNameCountries) => {
        const departmentProfileCountryCode =
            currentUserDepartmentProfile?.locationBias?.countryCode;

        const deptCountryIndex = departmentProfileCountryCode
            ? map(onlyNameCountries, (country) => country.code).indexOf(
                  departmentProfileCountryCode
              )
            : -1;

        if (deptCountryIndex !== -1) {
            const [value] = onlyNameCountries.splice(deptCountryIndex, 1);
            onlyNameCountries.unshift(value);
        }

        return onlyNameCountries;
    }
);

type CurrentUserAction =
    | ReturnType<typeof bootstrapStart>
    | ReturnType<typeof bootstrapSuccess>
    | ReturnType<typeof bootstrapFailure>;

// REDUCER
export default function currentUserReducer(
    state: CurrentUserState = {
        userIsLoggedIn: false,
        userIsBootstrapping: false,
        currentUserId: undefined,
        currentUserDepartmentId: undefined,
    },
    action: CurrentUserAction
): CurrentUserState {
    switch (action.type) {
        case authActionTypes.BOOTSTRAP_START:
            return {
                ...state,
                userIsBootstrapping: true,
            };
        case authActionTypes.BOOTSTRAP_SUCCESS:
            const { userProfile } = action.payload.userData;
            return {
                ...state,
                userIsLoggedIn: true,
                userIsBootstrapping: false,
                currentUserId: userProfile.userId,
                currentUserDepartmentId: userProfile.departmentId,
                currentUserDepartmentAgencyId: userProfile.departmentAgencyId,
            };
        case authActionTypes.BOOTSTRAP_FAILURE:
            return {
                ...state,
                userIsBootstrapping: false,
            };
        default:
            return state;
    }
}

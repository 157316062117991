import { OtherInvolvedAgency } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'otherInvolvedAgencies';

const otherInvolvedAgenciesModule = createNormalizedModule<OtherInvolvedAgency>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

export const otherInvolvedAgenciesSelector = otherInvolvedAgenciesModule.selectors.entitiesSelector;

export default otherInvolvedAgenciesModule.reducerConfig;

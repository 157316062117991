import { EntityTypeEnum } from '@mark43/rms-api';
import { omit } from 'lodash';

/**
 * Convert entity type in the form model for owners and claimants.
 * @param {Object}  owner  owner or claimant
 * @return {Object}
 */
export function ownerIsOrgToEntityType(owner) {
    return {
        entityType: owner.isOrg
            ? EntityTypeEnum.ORGANIZATION_PROFILE.name
            : EntityTypeEnum.PERSON_PROFILE.name,
        ...omit(owner, 'isOrg'),
    };
}

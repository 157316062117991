import React from 'react';
import { BriefingSimpleView } from '@mark43/rms-api';
import dateCellFactory from '../../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import { BriefingCellWrapper } from './BriefingCellWrapper';

const PostedDateUtc = dateCellFactory('postedDateUtc');

type Props = Pick<BriefingSimpleView, 'postedDateUtc'>;

export const BriefingPostedDateCell = (props: Props) => {
    return (
        <BriefingCellWrapper>
            <PostedDateUtc {...props} />{' '}
        </BriefingCellWrapper>
    );
};

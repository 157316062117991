const BASE_PATH = 'attributes/codes/links';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Attribute Code Type Links Resource',
        methods: {
            loadAttributeCodeLinksByAttributeType(attrType) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}?codeTypeSource=${attrType}`,
                });
            },
        },
    });
}

/**
 * This is temporarily a function to avoid dependency
 *  problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

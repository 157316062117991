import { get, some, isEmpty, map, reduce, chain } from 'lodash';
import { RefContextEnum } from '@mark43/rms-api';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '../../field-configuration-contexts/state/data';

export type Subdivisions = {
    subdivisionAttrIds?: number[];
    subdivision1AttrIds?: number[];
    subdivision2AttrIds?: number[];
    subdivision3AttrIds?: number[];
    subdivision4AttrIds?: number[];
    subdivision5AttrIds?: number[];
};

type SubdivisionKeys = keyof Subdivisions;

export const allSubdivisionAttrIdNames: SubdivisionKeys[] = [
    'subdivisionAttrIds',
    'subdivision1AttrIds',
    'subdivision2AttrIds',
    'subdivision3AttrIds',
    'subdivision4AttrIds',
    'subdivision5AttrIds',
];

type ElasticQuery = {
    locations?: Subdivisions[];
    involvedLocations?: Subdivisions[];
};

export const includeLocationSubdivisions = (location: Subdivisions) => {
    return some(
        map(
            allSubdivisionAttrIdNames,
            (subdivisionAttrName) => !isEmpty(get(location, subdivisionAttrName))
        )
    );
};

export const getSubdivisionsFromLocation = (location: Subdivisions): Subdivisions => {
    const resultSubdivisions: Subdivisions = {};
    return reduce(
        allSubdivisionAttrIdNames,
        (result, subdivisionName) => {
            result[subdivisionName] = get(location, subdivisionName);
            return result;
        },
        resultSubdivisions
    );
};

export const getElasticQuerySubdivisionAttrIds = (
    elasticQuery: ElasticQuery,
    locationFieldName: keyof ElasticQuery
): Subdivisions => {
    return getSubdivisionsFromLocation(
        chain(elasticQuery)
            .get(locationFieldName)
            .reject((location: Subdivisions) => !includeLocationSubdivisions(location))
            .first()
            .value()
    );
};

type SubdivisionMapType = {
    [subdivisionName: string]: boolean;
};

export const getSubdivisionShownMap = (
    fieldConfigurationContextByContextAndFieldName: ReturnType<
        typeof fieldConfigurationContextByContextAndFieldNameSelector
    >
) => {
    const subdivisionShownMap: SubdivisionMapType = {};

    const isFieldStaticallyVisibleInFormLocationEntityContext = (fieldName: string) => {
        const fieldInContext = fieldConfigurationContextByContextAndFieldName(
            RefContextEnum.FORM_LOCATION_ENTITY_LINK_SIDE_PANEL.name,
            fieldName
        );
        return !get(fieldInContext, 'isStaticallyHidden');
    };

    for (let i = 1; i <= 5; i++) {
        subdivisionShownMap[
            `LOCATION_ENTITY_LINK_SUBDIVISION_${i}_ATTR_ID`
        ] = isFieldStaticallyVisibleInFormLocationEntityContext(
            `LOCATION_ENTITY_LINK_SUBDIVISION_${i}_ATTR_ID`
        );
    }

    return subdivisionShownMap;
};

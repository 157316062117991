import React, { useCallback } from 'react';

import { AttributeViewModel } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

import { arbiterMFTInput } from '../../../arbiter';
import AttributeSelect from './AttributeSelect';

/**
 * An `AttributeSelect` that also accepts a `timeWindowDate` and passes a predicate to display attribute options valid on that date.
 * If `timeWindowDate` is undefined it should behave as an ordinary `AttributeSelect`
 */
const ValidByDateAttributeSelect = ({
    timeWindowDate,
    ...otherProps
}: {
    timeWindowDate: string | undefined | null;
}) => {
    const timeWindowPredicate = useCallback(
        (attribute: AttributeViewModel) => {
            if (!timeWindowDate) {
                return true;
            } else if (attribute.start && attribute.end) {
                const timeWindowDateAsDate = new Date(timeWindowDate);
                return (
                    timeWindowDateAsDate >= new Date(attribute.start) &&
                    timeWindowDateAsDate <= new Date(attribute.end)
                );
            } else if (attribute.start) {
                return new Date(timeWindowDate) >= new Date(attribute.start);
            } else {
                return true;
            }
        },
        [timeWindowDate]
    );
    return (
        <AttributeSelect
            predicate={timeWindowPredicate}
            includeExpired={!!timeWindowDate}
            {...otherProps}
        />
    );
};

export const ArbiterMFTValidByDateAttributeSelect = arbiterMFTInput(ValidByDateAttributeSelect);

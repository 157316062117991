import { GpsRetentionPolicyDto } from '@mark43/gps-api';
import {
    Form,
    InferFormDataShape,
    createField,
    createFormConfiguration,
    formEvents,
} from 'markformythree';
import * as yup from 'yup';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../../core/validation/yupMftValidation';

const strings = componentStrings.validation;

export const GPS_RETENTION_FORM = 'GPS_RETENTION_FORM';

type FormModel = InferFormDataShape<typeof mftFormConfiguration>;

export const mftFormConfiguration = createFormConfiguration({
    days: createField<number>({}),
    departmentId: createField<number>({}),
    isActive: createField<boolean>({}),
});

export function convertToFormModel(gpsRetentionDto: GpsRetentionPolicyDto): FormModel {
    const { days, departmentId, isActive } = gpsRetentionDto;

    return {
        days,
        departmentId,
        isActive,
    };
}

export function convertFromFormModel(formModel: FormModel): Partial<GpsRetentionPolicyDto> {
    const { days, departmentId, isActive } = formModel;
    return {
        days,
        departmentId,
        isActive,
    };
}

const validationSchema = yup.object().shape({
    days: yup.number().required(strings.requiredError),
    isActive: yup.boolean(),
});

export const onValidate: Form['props']['onValidate'] = ({ formState, eventType }) => {
    return validationSchema
        .validate(formState.model, { abortEarly: false })
        .then((validationResult) => {
            return convertYupSuccessShapeToMFTSuccessShape(validationResult, formState.ui.$form);
        })
        .catch((validationErrors) => {
            return convertYupErrorShapeToMFTErrorShape(
                validationErrors,
                formState.ui.$form,
                eventType
            );
        });
};

export const validationEvents = [{ eventType: formEvents.FORM_SUBMIT }];

export const initialState = {
    days: undefined,
    departmentId: undefined,
    isActive: undefined,
};

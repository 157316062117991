let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Id Format Configuration Resource',
        methods: {
            getIdFormatConfigurations() {
                return req({
                    method: 'GET',
                    url: 'id_formats/configuration',
                });
            },
            getIdFormatConfigurationById(id) {
                return req({
                    method: 'GET',
                    url: `id_formats/configuration/${id}`,
                });
            },
            upsertIdFormatConfiguration(idFormatConfiguration) {
                return req({
                    method: 'POST',
                    url: 'id_formats/configuration/upsert',
                    data: idFormatConfiguration,
                });
            },
            upsertWarrantIdFormatConfiguration(idFormatConfiguration) {
                return req({
                    method: 'POST',
                    url: 'id_formats/warrant/configuration',
                    data: idFormatConfiguration,
                });
            },
            upsertWarrantIdFormatSequenceType(appSettingRequest) {
                return req({
                    method: 'POST',
                    url: 'id_formats/warrant/sequence_type',
                    data: appSettingRequest,
                });
            },
            removeIdFormatConfigurationById(idFormatConfigurationId) {
                return req({
                    method: 'DELETE',
                    url: `id_formats/configuration/${idFormatConfigurationId}`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import styled from 'styled-components';
import Icon from '../../../../legacy-redux/components/core/Icon';

export const HistoryWrapper = styled.div`
    border-left: 3px solid ${(props) => props.theme.colors.lightGrey};
    margin: 0 0 0 20px;
    position: relative;
    min-height: 75px;
    width: 595px;
    padding: 0 0 25px 20px;
`;

export const HistoryIconWrapper = styled.div`
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: -27px;
    display: block;
    background-color: ${(props) => props.theme.colors.white};
    background-position: 50%;
    width: 48px;
    height: 50px;
    background-repeat: no-repeat;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    border-left: 6px solid ${(props) => props.theme.colors.cobaltBlue};
`;

export const HistoryIcon = styled(Icon)<{ size: string }>`
    &::before {
        font-size: ${(props) => props.size || '36px'};
        color: ${(props) => props.theme.colors.cobaltBlue};
        margin: 5px 8px;
    }
`;

export const HistoryContent = styled.div`
    padding: 10px 20px 20px;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

import { SystemRuleEnum } from '@mark43/rms-api';
import _ from 'lodash';
import { otherUserId } from '~/client-common/helpers/userHelpers';
import addRuleId from '../helpers/addRuleId';
import { setKoModelValidation } from '../helpers/koValidationHelper';

export const propertyRecordsRecoveredByOtherNameRequired = addRuleId(
    SystemRuleEnum.PROPERTY_STATUS_RECOVERED_BY_OTHER_NAME_REQUIRED.name,
    (data, errMsg) => {
        // react rrfValidationRunner arguments (data=formModel)
        if (data.propertyStatusesWithCoC || data.propertyStatusesWithoutCoC) {
            const propertyStatuses = _(data).values().flatten().value();
            return _.every(propertyStatuses, ({ recoveredByOfficerId, recoveredByOtherName }) => {
                return recoveredByOfficerId === otherUserId
                    ? !!recoveredByOtherName
                    : !!recoveredByOfficerId;
            });
        }

        // non-react version, data is the entity data.
        const recoveredByOfficerId = data.propertyStatus.recoveredByOfficerId();
        const isValid =
            recoveredByOfficerId === otherUserId
                ? !!data.propertyStatus.recoveredByOtherName()
                : !!recoveredByOfficerId;
        setKoModelValidation(data.propertyStatus.recoveredByOtherName, isValid, errMsg);
        return isValid;
    }
);

export const propertyStatusVehicleSearchConsentRequired = addRuleId(
    SystemRuleEnum.PROPERTY_STATUS_VEHICLE_SEARCH_CONSENT_REQUIRED.name,
    (data, errMsg) => {
        if (data.propertyStatusesWithCoC || data.propertyStatusesWithoutCoC) {
            // prevent error in react version of this form
            // TODO: this rule has no fieldName, so user cannot see
            // which field needs to be fixed.
            return true;
        }
        if (data.propertyStatus.wasVehicleSearched()) {
            const isValid =
                data.propertyStatus.wasVehicleSearchConsentedTo() !== null &&
                data.propertyStatus.wasVehicleSearchConsentedTo() !== undefined;
            setKoModelValidation(
                data.propertyStatus.wasVehicleSearchConsentedToSelect,
                isValid,
                errMsg
            );
            return isValid;
        } else {
            return true;
        }
    }
);

export const itemFacilityLinkFacilityIdRequiredForEvidenceWithoutChainOfCustody = addRuleId(
    SystemRuleEnum
        .ITEM_FACILITY_LINK_STORAGE_LOCATION_ID_REQUIRED_FOR_EVIDENCE_WITHOUT_CHAIN_OF_CUSTODY.name,
    (data, errMsg) => {
        // react rrfValidationRunner arguments (data=formModel, errMsg=value)
        if (data.propertyStatusesWithoutCoC) {
            // react rrfValidationRunner uses different arguments
            const value = errMsg;
            return !!value;
        }

        // non-react version, data is the entity data.
        const storageLocationIdIsHidden =
            _.get(data.propertyStatus, '_config.storageLocationId.hidden') &&
            data.propertyStatus._config.storageLocationId.hidden();
        if (data.item.isEvidence() && !storageLocationIdIsHidden) {
            const isValid = !!data.propertyStatus.storageLocationId();

            setKoModelValidation(data.propertyStatus.storageLocationId, isValid, errMsg);
            return isValid;
        } else {
            return true;
        }
    }
);

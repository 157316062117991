import { get, isEmpty, map, size } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import factory from '../../../../legacy-redux/actions/types/actionTypesFactory';
import {
    openLoadingModal,
    closeLoadingModal,
    openConfirmationModal,
    closeConfirmationModal,
    openErrorModal,
} from '../../../../legacy-redux/actions/boxActions';

const strings = componentStrings.admin.importexport;

export const actionTypes = factory('admin-core', [
    'VALIDATE_FILE_START',
    'VALIDATE_FILE_SUCCESS',
    'VALIDATE_FILE_FAILURE',
    'IMPORT_START',
    'IMPORT_SUCCESS',
    'IMPORT_FAILURE',
    'EXPORT_START',
    'EXPORT_SUCCESS',
    'EXPORT_FAILURE',
]);

function validateFileStart(fileId) {
    return {
        type: actionTypes.VALIDATE_FILE_START,
        payload: fileId,
    };
}

function validateFileSuccess(fileId) {
    return {
        type: actionTypes.VALIDATE_FILE_SUCCESS,
        payload: fileId,
    };
}

function validateFileFailure(errorMessages) {
    return {
        type: actionTypes.VALIDATE_FILE_FAILURE,
        payload: errorMessages,
    };
}

// use this function to create an action creator for each type of object that
// can be imported (attributes, users...)
export const createValidateFileActionCreator = (resourceMethod, importAction) => (
    fileId,
    ...otherArgs
) => (dispatch) => {
    dispatch(openLoadingModal());
    dispatch(validateFileStart(fileId));

    return resourceMethod(fileId, ...otherArgs)
        .then((simpleValidationErrors) => {
            if (isEmpty(simpleValidationErrors)) {
                dispatch(validateFileSuccess(fileId));
                dispatch(importAction(fileId));
            } else {
                const errorMessages = map(simpleValidationErrors, 'errorDetailMessage');
                dispatch(validateFileFailure(errorMessages));
                dispatch(closeLoadingModal());
                dispatch(
                    openErrorModal({
                        paragraphs: [strings.validateImportError(size(errorMessages))],
                        list: errorMessages,
                    })
                );
            }
        })
        .catch((err) => {
            dispatch(validateFileFailure([strings.genericImportError]));
            dispatch(closeLoadingModal());
            dispatch(
                openErrorModal({
                    paragraphs: [strings.genericImportError],
                    list: [err.message],
                })
            );
        });
};

function importStart(fileId) {
    return {
        type: actionTypes.IMPORT_START,
        payload: fileId,
    };
}

function importSuccess(fileId) {
    return {
        type: actionTypes.IMPORT_SUCCESS,
        payload: fileId,
    };
}

function importFailure(errorMessages) {
    return {
        type: actionTypes.IMPORT_FAILURE,
        payload: errorMessages,
    };
}

// use this function to create an action creator for each type of object that
// can be imported (attributes, users...)
export const createImportActionCreator = (resourceMethod, options = {}) => (
    fileId,
    ...otherArgs
) => (dispatch) => {
    dispatch(openLoadingModal());
    dispatch(importStart(fileId));

    const { reloadOnSuccess = true } = options;

    return resourceMethod(fileId, ...otherArgs)
        .then((success) => {
            if (success) {
                dispatch(importSuccess(fileId));
                dispatch(closeLoadingModal());
                if (reloadOnSuccess) {
                    // reload page; we have just imported successfully, so, let's reflect the changes.
                    window.location.reload();
                }
            } else {
                dispatch(importFailure([strings.genericImportError]));
                dispatch(closeLoadingModal());
                dispatch(openErrorModal({ paragraphs: [strings.genericImportError] }));
            }
        })
        .catch((err) => {
            dispatch(importFailure([strings.genericImportError]));
            dispatch(closeLoadingModal());
            dispatch(
                openErrorModal({
                    paragraphs: [strings.genericImportError],
                    list: [err.message],
                })
            );
        });
};

function exportStart() {
    return {
        type: actionTypes.EXPORT_START,
    };
}

function exportSuccess(file) {
    return {
        type: actionTypes.EXPORT_SUCCESS,
        payload: file,
    };
}

function exportFailure(errorMessage) {
    return {
        type: actionTypes.EXPORT_FAILURE,
        payload: errorMessage,
    };
}

// use this function to create an action creator for each type of object that
// can be exported (attributes, users...)
export const createExportActionCreator = (resourceMethod) => (...args) => (dispatch) => {
    dispatch(openLoadingModal());
    dispatch(exportStart());

    return resourceMethod(...args)
        .then((file) => {
            const fileWebServerPath = get(file, 'fileWebServerPath');
            if (fileWebServerPath) {
                window.open(fileWebServerPath, '_blank');
                dispatch(exportSuccess(file));
                dispatch(closeLoadingModal());
            } else {
                dispatch(exportFailure(strings.genericExportError));
                dispatch(closeLoadingModal());
                dispatch(openErrorModal({ paragraphs: [strings.genericExportError] }));
            }
        })
        .catch(() => {
            dispatch(exportFailure(strings.genericExportError));
            dispatch(closeLoadingModal());
            dispatch(openErrorModal({ paragraphs: [strings.genericExportError] }));
        });
};

/*
These new action creator methods will be used by the admin pages that send a bulk import request packet instead of just a file id.
Currently [Attributes,Offense codes, users, location aliases, street aliases, street segments, vehicle make model]
 */

export const createValidateBulkActionCreator = (resourceMethod, importAction) => (
    bulkImportRequest
) => (dispatch) => {
    dispatch(openLoadingModal());
    dispatch(validateFileStart(bulkImportRequest.fileId));

    return resourceMethod(bulkImportRequest)
        .then((bulkImportResponse) => {
            if (bulkImportResponse.success) {
                dispatch(validateFileSuccess(bulkImportRequest.fileId));
                const file = bulkImportResponse.validatedChangesExcelFile;
                if (!!file) {
                    downloadSuccessfulValidationExcelFile(
                        dispatch,
                        bulkImportRequest,
                        bulkImportResponse.successMessage,
                        file,
                        importAction
                    );
                } else {
                    dispatch(importAction(bulkImportRequest));
                }
            } else {
                const errorMessages = map(bulkImportResponse.errorMessages, 'errorDetailMessage');
                dispatch(validateFileFailure(errorMessages));
                dispatch(closeLoadingModal());
                dispatch(
                    openErrorModal({
                        paragraphs: [strings.validateImportError(size(errorMessages))],
                        list: errorMessages,
                    })
                );
            }
        })
        .catch((err) => {
            dispatch(
                validateFileFailure([strings.bulkImportError(strings.importPhase.validating)])
            );
            dispatch(closeLoadingModal());
            dispatch(
                openErrorModal({
                    paragraphs: [strings.bulkImportError(strings.importPhase.validating)],
                    list: [err.message],
                })
            );
        });
};

export const createBulkImportActionCreator = (resourceMethod, options = {}) => (
    bulkImportRequest
) => (dispatch) => {
    dispatch(openLoadingModal());
    dispatch(importStart(bulkImportRequest.fileId));

    const { reloadOnSuccess = true } = options;

    const errorMessageHeader = strings.bulkImportError(strings.importPhase.importing);

    return resourceMethod(bulkImportRequest)
        .then((bulkImportResponse) => {
            if (bulkImportResponse.success) {
                dispatch(importSuccess(bulkImportRequest.fileId));
                dispatch(closeLoadingModal());
                if (reloadOnSuccess) {
                    // reload page; we have just imported successfully, so, let's reflect the changes.
                    window.location.reload();
                }
            } else {
                const errorMessages = map(bulkImportResponse.errorMessages, 'errorDetailMessage');
                dispatch(importFailure([errorMessageHeader]));
                dispatch(closeLoadingModal());
                dispatch(
                    openErrorModal({
                        paragraphs: [errorMessageHeader],
                        list: errorMessages,
                    })
                );
            }
        })
        .catch((err) => {
            dispatch(importFailure([errorMessageHeader]));
            dispatch(closeLoadingModal());
            dispatch(
                openErrorModal({
                    paragraphs: [errorMessageHeader],
                    list: [err.message],
                })
            );
        });
};

function downloadSuccessfulValidationExcelFile(
    dispatch,
    bulkImportRequest,
    successMessage,
    file,
    importAction
) {
    const fileWebServerPath = get(file, 'fileWebServerPath');
    if (fileWebServerPath) {
        const saveCallback = () => {
            dispatch(closeConfirmationModal());
            dispatch(importAction(bulkImportRequest));
        };
        window.open(fileWebServerPath, '_blank');
        dispatch(exportSuccess(file));
        dispatch(closeLoadingModal());
        dispatch(
            openConfirmationModal({
                title: strings.successfulValidation.title,
                message: strings.successfulValidation.message(successMessage),
                saveCallback,
                messageContainsNewLine: true,
            })
        );
    } else {
        // continue with import.
        dispatch(importAction(bulkImportRequest));
    }
}

import React from 'react';
import { useSelector } from 'react-redux';
import { sortBy, chain, map, get } from 'lodash';
import { UseOfForceSubject } from '@mark43/rms-api';

import * as fields from '~/client-common/core/enums/universal/fields';
import { booleanToYesNo, joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { nameAttributeViewModelsWhereSelector } from '~/client-common/core/domain/name-attributes/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { useOfForceSubjectDeEscalationsWhereSelector } from '~/client-common/core/domain/use-of-force-subject-de-escalations/state/data';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';

type UseOfForceSubjectCardSummaryProps = {
    useOfForceSubject: UseOfForceSubject;
    nameSummaryViewWrapper: React.ReactNode;
    subjectPersonProfileId: number;
};

const UseOfForceSubjectCardSummary: React.FC<UseOfForceSubjectCardSummaryProps> = (props) => {
    const { useOfForceSubject, nameSummaryViewWrapper, subjectPersonProfileId } = props;

    const nameAttributeViewModelsWhere = useSelector(nameAttributeViewModelsWhereSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const useOfForceSubjectDeEscalationsWhere = useSelector(
        useOfForceSubjectDeEscalationsWhereSelector
    );
    const useOfForceSubjectDeEscalations = useOfForceSubjectDeEscalationsWhere({
        subjectId: useOfForceSubject.id,
    });

    if (!useOfForceSubject) {
        return <div />;
    }
    const {
        arrested,
        charges,
        deEscalationAttempted,
        firearmDischargeIntentional,
        firearmDischargeAccidental,
        shotsFiredAttrId,
        numShotsFired,
        numShotsHit,
        resistedAttrId,
        subjectPerceivedArmedWithAttrId,
        subjectConfirmedArmedWithAttrId,
        officerAttemptedToDisarmSubject,
        subjectBehaviorWasErratic,
        subjectImpairmentAttrId,
        officerUsedForceOnSubject,
        subjectDeceasedAsResultOfUseOfForce,
        subjectUsedForceOnOfficer,
        officerDeceasedAsResultOfUseOfForce,
        threatDirectedAtAttrId,
        officerInjuredAttrId,
        officerAssaultHomicideIncidentNumber,
        nibrsIncidentNumberPuAttrId,
        subjectDispositionAttrId,
        chargedAttrId,
    } = useOfForceSubject;

    const nameAttributes = nameAttributeViewModelsWhere({
        nameId: subjectPersonProfileId,
    });

    const nameAttributeFormatsByType = chain(nameAttributes)
        .groupBy('attributeType')
        .mapValues((nameAttributes) => {
            return joinTruthyValues(
                sortBy(
                    map(nameAttributes, (attribute) =>
                        get(getViewModelProperties(attribute), 'displayFormats.description')
                    )
                )
            );
        })
        .value();
    return (
        <>
            <SummaryList labelWidth={220} contentWidth={320}>
                <CardSection>
                    <FeatureFlagged
                        flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED"
                        fallback={
                            <>
                                <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_ARRESTED}>
                                    {booleanToYesNo(arrested)}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_CHARGES}>
                                    {charges}
                                </SummaryRow>
                            </>
                        }
                    />
                    <SummaryRow
                        fieldName={fields.USE_OF_FORCE_SUBJECT_FIREARM_DISCHARGE_INTENTIONAL}
                    >
                        {booleanToYesNo(firearmDischargeIntentional)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.USE_OF_FORCE_SUBJECT_FIREARM_DISCHARGE_ACCIDENTAL}
                    >
                        {booleanToYesNo(firearmDischargeAccidental)}
                    </SummaryRow>
                    <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                        <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_SHOTS_FIRED_ATTR_ID}>
                            {formatAttributeById(shotsFiredAttrId)}
                        </SummaryRow>
                    </FeatureFlagged>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_NUM_SHOTS_FIRED}>
                        {numShotsFired}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_NUM_SHOTS_HIT}>
                        {numShotsHit}
                    </SummaryRow>
                </CardSection>
                <CardSection
                    fieldName={fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_USE_OF_FORCE_LINK_TYPE}
                >
                    {nameSummaryViewWrapper}
                    <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                        <SummaryRow
                            fieldName={fields.USE_OF_FORCE_SUBJECT_THREAT_DIRECTED_AT_ATTR_ID}
                        >
                            {formatAttributeById(threatDirectedAtAttrId)}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.USE_OF_FORCE_SUBJECT_SUBJECT_IMPAIRMENT_ATTR_ID}
                        >
                            {formatAttributeById(subjectImpairmentAttrId)}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_IMPAIRMENTS_ATTRIBUTE_ID
                            }
                        >
                            {nameAttributeFormatsByType.USE_OF_FORCE_SUBJECT_IMPAIRMENTS}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_DE_ESCALATION_ATTEMPTED}>
                            {booleanToYesNo(deEscalationAttempted)}
                        </SummaryRow>
                        {map(useOfForceSubjectDeEscalations, (useOfForceSubjectDeEscalation, i) => {
                            const {
                                deEscalationTypeAttrId,
                                deEscalationSuccessful,
                            } = useOfForceSubjectDeEscalation;
                            return (
                                <div key={i}>
                                    <SummaryRow
                                        fieldName={
                                            fields.USE_OF_FORCE_SUBJECT_DE_ESCALATION_DE_ESCALATION_TYPE_ATTR_ID
                                        }
                                    >
                                        {formatAttributeById(deEscalationTypeAttrId)}
                                    </SummaryRow>
                                    <SummaryRow
                                        fieldName={
                                            fields.USE_OF_FORCE_SUBJECT_DE_ESCALATION_DE_ESCALATION_SUCCESSFUL
                                        }
                                    >
                                        {booleanToYesNo(deEscalationSuccessful)}
                                    </SummaryRow>
                                </div>
                            );
                        })}
                    </FeatureFlagged>
                    <SummaryRow
                        fieldName={fields.USE_OF_FORCE_SUBJECT_SUBJECT_PERCEIVED_ARMED_WITH_ATTR_ID}
                    >
                        {formatAttributeById(subjectPerceivedArmedWithAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.USE_OF_FORCE_SUBJECT_SUBJECT_CONFIRMED_ARMED_WITH_ATTR_ID}
                    >
                        {formatAttributeById(subjectConfirmedArmedWithAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.USE_OF_FORCE_SUBJECT_OFFICER_ATTEMPTED_TO_DISARM_SUBJECT}
                    >
                        {booleanToYesNo(officerAttemptedToDisarmSubject)}
                    </SummaryRow>
                    <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                        <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_RESISTED_ATTR_ID}>
                            {formatAttributeById(resistedAttrId)}
                        </SummaryRow>
                    </FeatureFlagged>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_ACTIONS_ATTRIBUTE_ID
                        }
                    >
                        {nameAttributeFormatsByType.USE_OF_FORCE_SUBJECT_ACTIONS}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.USE_OF_FORCE_SUBJECT_SUBJECT_BEHAVIOR_WAS_ERRATIC}
                    >
                        {booleanToYesNo(subjectBehaviorWasErratic)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_DETAILS_ATTRIBUTE_ID
                        }
                    >
                        {nameAttributeFormatsByType.USE_OF_FORCE_SUBJECT_DETAILS}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.USE_OF_FORCE_SUBJECT_OFFICER_USED_FORCE_ON_SUBJECT}
                    >
                        {booleanToYesNo(officerUsedForceOnSubject)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_ATTRIBUTE_ID
                        }
                    >
                        {nameAttributeFormatsByType.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_LOCATION_ATTRIBUTE_ID
                        }
                    >
                        {
                            nameAttributeFormatsByType.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_LOCATION
                        }
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_INJURY_SEVERITY_ATTRIBUTE_ID
                        }
                    >
                        {nameAttributeFormatsByType.USE_OF_FORCE_SUBJECT_INJURY_SEVERITY}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_INJURY_TYPE_ATTRIBUTE_ID
                        }
                    >
                        {nameAttributeFormatsByType.USE_OF_FORCE_SUBJECT_INJURY_TYPE}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_MEDICAL_AID_RECEIVED_ATTRIBUTE_ID
                        }
                    >
                        {nameAttributeFormatsByType.USE_OF_FORCE_MEDICAL_AID_RECEIVED}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.USE_OF_FORCE_SUBJECT_SUBJECT_DECEASED_AS_RESULT_OF_USE_OF_FORCE
                        }
                    >
                        {booleanToYesNo(subjectDeceasedAsResultOfUseOfForce)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.USE_OF_FORCE_SUBJECT_SUBJECT_USED_FORCE_ON_OFFICER}
                    >
                        {booleanToYesNo(subjectUsedForceOnOfficer)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_ATTRIBUTE_ID
                        }
                    >
                        {nameAttributeFormatsByType.USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_LOCATION_ATTRIBUTE_ID
                        }
                    >
                        {
                            nameAttributeFormatsByType.USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_LOCATION
                        }
                    </SummaryRow>
                    <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                        <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_OFFICER_INJURED_ATTR_ID}>
                            {officerInjuredAttrId && (
                                <ConnectedFormattedAttribute attributeId={officerInjuredAttrId} />
                            )}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.USE_OF_FORCE_SUBJECT_OFFICER_ASSAULT_HOMICIDE_INCIDENT_NUMBER
                            }
                        >
                            {officerAssaultHomicideIncidentNumber}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.USE_OF_FORCE_SUBJECT_NIBRS_INCIDENT_NUMBER_PU_ATTR_ID}
                        >
                            {nibrsIncidentNumberPuAttrId && (
                                <ConnectedFormattedAttribute
                                    attributeId={nibrsIncidentNumberPuAttrId}
                                />
                            )}
                        </SummaryRow>
                    </FeatureFlagged>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_INJURY_SEVERITY_ATTRIBUTE_ID
                        }
                    >
                        {nameAttributeFormatsByType.USE_OF_FORCE_OFFICER_INJURY_SEVERITY}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_INJURY_TYPE_ATTRIBUTE_ID
                        }
                    >
                        {nameAttributeFormatsByType.USE_OF_FORCE_OFFICER_INJURY_TYPE}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.USE_OF_FORCE_SUBJECT_OFFICER_DECEASED_AS_RESULT_OF_USE_OF_FORCE
                        }
                    >
                        {booleanToYesNo(officerDeceasedAsResultOfUseOfForce)}
                    </SummaryRow>
                    <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                        <SummaryRow
                            fieldName={fields.USE_OF_FORCE_SUBJECT_SUBJECT_DISPOSITION_ATTR_ID}
                        >
                            {formatAttributeById(subjectDispositionAttrId)}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_CHARGES}>
                            {charges}
                        </SummaryRow>
                    </FeatureFlagged>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_SUBJECT_CHARGED_ATTR_ID}>
                        {formatAttributeById(chargedAttrId)}
                    </SummaryRow>
                </CardSection>
            </SummaryList>
        </>
    );
};

export default UseOfForceSubjectCardSummary;

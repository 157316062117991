import { connect } from 'react-redux';
import { compose } from 'redux';
import React from 'react';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { reportCaseStatusViewModelByReportIdSelector } from '~/client-common/core/domain/report-case-statuses/state/ui';
import { reportDefinitionHasReportCaseStatusSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.core.EventInfoCard;

const mapStateToProps = (state, ownProps) => {
    const reportId = ownProps.reportId;
    const report = reportByIdSelector(state)(reportId) || {};
    const reportDefinitionHasReportCaseStatus = reportDefinitionHasReportCaseStatusSelector(state)(
        report.reportDefinitionId
    );
    const reportCaseStatusViewModel =
        reportCaseStatusViewModelByReportIdSelector(state)(reportId) || {};

    return {
        reportDefinitionHasReportCaseStatus,
        reportCaseStatusViewModel,
    };
};

const ReportCaseStatusSummary = compose(
    connect(mapStateToProps),
    renderOnlyIf(
        ({ reportCaseStatusViewModel, reportDefinitionHasReportCaseStatus }) =>
            reportDefinitionHasReportCaseStatus && !!reportCaseStatusViewModel.caseStatusAttrId
    )
)(function _ReportCaseStatusSummary({ reportCaseStatusViewModel }) {
    const viewModelProperties = getViewModelProperties(reportCaseStatusViewModel);
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    return (
        <SummaryList
            labelWidth={160}
            contentWidth={380}
            testId={testIds.EVENT_CARD_SUMMARY_CASE_STATUS_SECTION}
        >
            <CardSubsection title={strings.caseAssignmentStatusSectionTitle(caseDisplayName)}>
                <SummaryRow fieldName={fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID}>
                    {viewModelProperties.caseStatusAttrId}
                </SummaryRow>
                <SummaryRowDate
                    fieldName={fields.REPORT_CASE_STATUS_STATUS_DATE_UTC}
                    date={reportCaseStatusViewModel.statusDateUtc}
                />
                <SummaryRow fieldName={fields.REPORT_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID}>
                    {viewModelProperties.closedByDivisionAttrId}
                </SummaryRow>
                <SummaryRow fieldName={fields.REPORT_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID}>
                    {viewModelProperties.closedByUnitAttrId}
                </SummaryRow>
            </CardSubsection>
        </SummaryList>
    );
});

export default ReportCaseStatusSummary;

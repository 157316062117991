import factory from './actionTypesFactory';

export default factory('exports', [
    'EXPORTS_PAGELOAD',
    'PRINT',
    'PRINT_FAILURE',
    'PRINT_REFRESH',
    'LOAD_PACKET_OPTIONS',
    'LOAD_PACKET_OPTIONS_SUCCESS',
    'LOAD_PACKET_OPTIONS_FAILURE',
    'EXPAND_MY_EXPORTS',
    'COLLAPSE_MY_EXPORTS',
    'CLEAR_EXPORTS',
    'CLEAR_EXPORTS_FAILURE',
    'REMOVE_FILES_FROM_MY_EXPORTS',
    'SET_REDACTION_WORK_ORDER',
    'SET_POSITION_RIGHT',
]);

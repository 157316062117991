import React from 'react';
import { Text, cssVar } from 'arc';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { EFileLinkTypesT } from '../../..';

const strings: { [key in EFileLinkTypesT]: string } =
    componentStrings.eFiles.core.grid.deactivatedLabels;

const DefendantWrapper = styled.div<{ $isDeactivated: boolean }>`
    padding: 10px;
    border-radius: 6px;
    background-color: ${({ $isDeactivated }) =>
        $isDeactivated
            ? cssVar('arc.colors.interactive.active')
            : cssVar('arc.colors.surface.canvas')};
`;

const DeactivatedText = styled(Text)`
    font-style: italic;
    padding-bottom: 10px;
`;

type EFileDefendantSummaryCardPropsT = {
    isDeactivated: boolean;
    linkType: EFileLinkTypesT;
    children: React.ReactNode;
};

export const EFileDefendantSummaryCard = ({
    isDeactivated,
    linkType,
    children,
}: EFileDefendantSummaryCardPropsT) => (
    <DefendantWrapper $isDeactivated={isDeactivated}>
        {isDeactivated && <DeactivatedText variant="bodyMd">{strings[linkType]}</DeactivatedText>}
        {children}
    </DefendantWrapper>
);

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReportStatusView } from '@mark43/rms-api';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import reportApprovalLevelClientEnum from '~/client-common/core/enums/client/reportApprovalLevelClientEnum';
import {
    approvalStatusSelector,
    disableReportSubmissionButtonsSelector,
    reportStatusViewSelector,
    currentReportApprovalLevelSelector,
    reportLastExportedDateSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';
import { openReportRejectionModal } from '../../state/ui';
import { actuallyRejectReport } from '../../state/ui/submissions';

import { RmsDispatch } from '../../../../../core/typings/redux';
import DraftCard from './DraftCard';
import SubmittedCard from './SubmittedCard';
import RejectedCard from './RejectedCard';
import CompletedCard from './CompletedCard';
import PendingSecondaryReviewCard from './PendingSecondaryReviewCard';

interface ReportStatusCommentsCardPropsT {
    approvalStatus: keyof typeof approvalStatusClientEnum | undefined;
    className?: string;
    currentReportApprovalLevel: keyof typeof reportApprovalLevelClientEnum | undefined;
    disableReportSubmissionButtons: boolean;
    isCustodialReport: boolean;
    rejectReport: (callback: () => void) => void;
    reportLastExportedDate: string | undefined;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
    reportStatusView: ReportStatusView | false;
    ucrRejectReport: () => void;
}

const ReportStatusCommentsCard = ({
    isCustodialReport,
    approvalStatus,
    reportStatusView,
    className,
    rejectReport,
    ucrRejectReport,
    reportSealingInfo,
    disableReportSubmissionButtons,
    currentReportApprovalLevel,
    reportLastExportedDate,
}: ReportStatusCommentsCardPropsT) => {
    if (!reportStatusView || !approvalStatus) {
        return <div />;
    }
    switch (approvalStatus) {
        case approvalStatusClientEnum.DRAFT:
            return (
                <DraftCard
                    isCustodialReport={isCustodialReport}
                    disabled={disableReportSubmissionButtons}
                    className={className}
                    reportStatusView={reportStatusView}
                    reportSealingInfo={reportSealingInfo}
                    reportApprovalLevel={currentReportApprovalLevel}
                />
            );

        case approvalStatusClientEnum.SUBMITTED:
        case approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW:
            return (
                <SubmittedCard
                    reportStatusView={reportStatusView}
                    rejectReport={rejectReport}
                    disabled={disableReportSubmissionButtons}
                    reportSealingInfo={reportSealingInfo}
                />
            );
        case approvalStatusClientEnum.REJECTED:
            return <RejectedCard reportSealingInfo={reportSealingInfo} />;
        case approvalStatusClientEnum.PENDING_SECONDARY_REVIEW:
            return (
                <PendingSecondaryReviewCard
                    className={className}
                    reportStatusView={reportStatusView}
                    ucrRejectReport={ucrRejectReport}
                    rejectReport={rejectReport}
                    disabled={disableReportSubmissionButtons}
                    reportSealingInfo={reportSealingInfo}
                />
            );
        case approvalStatusClientEnum.COMPLETED:
            return (
                <CompletedCard
                    reportApprovalLevel={currentReportApprovalLevel}
                    disabled={disableReportSubmissionButtons}
                    className={className}
                    reportStatusView={reportStatusView}
                    reportSealingInfo={reportSealingInfo}
                    ucrRejectReport={ucrRejectReport}
                    rejectReport={rejectReport}
                    reportLastExportedDate={reportLastExportedDate}
                />
            );

        default:
            return <div />;
    }
};

const mapStateToProps = createStructuredSelector({
    reportSealingInfo: currentReportSealingSelector,
    approvalStatus: approvalStatusSelector,
    reportStatusView: reportStatusViewSelector,
    disableReportSubmissionButtons: disableReportSubmissionButtonsSelector,
    currentReportApprovalLevel: currentReportApprovalLevelSelector,
    reportLastExportedDate: reportLastExportedDateSelector,
});

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    rejectReport: (callback: () => void) => {
        dispatch(
            openReportRejectionModal({
                callback: (reasonForRejection: string) =>
                    dispatch(actuallyRejectReport(false, reasonForRejection, callback)),
            })
        );
    },
    ucrRejectReport: () => {
        dispatch(
            openReportRejectionModal({
                callback: (reasonForRejection: string) =>
                    dispatch(actuallyRejectReport(true, reasonForRejection)),
            })
        );
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportStatusCommentsCard);

import React from 'react';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Modal from '../../../../../legacy-redux/components/core/Modal';
import { submitReportSubmissionModal } from '../../state/ui';
import { RmsDispatch } from '../../../../../core/typings/redux';

const strings = componentStrings.reports.core.ReportSubmissionModal;

const context = {
    name: boxEnum.REPORT_SUBMISSION_MODAL,
};

function ReportSubmissionModal({
    submitReportSubmissionModal,
}: {
    submitReportSubmissionModal: () => void;
}) {
    return (
        <Modal title={strings.title} context={context} onSave={submitReportSubmissionModal}>
            {strings.areYouSure}
        </Modal>
    );
}

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    submitReportSubmissionModal: () => dispatch(submitReportSubmissionModal()),
});

export default connect(null, mapDispatchToProps)(ReportSubmissionModal);

import { RefContextEnum } from '@mark43/rms-api';
import { lifecycleOptions } from 'markformythree';
import React from 'react';
import Row from '../../../../core/components/Row';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { initialState, formConfiguration } from '../../state/forms/secondaryRejectDispositionForm';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';

/**
 * Form for a secondary disposition reviewer to reject the primary review on an
 *   item, which sends it back to the primary review state. TODO: remove
 *   hardcoded labels after field configs are created
 */
const SecondaryRejectDispositionForm = () => (
    <ArbiterForm
        context={RefContextEnum.FORM_EVIDENCE_DISPOSITION_SECONDARY_REJECTION.name}
        configuration={formConfiguration}
        initialState={initialState}
        name={RefContextEnum.FORM_EVIDENCE_DISPOSITION_SECONDARY_REJECTION.name}
        lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
        render={() => (
            <Row>
                <ArbiterMFTTextArea path="comment" rows={6} />
            </Row>
        )}
    />
);

export default SecondaryRejectDispositionForm;

import { EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { some } from 'lodash';
import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { CASE_LOCAL_ID, DISPLAY_CASE_ASSIGNEE } from '~/client-common/core/enums/universal/fields';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import withFields from '~/client-common/core/fields/components/withFields';

import { formatCaseName } from '~/client-common/core/domain/cases/utils/caseHelpers';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import RecordsHeader from '../../../records/core/components/header/RecordsHeader';
import RecordsHeaderEditButton from '../../../records/core/components/header/RecordsHeaderEditButton';
import RecordsHeaderExportsButton from '../../../records/core/components/header/RecordsHeaderExportsButton';
import RecordsHeaderHistoryButton from '../../../records/core/components/header/RecordsHeaderHistoryButton';
import RecordsHeaderCurrentViewersToggle from '../../../records/core/components/header/RecordsHeaderCurrentViewersToggle';
import RecordsHeaderLinkReportMenu from '../../../records/core/components/header/RecordsHeaderLinkReportMenu';

import { openCaseReviewsSidePanel } from '../../case-review/state/ui';
import {
    caseReportsSelector,
    currentCaseIdSelector,
    currentCaseViewSelector,
    assigneeCaseRoleLinkSelector,
    supervisorCaseRoleLinksSelector,
    openCreateManageCaseModalForCase,
} from '../state/ui';

import { routeNameSelector } from '../../../../routing/routerModule';
import routesConfig from '../../../../routing/routesConfig';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import { ButtonGroup } from '../../../core/components/Button';
import Subheader from '../../../core/components/Subheader';
import { toggleCaseHistories } from '../state/ui/caseHistories';
import CaseHeaderHamburgerMenu from './CaseHeaderHamburgerMenu';
import CaseHeaderReviewCaseButton from './CaseHeaderReviewCaseButton';
import CreateManageCaseModal from './CreateManageCaseModal';
import CaseHeaderPermissionsButton from './CaseHeaderPermissionsButton';
import CreateNewReportFromMultiRenCaseModal from './CreateNewReportFromMultiRenCaseModal';

const strings = componentStrings.cases.core.CaseHeader;

/**
 * Header that appears on a case page. Don't confuse this with `CasesHeader`,
 *   which is the header for cases dashboard pages.
 */
function CaseHeader({
    toggleCaseHistories,
    openManageCaseModal,
    openCaseReviewsSidePanel,
    currentCaseId,
    currentCaseView,
    currentUserDepartmentId,
    fieldDisplayNames,
    caseReports,
    applicationSettings,
    routeName,
    bannerComponent,
}) {
    const { departmentId, permissionSet, reportingEventNumber, localId, title } =
        currentCaseView || {};
    const caseEnhancementsEnabled = applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE;
    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();
    const isCrossAgency = departmentId !== currentUserDepartmentId;
    const caseDisplayName = joinTruthyValues(
        [fieldDisplayNames.CASE_LOCAL_ID, formatCaseName(localId, title)],
        ' '
    );

    const showLinkReportButton =
        !isCrossAgency &&
        (caseEnhancementsEnabled ? some(caseReports, 'ren') : !!reportingEventNumber);

    return (
        <Subheader
            title={caseDisplayName}
            content={<RecordsHeader subtitleParts={[]} departmentId={departmentId} />}
            bannerComponent={bannerComponent}
        >
            <ButtonGroup spacing="var(--arc-space-1)">
                <OnlyWithEntityPermission
                    permissionSet={permissionSet}
                    has={OperationTypeEnum.WRITE.name}
                >
                    <CaseHeaderReviewCaseButton onClick={openCaseReviewsSidePanel} />
                </OnlyWithEntityPermission>
                <OnlyWithAbility has={abilitiesEnum.CASES.EDIT_GENERAL}>
                    <RecordsHeaderEditButton onClick={openManageCaseModal} />
                    <CreateManageCaseModal
                        title={strings.manageCase(caseFieldName)}
                        okText={strings.save}
                        isBulkCreate={false}
                        includeLocalIdInTitle={true}
                    />
                </OnlyWithAbility>
                {!isCrossAgency && (
                    <RecordsHeaderExportsButton
                        exportRouteName={routesConfig.CASE_EXPORTS.name}
                        path={`/cases/${currentCaseId}`}
                    />
                )}
                {!isCrossAgency && (
                    <CaseHeaderPermissionsButton
                        caseId={currentCaseId}
                        permissionSet={permissionSet}
                    />
                )}
                <OnlyWithAbility has={abilitiesEnum.CASES.VIEW_HISTORY}>
                    <RecordsHeaderHistoryButton
                        onClick={toggleCaseHistories}
                        active={routeName === routesConfig.CASE_HISTORY.name}
                    />
                </OnlyWithAbility>
                <RecordsHeaderCurrentViewersToggle
                    entityId={currentCaseId}
                    entityType={EntityTypeEnum.CASE.name}
                />
                {showLinkReportButton && (
                    <OnlyWithAbility has={abilitiesEnum.REPORTING.CREATE_REPORTS}>
                        <RecordsHeaderLinkReportMenu
                            reportingEventNumber={reportingEventNumber}
                            caseId={currentCaseId}
                        />
                        <FeatureFlagged flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE">
                            <CreateNewReportFromMultiRenCaseModal />
                        </FeatureFlagged>
                    </OnlyWithAbility>
                )}
                <CaseHeaderHamburgerMenu permissionSet={permissionSet} />
            </ButtonGroup>
        </Subheader>
    );
}

const mapStateToProps = createStructuredSelector({
    currentCaseId: currentCaseIdSelector,
    currentCaseView: currentCaseViewSelector,
    caseReports: caseReportsSelector,
    routeName: routeNameSelector,
    assigneeCaseRoleLink: assigneeCaseRoleLinkSelector,
    supervisorCaseRoleLinks: supervisorCaseRoleLinksSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    applicationSettings: applicationSettingsSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    openManageCaseModal: () => dispatch(openCreateManageCaseModalForCase()),
    openCaseReviewsSidePanel: () => dispatch(openCaseReviewsSidePanel()),
    toggleCaseHistories: () => dispatch(toggleCaseHistories(router)),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withFields([CASE_LOCAL_ID, DISPLAY_CASE_ASSIGNEE])
)(CaseHeader);

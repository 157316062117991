import Promise from 'bluebird';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import createCard from '../../utils/createCard';

const baseCard = createCard({
    anchor: 'vehicle-card',
    baseSelector: (state) => state.ui.report.vehicleCard,
    name: reportCardEnum.VEHICLE.name,
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit: (options = {}) => {
            const { index } = options;
            return (dispatch) => dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
        },
        save: (form, options = {}) => {
            return (dispatch) => {
                const cardIndex = { index: options.index };
                dispatch(baseCard.actionCreators.savingSuccess(cardIndex));
                return Promise.resolve();
            };
        },
    },
};

import React from 'react';
import invariant from 'invariant';
import Konva from 'konva';
import { Stage, Layer } from 'react-konva';
import { useToast } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { createWidget, getImageDimensions } from '../helpers';
import { CrashDiagramPosition, widgetTypes } from '../types';
import { useScreenBreakpoint } from '../../../../../../core/utils/useScreenBreakpoint';
import {
    CrashDiagramAssetType,
    STAGE_WIDTH_LARGE,
    STAGE_HEIGHT,
    DEFAULT_WIDGET_POSITION,
} from '../config';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { logError } from '../../../../../../../core/logging';
import errorToMessage from '../../../../../../core/errors/utils/errorToMessage';
import { ScrollableAssetsPanel } from './ScrollableAssetsPanel';
import { Image } from './widgets/Image';
import { Background } from './widgets/Background';
import { Text } from './widgets/Text';

const crashDiagramModalErrorMessageStrings =
    componentStrings.dragon.crashDiagram.CrashDiagramModal.errorMessages;

type CrashDiagramProps = {
    assetType?: CrashDiagramAssetType;
    stageRef: React.RefObject<Konva.Stage>;
    handleSetAssetType: (assetType?: CrashDiagramAssetType) => void;
};

const isSupportedDropWidgetType = (widgetType: string): widgetType is 'IMAGE' => {
    return widgetType === widgetTypes.IMAGE;
};

export const CrashDiagram: React.FC<CrashDiagramProps> = ({
    assetType,
    stageRef,
    handleSetAssetType,
}) => {
    const { isLargeScreen } = useScreenBreakpoint();
    const toast = useToast();
    const {
        widgets,
        selectedWidget,
        backgroundImage,
        addWidget,
        transformWidget,
        selectWidget,
        clearSelection,
    } = useCrashDiagram();

    const onSaveDragEndHistoryEvent = (widgetId: string, position: CrashDiagramPosition) => {
        transformWidget({
            widgetId,
            position,
        });
    };

    const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const konvaStage = stageRef?.current?.getStage();

        if (!konvaStage) {
            return;
        }

        konvaStage.setPointersPositions(e);

        const pointerPosition = konvaStage.getPointerPosition();
        if (!pointerPosition) {
            return;
        }

        const widgetSvgDataUrl = e.dataTransfer.getData('svgDataUrl');
        const widgetTypeDataTransferValue = e.dataTransfer.getData('widgetType');
        const widgetLabel = e.dataTransfer.getData('widgetLabel');
        invariant(
            isSupportedDropWidgetType(widgetTypeDataTransferValue),
            'Unsupported Widget Type'
        );
        invariant(!!widgetSvgDataUrl, 'Failed to transfer widgets base64 String');
        invariant(!!widgetLabel, 'Failed to transfer widget label');

        let width;
        let height;
        try {
            ({ width, height } = await getImageDimensions(widgetSvgDataUrl));
        } catch (err) {
            logError(
                errorToMessage(
                    err,
                    crashDiagramModalErrorMessageStrings.unhandledWidgetOnDropException(widgetLabel)
                )
            );
            toast({
                status: 'error',
                description:
                    crashDiagramModalErrorMessageStrings.unhandledWidgetOnDropException(
                        widgetLabel
                    ),
            });
            return;
        }

        addWidget({
            widget: createWidget({
                value: widgetSvgDataUrl,
                type: widgetTypeDataTransferValue,
                position: {
                    ...DEFAULT_WIDGET_POSITION,
                    ...pointerPosition,
                    height,
                    width,
                    offsetX: width / 2,
                    offsetY: height / 2,
                },
                opacity: 1,
            }),
        });
    };

    const onStageClick = (
        event: Konva.KonvaEventObject<MouseEvent> | Konva.KonvaEventObject<TouchEvent>
    ) => {
        const clickedOnEmpty = event.target === event.target.getStage();
        if (!clickedOnEmpty) {
            return;
        }
        clearSelection();
    };

    return (
        <>
            <div
                onDrop={onDrop}
                onDragOver={(e) => e.preventDefault()}
                style={!isLargeScreen ? { overflow: 'scroll' } : undefined}
            >
                {/*
                    https://github.com/konvajs/react-konva?tab=readme-ov-file#usage-with-react-context
                    // Note: Konva doesn't recognize that it is being rendered
                    // from within a React Context which is is why we can't
                    // consume the context in this component. To work around that
                    // we pass in callback functions as props to this component.
                */}
                <Stage
                    ref={stageRef}
                    width={STAGE_WIDTH_LARGE}
                    height={STAGE_HEIGHT}
                    onClick={onStageClick}
                >
                    <Layer>
                        {!!backgroundImage && (
                            <Background
                                width={STAGE_WIDTH_LARGE}
                                height={STAGE_HEIGHT}
                                imageUrl={backgroundImage}
                            />
                        )}
                        {widgets.map((widget) => {
                            switch (widget.type) {
                                case widgetTypes.IMAGE:
                                    return (
                                        <Image
                                            key={widget.id}
                                            widget={widget}
                                            isSelected={selectedWidget?.id === widget.id}
                                            onSelect={() => {
                                                selectWidget(widget);
                                            }}
                                            onSaveDragEndHistoryEvent={onSaveDragEndHistoryEvent}
                                        />
                                    );
                                case widgetTypes.TEXT:
                                    return (
                                        <Text
                                            key={widget.id}
                                            text={widget}
                                            isSelected={selectedWidget?.id === widget.id}
                                            onSelect={() => {
                                                selectWidget(widget);
                                            }}
                                            onSaveDragEndHistoryEvent={onSaveDragEndHistoryEvent}
                                        />
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </Layer>
                </Stage>
            </div>
            <ScrollableAssetsPanel
                assetType={assetType}
                stageRef={stageRef}
                handleSetAssetType={handleSetAssetType}
            />
        </>
    );
};

import moment from 'moment';
import { useSelector } from 'react-redux';

import { currentDepartmentDateFormatsSelector } from '../../domain/current-user/state/ui';
import { DateTimeFormatKeys } from '../utils/dateHelpers';

export const useFormattedDate = (date: moment.MomentInput, format: DateTimeFormatKeys) => {
    const dateTimeFormats = useSelector(currentDepartmentDateFormatsSelector);
    return date ? moment(date).format(dateTimeFormats[format]) : null;
};

/**
 * Returns a map with the date formatted in all formats that are provided in the "formats" parameter.
 * If the parameter is not present, then return a map with the date formatted in all available formats. 
 * @param date the date to be formatted.
 * @param formats the formats that will be present on the map.
 * @returns a map where the format is the key and the respective formatted date is the value.
 */
export const useFormattedDateWithMultipleFormats = (
    date: moment.MomentInput,
    formats: DateTimeFormatKeys[] = []
) => {
    const dateTimeFormats = useSelector(currentDepartmentDateFormatsSelector);
    const formatsToUse = formats.length
        ? formats
        : (Object.keys(dateTimeFormats) as DateTimeFormatKeys[]);
    return formatsToUse.reduce(
        (acc: { [id: string]: string | null }, curr: DateTimeFormatKeys) => ({
            ...acc,
            [curr]: date ? moment(date).format(dateTimeFormats[curr]) : null,
        }),
        {}
    );
};

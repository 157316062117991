import { HiddenDepartmentLinkType, LinkType } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import _, { map } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getHiddenDepartmentLinkTypesResource from '../../resources/hiddenDepartmentLinkTypesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'hiddenDepartmentLinkTypes';
const hiddenDepartmentLinkTypesModule = createNormalizedModule<HiddenDepartmentLinkType>({
    type: NEXUS_STATE_PROP,
});

// ACTION TYPES
const UPDATE_HIDDEN_DEPT_LINK_TYPES_START = 'UPDATE_HIDDEN_DEPT_LINK_TYPES_START';
const UPDATE_HIDDEN_DEPT_LINK_TYPES_SUCCESS = 'UPDATE_HIDDEN_DEPT_LINK_TYPES_SUCCESS';
const UPDATE_HIDDEN_DEPT_LINK_TYPES_FAILURE = 'UPDATE_HIDDEN_DEPT_LINK_TYPES_FAILURE';

const updateHiddenDepartmentLinkTypesStart = () => ({
    type: UPDATE_HIDDEN_DEPT_LINK_TYPES_START,
});

const updateHiddenDepartmentLinkTypesSuccess = () => ({
    type: UPDATE_HIDDEN_DEPT_LINK_TYPES_SUCCESS,
});

const updateHiddenDepartmentLinkTypesFailure = (errorMessage: string) => ({
    type: UPDATE_HIDDEN_DEPT_LINK_TYPES_FAILURE,
    payload: errorMessage,
});

// ACTIONS

export const updateHiddenDepartmentLinkTypes = (
    updatedLinkTypes: LinkType[]
): ClientCommonAction<Promise<HiddenDepartmentLinkType[]>> => (
    dispatch,
    getState,
    { nexus: { withEntityItems, withRemove } }
) => {
    const resource = getHiddenDepartmentLinkTypesResource();

    const hiddenDeptLinkTypes = _(updatedLinkTypes)
        .filter(['isVisible', false])
        .map((linkType) => ({ linkType: linkType.id }))
        .value();

    return resource
        .updateHiddenDepartmentLinkTypes(hiddenDeptLinkTypes)
        .then((response: HiddenDepartmentLinkType[]) => {
            dispatch(updateHiddenDepartmentLinkTypesSuccess());
            // clear all hidden link types from state before updating with response
            const withHiddenDepartmentLinkTypes = withRemove(
                NEXUS_STATE_PROP,
                {},
                updateHiddenDepartmentLinkTypesStart()
            );

            const replaceAction = withEntityItems(
                {
                    [NEXUS_STATE_PROP]: response,
                },
                withHiddenDepartmentLinkTypes
            );
            dispatch(replaceAction);
            return response;
        })
        .catch((err: Error) => {
            dispatch(updateHiddenDepartmentLinkTypesFailure(err.message));
            throw err;
        });
};

// SELECTORS
const hiddenDepartmentLinkTypesSelector =
    hiddenDepartmentLinkTypesModule.selectors.entitiesSelector;
export const hiddenDepartmentLinkTypeIdsSelector = createSelector(
    hiddenDepartmentLinkTypesSelector,
    (hiddenDepartmentLinkTypes) => map(hiddenDepartmentLinkTypes, ({ linkType }) => linkType)
);

// REDUCER
export default hiddenDepartmentLinkTypesModule.reducerConfig;

import { AttributeTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { chain, map, find, filter, sortBy, get } from 'lodash';

import { createFieldset, createFormConfiguration, _Form, createNItems } from 'markformythree';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import { convertOffenseCodeToStatuteCodeSetFilter } from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';

import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const createIncidentForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;

    return new _Form({
        name: RefContextEnum.FORM_INCIDENT.name,
        initialState,
        onValidate: createArbiterMFTValidationHandler(
            arbiter,
            RefContextEnum.FORM_INCIDENT.name,
            formatFieldByName
        ),
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            // UI-only toggle for filtering the dropdown options of offenseCodeId
            statuteCodeSetFilter: {
                fieldName: fields.DISPLAY_ONLY_OFFENSE_CODE_STATUTE_CODE_SET_FILTER,
            },
            incident: createFieldset({
                fields: {
                    id: {},
                    reportId: {},
                    offenseCodeId: { fieldName: fields.DISPLAY_OFFENSE_INCIDENT_TYPE_OFFENSE_CODE },
                },
            }),
            // meta data used in validations, not directly related to incident card model data
            links: createFieldset({
                fields: {
                    location: createFieldset({
                        fields: {
                            typeAttrId: {
                                fieldName:
                                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_TYPE_ATTR_ID,
                            },
                            linkType: {
                                fieldName:
                                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_LINK_TYPE,
                            },
                            positionAttrId: {
                                fieldName:
                                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_POSITION_ATTR_ID,
                            },
                            description: {
                                fieldName:
                                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_DESCRIPTION,
                            },
                        },
                    }),
                    // empty `NItems` so we have a place to put witnesses and other names,
                    // even though we are not going to modify them in the form.
                    // this allows us to easily access them when we submit the form
                    subjects: createNItems({
                        fields: {},
                    }),
                    witnesses: createNItems({
                        fields: {},
                    }),
                    otherNames: createNItems({
                        fields: {},
                    }),
                },
            }),
            offenseAttributes: createFieldset({
                fields: {
                    [AttributeTypeEnum.INCIDENT_STATISTIC.name]: createFieldset({
                        fields: {
                            attributeIds: {
                                fieldName:
                                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_INCIDENT_STATISTIC_ATTRIBUTE_ID,
                            },
                            description: {
                                fieldName:
                                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_INCIDENT_STATISTIC_DESCRIPTION,
                            },
                        },
                    }),
                },
            }),
        }),
    });
};

export const convertToFormModel = ({
    incident,
    offenseAttributes,
    nameReportLinks,
    locationLinks,
    offenseCode,
    statuteCodeSetAttributes,
    departmentSubDomain,
    fieldConfigurationContextByContextAndFieldName,
}) => {
    const offenseAttributesByType = chain(offenseAttributes)
        .groupBy('attributeType')
        .mapValues((attributesByType) => ({
            attributeIds: map(attributesByType, 'attributeId'),
            description: getDescriptionForAttributeLinks(attributesByType),
        }))
        .value();
    const sortedNameReportLinks = sortBy(nameReportLinks, 'linkTypeSequenceNumber');
    const subjects = filter(
        sortedNameReportLinks,
        (nameReportLink) => nameReportLink.linkType === LinkTypesEnum.SUBJECT_IN_OFFENSE
    );
    const witnesses = filter(
        sortedNameReportLinks,
        (nameReportLink) => nameReportLink.linkType === LinkTypesEnum.WITNESS_IN_OFFENSE
    );
    const otherNames = filter(
        sortedNameReportLinks,
        (nameReportLink) => nameReportLink.linkType === LinkTypesEnum.OTHER_NAME_IN_OFFENSE
    );
    const location = find(
        locationLinks,
        (locationLink) => locationLink.linkType === LinkTypesEnum.OFFENSE_LOCATION
    );
    const statuteCodeSetFilterFieldContext = fieldConfigurationContextByContextAndFieldName(
        RefContextEnum.FORM_INCIDENT.name,
        fields.DISPLAY_ONLY_OFFENSE_CODE_STATUTE_CODE_SET_FILTER
    );
    const statuteCodeSetFilter = convertOffenseCodeToStatuteCodeSetFilter(
        offenseCode,
        statuteCodeSetAttributes,
        departmentSubDomain,
        !!get(statuteCodeSetFilterFieldContext, 'isStaticallyHidden')
    );
    return {
        incident,
        statuteCodeSetFilter,
        offenseAttributes: offenseAttributesByType,
        links: {
            location,
            subjects,
            witnesses,
            otherNames,
        },
    };
};

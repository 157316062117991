import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { Form, Fieldset as MFTFieldset, lifecycleOptions } from 'markformythree';
import React from 'react';

import { findIndex, get, isEmpty } from 'lodash';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatAttributeWithOther } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import Row from '../../../../core/components/Row';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import IndentedFields from '../../../../core/components/IndentedFields';
import { ArbiterMFTBooleanButtonRadio } from '../../../../core/forms/components/button-radios/BooleanButtonRadio';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { FormConfigurationRenderer } from '../../../../core/markformythree-arbiter/dynamic-fields/components/FormConfigurationRenderer';
import { CUSTOM_PROPERTIES_KEY_NAME } from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';
import AddArrestReportButton from './AddArrestReportButton';

class OffenseSuspectFields extends React.PureComponent {
    render() {
        const { nameLinkIndex } = this.props;
        return (
            <Row>
                <MFTFieldset
                    path="links"
                    render={() => (
                        <MFTFieldset
                            path="suspects"
                            render={() => (
                                <MFTFieldset
                                    path={`[${nameLinkIndex}]`}
                                    render={() => (
                                        <>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="restrainingOrderEverIssued" />
                                            </Row>
                                            <IndentedFields>
                                                <Row>
                                                    <ArbiterMFTBooleanButtonRadio path="restrainingOrderViolated" />
                                                </Row>
                                            </IndentedFields>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="restrainingOrderIssued" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="clearedBySuicide" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="offenderLivedWithVictimAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.OFFENDER_HOUSEHOLD_STATUS
                                                            .name
                                                    }
                                                    length="lg"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="howAggressorWasIdentifiedAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum
                                                            .HOW_AGGRESSOR_WAS_IDENTIFIED.name
                                                    }
                                                    length="lg"
                                                />
                                            </Row>
                                            <IndentedFields>
                                                <Row>
                                                    <ArbiterMFTText
                                                        path="howAggressorWasIdentifiedOtherDescription"
                                                        length="lg"
                                                    />
                                                </Row>
                                            </IndentedFields>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="substanceAbuseAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.SUBSTANCE_ABUSE.name
                                                    }
                                                    length="lg"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio
                                                    path="wasSearchPerformed"
                                                    length="lg"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="offenderReligionAttrId"
                                                    attributeType={AttributeTypeEnum.RELIGION.name}
                                                    length="lg"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="wasStatementTaken" />
                                            </Row>
                                            <IndentedFields>
                                                <Row>
                                                    <ArbiterMFTTextArea
                                                        path="statement"
                                                        length="lg"
                                                    />
                                                </Row>
                                            </IndentedFields>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="suspectStatusAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.SUSPECT_STATUS.name
                                                    }
                                                    length="lg"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="suspectSuspectedDrugTypeAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.SUSPECTED_DRUG_TYPE.name
                                                    }
                                                    multiple={true}
                                                    length="lg"
                                                />
                                            </Row>
                                        </>
                                    )}
                                />
                            )}
                        />
                    )}
                />
            </Row>
        );
    }
}
export class OffenseSuspectAdditionalFields extends React.PureComponent {
    render() {
        const { nameLink, summaryMode, formatAttributeById, offenseFormIndex } = this.props;

        if (summaryMode) {
            return (
                <>
                    <SummaryList labelWidth={400} contentWidth={150}>
                        <SummaryRow
                            fieldName={fields.NAME_REPORT_LINK_RESTRAINING_ORDER_EVER_ISSUED}
                        >
                            {booleanToYesNo(nameLink.restrainingOrderEverIssued)}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.NAME_REPORT_LINK_RESTRAINING_ORDER_VIOLATED}>
                            {booleanToYesNo(nameLink.restrainingOrderViolated)}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.NAME_REPORT_LINK_RESTRAINING_ORDER_ISSUED}>
                            {booleanToYesNo(nameLink.restrainingOrderIssued)}
                        </SummaryRow>
                    </SummaryList>
                    <SummaryList labelWidth={150} contentWidth={400}>
                        <SummaryRow fieldName={fields.NAME_REPORT_LINK_CLEARED_BY_SUICIDE}>
                            {booleanToYesNo(nameLink.clearedBySuicide)}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.NAME_REPORT_LINK_OFFENDER_LIVED_WITH_VICTIM_ATTR_ID}
                        >
                            {formatAttributeById(nameLink.offenderLivedWithVictimAttrId)}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.NAME_REPORT_LINK_HOW_AGGRESSOR_WAS_IDENTIFIED_ATTR_ID}
                        >
                            {nameLink.howAggressorWasIdentifiedAttrId &&
                                formatAttributeWithOther(
                                    formatAttributeById(nameLink.howAggressorWasIdentifiedAttrId),
                                    nameLink.howAggressorWasIdentifiedOtherDescription,
                                    ' - '
                                )}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.NAME_REPORT_LINK_SUBSTANCE_ABUSE_ATTR_ID}>
                            {formatAttributeById(nameLink.substanceAbuseAttrId)}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_WAS_SEARCH_PERFORMED
                            }
                        >
                            {booleanToYesNo(nameLink.wasSearchPerformed)}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_OFFENDER_RELIGION_ATTR_ID
                            }
                        >
                            {formatAttributeById(nameLink.offenderReligionAttrId)}
                        </SummaryRow>
                        {!isEmpty(nameLink.statement) && (
                            <SummaryRow
                                fieldName={
                                    fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_WAS_STATEMENT_TAKEN
                                }
                            >
                                {booleanToYesNo(true)}
                            </SummaryRow>
                        )}
                        <SummaryRow
                            fieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_STATEMENT
                            }
                        >
                            {nameLink.statement}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_SUSPECT_STATUS_ATTR_ID
                            }
                        >
                            {formatAttributeById(nameLink.suspectStatusAttrId)}
                        </SummaryRow>

                        <Form
                            name={RefContextEnum.FORM_OFFENSE.name}
                            index={offenseFormIndex}
                            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                            render={(form) => {
                                const suspectData = get(form.getState(), 'model.links.suspects');
                                const nameLinkIndex = findIndex(suspectData, {
                                    nameId: nameLink.nameId,
                                });
                                if (nameLinkIndex <= -1) {
                                    return null;
                                }
                                return (
                                    <FormConfigurationRenderer
                                        form={form}
                                        absoluteFormPath={`links.suspects[${nameLinkIndex}].${CUSTOM_PROPERTIES_KEY_NAME}`}
                                        mode="SUMMARY"
                                    />
                                );
                            }}
                        />
                    </SummaryList>
                </>
            );
        }

        return (
            <>
                <Form
                    name={RefContextEnum.FORM_OFFENSE.name}
                    index={offenseFormIndex}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    render={(form) => {
                        const suspectData = get(form.getState(), 'model.links.suspects');
                        const nameLinkIndex = findIndex(suspectData, { nameId: nameLink.nameId });

                        // Only if we found the `nameLinkIndex` in our m43 form,
                        // should we attempt to render the corresponding form elements
                        return (
                            nameLinkIndex > -1 && (
                                <>
                                    <OffenseSuspectFields
                                        form={form}
                                        nameLinkIndex={nameLinkIndex}
                                    />
                                    <FormConfigurationRenderer
                                        form={form}
                                        absoluteFormPath={`links.suspects[${nameLinkIndex}].${CUSTOM_PROPERTIES_KEY_NAME}`}
                                        mode="FORM"
                                    />
                                </>
                            )
                        );
                    }}
                />
                <Row>
                    <AddArrestReportButton defendantId={nameLink.nameId} />
                </Row>
            </>
        );
    }
}

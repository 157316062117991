import { AttributeTypeEnum } from '@mark43/rms-api';
import { isEmpty } from 'lodash';

import { createSelector } from 'reselect';
import validationStrings from '~/client-common/core/strings/validationStrings';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { reportAttributesWhereSelector } from '~/client-common/core/domain/report-attributes/state/data';
import { isOffenseReportSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { currentReportIdSelector } from '../../selectors/reportSelectors';

/**
 * Returns a validation error message, if validation failed.
 * Otherwise, an empty string is returned.
 */
const routingLabelsRequiredValidationSelector = createSelector(
    applicationSettingsSelector,
    currentReportIdSelector,
    isOffenseReportSelector,
    reportAttributesWhereSelector,
    (applicationSettings, currentReportId, isOffenseReport, reportAttributesWhere) => {
        const RMS_REPORTS_ROUTING_LABELS_REQUIRED_ON_REPORT_APPROVAL_ENABLED =
            applicationSettings.RMS_REPORTS_ROUTING_LABELS_REQUIRED_ON_REPORT_APPROVAL_ENABLED;

        if (
            RMS_REPORTS_ROUTING_LABELS_REQUIRED_ON_REPORT_APPROVAL_ENABLED &&
            isOffenseReport(currentReportId)
        ) {
            const routingLabels = reportAttributesWhere({
                reportId: currentReportId,
                attributeType: AttributeTypeEnum.ROUTING_LABEL.name,
            });

            if (isEmpty(routingLabels)) {
                return validationStrings.submission.routingLabelsRequired;
            }
        }

        return '';
    }
);

export default routingLabelsRequiredValidationSelector;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withHandlers } from 'recompose';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../core/testIds';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';

import {
    connectWarrantsElasticWarrantsViewModelsSelector,
    connectWarrantsUiSelector,
    submitConnectWarrantsSearch,
    showConnectWarrantsSearch,
    selectWarrantFromConnectWarrantsSidePanel,
    continueConnectWarrantsSearch,
} from '../state/ui/connectWarrantsSidePanel';
import connectWarrantsSidePanelForm from '../state/forms/connectWarrantsSidePanelForm';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import ConnectWarrantsResults from './ConnectWarrantsResults';
import ConnectWarrantsSearchForm from './ConnectWarrantsSearchForm';

const strings = componentStrings.warrants.connectWarrants;

const context = { name: boxEnum.CONNECT_WARRANTS_SIDE_PANEL };

function _ConnectWarrantsSidePanel({
    ui: { showingResults, continuingSearch, totalCount, warrantSearchPosition },
    elasticWarrants,
    formModel,
    onClickQuickSearch,
    onClickDetailSearch,
    onClickBack,
    onClickWarrant,
    onClickMoreResults,
    currentUserDepartmentId,
}) {
    return showingResults ? (
        <ConnectWarrantsResults
            onClickBack={onClickBack}
            onClickWarrant={onClickWarrant}
            onClickMoreResults={onClickMoreResults}
            continuingSearch={continuingSearch}
            elasticWarrants={elasticWarrants}
            totalCount={totalCount}
            warrantSearchPosition={warrantSearchPosition}
            currentUserDepartmentId={currentUserDepartmentId}
        />
    ) : (
        <ConnectWarrantsSearchForm
            onClickQuickSearch={onClickQuickSearch}
            onClickDetailSearch={onClickDetailSearch}
            formModel={formModel}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    ui: connectWarrantsUiSelector,
    elasticWarrants: connectWarrantsElasticWarrantsViewModelsSelector,
    formModel: connectWarrantsSidePanelForm.selectors.formModelSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
});

function mapDispatchToProps(dispatch) {
    return {
        onClickWarrant: (warrantId) =>
            dispatch(selectWarrantFromConnectWarrantsSidePanel(warrantId)),
        onClickQuickSearch: (options) => dispatch(submitConnectWarrantsSearch(options)),
        onClickDetailSearch: (options) => dispatch(submitConnectWarrantsSearch(options)),
        onClickBack: () => dispatch(showConnectWarrantsSearch()),
        onClickMoreResults: () => dispatch(continueConnectWarrantsSearch()),
    };
}

const ConnectWarrantsSidePanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withHandlers({
        onClickQuickSearch: (props) => () => {
            const options = {
                isQuickSearch: true,
                searchOnlyCurrentDepartment: false,
            };

            return props.onClickQuickSearch(options);
        },
        onClickDetailSearch: (props) => () => {
            const options = {
                isQuickSearch: false,
                searchOnlyCurrentDepartment: false,
            };

            return props.onClickQuickSearch(options);
        },
    })(_ConnectWarrantsSidePanel)
);

const ConnectWarrantsSidePanelWrapper = ({ onSave }) => (
    <SidePanel
        title={strings.title}
        context={context}
        onSave={onSave}
        saveText={null}
        testId={testIds.CONNECT_WARRANT_SIDE_PANEL}
    >
        <ConnectWarrantsSidePanel />
    </SidePanel>
);

export default ConnectWarrantsSidePanelWrapper;

import { Court } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'courts';

const courtsModule = createNormalizedModule<Court>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// SELECTORS
export const courtsSelector = courtsModule.selectors.entitiesSelector;
export const courtsByIdSelector = courtsModule.selectors.entityByIdSelector;
export const courtsWhereSelector = courtsModule.selectors.entitiesWhereSelector;

export default courtsModule.reducerConfig;

import { ItemAttribute } from '@mark43/rms-api';

import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'itemAttributes';
const itemAttributeModule = createLinkModule<ItemAttribute>({
    type: NEXUS_STATE_PROP,
    keys: ['itemProfileId', 'attributeId', 'attributeType'],
});

// SELECTORS
export const itemAttributesWhereSelector = itemAttributeModule.selectors.linksWhereSelector;

// REDUCER
export default itemAttributeModule.reducerConfig;

import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import testIds from '../../../../core/testIds';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    border-top: ${(props) =>
        props.noDivider ? 'none' : `2px solid ${props.theme.colors.extraLightGrey}`};
    margin: ${(props) => (props.noDivider ? 0 : '20px 0')};
    padding: 20px 0 0 0;
`;

const Column1 = styled.h3`
    flex: 0 0 auto;
    margin-right: 6px;
`;

const Column2 = styled.div`
    flex: 1 1 auto;
`;

export function EntityProfileDetailSummaryList({ labelWidth = 120, contentWidth = 200, children }) {
    return (
        <SummaryList
            className="entity-profile-summary-detail"
            labelWidth={labelWidth}
            contentWidth={contentWidth}
        >
            {children}
        </SummaryList>
    );
}

export function EntityProfileSubdetailSummaryList({
    labelWidth = 'auto',
    contentWidth = 100,
    children,
}) {
    return (
        <SummaryList
            className="entity-profile-summary-subdetail"
            labelWidth={labelWidth}
            contentWidth={contentWidth}
        >
            {children}
        </SummaryList>
    );
}

export function EntityProfileSummaryColumn({ className, children }) {
    return <div className={classNames('entity-profile-summary-column', className)}>{children}</div>;
}

export default function EntityProfileSection({ title = '', noDivider = false, children }) {
    return (
        <Row noDivider={noDivider} data-test-id={testIds.MASTER_PROFILE_CONTENT}>
            <Column1 className="entity-profile-section-title">{title}</Column1>
            <Column2 className="entity-profile-section-content">{children}</Column2>
        </Row>
    );
}

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_URC_ITEM_LABEL,
    UCR_OFFENSE_UCR_CODE,
} from '~/client-common/core/enums/universal/fields';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFNItems } from '../../../core/forms/components/NItems';

import ucrClassificationForm from '../state/forms/ucrClassificationForm';
import { prefillUcrClassification } from '../state/ui';
import testIds from '../../../../core/testIds';
import _PrefillButton from './PrefillButton';
import UcrOffenseFieldset from './UcrOffenseFieldset';
import UcrEventFieldset from './UcrEventFieldset';
import UcrPropertyFieldset from './UcrPropertyFieldset';

const strings = componentStrings.reports.UcrClassificationSidePanel;

const HeaderDiv = styled.div`
    margin-top: 20px;
`;

const PrefillButton = styled(_PrefillButton)`
    float: right;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 10px;
`;

export default connect(
    createStructuredSelector({
        formModelByPath: ucrClassificationForm.selectors.formModelByPathSelector,
    }),
    { onClickPrefill: prefillUcrClassification }
)(function UcrClassificationSidePanelForm({ formModelByPath, onClickPrefill }) {
    const {
        UCR_OFFENSE_UCR_CODE: urcOffenseLabel,
        DISPLAY_ONLY_URC_ITEM_LABEL: urcLabel,
    } = useFields([UCR_OFFENSE_UCR_CODE, DISPLAY_ONLY_URC_ITEM_LABEL]);
    return (
        <ReactReduxForm {...ucrClassificationForm}>
            <PrefillButton onClick={onClickPrefill} />
            <UcrEventFieldset formModelByPath={formModelByPath} />
            <HeaderDiv className={'mark43-fieldset-header'}>{urcOffenseLabel}</HeaderDiv>
            <RRFNItems
                path="ucrOffenses"
                addText={urcOffenseLabel}
                addItemOnDirty={false}
                addItemOnEmpty={false}
                automaticallyIncludeDeleteButton={false}
            >
                {(fields, ucrOffenseIndex, deleteButton) => (
                    <UcrOffenseFieldset
                        fields={fields}
                        ucrOffenseIndex={ucrOffenseIndex}
                        deleteButton={deleteButton}
                        formModelByPath={formModelByPath}
                    />
                )}
            </RRFNItems>
            <HeaderDiv className={'mark43-fieldset-header'}>
                {strings.UcrPropertyFieldset.title}
            </HeaderDiv>
            <div data-test-id={testIds.ITEMS_AND_PROPERTY_SECTION}>
                <RRFNItems
                    path="ucrProperty"
                    addText={urcLabel}
                    addItemOnDirty={false}
                    addItemOnEmpty={false}
                    automaticallyIncludeDeleteButton={false}
                >
                    {(fields, ucrPropertyIndex, deleteButton) => (
                        <UcrPropertyFieldset
                            fields={fields}
                            ucrPropertyIndex={ucrPropertyIndex}
                            deleteButton={deleteButton}
                            formModelByPath={formModelByPath}
                        />
                    )}
                </RRFNItems>
            </div>
        </ReactReduxForm>
    );
});

import { PrintingDataTypeEnum } from '@mark43/rms-api';
import isPrintableOfPrintingDataType from './isPrintableOfPrintingDataType';

import type { PacketOption } from './exportHelpers';

export default function isFolderPacket(packetOption: PacketOption): boolean {
    return packetOption.rawPacket
        ? isPrintableOfPrintingDataType(
              packetOption.rawPacket,
              PrintingDataTypeEnum.PARENT_FOLDER.name
          )
        : false;
}

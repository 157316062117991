let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Street Segment Resource',
        methods: {
            upsertStreetSegment(segment) {
                return req({
                    method: 'POST',
                    url: 'master/locations/street_centerline/segment',
                    data: segment,
                });
            },
            upsertStreetSegmentAcrossConsortium(segment) {
                return req({
                    method: 'POST',
                    url: 'master/locations/street_centerline/segment_across_consortium',
                    data: segment,
                });
            },
            deleteStreetSegment(segmentId) {
                return req({
                    method: 'DELETE',
                    url: `master/locations/street_centerline/segment/${segmentId}`,
                });
            },
            /**
             * Export all Street Segments into a CSV.
             * @return {Promise}
             */
            exportStreetSegments() {
                return req({
                    method: 'GET',
                    url: 'master/locations/street_centerline/segment/export',
                });
            },

            /**
             * Validate that a file is valid for importing new Street Segments.
             * @return {Promise}
             */
            validateStreetSegmentImport(fileId) {
                return req({
                    method: 'POST',
                    url: `master/locations/street_centerline/segment/import/validate/${fileId}`,
                });
            },

            /**
             * Import Street Segments from an uploaded file.
             * @return {Promise}
             */
            importStreetSegments(fileId) {
                return req({
                    method: 'POST',
                    url: `master/locations/street_centerline/segment/import/${fileId}`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import { RefContextEnum } from '@mark43/rms-api';
import { lifecycleOptions } from 'markformythree';
import React from 'react';
import { initialState, formConfiguration } from '../../state/forms/releaseForm';
import Row from '../../../../core/components/Row';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';

const ReleaseForm = () => (
    <ArbiterForm
        context={RefContextEnum.FORM_EVIDENCE_DISPOSITION_PRIMARY_APPROVAL_FOR_RELEASE.name}
        configuration={formConfiguration}
        initialState={initialState}
        name={RefContextEnum.FORM_EVIDENCE_DISPOSITION_PRIMARY_APPROVAL_FOR_RELEASE.name}
        lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
        render={() => (
            <Row>
                <ArbiterMFTTextArea path="comment" rows={6} />
            </Row>
        )}
    />
);

export default ReleaseForm;

import shortcutCommandEnum from '../enums/universal/shortcutCommandEnum';
import { prettify } from '../../helpers/stringHelpers';

export function prettifyTitle(title) {
    switch (title) {
        /**
         * Instead of changing the 'Command Line Compact Toggle' preference in the DB
         * Per https://mark43.atlassian.net/browse/CAD-8883
         */
        case shortcutCommandEnum.COMMAND_LINE_COMPACT_TOGGLE.name:
            return 'Toggle Compact Mode';
        default:
            return prettify(title);
    }
}

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const cadCallTakerStationsAdminFormFieldViewModels = {
    cadCallTakerStation: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadCallTakerStation',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'displayValue',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'integrationAlias',
                validators: {},
            },
            {
                key: 'startDateUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'endDateUtc',
                validators: {},
            },
        ]),
    },
};

function convertToFormModel({ cadCallTakerStation = {} }) {
    return {
        cadCallTakerStation: {
            ...cadCallTakerStation,
        },
    };
}

function convertFromFormModel({ cadCallTakerStation = {} }) {
    return { cadCallTakerStation };
}

/**
 * Module of the cad admin form.
 * @type {Object}
 */
const formModule = createFormModule({
    formName: formClientEnum.CAD_CALL_TAKER_STATIONS_ADMIN,
    fieldViewModels: cadCallTakerStationsAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

/**
 * Module of the cad rotation list admin form.
 * @type {Object}
 */
export default formModule;

export function validate() {
    return (dispatch, getState) => {
        dispatch(formModule.actionCreators.validate());
        const isValid = formModule.selectors.formIsValidSelector(getState());
        return !isValid;
    };
}

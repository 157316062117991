/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, filter, isArray, ListIterateeCustom } from 'lodash';
import { createSelector } from 'reselect';
import invariant from 'invariant';
import { createWithEntityItems } from 'nexus-mark43';
import { createWithRemove, linkModuleCompatStrategy } from 'probes-mark43';
import type { LinkedModuleShape, RootState } from '../../redux/state';

const withRemove = createWithRemove({ key: 'config' });
const withEntityItems = createWithEntityItems({ key: 'items' });

export type { LinkedModuleShape };

// a cache of the names of existing modules, used to ensure that no duplicates
// are created
const existingModules: Record<string, boolean> = {};

/**
 * creates a module for links (eg, nameItemLink) and returns actions, selectors and a reducer
 * @param   options.type    actionType, often the snake case of the link type, eg, NAME_ITEM_LINK
 * @param   options.keys   The keys to create a unique hash to determine uniqueness
 * @return                  action creators and data reducer
 */
export default function createLinkModule<T>({ type, keys }: { type: string; keys: string[] }) {
    invariant(!existingModules[type], "A link module for the specified 'type' already exists");

    // for a detailed explanation why the below is necessary
    // refer to `client-common/core/utils/createNormalizedModule.js`
    const defaultEmpty: T[] = [];
    const safeBaseSelector = createSelector(
        (state: RootState) =>
            // @ts-expect-error TODO: Change `type` from string to a union of the strings defined in RootState
            state.dataNexus[type] as { global: LinkedModuleShape<T> },
        (value) => get(value, 'global', defaultEmpty)
    );
    const actionTypes = createActionTypes(type);
    const actionCreators = createActionCreators<T>(type, actionTypes);
    const selectors = createSelectors<T>(safeBaseSelector);
    const linkMerge = linkModuleCompatStrategy({
        keys,
        metaKey: 'meta',
        tagsKey: 'tags',
    });
    const reducerConfig = {
        [type]: {
            indexStrategy: linkMerge.indexStrategy,
            mergeStrategy: linkMerge,
        },
    };

    return {
        actionCreators,
        selectors,
        reducerConfig,
        withEntityItems,
        withRemove,
    };
}

function createActionTypes(type: string) {
    return {
        setLinks: `core/SET_${type}`,
        storeLink: `core/STORE_${type}`,
        replaceLinksWhere: `core/REPLACE_${type}_LINKS_WHERE`,
        removeLinksWhere: `core/REMOVE_${type}_LINKS_WHERE`,
    };
}

function createActionCreators<T>(type: string, actionTypes: ReturnType<typeof createActionTypes>) {
    return {
        setLinks: (links: T[] | T = []) =>
            withEntityItems(
                {
                    [type]: isArray(links) ? links : [links],
                },
                withRemove(type, {}, { type: actionTypes.setLinks })
            ),
        storeLinks: (links: T[] | T = []) =>
            withEntityItems(
                {
                    [type]: isArray(links) ? links : [links],
                },
                { type: actionTypes.storeLink }
            ),
        replaceLinksWhere: (predicate: ListIterateeCustom<T, boolean>, newLinks: T[] | T = []) =>
            withEntityItems(
                {
                    [type]: isArray(newLinks) ? newLinks : [newLinks],
                },
                withRemove(type, predicate, { type: actionTypes.replaceLinksWhere })
            ),
        removeLinksWhere: (predicate: ListIterateeCustom<T, boolean>) =>
            withRemove(type, predicate, { type: actionTypes.removeLinksWhere }),
    };
}

function createSelectors<T>(baseSelector: (state: RootState) => LinkedModuleShape<T>) {
    return {
        linksSelector: baseSelector,
        linksWhereSelector: createSelector(
            baseSelector,
            (links) => (predicate: ListIterateeCustom<T, boolean>) => filter(links, predicate)
        ),
    };
}

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';

import { locationUpdatedChainEventTypeSelector } from '~/client-common/core/domain/chain-event-types/state/data';
import { formatChainEventTypeByIdSelector } from '~/client-common/core/domain/chain-event-types/state/ui';
import { formatFacilityByIdSelector } from '~/client-common/core/domain/facilities/state/ui';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';

import { currentUserIdSelector } from '../../../core/current-user/state/ui';
import {
    ChainEventWrapper as _ChainEventWrapper,
    CornerDot as _CornerDot,
    ChainEventItemHeader,
    Bold,
    ChainEventItemSubheader,
} from '../../../evidence/chain-of-custody/components/ChainOfCustody';
import { useDateTimeFormatter } from '../../../core/current-user/hooks/dateTimeFormats';

const PreviewWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.lightBlue};
    margin-top: 15px;
    padding: 15px 20px;
`;
const ChainEventWrapper = styled(_ChainEventWrapper)`
    padding-bottom: 0;
    max-width: none;
`;
const CornerDot = styled(_CornerDot)`
    outline: 2px solid ${(props) => props.theme.colors.lightBlue};
`;

function LocationUpdatedChainEventPreview({
    formattedLocationUpdatedChainEventType,
    facilityName,
    currentUserName,
}) {
    const dateTimeFormatter = useDateTimeFormatter();
    const date = dateTimeFormatter.formatDateTime(nowUtc());

    return (
        <PreviewWrapper>
            <ChainEventWrapper>
                <CornerDot />
                <ChainEventItemHeader>
                    <Bold>{formattedLocationUpdatedChainEventType}</Bold>
                    {' within '}
                    <Bold>{facilityName}</Bold>
                    {' by '}
                    <Bold>{currentUserName}</Bold>
                </ChainEventItemHeader>
                <ChainEventItemSubheader>{date}</ChainEventItemSubheader>
            </ChainEventWrapper>
        </PreviewWrapper>
    );
}

const formatLocationUpdatedChainEventTypeSelector = createSelector(
    locationUpdatedChainEventTypeSelector,
    formatChainEventTypeByIdSelector,
    (locationUpdatedChainEventType, formatChainEventTypeById) =>
        formatChainEventTypeById(locationUpdatedChainEventType.id)
);
const currentUserNameSelector = createSelector(
    formatMiniUserByIdSelector,
    currentUserIdSelector,
    (formatMiniUserById, currentUserId) => formatMiniUserById(currentUserId)
);

const mapStateToProps = createStructuredSelector({
    formattedLocationUpdatedChainEventType: formatLocationUpdatedChainEventTypeSelector,
    formatFacilityById: formatFacilityByIdSelector,
    currentUserName: currentUserNameSelector,
});

/**
 * A preview of the Chain of Custody that will be created for the items
 *   as a result of a storage location move.
 *   As of Dec 5, 2017, only used in MoveStorageModal.
 * @param  {facilityId} number facility within which location move is occurring
 */
export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['formatFacilityById', 'facilityId'],
        ({ formatFacilityById, facilityId }) => ({
            facilityName: formatFacilityById(facilityId),
        })
    )
)(LocationUpdatedChainEventPreview);

import React from 'react';
import { Table as ArcTable, Thead, Tbody } from 'arc';

export { Td as DashboardTd, Tr as DashboardTr, Th as DashboardTh } from 'arc';

export const DashboardTable = ({ data, renderRow, renderHeader, ...props }) => {
    return (
        <ArcTable style={{ fontSize: 'var(--arc-fontSizes-md)' }} {...props}>
            <Thead style={{ fontSize: 'var(--arc-fontSizes-sm)' }}>{renderHeader()}</Thead>
            <Tbody>{data.map((row, index) => renderRow(row, index))}</Tbody>
        </ArcTable>
    );
};

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import styled from 'styled-components';
import { get } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { createModalSelector } from '../../../core/box/state/ui';

import {
    CONSORTIUM_ADMIN_REMOVE_DEPARTMENT_MODAL_CONTEXT,
    removeDepartmentFromConsortiumViaModal,
} from '../state/ui';

const strings = componentStrings.admin.consortium.ConsortiumAdminRemoveDepartmentModal;

const ModalText = styled.div`
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

class ConsortiumAdminRemoveDepartmentModal extends Component {
    onSave = () =>
        this.props.removeDepartmentFromConsortiumViaModal(
            get(this.props.removeDepartmentFromConsortiumData, 'consortiumId'),
            get(this.props.removeDepartmentFromConsortiumData, 'departmentId'),
            this.props.router
        );

    render() {
        return (
            <Modal
                context={CONSORTIUM_ADMIN_REMOVE_DEPARTMENT_MODAL_CONTEXT}
                title={strings.modalTitle}
                shouldCloseOnOverlayClick={true}
                okText={strings.modalOk}
                onSave={this.onSave}
            >
                <ModalText>{strings.modalContent}</ModalText>
            </Modal>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    removeDepartmentFromConsortiumData: createModalSelector(
        CONSORTIUM_ADMIN_REMOVE_DEPARTMENT_MODAL_CONTEXT,
        'removeDepartmentFromConsortiumData'
    ),
});

const mapDispatchToProps = { removeDepartmentFromConsortiumViaModal };

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConsortiumAdminRemoveDepartmentModal);

import { filter, chain } from 'lodash';
import { createSelector } from 'reselect';
import { ReportCaseStatus, ReportShortTitle } from '@mark43/rms-api';

import getReportCaseStatusesResource from '../../resources/reportCaseStatusResource';
import createNormalizedModule, { ModuleShape } from '../../../../utils/createNormalizedModule';
import { reportShortTitleByReportIdSelector } from '../../../report-short-titles/state/data';
import { reportsSelector } from '../../../reports/state/data';
import {
    reportDefinitionHasReportCaseStatusSelector,
    getReportIdAndReportDefinitionIds,
} from '../../../report-definitions/state/data';
import { normalize } from '../../../../../helpers/dataHelpers';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'reportCaseStatuses';
const reportCaseStatusesModule = createNormalizedModule<ReportCaseStatus>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const reportCaseStatusesSelector = reportCaseStatusesModule.selectors.entitiesSelector;
export const reportCaseStatusesWhereSelector =
    reportCaseStatusesModule.selectors.entitiesWhereSelector;

export const reportHasReportCaseStatusByReportIdSelector = createSelector(
    reportsSelector,
    reportShortTitleByReportIdSelector,
    reportDefinitionHasReportCaseStatusSelector,
    (reports, reportShortTitleByReportId, reportDefinitionHasReportCaseStatus) => (
        reportId: number
    ) => {
        // during case creation, the report we need to check may not be in data state,
        // so we look to short titles
        const report = reports[reportId];
        const reportShortTitle = reportShortTitleByReportId(reportId);
        const { reportDefinitionId } = report || reportShortTitle || {};
        return !!reportDefinitionId && reportDefinitionHasReportCaseStatus(reportDefinitionId);
    }
);

export const reportIdsForHasReportCaseStatusEnabledSelector = createSelector(
    reportsSelector,
    reportDefinitionHasReportCaseStatusSelector,
    (reports, reportDefinitionHasReportCaseStatus) => {
        const reportShortTitles: ModuleShape<ReportShortTitle> = {};
        const reportIdAndReportDefinitionIds = getReportIdAndReportDefinitionIds(
            reports,
            reportShortTitles
        );

        const reportIds = chain(reportIdAndReportDefinitionIds)
            .filter(({ reportDefinitionId }) =>
                reportDefinitionHasReportCaseStatus(reportDefinitionId)
            )
            .map('reportId')
            .value();

        return reportIds;
    }
);

/**
 * It's possible to have reportCaseStatuses for reports that don't allow report case statuses
 */
export const reportCaseStatusesForValidReportsSelector = createSelector(
    reportCaseStatusesSelector,
    reportHasReportCaseStatusByReportIdSelector,
    (reportCaseStatuses, reportHasReportCaseStatusByReportId) => {
        const filteredReportCaseStatuses = filter(reportCaseStatuses, (reportCaseStatus) => {
            // during case creation, the report we need to check may not be in data state,
            // so we look to short titles
            return reportHasReportCaseStatusByReportId(reportCaseStatus.reportId);
        });
        return normalize(filteredReportCaseStatuses);
    }
);

// ACTIONS
const storeReportCaseStatuses = reportCaseStatusesModule.actionCreators.storeEntities;

export function loadReportCaseStatuses(
    reportId: number
): ClientCommonAction<Promise<ReportCaseStatus[]>> {
    return function (dispatch) {
        // load case status data models from the server
        const resource = getReportCaseStatusesResource();
        return resource
            .getCaseStatusesForReportId(reportId)
            .then((reportCaseStatuses: ReportCaseStatus[]) => {
                dispatch(storeReportCaseStatuses(reportCaseStatuses));
                return reportCaseStatuses;
            });
    };
}

export function saveReportCaseStatuses(
    reportCaseStatuses: ReportCaseStatus[]
): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        const resource = getReportCaseStatusesResource();
        return resource.saveCaseStatuses(reportCaseStatuses).then(() => {
            dispatch(storeReportCaseStatuses(reportCaseStatuses));
        });
    };
}

// REDUCER
export default reportCaseStatusesModule.reducerConfig;

import { map, debounce } from 'lodash';

import { Attribute, AttributeTypeEnumType } from '@mark43/rms-api';

// helpers
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';

import { DeferredPromise, deferredPromise, retry } from '../../../utils/promiseHelpers';
import { DependenciesArg, RmsAction, RmsDispatch } from '../../../../../core/typings/redux';
import attributesResource from '../../resources/attributesResource';

import {
    LOAD_ATTRIBUTES_BEGIN,
    LOAD_ATTRIBUTES_SUCCESS,
    LOAD_ATTRIBUTES_FAILURE,
} from './actionTypes';

export const loadAttributesBegin = (attributeTypes: AttributeTypeEnumType[]) => ({
    type: LOAD_ATTRIBUTES_BEGIN,
    payload: {
        attributeTypes,
    },
});

export const loadAttributesSuccess = (attributeTypes: AttributeTypeEnumType[]) => ({
    type: LOAD_ATTRIBUTES_SUCCESS,
    payload: {
        attributeTypes,
    },
});

export const loadAttributesFailure = (attributeTypes: AttributeTypeEnumType[]) => ({
    type: LOAD_ATTRIBUTES_FAILURE,
    payload: {
        attributeTypes,
    },
});

let attributeTypesToLoad: AttributeTypeEnumType[] = [];
let deferredAttributeFetchPromise: DeferredPromise<Attribute[]> | undefined;

/**
 * For usage in tests only!!
 */
export const resetAttributeFetchInternals = () => {
    if (process.env.NODE_ENV !== 'test') {
        throw new Error('`resetAttributeFetchInternals` can only be used within tests');
    }
    attributeTypesToLoad = [];
    deferredAttributeFetchPromise = undefined;
};

const debouncedAttributeFetch: (
    dispatch: RmsDispatch,
    dependencies: DependenciesArg,
    deferred: DeferredPromise<Attribute[]>,
    numRetries: number,
    includeDisabled: boolean
) => Promise<Attribute[]> = debounce(
    (dispatch, dependencies, deferred, numRetries, includeDisabled) => {
        deferredAttributeFetchPromise = undefined;
        const typesToLoad = attributeTypesToLoad.slice();
        attributeTypesToLoad = [];
        dispatch(loadAttributesBegin(typesToLoad));
        return retry(
            () =>
                attributesResource.loadAttributesForType({
                    attributeType: typesToLoad,
                    // always include expire attributes for now
                    includeExpired: true,
                    // always include parent attributes
                    includeParents: true,
                    includeDisabled,
                }),
            { retries: numRetries }
        )
            .then((result) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                                result,
                                convertAttributeToAttributeView
                            ),
                        },
                        loadAttributesSuccess(typesToLoad)
                    )
                );
                deferred.resolve(result);
                return result;
            })
            .catch((err: Error) => {
                dispatch(loadAttributesFailure(typesToLoad));
                deferred.reject(err);
            });
    },
    15
);

interface LoadAttributesForTypePropsT {
    attributeType: AttributeTypeEnumType[] | AttributeTypeEnumType;
    numRetries?: number;
    includeDisabled?: boolean;
}

export const loadAttributesForType = ({
    attributeType,
    numRetries = 2,
    includeDisabled = false,
}: LoadAttributesForTypePropsT): RmsAction<Promise<Attribute[]>> => {
    return (dispatch, getState, dependencies) => {
        if (!deferredAttributeFetchPromise) {
            deferredAttributeFetchPromise = deferredPromise();
        }
        const previousPromise = deferredAttributeFetchPromise;
        attributeTypesToLoad = attributeTypesToLoad.concat(attributeType);
        debouncedAttributeFetch(
            dispatch,
            dependencies,
            deferredAttributeFetchPromise,
            numRetries,
            includeDisabled
        );
        return previousPromise.promise;
    };
};

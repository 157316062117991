import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import _ResultsTable from '../components/ResultsTable';

const SearchResults = ({
    TableHeader,
    TableBody,
    TableFooter,
    rowComponent,
    className,
    columnSpan,
    handleClearAllSelectedRows,
    handlePaginationClickAction,
    handleRowClick,
    handleRowClickAction,
    handleSelectAllRows,
    handleSelectRowsAction,
    handleSizeChangeAction,
    handleSortKeyClickAction,
    handleSortTypeClickAction,
    queryFromSelector,
    queryResultsSelector,
    querySizeSelector,
    querySortKeySelector,
    querySortTypeSelector,
    showTableHeader,
    selectableRows,
    selectedRowsSelector,
    totalResultsSelector,
    tableCheckboxMargin = '10px',
    SearchForm,
}) => {
    const mapStateToProps = createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
        queryFrom: queryFromSelector,
        querySize: querySizeSelector,
        querySortKey: querySortKeySelector,
        querySortType: querySortTypeSelector,
        queryResults: queryResultsSelector,
        selectedRows: selectedRowsSelector,
        totalResults: totalResultsSelector,
    });

    // TODO - we should refactor these functions:
    // Event handlers passed in should NOT be assumed to be action-creators, this handler requirement
    // is too opinionated in such that, the user of this component should be responsible for that 'definition'
    const mapDispatchToProps = (dispatch) => ({
        handleSortKeyClick({ value, sortOptions }) {
            // Relevance search has a hidden sortType that must be included
            let sortType = sortOptions ? sortOptions[0].sortType : undefined;
            if (value === sortKeyEnum.ELASTICSEARCH_SCORE) {
                // There's a hidden secondary date acquired sort if we're searching by relevance
                sortType = sortTypeEnum.RELEVANCE_MOST_TO_LEAST;
            }
            dispatch(
                handleSortKeyClickAction({
                    sortKey: value,
                    sortType,
                })
            );
        },
        handleSortTypeClick(sortType) {
            dispatch(handleSortTypeClickAction({ sortType }));
        },
        handlePaginationClick(nextPage, querySize) {
            if (handlePaginationClickAction) {
                dispatch(
                    handlePaginationClickAction(
                        {
                            from: nextPage * querySize - querySize,
                            querySize,
                        },
                        { cacheBust: true }
                    )
                );
            }
        },

        handleRowClick(elasticReport, rowIndex) {
            if (handleRowClick) {
                handleRowClick(elasticReport, rowIndex);
            }

            if (handleRowClickAction) {
                dispatch(handleRowClickAction(elasticReport, rowIndex));
            }
        },
        handleSelectRows(newSelectedRows) {
            if (handleSelectRowsAction) {
                dispatch(handleSelectRowsAction(newSelectedRows));
            }
        },
        handleSizeChange(size) {
            if (handleSizeChangeAction) {
                dispatch(handleSizeChangeAction({ from: 0, size }));
            }
        },
        handleSelectAllRows() {
            if (handleSelectAllRows) {
                dispatch(handleSelectAllRows());
            }
        },
        handleClearAllSelectedRows() {
            if (handleClearAllSelectedRows) {
                dispatch(handleClearAllSelectedRows([]));
            }
        },
    });

    const ResultsTable = connect(mapStateToProps, mapDispatchToProps)(_ResultsTable);

    return (
        <ResultsTable
            TableHeader={TableHeader}
            TableBody={TableBody}
            TableFooter={TableFooter}
            rowComponent={rowComponent}
            columnSpan={columnSpan}
            className={className}
            selectableRows={selectableRows}
            showTableHeader={showTableHeader}
            tableCheckboxMargin={tableCheckboxMargin}
            SearchForm={SearchForm}
        />
    );
};

SearchResults.propTypes = {
    TableHeader: PropTypes.func.isRequired,
    TableBody: PropTypes.func.isRequired,
    TableFooter: PropTypes.func,
    columnSpan: PropTypes.number,
    handleClearAllSelectedRows: PropTypes.func,
    handlePaginationClickAction: PropTypes.func,
    handleRowClick: PropTypes.func,
    handleRowClickAction: PropTypes.func,
    handleSelectAllRows: PropTypes.func,
    handleSelectRowsAction: PropTypes.func,
    handleSizeChangeAction: PropTypes.func,
    handleSortKeyClickAction: PropTypes.func,
    handleSortTypeClickAction: PropTypes.func,
    queryFromSelector: PropTypes.func,
    queryResultsSelector: PropTypes.func.isRequired,
    querySizeSelector: PropTypes.func,
    querySortKeySelector: PropTypes.func.isRequired,
    querySortTypeSelector: PropTypes.func.isRequired,
    selectedRowsSelector: PropTypes.func,
    showTableHeader: PropTypes.bool,
    totalResultsSelector: PropTypes.func,
    SearchForm: PropTypes.func,
};

SearchResults.defaultProps = {
    TableFooter: noop,
    columnSpan: 0,
    handleRowClick: noop,
    handleSortKeyClickAction: noop,
    handleSortTypeClickAction: noop,
    queryFromSelector: noop,
    querySizeSelector: noop,
    selectedRowsSelector: noop,
    showTableHeader: true,
    totalResultsSelector: noop,
    SearchForm: undefined,
};

export default SearchResults;

import { Styles as ReactModalStyles } from 'react-modal';
import { defaultContentStyles, defaultOverlayStyles } from '../configuration';

interface ComputeStyleProps {
    hideFooter?: boolean;
    modalStyle?: React.CSSProperties;
    overlayStyle?: React.CSSProperties;
}

export function computeStyle(props: ComputeStyleProps): ReactModalStyles {
    return {
        content: {
            ...defaultContentStyles,
            marginBottom: props.hideFooter ? 0 : undefined,
            ...props.modalStyle,
        },
        overlay: {
            ...defaultOverlayStyles,
            ...props.overlayStyle,
        },
    };
}

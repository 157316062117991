import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';
import styled from 'styled-components';

import { reportShortTitleViewModelsWhereSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import { renSearchUiSelector } from '../../../../legacy-redux/selectors/renSearchModalSelectors';
import LinkedReportsSection from '../../../../legacy-redux/components/reports/LinkedReportsSection';

const strings = componentStrings.warrants.warrant.WarrantRenSearchResults;

const NoRenString = styled.div`
    float: left;
    width: 400px;
`;

const ResultSection = styled.div`
    margin-left: 20px;
`;

function WarrantRenSearchResults({
    renSearchUi: { hasFinishedSearch, enteredReportingEventNumber },
    reportShortTitleViewModels,
    renDisplayName,
}) {
    const resultSection = hasFinishedSearch ? (
        <div>
            {reportShortTitleViewModels.length > 0 ? (
                <LinkedReportsSection
                    title={strings.relatedReports(renDisplayName, enteredReportingEventNumber)}
                    reportShortTitleViewModels={reportShortTitleViewModels}
                />
            ) : (
                <NoRenString>{strings.noReportsForNewRen(renDisplayName)}</NoRenString>
            )}
        </div>
    ) : null;

    return <ResultSection>{resultSection}</ResultSection>;
}

const mapStateToProps = createStructuredSelector({
    renSearchUi: renSearchUiSelector,
    formatFieldByName: formatFieldByNameSelector,
    reportShortTitleViewModelsWhere: reportShortTitleViewModelsWhereSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
});

const convertProps = function (props) {
    const {
        renSearchUi,
        formatFieldByName,
        reportShortTitleViewModelsWhere,
        currentUserDepartmentId,
    } = props;
    const reportShortTitleViewModels = reportShortTitleViewModelsWhere({
        reportingEventNumber: renSearchUi.enteredReportingEventNumber,
        departmentId: currentUserDepartmentId,
    });
    const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);

    return {
        renDisplayName,
        reportShortTitleViewModels,
    };
};

export default compose(
    connect(mapStateToProps),
    withPropsOnChange(['renSearchUi'], (props) => ({ ...props, ...convertProps(props) }))
)(WarrantRenSearchResults);

import { ReportsResource, ExternalReportStatus } from '@mark43/rms-api';
import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';

export default createResource({
    name: 'External Report Status Resource',
    methods: {
        getExternalReportStatusForReportingEventId(reportingEventId: number) {
            return req<ReportsResource.GetExternalReportStatusForReportingEventId>({
                method: 'GET',
                url: `reports/external_report_status/${reportingEventId}/`,
            });
        },
        upsertExternalReportStatus(externalReportStatus: ExternalReportStatus) {
            return req<ReportsResource.UpsertExternalReportStatus>({
                method: 'PUT',
                url: `reports/external_report_status/${externalReportStatus.reportingEventId}/`,
                data: externalReportStatus,
            });
        },
    },
});

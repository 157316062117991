import _, { parseInt, omitBy, every } from 'lodash';
import { createSelector } from 'reselect';
import {
    societyProfilesSelector,
    societyProfilesWhereSelector,
} from '~/client-common/core/domain/society-profiles/state/data';

import societyProfileAdminForm from '../forms/societyProfileAdminForm';
import {
    LOAD_SOCIETY_PROFILES_START,
    LOAD_SOCIETY_PROFILES_SUCCESS,
    LOAD_SOCIETY_PROFILES_FAILURE,
    SAVE_SOCIETY_PROFILE_START,
    SAVE_SOCIETY_PROFILE_SUCCESS,
    SAVE_SOCIETY_PROFILE_FAILURE,
    REMOVE_SOCIETY_PROFILE_START,
    REMOVE_SOCIETY_PROFILE_SUCCESS,
    REMOVE_SOCIETY_PROFILE_FAILURE,
    saveSocietyProfile,
} from '../data';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

const SELECT_SOCIETY_PROFILE_START = 'society-profiles/SELECT_SOCIETY_PROFILE_START';
const SELECT_SOCIETY_PROFILE_SUCCESS = 'society-profiles/SELECT_SOCIETY_PROFILE_SUCCESS';
const SELECT_SOCIETY_PROFILE_FAILURE = 'society-profiles/SELECT_SOCIETY_PROFILE_FAILURE';
const OPEN_NEW_SOCIETY_PROFILE_FORM = 'society-profiles/OPEN_NEW_SOCIETY_PROFILE_FORM';

function selectSocietyProfileStart() {
    return {
        type: SELECT_SOCIETY_PROFILE_START,
    };
}

function selectSocietyProfileSuccess(id) {
    return {
        type: SELECT_SOCIETY_PROFILE_SUCCESS,
        payload: id,
    };
}

function selectSocietyProfileFailure(errMsg) {
    return {
        type: SELECT_SOCIETY_PROFILE_FAILURE,
        error: true,
        payload: errMsg,
    };
}

/**
 * Dispatches the actions required to display a society profile on the page
 *
 * @param {number} id id of the society profile
 */
export function selectSocietyProfile(id) {
    return (dispatch, getState) => {
        dispatch(selectSocietyProfileStart());
        const state = getState();
        const societyProfile = societyProfilesSelector(state)[id];
        if (societyProfile) {
            dispatch(societyProfileAdminForm.actionCreators.change(societyProfile));
            dispatch(selectSocietyProfileSuccess(id));
        } else {
            dispatch(selectSocietyProfileFailure('Failed to get society profile.'));
        }
    };
}

export function openNewSocietyProfileForm() {
    return {
        type: OPEN_NEW_SOCIETY_PROFILE_FORM,
    };
}

const initialUiState = {
    selectedSocietyProfileId: null,
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    profileLoadError: null,
};

/**
 * Society Profile Redux Reducer
 *
 * @param {Object} [state=initialUiState]
 * @param {Object} action
 * @returns
 */
export default function societyProfilesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_SOCIETY_PROFILES_START:
            return {
                ...state,
                loadingList: true,
                selectedSocietyProfileId: null,
                listError: null,
            };
        case LOAD_SOCIETY_PROFILES_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_SOCIETY_PROFILES_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case SAVE_SOCIETY_PROFILE_START:
        case REMOVE_SOCIETY_PROFILE_START:
            return {
                ...state,
                submittingForm: true,
            };
        case SAVE_SOCIETY_PROFILE_SUCCESS:
        case REMOVE_SOCIETY_PROFILE_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case SAVE_SOCIETY_PROFILE_FAILURE:
        case REMOVE_SOCIETY_PROFILE_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_SOCIETY_PROFILE_SUCCESS:
            return {
                ...state,
                profileLoadError: null,
                selectedSocietyProfileId: parseInt(action.payload),
                listError: null,
                formError: null,
            };
        case SELECT_SOCIETY_PROFILE_FAILURE:
            return {
                ...state,
                profileLoadError: action.payload,
            };
        case OPEN_NEW_SOCIETY_PROFILE_FORM:
            return {
                ...state,
                formError: null,
                profileLoadError: null,
                selectedSocietyProfileId: null,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.societyProfilesAdmin;

/**
 * Takes organizationProfilesWhereSelector, uiSelector and currentUserDepartmentIdSelector,
 * builds a list of society profiles scoped by current department id with
 * {path, title, key, code, selected} structure that will be used in the AdminList UI.
 *
 * @param {Function} organizationProfilesWhere
 * @param {Object} ui
 * @param {Number} currentUserDepartmentId
 * @returns a list of society profiles with
 * {path, title, key, selected} structure that will be used in the AdminList UI.
 */
export const societyProfileListItemsSelector = createSelector(
    societyProfilesWhereSelector,
    uiSelector,
    currentUserDepartmentIdSelector,
    (societyProfilesWhereSelector, ui, currentUserDepartmentId) =>
        _(societyProfilesWhereSelector({ departmentId: currentUserDepartmentId }))
            .map((societyProfile) => {
                return {
                    path: `/admin/society-profiles/${societyProfile.id}`,
                    title: societyProfile.name,
                    key: societyProfile.id,
                    selected: societyProfile.id === ui.selectedSocietyProfileId,
                };
            })
            .sortBy('title')
            .value()
);

const isSocietyNameUnique = (allProfiles, selectedProfile) => {
    const allProfilesWithoutSelected = omitBy(
        allProfiles,
        (profile) => profile.id === selectedProfile.id
    );
    const newName = selectedProfile.name.toLowerCase().trim();
    return every(allProfilesWithoutSelected, ({ name }) => name.toLowerCase().trim() !== newName);
};

/**
 * Submit method that is used in SocietyProfileAdminForm. It saves the changes (update or create) if they are valid
 * @param {Object} dispatch
 * @param {function} getState
 * @return result of the main dispatch
 */
export const submitSocietyProfile = function () {
    return (dispatch, getState) => {
        return dispatch(
            societyProfileAdminForm.actionCreators.submit((formModel) => {
                const societyProfiles = societyProfilesSelector(getState());
                const isValid = isSocietyNameUnique(societyProfiles, formModel);
                dispatch(
                    societyProfileAdminForm.actionCreators.setValidity('name', {
                        duplicateError: isValid,
                    })
                );
                if (isValid) {
                    dispatch(
                        saveSocietyProfile(
                            societyProfileAdminForm.convertFromFormModel({
                                // spread in data that's not in the form
                                ...societyProfiles[formModel.id],
                                ...formModel,
                            })
                        )
                    ).tap((societyProfile) => dispatch(selectSocietyProfile(societyProfile.id)));
                }
            })
        );
    };
};

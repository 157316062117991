import { find, includes, values, isEmpty, invert, keys, get, pick } from 'lodash';
import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';

const dateTypeToElasticSearch = {
    [dateTypeEnum.EVENT]: 'eventStartDateRangeQuery',
    [dateTypeEnum.MODIFIED]: 'updatedDateRangeQuery',
    [dateTypeEnum.CREATED]: 'createdDateRangeQuery',
};

export function extractDateTimeModelFromQuery(elasticQuery = {}) {
    // search elasticQuery object for dateRange key (eventStartDateRangeQuery, updatedDateRangeQuery, or createdDateRangeQuery)
    const dateRangeKey = find(keys(elasticQuery), (k) =>
        includes(values(dateTypeToElasticSearch), k)
    );
    // get dateRange object off the elasticQuery using above key, if there is no key the following will return undefined
    const dateRangeObject = get(elasticQuery, dateRangeKey);

    return {
        // if found, compute dateType ("EVENT", "MODIFIED", or "CREATED") by inverting dateTypeToElasticSearch defined above and mapping key to value
        // note: isEmpty(undefined) === true, isEmpty({}) === true
        dateType: !isEmpty(dateRangeObject)
            ? invert(dateTypeToElasticSearch)[dateRangeKey]
            : undefined,
        ...dateRangeObject,
    };
}

export function dateFormFieldsToElasticQueryFields({ dateType, ...otherFields } = {}) {
    // convert client side enum to ES property using dateTypeToElasticSearch defined in dateTypeToElasticSearch
    const elasticSearchDateTypeField = dateTypeToElasticSearch[dateType];

    // set value of dateQuery to object containing Date/Time Range field information
    // (everything except startTime and endTime)
    return {
        ...pick(otherFields, 'startTime'),
        ...pick(otherFields, 'endTime'),
        // set value of dateQuery to object containing Date/Time Range field information (everything except startTime and endTime)
        [elasticSearchDateTypeField]: pick(otherFields, [
            'withinLastPeriod',
            'toDatePeriod',
            'startDateUtc',
            'endDateUtc',
        ]),
    };
}

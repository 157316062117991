import { openBox, closeBox } from './boxActions';

export function openLightbox(context, payload) {
    return (dispatch) => {
        // any custom opening lightbox actions would go here
        dispatch(openBox(context, payload));
    };
}

export function closeLightbox(context) {
    return (dispatch) => {
        // any custom closing lightbox actions would go here
        dispatch(closeBox(context));
    };
}

import {
    TaskEntityLinkView,
    EntityTypeEnum,
    ElasticSearchTypeEnum,
    ElasticVehicle,
} from '@mark43/rms-api';
import * as fields from '~/client-common/core/enums/universal/fields';
import type { Field } from '~/client-common/core/fields/state/config';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

import { SearchResultWithType } from '../../../search/quick-search/helpers/selectedResultHelpers';
import { getCaseTitle } from '../../../records/core/helpers/caseStringFormatters';
import { formatTitleForPropertyRecord } from '../../../../legacy-redux/helpers/propertyRecordHelpers';

export function getTaskEntityLinkTitle(taskEntityLink: Partial<TaskEntityLinkView>): string {
    if (taskEntityLink.entityTitle) {
        return taskEntityLink.entityTitle;
    }

    // these strings are not supposed to appear, they serve as fallback in case the entityTitle is not being formatted
    // correctly in back end
    switch (taskEntityLink.entityType) {
        case EntityTypeEnum.BRIEFING.name:
            return taskEntityLink.entityTitle || `Briefing ${taskEntityLink.entityId}`;
        case EntityTypeEnum.CASE.name:
            return taskEntityLink.entityTitle || `Case ${taskEntityLink.entityId}`;
        case EntityTypeEnum.ITEM_PROFILE.name:
            return taskEntityLink.entityTitle || `Item ${taskEntityLink.entityId}`;
        case EntityTypeEnum.PERSON_PROFILE.name:
            return taskEntityLink.entityTitle || `Person ${taskEntityLink.entityId}`;
        case EntityTypeEnum.REPORT.name:
            return taskEntityLink.entityTitle || `Report ${taskEntityLink.entityId}`;
        case EntityTypeEnum.WARRANT.name:
            return taskEntityLink.entityTitle || `Warrant ${taskEntityLink.entityId}`;
        case EntityTypeEnum.E_FILE.name:
            return taskEntityLink.entityTitle || `E-File ${taskEntityLink.entityId}`;
        default:
            return '';
    }
}

export function getTaskEntityLinkTitleForSearchResult(
    searchResult: SearchResultWithType,
    caseFieldName: string,
    {
        formatAttributeById,
        formatCaseDefinitionById,
        formatFieldByName,
        formatTitleForElasticVehicle,
    }: {
        formatAttributeById: (attrId?: number) => string;
        formatCaseDefinitionById: (caseDefinitionId: number) => string | undefined;
        formatFieldByName: (fieldName: Field) => string | undefined;
        formatTitleForElasticVehicle: (
            vehicle: ElasticVehicle,
            options: { omitProps: string | string[] }
        ) => string;
    }
): string {
    switch (searchResult.type) {
        case ElasticSearchTypeEnum.CASE.name:
            return (
                searchResult.item.shortTitle ||
                getCaseTitle({
                    localId: searchResult.item.caseNumber || searchResult.item.localId,
                    caseDefinition: formatCaseDefinitionById(searchResult.item.caseDefinitionId),
                    caseFieldName,
                })
            );
        case ElasticSearchTypeEnum.ORGANIZATION.name:
            return searchResult.item.name || 'Organization';
        case ElasticSearchTypeEnum.PERSON.name:
            return formatFullName(searchResult.item);
        case ElasticSearchTypeEnum.REPORT.name:
            return joinTruthyValues(
                [
                    searchResult.item.ren
                        ? formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER)
                        : undefined,
                    searchResult.item.ren,
                    searchResult.item.shortTitle,
                ],
                ' '
            );
        case ElasticSearchTypeEnum.PROPERTY.name:
            return formatTitleForPropertyRecord(
                {
                    itemCategoryAttrId: formatAttributeById(searchResult.item.itemCategoryAttrId),
                    description: searchResult.item.description,
                    firearmMakeAttrId: formatAttributeById(searchResult.item.firearmMakeAttrId),
                    atfManufacturer: searchResult.item.property.atfManufacturerName,
                },
                true
            );
        case ElasticSearchTypeEnum.VEHICLE.name:
            return formatTitleForElasticVehicle(searchResult.item, { omitProps: 'itemCategory' });
        case ElasticSearchTypeEnum.WARRANT.name:
        default:
            return getTaskEntityLinkTitle({
                entityType: searchResult.entityType,
                entityId: searchResult.item.id,
            });
    }
}

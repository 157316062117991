import factory from './actionTypesFactory';

export default factory('securityipsadmin', [
    'LOAD_IPS_START',
    'LOAD_IPS_SUCCESS',
    'LOAD_IPS_FAILURE',
    'SELECT_IP',
    'CREATE_IP',
    'SAVE_IP_START',
    'SAVE_IP_SUCCESS',
    'SAVE_IP_FAILURE',
    'DELETE_IP_START',
    'DELETE_IP_SUCCESS',
    'DELETE_IP_FAILURE',
]);

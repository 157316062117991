import { map, get, first } from 'lodash';
import getAdminConsortiumResource from '~/client-common/core/domain/full-consortium-view/resources/AdminConsortiumResource';
import { NEXUS_STATE_PROP as CONSORTIUM_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/consortium-profiles/state/data';
import { NEXUS_STATE_PROP as CONSORTIUM_DEPARTMENT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/consortium-link-view/state/data';
import {
    NEXUS_STATE_PROP as DEPARTMENT_PROFILES_NEXUS_STATE_PROP,
    departmentProfilesSelector,
} from '~/client-common/core/domain/department-profiles/state/data';
import { departmentProfileIsInConsortiumTransientKey } from '~/client-common/core/domain/department-profiles/state/ui';
import { currentUserConsortiumIdSelector } from '../../../../core/current-user/state/ui';

export const LOAD_CONSORTIUM_DETAILS_START = 'consortium-admin/LOAD_CONSORTIUM_DETAILS_START';
export const LOAD_CONSORTIUM_DETAILS_SUCCESS = 'consortium-admin/LOAD_CONSORTIUM_DETAILS_SUCCESS';
export const LOAD_CONSORTIUM_DETAILS_FAILURE = 'consortium-admin/LOAD_CONSORTIUM_DETAILS_FAILURE';

export const ADD_DEPARTMENT_TO_CONSORTIUM_START =
    'consortium-admin/ADD_DEPARTMENT_TO_CONSORTIUM_START';
export const ADD_DEPARTMENT_TO_CONSORTIUM_SUCCESS =
    'consortium-admin/ADD_DEPARTMENT_TO_CONSORTIUM_SUCCESS';
export const ADD_DEPARTMENT_TO_CONSORTIUM_FAILURE =
    'consortium-admin/ADD_DEPARTMENT_TO_CONSORTIUM_FAILURE';

export const UPSERT_CONSORTIUM_START = 'consortium-admin/UPSERT_CONSORTIUM_START';
export const UPSERT_CONSORTIUM_SUCCESS = 'consortium-admin/UPSERT_CONSORTIUM_SUCCESS';
export const UPSERT_CONSORTIUM_FAILURE = 'consortium-admin/UPSERT_CONSORTIUM_FAILURE';

export const DELETE_CONSORTIUM_START = 'consortium-admin/DELETE_CONSORTIUM_START';
export const DELETE_CONSORTIUM_SUCCESS = 'consortium-admin/DELETE_CONSORTIUM_SUCCESS';
export const DELETE_CONSORTIUM_FAILURE = 'consortium-admin/DELETE_CONSORTIUM_FAILURE';

const GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_START =
    'consortium-admin/GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_START';
const GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_SUCCESS =
    'consortium-admin/GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_SUCCESS';
export const GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_FAILURE =
    'consortium-admin/GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_FAILURE';

const REMOVE_DEPARTMENT_FROM_CONSORTIUM_START =
    'consortium-admin/REMOVE_DEPARTMENT_FROM_CONSORTIUM_START';
const REMOVE_DEPARTMENT_FROM_CONSORTIUM_SUCCESS =
    'consortium-admin/REMOVE_DEPARTMENT_FROM_CONSORTIUM_SUCCESS';
const REMOVE_DEPARTMENT_FROM_CONSORTIUM_FAILURE =
    'consortium-admin/REMOVE_DEPARTMENT_FROM_CONSORTIUM_FAILURE';

export const GRANT_PERMISSIONS_START = 'consortium-admin/GRANT_PERMISSIONS_START';
export const GRANT_PERMISSIONS_SUCCESS = 'consortium-admin/GRANT_PERMISSIONS_SUCCESS';
export const GRANT_PERMISSIONS_FAILURE = 'consortium-admin/GRANT_PERMISSIONS_FAILURE';

export const GET_PERMISSION_STATUS_START = 'consortium-admin/GET_PERMISSION_STATUS_START';
export const GET_PERMISSION_STATUS_SUCCESS = 'consortium-admin/GET_PERMISSION_STATUS_SUCCESS';
export const GET_PERMISSION_STATUS_FAILURE = 'consortium-admin/GET_PERMISSION_STATUS_FAILURE';

function loadConsortiumDetailsStart() {
    return {
        type: LOAD_CONSORTIUM_DETAILS_START,
    };
}

function loadConsortiumDetailsSuccess() {
    return {
        type: LOAD_CONSORTIUM_DETAILS_SUCCESS,
    };
}

function loadConsortiumDetailsFailure(errMsg) {
    return {
        type: LOAD_CONSORTIUM_DETAILS_FAILURE,
        payload: errMsg,
    };
}

function addDepartmentToConsortiumStart() {
    return {
        type: ADD_DEPARTMENT_TO_CONSORTIUM_START,
    };
}

function addDepartmentToConsortiumSuccess() {
    return {
        type: ADD_DEPARTMENT_TO_CONSORTIUM_SUCCESS,
    };
}

function addDepartmentToConsortiumFailure(errMsg) {
    return {
        type: ADD_DEPARTMENT_TO_CONSORTIUM_FAILURE,
        payload: errMsg,
    };
}

function upsertConsortiumStart() {
    return {
        type: UPSERT_CONSORTIUM_START,
    };
}

function upsertConsortiumSuccess() {
    return {
        type: UPSERT_CONSORTIUM_SUCCESS,
    };
}

function upsertConsortiumFailure(errMsg) {
    return {
        type: UPSERT_CONSORTIUM_FAILURE,
        payload: errMsg,
    };
}

function deleteConsortiumStart() {
    return {
        type: DELETE_CONSORTIUM_START,
    };
}

function deleteConsortiumSuccess() {
    return {
        type: DELETE_CONSORTIUM_SUCCESS,
    };
}

function deleteConsortiumFailure(errMsg) {
    return {
        type: DELETE_CONSORTIUM_FAILURE,
        payload: errMsg,
    };
}

function getDepartmentProfilesNotInConsortiumStart() {
    return {
        type: GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_START,
    };
}

function getDepartmentProfilesNotInConsortiumSuccess() {
    return {
        type: GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_SUCCESS,
    };
}

function getDepartmentProfilesNotInConsortiumFailure(errMsg) {
    return {
        type: GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_FAILURE,
        payload: errMsg,
    };
}

function removeDepartmentFromConsortiumStart() {
    return {
        type: REMOVE_DEPARTMENT_FROM_CONSORTIUM_START,
    };
}

function removeDepartmentFromConsortiumSuccess() {
    return {
        type: REMOVE_DEPARTMENT_FROM_CONSORTIUM_SUCCESS,
    };
}

function removeDepartmentFromConsortiumFailure(errMsg) {
    return {
        type: REMOVE_DEPARTMENT_FROM_CONSORTIUM_FAILURE,
        payload: errMsg,
    };
}

function grantPermissionsStart() {
    return {
        type: GRANT_PERMISSIONS_START,
    };
}

function grantPermissionsSuccess(departmentPermissions) {
    return {
        type: GRANT_PERMISSIONS_SUCCESS,
        payload: departmentPermissions,
    };
}

function grantPermissionsFailure(errMsg) {
    return {
        type: GRANT_PERMISSIONS_FAILURE,
        payload: errMsg,
    };
}

function getPermissionStatusStart() {
    return {
        type: GET_PERMISSION_STATUS_START,
    };
}

function getPermissionStatusSuccess(status) {
    return {
        type: GET_PERMISSION_STATUS_SUCCESS,
        payload: status,
    };
}

function getPermissionStatusFailure(errMsg) {
    return {
        type: GET_PERMISSION_STATUS_FAILURE,
        payload: errMsg,
    };
}

export function loadConsortiumDetails() {
    return (dispatch, getState, dependencies) => {
        dispatch(loadConsortiumDetailsStart());
        return getAdminConsortiumResource()
            .getConsortiumDetails()
            .then((consortiumDetails) => {
                const {
                    consortiumLinks,
                    consortiumProfiles,
                    departmentProfiles,
                } = consortiumDetails;
                const currentUserConsortiumId = currentUserConsortiumIdSelector(getState());
                dispatch(
                    dependencies.nexus.withRemove(
                        CONSORTIUM_DEPARTMENT_LINKS_NEXUS_STATE_PROP,
                        { consortiumId: currentUserConsortiumId },
                        dependencies.nexus.withEntityItems(
                            {
                                [CONSORTIUM_PROFILES_NEXUS_STATE_PROP]: consortiumProfiles,
                                [CONSORTIUM_DEPARTMENT_LINKS_NEXUS_STATE_PROP]: consortiumLinks,
                                [DEPARTMENT_PROFILES_NEXUS_STATE_PROP]: departmentProfiles,
                            },
                            loadConsortiumDetailsSuccess()
                        )
                    )
                );
                return consortiumDetails;
            })
            .catch((err) => {
                dispatch(loadConsortiumDetailsFailure(err.message));
                throw err;
            });
    };
}

export function upsertConsortium(consortiumProfile) {
    return (dispatch, getState, dependencies) => {
        dispatch(upsertConsortiumStart());
        return getAdminConsortiumResource()
            .upsertConsortium(consortiumProfile)
            .then(({ consortiumProfiles, consortiumLinks }) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [CONSORTIUM_PROFILES_NEXUS_STATE_PROP]: consortiumProfiles,
                            [CONSORTIUM_DEPARTMENT_LINKS_NEXUS_STATE_PROP]: consortiumLinks,
                        },
                        upsertConsortiumSuccess()
                    )
                );
                return first(consortiumProfiles);
            })
            .catch((err) => {
                dispatch(upsertConsortiumFailure(err.message));
                throw err;
            });
    };
}

export function addDepartmentToConsortium(consortiumId, departmentId) {
    return (dispatch, getState, dependencies) => {
        dispatch(addDepartmentToConsortiumStart());
        return getAdminConsortiumResource()
            .addDepartment(consortiumId, departmentId)
            .then((consortiumLink) => {
                const departmentProfile = departmentProfilesSelector(getState())[departmentId];
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [CONSORTIUM_DEPARTMENT_LINKS_NEXUS_STATE_PROP]: [consortiumLink],
                            [DEPARTMENT_PROFILES_NEXUS_STATE_PROP]: departmentProfile && [
                                {
                                    ...departmentProfile,
                                    [departmentProfileIsInConsortiumTransientKey]: true,
                                },
                            ],
                        },
                        addDepartmentToConsortiumSuccess()
                    )
                );
                return departmentId;
            })
            .catch((err) => {
                dispatch(addDepartmentToConsortiumFailure(err.message));
                throw err;
            });
    };
}

export function deleteConsortium(consortiumId) {
    return (dispatch, getState, dependencies) => {
        dispatch(deleteConsortiumStart());
        return getAdminConsortiumResource()
            .deleteConsortium(consortiumId)
            .then(() => {
                dispatch(
                    dependencies.nexus.withRemove(
                        CONSORTIUM_PROFILES_NEXUS_STATE_PROP,
                        { id: consortiumId },
                        dependencies.nexus.withRemove(
                            CONSORTIUM_DEPARTMENT_LINKS_NEXUS_STATE_PROP,
                            { consortiumId },
                            deleteConsortiumSuccess()
                        )
                    )
                );
                return consortiumId;
            })
            .catch((err) => {
                dispatch(deleteConsortiumFailure(err.message));
                throw err;
            });
    };
}

export function getDepartmentProfilesNotInConsortium() {
    return (dispatch, getState, dependencies) => {
        dispatch(getDepartmentProfilesNotInConsortiumStart());
        return getAdminConsortiumResource()
            .getDepartmentProfilesNotInConsortium()
            .then((departmentProfilesNotInConsortium) =>
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [DEPARTMENT_PROFILES_NEXUS_STATE_PROP]: map(
                                departmentProfilesNotInConsortium,
                                (departmentProfile) => ({
                                    ...departmentProfile,
                                    [departmentProfileIsInConsortiumTransientKey]: false,
                                })
                            ),
                        },
                        getDepartmentProfilesNotInConsortiumSuccess()
                    )
                )
            )
            .catch((err) => {
                dispatch(getDepartmentProfilesNotInConsortiumFailure(err.message));
                throw err;
            });
    };
}

export function removeDepartmentFromConsortium(consortiumId, departmentId) {
    return (dispatch, getState, dependencies) => {
        dispatch(removeDepartmentFromConsortiumStart());
        return getAdminConsortiumResource()
            .removeDepartment(consortiumId, departmentId)
            .then(() => {
                dispatch(
                    dependencies.nexus.withRemove(
                        CONSORTIUM_DEPARTMENT_LINKS_NEXUS_STATE_PROP,
                        {
                            consortiumId,
                            deptId: departmentId,
                        },
                        removeDepartmentFromConsortiumSuccess()
                    )
                );
            })
            .catch((err) => {
                dispatch(removeDepartmentFromConsortiumFailure());
                throw err;
            });
    };
}

export function grantPermissions(departmentPermissions) {
    return (dispatch) => {
        dispatch(grantPermissionsStart());
        return getAdminConsortiumResource()
            .grantPermissions(departmentPermissions)
            .then(() => dispatch(grantPermissionsSuccess(departmentPermissions)))
            .catch((err) => {
                dispatch(grantPermissionsFailure(err.message));
                throw err;
            });
    };
}

export function getPermissionStatus(departmentId) {
    return (dispatch) => {
        dispatch(getPermissionStatusStart());
        return getAdminConsortiumResource()
            .getPermissionStatus(departmentId)
            .then((data) => dispatch(getPermissionStatusSuccess(get(data, 'commandStatus'))))
            .catch((err) => {
                dispatch(getPermissionStatusFailure(err.message));
                throw err;
            });
    };
}

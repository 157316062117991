import React from 'react';

type ErrorRateCellPropsT = {
    errorRate?: string;
    isOverwritten: boolean;
};
const ErrorRateCell: React.FC<ErrorRateCellPropsT> = ({ errorRate, isOverwritten }) =>
    isOverwritten ? null : <span>{errorRate}%</span>;

export default ErrorRateCell;

import { defer, flatMap, get, map, parseInt, pick } from 'lodash';
import Promise from 'bluebird';
import * as Sentry from '@sentry/browser';
import { batch } from 'react-redux';
import {
    UserSettingsView,
    DepartmentSettingsView,
    UserProfileView,
    AttributeTypeEnum,
    DepartmentStatusEnum,
    ProductModuleEnum,
} from '@mark43/rms-api';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import environmentEnum from '~/client-common/core/enums/client/environmentEnum';
import {
    storeSettings,
    applicationSettingsSelector,
} from '~/client-common/core/domain/settings/state/data';
import {
    isProductModuleActiveSelector,
    removeProductModuleWhere,
    storeProductModules,
} from '~/client-common/core/domain/product-modules/state/data';
import { NEXUS_STATE_PROP as FIELD_CONFIGURATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/field-configurations/state/data';
import {
    storeTimeZones,
    storeUnknownLocationId,
} from '~/client-common/core/constants/state/constants';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { NEXUS_STATE_PROP as AGENCY_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/agency-profiles/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { NEXUS_STATE_PROP as CASE_DEFINITIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/case-definitions/state/data';
import { NEXUS_STATE_PROP as FIELD_CONFIGURATION_CONTEXTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { NEXUS_STATE_PROP as FIELD_DETAIL_CONTEXTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/field-detail-contexts/state/data';
import { NEXUS_STATE_PROP as FIELD_DETAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/field-details/state/data';
import { NEXUS_STATE_PROP as NIBRS_OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { NEXUS_STATE_PROP as REPORT_DEFINITIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-definitions/state/data';
import { NEXUS_STATE_PROP as RULE_ACTIONED_FIELDS_NEXUS_STATE_PROP } from '~/client-common/core/domain/rule-actioned-fields/state/data';
import { NEXUS_STATE_PROP as RULE_CONDITIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/rule-conditions/state/data';
import { NEXUS_STATE_PROP as RULE_CONFIGURATION_CONTEXTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/rule-configuration-contexts/state/data';
import { NEXUS_STATE_PROP as RULES_NEXUS_STATE_PROP } from '~/client-common/core/domain/rules/state/data';
import { NEXUS_STATE_PROP as SOCIETY_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/society-profiles/state/data';
import { NEXUS_STATE_PROP as UCR_SUMMARY_OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/ucr-summary-offense-codes/state/data';
import { NEXUS_STATE_PROP as USER_ASSIGNMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/user-assignments/state/data';
import { NEXUS_STATE_PROP as RULE_CONDITION_ARGS_NEXUS_STATE_PROP } from '~/client-common/core/domain/rule-condition-args/state/data';
import { NEXUS_STATE_PROP as HIDDEN_DEPARTMENT_LINK_TYPES_NEXUS_STATE_PROP } from '~/client-common/core/domain/hidden-department-link-types/state/data';
import { currentUserHasAbilitySelector } from '../../modules/core/current-user/state/ui';
import { loadReportModules } from '../../modules/reports/core/state/data/reportModules';

import authResource from '../resources/authResource';
import errors from '../../lib/errors';
import cobaltHistory from '../../routing/cobaltHistory';
import userResource from '../resources/userResource';
import attributesAdminResource from '../resources/attributesAdminResource';

import { loadTheme } from '../../modules/core/styles/state';

import addRuleImplementation from '../validation/addRuleImplementation';
import { clearAuthToken } from '../../core/auth';
import { getBootstrapResolver } from '../../routing/bootstrapState';
import { evidenceBootstrap } from '../../modules/evidence/core/state/data';
import { routerLocationSelector } from '../../routing/routerModule';
import { RmsAction, RmsDispatch } from '../../core/typings/redux';
import initPendo from '../../core/initPendo';
import handleAuthRedirect from '../../routing/utils/handleAuthRedirect';
import { getRelativeReference } from '../helpers/urlHelpers';
import { userSsoLogoutUrlSelector } from '../selectors/userSelectors';
import { loadAttributesSuccess } from '../../modules/core/attributes/state/ui/loadAttributesForType';
import actionTypes from './types/authActionTypes';
import { loadRulesSuccess } from './validationActions';
import { showLoadingMask, setDepartmentStatusIndicator } from './globalActions';
import { openBox, closeBox } from './boxActions';
import { pollForExports } from './exportsActions';

/* START CHECK AUTH / BOOTSTRAP DATA */
// we dont have an official "bootstrap" endpoint, we mimic this behaviour
// by retrieving attributes and the user profile
export function bootstrap(): RmsAction<Promise<void>> {
    return (dispatch, getState, dependencies) => {
        const bootstrapResolver = getBootstrapResolver();
        batch(() => {
            dispatch(bootstrapStart());
            dispatch(showLoadingMask(true));
        });

        return (
            Promise.all([
                userResource.getUserProfile(true),
                attributesAdminResource.getAllCodes(true),
            ])
                .spread((userData: UserSettingsView, attributesData: DepartmentSettingsView) => {
                    const redirectPath = get(routerLocationSelector(getState()), 'query.redirect');

                    // this is actually a UserProfileView that is injected by the BE
                    const userProfile = userData.userProfile as UserProfileView;

                    // side effectful Sentry configuration - this is probably
                    // the best place for this
                    Sentry.configureScope((scope) => {
                        scope.setUser({
                            email: userProfile.primaryEmail,
                            id: (userProfile.user.id as unknown) as string,
                        });
                    });

                    const { departmentId } = userData.departmentProfile;

                    const entitiesToStore = {
                        ...pick(userData, [
                            SOCIETY_PROFILES_NEXUS_STATE_PROP,
                            USER_ASSIGNMENTS_NEXUS_STATE_PROP,
                            HIDDEN_DEPARTMENT_LINK_TYPES_NEXUS_STATE_PROP,
                        ]),
                        ...pick(attributesData, [
                            FIELD_DETAIL_CONTEXTS_NEXUS_STATE_PROP,
                            FIELD_CONFIGURATION_CONTEXTS_NEXUS_STATE_PROP,
                            RULE_ACTIONED_FIELDS_NEXUS_STATE_PROP,
                            RULE_CONFIGURATION_CONTEXTS_NEXUS_STATE_PROP,
                            REPORT_DEFINITIONS_NEXUS_STATE_PROP,
                            CASE_DEFINITIONS_NEXUS_STATE_PROP,
                            ATTRIBUTES_NEXUS_STATE_PROP,
                            AGENCY_PROFILES_NEXUS_STATE_PROP,
                            FIELD_DETAILS_NEXUS_STATE_PROP,
                            RULES_NEXUS_STATE_PROP,
                            RULE_CONDITIONS_NEXUS_STATE_PROP,
                            RULE_CONDITION_ARGS_NEXUS_STATE_PROP,
                            NIBRS_OFFENSE_CODES_NEXUS_STATE_PROP,
                            UCR_SUMMARY_OFFENSE_CODES_NEXUS_STATE_PROP,
                            FIELD_CONFIGURATIONS_NEXUS_STATE_PROP,
                        ]),

                        miniUsers: userData.allMiniUsers,
                        modules: map(userData.modules, 'module'),
                        roles: [
                            ...userData.roles,
                            ...attributesData.consortiumDetails.externalRoles,
                        ],
                        departmentProfiles: map(
                            userData.associatedDepartmentProfiles,
                            (departmentProfile) =>
                                departmentProfile.departmentId === departmentId
                                    ? userData.departmentProfile
                                    : departmentProfile
                        ),
                        userProfiles: [userProfile],
                        consortiumProfiles: attributesData.consortiumDetails.consortiumProfiles,
                        consortiumDepartmentLinks: attributesData.consortiumDetails.consortiumLinks,
                        userRoles: userData.userRolesForUser,
                        abilityRoleLinks: userData.abilityRoleLinksForUser,
                        // NOTE: a Code is different from a NibrsOffenseCode
                        // Codes from the department's regional group, e.g., NIBRS or CA_UCR
                        codes: attributesData.regionalGroupCodes,

                        // NOTE: there is a server AttributeCode model that links an attributeId to codeId
                        // but also has other data we don't need to support de-normalization in our tables.
                        // Our attributeCodes in the FE only contain the data we need: attributeId and codeId

                        attributeCodes: flatMap(
                            // NOTE: swagger types are incorrect
                            (attributesData.attrIdsToNibrsCodeIds as unknown) as {
                                [key: string]: number[];
                            },
                            (codeIds, attrId) => {
                                return map(codeIds, (codeId) => ({
                                    attributeId: parseInt(attrId), // need to parseInt bc it comes down as a key (string)
                                    codeId,
                                }));
                            }
                        ),
                    };

                    const attributesToMarkAsLoaded = [
                        // types used by inputs which do not use attribute selects
                        AttributeTypeEnum.ITEM_TYPE.name,
                        AttributeTypeEnum.ROUTING_LABEL.name,

                        AttributeTypeEnum.VEHICLE_MODEL.name,
                        AttributeTypeEnum.VEHICLE_MAKE.name,

                        // notifications
                        AttributeTypeEnum.BULLETIN_TYPE.name,

                        // user profiles (admin)
                        AttributeTypeEnum.USER_TRAINING.name,
                        AttributeTypeEnum.USER_SKILL.name,

                        // advanced search related
                        AttributeTypeEnum.CASE_STATUS.name,
                        AttributeTypeEnum.CUSTOM_REPORT_CLASSIFICATION.name,
                    ];

                    batch(() => {
                        dispatch(storeSettings(attributesData.settingsByName));

                        dispatch(
                            loadRulesSuccess(
                                addRuleImplementation(attributesData.validationRules, dispatch)
                            )
                        );
                        dispatch(loadAttributesSuccess(attributesToMarkAsLoaded));
                        dispatch(storeProductModules(userData.productModules));

                        dispatch(storeTimeZones(attributesData.allTimeZones));
                        dispatch(storeUnknownLocationId(attributesData.unknownLocationId));
                        dispatch(
                            dependencies.nexus.withEntityItems(entitiesToStore, {
                                type: 'BOOTSTRAP_ENTITIES',
                            })
                        );

                        const { department } = userData.departmentProfile;
                        const { departmentStatus } = department;
                        if (departmentStatus !== DepartmentStatusEnum.LIVE.name) {
                            dispatch(setDepartmentStatusIndicator(department.departmentStatus));
                        }
                    });

                    const applicationSettings = applicationSettingsSelector(getState());

                    if (applicationSettings.RMS_PENDO_ENABLED) {
                        initPendo({
                            userProfile,
                            departmentProfile: userData.departmentProfile,
                        });
                    }

                    dispatch(
                        bootstrapSuccess({
                            userData,
                            attributesData,
                        })
                    );

                    if (redirectPath) {
                        cobaltHistory.push(redirectPath);
                    }
                    // start polling for 'MyExports'
                    dispatch(pollForExports());

                    // async actions that need to happen before the bootstrap process is
                    // complete
                    const asyncActions: Promise<unknown>[] = [];

                    dispatch(loadTheme());

                    // the features below depend on bootstrap success, after Redux state has been updated
                    const stateAfterBootstrapSuccess = getState();
                    const currentUserHasAbility = currentUserHasAbilitySelector(
                        stateAfterBootstrapSuccess
                    );

                    if (
                        applicationSettings.RMS_REPORT_MODULES_ENABLED &&
                        currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_GENERAL)
                    ) {
                        // report modules are loaded on bootstrap because they always appear in the left navigation
                        const reportModulesPromise = dispatch(loadReportModules());
                        asyncActions.push(reportModulesPromise);
                    }

                    const shouldBootstrapEvidence =
                        isProductModuleActiveSelector(stateAfterBootstrapSuccess)(
                            ProductModuleEnum.EVIDENCE.name
                        ) && currentUserHasAbility(abilitiesEnum.EVIDENCE.VIEW_GENERAL);
                    if (shouldBootstrapEvidence) {
                        asyncActions.push(dispatch(evidenceBootstrap()));
                    }

                    return Promise.all(asyncActions).catch(
                        // gracefully disable evidence on failed bootstrap
                        () => dispatch(removeProductModuleWhere(ProductModuleEnum.EVIDENCE.name))
                    );
                })
                .then(() => {
                    // this resolves a promise which indicates to our application that
                    // the bootstrap process is complete, allowing for data calls and
                    // routing which rely on bootstrapped data (such as the user profile
                    // or attributes data)
                    bootstrapResolver();
                    dispatch(showLoadingMask(false));
                })
                // @ts-expect-error Our installed version of Bluebird is out of date. Types for Bluebird 3.x support
                // multiple error classes. The solution is to upgrade Bluebird in TNP-532.
                .catch(errors.UnauthorizedError, errors.InsufficientPermissionsError, () => {
                    handleAuthRedirect({
                        location: {
                            pathname: '/',
                            search: `?redirect=${encodeURIComponent(
                                getRelativeReference(window.location)
                            )}`,
                        },
                    });
                })
                .catch((err) => {
                    // handle a non-401 error (connectivity or other unexpected issues)
                    // from any bootstrap API request
                    dispatch(bootstrapFailure());
                    // pushing the same patch twice onto a hash location is going to throw an error
                    if (cobaltHistory.getCurrentLocation().pathname !== '/') {
                        cobaltHistory.push('/');
                    }
                    // open error modal on next tick, otherwise the route change closes
                    // it right away
                    defer(() => {
                        dispatch(openBox({ name: boxEnum.APP_LOAD_FAILURE_MODAL }));
                    });
                    dispatch(showLoadingMask(false));
                    if (MARK43_ENV === environmentEnum.DEVELOPER) {
                        throw err;
                    }
                })
        );
    };
}

export function bootstrapStart() {
    return {
        type: actionTypes.BOOTSTRAP_START,
        payload: null,
    } as const;
}

export function bootstrapSuccess(authData: {
    userData: UserSettingsView;
    attributesData: DepartmentSettingsView;
}) {
    return {
        type: actionTypes.BOOTSTRAP_SUCCESS,
        payload: authData,
    } as const;
}

export function bootstrapFailure() {
    return {
        type: actionTypes.BOOTSTRAP_FAILURE,
        payload: null,
    } as const;
}

/* END CHECK AUTH / BOOTSTRAP DATA */

/* BEGIN SESSION TIMEOUT INACTIVITY WORKFLOW */

// HARDCODED probably don't change for quite a while!
// this is the amount of time before they will be auto
// logged out that we show them the modal
const INACTIVITY_LIMIT = 300; // 5 minutes

const inactivityModalContext = {
    name: boxEnum.INACTIVITY_MODAL,
};

export function showInactivityModalBasedOnExpirationSeconds(
    expirationSeconds: number,
    dispatch: RmsDispatch
) {
    if (expirationSeconds < INACTIVITY_LIMIT) {
        dispatch(openBox(inactivityModalContext));
    }
}

export function logoutDueToInactivity(): RmsAction<void> {
    return (dispatch) => {
        dispatch(logout()); // don't actually show message, the person clicked!
    };
}

export function stayLoggedIn(): RmsAction<void> {
    return (dispatch) => {
        // error handling here is a bit overkill
        // we're just pinging like "hey we're back"
        authResource.extendSession();
        dispatch(closeBox(inactivityModalContext));
    };
}

/* END SESSION TIMEOUT INACTIVITY WORKFLOW */

/* START LOGOUT WORKFLOW */

export function logout(dueToInactivity?: boolean): RmsAction<void> {
    return (dispatch, getState) => {
        const routerLocation = routerLocationSelector(getState());
        const locationQuery = get(routerLocation, 'search', '');
        const ssoLogoutUrl = userSsoLogoutUrlSelector(getState());
        const redirectToLoginApp = () => {
            clearAuthToken();
            // if there's a logout url present, we know that this use is an
            // sso enabled user and we redirect to the appropriate sso logout page
            if (ssoLogoutUrl) {
                window.location.assign(ssoLogoutUrl);
                // otherwise, we redirect the user to mark43's login page
            } else {
                handleAuthRedirect({
                    location: {
                        pathname: '/',
                        search: dueToInactivity
                            ? `?redirect=${encodeURIComponent(
                                  getRelativeReference(window.location)
                              )}`
                            : locationQuery,
                    },
                });
            }
        };
        authResource
            .logout()
            .then(redirectToLoginApp)
            // in this case we have not properly destoryed the user's auth
            // token, but we mimic a proper logout as best possible on the
            // client
            .catch(redirectToLoginApp);
    };
}

/* END LOGOUT */

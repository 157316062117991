import { ResubmissionStatusEnumType, NibrsexportResource } from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Reports Resource',
    methods: {
        getQueuedResubmissions(reportId: number) {
            return req<NibrsexportResource.GetQueuedComplianceResubmissionByReportId>({
                method: 'GET',
                url: `nibrs/export/resubmissions/report/${reportId}/`,
            });
        },
        putResubmissionToQueue(
            resubmissionStatuses: {
                agencyId: number;
                departmentId: number;
                reportId: number;
                resubmissionStatus: ResubmissionStatusEnumType;
            }[]
        ) {
            return req<NibrsexportResource.UpdateAll>({
                method: 'PUT',
                url: 'nibrs/export/resubmissions',
                // @ts-expect-error Resolve this type error by updating the resource type to handle
                // Partial<ComplianceResubmissionStatus>[] instead of ComplianceResubmissionStatus[]
                data: resubmissionStatuses,
            });
        },
        removeResubmissionFromQueue(resubmissionId: number) {
            return req<NibrsexportResource.DeleteResubmissionStatusById>({
                method: 'DELETE',
                url: `nibrs/export/resubmissions/${resubmissionId}/`,
            });
        },
    },
});

import { EntityTypeEnum } from '@mark43/rms-api';
import { map, isString } from 'lodash';
import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { Text, cssVar, mediaQueries } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { BodyMediumText } from '../../../modules/core/components/typography';
import {
    OrganizationProfileImage,
    PersonProfileImage,
    profileImageSizes,
} from '../../../modules/core/components/ProfileImage';
import { responsiveStack } from '../../../modules/core/styles/mixins';

import Tabs, { Tab } from '../core/Tabs';
import Divider from '../../../modules/core/components/Divider';
import { InlineBanner } from '../../../modules/core/components/InlineBanner';
import { PopoutLink } from '../../../modules/core/components/links/Link';

const strings = componentStrings.reports.InvolvedNamesCard.InvolvedNamesCardTabs;

const InvolvementTypes = styled(Text)`
    font-size: ${cssVar('arc.fontSizes.sm')};
`;

const InvolvementWrapper = styled.div`
    margin-top: 4px;
    ${responsiveStack()}

    @media (min-width: ${mediaQueries.md}) {
        gap: var(--arc-space-3);
    }
`;

const StyledDivider = styled(Divider)`
    border-style: dashed;
`;

const TabHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--arc-space-4);
    flex-wrap: wrap;

    @media (min-width: ${mediaQueries.md}) {
        flex-direction: row;
    }
`;

const InvolvedNameCardTab = styled(Tab)`
    &&  {
        overflow: visible;
    }
`;

/**
 * @param {Object}         props.viewModels View models containing display
 *   strings for each involved person/org.
 * @param {string}         [props.error]    Tab level error, not to be confused
 *   with a card level error.
 * @param {string|boolean} [error]          If `true` or string, the tab link
 *   gets the error style (red highlight). If string, it's shown as an error
 *   message near the top of the tab content.
 * @param {function} props.children
 */
export default function InvolvedNamesCardTabs({
    selectedTab,
    onSelectTab,
    viewModels,
    error,
    children,
}) {
    return (
        <Tabs selectedTab={selectedTab} onSelectTab={onSelectTab} className="mark43-card-tabs">
            {map(viewModels, (viewModel, index) => {
                return (
                    <InvolvedNameCardTab
                        key={index}
                        disabled={viewModel.isExpunged}
                        title={
                            <BodyMediumText fontWeight="semibold" color="inherit">
                                {viewModel.display.label}
                                {!viewModel.isExpunged &&
                                    viewModel.display.abbreviatedLinkTypes && (
                                        <div>{viewModel.display.abbreviatedLinkTypes}</div>
                                    )}
                            </BodyMediumText>
                        }
                    >
                        {!viewModel.isExpunged && (
                            <div>
                                <TabHeader>
                                    {viewModel.nameEntityType ===
                                    EntityTypeEnum.PERSON_PROFILE.name ? (
                                        <PersonProfileImage
                                            size={profileImageSizes.INVOLVED_NAME}
                                            url={viewModel.display.profileImageUrl}
                                            isUnknown={viewModel.isUnknown}
                                            zIndex={0}
                                        />
                                    ) : (
                                        <OrganizationProfileImage
                                            size={profileImageSizes.INVOLVED_NAME}
                                            zIndex={0}
                                        />
                                    )}
                                    <div>
                                        {viewModel.display.link ? (
                                            <PopoutLink to={viewModel.display.link}>
                                                <BodyMediumText>
                                                    {viewModel.display.label}
                                                </BodyMediumText>
                                            </PopoutLink>
                                        ) : (
                                            <BodyMediumText>
                                                {viewModel.display.label}
                                            </BodyMediumText>
                                        )}
                                        {viewModel.display.expandedLinkTypes && (
                                            <InvolvementWrapper>
                                                <BodyMediumText>
                                                    {strings.involvedLabel}
                                                </BodyMediumText>
                                                <InvolvementTypes as="div">
                                                    {viewModel.display.expandedLinkTypes}
                                                </InvolvementTypes>
                                            </InvolvementWrapper>
                                        )}
                                    </div>
                                </TabHeader>
                            </div>
                        )}
                        {isString(error) && error && (
                            <div>
                                <StyledDivider />
                                <InlineBanner status="error">{error}</InlineBanner>
                            </div>
                        )}
                        {!viewModel.isExpunged && index === parseInt(selectedTab, 10) && (
                            <>
                                <StyledDivider />
                                {children(viewModel, index)}
                            </>
                        )}
                    </InvolvedNameCardTab>
                );
            })}
        </Tabs>
    );
}

InvolvedNamesCardTabs.propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

import * as fields from '~/client-common/core/enums/universal/fields';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'printableHash',
        fieldName: fields.EVIDENCE_PRINTING_TEMPLATE_EVIDENCE_DOCUMENT_TYPE,
        validators: {
            requiredError: requiredString,
        },
    },
]);

const documentExportingForm = createFormModule({
    formName: formClientEnum.EVIDENCE_DOCUMENT_EXPORTING,
    fieldViewModels,
});

/**
 * Module of the form for exporting an evidence document for selected item
 *   profiles.
 * @type {Object}
 */
export default documentExportingForm;

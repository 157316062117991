import { createFormConfiguration, createField, InferFormDataShape } from 'markformythree';

type VehicleSearchToAddFormConfiguration = ReturnType<
    typeof createVehicleSearchToAddFormConfiguration
>;
type VehicleSearchToAddFormModel = InferFormDataShape<VehicleSearchToAddFormConfiguration>;

export const VEHICLE_SEARCH_TO_ADD_FORM = 'VEHICLE_SEARCH_TO_ADD_FORM';

type VehicleSearchConvertFromFormModelResult = {
    quickSearchQuery: string | undefined;
};

export function createVehicleSearchToAddFormConfiguration() {
    return createFormConfiguration({
        quickSearchQuery: createField<string>({}),
    });
}

export const convertFromFormModel = (
    formModel: Partial<VehicleSearchToAddFormModel> = {}
): Partial<VehicleSearchConvertFromFormModelResult> => {
    const entries = Object.entries(formModel).map(([key, value]) => {
        return [key, value === '' ? null : value];
    });

    return Object.fromEntries(entries);
};

import React from 'react';
import styled from 'styled-components';

const CellWrapper = styled.div`
    margin-left: 12px;
`;
const MissingItemsCountCell = ({ missingItemsCount }) => (
    <CellWrapper>{missingItemsCount > 0 ? missingItemsCount : '-'}</CellWrapper>
);

export default MissingItemsCountCell;

import _ from 'lodash';

export function OptionsArray(props, propName) {
    const value = props[propName];
    if (!value || !_.isArray(value)) {
        return new Error('Options must be specified and must be an array');
    } else {
        const allPassed = _.every(value, (option) => {
            return option.display && option.value;
        });
        if (!allPassed) {
            return new Error('All options must have a display and a value property');
        }
    }
}

import { Mark43File } from '@mark43/rms-api';
import { map } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getFilesResource from '../../resources/filesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'files';
const filesModule = createNormalizedModule<Mark43File>({
    type: NEXUS_STATE_PROP,
});

const LOAD_FILE_START = 'files/LOAD_FILE_START';
const LOAD_FILE_SUCCESS = 'files/LOAD_FILE_SUCCESS';
export const LOAD_FILE_FAILURE = 'files/LOAD_FILE_FAILURE';
const UPDATE_FILES_SUCCESS = 'files/UPDATE_FILES_SUCCESS';
const UPDATE_FILES_FAILURE = 'files/UPDATE_FILES_FAILURE';
const REMOVE_FILES_START = 'files/REMOVE_FILES_START';
const REMOVE_FILES_SUCCESS = 'files/REMOVE_FILES_SUCCESS';
const REMOVE_FILES_FAILURE = 'files/REMOVE_FILES_FAILURE';

// ACTIONS
export const storeFiles = filesModule.actionCreators.storeEntities;

function loadFileStart() {
    return {
        type: LOAD_FILE_START,
    };
}

function loadFileSuccess() {
    return {
        type: LOAD_FILE_SUCCESS,
    };
}

function loadFileFailure(err = { message: '' }) {
    return {
        type: LOAD_FILE_FAILURE,
        payload: err.message,
    };
}

function removeFilesStart() {
    return {
        type: REMOVE_FILES_START,
    };
}

function removeFilesSuccess() {
    return {
        type: REMOVE_FILES_SUCCESS,
    };
}

function removeFilesFailure(err = { message: '' }) {
    return {
        type: REMOVE_FILES_FAILURE,
        payload: err.message,
    };
}

function updateFilesSuccess() {
    return {
        type: UPDATE_FILES_SUCCESS,
    };
}

function updateFilesFailure(errorMessage: string) {
    return {
        type: UPDATE_FILES_FAILURE,
        payload: errorMessage,
    };
}

export function loadFile(fileId: number): ClientCommonAction<Promise<Mark43File>> {
    const resource = getFilesResource();
    return (dispatch) => {
        dispatch(loadFileStart());
        return resource
            .getFile(fileId)
            .then((file: Mark43File) => {
                dispatch(storeFiles(file));
                dispatch(loadFileSuccess());
                return file;
            })
            .catch((err: Error) => dispatch(loadFileFailure(err)));
    };
}

export function updateFiles(results: Mark43File[]): ClientCommonAction<Promise<Mark43File[]>> {
    const filesResource = getFilesResource();
    return (dispatch, getState, { nexus }) => {
        return filesResource
            .updateFiles(results)
            .then((files: Mark43File[]) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: files,
                        },
                        updateFilesSuccess()
                    )
                );
                return files;
            })
            .catch((err: Error) => {
                dispatch(updateFilesFailure(err.message));
                throw err;
            });
    };
}

export function removeFiles(fileIds: number[]): ClientCommonAction<Promise<void>> {
    const filesResource = getFilesResource();
    return (dispatch, getState, { nexus }) => {
        dispatch(removeFilesStart());
        return filesResource
            .bulkDeleteFiles(fileIds)
            .then(() => {
                nexus.withRemoveMultiple(
                    {
                        [NEXUS_STATE_PROP]: map(fileIds, (fileId) => ({
                            id: fileId,
                        })),
                    },
                    removeFilesSuccess()
                );
            })
            .catch((err: Error) => {
                dispatch(removeFilesFailure(err));
                throw err;
            });
    };
}

// SELECTORS

export const fileByIdSelector = filesModule.selectors.entityByIdSelector;

// REDUCER
export default filesModule.reducerConfig;

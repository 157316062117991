import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'arc';

const FlexColumn = styled.div`
    flex: 1 0 ${(props) => props.flexBasis};
    margin-right: ${(props) => props.marginRight};

    &:first-child {
        padding: 0 var(--arc-space-6);
    }

    &:last-child {
        padding: 0 var(--arc-space-6);
    }

    @media (min-width: ${mediaQueries.md}) {
        overflow: auto;
        max-width: ${(props) => props.maxWidth || 'none'};

        &:first-child {
            padding-right: 0;
        }

        &:last-child {
            padding-left: 0;
        }
    }

    /* an empty column should never take up any space in this layout, so we force it to collapse */
    &:empty {
        flex: 0 0 0%;
    }
`;

const StyledContent = styled('div')`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.white};
    gap: var(--arc-space-6);
    padding: 20px 0 0;
    margin-bottom: 62px;
    overflow: auto;
    flex: 1;
    min-height: 0;
    width: 100%;

    @media (min-width: ${mediaQueries.md}) {
        overflow: unset;
        gap: var(--arc-space-12);
        flex-direction: row;
        margin-bottom: 0;
        padding-bottom: 62px;
    }
`;

export function ExportPageWithColumns({ leftColumn, rightColumn }) {
    return (
        <StyledContent>
            <FlexColumn children={leftColumn} flexBasis="50%" maxWidth="50%" />
            <FlexColumn children={rightColumn} flexBasis="45%" />
        </StyledContent>
    );
}

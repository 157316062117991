import { useSelector } from 'react-redux';
import React from 'react';

import * as fields from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import { externalReportStatusForReportingEventIdSelector } from '~/client-common/core/domain/external-report-statuses/state/data';
import SummaryRowDate from '../../../../legacy-redux/components/summaries/SummaryRowDate';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';

import { SummaryRowAttribute } from '../../../../legacy-redux/components/summaries/SummaryRowAttribute';
import { CardSubsection } from '../../../../legacy-redux/components/core/Card';

export const ExternalReportStatusSummary: React.FC<{ reportingEventId: number }> = ({
    reportingEventId,
}) => {
    const externalReportStatus = useSelector(externalReportStatusForReportingEventIdSelector)(
        reportingEventId
    );
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const externalReportStatusTitle = formatFieldByName(
        fields.EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID
    );

    const externalReportStatusAttrId = externalReportStatus?.externalReportStatusAttrId;
    return !!externalReportStatusAttrId ? (
        <SummaryList labelWidth={160} contentWidth={380}>
            <CardSubsection
                title={externalReportStatusTitle}
                // temporarily set these as undefined
                // until we move `CardSubsection` to TS
                className={undefined}
                testId={undefined}
            >
                <SummaryRowAttribute
                    fieldName={fields.EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID}
                    attributeId={externalReportStatusAttrId}
                />
                <SummaryRowDate
                    fieldName={fields.EXTERNAL_REPORT_STATUS_STATUS_DATE_UTC}
                    date={externalReportStatus?.statusDateUtc}
                />
                <SummaryRowAttribute
                    fieldName={fields.EXTERNAL_REPORT_STATUS_CLOSED_BY_DIVISION_ATTR_ID}
                    attributeId={externalReportStatus?.closedByDivisionAttrId}
                />
                <SummaryRowAttribute
                    fieldName={fields.EXTERNAL_REPORT_STATUS_CLOSED_BY_UNIT_ATTR_ID}
                    attributeId={externalReportStatus?.closedByUnitAttrId}
                />
            </CardSubsection>
        </SummaryList>
    ) : null;
};

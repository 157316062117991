import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight, pick, trim } from 'lodash';

import { caseTitleViewModelsSelector } from '~/client-common/core/domain/case-titles/state/ui';
import { reportShortTitleViewModelsSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import withFields from '~/client-common/core/fields/components/withFields';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import Row from '../../../core/components/Row';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAsyncText } from '../../../core/forms/components/AsyncText';
import LinkedCases from '../../../records/core/components/sidebar/LinkedCases';
import LinkedReports from '../../../records/core/components/sidebar/LinkedReports';

import editCaseRenForm from '../state/form/editCaseRenForm';
import { submitCaseRenSearch } from '../state/ui';

const modalContext = { name: boxEnum.EDIT_CASE_REN_MODAL };
const strings = componentStrings.cases.core.EditCaseRenModal;

function EditCaseRenForm({
    existingCaseIds,
    existingReportIds,
    submitCaseRenSearch,
    caseTitleViewModels,
    reportShortTitleViewModels,
    fieldDisplayNames,
}) {
    const existingCaseTitleViewModels = pick(caseTitleViewModels, existingCaseIds);
    const existingReportShortTitleViewModels = pick(reportShortTitleViewModels, existingReportIds);
    return (
        <ReactReduxForm {...editCaseRenForm}>
            <Row>
                <RRFText
                    label={strings.existingCaseRen(fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER)}
                    path="existingCaseRen"
                    width={250}
                    disabled={true}
                />
            </Row>
            <Row>
                <RRFAsyncText
                    label={strings.newCaseRen(fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER)}
                    path="newCaseRen"
                    width={250}
                    alterValue={(value) => trim(value)}
                    asyncAction={submitCaseRenSearch}
                />
            </Row>
            <Row>
                <LinkedCases caseTitleViewModels={existingCaseTitleViewModels} />
            </Row>
            <Row>
                <LinkedReports reportShortTitleViewModels={existingReportShortTitleViewModels} />
            </Row>
        </ReactReduxForm>
    );
}

const mapStateToProps = createStructuredSelector({
    caseTitleViewModels: caseTitleViewModelsSelector,
    reportShortTitleViewModels: reportShortTitleViewModelsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    submitCaseRenSearch: () =>
        dispatch(
            submitCaseRenSearch({
                boxContext: modalContext,
                form: editCaseRenForm,
                fieldPath: 'newCaseRen',
            })
        ),
});

/**
 * Form for changing the Case REN on an existing case.
 */
export default flowRight(
    connect(mapStateToProps, mapDispatchToProps),
    withFields([REPORT_REPORTING_EVENT_NUMBER])
)(EditCaseRenForm);

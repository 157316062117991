import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reduce, find, findIndex } from 'lodash';
import { EFileNameSearchRequest, EFileNameSearchView } from '@mark43/rms-api';
import redirectToErrorPage from '../../core/utils/redirectToErrorPage';

import { EFileHandlersT, EFileReducerType, EFileActionsT, EFileSearchRequest } from '../types';
import { INVOLVED_PROFILES_PAGE_SIZE } from '../constants';
import { eFileResource } from '../resources';

/**
 * This hook can only be used within the E-File Module and the eFileContext
 * Using this outside of the module will cause errors
 * @returns EFileHandlersT
 */

export function useEFileHandlers(state: EFileReducerType, actions: EFileActionsT): EFileHandlersT {
    const globalDispatch = useDispatch();

    const getInvolvedProfiles = useCallback(
        (searchQuery: EFileSearchRequest<EFileNameSearchRequest>, efileId?: number) => {
            return efileId
                ? eFileResource.getInvolvedProfiles({
                      ...searchQuery,
                      from: searchQuery.from || 0,
                      size: searchQuery.size || INVOLVED_PROFILES_PAGE_SIZE,
                      query: {
                          ...searchQuery.query,
                          efileId,
                      },
                  })
                : Promise.resolve(undefined);
        },
        []
    );

    const onFetchError = useCallback(() => {
        globalDispatch(redirectToErrorPage());
    }, [globalDispatch]);

    const updateInvolvedNamesDeactivatedState = (eFileLinkId: number, isDeactivated: boolean) => {
        const defendantsSearchResults = state.search.defendants;
        if (!defendantsSearchResults) {
            return;
        }
        const defendants = state.search.defendants?.items;

        if (!defendants) {
            return;
        }

        const updatedDefendants = reduce(
            defendants,
            (acc, item) => {
                const entityProfile = item.person ? item.personProfile : item.organizationProfile;
                if (entityProfile) {
                    const { nameEFileLinks } = entityProfile;
                    const nameEFileLink = find(nameEFileLinks, { id: eFileLinkId });

                    if (nameEFileLink) {
                        return [
                            ...acc,
                            {
                                ...item,
                                isDeactivated,
                            },
                        ];
                    }
                }

                return [...acc, item];
            },
            [] as EFileNameSearchView[]
        );
        actions.search.setEFileDefendants({
            ...defendantsSearchResults,
            items: updatedDefendants,
        });
    };

    const updateInvolvedProfileDeactivatedState = (eFileLinkId: number, isDeactivated: boolean) => {
        const defendantOrganizationProfile =
            state.selectedEFileEntity.defendantOrganizationProfile?.hydratedOrganization;
        const defendantPersonProfile =
            state.selectedEFileEntity.defendantPersonProfile?.hydratedPerson;

        if (defendantPersonProfile) {
            const index = findIndex(defendantPersonProfile.nameEFileLinks, { id: eFileLinkId });

            if (index !== -1) {
                defendantPersonProfile.nameEFileLinks[index] = {
                    ...defendantPersonProfile.nameEFileLinks[index],
                    isDeactivated,
                };
                actions.selectedEFileEntity.setEFileDefendantPersonProfile({
                    hydratedPerson: defendantPersonProfile,
                });
            }
        }

        if (defendantOrganizationProfile) {
            const index = findIndex(defendantOrganizationProfile.nameEFileLinks, {
                id: eFileLinkId,
            });

            if (index !== -1) {
                defendantOrganizationProfile.nameEFileLinks[index] = {
                    ...defendantOrganizationProfile.nameEFileLinks[index],
                    isDeactivated,
                };
                actions.selectedEFileEntity.setEFileDefendantOrganizationProfile({
                    hydratedOrganization: defendantOrganizationProfile,
                });
            }
        }
    };

    const removeInvolvedNamesFromState = (eFileLinkId: number) => {
        const defendantsSearchResults = state.search.defendants;
        if (!defendantsSearchResults) {
            return;
        }
        const defendants = state.search.defendants?.items;

        if (!defendants) {
            return;
        }

        const updatedDefendants = reduce(
            defendants,
            (acc, item) => {
                const entityProfile = item.person ? item.personProfile : item.organizationProfile;
                if (entityProfile) {
                    const { nameEFileLinks } = entityProfile;
                    const nameEFileLink = find(nameEFileLinks, { id: eFileLinkId });

                    if (!nameEFileLink) {
                        acc = [...acc, item];
                    }
                }

                return acc;
            },
            [] as EFileNameSearchView[]
        );
        actions.search.setEFileDefendants({
            ...defendantsSearchResults,
            totalCount: updatedDefendants.length,
            items: updatedDefendants,
        });
    };

    return {
        getInvolvedProfiles,
        onFetchError,
        updateInvolvedNamesDeactivatedState,
        updateInvolvedProfileDeactivatedState,
        removeInvolvedNamesFromState,
    };
}

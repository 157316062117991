import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { N_ITEMS_FIELDSET, REPORT_DEFINITION, NIBRS_CODE, NIBRS_CODE_ID } = fieldTypeClientEnum;
const strings =
    componentStrings.cases.unassignedReports.UnassignedReportsSearchReportDefinitionFieldset;

/**
 * Fieldset view model.
 */
export default {
    type: N_ITEMS_FIELDSET,
    key: 'reportDefinitions',
    title: strings.title,
    filterLabel: strings.title,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'reportDefinitionId',
            type: REPORT_DEFINITION,
            label: strings.labels.reportDefinitionId,
        },
        // deprecated field
        {
            key: 'nibrsCodes',
            type: NIBRS_CODE,
            fieldName: fields.DISPLAY_ONLY_OFFENSE_NIBRS_CODE_CODE,
        },
        {
            key: 'nibrsCodeIds',
            type: NIBRS_CODE_ID,
            fieldName: fields.DISPLAY_ONLY_OFFENSE_NIBRS_CODE_CODE,
        },
        {
            key: 'nibrsGroups',
            fieldName: fields.DISPLAY_ONLY_OFFENSE_GROUP_LABEL,
        },
        {
            key: 'ucrGroups',
            fieldName: fields.DISPLAY_ONLY_OFFENSE_GROUP_LABEL,
        },
    ]),
};

import * as React from 'react';
import { differenceWith } from 'lodash';

import { ResolvedEntityResult } from './dragon-select-state-reducer';
import { convertObjectsToDragonOptions } from './convert-objects-to-dragon-options';

export type DragonOption = { value: string | number | boolean; display: string };

type DragonSelectMappedValue = Record<string, unknown> | Record<string, unknown>[];
type DragonSelectToMftChangeHandler = (value: DragonSelectMappedValue) => void;
export type UseDragonSelectBaseResult = {
    onChange: (value: number | number[]) => void;
    value?: number | number[];
    options: DragonOption[];
};

export type UseDragonSelectBaseOptions = {
    optionData?: ResolvedEntityResult;
    displayPropertyId: number;
    sortPropertyId?: number;
    valuePropertyId: number;
    onChange: DragonSelectToMftChangeHandler;
    onBlur?: (value: number | number[]) => void;
    value?: Record<string, unknown> | Record<string, unknown>[];
    isMultiple: boolean;
};

function notNullOrUndefined<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
}

function isNumberOrString(val: unknown): val is number | string {
    return typeof val === 'string' || typeof val === 'number';
}

export function useDragonSelectBase({
    optionData,
    valuePropertyId,
    displayPropertyId,
    sortPropertyId,
    onChange,
    value,
    isMultiple,
}: UseDragonSelectBaseOptions): UseDragonSelectBaseResult {
    const possiblySortedOptionData = React.useMemo(() => {
        if (sortPropertyId) {
            return (
                optionData?.sort((optionA, optionB) => {
                    const sortValueA = optionA[sortPropertyId];
                    const sortValueB = optionB[sortPropertyId];
                    if (isNumberOrString(sortValueA) && isNumberOrString(sortValueB)) {
                        if (sortValueA > sortValueB) {
                            return 1;
                        }
                        if (sortValueA < sortValueB) {
                            return -1;
                        }
                        return 0;
                    } else {
                        throw new Error(
                            'Select option sort value was neither a number nor a string'
                        );
                    }
                }) ?? []
            );
        }
        return optionData;
    }, [optionData, sortPropertyId]);

    let options = React.useMemo(
        () =>
            convertObjectsToDragonOptions(
                displayPropertyId,
                valuePropertyId,
                possiblySortedOptionData
            ),
        [displayPropertyId, valuePropertyId, possiblySortedOptionData]
    );

    const existinValuesWithoutOptions: Record<string, unknown>[] = React.useMemo(() => {
        const existingValuesToCheck = value ? (Array.isArray(value) ? value : [value]) : undefined;

        return differenceWith(
            existingValuesToCheck,
            options,
            (a, b) => a[valuePropertyId] === b.value
        );
    }, [value, valuePropertyId, options]);

    // ensure that values saved to a field show up regardless of whether or not a) options have been fetched b) they contain the existing values
    if (!!existinValuesWithoutOptions.length) {
        const optionsToAdd = existinValuesWithoutOptions.length
            ? convertObjectsToDragonOptions(
                  displayPropertyId,
                  valuePropertyId,
                  existinValuesWithoutOptions as ResolvedEntityResult
              )
            : undefined;
        if (optionsToAdd) {
            options = options ? options.concat(optionsToAdd) : optionsToAdd;
        }
    }

    // because we do not send expired instances across the wire we have to ensure that
    // we add them to our indexed instances. because this is based on the form value,
    // this will cause computation on every value change. This might not be an issue
    // but we should keep an eye on this. Additionally we might contemplate removing the `useMemo`
    // call outright if it doesn't really provide a tangible benefit.
    const indexedInstances = React.useMemo(
        () =>
            (optionData ?? [])
                .concat(existinValuesWithoutOptions)
                .reduce<{ [index: string]: Record<string, unknown> }>((acc, data) => {
                    // We have to assert the type here because `unknown` cannot be used as an index type.
                    acc[data[valuePropertyId] as string | number] = data;
                    return acc;
                }, {}) ?? {},
        [optionData, existinValuesWithoutOptions, valuePropertyId]
    );

    const wrappedOnChange = React.useCallback(
        (selectedValue: number | number[]) => {
            // Dragon requires the full instance to be sent.
            // Because of this we have to persist it into form state instead of just the selected id value.
            onChange(
                Array.isArray(selectedValue)
                    ? selectedValue.map((val: number) => indexedInstances[val])
                    : indexedInstances[selectedValue]
            );
        },
        [onChange, indexedInstances]
    );

    const valuePropertyToNumber = (selectedObjectInstance: Record<string, unknown>): number => {
        const value = selectedObjectInstance[valuePropertyId];
        if (typeof value !== 'string' && typeof value !== 'number') {
            throw new Error(
                'Unexpectedly received a non string/number value while converting select values'
            );
        }
        return parseInt(value.toString(), 10);
    };

    let mappedValue: number | number[] | undefined;

    if (value) {
        if (isMultiple) {
            mappedValue = Array.isArray(value)
                ? value.filter(notNullOrUndefined).map(valuePropertyToNumber)
                : [];
        } else {
            if (Array.isArray(value)) {
                throw new Error('Unexpectedly found array value for non-array field');
            }
            mappedValue = valuePropertyToNumber(value);
        }
    }
    return {
        options,
        value: mappedValue,
        onChange: wrappedOnChange,
    };
}

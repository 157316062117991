import { createSelector } from 'reselect';
import { find, get, map } from 'lodash';
import { Printable, ExportOption } from '@mark43/rms-api';
import { reportPrintingTemplatesSelector } from '~/client-common/core/domain/report-printing-templates/state/data';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { rawPacketOptionsSelector } from '../../../../../../legacy-redux/selectors/exportsSelectors';
import { FILLABLE_PDF_TEMPLATE_TYPES } from '../../../../../../legacy-redux/configs/exportConfig';

export interface ReportTypePacket {
    display: string;
    reportDefinitionId?: number | null;
    reportPrintingTemplateId?: number;
    exportConfigs: Omit<ExportOption, 'exportOptionValue'>[];
}

export const packetsForExportPresetReportTypesSelector = createSelector(
    [rawPacketOptionsSelector, reportPrintingTemplatesSelector],
    (reportTypePrintables: Printable[], reportPrintingTemplates): ReportTypePacket[] => {
        return map(reportTypePrintables, (reportType) => {
            const exportConfigs = map(reportType.exportOptions, (option) => ({
                exportOptionDisplay: option.exportOptionDisplay,
                exportOptionName: option.exportOptionName,
                isEnabled: false,
            }));
            const reportPrintingTemplate = find(
                reportPrintingTemplates,
                (rpt) =>
                    rpt.printingTemplateId === reportType.printingTemplateId &&
                    (rpt.reportDefinitionId === reportType.entityId ||
                        isUndefinedOrNull(rpt.reportDefinitionId))
            );
            const displayPrefix = FILLABLE_PDF_TEMPLATE_TYPES.includes(reportType.templateType)
                ? 'Fillable PDF - '
                : '';
            return {
                display: `${displayPrefix}${reportType.groupTitle}`,
                reportDefinitionId: reportType.entityId,
                reportPrintingTemplateId: get(reportPrintingTemplate, 'id'),
                exportConfigs,
            };
        });
    }
);

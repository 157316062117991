import factory from './actionTypesFactory';

export default factory('reports', [
    'STORE_LINKED_REPORT_IDS',
    'SET_REPORT',
    'STORE_REPORTS',
    'SET_REPORT_NARRATIVE',
    'SET_REPORT_AGENCY_ID',
    'SET_REPORT_DESCRIPTION',
    'SET_CARD_SIDEBAR_TITLE',
    'SET_CAN_SUBMIT',
    'SET_REPORT_STATUS_VIEW',
    'SET_REPORT_IS_PACKAGED',
    'SET_REPORT_IS_UCR_VALIDATED',
    'SET_LINKED_WARRANT_IDS',
    'FETCH_REPORT',
    'FETCH_REPORT_SUCCESS',
    'FETCH_REPORT_FAILURE',
    'LEAVE_REPORT',
    'SET_LAST_CREATED_REPORT_ID',
    'CLEAR_ALL_REPORT_UI_DATA',
    'SET_EVENT_INFO_CARD_PREFILL',
    'SET_SUPPLEMENT_INFO_CARD_PREFILL',
]);

import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { BlueActionBar } from '../../../core/components/ActionBar';
// import { CustomLink } from '../../../core/components/links/Link'; TODO: RMS-9947

const strings = componentStrings.cases.core.CasesActionBar;

const BulkCasesActionBar = styled(BlueActionBar)`
    background-color: ${(props) => props.theme.colors.brightBlue};
    color: ${(props) => props.theme.colors.white};
    line-height: 52px;
    display: flex;
    align-items: center;
`;

/* TODO: RMS-9947
const ClickableText = styled(CustomLink)`
    margin-left: 10px;
    color: ${props => props.theme.colors.white};
`;
*/

/**
 * The bar that appears below the cases results table when a case is selected.
 */
export default function CasesActionBar({
    buttons,
    totalResultCount = 0,
    selectedResultCount = 0,
    allResultsSelected = false,
    //    onClickSelectAll, TODO: RMS-9947
    //    onClickClearSelection, TODO: RMS-9947
    visible,
}) {
    const actionBarText = (
        <>
            <span>
                {allResultsSelected || selectedResultCount === totalResultCount
                    ? strings.allSelected(totalResultCount)
                    : strings.someOnPageSelected(selectedResultCount)}
            </span>
            {/* TODO: RMS-9947
            allResultsSelected || selectedResultCount === totalResultCount ? (
                <ClickableText onClick={onClickClearSelection}>
                    {strings.clearSelection}
                </ClickableText>
            ) : (
                <ClickableText
                    // onClick={onClickSelectAll} -
                >
                    {strings.selectAll(totalResultCount)}
                </ClickableText>
            )*/}
        </>
    );
    return (
        <BulkCasesActionBar visible={visible}>
            {buttons}
            {actionBarText}
        </BulkCasesActionBar>
    );
}

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const withDraggable = (draggableId, index) => (Component) => {
    return (
        <Draggable key={draggableId} draggableId={draggableId} index={index}>
            {(provided, snapshot) => {
                return (
                    <div {...provided.draggableProps} ref={provided.innerRef}>
                        <Component
                            dragHandleProps={provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                        />
                    </div>
                );
            }}
        </Draggable>
    );
};

export default withDraggable;

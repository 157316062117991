import React from 'react';
import { useDispatch } from 'react-redux';

import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../core/testIds';
import { OnlyWithAbility, abilitiesEnum } from '../../core/abilities';
import Page from '../../core/components/Page';
import Subheader from '../../core/components/Subheader';
import ScrollableUnderSubheader from '../../core/components/ScrollableUnderSubheader';
import { ButtonGroup } from '../../core/components/Button';

import { openBriefingRoutingModal } from '../state/ui';
import { BriefingRoutingModalType } from '../types';
import { ActionButton } from '../utils/styledComponents';

import { BriefingsDashboardResults } from './dashboard/BriefingsDashboardResults';
import BriefingRoutingModal from './BriefingRoutingModal';

const strings = componentStrings.briefings.dashboard;

const BriefingsDashboard: React.FC = () => {
    const dispatch = useDispatch();

    const handleBriefingCreation = () => {
        dispatch(openBriefingRoutingModal(BriefingRoutingModalType.CREATE));
    };

    return (
        <Page>
            <Subheader title={strings.title}>
                <ButtonGroup>
                    <OnlyWithAbility has={abilitiesEnum.BRIEFING.EDIT_BRIEFING}>
                        <ActionButton
                            variant="solid"
                            isTextTransformNone
                            testId={testIds.BRIEFING_CREATE_BUTTON}
                            onClick={handleBriefingCreation}
                        >
                            {strings.createNewBriefing}
                        </ActionButton>
                        <BriefingRoutingModal />
                    </OnlyWithAbility>
                </ButtonGroup>
            </Subheader>

            <ScrollableUnderSubheader>
                <BriefingsDashboardResults />
            </ScrollableUnderSubheader>
        </Page>
    );
};

export default BriefingsDashboard;

import React from 'react';
import styled from 'styled-components';

import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../components/Icon';
import testIds from '../../../../core/testIds';

const SidePanelContainer = styled.div`
    &,
    * {
        box-sizing: border-box;
    }
`;
const Header = styled.div`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
`;
const BackButton = styled(Button)`
    display: inline-block;
    margin: 10px 0 0 24px;
    font-size: var(--arc-fontSizes-lg);

    && .textvalignmiddle {
        vertical-align: baseline;
    }
`;
// align corrects for any button floats
const LeftHeaderContent = styled.div`
    display: inline-block;
    width: 468px;
    padding: 20px 28px 0 28px;
    vertical-align: top;
`;
// padding offsets content 2px border
const RightHeaderContent = styled(LeftHeaderContent)`
    padding-left: 30px;
`;
const HeaderLabel = styled.div`
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.darkGrey};
`;
const HeaderSecondary = styled.div`
    margin: 7px 0 10px 0;
    height: 30px;
    font-size: var(--arc-fontSizes-md);
`;
const LeftContentWrapper = styled.div`
    position: absolute;
    width: 468px;
    top: ${({ hideBackButton }) => (hideBackButton ? 121 : 153)}px;
    bottom: ${({ hideFooter }) => (hideFooter ? 0 : 54)}px;
    overflow: auto;
    padding: 20px 28px 10px 28px;
`;
const RightContentWrapper = styled(LeftContentWrapper)`
    left: 468px;
    width: 470px;
    box-sizing: border-box;
    border-left: 2px solid ${(props) => props.theme.colors.lightGrey};
`;

/**
 * Side Panel that is double wide. Used to display two entities side by side.
 * Fixed width of 940px. Header is fixed height, 14px label and secondary
 * content can accommodate a button or 14px text. In addition to props below,
 * SidePanel props will be passed through.
 * @param {boolean} props.hideBackButton hide back button
 * @param {function} props.onBack callback for back button
 * @param {string} props.leftHeaderLabel label left header
 * @param {string} props.rightHeaderLabel label right header
 * @param {*} props.leftHeaderSecondary left header secondary content
 * @param {*} props.rightHeaderSecondary right header secondary content
 * @param {*} props.leftContent content for left side of panel
 * @param {*} props.rightContent content for right side of panel
 */
export default function DoubleSidePanel({
    context,
    title,
    leftHeaderLabel,
    leftHeaderSecondary,
    rightHeaderLabel,
    rightHeaderSecondary,
    leftContent,
    rightContent,
    onBack,
    onSave,
    onCancel,
    saveText,
    cancelText,
    saveDisabled,
    hideFooter,
    hideBackButton,
    renderChildrenWrapper,
}) {
    const children = (
        <SidePanelContainer>
            <Header>
                {!hideBackButton && (
                    <BackButton
                        iconLeft={iconTypes.OPEN_LEFT}
                        onClick={onBack}
                        className={buttonTypes.ICON_LINK}
                    >
                        Back
                    </BackButton>
                )}
                <div>
                    <LeftHeaderContent>
                        <HeaderLabel>{leftHeaderLabel}</HeaderLabel>
                        <HeaderSecondary>{leftHeaderSecondary}</HeaderSecondary>
                    </LeftHeaderContent>
                    <RightHeaderContent>
                        <HeaderLabel>{rightHeaderLabel}</HeaderLabel>
                        <HeaderSecondary>{rightHeaderSecondary}</HeaderSecondary>
                    </RightHeaderContent>
                </div>
            </Header>
            <LeftContentWrapper hideBackButton={hideBackButton} hideFooter={hideFooter}>
                {leftContent}
            </LeftContentWrapper>
            <RightContentWrapper
                hideBackButton={hideBackButton}
                hideFooter={hideFooter}
                data-test-id={testIds.DOUBLE_SIDE_PANEL_RIGHT_CONTENT}
            >
                {rightContent}
            </RightContentWrapper>
        </SidePanelContainer>
    );
    return (
        <SidePanel
            context={context}
            useDefaultStyle={false}
            hideFooter={hideFooter}
            width={940}
            title={title}
            onSave={onSave}
            onCancel={onCancel}
            saveText={saveText}
            cancelText={cancelText}
            saveDisabled={saveDisabled}
        >
            {renderChildrenWrapper ? renderChildrenWrapper(children) : children}
        </SidePanel>
    );
}

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/print';

export default createResource({
    name: 'Chain of Custody Print Resource',
    methods: {
        // The printables method lives inside `client-common/core/domain/printables/resources/printablesResource`

        /**
         * Export selected chain of custody PDFs
         * Matches the print method inside `exportsResource`
         * @param  {Object} data
         * @return {Promise<Object[]>}
         */
        exportChainOfCustody(data) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: BASE_PATH,
                data,
            });
        },
    },
});

import { SystemRuleEnum } from '@mark43/rms-api';
import addRuleId from '../../../../../legacy-redux/validation/helpers/addRuleId';
import { caseReviewInRejectionStateSelector } from '../ui';

export const caseReviewMinLengthRequired = addRuleId(
    SystemRuleEnum.CASE_REVIEW_MIN_LENGTH_REQUIRED_IF_REJECTED.name,
    (formData, value, state) => {
        const { body = '' } = formData;
        return caseReviewInRejectionStateSelector(state) ? body.length > 20 : true;
    }
);

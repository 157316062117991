import { map } from 'lodash';
import { createSelector } from 'reselect';

import type { ReportModuleView as BaseReportModuleView } from '@mark43/rms-api';
import createNormalizedModule from '~/client-common/core/utils/createNormalizedModule';
import { reportDefinitionsSelector } from '~/client-common/core/domain/report-definitions/state/data';

import { RmsAction } from '../../../../../core/typings/redux';
import reportModuleResource from '../../resources/reportModuleResource';
import { maybeAppendDefaultReportModule, sortReportModules } from '../../utils/reportModuleHelpers';

/**
 * Import this type instead of the type from mark43-resources unless you need the IVersionable properties.
 */
export type ReportModuleView = Pick<
    BaseReportModuleView,
    'id' | 'name' | 'url' | 'icon' | 'moduleOrder' | 'isDefault' | 'reportDefinitionIds'
>;

const NEXUS_STATE_PROP = 'reportModules';

/**
 * There is no module for ReportModuleDefinitions.
 * This module contains both ReportModule and ReportModuleDefinition combined in one view model.
 */
const module = createNormalizedModule<ReportModuleView>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

export const reportModulesSelector = createSelector(module.selectors.entitiesSelector, (entities) =>
    sortReportModules(entities)
);

export default module.reducerConfig;

/**
 * Completely replace all ReportModuleViews in Nexus data state.
 */
export function setReportModules(reportModules: ReportModuleView[]): RmsAction<ReportModuleView[]> {
    return (dispatch, getState, { nexus }) => {
        const state = getState();
        const allReportDefinitionIds = map(reportDefinitionsSelector(state), 'id');

        const reportModuleViews = maybeAppendDefaultReportModule(
            reportModules,
            allReportDefinitionIds
        );
        dispatch(
            nexus.withEntityItems(
                {
                    [NEXUS_STATE_PROP]: reportModuleViews,
                },
                {
                    type: 'STORE_REPORT_MODULES',
                }
            )
        );
        return reportModuleViews;
    };
}

export function loadReportModules(): RmsAction<Promise<ReportModuleView[]>> {
    return (dispatch) => {
        return reportModuleResource.getReportModules().then((reportModules: ReportModuleView[]) => {
            return dispatch(setReportModules(reportModules));
        });
    };
}

import React from 'react';
import classNames from 'classnames';

export default function HeaderSecondaryInfo({ label, children: value }) {
    return (
        <div className={classNames('secondary-info clearfix', !label ? 'no-label' : '')}>
            {label && <span className="secondary-info-label">{label}</span>}
            <span className="secondary-info-value">{value}</span>
        </div>
    );
}

import { map, filter } from 'lodash';
import { createSelector } from 'reselect';
import { userRolesSelector } from '../data';
import { miniUserViewModelsSelector } from '../../../mini-users/state/ui';
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';

const userRoleViewModelsSelector = createSelector(
    userRolesSelector,
    miniUserViewModelsSelector,
    (userRoles, miniUserViewModels) => {
        const viewModel = buildViewModel({
            mappers: [
                ({ updatedBy }) => ({
                    updatedBy: miniUserViewModels[updatedBy],
                }),
            ],
        });

        return map(userRoles, viewModel);
    }
);

export const userRoleViewModelsWhereSelector = createSelector(
    userRoleViewModelsSelector,
    (userRoleViewModels) => (predicate) => filter(userRoleViewModels, predicate)
);

import React, { useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { pageSizeToFrom } from '~/client-common/helpers/searchHelpers';
import { REPORT_REPORTING_EVENT_NUMBER, DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL } from '~/client-common/core/enums/universal/fields';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { profileReportsViewModelsSelector, uiReportsPaginationSelector } from '../../core/state/ui';
import { fetchAndStoreRelatedReports } from '../../core/state/data';
import { InlineBanner } from '../../../core/components/InlineBanner';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import InfoCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/InfoCell';
import ReportTypeCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportTypeCell';
import PrimaryLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/PrimaryLocationCell';
import InvolvementCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/InvolvementCell';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import { queryParamDefaults } from '../../core/configuration';
import EntityProfileReportsWithFilterTableHeader from '../../core/components/EntityProfileReportsWithFilterTableHeader';
import entityProfileReportsFilterForm from '../../core/state/forms/entityProfileReportsFilterForm';

const strings = componentStrings.entityProfiles.EntityProfilePersonReports;

const EventStartUtcCell = dateCellFactory('eventStartUtc');
const ReportCreatedUtcCell = dateCellFactory('reportCreationDateUtc');

const DEFAULT_SORT_KEY = queryParamDefaults.sortKey;
const DEFAULT_SORT_TYPE = queryParamDefaults.sortType;

const columnKeyToValueMap = {
    eventStartUtc: sortKeyEnum.REPORT_EVENT_START_UTC,
    reportCreationDateUtc: sortKeyEnum.REPORT_REPORT_CREATION_DATE_UTC,
};

function useSortChangeHandler({ entityType, entityId }) {
    const [currentSortType, setCurrentSortType] = useState(DEFAULT_SORT_TYPE);
    const [currentSortKey, setCurrentSortKey] = useState(DEFAULT_SORT_KEY);
    const [currentColumnKey, setCurrentColumnKey] = useState('reportCreationDateUtc');
    const dispatch = useDispatch();

    return {
        currentSortType,
        currentSortKey,
        currentColumnKey,
        handleSortChange: ({ from, size, columnKey, sortType }) => {
            if (columnKey) {
                setCurrentColumnKey(columnKey);
                setCurrentSortKey(columnKeyToValueMap[columnKey]);
            }

            if (sortType) {
                setCurrentSortType(sortType);
            }

            dispatch(
                entityProfileReportsFilterForm.actionCreators.submit((formData) => {
                    dispatch(
                        fetchAndStoreRelatedReports(
                            entityType,
                            entityId,
                            {
                                from,
                                size,
                                sortKey: columnKey
                                    ? columnKeyToValueMap[columnKey]
                                    : currentSortKey,
                                sortType: sortType || currentSortType,
                            },
                            formData
                        )
                    );
                })
            );
        },
    };
}

function EntityProfilePersonReports({
    uiReportsPagination,
    profileReports,
    formatFieldByName,
    handleRowClick,
    handlePaginationClick,
    params: { entityType, entityId },
}) {
    const { loading, from, size, totalCount, fetchErrorMessage } = uiReportsPagination;
    const {
        currentSortType,
        currentSortKey,
        currentColumnKey,
        handleSortChange,
    } = useSortChangeHandler({ entityType, entityId });

    const error = fetchErrorMessage ? (
        <th colSpan={5}>
            <InlineBanner status="error">{fetchErrorMessage}</InlineBanner>
        </th>
    ) : null;

    return (
        <Table
            data={profileReports}
            onRowClick={handleRowClick}
            sortKey={currentSortKey}
            sortType={currentSortType}
            noRowsText={strings.noResults}
            simpleStyles={true}
            rowComponent={ElasticResultRow}
            disableBody={loading}
        >
            <TableHeader>
                {error}
                <EntityProfileReportsWithFilterTableHeader
                    from={from}
                    size={size}
                    totalCount={totalCount}
                    onPaginationClick={(lastPage, nextPage) =>
                        handlePaginationClick(nextPage, size, currentSortKey, currentSortType)
                    }
                />
                <TableColumns>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={currentColumnKey}
                        activeSortType={currentSortType}
                        activeValue={currentSortKey}
                        onOptionClick={({ columnKey }) => {
                            handleSortChange({
                                from,
                                size,
                                columnKey,
                            });
                        }}
                        onSortTypeClick={(sortType) => handleSortChange({ from, size, sortType })}
                        width={132}
                        popoutWidth={160}
                    >
                        <OptionsTableColumnOption
                            display={strings.OptionsTableColumnOption.eventDate}
                            value={sortKeyEnum.REPORT_EVENT_START_UTC}
                            columnKey="eventStartUtc"
                            sortOptions={[
                                {
                                    display: strings.OptionsTableColumnOption.sortDesc,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.OptionsTableColumnOption.sortAsc,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                        <OptionsTableColumnOption
                            display={strings.OptionsTableColumnOption.createdDate}
                            value={sortKeyEnum.REPORT_REPORT_CREATION_DATE_UTC}
                            columnKey="reportCreationDateUtc"
                            sortOptions={[
                                {
                                    display: strings.OptionsTableColumnOption.sortDesc,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.OptionsTableColumnOption.sortAsc,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                    </OptionsTableColumn>
                    <TableColumn
                        display={strings.columns.renOwner.label(
                            formatFieldByName(REPORT_REPORTING_EVENT_NUMBER), formatFieldByName(DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL)
                        )}
                        columnKey="info"
                        width={127}
                    />
                    <TableColumn
                        display={strings.columns.reportType.label}
                        columnKey="reportType"
                        width={156}
                    />
                    <TableColumn
                        display={strings.columns.locations.label}
                        columnKey="locations"
                        width={214}
                    />
                    <TableColumn
                        display={strings.columns.involvement.label}
                        columnKey="involvement"
                        width={123}
                    />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <EventStartUtcCell columnKey="eventStartUtc" />
                <ReportCreatedUtcCell columnKey="reportCreationDateUtc" />
                <InfoCell columnKey="info" />
                <ReportTypeCell columnKey="reportType" />
                <PrimaryLocationCell columnKey="locations" />
                <InvolvementCell columnKey="involvement" />
            </TableBody>
        </Table>
    );
}

const mapStateToProps = createStructuredSelector({
    uiReportsPagination: uiReportsPaginationSelector,
    profileReports: profileReportsViewModelsSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleRowClick({ id }) {
        ownProps.router.push(`/reports/${id}`);
    },
    handlePaginationClick(
        nextPage,
        size,
        sortKey = DEFAULT_SORT_KEY,
        sortType = DEFAULT_SORT_TYPE
    ) {
        const from = pageSizeToFrom(nextPage, size);
        dispatch(
            entityProfileReportsFilterForm.actionCreators.submit((formData) => {
                dispatch(
                    fetchAndStoreRelatedReports(
                        ownProps.params.entityType,
                        ownProps.params.entityId,
                        {
                            from,
                            size,
                            sortKey,
                            sortType,
                        },
                        formData
                    )
                );
            })
        );
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EntityProfilePersonReports);

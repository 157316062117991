import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { isFunction, map } from 'lodash';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { formatLinkTypeLetterNumber } from '~/client-common/helpers/linkTypesHelpers';
import { deleteNameReportLink } from '~/client-common/core/domain/name-report-links/state/data';
import testIds from '../../../../../core/testIds';
import { openErrorModal } from '../../../../../legacy-redux/actions/boxActions';
import { PersonSidePanel } from '../../../../core/persons/components/PersonSidePanel';
import { OrganizationSidePanel } from '../../../../core/organizations/components/OrganizationSidePanel';
import OrganizationProfileSummary from './OrganizationProfileSummary';
import { PersonProfileSummary } from './PersonProfileSummary';
import { SummaryRemoveButton } from './buttons/SummaryRemoveButton';
import { SummaryEditButton } from './buttons/SummaryEditButton';

const SummaryWrapper = styled.div`
    margin-bottom: 20px;
`;

/**
 * Pass in an array of nameLinks. Consumer is responsible for calling nameLinksWhere, or creating a
 * fake nameLink in the case of arrest.defendantId.
 *
 * The intention of having RecordCardNameSummaries wrap PersonProfileSummary is that
 * RecordCardNameSummaries would be specifically for cards. If you want a similar summary elsewhere,
 * please create a new wrapper component around PersonProfileSummary, etc to avoid bloating this
 * component.
 * @param {Boolean} options.summaryMode            Whether or not component should be in summary mode or not
 * @param {Function} options.summaryModeItem       Whether or not item should be in summary mode or not
 * @param {Function} options.renderAdditionalItem
 * @param {Object[]} options.nameLinks
 * @param {String} options.parentEntityType        parentEntityType to be passed into Person/Org SidePanel
 * @param {Number} options.parentId                parentId to be passed into Person/Org SidePanel
 * @param {String} options.contexType              contextType to be passed into Person/Org SidePanel
 * @param {Number} options.contextId               contextId to be passed into Person/Org SidePanel
 * @param {Function} [options.onDelete]            Override default onDelete behavior
 * @param {Function} [options.onEdit]              Override default onEdit behavior
 * @param {String} options.personOverlayIdPrefix   Prefixed overlay ID to use for the person overlay.
 * @param {String} options.organizationOverlayIdPrefix   Prefixed overlay ID to use for the organization overlay.
 */
export default connect(null, (dispatch) => ({
    removeNameReportLink: (nameReportLink) => {
        dispatch(deleteNameReportLink(nameReportLink)).catch(() => {
            dispatch(
                openErrorModal({
                    paragraphs: [
                        `Failed to successfully remove ${
                            nameReportLink.entityType === EntityTypeEnum.PERSON_PROFILE.name
                                ? 'person'
                                : 'organization'
                        }, please try again`,
                    ],
                })
            );
        });
    },
}))(function RecordCardNameSummaries({
    summaryMode,
    summaryModeItem,
    renderAdditionalItem,
    nameLinks,
    parentEntityType,
    parentId,
    contextType,
    contextId,
    renForRecents,
    onDelete,
    removeNameReportLink,
    onEdit,
    personOverlayIdPrefix,
    organizationOverlayIdPrefix,
    setDeleteButton,
    nextEditButtonRef,
    nibrsOffenseCode,
    showExpandCollapseButton,
    showMugshotThumbnail,
}) {
    return (
        <div>
            {map(nameLinks, (nameLink, index) => {
                const getNameSidePanelProps = () => ({
                    linkType: nameLink.linkType,
                    contextType,
                    contextId,
                    ownerId: parentId,
                    ownerType: parentEntityType,
                    entityId: nameLink.nameId,
                    renForRecents,
                    nibrsOffenseCode,
                });
                let baseSummary;
                const additionalItem = isFunction(renderAdditionalItem)
                    ? ({ collapsed }) =>
                          renderAdditionalItem({ nameLink, summaryMode, index, collapsed })
                    : undefined;

                const deleteHandler = () =>
                    onDelete ? onDelete(nameLink, index) : removeNameReportLink(nameLink);

                if (nameLink.entityType === EntityTypeEnum.PERSON_PROFILE.name) {
                    baseSummary = (
                        <PersonSidePanel
                            overlayId={`${personOverlayIdPrefix}.${index}`}
                            renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => {
                                const renderActionButtons = ({ isUnknownAndIdentified }) => {
                                    const summaryModeForItem = isFunction(summaryModeItem) && summaryModeItem(nameLink);
                                    const showEditButton = !summaryMode && !summaryModeForItem && !isUnknownAndIdentified;
                                    const showRemoveButton = !summaryMode && !summaryModeForItem;

                                    return (
                                        <>
                                            {showEditButton && (
                                                <SummaryEditButton
                                                    onClick={onEdit || open}
                                                    ref={(ref) => {
                                                        if (ref) {
                                                            setCloseFocusRefs(ref);
                                                            if (index === nameLinks.length - 1) {
                                                                nextEditButtonRef.current = ref;
                                                            }
                                                        }
                                                    }}
                                                    testId={testIds.NAME_SUMMARY_VIEW_EDIT_NAME}
                                                />
                                            )}
                                            {showRemoveButton && (
                                                <SummaryRemoveButton
                                                    onClick={deleteHandler}
                                                    ref={setDeleteButton(index)}
                                                    testId={testIds.NAME_SUMMARY_VIEW_REMOVE_NAME}
                                                />
                                            )}
                                        </>
                                    );
                                };

                                return (
                                    <PersonProfileSummary
                                        personProfileId={nameLink.nameId}
                                        linkTypeLetterNumber={formatLinkTypeLetterNumber(
                                            nameLink.linkType,
                                            nameLink.linkTypeSequenceNumber
                                        )}
                                        additionalItem={additionalItem}
                                        showExpandCollapseButton={showExpandCollapseButton}
                                        renderActionButtons={renderActionButtons}
                                        showMugshotThumbnail={showMugshotThumbnail}
                                        showCautionsAsSnapshot
                                    />
                                );
                            }}
                            {...getNameSidePanelProps()}
                        />
                    );
                } else if (nameLink.entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name) {
                    baseSummary = (
                        <OrganizationSidePanel
                            overlayId={`${organizationOverlayIdPrefix}.${index}`}
                            renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => {
                                const renderActionButtons = ({ isSociety }) => {
                                    const summaryModeForItem = isFunction(summaryModeItem) && summaryModeItem(nameLink);
                                    const showEditButton = !summaryMode && !summaryModeForItem && !isSociety;
                                    const showRemoveButton = !summaryMode && !summaryModeForItem;

                                    return (
                                        <>
                                            {showEditButton && (
                                                <SummaryEditButton
                                                    onClick={onEdit || open}
                                                    ref={(ref) => {
                                                        if (ref) {
                                                            setCloseFocusRefs(ref);
                                                            if (index === nameLinks.length - 1) {
                                                                nextEditButtonRef.current = ref;
                                                            }
                                                        }
                                                    }}
                                                    testId={testIds.NAME_SUMMARY_VIEW_EDIT_NAME}
                                                />
                                            )}
                                            {showRemoveButton && (
                                                <SummaryRemoveButton
                                                    onClick={deleteHandler}
                                                    ref={setDeleteButton(index)}
                                                    testId={testIds.NAME_SUMMARY_VIEW_REMOVE_NAME}
                                                />
                                            )}
                                        </>
                                    );
                                };
                                return (
                                    <OrganizationProfileSummary
                                        organizationProfileId={nameLink.nameId}
                                        linkTypeLetterNumber={formatLinkTypeLetterNumber(
                                            nameLink.linkType,
                                            nameLink.linkTypeSequenceNumber
                                        )}
                                        additionalItem={additionalItem}
                                        showExpandCollapseButton={showExpandCollapseButton}
                                        renderActionButtons={renderActionButtons}
                                    />
                                );
                            }}
                            {...getNameSidePanelProps()}
                        />
                    );
                } else {
                    baseSummary = <div />;
                }
                return <SummaryWrapper key={nameLink.nameId}>{baseSummary}</SummaryWrapper>;
            })}
        </div>
    );
});

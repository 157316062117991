import Promise from 'bluebird';
import { filter, includes, map, groupBy, mapValues, reject } from 'lodash';
import { createSelector } from 'reselect';
import { EntityTypeEnum } from '@mark43/rms-api';
import {
    attachmentsSelector,
    loadAttachmentsByEntityIdsAndTypes,
} from '~/client-common/core/domain/attachments/state/data';

/**
 * Loads attachments for given entityIds and handles interaction
 * state transitions for the given overlay id
 *
 * @param {object[]} entities An array of { entityId: number, entityType: string}
 * @param {string} overlayId
 * @param {function} onSuccess
 */
export function loadAttachments({ entities, excludeAttachmentLinkTypes, overlayId, onSuccess }) {
    return (dispatch, getState, { overlayStore }) => {
        overlayStore.setLoading(overlayId, true);
        const getEntityType = (entity) => entity.entityType;
        const entityIdsByType = mapValues(
            groupBy(filter(entities, getEntityType), getEntityType),
            (entities) => map(entities, (entity) => entity.entityId)
        );

        return Promise.resolve(
            dispatch(
                loadAttachmentsByEntityIdsAndTypes(entityIdsByType, { excludeAttachmentLinkTypes })
            )
        )
            .then((data) => {
                const caseIds = entityIdsByType[EntityTypeEnum.CASE.name];
                if (caseIds && caseIds.length > 0) {
                    // tech debt: remove this ad hoc logic after
                    // https://github.com/mark43/mark43/pull/49682#discussion_r940394791
                    // see the comment in loadAttachmentsByEntityIdsAndTypes
                    const state = getState();
                    const allAttachments = exportAttachmentsSelector(state)();
                    const caseAttachments = filter(allAttachments, (attachment) =>
                        includes(caseIds, attachment.entityId)
                    );
                    if (caseAttachments.length > 0) {
                        data = [...data, ...caseAttachments];
                    }
                }
                onSuccess(data);
                overlayStore.setLoading(overlayId, false);
            })
            .catch((err) => {
                overlayStore.setError(overlayId, err.message);
            });
    };
}

export const exportAttachmentsSelector = createSelector(
    attachmentsSelector,
    (attachments) => (excludeAttachmentLinkTypes) => {
        if (!excludeAttachmentLinkTypes) {
            return attachments;
        }

        return reject(attachments, ({ linkType }) =>
            includes(excludeAttachmentLinkTypes, linkType)
        );
    }
);

import { TableColumn as Column, TableRow as Row } from 'components-mark43';
import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import PropertyTitle from '~/client-common/core/domain/item-profiles/components/PropertyTitle';
import { ItemID } from '~/client-common/core/domain/item-profiles/components/ItemID';

import { VehicleEnhancedCautions } from '../../../../../core/cautions/components/VehicleEnhancedCautions';
import { IconButton } from '../../../../../core/components/IconButton';
import testIds from '../../../../../../core/testIds';
import { PopoutLink } from '../../../../../core/components/links/Link';
import { VehicleLabels } from '../../../../../core/components/tags/VehicleLabels';
import { ItemActionIcons, ItemCheckbox } from '../ManageItemsItemList';
import { SelectAllCheckbox } from '../ManageItemsSidePanel';
import {
    CustodyStatusHeaderCell,
    PoliceCustodyCell,
    PropertyStatusCell,
    StatusHeaderCell,
    TitleCell,
    TitleHeaderCell,
    ToggleCell,
} from './Cell';
import {
    CustodyStatusColumn,
    StatusColumn,
    SummaryColumn,
    TitleColumn,
    ToggleColumn,
    ToggleHeaderColumn,
} from './Column';

import Details from './Details';

const ActionsColumn = styled(Column)`
    align-items: center;
    flex: 0 0 ${(props) => (props.showActionsBar ? '86px' : '40px')};
    flex-direction: row;
    height: 100%;
`;

const BodyRow = styled(Row)`
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-sm);
    padding: 10px 0;
    align-items: stretch;
`;

const HeaderRow = styled(Row)`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    text-transform: uppercase;
`;

export const NavigationRow = styled(Row)`
    justify-content: space-between;
    margin: 10px 0 20px 0;
`;

const VehicleCautionsLabelWrapper = styled.div`
    margin: 0 10px;
`;

export const TableBodyRow = ({
    data,
    detailsShowing,
    hideDetails,
    isVehicle,
    showDetails,
    summaryMode,
    selectableItemProfileIds,
    selectedItemProfileIds,
    setSelectedItemProfileIds,
}) => {
    const showVehicleActions = isVehicle && !summaryMode;

    return (
        <BodyRow>
            <FeatureFlagged flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED">
                {showVehicleActions && (
                    <ItemCheckbox
                        itemProfileId={data.id}
                        setSelectedItemProfileIds={setSelectedItemProfileIds}
                        value={selectedItemProfileIds.indexOf(data.id) >= 0}
                        disabled={selectableItemProfileIds.indexOf(data.id) === -1}
                    />
                )}
            </FeatureFlagged>
            <SummaryColumn>
                <Row style={{ margin: '5px 0 10px 0' }}>
                    <TitleColumn>
                        <TitleCell>
                            <PopoutLink
                                to={`/profiles/${isVehicle ? 'vehicles' : 'property'}/${get(
                                    data,
                                    'masterItemId'
                                )}`}
                            >
                                <PropertyTitle itemProfile={data} />
                            </PopoutLink>
                            <ItemID itemProfile={data} />
                        </TitleCell>
                    </TitleColumn>
                    <StatusColumn>
                        <PropertyStatusCell data={data} />
                    </StatusColumn>
                    <CustodyStatusColumn>
                        <PoliceCustodyCell isVehicle={isVehicle} data={data} />
                    </CustodyStatusColumn>
                </Row>

                <FeatureFlagged
                    flag="RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <FeatureFlagged flag="RMS_VEHICLE_CAUTIONS_ENABLED">
                            {isVehicle && (
                                <VehicleCautionsLabelWrapper>
                                    <VehicleLabels
                                        testId={testIds.VEHICLE_LABELS}
                                        itemAttributes={data.itemAttributes}
                                        isStolen={data.isStolen}
                                        size="md"
                                    />
                                </VehicleCautionsLabelWrapper>
                            )}
                        </FeatureFlagged>
                    }
                >
                    {isVehicle && (
                        <VehicleCautionsLabelWrapper>
                            <VehicleEnhancedCautions
                                cautions={data.cautions}
                                isStolen={data.isStolen}
                                size="sm"
                                evaluateByCreationDates
                            />
                        </VehicleCautionsLabelWrapper>
                    )}
                </FeatureFlagged>

                {detailsShowing ? <Details data={data} /> : null}
            </SummaryColumn>

            <FeatureFlagged
                flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                fallback={
                    <ToggleColumn>
                        <ToggleCell>
                            <IconButton
                                onClick={() => (detailsShowing ? hideDetails() : showDetails())}
                                testId={testIds.GENERIC_EXPAND_COLLAPSE_BUTTON}
                                icon={detailsShowing ? 'Close' : 'Open'}
                                variant="ghost"
                            />
                        </ToggleCell>
                    </ToggleColumn>
                }
            >
                <ActionsColumn showActionsBar={showVehicleActions}>
                    <ToggleCell>
                        <IconButton
                            onClick={() => (detailsShowing ? hideDetails() : showDetails())}
                            testId={testIds.GENERIC_EXPAND_COLLAPSE_BUTTON}
                            icon={detailsShowing ? 'Close' : 'Open'}
                            variant="ghost"
                        />
                    </ToggleCell>
                    {showVehicleActions && (
                        <ItemActionIcons
                            hideIconLabels={true}
                            isVehicle={isVehicle}
                            itemProfileId={data.id}
                            masterItemId={data.masterItemId}
                            ownerType={data.ownerType}
                            ownerId={data.ownerId}
                            hideDividers={true}
                            render={({ editButton, deleteButton }) => (
                                <>
                                    <span>{editButton}</span>
                                    <span>{deleteButton}</span>
                                </>
                            )}
                        />
                    )}
                </ActionsColumn>
            </FeatureFlagged>
        </BodyRow>
    );
};

export const TableHeaderRow = ({
    isVehicle,
    summaryMode,
    selectableItemProfileIds,
    selectedItemProfileIds,
    setSelectedItemProfileIds,
}) => (
    <HeaderRow>
        <SummaryColumn>
            <Row>
                <FeatureFlagged flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED">
                    {isVehicle && !summaryMode && (
                        <SelectAllCheckbox
                            selectableItemProfileIds={selectableItemProfileIds}
                            selectedItemProfileIds={selectedItemProfileIds}
                            setSelectedItemProfileIds={setSelectedItemProfileIds}
                            showLabel={false}
                        />
                    )}
                </FeatureFlagged>
                <TitleColumn>
                    <TitleHeaderCell isVehicle={isVehicle} />
                </TitleColumn>
                <StatusColumn>
                    <StatusHeaderCell />
                </StatusColumn>
                <CustodyStatusColumn>
                    <CustodyStatusHeaderCell isVehicle={isVehicle} />
                </CustodyStatusColumn>
                <ToggleHeaderColumn />
            </Row>
        </SummaryColumn>
    </HeaderRow>
);

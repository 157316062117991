import imageGalleryActionTypes from '../actions/types/imageGalleryActionTypes';

const initialUiState = {
    images: [],
    currentIndex: 0,
    emailsEnabled: false,
};

export function imageGalleryUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case imageGalleryActionTypes.NEXT_IMAGE:
            return {
                ...state,
                currentIndex: (state.currentIndex + 1) % state.images.length,
            };
        case imageGalleryActionTypes.PREVOUS_IMAGE:
            return {
                ...state,
                currentIndex: (state.currentIndex - 1 + state.images.length) % state.images.length,
            };
        case imageGalleryActionTypes.SET_CURRENT_INDEX:
            return {
                ...state,
                currentIndex: action.payload,
            };
        case imageGalleryActionTypes.SET_IMAGES:
            return {
                ...state,
                images: action.payload,
            };
        case imageGalleryActionTypes.SET_EMAILS_ENABLED:
            return {
                ...state,
                emailsEnabled: action.payload,
            };
        default:
            return state;
    }
}

import { ComplianceGroupEnum, EntityTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import { map, sortBy } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { idFormatConfigurationByEntityTypeSelector } from '~/client-common/core/domain/id-format-configurations/state/data';
import { idGeneratorFormatsByEntityTypeSelector } from '~/client-common/core/domain/id-generator/state/data';
import { prettify } from '~/client-common/helpers/stringHelpers';
import { agencyProfilesWhereSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';

import {
    LOAD_ID_FORMATS_START,
    LOAD_ID_FORMATS_FAILURE,
    LOAD_ID_FORMATS_SUCCESS,
    SAVE_ID_FORMAT_START,
    SAVE_ID_FORMAT_SUCCESS,
    SAVE_ID_FORMAT_FAILURE,
    SAVE_SEQUENCE_INCREASE_START,
    SAVE_SEQUENCE_INCREASE_SUCCESS,
    SAVE_SEQUENCE_INCREASE_FAILURE,
} from '../data';
import {
    currentUserDepartmentIdSelector,
    currentUserDepartmentProfileSelector,
} from '../../../../core/current-user/state/ui';

const SELECT_ENTITY_TYPE = 'support-id-generator/SELECT_ENTITY_TYPE';
const SELECT_AGENCY_ID = 'support-id-generator/SELECT_AGENCY_ID';
const LEGACY_ID_GENERATOR_ENTITY_TYPES = [
    EntityTypeEnum.ARREST.name,
    EntityTypeEnum.CAD_COMMON_EVENT.name,
    EntityTypeEnum.CAD_AGENCY_EVENT.name,
    EntityTypeEnum.CASE.name,
    EntityTypeEnum.FIELD_CONTACT.name,
    EntityTypeEnum.REPORT.name,
    EntityTypeEnum.SCHEDULED_AGENCY_EVENT.name,
    EntityTypeEnum.TOW_VEHICLE.name,
    EntityTypeEnum.WARRANT.name,
    EntityTypeEnum.BOLO.name,
];

const ID_GENERATOR_ENTITY_TYPES = [
    EntityTypeEnum.BOLO.name,
    EntityTypeEnum.CAD_COMMON_EVENT.name,
    EntityTypeEnum.CAD_AGENCY_EVENT.name,
    EntityTypeEnum.CASE.name,
    EntityTypeEnum.REPORTING_EVENT_NUMBER.name,
    EntityTypeEnum.RMS_AUTOGENERATED_REPORTING_EVENT_NUMBER.name,
    EntityTypeEnum.SCHEDULED_AGENCY_EVENT.name,
    EntityTypeEnum.WARRANT.name,
    EntityTypeEnum.EXTERNAL_AGENCY_EVENT.name,
    EntityTypeEnum.EXTERNAL_AGENCY_EVENT_ACTION.name,
    EntityTypeEnum.E_FILE.name,
    EntityTypeEnum.ARREST.name,
];

export default function reducer(
    state = {
        selectedEntityType: null,
        loading: false,
        savingIdFormat: false,
        loadingError: null,
        savingIdFormatError: null,
        savingSequenceIncrease: false,
        savingSequenceIncreaseError: null,
        selectedAgencyId: null,
    },
    action
) {
    switch (action.type) {
        case SELECT_ENTITY_TYPE:
            return {
                ...state,
                selectedEntityType: action.payload,
                selectedAgencyId: undefined,
            };
        case SELECT_AGENCY_ID:
            return {
                ...state,
                selectedAgencyId: action.payload,
            };
        case LOAD_ID_FORMATS_START:
            return {
                ...state,
                loading: true,
                loadingError: null,
                savingIdFormatError: null,
                savingSequenceIncreaseError: null,
            };
        case LOAD_ID_FORMATS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case LOAD_ID_FORMATS_FAILURE:
            return {
                ...state,
                loading: false,
                loadingError: action.payload,
            };
        case SAVE_ID_FORMAT_START:
            return {
                ...state,
                savingIdFormat: true,
                loadingError: null,
                savingIdFormatError: null,
                savingSequenceIncreaseError: null,
            };
        case SAVE_ID_FORMAT_SUCCESS:
            return {
                ...state,
                savingIdFormat: false,
            };
        case SAVE_ID_FORMAT_FAILURE:
            return {
                ...state,
                savingIdFormat: false,
                savingIdFormatError: action.payload,
            };
        case SAVE_SEQUENCE_INCREASE_START:
            return {
                ...state,
                savingSequenceIncrease: true,
                loadingError: null,
                savingIdFormatError: null,
                savingSequenceIncreaseError: null,
            };
        case SAVE_SEQUENCE_INCREASE_SUCCESS:
            return {
                ...state,
                savingSequenceIncrease: false,
            };
        case SAVE_SEQUENCE_INCREASE_FAILURE:
            return {
                ...state,
                savingSequenceIncrease: false,
                savingSequenceIncreaseError: action.payload,
            };
        default:
            return state;
    }
}

export function selectEntityType(entityType) {
    return {
        type: SELECT_ENTITY_TYPE,
        payload: entityType,
    };
}

export function selectAgencyId(agencyId) {
    return {
        type: SELECT_AGENCY_ID,
        payload: agencyId,
    };
}

const uiBaseSelectorSelector = (state) => state.ui.supportIdGenerator;

const agencyListItemsSelector = createSelector(
    agencyProfilesWhereSelector,
    uiBaseSelectorSelector,
    currentUserDepartmentIdSelector,
    formatAttributeByIdSelector,
    (agencyProfilesWhere, ui, currentUserDepartmentId, formatAttributeById) => {
        return sortBy(
            agencyProfilesWhere({ departmentId: currentUserDepartmentId }).map((agency) => {
                return {
                    title: agency.agencyName,
                    key: agency.agencyId,
                    code: agency.agencyCode,
                    selected: agency.agencyId === ui.selectedAgencyId,
                    isDefault: agency.isDefault,
                    defaultLabel: agency.isDefault ? 'Default Agency' : undefined,
                    path: `/support/id-generator/${ui.selectedEntityType}/${agency.agencyId}`,
                    subtitle: formatAttributeById(agency.agencyTypeGlobalAttrId),
                };
            }),
            'title'
        );
    }
);

export const agenciesByCurrentUserDepartmentIdSelector = createSelector(
    agencyProfilesWhereSelector,
    currentUserDepartmentIdSelector,
    (agencyProfilesWhere, currentUserDepartmentId) => {
        return agencyProfilesWhere({ departmentId: currentUserDepartmentId });
    }
);

function computeEntityTypes(recordsWithoutRenEnabled, complianceGroup) {
    const list = recordsWithoutRenEnabled
        ? ID_GENERATOR_ENTITY_TYPES
        : LEGACY_ID_GENERATOR_ENTITY_TYPES;
    if (complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name) {
        return [...list, EntityTypeEnum.OFFENSE.name];
    } else {
        return list;
    }
}

export const uiSelector = createSelector(
    uiBaseSelectorSelector,
    idGeneratorFormatsByEntityTypeSelector,
    idFormatConfigurationByEntityTypeSelector,
    applicationSettingsSelector,
    currentUserDepartmentProfileSelector,
    agencyListItemsSelector,
    (
        ui,
        idGeneratorFormatByEntityType,
        idFormatConfigurationByEntityType,
        applicationSettings,
        currentUserDepartmentProfile,
        agencyListItems
    ) => {
        const recordsWithoutRenEnabled = applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;
        const entityTypes = computeEntityTypes(
            recordsWithoutRenEnabled,
            currentUserDepartmentProfile.complianceGroup
        );
        const selector = recordsWithoutRenEnabled
            ? idFormatConfigurationByEntityType
            : idGeneratorFormatByEntityType;

        const agencyFormattingEnabledForRen =
            applicationSettings.CAD_USE_AGENCY_DEPT_CONFIG_FOR_REN &&
            ui.selectedEntityType === EntityTypeEnum.REPORTING_EVENT_NUMBER.name;
        const agencyFormattingEnabledForOffense =
            ui.selectedEntityType === EntityTypeEnum.OFFENSE.name;
        const agencyFormattingEnabledForArrest =
            ui.selectedEntityType === EntityTypeEnum.ARREST.name;
        const agencyFormattingEnabled =
            agencyFormattingEnabledForRen ||
            agencyFormattingEnabledForOffense ||
            agencyFormattingEnabledForArrest;

        const selectedAgencyId = agencyFormattingEnabled ? ui.selectedAgencyId : undefined;

        return {
            ...ui,
            selectedIdGeneratorFormat:
                ui.selectedEntityType && selector(ui.selectedEntityType, selectedAgencyId),
            selectedEntityTypeDisplay: ui.selectedEntityType && prettify(ui.selectedEntityType),
            list: sortBy(
                map(entityTypes, (entityType) => ({
                    key: entityType,
                    title: prettify(entityType),
                    path: `/support/id-generator/${entityType}`,
                    selected: entityType === ui.selectedEntityType,
                })),
                'title'
            ),
            agencyFormattingEnabled,
            agencyListItems: agencyFormattingEnabled ? agencyListItems : [],
        };
    }
);

import { map } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import {
    hydratedChargeViewModelsForChargesWhereSelector,
    sortHydratedChargeViewModels,
} from '~/client-common/core/domain/charges/state/ui';

import { ChargeSummaryView } from './ChargeSummaryView';

const ChargesSummaryView = ({ arrestId, chargesFormIndex }) => {
    const hydratedChargeViewModelsForChargesWhere = useSelector(
        hydratedChargeViewModelsForChargesWhereSelector
    );

    const hydratedChargeViewModels = hydratedChargeViewModelsForChargesWhere({ arrestId });

    const sortedHydratedChargeViewModels = sortHydratedChargeViewModels({
        hydratedChargeViewModels,
    });

    // Get all of the ids from `sortedHydratedChargeViewModels`.
    // When this component mounts - gets all the info and store it in component state in some way
    // Pass the data down into the components that are being returned from the map.
    return (
        <div>
            {map(sortedHydratedChargeViewModels, (hydratedChargeViewModel) => {
                return (
                    <ChargeSummaryView
                        hydratedChargeViewModel={hydratedChargeViewModel}
                        summaryMode={true}
                        chargesFormIndex={chargesFormIndex}
                    />
                );
            })}
        </div>
    );
};

export default ChargesSummaryView;

/**
 * Given a starting `node`, traverse up the DOM
 * tree to find the first node that
 * matches the given predicate
 */
function findClosestParent(
    node: HTMLElement,
    predicate: (node: HTMLElement) => boolean
): HTMLElement | undefined {
    const { parentNode } = node;
    if (parentNode) {
        if (predicate(parentNode as HTMLElement)) {
            return parentNode as HTMLElement;
        } else {
            return findClosestParent(parentNode as HTMLElement, predicate) as HTMLElement;
        }
    }
    return;
}

/**
 * Given a starting `node`, traverse up the DOM
 * tree to find the first node that
 * has the given `styleName` and `styleValue`
 */
export function findClosestParentWithStyle(
    node: HTMLElement,
    styleName: keyof CSSStyleDeclaration,
    styleValue: string | number
) {
    return findClosestParent(node, (node) => {
        return window.getComputedStyle(node)[styleName] === styleValue;
    });
}

import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';

import { currentProfileWarrantViewModelsSelector } from '../../core/state/ui/selectors';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';

const strings = componentStrings.entityProfiles.EntityProfilePersonWarrantsSummary;

const WarrantsHeader = styled.div`
    background: ${(props) =>
        props.transitionWarrants ? props.theme.colors.lightYellow : props.theme.colors.red};
    border: 1px solid
        ${(props) =>
            props.transitionWarrants ? props.theme.colors.brightYellow : props.theme.colors.red};
    border-radius: 4px 4px 0 0;
    color: ${(props) =>
        props.transitionWarrants ? props.theme.colors.darkGrey : props.theme.colors.white};
    transition: border 1s, background 1s, color 1s;
    padding: 10px 10px;
    font-size: var(--arc-fontSizes-md);
`;

const WarrantTitleBold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    margin-right: 5px;
`;

const WarrantTitleItalic = styled.span`
    font-style: italic;
`;

const WarrantsContainer = styled.div`
    border: 1px solid
        ${(props) =>
            props.transitionWarrants ? props.theme.colors.brightYellow : props.theme.colors.red};
    border-top: none;
    border-radius: 0 0 4px 4px;
    transition: border 1s;
    padding: 15px 10px 0 10px;
    font-size: var(--arc-fontSizes-md);
`;

const WarrantLink = styled.div`
    color: ${(props) => props.theme.colors.brightBlue};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    cursor: pointer;
    margin-right: 5px;
    display: inline-block;
`;

const WarrantObtaining = styled.div`
    color: ${(props) => props.theme.colors.lightGrey};
    font-style: italic;
    display: inline-block;
`;

const WarrantLine = styled.div`
    margin-bottom: 15px;
`;

const PopoutIcon = styled(Icon)`
    float: right;
`;

function WarrantRow({ warrantViewModel }) {
    const { obtainingOfficerUserDisplay, warrantShortTitle } = getViewModelProperties(
        warrantViewModel
    );
    const obtainingOfficer = obtainingOfficerUserDisplay
        ? `${componentStrings.warrants.generic.obtainedBy} ${obtainingOfficerUserDisplay}`
        : '';
    return (
        <WarrantLine>
            <WarrantLink onClick={() => window.open(`/#/warrants/${warrantViewModel.id}`)}>
                {warrantShortTitle}
                <PopoutIcon type={iconTypes.POPOUT} />
            </WarrantLink>
            <WarrantObtaining>
                {obtainingOfficer}
                {obtainingOfficer && warrantViewModel.warrantIssuedDateUtc ? ', ' : ''}
                <FormattedDate
                    date={warrantViewModel.warrantIssuedDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE}
                />
            </WarrantObtaining>
        </WarrantLine>
    );
}

function WarrantsSummary({ transitionWarrants, currentProfileWarrantViewModels }) {
    return currentProfileWarrantViewModels.length > 0 ? (
        <div>
            <WarrantsHeader transitionWarrants={transitionWarrants}>
                <WarrantTitleBold>{strings.potentialActiveWarrants}</WarrantTitleBold>
                <WarrantTitleItalic>{strings.confirmationRequired}</WarrantTitleItalic>
            </WarrantsHeader>
            <WarrantsContainer transitionWarrants={transitionWarrants}>
                {map(currentProfileWarrantViewModels, (warrantViewModel) => {
                    return (
                        <WarrantRow warrantViewModel={warrantViewModel} key={warrantViewModel.id} />
                    );
                })}
            </WarrantsContainer>
        </div>
    ) : (
        <div />
    );
}

const mapStateToProps = createStructuredSelector({
    currentProfileWarrantViewModels: currentProfileWarrantViewModelsSelector,
});

export default connect(mapStateToProps, null)(WarrantsSummary);

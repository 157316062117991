import React, { useCallback, useState } from 'react';
import { TaskEntityLinkView, TaskView } from '@mark43/rms-api';
import { useDispatch, useSelector } from 'react-redux';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { PendingComment, updateTask } from '../state/ui';
import { currentUserIdSelector } from '../../../core/current-user/state/ui';
import Modal from '../../../core/overlays/components/Modal';
import TextArea from '../../../core/forms/components/TextArea';
import { RmsDispatch } from '../../../../core/typings/redux';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import { completedTaskStatusAttributeIdSelector } from '../data';
import { useOpenTaskErrorModal } from './TaskErrorModal';

const overlayId = overlayIdEnum.TASK_ADD_UPDATE_OVERLAY;
const strings = componentStrings.tasks.core.TaskAddUpdateModal;

type TaskAddUpdateModalCustomProperties = {
    task?: Partial<TaskView>;
    taskEntityLinks: Partial<TaskEntityLinkView>[];
    onSave?: (pendingUpdates: PendingComment[]) => void;
    onCancel?: () => void;
    withoutUpdateRequest?: boolean;
};

export const useOpenTaskAddUpdateModal = () => {
    const overlayStore = useOverlayStore<TaskAddUpdateModalCustomProperties>();
    return useCallback(
        (customProperties: TaskAddUpdateModalCustomProperties) => {
            overlayStore.open(overlayId, customProperties);
        },
        [overlayStore]
    );
};

const TaskAddUpdateModalBody = ({
    task = {},
    taskEntityLinks,
    onSave,
    onCancel,
    withoutUpdateRequest = false,
}: TaskAddUpdateModalCustomProperties) => {
    const dispatch = useDispatch<RmsDispatch>();
    const createdBy = useSelector(currentUserIdSelector);
    const completeTaskStatusId = useSelector(completedTaskStatusAttributeIdSelector);
    const overlayStore = useOverlayStore<TaskAddUpdateModalCustomProperties>();
    const openTaskErrorModal = useOpenTaskErrorModal();
    const [updateText, setUpdateText] = useState<string>('');

    const handleCancel = useCallback(() => {
        setUpdateText('');
        onCancel?.();
    }, [onCancel]);

    const handleSave = () => {
        const pendingUpdates: PendingComment[] = [];
        if (updateText && createdBy) {
            pendingUpdates.push({
                comment: updateText,
                createdBy,
                createdDateUtc: new Date().toISOString(),
            });
        }

        const response = withoutUpdateRequest
            ? Promise.resolve()
            : dispatch(
                  updateTask(
                      { ...task, statusAttrId: completeTaskStatusId },
                      taskEntityLinks,
                      pendingUpdates
                  )
              );

        response
            .then(() => {
                onSave?.(pendingUpdates);
                setUpdateText('');
                overlayStore.close(overlayId);
            })
            .catch((err) => {
                overlayStore.close(overlayId);
                openTaskErrorModal({
                    titleString: strings.title,
                    message: err.message,
                });
            });
    };

    return (
        <Modal
            id={overlayId}
            title={strings.title}
            okText={strings.saveText}
            onSave={handleSave}
            onCancel={handleCancel}
            closeOnSave={false}
        >
            {strings.body}
            <TextArea
                width="100%"
                rows={3}
                value={updateText}
                onChange={(text) => setUpdateText(text ? text.toString() : '')}
            />
        </Modal>
    );
};

const TaskAddUpdateModal: React.FC = () => {
    return (
        <OverlayBaseHelper<TaskAddUpdateModalCustomProperties> id={overlayId}>
            {({ overlayBase }) => {
                const { customProperties } = overlayBase.overlayState;
                return <TaskAddUpdateModalBody {...customProperties} />;
            }}
        </OverlayBaseHelper>
    );
};

export default TaskAddUpdateModal;

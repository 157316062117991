import React from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';

import Fieldset from '../../../core/forms/components/Fieldset';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';

import { personInjuriesFieldsetViewModel } from '../../../../legacy-redux/configs/fieldsetsConfig';

const { title } = personInjuriesFieldsetViewModel;

/**
 * This fieldset for a person's injuries has a default title and a default
 *   className.
 * @param {Object} props
 * @param {string} [props.path='injuries'] Form model state path.
 * @param {string} [props.className='light']
 */
export default pure(function PersonInjuriesFieldset({
    path = 'injuries',
    fuzzyMatchingEnabled,
    className = 'light',
    ...otherProps
}) {
    return (
        <Fieldset title={title} className={className} {...otherProps}>
            <RRFNItems path={path} addItemOnDirty={true}>
                {() => (
                    <div>
                        <RRFAttributeSelect
                            attributeType="INJURY_CATEGORY"
                            includeExpired={true}
                            length="md"
                            path="injuryCategoryAttrId"
                        />
                        <RRFAttributeSelect
                            attributeType="BODY_PART"
                            includeExpired={true}
                            length="md"
                            path="bodyPartAttrId"
                        />
                        <RRFText
                            className={classNames({
                                'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                            })}
                            path="description"
                        />
                    </div>
                )}
            </RRFNItems>
        </Fieldset>
    );
});

import { map } from 'lodash';
import { AttributeView, ReportAttribute } from '@mark43/rms-api';
import { ModuleShape } from '../../../../redux/state';

/**
 * Given a list of report attributes, this function will return a new list of report
 *   attributes with a description mapped on to the appropriate report attributes (which
 *   is any report attribute where the linked attribute "is other")
 * @param   reportAttributes A list of report attributes
 * @param   allAttributes    All of the attributes from application state, keyed by id
 * @param     description      The description, or "Other Text" provided by a user
 * @return
 */
export function assignDescriptionToReportAttributes<
    T extends { attributeId: number } = ReportAttribute,
>(
    reportAttributes: T[],
    allAttributes: ModuleShape<AttributeView>,
    description: string | undefined
): T[] {
    return map(reportAttributes, (reportAttribute) => {
        if (reportAttribute.attributeId && allAttributes[reportAttribute.attributeId]?.other) {
            return {
                ...reportAttribute,
                description,
            };
        } else {
            return reportAttribute;
        }
    });
}

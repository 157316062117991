import React from 'react';
import { createStructuredSelector } from 'reselect';
import { iconTypes } from 'components-mark43';
import { connect } from 'react-redux';
import { CustomLink, PopoutIcon } from '../../../../core/components/links/Link';
import {
    loadCadSettingsForCurrentUser,
    cadUserSettingsSelector,
} from '../../../../core/account-switcher/state/ui';
import { switchToCad } from '../../../../core/account-switcher/state/data';
import testIds from '../../../../../core/testIds';

const LinkToCadEvent = function ({
    cadAgencyEventNumber,
    cadAgencyEventId,
    cadUserSettings,
    switchToCad,
    loadCadSettingsForCurrentUser,
}) {
    React.useEffect(() => {
        // If there is no cadDeptId, then attempt to fetch one
        if (!cadUserSettings.cadDepartmentId) {
            loadCadSettingsForCurrentUser();
        }
    }, [cadUserSettings.cadDepartmentId, loadCadSettingsForCurrentUser]);
    const { hasCadModule, hasFirstResponderAbility } = cadUserSettings;
    const canRenderLink = hasFirstResponderAbility && hasCadModule && cadAgencyEventId;
    if (!canRenderLink) {
        return cadAgencyEventNumber;
    }
    return (
        <CustomLink
            data-test-id={testIds.LINK_TO_CAD_EVENT}
            onClick={() => switchToCad(cadUserSettings, cadAgencyEventId)}
        >
            {cadAgencyEventNumber}
            <PopoutIcon size={11} type={iconTypes.OPEN_NEW_WINDOW} />
        </CustomLink>
    );
};

const mapStateToProps = createStructuredSelector({
    cadUserSettings: cadUserSettingsSelector,
});

const mapDispatchToProps = {
    switchToCad,
    loadCadSettingsForCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkToCadEvent);

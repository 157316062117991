import React, { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SkeletonText } from '@arc/skeleton';
import { ElasticReport, RefContextEnum } from '@mark43/rms-api';
import { flatten, values } from 'lodash';
import { useResource } from '~/client-common/core/hooks/useResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import type { ReportShortTitleWrapper } from '~/client-common/core/domain/report-short-titles/types';

import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import { RmsDispatch } from '../../../../core/typings/redux';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import formsRegistry from '../../../../core/formsRegistry';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import Modal from '../../../core/overlays/components/Modal';
import reportsResource from '../../../reports/core/resources/reportsResource';
import buildReportIdentifierView from '../../../reports/core/utils/buildReportIdentifierView';
import caseReportLinksResource from '../../core/resources/caseReportLinksResource';
import ReasonForRelationForm, {
    ReasonForRelationFormLocationType,
} from '../../core/components/ReasonForRelationForm';
import {
    convertFromFormModelToCaseRequest,
    convertReportShortTitleWrapperToFormModel,
} from '../../core/state/form/reasonForRelationForm';
import { caseOnEnter } from '../..';

const strings = componentStrings.cases.caseSummary.CaseReasonForRelationModal;

type ReasonForRelationModalProps = {
    reports: ElasticReport[];
};

const ReasonForRelationModal: React.FC<{ caseId: number }> = ({ caseId }) => {
    return (
        <OverlayBaseHelper<ReasonForRelationModalProps>
            id={overlayIdEnum.REASON_FOR_RELATION_MODAL}
        >
            {(renderProps) => {
                const { reports } = renderProps.overlayBase.overlayState.customProperties;

                return (
                    <ReasonForRelationModalContent
                        caseId={caseId}
                        reports={reports}
                        closePanel={renderProps.closePanel}
                    />
                );
            }}
        </OverlayBaseHelper>
    );
};

type ReasonForRelationModalContentProps = {
    caseId: number;
    reports: ElasticReport[];
    closePanel: () => void;
};

const ReasonForRelationModalContent: FC<ReasonForRelationModalContentProps> = ({
    caseId,
    reports,
    closePanel,
}) => {
    const dispatch = useDispatch<RmsDispatch>();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;

    const { getForm } = useFormGetter();
    const onSave = React.useCallback(() => {
        const form = getForm(RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name);
        if (form) {
            return form.submit().then((result) => {
                const formModel = result.form.getState().model;
                const caseRequest = convertFromFormModelToCaseRequest(
                    formModel,
                    caseId,
                    individualReportSelectionEnabled
                );
                return caseReportLinksResource
                    .createCaseReportLinksFromCase(caseRequest)
                    .then(() => {
                        closePanel();
                        form.resetUi();
                        form.resetModel();
                        dispatch((dispatch, getState) => {
                            caseOnEnter.call(
                                { dispatch, getState },
                                {
                                    params: {
                                        caseId,
                                    },
                                }
                            );
                        });
                    });
            });
        } else {
            return Promise.resolve();
        }
    }, [getForm, caseId, individualReportSelectionEnabled, closePanel, dispatch]);

    return (
        <Modal id={overlayIdEnum.REASON_FOR_RELATION_MODAL} title={strings.title} onSave={onSave}>
            <ReasonForRelationContent
                caseId={caseId}
                reports={reports}
                formLocation={overlayIdEnum.REASON_FOR_RELATION_MODAL}
            />
        </Modal>
    );
};

export const ReasonForRelationContent: FC<{
    caseId: number;
    reports: ElasticReport[];
    formLocation: ReasonForRelationFormLocationType;
}> = ({ caseId, reports, formLocation }) => {
    const dispatch = useDispatch<RmsDispatch>();
    const [reportShortTitleWrapper, setReportShortTitleWrapper] = React.useState<
        ReportShortTitleWrapper | undefined
    >();
    const reportIds = reports.map(({ id }) => id);
    const reportIdsRef = useRef(reportIds);

    const loadReportShortTitles = React.useCallback(() => {
        const reportIdentifierView = buildReportIdentifierView(reports);
        return reportsResource.findReportTitlesByReportIdentifier(reportIdentifierView);
    }, [reports]);

    const applicationSettings = useSelector(applicationSettingsSelector);
    const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;

    const onLoad = React.useCallback(
        (wrapper: ReportShortTitleWrapper) => {
            // store the report short titles in redux
            dispatch(
                withEntityItems(
                    {
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: [
                            ...flatten(values(wrapper.reportShortTitlesByREN)),
                            ...flatten(
                                values(wrapper.reportShortTitlesByRecordWithoutRENRecordNumber)
                            ),
                        ],
                    },
                    { type: 'STORE_REPORT_SHORT_TITLES' }
                )
            );

            // initialize form state
            formsRegistry.maybeDeferredOperation(
                RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name,
                undefined,
                (form) => {
                    const selectedReportIds = individualReportSelectionEnabled
                        ? reportIdsRef.current
                        : undefined;
                    const formModel = convertReportShortTitleWrapperToFormModel(
                        wrapper,
                        selectedReportIds,
                        caseId
                    );
                    form.set('', formModel);

                    // this data is passed into the form component to be displayed in the UI; it does not exist in form state
                    setReportShortTitleWrapper(wrapper);
                }
            );
        },
        [caseId, dispatch, reportIdsRef, individualReportSelectionEnabled]
    );
    const { isLoading, errorMessage } = useResource(loadReportShortTitles, onLoad);

    if (errorMessage) {
        return <InlineBanner status="error">{errorMessage}</InlineBanner>;
    }

    if (isLoading) {
        return <SkeletonText noOfLines={3} />;
    }

    return (
        <ReasonForRelationForm
            context={RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name}
            formLocation={formLocation}
            reportShortTitleWrapper={reportShortTitleWrapper}
        />
    );
};

export default ReasonForRelationModal;

import React, { useCallback, useEffect } from 'react';
// @ts-expect-error client-common to client RND-7529
import MapView from 'esri/views/MapView'; // eslint-disable-line
import { CenterT } from '../../types';
import SimpleZoomControls from '../SimpleZoomControls';

export type SimpleZoomControlsPositionT = 'middle' | 'top';
export type SimpleZoomControlsButtonStyleT = 'arc';
interface SimpleZoomControlsProps {
    mapView: MapView | undefined;
    centerForUpdate?: CenterT;
    displayOnlyMode: boolean;
    zoomForUpdate?: number;
    zoom: number;
    zoomControlPosition?: SimpleZoomControlsPositionT;
    zoomButtonStyle?: SimpleZoomControlsButtonStyleT;
}

export const useSimpleZoomControls: React.FC<SimpleZoomControlsProps> = ({
    mapView,
    centerForUpdate,
    displayOnlyMode,
    zoomForUpdate,
    zoom,
    zoomControlPosition = 'top',
    zoomButtonStyle,
}) => {
    useEffect(() => {
        if (mapView && centerForUpdate) {
            mapView.goTo(
                {
                    target: [centerForUpdate.lng, centerForUpdate.lat],
                    zoom: zoomForUpdate,
                },
                { animate: false }
            );
        }
    }, [centerForUpdate, zoomForUpdate, mapView]);

    const zoomIn = useCallback(() => {
        if (mapView) {
            mapView.goTo({
                target: mapView.center,
                zoom: mapView.zoom + 1,
            });
        }
    }, [mapView]);

    const zoomOut = useCallback(() => {
        if (mapView) {
            mapView.goTo({
                target: mapView.center,
                zoom: mapView.zoom - 1,
            });
        }
    }, [mapView]);

    const zoomToLevel = useCallback(
        (zoomLevel) => {
            if (mapView) {
                mapView.goTo({
                    target: mapView.center,
                    zoom: zoomLevel,
                });
            }
        },
        [mapView]
    );

    return displayOnlyMode ? null : (
        <SimpleZoomControls
            zoomButtonStyle={zoomButtonStyle}
            zoom={zoom}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            zoomToLevel={zoomToLevel}
            zoomControlPosition={zoomControlPosition}
        />
    );
};

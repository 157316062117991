// Actual dependencies of this module for bootstrapping
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';

// Start TODO PRINTING-REFACTOR determine a long term pattern for resources in shared code
import { buildResource as buildLocationsResource } from '~/client-common/core/domain/locations/resources/locationsResource';
import { buildResource as buildPointLocationsResource } from '~/client-common/core/domain/point-locations/resources/pointLocationsResource';
import { buildResource as buildCountryResource } from '~/client-common/core/domain/countries/resources/countryResource';
import { buildResource as buildFacilitiesResource } from '~/client-common/core/domain/facilities/resources/facilitiesResource';
import { buildResource as buildRetentionPoliciesResource } from '~/client-common/core/domain/retention-policies/resources/retentionPoliciesResource';
import { buildResource as buildStorageLocationResource } from '~/client-common/core/domain/storage-locations/resources/storageLocationResource';
import { buildResource as buildImportEventResource } from '~/client-common/core/domain/import-events/resources/importEventResource';
import { buildResource as buildChainEventsResource } from '~/client-common/core/domain/chain-events/resources/chainEventsResource';
import { buildResource as buildDispositionEventsResource } from '~/client-common/core/domain/disposition-events/resources/dispositionEventsResource';
import { buildResource as buildStaffRemarkResource } from '~/client-common/core/domain/staff-remarks/resources/staffRemarkResource';
import { buildResource as buildInventoriesResource } from '~/client-common/core/domain/inventories/resources/inventoriesResource';
import { buildResource as buildInventoryItemsResource } from '~/client-common/core/domain/inventory-items/resources/inventoryItemsResource';
import { buildResource as buildCasePrintingResource } from '~/client-common/core/domain/case-printing-templates/resources/casePrintingResource';
import { buildResource as buildUserRolesResource } from '~/client-common/core/domain/user-roles/resources/userRoleResource';
import { buildResource as buildPrintablesResource } from '~/client-common/core/domain/printables/resources/printablesResource';
import { buildResource as buildAbilitiesResource } from '~/client-common/core/domain/abilities/resources/abilitiesResource';
import { buildResource as buildAbilityRoleLinksResource } from '~/client-common/core/domain/ability-role-links/resources/abilityRoleLinksResource';
import { buildResource as buildRolesResource } from '~/client-common/core/domain/roles/resources/rolesResource';
import { buildResource as buildItemFacilityLinksResources } from '~/client-common/core/domain/item-facility-links/resources/itemFacilityLinksResource';
import { buildResource as buildRoleApprovalsResource } from '~/client-common/core/domain/role-approvals/resources/roleApprovalsResource';
import { buildResource as buildUserAssignmentsResource } from '~/client-common/core/domain/user-assignments/resources/userAssignmentsResource';
import { buildResource as buildPropertyStatusResource } from '~/client-common/core/domain/property-statuses/resources/propertyStatusResource';
import { buildResource as buildAttachmentsResource } from '~/client-common/core/domain/attachments/resources/attachmentsResource';
import { buildResource as buildUserNotificationsResource } from '~/client-common/core/domain/user-notifications/resources/userNotificationsResource';
import { buildResource as buildTowVehiclesResource } from '~/client-common/core/domain/tow-vehicles/resources/towVehiclesResource';
import { buildResource as buildAssistingOfficersResource } from '~/client-common/core/domain/assisting-officers/resources/assistingOfficersResource';
import { buildResource as buildCustodialResponsibleOfficerResource } from '~/client-common/core/domain/custodial-responsible-officers/resources/custodialResponsibleOfficerResource';
import { buildResource as buildImpoundsResource } from '~/client-common/core/domain/impounds/resources/impoundsResource';
import { buildResource as buildNotificationPreferencesResource } from '~/client-common/core/domain/user-opt-outs/resources/notificationPreferencesResource';
import { buildResource as buildNameReportLinksResource } from '~/client-common/core/domain/name-report-links/resources/nameReportLinksResource';
import { buildResource as buildReportAttributesResource } from '~/client-common/core/domain/report-attributes/resources/reportAttributesResource';
import { buildResource as buildReportCaseStatusResource } from '~/client-common/core/domain/report-case-statuses/resources/reportCaseStatusResource';
import { buildResource as buildReportCommentsResource } from '~/client-common/core/domain/report-comments/resources/reportCommentsResource';
import { buildResource as buildStreetSearchAliasResource } from '~/client-common/core/domain/street-aliases/resources/streetSearchAliasResource';
import { buildResource as buildStreetSegmentResouce } from '~/client-common/core/domain/street-segments/resources/streetSegmentResource';
import { buildResource as buildStreetCenterlineResource } from '~/client-common/core/domain/street-centerline/resources/streetCenterlineResource';
import { buildResource as buildAttributeTypeCodesTypeLinksResource } from '~/client-common/core/domain/attribute-type-codes-type-links/resources/attributeTypeCodesTypeLinksResource';
import { buildResource as buildAttributeCodesTypeLinksResource } from '~/client-common/core/domain/attribute-codes-type-links/resources/attributeCodesTypeLinksResource';
import { buildResource as buildWarrantActivityStatusLinksResource } from '~/client-common/core/domain/warrant-activity-status-links/resources/warrantActivityStatusLinkResource';
import { buildResource as buildWarrantsResource } from '~/client-common/core/domain/warrants/resources/warrantsResource';
import { buildResource as buildWarrantActivityResource } from '~/client-common/core/domain/warrant-activities/resources/warrantActivityResource';
import { buildResource as buildEventDetailsResource } from '~/client-common/core/domain/event-details/resources/eventDetailsResource';
import { buildResource as buildCourtCasesResource } from '~/client-common/core/domain/court-cases/resources/courtCasesResource';
import { buildResource as buildReportNotificationsResource } from '~/client-common/core/domain/report-notifications/resources/reportNotificationsResource';
import { buildResource as buildVehicleMakesResource } from '~/client-common/core/domain/vehicle-makes/resources/vehicleMakesResource';
import { buildResource as buildVehicleModelsResource } from '~/client-common/core/domain/vehicle-models/resources/vehicleModelsResource';
import { buildResource as buildCurrentViewersResource } from '~/client-common/core/domain/current-viewers/resources/currentViewersResource';
import { buildResource as buildOffensesResource } from '~/client-common/core/domain/offenses/resources/offensesResource';
import { buildResource as buildExportReleaseResource } from '~/client-common/core/domain/export-releases/resources/exportReleasesResource';
import { buildResource as buildReportResource } from '~/client-common/core/domain/reports/resources/reportResource';
import { buildResource as buildReportHistoryResource } from '~/client-common/core/domain/reports/resources/reportHistoryResource';
import { buildResource as buildReportCardBundleResource } from '~/client-common/core/domain/reports/resources/reportCardBundleResource';
import { buildResource as buildReportShortTitlesResource } from '~/client-common/core/domain/report-short-titles/resources/reportShortTitlesResource';
import { buildResource as buildPersonProfileResource } from '~/client-common/core/domain/person-profiles/resources/personProfileResource';
import { buildResource as buildOrganizationProfileResource } from '~/client-common/core/domain/organization-profiles/resources/organizationProfileResource';
import { buildResource as buildItemProfileResource } from '~/client-common/core/domain/item-profiles/resources/itemProfileResource';
import { buildResource as buildFieldNoteResource } from '~/client-common/core/domain/field-notes/resources/fieldNoteResource';
import { buildResource as buildArrestResource } from '~/client-common/core/domain/arrests/resources/arrestResource';
import { buildResource as buildBookingResource } from '~/client-common/core/domain/arrests/resources/bookingResource';
import { buildResource as buildAdminConsortiumResource } from '~/client-common/core/domain/full-consortium-view/resources/AdminConsortiumResource';
import { buildResource as buildEvidencePrintingTemplatesResource } from '~/client-common/core/domain/evidence-printing-templates/resources/evidencePrintingTemplatesResource';
import { buildResource as buildSubdivisionsResource } from '~/client-common/core/domain/subdivisions/resources/subdivisionsResource';
import { buildResource as buildFilesResource } from '~/client-common/core/domain/files/resources/filesResource';
import { buildResource as buildDispatchAreasResource } from '~/client-common/core/domain/dispatch-areas/resources/dispatchAreasResource';
import { buildResource as buildDuplicateEventSearchConfigResource } from '~/client-common/core/domain/duplicate-event-search-configs/resources/duplicateEventSearchConfigResource';
import { buildResource as buildCadRolodexResource } from '~/client-common/core/domain/cad-rolodex/resources/cadRolodexResource';
import { buildResource as buildReportingEventsResource } from '~/client-common/core/domain/reporting-events/resources/reportingEventsResource';
import { buildResource as buildEvidencePrintablesResource } from '~/client-common/core/domain/evidence-printables/resources/evidencePrintablesResource';
import { buildResource as buildCsvExportColumnsResource } from '~/client-common/core/domain/csv-export-columns/resources/csvExportColumnsResource';
import { buildResource as buildAlertSchedulesResource } from '~/client-common/core/domain/alert-schedules/resources/alertSchedulesResource';
import { buildResource as buildStationsResource } from '~/client-common/core/domain/stations/resources/stationsResource';
import { buildResource as buildNameAttributesResource } from '~/client-common/core/domain/name-attributes/resources/nameAttributesResource';
import { buildResource as buildIdFormatConfigurationResource } from '~/client-common/core/domain/id-format-configurations/resources/idFormatConfigurationResource';
import { buildResource as buildUserProfileResource } from '~/client-common/core/domain/user-profiles/resources/userProfileResource';
import { buildResource as buildExternalLinksResource } from '~/client-common/core/domain/external-links/resources/externalLinksResource';
import { buildResource as buildNarrativeGuidesResource } from '~/client-common/core/domain/narrative-guides/resources/narrativeGuidesResource';
import { buildResource as buildExpungementResource } from '~/client-common/core/domain/charges/resources/expungementResource';
import { buildResource as buildChargesResource } from '~/client-common/core/domain/charges/resources/chargesResource';
import { buildResource as buildOffenseCodesResource } from '~/client-common/core/domain/offense-codes/resources/offenseCodesResource';
import { buildResource as buildUsageLogsResource } from '~/client-common/core/domain/usage-logs/resources/usageLogsResource';
import { buildResource as buildEvidenceReportResource } from '~/client-common/core/domain/evidence/resources/evidenceReportResource';
import { buildResource as buildEvidenceBootstrapResource } from '~/client-common/core/domain/evidence/resources/evidenceBootstrapResource';
import { buildResource as buildEvidenceBasketResource } from '~/client-common/core/domain/evidence/resources/evidenceBasketResource';
import { buildResource as buildExportsResource } from '~/client-common/core/domain/exports/resources/exportsResource';
import { buildResource as buildAssociatedRecordsResource } from '~/client-common/core/domain/reports/resources/associatedRecordsResource';
import { augmentMomentParseTwoDigitYear } from '~/client-common/core/dates/utils/augmentMomentParseTwoDigitYear';
import { buildResource as buildExportPresetsResource } from '~/client-common/core/domain/export-presets/resources/exportPresetsResource';
import { buildResource as buildAttributeCodesResource } from '~/client-common/core/domain/attribute-codes/resources/attributeCodesResource';
import { buildResource as buildHiddenDepartmentLinkTypesResource } from '~/client-common/core/domain/hidden-department-link-types/resources/hiddenDepartmentLinkTypesResource';
import { buildResource as buildDojSchoolsResource } from '~/client-common/core/domain/doj-schools/resources/dojSchoolsResource';
import { buildResource as buildRipaOffenseCodesResource } from '~/client-common/core/domain/ripa-offense-codes/resources/ripaOffenseCodeResource';
import { buildResource as buildRmsSavedSearchResource } from './modules/search/core/resources/rmsSavedSearchResource';
import { buildResource as buildSealingResource } from './modules/record-privacy/core/resources/sealingResource';
import { buildResource as buildUcrClassificationResource } from './modules/reports/ucr-classification/resources/ucrClassificationResource';

import Resource from './lib/resources/Resource';
import { req } from './lib/ajax';
import { buildArbiter } from './modules/core/arbiter';

import { App } from './App';
import store from './core/store';
import { initSentry, sentrySetExtra } from './core/sentry';
import { initLogRocket } from './core/logRocket';
import { initReactHotKeys } from './core/reactHotKeys';

initSentry();
initLogRocket();

augmentMomentParseTwoDigitYear(moment);

// "DI" redux store to consumers that need it
buildArbiter(store);

// build resources
buildLocationsResource(Resource, req);
buildPointLocationsResource(Resource, req);
buildCountryResource(Resource, req);
buildRetentionPoliciesResource(Resource, req);
buildFacilitiesResource(Resource, req);
buildStorageLocationResource(Resource, req);
buildImportEventResource(Resource, req);
buildChainEventsResource(Resource, req);
buildDispositionEventsResource(Resource, req);
buildStaffRemarkResource(Resource, req);
buildInventoriesResource(Resource, req);
buildInventoryItemsResource(Resource, req);
buildCasePrintingResource(Resource, req);
buildUserRolesResource(Resource, req);
buildPrintablesResource(Resource, req);
buildAbilitiesResource(Resource, req);
buildItemFacilityLinksResources(Resource, req);
buildAbilityRoleLinksResource(Resource, req);
buildRolesResource(Resource, req);
buildRoleApprovalsResource(Resource, req);
buildUserAssignmentsResource(Resource, req);
buildPropertyStatusResource(Resource, req);
buildAttachmentsResource(Resource, req);
buildUserNotificationsResource(Resource, req);
buildAssistingOfficersResource(Resource, req);
buildCustodialResponsibleOfficerResource(Resource, req);
buildTowVehiclesResource(Resource, req);
buildImpoundsResource(Resource, req);
buildNotificationPreferencesResource(Resource, req);
buildNameReportLinksResource(Resource, req);
buildReportAttributesResource(Resource, req);
buildReportCaseStatusResource(Resource, req);
buildReportCommentsResource(Resource, req);
buildStreetSearchAliasResource(Resource, req);
buildStreetSegmentResouce(Resource, req);
buildStreetCenterlineResource(Resource, req);
buildAttributeTypeCodesTypeLinksResource(Resource, req);
buildAttributeCodesTypeLinksResource(Resource, req);
buildWarrantActivityStatusLinksResource(Resource, req);
buildWarrantsResource(Resource, req);
buildWarrantActivityResource(Resource, req);
buildEventDetailsResource(Resource, req);
buildCourtCasesResource(Resource, req);
buildReportNotificationsResource(Resource, req);
buildVehicleMakesResource(Resource, req);
buildVehicleModelsResource(Resource, req);
buildCurrentViewersResource(Resource, req);
buildOffensesResource(Resource, req);
buildExportReleaseResource(Resource, req);
buildReportResource(Resource, req);
buildReportHistoryResource(Resource, req);
buildReportCardBundleResource(Resource, req);
buildReportShortTitlesResource(Resource, req);
buildPersonProfileResource(Resource, req);
buildOrganizationProfileResource(Resource, req);
buildItemProfileResource(Resource, req);
buildFieldNoteResource(Resource, req);
buildArrestResource(Resource, req);
buildBookingResource(Resource, req);
buildAdminConsortiumResource(Resource, req);
buildEvidencePrintingTemplatesResource(Resource, req);
buildSubdivisionsResource(Resource, req);
buildFilesResource(Resource, req);
buildDispatchAreasResource(Resource, req);
buildDuplicateEventSearchConfigResource(Resource, req);
buildCadRolodexResource(Resource, req);
buildReportingEventsResource(Resource, req);
buildEvidencePrintablesResource(Resource, req);
buildCsvExportColumnsResource(Resource, req);
buildAlertSchedulesResource(Resource, req);
buildStationsResource(Resource, req);
buildUcrClassificationResource(Resource, req);
buildNameAttributesResource(Resource, req);
buildIdFormatConfigurationResource(Resource, req);
buildUserProfileResource(Resource, req);
buildSealingResource(Resource, req);
buildRmsSavedSearchResource(Resource, req);
buildExternalLinksResource(Resource, req);
buildExpungementResource(Resource, req);
buildChargesResource(Resource, req);
buildOffenseCodesResource(Resource, req);
buildNarrativeGuidesResource(Resource, req);
buildUsageLogsResource(Resource, req);
buildEvidenceReportResource(Resource, req);
buildEvidenceBootstrapResource(Resource, req);
buildEvidenceBasketResource(Resource, req);
buildExportsResource(Resource, req);
buildAssociatedRecordsResource(Resource, req);
buildExportPresetsResource(Resource, req);
buildAttributeCodesResource(Resource, req);
buildHiddenDepartmentLinkTypesResource(Resource, req);
buildDojSchoolsResource(Resource, req);
buildRipaOffenseCodesResource(Resource, req);
// End TODO PRINTING-REFACTOR

// Sentry extra data handler
let previousState = null;
store.subscribe(() => {
    const state = store.getState();
    if (state.sessionHistory !== previousState) {
        previousState = state.sessionHistory;
        sentrySetExtra(state);
    }
});

// Setup configuration preferences for the react-hotkeys library
initReactHotKeys();

ReactDOM.render(<App />, document.getElementById('cobalt'));

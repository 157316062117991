import { EntityTypeEnum } from '@mark43/rms-api';
import React, { FC, useMemo } from 'react';
import { Box, cssVar } from 'arc';
import { TasksTable } from '../../../../tasks/core/components/TasksTable';
import { columnKeys } from '../../../../tasks/core/components/TasksTable/constants';
import { useTasksSearch } from '../../../../tasks/core/hooks/useTasksSearch';
import { defaultTasksSorts } from '../../../../tasks/core/hooks/useTasksSorts';

const hiddenColumns = [columnKeys.ownerTitle];

export const EFileTasksTable: FC<{ eFileId: number }> = ({ eFileId }) => {
    const baseQuery = useMemo(
        () => ({
            ...defaultTasksSorts,
            formData: {
                owners: [
                    {
                        ownerId: eFileId,
                        ownerType: EntityTypeEnum.E_FILE.name,
                    },
                ],
            },
        }),
        [eFileId]
    );

    const {
        results,
        loading,
        initialLoading,
        pagination,
        sort,
        onColumnSortChange,
    } = useTasksSearch(baseQuery);

    return (
        <Box marginTop={cssVar('arc.space.4')}>
            <TasksTable
                results={results}
                hiddenColumns={hiddenColumns}
                config={{ sort }}
                loading={loading}
                initialLoading={initialLoading}
                paginationProps={pagination}
                onColumnSortChange={onColumnSortChange}
            />
        </Box>
    );
};

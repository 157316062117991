import { Store } from 'redux';
import { ElasticReport, LinkTypesEnum } from '@mark43/rms-api';
import { iconPaths } from '@arc/icon';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { buildElasticLocationLines } from '~/client-common/core/domain/elastic-involved-locations/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { RootState } from '../../../../../../../legacy-redux/reducers/rootReducer';
import { FormatMentionItemMapper, Mention } from '../types';
import { getDefaultIcon, getMentionEntityLinkUrl, getTruthyValues } from '../utils';
import { iconTypes } from '../../../../../components/Icon';
import {
    convertApprovalStatusIconTypeToThemeColor,
    convertReportApprovalStatusToIconType,
} from '../../../../../../records/core/components/ApprovalStatusIcon';
import { currentThemeSelector } from '../../../../../styles/state';

const ICON_TYPE_TO_ICON_MAPPER = {
    [iconTypes.RECORDS_APPROVED]: iconPaths.RecordsApproved,
    [iconTypes.SUPERVISOR_APPROVED]: iconPaths.SupervisorApproved,
    [iconTypes.PENDING]: iconPaths.Pending,
    [iconTypes.REJECTED]: iconPaths.Rejected,
    [iconTypes.DRAFT]: iconPaths.Draft,
    [iconTypes.PENDING_SECONDARY_REVIEW]: iconPaths.PendingSecondaryReview,
    [iconTypes.PERMISSIONS]: iconPaths.Permissions,
};

const getApprovalStatusIcon = ({
    approvalStatus,
    isSealed,
    isPartiallySealed,
    isVacated,
    theme,
}: {
    approvalStatus?: keyof typeof approvalStatusClientEnum;
    isSealed?: boolean;
    isPartiallySealed?: boolean;
    isVacated?: boolean;
    theme: ReturnType<typeof currentThemeSelector>;
}) => {
    const reportIconType = convertReportApprovalStatusToIconType(approvalStatus);
    const iconColor = convertApprovalStatusIconTypeToThemeColor(reportIconType);

    if (isSealed || isPartiallySealed || isVacated) {
        return getDefaultIcon(
            ICON_TYPE_TO_ICON_MAPPER[iconTypes.PERMISSIONS],
            theme.colors.highContrastGrey
        );
    }

    return getDefaultIcon(
        ICON_TYPE_TO_ICON_MAPPER[reportIconType],
        iconColor ? theme.colors[iconColor] : theme.colors.mediumGrey
    );
};

const prepareMentionedItem = (item: ElasticReport, store: Store<RootState>): Mention => {
    const applicationSettings = applicationSettingsSelector(store.getState());
    const formatFieldByName = formatFieldByNameSelector(store.getState());
    const currentTheme = currentThemeSelector(store.getState());
    const dateFormatter = currentDepartmentDateFormatterSelector(store.getState());
    const isSubPremiseSupportEnabled = !!applicationSettings.SUBPREMISE_SUPPORT_ENABLED;

    const name = getTruthyValues(
        [
            item.ren ? formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER) : undefined,
            item.ren,
            item.shortTitle,
        ],
        ' '
    );

    const location = item.involvedElasticLocations.find(
        ({ type }) => type === LinkTypesEnum.LOCATION_OF_EVENT
    );
    const descriptions = location
        ? [
              buildElasticLocationLines(location, {
                  isSubPremiseSupportEnabled,
              }).join(', '),
          ]
        : undefined;

    const { isSealed, isPartiallySealed, clientApprovalStatus } = item;
    const isVacated = item.reportDefinition.charges.some((item) => item.isVacated);
    const approvalStatusIcon = getApprovalStatusIcon({
        approvalStatus: clientApprovalStatus,
        isSealed,
        isPartiallySealed,
        isVacated,
        theme: currentTheme,
    });

    return {
        id: item.id.toString(),
        name,
        text: name,
        descriptions,
        category: 'REPORTS',
        defaultIcon: approvalStatusIcon,
        url: getMentionEntityLinkUrl({ itemType: 'REPORTS', itemId: item.id }),
        createdDate: dateFormatter.formatDate(item.reportCreationDateUtc),
    };
};

const formatMapper: FormatMentionItemMapper<ElasticReport> = {
    resourceMethod: 'searchReports',
    prepareMentionedItem,
};
export default formatMapper;

/**
 * Must match server enum {@link
 *   cobalt-core-client/personnel-client/src/main/java/com/mark43/core/personnel/model/department/StatePlaneZone.java}
 * @type {Object}
 */
export default {
    ALABAMA_EAST: {
        value: 'ALABAMA_EAST',
        display: '0101 - Alabama East',
    },
    ALABAMA_WEST: {
        value: 'ALABAMA_WEST',
        display: '0102 - Alabama West',
    },
    ARIZONA_EAST: {
        value: 'ARIZONA_EAST',
        display: '0201 - Arizona East',
    },
    ARIZONA_CENTRAL: {
        value: 'ARIZONA_CENTRAL',
        display: '0202 - Arizona Central',
    },
    ARIZONA_WEST: {
        value: 'ARIZONA_WEST',
        display: '0203 - Arizona West',
    },
    ARKANSAS_NORTH: {
        value: 'ARKANSAS_NORTH',
        display: '0301 - Arkansas North',
    },
    ARKANSAS_SOUTH: {
        value: 'ARKANSAS_SOUTH',
        display: '0302 - Arkansas South',
    },
    CALIFORNIA_ZONE_1: {
        value: 'CALIFORNIA_ZONE_1',
        display: '0401 - California Zone 1',
    },
    CALIFORNIA_ZONE_2: {
        value: 'CALIFORNIA_ZONE_2',
        display: '0402 - California Zone 2',
    },
    CALIFORNIA_ZONE_3: {
        value: 'CALIFORNIA_ZONE_3',
        display: '0403 - California Zone 3',
    },
    CALIFORNIA_ZONE_4: {
        value: 'CALIFORNIA_ZONE_4',
        display: '0404 - California Zone 4',
    },
    CALIFORNIA_ZONE_5: {
        value: 'CALIFORNIA_ZONE_5',
        display: '0405 - California Zone 5',
    },
    CALIFORNIA_ZONE_6: {
        value: 'CALIFORNIA_ZONE_6',
        display: '0406 - California Zone 6',
    },
    COLORADO_NORTH: {
        value: 'COLORADO_NORTH',
        display: '0501 - Colorado North',
    },
    COLORADO_CENTRAL: {
        value: 'COLORADO_CENTRAL',
        display: '0502 - Colorado Central',
    },
    COLORADO_SOUTH: {
        value: 'COLORADO_SOUTH',
        display: '0503 - Colorado South',
    },
    CONNECTICUT: {
        value: 'CONNECTICUT',
        display: '0600 - Connecticut',
    },
    DELAWARE: {
        value: 'DELAWARE',
        display: '0700 - Delaware',
    },
    FLORIDA_EAST: {
        value: 'FLORIDA_EAST',
        display: '0901 - Florida East',
    },
    FLORIDA_WEST: {
        value: 'FLORIDA_WEST',
        display: '0902 - Florida West',
    },
    FLORIDA_NORTH: {
        value: 'FLORIDA_NORTH',
        display: '0903 - Florida North',
    },
    GEORGIA_EAST: {
        value: 'GEORGIA_EAST',
        display: '1001 - Georgia East',
    },
    GEORGIA_WEST: {
        value: 'GEORGIA_WEST',
        display: '1002 - Georgia West',
    },
    IDAHO_EAST: {
        value: 'IDAHO_EAST',
        display: '1101 - Idaho East',
    },
    IDAHO_CENTRAL: {
        value: 'IDAHO_CENTRAL',
        display: '1102 - Idaho Central',
    },
    IDAHO_WEST: {
        value: 'IDAHO_WEST',
        display: '1103 - Idaho West',
    },
    ILLINOIS_EAST: {
        value: 'ILLINOIS_EAST',
        display: '1201 - Illinois East',
    },
    ILLINOIS_WEST: {
        value: 'ILLINOIS_WEST',
        display: '1202 - Illinois West',
    },
    INDIANA_EAST: {
        value: 'INDIANA_EAST',
        display: '1301 - Indiana East',
    },
    INDIANA_WEST: {
        value: 'INDIANA_WEST',
        display: '1302 - Indiana West',
    },
    IOWA_NORTH: {
        value: 'IOWA_NORTH',
        display: '1401 - Iowa North',
    },
    IOWA_SOUTH: {
        value: 'IOWA_SOUTH',
        display: '1402 - Iowa South',
    },
    KANSAS_NORTH: {
        value: 'KANSAS_NORTH',
        display: '1501 - Kansas North',
    },
    KANSAS_SOUTH: {
        value: 'KANSAS_SOUTH',
        display: '1502 - Kansas South',
    },
    KENTUCKY_SINGLE_ZONE: {
        value: 'KENTUCKY_SINGLE_ZONE',
        display: '1600 - Kentucky Single Zone',
    },
    KENTUCKY_NORTH: {
        value: 'KENTUCKY_NORTH',
        display: '1601 - Kentucky North',
    },
    KENTUCKY_SOUTH: {
        value: 'KENTUCKY_SOUTH',
        display: '1602 - Kentucky South',
    },
    LOUISIANA_NORTH: {
        value: 'LOUISIANA_NORTH',
        display: '1701 - Louisiana North',
    },
    LOUISIANA_SOUTH: {
        value: 'LOUISIANA_SOUTH',
        display: '1702 - Louisiana South',
    },
    LOUISIANA_OFFSHORE: {
        value: 'LOUISIANA_OFFSHORE',
        display: '1703 - Louisiana Offshore',
    },
    MAINE_EAST_1983: {
        value: 'MAINE_EAST_1983',
        display: '1801 - Maine East 1983',
    },
    MAINE_WEST_1983: {
        value: 'MAINE_WEST_1983',
        display: '1802 - Maine West 1983',
    },
    MAINE_EAST_2000: {
        value: 'MAINE_EAST_2000',
        display: '1811 - Maine East 2000',
    },
    MAINE_CENTRAL_2000: {
        value: 'MAINE_CENTRAL_2000',
        display: '1812 - Maine Central 2000',
    },
    MAINE_WEST_2000: {
        value: 'MAINE_WEST_2000',
        display: '1813 - Maine West 2000',
    },
    MARYLAND: {
        value: 'MARYLAND',
        display: '1900 - Maryland',
    },
    MASSACHUSETTS_MAINLAND: {
        value: 'MASSACHUSETTS_MAINLAND',
        display: '2001 - Massachusetts Mainland',
    },
    MASSACHUSETTS_ISLAND: {
        value: 'MASSACHUSETTS_ISLAND',
        display: '2002 - Massachusetts Island',
    },
    MICHIGAN_NORTH: {
        value: 'MICHIGAN_NORTH',
        display: '2111 - Michigan North',
    },
    MICHIGAN_CENTRAL: {
        value: 'MICHIGAN_CENTRAL',
        display: '2112 - Michigan Central',
    },
    MICHIGAN_SOUTH: {
        value: 'MICHIGAN_SOUTH',
        display: '2113 - Michigan South',
    },
    MINNESOTA_NORTH: {
        value: 'MINNESOTA_NORTH',
        display: '2201 - Minnesota North',
    },
    MINNESOTA_CENTRAL: {
        value: 'MINNESOTA_CENTRAL',
        display: '2202 - Minnesota Central',
    },
    MINNESOTA_SOUTH: {
        value: 'MINNESOTA_SOUTH',
        display: '2203 - Minnesota South',
    },
    MISSISSIPPI_EAST: {
        value: 'MISSISSIPPI_EAST',
        display: '2301 - Mississippi East',
    },
    MISSISSIPPI_WEST: {
        value: 'MISSISSIPPI_WEST',
        display: '2302 - Mississippi West',
    },
    MISSOURI_EAST: {
        value: 'MISSOURI_EAST',
        display: '2401 - Missouri East',
    },
    MISSOURI_CENTRAL: {
        value: 'MISSOURI_CENTRAL',
        display: '2402 - Missouri Central',
    },
    MISSOURI_WEST: {
        value: 'MISSOURI_WEST',
        display: '2403 - Missouri West',
    },
    MONTANA: {
        value: 'MONTANA',
        display: '2500 - Montana',
    },
    NEBRASKA: {
        value: 'NEBRASKA',
        display: '2600 - Nebraska',
    },
    NEVADA_EAST: {
        value: 'NEVADA_EAST',
        display: '2701 - Nevada East',
    },
    NEVADA_CENTRAL: {
        value: 'NEVADA_CENTRAL',
        display: '2702 - Nevada Central',
    },
    NEVADA_WEST: {
        value: 'NEVADA_WEST',
        display: '2703 - Nevada West',
    },
    NEW_HAMPSHIRE: {
        value: 'NEW_HAMPSHIRE',
        display: '2800 - New Hampshire',
    },
    NEW_JERSEY: {
        value: 'NEW_JERSEY',
        display: '2900 - New Jersey',
    },
    NEW_MEXICO_EAST: {
        value: 'NEW_MEXICO_EAST',
        display: '3001 - New Mexico East',
    },
    NEW_MEXICO_CENTRAL: {
        value: 'NEW_MEXICO_CENTRAL',
        display: '3002 - New Mexico Central',
    },
    NEW_MEXICO_WEST: {
        value: 'NEW_MEXICO_WEST',
        display: '3003 - New Mexico West',
    },
    NEW_YORK_EAST: {
        value: 'NEW_YORK_EAST',
        display: '3101 - New York East',
    },
    NEW_YORK_CENTRAL: {
        value: 'NEW_YORK_CENTRAL',
        display: '3102 - New York Central',
    },
    NEW_YORK_WEST: {
        value: 'NEW_YORK_WEST',
        display: '3103 - New York West',
    },
    NEW_YORK_LONG_ISLAND: {
        value: 'NEW_YORK_LONG_ISLAND',
        display: '3104 - New York Long Island',
    },
    NORTH_CAROLINA: {
        value: 'NORTH_CAROLINA',
        display: '3200 - North Carolina',
    },
    NORTH_DAKOTA_NORTH: {
        value: 'NORTH_DAKOTA_NORTH',
        display: '3301 - North Dakota North',
    },
    NORTH_DAKOTA_SOUTH: {
        value: 'NORTH_DAKOTA_SOUTH',
        display: '3302 - North Dakota South',
    },
    OHIO_NORTH: {
        value: 'OHIO_NORTH',
        display: '3401 - Ohio North',
    },
    OHIO_SOUTH: {
        value: 'OHIO_SOUTH',
        display: '3402 - Ohio South',
    },
    OKLAHOMA_NORTH: {
        value: 'OKLAHOMA_NORTH',
        display: '3501 - Oklahoma North',
    },
    OKLAHOMA_SOUTH: {
        value: 'OKLAHOMA_SOUTH',
        display: '3502 - Oklahoma South',
    },
    OREGON_NORTH: {
        value: 'OREGON_NORTH',
        display: '3601 - Oregon North',
    },
    OREGON_SOUTH: {
        value: 'OREGON_SOUTH',
        display: '3602 - Oregon South',
    },
    PENNSYLVANIA_NORTH: {
        value: 'PENNSYLVANIA_NORTH',
        display: '3701 - Pennsylvania North',
    },
    PENNSYLVANIA_SOUTH: {
        value: 'PENNSYLVANIA_SOUTH',
        display: '3702 - Pennsylvania South',
    },
    RHODE_ISLAND: {
        value: 'RHODE_ISLAND',
        display: '3800 - Rhode Island',
    },
    SOUTH_CAROLINA: {
        value: 'SOUTH_CAROLINA',
        display: '3900 - South Carolina',
    },
    SOUTH_DAKOTA_NORTH: {
        value: 'SOUTH_DAKOTA_NORTH',
        display: '4001 - South Dakota North',
    },
    SOUTH_DAKOTA_SOUTH: {
        value: 'SOUTH_DAKOTA_SOUTH',
        display: '4002 - South Dakota South',
    },
    TENNESSEE: {
        value: 'TENNESSEE',
        display: '4100 - Tennessee',
    },
    TEXAS_NORTH: {
        value: 'TEXAS_NORTH',
        display: '4201 - Texas North',
    },
    TEXAS_NORTH_CENTRAL: {
        value: 'TEXAS_NORTH_CENTRAL',
        display: '4202 - Texas North Central',
    },
    TEXAS_CENTRAL: {
        value: 'TEXAS_CENTRAL',
        display: '4203 - Texas Central',
    },
    TEXAS_SOUTH_CENTRAL: {
        value: 'TEXAS_SOUTH_CENTRAL',
        display: '4204 - Texas South Central',
    },
    TEXAS_SOUTH: {
        value: 'TEXAS_SOUTH',
        display: '4205 - Texas South',
    },
    UTAH_NORTH: {
        value: 'UTAH_NORTH',
        display: '4301 - Utah North',
    },
    UTAH_CENTRAL: {
        value: 'UTAH_CENTRAL',
        display: '4302 - Utah Central',
    },
    UTAH_SOUTH: {
        value: 'UTAH_SOUTH',
        display: '4303 - Utah South',
    },
    VERMONT: {
        value: 'VERMONT',
        display: '4400 - Vermont',
    },
    VIRGINIA_NORTH: {
        value: 'VIRGINIA_NORTH',
        display: '4501 - Virginia North',
    },
    VIRGINIA_SOUTH: {
        value: 'VIRGINIA_SOUTH',
        display: '4502 - Virginia South',
    },
    WASHINGTON_NORTH: {
        value: 'WASHINGTON_NORTH',
        display: '4601 - Washington North',
    },
    WASHINGTON_SOUTH: {
        value: 'WASHINGTON_SOUTH',
        display: '4602 - Washington South',
    },
    WEST_VIRGINIA_NORTH: {
        value: 'WEST_VIRGINIA_NORTH',
        display: '4701 - West Virginia North',
    },
    WEST_VIRGINIA_SOUTH: {
        value: 'WEST_VIRGINIA_SOUTH',
        display: '4702 - West Virginia South',
    },
    WISCONSIN_NORTH: {
        value: 'WISCONSIN_NORTH',
        display: '4801 - Wisconsin North',
    },
    WISCONSIN_CENTRAL: {
        value: 'WISCONSIN_CENTRAL',
        display: '4802 - Wisconsin Central',
    },
    WISCONSIN_SOUTH: {
        value: 'WISCONSIN_SOUTH',
        display: '4803 - Wisconsin South',
    },
    WYOMING_EAST: {
        value: 'WYOMING_EAST',
        display: '4901 - Wyoming East',
    },
    WYOMING_EAST_CENTRAL: {
        value: 'WYOMING_EAST_CENTRAL',
        display: '4902 - Wyoming East Central',
    },
    WYOMING_WEST_CENTRAL: {
        value: 'WYOMING_WEST_CENTRAL',
        display: '4903 - Wyoming West Central',
    },
    WYOMING_WEST: {
        value: 'WYOMING_WEST',
        display: '4904 - Wyoming West',
    },
    ALASKA_ZONE_1: {
        value: 'ALASKA_ZONE_1',
        display: '5001 - Alaska Zone 1',
    },
    ALASKA_ZONE_2: {
        value: 'ALASKA_ZONE_2',
        display: '5002 - Alaska Zone 2',
    },
    ALASKA_ZONE_3: {
        value: 'ALASKA_ZONE_3',
        display: '5003 - Alaska Zone 3',
    },
    ALASKA_ZONE_4: {
        value: 'ALASKA_ZONE_4',
        display: '5004 - Alaska Zone 4',
    },
    ALASKA_ZONE_5: {
        value: 'ALASKA_ZONE_5',
        display: '5005 - Alaska Zone 5',
    },
    ALASKA_ZONE_6: {
        value: 'ALASKA_ZONE_6',
        display: '5006 - Alaska Zone 6',
    },
    ALASKA_ZONE_7: {
        value: 'ALASKA_ZONE_7',
        display: '5007 - Alaska Zone 7',
    },
    ALASKA_ZONE_8: {
        value: 'ALASKA_ZONE_8',
        display: '5008 - Alaska Zone 8',
    },
    ALASKA_ZONE_9: {
        value: 'ALASKA_ZONE_9',
        display: '5009 - Alaska Zone 9',
    },
    ALASKA_ZONE_10: {
        value: 'ALASKA_ZONE_10',
        display: '5010 - Alaska Zone 10',
    },
    HAWAII_ZONE_1: {
        value: 'HAWAII_ZONE_1',
        display: '5101 - Hawaii Zone 1',
    },
    HAWAII_ZONE_2: {
        value: 'HAWAII_ZONE_2',
        display: '5102 - Hawaii Zone 2',
    },
    HAWAII_ZONE_3: {
        value: 'HAWAII_ZONE_3',
        display: '5103 - Hawaii Zone 3',
    },
    HAWAII_ZONE_4: {
        value: 'HAWAII_ZONE_4',
        display: '5104 - Hawaii Zone 4',
    },
    HAWAII_ZONE_5: {
        value: 'HAWAII_ZONE_5',
        display: '5105 - Hawaii Zone 5',
    },
    PUERTO_RICO_AND_VIRGIN_ISLANDS: {
        value: 'PUERTO_RICO_AND_VIRGIN_ISLANDS',
        display: '5200 - Puerto Rico And Virgin Islands',
    },
};

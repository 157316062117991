import React from 'react';
import { HStack, Text } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { AddTaskButton } from './AddTaskButton';

const strings = componentStrings.eFiles.tasks;

export const EFileTasksHeader = () => {
    return (
        <HStack justify="space-between" align="start">
            <Text variant="headingMd">{strings.header.title}</Text>
            <AddTaskButton />
        </HStack>
    );
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _, { map, uniqBy } from 'lodash';
import { formatTitleForElasticVehicleSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import Icon, { iconTypes } from '../../Icon';

function VehiclesCell({ formatTitleForElasticVehicle, involvedVehicles, permissionSet = [] }) {
    if (involvedVehicles.length === 0) {
        const fallbackText = canRead(permissionSet) ? 'No Involved Vehicles' : undefined;
        return <div>{fallbackText}</div>;
    }

    const displayedVehicles = _(involvedVehicles)
        .groupBy('vehicle.id')
        .map((vehicleGroup) => {
            return {
                title: formatTitleForElasticVehicle(vehicleGroup[0]),
                sequenceNumber: vehicleGroup[0].sequenceNumber,
                propertyStatuses: _(vehicleGroup)
                    .map((vehicle) => getViewModelProperties(vehicle).propertyStatusAttrId)
                    .sortBy()
                    .join(', '),
            };
        })
        .sortBy('sequenceNumber')
        .value();

    // displayed vehicles are effectively unique by default because of the "groupBy"
    // we perform above
    const displayedVehiclesUniqueCount = displayedVehicles.length;
    const totalVehiclesUniqueCount = uniqBy(involvedVehicles, 'vehicle.id').length;
    const vehiclesNotDisplayedCount = totalVehiclesUniqueCount - displayedVehiclesUniqueCount;

    // This shows the number of unique persons not displayed in the cell
    // which is different from the number of person report links which are
    // not displayed in the cell
    const showMore =
        vehiclesNotDisplayedCount > 0 ? (
            <div className="elastic-row-show-more">
                {`+ ${vehiclesNotDisplayedCount} other vehicles`}
            </div>
        ) : undefined;

    return (
        <div className="elastic-report-property">
            {map(displayedVehicles, ({ title, sequenceNumber, propertyStatuses }) => (
                <div key={sequenceNumber}>
                    <div className="link-type">
                        <Icon type={iconTypes.VEHICLE} />
                        <span className="link-type-dash">-</span>
                    </div>
                    <div className="property">
                        <div>{title}</div>
                        <div className="property-statuses">{propertyStatuses}</div>
                    </div>
                </div>
            ))}
            {showMore}
        </div>
    );
}

export default connect(
    createStructuredSelector({
        formatTitleForElasticVehicle: formatTitleForElasticVehicleSelector,
    })
)(VehiclesCell);

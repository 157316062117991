import $ from 'jquery';
import { get, isFunction } from 'lodash';
import { getFocusableElements } from '~/client-common/core/keyboardFocus/helpers';
import { SCROLL_OFFSET } from '../configs/reportsConfig';

/**
 * A helper for report sidebars to scroll to selected cards which each have a
 *   `data-anchor` attribute. I really wish we weren't using jQuery to scroll,
 *   but there doesn't appear to be a great scrolling solution for React / React
 *   Router right now... We could rewrite this function natively if we're
 *   looking to remove jQuery dependency altogether in the future. -Josh
 * @param  {string} anchor
 */
export function scrollToDataAnchor(anchor, { wrapperSelector, onScroll } = {}) {
    scrollToElement({ selector: `[data-anchor='${anchor}']`, wrapperSelector, onScroll });
}

/**
 * Scroll to the given element.
 * @param  {object} options jQuery selector for the element to scroll to.
 * @param  {string|HTMLElement} options.selector jQuery selector for the element to scroll to, or
 *  the actual HTMLElement.
 * @param  {number} [options.scrollDuration=300]
 *  the element to scroll within
 * @param  {string|HTMLElement} [options.wrapperSelector='.mark43-content'] jQuery selector for the
 *   element to scroll within, or the actual HTMLElement. The default selector works for pages without a
 *   subheader.
 * @param  {number} [options.offset=150] Number of pixels to offset the scroll by.
 *   Usually, this will include the height of the page header and/or subheader.
 * @param  {function} [options.onScroll]
 */
export function scrollToElement({
    selector,
    scrollDuration = 300,
    wrapperSelector = '.mark43-content',
    offset = SCROLL_OFFSET,
    maxTimeoutMs = 100,
    retryInterval = 10,
    retryBackoff = 1.5,
    onScroll,
}) {
    let currentTimeoutMs = retryInterval;
    function checkAndScroll() {
        const wrapper = $(wrapperSelector);
        const element = $(selector);
        const offsetObject = element.offset();
        let didScroll = false;
        if (offsetObject) {
            const offsetTop = offsetObject.top;
            const onScrollComplete = () => {
                if (onScroll) {
                    onScroll();
                }
            };
            wrapper.animate(
                {
                    scrollTop: wrapper.scrollTop() + offsetTop - offset,
                },
                {
                    duration: scrollDuration,
                    complete: onScrollComplete,
                }
            );
            didScroll = true;
        }
        currentTimeoutMs = currentTimeoutMs * retryBackoff;
        if (!didScroll && currentTimeoutMs < maxTimeoutMs) {
            setTimeout(checkAndScroll, currentTimeoutMs);
        }
    }
    checkAndScroll();
}

export function scrollToTop() {
    $('.mark43-content').animate({
        scrollTop: 0,
    });
}

export function scrollToAnchorAtOffset({ startingAnchor, offset, onScroll, onScrollFail }) {
    const allElementsWithAnchors = [...document.querySelectorAll('[data-anchor]')];
    let filteredElementsWithAnchors = allElementsWithAnchors;
    // Filter out any anchors that are hidden
    filteredElementsWithAnchors = filteredElementsWithAnchors.filter((el) => {
        const computedStyle = window.getComputedStyle && window.getComputedStyle(el);
        const display = get(computedStyle, 'display');
        return display !== 'none';
    });
    const startingAnchorIndex = filteredElementsWithAnchors.findIndex(
        (el) => el.dataset.anchor === startingAnchor
    );
    const targetAnchorIndex = startingAnchorIndex + offset;
    const targetAnchor = get(filteredElementsWithAnchors, `[${targetAnchorIndex}].dataset.anchor`);
    if (targetAnchor) {
        const scrollHandler = isFunction(onScroll) ? () => onScroll(targetAnchor) : null;

        scrollToDataAnchor(targetAnchor, { onScroll: scrollHandler });
        // we should be able to delete the previous line
        // once the report page is refactored to react
        scrollToDataAnchor(targetAnchor, {
            wrapperSelector: '.mark43-scrollable-under-subheader',
            onScroll: scrollHandler,
        });
    } else {
        if (isFunction(onScrollFail)) {
            onScrollFail();
        }
    }
}

/**
 * @param  {object} options
 * @param  {HTMLElement} options.clickedElement The HTMLElement that was clicked
 * @param  {string} options.fieldName The `fieldName` of the element we want to scroll to
 * @param  {string} [options.index] For NItems, sometimes we may want to skip to the Nth field
 *  on the card
 * @param  {number} [options.offset=0] When scrolling to the field,
 *  we still want a little bit of space between the field and the error header.
 *  This number indicates how much spacing to add (in px)
 */
export function scrollToFieldWithinSidePanel({ clickedElement, fieldName, index = 0, offset = 0 }) {
    const parentSidePanel = clickedElement.closest('.mark43-react-side-panel');

    // Find the scrollable container
    const scrollableContainer = parentSidePanel.querySelector('.react-side-panel-content-wrapper');

    // From the side panel, find the field that we want to scroll to
    const field = parentSidePanel
        ? parentSidePanel.querySelectorAll(`[data-test-field-name="${fieldName}"]`).item(index)
        : undefined;

    // Alternatively, if we were not able to find a field corresponding to this field,
    // let's see if there are any 'sections' that correspond to the field
    const section =
        parentSidePanel && !field
            ? parentSidePanel
                  .querySelectorAll(`[data-test-field-name-section="${fieldName}"]`)
                  .item(0)
            : undefined;

    const parentOfElementToFocus = field || section;

    /**
     * @type {HTMLElement|undefined} Once we have either a field or a section, look
     *  for an underlying element that we will set focus to
     */
    const elementToFocus =
        parentOfElementToFocus && getFocusableElements(parentOfElementToFocus)[0];

    if (scrollableContainer && elementToFocus) {
        const scrollableContainerBoundingClientRect = scrollableContainer.getBoundingClientRect();

        scrollToElement({
            selector: parentOfElementToFocus,
            wrapperSelector: scrollableContainer,
            offset:
                // The offset of the scrollable container
                scrollableContainerBoundingClientRect.top +
                // Extra spacing to add some distance between
                // the fixed error header and the field
                offset,
            onScroll: () => {
                elementToFocus.focus();
            },
        });
    }
}

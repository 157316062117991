import _ from 'lodash';
import { RefContextEnum } from '@mark43/rms-api';
import { _Form, formEvents } from 'markformythree';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { RmsAction } from '../../../../core/typings/redux';
import createArbiterMFTValidationHandler from '../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import { ArbiterInstance } from '../../../core/markformythree-arbiter/ArbiterForm';
import { createRMSArbiterInstance } from '../../../core/arbiter';
import mftArbiterValidationEvents from '../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import { getFormConfiguration } from '../state/forms/arrest-charges-side-panel/nChargesForm';
import { buildChargesCardFormModel } from '../state/forms/chargesForm';

interface AdditionalFormToValidate {
    formName: string;
    reportId: number;
}

export const validateArrestChargesForm = ({
    additionalFormsToValidate,
}: {
    additionalFormsToValidate: AdditionalFormToValidate[];
}): RmsAction<string[]> => (dispatch, getState, dependencies) => {
    const chargesSidePanelContext = RefContextEnum.FORM_ARREST_CHARGES_N_CHARGES_SIDE_PANEL.name;
    const formToValidate = additionalFormsToValidate.find(
        (f) => f.formName === chargesSidePanelContext
    );
    if (!formToValidate) {
        return [];
    }
    const { formName, reportId } = formToValidate;
    const state = getState();
    const formatFieldByName = formatFieldByNameSelector(state);
    const chargesArbiterInstance: ArbiterInstance = createRMSArbiterInstance(
        getState,
        chargesSidePanelContext
    );
    const formsRegistry = dependencies.formsRegistry;
    const model = dispatch(buildChargesCardFormModel({ reportId }));
    formsRegistry.register(
        // @ts-expect-error Property 'id' is incompatible with index signature.
        // Type 'number' is not assignable to type 'MFTFieldConfigurationBase | InferFieldsetOrNitems<MFTFieldsetConfiguration> | InferFieldsetOrNitems<MFTNItemsConfiguration>[] | undefined'.ts(2345)
        new _Form({
            name: formName,
            configuration: getFormConfiguration(),
            initialState: model,
            onValidate: createArbiterMFTValidationHandler(
                chargesArbiterInstance,
                chargesSidePanelContext,
                formatFieldByName
            ),
            validationEvents: mftArbiterValidationEvents,
        })
    );

    const form = formsRegistry.get(formName);
    if (!form) {
        return [];
    }
    const validationObject = form.validate({ eventType: formEvents.FORM_SUBMIT, path: '' });
    formsRegistry.unregister(formName);
    if (!validationObject || validationObject instanceof Promise || validationObject?.success) {
        return [];
    }
    return _(validationObject.formErrors).compact().uniq().value();
};

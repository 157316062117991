import React, { useEffect } from 'react';
import { filter, find, includes, map, omit, pick } from 'lodash';
import { EmailTypeEnum, PhoneTypeEnum } from '@mark43/rms-api';

import { useSelector } from 'react-redux';
import {
    convertPhoneToFormModel,
    convertPhoneFromFormModel,
} from '~/client-common/helpers/phoneNumberHelpers';
import { filterFormData } from '~/client-common/helpers/formHelpers';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { ArbiterMFTDatePicker } from '../../forms/components/DatePicker';
import { nameEmailFields, namePhoneFields } from './nameFieldHelpers';

export const addEntityTypeToCollection = (collection, entityType) =>
    map(collection, (item) => ({ ...item, entityType }));

export const mergeEntitiesWithExistingData = (collection, existingData) =>
    filterFormData(
        map(collection, (item) =>
            item.id ? { ...find(existingData, { id: item.id }), ...item } : item
        )
    );

export const isOtherPhoneType = (phoneTypeName) => {
    const phoneTypeDisplays = map(
        filter(PhoneTypeEnum, (phoneTypeEnum) => phoneTypeEnum !== PhoneTypeEnum.OTHER_PHONE),
        'value'
    );
    return !!phoneTypeName && !includes(phoneTypeDisplays, phoneTypeName);
};

export const isOtherEmailType = (emailTypeName) => {
    const emailTypeDisplays = map(
        filter(EmailTypeEnum, (emailTypeEnum) => emailTypeEnum !== EmailTypeEnum.OTHER_EMAIL),
        'value'
    );
    return !!emailTypeName && !includes(emailTypeDisplays, emailTypeName);
};

export const convertPhonesToFormModel = (phoneNumbers) => {
    return map(phoneNumbers, (phoneNumber) => {
        const result = pick(convertPhoneToFormModel(phoneNumber), namePhoneFields);
        if (isOtherPhoneType(result.phoneType)) {
            result.phoneTypeOther = result.phoneType;
            result.phoneType = PhoneTypeEnum.OTHER_PHONE.value;
        }
        return result;
    });
};

export const convertEmailsToFormModel = (emails) => {
    return map(emails, (email) => {
        const result = pick(email, nameEmailFields);
        if (isOtherEmailType(result.emailType)) {
            result.emailTypeOther = result.emailType;
            result.emailType = EmailTypeEnum.OTHER_EMAIL.value;
        }
        return result;
    });
};

export const convertPhonesFromFormModel = (phoneNumbers) => {
    return map(phoneNumbers, (phoneNumber) => {
        if (isOtherPhoneType(phoneNumber.phoneType)) {
            phoneNumber.phoneType = phoneNumber.phoneTypeOther;
        }
        return convertPhoneFromFormModel(phoneNumber);
    });
};

export const convertEmailsFromFormModel = (emails) => {
    return map(emails, (email) => {
        if (isOtherEmailType(email.emailType)) {
            email.emailType = email.emailTypeOther;
        }
        return email;
    });
};

/**
 * Adds existing data back to links and ensures
 * that each link has an entity type set on it
 */
export const processLinkData = (collection, existingData, entityType) =>
    addEntityTypeToCollection(mergeEntitiesWithExistingData(collection, existingData), entityType);

/**
 * Convert the form data from our location to a `locationBundle` to send
 * to the hydrated person endpoint.
 *
 * Note that this puts a `locationView` on the `locationBundle` instead
 * of a plain `location` but this is ok, because all the data is
 * contained
 *
 * @param {Object}   formLocationEntityLink
 * @param {Object[]} stateData.locations
 * @param {Object[]} stateData.locationEntityLinks
 * @param {number}   [stateData.unknownLocationId]
 *      This is optional because organizations
 *      cannot have unknown locations, so this
 *      doesn't need to be specified for orgs
 * @param {number}   [additionalData.entityId]
 * @param {number}   additionalData.entityType
 * @param {number}   additionalData.linkType
 * @param {boolean}   [returnUnknownLocationId]
 *      This is only needed when constructing the `locationBundle`
 *      while sending it through to the `LocationSummary` component
 * @return {Object}
 */
export function convertFormLocationEntityLinkToLocationBundle({
    formLocationEntityLink,
    stateData,
    additionalData,
    returnUnknownLocationId,
}) {
    const { locations, locationEntityLinks, unknownLocationId } = stateData;
    const { entityId, entityType, linkType } = additionalData;

    const locationId = formLocationEntityLink.locationId;
    // We need to spread basic information
    // because our endpoints expect it
    const formLocationEntityLinkWithOverrides = {
        ...formLocationEntityLink,
        locationId,
        entityId,
        entityType,
        linkType,
    };
    return {
        location: omit(
            locations[locationId] ||
                // If we could not find the supplied id in `dataNexus`, but it
                // is a `fixed` address, then stub it
                (locationId === unknownLocationId ? { id: unknownLocationId } : {}),
            'entityLinks'
        ),
        // Try to grab the `entityLink` from store,
        // and merge the supplied link into it
        //
        // If we didn't supply enough params
        // to grab the `entitLink`, then
        // just use the supplied `entityLink`
        entityLinks: [
            locationId && entityId && entityType && linkType
                ? {
                      ...find(locationEntityLinks, {
                          locationId,
                          entityId,
                          entityType,
                          linkType,
                      }),
                      ...formLocationEntityLinkWithOverrides,
                  }
                : { ...formLocationEntityLinkWithOverrides },
        ],
        ...(returnUnknownLocationId ? { unknownLocationId } : {}),
    };
}

export function convertFormLocationEntityLinksToLocationBundles({
    formLocationEntityLinks,
    stateData,
    additionalData,
    returnUnknownLocationId,
}) {
    return map(formLocationEntityLinks, (formLocationEntityLink) =>
        convertFormLocationEntityLinkToLocationBundle({
            formLocationEntityLink,
            stateData,
            additionalData,
            returnUnknownLocationId,
        })
    );
}

// If we are creating an `entityLink` for a not yet created person/org
// (e.g. no `entityId`), or saving an 'other' location then we need to store the entire link
// into form state
//
// Otherwise, just store the locationId into form state
export const addLocationToFormFactory = (form, path, saveLink = false) => (
    location,
    entityLink,
    prefilledFields = {}
) => {
    const formLocationModel = {
        ...(entityLink.entityId && !saveLink ? pick(entityLink, 'locationId') : entityLink),
        ...prefilledFields,
    };

    form.push(path, formLocationModel);
};

// Sets the dateEffectiveFrom of the passed down NItemPath to current date only for new users
export const DefaultDateEffectiveFromToCurrent = ({
    entityId,
    form,
    NItemPath,
    dateNonPrefillConditions,
}) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isPoleDisabled = !applicationSettings['RMS_POLE_DATA_CAPTURE_ENABLED'];

    useEffect(() => {
        if (
            entityId ||
            dateNonPrefillConditions?.some((isConditionTrue) => isConditionTrue) ||
            isPoleDisabled
        ) {
            return;
        }

        const currentDate = nowUtc();

        form.transaction(() => {
            form.set(`${NItemPath}.dateEffectiveFrom`, currentDate);
            form.do({ [`${NItemPath}.dateEffectiveFrom`]: { dirty: true } });
        });
    }, [form, entityId, NItemPath, dateNonPrefillConditions, isPoleDisabled]);

    return (
        <ArbiterMFTDatePicker
            path="dateEffectiveFrom"
            variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
        />
    );
};

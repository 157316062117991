import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { simpleControl } from 'markformythree';

import { associatedDepartmentsOptionsSelector } from '../../../account-switcher/state/ui';
import Select from './Select';

// Associated departments as options for the select in the account switcher modal.
const mapStateToProps = createStructuredSelector({
    options: associatedDepartmentsOptionsSelector,
});

/**
 * Select for selecting departments associated with the logged in account.
 * @param {Object} props Same props as `Select`.
 */
const AssociatedDepartmentsSelect = compose(
    setDisplayName('AssociatedDepartmentsSelect'),
    connect(mapStateToProps)
)(Select);

export const MFTAssociatedDepartmentsSelect = simpleControl(AssociatedDepartmentsSelect);

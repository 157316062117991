import keyMirror from 'keymirror';

/**
 * Client-only enum for range form field types.
 * @see  https://readme.mark43.io/guides/products/rms/rms-frontend/rmsfe-form-modules/#form-field-view-models
 */
export default keyMirror({
    RANGE_START: null,
    RANGE_END: null,
    WITHIN_LAST_PERIOD: null,
    TO_DATE_PERIOD: null,
});

import {
    SearchResultElasticPerson,
    SearchResultElasticOrganization,
    SearchResultElasticVehicle,
    SearchQuery,
    RefContextEnumType,
} from '@mark43/rms-api';
import { InferFormDataShape, MFTFormConfiguration } from 'markformythree';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { searchToAddSearch } from '../data';

import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../core/state/ui';
import { convertFromFormModel as personSearchConvertFromFormModel } from '../forms/personSearchToAddForm';
import {
    convertFromFormModel as organizationSearchConvertFromFormModel,
    ORGANIZATION_SEARCH_TO_ADD_FORM,
} from '../forms/organizationSearchToAddForm';
import { MFTFormsRegistry } from '../../../../../core/formsRegistry';
import { RmsDispatch, RmsAction } from '../../../../../core/typings/redux';
import { SearchResultTyped } from '../../../../../core/typings/search-module';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import {
    convertFromFormModel as vehicleSearchConvertFromFormModel,
    VEHICLE_SEARCH_TO_ADD_FORM,
} from '../forms/vehicleSearchToAddForm';

const searchToAddSize = 20;

type SearchToAddParams<TResult extends SearchResultTyped> = {
    from?: number;
    onSuccess?: (results: TResult) => void;
    onError?: (error: Error) => void;
};

export type SearchToAddHandler<TResult extends SearchResultTyped> = (
    props: SearchToAddParams<TResult>
) => void;

type FormContextName = RefContextEnumType | keyof typeof formClientEnum;

const searchToAddSearchFactory = <
    TFormName extends string = FormContextName,
    TElasticQuery extends SearchQuery['elasticQuery'] = SearchQuery['elasticQuery']
>({
    formName,
    formType,
    convertFromFormModel,
}: {
    formName: TFormName;
    formType: string;
    convertFromFormModel: <TFormConfig extends MFTFormConfiguration = MFTFormConfiguration>(
        formModel: InferFormDataShape<TFormConfig>
    ) => Partial<TElasticQuery>;
}) => <TResult extends SearchResultTyped>({
    from = 0,
    onSuccess,
    onError,
}: SearchToAddParams<TResult>): RmsAction<Promise<void>> => async (
    dispatch: RmsDispatch,
    getState: () => RootState,
    { formsRegistry }: { formsRegistry: MFTFormsRegistry }
) => {
    formsRegistry
        .get(formName)
        ?.submit()
        .then(({ form }) => {
            const model = form.getState().model;
            return dispatch(
                searchToAddSearch({
                    from,
                    size: searchToAddSize,
                    elasticQuery: transformElasticQueryIdsToDisplayValuesSelector(getState())(
                        convertFromFormModel(model)
                    ),
                    type: formType,
                })
            ).then(onSuccess);
        })
        .catch(onError);
};

export function unregisterForm(formName: string): RmsAction<void> {
    return (
        dispatch: RmsDispatch,
        getState: () => RootState,
        { formsRegistry }: { formsRegistry: MFTFormsRegistry }
    ) => formsRegistry.unregister(formName);
}

export const personSearchToAddSearch = ({
    from = 0,
    onSuccess,
    onError,
}: SearchToAddParams<SearchResultElasticPerson>) =>
    searchToAddSearchFactory({
        formName: formClientEnum.PERSON_SEARCH_TO_ADD_FORM,
        formType: 'person',
        convertFromFormModel: personSearchConvertFromFormModel,
    })({
        from,
        onSuccess,
        onError,
    });

export const organizationSearchToAddSearch = ({
    from = 0,
    onSuccess,
    onError,
}: SearchToAddParams<SearchResultElasticOrganization>) =>
    searchToAddSearchFactory({
        formName: ORGANIZATION_SEARCH_TO_ADD_FORM,
        formType: 'organization',
        convertFromFormModel: organizationSearchConvertFromFormModel,
    })({
        from,
        onSuccess,
        onError,
    });

export const vehicleSearchToAddSearch = ({
    from = 0,
    onSuccess,
    onError,
}: SearchToAddParams<SearchResultElasticVehicle>) =>
    searchToAddSearchFactory({
        formName: VEHICLE_SEARCH_TO_ADD_FORM,
        formType: 'vehicle',
        convertFromFormModel: vehicleSearchConvertFromFormModel,
    })({
        from,
        onSuccess,
        onError,
    });

import approvalStatusClientEnum from '../../../../enums/client/approvalStatusClientEnum';

// createdBy is a display string for the user
export function formatStatusHistoryWithFields(reportStatusHistory, createdBy, fieldDisplayNames) {
    const { clientApprovalStatus } = reportStatusHistory;

    let status = '';

    const {
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
    } = fieldDisplayNames;

    switch (clientApprovalStatus) {
        case approvalStatusClientEnum.DRAFT:
            status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT;
            break;
        case approvalStatusClientEnum.REJECTED:
            status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED;
            break;
        case approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW:
            status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW;
            break;
        case approvalStatusClientEnum.PENDING_SECONDARY_REVIEW:
            status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW;
            break;
        case approvalStatusClientEnum.COMPLETED:
            status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED;
            break;
        default:
            status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT;
    }

    return `${createdBy} changed the report status to ${status} `;
}

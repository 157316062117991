import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { get } from 'lodash';
import React from 'react';
import { createStructuredSelector } from 'reselect';

import { connect } from 'react-redux';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';

import fieldsetViewModel from '../state/forms/unassignedReportsSearchPersonnelFieldset';

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    fieldConfigurationContextByContextAndFieldName: fieldConfigurationContextByContextAndFieldNameSelector,
});

/**
 * Fieldset for personnel (involved users) in the Unassigned Reports search form.
 */
export default connect(mapStateToProps)(function UnassignedReportsSearchPersonnelFieldset({
    formatFieldByName,
    fieldConfigurationContextByContextAndFieldName,
}) {
    const personnelUnitEventCardFieldConfigContext = fieldConfigurationContextByContextAndFieldName(
        RefContextEnum.FORM_EVENT_INFO.name,
        EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID
    );
    const isPersonnelUnitStaticallyHidden = !!get(
        personnelUnitEventCardFieldConfigContext,
        'isStaticallyHidden'
    );
    return (
        <RRFFieldset path="personnel" title={fieldsetViewModel.title} highlightOnFocus={false}>
            <Row>
                <RRFUserSelect multiple={true} width={260} path="authorIds" effectiveDate={null} />
                <RRFUserSelect
                    multiple={true}
                    width={260}
                    path="involvedOfficerIds"
                    effectiveDate={null}
                />
                {!isPersonnelUnitStaticallyHidden && (
                    <RRFAttributeSelect
                        multiple={true}
                        label={formatFieldByName(EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID)}
                        attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                        includeExpired={true}
                        width={260}
                        path="personnelUnitAttrIds"
                    />
                )}
            </Row>
        </RRFFieldset>
    );
});

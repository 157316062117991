import { get } from 'lodash';
import React from 'react';

import styled from 'styled-components';
import { Fieldset as MFTFieldset } from 'markformythree';

import { LocationSourceEnum } from '@mark43/rms-api';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_LOCATION_SIDE_PANEL_INTERSECTION_LABEL } from '~/client-common/core/enums/universal/fields';
import { responsiveStack } from '../../styles/mixins';
import { ArbiterMFTText as ArbiterMFTText } from '../../forms/components/Text';
import { ArbiterMFTCountrySelect as ArbiterMFTCountrySelect } from '../../forms/components/selects/CountrySelect';
import { SecondarySectionHeader } from '../../components/typography';
import FormRow from '../../forms/components/FormRow';
import FormGroup from '../../forms/components/FormGroup';
import ToggleButton from '../../../../legacy-redux/components/core/ToggleButton';
import LocationSummary from '../../../records/core/components/summaries/LocationSummary';
import testIds from '../../../../core/testIds';
import { MFTNItems, RemoveButton, AddButton } from '../../forms/components/NItems';

const strings = componentStrings.core.LocationSidePanel;

const AddressButton = styled(ToggleButton)`
    margin: 0;
    border-radius: 4px 0 0 4px;
`;

const IntersectionButton = styled(ToggleButton)`
    margin: 0;
    border-radius: 0 4px 4px 0;
`;

const ButtonWrapper = styled.div`
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: center;
`;

const AptFieldset = styled(MFTFieldset)`
    max-width: 50%;
    flex: 1;
`;

const FlexRow = styled.div`
    display: flex;
    width: 100%;
    ${responsiveStack('sm')};
    align-items: center;
`;

function LocationInfoSection({
    isEditMode,
    location,
    isLocationEntityLink,
    switchToEditMode,
    isAddressMode,
    switchToAddressMode,
    switchToIntersectionMode,
    form,
}) {
    const intersectionLabel = useFields(DISPLAY_ONLY_LOCATION_SIDE_PANEL_INTERSECTION_LABEL)[
        DISPLAY_ONLY_LOCATION_SIDE_PANEL_INTERSECTION_LABEL
    ];

    const renderAddressMode = () => {
        return (
            <div key="address">
                {isLocationEntityLink ? (
                    <FlexRow>
                        <ArbiterMFTText
                            path="streetAddress"
                            autoFocus={!isLocationEntityLink}
                            length="lg"
                        />
                        <FeatureFlagged
                            flag="SUBPREMISE_SUPPORT_ENABLED"
                            fallback={
                                <AptFieldset path="entityLink">
                                    <ArbiterMFTText path="subPremise" />
                                </AptFieldset>
                            }
                        />
                    </FlexRow>
                ) : (
                    <ArbiterMFTText path="streetAddress" autoFocus={!isLocationEntityLink} />
                )}
                <FeatureFlagged flag="SUBPREMISE_SUPPORT_ENABLED">
                    <MFTNItems
                        path="subPremises"
                        childFieldKeys={['subPremiseName', 'subPremiseValue']}
                        addItemOnEmpty={true}
                        render={({ removeItem, items }) => (
                            <FlexRow>
                                <ArbiterMFTText path="subPremiseName" length="md" />
                                <ArbiterMFTText path="subPremiseValue" length="md" />
                                {items.length > 1 && <RemoveButton onClick={removeItem} />}
                            </FlexRow>
                        )}
                        renderRemoveButton={undefined}
                        renderAddButton={({ addItem, items }) => (
                            <AddButton onClick={addItem} isDisabled={items.length >= 5} />
                        )}
                        formName={form.name}
                    />
                </FeatureFlagged>
                <FormRow>
                    <ArbiterMFTText path="neighborhood" length="md" />
                    <ArbiterMFTText path="locality" length="md" />
                </FormRow>
                <FormRow>
                    <ArbiterMFTText path="adminArea1" length="md" />
                    <ArbiterMFTText path="adminArea2" length="md" />
                    <ArbiterMFTText path="postalCode" length="md" />
                </FormRow>
                <ArbiterMFTCountrySelect path="country" length="lg" />
                <FormRow>
                    <ArbiterMFTText path="crossStreet1" length="md" />
                    <ArbiterMFTText path="crossStreet2" length="md" />
                </FormRow>
            </div>
        );
    };

    const renderIntersectionMode = () => {
        return (
            <FormGroup key="intersection">
                <FormRow>
                    <ArbiterMFTText
                        path="crossStreet1"
                        autoFocus={!isLocationEntityLink}
                        length="md"
                    />
                    <ArbiterMFTText path="crossStreet2" length="md" />
                </FormRow>
                <ArbiterMFTText path="locality" length="lg" />
                <FormRow>
                    <ArbiterMFTText path="adminArea1" length="md" />
                    <ArbiterMFTText path="postalCode" length="md" />
                </FormRow>
                <ArbiterMFTCountrySelect path="country" />
            </FormGroup>
        );
    };

    const renderEditMode = () => {
        return (
            <div>
                <SecondarySectionHeader>{strings.locationInfo}</SecondarySectionHeader>
                <ButtonWrapper>
                    <AddressButton
                        active={isAddressMode}
                        onClick={switchToAddressMode}
                        testId={testIds.LOCATION_SIDE_PANEL_TYPE_ADDRESS}
                    >
                        {strings.address}
                    </AddressButton>
                    <IntersectionButton
                        active={!isAddressMode}
                        onClick={switchToIntersectionMode}
                        testId={testIds.LOCATION_SIDE_PANEL_TYPE_INTERSECTION}
                    >
                        {intersectionLabel}
                    </IntersectionButton>
                </ButtonWrapper>
                {isLocationEntityLink && (
                    <MFTFieldset path="entityLink">
                        <FormRow>
                            <ArbiterMFTText path="placeName" length="lg" autoFocus={true} />
                        </FormRow>
                    </MFTFieldset>
                )}
                {isAddressMode ? renderAddressMode() : renderIntersectionMode()}
                <FormRow>
                    <ArbiterMFTText path="latitude" length="md" />
                    <ArbiterMFTText path="longitude" length="md" />
                </FormRow>
            </div>
        );
    };

    const renderSummaryMode = () => {
        // only include placeName b/c all other locationEntityLink fields are editable in the form
        const locationEntityLink = {
            placeName: get(location, 'entityLinks[0].placeName'),
        };
        const locationBundle = location
            ? {
                  location,
                  locationEntityLink,
              }
            : null;
        const isDcMarLocation = location.source === LocationSourceEnum.DC_MAR.name;
        return (
            <div>
                <FeatureFlagged
                    flag="RMS_WASHINGTON_DC_RESTRICT_OFFENSEINCIDENT_ADDRESS_ENABLED"
                    fallback={
                        <SecondarySectionHeader onEditClick={switchToEditMode}>
                            {strings.locationInfo}
                        </SecondarySectionHeader>
                    }
                >
                    {isDcMarLocation ? (
                        <SecondarySectionHeader>{strings.locationInfo}</SecondarySectionHeader>
                    ) : (
                        <SecondarySectionHeader onEditClick={switchToEditMode}>
                            {strings.locationInfo}
                        </SecondarySectionHeader>
                    )}
                </FeatureFlagged>

                <LocationSummary locationBundle={locationBundle} showMap={true} />
                {isLocationEntityLink && (
                    <FeatureFlagged
                        flag="SUBPREMISE_SUPPORT_ENABLED"
                        fallback={
                            <AptFieldset path="entityLink">
                                <ArbiterMFTText path="subPremise" />
                            </AptFieldset>
                        }
                    />
                )}
            </div>
        );
    };

    return <div>{!location || isEditMode ? renderEditMode() : renderSummaryMode()}</div>;
}

export default LocationInfoSection;

import {
    formatTitleForFirearm,
    formatTitleForDefaultItem,
} from '~/client-common/core/domain/item-profiles/utils/itemProfileHelpers';
import { formatTitleForVehicle as _formatTitleForVehicle } from '~/client-common/core/domain/vehicles/utils/vehicleHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

// List of the view model props that need to be formatted for property record title
export const propertyRecordViewModelProps = [
    'vehicleMakeAttrId',
    'vehicleModelAttrId',
    'itemCategoryAttrId',
    'firearmMakeAttrId',
    'atfManufacturerId',
];

// the following two methods have matching methods on the backend, currently found at
// mark43/cobalt-rms/reporting/reporting-services/src/main/java/mark43/reporting/services/ucr/UcrPrefillService.java.
// the server uses these to fill the ucr property's additional notes.
// if you're updating these client versions, please update the backend versions as well.
// ** NOTE ** this function will be deprecated in favour of `formatTitleForVehicle` from `client-common/core/domain/vehicles/utils/vehicleHelpers`
function formatTitleForVehicle({
    vehicle = {},
    vehicleMakeIsOther = false,
    vehicleModelIsOther = false,
}) {
    const { description, vehicleMakeOther, vehicleModelOther, yearOfManufacture } = vehicle;

    const {
        itemCategoryAttrId: itemCategory,
        vehicleMakeAttrId: vehicleMakeAttr,
        vehicleModelAttrId: vehicleModelAttr,
    } = getViewModelProperties(vehicle);

    return _formatTitleForVehicle({
        description,
        itemCategory,
        vehicleMakeAttr,
        vehicleMakeIsOther,
        vehicleMakeOther,
        vehicleModelAttr,
        vehicleModelIsOther,
        vehicleModelOther,
        yearOfManufacture,
    });
}

// This method assumes it is being given an item which has been populated
// with its viewmodel properties
// Utilizes helper functions from '~/client-common/core/domain/item-profiles/utils/itemProfileHelpers'
// When dealing with raw item profile models consider using PropertyTitle react component.
export function formatTitleForPropertyRecord(
    property = {},
    useRaw = false,
    vehicleMakeIsOther = false,
    vehicleModelIsOther = false
) {
    const {
        description,
        firearmMakeAttrId: firearmMake,
        itemCategoryAttrId: itemCategory,
        atfManufacturer,
    } = useRaw
        ? property
        : {
              ...property,
              ...getViewModelProperties(property),
          };

    switch (property.itemTypeAttrId) {
        case globalAttributes.itemType.vehicle:
        case globalAttributes.itemType.bus:
            return formatTitleForVehicle({
                vehicle: property,
                vehicleMakeIsOther,
                vehicleModelIsOther,
            });
        case globalAttributes.itemType.firearm:
            return formatTitleForFirearm({
                description,
                itemCategory,
                firearmMake,
                atfManufacturer,
            });
        default:
            return formatTitleForDefaultItem({ description, itemCategory });
    }
}

import React from 'react';
import { reduce, noop } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName, withHandlers } from 'recompose';
import withFields from '~/client-common/core/fields/components/withFields';
import {
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
} from '~/client-common/core/enums/universal/fields';

import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data/';
import {
    convertPrimaryApprovalStatusClientEnumValuesToFieldValues,
    clientApprovalStatusToFieldNames,
} from '~/client-common/helpers/approvalStatusHelpers';
import { getDropdownOptionsFromObject } from '~/client-common/helpers/dropdownOptionHelpers';

import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const { labels: defaultLabels } = componentStrings.forms.select.ApprovalStatusSelect;

const {
    DRAFT,
    REJECTED,
    PENDING_SUPERVISOR_REVIEW,
    PENDING_SECONDARY_REVIEW,
    COMPLETED,
} = approvalStatusClientEnum;

function getApprovalStatusOptions(fieldDisplayNames) {
    const approvalStatuses = [
        DRAFT,
        REJECTED,
        PENDING_SUPERVISOR_REVIEW,
        PENDING_SECONDARY_REVIEW,
        COMPLETED,
    ];

    const dropdownOptions = reduce(
        approvalStatuses,
        (acc, approvalStatus) => {
            acc[approvalStatus] =
                fieldDisplayNames[clientApprovalStatusToFieldNames[approvalStatus]];
            return acc;
        },
        {}
    );

    return getDropdownOptionsFromObject(dropdownOptions);
}

const ApprovalStatusSelect = compose(
    connect(
        createStructuredSelector({
            applicationSettings: applicationSettingsSelector,
        })
    ),
    withHandlers({
        onSelectChange({ onChange = noop }) {
            return (optionValues) => {
                const fieldValues = convertPrimaryApprovalStatusClientEnumValuesToFieldValues(
                    optionValues
                );

                // call custom handler
                onChange(fieldValues);
            };
        },
    })
)(function ApprovalStatusSelect({
    onSelectChange,
    value,
    fieldDisplayNames,
    label,
    ...otherProps
}) {
    const options = getApprovalStatusOptions(fieldDisplayNames);

    return (
        <Select
            label={label || defaultLabels.approvalStatus}
            options={options}
            value={value}
            multiple={true}
            {...otherProps}
            onChange={onSelectChange}
        />
    );
});

export const RRFApprovalStatusSelect = compose(
    setDisplayName('RRFApprovalStatusSelect'),
    connectRRFInput,
    withFields([
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
    ])
)(ApprovalStatusSelect);

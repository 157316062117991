import { RefContextEnum } from '@mark43/rms-api';
import _, { parseInt } from 'lodash';
import { createSelector } from 'reselect';
import {
    externalLinksSelector,
    externalLinksByIdSelector,
    LOAD_EXTERNAL_LINKS_START,
    LOAD_EXTERNAL_LINKS_SUCCESS,
    LOAD_EXTERNAL_LINKS_FAILURE,
    SAVE_EXTERNAL_LINK_START,
    SAVE_EXTERNAL_LINK_SUCCESS,
    SAVE_EXTERNAL_LINK_FAILURE,
    REMOVE_EXTERNAL_LINK_START,
    REMOVE_EXTERNAL_LINK_SUCCESS,
    REMOVE_EXTERNAL_LINK_FAILURE,
} from '~/client-common/core/domain/external-links/state/data';
import { convertToFormModel } from '../forms/externalLinkForm';

const SELECT_EXTERNAL_LINK_START = 'external-links/SELECT_EXTERNAL_LINK_START';
const SELECT_EXTERNAL_LINK_SUCCESS = 'external-links/SELECT_EXTERNAL_LINK_SUCCESS';
const SELECT_EXTERNAL_LINK_FAILURE = 'external-links/SELECT_EXTERNAL_LINK_FAILURE';

function selectExternalLinkStart() {
    return { type: SELECT_EXTERNAL_LINK_START };
}

function selectExternalLinkSuccess(id) {
    return { type: SELECT_EXTERNAL_LINK_SUCCESS, payload: id };
}

function selectExternalLinkFailure(errMsg) {
    return { type: SELECT_EXTERNAL_LINK_FAILURE, error: true, payload: errMsg };
}

export const uiSelector = (state) => state.ui.externalLinksAdmin;

export const selectedExternalLinkSelector = createSelector(
    uiSelector,
    externalLinksByIdSelector,
    (ui, externalLinksById) => externalLinksById(ui.selectedExternalLinkId)
);

export const externalLinksListItemsSelector = createSelector(
    uiSelector,
    externalLinksSelector,
    (ui, externalLinks) => {
        return _(externalLinks)
            .filter((externalLink) => externalLink.id > -1)
            .map((externalLink) => {
                return {
                    path: `/admin/external-links/${externalLink.id}`,
                    title: externalLink.name,
                    key: externalLink.id,
                    selected: externalLink.id === ui.selectedExternalLinkId,
                };
            })
            .sortBy('title')
            .value();
    }
);

export function selectExternalLink(externalLinkId) {
    return (dispatch, getState, { formsRegistry }) => {
        dispatch(selectExternalLinkStart());
        const id = parseInt(externalLinkId);
        const selectedExternalLink = externalLinksByIdSelector(getState())(id);
        if (!selectedExternalLink) {
            return dispatch(selectExternalLinkFailure('Failed to get external link.'));
        }
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_EXTERNAL_LINK.name,
            undefined,
            (form) => {
                form.set('', convertToFormModel(selectedExternalLink));
                form.resetUi();
            }
        );
        dispatch(selectExternalLinkSuccess(selectedExternalLink.id));
    };
}

const initialUiState = {
    errorMessage: '',
    submittingForm: false,
    selectedExternalLinkId: undefined,
};

function externalLinksAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SELECT_EXTERNAL_LINK_START:
            return {
                ...state,
                submittingForm: false,
                selectedExternalLinkId: null,
                errorMessage: '',
            };
        case SELECT_EXTERNAL_LINK_SUCCESS:
            return {
                ...state,
                submittingForm: null,
                selectedExternalLinkId: parseInt(action.payload),
                errorMessage: '',
            };
        case SELECT_EXTERNAL_LINK_FAILURE:
        case LOAD_EXTERNAL_LINKS_START:
            return {
                ...state,
                errorMessage: null,
                loading: true,
            };
        case LOAD_EXTERNAL_LINKS_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
            };
        case LOAD_EXTERNAL_LINKS_FAILURE:
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case REMOVE_EXTERNAL_LINK_START:
            return {
                ...state,
                submittingForm: true,
            };
        case REMOVE_EXTERNAL_LINK_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case REMOVE_EXTERNAL_LINK_FAILURE:
            return {
                ...state,
                submittingForm: false,
                errorMessage: action.payload,
            };
        case SAVE_EXTERNAL_LINK_START:
        case SAVE_EXTERNAL_LINK_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                errorMessage: '',
            };
        case SAVE_EXTERNAL_LINK_FAILURE:
            return {
                ...state,
                submittingForm: false,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
}

export default externalLinksAdminUiReducer;

import React from 'react';
import { compose, mapProps } from 'recompose';
import { compact, map } from 'lodash';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

const SupervisorsCell = function ({ elasticCase }) {
    const { supervisors } = getViewModelProperties(elasticCase);
    const supervisorsString = compact(
        map(supervisors, (s) => s.fullNameWithFirstInitial || s.default || '')
    ).join(', ');
    return <>{supervisorsString}</>;
};

export default compose(mapProps((ownerProps) => ({ elasticCase: ownerProps })))(SupervisorsCell);

import filter from 'lodash/filter';
import map from 'lodash/map';
import { CaseAttribute, ElasticAttributeDetail } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';

export const NEXUS_STATE_PROP = 'caseAttributes';
const caseAttributesModule = createNormalizedModule<CaseAttribute>({
    type: NEXUS_STATE_PROP,
    key: 'attributeId',
});

// SELECTORS
const caseAttributesSelector = caseAttributesModule.selectors.entitiesSelector;
export const caseAttributesByCaseIdSelector = createSelector(
    caseAttributesSelector,
    (caseAttributes) => (caseId: number) => filter(caseAttributes, { caseId })
);

export const caseElasticAttributesByCaseIdSelector = createSelector(
    [caseAttributesByCaseIdSelector, formatAttributeByIdSelector],
    (attributesByCaseId, formatAttribute) => (caseId: number)=> map(attributesByCaseId(caseId), (attr): ElasticAttributeDetail => ({
        displayValue: formatAttribute(attr.attributeId),
        id: attr.attributeId,
        type: attr.attributeType,
    }))
);

// REDUCER
export default caseAttributesModule.reducerConfig;

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { replaceStopCardBundle } from '~/client-common/core/domain/reports/state/ui';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import { buildStopCardBundle, refreshStopForm } from '../forms/stopForm';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.STOP.name,
    baseSelector: (state) => state.ui.report.stopCard,
    anchor: 'stop-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);
                const report = currentReportSelector(state);
                const reportId = report.id;

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const stopCardBundle = dispatch(
                                    buildStopCardBundle({ reportId, form })
                                );

                                return [
                                    dispatch(
                                        replaceStopCardBundle({
                                            reportId,
                                            stopCardBundle,
                                        })
                                    ).then((stopCardBundle) => {
                                        form.set('stop.id', stopCardBundle.stop.id);
                                        return stopCardBundle;
                                    }),
                                ];
                            },
                            onSavingSuccess: () => {
                                dispatch(refreshStopForm({ reportId }));
                            },
                        })
                    );
                }
            };
        },
        initializeStop(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const report = currentReportSelector(state);
                const reportId = report.id;

                const stopCardBundle = dispatch(buildStopCardBundle({ reportId, form }));

                dispatch(
                    replaceStopCardBundle({
                        reportId,
                        stopCardBundle,
                    })
                ).then((stopCardBundle) => {
                    form.set('stop.id', stopCardBundle.stop.id);
                });
            };
        },
    },
};

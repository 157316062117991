import React from 'react';
import { AttributeTypeEnum } from '@mark43/rms-api';
import { sortExtendedBooleanAttributes } from '../../../../reports/core/utils/useOfForceHelpers';
import { FormElementWidthOrLengthProps } from '../FormElement';
import { ArbiterMFTAttributeSelect } from './AttributeSelect';

type UseOfForceExtendedBooleanSelectProps = {
    path: string;
} & FormElementWidthOrLengthProps;

export const UseOfForceExtendedBooleanSelect: React.FC<UseOfForceExtendedBooleanSelectProps> = (
    props: UseOfForceExtendedBooleanSelectProps
) => (
    <ArbiterMFTAttributeSelect
        attributeType={AttributeTypeEnum.USE_OF_FORCE_EXTENDED_BOOLEAN.name}
        sortAttributes={sortExtendedBooleanAttributes}
        {...props}
    />
);

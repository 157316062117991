import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RmsHydratedItem, EntityTypeEnumType } from '@mark43/rms-api';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import _Pill from '../../../../core/components/Pill';

import QuickSearchResultSectionVehicleItem from '../../../../search/quick-search/components/sectionitems/QuickSearchResultSectionVehicleItem';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import testIds from '../../../../../core/testIds';

const Pill = styled(_Pill)`
    margin-bottom: 10px;
`;

type VehiclePillProps = {
    item: RmsHydratedItem;
    type: EntityTypeEnumType;
    query?: string;
    onResultClick: (props: onResultClickT) => void;
};

type onResultClickT = {
    item: RmsHydratedItem;
    type: EntityTypeEnumType;
};

const VehiclePill: React.FC<VehiclePillProps> = ({ item, type, onResultClick, query }) => {
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const departmentNameFromConsortiumLinksByDepartmentId = useSelector(
        departmentNameFromConsortiumLinksByDepartmentIdSelector
    );
    const isExternal = item.departmentId !== currentUserDepartmentId;
    return (
        <Pill showChevron={true} onClick={() => onResultClick({ item, type })}>
            <QuickSearchResultSectionVehicleItem
                item={item}
                query={query}
                externalDepartmentName={
                    isExternal
                        ? departmentNameFromConsortiumLinksByDepartmentId(item.departmentId)
                        : undefined
                }
                testType={type}
                testId={testIds.VEHICLE_SIDE_PANEL_SEARCH_RESULT}
                // @ts-expect-error QuickSearchResultSectionVehicleItem is untyped
                containerPadding={0}
            />
        </Pill>
    );
};

export default VehiclePill;

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';

import { map } from 'lodash';
import { createStructuredSelector } from 'reselect';
import * as fields from '~/client-common/core/enums/universal/fields';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import { formatAttributeLinkViewModels } from '~/client-common/core/domain/attributes/state/ui';
import {
    ConnectedFormattedUser,
    FORMATS as USER_FORMATS,
} from '~/client-common/core/users/components';
import { reportAttributeViewModelsWhereSelector } from '~/client-common/core/domain/report-attributes/state/ui';
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import { otherInvolvedAgenciesSelector } from '~/client-common/core/domain/other-involved-agencies/state/data';
import { formatNibrsCode } from '~/client-common/helpers/offenseCodesHelpers';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import UseOfForceOfficerDemographics from './UseOfForceOfficerDemographics';

export default connect(
    createStructuredSelector({
        reportAttributeViewModelsWhere: reportAttributeViewModelsWhereSelector,
        formatAttributeWithOther: formatAttributeWithOtherSelector,
        otherInvolvedAgencies: otherInvolvedAgenciesSelector,
        formatAttributeById: formatAttributeByIdSelector,
    })
)(function UseOfForceCardSummary({
    reportId,
    reportAttributeViewModelsWhere,
    formatAttributeWithOther,
    useOfForce,
    otherInvolvedAgencies,
    formatAttributeById,
}) {
    const {
        useOfForceReasonAttrId,
        useOfForceReasonOther,
        nibrsOffenseCodePendingUnknownAttrId,
        nibrsCode1,
        nibrsCode2,
        nibrsCode3,
        nibrsIncidentNumberPendingUnknownAttrId,
        nibrsIncidentNumber,
        officerSexAttrId,
        officerRaceAttrId,
        officerDressAttrId,
        medicalAidReceivedAttrId,
        didOfficerApproachAttrId,
        seniorOfficerPresentAttrId,
        supervisorOnScene,
        wasOfficerAmbushedAttrId,
        officerFullPartTimeAttrId,
        otherOfficersInvolvedButUnknown,
        minimumNumberOfUnknownOfficersInvolved,
        wasOfficerOnDutyAttrId,
        officerDutyTypeAttrId,
        specialDutyContractNumber,
    } = useOfForce;

    const useOfForceStatisticsDisplay = formatAttributeLinkViewModels(
        reportAttributeViewModelsWhere({
            reportId,
            attributeType: AttributeTypeEnum.USE_OF_FORCE_STATISTICS.name,
        })
    );
    if (!useOfForce) {
        return <div />;
    }

    return (
        <div>
            <CardSection>
                <SummaryList labelWidth={220} contentWidth={320}>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_USE_OF_FORCE_REASON_ATTR_ID}>
                        {useOfForceReasonAttrId &&
                            formatAttributeWithOther({
                                attributeId: useOfForceReasonAttrId,
                                other: useOfForceReasonOther,
                            })}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.USE_OF_FORCE_NIBRS_INCIDENT_NUMBER_PENDING_UNKNOWN_ATTR_ID
                        }
                    >
                        {formatAttributeById(nibrsIncidentNumberPendingUnknownAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_NIBRS_INCIDENT_NUMBER}>
                        {nibrsIncidentNumber}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.USE_OF_FORCE_NIBRS_OFFENSE_CODE_PENDING_UNKNOWN_ATTR_ID}
                    >
                        {nibrsOffenseCodePendingUnknownAttrId &&
                            formatAttributeWithOther({
                                attributeId: nibrsOffenseCodePendingUnknownAttrId,
                            })}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_NIBRS_CODE_1}>
                        {nibrsCode1 && formatNibrsCode(nibrsCode1)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_NIBRS_CODE_2}>
                        {nibrsCode2 && formatNibrsCode(nibrsCode2)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_NIBRS_CODE_3}>
                        {nibrsCode3 && formatNibrsCode(nibrsCode3)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_USER_PROFILE_ID}>
                        {useOfForce.userProfileId && (
                            <ConnectedFormattedUser
                                userId={useOfForce.userProfileId}
                                format={USER_FORMATS.FULL_NAME}
                            />
                        )}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_NAME}>
                        {useOfForce.officerName}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_BADGE_NUMBER}>
                        {useOfForce.officerBadgeNumber}
                    </SummaryRow>
                    <UseOfForceOfficerDemographics>
                        <SummaryRowDate
                            date={useOfForce.officerDateOfBirth}
                            fieldName={fields.USE_OF_FORCE_OFFICER_DATE_OF_BIRTH}
                            format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                        />
                    </UseOfForceOfficerDemographics>
                    <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                        <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_FULL_PART_TIME_ATTR_ID}>
                            {officerFullPartTimeAttrId && (
                                <ConnectedFormattedAttribute
                                    attributeId={officerFullPartTimeAttrId}
                                />
                            )}
                        </SummaryRow>
                    </FeatureFlagged>
                    <UseOfForceOfficerDemographics>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_SEX_ATTR_ID}>
                            {officerSexAttrId &&
                                formatAttributeWithOther({
                                    attributeId: officerSexAttrId,
                                })}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_RACE_ATTR_ID}>
                            {officerRaceAttrId &&
                                formatAttributeWithOther({
                                    attributeId: officerRaceAttrId,
                                })}
                        </SummaryRow>
                    </UseOfForceOfficerDemographics>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_DRESS_ATTR_ID}>
                        {officerDressAttrId &&
                            formatAttributeWithOther({
                                attributeId: officerDressAttrId,
                            })}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_MEDICAL_AID_RECEIVED_ATTR_ID}>
                        {medicalAidReceivedAttrId &&
                            formatAttributeWithOther({
                                attributeId: medicalAidReceivedAttrId,
                            })}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_WAS_OFFICER_ON_DUTY_ATTR_ID}>
                        {formatAttributeById(wasOfficerOnDutyAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_DUTY_TYPE_ATTR_ID}>
                        {formatAttributeById(officerDutyTypeAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_SPECIAL_DUTY_CONTRACT_NUMBER}>
                        {specialDutyContractNumber}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_INCIDENT_RESULTED_IN_CRIME_REPORT}>
                        {booleanToYesNo(useOfForce.incidentResultedInCrimeReport)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_RANK}>
                        {useOfForce.officerRank}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_DUTY_ASSIGNMENT}>
                        {useOfForce.officerDutyAssignment}
                    </SummaryRow>
                    <UseOfForceOfficerDemographics>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_YEARS_OF_SERVICE}>
                            {useOfForce.officerYearsOfService}
                        </SummaryRow>
                    </UseOfForceOfficerDemographics>
                    <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                        <UseOfForceOfficerDemographics>
                            <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_HEIGHT_FEET}>
                                {useOfForce.officerHeightFeet}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_HEIGHT_INCHES}>
                                {useOfForce.officerHeightInches}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.USE_OF_FORCE_OFFICER_WEIGHT}>
                                {useOfForce.officerWeight}
                            </SummaryRow>
                        </UseOfForceOfficerDemographics>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_DID_OFFICER_APPROACH_ATTR_ID}>
                            {didOfficerApproachAttrId &&
                                formatAttributeWithOther({
                                    attributeId: didOfficerApproachAttrId,
                                })}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_SENIOR_OFFICER_PRESENT_ATTR_ID}>
                            {seniorOfficerPresentAttrId &&
                                formatAttributeWithOther({
                                    attributeId: seniorOfficerPresentAttrId,
                                })}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_SUPERVISOR_ON_SCENE}>
                            {booleanToYesNo(supervisorOnScene)}
                        </SummaryRow>
                        {supervisorOnScene && (
                            <SummaryRow
                                fieldName={fields.USE_OF_FORCE_ON_SCENE_SUPERVISOR_USER_PROFILE_ID}
                            >
                                <ConnectedFormattedUser
                                    userId={useOfForce.onSceneSupervisorUserProfileId}
                                    format={USER_FORMATS.FULL_NAME_WITHOUT_BADGE}
                                />
                            </SummaryRow>
                        )}
                        <SummaryRow
                            fieldName={
                                fields.USE_OF_FORCE_ON_SCENE_SUPERVISOR_HUMAN_RESOURCES_NUMBER
                            }
                        >
                            {useOfForce.onSceneSupervisorHumanResourcesNumber}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_ON_SCENE_SUPERVISOR_UNIT}>
                            {useOfForce.onSceneSupervisorUnit}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.USE_OF_FORCE_WAS_OFFICER_AMBUSHED_ATTR_ID}>
                            {wasOfficerAmbushedAttrId &&
                                formatAttributeWithOther({
                                    attributeId: wasOfficerAmbushedAttrId,
                                })}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.USE_OF_FORCE_OTHER_OFFICERS_INVOLVED_BUT_UNKNOWN}
                        >
                            {booleanToYesNo(otherOfficersInvolvedButUnknown)}
                        </SummaryRow>
                        {otherOfficersInvolvedButUnknown && (
                            <SummaryRow
                                fieldName={
                                    fields.USE_OF_FORCE_MINIMUM_NUMBER_OF_UNKNOWN_OFFICERS_INVOLVED
                                }
                            >
                                {minimumNumberOfUnknownOfficersInvolved}
                            </SummaryRow>
                        )}
                        {map(otherInvolvedAgencies, (otherInvolvedAgency, i) => {
                            const {
                                otherAgencyName,
                                otherAgencyOri,
                                otherAgencyCaseNumber,
                            } = otherInvolvedAgency;
                            return (
                                <div key={i}>
                                    <SummaryRow
                                        fieldName={fields.OTHER_INVOLVED_AGENCY_OTHER_AGENCY_NAME}
                                    >
                                        {otherAgencyName}
                                    </SummaryRow>
                                    <SummaryRow
                                        fieldName={fields.OTHER_INVOLVED_AGENCY_OTHER_AGENCY_ORI}
                                    >
                                        {otherAgencyOri}
                                    </SummaryRow>
                                    <SummaryRow
                                        fieldName={
                                            fields.OTHER_INVOLVED_AGENCY_OTHER_AGENCY_CASE_NUMBER
                                        }
                                    >
                                        {otherAgencyCaseNumber}
                                    </SummaryRow>
                                </div>
                            );
                        })}
                    </FeatureFlagged>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_STATISTICS_ATTRIBUTE_ID
                        }
                    >
                        {useOfForceStatisticsDisplay}
                    </SummaryRow>
                </SummaryList>
            </CardSection>
        </div>
    );
});

import { compact, flatMap, map, keyBy, values, chain, includes, filter } from 'lodash';
import { OffenseCaseStatus, Report, ReportShortTitle } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import {
    NEXUS_STATE_PROP,
    offenseCaseStatusesSelector,
} from '~/client-common/core/domain/offense-case-statuses/state/data';
import {
    reportDefinitionHasOffenseCaseStatusSelector,
    getReportIdAndReportDefinitionIds,
} from '~/client-common/core/domain/report-definitions/state/data';
import { reportShortTitlesSelector } from '~/client-common/core/domain/report-short-titles/state/data';
import { ModuleShape } from '~/client-common/core/utils/createNormalizedModule';
import offenseCaseStatusResource from '../../resources/offenseCaseStatusResource';
import { RmsAction } from '../../../../../core/typings/redux';
import { sortedOffenseIncidentTitlesForReportIdSelector } from '../../../../reports/core/state/ui/offense';

const LOAD_OFFENSE_CASE_STATUSES_FOR_REPORT_ID_START =
    'offense-case-statuses/LOAD_OFFENSE_CASE_STATUSES_FOR_REPORT_ID_START';
const LOAD_OFFENSE_CASE_STATUSES_FOR_REPORT_ID_SUCCESS =
    'offense-case-statuses/LOAD_OFFENSE_CASE_STATUSES_FOR_REPORT_ID_SUCCESS';
const LOAD_OFFENSE_CASE_STATUSES_FOR_REPORT_ID_FAILURE =
    'offense-case-statuses/LOAD_OFFENSE_CASE_STATUSES_FOR_REPORT_ID_FAILURE';

const SAVE_OFFENSE_CASE_STATUS_FOR_REPORT_ID_START =
    'offense-case-statuses/SAVE_OFFENSE_CASE_STATUS_FOR_REPORT_ID_START';
const SAVE_OFFENSE_CASE_STATUS_FOR_REPORT_ID_SUCCESS =
    'offense-case-statuses/SAVE_OFFENSE_CASE_STATUS_FOR_REPORT_ID_SUCCESS';
const SAVE_OFFENSE_CASE_STATUS_FOR_REPORT_ID_FAILURE =
    'offense-case-statuses/SAVE_OFFENSE_CASE_STATUS_FOR_REPORT_ID_FAILURE';

// ACTIONS
function loadOffenseCaseStatusesForReportIdStart() {
    return {
        type: LOAD_OFFENSE_CASE_STATUSES_FOR_REPORT_ID_START,
    };
}
function loadOffenseCaseStatusesForReportIdSuccess(offenseCaseStatuses: OffenseCaseStatus[]) {
    return {
        type: LOAD_OFFENSE_CASE_STATUSES_FOR_REPORT_ID_SUCCESS,
        payload: offenseCaseStatuses,
    };
}
function loadOffenseCaseStatusesForReportIdFailure(errorMessage: string) {
    return {
        type: LOAD_OFFENSE_CASE_STATUSES_FOR_REPORT_ID_FAILURE,
        payload: errorMessage,
    };
}

function saveOffenseCaseStatusesStart(offenseCaseStatuses: OffenseCaseStatus[]) {
    return {
        type: SAVE_OFFENSE_CASE_STATUS_FOR_REPORT_ID_START,
        payload: offenseCaseStatuses,
    };
}
function saveOffenseCaseStatusesSuccess(offenseCaseStatuses: OffenseCaseStatus[]) {
    return {
        type: SAVE_OFFENSE_CASE_STATUS_FOR_REPORT_ID_SUCCESS,
        payload: offenseCaseStatuses,
    };
}
function saveOffenseCaseStatusesFailure(errorMessage: string) {
    return {
        type: SAVE_OFFENSE_CASE_STATUS_FOR_REPORT_ID_FAILURE,
        payload: errorMessage,
    };
}

// TYPES
export interface ExtendedOffenseCaseStatus extends Partial<OffenseCaseStatus> {
    offenseId: number;
    title: string;
    reportId: number;
}

// SELECTORS
const getReportIdsForHasOffenseCaseStatusEnabled = (
    reports: Report[] | ModuleShape<Report> | undefined,
    reportShortTitles: ReportShortTitle[] | ModuleShape<ReportShortTitle> | undefined,
    reportDefinitionHasOffenseCaseStatus: (id: number) => boolean
) => {
    const reportIdAndReportDefinitionIds = getReportIdAndReportDefinitionIds(
        // @ts-expect-error TODO: update the type of this function to accept either Report[] or ModuleShape<Report>
        reports,
        reportShortTitles
    );

    const reportIds = chain(reportIdAndReportDefinitionIds)
        .filter(({ reportDefinitionId }) =>
            reportDefinitionHasOffenseCaseStatus(reportDefinitionId)
        )
        .map('reportId')
        .value();

    return reportIds;
};

const sortedOffenseCaseStatusesForFilteredReportIdsSelector = createSelector(
    sortedOffenseIncidentTitlesForReportIdSelector,
    (sortedOffenseIncidentTitlesForReportId) => (
        offenseCaseStatuses: OffenseCaseStatus[],
        filteredReportIds: number[]
    ): ExtendedOffenseCaseStatus[] => {
        const offenseCaseStatusByOffenseId = keyBy(offenseCaseStatuses, 'offenseId');

        const sortedOffenseCaseStatuses = compact(
            flatMap(filteredReportIds, (reportId) => {
                const offenses = sortedOffenseIncidentTitlesForReportId(reportId);
                return compact(
                    map(offenses, (offense) => {
                        if (offense.id < 0) {
                            return undefined;
                        }

                        if (!offenseCaseStatusByOffenseId[offense.id]) {
                            return {
                                offenseId: offense.id,
                                title: offense.display,
                                reportId,
                            };
                        }

                        return {
                            ...offenseCaseStatusByOffenseId[offense.id],
                            title: offense.display,
                            reportId,
                        };
                    })
                );
            })
        );

        return sortedOffenseCaseStatuses;
    }
);

export const sortedOffenseCaseStatusesForOffenseCaseStatusesAndReportIdsSelector = createSelector(
    reportDefinitionHasOffenseCaseStatusSelector,
    sortedOffenseCaseStatusesForFilteredReportIdsSelector,
    (reportDefinitionHasOffenseCaseStatus, sortedOffenseCaseStatusesForFilteredReportIds) => ({
        offenseCaseStatuses,
        reportShortTitles,
        reports,
        reportIds,
    }: {
        offenseCaseStatuses: OffenseCaseStatus[];
        reportShortTitles: ReportShortTitle[] | undefined;
        reports: Report[] | undefined;
        reportIds: number[];
    }) => {
        const reportIdsForHasOffenseCaseStatusEnabled = getReportIdsForHasOffenseCaseStatusEnabled(
            reports,
            reportShortTitles,
            reportDefinitionHasOffenseCaseStatus
        );
        const filteredReportIds = filter(reportIds, (reportId) =>
            includes(reportIdsForHasOffenseCaseStatusEnabled, reportId)
        );

        return sortedOffenseCaseStatusesForFilteredReportIds(
            offenseCaseStatuses,
            filteredReportIds
        );
    }
);

export const sortedOffenseCaseStatusesForReportIdsSelector = createSelector(
    sortedOffenseCaseStatusesForOffenseCaseStatusesAndReportIdsSelector,
    reportShortTitlesSelector,
    offenseCaseStatusesSelector,
    (
        sortedOffenseCaseStatusesForOffenseCaseStatusesAndReportIds,
        reportShortTitles,
        offenseCaseStatuses
    ) => (reportIds: number[]) => {
        return sortedOffenseCaseStatusesForOffenseCaseStatusesAndReportIds({
            offenseCaseStatuses,
            reportShortTitles: values(reportShortTitles),
            reports: undefined,
            reportIds,
        });
    }
);

export function loadOffenseCaseStatusesForReportingEventId(
    reportingEventId: number
): RmsAction<Promise<OffenseCaseStatus[]>> {
    return (dispatch, _getState, { nexus }) => {
        dispatch(loadOffenseCaseStatusesForReportIdStart());
        return offenseCaseStatusResource
            .getOffenseCaseStatusesForReportingEventId(reportingEventId)
            .then((offenseCaseStatuses) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: offenseCaseStatuses,
                        },
                        loadOffenseCaseStatusesForReportIdSuccess(offenseCaseStatuses)
                    )
                );
                return offenseCaseStatuses;
            })
            .catch((err) => {
                dispatch(loadOffenseCaseStatusesForReportIdFailure(err.message));
                throw err;
            });
    };
}

export function saveOffenseCaseStatuses(
    reportingEventId: number,
    offenseCaseStatuses: OffenseCaseStatus[]
): RmsAction<Promise<OffenseCaseStatus[]>> {
    return (dispatch, _getState, { nexus }) => {
        dispatch(saveOffenseCaseStatusesStart(offenseCaseStatuses));

        return offenseCaseStatusResource
            .upsertOffenseCaseStatuses(reportingEventId, offenseCaseStatuses)
            .then((offenseCaseStatuses) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: offenseCaseStatuses,
                        },
                        saveOffenseCaseStatusesSuccess(offenseCaseStatuses)
                    )
                );
                return offenseCaseStatuses;
            })
            .catch((err) => {
                dispatch(saveOffenseCaseStatusesFailure(err.message));
                throw err;
            });
    };
}

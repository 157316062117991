import { combineReducers } from 'redux';

import constantsReducer from '~/client-common/core/constants/state/constants';

import routerReducer from '../../routing/routerModule';
import localStorageReducer from '../../modules/core/local-storage';
import themeReducer from '../../modules/core/styles/state';
import dataReducer from './dataReducer';
import dataNexusReducer from './dataNexusReducer';
import uiReducer from './uiReducer';
import formReducer from './formReducer'; // redux-form
import { formModelsReducer, formUiReducer } from './formReducers'; // react-redux-form
import configReducer from './configReducer';
import persistentReducer from './persistentReducer';
import sessionHistoryReducer from './sessionHistoryReducer';

const rootReducer = combineReducers({
    dataNexus: dataNexusReducer,

    /**
     * Raw, unmodified data received from the server. Sometimes reorganized
     *   (such as normalized by a certain key) for convenient access.
     */
    data: dataReducer,

    /**
     * State necessary for shaping the current ui
     */
    ui: uiReducer,

    /**
     * DEPRECATED.
     * redux-form state.
     */
    form: formReducer,

    /**
     * TODO Nest under state.forms
     * react-redux-form form model state. Holds the values of fields
     *   in our many instances of react-redux-form.
     */
    formModels: formModelsReducer,

    /**
     * TODO Nest under state.forms
     * react-redux-form form ui state. Holds ui state related to form fields,
     *   including validation state, etc.
     */
    formUi: formUiReducer,

    /**
     * Application settings configured by a department.
     */
    config: configReducer,

    /**
     * Static declarations which are kept on the server, sent down in a remote
     *  call to reduce the overhead of maintaining these declarations in two places.
     *  Includes things like a full list of supported countries and timezones.
     */
    constants: constantsReducer,

    /**
     * TODO rename to state.router
     * Router state pertaining mostly to the route the user is currently viewing,
     *   includes page name and path params
     */
    routing: routerReducer,

    /**
     * A copy of local storage kept in redux state. Allows for consumers to
     *   read from local storage via an idiomatic redux workflow.
     */
    localStorage: localStorageReducer,

    /**
     * An enum value of the currently selected theme for the user
     */
    theme: themeReducer,

    /**
     * A subtree of state that persists across login and logout. Most other state
     *   is cleared during login or logout due to being wrapped by `makeResettable`.
     */
    persistent: persistentReducer,

    /**
     * A history of user actions used to aid in error replication/fixing.
     *   State shaped by this reducer is sent to sentry and as such should never
     *   contain sensitive information (CJI).
     */
    sessionHistory: sessionHistoryReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import React from 'react';
import { compact, isEmpty } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';

import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import SummarySectionDivider from '../../../../legacy-redux/components/summaries/SummarySectionDivider';

const strings = componentStrings.trafficCrashTrailerSummary;

export const TrafficCrashTrailerSummary = ({
    title,
    trailerName,
    licensePlateNum,
    vin,
    totalNumAxels,
    showBottomDivider,
}: {
    title?: string;
    trailerName?: string;
    licensePlateNum?: string;
    vin?: string;
    totalNumAxels?: number;
    showBottomDivider?: boolean;
}) => {
    if (isEmpty(compact([trailerName, licensePlateNum, vin, totalNumAxels]))) {
        return null;
    }

    return (
        <>
            <SummarySectionDivider className="summary-section-divider" />
            <SummaryRow label={title}>{trailerName}</SummaryRow>
            <SummaryRow label={strings.licensePlateNum}>{licensePlateNum}</SummaryRow>
            <SummaryRow label={strings.vin}>{vin}</SummaryRow>
            <SummaryRow label={strings.numAxels}>{totalNumAxels}</SummaryRow>
            {showBottomDivider && <SummarySectionDivider className="summary-section-divider" />}
        </>
    );
};

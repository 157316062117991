import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import invariant from 'invariant';
import _, { map, first, last, tail, initial, ceil } from 'lodash';
import testIds from '../../../core/testIds';

function surroundNumbers(baseCollection, surroundCount, maxNumber, minNumber) {
    if (surroundCount === 0) {
        return baseCollection;
    }
    if (last(baseCollection) + 1 <= maxNumber) {
        baseCollection = [...baseCollection, last(baseCollection) + 1];
        surroundCount--;
    }
    if (surroundCount && first(baseCollection) - 1 > minNumber) {
        baseCollection = [first(baseCollection) - 1, ...baseCollection];
        surroundCount--;
    }

    return surroundNumbers(baseCollection, surroundCount, maxNumber, minNumber);
}

function Pagination({
    className,
    currentPage = 0,
    itemCount,
    itemsPerPage,
    maxDisplayedItems = 7,
    maxEdgeItems = 0,
    prevText = 'PREV',
    nextText = 'NEXT',
    gapText,
    onClick,
}) {
    invariant(itemsPerPage > 0, `itemsPerPage must be greater than 0, but is ${itemsPerPage}`);
    const pageCount = ceil(itemCount / itemsPerPage);

    invariant(maxDisplayedItems % 2, 'maxDisplayedItems must be an odd number.');

    invariant(
        maxEdgeItems <= (maxDisplayedItems - 1) / 2,
        `Given a value of ${maxDisplayedItems} maxDisplayedItems, maxEdgeItems
        must be no greater than ${(maxDisplayedItems - 1) / 2}`
    );

    const handleClick = (newPage) => onClick(currentPage, newPage);

    const prevButton = (
        <PaginationButton
            pageNumber={currentPage - 1}
            disabled={currentPage <= 1}
            textOverride={prevText}
            onClick={handleClick}
            className="prev-next"
        />
    );

    const nextButton = (
        <PaginationButton
            pageNumber={currentPage + 1}
            disabled={currentPage >= pageCount}
            textOverride={nextText}
            onClick={handleClick}
            className="prev-next"
        />
    );

    // in a simple case we can cut out early and do a very basic render without
    // any real logic needed
    if (pageCount <= maxDisplayedItems || pageCount <= maxEdgeItems * 2) {
        return (
            <ul className={classNames('mark43-pagination-container', className)}>
                {prevButton}
                {map(
                    Array(pageCount),
                    (x, index) => (
                        ++index,
                        (
                            <PaginationButton
                                key={index}
                                pageNumber={index}
                                onClick={handleClick}
                                active={index === currentPage}
                            />
                        )
                    )
                )}
                {nextButton}
            </ul>
        );
    }

    const leftEdgePageNumbers = map(Array(maxEdgeItems), (item, index) => index + 1);
    const rightEdgePageNumbers = map(
        Array(maxEdgeItems),
        (item, index) => pageCount - index
    ).reverse();
    let hasLeftFiller = false;
    let hasRightFiller = false;

    let middlePageNumbers = _(
        surroundNumbers(
            [
                // make sure the value we're surrounding is always greater
                // than our minimum and below our maximum, but as close
                // to its original value as possible
                currentPage <= maxEdgeItems
                    ? maxEdgeItems + 1
                    : currentPage >= pageCount - maxEdgeItems
                    ? pageCount - maxEdgeItems
                    : currentPage,
            ],
            maxDisplayedItems - 1 - maxEdgeItems * 2,
            pageCount - maxEdgeItems + 1,
            maxEdgeItems
        )
    )
        .sortBy()
        .value();

    if (last(leftEdgePageNumbers) + 1 < first(middlePageNumbers)) {
        middlePageNumbers = tail(middlePageNumbers);
        hasLeftFiller = true;
    }

    if (last(middlePageNumbers) < first(rightEdgePageNumbers) - 1) {
        middlePageNumbers = initial(middlePageNumbers);
        hasRightFiller = true;
    }

    return (
        <ul
            className={classNames('mark43-pagination-container', className)}
            data-test-id={testIds.PAGINATION_CONTAINER}
        >
            {prevButton}
            {map(leftEdgePageNumbers, (pageNumber) => (
                <PaginationButton
                    key={pageNumber}
                    pageNumber={pageNumber}
                    onClick={handleClick}
                    active={pageNumber === currentPage}
                />
            ))}
            {hasLeftFiller && <PaginationFiller gapText={gapText} />}
            {map(middlePageNumbers, (pageNumber) => (
                <PaginationButton
                    key={pageNumber}
                    pageNumber={pageNumber}
                    onClick={handleClick}
                    active={pageNumber === currentPage}
                />
            ))}
            {hasRightFiller && <PaginationFiller gapText={gapText} />}
            {nextButton}
        </ul>
    );
}

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    itemCount: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    maxDisplayedItems: PropTypes.number,
    maxEdgeItems: PropTypes.number,
    prevText: PropTypes.string,
    nextText: PropTypes.string,
    gapText: PropTypes.string,
};

function PaginationButton({
    disabled,
    active,
    textOverride,
    pageNumber,
    onClick: handleClick,
    className,
}) {
    const anchorClassNames = classNames('mark43-pagination-button', className, {
        disabled,
        active,
    });
    return (
        <li className="mark43-pagination-item">
            <span
                className={anchorClassNames}
                onClick={() => !disabled && !active && handleClick(pageNumber)}
                data-test-id={testIds.PAGINATION_ITEM}
            >
                {textOverride || pageNumber}
            </span>
        </li>
    );
}

PaginationButton.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    textOverride: PropTypes.string,
    pageNumber: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

PaginationButton.defaultProps = {
    disabled: false,
    active: false,
};

function PaginationFiller({ gapText = '\u2026' }) {
    return (
        <li className="mark43-pagination-item">
            <span className="mark43-pagination-filler">{gapText}</span>
        </li>
    );
}

PaginationFiller.propTypes = {
    textOverride: PropTypes.string,
};

PaginationFiller.defaultProps = {
    textOverride: '',
};

export default Pagination;

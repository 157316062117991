import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { map, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Box } from 'arc';

import { Observer, Fields, Fieldset as MFTFieldset } from 'markformythree';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_PERSON_PROFILE_ADD_CRITERIA_LABEL } from '~/client-common/core/enums/universal/fields';

import FormRow from '../../forms/components/FormRow';
import { ArbiterMFTBooleanSelect } from '../../forms/components/selects/BooleanSelect';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../forms/components/Text';
import { MFTNItems } from '../../forms/components/NItems';
import {
    renderAddButton,
    renderRemoveButton,
} from '../../names/components/nameFormNItemsRenderers';
import testIds from '../../../../core/testIds';
import FormGroup from '../../forms/components/FormGroup';
import { NItemsRow } from './NItemsRow';

const mapStateToProps = createStructuredSelector({
    formatAttributeById: formatAttributeByIdSelector,
    getAttributeById: getAttributeByIdSelector,
});

const CriteriaWrapper = styled(NItemsRow)`
    display: flex;
    gap: var(--arc-space-3);
`;

const CriteriaDescription = styled(ArbiterMFTText)`
    margin-top: -4px;
`;

const HelpText = styled.div`
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.mediumGrey};
    font-style: italic;
    clear: both;
    float: left;
`;

const StyledFormRow = styled(FormRow)`
    display: flex;
    flex-direction: column;
`;

function PersonProfileGangTrackingFieldsUnconnected({ getAttributeById, formatAttributeById }) {
    const attributeIdSubscription = {
        attributeId: 'attributeId',
        descriptionHidden: ['description', Fields.HIDDEN],
    };

    const addCriteriaLabel = useFields(DISPLAY_ONLY_PERSON_PROFILE_ADD_CRITERIA_LABEL)[
        DISPLAY_ONLY_PERSON_PROFILE_ADD_CRITERIA_LABEL
    ];

    const renderHelpText = ({ attributeId, descriptionHidden }) =>
        attributeId && !descriptionHidden ? (
            <HelpText data-test-id={testIds.PERSON_GANG_CRITERIA_HELP_TEXT}>
                {getAttributeById(attributeId).inAppDescription}
            </HelpText>
        ) : null;

    const gangNameFilterOptions = (options) =>
        sortBy(
            map(options, ({ value }) => {
                return {
                    value,
                    display: formatAttributeById(value, false, true),
                };
            }),
            'display'
        );

    const renderGangCriteriaNItems = () => {
        return (
            <Box flex="1">
                <ArbiterMFTAttributeSelect
                    path="attributeId"
                    attributeType={AttributeTypeEnum.GANG_CRITERIA.name}
                />
                <StyledFormRow hasIndent>
                    <CriteriaDescription label="" path="description" />
                    <Observer
                        pathIsRelative={true}
                        subscriptions={attributeIdSubscription}
                        render={renderHelpText}
                    />
                </StyledFormRow>
            </Box>
        );
    };

    return (
        <FormGroup>
            <ArbiterMFTBooleanSelect length="lg" path="isAllegedGangMember" />

            <MFTFieldset path="gangName">
                <ArbiterMFTAttributeSelect
                    attributeType={[
                        AttributeTypeEnum.GANG_NAME.name,
                        AttributeTypeEnum.GANG_SUBGROUP.name,
                    ]}
                    filterOptions={gangNameFilterOptions}
                    path="gangNameAttrId"
                    length="lg"
                />
                <FormRow hasIndent>
                    <ArbiterMFTText length="lg" path="gangNameOther" />
                </FormRow>
            </MFTFieldset>
            <MFTNItems
                path="gangCriteria"
                addText={addCriteriaLabel}
                childFieldKeys={['attributeId', 'description']}
                renderAddButton={renderAddButton}
                renderRemoveButton={renderRemoveButton}
                render={renderGangCriteriaNItems}
                renderRowContainer={({ removeButtonElement, index, itemElement }) => (
                    <CriteriaWrapper key={index}>
                        {itemElement}
                        {removeButtonElement}
                    </CriteriaWrapper>
                )}
            />
        </FormGroup>
    );
}

export const PersonProfileGangTrackingFields = connect(mapStateToProps)(
    PersonProfileGangTrackingFieldsUnconnected
);

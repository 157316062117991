import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ElasticCase, SearchQuery } from '@mark43/rms-api';
import isEqual from 'lodash/isEqual';

import { allCasesSearch } from '../../../all-cases/state/ui';
import { useDataTablePagination } from '../../../../search/core/hooks/useDataTablePagination';
import { useCasesSorts } from './useCasesSorts';

// @ts-expect-error allCasesSearch is not typed yet
const { actionCreators, selectors } = allCasesSearch;
const { resetState, search, setIsInitialSearch } = actionCreators;
const {
    currentResultsSelector,
    currentQuerySelector,
    tableLoadingSelector,
    totalCountSelector,
    initialSearchSelector,
} = selectors;

export const useCasesSearch = (initialQuery: Partial<SearchQuery> & { formData: Record<string, unknown> }) => {
    const dispatch = useDispatch();
    const [query, setQuery] = useState(initialQuery);

    const updateQuery = useCallback((params) => {
        setQuery((prevQuery) => {
            const nextQuery = { ...prevQuery, ...(params || {}) };
            return isEqual(nextQuery, prevQuery) ? prevQuery : nextQuery;
        });
    }, []);

    const { sortKey, sortType } = query;
    const { sort, onColumnSortChange } = useCasesSorts({ sortKey, sortType }, updateQuery);

    const { from, size }: { from: number; size: number } = useSelector(currentQuerySelector);
    const totalSize: number = useSelector(totalCountSelector);
    const pagination = useDataTablePagination(totalSize, { from, size }, updateQuery);

    const results: ElasticCase[] = useSelector(currentResultsSelector);
    const loading: boolean = useSelector(tableLoadingSelector);
    const initialLoading: boolean = useSelector(initialSearchSelector);

    useEffect(() => {
        dispatch(setIsInitialSearch(true));
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(search(query));
    }, [dispatch, query]);

    return { results, loading, initialLoading, pagination, sort, onColumnSortChange };
};

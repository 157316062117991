import { AttributeTypeEnum } from '@mark43/rms-api';

import { createSelector } from 'reselect';

// helpers
import { filterOffenseAttributes } from '../../../offense-attributes/utils/offenseAttributesHelpers';
// selectors
import { offenseByIdSelector } from '../../../offenses/state/data';

import { offenseAttributesSelector } from '../../../offense-attributes/state/data';
import { offenseCodeByIdSelector } from '../../../offense-codes/state/data';

/**
 * `AttributeType`s that are valid for the `OffenseAttribute`s in an Incident Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const INCIDENT_CARD_OFFENSE_ATTRIBUTE_TYPES = [AttributeTypeEnum.INCIDENT_STATISTIC.name];

export const hydratedIncidentByIdSelector = createSelector(
    offenseByIdSelector,
    offenseAttributesSelector,
    offenseCodeByIdSelector,
    (offenseById, offenseAttributes, offenseCodeById) => (incidentId) => {
        const incident = offenseById(incidentId);
        const offenseCode = offenseCodeById(incident.offenseCodeId);
        const filteredOffenseAttributes = filterOffenseAttributes({
            offenseAttributes,
            offenseId: incidentId,
            attributeTypes: INCIDENT_CARD_OFFENSE_ATTRIBUTE_TYPES,
        });

        return {
            incident,
            offenseCode,
            offenseAttributes: filteredOffenseAttributes,
        };
    }
);

import { ruleTypes } from 'arbiter-mark43';
import { actions } from 'react-redux-form';
import _, {
    compact,
    filter,
    forEach,
    get,
    has,
    mapKeys,
    merge,
    reduce,
    some,
    values,
} from 'lodash';
import { createSelector } from 'reselect';

// selectors
import { formatFieldByNameSelector } from '../fields/state/config';
import { nonInternalStaticallyHiddenFieldConfigurationsInContextSelector } from '../domain/field-configuration-contexts/state/data';

// helpers
import logBrokenFieldEffectComputation from './logBrokenFieldEffectComputation';

// constants
import { interpolateErrorMessage } from './templateRunner';

const formUiSelector = (state) => state.formUi;
const fieldUiSelector = createSelector(formUiSelector, (formUi) => (path) => get(formUi, path, {}));

// This function converts a fieldPath (that is the path to a field under formModels)
// to the path to the same field under formUi
function fieldPathToFieldUiErrorsPath(fieldPath) {
    const [, ...rest] = fieldPath.split('.');
    return `${rest.join('.')}.errors`;
}

function pushOrSet(map, key, val) {
    if (map[key]) {
        map[key].push(val);
    } else {
        map[key] = [val];
    }
}

export const FIELD_IS_HIDDEN = Symbol('FIELD_IS_HIDDEN');
const CHECKED_VALIDATION = Symbol('CHECKED_VALIDATION');
const CHECKED_HIDE = Symbol('CHECKED_HIDE');

// TODO -- there should be a default here for `fieldFieldLevelEffects` = false.
const DEFAULT_EFFECTS_CONFIGURATION = {
    skipTypes: {
        [ruleTypes.VALIDATION]: undefined,
        [ruleTypes.HIDE]: undefined,
        [ruleTypes.FIELD_CONSTRAINT]: undefined,
        [ruleTypes.CONDITIONAL]: true,
    },
    clearHiddenFields: false,
    keepTemplateString: false,
};

export default function produceRuleEffectsRRF(
    basePath,
    context,
    selectors,
    rulesResults,
    effectsConfiguration
) {
    const transformedEffectsConfiguration = {
        ...effectsConfiguration,
        skipTypes: _(get(effectsConfiguration, 'skipTypes'))
            .mapKeys()
            .mapValues(() => true)
            .value(),
    };
    const finalEffectsConfiguration = merge(
        {},
        DEFAULT_EFFECTS_CONFIGURATION,
        transformedEffectsConfiguration
    );
    return (dispatch, getState) => {
        const effectMap = {};
        // Safe to get state up here -- we use state only when building rule
        // effects, not when `dispatch`ing changes to reduce effects.
        const state = getState();
        const nonInternalStaticallyHiddenFields = nonInternalStaticallyHiddenFieldConfigurationsInContextSelector(
            state
        )(context);
        const formatFieldByName = formatFieldByNameSelector(state);
        const filteredArbiterRuleResults = filterStaticallyHiddenFieldsFromArbiterRuleResults(
            nonInternalStaticallyHiddenFields,
            rulesResults
        );
        const formHasBeenSubmitted = selectors.formHasBeenSubmittedSelector(state);
        const formFieldIsTouched = selectors.formFieldIsTouchedSelector(state);
        const forceFieldLevelEffects = get(finalEffectsConfiguration, 'forceFieldLevelEffects');
        let success = true;

        // field-level effects first
        forEach(filteredArbiterRuleResults, (ruleResult) => {
            const ruleType = ruleResult.ruleView.rule.ruleType;
            // if we should skip this ruleType, just quit early
            if (finalEffectsConfiguration.skipTypes[ruleType]) {
                return;
            }

            const ruleActionedFieldDescriptors = ruleResult.ruleActionedFieldDescriptors;

            try {
                if (ruleType === ruleTypes.VALIDATION) {
                    if (!ruleResult.success) {
                        success = false;
                    }

                    // omit producing field-level effects if:
                    // 1) The form has never attempted to have been saved
                    // 2) All fields involved with the rule have not yet
                    // been touched
                    // Note: if a field is involved in multiple rules, where
                    // at least 1 rule fails this step, then the field will
                    // have effects produced for it.
                    if (forceFieldLevelEffects !== true && !formHasBeenSubmitted) {
                        const ruleHasTouchedField = some(
                            ruleActionedFieldDescriptors,
                            (ruleActionedFieldDescriptor) => {
                                const fieldPath = ruleActionedFieldDescriptor.meta.path;
                                const isTouched = formFieldIsTouched(fieldPath);
                                return isTouched;
                            }
                        );
                        if (!ruleHasTouchedField) {
                            // omit producing a field-level effect
                            return;
                        }
                    }

                    // otherwise, produce effects for all fields involved in this rule.
                    forEach(ruleActionedFieldDescriptors, (ruleActionedFieldDescriptor) => {
                        const fieldPath = ruleActionedFieldDescriptor.meta.path;
                        // we negate ruleResult.success because ultimately we reduce this
                        // result to produce ERRORS, so a success would mean that a need
                        // for an error is false
                        pushOrSet(
                            effectMap,
                            fieldPath,
                            ruleResult.success
                                ? CHECKED_VALIDATION
                                : ruleActionedFieldDescriptor.ruleFailureDisplayText
                                ? // if theres a custom field message, use it
                                  {
                                      [ruleActionedFieldDescriptor.ruleFailureDisplayText]: interpolateErrorMessage(
                                          ruleActionedFieldDescriptor.ruleFailureDisplayText,
                                          formatFieldByName
                                      ),
                                  }
                                : // otherwise just use `true`
                                  { _error: true }
                        );
                    });
                } else if (ruleType === ruleTypes.FIELD_CONSTRAINT) {
                    if (!ruleResult.success) {
                        success = false;
                    }
                    forEach(ruleActionedFieldDescriptors, (ruleActionedFieldDescriptor) => {
                        const fieldPath = ruleActionedFieldDescriptor.meta.path;
                        pushOrSet(
                            effectMap,
                            fieldPath,
                            // same as above regardaing pushing false when there's a success
                            ruleResult.success
                                ? false
                                : {
                                      [ruleActionedFieldDescriptor.ruleFailureDisplayText]: interpolateErrorMessage(
                                          ruleActionedFieldDescriptor.ruleFailureDisplayText,
                                          formatFieldByName
                                      ),
                                  }
                        );
                    });
                } else if (ruleType === ruleTypes.HIDE) {
                    forEach(ruleActionedFieldDescriptors, (ruleActionedFieldDescriptor) => {
                        const fieldPath = ruleActionedFieldDescriptor.meta.path;
                        pushOrSet(
                            effectMap,
                            fieldPath,
                            // same as above regardaing pushing false when there's a success
                            ruleResult.success ? { [FIELD_IS_HIDDEN]: true } : CHECKED_HIDE
                        );
                    });
                } else {
                    throw new Error('Rule Result has an unhandled ruleType: ', ruleType);
                }
            } catch {
                logBrokenFieldEffectComputation(ruleResult);
            }
        });

        const fullEffectMap = mapKeys(
            effectMap,
            (value, partialPath) => `${basePath}.${partialPath}`
        );
        const computedFieldEffects = dispatch(
            reduceEffects(fullEffectMap, finalEffectsConfiguration)
        );

        // If after computing field effects we find that no fields have validation errors, but we've previously
        // set panel errors, we clear the panel errors (this can occur when validation errors were stripped
        // when they were the result of a poor rule configuration)
        if (
            !some(
                computedFieldEffects,
                (computedFieldEffect) => values(computedFieldEffect).length >= 1
            )
        ) {
            return {
                success: true,
                panelErrors: [],
            };
        }

        // panel-level effects second
        const panelErrors = getPanelErrorMessages(
            filteredArbiterRuleResults,
            formatFieldByName,
            finalEffectsConfiguration
        );

        return {
            success,
            panelErrors,
        };
    };
}

function reduceEffects(effectMap, effectsConfiguration) {
    return (dispatch, getState) => {
        const computedFieldEffects = [];
        forEach(effectMap, (results, fieldPath) => {
            let checkedHide = false;
            // let checkedValidation = false;
            let computedFieldEffect = reduce(
                results,
                (acc, result) => {
                    if (result === CHECKED_HIDE) {
                        checkedHide = true;
                        return acc;
                    } else if (result === CHECKED_VALIDATION) {
                        // checkedValidation = true;
                        return acc;
                        // if we already have an object and encounter another, we simply
                        // combine the two, as this indicates that we have multiple effects
                        // and we want to be able to express them all
                    } else if (typeof acc === 'object' && typeof result === 'object') {
                        return {
                            ...acc,
                            ...result,
                        };
                        // if we have an object and encounter a non object, we know to only keep the object,
                        // as the boolean describes nothing of use
                    } else if (typeof acc === 'object' && typeof result !== 'object') {
                        return acc;
                        // if we have a non object (which is the same as a non effect) and encounter
                        // an object, we take the object
                    } else if (typeof acc !== 'object' && typeof result === 'object') {
                        return { ...result };
                    } else if (typeof acc === 'boolean' && typeof result === 'boolean') {
                        return acc;
                    } else {
                        throw new Error(
                            'Unexpected case reached when reducing validation rule results'
                        );
                    }
                },
                {}
            );
            // if no hide rules were evaluated, we want to retain the existing hide state for this field
            if (!checkedHide && computedFieldEffect[FIELD_IS_HIDDEN] !== true) {
                const fieldErrorsPath = fieldPathToFieldUiErrorsPath(fieldPath);
                const fieldErrors = fieldUiSelector(getState())(fieldErrorsPath);
                computedFieldEffect = {
                    ...computedFieldEffect,
                    ...(fieldErrors[FIELD_IS_HIDDEN] === true ? { [FIELD_IS_HIDDEN]: true } : {}),
                };
            }
            // if no validation rules were evaluated, we want to retain the existing validation state for this field
            // if (!checkedValidation) {
            //     const fieldErrorsPath = fieldPathToFieldUiErrorsPath(fieldPath);
            //     const fieldErrors = fieldUiSelector(getState())(fieldErrorsPath);
            //     computedFieldEffect = {
            //         ...computedFieldEffect,
            //         ...(size(values(fieldErrors)) > 0 ? {...fieldErrors} : {})
            //     };
            // }

            // If the field is hidden AND has some other error, we know that we have a conflict - because the user
            // cannot see the field but some error on this field will prevent a form from being submitted.
            // If we hit this case, we want to hide the field, but strip validation errors, per acceptance criteria
            // established in https://mark43.atlassian.net/browse/RMS-5474
            if (
                computedFieldEffect[FIELD_IS_HIDDEN] === true &&
                values(computedFieldEffect).length >= 1
            ) {
                computedFieldEffect = {
                    [FIELD_IS_HIDDEN]: true,
                };
            }
            if (effectsConfiguration.clearHiddenFields && computedFieldEffect[FIELD_IS_HIDDEN]) {
                dispatch(actions.change(fieldPath, undefined));
            }
            dispatch(actions.setErrors(fieldPath, computedFieldEffect));
            computedFieldEffects.push(computedFieldEffect);
        });
        return computedFieldEffects;
    };
}

/**
 * We produce panel-level effects if:
 *  1) `VALIDATION` rule that is enabled (`isDisabled` === false), has failed, and has a panel-level
 *      error message defined.
 *  2) All fields involved in the rule (that is to say: "all fields involved in all rule conditions")
 *     are shown.
 */
function getPanelErrorMessages(
    allArbiterRuleResults,
    formatFieldByName,
    effectsConfiguration = { keepTemplateString: false }
) {
    const allHiddenRuleActionedFields = _(allArbiterRuleResults)
        // grab all `HIDE` rules that are not disabled and have "passed" Arbiter rule running.
        .filter((arbiterRuleResult) => {
            const ruleType = get(arbiterRuleResult, 'ruleView.rule.ruleType');
            const ruleIsDisabled = get(
                arbiterRuleResult,
                'ruleView.ruleConfigurationContext.isDisabled'
            );
            return (
                ruleType === ruleTypes.HIDE &&
                ruleIsDisabled !== true &&
                arbiterRuleResult.success === true
            );
        })
        // grab all `RuleActionedFields`
        .flatMap((arbiterRuleResult) => {
            return get(arbiterRuleResult, 'ruleView.ruleActionedFields');
        })
        .keyBy('fieldConfigurationId')
        .value();

    const failedValidationRulesPanelErrorMessages = _(allArbiterRuleResults)
        // grab all `VALIDATION` rules that are not disabled and have failed Arbiter rule running.
        .filter((arbiterRuleResult) => {
            const ruleType = get(arbiterRuleResult, 'ruleView.rule.ruleType');
            const ruleIsDisabled = get(
                arbiterRuleResult,
                'ruleView.ruleConfigurationContext.isDisabled'
            );
            return (
                ruleType === ruleTypes.VALIDATION &&
                ruleIsDisabled !== true &&
                arbiterRuleResult.success !== true
            );
        })
        .map((arbiterRuleResult) => {
            const rulePanelErrorMessage = get(
                arbiterRuleResult,
                'ruleView.ruleConfigurationContext.ruleFailureDisplayText'
            );
            const errorMessage = effectsConfiguration.keepTemplateString
                ? rulePanelErrorMessage
                : interpolateErrorMessage(rulePanelErrorMessage, formatFieldByName);

            const allRuleConditions = get(arbiterRuleResult, 'ruleView.ruleConditions');
            // grab all distinct field configuration ids, for all rule conditions of the rule
            const allRuleConditionsFieldConfigurationIds = _(allRuleConditions)
                .flatMap((ruleCondition) => {
                    const fieldConfigurationIds = [
                        ruleCondition.fieldConfigurationId,
                        ruleCondition.fieldConfiguration2Id,
                    ];
                    return compact(fieldConfigurationIds);
                })
                .uniq()
                .value();

            const ruleHasHiddenFields = some(
                allRuleConditionsFieldConfigurationIds,
                (ruleConditionFieldConfigurationId) =>
                    has(allHiddenRuleActionedFields, ruleConditionFieldConfigurationId)
            );

            return ruleHasHiddenFields ? undefined : errorMessage;
        })
        .compact()
        .value();

    return failedValidationRulesPanelErrorMessages;
}

// TODO -- combine common functionality here with the function above.  Not done here because this
// function was added as a patch.
/**
 * Filter out `VALIDATION` rules from Arbiter Rule Results if the `VALIDATION` rule contains *any*
 * non-`INTERNAL_DATA` fields that are statically hidden.
 */
function filterStaticallyHiddenFieldsFromArbiterRuleResults(
    nonInternalStaticallyHiddenFields,
    arbiterRuleResults
) {
    const filteredArbiterRuleResults = filter(arbiterRuleResults, (arbiterRuleResult) => {
        const ruleType = get(arbiterRuleResult, 'ruleView.rule.ruleType');
        if (ruleType !== ruleTypes.VALIDATION) {
            return true;
        }

        const allRuleConditions = get(arbiterRuleResult, 'ruleView.ruleConditions');
        // grab all distinct field configuration ids, for all rule conditions of the rule
        const allRuleConditionsFieldConfigurationIds = _(allRuleConditions)
            .flatMap((ruleCondition) => {
                const fieldConfigurationIds = [
                    ruleCondition.fieldConfigurationId,
                    ruleCondition.fieldConfiguration2Id,
                ];
                return compact(fieldConfigurationIds);
            })
            .uniq()
            .value();

        const ruleHasStaticallyHiddenFields = some(
            allRuleConditionsFieldConfigurationIds,
            (ruleConditionFieldConfigurationId) =>
                some(nonInternalStaticallyHiddenFields, { id: ruleConditionFieldConfigurationId })
        );

        return !ruleHasStaticallyHiddenFields;
    });

    return filteredArbiterRuleResults;
}

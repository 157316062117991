import React from 'react';
import styled from 'styled-components';
import { FormattedDate } from '~/client-common/core/dates/components';

const CellWrapper = styled.div`
    margin-left: 12px;
`;
const CompletedDateCell = (data) => (
    <CellWrapper>
        <FormattedDate date={data.completedDateUtc} format={FormattedDate.FORMATS.FORM_DATE}>
            {(formattedDate) => <div>{formattedDate || '-'}</div>}
        </FormattedDate>
    </CellWrapper>
);

export default CompletedDateCell;

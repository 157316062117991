import React from 'react';
import styled from 'styled-components';
import { FormattedDate } from '~/client-common/core/dates/components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

const strings = componentStrings.core.DateCell;

const OverdueText = styled.div`
    font-size: var(--arc-fontSizes-xs);
    font-style: italic;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.red};
`;

export default function dateCellFactory(propKey, inline) {
    return function DateCell(props) {
        const date = (
            <FormattedDate date={props[propKey]} format={FormattedDate.FORMATS.FORM_DATE} />
        );
        const time = (
            <FormattedDate date={props[propKey]} format={FormattedDate.FORMATS.FORM_TIME} />
        );
        const isOverdue = getViewModelProperties(props).isOverdue;

        return (
            <div>
                {!props[propKey] ? (
                    <div />
                ) : inline ? (
                    <>
                        <div>
                            {date} {time}
                        </div>
                    </>
                ) : (
                    <div>
                        <div>{date}</div>
                        <div>{time}</div>
                    </div>
                )}
                {isOverdue && <OverdueText>{strings.overdue}</OverdueText>}
            </div>
        );
    };
}

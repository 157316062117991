import { createSelector } from 'reselect';
import { OffenseCodeHocCategoryLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'offenseCodeHocCategoryLinks';

const offenseCodeHocCategoryLinksModule = createLinkModule<OffenseCodeHocCategoryLink>({
    type: NEXUS_STATE_PROP,
    keys: ['offenseCodeId', 'hocCategoryAttrId'],
});

const offenseCodeHocCategoryLinksWhereSelector =
    offenseCodeHocCategoryLinksModule.selectors.linksWhereSelector;

export const hocCategoryAttrIdsForOffenseCodeIdSelector = createSelector(
    offenseCodeHocCategoryLinksWhereSelector,
    (offenseCodeHocCategoryLinks) => (offenseCodeId: number) => {
        const hocCategoryForOffenseCodeId = offenseCodeHocCategoryLinks({
            offenseCodeId,
        });
        return hocCategoryForOffenseCodeId.map((x) => x.hocCategoryAttrId);
    }
);

export default offenseCodeHocCategoryLinksModule.reducerConfig;

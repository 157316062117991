import { PreferencePermissionEnum } from '@mark43/rms-api';
import { get, without } from 'lodash';
import {
    storeDepartmentPreferences,
    departmentPreferenceByIdSelector,
} from '~/client-common/core/domain/department-preferences/state/data';
import {
    storePreferenceLinks,
    preferenceLinkByIdSelector,
} from '~/client-common/core/domain/preference-links/state/data';
import cadPreferenceResource from '../../resources/cadPreferenceResource';

const LOAD_DEPARTMENT_PREFERENCES_START = 'preferences/LOAD_DEPARTMENT_PREFERENCES_START';
const LOAD_DEPARTMENT_PREFERENCES_SUCCESS = 'preferences/LOAD_DEPARTMENT_PREFERENCES_SUCCESS';
export const LOAD_DEPARTMENT_PREFERENCES_FAILURE =
    'preferences/LOAD_DEPARTMENT_PREFERENCES_FAILURE';
const LOAD_PREFERENCE_LINKS_START = 'preferences/LOAD_PREFERENCE_LINKS_START';
const LOAD_PREFERENCE_LINKS_SUCCESS = 'preferences/LOAD_PREFERENCE_LINKS_SUCCESS';
export const LOAD_PREFERENCE_LINKS_FAILURE = 'preferences/LOAD_PREFERENCE_LINKS_FAILURE';
const UPSERT_PREFERENCE_LINK_START = 'preferences/UPSERT_PREFERENCE_LINK_START';
const UPSERT_PREFERENCE_LINK_SUCCESS = 'preferences/UPSERT_PREFERENCE_LINK_SUCCESS';
export const UPSERT_PREFERENCE_LINK_FAILURE = 'preferences/UPSERT_PREFERENCE_LINK_FAILURE';
const UPSERT_DEPARTMENT_PREFERENCE_START = 'preferences/UPSERT_DEPARTMENT_PREFERENCE_START';
const UPSERT_DEPARTMENT_PREFERENCE_SUCCESS = 'preferences/UPSERT_DEPARTMENT_PREFERENCE_SUCCESS';
export const UPSERT_DEPARTMENT_PREFERENCE_FAILURE =
    'preferences/UPSERT_DEPARTMENT_PREFERENCE_FAILURE';

function loadDepartmentPreferencesStart() {
    return {
        type: LOAD_DEPARTMENT_PREFERENCES_START,
    };
}

function loadDepartmentPreferencesSuccess(preferences) {
    return {
        type: LOAD_DEPARTMENT_PREFERENCES_SUCCESS,
        payload: preferences,
    };
}

function loadDepartmentPreferencesFailure(err) {
    return {
        type: LOAD_DEPARTMENT_PREFERENCES_FAILURE,
        payload: err,
    };
}

function loadPreferenceLinksStart() {
    return {
        type: LOAD_PREFERENCE_LINKS_START,
    };
}

function loadPreferenceLinksSuccess(preferenceLinks) {
    return {
        type: LOAD_PREFERENCE_LINKS_SUCCESS,
        payload: preferenceLinks,
    };
}

function loadPreferenceLinksFailure(err) {
    return {
        type: LOAD_PREFERENCE_LINKS_FAILURE,
        payload: err,
    };
}

function upsertDepartmentPreferenceStart() {
    return {
        type: UPSERT_DEPARTMENT_PREFERENCE_START,
    };
}

function upsertDepartmentPreferenceSuccess(departmentPreference) {
    return {
        type: UPSERT_DEPARTMENT_PREFERENCE_SUCCESS,
        payload: departmentPreference,
    };
}

function upsertDepartmentPreferenceFailure(err) {
    return {
        type: UPSERT_DEPARTMENT_PREFERENCE_FAILURE,
        payload: err,
    };
}

function upsertPreferenceLinkStart() {
    return {
        type: UPSERT_PREFERENCE_LINK_START,
    };
}

function upsertPreferenceLinkSuccess(preferenceLink) {
    return {
        type: UPSERT_PREFERENCE_LINK_SUCCESS,
        payload: preferenceLink,
    };
}

function upsertPreferenceLinkFailure(err) {
    return {
        type: UPSERT_PREFERENCE_LINK_FAILURE,
        payload: err,
    };
}

export function loadDepartmentPreferenceAdmin() {
    return function (dispatch) {
        dispatch(loadDepartmentPreferencesStart());
        dispatch(loadPreferenceLinksStart());
        return cadPreferenceResource
            .getDepartmentPreferencesAdmin()
            .then(({ departmentPreferences, preferenceLinks }) => {
                dispatch(storeDepartmentPreferences(departmentPreferences));
                dispatch(storePreferenceLinks(preferenceLinks));
                dispatch(loadDepartmentPreferencesSuccess(departmentPreferences));
                dispatch(loadPreferenceLinksSuccess(preferenceLinks));
            })
            .catch((err) => {
                dispatch(loadDepartmentPreferencesFailure(err));
                dispatch(loadPreferenceLinksFailure(err));
            });
    };
}

export function upsertDepartmentPreferenceLink(id, value) {
    return function (dispatch, getState) {
        const state = getState();
        const preferenceLink = preferenceLinkByIdSelector(state)(id);
        const updated = {
            ...preferenceLink,
            booleanValue: value,
        };

        dispatch(upsertPreferenceLinkStart());
        return cadPreferenceResource
            .upsertDepartmentPreferenceLink(updated)
            .then((result) => {
                dispatch(storePreferenceLinks(result));
                dispatch(upsertPreferenceLinkSuccess(result));
            })
            .catch((err) => {
                dispatch(upsertPreferenceLinkFailure(err));
            });
    };
}

export function updateUserOverrideForDepartmentPreference(id, allowUserOverride) {
    return function (dispatch, getState) {
        const state = getState();
        const departmentPreference = departmentPreferenceByIdSelector(state)(id);

        const currentPermissionSet = get(departmentPreference, 'preferencePermissionSet', []);
        const updatedPermissionSet = allowUserOverride
            ? [...currentPermissionSet, PreferencePermissionEnum.USER.name]
            : without(currentPermissionSet, PreferencePermissionEnum.USER.name);

        const updated = {
            ...departmentPreference,
            preferencePermissionSet: updatedPermissionSet,
        };

        dispatch(upsertDepartmentPreferenceStart());
        return cadPreferenceResource
            .upsertDepartmentPreference(updated)
            .then((result) => {
                dispatch(storeDepartmentPreferences(result));
                dispatch(upsertDepartmentPreferenceSuccess(result));
            })
            .catch((err) => {
                dispatch(upsertDepartmentPreferenceFailure(err));
            });
    };
}

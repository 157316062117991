import { joinTruthyValues } from '../../../../helpers/stringHelpers';
import { isUndefinedOrNull } from '../../../../helpers/logicHelpers';
import { getViewModelProperties } from '../../../../helpers/viewModelHelpers';
import { canRead } from '../../entity-permissions/state/ui';

/**
 * For a inter-department link `toReportId` could be either this report or the other report.
 * We want the one that's not the current one.
 */
export const computeOtherReportId = ({ currentReportId, fromReportId, toReportId }) =>
    toReportId === currentReportId ? fromReportId : toReportId;

/**
 * We will use either an `ElasticReport` object or a `ReportShortTitle` object to build
 * our Report Report Links.  The difference is that newly added internal links
 * are added via quick search, which will give us an `ElasticReport` whereas existing
 * links will always be retrieved from the BE and stored as `ReportShortTitle`s
 * Ultimately, regardless of the means, we should end up with the same title.
 */
const buildReportObject = ({
    reportId,
    reportShortTitleViewModelByReportId,
    elasticReportById,
}) => {
    const reportShortTitleViewModel = reportShortTitleViewModelByReportId(reportId);
    const elasticReport = elasticReportById(reportId);

    // arbitrarily prioritize `ReportShortTitle`s ...
    if (!isUndefinedOrNull(reportShortTitleViewModel)) {
        const { shortTitleWithRen, canRead } = getViewModelProperties(reportShortTitleViewModel);
        return { shortTitleWithRen, canRead };
    } else if (!isUndefinedOrNull(elasticReport)) {
        const { ren, shortTitle, permissionSet } = elasticReport;
        return {
            shortTitleWithRen: joinTruthyValues([ren, shortTitle], ' '),
            canRead: canRead(permissionSet),
        };
    }
    // for safety ...
    return { shortTitleWithRen: '', canRead: undefined };
};

export const computeReportReportLinkTitle = ({
    reportId,
    reportShortTitleViewModelByReportId,
    elasticReportById,
}) => {
    const { shortTitleWithRen } = buildReportObject({
        reportId,
        reportShortTitleViewModelByReportId,
        elasticReportById,
    });
    return shortTitleWithRen;
};

export const computeCanReadReportReportLink = ({
    reportId,
    reportShortTitleViewModelByReportId,
    elasticReportById,
}) => {
    const { canRead } = buildReportObject({
        reportId,
        reportShortTitleViewModelByReportId,
        elasticReportById,
    });
    return !!canRead;
};

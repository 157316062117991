import { map, includes, flatMap } from 'lodash';
import { createSelector } from 'reselect';
import { AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import { latestCadTicket } from '../../../cad-tickets/utils/cadTicketsHelpers';
import { reportByIdSelector, EVENT_CARD_LOCATION_LINK_TYPES } from '../data';
import { eventDetailByReportIdSelector } from '../../../event-details/state/data';
import {
    reportAttributesWhereSelector,
    NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP,
} from '../../../report-attributes/state/data';
import {
    reportNotificationsWhereSelector,
    NEXUS_STATE_PROP as REPORT_NOTIFICATIONS_NEXUS_STATE_PROP,
} from '../../../report-notifications/state/data';
import {
    assistingOfficersWhereSelector,
    NEXUS_STATE_PROP as ASSISTING_OFFICERS_NEXUS_STATE_PROP,
} from '../../../assisting-officers/state/data';
import { paramedicsByReportIdSelector } from '../../../paramedics/state/data';
import { reportDefinitionByIdSelector } from '../../../report-definitions/state/data';
import { idFormatConfigurationByIdAndEntityTypeSelector } from '../../../id-format-configurations/state/data';
import { cadTicketsWhereSelector } from '../../../cad-tickets/state/data';
import { getDescriptionForAttributeLinks } from '../../../attributes/state/ui';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '../../../location-entity-links/state/data';

const REPORT_EVENT_DETAIL_ATTRIBUTES = [
    AttributeTypeEnum.WEATHER.name,
    AttributeTypeEnum.EVENT_STATISTICS.name,
];

export const hydratedEventDetailByReportIdSelector = createSelector(
    reportByIdSelector,
    eventDetailByReportIdSelector,
    reportAttributesWhereSelector,
    reportNotificationsWhereSelector,
    assistingOfficersWhereSelector,
    paramedicsByReportIdSelector,
    reportDefinitionByIdSelector,
    idFormatConfigurationByIdAndEntityTypeSelector,
    cadTicketsWhereSelector,
    (
        reportById,
        eventDetailByReportId,
        reportAttributesWhere,
        reportNotificationsWhere,
        assistingOfficersWhere,
        paramedicsByReportId,
        reportDefinitionById,
        idFormatConfigurationByIdAndEntityType,
        cadTicketsWhere
    ) => (reportId) => {
        const report = reportById(reportId);
        const eventDetail = eventDetailByReportId(reportId);

        const weatherReportAttributes = reportAttributesWhere({
            reportId,
            attributeType: AttributeTypeEnum.WEATHER.name,
        });
        const weatherAttrIds = map(weatherReportAttributes, 'attributeId');
        const weatherDescription = getDescriptionForAttributeLinks(weatherReportAttributes);
        const eventStatisticsReportAttributes = reportAttributesWhere({
            reportId,
            attributeType: AttributeTypeEnum.EVENT_STATISTICS.name,
        });
        const eventStatisticsAttrIds = map(eventStatisticsReportAttributes, 'attributeId');
        const eventStatisticsDescription = getDescriptionForAttributeLinks(
            eventStatisticsReportAttributes
        );

        const reportNotifications = reportNotificationsWhere({ reportId });
        const assistingPersonnel = assistingOfficersWhere({ reportId });
        const paramedics = paramedicsByReportId(reportId);

        const reportDefinition = reportDefinitionById(report.reportDefinitionId);
        const idFormatConfiguration = idFormatConfigurationByIdAndEntityType(
            reportDefinition.idFormatConfigurationId,
            EntityTypeEnum.CAD_AGENCY_EVENT.name
        );
        const cadTicketPredicate = !!idFormatConfiguration
            ? { cadAgencyEventNumber: report.recordNumber }
            : (cadTicket) => includes(cadTicket.reportingEventNumbers, report.reportingEventNumber);
        const cadTickets = cadTicketsWhere(cadTicketPredicate);
        const cadTicket = latestCadTicket(cadTickets);
        return {
            eventDetail,
            weatherAttrIds,
            weatherDescription,
            eventStatisticsAttrIds,
            eventStatisticsDescription,
            report,
            reportNotifications,
            assistingPersonnel,
            paramedics,
            cadTicket,
        };
    }
);

const reportEventDetailsAttributesFilter = (reportId) => {
    return (reportAttribute) => {
        return (
            reportAttribute.reportId === reportId &&
            includes(REPORT_EVENT_DETAIL_ATTRIBUTES, reportAttribute.attributeType)
        );
    };
};

const eventLocationEntityLinksFilter = (reportId) => {
    return (locationEntityLink) =>
        locationEntityLink.entityType === EntityTypeEnum.REPORT.name &&
        locationEntityLink.entityId === reportId &&
        includes(EVENT_CARD_LOCATION_LINK_TYPES, locationEntityLink.linkType);
};

export function replaceEventDetailCardBundle(eventDetailBundle) {
    return (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) => {
        const {
            reportAttributes,
            reportNotifications,
            assistingOfficers,
            eventDetail,
            locationViews,
        } = eventDetailBundle;

        const { reportId } = eventDetail;
        const locationEntityLinks = flatMap(locationViews, 'entityLinks');

        dispatch(
            withRemoveMultiple(
                {
                    [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportEventDetailsAttributesFilter(
                        reportId
                    ),
                    [REPORT_NOTIFICATIONS_NEXUS_STATE_PROP]: { reportId },
                    [ASSISTING_OFFICERS_NEXUS_STATE_PROP]: { reportId },
                    [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: eventLocationEntityLinksFilter(
                        reportId
                    ),
                },
                withEntityItems(
                    {
                        [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportAttributes,
                        [REPORT_NOTIFICATIONS_NEXUS_STATE_PROP]: reportNotifications,
                        [ASSISTING_OFFICERS_NEXUS_STATE_PROP]: assistingOfficers,
                        [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: locationEntityLinks,
                    },
                    { type: 'EVENT_DETAILS_SAVE_SUCCESS' }
                )
            )
        );
    };
}

import { filter, reject, some } from 'lodash';
import { ruleTypes } from 'arbiter-mark43';
import { formEvents } from 'markformythree';
import { rulesResultsToMFTValidationResult, formDataToArbiterDataMFT } from '../arbiter';

// arbiter produces rules results for _all_ field constraints, but if we are performing
// validation for only a single field, we only want to produce effects from field constraints
// for that field
function filterIrrelevantFieldConstraintResults(rulesResults, path) {
    return filter(rulesResults, (ruleResult) => {
        if (ruleResult.ruleView.rule.ruleType !== ruleTypes.FIELD_CONSTRAINT) {
            return true;
        } else if (
            some(ruleResult.ruleActionedFieldDescriptors, (rafd) => rafd.meta.path !== path)
        ) {
            return false;
        }
        return true;
    });
}

/**
 * The 15-character field constraint on phone number fields should only apply to + and digits, not to phone-formatting
 * characters (brackets, dashes, and spaces).
 * CHI-1930
 */
function filterPhoneNumberFieldConstraintResults(rulesResults) {
    return reject(rulesResults, (ruleResult) => {
        return (
            ruleResult.ruleView.rule.ruleType === ruleTypes.FIELD_CONSTRAINT &&
            !ruleResult.success &&
            some(ruleResult.ruleActionedFieldDescriptors, (rafd) => {
                return (
                    /(displayNumber|PhoneNumber)$/.test(rafd.meta.path) &&
                    rafd.value &&
                    rafd.value.replace(/[^\d\+]+/g, '').length <= 15
                );
            })
        );
    });
}

/**
 *
 * @return {Form['props']['onValidate']}
 */
export default function createArbiterMFTValidationHandler(arbiter, context, formatFieldByName) {
    return ({ configuration, path, getConfigurationForPath, eventType, fieldState, formState }) => {
        const rulesResultsToValidationResult = (rulesResults, eventType) => {
            let effectsConfiguration = {};

            // on form submit, we clear hidden fields during rule running
            if (eventType === formEvents.FORM_SUBMIT) {
                effectsConfiguration = {
                    forceFieldLevelEffects: true,
                    clearHiddenFields: true,
                };
            }

            const validationResult = rulesResultsToMFTValidationResult(
                arbiter.dataGetters,
                rulesResults,
                formState.ui,
                effectsConfiguration,
                formatFieldByName
            );
            return validationResult;
        };
        const arbiterData = formDataToArbiterDataMFT(formState.model, configuration);
        let rulesResults;
        let filteredRuleResults;
        let fieldName;
        let effects;
        let pathConfiguration;
        switch (eventType) {
            case formEvents.INPUT_CHANGE:
                pathConfiguration = getConfigurationForPath(path);
                fieldName = pathConfiguration && pathConfiguration.fieldName;
                if (fieldName) {
                    rulesResults = fieldState.ui.touched
                        ? arbiter.runRules(context, arbiterData, {
                              fieldName,
                          })
                        : arbiter.runRules(context, arbiterData, {
                              ruleTypes: [ruleTypes.HIDE],
                              fieldName,
                          });
                    filteredRuleResults = filterIrrelevantFieldConstraintResults(
                        rulesResults,
                        path
                    );
                    filteredRuleResults = filterPhoneNumberFieldConstraintResults(
                        filteredRuleResults
                    );
                    effects = rulesResultsToValidationResult(filteredRuleResults);
                    return effects;
                } else {
                    return {
                        formErrors: [],
                        do: {},
                    };
                }
            // there might be no fieldState object if the change event came from a
            // change to the entire form
            case formEvents.INPUT_BLUR:
                pathConfiguration = getConfigurationForPath(path);
                fieldName = pathConfiguration && pathConfiguration.fieldName;
                if (fieldName) {
                    rulesResults = arbiter.runRules(context, arbiterData, {
                        fieldName,
                    });
                    filteredRuleResults = filterIrrelevantFieldConstraintResults(
                        rulesResults,
                        path
                    );
                    filteredRuleResults = filterPhoneNumberFieldConstraintResults(
                        filteredRuleResults
                    );
                    effects = rulesResultsToValidationResult(filteredRuleResults);
                    return effects;
                } else {
                    return {
                        formErrors: [],
                        do: {},
                    };
                }
            case formEvents.FORM_MOUNT:
                // This dry run initializes arbiter's cache,
                // so it is a side effect and the result doesn't
                // need to be captured
                arbiter.runRules(context, arbiterData, {
                    dryRun: true,
                });
                rulesResults = arbiter.runRules(context, arbiterData, {
                    ruleTypes: [ruleTypes.HIDE],
                });
                rulesResults = filterPhoneNumberFieldConstraintResults(rulesResults);
                effects = rulesResultsToValidationResult(rulesResults, formEvents.FORM_MOUNT);
                return effects;
            case formEvents.FORM_SUBMIT:
                rulesResults = arbiter.runRules(context, arbiterData);
                rulesResults = filterPhoneNumberFieldConstraintResults(rulesResults);
                effects = rulesResultsToValidationResult(rulesResults, formEvents.FORM_SUBMIT);
                return effects;
            case formEvents.MODEL_CHANGE:
                rulesResults = arbiter.runRules(context, arbiterData, {
                    ruleTypes: [ruleTypes.HIDE],
                });
                rulesResults = filterPhoneNumberFieldConstraintResults(rulesResults);
                return rulesResultsToValidationResult(rulesResults);
            // We need to run validations on NItem add/remove or else validations
            // against NItem counts won't be triggered
            case formEvents.N_ITEM_ADDED:
            case formEvents.N_ITEM_REMOVED:
                rulesResults = arbiter.runRules(context, arbiterData, {
                    ruleTypes: [ruleTypes.HIDE, ruleTypes.VALIDATION],
                });
                rulesResults = filterPhoneNumberFieldConstraintResults(rulesResults);
                return rulesResultsToValidationResult(rulesResults);
            default:
                return undefined;
        }
    };
}

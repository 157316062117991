import React, { useState } from 'react';
import { CreatableSelect, SelectChangeEventHandler, OptionTypeBase } from 'arc';
import { simpleControl } from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';

import FormElement from '../FormElement';
import { computeInputStyle, InputStyles } from '../../helpers/inputStyles';

const EMAIL_VALIDATION_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const strings = componentStrings.exports.emails;

interface ExternalEmailsSelectOwnProps {
    label?: string;
    testId?: string;
}
type ExternalEmailsSelectProps = ExternalEmailsSelectOwnProps &
    React.ComponentProps<ReturnType<typeof simpleControl>>;

const mapValueToOptions = (emails: string[]) =>
    emails.map((email) => ({ label: email, value: email }));

const ExternalEmailsSelect: React.FC<ExternalEmailsSelectProps> = (props) => {
    const { value, touched, error, testId, onChange } = props;

    const [options, setOptions] = useState(value ? mapValueToOptions(value) : []);

    const handleOptionCreation = (input: string) => {
        setOptions([...options, { label: input, value: input }]);
        const newValue = Array.isArray(value) ? [...value, input] : [input];
        if (onChange) {
            onChange(newValue);
        }
    };

    const handleChange: SelectChangeEventHandler<OptionTypeBase, true> = (e) => {
        if (onChange) {
            onChange(e.target.value);
        }
    };

    const validateNewOption = (input: string) => EMAIL_VALIDATION_REGEXP.test(input);

    const inputStyle = computeInputStyle({
        isEmpty: Array.isArray(value) ? !!value.length : !!value,
        isTouched: touched,
        hasError: !!error,
    });

    return (
        <FormElement {...props} inputStyle={inputStyle}>
            <CreatableSelect
                options={options}
                value={value}
                isMultiple
                isInvalid={inputStyle === InputStyles.ERROR}
                menuPlacement="bottom"
                emptyState={strings.enterEmail}
                isValidNewOption={validateNewOption}
                onCreateOption={handleOptionCreation}
                onChange={handleChange}
                data-test-id={testId}
            />
        </FormElement>
    );
};

export const MFTExternalEmailsSelect = simpleControl(ExternalEmailsSelect);

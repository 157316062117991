import styled from 'styled-components';

const Column = styled.div(
    [],
    // using functions here instead of template string interpolations
    // to cut down the number of functions that have to be called
    // to 2 vs 9 or 10;
    (props) => ({
        backgroundColor:
            props.theme.colors[props.backgroundColor] || props.backgroundColor || 'transparent',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: props.direction || 'row',
        flex: `0 1 ${props.width || '100%'}`,
        padding: props.padding !== undefined ? props.padding : '15px 20px',
        borderTop: 'none',
        borderRight: props.borderRight || 'none',
        borderLeft: props.borderLeft || 'none',
        borderBottom: props.borderBottom || 'none',
        borderColor: props.theme.colors[props.borderColor] || props.borderColor || 'transparent',
        // IE flex padding bug fix
        // max-width forces the right box-sizing, whereas `display: flex`
        // apparently does not
        // This fix can cause issues where you want an element to grow
        // but luckily we are not in that situation here
        maxWidth: props.width || '100%',
    }),
    (props) => {
        if (!props.hoverBackgroundColor) {
            return undefined;
        }

        const backgroundColor =
            props.theme.colors[props.hoverBackgroundColor] ||
            props.hoverBackgroundColor ||
            'transparent';

        return {
            '&:hover': {
                backgroundColor,
            },
        };
    }
);

export default Column;

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';
import classNames from 'classnames';
import { max, map, at } from 'lodash';

import { useCaseFieldName, useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import OffenseLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/OffenseLocationCell';
import PrimaryLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/PrimaryLocationCell';
import BatchBanners from '../../batch-operations/components/BatchBanners';
import BulkManageCasesSidePanel from '../../core/components/BulkManageCasesSidePanel';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';

import ResultsHeader from '../../core/components/ResultsHeader';
import CasesResultsFooter from '../../core/components/CasesResultsFooter';
import CasesActionBar from '../../core/components/CasesActionBar';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import { ElasticResultRowWithTargetProfileDetails } from '../../core/components/ElasticResultRowWithTargetProfileDetails';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import YourInvolvementCell from '../../core/components/YourInvolvementCell';
import AssignedUnitCell from '../../core/components/AssignedUnitCell';
import CaseNumberNameCell from '../../core/components/CaseNumberNameCell';
import CaseTypeCell from '../../core/components/CaseTypeCell';
import CaseApprovalStatusCell from '../../core/components/CaseApprovalStatusCell';
import CaseStatusCell from '../../all-cases/components/CaseStatusCell';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { abilitiesEnum } from '../../../core/abilities';
import {
    openCaseDetailsModalFromDashboard,
    openCreateManageCaseModalFromDashboard,
} from '../../core/state/ui';
import { useDashboardScroll } from '../../../search/core/hooks/useDashboardScroll';
import { myCasesSearch } from '../state/ui';
import { iconTypes } from '../../../core/components/Icon';
import { Tooltip } from '../../../core/components/tooltip';
import CreateManageCaseModal from '../../core/components/CreateManageCaseModal';
import { exportsPageLoad } from '../../../../legacy-redux/actions/exportsActions';
import testIds from '../../../../core/testIds';
import MyCasesSearchForm from './MyCasesSearchForm';

const AssignedDateCell = dateCellFactory('assignedDateUtc');
const UpdatedDateCell = dateCellFactory('updatedDateUtc');
const CreatedDateCell = dateCellFactory('createdDateUtc');
const DueDateCell = dateCellFactory('dueDateUtc');

const strings = componentStrings.cases.myCases.MyCases;

function ActionButton({ disabled, disabledTooltipText, ...props }) {
    const button = <Button className={buttonTypes.SECONDARY} disabled={disabled} {...props} />;
    return disabled && disabledTooltipText ? (
        <Tooltip side="top" hasButtonOffset content={disabledTooltipText}>
            <div>{button}</div>
        </Tooltip>
    ) : (
        button
    );
}

function MyCases({
    // Current Query and Search Results
    results = [],
    query: { from, size, sortKey: currentSortKey, sortType: currentSortType },
    totalCount: totalResults,
    activeColumnKeys,
    applicationSettings,

    // Table UI State
    tableLoading,
    selectedRows,
    highlightedRows,
    allResultsSelected,

    // Event Handlers
    handleBulkRowSelect,
    handleRowClick,
    handleDateOptionClick,
    handleDateSortTypeClick,
    handleCaseInfoOptionClick,
    handlePersonnelOptionClick,
    handleLocationOptionClick,
    handlePaginationClick,
    handleSizeChange,
    handleSelectAllRows,
    handleClearAllSelectedRows,
    handleManageCaseClick,
    handleCaseLinkClick,
    handleExportClick,
    currentUserHasAbility,
    router,
}) {
    const {
        singularCaseFieldName: caseDisplayName,
        pluralCaseFieldName: casesDisplayName,
    } = useCaseFieldName();
    const offenseDisplayName = useOffenseFieldName();
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: myCasesSearch,
    });
    const currentPage = max([1, fromSizeToPage(from + 1, size)]);

    const manageCaseButton =
        selectedRows.length > 1 ? (
            <BulkManageCasesSidePanel
                caseIds={map(selectedRows, (r) => results[r].id)}
                renderButton={(open) => (
                    <Button
                        testId={testIds.ACTION_BAR_BULK_MANAGE_CASE_BUTTON}
                        className={buttonTypes.SECONDARY_BOLD}
                        onClick={open}
                    >
                        {strings.actionButtons.bulkManageCases(casesDisplayName)}
                    </Button>
                )}
            />
        ) : (
            <Button
                testId={testIds.ACTION_BAR_MANAGE_CASE_BUTTON}
                className={buttonTypes.SECONDARY_BOLD}
                onClick={handleManageCaseClick}
            >
                {strings.actionButtons.manageCase(caseDisplayName)}
            </Button>
        );

    const bulkExportCaseLimit = applicationSettings.RMS_BULK_EXPORT_CASE_LIMIT;

    const casesActionBarButtons = (
        <>
            {manageCaseButton}
            <FeatureFlagged flag="RMS_BULK_EXPORT_CASE_ENABLED">
                <ActionButton
                    iconLeft={iconTypes.EXPORT}
                    className={buttonTypes.SECONDARY}
                    onClick={handleExportClick}
                    disabled={bulkExportCaseLimit > 1 && selectedRows.length > bulkExportCaseLimit}
                    disabledTooltipText={strings.maxBulkCaseExportReached(caseDisplayName)}
                >
                    {strings.actionButtons.export}
                </ActionButton>
            </FeatureFlagged>
        </>
    );

    return (
        <>
            <ScrollableUnderSubheader>
                <div className={myCasesSearch.resultsContainerClassName}>
                    <BatchBanners />
                    <ResultsHeader
                        from={from}
                        to={from + results.length}
                        totalResults={totalResults}
                        onSizeChange={handleSizeChange}
                        SearchForm={MyCasesSearchForm}
                        searchModule={myCasesSearch}
                        isSearchSavable={true}
                        inlineWithTabs={false}
                    />
                    <Table
                        data={results}
                        onRowClick={handleRowClick(getCurrentScrollPosition)}
                        sortKey={currentSortKey}
                        sortType={currentSortType}
                        noRowsText={strings.noResults(casesDisplayName)}
                        disableBody={tableLoading}
                        selectableRows={currentUserHasAbility(abilitiesEnum.CASES.EDIT_GENERAL)}
                        selectableRow={false}
                        selectedRows={selectedRows}
                        highlightedRows={highlightedRows}
                        onSelectRow={handleBulkRowSelect}
                        containerClassName={classNames('search-results-table')}
                        rowComponent={ElasticResultRowWithTargetProfileDetails}
                        tableCheckboxMargin="10px"
                    >
                        <TableHeader>
                            <TableColumns>
                                <OptionsTableColumn
                                    display={({ display }) => display}
                                    columnKey={activeColumnKeys.date}
                                    activeValue={currentSortKey}
                                    activeSortType={currentSortType}
                                    onOptionClick={handleDateOptionClick}
                                    onSortTypeClick={handleDateSortTypeClick}
                                    width={169}
                                    popoutWidth={160}
                                >
                                    <OptionsTableColumnOption
                                        display={strings.columns.assignedDateUtc.label}
                                        value={sortKeyEnum.CASE_ASSIGNED_DATE_UTC}
                                        columnKey="assignedDateUtc"
                                        sortOptions={[
                                            {
                                                display:
                                                    strings.columns.assignedDateUtc.descendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                            },
                                            {
                                                display:
                                                    strings.columns.assignedDateUtc.ascendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                            },
                                        ]}
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.updatedDateUtc.label}
                                        value={sortKeyEnum.CASE_UPDATED_DATE_UTC}
                                        columnKey="updatedDateUtc"
                                        sortOptions={[
                                            {
                                                display:
                                                    strings.columns.updatedDateUtc.descendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                            },
                                            {
                                                display:
                                                    strings.columns.updatedDateUtc.ascendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                            },
                                        ]}
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.createdDateUtc.label}
                                        value={sortKeyEnum.CASE_CREATED_DATE_UTC}
                                        columnKey="createdDateUtc"
                                        sortOptions={[
                                            {
                                                display:
                                                    strings.columns.createdDateUtc.descendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                            },
                                            {
                                                display:
                                                    strings.columns.createdDateUtc.ascendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                            },
                                        ]}
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.dueDateUtc.label}
                                        value={sortKeyEnum.CASE_DUE_DATE_UTC}
                                        columnKey="dueDateUtc"
                                        sortOptions={[
                                            {
                                                display: strings.columns.dueDateUtc.descendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                            },
                                            {
                                                display: strings.columns.dueDateUtc.ascendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                            },
                                        ]}
                                    />
                                </OptionsTableColumn>
                                <OptionsTableColumn
                                    display={({ display }) => display}
                                    columnKey={activeColumnKeys.caseInfo}
                                    activeValue={activeColumnKeys.caseInfo}
                                    onOptionClick={handleCaseInfoOptionClick}
                                    width={205}
                                    popoutWidth={205}
                                >
                                    <OptionsTableColumnOption
                                        display={strings.columns.caseNumberName.label(
                                            caseDisplayName
                                        )}
                                        value="caseNumberName"
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.caseType.label(caseDisplayName)}
                                        value="caseType"
                                    />
                                </OptionsTableColumn>
                                <OptionsTableColumn
                                    display={({ display }) => display}
                                    columnKey={activeColumnKeys.personnel}
                                    activeValue={activeColumnKeys.personnel}
                                    onOptionClick={handlePersonnelOptionClick}
                                    width={204}
                                    popoutWidth={204}
                                >
                                    <OptionsTableColumnOption
                                        display={strings.columns.yourInvolvement.label}
                                        value="yourInvolvement"
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.assignedUnit.label}
                                        value="assignedUnit"
                                    />
                                </OptionsTableColumn>
                                <OptionsTableColumn
                                    display={({ display }) => display}
                                    columnKey={activeColumnKeys.location}
                                    activeValue={activeColumnKeys.location}
                                    onOptionClick={handleLocationOptionClick}
                                    width={132}
                                    popoutWidth={170}
                                >
                                    <OptionsTableColumnOption
                                        display={strings.columns.primaryLocation.label}
                                        value="primaryLocation"
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.offenseLocation.label(
                                            offenseDisplayName
                                        )}
                                        value="offenseLocation"
                                    />
                                </OptionsTableColumn>
                                <TableColumn
                                    display={strings.columns.caseStatus.label(caseDisplayName)}
                                    columnKey="caseStatus"
                                    width={140}
                                />
                                <TableColumn
                                    display={strings.columns.caseApprovalStatus.label}
                                    columnKey="caseApprovalStatus"
                                    width={70}
                                />
                            </TableColumns>
                        </TableHeader>
                        <TableBody>
                            <AssignedDateCell columnKey="assignedDateUtc" />
                            <UpdatedDateCell columnKey="updatedDateUtc" />
                            <CreatedDateCell columnKey="createdDateUtc" />
                            <DueDateCell columnKey="dueDateUtc" />
                            <CaseNumberNameCell
                                columnKey="caseNumberName"
                                handleCaseLinkClick={handleCaseLinkClick(getCurrentScrollPosition)}
                            />
                            <CaseTypeCell columnKey="caseType" />
                            <YourInvolvementCell columnKey="yourInvolvement" />
                            <AssignedUnitCell columnKey="assignedUnit" />
                            <PrimaryLocationCell columnKey="primaryLocation" />
                            <OffenseLocationCell columnKey="offenseLocation" />
                            <CaseApprovalStatusCell columnKey="caseApprovalStatus" />
                            <CaseStatusCell columnKey="caseStatus" />
                        </TableBody>
                    </Table>
                    {totalResults > 0 && (
                        <CasesResultsFooter
                            totalResults={totalResults}
                            searchSize={size}
                            currentPage={currentPage}
                            handlePaginationClick={handlePaginationClick}
                        />
                    )}

                    <CreateManageCaseModal
                        title={componentStrings.cases.core.CaseHeader.manageCase(caseDisplayName)}
                        okText={strings.actionButtons.save}
                        isBulkCreate={false}
                        includeLocalIdInTitle={true}
                    />
                </div>
            </ScrollableUnderSubheader>
            <CasesActionBar
                totalResultCount={totalResults}
                selectedResultCount={selectedRows.length}
                buttons={casesActionBarButtons}
                allResultsSelected={allResultsSelected}
                onClickSelectAll={handleSelectAllRows}
                onClickClearSelection={handleClearAllSelectedRows}
                visible={selectedRows.length > 0}
            />
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    // table ui state
    query: myCasesSearch.selectors.currentQuerySelector,
    results: myCasesSearch.selectors.currentResultsViewModelsSelector,
    highlightedRows: myCasesSearch.selectors.highlightedRowsSelector,
    totalCount: myCasesSearch.selectors.totalCountSelector,
    tableLoading: myCasesSearch.selectors.tableLoadingSelector,
    activeColumnKeys: myCasesSearch.selectors.activeColumnKeysSelector,
    // saved search state
    savedSearchErrorMessage: myCasesSearch.selectors.loadSavedSearchErrorMessageSelector,
    // state needed for the assign bar
    selectedRows: myCasesSearch.selectors.selectedRowsSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    applicationSettings: applicationSettingsSelector,
    allResultsSelected: myCasesSearch.selectors.allResultsSelectedSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleRowSelect(selectedRow) {
        dispatch(
            myCasesSearch.actionCreators.selectRows(
                !isUndefinedOrNull(selectedRow) ? [selectedRow] : []
            )
        );
    },
    handleBulkRowSelect(newSelectedRows) {
        dispatch(myCasesSearch.actionCreators.selectRows(newSelectedRows));
    },
    handleRowClick(getCurrentScrollPosition) {
        return (elasticCase, rowIndex) => {
            dispatch(
                myCasesSearch.actionCreators.openSearchResult(
                    elasticCase,
                    rowIndex,
                    router,
                    getCurrentScrollPosition()
                )
            );
        };
    },
    handleDateOptionClick({ columnKey, value, sortOptions }) {
        dispatch(myCasesSearch.actionCreators.setActiveColumnKey('date', columnKey));
        dispatch(
            myCasesSearch.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleDateSortTypeClick(sortType) {
        dispatch(myCasesSearch.actionCreators.search({ sortType }));
    },
    handleCaseInfoOptionClick({ value }) {
        dispatch(myCasesSearch.actionCreators.setActiveColumnKey('caseInfo', value));
    },
    handlePersonnelOptionClick({ value }) {
        dispatch(myCasesSearch.actionCreators.setActiveColumnKey('personnel', value));
    },
    handleLocationOptionClick({ value }) {
        dispatch(myCasesSearch.actionCreators.setActiveColumnKey('location', value));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            myCasesSearch.actionCreators.search(
                {
                    from: nextPage * size - size,
                    size,
                },
                { cacheBust: true }
            )
        );
    },
    handleSizeChange(size) {
        dispatch(
            myCasesSearch.actionCreators.search({
                from: 0,
                size,
            })
        );
    },
    handleAssignClick(elasticCase) {
        dispatch(openCaseDetailsModalFromDashboard(elasticCase.id));
    },
    handleManageCaseClick(elasticCase) {
        dispatch(openCreateManageCaseModalFromDashboard(elasticCase.id));
    },
    handleSelectAllRows() {
        dispatch(myCasesSearch.actionCreators.selectAllResults());
    },
    handleClearAllSelectedRows() {
        dispatch(myCasesSearch.actionCreators.selectRows([]));
    },
    handleCaseLinkClick(getCurrentScrollPosition) {
        return (event) => {
            event.stopPropagation();
            dispatch(myCasesSearch.actionCreators.setScrollPosition(getCurrentScrollPosition()));
        };
    },
    handleExportClick(selectedRows, results) {
        const caseIds = map(at(results, selectedRows), 'id');
        if (caseIds.length === 1) {
            dispatch(exportsPageLoad());
            router.push(`/cases/${caseIds[0]}/exports`);
        } else {
            dispatch(myCasesSearch.actionCreators.loadSearchExportPrintables(caseIds)).then(() =>
                router.push('/cases/mine/exports')
            );
        }
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        handlePaginationClick({ handlePaginationClick, query: { size } }) {
            return (lastPage, nextPage) => handlePaginationClick(nextPage, size);
        },
        handleAssignClick({ handleAssignClick, selectedRows, results }) {
            return () => handleAssignClick(results[selectedRows[0]]);
        },
        handleManageCaseClick({ handleManageCaseClick, selectedRows, results }) {
            return () => handleManageCaseClick(results[selectedRows[0]]);
        },
        handleExportClick({ handleExportClick, selectedRows, results }) {
            return () => handleExportClick(selectedRows, results);
        },
    })
)(MyCases);

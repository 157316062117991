import { LinkTypesEnum, AttributeTypeEnum } from '@mark43/rms-api';
import _, { get, findKey } from 'lodash';
import Promise from 'bluebird';

import { normalize } from '~/client-common/helpers/dataHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import resource from '../resources/attributesAdminResource';
import {
    attributeByIdSelector,
    attributeSelector,
    codeMappingsByAttributeTypeSelector,
    codeLinksByAttributeIdSelector,
    codeLinksByAttributeTypeSelector,
    codeLinkFormDataSelector,
    linkTypeByAttributeIdSelector,
} from '../selectors/attributesAdminSelectors';
import { Mark43Error } from '../../lib/errors';

import actionTypes from './types/attributesAdminActionTypes';

const strings = componentStrings.admin.attributes;

function pageLoadStart() {
    return {
        type: actionTypes.PAGE_LOAD_START,
    };
}

function pageLoadSuccess(attributeTypes, attributes) {
    return {
        type: actionTypes.PAGE_LOAD_SUCCESS,
        payload: {
            attributeTypes: normalize(attributeTypes, 'attributeType'),
            attributes: normalize(attributes),
        },
    };
}

function pageLoadError() {
    return {
        type: actionTypes.PAGE_LOAD_ERROR,
    };
}

function fetchAttributeTypeHistoryStart() {
    return {
        type: actionTypes.FETCH_ATTRIBUTE_TYPE_HISTORY_START,
    };
}

function fetchAttributeTypeHistorySuccess(attributeType, histories) {
    return {
        type: actionTypes.FETCH_ATTRIBUTE_TYPE_HISTORY_SUCCESS,
        payload: { attributeType, histories },
    };
}

function fetchAttributeTypeHistoryFailure(attributeType, err) {
    return {
        type: actionTypes.FETCH_ATTRIBUTE_TYPE_HISTORY_FAILURE,
        payload: { attributeType, err },
    };
}

export function toggleExpiredAttributes() {
    return {
        type: actionTypes.TOGGLE_EXPIRED_ATTRIBUTES,
    };
}

export function pageLoad() {
    return function (dispatch) {
        dispatch(pageLoadStart());
        return Promise.all([resource.getAttributeTypes(), resource.getAllAttributes()])
            .spread((...args) => dispatch(pageLoadSuccess(...args)))
            .catch(() => dispatch(pageLoadError()));
    };
}

function selectAttributeTypeStart(attributeType) {
    return {
        type: actionTypes.SELECT_ATTRIBUTE_TYPE_START,
        payload: attributeType,
    };
}

function selectAttributeTypeSuccess(attributeType, codeMappings) {
    return {
        type: actionTypes.SELECT_ATTRIBUTE_TYPE_SUCCESS,
        payload: { attributeType, codeMappings },
    };
}

function saveAttributeTypeLinkTypes(attributeType, linkTypes) {
    return {
        type: actionTypes.SAVE_ATTRIBUTE_TYPE_LINK_TYPES,
        payload: { attributeType, linkTypes },
    };
}

function selectAttributeTypeFailure() {
    return {
        type: actionTypes.SELECT_ATTRIBUTE_TYPE_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to get attribute type'),
    };
}

// wrap around the selector to return cached data when available
function getCodeMappingsByAttributeType(state, type, forceRefresh) {
    if (!type) {
        return Promise.resolve([]);
    }

    const codeMappings = codeMappingsByAttributeTypeSelector(state)(type);

    return codeMappings && !forceRefresh
        ? Promise.resolve(codeMappings)
        : resource.getAttributeTypeMappings(type);
}

// `type` is a string
export function selectAttributeType(type, forceRefresh) {
    return function (dispatch, getState) {
        dispatch(selectAttributeTypeStart(type));
        return getCodeMappingsByAttributeType(getState(), type, forceRefresh)
            .then((mappings) => {
                dispatch(saveAttributeTypeLinkTypes(type, mappings.linkTypes));
                return mappings;
            })
            .then((mappings) => {
                return dispatch(selectAttributeTypeSuccess(type, mappings));
            })
            .catch(() => dispatch(selectAttributeTypeFailure()));
    };
}

export function fetchAttributeTypeHistory(type) {
    return function (dispatch) {
        dispatch(selectAttributeTypeStart(type));
        dispatch(fetchAttributeTypeHistoryStart());
        return resource
            .getAttributeTypeHistory(type)
            .then((response) => {
                dispatch(fetchAttributeTypeHistorySuccess(type, response));
            })
            .catch((err) => {
                dispatch(fetchAttributeTypeHistoryFailure(type, err));
            });
    };
}

function selectAttributeTypeOptionStart(attributeType) {
    return {
        type: actionTypes.SELECT_ATTRIBUTE_TYPE_OPTION_START,
        payload: attributeType,
    };
}

function selectAttributeTypeOptionSuccess(attributeType, codeMappings, codeLinks) {
    return {
        type: actionTypes.SELECT_ATTRIBUTE_TYPE_OPTION_SUCCESS,
        payload: { attributeType, codeMappings, codeLinks },
    };
}

function selectAttributeTypeOptionFailure() {
    return {
        type: actionTypes.SELECT_ATTRIBUTE_TYPE_OPTION_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to get attribute type'),
    };
}

// on selecting a type for a new attribute, we need to get the type's code
// mappings in order to compute the code links to put in the form
export function selectAttributeTypeOption(type) {
    return function (dispatch, getState) {
        dispatch(selectAttributeTypeOptionStart(type));
        return getCodeMappingsByAttributeType(getState(), type)
            .then((codeMappings) => {
                return dispatch(
                    selectAttributeTypeOptionSuccess(
                        type,
                        codeMappings,
                        codeLinksByAttributeTypeSelector(getState())(type, codeMappings)
                    )
                );
            })
            .catch(() => dispatch(selectAttributeTypeOptionFailure()));
    };
}

function selectAttributeStart(id) {
    return {
        type: actionTypes.SELECT_ATTRIBUTE_START,
        payload: id,
    };
}

// `attribute` can be `null` or `undefined` to select no attribute
function selectAttributeSuccess(attribute, codeLinks, linkType) {
    return {
        type: actionTypes.SELECT_ATTRIBUTE_SUCCESS,
        payload: { attribute, codeLinks, linkType },
    };
}

function selectAttributeFailure(err) {
    return {
        type: actionTypes.SELECT_ATTRIBUTE_FAILURE,
        payload: err,
    };
}

/**
 * Select an attribute in the attributes admin list. Pass in a falsey id to
 *   clear the current selection.
 * @param  {number} [id]
 */
export function selectAttributeById(id) {
    return function (dispatch, getState) {
        dispatch(selectAttributeStart(id));

        if (!id) {
            // clear selection
            return dispatch(selectAttributeSuccess());
        }

        const state = getState();
        const attribute = attributeByIdSelector(state)(id);

        if (attribute) {
            // get code links as well because they are part of the form
            const codeLinks = codeLinksByAttributeIdSelector(state)(id);
            const linkType = linkTypeByAttributeIdSelector(state)(id);

            return dispatch(selectAttributeSuccess(attribute, codeLinks, linkType));
        }

        // TODO: remove this crappy interval when migrating the router, this is
        // a workaround for onEnter/onLeave hooks getting called twice each
        let attempts = 0;
        const intervalId = window.setInterval(() => {
            // retry
            const state = getState();
            const attribute = attributeByIdSelector(state)(id);

            if (attribute) {
                // success, stop the interval
                window.clearInterval(intervalId);

                // get code links as well because they are part of the form
                const codeLinks = codeLinksByAttributeIdSelector(state)(id);
                const linkType = linkTypeByAttributeIdSelector(state)(id);
                return dispatch(selectAttributeSuccess(attribute, codeLinks, linkType));
            }

            attempts++;
            if (attempts > 300) {
                // give up, stop the interval and show an error
                window.clearInterval(intervalId);

                const err = new Mark43Error(strings.selectAttributeError);
                return dispatch(selectAttributeFailure(err));
            }
        }, 1000);
    };
}

export function openNewAttributeForm() {
    return {
        type: actionTypes.OPEN_NEW_ATTRIBUTE_FORM,
    };
}

// for a new attribute, the code links do not yet have `attributeId` at this
// point
function saveAttributeStart(attribute, codeLinks) {
    return {
        type: actionTypes.SAVE_ATTRIBUTE_START,
        payload: { attribute, codeLinks },
    };
}

function saveAttributeSuccess(attribute, codeLinks) {
    return {
        type: actionTypes.SAVE_ATTRIBUTE_SUCCESS,
        payload: { attribute, codeLinks },
    };
}

function saveAttributeFailure(errorMessage) {
    return {
        type: actionTypes.SAVE_ATTRIBUTE_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

// either create a new attribute or save an existing attribute (if it has an
// id); this involves saving the attribute's code links as well, which works the
// same way for both new and existing attributes
export function saveAttribute(formData, attributeId) {
    return function (dispatch, getState) {
        const state = getState();
        const attributeById = attributeSelector(state);
        const selectedAttribute = attributeById(attributeId);

        // We have to make sure that we always grab the correct type for the parent attribute
        // in case incorrect data made it into the system via CSV import. If we don't
        // send down the correct attribute type, the BE will not be able to save the
        // attribute
        const parentAttributeId =
            formData.parentAttributeId || get(selectedAttribute, 'parentAttributeId');
        const parentAttributeType = parentAttributeId
            ? attributeById(parentAttributeId).attributeType
            : undefined;

        const attribute = {
            ...selectedAttribute,
            ...formData,
            parentAttributeType,
        };

        const applicationSettings = applicationSettingsSelector(state);
        let codeLinks = codeLinkFormDataSelector(state);

        dispatch(saveAttributeStart(attribute, codeLinks));

        const method = attribute.id ? 'updateAttribute' : 'createAttribute';

        const linkTypeKey = applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED
            ? findKey(LinkTypesEnum, (linkType) => linkType === formData.linkType)
            : undefined;

        return resource[method](attribute, linkTypeKey)
            .then((updatedAttribute) => {
                // update code links in a separate request
                codeLinks = _.map(codeLinks, (link) => ({
                    ...link,
                    // for a new attribute, its code links need to have the new
                    // attribute id in order to establish the relationship
                    attributeId: updatedAttribute.id,
                    // default `null` since the field can be emptied
                    codeId: link.codeId || null,
                }));

                return [
                    updatedAttribute,
                    resource.updateAttributeCodeLinks(updatedAttribute, codeLinks),
                ];
            })
            .spread((updatedAttribute, updatedCodeLinks) => {
                // success
                dispatch(saveAttributeSuccess(updatedAttribute, updatedCodeLinks));
                return updatedAttribute;
            })
            .then((updatedAttribute) => {
                dispatch(
                    selectAttributeType(
                        updatedAttribute.attributeType,
                        updatedAttribute.attributeType ===
                            AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE.name &&
                            applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED
                    )
                );
            })
            .catch((err) => dispatch(saveAttributeFailure(err.message)));
    };
}

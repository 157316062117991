import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { dojSchoolsOptionsSelector } from '~/client-common/core/domain/doj-schools/state/ui';
import { searchForDojSchools } from '~/client-common/core/domain/doj-schools/state/data';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { arbiterMFTInput } from '../../../arbiter';
import AsyncSelect from './AsyncSelect';

const DOJSchoolSelect: React.FC = (props) => {
    const dojSchoolsOptions = useSelector(dojSchoolsOptionsSelector);

    const dispatch: RmsDispatch = useDispatch();
    const asyncAction = React.useCallback(
        (q: string) => {
            return dispatch(
                searchForDojSchools({
                    q,
                    from: 0,
                    size: 20,
                })
            );
        },
        [dispatch]
    );

    return <AsyncSelect asyncAction={asyncAction} options={dojSchoolsOptions} {...props} />;
};

export const ArbiterMFTDOJSchoolSelect = arbiterMFTInput(DOJSchoolSelect);

export const getShapefileProperties = ({
    shapefileId,
    propertiesById,
}: {
    shapefileId: number;
    propertiesById: Record<number, unknown[]>;
}) => {
    const properties = propertiesById[shapefileId];

    if (!properties) {
        return;
    }

    return properties.map((property) =>
        typeof property === 'number' ? String(property) : property
    );
};

import keyMirror from 'keymirror';

/**
 * Must match server enum {@link
 *   cobalt-rms/search/search-common/src/main/java/mark43/search/model/ElasticCobaltSortType.java},
 *   except for the default `RELEVANCE`.
 * @type {Object}
 */
export default {
    RELEVANCE: undefined,
    ...keyMirror({
        // Using undefined to represent relevance works but is deprecated
        // Use this key for relevance searches from now on
        RELEVANCE_MOST_TO_LEAST: null,
        RELEVANCE_LEAST_TO_MOST: null,
        DATE_MOST_RECENT_TO_LEAST_RECENT: null,
        DATE_LEAST_RECENT_TO_MOST_RECENT: null,
        ALPHABETICAL_A_TO_Z: null,
        ALPHABETICAL_Z_TO_A: null,
        AGE_YOUNGEST_TO_OLDEST: null,
        AGE_OLDEST_TO_YOUNGEST: null,
        PRIORITY_HIGHEST_TO_LOWEST: null,
        PRIORITY_LOWEST_TO_HIGHEST: null,
        NUMBER_LOWEST_TO_HIGHEST: null,
        NUMBER_HIGHEST_TO_LOWEST: null,
        ACTIVE_TO_INACTIVE: null,
    }),
};

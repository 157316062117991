import { TrafficCrashVehicle } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'trafficCrashVehicles';
const trafficCrashVehicleModule = createNormalizedModule<TrafficCrashVehicle>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS
export const trafficCrashVehiclesSelector = trafficCrashVehicleModule.selectors.entitiesSelector;

// REDUCER
export default trafficCrashVehicleModule.reducerConfig;

import React from 'react';
import classNames from 'classnames';

const Row: React.FC<{ className?: string; testId?: string; style?: React.CSSProperties }> = ({
    className,
    style,
    children,
    testId,
    ...props
}) => {
    return (
        <div
            className={classNames('mark43-row clearfix', className)}
            style={style}
            data-test-id={testId}
            {...props}
        >
            {children}
        </div>
    );
};

export default Row;

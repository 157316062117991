import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { EventDetail } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { agencyProfileByIdSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import { reportViewModelByIdSelector } from '~/client-common/core/domain/reports/state/ui';
import testIds from '../../../core/testIds';
import { useDateTimeFormatter } from '../../core/current-user/hooks/dateTimeFormats';

const StyledEventDetailCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 12px 12px 24px;
    font-size: var(--arc-fontSizes-md);

    /* light/surface/surface-4 */
    background: ${(props) => props.theme.colors.lightBlue};
    border-radius: 6px;
`;

const StyledEventTitleColumn = styled.td`
    line-height: 18px;
    font-size: var(--arc-fontSizes-md);
    white-space: nowrap;
    padding-bottom: 10px;
    vertical-align: text-top;
`;

const StyledEventDetailColumn = styled.td`
    line-height: 18px;
    font-size: var(--arc-fontSizes-md);
    padding-bottom: 10px;
    vertical-align: text-top;
    padding-left: 10px;
`;

const strings = componentStrings.clips.ClipsReportEventDetailSidebar;

const useAgencyName = (reportId?: number): string | undefined => {
    const reportViewModelById = useSelector(reportViewModelByIdSelector);
    const agencyProfileById = useSelector(agencyProfileByIdSelector);
    const reportViewModel = reportViewModelById(reportId || 0);
    // @ts-expect-error todo: update reportViewModelByIdSelector to return undefined instead of {}
    const agencyProfile = agencyProfileById(reportViewModel?.agencyId);

    return agencyProfile?.agencyName;
};

const ClipsReportEventDetailSidebar: React.FC<{
    eventDetailEntities: EventDetail;
    eventDetailViewProperties: EventDetail;
    ren: string | undefined;
}> = ({ eventDetailEntities, eventDetailViewProperties, ren }) => {
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const dateTimeFormatter = useDateTimeFormatter();
    const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
    const renString = renDisplayName ? `${renDisplayName}:` : strings.renSubtitle;
    const renField = ren || strings.notAvailable;
    let createdDate: string = strings.notAvailable;
    let agency: string | number = strings.notAvailable;
    let eventStart: string = strings.notAvailable;
    let reporter: string | number = strings.notAvailable;

    const agencyName = useAgencyName(eventDetailEntities?.reportId);

    // check for createdDateUtc property, indicates eventDetailEntities is not an empty object
    if (eventDetailEntities.createdDateUtc) {
        createdDate = dateTimeFormatter.formatSummaryDateTime(eventDetailEntities.createdDateUtc);
        // handle optional event details fields
        agency = agencyName || strings.notAvailable;
        eventStart =
            dateTimeFormatter.formatSummaryDateTime(eventDetailEntities.eventStartUtc) ||
            strings.notAvailable;
        reporter = eventDetailViewProperties.respondingOfficerId || strings.notAvailable;
    }

    const eventData = [
        { title: strings.dateSubtitle, value: createdDate },
        { title: strings.agencySubtitle, value: agency },
        { title: strings.eventSubtitle, value: eventStart },
        { title: strings.reporterSubtitle, value: reporter },
        { title: renString, value: renField },
    ];

    return (
        <StyledEventDetailCard>
            <p>
                <strong>{strings.title}</strong>
            </p>
            <table data-test-id={testIds.CLIPS_EVENT_INFO}>
                {eventData.map((ed) => (
                    <tr key={ed.title}>
                        <StyledEventTitleColumn>{ed.title}</StyledEventTitleColumn>
                        <StyledEventDetailColumn>{ed.value}</StyledEventDetailColumn>
                    </tr>
                ))}
            </table>
        </StyledEventDetailCard>
    );
};

export default ClipsReportEventDetailSidebar;

import React from 'react';
import { useSelector } from 'react-redux';
import AbilityBase from '~/client-common/core/domain/abilities/components/AbilityBase';
import { currentUserIdSelector } from '../../current-user/state/ui';

type AbilityProps = { ability: number; children: React.ReactNode };

const Ability = ({ ability, children }: AbilityProps) => {
    const currentUserId = useSelector(currentUserIdSelector);

    return (
        <AbilityBase userId={currentUserId} ability={ability}>
            {children}
        </AbilityBase>
    );
};

export default Ability;

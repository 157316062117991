import keyMirror from 'keymirror';

/**
 * Client-only enum for range form field keys.
 * @see  https://readme.mark43.io/guides/products/rms/rms-frontend/rmsfe-form-modules/#form-field-view-models
 */
export default keyMirror({
    AGE_RANGE: null,
    DATE_TIME_RANGE: null,
    TIME_RANGE: null,
    HEIGHT_RANGE: null,
    VEHICLE_YEAR_RANGE: null,
    WEIGHT_RANGE: null,
    YEARS_OF_SERVICE_RANGE: null,
});

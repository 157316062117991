// TODO PRINTING-REFACTOR evalute the "DI" pattern used here

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Expungement Resource',
        methods: {
            vacateCharges(vacateInfo) {
                return req({
                    method: 'POST',
                    url: 'reporting/expungement/vacate',
                    data: vacateInfo,
                });
            },
            loadVacateInfoForReportId(reportId) {
                return req({
                    method: 'GET',
                    url: `reporting/expungement/vacate/${reportId}`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import { EntityTypeEnum } from '@mark43/rms-api';
import _, { chain, get } from 'lodash';

import { createSelector } from 'reselect';
import { chainEventsSelector, chainEventsWhereSelector, itemHasChainEventsSelector } from '../data';
import { chainOfCustodiesSelector } from '../../../chain-of-custodies/state/data';
import { storageLocationViewModelByIdSelector } from '../../../storage-locations/state/ui';
import { formatFacilityByIdSelector } from '../../../facilities/state/ui';

import {
    chainEventTypesSelector,
    checkedInTempChainEventTypeSelector,
    infieldTransferChainEventTypeSelector,
    inPoliceCustodyChainEventTypeSelector,
} from '../../../chain-event-types/state/data';
import { formatChainEventTypeByIdSelector } from '../../../chain-event-types/state/ui';
import { attachmentViewModelsWhereSelector } from '../../../attachments/state/ui';

import {
    buildViewModel,
    buildAllMiniUserFormatsMapper,
} from '../../../../../helpers/viewModelHelpers';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';

/*
 * The chain event view model for a chain event id
 * @param {id} The id of the chain event
 * @return {Array[ChainEventViewModels]} An array of chain event view models
 */

const chainEventViewModelByIdSelector = createSelector(
    chainOfCustodiesSelector,
    chainEventTypesSelector,
    chainEventsSelector,
    storageLocationViewModelByIdSelector,
    allMiniUserFormatsByIdSelector,
    formatChainEventTypeByIdSelector,
    attachmentViewModelsWhereSelector,
    formatFacilityByIdSelector,
    (
            chainOfCustodies,
            chainEventTypes,
            chainEvents,
            storageLocationViewModelById,
            allMiniUserFormatsById,
            formatChainEventTypeById,
            attachmentViewModelsWhere,
            formatFacilityById
        ) =>
        (id) => {
            const chainEvent = chainEvents[id];
            const buildChainEventViewModel = buildViewModel({
                mappers: [
                    buildAllMiniUserFormatsMapper('receivedByEntityId'),
                    ({ chainOfCustodyId }) => {
                        const chainOfCustody = chainOfCustodies[chainOfCustodyId] || {};
                        return {
                            masterItemId: chainOfCustody.masterItemId,
                            reportId: chainOfCustody.reportId,
                        };
                    },
                    ({ eventTypeId, facilityId, storageLocationId }) => {
                        const storageLocation = storageLocationViewModelById(storageLocationId);
                        return {
                            // tech debt: these 2 chain event type properties
                            // should not be separate or live on this view model
                            // in the first place
                            eventType: chainEventTypes[eventTypeId],
                            eventTypeDisplay: formatChainEventTypeById(eventTypeId),
                            attachments: attachmentViewModelsWhere({
                                entityType: EntityTypeEnum.CHAIN_EVENT.name,
                                entityId: chainEvent.id,
                            }),
                            facility: formatFacilityById(
                                facilityId || _.get(storageLocation, 'facilityId')
                            ),
                            storageLocation,
                        };
                    },
                ],
                helpers: {
                    allMiniUserFormatsById,
                },
            });
            return buildChainEventViewModel(chainEvent);
        }
);

/**
 * Sorted chain event view models on the given chain of custody. This is useful
 *   over the data selector `chainEventsSelector` because it's sorted and
 *   contains view model properties. TODO: Split out a selector that returns
 *   only the latest one.
 * @param  {number} chainOfCustodyId
 * @return {Object[]}
 */
export const chainEventViewModelsForChainOfCustodyIdSelector = createSelector(
    chainEventsWhereSelector,
    chainEventViewModelByIdSelector,
    (chainEventsWhere, chainEventViewModelById) => (chainOfCustodyId) => {
        const chainEvents = chainEventsWhere({ chainOfCustodyId });
        return _(chainEvents)
            .orderBy(['eventDateUtc', 'id'], ['desc', 'desc'])
            .map((chainEvent) => chainEventViewModelById(chainEvent.id))
            .value();
    }
);

/*
 * Sorted chain event view models for the given master item. If the master item has multiple chains of custody, all
 *   their chain events are returned together,
 *
 * 1. first sorted by chain of custody created date desc (so that each CoC's events stay together),
 * 2. then by chain event date desc (a typical CoC will only sort by these dates alone),
 * 3. then by id desc (because it's technically possible for multiple chain events to share the same date, even though
 *    they shouldn't from the workflow perspective).
 */
export const chainEventViewModelsForMasterItemIdSelector = createSelector(
    chainOfCustodiesSelector,
    chainEventsSelector,
    chainEventViewModelByIdSelector,
    (chainOfCustodies, chainEvents, chainEventViewModelById) => (masterItemId) => {
        return _(chainEvents)
            .filter(({ chainOfCustodyId }) => {
                return masterItemId === get(chainOfCustodies[chainOfCustodyId], 'masterItemId');
            })
            .orderBy(
                [
                    ({ chainOfCustodyId }) =>
                        get(chainOfCustodies[chainOfCustodyId], 'createdDateUtc'),
                    'eventDateUtc',
                    'id',
                ],
                ['desc', 'desc', 'desc']
            )
            .map((chainEvent) => chainEventViewModelById(chainEvent.id))
            .value();
    }
);

/**
 * Returns true, if the latest chain of custody event was CHECKED_IN_TEMP
 * @type {boolean}
 */
const wasLastCheckedInTempForMasterItemSelector = createSelector(
    chainEventViewModelsForMasterItemIdSelector,
    checkedInTempChainEventTypeSelector,
    (chainEventViewModelsForMasterItemId, { id: checkedInTempEventTypeId }) =>
        (masterItemId) =>
            chain(masterItemId)
                .thru(chainEventViewModelsForMasterItemId)
                .head()
                .get('eventTypeId')
                .value() === checkedInTempEventTypeId
);

/**
 * Returns true, if the latest chain of custody event was IN_POLICE_CUSTODY
 * @type {boolean}
 */
const wasLastInPoliceCustodyForMasterItemSelector = createSelector(
    chainEventViewModelsForMasterItemIdSelector,
    inPoliceCustodyChainEventTypeSelector,
    (chainEventViewModelsForMasterItemId, inPoliceCustodyChainEventType) => (masterItemId) => {
        if (!inPoliceCustodyChainEventType) {
            return false;
        }

        const { id: inPoliceCustodyChainEventTypeId } = inPoliceCustodyChainEventType;

        return (
            chain(masterItemId)
                .thru(chainEventViewModelsForMasterItemId)
                .head()
                .get('eventTypeId')
                .value() === inPoliceCustodyChainEventTypeId
        );
    }
);

/** If latest chain of custody event was INFIELD_TRANSFER */
const wasLastIsInfieldTransferForMasterItemSelector = createSelector(
    chainEventViewModelsForMasterItemIdSelector,
    infieldTransferChainEventTypeSelector,
    (chainEventViewModelsForMasterItemId, { id: inPoliceCustodyChainEventTypeId }) =>
        (masterItemId) =>
            chain(masterItemId)
                .thru(chainEventViewModelsForMasterItemId)
                .head()
                .get('eventTypeId')
                .value() === inPoliceCustodyChainEventTypeId
);

/**
 * Return true, if the storage location field is editable
 * @type {boolean}
 */
export const isStorageLocationEditableSelector = createSelector(
    itemHasChainEventsSelector,
    wasLastCheckedInTempForMasterItemSelector,
    wasLastInPoliceCustodyForMasterItemSelector,
    wasLastIsInfieldTransferForMasterItemSelector,
    (
            itemHasChainEvents,
            wasLastCheckedInTempForMasterItem,
            wasLastInPoliceCustodyForMasterItem,
            wasLastIsInfieldTransferForMasterItem
        ) =>
        (masterItemId) =>
            !itemHasChainEvents(masterItemId) ||
            wasLastCheckedInTempForMasterItem(masterItemId) ||
            wasLastInPoliceCustodyForMasterItem(masterItemId) ||
            wasLastIsInfieldTransferForMasterItem(masterItemId)
);

import React, { useState, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { map } from 'lodash';
import styled from 'styled-components';
import { Tooltip } from 'arc';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    REPORT_RECORD_NUMBER,
    DISPLAY_ONLY_OFFENSE,
} from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import LinkedRenOwnerDateCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/LinkedRenOwnerDateCell';
import ReportTypeCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportTypeCell';
import NarrativeCell from '../../../../legacy-redux/components/core/tables/elasticCaseTable/NarrativeCell';
import EventLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/EventLocationCell';
import PersonsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/PersonsCell';
import StatCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/StatCell';
import OffenseLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/OffenseLocationCell';
import { Button as ArcButton } from '../../../core/components/Button';
import testIds from '../../../../core/testIds';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';

const strings = componentStrings.cases.caseSummary.EnhancedCaseReportsTable;

const RemoveButton = styled(ArcButton)`
    margin-right: 5px;
`;

const groupingData = {
    field: 'groupingField',
    showCollapseButton: (row) => row.length > 1,
    showLoadMore: true,
    displayMessage: (row) => `${row[0].groupingField}`,
};

/**
 * Related reports to a case
 * @param {Object} options.caseReports    elasticReports
 * @param {function} options.handleRowClick
 */
function EnhancedCaseReportsTable({
    caseReports,
    caseIsEditable,
    caseDisplayName,
    handleRowClick,
    formatFieldByName,
    applicationSettings,
}) {
    const defaultEntityColumn = (
        <OptionsTableColumnOption display={strings.columns.persons} value="persons" />
    );
    const [activeEntityColumn, setActiveEntityColumn] = useState(defaultEntityColumn.props);
    const overlayStore = useOverlayStore();
    const openRemoveModal = useCallback(
        (row) =>
            overlayStore.open(overlayIdEnum.REASON_FOR_REMOVAL_MODAL, {
                ren: row[0].reportingEventNumber,
                recordNumber: row[0].recordNumber,
            }),
        [overlayStore]
    );

    const rightElement = (groupedRow) => {
        return (
            <>
                {caseIsEditable ? (
                    <RemoveButton
                        size="sm"
                        onClick={() => openRemoveModal(groupedRow)}
                        testId={testIds.CASE_REMOVE_REN_BUTTON}
                        style={{ float: 'right' }}
                    >
                        {strings.remove}
                    </RemoveButton>
                ) : (
                    <Tooltip content={strings.cannotEditCaseTooltip(caseDisplayName)} hasArrow>
                        <RemoveButton
                            size="sm"
                            testId={testIds.CASE_REMOVE_REN_BUTTON}
                            style={{ float: 'right' }}
                            disabled={true}
                        >
                            {strings.remove}
                        </RemoveButton>
                    </Tooltip>
                )}
            </>
        );
    };

    const reports = !!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE
        ? map(caseReports, (row) => {
              return {
                  ...row,
                  groupingField: row.reportingEventNumber
                      ? `${formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)} ${
                            row.reportingEventNumber
                        }`
                      : `${formatFieldByName(REPORT_RECORD_NUMBER)} ${row.recordNumber}`,
              };
          })
        : caseReports;

    return (
        <Table
            data={reports}
            onRowClick={handleRowClick}
            noRowsText={strings.noResult}
            simpleStyles={false}
            className="case-reports-table"
            tableColumnsClassName="no-top-border"
            rowComponent={ElasticResultRow}
            groupingData={
                !!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE
                    ? {
                          ...groupingData,
                          rightElement,
                      }
                    : undefined
            }
        >
            <TableHeader>
                <TableColumns>
                    <TableColumn
                        display={strings.columns.renOwnerDate(
                            formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
                        )}
                        columnKey="renOwnerDate"
                        width={155}
                    />
                    <TableColumn
                        display={strings.columns.reportType}
                        columnKey="reportType"
                        width={191}
                    />
                    <TableColumn
                        display={strings.columns.narrative}
                        columnKey="narrative"
                        width={132}
                    />

                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeEntityColumn.value}
                        activeValue={activeEntityColumn.value}
                        onOptionClick={setActiveEntityColumn}
                        width={220}
                        popoutWidth={160}
                    >
                        {defaultEntityColumn}
                        <OptionsTableColumnOption
                            display={strings.columns.eventLocation}
                            value="eventLocation"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.offenseLocation(
                                formatFieldByName(DISPLAY_ONLY_OFFENSE)
                            )}
                            value="offenseLocation"
                        />
                    </OptionsTableColumn>
                    <TableColumn display={strings.columns.stat} columnKey="stat" width={66} />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <LinkedRenOwnerDateCell columnKey="renOwnerDate" />
                <ReportTypeCell columnKey="reportType" />
                <NarrativeCell columnKey="narrative" />
                <EventLocationCell columnKey="eventLocation" />
                <PersonsCell columnKey="persons" />
                <StatCell columnKey="stat" />
                <OffenseLocationCell columnKey="offenseLocation" />
            </TableBody>
        </Table>
    );
}

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    applicationSettings: applicationSettingsSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleRowClick({ id }) {
        router.push(`/reports/${id}`);
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EnhancedCaseReportsTable);

import { every, filter, get, isEmpty, map, parseInt } from 'lodash';
import { createSelector } from 'reselect';
import { InjectedRouter } from 'react-router';
import { ExportPresetView, RefContextEnum } from '@mark43/rms-api';
import {
    exportPresetsByIdSelector,
    exportPresetsSelector,
    LOAD_EXPORT_PRESETS_FAILURE,
    LOAD_EXPORT_PRESETS_START,
    LOAD_EXPORT_PRESETS_SUCCESS,
    REMOVE_EXPORT_PRESET_FAILURE,
    REMOVE_EXPORT_PRESET_START,
    REMOVE_EXPORT_PRESET_SUCCESS,
    SAVE_EXPORT_PRESET_FAILURE,
    SAVE_EXPORT_PRESET_START,
    SAVE_EXPORT_PRESET_SUCCESS,
    saveExportPreset,
    saveExportPresetFailure,
    ExportPresetAction,
} from '~/client-common/core/domain/export-presets/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    convertFromFormModel,
    convertToFormModel,
    ExportPresetFormConfiguration,
    ExportPresetFormDataShape,
} from '../forms/exportPresetsForm';
import { sortExportPresets } from '../data';
import { packetsForExportPresetReportTypesSelector } from '../../report-types/state/data';
import { RmsAction } from '../../../../../core/typings/redux';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';

const strings = componentStrings.admin.exportPresets.ExportPresetsAdmin;
const formStrings = componentStrings.admin.exportPresets.ExportPresetsAdminForm;

const SELECT_EXPORT_PRESET_START = 'export-presets/SELECT_EXPORT_PRESET_START' as const;
const SELECT_EXPORT_PRESET_SUCCESS = 'export-presets/SELECT_EXPORT_PRESET_SUCCESS' as const;
const SELECT_EXPORT_PRESET_FAILURE = 'export-presets/SELECT_EXPORT_PRESET_FAILURE' as const;

function selectExportPresetStart() {
    return { type: SELECT_EXPORT_PRESET_START };
}

function selectExportPresetSuccess(id: number | null) {
    return { type: SELECT_EXPORT_PRESET_SUCCESS, payload: id };
}

function selectExportPresetFailure(errMsg: string) {
    return { type: SELECT_EXPORT_PRESET_FAILURE, error: true, payload: errMsg };
}

export const uiSelector = (state: RootState) => state.ui.exportPresetsAdmin;

export const selectedExportPresetSelector = createSelector(
    uiSelector,
    exportPresetsByIdSelector,
    (ui, exportPresetViewById) =>
        ui.selectedExportPresetId ? exportPresetViewById(ui.selectedExportPresetId) : undefined
);

export const exportPresetsListItemsSelector = createSelector(
    uiSelector,
    exportPresetsSelector,
    (ui, exportPresets) => {
        const filteredExportPresets = filter(exportPresets, (exportPreset) => exportPreset.id > -1);
        return map(sortExportPresets(filteredExportPresets), (exportPreset) => {
            const exportPresetTitle = exportPreset.isDefaultExportPreset
                ? `${strings.defaultPrefix}${exportPreset.title}`
                : exportPreset.title;
            return {
                path: `/admin/export-presets/${exportPreset.id}`,
                title: exportPresetTitle,
                key: exportPreset.id,
                selected: exportPreset.id === ui.selectedExportPresetId,
            };
        });
    }
);

export function selectNewExportPreset(router: InjectedRouter): RmsAction<void> {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(RefContextEnum.FORM_ADMIN_EXPORT_PRESET.name);
        if (form) {
            form.resetModel();
        }
        dispatch(selectExportPresetSuccess(null));
        router.push('/admin/export-presets/new');
    };
}

export function selectExportPreset(exportPresetId: number | string): RmsAction<void> {
    return (dispatch, getState, { formsRegistry }) => {
        dispatch(selectExportPresetStart());
        const id = typeof exportPresetId === 'string' ? parseInt(exportPresetId) : exportPresetId;
        const selectedExportPreset = exportPresetsByIdSelector(getState())(id);
        if (!selectedExportPreset) {
            return dispatch(selectExportPresetFailure('Failed to get export preset.'));
        }
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_ADMIN_EXPORT_PRESET.name,
            undefined,
            (form) => {
                form.set(
                    '',
                    convertToFormModel(
                        selectedExportPreset,
                        packetsForExportPresetReportTypesSelector(getState())
                    )
                );
                form.resetUi();
            }
        );
        dispatch(selectExportPresetSuccess(selectedExportPreset.id));
        return;
    };
}

// need to manually check the form for validation
// since this can't be handled through the BE
const isFormModelValid = (formModel: ExportPresetFormDataShape) => {
    const { exportPresetReportTypes, isDefaultExportPreset } = formModel;

    return every(exportPresetReportTypes, (exportPresetReportType) => {
        const reportPrintables = get(exportPresetReportType, 'reportPrintables', []);
        const enabledReportPrintables = filter(
            reportPrintables,
            (reportPrintable) => reportPrintable.isEnabled
        );
        return isDefaultExportPreset || !isEmpty(enabledReportPrintables);
    });
};

export function submitExportPreset(
    selectedId: number | undefined,
    router: InjectedRouter
): RmsAction<void> {
    return function (dispatch, getState, { formsRegistry }) {
        const form = formsRegistry.get<ExportPresetFormConfiguration>(
            RefContextEnum.FORM_ADMIN_EXPORT_PRESET.name
        );
        if (!form) {
            return;
        }
        return form
            .submit()
            .then((result) => {
                const formModel = result.form.getState().model;
                if (!isFormModelValid(formModel)) {
                    throw new Error(formStrings.noReportTypeSelectedError);
                }
                const formExportPreset = convertFromFormModel(formModel, selectedId);

                return dispatch(saveExportPreset(formExportPreset)).then(
                    (updatedExportPreset: ExportPresetView) => {
                        form.set(
                            '',
                            convertToFormModel(
                                updatedExportPreset,
                                packetsForExportPresetReportTypesSelector(getState())
                            )
                        );
                        if (selectedId === undefined) {
                            dispatch(selectExportPreset(updatedExportPreset.id));
                            router.push(`/admin/export-presets/${updatedExportPreset.id}`);
                        }
                        return updatedExportPreset;
                    }
                );
            })
            .catch((err: Error) => dispatch(saveExportPresetFailure(err.message)));
    };
}

type ExportPresetsAdminUiState = {
    errorMessage: string | null;
    submittingForm: boolean;
    selectedExportPresetId: number | null | undefined;
    loading: boolean;
};

type ExportPresetsAdminAction =
    | ExportPresetAction
    | ReturnType<typeof selectExportPresetStart>
    | ReturnType<typeof selectExportPresetSuccess>
    | ReturnType<typeof selectExportPresetFailure>;

const initialUiState = {
    errorMessage: '',
    submittingForm: false,
    selectedExportPresetId: undefined,
    loading: false,
};

function exportPresetsAdminUiReducer(
    state: ExportPresetsAdminUiState = initialUiState,
    action: ExportPresetsAdminAction
): ExportPresetsAdminUiState {
    switch (action.type) {
        case SELECT_EXPORT_PRESET_START:
            return {
                ...state,
                submittingForm: false,
                selectedExportPresetId: null,
                errorMessage: null,
            };
        case SELECT_EXPORT_PRESET_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                selectedExportPresetId: action.payload,
                errorMessage: null,
            };
        case LOAD_EXPORT_PRESETS_START:
            return {
                ...state,
                errorMessage: null,
                loading: true,
            };
        case SELECT_EXPORT_PRESET_FAILURE:
        case LOAD_EXPORT_PRESETS_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: null,
            };
        case LOAD_EXPORT_PRESETS_FAILURE:
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case REMOVE_EXPORT_PRESET_START:
            return {
                ...state,
                submittingForm: true,
            };
        case REMOVE_EXPORT_PRESET_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case REMOVE_EXPORT_PRESET_FAILURE:
            return {
                ...state,
                submittingForm: false,
                errorMessage: action.payload,
            };
        case SAVE_EXPORT_PRESET_START:
        case SAVE_EXPORT_PRESET_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                errorMessage: null,
            };
        case SAVE_EXPORT_PRESET_FAILURE:
            return {
                ...state,
                submittingForm: false,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
}

export default exportPresetsAdminUiReducer;

import { flatMap, get, map } from 'lodash';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import getReportingEventsResource from '~/client-common/core/domain/reporting-events/resources/reportingEventsResource';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { cadTicketsViewModelWhereSelector } from '~/client-common/core/domain/cad-tickets/state/ui';
import { latestCadTicket } from '~/client-common/core/domain/cad-tickets/utils/cadTicketsHelpers';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';

import {
    getEnteredReportingEventNumber,
    getEnteredCadAgencyEventNumber,
} from '../selectors/renSearchModalSelectors';
import { createModalSelector } from '../../modules/core/box/state/ui';
import { currentUserDepartmentAgencyIdSelector } from '../../modules/core/current-user/state/ui';
import { createReportInQuickCrash } from '../../modules/reports/traffic-crash/utils';
import { isQuickCrashReportDefinitionSelector } from '../selectors/reportSelectors';

import {
    openBox,
    saveBoxStart,
    saveBoxFailure,
    saveBoxSuccess,
    storeBoxErrorMessages,
} from './boxActions';
import actionTypes from './types/renSearchModalActionTypes';
import { setLastCreatedReportId } from './reportsActions';

const {
    GET_INFO_FOR_REPORTING_EVENT_NUMBER_START,
    GET_INFO_FOR_REPORTING_EVENT_NUMBER_SUCCESS,
    GET_INFO_FOR_REPORTING_EVENT_NUMBER_FAILURE,
    DISABLE_REPORTING_EVENT_NUMBER_SEARCH_FORM,
    CREATE_REPORT_START,
    CREATE_REPORT_SUCCESS,
    CREATE_REPORT_FAILURE,
} = actionTypes;

const createReportContext = {
    name: boxEnum.CREATE_REPORT_MODAL,
};

function getInfoForReportingEventNumberStart(reportingEventNumber) {
    return {
        type: GET_INFO_FOR_REPORTING_EVENT_NUMBER_START,
        payload: reportingEventNumber,
    };
}
function getInfoForReportingEventNumberSuccess(result) {
    return {
        type: GET_INFO_FOR_REPORTING_EVENT_NUMBER_SUCCESS,
        payload: result,
    };
}

function getInfoForReportingEventNumberFailure(err) {
    return {
        type: GET_INFO_FOR_REPORTING_EVENT_NUMBER_FAILURE,
        payload: err,
    };
}

function storeReportingEventInfo(reportingEventInfo) {
    return function (dispatch) {
        const unitsAndMembers = flatMap(reportingEventInfo.cadTickets, 'unitsAndMembers');
        const cadTickets = map(reportingEventInfo.cadTickets, (cadTicket) => {
            cadTicket.unitsAndMembers = null;
            return cadTicket;
        });

        dispatch(
            withEntityItems(
                {
                    reportingEvents: [reportingEventInfo.reportingEvent],
                    reportShortTitles: reportingEventInfo.reportShortTitles,
                    caseTitles: reportingEventInfo.caseTitles,
                    cadTicketsUnitsAndMembers: unitsAndMembers,
                    cadTickets,
                },
                { type: 'STORE_REPORTING_EVENT_INFO' }
            )
        );
        dispatch(getInfoForReportingEventNumberSuccess(reportingEventInfo));

        return reportingEventInfo;
    };
}

export function getInfoForReportingEventNumber(reportingEventNumber, context, options) {
    return function (dispatch) {
        if (!reportingEventNumber) {
            return;
        }
        const trimmedReportingEventNumber = reportingEventNumber.trim();
        dispatch(getInfoForReportingEventNumberStart(trimmedReportingEventNumber));
        const reportingEventsResource = getReportingEventsResource();

        return reportingEventsResource
            .getEventInfoForReportingEventNumber(trimmedReportingEventNumber, options)
            .then((result) => {
                dispatch(storeBoxErrorMessages(context, []));
                return dispatch(storeReportingEventInfo(result, context));
            })
            .catch((err) => {
                dispatch(saveBoxFailure(context, err.message));
                dispatch(getInfoForReportingEventNumberFailure(err));
            });
    };
}

export function getInfoForReportingEventNumberWithPromise(submissionPromise, context, options) {
    return function (dispatch) {
        return submissionPromise
            .then(({ form }) => {
                return dispatch(
                    getInfoForReportingEventNumber(
                        form.get('reportingEventNumber'),
                        context,
                        options
                    )
                ).then((result) => {
                    form.set('reportingEventNumber', result.reportingEventNumber);
                });
            })
            .catch((err) => {
                dispatch(getInfoForReportingEventNumberFailure(err));
            });
    };
}

function getInfoForCadAgencyEventNumber(cadAgencyEventNumber, context, options = {}) {
    return function (dispatch) {
        if (!cadAgencyEventNumber) {
            return;
        }
        cadAgencyEventNumber = cadAgencyEventNumber.trim();
        dispatch(getInfoForReportingEventNumberStart(cadAgencyEventNumber));
        const reportingEventsResource = getReportingEventsResource();

        return reportingEventsResource
            .getReportingEventInfoByCadAgencyEventNumber(cadAgencyEventNumber, options)
            .then((result) => {
                dispatch(storeBoxErrorMessages(context, []));
                return dispatch(
                    storeReportingEventInfo({
                        cadAgencyEventNumber,
                        ...result,
                    })
                );
            })
            .catch((err) => {
                dispatch(saveBoxFailure(context, err.message));
                dispatch(getInfoForReportingEventNumberFailure(err));
            });
    };
}

/**
 * Disable the REN search form inside the report creation modal. The modal
 *   itself does not get disabled.
 * @param  {boolean} disabled
 * @return {Object}
 */
function disableReportingEventNumberSearchForm() {
    return {
        type: DISABLE_REPORTING_EVENT_NUMBER_SEARCH_FORM,
    };
}

// TODO the actions after this line should all be in a separate
// file related to CreateReportModal rather than RenSearchModal

/**
 * Open the report creation modal, fill in the given REN and search for it.
 *   Disable the REN search form so the REN field cannot be changed.
 * @param {string}  reportingEventNumber
 */
export function openCreateReportModalWithReportingEventNumber({
    reportingEventNumber,
    cadAgencyEventNumber,
}) {
    return function (dispatch) {
        // open the modal
        dispatch(
            openBox(createReportContext, {
                formInitialState: { reportingEventNumber, cadAgencyEventNumber },
                showCadAgencyEventNumberField: !!cadAgencyEventNumber,
            })
        );
        // disable the REN search form
        dispatch(disableReportingEventNumberSearchForm());
        // fill in the given REN into the REN search form
        // submit the REN search form to show existing reports and available
        // report types
        const ignoreFormatValidation = true;

        // prioritize searching by REN first
        if (!isUndefinedOrNull(reportingEventNumber)) {
            dispatch(
                getInfoForReportingEventNumber(reportingEventNumber, createReportContext, {
                    ignoreFormatValidation,
                })
            );
        } else {
            dispatch(
                getInfoForCadAgencyEventNumber(cadAgencyEventNumber, createReportContext, {
                    ignoreFormatValidation,
                })
            );
        }
    };
}

function createReportStart() {
    return {
        type: CREATE_REPORT_START,
    };
}
function createReportSuccess(results) {
    return {
        type: CREATE_REPORT_SUCCESS,
        payload: results,
    };
}

function createReportFailure(err) {
    return {
        type: CREATE_REPORT_FAILURE,
        payload: err,
    };
}

export function createReport(reportDefinitionId, router) {
    return function (dispatch, getState) {
        dispatch(createReportStart());
        dispatch(saveBoxStart(createReportContext));
        const state = getState();
        const reportingEventNumber = getEnteredReportingEventNumber(state)
            ? getEnteredReportingEventNumber(state).trim()
            : null;
        const cadAgencyEventNumber = getEnteredCadAgencyEventNumber(state)
            ? getEnteredCadAgencyEventNumber(state).trim()
            : null;

        const relatedCadTickets = cadTicketsViewModelWhereSelector(state)(
            reportingEventNumber,
            undefined
        );
        const cadTicket = latestCadTicket(relatedCadTickets);
        const curentUserDepartmentAgencyId = currentUserDepartmentAgencyIdSelector(state);
        const agencyId = get(cadTicket, 'agencyId') || curentUserDepartmentAgencyId;
        const { onSave, prefilledNarrative } = createModalSelector(createReportContext)(state);
        // send cadAgencyEventNumber as the recordNumber if REN doesn't exist,
        // but keep null if REN does exist. BE will then fill in the record number as appropriate
        const recordNumber = !reportingEventNumber ? cadAgencyEventNumber : null;

        const request = {
            reportDefinitionId,
            agencyId,
            reportingEventNumber,
            recordNumber,
            // In the mobile section, reports can be created with the note text filled in
            narrative: prefilledNarrative,
        };

        const isQuickCrashReportDefinition = isQuickCrashReportDefinitionSelector(state)(
            reportDefinitionId
        );

        return getReportResource()
            .createReport(request)
            .then((report) => {
                const reportId = report.id;
                dispatch(saveBoxSuccess(createReportContext));
                dispatch(createReportSuccess(report));
                if (onSave) {
                    onSave(report);
                }
                dispatch(setLastCreatedReportId(reportId));
                if (isQuickCrashReportDefinition) {
                    createReportInQuickCrash({
                        reportingEventNumber: report.reportingEventNumber,
                        recordNumber: report.recordNumber,
                        reportId,
                    });
                } else {
                    router.push(`/reports/${reportId}`);
                }
            })
            .catch((err) => {
                dispatch(saveBoxFailure(createReportContext, err.message));
                dispatch(createReportFailure(err));
            });
    };
}

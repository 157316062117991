import React from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';
import { AttributeTypeEnum } from '@mark43/rms-api';
import styled from 'styled-components';

import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';

import { identifiersFieldsetViewModel } from '../../../../legacy-redux/configs/fieldsetsConfig';
import testIds from '../../../../core/testIds';
import SearchCapabilityIcon, { SEARCH_CAPABILITY_TYPE_ENUM } from './SearchCapabilityIcon';

const { title } = identifiersFieldsetViewModel;

const IconWrapper = styled.span`
    margin-left: 10px;
`;

function IdentifiersFieldsetRow1({ fuzzyMatchingEnabled }) {
    return (
        <Row>
            <RRFText width={183} path="fbiUcn" />
            <RRFText width={183} path="stateIdNumber" />
            <RRFText
                width={183}
                className={classNames({
                    'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                })}
                extraLabelContent={
                    <IconWrapper>
                        <SearchCapabilityIcon variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name} />
                    </IconWrapper>
                }
                labelClassName=""
                path="ssn"
            />
        </Row>
    );
}

function IdentifiersFieldsetRow2({ fuzzyMatchingEnabled }) {
    return (
        <Row>
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.DL_TYPE.name}
                includeExpired={true}
                width={183}
                path="dlTypeAttrId"
            />
            <RRFText width={183} path="dlNumber" />
            <div data-test-id={testIds.ADVANCED_SEARCH_PERSON_PROFILE_PHONE_NUMBER}>
                <RRFText
                    width={183}
                    className={classNames({
                        'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                    })}
                    extraLabelContent={
                        <IconWrapper>
                            <SearchCapabilityIcon
                                variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name}
                            />
                        </IconWrapper>
                    }
                    labelClassName=""
                    path="phoneNumber"
                />
            </div>
        </Row>
    );
}

function IdentifiersFieldsetRow3({ fuzzyMatchingEnabled }) {
    return (
        <Row>
            <RRFNItems path="passports">
                {() => (
                    <RRFText
                        width={183}
                        className={classNames({
                            'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                        })}
                        path="number"
                        extraLabelContent={
                            <IconWrapper>
                                <SearchCapabilityIcon
                                    variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name}
                                />
                            </IconWrapper>
                        }
                        labelClassName=""
                    />
                )}
            </RRFNItems>
        </Row>
    );
}

function IdentifiersFieldsetRow4() {
    return (
        <Row>
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.RELIGION.name}
                includeExpired={true}
                width={183}
                path="religionAttrId"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.SEXUAL_ORIENTATION.name}
                includeExpired={true}
                width={183}
                path="sexualOrientationAttrId"
            />
        </Row>
    );
}

function NameIdentifiersNItems({ fuzzyMatchingEnabled }) {
    return (
        <RRFNItems path="nameIdentifiers" addItemOnDirty={true}>
            {() => [
                <RRFAttributeSelect
                    key={0}
                    attributeType={AttributeTypeEnum.NAME_IDENTIFIER_TYPE.name}
                    includeExpired={true}
                    width={183}
                    path="nameIdentifierTypeAttrId"
                />,
                <RRFText
                    key={1}
                    width={183}
                    className={classNames({
                        'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                    })}
                    path="idValue"
                />,
            ]}
        </RRFNItems>
    );
}

function MniIdentifiersNItems({ fuzzyMatchingEnabled }) {
    return (
        <RRFNItems path="ids" addItemOnDirty={true}>
            {() => (
                <RRFText
                    width={183}
                    className={classNames({
                        'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                    })}
                    path="mniValue"
                />
            )}
        </RRFNItems>
    );
}

function MniIdentifier({ fuzzyMatchingEnabled }) {
    return (
        <RRFText
            width={183}
            className={classNames({
                'fuzzy-matching-enabled': fuzzyMatchingEnabled,
            })}
            path="masterPersonId"
        />
    );
}

/**
 * Fieldset for a person's identifiers, including `nameIdentifiers`.
 * @param {Object}  props
 * @param {boolean} [props.collapsed=false]
 * @param {boolean} [props.fuzzyMatchingEnabled]
 * @param {string}  [props.className='light']
 */
export default pure(function IdentifiersFieldset({
    collapsed = false,
    fuzzyMatchingEnabled,
    className = 'light',
    mniDisplayNItems,
    displayMniSearchField,
    ...otherProps
}) {
    return (
        <RRFFieldset path="identifiers" title={title} className={className} {...otherProps}>
            <IdentifiersFieldsetRow1 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            {!collapsed && <IdentifiersFieldsetRow2 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />}
            {!collapsed && <IdentifiersFieldsetRow3 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />}
            {!collapsed && <IdentifiersFieldsetRow4 />}
            {!collapsed && <NameIdentifiersNItems fuzzyMatchingEnabled={fuzzyMatchingEnabled} />}
            {!collapsed &&
                displayMniSearchField &&
                (mniDisplayNItems ? (
                    <MniIdentifiersNItems fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                ) : (
                    <MniIdentifier fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                ))}
        </RRFFieldset>
    );
});

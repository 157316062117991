import React from 'react';
import { canRead, canFindOnly } from '~/client-common/core/domain/entity-permissions/state/ui';
import Link from '../../../../../modules/core/components/links/Link';
import { CourtOrderCellProps } from './CourtOrderCellProps';

export default function CourtCaseNumberCell({
    courtCaseNumber,
    reportId,
    permissionSet,
}: CourtOrderCellProps) {
    const isRestricted = !canRead(permissionSet) || canFindOnly(permissionSet);
    return isRestricted ? (
        <div>{courtCaseNumber}</div>
    ) : (
        <Link hoverOnUnderline={true} to={`reports/${reportId}`}>
            {courtCaseNumber}
        </Link>
    );
}

import React from 'react';
import { partialRight } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import withFields from '~/client-common/core/fields/components/withFields';
import { DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED } from '~/client-common/core/enums/universal/fields';

import { Field } from '~/client-common/core/fields/state/config';
import testIds from '../../../../../core/testIds';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';
import CardWithApprovalStatusIcon from './CardWithApprovalStatusIcon';

const strings = componentStrings.reports.core.ReportStatusCommentsCard;

interface RejectedCardPropsT {
    className?: string;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
    fieldDisplayNames: Record<Field, string>;
}

/**
 * Approval status card in the "rejected" state. This is a yellow card that must be returned
 * to draft before it can be resubmitted
 */
function RejectedCard({ className, reportSealingInfo, fieldDisplayNames }: RejectedCardPropsT) {
    return (
        <CardWithApprovalStatusIcon
            approvalStatus={approvalStatusClientEnum.REJECTED}
            reportSealingInfo={reportSealingInfo}
            title={fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED}
            className={className}
            latestHistoryText={partialRight(
                strings.rejected.enhancedRejectedText,
                fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED
            )}
            customSummaryModeColor={'lightYellow'}
            customSummaryModeBorderColor={'brightYellow'}
            testId={testIds.REPORT_STATUS_COMMENTS_REJECTED_CARD}
        />
    );
}

export default withFields([DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED])(RejectedCard);

import { connect } from 'react-redux';
import { compose } from 'redux';
import { first, map, sortBy } from 'lodash';
import React from 'react';
import { createStructuredSelector } from 'reselect';

import { codesByCodeIdSelector } from '~/client-common/core/domain/codes/state/data';
import { formatCode } from '~/client-common/core/domain/codes/state/ui';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatUcrSummaryOffenseCodeByCodeSelector } from '~/client-common/core/domain/ucr-summary-offense-codes/state/ui';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import { ucrEventsWhereSelector } from '~/client-common/core/domain/ucr-events/state/data';
import { ucrOffensesWhereSelector } from '~/client-common/core/domain/ucr-offenses/state/data';
import { ucrPropertyWhereSelector } from '~/client-common/core/domain/ucr-property/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import UcrOffenseSummary from './UcrOffenseSummary';
import UcrPropertySummary from './UcrPropertySummary';

const strings = componentStrings.reports.core.EventInfoCard;

const mapStateToProps = createStructuredSelector({
    ucrEventsWhere: ucrEventsWhereSelector,
    ucrOffensesWhere: ucrOffensesWhereSelector,
    ucrPropertyWhere: ucrPropertyWhereSelector,
    codesByCodeId: codesByCodeIdSelector,
    formatUcrSummaryOffenseCodeByCode: formatUcrSummaryOffenseCodeByCodeSelector,
    formatAttributeById: formatAttributeByIdSelector,
});

const UcrClassificationSummary = compose(connect(mapStateToProps))(
    ({
        ucrEventsWhere,
        ucrOffensesWhere,
        ucrPropertyWhere,
        codesByCodeId,
        formatUcrSummaryOffenseCodeByCode,
        reportId,
        formatAttributeById,
    }) => {
        const ucrEvent = first(ucrEventsWhere({ reportId }));
        const ucrOffenses = sortBy(ucrOffensesWhere({ reportId }), 'sequenceNumber');
        const ucrProperty = sortBy(ucrPropertyWhere({ reportId }), 'sequenceNumber');
        if (!ucrEvent) {
            return <div />;
        }
        return (
            <SummaryList labelWidth={160} contentWidth={380}>
                <CardSubsection title={strings.ucrClassificationSectionTitle}>
                    <SummaryRow fieldName={fields.UCR_EVENT_CUSTOM_REPORT_CLASSIFICATION_ATTR_ID}>
                        {formatAttributeById(ucrEvent.customReportClassificationAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.UCR_EVENT_IS_DOMESTIC_VIOLENCE}>
                        {booleanToYesNo(ucrEvent.isDomesticViolence)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.UCR_EVENT_WEAPON_OR_FORCE_INVOLVED_CODE_ID}>
                        {formatCode(codesByCodeId(ucrEvent.weaponOrForceInvolvedCodeId))}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.UCR_SUFFOCATION_OR_STRANGULATION_CODE_ID}>
                        {formatCode(
                            codesByCodeId(ucrEvent.suffocationOrStrangulationInvolvedCodeId)
                        )}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.UCR_EVENT_IS_LEOKA}>
                        {booleanToYesNo(ucrEvent.isLeoka)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.UCR_EVENT_IS_ANTI_REPRODUCTIVE_RIGHTS_CRIME}>
                        {booleanToYesNo(ucrEvent.isAntiReproductiveRightsCrime)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.UCR_EVENT_WERE_SENIOR_CITIZENS_VICTIMS}>
                        {booleanToYesNo(ucrEvent.wereSeniorCitizensVictims)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.UCR_EVENT_NUMBER_OF_SENIOR_CITIZEN_VICTIMS}>
                        {ucrEvent.numberOfSeniorCitizenVictims}
                    </SummaryRow>
                </CardSubsection>
                {map(ucrOffenses, (ucrOffense) => {
                    return (
                        <UcrOffenseSummary
                            key={ucrOffense.sequenceNumber}
                            ucrOffense={ucrOffense}
                            codesByCodeId={codesByCodeId}
                            formatUcrSummaryOffenseCodeByCode={formatUcrSummaryOffenseCodeByCode}
                        />
                    );
                })}
                {map(ucrProperty, (ucrProperty) => {
                    return (
                        <UcrPropertySummary
                            key={ucrProperty.sequenceNumber}
                            ucrProperty={ucrProperty}
                            codesByCodeId={codesByCodeId}
                        />
                    );
                })}
            </SummaryList>
        );
    }
);

export default UcrClassificationSummary;

import _ from 'lodash';
import { RotationServiceBundle } from '@mark43/cad-api';
import { createSelector } from 'reselect';
import createNormalizedModule, { ModuleShape } from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'cadServiceRotationProviders';
const cadServiceRotationProvidersModule = createNormalizedModule<RotationServiceBundle>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeCadServiceRotationProviders =
    cadServiceRotationProvidersModule.actionCreators.storeEntities;

/**
 * Format the given service rotation type as a dropdown/checkbox option.
 */
function mapCadServiceRotationProviderToOption({
    id,
    name,
    rotationServiceTypeId,
}: RotationServiceBundle) {
    return {
        value: id,
        display: name,
        rotationServiceTypeId,
    };
}

/**
 * Format the given cad units as dropdown/checkbox options, sorted by
 *   display string.
 */
function mapCadServiceRotationProvidersToOption(
    cadServiceRotationProviders: ModuleShape<RotationServiceBundle>
) {
    return _(cadServiceRotationProviders)
        .map(mapCadServiceRotationProviderToOption)
        .sortBy('display')
        .value();
}

// SELECTORS
export const cadServiceRotationProvidersSelector =
    cadServiceRotationProvidersModule.selectors.entitiesSelector;

export const cadServiceRotationProviderOptionsSelector = createSelector(
    cadServiceRotationProvidersSelector,
    mapCadServiceRotationProvidersToOption
);

// REDUCER
export default cadServiceRotationProvidersModule.reducerConfig;

// Slider config
export {
    SLIDER_THROTTLE,
    AGE_RANGE_SLIDER_MAX,
    HEIGHT_RANGE_SLIDER_MIN,
    HEIGHT_RANGE_SLIDER_MAX,
    WEIGHT_RANGE_SLIDER_MIN,
    WEIGHT_RANGE_SLIDER_MAX,
    WEIGHT_RANGE_SLIDER_STEP,
    VEHICLE_YEAR_RANGE_SLIDER_MIN,
    VEHICLE_YEAR_RANGE_SLIDER_MAX,
} from '~/client-common/configs/formsConfig';

// DatePicker config
// displayed format inputs are converted to
export const timeFormat = 'HH:mm';
export const datePlaceholder = 'MM/DD/YYYY';
export const timePlaceholder = 'HH:mm';
// should move these widths into the stylesheet using classes for the date and
// time pickers
export const dateDefaultWidth = 120;
export const timeDefaultWidth = 80;
export const datePickerTotalWidth = 125;
export const dateTimePickerTotalWidth = 220;

// DateRangePicker config
export const dateRangePickerWidth = 300;

// Modal form elements config
export const modalHalfFormWidth = 218;
export const modalSmallFormWidth = 150;

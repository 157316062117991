import { useSelector } from 'react-redux';
import { RegionalGroupEnum } from '@mark43/rms-api';
import { currentUserDepartmentProfileSelector } from '../../core/current-user/state/ui';
import { ComplianceExportType } from '../types';
import { NIBRS_COMPLIANCE_TYPE } from '../configuration';
import { getComplianceExportTypeEnum } from '../util/complianceExportTypeHelpers';

const useSubmissionType = (complianceExportType?: ComplianceExportType | string) => {
    const exportType =
        typeof complianceExportType === 'string'
            ? getComplianceExportTypeEnum(complianceExportType)
            : complianceExportType;

    const currentUserDepartmentProfile = useSelector(currentUserDepartmentProfileSelector);
    const departmentRegionalGroup = currentUserDepartmentProfile
        ? RegionalGroupEnum[currentUserDepartmentProfile.nibrsRegionalGroup]
        : undefined;

    const isNibrs = exportType?.name === NIBRS_COMPLIANCE_TYPE.name;
    const isExternalSubmission = departmentRegionalGroup?.isExternalSubmission;

    return {
        isNibrs,
        isExternalSubmission,
    };
};

export default useSubmissionType;

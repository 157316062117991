import { AttributeTypeEnum } from '@mark43/rms-api';
import { find, size } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';

import { lifecycleOptions, Fields, Observer } from 'markformythree';
import {
    ARREST_LOCKUP_NUMBER,
    ARREST_LOCKUP_LOCATION,
    ARREST_LOCKUP_DATE_UTC,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { dateTimePickerTotalWidth } from '../../../../../legacy-redux/configs/formsConfig';

import { formName, prefillChargeFormData } from '../../state/forms/bookingForm';
import PaddedNoDataBlock from '../../../../core/components/PaddedNoDataBlock';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { MFTNItems } from '../../../../core/forms/components/NItems';
import Row from '../../../../core/components/Row';
import { CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { Button } from '../../../../core/components/Button';
import ChargeTitle from './ChargeTitle';

const strings = componentStrings.reports.core.BookingCard;

const BookingCardForm = connect(
    createStructuredSelector({
        fieldConfigurationContextByContextAndFieldName:
            fieldConfigurationContextByContextAndFieldNameSelector,
    })
)(function _BookingCardForm({ fieldConfigurationContextByContextAndFieldName, charges, index }) {
    const totalChargesCount = size(charges);
    return totalChargesCount > 0 ? (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            index={index}
            render={(form) => (
                <>
                    <MFTNItems
                        path="charges"
                        addItemOnEmpty={false}
                        hideAddButtonOnEmptyItem={true}
                        automaticallyIncludeDeleteButton={false}
                        renderRemoveButton={undefined}
                        renderAddButton={undefined}
                        render={({ index, items }) => {
                            const showApplyToAllButton = totalChargesCount > 1 && index === 0;
                            const chargeFormModel = items[index];
                            const { id: chargeId } = chargeFormModel;
                            const rawCharge = find(charges, { id: chargeId });

                            return (
                                <>
                                    <Row>
                                        <ChargeTitle charge={rawCharge} />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            path="dispositionAttrId"
                                            attributeType={
                                                AttributeTypeEnum.ARREST_DISPOSITION.name
                                            }
                                            length="md"
                                        />
                                        <ArbiterMFTDatePicker
                                            path="dispositionDateUtc"
                                            includeTime={true}
                                            width={dateTimePickerTotalWidth}
                                        />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            path="juvenileDispositionAttrId"
                                            attributeType={
                                                AttributeTypeEnum.JUVENILE_DISPOSITION.name
                                            }
                                            length="md"
                                        />
                                        <ArbiterMFTDatePicker
                                            path="juvenileDispositionDateUtc"
                                            includeTime={true}
                                            width={dateTimePickerTotalWidth}
                                        />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTText
                                            path="collateralBondReceiptNum"
                                            length="md"
                                        />
                                        <ArbiterMFTText
                                            path="collateralBondAmountPaid"
                                            length="md"
                                        />
                                    </Row>
                                    {showApplyToAllButton && (
                                        <Button
                                            isTextTransformNone
                                            size="sm"
                                            style={{ marginBottom: '20px' }}
                                            onClick={() =>
                                                prefillChargeFormData({
                                                    form,
                                                    prefillFromChargeId: chargeId,
                                                })
                                            }
                                        >
                                            {strings.applyToAll}
                                        </Button>
                                    )}
                                </>
                            );
                        }}
                    />
                    <Observer
                        subscriptions={{
                            isLockupNumberDynamicallyHidden: ['arrest.lockupNumber', Fields.HIDDEN],
                            isLockupLocationDynamicallyHidden: [
                                'arrest.lockupLocation',
                                Fields.HIDDEN,
                            ],
                            isLockupDateUtcDynamicallyHidden: [
                                'arrest.lockupDateUtc',
                                Fields.HIDDEN,
                            ],
                        }}
                        render={({
                            isLockupNumberDynamicallyHidden,
                            isLockupLocationDynamicallyHidden,
                            isLockupDateUtcDynamicallyHidden,
                        }) => {
                            const lockupNumberFieldConfigurationContext =
                                fieldConfigurationContextByContextAndFieldName(
                                    formName,
                                    ARREST_LOCKUP_NUMBER
                                );
                            const lockupLocationFieldConfigurationContext =
                                fieldConfigurationContextByContextAndFieldName(
                                    formName,
                                    ARREST_LOCKUP_LOCATION
                                );
                            const lockupDateUtcFieldConfigurationContext =
                                fieldConfigurationContextByContextAndFieldName(
                                    formName,
                                    ARREST_LOCKUP_DATE_UTC
                                );

                            const isLockupNumberStaticallyHidden =
                                lockupNumberFieldConfigurationContext.isStaticallyHidden;
                            const isLockupLocationStaticallyHidden =
                                lockupLocationFieldConfigurationContext.isStaticallyHidden;
                            const isLockupDateUtcStaticallyHidden =
                                lockupDateUtcFieldConfigurationContext.isStaticallyHidden;

                            // the afisNumber field is not included in this check since its
                            // dynamically hidden state is tied to the other 3 fields in the same
                            // rule (HIDE_BOOKING_ARREST_LOCKUP_FIELDS), and its statically hidden
                            // state is independent of the other 3 (when a tenant statically hides
                            // afisNumber, we don't want to hide this entire section)
                            const showLockupSection = !(
                                isLockupNumberDynamicallyHidden ||
                                isLockupNumberStaticallyHidden ||
                                isLockupLocationDynamicallyHidden ||
                                isLockupLocationStaticallyHidden ||
                                isLockupDateUtcDynamicallyHidden ||
                                isLockupDateUtcStaticallyHidden
                            );

                            return showLockupSection ? (
                                <CardSubsection title={strings.lockupDetails}>
                                    <Row>
                                        <ArbiterMFTText path="arrest.lockupNumber" length="md" />
                                        <ArbiterMFTText path="arrest.afisNumber" length="md" />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTText path="arrest.lockupLocation" length="md" />
                                        <ArbiterMFTDatePicker
                                            path="arrest.lockupDateUtc"
                                            includeTime={true}
                                            length="md"
                                        />
                                    </Row>
                                </CardSubsection>
                            ) : null;
                        }}
                    />
                </>
            )}
        />
    ) : (
        <PaddedNoDataBlock>{strings.noChargesMessage}</PaddedNoDataBlock>
    );
});

export default BookingCardForm;

// @ts-nocheck inifinite types - client-common to client
import { parseInt } from 'lodash';
import { createSelector } from 'reselect';
import {
    EntityTypeEnum,
    EventDetail,
    Firearm,
    PersonProfile,
    Report,
    ItemProfile,
    Vehicle,
} from '@mark43/rms-api';
import { loadFullWarrant } from '~/client-common/core/domain/warrants/state/data';
import { formatReportTitleForReportIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { eventDetailViewModelByReportIdSelector } from '~/client-common/core/domain/event-details/state/ui';
import { personProfileViewModelsInReportSelector } from '~/client-common/core/domain/person-profiles/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import { RmsDispatch } from '../../../../core/typings/redux';
import { RootState } from '../../../../legacy-redux/reducers/rootReducer';
import { loadReportOnEnter } from '../../../reports/core/state/data/reportRouting';
import {
    currentReportSelector,
    isReportNewSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import { itemSummaryViewModelsForCurrentReportSelector } from '../../../reports/core/state/ui';

const CLIPS_SET_ERROR_MESSAGE = 'CLIPS_SET_ERROR_MESSAGE';

type EventDetailViewModelByReportId = ReturnType<typeof eventDetailViewModelByReportIdSelector>;
type PersonProfileViewModelsInReportId = ReturnType<typeof personProfileViewModelsInReportSelector>;
type ItemSummaryViewModelsForCurrentReport = ReturnType<
    typeof itemSummaryViewModelsForCurrentReportSelector
>;

type ClipsItemSummaryData = {
    vehicleSummaries: Vehicle[];
    itemSummaries: (ItemProfile | Firearm)[];
};

export type ClipsCurrentReportData = {
    eventDetailEntities: EventDetail;
    personProfileEntities: PersonProfile[];
    itemSummaryEntities: ClipsItemSummaryData;
    title: string;
    report?: Report;
    eventDetailViewProperties: EventDetail;
};

export function clipsOnEnter(
    this: {
        dispatch: RmsDispatch;
        getState: () => RootState;
    },
    nextState: {
        params: {
            entityId: string;
            entityType: string;
            reportId?: string;
        };
    }
) {
    const params = nextState.params;
    const entityId = params.entityId;
    const entityType = params.entityType;
    const { dispatch, getState } = this;

    if (entityType === EntityTypeEnum.REPORT.name) {
        params['reportId'] = entityId;
        const state = getState();
        const reportIsNew = isReportNewSelector(state)(parseInt(params.reportId));
        const args = { dispatch, getState, reportIsNew };

        loadReportOnEnter(params, args);
    } else if (entityType === EntityTypeEnum.WARRANT.name) {
        dispatch(loadFullWarrant(parseInt(entityId)));
    }
}

// SELECTORS
const uiSelector = (state: RootState) => state.ui.clips;

export const clipsErrorMessageSelector = createSelector(uiSelector, (ui) => ui.errorMessage);

const clipsReportItemSummarySelector = createSelector(
    itemSummaryViewModelsForCurrentReportSelector,
    (getItemSummaryViewModels: ItemSummaryViewModelsForCurrentReport): ClipsItemSummaryData => {
        const itemSummaryViewModelsVehicles = getItemSummaryViewModels(true);
        const itemSummaryViewModelsNotVehicles = getItemSummaryViewModels(false);

        return {
            // @ts-expect-error client-common to client RND-7529
            vehicleSummaries: itemSummaryViewModelsVehicles,
            // @ts-expect-error client-common to client RND-7529
            itemSummaries: itemSummaryViewModelsNotVehicles,
        };
    }
);

type ClipsReportItemSummary = ReturnType<typeof clipsReportItemSummarySelector>;

export const clipsCurrentReportDataSelector = createSelector(
    eventDetailViewModelByReportIdSelector,
    personProfileViewModelsInReportSelector,
    clipsReportItemSummarySelector,
    currentReportSelector,
    formatReportTitleForReportIdSelector,
    (
        getEventDetailViewModelByReportId: EventDetailViewModelByReportId,
        getPersonProfileViewModelsInReportId: PersonProfileViewModelsInReportId,
        itemSummaryEntities: ClipsReportItemSummary,
        report: Report | undefined,
        reportTitleSelector
    ) => (entityId: number): ClipsCurrentReportData => {
        const eventDetailEntities = getEventDetailViewModelByReportId(entityId);
        const eventDetailViewProperties = getViewModelProperties(eventDetailEntities);
        const personProfileEntities = getPersonProfileViewModelsInReportId(entityId);
        const title = reportTitleSelector(entityId);

        return {
            // @ts-expect-error client-common to client RND-7529
            eventDetailEntities,
            personProfileEntities,
            itemSummaryEntities,
            title,
            report,
            // @ts-expect-error client-common to client RND-7529
            eventDetailViewProperties,
        };
    }
);

export function setErrorMessage(errorMessage: string) {
    return { type: CLIPS_SET_ERROR_MESSAGE, payload: { errorMessage } } as const;
}

export function clearErrorMessage() {
    return setErrorMessage('');
}

type ClipsAction = ReturnType<typeof setErrorMessage>;

type ClipsUiState = {
    errorMessage: string;
};

const initialState: ClipsUiState = {
    errorMessage: '',
};

function clipsUiReducer(state = initialState, action: ClipsAction): ClipsUiState {
    switch (action.type) {
        case CLIPS_SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
            };
        default:
            return state;
    }
}

export default clipsUiReducer;

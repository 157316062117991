import React from 'react';
import { Status, StatusProps } from 'arc';
import { IntegrationStatusEnum, IntegrationStatusEnumType } from '@mark43/rms-api';

const convertIntegrationStatusToArcStatus = (
    status: IntegrationStatusEnumType
): StatusProps['status'] => {
    switch (status) {
        case IntegrationStatusEnum.ACKNOWLEDGED.name:
        case IntegrationStatusEnum.IN_PROGRESS.name:
        case IntegrationStatusEnum.SENT.name:
            return 'inProgress';
        case IntegrationStatusEnum.ERROR.name:
        case IntegrationStatusEnum.FAILURE.name:
            return 'negative';
        default:
            return 'positive';
    }
};

export const SubmissionStatusPill: React.FC<{ status: IntegrationStatusEnumType }> = ({
    status,
}) => {
    const arcStatus = convertIntegrationStatusToArcStatus(status);
    return <Status status={arcStatus}>{status}</Status>;
};

import _ from 'lodash';

import { filterFormData } from '~/client-common/helpers/formHelpers';
import { storeFieldConfigurations } from '~/client-common/core/domain/field-configurations/state/data';
import { storeFieldConfigurationContexts } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import stringsConfig from '~/client-common/core/strings';

import fieldConfigurationsAdminResource from '../../resources/fieldConfigurationsAdminResource';

const strings = stringsConfig.components.admin.fieldConfigurations;

export const SAVE_FIELD_CONFIGURATION_ADMIN_FORM_START =
    'field-configurations/SAVE_FIELD_CONFIGURATION_ADMIN_FORM_START';
export const SAVE_FIELD_CONFIGURATION_ADMIN_FORM_SUCCESS =
    'field-configurations/SAVE_FIELD_CONFIGURATION_ADMIN_FORM_SUCCESS';
export const SAVE_FIELD_CONFIGURATION_ADMIN_FORM_FAILURE =
    'field-configurations/SAVE_FIELD_CONFIGURATION_ADMIN_FORM_FAILURE';

function saveFieldConfigurationAdminFormStart() {
    return { type: SAVE_FIELD_CONFIGURATION_ADMIN_FORM_START };
}

function saveFieldConfigurationAdminFormSuccess() {
    return { type: SAVE_FIELD_CONFIGURATION_ADMIN_FORM_SUCCESS };
}

export function saveFieldConfigurationAdminFormFailure(errorMessages) {
    return {
        type: SAVE_FIELD_CONFIGURATION_ADMIN_FORM_FAILURE,
        payload: errorMessages,
    };
}

export function saveFieldConfigurationAdminForm(fieldConfigurationsAdminFormFieldViewModel) {
    return (dispatch) => {
        dispatch(saveFieldConfigurationAdminFormStart());
        const bundle = [
            {
                fieldConfigurations: [
                    fieldConfigurationsAdminFormFieldViewModel.fieldConfiguration,
                ],
                fieldConfigurationContexts:
                    fieldConfigurationsAdminFormFieldViewModel.fieldConfigurationContexts,
            },
        ];
        const filteredBundle = filterFormData(bundle);

        return fieldConfigurationsAdminResource
            .updateFieldConfigurationBundles(filteredBundle)
            .then((fieldConfigurationBundles) => {
                const fieldConfigurations = _(fieldConfigurationBundles)
                    .map('fieldConfigurations')
                    .flatten()
                    .value();
                const fieldConfigurationContexts = _(fieldConfigurationBundles)
                    .map('fieldConfigurationContexts')
                    .flatten()
                    .value();

                dispatch(storeFieldConfigurations(fieldConfigurations));
                dispatch(storeFieldConfigurationContexts(fieldConfigurationContexts));
                dispatch(saveFieldConfigurationAdminFormSuccess());
                return fieldConfigurationBundles;
            })
            .catch(() => dispatch(saveFieldConfigurationAdminFormFailure([strings.saveError])));
    };
}

import { ChainEventType } from '@mark43/evidence-api';
import { at } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inPoliceCustodyChainEventTypeSelector } from '~/client-common/core/domain/chain-event-types/state/data';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../../../../core/testIds';
import { Button } from '../../../../../../core/components/Button';
import { ConditionalTooltip } from '../../../../../../core/components/tooltip';
import { openLabelPrintingModal as _openLabelPrintingModal } from '../../../../../label-printing/state/ui';
import { evidenceDashboardSearch } from '../../../../state/ui';
import type { ManageEvidenceViewModel } from '../../../../types';

const strings = componentStrings.evidence.dashboard.EvidenceDashboardSearchResults;

export const PrintLabelsBulkAction = () => {
    const results: ManageEvidenceViewModel[] = useSelector(
        // @ts-expect-error Need to Type the createSearchModule abstraction
        evidenceDashboardSearch.selectors.currentResultsViewModelsSelector
    );
    // TODO: When `createSearchModule` is typed, remove number[]
    const selectedRows: number[] = useSelector(
        // @ts-expect-error Need to Type the createSearchModule abstraction
        evidenceDashboardSearch.selectors.selectedRowsSelector
    );
    const selectedResults = at(results, selectedRows);

    const inPoliceCustodyChainEventType = useSelector(
        inPoliceCustodyChainEventTypeSelector
    ) as ChainEventType;

    const dispatch = useDispatch();

    const onPrintLabelsClick = () => {
        return dispatch(
            _openLabelPrintingModal({
                masterItemIds: selectedResults.map((result) => result.masterItemId),
            })
        );
    };

    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];

    // cannot take evidence actions on any item that is still in police custody
    // (same as CPS)
    const someItemIsInPoliceCustody = selectedResults
        .map((item) => item.currentChainEvent[0].eventTypeId)
        .includes(inPoliceCustodyChainEventType.id);

    return (
        <ConditionalTooltip
            condition={someItemIsInPoliceCustody}
            content={strings.actionButtons.disabledPrintLabelsTooltip(custodyLabel)}
        >
            <Button
                isTextTransformNone
                leadingVisual="Label"
                onClick={onPrintLabelsClick}
                disabled={someItemIsInPoliceCustody}
                testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_PRINT_LABELS}
            >
                {strings.actionButtons.printLabels}
            </Button>
        </ConditionalTooltip>
    );
};

import React, { ReactNode, Ref } from 'react';
import { IOverlayBaseRenderProps } from 'overlay-manager/lib/OverlayBase';
import { OverlayIdEnumType } from '~/client-common/core/enums/universal/overlayIdEnum';
import { OverlayStateTypeEnumType } from '~/client-common/core/enums/client/overlayStateTypeEnum';
import { OverlayStateManager } from './OverlayStateManager';

const defaultGetInitialCustomPropertyState = () => ({});

export interface OverlayRenderProps<T extends object> extends IOverlayBaseRenderProps<T> {
    overlayBase: IOverlayBaseRenderProps<T>;
    closePanel: () => void;
    setCancelFocusRef: (cancelElement?: Element | null) => Ref<HTMLButtonElement>;
    setCloseFocusRefs: (closeElement?: Element | null) => Ref<HTMLButtonElement>;
}

export interface OverlayBaseHelperProps<T extends object> {
    id: OverlayIdEnumType;
    overlayStateType?: OverlayStateTypeEnumType;
    getInitialCustomPropertyState?: () => Partial<T>;
    renderButton?: (renderProps: OverlayRenderProps<T>) => ReactNode;
    children?: (renderProps: OverlayRenderProps<T>) => ReactNode;
    saveRef?: Ref<HTMLElement>;
}

export function OverlayBaseHelper<T extends object>({
    id,
    overlayStateType,
    getInitialCustomPropertyState,
    renderButton,
    saveRef,
    children,
}: OverlayBaseHelperProps<T>) {
    return (
        <OverlayStateManager
            id={id}
            overlayStateType={overlayStateType}
            getInitialCustomPropertyState={
                getInitialCustomPropertyState || defaultGetInitialCustomPropertyState
            }
            saveRef={saveRef}
        >
            {(renderProps: OverlayRenderProps<T>) => (
                <div>
                    {renderButton && renderButton(renderProps)}
                    {renderProps.overlayBase.overlayState.isOpen &&
                        children &&
                        children(renderProps)}
                </div>
            )}
        </OverlayStateManager>
    );
}

import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { HStack, VStack } from 'arc';

import testIds from '../../../../../core/testIds';
import NoDataBlock from '../../../../core/components/NoDataBlock';
import { useDateTimeFormatter } from '../../../../core/current-user/hooks/dateTimeFormats';
import { useSubmissionMessages } from '../../../../submissions/hooks';
import { SubmissionStatusPill } from '../../../../submissions/components';
import { LinkedRecordContainer } from './LinkedRecordComponents';

type PropsT = {
    className?: string;
};

const EntityHStack = styled(HStack)`
    width: 100%;
`;

const SubText = styled.div`
    font-size: var(--arc-fontSizes-sm);
    font-style: italic;
`;

const EntitySubmissionMessages = ({ className }: PropsT) => {
    const dateTimeFormatter = useDateTimeFormatter();

    const {
        state: { submissionMessageViews },
    } = useSubmissionMessages();

    if (!submissionMessageViews.length) {
        return (
            <HStack justifyContent="center">
                <NoDataBlock>No Submissions</NoDataBlock>
            </HStack>
        );
    }

    return (
        <div data-test-id={testIds.REPORT_SIDEBAR_SUBMISSIONS_TAB}>
            {map(
                submissionMessageViews,
                ({
                    message: { id: messageId, createdDateUtc },
                    messageTypeDisplayName,
                    messageTypeDisplayCode,
                    status: { status },
                }) => (
                    <LinkedRecordContainer key={messageId} className={className}>
                        <VStack alignItems="self-start">
                            <EntityHStack justifyContent="space-between">
                                <div>{`${messageTypeDisplayName} (${messageTypeDisplayCode})`}</div>
                                <SubmissionStatusPill status={status} />
                            </EntityHStack>
                            <SubText>
                                {`Created Date: ${dateTimeFormatter.formatSummaryDateTime(
                                    createdDateUtc
                                )}`}
                            </SubText>
                        </VStack>
                    </LinkedRecordContainer>
                )
            )}
        </div>
    );
};

export default EntitySubmissionMessages;

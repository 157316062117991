import { DeepPartial } from 'utility-types';
import {
    CasePhotoLineupCreationRequest,
    SearchQueryElasticPersonQuery,
    PhotoLineupResource,
    PhotoLineupView,
    ElasticSearchResource,
} from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Case Photo Lineup Resource',
    methods: {
        // CREATE
        createPhotoLineupForCase(data: CasePhotoLineupCreationRequest) {
            return req<PhotoLineupResource.CreatePhotoLineupForCase>({
                method: 'POST',
                url: 'photo-lineup/case/create',
                data,
            });
        },
        // GET
        getPhotoLineupForCase(caseId: number, photoLineupId: number) {
            return req<PhotoLineupResource.GetPhotoLineupForCase>({
                method: 'GET',
                url: `photo-lineup/case/${caseId}/${photoLineupId}/`,
            });
        },
        getPhotoLineupsForCase(caseId: number) {
            return req<PhotoLineupResource.GetPhotoLineupsForCase>({
                method: 'GET',
                url: `photo-lineup/case/${caseId}/`,
            });
        },
        // PUT
        upsertPhotoLineupForCase(data: PhotoLineupView) {
            return req<PhotoLineupResource.SavePhotoLineup>({
                method: 'PUT',
                url: 'photo-lineup/case',
                data,
            });
        },
        // DELETE
        deletePhotoLineupForCase(photoLineupId: number) {
            return req<PhotoLineupResource.DeletePhotoLineupForCase>({
                method: 'DELETE',
                url: `photo-lineup/case/${photoLineupId}/`,
            });
        },
        searchPhotos(data: DeepPartial<SearchQueryElasticPersonQuery>) {
            return req<ElasticSearchResource.SearchPhotoLineup>({
                method: 'POST',
                url: 'elastic_search/photo_lineups',
                // @ts-expect-error JRV-920
                data,
            });
        },
    },
});

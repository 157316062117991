import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { includes } from 'lodash';
import { OperationTypeEnum, EntityTypeEnumType, OperationTypeEnumType } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { RmsDispatch } from '../../../../core/typings/redux';
import Modal from '../../overlays/components/Modal';
import { useLoadPermissionsForOverlay } from '../hooks/useLoadPermissionsForOverlay';
import { getFormNameForEntityType, saveEntityPermissionsForm } from '../state/ui';
import RecordPermissionsForm from './RecordPermissionsForm';

export interface GenericPermissionsModalProps {
    buttonElement?: React.ReactNode;
    entityId: number;
    entityType: EntityTypeEnumType;
    overlayId: string;
    permissionSet: OperationTypeEnumType[];
    title: string;
}

const strings = componentStrings.core.permissions.genericPermissionsModal;

const Content: React.FC<GenericPermissionsModalProps> = (props) => {
    const { entityId, entityType, overlayId, permissionSet } = props;

    useLoadPermissionsForOverlay({ entityId, entityType, overlayId });

    const editable = includes(permissionSet, OperationTypeEnum.MANAGE.name);
    const formName = getFormNameForEntityType(entityType);

    return <RecordPermissionsForm formName={formName} disabledRoleIds={[]} editable={editable} />;
};

const GenericPermissionsModal: React.FC<GenericPermissionsModalProps> = (props) => {
    const { buttonElement, entityId, entityType, overlayId, permissionSet, title } = props;

    const dispatch = useDispatch<RmsDispatch>();
    const handleSave = useCallback(() => {
        return dispatch(saveEntityPermissionsForm(entityType, entityId));
    }, [dispatch, entityId, entityType]);

    const editable = includes(permissionSet, OperationTypeEnum.MANAGE.name);

    return (
        <Modal
            buttonElement={buttonElement}
            id={overlayId}
            okText={strings.saveText}
            onSave={handleSave}
            saveDisabled={!editable}
            title={title}
        >
            <Content {...props} />
        </Modal>
    );
};

export default GenericPermissionsModal;

/**
 * Padding of all cells of <ItemQueueTable>.
 * @type {number}
 */
export const ITEM_QUEUE_TABLE_CELL_PADDING = 10;

/**
 * Width of the <ItemInfo> column of <ItemQueueTable>
 * @type {number}
 */
export const ITEM_QUEUE_TABLE_ITEM_INFO_WIDTH = 236;

/**
 * Width of the Create Chain Events panel, including its border.
 * @type {number}
 */
export const CREATE_CHAIN_EVENTS_PANEL_WIDTH = 348;

/**
 * Width of elements inside Create Chain Events panel.
 * @type {number}
 */
export const CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH = 288;

/**
 * Time (milliseconds) to wait after auto-opening item queue popover before
 *  auto-closing popover.
 * @type {number}
 */
export const ITEM_QUEUE_AUTO_OPEN_CLOSE_DELAY = 3000;

import $ from 'jquery';
import { get, map, omit, isEmpty, head, compact } from 'lodash';

export const REDACTABLE_FIELD_CONTENTS_CLASS_NAME = 'redactable-field-contents';
export const REDACTABLE_TEXT_CLASS_NAME = 'redactable-text';

export const REDACTED_BAR_CLASS_NAME = 'redacted-bar';

export const REDACTED_BAR_MIN_LENGTH = 5;

const generateKeyForText = (text) => {
    const keyLength = Math.max(text.length, REDACTED_BAR_MIN_LENGTH);
    return (+new Date() + Math.random()).toString(36).slice(-keyLength);
};

const getRedactedBarForText = (text, className) => {
    const key = generateKeyForText(text);
    return {
        key,
        redactedBarHtml: `<span style="color: #000; background:#000;" class="${className}">${key}</span>`,
    };
};

const toggleElementDisplay = (element) => {
    if (element) {
        const currentDisplay = element.css('display');
        const newDisplay = currentDisplay === 'none' ? '' : 'none';
        element.css('display', newDisplay);
    }
};

export const toggleFieldRedaction = ({ fieldName, html }) => {
    const fieldSelector = `[data-test-id=${fieldName}]`;
    const fieldContentsSelector = `${fieldSelector} .${REDACTABLE_FIELD_CONTENTS_CLASS_NAME}`;
    const redactedBarSelector = `${fieldSelector} .${REDACTED_BAR_CLASS_NAME}`;

    const htmlElement = $(`<div>${html}</div>`);
    if ($(fieldContentsSelector, htmlElement).length) {
        toggleElementDisplay($(fieldContentsSelector, htmlElement));
        toggleElementDisplay($(redactedBarSelector, htmlElement));
    } else {
        const textToRedact = $(fieldSelector, htmlElement).text();
        if (!isEmpty(textToRedact)) {
            const { redactedBarHtml } = getRedactedBarForText(
                textToRedact,
                REDACTED_BAR_CLASS_NAME
            );
            $(fieldSelector, htmlElement).each(function () {
                $(this)
                    .contents()
                    .wrapAll(
                        `<div style="display:none" class="${REDACTABLE_FIELD_CONTENTS_CLASS_NAME}"/>`
                    );
                $(this).append(redactedBarHtml);
            });
        }
    }
    return htmlElement.html();
};

const updateWorkOrderWithRedactedFields = ({ workOrder, redactedFields }) => {
    const exportConfigs = {
        ...get(workOrder, 'ec', {}),
        fieldsToRedact: redactedFields,
    };
    const children = get(workOrder, 'c');
    const childrenWithUpdatedExportConfigs = map(children, (child) => ({
        ...omit(child, 'ec'),
        exportConfigs,
    }));
    return {
        ...omit(workOrder, ['ec', 'c']),
        children: childrenWithUpdatedExportConfigs,
        exportConfigs,
    };
};

export const getRedactionRegexFromTextToRedact = (textToRedact) => {
    const escapedString = escapeRegExp(textToRedact);
    /**
     * Also want to exclude anything inside of html tags <>. However,
     * if for some reason our RegExp here fails due to browser constraints,
     * we'll ignore this constraint.
     */
    try {
        return new RegExp(`${escapedString}(?![^<]*[>])`, 'gi');
    } catch (e) {
        return new RegExp(escapedString, 'gi');
    }
};

const parseHtmlBody = (html) => {
    const bodyStartTag = '<body>';
    const bodyEndTag = '</body>';
    const bodyStartIndex = html.indexOf(bodyStartTag) + bodyStartTag.length;
    const bodyEndIndex = html.indexOf(bodyEndTag);

    /**
     * If there is no body tag, assume the whole content is part of the body
     */
    if (bodyStartIndex < 0 || bodyEndIndex < 0) {
        return {
            beforeBody: '',
            body: html,
            afterBody: '',
        };
    }

    const beforeBody = html.substring(0, bodyStartIndex);
    const body = html.substring(bodyStartIndex, bodyEndIndex);
    const afterBody = html.substring(bodyEndIndex);
    return {
        beforeBody,
        body,
        afterBody,
    };
};

/**
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 */
const escapeRegExp = (string) => {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const escapeHtmlReservedCharacters = (input) => {
    return input.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

const getTextRedaction = (htmlContents, textToRedact) => {
    const escapedTextToRedact = escapeHtmlReservedCharacters(textToRedact);
    const redactionRegex = getRedactionRegexFromTextToRedact(escapedTextToRedact);
    const { key, redactedBarHtml } = getRedactedBarForText(
        escapedTextToRedact,
        REDACTABLE_TEXT_CLASS_NAME
    );

    let contentsWereRedacted = false;

    map(htmlContents, (htmlContent) => {
        const { body } = parseHtmlBody(htmlContent);
        const matchingTextToRedact = head(body.match(redactionRegex));
        if (!!matchingTextToRedact) {
            contentsWereRedacted = true;
        }
    });

    if (contentsWereRedacted) {
        return {
            key,
            textToRedact: escapedTextToRedact,
            textToRedactDisplay: textToRedact,
            redactedBarHtml,
            redactionRegex,
        };
    }

    return null;
};

export const redactTextFromHtmlContents = ({
    htmlContents,
    textToRedact,
    currentTextRedactions = [],
}) => {
    let redaction;

    if (textToRedact) {
        redaction = getTextRedaction(htmlContents, textToRedact);

        if (redaction) {
            currentTextRedactions = [...currentTextRedactions, redaction];
        }
    }

    // Need to sort by string length - not alphanumeric
    currentTextRedactions.sort((a, b) => {
        return b.textToRedactDisplay.length - a.textToRedactDisplay.length;
    });
    const redactedHtmlContents = map(htmlContents, (htmlContent) => {
        const { beforeBody, body, afterBody } = parseHtmlBody(htmlContent);
        let redactedBody = body;
        currentTextRedactions.forEach((term) => {
            const matchingTextToRedact = head(redactedBody.match(term.redactionRegex));
            if (!!matchingTextToRedact) {
                redactedBody = redactedBody.replace(term.redactionRegex, term.redactedBarHtml);
            }
        });
        return `${beforeBody}${redactedBody}${afterBody}`;
    });

    return {
        redaction,
        redactedHtmlContents,
    };
};

/**
 * For security purposes, instead of just directly sending down the user's
 * search terms for redaction, we want to send down character ranges that
 * correspond to the original preview HTML which the backend will then translate
 * back into the actual string for each search term
 */
export const getCharacterRangesToRedact = ({ htmlContents, redactionRegexes }) =>
    compact(
        map(redactionRegexes, (redactionRegex) => {
            let characterRangeToRedact;
            if (!!redactionRegex) {
                let overallStartIndex = 0;
                for (let i = 0; i < htmlContents.length; i++) {
                    const htmlContent = htmlContents[i];
                    const { beforeBody, body } = parseHtmlBody(htmlContent);
                    let textToRedactStartIndex = body.search(redactionRegex);
                    if (textToRedactStartIndex > -1) {
                        textToRedactStartIndex += overallStartIndex;
                        if (!!beforeBody) {
                            textToRedactStartIndex += beforeBody.length;
                        }

                        /**
                         * As of TW-1180 we're only offering global redaction for search terms
                         * so we only need one character range per search term -- which is why
                         * we're only using the first match
                         */
                        const matchingTextToRedact = head(body.match(redactionRegex));
                        const textToRedactEndIndex =
                            textToRedactStartIndex + matchingTextToRedact.length;
                        characterRangeToRedact = {
                            left: textToRedactStartIndex,
                            right: textToRedactEndIndex,
                        };
                        break;
                    }
                    overallStartIndex += htmlContent.length;
                }
            }
            return characterRangeToRedact;
        })
    );

/**
 * Remove the department logo and other S3 images from the HTML. Their urls violate our Content Security Policy, because
 * it's meant to be rendered directly from the PDF, whereas in-app images should use relative paths to S3.
 */
export function removeDepartmentLogo(html = '') {
    return html.replace(/<img.*?amazonaws.*?>/g, '');
}

export const getRedactedWorkOrder = ({
    workOrder,
    redactedFields,
    originalPreviewHtmlContents,
    textRedactions,
}) => {
    const workOrderWithRedactedFields = updateWorkOrderWithRedactedFields({
        workOrder,
        redactedFields,
    });

    const redactionRegexes = map(textRedactions, 'redactionRegex');
    const characterRangesToRedact = getCharacterRangesToRedact({
        htmlContents: originalPreviewHtmlContents,
        redactionRegexes,
    });

    const workOrderWithAllRedactions = {
        ...workOrderWithRedactedFields,
        characterRangesToRedact,
    };

    return workOrderWithAllRedactions;
};

import { EntityTypeEnum } from '@mark43/rms-api';
import { StaffRemark } from '@mark43/evidence-api';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { RemarksAccordion } from '../../../../../modules/core/components/RemarksAccordion';
import StaffRemarks from '../../../../../modules/evidence/core/components/StaffRemarks';

const strings = componentStrings.core.tables.elasticEvidenceTable.StaffRemarksCell;

const CellContainer = styled.div`
    cursor: default;
    margin-left: 35px;
    margin-bottom: 16px;
`;

const StaffRemarksContainer = styled.div`
    margin-top: 26px;

    &:first-child {
        margin-top: 8px;
    }
`;

const Subtitle = styled.div`
    margin-bottom: 16px;
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

type StaffRemarksCellProps = {
    staffRemarks: StaffRemark[];
    reportingEventNumber: string;
};

const StaffRemarksCell: React.FC<StaffRemarksCellProps> = ({
    staffRemarks,
    reportingEventNumber,
}) => {
    const reportLevelStaffRemarks = _(staffRemarks)
        .filter({ entityType: EntityTypeEnum.REPORT.name })
        .sortBy('remarkDateUtc')
        .reverse()
        .value();
    const itemLevelStaffRemarks = _(staffRemarks)
        .filter({ entityType: EntityTypeEnum.ITEM_PROFILE.name })
        .sortBy('remarkDateUtc')
        .reverse()
        .value();

    const fieldDisplayNames = useFields([REPORT_REPORTING_EVENT_NUMBER]);

    return (
        <CellContainer onClick={(event) => event.stopPropagation()}>
            <RemarksAccordion header={strings.title(staffRemarks.length)}>
                {reportLevelStaffRemarks.length > 0 && (
                    <StaffRemarksContainer>
                        <Subtitle>
                            {strings.reportLevelStaffRemarks(
                                fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER,
                                reportingEventNumber
                            )}
                        </Subtitle>
                        <StaffRemarks staffRemarks={reportLevelStaffRemarks} />
                    </StaffRemarksContainer>
                )}
                {itemLevelStaffRemarks.length > 0 && (
                    <StaffRemarksContainer>
                        <Subtitle>{strings.itemLevelStaffRemarks}</Subtitle>
                        <StaffRemarks staffRemarks={itemLevelStaffRemarks} />
                    </StaffRemarksContainer>
                )}
            </RemarksAccordion>
        </CellContainer>
    );
};

/**
 * If an evidence item has staff remarks, then this special cell appears below the normal row of
 *   search results and spans 2 columns.
 */
export default StaffRemarksCell;

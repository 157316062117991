import styled from 'styled-components';

const FlexContainer = styled.div`
    background-color: ${(props) =>
        props.backgroundColor && props.theme.colors[props.backgroundColor]};
    display: flex;
    flex-direction: ${(props) => props.flexDirection};
    flex: ${(props) =>
        `${props.flexGrow || 0} ${props.flexShrink || 0} ${
            props.flexBasis || `${props.theme.widths.container}px`
        }`};
    justify-content: ${(props) => props.justify || 'flex-end'};
    overflow-x: ${(props) => props.overflowX || 'hidden'};
    overflow-y: ${(props) => props.overflowY || 'auto'};
    width: ${(props) => props.width || `${props.theme.widths.container}px`};
`;

export default FlexContainer;

import keyMirror from 'keymirror';

/**
 *  This enum is a copy of the ClientApprovalStatus enum found on the server. Eventually,
 *  usages of this enum should be replaced with the enum found in `mark43-resources`.
 *
 *  A report that is COMPLETED means that it must have gone through all levels
 *  of approval in which it was configured for. (0, 1, or 2 levels).
 *
 *  A report can only be configured up to a maximum of 2 levels of approval.
 *
 *  0 Level of Approval: A report goes from DRAFT -> COMPLETED
 *  1 Level of Approval: A report goes from DRAFT -> PENDING_SUPERVISOR_REVIEW -> COMPLETED
 *  2 Levels of Approval: A report goes from DRAFT -> PENDING_SUPERVISOR_REVIEW -> PENDING_SECONDARY_REIVEW -> COMPLETED
 *
 * @type {Object}
 *
 */

export default keyMirror({
    DRAFT: null,
    REJECTED: null,
    SUBMITTED: null,
    APPROVED: null,
    STAFF_REVIEWED: null,
    NONE: null,
    PENDING_SECONDARY_REVIEW: null,
    PENDING_SUPERVISOR_REVIEW: null,
    COMPLETED: null,
    UNKNOWN: null,
});

import { get } from 'lodash';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Text } from 'arc';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data/';
import { narrativeAutosavesSelector } from '~/client-common/core/domain/narrative-autosave/state/data/';
import { AUTOSAVE_INTERVAL } from '~/client-common/core/domain/reports/state/ui/narratives';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import formsRegistry from '../../../../../core/formsRegistry';
import narrativeCard from '../../state/ui/narrativeCard';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import withCard from '../../utils/withCard';
import { registerForm } from '../../state/ui';

import { canViewInlineCommentsSelector } from '../../state/ui/inlineComments';
import testIds from '../../../../../core/testIds';
import { hasBannerSelector } from '../../../../../legacy-redux/selectors/alertsSelectors';
import {
    createNarrativeForm,
    refreshNarrativeForm,
    narrativeFormName,
} from '../../state/forms/narrativeForm';
import { registerCard } from '../../utils/cardsRegistry';
import { CardWithExpandedSupport } from '../NarrativeStyledComponents';
import NarrativeCardBase from '../NarrativeCardBase';
import { getNarrativeName } from '../../../../core/editor/utils/tinyEditor';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import { TinyNarrativeCardContent } from './TinyNarrativeCardContent';
import TinyInlineComments from './TinyInlineComments';

const strings = componentStrings.reports.core.NarrativeCard;

/**
 * Return the initial state for the narrativeForm.
 * If a NarrativeAutosave exists, return the autosave. This is the "recover autosave" feature.
 */
function getInitialFormState({ reportId, narrativeAutosaves, refreshNarrativeForm }) {
    const dataState = refreshNarrativeForm(reportId);

    const autosave = narrativeAutosaves[reportId];
    if (autosave && autosave.narrativeHtml) {
        return {
            narrative: autosave.narrativeHtml,
        };
    }

    return dataState;
}

class NarrativeCard extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
            cardHeaderHeight: 0,
            showOpenInlineComments: false,
            selectedCommentId: undefined,
        };

        const {
            refreshNarrativeForm,
            formatFieldByName,
            arbiter,
            currentReportId,
            narrativeAutosaves,
        } = this.props;

        const form = createNarrativeForm({
            formatFieldByName,
            arbiter,
            initialState: getInitialFormState({
                reportId: currentReportId,
                narrativeAutosaves,
                refreshNarrativeForm,
            }),
        });

        registerForm({ form, index: currentReportId });

        this.unregisterCard = registerCard({
            cardModule: narrativeCard,
            onSave: this.onSave,
            index: currentReportId,
        });
    }

    componentWillUnmount() {
        this.unregisterCard();
        formsRegistry.unregister(narrativeFormName, this.props.currentReportId);
    }

    getForm = () => formsRegistry.get(narrativeFormName, this.props.currentReportId);

    onEdit = () => {
        this.props.editCallback(() => this.props.onEdit({ index: this.props.currentReportId }));
    };

    onSaveProgress = () => {
        this.props.onSaveProgress(this.getForm(), {
            onSaveSuccessCallback: this.props.onSaveSuccess,
            index: this.props.currentReportId,
        });
    };

    // Needs to be a class method so that we can
    // access it during report validation
    onSave = () => {
        return this.props.onSave(this.getForm(), {
            onSaveSuccessCallback: this.props.onSaveSuccess,
            index: this.props.currentReportId,
        });
    };

    setError = (errorMessage) => {
        this.props.setErrorMessages([errorMessage], { index: this.props.currentReportId });
    };

    setCardHeaderHeight = (ref) => this.setState({ cardHeaderHeight: get(ref, 'offsetHeight') });

    getSummaryMode = () => {
        return this.props.card.summaryMode;
    };

    onToggleComments = (commentIsSelected, selectedCommentId) => {
        this.setState({
            showOpenInlineComments:
                commentIsSelected ||
                // We don't dynamically show/hide the comment sidebar while in expanded mode because the width of the
                // card would suddenly change as the user selects text, disrupting the text that they'd end up
                // selecting. This is the same check as below.
                (this.props.canViewInlineComments && this.props.getIsExpandedFullScreen()),
            selectedCommentId,
        });
    };

    onFullScreenToggleForTinyNarrativeCard = () => {
        /**
         * Before toggling between expanded full screen mode and collapsed mode, we statically show/hide the inline
         * comment sidebar.
         *
         * We don't dynamically show/hide the comment sidebar while in expanded mode because the width of the card would
         * suddenly change as the user selects text, disrupting the text that they'd end up selecting. This is the same
         * check as above.
         */
        this.setState({
            showOpenInlineComments:
                this.props.canViewInlineComments &&
                // this value is false when the card is being transitioned to expanded full screen mode
                !this.props.getIsExpandedFullScreen(),
        });

        this.props.onFullScreenToggle();
    };

    render() {
        const { card = {}, hasBanner, currentReportCardUITitleByType } = this.props;
        const {
            anchor,
            canEditReportCardStatus,
            saving,
            anchorForIndex,
            summaryMode,
            errorMessages,
        } = card;
        const isExpandedFullScreen = this.props.getIsExpandedFullScreen();
        const cardTitle = currentReportCardUITitleByType(reportCardEnum.NARRATIVE.id);
        return (
            <CardWithExpandedSupport
                cardHeaderRef={this.setCardHeaderHeight}
                className={anchor}
                anchor={anchorForIndex(this.props.index)}
                isEmbedded={this.props.isEmbedded}
                title={
                    summaryMode ? (
                        cardTitle
                    ) : (
                        <>
                            {cardTitle}{' '}
                            <Text as="span" color="inverse" fontSize="sm" isItalic>
                                {strings.autosaveReminder(AUTOSAVE_INTERVAL / 1000)}
                            </Text>
                        </>
                    )
                }
                renderContent={(summaryMode) => (
                    <>
                        {this.state.showOpenInlineComments ? (
                            <TinyInlineComments
                                reportId={this.props.currentReportId}
                                selectedCommentId={this.state.selectedCommentId}
                                editorRef={this.props.narrativeCardEditorRef}
                            />
                        ) : undefined}
                        <TinyNarrativeCardContent
                            id={getNarrativeName(this.props.currentReportId)}
                            isExpandedFullScreen={isExpandedFullScreen}
                            isSticky={!summaryMode && !isExpandedFullScreen}
                            offset={this.state.cardHeaderHeight}
                            currentReportId={this.props.currentReportId}
                            summaryMode={summaryMode}
                            form={this.getForm()}
                            setError={this.setError}
                            editorRef={this.props.narrativeCardEditorRef}
                            getSummaryMode={this.getSummaryMode}
                            onToggleComments={this.onToggleComments}
                            onFullScreenToggle={this.onFullScreenToggleForTinyNarrativeCard}
                        />
                    </>
                )}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={summaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                onSave={this.onSaveProgress}
                saving={saving}
                testId={testIds.NARRATIVE_CARD}
                isExpandedFullScreen={isExpandedFullScreen}
                stickyHeader={!isExpandedFullScreen}
                hasBanner={hasBanner}
                renderFullScreenToggleHeaderButton={this.props.renderFullScreenToggleButton}
                showOpenInlineComments={this.state.showOpenInlineComments}
            />
        );
    }
}

class NarrativeCardWrapper extends React.Component {
    constructor() {
        super(...arguments);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={narrativeFormName}>
                {(arbiter) => {
                    return (
                        <NarrativeCardBase>
                            {({
                                narrativeCardEditorRef,
                                setIsExpandedFullScreen,
                                getIsExpandedFullScreen,
                                onFullScreenToggle,
                                onSaveSuccess,
                                renderFullScreenToggleButton,
                            }) => (
                                <NarrativeCard
                                    narrativeCardEditorRef={narrativeCardEditorRef}
                                    onSaveSuccess={onSaveSuccess}
                                    setIsExpandedFullScreen={setIsExpandedFullScreen}
                                    getIsExpandedFullScreen={getIsExpandedFullScreen}
                                    onFullScreenToggle={onFullScreenToggle}
                                    renderFullScreenToggleButton={renderFullScreenToggleButton}
                                    ref={this.setRef}
                                    {...this.props}
                                    arbiter={arbiter}
                                />
                            )}
                        </NarrativeCardBase>
                    );
                }}
            </RMSArbiterProvider>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    refreshNarrativeForm: (reportId) => {
        return dispatch(refreshNarrativeForm(reportId));
    },
    setErrorMessages: (errorMessages, options) => {
        return dispatch(narrativeCard.actionCreators.setErrorMessages(errorMessages, options));
    },
});
export default compose(
    withCard(narrativeCard),
    connect(
        createStructuredSelector({
            formatFieldByName: formatFieldByNameSelector,
            narrativeAutosaves: narrativeAutosavesSelector,
            canViewInlineComments: canViewInlineCommentsSelector,
            applicationSettings: applicationSettingsSelector,
            hasBanner: hasBannerSelector,
            currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
        }),
        mapDispatchToProps,
        null,
        { forwardRef: true }
    )
)(NarrativeCardWrapper);

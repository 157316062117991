import Resource from '../../resources/supportSearchSyncResource';

export const SYNC_ENTITY_START = 'search-sync/SYNC_ENTITY_START';
export const SYNC_ENTITY_SUCCESS = 'search-sync/SYNC_ENTITY_SUCCESS';
export const SYNC_ENTITY_FAILURE = 'search-sync/SYNC_ENTITY_FAILURE';

export const SYNC_TYPE_START = 'search-sync/SYNC_TYPE_START';
export const SYNC_TYPE_SUCCESS = 'search-sync/SYNC_TYPE_SUCCESS';
export const SYNC_TYPE_FAILURE = 'search-sync/SYNC_TYPE_FAILURE';

export const SYNC_MULTI_START = 'search-sync/SYNC_MULTI_START';
export const SYNC_MULTI_SUCCESS = 'search-sync/SYNC_MULTI_SUCCESS';
export const SYNC_MULTI_FAILURE = 'search-sync/SYNC_MULTI_FAILURE';

export const LOAD_SYNC_TYPE_STATUS_START = 'search-sync/LOAD_SYNC_TYPE_STATUS_START';
export const LOAD_SYNC_TYPE_STATUS_SUCCESS = 'search-sync/LOAD_SYNC_TYPE_STATUS_SUCCESS';
export const LOAD_SYNC_TYPE_STATUS_FAILURE = 'search-sync/LOAD_SYNC_TYPE_STATUS_FAILURE';

export const CLEAR_SYNC_TYPE_START = 'search-sync/CLEAR_SYNC_TYPE_START';
export const CLEAR_SYNC_TYPE_SUCCESS = 'search-sync/CLEAR_SYNC_TYPE_SUCCESS';
export const CLEAR_SYNC_TYPE_FAILURE = 'search-sync/CLEAR_SYNC_TYPE_FAILURE';

export const GET_ARE_ENTITIES_SYNCED_START = 'search-sync/GET_ARE_ENTITIES_SYNCED_START';
export const GET_ARE_ENTITIES_SYNCED_SUCCESS = 'search-sync/GET_ARE_ENTITIES_SYNCED_SUCCESS';
export const GET_ARE_ENTITIES_SYNCED_FAILURE = 'search-sync/GET_ARE_ENTITIES_SYNCED_FAILURE';

function syncEntityStart() {
    return {
        type: SYNC_ENTITY_START,
    };
}

function syncEntitySuccess() {
    return {
        type: SYNC_ENTITY_SUCCESS,
    };
}

function syncEntityFailure(error = {}) {
    return {
        type: SYNC_ENTITY_FAILURE,
        payload: error.message,
    };
}

function syncTypeStart() {
    return {
        type: SYNC_TYPE_START,
    };
}

function syncTypeSuccess(data) {
    return {
        type: SYNC_TYPE_SUCCESS,
        payload: data,
    };
}

function syncTypeFailure(error = {}) {
    return {
        type: SYNC_TYPE_FAILURE,
        payload: error.message,
    };
}

function syncMultiStart() {
    return {
        type: SYNC_MULTI_START,
    };
}

function syncMultiSuccess(data) {
    return {
        type: SYNC_MULTI_SUCCESS,
        payload: data,
    };
}

function syncMultiFailure(module, error = {}) {
    return {
        type: SYNC_MULTI_FAILURE,
        payload: {
            module,
            error: error.message,
        },
    };
}

function loadSyncTypeStatusStart() {
    return {
        type: LOAD_SYNC_TYPE_STATUS_START,
    };
}

function loadSyncTypeStatusSuccess(data) {
    return {
        type: LOAD_SYNC_TYPE_STATUS_SUCCESS,
        payload: data,
    };
}

function loadSyncTypeStatusFailure(error = {}) {
    return {
        type: LOAD_SYNC_TYPE_STATUS_FAILURE,
        payload: error.message,
    };
}

function clearSyncTypeStart() {
    return {
        type: CLEAR_SYNC_TYPE_START,
    };
}

function clearSyncTypeSuccess() {
    return {
        type: CLEAR_SYNC_TYPE_SUCCESS,
    };
}

function clearSyncTypeFailure(error = {}) {
    return {
        type: CLEAR_SYNC_TYPE_FAILURE,
        payload: error.message,
    };
}

function getAreEntitiesSyncedStart() {
    return {
        type: GET_ARE_ENTITIES_SYNCED_START,
    };
}

function getAreEntitiesSyncedSuccess(data) {
    return {
        type: GET_ARE_ENTITIES_SYNCED_SUCCESS,
        payload: data,
    };
}

function getAreEntitiesSyncedFailure(error = {}) {
    return {
        type: GET_ARE_ENTITIES_SYNCED_FAILURE,
        payload: error.message,
    };
}

export function kickOffSearchSyncForEntities(module, entityType, entityIds) {
    return function (dispatch) {
        dispatch(syncEntityStart());
        Resource.kickOffSearchSyncForEntities(module, entityType, entityIds)
            .then(() => {
                dispatch(getAreEntitiesSyncedStart());
                dispatch(syncEntitySuccess());
                return dispatch(getAreEntitiesSynced(module, entityType, entityIds));
            })
            .catch((error) => {
                dispatch(syncEntityFailure(error));
            });
    };
}

export function loadSyncTypeStatus() {
    return (dispatch) => {
        dispatch(loadSyncTypeStatusStart());

        Resource.getSyncTypeStatus()
            .then((data) => dispatch(loadSyncTypeStatusSuccess(data)))
            .catch((error) => dispatch(loadSyncTypeStatusFailure(error)));
    };
}

export function syncRmsReportType(reportDefinitionId) {
    return function (dispatch) {
        dispatch(syncTypeStart());
        Resource.syncRmsReportType(reportDefinitionId)
            .then((data) => {
                dispatch(syncTypeSuccess(data));
                return dispatch(loadSyncTypeStatus());
            })
            .catch((error) => {
                dispatch(syncTypeFailure(error));
            });
    };
}

export function partialSearchSyncEntities(module, entityType, startDateTime) {
    return function (dispatch) {
        dispatch(syncTypeStart());
        Resource.partialSearchSyncEntities(module, entityType, startDateTime)
            .then((data) => {
                dispatch(syncTypeSuccess(data));
                return dispatch(loadSyncTypeStatus());
            })
            .catch((error) => {
                dispatch(syncTypeFailure(error));
            });
    };
}

export function syncType(module, entityType) {
    return function (dispatch) {
        dispatch(syncTypeStart());
        Resource.syncType(module, entityType)
            .then((data) => {
                dispatch(syncTypeSuccess(data));
                return dispatch(loadSyncTypeStatus());
            })
            .catch((error) => {
                dispatch(syncTypeFailure(error));
            });
    };
}

export function syncMulti(entityTypesByModule, departments) {
    return function (dispatch) {
        dispatch(syncMultiStart());
        const requests = [];
        if (entityTypesByModule['RMS']) {
            requests.push(
                Resource.syncMulti('RMS', entityTypesByModule['RMS'], departments)
                    .then((data) => {
                        dispatch(syncMultiSuccess(data));
                    })
                    .catch((error) => {
                        dispatch(syncMultiFailure('RMS', error));
                    })
            );
        }
        if (entityTypesByModule['CAD']) {
            requests.push(
                Resource.syncMulti('CAD', entityTypesByModule['CAD'], departments)
                    .then((data) => {
                        dispatch(syncMultiSuccess(data));
                    })
                    .catch((error) => {
                        dispatch(syncMultiFailure('CAD', error));
                    })
            );
        }
        if (entityTypesByModule['EVIDENCE']) {
            requests.push(
                Resource.syncMulti('EVIDENCE', entityTypesByModule['EVIDENCE'], departments)
                    .then((data) => {
                        dispatch(syncMultiSuccess(data));
                    })
                    .catch((error) => {
                        dispatch(syncMultiFailure('EVIDENCE', error));
                    })
            );
        }
        return Promise.all(requests);
    };
}

export function clearSyncType() {
    return function (dispatch) {
        dispatch(clearSyncTypeStart());
        Resource.clearSyncType()
            .then(() => {
                dispatch(clearSyncTypeSuccess());
                return dispatch(loadSyncTypeStatus());
            })
            .catch((error) => dispatch(clearSyncTypeFailure(error)));
    };
}

export function getAreEntitiesSynced(module, entityType, ids) {
    return function (dispatch) {
        dispatch(getAreEntitiesSyncedStart());
        Resource.getAreEntitiesSynced(module, entityType, ids)
            .then((data) => dispatch(getAreEntitiesSyncedSuccess(data)))
            .catch((error) => dispatch(getAreEntitiesSyncedFailure(error)));
    };
}

import React from 'react';
import { connect } from 'react-redux';
import { impoundViewModelsSelector } from '~/client-common/core/domain/impounds/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import testIds from '../../../../../core/testIds';

function TowVehicleImpoundCardSummary({ impoundViewModel }) {
    const {
        nicNumberOriginalIsLocal,
        nicNumberCancellationIsLocal,
        keysInVehicle,
        vehicleLocked,
    } = getViewModelProperties(impoundViewModel);

    return (
        <div>
            <CardSection testId={testIds.CARD_SUMMARY}>
                <SummaryList labelWidth={180} contentWidth={360}>
                    <SummaryRowDate
                        fieldName={fields.IMPOUND_NIC_NUMBER_ORIGINAL_DATE_UTC}
                        date={impoundViewModel.nicNumberOriginalDateUtc}
                    />
                    <SummaryRow fieldName={fields.IMPOUND_NIC_NUMBER_ORIGINAL}>
                        {impoundViewModel.nicNumberOriginal}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.IMPOUND_NIC_NUMBER_ORIGINAL_IS_LOCAL}>
                        {nicNumberOriginalIsLocal}
                    </SummaryRow>
                    <SummaryRowDate
                        fieldName={fields.IMPOUND_NIC_NUMBER_CANCELLATION_DATE_UTC}
                        date={impoundViewModel.nicNumberCancellationDateUtc}
                    />
                    <SummaryRow fieldName={fields.IMPOUND_NIC_NUMBER_CANCELLATION}>
                        {impoundViewModel.nicNumberCancellation}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.IMPOUND_NIC_NUMBER_CANCELLATION_IS_LOCAL}>
                        {nicNumberCancellationIsLocal}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.IMPOUND_KEYS_IN_VEHICLE}>
                        {keysInVehicle}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.IMPOUND_VEHICLE_LOCKED}>
                        {vehicleLocked}
                    </SummaryRow>
                </SummaryList>
            </CardSection>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    const impoundId = ownProps.impoundId;
    const impoundViewModel = impoundViewModelsSelector(state)[impoundId] || {};
    return {
        impoundViewModel,
    };
};

export default connect(mapStateToProps)(TowVehicleImpoundCardSummary);

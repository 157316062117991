import PropTypes from 'prop-types';
import React from 'react';
import { compose, defaultProps, setDisplayName, setPropTypes } from 'recompose';
import keyMirror from 'keymirror';

import { getDropdownOptionsFromObject } from '~/client-common/helpers/dropdownOptionHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_OFFENSE_GROUP_LABEL } from '~/client-common/core/enums/universal/fields';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import MultiFieldSelect from './MultiFieldSelect';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const { options } = componentStrings.forms.select.OffenseGroupSelect;
const nibrsGroupOptions = getDropdownOptionsFromObject(options.nibrsGroups);
const ucrGroupOptions = getDropdownOptionsFromObject(options.ucrGroups);

/**
 * `MultiFieldSelect` dropdown containing all offense groups from both UCR and
 *   NIBRS. This dropdown models 2 separate form fields, for both types.
 * @param {Object} props
 * @param {Object} props.fields
 */
function OffenseGroupSelect({ fields: { nibrsGroups, ucrGroups }, ...otherProps }) {
    const displayFieldNames = useFields(DISPLAY_ONLY_OFFENSE_GROUP_LABEL);

    return (
        <MultiFieldSelect
            label={displayFieldNames.DISPLAY_ONLY_OFFENSE_GROUP_LABEL}
            {...otherProps}
            fields={{
                nibrsGroups: {
                    options: nibrsGroupOptions,
                    ...nibrsGroups,
                },
                ucrGroups: {
                    options: ucrGroupOptions,
                    ...ucrGroups,
                },
            }}
        />
    );
}

export const RRFOffenseGroupSelect = compose(
    setDisplayName('RRFOffenseGroupSelect'),
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            nibrsGroups: null,
            ucrGroups: null,
        }),
    }),
    connectRRFMultiFieldInput
)(OffenseGroupSelect);

import {
    upsertPropertyStatuses,
    deletePropertyStatuses,
} from '~/client-common/core/domain/property-statuses/state/data';
import {
    linkPropertyStatusesToOffenseId,
    fillNewPropertyStatusesWithNegativeIds,
} from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import { openBox, closeBox, saveBoxFailure } from '../../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../../core/box/state/ui';

const context = { name: boxEnum.LINK_ITEMS_TO_OFFENSE_SIDE_PANEL };

/**
 * Open the side panel for linking items to an offense.
 */
export function openLinkItemsToOffenseSidePanel({
    isVehicle,
    ownerId,
    ownerType,
    offenseId,
    selectedPropertyStatusIds = [],
    offenseNibrsCode,
    nibrsAllowedProperty,
}) {
    return openBox(context, {
        isVehicle,
        ownerId,
        ownerType,
        offenseId,
        selectedPropertyStatusIds,
        offenseNibrsCode,
        nibrsAllowedProperty,
    });
}

/**
 * Close the Link Items to Offense side panel.
 */
export function closeLinkItemsToOffenseSidePanel() {
    return closeBox(context);
}

/**
 * Submit the Link Items to Offense side panel by creating/updating/deleting propertyStatuses based
 *   on their offenseId.
 * @param {number[]} selectedPropertyStatusIds
 * @param {Object[]} propertyStatuses
 */
export function submitLinkItemsToOffenseSidePanel(selectedPropertyStatusIds, propertyStatuses) {
    return function (dispatch, getState) {
        const state = getState();
        const offenseId = linkItemsToOffenseSidePanelOffenseIdSelector(state);
        const {
            propertyStatusesToUpsert,
            propertyStatusIdsToDelete,
        } = linkPropertyStatusesToOffenseId(propertyStatuses, selectedPropertyStatusIds, offenseId);

        return Promise.all([
            propertyStatusesToUpsert.length > 0
                ? dispatch(
                      upsertPropertyStatuses(
                          fillNewPropertyStatusesWithNegativeIds(propertyStatusesToUpsert)
                      )
                  )
                : undefined,
            propertyStatusIdsToDelete.length > 0
                ? dispatch(deletePropertyStatuses(propertyStatusIdsToDelete))
                : undefined,
        ])
            .then(() => {
                dispatch(closeLinkItemsToOffenseSidePanel());
            })
            .catch(() => {
                dispatch(saveBoxFailure(context));
            });
    };
}

export const linkItemsToOffenseSidePanelIsVehicleSelector = createModalSelector(
    context,
    'isVehicle'
);
export const linkItemsToOffenseSidePanelOwnerIdSelector = createModalSelector(context, 'ownerId');
export const linkItemsToOffenseSidePanelOwnerTypeSelector = createModalSelector(
    context,
    'ownerType'
);
export const linkItemsToOffenseSidePanelOffenseIdSelector = createModalSelector(
    context,
    'offenseId'
);
export const linkItemsToOffenseSidePanelOffenseNibrsCodeSelector = createModalSelector(
    context,
    'offenseNibrsCode'
);
export const linkItemsToOffenseSidePanelNibrsAllowedPropertySelector = createModalSelector(
    context,
    'nibrsAllowedProperty'
);

import styled from 'styled-components';
import Button from '../../../../legacy-redux/components/core/Button';

const EntityRowActionButton = styled(Button)`
    float: none;
    display: inline-block;
    margin: 0;
`;

export default EntityRowActionButton;

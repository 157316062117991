import { EntityTypeEnumType, NameCaseLink, ItemCaseLink } from '@mark43/rms-api';
import pick from 'lodash/pick';
import keyBy from 'lodash/keyBy';

import {
    NameCaseLinkFormModel,
    NameCaseLinkFromReportFormModel,
    ItemCaseLinkFormModel,
} from '../state/forms/caseLinkedProfilesForm';

const convertNameCaseLinkToGenericNameCaseLink = (
    link: NameCaseLink
): NameCaseLinkFormModel | NameCaseLinkFromReportFormModel => {
    return pick(link, [
        'nameId',
        'entityType',
        'id',
        'involvementTypeAttrId',
        'targetProfileLevelAttrId',
    ]);
};

const convertItemCaseLinkToGenericItemCaseLink = (link: ItemCaseLink): ItemCaseLinkFormModel => {
    return pick(link, ['itemId', 'entityType', 'id', 'targetProfileLevelAttrId']);
};

const createGenericNameCaseLinkFromRelatedEntityId = ({
    id,
    entityType,
}: {
    id: number;
    entityType: EntityTypeEnumType;
}): NameCaseLinkFormModel | NameCaseLinkFromReportFormModel => {
    return {
        nameId: id,
        entityType,
        id,
    };
};

const createGenericItemCaseLinkFromRelatedEntityId = ({
    id,
    entityType,
}: {
    id: number;
    entityType: EntityTypeEnumType;
}): ItemCaseLinkFormModel => {
    return {
        itemId: id,
        entityType,
        id,
    };
};

export const createGenericNameCaseLinks = ({
    relatedEntityIds,
    nameCaseLinks,
    entityType,
    checkProfileIsFromReport,
}: {
    relatedEntityIds: number[];
    nameCaseLinks: NameCaseLink[];
    entityType: EntityTypeEnumType;
    checkProfileIsFromReport: (masterProfileId: number, entityType: EntityTypeEnumType) => boolean;
}) => {
    const nameCaseLinksByNameId = keyBy(nameCaseLinks, 'nameId');

    return relatedEntityIds.reduce<{
        regular: NameCaseLinkFormModel[];
        fromReport: NameCaseLinkFromReportFormModel[];
    }>(
        (acc, entityId) => {
            let genericNameCaseLink: NameCaseLinkFormModel | NameCaseLinkFromReportFormModel;
            
            if (entityId in nameCaseLinksByNameId) {
                genericNameCaseLink = convertNameCaseLinkToGenericNameCaseLink(
                    nameCaseLinksByNameId[entityId]
                );
            } else {
                genericNameCaseLink = createGenericNameCaseLinkFromRelatedEntityId({
                    id: entityId,
                    entityType,
                });
            }

            if (
                genericNameCaseLink.nameId &&
                checkProfileIsFromReport(genericNameCaseLink.nameId, entityType)
            ) {
                acc.fromReport = acc.fromReport.concat(genericNameCaseLink);
            } else {
                acc.regular = acc.regular.concat(genericNameCaseLink);
            }

            return acc;
        },
        { regular: [], fromReport: [] }
    );
};

export const createGenericItemCaseLinks = ({
    relatedEntityIds,
    itemCaseLinks,
    entityType,
}: {
    relatedEntityIds: number[];
    itemCaseLinks: ItemCaseLink[];
    entityType: EntityTypeEnumType;
}) => {
    const itemCaseLinksByItemId = keyBy(itemCaseLinks, 'itemId');

    return relatedEntityIds.map((id) => {
        if (id in itemCaseLinksByItemId) {
            return convertItemCaseLinkToGenericItemCaseLink(itemCaseLinksByItemId[id]);
        }

        return createGenericItemCaseLinkFromRelatedEntityId({ id, entityType });
    });
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RecordsHeaderExportsButton from '../../../../records/core/components/header/RecordsHeaderExportsButton';
import routesConfig from '../../../../../routing/routesConfig';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

function ReportHeaderExportsButton({ currentReportId, asDropdownOption = false }) {
    return (
        <RecordsHeaderExportsButton
            exportRouteName={routesConfig.REPORT_EXPORTS.name}
            path={`/reports/${currentReportId}`}
            asDropdownOption={asDropdownOption}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    currentReportId: currentReportIdSelector,
});

export default connect(mapStateToProps)(ReportHeaderExportsButton);

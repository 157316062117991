import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';

import _SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import _SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import { EntityFlexRow } from '../../core/components/RecordPrivacyRow';
import EntityRowActionButton from '../../core/components/EntityRowActionButton';
import Tag from '../../core/components/Tag';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import testIds from '../../../../core/testIds';

const ChargeOffenseCode = styled.span`
    font-size: var(--arc-fontSizes-sm);
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const ChargeInfoContainer = styled.div`
    flex: 1 1 100%;
`;

const SummaryList = styled(_SummaryList)`
    display: block;
`;

const SummaryRow = styled(_SummaryRow)`
    && {
        display: block;
    }
`;

const { recordPrivacy } = componentStrings;

class ChargeRow extends React.Component {
    handleVacateClick = () => this.props.onVacateChange(this.props.charge, !this.props.vacated);
    render() {
        const { vacated, charge, formatAttributeById } = this.props;
        return (
            <EntityFlexRow>
                <ChargeInfoContainer data-test-id={testIds.VACATE_CHARGE_ROW}>
                    {vacated && <Tag>{recordPrivacy.vacating.ChargeRow.vacateTag}</Tag>}
                    <ChargeOffenseCode>
                        {this.props.formatOffenseCodeById({
                            id: charge.chargeOffenseCodeId,
                            includeCode: false,
                        })}
                    </ChargeOffenseCode>
                    {!!charge.dispositionAttrId && (
                        <SummaryList labelWidth={130} contentWidth="calc(100% - 140px)">
                            <SummaryRow fieldName={fields.CHARGE_DISPOSITION_ATTR_ID}>
                                {formatAttributeById(charge.dispositionAttrId)}
                            </SummaryRow>
                        </SummaryList>
                    )}
                </ChargeInfoContainer>
                <EntityRowActionButton
                    className={buttonTypes.SECONDARY}
                    onClick={this.handleVacateClick}
                    active={vacated}
                    testId={testIds.VACATE_CHARGE_BUTTON}
                >
                    {recordPrivacy.vacating.ChargeRow.vacateCharge}
                </EntityRowActionButton>
            </EntityFlexRow>
        );
    }
}

export default connect(
    createStructuredSelector({
        formatOffenseCodeById: formatOffenseCodeByIdSelector,
        formatAttributeById: formatAttributeByIdSelector,
    })
)(ChargeRow);

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { getDropdownOptionsFromStrings } from '../../../../helpers/dropdownOptionHelpers';

const STORE_TIME_ZONES = 'constants/STORE_TIME_ZONES';
const STORE_UNKNOWN_LOCATION_ID = 'constants/STORE_UNKNOWN_LOCATION_ID';

export function storeTimeZones(timeZones: string[]) {
    return {
        type: STORE_TIME_ZONES,
        payload: timeZones,
    };
}

export function storeUnknownLocationId(id: number) {
    return {
        type: STORE_UNKNOWN_LOCATION_ID,
        payload: id,
    };
}

function timeZonesReducer(
    state = {},
    action: ReturnType<typeof storeTimeZones>
): string[] | Record<string, unknown> {
    switch (action.type) {
        case STORE_TIME_ZONES:
            return action.payload || state;
        default:
            return state;
    }
}

function unknownLocationIdReducer(
    state = {},
    action: ReturnType<typeof storeUnknownLocationId>
): number | Record<string, unknown> {
    switch (action.type) {
        case STORE_UNKNOWN_LOCATION_ID:
            return action.payload || state;
        default:
            return state;
    }
}

export default combineReducers({
    timeZones: timeZonesReducer,
    unknownLocationId: unknownLocationIdReducer,
});

const constantsSelector: (
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    state: any
) => {
    timeZones: ReturnType<typeof timeZonesReducer>;
    unknownLocationId: ReturnType<typeof unknownLocationIdReducer>;
} = (state) => state.constants;

const timeZonesSelector = createSelector(constantsSelector, ({ timeZones }) => timeZones);

export const unknownLocationIdSelector = createSelector(
    constantsSelector,
    ({ unknownLocationId }) => unknownLocationId
);

export const timeZoneOptionsSelector = createSelector(timeZonesSelector, (timeZoneOptions) =>
    // @ts-expect-error legacy-todo - the initial state for this should be moved to an array
    getDropdownOptionsFromStrings(timeZoneOptions)
);

import { StaffRemark, EntityTypeEnumType, ItemProfile, EntityTypeEnum } from '@mark43/evidence-api';
import _, { get } from 'lodash';
import { createSelector } from 'reselect';
import { latestChainOfCustodyForMasterItemIdSelector } from '../../../chain-of-custodies/state/data';
import { itemProfilesSelector } from '../../../item-profiles/state/data';

import getStaffRemarkResource from '../../resources/staffRemarkResource';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { ClientCommonAction } from '../../../../../redux/types';

const { REPORT, ITEM_PROFILE } = EntityTypeEnum;

export const NEXUS_STATE_PROP = 'staffRemarks';

const staffRemarksModule = createNormalizedModule<StaffRemark>({
    type: NEXUS_STATE_PROP,
});

// ACTION TYPES
const CREATE_STAFF_REMARKS_START = 'staff-remarks/CREATE_STAFF_REMARKS_START';
const CREATE_STAFF_REMARKS_SUCCESS = 'staff-remarks/CREATE_STAFF_REMARKS_SUCCESS';
const CREATE_STAFF_REMARKS_FAILURE = 'staff-remarks/CREATE_STAFF_REMARKS_FAILURE';
const LOAD_STAFF_REMARKS_FOR_ENTITY_START = 'staff-remarks/LOAD_STAFF_REMARKS_FOR_ENTITY_START';
const LOAD_STAFF_REMARKS_FOR_ENTITY_SUCCESS = 'staff-remarks/LOAD_STAFF_REMARKS_FOR_ENTITY_SUCCESS';
const LOAD_STAFF_REMARKS_FOR_ENTITY_FAILURE = 'staff-remarks/LOAD_STAFF_REMARKS_FOR_ENTITY_FAILURE';
const DELETE_STAFF_REMARKS_START = 'staff-remarks/DELETE_STAFF_REMARKS_START';
const DELETE_STAFF_REMARKS_SUCCESS = 'staff-remarks/DELETE_STAFF_REMARKS_SUCCESS';
const DELETE_STAFF_REMARKS_FAILURE = 'staff-remarks/DELETE_STAFF_REMARKS_FAILURE';

// ACTIONS
const storeStaffRemarks = staffRemarksModule.actionCreators.storeEntities;
const deleteStaffRemarksWhere = staffRemarksModule.actionCreators.deleteEntitiesWhere;

function createStaffRemarksStart() {
    return {
        type: CREATE_STAFF_REMARKS_START,
    };
}

function createStaffRemarksSuccess() {
    return {
        type: CREATE_STAFF_REMARKS_SUCCESS,
    };
}

function createStaffRemarksFailure(errorMessage: string) {
    return {
        type: CREATE_STAFF_REMARKS_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Create staff remarks.
 */
export function createStaffRemarks(
    staffRemarks: StaffRemark[]
): ClientCommonAction<Promise<StaffRemark[]>> {
    const staffRemarkResource = getStaffRemarkResource();

    return function (dispatch) {
        dispatch(createStaffRemarksStart());
        return staffRemarkResource
            .createStaffRemarks(staffRemarks)
            .then((staffRemarks: StaffRemark[]) => {
                dispatch(createStaffRemarksSuccess());
                dispatch(storeStaffRemarks(staffRemarks));
                return staffRemarks;
            })
            .catch((err: Error) => {
                dispatch(createStaffRemarksFailure(err.message));
                throw err;
            });
    };
}

function loadStaffRemarksForEntityStart(entityType: EntityTypeEnumType, entityId: number) {
    return {
        type: LOAD_STAFF_REMARKS_FOR_ENTITY_START,
        payload: { entityType, entityId },
    };
}

function loadStaffRemarksForEntitySuccess() {
    return {
        type: LOAD_STAFF_REMARKS_FOR_ENTITY_SUCCESS,
    };
}

function loadStaffRemarksForEntityFailure(errorMessage: string) {
    return {
        type: LOAD_STAFF_REMARKS_FOR_ENTITY_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Load all staff remarks for the given entity.
 */
function loadStaffRemarksForEntity(
    entityType: EntityTypeEnumType,
    entityId: number
): ClientCommonAction<Promise<StaffRemark[]>> {
    const staffRemarkResource = getStaffRemarkResource();

    return function (dispatch) {
        dispatch(loadStaffRemarksForEntityStart(entityType, entityId));
        return staffRemarkResource
            .getStaffRemarksForEntity(entityType, entityId)
            .then((staffRemarks: StaffRemark[]) => {
                dispatch(loadStaffRemarksForEntitySuccess());
                dispatch(storeStaffRemarks(staffRemarks));
                return staffRemarks;
            })
            .catch((err: Error) => {
                dispatch(loadStaffRemarksForEntityFailure(err.message));
                throw err;
            });
    };
}

/**
 * Load report-level staff remarks for the given contexted item profiles (not master profiles). This
 *   action creator is needed because report-level staff remarks are not included on
 *   EvidenceHydratedItem.
 */
export function loadReportLevelStaffRemarksForItemProfiles(
    itemProfiles: ItemProfile[]
): ClientCommonAction<void> {
    return function (dispatch) {
        _(itemProfiles)
            // non-custodial reports are included here; ideally we'd filter these down to only
            // custodial property summary reports, but that will complicate this code and we may end
            // up including report-level remarks in a bundle
            .filter({ ownerType: EntityTypeEnum.REPORT.name })
            .map('ownerId')
            .uniq()
            .forEach((ownerId) => {
                dispatch(loadStaffRemarksForEntity(EntityTypeEnum.REPORT.name, ownerId));
            });
    };
}

function deleteStaffRemarkStart(id: number) {
    return {
        type: DELETE_STAFF_REMARKS_START,
        payload: id,
    };
}

function deleteStaffRemarkSuccess(id: number) {
    return {
        type: DELETE_STAFF_REMARKS_SUCCESS,
        payload: id,
    };
}

function deleteStaffRemarkFailure(errorMessage: string) {
    return {
        type: DELETE_STAFF_REMARKS_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Delete a staff remark.
 */
export function deleteStaffRemark(id: number): ClientCommonAction<Promise<void>> {
    const staffRemarkResource = getStaffRemarkResource();

    return function (dispatch) {
        dispatch(deleteStaffRemarkStart(id));
        return staffRemarkResource
            .deleteStaffRemark(id)
            .then(() => {
                dispatch(deleteStaffRemarkSuccess(id));
                dispatch(deleteStaffRemarksWhere({ id }));
                return;
            })
            .catch((err: Error) => {
                dispatch(deleteStaffRemarkFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
const staffRemarksSelector = staffRemarksModule.selectors.entitiesSelector;

export const staffRemarksByEntitySelector = createSelector(
    staffRemarksSelector,
    (staffRemarks) => (entityType: EntityTypeEnumType, entityId?: number) =>
        _(staffRemarks).filter({ entityType, entityId }).sortBy('remarkDateUtc').reverse().value()
);

/**
 * Find all the staff remarks by evidence item id
 */
export const staffRemarksByMasterItemIdSelector = createSelector(
    latestChainOfCustodyForMasterItemIdSelector,
    staffRemarksByEntitySelector,
    itemProfilesSelector,
    (latestChainOfCustodyForMasterItemId, staffRemarksByEntity, itemProfiles) => (
        masterItemId: number
    ) => {
        const custodialReportId = get(
            latestChainOfCustodyForMasterItemId(masterItemId),
            'reportId'
        );
        const itemProfileId = _.chain(itemProfiles)
            .find(
                ({ masterItemId: masterId, ownerId, ownerType }) =>
                    masterId === masterItemId &&
                    ownerId === custodialReportId &&
                    ownerType === EntityTypeEnum.REPORT.name
            )
            .get('id')
            .value();

        return _.sortBy(
            [
                ...staffRemarksByEntity(ITEM_PROFILE.name, itemProfileId),
                ...staffRemarksByEntity(REPORT.name, custodialReportId),
            ],
            'remarkDateUtc'
        ).reverse();
    }
);

// REDUCER
export default staffRemarksModule.reducerConfig;

import keyMirror from 'keymirror';

export const columnKeys = keyMirror({
    title: null,
    ownerTitle: null,
    assigneeRoleId: null,
    dueDateUtc: null,
    updatedDateUtc: null,
    createdDateUtc: null,
    priorityAttrId: null,
    typeAttrId: null,
    statusAttrId: null,
});

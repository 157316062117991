import { RoleTypeEnum } from '@mark43/rms-api';
import React from 'react';

import { pure } from 'recompose';
import { DISPLAY_CASE_SUPERVISORS } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFCaseSupervisorSelect } from '../../../core/forms/components/selects/CaseSupervisorSelect';

import fieldsetViewModel from '../state/forms/myCasesSearchPersonnelFieldset';

/**
 * Fieldset for personnel (involved users) in the My Cases search form.
 */
export default pure(function MyCasesSearchPersonnelFieldset() {
    const supervisorsLabel = useFields(DISPLAY_CASE_SUPERVISORS)[DISPLAY_CASE_SUPERVISORS];
    return (
        <RRFFieldset path="personnel" title={fieldsetViewModel.title}>
            <Row>
                <RRFCaseSupervisorSelect
                    multiple={true}
                    width={260}
                    label={supervisorsLabel}
                    paths={{
                        hasSupervisor: 'hasSupervisor',
                        supervisorRoleIds: 'supervisorRoleIds',
                    }}
                    roleTypes={RoleTypeEnum.USER.name}
                    effectiveDate={null}
                />
            </Row>
        </RRFFieldset>
    );
});

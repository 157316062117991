import React from 'react';
import { ExportIntervalTypeEnum, ExportIntervalTypeEnumType } from '@mark43/rms-api';
import { FormattedDate } from '~/client-common/core/dates/components';
import {
    dateTimeFormats,
    formatDayMonthYear,
    monthYearNumbersToLongMonthYear,
} from '~/client-common/core/dates/utils/dateHelpers';

type PropsT = {
    exportIntervalType: ExportIntervalTypeEnumType;
    day?: number;
    month?: number;
    year?: number;
    startDate?: string;
    endDate?: string;
};

const ComplianceFormattedDateCell: React.FC<PropsT> = ({
    exportIntervalType,
    day,
    month,
    year,
    startDate,
    endDate,
}) => {
    if (exportIntervalType === ExportIntervalTypeEnum.MONTHLY.name && month && year && day) {
        return <span>{monthYearNumbersToLongMonthYear(month, year)}</span>;
    } else if (exportIntervalType === ExportIntervalTypeEnum.TN_WEEKLY.name && year && month && day) {
        return <span>{formatDayMonthYear(day, month, year, dateTimeFormats.summaryDate)}</span>;
    } else if (exportIntervalType === ExportIntervalTypeEnum.YEARLY.name && year) {
        return <span>{year}</span>;
    } else if ((exportIntervalType === ExportIntervalTypeEnum.NONE.name
        || exportIntervalType === ExportIntervalTypeEnum.TN_WEEKLY.name) && startDate && endDate) {
        return (
            <span>
                <FormattedDate date={startDate} format={FormattedDate.FORMATS.FORM_DATE} />
                {' - '}
                <FormattedDate date={endDate} format={FormattedDate.FORMATS.FORM_DATE} />
            </span>
        );
    }
    return null;
};

export default ComplianceFormattedDateCell;

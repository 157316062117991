import { CadTicketUnitAndMember } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'cadTicketsUnitsAndMembers';

const cadTicketUnitAndMember = createNormalizedModule<CadTicketUnitAndMember>({
    type: NEXUS_STATE_PROP,
});

// REDUCER
export default cadTicketUnitAndMember.reducerConfig;

import { createField, createFieldset } from 'markformythree';
import {
    RefContextEnumType,
    CoreModelEnumType,
    LinkTypesEnumType,
    FieldTypeEnumType,
    ValueTypeEnumType,
    RefContextEnum,
    CoreModelEnum,
    ValueTypeEnum,
} from '@mark43/rms-api';
import { orderBy, last } from 'lodash';
import { BuildFormConfigurationForCoreModelAdditionalData } from '../types';
import { propertiesToIgnore } from '../constants/constants';
import { logError } from '../../../../../core/logging';

const isValueType = (maybeValueType: string): maybeValueType is ValueTypeEnumType => {
    if (maybeValueType in ValueTypeEnum) {
        return true;
    }
    logError('Expected a valid `ValueType`', {
        extras: {
            value: maybeValueType,
        },
    });
    return false;
};

const valueTypesForFieldType: Partial<Record<FieldTypeEnumType, ValueTypeEnumType[]>> = {
    ATTRIBUTE: [ValueTypeEnum.ID.name],
    BOOLEAN: [ValueTypeEnum.BOOLEAN.name],
    DATE: [ValueTypeEnum.DATETIME.name],
    DATETIME: [ValueTypeEnum.DATETIME.name],
    NUMBER: [ValueTypeEnum.DECIMAL.name],
    INTEGER: [ValueTypeEnum.BIGINT.name],
    USER: [ValueTypeEnum.ID.name],
    STRING: [ValueTypeEnum.STRING.name],
};

function isFieldTypeAndValueTypeCompatible(
    fieldType: FieldTypeEnumType,
    valueType: ValueTypeEnumType
) {
    return !!valueTypesForFieldType[fieldType]?.includes(valueType);
}

/**
 * Dynamically construct form configuration neccessary for dragon properties that
 * should be supported by the given form
 *
 * When the fields end up getting rendered on the FE, they will use the ordering
 * present in the configuration to determine the order in which to render the fields.
 *
 * In order to immediately meet a deadline for the TNIBRS project, we've introduced
 * this new prop called `__orderedPropertyNames` which enables us to specify a hardcoded list of fields
 * and the order in which they should appear in the form
 */
export const buildFormConfigurationForCoreModel = (
    params: {
        /**
         * The keyName at which to render the fieldset
         */
        keyName: string;
        /**
         * The core model whose properties we'd like to build a form configuration for
         */
        coreModelName: CoreModelEnumType;
        /**
         * The context that we'll be adding the form configuration to
         */
        context: RefContextEnumType;
        /**
         * All the data from the redux store that we need
         * in order to build out configuration properly
         */
        additionalData: BuildFormConfigurationForCoreModelAdditionalData;
        /**
         * TEMPORARY: A list of ordered `propertyNames`
         */
        __orderedPropertyNames?: string[];
    } & (
        | {
              genericFieldType?: never;
              genericFieldTypeMappedId?: never;
          }
        | {
              /**
               * If specified, we filter down to a subset of
               * dragon properties that we care about
               */
              genericFieldType: 'LINK_TYPE';
              genericFieldTypeMappedId: LinkTypesEnumType;
          }
    )
) => {
    const coreModelConfiguration = params.additionalData.coreModelConfigurations.find(
        (coreModelConfiguration) =>
            coreModelConfiguration.keyName === CoreModelEnum[params.coreModelName].keyName
    );
    if (!coreModelConfiguration) {
        return undefined;
    }

    const dragonManagedPropertiesForCoreModel = coreModelConfiguration.properties.filter(
        (property) => {
            return !property.isCore;
        }
    );

    const fieldConfigurationContextsForContext = params.additionalData.fieldConfigurationContexts.filter(
        (fieldConfigurationContext) =>
            fieldConfigurationContext.contextId === RefContextEnum[params.context].value
    );

    /**
     * When building out the configuration, we order them by `createDateUtc` of the
     * `fieldConfigurationContext` to provide some semblance of sort
     *
     * From all the `fieldConfigurationContexts` for this form, only pull out the ones
     * that refer to a dragon managed property
     */
    const fieldConfigurationBundles = fieldConfigurationContextsForContext.flatMap(
        (fieldConfigurationContext) => {
            const fieldConfiguration =
                params.additionalData.fieldConfigurations[
                    fieldConfigurationContext.fieldConfigurationId
                ];
            const fieldDetail =
                params.additionalData.fieldDetails[fieldConfiguration.fieldDetailId];

            const { propertyName, genericFieldTypeMappedId, genericFieldType, fieldType } =
                fieldDetail ?? {};

            // This feels kind of arbitrary, but should always end up matching
            const fieldDetailClassName = fieldDetail.className
                ? last(fieldDetail.className.split('.'))
                : undefined;

            const fieldConfigurationHasCorrespondingDragonProperty = dragonManagedPropertiesForCoreModel.find(
                (property) => property.keyName === propertyName
            );

            const propertyIsValid = propertyName
                ? !(propertiesToIgnore[params.context] || []).includes(propertyName)
                : false;

            if (
                fieldDetailClassName === coreModelConfiguration.keyName &&
                fieldConfigurationHasCorrespondingDragonProperty &&
                propertyName &&
                propertyIsValid &&
                isValueType(fieldConfigurationHasCorrespondingDragonProperty.valueType) &&
                isFieldTypeAndValueTypeCompatible(
                    fieldType,
                    fieldConfigurationHasCorrespondingDragonProperty.valueType
                ) &&
                // If we specified a `genericFieldType`, then we only
                // care about field details that also match this type
                (params.genericFieldType
                    ? params.genericFieldType === genericFieldType &&
                      params.genericFieldTypeMappedId === genericFieldTypeMappedId
                    : true)
            ) {
                return {
                    fieldConfigurationContext,
                    fieldConfiguration,
                    fieldDetail: {
                        // Spreading just to get the types right
                        // e.g. after the flatMap, `propertyName`
                        // will be non-nullable
                        // (even though `FieldDetail.propertyName` is nullable)
                        ...fieldDetail,
                        propertyName,
                    },
                };
            }
            return [];
        }
    );

    const sortedFieldConfigurationBundles = orderBy(
        fieldConfigurationBundles,
        [
            (fieldConfigurationBundle) => {
                const propertyName = fieldConfigurationBundle.fieldDetail.propertyName;
                const propertyNameSortOrder = (params.__orderedPropertyNames || []).findIndex(
                    (orderedPropertyName) => orderedPropertyName === propertyName
                );

                // If the property wasn't found in the ordered property names, push it to the end of the list
                if (propertyNameSortOrder === -1) {
                    return Infinity;
                }
                return propertyNameSortOrder;
            },
            (fieldConfigurationBundle) => {
                // For all the tie (e.g. fields not existing in the `orderedPropertyName`, sort by createdDate)
                return fieldConfigurationBundle.fieldConfigurationContext.createdDateUtc;
            },
        ],
        ['asc', 'asc']
    );

    return sortedFieldConfigurationBundles.length
        ? {
              [params.keyName]: createFieldset({
                  fields: sortedFieldConfigurationBundles.reduce<
                      Record<string, ReturnType<typeof createField>>
                  >((acc, fieldConfigurationBundle) => {
                      const propertyName = fieldConfigurationBundle.fieldDetail.propertyName;
                      const fieldName = fieldConfigurationBundle.fieldDetail.fieldName;
                      acc[propertyName] = createField({
                          fieldName,
                      });
                      return acc;
                  }, {}),
              }),
          }
        : undefined;
};

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { replaceTrafficCrashCardBundle } from '~/client-common/core/domain/reports/state/ui';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import { buildTrafficCrashCardBundle, refreshTrafficCrashForm } from '../forms/trafficCrashForm';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.TRAFFIC_CRASH.name,
    baseSelector: (state) => state.ui.report.trafficCrashCard,
    anchor: 'traffic-crash-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);
                const report = currentReportSelector(state);
                const reportId = report.id;

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const trafficCrashCardBundle = buildTrafficCrashCardBundle({
                                    form,
                                });

                                return [
                                    dispatch(
                                        replaceTrafficCrashCardBundle({
                                            reportId,
                                            trafficCrashCardBundle,
                                        })
                                    ),
                                ];
                            },
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                dispatch(refreshTrafficCrashForm({ reportId }));
                            },
                        })
                    );
                }
            };
        },
    },
};

import React from 'react';
import Modal from '../../../../legacy-redux/components/core/Modal';

// overlay needs to be above header
const overlayStyle = {
    top: 0,
    zIndex: 2125,
};
const defaultModalStyle = {
    width: 340,
    height: 450,
    left: 0,
    top: 55,
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.29)',
    // unset css translate in ModalStyles
    transform: 'none',
};
const defaultContentStyle = {
    maxHeight: 450,
    height: 450,
    marginBottom: 0,
    padding: 0,
    border: 0,
    borderRadius: 5,
};

/**
 * Main Navigation Menu related popovers. Positioned to appear directly
 * below main navigation menu. Use modalStyle to set the
 * left positioning of Popover.
 * @param {Object} context
 * @param {Object} modalStyle
 */
export default function MenuPopover({ context, modalStyle, ...otherProps }) {
    return (
        <Modal
            context={context}
            hideFooter={true}
            dimmedOverlay={false}
            shouldCloseOnOverlayClick={true}
            overlayStyle={overlayStyle}
            modalStyle={{ ...defaultModalStyle, ...modalStyle }}
            contentStyle={defaultContentStyle}
            {...otherProps}
        />
    );
}

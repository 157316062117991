import styled from 'styled-components';

export default styled.button`
    && {
        background: none;
        position: relative;
        border: none;
        padding: 10px 15px;
        margin: 0;
        display: block;
        font: ${(props) =>
            `${props.theme.fontWeights.semiBold} 0.875 ${props.theme.fontFamilies.proximaNova}`};
        color: ${(props) => props.theme.colors.cobaltBlue};
        border-top: ${(props) => `1px solid ${props.theme.colors.lightGrey}`};
        width: 100%;
        text-align: left;
        text-transform: uppercase;
    }
`;

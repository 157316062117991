import createNormalizedModule from '../../../../utils/createNormalizedModule';

interface IntegrationJobRun {
    commandRecordId: number;
    createdDateUtc: string;
    id: number;
    integrationId: number;
    isChunked: boolean;
    serializedInput?: string;
    serializedOutput?: string;
}

const integrationJobRunModule = createNormalizedModule<IntegrationJobRun>({
    type: 'integrationJobRuns',
});

// SELECTORS
export const integrationJobRunsSelector = integrationJobRunModule.selectors.entitiesSelector;
export const integrationJobRunsWhereSelector =
    integrationJobRunModule.selectors.entitiesWhereSelector;

// REDUCER
export default integrationJobRunModule.reducerConfig;

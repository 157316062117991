import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { Tooltip } from '../../../core/components/tooltip';

import ActionBar from '../../../core/components/ActionBar';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Link from '../../../core/components/links/Link';
import testIds from '../../../../core/testIds';

const { recordPrivacy } = componentStrings;

const SealingActionBar = ({
    personToSealSelected,
    chargeToSealSelected,
    hasChargesToSeal,
    sealButtonDisabled,
    onSealClick,
    backUrl,
}) => {
    const sealButton = (
        <Button
            onClick={onSealClick}
            className={buttonTypes.PRIMARY}
            float="none"
            display="inline-block"
            disabled={sealButtonDisabled}
            testId={testIds.SEAL_BUTTON}
        >
            {recordPrivacy.sealing.SealingActionBar.seal}
        </Button>
    );
    const tooltip = hasChargesToSeal
        ? recordPrivacy.sealing.SealingActionBar.selectOneProfileOrCharge
        : recordPrivacy.sealing.SealingActionBar.selectOneProfile;

    const content =
        !personToSealSelected && !chargeToSealSelected ? (
            <Tooltip side="top" hasButtonOffset content={tooltip}>
                <div>{sealButton}</div>
            </Tooltip>
        ) : (
            sealButton
        );
    return (
        <ActionBar visible position="static">
            {content}
            <Link to={backUrl}>
                <Button className={buttonTypes.SECONDARY} float="none" display="inline-block">
                    {recordPrivacy.sealing.SealingActionBar.cancel}
                </Button>
            </Link>
        </ActionBar>
    );
};

export default SealingActionBar;

import { ElasticOrganization, ElasticPerson } from '@mark43/rms-api';
import { reduce } from 'lodash';

export function mapQuickAddEntitiesToSameOwnerId<T extends ElasticPerson | ElasticOrganization>(
    entities: T[],
    mappedOwnerId: number,
    masterEntityIdPropertyName: keyof T
): T[] {
    return reduce<T, T[]>(
        entities,
        (result, entity) => {
            const { ownerId: entityOwnerId } = entity;
            const masterEntityId = entity[masterEntityIdPropertyName];
            if (entityOwnerId !== mappedOwnerId && masterEntityId) {
                result.push({
                    ...entity,
                    id: masterEntityId,
                    ownerId: mappedOwnerId,
                });
            } else {
                result.push(entity);
            }
            return result;
        },
        []
    );
}

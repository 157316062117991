import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import testIds from '../../../../core/testIds';

const strings = componentStrings.cases.caseReviews.CaseReviewComment;

function ApprovalStatusComment({ children }) {
    return (
        <div className="case-review-comment approval">
            <div className="case-review-comment-title approval">{strings.approved}</div>
            {children && <div className="case-review-comment-note approval">{children}</div>}
        </div>
    );
}

function RejectionComment({ children }) {
    return (
        <div className="case-review-comment rejection">
            <div className="case-review-comment-title rejection">{strings.rejected}</div>
            {children && <div className="case-review-comment-note rejection">{children}</div>}
        </div>
    );
}

function RequestForApprovalComment({ children }) {
    return (
        <div className="case-review-comment request-for-approval">
            <div className="case-review-comment-title request-for-approval">
                {strings.requestForApproval}
            </div>
            {children && (
                <div className="case-review-comment-note request-for-approval">{children}</div>
            )}
        </div>
    );
}

function NormalComment({ children, className }) {
    return <div className={classNames('case-review-comment', className)}>{children}</div>;
}

function NotificationComment({ user }) {
    return <div className="case-review-notification">{strings.notificationSentToUser(user)}</div>;
}

export default function CaseReviewComment({ caseReviewViewModel }) {
    const { isApproval, isRejection, isRequestForApproval, body } = caseReviewViewModel;
    const display = getViewModelProperties(caseReviewViewModel);
    const isNotification = false; // TODO hook up real notification

    let comment;
    if (isApproval) {
        comment = <ApprovalStatusComment>{body}</ApprovalStatusComment>;
    } else if (isRejection) {
        comment = <RejectionComment>{body}</RejectionComment>;
    } else if (isRequestForApproval) {
        comment = <RequestForApprovalComment>{body}</RequestForApprovalComment>;
    } else if (isNotification) {
        comment = <NotificationComment user={''} />;
    } else {
        comment = (
            <NormalComment
                className={classNames({
                    'current-user-comment': display.isCurrentUsersComment,
                })}
            >
                {body}
            </NormalComment>
        );
    }

    return (
        <div data-test-id={testIds.CASE_REVIEW_COMMENT}>
            <div className="case-review-comment-title">
                {`${get(display, 'createdBy.fullNameWithFirstInitial')} `}
                <FormattedDate
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                    date={caseReviewViewModel.createdDateUtc}
                >
                    {(formattedDate) => (
                        <span className="case-review-comment-date">{`on ${formattedDate}`}</span>
                    )}
                </FormattedDate>
            </div>
            {comment}
        </div>
    );
}

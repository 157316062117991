import { joinTruthyValues } from './stringHelpers';

export function formatWarrantShortTitle({
    warrantNumber,
    warrantType,
}: {
    warrantNumber?: string;
    warrantType: string;
}) {
    return joinTruthyValues(
        [warrantNumber ? `#${warrantNumber}` : null, warrantType, 'Warrant'],
        ' '
    );
}

export function formatWarrantTitle({
    subjectName,
    warrantNumber,
    warrantType = '',
}: {
    warrantNumber: string;
    warrantType?: string;
    subjectName?: string;
}) {
    const subjectPart = subjectName ? `: ${subjectName}` : '';
    const typePart = warrantType ? ` ${warrantType}` : '';
    return `#${warrantNumber}${typePart}${subjectPart}`;
}

export const formatObtainingOfficerUser = ({
    formattedObtainingOfficer,
    obtainingOfficerOther,
}: {
    formattedObtainingOfficer?: string;
    obtainingOfficerOther?: string;
}) => {
    return joinTruthyValues([formattedObtainingOfficer, obtainingOfficerOther], ': ');
};

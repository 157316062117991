import _, { find, pick, pickBy, mapValues } from 'lodash';
import { createSelector } from 'reselect';

import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';

import { facilitiesSelector } from '../data';
import { locationsSelector } from '../../../locations/state/data';
import { locationEntityLinksSelector } from '../../../location-entity-links/state/data';
import { appendExpiredLabelIfExpired } from '../../../storage-locations/utils/storageLocationHelpers';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '../../../attributes/state/data';
import { getAdminListStatusFromStartEnd } from '../../../../dates/utils/dateHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

import { attributeStatuses } from '../../../attributes/configuration';

const { ACTIVE } = attributeStatuses;

/**
 * All facility view models, keyed by id.
 * @type {Object}
 */
export const facilityViewModelsSelector = createSelector(
    facilitiesSelector,
    locationsSelector,
    locationEntityLinksSelector,
    formatAttributeByIdSelector,
    (facilities, locations, locationEntityLinks, formatAttributeById) =>
        _(facilities)
            .mapValues((facility) => {
                const locationEntityLink = find(locationEntityLinks, {
                    entityId: facility.id,
                });

                if (!locationEntityLink) {
                    return facility;
                }

                const location = locations[locationEntityLink.locationId];

                // combine facility fields and location fields into one flat
                // object
                return {
                    ...facility,
                    locationId: location.id,
                    ...pick(location, [
                        'streetAddress',
                        'locality',
                        'adminArea1',
                        'postalCode',
                        'country',
                    ]),
                    ...pick(locationEntityLink, ['subPremise']),
                };
            })
            .mapValues(
                buildViewModel({
                    recursive: false,
                    mappers: [allSingleAttributeValuesMapper],
                    helpers: {
                        formatAttributeById,
                    },
                })
            )
            .value()
);

/**
 * Basically a toString for facilityId's, used in the evidence dashboard
 * @return a string representing all of the combined facilities
 */
export const formatFacilityByIdSelector = createSelector(
    facilityViewModelsSelector,
    (facilityViewModels) => (facilityId) => {
        return !_.isArray(facilityId)
            ? _.get(facilityViewModels[facilityId], 'locationName')
            : joinTruthyValues(
                  _(facilityId)
                      .map((facilityId) => _.get(facilityViewModels[facilityId], 'locationName'))
                      .sortBy()
                      .value()
              );
    }
);

/**
 * All facility view models, formatted for dropdown select options, sorted by
 *   status and then display string.
 *   Whenever a facility is selected (including initially), its id gets stored
 *   in component state so that any expired or scheduled facilities that get
 *   selected are always shown in this dropdown (even when `includeExpired` is
 *   `false`), which is useful mainly in old reports where the initial facility
 *   is expired.
 * @param  {number|number[]} [evidenceFacilityGlobalAttrIdFilter=[]]
     Optionally filter by evidence facility global attribute (internal or
     external)
 * @param  {number|number[]} [additionalFacilityIds] Optional ids of additional
 *   facilities to also include. The main purpose of this argument to
 *   sufficiently populate dropdowns where the selected option is an expired or
 *   scheduled facility, which can happen when viewing a legacy report with old
 *   facilities.
 * @return {Object[]}
*/
export const facilityOptionsSelector = createSelector(
    facilityViewModelsSelector,
    parentAttributeIdByAttributeIdSelector,
    (facilityViewModels, parentAttributeIdByAttributeId) => (
        evidenceFacilityGlobalAttrIdFilter = [],
        additionalFacilityIds = []
    ) => {
        const facilities = gatherFacilityDataForFacilityOptions({
            facilityViewModels,
            parentAttributeIdByAttributeId,
            evidenceFacilityGlobalAttrIdFilter,
            additionalFacilityIds,
        });

        return _(facilities)
            .map(({ locationName, id, expiredDateUtc }) => ({
                display: appendExpiredLabelIfExpired(locationName, expiredDateUtc),
                value: id,
                expiredDateUtc,
            }))
            .sortBy(({ display }) => display.toLowerCase())
            .value();
    }
);

export function gatherFacilityDataForFacilityOptions({
    facilityViewModels,
    parentAttributeIdByAttributeId,
    evidenceFacilityGlobalAttrIdFilter,
    additionalFacilityIds,
}) {
    // convert to array
    additionalFacilityIds = [].concat(additionalFacilityIds);
    evidenceFacilityGlobalAttrIdFilter = [].concat(evidenceFacilityGlobalAttrIdFilter);

    const now = Date.now();
    const facilities = mapValues(facilityViewModels, (facility) => ({
        ...facility,
        status: getAdminListStatusFromStartEnd(
            facility.createdDateUtc,
            facility.expiredDateUtc,
            now
        ),
    }));
    const filterFacilities = (status) => {
        const filtered = _(facilities).filter({ status }).mapKeys('id').value();

        if (evidenceFacilityGlobalAttrIdFilter.length > 0) {
            return pickBy(filtered, ({ locationTypeAttrId }) =>
                _.includes(
                    evidenceFacilityGlobalAttrIdFilter,
                    parentAttributeIdByAttributeId(locationTypeAttrId)
                )
            );
        }
        return filtered;
    };

    return {
        ...filterFacilities(ACTIVE),
        ...pick(facilities, additionalFacilityIds),
    };
}

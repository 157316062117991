import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { mergePropertyStatuses } from '../utils/propertyStatusHelpers';

import { propertyStatusesByItemProfileIdSelector } from '../state/data';
import FormattedPropertyStatusQuantity from './FormattedPropertyStatusQuantity';

/**
 * Use this component to format property status quantity for an item profile id.
 * @param  {number} props.itemProfileId
 */
const ConnectedFormattedPropertyStatusQuantityForItem = compose(
    connect(
        createStructuredSelector({
            propertyStatusesByItemProfileId: propertyStatusesByItemProfileIdSelector,
        })
    ),
    withPropsOnChange(
        ['itemProfileId', 'propertyStatusesByItemProfileId'],
        ({ itemProfileId, propertyStatusesByItemProfileId }) => ({
            propertyStatus: mergePropertyStatuses(propertyStatusesByItemProfileId(itemProfileId)),
        })
    )
)(FormattedPropertyStatusQuantity);

export default ConnectedFormattedPropertyStatusQuantityForItem;

// eslint-disable-next-line no-redeclare
/* global MARK43_ENV */
import * as Sentry from '@sentry/browser';
import environmentEnum from '../enums/client/environmentEnum';

export default function logBrokenFieldEffectComputation(ruleResult) {
    if (MARK43_ENV === environmentEnum.DEVELOPER) {
        // eslint-disable-next-line no-console
        console.error(
            "A field effect computation could not be completed. This is likely due to an actioned field not being declared in a form's fieldsetViewModel.",
            { ruleResult }
        );
    } else {
        Sentry.withScope((scope) => {
            scope.setLevel('error');
            scope.setExtra('ruleResult', ruleResult);
            Sentry.captureMessage(
                "A field effect computation could not be completed. This is likely due to an actioned field not being declared in a form's fieldsetViewModel."
            );
        });
    }
}

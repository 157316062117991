import { OfficerInvolvementEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import _, { map, pick, find, get } from 'lodash';
// selectors
import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatMiniUserShortHandByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { formatReportDefinitionByIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { formatAgencyProfileByIdSelector } from '~/client-common/core/domain/agency-profiles/state/ui';

import {
    getPrimaryLocation,
    getOffenseLocation,
    getArrestLocation,
    getEventLocation,
} from '~/client-common/core/domain/elastic-reports/state/ui';

// view model helpers
import {
    buildViewModel,
    allAttributeIdListsMapper,
    allSingleAttributeValuesMapper,
    subdivisionAttrIdsMapper,
    allOfficerIdListsMapper,
} from '~/client-common/helpers/viewModelHelpers';

// NOTE: DEPRECATED. Use elasticReportViewModelsSelector in `client-common/core/domain/elastic-reports/state/ui/index.js`
export default function createReportsResultsSelector(baseSelector) {
    return createSelector(
        formatMiniUserShortHandByIdSelector,
        formatAttributeByIdSelector,
        formatSubdivisionAttrIdsSelector,
        formatReportDefinitionByIdSelector,
        formatAgencyProfileByIdSelector,
        baseSelector,
        (
            formatMiniUserShortHandById,
            formatAttributeById,
            formatSubdivisionAttrIds,
            formatReportDefinitionById,
            formatAgencyProfileById,
            currentResults
        ) =>
            map(
                currentResults,
                buildViewModel({
                    recursive: true,
                    mappers: [
                        allAttributeIdListsMapper,
                        allSingleAttributeValuesMapper,
                        subdivisionAttrIdsMapper,
                        allOfficerIdListsMapper,
                        (obj, helpers) =>
                            _(obj)
                                .pick('currentSubmissionOwnerId')
                                .mapValues((id) => helpers.formatMiniUserShortHandById(id))
                                .value(),
                        (obj, helpers) =>
                            _(obj)
                                .pick('reviewerIds')
                                .mapValues((reviewers) =>
                                    reviewers.map((id) => ({
                                        id,
                                        display: helpers.formatMiniUserShortHandById(id),
                                    }))
                                )
                                .value(),
                        (obj, helpers) =>
                            _(obj)
                                .pick('authorIds')
                                .mapValues((reviewers) =>
                                    reviewers.map((id) => ({
                                        id,
                                        display: helpers.formatMiniUserShortHandById(id),
                                    }))
                                )
                                .value(),
                        (obj, helpers) =>
                            _(obj)
                                .pick('agencyId')
                                .mapValues((id) => helpers.formatAgencyProfileById(id))
                                .value(),
                        (obj, helpers) =>
                            _(obj)
                                .pick('reportDefinition')
                                .mapValues(({ reportDefinitionId }) =>
                                    helpers.formatReportDefinitionById(reportDefinitionId)
                                )
                                .value(),
                        ({ involvedOfficers }, helpers) => {
                            const arrestingOfficer = find(involvedOfficers, {
                                officerInvolvement: OfficerInvolvementEnum.ARRESTING.name,
                            });
                            return {
                                arrestingOfficerFormats: helpers.formatMiniUserShortHandById(
                                    get(arrestingOfficer, 'officerId')
                                ),
                            };
                        },
                        (elasticReport) => {
                            const elasticLocations = pick(
                                elasticReport,
                                'involvedElasticLocations'
                            );
                            return {
                                primaryLocation: getPrimaryLocation(elasticLocations),
                                offenseLocation: getOffenseLocation(elasticLocations),
                                arrestLocation: getArrestLocation(elasticLocations),
                                eventLocation: getEventLocation(elasticLocations),
                            };
                        },
                    ],
                    helpers: {
                        formatMiniUserShortHandById,
                        formatAttributeById,
                        formatSubdivisionAttrIds,
                        formatReportDefinitionById,
                        formatAgencyProfileById,
                    },
                })
            )
    );
}

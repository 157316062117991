import { FormEnum, LinkTypesEnum } from '@mark43/rms-api';
import { pick, map } from 'lodash';
import { createSelector } from 'reselect';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { itemTypeToKey } from '~/client-common/core/domain/item-profiles/utils/itemProfileHelpers';
import { ownerIsOrgToEntityType } from '~/client-common/core/domain/name-item-links/utils/nameItemLinkHelpers';

import { createFormModule } from '../../../../core/forms';
import itemProfileFieldViewModels from '../../../../reports/core/state/forms/itemProfileFieldViewModels';
import itemIdentifierNItemsFieldViewModels from '../../../../reports/core/state/forms/itemIdentifierNItemsFieldViewModels';
import ownerNItemsFieldViewModels from '../../../../reports/core/state/forms/ownerNItemsFieldViewModels';
import { itemSplitPropertyStatusFieldsetFields } from './itemSplitPropertyStatusFieldsetFields';

const { FIELDSET } = fieldTypeClientEnum;

const itemSplitUiSelector = (state) => state.ui.evidence.itemSplit;

/**
 * The Parent Item Profile Id for the Item Split.
 * @type {number|null}
 */
export const parentItemProfileIdSelector = createSelector(
    itemSplitUiSelector,
    (itemSplitUi) => itemSplitUi.parentItemProfileId
);

/**
 * The Parent Item Type for the Item Split.
 * @type {number|null}
 */
export const parentItemTypeAttrIdSelector = createSelector(
    itemSplitUiSelector,
    (itemSplitUi) => itemSplitUi.parentItemTypeAttrId
);

/**
 * The Child Item Type for the Item Split.
 * @type {number|null}
 */
export const childItemTypeAttrIdSelector = createSelector(
    itemSplitUiSelector,
    (itemSplitUi) => itemSplitUi.childItemTypeAttrId
);

/**
 * The Property Status Global Attribute Id for the Item Split.
 * @type {number|null}
 */
const propertyStatusGlobalAttrIdSelector = createSelector(
    itemSplitUiSelector,
    (itemSplitUi) => itemSplitUi.propertyStatusGlobalAttrId
);

const fieldViewModels = {
    parentItemProfile: {
        type: FIELDSET,
        key: 'parentItemProfile',
        fields: itemProfileFieldViewModels,
    },
    childItemProfile: {
        type: FIELDSET,
        key: 'childItemProfile',
        fields: {
            ...itemProfileFieldViewModels,
            itemIdentifiers: itemIdentifierNItemsFieldViewModels,
            owners: ownerNItemsFieldViewModels,
        },
    },
    parentPropertyStatus: {
        type: FIELDSET,
        key: 'parentPropertyStatus',
        fields: itemSplitPropertyStatusFieldsetFields,
    },
    childPropertyStatus: {
        type: FIELDSET,
        key: 'childPropertyStatus',
        fields: itemSplitPropertyStatusFieldsetFields,
    },
};

function convertToFormModel(parentItemProfile, propertyStatus) {
    const parentItemProfileFields = pick(
        parentItemProfile,
        map(fieldViewModels.parentItemProfile.fields, 'key')
    );
    const parentPropertyStatusFields = pick(
        propertyStatus,
        map(itemSplitPropertyStatusFieldsetFields, 'key')
    );

    return {
        parentItemProfile: parentItemProfileFields,
        parentPropertyStatus: parentPropertyStatusFields,
    };
}

function convertFromFormModel(formModel, dataFromState) {
    const {
        parentItemProfile,
        childItemProfile,
        parentPropertyStatus,
        childPropertyStatus,
    } = formModel;
    const { itemIdentifiers, owners, claimants, ...restChildItemProfile } = childItemProfile;
    const ownersWithLinkType = map(owners, (owner) => ({
        linkType: LinkTypesEnum.OWNED_BY,
        ...ownerIsOrgToEntityType(owner),
    }));
    const claimantsWithLinkType = map(claimants, (claimant) => ({
        linkType: LinkTypesEnum.CLAIMED_BY,
        ...ownerIsOrgToEntityType(claimant),
    }));
    const nameItemLinks = [...ownersWithLinkType, ...claimantsWithLinkType];
    const {
        parentItemIdentifiers,
        parentNameItemLinks,
        parentItemTypeAttrId,
        childItemTypeAttrId,
        propertyStatusProps,
    } = dataFromState;
    const parentItem = {
        [itemTypeToKey(parentItemTypeAttrId)]: {
            ...parentItemProfile,
            itemTypeAttrId: parentItemTypeAttrId,
        },
    };
    const childItem = {
        [itemTypeToKey(childItemTypeAttrId)]: {
            ...restChildItemProfile,
            itemTypeAttrId: childItemTypeAttrId,
        },
    };

    return [
        {
            ...parentItem,
            propertyStatus: {
                ...propertyStatusProps,
                ...parentPropertyStatus,
            },
            itemIdentifiers: parentItemIdentifiers,
            nameItemLinks: parentNameItemLinks,
        },
        {
            ...childItem,
            propertyStatus: {
                ...propertyStatusProps,
                ...childPropertyStatus,
            },
            itemIdentifiers,
            nameItemLinks,
        },
    ];
}

function validationContext(formModel, __, state) {
    const { parentItemProfile, childItemProfile, parentPropertyStatus } = formModel;
    const parentItemTypeAttrId = parentItemTypeAttrIdSelector(state);
    const childItemTypeAttrId = childItemTypeAttrIdSelector(state);
    const propertyStatusGlobalAttrId = propertyStatusGlobalAttrIdSelector(state);

    const form =
        !!parentItemProfile || !!childItemProfile
            ? FormEnum.ITEM.name
            : FormEnum.EVIDENCE_ITEM_SPLIT.name;
    const itemTypeAttrId =
        !!parentItemProfile || !!parentPropertyStatus ? parentItemTypeAttrId : childItemTypeAttrId;

    return { form, itemTypeAttrId, propertyStatusGlobalAttrId };
}

const evidenceItemSplitForm = createFormModule({
    formName: FormEnum.EVIDENCE_ITEM_SPLIT.name,
    fieldViewModels,
    convertToFormModel,
    convertFromFormModel,
    validationContext,
});

/**
 * Module of the item split form, for splitting an item.
 * @type {Object}
 */
export default evidenceItemSplitForm;

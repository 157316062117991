import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import withFields from '~/client-common/core/fields/components/withFields';
import {
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
} from '~/client-common/core/enums/universal/fields';
import { RmsDispatch } from '../../../../../core/typings/redux';
import Modal, { OverrideModalPropsFunction } from '../../../../core/overlays/components/Modal';
import { actuallyRescindStaffReview, addSubmissionAuthor } from '../../state/ui/submissions';

type RescindStaffReviewModalCustomProps = {
    callback: () => void;
};

const strings = componentStrings.reports.core.RescindStaffReviewModal;

const RescindStaffReviewModal: React.FC<{
    fieldDisplayNames: {
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW: string;
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED: string;
    };
}> = ({ fieldDisplayNames }) => {
    const dispatch = useDispatch<RmsDispatch>();

    // override saveModal because submissions workflow uses ERROR_MODAL to display errors.
    const overrideModalProps: OverrideModalPropsFunction<RescindStaffReviewModalCustomProps> = useCallback(
        (overlayBaseProps) => ({
            saveModal: () => {
                dispatch(
                    actuallyRescindStaffReview(
                        overlayBaseProps.overlayState.customProperties.callback
                    )
                );
                dispatch(
                    addSubmissionAuthor(overlayBaseProps.overlayState.customProperties.callback)
                );
            },
        }),
        [dispatch]
    );

    return (
        <Modal<RescindStaffReviewModalCustomProps>
            id={overlayIdEnum.RESCIND_STAFF_REVIEW_MODAL}
            okText={strings.confirm}
            overrideModalProps={overrideModalProps}
            shouldCloseOnOverlayClick={true}
            title={strings.title(fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED)}
        >
            {strings.confirmationText(
                fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
                fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED
            )}
        </Modal>
    );
};

export default withFields([
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
])(RescindStaffReviewModal);

export type DeferredPromise<T> = {
    promise: Promise<T>;
    resolve: (value: T) => void;
    reject: (error?: Error) => void;
};
export const deferredPromise = <T>(): DeferredPromise<T> => {
    // eslint-disable-next-line no-undef-init
    let resolveFn: ((value: T) => void) | undefined = undefined;
    // eslint-disable-next-line no-undef-init
    let rejectFn: ((error?: Error) => void) | undefined = undefined;

    const promise = new Promise<T>((resolve, reject) => {
        resolveFn = resolve;
        rejectFn = reject;
    });

    // this won't trigger but it is required to make TS understand that these functions will be defined.
    // TS doesn't know that the promise will call its callback instantly
    if (!resolveFn || !rejectFn) {
        throw new Error('Unexpectedly did not find resolve or reject function');
    }

    return {
        resolve: resolveFn,
        reject: rejectFn,
        promise,
    };
};

const delay = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export const retry = <T>(
    promiseFn: () => Promise<T>,
    {
        retries = 2,
        delayMs = 100,
        delayFactor = 1.5,
    }: { retries?: number; delayMs?: number; delayFactor?: number } = {}
): Promise<T> =>
    promiseFn().catch((err) => {
        if (retries) {
            const computedDelay = delayMs * delayFactor;
            return delay(computedDelay).then(() =>
                retry(promiseFn, { retries: retries - 1, delayMs: computedDelay, delayFactor })
            );
        }
        throw err;
    });

import { ReportsResource } from '@mark43/rms-api';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Generated Segments Resource',
    methods: {
        generateNibrsSegment(reportId: number) {
            return req<ReportsResource.GenerateNibrsSegments>({
                method: 'GET',
                url: `reports/${reportId}/nibrs/generate_segments`,
            });
        },
        getNibrsErrors(reportId: number) {
            return req<ReportsResource.GetNibrsErrors>({
                method: 'GET',
                url: `reports/${reportId}/nibrs/errors`,
            });
        },
    },
});

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Field Notes Resource',
        methods: {
            getFieldNotesForCurrentUser(isArchived = false) {
                return req({
                    method: 'GET',
                    url: 'field_notes/summary',
                    params: {
                        is_archived: isArchived,
                    },
                });
            },
            getFullFieldNote(fieldNoteId) {
                return req({
                    url: `field_notes/${fieldNoteId}/full`,
                });
            },
            getHydratedFieldNotesForCurrentUser({
                includeAttachments = false,
                includePersons = false,
                includeLocations = false,
            }) {
                return req({
                    url: `field_notes/full/batch`,
                    params: {
                        include_attachments: includeAttachments,
                        include_persons: includePersons,
                        include_locations: includeLocations,
                    },
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import mergeEntitiesReducer from './reducers';

export {
    isLoadingSelector,
    errorSelector,
    profilesLoadedSelector,
    entityTypeToMergeSelector,
    entityIdsToMergeSelector,
    profilesToMergeSelector,
} from './selectors';

export {
    storeSelectedValues,
    loadProfilesStart,
    loadEntityProfilesForIdsAndType,
    resetMergeEntitiesState,
    initiateEntityMerge,
    saveMergedProfiles,
    getMasterPersonMergeRequestData,
} from './actions';

export default mergeEntitiesReducer;

import React from 'react';
import { cssVar } from 'arc';
import styled from 'styled-components';
import { Icon } from '@arc/icon';
import { Center } from '@arc/layout';

const IconWrapper = styled(Center)`
    border-radius: var(--arc-radii-md);
    border: solid 1px var(--arc-colors-border-default);
    width: 4.75rem;
    height: 4.75rem;
`;

export const NoImagePlaceHolder = () => {
    return (
        <IconWrapper>
            <Icon icon="ImageOff" color="icon.default" />
        </IconWrapper>
    );
};

export const Wrapper = styled.div`
    margin: 0 ${cssVar('arc.space.2')};
`;

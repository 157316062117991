import { CourtsResource, CourtCreationRequest, CourtUpdateRequest } from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/rms/api';
const BASE_PATH = 'courts';

export default createResource({
    name: 'Court Resource',
    methods: {
        getCourts() {
            return req<CourtsResource.GetCourtsForCurrentDepartment>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: BASE_PATH,
            });
        },
        createCourt(courtCreationRequest: CourtCreationRequest) {
            return req<CourtsResource.CreateCourt>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: BASE_PATH,
                data: courtCreationRequest,
            });
        },
        updateCourt(courtUpdateRequest: CourtUpdateRequest) {
            return req<CourtsResource.UpdateCourt>({
                method: 'PUT',
                baseUrl: BASE_URL,
                url: BASE_PATH,
                data: courtUpdateRequest,
            });
        },
    },
});

import React, { useState } from 'react';
import { truncate } from 'lodash';
import styled from 'styled-components';

import { CustomLink } from './links/Link';

const MoreButton = styled(CustomLink)`
    font-weight: ${(props) => props.theme.fontWeights.regular};
    text-decoration: none;
`;

const TruncatedText: React.FC<{ text?: string; length?: number }> = ({ text, length = 150 }) => {
    const [isTruncated, setIsTruncated] = useState(true);
    const descriptionIsLong = text && text.length > length;
    const displayText =
        descriptionIsLong && isTruncated ? truncate(text, { separator: /,? +/, length }) : text;
    return (
        <>
            {displayText}
            {descriptionIsLong && isTruncated && (
                <MoreButton onClick={() => setIsTruncated(false)}>more</MoreButton>
            )}
        </>
    );
};

export default TruncatedText;

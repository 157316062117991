import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { GlobalThemeProvider as ArcGlobalThemeProvider, TooltipProvider } from 'arc';
import { compose, withProps } from 'recompose';
import { ThemeProvider } from 'styled-components';
import { createStructuredSelector } from 'reselect';
import themeEnum from './themeEnum';
import { currentThemeSelector, currentThemeNameSelector } from './state';
import lightMode from './theme';
import darkMode from './theme.dark';

type ValueOf<T> = T[keyof T];
type ThemeNameType = ValueOf<typeof themeEnum>;

const themeSelector = createStructuredSelector({
    theme: currentThemeSelector,
    themeName: currentThemeNameSelector,
});

// There are two parts to changing the theme:
// - changing the styled component theme
// - and changing the CSS theme
function changeCSSTheme(themeName: ThemeNameType) {
    if (themeName === themeEnum.DARK_MODE) {
        $('html').addClass('dark');
    } else {
        $('html').removeClass('dark');
    }
}

type InnerProps = {
    themeName: ThemeNameType;
    theme: typeof lightMode | typeof darkMode;
};

type OuterProps = {
    forceMode?: ThemeNameType;
};

/**
 * This component wraps the theme provider and injects the currently selected
 * theme from the store. It must be a child of the Provider component
 */
const Mark43ThemeProvider: React.FC<InnerProps> = (props) => {
    const { themeName, theme, children } = props;

    React.useEffect(() => {
        changeCSSTheme(themeName);
    }, [themeName]);

    return (
        /**
         * Whenever there is a new provider from the Arc design system that needs to be wrapped around the entire RMS
         * app, add it here.
         */
        <TooltipProvider>
            {/**
             * with Arc v10 onwards, ThemeProvider and ArcGlobalThemeProvider use different styling systems
             * Arc is not affected by RMS styling, but RMS styling may be affected (overriden) by Arc
             * ThemeProvider is still needed to style non-Arc RMS components
             * it can only be deprecated when all of RMS only uses Arc components
             */}
            <ThemeProvider theme={theme}>
                <ArcGlobalThemeProvider theme={themeName}>{children}</ArcGlobalThemeProvider>
            </ThemeProvider>
        </TooltipProvider>
    );
};

export default compose<InnerProps, OuterProps>(
    connect(themeSelector),
    withProps(
        ({ forceMode, themeName, theme }: InnerProps & OuterProps): InnerProps => {
            let effectiveTheme: typeof lightMode | typeof darkMode;
            const effectiveThemeName = forceMode || themeName;
            if (forceMode === themeEnum.LIGHT_MODE) {
                effectiveTheme = lightMode;
            } else if (forceMode === themeEnum.DARK_MODE) {
                effectiveTheme = darkMode;
            } else {
                effectiveTheme = theme;
            }
            return {
                theme: effectiveTheme,
                themeName: effectiveThemeName,
            };
        }
    )
)(Mark43ThemeProvider);

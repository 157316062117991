import { LinkTypesEnum } from '@mark43/rms-api';
import { filter, first, get } from 'lodash';

import { createSelector } from 'reselect';
import { isUndefinedOrNull } from '../../../../../helpers/logicHelpers';
import { getViewModelProperties } from '../../../../../helpers/viewModelHelpers';
import { arrestForReportIdSelector } from '../data';

import { formatAttributeByIdSelector } from '../../../attributes/state/data';

import { applicationSettingsSelector } from '../../../settings/state/data';
import { attachmentViewModelsWhereSelector } from '../../../attachments/state/ui';
import { formatArrestTitle } from '../../../reports/utils/reportsHelpers';
import {
    formatReportDefinitionByReportIdSelector,
    reportCardTitleByReportIdAndCardIdSelector,
    reportDefinitionByReportIdSelector,
} from '../../../report-definitions/state/data';

import { reportByIdSelector } from '../../../reports/state/data';
import { reportDefinitionHasArrestCard } from '../../../../../helpers/reportDefinitionsHelpers';
import reportCardEnum from '../../../../enums/universal/reportCardEnum';
import globalStrings from '../../../../strings/globalStrings';

export const findArrestRecordNumberSelector = createSelector(
    applicationSettingsSelector,
    reportByIdSelector,
    (applicationSettings, reportById) =>
        ({ arrest, reportId }) => {
            const recordsWithoutRenEnabled =
                !!applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;
            const dcArrestNumberGenerationEnabled =
                !!applicationSettings.RMS_WASHINGTON_DC_ARREST_NUMBER_GENERATION_ENABLED;
            if (recordsWithoutRenEnabled || dcArrestNumberGenerationEnabled) {
                const report = reportById(reportId);
                const reportRecordNumber = get(report, 'recordNumber') || '';
                const arrestingAgencyAttrId = get(arrest, 'arrestingAgencyAttrId');
                return dcArrestNumberGenerationEnabled && isUndefinedOrNull(arrestingAgencyAttrId)
                    ? ''
                    : reportRecordNumber;
            }
            return get(arrest, 'localId') || '';
        }
);

export const formatArrestTitleByReportIdSelector = createSelector(
    findArrestRecordNumberSelector,
    arrestForReportIdSelector,
    formatAttributeByIdSelector,
    formatReportDefinitionByReportIdSelector,
    reportCardTitleByReportIdAndCardIdSelector,
    (
            findArrestRecordNumber,
            arrestForReportId,
            formatAttributeById,
            reportCardTitleByReportIdAndCardId
        ) =>
        (reportId, reportDefDisplayNameOverride) => {
            const arrest = arrestForReportId(reportId);
            const arrestIsDetentionDisplayName = !!arrest?.isDetention
                ? globalStrings.detention
                : undefined;
            const recordNumber = findArrestRecordNumber({ arrest, reportId });
            const reportDefDisplayName =
                reportDefDisplayNameOverride ||
                arrestIsDetentionDisplayName ||
                reportCardTitleByReportIdAndCardId(reportId, reportCardEnum.ARREST.id);
            return arrest
                ? formatArrestTitle({
                      recordNumber,
                      arrestType: formatAttributeById(arrest.arrestTypeAttrId),
                      reportDefDisplayName,
                  })
                : '';
        }
);

export const defendantMugshotPathByReportIdSelector = createSelector(
    arrestForReportIdSelector,
    attachmentViewModelsWhereSelector,
    (arrestForReportId, attachmentViewModelsWhere) => (reportId) => {
        const { defendantId } = arrestForReportId(reportId) || {};

        const { thumbnailMediumPath, path } = getViewModelProperties(
            first(
                filter(attachmentViewModelsWhere({ entityId: defendantId }), {
                    attachmentType: 'IMAGE',
                    linkType: LinkTypesEnum.MUGSHOT,
                })
            )
        );

        return thumbnailMediumPath || path;
    }
);

export const isArrestReportSelector = createSelector(
    reportDefinitionByReportIdSelector,
    (reportDefinitionByReportId) => (reportId) => {
        const reportDefinition = reportDefinitionByReportId(reportId);
        return reportDefinitionHasArrestCard(reportDefinition);
    }
);

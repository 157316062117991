import { map } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/attachments/state/data';
import { NEXUS_STATE_PROP as CHAIN_OF_CUSTODIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/chain-of-custodies/state/data';
import { NEXUS_STATE_PROP as FIREARMS_NEXUS_STATE_PROP } from '~/client-common/core/domain/firearms/state/data';
import { NEXUS_STATE_PROP as ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-evidence-states/state/data';
import { NEXUS_STATE_PROP as ITEM_FACILITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-facility-links/state/data';
import { NEXUS_STATE_PROP as ITEM_IDENTIFIERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-identifiers/state/data';
import {
    NEXUS_STATE_PROP as ITEM_PROFILES_NEXUS_STATE_PROP,
    withRemoveHydratedItem,
} from '~/client-common/core/domain/item-profiles/state/data';
import { NEXUS_STATE_PROP as ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-reporting-event-links/state/data';
import { NEXUS_STATE_PROP as NAME_ITEM_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-item-links/state/data';
import { NEXUS_STATE_PROP as STAFF_REMARKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/staff-remarks/state/data';
import { NEXUS_STATE_PROP as STORAGE_LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/storage-locations/state/data';
import {
    NEXUS_STATE_PROP as VEHICLES_NEXUS_STATE_PROP,
    vehiclesSelector,
} from '~/client-common/core/domain/vehicles/state/data';
import { convertStorageLocationsToElasticAndStore } from '~/client-common/core/domain/elastic-storage-locations/state/data';

import evidenceHydratedItemResource from '../../resources/evidenceHydratedItemResource';
import {
    mapHydratedEvidenceItemToNexusProp,
    storeEvidenceHydratedItems,
    storeEvidenceHydratedItemsLite,
} from '../../utils/evidenceItemsHelpers';

/**
 * The back-end needs to update `/api/evidence/item/hydrated/:id` to set isStolen
 * on EvidenceHydratedItem.vehicles. Until that is done there is a race condition happening based
 * on when the `api/rms/item/hydrated/:id` gets called and when `/api/evidence/item/hydrated/:id`
 * gets called. In the event that the evidence API request resolves after the RMS API request
 * then the Stolen Label will disappear on the UI because redux-state's dataNexus.vehicles will be
 * updated without the `isStolen` property set.
 *
 * This will only happen to customers that have the Evidence Product Module turned on within the RMS.
 *
 * This will be addressed when the user story to add labels to the CPS Report is prioritized
 * and tickets created here:
 * https://mark43.atlassian.net/browse/CHI-1686
 * https://mark43.atlassian.net/browse/CHI-1687
 *
 * For now we will move forward with mutating the response on the FE with this method
 * @param  {Vehicle[]} evidenceHydratedVehicles
 * @param  {{[key: number]: Vehicle}} vehicles
 * @return {Vehicle[]}
 */

export const setIsStolenOnEvidenceHydratedVehicles = (evidenceHydratedVehicles, vehicles) => {
    const mappedEvidenceHydratedVehicles = map(
        evidenceHydratedVehicles,
        (evidenceHydratedVehicle) => {
            const vehicle = vehicles[evidenceHydratedVehicle.id];
            if (vehicle) {
                return {
                    ...evidenceHydratedVehicle,
                    isStolen: vehicle.isStolen,
                };
            }
            return evidenceHydratedVehicle;
        }
    );
    return mappedEvidenceHydratedVehicles;
};

/**
 * Load all data for an evidence item via the model EvidenceHydratedItem. The
 *   provided id can be a masterItemId or a contexted itemProfileId.
 *
 * On EvidenceHydratedItem, itemFacilityLinks are included for itemProfileId,
 *   but not for masterItemId. They are needed on both custodial and
 *   non-custodial reports for the Drop-Off Location field.
 *
 * On EvidenceHydratedItem, itemReportingEventLinks are included for
 *   masterItemId, but not for itemProfileId. They are needed for custodial
 *   reports in order to display linked RENs, and not needed for non-custodial
 *   reports.
 * @param  {number} itemId
 * @return {Promise<Object>}
 */
export function loadEvidenceItem(itemId) {
    return function (dispatch, getState, dependencies) {
        const state = getState();
        const vehicles = vehiclesSelector(state);
        const applicationSettings = applicationSettingsSelector(state);

        return evidenceHydratedItemResource.getHydratedItem(itemId).then((hydratedItem) => {
            const newHydratedItem = applicationSettings.RMS_VEHICLE_CAUTIONS_ENABLED
                ? {
                      ...hydratedItem,
                      vehicles: setIsStolenOnEvidenceHydratedVehicles(
                          hydratedItem.vehicles,
                          vehicles
                      ),
                  }
                : hydratedItem;

            const entitiesToStore = mapHydratedEvidenceItemToNexusProp(newHydratedItem);

            dispatch(
                dependencies.nexus.withEntityItems(entitiesToStore, {
                    type: 'evidence/LOAD_EVIDENCE_ITEM',
                })
            );
            dispatch(convertStorageLocationsToElasticAndStore(hydratedItem.storageLocationViews));

            return hydratedItem;
        });
    };
}

/**
 * Load all data for a batch of evidence items. The provided ids can be
 *   masterItemIds and/or contexted itemProfileIds (can do both at the same
 *   time). See the singular version of this action creator for details,
 *   `loadEvidenceItem()`.
 * @param {number[]} itemIds
 * @return {Promise<EvidenceHydratedItem[]>}
 */
export function loadEvidenceItems(itemIds) {
    return function (dispatch) {
        return evidenceHydratedItemResource
            .getHydratedItems(itemIds, { hideLoadingBar: true })
            .then((hydratedItems) => {
                dispatch(storeEvidenceHydratedItems(hydratedItems));
                return hydratedItems;
            });
    };
}

/**
 * Load data for a batch of evidence items that is used in non-Custodial
 *   Property Summary reports. This is a slimmed down version of
 *   `loadEvidenceItems()` that excludes unnecessary data.
 * @param {number[]} itemIds
 */
export function loadEvidenceItemsLite(itemIds) {
    return function (dispatch) {
        return evidenceHydratedItemResource.getHydratedItemsLite(itemIds).then((hydratedItems) => {
            dispatch(storeEvidenceHydratedItemsLite(hydratedItems));
            return hydratedItems;
        });
    };
}

function withRemoveEvidenceHydratedItem(
    action,
    { itemProfileId, masterItemId, reportId },
    withRemoveMultiple
) {
    return withRemoveMultiple(
        {
            [ATTACHMENTS_NEXUS_STATE_PROP]: { entityId: itemProfileId },
            [CHAIN_OF_CUSTODIES_NEXUS_STATE_PROP]: { masterItemId, reportId },
            [FIREARMS_NEXUS_STATE_PROP]: { id: itemProfileId },
            // tech debt: filter by chainOfCustodyId here too in case it is possible for another
            // itemEvidenceStates of the same master item to exist in state
            [ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP]: { masterItemId },
            [ITEM_FACILITY_LINKS_NEXUS_STATE_PROP]: { itemProfileId },
            [ITEM_IDENTIFIERS_NEXUS_STATE_PROP]: { itemId: itemProfileId },
            [ITEM_PROFILES_NEXUS_STATE_PROP]: { id: itemProfileId },
            [ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP]: { masterItemProfileId: masterItemId },
            [NAME_ITEM_LINKS_NEXUS_STATE_PROP]: { itemProfileId },
            [STAFF_REMARKS_NEXUS_STATE_PROP]: { entityId: itemProfileId },
            [STORAGE_LOCATIONS_NEXUS_STATE_PROP]: {},
            [VEHICLES_NEXUS_STATE_PROP]: { id: itemProfileId },
        },
        action
    );
}

/**
 * Remove from Nexus data state most RMS and Evidence data linked to an item.
 *
 * withRemoveHydratedItem is already responsible for removing most RMS data, and so it is okay for
 *   this helper to remove only the RMS data that lives on EvidenceHydratedItem.
 *
 * The properties on EvidenceHydratedItem that are not removed here are:
 *
 * custodialResponsibleOfficers are not removed because they apply to the whole report, not to each
 *   individual item.
 *
 * storageLocations are not removed because other items can still be linked to them.
 *
 * Tech debt: Remove chainEvents and dispositionEvents too. We don't technically need to remove them
 *   yet because they do not affect any business logic once chainOfCustodies are removed.
 * @param  {number} itemProfileId
 * @param  {object} options
 * @param  {number} options.masterItemId
 * @param  {number} options.reportId
 * @param  {string} options.reasonForDeletion
 * @return {Promise}
 */
export function deleteEvidenceItem(itemProfileId, { masterItemId, reportId, reasonForDeletion }) {
    return function (dispatch, getState, dependencies) {
        return evidenceHydratedItemResource
            .deleteHydratedItem(itemProfileId, reasonForDeletion)
            .then((success) => {
                if (!success) {
                    throw new Error('Failed to delete evidence item');
                }

                // remove RMS data
                const removeHydratedItem = withRemoveHydratedItem(
                    { type: 'DELETE_EVIDENCE_ITEM' },
                    itemProfileId,
                    dependencies,
                    masterItemId
                );

                // remove Evidence data
                return dispatch(
                    withRemoveEvidenceHydratedItem(
                        removeHydratedItem,
                        {
                            itemProfileId,
                            masterItemId,
                            reportId,
                        },
                        dependencies.nexus.withRemoveMultiple
                    )
                );
            });
    };
}

import { EntityTypeEnumType, FolderBreadcrumb } from '@mark43/rms-api';
import React, { useContext } from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import { getCaseFolderPath } from '../utils/getCaseFolderPath';
import Icon, { iconTypes } from '../../../core/components/Icon';
import DropdownMenu from '../../../core/components/DropdownMenu';
import { caseFolderDefaults } from '../configuration';
import { SearchFilterContext } from '../contexts';
import testIds from '../../../../core/testIds';
import Breadcrumb, { CurrentFolderStyle } from './Breadcrumb';

const { MAX_BREADCRUMBS_DISPLAYED } = caseFolderDefaults;

const StyledBreadcrumbsWrap = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;

    & > * {
        margin-right: 0.5em;
    }
`;

interface FolderBreadcrumbsProps {
    entityId: number | undefined;
    entityType: EntityTypeEnumType;
    breadcrumbs: FolderBreadcrumb[] | undefined;
    tabName: string;
}

const FolderBreadcrumbs: React.FC<FolderBreadcrumbsProps> = ({
    entityId,
    entityType,
    breadcrumbs,
    tabName,
}) => {
    const { setFilter, setCurrentPage } = useContext(SearchFilterContext);

    if (!entityId || !breadcrumbs) {
        return <CurrentFolderStyle>{tabName}</CurrentFolderStyle>;
    }

    const renderDropdownMenu = breadcrumbs.length > MAX_BREADCRUMBS_DISPLAYED;

    const entityPath = getCaseFolderPath({
        caseId: entityId,
        entityType,
    });

    return (
        <StyledBreadcrumbsWrap>
            <Link
                to={entityPath}
                onClick={() => {
                    if (setFilter) {
                        setFilter('');
                    }
                    if (setCurrentPage) {
                        setCurrentPage(1);
                    }
                }}
            >
                {tabName}
            </Link>
            {renderDropdownMenu ? (
                <>
                    <Icon color="cobaltBlue" size={12} type={iconTypes.OPEN_RIGHT} />
                    <DropdownMenu
                        buttonContent={<span>...</span>}
                        dropRight={true}
                        data-test-id={testIds.DROPDOWN_MENU_OPTION}
                    >
                        {breadcrumbs
                            .slice(0, breadcrumbs.length - MAX_BREADCRUMBS_DISPLAYED)
                            .map((crumb) => {
                                const folderPath = getCaseFolderPath({
                                    caseId: entityId,
                                    entityType,
                                    folderId: crumb.folderId,
                                });
                                return (
                                    <Link
                                        to={folderPath}
                                        className="dropdown-menu-option"
                                        key={`dropdown-option-${crumb.folderId}`}
                                        onClick={() => {
                                            if (setFilter) {
                                                setFilter('');
                                            }
                                            if (setCurrentPage) {
                                                setCurrentPage(1);
                                            }
                                        }}
                                        data-test-id={testIds.DROPDOWN_MENU_OPTION_LINK}
                                    >
                                        {crumb.folderName}
                                    </Link>
                                );
                            })}
                    </DropdownMenu>
                    {breadcrumbs.slice(-MAX_BREADCRUMBS_DISPLAYED).map((crumb, index, crumbs) => {
                        const folderPath = getCaseFolderPath({
                            caseId: entityId,
                            entityType,
                            folderId: crumb.folderId,
                        });
                        const lastBreadcrumb = crumbs.length === index + 1;

                        return (
                            <Breadcrumb
                                path={folderPath}
                                name={crumb.folderName}
                                key={`breadcrumb-${crumb.folderId}`}
                                lastBreadcrumb={lastBreadcrumb}
                            />
                        );
                    })}
                </>
            ) : (
                breadcrumbs.map((crumb, index, crumbs) => {
                    const folderPath = getCaseFolderPath({
                        caseId: entityId,
                        entityType,
                        folderId: crumb.folderId,
                    });
                    const lastBreadcrumb = crumbs.length === index + 1;

                    return (
                        <Breadcrumb
                            path={folderPath}
                            name={crumb.folderName}
                            key={`breadcrumb-${crumb.folderId}`}
                            lastBreadcrumb={lastBreadcrumb}
                        />
                    );
                })
            )}
        </StyledBreadcrumbsWrap>
    );
};

export default FolderBreadcrumbs;

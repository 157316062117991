import { createWithEntityItems } from 'nexus-mark43';
import { createSelector } from 'reselect';
import { map } from 'lodash';
import getCountryResource from '../../resources/countryResource';
import { countriesSelector } from '../data';

const withEntityItems = createWithEntityItems({ key: 'items' });
const LOAD_COUNTRIES_BEGIN = 'countries/LOAD_COUNTRIES_BEGIN';
const LOAD_COUNTRIES_SUCCESS = 'countries/LOAD_COUNTRIES_SUCCESS';
const LOAD_COUNTRIES_FAILURE = 'countries/LOAD_COUNTRIES_FAILURE';

const loadCountriesBegin = () => ({ type: LOAD_COUNTRIES_BEGIN });
const loadCountriesSuccess = () => ({ type: LOAD_COUNTRIES_SUCCESS });
const loadCountriesFailure = (error) => ({ type: LOAD_COUNTRIES_FAILURE, payload: error });

export const countriesUiSelector = (state) => state.ui.countries;

export const countriesUiReducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD_COUNTRIES_BEGIN:
            return { loading: true };
        case LOAD_COUNTRIES_SUCCESS:
            return { loading: false };
        case LOAD_COUNTRIES_FAILURE:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export function loadCountries() {
    return (dispatch) => {
        dispatch(loadCountriesBegin());
        return getCountryResource()
            .searchForCountries({ size: 1000 })
            .then((countries) => {
                dispatch(withEntityItems({ countries }, loadCountriesSuccess()));
                return countries;
            })
            .catch((err) => dispatch(loadCountriesFailure(err)));
    };
}

export const onlyNameCountriesSelector = createSelector(countriesSelector, (countries) => {
    const countryNamesOnly = map(countries, ({ value: _value, display: _display }) => {
        const nameOnly = _display.slice(_value.length + 3);

        return { value: nameOnly, display: nameOnly, code: _value };
    });
    return countryNamesOnly;
});

import { EntityTypeEnum, AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { chain, get, map } from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import { getAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { nameAttributesWhereSelector } from '~/client-common/core/domain/name-attributes/state/data';

import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { personProfileByIdSelector } from '~/client-common/core/domain/person-profiles/state/data';

import {
    replacePersonGangTrackingsWhere,
    storePersonGangTrackings,
    personGangTrackingForPersonIdSelector,
} from '~/client-common/core/domain/person-gang-trackings/state/data';
import formsRegistry from '../../../../../core/formsRegistry';

import {
    openBox,
    closeBox,
    saveBoxHalt,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import gangTrackingResource from '../../resources/gangTrackingResource';

const context = {
    name: boxEnum.GANG_TRACKING_SIDE_PANEL,
};

export function openGangTrackingSidePanel(reportId) {
    return (dispatch, getState) => {
        const state = getState();
        const defendantId = get(arrestForReportIdSelector(state)(reportId), 'defendantId');
        const personProfilesWithCriteria = chain(
            nameReportLinksWhereSelector(state)({
                reportId,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
            })
        )
            .map('nameId')
            .concat([defendantId])
            .compact()
            .uniq()
            .map((nameId) => {
                const personProfile = personProfileByIdSelector(state)(nameId);
                return {
                    personProfile,
                    gangCriteria: nameAttributesWhereSelector(state)({
                        nameId,
                        attributeType: AttributeTypeEnum.GANG_CRITERIA.name,
                    }),
                };
            })
            .filter(({ gangCriteria }) => gangCriteria.length !== 0)
            .filter(({ personProfile }) => !personProfile.isExpunged)
            .value();
        gangTrackingResource
            .getPersonGangTrackingsBatch(
                map(personProfilesWithCriteria, 'personProfile.masterPersonId')
            )
            .then((result) => {
                dispatch(storePersonGangTrackings(result));
            });
        dispatch(openBox(context, { reportId, personProfilesWithCriteria }));
    };
}

export function handleSubmit(masterPersonId) {
    return (dispatch, getState) => {
        const form = formsRegistry.get(RefContextEnum.FORM_GANG_UNIT_SIDE_PANEL.name);
        return form.submit().then((result) => {
            const formModel = result.form.getState().model;
            const formGangAttrId = formModel.gangNameAttrId;
            const isDeleting = !formModel.gangNameAttrId && !formModel.gangSubgroupAttrId;
            if (isDeleting) {
                return dispatch(submitGangTrackingInformation(masterPersonId, []));
            } else {
                const state = getState();
                const formGangNameAttribute = getAttributeByIdSelector(state)(formGangAttrId);
                const existingGangTracking = personGangTrackingForPersonIdSelector(state)(
                    masterPersonId
                );

                const gangAttributeData =
                    formGangNameAttribute.type === AttributeTypeEnum.GANG_NAME.name
                        ? {
                              gangNameAttrId: formGangAttrId,
                          }
                        : {
                              gangNameAttrId: formGangNameAttribute.parentId,
                              gangSubgroupAttrId: formGangAttrId,
                          };
                return dispatch(
                    submitGangTrackingInformation(masterPersonId, [
                        {
                            personProfileId: masterPersonId,
                            ...gangAttributeData,
                            ...(existingGangTracking && { id: existingGangTracking.id }),
                        },
                    ])
                );
            }
        });
    };
}

function submitGangTrackingInformation(masterPersonId, personGangTrackings) {
    return (dispatch) => {
        return gangTrackingResource
            .setMasterPersonGangTrackings(masterPersonId, personGangTrackings)
            .then((result) => {
                dispatch(saveBoxHalt(context));
                dispatch(
                    replacePersonGangTrackingsWhere({ personProfileId: masterPersonId }, result)
                );
                return result;
            })
            .catch((err) => {
                dispatch(saveBoxFailure(context, [err.message]));
            });
    };
}

export function closeGangTrackingSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(context));
    };
}

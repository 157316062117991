import React from 'react';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fields from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { formatAttributeWithOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import { FormattedDate } from '~/client-common/core/dates/components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatWarrantShortTitle } from '~/client-common/helpers/warrantHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

const WarrantTitle = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const ObtainingInfo = styled.div`
    color: ${(props) => props.theme.colors.darkGrey};
`;

const AgencyNameContainer = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

// This is not ideal, but WarrantCell cannot be wrapped because it breaks the SYMBOL
// insde the videwModel
const ReportingEventNumber = compose(
    renderOnlyIf(({ ren }) => !!ren),
    withFields([fields.REPORT_REPORTING_EVENT_NUMBER])
)(function reportingEventNumber({ ren, fieldDisplayNames }) {
    return (
        <ObtainingInfo>
            {fieldDisplayNames[fields.REPORT_REPORTING_EVENT_NUMBER]}: {ren}
        </ObtainingInfo>
    );
});

const OtherJurisdiction = compose(
    renderOnlyIf(({ issuingAgencyNameAttrId }) => !!issuingAgencyNameAttrId),
    withFields([fields.WARRANT_ISSUING_AGENCY_NAME_ATTR_ID]),
    connect(
        createStructuredSelector({
            formatAttributeWithOther: formatAttributeWithOtherSelector,
        })
    )
)(function otherJurisdiction({
    issuingAgencyNameAttrId,
    issuingAgencyNameAttrDetail,
    fieldDisplayNames,
    formatAttributeWithOther,
}) {
    return (
        <ObtainingInfo>
            {`${
                fieldDisplayNames[fields.WARRANT_ISSUING_AGENCY_NAME_ATTR_ID]
            }: ${formatAttributeWithOther({
                attributeId: issuingAgencyNameAttrId,
                other: issuingAgencyNameAttrDetail ? issuingAgencyNameAttrDetail.displayValue : '',
            })}`}
        </ObtainingInfo>
    );
});

function WarrantCell({
    elasticWarrant,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}) {
    const viewModelProps = getViewModelProperties(elasticWarrant);
    const warrantShortTitle = formatWarrantShortTitle({
        warrantNumber: elasticWarrant.warrantNumber,
        warrantType: viewModelProps.warrantTypeAttrId,
    });

    const isExternalAgency = elasticWarrant.departmentId !== currentUserDepartmentId;
    const departmentDisplayTag = isExternalAgency
        ? ` (${departmentNameFromConsortiumLinksByDepartmentId(elasticWarrant.departmentId)})`
        : undefined;

    return (
        <div>
            <WarrantTitle>{warrantShortTitle}</WarrantTitle>
            <ReportingEventNumber ren={elasticWarrant.reportingEventNumber} />
            {departmentDisplayTag && (
                <AgencyNameContainer>{departmentDisplayTag}</AgencyNameContainer>
            )}
            <OtherJurisdiction
                issuingAgencyNameAttrId={elasticWarrant.issuingAgencyNameAttrId}
                issuingAgencyNameOther={elasticWarrant.issuingAgencyNameAttrDetail}
            />
            <ObtainingInfo>
                {viewModelProps.obtainingOfficerUserDisplay && (
                    <div>{viewModelProps.obtainingOfficerUserDisplay}</div>
                )}
                <FormattedDate
                    date={elasticWarrant.warrantIssuedDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE}
                >
                    {(formattedDate) => <div>{formattedDate}</div>}
                </FormattedDate>
            </ObtainingInfo>
        </div>
    );
}

export default compose(
    mapProps((ownerProps) => ({ elasticWarrant: ownerProps })),
    connect(
        createStructuredSelector({
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
        })
    )
)(WarrantCell);

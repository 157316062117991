import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { notificationsInbox } from '../state/ui';
import NotificationsTable from './NotificationsTable';

const { selectors, actionCreators } = notificationsInbox;

const mapStateToProps = createStructuredSelector({
    query: selectors.currentQuerySelector,
    results: selectors.currentResultsViewModelsSelector,
    totalCount: selectors.totalCountSelector,
    errorMessage: selectors.errorMessageSelector,
    selectedRows: selectors.selectedRowsSelector,
    allResultsSelected: selectors.allResultsSelectedSelector,
});

const mapDispatchToProps = (dispatch) => ({
    searchNotifications: (query) =>
        dispatch(
            actionCreators.search(query, {
                cacheBust: true,
            })
        ),
    selectRows: (ids) => dispatch(actionCreators.selectRows(ids)),
    handleSelectAllRows: () => dispatch(actionCreators.selectAllResults()),
});

/**
 * On the notifications dashboard, this is all the content that appears on the
 *   inbox route to the right of the sidebar.
 */
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTable);

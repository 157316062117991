import { compact, every, filter, map } from 'lodash';

/**
 * Filters an array of models by a user's text input. This is for situations where you want to filter
 * in a forgiving way, so that capitalization and spaces are ignored.
 * @param {filterValue: string} filterValue The value to filter on
 * @param {models: any[]} The array of models to filter off of
 *  @param {getFilterValueFromModel: () => string} The array of entities to filter off of
 *
 * @return {any[]}
 */
export function filterArrayForInput({ filterValue, models, getFilterValueFromModel }) {
    const splitValues = compact(map(filterValue.split(' '), (x) => x.toLowerCase()));
    return filter(models, (model) => {
        return every(splitValues, (value) => {
            const filterString = getFilterValueFromModel(model);
            if (typeof filterString === 'string') {
                return filterString.toLowerCase().indexOf(value) > -1;
            }
            return false;
        });
    });
}

import { RoleTypeEnum } from '@mark43/rms-api';
import Promise from 'bluebird';

import _, { isEmpty, forEach } from 'lodash';
import { NEXUS_STATE_PROP as USER_SEARCH_RESULTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/user-search-results/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { findAdditionsAndSubtractions } from '~/client-common/helpers/logicHelpers';
import { NEXUS_STATE_PROP as ROLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/roles/state/data';
import {
    storeUserRoles,
    removeUserRolesWhere,
} from '~/client-common/core/domain/user-roles/state/data';
import rolesResource from '../resources/rolesResource';
import rolesActionTypes from './types/rolesActionTypes';
import { searchUsers } from './searchActions';
import { closeBox, saveBoxSuccess, saveBoxFailure } from './boxActions';

const rolesSidepanelContext = {
    name: boxEnum.USER_PROFILE_ADMIN_ROLES_SIDE_PANEL,
};

export function loadUserRolesSuccess(roles) {
    return {
        type: rolesActionTypes.LOAD_USER_ROLES_SUCCESS,
        payload: roles,
    };
}

function addUserRolesSuccess(roles) {
    return {
        type: rolesActionTypes.ADD_USER_ROLES_SUCCESS,
        payload: roles,
    };
}

function deleteUserRolesSuccess(roles) {
    return {
        type: rolesActionTypes.DELETE_USER_ROLES_SUCCESS,
        payload: roles,
    };
}

function saveUserRolesFailure() {
    return {
        type: rolesActionTypes.SAVE_USER_ROLES_FAILURE,
    };
}

function saveUserRoleSuccess(role) {
    return {
        type: rolesActionTypes.SAVE_USER_ROLE_SUCCESS,
        payload: role,
    };
}

function saveUserRoleFailure() {
    return {
        type: rolesActionTypes.SAVE_USER_ROLE_FAILURE,
    };
}

/**
 * Search for user roles (each is a role that only one user belongs to). Store
 *   the search results in data state.
 * @param  {string}     query
 * @param  {Object}     options
 *         {string}     effectiveDate   Moment string that specifies the duty
 *                                      status effective date that will be used
 *                                      to query for `userSearchResult`s.
 *                                      Only *active* users for the specified
 *                                      `effectiveDate` will be queried for.
 * @return {Promise<Object[]>} Roles.
 */
export function searchUserRoles(query, { effectiveDate }) {
    return function (dispatch, getState, dependencies) {
        return dispatch(searchUsers(query, { effectiveDate })).then((users) => {
            const userRoles = _.map(users, (user) => ({
                id: user.userRoleId,
                roleType: RoleTypeEnum.USER.name,
                userId: user.id,
                departmentId: user.departmentId,
            }));
            const data = {};
            if (users.length > 0) {
                data[USER_SEARCH_RESULTS_NEXUS_STATE_PROP] = users;
            }
            if (userRoles.length > 0) {
                data[ROLES_NEXUS_STATE_PROP] = userRoles;
            }

            if (!isEmpty(data)) {
                dispatch(
                    dependencies.nexus.withEntityItems(data, { type: 'SEARCH_USER_ROLES_SUCCESS ' })
                );
            }

            return userRoles;
        });
    };
}

function saveMultipleUserRoles(userId, roleIds, isUserAdmin) {
    return Promise.map(roleIds, (roleId) => rolesResource.updateRole(userId, roleId, isUserAdmin));
}

function saveUserRole(userId, roleId, isUserAdmin) {
    return () => rolesResource.updateRole(userId, roleId, isUserAdmin);
}

// The DELETE endpoint simply returns true for a successful operation,
// but we need the userId and roleId further down the line in order to update the ui and data states.
function deleteMultipleUserRoles(userId, roleIds) {
    return Promise.map(roleIds, (roleId) => {
        return rolesResource.deleteRole(userId, roleId).then(() => ({ userId, roleId }));
    });
}

function updateUserRoles(userId, roleIdsToCreate, roleIdsToDelete) {
    return (dispatch) => {
        return Promise.all([
            saveMultipleUserRoles(userId, roleIdsToCreate, false),
            deleteMultipleUserRoles(userId, roleIdsToDelete),
        ])
            .spread((createdRoles, deletedRoles) => {
                dispatch(addUserRolesSuccess(createdRoles));
                dispatch(deleteUserRolesSuccess(deletedRoles));
                dispatch(storeUserRoles(createdRoles));
                forEach(deletedRoles, ({ roleId }) => {
                    dispatch(removeUserRolesWhere({ roleId }));
                });
            })
            .catch((err) => {
                dispatch(saveUserRolesFailure());
                throw err;
            });
    };
}

export function cancelUserProfileRolesPanel() {
    return (dispatch) => {
        dispatch(closeBox(rolesSidepanelContext));
    };
}

export function saveUserProfileRolesPanel(userId, roleIdsToCreate, roleIdsToDelete) {
    return (dispatch) => {
        return dispatch(updateUserRoles(userId, roleIdsToCreate, roleIdsToDelete))
            .then(() => dispatch(saveBoxSuccess(rolesSidepanelContext)))
            .then(() => dispatch(closeBox(rolesSidepanelContext)))
            .catch(() => dispatch(saveBoxFailure(rolesSidepanelContext)));
    };
}

function updateUserRole(userId, roleId, isUserAdmin) {
    return (dispatch) => {
        return dispatch(saveUserRole(userId, roleId, isUserAdmin))
            .then((response) => dispatch(saveUserRoleSuccess(response)))
            .catch(() => dispatch(saveUserRoleFailure()));
    };
}

export function updateMultipleUserRoleAdminStatus(userId, oldRoleIds, newRoleIds) {
    return (dispatch) => {
        const [roleIdsToCreate, roleIdsToDelete] = findAdditionsAndSubtractions(
            oldRoleIds,
            newRoleIds
        );

        return Promise.all([
            Promise.map(roleIdsToCreate, (roleId) =>
                dispatch(updateUserRole(userId, roleId, true))
            ),
            Promise.map(roleIdsToDelete, (roleId) =>
                dispatch(updateUserRole(userId, roleId, false))
            ),
        ]);
    };
}

export function updateRoleName(updateRole) {
    return (dispatch, getState, dependencies) =>
        rolesResource.updateRoleName(updateRole).then((updateRole) => {
            dispatch(
                dependencies.nexus.withEntityItems(
                    {
                        [ROLES_NEXUS_STATE_PROP]: [updateRole],
                    },
                    { type: 'UPDATE_ROLE_NAME_SUCCESS' }
                )
            );
            return updateRole;
        });
}

export function deleteRoleByRoleId(roleId) {
    return (dispatch, getState, dependencies) =>
        rolesResource
            .deleteRoleByRoleId(roleId)
            .then(() =>
                dispatch(
                    dependencies.nexus.withRemove(
                        ROLES_NEXUS_STATE_PROP,
                        { id: roleId },
                        { type: 'DELETE_ROLE_BY_ROLE_ID_SUCCESS' }
                    )
                )
            );
}

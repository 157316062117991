import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import {
    mustBeInt,
    requiredString,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const duplicateEventSearchFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'searchRadiusFeet',
        validators: {
            mustBeIntError: mustBeInt,
            requiredError: requiredString,
        },
    },
    'includeClosedEvents',
    {
        key: 'closedEventSearchTimespanMinutes',
        validators: {
            mustBeIntError: mustBeInt,
        },
    },
]);

export default createFormModule({
    formName: formClientEnum.DUPLICATE_EVENT_SEARCH_ADMIN,
    fieldViewModels: duplicateEventSearchFormFieldViewModels,
    formValidators: {
        duplicateEventSearchTimespanIsRequired: ({
            includeClosedEvents,
            closedEventSearchTimespanMinutes,
        }) => (includeClosedEvents ? !!closedEventSearchTimespanMinutes : true),
    },
});

import _, { get, head, pick, values, find, sortBy, filter, isArray, includes } from 'lodash';
import { createSelector } from 'reselect';
import { ApprovalStatusEnum, ProductModuleEnum } from '@mark43/rms-api';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import {
    cardTitleByCardId,
    reportCardTitleByReportIdAndCardIdSelector,
    reportDefinitionByIdSelector,
    reportDefinitionHasCardSelector,
} from '~/client-common/core/domain/report-definitions/state/data';
import { reportShortTitlesSelector } from '~/client-common/core/domain/report-short-titles/state/data';
import { reportShortTitleViewModelsSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { warrantTitleViewModelsSelector } from '~/client-common/core/domain/warrant-titles/state/ui';
import { caseTitleViewModelsSelector } from '~/client-common/core/domain/case-titles/state/ui';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { convertReportDefinitionFieldsToReportApprovalLevelClientEnum } from '~/client-common/helpers/reportApprovalLevelHelpers';
import { orderedReportStatusHistoriesByReportIdSelector } from '~/client-common/core/domain/report-status-histories/state/data';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { trafficCrashByReportIdSelector } from '~/client-common/core/domain/traffic-crashes/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    offenseReportDefinitionForCurrentDepartmentSelector,
    offenseModifyingSupplementReportDefinitionForCurrentDepartmentSelector,
} from '../../modules/core/report-definitions/state/ui';
import {
    currentUserDepartmentIdSelector,
    currentUserHasAbilitySelector,
} from '../../modules/core/current-user/state/ui';
import { computeCanEditReportCard } from '../../modules/reports/core/state/ui/cardUtilities';
import { RootState } from '../reducers/rootReducer';

/**
 * Reports keyed by id.
 * @deprecated
 */
export const reportsSelector = (state: RootState) => state.data.reports;

/**
 * Id of the report currently being viewed.
 */
export const currentReportIdSelector = (state: RootState) => state.ui.report.currentReportId;

/**
 * Last Report created in the current session.
 * This can be an array of ids if there are O/I report
 * and nested arrest reports created in the current session.
 */
export const lastCreatedReportIdSelector = (state: RootState) => state.ui.lastCreatedReportId;

export const currentReportIsSnapshotSelector = (state: RootState) => state.ui.report.isSnapshot;

/**
 * The current report being viewed by the user.
 */
export const currentReportSelector = createSelector(
    reportsSelector,
    currentReportIdSelector,
    (reports, currentReportId) => (currentReportId ? reports[currentReportId] : undefined)
);

export const currentReportInUserDepartmentSelector = createSelector(
    currentReportSelector,
    currentUserDepartmentIdSelector,
    (currentReport, currentUserDepartmentId) =>
        get(currentReport, 'departmentId') === currentUserDepartmentId
);

/**
 * The current report is loading
 */
export const reportIsLoadingSelector = (state: RootState) => state.ui.report.reportIsLoading;

/**
 * The REN of the current report being viewed by the user.
 */
export const currentReportRENSelector = createSelector(currentReportSelector, (currentReport) =>
    get(currentReport, 'reportingEventNumber')
);

/**
 * The eventId of the current report being viewed by the user.
 */
export const currentReportEventIdSelector = createSelector(currentReportSelector, (currentReport) =>
    get(currentReport, 'eventId')
);

/**
 * The ids of the cases linked to the current report, not sorted.
 */
const linkedCaseIdsSelector = (state: RootState) => state.ui.report.linkedCaseIds;

/**
 * The ids of the warrants linked to the current report, not sorted.
 */
const linkedWarrantIdsSelector = (state: RootState) => state.ui.report.linkedWarrantIds;

/**
 * The ids of the reports linked to the current report, not sorted.
 */
export const linkedReportIdsSelector = (state: RootState) => state.ui.report.linkedReportIds;

/**
 * All report short titles that pertain to reports linked to the current report.
 */
export const linkedReportShortTitlesSelector = createSelector(
    linkedReportIdsSelector,
    reportShortTitlesSelector,
    (linkedReportIds, reportShortTitles) => pick(reportShortTitles, linkedReportIds)
);

/**
 * All report short title view models that pertain to reports linked to the current report.
 */
export const linkedReportShortTitleViewModelsSelector = createSelector(
    linkedReportIdsSelector,
    reportShortTitleViewModelsSelector,
    (linkedReportIds, reportShortTitleViewModels) =>
        pick(reportShortTitleViewModels, linkedReportIds)
);

export const linkedCaseTitleViewModelsSelector = createSelector(
    linkedCaseIdsSelector,
    caseTitleViewModelsSelector,
    (linkedCaseIds, caseTitleViewModels) => values(pick(caseTitleViewModels, linkedCaseIds))
);

/**
 * Warrant Title View models of warrants linked to the current report.
 */
export const linkedWarrantTitleViewModelsSelector = createSelector(
    linkedWarrantIdsSelector,
    warrantTitleViewModelsSelector,
    (linkedWarrantIds, warrantTitleViewModels) =>
        values(pick(warrantTitleViewModels, linkedWarrantIds))
);

export const isReportNewSelector = createSelector(
    lastCreatedReportIdSelector,
    (lastCreatedReportId) => (reportId: number | null) => {
        if (lastCreatedReportId) {
            if (isArray(lastCreatedReportId)) {
                return includes(lastCreatedReportId, reportId);
            }
            return lastCreatedReportId === reportId;
        }
        return false;
    }
);

export const isCurrentReportNewSelector = createSelector(
    currentReportIdSelector,
    isReportNewSelector,
    (currentReportId, isReportNew) => isReportNew(currentReportId)
);

/**
 * Complete `ReportStatusView` object, computed by the BE.  Contains booleans such as
 * `canEdit`, `canEditOffenses`, canMasterEdit`, etc., which are booleans
 * describing actions that a user can take on a report.
 * `ReportStatusView` also contains accompanying user-facing error messages, giving
 * reasoning as to why an action cannot be taken.  These `ReportStatusError` objects
 * are named similarly to their accompanying booleans, such as `canEditErrors`,
 * `canEditOffensesErrors`, canMasterEditErrors`, etc.
 */
export const reportStatusViewSelector = (state: RootState) => state.ui.report.reportStatusView;

/**
 * Returns an object with shape:
 * `{ canEditReportCard: <boolean>, errorMessage: <string> }`
 * denoting whether or not the user can edit the report card.
 * The `errorMessage` is used for user-facing messaging describing why the edit action
 * cannot be taken.
 * Note: Offense and Incident report cards are handled specially just below in
 * `canEditOffenseIncidentReportCardStatusSelector`.
 *
 * There are nuances, see
 *   https://readme.mark43.io/guides/products/rms/rms-frontend/rmsfe-permissions/
 */
export const canEditReportCardStatusSelector = createSelector(
    reportStatusViewSelector,
    currentUserHasAbilitySelector,
    (reportStatusView, currentUserHasAbility) => {
        if (!reportStatusView) {
            return false;
        }
        return computeCanEditReportCard({
            canEdit: reportStatusView.canEdit,
            editErrors: reportStatusView.canEditErrors,
            canMasterEdit: reportStatusView.canMasterEdit,
            masterEditErrors: reportStatusView.canMasterEditErrors,
            userHasMasterEditAbility: currentUserHasAbility(abilitiesEnum.REPORTING.MASTER_EDIT),
        });
    }
);

export const canEditOffenseIncidentReportCardStatusSelector = createSelector(
    reportStatusViewSelector,
    currentUserHasAbilitySelector,
    (reportStatusView, currentUserHasAbility) => {
        if (!reportStatusView) {
            return false;
        }
        return computeCanEditReportCard({
            canEdit: reportStatusView.canEditOffenses,
            editErrors: reportStatusView.canEditOffensesErrors,
            canMasterEdit: reportStatusView.canMasterEdit,
            masterEditErrors: reportStatusView.canMasterEditErrors,
            userHasMasterEditAbility: currentUserHasAbility(abilitiesEnum.REPORTING.MASTER_EDIT),
        });
    }
);

export const canEditEventInfoReportCardStatusSelector = createSelector(
    reportStatusViewSelector,
    currentUserHasAbilitySelector,
    (reportStatusView, currentUserHasAbility) => {
        if (!reportStatusView) {
            return false;
        }
        return computeCanEditReportCard({
            canEdit: reportStatusView.canEditEventInfo,
            editErrors: reportStatusView.canEditEventInfoErrors,
            canMasterEdit: reportStatusView.canMasterEdit,
            masterEditErrors: reportStatusView.canMasterEditErrors,
            userHasMasterEditAbility: currentUserHasAbility(abilitiesEnum.REPORTING.MASTER_EDIT),
        });
    }
);

export const canEditSummaryNarrativeReportCardStatusSelector = createSelector(
    reportStatusViewSelector,
    currentUserHasAbilitySelector,
    (reportStatusView, currentUserHasAbility) => {
        if (!reportStatusView) {
            return false;
        }
        return computeCanEditReportCard({
            canEdit: reportStatusView.canEditSummaryNarrative,
            editErrors: reportStatusView.canEditSummaryNarrativeErrors,
            canMasterEdit: reportStatusView.canMasterEdit,
            masterEditErrors: reportStatusView.canMasterEditErrors,
            userHasMasterEditAbility: currentUserHasAbility(abilitiesEnum.REPORTING.MASTER_EDIT),
        });
    }
);

/**
 * Whether the user can submit the current report.
 */
export const canSubmitReportSelector = (state: RootState) => state.ui.report.canSubmit;

export const reportIsPackagedSelector = (state: RootState) => state.ui.report.reportIsPackaged;

export const reportIsUcrValidatedSelector = (state: RootState) =>
    state.ui.report.reportIsUcrValidated;

const reportSupplementViewSelector = (state: RootState) => state.ui.report.reportSupplementView;

export const renReportSupplementHistoriesSelector = createSelector(
    reportSupplementViewSelector,
    (reportSupplementView) => {
        return get(reportSupplementView, 'renReportSupplementHistories');
    }
);

/**
 * Returns the approved REN Offense report id for the current report, else undefined.
 * Logic to pull the approved REN Offense report id:
 *  - the current report is an `Offense Modifying Supplement` report
 *  - there is an approved REN offense report, derived from the `ReportSupplementView`
 */
export const offenseModifyingSupplementRenOffenseReportIdSelector = createSelector(
    currentReportSelector,
    reportSupplementViewSelector,
    offenseModifyingSupplementReportDefinitionForCurrentDepartmentSelector,
    (
        currentReport,
        reportSupplementView,
        offenseModifyingSupplementReportDefinitionForCurrentDepartment
    ) => {
        const isOMSReport =
            get(currentReport, 'reportDefinitionId') ===
            get(offenseModifyingSupplementReportDefinitionForCurrentDepartment, 'id');
        const approvedRenOffenseReportId = get(
            reportSupplementView,
            'renOffenseFlatHydratedReport.report.id'
        );

        return isOMSReport && !isUndefinedOrNull(approvedRenOffenseReportId)
            ? approvedRenOffenseReportId
            : undefined;
    }
);

export const reportOwnerIdSelector = createSelector(
    reportStatusViewSelector,
    (reportStatusView) => {
        if (!reportStatusView) {
            return undefined;
        }
        return reportStatusView.activeSubmission?.ownerId;
    }
);

/**
 * The approval status of the current report.
 */
export const approvalStatusSelector = createSelector(
    currentReportSelector,
    reportStatusViewSelector,
    (currentReport, reportStatusView) => {
        if (!reportStatusView || !currentReport) {
            return undefined;
        }
        return reportStatusView.clientApprovalStatus;
    }
);

export const currentReportApprovalLevelSelector = createSelector(
    currentReportSelector,
    reportDefinitionByIdSelector,
    (currentReport, reportDefinitionById) => {
        if (!currentReport) {
            return undefined;
        }
        const reportDefinitionId = get(currentReport, 'reportDefinitionId');
        const reportDefinition = reportDefinitionById(reportDefinitionId);
        return convertReportDefinitionFieldsToReportApprovalLevelClientEnum(reportDefinition);
    }
);

export const currentReportCardUITitleByTypeSelector = createSelector(
    currentReportIdSelector,
    formatFieldByNameSelector,
    reportCardTitleByReportIdAndCardIdSelector,
    (currentReportId, formatFieldByName, reportCardTitleByReportIdAndCardId) => (
        cardId: number
    ) => {
        if (!currentReportId) {
            return cardTitleByCardId(cardId, formatFieldByName) || 'Card';
        }
        return reportCardTitleByReportIdAndCardId(currentReportId, cardId);
    }
);

/**
 * The authors of the current report, in the server's ReportSubmissionAuthor data model.
 */
export const reportSubmissionAuthorsSelector = (state: RootState) =>
    state.ui.report.reportSubmissionAuthors;

/**
 * The report exported segments,
 */
const reportNibrsExportedSegmentsSelector = (state: RootState) =>
    state.ui.report.reportNibrsExportedReportSegments;

export const reportLastExportedDateSelector = createSelector(
    reportNibrsExportedSegmentsSelector,
    (exportedSegments) => {
        return _(exportedSegments)
            .map((segment) => segment.updatedDateUtc)
            .sort((a, b) => (b < a ? -1 : 1))
            .first();
    }
);

/**
 * Offense Report Id for the current report's REN or undefined if no Offense Report exists.
 */
export const offenseReportIdForCurrentReportRENSelector = createSelector(
    offenseReportDefinitionForCurrentDepartmentSelector,
    currentReportSelector,
    linkedReportShortTitlesSelector,
    (offenseReportDefinition, currentReport, linkedReportShortTitles) => {
        if (!currentReport) {
            return undefined;
        }
        // defensive -- there should be only 1 Offense Report for a REN.
        return get(currentReport, 'reportDefinitionId') === offenseReportDefinition?.id
            ? currentReport.id
            : _(linkedReportShortTitles)
                  .filter({
                      reportDefinitionId: offenseReportDefinition?.id,
                      reportingEventNumber: get(currentReport, 'reportingEventNumber'),
                  })
                  .map('reportId')
                  .sortBy()
                  .last();
    }
);

export const latestReportStatusHistoryForCurrentReport = createSelector(
    orderedReportStatusHistoriesByReportIdSelector,
    currentReportIdSelector,
    (orderedReportStatusHistoriesByReportId, currentReportId) => {
        if (!currentReportId) {
            return undefined;
        }
        return head(orderedReportStatusHistoriesByReportId(currentReportId));
    }
);

export const secondaryApprovedReportAdditionalArgumentsSelector = createSelector(
    orderedReportStatusHistoriesByReportIdSelector,
    currentReportIdSelector,
    (orderedReportStatusHistoriesByReportId, currentReportId) => {
        if (!currentReportId) {
            return undefined;
        }
        const reportStatusHistories = orderedReportStatusHistoriesByReportId(currentReportId);
        const supervisorHistoryRecord = find(reportStatusHistories, (history) => {
            return (
                history.approvalStatus === ApprovalStatusEnum.COMPLETED.name &&
                !history.secondaryApprovalStatus
            );
        });

        if (!supervisorHistoryRecord) {
            return;
        }

        return {
            supervisorUserId: supervisorHistoryRecord.createdBy,
            date: supervisorHistoryRecord.createdDateUtc,
        };
    }
);

/**
 * All oms reports linked to the current report.
 */
export const linkedOmsReportSelector = createSelector(
    linkedReportShortTitlesSelector,
    offenseModifyingSupplementReportDefinitionForCurrentDepartmentSelector,
    (linkedReportShortTitles, omsReportDefinition) =>
        omsReportDefinition
            ? sortBy(
                  filter(linkedReportShortTitles, {
                      reportDefinitionId: omsReportDefinition.id,
                  }),
                  'reportId'
              )
            : []
);

export const prefillForReportCardSelector = (state: RootState) => (
    reportCardName:
        | typeof reportCardEnum.SUPPLEMENT_INFO.name
        | typeof reportCardEnum.EVENT_INFO.name
) => state.ui.report.prefill[reportCardName];

export const isQuickCrashReportDefinitionSelector = createSelector(
    isProductModuleActiveSelector,
    reportDefinitionHasCardSelector,
    (isProductModuleActive, reportDefinitionHasCard) => (reportDefinitionId?: number) => {
        const isTrafficCrashModuleEnabled = isProductModuleActive(
            ProductModuleEnum.TRAFFIC_CRASH.name
        );
        const isTrafficCrashReport =
            !!reportDefinitionId &&
            reportDefinitionHasCard(reportDefinitionId, reportCardEnum.TRAFFIC_CRASH.id);
        return isTrafficCrashModuleEnabled && isTrafficCrashReport;
    }
);

export const isQuickCrashReportSelector = createSelector(
    currentReportSelector,
    isQuickCrashReportDefinitionSelector,
    trafficCrashByReportIdSelector,
    (currentReport, isQuickCrashReportDefinition, trafficCrashByReportId) => {
        if (!currentReport) {
            return false;
        }
        const trafficCrash = trafficCrashByReportId(currentReport.id);
        return isQuickCrashReportDefinition(currentReport.reportDefinitionId) && !!trafficCrash;
    }
);

export const disableReportSubmissionButtonsSelector = createSelector(
    currentReportInUserDepartmentSelector,
    currentReportIsSnapshotSelector,
    approvalStatusSelector,
    isQuickCrashReportSelector,
    (currentReportInUserDepartment, currentReportIsSnapshot, approvalStatus, isQuickCrashReport) =>
        currentReportIsSnapshot ||
        !currentReportInUserDepartment ||
        (approvalStatus === ApprovalStatusEnum.DRAFT.name && isQuickCrashReport)
);

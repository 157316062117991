import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { compact, map } from 'lodash';

import { NEXUS_STATE_PROP as CITATIONS_NEXUS_STATE_PROP } from '../../../citations/state/data';
import { NEXUS_STATE_PROP as CITATION_CHARGES_NEXUS_STATE_PROP } from '../../../citation-charges/state/data';
import { NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP } from '../../../report-attributes/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '../../../location-entity-links/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

/**
 * `AttributeType`s that are valid for the `ReportAttribute`s in a Citation Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const CITATION_CARD_REPORT_ATTRIBUTE_TYPES = [AttributeTypeEnum.CITATION_STATISTICS.name];

/**
 * `LinkTypes` that are valid for the `LocationEntityLinks`s in an Citation Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const CITATION_CARD_LOCATION_LINK_TYPES = [LinkTypesEnum.LOCATION_OF_CITATION];

export const buildCitationChargeOffenseCodeIdDisplayName = (displayName, chargeOrder) =>
    `${displayName} - ${chargeOrder}`;

export function replaceCitationCardBundle({ reportId, citationCardBundle }) {
    return function (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) {
        const resource = getReportCardBundleResource();
        return resource
            .upsertCitationCard(reportId, citationCardBundle)
            .then((updatedCitationCardBundle) => {
                const {
                    citation,
                    citationCharges,
                    reportAttributes,
                    locationEntityLink,
                } = updatedCitationCardBundle;
                const { id: citationId } = citation;
                const predicateCitationChargesToRemoveBy = [{ citationId }];
                const predicateReportAttributeObjectsToRemoveBy = map(
                    CITATION_CARD_REPORT_ATTRIBUTE_TYPES,
                    (attributeType) => ({
                        reportId,
                        attributeType,
                    })
                );
                const predicateLocationEntityLinksToRemoveBy = map(
                    CITATION_CARD_LOCATION_LINK_TYPES,
                    (linkType) => ({
                        linkType,
                        entityType: EntityTypeEnum.CITATION.name,
                        entityId: citationId,
                    })
                );

                dispatch(
                    withEntityItems(
                        {
                            [CITATIONS_NEXUS_STATE_PROP]: [citation],
                            [CITATION_CHARGES_NEXUS_STATE_PROP]: citationCharges,
                            [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportAttributes,
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: compact([locationEntityLink]),
                        },
                        withRemoveMultiple(
                            {
                                [CITATION_CHARGES_NEXUS_STATE_PROP]: predicateCitationChargesToRemoveBy,
                                [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: predicateReportAttributeObjectsToRemoveBy,
                                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: predicateLocationEntityLinksToRemoveBy,
                            },
                            {
                                type: 'UPSERT_CITATION_CARD_BUNDLE',
                            }
                        )
                    )
                );

                return updatedCitationCardBundle;
            });
    };
}

import { EntityTypeEnum } from '@mark43/rms-api';
import { mapValues } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';
import classNames from 'classnames';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';
import Row from '../../../core/components/Row';
import { RRFFieldset, CollapsibleFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNFieldsets } from '../../../core/forms/components/NFieldsets';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFAdvancedSearchLinkTypeSelect } from '../../../core/forms/components/selects/AdvancedSearchLinkTypeSelect';
import { organizationFieldsetViewModel } from '../../../../legacy-redux/configs/fieldsetsConfig';
import testIds from '../../../../core/testIds';
import {
    LocationFieldsetRow1,
    LocationFieldsetRow2,
    LocationFieldsetRow3,
} from './LocationsFieldset';
import SearchCapabilityIcon, { SEARCH_CAPABILITY_TYPE_ENUM } from './SearchCapabilityIcon';

const IconWrapper = styled.span`
    margin-left: 10px;
`;

const labels = mapValues(organizationFieldsetViewModel.fields, 'label');

function OrganizationFieldsetRow1({ fuzzyMatchingEnabled, expandCollapseButton }) {
    const organizationFieldName = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];
    return (
        <Row>
            <div data-test-id={testIds.ORGANIZATION_PROFILE_NAME}>
                <RRFText
                    label={labels.name(organizationFieldName)}
                    width={248}
                    className={classNames({
                        'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                    })}
                    extraLabelContent={
                        <IconWrapper>
                            <SearchCapabilityIcon
                                variant={SEARCH_CAPABILITY_TYPE_ENUM.BOOLEAN.name}
                            />
                        </IconWrapper>
                    }
                    labelClassName=""
                    path="name"
                />
            </div>
            <RRFText
                label={labels.phoneNumber(organizationFieldName)}
                width={248}
                className={classNames({
                    'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                })}
                path="phoneNumber"
            />
            {expandCollapseButton}
        </Row>
    );
}

const entityTypePairs = [
    [EntityTypeEnum.ORGANIZATION_PROFILE.name, EntityTypeEnum.REPORT.name],
    [EntityTypeEnum.REPORT.name],
];

function OrganizationFieldsetRow2({ includeInvolvement, includeIndustryAttrId }) {
    const organizationFieldName = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];
    return (
        <Row>
            {includeInvolvement && (
                <RRFAdvancedSearchLinkTypeSelect
                    entityTypePairs={entityTypePairs}
                    label={labels.involvement(organizationFieldName)}
                    width={248}
                    path="involvement"
                />
            )}
            <RRFAttributeSelect
                label={labels.organizationTypeAttrIds(organizationFieldName)}
                attributeType="ORGANIZATION_TYPE"
                multiple={true}
                grouped={true}
                showSelectedGroup={false}
                includeExpired={true}
                width={248}
                path="organizationTypeAttrIds"
            />
            {includeIndustryAttrId && (
                <RRFAttributeSelect
                    label={labels.industryAttrIds(organizationFieldName)}
                    attributeType="INDUSTRY"
                    multiple={true}
                    includeExpired={true}
                    width={248}
                    path="industryAttrIds"
                />
            )}
        </Row>
    );
}

/**
 * Fieldset for a single organization, including location fields. There's no add
 *   button on the location NItems since only 1 location can be entered for an
 *   org. Some of the fields are optional for use in different forms.
 * @param {Object}  props
 * @param {Object}  [props.fuzzyMatchingEnabled]
 * @param {boolean} [props.includeInvolvement=false]
 * @param {boolean} [props.includeIndustryAttrId=false]
 * @param {boolean} [props.includeCrossStreets=false]
 */
export const OrganizationFieldset = pure(function OrganizationFieldset({
    fuzzyMatchingEnabled,
    includeInvolvement = false,
    includeIndustryAttrId = false,
    includeCrossStreets = false,
    ...otherProps
}) {
    const organizationFieldName = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];
    return (
        <RRFFieldset title={organizationFieldName} path="organization" {...otherProps}>
            <OrganizationFieldsetRow1 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <OrganizationFieldsetRow2
                includeInvolvement={includeInvolvement}
                includeIndustryAttrId={includeIndustryAttrId}
            />
            <RRFNItems path="locations">
                {() => (
                    <div>
                        <LocationFieldsetRow1 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                        <LocationFieldsetRow2 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                        <LocationFieldsetRow3
                            fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                            includeCrossStreets={includeCrossStreets}
                        />
                    </div>
                )}
            </RRFNItems>
        </RRFFieldset>
    );
});

/**
 * Collapsible fieldset for a single organization, including location fields.
 * @param {Object}  props
 * @param {boolean} [props.fuzzyMatchingEnabled]
 * @param {boolean} [props.includeInvolvement]
 * @param {boolean} [props.includeIndustryAttrId]
 */
function CollapsibleOrganizationFieldset({
    fuzzyMatchingEnabled,
    includeInvolvement,
    includeIndustryAttrId,
    ...otherProps
}) {
    return (
        <CollapsibleFieldset
            expandedChildren={
                <div>
                    <OrganizationFieldsetRow2
                        includeInvolvement={includeInvolvement}
                        includeIndustryAttrId={includeIndustryAttrId}
                    />
                    <RRFNItems path="locations">
                        {() => (
                            <div>
                                <LocationFieldsetRow1
                                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                                    includeSearchIcon={false}
                                />
                                <LocationFieldsetRow2 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                            </div>
                        )}
                    </RRFNItems>
                </div>
            }
            {...otherProps}
        >
            {(expandCollapseButton) => (
                <OrganizationFieldsetRow1
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    expandCollapseButton={expandCollapseButton}
                />
            )}
        </CollapsibleFieldset>
    );
}

/**
 * N organization fieldsets, each of which is collapsible and includes location
 *   fields. There's no add button on the location NItems since only 1 location
 *   can be entered for an org. Some of the fields are optional for use in
 *   different forms.
 * @param {Object}  props
 * @param {boolean} [props.fuzzyMatchingEnabled]
 * @param {boolean} [props.includeInvolvement=false]
 * @param {boolean} [props.includeIndustryAttrId=false]
 */
export default pure(function OrganizationsFieldset({
    fuzzyMatchingEnabled,
    includeInvolvement = false,
    includeIndustryAttrId = false,
    ...otherProps
}) {
    return (
        <RRFNFieldsets
            path="organizations"
            automaticallyIncludeDeleteButton={false}
            {...otherProps}
        >
            {(fieldsetProps) => (
                <CollapsibleOrganizationFieldset
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    includeInvolvement={includeInvolvement}
                    includeIndustryAttrId={includeIndustryAttrId}
                    {...fieldsetProps}
                />
            )}
        </RRFNFieldsets>
    );
});

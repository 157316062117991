import httpHeaderEnum from '~/client-common/core/enums/client/httpHeaderEnum';
import { encode } from '../lib/base64';

/**
 * Get the auth token from the cookie.
 * @return {string} 26-character string.
 */
export function getAuthToken() {
    return localStorage.csrfToken;
}

/**
 * Remove the auth token, generally as part of de-authing the user
 * @return {undefined}
 */
export function clearAuthToken() {
    localStorage.removeItem('csrfToken');
}

/**
 * Get the value for the HTTP Authorization header.
 * @return {string} String of the form  `Basic <Base64 encoded string>`.
 */
export function getAuthorizationHeader() {
    return `Basic ${encode(getAuthToken() + ':' + httpHeaderEnum.X_SESSION_TOKEN)}`; // eslint-disable-line prefer-template
}

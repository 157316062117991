import { ProductModuleEnum } from '@mark43/rms-api';
import React, { useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import styled from 'styled-components';

import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    useEvidenceModuleName,
    useCaseFieldName,
} from '~/client-common/core/fields/hooks/useFields';
import { getFederatedSearchNotificationsCount } from '../../../modules/federated-search/state/ui';
import { Ability, OnlyWithAbility, abilitiesEnum } from '../../../modules/core/abilities';
import Link from '../../../modules/core/components/links/Link';
import testIds from '../../../core/testIds';

import { logout } from '../../actions/authActions';
import { pathStartsWith } from '../../helpers/urlHelpers';
import { useRouteTitle, ROUTE_KEYS } from '../../../routing/routesConfig';

import _DropdownMenu from '../../../modules/core/components/DropdownMenu';
import Icon, { iconTypes } from '../../../modules/core/components/Icon';

import { QuickSearch } from '../../../modules/search/quick-search/components';
import {
    departmentStatusIndicatorSelector,
    shouldShowFieldNamesSelector,
} from '../../selectors/globalSelectors';
import { departmentProfileSelector } from '../../selectors/departmentProfileSelectors';
import { userInitialsSelector, userProfileUrlSelector } from '../../selectors/userSelectors';
import { navigationPermissionsSelector } from '../../selectors/navigationSelectors';
import AccountSwitcherLink from '../../../modules/core/account-switcher/components/AccountSwitcherLink';
import NavigationLink from '../../../modules/cobalt/navigation/core/components/NavigationLink';
import NotificationsNavigationLink from '../../../modules/cobalt/navigation/navigation-links/components/NotificationsNavigationLink';
import ItemQueueNavigationLink from '../../../modules/cobalt/navigation/navigation-links/components/ItemQueueNavigationLink';
import { toggleShowFieldNames, toggleFieldDisplayNames } from '../../actions/globalActions';
import { KeyboardShortcutsSidePanel } from '../../../modules/core/keyboard-shortcuts/components/KeyboardShortcutsSidePanel';
import { SUPPORT_URL } from '../../../core/constants';

const titles = componentStrings.core.navigation;

const DropdownMenu = styled(_DropdownMenu)`
    .dropdown-menu-options {
        max-width: 200px;

        .dropdown-menu-option {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .user-initials {
        font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    }
`;

const mapStateToProps = createStructuredSelector({
    userInitials: userInitialsSelector,
    userProfileUrl: userProfileUrlSelector,
    navigationPermissions: navigationPermissionsSelector,
    departmentStatusIndicator: departmentStatusIndicatorSelector,
    departmentProfile: departmentProfileSelector,
    shouldShowFieldNames: shouldShowFieldNamesSelector,
});

class NavSearch extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            showSearch: false,
        };

        this.handleQuickSearchClose = this.handleQuickSearchClose.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleContainerRef = this.handleContainerRef.bind(this);
    }

    componentDidMount() {
        this.clickHandler = (e) => {
            const { containerRef } = this;
            const { target } = e;
            if (
                this.state.showSearch &&
                containerRef &&
                !containerRef.contains(target) &&
                containerRef !== target
            ) {
                this.setState(() => ({ showSearch: false }));
            }
        };

        // in order to close the overlay properly,
        // we have to manually listen for clicks on the window
        window.addEventListener('click', this.clickHandler);

        this.keyupHandler = (e) => {
            if (e.keyCode === keyCodeEnum.ESCAPE && this.state.showSearch) {
                this.setState(() => ({ showSearch: false }));
            }
        };
        window.addEventListener('keyup', this.keyupHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.clickHandler);
        window.removeEventListener('keyup', this.keyupHandler);
    }

    handleContainerRef(ref) {
        this.containerRef = ref;
    }

    handleQuickSearchClose() {
        this.setState(() => ({ showSearch: false }));
    }

    handleSearchClick() {
        if (!this.state.showSearch) {
            this.setState(() => ({ showSearch: true }));
        }
    }

    render() {
        return (
            <div ref={this.handleContainerRef}>
                <div
                    onClick={this.handleSearchClick}
                    data-test-id={testIds.QUICK_SEARCH_BUTTON}
                    className={classNames({
                        'nav-box auto-search': true,
                        'highlighted-nav': this.state.showSearch,
                    })}
                >
                    <div
                        className={classNames('nav-link', {
                            'nav-selected': this.props.selected,
                        })}
                    >
                        <div>
                            <Icon type={iconTypes.SEARCH} size={20} />
                        </div>
                        <span>{titles.search}</span>
                    </div>
                </div>
                {this.state.showSearch && <QuickSearch onClose={this.handleQuickSearchClose} />}
            </div>
        );
    }
}

// this legacy component is simply to facilitate rendering our whole app
// under react before proper refactoring
function Navigation({
    userInitials,
    userProfileUrl,
    navigationPermissions,
    departmentStatusIndicator,
    departmentProfile,
    logout,
    location,
    toggleFieldNames,
    shouldShowFieldNames,
}) {
    const evidenceModuleName = useEvidenceModuleName();
    const { pluralCaseFieldName: casesFieldName } = useCaseFieldName();
    const federatedSearchNotificationsCount = useSelector(getFederatedSearchNotificationsCount);
    const reports = navigationPermissions.hasReportingGeneral && (
        <NavigationLink
            to="/reports"
            text={titles.reports}
            iconType={iconTypes.REPORT}
            selected={pathStartsWith(location.pathname, ['/reports', '/react-reports'])}
            testId={testIds.MODULE_REPORTS}
        />
    );
    const cases = navigationPermissions.hasCasesGeneral && (
        <ProductModuled productModule={ProductModuleEnum.CASES.name}>
            <NavigationLink
                to="/cases"
                text={titles.cases(casesFieldName)}
                iconType={iconTypes.CASE}
                selected={pathStartsWith(location.pathname, '/cases')}
                testId={testIds.MODULE_CASES}
            />
        </ProductModuled>
    );
    const warrants = (
        <ProductModuled productModule={ProductModuleEnum.WARRANTS.name}>
            <OnlyWithAbility has={abilitiesEnum.WARRANTS.VIEW_GENERAL}>
                <NavigationLink
                    to="/warrants"
                    text={titles.warrants}
                    iconType={iconTypes.WARRANT}
                    selected={pathStartsWith(location.pathname, '/warrants')}
                    testId={testIds.MODULE_WARRANTS}
                />
            </OnlyWithAbility>
        </ProductModuled>
    );
    const evidence = (
        <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
            <OnlyWithAbility has={abilitiesEnum.EVIDENCE.SEARCH_ITEMS}>
                <NavigationLink
                    to="/evidence/dashboard"
                    text={titles.evidence(evidenceModuleName)}
                    iconType={iconTypes.EVIDENCE}
                    selected={pathStartsWith(location.pathname, '/evidence')}
                    testId={testIds.MODULE_EVIDENCE}
                />
            </OnlyWithAbility>
        </ProductModuled>
    );
    const analysis = navigationPermissions.hasAnalysisGeneral && (
        <ProductModuled productModule={ProductModuleEnum.ANALYSIS.name}>
            <NavigationLink
                to="/analytics"
                text={titles.analysis}
                iconType={iconTypes.ANALYSIS}
                selected={pathStartsWith(location.pathname, '/analytics')}
                testId={testIds.MODULE_ANALYSIS}
            />
        </ProductModuled>
    );

    const insights = (
        <ProductModuled productModule={ProductModuleEnum.INSIGHTS.name}>
            <OnlyWithAbility has={abilitiesEnum.ANALYSIS.GENERAL}>
                <NavigationLink
                    to="/insights"
                    text={titles.insights}
                    iconType={iconTypes.ANALYTICS_LOOK}
                    selected={pathStartsWith(location.pathname, '/insights')}
                />
            </OnlyWithAbility>
        </ProductModuled>
    );

    const notifications = (
        <ProductModuled productModule={ProductModuleEnum.NOTIFICATIONS.name}>
            <OnlyWithAbility has={abilitiesEnum.NOTIFICATIONS.CORE}>
                <NotificationsNavigationLink
                    text={titles.notifications}
                    testId={testIds.MODULE_NOTIFICATIONS}
                />
            </OnlyWithAbility>
        </ProductModuled>
    );
    const mobile = (
        <FeatureFlagged flag="RMS_MOBILE_ENABLED">
            <OnlyWithAbility has={abilitiesEnum.REPORTING.FIELD_NOTES}>
                <NavigationLink
                    to="/mobile/collections"
                    text={titles.mobile}
                    iconType={iconTypes.NOTE}
                    selected={pathStartsWith(location.pathname, '/mobile')}
                    testId={testIds.MODULE_MOBILE}
                />
            </OnlyWithAbility>
        </FeatureFlagged>
    );
    const federatedSearch = (
        <ProductModuled productModule={ProductModuleEnum.RMS_DEX.name}>
            <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_MAKE_INQUIRIES}>
                <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_VIEW_RESULTS}>
                    <NavigationLink
                        to="/federated-search"
                        text={titles.dex}
                        count={federatedSearchNotificationsCount}
                        iconType={iconTypes.DEX}
                        selected={pathStartsWith(location.pathname, '/federated-search')}
                        testId={testIds.FEDERATED_SEARCH}
                    />
                </OnlyWithAbility>
            </OnlyWithAbility>
        </ProductModuled>
    );
    const AccountSettings = () => {
        const routeTitle = useRouteTitle(ROUTE_KEYS.MY_ACCOUNT);
        return navigationPermissions.hasReadUsers ? (
            <Link
                className="dropdown-menu-option"
                to={userProfileUrl}
                testId={testIds.INITIALS_DROPDOWN_ACCOUNT_SETTINGS}
            >
                {routeTitle}
            </Link>
        ) : null;
    };
    const AdminSettings = () => {
        const routeTitle = useRouteTitle(ROUTE_KEYS.ADMIN_SETTINGS);
        return navigationPermissions.hasAnyAdminAbility ? (
            <Link
                className="dropdown-menu-option"
                to="/admin"
                testId={testIds.INITIALS_DROPDOWN_ADMIN_SETTINGS}
            >
                {routeTitle}
            </Link>
        ) : null;
    };
    const ComplianceDashboard = () => {
        const canNibrs =
            navigationPermissions.hasNibrs && checkIfDepartmentIsNibrs(departmentProfile);
        const canCompliance =
            navigationPermissions.hasComplianceDashboard && departmentProfile.complianceGroup;
        const routeTitle = useRouteTitle(ROUTE_KEYS.COMPLIANCE_DASHBOARD);
        return canNibrs || canCompliance ? (
            <Link
                className="dropdown-menu-option"
                to="/compliance/dashboard"
                testId={testIds.INITIALS_DROPDOWN_COMPLIANCE_DASHBOARD}
            >
                {routeTitle}
            </Link>
        ) : null;
    };
    const Bulletins = () => {
        const routeTitle = useRouteTitle(ROUTE_KEYS.ADMIN_ALERTS);
        return navigationPermissions.hasManageBulletinsPermissions ? (
            <Link
                className="dropdown-menu-option"
                to="/admin/alerts"
                testId={testIds.INITIALS_DROPDOWN_MANAGE_ALERTS}
            >
                {routeTitle}
            </Link>
        ) : null;
    };
    const itemQueue = (
        <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
            <OnlyWithAbility has={abilitiesEnum.EVIDENCE.ITEM_QUEUE}>
                <ItemQueueNavigationLink
                    onPath={pathStartsWith(location.pathname, '/evidence/item-queue')}
                />
            </OnlyWithAbility>
        </ProductModuled>
    );

    const tasks = (
        <FeatureFlagged flag="RMS_TASK_AND_REQUEST_TRACKING_ENABLED">
            <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_NON_CASE_TASKS}>
                <NavigationLink
                    to="/tasks"
                    text={titles.tasks}
                    iconType={iconTypes.TASK}
                    selected={pathStartsWith(location.pathname, '/tasks')}
                    testId={testIds.MODULE_TASKS}
                />
            </OnlyWithAbility>
        </FeatureFlagged>
    );

    const dropdownRef = useRef(null);

    return (
        <div className="mark43-header">
            <div className="mark43-container header-container">
                <NavSearch selected={pathStartsWith(location.pathname, ['/persons', '/search'])} />
                <NavigationLink
                    to="/"
                    text={titles.dashboard}
                    iconType={iconTypes.DASHBOARD}
                    selected={location.pathname === '/'}
                    testId={testIds.MODULE_DASHBOARD}
                />
                {reports}
                {cases}
                {evidence}
                {warrants}
                {analysis}
                {insights}
                {mobile}
                {tasks}
                {notifications}
                {federatedSearch}
                <DropdownMenu
                    buttonContent={
                        <div data-test-id={testIds.INITIALS_DROPDOWN_BUTTON}>
                            <span className="user-initials">{userInitials}</span>
                            <Icon type={iconTypes.TRIANGLE_DOWN} />
                        </div>
                    }
                    className="account-dropdown-menu"
                    width={200}
                    ref={dropdownRef}
                >
                    <AccountSettings />
                    <AdminSettings />
                    <ComplianceDashboard />
                    <Bulletins />
                    <FeatureFlagged flag="RMS_TOGGLE_FIELD_LABELS_ENABLED">
                        <OnlyWithAbility
                            hasOneOf={[
                                abilitiesEnum.ADMIN.VIEW_VALIDATION,
                                abilitiesEnum.ADMIN.EDIT_GLOBAL_ATTRIBUTE_CONFIGURATION,
                            ]}
                        >
                            <div className="dropdown-menu-option" onClick={toggleFieldNames}>
                                {shouldShowFieldNames ? 'Hide' : 'Show'}{' '}
                                <Ability ability={abilitiesEnum.ADMIN.VIEW_VALIDATION}>
                                    {({ userHasAbility }) => (
                                        <span>
                                            {userHasAbility ? 'Field Names' : 'Attribute Types'}
                                        </span>
                                    )}
                                </Ability>
                            </div>
                        </OnlyWithAbility>
                    </FeatureFlagged>
                    <KeyboardShortcutsSidePanel
                        renderButton={({ overlayBase: { open }, setCancelFocusRef }) => (
                            <div
                                className="dropdown-menu-option"
                                onClick={() => {
                                    setCancelFocusRef(dropdownRef);
                                    open();
                                }}
                            >
                                <span>{componentStrings.keyboardShortcutsSidePanel.title}</span>
                            </div>
                        )}
                    />
                    <FeatureFlagged flag="ACCOUNT_SWITCHER_ENABLED">
                        <AccountSwitcherLink />
                    </FeatureFlagged>
                    <a
                        className="dropdown-menu-option"
                        href={SUPPORT_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {componentStrings.core.adminMenu.support}
                    </a>
                    <a
                        className="dropdown-menu-option"
                        target="_self"
                        onClick={logout}
                        data-test-id={testIds.INITIALS_DROPDOWN_LOGOUT}
                    >
                        {componentStrings.core.adminMenu.logout}
                    </a>
                </DropdownMenu>
                {itemQueue}
                {departmentStatusIndicator && (
                    <div className="nav-training-indicator">{departmentStatusIndicator}</div>
                )}
            </div>
        </div>
    );
}

export default connect(mapStateToProps, (dispatch) => ({
    logout() {
        dispatch(logout());
    },
    toggleFieldNames() {
        dispatch(toggleShowFieldNames());
        dispatch(toggleFieldDisplayNames());
    },
}))(Navigation);

import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { createFormConfiguration, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { submitWarrantActivityForm, WARRANT_ACTIVITY_BOX_CONTEXT } from '../state/ui';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTTextArea } from '../../../core/forms/components/TextArea';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import { ArbiterMFTDatePicker } from '../../../core/forms/components/DatePicker';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTUserSelect } from '../../../core/forms/components/selects/UserSelect';
import Row from '../../../core/components/Row';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import IndentedFields from '../../../core/components/IndentedFields';
import { currentUserIdSelector } from '../../../core/current-user/state/ui';
import { closeBox } from '../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../core/box/state/ui';
import formsRegistry from '../../../../core/formsRegistry';

const strings = componentStrings.warrants.warrant.WarrantActivitySidePanel;

const MyselfButton = styled(Button)`
    margin-top: 19px;
`;

function WarrantActivitySidePanel({ onSubmit, close, currentUserId, boxPayload }) {
    const { warrantActivityId, warrantId } = boxPayload || {};
    return (
        <SidePanel
            title={strings.title}
            onSave={() => onSubmit({ warrantActivityId, warrantId })}
            onCancel={close}
            context={WARRANT_ACTIVITY_BOX_CONTEXT}
            width={400}
        >
            <ArbiterForm
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                name={RefContextEnum.FORM_WARRANT_ACTIVITY.name}
                context={RefContextEnum.FORM_WARRANT_ACTIVITY.name}
                configuration={createFormConfiguration({
                    activityDateUtc: {
                        fieldName: fields.WARRANT_ACTIVITY_ACTIVITY_DATE_UTC,
                    },
                    activityTypeAttrId: {
                        fieldName: fields.WARRANT_ACTIVITY_ACTIVITY_TYPE_ATTR_ID,
                    },
                    activityTypeOther: {
                        fieldName: fields.WARRANT_ACTIVITY_ACTIVITY_TYPE_OTHER,
                    },
                    activityPerformedByUserId: {
                        fieldName: fields.WARRANT_ACTIVITY_ACTIVITY_PERFORMED_BY_USER_ID,
                    },
                    notes: {
                        fieldName: fields.WARRANT_ACTIVITY_NOTES,
                    },
                })}
                render={({ set, resetUi }) => (
                    <div>
                        <Row>
                            <ArbiterMFTDatePicker
                                label="Activity Date"
                                path="activityDateUtc"
                                includeTime={true}
                                width={225}
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                label="Activity Type"
                                attributeType="WARRANT_ACTIVITY"
                                width={225}
                                path="activityTypeAttrId"
                            />
                        </Row>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTText path="activityTypeOther" width={225} />
                            </Row>
                        </IndentedFields>
                        <Row>
                            <ArbiterMFTUserSelect
                                label="User"
                                width={225}
                                path="activityPerformedByUserId"
                            />
                            <MyselfButton
                                className={buttonTypes.SECONDARY}
                                onClick={() => {
                                    set('activityPerformedByUserId', currentUserId);
                                    resetUi('activityPerformedByUserId');
                                }}
                            >
                                {strings.myself}
                            </MyselfButton>
                        </Row>
                        <Row>
                            <ArbiterMFTTextArea label="Notes" width={348} path="notes" rows={4} />
                        </Row>
                    </div>
                )}
            />
        </SidePanel>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUserId: currentUserIdSelector,
    boxPayload: createModalSelector(WARRANT_ACTIVITY_BOX_CONTEXT),
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: ({ warrantActivityId, warrantId }) =>
        dispatch(submitWarrantActivityForm({ warrantActivityId, warrantId })),
    close: () => {
        dispatch(closeBox(WARRANT_ACTIVITY_BOX_CONTEXT));
        const form = formsRegistry.get(RefContextEnum.FORM_WARRANT_ACTIVITY.name);
        form.resetModel();
    },
});

/**
 * Side panel for adding/editing an activity
 */
export default connect(mapStateToProps, mapDispatchToProps)(WarrantActivitySidePanel);

import Promise from 'bluebird';

import warrantCardEnum from '~/client-common/core/enums/client/warrantCardEnum';
import createCard from '../../../../reports/core/utils/createCard';

// Also used by reports.
// It's a bit janky that the card state lives in warrants, but it works.
const baseCard = createCard({
    name: warrantCardEnum.ATTACHMENTS,
    baseSelector: (state) => state.ui.warrants.warrant.attachmentsCard,
    anchor: 'attachments-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit(options = {}) {
            const { index } = options;
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        save(options = {}) {
            return (dispatch, getState) => {
                return new Promise((resolve) => {
                    const state = getState();
                    const cardIndex = { index: options.index };
                    const isInSummaryMode = baseCard.selectors.summaryModeSelector(
                        state,
                        cardIndex
                    );
                    if (!isInSummaryMode) {
                        // just transition into summary mode, nothing to "actually" save
                        dispatch(baseCard.actionCreators.savingSuccess(cardIndex));
                        baseCard.scrollToTop(cardIndex);
                    }
                    resolve();
                });
            };
        },
    },
};

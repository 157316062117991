import _ from 'lodash';

/**
 * @param  {Object} nameAttributes
 * @param  {number} nameId
 * @return {Object[]} Name attributes by nameId and truthy attributeId.
 */
function filterNameAttributesByNameId(nameAttributes, nameId) {
    return _.filter(
        nameAttributes,
        ({ nameId: nId, attributeId }) => nId === nameId && attributeId
    );
}

/**
 * Convert nameAttributes data models to redux-form state.
 * @param  {Object[]} nameAttributes
 * @param  {number}   nameId
 * @param  {boolean}  returnArrayValue Whether to return an array value or an object
 * @return {Object}
 */
export function convertNameAttributesDataStateToFormState(
    nameAttributes,
    nameId,
    returnArrayValue = false
) {
    const ids = _.map(filterNameAttributesByNameId(nameAttributes, nameId), 'attributeId');
    return returnArrayValue
        ? ids
        : {
              value: ids,
          };
}

/**
 * Convert nameAttributes form data to data state models.
 * @param  {number[]} formData       Attribute ids from a form field.
 * @param  {number}   nameId
 * @param  {string}   nameEntityType
 * @param  {string}   attributeType
 * @param  {string}   description
 * @param  {function} attributeIsOther
 * @param  {number}   departmentId
 *
 * @return {Object[]}
 */
export function convertNameAttributesFormDataToDataState(
    formData,
    nameId,
    nameEntityType,
    attributeType,
    description,
    attributeIsOther,
    departmentId
) {
    return _.chain(formData)
        .map((attributeId) => ({
            entityType: nameEntityType,
            nameId,
            attributeId,
            attributeType,
            description:
                attributeIsOther && attributeIsOther(attributeId) ? description : undefined,
            departmentId,
        }))
        .filter('attributeId')
        .value();
}

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionHeader, AccordionContent, AccordionItem, Text, cssVar } from 'arc';
import testIds from '../../../core/testIds';

type RemarksAccordionProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'defaultValue'> & {
    /** Content displayed in the header, it is wrapped inside a <p/>. */
    header: React.ReactNode;
    /** Use when externally update the state of the accordion with another interaction or to set the initial state. */
    isCollapsed?: boolean;
};

const StyledAccordionItem = styled(AccordionItem)`
    border-top: 1px solid ${cssVar('arc.colors.border.default')};
    border-bottom: none;
`;
const accordionValue = 'remarks';

/** Use this for display remarks that have been added to a property item or report */
export const RemarksAccordion = ({
    header,
    children,
    isCollapsed = true,
    className,
    ...rest
}: RemarksAccordionProps) => {
    const [collapsed, setCollapsed] = useState(isCollapsed ? [] : [accordionValue]);

    useEffect(() => {
        const collapsedItems = isCollapsed ? [] : [accordionValue];
        setCollapsed(collapsedItems);
    }, [isCollapsed]);

    return (
        <Accordion
            className={className}
            value={collapsed}
            onValueChange={(value) => setCollapsed(value)}
            {...rest}
        >
            <StyledAccordionItem value={accordionValue}>
                <AccordionHeader data-test-id={testIds.REMARKS_ACCORDION_HEADER}>
                    <Text fontSize={cssVar('arc.fontSizes.sm')} color="brand">
                        {header}
                    </Text>
                </AccordionHeader>
                <AccordionContent>{children}</AccordionContent>
            </StyledAccordionItem>
        </Accordion>
    );
};

import React from 'react';
import { AttributeTypeEnum } from '@mark43/rms-api';
import { ArbiterMFTAttributeButtonRadio } from './AttributeButtonRadio';

const sortByRmsEventIdIteratee = ['rmsEventId'];

type ArbiterMFTYesNoUnknownButtonRadioPropsType = {
    path: string;
};

export const ArbiterMFTYesNoUnknownButtonRadio: React.FC<ArbiterMFTYesNoUnknownButtonRadioPropsType> = (
    props
) => (
    <ArbiterMFTAttributeButtonRadio
        attributeType={AttributeTypeEnum.YES_NO_UNKNOWN.name}
        sortByIteratees={sortByRmsEventIdIteratee}
        {...props}
    />
);

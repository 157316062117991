import { AttributeTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { find, get, includes, map, pick } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Observer, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import {
    DISPLAY_ONLY_ORGANIZATION_LABEL,
    NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_SUBJECT_TYPE_ATTR_ID,
    NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_WAS_FRISKED,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import entityTypeObjectEnum from '~/client-common/core/enums/universal/entityTypeObjectEnum';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { formatAttributeWithOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { fieldContactByReportIdSelector } from '~/client-common/core/domain/field-contacts/state/data';
import { LOCATION_ENTITY_LINK_MODEL_KEYS } from '~/client-common/core/domain/location-entity-links/state/data';
import { formatNameReportLinkTypeIdSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import { formatCommunityInfoTitleByReportIdSelector } from '~/client-common/core/domain/field-contacts/state/ui';
import buildLocationEntityLinkKey from '~/client-common/core/domain/location-entity-links/utils/buildLocationEntityLinkKey';

import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import { createNItemsAdder, createNItemsRemover } from '../../utils/nItemsHelpers';
import withCard from '../../utils/withCard';
import {
    formName,
    COMMUNITY_INFO_SUBJECTS_PATH,
    COMMUNITY_INFO_SUBJECTS_ENTITY_TYPE_ID_PATH,
    COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_ATTR_ID_PATH,
    COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_OTHER_PATH,
    COMMUNITY_INFO_SUBJECTS_WAS_FRISKED_PATH,
    COMMUNITY_INFO_ORGANIZATIONS_PATH,
    COMMUNITY_INFO_LOCATIONS_PATH,
    COMMUNITY_INFO_LOCATION_DESCRIPTION_PATH,
    COMMUNITY_INFO_LOCATION_POSITION_ATTR_ID_PATH,
    getCommunityInfoForm,
    createCommunityInfoForm,
    buildCommunityInfoCardFormModel,
    removeDefaultCommunityInfoSubjectsFromFormModel,
    maybeAddDefaultCommunityInfoSubjectToFormModel,
    removeDefaultCommunityInfoLocationsFromFormModel,
    maybeAddDefaultCommunityInfoLocationToFormModel,
    findIndexOfNItemsNameLink,
    findIndexOfNItemsLocationEntityLink,
} from '../../state/forms/communityInfoForm';
import { registerForm } from '../../state/ui';
import communityInfoCard from '../../state/ui/communityInfoCard';
import testIds from '../../../../../core/testIds';
import { registerCard } from '../../utils/cardsRegistry';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import { LocationSummaryViewWrapper } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapper';
import { locationEntityLinkSummaryVariants } from '../../../../records/core/components/summaries/locations/LocationEntityLinkSummary';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import CommunityInfoCardSummary from './CommunityInfoCardSummary';
import CommunityInfoCardForm from './CommunityInfoCardForm';

const strings = componentStrings.reports.core.CommunityInfoCard;

class CommunityInfoCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            buildCommunityInfoCardFormModel,
            currentReportId: reportId,
        } = this.props;

        const form = createCommunityInfoForm({
            initialState: buildCommunityInfoCardFormModel({ reportId }),
            arbiter,
            formatFieldByName,
        });

        registerForm({ form });
        registerCard({
            cardModule: communityInfoCard,
            onSave: this.onSave,
        });
    }

    componentWillUnmount() {
        formsRegistry.unregister(formName);
    }

    handleCommunityInfoSubjectAdd = (nameReportLink) => {
        const { entityType } = nameReportLink;
        const entityTypeId = get(entityTypeObjectEnum, `${entityType}.value`);
        const nameReportLinkWithEntityTypeId = {
            ...nameReportLink,
            [COMMUNITY_INFO_SUBJECTS_ENTITY_TYPE_ID_PATH]: entityTypeId,
        };

        // remove any default community info subjects
        removeDefaultCommunityInfoSubjectsFromFormModel();

        // add new subject
        return createNItemsAdder({
            getForm: getCommunityInfoForm,
            path: COMMUNITY_INFO_SUBJECTS_PATH,
            defaults: {
                [COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_ATTR_ID_PATH]: undefined,
                [COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_OTHER_PATH]: undefined,
                [COMMUNITY_INFO_SUBJECTS_WAS_FRISKED_PATH]: undefined,
            },
        })(nameReportLinkWithEntityTypeId);
    };

    handleCommunityInfoSubjectRemove = (nameReportLink) => {
        const { nameId } = nameReportLink;
        createNItemsRemover({
            getForm: getCommunityInfoForm,
            path: COMMUNITY_INFO_SUBJECTS_PATH,
        })({ nameId });

        // if we've successfully removed the last community info subject,
        // add a default community info subject to the form model
        maybeAddDefaultCommunityInfoSubjectToFormModel();
    };

    handleCommunityInfoOrganizationAdd = (nameReportLink) => {
        return createNItemsAdder({
            getForm: getCommunityInfoForm,
            path: COMMUNITY_INFO_ORGANIZATIONS_PATH,
        })(nameReportLink);
    };

    handleCommunityInfoOrganizationRemove = (nameReportLink) => {
        const { nameId } = nameReportLink;
        return createNItemsRemover({
            getForm: getCommunityInfoForm,
            path: COMMUNITY_INFO_ORGANIZATIONS_PATH,
        })({ nameId });
    };

    handleCommunityInfoLocationAdd = (location, locationEntityLinkModel) => {
        // remove any default community info locations
        removeDefaultCommunityInfoLocationsFromFormModel();

        // add new location
        return createNItemsAdder({
            getForm: getCommunityInfoForm,
            path: COMMUNITY_INFO_LOCATIONS_PATH,
            defaults: {
                [COMMUNITY_INFO_LOCATION_DESCRIPTION_PATH]: undefined,
            },
        })(locationEntityLinkModel);
    };

    handleCommunityInfoLocationRemove = (
        removedLocationEntityLink,
        remainingLocationEntityLinks = []
    ) => {
        const form = getCommunityInfoForm();
        const formStateLocationEntityLinks = form.get(COMMUNITY_INFO_LOCATIONS_PATH) || [];
        const remainingLocationEntityLinkKeys = map(
            remainingLocationEntityLinks,
            buildLocationEntityLinkKey
        );
        const locationEntityLinkToRemove =
            find(formStateLocationEntityLinks, (formStateLocationEntityLink) => {
                const formStateLocationEntityLinkKey = buildLocationEntityLinkKey(
                    formStateLocationEntityLink
                );
                return !includes(remainingLocationEntityLinkKeys, formStateLocationEntityLinkKey);
            }) || {};
        const locationEntityLinkKeysToRemoveBy = pick(
            locationEntityLinkToRemove,
            LOCATION_ENTITY_LINK_MODEL_KEYS
        );

        createNItemsRemover({
            getForm: getCommunityInfoForm,
            path: COMMUNITY_INFO_LOCATIONS_PATH,
        })(locationEntityLinkKeysToRemoveBy);

        // if we've successfully removed the last community info location,
        // add a default community info location to the form model
        maybeAddDefaultCommunityInfoLocationToFormModel();
    };

    onEdit = () => {
        this.props.editCallback(() => this.props.onEdit());
    };

    onSaveProgress = () => {
        const form = getCommunityInfoForm();
        return this.props.onSaveProgress(form, {});
    };

    onSave = () => {
        const form = getCommunityInfoForm();
        return this.props.onSave(form);
    };

    render() {
        const {
            card = {},
            currentReportId: reportId,
            reportById,
            fieldContactByReportId,
            formatNameReportLinkTypeId,
            formatCommunityInfoTitleByReportId,
            formatAttributeWithOther,
        } = this.props;
        const {
            anchor,
            summaryMode: cardSummaryMode,
            errorMessages,
            canEditReportCardStatus,
            saving,
        } = card;

        const { id: fieldContactId } = fieldContactByReportId(reportId);
        const renForRecents = get(reportById(reportId), 'reportingEventNumber');
        const cardTitle = formatCommunityInfoTitleByReportId(reportId);
        const communityInfoSubjectsTitle = formatNameReportLinkTypeId(
            LinkTypesEnum.SUBJECT_IN_COMMUNITY_INFORMATION
        );
        const communityInfoOrganizationTitle = formatNameReportLinkTypeId(
            LinkTypesEnum.ORGANIZATION_IN_FIELD_CONTACT
        );

        return (
            <Card
                testId={testIds.COMMUNITY_INFORMATION_CARD}
                className={anchor}
                anchor={anchor}
                title={cardTitle}
                renderContent={(summaryMode) => (
                    <>
                        {summaryMode ? (
                            <CommunityInfoCardSummary reportId={reportId} />
                        ) : (
                            <CommunityInfoCardForm />
                        )}
                        <ArbiterForm
                            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                            name={formName}
                            context={formName}
                            render={() => {
                                return (
                                    <CardSection
                                        helpText={strings.subjectSectionHelpText(
                                            this.props.formatFieldByName(
                                                DISPLAY_ONLY_ORGANIZATION_LABEL
                                            )
                                        )}
                                        testId={testIds.SUBJECT_IN_COMMUNITY_INFORMATION_SECTION}
                                        fieldName={
                                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_LINK_TYPE
                                        }
                                    >
                                        <Observer
                                            subscriptions={{
                                                communityInfoSubjects: COMMUNITY_INFO_SUBJECTS_PATH,
                                            }}
                                            formName={formName}
                                            render={() => {
                                                return (
                                                    <NameSummaryViewWrapper
                                                        renForRecents={renForRecents}
                                                        summaryMode={summaryMode}
                                                        addNameButtonText={
                                                            communityInfoSubjectsTitle
                                                        }
                                                        reportId={reportId}
                                                        contextType={
                                                            entityTypeObjectEnum.REPORT.name
                                                        }
                                                        contextId={reportId}
                                                        parentEntityType={
                                                            entityTypeObjectEnum.REPORT.name
                                                        }
                                                        parentId={reportId}
                                                        // SUBJECT_IN_COMMUNITY_INFORMATION is not actually used...
                                                        linkType={
                                                            LinkTypesEnum.SUBJECT_IN_FIELD_CONTACT
                                                        }
                                                        show={{ people: true, organizations: true }}
                                                        onAddSuccess={
                                                            this.handleCommunityInfoSubjectAdd
                                                        }
                                                        onRemoveSuccess={
                                                            this.handleCommunityInfoSubjectRemove
                                                        }
                                                        renderAdditionalItem={({ nameLink }) => {
                                                            const {
                                                                nameId,
                                                                subjectTypeAttrId,
                                                                subjectTypeOther,
                                                                wasFrisked,
                                                            } = nameLink;
                                                            const index = findIndexOfNItemsNameLink(
                                                                {
                                                                    path: COMMUNITY_INFO_SUBJECTS_PATH,
                                                                    nameId,
                                                                }
                                                            );

                                                            // Only render additional fields if the name currently exists on the form.
                                                            if (index < 0) {
                                                                return null;
                                                            }

                                                            const completeSubjectTypeAttrIdPath = `${COMMUNITY_INFO_SUBJECTS_PATH}[${index}].${COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_ATTR_ID_PATH}`;
                                                            const completeSubjectTypeOtherPath = `${COMMUNITY_INFO_SUBJECTS_PATH}[${index}].${COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_OTHER_PATH}`;
                                                            const completeWasFriskedPath = `${COMMUNITY_INFO_SUBJECTS_PATH}[${index}].${COMMUNITY_INFO_SUBJECTS_WAS_FRISKED_PATH}`;

                                                            return summaryMode ? (
                                                                <SummaryList
                                                                    labelWidth={100}
                                                                    contentWidth={400}
                                                                >
                                                                    <SummaryRow
                                                                        fieldName={
                                                                            NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_SUBJECT_TYPE_ATTR_ID
                                                                        }
                                                                    >
                                                                        {formatAttributeWithOther({
                                                                            attributeId: subjectTypeAttrId,
                                                                            other: subjectTypeOther,
                                                                        })}
                                                                    </SummaryRow>
                                                                    <SummaryRow
                                                                        fieldName={
                                                                            NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_WAS_FRISKED
                                                                        }
                                                                    >
                                                                        {booleanToYesNo(wasFrisked)}
                                                                    </SummaryRow>
                                                                </SummaryList>
                                                            ) : (
                                                                <>
                                                                    <Row>
                                                                        <ArbiterMFTAttributeSelect
                                                                            path={
                                                                                completeSubjectTypeAttrIdPath
                                                                            }
                                                                            length="lg"
                                                                            attributeType={
                                                                                AttributeTypeEnum
                                                                                    .FIELD_CONTACT_SUBJECT_TYPE
                                                                                    .name
                                                                            }
                                                                        />
                                                                    </Row>
                                                                    <Row>
                                                                        <IndentedFields>
                                                                            <ArbiterMFTText
                                                                                path={
                                                                                    completeSubjectTypeOtherPath
                                                                                }
                                                                                length="lg"
                                                                            />
                                                                        </IndentedFields>
                                                                    </Row>
                                                                    <Row>
                                                                        <ArbiterMFTBooleanSelect
                                                                            path={
                                                                                completeWasFriskedPath
                                                                            }
                                                                            length="lg"
                                                                        />
                                                                    </Row>
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </CardSection>
                                );
                            }}
                        />
                        <VisibilityObserver
                            formName={formName}
                            path={COMMUNITY_INFO_ORGANIZATIONS_PATH}
                            render={({ hidden }) =>
                                !hidden && (
                                    <CardSection
                                        testId={testIds.ORGANIZATIONS_IN_COMMUNITY_INFORMATION_SECTION}
                                        fieldName={
                                            fields.NAME_REPORT_LINK_LINK_TYPE_ORGANIZATION_IN_FIELD_CONTACT_LINK_TYPE
                                        }
                                    >
                                        <NameSummaryViewWrapper
                                            renForRecents={renForRecents}
                                            summaryMode={summaryMode}
                                            addNameButtonText={communityInfoOrganizationTitle}
                                            reportId={reportId}
                                            contextType={entityTypeObjectEnum.REPORT.name}
                                            contextId={reportId}
                                            parentEntityType={entityTypeObjectEnum.REPORT.name}
                                            parentId={reportId}
                                            linkType={LinkTypesEnum.ORGANIZATION_IN_FIELD_CONTACT}
                                            show={{ organizations: true }}
                                            onAddSuccess={this.handleCommunityInfoOrganizationAdd}
                                            onRemoveSuccess={this.handleCommunityInfoOrganizationRemove}
                                        />
                                    </CardSection>
                            )}
                        />
                        <ArbiterForm
                            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                            name={formName}
                            context={formName}
                            render={() => {
                                return (
                                    <VisibilityObserver
                                        formName={formName}
                                        path={COMMUNITY_INFO_LOCATIONS_PATH}
                                        render={({ hidden }) => {
                                            return (
                                                !hidden && (
                                                    <CardSection
                                                        testId={
                                                            testIds.COMMUNITY_INFORMATION_LOCATION_SECTION
                                                        }
                                                        fieldName={
                                                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_FIELD_CONTACT_LOCATION_ID
                                                        }
                                                    >
                                                        <Observer
                                                            subscriptions={{
                                                                communityInfoLocations: COMMUNITY_INFO_LOCATIONS_PATH,
                                                            }}
                                                            formName={formName}
                                                            render={() => {
                                                                return (
                                                                    <LocationSummaryViewWrapper
                                                                        summaryMode={summaryMode}
                                                                        locationEntityLinkSummaryVariant={
                                                                            summaryMode
                                                                                ? undefined
                                                                                : locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION
                                                                        }
                                                                        entityType={
                                                                            entityTypeObjectEnum
                                                                                .FIELD_CONTACT.name
                                                                        }
                                                                        entityId={fieldContactId}
                                                                        linkType={
                                                                            LinkTypesEnum.LOCATION_OF_FIELD_CONTACT
                                                                        }
                                                                        allowMultiple={true}
                                                                        onLocationAdd={
                                                                            this
                                                                                .handleCommunityInfoLocationAdd
                                                                        }
                                                                        onLocationRemove={
                                                                            this
                                                                                .handleCommunityInfoLocationRemove
                                                                        }
                                                                        additionalLocationContent={(
                                                                            locationBundle = {}
                                                                        ) => {
                                                                            if (summaryMode) {
                                                                                return null;
                                                                            }

                                                                            const {
                                                                                locationEntityLink,
                                                                            } = locationBundle;
                                                                            const locationEntityLinkKey = buildLocationEntityLinkKey(
                                                                                locationEntityLink
                                                                            );
                                                                            const index = findIndexOfNItemsLocationEntityLink(
                                                                                {
                                                                                    path: COMMUNITY_INFO_LOCATIONS_PATH,
                                                                                    locationEntityLinkKey,
                                                                                }
                                                                            );

                                                                            // Only render additional fields if the name currently exists on the form.
                                                                            if (index < 0) {
                                                                                return null;
                                                                            }

                                                                            const completeDescriptionPath = `${COMMUNITY_INFO_LOCATIONS_PATH}[${index}].${COMMUNITY_INFO_LOCATION_DESCRIPTION_PATH}`;
                                                                            const completePositionPath = `${COMMUNITY_INFO_LOCATIONS_PATH}[${index}].${COMMUNITY_INFO_LOCATION_POSITION_ATTR_ID_PATH}`;
                                                                            return (
                                                                                <>
                                                                                    <Row>
                                                                                        <ArbiterMFTAttributeSelect
                                                                                            path={
                                                                                                completePositionPath
                                                                                            }
                                                                                            attributeType={
                                                                                                AttributeTypeEnum
                                                                                                    .LOCATION_POSITION
                                                                                                    .name
                                                                                            }
                                                                                            length="md"
                                                                                        />
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <ArbiterMFTTextArea
                                                                                            path={
                                                                                                completeDescriptionPath
                                                                                            }
                                                                                            length="lg"
                                                                                        />
                                                                                    </Row>
                                                                                </>
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </CardSection>
                                                )
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                    </>
                )}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={cardSummaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                saving={saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class CommunityInfoCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => (
                    <CommunityInfoCard ref={this.setRef} {...this.props} arbiter={arbiter} />
                )}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    reportById: reportByIdSelector,
    fieldContactByReportId: fieldContactByReportIdSelector,
    formatFieldByName: formatFieldByNameSelector,
    formatNameReportLinkTypeId: formatNameReportLinkTypeIdSelector,
    formatCommunityInfoTitleByReportId: formatCommunityInfoTitleByReportIdSelector,
    formatAttributeWithOther: formatAttributeWithOtherSelector,
});

const mapDispatchToProps = {
    buildCommunityInfoCardFormModel,
};

export default compose(
    withCard(communityInfoCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(CommunityInfoCardWrapper);

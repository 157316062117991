import { TowVehicleCheckIn } from '@mark43/rms-api';
import { head } from 'lodash';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'towVehicleCheckIns';

const towVehicleCheckInsModule = createNormalizedModule<TowVehicleCheckIn>({
    type: NEXUS_STATE_PROP,
});

export const towVehicleCheckInsWhereSelector =
    towVehicleCheckInsModule.selectors.entitiesWhereSelector;
// Only 1 `TowVehicleCheckIn` per report
export const towVehicleCheckInByReportIdSelector = createSelector(
    towVehicleCheckInsWhereSelector,
    (towVehicleCheckInsWhere) => (reportId: number) => head(towVehicleCheckInsWhere({ reportId }))
);

export default towVehicleCheckInsModule.reducerConfig;

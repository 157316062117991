import { includes, uniq, without, map } from 'lodash';
import React from 'react';
import { iconTypes } from 'components-mark43';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { CardSection as _CardSection } from '../../../../legacy-redux/components/core/Card';
import Checkbox from '../../../core/forms/components/checkboxes/Checkbox';
import Divider from '../../../../legacy-redux/components/core/Divider';
import { renderOnlyIf } from '../../../../legacy-redux/helpers/reactHelpers';
import { useScreenBreakpoint } from '../../../core/utils/useScreenBreakpoint';
import Row from '../../../core/components/Row';
import Icon from '../../../core/components/Icon';
import { custodialPropertyStatusesSelector } from '../state/ui';
import CustodialPropertyCardItem from './custodial-property-card-item/CustodialPropertyCardItem';
import DeleteEvidenceItemModal from './DeleteEvidenceItemModal';

const strings = componentStrings.reports.custodialPropertySummary.CustodialPropertyCard;

const CardSection = styled(_CardSection)`
    padding: var(--arc-space-2) var(--arc-space-2) 0;
`;

const CardSectionTitleCheckbox = styled(Checkbox)`
    margin-top: -3px;
    margin-bottom: -3px;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: var(--arc-space-3);
`;

function CustodialPropertyCardSection({
    summaryMode,
    canEdit,
    detailsCollapsed,
    itemsHaveCoC,
    itemProfileIds,
    selectedItemProfileIds = [],
    custodialPropertyStatuses: {
        propertyStatusesWithCoC = [],
        propertyStatusesWithoutCoC = [],
    } = {},
    onAllItemSelectionToggle,
    onShowDetailsToggle,
    onItemSelectionToggle,
}) {
    let selectAllCheckboxValue = false;
    if (selectedItemProfileIds.length === itemProfileIds.length) {
        selectAllCheckboxValue = true;
    } else if (selectedItemProfileIds.length > 0) {
        selectAllCheckboxValue = 'INDETERMINATE';
    }

    const iconType = detailsCollapsed ? iconTypes.OPEN : iconTypes.CLOSE;
    const { isMobileSm } = useScreenBreakpoint();
    const propertyStatuses = itemsHaveCoC ? propertyStatusesWithCoC : propertyStatusesWithoutCoC;

    return (
        <div>
            {itemsHaveCoC && <DeleteEvidenceItemModal />}
            <CardSection
                title={
                    <Title>
                        <div>
                            {summaryMode && itemsHaveCoC && (
                                <CardSectionTitleCheckbox
                                    value={selectAllCheckboxValue}
                                    onChange={onAllItemSelectionToggle}
                                    testId={testIds.CUSTODIAL_PROPERTY_CHECKBOX_ALL}
                                />
                            )}
                            {itemsHaveCoC
                                ? strings.sectionTitles.withCoC
                                : strings.sectionTitles.withoutCoC}
                        </div>
                        {itemsHaveCoC && (
                            <Button
                                onClick={onShowDetailsToggle}
                                className={buttonTypes.ICON_LINK}
                                iconLeft={<Icon type={iconType} size={16} color="cobaltBlue" />}
                            >
                                {!isMobileSm &&
                                    (detailsCollapsed ? strings.expandAll : strings.collapseAll)}
                            </Button>
                        )}
                    </Title>
                }
            >
                {map(propertyStatuses, (propertyStatus, index) => {
                    const { itemProfileId } = propertyStatus;
                    return (
                        <Row key={itemProfileId}>
                            {index > 0 && <Divider />}
                            <CustodialPropertyCardItem
                                summaryMode={summaryMode}
                                canEditReport={canEdit}
                                hasCoC={itemsHaveCoC}
                                itemProfileId={itemProfileId}
                                selected={includes(selectedItemProfileIds, itemProfileId)}
                                onSelectionToggle={onItemSelectionToggle}
                                detailsCollapsed={detailsCollapsed}
                            />
                        </Row>
                    );
                })}
            </CardSection>
        </div>
    );
}

/**
 * Section displaying item profiles in the custodial property card.
 */
export default compose(
    connect(
        createStructuredSelector({
            custodialPropertyStatuses: custodialPropertyStatusesSelector,
        })
    ),
    renderOnlyIf(({ itemProfileIds }) => itemProfileIds.length > 0),
    withState('detailsCollapsed', 'setDetailsCollapsed', true),
    withHandlers({
        onShowDetailsToggle({ detailsCollapsed, setDetailsCollapsed }) {
            return () => {
                setDetailsCollapsed(!detailsCollapsed);
            };
        },
        onItemSelectionToggle({ selectItemProfiles, selectedItemProfileIds }) {
            return (id) => {
                if (includes(selectedItemProfileIds, id)) {
                    // unselect
                    selectItemProfiles(without(selectedItemProfileIds, id));
                } else {
                    // add to selection
                    selectItemProfiles(uniq([...selectedItemProfileIds, id]));
                }
            };
        },
        onAllItemSelectionToggle({ selectItemProfiles, selectedItemProfileIds, itemProfileIds }) {
            return () => {
                if (selectedItemProfileIds.length === 0) {
                    // select all
                    selectItemProfiles(itemProfileIds);
                } else {
                    // select none when all or some are already selected
                    selectItemProfiles([]);
                }
            };
        },
    })
)(CustodialPropertyCardSection);

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { lifecycleOptions } from 'markformythree';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { dateTimePickerTotalWidth } from '../../../../../legacy-redux/configs/formsConfig';

import testIds from '../../../../../core/testIds';
import { formName, ArrestMFTForm, formConfiguration } from '../../state/forms/arrestForm';
import { refreshBookingCardDependentArrestDateUtcField } from '../../state/forms/bookingForm';
import { refreshCourtCaseCardDependentArrestDateUtcField } from '../../state/forms/courtCaseForm';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTUserSelect } from '../../../../core/forms/components/selects/UserSelect';
import Fieldset from '../../../../core/forms/components/Fieldset';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Row from '../../../../core/components/Row';
import IndentedFields from '../../../../core/components/IndentedFields';
import { currentUserIdSelector } from '../../../../core/current-user/state/ui';

import {
    MFTAutofillDateButton,
    autofillDateTypeEnum,
} from '../../../../core/components/AutofillDateButton';

const strings = componentStrings.reports.core.ArrestCardForm;

const AssignSpecificButton = styled(Button)`
    margin-top: 19px;
`;

const ArrestCardFormFields: React.FC<{
    reportId: number;
    formIndex?: number;
    form: ArrestMFTForm;
}> = ({ reportId, formIndex, form }) => {
    const currentUserId = useSelector(currentUserIdSelector);
    const reportById = useSelector(reportByIdSelector);

    const assignToMe = React.useCallback(
        (path: string) => {
            return () => {
                form.set(path, currentUserId);
            };
        },
        [currentUserId, form]
    );

    const currentReport = reportById(reportId);

    const effectiveDateFilter = currentReport?.isLegacyReport
        ? null
        : currentReport?.createdDateUtc;

    return (
        <>
            <Row>
                <ArbiterMFTDatePicker
                    path="arrest.arrestDateUtc"
                    includeTime={true}
                    width={dateTimePickerTotalWidth}
                    onChange={(val?: string) => {
                        refreshBookingCardDependentArrestDateUtcField({
                            arrestDateUtc: val,
                            formIndex,
                        });
                        refreshCourtCaseCardDependentArrestDateUtcField({
                            arrestDateUtc: val,
                            formIndex,
                        });
                    }}
                />
                <MFTAutofillDateButton
                    onClick={(_e: unknown, { eventStartUtc }: { eventStartUtc: string }) => {
                        form.set('arrest.arrestDateUtc', eventStartUtc);
                        refreshBookingCardDependentArrestDateUtcField({
                            arrestDateUtc: eventStartUtc,
                            formIndex,
                        });
                        refreshCourtCaseCardDependentArrestDateUtcField({
                            arrestDateUtc: eventStartUtc,
                            formIndex,
                        });
                    }}
                    autofillDateType={autofillDateTypeEnum.START_DATE_TIME}
                    startDatePath="arrest.arrestDateUtc"
                />
            </Row>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="arrest.arrestingAgencyAttrId"
                    attributeType={AttributeTypeEnum.ARRESTING_AGENCY.name}
                    length="lg"
                />
            </Row>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="arrest.arrestTypeAttrId"
                    attributeType={AttributeTypeEnum.ARREST_TYPE.name}
                    length="md"
                />
            </Row>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="arrestAttributes.ARREST_STATUS.attributeId"
                    attributeType={AttributeTypeEnum.ARREST_STATUS.name}
                    length="lg"
                />
            </Row>
            <Row>
                <ArbiterMFTText path="arrest.ticketNumbers" length="lg" />
            </Row>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="arrestAttributes.ARREST_TACTICS.attributeIds"
                    length="lg"
                    attributeType={AttributeTypeEnum.ARREST_TACTICS.name}
                    multiple={true}
                />
            </Row>
            <IndentedFields>
                <Row>
                    <ArbiterMFTText
                        path="arrestAttributes.ARREST_TACTICS.description"
                        length="lg"
                    />
                </Row>
            </IndentedFields>
            <Row>
                <ArbiterMFTUserSelect
                    effectiveDate={effectiveDateFilter}
                    path="arrest.arrestingOfficerId"
                    length="lg"
                />
                <VisibilityObserver
                    formName={formName}
                    formIndex={formIndex}
                    path="arrest.arrestingOfficerId"
                    render={({ hidden }: { hidden: boolean }) =>
                        !hidden && (
                            <AssignSpecificButton
                                className={buttonTypes.SECONDARY}
                                onClick={assignToMe('arrest.arrestingOfficerId')}
                                testId={testIds.ASSIGN_TO_ME_BUTTON}
                            >
                                {strings.assignToMe}
                            </AssignSpecificButton>
                        )
                    }
                />
            </Row>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="arrestAttributes.ARRESTEE_WAS_ARMED_WITH.attributeIds"
                    length="lg"
                    attributeType={AttributeTypeEnum.ARRESTEE_WAS_ARMED_WITH.name}
                    multiple={true}
                />
            </Row>
            <IndentedFields>
                <Row>
                    <ArbiterMFTText
                        path="arrestAttributes.ARRESTEE_WAS_ARMED_WITH.description"
                        length="lg"
                    />
                </Row>
            </IndentedFields>
            <Row>
                <ArbiterMFTBooleanSelect path="arrest.isArresteeSuspectedUsing" length="lg" />
            </Row>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="arrestAttributes.ARREST_ARRESTEE_SUSPECTED_USING.attributeIds"
                    length="lg"
                    attributeType={AttributeTypeEnum.ARREST_ARRESTEE_SUSPECTED_USING.name}
                    multiple={true}
                />
            </Row>
            <IndentedFields>
                <Row>
                    <ArbiterMFTText
                        path="arrestAttributes.ARREST_ARRESTEE_SUSPECTED_USING.description"
                        length="lg"
                    />
                </Row>
            </IndentedFields>
            <ArbiterMFTAttributeCheckboxes
                path="arrestAttributes.ARREST_STATISTICS.attributeIds"
                attributeType={AttributeTypeEnum.ARREST_STATISTICS.name}
                columns={2}
                columnWidth="48%"
                gutterWidth="4%"
            />
            <IndentedFields>
                <Row>
                    <ArbiterMFTText path="arrestAttributes.ARREST_STATISTICS.description" />
                </Row>
            </IndentedFields>
            <FeatureFlagged flag="ARRESTS_SHOW_ADVISED_RIGHTS">
                <Fieldset highlightOnFocus={false} noMargins={true}>
                    <ArbiterMFTBooleanSelect path="arrest.advisedRights" length="lg" />
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTUserSelect
                                path="arrest.advisedRightsOfficerId"
                                effectiveDate={effectiveDateFilter}
                                length="lg"
                            />
                            <VisibilityObserver
                                formName={formName}
                                formIndex={formIndex}
                                path="arrest.advisedRightsOfficerId"
                                render={({ hidden }: { hidden: boolean }) =>
                                    !hidden && (
                                        <AssignSpecificButton
                                            className={buttonTypes.SECONDARY}
                                            onClick={assignToMe('arrest.advisedRightsOfficerId')}
                                            testId={testIds.ASSIGN_TO_ME_BUTTON}
                                        >
                                            {strings.assignToMe}
                                        </AssignSpecificButton>
                                    )
                                }
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="arrest.advisedRightsResponseAttrId"
                                attributeType={AttributeTypeEnum.ADVISED_RIGHTS_RESPONSE.name}
                                length="lg"
                            />
                        </Row>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTText
                                    path="arrest.advisedRightsResponseOther"
                                    length="lg"
                                />
                            </Row>
                        </IndentedFields>
                    </IndentedFields>
                </Fieldset>
            </FeatureFlagged>
            <Row>
                <ArbiterMFTBooleanSelect path="arrest.wasArresteeFingerprinted" length="lg" />
            </Row>
            <IndentedFields>
                <Row>
                    <ArbiterMFTText path="arrest.incidentTransactionNumber" length="lg" />
                </Row>
            </IndentedFields>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="arrest.warrantSignedByAttrId"
                    attributeType={AttributeTypeEnum.NIBRS_WARRANT_TYPE.name}
                    length="lg"
                />
            </Row>
        </>
    );
};

const ArrestCardForm: React.FC<{ reportId: number; index?: number }> = ({ reportId, index }) => {
    return (
        <ArbiterForm<typeof formConfiguration>
            name={formName}
            context={formName}
            index={index}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            render={(form) => <ArrestCardFormFields reportId={reportId} form={form} />}
            configuration={formConfiguration}
        />
    );
};

export default ArrestCardForm;

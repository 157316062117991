import { Middleware } from 'redux';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { userProfileSelector } from '../legacy-redux/selectors/userSelectors';
import actionTypes from '../legacy-redux/actions/types/authActionTypes';
import { RootState } from '../legacy-redux/reducers/rootReducer';
import { RmsDispatch, DependenciesArg } from './typings/redux';
import { LogRocket } from './logRocket';

export const bootstrapMiddleware: (
    dependencies: DependenciesArg
) => Middleware<Record<string, unknown>, RootState, RmsDispatch> = () => (store) => (next) => (
    action
) => {
    const result = next(action);

    // If bootstrap succeeded, initialize our logrocket user
    if (action.type === actionTypes.BOOTSTRAP_SUCCESS) {
        const state = store.getState();
        const userProfile = userProfileSelector(state);
        const userId = userProfile?.userId;

        LogRocket.identify(userId, {
            name: joinTruthyValues([userProfile.firstName, userProfile.lastName], ' '),
            email: userProfile.primaryEmail,
        });
    }

    return result;
};

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'User Profile Resource',
        methods: {
            getFullUserProfile(userId) {
                return req({
                    method: 'GET',
                    url: `users/profiles/${userId}/full`,
                });
            },
            getCadUserSettings() {
                return req({
                    method: 'GET',
                    url: 'user/profile/settings/cad',
                });
            },
        },
    });
}

/**
 * Resources for User Profile objects. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import _ from 'lodash';

import { createResource } from '../../../../lib/resources/Resource';

const LABEL_PRINTING_V2_BASE_URL = 'https://localhost:9101';

// A custom req implementation so we can do CORS requests without triggering a preflight options
// Using the normal JQuery version adds headers that trigger the preflight
function req({ method, url, data, contentType = 'text/plain', parseJson = true }) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                if (parseJson) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    resolve(xhr.response);
                }
            } else {
                reject(new Error(xhr.response));
            }
        };
        xhr.onerror = () => {
            reject(new Error(xhr.response));
        };
        xhr.open(method, url, true);
        if (data) {
            xhr.setRequestHeader('Content-type', contentType);
            xhr.send(JSON.stringify(data));
        } else {
            xhr.send(null);
        }
    });
}

/**
 * Resource for printing evidence labels from label printers. There are twos
 *   completely different sets of methods here that are used depending on the
 *   value of the label printing feature flag. The v2 tech doc is at
 *   https://docs.google.com/document/d/1PnA01KLR0gUjcgtmRg_W9_8RsottBlkc7YYRI-ektyI/
 *   The v3 tech doc is at
 *   https://docs.google.com/document/d/11C4QGmqgy_iqtmfAW0ZjrbOqsNEV0mY-i-ov77laIAg/
 */
export default createResource({
    name: 'Label Printing Resource',
    methods: {
        // These v2 methods are highly specialized and require the BrowserPrint software
        // to be installed locally
        // They are used to forge a connection jump over the SSL and CORS requirements for AJAX
        // requests to the printer
        // This write endpoint responds with 200 and no data
        writeV2(device, zplCode) {
            return req({
                method: 'POST',
                url: `${LABEL_PRINTING_V2_BASE_URL}/write`,
                data: {
                    device,
                    data: zplCode,
                },
            });
        },
        // Reads the printer status
        // 200 with text means success
        // 200 with empty response probably means failed to connect to printer, it may have turned off
        // 500 with empty response means Browser Print failure
        // returns something in the plain text form that looks like this:
        //
        //   PRINTER STATUS
        //    ERRORS:         1 00000000 00000004
        //    WARNINGS:       0 00000000 00000000
        //
        //
        // That first number after Errors is 1/0 depending on whether it was successful
        // There are more complicated error handling codes but those are not handled
        // here
        printerStatusV2(device) {
            return req({
                method: 'POST',
                url: `${LABEL_PRINTING_V2_BASE_URL}/read`,
                data: {
                    device,
                },
                parseJson: false,
            }).then((response) => {
                const regex = /ERRORS:\s+(\d)/g;
                const errorCode = regex.exec(response);
                if (_.get(errorCode, '[1]') === '0') {
                    return response;
                } else {
                    throw new Error(response);
                }
            });
        },
        // Have the dameon search for currently available printers
        getAvailablePrintersV2() {
            return req({
                method: 'GET',
                url: `${LABEL_PRINTING_V2_BASE_URL}/available`,
            });
        },
        // Get the printer set as default from the daemon
        getDefaultPrinterV2() {
            return req({
                method: 'GET',
                url: `${LABEL_PRINTING_V2_BASE_URL}/default?type=printer`,
            });
        },

        /**
         * Make a request directly to a hostname within the department that
         *   leads to our label printing v3 server (Okapi). It responds with a
         *   string on both success and failure, unless the printer driver isn't
         *   even running or something, in which case it's an unrecoverable
         *   error anyway.
         * @param  {string} networkAddress
         * @param  {string|undefined} printerAddress Used only when the
         *.  department has a central server with multiple networked printers.
         * @param  {string} zplCode
         * @return {Promise}
         */
        printV3(networkAddress, printerAddress, zplCode) {
            return req({
                method: 'POST',
                url: `${networkAddress}/print`,
                data: {
                    zpl: zplCode,
                    ...(!!printerAddress ? { ip: printerAddress } : {}),
                },
                contentType: 'application/json',
            });
        },
    },
});

import { EmailExportReleaseEntity } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'emailExportReleaseEntities';

const emailExportReleaseEntitiesModule = createGroupedModule<EmailExportReleaseEntity>({
    type: NEXUS_STATE_PROP,
    key: 'exportReleaseId',
});

// SELECTORS
export const emailExportReleaseEntitiesByReleaseIdSelector =
    emailExportReleaseEntitiesModule.selectors.entitiesByKeySelector;

// REDUCER
export default emailExportReleaseEntitiesModule.reducerConfig;

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { createSelector, createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { filter } from 'lodash';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import AttributeSelect from './AttributeSelect';

const { connectRRFInput } = reactReduxFormHelpers;

const stubStatusFilterSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (options) =>
        filter(
            options,
            (opt) =>
                parentAttributeIdByAttributeId(opt.value) !==
                globalAttributes.warrantStatusGlobal.stub
        )
);

const mapStateToProps = createStructuredSelector({
    stubStatusFilter: stubStatusFilterSelector,
});

const WarrantStatusAttributeSelect = connect(mapStateToProps)(
    function WarrantStatusAttributeSelect({ stubStatusFilter, ...otherProps }) {
        return (
            <AttributeSelect
                attributeType={AttributeTypeEnum.WARRANT_STATUS.name}
                filterOptions={stubStatusFilter}
                {...otherProps}
            />
        );
    }
);

export const RRFWarrantStatusAttributeSelect = connectRRFInput(WarrantStatusAttributeSelect);
export const ArbiterMFTWarrantStatusAttributeSelect = arbiterMFTInput(WarrantStatusAttributeSelect);

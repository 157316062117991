import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from 'recompose';
import classNames from 'classnames';

import testIds from '../../../core/testIds';

export const summaryListContextShape = {
    labelStyles: PropTypes.object,
    contentStyles: PropTypes.object,
    labelWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contentWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

type SummaryListContextProps = {
    labelWidth?: number | string;
    contentWidth?: number | string;
};

type SummaryListProps = {
    className?: string;
    testId?: keyof typeof testIds;
} & SummaryListContextProps;

const SummaryList: React.FC<SummaryListProps> = ({ className, children, testId }) => {
    const summaryListClassName = classNames('summary-list', className);

    return (
        <div className={summaryListClassName} data-test-id={testId}>
            {children}
        </div>
    );
};

export default withContext(
    summaryListContextShape,
    ({ labelWidth, contentWidth }: SummaryListContextProps) => ({
        labelWidth,
        contentWidth,
    })
)(SummaryList);

import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';

import impoundForm from '../../state/forms/impoundForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { RRFBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { RRFText } from '../../../../core/forms/components/Text';
import ReactReduxForm from '../../../../core/forms/components/ReactReduxForm';
import Row from '../../../../core/components/Row';
import FormRow from '../../../../core/forms/components/FormRow';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.core.ImpoundCard;

function ImpoundForm({ hasVehicle }) {
    return (
        <ReactReduxForm {...impoundForm}>
            {hasVehicle && (
                <CardSection
                    title={strings.vehicleSectionTitle}
                    testId={testIds.IMPOUND_CARD_VEHICLE_SECTION}
                >
                    <Row>
                        <RRFBooleanSelect path="vehicleLocked" length="sm" />
                    </Row>
                    <Row>
                        <RRFBooleanSelect path="keysInVehicle" length="sm" />
                    </Row>
                </CardSection>
            )}
            <CardSection
                title={strings.ncicSectionTitle}
                testId={testIds.IMPOUND_CARD_NCIC_INFORMATION_SECTION}
            >
                <FormRow>
                    <RRFText path="nicNumberOriginal" length="sm" />
                    <RRFText path="ocaNumberOriginal" length="sm" />
                    <RRFText path="originatingAgencyOriginal" length="md" />
                </FormRow>
                <FormRow>
                    <RRFText path="nicNumberCancellation" length="sm" />
                    <RRFText path="ocaNumberCancellation" length="sm" />
                    <RRFText path="originatingAgencyCancellation" length="md" />
                </FormRow>
            </CardSection>
        </ReactReduxForm>
    );
}

export default ImpoundForm;

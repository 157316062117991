import { Firearm } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'firearms';

const firearmModule = createNormalizedModule<Firearm>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const firearmsSelector = firearmModule.selectors.entitiesSelector;

// REDUCER
export default firearmModule.reducerConfig;

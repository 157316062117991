import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get, chain, includes, filter, map, keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { nameNameLinksWhereSelector } from '~/client-common/core/domain/name-name-links/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { offensesWhereSelector } from '~/client-common/core/domain/offenses/state/data';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import {
    personProfilesForReportIdSelector,
    organizationProfilesForReportIdSelector,
} from '~/client-common/core/domain/reports/state/ui/names';

import {
    currentReportIdSelector,
    offenseModifyingSupplementRenOffenseReportIdSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';

export const relationshipsDataSelector = createSelector(
    personProfilesForReportIdSelector,
    organizationProfilesForReportIdSelector,
    nameReportLinksWhereSelector,
    nameNameLinksWhereSelector,
    offensesWhereSelector,
    currentReportIdSelector,
    offenseModifyingSupplementRenOffenseReportIdSelector,
    (
        personProfilesForReportId,
        organizationProfilesForReportId,
        nameReportLinksWhere,
        nameNameLinksWhere,
        offensesWhere,
        currentReportId,
        offenseModifyingSupplementRenOffenseReportId
    ) => {
        const isOffenseModifyingSupplementReport = !isUndefinedOrNull(
            offenseModifyingSupplementRenOffenseReportId
        );
        const reportId = isOffenseModifyingSupplementReport
            ? offenseModifyingSupplementRenOffenseReportId
            : currentReportId;

        // NOTE: By using this selector, we are actually surfacing
        // more relationships on the FE than were shown before,
        // namely those from name-item links
        const personProfiles = personProfilesForReportId(reportId);
        const organizationProfiles = organizationProfilesForReportId({
            reportId,
            includeSocietyProfiles: false,
        });
        const nameReportLinks = filter(nameReportLinksWhere({ reportId }), (nrl) => {
            // TODO Only recently have we made a DEFENDANT nrl to capture a defendant's statement (NRL.statement)
            // It will soon be a true NRL with: https://mark43.atlassian.net/browse/RMS-11189
            // We need to filter this out here since we're still creating a mock defendant below
            return nrl.linkType !== LinkTypesEnum.DEFENDANT;
        });

        const nameIdMap = {
            ...keyBy(personProfiles, 'id'),
            ...keyBy(organizationProfiles, 'id'),
        };
        const nameNameLinks = nameNameLinksWhere(({ nameFromId, nameToId }) => {
            return get(nameIdMap, nameFromId) && get(nameIdMap, nameToId);
        });

        const relationshipsData = {
            nameReportLinks,
            organizationProfiles,
            personProfiles,
            nameNameLinks,
        };

        // If the report is an Offense Modifying Supplements report, then we only want
        // to show names for the Offense cards on the OMS report.
        return isOffenseModifyingSupplementReport
            ? filterRelationshipsForOMS({
                  ...relationshipsData,
                  offenseModifyingSupplementRenOffenseReportId: reportId,
                  offensesWhere,
              })
            : relationshipsData;
    }
);

export const filterRelationshipsForOMS = ({
    personProfiles,
    organizationProfiles,
    nameReportLinks,
    nameNameLinks,
    offenseModifyingSupplementRenOffenseReportId,
    offensesWhere,
}) => {
    const isOffenseModifyingSupplementReport = !isUndefinedOrNull(
        offenseModifyingSupplementRenOffenseReportId
    );
    if (isOffenseModifyingSupplementReport) {
        const offenses = offensesWhere({ reportId: offenseModifyingSupplementRenOffenseReportId });
        const offenseIds = map(offenses, 'id');

        const filteredNameReportLinks = filter(
            nameReportLinks,
            ({ reportId, contextType, contextId }) =>
                reportId === offenseModifyingSupplementRenOffenseReportId &&
                contextType === EntityTypeEnum.OFFENSE.name &&
                includes(offenseIds, contextId)
        );
        const filteredPersonIds = chain(filteredNameReportLinks)
            .filter({ entityType: EntityTypeEnum.PERSON_PROFILE.name })
            .map('nameId')
            .value();
        const filteredOrgIds = chain(filteredNameReportLinks)
            .filter({ entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name })
            .map('nameId')
            .value();
        const allFilteredNameIds = [...filteredPersonIds, ...filteredOrgIds];

        const filteredPersonProfiles = filter(personProfiles, ({ id }) =>
            includes(filteredPersonIds, id)
        );
        const filteredOrganizationProfiles = filter(organizationProfiles, ({ id }) =>
            includes(filteredOrgIds, id)
        );
        const filteredNameNameLinks = filter(
            nameNameLinks,
            ({ nameFromId, nameToId }) =>
                includes(allFilteredNameIds, nameFromId) && includes(allFilteredNameIds, nameToId)
        );

        return {
            personProfiles: filteredPersonProfiles,
            organizationProfiles: filteredOrganizationProfiles,
            nameReportLinks: filteredNameReportLinks,
            nameNameLinks: filteredNameNameLinks,
        };
    }
    return {
        personProfiles,
        organizationProfiles,
        nameReportLinks,
        nameNameLinks,
    };
};

import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Case } from '@mark43/rms-api';
import { map, isEmpty, includes, filter } from 'lodash';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { attachmentViews } from '../../../attachments/core/components/AttachmentViewToggleButton';
import AttachmentGridItem from '../../../attachments/core/components/AttachmentGridItem';
import AttachmentFolderGridItem from '../../../attachments/core/components/AttachmentFolderGridItem';
import AttachmentRow from '../../../attachments/core/components/AttachmentRow';
import { iconTypes } from '../../../core/components/Icon';
import { DraggingIdContext, SearchFilterContext, SelectedRowsContext } from '../../core/contexts';
import AttachmentFolderRow from '../../../attachments/core/components/AttachmentFolderRow';
import { tableWidths } from '../configuration';
import { fileIconTypeForFileCategory } from '../../../attachments/files/helpers';
import {
    createFolderDroppableId,
    createAttachmentDroppableId,
    getDragStyle,
} from '../../core/utils/dragAndDropHelpers';
import {
    DraggableCloneWrapper,
    DraggableClone,
    DroppablePlaceHolderWrapper,
    DraggableWrapper,
} from '../../core/components/drag-and-drop';
import { RowView, AttachmentRowView } from '../types';

const AttachmentRows = ({
    currentView,
    currentCase,
    openLightbox,
    paginatedRows,
    generateExportAttachmentUsageLog,
}: {
    currentView: string;
    currentCase: Case;
    openLightbox: (attachments: AttachmentRowView[], attachmentId: number, caseId?: number) => void;
    generateExportAttachmentUsageLog: (caseId: number, attachment: AttachmentRowView) => void;
    paginatedRows: RowView[];
}) => {
    const { draggingId } = useContext(DraggingIdContext);
    const { selectedRows, handleRowSelect, isRowSelected } = useContext(SelectedRowsContext);
    const { filter: searchFilter } = useContext(SearchFilterContext);
    const applicationSettings = useSelector(applicationSettingsSelector);

    // When user select multiple rows and drag these rows into a folder,
    // the rows that were selected, but not exactly the one being draged are
    // consider as "ghost". The ghost row will be styled a bit differently
    // e.g greying out background color
    const isGhosting = useCallback(
        (id: number): boolean => {
            if (!draggingId) {
                return false;
            }
            const selectedRowIds = map(selectedRows, 'rowId');
            return draggingId !== id && includes(selectedRowIds, id);
        },
        [draggingId, selectedRows]
    );
    const sortedAttachmentsForLightbox = filter(paginatedRows, {
        isFolder: false,
    }) as AttachmentRowView[];

    return currentView === attachmentViews.LIST ? (
        <div>
            {!isEmpty(paginatedRows) &&
                map(paginatedRows, (element, index) => {
                    const { isFolder } = element;
                    const file = !isFolder ? getAttachmentFile(element) : undefined;
                    const iconType = isFolder
                        ? iconTypes.CASE
                        : file?.fileCategory
                        ? fileIconTypeForFileCategory(file?.fileCategory)
                        : iconTypes.DOCUMENT;

                    let isSelected = false;
                    if (isRowSelected) {
                        isSelected = isFolder
                            ? isRowSelected(element.id)
                            : isRowSelected(element.attachmentId);
                    }

                    return isFolder ? (
                        <Droppable
                            droppableId={createFolderDroppableId(element.id, element.displayName)}
                            renderClone={(provided) => (
                                <DraggableCloneWrapper
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >
                                    <DraggableClone
                                        displayName={element.name}
                                        iconType={iconType}
                                    />
                                </DraggableCloneWrapper>
                            )}
                            key={`droppable-folder-${element.id}`}
                        >
                            {(provided, dropSnapshot) => (
                                <DraggableWrapper
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={dropSnapshot.isDraggingOver}
                                >
                                    <Draggable
                                        draggableId={`${element.id}`}
                                        index={index}
                                        key={`draggable-folder${element.id}`}
                                        isDragDisabled={!isSelected}
                                    >
                                        {(dragProvided, snapshot) => (
                                            <div
                                                ref={dragProvided.innerRef}
                                                {...dragProvided.draggableProps}
                                                {...dragProvided.dragHandleProps}
                                                style={getDragStyle(
                                                    dragProvided.draggableProps,
                                                    snapshot
                                                )}
                                            >
                                                <AttachmentFolderRow
                                                    key={element.id}
                                                    folder={element}
                                                    handleRowSelect={handleRowSelect}
                                                    isRowSelected={isSelected}
                                                    isGhosting={isGhosting(element.id)}
                                                    isDraggingOver={dropSnapshot.isDraggingOver}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                    <DroppablePlaceHolderWrapper
                                        isDragging={draggingId === element.id}
                                    >
                                        {provided.placeholder}
                                    </DroppablePlaceHolderWrapper>
                                </DraggableWrapper>
                            )}
                        </Droppable>
                    ) : (
                        <Droppable
                            droppableId={createAttachmentDroppableId(element.attachmentId)}
                            renderClone={(provided) => (
                                <DraggableCloneWrapper
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >
                                    <DraggableClone
                                        displayName={element.displayName}
                                        iconType={iconType}
                                    />
                                </DraggableCloneWrapper>
                            )}
                            isDropDisabled={true}
                            key={`droppable-attachment-${element.attachmentId}`}
                        >
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    <Draggable
                                        draggableId={`${element.attachmentId}`}
                                        index={index}
                                        key={`draggable-attachment-${element.attachmentId}`}
                                        isDragDisabled={!isSelected}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getDragStyle(
                                                    provided.draggableProps,
                                                    snapshot
                                                )}
                                            >
                                                <AttachmentRow
                                                    className={''}
                                                    tableWidths={tableWidths}
                                                    onClickForLightBox={openLightbox(
                                                        sortedAttachmentsForLightbox,
                                                        element.id,
                                                        currentCase.id
                                                    )}
                                                    onDownload={() =>
                                                        generateExportAttachmentUsageLog(
                                                            currentCase.id,
                                                            element
                                                        )
                                                    }
                                                    key={element.id}
                                                    attachment={element}
                                                    handleRowSelect={handleRowSelect}
                                                    isRowSelected={isSelected}
                                                    isGhosting={isGhosting(element.attachmentId)}
                                                    hideDownloadButton={
                                                        !!applicationSettings.RMS_CASE_FOLDERING_ENABLED
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                    <DroppablePlaceHolderWrapper
                                        isDragging={draggingId === element.attachmentId}
                                    >
                                        {provided.placeholder}
                                    </DroppablePlaceHolderWrapper>
                                </div>
                            )}
                        </Droppable>
                    );
                })}
        </div>
    ) : (
        <>
            {map(paginatedRows, (element) => {
                const { isFolder } = element;
                let isGridSelected = false;
                if (isRowSelected) {
                    isGridSelected = isFolder
                        ? isRowSelected(element.id)
                        : isRowSelected(element.attachmentId);
                }

                return !isFolder ? (
                    <AttachmentGridItem
                        onClickForLightBox={openLightbox(sortedAttachmentsForLightbox, element.id)}
                        onDownload={() => generateExportAttachmentUsageLog(currentCase.id, element)}
                        key={element.id}
                        attachment={element}
                        isGridSelected={isGridSelected}
                        handleGridSelect={handleRowSelect}
                        showCheckbox={
                            !searchFilter && !!applicationSettings.RMS_CASE_FOLDERING_ENABLED
                        }
                    />
                ) : (
                    <AttachmentFolderGridItem
                        key={element.id}
                        folder={element}
                        isGridSelected={isGridSelected}
                        handleGridSelect={handleRowSelect}
                    />
                );
            })}
        </>
    );
};

export default AttachmentRows;

import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import * as fields from '~/client-common/core/enums/universal/fields';

export function usePhotoLineupFieldName() {
    const savedFieldName = useFields(fields.DISPLAY_ONLY_PHOTO_LINEUP).DISPLAY_ONLY_PHOTO_LINEUP;
    const defaultLineupFieldName =
        componentStrings.cases.casePhotoLineups.CasePhotoLineups.defaultLineupFieldName;
    const photoLineupFieldName =
        savedFieldName.trim().length === 0 ? defaultLineupFieldName : savedFieldName;

    return photoLineupFieldName;
}

import { filter, mapValues } from 'lodash';
import { createSelector } from 'reselect';
import { reportNotificationsSelector } from '../data';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';

const reportNotificationViewModelsSelector = createSelector(
    formatAttributeByIdSelector,
    reportNotificationsSelector,
    (formatAttributeById, reportNotifications) => {
        const viewModel = buildViewModel({
            mappers: [allSingleAttributeValuesMapper],
            helpers: {
                formatAttributeById,
            },
        });

        return mapValues(reportNotifications, viewModel);
    }
);

export const reportNotificationViewModelsWhereSelector = createSelector(
    reportNotificationViewModelsSelector,
    (reportNotificationViewModels) => (predicate) => filter(reportNotificationViewModels, predicate)
);

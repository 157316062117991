import componentStrings from '~/client-common/core/strings/componentStrings';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import type { SelectOption } from '../../../core/forms/helpers/selectHelpers';

const strings = componentStrings.admin.customreports.CustomReportsAdminForm.informationCardOptions;

/**
 * These are the "Information" cards which are usually displayed at the top of a report, to capture its most basic information.
 * Each report type should have exactly 0 or 1 of these cards.
 */
export const informationCardOptions = [
    {
        value: reportCardEnum.EVENT_INFO.id,
        display: strings.eventInformation,
    },
    {
        value: reportCardEnum.SUPPLEMENT_INFO.id,
        display: strings.supplementInformation,
    },
    {
        value: reportCardEnum.LEGACY_INFO.id,
        display: strings.migrationInformation,
    },
    {
        value: reportCardEnum.CRASH_EVENT_INFO.id,
        display: strings.crashEventInformation,
    },
] as const;

export const informationCardArcSelectOptions: SelectOption[] = informationCardOptions.map(
    (option) => ({
        value: option.value,
        label: option.display,
    })
);

export function isInformationCard(cardId: number): boolean {
    return informationCardOptions.some((option) => option.value === cardId);
}

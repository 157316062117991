import React from 'react';
import styled from 'styled-components';
import _, { get, filter, map, isFunction, noop } from 'lodash';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import {
    Table,
    TableColumn as Column,
    TableRow as Row,
    TableCell as Cell,
} from 'components-mark43';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import TableHeaderButton from '../../../legacy-redux/components/core/tables/TableHeaderButton';
import TableResultsSummary from '../../../legacy-redux/components/core/tables/TableResultsSummary';
import Pagination from '../../../legacy-redux/components/core/Pagination';
import DebouncedText from '../forms/components/DebouncedText';
import Text from '../forms/components/Text';
import NoDataBlock from './NoDataBlock';

const DEFAULT_DEBOUNCE_FILTER_MIN = 30;
const DEFAULT_MAX_PER_PAGE = 30;

const TableHeaderRow = styled(Row)`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

export const TableBodyRow = styled(Row)`
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    ${(props) =>
        props.highlightOnHover
            ? `
                cursor: pointer;
                &:hover {
                    background-color: ${props.theme.colors.lightBlue};
                }
            `
            : ''};
`;

const TableHeaderColumn = styled(Column)`
    width: ${(props) => props.width};
    padding-bottom: 5px;
    text-align: ${(props) => props.textAlign || 'left'};

    &:last-child {
        padding-right: 10px;
    }
`;

export const TableBodyColumn = styled(Column)`
    width: ${(props) => props.width};
    ${(props) => (props.sortable ? 'padding: 5px 10px;' : '')};
    font-size: var(--arc-fontSizes-sm);
    word-break: break-word;
`;

const FilterText = styled(Text)`
    margin: 0;
`;

const Header = styled.div`
    height: 42px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Footer = styled.div`
    text-align: right;
    clear: both;
    padding: 16px 0;
`;

const NoResults = styled(NoDataBlock)`
    margin: 20px 0;
`;

const Centered = styled.div`
    text-align: center;
`;

const sortTypes = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
};

function Filter({
    useDebounced,
    filterText,
    width = 280,
    handleFilterChange,
    filterPlaceholderText = 'Type to filter...',
}) {
    return useDebounced ? (
        <DebouncedText
            width={width}
            value={filterText}
            onChange={handleFilterChange}
            placeholder={filterPlaceholderText}
            TextComponent={FilterText}
        />
    ) : (
        <FilterText
            width={width}
            value={filterText}
            onChange={handleFilterChange}
            placeholder={filterPlaceholderText}
        />
    );
}

function getPagination({ items, page, pageSize }) {
    const zeroBasedPage = page - 1;
    const sliceOffset = zeroBasedPage * pageSize;
    const results = items.slice(sliceOffset, sliceOffset + pageSize);

    const paginationItemsTo = sliceOffset + results.length;

    return {
        paginationItemsFrom: sliceOffset,
        paginationItemsTo,
        numTotalResults: items.length,
        results,
    };
}

function sort(items, sortKey, sortType, sortFunc) {
    return sortFunc && isFunction(sortFunc)
        ? items.sort((a, b) => sortFunc(get(a, sortKey), get(b, sortKey), sortType))
        : sortByNaturalOrder(items, [sortKey], [sortType === sortTypes.ASCENDING ? 'asc' : 'desc']);
}

class TableBase extends React.Component {
    static propTypes = {
        items: PropTypes.array.isRequired, // data to populate the table
        columns: PropTypes.arrayOf(
            // column definitions
            PropTypes.shape({
                // key should match a property name in the items to render
                key: PropTypes.string.isRequired,
                width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fieldName: PropTypes.string,
                title: PropTypes.string,
                // custom sort func, the default uses sortByNaturalOrder
                // sortFunc(a, b, sortType)
                sortFunc: PropTypes.func,
                // column.renderHeaderCellContent and column.renderCellContent - useful for transforming the cell content
                // renderHeaderCellContent({ column, formattedFieldName }) - content to be rendered inside <td></td>
                renderHeaderCellContent: PropTypes.func,
                // renderCellContent({ item, index }) - content to be rendered inside <td></td>
                renderCellContent: PropTypes.func,
                // when true, column header will allow sorting. defaults to true
                sortable: PropTypes.bool,
                // when true, filter component will filter against this column field. defaults to true
                filterable: PropTypes.bool,
            })
        ).isRequired,
        // renderHeaderRow and renderBodyRow useful for specifying dynamically hidden rows based on feature flags, etc,
        // this will overwrite column.renderHeaderCellContent / column.renderCellContent
        // renderHeaderRow({ columns })
        showHeaderRow: PropTypes.bool,
        renderHeaderRow: PropTypes.func,
        // renderBodyRow({ item, index, handleRowClick })
        renderBodyRow: PropTypes.func,
        // renderLayout - useful for fitting other elements into the view, like "Download Button"
        // renderLayout({
        //     filterComponent,
        //     tableComponent,
        //     paginationComponent,
        //     paginationSummaryComponent,
        // })
        renderLayout: PropTypes.func,
        // handleRowClick({ item, index })
        handleRowClick: PropTypes.func,
        includeFilter: PropTypes.bool, // defaults to true
        includePagination: PropTypes.bool, // defaults to true
        filterOptions: PropTypes.shape({
            // by default, filter will only debounce if there are more items than `numItemsUntilDebounce || DEFAULT_DEBOUNCE_FILTER_MIN`
            // if you want filter to always debounce, you can set the numItemsUntilDebounce = 0
            numItemsUntilDebounce: PropTypes.number,
            filterPlaceholderText: PropTypes.string,
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // width of filter component
        }),
        paginationOptions: PropTypes.shape({
            pageSize: PropTypes.number,
        }),
        noDataText: PropTypes.string,
        defaultSortConfig: PropTypes.shape({
            sortKey: PropTypes.string.isRequired, // should match a key in columns
            sortType: PropTypes.oneOf([sortTypes.ASCENDING, sortTypes.DESCENDING]).isRequired,
        }),
        testId: PropTypes.string,
    };

    constructor(props) {
        super(props);

        let sortType;
        let sortKey;
        let sortedItems = this.props.items;
        if (this.props.defaultSortConfig) {
            // set initial sort
            sortType = this.props.defaultSortConfig.sortType;
            sortKey = this.props.defaultSortConfig.sortKey;
            const column = filter(this.props.columns, { key: sortKey });
            sortedItems = sort(this.props.items, sortKey, sortType, column.sortFunc);
        }
        this.state = {
            filterText: '',
            currentPage: 1,
            sortType,
            sortKey,
            sortedItems,
        };
    }

    setSort = (sortKey, sortFunc) => {
        this.setState((prevState) => {
            const newSortType =
                prevState.sortKey === sortKey && prevState.sortType === sortTypes.ASCENDING
                    ? sortTypes.DESCENDING
                    : sortTypes.ASCENDING;
            return {
                sortedItems: sort(prevState.sortedItems, sortKey, newSortType, sortFunc),
                sortKey,
                sortType: newSortType,
            };
        });
    };

    setSortedItems = (sortedItems) => {
        this.setState({ sortedItems });
    };

    handleFilterChange = (filterText = '') => {
        this.setState({
            filterText,
            currentPage: 1,
        });
    };

    handlePaginationClick = (currentPage, nextPage) => {
        this.setState({ currentPage: nextPage });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.items !== nextProps.items) {
            const column = filter(this.props.columns, { key: this.props.sortKey });
            this.setState({
                sortedItems: sort(
                    nextProps.items,
                    this.props.sortKey,
                    this.props.sortType,
                    column.sortFunc
                ),
            });
        }
    }

    render() {
        const {
            items,
            columns,
            renderBodyRow,
            renderHeaderRow,
            renderLayout,
            handleRowClick,
            includeFilter = true,
            includePagination = true,
            filterOptions = {},
            paginationOptions = {},
            noDataText = 'No Results',
            formatFieldByName,
            className,
            showHeaderRow = true,
            testId,
        } = this.props;
        const { numItemsUntilDebounce, filterPlaceholderText, width: filterWidth } = filterOptions;
        const { pageSize = DEFAULT_MAX_PER_PAGE } = paginationOptions;

        // by default, all columns are filterable and sortable
        const tableColumns = map(columns, (column) => ({
            sortable: true,
            filterable: true,
            ...column,
        }));

        const filterableColumns = filter(tableColumns, 'filterable');

        // only show pagination if there are more items than page size
        const showPagination = items.length > pageSize && includePagination;
        // don't show filter component if no columns can be filtered
        const showFilter = includeFilter && filterableColumns.length > 0;

        const headers = map(tableColumns, (column) => {
            const {
                key,
                title,
                fieldName,
                sortable,
                width,
                renderHeaderCellContent,
                sortFunc,
            } = column;

            const defaultHeaderCellContent = sortable ? (
                <TableHeaderButton
                    activeSortKey={this.state.sortKey}
                    activeSortType={this.state.sortType}
                    sortKey={key}
                    onClick={(sortKey) => this.setSort(sortKey, sortFunc)}
                >
                    {formatFieldByName(fieldName) || title}
                </TableHeaderButton>
            ) : (
                <div>{formatFieldByName(fieldName) || title}</div>
            );
            return (
                <TableHeaderColumn width={width} key={key}>
                    <Cell>
                        {renderHeaderCellContent && isFunction(renderHeaderCellContent)
                            ? renderHeaderCellContent({
                                  column,
                                  formattedFieldName: fieldName ? formatFieldByName(fieldName) : '',
                              })
                            : defaultHeaderCellContent}
                    </Cell>
                </TableHeaderColumn>
            );
        });

        let finalItems = this.state.sortedItems;
        // no need to do the filtering logic if the filter component isn't shown
        if (showFilter) {
            const lowercaseFilter = this.state.filterText.toLowerCase();

            finalItems = filter(finalItems, (item) => {
                const filterableContent = joinTruthyValues(
                    _(tableColumns)
                        .filter('filterable')
                        .map((column) => {
                            return item[column.key];
                        })
                        .value(),
                    ''
                );

                return filterableContent.toLowerCase().indexOf(lowercaseFilter) !== -1;
            });
        }

        const {
            paginationItemsFrom,
            paginationItemsTo,
            results, // this gets the sliced results
        } = getPagination({
            items: finalItems,
            page: this.state.currentPage,
            pageSize,
        });

        const getFilterableContent = (item) =>
            joinTruthyValues(
                _(tableColumns)
                    .filter('filterable')
                    .map((column) => {
                        return item[column.key];
                    })
                    .value(),
                ''
            );

        const defaultRenderRow = ({ item, index }) => {
            return (
                <TableBodyRow
                    key={item.id || index}
                    onClick={
                        !!handleRowClick
                            ? () => handleRowClick({ item, index, router: this.props.router })
                            : noop
                    }
                    highlightOnHover={!!handleRowClick}
                >
                    {map(tableColumns, ({ renderCellContent, sortable, key, width }) => {
                        return (
                            <TableBodyColumn key={key} sortable={sortable} width={width}>
                                <Cell>
                                    {renderCellContent && isFunction(renderCellContent)
                                        ? renderCellContent({ item, index })
                                        : item[key]}
                                </Cell>
                            </TableBodyColumn>
                        );
                    })}
                </TableBodyRow>
            );
        };

        const filterComponent = (
            <Filter
                useDebounced={
                    numItemsUntilDebounce
                        ? items.length > numItemsUntilDebounce
                        : items.length > DEFAULT_DEBOUNCE_FILTER_MIN
                }
                filterPlaceholderText={filterPlaceholderText}
                width={filterWidth}
                filterText={this.state.filterText}
                handleFilterChange={this.handleFilterChange}
                getFilterableContent={getFilterableContent}
            />
        );
        const tableComponent = (
            <div>
                <Table
                    data={results}
                    renderHeaderRow={() => {
                        if (!showHeaderRow) {
                            return;
                        }

                        if (renderHeaderRow && isFunction(renderHeaderRow)) {
                            return renderHeaderRow({
                                columns: tableColumns,
                            });
                        }

                        return <TableHeaderRow>{headers}</TableHeaderRow>;
                    }}
                    renderBodyRow={({ data, index }) => {
                        return renderBodyRow && isFunction(renderBodyRow)
                            ? renderBodyRow({ item: data, index, handleRowClick })
                            : defaultRenderRow({ item: data, index });
                    }}
                />
                {!results.length > 0 && (
                    <Centered>
                        <NoResults>{noDataText}</NoResults>
                    </Centered>
                )}
            </div>
        );

        const paginationComponent = (
            <Pagination
                currentPage={this.state.currentPage}
                itemsPerPage={pageSize}
                itemCount={finalItems.length}
                onClick={this.handlePaginationClick}
                maxEdgeItems={1}
            />
        );

        const paginationSummaryComponent = (
            <TableResultsSummary
                from={paginationItemsFrom}
                to={paginationItemsTo}
                totalResults={items.length}
                caption=""
            />
        );

        const defaultLayout = (
            <div>
                {(showFilter || showPagination) && (
                    <Header>
                        <div>{showFilter && filterComponent}</div>
                        {showPagination && paginationSummaryComponent}
                    </Header>
                )}
                {tableComponent}
                {showPagination && <Footer>{paginationComponent}</Footer>}
            </div>
        );
        return (
            <div className={className} data-test-id={testId}>
                {renderLayout && isFunction(renderLayout)
                    ? renderLayout({
                          filterComponent,
                          tableComponent,
                          paginationComponent,
                          paginationSummaryComponent,
                      })
                    : defaultLayout}
            </div>
        );
    }
}

export default compose(
    withRouter,
    connect(createStructuredSelector({ formatFieldByName: formatFieldByNameSelector }))
)(TableBase);

import { withRouter, WithRouterProps } from 'react-router';
import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Text, Flex, mediaQueries, IconButton, isOverflowHorizontal } from 'arc';
import { truncateText } from '../styles/mixins';
import testIds from '../../../core/testIds';
import zIndexes from '../styles/zIndexes';
import { ConditionalTooltip } from './tooltip';
import { NavMenuToggle } from './Navigation/NavMenuToggle';

const SubheaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--arc-colors-surface-foreground);
    box-shadow: var(--arc-shadows-md);
    align-self: stretch;
    z-index: ${zIndexes.stickyHeader};
`;

const SubheaderContainer = styled.div<{
    $hasFlushContent: boolean;
    $fullscreen?: boolean;
}>`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--arc-space-2) var(--arc-space-3);
    align-items: stretch;
    justify-content: space-between;
    gap: var(--arc-space-1);

    @media (min-width: ${mediaQueries.md}) {
        min-height: 3.5rem;
        padding: var(--arc-space-3) var(--arc-space-8);
        gap: 0;
    }

    ${(props) =>
        props.$fullscreen !== true &&
        `width: calc(${props.theme.widths.container}px + var(--arc-space-8) * 2);
        margin: auto;`}

    ${(props) =>
        props.$hasFlushContent &&
        `
        padding-bottom: 0;

        @media (min-width: ${mediaQueries.md}) {
            padding-bottom: 0;
        }
    `}
`;

const SubheaderHeader = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: end;
    gap: var(--arc-space-1);
`;

const SubheaderTitleWrapper = styled.div`
    display: flex;
    gap: var(--arc-space-2);
    min-width: 7.5rem;
    flex: 1;
    align-items: center;
`;

const StyledText = styled(Text)`
    /* The ARC noOfLines uses line-clamp which doesn't work
    well with a long unbroken string. */
    ${truncateText}
`;

/** Takes a string, styles it for the subheader and wraps it in a tooltip automatically if it gets truncated.
 * Used by default in the Subheader, useful when composing a more custom `title` to pass to the Subheader.
 */
export const SubheaderTitle = ({ title, testId }: { title: string; testId?: string }) => {
    const titleRef = React.useRef(null);
    const [showTooltip, setShowTooltip] = React.useState(false);

    return (
        <ConditionalTooltip condition={showTooltip} content={title}>
            <StyledText
                ref={titleRef}
                lineHeight="2rem"
                variant="headingMd"
                data-test-id={testId ?? testIds.SUBHEADER_TITLE}
                onMouseEnter={() =>
                    titleRef?.current && setShowTooltip(isOverflowHorizontal(titleRef.current))
                }
                onMouseLeave={() => setShowTooltip(false)}
            >
                {title}
            </StyledText>
        </ConditionalTooltip>
    );
};
interface SubheaderProps {
    // Route path for the back button. If this or `onBackButtonClick` is provided the back button will render.
    backButtonTo?: string;
    // If a string, will render the SubheaderTitle with automatic overflow and tooltip handling. Also supports ReactNode for custom titles.
    title?: React.ReactNode;
    bannerComponent?: React.ReactNode;
    // onClick event for the back button. If this or `backButtonTo` is provided the back button will render.
    onBackButtonClick?: () => void;
    className?: string;
    // Content that renders below the title and children
    content?: React.ReactNode;
    // If true, bottom padding is removed so the content is flush to the bottom of the container, such as tabs.
    hasFlushContent?: boolean;
    testId?: string;
    /** When true this removes old style width contraints and lets the subheader take up the full width.
     * Most of our pages (except for Admin) are NOT ready for this usage and should stay constrained so the header aligns with the old style content,
     * in the ideal future more of our pages should use full width styles.
     */
    fullscreen?: boolean;
}
const Subheader: React.FC<SubheaderProps & WithRouterProps> = ({
    backButtonTo,
    title,
    hasFlushContent,
    fullscreen,
    content,
    children,
    bannerComponent,
    onBackButtonClick,
    router,
    className,
    testId,
}) => {
    return (
        <SubheaderWrapper>
            <SubheaderContainer
                $hasFlushContent={!!hasFlushContent}
                $fullscreen={fullscreen}
                className={classNames('mark43-page-header', { className })}
            >
                <SubheaderHeader>
                    <SubheaderTitleWrapper>
                        {backButtonTo || onBackButtonClick ? (
                            <IconButton
                                icon="ArrowLeft"
                                aria-label="Back"
                                size="md"
                                variant="ghost"
                                data-test-id={testIds.SUBHEADER_BACK_BUTTON}
                                onClick={() => {
                                    if (onBackButtonClick) {
                                        onBackButtonClick();
                                    }
                                    if (backButtonTo) {
                                        router.push(backButtonTo);
                                    }
                                }}
                            />
                        ) : (
                            // Won't show if there is a back button.
                            <NavMenuToggle />
                        )}

                        {title && typeof title === 'string' ? (
                            <SubheaderTitle title={title} testId={testId} />
                        ) : (
                            title
                        )}
                    </SubheaderTitleWrapper>
                    <Flex maxWidth="fit-content">{children}</Flex>
                </SubheaderHeader>
                {content}
            </SubheaderContainer>
            {bannerComponent}
        </SubheaderWrapper>
    );
};

/**
 * Subheader that appears below the page header. It includes an optional title, children, content and footer.
 * Children are displayed to the far right of the title, primarily used for buttons.
 */
export default withRouter(Subheader);

import {
    CadChannelUpsertRequestDto,
    CadChannelListRequestDto,
    CadChannel,
    CadChannelHistory,
} from '@mark43/cad-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const CAD_BASE_URL = '/cad/api';

export const cadChannelsResource = createResource({
    name: 'CAD Channels Admin Resource',
    methods: {
        listCadChannels(cadChannelListRequest: CadChannelListRequestDto) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'POST',
                url: 'channels/list',
                data: cadChannelListRequest,
            }) as Promise<CadChannel[]>;
        },
        upsertCadChannel(cadChannelUpsertRequest: CadChannelUpsertRequestDto) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'POST',
                url: 'channels',
                data: cadChannelUpsertRequest,
            }) as Promise<CadChannel>;
        },
        getCadChannelHistory(id: number) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'GET',
                url: `channels/${id}/history`,
            }) as Promise<CadChannelHistory[]>;
        },
    },
});

import React from 'react';
import { cssVar } from 'arc';
import { AttributeTypeEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import { DISPLAY_ONLY_CAUTION_LABEL } from '~/client-common/core/enums/universal/fields';
import { useFieldName } from '~/client-common/core/fields/hooks/useFields';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { ArbiterMFTTextArea } from '../../../../../core/forms/components/TextArea';
import { MFTNItems } from '../../../../../core/forms/components/NItems';
import Divider from '../../../../../core/components/Divider';
import Row from '../../../../../core/components/Row';
import IndentedFields from '../../../../../core/components/IndentedFields';
import { useAbilitySelector } from '../../../../../core/current-user/hooks/useAbilitySelector';
import { renderRemoveButton } from '../../../../../core/names/components/nameFormNItemsRenderers';
import testIds from '../../../../../../core/testIds';
import { HelpTextProps } from '../../../../../../legacy-redux/components/core/HelpText';
import { cautionFormConfiguration } from '../../../state/forms/hydratedItemFormConfiguration';

const RemoveNItemButtonWrapper = styled.div`
    position: absolute;
    top: -20px;
    right: 0;
`;

const Box = styled.div`
    margin-top: ${cssVar('arc.space.6')};
`;

export const VehicleCautionsFieldset = ({
    helpTextCollisionBoundary,
}: {
    helpTextCollisionBoundary: HelpTextProps['collisionBoundary'];
}) => {
    const { singular: addCautionLabel } = useFieldName(DISPLAY_ONLY_CAUTION_LABEL);
    const hasDeleteCautionsAbility = useAbilitySelector(abilitiesEnum.CORE.DELETE_CAUTIONS);

    return (
        <>
            <MFTNItems<typeof cautionFormConfiguration>
                path="cautions"
                addText={addCautionLabel}
                addItemOnEmpty={false}
                renderRemoveButton={(props) => {
                    const { item } = props;
                    if ((!hasDeleteCautionsAbility && item.id) || item.isProtected) {
                        return null;
                    }

                    return (
                        <RemoveNItemButtonWrapper>
                            {renderRemoveButton(props)}
                        </RemoveNItemButtonWrapper>
                    );
                }}
                render={({ index, item: { isProtected } }) => (
                    <Box>
                        <ArbiterMFTAttributeSelect
                            path="cautionAttrId"
                            attributeType={AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name}
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            disabled={isProtected}
                            testId={`${testIds.VEHICLE_CAUTIONS_N_ITEM_CAUTION_TYPE}_${index}`}
                        />
                        <IndentedFields>
                            <ArbiterMFTText
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="cautionOther"
                                length="md"
                                disabled={isProtected}
                            />
                        </IndentedFields>

                        <Row>
                            <ArbiterMFTDatePicker path="dateEffectiveFrom" disabled={isProtected} />
                            <ArbiterMFTDatePicker path="dateEffectiveTo" disabled={isProtected} />
                        </Row>

                        <ArbiterMFTTextArea
                            path="description"
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            disabled={isProtected}
                        />
                        <ArbiterMFTTextArea
                            path="provenance"
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            disabled={isProtected}
                        />
                    </Box>
                )}
            />
            <Divider />
        </>
    );
};

import { identity } from 'lodash';
import recursivelyConvertIdsToDisplayValueObjects from './recursivelyConvertIdsToDisplayValueObjects';

const REPORT_DEFINITION_KEY_REGEX = /reportDefinitionIds?$/;
const ID_KEY_REGEX = /Id(s)?$/;

/**
 * Recursively traverses an object, looking for keys that match `reportDefinitionId(s)`,
 * transforms their values into display value objects with a shape
 * of `[<string>]` or `<string>` and stores the result on a new key,
 * replacing the `Id(s)` suffix with `Detail`.
 *
 * For params see return value of `recursivelyConvertIdsToDisplayValueObjects`
 */
export default recursivelyConvertIdsToDisplayValueObjects({
    sourceKeyRegex: REPORT_DEFINITION_KEY_REGEX,
    targetKeyRegex: ID_KEY_REGEX,
    createTargetValue: identity,
    getTargetKey: (key, targetKeyRegex) => key.replace(targetKeyRegex, 'Name$1'),
    preserveOldKeys: true,
});

import { createSelector } from 'reselect';

import { sortByNaturalOrder } from '../../../../../helpers/arrayHelpers';

import { warrantDexReturnsWhereSelector } from '../data';

export const dateSortedWarrantDexReturnsWhereSelector = createSelector(
    warrantDexReturnsWhereSelector,
    (warrantDexReturnsWhere) => (predicate) =>
        sortByNaturalOrder(warrantDexReturnsWhere(predicate), ['returnDateUtc'], ['desc'])
);

import React from 'react';

import { BookingCellProps } from '../../../../../modules/entity-profiles/persons/components/EntityProfilePersonJmsBookings';
import Link from '../../../../../modules/core/components/links/Link';

export default function EventNumberCell({ rens }: BookingCellProps) {
    return (
        <div>
            {rens?.map((data) => {
                const arrest =
                    data.rmsArrestId && data.arrestNumber ? (
                        <Link to={`/reports/${data.rmsArrestId}`}>{data.arrestNumber}</Link>
                    ) : (
                        data.arrestNumber
                    );
                return (
                    <div key={data.ren}>
                        <div>{data.ren}</div>
                        {arrest && 'ID '}
                        {arrest}
                    </div>
                );
            })}
        </div>
    );
}

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Associated Records Resource',
        methods: {
            setAssociatedRecordsForReport(reportId, data) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/associated_records/`,
                    data,
                });
            },
            createAssociationForReport(reportId, reportReportLink) {
                return req({
                    method: 'POST',
                    url: `reports/${reportId}/associated_records/`,
                    data: reportReportLink,
                });
            },
            getAssociatedRecordsForCase(caseId) {
                return req({
                    method: 'GET',
                    url: `cases/${caseId}/associated_records`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import {
    EntityTypeEnumType,
    FilesSendEmailRequest,
    Mark43File,
    PrintsSendEmailRequest,
    RmsWorkOrder,
    SendEmailRequest,
} from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import type { MiniUser } from '~/client-common/core/domain/email-export-releases/state/ui';

import emailResource from '../resources/emailResource';
import { MAX_EMAIL_ATTACHMENTS_SIZE } from '../configuration';

const emailStrings = componentStrings.exports.emails;

export const getEmailButtonState = (
    numOfSelectedPackets: number,
    includeAttachments: boolean,
    attachmentsSize: number
) => {
    if (numOfSelectedPackets < 1) {
        return {
            isDisabled: true,
            tooltip: emailStrings.noPacketsTooltip,
        };
    }

    if (includeAttachments && attachmentsSize > MAX_EMAIL_ATTACHMENTS_SIZE) {
        return {
            isDisabled: true,
            tooltip: emailStrings.attachmentsSizeTooltip,
        };
    }
    return { isDisabled: false };
};

export const emailWorkOrder = (
    workOrder: RmsWorkOrder | RmsWorkOrder[],
    emailData: SendEmailRequest
) => {
    const payload: PrintsSendEmailRequest = {
        sendEmailRequest: emailData,
        workOrders: Array.isArray(workOrder) ? workOrder : [workOrder],
    };
    return emailResource.emailWorkOrder(payload);
};

export const getShortenedRecipientsText = (externalRecipients: string[], users: MiniUser[]) => {
    const userNames = users.map(({ fullName }) => fullName);

    // per product, order of user names vs external emails in a shortened text doesn't matter
    const recipients = [...userNames, ...externalRecipients];
    const length = recipients.length;

    if (length > 1) {
        return `${recipients[0]} + ${length - 1}`;
    }
    if (length === 1) {
        return recipients[0];
    }
    return '';
};

export const emailAttachments = ({
    emailData,
    attachmentFileIds,
    entityType,
    entityId,
}: {
    emailData: SendEmailRequest;
    attachmentFileIds: Mark43File['id'][];
    entityType: EntityTypeEnumType;
    entityId: number;
}) => {
    const payload: FilesSendEmailRequest = {
        sendEmailRequest: emailData,
        mark43FileIds: attachmentFileIds,
        ownerContext: {
            ownerType: entityType,
            ownerId: entityId,
        },
    };
    return emailResource.emailFiles(payload);
};

import styled from 'styled-components';
import { mediaQueries } from 'arc';

const lightGreyInterpolation = (props) => props.theme.colors.lightGrey;

const Column = styled.div`
    padding: 30px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid ${lightGreyInterpolation};

    @media (min-width: ${mediaQueries.md}) {
        border-bottom: none;
        border-right: 1px solid ${lightGreyInterpolation};
    }
`;

export const ColumnLeft = styled(Column)`
    flex: 0 1 40%;
`;

export const ColumnFull = styled(Column)`
    padding: 10px 20px;
    flex: ${(props) => (props.flexGrow !== undefined ? props.flexGrow : 1)} 0 auto;
    border: none;
`;

export const ColumnFullBottomBorder = styled(ColumnFull)`
    border-bottom: 1px solid ${lightGreyInterpolation};
`;

export const ColumnRight = styled(Column)`
    flex: 0 1 60%;
    border: none;
`;

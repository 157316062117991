import { some } from 'lodash';
import { useFormGetter } from '../forms/hooks/useFormGetter';
import { FieldDescriptorType } from './helpers/types';

// The body part and mark type are required to be entered
type ScarsMarksTattoosFormModelT = {
    bodyPartAttrId: number;
    identifyingMarkTypeAttrId: number;
    description?: number;
};

type PersonProfilePhysicalCharacteristicsFormModelT = {
    // Height (or Height Range)
    heightFeet?: string;
    heightInches?: string;
    heightMaxFeet?: string;
    heightMaxInches?: string;
    heightMinFeet?: string;
    heightMinInches?: string;

    // Weight (or Weight Range)
    weight?: string;
    weightRangeMax?: string;
    weightRangeMin?: string;

    // Build
    buildAttrId?: number;

    // Complexion
    skinToneAttrId?: number;

    // Scars Marks Tattoos
    identifyingMarks?: ScarsMarksTattoosFormModelT[];

    // Eye Color
    eyeColorAttrId?: number;

    // Ethnicity
    ethnicityAttrId?: number;

    // Hair Color
    hairColorAttrId?: number;
};

type ArgsT = {
    minValue: string;
};
export const getPersonProfileNumberOfPhysicalCharacteristics =
    () =>
    (
        _fieldDescriptor: FieldDescriptorType,
        { minValue }: ArgsT,
        { context: formContext }: { context: string }
    ) => {
        const { getForm } = useFormGetter();
        const personProfileForm = getForm(formContext);
        let numberOfPhysicalCharacteristics = 0;

        if (!personProfileForm) {
            return true;
        }
        // When personProfileFormConfiguration is typed, we should remove the type assertion.
        const formModel = personProfileForm.getState()
            .model as PersonProfilePhysicalCharacteristicsFormModelT;

        const {
            // Height
            heightFeet,
            heightInches,
            heightMaxFeet,
            heightMaxInches,
            heightMinFeet,
            heightMinInches,

            // Weight
            weight,
            weightRangeMax,
            weightRangeMin,

            // Build
            buildAttrId,

            // Complexion
            skinToneAttrId,
            // Scars Marks Tattoos
            identifyingMarks = [],

            // Eye Color
            eyeColorAttrId,

            // Ethnicity
            ethnicityAttrId,

            // Hair Color
            hairColorAttrId,
        } = formModel;

        const heightEntry = !!heightFeet && !!heightInches;

        const heightRangeEntry =
            !!heightMaxFeet && !!heightMaxInches && !!heightMinFeet && !!heightMinInches;

        if (heightEntry || heightRangeEntry) {
            numberOfPhysicalCharacteristics++;
        }

        if (!!weight || (!!weightRangeMax && !!weightRangeMin)) {
            numberOfPhysicalCharacteristics++;
        }

        if (!!buildAttrId) {
            numberOfPhysicalCharacteristics++;
        }

        if (!!skinToneAttrId) {
            numberOfPhysicalCharacteristics++;
        }

        const marksEntered = some(
            identifyingMarks,
            ({ bodyPartAttrId, identifyingMarkTypeAttrId }) => {
                return !!bodyPartAttrId && !!identifyingMarkTypeAttrId;
            }
        );

        if (marksEntered) {
            numberOfPhysicalCharacteristics++;
        }

        if (!!eyeColorAttrId) {
            numberOfPhysicalCharacteristics++;
        }

        if (!!ethnicityAttrId) {
            numberOfPhysicalCharacteristics++;
        }

        if (!!hairColorAttrId) {
            numberOfPhysicalCharacteristics++;
        }

        return numberOfPhysicalCharacteristics >= Number(minValue);
    };

import React from 'react';
import { Icon as BaseIcon, iconTypes } from 'components-mark43';
import { withTheme } from 'styled-components';
import theme from '../styles/theme';
import getThemeColor, { ThemeColorsT } from '../utils/getThemeColor';

export { iconTypes, iconSizes } from 'components-mark43';

interface IconProps {
    color?: ThemeColorsT;
    type: keyof typeof iconTypes;
    size?: number;
    theme: typeof theme;
    className?: string;
}

const Icon: React.FC<IconProps> = ({ theme, color, ...otherProps }) => {
    return <BaseIcon color={color && getThemeColor(theme, color)} {...otherProps} />;
};

// A color can either be passed in by hex code,
// or by the name of the color in the theme

export default withTheme(Icon);

import { map, filter } from 'lodash';
import { createSelector } from 'reselect';

// helpers
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';

// selectors
import { formatAttributeByIdSelector, attributesSelector } from '../../../attributes/state/data';
import { offenseAttributesSelector } from '../data';

const offenseAttributeViewModelsSelector = createSelector(
    offenseAttributesSelector,
    formatAttributeByIdSelector,
    attributesSelector,
    (offenseAttributes, formatAttributeById, attributes) => {
        const viewModel = buildViewModel({
            mappers: [
                ({ attributeId }) => ({
                    attributeId: formatAttributeById(attributeId),
                }),
                ({ attributeId }) => ({
                    isOther: attributes[attributeId].other,
                }),
            ],
        });

        return map(offenseAttributes, viewModel);
    }
);

export const offenseAttributeViewModelsWhereSelector = createSelector(
    offenseAttributeViewModelsSelector,
    (offenseAttributeViewModels) => (predicate) => filter(offenseAttributeViewModels, predicate)
);

import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';
import { FeatureLayerApplyEditsT, FeatureLayerT, GraphicT, MapViewT } from '../../types';

type LayerControlProps<T> = {
    layer: FeatureLayerT | undefined;
    currItems: T[];
    createGraphics: (items: T[]) => GraphicT[];
    replaceExisting?: boolean;
    zoomToGraphics?: boolean;
    mapView?: MapViewT;
};

export const spatialReference = {
    // WGS84
    wkid: 4326,
};

export const useStaticLayerControl = <T>({
    layer,
    currItems,
    createGraphics,
    replaceExisting,
    zoomToGraphics,
    mapView,
}: LayerControlProps<T>) => {
    const itemsRef = useRef<GraphicT[]>();

    useEffect(() => {
        if (layer) {
            const itemsToBeAdded: T[] = [];

            // add all currItems
            itemsToBeAdded.push(...currItems);

            // build graphic of icons
            const itemGraphicsToBeAdded = createGraphics(itemsToBeAdded);

            // compile edits into correct format
            const edits: FeatureLayerApplyEditsT = {};
            if (itemGraphicsToBeAdded.length) {
                edits.addFeatures = itemGraphicsToBeAdded;
            }

            if (replaceExisting && !!itemsRef.current?.length) {
                edits.deleteFeatures = itemsRef.current;
            }

            // apply edits to the unit layer if there are any
            if (!isEmpty(edits)) {
                layer?.applyEdits(edits).then(() => {
                    itemsRef.current = itemGraphicsToBeAdded;

                    // We can zoom to the graphics by passing them into the `goTo` function. This
                    // can be very useful in the case of the setup wizard.
                    if (mapView && zoomToGraphics) {
                        mapView.goTo(itemGraphicsToBeAdded);
                    }
                });
            }
        }
    }, [currItems, replaceExisting, layer, mapView, zoomToGraphics, createGraphics]);
};

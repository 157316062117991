import { FieldNameEnumType } from '@mark43/rms-api';
import React from 'react';
import FormElement from '../FormElement';

import MultiSelectButtonRadio from './MultiSelectButtonRadio';
import { ButtonRadioOption, LegacyButtonRadioOption } from './types';

type FormElementButtonRadioProps = {
    error?: string;
    fieldName?: FieldNameEnumType;
    forceShowError?: boolean;
    helpText?: string;
    isRequired?: boolean;
    label: React.ReactNode;
    multiple?: boolean;
    onChange?: (newSelectedOptions: ButtonRadioOption[]) => void;
    options: ButtonRadioOption[] | LegacyButtonRadioOption[];
    selectedOptions: ButtonRadioOption[];
};

const FormElementMultiSelectButtonRadio: (props: FormElementButtonRadioProps) => JSX.Element = ({
    error,
    fieldName,
    forceShowError,
    helpText,
    isRequired,
    label,
    multiple,
    onChange,
    options,
    selectedOptions,
    ...otherProps
}) => {
    return (
        <FormElement
            label={label}
            fieldName={fieldName}
            error={error}
            forceShowError={forceShowError}
            helpText={helpText}
            isRequired={isRequired}
            labelClassName="mark43-form-element-button-radio-label"
            {...otherProps}
        >
            <MultiSelectButtonRadio
                multiple={multiple}
                onChange={onChange}
                options={options}
                selectedOptions={selectedOptions}
            />
        </FormElement>
    );
};

export default FormElementMultiSelectButtonRadio;

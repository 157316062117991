import React from 'react';
import { chain, head, get, keyBy } from 'lodash';
import classNames from 'classnames';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { OperationTypeEnum } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { itemEvidenceStateByMasterItemIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import { chainEventViewModelsForMasterItemIdSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { inPoliceCustodyChainEventTypeSelector } from '~/client-common/core/domain/chain-event-types/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import useFields, { useEvidenceModuleName } from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';
import { evidenceLocationPermissionsWhereSelector } from '~/client-common/core/domain/evidence-location-permissions/state/data';
import { userRolesSelector } from '~/client-common/core/domain/user-roles/state/data';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { hasEvidenceLocationPermission } from '../utils/evidenceItemsHelpers';
import { Tooltip, ConditionalTooltip } from '../../../core/components/tooltip';
import Icon, { iconTypes } from '../../../core/components/Icon';
import OverlayButton from '../../../core/overlays/components/OverlayButton';
import Link from '../../../core/components/links/Link';
import _DropdownMenu from '../../../core/components/DropdownMenu';
import testIds from '../../../../core/testIds';
import { openLabelPrintingModal } from '../../label-printing/state/ui';
import { addToItemQueue } from '../../item-queue/state/ui';
import withDispositionActionSidePanel, {
    ACTION_TYPES,
} from '../../../reports/custodial-property-summary/components/withDispositionActionSidePanel';
import { setHighValue } from '../state/ui';
import EvidenceSetHighValueModal from './EvidenceSetHighValueModal';

const DropdownMenu = styled(_DropdownMenu)`
    .dropdown-menu-button {
        background-color: ${(props) => props.theme.colors.extraLightGrey};
    }
`;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
`;

const entity = componentStrings.entityProfiles;
const strings = entity.EvidenceItemMenu;
const noManagePermissionTooltip =
    componentStrings.evidence.dashboard.EvidenceDashboardSearchResults.actionButtons
        .noManagePermissionTooltip;

const DispositionDropdownMenuOption = withDispositionActionSidePanel(
    ({ onClick, optionTestId, disabled, children, disabledTooltipText }) => (
        <ConditionalTooltip side="left" content={disabledTooltipText} condition={disabled}>
            <div
                className={classNames('dropdown-menu-option', { disabled })}
                onClick={disabled ? undefined : onClick}
                data-test-id={optionTestId}
            >
                {children}
            </div>
        </ConditionalTooltip>
    )
);

function EvidenceItemMenu({
    canPrintLabel,
    isInPoliceCustody,
    onPrintLabelClick,
    onAddToQueueClick,
    masterItemId,
    itemEvidenceStateByMasterItemId,
    setHighValue,
    evidenceLocationPermissionsWhere,
    isInEvidenceLocation,
    applicationSettings,
}) {
    const userRoles = useSelector(userRolesSelector);
    const itemEvidenceState = itemEvidenceStateByMasterItemId(masterItemId);

    const showDisposition = get(itemEvidenceState, 'canManualRequestDisposition');
    const isHighValue = get(itemEvidenceState, 'isHighValue');
    const isInHighValueContainer = get(itemEvidenceState, 'isInHighValueContainer');
    const evidenceModuleName = useEvidenceModuleName();
    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];

    // only return a permission set if the item is currently in Facility or Storage location
    const permissions = isInEvidenceLocation
        ? [evidenceLocationPermissionsWhere({ masterItemId })]
        : [];
    const userRolesById = keyBy(userRoles, (role) => role.roleId);

    const useEvdPerms = applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED;
    const hasLocationPermission =
        !useEvdPerms ||
        hasEvidenceLocationPermission(permissions, userRolesById, OperationTypeEnum.MANAGE.name);

    return (
        <>
            <DropdownMenu
                buttonContent={
                    <DropdownMenuButtonContainer>
                        <Icon color="cobaltBlue" type={iconTypes.EVIDENCE} />
                        <Icon color="cobaltBlue" type={iconTypes.TRIANGLE_DOWN} />
                    </DropdownMenuButtonContainer>
                }
                testId={testIds.EVIDENCE_ITEM_MENU}
                tooltip={entity.EntityProfileHeaderMenu.evidenceOptions(evidenceModuleName)}
            >
                {!isInPoliceCustody && (
                    <OnlyWithAbility has={abilitiesEnum.EVIDENCE.ITEM_QUEUE}>
                        {hasLocationPermission ? (
                            <div
                                onClick={onAddToQueueClick}
                                className="dropdown-menu-option"
                                data-test-id={testIds.EVIDENCE_ITEM_MENU_ADD_TO_QUEUE}
                            >
                                {strings.addToQueue}
                            </div>
                        ) : (
                            <Tooltip side="top" content={noManagePermissionTooltip}>
                                <div
                                    className="dropdown-menu-option disabled"
                                    data-test-id={testIds.EVIDENCE_ITEM_MENU_ADD_TO_QUEUE}
                                >
                                    {strings.addToQueue}
                                </div>
                            </Tooltip>
                        )}
                    </OnlyWithAbility>
                )}
                <OnlyWithAbility has={abilitiesEnum.EVIDENCE.CREATE_CHAIN_EVENTS}>
                    <Link
                        to={`/evidence/${masterItemId}/exports`}
                        className="dropdown-menu-option"
                        testId={testIds.EVIDENCE_ITEM_MENU_EXPORT}
                    >
                        {strings.downloadChainOfCustody(custodyLabel)}
                    </Link>
                </OnlyWithAbility>
                {canPrintLabel && (
                    <div
                        onClick={onPrintLabelClick}
                        className="dropdown-menu-option"
                        data-test-id={testIds.EVIDENCE_ITEM_MENU_PRINT_LABEL}
                    >
                        {strings.printItemLabel}
                    </div>
                )}
                {showDisposition && (
                    <OnlyWithAbility has={abilitiesEnum.EVIDENCE.REQUEST_DISPOSITION}>
                        <DispositionDropdownMenuOption
                            actionType={ACTION_TYPES.REQUEST_DISPOSITION}
                            masterItemIds={[masterItemId]}
                            disabled={!hasLocationPermission}
                            optionTestId={testIds.EVIDENCE_ITEM_MENU_REQUEST_DISPOSITION}
                            disabledTooltipText={noManagePermissionTooltip}
                        >
                            {strings.requestDisposition}
                        </DispositionDropdownMenuOption>
                    </OnlyWithAbility>
                )}
                {
                    <FeatureFlagged flag="EVIDENCE_AUTO_LOCATION_UPDATE_ENABLED">
                        <OnlyWithAbility
                            has={abilitiesEnum.EVIDENCE.SET_EVIDENCE_ITEM_HIGH_VALUE_STATUS}
                        >
                            {!!isInHighValueContainer ? (
                                <div className="dropdown-menu-option disabled">
                                    {strings.itemIsHighValue}
                                </div>
                            ) : (
                                <OverlayButton id={overlayIdEnum.EVIDENCE_SET_HIGH_VALUE_MODAL}>
                                    {(openOverlay) => (
                                        <div onClick={openOverlay} className="dropdown-menu-option">
                                            {strings.setHighValue(!!isHighValue)}
                                        </div>
                                    )}
                                </OverlayButton>
                            )}
                        </OnlyWithAbility>
                    </FeatureFlagged>
                }
            </DropdownMenu>
            <EvidenceSetHighValueModal
                onSave={() => setHighValue(!isHighValue, masterItemId)}
                isHighValue={!!isHighValue}
            />
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    chainEventViewModelsForMasterItemId: chainEventViewModelsForMasterItemIdSelector,
    inPoliceCustodyChainEventType: inPoliceCustodyChainEventTypeSelector,
    itemEvidenceStateByMasterItemId: itemEvidenceStateByMasterItemIdSelector,
    evidenceLocationPermissionsWhere: evidenceLocationPermissionsWhereSelector,
    applicationSettings: applicationSettingsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openLabelPrintingModal: (masterItemId) =>
        dispatch(openLabelPrintingModal({ masterItemIds: [masterItemId] })),
    addToItemQueue: (masterItemId) => dispatch(addToItemQueue(masterItemId)),
    setHighValue: (isHighValue, masterItemId) => dispatch(setHighValue(isHighValue, masterItemId)),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onPrintLabelClick({ openLabelPrintingModal, masterItemId }) {
            return () => openLabelPrintingModal(masterItemId);
        },
        onAddToQueueClick({ addToItemQueue, masterItemId }) {
            return () => addToItemQueue(masterItemId);
        },
    }),
    withPropsOnChange(
        ['chainEventViewModelsForMasterItemId', 'inPoliceCustodyChainEventType', 'masterItemId'],
        ({ chainEventViewModelsForMasterItemId, inPoliceCustodyChainEventType, masterItemId }) => {
            const isInPoliceCustody = !!inPoliceCustodyChainEventType
                ? chain(masterItemId)
                      .thru(chainEventViewModelsForMasterItemId)
                      .first()
                      .get('eventTypeId')
                      .thru((type) => type === inPoliceCustodyChainEventType.id)
                      .value()
                : false;

            const canPrintLabel = !isInPoliceCustody;
            const latestChainEvent = head(chainEventViewModelsForMasterItemId(masterItemId));

            const isInEvidenceLocation =
                latestChainEvent.facilityId !== undefined ||
                latestChainEvent.storageLocationId !== undefined;

            return {
                isInPoliceCustody,
                canPrintLabel,
                isInEvidenceLocation,
            };
        }
    )
)(EvidenceItemMenu);

import { connectPropertyStatusesForItem } from '../utils/propertyStatusHelpers';
import PropertyStatusTitles from './PropertyStatusTitles';

/**
 * Use this component to display property status titles for an item profile id.
 *   Statuses are sorted oldest to newest statusDateUtc.
 * @param  {number} props.itemProfileId
 */
const ConnectedPropertyStatusTitlesForItem = connectPropertyStatusesForItem(PropertyStatusTitles);

export default ConnectedPropertyStatusTitlesForItem;

import {
    AttributeTypeEnum,
    EntityTypeEnum,
    ProductModuleEnum,
    UsageActionEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';
import _, { pick, chain, keyBy, map, get, parseInt, find, filter, includes, first } from 'lodash';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import sortLocationEntityLinks from '~/client-common/core/domain/location-entity-links/utils/sortLocationEntityLinks';
import { nowUtc, isDateInFuture } from '~/client-common/core/dates/utils/dateHelpers';
import {
    convertImageToImageGalleryViewModel,
    convertAttachmentToImageGalleryViewModel,
} from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import {
    assignViewModelProperties,
    getViewModelProperties,
} from '~/client-common/helpers/viewModelHelpers';
import {
    buildElasticLocationLines,
    createLocationsResultsSelector,
} from '~/client-common/core/domain/elastic-involved-locations/state/ui';
import { nameItemLinksSelector } from '~/client-common/core/domain/name-item-links/state/data';
import { nameReportLinksSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { elasticReportsSelector } from '~/client-common/core/domain/elastic-reports/state/data';
import { elasticPersonsSelector } from '~/client-common/core/domain/elastic-persons/state/data';
import { elasticOrganizationsSelector } from '~/client-common/core/domain/elastic-organizations/state/data';
import { elasticPropertySelector } from '~/client-common/core/domain/elastic-property/state/data';
import { elasticVehiclesSelector } from '~/client-common/core/domain/elastic-vehicles/state/data';
import { elasticWarrantsSelector } from '~/client-common/core/domain/elastic-warrants/state/data';
import { staffRemarksByEntitySelector } from '~/client-common/core/domain/staff-remarks/state/data';

import { createPersonsResultsSelector } from '~/client-common/core/domain/elastic-persons/state/ui';
import { createOrganizationsResultsSelector } from '~/client-common/core/domain/elastic-organizations/state/ui';
import { createPropertyResultsSelector } from '~/client-common/core/domain/elastic-property/state/ui';
import { createVehiclesResultsSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import { createWarrantsResultsSelector } from '~/client-common/core/domain/elastic-warrants/state/ui';
import {
    compareNameNameLinksByEffectiveDates,
    filterNameNameLinks,
} from '~/client-common/helpers/nameNameLinksHelpers';
import { identifyingMarksDisplayAndImagesByNameIdSelector } from '~/client-common/core/domain/identifying-marks/state/ui';
import { personProfilesSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { personProfileViewModelByIdSelector } from '~/client-common/core/domain/person-profiles/state/ui';
import { organizationProfilesSelector } from '~/client-common/core/domain/organization-profiles/state/data';
import { organizationProfileViewModelByIdSelector } from '~/client-common/core/domain/organization-profiles/state/ui';
import { employmentHistoryViewModelsByPersonProfileIdSelector } from '~/client-common/core/domain/employment-histories/state/ui';
import { schoolHistoriesByPersonProfileIdSelector } from '~/client-common/core/domain/school-histories/state/data';
import { nameMonikersByNameIdSelector } from '~/client-common/core/domain/name-monikers/state/data';
import { attachmentsWhereSelector } from '~/client-common/core/domain/attachments/state/data';
import { itemProfileViewModelByIdSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import { formatRenSequenceNumberSelector } from '~/client-common/core/domain/item-reporting-event-links/state/ui';
import { legacyEntityDetailsWhereSelector } from '~/client-common/core/domain/legacy-entity-details/state/data';
import { personEmergencyContactsByPersonProfileIdSelector } from '~/client-common/core/domain/person-emergency-contacts/state/data';
import { passportsByPersonProfileIdSelector } from '~/client-common/core/domain/passports/state/data';
import { personProbationsByPersonProfileIdSelector } from '~/client-common/core/domain/person-probations/state/data';
import { personGangTrackingForPersonIdSelector } from '~/client-common/core/domain/person-gang-trackings/state/data';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    attributesByTypeSelector,
    formatAttributeByIdSelector,
    linkAttributeDisplayObjectsSelector,
    attributesDisplayValuesByTypeForAttributesSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { formatAgeRange } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { defaultProfileImagePath } from '~/client-common/helpers/profileImageHelpers';
import {
    entityTypeParamToEnum,
    entityTypeSectionToUsageActionEnum,
} from '~/client-common/helpers/entityProfileHelpers';

import { itemProfilesSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { firearmsSelector } from '~/client-common/core/domain/firearms/state/data';
import { firearmViewModelByIdSelector } from '~/client-common/core/domain/firearms/state/ui';
import { vehiclesSelector } from '~/client-common/core/domain/vehicles/state/data';
import {
    vehicleViewModelByIdSelector,
    formatTitleForVehicleSelector,
} from '~/client-common/core/domain/vehicles/state/ui';
import {
    nameItemLinkViewModelsWithItemIdAndParentNameItemAssociationAttrIdSelector,
    formatNameItemAssociationAttrByNameItemLinkIdSelector,
} from '~/client-common/core/domain/name-item-links/state/ui';
import { getInvolvementSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import {
    formatLocationLinkTypeId,
    getOtherLocationTypesLabelMap,
    otherPersonLocationTypes,
} from '~/client-common/helpers/linkTypesHelpers';

import { chainOfCustodyViewModelsForMasterItemIdSelector } from '~/client-common/core/domain/chain-of-custodies/state/ui';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { itemAttributesWhereSelector } from '~/client-common/core/domain/item-attributes/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { formatUserByIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';
import { nameAttributesDataSelector } from '../../../../../legacy-redux/selectors/nameAttributesSelectors';
import {
    nameNameLinksDataSelector,
    formatNameNameLinkSelector,
    formatNameNameLinkWithEffectiveDatesSelector,
} from '../../../../../legacy-redux/selectors/nameNameLinksSelectors';
import createReportsResultsSelector from '../../../../search/core/utils/createReportsResultsSelector';
import { formatTitleForPropertyRecord } from '../../../../../legacy-redux/helpers/propertyRecordHelpers';

import {
    uiReportsSelector,
    uiSelector,
    currentEntityProfileIdSelector,
    currentEntityProfileChildrenIdsSelector,
    currentProfileWarrantViewModelsSelector,
} from './selectors';

export {
    currentEntityProfileIdSelector,
    currentEntityProfileMasterProfileIdSelector,
    currentEntityProfileOwnerContextSelector,
    currentEntityProfileOwnerTitleSelector,
} from './selectors';

const { ITEM_PROFILE, REPORT } = EntityTypeEnum;

// ACTION TYPES
const INITIALIZE_ENTITY_PROFILE = 'entity-profiles/INITIALIZE_ENTITY_PROFILE';
const TRANSITION_WARRANTS_CONTAINER = 'entity-profiles/TRANSITION_WARRANTS_CONTAINER';

export const LOAD_ENTITY_PROFILE_START = 'entity-profiles/LOAD_ENTITY_PROFILE_START';
export const LOAD_ENTITY_PROFILE_SUCCESS = 'entity-profiles/LOAD_ENTITY_PROFILE_SUCCESS';
export const LOAD_ENTITY_PROFILE_REPORTS_START =
    'entity-profiles/LOAD_ENTITY_PROFILE_REPORTS_START';
export const LOAD_ENTITY_PROFILE_REPORTS_FAILURE =
    'entity-profiles/LOAD_ENTITY_PROFILE_REPORTS_FAILURE';
export const LOAD_ENTITY_PROFILE_REPORTS_SUCCESS =
    'entity-profiles/LOAD_ENTITY_PROFILE_REPORTS_SUCCESS';
export const LOAD_ENTITY_PROFILE_BOOKINGS_START =
    'entity-profiles/LOAD_ENTITY_PROFILE_BOOKINGS_START';
export const LOAD_ENTITY_PROFILE_BOOKINGS_FAILURE =
    'entity-profiles/LOAD_ENTITY_PROFILE_BOOKINGS_FAILURE';
export const LOAD_ENTITY_PROFILE_BOOKINGS_SUCCESS =
    'entity-profiles/LOAD_ENTITY_PROFILE_BOOKINGS_SUCCESS';
export const LOAD_ENTITY_PROFILE_LOCATIONS_SUCCESS =
    'entity-profiles/LOAD_ENTITY_PROFILE_LOCATIONS_SUCCESS';
export const LOAD_ENTITY_PROFILE_PERSONS_SUCCESS =
    'entity-profiles/LOAD_ENTITY_PROFILE_PERSONS_SUCCESS';
export const LOAD_ENTITY_PROFILE_ORGANIZATIONS_SUCCESS =
    'entity-profiles/LOAD_ENTITY_PROFILE_ORGANIZATIONS_SUCCESS';
export const LOAD_ENTITY_PROFILE_PROPERTY_SUCCESS =
    'entity-profiles/LOAD_ENTITY_PROFILE_PROPERTY_SUCCESS';
export const LOAD_ENTITY_PROFILE_VEHICLES_SUCCESS =
    'entity-profiles/LOAD_ENTITY_PROFILE_VEHICLES_SUCCESS';
export const LOAD_ENTITY_PROFILE_WARRANTS_SUCCESS =
    'entity-profiles/LOAD_ENTITY_PROFILE_WARRANTS_SUCCESS';
export const LOAD_ENTITY_PROFILE_FAILURE = 'entity-profiles/LOAD_ENTITY_PROFILE_FAILURE';

// ACTIONS
export function initializeEntityProfile() {
    return { type: INITIALIZE_ENTITY_PROFILE };
}

function transitionWarrantsContainer() {
    return { type: TRANSITION_WARRANTS_CONTAINER };
}

export function startWarrantsContainerTransitionDelay() {
    return function (dispatch) {
        setTimeout(() => dispatch(transitionWarrantsContainer()), 1500);
    };
}

// SELECTORS
export const uiReportsPaginationSelector = createSelector(
    uiReportsSelector,
    ({ pagination }) => pagination
);

/**
 * The type of the entity profile currently being viewed (as an entityType enum value).
 * @type {string}
 */
export const currentEntityProfileTypeSelector = createSelector(
    uiSelector,
    ({ currentEntityProfileType }) => currentEntityProfileType
);

/**
 * The item type of the entity profile currently being viewed. Will be `undefined`
 *   if the entity profile does not represent an item.
 * @type {string}
 */
export const currentEntityProfileItemTypeSelector = createSelector(
    uiSelector,
    ({ currentEntityProfileItemType }) => currentEntityProfileItemType
);

/**
 * The profile model of the entity profile currently being viewed. For a person,
 *   this would be the PersonProfile model, not the PersonProfileView model.
 * @type {Object}
 */
const currentEntityProfileSelector = createSelector(
    currentEntityProfileTypeSelector,
    currentEntityProfileIdSelector,
    personProfilesSelector,
    organizationProfilesSelector,
    itemProfilesSelector,
    firearmsSelector,
    vehiclesSelector,
    (
        entityType,
        entityId,
        personProfiles,
        organizationProfiles,
        itemProfiles,
        firearms,
        vehicles
    ) => {
        switch (entityType) {
            case EntityTypeEnum.PERSON_PROFILE.name:
                return personProfiles[entityId];
            case EntityTypeEnum.ORGANIZATION_PROFILE.name:
                return organizationProfiles[entityId];
            case EntityTypeEnum.ITEM_PROFILE.name:
                return itemProfiles[entityId] || firearms[entityId] || vehicles[entityId];
            default:
                return {};
        }
    }
);

/**
 * Whether the entity profile currently being viewed is a master entity.
 * @type {boolean}
 */
export const currentEntityProfileIsMasterProfileSelector = createSelector(
    currentEntityProfileSelector,
    // TODO - I don't think this is correct
    (profile) => get(profile, 'id') === get(profile, 'ownerId')
);

/**
 * The permissions that the current user has for the entity profile currently
 *   being viewed.
 * @type {string[]}
 */
export const currentEntityProfileUserOperationTypesSelector = createSelector(
    currentEntityProfileSelector,
    (profile) => get(profile, 'permissionSet')
);

/**
 * The department id for the department owning the entity profile
 * currently being viewed.
 * @type {number}
 */
export const currentEntityProfileDepartmentIdSelector = createSelector(
    currentEntityProfileSelector,
    (profile) => get(profile, 'departmentId')
);

/**
 * Whether the entity profile's permissions are visible to the current user. If
 *   true, a lock icon appears in the entity profile for the user to view (and
 *   possibly manage) the permissions.
 * @type {boolean}
 */
export const currentEntityProfileHasVisiblePermissionsSelector = createSelector(
    currentEntityProfileIsMasterProfileSelector,
    currentEntityProfileSelector,
    (isMaster, profile) => isMaster && !get(profile, 'isSociety')
);

const currentEntityProfileIsContextedWithBookingParentSelector = createSelector(
    currentEntityProfileIsMasterProfileSelector,
    currentEntityProfileSelector,
    (isMaster, profile) => !isMaster && get(profile, 'ownerType') === EntityTypeEnum.BOOKING.name
);

export const currentEntityProfileIsEditableSelector = createSelector(
    currentEntityProfileHasVisiblePermissionsSelector,
    currentEntityProfileIsContextedWithBookingParentSelector,
    (hasVisiblePermissions, isContextedWithBookingParent) =>
        hasVisiblePermissions || isContextedWithBookingParent
);

const profileReportsSelector = createSelector(
    uiReportsSelector,
    elasticReportsSelector,
    ({ ids }, elasticReports) => map(ids, (id) => elasticReports[id])
);
const reportsResultsViewModelsSelector = createReportsResultsSelector(profileReportsSelector);
const augmentReportViewModelSelector = createSelector(
    attributesByTypeSelector,
    nameReportLinksSelector,
    reportsResultsViewModelsSelector,
    formatAttributeByIdSelector,
    currentEntityProfileIdSelector,
    currentEntityProfileChildrenIdsSelector,
    getInvolvementSelector,
    elasticReportsSelector,
    (
        attributesByType,
        nameReportLinks,
        reportsResultsViewModels,
        formatAttributeById,
        currentEntityProfileId,
        childrenIds,
        getInvolvement,
        elasticReports
    ) => {
        return map(reportsResultsViewModels, ({ id, involvedProperty, involvedVehicles }) => ({
            involvement: getInvolvement(
                nameReportLinks,
                id,
                [currentEntityProfileId, ...childrenIds],
                keyBy(attributesByType(AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE.name), 'id')
            ),
            propertyStatuses: _(involvedProperty)
                .filter({
                    property: {
                        masterItemId: currentEntityProfileId,
                    },
                })
                .concat(
                    filter(involvedVehicles, {
                        vehicle: { masterVehicleId: currentEntityProfileId },
                    })
                )
                .uniqBy('propertyStatusAttrId')
                .map(({ propertyStatusAttrId }) => formatAttributeById(propertyStatusAttrId))
                .join(', '),
            isJuvenile: isJuvenileHelper(elasticReports, id, currentEntityProfileId),
        }));
    }
);

const isJuvenileHelper = (elasticReports, id, currentEntityProfileId) => {
    let isJuvenile = false;

    elasticReports[id].involvedPersons.forEach((person) =>
        person.person.masterPersonId === currentEntityProfileId
            ? person.person.isJuvenile
                ? (isJuvenile = true)
                : undefined
            : undefined
    );

    return isJuvenile;
};

export const profileReportsViewModelsSelector = createSelector(
    reportsResultsViewModelsSelector,
    augmentReportViewModelSelector,
    (reportViewModels, augmentReportViewModels) =>
        _(reportViewModels)
            .zip(augmentReportViewModels)
            // pair[0] is the original reportViewModel, pair[1] is an object
            // containing properties with which we'll augment the original
            // reportViewModel
            .map((pair) => assignViewModelProperties(pair[0], pair[1]))
            .value()
);

export const sortedProfileReportsViewModelsSelector = createSelector(
    profileReportsViewModelsSelector,
    (reportViewModels) => _(reportViewModels).sortBy('reportCreationDateUtc').reverse().value()
);

const profileImagesByEntityIdSelector = createSelector(
    attachmentsWhereSelector,
    (attachmentsWhere) => (entityId) =>
        _(
            attachmentsWhere(
                (attachment) =>
                    attachment.entityId === entityId &&
                    (attachment.linkType === LinkTypesEnum.MUGSHOT ||
                        attachment.linkType === LinkTypesEnum.ITEM_PROFILE_PHOTO ||
                        attachment.linkType === LinkTypesEnum.ORGANIZATION_PROFILE_PHOTO)
            )
        )
            .map('image')
            .compact()
            // ids are incremented for each upload, preventing ties
            // that would occur if sorted by createdDateUtc
            .orderBy('id', 'desc')
            .value()
);

export const organizationProfileDetailsSelector = createSelector(
    organizationProfileViewModelByIdSelector,
    currentEntityProfileIdSelector,
    legacyEntityDetailsWhereSelector,
    profileImagesByEntityIdSelector,
    formatUserByIdSelector,
    currentDepartmentDateFormatterSelector,
    (
        organizationProfileViewModelById,
        entityId,
        legacyEntityDetailsWhere,
        profileImagesByEntityId,
        formatUserById,
        dateTimeFormatter
    ) => {
        const profileImages = profileImagesByEntityId(entityId);
        const orgProfileViewModel = organizationProfileViewModelById(entityId);

        if (orgProfileViewModel) {
            const imageGalleryProfileImages = map(profileImages, (image) => {
                return convertImageToImageGalleryViewModel(
                    image,
                    formatUserById,
                    orgProfileViewModel.name,
                    dateTimeFormatter
                );
            });

            return {
                ...orgProfileViewModel,
                legacyEntityDetails: legacyEntityDetailsWhere({
                    entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                    entityId,
                }),
                profileImages: imageGalleryProfileImages,
            };
        }

        return undefined;
    }
);

const profileAttachmentsByEntityIdSelector = createSelector(
    attachmentsWhereSelector,
    (attachmentsWhere) => (entityId) =>
        _(
            attachmentsWhere(
                (attachment) =>
                    attachment.entityId === entityId &&
                    (attachment.linkType === LinkTypesEnum.MUGSHOT ||
                        attachment.linkType === LinkTypesEnum.ITEM_PROFILE_PHOTO ||
                        attachment.linkType === LinkTypesEnum.ITEM_PROFILE_ATTACHMENT)
            )
        )
            .map((attachment) => attachment.image || attachment.file)
            .compact()
            // ids are incremented for each upload, preventing ties
            // that would occur if sorted by createdDateUtc
            .orderBy('id', 'desc')
            .value()
);

export const personDetailsViewModelSelector = createSelector(
    personProfileViewModelByIdSelector,
    currentEntityProfileIdSelector,
    nameMonikersByNameIdSelector,
    identifyingMarksDisplayAndImagesByNameIdSelector,
    personEmergencyContactsByPersonProfileIdSelector,
    passportsByPersonProfileIdSelector,
    personProbationsByPersonProfileIdSelector,
    employmentHistoryViewModelsByPersonProfileIdSelector,
    schoolHistoriesByPersonProfileIdSelector,
    nameAttributesDataSelector,
    linkAttributeDisplayObjectsSelector,
    attributesDisplayValuesByTypeForAttributesSelector,
    legacyEntityDetailsWhereSelector,
    profileImagesByEntityIdSelector,
    formatUserByIdSelector,
    currentDepartmentDateFormatterSelector,
    (
        personProfileViewModelById,
        entityId,
        monikersByNameId,
        identifyingMarksDisplayAndImagesByNameId,
        personEmergencyContactsByNameId,
        passportsByPersonProfileId,
        personProbationsByNameId,
        employmentHistoriesByNameId,
        schoolHistoriesByNameId,
        nameAttributes,
        linkAttributeDisplayObjects,
        attributesDisplayValuesByTypeForAttributes,
        legacyEntityDetailsWhere,
        profileImagesByEntityId,
        formatUserById,
        dateTimeFormatter
    ) => {
        let profileImages = [];
        const personProfileViewModel = personProfileViewModelById(entityId);

        // gets the nameAttributes and formats it as
        // ATTRIBUTE_TYPE: [array of display values]
        const nameAttributesDisplay = attributesDisplayValuesByTypeForAttributes(nameAttributes);
        const clothingAttributesDisplayObject = linkAttributeDisplayObjects(
            filter(nameAttributes, { attributeType: AttributeTypeEnum.CLOTHING_TYPE.name })
        );

        const identifyingMarks = identifyingMarksDisplayAndImagesByNameId(entityId);
        const identifyingMarksImages = _(identifyingMarks).map('image').compact().value();

        if (personProfileViewModel) {
            // pulls from attachments with images and maps to an image object
            // that's consumable by the lightbox image gallery
            profileImages = map(profileImagesByEntityId(entityId), (image) => {
                return convertImageToImageGalleryViewModel(
                    image,
                    formatUserById,
                    personProfileViewModel.fullName,
                    dateTimeFormatter
                );
            });

            return {
                ...personProfileViewModel,
                monikers: monikersByNameId(entityId),
                schoolHistories: schoolHistoriesByNameId(entityId),
                personEmergencyContacts: personEmergencyContactsByNameId(entityId),
                passports: passportsByPersonProfileId(entityId),
                personProbations: personProbationsByNameId(entityId),
                employmentHistories: _.reject(
                    employmentHistoriesByNameId(entityId),
                    'militaryBranchAttrId'
                ),
                militaryHistories: _.filter(
                    employmentHistoriesByNameId(entityId),
                    'militaryBranchAttrId'
                ),
                identifyingMarks,
                identifyingMarksImages,
                profileImages,
                nameAttributesDisplay,
                clothingAttributesDisplayObject,
                legacyEntityDetails: legacyEntityDetailsWhere({
                    entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    entityId,
                }),
            };
        }

        return undefined;
    }
);

export const profileImagePathSelector = createSelector(
    currentEntityProfileItemTypeSelector,
    currentEntityProfileTypeSelector,
    currentEntityProfileIdSelector,
    profileImagesByEntityIdSelector,
    (
        currentEntityProfileItemType,
        currentEntityProfileType,
        currentEntityProfileId,
        profileImagesByEntityId
    ) => {
        const entityProfileImage = first(profileImagesByEntityId(currentEntityProfileId));
        const profileImage =
            get(entityProfileImage, 'thumbnailLargeFile.fileWebServerPath') ||
            get(entityProfileImage, 'originalFile.fileWebServerPath');

        return (
            profileImage ||
            defaultProfileImagePath(currentEntityProfileType, currentEntityProfileItemType)
        );
    }
);

export const personHeaderDataSelector = createSelector(
    personProfileViewModelByIdSelector,
    currentEntityProfileIdSelector,
    nameMonikersByNameIdSelector,
    nameAttributesDataSelector,
    currentProfileWarrantViewModelsSelector,
    personGangTrackingForPersonIdSelector,
    isProductModuleActiveSelector,
    applicationSettingsSelector,
    (
        personProfileViewModelById,
        entityId,
        monikersByNameId,
        nameAttributes,
        currentProfileWarrantViewModels,
        personGangTrackingForPersonId,
        isProductModuleActive,
        applicationSettings
    ) => {
        const personProfileViewModel = personProfileViewModelById(entityId);
        const viewModelProperties = personProfileViewModel
            ? getViewModelProperties(personProfileViewModel)
            : {};
        const { RMS_GANG_TRACKING_ENABLED } = applicationSettings;

        return !personProfileViewModel
            ? undefined
            : {
                  fullName: viewModelProperties.fullName,
                  nicknames: joinTruthyValues(_.map(monikersByNameId(entityId), 'moniker')),
                  dob: personProfileViewModel.dateOfBirth,
                  ageRange: formatAgeRange({ ...personProfileViewModel }),
                  isMaster: viewModelProperties.isMaster,
                  personLabelNameAttributes: filter(nameAttributes, {
                      attributeType: AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
                  }),
                  isJuvenile: personProfileViewModel.isJuvenile,
                  hasPotentialActiveWarrant:
                      isProductModuleActive(ProductModuleEnum.WARRANTS.name) &&
                      currentProfileWarrantViewModels.length > 0,
                  // using a more complicated computation with HydratedPerson.personGangTrackings
                  // instead of HydratedPerson.isSuspectedGangMember because the latter boolean is
                  // a level above PersonProfile
                  isSuspectedGangMember:
                      RMS_GANG_TRACKING_ENABLED &&
                      viewModelProperties.isMaster &&
                      personGangTrackingForPersonId(entityId) &&
                      isDateInFuture(
                          get(personGangTrackingForPersonId(entityId), 'expirationDateUtc')
                      ),
                  isVulnerable: personProfileViewModel.isVulnerable,
                  dateVulnerableTo: personProfileViewModel.dateVulnerableTo,
              };
    }
);

export const entityProfileIsLoadingSelector = createSelector(
    uiSelector,
    ({ isLoading }) => isLoading
);

const baseProfilePersonsSelector = createSelector(
    uiSelector,
    elasticPersonsSelector,
    ({ personIds }, elasticPersons) => map(personIds, (personId) => elasticPersons[personId])
);
const baseProfileOrganizationsSelector = createSelector(
    uiSelector,
    elasticOrganizationsSelector,
    ({ organizationIds }, elasticOrganizations) =>
        map(organizationIds, (organizationId) => elasticOrganizations[organizationId])
);
const baseProfilePropertySelector = createSelector(
    uiSelector,
    elasticPropertySelector,
    ({ propertyIds }, elasticProperty) =>
        map(propertyIds, (propertyId) => elasticProperty[propertyId])
);
const baseProfileVehiclesSelector = createSelector(
    uiSelector,
    elasticVehiclesSelector,
    ({ vehicleIds }, elasticVehicles) => map(vehicleIds, (vehicleId) => elasticVehicles[vehicleId])
);
const baseProfileInvolvedLocationsSelector = createSelector(
    uiSelector,
    ({ involvedLocations }) => involvedLocations
);
const profileLocationEntityLinksSelector = createSelector(
    uiSelector,
    ({ locationEntityLinks }) => locationEntityLinks
);
const baseProfileWarrantsSelector = createSelector(
    uiSelector,
    elasticWarrantsSelector,
    ({ warrantIds }, elasticWarrants) => map(warrantIds, (warrantId) => elasticWarrants[warrantId])
);

const baseProfilePersonsViewModelsSelector = createPersonsResultsSelector(
    baseProfilePersonsSelector
);
const baseProfileOrganizationsViewModelsSelector = createOrganizationsResultsSelector(
    baseProfileOrganizationsSelector
);
const baseProfilePropertyViewModelsSelector = createPropertyResultsSelector(
    baseProfilePropertySelector
);
const baseProfileVehiclesViewModelsSelector = createVehiclesResultsSelector(
    baseProfileVehiclesSelector
);
const baseProfileInvolvedLocationsViewModelsSelector = createLocationsResultsSelector(
    baseProfileInvolvedLocationsSelector
);
const baseProfileWarrantsViewModelsSelector = createWarrantsResultsSelector(
    baseProfileWarrantsSelector
);

export const profilePersonsViewModelsSelector = createSelector(
    baseProfilePersonsViewModelsSelector,
    nameNameLinksDataSelector,
    currentEntityProfileIdSelector,
    formatNameNameLinkSelector,
    formatNameNameLinkWithEffectiveDatesSelector,
    applicationSettingsSelector,
    (
        baseProfilePersonsViewModels,
        nameNameLinks,
        currentEntityProfileId,
        formatNameNameLink,
        formatNameNameLinkWithEffectiveDates,
        applicationSettings
    ) =>
        map(baseProfilePersonsViewModels, (baseProfilePersonViewModel) => {
            const relatedLinks = filterNameNameLinks(
                nameNameLinks,
                currentEntityProfileId,
                baseProfilePersonViewModel.id
            );

            let linkTypes;
            if (applicationSettings.RMS_RELATIONSHIP_PREFILL_ENABLED) {
                linkTypes = relatedLinks
                    .sort(compareNameNameLinksByEffectiveDates)
                    .map((link) =>
                        formatNameNameLinkWithEffectiveDates(link, baseProfilePersonViewModel.id)
                    );
            } else {
                linkTypes = relatedLinks
                    .map((link) => formatNameNameLink(link, baseProfilePersonViewModel.id))
                    .join(', ');
            }

            return assignViewModelProperties(baseProfilePersonViewModel, {
                linkTypes,
            });
        })
);
const profileOrganizationsViewModelsSelector = createSelector(
    baseProfileOrganizationsViewModelsSelector,
    nameNameLinksDataSelector,
    currentEntityProfileIdSelector,
    formatNameNameLinkSelector,
    formatNameNameLinkWithEffectiveDatesSelector,
    applicationSettingsSelector,
    (
        baseProfileOrganizationsViewModels,
        nameNameLinks,
        currentEntityProfileId,
        formatNameNameLink,
        formatNameNameLinkWithEffectiveDates,
        applicationSettings
    ) =>
        map(baseProfileOrganizationsViewModels, (baseProfileOrganizationViewModel) => {
            const relatedLinks = filterNameNameLinks(
                nameNameLinks,
                currentEntityProfileId,
                baseProfileOrganizationViewModel.id
            );

            let linkTypes;
            if (applicationSettings.RMS_RELATIONSHIP_PREFILL_ENABLED) {
                linkTypes = relatedLinks
                    .sort(compareNameNameLinksByEffectiveDates)
                    .map((link) =>
                        formatNameNameLinkWithEffectiveDates(
                            link,
                            baseProfileOrganizationViewModel.id
                        )
                    );
            } else {
                linkTypes = relatedLinks
                    .map((link) => formatNameNameLink(link, baseProfileOrganizationViewModel.id))
                    .join(', ');
            }

            return assignViewModelProperties(baseProfileOrganizationViewModel, {
                linkTypes,
            });
        })
);
export const sortedProfileOrganizationsViewModelsSelector = createSelector(
    profileOrganizationsViewModelsSelector,
    (organizationsViewModels) =>
        _(organizationsViewModels).sortBy('updatedDateUtc').reverse().value()
);

const profilePropertyViewModelsSelector = createSelector(
    baseProfilePropertyViewModelsSelector,
    nameItemLinksSelector,
    currentEntityProfileIdSelector,
    formatNameItemAssociationAttrByNameItemLinkIdSelector,
    (
        baseProfilePropertyViewModels,
        nameItemLinks,
        currentEntityProfileId,
        formatNameItemAssociationAttrByNameItemLinkId
    ) =>
        map(baseProfilePropertyViewModels, (baseProfilePropertyViewModel) => {
            return assignViewModelProperties(baseProfilePropertyViewModel, {
                linkTypes: _(nameItemLinks)
                    .filter({
                        itemProfileId: baseProfilePropertyViewModel.id,
                        nameId: currentEntityProfileId,
                    })
                    .map(({ id }) => formatNameItemAssociationAttrByNameItemLinkId(id))
                    .join(', '),
            });
        })
);
export const sortedProfilePropertyViewModelsSelector = createSelector(
    profilePropertyViewModelsSelector,
    (propertyViewModels) => _(propertyViewModels).sortBy('updatedDateUtc').reverse().value()
);
const profileVehiclesViewModelsSelector = createSelector(
    baseProfileVehiclesViewModelsSelector,
    nameItemLinksSelector,
    currentEntityProfileIdSelector,
    formatNameItemAssociationAttrByNameItemLinkIdSelector,
    (
        baseProfileVehiclesViewModels,
        nameItemLinks,
        currentEntityProfileId,
        formatNameItemAssociationAttrByNameItemLinkId
    ) =>
        map(baseProfileVehiclesViewModels, (baseProfileVehicleViewModel) =>
            assignViewModelProperties(baseProfileVehicleViewModel, {
                linkTypes: _(nameItemLinks)
                    .filter({
                        itemProfileId: baseProfileVehicleViewModel.id,
                        nameId: currentEntityProfileId,
                    })
                    .map(({ id }) => formatNameItemAssociationAttrByNameItemLinkId(id))
                    .join(', '),
            })
        )
);
export const sortedProfileVehiclesViewModelsSelector = createSelector(
    profileVehiclesViewModelsSelector,
    (vehiclesViewModels) => _(vehiclesViewModels).sortBy('updatedDateUtc').reverse().value()
);

export const sortedProfileLocationsViewModelsSelector = createSelector(
    baseProfileInvolvedLocationsViewModelsSelector,
    profileLocationEntityLinksSelector,
    currentEntityProfileIdSelector,
    currentEntityProfileChildrenIdsSelector,
    formatFieldByNameSelector,
    (
        baseProfileInvolvedLocationsViewModels,
        locationEntityLinks,
        currentEntityProfileId,
        childrenIds,
        formatFieldByName
    ) =>
        _(sortLocationEntityLinks({ locationEntityLinks }))
            // this is because LELs for item profiles do not auto commit to master,
            // but they do for persons/orgs (tommy@mark43.com 11/3/16)
            .filter((locationEntityLink) => {
                if (locationEntityLink.entityType === EntityTypeEnum.ITEM_PROFILE.name) {
                    return includes(
                        [currentEntityProfileId, ...childrenIds],
                        locationEntityLink.entityId
                    );
                } else {
                    return currentEntityProfileId === locationEntityLink.entityId;
                }
            })
            .reduce((acc, locationEntityLink) => {
                const { linkType, locationId, subPremise } = locationEntityLink;
                const otherLocationTypesLabelMap = getOtherLocationTypesLabelMap(formatFieldByName);
                const locationTypeLabel = includes(otherPersonLocationTypes, linkType)
                    ? otherLocationTypesLabelMap[linkType]
                    : formatLocationLinkTypeId(linkType, formatFieldByName);
                return {
                    ...acc,
                    [locationTypeLabel]: [
                        ...(acc[locationTypeLabel] || []),
                        {
                            ...find(
                                baseProfileInvolvedLocationsViewModels,
                                !!subPremise ? { id: locationId, subPremise } : { id: locationId }
                            ),
                            // We also want to store some data from the LEL into this location view model
                            locationEntityLink: pick(locationEntityLink, [
                                'createdDateUtc',
                                'enteredDateUtc',
                                'startDateUtc',
                                'endDateUtc',
                            ]),
                        },
                    ],
                };
            }, {})
);

export const sortedProfileWarrantsViewModelsSelector = createSelector(
    baseProfileWarrantsViewModelsSelector,
    (warrantViewModels) =>
        _.orderBy(
            warrantViewModels,
            [(item) => item.warrantIssuedDateUtc || '', 'updatedDateUtc'],
            ['desc', 'desc']
        )
);

export const organizationProfileHeaderDataSelector = createSelector(
    organizationProfileViewModelByIdSelector,
    sortedProfileLocationsViewModelsSelector,
    currentEntityProfileIdSelector,
    (organizationProfileViewModelById, sortedProfileLocationsViewModels, entityId) => {
        const orgProfileViewModel = organizationProfileViewModelById(entityId);
        // take either first business location (physical address) or business address (mailing address)
        const location =
            get(
                sortedProfileLocationsViewModels[
                    formatLocationLinkTypeId(LinkTypesEnum.BUSINESS_LOCATION)
                ],
                '[0]'
            ) ||
            get(
                sortedProfileLocationsViewModels[
                    formatLocationLinkTypeId(LinkTypesEnum.BUSINESS_ADDRESS)
                ],
                '[0]'
            );
        const locationViewModelProperties = getViewModelProperties(location);

        return !orgProfileViewModel
            ? undefined
            : {
                  name: orgProfileViewModel.name,
                  address: joinTruthyValues(locationViewModelProperties.lines),
                  isVerified: location ? !!locationViewModelProperties.isVerified : false,
              };
    }
);

/**
 * Link Details by Entity Id, by default will search for owners; alternative types can be searched
 * via linkTypesEnum
 *
 */
const linkDetailsByItemEntityIdSelector = createSelector(
    baseProfilePersonsViewModelsSelector,
    baseProfileOrganizationsViewModelsSelector,
    nameItemLinkViewModelsWithItemIdAndParentNameItemAssociationAttrIdSelector,
    applicationSettingsSelector,
    (
        profilePersonsViewModel,
        profileOrganizationsViewModel,
        nameItemLinkViewModelsWithItemIdAndParentNameItemAssociationAttrId,
        applicationSettings
    ) => (
        parentNameItemAssocationAttrId = globalAttributes.nameItemAssociationGlobal.owner,
        entityId
    ) =>
        _(
            nameItemLinkViewModelsWithItemIdAndParentNameItemAssociationAttrId(
                entityId,
                parentNameItemAssocationAttrId
            )
        )
            .sortBy('createdDateUtc')
            .reverse()
            .map((nameItemLink) => {
                const isSubPremiseSupportEnabled = applicationSettings.SUBPREMISE_SUPPORT_ENABLED;
                const viewModelProperties = getViewModelProperties(nameItemLink);
                const linkedProfile =
                    nameItemLink.entityType === EntityTypeEnum.PERSON_PROFILE.name
                        ? find(profilePersonsViewModel, { id: nameItemLink.nameId })
                        : find(profileOrganizationsViewModel, { id: nameItemLink.nameId });
                const profileViewModelProperties = getViewModelProperties(linkedProfile);

                return (
                    linkedProfile && {
                        // this displays the context person name and then will link to the master profile
                        name: linkedProfile.name || profileViewModelProperties.fullName, // name for orgs, fullName for people
                        nameId: nameItemLink.nameId,
                        proofOfOwnership: viewModelProperties.proofOfOwnership,
                        phone: linkedProfile.phoneNumbers[0],
                        morePhoneNumbersCount: linkedProfile.phoneNumbers.length - 1,
                        address:
                            linkedProfile.involvedLocations[0] &&
                            buildElasticLocationLines(linkedProfile.involvedLocations[0], {
                                isSubPremiseSupportEnabled,
                            }),
                        moreLocationsCount: linkedProfile.involvedLocations.length - 1,
                        isOrg: nameItemLink.entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name,
                        permissionSet: linkedProfile.permissionSet,
                        dateEffectiveFrom: nameItemLink.dateEffectiveFrom,
                        dateEffectiveTo: nameItemLink.dateEffectiveTo,
                    }
                );
            })
            .compact()
            .value()
);

export const propertyDetailsSelector = createSelector(
    firearmViewModelByIdSelector,
    itemProfileViewModelByIdSelector,
    currentEntityProfileIdSelector,
    currentEntityProfileItemTypeSelector,
    linkDetailsByItemEntityIdSelector,
    legacyEntityDetailsWhereSelector,
    profileImagesByEntityIdSelector,
    profileAttachmentsByEntityIdSelector,
    formatUserByIdSelector,
    currentDepartmentDateFormatterSelector,
    (
        firearmViewModelById,
        itemProfileViewModelById,
        entityId,
        itemType,
        linkDetailsByItemEntityId,
        legacyEntityDetailsWhere,
        profileImagesByEntityId,
        profileAttachmentsByEntityId,
        formatUserById,
        dateTimeFormatter
    ) => {
        const firearmViewModel = firearmViewModelById(entityId);
        const itemViewModel = itemProfileViewModelById(entityId);
        const profileImageId = get(profileImagesByEntityId(entityId)[0], 'id');
        const propertyViewModel = firearmViewModel || itemViewModel || {};
        const profileAttachments = map(profileAttachmentsByEntityId(entityId), (attachment) => {
            return convertAttachmentToImageGalleryViewModel(
                attachment,
                formatUserById,
                propertyViewModel.description,
                dateTimeFormatter
            );
        });

        return {
            itemType,
            firearmDetails: firearmViewModel || {},
            itemDetails: itemViewModel || {},
            ownerDetails: linkDetailsByItemEntityId(
                globalAttributes.nameItemAssociationGlobal.owner,
                entityId
            ),
            claimantDetails: linkDetailsByItemEntityId(
                globalAttributes.nameItemAssociationGlobal.claimant,
                entityId
            ),
            collectorDetails: linkDetailsByItemEntityId(
                globalAttributes.nameItemAssociationGlobal.collector,
                entityId
            ),
            genericAssociationDetails: linkDetailsByItemEntityId(
                globalAttributes.nameItemAssociationGlobal.generic,
                entityId
            ),
            legacyEntityDetails: legacyEntityDetailsWhere({
                entityType: EntityTypeEnum.ITEM_PROFILE.name,
                entityId,
            }),
            profileImageId,
            profileAttachments,
        };
    }
);

export const propertyHeaderDataSelector = createSelector(
    firearmViewModelByIdSelector,
    itemProfileViewModelByIdSelector,
    currentEntityProfileItemTypeSelector,
    currentEntityProfileIdSelector,
    (firearmViewModelById, itemProfileViewModelById, itemType, entityId) => {
        const viewModel =
            itemType === globalAttributes.itemType.firearm
                ? firearmViewModelById(entityId)
                : itemProfileViewModelById(entityId);

        return {
            title: formatTitleForPropertyRecord(viewModel),
        };
    }
);

export const vehicleDetailsSelector = createSelector(
    vehicleViewModelByIdSelector,
    currentEntityProfileIdSelector,
    linkDetailsByItemEntityIdSelector,
    legacyEntityDetailsWhereSelector,
    profileImagesByEntityIdSelector,
    profileAttachmentsByEntityIdSelector,
    formatUserByIdSelector,
    currentDepartmentDateFormatterSelector,
    (
        vehicleViewModelById,
        entityId,
        linkDetailsByItemEntityId,
        legacyEntityDetailsWhere,
        profileImagesByEntityId,
        profileAttachmentsByEntityId,
        formatUserById,
        dateTimeFormatter
    ) => {
        const profileImageId = get(profileImagesByEntityId(entityId)[0], 'id');
        const vehicleViewModel = vehicleViewModelById(entityId) || {};

        const profileAttachments = map(profileAttachmentsByEntityId(entityId), (attachment) => {
            return convertAttachmentToImageGalleryViewModel(
                attachment,
                formatUserById,
                vehicleViewModel.description,
                dateTimeFormatter
            );
        });

        return {
            vehicleViewModel,
            ownerDetails: linkDetailsByItemEntityId(
                globalAttributes.nameItemAssociationGlobal.owner,
                entityId
            ),
            claimantDetails: linkDetailsByItemEntityId(
                globalAttributes.nameItemAssociationGlobal.claimant,
                entityId
            ),
            collectorDetails: linkDetailsByItemEntityId(
                globalAttributes.nameItemAssociationGlobal.collector,
                entityId
            ),
            genericAssociationDetails: linkDetailsByItemEntityId(
                globalAttributes.nameItemAssociationGlobal.generic,
                entityId
            ),
            legacyEntityDetails: legacyEntityDetailsWhere({
                entityType: EntityTypeEnum.ITEM_PROFILE.name,
                entityId,
            }),
            profileImageId,
            profileAttachments,
        };
    }
);

export const vehicleHeaderDataSelector = createSelector(
    vehicleViewModelByIdSelector,
    currentEntityProfileIdSelector,
    formatTitleForVehicleSelector,
    itemAttributesWhereSelector,
    formatAttributeByIdSelector,
    (
        vehicleViewModelById,
        entityId,
        formatTitleForVehicle,
        itemAttributesWhere,
        formatAttributeById
    ) => {
        const vehicleViewModel = vehicleViewModelById(entityId) || {};
        const viewModelProperties = getViewModelProperties(vehicleViewModel);

        const vehicleAdditionalSecondaryColors = itemAttributesWhere({
            itemProfileId: entityId,
            attributeType: AttributeTypeEnum.ITEM_COLOR.name,
        });
        const additionalSecondaryColors = map(vehicleAdditionalSecondaryColors, ({ attributeId }) =>
            formatAttributeById(attributeId)
        ).join(', ');
        const joinedPrimaryAndSecondaryColor = joinTruthyValues(
            [
                viewModelProperties.primaryColorAttrId,
                viewModelProperties.secondaryColorAttrId,
                additionalSecondaryColors,
            ],
            ' & '
        );

        return {
            title: formatTitleForVehicle(vehicleViewModel),
            color: joinedPrimaryAndSecondaryColor,
            state: viewModelProperties.registrationStateAbbrev,
            tag: vehicleViewModel.tag,
        };
    }
);

export const transitionWarrantsSelector = createSelector(
    uiSelector,
    ({ transitionWarrants }) => transitionWarrants
);

export const staffRemarksDataSelector = createSelector(
    currentEntityProfileIdSelector,
    chainOfCustodyViewModelsForMasterItemIdSelector,
    formatRenSequenceNumberSelector,
    itemProfilesSelector,
    staffRemarksByEntitySelector,
    (
        masterItemId,
        chainOfCustodyViewModelsForMasterItemId,
        formatRenSequenceNumber,
        itemProfiles,
        staffRemarksByEntity
    ) =>
        map(chainOfCustodyViewModelsForMasterItemId(masterItemId), ({ custodialReportId }) => {
            const itemProfileId = chain(custodialReportId)
                .thru((custodialReportId) =>
                    filter(
                        itemProfiles,
                        ({ ownerId, ownerType }) =>
                            ownerId === custodialReportId &&
                            ownerType === EntityTypeEnum.REPORT.name
                    )
                )
                .filter({ masterItemId })
                .map('id')
                .head()
                .value();

            return {
                custodialReportId,
                itemProfileId,
                itemRemarks: staffRemarksByEntity(ITEM_PROFILE.name, itemProfileId),
                renSequenceNumber: formatRenSequenceNumber(masterItemId, custodialReportId),
                reportRemarks: staffRemarksByEntity(REPORT.name, custodialReportId),
            };
        })
);

// REDUCER
function entityProfileReportsUiReducer(
    state = {
        ids: [],
        pagination: {
            loading: false,
            from: undefined,
            size: undefined,
            totalCount: undefined,
            latestFetchDateUtc: undefined,
            fetchErrorMessage: '',
        },
    },
    action
) {
    switch (action.type) {
        case LOAD_ENTITY_PROFILE_REPORTS_START:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    loading: true,
                    fetchErrorMessage: '',
                },
            };
        case LOAD_ENTITY_PROFILE_REPORTS_FAILURE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    loading: false,
                    fetchErrorMessage: action.payload.errorMessage,
                },
            };
        case LOAD_ENTITY_PROFILE_REPORTS_SUCCESS:
            return {
                ...state,
                ids: map(action.payload.reports, 'id'),
                pagination: {
                    ...state.pagination,
                    loading: false,
                    from: action.payload.from,
                    size: action.payload.size,
                    totalCount: action.payload.totalCount,
                    latestFetchDateUtc: nowUtc(),
                },
            };
        default:
            return state;
    }
}

function entityProfileBookingsUiReducer(
    state = {
        pagination: {
            loading: false,
            from: undefined,
            size: undefined,
            totalCount: undefined,
            fetchErrorMessage: '',
        },
    },
    action
) {
    switch (action.type) {
        case LOAD_ENTITY_PROFILE_BOOKINGS_START:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    loading: true,
                    fetchErrorMessage: '',
                },
            };
        case LOAD_ENTITY_PROFILE_BOOKINGS_FAILURE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    loading: false,
                    fetchErrorMessage: action.payload.errorMessage,
                },
            };
        case LOAD_ENTITY_PROFILE_BOOKINGS_SUCCESS:
            return {
                ...state,
                bookings: action.payload.bookings,
                pagination: {
                    ...state.pagination,
                    loading: false,
                    from: action.payload.from,
                    size: action.payload.size,
                    totalCount: action.payload.totalCount,
                },
            };
        default:
            return state;
    }
}

function entityProfilesUiReducer(
    state = {
        isLoading: false,
        currentEntityProfileItemType: undefined,
        currentEntityProfileId: undefined,
        locationIds: [],
        personIds: [],
        organizationIds: [],
        propertyIds: [],
        vehicleIds: [],
        warrantIds: [],
        transitionWarrants: false,
        childrenIds: [],
        linkedMasterProfileIds: [],
    },
    action
) {
    switch (action.type) {
        case INITIALIZE_ENTITY_PROFILE:
            return {
                ...state,
                isLoading: true,
                transitionWarrants: false,
            };
        case LOAD_ENTITY_PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                // convert router param (e.g. 'persons') to enum value (e.g.
                // 'PERSON_PROFILE'); this operation is not 1-to-1 because
                // vehicles and property are the same entity type
                // ('ITEM_PROFILE')
                currentEntityProfileType: entityTypeParamToEnum[action.payload.entityType],
                // parseInt is required because this data comes from router
                // params which are always strings
                currentEntityProfileId: parseInt(action.payload.entityId),
                currentEntityProfileMasterProfileId: get(
                    action.payload,
                    'entityProfile.masterProfileId'
                ),
                currentEntityProfileOwnerType: get(
                    action.payload,
                    'entityProfile.profile.ownerType'
                ),
                currentEntityProfileOwnerId: get(action.payload, 'entityProfile.profile.ownerId'),
                currentEntityProfileOwnerTitle: get(action.payload, 'entityProfile.ownerTitle'),
                childrenIds: get(action.payload, 'entityProfile.childrenIds'),
                linkedMasterProfileIds: get(action.payload, 'entityProfile.linkedMasterProfileIds'),
                currentEntityProfileItemType: get(action.payload, 'entityProfile.profile.vehicle')
                    ? globalAttributes.itemType.vehicle
                    : get(action.payload, 'entityProfile.profile.item')
                    ? get(action.payload, 'entityProfile.profile.item.itemTypeAttrId')
                    : undefined,
            };
        case TRANSITION_WARRANTS_CONTAINER:
            return {
                ...state,
                transitionWarrants: true,
            };
        case LOAD_ENTITY_PROFILE_LOCATIONS_SUCCESS:
            return {
                ...state,
                involvedLocations: action.payload.involvedLocations,
                locationEntityLinks: action.payload.locationEntityLinks,
            };
        case LOAD_ENTITY_PROFILE_PERSONS_SUCCESS:
            return {
                ...state,
                personIds: map(action.payload, 'id'),
            };
        case LOAD_ENTITY_PROFILE_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                organizationIds: map(action.payload, 'id'),
            };
        case LOAD_ENTITY_PROFILE_PROPERTY_SUCCESS:
            return {
                ...state,
                propertyIds: map(action.payload, 'id'),
            };
        case LOAD_ENTITY_PROFILE_VEHICLES_SUCCESS:
            return {
                ...state,
                vehicleIds: map(action.payload, 'id'),
            };
        case LOAD_ENTITY_PROFILE_WARRANTS_SUCCESS:
            return {
                ...state,
                warrantIds: map(action.payload, 'id'),
            };
        case LOAD_ENTITY_PROFILE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

export const entityTitleSelector = createSelector(
    propertyHeaderDataSelector,
    vehicleHeaderDataSelector,
    personHeaderDataSelector,
    organizationProfileHeaderDataSelector,
    (propertyHeaderData, vehicleHeaderData, personHeaderData, organizationProfileHeaderData) => (
        entityType
    ) => {
        switch (entityTypeSectionToUsageActionEnum[entityType]) {
            case UsageActionEnum.VIEWED_ORGANIZATION_PROFILE.name:
                return organizationProfileHeaderData.name;
            case UsageActionEnum.VIEWED_VEHICLE_PROFILE.name:
                return vehicleHeaderData.title;
            case UsageActionEnum.VIEWED_PROPERTY_PROFILE.name:
                return propertyHeaderData.title;
            case UsageActionEnum.VIEWED_PERSON_PROFILE.name:
                return personHeaderData.fullName;
            default:
                return '';
        }
    }
);

export default combineReducers({
    ui: makeResettable(INITIALIZE_ENTITY_PROFILE, entityProfilesUiReducer),
    reports: makeResettable(INITIALIZE_ENTITY_PROFILE, entityProfileReportsUiReducer),
    bookings: makeResettable(INITIALIZE_ENTITY_PROFILE, entityProfileBookingsUiReducer),
});

import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import ItemInfo from '../../core/components/ItemInfo';

const InventoryItemIdentifierCell = (inventoryItem) => {
    const viewModel = getViewModelProperties(inventoryItem);

    return (
        <ItemInfo disableLink={true} viewModel={viewModel} width={300} showHighRiskLabel={true} />
    );
};

export default InventoryItemIdentifierCell;

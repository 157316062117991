import _, { map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Stack, Flex, Text, Icon } from 'arc';
import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { savedSearchRouteMap } from '~/client-common/configs/savedSearchConfig';
import useFields, {
    useCaseFieldName,
    useEvidenceModuleName,
} from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';

import Link from '../../core/components/links/Link';
import { savedSearchTypeMap, loadRecentSavedSearches } from '../state/ui';

import { NoDataBlock, DashboardSideBarCard } from './DashboardSection';
import { DashboardLeftSidebarLoader } from './DashboardLoaders';

const strings = componentStrings.personalDashboard.RecentSavedSearches;

const DEFAULT_SAVED_SEARCH_NAME_REGEX = /^SAVED SEARCH \d\d\/\d\d\/\d{4} \d\d:\d\d:\d\d:\d{5}$/;

const StyledLink = styled(Link)`
    padding: var(--arc-space-1) var(--arc-space-2);
    border-radius: var(--arc-radii-base);
    color: var(--arc-colors-icon-default);

    &:hover {
        background-color: var(--arc-colors-interactive-hover);
    }
`;

const getLinkTitleBySearchType = (searchType, { evidenceModule, caseModule, organization }) => {
    switch (searchType) {
        case ElasticSearchTypeEnum.EVIDENCE_ITEM.name:
            return strings.searchTypes[searchType](evidenceModule);
        case ElasticSearchTypeEnum.CASE.name:
        case ElasticSearchTypeEnum.CASE_ASSIGNED_TO_ME.name:
            return strings.searchTypes[searchType](caseModule);
        case ElasticSearchTypeEnum.ORGANIZATION.name:
            return strings.searchTypes[searchType](organization);
        default:
            return strings.searchTypes[searchType];
    }
};

function SavedSearch({ savedSearchViewModel }) {
    const evidenceModuleName = useEvidenceModuleName();
    const { pluralCaseFieldName: caseModuleName } = useCaseFieldName();
    const organizationLabel = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];

    const { id, name, searchType } = savedSearchViewModel;
    const { elasticQueryDisplay } = getViewModelProperties(savedSearchViewModel);
    // safety check in case of bad data
    if (!name && !elasticQueryDisplay) {
        return <div />;
    }

    // `name` is either what the user optionally typed in to label this saved search, or a timestamp
    // by default. `elasticQueryDisplay` has all the field values being searched for.
    const displayString =
        DEFAULT_SAVED_SEARCH_NAME_REGEX.test(name) && elasticQueryDisplay
            ? elasticQueryDisplay
            : name;

    return (
        <StyledLink
            to={{
                pathname: savedSearchRouteMap[searchType],
                query: { savedSearchId: id },
            }}
        >
            <Flex align="flex-start" gap="var(--arc-space-2)">
                <Icon
                    icon="Search"
                    color="var(--arc-colors-brand-default)"
                    style={{ marginTop: 'var(--arc-space-1)' }}
                />
                <Text>
                    {getLinkTitleBySearchType(searchType, {
                        evidenceModule: evidenceModuleName,
                        caseModule: caseModuleName,
                        organization: organizationLabel,
                    })}
                    {': '}
                    {displayString}
                </Text>
            </Flex>
        </StyledLink>
    );
}

class RecentSavedSearches extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            savedSearchIds: [],
            error: false,
            loading: true,
        };
    }

    componentDidMount() {
        this.props
            .loadRecentSavedSearches({
                hideLoadingBar: true,
            })
            .then((savedSearchIds) => {
                this.setState({
                    savedSearchIds,
                    error: false,
                    loading: false,
                });
            })
            .catch(() => {
                this.setState({
                    error: true,
                    loading: false,
                });
            });
    }

    render() {
        let content;
        if (this.state.loading) {
            content = <DashboardLeftSidebarLoader />;
        } else if (this.state.error) {
            content = <NoDataBlock>{strings.loadError}</NoDataBlock>;
        } else if (this.state.savedSearchIds.length === 0) {
            content = <NoDataBlock>{strings.noResults}</NoDataBlock>;
        } else {
            content = map(this.state.savedSearchIds, (savedSearchId) => {
                const savedSearchViewModel = this.props.savedSearch.savedSearchViewModels[
                    savedSearchId
                ];
                return savedSearchViewModel ? (
                    <SavedSearch key={savedSearchId} savedSearchViewModel={savedSearchViewModel} />
                ) : undefined;
            }).filter((component) => !!component);
        }

        return (
            <DashboardSideBarCard
                dragHandleProps={this.props.dragHandleProps}
                isDragging={this.props.isDragging}
                title={strings.title}
                padding="var(--arc-space-2)"
            >
                <Stack spacing="0">{content}</Stack>
            </DashboardSideBarCard>
        );
    }
}

const savedSearchSelector = createSelector(
    map(savedSearchTypeMap, ({ module }) => module.selectors.savedSearchesViewModelsSelector),
    (...savedSearchViewModelsInArrays) => ({
        savedSearchViewModels: _(savedSearchViewModelsInArrays)
            .flatten()
            .mapKeys(({ id }) => id)
            .value(),
    })
);

const mapStateToProps = createStructuredSelector({
    savedSearch: savedSearchSelector,
});

const mapDispatchToProps = {
    loadRecentSavedSearches,
};

/**
 * This section on the Personal Dashboard shows the saved searches most recently executed by the
 *   current user.
 */
export default connect(mapStateToProps, mapDispatchToProps)(RecentSavedSearches);

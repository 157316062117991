import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import Button, { ButtonProps, buttonTypes } from '../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../legacy-redux/components/core/Icon';

type ValueOf<T> = T[keyof T];

type ComplianceInlineButtonProps = {
    buttonType: ValueOf<typeof buttonTypes>;
    iconType: ValueOf<typeof iconTypes>;
    iconSize?: string;
    className?: string;
} & ButtonProps;

const BaseComplianceInlineButton: React.FC<ComplianceInlineButtonProps> = ({
    buttonType,
    iconType,
    iconSize = '20px',
    className,
    ...rest
}) => (
    <Button
        {...rest}
        className={classNames(buttonType, className)}
        iconLeft={<Icon type={iconType} fontSize={iconSize} />}
    />
);

const ComplianceInlineButton = styled(BaseComplianceInlineButton)`
    float: none;
    display: inline-block;

    &:last-child {
        margin-right: 0;
    }

    & i::before {
        font-size: ${(props) => props.iconSize || '20px'} !important;
    }
`; // have to use !important here because this style gets overridden by legacy css

export default ComplianceInlineButton;

import React from 'react';
import { useSelector } from 'react-redux';
import { EsriConfiguration } from '~/client-common/core/maps/components/EsriConfiguration';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

export function EsriConfigurationWrapper({
    children,
}: React.PropsWithChildren<Record<never, never>>) {
    const applicationSettings = useSelector(applicationSettingsSelector);

    return (
        <EsriConfiguration
            apiKey={
                applicationSettings.ESRI_MAPS_API_KEY
                    ? String(applicationSettings.ESRI_MAPS_API_KEY)
                    : undefined
            }
        >
            {children}
        </EsriConfiguration>
    );
}

import { OffenseResource, OffenseCaseStatus } from '@mark43/rms-api';
import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';

export default createResource({
    name: 'Offense Case Status Resource',
    methods: {
        getOffenseCaseStatusesForReportingEventId(reportingEventId: number) {
            return req<OffenseResource.GetOffenseCaseStatusesForReportingEventId>({
                method: 'GET',
                url: `offense/case_status/${reportingEventId}/`,
            });
        },
        upsertOffenseCaseStatuses(
            reportingEventId: number,
            offenseCaseStatuses: OffenseCaseStatus[]
        ) {
            return req<OffenseResource.UpdateOffenseCaseStatusesForReportingEventId>({
                method: 'PUT',
                url: `offense/case_status/${reportingEventId}/`,
                data: offenseCaseStatuses,
            });
        },
    },
});

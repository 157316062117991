import React from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    useBreakpointValue,
    Drawer,
    DrawerContent,
    DrawerBody,
    DrawerHeader,
} from 'arc';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import QuickSearch from '../../../../search/quick-search/components/UpdatedQuickSearch';
import { NavItemToggle, handleNavToggle } from '../../../../core/components/Navigation/components';
import testIds from '../../../../../core/testIds';

const { quickSearch: quickSearchStrings } = componentStrings;

const StyledDrawerBody = styled(DrawerBody)`
    padding: 0;
`;

export const QuickSearchNavItem = ({ isActive = false }: { isActive: boolean }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const popoverTriggerRef = React.useRef(null);

    function closeQuickSearch() {
        setIsOpen(false);
    }
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });

    const navItem = (
        <NavItemToggle
            onClick={() => setIsOpen((prevState) => !prevState)}
            label="Search"
            icon="Search"
            data-test-id={testIds.QUICK_SEARCH_BUTTON}
            isActive={isOpen || isActive}
        />
    );

    if (isMobile) {
        return (
            <>
                {navItem}
                <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <DrawerContent>
                        <DrawerHeader>
                            <>{quickSearchStrings.generic.title}</>
                        </DrawerHeader>
                        <StyledDrawerBody>
                            <QuickSearch onClose={closeQuickSearch} />
                        </StyledDrawerBody>
                    </DrawerContent>
                </Drawer>
            </>
        );
    } else {
        return (
            <Popover isOpen={isOpen}>
                <PopoverTrigger asChild ref={popoverTriggerRef}>
                    {navItem}
                </PopoverTrigger>
                <PopoverContent
                    onInteractOutside={(e) =>
                        handleNavToggle(e, popoverTriggerRef, closeQuickSearch)
                    }
                    onEscapeKeyDown={closeQuickSearch}
                    side="right"
                    align="start"
                    hasPadding={false}
                >
                    <QuickSearch onClose={closeQuickSearch} />
                </PopoverContent>
            </Popover>
        );
    }
};

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import type { ItemType } from './broadcast';

export function getItemTypeForItemEvent(itemTypeAttrId: number): ItemType {
    return itemTypeAttrId === globalAttributes.itemType.vehicle
        ? 'VEHICLE'
        : itemTypeAttrId === globalAttributes.itemType.firearm
        ? 'FIREARM'
        : 'ITEM_PROFILE';
}

import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import withCard from '../../utils/withCard';
import propertyCard from '../../state/ui/propertyCard';
import { openManageItemsSidePanel } from '../../state/ui';
import testIds from '../../../../../core/testIds';
import { registerCard } from '../../utils/cardsRegistry';
import ItemCard from './ItemCard';

const mapDispatchToProps = (dispatch) => ({
    onAdd: ({ ownerType, ownerId, reportingEventNumber }) =>
        dispatch(
            openManageItemsSidePanel({
                ownerType,
                ownerId,
                isVehicle: false,
                limitToOne: false,
                reportingEventNumber,
            })
        ),
});

/**
 * Property Card in Item Entry v2.
 * @param  {number} props.ownerType The ownerType of all the property in this card. This is always
 *   'REPORT' as of Jan 2018.
 * @param  {number} props.ownerId The ownerId of all the property in this card.
 * @param  {string} props.reportingEventNumber
 */
export default compose(
    withCard(propertyCard),
    connect(null, mapDispatchToProps, null, { forwardRef: true }),
    withHandlers({
        onAdd: ({ ownerType, ownerId, reportingEventNumber, onAdd }) => () =>
            onAdd({ ownerType, ownerId, reportingEventNumber }),
        onEdit: ({ editCallback, onEdit }) => () => editCallback(() => onEdit()),
        registerCard: ({ onSave }) => () => registerCard({ cardModule: propertyCard, onSave }),
    }),
    withProps({
        isVehicle: false,
        cardId: reportCardEnum.PROPERTY.id,
        testId: testIds.PROPERTY_CARD,
    })
)(ItemCard);

import { get, map, omit, pick } from 'lodash';
import { createSelector } from 'reselect';
import {
    ElasticCourtOrderQuery,
    ElasticAttributeDetailQuery,
    DateRangeQuery,
} from '@mark43/rms-api';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import {
    formDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import { courtOrdersFieldsetViewModel } from '../../../../../legacy-redux/configs/fieldsetsConfig';

interface CourtOrderFormModel {
    arrestNumber?: string;
    courtCaseDateUtc?: DateRangeQuery;
    statuteCodeAttrId?: number;
    statuteCodeAttrDetail?: ElasticAttributeDetailQuery;
    courtCaseNumber?: string;
    courtCodeAttrId?: number;
    courtCodeAttrDetail?: ElasticAttributeDetailQuery;
    involvedPersonFullName?: string;
    modifiedDateUtc?: DateRangeQuery;
    stateIdNumber?: string;
}
export interface CourtOrdersFormModel {
    fuzzyMatchingEnabled?: boolean;
    courtOrders: CourtOrderFormModel;
}

const advancedSearchCourtOrdersFormFieldViewModels = {
    ...buildFlatFormFieldViewModels([
        // fields in the form that are not nested
        'fuzzyMatchingEnabled',
    ]),
    courtOrders: courtOrdersFieldsetViewModel,
};

export function convertAdvancedSearchCourtOrdersElasticQueryToFormModel(
    elasticQuery: Partial<ElasticCourtOrderQuery>
): CourtOrdersFormModel {
    // @ts-expect-error client-common transport to client
    return buildFormModel(
        {
            fuzzyMatchingEnabled: get(elasticQuery, 'fuzzyMatchingEnabled'),
            courtOrders: pick(elasticQuery, map(courtOrdersFieldsetViewModel.fields, 'key')),
        },
        advancedSearchCourtOrdersFormFieldViewModels
    );
}

export function convertAdvancedSearchCourtOrdersFormModelToElasticQuery(
    formModel: Partial<CourtOrdersFormModel>
): ElasticCourtOrderQuery {
    // @ts-expect-error client-common transport to client
    return filterFormData({
        fuzzyMatchingEnabled: formModel.fuzzyMatchingEnabled,
        ...formModel.courtOrders,
    });
}

export function convertAdvancedSearchCourtOrdersFormModelToFilterGroups(
    formModel: CourtOrdersFormModel,
    formatFieldValue: string,
    boundSelectors: {
        formatFieldByName: (fieldName: string) => string;
    }
) {
    return convertFormModelToFilterGroups(
        formModel,
        advancedSearchCourtOrdersFormFieldViewModels,
        formatFieldValue,
        boundSelectors.formatFieldByName
    );
}

// @ts-expect-error client-common transport to client
const advancedSearchCourtOrdersForm = createFormModule({
    formName: formClientEnum.ADVANCED_SEARCH_COURT_ORDERS,
    fieldViewModels: advancedSearchCourtOrdersFormFieldViewModels,
    convertToFormModel: convertAdvancedSearchCourtOrdersElasticQueryToFormModel,
    convertFromFormModel: convertAdvancedSearchCourtOrdersFormModelToElasticQuery,
});

// @ts-expect-error client-common transport to client
advancedSearchCourtOrdersForm.selectors.formIsEmptySelector = createSelector(
    // @ts-expect-error client-common transport to client
    advancedSearchCourtOrdersForm.selectors.formModelSelector,
    (formModel: CourtOrdersFormModel) => formDataIsEmpty(omit(formModel, 'fuzzyMatchingEnabled'))
);

export default advancedSearchCourtOrdersForm;

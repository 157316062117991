import { ElasticReportDefinitionQuery, SearchQueryElasticReportQuery } from '@mark43/rms-api';

export function getReportDefinitionQueriesWithConfiguredEntityQueriesFromElasticReportQueries(
    queries: SearchQueryElasticReportQuery[]
): ElasticReportDefinitionQuery[] {
    return queries.flatMap((query) =>
        (query.elasticQuery.reportDefinitions || []).filter((query) => {
            return !!query.configuredEntities?.length;
        })
    );
}

import { useSelector } from 'react-redux';
import { RefContextEnumType } from '@mark43/rms-api';

import { isFieldStaticRequiredSelector } from '../../arbiter/state/ui';

type UseStaticFieldRequiredParams = {
    disabled?: boolean;
    value?: string;
    context: RefContextEnumType;
    fieldConfigurationId: number;
};

export function useStaticFieldRequired(params: UseStaticFieldRequiredParams): boolean {
    const { context, disabled, fieldConfigurationId } = params;
    const isFieldStaticRequired = useSelector(isFieldStaticRequiredSelector);

    if (disabled) {
        return false;
    }

    return isFieldStaticRequired(fieldConfigurationId, context);
}

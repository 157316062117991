import _ from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import { normalize } from '~/client-common/helpers/dataHelpers';
import { getAdminListStatusFromStartEnd } from '~/client-common/core/dates/utils/dateHelpers';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import {
    callForServicesSelector,
    callForServiceByIdSelector,
} from '~/client-common/core/domain/call-for-services/state/data';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import {
    processHistoryEvent,
    processChangeSet,
} from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { currentDepartmentDateFormatsSelector } from '~/client-common/core/domain/current-user/state/ui';
import callForServiceForm from '../forms/callForServiceForm';
import authActionTypes from '../../../../../legacy-redux/actions/types/authActionTypes';
import {
    CALL_FOR_SERVICE_PAGE_LOAD_START,
    CALL_FOR_SERVICE_PAGE_LOAD_SUCCESS,
    SAVE_CALL_FOR_SERVICE_START,
    SAVE_CALL_FOR_SERVICE_SUCCESS,
    SAVE_CALL_FOR_SERVICE_FAILURE,
    FETCH_CALL_FOR_SERVICE_HISTORY_SUCCESS,
    FETCH_ALL_CALL_FOR_SERVICE_HISTORY_SUCCESS,
} from '../data';
import { routeNameSelector } from '../../../../../routing/routerModule';
import routesConfig from '../../../../../routing/routesConfig';
import { formatUserByIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';

const { BOOTSTRAP_SUCCESS } = authActionTypes;
const initialUiState = {
    isAgencyCombination: false,
    selectedCallForServiceId: null,
    agencies: {}, // not the official store so keeping it in UI state
    pageLoadError: false,
    saving: false,
    savingError: null,
    saveSuccess: null,
    histories: {},
    allHistories: [], // this is a stop-gap solution. Normally we would normalize all of these by cfs id or something
    // but there is no consistent way to discern the cfs id in history objects.
};

const SELECT_CALL_FOR_SERVICE_START = 'callForServices/SELECT_CALL_FOR_SERVICE_START';
const SELECT_CALL_FOR_SERVICE_SUCCESS = 'callForServices/SELECT_CALL_FOR_SERVICE_SUCCESS';
const SELECT_CALL_FOR_SERVICE_FAILURE = 'callForServices/SELECT_CALL_FOR_SERVICE_FAILURE';
const RESET_CALL_FOR_SERVICE_ADMIN_PAGE = 'callForServices/RESET_CALL_FOR_SERVICE_ADMIN_PAGE';
const UPDATE_IS_AGENCY_COMBINATION = 'callForServices/UPDATE_IS_AGENCY_COMBINATION';

// SELECTORS

export const uiSelector = (state) => state.ui.callForServiceAdmin;

const callForServiceAgenciesSelector = (state) => state.ui.callForServiceAdmin.agencies;

const adminListSelector = createSelector(
    callForServicesSelector,
    routeNameSelector,
    (callForServices, routeName) => {
        return _(callForServices)
            .sortBy('callForService.description')
            .map((cfs) => {
                const historyView =
                    routeName === routesConfig.ADMIN_CALL_FOR_SERVICE_HISTORY.name ||
                    routeName === routesConfig.ADMIN_ALL_CALL_FOR_SERVICES_HISTORY.name;
                const path = `/admin/cad/call-for-service/${cfs.callForService.id}${
                    historyView ? '/history' : ''
                }`;

                let subtitle = '';

                if (cfs.callForServiceDetails.length === 1) {
                    const agencyTypeGlobalAttrId =
                        cfs.callForServiceDetails[0].agencyTypeGlobalAttrId;

                    if (agencyTypeGlobalAttrId === globalAttributes.agencyTypeGlobal.police) {
                        subtitle = 'POLICE';
                    } else if (agencyTypeGlobalAttrId === globalAttributes.agencyTypeGlobal.fire) {
                        subtitle = 'FIRE';
                    } else if (
                        agencyTypeGlobalAttrId === globalAttributes.agencyTypeGlobal.medical
                    ) {
                        subtitle = 'EMS';
                    }
                } else if (cfs.callForServiceDetails.length > 1) {
                    subtitle = 'COMBINED';
                }

                return {
                    path,
                    title: cfs.callForService.description,
                    subtitle,
                    key: cfs.callForService.id,
                    status: getAdminListStatusFromStartEnd(
                        cfs.callForService.activeDateUtc,
                        cfs.callForService.expirationDateUtc
                    ),
                    selected: false,
                };
            })
            .value();
    }
);

export const adminListSelectorWithSelected = createSelector(
    [adminListSelector, uiSelector],
    (list, ui) => {
        return _.map(list, (cfs) => {
            const selected = ui.selectedCallForServiceId === cfs.key;
            cfs.selected = selected;
            return cfs;
        });
    }
);

export const selectedCallForServiceSelector = createSelector(
    [callForServicesSelector, uiSelector],
    (callForServices, ui) => {
        const cfs = ui.selectedCallForServiceId && callForServices[ui.selectedCallForServiceId];
        const callForServiceDetails = cfs ? cfs.callForServiceDetails : null;

        if (cfs) {
            return {
                ...cfs.callForService,
                callForServiceDetails,
                status: getAdminListStatusFromStartEnd(
                    cfs.callForService.activeDateUtc,
                    cfs.callForService.expirationDateUtc
                ),
            };
        }

        return null;
    }
);

export const selectedCallForServiceIdSelector = createSelector(
    uiSelector,
    (ui) => ui.selectedCallForServiceId
);

export const callForServiceSavingErrorSelector = createSelector(uiSelector, (ui) => ui.savingError);

export const isAgencyCombinationSelector = createSelector(
    uiSelector,
    (ui) => ui.isAgencyCombination
);

export const callForServiceAgencyOptions = createSelector(
    callForServiceAgenciesSelector,
    (agenciesById) => {
        return _.map(agenciesById, (agencyProfile) => {
            return {
                display: agencyProfile.agencyName,
                value: agencyProfile.id,
            };
        });
    }
);

export const genericCFSDropdownOptions = createSelector(
    callForServicesSelector,
    uiSelector,
    (callForServices, ui) => {
        return _(callForServices)
            .filter((cfs) => {
                return (
                    cfs.callForService.attributes &&
                    cfs.callForService.attributes.length > 0 &&
                    cfs.callForService.id !== ui.selectedCallForServiceId
                );
            })
            .map((cfs) => {
                return {
                    display: cfs.callForService.description,
                    value: cfs.callForService.id,
                };
            })
            .value();
    }
);

export const cfsOptionsForAgencyType = (agencyTypeId, callForServices) => {
    return _(callForServices)
        .filter((cfs) => {
            const details = _.get(cfs, 'callForServiceDetails', []);
            return details.length === 1 && details[0].agencyTypeGlobalAttrId === agencyTypeId;
        })
        .map((cfs) => {
            const isActive = _.get(cfs, 'callForService.expirationDateUtc')
                ? moment().isBefore(_.get(cfs, 'callForService.expirationDateUtc'))
                : true;

            return {
                display: cfs.callForService.description,
                value: cfs.callForService.id,
                noteDisplay: isActive ? '' : '(expired)',
            };
        })
        .value();
};

export const historiesForCallForServiceIdSelector = createSelector(
    uiSelector,
    formatAttributeByIdSelector,
    formatUserByIdSelector,
    getAttributeByIdSelector,
    currentDepartmentDateFormatsSelector,
    (ui, formatAttributeById, userById, attributeById, dateTimeFormats) => (id) => {
        const histories = id ? ui.histories[id] : ui.allHistories;
        const displayDate = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDate);
            }
            return '';
        };

        const displayDateTime = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDateTime);
            }
            return '';
        };

        const args = {
            formatAttributeById,
            userById,
            displayDate,
            displayDateTime,
            attributeById,
        };

        return _.map(_.sortBy(histories, 'timestampUtc'), (history) => {
            const processed = processHistoryEvent(history, args);
            const newObj = {
                ...processed,
                user: userById(processed.changedBy),
                changes: _.map(processed.changeSet, (changeSet) =>
                    processChangeSet(changeSet, args, processed)
                ),
            };

            return newObj;
        }).reverse();
    }
);

// ACTION CREATORS
function selectCallForServiceStart() {
    return { type: SELECT_CALL_FOR_SERVICE_START };
}

function selectCallForServiceSuccess(callForServiceId) {
    return {
        type: SELECT_CALL_FOR_SERVICE_SUCCESS,
        payload: callForServiceId,
    };
}

function selectCallForServiceFailure(errorMessage) {
    return {
        type: SELECT_CALL_FOR_SERVICE_FAILURE,
        payload: errorMessage,
    };
}

export function resetCallForServiceAdminPage() {
    return { type: RESET_CALL_FOR_SERVICE_ADMIN_PAGE };
}

export function updateIsAgencyCombination(isAgencyCombination) {
    return {
        type: UPDATE_IS_AGENCY_COMBINATION,
        payload: { isAgencyCombination },
    };
}

// REDUCERS

function callForServiceAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case BOOTSTRAP_SUCCESS:
            return {
                ...state,
                agencies: normalize(action.payload.userData.departmentAgencies),
            };
        case CALL_FOR_SERVICE_PAGE_LOAD_START:
            return {
                ...state,
            };

        case CALL_FOR_SERVICE_PAGE_LOAD_SUCCESS:
            return {
                ...state,
                callForServices: normalize(action.payload.callForServices),
            };
        case SELECT_CALL_FOR_SERVICE_SUCCESS:
            return {
                ...state,
                saveSuccess: null,
                savingError: null,
                selectedCallForServiceId: action.payload ? action.payload.id : null,
            };
        case SAVE_CALL_FOR_SERVICE_START:
            return {
                ...state,
                saveSuccess: null,
                saving: true,
            };
        case SAVE_CALL_FOR_SERVICE_SUCCESS:
            return {
                ...state,
                savingError: null,
                saving: false,
                saveSuccess: true,
                selectedCallForServiceId: action.payload.newId,
                callForServices: {
                    ...state.callForServices,
                    ...normalize(action.payload.callForServices),
                },
            };
        case SAVE_CALL_FOR_SERVICE_FAILURE:
            return {
                ...state,
                saveSuccess: null,
                saving: false,
                savingError: action.payload.message,
            };
        case FETCH_CALL_FOR_SERVICE_HISTORY_SUCCESS:
            return {
                ...state,
                histories: {
                    ...state.histories,
                    [action.payload.callForServiceId]: action.payload.histories,
                },
            };
        case FETCH_ALL_CALL_FOR_SERVICE_HISTORY_SUCCESS:
            return {
                ...state,
                allHistories: action.payload.histories,
            };
        case UPDATE_IS_AGENCY_COMBINATION:
            return {
                ...state,
                isAgencyCombination: action.payload.isAgencyCombination,
            };
        default:
            return state;
    }
}

export function selectCallForService(callforServiceId) {
    return (dispatch, getState) => {
        dispatch(selectCallForServiceStart());
        const state = getState();
        const id = parseInt(callforServiceId);
        const callForService = callForServiceByIdSelector(state)(id);

        if (callForService) {
            dispatch(callForServiceForm.actionCreators.change({ callForService }));
            dispatch(selectCallForServiceSuccess(callForService));
        } else {
            dispatch(selectCallForServiceFailure());
        }
    };
}

export default makeResettable(RESET_CALL_FOR_SERVICE_ADMIN_PAGE, callForServiceAdminUiReducer);

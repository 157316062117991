import keyMirror from 'keymirror';

const OverlayStateTypeEnum = keyMirror({
    CHARGES_OVERLAY: null,
    GENERATED_SEGMENTS_ERRORS_OVERLAY: null,
    LOCATION_OVERLAY: null,
    ORGANIZATION_OVERLAY: null,
    PERSON_OVERLAY: null,
    TOW_VEHICLE_CHECK_IN_OVERLAY: null,
    TOW_VEHICLE_RELEASE_OVERLAY: null,
});

export type OverlayStateTypeEnumType = keyof typeof OverlayStateTypeEnum;

export default OverlayStateTypeEnum;

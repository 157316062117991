import PropTypes from 'prop-types';
import _, { mapKeys } from 'lodash';
import { createSelector } from 'reselect';
import { routerSelector } from '../../routing/routerModule';

// this will include all properties that would ever possibly be included
// on a cobalt context... real keys would be things like "reportId", "isKnown",
// etc. (expect to add properties to this over time as necessary)
const COBALT_CONTEXT_SHAPE = mapKeys(
    {
        ROUTE_NAME: PropTypes.string,
        reportId: PropTypes.number,
        caseId: PropTypes.number,
        personProfileId: PropTypes.number,
        organizationProfileId: PropTypes.number,
        userId: PropTypes.number,
        roleId: PropTypes.number,
        integrationId: PropTypes.number,
    },
    (val, key) => mapStringToContextShapeKey(key)
);

function mapStringToContextShapeKey(str) {
    return `COBALT_CONTEXT_${str}`;
}

// this function returns an object containing all data available in our router
// state which belongs on a context (to be sent with ajax requests)
export const getRouterContext = createSelector(routerSelector, (routerState) => {
    // during app bootstrap no router state will be available - in this
    // case we return an empty object so that there aren't horrible downstream
    // effects to consumers of this function
    if (!routerState) {
        return {};
    }
    return _({ ROUTE_NAME: routerState.ROUTE_NAME })
        .assign(routerState.params)
        .omitBy((val, key) => {
            return !COBALT_CONTEXT_SHAPE[mapStringToContextShapeKey(key)];
        })
        .value();
});

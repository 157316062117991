import { internalFieldsForPhoneNumber } from '~/client-common/helpers/phoneNumberHelpers';

export const namePhoneFields = [
    'id',
    'phoneType',
    'phoneTypeOther',
    'displayNumber',
    ...internalFieldsForPhoneNumber,
    'isPrimary',
    'dateEffectiveFrom',
    'dateEffectiveTo',
    'provenance',
    'isSafeContact',
    'safeContactDetails',
];
export const nameEmailFields = [
    'id',
    'emailType',
    'emailTypeOther',
    'emailAddress',
    'dateEffectiveFrom',
    'dateEffectiveTo',
    'provenance',
    'isPrimary',
    'isSafeContact',
    'safeContactDetails',
];
export const nameIdentifierFields = [
    'id',
    'nameIdentifierTypeAttrId',
    'identifier',
    'nameIdentifierTypeOther',
];
export const nameMonikerFields = ['id', 'moniker', 'dateOfBirthOther'];
export const employmentHistoryFields = [
    'id',
    'employerName',
    'employerAddress',
    'occupation',
    'displayNumber',
    ...internalFieldsForPhoneNumber,
    'dateStart',
    'dateEnd',
];
export const militaryHistoryFields = ['id', 'militaryBranchAttrId', 'dateStart', 'dateEnd'];
export const schoolHistoryFields = [
    'id',
    'schoolName',
    'schoolAddress',
    'grade',
    'status',
    'displayNumber',
    ...internalFieldsForPhoneNumber,
];
export const identifyingMarkFields = [
    'id',
    'description',
    'bodyPartAttrId',
    'identifyingMarkTypeAttrId',
    'imageId',
];
export const addressFields = ['locationId', 'positionAttrId', 'startDateUtc', 'endDateUtc'];
export const homeAddressFields = [
    'locationId',
    'positionAttrId',
    'description',
    'startDateUtc',
    'endDateUtc',
];
export const workAddressFields = [
    'locationId',
    'location',
    'positionAttrId',
    'startDateUtc',
    'endDateUtc',
];
export const medicalTreatmentFields = [
    'medicalTreatmentAttrId',
    'medicineTransported',
    'medicalTransportationTypeAttrId',
    'ambulanceNumber',
    'takenToHospitalBy',
    'medicalFacilityAttrId',
    'hospitalTreatedAt',
    'attendingPhysicians',
];
export const emergencyContactFields = [
    'id',
    'emergencyContactName',
    'emergencyContactAddress',
    'emergencyContactPhoneNumber',
    ...internalFieldsForPhoneNumber,
    'emergencyContactLinkType',
    'isGuardian',
    'dateEffectiveFrom',
    'dateEffectiveTo',
];

export const passportFields = ['number', 'dateOfExpiry', 'dateOfIssue', 'country'];

export const personProbationFields = [
    'id',
    'probationTypeAttrId',
    'probationOfficer',
    'miscDescription',
];

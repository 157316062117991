import { AttributeTypeEnum } from '@mark43/rms-api';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, defaultProps, setPropTypes } from 'recompose';

import keyMirror from 'keymirror';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { attributeOptionsByTypeForCurrentDepartmentSelector } from '../../../attributes/state/ui';
import MultiFieldSelect from './MultiFieldSelect';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const strings = componentStrings.forms.select.LabelsWithNoneAttrSelect;

/**
 * Select dropdown containing all Labels as well as "No Label(s)",
 *   (modeled by `hasRoutingLabel = false/true`).
 * @param {Object} props Same props as `Select`.
 */
const LabelsWithNoneAttrSelect = connect(
    createStructuredSelector({
        attributeOptionsByTypeForCurrentDepartment: attributeOptionsByTypeForCurrentDepartmentSelector,
    })
)(function LabelsWithNoneAttrSelect({
    fields: { hasRoutingLabel, routingLabelAttrIds },
    attributeOptionsByTypeForCurrentDepartment,
    label,
    ...otherProps
}) {
    const opts = attributeOptionsByTypeForCurrentDepartment({
        type: AttributeTypeEnum.ROUTING_LABEL.name,
        includeExpired: true,
        additionalIds: [],
        includeAbbr: false,
    });

    return (
        <MultiFieldSelect
            label={label || strings.label}
            {...otherProps}
            fields={{
                hasRoutingLabel: {
                    options: [
                        {
                            value: false,
                            display: strings.options.hasNoLabels,
                            none: true,
                        },
                    ],
                    ...hasRoutingLabel,
                },
                routingLabelAttrIds: {
                    options: opts,
                    ...routingLabelAttrIds,
                },
            }}
        />
    );
});

export const RRFLabelsWithNoneAttrSelect = compose(
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            hasRoutingLabel: null,
            routingLabelAttrIds: null,
        }),
    }),
    connectRRFMultiFieldInput
)(LabelsWithNoneAttrSelect);

import { some, flatMap, compact } from 'lodash';
import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';

// `attributeId` can be an array of ids, or just a single id
const getIsAttributeOfTypeFactory = (type) => (getState) => (attributeId) => {
    const state = getState();
    // If an attribute field has no value, we assume it's not a relevant attribute
    if (!attributeId) {
        return false;
    }

    const attributes = attributesSelector(state);
    // If the attribute field does have a value, we look in state to see if it's
    // an is a relevant attribute
    if (Array.isArray(attributeId)) {
        return some(attributeId, (attributeId) => attributes[attributeId][type]);
    } else {
        return attributes[attributeId][type];
    }
};

// `attributeIds` can be an array of single `attributeId` or
// an array of an array of `attributeId` e.g.
// [1, 2, 3, ...] or [[1,2], [1,3], ...]
const getHasAttributesOfTypeFactory = (type) => (getState) => (attributeIds) => {
    const state = getState();
    // If an attribute field has no value, we assume it's not a relevant attribute
    if (!attributeIds) {
        return false;
        // If the attribute field does have a value, we look in state to see if it's
        // an is a relevant attribute
    } else {
        // Flat map in case castedValue is an array of ids
        return some(
            compact(flatMap(attributeIds, 'castedValue')),
            (attributeId) => attributesSelector(state)[attributeId][type]
        );
    }
};

export const getAttributeIsOther = getIsAttributeOfTypeFactory('other');
export const getAttributeIsNone = getIsAttributeOfTypeFactory('none');
export const getAttributesAreOther = getHasAttributesOfTypeFactory('other');
export const getAttributesAreNone = getHasAttributesOfTypeFactory('none');

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { filter, map, isEmpty, isFunction } from 'lodash';
import styled from 'styled-components';

import { createStructuredSelector } from 'reselect';
import { Observer, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatNameReportLinkTypeIdSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';

import testIds from '../../../../../core/testIds';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import { handleAddDefendantSidePanelSave, handleRemoveDefendant } from '../../state/ui';
import useQuickAddCallbacks from '../../hooks/useQuickAddCallbacks';
import Row from '../../../../core/components/Row';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { formName } from '../../state/forms/arrestForm';

const strings = componentStrings.reports.core.DefendantSection;

const Thumbnail = styled(ImgWithExifOrientation)`
    max-width: 100%;
    margin-right: 10px;
`;

const mapStateToProps = createStructuredSelector({
    formatNameReportLinkTypeId: formatNameReportLinkTypeIdSelector,
});

function DefendantContent({
    currentReportREN,
    reportId,
    formIndex,
    handleRemoveDefendant,
    handleAddDefendant,
    defendantDisplayName,
    arrest,
    defendantMugshots,
    summaryMode,
    defendantRemarks,
}) {
    return (
        <>
            <Observer
                formName={formName}
                formIndex={formIndex}
                subscriptions={{ defendantId: 'arrest.defendantId' }}
                render={({ defendantId }) => (
                    <NameSummaryViewWrapper
                        renForRecents={currentReportREN}
                        nameId={defendantId}
                        linkType={LinkTypesEnum.DEFENDANT_IN_ARREST}
                        parentEntityType={EntityTypeEnum.REPORT.name}
                        parentId={reportId}
                        summaryMode={summaryMode}
                        onDelete={handleRemoveDefendant}
                        onAddSuccess={handleAddDefendant}
                        show={{ people: true }}
                        limitToOne={true}
                        addNameButtonText={defendantDisplayName}
                        reportId={reportId}
                        personOverlayIdPrefix={joinTruthyValues(
                            [overlayIdEnum.PERSON_OVERLAY_DEFENDANT_SECTION, formIndex],
                            '.'
                        )}
                        renderAdditionalItem={({ collapsed }) => (
                            <>
                                {!isEmpty(defendantMugshots) && (
                                    <SummaryList labelWidth={100} contentWidth={380}>
                                        <SummaryRow label={strings.mugshot}>
                                            {map(defendantMugshots, (image) => {
                                                const {
                                                    thumbnailMediumPath,
                                                    path,
                                                } = getViewModelProperties(image);
                                                return (
                                                    <Thumbnail
                                                        key={image.id}
                                                        src={thumbnailMediumPath || path}
                                                    />
                                                );
                                            })}
                                        </SummaryRow>
                                    </SummaryList>
                                )}
                                {!collapsed && !!summaryMode && (
                                    <Row>
                                        <SummaryList labelWidth={250} contentWidth={380}>
                                            <SummaryRow
                                                fieldName={
                                                    fields.NAME_REPORT_LINK_LINK_TYPE_DEFENDANT_STATEMENT
                                                }
                                            >
                                                {defendantRemarks}
                                            </SummaryRow>
                                            <SummaryRow
                                                fieldName={fields.ARREST_WALES_NCIC_CHECK_NUMBERS}
                                            >
                                                {arrest.walesNcicCheckNumbers}
                                            </SummaryRow>
                                        </SummaryList>
                                    </Row>
                                )}
                            </>
                        )}
                    />
                )}
            />
            {!summaryMode && (
                <ArbiterForm
                    name={formName}
                    context={formName}
                    index={formIndex}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    render={() => (
                        <>
                            <Row>
                                <ArbiterMFTTextArea path="defendantReportLink.statement" rows={3} />
                            </Row>
                            <Row>
                                <ArbiterMFTText path="arrest.walesNcicCheckNumbers" />
                            </Row>
                        </>
                    )}
                />
            )}
        </>
    );
}

export default connect(mapStateToProps, (dispatch) => ({
    handleAddDefendant: (nameId, formIndex) =>
        dispatch(handleAddDefendantSidePanelSave(nameId, formIndex)),
    handleRemoveDefendant: (formIndex) => dispatch(handleRemoveDefendant(formIndex)),
}))(function DefendantSection({
    summaryMode,
    defendantAttachments,
    arrest,
    handleRemoveDefendant,
    handleAddDefendant,
    formatNameReportLinkTypeId,
    currentReportREN,
    reportId,
    formIndex,
    defendantRemarks,
}) {
    const defendantMugshots = filter(defendantAttachments, {
        attachmentType: 'IMAGE',
        linkType: LinkTypesEnum.MUGSHOT,
    });

    const defendantDisplayName = formatNameReportLinkTypeId(LinkTypesEnum.DEFENDANT_IN_ARREST);
    const { personQuickAddCallback } = useQuickAddCallbacks();
    const onAddDefendant = React.useCallback(
        (nameReportLink) => handleAddDefendant(nameReportLink.nameId, formIndex),
        [handleAddDefendant, formIndex]
    );
    const onRemoveDefendant = React.useCallback(
        () =>
            handleRemoveDefendant(formIndex).then(() => {
                if (isFunction(personQuickAddCallback)) {
                    personQuickAddCallback();
                }
            }),
        [handleRemoveDefendant, formIndex, personQuickAddCallback]
    );

    return (
        <CardSection testId={testIds.DEFENDANT_SECTION} fieldName={fields.ARREST_DEFENDANT_ID}>
            <DefendantContent
                summaryMode={summaryMode}
                handleRemoveDefendant={onRemoveDefendant}
                handleAddDefendant={onAddDefendant}
                currentReportREN={currentReportREN}
                reportId={reportId}
                formIndex={formIndex}
                defendantMugshots={defendantMugshots}
                defendantDisplayName={defendantDisplayName}
                arrest={arrest}
                defendantRemarks={defendantRemarks}
            />
        </CardSection>
    );
});

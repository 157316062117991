import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { ORGANIZATION_PROFILE_NAME } from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const societyProfileAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'id',
    },
    {
        key: 'name',
        fieldName: ORGANIZATION_PROFILE_NAME,
        validators: {
            requiredError: requiredString,
        },
    },
]);

/**
 * Module of the society profiles admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.SOCIETY_PROFILE_ADMIN,
    fieldViewModels: societyProfileAdminFormFieldViewModels,
});

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    Text,
    DataItem,
    DataList,
    Divider,
    HStack,
    VStack,
    cssVar,
    Flex,
    Button,
    Spacer,
    Box,
} from 'arc';
import _ from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { reportDefinitionByIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { idFormatConfigurationByIdAndEntityTypeSelector } from '~/client-common/core/domain/id-format-configurations/state/data';
import { cadTicketsViewModelWhereSelector } from '~/client-common/core/domain/cad-tickets/state/ui';
import { latestCadTicket } from '~/client-common/core/domain/cad-tickets/utils/cadTicketsHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { FormattedDate } from '~/client-common/core/dates/components';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { collapsibleStateReducer, toggleIsOpenByKey } from '../state/ui/eventInfo';
import LinkToCadEvent from '../../core/components/event-info/LinkToCadEvent';

const strings = componentStrings.reports.core.EventInfo;

const EventInfoSection = styled.div`
    margin-bottom: 16px;
    width: 100%;
    padding: 3px;
`;
const EventHistorySection = styled.div`
    margin-top: 16px;
    width: 100%;
    padding: 3px;
`;

const CadTicketCommentWrapper = styled.div`
    width: 100%;
    margin-bottom: 4px;
    overflow-wrap: break-word;
`;
const CommentText = styled.div`
    padding: 0px;
    margin-left: 5px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text};
`;

const CommentContainer = styled.div`
    max-height: 290px;
    overflow: scroll;
    width: 100%;
`;

const ShowMoreLessButton = styled(Button)`
    width: 69px;
    font-size: 12px;
`;

const DateContainer = styled.div`
    width: 32px;
    overflow-wrap: normal;
`;

const TitleContainer = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    width: 270px;
`;

/**
 * A sidebar section to display tasks linked to the given entity. It loads its own data and stores it into Redux state.
 */
const EventInfo = ({ reportId }: { reportId: number }) => {
    const maxLength = 170;
    const [isOpenState, dispatch] = React.useReducer(collapsibleStateReducer, {
        eventDetails: true,
        eventHistory: true,
    });

    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const reportById = useSelector(reportByIdSelector);
    const reportDefinitionById = useSelector(reportDefinitionByIdSelector);
    const idFormatConfigurationByIdAndEntityType = useSelector(
        idFormatConfigurationByIdAndEntityTypeSelector
    );
    const cadTicketsViewModelWhere = useSelector(cadTicketsViewModelWhereSelector);
    const report = reportById(reportId);
    if (!report) {
        return {
            cadTicketViewModel: undefined,
        };
    }
    const reportDefinition = reportDefinitionById(report.reportDefinitionId);
    const idFormatConfiguration = idFormatConfigurationByIdAndEntityType(
        reportDefinition.idFormatConfigurationId ?? 0,
        EntityTypeEnum.CAD_AGENCY_EVENT.name
    );
    const cadAgencyEventNumber = idFormatConfiguration ? report.recordNumber : null;

    const cadTicketViewModels = cadTicketsViewModelWhere(
        report.reportingEventNumber,
        cadAgencyEventNumber
    );
    const cadTicketViewModel = latestCadTicket(cadTicketViewModels);
    const {
        eventDate,
        secondaryEventTypeAttrId,
        eventTypeAttrId,
        responderUnitsCommaSeparated,
        responderMembersCommaSeparated,
    } = getViewModelProperties(cadTicketViewModel);

    const formatComment = (comment: string, index: number) => {
        return !isOpenState[index] && comment.length > maxLength
            ? `${comment.substring(0, maxLength - 3)}...`
            : comment;
    };

    const renderEventDateString = () => {
        const start = new Date(cadTicketViewModel.eventStartDateUtc);
        const end = new Date(cadTicketViewModel.eventClosedDateUtc);
        // use standard eventDate value if start/end are invalid
        if (isNaN(start.getTime()) || isNaN(end.getTime())) {
            return eventDate;
        }
        return `${start.toLocaleString("en-US")} - ${end.toLocaleString("en-US")}`;
    }

    return (
        <>
            <EventInfoSection>
                <VStack>
                    <Flex width="100%">
                        <TitleContainer>
                            <Text color="secondary">{strings.coreEventInfo}</Text>
                        </TitleContainer>
                        <Spacer />
                        <Box>
                            <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => dispatch(toggleIsOpenByKey('eventDetails'))}
                            >
                                <Icon
                                    color="mediumGrey"
                                    size={18}
                                    type={
                                        isOpenState.eventDetails ? iconTypes.CLOSE : iconTypes.OPEN
                                    }
                                />
                            </Button>
                        </Box>
                    </Flex>
                    {isOpenState.eventDetails && (
                        <DataList variant="columned" font-size="14px">
                            <DataItem
                                dataKey={formatFieldByName(
                                    fields.CAD_TICKET_CAD_AGENCY_EVENT_NUMBER
                                )}
                            >
                                <LinkToCadEvent
                                    cadAgencyEventNumber={cadTicketViewModel.cadAgencyEventNumber}
                                    cadAgencyEventId={cadTicketViewModel.cadAgencyEventId}
                                />
                            </DataItem>
                            <DataItem
                                dataKey={formatFieldByName(
                                    fields.DISPLAY_CAD_TICKET_EVENT_START_END
                                )}
                            >
                                {renderEventDateString()}
                            </DataItem>
                            <DataItem dataKey={formatFieldByName(fields.CAD_TICKET_EVENT_TYPE)}>
                                {eventTypeAttrId}
                            </DataItem>
                            <DataItem
                                dataKey={formatFieldByName(fields.CAD_TICKET_SECONDARY_EVENT_TYPE)}
                            >
                                {secondaryEventTypeAttrId}
                            </DataItem>
                            <DataItem
                                dataKey={formatFieldByName(
                                    fields.DISPLAY_AGENCY_EVENT_PRIMARY_UNITS
                                )}
                            >
                                {responderUnitsCommaSeparated}
                            </DataItem>
                            <DataItem
                                dataKey={formatFieldByName(
                                    fields.DISPLAY_AGENCY_EVENT_PRIMARY_OFFICERS
                                )}
                            >
                                {responderMembersCommaSeparated}
                            </DataItem>
                            <DataItem dataKey={formatFieldByName(fields.CAD_TICKET_FULL_ADDRESS)}>
                                {cadTicketViewModel.fullAddress}
                            </DataItem>
                        </DataList>
                    )}
                </VStack>
            </EventInfoSection>
            <Divider />
            <EventHistorySection>
                <VStack>
                    <Flex width="100%">
                        <TitleContainer>
                            <Text color="secondary">{strings.eventHistory}</Text>
                        </TitleContainer>
                        <Spacer />
                        <Box>
                            <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => dispatch(toggleIsOpenByKey('eventHistory'))}
                            >
                                <Icon
                                    color="mediumGrey"
                                    size={18}
                                    type={
                                        isOpenState.eventHistory ? iconTypes.CLOSE : iconTypes.OPEN
                                    }
                                />
                            </Button>
                        </Box>
                    </Flex>
                    <CommentContainer>
                        {isOpenState.eventHistory &&
                            _(cadTicketViewModel.comments)
                                .sortBy('authoredDateUtc')
                                .map((cadTicketComment, index) => (
                                    <CadTicketCommentWrapper key={index}>
                                        <HStack align="top" fontSize="14px" color="text.secondary">
                                            <DateContainer>
                                                <FormattedDate
                                                    date={cadTicketComment.authoredDateUtc}
                                                    format={FormattedDate.FORMATS.FORM_TIME}
                                                />
                                            </DateContainer>
                                            <VStack align="left">
                                                <CommentText>
                                                    {formatComment(cadTicketComment.comment, index)}
                                                </CommentText>
                                                {cadTicketComment.comment.length > maxLength && (
                                                    <ShowMoreLessButton
                                                        variant="ghost"
                                                        size="xs"
                                                        color={cssVar('arc.colors.brand.default')}
                                                        onClick={() =>
                                                            dispatch(toggleIsOpenByKey(`${index}`))
                                                        }
                                                    >
                                                        {isOpenState[index]
                                                            ? strings.showLess
                                                            : strings.showMore}
                                                    </ShowMoreLessButton>
                                                )}
                                            </VStack>
                                        </HStack>
                                    </CadTicketCommentWrapper>
                                ))
                                .value()}
                    </CommentContainer>
                </VStack>
            </EventHistorySection>
        </>
    );
};

export default EventInfo;

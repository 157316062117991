import {
    BookingsResource,
    LocationsResource,
    JmsApiBookingSearchRequest,
    JmsUsersResource,
} from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export const bookingsResource = createResource({
    name: 'Bookings Resource',
    methods: {
        searchBookings({ query, limit, ...rest }: Partial<JmsApiBookingSearchRequest>) {
            return req<BookingsResource.SearchBookings>({
                method: 'POST',
                url: `jms/api/bookings/search`,
                data: {
                    query,
                    limit,
                    ...rest,
                },
            });
        },
        getBookingFacilities(query?: string, limit?: number) {
            return req<LocationsResource.SearchJmsLocations>({
                method: 'POST',
                url: 'jms/api/locations/search',
                data: {
                    locationType: 'SITE',
                    status: 'ACTIVE',
                    name: query,
                    limit,
                },
            });
        },
        getJmsUsers(query?: string, limit?: number) {
            return req<JmsUsersResource.SearchJmsUsers>({
                method: 'POST',
                url: 'jms/api/users/search',
                data: {
                    name: query,
                    limit,
                },
            });
        },
        getBookingDetails(id: string) {
            return req<BookingsResource.GetBookingView>({
                method: 'GET',
                url: `jms/api/bookings/${id}/`,
            });
        },
    },
});

import { storeCadUnitStatuses } from '~/client-common/core/domain/cad-unit-statuses/state/data';
import { storeCadUnitStatusTransitions } from '~/client-common/core/domain/cad-unit-status-transitions/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import cadUnitStatusTransitionsResource from '../../resources/cadUnitStatusTransitionsResource';
import AdminBulkImportResource from '../../../../admin/core/import-export/resources/AdminBulkImportResource';
import { departmentProfileSelector } from '../../../../../legacy-redux/selectors/departmentProfileSelectors';

import {
    createBulkImportActionCreator,
    createValidateBulkActionCreator,
    createExportActionCreator,
} from '../../../../admin/core/utils/importExportHelpers';

export const LOAD_CAD_UNIT_STATUSES_START =
    'cad-unit-status-transitions/LOAD_CAD_UNIT_STATUSES_START';
export const LOAD_CAD_UNIT_STATUSES_SUCCESS =
    'cad-unit-status-transitions/LOAD_CAD_UNIT_STATUSES_SUCCESS';
export const LOAD_CAD_UNIT_STATUSES_FAILURE =
    'cad-unit-status-transitions/LOAD_CAD_UNIT_STATUSES_FAILURE';

export const SAVE_CAD_UNIT_STATUS_TRANSITIONS_START =
    'cad-unit-status-transitions/SAVE_CAD_UNIT_STATUS_TRANSITIONS_START';
export const SAVE_CAD_UNIT_STATUS_TRANSITIONS_SUCCESS =
    'cad-unit-status-transitions/SAVE_CAD_UNIT_STATUS_TRANSITIONS_SUCCESS';
export const SAVE_CAD_UNIT_STATUS_TRANSITIONS_FAILURE =
    'cad-unit-status-transitions/SAVE_CAD_UNIT_STATUS_TRANSITIONS_FAILURE';

function loadCadUnitStatusesStart() {
    return {
        type: LOAD_CAD_UNIT_STATUSES_START,
    };
}

function loadCadUnitStatusesFailure(error = {}) {
    return {
        type: LOAD_CAD_UNIT_STATUSES_FAILURE,
        payload: error.message,
    };
}

function loadCadUnitStatusesSuccess() {
    return {
        type: LOAD_CAD_UNIT_STATUSES_SUCCESS,
    };
}

function saveCadUnitStatusTransitionsStart() {
    return {
        type: SAVE_CAD_UNIT_STATUS_TRANSITIONS_START,
    };
}

function saveCadUnitStatusTransitionsFailure(error = {}) {
    return {
        type: SAVE_CAD_UNIT_STATUS_TRANSITIONS_FAILURE,
        payload: error.message,
    };
}

function saveCadUnitStatusTransitionsSuccess() {
    return {
        type: SAVE_CAD_UNIT_STATUS_TRANSITIONS_SUCCESS,
    };
}

export function loadCadUnitStatuses() {
    return (dispatch, getState) => {
        dispatch(loadCadUnitStatusesStart());
        const departmentId = departmentProfileSelector(getState()).departmentId;
        return Promise.all([
            cadUnitStatusTransitionsResource.getUnitStatuses(departmentId),
            cadUnitStatusTransitionsResource.getUnitStatusTransitions(),
        ])
            .then(([unitStatuses, unitStatusTransitions]) => {
                dispatch(loadCadUnitStatusesSuccess());
                dispatch(storeCadUnitStatuses(unitStatuses));
                dispatch(storeCadUnitStatusTransitions(unitStatusTransitions));
            })
            .catch((error) => dispatch(loadCadUnitStatusesFailure(error)));
    };
}

export function saveCadUnitStatusTransitions(fromId, transitions) {
    return (dispatch) => {
        dispatch(saveCadUnitStatusTransitionsStart());
        return cadUnitStatusTransitionsResource
            .updateUnitStateTransitions(fromId, transitions)
            .then((transitions) => {
                dispatch(saveCadUnitStatusTransitionsSuccess());
                dispatch(storeCadUnitStatusTransitions(transitions));
            })
            .catch((error) => dispatch(saveCadUnitStatusTransitionsFailure(error)));
    };
}

export const exportUnitStates = createExportActionCreator(
    cadUnitStatusTransitionsResource.exportUnitStates
);

export const exportPoliceUnitStateTransitions = createExportActionCreator(() =>
    cadUnitStatusTransitionsResource.exportUnitStateTransitions(
        globalAttributes.agencyTypeGlobal.police
    )
);

export const exportFireUnitStateTransitions = createExportActionCreator(() =>
    cadUnitStatusTransitionsResource.exportUnitStateTransitions(
        globalAttributes.agencyTypeGlobal.fire
    )
);
export const exportEMSUnitStateTransitions = createExportActionCreator(() =>
    cadUnitStatusTransitionsResource.exportUnitStateTransitions(
        globalAttributes.agencyTypeGlobal.medical
    )
);

/**
 * Import unit states from an uploaded file.
 * @param  {BulkImportRequest} BulkImportRequest
 * @return {Promise}
 */
const importUnitStates = createBulkImportActionCreator(AdminBulkImportResource.bulkImportCadAdmin);

const importPoliceUnitStateTransitions = createBulkImportActionCreator(
    AdminBulkImportResource.bulkImportCadAdmin
);

const importFireUnitStateTransitions = createBulkImportActionCreator(
    AdminBulkImportResource.bulkImportCadAdmin
);
const importEMSUnitStateTransitions = createBulkImportActionCreator(
    AdminBulkImportResource.bulkImportCadAdmin
);

/**
 * Validate that a file is valid for importing new unit states.
 * @param  {BulkImportRequest} BulkImportRequest
 * @return {Promise}
 */
export const validateUnitStateImport = createValidateBulkActionCreator(
    AdminBulkImportResource.validateBulkImportCadAdmin,
    importUnitStates
);

/**
 * Validate that a file is valid for importing new police unit state transitions
 * @param  {BulkImportRequest} BulkImportRequest
 * @return {Promise}
 */
export const validatePoliceUnitStateTransitionImport = createValidateBulkActionCreator(
    AdminBulkImportResource.validateBulkImportCadAdmin,
    importPoliceUnitStateTransitions
);

/**
 * Validate that a file is valid for importing new fire unit state transitions
 * @param  {BulkImportRequest} BulkImportRequest
 * @return {Promise}
 */
export const validateFireUnitStateTransitionImport = createValidateBulkActionCreator(
    AdminBulkImportResource.validateBulkImportCadAdmin,
    importFireUnitStateTransitions
);

/**
 * Validate that a file is valid for importing new EMS unit state transitions
 * @param  {BulkImportRequest} BulkImportRequest
 * @return {Promise}
 */
export const validateEMSUnitStateTransitionImport = createValidateBulkActionCreator(
    AdminBulkImportResource.validateBulkImportCadAdmin,
    importEMSUnitStateTransitions
);

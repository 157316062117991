import React from 'react';
import { BriefingSimpleView } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';

import { BriefingCellWrapper } from './BriefingCellWrapper';

type Props = Pick<BriefingSimpleView, 'locations'>;
export const BriefingLocationsCell = ({ locations }: Props) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);

    return <BriefingCellWrapper>{formatAttributeById(locations)}</BriefingCellWrapper>;
};

import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';

export const profileHeaderSpringParameters = {
    stiffness: 60,
    damping: 15,
};

// Default search request `from`, `size`, `sortKey`, and `sortType` parameters.
// `sortKey` and `sortType` are currently only used by ENTITY_TYPE_TO_RESOURCES.reports()
export const queryParamDefaults = {
    from: 0,
    size: 25,
    sortKey: sortKeyEnum.REPORT_REPORT_CREATION_DATE_UTC,
    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
};

export const MAX_TARGET_PROFILES_IN_BANNER = 10;

import {
    FolderContentsResource,
    FolderContentUploadRequest,
    FolderContentRemovalRequest,
    EntityTypeEnumType,
} from '@mark43/rms-api';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Folder Contents Resource',
    methods: {
        getFolderContents(folderId) {
            return req<FolderContentsResource.GetFolderContents>({
                method: 'GET',
                url: `folder_contents/${folderId}/`,
            });
        },
        createFolderContents(data: FolderContentUploadRequest) {
            return req<FolderContentsResource.CreateFolderContents>({
                method: 'POST',
                url: `folder_contents`,
                data,
            });
        },
        removeFolderContents(data: FolderContentRemovalRequest) {
            return req<FolderContentsResource.RemoveFolderContents>({
                method: 'POST',
                url: `folder_contents/delete/bulk`,
                data,
            });
        },
        getOrphanContents(ownerId) {
            return req<FolderContentsResource.GetOrphanContents>({
                method: 'GET',
                url: `folder_contents/${ownerId}/orphan_contents`,
            });
        },
        getFolderIdForContent(entityType: EntityTypeEnumType, entityId: number) {
            return req<FolderContentsResource.GetFolderIdForContent>({
                method: 'GET',
                url: `folder_contents/${entityType}/${entityId}/`,
            });
        },
    },
});

import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { historyEventViewModelsByEntityIdSelector } from '~/client-common/core/domain/history-events/state/ui';
import History from '../../../core/histories/components/History';
import { HistoryType } from '../../../core/histories/config';

function CaseHistory({ historyEventViewModelsByEntityId, params: { caseId } }) {
    const historyEventViewModels = historyEventViewModelsByEntityId(caseId);
    return (
        <History
            showFilter={true}
            historyEventViewModels={historyEventViewModels}
            historyType={HistoryType.CASE}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    historyEventViewModelsByEntityId: historyEventViewModelsByEntityIdSelector,
});

export default connect(mapStateToProps)(CaseHistory);

import React from 'react';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { warrantDexSubmissionHistoriesWhereSelector } from '~/client-common/core/domain/warrant-dex-submission-histories/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Card from '../../../../legacy-redux/components/core/Card';
import withCard from '../../../reports/core/utils/withCard';
import warrantDexSubmissionCard from '../state/cards/warrantDexSubmissionCard';
import testIds from '../../../../core/testIds';
import WarrantDexSubmissionForm from './WarrantDexSubmissionForm';
import WarrantDexSubmissionSummary from './WarrantDexSubmissionSummary';

const strings = componentStrings.warrants.warrant.WarrantDexSubmissionCard;

function WarrantDexSubmissionCard({ onEdit, onSave, card = {}, warrantId, canEdit }) {
    return (
        <Card
            testId={testIds.WARRANT_DEX_SUBMISSION_CARD}
            className={card.anchor}
            anchor={card.anchor}
            title={strings.recordsWorkflow}
            errors={card.errorMessages}
            renderContent={(summaryMode) => {
                if (summaryMode) {
                    return <WarrantDexSubmissionSummary warrantId={warrantId} />;
                } else {
                    return <WarrantDexSubmissionForm warrantId={warrantId} />;
                }
            }}
            onEdit={onEdit}
            summaryMode={card.summaryMode}
            canEdit={canEdit}
            saving={card.saving}
            onSave={onSave}
        />
    );
}

const mapDispatchToProps = (dispatch, props) => ({
    onSave() {
        dispatch(warrantDexSubmissionCard.actionCreators.save({ warrantId: props.warrantId }));
    },
    onEdit() {
        dispatch(
            warrantDexSubmissionCard.actionCreators.transitionToEditMode({
                warrantId: props.warrantId,
            })
        );
    },
});

export default compose(
    withCard(warrantDexSubmissionCard),
    connect(
        createStructuredSelector({
            warrantDexSubmissionHistoriesWhere: warrantDexSubmissionHistoriesWhereSelector,
        }),
        mapDispatchToProps
    ),
    pure
)(WarrantDexSubmissionCard);

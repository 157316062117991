import React, { useCallback, useState, useRef } from 'react';

import { ElasticReport, RefContextEnum } from '@mark43/rms-api';
import { SkeletonText, Text } from 'arc';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { flatten, get, map, noop, values } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import type { ReportShortTitleWrapper } from '~/client-common/core/domain/report-short-titles/types';
import { useResource } from '~/client-common/core/hooks/useResource';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import buildReportIdentifierView from '../../../reports/core/utils/buildReportIdentifierView';
import reportsResource from '../../../reports/core/resources/reportsResource';
import formsRegistry from '../../../../core/formsRegistry';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import Modal from '../../../core/overlays/components/Modal';
import {
    convertFromFormModelToCaseDefaultsRequest,
    convertReportShortTitleWrapperToFormModel,
    resetReasonForRelationForm,
} from '../../core/state/form/reasonForRelationForm';
import { RmsDispatch } from '../../../../core/typings/redux';
import ReasonForRelationForm from '../../core/components/ReasonForRelationForm';
import CaseTypesSection from '../../core/components/CaseTypesSection';
import {
    caseDefaultsResponseToCaseDetails,
    getCaseDefaults,
    openCreateManageCaseModalForCase,
} from '../../core/state/ui';
import { clearUnassignedReportsSelection } from '../state/ui';

const strings = componentStrings.cases.core.CreateCaseFromMultiReportsModal;

const DescriptionWrapper = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const message = (caseDisplayName: string, numReports: number) => (
    <DescriptionWrapper>
        <Text fontWeight="bold" fontSize="sm">
            {strings.descriptionBoldStart(caseDisplayName)}
        </Text>
        &nbsp;
        <Text fontSize="sm"> {strings.descriptionEnd(numReports)}</Text>
    </DescriptionWrapper>
);

type CreateCaseFromMultiReportsModalProps = {
    reports: ElasticReport[];
};

type MultiReportsModalFormLocation =
    | typeof overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL
    | typeof overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL;

const CreateCaseFromMultiReportsModal = ({
    formLocation,
}: {
    formLocation: MultiReportsModalFormLocation;
}) => {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    return (
        <OverlayBaseHelper<CreateCaseFromMultiReportsModalProps> id={formLocation}>
            {(renderProps) => {
                const { reports } = renderProps.overlayBase.overlayState.customProperties;
                return (
                    <Modal
                        id={formLocation}
                        okText={null}
                        onSave={noop}
                        onCancel={() => {
                            resetReasonForRelationForm(
                                RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name
                            );
                        }}
                        title={strings.title(caseDisplayName)}
                    >
                        <ModalContent
                            caseDisplayName={caseDisplayName}
                            formLocation={formLocation}
                            reports={reports}
                            setError={renderProps.overlayBase.setError}
                            closePanel={renderProps.closePanel}
                        />
                    </Modal>
                );
            }}
        </OverlayBaseHelper>
    );
};

type ModalContentProps = {
    caseDisplayName: string;
    formLocation: MultiReportsModalFormLocation;
    reports: ElasticReport[];
    setError: (error: string) => void;
    closePanel: () => void;
};

const ModalContent = ({
    caseDisplayName,
    formLocation,
    reports,
    setError,
    closePanel,
}: ModalContentProps) => {
    const dispatch = useDispatch<RmsDispatch>();
    const { getForm } = useFormGetter();
    const [
        reportShortTitleWrapper,
        setReportShortTitleWrapper,
    ] = useState<ReportShortTitleWrapper>();
    const [reportIds, setReportIds] = useState(map(reports, 'id'));
    const reportIdsRef = useRef(reportIds);

    const loadReportShortTitles = useCallback(() => {
        const reportIdentifierView = buildReportIdentifierView(reports || []);
        return reportsResource.findReportTitlesByReportIdentifier(reportIdentifierView);
    }, [reports]);

    const applicationSettings = useSelector(applicationSettingsSelector);
    const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;

    const onSuccess = useCallback(
        (shortTitleWrapper: ReportShortTitleWrapper) => {
            // store the report short titles in redux
            dispatch(
                withEntityItems(
                    {
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: [
                            ...flatten(values(shortTitleWrapper.reportShortTitlesByREN)),
                            ...flatten(
                                values(
                                    shortTitleWrapper.reportShortTitlesByRecordWithoutRENRecordNumber
                                )
                            ),
                        ],
                    },
                    { type: 'STORE_REPORT_SHORT_TITLES' }
                )
            );

            // initialize form state
            formsRegistry.maybeDeferredOperation(
                RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name,
                undefined,
                (form) => {
                    const selectedReportIds = individualReportSelectionEnabled
                        ? reportIdsRef.current
                        : undefined;
                    const formModel = convertReportShortTitleWrapperToFormModel(
                        shortTitleWrapper,
                        selectedReportIds
                    );
                    form.set('', formModel);

                    // this data is passed into the form component to be displayed in the UI; it does not exist in form state
                    setReportShortTitleWrapper(shortTitleWrapper);
                }
            );
        },
        [dispatch, reportIdsRef, individualReportSelectionEnabled]
    );
    const { isLoading, errorMessage } = useResource(loadReportShortTitles, onSuccess);

    const onCaseTypeClick = useCallback(
        async (caseDefinitionId: number) => {
            const form = getForm(RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name);
            if (!form) {
                throw new Error('Error finding form');
            }

            try {
                // Check for form errors before getting case defaults
                // and going to the case management modal
                const formData = await form.submit();

                // make the case default request
                const caseDefaultsRequest = convertFromFormModelToCaseDefaultsRequest(
                    formData.form.getState().model,
                    caseDefinitionId
                );

                const caseDefaults = await dispatch(getCaseDefaults(caseDefaultsRequest));
                closePanel();

                dispatch(
                    openCreateManageCaseModalForCase(
                        caseDefaultsResponseToCaseDetails(caseDefaults)
                    )
                );
                dispatch(clearUnassignedReportsSelection());
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                } else if (err) {
                    setError(get(err, 'validationResult.formErrors'));
                }
            }
        },
        [closePanel, dispatch, getForm, setError]
    );

    const onChangeReportIds = useCallback(() => {
        const form = getForm(RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name);
        if (form) {
            const { reportIds } = convertFromFormModelToCaseDefaultsRequest(
                form.getState().model,
                -1
            );

            setReportIds(reportIds);
        }
    }, [getForm]);

    if (errorMessage) {
        return <InlineBanner status="error">{errorMessage}</InlineBanner>;
    }

    if (isLoading) {
        return <SkeletonText noOfLines={3} />;
    }

    return (
        <>
            {message(caseDisplayName, reportIds.length)}
            <ReasonForRelationForm
                context={RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name}
                formLocation={formLocation}
                reportShortTitleWrapper={reportShortTitleWrapper}
                onChangeReportIds={onChangeReportIds}
                onRemoveItem={onChangeReportIds}
            />
            <CaseTypesSection onClick={onCaseTypeClick} />
        </>
    );
};

export default CreateCaseFromMultiReportsModal;

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { currentUserHasAbilitySelector } from '../../core/current-user/state/ui';

import redirectInsufficientPermissions from '../../core/utils/redirectInsufficientPermissions';
import { loadChainOfCustodyExports } from './state/ui';

export function onEnter(nextState) {
    const state = this.getState();
    if (!currentUserHasAbilitySelector(state)(abilitiesEnum.EVIDENCE.CREATE_CHAIN_EVENTS)) {
        this.dispatch(redirectInsufficientPermissions());
    } else {
        this.dispatch(loadChainOfCustodyExports(parseInt(nextState.params.itemId)));
    }
}

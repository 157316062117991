import * as React from 'react';
import { simpleControl } from 'markformythree';
import { ApiResultSearchOptionView } from '@mark43/rms-api';
import DataLoader from 'dataloader';

import { req } from '../../../../../lib/ajax';

import { uiConfigurationIdToNumber } from '../../../utils/ui-configuration-id-to-number';
import { ResolvedEntityDataLoaderResult } from '../../../rms-types';
import { dragonSelectStateReducer } from './dragon-select-state-reducer';
import {
    useDragonSelectBase,
    DragonOption,
    UseDragonSelectBaseOptions,
    UseDragonSelectBaseResult,
} from './use-dragon-select-base';

type UseDragonEntitySelectOptions = { uiConfigurationId: string } & Exclude<
    UseDragonSelectBaseOptions,
    'optionData'
>;

type UseDragonEntitySelectResult = UseDragonSelectBaseResult & {
    onBlur: UseDragonSelectBaseOptions['onBlur'];
    isLoading: boolean;
    error?: string;
};

// NOTE: Dataloader is caching all values which are returned by our api. This has to be done on a per ui configuration basis,
// as different configurations could have different filters applied to them. Because of this we cannot reuse result sets for configured entity types.
// This will potentially lead to a lot of cached data. We will have to figure out:
//
// 1) Do we want options to be fetched once and persist for the lifetime of the application?
// 2) How and when should we clear this cache?
const resolvedEntityOptionsDataLoader: DataLoader<
    string,
    ResolvedEntityDataLoaderResult,
    number | string
> = new DataLoader(
    (
        uiConfigurationIds: readonly string[]
    ): Promise<ResolvedEntityDataLoaderResult[] | Error[]> => {
        // the substring call here is a temporary workaround for the fact that this frontend receives
        // all uiConfigurationIds with a '_' prefix, which isn't valid when calling POST /dragon/search/options
        const uiConfigurationIdsWithoutPrefix = uiConfigurationIds.map(uiConfigurationIdToNumber);
        return req<{
            path: 'dragon/search/options';
            method: 'POST';
            data: (number | string)[];
            returns: ApiResultSearchOptionView;
        }>({
            method: 'POST',
            url: 'dragon/search/options',
            data: uiConfigurationIdsWithoutPrefix,
        })
            .then((result) =>
                uiConfigurationIdsWithoutPrefix.map(
                    (uiConfigurationId) =>
                        result.resolvedConfiguredEntityPropertyInstanceValues[uiConfigurationId] ??
                        []
                )
            )
            .catch((err) =>
                uiConfigurationIdsWithoutPrefix.map(
                    (uiConfigurationId) =>
                        new Error(
                            `Error loading data for key ${uiConfigurationId}: (${err.message})`
                        )
                )
            );
    }
);

function useDragonEntitySelect({
    uiConfigurationId,
    onChange,
    onBlur = () => {},
    displayPropertyId,
    sortPropertyId,
    valuePropertyId,
    isMultiple,
    value,
}: UseDragonEntitySelectOptions): UseDragonEntitySelectResult {
    const [state, dispatch] = React.useReducer(dragonSelectStateReducer, {
        isLoading: false,
        error: undefined,
        data: undefined,
    });
    React.useEffect(() => {
        let ignore = false;
        dispatch({ type: 'LOADING_START' });
        resolvedEntityOptionsDataLoader
            .load(uiConfigurationId)
            .then((result) => {
                if (ignore) {
                    return;
                }
                dispatch({ type: 'LOADING_SUCCESS', payload: result });
            })
            .catch((err) => {
                if (ignore) {
                    return;
                }
                // instantly clear out the result in case of a failure so that fetching will be re-attempted when this select is re-opened
                resolvedEntityOptionsDataLoader.clear(uiConfigurationId);
                dispatch({ type: 'LOADING_FAILURE', payload: err });
            });

        return () => {
            ignore = true;
        };
    }, [uiConfigurationId]);

    const optionData = state.data;
    const dragonSelectBaseResult = useDragonSelectBase({
        optionData,
        displayPropertyId,
        sortPropertyId,
        valuePropertyId,
        onChange,
        isMultiple,
        value,
    });

    return {
        ...dragonSelectBaseResult,
        onBlur,
        error: state.error?.message,
        isLoading: state.isLoading,
    };
}

type RenderOptions = UseDragonEntitySelectResult;

type DragonConfiguredEntityInstanceControlProps = {
    uiConfigurationId: string;
    displayPropertyId: number;
    sortPropertyId?: number;
    valuePropertyId: number;
    isMultiple: boolean;
    children: (options: RenderOptions) => React.ReactElement;
};

function DragonConfiguredEntityInstanceControlBase({
    uiConfigurationId,
    displayPropertyId,
    sortPropertyId,
    valuePropertyId,
    value: mftValue,
    onChange: mftOnChange,
    children,
    isMultiple,
    error: mftFieldError,
}: DragonConfiguredEntityInstanceControlProps & {
    value?: DragonOption | DragonOption[];
    onChange: (val: unknown) => void;
    error?: string;
}) {
    const useDragonEntitySelectResult = useDragonEntitySelect({
        valuePropertyId,
        displayPropertyId,
        sortPropertyId,
        uiConfigurationId,
        onChange: mftOnChange,
        value: mftValue,
        isMultiple,
    });

    return children({
        ...useDragonEntitySelectResult,
        error: useDragonEntitySelectResult.error || mftFieldError || '',
    });
}

export const DragonConfiguredEntityInstanceControl: React.ComponentType<
    DragonConfiguredEntityInstanceControlProps & { path: string }
> = simpleControl(DragonConfiguredEntityInstanceControlBase);

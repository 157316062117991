import { EntityTypeEnum, EntityTypeEnumType } from '@mark43/rms-api';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

export function getCaseFolderPath({
    entityType,
    caseId,
    folderId,
    noteId,
}: {
    entityType: EntityTypeEnumType;
    caseId: number;
    folderId?: number;
    noteId?: number;
}): string {
    let entity;
    switch (entityType) {
        case EntityTypeEnum.ATTACHMENT.name:
            entity = 'attachments';
            break;
        case EntityTypeEnum.CASE_NOTE.name:
            entity = 'notes';
            break;
        default:
            entity = '';
    }

    return joinTruthyValues(
        ['/cases', caseId, entity, folderId && `folder/${folderId}`, noteId],
        '/'
    );
}

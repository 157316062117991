import React, { useState, useRef } from 'react';
import { LocationSourceEnum } from '@mark43/rms-api';
import { map } from 'lodash';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useDisabledAddLocation } from '../hooks/useDisabledAddLocation';
import testIds from '../../../../core/testIds';
import _AddPill from '../../components/AddPill';
import { SecondarySectionHeader } from '../../components/typography';
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import { scrollToElement } from '../../../../legacy-redux/helpers/navigationHelpers';
import _LocationPill from './LocationPill';

const strings = componentStrings.core.LocationSidePanel;

const AddPill = styled(_AddPill)`
    margin-bottom: 10px;
`;

const LocationPill = styled(_LocationPill)`
    margin-bottom: 10px;
`;

const MAX_RESULTS_PER_PAGE = 15;
const SIDE_PANEL_TOP_OFFSET = 60;

const LocationSearchResults = ({ searchResults, onClickSearchResult, onClickAddNew, linkType }) => {
    const [currentPage, setPage] = useState(0);
    const lastSearchResults = useRef();

    // reset page when our search results change
    if (lastSearchResults.current && lastSearchResults.current !== searchResults) {
        setPage(0);
    }
    lastSearchResults.current = searchResults;

    const displayPagination = searchResults.length > MAX_RESULTS_PER_PAGE;
    const slicedResults = displayPagination
        ? searchResults.slice(
              currentPage * MAX_RESULTS_PER_PAGE,
              currentPage * MAX_RESULTS_PER_PAGE + MAX_RESULTS_PER_PAGE
          )
        : searchResults;

    const disabledAddLocation = useDisabledAddLocation(linkType);

    return (
        <div>
            <SecondarySectionHeader>{strings.searchResults}</SecondarySectionHeader>
            <AddPill
                disabled={disabledAddLocation}
                onClick={onClickAddNew}
                testId={testIds.LOCATION_SIDE_PANEL_NEW_LOCATION}
            >
                {strings.addNew}
            </AddPill>
            {map(slicedResults, (result, index) => {
                // Disable a location if
                // * FF is enabled
                // * this is an offense location
                // * this is not a MAR location
                const disabledLocation =
                    disabledAddLocation && result.source !== LocationSourceEnum.DC_MAR.name;
                return (
                    <LocationPill
                        disabled={disabledLocation}
                        key={index}
                        location={result}
                        onClick={onClickSearchResult}
                        testId={testIds.LOCATION_SIDE_PANEL_LOCATION_RESULT}
                        subtitle={disabledLocation && strings.verifiedAddressNote}
                    />
                );
            })}
            {displayPagination && (
                <Pagination
                    currentPage={currentPage + 1}
                    itemCount={searchResults.length}
                    itemsPerPage={MAX_RESULTS_PER_PAGE}
                    onClick={(prevPage, nextPage) => {
                        setPage(nextPage - 1);
                        scrollToElement({
                            selector: '.location-side-panel-search-input',
                            scrollDuration: 300,
                            wrapperSelector: '.react-side-panel-content-wrapper',
                            offset: SIDE_PANEL_TOP_OFFSET,
                        });
                    }}
                    maxDisplayedItems={9}
                    maxEdgeItems={1}
                />
            )}
        </div>
    );
};

export default LocationSearchResults;

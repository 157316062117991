import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';

const strings = componentStrings.core.tables.elasticReportsTable.PersonsCell;

export default function InvolvementCell(elasticReport) {
    const { involvement, isJuvenile } = getViewModelProperties(elasticReport);
    return canRead(elasticReport.permissionSet) ? (
        <div className="elastic-report-involvement">
            {involvement} {isJuvenile ? strings.isJuvenileTag : undefined}
        </div>
    ) : (
        <div />
    );
}

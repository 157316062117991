import { max, noop } from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';

import Pagination from '../../../../legacy-redux/components/core/Pagination';

const Footer = styled.div`
    padding: 15px 15px 77px 15px;
    text-align: right;
`;

const ResultsTableFooter = ({
    handlePaginationClick,
    maxEdgeItems,
    queryFrom,
    querySize,
    totalResults,
}) => {
    const currentPage = max([1, fromSizeToPage(queryFrom + 1, querySize)]);

    return totalResults ? (
        <Footer>
            <Pagination
                currentPage={currentPage}
                itemsPerPage={querySize}
                itemCount={totalResults}
                onClick={(currentPage, nextPage) => handlePaginationClick(nextPage, querySize)}
                maxEdgeItems={maxEdgeItems}
            />
        </Footer>
    ) : null;
};

ResultsTableFooter.propTypes = {
    handlePaginationClick: PropTypes.func,
    maxEdgeItems: PropTypes.number,
    queryFrom: PropTypes.number,
    querySize: PropTypes.number,
    totalResults: PropTypes.number,
};

ResultsTableFooter.defaultProps = {
    handlePaginationClick: noop,
    maxEdgeItems: 1,
    queryFrom: 0,
    querySize: 0,
    totalResults: 0,
};

export default ResultsTableFooter;

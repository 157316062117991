import React from 'react';
import { DraggableProvidedDraggableProps } from 'react-beautiful-dnd';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { DashboardSideBarCard, DashboardCardProps } from './DashboardSection';
import { RecentlyViewedReports } from './RecentlyViewedReports';

const strings = componentStrings.personalDashboard.RecentlyViewed;

type RecentlyViewedHeaderProps = {
    dragHandleProps?: DraggableProvidedDraggableProps;
    customTitle?: string;
};

type RecentlyViewedProps = {
    hideLoadingBar?: boolean;
    style?: React.CSSProperties;
} & RecentlyViewedHeaderProps &
    DashboardCardProps;

const RecentlyViewed = ({
    hideLoadingBar = true,
    dragHandleProps,
    isDragging,
    title,
    ...props
}: RecentlyViewedProps) => {
    return (
        <DashboardSideBarCard
            dragHandleProps={dragHandleProps}
            isDragging={isDragging}
            title={title ?? strings.title}
            padding="var(--arc-space-2)"
            {...props}
        >
            <RecentlyViewedReports hideLoadingBar={hideLoadingBar} />
        </DashboardSideBarCard>
    );
};

/**
 * This section on the Personal Dashboard shows the entities that the user most recently viewed,
 *   based on usage logs.
 */
export default RecentlyViewed;

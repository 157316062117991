import React, { useCallback } from 'react';
import { EntityTypeEnumType } from '@mark43/rms-api';
import styled from 'styled-components';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { CustomLink as _CustomLink, PopoutIcon } from '../../../core/components/links/Link';
import { getCaseFolderPath } from '../../../cases/core/utils/getCaseFolderPath';
import { iconTypes } from '../../../core/components/Icon';
import folderContentsResource from '../../../cases/core/resources/folderContentsResource';

const CustomLink = styled(_CustomLink)`
    font-weight: ${(props) => props.theme.fontWeights.regular};
    text-decoration: none;
`;

function AttachmentLink({
    entityType,
    title,
    entityId,
    caseId,
}: {
    entityType: EntityTypeEnumType;
    title: string;
    entityId: number;
    caseId: number;
}) {
    const loadFolderId = useCallback(
        () => folderContentsResource.getFolderIdForContent(entityType, entityId),
        [entityType, entityId]
    );
    const onResourceSuccess = useCallback(
        (folderId) => {
            const newLink = getCaseFolderPath({
                caseId,
                entityType,
                folderId,
                noteId: entityId,
            });
            window.open(`#${newLink}`, '_blank');
        },
        [caseId, entityId, entityType]
    );

    const { callResource: fetchCaseNoteFolderId } = useResourceDeferred(
        loadFolderId,
        onResourceSuccess
    );

    return (
        <CustomLink onClick={() => fetchCaseNoteFolderId()}>
            {title}
            <PopoutIcon size={11} type={iconTypes.OPEN_NEW_WINDOW} color="cobaltBlue" />
        </CustomLink>
    );
}

export default AttachmentLink;

import React, { useCallback, useState } from 'react';
import { simpleControl } from 'markformythree';
import styled from 'styled-components';
import { Button, useToast, EmptyState } from 'arc';
import { FieldUiOptions } from 'dragon-react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { RMSDragonConfigurationExtensions } from '../../../../../rms-types';
import { useOverlayStore } from '../../../../../../core/overlays/hooks/useOverlayStore';
import { useSummaryValueForPath } from '../../../../../hooks/use-summary-value-for-path';
import { useFilePathForFileId } from '../../../../../../attachments/files/hooks/useFilePathForFileId';
import { getSignatureModalOverlayId } from '../helpers';
import { CustomSignatureModal, CustomSignatureModalCustomPropsT } from './custom-signature-modal';

const CaptureSignatureButton = styled(Button)`
    width: 95%;
    height: 60px;
`;

const SignatureSummaryWrapper = styled.div`
    padding: 10px 0;
`;

const signatureStrings = componentStrings.dragon.signature.SignatureFormField;

type BaseSignatureCustomComponentPropsT = Pick<
    FieldUiOptions<RMSDragonConfigurationExtensions>,
    'fullyQualifiedPath'
>;

type SignatureSummaryPropsT = {
    fileId?: number;
};

type DragonSignatureCustomComponentPropsT = {
    identifier: string;
    onChange: (value: number) => void;
} & BaseSignatureCustomComponentPropsT;

export const SignatureFormField = simpleControl(
    (props: DragonSignatureCustomComponentPropsT): JSX.Element | null => {
        const dragonFormValue = Number(useSummaryValueForPath(props.fullyQualifiedPath));
        const dragonFileId = !isNaN(dragonFormValue) ? dragonFormValue : undefined;
        const [fileId, setFileId] = useState(dragonFileId);

        const { fileWebServerPath: capturedSignatureWebServerPath } = useFilePathForFileId(fileId);

        const toast = useToast();
        const overlayStore = useOverlayStore<CustomSignatureModalCustomPropsT>();

        const { identifier, onChange } = props;
        const overlayId = getSignatureModalOverlayId(identifier);
        const openCaptureSignatureModal = useCallback(() => {
            overlayStore.open(overlayId);
        }, [overlayId, overlayStore]);

        const onSave = useCallback(
            (file) => {
                toast({
                    status: 'default',
                    description: signatureStrings.signatureCapturedMessage,
                    duration: 2500,
                });

                onChange(file.id);
                setFileId(file.id);
            },
            [onChange, setFileId, toast]
        );

        return (
            <>
                <CaptureSignatureButton
                    leadingVisual="Signature"
                    onClick={openCaptureSignatureModal}
                    variant="outline"
                >
                    {signatureStrings.captureSignatureButtonText}
                </CaptureSignatureButton>
                <SignatureSummaryWrapper>
                    {capturedSignatureWebServerPath && (
                        <img
                            width="100%"
                            height="auto"
                            src={capturedSignatureWebServerPath}
                            alt=""
                        />
                    )}
                </SignatureSummaryWrapper>
                <CustomSignatureModal
                    overlayId={overlayId}
                    identifier={identifier}
                    onSave={onSave}
                />
            </>
        );
    }
);

export const SignatureSummary = ({ fileId }: SignatureSummaryPropsT): JSX.Element | null => {
    const { fileWebServerPath: capturedSignatureWebServerPath } = useFilePathForFileId(fileId);
    if (!capturedSignatureWebServerPath) {
        return <EmptyState title="" subtitle={signatureStrings.emptyStateText} />;
    }

    return <img width="100%" height="auto" src={capturedSignatureWebServerPath} alt="" />;
};

import React from 'react';
import { Tab, TabList, Tabs, HStack } from 'arc';
import { LinkTypesEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Link from '../../../../core/components/links/Link';

const strings = componentStrings.eFiles.materials.body;

export const EFileMaterialHeader: React.FC<{
    eFileId: number;
    linkType: typeof LinkTypesEnum.ITEM_IN_EFILE | typeof LinkTypesEnum.ATTACHMENT_IN_EFILE;
}> = ({ linkType, eFileId, children }) => {
    return (
        <HStack justify="space-between" align="start">
            <Tabs value={String(linkType)} isManual={true}>
                <TabList>
                    <Tab value={String(LinkTypesEnum.ITEM_IN_EFILE)}>
                        <Link to={`/e-file/${eFileId}/material/items`}>
                            {strings.tabs.itemTabLabel}
                        </Link>
                    </Tab>
                    <Tab value={String(LinkTypesEnum.ATTACHMENT_IN_EFILE)}>
                        <Link to={`/e-file/${eFileId}/material/attachments`}>
                            {strings.tabs.attachmentTabLabel}
                        </Link>
                    </Tab>
                </TabList>
            </Tabs>
            {children}
        </HStack>
    );
};

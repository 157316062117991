import {
    UsageSourceModuleEnum,
    EntityTypeEnum,
    UsageActionEnum,
    UsageCompletionEnum,
} from '@mark43/rms-api';

import { storeCasesDashboardTab } from '../core/state/ui';
import { casesTabEnum } from '../core/configuration';
import { executeSavedSearchFromQueryParam } from '../../search/core/state/ui';
import { createUsageLog } from '../../admin/usage-logs/state/data';
import { myCasesSearch, searchInitialMyCases } from './state/ui';

const TAB_NAME = casesTabEnum.MINE;

export function onEnter(nextState, replace) {
    this.dispatch(myCasesSearch.actionCreators.setIsInitialSearch(true));
    this.dispatch(
        executeSavedSearchFromQueryParam({ searchModule: myCasesSearch, nextState, replace })
    ).then((executed) => {
        if (!executed) {
            this.dispatch(searchInitialMyCases());
        }
    });
    this.dispatch(storeCasesDashboardTab(TAB_NAME));
    this.dispatch(
        createUsageLog({
            primaryEntityType: EntityTypeEnum.CASE.name,
            action: UsageActionEnum.VIEWED_MY_CASES_DASHBOARD.name,
            completion: UsageCompletionEnum.SUCCEEDED.name,
            sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
        })
    );
}

import { AttributeTypeEnum, BriefingStateEnum, RoleTypeEnum } from '@mark43/rms-api';

export const queryParamDefaults = {
    FROM: 0,
    SIZE: 25,
};

export const AUTOSAVE_SECONDS_INTERVAL = 30;

export const TOOLTIP_DELAY_MS = 500;

// the amount of time for a Briefing update to be considered recent
export const RECENT_UPDATE_MINUTES = 5;

export const BRIEFING_STATE_STATUS_MAP = {
    [BriefingStateEnum.ACTIVE.name]: 'positive',
    [BriefingStateEnum.DRAFT.name]: 'inProgress',
    [BriefingStateEnum.ARCHIVED.name]: 'neutral',
} as const;

export const SUPPORTED_SUBDIVISION_TYPES = [
    AttributeTypeEnum.SUBDIVISION_DEPTH_1.name,
    AttributeTypeEnum.SUBDIVISION_DEPTH_2.name,
    AttributeTypeEnum.SUBDIVISION_DEPTH_3.name,
    AttributeTypeEnum.SUBDIVISION_DEPTH_4.name,
    AttributeTypeEnum.SUBDIVISION_DEPTH_5.name,
];

export const SUPPORTED_ROLES_TYPES = [RoleTypeEnum.NORMAL.name, RoleTypeEnum.USER.name];

export const ALWAYS_VISIBLE_FILTERS_LIMIT = 4;

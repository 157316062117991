import { useSelector } from 'react-redux';
import { LinkTypesEnum, LinkTypesEnumType } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

export const useDisabledAddLocation = (linkType: LinkTypesEnumType) => {
    const RMS_WASHINGTON_DC_RESTRICT_OFFENSEINCIDENT_ADDRESS_ENABLED = useSelector(
        applicationSettingsSelector
    ).RMS_WASHINGTON_DC_RESTRICT_OFFENSEINCIDENT_ADDRESS_ENABLED;

    const disabledAddLocation =
        RMS_WASHINGTON_DC_RESTRICT_OFFENSEINCIDENT_ADDRESS_ENABLED &&
        linkType === LinkTypesEnum.OFFENSE_LOCATION;

    return !!disabledAddLocation;
};

import { DragonFormsResource } from '@mark43/rms-api';
import { req } from '../../../lib/ajax';
import { uiConfigurationIdToNumber } from '../utils/ui-configuration-id-to-number';
import { ParentFormPath } from '../rms-types';
import { retry } from '../../core/utils/promiseHelpers';

/**
 * Given a list of dragon instance ids, a target configured form id and the path to the current form,
 * deletes the given instances and their nested children. This function is used to ensure that we delete
 * all associated dragon data for a dragon entity and it's associated RMS entity. It will return a list of
 * "core" entities which have been deleted so that we can interface with RMS and update its state accordingly.
 */
export function deleteInlineFormReference({
    configuredFormId,
    instanceIds,
    parentFormPathInstance,
    parentReferencingUiConfigurationId,
}: {
    configuredFormId: number;
    instanceIds: number[];
    parentFormPathInstance: ParentFormPath;
    parentReferencingUiConfigurationId: string;
}): Promise<
    NonNullable<DragonFormsResource.DeleteInlineFormReferenceInstances['returns']['data']>
> {
    return retry(() =>
        req<DragonFormsResource.DeleteInlineFormReferenceInstances>({
            method: 'POST',
            url: 'dragon/forms/inline/delete',
            hideLoadingBar: true,
            data: {
                instanceIds,
                formConfigurationId: configuredFormId,
                parentFormPathInstance,
                parentReferencingUiConfigurationId: uiConfigurationIdToNumber(
                    parentReferencingUiConfigurationId
                ),
            },
        })
    );
}

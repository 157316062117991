import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'messaging';

export default createResource({
    name: 'Cad Messaging Groups Resource',
    methods: {
        getCadMessagingGroups(departmentId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/message_group_members/${departmentId}`,
            });
        },
        getCadUnits() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: 'unit',
            });
        },
        getCallTakerStations() {
            return req({
                method: 'GET',
                baseUrl: '/cad/api',
                url: 'call_taker_station/get_all_for_department',
            });
        },
        getDispatchAreas() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: 'dispatch_areas',
            });
        },
        getDepartmentAgencyProfiles(departmentIds) {
            return req({
                method: 'POST',
                url: 'admin/agency/department/batch',
                data: departmentIds,
            });
        },

        createCadMessagingGroup(cadMessagingGroup) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/message_group/create`,
                data: cadMessagingGroup,
            });
        },
        updateCadMessagingGroup(cadMessagingGroup) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}`,
                data: cadMessagingGroup,
            });
        },

        removeMemberFromMessagingGroup({ messageGroupId, memberEntityId }) {
            return req({
                method: 'DELETE',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/message_group/${messageGroupId}/member/${memberEntityId}`,
            });
        },

        addMembersToMessagingGroup({ members, messageGroupId }) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/message_group/${messageGroupId}/members`,
                data: members,
            });
        },

        /**
         * Validate that a file is valid for importing new MessagingGroups.
         * @return {Promise}
         */
        validateMessagingGroupImport(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/validate/${fileId}`,
            });
        },
    },
});

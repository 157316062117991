import { EntityTypeEnum } from '@mark43/rms-api';

import { filter, includes, omit } from 'lodash';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { replaceArrestCardBundle } from '~/client-common/core/domain/reports/state/ui';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { ARREST_CARD_NAME_REPORT_LINK_TYPES } from '~/client-common/core/domain/reports/state/ui/arrests';
import LinkTypesEnum from '~/client-common/core/enums/universal/linkTypesEnum';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';

import { refreshArrestCardForm, convertFromFormModel } from '../forms/arrestForm';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.ARREST.name,
    baseSelector: (state) => state.ui.report.arrestCard,
    anchor: 'arrest-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit({ index }) {
            return (dispatch, getState) => {
                const state = getState();
                const reportId = baseCard.selectors.reportIdSelector(state, { index });

                dispatch(refreshArrestCardForm(reportId, index));
                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        save(arrestForm, options = {}) {
            return (dispatch, getState) => {
                arrestForm.resetUi();
                const { index } = options;
                let { reportId } = options;
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(getState(), {
                    index,
                });
                if (!reportId) {
                    reportId = baseCard.selectors.reportIdSelector(getState(), { index });
                }
                const arrest = arrestForReportIdSelector(getState())(reportId);
                const additionalFormsToValidate = arrest.defendantId
                    ? [
                          {
                              id: arrest.defendantId,
                              linkType: LinkTypesEnum.DEFENDANT_IN_ARREST,
                          },
                      ]
                    : [];

                const validateCardAction = validateCard({
                    card: baseCard,
                    formComponent: arrestForm,
                    options: { index, additionalFormsToValidate },
                });

                if (isInSummaryMode) {
                    return dispatch(validateCardAction);
                } else {
                    // pull `state` down here, because we altered form model state just above.
                    const state = getState();
                    const formModel = arrestForm.get();
                    const attributes = attributesSelector(state);

                    const allNameReportLinks = nameReportLinksWhereSelector(state)({
                        reportId,
                        contextType: EntityTypeEnum.REPORT.name,
                        contextId: reportId,
                    });
                    const arrestNameReportLinks = filter(allNameReportLinks, (nrl) =>
                        includes(ARREST_CARD_NAME_REPORT_LINK_TYPES, nrl.linkType)
                    );
                    const arrestCardBundle = convertFromFormModel(
                        formModel,
                        attributes,
                        arrestNameReportLinks
                    );

                    const promises = [
                        dispatch(
                            replaceArrestCardBundle(
                                reportId,
                                omit(arrestCardBundle, ['warrants', 'charges'])
                            )
                        ),
                    ];

                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: arrestForm,
                            promises,
                            options: {
                                index,
                            },
                        })
                    ).then(() =>
                        dispatch(validateCardAction).catch(() => {
                            dispatch(baseCard.actionCreators.transitionToEditMode(index));
                        })
                    );
                }
            };
        },
    },
};

import { Mark43File } from '@mark43/cad-api';

export const getFilePath = (fileObj: Mark43File) => {
    return fileObj.fileWebServerPath;
};

export const openFile = (file?: Mark43File) => {
    if (!file) {
        return;
    }

    window.open(getFilePath(file), '_blank');
};

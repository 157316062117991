import moment from 'moment';
import { convertIsoDurationFormatToFutureDate } from '~/client-common/core/dates/utils/dateHelpers';

type ArgsT = {
    duration: string;
};

// We can assume that the duration will always be
// in the correct format, since there will be
// a unit test created in `RuleConditionArgIntegrityTests`
// to check for this at the time of creation.
export const getIsDateWithinFutureRange = () => (value: Date, { duration }: ArgsT) => {
    if (!value) {
        return true;
    }

    if (!(value instanceof Date)) {
        return true;
    }

    const enteredDate = moment(value);

    const enteredDateUtc = enteredDate.toISOString();

    // Invalid date was entered. We can't assume that this RFG
    // Should pass.
    if (!enteredDateUtc) {
        return false;
    }

    // This will take in any ISO 8601 Format and return the number of numbers from it.
    // Ex: P1M will return 1 for month, P1Y will return 12 months, P1D will return 0 months

    const momentDuration = moment.duration(duration);
    const months = Math.floor(momentDuration.asMonths());
    const futureDate = convertIsoDurationFormatToFutureDate(duration);

    const isBeforeToday = enteredDate.isBefore(moment());

    if (isBeforeToday) {
        return true;
    }

    const diff = futureDate.diff(enteredDate, 'months', true);

    return diff >= 0 && diff <= months;
};

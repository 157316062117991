import { createSelector } from 'reselect';
import { actionTypes } from '../../../core/utils/importExportHelpers';

const uiSelector = (state) => state.ui.cadContactListsAdmin;

export const isImportSuccessfulSelector = createSelector(uiSelector, (ui) => ui.isImportSuccessful);

const initialState = {
    isImportSuccessful: false,
};

export default function cadContactListAdminUiReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.EXPORT_FAILURE:
        case actionTypes.EXPORT_START:
        case actionTypes.IMPORT_FAILURE:
        case actionTypes.IMPORT_START:
            return {
                isImportSuccessful: false,
            };
        case actionTypes.IMPORT_SUCCESS:
            return {
                isImportSuccessful: true,
            };
        default:
            return state;
    }
}

import styled from 'styled-components';
import { SimpleGrid, mediaQueries } from 'arc';

// Used for the Organization, Person and Location quick add grids.
export const QuickAddGrid = styled(SimpleGrid)`
    width: 100%;
    grid-template-columns: 1fr;
    gap: var(--arc-space-2);

    @media (min-width: ${mediaQueries.md}) {
        grid-template-columns: 1fr 1fr;
    }
`;

import { orderBy } from 'lodash';
import {
    ElasticCobaltSortTypeEnum,
    ElasticCobaltSortTypeEnumType,
    TaskView,
} from '@mark43/rms-api';
import sortRelatedRecordsEnum, {
    ReportSidebarSortRelatedRecordsEnumType,
} from '~/client-common/core/enums/client/reportSidebarSortRelatedRecordsEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';

function getSortByTaskStatus(
    parentAttributeIdByAttributeId: ReturnType<typeof parentAttributeIdByAttributeIdSelector>
) {
    return function (task: TaskView): number {
        const taskStatusGlobalAttrId = parentAttributeIdByAttributeId(task.statusAttrId);
        switch (taskStatusGlobalAttrId) {
            case globalAttributes.taskStatusGlobal.pending:
                return 1;
            case globalAttributes.taskStatusGlobal.inProgress:
                return 1;
            case globalAttributes.taskStatusGlobal.completed:
                return 2;
            case globalAttributes.taskStatusGlobal.notApplicable:
                return 3;
            default:
                return 4;
        }
    };
}

/**
 * This is the default sort order of tasks from back end.
 *
 * 1. Task status. To Do and In Progress are at the top (between these 2 statuses, they are not separated in the sort
 *    order), then Completed, then Not Applicable at the bottom. These are the global parent attributes of the task
 *    status attributes.
 * 2. Due date, least recent to most recent. This means the oldest due date is at the top.
 * 3. No due date. This means tasks with no due date are at the bottom. In front end, we achieve 2 and 3 with a single
 *    sort, unlike back end.
 * 4. Title, in alphabetical order.
 * 5. Created date, least recent to most recent. When the user select s for a, the remaining 3 sorts are done in the
 *    same order.
 *
 * When the user chooses to sort by any of these fields (1, 2, 4, or 5), we do tiebreaking on the other 3 fields in the
 * same order as listed above. Each tiebreaking field defaults to being sorted in ascending order, except the order of
 * the 2 date fields should stay in sync because they are both dates.
 */
export default function sortTasks(
    tasks: TaskView[],
    {
        activeValue = sortRelatedRecordsEnum.STATUS,
        sortType = ElasticCobaltSortTypeEnum.ALPHABETICAL_A_TO_Z.name,
        parentAttributeIdByAttributeId,
    }: {
        activeValue?: ReportSidebarSortRelatedRecordsEnumType;
        sortType?: ElasticCobaltSortTypeEnumType;
        parentAttributeIdByAttributeId: ReturnType<typeof parentAttributeIdByAttributeIdSelector>;
    }
): TaskView[] {
    const sortByTaskStatus = getSortByTaskStatus(parentAttributeIdByAttributeId);
    let order: 'asc' | 'desc';
    switch (activeValue) {
        case sortRelatedRecordsEnum.STATUS:
            order =
                sortType === ElasticCobaltSortTypeEnum.ALPHABETICAL_Z_TO_A.name ? 'desc' : 'asc';
            return orderBy(
                tasks,
                [sortByTaskStatus, 'dueDateUtc', 'title', 'createdDateUtc'],
                [order, 'asc', 'asc', 'asc']
            );
        case sortRelatedRecordsEnum.DUE_DATE:
            order =
                sortType === ElasticCobaltSortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT.name
                    ? 'desc'
                    : 'asc';
            return orderBy(
                tasks,
                ['dueDateUtc', sortByTaskStatus, 'title', 'createdDateUtc'],
                [order, 'asc', 'asc', order]
            );
        case sortRelatedRecordsEnum.ALPHABETICAL:
            order =
                sortType === ElasticCobaltSortTypeEnum.ALPHABETICAL_Z_TO_A.name ? 'desc' : 'asc';
            return orderBy(
                tasks,
                ['title', sortByTaskStatus, 'dueDateUtc', 'createdDateUtc'],
                [order, 'asc', 'asc', 'asc']
            );
        case sortRelatedRecordsEnum.CREATED_DATE:
            order =
                sortType === ElasticCobaltSortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT.name
                    ? 'desc'
                    : 'asc';
            return orderBy(
                tasks,
                ['createdDateUtc', sortByTaskStatus, 'dueDateUtc', 'title'],
                [order, 'asc', order, 'asc']
            );
        default:
            return tasks;
    }
}

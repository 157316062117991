import _, { map, mapValues } from 'lodash';
import { createSelector } from 'reselect';

import { elasticPropertySelector } from '../data';
import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '../../../attributes/state/data';

import {
    buildViewModel,
    subdivisionAttrIdsMapper,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatLocationLinkTypeId } from '../../../../../helpers/linkTypesHelpers';

const buildElasticPropertyViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
    (formatAttributeById, formatSubdivisionAttrIds) =>
        buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                subdivisionAttrIdsMapper,
                (obj) =>
                    _(obj)
                        .pick('type')
                        .mapValues((type) => formatLocationLinkTypeId(type))
                        .value(),
            ],
            helpers: {
                formatAttributeById,
                formatSubdivisionAttrIds,
            },
        })
);

export const elasticPropertyViewModelsSelector = createSelector(
    elasticPropertySelector,
    buildElasticPropertyViewModelSelector,
    (elasticProperty, buildElasticPropertyViewModel) =>
        mapValues(elasticProperty, buildElasticPropertyViewModel)
);

export function createPropertyResultsSelector(baseSelector) {
    return createSelector(
        baseSelector,
        buildElasticPropertyViewModelSelector,
        (propertyResults, buildElasticPropertyViewModel) =>
            map(propertyResults, buildElasticPropertyViewModel)
    );
}

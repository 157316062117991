import { UcrOffense } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'ucrOffenses';

const ucrOffensesModule = createNormalizedModule<UcrOffense>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS

export const ucrOffensesWhereSelector = ucrOffensesModule.selectors.entitiesWhereSelector;

// REDUCER
export default ucrOffensesModule.reducerConfig;

import { get } from 'lodash';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { updateReportNarrative } from '~/client-common/core/domain/reports/state/data';
import { narrativeAutosavesSelector } from '~/client-common/core/domain/narrative-autosave/state/data/';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import errorToMessage from '../../../../core/errors/utils/errorToMessage';
import { refreshNarrativeForm } from '../forms/narrativeForm';
import createCard from '../../utils/createCard';
import {
    getEditorIdByReportId,
    onEditNarrativeCards,
    sanitizeTinyMCEValue,
} from '../../../../core/editor/utils/tinyEditor';
import { TINYMCE_DEFAULT_INIT_TIMEOUT } from '../../../../core/editor/constants';
import { withTinyEditor } from '../../../../core/editor/utils/withTinyEditor';
import { submitCard, validateCard } from './cards';

const strings = componentStrings.core.Editor.errors;

export const baseSelector = (state) => state.ui.report.narrativeCard;

const baseCard = createCard({
    name: reportCardEnum.NARRATIVE.name,
    baseSelector,
    anchor: 'narrative-card',
});

const NARRATIVE_FORM_MODEL_PATH = 'narrative';

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit(options = {}) {
            return (dispatch, getState) => {
                const { index } = options;

                onEditNarrativeCards(index, false, getState());
                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        editAll(options = {}) {
            return (dispatch, getState) => {
                const { reportId } = options;

                onEditNarrativeCards(reportId, false, getState());
                dispatch(baseCard.actionCreators.transitionAllCardsToEditMode());
            };
        },
        save(form, options) {
            return (dispatch, getState) => {
                const { onSaveSuccessCallback, index } = options;

                const state = getState();
                const reportId = baseCard.selectors.reportIdSelector(state, {
                    index,
                });

                const editorId = getEditorIdByReportId(reportId, false);
                const applicationSettings = applicationSettingsSelector(state);
                const initTimeout =
                    applicationSettings.RMS_TINYMCE_INIT_TIMEOUT || TINYMCE_DEFAULT_INIT_TIMEOUT;

                return new Promise((resolve, reject) => {
                    const timeout = setTimeout(() => {
                        reject(strings.saveTimeout);
                    }, initTimeout);

                    withTinyEditor(editorId, 'MAYBE_DEFERRED', (editor) => {
                        try {
                            form.set(
                                NARRATIVE_FORM_MODEL_PATH,
                                sanitizeTinyMCEValue(editor.getContent())
                            );

                            form.resetUi();

                            const isInSummaryMode = baseCard.selectors.summaryModeSelector(state, {
                                index,
                            });

                            const hasPendingAutosave = get(
                                narrativeAutosavesSelector(state)[reportId],
                                'narrativeHtml'
                            );

                            // Even if the card is in summary mode,
                            // if there is a pending auto save - save the card!
                            if (isInSummaryMode && !hasPendingAutosave) {
                                dispatch(
                                    validateCard({
                                        card: baseCard,
                                        formComponent: form,
                                        options: {
                                            index,
                                        },
                                    })
                                )
                                    .then(() => {
                                        clearTimeout(timeout);
                                        resolve();
                                    })
                                    .catch((err) => {
                                        clearTimeout(timeout);
                                        reject(errorToMessage(err));
                                    });
                            } else {
                                const getPromisesForSubmission = () => [
                                    dispatch(
                                        updateReportNarrative(
                                            reportId,
                                            form.getState().model.narrative
                                        )
                                    ),
                                ];

                                dispatch(
                                    submitCard({
                                        card: baseCard,
                                        formComponent: form,
                                        getPromisesForSubmission,
                                        options: {
                                            index,
                                        },
                                        onSavingSuccess: () => {
                                            // Always re-sync `formModel` state.
                                            const formModel = dispatch(
                                                refreshNarrativeForm(reportId)
                                            );
                                            form.set('', formModel);
                                            onSaveSuccessCallback();
                                        },
                                    })
                                )
                                    .then(() => {
                                        clearTimeout(timeout);
                                        resolve();
                                    })
                                    .catch((err) => {
                                        clearTimeout(timeout);
                                        reject(errorToMessage(err));
                                    });
                            }
                        } catch (err) {
                            clearTimeout(timeout);
                            reject(errorToMessage(err));
                        }
                    });
                });
            };
        },
    },
};

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { replaceCourtCaseCardBundle } from '~/client-common/core/domain/reports/state/ui/courtCases';

import { buildCourtCaseCardBundle, refreshCourtCaseForm } from '../forms/courtCaseForm';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.COURT_CASE.name,
    baseSelector: (state) => state.ui.report.courtCaseCard,
    anchor: 'court-case-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit({ index }) {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        save(form, options = {}) {
            return (dispatch, getState) => {
                form.resetUi();
                const { index } = options;
                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state, { index });
                const reportId = baseCard.selectors.reportIdSelector(state, { index });

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                            options: {
                                index,
                            },
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            options: {
                                index,
                            },
                            getPromisesForSubmission: () => {
                                const courtCaseCardBundle = buildCourtCaseCardBundle({
                                    reportId,
                                    form,
                                });

                                return [
                                    dispatch(
                                        replaceCourtCaseCardBundle({
                                            reportId,
                                            courtCaseCardBundle,
                                        })
                                    ),
                                ];
                            },
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                dispatch(refreshCourtCaseForm({ reportId, index }));
                            },
                        })
                    );
                }
            };
        },
    },
};

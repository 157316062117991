import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { filterFormData } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';
import fieldViewModels from './fieldViewModels';

const inventoriesDashboardSearchForm = createFormModule({
    convertFromFormModel: (formModel = {}) => {
        const {
            departmentIds = [],
            inventoryType = [],
            completedStartDateUtc = null,
            completedEndDateUtc = null,
            completedWithinLastPeriod = null,
            completedToDatePeriod = null,
            ownerUserIds = [],
            storageLocationIds = [],
        } = formModel;

        return filterFormData({
            departmentIds,
            inventoryTypes: inventoryType ? [inventoryType] : inventoryType,
            completedStartDateUtc,
            completedEndDateUtc,
            completedWithinLastPeriod,
            completedToDatePeriod,
            ownerUserIds,
            storageLocationIds,
        });
    },
    fieldViewModels,
    formName: formClientEnum.EVIDENCE_INVENTORIES_DASHBOARD_SEARCH,
});

/**
 * Module of the search form on the inventories dashboard.
 * @type {Object}
 */
export default inventoriesDashboardSearchForm;

import { keyBy, map, truncate, get, head } from 'lodash';
import {
    createExportReleases,
    updateExportRelease,
    LOAD_EXPORT_RELEASES_START,
    LOAD_EXPORT_RELEASES_SUCCESS,
    LOAD_EXPORT_RELEASES_FAILURE,
} from '~/client-common/core/domain/export-releases/state/data/';
import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { printingDataTypeToEntityType } from '~/client-common/helpers/exportHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

import {
    saveBoxSuccess,
    saveBoxFailure,
    closeBox,
} from '../../../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../../box/state/ui';
import {
    exportPDF,
    exportPDFFromWorkOrder,
} from '../../../../../../legacy-redux/actions/exportsActions';
import createReleaseForm from '../forms/createReleaseForm';

// RMS-7589 -- to prevent long report titles from failing DB table insert (and thus failing the
// report from ever being exported), the BE will forcefully truncate long titles to ensure they
// fit.  We match the truncation here.
// If this value changes or gets nuked, update the matching comment in:
// `cobalt-core-client/paper-mill-client/src/main/java/com/mark43/papermill/models/ExportRelease.java`
const truncateName = (name) => truncate(name, { length: 256, omission: '' });
const getPacketName = ({ groupTitle, fileName }) =>
    joinTruthyValues([groupTitle, truncateName(fileName)], ' - ');
const getReleasePacketKey = (entityId, name) => `${entityId}~${truncateName(name)}`;

export function submitEditReleaseModal() {
    return (dispatch, getState) => {
        const context = {
            name: boxEnum.EDIT_EXPORT_RELEASE_MODAL,
        };
        return dispatch(
            createReleaseForm.actionCreators.submit((formModel) => {
                const release = createReleaseForm.convertFromFormModel(
                    formModel,
                    attributeIsOtherSelector(getState())
                );
                return dispatch(updateExportRelease(release))
                    .then((updatedExportReleases) => {
                        dispatch(saveBoxSuccess(context));
                        dispatch(closeBox(context));
                        // reset the values now that we don't need them
                        dispatch(createReleaseForm.actionCreators.reset());
                        return updatedExportReleases;
                    })
                    .catch((err) => {
                        dispatch(saveBoxFailure(context, err.message));
                    });
            })
        ).catch((err) => {
            dispatch(saveBoxFailure(context, err));
        });
    };
}

export function createExportReleasesFromPackets({ selectedPackets, formModel, state }) {
    return map(
        selectedPackets,
        ({ dataType, entityId, fileName, groupTitle, exportReportVersion }) => {
            return {
                ...createReleaseForm.convertFromFormModel(
                    formModel,
                    attributeIsOtherSelector(state)
                ),
                entityId,
                entityType: printingDataTypeToEntityType[dataType],
                releaseTypeOther: getPacketName({ groupTitle, fileName }),
                exportReportVersion,
            };
        }
    );
}

function createExportReleasesFromWorkOrder({ workOrder, formModel, state }) {
    /**
     * Note: as part of RMS-8583 we shortened the JSON names for properties
     * on the WorkOrder model to shrink the size of the JSON. Translating back
     * to their full names here for clarity
     */
    const { dt: dataType, mim: millerIdMap, t: title } = workOrder;
    const entityType = printingDataTypeToEntityType[dataType];
    const entityId = get(millerIdMap, entityType);
    const releaseTypeOther = title;
    return [
        {
            ...createReleaseForm.convertFromFormModel(formModel, attributeIsOtherSelector(state)),
            entityId,
            entityType,
            releaseTypeOther,
        },
    ];
}

export function getPacketsWithReleases({ selectedPackets, createdExportReleases }) {
    // TODO - once we stop defaulting to packet title for release type this will need to change
    const releasesByName = keyBy(createdExportReleases, ({ entityId, releaseTypeOther }) =>
        getReleasePacketKey(entityId, releaseTypeOther)
    );
    return map(selectedPackets, (packet) => {
        const packetName = getPacketName(packet);
        return {
            ...packet,
            releaseId: releasesByName[getReleasePacketKey(packet.entityId, packetName)].id,
        };
    });
}

export function submitCreateReleaseModal() {
    return (dispatch, getState) => {
        const context = {
            name: boxEnum.CREATE_EXPORT_RELEASE_MODAL,
        };
        return dispatch(
            createReleaseForm.actionCreators.submit((formModel) => {
                const state = getState();
                const selectedPackets = createModalSelector(context, 'selectedPackets')(state);
                const workOrder = createModalSelector(context, 'workOrder')(state);
                const onSuccess = createModalSelector(context, 'onSuccess')(state);
                const useWorkOrder = !!workOrder;
                const exportReleases = useWorkOrder
                    ? createExportReleasesFromWorkOrder({ workOrder, formModel, state })
                    : createExportReleasesFromPackets({ selectedPackets, formModel, state });
                return dispatch(createExportReleases(exportReleases))
                    .then((createdExportReleases) => {
                        const formData = createModalSelector(context, 'formData')(state);
                        const exportResourceMethod = createModalSelector(
                            context,
                            'exportResourceMethod'
                        )(state);
                        const entityMetadata = createModalSelector(
                            context,
                            'entityMetadata'
                        )(state);

                        // reset the values now that we don't need them
                        dispatch(createReleaseForm.actionCreators.reset());

                        if (useWorkOrder) {
                            const workOrderWithReleaseId = {
                                ...workOrder,
                                releaseId: get(head(createdExportReleases), 'id'),
                            };
                            dispatch(exportPDFFromWorkOrder(workOrderWithReleaseId, onSuccess));
                        } else {
                            const packetsWithReleases = getPacketsWithReleases({
                                selectedPackets,
                                createdExportReleases,
                            });
                            // kick off the actual export
                            dispatch(
                                exportPDF(
                                    formData,
                                    packetsWithReleases,
                                    exportResourceMethod,
                                    onSuccess,
                                    entityMetadata
                                )
                            );
                        }

                        dispatch(saveBoxSuccess(context));
                        dispatch(closeBox(context));
                        return createdExportReleases;
                    })
                    .catch((err) => {
                        dispatch(saveBoxFailure(context, err.message));
                    });
            })
        ).catch((err) => {
            dispatch(saveBoxFailure(context, err));
        });
    };
}

export const releaseTrackingUiSelector = (state) => state.ui.releaseTracking;

export default function releaseTrackingUiReducer(
    state = {
        loading: false,
        releaseIds: [],
    },
    action
) {
    switch (action.type) {
        case LOAD_EXPORT_RELEASES_START:
            return {
                ...state,
                loading: true,
                releaseIds: [],
                error: undefined,
            };
        case LOAD_EXPORT_RELEASES_SUCCESS:
            return {
                ...state,
                loading: false,
                releaseIds: action.payload,
            };
        case LOAD_EXPORT_RELEASES_FAILURE:
            return {
                ...state,
                loading: false,
                releaseId: [],
                error: action.payload,
            };
        default:
            return state;
    }
}

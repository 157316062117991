import { Paramedic } from '@mark43/rms-api';

import createGroupedModule from '../../../../utils/createGroupedModule';

type ClientParamedic = { reportId: number } & Paramedic;

// helpers

const paramedicsModule = createGroupedModule<ClientParamedic>({
    type: 'paramedics',
    key: 'reportId',
    indexKeys: ['idNumber', 'name'],
});

// ACTIONS
export const replaceParamedicsWhere = paramedicsModule.actionCreators.replaceEntitiesWhere;

// SELECTORS

export const paramedicsByReportIdSelector = paramedicsModule.selectors.entitiesByKeySelector;

// REDUCER
export default paramedicsModule.reducerConfig;

import React, { MouseEventHandler } from 'react';
import { Button, ButtonGroup, IconButton, Menu, MenuContent, MenuListItem, MenuTrigger } from 'arc';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ConditionalTooltip } from '../../../core/components/tooltip';

const strings = componentStrings.core.cards.AttachmentsCard;

const ActionConditionalTooltip = styled(ConditionalTooltip)`
    z-index: 5100;
`;

type NonEmptyArray<T> = [T, ...T[]];

type AttachmentMenuItem = {
    title: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    tooltip?: string;
};

export const AttachmentsMenu = ({ items }: { items: NonEmptyArray<AttachmentMenuItem> }) => {
    const [actionItem, ...menuItems] = items;

    const getMenuItem = (item: AttachmentMenuItem) => (
        <MenuListItem onSelect={item.onClick} disabled={item.disabled}>
            {item.title}
        </MenuListItem>
    );

    return (
        <Menu>
            <ButtonGroup isAttached variant="outline">
                <Button onClick={actionItem.onClick} disabled={actionItem.disabled}>
                    {actionItem.title}
                </Button>
                {menuItems.length ? (
                    <MenuTrigger asChild>
                        <IconButton aria-label={strings.open} icon="Open" />
                    </MenuTrigger>
                ) : null}
            </ButtonGroup>

            <MenuContent align="end">
                {menuItems.map((item, index) => (
                    <ActionConditionalTooltip
                        condition={!!item.tooltip}
                        content={item.tooltip}
                        key={index}
                    >
                        <span>{getMenuItem(item)}</span>
                    </ActionConditionalTooltip>
                ))}
            </MenuContent>
        </Menu>
    );
};

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Street Search Alias Resource',
        methods: {
            getStreetSearchAliases() {
                return req({
                    method: 'GET',
                    url: 'master/locations/street_centerline',
                });
            },
            upsertAliasesForStreet(streetId, aliases) {
                return req({
                    method: 'PUT',
                    url: `master/locations/street_centerline/alias/${streetId}`,
                    data: aliases,
                });
            },
            deleteStreetSearchAlias(streetAliasId) {
                return req({
                    method: 'DELETE',
                    url: `master/locations/aliases/street_search/${streetAliasId}`,
                });
            },

            /**
             * Export all street aliases into a CSV.
             * @return {Promise}
             */
            exportStreetAliases() {
                return req({
                    method: 'GET',
                    url: 'master/locations/street_centerline/alias/export',
                });
            },

            /**
             * Validate that a file is valid for importing new street aliases.
             * @return {Promise}
             */
            validateStreetAliasImport(fileId) {
                return req({
                    method: 'POST',
                    url: `master/locations/street_centerline/alias/import/validate/${fileId}`,
                });
            },

            /**
             * Import street aliases from an uploaded file.
             * @return {Promise}
             */
            importStreetAliases(fileId) {
                return req({
                    method: 'POST',
                    url: `master/locations/street_centerline/alias/import/${fileId}`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';

import { lifecycleOptions } from 'markformythree';

import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { formName } from '../../state/forms/courtCaseForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { MFTNItems } from '../../../../core/forms/components/NItems';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';

const CourtCaseCardForm = ({ index }) => {
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            index={index}
            render={() => (
                <CardSection>
                    <MFTNItems
                        path="courtCases"
                        useRowSpacing={true}
                        render={() => {
                            return (
                                <>
                                    <Row>
                                        <ArbiterMFTDatePicker
                                            path="courtDateUtc"
                                            includeTime={true}
                                        />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            path="courtTypeAttrId"
                                            length="lg"
                                            attributeType={AttributeTypeEnum.COURT_TYPE.name}
                                        />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTText path="courtName" length="lg" />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTText path="judgeName" length="lg" />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTText path="bailAmount" length="lg" />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            path="placeDetainedAtAttrId"
                                            length="lg"
                                            attributeType={
                                                AttributeTypeEnum.COURT_CASE_PLACE_DETAINED_AT.name
                                            }
                                        />
                                        <Row>
                                            <IndentedFields>
                                                <ArbiterMFTText
                                                    path="placeDetainedAtOther"
                                                    length="lg"
                                                />
                                            </IndentedFields>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <ArbiterMFTText path="courtRoomNumber" length="lg" />
                                    </Row>
                                </>
                            );
                        }}
                        renderAddButton={undefined}
                        renderRemoveButton={undefined}
                    />
                </CardSection>
            )}
        />
    );
};

export default CourtCaseCardForm;

import React from 'react';
import { flowRight } from 'lodash';

import { simpleControl } from 'markformythree';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Text from './Text';
import withAsyncHandler from './withAsyncHandler';

const { connectRRFInput } = reactReduxFormHelpers;

/**
 * This wraps the text component in an async system that allows you
 * to pass in an action to perform as a search, and will fire
 * off the search as the user types, and cancel old searches.
 * it assume the options are filled via selectors handled in the background
 * @param {Object} props Same props as `Text` with the addition of `searchAction` to specify the action creator that performs search
 */
class AsyncText extends React.Component {
    constructor(...args) {
        super(...args);
    }
    render() {
        const { asyncHandler, asyncHandlerIsLoading, ...otherProps } = this.props;
        return (
            <Text onQueryChange={asyncHandler} loading={asyncHandlerIsLoading} {...otherProps} />
        );
    }
}

export default withAsyncHandler({ withRef: true })(AsyncText);

export const RRFAsyncText = flowRight(
    connectRRFInput,
    withAsyncHandler({ withRef: true })
)(AsyncText);
export const MFTAsyncText = flowRight(
    simpleControl,
    withAsyncHandler({ withRef: true })
)(AsyncText);

import { createSelector } from 'reselect';
import _ from 'lodash';
import { attributesSelector } from '../../../attributes/state/data';
import {} from '../data';
import { formatPersonInjury } from '../../utils/personInjuriesHelpers';

/**
 * Format the given person injuries into display strings.
 * @param {Object[]} personInjuries [description]
 */
export const formatPersonInjuriesSelector = createSelector(
    attributesSelector,
    (attributes) => (personInjuries) =>
        _(personInjuries)
            .map((injury) =>
                formatPersonInjury(
                    attributes[injury.injuryTypeAttrId],
                    attributes[injury.bodyPartAttrId],
                    injury.description,
                    attributes[injury.weaponsUsedAttrId],
                    injury.wasWeaponSeized,
                    attributes[injury.injurySeverityAttrId]
                )
            )
            .filter()
            .value()
);

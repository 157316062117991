import React, { useEffect } from 'react';
import { includes, get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import {
    createField,
    createFormConfiguration,
    createNItems,
    lifecycleOptions,
    InferFormDataShape,
    LifeCycle,
    Observer,
} from 'markformythree';
import {
    OperationTypeEnumType,
    RoleTypeEnum,
    RefContextEnum,
    OperationTypeEnum,
} from '@mark43/rms-api';
import styled from 'styled-components';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';

import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import useFields, { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_ADD_ROLE_LABEL,
    DISPLAY_ONLY_USER,
} from '~/client-common/core/enums/universal/fields';
import testIds from '../../../../core/testIds';
import { MFTNItems } from '../../forms/components/NItems';
import { ArbiterMFTRoleSelect } from '../../forms/components/selects/RoleSelect';
import { ArbiterMFTOperationTypeSelect } from '../../forms/components/selects/OperationTypeSelect';

import { SecondarySectionHeader } from '../../components/typography/Headers';
import HelpText from '../../../../legacy-redux/components/core/HelpText';

import ArbiterForm from '../../markformythree-arbiter/ArbiterForm';
import { useFormGetter } from '../../forms/hooks/useFormGetter';

import { currentUserDepartmentIdSelector } from '../../current-user/state/ui';
import { RecordPermissionsFormName } from '../state/ui';
import removeButton from './permissionsRemoveButton';

const roleTypes = [
    RoleTypeEnum.NORMAL.name,
    RoleTypeEnum.DEPARTMENT.name,
    RoleTypeEnum.SUPPORT.name,
];

const strings = componentStrings.core.permissions.recordPermissionsForm;

const RoleSectionRow = styled.div`
    display: flex;
    align-items: flex-start;
    flex: 1;
`;

const IconSpacer = styled.div`
    width: 19px;
    height: 22px;
    flex-shrink: 0;
`;

const recordPermissionsFormConfiguration = createFormConfiguration({
    id: createField<number>({}),
    roleSettings: createNItems({
        fields: {
            id: createField<number>({}),
            roleId: createField<number>({ fieldName: fields.DISPLAY_ONLY_ROLE }),
            operationType: createField<OperationTypeEnumType>({
                fieldName: fields.DISPLAY_ONLY_ROLE_PERMISSION,
            }),
        },
    }),
    userSettings: createNItems({
        fields: {
            id: createField<number>({}),
            roleId: createField<number>({ fieldName: fields.DISPLAY_ONLY_USER }),
            operationType: createField<OperationTypeEnumType>({
                fieldName: fields.DISPLAY_ONLY_USER_PERMISSION,
            }),
        },
    }),
    externalAgencySettings: createNItems({
        fields: {
            id: createField<number>({}),
            roleId: createField<number>({ fieldName: fields.DISPLAY_ONLY_ROLE }),
            operationType: createField<OperationTypeEnumType>({
                fieldName: fields.DISPLAY_ONLY_ROLE_PERMISSION,
            }),
        },
    }),
});

const useInitialRoleSettings = (formName?: string, initialRoleSettings?: RoleSetting[]) => {
    const { getForm } = useFormGetter();
    useEffect(() => {
        if (formName && !isEmpty(initialRoleSettings)) {
            const form = getForm(formName);
            form?.set('roleSettings', initialRoleSettings);
        }
    }, [formName, getForm, initialRoleSettings]);
};

const canEditDepartmentRole = (
    item?: RoleSetting,
    isDeptRoleEditable?: boolean,
    currentUserDeptId?: number
): boolean => {
    if (isDeptRoleEditable) {
        return true;
    }

    return (
        get(item, 'role.roleType') !== RoleTypeEnum.DEPARTMENT.name ||
        get(item, 'role.departmentId') !== currentUserDeptId
    );
};

export type RecordPermissionsFormConfiguration = typeof recordPermissionsFormConfiguration;
export type RoleSetting = InferFormDataShape<RecordPermissionsFormConfiguration['roleSettings']>[0];
type UserSetting = InferFormDataShape<RecordPermissionsFormConfiguration['userSettings']>[0];
type RecordPermissionsFormProps = {
    disabledRoleIds: number[];
    editable: boolean;
    formName: RecordPermissionsFormName;
    hideUserSettings?: boolean;
    hideRoleSettings?: boolean;
    hideExternalAgencySettings?: boolean;
    addRoleButtonText?: string;
    roleSettingsTitle?: string;
    roleOperationTypeOptions?: OperationTypeEnumType[];
    roleAddItemOnEmpty?: boolean;
    roleSettingsRoleIdsToExclude?: number[];
    lifecycle?: LifeCycle;
    initialRoleSettings?: RoleSetting[];
    userPermissionDropdown?: OperationTypeEnumType[];
    rolePermissionDropdown?: OperationTypeEnumType[];
    consortiumPermissionDropdown?: OperationTypeEnumType[];
    isDepartmentRoleEditable?: boolean;
};

const RecordPermissionsForm: React.FC<RecordPermissionsFormProps> = (props) => {
    const {
        disabledRoleIds,
        editable,
        formName,
        hideExternalAgencySettings,
        hideRoleSettings,
        hideUserSettings,
        addRoleButtonText,
        roleSettingsTitle,
        roleOperationTypeOptions,
        lifecycle = lifecycleOptions.REGISTER_AND_RETAIN,
        roleAddItemOnEmpty = true,
        roleSettingsRoleIdsToExclude = [],
        initialRoleSettings = [],
        userPermissionDropdown,
        rolePermissionDropdown,
        consortiumPermissionDropdown,
        isDepartmentRoleEditable,
    } = props;

    useInitialRoleSettings(formName, initialRoleSettings);

    const consortiumDepartmentLinksAvailable = useSelector(
        consortiumDepartmentLinksAvailableSelector
    );
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);

    const {
        singularCaseFieldName: caseDisplayName,
        pluralCaseFieldName: casesDisplayName,
    } = useCaseFieldName();

    const { DISPLAY_ONLY_USER: userLabel, DISPLAY_ONLY_ADD_ROLE_LABEL: roleLabel } = useFields([
        DISPLAY_ONLY_USER,
        DISPLAY_ONLY_ADD_ROLE_LABEL,
    ]);

    const context =
        formName === formClientEnum.SAVED_SEARCH_PERMISSIONS_FORM
            ? RefContextEnum.FORM_SHARED_SAVED_SEARCH_PERMISSION.name
            : formName === formClientEnum.EVIDENCE_LOCATION_PERMISSIONS_FORM
            ? RefContextEnum.FORM_EVIDENCE_LOCATION_ENTITY_PERMISSION.name
            : RefContextEnum.FORM_ENTITY_PERMISSION.name;
    return (
        <ArbiterForm
            lifecycle={lifecycle}
            name={formName}
            context={context}
            configuration={recordPermissionsFormConfiguration}
            render={() => (
                <div>
                    {!hideRoleSettings && (
                        <div data-test-id={testIds.ROLE_PERMISSION_SECTION}>
                            <SecondarySectionHeader>
                                {roleSettingsTitle || strings.roleSettings}
                            </SecondarySectionHeader>
                            <MFTNItems<RoleSetting>
                                path="roleSettings"
                                showLabelForFirstItemOnly={true}
                                addItemOnEmpty={roleAddItemOnEmpty}
                                childFieldKeys={['roleId', 'operationType']}
                                addText={addRoleButtonText || roleLabel}
                                disabled={!editable}
                                renderRemoveButton={undefined}
                                render={({ index, removeItem }) => {
                                    return (
                                        <Observer<
                                            { role?: RoleSetting },
                                            RecordPermissionsFormConfiguration
                                        >
                                            subscriptions={{
                                                role: `roleSettings[${index}]`,
                                            }}
                                            render={({ role }) => {
                                                const disabled = !canEditDepartmentRole(
                                                    role,
                                                    isDepartmentRoleEditable,
                                                    currentUserDepartmentId
                                                );
                                                return (
                                                    <RoleSectionRow>
                                                        <ArbiterMFTRoleSelect
                                                            clearable={false}
                                                            path="roleId"
                                                            disabled={disabled || !editable}
                                                            noAsyncAction={true}
                                                            roleTypes={roleTypes}
                                                            roleExcludeRoleIds={
                                                                roleSettingsRoleIdsToExclude
                                                            }
                                                        />
                                                        <ArbiterMFTOperationTypeSelect
                                                            clearable={false}
                                                            path="operationType"
                                                            disabled={!editable}
                                                            dropdownOptionValues={
                                                                roleOperationTypeOptions ||
                                                                rolePermissionDropdown
                                                            }
                                                        />
                                                        {!disabled && editable ? (
                                                            removeButton({
                                                                item: role,
                                                                removeItem,
                                                            })
                                                        ) : (
                                                            <IconSpacer />
                                                        )}
                                                    </RoleSectionRow>
                                                );
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    )}
                    {!hideUserSettings && (
                        <div data-test-id={testIds.USER_PERMISSION_SECTION}>
                            <SecondarySectionHeader>{strings.userSettings}</SecondarySectionHeader>
                            <MFTNItems<UserSetting>
                                path="userSettings"
                                showLabelForFirstItemOnly={true}
                                childFieldKeys={['roleId', 'operationType']}
                                addText={userLabel}
                                disabled={!editable}
                                renderRemoveButton={(props) =>
                                    removeButton(props, (item: UserSetting) => {
                                        return !editable || includes(disabledRoleIds, item.roleId);
                                    })
                                }
                                render={({ item }) => {
                                    return (
                                        <RoleSectionRow>
                                            <ArbiterMFTRoleSelect
                                                clearable={false}
                                                roleTypes={[RoleTypeEnum.USER.name]}
                                                path="roleId"
                                                disabled={
                                                    !editable ||
                                                    includes(disabledRoleIds, item.roleId)
                                                }
                                            />
                                            <ArbiterMFTOperationTypeSelect
                                                clearable={false}
                                                fieldName={fields.DISPLAY_ONLY_USER_PERMISSION}
                                                path="operationType"
                                                disabled={
                                                    !editable ||
                                                    includes(disabledRoleIds, item.roleId)
                                                }
                                                dropdownOptionValues={userPermissionDropdown}
                                            />
                                        </RoleSectionRow>
                                    );
                                }}
                            />
                        </div>
                    )}
                    {!hideExternalAgencySettings && consortiumDepartmentLinksAvailable && (
                        <>
                            <SecondarySectionHeader>
                                {strings.externalAgencySettings}
                                <HelpText
                                    content={strings.externalHelpText(
                                        caseDisplayName,
                                        casesDisplayName
                                    )}
                                    collisionBoundary={document.querySelector(
                                        '.mark43-react-modal'
                                    )}
                                />
                            </SecondarySectionHeader>
                            <MFTNItems
                                path="externalAgencySettings"
                                showLabelForFirstItemOnly={true}
                                childFieldKeys={['roleId', 'operationType']}
                                addText={roleLabel}
                                disabled={!editable}
                                renderRemoveButton={(props) => removeButton(props, () => !editable)}
                                render={() => {
                                    return (
                                        <RoleSectionRow>
                                            <ArbiterMFTRoleSelect
                                                clearable={false}
                                                noAsyncAction={true}
                                                roleTypes={roleTypes}
                                                useExternalRoles
                                                disabled={!editable}
                                                path="roleId"
                                            />
                                            <ArbiterMFTOperationTypeSelect
                                                clearable={false}
                                                path="operationType"
                                                disabled={!editable}
                                                dropdownOptionValues={
                                                    consortiumPermissionDropdown || [
                                                        OperationTypeEnum.READ.name,
                                                        OperationTypeEnum.SEARCH.name,
                                                    ]
                                                }
                                            />
                                        </RoleSectionRow>
                                    );
                                }}
                            />
                        </>
                    )}
                </div>
            )}
        />
    );
};

export default RecordPermissionsForm;

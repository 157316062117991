import { createSelector } from 'reselect';
import { OffenseSubCrimeLink } from '@mark43/rms-api';
import { ClientCommonAction } from '../../../../../redux/types';
import createLinkModule from '../../../../utils/createLinkModule';
import getOffensesResource from '../../../offenses/resources/offensesResource';

export const NEXUS_STATE_PROP = 'offenseSubCrimeLinks';

const offenseSubCrimeLinksModule = createLinkModule<OffenseSubCrimeLink>({
    type: NEXUS_STATE_PROP,
    keys: ['offenseId', 'offenseCodeId'],
});

const UPDATE_OFFENSE_SUB_CRIME_LINKS_START =
    'OFFENSE_SUB_CRIME_LINKS/UPDATE_OFFENSE_SUB_CRIME_LINKS_START';
const UPDATE_OFFENSE_SUB_CRIME_LINKS_SUCCESS =
    'OFFENSE_SUB_CRIME_LINKS/UPDATE_OFFENSE_SUB_CRIME_LINKS_SUCCESS';
const UPDATE_OFFENSE_SUB_CRIME_LINKS_FAILURE =
    'OFFENSE_SUB_CRIME_LINKS/UPDATE_OFFENSE_SUB_CRIME_LINKS_FAILURE';

function updateOffenseSubCrimeLinksStart(offenseSubCrimeLinks: OffenseSubCrimeLink[]) {
    return {
        type: UPDATE_OFFENSE_SUB_CRIME_LINKS_START,
        payload: offenseSubCrimeLinks,
    };
}

function updateOffenseSubCrimeLinksSuccess(offenseSubCrimeLinks: OffenseSubCrimeLink[]) {
    return {
        type: UPDATE_OFFENSE_SUB_CRIME_LINKS_SUCCESS,
        payload: offenseSubCrimeLinks,
    };
}

function updateOffenseSubCrimeLinksFailure(message: string) {
    return {
        type: UPDATE_OFFENSE_SUB_CRIME_LINKS_FAILURE,
        payload: message,
    };
}

const offenseSubCrimeLinksWhereSelector = offenseSubCrimeLinksModule.selectors.linksWhereSelector;

export const offenseSubCrimeLinksByOffenseIdSelector = createSelector(
    offenseSubCrimeLinksWhereSelector,
    (offenseSubCrimeLinksWhere) => (offenseId: number) => {
        return offenseSubCrimeLinksWhere({
            offenseId,
        });
    }
);

export const replaceOffenseSubCrimeLinks: (args: {
    offenseId: number;
    subCrimeLinks: OffenseSubCrimeLink[];
}) => ClientCommonAction<Promise<OffenseSubCrimeLink[]>> = ({ offenseId, subCrimeLinks }) => {
    return (dispatch, getState, { nexus }) => {
        dispatch(updateOffenseSubCrimeLinksStart(subCrimeLinks));
        return getOffensesResource()
            .replaceOffenseSubCrimeLinks({ offenseId, subCrimeLinks })
            .then((updatedSubCrimeLinks: OffenseSubCrimeLink[]) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: updatedSubCrimeLinks,
                        },
                        nexus.withRemove(
                            NEXUS_STATE_PROP,
                            { offenseId },
                            updateOffenseSubCrimeLinksSuccess(updatedSubCrimeLinks)
                        )
                    )
                );
                return updatedSubCrimeLinks;
            })
            .catch((err: Error) => {
                dispatch(updateOffenseSubCrimeLinksFailure(err.message));
                throw err;
            });
    };
};

export default offenseSubCrimeLinksModule.reducerConfig;

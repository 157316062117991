export default {
    homicide: ['1A', '1B'],
    floridaRequiresWeaponCodes: ['1A', '1B', '2A', '2B', '2C', '2D', '3', '4A', '4B', '4C'],
    potentialJustifiableHomicideCode: '1A',
    floridaJustifiableHomicideCode: '1C',
    floridaKidnappingCode: '8',
    negligentManslaughterCode: '1B',
    arsonMobileMotorVehicleCode: '8H',
    arsonMobileOtherCode: '8I',
} as const;

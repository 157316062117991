import { EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import { filter, some, every } from 'lodash';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';

const warrantPermissionsAdminFormFieldViewModels = {
    defaultPermissions: {
        type: fieldTypeClientEnum.N_FIELDSETS,
        key: 'defaultPermissions',
        fields: buildFlatFormFieldViewModels(['roleId', 'operationType']),
    },
    defaultDepartmentPermissions: {
        type: fieldTypeClientEnum.N_FIELDSETS,
        key: 'defaultDepartmentPermissions',
        fields: buildFlatFormFieldViewModels(['roleId', 'operationType']),
    },
};

function convertToFormModel({ permissions = [], roleById, currentDepartmentId }) {
    const isExternalRole = (roleId) => {
        const role = roleById(roleId);
        // if the role doesn't exists we assume it is a user role,
        // which we do not support for external departments
        return role && role.departmentId !== currentDepartmentId;
    };
    return {
        defaultPermissions: filter(
            permissions,
            (futureEntityPermission) => !isExternalRole(futureEntityPermission.roleId)
        ),
        defaultDepartmentPermissions: filter(permissions, (futureEntityPermission) =>
            isExternalRole(futureEntityPermission.roleId)
        ),
    };
}

function convertFromFormModel({ defaultPermissions = [], defaultDepartmentPermissions = [] }) {
    const mapPermissions = (permission) => ({
        entityType: EntityTypeEnum.WARRANT.name,
        operationType: permission.operationType,
        roleId: permission.roleId,
    });
    return {
        permissions: [
            ...defaultPermissions.map(mapPermissions),
            ...defaultDepartmentPermissions.map(mapPermissions),
        ],
    };
}

/**
 * Module of the warrant permissions admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.WARRANT_PERMISSIONS,
    fieldViewModels: warrantPermissionsAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
    formValidators: {
        internalEntityPermissionMustHaveManage: ({ defaultPermissions }) =>
            some(
                defaultPermissions,
                ({ operationType }) => operationType === OperationTypeEnum.MANAGE.name
            ),
        noEntityPermissionsEmpty: ({ defaultPermissions }) =>
            every(defaultPermissions, ({ roleId, operationType }) => roleId && operationType),
    },
});

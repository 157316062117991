import { ElasticCadTicket, ElasticSearchTypeEnum, QuickSearchResult } from '@mark43/rms-api';
import React, { useState, useRef, useEffect } from 'react';
import { invert, reduce } from 'lodash';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
    cssVar,
    Stack,
    HStack,
    Icon as ArcIcon,
    Divider,
    useNavOpen,
    useNavCollapse,
    useBreakpointValue,
    mediaQueries,
} from 'arc';
import { withRouter, InjectedRouter } from 'react-router';
import { ArrayElement, ValueOf } from '~/client-common/types';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { LoadingState } from '~/client-common/core/hooks/useLoadingState';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import {
    TriggerQuickSearchOptions as _TriggerQuickSearchOptions,
    TriggerQuickSearchLoadMoreForTypeOptions as _TriggerQuickSearchLoadMoreForTypeOptions,
} from '../hooks/useQuickSearch';
import Checkbox from '../../../core/forms/components/checkboxes/Checkbox';
import testIds from '../../../../core/testIds';
import AsyncText from '../../../core/forms/components/AsyncText';
import { SearchErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import {
    MIN_QUERY_LENGTH,
    quickSearchResultTypeToPropMap,
    QuickSearchResultSearchTypes,
} from '../config';
import {
    triggerQuickSearch as _triggerQuickSearch,
    triggerQuickSearchLoadMoreForType as _triggerQuickSearchLoadMoreForType,
    resetQuickSearchState as _resetQuickSearchState,
    updateQuickSearchQuery as _updateQuickSearchQuery,
    quickSearchResultsSelector,
    quickSearchisLoadingSelector,
    quickSearchIsSectionLoadingForTypeSelector,
    quickSearchErrorSelector,
    quickSearchQuerySelector,
    quickSearchHasResultsSelector,
    quickSearchDefaultResultTypesSelector,
    allEntityTypes,
} from '../state/ui';
import zIndexes from '../../../core/styles/zIndexes';
import { RmsDispatch } from '../../../../core/typings/redux';
import advancedSearchCadTickets from '../../cad-tickets/state/ui';
import _handleResultItemSelection from '../helpers/handleResultItemSelection';
import AdvancedSearchTypesMenu from './AdvancedSearchTypesMenu';
import QuickSearchResults from './QuickSearchResults';

const MAX_QUERY_LENGTH = 200;

const { quickSearch: quickSearchStrings } = componentStrings;

export const QuickSearchWrapper = styled(Stack)`
    max-height: 85vh;

    @media (min-width: ${mediaQueries.md}) {
        width: 32rem;
    }
`;

const InputWrapper = styled(HStack)`
    position: relative;
    z-index: ${zIndexes.quickSearch};
    width: 100%;
    flex: 1;

    @media (min-width: ${mediaQueries.md}) {
        width: unset;
    }
`;
const SearchWrapper = styled.div`
    display: flex;
    gap: var(--arc-space-2);
    flex-direction: column;
    align-items: flex-end;

    @media (min-width: ${mediaQueries.md}) {
        flex-direction: row;
    }
`;

const Header = styled(Stack)`
    padding: ${cssVar('arc.space.4')};
    flex-wrap: wrap;
    align-items: stretch;
`;

export const ResultsWrapper = styled.div`
    overflow: auto;
`;

// using !important here is gross,
// but since we are mixing classes and
// styled components that's how it is.
const QuickSearchInput = styled(AsyncText)`
    margin: 0 !important;
    width: unset;
    flex: 1;
    z-index: 0;

    & input {
        padding-left: ${cssVar('arc.space.8')};
    }
`;

const SearchInputIcon = styled(ArcIcon)`
    color: var(--arc-colors-icon-default);
    position: absolute;
    top: 7px;
    left: var(--arc-space-2);
    z-index: 1;
`;

type OnSearchResultClickSelectedDetails = {
    event: React.SyntheticEvent;
    index: number;
    itemType: QuickSearchResultSearchTypes;
};

type QuickSearchBasicProps = {
    onClose?: () => void;
    router?: InjectedRouter;
    isPlainInput?: boolean;
    disabled?: boolean;
    inputLabel?: string;
    onSearchResultClick?: (props: OnSearchResultClickSelectedDetails) => void;
};

type QuickSearchProps = QuickSearchBasicProps & {
    allowedEntityTypes?: TriggerQuickSearchOptions['allowedEntityTypes'];
};

type TriggerQuickSearchOptions = _TriggerQuickSearchOptions &
    Partial<Pick<QuickSearchBaseProps, 'query'>>;

type TriggerQuickSearchLoadMoreForTypeOptions = _TriggerQuickSearchLoadMoreForTypeOptions &
    Partial<Pick<QuickSearchBaseProps, 'query'>>;

type QuickSearchBaseProps = QuickSearchBasicProps & {
    allowedEntityTypes: TriggerQuickSearchOptions['allowedEntityTypes'];
    results?: QuickSearchResult;
    isLoading: LoadingState['isLoading'];
    isSectionLoadingForType: ((props: QuickSearchResultSearchTypes) => boolean) | (() => void);
    error: LoadingState['errorMessage'];
    hasResults: boolean;
    query: string;
    resultTypes: QuickSearchResultSearchTypes[];
    applicationSettings: Record<string, string | number | boolean | null>;
    consortiumDepartmentLinksAvailable?: boolean | unknown;
    triggerQuickSearch: (props: TriggerQuickSearchOptions) => void;
    triggerQuickSearchLoadMoreForType: (props: TriggerQuickSearchLoadMoreForTypeOptions) => void;
    resetQuickSearchState: () => void;
    updateQuickSearchQuery: (props: string) => void;
    handleCadResultClick?: (props: unknown) => void;
};

export const QuickSearchBase = ({
    onClose,
    router,
    allowedEntityTypes,
    isPlainInput,
    disabled,
    inputLabel,
    onSearchResultClick,
    results,
    isLoading,
    isSectionLoadingForType,
    error,
    hasResults,
    query,
    resultTypes,
    applicationSettings,
    consortiumDepartmentLinksAvailable,
    triggerQuickSearch,
    triggerQuickSearchLoadMoreForType,
    resetQuickSearchState,
    updateQuickSearchQuery,
    handleCadResultClick,
}: QuickSearchBaseProps) => {
    const [, setNavCollapse] = useNavCollapse();
    const [, setNavOpen] = useNavOpen();
    const isDesktop = useBreakpointValue({
        base: false,
        xl: true,
    });
    const closeNav = () => {
        setNavOpen(false);
        if (!isDesktop) {
            setNavCollapse(true);
        }
    };
    // This handler is being passed to child components
    // We do this to have a centralized way of controlling
    // how different result types should behave when being selected
    const handleResultItemSelection = _handleResultItemSelection({
        defaultAction: () => {
            if (onClose) {
                onClose();
            }
            closeNav();
        },
        router,
        customItemTypeActions: {
            [ElasticSearchTypeEnum.CAD_TICKET.name]: (item: ElasticCadTicket) => {
                if (handleCadResultClick) {
                    handleCadResultClick(item);
                }
                closeNav();
            },
        },
    });
    const [showAdvancedMenu, setShowAdvancedMenu] = useState(false);
    const [excludeExternalAgencyResults, setExcludeExternalAgencyResults] = useState(
        !!applicationSettings?.RMS_CONSORTIUM_QUICK_SEARCH_FILTER
    );
    const isQueryInvalid = (value: string | undefined) => {
        if (
            (value && value.length < MIN_QUERY_LENGTH) ||
            (value && value.length > MAX_QUERY_LENGTH)
        ) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        return resetQuickSearchState;
    }, [resetQuickSearchState]);

    const wrapperRef = useRef();
    const resultsWrapperRef = useRef();
    const inputWrapperRef = useRef();

    const triggerSearchQuery = React.useCallback(
        (value: string) => {
            const query = value?.trim();
            if (isQueryInvalid(query)) {
                return;
            }
            return triggerQuickSearch({
                allowedEntityTypes,
                query,
                excludeExternalAgencyResults,
            });
        },
        [allowedEntityTypes, excludeExternalAgencyResults, triggerQuickSearch]
    );

    const handleExcludeExternalAgencyResultsChange = (excludeExternalAgencyResults: boolean) => {
        setExcludeExternalAgencyResults(excludeExternalAgencyResults);
        triggerSearchQuery(query);
    };

    const handleTriggerQuickSearchLoadMoreForType = React.useCallback(
        (data) => {
            return triggerQuickSearchLoadMoreForType(data);
        },
        [triggerQuickSearchLoadMoreForType]
    );

    const handleQueryChange = React.useCallback(
        (value) => {
            // if we moved from an invalid query to a valid query we want to
            // reset the result state before updating our query to prevent
            // flickering of old results
            const prevQuery = query?.trim();
            const newQuery = value.trim();
            const prevQueryInvalid = isQueryInvalid(prevQuery);
            const newQueryInvalid = isQueryInvalid(newQuery);
            if ((!newQueryInvalid && prevQueryInvalid) || (newQueryInvalid && !prevQueryInvalid)) {
                resetQuickSearchState();
            }
            updateQuickSearchQuery(value);
        },
        [query, updateQuickSearchQuery, resetQuickSearchState]
    );

    const handleQueryOnFocus = () => {
        if (showAdvancedMenu) {
            setShowAdvancedMenu(false);
        }
    };

    const handleAdvancedSearchButtonClick = () => {
        setShowAdvancedMenu((prev) => !prev);
    };

    const handleInputEnterPress = () => {
        if (hasResults) {
            // Loop through all the results we have and check if
            // there is exactly one item. If so, then we can navigate directly
            // to it once the user presses "enter". If we have more than one
            // result we don't do anything.
            type ItemResults = {
                item?: ArrayElement<ValueOf<QuickSearchResult>['items']>;
                onlyOne: boolean;
                type: string;
            };

            const item = reduce<QuickSearchResult, ItemResults>(
                results,
                (acc, value, key) => {
                    const { items } = value;
                    if (items.length > 1) {
                        acc.onlyOne = false;
                    }

                    if (items.length === 1) {
                        if (acc.item) {
                            acc.onlyOne = false;
                        }
                        if (acc) {
                            acc.item = items[0];
                            acc.type = key;
                        }
                    }

                    if (!acc.onlyOne) {
                        return acc;
                    }

                    return acc;
                },
                { item: undefined, onlyOne: true, type: '' }
            );

            if (item.onlyOne) {
                // sections know their items' types via the `type` prop.
                // since this is a global handler, we have to infer the actual
                // type from the result key.
                const itemType = invert(quickSearchResultTypeToPropMap)[item.type];
                handleResultItemSelection({ item: item.item, itemType });
            }
        }
    };

    return (
        <SearchErrorBoundary>
            <QuickSearchWrapper spacing={0} ref={wrapperRef}>
                {isPlainInput ? (
                    <AsyncText
                        label={inputLabel}
                        onChange={handleQueryChange}
                        onFocus={handleQueryOnFocus}
                        autoFocus={true}
                        value={query}
                        loading={isLoading}
                        placeholder={quickSearchStrings.QuickSearchInput.placeholder}
                        onPressEnter={handleInputEnterPress}
                        asyncAction={triggerSearchQuery}
                        typeaheadThrottle={600}
                        fieldName={testIds.QUICK_SEARCH_TEXT_INPUT}
                        disabled={disabled}
                    />
                ) : (
                    <>
                        <Header spacing={cssVar('arc.space.3')}>
                            <SearchWrapper>
                                <InputWrapper
                                    ref={inputWrapperRef}
                                    data-test-id={testIds.QUICK_SEARCH_TEXT_INPUT}
                                >
                                    <SearchInputIcon icon="Search" />
                                    <QuickSearchInput
                                        onChange={handleQueryChange}
                                        onFocus={handleQueryOnFocus}
                                        autoFocus={true}
                                        value={query}
                                        loading={isLoading}
                                        placeholder={
                                            quickSearchStrings.QuickSearchInput.placeholder
                                        }
                                        onPressEnter={handleInputEnterPress}
                                        asyncAction={triggerSearchQuery}
                                        typeaheadThrottle={400}
                                        fieldName={testIds.QUICK_SEARCH_TEXT_INPUT}
                                    />
                                </InputWrapper>
                                <AdvancedSearchTypesMenu
                                    style={{
                                        flexShrink: '0',
                                    }}
                                    onButtonClick={handleAdvancedSearchButtonClick}
                                    onMenuItemClick={onClose}
                                    expanded={showAdvancedMenu}
                                    resultTypes={resultTypes}
                                />
                            </SearchWrapper>

                            {consortiumDepartmentLinksAvailable && (
                                <Checkbox
                                    value={excludeExternalAgencyResults}
                                    onChange={(e) =>
                                        handleExcludeExternalAgencyResultsChange(e as boolean)
                                    }
                                    label={quickSearchStrings.generic.hideExternalResults}
                                />
                            )}
                        </Header>
                        {query.length > 1 && <Divider />}
                    </>
                )}
                <ResultsWrapper ref={resultsWrapperRef.current}>
                    <QuickSearchResults
                        results={results}
                        resultTypes={resultTypes}
                        hasResults={hasResults}
                        error={error}
                        query={query}
                        isLoading={isLoading}
                        isSectionLoadingForType={isSectionLoadingForType}
                        onResultClick={onSearchResultClick || handleResultItemSelection}
                        excludeExternalAgencyResults={excludeExternalAgencyResults}
                        minQueryLength={MIN_QUERY_LENGTH}
                        maxQueryLength={MAX_QUERY_LENGTH}
                        triggerQuickSearchLoadMoreForType={handleTriggerQuickSearchLoadMoreForType}
                    />
                </ResultsWrapper>
            </QuickSearchWrapper>
        </SearchErrorBoundary>
    );
};

const QuickSearch = ({ allowedEntityTypes = allEntityTypes, ...props }: QuickSearchProps) => {
    const dispatch = useDispatch<RmsDispatch>();

    const results = useSelector(quickSearchResultsSelector);
    const isLoading = useSelector(quickSearchisLoadingSelector);
    const isSectionLoadingForType = useSelector(quickSearchIsSectionLoadingForTypeSelector);
    const error = useSelector(quickSearchErrorSelector);
    const hasResults = useSelector(quickSearchHasResultsSelector);
    const query = useSelector(quickSearchQuerySelector);
    const resultTypes = useSelector(quickSearchDefaultResultTypesSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const consortiumDepartmentLinksAvailable = useSelector(
        consortiumDepartmentLinksAvailableSelector
    );

    const triggerQuickSearch = React.useCallback(
        ({ query, allowedEntityTypes, excludeExternalAgencyResults }: TriggerQuickSearchOptions) =>
            dispatch(
                _triggerQuickSearch({
                    query,
                    allowedEntityTypes,
                    excludeExternalAgencyResults,
                })
            ),
        [dispatch]
    );
    const triggerQuickSearchLoadMoreForType = React.useCallback(
        ({ type, query, excludeExternalAgencyResults }: TriggerQuickSearchLoadMoreForTypeOptions) =>
            dispatch(
                _triggerQuickSearchLoadMoreForType({ type, query, excludeExternalAgencyResults })
            ),
        [dispatch]
    );
    const resetQuickSearchState = React.useCallback(() => dispatch(_resetQuickSearchState()), [
        dispatch,
    ]);
    const updateQuickSearchQuery = React.useCallback(
        (query: QuickSearchBaseProps['query']) => dispatch(_updateQuickSearchQuery(query)),
        [dispatch]
    );
    const handleCadResultClick = React.useCallback(
        (props: unknown) =>
            // @ts-expect-error client-common transport to client
            dispatch(advancedSearchCadTickets.actionCreators.openSearchResult(props)),
        [dispatch]
    );

    return (
        <QuickSearchBase
            {...props}
            allowedEntityTypes={allowedEntityTypes}
            results={results}
            isLoading={isLoading}
            isSectionLoadingForType={isSectionLoadingForType}
            error={error}
            hasResults={hasResults}
            query={query}
            resultTypes={resultTypes}
            applicationSettings={applicationSettings}
            consortiumDepartmentLinksAvailable={consortiumDepartmentLinksAvailable}
            triggerQuickSearch={triggerQuickSearch}
            triggerQuickSearchLoadMoreForType={triggerQuickSearchLoadMoreForType}
            resetQuickSearchState={resetQuickSearchState}
            updateQuickSearchQuery={updateQuickSearchQuery}
            handleCadResultClick={handleCadResultClick}
        />
    );
};
export default withRouter(QuickSearch);

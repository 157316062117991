import { createSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { departmentProfilesSelector } from '~/client-common/core/domain/department-profiles/state/data';
import getUserProfileResource from '~/client-common/core/domain/user-profiles/resources/userProfileResource';
import {
    associatedDepartmentProfilesSelector,
    currentUserDepartmentIdSelector,
    currentUserDepartmentProfileSelector,
} from '../../../current-user/state/ui';
import { openBox, closeBox, saveBoxFailure } from '../../../../../legacy-redux/actions/boxActions';
import { switchDepartments } from '../data';
import { openConfirmationBar } from '../../../confirmation-bar/state/ui';

export const ACCOUNT_SWITCHER_MODAL_CONTEXT = { name: boxEnum.ACCOUNT_SWITCHER_MODAL };

export const FORM_NAME = 'ACCOUNT_SWITCHER';

const SET_CAD_USER_SETTINGS = 'account-switcher/SET_CAD_USER_SETTINGS';

// For the account switcher modal, we want the current department to be the first option.
export const associatedDepartmentsOptionsSelector = createSelector(
    associatedDepartmentProfilesSelector,
    currentUserDepartmentProfileSelector,
    (associatedDepartmentProfiles, currentUserDepartmentProfile) => {
        const departmentProfiles = [currentUserDepartmentProfile, ...associatedDepartmentProfiles];
        return departmentProfiles.map((departmentProfile) => ({
            value: departmentProfile.departmentId,
            display: `${departmentProfile.displayName} (${departmentProfile.department.subDomain}) (${departmentProfile.department.departmentStatus})`,
        }));
    }
);

const uiSelector = (state) => state.ui.accountSwitcher;

export const cadUserSettingsSelector = createSelector(
    uiSelector,
    ({ cadUserSettings }) => cadUserSettings
);

// Open modal: default selected option should be the current department
export function openAccountSwitcherModal() {
    return (dispatch, getState, { formsRegistry }) => {
        const currUserDeptId = currentUserDepartmentIdSelector(getState());
        formsRegistry.maybeDeferredOperation(FORM_NAME, undefined, (form) => {
            form.set('departmentId', currUserDeptId);
        });
        dispatch(openBox(ACCOUNT_SWITCHER_MODAL_CONTEXT));
    };
}

// Submit the account switcher form. Should go to BE endpoint and then redirect to the selected department.
export function submitAccountSwitcherForm() {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(FORM_NAME);
        return form.submit().then((result) => {
            const fromDeptId = currentUserDepartmentIdSelector(getState());
            const toDeptId = result.form.get('departmentId');
            if (toDeptId && toDeptId !== fromDeptId) {
                const toSubDomain = departmentProfilesSelector(getState())[toDeptId].department
                    .subDomain;
                return dispatch(switchDepartments(toSubDomain)).catch((err) => {
                    dispatch(saveBoxFailure(ACCOUNT_SWITCHER_MODAL_CONTEXT, err.message));
                });
            } else {
                dispatch(closeBox(ACCOUNT_SWITCHER_MODAL_CONTEXT));
            }
        });
    };
}

// Switching and error handling when not using the modal
export function switchDeptsFromNavDropdown(subDomain) {
    return (dispatch) =>
        dispatch(switchDepartments(subDomain)).catch((err) =>
            dispatch(
                openConfirmationBar({
                    message: err.message,
                    isError: true,
                })
            )
        );
}

// in a consortium setup, sometimes a department will not have CAD product module enabled,
// but one of the other departments in its consortium will have this enabled.
// essentially, this verifies if any department in a consortium has the CAD product module enabled.
// this will also set the CAD related data that will be needed for permissions.
export function loadCadSettingsForCurrentUser() {
    return (dispatch) => {
        return getUserProfileResource()
            .getCadUserSettings()
            .then((cadUserSettingsView) => {
                dispatch(setCadUserSettings(cadUserSettingsView));
            })
            .catch(() => {
                dispatch(setCadUserSettings(defaultState));
            });
    };
}

function setCadUserSettings(cadUserSettings) {
    return {
        type: SET_CAD_USER_SETTINGS,
        payload: cadUserSettings,
    };
}

const defaultState = {
    cadUserSettings: {
        cadDepartmentId: undefined,
        hasCadModule: undefined,
        hasFirstResponderAbility: undefined,
        hasDispatcherAbility: undefined,
    },
};

export default function AccountSwitcherUiReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_CAD_USER_SETTINGS:
            return {
                ...state,
                cadUserSettings: action.payload,
            };
        default:
            return state;
    }
}

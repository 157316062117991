import { EntityTypeEnum, UsageSourceModuleEnum, UsageActionEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withHandlers, pure, withPropsOnChange } from 'recompose';
import { map, size, compact, first, findIndex, isFunction } from 'lodash';

import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';
import {
    augmentedAttachmentViewModelsWhereSelector,
    convertAttachmentViewModelsForLightboxGallery,
} from '~/client-common/core/domain/attachments/state/ui';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import AttachmentViewToggleButton, {
    attachmentViews,
} from '../../../attachments/core/components/AttachmentViewToggleButton';
import _TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import { openImageGalleryLightbox } from '../../../../legacy-redux/actions/imageGalleryLightboxActions';
import { exportFiles } from '../../../../legacy-redux/actions/exportsActions';
import Icon, { iconTypes } from '../../../core/components/Icon';
import TableHeaderButton from '../../../../legacy-redux/components/core/tables/TableHeaderButton';
import AttachmentRow from '../../../attachments/core/components/AttachmentRow';
import AttachmentGridItem from '../../../attachments/core/components/AttachmentGridItem';
import { downloadSelectFile } from '../../../attachments/files/helpers';

import _OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import { createUsageLog } from '../../../admin/usage-logs/state/data';

const strings = componentStrings.core.cards.AttachmentsCard;

const NUM_OF_RESULTS_ON_PAGE = 30;

const tableWidths = {
    displayName: 210,
    createdDateUtc: 200,
    export: 47,
};

const sortTypes = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
};

const TableHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const TableResultsSummary = styled(_TableResultsSummary)`
    margin-right: 10px;
    display: inline-block;
`;

const AttachmentsTableHeaders = styled.table`
    width: 100%;
    padding-top: 40px;
`;

const AttachmentHeader = styled.tr`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const AttachmentHeaderCell = styled.td`
    text-align: left;

    &:last-child {
        padding-right: 7px;
    }
`;

const DirectionIcon = styled(Icon)`
    margin-left: 5px;
`;

const OptionsTableColumn = styled(_OptionsTableColumn)`
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.cobaltBlue};
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;

    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

const OptionTableColumnDisplayContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CenteredCardSection = styled.div`
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
`;

const PaddedNoDataBlock = styled.div`
    padding: 10px 20px;
`;

const Footer = styled.div`
    text-align: right;
    clear: both;
    padding: 16px 0;
`;

function getPagination(items, page, pageSize = NUM_OF_RESULTS_ON_PAGE) {
    const zeroBasedPage = page - 1;
    const sliceOffset = zeroBasedPage * pageSize;
    const results = items.slice(sliceOffset, sliceOffset + pageSize);

    const paginationItemsTo = sliceOffset + results.length;

    return {
        paginationItemsFrom: sliceOffset,
        paginationItemsTo,
        numTotalResults: items.length,
        results,
    };
}

function NotepadPhotosContent({
    attachmentRows,
    summaryMode,
    sortKey,
    sortType,
    fileGridAttachments,
    paginationItemsFrom,
    paginationItemsTo,
    totalAttachments,
    currentView,
    currentPage,
    setSort,
    toggleView,
    handlePaginationClick,
}) {
    return (
        <div>
            {size(fileGridAttachments) > 0 && (
                <div>
                    <TableHeader>
                        <div>
                            <TableResultsSummary
                                from={paginationItemsFrom}
                                to={paginationItemsTo}
                                totalResults={totalAttachments.length}
                                caption=""
                            />
                        </div>
                    </TableHeader>
                    <AttachmentsTableHeaders>
                        <thead>
                            {currentView === attachmentViews.LIST ? (
                                <AttachmentHeader>
                                    <AttachmentHeaderCell width={tableWidths.displayName}>
                                        <TableHeaderButton
                                            activeSortKey={sortKey}
                                            activeSortType={sortType}
                                            sortKey="displayName"
                                            onClick={setSort}
                                        >
                                            {strings.name}
                                        </TableHeaderButton>
                                    </AttachmentHeaderCell>
                                    <AttachmentHeaderCell width={tableWidths.createdDateUtc}>
                                        <TableHeaderButton
                                            activeSortKey={sortKey}
                                            activeSortType={sortType}
                                            sortKey="createdDateUtc"
                                            onClick={setSort}
                                        >
                                            {strings.uploadDate}
                                        </TableHeaderButton>
                                    </AttachmentHeaderCell>
                                    <AttachmentHeaderCell width={tableWidths.export}>
                                        <AttachmentViewToggleButton
                                            currentView={currentView}
                                            onClick={toggleView}
                                        />
                                    </AttachmentHeaderCell>
                                </AttachmentHeader>
                            ) : (
                                <AttachmentHeader>
                                    <AttachmentHeaderCell>
                                        <OptionsTableColumn
                                            display={(data) => (
                                                <OptionTableColumnDisplayContainer>
                                                    {data.display}
                                                    <DirectionIcon
                                                        color="cobaltBlue"
                                                        type={
                                                            sortType === sortTypes.DESCENDING
                                                                ? iconTypes.ARROW_UP
                                                                : iconTypes.ARROW_DOWN
                                                        }
                                                        size={10}
                                                    />
                                                </OptionTableColumnDisplayContainer>
                                            )}
                                            activeValue={sortKey}
                                            activeSortType={sortType}
                                            onOptionClick={(data) => setSort(data.value)}
                                            onSortTypeClick={() => setSort(sortKey)}
                                            popoutWidth={160}
                                        >
                                            <OptionsTableColumnOption
                                                display={strings.name}
                                                value={'displayName'}
                                                sortOptions={[
                                                    {
                                                        display: `${strings.name} A-Z`,
                                                        sortType: sortTypes.ASCENDING,
                                                    },
                                                    {
                                                        display: `${strings.name} Z-A`,
                                                        sortType: sortTypes.DESCENDING,
                                                    },
                                                ]}
                                            />
                                            <OptionsTableColumnOption
                                                display={strings.uploadDate}
                                                value={'createdDateUtc'}
                                                sortOptions={[
                                                    {
                                                        display: strings.sortDateAsc,
                                                        sortType: sortTypes.ASCENDING,
                                                    },
                                                    {
                                                        display: strings.sortDateDesc,
                                                        sortType: sortTypes.DESCENDING,
                                                    },
                                                ]}
                                            />
                                        </OptionsTableColumn>
                                    </AttachmentHeaderCell>
                                    <AttachmentHeaderCell width={tableWidths.createdDateUtc} />
                                    <AttachmentHeaderCell width={tableWidths.export}>
                                        <AttachmentViewToggleButton
                                            currentView={currentView}
                                            onClick={toggleView}
                                        />
                                    </AttachmentHeaderCell>
                                </AttachmentHeader>
                            )}
                        </thead>
                    </AttachmentsTableHeaders>
                    {attachmentRows}
                    {totalAttachments.length > NUM_OF_RESULTS_ON_PAGE && (
                        <Footer>
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={NUM_OF_RESULTS_ON_PAGE}
                                itemCount={totalAttachments.length}
                                onClick={handlePaginationClick}
                                maxEdgeItems={1}
                            />
                        </Footer>
                    )}
                </div>
            )}
            <CenteredCardSection>
                {totalAttachments.length < 1 && summaryMode && (
                    <PaddedNoDataBlock>{strings.noData}</PaddedNoDataBlock>
                )}
            </CenteredCardSection>
        </div>
    );
}

class NotepadPhotos extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            currentView: attachmentViews.LIST,
            sortType: sortTypes.ASCENDING,
            sortKey: 'displayName',
            currentPage: 1,
        };

        this.onEdit = this.onEdit.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handlePaginationClick = this.handlePaginationClick.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.setSort = this.setSort.bind(this);
    }

    onEdit() {
        // Attachments card on reports uses `editMediator`, which is unused for warrants.
        if (isFunction(this.props.editCallback)) {
            this.props.editCallback(() => this.props.onEdit(), this.props.canEdit);
        } else {
            this.props.onEdit();
        }
    }

    onSave() {
        return this.props.onSave();
    }

    handlePaginationClick(currentPage, nextPage) {
        this.setState({ currentPage: nextPage });
    }

    toggleView() {
        this.setState({
            currentView:
                this.state.currentView === attachmentViews.LIST
                    ? attachmentViews.GRID
                    : attachmentViews.LIST,
        });
    }

    setSort(sortKey) {
        this.setState({
            sortKey,
            sortType:
                this.state.sortKey === sortKey && this.state.sortType === sortTypes.ASCENDING
                    ? sortTypes.DESCENDING
                    : sortTypes.ASCENDING,
        });
    }

    render() {
        const {
            fileGridAttachments,
            generateExportAttachmentUsageLog,
            download,
            openLightbox,
        } = this.props;

        const { currentView, sortKey, sortType, currentPage } = this.state;
        const totalAttachments = sortByNaturalOrder(
            fileGridAttachments,
            [sortKey],
            [sortType === 'ASCENDING' ? 'asc' : 'desc']
        );

        const {
            paginationItemsFrom,
            paginationItemsTo,
            results, // this gets the sliced results
        } = getPagination(totalAttachments, this.state.currentPage);

        const attachmentRows =
            currentView === attachmentViews.LIST ? (
                <div>
                    {map(results, (attachment) => (
                        <AttachmentRow
                            tableWidths={tableWidths}
                            onClickForLightBox={openLightbox(totalAttachments, attachment.id)}
                            onDownload={() => generateExportAttachmentUsageLog(attachment)}
                            key={attachment.id}
                            attachment={attachment}
                            columns={['displayName', 'createdDateUtc', 'export']}
                        />
                    ))}
                </div>
            ) : (
                map(results, (attachment) => (
                    <AttachmentGridItem
                        onClickForLightBox={openLightbox(totalAttachments, attachment.id)}
                        onDownload={() => generateExportAttachmentUsageLog(attachment)}
                        key={attachment.id}
                        attachment={attachment}
                    />
                ))
            );
        return (
            <div>
                <NotepadPhotosContent
                    attachmentRows={attachmentRows}
                    paginationItemsTo={paginationItemsTo}
                    paginationItemsFrom={paginationItemsFrom}
                    sortKey={sortKey}
                    sortType={sortType}
                    fileGridAttachments={fileGridAttachments}
                    download={download}
                    totalAttachments={totalAttachments}
                    currentView={currentView}
                    currentPage={currentPage}
                    handleSearchChange={this.handleSearchChange}
                    setSort={this.setSort}
                    toggleView={this.toggleView}
                    handlePaginationClick={this.handlePaginationClick}
                />
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    augmentedAttachmentViewModelsWhere: augmentedAttachmentViewModelsWhereSelector,
    dateTimeFormatter: currentDepartmentDateFormatterSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    download: (attachments) => {
        const { entityType, entityId, entityTitle } = ownProps;
        const files = map(attachments, getAttachmentFile);
        const fileIds = compact(map(files, 'id'));
        const bulkDownloadTitle = componentStrings.attachments.core.bulkDownloadTitle(entityTitle);

        const usageLog = {
            primaryEntityType: entityType,
            primaryEntityId: entityId,
            sourceModule:
                entityType === EntityTypeEnum.REPORT.name
                    ? UsageSourceModuleEnum.REPORTS.name
                    : UsageSourceModuleEnum.WARRANTS.name,
        };

        if (size(fileIds) === 1) {
            const firstFile = first(files);
            downloadSelectFile(firstFile.fileWebServerPath, firstFile.originalFileName);
            dispatch(
                createUsageLog({
                    ...usageLog,
                    primaryEntityTitle: `${entityTitle}: ${firstFile.id} - ${firstFile.originalFileName}`,
                    action: UsageActionEnum.DOWNLOADED_ATTACHMENT.name,
                })
            );
        } else if (size(fileIds) > 1) {
            dispatch(exportFiles(fileIds, bulkDownloadTitle));
            dispatch(
                createUsageLog({
                    ...usageLog,
                    primaryEntityTitle: `${entityTitle} (${fileIds.length} Attachments)`,
                    action: UsageActionEnum.DOWNLOADED_BULK_ATTACHMENTS.name,
                })
            );
        }
    },
    generateExportAttachmentUsageLog: (attachment) => {
        const { entityType, entityId, entityTitle } = ownProps;
        const file = getAttachmentFile(attachment);
        const usageLog = {
            primaryEntityType: entityType,
            primaryEntityId: entityId,
            primaryEntityTitle: `${entityTitle}: ${file.id} - ${file.originalFileName}`,
            action: UsageActionEnum.DOWNLOADED_ATTACHMENT.name,
            sourceModule:
                entityType === EntityTypeEnum.REPORT.name
                    ? UsageSourceModuleEnum.REPORTS.name
                    : UsageSourceModuleEnum.WARRANTS.name,
        };
        dispatch(createUsageLog(usageLog));
    },
    openImageGalleryLightbox: (images, index) => {
        const { entityType, entityTitle } = ownProps;
        const source =
            entityType === EntityTypeEnum.REPORT.name
                ? UsageSourceModuleEnum.REPORTS.name
                : UsageSourceModuleEnum.WARRANTS.name;
        const usageLogParamsForAttachments = {
            primaryEntityTitle: entityTitle,
            sourceModule: source,
        };
        dispatch(openImageGalleryLightbox(images, index, usageLogParamsForAttachments));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['entityType', 'entityId', 'linkType', 'augmentedAttachmentViewModelsWhere'],
        ({ entityType, entityId, linkType, augmentedAttachmentViewModelsWhere }) => {
            const fileGridAttachments = augmentedAttachmentViewModelsWhere({
                entityType,
                entityId,
                linkType,
            });
            return {
                fileGridAttachments,
            };
        }
    ),
    withHandlers({
        openLightbox: ({ openImageGalleryLightbox, dateTimeFormatter }) => (
            attachments,
            attachmentId
        ) => () => {
            const index = findIndex(attachments, { id: attachmentId });
            openImageGalleryLightbox(
                convertAttachmentViewModelsForLightboxGallery(attachments, dateTimeFormatter),
                index
            );
        },
    }),
    pure
)(NotepadPhotos);

import { Detention } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createNormalizedModule from '~/client-common/core/utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'detentions';

const detentionModule = createNormalizedModule<Detention>({
    type: NEXUS_STATE_PROP,
});

const detentionsWhereSelector = detentionModule.selectors.entitiesWhereSelector;

export const detentionsForReportIdSelector = createSelector(
    detentionsWhereSelector,
    (detentionsWhere) => (reportId: number) => detentionsWhere({ reportId })
);

export default detentionModule.reducerConfig;

import { map } from 'lodash';
import { vehiclesWhereSelector } from '~/client-common/core/domain/vehicles/state/data';
import {
    filterRecoveredInvolvedItemsSelector,
    filterStolenInvolvedItemsSelector,
} from '~/client-common/core/domain/property-statuses/state/data';
import { offensesByReportIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import { currentReportSelector } from '../../../legacy-redux/selectors/reportSelectors';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

export const getOffenseHasLessThanOrEqualRecoveredVehiclesThanStolen = (
    getState: () => RootState
) => () => {
    const state = getState();
    if (!checkIfDepartmentIsNibrs(currentUserDepartmentProfileSelector(state))) {
        return true;
    }
    const currentReport = currentReportSelector(state);
    const currentReportId = currentReport?.id;
    const offenses = !!currentReportId ? offensesByReportIdSelector(state)(currentReportId) : [];
    const vehicles = vehiclesWhereSelector(state)({ ownerId: currentReportId });

    if (!currentReport) {
        return true;
    }

    const stolenVehicles = filterStolenInvolvedItemsSelector(state)(
        map(vehicles, 'itemId'),
        map(offenses, 'id'),
        currentReport.departmentId
    );
    const recoveredVehicles = filterRecoveredInvolvedItemsSelector(state)(
        map(vehicles, 'itemId'),
        map(offenses, 'id'),
        currentReport.departmentId
    );
    return recoveredVehicles.length <= stolenVehicles.length;
};

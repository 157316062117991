import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { lifecycleOptions } from 'markformythree';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import overlayStateTypeEnum from '~/client-common/core/enums/client/overlayStateTypeEnum';

import { towVehicleByReportIdSelector } from '~/client-common/core/domain/tow-vehicles/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';

import {
    onCloseTowVehicleCheckInSidePanel,
    saveTowVehicleCheckInSidePanel,
} from '../../state/ui/tow-vehicle-check-in-side-panel/towVehicleCheckInSidePanel';
import {
    TOW_VEHICLE_STORAGE_LOCATION_PATH,
    TOW_VEHICLE_STORAGE_LOCATION_DESCRIPTION_FULL_PATH,
    TOW_VEHICLE_STORAGE_LOCATION_POSITION_ATTR_ID_FULL_PATH,
    formName,
} from '../../state/forms/towVehicleCheckInForm';
import { getErrorMessagesFromErrors } from '../../helpers/validationHelpers';
import {
    PortalSidePanel,
    SidePanelSection,
} from '../../../../../legacy-redux/components/core/SidePanel';
import { OverlayBaseHelper } from '../../../../core/components/OverlayBaseHelper';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import Row from '../../../../core/components/Row';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import testIds from '../../../../../core/testIds';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

const mapStateToProps = createStructuredSelector({
    towVehicleByReportId: towVehicleByReportIdSelector,
    formatFieldByName: formatFieldByNameSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onCloseTowVehicleCheckInSidePanel: ({ reportId }) =>
        dispatch(onCloseTowVehicleCheckInSidePanel({ reportId })),
    saveTowVehicleCheckInSidePanel: ({ reportId }) =>
        dispatch(saveTowVehicleCheckInSidePanel({ reportId })),
});

const TowVehicleCheckInSidePanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(function _TowVehicleCheckInSidePanel({
    reportId,
    towVehicleByReportId,
    onCloseTowVehicleCheckInSidePanel,
    saveTowVehicleCheckInSidePanel,
    formatFieldByName,
    currentReportCardUITitleByType,
}) {
    const cardTitle = currentReportCardUITitleByType(reportCardEnum.CHECK_IN_INFORMATION.id);
    return (
        <OverlayBaseHelper
            id={overlayIdEnum.TOW_VEHICLE_CHECK_IN_SIDE_PANEL}
            overlayStateType={overlayStateTypeEnum.TOW_VEHICLE_CHECK_IN_OVERLAY}
        >
            {({
                overlayBase: { setError, ...overlayProps },
                screenManagerApi,
                savePanel,
                closePanel,
                ...renderProps
            }) => {
                const { id: towVehicleId } = towVehicleByReportId(reportId);
                const { getCurrentScreen, setCurrentScreenState } = screenManagerApi;
                const {
                    screenState: { isPerformingAsyncAction },
                } = getCurrentScreen();
                const {
                    overlayState: { errors },
                } = overlayProps;
                const onCancelSidePanel = () => {
                    setCurrentScreenState({ isPerformingAsyncAction: false });
                    onCloseTowVehicleCheckInSidePanel({ reportId });
                    setError([]);
                    closePanel();
                };
                const onSaveSidePanel = () => {
                    setCurrentScreenState({ isPerformingAsyncAction: true });
                    saveTowVehicleCheckInSidePanel({ reportId })
                        .then(() => {
                            setCurrentScreenState({ isPerformingAsyncAction: false });
                            setError([]);
                            savePanel();
                        })
                        .catch((formErrors) => {
                            setCurrentScreenState({ isPerformingAsyncAction: false });
                            setError(getErrorMessagesFromErrors(formErrors));
                        });
                };

                return (
                    <PortalSidePanel
                        errorMessages={errors}
                        // always true -- we always want panel level error messages to show.
                        userHasAttemptedSave={true}
                        title={cardTitle}
                        testId={testIds.CHECK_IN_INFORMATION_SIDE_PANEL}
                        closePanel={onCancelSidePanel}
                        savePanel={onSaveSidePanel}
                        saveDisabled={isPerformingAsyncAction}
                        cancelDisabled={isPerformingAsyncAction}
                        {...renderProps}
                        {...overlayProps}
                    >
                        <ArbiterForm
                            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                            name={formName}
                            context={formName}
                            render={(form) => {
                                return (
                                    <>
                                        <Row>
                                            <ArbiterMFTText path="lotLocation" length="lg" />
                                            <ArbiterMFTTextArea path="damageOnVehicle" rows={4} />
                                            <ArbiterMFTBooleanSelect
                                                path="licenseInVehicle"
                                                length="md"
                                            />
                                            <ArbiterMFTBooleanSelect
                                                path="insuranceInVehicle"
                                                length="md"
                                            />
                                            <ArbiterMFTBooleanSelect
                                                path="registrationInVehicle"
                                                length="md"
                                            />
                                            <ArbiterMFTBooleanSelect
                                                path="vehicleHeldAsEvidence"
                                                length="md"
                                            />
                                        </Row>
                                        <SidePanelSection
                                            title={formatFieldByName(
                                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_STORAGE_LOCATION_LOCATION_ID
                                            )}
                                        >
                                            <LocationSummaryViewWrapperWithFormFields
                                                summaryMode={false}
                                                onLocationAdd={(
                                                    location,
                                                    locationEntityLinkModel
                                                ) => {
                                                    form.set(
                                                        TOW_VEHICLE_STORAGE_LOCATION_PATH,
                                                        locationEntityLinkModel
                                                    );
                                                }}
                                                onLocationRemove={() => {
                                                    form.set(
                                                        TOW_VEHICLE_STORAGE_LOCATION_PATH,
                                                        undefined
                                                    );
                                                }}
                                                entityType={EntityTypeEnum.RMS_TOW_VEHICLE.name}
                                                entityId={towVehicleId}
                                                linkType={
                                                    LinkTypesEnum.TOW_VEHICLE_STORAGE_LOCATION
                                                }
                                                locationDescriptionPath={() =>
                                                    TOW_VEHICLE_STORAGE_LOCATION_DESCRIPTION_FULL_PATH
                                                }
                                                locationPositionAttrIdPath={() =>
                                                    TOW_VEHICLE_STORAGE_LOCATION_POSITION_ATTR_ID_FULL_PATH
                                                }
                                            />
                                        </SidePanelSection>
                                    </>
                                );
                            }}
                        />
                    </PortalSidePanel>
                );
            }}
        </OverlayBaseHelper>
    );
});

export default TowVehicleCheckInSidePanel;

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import { ManageEvidenceDashboardDataTable } from '../components/data-table/manage-evidence/tables/ManageEvidenceDataTable';
import { evidenceDashboardSearch } from '../state/ui';

// Legacy imports
import { PageContent } from '../../../core/components/PageContent';
import ScrollableUnderSubheader, {
    BaseScrollableUnderSubheader,
} from '../../../core/components/ScrollableUnderSubheader';
import TableFooter from '../../search-results/components/ResultsTableFooter';
import SearchResults from '../../search-results/containers/SearchResults';
import SearchForm from '../components/EvidenceDashboardSearchForm';
import Row from '../components/EvidenceDashboardSearchResultRow';
import _EvidenceDashboardSearchResultsFooter from '../components/EvidenceDashboardSearchResultsFooter';
import TableBody from '../components/EvidenceDashboardSearchResultsTableBody';
import TableHeader from '../components/EvidenceDashboardSearchResultsTableHeader';

const querySelector = evidenceDashboardSearch.selectors.currentQuerySelector;

const EvidenceDashboardSearchResultsFooter = connect(
    createStructuredSelector({
        allResultsSelected: evidenceDashboardSearch.selectors.allResultsSelectedSelector,
        queryResults: evidenceDashboardSearch.selectors.currentResultsViewModelsSelector,
        querySize: createSelector(querySelector, (currentQuery) => currentQuery.size),
        selectedRows: evidenceDashboardSearch.selectors.selectedRowsSelector,
        totalResults: evidenceDashboardSearch.selectors.totalCountSelector,
    }),
    {
        handleSelectAllRows: evidenceDashboardSearch.actionCreators.selectAllResults,
        handleClearAllSelectedRows: evidenceDashboardSearch.actionCreators.selectRows,
    }
)(_EvidenceDashboardSearchResultsFooter);

const LegacyManageEvidenceDashboard = ({ className, openSearchResult }) => (
    <>
        <ScrollableUnderSubheader contentClassnames="dashboard-under-subheader">
            <div className="dashboard-content-container">
                <div className={className}>
                    <SearchForm />
                    <SearchResults
                        TableHeader={TableHeader}
                        TableBody={TableBody}
                        TableFooter={TableFooter}
                        rowComponent={Row}
                        columnSpan={5}
                        handleSortKeyClickAction={evidenceDashboardSearch.actionCreators.search}
                        handleSortTypeClickAction={evidenceDashboardSearch.actionCreators.search}
                        handlePaginationClickAction={evidenceDashboardSearch.actionCreators.search}
                        handleRowClickAction={openSearchResult}
                        handleSelectRowsAction={evidenceDashboardSearch.actionCreators.selectRows}
                        handleSizeChangeAction={evidenceDashboardSearch.actionCreators.search}
                        handleSelectAllRows={
                            evidenceDashboardSearch.actionCreators.selectAllResults
                        }
                        handleClearAllSelectedRows={
                            evidenceDashboardSearch.actionCreators.selectRows
                        }
                        queryFromSelector={createSelector(
                            querySelector,
                            (currentQuery) => currentQuery.from
                        )}
                        querySizeSelector={createSelector(
                            querySelector,
                            (currentQuery) => currentQuery.size
                        )}
                        querySortKeySelector={createSelector(
                            querySelector,
                            (currentQuery) => currentQuery.sortKey
                        )}
                        querySortTypeSelector={createSelector(
                            querySelector,
                            (currentQuery) => currentQuery.sortType
                        )}
                        queryResultsSelector={
                            evidenceDashboardSearch.selectors.currentResultsViewModelsSelector
                        }
                        selectableRows={true}
                        selectedRowsSelector={
                            evidenceDashboardSearch.selectors.selectedRowsSelector
                        }
                        totalResultsSelector={evidenceDashboardSearch.selectors.totalCountSelector}
                        tableCheckboxMargin="10px"
                    />
                </div>
            </div>
        </ScrollableUnderSubheader>
        <EvidenceDashboardSearchResultsFooter />
    </>
);

export const LegacyEvidenceDashboardWrapper = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    padding: var(--arc-space-4) var(--arc-space-6) 0;
    background: var(--arc-colors-surface-foreground);
    gap: var(--arc-space-3);
`;

export const EvidenceDashboardWrapper = styled(PageContent)`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;

    /* Style overrides to get the current Search Form to work with the
    updated dashboard layout */
    .evidence-dashboard__search-form {
        background-color: var(--arc-colors-surface-foreground);
    }
    .evidence-dashboard__search-well {
        margin-top: 0;
    }
`;

export const EvidenceTableWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 50vh; // Only here for use with the old SearchForm
    background-color: var(--arc-colors-surface-foreground);
`;

const EvidenceDashboard = ({ className, openSearchResult }) => {
    return (
        <FeatureFlagged
            flag="RMS_EVIDENCE_DASHBOARD_REFRESH_ENABLED"
            fallback={
                <LegacyEvidenceDashboardWrapper>
                    <LegacyManageEvidenceDashboard
                        className={className}
                        openSearchResult={openSearchResult}
                    />
                </LegacyEvidenceDashboardWrapper>
            }
        >
            <BaseScrollableUnderSubheader fullscreen contentClassnames="dashboard-under-subheader">
                <EvidenceDashboardWrapper fullscreen>
                    <SearchForm />
                    <EvidenceTableWrapper>
                        <ManageEvidenceDashboardDataTable />
                    </EvidenceTableWrapper>
                </EvidenceDashboardWrapper>
            </BaseScrollableUnderSubheader>
        </FeatureFlagged>
    );
};

const mapDispatchToProps = (dispatch, { router }) => ({
    openSearchResult: (elasticEvidenceItem, rowIndex) =>
        dispatch(
            evidenceDashboardSearch.actionCreators.openSearchResult(
                elasticEvidenceItem,
                rowIndex,
                router
            )
        ),
});

/**
 * Route component for the evidence dashboard.
 */
export default compose(withRouter, connect(null, mapDispatchToProps))(EvidenceDashboard);

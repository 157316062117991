import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { createStructuredSelector } from 'reselect';
import { reportShortTitleViewModelByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { formatReportReportLinkTitleSelector } from '~/client-common/core/domain/report-report-links/state/ui';
import {
    computeOtherReportId,
    computeCanReadReportReportLink,
} from '~/client-common/core/domain/report-report-links/utils';
import { elasticReportByIdSelector } from '~/client-common/core/domain/elastic-reports/state/data';
import testIds from '../../../../core/testIds';
import { PopoutLink as _PopoutLink } from '../../../core/components/links/Link';

const PopoutLink = styled(_PopoutLink)`
    &:hover {
        text-decoration: underline;
    }
`;

function ReportReportLinkTitle({
    reportLink,
    currentReportId,
    reportShortTitleViewModelByReportId,
    elasticReportById,
    formatReportReportLinkTitle,
}) {
    const otherReportId = computeOtherReportId({
        currentReportId,
        fromReportId: reportLink.fromReportId,
        toReportId: reportLink.toReportId,
    });
    const canRead = computeCanReadReportReportLink({
        reportId: otherReportId,
        reportShortTitleViewModelByReportId,
        elasticReportById,
    });
    const title = formatReportReportLinkTitle({
        currentReportId,
        reportLink,
    });

    return (
        <span>
            {canRead ? (
                <PopoutLink to={`reports/${otherReportId}`} testId={testIds.REPORT_REPORT_LINK}>
                    {title}
                </PopoutLink>
            ) : (
                <div>{title}</div>
            )}
        </span>
    );
}

const mapStateToProps = createStructuredSelector({
    reportShortTitleViewModelByReportId: reportShortTitleViewModelByReportIdSelector,
    elasticReportById: elasticReportByIdSelector,
    formatReportReportLinkTitle: formatReportReportLinkTitleSelector,
});

export default connect(mapStateToProps)(ReportReportLinkTitle);

import React from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName } from 'recompose';
import { size } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { nibrsOffenseCodeOptionsForOffenseCodeIdSelector } from '~/client-common/core/domain/offense-code-nibrs-code-links/state/data';
import { arbiterMFTInput } from '../../../arbiter';
import Select from './Select';

const { label } = componentStrings.forms.select.NibrsCodeSelect;

const FilteredNibrsOffenseCodeIdSelect = compose(
    setDisplayName('FilteredNibrsOffenseCodeIdSelect'),
    connect(
        createStructuredSelector({
            options: nibrsOffenseCodeOptionsForOffenseCodeIdSelector,
        })
    )
)(function NibrsOffenseCodeIdSelect({ options, ...otherProps }) {
    const selectOptions = options(otherProps.offenseCodeId);
    return (
        <Select
            label={label}
            {...otherProps}
            options={selectOptions}
            disabled={otherProps.disabled || size(selectOptions) <= 1}
        />
    );
});

export const ArbiterMFTFilteredNibrsOffenseCodeIdSelect = arbiterMFTInput(
    FilteredNibrsOffenseCodeIdSelect
);

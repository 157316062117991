import { RefContextEnum } from '@mark43/rms-api';
import { map } from 'lodash';
import { upsertWarrantCharges } from '~/client-common/core/domain/warrant-charges/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import validationStrings from '~/client-common/core/strings/validationStrings';
import { saveBoxFailure, closeBox, openBox } from '../../../../../legacy-redux/actions/boxActions';

export const WARRANT_CHARGES_BOX_CONTEXT = {
    name: boxEnum.WARRANT_CHARGES_SIDE_PANEL,
};

export function openWarrantChargesSidePanel({ warrantCharges, warrantId, createNew }) {
    return (dispatch, getState, { formsRegistry }) => {
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_WARRANT_CHARGES.name,
            undefined,
            (form) => {
                form.set('charges', warrantCharges);
            }
        );
        dispatch(
            openBox(WARRANT_CHARGES_BOX_CONTEXT, {
                warrantId,
                createNew,
            })
        );
    };
}

export function submitWarrantChargesForm({ warrantId } = {}) {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(RefContextEnum.FORM_WARRANT_CHARGES.name);

        return (
            form
                .submit()
                .then((result) => {
                    const warrantCharges = map(result.form.getState().model.charges, (charge) => ({
                        ...charge,
                        warrantId,
                    }));

                    return dispatch(upsertWarrantCharges(warrantCharges))
                        .then(() => {
                            dispatch(closeBox(WARRANT_CHARGES_BOX_CONTEXT));
                            form.resetModel();
                        })
                        .catch((err) =>
                            dispatch(saveBoxFailure(WARRANT_CHARGES_BOX_CONTEXT, err.message))
                        );
                })
                // purposely use the generic validation string because otherwise the message will cover the form
                .catch(() =>
                    dispatch(
                        saveBoxFailure(WARRANT_CHARGES_BOX_CONTEXT, validationStrings.panel.generic)
                    )
                )
        );
    };
}

import { reduce, keys, pickBy } from 'lodash';

import { LEFT_VALUE } from '../configuration/constants';

const computeMergedModel = ({ propsToPick, leftModel, rightModel }) =>
    pickBy(
        reduce(
            keys(propsToPick),
            (acc, prop) => {
                const value = propsToPick[prop];
                if (value === undefined) {
                    return acc;
                }
                const source = value === LEFT_VALUE ? leftModel : rightModel;
                acc[prop] = source[prop];
                return acc;
            },
            {}
        ),
        (value) => value !== undefined && value !== null
    );

export default computeMergedModel;

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Person Profile Resource',
        methods: {
            getAllContextedProfilesForMasterProfile(masterProfileId) {
                return req({
                    method: 'GET',
                    url: `person/profiles/${masterProfileId}`,
                });
            },
            getContextedOrMasterPersonProfile({ masterOrContextedProfileId, ownerType, ownerId }) {
                return req({
                    method: 'GET',
                    url: `person/profile/${masterOrContextedProfileId}/owner/${ownerType}/${ownerId}`,
                });
            },
            createPersonProfile(personProfile) {
                return req({
                    method: 'POST',
                    url: 'person/profile',
                    data: personProfile,
                });
            },
            upsertHydratedPerson({
                hydratedPerson,
                removeLocationsIfEmpty,
                removePersonGangTrackingIfEmpty,
            }) {
                return req({
                    method: 'POST',
                    url: 'person/hydrated',
                    data: hydratedPerson,
                    params: {
                        remove_locations_if_empty: removeLocationsIfEmpty,
                        remove_person_gang_if_empty: removePersonGangTrackingIfEmpty,
                    },
                });
            },
            identifyHydratedPerson(unknownContextedPersonId, hydratedPerson) {
                return req({
                    method: 'POST',
                    url: `person/hydrated/identify/${unknownContextedPersonId}`,
                    data: hydratedPerson,
                });
            },
            getPersonProfile(profileId) {
                return req({
                    method: 'GET',
                    url: `person/profile/${profileId}`,
                });
            },
            getDuplicatePersonCandidates() {
                return req({
                    method: 'GET',
                    url: 'person/profile/duplicate_candidates',
                });
            },
            updateDuplicatePersonCandidate(personDeduplicateBundle) {
                return req({
                    method: 'POST',
                    url: 'person/profile/duplicate_candidates',
                    data: personDeduplicateBundle,
                });
            },
            getRelationshipData(personProfileIds) {
                return req({
                    method: 'POST',
                    url: 'person/profiles/links/profiles',
                    data: personProfileIds,
                });
            },
            linkPersonPerson(toProfileId, fromProfileId, linkType) {
                return req({
                    method: 'POST',
                    url: `person/profiles/${fromProfileId}/links/profiles/${toProfileId}/type/${linkType}`,
                });
            },
        },
    });
}

/**
 * Resource for person profiles. This is temporarily a function to avoid
 *   dependency problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

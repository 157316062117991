import React from 'react';
import styled from 'styled-components';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import testIds from '../../../../core/testIds';
import { warrantsDashboardSearch } from '../state/ui';

const ActionButton = styled(Button)`
    margin-top: 16px;
    margin-bottom: 16px;
`;

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    white-space: pre-wrap;
`;

const strings = componentStrings.warrants.dashboard.results;

function ActionBarContent({
    onExportClick,
    allResultsSelected,
    allResultsOnPageSelected,
    totalResults,
    selectedResults,
    handleClearAllSelectedRows,
    handleSelectAllRows,
}) {
    const clickText = allResultsSelected ? strings.clearSelection : strings.selectAll(totalResults);
    let statusText;
    if (allResultsSelected) {
        statusText = strings.allSelected(totalResults);
    } else if (allResultsOnPageSelected) {
        statusText = strings.allOnPageSelected(selectedResults.length);
    } else {
        statusText = strings.someOnPageSelected(selectedResults.length);
    }
    return (
        <div>
            <FlexWrapper>
                <ActionButton
                    className={buttonTypes.SECONDARY}
                    onClick={onExportClick}
                    testId={testIds.WARRANTS_DASHBOARD_EXPORT}
                >
                    {strings.actionButtons.export}
                </ActionButton>
                {`${statusText} `}
                <span
                    className="select-action"
                    onClick={allResultsSelected ? handleClearAllSelectedRows : handleSelectAllRows}
                    data-test-id={testIds.WARRANTS_DASHBOARD_ACTION_BAR_SELECT_ALL_RESULTS}
                >
                    {clickText}
                </span>
            </FlexWrapper>
        </div>
    );
}
function WarrantsDashboardSearchResultsActionBar(props) {
    return (
        <div
            className="evidence-dashboard-search-results-action-bar"
            data-test-id={testIds.WARRANTS_DASHBOARD_ACTION_BAR}
        >
            <div className="action-bar-content">
                <ActionBarContent {...props} />
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    handleExport: (masterItemIds, allResultsSelected) => {
        dispatch(
            warrantsDashboardSearch.actionCreators.exportResults(masterItemIds, allResultsSelected)
        );
    },
});

export default compose(
    connect(null, mapDispatchToProps),
    withHandlers({
        onAddToQueueClick({ addToItemQueue, selectedResults }) {
            return () => addToItemQueue(map(selectedResults, 'masterItemId'));
        },
        onExportClick({ handleExport, selectedRows, allResultsSelected }) {
            return () => handleExport(selectedRows, allResultsSelected);
        },
    })
)(WarrantsDashboardSearchResultsActionBar);

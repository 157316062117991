import { DeliveryMethodEnum, NotificationCategoryEnum } from '@mark43/notifications-api';
import React from 'react';
import { chain, map, get } from 'lodash';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { notificationCategoryConfigsSelector } from '~/client-common/core/domain/user-notifications/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { SecondarySectionHeader } from '../../../core/components/typography';
import { RRFFieldset as _RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNItems } from '../../../core/forms/components/NItems';
import _Checkbox, {
    RRFCheckbox as _RRFCheckbox,
    INDETERMINATE,
} from '../../../core/forms/components/checkboxes/Checkbox';
import {
    notificationCategoryByIndex,
    notificationSettingByIndex,
    notificationTypeWithRenderOnlyIf,
} from '../utils/notificationSettingsHelpers';
import notificationSettingsForm from '../state/forms/notificationSettingsForm';

const strings = componentStrings.notifications.settings.NotificationCategorySettingsFieldset;
const { EMAIL, WEB_APP } = DeliveryMethodEnum;

const deliveryMethodPropertyKeys = {
    [EMAIL.name]: 'emailDelivery',
    [WEB_APP.name]: 'webDelivery',
};

const RRFFieldset = styled(_RRFFieldset)`
    margin: 0 0 0 8px;
`;

const ColumnHeaderRow = styled.div`
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const ColumnHeader = styled.div`
    display: inline-block;
    text-transform: uppercase;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: var(--arc-fontSizes-sm);
    margin-right: 15px;
    color: ${(props) => props.theme.colors.lightGrey};
`;
const SettingTypeColumnHeader = styled(ColumnHeader)`
    flex: 0 0 240px;
    padding-bottom: 20px;
`;

const SettingRowInner = styled.div`
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
`;

const SettingName = styled.div`
    flex: 0 0 240px;
    font-size: var(--arc-fontSizes-sm);
    margin-right: 15px;
`;

const RRFCheckbox = styled(_RRFCheckbox)`
    && {
        display: flex;
        clear: both;
        float: none;
        vertical-align: top;
        justify-content: left;
        margin: 0;
        flex: 0 0 79px;
    }
`;

const Checkbox = styled(_Checkbox)`
    span {
        text-transform: uppercase;
        font-family: ${(props) => props.theme.fontFamilies.proximaNova};
        font-weight: ${(props) => props.theme.fontWeights.semiBold};
        font-size: var(--arc-fontSizes-sm);
        color: ${(props) => props.theme.colors.lightGrey};
    }
`;

const SettingRow = notificationTypeWithRenderOnlyIf(function SettingRow({
    display,
    emailDelivery,
    webDelivery,
}) {
    return (
        <SettingRowInner>
            <SettingName>{display}</SettingName>
            <RRFCheckbox path={EMAIL.name} disabled={!emailDelivery} />
            <RRFCheckbox path={WEB_APP.name} disabled={!webDelivery} />
        </SettingRowInner>
    );
});

const Separator = styled.div`
    margin: 25px 0 40px 0;
`;

function deriveDeliveryCheckAllValueByCategory(
    categoryIndex,
    categoryNotificationTypes,
    notificationCategoryConfigs,
    deliveryMethod
) {
    const categoryNotificationSettingsByDeliveryMethod = chain(categoryNotificationTypes)
        .filter((notificationType, settingIndex) => {
            // Some notifications may either be sent either though the app or email or both.
            // Only include the notifications that are configured for the respective delivery method.
            const notificationSetting = notificationSettingByIndex(
                categoryIndex,
                settingIndex,
                notificationCategoryConfigs
            );
            return get(notificationSetting, deliveryMethodPropertyKeys[deliveryMethod]);
        })
        .map(deliveryMethod)
        .value();

    const isAllCategorySettingsChecked = chain(categoryNotificationSettingsByDeliveryMethod)
        .every()
        .value();

    if (isAllCategorySettingsChecked) {
        return true;
    }

    const isSomeCategorySettingsChecked = chain(categoryNotificationSettingsByDeliveryMethod)
        .some()
        .value();

    if (isSomeCategorySettingsChecked) {
        return INDETERMINATE;
    }

    return undefined;
}

function NotificationCategorySettingsFieldset({
    index: categoryIndex,
    notificationCategoryConfigs,
    notificationsFormModelByPath,
    selectAllCategoryNotificationsByDeliveryMethod,
}) {
    const notificationTypesByCategory =
        notificationsFormModelByPath(
            `notificationSettingsForm.categories[${categoryIndex}].types`
        ) || [];

    const checkAllWebAppCategoryNotifications = deriveDeliveryCheckAllValueByCategory(
        categoryIndex,
        notificationTypesByCategory,
        notificationCategoryConfigs,
        WEB_APP.name
    );

    const checkAllEmailCategoryNotifications = deriveDeliveryCheckAllValueByCategory(
        categoryIndex,
        notificationTypesByCategory,
        notificationCategoryConfigs,
        EMAIL.name
    );

    const notificationCategory = notificationCategoryByIndex(
        categoryIndex,
        notificationCategoryConfigs
    );

    // Disable the all checkbox when every category is disabled
    const disableAllEmailCategoryNotification = notificationCategory.notificationTypes.every(
        ({ emailDelivery }) => !emailDelivery
    );
    const disableAllWebAppCategoryNotification = notificationCategory.notificationTypes.every(
        ({ webDelivery }) => !webDelivery
    );

    return (
        <RRFFieldset>
            {categoryIndex > 0 && <Separator />}
            <SecondarySectionHeader>{notificationCategory.display}</SecondarySectionHeader>

            <ColumnHeaderRow>
                <SettingTypeColumnHeader>{strings.labels.type}</SettingTypeColumnHeader>
                <Checkbox
                    onChange={(value) => {
                        selectAllCategoryNotificationsByDeliveryMethod(EMAIL.name, value);
                    }}
                    label={strings.labels.email}
                    value={checkAllEmailCategoryNotifications}
                    disabled={disableAllEmailCategoryNotification}
                />
                <Checkbox
                    onChange={(value) => {
                        selectAllCategoryNotificationsByDeliveryMethod(WEB_APP.name, value);
                    }}
                    label={strings.labels.inApp}
                    value={checkAllWebAppCategoryNotifications}
                    disabled={disableAllWebAppCategoryNotification}
                />
            </ColumnHeaderRow>
            <RRFNItems disabled={true} path="types" addItemOnEmpty={false}>
                {(item, settingIndex) => {
                    const {
                        name,
                        display,
                        emailDelivery,
                        webDelivery,
                    } = notificationSettingByIndex(
                        categoryIndex,
                        settingIndex,
                        notificationCategoryConfigs
                    );

                    return (
                        <SettingRow
                            name={name}
                            display={display}
                            emailDelivery={emailDelivery}
                            webDelivery={webDelivery}
                        />
                    );
                }}
            </RRFNItems>
        </RRFFieldset>
    );
}

const mapStateToProps = createStructuredSelector({
    applicationSettings: applicationSettingsSelector,
    notificationCategoryConfigs: notificationCategoryConfigsSelector,
    notificationsFormModelByPath: notificationSettingsForm.selectors.formModelByPathSelector,
});

/**
 * Fieldset for a set of notifications settings types for one category.
 * Index prop indicates the category, as set in the configuration object
 */
export default compose(
    connect(mapStateToProps, {
        changePath: notificationSettingsForm.actionCreators.changePath,
    }),
    withHandlers({
        selectAllCategoryNotificationsByDeliveryMethod: ({
            index: categoryIndex,
            changePath,
            notificationsFormModelByPath,
            notificationCategoryConfigs,
        }) => (deliveryMethod, selected) => {
            const categoryNotificationTypes =
                notificationsFormModelByPath(
                    `notificationSettingsForm.categories[${categoryIndex}].types`
                ) || [];

            changePath(
                `categories.${categoryIndex}.types`,
                map(categoryNotificationTypes, (notificationType, settingIndex) => {
                    const notificationSetting = notificationSettingByIndex(
                        categoryIndex,
                        settingIndex,
                        notificationCategoryConfigs
                    );

                    if (get(notificationSetting, deliveryMethodPropertyKeys[deliveryMethod])) {
                        return {
                            ...notificationType,
                            [deliveryMethod]: selected,
                        };
                    }
                    return notificationType;
                })
            );
        },
    }),
    renderOnlyIf(({ notificationCategoryConfigs }) => notificationCategoryConfigs.length > 0),
    renderOnlyIf(({ applicationSettings, index, notificationCategoryConfigs }) => {
        const currentCategory = notificationCategoryConfigs[index].notificationCategory;

        switch (currentCategory) {
            case NotificationCategoryEnum.TASKS.name:
                return applicationSettings.RMS_TASK_AND_REQUEST_TRACKING_ENABLED;
            case NotificationCategoryEnum.SUBSCRIPTIONS.name:
                return applicationSettings.RMS_SUBSCRIPTIONS_ENABLED;
            case NotificationCategoryEnum.SAVED_SEARCHES.name:
                return applicationSettings.RMS_SAVED_SEARCH_SHARING_ENABLED;
            default:
                return true;
        }
    })
)(NotificationCategorySettingsFieldset);

import { EntityTypeEnum, RefContextEnum, RenConfigurationEnum } from '@mark43/rms-api';
import { flatMap, get, map, includes, flatten, compact } from 'lodash';
import { createFormConfiguration } from 'markformythree';
import { createSelector } from 'reselect';
import componentStrings from '~/client-common/core/strings';
import getReportingEventsResource from '~/client-common/core/domain/reporting-events/resources/reportingEventsResource';
import { NEXUS_STATE_PROP as CAD_TICKETS_UNITS_AND_MEMBERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/cad-tickets-units-and-members/state/data';
import { NEXUS_STATE_PROP as CAD_TICKETS_NEXUS_STATE_PROP } from '~/client-common/core/domain/cad-tickets/state/data';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { cadTicketsViewModelWhereSelector } from '~/client-common/core/domain/cad-tickets/state/ui';
import { latestCadTicket } from '~/client-common/core/domain/cad-tickets/utils/cadTicketsHelpers';
import getIdFormatConfigurationResource from '~/client-common/core/domain/id-format-configurations/resources/idFormatConfigurationResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    CAD_TICKET_CAD_AGENCY_EVENT_NUMBER,
    REPORT_RECORD_NUMBER,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import { idFormatConfigurationToRecordSequenceType } from '~/client-common/core/domain/id-format-configurations/state/ui';
import { isBlank, isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { reportShortTitleViewModelsWhereSelector } from '~/client-common/core/domain/report-short-titles/state/ui';

import recordSequenceTypeEnum from '~/client-common/core/enums/client/recordSequenceTypeEnum';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import {
    currentUserDepartmentIdSelector,
    currentUserDepartmentAgencyIdSelector,
} from '../../../../core/current-user/state/ui';
import { saveFieldNoteToReport } from '../../../../mobile/notepad/state/data';
import reportDefinitionResource from '../../../../../legacy-redux/resources/reportDefinitionResource';
import { setLastCreatedReportId } from '../../../../../legacy-redux/actions/reportsActions';
import { setOverrideCreateReportModalV2 } from '../../../../../legacy-redux/actions/globalActions';
import { createReportInQuickCrash } from '../../../traffic-crash/utils';
import { isQuickCrashReportDefinitionSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { openErrorModal } from '../../../../../legacy-redux/actions/boxActions';
import { overrideCreateReportModalV2Selector } from '../../../../../legacy-redux/selectors/globalSelectors';

const strings = componentStrings.components.reports.CreateReportModal;

export const overlayIdEnumSelector = createSelector(
    applicationSettingsSelector,
    overrideCreateReportModalV2Selector,
    (applicationSettings, overrideCreateReportModalV2) => {
        return !applicationSettings.CAD_MULTI_REN_GENERATION_ENABLED
            ? overlayIdEnum.CREATE_REPORT_MODAL
            : overrideCreateReportModalV2
            ? overlayIdEnum.CREATE_REPORT_MODAL
            : overlayIdEnum.CREATE_REPORT_MODAL_V2;
    }
);

export const currentDepartmentReportShortTitleViewModelsWhereSelector = createSelector(
    reportShortTitleViewModelsWhereSelector,
    currentUserDepartmentIdSelector,
    (reportShortTitleViewModelsWhere, departmentId) => (reportingEventNumber) =>
        reportShortTitleViewModelsWhere({
            reportingEventNumber,
            departmentId,
        })
);

export const currentDepartmentReportShortTitleViewModelsMultiRenWhereSelector = createSelector(
    reportShortTitleViewModelsWhereSelector,
    currentUserDepartmentIdSelector,
    (reportShortTitleViewModelsWhere, departmentId) => (rens) =>
        reportShortTitleViewModelsWhere(
            (viewModel) =>
                includes(rens, viewModel.reportingEventNumber) &&
                viewModel.departmentId === departmentId
        )
);

export const createReportModalFormConfiguration = createFormConfiguration({
    reportingEventNumber: {
        fieldName: REPORT_REPORTING_EVENT_NUMBER,
    },
    cadAgencyEventNumber: {
        fieldName: CAD_TICKET_CAD_AGENCY_EVENT_NUMBER,
    },
    recordNumber: {
        fieldName: REPORT_RECORD_NUMBER,
    },
});

export const setReportDefinitionForCreateReportModal = ({
    idFormatConfiguration,
    reportDefinition,
}) => (dispatch, getState, { formsRegistry, overlayStore }) => {
    const overlayId = overlayIdEnumSelector(getState());

    const {
        customProperties: { fieldNote, fromRenSearch, createdFromCadEvent, cadEventInfo },
    } = overlayStore.getStateForId(overlayId);

    const recordSequenceType = idFormatConfigurationToRecordSequenceType(
        idFormatConfiguration,
        reportDefinition.isAutogenerated
    );

    const cadToRmsMultiRenFlag = applicationSettingsSelector(getState())
        .CAD_MULTI_REN_GENERATION_ENABLED;

    overlayStore.setCustomProperties(overlayId, {
        isLoadingSearch: false,
        hasFinishedSearch: cadToRmsMultiRenFlag,
        reportDefinition: {
            ...reportDefinition,
            recordSequenceType,
        },
        formIsBlankOrError:
            (!cadToRmsMultiRenFlag &&
                (reportDefinition.renConfiguration === RenConfigurationEnum.REQUIRED.name ||
                    recordSequenceType === recordSequenceTypeEnum.CAD_EVENT)) ||
            recordSequenceType === recordSequenceTypeEnum.FREE_TEXT ||
            recordSequenceType === recordSequenceTypeEnum.FORMATTED_FREE_TEXT,
    });
    formsRegistry.maybeDeferredOperation(
        RefContextEnum.FORM_REPORT_CREATE_REPORT.name,
        0,
        (form) => {
            if (!isUndefinedOrNull(fieldNote)) {
                const { reportingEventNumber } = fieldNote;
                form.set('', { reportingEventNumber });

                if (
                    !isUndefinedOrNull(reportingEventNumber) &&
                    reportDefinition.renConfiguration !== RenConfigurationEnum.NONE.name
                ) {
                    dispatch(submitFormSearch());
                }
            } else if (createdFromCadEvent || fromRenSearch) {
                const { reportingEventNumber, cadAgencyEventNumber } = cadEventInfo;
                form.set('', { reportingEventNumber, cadAgencyEventNumber });

                if (!cadToRmsMultiRenFlag) {
                    dispatch(submitFormSearch());
                }
            } else {
                form.resetModel();
                // ui only resets if it's an arc text component
                form.resetUi('reportingEventNumber');
                form.resetUi('cadAgencyEventNumber');
                form.resetUi('recordNumber');
            }
        }
    );
};

const openAndPrefillCreateReportModal = ({
    idFormatConfiguration,
    reportDefinition,
    reportingEventNumber,
    onCloseRef,
}) => (dispatch, getState, { formsRegistry, overlayStore }) => {
    overlayStore.open(overlayIdEnumSelector(getState()), {
        isLoadingSearch: false,
        hasFinishedSearch: false,
        showBackButton: false,
        disableRen: true,
        fromPrefill: true,
        onCloseRef,
        reportDefinition: {
            ...reportDefinition,
            recordSequenceType: idFormatConfigurationToRecordSequenceType(
                idFormatConfiguration,
                reportDefinition.isAutogenerated
            ),
        },
    });

    // set the REN form field
    formsRegistry.maybeDeferredOperation(
        RefContextEnum.FORM_REPORT_CREATE_REPORT.name,
        0,
        (form) => {
            form.set('reportingEventNumber', reportingEventNumber);
            // and also kick off the REN search
            dispatch(submitFormSearch());
        }
    );
};

export const openCreateReportModal = (fieldNote) => (dispatch, getState, { overlayStore }) => {
    dispatch(setOverrideCreateReportModalV2(true));

    return reportDefinitionResource
        .getCreateReportModalCreatableReportDefinitions()
        .then((reportDefinitions) => {
            overlayStore.open(overlayIdEnumSelector(getState()), {
                reportDefinitions,
                fieldNote,
            });
        })
        .catch(() =>
            overlayStore.setError(
                overlayIdEnumSelector(getState()),
                strings.fetchReportDefinitionsFailure
            )
        );
};

export const openCreateReportModalFromLinkedReport = ({
    reportDefinition,
    reportingEventNumber,
    router,
    onCloseRef,
}) => (dispatch) => {
    dispatch(setOverrideCreateReportModalV2(true));

    if (isUndefinedOrNull(reportDefinition.idFormatConfigurationId)) {
        return dispatch(
            openAndPrefillCreateReportModal({
                reportDefinition,
                reportingEventNumber,
                onCloseRef,
            })
        );
    }
    return getIdFormatConfigurationResource()
        .getIdFormatConfigurationById(reportDefinition.idFormatConfigurationId)
        .then((idFormatConfiguration) => {
            // linked reports should only be linked through REN, so no need to handle
            // the REN-less report definitions

            const recordSequenceType = idFormatConfigurationToRecordSequenceType(
                idFormatConfiguration,
                reportDefinition.isAutogenerated
            );
            if (
                recordSequenceType === recordSequenceTypeEnum.REN ||
                recordSequenceType === recordSequenceTypeEnum.AUTO_GENERATED
            ) {
                dispatch(
                    createReport({
                        reportDefinitionId: reportDefinition.id,
                        reportingEventNumber,
                        router,
                    })
                );
            } else {
                dispatch(
                    openAndPrefillCreateReportModal({
                        idFormatConfiguration,
                        reportDefinition,
                        reportingEventNumber,
                        onCloseRef,
                    })
                );
            }
        })
        .catch((err) => {
            dispatch(
                openErrorModal({
                    title: 'Could not add report',
                    paragraphs: compact([err && err.message]),
                })
            );
        });
};

export const openCreateReportModalFromCadEvent = ({
    reportingEventNumber,
    cadAgencyEventNumber,
}) => {
    return function (dispatch, getState, { nexus, formsRegistry, overlayStore }) {
        dispatch(setOverrideCreateReportModalV2(false));

        const cadToRmsMultiRenFlag = applicationSettingsSelector(getState())
            .CAD_MULTI_REN_GENERATION_ENABLED;

        if (cadToRmsMultiRenFlag && !!reportingEventNumber) {
            return getReportingEventsResource()
                .getMultiRenReportingEventInfoByCadAgencyEventNumber(
                    cadAgencyEventNumber,
                    reportingEventNumber,
                    {
                        ignoreFormatValidation: true,
                    }
                )
                .then(({ acceptableReports, cadTickets, reportShortTitles }) => {
                    dispatch(
                        nexus.withEntityItems(
                            {
                                [CAD_TICKETS_UNITS_AND_MEMBERS_NEXUS_STATE_PROP]: flatMap(
                                    cadTickets,
                                    'unitsAndMembers'
                                ),
                                [CAD_TICKETS_NEXUS_STATE_PROP]: cadTickets,
                                [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: reportShortTitles,
                            },
                            {
                                type: 'GET_MULTI_REN_EVENT_INFO_FOR_REPORTING_EVENT_NUMBER_SUCCESS',
                            }
                        )
                    );

                    const rens = flatten(map(cadTickets, (x) => x.reportingEventNumbers));
                    formsRegistry.maybeDeferredOperation(
                        RefContextEnum.FORM_REPORT_CREATE_REPORT.name,
                        0,
                        (form) => {
                            form.set('', { reportingEventNumber, cadAgencyEventNumber });
                        }
                    );

                    overlayStore.open(overlayIdEnumSelector(getState()), {
                        reportDefinitions: acceptableReports,
                        hasFinishedSearch: true,
                        createdFromCadEvent: true,
                        disableRen: true,
                        isLoadingSearch: false,
                        formIsBlankOrError: false,
                        reportDefinition: null,
                        cadEventInfo: {
                            rens,
                            cadAgencyEventNumber,
                            reportingEventNumber,
                        },
                    });
                })
                .catch(() => overlayStore.setError(overlayIdEnumSelector(getState())));
        } else {
            return getReportingEventsResource()
                .getReportingEventInfoByCadAgencyEventNumber(cadAgencyEventNumber, {
                    ignoreFormatValidation: true,
                })
                .then(({ acceptableReports, cadTickets, reportShortTitles }) => {
                    dispatch(
                        nexus.withEntityItems(
                            {
                                [CAD_TICKETS_UNITS_AND_MEMBERS_NEXUS_STATE_PROP]: flatMap(
                                    cadTickets,
                                    'unitsAndMembers'
                                ),
                                [CAD_TICKETS_NEXUS_STATE_PROP]: cadTickets,
                                [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: reportShortTitles,
                            },
                            {
                                type: 'GET_REPORTING_EVENT_INFO_BY_CAD_AGENCY_EVENT_NUMBER_SUCCESS',
                            }
                        )
                    );

                    overlayStore.open(overlayIdEnumSelector(getState()), {
                        reportDefinitions: acceptableReports,
                        createdFromCadEvent: true,
                        disableRen: true,
                        cadEventInfo: {
                            reportingEventNumber,
                            cadAgencyEventNumber,
                        },
                    });
                })
                .catch(() => overlayStore.setError(overlayIdEnumSelector(getState())));
        }
    };
};

export const chooseReportDefinition = ({ reportDefinition, router }) => (dispatch) => {
    if (isUndefinedOrNull(reportDefinition.idFormatConfigurationId)) {
        return dispatch(setReportDefinitionForCreateReportModal({ reportDefinition }));
    }
    return getIdFormatConfigurationResource()
        .getIdFormatConfigurationById(reportDefinition.idFormatConfigurationId)
        .then((idFormatConfiguration) => {
            if (
                reportDefinition.renConfiguration === RenConfigurationEnum.NONE.name &&
                reportDefinition.isAutogenerated &&
                idFormatConfiguration.entityType === EntityTypeEnum.REPORT.name
            ) {
                return dispatch(
                    createReport({
                        reportDefinitionId: reportDefinition.id,
                        router,
                    })
                );
            } else {
                return dispatch(
                    setReportDefinitionForCreateReportModal({
                        idFormatConfiguration,
                        reportDefinition,
                    })
                );
            }
        });
};

export const clearReportDefinitionFromCreateReportModal = () => (
    dispatch,
    getState,
    { formsRegistry, overlayStore }
) => {
    const overlayId = overlayIdEnumSelector(getState());
    const {
        customProperties: { fromRenSearch, disableRen },
    } = overlayStore.getStateForId(overlayId);
    const form = formsRegistry.get(RefContextEnum.FORM_REPORT_CREATE_REPORT.name);

    if (!fromRenSearch) {
        form.set('reportingEventNumber', '');
    }

    overlayStore.setCustomProperties(overlayId, {
        isLoadingSearch: false,
        hasFinishedSearch: false,
        reportDefinition: null,
        disableRen,
    });
    overlayStore.setError(overlayId, null);
};

const validateCanPerformSearch = ({
    recordSequenceType,
    renConfiguration,
    cadAgencyEventNumber,
    reportingEventNumber,
}) => {
    const isCadAgencyEventNumberSearchable = !isBlank(cadAgencyEventNumber);
    const isReportingEventNumberSearchable = !isBlank(reportingEventNumber);

    if (
        recordSequenceType === recordSequenceTypeEnum.CAD_EVENT &&
        !isCadAgencyEventNumberSearchable &&
        !isReportingEventNumberSearchable
    ) {
        return {
            canPerformSearch: false,
            performSearchError: { cadAgencyEventNumberError: strings.noFieldsFailure },
        };
    } else if (
        recordSequenceType !== recordSequenceTypeEnum.CAD_EVENT &&
        renConfiguration !== RenConfigurationEnum.NONE.name &&
        !isReportingEventNumberSearchable
    ) {
        return {
            canPerformSearch: false,
            performSearchError: { reportingEventNumberError: strings.noFieldsFailure },
        };
    }

    return { canPerformSearch: true };
};

export const submitRenSearch = () => (
    dispatch,
    getState,
    { overlayStore, formsRegistry, nexus }
) => {
    const overlayId = overlayIdEnumSelector(getState());
    const form = formsRegistry.get(RefContextEnum.FORM_REPORT_CREATE_REPORT.name);
    const { disableRen } = overlayStore.getStateForId(overlayId).customProperties;

    return form
        .submit()
        .then((submitSearchResult) => {
            overlayStore.setCustomProperties(overlayId, {
                hasFinishedSearch: false,
                isLoadingSearch: true,
                formIsBlankOrError: true,
            });
            const {
                reportingEventNumber: inputtedReportingEventNumber,
            } = submitSearchResult.form.get();

            // Why not use arbiter required rules? because we have two buttons that have different
            // rules for whether or not a field should be required. So we are doing some custom
            // error handling here to have some errors appear as field errors and others as modal.
            const canPerformSearchValidationResult = validateCanPerformSearch({
                recordSequenceType: recordSequenceTypeEnum.REN,
                renConfiguration: RenConfigurationEnum.OPTIONAL.name,
                cadAgencyEventNumber: '',
                reportingEventNumber: inputtedReportingEventNumber,
            });

            if (canPerformSearchValidationResult.canPerformSearch) {
                const reportingEventsResource = getReportingEventsResource();
                if (inputtedReportingEventNumber) {
                    return reportingEventsResource.getEventInfoForReportingEventNumber(
                        inputtedReportingEventNumber,
                        {
                            ignoreFormatValidation: disableRen,
                        }
                    );
                }
            } else {
                return canPerformSearchValidationResult.performSearchError;
            }
        })
        .then((reportingEventInfo) => {
            const {
                cadAgencyEventNumber: returnedFormattedCadAgencyEventNumber,
                reportingEventNumber: returnedFormattedReportingEventNumber,
                cadTickets,
                reportShortTitles,
                reportingEventNumberError,
                acceptableReports,
            } = reportingEventInfo;

            form.set('reportingEventNumber', returnedFormattedReportingEventNumber);

            if (reportingEventNumberError) {
                form.do({
                    reportingEventNumber: {
                        errors: {
                            [reportingEventNumberError]: reportingEventNumberError,
                        },
                    },
                });
            }
            dispatch(
                nexus.withEntityItems(
                    {
                        [CAD_TICKETS_UNITS_AND_MEMBERS_NEXUS_STATE_PROP]: flatMap(
                            cadTickets,
                            'unitsAndMembers'
                        ),
                        [CAD_TICKETS_NEXUS_STATE_PROP]: cadTickets,
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: reportShortTitles,
                    },
                    { type: 'GET_EVENT_INFO_FOR_REPORTING_EVENT_NUMBER_SUCCESS' }
                )
            );
            overlayStore.setCustomProperties(overlayId, {
                reportDefinitions: acceptableReports,
                hasFinishedSearch: true,
                isLoadingSearch: false,
                lastSearchedReportingEventNumber: returnedFormattedReportingEventNumber,
                lastSearchedCadAgencyEventNumber: returnedFormattedCadAgencyEventNumber,
                fromRenSearch: true,
                disableRen: true,
                cadEventInfo: {
                    reportingEventNumber: returnedFormattedReportingEventNumber,
                    cadAgencyEventNumber: returnedFormattedCadAgencyEventNumber,
                },
                formIsBlankOrError: !!reportingEventNumberError,
            });
            overlayStore.setError(overlayId, null);
            return reportingEventInfo;
        })
        .catch((e) => {
            overlayStore.setCustomProperties(overlayId, {
                isSaving: false,
                isLoadingSearch: false,
            });
            if (e.message) {
                overlayStore.setError(overlayId, `${strings.eventInfoFailure}: ${e.message}`);
            }
        });
};

export const submitFormSearch = () => (
    dispatch,
    getState,
    { overlayStore, formsRegistry, nexus }
) => {
    const overlayId = overlayIdEnumSelector(getState());

    const cadToRmsMultiRenFlag = applicationSettingsSelector(getState())
        .CAD_MULTI_REN_GENERATION_ENABLED;
    const form = formsRegistry.get(RefContextEnum.FORM_REPORT_CREATE_REPORT.name);
    const { reportDefinition, disableRen } = overlayStore.getStateForId(overlayId).customProperties;
    const { recordSequenceType, renConfiguration } = reportDefinition;

    return form
        .submit()
        .then((submitSearchResult) => {
            overlayStore.setCustomProperties(overlayId, {
                hasFinishedSearch: false,
                isLoadingSearch: true,
                formIsBlankOrError: true,
            });
            const {
                cadAgencyEventNumber: inputtedCadAgencyEventNumber,
                reportingEventNumber: inputtedReportingEventNumber,
            } = submitSearchResult.form.get();

            // Why not use arbiter required rules? because we have two buttons that have different
            // rules for whether or not a field should be required. So we are doing some custom
            // error handling here to have some errors appear as field errors and others as modal.
            const canPerformSearchValidationResult = validateCanPerformSearch({
                recordSequenceType,
                renConfiguration,
                cadAgencyEventNumber: inputtedCadAgencyEventNumber,
                reportingEventNumber: inputtedReportingEventNumber,
            });

            if (canPerformSearchValidationResult.canPerformSearch) {
                const reportingEventsResource = getReportingEventsResource();
                if (cadToRmsMultiRenFlag) {
                    // prioritize searching by cad first
                    if (inputtedCadAgencyEventNumber) {
                        return reportingEventsResource.getReportingEventInfoByCadAgencyEventNumber(
                            inputtedCadAgencyEventNumber,
                            {
                                reportDefinitionId: reportDefinition.id,
                            }
                        );
                    } else if (inputtedReportingEventNumber) {
                        return reportingEventsResource.getReportingEventInfoByReportingEventNumber(
                            inputtedReportingEventNumber,
                            {
                                reportDefinitionId: reportDefinition.id,
                                ignoreFormatValidation: disableRen,
                            }
                        );
                    }
                } else {
                    // prioritize searching by REN first
                    if (inputtedReportingEventNumber) {
                        return reportingEventsResource.getReportingEventInfoByReportingEventNumber(
                            inputtedReportingEventNumber,
                            {
                                reportDefinitionId: reportDefinition.id,
                                ignoreFormatValidation: disableRen,
                            }
                        );
                    } else if (inputtedCadAgencyEventNumber) {
                        return reportingEventsResource.getReportingEventInfoByCadAgencyEventNumber(
                            inputtedCadAgencyEventNumber,
                            {
                                reportDefinitionId: reportDefinition.id,
                            }
                        );
                    }
                }
            } else {
                return canPerformSearchValidationResult.performSearchError;
            }
        })
        .then((reportingEventInfo) => {
            const {
                cadAgencyEventNumber: returnedFormattedCadAgencyEventNumber,
                reportingEventNumber: returnedFormattedReportingEventNumber,
                cadTickets,
                reportShortTitles,
                reportingEventNumberError,
                cadAgencyEventNumberError,
            } = reportingEventInfo;
            const { recordNumber: inputtedRecordNumber } = form.get();

            if (recordSequenceType === recordSequenceTypeEnum.CAD_EVENT) {
                form.resetUi('reportingEventNumber');
                form.set('cadAgencyEventNumber', returnedFormattedCadAgencyEventNumber);
            }
            form.set('reportingEventNumber', returnedFormattedReportingEventNumber);

            if (reportingEventNumberError) {
                form.do({
                    reportingEventNumber: {
                        errors: {
                            [reportingEventNumberError]: reportingEventNumberError,
                        },
                    },
                });
            }
            if (cadAgencyEventNumberError) {
                form.do({
                    cadAgencyEventNumber: {
                        errors: {
                            [cadAgencyEventNumberError]: cadAgencyEventNumberError,
                        },
                    },
                });
            }

            dispatch(
                nexus.withEntityItems(
                    {
                        [CAD_TICKETS_UNITS_AND_MEMBERS_NEXUS_STATE_PROP]: flatMap(
                            cadTickets,
                            'unitsAndMembers'
                        ),
                        [CAD_TICKETS_NEXUS_STATE_PROP]: cadTickets,
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: reportShortTitles,
                    },
                    { type: 'GET_EVENT_INFO_FOR_REPORTING_EVENT_NUMBER_SUCCESS' }
                )
            );

            overlayStore.setCustomProperties(overlayId, {
                hasFinishedSearch: true,
                isLoadingSearch: false,
                lastSearchedReportingEventNumber: returnedFormattedReportingEventNumber,
                lastSearchedCadAgencyEventNumber: returnedFormattedCadAgencyEventNumber,
                recordFieldIsBlank:
                    (recordSequenceType === recordSequenceTypeEnum.FREE_TEXT &&
                        isBlank(inputtedRecordNumber)) ||
                    (recordSequenceType === recordSequenceTypeEnum.FORMATTED_FREE_TEXT &&
                        isBlank(inputtedRecordNumber)),
                cadNotSearchedAndTouched:
                    recordSequenceType === recordSequenceTypeEnum.CAD_EVENT &&
                    isBlank(returnedFormattedCadAgencyEventNumber),
                renFieldIsBlank:
                    renConfiguration === RenConfigurationEnum.REQUIRED.name &&
                    isBlank(returnedFormattedReportingEventNumber),
                formIsBlankOrError: !!reportingEventNumberError || !!cadAgencyEventNumberError,
            });
            overlayStore.setError(overlayId, null);
            return reportingEventInfo;
        })
        .catch((e) => {
            overlayStore.setCustomProperties(overlayId, {
                isSaving: false,
                isLoadingSearch: false,
            });
            if (e.message) {
                overlayStore.setError(overlayId, `${strings.eventInfoFailure}: ${e.message}`);
            }
        });
};

export const submitCreateReportModal = ({
    reportDefinitionId,
    useCadAsRecordNumber,
    router,
    isRenAutogenerated,
}) => (dispatch, getState, { formsRegistry }) => {
    return formsRegistry
        .get(RefContextEnum.FORM_REPORT_CREATE_REPORT.name)
        .submit()
        .then((result) => {
            const { reportingEventNumber, cadAgencyEventNumber, recordNumber } = result.form.get();

            return dispatch(
                createReport({
                    reportDefinitionId,
                    reportingEventNumber,
                    recordNumber: useCadAsRecordNumber ? cadAgencyEventNumber : recordNumber,
                    router,
                    isRenAutogenerated,
                })
            );
        });
};

const createReport = ({
    reportDefinitionId,
    reportingEventNumber,
    recordNumber,
    router,
    isRenAutogenerated,
}) => (dispatch, getState, { overlayStore }) => {
    const overlayId = overlayIdEnumSelector(getState());

    const state = getState();
    const {
        customProperties: { fieldNote },
    } = overlayStore.getStateForId(overlayId);
    const narrative = get(fieldNote, 'note');

    const relatedCadTickets = cadTicketsViewModelWhereSelector(state)(
        reportingEventNumber,
        undefined
    );
    const cadTicket = latestCadTicket(relatedCadTickets);
    const agencyId = get(cadTicket, 'agencyId') || currentUserDepartmentAgencyIdSelector(state);

    const request = {
        reportDefinitionId,
        agencyId,
        reportingEventNumber,
        recordNumber,
        narrative,
        isRenAutogenerated,
    };

    const isQuickCrashReportDefinition = isQuickCrashReportDefinitionSelector(state)(
        reportDefinitionId
    );
    return getReportResource()
        .createReport(request)
        .then((report) => {
            const reportId = report.id;
            if (!isUndefinedOrNull(fieldNote)) {
                dispatch(saveFieldNoteToReport(report, fieldNote));
            }
            dispatch(setLastCreatedReportId(reportId));
            overlayStore.close(overlayId);
            if (isQuickCrashReportDefinition) {
                createReportInQuickCrash({
                    reportingEventNumber: report.reportingEventNumber,
                    recordNumber: report.recordNumber,
                    reportId,
                });
            } else {
                router.push(`/reports/${reportId}`);
            }
        })
        .catch((e) => {
            overlayStore.setCustomProperties(overlayId, {
                isSaving: false,
            });
            overlayStore.setError(overlayId, e.message ? e.message : strings.createReportFailure);
        });
};

export const onReportingEventNumberChange = ({ renConfiguration, ren }) => (
    dispatch,
    getState,
    { overlayStore }
) => {
    const overlayId = overlayIdEnumSelector(getState());
    overlayStore.setError(overlayId, null);
    overlayStore.setCustomProperties(overlayId, {
        renFieldIsBlank:
            (renConfiguration !== RenConfigurationEnum.OPTIONAL.name && isBlank(ren)) ||
            !isBlank(ren),
    });
};

export const onCadAgencyEventNumberChange = () => (dispatch, getState, { overlayStore }) => {
    const overlayId = overlayIdEnumSelector(getState());
    overlayStore.setError(overlayId, null);
    overlayStore.setCustomProperties(overlayId, {
        cadNotSearchedAndTouched: true,
    });
};

export const onRecordNumberChange = () => (dispatch, getState, { overlayStore, formsRegistry }) => {
    const form = formsRegistry.get(RefContextEnum.FORM_REPORT_CREATE_REPORT.name);
    const overlayId = overlayIdEnumSelector(getState());
    overlayStore.setError(overlayId, null);
    const { recordNumber } = form.get();
    overlayStore.setCustomProperties(overlayId, {
        recordFieldIsBlank: isBlank(recordNumber),
        formIsBlankOrError: false, // this is the only field that doesn't need to be searched on and can trigger a non-blank state
    });
};

import React from 'react';
import { compose, withProps } from 'recompose';
import classNames from 'classnames';

import createNFieldsetsCommon from '~/client-common/components/forms/createNFieldsets';
import { nItemFormDataIsEmpty } from '../../../../legacy-redux/helpers/formHelpers';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import Row from '../../components/Row';
import NItems, { createNItems } from './NItems';

const { connectRRFNItems, connectRRFNItem } = reactReduxFormHelpers;

// different from default button to fit in fieldset header
function renderDeleteButton(removeItem, index) {
    return (
        index > 0 && (
            <Button
                className={classNames(buttonTypes.ICON_LINK, 'delete-button')}
                iconLeft={iconTypes.TRASH_CAN}
                onClick={removeItem(index)}
            />
        )
    );
}

const createNFieldsets = compose(
    withProps(({ className }) => ({
        className: classNames('mark43-n-fieldsets', className),
        renderDeleteButton,
    })),
    createNFieldsetsCommon
);

/**
 * An `<NItems>` of `<Fieldset>`s, used for a collection of multi-row fieldsets
 *   like locations in advanced search. Note that this is not the same as a
 *   `<Fieldset>` containing `<NItems>`, such as clothing in advanced search. By
 *   default, the add button is hidden when any one of the fieldsets is empty.
 *   Every fieldset except for the first one receives a title and delete button.
 * @param {Object}   props           Other props get spread into `<NItems>`.
 * @param {function} [props.title]  `index => string`, for computing the title
 *   of the nth fieldset.
 * @param {function} props.children `({ fields, index, deleteButton, title }) =>
 *   Fieldset`, for rendering the nth fieldset.
 */
createNFieldsets(NItems);

/**
 * `<NFieldsets>` to be used inside a `<ReactReduxForm>`.
 * @param {Object} props      Props get spread into `<NFieldsets>`.
 * @param {string} props.path Relative path to the form model in state.
 */
export const RRFNFieldsets = compose(
    connectRRFNItems,
    createNFieldsets,
    createNItems(nItemFormDataIsEmpty),
    connectRRFNItem
)(Row);

import _ from 'lodash';

import getReportingEventsResource from '~/client-common/core/domain/reporting-events/resources/reportingEventsResource';
import { reportDefinitionsSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { storeReportShortTitles } from '~/client-common/core/domain/report-short-titles/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import stringsConfig from '~/client-common/core/strings';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import { renChangeValidator } from '../validation/components/renChangeValidators';

import { setReport } from './reportsActions';
import { openBox, closeBox, saveBoxFailure } from './boxActions';
import renChangeActionTypes from './types/renChangeActionTypes';

const strings = stringsConfig.components.reports.RenChangeForm;

const context = {
    name: boxEnum.REN_CHANGE_MODAL,
};

export function clearRenChangeModal() {
    return {
        type: renChangeActionTypes.CLEAR_REN_CHANGE_MODAL,
    };
}

/**
 * Open the REN change modal
 * @return {null}
 */
export function openRenChangeModal() {
    return function (dispatch) {
        dispatch(openBox(context));
    };
}

function updateRenStart() {
    return {
        type: renChangeActionTypes.UPDATE_REN_START,
    };
}

function updateRenSuccess() {
    return {
        type: renChangeActionTypes.UPDATE_REN_SUCCESS,
    };
}

function updateRenFailure(errorMessage) {
    return {
        type: renChangeActionTypes.UPDATE_REN_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Update the permissions for the given entity. On success, close the modal.
 * @param  {Object}   report
 * @param  {string}   newRen
 * @return {Promise<Object>}
 */
export function updateRen(reportId, newRen) {
    return function (dispatch) {
        dispatch(updateRenStart());

        return getReportResource()
            .updateRen(reportId, newRen)
            .then((newReport) => {
                dispatch(setReport(newReport));
                dispatch(updateRenSuccess());
                dispatch(closeBox(context));
                window.location.reload();
            })
            .catch((err) => {
                dispatch(updateRenFailure(err.message));
                dispatch(saveBoxFailure(context, err.message));
            });
    };
}

function validateRenUpdateStart() {
    return {
        type: renChangeActionTypes.VALIDATE_REN_UPDATE_START,
    };
}

function validateRenUpdateSuccess(newRen) {
    return {
        type: renChangeActionTypes.VALIDATE_REN_UPDATE_SUCCESS,
        payload: { newRen },
    };
}

function validateRenUpdateFailure(errorMessage) {
    return {
        type: renChangeActionTypes.VALIDATE_REN_UPDATE_FAILURE,
        payload: errorMessage,
    };
}

function enableRenUpdate(message) {
    return {
        type: renChangeActionTypes.ENABLE_REN_UPDATE,
        payload: message,
    };
}

export function disableRenUpdate(message) {
    return {
        type: renChangeActionTypes.DISABLE_REN_UPDATE,
        payload: message,
    };
}

/**
 * Validate the ren update
 * @param  {Object}   report
 * @param  {string}   newRen
 * @return {Promise<Object>}
 */
export function validateRenUpdate({ report, newRen }, props) {
    return function (dispatch, getState) {
        dispatch(validateRenUpdateStart());

        const state = getState();
        const reportDefinitions = reportDefinitionsSelector(state);
        const allowMultiple = reportDefinitions[report.reportDefinitionId].allowMultiple;
        const renDisplayName = formatFieldByNameSelector(state)(REPORT_REPORTING_EVENT_NUMBER);
        const reportingEventsResource = getReportingEventsResource();

        return reportingEventsResource
            .getEventInfoForReportingEventNumber(newRen)
            .then((result) => {
                const reportShortTitles = result.reportShortTitles;
                dispatch(storeReportShortTitles(reportShortTitles));
                dispatch(validateRenUpdateSuccess(result.reportingEventNumber));

                // Validate updated REN
                const errors = renChangeValidator(
                    {
                        currentRen: report.reportingEventNumber,
                        newRen: result.reportingEventNumber,
                    },
                    props
                );

                if (!!errors?._error) {
                    dispatch(disableRenUpdate(errors._error));
                } else {
                    const hasSameType =
                        _.filter(reportShortTitles, (r) => {
                            return r.reportDefinitionId === report.reportDefinitionId;
                        }).length > 0;

                    if (hasSameType && !allowMultiple) {
                        dispatch(disableRenUpdate(strings.renTakenError(renDisplayName)));
                    } else {
                        dispatch(enableRenUpdate(strings.validRenNotice(renDisplayName)));
                    }
                }
            })
            .catch((err) => {
                dispatch(validateRenUpdateFailure(err.message));
                dispatch(saveBoxFailure(context));
            });
    };
}

/**
 * Store the form's submit handler in the ui state, so a parent component can
 *   trigger the form to be submitted. In this case, the containing modal has
 *   the submit button, and the form itself does not. This is a temporary ugly
 *   workaround that can be removed if/when we update redux-form to v4.2.0.
 * @param  {function} handleSubmit
 * @return {Object}
 */
export function storeHandleSubmit(handleSubmit) {
    return {
        type: renChangeActionTypes.STORE_HANDLE_SUBMIT,
        payload: handleSubmit,
    };
}

import { CodeTypeSourceEnum, UcrCategoryEnum } from '@mark43/rms-api';
import { some, includes, get } from 'lodash';

import ucrCodes from '~/client-common/core/legacy-constants/ucrCodes';

export function isUcr(currentUcrSource) {
    return (
        currentUcrSource === CodeTypeSourceEnum.CA_UCR.name ||
        currentUcrSource === CodeTypeSourceEnum.NJ_UCR.name ||
        currentUcrSource === CodeTypeSourceEnum.FL_UCR.name
    );
}

export function showDomesticViolenceOnUcrEvent(ucrRuleBundle) {
    const { currentUcrSource } = ucrRuleBundle;
    return (
        currentUcrSource === CodeTypeSourceEnum.CA_UCR.name ||
        currentUcrSource === CodeTypeSourceEnum.NJ_UCR.name
    );
}

export function showDomesticViolenceOnUcrOffense(ucrRuleBundle) {
    const dvUcrCodeCategories = [
        UcrCategoryEnum.I.name,
        UcrCategoryEnum.II.name,
        UcrCategoryEnum.IV.name,
    ];
    return (
        ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.FL_UCR.name &&
        includes(dvUcrCodeCategories, ucrRuleBundle.ucrCodeCategory) &&
        ucrRuleBundle.ucrCodeCode !== ucrCodes.floridaJustifiableHomicideCode
    );
}

export function showRelationshipCodeId(ucrRuleBundle) {
    return (
        ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.FL_UCR.name &&
        ((showDomesticViolenceOnUcrOffense(ucrRuleBundle) &&
            get(ucrRuleBundle.ucrOffense, 'isDomesticViolence')) ||
            UcrCategoryEnum.I.name === ucrRuleBundle.ucrCodeCategory)
    );
}

export function showHomicideFields(ucrRuleBundle) {
    return (
        ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.FL_UCR.name &&
        UcrCategoryEnum.I.name === ucrRuleBundle.ucrCodeCategory
    );
}

export function showArsonLocation(ucrRuleBundle) {
    return (
        ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.FL_UCR.name &&
        ucrRuleBundle.ucrCodeCode !== ucrCodes.floridaKidnappingCode &&
        ucrRuleBundle.ucrCodeCategory === UcrCategoryEnum.VIII.name
    );
}

export function showNegligentManslaughter(ucrRuleBundle) {
    return (
        ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.FL_UCR.name &&
        ucrRuleBundle.ucrCodeCode === ucrCodes.negligentManslaughterCode
    );
}

export function showBuildingInhabited(ucrRuleBundle) {
    const { ucrCodeCategory, ucrCodeCode } = ucrRuleBundle;
    if (ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.FL_UCR.name) {
        return (
            ucrCodeCategory === UcrCategoryEnum.VIII.name &&
            ucrCodeCode !== ucrCodes.floridaKidnappingCode
        );
    } else {
        return (
            ucrCodeCategory === UcrCategoryEnum.VIII.name &&
            ucrCodeCode !== ucrCodes.arsonMobileMotorVehicleCode &&
            ucrCodeCode !== ucrCodes.arsonMobileOtherCode
        );
    }
}

export function showWeaponOrForceInvolved(ucrRuleBundle) {
    if (ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.FL_UCR.name) {
        return some(ucrRuleBundle.ucrOffenseSummaryCodes, (ucrCode) =>
            includes(ucrCodes.floridaRequiresWeaponCodes, ucrCode)
        );
    } else {
        return get(ucrRuleBundle.ucrEvent, 'isDomesticViolence');
    }
}

export function showSuffocationOrStrangulation(ucrRuleBundle) {
    return (
        ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.CA_UCR.name &&
        get(ucrRuleBundle.ucrEvent, 'isDomesticViolence')
    );
}

export function showIsAntiReproductiveRightsCrime(ucrRuleBundle) {
    return ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.CA_UCR.name;
}

export function showWereSeniorCitizensVictims(ucrRuleBundle) {
    return ucrRuleBundle.currentUcrSource === CodeTypeSourceEnum.CA_UCR.name;
}

export function showNumberOfSeniorCitizenVictims(ucrRuleBundle) {
    return (
        showWereSeniorCitizensVictims(ucrRuleBundle) &&
        get(ucrRuleBundle.ucrEvent, 'wereSeniorCitizensVictims')
    );
}

export function showRobbberyLocation(ucrRuleBundle) {
    return ucrRuleBundle.ucrCodeCategory === UcrCategoryEnum.III.name;
}

export function showBurglaryFields(ucrRuleBundle) {
    return ucrRuleBundle.ucrCodeCategory === UcrCategoryEnum.V.name;
}

export function showLarcenyTheft(ucrRuleBundle) {
    return ucrRuleBundle.ucrCodeCategory === UcrCategoryEnum.VI.name;
}

import styled from 'styled-components';

const mediumGreyColorInterpolation = (props) => props.theme.colors.mediumGrey;

export default styled.div`
    color: ${mediumGreyColorInterpolation};
    text-transform: uppercase;
    font-size: var(--arc-fontSizes-md);
    border-bottom: 1px solid ${mediumGreyColorInterpolation};
    margin-bottom: 10px;
`;

import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    formName,
    getBookingForm,
    createBookingForm,
    buildBookingCardData,
    buildBookingCardFormModel,
} from '../../state/forms/bookingForm';
import { registerForm } from '../../state/ui';
import withCard from '../../utils/withCard';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import {
    defendantPersonProfileAgeForCurrentArrestByReportIdSelector,
    defendantPersonProfileAgeMinForCurrentArrestByReportIdSelector,
    defendantPersonProfileIsJuvenileForCurrentArrestByReportIdSelector,
} from '../../state/ui/booking';
import bookingCard from '../../state/ui/bookingCard';
import testIds from '../../../../../core/testIds';
import { registerCard } from '../../utils/cardsRegistry';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import BookingCardForm from './BookingCardForm';
import BookingCardSummary from './BookingCardSummary';

class BookingCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            buildBookingCardFormModel,
            currentReportId,
            index,
        } = this.props;

        const form = createBookingForm({
            initialState: buildBookingCardFormModel({
                reportId: currentReportId,
                formIndex: index,
            }),
            arbiter,
            formatFieldByName,
        });

        registerForm({ form, index });

        this.unregisterCard = registerCard({
            cardModule: bookingCard,
            onSave: this.onSave,
            index,
        });
    }

    componentDidUpdate(prevProps) {
        const personProfileIsJuvenile = this.props.defendantPersonProfileIsJuvenileForCurrentArrestByReportId(
            this.props.currentReportId
        );

        const personProfileAge = this.props.defendantPersonProfileAgeForCurrentArrestByReportId(
            this.props.currentReportId
        );

        const personProfileAgeMin = this.props.defendantPersonProfileAgeMinForCurrentArrestByReportId(
            this.props.currentReportId
        );

        const previousPersonProfileIsJuvenile = prevProps.defendantPersonProfileIsJuvenileForCurrentArrestByReportId(
            this.props.currentReportId
        );

        const previousPersonProfileAge = prevProps.defendantPersonProfileAgeForCurrentArrestByReportId(
            this.props.currentReportId
        );

        const previousPersonProfileAgeMin = prevProps.defendantPersonProfileAgeMinForCurrentArrestByReportId(
            this.props.currentReportId
        );

        if (previousPersonProfileIsJuvenile !== personProfileIsJuvenile) {
            getBookingForm(this.props.index).set(
                'defendantProfile.personProfileIsJuvenile',
                personProfileIsJuvenile
            );
        }

        if (previousPersonProfileAge !== personProfileAge) {
            getBookingForm(this.props.index).set(
                'defendantProfile.personProfileAge',
                personProfileAge
            );
        }

        if (previousPersonProfileAgeMin !== personProfileAgeMin) {
            getBookingForm(this.props.index).set(
                'defendantProfile.personProfileAgeMin',
                personProfileAgeMin
            );
        }
    }

    componentWillUnmount() {
        this.unregisterCard();
        formsRegistry.unregister(formName, this.props.index);
    }

    onEdit = () => {
        this.props.editCallback(() => this.props.onEdit({ index: this.props.index }));
    };

    onSaveProgress = () => {
        const form = getBookingForm(this.props.index);
        return this.props.onSaveProgress(form, { index: this.props.index });
    };

    onSave = () => {
        const form = getBookingForm(this.props.index);
        return this.props.onSave(form, { index: this.props.index });
    };

    render() {
        const {
            card = {},
            arrestForReportId,
            chargesWhere,
            currentReportId: reportId,
            index,
            currentReportCardUITitleByType,
        } = this.props;
        const { arrest, charges } = buildBookingCardData({
            reportId,
            arrestForReportId,
            chargesWhere,
        });

        const cardTitle = currentReportCardUITitleByType(reportCardEnum.BOOKING.id);

        return (
            <Card
                testId={testIds.BOOKING_CARD}
                className={card.anchor}
                anchor={card.anchorForIndex(index)}
                title={cardTitle}
                renderContent={(summaryMode) => {
                    return (
                        <CardSection>
                            {summaryMode ? (
                                <BookingCardSummary arrest={arrest} charges={charges} />
                            ) : (
                                <BookingCardForm charges={charges} index={index} />
                            )}
                        </CardSection>
                    );
                }}
                onEdit={this.onEdit}
                errors={card.errorMessages}
                summaryMode={card.summaryMode}
                canEdit={get(card.canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(card.canEditReportCardStatus, 'errorMessage')}
                saving={card.saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class BookingCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => <BookingCard ref={this.setRef} {...this.props} arbiter={arbiter} />}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    arrestForReportId: arrestForReportIdSelector,
    chargesWhere: chargesWhereSelector,
    defendantPersonProfileIsJuvenileForCurrentArrestByReportId: defendantPersonProfileIsJuvenileForCurrentArrestByReportIdSelector,
    defendantPersonProfileAgeForCurrentArrestByReportId: defendantPersonProfileAgeForCurrentArrestByReportIdSelector,
    defendantPersonProfileAgeMinForCurrentArrestByReportId: defendantPersonProfileAgeMinForCurrentArrestByReportIdSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = { buildBookingCardFormModel };

export default compose(
    withCard(bookingCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(BookingCardWrapper);

import Promise from 'bluebird';
import { createResource } from '../../../../lib/resources/Resource';
// import { req } from '../../../../lib/ajax';

/**
 * Resources for Export Release Settings. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
export default createResource({
    name: 'Export Release Settings Resource',
    methods: {
        getAllExportReleaseSettings() {
            return Promise.resolve({
                exportReleaseTypes: [
                    {
                        id: 1,
                        releaseTypeName: 'Test Release Type Name',
                        description: 'Test Description',
                    },
                ],
            });
            // return req({
            //     method: 'GET',
            //     url: 'print/release/settings'
            // });
        },
        upsertExportReleaseSetting(exportReleaseSetting) {
            return Promise.resolve(exportReleaseSetting);
            // return req({
            //     method: 'PUT',
            //     url: 'print/release/setting',
            //     data: exportReleaseSetting
            // });
        },
    },
});

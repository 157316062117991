import { EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get, head } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, { useRef, useCallback } from 'react';

import { createFieldset, createFormConfiguration, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { towVehicleByReportIdSelector } from '~/client-common/core/domain/tow-vehicles/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';

import { formattedTowVehicleCardTitleSelector } from '../../state/ui';
import towVehicleCard from '../../state/ui/towVehicleCard';
import Card from '../../../../../legacy-redux/components/core/Card';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { withStore } from '../../../../core/arbiter';
import withCard from '../../utils/withCard';
import { refreshTowVehicleForm } from '../../state/ui/towVehicle';
import { registerCard } from '../../utils/cardsRegistry';
import testIds from '../../../../../core/testIds';
import TowVehicleCardSummary from './TowVehicleCardSummary';
import TowVehicleCardForm from './TowVehicleCardForm';

const strings = componentStrings.reports.core.TowVehicleCard;

const mapStateToProps = createStructuredSelector({
    towVehicleByReportId: towVehicleByReportIdSelector,
    formattedTowVehicleCardTitle: formattedTowVehicleCardTitleSelector,
    reportById: reportByIdSelector,
});

const towedFromLocationDescriptionPath = () => 'towedFromLocation.description';
const reportedStolenLocationDescriptionPath = () => 'reportedStolenLocation.description';
const outsideRecoveryLocationDescriptionPath = () => 'outsideRecoveryLocation.description';
const towedFromLocationPositionAttrIdPath = () => 'towedFromLocation.positionAttrId';
const reportedStolenLocationPositionAttrIdPath = () => 'reportedStolenLocation.positionAttrId';
const outsideRecoveryLocationPositionAttrIdPath = () => 'outsideRecoveryLocation.positionAttrId';

class TowVehicleCard extends React.Component {
    constructor(...args) {
        super(...args);

        this.onEdit = this.onEdit.bind(this);
        this.onSaveProgress = this.onSaveProgress.bind(this);
        this.onSave = this.onSave.bind(this);

        registerCard({
            cardModule: towVehicleCard,
            onSave: this.onSave,
        });
    }

    componentDidMount() {
        this.props.refreshTowVehicleForm();
    }

    onEdit() {
        this.props.editCallback(() => this.props.onEdit());
    }

    onSaveProgress() {
        return this.props.onSaveProgress();
    }

    onSave() {
        return this.props.onSave();
    }

    render() {
        const {
            card = {},
            currentReportId,
            towVehicleByReportId,
            formattedTowVehicleCardTitle,
        } = this.props;
        const towVehicle = towVehicleByReportId(currentReportId);
        const towVehicleId = towVehicle.id;

        const towedFromLocation = (form) => (
            <LocationSummaryViewWrapperWithFormFields
                label={strings.connect.towedFrom.label}
                entityType={EntityTypeEnum.RMS_TOW_VEHICLE.name}
                entityId={towVehicleId}
                linkType={LinkTypesEnum.TOW_VEHICLE_TOWED_FROM_LOCATION}
                summaryMode={card.summaryMode}
                locationDescriptionPath={towedFromLocationDescriptionPath}
                locationPositionAttrIdPath={towedFromLocationPositionAttrIdPath}
                onLocationAdd={(location, modelEntityLink) =>
                    form.set('towedFromLocation', modelEntityLink)
                }
                onLocationRemove={() => form.set('towedFromLocation', undefined)}
            />
        );

        const reportedStolenLocation = (form) => (
            <LocationSummaryViewWrapperWithFormFields
                label={strings.connect.locationReportedStolen.label}
                entityType={EntityTypeEnum.RMS_TOW_VEHICLE.name}
                entityId={towVehicleId}
                linkType={LinkTypesEnum.TOW_VEHICLE_REPORTED_STOLEN_LOCATION}
                summaryMode={card.summaryMode}
                locationDescriptionPath={reportedStolenLocationDescriptionPath}
                locationPositionAttrIdPath={reportedStolenLocationPositionAttrIdPath}
                onLocationAdd={(location, modelEntityLink) =>
                    form.set('reportedStolenLocation', modelEntityLink)
                }
                onLocationRemove={() => form.set('reportedStolenLocation', undefined)}
            />
        );

        const outsideRecoveryLocation = (form) => (
            <LocationSummaryViewWrapperWithFormFields
                title={strings.connect.outsideRecoveryLocation.label}
                entityType={EntityTypeEnum.RMS_TOW_VEHICLE.name}
                entityId={towVehicleId}
                linkType={LinkTypesEnum.TOW_VEHICLE_RECOVERY_LOCATION}
                summaryMode={card.summaryMode}
                locationDescriptionPath={outsideRecoveryLocationDescriptionPath}
                locationPositionAttrIdPath={outsideRecoveryLocationPositionAttrIdPath}
                onLocationAdd={(location, modelEntityLink) =>
                    form.set('outsideRecoveryLocation', modelEntityLink)
                }
                onLocationRemove={() => form.set('outsideRecoveryLocation', undefined)}
            />
        );

        const renderContent = (summaryMode) =>
            summaryMode ? (
                <TowVehicleCardSummary
                    reportId={currentReportId}
                    towedFromLocation={towedFromLocation}
                    reportedStolenLocation={reportedStolenLocation}
                    outsideRecoveryLocation={outsideRecoveryLocation}
                />
            ) : (
                <TowVehicleCardForm
                    towedFromLocation={towedFromLocation}
                    reportedStolenLocation={reportedStolenLocation}
                    outsideRecoveryLocation={outsideRecoveryLocation}
                />
            );

        return (
            <Card
                className={card.anchor}
                anchor={card.anchor}
                title={formattedTowVehicleCardTitle}
                testId={testIds.TOW_VEHICLE_CARD}
                renderContent={renderContent}
                onEdit={this.onEdit}
                errors={card.errorMessages}
                summaryMode={card.summaryMode}
                canEdit={get(card.canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(card.canEditReportCardStatus, 'errorMessage')}
                saving={card.saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

const createTowVehicleForm = () => (Component) =>
    React.forwardRef((props, ref) => {
        const formConfiguration = useRef(
            createFormConfiguration({
                id: {},
                reportId: {},
                involvedVehicle: {
                    fieldName: fields.ITEM_PROFILE_ID,
                },
                towVehicleStatusAttrId: {
                    fieldName: fields.RMS_TOW_VEHICLE_TOW_VEHICLE_STATUS_ATTR_ID,
                },
                remarksAndConditions: {
                    fieldName: fields.RMS_TOW_VEHICLE_REMARKS_AND_CONDITIONS,
                },
                towCompanyCalledAttrId: {
                    fieldName: fields.RMS_TOW_VEHICLE_TOW_COMPANY_CALLED_ATTR_ID,
                },
                towCompanyCalledOther: {
                    fieldName: fields.RMS_TOW_VEHICLE_TOW_COMPANY_CALLED_OTHER,
                },
                towCompanyCalledDateUtc: {
                    fieldName: fields.RMS_TOW_VEHICLE_TOW_COMPANY_CALLED_DATE_UTC,
                },
                vehicleWasTowedDateUtc: {
                    fieldName: fields.RMS_TOW_VEHICLE_VEHICLE_WAS_TOWED_DATE_UTC,
                },
                reasonsForTow: {
                    fieldName:
                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_TOW_VEHICLE_REASON_FOR_TOW_ATTRIBUTE_ID,
                },
                towedFromLocation: createFieldset({
                    fields: {
                        locationId: {
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_TOWED_FROM_LOCATION_LOCATION_ID,
                        },
                        positionAttrId: {
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_TOWED_FROM_LOCATION_POSITION_ATTR_ID,
                        },
                        description: {
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_TOWED_FROM_LOCATION_DESCRIPTION,
                        },
                    },
                }),
                reportedStolenLocation: createFieldset({
                    fields: {
                        locationId: {
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_REPORTED_STOLEN_LOCATION_LOCATION_ID,
                        },
                        positionAttrId: {
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_REPORTED_STOLEN_LOCATION_POSITION_ATTR_ID,
                        },
                        description: {
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_REPORTED_STOLEN_LOCATION_DESCRIPTION,
                        },
                    },
                }),
                outsideRecoveryLocation: createFieldset({
                    fields: {
                        locationId: {
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_RECOVERY_LOCATION_LOCATION_ID,
                        },
                        positionAttrId: {
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_RECOVERY_LOCATION_POSITION_ATTR_ID,
                        },
                        description: {
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_RECOVERY_LOCATION_DESCRIPTION,
                        },
                    },
                }),
                originalRen: {
                    fieldName: fields.RMS_TOW_VEHICLE_ORIGINAL_REN,
                },
                dateOfTheft: {
                    fieldName: fields.RMS_TOW_VEHICLE_DATE_OF_THEFT,
                },
                wasLocateSent: {
                    fieldName: fields.RMS_TOW_VEHICLE_WAS_LOCATE_SENT,
                },
                wereImpoundsChecked: {
                    fieldName: fields.RMS_TOW_VEHICLE_WERE_IMPOUNDS_CHECKED,
                },
                isImpounded: {
                    fieldName: fields.RMS_TOW_VEHICLE_IS_IMPOUNDED,
                },
                wasOutsideRecovery: {
                    fieldName: fields.RMS_TOW_VEHICLE_WAS_OUTSIDE_RECOVERY,
                },
                outsideRecoveryAgency: {
                    fieldName: fields.RMS_TOW_VEHICLE_OUTSIDE_RECOVERY_AGENCY,
                },
                outsideRecoveryAgencyRen: {
                    fieldName: fields.RMS_TOW_VEHICLE_OUTSIDE_RECOVERY_AGENCY_REN,
                },
                wasOwnerContactAttempted: {
                    fieldName: fields.RMS_TOW_VEHICLE_WAS_OWNER_CONTACT_ATTEMPTED,
                },
                messageLeftWith: {
                    fieldName: fields.RMS_TOW_VEHICLE_MESSAGE_LEFT_WITH,
                },
                additionalNotes: {
                    fieldName: fields.RMS_TOW_VEHICLE_ADDITIONAL_NOTES,
                },
            })
        );
        const onBeforeValidate = useCallback(
            ({ form }) => {
                const state = props.store.getState();
                const vehicleId = get(
                    head(itemProfilesInReportSelector(state)(currentReportIdSelector(state))),
                    'id'
                );
                if (vehicleId) {
                    form.set('involvedVehicle', vehicleId);
                } else {
                    form.set('involvedVehicle', undefined);
                }
            },
            [props.store]
        );
        return (
            <ArbiterForm
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                name={RefContextEnum.FORM_TOW_VEHICLE.name}
                context={RefContextEnum.FORM_TOW_VEHICLE.name}
                configuration={formConfiguration.current}
                onBeforeValidate={onBeforeValidate}
                customRender={() => <Component {...props} ref={ref} />}
            />
        );
    });

const mapDispatchToProps = {
    refreshTowVehicleForm,
};

export default compose(
    withStore(),
    createTowVehicleForm(),
    withCard(towVehicleCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(TowVehicleCard);

import { RefContextEnum } from '@mark43/rms-api';
import { pick, keys } from 'lodash';
import { createFormConfiguration, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import formsRegistry from '../../../../../core/formsRegistry';

const formConfiguration = {
    name: { fieldName: fields.DASHBOARD_EXTERNAL_LINK_NAME },
    url: { fieldName: fields.DASHBOARD_EXTERNAL_LINK_URL },
};

export function convertToFormModel(externalLink) {
    return pick(externalLink, keys(formConfiguration));
}

export function convertFromFormModel(formModel, selectedId = undefined) {
    return { ...formModel, id: selectedId };
}

export function clearExternalLinkAdminForm() {
    formsRegistry.maybeDeferredOperation(
        RefContextEnum.FORM_EXTERNAL_LINK.name,
        undefined,
        (form) => {
            form.resetModel();
        }
    );
}

const externalLinkAdminForm = {
    name: RefContextEnum.FORM_EXTERNAL_LINK.name,
    configuration: createFormConfiguration(formConfiguration),
    lifecycle: lifecycleOptions.REGISTER_AND_RETAIN,
};

export default externalLinkAdminForm;

import { compact } from 'lodash';

import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';

import { setLastCreatedReportId } from '../../../../../legacy-redux/actions/reportsActions';
import { currentUserDepartmentAgencyIdSelector } from '../../../../core/current-user/state/ui';
import { openErrorModal } from '../../../../../legacy-redux/actions/boxActions';
import { createReportInQuickCrash } from '../../../../reports/traffic-crash/utils';
import { isQuickCrashReportDefinitionSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

/**
 * Link a new report to the current report. On failure, show an alert modal.
 *   This logic must match the legacy reportView.js, except for navigating to
 *   the new report on success (we do that on the component).
 * @param  {string} reportingEventNumber
 * @param  {number} reportDefinitionId
 * @return {Promise<Object>} The new report.
 */
export function linkNewReport(reportingEventNumber, reportDefinitionId) {
    return function (dispatch, getState) {
        const reduxState = getState();
        const currentUserAgencyId = currentUserDepartmentAgencyIdSelector(reduxState);

        const isQuickCrashReportDefinition = isQuickCrashReportDefinitionSelector(reduxState)(
            reportDefinitionId
        );

        return getReportResource()
            .createReport({
                reportingEventNumber,
                reportDefinitionId,
                agencyId: currentUserAgencyId,
            })
            .then((report) => {
                const reportId = report.id;
                dispatch(setLastCreatedReportId(reportId));
                if (isQuickCrashReportDefinition) {
                    createReportInQuickCrash({
                        reportingEventNumber: report.reportingEventNumber,
                        recordNumber: report.recordNumber,
                        reportId,
                    });
                } else {
                    return report;
                }
            })
            .catch((err) => {
                dispatch(
                    openErrorModal({
                        title: 'Could not add report',
                        paragraphs: compact([err && err.message]),
                    })
                );
            });
    };
}

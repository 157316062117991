import { AttributeTypeEnum, EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { useSelector, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';
import styled from 'styled-components';
import { Fieldset as MFTFieldset } from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fieldNames from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { otherUserId } from '~/client-common/helpers/userHelpers';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';

import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import FormRow from '../../../../../core/forms/components/FormRow';
import { Button as ArcButton } from '../../../../../core/components/Button';

import FieldState from '../../../../../core/forms/markformythree-arbiter/FieldState';
import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTTextArea } from '../../../../../core/forms/components/TextArea';
import { ArbiterMFTCheckbox } from '../../../../../core/forms/components/checkboxes/Checkbox';
import { ArbiterMFTUserSelect } from '../../../../../core/forms/components/selects/UserSelect';
import { ArbiterMFTSelect } from '../../../../../core/forms/components/selects/Select';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import testIds from '../../../../../../core/testIds';
import {
    MFTAutofillDateButton,
    autofillDateTypeEnum,
} from '../../../../../core/components/AutofillDateButton';
import { currentUserIdSelector } from '../../../../../core/current-user/state/ui';

const strings = componentStrings.reports.core.RecoveryInfoFieldset;

const FormRowButton = styled(ArcButton)`
    margin-top: 23px;
`;

const FlexRow = styled.div`
    display: flex;
    width: 100%;
`;

const RecoveringOfficerRows = compose(
    connect(createStructuredSelector({ currentUserId: currentUserIdSelector })),
    withHandlers({
        onAssignToMe({ form, currentUserId }) {
            return () => {
                form.set('recoveryInfo.recoveredByOfficerId', currentUserId);
                form.set('recoveryInfo.recoveredByOtherName', undefined);
                form.resetUi('recoveryInfo.recoveredByOfficerId');
            };
        },
        handleRecoveredByOfficerIdChange({ form }) {
            return (id) => {
                form.set('recoveryInfo.recoveredByOfficerId', id);
                form.resetUi('recoveryInfo.recoveredByOfficerId');
                if (id !== otherUserId) {
                    form.set('recoveryInfo.recoveredByOtherName', undefined);
                }
            };
        },
    })
)(function RecoveringOfficerRows({
    currentUserId,
    helpTextCollisionBoundary,
    onAssignToMe,
    handleRecoveredByOfficerIdChange,
}) {
    // Using FieldState hidden override for ASSIGN_TO_ME_BUTTON and recoveredByOtherName.
    // The recoveredByOtherName hide rule is also dependent on recoveredByOfficerId hide rule.
    // Because of the two mutually exclusive rules for recoveredByOfficerId hide, we are choosing
    // to handle the hide dependency on the FE.
    return (
        <FieldState
            path="recoveredByOfficerId"
            render={({ hidden }) =>
                !hidden && (
                    <>
                        <FlexRow>
                            <ArbiterMFTUserSelect
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="recoveredByOfficerId"
                                additionalIds={[currentUserId]}
                                includeOther={true}
                                onChange={handleRecoveredByOfficerIdChange}
                            />
                            <FormRowButton
                                isTextTransformNone
                                onClick={onAssignToMe}
                                testId={testIds.ASSIGN_TO_ME_BUTTON}
                            >
                                {strings.assignToMe}
                            </FormRowButton>
                        </FlexRow>
                        <FormRow hasIndent>
                            <ArbiterMFTText
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="recoveredByOtherName"
                                length="lg"
                            />
                        </FormRow>
                    </>
                )
            }
        />
    );
});

const DateUtcRow = ({ form, helpTextCollisionBoundary, datePath }) => {
    return (
        <FieldState
            path={datePath}
            render={({ hidden }) =>
                !hidden && (
                    <FlexRow>
                        <ArbiterMFTDatePicker
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path={datePath}
                            includeTime={true}
                        />
                        <MFTAutofillDateButton
                            onClick={(_e, { eventStartUtc }) => {
                                const path = `recoveryInfo.${datePath}`;
                                form.set(path, eventStartUtc);
                                form.resetUi(path);
                            }}
                            alwaysRender={true}
                            autofillDateType={autofillDateTypeEnum.START_DATE_TIME}
                            startDatePath={datePath}
                        />
                    </FlexRow>
                )
            }
        />
    );
};

const collectedFromOptions = [
    {
        value: EntityTypeEnum.LOCATION.name,
        display: 'Location',
    },
    {
        value: EntityTypeEnum.PERSON_PROFILE.name,
        display: 'Person',
    },
    {
        value: EntityTypeEnum.VEHICLE.name,
        display: 'Vehicle',
    },
];

const CollectedFromRow = ({ collectedFromIsStaticallyHidden, locationSummaryView }) => {
    return (
        <>
            <ArbiterMFTSelect
                path="collectedFromEntityType"
                options={collectedFromOptions}
                length="md"
            />
            <FormRow>
                <ArbiterMFTText path="collectedVehicleRegistrationNumber" length="md" />
                <ArbiterMFTAttributeSelect
                    path="collectedVehicleStateAttrId"
                    length="md"
                    attributeType={AttributeTypeEnum.STATE.name}
                />
            </FormRow>
            {!collectedFromIsStaticallyHidden && (
                <MFTFieldset path="locationEntityLink">{locationSummaryView}</MFTFieldset>
            )}
        </>
    );
};

function RecoveryInfoFieldset({ form, helpTextCollisionBoundary, locationSummaryView }) {
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const fieldConfigurationContextByContextAndFieldName = useSelector(
        fieldConfigurationContextByContextAndFieldNameSelector
    );

    const {
        isStaticallyHidden: collectedFromIsStaticallyHidden,
    } = fieldConfigurationContextByContextAndFieldName(
        RefContextEnum.FORM_HYDRATED_ITEM.name,
        fieldNames.PROPERTY_STATUS_COLLECTED_FROM_ENTITY_TYPE
    );

    // Using FieldState hidden override for Section Title and locationSummaryView
    return (
        <FieldState
            path="recoveryInfo.recoveredByOfficerId"
            render={({ hidden }) =>
                !hidden && (
                    <SidePanelSection
                        title={formatFieldByName(
                            fieldNames.DISPLAY_ONLY_ITEM_SIDE_PANEL_RECOVERY_INFORMATION_SECTION_TITLE
                        )}
                        testId={testIds.ITEM_SIDE_PANEL_RECOVERY_INFO_SECTION}
                    >
                        <MFTFieldset path="recoveryInfo">
                            <RecoveringOfficerRows
                                form={form}
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                            />
                            <DateUtcRow
                                form={form}
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                datePath="recoveredDateUtc"
                            />
                            <CollectedFromRow
                                collectedFromIsStaticallyHidden={collectedFromIsStaticallyHidden}
                                locationSummaryView={locationSummaryView}
                            />
                            {collectedFromIsStaticallyHidden && (
                                <MFTFieldset path="locationEntityLink">
                                    {locationSummaryView}
                                </MFTFieldset>
                            )}
                            <ArbiterMFTText
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="intakePerson"
                            />
                            <ArbiterMFTCheckbox
                                path="ownerNotified"
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                            />
                            <DateUtcRow
                                form={form}
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                datePath="notifiedDateUtc"
                                length="lg"
                            />
                            <ArbiterMFTText
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="notifierName"
                            />
                            <ArbiterMFTTextArea
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="statementOfFacts"
                                rows={5}
                            />
                        </MFTFieldset>
                    </SidePanelSection>
                )
            }
        />
    );
}

/**
 * Fieldset in the item entry v2 form that appears depending on a HIDE rule.
 */
export default RecoveryInfoFieldset;

import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import _, {
    isEmpty,
    isNil,
    size,
    reduce,
    get,
    groupBy,
    filter,
    flatMap,
    first,
    reject,
    includes,
    map,
    mapValues,
    omit,
    compact,
    pick,
    split,
    values,
    some,
    sortBy,
    uniqBy,
} from 'lodash';

import sortLocationEntityLinks from '~/client-common/core/domain/location-entity-links/utils/sortLocationEntityLinks';

import { personInjuriesByPersonProfileIdSelector } from '~/client-common/core/domain/person-injuries/state/data';
import { personDriverLicenseDetailsByPersonProfileIdSelector } from '~/client-common/core/domain/person-drivers-license-details/state/data';
import { personProfilesSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { nameMonikersSelector } from '~/client-common/core/domain/name-monikers/state/data';
import { nameIdentifiersSelector } from '~/client-common/core/domain/name-identifiers/state/data';
import { identifyingMarksSelector } from '~/client-common/core/domain/identifying-marks/state/data';
import { namePhonesSelector } from '~/client-common/core/domain/name-phones/state/data';
import { nameEmailsSelector } from '~/client-common/core/domain/name-emails/state/data';
import { personEmergencyContactsSelector } from '~/client-common/core/domain/person-emergency-contacts/state/data';
import { passportsSelector } from '~/client-common/core/domain/passports/state/data';
import { personProbationsSelector } from '~/client-common/core/domain/person-probations/state/data';
import { employmentHistoriesSelector } from '~/client-common/core/domain/employment-histories/state/data';
import { schoolHistoriesSelector } from '~/client-common/core/domain/school-histories/state/data';
import { nameAttributesWhereSelector } from '~/client-common/core/domain/name-attributes/state/data';
import { personGangTrackingForPersonIdSelector } from '~/client-common/core/domain/person-gang-trackings/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import { unknownLocationIdSelector } from '~/client-common/core/constants/state/constants';
import { imagesSelector } from '~/client-common/core/domain/images/state/data';
import { personProfilesForReportIdSelector } from '~/client-common/core/domain/reports/state/ui/names';
import { personProfilesForWarrantIdSelector } from '~/client-common/core/domain/warrants/state/ui/names';
import { cautionsByEntitySelector } from '~/client-common/core/domain/cautions/state/data';

import {
    ssnRegex,
    isMasterProfile,
    isUnknown,
    buildPersonFullName,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { filterFormData } from '~/client-common/helpers/formHelpers';
import { getThumbnailPath } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import {
    convertPhoneToFormModel,
    convertPhoneFromFormModel,
} from '~/client-common/helpers/phoneNumberHelpers';
import { otherPersonLocationTypes } from '~/client-common/helpers/linkTypesHelpers';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { convertNameAttributesFormDataToDataState } from '../../../../../legacy-redux/helpers/nameAttributesHelpers';
import {
    convertFromFormGangCriteria,
    convertFromFormGangTrackings,
} from '../../../../reports/gang-tracking/state/ui';

import { currentUserDepartmentProfileSelector } from '../../../current-user/state/ui';
import { KNOWN_PERSON, UNKNOWN_PERSON } from '../../config/personProfileFormPersonTypes';
import { shimIsJuvenilePersonProfile } from '../../utils/personProfileHelpers';

import {
    nameMonikerFields,
    employmentHistoryFields,
    militaryHistoryFields,
    schoolHistoryFields,
    nameIdentifierFields,
    identifyingMarkFields,
    homeAddressFields,
    workAddressFields,
    medicalTreatmentFields,
    emergencyContactFields,
    personProbationFields,
    passportFields,
} from '../../../names/util/nameFieldHelpers';
import {
    processLinkData,
    mergeEntitiesWithExistingData,
    addEntityTypeToCollection,
    convertFormLocationEntityLinksToLocationBundles,
    convertEmailsFromFormModel,
    convertPhonesFromFormModel,
    convertEmailsToFormModel,
    convertPhonesToFormModel,
} from '../../../names/util/nameFormHelpers';
import { cautionFormFields } from '../../../cautions/configuration';
import { convertCautionsFromFormModel } from '../../../cautions/helpers';

function convertToFormModel(
    {
        personInjuries,
        identifyingMarks,
        identifiers,
        schoolHistories,
        employmentHistories,
        phoneNumbers,
        emails,
        monikers,
        personProfile,
        nameAttributes,
        gangTracking,
        locationEntityLinks,
        unknownLocationId,
        images,
        emergencyContacts,
        passports,
        personProbations,
        cautions,
        driversLicenseDetails = [],
    },
    { personType, nibrsOffenseCode }
) {
    const isMilitaryHistory = (history) => !!history.militaryBranchAttrId;

    // TODO: There is a bug with the Involved persons card where we don't remove
    // attributes AND we don't remove injuries
    const nameAttributesByType = groupBy(nameAttributes, 'attributeType');

    // clothing name attributes must be handled separately
    const clothingNameAttributes = nameAttributesByType[AttributeTypeEnum.CLOTHING_TYPE.name];

    // medical statistics attributes must also be handled separately
    const medicalStatisticsAttributes =
        nameAttributesByType[AttributeTypeEnum.MEDICAL_STATISTICS.name];

    const locationEntityLinksByType = groupBy(locationEntityLinks, 'linkType');

    const { firstName, lastName } = personProfile;

    const showAdditionalHeightFields = some(
        values(
            pick(personProfile, [
                'heightMinFeet',
                'heightMinInches',
                'heightMaxFeet',
                'heightMaxInches',
            ])
        )
    );

    const showAdditionalWeightFields = some(
        values(pick(personProfile, ['weightRangeMin', 'weightRangeMax']))
    );

    const personInjuryFields = [
        'id',
        'description',
        'injuryTypeAttrId',
        'bodyPartAttrId',
        'weaponsUsedAttrId',
        'wasWeaponSeized',
        'injurySeverityAttrId',
    ];

    const isPhoneNumberNotGiven = !size(phoneNumbers);

    const medicalTreatmentFieldsFromPersonProfile = pick(personProfile, medicalTreatmentFields);

    // Set the initial value for the `medicineTransported` checkbox
    const medicineTransported = some(
        values(
            pick(personProfile, [
                'medicalTransportationTypeAttrId',
                'ambulanceNumber',
                'takenToHospitalBy',
            ])
        )
    );

    const medicalTreatment = {
        medicalStatisticsAttrIds: map(medicalStatisticsAttributes, 'attributeId'),
        medicineTransported,
        ...medicalTreatmentFieldsFromPersonProfile,
    };

    // Process the entire `medicalTreatment` section to determine if
    // we should initially show or hide it
    const showMedicalTreatment = !isEmpty(
        filterFormData(omit(medicalTreatment, 'medicineTransported'))
    );

    const filteredPersonProfile = omit(personProfile, medicalTreatmentFields);
    const massagedPhoneNumbers = convertPhonesToFormModel(phoneNumbers);
    const massagedEmails = convertEmailsToFormModel(emails);

    const driversLicenseDetail = driversLicenseDetails[0];

    const extendedDriverLicenseDetails = {
        dlExpiryDateUtc: driversLicenseDetail?.dlExpiryDateUtc,
    };

    return {
        ...filteredPersonProfile,
        ...extendedDriverLicenseDetails,
        showMedicalTreatment,
        nibrsCodeCode: get(nibrsOffenseCode, 'code'),
        nibrsOffenseCodeId: get(nibrsOffenseCode, 'id'),
        isPhoneNumberNotGiven,
        showAdditionalWeightFields,
        showAdditionalHeightFields,
        // Check for personType is needed specifically
        // for the identify workflow
        //
        // If we are identifying an person as an unknown, we need
        // the [last|first]nameUnknown box to be unchecked
        // because we must always have the first and last name
        // for a person that we are identifying.
        //
        // It follows that personType will always be KNOWN_PERSON
        // when we get here via the identify workflow
        lastNameUnknown: personType === KNOWN_PERSON ? false : !lastName,
        firstNameUnknown: personType === KNOWN_PERSON ? false : !firstName,
        emails: massagedEmails,
        phoneNumbers: massagedPhoneNumbers,
        clothing: _.chain(clothingNameAttributes)
            .mapKeys((nameAttribute) => nameAttribute.attributeId)
            .mapValues((nameAttribute) => nameAttribute.description)
            .value(),
        schoolHistories: map(schoolHistories, (history) =>
            pick(convertPhoneToFormModel(history), schoolHistoryFields)
        ),
        employmentHistories: map(reject(employmentHistories, isMilitaryHistory), (history) =>
            pick(convertPhoneToFormModel(history), employmentHistoryFields)
        ),
        militaryHistories: map(filter(employmentHistories, isMilitaryHistory), (history) =>
            pick(history, militaryHistoryFields)
        ),
        identifiers: map(identifiers, (identifier) => pick(identifier, nameIdentifierFields)),
        // we need to mimic the shape given to us be the uploader component in order to show
        // previews for existing files
        identifyingMarks: map(identifyingMarks, (mark) =>
            mark.imageId
                ? {
                      ...pick(mark, identifyingMarkFields),
                      imageId: {
                          image: images[mark.imageId],
                          preview: getThumbnailPath({
                              image: images[mark.imageId],
                              attachmentType: EntityTypeEnum.IMAGE.name,
                          }),
                      },
                  }
                : pick(mark, identifyingMarkFields)
        ),
        // Prefill locations with only the `locationEntityLink` data
        // and just pick the data that we need
        homeAddresses: map(
            locationEntityLinksByType[LinkTypesEnum.LIVES_AT],
            (locationEntityLink) => ({
                ...pick(locationEntityLink, homeAddressFields),
                description: locationEntityLink.description || '',
                isUnknownLocation: locationEntityLink.locationId === unknownLocationId,
            })
        ),
        workAddresses: map(locationEntityLinksByType[LinkTypesEnum.WORKS_AT], (location) =>
            pick(location, workAddressFields)
        ),
        otherAddresses: filter(locationEntityLinks, (locationEntityLink) => {
            return includes(otherPersonLocationTypes, locationEntityLink.linkType);
        }),
        monikers: map(monikers, (moniker) => pick(moniker, nameMonikerFields)),
        // gang tracking
        gangCriteria: map(
            nameAttributesByType[AttributeTypeEnum.GANG_CRITERIA.name],
            ({ attributeId, description }) => ({
                attributeId,
                description,
            })
        ),
        gangName: {
            gangNameAttrId:
                gangTracking && (gangTracking.gangSubgroupAttrId || gangTracking.gangNameAttrId),
            gangNameOther: gangTracking && gangTracking.gangNameOther,
        },
        infantAge: first(nameAttributesByType[AttributeTypeEnum.INFANT_AGE.name])?.attributeId,
        injuries: map(personInjuries, (personInjury) => pick(personInjury, personInjuryFields)),
        medicalTreatment,
        attributes: mapValues(
            omit(nameAttributesByType, [
                AttributeTypeEnum.GANG_CRITERIA.name,
                AttributeTypeEnum.CLOTHING_TYPE.name,
                AttributeTypeEnum.MEDICAL_STATISTICS.name,
                AttributeTypeEnum.INFANT_AGE.name,
            ]),
            (attributes) => {
                return {
                    attributeIds: map(attributes, 'attributeId'),
                    description: first(compact(map(attributes, 'description'))),
                };
            }
        ),
        emergencyContacts: map(emergencyContacts, (emergencyContact) =>
            pick(convertPhoneToFormModel(emergencyContact), emergencyContactFields)
        ),
        passports: map(passports, (passport) => pick(passport, passportFields)),
        personProbations: map(personProbations, (personProbation) =>
            pick(personProbation, personProbationFields)
        ),
        cautions: cautions.map((caution) => pick(caution, cautionFormFields)),
    };
}

const constructPersonProfileExtendedDriverLicenseDetails = (personProfile) => {
    const dlExpiryDateUtc = personProfile.dlExpiryDateUtc;

    let extendedPersonProfileDriverLicenseDetails = { personProfileId: personProfile.id };
    if (!!dlExpiryDateUtc) {
        extendedPersonProfileDriverLicenseDetails = {
            ...extendedPersonProfileDriverLicenseDetails,
            dlExpiryDateUtc,
        };
    }

    if (Object.keys(extendedPersonProfileDriverLicenseDetails).length === 1) {
        return [];
    }

    return [extendedPersonProfileDriverLicenseDetails];
};

/**
 * Convert hydrated person form model to hydrated person payload
 * @param {Object}   formModel
 * @param {Object[]} [stateData.monikers]
 * @param {Object[]} [stateData.identifiers]
 * @param {Object[]} [stateData.personInjuries]
 * @param {Object[]} [stateData.phoneNumbers]
 * @param {Object[]} [stateData.identifyingMarks]
 * @param {Object[]} [stateData.employmentHistories]
 * @param {Object[]} [stateData.schoolHistories]
 * @param {Object[]} [stateData.locationEntityLinks]
 * @param {Object[]} [stateData.locations]
 * @param {Object[]} [stateData.personEmergencyContacts]
 * @param {Object[]} stateData.unknownLocationId
 * @param {Object[]} [stateData.cautions]
 * @param {number}   [additionalData.entityId]
 * @param {boolean}  [additionalData.isIdentifying]
 * @param {boolean}  [additionalData.isEditingMasterProfile]
 * @param {function} additionalData.dispatch
 * @param {function} additionalData.formatAttributeById
 * @param {function} additionalData.attributeIsOther
 * @param {number}   additionalData.ownerId
 * @param {string}   additionalData.ownerType
 * @return {Object}
 */
export function convertFromFormModel(
    formModel,
    stateData,
    {
        isIdentifying,
        isEditingMasterProfile,
        entityId,
        lookupEntityId,
        dispatch,
        formatAttributeById,
        attributeIsOther,
        ownerId,
        ownerType,
    }
) {
    const {
        clothing,
        phoneNumbers,
        emails,
        monikers,
        emergencyContacts,
        passports,
        personProbations,
        employmentHistories,
        militaryHistories,
        schoolHistories,
        identifiers,
        identifyingMarks,
        attributes,
        homeAddresses,
        workAddresses,
        otherAddresses,
        gangCriteria,
        gangName,
        infantAge,
        injuries,
        lastNameUnknown,
        firstNameUnknown,
        cautions,
        medicalTreatment: { medicalStatisticsAttrIds, ...medicalTreatment },
        ...personProfile
    } = formModel;

    // unfortunately clothing needs to be handled separately from other name attributes
    // because of the shape in which they are stored in form state, and also because
    // our helper for handling other name attributes applies logic for "other" attributes
    // that does not work with how we handle clothing attributes
    const clothingNameAttributes = filter(
        map(clothing, (description, attributeId) => ({
            entityType: EntityTypeEnum.PERSON_PROFILE.name,
            nameId: entityId,
            attributeType: AttributeTypeEnum.CLOTHING_TYPE.name,
            description,
            attributeId,
        })),
        (attribute) => attribute.description
    );

    const augmentMilitaryHistories = (histories) =>
        map(histories, (history) => ({
            ...history,
            employerName: formatAttributeById(history.militaryBranchAttrId),
        }));

    const convertNestedImageIdToFlatProperty = (item) => ({
        ...item,
        imageId: get(item, 'imageId.image.id'),
    });

    const convertedGangTrackings = dispatch(
        convertFromFormGangTrackings(
            {
                isAllegedGangMember: personProfile.isAllegedGangMember,
                gangNameOther: gangName.gangNameOther,
                gangNameAttrId: gangName.gangNameAttrId,
            },
            entityId
        )
    );

    const { gangCriteria: convertedGangCriteria } = convertFromFormGangCriteria({
        isAllegedGangMember: personProfile.isAllegedGangMember,
        gangCriteria,
    });

    const isUnknownFromOtherReport = isUnknown(personProfile) && personProfile.ownerId !== ownerId;

    // we need to set owner type and id on the person profile model
    // because that's what the backend expects
    const updatedPersonProfile = omit(
        { ...personProfile, ...medicalTreatment, ownerId, ownerType },
        ['offenseCode', 'medicineTransported', 'showMedicalTreatment']
    );

    // we remove the id when processing a master profile rather than
    // a contexted one because the backend needs to create a new contexted version
    // on save.
    // `isIdentifying` was explicitly created for the identification work flow
    // When identifying an unknown as a new person, we prefill with the unknown contexted
    // profile (which has an id associated with it).  However, when saving,
    // we want to associate a master profile with it, and blanking out the id achieves this
    if (
        isIdentifying ||
        isUnknownFromOtherReport ||
        (isMasterProfile(updatedPersonProfile) && !isEditingMasterProfile)
    ) {
        updatedPersonProfile.id = 0;
    }

    if (isEditingMasterProfile) {
        updatedPersonProfile.ownerType = EntityTypeEnum.PERSON_PROFILE.name;
        updatedPersonProfile.ownerId = updatedPersonProfile.id;
    }

    if (firstNameUnknown) {
        updatedPersonProfile.firstName = null;
    }

    if (lastNameUnknown) {
        updatedPersonProfile.lastName = null;
    }

    if (!personProfile.isUnborn) {
        updatedPersonProfile.expectedDateOfBirth = null;
        updatedPersonProfile.isExpectedDateOfBirthUnknown = null;
    }

    if (personProfile.isDobUnknown || personProfile.isUnborn) {
        updatedPersonProfile.dateOfBirth = null;
        updatedPersonProfile.dateOfCalculation = stateData.dateOfCalculation;
    }

    if (personProfile.isExpectedDateOfBirthUnknown) {
        updatedPersonProfile.expectedDateOfBirth = null;
    }

    if (!personProfile.isDobUnknown || personProfile.isUnborn) {
        updatedPersonProfile.ageMin = null;
        updatedPersonProfile.ageMax = null;
        updatedPersonProfile.dateOfBirthRangeEnd = null;
        updatedPersonProfile.dateOfBirthRangeStart = null;
    }

    if (!personProfile.isVulnerable) {
        updatedPersonProfile.dateVulnerableFrom = null;
        updatedPersonProfile.dateVulnerableTo = null;
    }

    if (personProfile.showAdditionalWeightFields) {
        updatedPersonProfile.weight = null;
    } else {
        updatedPersonProfile.weightRangeMax = null;
        updatedPersonProfile.weightRangeMin = null;
    }

    const noHeightInput = !updatedPersonProfile.heightFeet && !updatedPersonProfile.heightInches;
    const noHeightRangeInput =
        !updatedPersonProfile.heightMaxFeet &&
        !updatedPersonProfile.heightMaxInches &&
        !updatedPersonProfile.heightMinFeet &&
        !updatedPersonProfile.heightMinInches;

    if (noHeightInput) {
        updatedPersonProfile.height = null;
        updatedPersonProfile.heightFeet = null;
        updatedPersonProfile.heightInches = null;
    }
    if (noHeightRangeInput) {
        updatedPersonProfile.heightMaxFeet = null;
        updatedPersonProfile.heightMaxInches = null;
        updatedPersonProfile.heightMinFeet = null;
        updatedPersonProfile.heightMinInches = null;
        updatedPersonProfile.heightRangeMax = null;
        updatedPersonProfile.heightRangeMin = null;
    }

    const massagedPhoneNumbers = convertPhonesFromFormModel(phoneNumbers);
    const massagedEmails = convertEmailsFromFormModel(emails);

    const personCautions = convertCautionsFromFormModel(
        cautions,
        EntityTypeEnum.PERSON_PROFILE.name,
        lookupEntityId
    );

    const data = {
        injuries: mergeEntitiesWithExistingData(injuries, stateData.personInjuries),
        personProfiles: [updatedPersonProfile],
        identifyingMarks: processLinkData(
            map(identifyingMarks, convertNestedImageIdToFlatProperty),
            stateData.identifyingMarks,
            EntityTypeEnum.PERSON_PROFILE.name
        ),
        schoolHistories: map(
            mergeEntitiesWithExistingData(schoolHistories, stateData.schoolHistories),
            convertPhoneFromFormModel
        ),
        employmentHistories: map(
            mergeEntitiesWithExistingData(
                employmentHistories.concat(augmentMilitaryHistories(militaryHistories)),
                stateData.employmentHistories
            ),
            convertPhoneFromFormModel
        ),
        nameAttributes: flatMap(
            {
                [AttributeTypeEnum.MEDICAL_STATISTICS.name]: {
                    attributeIds: medicalStatisticsAttrIds,
                },
                [AttributeTypeEnum.INFANT_AGE.name]: { attributeIds: compact([infantAge]) },
                ...attributes,
            },
            ({ attributeIds, description }, attributeType) =>
                convertNameAttributesFormDataToDataState(
                    attributeIds,
                    entityId,
                    EntityTypeEnum.PERSON_PROFILE.name,
                    attributeType,
                    description,
                    attributeIsOther
                )
        )
            .concat(addEntityTypeToCollection(convertedGangCriteria))
            .concat(clothingNameAttributes),
        emails: processLinkData(
            massagedEmails,
            stateData.emails,
            EntityTypeEnum.PERSON_PROFILE.name
        ),
        phones: processLinkData(
            massagedPhoneNumbers,
            stateData.phoneNumbers,
            EntityTypeEnum.PERSON_PROFILE.name
        ),
        identifiers: processLinkData(
            identifiers,
            stateData.identifiers,
            EntityTypeEnum.PERSON_PROFILE.name
        ),
        monikers: processLinkData(monikers, stateData.monikers, EntityTypeEnum.PERSON_PROFILE.name),
        personGangTrackings: convertedGangTrackings,
        // Even though we are passing a `locationBundle` with a `locationView`,
        // instead of a plain `location` this is still working as expected
        locations: reduce(
            [
                {
                    formLocationEntityLinks: homeAddresses,
                    linkType: LinkTypesEnum.LIVES_AT,
                },
                {
                    formLocationEntityLinks: workAddresses,
                    linkType: LinkTypesEnum.WORKS_AT,
                },
                ...map(otherAddresses, (otherAddress) => {
                    return {
                        formLocationEntityLinks: [otherAddress],
                        linkType: otherAddress.linkType,
                    };
                }),
            ],
            (acc, { formLocationEntityLinks, linkType }) =>
                acc.concat(
                    convertFormLocationEntityLinksToLocationBundles({
                        formLocationEntityLinks: map(formLocationEntityLinks, (link) =>
                            omit(link, ['isUnknownLocation'])
                        ),
                        stateData: {
                            locations: stateData.locations,
                            locationEntityLinks: stateData.locationEntityLinks,
                            unknownLocationId: stateData.unknownLocationId,
                        },
                        additionalData: {
                            entityId: lookupEntityId,
                            entityType: EntityTypeEnum.PERSON_PROFILE.name,
                            linkType,
                        },
                    })
                ),
            []
        ),
        personEmergencyContacts: map(
            mergeEntitiesWithExistingData(emergencyContacts, stateData.personEmergencyContacts),
            convertPhoneFromFormModel
        ),
        passports: mergeEntitiesWithExistingData(passports, stateData.passports),
        personProbations: mergeEntitiesWithExistingData(
            personProbations,
            stateData.personProbations
        ),
        cautions: mergeEntitiesWithExistingData(personCautions, stateData.cautions),
        driversLicenseDetails: constructPersonProfileExtendedDriverLicenseDetails(personProfile),
    };
    return filterFormData(data);
}

const convertPersonProfileFormDataToPrefilledPersonProfileFormData = (personProfileFormData) => {
    // When prefilling a contexed profile from the master,
    // we actually only care to prefill a couple attributes
    //
    // cobalt-core-client/master-entity-client/src/main/java/com/mark43/core/master/model/names/shared/NameAttribute.java ( EnumSet<AttributeType> PERMANENT_TYPES )
    //
    // Let's filter these out on the FE for now so that we can
    // but the real fix is for the BE to not save attributes to the master
    // profile that we don't care about prefilling.
    //
    // Once we make this BE adjustment, we can just delete this filter
    const filterNameAttributes = (nameAttributes) => {
        const attributeTypesToKeep = [
            AttributeTypeEnum.PHYSICAL_CHARACTERISTIC.name,
            AttributeTypeEnum.LANGUAGE.name,
            AttributeTypeEnum.VICTIM_DISABILITY_TYPE.name,
            AttributeTypeEnum.PERSON_SKILL.name,
            AttributeTypeEnum.MODUS_OPERANDI.name,
            AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
        ];
        return filter(nameAttributes, ({ attributeType }) =>
            includes(attributeTypesToKeep, attributeType)
        );
    };

    const keysToOmit = [
        'medicalTreatmentAttrId',
        'medicalTransportationTypeAttrId',
        'ambulanceNumber',
        'takenToHospitalBy',
        'medicalFacilityAttrId',
        'hospitalTreatedAt',
        'attendingPhysicians',
    ];

    return {
        ...personProfileFormData,
        personInjuries: [],
        personProfile: omit(personProfileFormData.personProfile, keysToOmit),
        nameAttributes: filterNameAttributes(personProfileFormData.nameAttributes),
    };
};

export const refreshPersonProfileForm =
    ({
        personProfileId,
        personType,
        nibrsOffenseCode,
        ownerEntityType,
        ownerEntityId,
        cadProfileId,
    }) =>
    (dispatch, getState) => {
        const state = getState();

        const personProfile = personProfilesSelector(state)[personProfileId];
        const { ageOfAdult } = currentUserDepartmentProfileSelector(state);

        // We should only prefill the person profile if they do not yet exist on the entity
        let shouldPrefill = false;
        const computeShouldPrefill = (targetPersonProfileId, entityExistingPersonProfiles) => {
            const entityExistingPersonProfileIds = map(entityExistingPersonProfiles, 'id');
            return !includes(entityExistingPersonProfileIds, targetPersonProfileId);
        };

        if (ownerEntityType === EntityTypeEnum.REPORT.name) {
            const personProfiles = personProfilesForReportIdSelector(state)(ownerEntityId);
            shouldPrefill = computeShouldPrefill(personProfileId, personProfiles);
        } else if (ownerEntityType === EntityTypeEnum.WARRANT.name) {
            const personProfiles = personProfilesForWarrantIdSelector(state)(ownerEntityId);
            shouldPrefill = computeShouldPrefill(personProfileId, personProfiles);
        }

        // Shim for isJuvenile either when person profile does not yet exist on the entity
        // Or when user edit a master profile and there is no isJuvenile value
        const canComputeIsJuvenile = personProfile && !isNil(ageOfAdult);
        const shouldShimIsJuvenile =
            canComputeIsJuvenile && (isNil(personProfile.isJuvenile) || shouldPrefill);

        const personCautions = cautionsByEntitySelector(state)(
            EntityTypeEnum.PERSON_PROFILE.name,
            personProfileId
        );

        const profileLocationLinks = locationEntityLinksWhereSelector(state)({
            entityId: personProfileId,
            entityType: EntityTypeEnum.PERSON_PROFILE.name,
        });

        const combinedLocationLinks =
            !cadProfileId && cadProfileId === personProfileId
                ? profileLocationLinks
                : profileLocationLinks.concat(
                      locationEntityLinksWhereSelector(state)({
                          entityId: cadProfileId,
                          entityType: EntityTypeEnum.PERSON_PROFILE.name,
                      })
                  );

        const personProfileFormData = {
            personInjuries: personInjuriesByPersonProfileIdSelector(state)(personProfileId),
            personProfile: shouldShimIsJuvenile
                ? shimIsJuvenilePersonProfile(personProfile, ageOfAdult)
                : personProfile,
            monikers: nameMonikersSelector(state)[personProfileId],
            identifiers: nameIdentifiersSelector(state)[personProfileId],
            phoneNumbers: namePhonesSelector(state)[personProfileId],
            emails: nameEmailsSelector(state)[personProfileId],
            identifyingMarks: identifyingMarksSelector(state)[personProfileId],
            emergencyContacts: personEmergencyContactsSelector(state)[personProfileId],
            passports: passportsSelector(state)[personProfileId],
            personProbations: personProbationsSelector(state)[personProfileId],
            employmentHistories: employmentHistoriesSelector(state)[personProfileId],
            schoolHistories: schoolHistoriesSelector(state)[personProfileId],
            nameAttributes: nameAttributesWhereSelector(state)({
                nameId: personProfileId,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
            }),
            locationEntityLinks: sortLocationEntityLinks({
                locationEntityLinks: uniqBy(combinedLocationLinks, 'locationId'),
            }),
            locations: locationsSelector(state),
            unknownLocationId: unknownLocationIdSelector(state),
            gangTracking: personGangTrackingForPersonIdSelector(state)(personProfileId),
            images: imagesSelector(state),
            cautions: sortBy(personCautions, ['id']),
            driversLicenseDetails:
                personDriverLicenseDetailsByPersonProfileIdSelector(state)(personProfileId),
        };

        const prefilledFormData = shouldPrefill
            ? convertPersonProfileFormDataToPrefilledPersonProfileFormData(personProfileFormData)
            : personProfileFormData;
        return convertToFormModel(prefilledFormData, { personType, nibrsOffenseCode });
    };

export const createPersonProfileFormInitialAddState =
    ({ personType, nibrsOffenseCode }) =>
    (dispatch, getState, { formsRegistry }) => {
        const isUnknown = personType === UNKNOWN_PERSON;

        const personSearchPrefill = {};
        const personSearchToAddForm = formsRegistry.get(formClientEnum.PERSON_SEARCH_TO_ADD_FORM);
        // Based on searchToAdd inputs, we can prefill the form
        if (personSearchToAddForm && !isUnknown) {
            const quickSearchQuery = split(personSearchToAddForm.get('quickSearchQuery'), ' ');
            const dateOfBirth = personSearchToAddForm.get('dateOfBirthQuickSearchQuery');
            personSearchPrefill.dlNumber = personSearchToAddForm.get('dlNumber');
            personSearchPrefill.dlStateAttrId = personSearchToAddForm.get('dlStateAttrId');

            // Always set date of birth, if provided
            if (dateOfBirth) {
                personSearchPrefill.dateOfBirth = dateOfBirth;
            }

            // If only one word is provided, check if its SSN, and prefill
            if (quickSearchQuery.length === 1 && ssnRegex.test(quickSearchQuery[0])) {
                personSearchPrefill.ssn = quickSearchQuery[0];
            } else {
                const { firstName, middleName, lastName } = buildPersonFullName(quickSearchQuery);
                personSearchPrefill.firstName = firstName;
                personSearchPrefill.middleName = middleName;
                personSearchPrefill.lastName = lastName;
            }
        }

        return {
            firstNameUnknown: isUnknown,
            lastNameUnknown: isUnknown,
            isPhoneNumberNotGiven: isUnknown,
            medicineTransported: false,
            showAdditionalWeightFields: false,
            showAdditionalHeightFields: false,
            // Default state for new persons is living
            isDead: false,
            nibrsCodeCode: get(nibrsOffenseCode, 'code'),
            nibrsOffenseCodeId: get(nibrsOffenseCode, 'id'),
            ...personSearchPrefill,
        };
    };

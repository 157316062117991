import { isObject } from 'lodash';
import { getInstanceIdFromFormReferenceValue } from 'dragon-react';

import { useCurrentFormValueForPath } from './use-current-form-value-for-path';

function isRecord(value: unknown): value is Record<string, unknown> {
    return isObject(value) && !Array.isArray(value);
}

/**
 * Given a fully qualified path (e.g. `a.b.c[0].d`) for a form reference, returns the instance id stored
 * in the current form's static value. If no value exists `undefined` is returned.
 */
export function useReferencedFormInstanceId(path: string): string | number | undefined {
    const formReferenceData = useCurrentFormValueForPath(path);
    if (isRecord(formReferenceData)) {
        return getInstanceIdFromFormReferenceValue(formReferenceData);
    }

    return undefined;
}

import { get } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import createFormModule from '../../../../core/forms/lib/createFormModule';

const fieldViewModels = {
    offenseUcrName: {
        key: 'offenseUcrName',
    },
    arrestUcr: {
        key: 'arrestUcr',
    },
};

const convertToFormModel = function ({ offenseUcr, arrestUcr }) {
    return {
        offenseUcrName: get(offenseUcr, 'name'),
        arrestUcr: get(arrestUcr, 'ucrCodeCode'),
    };
};

const arrestUcrClassificationForm = createFormModule({
    formName: formClientEnum.ARREST_UCR,
    fieldViewModels,
    convertToFormModel,
});

export default arrestUcrClassificationForm;

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import {
    forEach,
    entries,
    parseInt,
    omit,
    difference,
    compact,
    map,
    filter,
    includes,
    isFunction,
    keys,
    partition,
} from 'lodash';
import { reduxForm } from 'redux-form-mark43';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import testIds from '../../../core/testIds';
import CheckboxTree from '../../../modules/core/forms/components/checkboxes/CheckboxTree';
import _ExpandCollapseButton from '../core/ExpandCollapseButton';
import { convertExportOptionsToFormData } from '../../../modules/admin/export-presets/state/data';
import { SecondarySectionHeader } from '../../../modules/core/components/typography';
import { FILLABLE_PDF_TEMPLATE_TYPES } from '../../configs/exportConfig';
import GlobalExportsOptions from './GlobalExportsOptions';

const strings = componentStrings.exports.FormExportsOptions;

const InlineDisplay = styled.span`
    display: inline-block;
`;

const PrivacyDisplay = styled.span`
    display: block;
    font-style: italic;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FlexColumn = styled.div`
    flex: 1 1 100%;
`;

const ExpandCollapseButton = styled(_ExpandCollapseButton)`
    margin-right: 10px;
    align-items: center;
    margin-top: -3px;
`;

class FormExportsOptions extends React.Component {
    constructor(props, ...args) {
        super(props, ...args);
        this.state = {
            selectedExportPresetId: undefined,
            showExternalTemplates: false,
        };
    }

    /**
     * Custom `onChange` handler for downloadable packets.
     * It proxies through to the default `onChange` handler
     * but additionally resets the attachment state for deselected packets
     */
    handlePacketsChange = (packetsValue) => {
        this.props.fields.packets.onChange(packetsValue);
        this.setExportPresetId(undefined);
        const { selectedAttachmentsToInclude, redactedFields } = this.props.fields;
        const redactedFieldsValue = redactedFields.value;
        const selectedAttachmentsValue = selectedAttachmentsToInclude.value;
        if (redactedFieldsValue || selectedAttachmentsValue) {
            const selectedEntityIds = compact(
                map(
                    filter(this.props.packetOptions, (option) =>
                        includes(packetsValue, option.value)
                    ),
                    (packetOption) => packetOption.entityId
                )
            );
            const selectedAttachmentKeys = map(keys(selectedAttachmentsValue || {}), parseInt);
            const removedAttachmentKeys = difference(selectedAttachmentKeys, selectedEntityIds);
            if (removedAttachmentKeys.length) {
                selectedAttachmentsToInclude.onChange(
                    omit(selectedAttachmentsValue, removedAttachmentKeys)
                );
            }

            if (!packetsValue.length) {
                redactedFields.onChange([]);
            }
        }
    };

    onExternalTemplateClick = () => {
        this.setState({ showExternalTemplates: !this.state.showExternalTemplates });
    };

    handleExportOptionsChange = (exportPreset) => {
        const exportOptions = convertExportOptionsToFormData(exportPreset);
        forEach(entries(exportOptions), (option) => {
            if (
                !!this.props.fields[option[0]] &&
                isFunction(this.props.fields[option[0]].onChange)
            ) {
                this.props.fields[option[0]].onChange(option[1]);
            }
        });
        this.setExportPresetId(exportPreset.id);
    };

    setExportPresetId = (exportPresetId) => {
        this.setState({ selectedExportPresetId: exportPresetId });
    };

    render() {
        const {
            fields: {
                packets,
                includeFiles,
                includeHistoryEvents,
                onlyIncludeFieldsWithData,
                includeWarrantActivities,
                selectedAttachmentsToInclude,
                includeConfidentialInformation,
                includeNameAddendums,
                combinedPDF,
                redactedFields,
                redactFields,
                mergeAttachments,
            },
            handleSubmit,
            packetOptions,
            showIncludeWarrantActivities,
            entityId,
            entityType,
            includeAttachments,
            excludeAttachmentLinkTypes,
            showIncludeHistoryEvents,
            showOnlyIncludeFieldsWithData,
            showIncludeNameAddendums,
            showCombinedPDF,
            showFieldRedaction,
            submitButtonText,
            emailsEnabled,
        } = this.props;

        const finalPacketOptions =
            entityType === EntityTypeEnum.REPORT.name || entityType === EntityTypeEnum.CASE.name
                ? map(packetOptions, (option) => {
                      const isOptionReportSnapshot = !isUndefinedOrNull(option.rmsEventId);
                      option = {
                          ...option,
                          display: option.privacyDisplay ? (
                              <InlineDisplay>
                                  {option.display}
                                  <PrivacyDisplay>{option.privacyDisplay}</PrivacyDisplay>
                              </InlineDisplay>
                          ) : (
                              option.display
                          ),
                      };
                      // We only care to print the indicator text for reports
                      if (
                          entityType === EntityTypeEnum.REPORT.name &&
                          option.entityId === entityId &&
                          !isOptionReportSnapshot
                      ) {
                          return {
                              ...option,
                              indicatorText: strings.currentReport,
                          };
                      } else {
                          return option;
                      }
                  })
                : packetOptions;

        const [externalTemplatesPacketOptions, otherPacketOptions] = partition(
            finalPacketOptions,
            (option) => FILLABLE_PDF_TEMPLATE_TYPES.includes(option.templateType)
        );

        const globalExportOptions = (
            <GlobalExportsOptions
                packetOptions={packetOptions}
                onRedact={this.props.onRedact}
                onCancel={this.props.onCancel}
                onSubmit={this.props.onSubmit}
                onEmail={this.props.onEmail}
                onAddExportToEntity={this.props.onAddExportToEntity}
                includeAttachments={includeAttachments}
                includeFiles={includeFiles}
                includeHistoryEvents={includeHistoryEvents}
                onlyIncludeFieldsWithData={onlyIncludeFieldsWithData}
                includeWarrantActivities={includeWarrantActivities}
                includeNameAddendums={includeNameAddendums}
                combinedPDF={combinedPDF}
                redactedFields={redactedFields}
                redactFields={redactFields}
                numOfSelectedPackets={this.props.numOfSelectedPackets}
                submitButtonText={submitButtonText}
                selectedAttachmentsToInclude={selectedAttachmentsToInclude}
                showOnlyIncludeFieldsWithData={showOnlyIncludeFieldsWithData}
                showIncludeNameAddendums={showIncludeNameAddendums}
                showCombinedPDF={showCombinedPDF}
                showIncludeHistoryEvents={showIncludeHistoryEvents}
                showIncludeWarrantActivities={showIncludeWarrantActivities}
                showFieldRedaction={showFieldRedaction}
                excludeAttachmentLinkTypes={excludeAttachmentLinkTypes}
                handleSubmit={handleSubmit}
                includeConfidentialInformation={includeConfidentialInformation}
                mergeAttachments={mergeAttachments}
                clearExportPreset={() => this.setExportPresetId(undefined)}
                entityId={entityId}
                entityType={entityType}
                emailsEnabled={emailsEnabled}
            />
        );

        return this.props.renderFormExportsOptions ? (
            this.props.renderFormExportsOptions({
                packets,
                packetOptions: finalPacketOptions,
                globalExportOptions,
                handlePacketsChange: this.handlePacketsChange,
                handleExportOptionsChange: this.handleExportOptionsChange,
                selectedExportPresetId: this.state.selectedExportPresetId,
                setExportPresetId: this.setExportPresetId,
            })
        ) : (
            <FlexContainer>
                <SecondarySectionHeader>{strings.includeInExport}</SecondarySectionHeader>
                <FlexColumn className="clearfix">
                    <CheckboxTree
                        {...packets}
                        onChange={this.handlePacketsChange}
                        options={otherPacketOptions}
                        testId={testIds.EXPORTS_INCLUDE_IN_EXPORT_CHECKBOX}
                    />
                    {externalTemplatesPacketOptions.length > 0 && (
                        <>
                            <SecondarySectionHeader
                                data-test-id={testIds.EXPORTS_EXTERNAL_TEMPLATES_SECTION_HEADER}
                            >
                                {strings.externalTemplates.title}
                                <ExpandCollapseButton
                                    collapsed={!this.state.showExternalTemplates}
                                    setCollapsed={this.onExternalTemplateClick}
                                >
                                    {this.state.showExternalTemplates
                                        ? strings.externalTemplates.hide
                                        : strings.externalTemplates.show}
                                </ExpandCollapseButton>
                            </SecondarySectionHeader>
                            {this.state.showExternalTemplates && (
                                <CheckboxTree
                                    {...packets}
                                    onChange={this.handlePacketsChange}
                                    options={externalTemplatesPacketOptions}
                                    testId={testIds.EXPORTS_EXTERNAL_TEMPLATE_CHECKBOX}
                                />
                            )}
                        </>
                    )}
                    {globalExportOptions}
                </FlexColumn>
            </FlexContainer>
        );
    }
}
FormExportsOptions = reduxForm({
    form: 'exports',
    fields: [
        'packets',
        'includeFiles',
        'selectedAttachmentsToInclude',
        'includeHistoryEvents',
        'onlyIncludeFieldsWithData',
        'includeWarrantActivities',
        'includeConfidentialInformation',
        'includeNameAddendums',
        'combinedPDF',
        'redactedFields',
        'redactFields',
        'mergeAttachments',
    ],
})(FormExportsOptions);

// export the wrapped component
export default FormExportsOptions;

import React, { useState } from 'react';
import { Form, lifecycleOptions } from 'markformythree';
import { noop } from 'lodash';
import { JmsApiBookingSearchRequest } from '@mark43/rms-api';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import {
    SearchBookingsFormConfiguration,
    searchBookingsFormConfiguration,
    convertFromFormModel,
} from '../state/forms/searchBookingsForm';
import { MFTText } from '../../../core/forms/components/Text';
import { MFTSelect } from '../../../core/forms/components/selects/Select';
import FormSection from '../../../core/forms/components/FormSection';
import Row from '../../../core/components/Row';
import FormRow from '../../../core/forms/components/FormRow';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { MFTDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import AdvancedSearchFormFooter from './AdvancedSearchFormFooter';

const labels = componentStrings.search.AdvancedSearchBookingsForm;
const fieldsLabels = labels.fields;

type Options = {
    value: number;
    label: string;
};
type SearchBookingsFormProps = {
    submitHandler: (data: Partial<JmsApiBookingSearchRequest>) => Promise<void>;
    locationsLoading: boolean;
    officersLoading: boolean;
    locationsOptions: Options[];
    officersOptions: Options[];
    searchLocations: (query?: string) => Promise<void>;
    searchUsers: (query?: string) => Promise<void>;
};

export const SearchBookingsForm: React.FC<SearchBookingsFormProps> = ({
    submitHandler,
    locationsLoading,
    officersLoading,
    locationsOptions,
    officersOptions,
    searchLocations,
    searchUsers,
}) => {
    const { getForm } = useFormGetter();
    const form = getForm(formClientEnum.SEARCH_BOOKINGS_FORM);
    const submitForm = () => {
        if (!form) {
            return;
        }
        const data = convertFromFormModel(form.get());
        return submitHandler(data);
    };

    const clearForm = () => {
        form?.resetModel();
    };

    const isSubmitDisabled = () => {
        if (!form) {
            return true;
        }
        const formModel = form.getState().model;
        return Object.values(formModel).some((fieldValue) => !!fieldValue);
    };
    const [, setDateRange] = useState();
    const dateRangeFields = {
        withinLastPeriod: {
            onChange: noop,
        },
        toDatePeriod: {
            onChange: noop,
        },
        startDateUtc: {
            onChange: noop,
        },
        endDateUtc: {
            onChange: noop,
        },
    };
    return (
        <Form<SearchBookingsFormConfiguration>
            configuration={searchBookingsFormConfiguration}
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={formClientEnum.SEARCH_BOOKINGS_FORM}
            render={() => {
                return (
                    <>
                        <FormSection
                            className={'booking-search-section'}
                            title={labels.sections.booking}
                        >
                            <FormRow>
                                <MFTText
                                    path={'eventNumber'}
                                    label={fieldsLabels.eventNumber}
                                    maxLength={256}
                                    width={248}
                                    testId={testIds.ADVANCED_SEARCH_BOOKING_REN_NUMBER}
                                />
                            </FormRow>
                            <Row>
                                <MFTText
                                    width={248}
                                    path={'bookingNumber'}
                                    label={fieldsLabels.bookingNumber}
                                    maxLength={256}
                                    testId={testIds.ADVANCED_SEARCH_BOOKING_NUMBER}
                                />
                                <MFTDateRangePicker
                                    label={fieldsLabels.bookingTime}
                                    fields={dateRangeFields}
                                    width={248}
                                    onChange={setDateRange}
                                    includeTime={true}
                                    testId={testIds.ADVANCED_SEARCH_BOOKING_TIME}
                                    path={'bookingTime'}
                                    withinLastPeriodOptions={[
                                        'PT12H',
                                        'PT24H',
                                        'PT48H',
                                        'P7D',
                                        'P28D',
                                    ]}
                                    toDatePeriodOptions={['P1M', 'P1Y']}
                                />
                                <MFTSelect
                                    path={'bookingFacility'}
                                    width={248}
                                    label={fieldsLabels.bookingFacility}
                                    maxLength={256}
                                    isAsync={true}
                                    onQueryChange={searchLocations}
                                    loading={locationsLoading}
                                    options={locationsOptions}
                                    onClick={searchLocations}
                                    testId={testIds.ADVANCED_SEARCH_BOOKING_FACILITY}
                                />
                            </Row>
                        </FormSection>
                        <FormSection
                            className={'booking-search-section'}
                            title={labels.sections.personnel}
                        >
                            <MFTText
                                width={248}
                                path={'detaineeName'}
                                label={fieldsLabels.detaineeName}
                                maxLength={256}
                                testId={testIds.ADVANCED_SEARCH_BOOKING_DETAINEE_NAME}
                            />
                            <MFTText
                                path={'detaineeLastName'}
                                width={248}
                                label={fieldsLabels.detaineeLastName}
                                maxLength={256}
                                testId={testIds.ADVANCED_SEARCH_BOOKING_DETAINEE_LAST_NAME}
                            />
                            <MFTSelect
                                path={'bookingOfficer'}
                                width={248}
                                isAsync={true}
                                onQueryChange={searchUsers}
                                onClick={searchUsers}
                                label={fieldsLabels.bookingOfficer}
                                maxLength={256}
                                testId={testIds.ADVANCED_SEARCH_BOOKING_OFFICER}
                                loading={officersLoading}
                                options={officersOptions}
                            />
                            <MFTSelect
                                path={'arrestingOfficer'}
                                width={248}
                                label={fieldsLabels.arrestingOfficer}
                                maxLength={256}
                                testId={testIds.ADVANCED_SEARCH_BOOKING_ARRESTING_OFFICER}
                                loading={officersLoading}
                                isAsync={true}
                                onQueryChange={searchUsers}
                                onClick={searchUsers}
                                options={officersOptions}
                            />
                        </FormSection>
                        <div className={'clipped-bottom mark43-search-card-footer'}>
                            <AdvancedSearchFormFooter
                                searchText={labels.search}
                                searchDisabled={!isSubmitDisabled()}
                                includePersonsFilterCheckboxes={false}
                                includeFuzzyMatchingCheckbox={false}
                                onSubmit={submitForm}
                                onReset={clearForm}
                                fuzzyMatchingEnabled={false}
                            />
                        </div>
                    </>
                );
            }}
        />
    );
};

import _, { map, without } from 'lodash';
import invariant from 'invariant';
import { createSelector } from 'reselect';
import { taskViewModelsSelector } from '~/client-common/core/domain/tasks/state/ui';
import {
    DELETE_TASK_SUCCESS,
    taskViewsSelector,
} from '~/client-common/core/domain/taskViews/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { tasksSelector } from '~/client-common/core/domain/tasks/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';
import {
    openBox,
    closeBox,
    saveBoxSuccess,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import taskDetailsForm from '../forms/taskDetailsForm';
import { createTask, updateTask, deleteTask } from '../data';
import { currentCaseIdSelector } from '../../../core/state/ui';

const SET_CURRENT_CASE_TASKS = 'task-list/SET_CURRENT_CASE_TASKS';
const SET_CURRENT_CASE_TASKS_SORT_ORDER = 'task-list/SET_CURRENT_CASE_TASKS_SORT_ORDER';
const REMOVE_TASK_FROM_CASE_START = 'task-list/REMOVE_TASK_FROM_CASE_START';
const REMOVE_TASK_FROM_CASE_SUCCESS = 'task-list/REMOVE_TASK_FROM_CASE_SUCCESS';
const REMOVE_TASK_FROM_CASE_FAILURE = 'task-list/REMOVE_TASK_FROM_CASE_FAILURE';
const SET_TASK_DETAILS_FORM_TASK_ID = 'task-list/SET_TASK_DETAILS_FORM_TASK_ID';
const SET_TASK_LIST_SORT = 'task-list/SET_TASK_LIST_SORT';

const taskListSortTypes = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
};

const TASK_DETAILS_BOX_CONTEXT = { name: boxEnum.TASK_DETAILS_SIDE_PANEL };

export function setTaskListSort(sortKey) {
    return (dispatch, getState) => {
        const currentSort = taskListSortSelector(getState());
        dispatch({
            type: SET_TASK_LIST_SORT,
            payload: {
                sortKey,
                sortType:
                    currentSort.sortKey === sortKey &&
                    currentSort.sortType === taskListSortTypes.ASCENDING
                        ? taskListSortTypes.DESCENDING
                        : taskListSortTypes.ASCENDING,
            },
        });
    };
}

export function setCurrentCaseTasksSortOrder(taskIds) {
    return { type: SET_CURRENT_CASE_TASKS_SORT_ORDER, payload: taskIds };
}

export function setCurrentCaseTasks(caseTaskViews) {
    return {
        type: SET_CURRENT_CASE_TASKS,
        payload: caseTaskViews,
    };
}

function removeTaskFromCaseStart() {
    return {
        type: REMOVE_TASK_FROM_CASE_START,
        payload: undefined,
    };
}

function removeTaskFromCaseSuccess(taskId) {
    return {
        type: REMOVE_TASK_FROM_CASE_SUCCESS,
        payload: taskId,
    };
}

function removeTaskFromCaseFailure() {
    return {
        type: REMOVE_TASK_FROM_CASE_FAILURE,
        payload: undefined,
    };
}

export function removeTaskFromCase(taskId) {
    return (dispatch, getState) => {
        dispatch(removeTaskFromCaseStart());
        const caseId = currentCaseIdSelector(getState());
        return dispatch(deleteTask(caseId, taskId))
            .then((taskId) => dispatch(removeTaskFromCaseSuccess(taskId)))
            .catch((err) => dispatch(removeTaskFromCaseFailure(err.message)));
    };
}

function setTaskDetailsFormTaskId(taskId) {
    return {
        type: SET_TASK_DETAILS_FORM_TASK_ID,
        payload: taskId,
    };
}

export function openTaskDetailsSidePanel(taskId) {
    return (dispatch, getState) => {
        dispatch(setTaskDetailsFormTaskId(taskId));
        dispatch(taskDetailsForm.actionCreators.reset());
        dispatch(openBox(TASK_DETAILS_BOX_CONTEXT));
        if (taskId) {
            const taskToEdit = tasksSelector(getState())[taskId];
            dispatch(taskDetailsForm.actionCreators.change(taskToEdit));
        }
    };
}

export function closeTaskDetailsSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(TASK_DETAILS_BOX_CONTEXT));
        dispatch(taskDetailsForm.actionCreators.reset());
    };
}

export function openCaseTaskAttachmentSidePanel(attachmentEntityLinkDetails) {
    return (dispatch, getState, { overlayStore }) =>
        overlayStore.open(
            overlayIdEnum.CASE_TASK_ATTACHMENTS_SIDE_PANEL,
            attachmentEntityLinkDetails
        );
}
/**
 * Handles submission of the TaskDetailsForm both in the case of updating an
 *   existing task and creating a new task
 * @return {Thunk<Promise>}
 */
export function submitTaskDetailsForm() {
    return (dispatch, getState) => {
        return dispatch(
            taskDetailsForm.actionCreators.submit((formModel) => {
                const state = getState();
                const taskId = taskDetailsFormTaskIdSelector(state);
                let request;
                if (taskId) {
                    // update an existing task
                    const task = tasksSelector(state)[taskId];
                    request = updateTask(
                        currentCaseIdSelector(state),
                        taskDetailsForm.convertFromFormModel(formModel, task)
                    );
                } else {
                    // create a new task
                    request = createTask(
                        currentCaseIdSelector(state),
                        taskDetailsForm.convertFromFormModel(formModel)
                    );
                }
                return dispatch(request)
                    .then((tasks) => {
                        dispatch(setCurrentCaseTasks(tasks));
                        dispatch(saveBoxSuccess(TASK_DETAILS_BOX_CONTEXT));
                        dispatch(closeTaskDetailsSidePanel());
                    })
                    .catch((err) =>
                        dispatch(saveBoxFailure(TASK_DETAILS_BOX_CONTEXT, err.message))
                    );
            })
        ).catch(() => dispatch(saveBoxFailure(TASK_DETAILS_BOX_CONTEXT)));
    };
}

const currentCaseTasksUiSelector = (state) => state.ui.cases.tasks;

export const currentCaseTaskViewModelsSelector = createSelector(
    currentCaseTasksUiSelector,
    taskViewModelsSelector,
    ({ taskIds }, taskViewModelsSelector) =>
        map(taskIds, (taskId) => taskViewModelsSelector[taskId])
);

export const taskListSortSelector = createSelector(
    currentCaseTasksUiSelector,
    ({ sortKey, sortType }) => ({ sortKey, sortType })
);

export const sortedCurrentCaseTaskViewModelsSelector = createSelector(
    currentCaseTaskViewModelsSelector,
    taskListSortSelector,
    (taskViewModels, { sortKey, sortType }) => {
        let sortedTaskViewModels = _(taskViewModels);
        if (sortKey === 'description') {
            sortedTaskViewModels = sortedTaskViewModels.thru((arr) =>
                sortByNaturalOrder(arr, 'description')
            );
        } else if (sortKey === 'assignee') {
            sortedTaskViewModels = sortedTaskViewModels.sortBy(
                (task) => getViewModelProperties(task).assignee.lastNameAndBadge
            );
        } else if (sortKey === 'dueDateUtc') {
            sortedTaskViewModels = sortedTaskViewModels.sortBy('dueDateUtc');
        } else if (sortKey === 'statusAttrId') {
            sortedTaskViewModels = sortedTaskViewModels.sortBy(
                (task) => getViewModelProperties(task).statusParentAttrId
            );
        } else {
            invariant(false, 'Unknown Task List sortKey');
        }
        if (sortType === taskListSortTypes.DESCENDING) {
            return sortedTaskViewModels.reverse().value();
        } else {
            return sortedTaskViewModels.value();
        }
    }
);

const taskDetailsFormTaskIdSelector = createSelector(
    currentCaseTasksUiSelector,
    ({ taskDetailsFormTaskId }) => taskDetailsFormTaskId
);

const currentCaseTasksSortOrderSelector = createSelector(
    currentCaseTasksUiSelector,
    ({ sortedTaskIds }) => sortedTaskIds
);

export const sortedCurrentCaseTasksSelector = createSelector(
    currentCaseTasksSortOrderSelector,
    taskViewsSelector,
    (currentCaseTasksSortOrder, taskViews) =>
        map(currentCaseTasksSortOrder, (taskId) => taskViews[taskId])
);

export default function currentCaseTasksReducer(
    state = {
        taskIds: [],
        taskDetailsFormTaskId: undefined,
        sortKey: 'dueDateUtc',
        sortType: taskListSortTypes.ASCENDING,
    },
    action
) {
    switch (action.type) {
        case SET_TASK_LIST_SORT:
            return {
                ...state,
                sortKey: action.payload.sortKey,
                sortType: action.payload.sortType,
            };
        case SET_CURRENT_CASE_TASKS:
            return {
                ...state,
                taskIds: map(action.payload, 'id'),
            };
        case SET_CURRENT_CASE_TASKS_SORT_ORDER:
            return {
                ...state,
                sortedTaskIds: action.payload,
            };
        case DELETE_TASK_SUCCESS:
            return {
                ...state,
                sortedTaskIds: without(state.sortedTaskIds, action.payload),
            };
        case REMOVE_TASK_FROM_CASE_SUCCESS:
            return {
                ...state,
                taskIds: without(state.taskIds, action.payload),
            };
        case SET_TASK_DETAILS_FORM_TASK_ID:
            return {
                ...state,
                taskDetailsFormTaskId: action.payload,
            };
        default:
            return state;
    }
}

import { map } from 'lodash';
import styled from 'styled-components';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// configs
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { cadTicketsViewModelWhereSelector } from '~/client-common/core/domain/cad-tickets/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import NoDataBlock from '../../../modules/core/components/NoDataBlock';
import { CadTicketSummaryRaw } from '../../../modules/reports/core/components/event-info/CadTicketSummary';

const strings = componentStrings.reports.LinkedReports;

const NoDataBlockWrapper = styled(NoDataBlock)`
    display: flex;
    justify-content: center;
`;

const LinkedCadSection = styled.div`
    width: 100%;
    margin-bottom: 14px;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
`;
const LinkedCadSectionTitle = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: var(--arc-fontSizes-md);
    text-transform: uppercase;
    line-height: 1;
    padding-bottom: 6px;
    border-bottom: 6px solid ${(props) => props.theme.colors.mediumGrey};
`;
const LinkedCadSectionMembers = styled.div`
    margin-top: 12px;
`;

const cadSearchResults = function (cadTicketViewModels) {
    const cadTicketsRows = map(cadTicketViewModels, (cadTicketViewModel) => (
        <CadTicketSummaryRaw key={cadTicketViewModel.id} cadTicketViewModel={cadTicketViewModel} />
    ));
    return cadTicketsRows;
};
const LinkedCadReports = function ({
    title,
    reportingEventNumber,
    cadAgencyEventNumber,
    formatFieldByName,
    getCadTickets,
    noCadTicketsLabel = strings.noCadTickets,
}) {
    const cadTickets = getCadTickets(reportingEventNumber, cadAgencyEventNumber);
    if (!cadTickets) {
        return null;
    }

    const cadTicketsRows = cadSearchResults(cadTickets);
    const noCadTickets = (
        <div style={{ marginBottom: 15 }}>
            {noCadTicketsLabel ? (
                <NoDataBlockWrapper>{noCadTicketsLabel}</NoDataBlockWrapper>
            ) : (
                strings.noCadTicketsForRen(formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER))
            )}
        </div>
    );

    const cadLinkedReports = (
        <LinkedCadSection>
            <LinkedCadSectionTitle>{title}</LinkedCadSectionTitle>
            <LinkedCadSectionMembers>
                {cadTicketsRows.length > 0 ? cadTicketsRows : noCadTickets}
            </LinkedCadSectionMembers>
        </LinkedCadSection>
    );
    return cadLinkedReports;
};

export default connect(
    createStructuredSelector({
        getCadTickets: cadTicketsViewModelWhereSelector,
        formatFieldByName: formatFieldByNameSelector,
    })
)(LinkedCadReports);

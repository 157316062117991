import styled from 'styled-components';
import { TableCell as _TableCell } from 'components-mark43';

const TableCell = styled(_TableCell)<{ width: number }>`
    display: flex;
    align-items: center;
    font-size: var(--arc-fontSizes-md);
    color: ${(props) => props.theme.colors.darkGrey};
    width: ${(props) => props.width}px;
`;

export const TableCellContent = styled.div`
    margin-left: 10px;
`;

export default TableCell;

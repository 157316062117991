import { get, forEach } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';

import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import {
    requiredString,
    requiredInt,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const cadServiceTypesAdminFormFieldViewModels = {
    cadServiceType: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadServiceType',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'id',
            },
            {
                key: 'removedCapabilities',
            },
            {
                key: 'code',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'name',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'description',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'isActive',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'acceptActionAttributeId',
                validators: {
                    requiredError: requiredInt,
                },
            },
            {
                key: 'declineActionAttributeId',
                validators: {
                    requiredError: requiredInt,
                },
            },
            {
                key: 'unavailableActionAttributeId',
                validators: {
                    requiredError: requiredInt,
                },
            },
            {
                key: 'skipActionAttributeId',
                validators: {
                    requiredError: requiredInt,
                },
            },
            {
                key: 'userCancelActionAttributeId',
                validators: {
                    requiredError: requiredInt,
                },
            },
            {
                key: 'providerCancelActionAttributeId',
                validators: {
                    requiredError: requiredInt,
                },
            },
        ]),
    },
};

function convertToFormModel({ cadServiceType = {} }) {
    return {
        cadServiceType: {
            ...cadServiceType,
        },
    };
}

function convertFromFormModel({ cadServiceType = {} }) {
    return { cadServiceType };
}

const formModule = createFormModule({
    formName: formClientEnum.CAD_SERVICE_TYPES_ADMIN,
    fieldViewModels: cadServiceTypesAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

/**
 * Module of the cad serviceTypes admin form.
 * @type {Object}
 */
export default formModule;

export function validate() {
    const setValidity = formModule.actionCreators.setValidity;
    return (dispatch, getState) => {
        dispatch(formModule.actionCreators.validate());
        const state = getState();
        let isValid = formModule.selectors.formIsValidSelector(getState());
        const formModel = formModule.selectors.formModelSelector(state);

        const capabilitiesPath = 'cadServiceType.capabilities';
        const capabilities = get(formModel, capabilitiesPath);
        if (isValid) {
            isValid = Object.keys(formModel).length > 0;
        }

        forEach(capabilities, (capability, index) => {
            const validateArrayObjectHasField = (field) => {
                const fieldValid = !!get(capability, field);
                if (fieldValid === undefined) {
                    isValid = false;
                }
                dispatch(
                    setValidity(`${capabilitiesPath}.${index}.${field}`, {
                        requiredError: fieldValid,
                    })
                );
            };

            validateArrayObjectHasField('code');
            validateArrayObjectHasField('name');
        });

        const validateHasField = (field) => {
            const fieldValid = get(formModel, field);
            if (fieldValid === undefined) {
                isValid = false;
            }
            dispatch(
                setValidity(field, {
                    requiredError: fieldValid,
                })
            );
        };

        const includeLocation = get(formModel.cadServiceType, 'includeLocation');
        if (includeLocation) {
            validateHasField('cadServiceType.shapefileId');
            validateHasField('cadServiceType.propertyName');
        }

        // this is to handle the new scenrio. validation is not being honored
        validateHasField('cadServiceType.code');
        validateHasField('cadServiceType.name');
        validateHasField('cadServiceType.description');
        validateHasField('cadServiceType.isActive');
        validateHasField('cadServiceType.acceptActionAttributeId');
        validateHasField('cadServiceType.declineActionAttributeId');
        validateHasField('cadServiceType.unavailableActionAttributeId');
        validateHasField('cadServiceType.skipActionAttributeId');
        validateHasField('cadServiceType.userCancelActionAttributeId');
        validateHasField('cadServiceType.providerCancelActionAttributeId');
        return !isValid;
    };
}

import React from 'react';

import styled from 'styled-components';
import formatRenSequenceNumber from '~/client-common/core/domain/item-reporting-event-links/utils/formatRenSequenceNumber';
import Link from '../../../../../../core/components/links/Link';
import testIds from '../../../../../../../core/testIds';
import { ManageEvidenceDashboardResults } from '../../../../types';

const RenLink = styled(Link)`
    font-weight: var(--arc-fontWeight-semibold);
`;

const EvidenceRenCell = ({
    custodialReportId,
    reportingEventNumber,
    sequenceNumber,
}: Pick<
    ManageEvidenceDashboardResults,
    'custodialReportId' | 'reportingEventNumber' | 'sequenceNumber'
>) => {
    if (!reportingEventNumber || !sequenceNumber) {
        return <></>;
    }
    return (
        <RenLink
            hoverOnUnderline
            onClick={(event: React.MouseEvent) => event.stopPropagation()}
            to={`/reports/${custodialReportId}`}
            data-test-id={testIds.EVIDENCE_DASHBOARD_REN_LINK}
        >
            {formatRenSequenceNumber(reportingEventNumber, sequenceNumber)}
        </RenLink>
    );
};

export { EvidenceRenCell };

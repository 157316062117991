import { createSelector } from 'reselect';
import _, { get } from 'lodash';

import { fieldConfigurationByFieldNameSelector } from '../../../domain/field-configurations/state/data';
import { applicationSettingsSelector } from '../../../domain/settings/state/data';
import {
    parentAttributeIdByAttributeIdSelector,
    formatAttributeByIdSelector,
} from '../../../domain/attributes/state/data';
import * as fields from '../../../enums/universal/fields';
import globalAttributes from '../../../legacy-constants/globalAttributes';
import { formatCombinedSubdivisionsLabel } from '../../../../helpers/subdivisionHelpers';

export type Field = (typeof fields)[keyof typeof fields];

export const formatFieldByNameSelector = createSelector(
    fieldConfigurationByFieldNameSelector,
    (fieldConfigurationByFieldName) => (fieldName: string) =>
        get(fieldConfigurationByFieldName[fieldName], 'displayName') || ''
);

export type FormatFieldByName = ReturnType<typeof formatFieldByNameSelector>;

// this lives here, and not in a "subdivisionSelectors" type file
// because it's specifically related to _fields_ and their labels
export const combinedSubdivisionsLabelSelector = createSelector(
    formatFieldByNameSelector,
    formatCombinedSubdivisionsLabel
);

export const globalSequenceNumberLabelSelector = createSelector(
    formatFieldByNameSelector,
    applicationSettingsSelector,
    (formatFieldByName, applicationSettings) =>
        applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED
            ? `${formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER)} / ${formatFieldByName(
                  fields.REPORT_RECORD_NUMBER
              )}`
            : formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER)
);

export const proofOfOwnershipOtherLabelSelector = createSelector(
    formatFieldByNameSelector,
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
    (formatFieldByName, formatAttributeById, parentAttributeIdByAttributeId) =>
        (proofOfOwnershipAttrId: number) =>
            parentAttributeIdByAttributeId(proofOfOwnershipAttrId) ===
            globalAttributes.proofOfOwnershipGlobal.driversLicense
                ? formatAttributeById(proofOfOwnershipAttrId)
                : formatFieldByName(fields.NAME_ITEM_LINK_PROOF_OF_OWNERSHIP_OTHER)
);

export const formatFieldNames = (formatFieldByName: FormatFieldByName, fieldNames: Field[]) => {
    const fieldDisplayNames = _(fieldNames)
        .mapKeys((fieldName: Field) => {
            return fieldName;
        })
        .mapValues((fieldName) => {
            return formatFieldByName(fieldName);
        })
        .value();
    return fieldDisplayNames as Record<Field, string>;
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cssVar, Icon } from 'arc';
import styled from 'styled-components';
import {
    EntityTypeEnumType,
    FileCategoryEnum,
    LinkTypesEnumType,
    UsageSourceModuleEnum,
} from '@mark43/rms-api';

import {
    sortedAugmentedAttachmentViewModelsWhereSelector,
    convertAttachmentViewModelsForLightboxGallery,
} from '~/client-common/core/domain/attachments/state/ui';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../../core/testIds';
import { arcIconForFileCategory } from '../../../../attachments/files/helpers';
import { openImageGalleryLightbox } from '../../../../../legacy-redux/actions/imageGalleryLightboxActions';

import TruncatedText from '../../../../core/components/TruncatedText';
import { BodyMediumText } from '../../../../core/components/typography';
import { NoResults } from '../../../../core/components/NoResults';
import { CustomLink } from '../../../../core/components/links/Link';

import RecordSidebarSection from './RecordSidebarSection';

const strings = componentStrings.records.core.sidebar.AttachmentsSection;

interface LinkedAttachmentsSectionProps {
    entityType: EntityTypeEnumType;
    entityId: number;
    entityTitle: string;
    linkType: LinkTypesEnumType;
}

const AttachmentWrapper = styled.div`
    :not(:last-child) {
        margin-bottom: ${cssVar('arc.space.3')};
    }
`;

const StyledLink = styled(CustomLink)`
    display: flex;
    align-items: flex-start;
    gap: 6px;
`;

const StyledIcon = styled(Icon)`
    margin-top: 5px;
`;

const StyledName = styled.div`
    max-width: 90%;
    word-break: break-all;
`;

const StyledDescription = styled(BodyMediumText)`
    display: block;
    max-width: 90%;
    word-break: break-word;
`;

/**
 * A sidebar section to display attachments linked to an entity
 * It DOES NOT fetch attachments, only selects them
 * To use this component you need to fetch and store attachments in a different component up in the tree
 */
const LinkedAttachmentsSection: React.FC<LinkedAttachmentsSectionProps> = ({
    entityType,
    entityId,
    entityTitle,
    linkType,
}) => {
    const dispatch = useDispatch();
    const attachments = useSelector(sortedAugmentedAttachmentViewModelsWhereSelector)({
        entityType,
        entityId,
        linkType,
    });
    const dateTimeFormatter = useSelector(currentDepartmentDateFormatterSelector);

    let content: React.ReactNode;
    const usageLogParams = {
        primaryEntityTitle: entityTitle,
        sourceModule: UsageSourceModuleEnum.RMS_GENERAL.name,
    };

    const handleAttachmentClick = (index: number) => {
        const images = convertAttachmentViewModelsForLightboxGallery(
            attachments,
            dateTimeFormatter
        );
        dispatch(openImageGalleryLightbox(images, index, usageLogParams));
    };

    if (!attachments.length) {
        content = <NoResults children={strings.noResults} />;
    } else {
        content = attachments.map((attachment, index) => {
            const displayName = attachment.displayName;
            const { description, fileCategory } = getAttachmentFile(attachment);
            const iconType = arcIconForFileCategory(fileCategory ?? FileCategoryEnum.FILE.name);

            return (
                <AttachmentWrapper key={attachment.id}>
                    <StyledLink
                        onClick={() => handleAttachmentClick(index)}
                        data-test-id={testIds.ENTITY_SIDEBAR_ATTACHMENT_LINK}
                    >
                        <StyledIcon icon={iconType} color={cssVar('arc.colors.brand.default')} />
                        <StyledName>{displayName}</StyledName>
                    </StyledLink>
                    <StyledDescription data-test-id={testIds.ENTITY_SIDEBAR_ATTACHMENT_DESCRIPTION}>
                        <TruncatedText text={description} />
                    </StyledDescription>
                </AttachmentWrapper>
            );
        });
    }

    return <RecordSidebarSection title={strings.title}>{content}</RecordSidebarSection>;
};

export default LinkedAttachmentsSection;

import { ApprovalStatusEnum } from '@mark43/rms-api';

import _, { map } from 'lodash';
import { storeElasticReports } from '~/client-common/core/domain/elastic-reports/state/data';

import personalDashboardResource from '../../resources/personalDashboardResource';

export function loadActionRequiredReportIds() {
    return function (dispatch) {
        return personalDashboardResource
            .getActionRequiredReports({ hideLoadingBar: true })
            .then((reports) => {
                const elasticReports = _(reports)
                    .sortBy(({ approvalStatus }) =>
                        approvalStatus === ApprovalStatusEnum.REJECTED.name ? 0 : 1
                    )
                    .value();
                dispatch(storeElasticReports(elasticReports));
                return map(elasticReports, 'id');
            });
    };
}

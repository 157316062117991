import { ItemInvolvementTypeEnum, CodeTypeCategoryEnum } from '@mark43/rms-api';
import _ from 'lodash';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';
import { propertyDescriptionCode, propertyLossCodes } from '../../../constants/nibrsCodes';
import globalAttributes from '../../../legacy-constants/globalAttributes';
import validationStrings from '../../../strings/validationStrings';

const { none, unknown } = propertyLossCodes;
const { item, vehicle, firearm, drugs } = globalAttributes.itemType;
const strings = validationStrings.panel;
const stolenRecovered = [propertyLossCodes.stolen, propertyLossCodes.recovered];

// This function only validates on the offense card because it needs to make sure
// that the exceptions apply to Vehicles/Properties that are linked to the offense
// as oppose to it being included on the report.
// Also, this provides a better user experience since the error is caught when the
// card is saved making it easier (less clicks) for the user to do in order to fix
// the error.
export function recoveredImpliesStolen({
    propertyStatus,
    itemProfile,
    nibrsAllowedProperty,
    federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory,
    currentUserDepartmentId,
    allVehiclePropertyStatusesOnOffense,
    formatFieldByName,
}) {
    const offenseDisplayName = formatFieldByName(DISPLAY_ONLY_OFFENSE);
    const allowedResult = { allowed: true, message: '' };
    const vehicleAndAccessoriesDisallowedResult = {
        allowed: false,
        message: strings.nibrsRecoveredPropertyImpliesStolenForVehiclePartsAndAccessories({
            offenseDisplayName,
        }),
    };
    const recoveredImpliesStolenDisallowedResult = {
        allowed: false,
        message: strings.nibrsRecoveredPropertyImpliesStolen,
    };
    const { itemCategoryAttrId, propertyStatus: allPropertyStatuses } = itemProfile;
    const { propertyStatusAttrId } = propertyStatus;
    const { allowedPropertyLossNibrsCodes = [] } = nibrsAllowedProperty;

    const propertyCategoryNibrsCode = federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory(
        itemCategoryAttrId,
        CodeTypeCategoryEnum.NIBRS_PROPERTY_CATEGORY.name,
        currentUserDepartmentId
    );

    // None and Unknown statuses are not allowed to be linked to the offense. When
    // allowedPropertyLossNibrsCodes contains None or Unknown, that means None or Unknown can be
    // specified using the separate Offense.itemInvolvementType field, not using
    // PropertyStatus.propertyStatusAttrId.
    const actuallyAllowedPropertyLossNibrsCodes = _.without(
        allowedPropertyLossNibrsCodes,
        none,
        unknown
    );

    const propertyLossNibrsCode = federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory(
        propertyStatusAttrId,
        CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name,
        currentUserDepartmentId
    );

    const hasSomeStolenVehicleLinkedToOffense = _.some(
        allVehiclePropertyStatusesOnOffense,
        (propertyStatus) =>
            federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory(
                propertyStatus.propertyStatusAttrId,
                CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name,
                currentUserDepartmentId
            ) === propertyLossCodes.stolen
    );

    const hasStolenPropertyStatus = _.some(
        allPropertyStatuses,
        (propertyStatus) =>
            federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory(
                propertyStatus.propertyStatusAttrId,
                CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name,
                currentUserDepartmentId
            ) === propertyLossCodes.stolen
    );

    const allowsStolenRecovered =
        _.difference(stolenRecovered, actuallyAllowedPropertyLossNibrsCodes).length === 0;

    // offense code exceptions
    // stolenPropertyOffenses and counterfeitingForgery does not allow stolen
    // therefore the allowedPropertyLossNibrsCodes already handles these nibrs code exceptions
    // NIBRS spec includes "58B - Export Violations" in the exceptions.
    // But mark43 does not use currently use this code.
    if (!allowsStolenRecovered) {
        return allowedResult;
    }

    // If we are not validating a recovered property status continue on
    if (propertyLossNibrsCode !== propertyLossCodes.recovered) {
        return allowedResult;
    }

    // Vehicle Parts/Accessories property with a missing stolen property status and a missing stolen vehicle
    if (
        propertyCategoryNibrsCode === propertyDescriptionCode.vehiclePartsAndAccessories &&
        !hasStolenPropertyStatus &&
        !hasSomeStolenVehicleLinkedToOffense
    ) {
        return vehicleAndAccessoriesDisallowedResult;
    } else if (!hasStolenPropertyStatus) {
        // This case is when it is not a Vehicle Parts/Accessories property item and there is a missing
        // stolen status to go along with the recovered status.
        return recoveredImpliesStolenDisallowedResult;
    }

    return allowedResult;
}

/**
 * Validate a property status against an offense. Return an object containing {allowed, message}.
 * @param  {Object}   propertyStatus
 * @param  {Object}   itemProfile
 * @param  {Object}   nibrsAllowedProperty
 * @param  {function} federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory
 * @param  {number}   currentUserDepartmentId
 * @param  {string}   offenseNibrsCode    Used for formatting the message.
 * @param  {function} formatAttributeById Used for formatting the message.
 * @return {Object}
 */
export function isPropertyStatusAllowedOnOffense({
    propertyStatus,
    itemProfile,
    nibrsAllowedProperty,
    federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory,
    currentUserDepartmentId,
    offenseNibrsCode,
    formatAttributeById,
    formatFieldByName,
}) {
    if (!propertyStatus || !itemProfile || !nibrsAllowedProperty) {
        // bad data or totally unexpected error, allow the user to do what they want as much as
        // possible (which is probably not much at all)
        return { allowed: true, message: '' };
    }

    const {
        propertyAllowed,
        vehiclesAllowed,
        allowedPropertyLossNibrsCodes = [],
        allowedPropertyCategoryNibrsCodes = [],
        disallowedPropertyCategoryNibrsCodes = [],
    } = nibrsAllowedProperty;
    const { itemTypeAttrId, itemCategoryAttrId } = itemProfile;
    const { propertyStatusAttrId } = propertyStatus;
    const offenseDisplayName = formatFieldByName(DISPLAY_ONLY_OFFENSE);

    if (!propertyAllowed && !vehiclesAllowed) {
        return { allowed: false, message: strings.nibrsDisallowedItems({ offenseDisplayName }) };
    }
    if (!propertyAllowed && _.includes([firearm, drugs, item], itemTypeAttrId)) {
        return { allowed: false, message: strings.nibrsDisallowedProperty({ offenseDisplayName }) };
    }
    if (!vehiclesAllowed && itemTypeAttrId === vehicle) {
        return { allowed: false, message: strings.nibrsDisallowedVehicle({ offenseDisplayName }) };
    }

    // None and Unknown statuses are not allowed to be linked to the offense. When
    // allowedPropertyLossNibrsCodes contains None or Unknown, that means None or Unknown can be
    // specified using the separate Offense.itemInvolvementType field, not using
    // PropertyStatus.propertyStatusAttrId.
    const actuallyAllowedPropertyLossNibrsCodes = _.without(
        allowedPropertyLossNibrsCodes,
        none,
        unknown
    );
    const propertyLossNibrsCode = federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory(
        propertyStatusAttrId,
        CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name,
        currentUserDepartmentId
    );
    if (
        actuallyAllowedPropertyLossNibrsCodes.length > 0 &&
        !_.includes(actuallyAllowedPropertyLossNibrsCodes, propertyLossNibrsCode)
    ) {
        return {
            allowed: false,
            message: strings.nibrsDisallowedStatus({
                status: formatAttributeById(propertyStatusAttrId),
                offenseNibrsCode,
                offenseDisplayName,
            }),
        };
    }

    const propertyCategoryNibrsCode = federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory(
        itemCategoryAttrId,
        CodeTypeCategoryEnum.NIBRS_PROPERTY_CATEGORY.name,
        currentUserDepartmentId
    );
    if (
        allowedPropertyCategoryNibrsCodes.length > 0 &&
        !_.includes(allowedPropertyCategoryNibrsCodes, propertyCategoryNibrsCode)
    ) {
        return {
            allowed: false,
            message: strings.nibrsDisallowedCategory({
                category: formatAttributeById(itemCategoryAttrId),
                offenseNibrsCode,
                offenseDisplayName,
            }),
        };
    }
    if (
        disallowedPropertyCategoryNibrsCodes.length > 0 &&
        _.includes(disallowedPropertyCategoryNibrsCodes, propertyCategoryNibrsCode)
    ) {
        return {
            allowed: false,
            message: strings.nibrsDisallowedCategory({
                category: formatAttributeById(itemCategoryAttrId),
                offenseNibrsCode,
                offenseDisplayName,
            }),
        };
    }
    return { allowed: true, message: '' };
}

/**
 * Validate itemInvolvementType value against vehicles and property linked to offense.
 *   Return validation error message string OR undefined.
 * @param  {number}  vehicles
 * @param  {number}  property
 * @param  {Object}  nibrsAllowedProperty
 * @param  {string}  itemInvolvementType
 * @return {string|undefined}
 */
export function validateNibrsLinkedItems({
    vehicles,
    property,
    nibrsAllowedProperty,
    itemInvolvementType,
    formatFieldByName,
}) {
    const {
        propertyAllowed,
        vehiclesAllowed,
        allowedPropertyLossNibrsCodes = [],
    } = nibrsAllowedProperty;
    const itemsLinkedToOffenseCount = vehicles + property;
    const itemInvolvementIsNoneOrUnknown =
        itemInvolvementType === ItemInvolvementTypeEnum.NONE.name ||
        itemInvolvementType === ItemInvolvementTypeEnum.UNKNOWN.name;
    const itemInvolvementIsPropertyInvolved =
        itemInvolvementType === ItemInvolvementTypeEnum.PROPERTY_INVOLVED.name;
    const onlyPropertyAllowedButNoProperty = propertyAllowed && !vehiclesAllowed && property === 0;
    const onlyVehiclesAllowedButNoVehicles = !propertyAllowed && vehiclesAllowed && vehicles === 0;
    const offenseDisplayName = formatFieldByName(DISPLAY_ONLY_OFFENSE);

    // checking allowedPropertyLossNibrsCodes because we don't want to display this message when
    // itemInvolvementValue is hidden and not selectable. in the case of '35A' where there is only
    // one option (NONE) available, we can show nibrsDisallowedItems message (see isPropertyStatusAllowedOnOffense).
    if (
        itemInvolvementIsNoneOrUnknown &&
        itemsLinkedToOffenseCount > 0 &&
        allowedPropertyLossNibrsCodes.length > 1
    ) {
        return validationStrings.panel.nibrsItemInvolvementDisallowedItems({ offenseDisplayName });
    }

    if (
        itemInvolvementIsPropertyInvolved &&
        propertyAllowed &&
        vehiclesAllowed &&
        itemsLinkedToOffenseCount === 0
    ) {
        return validationStrings.panel.nibrsOffenseRequiresLinkedItems({ offenseDisplayName });
    }

    if (
        itemInvolvementIsPropertyInvolved &&
        (onlyPropertyAllowedButNoProperty || onlyVehiclesAllowedButNoVehicles)
    ) {
        return onlyPropertyAllowedButNoProperty
            ? validationStrings.panel.nibrsOffenseRequiresLinkedProperty({ offenseDisplayName })
            : validationStrings.panel.nibrsOffenseRequiresLinkedVehicles({ offenseDisplayName });
    }
}

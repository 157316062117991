import { PrintingDataTypeEnum } from '@mark43/rms-api';

import _, { get } from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    loadEvidencePrintables,
    removeEvidencePrintablesWhere,
    evidencePrintablesSelector,
} from '~/client-common/core/domain/evidence-printables/state/data';
import { parseEvidenceDocumentPrintableHash } from '~/client-common/core/domain/evidence-printables/utils/evidencePrintableHelpers';
import documentExportingResource from '../../resources/documentExportingResource';
import documentExportingForm from '../forms/documentExportingForm';
import {
    openBox,
    loadBoxStart,
    saveBoxSuccess,
    saveBoxHalt,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import { exportPDF } from '../../../../../legacy-redux/actions/exportsActions';
import { Mark43Error } from '../../../../../lib/errors';

const documentExportingModalContext = {
    name: boxEnum.DOCUMENT_EXPORTING_MODAL,
};

/**
 * Open the modal for document exporting, and start loading the printables from the server.
 *
 * This function needs both master and contexted item ids because Chain of Custody printables need
 *   the former, and other printables need the latter.
 * @param {number[]} options.masterItemIds
 * @param {number[]} options.itemProfileIds
 */
export function openDocumentExportingModal({ masterItemIds, itemProfileIds }) {
    return function (dispatch) {
        // must clear evidence printables from data state before loading new ones because the
        // dropdown in the form will show all of them as options
        dispatch(removeEvidencePrintablesWhere({}));

        dispatch(openBox(documentExportingModalContext));
        dispatch(loadBoxStart(documentExportingModalContext));
        Promise.all([
            dispatch(
                loadEvidencePrintables({
                    itemIds: itemProfileIds,
                    printingDataTypes: [
                        PrintingDataTypeEnum.EVIDENCE_DOCUMENT.name,
                        PrintingDataTypeEnum.SINGLE_REN_ITEM_LIST.name,
                    ],
                })
            ),
            dispatch(
                loadEvidencePrintables({
                    itemIds: masterItemIds,
                    printingDataTypes: [PrintingDataTypeEnum.CHAIN_OF_CUSTODY.name],
                })
            ),
        ])
            .then(() => {
                dispatch(saveBoxHalt(documentExportingModalContext));
            })
            .catch((err) => {
                dispatch(saveBoxFailure(documentExportingModalContext, err.message));
            });
    };
}

/**
 * Start exporting an evidence document with the standard export pipeline. This
 *   will open the exports tray.
 */
export function submitDocumentExportingModal() {
    return function (dispatch, getState) {
        dispatch(
            documentExportingForm.actionCreators.submit((formModel) => {
                const state = getState();

                // based on the dropdown option that the user selected, find the
                // original printable
                const { printingTemplateId, title } = parseEvidenceDocumentPrintableHash(
                    formModel.printableHash
                );
                let printables = _.filter(evidencePrintablesSelector(state), {
                    printingTemplateId,
                    title,
                });

                if (!printingTemplateId || printables.length === 0) {
                    const errorMessage =
                        'Failed to load evidence document, please click cancel and try again';
                    dispatch(saveBoxFailure(documentExportingModalContext, errorMessage));
                    // error not caught, used for error reporting because this isn't
                    // supposed to ever happen
                    throw new Mark43Error(errorMessage);
                }

                // If there are multiple printables corresponding to the dropdown option that the
                // user selected, then they have to be combined into 1 printable to become 1 export.
                // As of April 2018, the only type of printable that this can happen to is Chain of
                // Custody, which printables all look the same except for their unique entityId.
                if (printables.length > 1) {
                    printables = [
                        {
                            ...printables[0],
                            childPrintables: _.tail(printables),
                        },
                    ];
                }
                const exportOptions = {};
                const printableDataType = get(printables, '[0].dataType');
                if (printableDataType === PrintingDataTypeEnum.CHAIN_OF_CUSTODY.name) {
                    // include any Chain of Custody attachments
                    exportOptions.includeFiles = true;
                }

                // the exports tray handles errors from this async action
                dispatch(
                    exportPDF(
                        exportOptions,
                        printables,
                        documentExportingResource.exportEvidenceDocument
                    )
                );
                dispatch(saveBoxSuccess(documentExportingModalContext));
            })
        ).catch(() => {
            dispatch(saveBoxHalt(documentExportingModalContext));
        });
    };
}

import React from 'react';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { some } from 'lodash';

import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { RRFAttributeSelect } from './selects/AttributeSelect';
import { RRFText } from './Text';
import FormRow from './FormRow';

const { withRRFFieldValues } = reactReduxFormHelpers;

// Arbiter can handle this hide/show functionality with hide/show rules,
// but this can be used for forms not hooked up to Arbiter
export default function createAttributeWithOtherFields({
    attributeField,
    attributeOtherField,
    attributeType,
    multiple,
    // Should deprecate `width` and use length instead.
    width = 250,
    length = 'md',
}) {
    return compose(
        connect(
            createStructuredSelector({
                attributeIsOther: attributeIsOtherSelector,
            })
        ),
        withRRFFieldValues({ attributeIds: attributeField }),
        mapProps(({ attributeIsOther, attributeIds }) => ({
            isOther: multiple
                ? some(attributeIds, (attributeId) => attributeIsOther(attributeId))
                : attributeIsOther(attributeIds), // `attributeIds` will be one value
        }))
    )(function AttributeWithOtherField({ isOther }) {
        return (
            <div>
                <RRFAttributeSelect
                    path={attributeField}
                    width={width}
                    length={length}
                    attributeType={attributeType}
                    multiple={multiple}
                />
                {isOther && (
                    <FormRow hasIndent>
                        <RRFText path={attributeOtherField} width={width} length={length} />
                    </FormRow>
                )}
            </div>
        );
    });
}

import * as React from 'react';
import { InlineFormConfigurationView } from '@mark43/rms-api';

const Context = React.createContext<
    Partial<Record<string, InlineFormConfigurationView>> | undefined
>(undefined);

export const DragonInlineFormConfigurationProvider = Context.Provider;

export function useDragonInlineFormConfigurationContext(): Partial<
    Record<string, InlineFormConfigurationView>
> {
    const value = React.useContext(Context);
    if (!value) {
        throw new Error(
            'Unexpectedly did not find value for inline form configurations context value in hierarchy'
        );
    }

    return value;
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { CollapsibleSectionWithStickyHeader } from '../../../core/components/CollapsibleSectionWithStickyHeader';
import { reportsResultsViewModelsSelector } from '../../core/state/ui';
import { CreateCaseModal } from '../../core/components';
import EnhancedCaseReportsTable from './EnhancedCaseReportsTable';
import AddReportsToCasePopover from './AddReportsToCasePopover';
import ReasonForRemovalModal from './ReasonForRemovalModal';

const strings = componentStrings.cases.caseSummary.CaseReports;

const EnhancedCaseReports = ({
    caseReports,
    caseIsEditable,
    className,
}: {
    caseReports: ReturnType<typeof reportsResultsViewModelsSelector>;
    caseIsEditable: boolean;
    className: string;
}) => {
    const [isSectionOpen, toggleSectionOpen] = useState(true);

    const applicationSettings = useSelector(applicationSettingsSelector);

    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    const caseEnhancementsEnabled = !!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE;

    return (
        <CollapsibleSectionWithStickyHeader
            isOpen={isSectionOpen}
            title={strings.title(caseDisplayName)}
            containerSelector={'.case-content-container'}
            toggleOpen={toggleSectionOpen}
            className={className}
            headerRightElement={
                caseEnhancementsEnabled ? (
                    <AddReportsToCasePopover
                        caseReports={caseReports}
                        caseIsEditable={caseIsEditable}
                    />
                ) : (
                    <></>
                )
            }
        >
            <EnhancedCaseReportsTable
                caseReports={caseReports}
                caseIsEditable={caseIsEditable}
                caseDisplayName={caseDisplayName}
            />
            <FeatureFlagged flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE">
                <ReasonForRemovalModal />
                <CreateCaseModal />
            </FeatureFlagged>
        </CollapsibleSectionWithStickyHeader>
    );
};

export default EnhancedCaseReports;

import classNames from 'classnames';
import React from 'react';
import { pick } from 'lodash';
import styled from 'styled-components';

import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { nextSortType } from '~/client-common/helpers/searchHelpers';
import _Icon, { iconTypes } from '../../../../modules/core/components/Icon';

const Icon = styled(_Icon)`
    vertical-align: middle;
    margin-top: -4px;
    margin-left: 4px;
`;

const mapSortTypeToIcon = {
    [sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT]: (
        <Icon size={12} color="cobaltBlue" type={iconTypes.ARROW_DOWN} />
    ),
    [sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT]: (
        <Icon size={12} color="cobaltBlue" type={iconTypes.ARROW_UP} />
    ),
    [sortTypeEnum.ALPHABETICAL_A_TO_Z]: (
        <Icon size={12} color="cobaltBlue" type={iconTypes.ARROW_UP} />
    ),
    [sortTypeEnum.ALPHABETICAL_Z_TO_A]: (
        <Icon size={12} color="cobaltBlue" type={iconTypes.ARROW_DOWN} />
    ),
    [sortTypeEnum.AGE_YOUNGEST_TO_OLDEST]: (
        <Icon size={12} color="cobaltBlue" type={iconTypes.ARROW_UP} />
    ),
    [sortTypeEnum.AGE_OLDEST_TO_YOUNGEST]: (
        <Icon size={12} color="cobaltBlue" type={iconTypes.ARROW_DOWN} />
    ),
    [sortTypeEnum.NUMBER_LOWEST_TO_HIGHEST]: (
        <Icon size={12} color="cobaltBlue" type={iconTypes.ARROW_UP} />
    ),
    [sortTypeEnum.NUMBER_HIGHEST_TO_LOWEST]: (
        <Icon size={12} color="cobaltBlue" type={iconTypes.ARROW_DOWN} />
    ),
};

const TABLE_COLUMN_LEFT_PADDING = 10;

/**
 * To be used with Table. This component is used in place of TableColumn to
 * build columns that are sortable.
 * @param  {string}    className  The inherited styled component class name
 * @param  {function}  onClick    Callback which is called whenever the TableColumn
 *   is clicked.
 * @param  {string}    sortKey    The column's sort key enum that will be passed
 *   into onClick callback
 * @param  {string}    [sortType] Indicates the type of sort for this column.
 *   This value will dictate display behaviours such as what colour the header
 *   should be and which icons should display.
 * @param  {string[]}  sortOptions  List of sort types to be cycled through
 * @param  {string}    display    The text which will be visible in the TableColumn.
 *   this column represents. `props.onClick` will be provided this value.
 * @param  {width}     width      The width of the column.
 */
export default function SortableTableColumn({
    className,
    display,
    width,

    // sorting
    sortKey,
    sortType,
    sortOptions,

    // handlers
    onClick: handleClick,
}) {
    const sortIcon = mapSortTypeToIcon[sortType];
    const sortTypes = pick(sortTypeEnum, sortOptions);

    return (
        <th
            className={classNames(className, 'cobalt-table-column sortable')}
            style={{ width }}
            onClick={() =>
                handleClick({
                    value: sortKey,
                    sortOptions: [{ sortType: nextSortType(sortType, sortTypes) }],
                })
            }
        >
            <div
                className="cobalt-table-column-title"
                style={{ width: width - TABLE_COLUMN_LEFT_PADDING }}
            >
                {display}
                {sortIcon}
            </div>
        </th>
    );
}

import { TrafficCrashCustomField } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'trafficCrashCustomFields';
const trafficCrashCustomFieldModule = createNormalizedModule<TrafficCrashCustomField>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS
export const trafficCrashCustomFieldsSelector =
    trafficCrashCustomFieldModule.selectors.entitiesSelector;

// REDUCER
export default trafficCrashCustomFieldModule.reducerConfig;

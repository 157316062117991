import { EmploymentHistory } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { mapValues, map } from 'lodash';
import { ViewModel, buildViewModel } from '../../../../../helpers/viewModelHelpers';
import { formatDateRange } from '../../../../dates/utils/dateRangeHelpers';
import { prettify } from '../../../../../helpers/stringHelpers';
import { employmentHistoriesSelector } from '../data';

type EmploymentHistoryViewModelProperties = {
    employerName?: string;
    dateRange?: string;
};

export type EmploymentHistoryViewModel = ViewModel<
    EmploymentHistory,
    EmploymentHistoryViewModelProperties
>;

const employmentHistoryViewModelsSelector = createSelector(
    employmentHistoriesSelector,
    (employmentHistories) => {
        const viewModel = buildViewModel<EmploymentHistory, EmploymentHistoryViewModelProperties>({
            recursive: true,
            mappers: [
                ({ dateStart, dateEnd }) => ({
                    dateRange: formatDateRange(dateStart, dateEnd, 'Unknown dates'),
                }),
                ({ employerName }) => ({
                    employerName: prettify(employerName),
                }),
            ],
        });
        return mapValues(employmentHistories, (employmentHistoryList) =>
            map(employmentHistoryList, viewModel)
        );
    }
);

export const employmentHistoryViewModelsByPersonProfileIdSelector = createSelector(
    employmentHistoryViewModelsSelector,
    (employmentHistoryViewModels) => (personProfileId: number) =>
        employmentHistoryViewModels[personProfileId]
);

import React from 'react';
import { FormattedDate } from '~/client-common/core/dates/components';
import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { formatUrl } from '~/client-common/core/domain/report-external-links/utils';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ExternalLink } from '../../../core/components/links/Link';
import testIds from '../../../../core/testIds';

import { ReportLinkContainer, BodyItalic } from './AssociatedRecordsUiComponents';

const strings = componentStrings.records.associatedRecords;

export default function ReportExternalLink({ reportLink, formatAttributeById, attributeIsOther }) {
    const sourceAttribute = attributeIsOther(reportLink.sourceSystemAttrId)
        ? reportLink.sourceSystemOther
        : formatAttributeById(reportLink.sourceSystemAttrId);

    const title = sourceAttribute
        ? `${sourceAttribute}: ${reportLink.sourceId}`
        : reportLink.sourceId;
    return (
        <ReportLinkContainer>
            {reportLink.url ? (
                <ExternalLink
                    to={formatUrl(reportLink.url)}
                    openInNewTab={true}
                    data-test-id={testIds.REPORT_EXTERNAL_LINK_URL_LINK}
                >
                    {title}
                </ExternalLink>
            ) : (
                <div>{title}</div>
            )}
            <BodyItalic>
                {strings.associatedBy}{' '}
                <ConnectedFormattedUser
                    userId={reportLink.updatedBy}
                    format={FORMATS.FULL_NAME_WITH_FIRST_INITIAL_AND_ID_NUMBER}
                />{' '}
                on{' '}
                <FormattedDate
                    date={reportLink.updatedDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE}
                />
            </BodyItalic>
        </ReportLinkContainer>
    );
}

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { omit } from 'lodash';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { queryParamDefaults } from '~/client-common/configs/advancedSearchConfig';
import { convertFromFormModel } from '../../../tasks/dashboard/state/forms/tasksDashboardSearchForm';
import { SearchModuleType } from '../../../../core/typings/search-module';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import testIds from '../../../../core/testIds';
import _DropdownMenu from '../../../core/components/DropdownMenu';
import _Icon, { iconTypes } from '../../../core/components/Icon';
import useSaveAsNewSearchHandler from '../../core/hooks/useSaveAsNewSearch';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { UpdateSavedSearchConfirmationModal } from './SavedSearchConfirmationModals';

const strings = componentStrings.search.savedSearch.SavedSearchDropdownButton;

const DropdownMenu = styled(_DropdownMenu)`
    // width: 170px prevents option with longest label from wrapping
    width: 170px;
    // reset line-height and text-align to prevent inheritance
    //  from table header styles in advanced search
    line-height: initial;
    text-align: initial;
`;

const DropdownLabel = styled.div`
    justify-content: center;
`;

const Icon = styled(_Icon)`
    margin-left: 7px;
    position: relative;
    top: 2px;
`;
interface SavedSearchDropdownButtonProps {
    searchModule: SearchModuleType;
    tooltipContainer?: string;
    updateSavedSearchOverride?: () => void;
}

export function SavedSearchDropdownButton(props: SavedSearchDropdownButtonProps) {
    const { updateSavedSearchOverride, searchModule } = props;

    const overlayStore = useOverlayStore();
    const dispatch = useDispatch();
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const existingSavedSearch = useSelector(
        searchModule.selectors.executedSavedSearchToUpdateSelector
    );
    const isSavedSearchUpdatable = useSelector(
        searchModule.selectors.isSavedSearchUpdatableSelector
    );
    const currentQuery = useSelector(searchModule.selectors.currentQuerySelector);
    const { getForm } = useFormGetter();
    const taskFilterForm = getForm(formClientEnum.TASKS_DASHBOARD_SEARCH_FORM);

    const updateSavedSearch = useCallback(() => {
        const formModel = taskFilterForm?.getState().model;

        const savedSearchQuery = omit(convertFromFormModel(formModel), 'query');

        const tasksQuery = {
            form: taskFilterForm,
            query: savedSearchQuery,
            from: queryParamDefaults.FROM,
            size: queryParamDefaults.SIZE,
        };

        /* Tasks search module currentQuerySelector is not compatible
           because we use different form library (convertFromFormModel is not applied)
           and we overwrite the query response with the form model */
        const query =
            searchModule.elasticSearchType === ElasticSearchTypeEnum.TASK.name && !!tasksQuery
                ? { ...currentQuery, ...tasksQuery }
                : currentQuery;

        if (updateSavedSearchOverride) {
            updateSavedSearchOverride();
        } else {
            dispatch(searchModule.actionCreators.updateSavedSearch(query));
        }
    }, [
        currentQuery,
        dispatch,
        searchModule.actionCreators,
        searchModule.elasticSearchType,
        taskFilterForm,
        updateSavedSearchOverride,
    ]);

    const updateSavedSearchHandler = useCallback(() => {
        if (existingSavedSearch?.isShared) {
            overlayStore.open(overlayIdEnum.UPDATE_SAVED_SEARCH_CONFIRMATION_MODAL);
        } else {
            updateSavedSearch();
        }
    }, [existingSavedSearch, overlayStore, updateSavedSearch]);

    // the BE sometimes returns a value for existingSavedSearch when we don't want it to
    // e.g. after clearing filters in the Reports dashboard search or with auto save searches
    // isSavedSearchUpdatable is independent from the BE and tracks whether there is a selected saved search that is updatable
    const showUpdateOption =
        !existingSavedSearch?.isAutoSave &&
        isSavedSearchUpdatable &&
        (!existingSavedSearch?.isShared ||
            (existingSavedSearch?.canEdit &&
                currentUserHasAbility(abilitiesEnum.ADMIN.SAVED_SEARCH_SHARING)));

    const saveAsNewSearchHandler = useSaveAsNewSearchHandler(searchModule);
    const saveNewSearchClickHandler = useCallback(() => {
        saveAsNewSearchHandler();
    }, [saveAsNewSearchHandler]);

    return (
        <FeatureFlagged flag="RMS_SAVED_SEARCH_SHARING_ENABLED">
            <DropdownMenu
                buttonContent={
                    <DropdownLabel>
                        <span>{strings.dropdownButton}</span>
                        <Icon color="cobaltBlue" size={12} type={iconTypes.TRIANGLE_DOWN} />
                    </DropdownLabel>
                }
                testId={testIds.SAVED_SEARCH_DROPDOWN_BUTTON}
            >
                {showUpdateOption && (
                    <div
                        className="dropdown-menu-option"
                        onClick={updateSavedSearchHandler}
                        tabIndex={0}
                        data-test-id={testIds.SAVED_SEARCH_UPDATE_OPTION}
                    >
                        {strings.updateSearch}
                    </div>
                )}
                <div
                    className="dropdown-menu-option"
                    onClick={saveNewSearchClickHandler}
                    tabIndex={0}
                    data-test-id={testIds.SAVED_SEARCH_SAVE_NEW_SEARCH_OPTION}
                >
                    {strings.saveNewSearch}
                </div>
            </DropdownMenu>
            <UpdateSavedSearchConfirmationModal
                searchModule={searchModule}
                onModalSave={updateSavedSearch}
            />
        </FeatureFlagged>
    );
}

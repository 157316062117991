import { RefContextEnum } from '@mark43/rms-api';
import { every, filter, includes, map, mapValues } from 'lodash';

import { createSelector } from 'reselect';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
    getViewModelProperties,
} from '../../../../../helpers/viewModelHelpers';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';
import { warrantsSelector } from '../data';
import { canRead, canWrite } from '../../../entity-permissions/state/ui';
import { personProfileViewModelByIdSelector } from '../../../person-profiles/state/ui';
import { formatWarrantShortTitle, formatWarrantTitle } from '../../../../../helpers/warrantHelpers';
import { formatFieldByNameSelector } from '../../../../fields/state/config';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

import { isUndefinedOrNull } from '../../../../../helpers/logicHelpers';
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '../../../attributes/state/data';
import { nonInternalStaticallyHiddenFieldConfigurationsInContextSelector } from '../../../field-configuration-contexts/state/data';
import * as fields from '../../../../enums/universal/fields';
import componentStrings from '../../../../strings/componentStrings';

export * from './names';

const warrantDetailsStrings = componentStrings.warrants.warrant.WarrantDetailsCard;

const warrantViewModelsSelector = createSelector(
    warrantsSelector,
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
    formatMiniUserByIdSelector,
    personProfileViewModelByIdSelector,
    formatFieldByNameSelector,
    (
        warrants,
        formatAttributeById,
        formatAttributeWithOther,
        formatMiniUserById,
        personProfileViewModelById,
        formatFieldByName
    ) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ obtainingOfficerUserId, obtainingOfficerOther }) => ({
                    obtainingOfficerUserDisplay: joinTruthyValues(
                        [formatMiniUserById(obtainingOfficerUserId), obtainingOfficerOther],
                        ': '
                    ),
                }),
                ({
                    warrantNumber,
                    warrantTypeAttrId,
                    subjectPersonProfileId,
                    issuingAgencyNameAttrId,
                    issuingAgencyNameOther,
                }) => {
                    const personProfileViewModelProps = getViewModelProperties(
                        personProfileViewModelById(subjectPersonProfileId)
                    );
                    const warrantType = formatAttributeById(warrantTypeAttrId);
                    return {
                        warrantShortTitle: formatWarrantShortTitle({
                            warrantNumber,
                            warrantType,
                        }),
                        warrantPrintingTitle: `${formatFieldByName(
                            fields.WARRANT_WARRANT_NUMBER
                        )}: # ${warrantNumber}`,
                        warrantTitle: formatWarrantTitle({
                            warrantNumber,
                            warrantType,
                            subjectName: personProfileViewModelProps.fullName,
                        }),
                        issuingAgencyNameDisplay: formatAttributeWithOther({
                            attributeId: issuingAgencyNameAttrId,
                            other: issuingAgencyNameOther,
                        }),
                    };
                },
                ({ permissionSet }) => ({
                    canRead: canRead(permissionSet),
                    canWrite: canWrite(permissionSet),
                }),
                boolToDisplayMapper,
                ({ hasNoBail, bailAmount }) => {
                    if (hasNoBail) {
                        return {
                            bailAmountDisplay: warrantDetailsStrings.noBail,
                        };
                    } else {
                        return {
                            bailAmountDisplay: isUndefinedOrNull(bailAmount)
                                ? null
                                : `${bailAmount}`,
                        };
                    }
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(warrants, viewModel);
    }
);

export const warrantViewModelByIdSelector = createSelector(
    warrantViewModelsSelector,
    (warrantsViewModels) => (id) => warrantsViewModels[id]
);

export const warrantViewModelBySubjectIdSelector = createSelector(
    warrantViewModelsSelector,
    (warrantsViewModels) => (subjectId) =>
        filter(
            warrantsViewModels,
            ({ subjectPersonProfileId }) => subjectId === subjectPersonProfileId
        )
);

export const warrantViewModelsBySubjectMasterIdSelector = createSelector(
    warrantViewModelsSelector,
    (warrantsViewModels) => (subjectMasterId) => {
        return filter(
            warrantsViewModels,
            ({ subjectMasterPersonProfileId }) => subjectMasterId === subjectMasterPersonProfileId
        );
    }
);

export const hideVehicleInformationSelector = createSelector(
    nonInternalStaticallyHiddenFieldConfigurationsInContextSelector,
    (nonInternalStaticallyHiddenFieldConfigurationsInContext) => {
        const vehicleInformationFieldNames = [
            fields.WARRANT_VEHICLE_COLOR,
            fields.WARRANT_VEHICLE_MAKE,
            fields.WARRANT_VEHICLE_MODEL,
            fields.WARRANT_VEHICLE_YEAR,
            fields.WARRANT_LICENSE_PLATE_NUMBER,
            fields.WARRANT_LICENSE_PLATE_STATE,
        ];

        const nonInternalStaticallyHiddenFieldNames = map(
            nonInternalStaticallyHiddenFieldConfigurationsInContext(
                RefContextEnum.FORM_WARRANT_DETAILS.name
            ),
            'fieldName'
        );
        return every(vehicleInformationFieldNames, (vehicleInformationFieldName) =>
            includes(nonInternalStaticallyHiddenFieldNames, vehicleInformationFieldName)
        );
    }
);

import { ExportIntervalTypeEnum } from '@mark43/rms-api';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

export const COMPLIANCE_DEFAULT_PAGE_SIZE = 25;
export const COMPLIANCE_POLL_INTERVAL_MS = 3000;
export const COMPLIANCE_YEAR_SELECT_DEFAULT_MAX_YEARS = 25;

export const MAX_OPTION_MONTHS_INITIAL = 24;

export const NIBRS_COMPLIANCE_TYPE = {
    codeTypeSource: undefined,
    complianceGroup: undefined,
    display: 'NIBRS', // this string may be dynamically updated to reflect the department profile's regional group
    exportIntervalType: ExportIntervalTypeEnum.MONTHLY.name,
    name: 'NIBRS',
    supportsErrors: true,
    value: 0,
} as const;

export const exportNibrsOverwriteModalContext = {
    name: boxEnum.NIBRS_EXPORT_OVERWRITE_CONFIRMATION_MODAL,
};

export const exportNibrsDownloadModalContext = {
    name: boxEnum.NIBRS_EXPORT_DOWNLOAD_CONFIRMATION_MODAL,
};

export const exportNibrsNotMostRecentMonthModalContext = {
    name: boxEnum.NIBRS_EXPORT_NOT_MOST_RECENT_MONTH_MODAL,
};

export const exportNibrsDeleteHistoryModalContext = {
    name: boxEnum.NIBRS_EXPORT_DELETE_HISTORY_MODAL,
};

export const exportResubmissionDownloadModalContext = {
    name: boxEnum.NIBRS_RESUBMISSION_DOWNLOAD_CONFIRMATION_MODAL,
};

import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Roles Resource',
    methods: {
        /**
         * Get user/group roles.
         * @param {number[]} ids
         * @return {Promise<Object[]>}
         */
        getRoles(ids) {
            return req({
                method: 'GET',
                url: 'admin/role',
                params: { id: ids },
            });
        },
        getUserRoles(userId) {
            return req({
                method: 'GET',
                url: `admin/user/${userId}/roles`,
            });
        },
        updateRole(userId, roleId, isUserAdmin) {
            return req({
                method: 'PUT',
                url: `admin/role/${roleId}/user`,
                data: { roleId, userId, isUserAdmin },
            });
        },
        deleteRole(userId, roleId) {
            return req({
                method: 'DELETE',
                url: `admin/role/${roleId}/user/${userId}`,
            });
        },
        deleteRoleByRoleId(id) {
            return req({
                method: 'DELETE',
                url: `admin/role/${id}`,
            });
        },
        updateRoleName(updatedRole) {
            return req({
                method: 'PUT',
                url: `admin/role/${updatedRole.id}`,
                data: {
                    ...updatedRole,
                },
            });
        },
        addUserRoles(userRoles) {
            return req({
                method: 'PUT',
                url: 'admin/role/users',
                data: userRoles,
            });
        },
        addUserRolesAdmin(userRoles, roleId) {
            return req({
                method: 'PUT',
                url: `admin/role/${roleId}/user_role/`,
                data: userRoles,
            });
        },
    },
});

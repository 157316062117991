import React, { useCallback, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { map, chain, find, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Attachment, EntityTypeEnum } from '@mark43/rms-api';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { prettify } from '~/client-common/helpers/stringHelpers';
import { removeAttachments } from '~/client-common/core/domain/attachments/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { openConfirmationBar } from '../../../core/confirmation-bar/state/ui';
import Modal from '../../../core/overlays/components/Modal';
import { removeCaseNotes } from '../../case-notes/state/data';
import {
    deleteFolders,
    permanentlyDeleteAttachmentsFromFolder,
    permanentlyDeleteCaseNotesFromFolder,
} from '../state/data/folders';
import {
    currentCaseSelector,
    currentCaseAttachmentsByFolderIdSelector,
    currentCaseNotesByFolderIdSelector,
} from '../state/ui';
import {
    CurrentFolderIdContext,
    SelectedContentItem,
    SelectedRowsContext,
    SearchFilterContext,
    KebabMenuContext,
} from '../contexts';
import { updateOrphanContents } from '../state/data/orphanedContents';
import { KebabSources } from './KebabMenu';
import { EntityTypeIdContext } from './CreateFolderModal';

const overlayId = overlayIdEnum.DELETE_CASE_ATTACHMENTS_FOLDER_MODAL;
const strings = componentStrings.cases.core.DeleteCaseAttachmentsModal;

const DeleteCaseFoldersAttachmentsAndCaseNotesModal: React.FC<
    RouteComponentProps<{ folderId: number }, never>
> = ({ router, params }) => {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const overlayStore = useOverlayStore();
    const currentCase = useSelector(currentCaseSelector);
    const currentCaseAttachmentsByFolder = useSelector(currentCaseAttachmentsByFolderIdSelector);
    const { setSelectedRows } = useContext(SelectedRowsContext);
    const {
        currentFolderId,
        folderIdsToDelete,
        selectedContentItemsToDelete,
        parentFolderIdOfCurrentFolder,
    } = useContext(CurrentFolderIdContext);
    const { filter, removeItemsFromSearchResults } = useContext(SearchFilterContext);
    const currentCaseNotesByFolderId = useSelector(currentCaseNotesByFolderIdSelector);

    const { folderId: focusedFolderId } = params;
    const currentCaseAttachments = currentCaseAttachmentsByFolder(parseInt(focusedFolderId));
    const currentCaseNotes = currentCaseNotesByFolderId(parseInt(focusedFolderId));

    const dispatch = useDispatch();
    const currentCaseId = currentCase?.id;

    const { source } = useContext(KebabMenuContext);
    const { entityTypeId: entityType } = useContext(EntityTypeIdContext);

    const handleCancel = useCallback(() => {
        return overlayStore.close(overlayId);
    }, [overlayStore]);

    const handleSave = useCallback(async () => {
        if (setSelectedRows) {
            setSelectedRows([]);
        }

        const hasSelectedFolders = folderIdsToDelete && folderIdsToDelete.length > 0;

        const selectedCaseAttachments = selectedContentItemsToDelete
            ? chain(
                  selectedContentItemsToDelete.filter(
                      (item: SelectedContentItem) =>
                          item.rowEntityType === EntityTypeEnum.ATTACHMENT.name
                  )
              )
                  .map((rowItem) => {
                      // Casting to Attachment for now because some of the
                      // selectors that currentCaseAttachmentsByFolder composes
                      // are not typed.
                      return find(currentCaseAttachments, {
                          attachmentId: rowItem.id,
                      }) as Attachment;
                  })
                  .compact()
                  .value()
            : [];

        const selectedCaseNotes = selectedContentItemsToDelete
            ? chain(selectedContentItemsToDelete)
                  .filter((item) => item.rowEntityType === EntityTypeEnum.CASE_NOTE.name)
                  .map((rowItem) => {
                      return find(currentCaseNotes, { id: rowItem.id });
                  })
                  .compact()
                  .value()
            : [];

        const nonCaseAttachments = selectedCaseAttachments.filter(
            (item) => item.entityType !== EntityTypeEnum.CASE.name
        );
        const hasSelectedNonCaseAttachments = nonCaseAttachments.length > 0;

        // User has selected a non case attachment to delete. Abort
        if (hasSelectedNonCaseAttachments) {
            const nonEntityTypesSelected = map(map(nonCaseAttachments, 'entityType'), (item) =>
                prettify(item)
            );

            await dispatch(
                openConfirmationBar({
                    message: strings.nonCaseAttachmentsWarningMessage(
                        nonCaseAttachments.length,
                        nonEntityTypesSelected.join(', ')
                    ),
                })
            );
            return;
        }

        // Deleting Folders
        if (hasSelectedFolders && entityType) {
            await dispatch(deleteFolders(folderIdsToDelete, currentCaseId, entityType));
        }

        // User is trying to delete attachments
        if (!isEmpty(selectedCaseAttachments) && entityType === EntityTypeEnum.ATTACHMENT.name) {
            if (currentFolderId) {
                // Delete case attachments permanently from a case folder content view.
                await dispatch(
                    permanentlyDeleteAttachmentsFromFolder(
                        currentFolderId,
                        selectedCaseAttachments,
                        currentCaseId
                    )
                );
            } else {
                // Delete attachments from the top level attachments tab.
                await dispatch(
                    removeAttachments(
                        selectedCaseAttachments,
                        currentCaseId,
                        EntityTypeEnum.CASE.name
                    )
                );
                // TODO: CHI-1221
                await dispatch(updateOrphanContents());
            }
        }

        if (!isEmpty(selectedCaseNotes) && entityType === EntityTypeEnum.CASE_NOTE.name) {
            const noteIds = map(selectedCaseNotes, 'id');
            if (currentFolderId) {
                // Delete case notes permanently from a case note folder content view
                await dispatch(permanentlyDeleteCaseNotesFromFolder(currentFolderId, noteIds));
            } else {
                // Delete case notes from the top level case notes tab
                await dispatch(removeCaseNotes(noteIds));

                // TODO: CHI-1221
                await dispatch(updateOrphanContents());
            }
        }

        // Remove the attachments/folders from the search results
        if (filter && removeItemsFromSearchResults) {
            removeItemsFromSearchResults({
                folderIds: folderIdsToDelete,
                attachmentIds: map(selectedCaseAttachments, 'attachmentId'),
            });

            // Always go to top level when you delete something from search page.
            // Case Notes do not have search functionality, adding this check just to
            // be safe
            if (entityType === EntityTypeEnum.ATTACHMENT.name) {
                router.push(`/cases/${currentCaseId}/attachments`);
            }
        } else if (
            hasSelectedFolders &&
            source === KebabSources.SIDEBAR &&
            focusedFolderId &&
            currentFolderId === parseInt(focusedFolderId)
        ) {
            if (parentFolderIdOfCurrentFolder) {
                if (entityType === EntityTypeEnum.ATTACHMENT.name) {
                    router.push(
                        `/cases/${currentCaseId}/attachments/folder/${parentFolderIdOfCurrentFolder}`
                    );
                } else if (entityType === EntityTypeEnum.CASE_NOTE.name) {
                    router.push(
                        `/cases/${currentCaseId}/notes/folder/${parentFolderIdOfCurrentFolder}`
                    );
                }
            } else {
                if (entityType === EntityTypeEnum.ATTACHMENT.name) {
                    router.push(`/cases/${currentCaseId}/attachments`);
                } else if (entityType === EntityTypeEnum.CASE_NOTE.name) {
                    router.push(`/cases/${currentCaseId}/notes`);
                }
            }
        }
    }, [
        setSelectedRows,
        folderIdsToDelete,
        selectedContentItemsToDelete,
        entityType,
        router,
        source,
        dispatch,
        currentCaseId,
        currentFolderId,
        currentCaseAttachments,
        currentCaseNotes,
        focusedFolderId,
        parentFolderIdOfCurrentFolder,
        removeItemsFromSearchResults,
        filter,
    ]);

    return (
        <Modal
            id={overlayId}
            title={strings.title}
            okText={strings.okText}
            cancelText={strings.cancelText}
            onSave={handleSave}
            onCancel={handleCancel}
        >
            <div>{strings.message(caseDisplayName)}</div>
        </Modal>
    );
};

export default withRouter(DeleteCaseFoldersAttachmentsAndCaseNotesModal);

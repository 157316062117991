import { LocationView, LocationEntityLink, ElasticInvolvedLocation } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    formatAddressForLocationBundle,
    locationNameFormatsForLocation,
    formatAddressLinesForLocationBundle,
} from '~/client-common/core/domain/locations/utils/locationHelpers';
import { buildElasticLocationLines } from '~/client-common/core/domain/elastic-involved-locations/state/ui';

type ClientLocationBundle = {
    location: LocationView;
    locationEntityLink?: LocationEntityLink;
    unknownLocationId?: number;
};

export const useSubPremiseFormatters = () => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isSubPremiseSupportEnabled = !!applicationSettings.SUBPREMISE_SUPPORT_ENABLED;

    return {
        formatAddressForLocationBundle: ({
            locationBundle,
        }: {
            locationBundle: ClientLocationBundle;
        }) =>
            formatAddressForLocationBundle({
                locationBundle,
                isSubPremiseSupportEnabled,
            }),
        formatAddressLinesForLocationBundle: ({
            locationBundle,
            showCountry,
            hidePlaceName,
        }: {
            locationBundle: ClientLocationBundle;
            showCountry?: boolean;
            hidePlaceName?: boolean;
        }) =>
            formatAddressLinesForLocationBundle({
                locationBundle,
                showCountry,
                hidePlaceName,
                isSubPremiseSupportEnabled,
            }),
        locationNameFormatsForLocation: ({
            location,
            hidePlaceName,
            unknownLocationId,
            showCountry,
        }: {
            location: LocationView;
            hidePlaceName?: boolean;
            unknownLocationId?: number;
            showCountry?: boolean;
        }) =>
            locationNameFormatsForLocation({
                location,
                hidePlaceName,
                isSubPremiseSupportEnabled,
                unknownLocationId,
                showCountry,
            }),
        buildElasticLocationLines: (
            elasticLocation: ElasticInvolvedLocation,
            config?: {
                showAliases?: boolean;
            }
        ) => buildElasticLocationLines(elasticLocation, { isSubPremiseSupportEnabled, ...config }),
    };
};

import { AttributeTypeEnum } from '@mark43/rms-api';

import { map } from 'lodash';
import getPointLocationsResource from '~/client-common/core/domain/point-locations/resources/pointLocationsResource';
import { convertLocationBundlesToLocationViews } from '~/client-common/core/domain/locations/utils/locationHelpers';
import {
    NEXUS_STATE_PROP as POINT_LOCATIONS_NEXUS_STATE_PROP,
    pointLocationsSelector,
} from '~/client-common/core/domain/point-locations/state/data';
import { NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/locations/state/data';
import { loadAttributesForType } from '../../../../core/attributes/state/ui/loadAttributesForType';

export const LOAD_POINT_LOCATIONS_START = 'point-locations/LOAD_POINT_LOCATIONS_START';
export const LOAD_POINT_LOCATIONS_SUCCESS = 'point-locations/LOAD_POINT_LOCATIONS_SUCCESS';
export const LOAD_POINT_LOCATIONS_FAILURE = 'point-locations/LOAD_POINT_LOCATIONS_FAILURE';

export const LOAD_POINT_LOCATION_START = 'point-locations/LOAD_POINT_LOCATION_START';
export const LOAD_POINT_LOCATION_SUCCESS = 'point-locations/LOAD_POINT_LOCATION_SUCCESS';
export const LOAD_POINT_LOCATION_FAILURE = 'point-locations/LOAD_POINT_LOCATION_FAILURE';

export const PURGE_POINT_LOCATIONS_START = 'point-locations/PURGE_POINT_LOCATIONS_START';
export const PURGE_POINT_LOCATIONS_FAILURE = 'point-locations/PURGE_POINT_LOCATIONS_FAILURE';
export const PURGE_POINT_LOCATIONS_SUCCESS = 'point-locations/PURGE_POINT_LOCATIONS_SUCCESS';

function loadPointLocationsStart() {
    return {
        type: LOAD_POINT_LOCATIONS_START,
    };
}

function loadPointLocationsSuccess(pointLocationViews) {
    return {
        type: LOAD_POINT_LOCATIONS_SUCCESS,
        payload: pointLocationViews,
    };
}

function loadPointLocationsFailure(err) {
    return {
        type: LOAD_POINT_LOCATIONS_FAILURE,
        error: true,
        payload: err,
    };
}

function loadPointLocationStart() {
    return {
        type: LOAD_POINT_LOCATION_START,
    };
}

function loadPointLocationSuccess(pointLocationView) {
    return {
        type: LOAD_POINT_LOCATION_SUCCESS,
        payload: pointLocationView,
    };
}

function loadPointLocationFailure(err) {
    return {
        type: LOAD_POINT_LOCATION_FAILURE,
        error: true,
        payload: err,
    };
}

function purgePointLocationsStart() {
    return {
        type: PURGE_POINT_LOCATIONS_START,
    };
}

function purgePointLocationsSuccess() {
    return {
        type: PURGE_POINT_LOCATIONS_SUCCESS,
    };
}

function purgePointLocationsFailure(err) {
    return {
        type: PURGE_POINT_LOCATIONS_FAILURE,
        error: true,
        payload: err,
    };
}

export function purgePointLocations() {
    return function (dispatch) {
        dispatch(purgePointLocationsStart());
        getPointLocationsResource()
            .purgePointLocations()
            .then(() => {
                return dispatch(purgePointLocationsSuccess());
            })
            .catch((err) => dispatch(purgePointLocationsFailure(err)));
    };
}

export function queryPointLocations({ query }) {
    return (dispatch, _, { nexus }) => {
        dispatch(loadPointLocationsStart());
        getPointLocationsResource()
            .searchPointLocations({ query })
            .then((pointLocationViews) => {
                return dispatch(
                    nexus.withEntityItems(
                        {
                            [POINT_LOCATIONS_NEXUS_STATE_PROP]: map(
                                pointLocationViews,
                                'pointLocation'
                            ),
                            [LOCATIONS_NEXUS_STATE_PROP]: convertLocationBundlesToLocationViews(
                                pointLocationViews
                            ),
                        },
                        loadPointLocationsSuccess(pointLocationViews)
                    )
                );
            })
            .catch((err) => dispatch(loadPointLocationsFailure(err.message)));
    };
}

export function loadSinglePointLocation({ pointLocationId }) {
    return (dispatch, getState, { nexus }) => {
        const pointLocation = pointLocationsSelector(getState())[pointLocationId];
        // do not fetch a location again
        // this covers the case where we visit the form via a search
        // result, which will already have fetched the required data
        if (pointLocation) {
            return;
        }

        dispatch(loadPointLocationStart());
        getPointLocationsResource()
            .getPointLocationById(pointLocationId)
            .then(({ location, pointLocation }) => {
                return dispatch(
                    nexus.withEntityItems(
                        {
                            [POINT_LOCATIONS_NEXUS_STATE_PROP]: [pointLocation],
                            [LOCATIONS_NEXUS_STATE_PROP]: convertLocationBundlesToLocationViews([
                                { location },
                            ]),
                        },
                        loadPointLocationSuccess({ location, pointLocation })
                    )
                );
            })
            .catch((err) => dispatch(loadPointLocationFailure(err.message)));
    };
}

export function loadPointLocationsAdmin() {
    return (dispatch) =>
        dispatch(
            loadAttributesForType({
                attributeType: [
                    AttributeTypeEnum.SUBDIVISION_DEPTH_1.name,
                    AttributeTypeEnum.SUBDIVISION_DEPTH_2.name,
                    AttributeTypeEnum.SUBDIVISION_DEPTH_3.name,
                    AttributeTypeEnum.SUBDIVISION_DEPTH_4.name,
                    AttributeTypeEnum.SUBDIVISION_DEPTH_5.name,
                ],
            })
        );
}

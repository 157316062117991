import { MFTFormConfiguration, _Form } from 'markformythree';
import { DragonInlineFormReferenceState } from '../rms-types';
import { assertArray } from './assert-array';

export function getInlineFormReferenceFormState(
    form: _Form<MFTFormConfiguration>,
    path: string
): DragonInlineFormReferenceState[] {
    const currentFormValue = form.get(path) as DragonInlineFormReferenceState[];
    assertArray(
        currentFormValue,
        'Unexpectedly did not find array value for inline form reference form state'
    );

    return currentFormValue;
}

import React, { CSSProperties, FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TaskEntityLink, TaskView } from '@mark43/rms-api';
import { Menu, MenuItem, MenuTrigger, MenuContent } from 'arc';
import {
    taskStatusOptionsForDisplay,
    TaskStatusGroupEnum,
} from '~/client-common/helpers/taskStatusHelpers';
import { RmsDispatch } from '../../../../core/typings/redux';
import testIds from '../../../../core/testIds';
import { updateTask } from '../state/ui';
import { TaskStatusOption } from '../types';
import _TaskStatusLabel from './TaskStatusLabel';
import { useOpenTaskAddUpdateModal } from './TaskAddUpdateModal';

export interface TaskStatusPopOverMenuProps {
    task: TaskView;
    taskEntityLinks: TaskEntityLink[];
    options: TaskStatusOption[];
    onUpdate: () => void;
    onMenuOpen: (task: TaskView) => void;
    buttonDisabled: boolean;
    style?: CSSProperties;
}

const TaskStatusLabel = styled(_TaskStatusLabel)`
    background-color: none;
    flex: 1 1 auto;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-md);
    text-transform: none;
    letter-spacing: 0em;
    margin-left: -7px;
    color: ${(props) => props.theme.colors.text};
`;

const Container = styled.div`
    width: ${({ style }) => style?.width};
`;

const TaskStatusPopOverMenu: FC<TaskStatusPopOverMenuProps> = ({
    task,
    taskEntityLinks,
    options,
    onUpdate,
    onMenuOpen,
    buttonDisabled = false,
    style,
}) => {
    const dispatch = useDispatch<RmsDispatch>();
    const openAddUpdateModal = useOpenTaskAddUpdateModal();
    const statusOptions: TaskStatusOption[] = taskStatusOptionsForDisplay(
        options.filter((x) => x.value !== task.statusAttrId)
    );

    const onTaskStatusUpdate = (selectedOption: TaskStatusOption) => {
        if (selectedOption.group !== TaskStatusGroupEnum.COMPLETED) {
            task.statusAttrId = selectedOption.value;
            dispatch(updateTask(task, taskEntityLinks)).then(onUpdate);
        } else {
            openAddUpdateModal({
                task,
                taskEntityLinks,
                onSave: onUpdate,
            });
        }
    };

    const onOpenChange = () => {
        if (onMenuOpen) {
            onMenuOpen(task);
        }
    };

    return (
        <Container style={style}>
            <Menu onOpenChange={onOpenChange}>
                <MenuTrigger
                    isDisabled={buttonDisabled}
                    data-test-id={testIds.TASK_STATUS_UPDATE_MENU_BUTTON}
                >
                    <TaskStatusLabel statusAttrId={task.statusAttrId} />
                </MenuTrigger>
                <MenuContent data-test-id={testIds.TASK_STATUS_UPDATE_MENU} align="end">
                    {statusOptions.map((option) => {
                        return (
                            <MenuItem
                                key={option.value}
                                onClick={() => onTaskStatusUpdate(option)}
                                data-test-id={testIds.TASK_STATUS_UPDATE_OPTION}
                            >
                                {option.display}
                            </MenuItem>
                        );
                    })}
                </MenuContent>
            </Menu>
        </Container>
    );
};

export default TaskStatusPopOverMenu;

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { branch, renderNothing, getContext } from 'recompose';
import { identity } from 'lodash';
import classNames from 'classnames';
import { Icon } from 'arc';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { isValueDisplayable } from '~/client-common/helpers/logicHelpers';
import { Tooltip } from '../../../modules/core/components/tooltip';
import { summaryListContextShape } from './SummaryList';

const SummaryRow = getContext(summaryListContextShape)(function SummaryRow({
    labelWidth,
    contentWidth,
    label,
    children,
    className,
    labelClassName,
    contentClassName,
    render,
    fieldName,
    labelTooltip,
}) {
    const labelStyle = {
        width: labelWidth,
    };
    const contentStyle = {
        maxWidth: contentWidth,
        verticalAlign: 'bottom',
    };

    const finalLabel = label ? `${label}:` : '';
    const renderedLabel = label && labelTooltip
        ? (
            <>
                <span>{label}</span>
                <Tooltip content={labelTooltip} side="top">
                    <Icon color="brand.default" icon="Information" size="sm"/>
                </Tooltip>
                <>:</>
            </>
        )
        : finalLabel;
    return render ? (
        render({ label: finalLabel })
    ) : (
        <div className={classNames('summary-row', className)} data-test-field-name={fieldName}>
            <div className={classNames('summary-row-label', labelClassName)} style={labelStyle}>
                {renderedLabel}
            </div>
            <div
                className={classNames('summary-row-content', contentClassName)}
                style={contentStyle}
            >
                {children}
            </div>
        </div>
    );
});

const mapStateToProps = (state, ownProps) => ({
    label:
        ownProps.fieldName && !ownProps.label
            ? formatFieldByNameSelector(state)(ownProps.fieldName)
            : ownProps.label,
});

export default compose(
    connect(mapStateToProps),
    branch(
        // hide if hidden is true or there are no children
        ({ hidden, children, render }) => hidden || (!isValueDisplayable(children) && !render),
        renderNothing,
        identity
    )
)(SummaryRow);

import { NarrativeMentionConfiguration } from '@mark43/rms-api';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import createNormalizedModule from '~/client-common/core/utils/createNormalizedModule';
import mentionsConfigurationAdminResource from '../../resources/mentionsConfigurationAdminResource';
import { RmsAction } from '../../../../../core/typings/redux';

const NEXUS_STATE_PROP = 'mentionsConfigurations';
const SAVE_MENTIONS_CONFIGURATIONS_SUCCESS = 'SAVE_MENTIONS_CONFIGURATIONS_SUCCESS';
const UPDATE_MENTION_CONFIGURATION_SUCCESS = 'UPDATE_MENTION_CONFIGURATION_SUCCESS';

const mentionsConfigurationModule = createNormalizedModule<NarrativeMentionConfiguration>({
    type: NEXUS_STATE_PROP,
    key: 'mentionCategory',
});

function saveMentionsConfigurationsSuccess() {
    return { type: SAVE_MENTIONS_CONFIGURATIONS_SUCCESS };
}

function updateMentionConfigurationSuccess() {
    return { type: UPDATE_MENTION_CONFIGURATION_SUCCESS };
}

function saveMentionsConfigurations(
    mentionsConfigurations: NarrativeMentionConfiguration[]
): RmsAction<void> {
    return function (dispatch, getState, { nexus }) {
        dispatch(
            nexus.withEntityItems(
                {
                    [NEXUS_STATE_PROP]: mentionsConfigurations,
                },
                saveMentionsConfigurationsSuccess()
            )
        );
    };
}

export const useFetchMentionsConfigurations = () => {
    const dispatch = useDispatch();
    const handleGetMentionsConfigurationSettingsSuccess = useCallback(
        (mentionsConfigurations: NarrativeMentionConfiguration[]) => {
            dispatch(saveMentionsConfigurations(mentionsConfigurations));
        },
        [dispatch]
    );

    return useResourceDeferred(
        mentionsConfigurationAdminResource.getMentionsConfigurationSettings,
        handleGetMentionsConfigurationSettingsSuccess
    );
};

export const useFetchMentionsConfigurationsOnNarrativeCard = (summaryMode: boolean) => {
    const mentionsConfigurations = useSelector(mentionsConfigurationsSelector);
    const { RMS_MENTIONS_IN_NARRATIVE_ENABLED } = useSelector(applicationSettingsSelector);
    const { callResource: fetchMentionsConfigurations } = useFetchMentionsConfigurations();

    useEffect(() => {
        if (isEmpty(mentionsConfigurations) && !summaryMode && RMS_MENTIONS_IN_NARRATIVE_ENABLED) {
            fetchMentionsConfigurations();
        }
    }, [
        fetchMentionsConfigurations,
        mentionsConfigurations,
        summaryMode,
        RMS_MENTIONS_IN_NARRATIVE_ENABLED,
    ]);
};

// ACTIONS
// Used to update a single mention entity
export function updateMentionConfiguration(
    mentionConfiguration: NarrativeMentionConfiguration
): RmsAction<void> {
    const { mentionCategory } = mentionConfiguration;
    return function (dispatch, getState, { nexus: { withEntityItems, withRemove } }) {
        dispatch(
            withEntityItems(
                {
                    [NEXUS_STATE_PROP]: [mentionConfiguration],
                },
                withRemove(
                    NEXUS_STATE_PROP,
                    { mentionCategory },
                    updateMentionConfigurationSuccess()
                )
            )
        );
    };
}

// SELECTORS
export const mentionsConfigurationsSelector =
    mentionsConfigurationModule.selectors.entitiesSelector;

export const mentionConfigurationByTypeSelector =
    mentionsConfigurationModule.selectors.entityByIdSelector;

export default mentionsConfigurationModule.reducerConfig;

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { LinkTypesEnum, Attachment, BookingView, RmsHydratedPerson } from '@mark43/rms-api';
import { Card, CardBody, CardHeader, cssVar, Stack, Button } from 'arc';
import getPersonProfileResource from '~/client-common/core/domain/person-profiles/resources/personProfileResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { bookingsResource } from '../../search/core/resources';
import Page from '../../core/components/Page';
import Subheader from '../../core/components/Subheader';
import Modal from '../../core/overlays/components/Modal';
import { SimpleLoading } from '../../../legacy-redux/components/core/Loading';
import { logError } from '../../../core/logging';
import { OverlayBase, overlayStore } from '../../../core/overlayManager';
import { PersonalIdentification } from './PersonalIdentification';
import { BookingDetails } from './BookingDetails';

const strings = componentStrings.bookings;
export const BookingDetailsPage: React.FC<RouteComponentProps<{ id: string }, { id: string }>> = ({
    params,
}) => {
    const [bookingDetails, setBookingDetails] = useState<BookingView>();
    const [mugshot, setMugshot] = useState<Attachment | undefined>();
    const { bookingNumber: title, editInJms: editText, retrievingDetails } = strings;
    useEffect(() => {
        overlayStore.open(overlayIdEnum.BOOKING_DETAILS_MODAL);
        const personProfileResource = getPersonProfileResource();
        bookingsResource
            .getBookingDetails(params.id)
            .then((res) => {
                setBookingDetails(res);
                personProfileResource
                    .getPersonProfile(res.rmsPersonId)
                    .then((data: RmsHydratedPerson) => {
                        const mugshotAttachment = data.attachments.find((item) => {
                            return item.linkType === LinkTypesEnum.MUGSHOT;
                        });
                        setMugshot(mugshotAttachment);
                    })
                    .catch(() => {
                        setMugshot(undefined);
                    });
            })
            .catch(logError)
            .finally(() => {
                overlayStore.close(overlayIdEnum.BOOKING_DETAILS_MODAL);
            });
    }, [params.id]);

    const editInJms = () => {
        window.open(
            `/jms/detainees/${bookingDetails?.detaineeId}/${bookingDetails?.id}/booking-intake`,
            '_blank'
        );
    };
    return (
        <Page
            style={{
                display: 'flex',
                alignItems: 'normal',
                gap: '10px',
                paddingBottom: cssVar('arc.space.1'),
            }}
        >
            <OverlayBase id={overlayIdEnum.BOOKING_DETAILS_MODAL}>
                {({ overlayState }) => {
                    return (
                        <>
                            {!overlayState.isOpen && (
                                <Subheader title={`${title} #${bookingDetails?.bookingNumber}`} />
                            )}
                            <Stack
                                style={{
                                    maxWidth: '800px',
                                    overflowX: 'auto',
                                    marginLeft: cssVar('arc.space.3'),
                                }}
                            >
                                {bookingDetails?.cards.map((card) => {
                                    return (
                                        <Card key={card.key}>
                                            <CardHeader
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                                title={card.name}
                                            >
                                                <Button
                                                    leadingVisual={'OpenNewWindow'}
                                                    variant={'ghost'}
                                                    onClick={editInJms}
                                                >
                                                    {editText}
                                                </Button>
                                            </CardHeader>
                                            <CardBody>
                                                {card.key !== 'PERSONAL_IDENTIFICATION_CARD' ? (
                                                    <BookingDetails fields={card.fields} />
                                                ) : (
                                                    <PersonalIdentification
                                                        mugshot={mugshot}
                                                        fields={card.fields}
                                                    />
                                                )}
                                            </CardBody>
                                        </Card>
                                    );
                                })}
                            </Stack>
                            <Modal hideFooter={true} id={overlayIdEnum.BOOKING_DETAILS_MODAL}>
                                <div className="mark43-loading-modal-content">
                                    <SimpleLoading />
                                    <div>{retrievingDetails}</div>
                                </div>
                            </Modal>
                        </>
                    );
                }}
            </OverlayBase>
        </Page>
    );
};

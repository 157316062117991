import { useSelector } from 'react-redux';
import { BriefingSimpleView } from '@mark43/rms-api';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { Briefing } from '~/client-common/core/domain/briefings/state/data';
import { canEdit } from '~/client-common/core/domain/entity-permissions/state/ui';

import { useAbilitySelector } from '../../core/current-user/hooks/useAbilitySelector';
import { currentUserIdSelector } from '../../core/current-user/state/ui';

const useBriefingPermissions = ({ createdBy, permissionSet }: Briefing | BriefingSimpleView) => {
    const currentUser = useSelector(currentUserIdSelector);

    const hasEditAbility = useAbilitySelector(abilitiesEnum.BRIEFING.EDIT_BRIEFING);
    const hasMasterEditAbility = useAbilitySelector(abilitiesEnum.BRIEFING.MASTER_EDIT_BRIEFING);
    const hasMasterManageAbility = useAbilitySelector(
        abilitiesEnum.BRIEFING.MASTER_MANAGE_BRIEFING
    );

    const hasEditPermission = canEdit(permissionSet);

    const isBriefingAuthor = createdBy === currentUser;

    return {
        canCreate: hasEditAbility,
        canEdit:
            hasMasterManageAbility || hasMasterEditAbility || (hasEditAbility && hasEditPermission),
        canDelete:
            hasMasterManageAbility ||
            (hasMasterEditAbility && isBriefingAuthor) ||
            (hasEditAbility && hasEditPermission),
    };
};

export default useBriefingPermissions;

import { getFocusableElements } from '~/client-common/core/keyboardFocus/helpers';

import { findClosestParentWithStyle } from '../../core/domHelpers';
import { scrollToElement } from './navigationHelpers';

const ADDITIONAL_OFFSET_FOR_FIELD = 10;

type Options = {
    /**
     * The HTMLElement that was clicked to initiate a scroll.
     */
    clickedElement: HTMLElement;
    /**
     * When scrolling to the field, we still want a little bit of space between the field and the error header. This
     * number indicates how much spacing to add (in px). The default value is `ADDITIONAL_OFFSET_FOR_FIELD`.
     */
    offset?: number;
} & (
    | {
          /**
           * The full CSS selector of the element we want to scroll to.
           *  Provide either `selector` or `fieldName`.
           */
          selector: string;
      }
    | {
          /**
           * The `field_details.field_name` of the element we want to scroll to.
           *  Provide either `selector` or `fieldName`.
           */
          fieldName: string;
          /**
           * For NItems, sometimes we may want to skip to the Nth field on the card.
           * `index` is used only when `fieldName` is provided.
           */
          index?: number;
      }
);

function getElementToFocus(options: Options): HTMLElement | undefined {
    // Since `fieldName` can be repeated across cards, we need
    // to scope our `fieldName` to the current card that the error corresponds to
    const parentCard = options.clickedElement.closest('.mark43-react-card');

    if (!parentCard) {
        return;
    }

    // Look for an element with the given selector, if provided
    if ('selector' in options) {
        const element = parentCard.querySelectorAll<HTMLElement>(options.selector).item(0);
        if (element) {
            return element;
        }
    }

    // Alternatively, from the card, find the field that we want to scroll to
    if ('fieldName' in options) {
        const index = 'index' in options && typeof options.index === 'number' ? options.index : 0;
        const field = parentCard
            .querySelectorAll<HTMLElement>(`[data-test-field-name="${options.fieldName}"]`)
            .item(index);
        if (field) {
            return field;
        }

        // Alternatively, if we were not able to find a field element, let's see if there are any 'sections' that
        // correspond to the field
        return parentCard
            .querySelectorAll<HTMLElement>(`[data-test-field-name-section="${options.fieldName}"]`)
            .item(0);
    }

    return;
}

/**
 * Find the card that `clickedElement` belongs to and scroll to the element corresponding to the `selector` or
 * `fieldName`.
 */
export function scrollToFieldOrSectionWithinCard(options: Options): void {
    const { clickedElement, offset = ADDITIONAL_OFFSET_FOR_FIELD } = options;

    // Since we know that the errors headers are fixed, we need to grab this
    // so that we can eventually calculate its height and offset the scroll
    // by this much
    const errorHeader = clickedElement.closest('[data-test-id="CARD_HEADER"]');

    // Find the scrollable container that we are nested within
    const scrollableContainer = findClosestParentWithStyle(clickedElement, 'overflowY', 'auto');

    const parentOfElementToFocus = getElementToFocus(options);

    /**
     * Once we have either a field or a section, look
     *  for an underlying element that we will set focus to
     */
    const elementToFocus: HTMLElement | undefined =
        parentOfElementToFocus && getFocusableElements(parentOfElementToFocus)[0];

    if (scrollableContainer && parentOfElementToFocus && errorHeader && elementToFocus) {
        const scrollableContainerBoundingClientRect = scrollableContainer.getBoundingClientRect();
        const errorHeaderBoundingClientRect = errorHeader.getBoundingClientRect();

        scrollToElement({
            selector: parentOfElementToFocus,
            wrapperSelector: scrollableContainer,
            offset:
                // The offset of the scrollable container
                scrollableContainerBoundingClientRect.top +
                // The height of the fixed error header
                errorHeaderBoundingClientRect.height +
                // Extra spacing to add some distance between
                // the fixed error header and the field
                offset,
            onScroll: () => {
                elementToFocus.focus();
            },
        });
    }
}

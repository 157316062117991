import { LinkTypesEnum, LinkTypesEnumType, RefContextEnum } from '@mark43/rms-api';

/**
 * Returns the matching form context for a given link type
 */
export function getFormContextForLinkType(linkType: LinkTypesEnumType) {
    switch (linkType) {
        case LinkTypesEnum.REPORTING_PARTY_IN_REPORT:
            return RefContextEnum.FORM_PERSON_REPORTING_PARTY.name;

        case LinkTypesEnum.DEFENDANT_IN_ARREST:
            return RefContextEnum.FORM_PERSON_DEFENDANT.name;

        case LinkTypesEnum.CO_DEFENDANT:
            return RefContextEnum.FORM_PERSON_CO_DEFENDANT.name;

        case LinkTypesEnum.INVOLVED_PERSON_IN_REPORT:
        case LinkTypesEnum.OTHER_NAME_IN_REPORT:
        case LinkTypesEnum.OTHER_NAME_IN_OFFENSE:
            return RefContextEnum.FORM_PERSON_OTHER.name;

        case LinkTypesEnum.OTHER_NAME_IN_BEHAVIORAL_CRISIS:
        case LinkTypesEnum.OTHER_NAME_IN_STOP:
        case LinkTypesEnum.OTHER_NAME_IN_FIELD_CONTACT:
            return RefContextEnum.FORM_PERSON_NAME.name;

        case LinkTypesEnum.MISSING_PERSON_IN_REPORT:
            return RefContextEnum.FORM_PERSON_MISSING_PERSON.name;

        case LinkTypesEnum.LAST_KNOWN_CONTACT_IN_REPORT:
            return RefContextEnum.FORM_PERSON_LAST_KNOWN_CONTACT.name;

        case LinkTypesEnum.SUBJECT_IN_BEHAVIORAL_CRISIS:
        case LinkTypesEnum.SUBJECT_IN_COMMUNITY_INFORMATION:
        case LinkTypesEnum.SUBJECT_IN_FIELD_CONTACT:
        case LinkTypesEnum.SUBJECT_IN_OFFENSE:
        case LinkTypesEnum.SUBJECT_IN_TRAFFIC_CRASH:
        case LinkTypesEnum.SUBJECT_IN_USE_OF_FORCE:
            return RefContextEnum.FORM_PERSON_SUBJECT.name;

        case LinkTypesEnum.SUBJECT_IN_STOP:
            return RefContextEnum.FORM_PERSON_STOP_SUBJECT.name;

        case LinkTypesEnum.WITNESS_IN_OFFENSE:
        case LinkTypesEnum.WITNESS_IN_REPORT:
            return RefContextEnum.FORM_PERSON_WITNESS.name;

        case LinkTypesEnum.VICTIM_IN_OFFENSE:
        case LinkTypesEnum.VICTIM_IN_REPORT:
            return RefContextEnum.FORM_PERSON_VICTIM.name;

        case LinkTypesEnum.SUSPECT_IN_OFFENSE:
        case LinkTypesEnum.SUSPECT_IN_REPORT:
            return RefContextEnum.FORM_PERSON_SUSPECT.name;

        case LinkTypesEnum.OFFENDER_IN_OFFENSE:
            return RefContextEnum.FORM_PERSON_OFFENDER.name;

        case LinkTypesEnum.COMPLAINANT_IN_REPORT:
            return RefContextEnum.FORM_PERSON_COMPLAINANT.name;

        case LinkTypesEnum.SUBJECT_IN_CITATION:
            return RefContextEnum.FORM_PERSON_CITATION_RECIPIENT.name;

        case LinkTypesEnum.VULNERABLE_PERSON_IN_REPORT:
            return RefContextEnum.FORM_VULNERABLE_PERSON.name;

        // TODO is this a valid default return value?
        default:
            return RefContextEnum.FORM_PERSON_OTHER.name;
    }
}

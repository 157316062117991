import { ShortcutKey } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const shortcutKeysModule = createNormalizedModule<ShortcutKey>({
    type: 'shortcutKeys',
});

// ACTIONS
export const storeShortcutKeys = shortcutKeysModule.actionCreators.storeEntities;

export const deleteShortcutKey = shortcutKeysModule.actionCreators.deleteEntity;

// SELECTORS
export const shortcutKeysSelector = shortcutKeysModule.selectors.entitiesSelector;
export const shortcutKeysByIdSelector = shortcutKeysModule.selectors.entityByIdSelector;

export default shortcutKeysModule.reducerConfig;

import { createSelector } from 'reselect';
import { some } from 'lodash';
import {
    RuleComparisonOperatorEnum,
    RefContextEnumType,
    RuleFieldGetterEnum,
    RuleTypeEnum,
} from '@mark43/rms-api';

import { ruleActionedFieldsWhereSelector } from '~/client-common/core/domain/rule-actioned-fields/state/data';
import { ruleConditionsWhereSelector } from '~/client-common/core/domain/rule-conditions/state/data';
import { ruleConfigurationContextsWhereSelector } from '~/client-common/core/domain/rule-configuration-contexts/state/data';
import { rulesSelector } from '~/client-common/core/domain/rules/state/data';

export const isFieldStaticRequiredSelector = createSelector(
    ruleActionedFieldsWhereSelector,
    ruleConditionsWhereSelector,
    ruleConfigurationContextsWhereSelector,
    rulesSelector,
    (ruleActionedFieldsWhere, ruleConditionsWhere, ruleConfigurationContextsWhere, rules) => (
        fieldConfigurationId: number,
        context: RefContextEnumType
    ) => {
        const ruleActionedFields = ruleActionedFieldsWhere({ fieldConfigurationId });

        if (ruleActionedFields.length === 0) {
            return false;
        }

        return some(ruleActionedFields, (ruleActionedField) => {
            const rule = rules[ruleActionedField.ruleId];

            if (rule.ruleType !== RuleTypeEnum.VALIDATION.name) {
                return false;
            }

            // expect maximum one rule configuration for context and rule id
            const [ruleConfiguration] = ruleConfigurationContextsWhere({
                context,
                ruleId: rule.id,
            });

            if (!ruleConfiguration || ruleConfiguration.isDisabled) {
                return false;
            }

            const ruleConditions = ruleConditionsWhere({ ruleId: rule.id });

            if (ruleConditions.length !== 1) {
                return false;
            }

            const ruleCondition = ruleConditions[0];

            const comparisonIsNotEmpty =
                ruleCondition.ruleComparisonOperator ===
                RuleComparisonOperatorEnum.IS_NOT_EMPTY.name;

            // linked entities like suspect and victims
            const comparisonIsGreaterThan =
                ruleCondition.ruleFieldGetter === RuleFieldGetterEnum.GET_COUNT.name &&
                ruleCondition.ruleComparisonOperator ===
                    RuleComparisonOperatorEnum.IS_GREATER_THAN.name &&
                ruleCondition.staticValue === '0';

            return (
                ruleCondition.fieldConfigurationId === fieldConfigurationId &&
                (comparisonIsNotEmpty || comparisonIsGreaterThan)
            );
        });
    }
);

import { get, isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    prefillForReportCardSelector,
    isCurrentReportNewSelector,
    currentReportCardUITitleByTypeSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';

import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import withCard from '../../utils/withCard';
import {
    formName,
    getSupplementInfoForm,
    createSupplementInfoForm,
    buildPrefilledSupplementInfoCardFormModel,
    buildSupplementInfoCardFormModelFromCadTicket,
    buildSupplementInfoCardFormModel,
} from '../../state/forms/supplementInfoForm';
import { registerForm } from '../../state/ui';
import Card from '../../../../../legacy-redux/components/core/Card';
import supplementInfoCard from '../../state/ui/supplementInfoCard';
import { registerCard } from '../../utils/cardsRegistry';
import testIds from '../../../../../core/testIds';
import SupplementInfoCardForm from './SupplementInfoCardForm';
import SupplementInfoCardSummary from './SupplementInfoCardSummary';

class SupplementInfoCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            prefillForReportCard,
            buildPrefilledSupplementInfoCardFormModel,
            buildSupplementInfoCardFormModelFromCadTicket,
            buildSupplementInfoCardFormModel,
            transitionToEditMode,
            currentReportId: reportId,
            isCurrentReportNew: isNewReport,
        } = this.props;

        const prefillForSupplementInfoCard =
            prefillForReportCard(reportCardEnum.SUPPLEMENT_INFO.name) || {};
        const {
            prefillModalSaveSelected,
            supplementCardPrefillBundle,
        } = prefillForSupplementInfoCard;
        const shouldPrefillSupplementInfoCard =
            prefillModalSaveSelected === true && !isUndefinedOrNull(supplementCardPrefillBundle);

        const shouldPrefillSupplementInfoFormFromCadTicket =
            isNewReport && isEmpty(prefillForSupplementInfoCard);

        let initialState;
        if (shouldPrefillSupplementInfoCard) {
            initialState = buildPrefilledSupplementInfoCardFormModel({
                supplementCardPrefillBundle,
            });
        } else if (shouldPrefillSupplementInfoFormFromCadTicket) {
            initialState = buildSupplementInfoCardFormModelFromCadTicket({ reportId });
        } else {
            initialState = buildSupplementInfoCardFormModel({ reportId });
        }

        const form = createSupplementInfoForm({
            initialState,
            arbiter,
            formatFieldByName,
        });

        registerForm({ form });
        registerCard({
            cardModule: supplementInfoCard,
            onSave: this.onSave,
        });

        // If we are prefilling the card, then initialize the card in edit mode.
        if (shouldPrefillSupplementInfoCard) {
            transitionToEditMode();
        }
    }

    componentWillUnmount() {
        formsRegistry.unregister(formName);
    }

    onEdit = () => {
        this.props.editCallback(() => this.props.onEdit());
    };

    onSaveProgress = () => {
        const form = getSupplementInfoForm();
        return this.props.onSaveProgress(form, {});
    };

    onSave = () => {
        const form = getSupplementInfoForm();
        return this.props.onSave(form);
    };

    render() {
        const { card = {}, currentReportId: reportId, currentReportCardUITitleByType } = this.props;
        const cardTitle = currentReportCardUITitleByType(reportCardEnum.SUPPLEMENT_INFO.id);
        const {
            anchor,
            errorMessages,
            summaryMode: cardSummaryMode,
            canEditReportCardStatus,
            saving,
        } = card;

        return (
            <Card
                className={anchor}
                anchor={anchor}
                title={cardTitle}
                testId={testIds.SUPPLEMENT_INFO_CARD}
                renderContent={(summaryMode) => {
                    if (summaryMode) {
                        return <SupplementInfoCardSummary reportId={reportId} />;
                    } else {
                        return <SupplementInfoCardForm reportId={reportId} />;
                    }
                }}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={cardSummaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                saving={saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class SupplementInfoCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => (
                    <SupplementInfoCard ref={this.setRef} {...this.props} arbiter={arbiter} />
                )}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    prefillForReportCard: prefillForReportCardSelector,
    isCurrentReportNew: isCurrentReportNewSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = {
    buildPrefilledSupplementInfoCardFormModel,
    buildSupplementInfoCardFormModelFromCadTicket,
    buildSupplementInfoCardFormModel,
    transitionToEditMode: supplementInfoCard.actionCreators.transitionToEditMode,
};

export default compose(
    withCard(supplementInfoCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(SupplementInfoCardWrapper);

/*
 * Values for the `rms.warrant_id_configuration_type`
 * application preference
 *
 */
export default {
    FREE_TEXT: 'free-text',
    SINGLE: 'single',
    MULTIPLE: 'multiple',
} as const;

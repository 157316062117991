import { FormEnum } from '@mark43/rms-api';
import { omit } from 'lodash';
import { CASE_REVIEW_BODY } from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

export default createFormModule({
    formName: FormEnum.CASE_REVIEW.name,
    fieldViewModels: buildFlatFormFieldViewModels([
        'userIdsToNotify',
        {
            key: 'body',
            fieldName: CASE_REVIEW_BODY,
        },
    ]),
    convertFromFormModel: (formData, otherData) => {
        return {
            caseReview: {
                ...omit(formData, 'userIdsToNotify'),
                ...otherData,
            },
            userIdsToNotify: formData.userIdsToNotify,
        };
    },
});

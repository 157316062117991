import { map } from 'lodash';
import { NameAttribute, AttributeTypeEnumType } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import getNameAttributesResource from '../../resources/nameAttributesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'nameAttributes';
const nameAttributesModule = createLinkModule<NameAttribute>({
    type: NEXUS_STATE_PROP,
    keys: ['nameId', 'attributeId', 'attributeType'],
});

// SELECTORS
export const nameAttributesSelector = nameAttributesModule.selectors.linksSelector;
export const nameAttributesWhereSelector = nameAttributesModule.selectors.linksWhereSelector;

// REDUCER
export default nameAttributesModule.reducerConfig;

const UPDATE_NAME_ATTRIBUTES_FOR_TYPE_START =
    'name-attributes/UPDATE_NAME_ATTRIBUTES_FOR_TYPE_START';
const UPDATE_NAME_ATTRIBUTES_FOR_TYPE_SUCCESS =
    'name-attributes/UPDATE_NAME_ATTRIBUTES_FOR_TYPE_SUCCESS';

function updateNameAttributesForTypeStart({
    attributes,
    attributeType,
    entityId,
}: Parameters<typeof updateNameAttributesForType>[0]) {
    return {
        type: UPDATE_NAME_ATTRIBUTES_FOR_TYPE_START,
        payload: { attributes, attributeType, entityId },
    };
}

function updateNameAttributesForTypeSuccess({
    attributes,
    attributeType,
    entityId,
}: Parameters<typeof updateNameAttributesForType>[0]) {
    return {
        type: UPDATE_NAME_ATTRIBUTES_FOR_TYPE_SUCCESS,
        payload: { attributes, attributeType, entityId },
    };
}

export function updateNameAttributesForType({
    attributes,
    attributeType,
    entityId,
}: {
    attributes: NameAttribute[];
    attributeType: AttributeTypeEnumType;
    entityId: number;
}): ClientCommonAction<Promise<NameAttribute[]>> {
    return (dispatch) => {
        dispatch(updateNameAttributesForTypeStart({ attributes, attributeType, entityId }));
        return getNameAttributesResource()
            .updateNameAttributesForType({
                attributes,
                attributeType,
                entityId,
            })
            .then((nameAttributes: NameAttribute[]) => {
                dispatch(
                    nameAttributesModule.withRemove(
                        NEXUS_STATE_PROP,
                        { nameId: entityId, attributeType },
                        nameAttributesModule.withEntityItems(
                            {
                                [NEXUS_STATE_PROP]: nameAttributes,
                            },
                            updateNameAttributesForTypeSuccess({
                                attributes,
                                attributeType,
                                entityId,
                            })
                        )
                    )
                );
                return nameAttributes;
            });
        // not catching the error here because downstream consumers should handle the error
        // on their own and we won't gain anything from dispatching a failure action here
    };
}

export const replaceNameAttributesForAttributeTypes = ({
    nameId,
    attributeTypesToReplaceFor = [],
    nameAttributesToReplaceWith,
}: {
    nameId: string;
    attributeTypesToReplaceFor: AttributeTypeEnumType[];
    nameAttributesToReplaceWith: NameAttribute[];
}): ClientCommonAction<void> => {
    return (dispatch) => {
        const removeConditions = map(attributeTypesToReplaceFor, (attributeTypeToReplaceFor) => ({
            nameId,
            attributeType: attributeTypeToReplaceFor,
        }));
        dispatch(
            nameAttributesModule.withRemove(
                NEXUS_STATE_PROP,
                removeConditions,
                nameAttributesModule.withEntityItems(
                    {
                        [NEXUS_STATE_PROP]: nameAttributesToReplaceWith,
                    },
                    {
                        type: 'REPLACE_NAME_ATTRIBUTES',
                        payload: {
                            nameId,
                            attributeTypesToReplaceFor,
                            nameAttributesToReplaceWith,
                        },
                    }
                )
            )
        );
    };
};

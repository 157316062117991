/**
 * Client-only enum of react-hotkeys actions. Handlers for these actions are defined
 * in the component in which they're implemented
 *
 * Note - under the hood, react-hotkeys accepts
 * multiple formats for key bindings -- it accepts
 * key names from react events, and it also supports
 * key names defined by the `mousetrap` library: https://craig.is/killing/mice
 *
 * However, in the RMS implementation, our keyBindings /must/
 * use the key names from react events.
 *
 * See here for a list of `mousetrap` bindings, and the equivalent
 * react key names:
 *
 * https://github.com/greena13/react-hotkeys/blob/866a121fe7aca87bcd40bbc8ec866b8bf5a6dc0e/src/const/MousetrapToReactKeyNamesDictionary.js
 *
 */
export default {
    FILL_CURRENT_DATE_TIME: {
        name: 'FILL_CURRENT_DATE_TIME',
        keyBinding: ['t', 'T'],
    },
    FILL_YESTERDAY_DATE: {
        name: 'FILL_YESTERDAY_DATE',
        keyBinding: ['y', 'Y'],
    },
    FILL_CAD_EVENT_DATES: {
        name: 'FILL_CAD_EVENT_DATES',
        keyBinding: ['e', 'E'],
    },
    CANCEL_AND_CLOSE: {
        name: 'CANCEL_AND_CLOSE',
        keyBinding: 'Escape',
    },
    SAVE_AND_CLOSE: {
        name: 'SAVE_AND_CLOSE',
        keyBinding: ['Control+s', 'Control+S'],
    },
    OPEN_LABELS_MODAL: {
        name: 'OPEN_LABELS_MODAL',
        keyBinding: ['Control+l', 'Control+L'],
    },
    OPEN_NIBRS_ADMIN_SIDE_PANEL: {
        name: 'OPEN_NIBRS_ADMIN_SIDE_PANEL',
        keyBinding: ['Control+i', 'Control+I'],
    },
    SCROLL_TO_NEXT_CARD: {
        name: 'SCROLL_TO_NEXT_CARD',
        keyBinding: 'Shift+ArrowDown',
    },
    SCROLL_TO_PREVIOUS_CARD: {
        name: 'SCROLL_TO_PREVIOUS_CARD',
        keyBinding: 'Shift+ArrowUp',
    },
    TRANSITION_ALL_CARDS_TO_EDIT_MODE: {
        name: 'TRANSITION_ALL_CARDS_TO_EDIT_MODE',
        keyBinding: ['Control+e', 'Control+E'],
    },
    SHOW_ALL_HOTKEYS: {
        name: 'SHOW_ALL_HOTKEYS',
        keyBinding: ['Control+/', 'Control+Shift+/', 'Control+?', 'Control+Shift+?'],
    },
    VALIDATE_REPORT: {
        name: 'VALIDATE_REPORT',
        keyBinding: ['Control+Shift+v', 'Control+Shift+V'],
    },
    PRINT_REPORT: {
        name: 'PRINT_REPORT',
        keyBinding: ['Control+p', 'Control+P'],
    },
    SEARCH_FORM: {
        name: 'SEARCH_FORM',
        keyBinding: ['Control+Enter', 'Control+Return'],
    },
};

import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import componentStrings from '~/client-common/core/strings/componentStrings';
import _Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import testIds from '../../../../../core/testIds';
import RecordsHeaderButton from './RecordsHeaderButton';

const strings = componentStrings.core.header.RecordsHeaderEditButton;

const Button = styled(_Button)`
    margin: 0;
    width: 38px;
    height: 30px;
    box-sizing: border-box;

    .mark43-icon::before {
        float: none;
    }
`;

function RecordsHeaderEditButton({ className, onClick }) {
    return (
        <RecordsHeaderButton overlay={strings.tooltip}>
            <Button
                testId={testIds.CASE_EDIT_BUTTON}
                className={classNames(buttonTypes.ICON, className)}
                iconLeft={<Icon size={18} color="cobaltBlue" type={iconTypes.EDIT} />}
                onClick={onClick}
            />
        </RecordsHeaderButton>
    );
}

export default RecordsHeaderEditButton;

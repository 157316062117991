import { RegionalGroupEnum, ExportIntervalTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { map } from 'lodash';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { omitProps } from '~/client-common/helpers/reactHelpers';

import routesConfig from '../../../routing/routesConfig';
import { routeNameSelector } from '../../../routing/routerModule';
import Link from '../../core/components/links/Link';
import {currentUserDepartmentProfileSelector} from "../../core/current-user/state/ui";

const strings = componentStrings.compliance.ComplianceSidebar;

const pathParamToRouteParam = {
    export: { name: routesConfig.COMPLIANCE_TYPE_EXPORT.name },
    resubmission: { name: routesConfig.COMPLIANCE_TYPE_RESUBMISSION.name },
};

const ComplianceSidebarLink = styled(omitProps(['active'])(Link))`
    display: flex;
    && {
        background-color: ${(props) => props.active && props.theme.colors.cobaltBlue};
        color: ${(props) =>
            props.active ? props.theme.colors.white : props.theme.colors.cobaltBlue};
    }
    min-width: 160px;
    font-size: var(--arc-fontSizes-lg);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    padding: 15px 16px;
    outline: none;

    &:hover {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.cobaltBlue};
    }
`;

type MenuItemType = keyof typeof strings.menu;

const ComplianceSidebar: React.FC<WithRouterProps> = (props) => {
    const { params } = props;

    const routeName = useSelector(routeNameSelector);
    const nibrsRegionalGroup = useSelector(currentUserDepartmentProfileSelector)?.nibrsRegionalGroup ?? RegionalGroupEnum.FEDERAL_NIBRS.name;
    const exportIntervalType = RegionalGroupEnum[nibrsRegionalGroup].nibrsExportIntervalType;
    const intervalString = exportIntervalType === ExportIntervalTypeEnum.MONTHLY.name ? strings.intervals.monthly : strings.intervals.weekly;

    return (
        <div>
            {map(pathParamToRouteParam, (param: { name: string }, pathParam: MenuItemType) => {
                const { name} = param;
                return (
                    <ComplianceSidebarLink
                        active={routeName === name}
                        key={pathParam}
                        to={`/compliance/${params.complianceExportType}/${pathParam}`}
                        data-test-id="COMPLIANCE_SIDEBAR_LINK"
                    >
                        {pathParam === 'export' && intervalString} {strings.menu[pathParam]}
                    </ComplianceSidebarLink>
                );
            })}
        </div>
    );
};

export default withRouter(ComplianceSidebar);

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';

import { lifecycleOptions } from 'markformythree';

import withFields from '~/client-common/core/fields/components/withFields';
import { formName } from '../../state/forms/missingPersonsForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import Row from '../../../../core/components/Row';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import IndentedFields from '../../../../core/components/IndentedFields';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';

export default withFields([])(function MissingPersonsCardForm() {
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            render={() => (
                <div>
                    <CardSection>
                        <Row>
                            <ArbiterMFTDatePicker path="lastContactDateUtc" includeTime={true} />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="missingPersonTypeAttrId"
                                length="md"
                                attributeType={AttributeTypeEnum.MISSING_PERSON_TYPE.name}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="missingPersonTypeOther" length="md" />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="missingPersonCriticalityAttrId"
                                length="md"
                                attributeType={AttributeTypeEnum.MISSING_PERSON_CRITICALITY.name}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="missingPersonCriticalityOther" length="md" />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="closureStatusAttrId"
                                length="md"
                                attributeType={AttributeTypeEnum.MISSING_PERSON_STATUS.name}
                            />
                            <ArbiterMFTDatePicker path="closureStatusDateUtc" includeTime={true} />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeCheckboxes
                                path="missingPersonAdditionalInfo"
                                attributeType={
                                    AttributeTypeEnum.MISSING_PERSON_ADDITIONAL_INFO.name
                                }
                                columns={2}
                                columnWidth="48%"
                                gutterWidth="4%"
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="missingPersonAdditionalInfoDescription" />
                            </IndentedFields>
                        </Row>
                    </CardSection>
                </div>
            )}
        />
    );
});

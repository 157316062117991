import {
    isArray,
    isPlainObject,
    flatMap,
    keys,
    values,
    findIndex,
    reduce,
    mergeWith,
    assignWith,
} from 'lodash';

const arrayMergeConcat = (a, b) => (isArray(a) ? a.concat(b) : undefined);

const arrayAssignConcat = (a, b) => {
    if (a) {
        return isArray(a) ? a.concat(b) : b;
    }

    return b;
};

const recursivelyGetValuesForPredicatesFromObject = (options) => {
    const resultKeys = keys(options);
    const predicates = values(options);
    const recurse = (obj) =>
        reduce(
            keys(obj),
            (acc, key) => {
                const resultKeyIndex = findIndex(predicates, (predicate) => predicate(key));
                if (resultKeyIndex > -1) {
                    const resultKey = resultKeys[resultKeyIndex];
                    acc[resultKey] = [
                        ...(acc[resultKey] || []),
                        ...(isArray(obj[key]) ? obj[key] : [obj[key]]),
                    ];
                    return acc;
                }

                if (isArray(obj[key])) {
                    return assignWith(
                        acc,
                        mergeWith(...flatMap(obj[key], recurse), arrayMergeConcat),
                        arrayAssignConcat
                    );
                }

                if (isPlainObject(obj[key])) {
                    return mergeWith(acc, recurse(obj[key]), arrayMergeConcat);
                }

                return acc;
            },
            {}
        );
    return recurse;
};

export default recursivelyGetValuesForPredicatesFromObject;

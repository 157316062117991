import { _Form, MFTFormConfiguration } from 'markformythree';
import * as React from 'react';

type MFTFormContextValue = _Form<MFTFormConfiguration>;

const MFTFormContext = React.createContext<MFTFormContextValue | undefined>(undefined);

export const MFTFormContextProvider: React.FC<{
    value: _Form<MFTFormConfiguration>;
}> = ({ value, children }) => {
    return <MFTFormContext.Provider value={value}>{children}</MFTFormContext.Provider>;
};

export function useMFTFormContext(): MFTFormContextValue {
    const value = React.useContext(MFTFormContext);
    if (!value) {
        throw new Error('Could not find MFT form in hierarchy');
    }

    return value;
}

import { SelectOption } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const countriesModule = createNormalizedModule<SelectOption>({
    type: 'countries',
    key: 'value',
});

// SELECTORS
export const countriesSelector = countriesModule.selectors.entitiesSelector;

// REDUCER
export default countriesModule.reducerConfig;

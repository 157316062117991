import React from 'react';
import classNames from 'classnames';
import stringsConfig from '~/client-common/core/strings';

const strings = stringsConfig.components.admin;

const AdminListStatus = (props) => {
    const correctString = props.status ? strings.adminListStatuses[props.status] : '';
    const classes = classNames('admin-date-status', props.status, {
        'date-time': props.dateTime,
    });
    return <div className={classes}>{correctString}</div>;
};

export default AdminListStatus;

import { times } from 'lodash';
import React from 'react';
import { Box, Divider, Flex, HStack, Skeleton, VStack } from 'arc';

export const SkeletonList = ({ numberOfRows = 10 }) => {
    return (
        <>
            {times(numberOfRows).map((i) => (
                <Box key={i} marginBottom="4">
                    <Skeleton />
                </Box>
            ))}
        </>
    );
};

export const SkeletonTable = ({ numberOfRows = 10 }) => {
    const arrayOfSkeletons: JSX.Element[] = [];

    for (let i = 0; i < numberOfRows && i < 20; i++) {
        arrayOfSkeletons.push(
            <div key={i}>
                <HStack justify="space-between">
                    <Flex align="center" height="3.5rem" width="40%">
                        <Skeleton height="23%" width={`${Math.random() * 70 + 30}%`} />
                    </Flex>
                    <Flex align="center" height="3.5rem" width="40%">
                        <Skeleton height="23%" width={`${Math.random() * 45 + 30}%`} />
                    </Flex>
                    <Flex align="center" height="3.5rem" width="9%">
                        <Skeleton height="23%" width="100%" />
                    </Flex>
                    <Flex align="center" height="3.5rem" width="4%">
                        <Skeleton height="23%" width="100%" />
                    </Flex>
                </HStack>
                <Divider />
            </div>
        );
    }
    return <VStack align="left">{arrayOfSkeletons}</VStack>;
};

import React, { useRef } from 'react';
import {
    createField,
    createFormConfiguration,
    _Form,
    Form,
    lifecycleOptions,
} from 'markformythree';
import { connect } from 'react-redux';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../../legacy-redux/components/core/Modal';
import { actuallySubmitReport } from '../../state/ui/submissions';
import { MFTTextArea } from '../../../../core/forms/components/TextArea';
import { closeBox } from '../../../../../legacy-redux/actions/boxActions';
import Row from '../../../../core/components/Row';
import testIds from '../../../../../core/testIds';
import { RmsDispatch } from '../../../../../core/typings/redux';

const strings = componentStrings.reports.core.SubmissionCommentsModal;

const context = {
    name: boxEnum.SUBMISSION_COMMENTS_MODAL,
};

const formConfiguration = createFormConfiguration({
    comments: createField<string>({}),
});

type SubmissionCommentsForm = _Form<typeof formConfiguration>;

function SubmissionCommentsModal({
    actuallySubmitReport,
    closeModal,
}: {
    actuallySubmitReport: (submissionComments?: string) => Promise<void>;
    closeModal: () => void;
}) {
    const formRef: React.MutableRefObject<SubmissionCommentsForm | null> = useRef(null);
    return (
        <Modal
            title={strings.title}
            context={context}
            onSave={() => {
                actuallySubmitReport(formRef.current?.get()?.comments);
                closeModal();
            }}
        >
            <Form
                configuration={formConfiguration}
                lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                name="SUBMISSION_COMMENTS"
                // @ts-expect-error TODO: Add `getForm` to js/packages/markformythree/index.d.ts
                getForm={(form: SubmissionCommentsForm) => {
                    formRef.current = form;
                }}
                render={() => (
                    <Row testId={testIds.EXPLAIN_REPORT_CHANGES}>
                        <MFTTextArea label={strings.label} path="comments" width={'100%'} />
                    </Row>
                )}
            />
        </Modal>
    );
}

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    actuallySubmitReport: (submissionComments?: string) =>
        dispatch(actuallySubmitReport(submissionComments)),
    closeModal: () => dispatch(closeBox(context)),
});

export default connect(undefined, mapDispatchToProps)(SubmissionCommentsModal);

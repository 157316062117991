import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SavedSearchView } from '@mark43/rms-api';

import { savedSearchErrorMessages } from '~/client-common/configs/advancedSearchConfig';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { SearchModuleType } from '../../../../core/typings/search-module';
import elasticSearchResource from '../../../../legacy-redux/resources/elasticSearchResource';
import Modal, { OverrideModalPropsFunction } from '../../../core/overlays/components/Modal';
import { convertSavedSearchQueryShapesToLegacyQueryShape } from '../../core/utils/createSearchModule';

const strings = componentStrings.search.savedSearch.SavedSearchConfirmationModals;

/**
 * Confirmation Modal when user wants to copy a saved search
 */
export const CopySavedSearchConfirmationModal: React.FC<{ searchModule: SearchModuleType }> = (
    props
) => {
    const { searchModule } = props;

    const dispatch = useDispatch();
    // calling resource directly. prefer not to build actions in search module because modals
    // come equipped with loading/error states already.
    const handleSaveClick = useCallback(
        (customProperties: { savedSearch: SavedSearchView }) =>
            elasticSearchResource
                .copySavedSearch(customProperties.savedSearch?.id)
                .then((savedSearch) => {
                    convertSavedSearchQueryShapesToLegacyQueryShape([savedSearch]);
                    dispatch(searchModule.actionCreators.copySavedSearchSuccess(savedSearch));
                }),
        [dispatch, searchModule]
    );
    const overrideModalProps = useCallback<
        OverrideModalPropsFunction<{ savedSearch: SavedSearchView }>
    >(
        (overlayProps) => ({
            title: joinTruthyValues(
                [
                    strings.CopySavedSearchConfirmationModal.titleAction,
                    overlayProps.overlayState.customProperties.savedSearch?.name,
                ],
                ' '
            ),
        }),
        []
    );

    return (
        <Modal
            children={strings.CopySavedSearchConfirmationModal.message}
            id={overlayIdEnum.COPY_SAVED_SEARCH_CONFIRMATION_MODAL}
            onSave={handleSaveClick}
            overrideModalProps={overrideModalProps}
        />
    );
};

/**
 * Confirmation Modal when user wants to delete a saved search.
 *   Sometimes used with useSavedSearchToggle.
 */
export const DeleteSavedSearchConfirmationModal: React.FC<{ searchModule: SearchModuleType }> = (
    props
) => {
    const { searchModule } = props;

    const dispatch = useDispatch();
    // calling resource directly. prefer not to build actions in search module because modals
    // come equipped with loading/error states already.
    const handleSaveClick = useCallback(
        (customProperties: { savedSearch: SavedSearchView }) =>
            elasticSearchResource
                .deleteSavedSearch(customProperties.savedSearch?.id)
                .then((result) => {
                    if (!result) {
                        throw new Error(savedSearchErrorMessages.onDeleteSavedSearchErrorMessage);
                    }

                    dispatch(
                        searchModule.actionCreators.deleteSavedSearchSuccess(
                            customProperties.savedSearch?.id
                        )
                    );
                }),
        [dispatch, searchModule]
    );
    const overrideModalProps = useCallback<
        OverrideModalPropsFunction<{ savedSearch: SavedSearchView }>
    >(
        (overlayProps) => ({
            title: joinTruthyValues(
                [
                    strings.DeleteSavedSearchConfirmationModal.titleAction,
                    overlayProps.overlayState.customProperties.savedSearch?.name,
                ],
                ' '
            ),
        }),
        []
    );

    return (
        <Modal
            children={strings.DeleteSavedSearchConfirmationModal.message}
            id={overlayIdEnum.DELETE_SAVED_SEARCH_CONFIRMATION_MODAL}
            onSave={handleSaveClick}
            overrideModalProps={overrideModalProps}
        />
    );
};

type UpdateSavedSearchConfirmationModalProps = {
    searchModule: SearchModuleType;
    onModalSave: () => void;
};

/**
 * Confirmation Modal when user wants to update a saved search
 */
export const UpdateSavedSearchConfirmationModal: React.FC<UpdateSavedSearchConfirmationModalProps> = (
    props
) => {
    const { searchModule, onModalSave } = props;

    const errorMessage = useSelector(searchModule.selectors.loadSavedSearchErrorMessageSelector);
    const savedSearch = useSelector(searchModule.selectors.executedSavedSearchToUpdateSelector);

    if (!savedSearch) {
        return null;
    }

    const title = joinTruthyValues(
        [strings.UpdateSavedSearchConfirmationModal.titleAction, savedSearch.name],
        ' '
    );

    return (
        <Modal
            children={strings.UpdateSavedSearchConfirmationModal.message}
            id={overlayIdEnum.UPDATE_SAVED_SEARCH_CONFIRMATION_MODAL}
            errorMessages={errorMessage ? [errorMessage] : undefined}
            onSave={onModalSave}
            title={title}
        />
    );
};

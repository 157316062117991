import { EntityTypeEnum } from '@mark43/rms-api';
import { get, map } from 'lodash';
import { createNItems, createFormConfiguration, formEvents } from 'markformythree';

import * as yup from 'yup';
import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../../core/validation/yupMftValidation';

const strings = componentStrings.admin.bulkEntityPermissions.BulkEntityPermissionsAdminForm;

export const BULK_ENTITY_PERMISSIONS_ADMIN_FORM = 'bulkEntityPermissionsAdminForm';

export const initialState = {
    batchEntityPermissionRequests: [
        {
            entityType: EntityTypeEnum.REPORT.name,
            isStartDateRequired: false,
        },
        {
            entityType: EntityTypeEnum.CASE.name,
            isStartDateRequired: false,
        },
        {
            entityType: EntityTypeEnum.PERSON_PROFILE.name,
            isStartDateRequired: false,
        },
        {
            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            isStartDateRequired: false,
        },
        {
            entityType: EntityTypeEnum.ITEM_PROFILE.name,
            isStartDateRequired: false,
        },
    ],
};

const validationSchema = yup.object().shape({
    batchEntityPermissionRequests: yup.array().of(
        yup.object().shape({
            isExternalDepartment: yup.boolean(),
            entityType: yup.mixed(),
            roleId: yup.mixed().test({
                name: 'role-id-required',
                test(value) {
                    if (this.parent.isExternalDepartment) {
                        return true;
                    }
                    return !!this.parent.operationType ||
                        (!!this.parent.isStartDateRequired && !!this.parent.startDateUtc)
                        ? !!value
                        : true;
                },
                message: strings.requiredError,
            }),
            operationType: yup.mixed().test({
                name: 'operation-type-required',
                test(value) {
                    return !!this.parent.roleId ||
                        (!!this.parent.isStartDateRequired && !!this.parent.startDateUtc)
                        ? !!value
                        : true;
                },
                message: strings.requiredError,
            }),
            isStartDateRequired: yup.boolean(),
            startDateUtc: yup
                .date()
                .nullable(true)
                .test({
                    name: 'start-date-utc-required',
                    test(value) {
                        return this.parent.isStartDateRequired ? !!value : true;
                    },
                    message: strings.requiredError,
                }),
        })
    ),
});

export function clearHiddenStartDateUtc(batchEntityPermissionRequests) {
    return map(batchEntityPermissionRequests, (request) => {
        return {
            ...request,
            startDateUtc: get(request, 'isStartDateRequired')
                ? get(request, 'startDateUtc')
                : undefined,
        };
    });
}

export const configuration = createFormConfiguration({
    batchEntityPermissionRequests: createNItems({
        fields: {
            isExternalDepartment: {}, // NItem field for validation
            entityType: {},
            roleId: {},
            operationType: {},
            isStartDateRequired: {},
            startDateUtc: {},
        },
    }),
});

export const onValidate = ({ formState, eventType }) =>
    validationSchema
        .validate(formState.model, { abortEarly: false })
        .then((validationResult) =>
            convertYupSuccessShapeToMFTSuccessShape(validationResult, formState.ui.$form)
        )
        .catch((validationErrors) =>
            convertYupErrorShapeToMFTErrorShape(validationErrors, formState.ui.$form, eventType)
        );

export const validationEvents = [
    {
        eventType: formEvents.FORM_SUBMIT,
    },
    {
        eventType: formEvents.INPUT_CHANGE,
    },
];

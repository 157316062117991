import { map, filter, size, sumBy, includes, keyBy, groupBy, reduce } from 'lodash';
import { Offense, NibrsOffenseCodeEnum } from '@mark43/rms-api';
import {
    itemProfilesWhereSelector,
    filterItemCategoryByNibrsCodeSelector,
} from '~/client-common/core/domain/item-profiles/state/data';
import { vehiclesWhereSelector } from '~/client-common/core/domain/vehicles/state/data';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import {
    filterRecoveredInvolvedItemsSelector,
    filterStolenInvolvedItemsSelector,
} from '~/client-common/core/domain/property-statuses/state/data';
import { offensesByReportIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { propertyDescriptionCode } from '~/client-common/core/constants/nibrsCodes';
import { currentReportSelector } from '../../../legacy-redux/selectors/reportSelectors';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

export const getOffenseHasLessThanOrEqualRecoveredPropertyValueThanStolen = (
    getState: () => RootState
) => () => {
    const state = getState();
    if (!checkIfDepartmentIsNibrs(currentUserDepartmentProfileSelector(state))) {
        return true;
    }
    const currentReport = currentReportSelector(state);
    const currentReportId = currentReport?.id;
    const offenses: Offense[] = !!currentReportId
        ? offensesByReportIdSelector(state)(currentReportId)
        : [];
    const vehicles = vehiclesWhereSelector(state)({ ownerId: currentReportId });
    const itemProfiles = itemProfilesWhereSelector(state)({ ownerId: currentReportId });
    const itemProfileById = keyBy(itemProfiles, 'id');
    const filteredOffenses = filter(
        offenses,
        (o) =>
            !!o.nibrsCodeCode &&
            o.nibrsCodeCode !== NibrsOffenseCodeEnum.CF.code &&
            o.nibrsCodeCode !== NibrsOffenseCodeEnum.SPO.code
    );

    if (!currentReport) {
        return true;
    }

    const recoveredPropertyStatuses = filterRecoveredInvolvedItemsSelector(state)(
        map(itemProfiles, 'id'),
        map(filteredOffenses, 'id'),
        currentReport.departmentId
    );

    if (!size(recoveredPropertyStatuses)) {
        return true;
    }

    const stolenPropertyStatuses = filterStolenInvolvedItemsSelector(state)(
        map(itemProfiles, 'id'),
        map(filteredOffenses, 'id'),
        currentReport.departmentId
    );

    const recoveredPropertyStatusByDescription = groupBy(
        recoveredPropertyStatuses,
        (propertyStatus) => itemProfileById[propertyStatus.itemProfileId]?.itemCategoryAttrId
    );
    const stolenPropertyStatusesByDescription = groupBy(
        stolenPropertyStatuses,
        (propertyStatus) => itemProfileById[propertyStatus.itemProfileId]?.itemCategoryAttrId
    );

    // Pre-compute stolen vehicle property value
    const stolenVehicles = filterStolenInvolvedItemsSelector(state)(
        map(vehicles, 'itemId'),
        map(filteredOffenses, 'id'),
        currentReport.departmentId
    );
    const stolenVehiclesPropertyValue = sumBy(stolenVehicles, 'declaredValue');
    const recoveredItemProfiles = filter(itemProfiles, ({ id }) =>
        includes(map(recoveredPropertyStatuses, 'itemProfileId'), id)
    );
    const recoveredMotorVehicleParts = filterItemCategoryByNibrsCodeSelector(state)(
        recoveredItemProfiles,
        propertyDescriptionCode.vehiclePartsAndAccessories,
        currentReport.departmentId
    );

    const recoveredMotorVehiclePartsByItemCategoryId = groupBy(
        recoveredMotorVehicleParts,
        'itemCategoryAttrId'
    );

    return reduce(
        recoveredPropertyStatusByDescription,
        (isValid, itemCategoryRecoveredPropertyStatuses, itemCategoryAttrId) => {
            const itemCategoryStolenPropertyStatuses =
                stolenPropertyStatusesByDescription[itemCategoryAttrId];

            const recoveredPropertyValue = sumBy(
                itemCategoryRecoveredPropertyStatuses,
                'declaredValue'
            );
            const stolenPropertyValue = sumBy(itemCategoryStolenPropertyStatuses, 'declaredValue');

            const isVehicleRecoveredPartsVsStolenVehicle =
                !!recoveredMotorVehicleParts.length &&
                !!stolenVehicles.length &&
                !!recoveredMotorVehiclePartsByItemCategoryId[itemCategoryAttrId];

            return (
                isValid &&
                (recoveredPropertyValue <= stolenPropertyValue ||
                    (isVehicleRecoveredPartsVsStolenVehicle &&
                        recoveredPropertyValue <= stolenVehiclesPropertyValue))
            );
        },
        true
    );
};

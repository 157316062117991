import { SqlSearchQueryBriefingSearchSqlQuery } from '@mark43/rms-api';

export enum BriefingRoutingModalType {
    CREATE,
    EDIT,
    DUPLICATE,
}

export const BriefingModes = {
    EDIT: 'edit',
    VIEW: 'view',
} as const;
type BriefingModeKeys = keyof typeof BriefingModes;
export type BriefingMode = typeof BriefingModes[BriefingModeKeys];

export type BriefingSearchSqlQueryWithFilters = SqlSearchQueryBriefingSearchSqlQuery & {
    isAutoSave: boolean;
};

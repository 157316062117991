import React, { useEffect, useState } from 'react';
import { LocationSourceEnum, LinkTypesEnum, EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';
import _, { some, reject, defer, map, size, take, indexOf, without, flatMap } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { locationExternalLinksWhereSelector } from '~/client-common/core/domain/location-external-links/state/data';
import {
    mergeLocationEntityLinkIntoLocationView,
    getAgencyIdForLocationEntityLink,
} from '~/client-common/core/domain/locations/utils/locationHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { locationByIdSelector } from '~/client-common/core/domain/locations/state/data';
import { getNextFocusableElement } from '~/client-common/core/keyboardFocus/helpers';
import { Button as ArcButton } from '../../components/Button';

import {
    REN_IDENTIFIER,
    REPORT_IDENTIFIER,
    recentEntitiesForOwnerTypeOwnerIdAndEntityTypeSelector,
} from '../../recent-entities/state/ui';
import { quickAddLocation } from '../state/ui';
import { LocationQuickAddContext } from '../../context/LocationQuickAddContext';
import loadingStateEnum from '../../context/constants/loadingStateEnum';
import { requestRecentLocationsForReports } from '../../../../legacy-redux/actions/recentEntitiesActions';
import {
    currentReportRENSelector,
    currentReportIdSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import { currentUserDepartmentAgencyIdSelector } from '../../current-user/state/ui';
import Icon, { iconTypes, iconSizes } from '../../components/Icon';
import { QuickAddGrid } from '../../components/QuickAddGrid';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { RMSArbiterProvider } from '../../arbiter';
import QuickAddInstruction from '../../components/QuickAddInstruction';

import LocationQuickAddPill from './LocationQuickAddPill';

const strings = componentStrings.core;
const INITIAL_NUM_LOCATIONS_TO_SHOW = 4;

const LocationQuickAddContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    padding-right: 15px;
    padding-top: 7px;
`;

const ExpandButtonContainer = styled.div`
    display: flex;
    width: 100%;
    margin-right: 15px;
    justify-content: center;
`;

const ExpandButton = styled(Button)`
    padding: 5px;
    margin: 5px auto 0;

    & > .react-icon-right {
        margin-left: 10px;
    }
`;

const formContext = RefContextEnum.FORM_LOCATION_ENTITY_LINK_SIDE_PANEL.name;

const LocationQuickAdd = ({
    renForRecents,
    ownerType,
    ownerId,
    linkType,
    locationEntityLinksWhere,
    recentEntitiesForOwnerTypeOwnerIdAndEntityType,
    requestRecentLocations,
    locationById,
    quickAddLocation,
    onSaveSuccess,
    locationOverlayId,
    setCancelFocusRef,
    currentReportId,
    currentUserDepartmentAgencyId,
}) => {
    const {
        isLocationStale,
        setIsLocationStale,
        locationsLoadingState,
        setLocationsLoadingState,
    } = LocationQuickAddContext.useContainer();
    const [isExpanded, setIsExpanded] = useState(false);
    const [workingEntityIds, setWorkingEntityIds] = useState([]);

    useEffect(() => {
        if (locationsLoadingState === loadingStateEnum.DO_LOAD) {
            setLocationsLoadingState((prevLoadingState) => {
                if (prevLoadingState === loadingStateEnum.DO_LOAD) {
                    defer(() =>
                        requestRecentLocations({
                            renForRecents,
                            onComplete: () => {
                                setLocationsLoadingState(loadingStateEnum.READY);
                                setIsLocationStale(false);
                            },
                        })
                    );
                    return loadingStateEnum.IN_PROGRESS;
                } else {
                    return prevLoadingState;
                }
            });
        }
    }, [
        locationsLoadingState,
        renForRecents,
        currentReportId,
        requestRecentLocations,
        setIsLocationStale,
        setLocationsLoadingState,
    ]);

    useEffect(() => {
        if (isLocationStale) {
            setLocationsLoadingState((prevLoadingState) =>
                prevLoadingState === loadingStateEnum.READY
                    ? loadingStateEnum.DO_LOAD
                    : prevLoadingState
            );
        }
    }, [isLocationStale, setLocationsLoadingState]);

    const { recentEntityIdsByOwner = {} } =
        recentEntitiesForOwnerTypeOwnerIdAndEntityType({
            ownerType: renForRecents ? REN_IDENTIFIER : REPORT_IDENTIFIER,
            ownerId: renForRecents || currentReportId,
            entityType: EntityTypeEnum.LOCATION.name,
        }) || {};
    const recentLocationIds = flatMap(recentEntityIdsByOwner);

    const MULTI_AGENCY_SUBDIVISIONS_ENABLED = useSelector(applicationSettingsSelector)
        .MULTI_AGENCY_SUBDIVISIONS_ENABLED;

    const recentLocations = _(recentLocationIds)
        .uniq()
        .differenceWith(
            locationEntityLinksWhere({
                linkType,
                entityId: ownerId,
            }),
            (recentLocationId, locationEntityLink) =>
                recentLocationId === locationEntityLink.locationId
        )
        .map((recentLocationId) => locationById(recentLocationId))
        .map((location) =>
            mergeLocationEntityLinkIntoLocationView(
                {
                    agencyId: getAgencyIdForLocationEntityLink({
                        multiagencySubdivisionsEnabled: MULTI_AGENCY_SUBDIVISIONS_ENABLED,
                        location,
                        currentUserDepartmentAgencyId,
                    }),
                },
                location
            )
        )
        .compact()
        .value();

    const locationExternalLinksWhere = useSelector(locationExternalLinksWhereSelector);

    const RMS_WASHINGTON_DC_RESTRICT_OFFENSEINCIDENT_ADDRESS_ENABLED = useSelector(
        applicationSettingsSelector
    ).RMS_WASHINGTON_DC_RESTRICT_OFFENSEINCIDENT_ADDRESS_ENABLED;

    const filteredRecentLocations = reject(recentLocations, (result) => {
        const locationExternalLinks = locationExternalLinksWhere({ locationId: result.id });
        const isDcMarLocation = some(
            locationExternalLinks,
            (locationExternalLink) =>
                locationExternalLink.locationSource === LocationSourceEnum.DC_MAR.name
        );

        // Remove locations if all of these conditions are true:
        // * FF is enabled
        // * this is an offense location
        // * this is not a MAR location
        return (
            RMS_WASHINGTON_DC_RESTRICT_OFFENSEINCIDENT_ADDRESS_ENABLED &&
            linkType === LinkTypesEnum.OFFENSE_LOCATION &&
            !isDcMarLocation
        );
    });

    const recentLocationsCount = size(filteredRecentLocations);

    const onClickLocation = (arbiterInstance) => ({ location, ref }) => {
        setWorkingEntityIds([...workingEntityIds, location.id]);
        setCancelFocusRef(ref);
        const nextFocusableElement = getNextFocusableElement(ref.current);
        quickAddLocation({
            location,
            entityType: ownerType,
            entityId: ownerId,
            linkType,
            formContext,
            arbiterInstance,
            onSuccess: ({ location, modelEntityLink }) => {
                setIsLocationStale(true);
                if (onSaveSuccess) {
                    onSaveSuccess(location, modelEntityLink);
                }

                // If there are more recent locations to add, focus on the next recent location
                if (nextFocusableElement) {
                    nextFocusableElement.focus();
                }
            },
            onComplete: ({ location }) =>
                setWorkingEntityIds(without(workingEntityIds, location.id)),
            locationOverlayId,
        });
    };

    return (
        <LocationQuickAddContext.Provider>
            <RMSArbiterProvider context={formContext}>
                {(arbiterInstance) => (
                    <LocationQuickAddContainer>
                        {recentLocationsCount > 0 && (
                            <>
                                <QuickAddInstruction
                                    renForRecents={renForRecents}
                                    type={strings.QuickAddInstruction.locations}
                                />
                                <QuickAddGrid>
                                    {map(
                                        take(
                                            filteredRecentLocations,
                                            isExpanded
                                                ? recentLocationsCount
                                                : INITIAL_NUM_LOCATIONS_TO_SHOW
                                        ),
                                        (recentLocation) => {
                                            return (
                                                <LocationQuickAddPill
                                                    key={recentLocation.id}
                                                    location={recentLocation}
                                                    onClick={onClickLocation(arbiterInstance)}
                                                    isSelected={
                                                        indexOf(
                                                            workingEntityIds,
                                                            recentLocation.id
                                                        ) >= 0
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </QuickAddGrid>
                            </>
                        )}
                        {!isExpanded && recentLocationsCount > INITIAL_NUM_LOCATIONS_TO_SHOW && (
                            <ExpandButtonContainer>
                                <FeatureFlagged
                                    flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                                    fallback={
                                        <ExpandButton
                                            className={buttonTypes.ICON_LINK}
                                            onClick={() => setIsExpanded(true)}
                                            iconRight={
                                                <Icon
                                                    color="cobaltBlue"
                                                    type={iconTypes.OPEN}
                                                    size={iconSizes.MEDIUM}
                                                />
                                            }
                                        >
                                            {strings.LocationQuickAdd.more(
                                                recentLocationsCount - INITIAL_NUM_LOCATIONS_TO_SHOW
                                            )}
                                        </ExpandButton>
                                    }
                                >
                                    <ArcButton
                                        onClick={() => setIsExpanded(true)}
                                        rightIcon="Open"
                                        variant="ghost"
                                    >
                                        {strings.LocationQuickAdd.more(
                                            recentLocationsCount - INITIAL_NUM_LOCATIONS_TO_SHOW
                                        )}
                                    </ArcButton>
                                </FeatureFlagged>
                            </ExpandButtonContainer>
                        )}
                    </LocationQuickAddContainer>
                )}
            </RMSArbiterProvider>
        </LocationQuickAddContext.Provider>
    );
};

const mapDispatchToProps = (dispatch) => ({
    requestRecentLocations: ({ renForRecents, onComplete }) => {
        return dispatch(
            requestRecentLocationsForReports(renForRecents, EntityTypeEnum.REPORT.name)
        ).finally(() => {
            if (onComplete) {
                onComplete();
            }
        });
    },
    quickAddLocation: ({
        location,
        entityType,
        entityId,
        onSuccess,
        linkType,
        formContext,
        arbiterInstance,
        onComplete,
        locationOverlayId,
    }) =>
        dispatch(
            quickAddLocation({
                location,
                entityType,
                entityId,
                onSuccess,
                linkType,
                formContext,
                arbiterInstance,
                onComplete,
                locationOverlayId,
            })
        ),
});

const mapStateToProps = createStructuredSelector({
    locationEntityLinksWhere: locationEntityLinksWhereSelector,
    recentEntitiesForOwnerTypeOwnerIdAndEntityType: recentEntitiesForOwnerTypeOwnerIdAndEntityTypeSelector,
    locationById: locationByIdSelector,
    renForRecents: currentReportRENSelector,
    currentReportId: currentReportIdSelector,
    currentUserDepartmentAgencyId: currentUserDepartmentAgencyIdSelector,
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationQuickAdd);

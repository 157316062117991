import { EntityTypeEnum, UsageSourceModuleEnum, UsageActionEnum } from '@mark43/rms-api';
import React, { useContext, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import {
    compact,
    map,
    size,
    first,
    findIndex,
    isEmpty,
    parseInt,
    chain,
    filter,
    concat,
} from 'lodash';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { useResource } from '~/client-common/core/hooks/useResource';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { convertAttachmentViewModelsForLightboxGallery } from '~/client-common/core/domain/attachments/state/ui';
import { convertAttachmentsToCaseAttachmentsRowViewModelsSelector } from '~/client-common/core/domain/case-attachments/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import linkTypesEnum from '~/client-common/core/enums/universal/linkTypesEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { folderContentViewsSelector } from '~/client-common/core/domain/folder-content-views/state/data';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { horizCenter } from '../../../core/styles/mixins';
import Icon, { iconSizes, iconTypes } from '../../../core/components/Icon';
import {
    currentCaseSelector,
    currentCaseCombinedAttachmentsAndFoldersSelector,
    canCurrentUserEditCurrentCaseSelector,
} from '../../core/state/ui';
import { currentUserIdSelector } from '../../../core/current-user/state/ui';
import {
    setAttachmentSort,
    caseAttachmentsTableUiSelector,
    toggleAttachmentView,
    attachmentListSortTypes,
} from '../state/ui';
import AttachmentViewToggleButton, {
    attachmentViews,
} from '../../../attachments/core/components/AttachmentViewToggleButton';

import { exportFiles } from '../../../../legacy-redux/actions/exportsActions';
import { openImageGalleryLightbox } from '../../../../legacy-redux/actions/imageGalleryLightboxActions';

import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';

import Text from '../../../core/forms/components/Text';
import DebouncedText from '../../../core/forms/components/DebouncedText';
import _TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import TableHeaderButton from '../../../../legacy-redux/components/core/tables/TableHeaderButton';
import _OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import PaddedNoDataBlock from '../../../core/components/PaddedNoDataBlock';
import _CaseRowCheckbox from '../../core/components/CaseRowCheckbox';
import KebabMenu from '../../core/components/KebabMenu';
import { downloadSelectFile } from '../../../attachments/files/helpers';

import { tableWidths } from '../configuration';
import { createUsageLog } from '../../../admin/usage-logs/state/data';
import AttachmentsSidePanel from '../../../attachments/core/components/AttachmentsSidePanel';
import { initializeAttachmentsSidePanelForm } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import testIds from '../../../../core/testIds';
import FolderBreadcrumbs from '../../core/components/FolderBreadcrumbs';
import {
    SearchFilterContext,
    CurrentFolderIdContext,
    SelectedRowsContext,
} from '../../core/contexts';
import {
    kebabCanCreateNewFolder,
    kebabCanDelete,
    kebabCanRename,
    kebabCanMoveFromFolder,
} from '../../core/utils/kebabMenuHelpers';
import { downloadContent } from '../../core/state/data/folderContents';
import folderResource from '../../core/resources/folderResource';
import AttachmentRows from './AttachmentRows';

const TABLE_RESULTS_MARGIN = 126;

const strings = componentStrings.cases.caseAttachments.CaseAttachments;

const NUM_OF_RESULTS_ON_PAGE = 100;

const TableResultsSummary = styled(_TableResultsSummary)`
    margin: 19px 10px 0;
`;

const AttachmentHeaderCell = styled.div`
    text-align: left;
`;

const CaseRowCheckbox = styled(_CaseRowCheckbox)`
    margin-left: 10px;
`;

const NameAttachmentHeaderCell = styled(AttachmentHeaderCell)`
    flex: 2.7 0 145px;
`;

const TypeAttachmentHeaderCell = styled(AttachmentHeaderCell)`
    flex: 1.8 0 70px;
`;

const CreatedDateAttachmentHeaderCell = styled(AttachmentHeaderCell)`
    flex: 1.1 0 131px;
`;

const SourceAttachmentHeaderCell = styled(AttachmentHeaderCell)`
    flex: 4 0 90px;
`;

const ViewToggleAttachmentHeaderCell = styled(AttachmentHeaderCell)`
    min-width: 5%;
`;

const GridNameAttachmentHeaderCell = styled(AttachmentHeaderCell)`
    flex: 10 0 150px;
`;

const Footer = styled.div`
    text-align: right;
    clear: both;
    padding: 16px 0;
`;

const HeaderAboveTable = styled.div`
    height: 80px;
    margin-bottom: 20px;
`;

const CaseAttachmentHeader = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const OptionsTableColumn = styled(_OptionsTableColumn)`
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.cobaltBlue};
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;

    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

const KebabMenuWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

const getPaginationCombined = (items, page, pageSize = NUM_OF_RESULTS_ON_PAGE) => {
    const zeroBasedPage = page - 1;
    const sliceOffset = zeroBasedPage * pageSize;
    const paginatedRows = compact(items.slice(sliceOffset, sliceOffset + pageSize));

    const paginationItemsTo = sliceOffset + paginatedRows.length;

    return {
        paginationItemsFrom: sliceOffset,
        paginationItemsTo,
        paginatedRows,
    };
};

const getCombinedSearchResults = (
    searchResult,
    caseId,
    currentUserId,
    convertAttachmentsToCaseAttachmentsRowViewModels
) => {
    const searchResultFolders = map(searchResult.folders, (folder) => ({
        ...folder,
        displayType: 'FOLDER',
        isFolder: true,
    }));

    const convertedAttachments = convertAttachmentsToCaseAttachmentsRowViewModels(
        caseId,
        currentUserId,
        searchResult.attachments
    );

    const searchResultAttachments = map(convertedAttachments, (attachment) => ({
        ...attachment,
        displayType: attachment.attachmentType,
        isFolder: false,
    }));

    return concat(searchResultAttachments, searchResultFolders);
};

const ButtonWrapper = styled.div`
    margin-right: -10px;
`;

const ScrollableTable = styled.div`
    position: relative;
    overflow-y: scroll;
    max-height: 62vh;
`;

const AttachmentsTableHeaders = styled.div`
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 3px 3px -3px ${(props) => props.theme.colors.shadow};
    padding-top: 10px;
`;

const AttachmentsTableResults = styled.div`
    padding-top: 10px;
    width: 100%;
`;

const NoResults = styled(PaddedNoDataBlock)`
    margin-top: ${TABLE_RESULTS_MARGIN + 20}px;
    width: 200px;
    ${horizCenter};
    position: relative;
`;

const FilterText = styled(Text)`
    margin-top: 6px;
`;

const DirectionIcon = styled(Icon)`
    margin-left: 5px;
`;

const OptionTableColumnDisplayContainer = styled.div`
    display: flex;
    align-items: center;
`;

const AddIcon = styled(Icon)`
    display: inline-block;
    padding-top: 3px;
    padding-right: 3px;
    vertical-align: top;
`;

const AddAttachmentText = styled.div`
    padding-top: 1px;
    padding-left: 2px;
    display: inline-block;
`;

const AddAttachmentButton = styled(Button)`
    margin: 0 -1px 0 5px;
    text-align: left;
`;

const SearchLine = styled.div`
    display: flex;
    justify-content: space-between;
    height: 40px;
`;

const SummaryLine = styled.div`
    display: flex;
    justify-content: end;
    height: 40px;
`;

function _CaseAttachments(props) {
    const {
        filter: searchFilter,
        setFilter,
        currentPage,
        setCurrentPage,
        searchResult,
        setSearchResult,
    } = useContext(SearchFilterContext);

    const currentCaseCombinedAttachmentsAndFolders = useSelector(
        currentCaseCombinedAttachmentsAndFoldersSelector
    );
    const canCurrentUserEditCurrentCase = useSelector(canCurrentUserEditCurrentCaseSelector);

    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    const {
        className,
        currentCase,
        downloadSelected,
        download,
        setSort,
        caseAttachmentsTableUi,
        openLightbox,
        generateExportAttachmentUsageLog,
        initializeAttachmentsSidePanelForm,
        folderContentViews,
        params,
        selectedRows,
        areAllElementsSelected,
        selectAllClick,
        applicationSettings,
        setSelectedRows,
    } = props;

    const handleSearchChange = (filterText = '') => {
        if (selectedRows.length > 0) {
            setSelectedRows([]);
        }

        setFilter(filterText);
        setCurrentPage(1);
    };

    const handlePaginationClick = (currentPage, nextPage) => {
        setCurrentPage(nextPage);
    };

    const toggleView = () => {
        props.toggleView();
    };

    const attachmentEntityLinkDetails = {
        entityType: EntityTypeEnum.CASE.name,
        entityId: currentCase?.id,
        attachmentLinkType: linkTypesEnum.CASE_ATTACHMENT,
    };
    const folderId = parseInt(params.folderId);
    const lowercaseFilter = searchFilter.toLowerCase();

    const areCaseFoldersEnabled = applicationSettings.RMS_CASE_FOLDERING_ENABLED;

    const loadSearchResults = useCallback(
        () =>
            folderResource.searchFoldersAndAttachments({
                ownerType: EntityTypeEnum.CASE.name,
                ownerId: params.caseId,
                nameToSearch: lowercaseFilter,
            }),
        [lowercaseFilter, params.caseId]
    );

    const onResourceSuccess = useCallback(
        (result) => {
            setSearchResult(result);
        },
        [setSearchResult]
    );

    useResource(loadSearchResults, onResourceSuccess);

    const combinedRows = currentCaseCombinedAttachmentsAndFolders(folderId).map((row) => {
        const displayType = row.isFolder ? 'FOLDER' : getAttachmentFile(row)?.fileCategory;
        return { ...row, displayType };
    });
    const folderContentView = folderContentViews[folderId];
    const folderBreadcrumbs = folderContentView?.folderBreadcrumbs;

    const currentUserId = useSelector(currentUserIdSelector);
    const convertAttachmentsToCaseAttachmentsRowViewModels = useSelector(
        convertAttachmentsToCaseAttachmentsRowViewModelsSelector
    );

    const combinedSearchResults = getCombinedSearchResults(
        searchResult,
        params.caseId,
        currentUserId,
        convertAttachmentsToCaseAttachmentsRowViewModels
    );

    const totalResults =
        areCaseFoldersEnabled && searchFilter
            ? combinedSearchResults
            : chain(combinedRows)
                  .filter(
                      (attachment) =>
                          `${attachment.source} ${attachment.sourceDescription} ${attachment.displayName} ${attachment.displayType}`
                              .toLowerCase()
                              .indexOf(lowercaseFilter) !== -1
                  )
                  .thru((arr) =>
                      sortByNaturalOrder(
                          arr,
                          [caseAttachmentsTableUi.sortKey],
                          [caseAttachmentsTableUi.sortType === 'ASCENDING' ? 'asc' : 'desc']
                      )
                  )
                  .value();

    const currentView = caseAttachmentsTableUi.view;
    const isTopLevel = !params.folderId;

    const { paginationItemsFrom, paginationItemsTo, paginatedRows } = getPaginationCombined(
        totalResults,
        currentPage
    );
    const paginatedAttachments = filter(paginatedRows, { isFolder: false });
    const paginatedFolders = filter(paginatedRows, { isFolder: true });

    const showAttachmentTableResults = currentCase && !isEmpty(totalResults);

    return (
        <div data-test-id={testIds.CASE_ATTACHMENTS}>
            <FeatureFlagged flag="RMS_CASE_FOLDERING_ENABLED">
                <FolderBreadcrumbs
                    entityId={currentCase?.id}
                    breadcrumbs={folderBreadcrumbs}
                    entityType={EntityTypeEnum.ATTACHMENT.name}
                    tabName="Attachments"
                />
            </FeatureFlagged>
            <HeaderAboveTable>
                <SearchLine data-test-id={testIds.CASE_ATTACHMENT_SEARCH_BAR}>
                    <FeatureFlagged
                        flag="RMS_CASE_FOLDERING_ENABLED"
                        fallback={
                            totalResults.length > NUM_OF_RESULTS_ON_PAGE ? (
                                <DebouncedText
                                    width={280}
                                    onChange={handleSearchChange}
                                    placeholder={strings.searchTextPlaceholder}
                                    TextComponent={FilterText}
                                />
                            ) : (
                                <FilterText
                                    width={280}
                                    value={searchFilter}
                                    onChange={handleSearchChange}
                                    placeholder={strings.searchTextPlaceholder}
                                />
                            )
                        }
                    >
                        <FilterText
                            width={280}
                            value={searchFilter}
                            onChange={handleSearchChange}
                            placeholder={strings.attachmentsAndFoldersSearchTextPlaceholder}
                        />
                    </FeatureFlagged>
                    <KebabMenuWrapper>
                        <FeatureFlagged flag="RMS_CASE_FOLDERING_ENABLED">
                            <KebabMenu
                                folderId={folderId}
                                entityTypeId={EntityTypeEnum.ATTACHMENT.name}
                                downloadSelected={downloadSelected}
                                canRename={kebabCanRename({
                                    canCurrentUserEditCurrentCase,
                                    selectedRows,
                                })}
                                canDelete={kebabCanDelete({
                                    canCurrentUserEditCurrentCase,
                                    selectedRows,
                                })}
                                canRemoveFromFolder={kebabCanMoveFromFolder({
                                    canCurrentUserEditCurrentCase,
                                    isTopLevel,
                                    searchFilter,
                                    selectedRows,
                                    entityType: EntityTypeEnum.ATTACHMENT.name,
                                })}
                                canCreateNewFolder={kebabCanCreateNewFolder({
                                    canCurrentUserEditCurrentCase,
                                    searchFilter,
                                    entityType: EntityTypeEnum.ATTACHMENT.name,
                                })}
                            />
                        </FeatureFlagged>
                        <AttachmentsSidePanel
                            entityType={EntityTypeEnum.CASE.name}
                            getInitialCustomPropertyState={() => attachmentEntityLinkDetails}
                            overlayId={overlayIdEnum.CASE_ATTACHMENTS_SIDE_PANEL}
                            currentFolderId={folderId}
                            renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => {
                                const handleAddClick = () => {
                                    initializeAttachmentsSidePanelForm(attachmentEntityLinkDetails);
                                    open();
                                };

                                return (
                                    <AddAttachmentButton
                                        className={buttonTypes.PRIMARY}
                                        iconLeft={
                                            <AddIcon
                                                size={iconSizes.MEDIUM}
                                                color="white"
                                                type={iconTypes.ADD}
                                            />
                                        }
                                        testId={testIds.CASE_ADD_ATTACHMENT}
                                        onClick={handleAddClick}
                                        setRef={setCloseFocusRefs}
                                        disabled={!canCurrentUserEditCurrentCase}
                                    >
                                        <FeatureFlagged
                                            flag="RMS_CASE_FOLDERING_ENABLED"
                                            fallback={
                                                <AddAttachmentText>
                                                    {strings.addAttachmentButton}
                                                </AddAttachmentText>
                                            }
                                        >
                                            <AddAttachmentText>
                                                {strings.addEditAttachmentButton}
                                            </AddAttachmentText>
                                        </FeatureFlagged>
                                    </AddAttachmentButton>
                                );
                            }}
                        />
                    </KebabMenuWrapper>
                </SearchLine>
                <SummaryLine>
                    <TableResultsSummary
                        from={paginationItemsFrom}
                        to={paginationItemsTo}
                        totalResults={totalResults.length}
                    />
                    <FeatureFlagged
                        flag="RMS_CASE_FOLDERING_ENABLED"
                        fallback={
                            <ButtonWrapper>
                                <Button
                                    style={{ width: '185px' }}
                                    className={buttonTypes.SECONDARY}
                                    disabled={size(totalResults) === 0}
                                    onClick={() =>
                                        download(totalResults, currentCase, caseDisplayName)
                                    }
                                    testId={testIds.CASE_ATTACHMENT_DOWNLOAD_ALL_BUTTON}
                                >
                                    {strings.downloadAll}
                                </Button>
                            </ButtonWrapper>
                        }
                    />
                </SummaryLine>
            </HeaderAboveTable>
            <ScrollableTable>
                <AttachmentsTableHeaders className={className}>
                    {currentView === attachmentViews.LIST ? (
                        <CaseAttachmentHeader>
                            <FeatureFlagged flag="RMS_CASE_FOLDERING_ENABLED">
                                <CaseRowCheckbox
                                    value={areAllElementsSelected({
                                        elements: paginatedRows,
                                        idKey: 'attachmentId',
                                    })}
                                    onChange={() =>
                                        selectAllClick({
                                            elements: paginatedAttachments,
                                            idKey: 'attachmentId',
                                            folders: paginatedFolders,
                                            entityType: EntityTypeEnum.ATTACHMENT.name,
                                        })
                                    }
                                    entityType={EntityTypeEnum.ATTACHMENT.name}
                                />
                            </FeatureFlagged>
                            <NameAttachmentHeaderCell>
                                <TableHeaderButton
                                    activeSortKey={caseAttachmentsTableUi.sortKey}
                                    activeSortType={caseAttachmentsTableUi.sortType}
                                    sortKey="displayName"
                                    onClick={setSort}
                                >
                                    {strings.name}
                                </TableHeaderButton>
                            </NameAttachmentHeaderCell>
                            <TypeAttachmentHeaderCell>
                                <TableHeaderButton
                                    activeSortKey={caseAttachmentsTableUi.sortKey}
                                    activeSortType={caseAttachmentsTableUi.sortType}
                                    sortKey="displayType"
                                    onClick={setSort}
                                >
                                    {strings.type}
                                </TableHeaderButton>
                            </TypeAttachmentHeaderCell>
                            <CreatedDateAttachmentHeaderCell>
                                <TableHeaderButton
                                    activeSortKey={caseAttachmentsTableUi.sortKey}
                                    activeSortType={caseAttachmentsTableUi.sortType}
                                    sortKey="createdDateUtc"
                                    onClick={setSort}
                                >
                                    {strings.createdDate}
                                </TableHeaderButton>
                            </CreatedDateAttachmentHeaderCell>
                            <SourceAttachmentHeaderCell>
                                <TableHeaderButton
                                    activeSortKey={caseAttachmentsTableUi.sortKey}
                                    activeSortType={caseAttachmentsTableUi.sortType}
                                    sortKey="description"
                                    onClick={setSort}
                                >
                                    {strings.source}
                                </TableHeaderButton>
                            </SourceAttachmentHeaderCell>
                            <ViewToggleAttachmentHeaderCell>
                                <AttachmentViewToggleButton
                                    currentView={currentView}
                                    onClick={toggleView}
                                />
                            </ViewToggleAttachmentHeaderCell>
                        </CaseAttachmentHeader>
                    ) : (
                        <CaseAttachmentHeader>
                            <FeatureFlagged flag="RMS_CASE_FOLDERING_ENABLED">
                                {!searchFilter && (
                                    <CaseRowCheckbox
                                        value={areAllElementsSelected({
                                            elements: paginatedRows,
                                            idKey: 'attachmentId',
                                        })}
                                        onChange={() =>
                                            selectAllClick({
                                                elements: paginatedAttachments,
                                                idKey: 'attachmentId',
                                                folders: paginatedFolders,
                                                entityType: EntityTypeEnum.ATTACHMENT.name,
                                            })
                                        }
                                        entityType={EntityTypeEnum.ATTACHMENT.name}
                                    />
                                )}
                            </FeatureFlagged>
                            <GridNameAttachmentHeaderCell>
                                <OptionsTableColumn
                                    display={(data) => (
                                        <OptionTableColumnDisplayContainer>
                                            {data.display}
                                            <DirectionIcon
                                                color="cobaltBlue"
                                                type={
                                                    caseAttachmentsTableUi.sortType ===
                                                    attachmentListSortTypes.DESCENDING
                                                        ? iconTypes.ARROW_UP
                                                        : iconTypes.ARROW_DOWN
                                                }
                                                size={10}
                                            />
                                        </OptionTableColumnDisplayContainer>
                                    )}
                                    activeValue={caseAttachmentsTableUi.sortKey}
                                    activeSortType={caseAttachmentsTableUi.sortType}
                                    onOptionClick={(data) => setSort(data.value)}
                                    onSortTypeClick={() => setSort(caseAttachmentsTableUi.sortKey)}
                                    popoutWidth={160}
                                >
                                    <OptionsTableColumnOption
                                        display={strings.name}
                                        value={'displayName'}
                                        sortOptions={[
                                            {
                                                display: `${strings.name} A-Z`,
                                                sortType: attachmentListSortTypes.ASCENDING,
                                            },
                                            {
                                                display: `${strings.name} Z-A`,
                                                sortType: attachmentListSortTypes.DESCENDING,
                                            },
                                        ]}
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.createdDate}
                                        value={'createdDateUtc'}
                                        sortOptions={[
                                            {
                                                display: strings.sortDateAsc,
                                                sortType: attachmentListSortTypes.ASCENDING,
                                            },
                                            {
                                                display: strings.sortDateDesc,
                                                sortType: attachmentListSortTypes.DESCENDING,
                                            },
                                        ]}
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.source}
                                        value={'description'}
                                        sortOptions={[
                                            {
                                                display: `${strings.source} A-Z`,
                                                sortType: attachmentListSortTypes.ASCENDING,
                                            },
                                            {
                                                display: `${strings.source} Z-A`,
                                                sortType: attachmentListSortTypes.DESCENDING,
                                            },
                                        ]}
                                    />
                                </OptionsTableColumn>
                            </GridNameAttachmentHeaderCell>
                            <AttachmentHeaderCell width={tableWidths.actions}>
                                <AttachmentViewToggleButton
                                    currentView={currentView}
                                    onClick={toggleView}
                                />
                            </AttachmentHeaderCell>
                        </CaseAttachmentHeader>
                    )}
                </AttachmentsTableHeaders>
                {showAttachmentTableResults ? (
                    <AttachmentsTableResults className={className}>
                        <AttachmentRows
                            openLightbox={openLightbox}
                            generateExportAttachmentUsageLog={generateExportAttachmentUsageLog}
                            currentView={currentView}
                            paginatedRows={paginatedRows}
                            currentCase={currentCase}
                        />
                    </AttachmentsTableResults>
                ) : (
                    <NoResults>{strings.noResultsFound}</NoResults>
                )}
            </ScrollableTable>
            <Footer>
                {showAttachmentTableResults && (
                    <Pagination
                        currentPage={currentPage}
                        itemsPerPage={NUM_OF_RESULTS_ON_PAGE}
                        itemCount={totalResults.length}
                        onClick={handlePaginationClick}
                        maxEdgeItems={1}
                    />
                )}
            </Footer>
        </div>
    );
}

const CaseAttachments = styled(_CaseAttachments)`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
`;

const ContextCaseAttachments = (props) => {
    const { setCurrentFolderId } = useContext(CurrentFolderIdContext);
    const { selectedRows, areAllElementsSelected, selectAllClick, setSelectedRows } = useContext(
        SelectedRowsContext
    );
    return (
        <CaseAttachments
            {...props}
            setCurrentFolderId={setCurrentFolderId}
            selectedRows={selectedRows}
            areAllElementsSelected={areAllElementsSelected}
            selectAllClick={selectAllClick}
            setSelectedRows={setSelectedRows}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    caseAttachmentsTableUi: caseAttachmentsTableUiSelector,
    currentCase: currentCaseSelector,
    folderContentViews: folderContentViewsSelector,
    applicationSettings: applicationSettingsSelector,
    dateTimeFormatter: currentDepartmentDateFormatterSelector,
});

const mapDispatchToProps = (dispatch) => ({
    initializeAttachmentsSidePanelForm: (entityLinkDetails) =>
        dispatch(initializeAttachmentsSidePanelForm(entityLinkDetails)),
    downloadSelected: (currentFolderId, selectedRows) => {
        dispatch(downloadContent(currentFolderId, selectedRows));
    },
    download: (attachments, currentCase, caseDisplayName) => {
        const { id, localId } = currentCase;
        const bulkDownloadTitle = strings.downloadZipFileTitle(localId, caseDisplayName);
        const files = map(attachments, getAttachmentFile);
        const fileIds = compact(map(files, 'id'));

        const usageLog = {
            sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
        };

        if (size(fileIds) === 1) {
            const file = first(files);
            downloadSelectFile(file.fileWebServerPath, file.originalFileName);
            const { entityType, entityId, sourceDescription } = first(attachments);
            const entityisFromCase =
                entityType === EntityTypeEnum.CASE_NOTE.name ||
                entityType === EntityTypeEnum.TASK.name;

            dispatch(
                createUsageLog({
                    ...usageLog,
                    primaryEntityId: entityisFromCase ? id : entityId,
                    primaryEntityType: entityisFromCase ? EntityTypeEnum.CASE.name : entityType,
                    primaryEntityTitle: `${sourceDescription}: ${file.id} - ${file.originalFileName}`,
                    action: UsageActionEnum.DOWNLOADED_ATTACHMENT.name,
                })
            );
        } else if (size(fileIds) > 1) {
            dispatch(exportFiles(fileIds, bulkDownloadTitle));
            dispatch(
                createUsageLog({
                    ...usageLog,
                    primaryEntityType: EntityTypeEnum.CASE.name,
                    primaryEntityId: id,
                    primaryEntityTitle: `${localId} (${fileIds.length} Attachments)`,
                    action: UsageActionEnum.DOWNLOADED_BULK_ATTACHMENTS.name,
                })
            );
        }
    },
    generateExportAttachmentUsageLog: (currentCaseId, attachment) => {
        const { sourceDescription, entityType, entityId } = attachment;
        const file = getAttachmentFile(attachment);
        const entityisFromCase =
            entityType === EntityTypeEnum.CASE_NOTE.name || entityType === EntityTypeEnum.TASK.name;

        const usageLog = {
            primaryEntityType: entityisFromCase ? EntityTypeEnum.CASE.name : entityType,
            primaryEntityTitle: `${sourceDescription}: ${file.id} - ${file.originalFileName}`,
            action: UsageActionEnum.DOWNLOADED_ATTACHMENT.name,
            primaryEntityId: entityisFromCase ? currentCaseId : entityId,
            sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
        };
        dispatch(createUsageLog(usageLog));
    },
    setSort: (sortKey) => dispatch(setAttachmentSort(sortKey)),
    toggleView: () => dispatch(toggleAttachmentView()),
    openImageGalleryLightbox: (images, index, caseId) =>
        dispatch(
            openImageGalleryLightbox(images, index, {
                sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
                primaryEntityId: caseId,
            })
        ),
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        openLightbox: ({ openImageGalleryLightbox, dateTimeFormatter }) => (
            attachments,
            attachmentId,
            caseId
        ) => () => {
            const index = findIndex(attachments, { id: attachmentId });
            openImageGalleryLightbox(
                convertAttachmentViewModelsForLightboxGallery(attachments, dateTimeFormatter),
                index,
                caseId
            );
        },
    })
)(ContextCaseAttachments);

import { createSelector } from 'reselect';

import { getRenSequenceNumberSelector } from '../data';
import formatRenSequenceNumber from '../../utils/formatRenSequenceNumber';

/**
 * Compute the REN-Sequeuce Number string for the given master item as contexted
 *   to the given report. The display label for this string is "Item ID #", yet
 *   it has nothing to do with the item's id.
 * @param  {number} masterItemId
 * @param  {number} reportId
 * @return {string}
 */
export const formatRenSequenceNumberSelector = createSelector(
    getRenSequenceNumberSelector,
    (getRenSequenceNumber) => (masterItemId, reportId) => {
        const { reportingEventNumber, sequenceNumber } = getRenSequenceNumber(
            masterItemId,
            reportId
        );

        return formatRenSequenceNumber(reportingEventNumber, sequenceNumber);
    }
);

/**
 * A function for sorting item profiles within the same report by REN-Sequence Number. (REN is not
 *   actually in this sorting logic since all the items have the same REN.)
 * @param  {Object} itemProfile
 * @return {number}
 */
export const sortItemProfilesSelector = createSelector(
    getRenSequenceNumberSelector,
    (getRenSequenceNumber) => ({ masterItemId, ownerId }) =>
        getRenSequenceNumber(masterItemId, ownerId).sequenceNumber
);

import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { map, sortBy } from 'lodash';

import { NibrsReportSegmentsDescription, NibrsSegmentsErrorDescription } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';

import rmsTheme from '../../../../core/styles/theme';
import _ExpandCollapseButton from '../../../../../legacy-redux/components/core/ExpandCollapseButton';

// width and padding: awkward hack to center icon button within focus borders
// margin: shift focus border within container
const ExpandCollapseButton = styled(_ExpandCollapseButton)`
    width: 28px;
    padding: 0px 5px;
    margin: 1px 1px 0 0;
`;

const ErrorsWrapper = styled.div`
    display: inline-block;
    padding-right: 10px;
    vertical-align: top;
    width: ${(props: { hasSibling: boolean }) => (props.hasSibling ? '95%' : '100%')};
`;

const ErrorWrapper = styled.div`
    margin-top: 30px;

    &:first-child {
        margin-top: 0;
    }
`;

type ErrorInfoWrapperProps = {
    theme?: typeof rmsTheme;
    isError?: boolean;
    isIncluded?: boolean;
};

const ErrorInfoWrapper = styled.div<ErrorInfoWrapperProps>`
    font: ${(props) => {
        const { theme, isError } = props;
        const { fontWeights, fontFamilies } = theme;

        const fontWeight = isError ? fontWeights.regular : fontWeights.semiBold;

        return `${fontWeight} var(--arc-fontSizes-md) ${fontFamilies.proximaNova}`;
    }};
    color: ${(props) => {
        const { theme, isError, isIncluded } = props;
        const { colors } = theme;

        let color;
        if (isIncluded) {
            color = colors.mediumLightGrey;
        } else if (isError) {
            color = colors.red;
        } else {
            color = colors.darkGrey;
        }
        return color;
    }};
    margin-top: 6px;

    &:first-child {
        margin-top: 0;
    }
`;

const MoreErrorsWrapper = styled.div<{ theme?: typeof rmsTheme }>`
    margin-top: 10px;
    font: ${(props) => {
        const { theme } = props;
        const { fontWeights, fontFamilies } = theme;
        const fontWeight = fontWeights.regular;
        return `${fontWeight} var(--arc-fontSizes-md) ${fontFamilies.proximaNova}`;
    }};
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const strings = componentStrings.compliance.ComplianceExportErrorsCell;

function createErrorComponents(
    { nibrsErrorCode, nibrsErrorMessage, rmsSuggestionMessage }: NibrsSegmentsErrorDescription,
    index: number
) {
    const rmsSuggestionMessages = rmsSuggestionMessage && rmsSuggestionMessage.split('\n');
    return (
        // we have no better key than the index here, sine each error segment
        // only contains the reportId and the two props above
        <ErrorWrapper key={index}>
            <ErrorInfoWrapper isError={true} isIncluded={!!nibrsErrorCode}>
                {nibrsErrorMessage}
            </ErrorInfoWrapper>
            {map(rmsSuggestionMessages, (message, index) => (
                <ErrorInfoWrapper key={index}>{message}</ErrorInfoWrapper>
            ))}
        </ErrorWrapper>
    );
}

type ComplianceExportErrorsCellProps = {
    errorSegment?: NibrsReportSegmentsDescription;
};

const ComplianceExportErrorsCell: React.FC<ComplianceExportErrorsCellProps> = (props) => {
    const { errorSegment } = props;
    const [showAllErrors, setShowAllErrors] = useState(false);

    const toggleShowAllErrors = useCallback(() => {
        setShowAllErrors((showAllErrors) => !showAllErrors);
    }, [setShowAllErrors]);

    const numTotalErrors = errorSegment?.errors.length || 0;
    const hasMoreThanOneError = numTotalErrors > 1;
    const sortedErrors = sortBy(errorSegment?.errors, 'nibrsErrorCode');
    const errorsToShow =
        showAllErrors || !hasMoreThanOneError ? sortedErrors : sortedErrors.slice(0, 1);

    return (
        <>
            <ErrorsWrapper hasSibling={hasMoreThanOneError}>
                {numTotalErrors ? errorsToShow.map(createErrorComponents) : strings.noErrors}
                {hasMoreThanOneError && !showAllErrors && (
                    <MoreErrorsWrapper>{strings.moreErrors(numTotalErrors - 1)}</MoreErrorsWrapper>
                )}
            </ErrorsWrapper>
            {hasMoreThanOneError && (
                <ExpandCollapseButton
                    collapsed={!showAllErrors}
                    setCollapsed={toggleShowAllErrors}
                />
            )}
        </>
    );
};

export default ComplianceExportErrorsCell;

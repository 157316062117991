import React from 'react';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    PROPERTY_STATUS_STATUS_DATE_UTC,
    PROPERTY_STATUS_INTAKE_PERSON,
    PROPERTY_STATUS_STATEMENT_OF_FACTS,
    DISPLAY_ONLY_CUSTODY_LABEL,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';

const strings = componentStrings.reports.core.PropertyStatusSummary;
const { alcohol, vehicle } = globalAttributes.itemType;

/**
 * Summary mode for a property status in React.
 *
 * Vehicle quantity must be 1 and hidden, unless it has a legacy or migrated value that is not 1.
 */
export default function PropertyStatusSummary({
    statusDateUtc,
    recoveredLocation,
    quantity,
    reasonForPoliceCustodyAttrId,
    recoveredByOfficerFullName,
    dropOffStorageLocation,
    declaredValue,
    wasContainerOpen,
    measurementUnitsAttrId,
    itemTypeAttrId,
    intakePerson,
    statementOfFacts,
    labelWidth,
    contentWidth,
}) {
    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];
    return (
        <div>
            <SummaryList labelWidth={labelWidth} contentWidth={contentWidth}>
                <SummaryRowDate fieldName={PROPERTY_STATUS_STATUS_DATE_UTC} date={statusDateUtc} />
                <SummaryRow label={strings.labels.declaredValue} children={declaredValue} />
                {(itemTypeAttrId !== vehicle || quantity !== 1) && (
                    <SummaryRow
                        label={strings.labels.quantity}
                        children={
                            measurementUnitsAttrId
                                ? `${quantity} ${measurementUnitsAttrId}`
                                : quantity
                        }
                    />
                )}
                {itemTypeAttrId === alcohol && (
                    <SummaryRow
                        label={strings.labels.wasContainerOpen}
                        children={wasContainerOpen}
                    />
                )}
                <SummaryRow
                    label={strings.labels.reasonForPoliceCustody(custodyLabel)}
                    children={reasonForPoliceCustodyAttrId}
                />
                <SummaryRow
                    label={strings.labels.recoveringOfficer}
                    children={recoveredByOfficerFullName}
                />
                <SummaryRow
                    label={strings.labels.dropOffStorageLocation}
                    children={dropOffStorageLocation || strings.inPoliceCustody(custodyLabel)}
                />
                <SummaryRow label={strings.labels.recoveredLocation} children={recoveredLocation} />
                <SummaryRow fieldName={PROPERTY_STATUS_INTAKE_PERSON} children={intakePerson} />
                <SummaryRow
                    fieldName={PROPERTY_STATUS_STATEMENT_OF_FACTS}
                    children={statementOfFacts}
                />
            </SummaryList>
        </div>
    );
}

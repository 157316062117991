import { isArray } from 'lodash';
import getUserOptOutsResource from '../../../user-opt-outs/resources/notificationPreferencesResource';

const STORE_NOTIFICATION_TYPES = 'notification-types/STORE_NOTIFICATION_TYPES';
const LOAD_NOTIFICATION_TYPES_START = 'notification-types/LOAD_NOTIFICATION_TYPES_START';
const LOAD_NOTIFICATION_TYPES_SUCCESS = 'notification-types/LOAD_NOTIFICATION_TYPES_SUCCESS';
const LOAD_NOTIFICATION_TYPES_FAILURE = 'notification-types/LOAD_NOTIFICATION_TYPES_FAILURE';

// ACTIONS
function storeNotificationTypes(notificationTypes) {
    return {
        type: STORE_NOTIFICATION_TYPES,
        payload: notificationTypes,
    };
}
function loadNotificationTypesStart() {
    return {
        type: LOAD_NOTIFICATION_TYPES_START,
    };
}
function loadNotificationTypesSuccess(notificationTypes) {
    return {
        type: LOAD_NOTIFICATION_TYPES_SUCCESS,
        payload: notificationTypes,
    };
}
function loadNotificationTypesFailure(errorMessage) {
    return {
        type: LOAD_NOTIFICATION_TYPES_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Load notification types for the current department
 * @return {Promise<Object[]>}
 */
export function loadNotificationTypes() {
    const resource = getUserOptOutsResource();

    return function (dispatch) {
        dispatch(loadNotificationTypesStart());
        return resource
            .getNotificationTypes()
            .then((notificationTypes) => {
                dispatch(storeNotificationTypes(notificationTypes));
                dispatch(loadNotificationTypesSuccess(notificationTypes));
                return notificationTypes;
            })
            .catch((err) => {
                dispatch(loadNotificationTypesFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const notificationTypesSelector = (state) => state.data.notificationTypes;

export default function notificationTypesReducer(state = [], action) {
    switch (action.type) {
        case STORE_NOTIFICATION_TYPES:
            const notificationTypes = isArray(action.payload) ? action.payload : [action.payload];
            return notificationTypes; // array of strings
        default:
            return state;
    }
}

import { EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get, head } from 'lodash';

import { createFormConfiguration, createFieldset, _Form } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';

import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { towVehicleByReportIdSelector } from '~/client-common/core/domain/tow-vehicles/state/data';
import { towVehicleCheckInByReportIdSelector } from '~/client-common/core/domain/tow-vehicle-check-ins/state/data';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const formName = RefContextEnum.FORM_TOW_VEHICLE_CHECK_IN.name;
export const TOW_VEHICLE_STORAGE_LOCATION_PATH = 'towVehicleStorageLocation';
const TOW_VEHICLE_STORAGE_LOCATION_DESCRIPTION_PATH = 'description';
const TOW_VEHICLE_STORAGE_LOCATION_POSITION_ATTR_ID_PATH = 'positionAttrId';
export const TOW_VEHICLE_STORAGE_LOCATION_DESCRIPTION_FULL_PATH = `${TOW_VEHICLE_STORAGE_LOCATION_PATH}.${TOW_VEHICLE_STORAGE_LOCATION_DESCRIPTION_PATH}`;
export const TOW_VEHICLE_STORAGE_LOCATION_POSITION_ATTR_ID_FULL_PATH = `${TOW_VEHICLE_STORAGE_LOCATION_PATH}.${TOW_VEHICLE_STORAGE_LOCATION_POSITION_ATTR_ID_PATH}`;
export const getTowVehicleCheckInForm = () => formsRegistry.get(formName);

export const createTowVehicleCheckInForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            id: {},
            lotLocation: {
                fieldName: fields.TOW_VEHICLE_CHECK_IN_LOT_LOCATION,
            },
            damageOnVehicle: {
                fieldName: fields.TOW_VEHICLE_CHECK_IN_DAMAGE_ON_VEHICLE,
            },
            licenseInVehicle: {
                fieldName: fields.TOW_VEHICLE_CHECK_IN_LICENSE_IN_VEHICLE,
            },
            insuranceInVehicle: {
                fieldName: fields.TOW_VEHICLE_CHECK_IN_INSURANCE_IN_VEHICLE,
            },
            registrationInVehicle: {
                fieldName: fields.TOW_VEHICLE_CHECK_IN_REGISTRATION_IN_VEHICLE,
            },
            vehicleHeldAsEvidence: {
                fieldName: fields.TOW_VEHICLE_CHECK_IN_VEHICLE_HELD_AS_EVIDENCE,
            },
            [TOW_VEHICLE_STORAGE_LOCATION_PATH]: createFieldset({
                fields: {
                    locationId: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_STORAGE_LOCATION_LOCATION_ID,
                    },
                    [TOW_VEHICLE_STORAGE_LOCATION_POSITION_ATTR_ID_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_STORAGE_LOCATION_POSITION_ATTR_ID,
                    },
                    [TOW_VEHICLE_STORAGE_LOCATION_DESCRIPTION_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_STORAGE_LOCATION_DESCRIPTION,
                    },
                },
            }),
        }),
    });
};

export const buildTowVehicleCheckInCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const towVehicleByReportId = towVehicleByReportIdSelector(state);
    const towVehicleCheckInByReportId = towVehicleCheckInByReportIdSelector(state);
    const locationEntityLinksWhere = locationEntityLinksWhereSelector(state);
    const { id: towVehicleId } = towVehicleByReportId(reportId);

    const {
        id: towVehicleCheckInId,
        lotLocation,
        damageOnVehicle,
        licenseInVehicle,
        insuranceInVehicle,
        registrationInVehicle,
        vehicleHeldAsEvidence,
    } = towVehicleCheckInByReportId(reportId);
    const towVehicleStorageLocation = head(
        locationEntityLinksWhere({
            linkType: LinkTypesEnum.TOW_VEHICLE_STORAGE_LOCATION,
            entityType: EntityTypeEnum.RMS_TOW_VEHICLE.name,
            entityId: towVehicleId,
        })
    );

    return {
        id: towVehicleCheckInId,
        lotLocation,
        damageOnVehicle,
        licenseInVehicle,
        insuranceInVehicle,
        registrationInVehicle,
        vehicleHeldAsEvidence,
        [TOW_VEHICLE_STORAGE_LOCATION_PATH]: towVehicleStorageLocation,
    };
};

export const buildTowVehicleCheckInCardBundle = ({ reportId, form }) => {
    const { model } = form.getState();
    const {
        id: towVehicleCheckInId,
        lotLocation,
        damageOnVehicle,
        licenseInVehicle,
        insuranceInVehicle,
        registrationInVehicle,
        vehicleHeldAsEvidence,
        [TOW_VEHICLE_STORAGE_LOCATION_PATH]: towVehicleStorageLocation,
    } = model;

    const towVehicleCheckInForBundle = {
        id: towVehicleCheckInId,
        reportId,
        lotLocation,
        damageOnVehicle,
        licenseInVehicle,
        insuranceInVehicle,
        registrationInVehicle,
        vehicleHeldAsEvidence,
    };

    // Only preserve the `LocationEntityLink` if the form has a valid
    // `LocationEntityLink`; use `locationId` to validate.
    // This is because both initializing and adding-then-removing a
    // location will cause the form model state to correctly reset with
    // `undefined` values for `locationId` & `description`, but the BE
    // is expecting a `NULL` `locationEntityLink` for processing,
    // not an "empty" one.
    const filteredTowVehicleStorageLocation = isUndefinedOrNull(
        get(towVehicleStorageLocation, 'locationId')
    )
        ? undefined
        : towVehicleStorageLocation;

    return {
        towVehicleCheckIn: towVehicleCheckInForBundle,
        locationEntityLink: filteredTowVehicleStorageLocation,
    };
};

export const refreshTowVehicleCheckInForm = ({ reportId }) => (dispatch) => {
    const form = getTowVehicleCheckInForm();
    const formModel = dispatch(buildTowVehicleCheckInCardFormModel({ reportId }));
    form.set('', formModel);
};

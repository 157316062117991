import { ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';

/**
 * These link types allow 1 task : many entity relationships.
 *   Contrasted with CASE/WARRANT/TASK where we only allow 1 task : 1 owner relationship.
 */
export const allowedTaskLinkEntityTypes = [
    EntityTypeEnum.ITEM_PROFILE.name,
    EntityTypeEnum.PERSON_PROFILE.name,
    EntityTypeEnum.REPORT.name,
];

/**
 * The TaskEntitiesLinkSidePanel only allows 'search to add' for these entity types.
 */
export const allowedTaskLinkEntitySearchTypes = [
    ElasticSearchTypeEnum.PERSON.name,
    ElasticSearchTypeEnum.PROPERTY.name,
    ElasticSearchTypeEnum.REPORT.name,
    ElasticSearchTypeEnum.VEHICLE.name,
];

export enum TaskDashboardSearchResultsParents {
    CASE_TASKS,
    ENTITY_PROFILE_TASKS,
    TASK_DASHBOARD,
}

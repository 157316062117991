import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import {
    ElasticItemAttribute,
    ElasticNameAttribute,
    ItemAttribute,
    NameAttribute,
} from '@mark43/rms-api';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { formatAttributeWithOther } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ThemeColorsT } from '../../../utils/getThemeColor';

const { PersonLabels, VehicleLabels } = componentStrings.core;
const globalPriorityAttributes = globalAttributes.personLabelPriority;

const { priority1, priority2, priority3, priority4 } = globalPriorityAttributes;

// Deprecate this when RMS_VEHICLE_CAUTIONS_ENABLED FF is torn down
export const labelColorProps = (
    priorityAttrId: number | undefined
): {
    backgroundColor: ThemeColorsT | string;
    borderColor: ThemeColorsT | string;
} => {
    switch (priorityAttrId) {
        case priority1:
            return { backgroundColor: 'rgba(255, 90, 0, 0.36)', borderColor: '#FF5A00' };
        case priority2:
        case priority3:
        case priority4:
        default:
            return { backgroundColor: 'extraLightGrey', borderColor: 'mediumGrey' };
    }
};

export enum ArcPriorityEnum {
    PriorityOne = 1,
    PriorityTwo = 2,
    PriorityThree = 3,
    PriorityFour = 4,
}

const mapPriorityAttributeIdToArcPriority = (priorityAttrId?: number) => {
    switch (priorityAttrId) {
        case priority1:
            return ArcPriorityEnum.PriorityOne;
        case priority2:
        case priority3:
        case priority4:
        default:
            return ArcPriorityEnum.PriorityFour;
    }
};

const getUniqueSortedItems = (items: PrioritizedLabelAttribute[]) => {
    return uniqBy(
        sortBy(items, ['priorityAttrId', 'formattedCategoryAttribute']),
        'formattedCategoryAttribute'
    );
};

export type PrioritizedLabelAttribute = {
    formattedCategoryAttribute: string;
    priorityAttrId: number | undefined;
    priority: ArcPriorityEnum;
};

export const getPrioritizedPersonLabelAttributes = ({
    nameAttributes,
    elasticNameAttributes,
    isJuvenile,
    isVulnerable,
    isActiveTarget,
    dateVulnerableTo,
    hasPotentialActiveWarrant,
    isSuspectedGangMember,
    priority1Only,
    formatAttributeById,
    parentAttributeIdByAttributeId,
}: {
    nameAttributes?: NameAttribute[];
    elasticNameAttributes?: ElasticNameAttribute[];
    isJuvenile?: boolean;
    isVulnerable?: boolean;
    dateVulnerableTo?: string;
    hasPotentialActiveWarrant?: boolean;
    isSuspectedGangMember?: boolean;
    isActiveTarget?: boolean;
    priority1Only?: boolean;
    formatAttributeById: ReturnType<typeof formatAttributeByIdSelector>;
    parentAttributeIdByAttributeId: ReturnType<typeof parentAttributeIdByAttributeIdSelector>;
}): PrioritizedLabelAttribute[] => {
    const isDateVulnerableToValid = dateVulnerableTo
        ? new Date(dateVulnerableTo) > new Date()
        : true;

    const items = [
        // first pick the important properties from the 2 models
        ...(nameAttributes ?? []).map(({ attributeId, description }) => ({
            attributeId,
            displayValue: formatAttributeById(attributeId),
            description,
        })),
        ...(elasticNameAttributes ?? []).map(({ nameAttrDetail, description }) => ({
            attributeId: nameAttrDetail?.id,
            displayValue: nameAttrDetail?.displayValue,
            description,
        })),
    ]
        .map(({ attributeId, displayValue, description }) => {
            if (!attributeId) {
                return undefined;
            }

            // parent of PERSON_LABEL_ATTRIBUTES is PERSON_LABEL_CATEGORY
            const personLabelCategoryAttributeId = parentAttributeIdByAttributeId(attributeId);

            if (!personLabelCategoryAttributeId) {
                return undefined;
            }

            const formattedCategoryAttribute = displayValue
                ? formatAttributeWithOther(displayValue, description)
                : '';

            if (!formattedCategoryAttribute) {
                return undefined;
            }

            return {
                formattedCategoryAttribute,
                // parent of PERSON_LABEL_CATEGORY is PERSON_LABEL_PRIORITY
                priorityAttrId: parentAttributeIdByAttributeId(personLabelCategoryAttributeId),
            };
        })
        .concat([
            isJuvenile
                ? {
                      formattedCategoryAttribute: PersonLabels.juvenile,
                      priorityAttrId: priority1,
                  }
                : undefined,
            hasPotentialActiveWarrant
                ? {
                      formattedCategoryAttribute: PersonLabels.potentialActiveWarrant,
                      priorityAttrId: priority1,
                  }
                : undefined,
            isSuspectedGangMember
                ? {
                      formattedCategoryAttribute: PersonLabels.suspectedGangMember,
                      priorityAttrId: priority2,
                  }
                : undefined,
            isVulnerable && isDateVulnerableToValid
                ? {
                      formattedCategoryAttribute: isJuvenile
                          ? PersonLabels.vulnerableChild
                          : PersonLabels.vulnerableAdult,
                      priorityAttrId: priority1,
                  }
                : undefined,
            isActiveTarget
                ? {
                      formattedCategoryAttribute: PersonLabels.activeTarget,
                      priorityAttrId: priority1,
                  }
                : undefined,
        ])
        .filter((item): item is Omit<PrioritizedLabelAttribute, 'priority'> => item !== undefined)
        .map((item) => ({
            ...item,
            priority: mapPriorityAttributeIdToArcPriority(item.priorityAttrId),
        }));

    return getUniqueSortedItems(items).filter((item) =>
        priority1Only ? item.priorityAttrId === priority1 : true
    );
};

export const getPrioritizedVehicleLabelAttributes = ({
    itemAttributes,
    elasticItemAttributes,
    isStolen,
    isActiveTarget,
    priority1Only,
    formatAttributeById,
    parentAttributeIdByAttributeId,
}: {
    itemAttributes?: ItemAttribute[];
    elasticItemAttributes?: ElasticItemAttribute[];
    isStolen?: boolean;
    isActiveTarget?: boolean;
    priority1Only?: boolean;
    formatAttributeById: ReturnType<typeof formatAttributeByIdSelector>;
    parentAttributeIdByAttributeId: ReturnType<typeof parentAttributeIdByAttributeIdSelector>;
}): PrioritizedLabelAttribute[] => {
    const items = [
        // first pick the important properties from the 2 models
        ...(itemAttributes ?? []).map(({ attributeId, description }) => ({
            attributeId,
            displayValue: formatAttributeById(attributeId),
            description,
        })),
        ...(elasticItemAttributes ?? []).map(({ itemAttrDetail, description }) => ({
            attributeId: itemAttrDetail?.id,
            displayValue: itemAttrDetail?.displayValue,
            description,
        })),
    ]
        .map(({ attributeId, displayValue, description }) => {
            if (!attributeId) {
                return undefined;
            }
            const parentAttributeId = parentAttributeIdByAttributeId(attributeId);

            if (!parentAttributeId) {
                return undefined;
            }

            const formattedCategoryAttribute = displayValue
                ? formatAttributeWithOther(displayValue, description)
                : '';

            if (!formattedCategoryAttribute) {
                return undefined;
            }

            return {
                formattedCategoryAttribute,
                // parent of VEHICLE_LABEL_ATTRIBUTES is VEHICLE_LABEL_CATEGORY
                // parent of VEHICLE_LABEL_CATEGORY is PERSON_LABEL_PRIORITY
                priorityAttrId: parentAttributeIdByAttributeId(parentAttributeId),
            };
        })
        .concat([
            isStolen
                ? {
                      formattedCategoryAttribute: VehicleLabels.isStolen,
                      priorityAttrId: priority1,
                  }
                : undefined,
            isActiveTarget
                ? {
                      formattedCategoryAttribute: VehicleLabels.isActiveTarget,
                      priorityAttrId: priority1,
                  }
                : undefined,
        ])
        .filter((item): item is Omit<PrioritizedLabelAttribute, 'priority'> => item !== undefined)
        .map((item) => ({
            ...item,
            priority: mapPriorityAttributeIdToArcPriority(item.priorityAttrId),
        }));

    return getUniqueSortedItems(items).filter((item) =>
        priority1Only ? item.priorityAttrId === priority1 : true
    );
};

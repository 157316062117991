import { chain, isArray, isObject, map } from 'lodash';
import { createSelector } from 'reselect';

import {
    formatNibrsCode,
    mapNibrsCodesToOptions,
} from '../../../../../helpers/offenseCodesHelpers';

import { nibrsOffenseCodesSelector, nibrsOffenseCodeByCodeSelector } from '../data';

export const nibrsOffenseCodeIdOptionsSelector = createSelector(
    nibrsOffenseCodesSelector,
    (nibrsOffenseCodes) => mapNibrsCodesToOptions(nibrsOffenseCodes, 'id')
);

/**
 * Format the display string for a NIBRS offense code(s).
 * @param  {string|string[]|Object|Object[]} code Can be objects because
 *   `ElasticOffenseQuery` in the API response gives objects rather than
 *   strings.
 * @return {string}
 */
export const formatNibrsOffenseCodeByCodeSelector = createSelector(
    nibrsOffenseCodeByCodeSelector,
    (nibrsOffenseCodeByCode) => (code) =>
        !isArray(code)
            ? formatNibrsCode(isObject(code) ? code : nibrsOffenseCodeByCode[code])
            : map(code, (code) =>
                  formatNibrsCode(isObject(code) ? code : nibrsOffenseCodeByCode[code])
              ).join(', ')
);

/**
 * Format the display string for a NIBRS offense code(s).
 */
export const formatNibrsOffenseCodeByIdSelector = createSelector(
    nibrsOffenseCodesSelector,
    (nibrsOffenseCodes) => (id) =>
        !isArray(id)
            ? formatNibrsCode(nibrsOffenseCodes[id])
            : chain(id)
                  .map((id) => formatNibrsCode(nibrsOffenseCodes[id]))
                  .sortBy()
                  .join(', ')
                  .value()
);

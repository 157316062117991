import factory from './actionTypesFactory';

export default factory('departmentprofileadmin', [
    'DEPARTMENT_PROFILE_ADMIN_PAGELOAD',
    'SAVE_DEPARTMENT_PROFILE_SUCCESS',
    'SAVE_DEPARTMENT_PROFILE_FAILURE',
    'RESET_DEPARTMENT_FORM',
    'LOAD_DEPARTMENT_PROFILE_SUCCESS',
    'LOAD_DEPARTMENT_PROFILE_FAILURE',
    'LOAD_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS',
    'SAVE_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS',
    'SAVE_DEPARTMENT_PROFILE_ATTACHMENTS_FAILURE',
]);

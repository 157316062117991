import { RipaSubjectOffenseCode } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'ripaSubjectOffenseCodes';

const ripaSubjectOffenseCodesLinksModule = createLinkModule<RipaSubjectOffenseCode>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// Selectors
export const ripaSubjectOffenseCodesSelector =
    ripaSubjectOffenseCodesLinksModule.selectors.linksSelector;
export const ripaSubjectOffenseCodesWhereSelector =
    ripaSubjectOffenseCodesLinksModule.selectors.linksWhereSelector;

export default ripaSubjectOffenseCodesLinksModule.reducerConfig;

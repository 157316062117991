import { ImagesResource } from '@mark43/rms-api';
import { req } from '../../../lib/ajax';
import { createResource } from '../../../lib/resources/Resource';

export const imageResource = createResource({
    name: 'Image Resource',
    methods: {
        uploadImage(formData: FormData) {
            return req<ImagesResource.Upload1>({
                method: 'POST',
                url: 'images',
                params: { 'my-file': undefined, image_sizes: [] },
                data: formData,
            });
        },
    },
});

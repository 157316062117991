import { compact, get, isUndefined } from 'lodash';
import { ComplianceGroupEnum, LinkTypesEnum, RefContextEnum } from '@mark43/rms-api';

import { offenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { ukOffenseCodeExtensionByOffenseCodeIdSelector } from '~/client-common/core/domain/uk-offense-code-extensions/state/data';
import { personProfilesSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';
import { dateOfBirthToAge } from '~/client-common/core/dates/utils/dateHelpers';

import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { currentReportIdSelector } from '../../../legacy-redux/selectors/reportSelectors';
import { logWarning } from '../../../core/logging';
import { offenseFormConfiguration } from '../../reports/core/state/forms/offenseForm';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { useFormGetter } from '../forms/hooks/useFormGetter';

const warningPrefix = 'Rule Field Getter getCountFailedUkOffenseCodeAgeComparisons:';

export const getIsAgeInvalidForOffenseCode = (getState: () => RootState) => (
    offenseId: number | undefined,
    {
        linkType,
        offenseCodeKey,
    }: {
        linkType: 'SUSPECT_IN_OFFENSE' | 'VICTIM_IN_OFFENSE' | 'OFFENDER_IN_OFFENSE';
        offenseCodeKey: 'aggrievedMinAge' | 'offenderMinAge' | 'aggrievedMaxAge' | 'offenderMaxAge';
    }
) => {
    if (!linkType || !offenseCodeKey) {
        logWarning(`${warningPrefix} missing rule condition argument`, {
            linkType,
            offenseCodeKey,
        });
        return false;
    }
    let comparisonType: 'LESS_THAN' | 'GREATER_THAN';

    switch (offenseCodeKey) {
        case 'aggrievedMinAge':
        case 'offenderMinAge':
            comparisonType = 'LESS_THAN';
            break;
        case 'aggrievedMaxAge':
        case 'offenderMaxAge':
            comparisonType = 'GREATER_THAN';
            break;
        default:
            logWarning(`${warningPrefix} offenseCodeKey does not have an implementation`, {
                offenseCodeKey,
            });
            return false;
    }

    const { getForm } = useFormGetter();
    const form = getForm<
        ReturnType<typeof offenseFormConfiguration>,
        typeof RefContextEnum.FORM_OFFENSE.name
    >(RefContextEnum.FORM_OFFENSE.name, offenseId);

    if (typeof form === 'undefined') {
        return false;
    }

    const state = getState();
    const { offense, links } = form.getState().model;
    const offenseCodeId = offense?.offenseCodeId;
    if (
        !offenseCodeId ||
        (typeof offenseCodeId !== 'string' && typeof offenseCodeId !== 'number')
    ) {
        return false;
    }
    const currentReportId = currentReportIdSelector(state);
    if (!currentReportId) {
        return false;
    }
    const eventDetail = eventDetailByReportIdSelector(state)(currentReportId);
    const eventDate =
        offense?.offenseDateUtc || eventDetail?.eventStartUtc || eventDetail?.createdDateUtc;

    const offenseCode = offenseCodeByIdSelector(state)(offenseCodeId);

    if (isUndefined(offenseCode)) {
        logWarning(`${warningPrefix} missing Offense code`, { offenseCodeId });
        throw new Error(`${warningPrefix} missing Offense code offenseCodeId: ${offenseCodeId}`);
    }

    let offenseCodeComparisonValue: number | undefined;
    const departmentProfile = currentUserDepartmentProfileSelector(state);
    if (departmentProfile?.complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name) {
        const offenseCodeExtension = ukOffenseCodeExtensionByOffenseCodeIdSelector(state)(
            offenseCode.id
        );

        offenseCodeComparisonValue = get(
            { ...offenseCodeExtension, ...offenseCode },
            offenseCodeKey
        );
    } else {
        offenseCodeComparisonValue = get(offenseCode, offenseCodeKey);
    }

    if (typeof offenseCodeComparisonValue !== 'number' || offenseCodeComparisonValue < 0) {
        return false;
    }

    const linkTypeId = LinkTypesEnum[linkType];

    let relevantLinks;
    switch (linkTypeId) {
        case LinkTypesEnum.OFFENDER_IN_OFFENSE:
            relevantLinks = links?.offenders;
            break;
        case LinkTypesEnum.SUSPECT_IN_OFFENSE:
            relevantLinks = links?.suspects;
            break;
        case LinkTypesEnum.VICTIM_IN_OFFENSE:
            relevantLinks = links?.victims;
            break;
        default:
            logWarning(`${warningPrefix}: linkType "${linkType}" does not have an implementation`);
            throw new Error(
                `${warningPrefix}: linkType "${linkType}" does not have an implementation`
            );
    }
    // @ts-expect-error relevantLinks used before being assigned. the above switch either assigns it or returns false
    // this should also be fixed by typing offenseForm.js
    if (!relevantLinks?.length) {
        return false;
    }
    const nameIds = compact(relevantLinks.map(({ nameId }) => nameId));
    const personProfiles = personProfilesSelector(state);

    switch (comparisonType) {
        case 'LESS_THAN':
            for (let i = 0; i < nameIds.length; i++) {
                const nameId = nameIds[i];
                // @ts-expect-error nameId type causing an error, offenseForm.js isn't typed
                const dateOfBirth = personProfiles[nameId]?.dateOfBirth;
                if (dateOfBirth) {
                    // @ts-expect-error eventDate type causing an error, offenseForm.js isn't typed
                    const age = dateOfBirthToAge(dateOfBirth, eventDate);
                    if (age < offenseCodeComparisonValue) {
                        return true;
                    }
                }
            }
            return false;
        case 'GREATER_THAN':
            for (let i = 0; i < nameIds.length; i++) {
                const nameId = nameIds[i];
                // @ts-expect-error nameId type causing an error, offenseForm.js isn't typed
                const dateOfBirth = personProfiles[nameId]?.dateOfBirth;
                if (dateOfBirth) {
                    // @ts-expect-error eventDate type causing an error, offenseForm.js isn't typed
                    const age = dateOfBirthToAge(dateOfBirth, eventDate);
                    if (age > offenseCodeComparisonValue) {
                        return true;
                    }
                }
            }
            return false;
        default:
            return false;
    }
};

import React from 'react';
import styled from 'styled-components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import createElement from '../../../core/utils/recompose.createElement';

import ImageCell from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/ImageCell';
import { PersonProfileVehicleCell } from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/PersonProfileVehicleCell';
import EntityProfileLinkIndicator from './EntityProfileLinkIndicator';

const EntityProfileVehicleSummaryStyled = styled.div`
    border-bottom: 2px solid ${(props) => props.theme.colors.extraLightGrey};
    margin-bottom: var(--arc-space-3);
    padding-bottom: var(--arc-space-3);

    .elastic-vehicle-profile-photo {
        border-radius: 10px;

        i::before {
            font-size: 80px;
            line-height: 80px;
        }
    }
`;

export default function EntityProfileVehiclesSummary({ hasReadAccess, elasticVehicle }) {
    // use of `createElement` is due to the fact that all `Symbol` properties
    // of an object are lost when it's "copied" (via spread syntax). This is an intended
    // effect of spread syntax / for in
    return (
        <EntityProfileVehicleSummaryStyled>
            <div className="entity-profile-summary-sibling">
                {createElement(ImageCell, { ...elasticVehicle })}
            </div>
            <div className="entity-profile-summary-sibling">
                <PersonProfileVehicleCell
                    hasReadAccess={hasReadAccess}
                    elasticVehicle={elasticVehicle}
                />
                <EntityProfileLinkIndicator
                    linkTypes={getViewModelProperties(elasticVehicle).linkTypes}
                />
            </div>
        </EntityProfileVehicleSummaryStyled>
    );
}

import { useRouterHistory } from 'react-router';
import { createHashHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';
import { clientStringifyQuery, clientParseQueryString } from '../legacy-redux/helpers/urlHelpers';
import store from '../core/store';

// we proxy the `createHistory` provided by `history` so that we can omit
// the `queryKey` (_k=jkib3l thing that can appear in the url otherwise)
function createCobaltHistory(...options) {
    return createHashHistory({ ...options });
}

export default syncHistoryWithStore(
    /* eslint-disable-next-line react-hooks/rules-of-hooks */
    useRouterHistory(createCobaltHistory)({
        parseQueryString: clientParseQueryString,
        stringifyQuery: (params) =>
            clientStringifyQuery(params, {
                snakeCaseKeys: false,
                includeQuestionMark: false,
            }),
    }),
    store
);

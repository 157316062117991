import React from 'react';
import { compact, some, find, includes, map, toLower, first } from 'lodash';
import { formatLinkTypeLetterNumber } from '~/client-common/helpers/linkTypesHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { PersonProfileSummary } from '../../../records/core/components/summaries/PersonProfileSummary';
import OrganizationProfileSummary from '../../../records/core/components/summaries/OrganizationProfileSummary';
import { LocationSummaryView } from '../../../records/core/components/summaries/locations/LocationSummaryView';
import { VehicleTitleSummary } from '../../../records/core/components/summaries/VehicleTitleSummary';
import { PropertyTitleSummary } from '../../../records/core/components/summaries/PropertyTitleSummary';
import EntitySummary from '../../../records/core/components/summaries/EntitySummary';
import testIds from '../../../../core/testIds';
import { entitySidebarConfigEnum } from '../constants/entitySidebarConfigEnum';

const strings = componentStrings.reports.core.InvolvedProfilesSidebar;

const stringsToSearchContainSearchTerm = (stringsToSearch, searchTerm) => {
    return some(stringsToSearch, (stringToSearch) => {
        return includes(toLower(stringToSearch), toLower(searchTerm));
    });
};

const getRelevantLocationLink = (links) => find(links, 'placeName') || first(links);

export const involvedProfilesSidebarConfig = [
    {
        title: strings.people,
        testId: testIds.ENTITY_SIDEBAR_PEOPLE_COLLAPSIBLE_SECTION,
        key: entitySidebarConfigEnum.PEOPLE,
        shouldShowItemFactory: (query) => ({ entity }) => {
            return !query || stringsToSearchContainSearchTerm([formatFullName(entity)], query);
        },
        Component: ({ entity, links }) => {
            const id = entity.id;
            const linkTypeLetterNumbers = joinTruthyValues(
                map(links, (link) =>
                    formatLinkTypeLetterNumber(link.linkType, link.linkTypeSequenceNumber)
                )
            );
            return (
                <PersonProfileSummary
                    personProfileId={id}
                    linkTypeLetterNumber={linkTypeLetterNumbers}
                    showExpandCollapseButton={false}
                    showCautionsAsSnapshot
                />
            );
        },
    },
    {
        title: strings.organizations,
        testId: testIds.ENTITY_SIDEBAR_ORGANIZATIONS_COLLAPSIBLE_SECTION,
        key: entitySidebarConfigEnum.ORGANIZATIONS,
        shouldShowItemFactory: (query) => ({ entity }) => {
            return !query || stringsToSearchContainSearchTerm([entity.name], query);
        },
        Component: ({ entity, links }) => {
            const id = entity.id;
            const linkTypeLetterNumbers = joinTruthyValues(
                map(links, (link) =>
                    formatLinkTypeLetterNumber(link.linkType, link.linkTypeSequenceNumber)
                )
            );
            return (
                <OrganizationProfileSummary
                    organizationProfileId={id}
                    linkTypeLetterNumber={linkTypeLetterNumbers}
                    showExpandCollapseButton={false}
                />
            );
        },
    },
    {
        title: strings.locations,
        testId: testIds.ENTITY_SIDEBAR_LOCATIONS_COLLAPSIBLE_SECTION,
        key: entitySidebarConfigEnum.LOCATIONS,
        shouldShowItemFactory: (query) => ({ entity, links }) => {
            const { names } = getViewModelProperties(entity);
            const relevantLink = getRelevantLocationLink(links);
            return (
                !query ||
                stringsToSearchContainSearchTerm(
                    compact([relevantLink.placeName, names.full]),
                    query
                )
            );
        },
        Component: ({ entity, links }) => {
            const id = entity.id;
            const relevantLink = getRelevantLocationLink(links);
            return (
                <LocationSummaryView
                    showExpandCollapseButton={false}
                    locationBundle={{
                        location: entity,
                        locationEntityLink: relevantLink,
                    }}
                    index={id}
                    hideQuickAdd={true}
                />
            );
        },
    },
    {
        title: strings.vehicles,
        testId: testIds.ENTITY_SIDEBAR_VEHICLES_COLLAPSIBLE_SECTION,
        key: entitySidebarConfigEnum.VEHICLES,
        shouldShowItemFactory: (query, { propertyTitleForItemProfile }) => ({ entity }) => {
            return (
                !query ||
                stringsToSearchContainSearchTerm(
                    compact([propertyTitleForItemProfile(entity)]),
                    query
                )
            );
        },
        Component: ({ entity }) => (
            <EntitySummary
                renderTitle={() => {
                    return <VehicleTitleSummary vehicleViewModel={entity} />;
                }}
            />
        ),
    },
    {
        title: strings.property,
        testId: testIds.ENTITY_SIDEBAR_PROPERTY_COLLAPSIBLE_SECTION,
        key: entitySidebarConfigEnum.PROPERTY,
        shouldShowItemFactory: (query, { propertyTitleForItemProfile }) => ({ entity }) => {
            return (
                !query ||
                stringsToSearchContainSearchTerm(
                    compact([propertyTitleForItemProfile(entity)]),
                    query
                )
            );
        },
        Component: ({ entity }) => (
            <EntitySummary
                renderTitle={() => {
                    return <PropertyTitleSummary propertyViewModel={entity} />;
                }}
            />
        ),
    },
];

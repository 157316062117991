import { map, unionWith, isEqual, isEmpty, every, includes } from 'lodash';
import React, { useCallback } from 'react';
import { Checkbox } from 'arc';
import { ColumnT } from '../../../../types';

import {
    EFileTableHeaderRow,
    EFileTableHeaderWrapper,
    EFileTableHeader,
    EFileTableHeaderCell,
    EFileCheckboxTableHeader,
} from '../EFileTable';
import { useMaterialAttachments } from '../../materials/EFileMaterialAttachmentsProvider';

export const EFileMaterialAttachmentsHeaderRow = ({
    columns,
    canEdit,
    materialIds,
}: {
    columns: ColumnT[];
    canEdit: boolean;
    materialIds: number[];
}) => {
    const { selectedMaterialIds, setSelectedMaterialIds } = useMaterialAttachments();

    const checkBoxOnChange = useCallback(
        (event) => {
            const checked = event.target.checked;
            const selected = checked ? unionWith(selectedMaterialIds, materialIds, isEqual) : [];
            setSelectedMaterialIds(selected);
        },
        [materialIds, selectedMaterialIds, setSelectedMaterialIds]
    );

    const isChecked =
        !isEmpty(materialIds) &&
        every(materialIds, (attachmentMaterialId) => {
            return includes(selectedMaterialIds, attachmentMaterialId);
        });

    const isCheckboxDisabled = !canEdit || isEmpty(materialIds);

    return (
        <EFileTableHeaderWrapper>
            <EFileTableHeaderRow>
                <EFileCheckboxTableHeader>
                    <Checkbox
                        isDisabled={isCheckboxDisabled}
                        isChecked={isChecked}
                        onChange={checkBoxOnChange}
                    />
                </EFileCheckboxTableHeader>
                {map(columns, (column) => {
                    return (
                        <EFileTableHeader key={column.key} width={column.width}>
                            <EFileTableHeaderCell>{column.title}</EFileTableHeaderCell>
                        </EFileTableHeader>
                    );
                })}
            </EFileTableHeaderRow>
        </EFileTableHeaderWrapper>
    );
};

import { map, sortBy, compact } from 'lodash';
import { createSelector } from 'reselect';
import { atfManufacturersSelector } from '../data';
import { formatAtfManufacturerDisplayValue } from '../../utils/atfManufacturersHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

export const atfManufacturerOptionsSelector = createSelector(
    atfManufacturersSelector,
    (atfManufacturers) => {
        return sortBy(
            map(atfManufacturers, (atfManufacturer) => {
                const { id } = atfManufacturer;
                return {
                    display: formatAtfManufacturerDisplayValue(atfManufacturer),
                    value: id,
                };
            }),
            'display'
        );
    }
);

export const formatAtfManufacturerByIdSelector = createSelector(
    atfManufacturersSelector,
    (atfManufacturers) => (id: undefined | number | number[]) => {
        const ids = Array.isArray(id) ? id : compact([id]);
        return joinTruthyValues(
            ids.map((id) => {
                return formatAtfManufacturerDisplayValue(atfManufacturers[id]);
            })
        );
    }
);

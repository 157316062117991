import _, { map, get, flatMap } from 'lodash';
import Promise from 'bluebird';
import blacksmithIntegrationResource from '../../resources/blacksmithIntegrationResource';

export const DELETE_BLACKSMITH_INTEGRATION_START = 'blacksmith/DELETE_INTEGRATION_START';
export const DELETE_BLACKSMITH_INTEGRATION_SUCCESS =
    'blacksmith/DELETE_BLACKSMITH_INTEGRATION_SUCCESS';
export const KICK_OFF_BLACKSMITH_INTEGRATION_START = 'blacksmith/KICK_OFF_INTEGRATION_START';
export const KICK_OFF_BLACKSMITH_INTEGRATION_SUCCESS =
    'blacksmith/KICK_OFF_BLACKSMITH_INTEGRATION_SUCCESS';

export function deleteBlacksmithIntegrationStart(blacksmithIntegrationId) {
    return {
        type: DELETE_BLACKSMITH_INTEGRATION_START,
        payload: {
            blacksmithIntegrationId,
        },
    };
}
export function deleteBlacksmithIntegrationSuccess() {
    return { type: DELETE_BLACKSMITH_INTEGRATION_SUCCESS };
}

export function kickOffBlacksmithIntegrationStart(blacksmithIntegrationId) {
    return {
        type: KICK_OFF_BLACKSMITH_INTEGRATION_START,
        payload: {
            blacksmithIntegrationId,
        },
    };
}
export function kickOffBlacksmithIntegrationSuccess() {
    return { type: KICK_OFF_BLACKSMITH_INTEGRATION_SUCCESS };
}

function storeIntegrationViews(integrationViews) {
    return (dispatch, getState, dependencies) => {
        const mostRecentJobRuns = _(integrationViews).map('mostRecentJobRun').compact().value();

        const entitiesToStore = {
            integrations: map(integrationViews, 'integration'),
            schedules: map(integrationViews, 'schedule'),
            mostRecentJobRuns,
            commandRecords: map(mostRecentJobRuns, 'command'),
            integrationJobRuns: map(mostRecentJobRuns, 'jobRun'),
            commandStatusHistories: flatMap(mostRecentJobRuns, 'statusHistories'),
        };

        dispatch(
            dependencies.nexus.withEntityItems(entitiesToStore, { type: 'SUPPORT_BLACKSMITH_INIT' })
        );
    };
}

export function loadAllBlacksmithIntegrations() {
    return (dispatch) => {
        blacksmithIntegrationResource
            .getAllIntegrations()
            .then((integrationViews) => {
                dispatch(storeIntegrationViews(integrationViews));
            })
            .catch(() => undefined);
    };
}

export function loadBlacksmithIntegration(integrationId) {
    return (dispatch) => {
        Promise.all([
            blacksmithIntegrationResource.getIntegrationById(integrationId),
            blacksmithIntegrationResource.getJobRuns(integrationId, 0, 15),
        ])
            .spread((integrationView, jobRuns) => {
                dispatch(storeIntegrationView(integrationView, jobRuns));
            })
            .catch(() => undefined);
    };
}

export function toggleBlacksmithIntegrationEnabled(integration) {
    return (dispatch, getState, dependencies) => {
        blacksmithIntegrationResource
            .updateIntegrationToggleEnable(integration.id, integration, !integration.isEnabled)
            .then((integration) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            integrations: [integration],
                        },
                        {
                            type: 'TOGGLE_BLACKSMITH_INTEGRATION_ENABLED',
                        }
                    )
                );
            })
            .catch(() => undefined);
    };
}

export function toggleBlacksmithIntegrationRemote(integration) {
    return (dispatch, getState, dependencies) => {
        blacksmithIntegrationResource
            .updateIntegrationToggleRemote(integration.id, integration, !integration.isRemote)
            .then((integration) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            integrations: [integration],
                        },
                        {
                            type: 'TOGGLE_BLACKSMITH_INTEGRATION_REMOTE',
                        }
                    )
                );
            })
            .catch(() => undefined);
    };
}

function storeIntegrationView(integrationView, jobRuns) {
    return (dispatch, getState, dependencies) => {
        const entitiesToStore = {
            integrations: [get(integrationView, 'integration')],
            schedules: get(integrationView, 'schedule'),
            commandRecords: map(jobRuns, 'command'),
            integrationJobRuns: map(jobRuns, 'jobRun'),
            commandStatusHistories: flatMap(jobRuns, 'statusHistories'),
        };

        dispatch(
            dependencies.nexus.withEntityItems(entitiesToStore, {
                type: 'SUPPORT_BLACKSMITH_INTEGRATION_INIT',
            })
        );
    };
}

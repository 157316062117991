import { IconNameT } from 'arc';
import Konva from 'konva';

export type ImageDimensions = {
    width: number;
    height: number;
};

export type MenuListItemsProps = {
    action: WidgetAction;
    leadingVisual: IconNameT;
    displayName: string;
};

export type FlipAction =
    | typeof widgetActions.FLIP_ITEM_HORIZONTAL
    | typeof widgetActions.FLIP_ITEM_VERTICAL;

export type ArrangeAction =
    | typeof widgetActions.BRING_ITEM_TO_FRONT
    | typeof widgetActions.SEND_ITEM_TO_BACK;

export const widgetActions = {
    BRING_ITEM_TO_FRONT: 'BRING_ITEM_TO_FRONT',
    SEND_ITEM_TO_BACK: 'SEND_ITEM_TO_BACK',
    FLIP_ITEM_HORIZONTAL: 'FLIP_ITEM_HORIZONTAL',
    FLIP_ITEM_VERTICAL: 'FLIP_ITEM_VERTICAL',
} as const;

export type WidgetAction = (typeof widgetActions)[keyof typeof widgetActions];

export type KonvaWidget = Konva.Image | Konva.Text;

export type CrashDiagramPosition = {
    x: number;
    y: number;
    offsetY?: number;
    offsetX?: number;
    rotation?: number;
    scaleX?: number;
    scaleY?: number;
    skewX?: number;
    skewY?: number;
    width?: number;
    height?: number;
};

export type Widget = ImageWidget | TextWidget;

type ImageWidget = {
    value: string;
    type: 'IMAGE' | 'BACKGROUND_IMAGE';
    id: string;
    position: CrashDiagramPosition;
    opacity: number;
    konvaInstance: undefined;
};

type TextWidget = {
    value: string;
    type: 'TEXT';
    id: string;
    position: CrashDiagramPosition;
    opacity: number;
    konvaInstance: Konva.Text;
};

export const widgetTypes = {
    IMAGE: 'IMAGE',
    TEXT: 'TEXT',
    BACKGROUND_IMAGE: 'BACKGROUND_IMAGE',
} as const;

export type WidgetType = keyof typeof widgetTypes;

type AddWidgetUserInteractionType = {
    widgetId: string;
    widgetType: WidgetType;
    type: 'ADD_WIDGET';
    konvaInstance?: Konva.Text;
    before: undefined;
    after: {
        value: string;
        position: CrashDiagramPosition;
        opacity: number;
    };
};

export type DeleteTextWidgetUserInteractionType = {
    widgetId: string;
    widgetType: 'TEXT';
    type: 'DELETE_WIDGET';
    konvaInstance: Konva.Text;
    before: {
        value: string;
        position: CrashDiagramPosition;
        opacity: number;
    };
    after: undefined;
    createdDateUtc: string;
    groupId: string;
};

export type AddTextWidgetUserInteractionType = {
    widgetId: string;
    widgetType: 'TEXT';
    type: 'ADD_WIDGET';
    konvaInstance: Konva.Text;
    before: undefined;
    after: {
        value: string;
        position: CrashDiagramPosition;
        opacity: number;
    };
    createdDateUtc: string;
    groupId: string;
};

export type DiagramUserInteractionType =
    | AddWidgetUserInteractionType
    | {
          widgetId: string;
          widgetType: WidgetType;
          type: 'TRANSFORM_WIDGET';
          konvaInstance?: Konva.Text;
          before: {
              value: undefined;
              position: CrashDiagramPosition;
              opacity: undefined;
          };
          after: {
              value: undefined;
              position: CrashDiagramPosition;
              opacity: undefined;
          };
      }
    | {
          widgetId: string;
          widgetType: WidgetType;
          type: 'SET_WIDGET_OPACITY';
          konvaInstance: undefined;
          before: {
              value: undefined;
              position: CrashDiagramPosition;
              opacity: number;
          };
          after: {
              value: undefined;
              position: CrashDiagramPosition;
              opacity: number;
          };
      }
    | {
          widgetId: string;
          widgetType: WidgetType;
          type: 'BRING_ITEM_TO_FRONT';
          konvaInstance?: Konva.Text;
          before: undefined;
          after: undefined;
      }
    | {
          widgetId: string;
          widgetType: WidgetType;
          type: 'SEND_ITEM_TO_BACK';
          konvaInstance?: Konva.Text;
          before: undefined;
          after: undefined;
      }
    | {
          widgetId: string;
          widgetType: WidgetType;
          type: 'FLIP_ITEM_HORIZONTAL';
          konvaInstance: undefined;
          before: {
              value: undefined;
              position: CrashDiagramPosition;
              opacity: undefined;
          };
          after: {
              value: undefined;
              position: CrashDiagramPosition;
              opacity: undefined;
          };
      }
    | {
          widgetId: string;
          widgetType: WidgetType;
          type: 'FLIP_ITEM_VERTICAL';
          konvaInstance: undefined;
          before: {
              value: undefined;
              position: CrashDiagramPosition;
              opacity: undefined;
          };
          after: {
              value: undefined;
              position: CrashDiagramPosition;
              opacity: undefined;
          };
      }
    | {
          widgetId: string;
          widgetType: WidgetType;
          type: 'DELETE_WIDGET';
          konvaInstance?: Konva.Text;
          before: {
              value: string;
              position: CrashDiagramPosition;
              opacity: number;
          };
          after: undefined;
      }
    | {
          widgetId: string;
          widgetType: WidgetType;
          type: 'WIDGET_VALUE_CHANGE';
          konvaInstance?: Konva.Text;
          before: {
              value: string;
              position: undefined;
              opacity: undefined;
          };
          after: {
              value: string;
              position: undefined;
              opacity: undefined;
          };
      };

export type CrashDiagramHistoryUserInteraction = {
    createdDateUtc: string;
    groupId: string;
} & DiagramUserInteractionType;

export type ExtendedAddWidgetUserInteractionType = AddWidgetUserInteractionType & {
    createdDateUtc: string;
    groupId: string;
};

import { ProductModuleEnum } from '@mark43/rms-api';
import _, { map } from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import {
    saveItemProfileIdsToOwner,
    itemProfilesByIdsSelector,
} from '~/client-common/core/domain/item-profiles/state/data';

import { runImportEvent } from '~/client-common/core/domain/import-events/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { openBox, closeBox } from '../../../../../legacy-redux/actions/boxActions';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { createModalSelector } from '../../../../core/box/state/ui';
import { linkedReportIdsSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { loadEvidenceItemsLite } from '../../../../evidence/core/state/data/evidenceItems';
import { logWarning } from '../../../../../core/logging';
import { getItemTypeForItemEvent } from '../../../../dragon/events/get-item-type-for-item-event';
import { getRecentHydratedItems } from './itemProfiles';

const context = { name: boxEnum.RECENT_ITEMS_SIDE_PANEL };

/**
 * Open a side panel to manage property or vehicle(s)
 *   profiles.
 * @param {object}   isVehicle   Property is a vehicle
 *                   itemProfileIds   List of item profile ids that have the same REN number
 *                   ownerId   Currently viewing report Id
 *                   ownerType   'REPORT'
 */
export const openRecentItemsSidePanel = ({
    isVehicle,
    itemProfileIds,
    limitToOne,
    ownerId,
    ownerType,
}) => (dispatch) =>
    dispatch(
        openBox(context, {
            isVehicle,
            itemProfileIds,
            limitToOne,
            ownerId,
            ownerType,
        })
    );

/**
 * Add related items to the given owner, which must be a report as of Jan 2019.
 *
 * If Evidence is enabled and the user has the EVIDENCE.EDIT_REPORTING ability, then use an Evidence
 *   endpoint to run the import event on the items. All of its RMS data and Evidence data (if it
 *   exists) will be copied to this report.
 *
 * Otherwise, use an RMS endpoint to copy the item's RMS data to this report.
 */
export const saveRecentItemsSidePanel = ({
    isVehicle,
    itemProfileIds,
    ownerId,
    ownerType,
    reportingEventNumber,
}) => (dispatch, getState, dependencies) => {
    const state = getState();
    const itemProfiles = itemProfilesByIdsSelector(state)(itemProfileIds);
    // regardless of which endpoint is used, do this to refresh the related items UI
    const successCallback = () => {
        const { firearmIds, itemProfileIds, vehicleIds } = itemProfiles.reduce(
            (acc, itemProfile) => {
                const list =
                    itemProfile.itemTypeAttrId === globalAttributes.itemType.firearm
                        ? acc.firearmIds
                        : itemProfile.itemTypeAttrId === globalAttributes.itemType.vehicle
                        ? acc.vehicleIds
                        : acc.itemProfileIds;

                list.push(itemProfile.id);
                return acc;
            },
            {
                firearmIds: [],
                itemProfileIds: [],
                vehicleIds: [],
            }
        );
        for (const [itemIds, itemType] of [
            [firearmIds, getItemTypeForItemEvent(globalAttributes.itemType.firearm)],
            [vehicleIds, getItemTypeForItemEvent(globalAttributes.itemType.vehicle)],
            [itemProfileIds, getItemTypeForItemEvent(globalAttributes.itemType.item)],
        ]) {
            if (itemIds.length) {
                dependencies.dragonEvents.publish({
                    type: 'ITEMS_MODIFIED',
                    payload: {
                        itemType,
                        itemIds,
                    },
                });
            }
        }

        return dispatch(
            getRecentHydratedItems({
                isVehicle,
                ownerId,
                ownerType,
                reportingEventNumber,
            })
        );
    };

    const useEvidenceEndpoint =
        isProductModuleActiveSelector(state)(ProductModuleEnum.EVIDENCE.name) &&
        currentUserHasAbilitySelector(state)(abilitiesEnum.EVIDENCE.EDIT_REPORTING);

    if (useEvidenceEndpoint) {
        // for sourceReportId, the Import Event endpoint does not care whether it is a CPS report or
        // whether the itemProfiles exist on that report, it just needs to be any report in the REN
        // that is different from this target report
        const linkedReportIds = linkedReportIdsSelector(state);
        const sourceReportId = _(linkedReportIds).without(ownerId).head();
        if (sourceReportId) {
            return dispatch(
                runImportEvent({
                    sourceReportId,
                    targetReportId: ownerId,
                    itemProfileIds,
                })
            )
                .then((hydratedItems) => dispatch(loadEvidenceItemsLite(map(hydratedItems, 'id'))))
                .then(successCallback);
        } else {
            logWarning('Missing linked report id for Import Event when copying related items', {
                ownerId,
                reportingEventNumber,
                linkedReportIds,
            });
            // fall back to using the RMS-only copying endpoint below
        }
    }

    return dispatch(
        saveItemProfileIdsToOwner({
            itemProfileIds,
            ownerId,
            ownerType,
            reportingEventNumber,
        })
    ).then(successCallback);
};

/**
 * Close side panel
 */
export const closeRecentItemsSidePanel = () => (dispatch) => dispatch(closeBox(context));

export const recentItemsSidePanelIsVehicleSelector =
    createModalSelector(context, 'isVehicle') || false;
export const recentItemsSidePanelItemProfileIdsSelector = createModalSelector(
    context,
    'itemProfileIds'
);
export const recentItemsSidePanelLimitToOneSelector = createModalSelector(context, 'limitToOne');
export const recentItemsSidePanelOwnerIdSelector = createModalSelector(context, 'ownerId');
export const recentItemsSidePanelOwnerTypeSelector = createModalSelector(context, 'ownerType');

import { NEXUS_STATE_PROP as SUMMARY_NARRATIVES_NEXUS_STATE_PROP } from '../../../summary-narratives/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

export function replaceSummaryNarrativeCardBundle({ reportId, summaryNarrativeCardBundle }) {
    return function (dispatch, getState, dependencies) {
        const resource = getReportCardBundleResource();
        const { withEntityItems } = dependencies.nexus;

        return resource
            .upsertSummaryNarrativeCard(reportId, summaryNarrativeCardBundle)
            .then((updatedSummaryNarrativeCardBundle) => {
                dispatch(
                    withEntityItems(
                        {
                            [SUMMARY_NARRATIVES_NEXUS_STATE_PROP]: [
                                updatedSummaryNarrativeCardBundle.summaryNarrative,
                            ],
                        },
                        { type: 'UPSERT_SUMMARY_NARRATIVE_CARD_BUNDLE' }
                    )
                );

                return updatedSummaryNarrativeCardBundle;
            });
    };
}

import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import componentStrings from '~/client-common/core/strings/componentStrings';
import _Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import testIds from '../../../../../core/testIds';
import RecordsHeaderButton from './RecordsHeaderButton';

const strings = componentStrings.core.header.RecordsHeaderHistoryButton;

const Button = styled(_Button)`
    margin: 0;
    width: 38px;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function ReportHeaderHistoryButton({ onClick, active, className }) {
    return (
        <RecordsHeaderButton
            overlay={strings.tooltip}
            testId={testIds.REPORT_HEADER_HISTORY_BUTTON}
        >
            <Button
                onClick={onClick}
                active={active}
                className={classNames(buttonTypes.ICON, className)}
            >
                <Icon size={18} type={iconTypes.HISTORY} />
            </Button>
        </RecordsHeaderButton>
    );
}

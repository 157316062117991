import _, { find, get, memoize, keyBy } from 'lodash';
import { createSelector } from 'reselect';
import {
    FieldConfigurationContext,
    RefContextEnumType,
    FieldDetail,
    FieldConfiguration,
    FieldTypeEnum,
} from '@mark43/rms-api';

// helpers
import createLinkModule from '../../../../utils/createLinkModule';

// redux
import {
    ClientFieldConfiguration,
    fieldConfigurationByIdSelector,
} from '../../../field-configurations/state/data';
import { fieldDetailByIdSelector } from '../../../field-details/state/data';

export const NEXUS_STATE_PROP = 'fieldConfigurationContexts';

const fieldConfigurationContextsModule = createLinkModule<FieldConfigurationContext>({
    type: NEXUS_STATE_PROP,
    keys: ['id', 'fieldConfigurationId', 'context'],
});

// SELECTORS
export const fieldConfigurationContextsSelector =
    fieldConfigurationContextsModule.selectors.linksSelector;
export const fieldConfigurationContextsWhereSelector =
    fieldConfigurationContextsModule.selectors.linksWhereSelector;

export const fieldConfigurationContextsByFieldConfigurationIdSelector = createSelector(
    fieldConfigurationContextsSelector,
    (fieldConfigurationContexts) =>
        keyBy(
            fieldConfigurationContexts,
            (fieldConfigurationContext) => `${fieldConfigurationContext.fieldConfigurationId}`
        )
);

export const fieldConfigurationContextsByFieldConfigurationIdAndContextSelector = createSelector(
    fieldConfigurationContextsSelector,
    (fieldConfigurationContexts) =>
        keyBy(
            fieldConfigurationContexts,
            (fieldConfigurationContext) =>
                `${fieldConfigurationContext.fieldConfigurationId}~${fieldConfigurationContext.context}`
        )
);

export const fieldConfigurationContextByContextAndFieldNameSelector = createSelector(
    fieldConfigurationContextsWhereSelector,
    fieldConfigurationByIdSelector,
    fieldDetailByIdSelector,
    (fieldConfigurationContextsWhere, fieldConfigurationById, fieldDetailById) => {
        return memoize(
            (context: RefContextEnumType, fieldName: string) => {
                const fieldConfigurationContexts = fieldConfigurationContextsWhere({ context });
                return find(fieldConfigurationContexts, (fieldConfigurationContext) => {
                    const fieldConfiguration = fieldConfigurationById(
                        fieldConfigurationContext.fieldConfigurationId
                    ) as FieldConfiguration;
                    const fieldDetail = fieldDetailById(
                        fieldConfiguration.fieldDetailId
                    ) as FieldDetail;
                    return fieldName === fieldDetail.fieldName;
                });
            },
            (...args) => args.join('~')
        );
    }
);
export const nonInternalStaticallyHiddenFieldConfigurationsInContextSelector = createSelector(
    fieldConfigurationContextsWhereSelector,
    fieldConfigurationByIdSelector,
    fieldDetailByIdSelector,
    (fieldConfigurationContextsWhere, fieldConfigurationById, fieldDetailById) => (
        context: RefContextEnumType
    ) => {
        const fieldConfigurationContexts = fieldConfigurationContextsWhere({ context });
        return _(fieldConfigurationContexts)
            .filter('isStaticallyHidden')
            .filter((fieldConfigurationContext) => {
                const fieldConfiguration = fieldConfigurationById(
                    fieldConfigurationContext.fieldConfigurationId
                );
                const fieldDetail: Partial<FieldDetail> =
                    fieldDetailById(get(fieldConfiguration, 'fieldDetailId')) || {};
                return fieldDetail.fieldType !== FieldTypeEnum.INTERNAL_DATA.name;
            })
            .map((fieldConfigurationContext) =>
                fieldConfigurationById(fieldConfigurationContext.fieldConfigurationId)
            )
            .filter(
                (fieldConfiguration): fieldConfiguration is ClientFieldConfiguration =>
                    !!fieldConfiguration
            )
            .value();
    }
);

// ACTIONS
export const storeFieldConfigurationContexts =
    fieldConfigurationContextsModule.actionCreators.storeLinks;

// REDUCER
export default fieldConfigurationContextsModule.reducerConfig;

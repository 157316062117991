import { get } from 'lodash';
import { createSelector } from 'reselect';
import {
    LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS,
    LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE,
    UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_START,
    UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS,
    UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE,
} from '~/client-common/core/domain/duplicate-event-search-configs/state/data';

const uiSelector = (state) => state.ui.duplicateEventSearchAdmin;

export const serverErrorMessageSelector = createSelector(uiSelector, (ui) => ui.serverErrorMessage);

export const isSavingSelector = createSelector(uiSelector, (ui) => ui.isSaving);

const initialState = {
    isSaving: false,
    serverErrorMessage: null,
};

export default function duplicateEventSearchAdminUiReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE:
        case UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE:
            return {
                ...state,
                isSaving: false,
                serverErrorMessage: get(action.payload, 'message'),
            };
        case UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_START:
            return {
                ...state,
                isSaving: true,
                serverErrorMessage: null,
            };
        case LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS:
        case UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS:
            return {
                ...state,
                isSaving: false,
                serverErrorMessage: null,
            };
        default:
            return state;
    }
}

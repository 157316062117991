import {
    UsageSourceModuleEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    EntityTypeEnum,
} from '@mark43/rms-api';

import { parseInt } from 'lodash';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import { createUsageLog } from '../../admin/usage-logs/state/data';
import { createCaseUsageLog } from '..';
import checkPagePermissions from '../../core/utils/checkPagePermissions';
import { loadCaseExports } from './state/ui/caseExports';
import { loadCaseHistory } from './state/data';

export function caseExportsOnEnter(nextState) {
    this.dispatch(loadCaseExports(nextState.params.caseId));
}

export function caseHistoryOnEnter({ params }) {
    const hasHistoryPermissions = this.dispatch(
        checkPagePermissions(abilitiesEnum.CASES.VIEW_HISTORY)
    );

    if (hasHistoryPermissions) {
        this.dispatch(
            createCaseUsageLog({
                usageLogAction: UsageActionEnum.VIEWED_CASE_HISTORY.name,
                completion: UsageCompletionEnum.SUCCEEDED.name,
            })
        );
        this.dispatch(loadCaseHistory(params.caseId));
    } else {
        this.dispatch(
            createUsageLog({
                primaryEntityType: EntityTypeEnum.CASE.name,
                primaryEntityId: parseInt(params.caseId),
                sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
                action: UsageActionEnum.VIEWED_CASE_HISTORY.name,
                completion: UsageCompletionEnum.INSUFFICIENT_PERMISSIONS.name,
            })
        );
    }
}

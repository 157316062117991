import React from 'react';

import { DrawerLayout, DrawerProvider, useDisclosure } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import UnderSubheader from '../../../core/components/UnderSubheader';
import { AnalyticsErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import { abilitiesEnum, OnlyWithAbility } from '../../../core/abilities';
import { ButtonGroup } from '../../../core/components/Button';
import CreateAnalysisDashboardModal from './CreateAnalysisDashboardModal';
import { AnalysisContextProvider } from './AnalysisContext';
import CopyLinkButton from './CopyLinkButton';
import ViewQueriesButton from './ViewQueriesButton';
import ViewQueriesDrawer from './ViewQueriesDrawer';

const strings = componentStrings.analysis.core.Analysis;

export default function Analysis({ children }) {
    const {
        isOpen: isViewQueriesDrawerOpen,
        onToggle: onToggleViewQueriesDrawer,
        onClose: onCloseViewQueriesDrawer,
    } = useDisclosure();
    return (
        <FeatureFlagged
            flag="RMS_LOOKER_IFRAME_LISTENER_ENABLED"
            fallback={
                <AnalyticsErrorBoundary>
                    <Page className="analysis">
                        <Subheader fullscreen title={strings.title}>
                            <OnlyWithAbility
                                has={abilitiesEnum.ANALYSIS.BISUITE_EXPLORER_ANALYTICS}
                            >
                                <CreateAnalysisDashboardModal />
                            </OnlyWithAbility>
                        </Subheader>
                        <UnderSubheader fullscreen={true}>{children}</UnderSubheader>
                    </Page>
                </AnalyticsErrorBoundary>
            }
        >
            <AnalysisContextProvider>
                <AnalyticsErrorBoundary>
                    <DrawerProvider drawerPortalId="view-queries-drawer-portal">
                        <Page className="analysis">
                            <Subheader fullscreen title={strings.title}>
                                <ButtonGroup>
                                    <FeatureFlagged flag="RMS_LOOKER_ACTIVE_QUERIES_PANEL_ENABLED">
                                        <ViewQueriesButton onClick={onToggleViewQueriesDrawer} />
                                    </FeatureFlagged>
                                    <FeatureFlagged flag="RMS_LOOKER_SHARE_DASHBOARD_URL_ENABLED">
                                        <CopyLinkButton />
                                    </FeatureFlagged>
                                    <OnlyWithAbility
                                        has={abilitiesEnum.ANALYSIS.BISUITE_EXPLORER_ANALYTICS}
                                    >
                                        <CreateAnalysisDashboardModal />
                                    </OnlyWithAbility>
                                </ButtonGroup>
                            </Subheader>
                            <UnderSubheader fullscreen={true}>
                                <FeatureFlagged
                                    flag="RMS_LOOKER_ACTIVE_QUERIES_PANEL_ENABLED"
                                    fallback={children}
                                >
                                    <DrawerLayout>
                                        {children}
                                        <ViewQueriesDrawer
                                            isOpen={isViewQueriesDrawerOpen}
                                            onClose={onCloseViewQueriesDrawer}
                                        />
                                    </DrawerLayout>
                                </FeatureFlagged>
                            </UnderSubheader>
                        </Page>
                    </DrawerProvider>
                </AnalyticsErrorBoundary>
            </AnalysisContextProvider>
        </FeatureFlagged>
    );
}

import React, { KeyboardEvent } from 'react';
import styled from 'styled-components';
import { Flex } from 'arc';
import { Form, lifecycleOptions, Observer } from 'markformythree';
import { AttributeTypeEnum } from '@mark43/rms-api';

import * as fields from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import useFields from '~/client-common/core/fields/hooks/useFields';

import testIds from '../../../../core/testIds';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import ButtonWithLoadingIndicator from '../../components/ButtonWithLoadingIndicator';
import { MFTText } from '../../forms/components/Text';
import FormRow from '../../forms/components/FormRow';
import { MFTDatePicker, ArbiterMFTDatePicker } from '../../forms/components/DatePicker';
import { MFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import {
    personSearchToAddSidePanelFormConfig,
    PersonSearchToAddFormConfiguration,
} from '../../../search/search-to-add/state/forms/personSearchToAddForm';
import { clearfix } from '../../styles/mixins';

const strings = componentStrings.core.PersonSearchToAdd;

const FormContainer = styled.div`
    ${clearfix};
`;
const StyledButtonWithLoadingIndicator = styled(ButtonWithLoadingIndicator)`
    clear: both;
`;

const CHAR_COUNT_TO_ENABLE_SUBMIT = 3;

export const PersonSearchToAddForm = ({
    onClickSearch,
    loading,
}: {
    onClickSearch: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    loading?: boolean;
}) => {
    const fieldDisplayNames = useFields([
        fields.DISPLAY_ONLY_PERSON_NAME_SEARCH_LABEL,
        fields.DISPLAY_ONLY_PERSON_NAME_SEARCH_PLACEHOLDER,
    ]);

    return (
        <Form<PersonSearchToAddFormConfiguration>
            name={formClientEnum.PERSON_SEARCH_TO_ADD_FORM}
            configuration={personSearchToAddSidePanelFormConfig}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            render={(form) => {
                const handlePressEnter = (
                    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                    const { quickSearchQuery = '' } = form.get();
                    if (quickSearchQuery.length >= CHAR_COUNT_TO_ENABLE_SUBMIT) {
                        onClickSearch(e);
                    }
                };

                return (
                    <FormContainer>
                        <FeatureFlagged
                            flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                            fallback={
                                <>
                                    <MFTText
                                        path="quickSearchQuery"
                                        label={
                                            fieldDisplayNames.DISPLAY_ONLY_PERSON_NAME_SEARCH_LABEL
                                        }
                                        placeholder={
                                            fieldDisplayNames.DISPLAY_ONLY_PERSON_NAME_SEARCH_PLACEHOLDER
                                        }
                                        onPressEnter={handlePressEnter}
                                        // @ts-expect-error not a real field, but this one is used for e2e tests
                                        fieldName="NAME_SIDE_PANEL_NAME_SEARCH_NAME_INPUT"
                                    />
                                    <MFTDatePicker
                                        path="dateOfBirthQuickSearchQuery"
                                        variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                                        label={strings.fieldLabels.dateOfBirthQuickSearchQuery}
                                        placeholder={
                                            strings.fieldPlaceholders.dateOfBirthQuickSearchQuery
                                        }
                                    />
                                    <MFTText
                                        path="masterPersonId"
                                        label={strings.fieldLabels.mniQuickSearchQuery}
                                        placeholder={strings.fieldPlaceholders.mniQuickSearchQuery}
                                        length="md"
                                    />
                                    <MFTText
                                        path="homeAddressQuickSearchQuery"
                                        label={strings.fieldLabels.homeAddressQuickSearchQuery}
                                        placeholder={
                                            strings.fieldPlaceholders.homeAddressQuickSearchQuery
                                        }
                                        onPressEnter={handlePressEnter}
                                        length="lg"
                                    />
                                </>
                            }
                        >
                            <Flex>
                                <MFTText
                                    path="quickSearchQuery"
                                    label={fieldDisplayNames.DISPLAY_ONLY_PERSON_NAME_SEARCH_LABEL}
                                    placeholder={
                                        fieldDisplayNames.DISPLAY_ONLY_PERSON_NAME_SEARCH_PLACEHOLDER
                                    }
                                    onPressEnter={handlePressEnter}
                                    // @ts-expect-error There is no such field in a DB, but this one is used for e2e tests
                                    fieldName="NAME_SIDE_PANEL_NAME_SEARCH_NAME_INPUT"
                                    length="lg"
                                />
                                <MFTDatePicker
                                    path="dateOfBirthQuickSearchQuery"
                                    variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                                    label={strings.newFieldLabels.dateOfBirthQuickSearchQuery}
                                    placeholder={
                                        strings.fieldPlaceholders.dateOfBirthQuickSearchQuery
                                    }
                                    hasCalendar={false}
                                />
                            </Flex>
                            <FormRow>
                                <MFTText
                                    path="dlNumber"
                                    label={strings.newFieldLabels.dlNumberQuickSearchQuery}
                                    length="md"
                                />
                                <MFTAttributeSelect
                                    label={strings.newFieldLabels.stateQuickSearchQuery}
                                    fieldName={fields.PERSON_PROFILE_DL_STATE_ATTR_ID}
                                    attributeType={AttributeTypeEnum.STATE.name}
                                    path="dlStateAttrId"
                                    includeExpired={true}
                                    length="md"
                                />
                            </FormRow>
                            <MFTText
                                path="homeAddressQuickSearchQuery"
                                label={strings.newFieldLabels.homeAddressQuickSearchQuery}
                                placeholder={strings.fieldPlaceholders.homeAddressQuickSearchQuery}
                                length="lg"
                                onPressEnter={handlePressEnter}
                            />
                            <MFTText
                                path="phoneNumber"
                                label={strings.newFieldLabels.phoneNumberQuickSearchQuery}
                                length="md"
                            />
                        </FeatureFlagged>
                        <Observer<{ quickSearchQuery?: string }, PersonSearchToAddFormConfiguration>
                            subscriptions={{
                                quickSearchQuery: 'quickSearchQuery',
                            }}
                            formName={formClientEnum.PERSON_SEARCH_TO_ADD_FORM}
                            render={({ quickSearchQuery }) => (
                                <StyledButtonWithLoadingIndicator
                                    loading={loading}
                                    disabled={
                                        !quickSearchQuery ||
                                        quickSearchQuery?.length < CHAR_COUNT_TO_ENABLE_SUBMIT
                                    }
                                    className={buttonTypes.PRIMARY}
                                    onClick={onClickSearch}
                                    testId={testIds.NAME_SIDE_PANEL_SEARCH_BUTTON}
                                >
                                    {strings.search}
                                </StyledButtonWithLoadingIndicator>
                            )}
                        />
                    </FormContainer>
                );
            }}
        />
    );
};

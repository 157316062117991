import { reduce, compact } from 'lodash';

import { EntityTypeEnum, Folder, PaperFormatEnum, PrintingDataTypeEnum } from '@mark43/rms-api';

import { partitionAttachmentAndFolderIdsSelector } from '../state/data/partitionAttachmentAndFolderIds';
import type { PacketOption } from './exportHelpers';

// can't type the return value as Partial<WorkOrder> from mark43-resources due to the custom @JsonProperty in Java
function buildWorkOrder(folder: Folder) {
    return {
        dataType: PrintingDataTypeEnum.PARENT_FOLDER.name,
        paperFormat: PaperFormatEnum.MULTI_PDF.name,
        millerIdMap: {
            [EntityTypeEnum.FOLDER.name]: folder.id,
        },
    };
}

type PartialWorkOrder = ReturnType<typeof buildWorkOrder>;

export default function folderPrintablesToWorkOrders({
    printables,
    selectedAttachmentsToInclude,
    partitionAttachmentAndFolderIds,
}: {
    printables: PacketOption[];
    selectedAttachmentsToInclude: Record<number, number[]>;
    partitionAttachmentAndFolderIds: ReturnType<typeof partitionAttachmentAndFolderIdsSelector>;
}): PartialWorkOrder[] {
    return compact(
        reduce<PacketOption, PartialWorkOrder[]>(
            printables,
            (folderPrintables, printable) => {
                // When the Printable is for a folder, this entityId is the folder's ownerId. See
                // groupAttachmentsByEntityIdSelector.
                if (!printable.entityId) {
                    return folderPrintables;
                }

                const { folderIds, folders } = partitionAttachmentAndFolderIds(
                    selectedAttachmentsToInclude[printable.entityId]
                );

                return [
                    ...folderPrintables,
                    ...reduce<number, PartialWorkOrder[]>(
                        folderIds,
                        (acc, folderId) => {
                            const folderView = folders[folderId];
                            if (folderView?.folder) {
                                return [...acc, buildWorkOrder(folderView.folder)];
                            }
                            return acc;
                        },
                        []
                    ),
                ];
            },
            []
        )
    );
}

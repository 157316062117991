import React from 'react';
import { useSelector } from 'react-redux';
import { some } from 'lodash';

import { userHasAbilitySelector } from '../state/data';

type BaseProps = {
    userId: number;
};

type HasProps = {
    has: number;
} & BaseProps;

type HasOneOfProps = {
    hasOneOf: number[];
} & BaseProps;

type OnlyWithAbilityBaseExternalProps = HasOneOfProps | HasProps;

const OnlyWithAbilityBase: React.FC<OnlyWithAbilityBaseExternalProps> = (props) => {
    const userHasAbility = useSelector(userHasAbilitySelector);

    if (
        'hasOneOf' in props
            ? some(props.hasOneOf, (ability) => userHasAbility(props.userId, ability))
            : userHasAbility(props.userId, props.has)
    ) {
        return <>{props.children}</>;
    }
    return null;
};

/**
 * A component for rendering child components only if abilities check passes
 * Takes in children components and an array of abilityIds
 * example use:
 * <OnlyWithAbilityBase has={abilitiesEnum.MODULE.SAY_STUFF} userId={1}>
 *  <Button>Say Stuff!</Button>
 * </OnlyWithAbilityBase>
 */
export default OnlyWithAbilityBase;

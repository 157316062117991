import React, { useState, useCallback } from 'react';
import { flowRight, map, slice, orderBy } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import useFields from '~/client-common/core/fields/hooks/useFields';
import { useResource } from '~/client-common/core/hooks/useResource';
import {
    DISPLAY_ONLY_CAD_LABEL,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { FormattedDate } from '~/client-common/core/dates/components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Button } from '../../core/components/Button';
import { MY_CAD_EVENTS_INTERVAL } from '../configuration';
import { openCreateReportModal } from '../state/ui';
import personalDashboardResource from '../resources/personalDashboardResource';
import { NoDataBlock, ShowMoreButton, DashboardCard } from './DashboardSection';
import { DashboardTable, DashboardTd, DashboardTr, DashboardTh } from './DashboardTable';
import { DashboardRightCardsLoader } from './DashboardLoaders';

const strings = componentStrings.personalDashboard.MyCadEvents;

const columnStyles = {
    location: { maxWidth: '22rem' },
};

const HeaderRow = withFields([REPORT_REPORTING_EVENT_NUMBER])(function HeaderRow({
    fieldDisplayNames,
}) {
    return (
        <DashboardTr>
            <DashboardTh>{fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER}</DashboardTh>
            <DashboardTh style={columnStyles.location}>{strings.fullAddress}</DashboardTh>
            <DashboardTh>{strings.createdDateUtc}</DashboardTh>
        </DashboardTr>
    );
});

function renderRow({ cadTicket, handleClick }) {
    return (
        <DashboardTr isInteractive key={cadTicket.id} onClick={() => handleClick(cadTicket)}>
            <DashboardTd style={{ color: 'var(--arc-colors-brand-default)' }}>
                {cadTicket.reportingEventNumber}
            </DashboardTd>
            <DashboardTd style={columnStyles.location}>{cadTicket.fullAddress}</DashboardTd>
            <DashboardTd>
                <FormattedDate
                    date={cadTicket.createdDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                />
            </DashboardTd>
        </DashboardTr>
    );
}

const MyCadEventsContent = ({ handleRowClick, hideLoadingBar }) => {
    const [tickets, setTickets] = useState([]);
    const [ticketsToShow, setTicketsToShow] = useState(MY_CAD_EVENTS_INTERVAL);

    const loadMyCadEvents = useCallback(
        () => personalDashboardResource.getMyCadEvents({ hideLoadingBar }),
        [hideLoadingBar]
    );

    const onResourceSuccess = useCallback(
        (tickets) => {
            tickets = orderBy(tickets, 'createdDateUtc', 'desc');
            setTickets(tickets);
        },
        [setTickets]
    );

    const loadingState = useResource(loadMyCadEvents, onResourceSuccess);

    const { isLoading, errorMessage } = loadingState;

    if (isLoading && tickets.length === 0) {
        return <DashboardRightCardsLoader />;
    }

    if (errorMessage) {
        return <NoDataBlock>{strings.loadError}</NoDataBlock>;
    }

    if (tickets.length === 0) {
        return <NoDataBlock>{strings.noResults}</NoDataBlock>;
    }

    const displayedTickets = slice(tickets, 0, ticketsToShow);

    const data = map(displayedTickets, (cadTicket) => ({
        cadTicket,
        handleClick: handleRowClick,
    })).flatMap((elem) => {
        return elem.cadTicket?.reportingEventNumbers.map((ren) => {
            return {
                ...elem,
                cadTicket: { ...elem.cadTicket, reportingEventNumber: ren },
            };
        });
    });

    return (
        <>
            <DashboardTable data={data} renderHeader={() => <HeaderRow />} renderRow={renderRow} />
            {displayedTickets.length < tickets.length && (
                <ShowMoreButton
                    isSticky
                    onClick={() => {
                        setTicketsToShow(ticketsToShow + MY_CAD_EVENTS_INTERVAL);
                    }}
                >
                    {strings.showMore}
                </ShowMoreButton>
            )}
        </>
    );
};

const MyCadEvents = ({ handleRowClick, dragHandleProps, isDragging }) => {
    const cadDisplayName = useFields(DISPLAY_ONLY_CAD_LABEL)[DISPLAY_ONLY_CAD_LABEL];

    return (
        <DashboardCard
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
            actions={
                <Button isTextTransformNone variant="ghost" asLink to="/search/cad">
                    {strings.viewAll}
                </Button>
            }
            title={strings.title(cadDisplayName)}
            helpText={strings.helpText}
        >
            <MyCadEventsContent handleRowClick={handleRowClick} hideLoadingBar={true} />
        </DashboardCard>
    );
};

const mapDispatchToProps = (dispatch) => ({
    handleRowClick(cadTicket, rowIndex) {
        dispatch(openCreateReportModal(cadTicket, rowIndex));
    },
});

export default flowRight(withRouter, connect(undefined, mapDispatchToProps))(MyCadEvents);

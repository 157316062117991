import React from 'react';
import { createField, createFormConfiguration, Form, lifecycleOptions } from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import testIds from '../../../../core/testIds';
import { MFTText } from '../../../core/forms/components/Text';
import Row from '../../../core/components/Row';

const strings = componentStrings.cases.core.CreateFolderForm;

const createFolderFormConfiguration = createFormConfiguration({
    name: createField<string>({}),
});

const CreateFolderForm: React.FC = () => {
    return (
        <Form
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={formClientEnum.FORM_CREATE_FOLDER}
            configuration={createFolderFormConfiguration}
            render={() => (
                <Row testId={testIds.FOLDER_NAME}>
                    <MFTText maxLength={64} label={strings.label} path="name" />
                </Row>
            )}
        />
    );
};

export default CreateFolderForm;

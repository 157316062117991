import { sortBy } from 'lodash';
import { ExportPresetView } from '@mark43/rms-api';

import packetTypeEnum from '~/client-common/core/enums/client/packetTypeEnum';
import { PacketOption } from './exportHelpers';
import { getMatchingExportPresetReportTypeForPacket } from './getMatchingExportPresetReportTypeForPacket';

/**
 * Sort the array of packet options using the export preset report type export positions.
 */
export function sortOptionsByExportPresetReportTypes(
    options: PacketOption[],
    exportPreset: ExportPresetView | undefined
) {
    if (exportPreset?.isDefaultExportPreset) {
        return options;
    }

    return sortBy(options, (option) => {
        const packet = option.packetType === packetTypeEnum.REGULAR ? option?.rawPacket : option;
        const exportPresetReportType = getMatchingExportPresetReportTypeForPacket(
            exportPreset?.exportPresetReportTypes,
            packet
        );

        return exportPresetReportType?.exportPosition;
    });
}

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';

import { compact, map } from 'lodash';

import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '../../../location-entity-links/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

/**
 * `LinkTypes` that are valid for the `LocationEntityLink`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const TRAFFIC_CRASH_CARD_LOCATION_LINK_TYPES = [LinkTypesEnum.LOCATION_OF_TRAFFIC_CRASH];

export function replaceTrafficCrashCardBundle({ reportId, trafficCrashCardBundle }) {
    return (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) => {
        const resource = getReportCardBundleResource();
        return resource
            .upsertTrafficCrashCard(reportId, trafficCrashCardBundle)
            .then((updatedTrafficCrashCardBundle) => {
                const { locationEntityLink } = updatedTrafficCrashCardBundle;
                const predicateLocationEntityLinksToRemoveBy = map(
                    TRAFFIC_CRASH_CARD_LOCATION_LINK_TYPES,
                    (linkType) => ({
                        linkType,
                        entityType: EntityTypeEnum.REPORT.name,
                        entityId: reportId,
                    })
                );

                dispatch(
                    withEntityItems(
                        {
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: compact([locationEntityLink]),
                        },
                        withRemoveMultiple(
                            {
                                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: predicateLocationEntityLinksToRemoveBy,
                            },
                            {
                                type: 'UPSERT_TRAFFIC_CRASH_CARD_BUNDLE',
                            }
                        )
                    )
                );

                return updatedTrafficCrashCardBundle;
            });
    };
}

import React from 'react';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { BodyMediumText } from '../../../core/components/typography';
import Modal from '../../../core/overlays/components/Modal';

const strings = componentStrings.entityProfiles.EvidenceItemMenu;

type PropsT = {
    onSave: () => Promise<void>;
    isHighValue: boolean;
};

const EvidenceSetHighValueModal: React.FC<PropsT> = ({ onSave, isHighValue }) => {
    return (
        <Modal
            id={overlayIdEnum.EVIDENCE_SET_HIGH_VALUE_MODAL}
            title={strings.setHighValueModalTitle}
            okText={strings.confirm}
            onSave={onSave}
        >
            <div>
                <BodyMediumText fontWeight="semibold">
                    {strings.setHighValueMessage(isHighValue)}
                </BodyMediumText>
                <p>{strings.reversibleAction}</p>
            </div>
        </Modal>
    );
};

export default EvidenceSetHighValueModal;

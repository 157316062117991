import React, { useCallback, useState } from 'react';
import { ComplianceExportHistoryView, NibrsHistoryView, RegionalGroupEnum } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    Form,
    lifecycleOptions,
    formEvents,
    createFormConfiguration,
    createField,
} from 'markformythree';
import { RouteComponentProps } from 'react-router';
import * as yup from 'yup';
import { noop, orderBy, first, last, filter } from 'lodash';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { agencyProfileByIdSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import withFields from '~/client-common/core/fields/components/withFields';
import { AGENCY_PROFILE_AGENCY_NAME } from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../core/validation/yupMftValidation';
import { MFTAgencyProfileWithOriSelect } from '../../core/forms/components/selects/AgencyProfileSelect';
import Link from '../../core/components/links/Link';
import Page from '../../core/components/Page';
import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import { SimpleLoading } from '../../../legacy-redux/components/core/Loading';
import Subheader from '../../core/components/Subheader';
import ScrollableUnderSubheader from '../../core/components/ScrollableUnderSubheader';
import { InlineBanner } from '../../core/components/InlineBanner';
import Icon, { iconTypes, iconSizes } from '../../core/components/Icon';
import nibrsExportResource from '../resources/nibrsExportResource';
import { NIBRS_COMPLIANCE_TYPE } from '../configuration';
import complianceExportResource from '../resources/complianceExportResource';
import type { SupportedComplianceExportType } from '../types';
import { complianceExportTypeDisplaySelector } from '../state/ui';
import { getComplianceExportTypeEnum } from '../util/complianceExportTypeHelpers';
import testIds from '../../../core/testIds';
import {currentUserDepartmentProfileSelector} from "../../core/current-user/state/ui";
import ComplianceExportHistoryTable, {
    NibrsExportHistoryTable,
} from './ComplianceExportHistoryTable';
import ComplianceExportHistoryDeleteHistoryForm from './ComplianceExportHistoryDeleteHistoryForm';

type PropsT = { fieldDisplayNames: { [key: string]: string } };
type Params = { complianceExportType: string };
type ComplianceExportHistoryProps = PropsT & RouteComponentProps<Params, Record<string, unknown>>;

const strings = componentStrings.compliance.ComplianceExportHistory;
const validationStrings = componentStrings.validation;

const complianceExportHistoryFormConfiguration = createFormConfiguration({
    agencyProfileId: createField<number>({}),
});

const validationSchema = yup.object().shape({
    agencyProfileId: yup.number().required(validationStrings.requiredError),
});

const ExportRouteButton = styled(Button)`
    margin-top: 19px;
    display: flex;
    align-items: center;

    .react-icon-left {
        margin-right: 6px;
    }
`;

const LoadHistoryButton = styled(Button)`
    margin-top: 16px;
`;

const SelectWrapper = styled.div`
    height: 64px;
`;

const TopSectionWrapper = styled.div`
    margin: 30px 0;
    padding: 0 20px;
`;

const FormContent = styled.div`
    display: flex;
    align-items: center;
`;

const ButtonLabel = styled.span`
    margin-bottom: 2px;
`;

const SectionHeader = styled.h2`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.mediumGrey};
    font-size: var(--arc-fontSizes-lg);
    text-transform: uppercase;
    margin: 17px 0 16px;
`;

function sortHistory<T extends ComplianceExportHistoryView | NibrsHistoryView>(
    historyData: T[]
): T[] {
    return orderBy(
        historyData,
        // `isOverwritten` is sorted ascending
        // because we want items which are not overwritten
        // to come first for the same date and
        // `false` is sorted before `true`, giving
        // us the desired order
        ['exportedDateUtc', 'isOverwritten', 'exportYear', 'exportMonth'],
        ['desc', 'asc', 'asc', 'asc']
    );
}

function sortLastExportHistoryByExportMonth<
    T extends ComplianceExportHistoryView| NibrsHistoryView
>(historyData: T[]): T[] {
    return orderBy(
        filter(historyData, (exportHistory) => !exportHistory.isOverwritten),
        ['exportYear', 'exportMonth']
    );
}

function sortLastExportHistoryByExportDate<
    T extends NibrsHistoryView
>(historyData: T[]): T[] {
    return orderBy(
        filter(historyData, (exportHistory) => !exportHistory.isOverwritten),
        ['exportYear', 'exportMonth', 'exportDay']
    );
}

const ComplianceExportHistory: React.FC<ComplianceExportHistoryProps> = (props) => {
    const [exportedAgencyIdResults, setExportedAgencyIdResults] = useState<number>();
    const [complianceExportHistoryData, setComplianceExportHistoryData] = useState<
        ComplianceExportHistoryView[]
    >([]);
    const [nibrsExportHistoryData, setNibrsExportHistoryData] = useState<NibrsHistoryView[]>([]);
    const [
        nibrsExportHistorySortedByExportDate,
        setNibrsExportHistorySortedByExportDate,
    ] = useState<NibrsHistoryView[]>([]);

    const [
        complianceExportHistorySortedByExportMonth,
        setComplianceExportHistorySortedByExportMonth,
    ] = useState<ComplianceExportHistoryView[]>([]);

    const { location, params, fieldDisplayNames } = props;

    const complianceExportTypeDisplay = useSelector(complianceExportTypeDisplaySelector);
    const complianceExportType = getComplianceExportTypeEnum(params.complianceExportType);
    const currentNibrsRegionalGroup = useSelector(currentUserDepartmentProfileSelector)?.nibrsRegionalGroup ?? RegionalGroupEnum.FEDERAL_NIBRS.name;
    const isNibrs = complianceExportType?.name === NIBRS_COMPLIANCE_TYPE.name;
    const exportIntervalType = RegionalGroupEnum[currentNibrsRegionalGroup].nibrsExportIntervalType;

    const exportUrl = location.pathname.replace('/history', '/export');
    const handleValidation = useCallback(({ formState, eventType }) => {
        const $form = formState.ui.$form;

        return validationSchema
            .validate(formState.model, { abortEarly: false })
            .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
            .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
    }, []);

    const onComplianceExportTypeSuccess = useCallback(
        (complianceHistoryViews: ComplianceExportHistoryView[]) => {
            setComplianceExportHistoryData(
                sortHistory<ComplianceExportHistoryView>(complianceHistoryViews)
            );
            setComplianceExportHistorySortedByExportMonth(
                sortLastExportHistoryByExportMonth<ComplianceExportHistoryView>(
                    complianceHistoryViews
                )
            );
        },
        []
    );
    const firstRecord = isNibrs
        ? first(nibrsExportHistorySortedByExportDate)
        : first(complianceExportHistorySortedByExportMonth);
    const lastRecord = isNibrs
        ? last(nibrsExportHistorySortedByExportDate)
        : last(complianceExportHistorySortedByExportMonth);

    const onNibrsExportTypeSuccess = useCallback((nibrsExportHistoryViews: NibrsHistoryView[]) => {
        setNibrsExportHistoryData(sortHistory<NibrsHistoryView>(nibrsExportHistoryViews));
        setNibrsExportHistorySortedByExportDate(
            sortLastExportHistoryByExportDate<NibrsHistoryView>(nibrsExportHistoryViews)
        );
    }, []);

    const loadComplianceHistoryData = useCallback(
        (complianceExportTypeName, agencyId) => {
            return isNibrs
                ? nibrsExportResource.getExportHistoryForAgencyId(agencyId)
                : complianceExportResource.getComplianceExportHistoryForAgencyId(
                      complianceExportTypeName,
                      agencyId
                  );
        },
        [isNibrs]
    );

    const agencyProfileById = useSelector(agencyProfileByIdSelector);

    const {
        callResource: fetchComplianceHistoryData,
        loading: { isLoading, errorMessage },
    } = useResourceDeferred(
        // @ts-expect-error need to discriminate between NibrsHistoryView and ComplianceExportHistoryView
        loadComplianceHistoryData,
        isNibrs ? onNibrsExportTypeSuccess : onComplianceExportTypeSuccess
    );

    const exportedAgencyProfile = agencyProfileById(exportedAgencyIdResults);

    return (
        <Page>
            <Subheader
                title={`${strings.title} - ${complianceExportTypeDisplay(
                    // TODO: type guard
                    params.complianceExportType as SupportedComplianceExportType
                )}`}
            >
                <Link to={exportUrl}>
                    <ExportRouteButton
                        active={true}
                        className={buttonTypes.SECONDARY}
                        float="right"
                        iconLeft={<Icon type={iconTypes.CARD} size={iconSizes.MEDIUM} />}
                    >
                        <ButtonLabel>{strings.button.exportRoute}</ButtonLabel>
                    </ExportRouteButton>
                </Link>
            </Subheader>
            <ScrollableUnderSubheader>
                <TopSectionWrapper>
                    <Form
                        configuration={complianceExportHistoryFormConfiguration}
                        lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                        initialState={{
                            agencyProfileId: undefined,
                        }}
                        onValidate={handleValidation}
                        validationEvents={[
                            { eventType: formEvents.FORM_SUBMIT },
                            { eventType: formEvents.INPUT_BLUR },
                            { eventType: formEvents.INPUT_CHANGE },
                        ]}
                        name={formClientEnum.COMPLIANCE_HISTORY}
                        render={(form) => {
                            return (
                                <div>
                                    {errorMessage && (
                                        <InlineBanner status="error">{errorMessage}</InlineBanner>
                                    )}
                                    <FormContent>
                                        <SelectWrapper
                                            data-test-id={
                                                testIds.COMPLIANCE_DASHBOARD_AGENCY_PROFILE_SELECT
                                            }
                                        >
                                            <MFTAgencyProfileWithOriSelect
                                                width={320}
                                                clearable={false}
                                                path="agencyProfileId"
                                                label={fieldDisplayNames.AGENCY_PROFILE_AGENCY_NAME}
                                            />
                                        </SelectWrapper>
                                        <LoadHistoryButton
                                            testId={
                                                testIds.COMPLIANCE_DASHBOARD_LOAD_HISTORY_BUTTON
                                            }
                                            className={buttonTypes.PRIMARY}
                                            disabled={!form.getUi().valid}
                                            onClick={() => {
                                                form.submit()
                                                    .then(() => {
                                                        const agencyProfileId = form.get(
                                                            'agencyProfileId'
                                                        ) as number;
                                                        setExportedAgencyIdResults(agencyProfileId);
                                                        return fetchComplianceHistoryData(
                                                            complianceExportType?.name,
                                                            agencyProfileId
                                                        );
                                                    })
                                                    .catch(noop);
                                            }}
                                        >
                                            {strings.button.loadHistory}
                                        </LoadHistoryButton>
                                    </FormContent>
                                </div>
                            );
                        }}
                    />
                    <FeatureFlagged flag="RMS_DELETE_COMPLIANCE_HISTORY_ENABLED">
                        <ComplianceExportHistoryDeleteHistoryForm
                            isLoading={isLoading}
                            firstHistoryData={firstRecord}
                            lastHistoryData={lastRecord}
                            agencyId={exportedAgencyIdResults}
                            complianceType={complianceExportType?.name}
                            exportIntervalType={exportIntervalType}
                            onSubmit={() =>
                                fetchComplianceHistoryData(
                                    complianceExportType?.name,
                                    exportedAgencyIdResults
                                )
                            }
                        />
                    </FeatureFlagged>

                    {!isLoading && !errorMessage && exportedAgencyProfile && (
                        <SectionHeader>
                            {strings.label.header(
                                // TODO: type guard
                                complianceExportTypeDisplay(
                                    params.complianceExportType as SupportedComplianceExportType
                                ),
                                exportedAgencyProfile.agencyName
                            )}
                        </SectionHeader>
                    )}
                </TopSectionWrapper>
                {isLoading && <SimpleLoading />}
                {!isLoading && !errorMessage && !isNibrs && (
                    <ComplianceExportHistoryTable data={complianceExportHistoryData} />
                )}
                {!isLoading && !errorMessage && isNibrs && (
                    <NibrsExportHistoryTable data={nibrsExportHistoryData} />
                )}
            </ScrollableUnderSubheader>
        </Page>
    );
};

export default withFields([AGENCY_PROFILE_AGENCY_NAME])(ComplianceExportHistory);

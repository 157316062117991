import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { isNumber, noop } from 'lodash';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { nextSortType } from '~/client-common/helpers/searchHelpers';
import _Icon, { iconTypes } from '../../../../modules/core/components/Icon';
import testIds from '../../../../core/testIds';
import { mapToSortType } from '../../../../modules/core/components/PopoutMenu';

const Icon = styled(_Icon)`
    vertical-align: middle;
    margin-top: -4px;
    margin-left: 4px;
`;

const mapSortTypeToIcon = {
    [sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT]: <Icon size={12} type={iconTypes.ARROW_DOWN} />,
    [sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT]: <Icon size={12} type={iconTypes.ARROW_UP} />,
    [sortTypeEnum.ALPHABETICAL_Z_TO_A]: <Icon size={12} type={iconTypes.ARROW_DOWN} />,
    [sortTypeEnum.ALPHABETICAL_A_TO_Z]: <Icon size={12} type={iconTypes.ARROW_UP} />,
    [sortTypeEnum.AGE_OLDEST_TO_YOUNGEST]: <Icon size={12} type={iconTypes.ARROW_DOWN} />,
    [sortTypeEnum.AGE_YOUNGEST_TO_OLDEST]: <Icon size={12} type={iconTypes.ARROW_UP} />,
};

const TABLE_COLUMN_LEFT_PADDING = 10;

/**
 * To be used with Table. This component defines look and behaviour of headers
 *   for table columns.
 * @typedef {object}   Props
 * @prop   {function}  [onClick]  Callback which is called whenever the TableColumn
 *   is clicked.
 * @prop   {string}    [sortType] Indicates the type of sort for this column.
 *   This value will dictate display behaviours such as what colour the header
 *   should be and which icons should display.
 * @prop   {string}    display    The text which will be visible in the TableColumn.
 * @prop   {string}    columnKey  Indicates which property on a row of data
 *   this column represents. `props.onClick` will be provided this value.
 * @prop   {width}     width      The width of the column.
 * @prop   {Component} [children] Either nothing or a dropdown provided from
 *   OptionsTableColumn.
 * @prop   {string}    [className] An additional complimentary CSS class that
 * TableColumn can inherit.
 *
 * @param {Props}    props
 */
export default function TableColumn({
    onClick: handleClick = noop,
    sortType = sortTypeEnum.NONE,
    display,
    columnKey,
    width,
    height,
    children: dropdown,
    className,
}) {
    const sortTypeToMap = mapToSortType(sortType) ? mapToSortType(sortType) : sortType;
    const sortIcon = mapSortTypeToIcon[sortTypeToMap];
    return (
        <th
            className={classNames(
                'cobalt-table-column',
                {
                    'has-action': !(handleClick === noop),
                    'open-dropdown': dropdown,
                },
                className
            )}
            style={{
                width,
                height,
            }}
            onClick={() => handleClick(columnKey, nextSortType(sortType))}
        >
            <div
                className="cobalt-table-column-title"
                style={{ width: isNumber(width) ? width - TABLE_COLUMN_LEFT_PADDING : width }}
                data-test-id={testIds.TABLE_COLUMN_TITLE}
            >
                {display}
                {sortIcon}
            </div>
            {dropdown}
        </th>
    );
}

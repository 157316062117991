import { AttributeTypeEnum, EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { createFormConfiguration, _Form, createFieldset, createNItems } from 'markformythree';
import { head, map, pick } from 'lodash';

import { useOfForcesWhereSelector } from '~/client-common/core/domain/use-of-forces/state/data';
import { reportAttributesWhereSelector } from '~/client-common/core/domain/report-attributes/state/data';
import { otherInvolvedAgenciesSelector } from '~/client-common/core/domain/other-involved-agencies/state/data';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';

import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const refreshUseOfForceForm = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const useOfForceStatisticsAttributes = reportAttributesWhereSelector(state)({
        reportId,
        attributeType: AttributeTypeEnum.USE_OF_FORCE_STATISTICS.name,
    });

    const useOfForceStatisticsAttrIds = map(useOfForceStatisticsAttributes, 'attributeId');
    const useOfForceStatisticsDescription = getDescriptionForAttributeLinks(
        useOfForceStatisticsAttributes
    );
    const useOfForce = convertToFormModel(head(useOfForcesWhereSelector(state)({ reportId })));
    const useOfForceLocation = head(
        locationEntityLinksWhereSelector(state)({
            linkType: LinkTypesEnum.LOCATION_OF_USE_OF_FORCE,
            entityType: EntityTypeEnum.USE_OF_FORCE.name,
            entityId: useOfForce.id,
        })
    );
    const otherInvolvedAgencies = map(otherInvolvedAgenciesSelector(state), (agency) =>
        pick(agency, ['id', 'otherAgencyName', 'otherAgencyOri', 'otherAgencyCaseNumber'])
    );
    return {
        ...useOfForce,
        useOfForceStatistics: useOfForceStatisticsAttrIds,
        useOfForceStatisticsDescription,
        useOfForceLocation,
        otherInvolvedAgencies,
    };
};

export const createUseOfForceForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: RefContextEnum.FORM_USE_OF_FORCE.name,
        onValidate: createArbiterMFTValidationHandler(
            arbiter,
            RefContextEnum.FORM_USE_OF_FORCE.name,
            formatFieldByName
        ),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            id: {},
            useOfForceReasonAttrId: {
                fieldName: fields.USE_OF_FORCE_USE_OF_FORCE_REASON_ATTR_ID,
            },
            userProfileId: {
                fieldName: fields.USE_OF_FORCE_USER_PROFILE_ID,
            },
            useOfForceReasonOther: {
                fieldName: fields.USE_OF_FORCE_USE_OF_FORCE_REASON_OTHER,
            },
            nibrsOffenseCodePendingUnknownAttrId: {
                fieldName: fields.USE_OF_FORCE_NIBRS_OFFENSE_CODE_PENDING_UNKNOWN_ATTR_ID,
            },
            nibrsCode1: {
                fieldName: fields.USE_OF_FORCE_NIBRS_CODE_1,
            },
            nibrsCode2: {
                fieldName: fields.USE_OF_FORCE_NIBRS_CODE_2,
            },
            nibrsCode3: {
                fieldName: fields.USE_OF_FORCE_NIBRS_CODE_3,
            },
            nibrsIncidentNumberPendingUnknownAttrId: {
                fieldName: fields.USE_OF_FORCE_NIBRS_INCIDENT_NUMBER_PENDING_UNKNOWN_ATTR_ID,
            },
            nibrsIncidentNumber: {
                fieldName: fields.USE_OF_FORCE_NIBRS_INCIDENT_NUMBER,
            },
            officerAge: {
                fieldName: fields.DISPLAY_ONLY_USE_OF_FORCE_OFFICER_AGE,
            },
            officerName: {
                fieldName: fields.USE_OF_FORCE_OFFICER_NAME,
            },
            officerBadgeNumber: {
                fieldName: fields.USE_OF_FORCE_OFFICER_BADGE_NUMBER,
            },
            officerDateOfBirth: {
                fieldName: fields.USE_OF_FORCE_OFFICER_DATE_OF_BIRTH,
            },
            officerFullPartTimeAttrId: {
                fieldName: fields.USE_OF_FORCE_OFFICER_FULL_PART_TIME_ATTR_ID,
            },
            officerSexAttrId: {
                fieldName: fields.USE_OF_FORCE_OFFICER_SEX_ATTR_ID,
            },
            officerRaceAttrId: {
                fieldName: fields.USE_OF_FORCE_OFFICER_RACE_ATTR_ID,
            },
            officerRank: {
                fieldName: fields.USE_OF_FORCE_OFFICER_RANK,
            },
            wasOfficerAmbushedAttrId: {
                fieldName: fields.USE_OF_FORCE_WAS_OFFICER_AMBUSHED_ATTR_ID,
            },
            officerDutyAssignment: {
                fieldName: fields.USE_OF_FORCE_OFFICER_DUTY_ASSIGNMENT,
            },
            officerYearsOfService: {
                fieldName: fields.USE_OF_FORCE_OFFICER_YEARS_OF_SERVICE,
            },
            officerHeightFeet: {
                fieldName: fields.USE_OF_FORCE_OFFICER_HEIGHT_FEET,
            },
            officerHeightInches: {
                fieldName: fields.USE_OF_FORCE_OFFICER_HEIGHT_INCHES,
            },
            officerWeight: {
                fieldName: fields.USE_OF_FORCE_OFFICER_WEIGHT,
            },
            seniorOfficerPresentAttrId: {
                fieldName: fields.USE_OF_FORCE_SENIOR_OFFICER_PRESENT_ATTR_ID,
            },
            supervisorOnScene: {
                fieldName: fields.USE_OF_FORCE_SUPERVISOR_ON_SCENE,
            },
            onSceneSupervisorUserProfileId: {
                fieldName: fields.USE_OF_FORCE_ON_SCENE_SUPERVISOR_USER_PROFILE_ID,
            },
            onSceneSupervisorHumanResourcesNumber: {
                fieldName: fields.USE_OF_FORCE_ON_SCENE_SUPERVISOR_HUMAN_RESOURCES_NUMBER,
            },
            onSceneSupervisorUnit: {
                fieldName: fields.USE_OF_FORCE_ON_SCENE_SUPERVISOR_UNIT,
            },
            didOfficerApproachAttrId: {
                fieldName: fields.USE_OF_FORCE_DID_OFFICER_APPROACH_ATTR_ID,
            },
            wasOfficerOnDutyAttrId: {
                fieldName: fields.USE_OF_FORCE_WAS_OFFICER_ON_DUTY_ATTR_ID,
            },
            incidentResultedInCrimeReport: {
                fieldName: fields.USE_OF_FORCE_INCIDENT_RESULTED_IN_CRIME_REPORT,
            },
            officerDressAttrId: {
                fieldName: fields.USE_OF_FORCE_OFFICER_DRESS_ATTR_ID,
            },
            medicalAidReceivedAttrId: {
                fieldName: fields.USE_OF_FORCE_MEDICAL_AID_RECEIVED_ATTR_ID,
            },
            useOfForceStatistics: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_STATISTICS_ATTRIBUTE_ID,
            },
            useOfForceStatisticsDescription: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_STATISTICS_DESCRIPTION,
            },
            useOfForceLocation: createFieldset({
                fields: {
                    locationId: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_USE_OF_FORCE_LOCATION_ID,
                    },
                    positionAttrId: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_USE_OF_FORCE_POSITION_ATTR_ID,
                    },
                    description: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_USE_OF_FORCE_DESCRIPTION,
                    },
                },
            }),
            otherOfficersInvolvedButUnknown: {
                fieldName: fields.USE_OF_FORCE_OTHER_OFFICERS_INVOLVED_BUT_UNKNOWN,
            },
            minimumNumberOfUnknownOfficersInvolved: {
                fieldName: fields.USE_OF_FORCE_MINIMUM_NUMBER_OF_UNKNOWN_OFFICERS_INVOLVED,
            },
            otherInvolvedAgencies: createNItems({
                fieldName: fields.DISPLAY_USE_OF_FORCE_OTHER_INVOLVED_AGENCIES_N_ITEMS_WRAPPER,
                fields: {
                    id: {},
                    otherAgencyName: {
                        fieldName: fields.OTHER_INVOLVED_AGENCY_OTHER_AGENCY_NAME,
                    },
                    otherAgencyOri: {
                        fieldName: fields.OTHER_INVOLVED_AGENCY_OTHER_AGENCY_ORI,
                    },
                    otherAgencyCaseNumber: {
                        fieldName: fields.OTHER_INVOLVED_AGENCY_OTHER_AGENCY_CASE_NUMBER,
                    },
                },
            }),
            officerDutyTypeAttrId: {
                fieldName: fields.USE_OF_FORCE_OFFICER_DUTY_TYPE_ATTR_ID,
            },
            specialDutyContractNumber: {
                fieldName: fields.USE_OF_FORCE_SPECIAL_DUTY_CONTRACT_NUMBER,
            },
        }),
    });
};

const convertToFormModel = (formModelData = {}) => {
    const { nibrsCode1, nibrsCode2, nibrsCode3 } = formModelData;

    return {
        ...formModelData,
        nibrsCode1: nibrsCode1?.id,
        nibrsCode2: nibrsCode2?.id,
        nibrsCode3: nibrsCode3?.id,
    };
};

export const convertFromFormModel = (formModel, additionalData = {}) => {
    const { nibrsOffenseCodes } = additionalData;
    const { nibrsCode1, nibrsCode2, nibrsCode3 } = formModel;

    return {
        ...formModel,
        nibrsCode1: nibrsOffenseCodes[nibrsCode1],
        nibrsCode2: nibrsOffenseCodes[nibrsCode2],
        nibrsCode3: nibrsOffenseCodes[nibrsCode3],
    };
};

import { map } from 'lodash';
import { createSelector } from 'reselect';

import { buildViewModel } from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { formatAttributeWithOther } from '../../../attributes/utils/attributesHelpers';

import { itemIdentifiersByItemIdSelector } from '../data';

export const itemIdentifierViewModelsByItemIdSelector = createSelector(
    itemIdentifiersByItemIdSelector,
    formatAttributeByIdSelector,
    (itemIdentifiersByItemId, formatAttributeById) => (itemId) => {
        const viewModelBuilder = buildViewModel({
            mappers: [
                ({ itemIdentifierTypeAttrId, itemIdentifierTypeOtherDesc }) => ({
                    itemIdentifierType: formatAttributeWithOther(
                        formatAttributeById(itemIdentifierTypeAttrId),
                        itemIdentifierTypeOtherDesc
                    ),
                }),
            ],
        });
        return map(itemIdentifiersByItemId(itemId), viewModelBuilder);
    }
);

import React from 'react';
import classNames from 'classnames';
import testIds from '../../../../core/testIds';

/**
 *
 * @param {object} props
 * @param {array} props.children           An array of column instances
 * @param {string} [props.className]         An additional complimentary
 *     CSS class that TableColumns can inherit
 */

export default function TableColumns({ children: columns, className }) {
    return (
        <tr
            className={classNames(className, 'cobalt-table-column-headers')}
            data-test-id={testIds.TABLE_COLUMN_HEADER}
        >
            {columns}
        </tr>
    );
}

import React from 'react';
import styled from 'styled-components';
import { SkeletonText } from 'arc';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import Icon, { iconTypes, iconSizes } from './Icon';

interface PillContainerProps {
    hoverable?: boolean;
    disabled?: boolean;
    kind?: 'flatRight' | 'flatBottom';
    justifyContent?: string;
    marginBottom?: number | string;
}

const PillContainer = styled.div<PillContainerProps>`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.mediumBlue};
    border-style: solid;
    border-radius: 4px;
    position: relative;
    display: flex;
    padding: 16px;
    cursor: pointer;
    justify-content: ${(props) => props.justifyContent || 'space-between'};
    margin-bottom: ${(props) => props.marginBottom || 0};
    ${(props) =>
        props.hoverable &&
        !props.disabled &&
        `
        &:hover {
            border-color: ${props.theme.colors.cobaltBlue};
        }
        &:active {
            background-color: ${props.theme.colors.brightBlue};
        }
    `};
    ${(props) =>
        props.kind &&
        props.kind === 'flatBottom' &&
        `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `};
    ${(props) =>
        props.disabled &&
        `
        opacity: .5;
        pointer-events: none;
        cursor: default;
    `};
    ${(props) =>
        props.kind &&
        props.kind === 'flatRight' &&
        `
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    `};
`;

const Chevron = styled.div`
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

type PillProps = {
    loading?: boolean;
    showChevron?: boolean;
    testId?: string;
    onClick?: (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => void;
} & PillContainerProps &
    React.HTMLAttributes<HTMLDivElement>;

const Pill: React.FC<PillProps> = React.forwardRef<HTMLDivElement, PillProps>(
    ({ children, showChevron, testId, loading, ...props }, ref) => {
        return (
            <PillContainer
                {...props}
                data-test-id={testId}
                tabIndex={0}
                ref={ref}
                onKeyDown={(e) => {
                    if (e.keyCode === keyCodeEnum.ENTER && props.onClick) {
                        props.onClick(e);
                    }
                }}
            >
                {loading ? <SkeletonText noOfLines={3} style={{ width: '100%' }} /> : children}
                {showChevron && (
                    <Chevron>
                        <Icon
                            color="cobaltBlue"
                            type={iconTypes.OPEN_RIGHT}
                            size={iconSizes.MEDIUM}
                        />
                    </Chevron>
                )}
            </PillContainer>
        );
    }
);

export default Pill;

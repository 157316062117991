import { LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { size } from 'lodash';

import LegacyEntityDetails from '../../core/components/LegacyEntityDetails';
import AttachmentDetails from '../../core/components/EntityProfileAttachmentDetails';
import { organizationProfileDetailsSelector } from '../../core/state/ui';
import { EntityProfileActiveTarget } from '../../core/components/EntityProfileActiveTarget/EntityProfileActiveTarget';
import IdentificationDetails from './EntityProfileOrganizationIdentificationDetails';

function EntityProfileOrganizationDetails({ orgProfileDetails }) {
    return orgProfileDetails ? (
        <div>
            <EntityProfileActiveTarget />
            <IdentificationDetails orgProfileDetails={orgProfileDetails} />
            <AttachmentDetails
                entityId={orgProfileDetails.id}
                linkType={LinkTypesEnum.ORGANIZATION_PROFILE_ATTACHMENT}
            />
            {size(orgProfileDetails.legacyEntityDetails) > 0 && (
                <LegacyEntityDetails legacyEntityDetails={orgProfileDetails.legacyEntityDetails} />
            )}
        </div>
    ) : (
        <div />
    );
}

const mapStateToProps = createStructuredSelector({
    orgProfileDetails: organizationProfileDetailsSelector,
});

export default compose(
    withRouter,
    connect(mapStateToProps, null)
)(EntityProfileOrganizationDetails);

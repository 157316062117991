import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    openCaseDetailsModalForCase,
    currentCaseStatusViewModelSelector,
    currentCaseViewSelector,
    currentCaseApprovalStatusSelector,
} from '../../core/state/ui';
import CaseDetails from './CaseDetails';

/**
 * The case summary displays overview information for a case.
 */
function CaseSummary({
    caseStatusViewModel,
    currentCaseViewModel,
    caseApprovalStatus,
    openCaseDetailsModal,
}) {
    return !currentCaseViewModel ? (
        <div />
    ) : (
        <CaseDetails
            caseViewModel={currentCaseViewModel}
            caseStatusViewModel={caseStatusViewModel}
            caseApprovalStatus={caseApprovalStatus}
            openLegacyCaseModal={openCaseDetailsModal}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    currentCaseViewModel: currentCaseViewSelector,
    caseStatusViewModel: currentCaseStatusViewModelSelector,
    caseApprovalStatus: currentCaseApprovalStatusSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openCaseDetailsModal: () => dispatch(openCaseDetailsModalForCase()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CaseSummary);

import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { flowRight, pick } from 'lodash';

import { warrantTitleViewModelsSelector } from '~/client-common/core/domain/warrant-titles/state/ui';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { WARRANT_WARRANT_NUMBER } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { submitWarrantNumberSearch } from '../state/ui';
import { createModalSelector } from '../../../core/box/state/ui';
import changeWarrantNumberForm from '../state/forms/changeWarrantNumberForm';
import { RRFText } from '../../../core/forms/components/Text';
import Row from '../../../core/components/Row';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import ExistingWarrantTitles from './ExistingWarrantTitles';
import WarrantNumberSearchText from './WarrantNumberSearchText';

const strings = componentStrings.warrants.warrant.ChangeWarrantNumberForm;
const modalContext = { name: boxEnum.CHANGE_WARRANT_NUMBER_MODAL };

const mapStateToProps = createStructuredSelector({
    existingWarrantIds: createModalSelector(modalContext, 'existingWarrantIds'),
    warrantTitleViewModels: warrantTitleViewModelsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    submitWarrantNumberSearch: () =>
        dispatch(
            submitWarrantNumberSearch({
                boxContext: modalContext,
                form: changeWarrantNumberForm,
                fieldPath: 'newWarrantNumber',
            })
        ),
});

/**
 * Form for changing an existing warrant's `warrantNumber`.
 */
export default flowRight(
    connect(mapStateToProps, mapDispatchToProps),
    withFields([WARRANT_WARRANT_NUMBER])
)(function ChangeWarrantNumberForm({
    submitWarrantNumberSearch,
    existingWarrantIds,
    warrantTitleViewModels,
    fieldDisplayNames,
}) {
    const existingWarrantTitleViewModels = pick(warrantTitleViewModels, existingWarrantIds);
    return (
        <ReactReduxForm {...changeWarrantNumberForm}>
            <Row>
                <RRFText
                    label={strings.warrantNumber(fieldDisplayNames.WARRANT_WARRANT_NUMBER)}
                    path="warrantNumber"
                    width={250}
                    disabled={true}
                />
            </Row>
            <Row>
                <WarrantNumberSearchText
                    onSearch={submitWarrantNumberSearch}
                    context={RefContextEnum.FORM_WARRANT_CHANGE_WARRANT_NUMBER.name}
                    label={strings.newWarrantNumber(fieldDisplayNames.WARRANT_WARRANT_NUMBER)}
                    path="newWarrantNumber"
                />
            </Row>
            <Row>
                <ExistingWarrantTitles warrantTitleViewModels={existingWarrantTitleViewModels} />
            </Row>
        </ReactReduxForm>
    );
});

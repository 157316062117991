import {
    ElasticInvolvedLocation,
    ElasticOrganization,
    ElasticPerson,
    ElasticProperty,
    ElasticReport,
    ElasticVehicle,
    ElasticWarrant,
} from '@mark43/rms-api';

import * as actionTypes from './types';

export const setCurrentCaseLinkedProfiles = ({
    elasticInvolvedLocations,
    elasticOrganizations,
    elasticPersons,
    elasticProperty,
    elasticReports,
    elasticVehicles,
    elasticWarrants,
}: {
    elasticInvolvedLocations: ElasticInvolvedLocation[];
    elasticOrganizations: ElasticOrganization[];
    elasticPersons: ElasticPerson[];
    elasticProperty: ElasticProperty[];
    elasticReports: ElasticReport[];
    elasticVehicles: ElasticVehicle[];
    elasticWarrants: ElasticWarrant[];
}) => {
    return {
        type: actionTypes.SET_CURRENT_CASE_RELATED_ENTITIES,
        payload: {
            elasticInvolvedLocations,
            elasticOrganizations,
            elasticPersons,
            elasticProperty,
            elasticReports,
            elasticVehicles,
            elasticWarrants,
        },
    };
};

import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ElasticSearchTypeEnum, EntityPermission, SavedSearchView } from '@mark43/rms-api';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import Modal, { OverrideModalPropsFunction } from '../../../core/overlays/components/Modal';
import { SearchModuleType } from '../../../../core/typings/search-module';
import useOverlayStoreCustomPropertiesGetter from '../../core/hooks/useOverlayStoreCustomPropertiesGetter';
import { useOnShareSearch } from '../../core/hooks/useShareSearch';
import { convertFromFormModel } from '../../../tasks/dashboard/state/forms/tasksDashboardSearchForm';
import SavedSearchModalPermissionContent, {
    useSavedSearchGetEntityPermissions,
} from './SavedSeachModalPermissionContent';

const title = componentStrings.search.savedSearch.ShareSavedSearchModal.title;
const labels = componentStrings.search.savedSearch.ShareSavedSearchModal.labels;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

interface ShareSavedSearchModalProps {
    searchModule: SearchModuleType;
}

type SavedSearchModalCustomProperties = {
    savedSearchView: SavedSearchView;
};

const ShareSavedSearchModal: React.FC<ShareSavedSearchModalProps> = ({ searchModule }) => {
    const onShareSearch = useOnShareSearch(searchModule);
    const getEntityPermissions = useSavedSearchGetEntityPermissions();
    const getCustomProperties = useOverlayStoreCustomPropertiesGetter<SavedSearchModalCustomProperties>(
        overlayIdEnum.SAVED_SEARCH_SHARE_MODAL
    );

    const handleShare = useCallback(() => {
        const entityPermissions = getEntityPermissions();
        const { savedSearchView } = getCustomProperties();

        /*  tasksDashboardSearch is unusual because we convert the query string in
              tasksDashboardSearch.buildLoadTasksSavedSearchesActionCreator */
        if (searchModule.elasticSearchType === ElasticSearchTypeEnum.TASK.name) {
            const queryObject = JSON.parse(savedSearchView.query);

            savedSearchView.query = JSON.stringify({
                ...queryObject,
                query: convertFromFormModel(queryObject?.query),
            });
        }

        onShareSearch({
            ...savedSearchView,
            isShared: true,
            entityPermissions: entityPermissions as EntityPermission[],
        });
    }, [onShareSearch, getEntityPermissions, getCustomProperties, searchModule]);

    const overrideModalProps = useCallback<
        OverrideModalPropsFunction<{ savedSearchView: SavedSearchView }>
    >(({ overlayState }) => {
        const { savedSearchView } = overlayState.customProperties || {};
        const { name } = savedSearchView || {};

        return { title: joinTruthyValues([title, name], ' ') };
    }, []);

    return (
        <Modal
            okText={labels.buttonShare}
            cancelText={labels.buttonCancel}
            id={overlayIdEnum.SAVED_SEARCH_SHARE_MODAL}
            onSave={handleShare}
            overrideModalProps={overrideModalProps}
        >
            <ContentWrapper>
                <SavedSearchModalPermissionContent
                    getCustomProperties={getCustomProperties}
                    roleAddItemOnEmpty={false}
                />
            </ContentWrapper>
        </Modal>
    );
};

export default ShareSavedSearchModal;

import { filter } from 'lodash';
import { CaseStatus } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'caseStatuses';
const caseStatusesModule = createNormalizedModule<CaseStatus>({
    type: NEXUS_STATE_PROP,
    key: 'caseId',
});

// SELECTORS
export const caseStatusesSelector = caseStatusesModule.selectors.entitiesSelector;
export const caseStatusesByCaseIdSelector = createSelector(
    caseStatusesSelector,
    (caseStatuses) => (caseId: number) => filter(caseStatuses, { caseId })
);

// REDUCER
export default caseStatusesModule.reducerConfig;

import React from 'react';
import styled from 'styled-components';
import { Icon as ArcIcon } from 'arc';
import { Tooltip, TooltipProps } from '../../../modules/core/components/tooltip';

const HelpTextContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    cursor: pointer;
    line-height: 0;
    margin-top: -2px;

    .active-icon {
        display: none;
    }

    &:hover {
        .active-icon {
            display: inline-block;
        }

        .inactive-icon {
            display: none;
        }
    }
`;

const StyledIcon = styled(ArcIcon)`
    height: 1rem;
    width: 1rem;
`;

export type HelpTextProps = Omit<TooltipProps, 'children'>;

/**
 * An icon that displays a help tooltip
 */
const HelpText: React.FC<HelpTextProps> = ({ side = 'top', ...otherProps }) => {
    return (
        <>
            <Tooltip side={side} {...otherProps}>
                <HelpTextContainer className="help-text-container">
                    <StyledIcon
                        size="sm"
                        icon="TooltipActive"
                        color="brand.default"
                        className="active-icon"
                    />
                    <StyledIcon
                        size="sm"
                        icon="TooltipInactive"
                        color="brand.default"
                        className="inactive-icon"
                    />
                </HelpTextContainer>
            </Tooltip>
        </>
    );
};

export default HelpText;

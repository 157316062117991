import Promise from 'bluebird';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import createCard from '../../utils/createCard';

const baseCard = createCard({
    name: reportCardEnum.CUSTODIAL.name,
    baseSelector: (state) => state.ui.report.custodialPropertyCard,
    anchor: 'custodial-property-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save() {
            return (dispatch) => {
                return Promise.resolve(dispatch(baseCard.actionCreators.savingSuccess()));
            };
        },
    },
};

import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { isFunction, noop } from 'lodash';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { Button as ArcButton } from '../../../core/components/Button';
import Card from '../../../../legacy-redux/components/core/Card';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import { InlineBanner } from '../../../core/components/InlineBanner';
import testIds from '../../../../core/testIds';
import {
    selectItemProfiles,
    selectedItemProfileIdsSelector,
    loadingEvidenceDataSelector,
    loadingEvidenceDataErrorMessageSelector,
    canEditCustodialReportSelector,
    currentReportHasItemProfilesSelector,
} from '../state/ui';
import {
    currentReportCardUITitleByTypeSelector,
    currentReportIdSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import {
    cpsCardViewableItemProfileIdsSelector,
    paginatedItemProfileIdsInReportWithCoCSelector,
    paginatedItemProfileIdsInReportWithoutCoCSelector,
    cpsCardIsFetchingBatchHydratedEvidenceItems,
    fetchPaginatedEvidenceHydratedItems,
} from '../../../evidence/cps-card/state/ui';

import custodialPropertyCard from '../../core/state/ui/custodialPropertyCard';
import withCard from '../../core/utils/withCard';
import CustodialPropertyCardSection from './CustodialPropertyCardSection';

const strings = componentStrings.reports.custodialPropertySummary.CustodialPropertyCard;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.mediumLightGrey};
`;

const StyledShowMoreButton = styled(ArcButton)`
    width: 90%;
    background-color: ${({ theme }) => theme.colors.extraLightGrey};
`;

const ShowMoreButton = () => {
    const reportId = useSelector(currentReportIdSelector);
    const viewableReportItemProfileIds = useSelector(cpsCardViewableItemProfileIdsSelector);

    const itemProfileIdsForReport = useSelector(itemProfilesInReportSelector);
    const isLoading = useSelector(cpsCardIsFetchingBatchHydratedEvidenceItems);

    const dispatch = useDispatch();

    const showMore = useCallback(() => {
        dispatch(custodialPropertyCard.actionCreators.resetState());
        dispatch(fetchPaginatedEvidenceHydratedItems()).catch(() => {
            dispatch(
                custodialPropertyCard.actionCreators.setErrorMessages(strings.batchRequestFailed)
            );
        });
    }, [dispatch]);

    useEffect(() => {
        if (reportId) {
            showMore();
        }
    }, [dispatch, reportId, showMore]);

    if (!reportId) {
        return null;
    }

    const itemProfileIds = itemProfileIdsForReport(reportId);

    if (viewableReportItemProfileIds.length === itemProfileIds.length) {
        return null;
    }

    return (
        <Wrapper>
            <StyledShowMoreButton
                width={100}
                variant="outline"
                onClick={showMore}
                disabled={isLoading}
            >
                {strings.showMore}
            </StyledShowMoreButton>
        </Wrapper>
    );
};

function CustodialPropertyCard({
    card = {},
    canEdit,
    onEdit,
    loadingEvidenceData,
    loadingEvidenceDataErrorMessage,
    itemProfileIdsWithCoC,
    itemProfileIdsWithoutCoC,
    selectedItemProfileIds,
    selectItemProfiles,
    saveCard,
    currentReportHasItemProfiles,
    currentReportCardUITitleByType,
}) {
    const { summaryMode } = card;

    const cardTitle = currentReportCardUITitleByType(reportCardEnum.CUSTODIAL.id);

    // the loading and failure states mean we render something different from
    // the default content
    let overridingContent;
    if (loadingEvidenceDataErrorMessage) {
        overridingContent = (
            <InlineBanner status="error">{loadingEvidenceDataErrorMessage}</InlineBanner>
        );
    } else if (loadingEvidenceData) {
        overridingContent = <SimpleLoading />;
    }

    // sections displaying item profiles
    const sections = (
        <div>
            <CustodialPropertyCardSection
                summaryMode={summaryMode}
                canEdit={canEdit}
                itemProfileIds={itemProfileIdsWithCoC}
                itemsHaveCoC={true}
                selectedItemProfileIds={selectedItemProfileIds}
                selectItemProfiles={selectItemProfiles}
            />
            <CustodialPropertyCardSection
                summaryMode={summaryMode}
                canEdit={canEdit}
                itemProfileIds={itemProfileIdsWithoutCoC}
                itemsHaveCoC={false}
            />
            <FeatureFlagged flag="EVIDENCE_PAGINATE_HYDRATED_ITEMS_ENABLED">
                <ShowMoreButton />
            </FeatureFlagged>
        </div>
    );

    return (
        <Card
            anchor={card.anchor}
            title={cardTitle}
            className="custodial-property-card"
            summaryMode={summaryMode}
            renderContent={() => overridingContent || <div>{sections}</div>}
            canEdit={canEdit}
            onEdit={onEdit}
            hideFooter={summaryMode}
            saveDisabled={!currentReportHasItemProfiles}
            onSave={saveCard}
            errors={card.errorMessages}
            saving={card.saving}
            testId={testIds.CUSTODIAL_PROPERTY_CARD}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    loadingEvidenceData: loadingEvidenceDataSelector,
    loadingEvidenceDataErrorMessage: loadingEvidenceDataErrorMessageSelector,
    itemProfileIdsWithCoC: paginatedItemProfileIdsInReportWithCoCSelector,
    itemProfileIdsWithoutCoC: paginatedItemProfileIdsInReportWithoutCoCSelector,
    canEdit: canEditCustodialReportSelector,
    selectedItemProfileIds: selectedItemProfileIdsSelector,
    reportId: currentReportIdSelector,
    currentReportHasItemProfiles: currentReportHasItemProfilesSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onEdit() {
        if (isFunction(ownProps.editCallback)) {
            ownProps.editCallback(() => ownProps.onEdit(), ownProps.canEdit);
        } else {
            ownProps.onEdit();
        }
    },
    selectItemProfiles: (ids) => dispatch(selectItemProfiles(ids)),
    saveCard: () => dispatch(custodialPropertyCard.actionCreators.save()).catch(noop),
    resetError: () => dispatch(custodialPropertyCard.actionCreators.setErrorMessages([])),
});

export default compose(
    withCard(custodialPropertyCard),
    connect(mapStateToProps, mapDispatchToProps)
)(CustodialPropertyCard);

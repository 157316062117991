import { createContext } from 'react';
import { noop } from 'lodash';
import { TaskView } from '@mark43/rms-api';

type TasksTableContextProps = {
    refreshTasks: () => void;
    setModalTask: (task?: TaskView) => void;
    modalTask?: TaskView;
};

export const TasksTableContext = createContext<TasksTableContextProps>({
    refreshTasks: noop,
    setModalTask: noop,
    modalTask: undefined,
});

import { find, includes, isEmpty, map, pick, reject } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Fields, Observer, lifecycleOptions } from 'markformythree';
import {
    AttributeTypeEnum,
    ComplianceGroupEnum,
    EntityTypeEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';

import * as fields from '~/client-common/core/enums/universal/fields';
import { LOCATION_ENTITY_LINK_MODEL_KEYS } from '~/client-common/core/domain/location-entity-links/state/data';
import buildLocationEntityLinkKey from '~/client-common/core/domain/location-entity-links/utils/buildLocationEntityLinkKey';
import { formatDojSchoolByIdSelector } from '~/client-common/core/domain/doj-schools/state/ui';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';

import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import testIds from '../../../../../core/testIds';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { LocationSummaryViewWrapper } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapper';
import { locationEntityLinkSummaryVariants } from '../../../../records/core/components/summaries/locations/LocationEntityLinkSummary';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import Row from '../../../../core/components/Row';
import { createNItemsAdder, createNItemsRemover } from '../../utils/nItemsHelpers';
import {
    formName,
    STOP_IS_LOCATION_K_12_PUBLIC_SCHOOL_PATH,
    STOP_LOCATIONS_PATH,
    STOP_LOCATION_DESCRIPTION_PATH,
    STOP_LOCATION_POSITION_ATTR_ID_PATH,
    STOP_LINKS_LOCATION_PATH,
    getStopForm,
    findIndexOfNItemsLocationEntityLink,
} from '../../state/forms/stopForm';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTDOJSchoolSelect } from '../../../../core/forms/components/selects/DOJSchoolSelect';
import { ArbiterMFTCheckbox } from '../../../../core/forms/components/checkboxes/Checkbox';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import WithComplianceGroup from '../../../../core/components/WithComplianceGroup';

function onAddSuccess(location, locationEntityLinkModel) {
    getStopForm().set(STOP_LINKS_LOCATION_PATH, locationEntityLinkModel);
    return createNItemsAdder({
        getForm: getStopForm,
        path: STOP_LOCATIONS_PATH,
        defaults: {
            [STOP_LOCATION_DESCRIPTION_PATH]: undefined,
            [STOP_LOCATION_POSITION_ATTR_ID_PATH]: undefined,
        },
    })(locationEntityLinkModel);
}

function onRemoveSuccess(removedLocationEntityLink, remainingLocationEntityLinks = []) {
    const form = getStopForm();
    form.set(STOP_LINKS_LOCATION_PATH, undefined);
    form.set(STOP_IS_LOCATION_K_12_PUBLIC_SCHOOL_PATH, undefined);

    const formStateLocationEntityLinks = form.get(STOP_LOCATIONS_PATH) || [];
    const remainingLocationEntityLinkKeys = map(
        remainingLocationEntityLinks,
        buildLocationEntityLinkKey
    );
    const locationEntityLinkToRemove =
        find(formStateLocationEntityLinks, (formStateLocationEntityLink) => {
            const formStateLocationEntityLinkKey = buildLocationEntityLinkKey(
                formStateLocationEntityLink
            );
            return !includes(remainingLocationEntityLinkKeys, formStateLocationEntityLinkKey);
        }) || {};
    const locationEntityLinkKeysToRemoveBy = pick(
        locationEntityLinkToRemove,
        LOCATION_ENTITY_LINK_MODEL_KEYS
    );

    return createNItemsRemover({
        getForm: getStopForm,
        path: STOP_LOCATIONS_PATH,
    })(locationEntityLinkKeysToRemoveBy);
}

function StopCardLocationSection({ summaryMode, stopId, stop }) {
    const formatDojSchoolById = useSelector(formatDojSchoolByIdSelector);
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            render={() => (
                <VisibilityObserver
                    path={STOP_LOCATIONS_PATH}
                    formName={formName}
                    render={({ hidden }) =>
                        !hidden && (
                            <CardSection
                                fieldName={
                                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_STOP_LINK_TYPE
                                }
                                testId={testIds.STOP_CARD_LOCATIONS_SECTION}
                            >
                                <Observer
                                    formName={formName}
                                    subscriptions={{
                                        locationsModel: [STOP_LOCATIONS_PATH, Fields.MODEL],
                                    }}
                                    render={({ locationsModel }) => (
                                        <LocationSummaryViewWrapper
                                            summaryMode={summaryMode}
                                            locationEntityLinkSummaryVariant={
                                                summaryMode
                                                    ? undefined
                                                    : locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION
                                            }
                                            entityType={EntityTypeEnum.STOP.name}
                                            entityId={stopId}
                                            linkType={LinkTypesEnum.LOCATION_OF_STOP}
                                            allowMultiple={false}
                                            onLocationAdd={onAddSuccess}
                                            onLocationRemove={onRemoveSuccess}
                                            additionalLocationEntityLinks={reject(
                                                locationsModel,
                                                // entityId doesn't exist yet in reports without Stops:
                                                // 1. legacy reports
                                                // 2. when the report definition had the Stop card added to
                                                //    it after this report was created
                                                'entityId'
                                            )}
                                            additionalLocationContent={(locationBundle = {}) => {
                                                if (summaryMode) {
                                                    return null;
                                                }

                                                const { locationEntityLink } = locationBundle;
                                                const locationEntityLinkKey = buildLocationEntityLinkKey(
                                                    locationEntityLink
                                                );
                                                const index = findIndexOfNItemsLocationEntityLink({
                                                    path: STOP_LOCATIONS_PATH,
                                                    locationEntityLinkKey,
                                                });

                                                // Only render additional fields if the name currently exists on the form.
                                                if (index < 0) {
                                                    return null;
                                                }

                                                const completeDescriptionPath = `${STOP_LOCATIONS_PATH}[${index}].${STOP_LOCATION_DESCRIPTION_PATH}`;
                                                const completePositionPath = `${STOP_LOCATIONS_PATH}[${index}].${STOP_LOCATION_POSITION_ATTR_ID_PATH}`;
                                                return (
                                                    <>
                                                        <Row>
                                                            <ArbiterMFTAttributeSelect
                                                                path={completePositionPath}
                                                                attributeType={
                                                                    AttributeTypeEnum
                                                                        .LOCATION_POSITION.name
                                                                }
                                                                length="md"
                                                            />
                                                        </Row>
                                                        <Row>
                                                            <ArbiterMFTTextArea
                                                                path={completeDescriptionPath}
                                                                length="lg"
                                                            />
                                                        </Row>
                                                    </>
                                                );
                                            }}
                                        />
                                    )}
                                />
                                <WithComplianceGroup
                                    complianceGroup={ComplianceGroupEnum.CALIFORNIA.name}
                                >
                                    {summaryMode ? (
                                        !isEmpty(stop) && (
                                            <SummaryList labelWidth={220} contentWidth={320}>
                                                <SummaryRow
                                                    fieldName={
                                                        fields.STOP_IS_LOCATION_K_12_PUBLIC_SCHOOL
                                                    }
                                                >
                                                    {booleanToYesNo(stop.isLocationK12PublicSchool)}
                                                </SummaryRow>
                                                <SummaryRow fieldName={fields.STOP_DOJ_SCHOOL_ID}>
                                                    {formatDojSchoolById(stop.dojSchoolId)}
                                                </SummaryRow>
                                            </SummaryList>
                                        )
                                    ) : (
                                        <>
                                            <Row>
                                                <ArbiterMFTCheckbox path="stop.isLocationK12PublicSchool" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTDOJSchoolSelect path="stop.dojSchoolId" />
                                            </Row>
                                        </>
                                    )}
                                </WithComplianceGroup>
                            </CardSection>
                        )
                    }
                />
            )}
        />
    );
}

export default StopCardLocationSection;

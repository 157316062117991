import React from 'react';
import styled from 'styled-components';

const EnhancedCaseSectionHeader = styled.div`
    align-items: center;
    background-color: ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.darkGrey};
    display: flex;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-lg);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    height: 40px;
    justify-content: space-between;
    letter-spacing: -0.15px;
    line-height: 17px;
    margin-bottom: 15px;
    padding: 6px 10px 4px;
`;

export default function CaseSection({ sectionActionButton, title, children }) {
    return (
        <>
            <EnhancedCaseSectionHeader>
                <span>{title}</span>
                <span>{sectionActionButton}</span>
            </EnhancedCaseSectionHeader>
            {children}
        </>
    );
}

import { Image } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'images';
const imagesModule = createNormalizedModule<Image>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const imagesSelector = imagesModule.selectors.entitiesSelector;

// REDUCER
export default imagesModule.reducerConfig;

import { isArray, flow, flatMap, keys, reduce, map, assignWith, filter } from 'lodash';

import {
    convertElasticOffenseCodeDetailToOffenseCode,
    augmentOffenseCodeWithOffenseCodeView,
} from '~/client-common/helpers/offenseCodesHelpers';

import recursivelyGetAttributeAndOffenseCodeDetailsFromObject from './recursivelyGetAttributeAndOffenseCodeDetailsFromObject';

const convertOffenseCodeDetailToOffenseCodeView = flow(
    convertElasticOffenseCodeDetailToOffenseCode,
    augmentOffenseCodeWithOffenseCodeView
);

const assignConcatArray = (a, b) => (isArray(a) ? a.concat(b) : b);

const getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults = (
    results,
    { offenseCodes = {} } = {}
) =>
    reduce(
        keys(results),
        (acc, key) =>
            results[key].items.length
                ? assignWith(
                      acc,
                      ...flatMap(results[key].items, (item) => {
                          const result = recursivelyGetAttributeAndOffenseCodeDetailsFromObject(
                              item
                          );
                          return {
                              elasticAttributeDetails: result.attributeDetails,
                              offenseCodeViews: map(
                                  filter(
                                      result.offenseCodeDetails,
                                      (offenseCode) => !offenseCodes[offenseCode.id]
                                  ),
                                  convertOffenseCodeDetailToOffenseCodeView
                              ),
                          };
                      }),
                      assignConcatArray
                  )
                : acc,
        { elasticAttributeDetails: [], offenseCodeViews: [] }
    );

export default getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults;

import { BulkImportRequest, GeoAlias, MasterlocationsgeoaliasesResource } from '@mark43/rms-api';
import { createResource } from '../../../lib/resources/Resource';
import { req } from '../../../lib/ajax';

export const geoAliasesResource = createResource({
    name: 'Geo Aliases Admin Resource',
    methods: {
        getAliases() {
            return req<MasterlocationsgeoaliasesResource.GetCustomAliases>({
                method: 'GET',
                url: 'master/locations/geoaliases',
            });
        },
        getAliasesByIds(ids: number[]) {
            return req<MasterlocationsgeoaliasesResource.GetAliasesByIds>({
                method: 'POST',
                url: 'master/locations/geoaliases/batch',
                data: ids,
            });
        },
        upsertAlias(alias: GeoAlias) {
            return req<MasterlocationsgeoaliasesResource.SaveAlias>({
                method: 'POST',
                url: 'master/locations/geoaliases',
                data: alias,
            });
        },
        deleteAlias(alias: GeoAlias) {
            return req<MasterlocationsgeoaliasesResource.RemoveAlias>({
                method: 'DELETE',
                url: `master/locations/geoaliases/${alias.id}/`,
            });
        },
        purgeAliases() {
            return req<MasterlocationsgeoaliasesResource.Purge1>({
                method: 'DELETE',
                url: 'master/locations/geoaliases/purge',
            });
        },
        importGeoAliases(bulkImport: BulkImportRequest) {
            return req<MasterlocationsgeoaliasesResource.ImportGeoAliasesCsvFile>({
                method: 'POST',
                url: `master/locations/geoaliases/import/${bulkImport.fileId}/`,
            }).then((responseData) => {
                // This modification is necessary to get the `createBulkImportActionCreator` to work
                // properly when the the resource method's Promise resolves. The function above tries
                // to access `bulkImportResponse.success`, which would not be available when the
                // response is a boolean value.
                return { success: responseData };
            });
        },
        validateGeoAliasesImport(bulkImport: BulkImportRequest) {
            return req<MasterlocationsgeoaliasesResource.ValidateGeoAliasCsvFile>({
                method: 'POST',
                url: `master/locations/geoaliases/import/validate/${bulkImport.fileId}/`,
            }).then((errorMessages) => {
                if (!errorMessages || errorMessages.length === 0) {
                    return { success: true };
                }

                return {
                    success: false,
                    errorMessages,
                };
            });
        },
        exportGeoAliases() {
            return req<MasterlocationsgeoaliasesResource.ExportGeoAliasesToCsvFile>({
                method: 'GET',
                url: 'master/locations/geoaliases/export',
            });
        },
    },
});

import { CrashLocation, CrashLocationCardBundle } from '@mark43/rms-api';
import { VersionableProperties } from '../../../../../types';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { WithCustomProperties } from '../../../dynamic-fields/types';

export type CrashLocationWithCustomProperties = WithCustomProperties<CrashLocation>;

type CrashLocationCardBundleCrashLocationForUpsert = Omit<
    CrashLocationWithCustomProperties,
    VersionableProperties | 'id'
> & {
    id?: number;
};

export type CrashLocationCardBundleForUpsert = {
    crashLocation: CrashLocationCardBundleCrashLocationForUpsert;
};

export const NEXUS_STATE_PROP = 'crashLocations';

const module = createNormalizedModule<CrashLocationWithCustomProperties>({
    type: NEXUS_STATE_PROP,
});

export const crashLocationsWhereSelector = module.selectors.entitiesWhereSelector;

const UPDATE_CRASH_LOCATION_START = 'crash-details/UPDATE_CRASH_LOCATION_START';
const UPDATE_CRASH_LOCATION_SUCCESS = 'crash-details/UPDATE_CRASH_LOCATION_SUCCESS';
const UPDATE_CRASH_LOCATION_FAILURE = 'crash-details/UPDATE_CRASH_LOCATION_FAILURE';

export const updateCrashLocationStart = (
    crashLocationCardBundle: CrashLocationCardBundleForUpsert
) => {
    return {
        type: UPDATE_CRASH_LOCATION_START,
        payload: crashLocationCardBundle,
    };
};

export const updateCrashLocationSuccess = (crashLocationCardBundle: CrashLocationCardBundle) => {
    return {
        type: UPDATE_CRASH_LOCATION_SUCCESS,
        payload: crashLocationCardBundle,
    };
};

export const updateCrashLocationFailure = (message: string) => {
    return {
        type: UPDATE_CRASH_LOCATION_FAILURE,
        payload: message,
    };
};

export default module.reducerConfig;

import keyMirror from 'keymirror';

export const policeSubdivisionAttrTypes = [
    'SUBDIVISION_DEPTH_5',
    'SUBDIVISION_DEPTH_4',
    'SUBDIVISION_DEPTH_3',
    'SUBDIVISION_DEPTH_2',
    'SUBDIVISION_DEPTH_1',
];

export const fireSubdivisionAttrTypes = [
    'SUBDIVISION_FIRE_DEPTH_5',
    'SUBDIVISION_FIRE_DEPTH_4',
    'SUBDIVISION_FIRE_DEPTH_3',
    'SUBDIVISION_FIRE_DEPTH_2',
    'SUBDIVISION_FIRE_DEPTH_1',
];

export const emsSubdivisionAttrTypes = [
    'SUBDIVISION_MEDICAL_DEPTH_5',
    'SUBDIVISION_MEDICAL_DEPTH_4',
    'SUBDIVISION_MEDICAL_DEPTH_3',
    'SUBDIVISION_MEDICAL_DEPTH_2',
    'SUBDIVISION_MEDICAL_DEPTH_1',
];

export const steps = keyMirror({
    UPLOAD_SHAPEFILES: null,
    MANAGE_SUBDIVISIONS: null,
    PREVIEW: null,
});

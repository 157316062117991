import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import Page from '../../core/components/Page';
import ScrollableUnderSubheader from '../../core/components/ScrollableUnderSubheader';
import { getComplianceExportTypeEnum } from '../util/complianceExportTypeHelpers';
import { ComplianceExportContextProvider } from '../contexts/ComplianceExportContext';
import { currentUserDepartmentAgencyIdSelector } from '../../core/current-user/state/ui';

import useSubmissionType from '../hooks/useIsExternalSubmission';
import { useGetLastExportedDate } from '../hooks';
import { ComplianceTypeProps } from '../types';
import ComplianceSubheader from './ComplianceSubheader';
import ComplianceSidebar from './ComplianceSidebar';

const ComplianceContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px;
`;

type ComplianceExportTabsContainerProps = ComplianceTypeProps & {
    children: React.ReactNode;
};

const ComplianceExportTabsContainer: React.FC<ComplianceExportTabsContainerProps> = ({
    location,
    params,
    children,
    router,
}) => {
    const currentUserAgencyId = useSelector(currentUserDepartmentAgencyIdSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const [currentAgencyId, setCurrentAgencyId] = useState<number | undefined>(currentUserAgencyId);
    const { complianceExportType: complianceTypeParam } = params;
    const complianceExportType = getComplianceExportTypeEnum(complianceTypeParam);
    const { isNibrs } = useSubmissionType(complianceTypeParam);

    if (isNibrs && applicationSettings.RMS_NIBRS_DISABLE_NIBRS_DASHBOARD_ENABLED) {
        router.push('compliance/dashboard');
    }

    const {
        callResource: callGetLastExportedDateResource,
        loading: getLastExportedDateLoadingState,
    } = useGetLastExportedDate(complianceExportType);

    useEffect(() => {
        if (isNibrs && applicationSettings.RMS_NIBRS_DISABLE_NIBRS_DASHBOARD_ENABLED) {
            return;
        }
        if (currentAgencyId) {
            callGetLastExportedDateResource(currentAgencyId);
        }
    }, [
        applicationSettings.RMS_NIBRS_DISABLE_NIBRS_DASHBOARD_ENABLED,
        callGetLastExportedDateResource,
        currentAgencyId,
        isNibrs,
    ]);

    return (
        <ComplianceExportContextProvider
            value={{
                complianceExportType,
                currentAgencyId,
                setCurrentAgencyId,
                isNibrs,
                callGetLastExportedDateResource,
                getLastExportedDateLoadingState,
            }}
        >
            <Page>
                <ComplianceSubheader location={location} />
                <ScrollableUnderSubheader style={{ display: 'flex' }}>
                    {isNibrs && (
                        <FeatureFlagged flag="RMS_COMPLIANCE_RESUBMISSION_WORKFLOW_ENABLED">
                            <ComplianceSidebar />
                        </FeatureFlagged>
                    )}
                    <ComplianceContentWrapper>{children}</ComplianceContentWrapper>
                </ScrollableUnderSubheader>
            </Page>
        </ComplianceExportContextProvider>
    );
};

export default ComplianceExportTabsContainer;

import { get } from 'lodash';
import { createSelector } from 'reselect';

import { reportByIdSelector } from '../data';
import { reportShortTitleByReportIdSelector } from '../../../report-short-titles/state/data';

export * from './arrests';
export * from './behavioralCrises';
export * from './communityInfos';
export * from './eventDetails';
export * from './fieldContacts';
export * from './stops';
export * from './incidents';
export * from './offenses';
export * from './supplements';
export * from './useOfForces';
export * from './useOfForceSubjects';
export * from './missingPersons';
export * from './bookings';
export * from './citations';
export * from './names';
export * from './relationships';
export * from './trafficCrashes';
export * from './towVehicleReleases';

// SELECTORS
export const reportRenByIdSelector = createSelector(
    reportByIdSelector,
    reportShortTitleByReportIdSelector,
    (reportById, reportShortTitleByReportId) => (reportId) => {
        return get(
            reportById(reportId) || reportShortTitleByReportId(reportId),
            'reportingEventNumber'
        );
    }
);

import { map, parseInt, compact } from 'lodash';
import { createSelector } from 'reselect';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import adminSecurityResource from '../../../../../legacy-redux/resources/departmentAdminSecurityResource';
import {
    openErrorModal,
    openLoadingModal,
    closeLoadingModal,
} from '../../../../../legacy-redux/actions/boxActions';
import {
    CHECK_PROGRESS_SUCCESS,
    CREATE_DEPARTMENT_SUCCESS,
    PAGE_LOAD_START,
    PAGE_LOAD_SUCCESS,
} from '../data';

const PREVIOUS_STEP = 'department-setup/PREVIOUS_STEP';
const NEXT_STEP = 'department-setup/NEXT_STEP';
const SET_SELECTED = 'department-setup/SET_SELECTED';

export function setSelected(index) {
    return {
        type: SET_SELECTED,
        payload: index,
    };
}

export function previousStep() {
    return {
        type: PREVIOUS_STEP,
    };
}

export function nextStep() {
    return {
        type: NEXT_STEP,
    };
}

export function nextStepWithSubDomainCheck(subDomain) {
    return (dispatch) => {
        dispatch(openLoadingModal());
        return adminSecurityResource
            .isDepartmentSubDomainAvailable(subDomain)
            .then((isDomainAvailable) => {
                dispatch(closeLoadingModal());
                if (isDomainAvailable) {
                    dispatch(nextStep());
                } else {
                    dispatch(openErrorModal({ paragraphs: ['Subdomain is already taken.'] }));
                }
            })
            .catch((err) => {
                dispatch(closeLoadingModal());
                dispatch(
                    openErrorModal({
                        paragraphs: [`Unable to check if subdomain available. ${err.message}`],
                    })
                );
            });
    };
}

const initialState = {
    // selected index is also the last completed index because
    // you can't go forward without clicking next (can't click on tabs after current tab),
    // you can only click on tabs before current tab
    selectedIndex: 0,
    isRMSCompleted: false,
    isCompleted: false,
    departmentProfiles: [],
    checkProgressResult: null,
    departmentCreationResult: null,
};

function departmentSetupAdminUiReducer(state = initialState, action) {
    // ake sure selectedIndex is a number to do math
    const currentStateIndex = parseInt(state.selectedIndex);
    switch (action.type) {
        case PREVIOUS_STEP:
            return {
                ...state,
                selectedIndex: currentStateIndex > 0 ? currentStateIndex - 1 : 0,
            };
        case NEXT_STEP:
            return {
                ...state,
                selectedIndex: currentStateIndex + 1,
            };
        case SET_SELECTED:
            // doing this because action.payload will be a string '2', but want to keep state as ints to do math
            return {
                ...state,
                selectedIndex: parseInt(action.payload),
            };
        case CREATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                selectedIndex: currentStateIndex + 1,
                departmentCreationResult: action.payload,
                isRMSCompleted: true,
            };
        case PAGE_LOAD_SUCCESS:
            // Technically we'd want this in data state rather than ui state
            // but it's very sketchy to have data from other departments
            // floating around, so we're keeping it in UI state only for now.
            return {
                ...state,
                departmentProfiles: action.payload,
            };
        case CHECK_PROGRESS_SUCCESS:
            return {
                ...state,
                checkProgressResult: action.payload,
            };
        default:
            return state;
    }
}

export default makeResettable(PAGE_LOAD_START, departmentSetupAdminUiReducer, initialState);

export const departmentSetupAdminUiSelector = (state) => state.ui.departmentSetupAdmin;
export const departmentTypeOptionsSelector = createSelector(
    departmentSetupAdminUiSelector,
    (departmentSetupAdminUi) =>
        map(departmentSetupAdminUi.departmentProfiles, (dp) => {
            const { displayName, departmentId, department: { subDomain } = {} } = dp;
            return {
                value: dp.departmentId,
                display: compact([
                    displayName,
                    subDomain ? `(${subDomain})` : null,
                    departmentId ? `(${departmentId})` : null,
                ]).join(' '),
            };
        })
);

import React from 'react';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { WARRANT_WARRANT_NUMBER } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { createModalSelector } from '../../../core/box/state/ui';
import { submitChangeWarrantNumberModal } from '../state/ui';
import Modal from '../../../../legacy-redux/components/core/Modal';
import ChangeWarrantNumberForm from './ChangeWarrantNumberForm';

const context = {
    name: boxEnum.CHANGE_WARRANT_NUMBER_MODAL,
};
const strings = componentStrings.warrants.warrant.ChangeWarrantNumberModal;

const mapStateToProps = createStructuredSelector({
    existingWarrantIds: createModalSelector(context, 'existingWarrantIds'),
});

/**
 * Modal for changing an existing warrant's `warrantNumber`.
 */
export default flowRight(
    connect(mapStateToProps, (dispatch) => ({
        submitNewWarrantNumber: () => dispatch(submitChangeWarrantNumberModal()),
    })),
    withFields([WARRANT_WARRANT_NUMBER])
)(function ChangeWarrantNumberModal({
    submitNewWarrantNumber,
    fieldDisplayNames,
    existingWarrantIds,
}) {
    return (
        <Modal
            context={context}
            title={strings.title(fieldDisplayNames.WARRANT_WARRANT_NUMBER)}
            onSave={submitNewWarrantNumber}
            okText={strings.save}
            saveDisabled={existingWarrantIds && existingWarrantIds.length !== 0}
        >
            <ChangeWarrantNumberForm />
        </Modal>
    );
});

import { Vehicle, EntityTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'vehicles';

const vehicleModule = createNormalizedModule<Vehicle>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const vehiclesSelector = vehicleModule.selectors.entitiesSelector;
export const vehiclesWhereSelector = vehicleModule.selectors.entitiesWhereSelector;
export const vehicleByIdSelector = vehicleModule.selectors.entityByIdSelector;

// REDUCER
export default vehicleModule.reducerConfig;

export const cadVehicleProfilesSelector = createSelector(vehiclesWhereSelector, (vehicles) => {
    const usedCadProfilesIds = vehicles({ ownerType: EntityTypeEnum.REPORT.name }).map(
        (x) => x.sourceCadVehicleProfileId
    );
    return vehicles({ ownerType: EntityTypeEnum.CAD_AGENCY_EVENT.name }).filter(
        (profile) => !usedCadProfilesIds.includes(profile.id)
    );
});

import React from 'react';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

function TaskAssignedToCell(task) {
    const { assignee } = getViewModelProperties(task);

    return <div>{assignee.fullName || assignee.default}</div>;
}

export default TaskAssignedToCell;

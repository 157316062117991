import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';

import { lifecycleOptions } from 'markformythree';
import {
    REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CITATION_STATISTICS_ATTRIBUTE_ID,
    CITATION_CHARGE_OFFENSE_CODE_ID,
    WARRANT_CHARGE_CHARGE_NAME,
} from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { TRAFFIC_TYPE_OFFENSE_CODE_FLAGS } from '~/client-common/core/domain/offense-codes/constants';

import { buildCitationChargeOffenseCodeIdDisplayName } from '~/client-common/core/domain/reports/state/ui/citations';

import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import {
    formName,
    buildNewCitationChargeFormModel,
    resequenceChargeOrders,
} from '../../state/forms/citationForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTAttributeButtonRadio } from '../../../../core/forms/components/button-radios/AttributeButtonRadio';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { MFTNItems } from '../../../../core/forms/components/NItems';
import { ArbiterMFTOffenseCodeSelect } from '../../../../core/forms/components/selects/OffenseCodeSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import {
    renderAddButton,
    renderRemoveButtonAfterFirstItem,
} from '../../../../core/names/components/nameFormNItemsRenderers';
import {
    MFTAutofillDateButton,
    autofillDateTypeEnum,
} from '../../../../core/components/AutofillDateButton';

const CitationCardForm = withFields([
    REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CITATION_STATISTICS_ATTRIBUTE_ID,
    CITATION_CHARGE_OFFENSE_CODE_ID,
    WARRANT_CHARGE_CHARGE_NAME,
])(function _CitationCardForm({ fieldDisplayNames }) {
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            render={({ set: formSet }) => (
                <CardSection>
                    <Row>
                        <ArbiterMFTText path="citationNumber" length="lg" />
                    </Row>
                    <Row>
                        <ArbiterMFTDatePicker path="issuedDateUtc" includeTime={true} />
                        <MFTAutofillDateButton
                            onClick={(_e, { eventStartUtc }) =>
                                formSet('issuedDateUtc', eventStartUtc)
                            }
                            autofillDateType={autofillDateTypeEnum.START_DATE_TIME}
                            startDatePath="issuedDateUtc"
                        />
                    </Row>
                    <Row>
                        <ArbiterMFTAttributeSelect
                            path="citationTypeAttrId"
                            length="lg"
                            attributeType={AttributeTypeEnum.CITATION_TYPE.name}
                        />
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="citationTypeOther" length="lg" />
                            </IndentedFields>
                        </Row>
                    </Row>
                    <Row>
                        <ArbiterMFTText path="citationPostedSpeed" length="lg" />
                    </Row>
                    <Row>
                        <ArbiterMFTText path="citationActualSpeed" length="lg" />
                    </Row>
                    <Row>
                        <MFTNItems
                            path="citationCharges"
                            addText={fieldDisplayNames.WARRANT_CHARGE_CHARGE_NAME}
                            useRowSpacing={true}
                            render={({ index, items }) => {
                                const citationChargeFormModel = items[index];
                                const citationChargeOffenseCodeIdDisplayName =
                                    fieldDisplayNames.CITATION_CHARGE_OFFENSE_CODE_ID;
                                const label = buildCitationChargeOffenseCodeIdDisplayName(
                                    citationChargeOffenseCodeIdDisplayName,
                                    citationChargeFormModel.chargeOrder
                                );

                                return (
                                    <>
                                        <ArbiterMFTOffenseCodeSelect
                                            path="offenseCodeId"
                                            label={label}
                                            flags={TRAFFIC_TYPE_OFFENSE_CODE_FLAGS}
                                            length="lg"
                                        />

                                        <IndentedFields>
                                            <ArbiterMFTAttributeButtonRadio
                                                path="chargeResultAttrId"
                                                attributeType={
                                                    AttributeTypeEnum.CITATION_CHARGE_RESULT.name
                                                }
                                            />
                                        </IndentedFields>
                                    </>
                                );
                            }}
                            renderAddButton={({ addItem, addText, items }) => {
                                const formModel = buildNewCitationChargeFormModel({
                                    existingCitationChargeFormModels: items,
                                });
                                return renderAddButton({
                                    addItem: () => addItem(formModel),
                                    addText,
                                });
                            }}
                            renderRemoveButton={({ removeItem, ...props }) => {
                                const augmentedRemoveItem = () => {
                                    removeItem();
                                    resequenceChargeOrders();
                                };
                                return renderRemoveButtonAfterFirstItem({
                                    ...props,
                                    removeItem: augmentedRemoveItem,
                                });
                            }}
                        />
                    </Row>
                    <Row>
                        <ArbiterMFTAttributeCheckboxes
                            path="citationStatisticsAttrIds"
                            attributeType={AttributeTypeEnum.CITATION_STATISTICS.name}
                            columns={2}
                            columnWidth="48%"
                            gutterWidth="4%"
                        />
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="citationStatisticsDescription" length="lg" />
                            </IndentedFields>
                        </Row>
                    </Row>
                </CardSection>
            )}
        />
    );
});

export default CitationCardForm;

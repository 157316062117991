import React from 'react';
import { connect } from 'react-redux';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import {
    saveArrestUcrClassificationSidePanel,
    closeArrestUcrClassificationSidePanel,
} from '../state/ui';
import ArrestUcrClassificationForm from './ArrestUcrClassificationForm';

const strings = componentStrings.reports.ArrestUcrClassificationSidePanel;

const context = { name: boxEnum.ARREST_UCR_CLASSIFICATION_SIDE_PANEL };

function ArrestUcrClassificationSidePanel({ onSave, onClose }) {
    return (
        <SidePanel title={strings.title} context={context} onSave={onSave} onClose={onClose}>
            <ArrestUcrClassificationForm />
        </SidePanel>
    );
}

export default connect(null, {
    onSave: () => saveArrestUcrClassificationSidePanel(),
    onClose: () => closeArrestUcrClassificationSidePanel(),
})(ArrestUcrClassificationSidePanel);

import { NotificationStatusEnum } from '@mark43/notifications-api';
import _, { at, get, includes, map, max, times, uniq, without } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import styled, { withTheme } from 'styled-components';

import classNames from 'classnames';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    markAllUserNotifications,
    markSpecificUserNotifications,
} from '~/client-common/core/domain/user-notifications/state/data';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';

import componentStrings from '~/client-common/core/strings/componentStrings';
import NotificationSummary from '../../core/components/NotificationSummary';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import _DropdownMenu from '../../../core/components/DropdownMenu';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import _ButtonCheckbox from '../../../core/forms/components/checkboxes/ButtonCheckbox';
import SearchSizeDropdownMenu from '../../../core/components/SearchSizeDropdownMenu';
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import { InlineBanner } from '../../../core/components/InlineBanner';
import NoDataBlock from '../../../core/components/NoDataBlock';

import { openBox } from '../../../../legacy-redux/actions/boxActions';

import { scrollToElement } from '../../../../legacy-redux/helpers/navigationHelpers';
import NotificationsActionBar from './NotificationsActionBar';

const NOTIFICATION_CONTAINER_CLASS = 'mark43-notifications-table';
const SCROLL_CONTAINER_CLASS = 'mark43-scrollable-under-subheader';

const { UNREAD, READ, ACKNOWLEDGED, ARCHIVED } = NotificationStatusEnum;
const strings = componentStrings.notifications.dashboard.NotificationsTable;

const NotificationsTableFooter = styled.div`
    margin-top: 20px;
    text-align: right;
`;

const LeftSection = styled.div`
    float: left;

    .mark43-react-secondary-button {
        margin-top: 0;
    }
`;

const RightSection = styled.div`
    float: right;
`;

const ButtonCheckbox = styled(_ButtonCheckbox)`
    margin-left: 5px;
`;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
`;

const DropdownMenu = styled(_DropdownMenu)`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    color: ${(props) => props.theme.colors.cobaltBlue};
`;

const TableResultsSummaryContainer = styled(TableResultsSummary)`
    float: left;
    margin-right: 10px;
    padding-top: 7px;
`;

const NoResults = styled.div`
    margin-top: 40px;
    text-align: center;
`;

const SelectableNotificationSummary = compose(
    withHandlers({
        onSelect({ selectedRows, selectRows, rowIndex }) {
            return (checkboxValue) => {
                if (checkboxValue) {
                    // add the index to the selected rows
                    selectRows(uniq([...selectedRows, rowIndex]));
                } else {
                    // remove the index from the seleted rows
                    selectRows(without(selectedRows, rowIndex));
                }
            };
        },
    }),
    withTheme
)(function SelectableNotificationSummary({
    userNotificationViewModel,
    selectedRows,
    onSelect,
    rowIndex,
    theme,
}) {
    return (
        <NotificationSummary
            userNotificationViewModel={userNotificationViewModel}
            width={theme.widths.container - 200}
            selectable={true}
            selected={includes(selectedRows, rowIndex)}
            onSelect={onSelect}
        />
    );
});

const _NotificationsTableHeader = compose(
    connect(null, (dispatch, { searchNotifications }) => ({
        markAllUserNotifications: (query) => {
            // open the loading modal right away to cover both API requests
            // (marking notifications, then re-searching after marking)
            dispatch(openBox({ name: boxEnum.ADVANCED_SEARCH_LOADING_MODAL }));
            dispatch(markAllUserNotifications({ ...query, endDateUtc: nowUtc() })).then(() =>
                searchNotifications()
            );
        },
        markSpecificUserNotifications: (query) => {
            dispatch(openBox({ name: boxEnum.ADVANCED_SEARCH_LOADING_MODAL }));
            dispatch(markSpecificUserNotifications(query)).then(() => searchNotifications());
        },
    })),
    withPropsOnChange(['results', 'selectedRows'], ({ results, selectedRows }) => ({
        selectedUserNotificationLinkIds: _(results)
            .at(selectedRows)
            .map('userNotificationLinkId')
            .value(),
    })),
    withHandlers({
        handleSizeChange({ searchNotifications }) {
            return (size) => searchNotifications({ from: 0, size });
        },
        handleAllCheckboxChange({ selectRows, results }) {
            return (value) => {
                selectRows(!value ? [] : times(results.length));
            };
        },
        onArchiveClick({
            allResultsSelected,
            selectedUserNotificationLinkIds,
            markAllUserNotifications,
            markSpecificUserNotifications,
        }) {
            return () => {
                if (allResultsSelected) {
                    markAllUserNotifications({
                        beforeStatuses: [UNREAD.name, READ.name, ACKNOWLEDGED.name],
                        afterStatus: ARCHIVED.name,
                    });
                } else {
                    markSpecificUserNotifications({
                        userNotificationLinkIds: selectedUserNotificationLinkIds,
                        afterStatus: ARCHIVED.name,
                    });
                }
            };
        },
        onMarkAsAcknowledgedClick({
            allResultsSelected,
            selectedUserNotificationLinkIds,
            markAllUserNotifications,
            markSpecificUserNotifications,
        }) {
            return () => {
                if (allResultsSelected) {
                    markAllUserNotifications({
                        beforeStatuses: [UNREAD.name, READ.name],
                        afterStatus: ACKNOWLEDGED.name,
                    });
                } else {
                    markSpecificUserNotifications({
                        userNotificationLinkIds: selectedUserNotificationLinkIds,
                        afterStatus: ACKNOWLEDGED.name,
                    });
                }
            };
        },
        onMarkAsReadClick({
            allResultsSelected,
            selectedUserNotificationLinkIds,
            markAllUserNotifications,
            markSpecificUserNotifications,
        }) {
            return () => {
                if (allResultsSelected) {
                    markAllUserNotifications({
                        beforeStatuses: [UNREAD.name, ACKNOWLEDGED.name],
                        afterStatus: READ.name,
                    });
                } else {
                    markSpecificUserNotifications({
                        userNotificationLinkIds: selectedUserNotificationLinkIds,
                        afterStatus: READ.name,
                    });
                }
            };
        },
        onUnarchiveClick({
            allResultsSelected,
            selectedUserNotificationLinkIds,
            markAllUserNotifications,
            markSpecificUserNotifications,
        }) {
            return () => {
                if (allResultsSelected) {
                    markAllUserNotifications({
                        beforeStatuses: [ARCHIVED.name],
                        afterStatus: ACKNOWLEDGED.name,
                    });
                } else {
                    markSpecificUserNotifications({
                        userNotificationLinkIds: selectedUserNotificationLinkIds,
                        afterStatus: ACKNOWLEDGED.name,
                    });
                }
            };
        },
    })
)(function _NotificationsTableHeader({
    isArchive,
    query: { from },
    results,
    totalCount,
    errorMessage,
    handleSizeChange,
    handleAllCheckboxChange,
    selectedRows,
    onArchiveClick,
    onMarkAsAcknowledgedClick,
    onMarkAsReadClick,
    onUnarchiveClick,
}) {
    let checkboxValue = false;
    // using >= because option to select ALL rows in all pages
    if (selectedRows.length >= results.length && results.length !== 0) {
        checkboxValue = true;
    } else if (selectedRows.length < results.length && selectedRows.length > 0) {
        checkboxValue = 'INDETERMINATE';
    }

    return (
        <div className={classNames('clearfix', NOTIFICATION_CONTAINER_CLASS)}>
            {get(errorMessage, 'readOnlyText') && (
                <InlineBanner status="error">{errorMessage.readOnlyText}</InlineBanner>
            )}
            {totalCount > 0 && (
                <LeftSection>
                    <ButtonCheckbox
                        value={checkboxValue}
                        onChange={handleAllCheckboxChange}
                        label={strings.all}
                    />
                    {!isArchive && selectedRows.length > 0 && (
                        <Button className={buttonTypes.SECONDARY} onClick={onArchiveClick}>
                            {strings.archive}
                        </Button>
                    )}
                    {!isArchive && selectedRows.length > 0 && (
                        <DropdownMenu
                            buttonContent={
                                <DropdownMenuButtonContainer>
                                    {strings.markAs}
                                    <Icon color="cobaltBlue" type={iconTypes.TRIANGLE_DOWN} />
                                </DropdownMenuButtonContainer>
                            }
                            width={107}
                        >
                            <div
                                className="dropdown-menu-option"
                                onClick={onMarkAsAcknowledgedClick}
                            >
                                {strings.markAsAcknowledged}
                            </div>
                            <div className="dropdown-menu-option" onClick={onMarkAsReadClick}>
                                {strings.markAsRead}
                            </div>
                        </DropdownMenu>
                    )}
                    {isArchive && selectedRows.length > 0 && (
                        <Button className={buttonTypes.SECONDARY} onClick={onUnarchiveClick}>
                            {strings.unarchive}
                        </Button>
                    )}
                </LeftSection>
            )}
            {totalCount > 0 && (
                <RightSection>
                    <TableResultsSummaryContainer
                        from={from}
                        to={from + results.length}
                        totalResults={totalCount > 100 ? '100+' : totalCount}
                        caption=""
                    />
                    <SearchSizeDropdownMenu onChange={handleSizeChange} />
                </RightSection>
            )}
        </div>
    );
});

const NotificationsTableHeader = styled(_NotificationsTableHeader)`
    margin-bottom: 20px;
`;

function _NotificationsTable({
    isArchive = false,
    query: { from, size },
    results,
    totalCount,
    errorMessage,
    selectedRows,
    selectRows,
    allResultsSelected,
    handlePaginationClick,
    handleSelectAllRows,
    handleClearAllSelectedRows,
    searchNotifications,
    className,
}) {
    // allResultsSelected only true when select all action fired. therefore also
    // check ui selectedRows
    const allSelected = allResultsSelected || selectedRows.length === totalCount;

    return (
        <>
            <div className={className} style={{ padding: '20px' }}>
                <NotificationsTableHeader
                    isArchive={isArchive}
                    query={{ from }}
                    results={results}
                    totalCount={totalCount}
                    errorMessage={errorMessage}
                    selectedRows={selectedRows}
                    selectRows={selectRows}
                    allResultsSelected={allSelected}
                    searchNotifications={searchNotifications}
                />
                <div>
                    {totalCount === 0 && (
                        <NoResults>
                            <NoDataBlock>{strings.noNotifications}</NoDataBlock>
                        </NoResults>
                    )}
                    {totalCount > 0 && results.length === 0 && (
                        <NoResults>
                            <NoDataBlock>{strings.noResults}</NoDataBlock>
                        </NoResults>
                    )}
                    {map(results, (viewModel, rowIndex) => (
                        <SelectableNotificationSummary
                            key={viewModel.userNotificationLinkId}
                            rowIndex={rowIndex}
                            userNotificationViewModel={viewModel}
                            selectedRows={selectedRows}
                            selectRows={selectRows}
                        />
                    ))}
                </div>
                {totalCount > size && (
                    <NotificationsTableFooter>
                        <Pagination
                            currentPage={max([1, fromSizeToPage(from + 1, size)])}
                            itemsPerPage={size}
                            itemCount={totalCount}
                            onClick={handlePaginationClick}
                            maxEdgeItems={1}
                        />
                    </NotificationsTableFooter>
                )}
            </div>

            {selectedRows.length > 0 && (
                <NotificationsActionBar
                    selectedRows={selectedRows}
                    selectedResults={at(results, selectedRows)}
                    allResultsSelected={allSelected}
                    allResultsOnPageSelected={selectedRows.length === size}
                    totalResults={totalCount}
                    handleSelectAllRows={handleSelectAllRows}
                    handleClearAllSelectedRows={handleClearAllSelectedRows}
                />
            )}
        </>
    );
}

const NotificationsTable = styled(_NotificationsTable)`
    min-height: 100%;
    background-color: ${(props) => props.theme.colors.white};

    &,
    * {
        box-sizing: border-box;
    }
`;

/**
 * Table displaying the current user's notifications. The header contains a
 *   search form.
 * @param {boolean} [isArchive=false] Whether this table displays only archived
 *   notifications.
 */
export default compose(
    withHandlers({
        handlePaginationClick({ searchNotifications, query: { size } }) {
            scrollToElement({
                selector: `.${NOTIFICATION_CONTAINER_CLASS}`,
                wrapperSelector: `.${SCROLL_CONTAINER_CLASS}`,
            });
            return (lastPage, nextPage) => {
                searchNotifications({
                    from: nextPage * size - size,
                    size,
                });
            };
        },
        handleClearAllSelectedRows({ selectRows }) {
            return () => {
                selectRows([]);
            };
        },
    })
)(NotificationsTable);

import { map, compact, filter, keyBy, sortBy, reverse } from 'lodash';

// helpers
import { joinTruthyValues } from '../../../../helpers/stringHelpers';
import { offenseCodesWithFlags } from '../../../../helpers/offenseCodesHelpers';

// constants
import {
    OFFENSE_TYPE_OFFENSE_CODE_FLAGS,
    INCIDENT_TYPE_OFFENSE_CODE_FLAGS,
} from '../../offense-codes/constants';
import componentStrings from '../../../strings/componentStrings';

const incidentCardStrings = componentStrings.reports.core.IncidentCard;

/**
 * Simple predicate helper to determine whether or not an offense
 * is a client-side stub
 */
export const isClientSideOffenseStub = (offense) => offense.id < 0;

export const buildOffenseCardTitle = ({ order, formattedOffenseCode, titlePrefix }) =>
    joinTruthyValues([`${titlePrefix} ${order}`, formattedOffenseCode], ': ');

export const buildIncidentCardTitle = ({
    formattedOffenseCode,
    titlePrefix = incidentCardStrings.incidentCardTitlePrefix,
}) => joinTruthyValues([`${titlePrefix}`, formattedOffenseCode], ': ');

/**
 * Return all Offenses which have a mapped Offense Type offense code.
 * @param {[object]} offenses offenses to be filtered
 * @param {[object]} offenseCodes All offense codes
 */
export const filterForOffenses = (offenses, offenseCodes) => {
    const offenseTypeOffenseCodesById = keyBy(
        offenseCodesWithFlags(offenseCodes, [OFFENSE_TYPE_OFFENSE_CODE_FLAGS]),
        'id'
    );
    return filter(offenses, (offense) => {
        return (
            // client-side only stub offenses have an id < 0
            // and an `isIncident` prop, which is used to determine
            // whether an offense is an offense or an incident prior to offense code selection.
            // isOffenseIncident is a client-side prop used to determine whether an offense
            // has been added via the combined add offense/incident button. This can be removed
            // when RMS_REPORTS_SINGLE_ADD_OFFENSE_INCIDENT_BUTTON_ENABLED is enabled globally
            (isClientSideOffenseStub(offense) &&
                !offense.isIncident &&
                !offense.isOffenseIncident) ||
            !!offenseTypeOffenseCodesById[offense.offenseCodeId]
        );
    });
};
/**
 * Return all Offenses which have a mapped Incident Type offense code.
 * @param {[object]} offenses offenses to be filtered
 * @param {[object]} offenseCodes All offense codes
 */
export const filterForIncidents = (offenses, offenseCodes) => {
    const incidentTypeOffenseCodesById = keyBy(
        offenseCodesWithFlags(offenseCodes, [INCIDENT_TYPE_OFFENSE_CODE_FLAGS]),
        'id'
    );
    return filter(
        offenses,
        (offense) =>
            // client-side only stub offenses have an id < 0
            // and an `isIncident` prop, which is used to determine
            // whether an offense is an offense or an incident prior to offense code selection
            // isOffenseIncident is a client-side prop used to determine whether an offense
            // has been added via the combined add offense/incident button. This can be removed
            // when RMS_REPORTS_SINGLE_ADD_OFFENSE_INCIDENT_BUTTON_ENABLED is enabled globally
            (isClientSideOffenseStub(offense) &&
                offense.isIncident &&
                !offense.isOffenseIncident) ||
            !!incidentTypeOffenseCodesById[offense.offenseCodeId]
    );
};

export const filterForStubOffenseIncidents = (offenses) => {
    return filter(
        offenses,
        (offense) => isClientSideOffenseStub(offense) && offense.isOffenseIncident
    );
};

export const sortOffenses = (offenses) => sortBy(offenses, ['offenseOrder', 'id']);
export const sortIncidents = (incidents) => sortBy(incidents, 'id');
export const offensesWithoutClientSideStubs = (offenses) => filter(offenses, (o) => o.id > 0);
export const sortOffenseIncidents = (offenseIncidents) => reverse(sortBy(offenseIncidents, 'id'));

export const computeOffensesToReorder = ({ offenses, fromIndex, toIndex }) => {
    const toUpdate = offenses.slice();
    const offense = toUpdate.splice(fromIndex, 1);
    toUpdate.splice(toIndex, 0, ...offense);

    const newOrders = map(toUpdate, (_, index) => index + 1);
    // We only return offenses, which actually had their order changed after computing
    // the new orderings, as these are the only offenses we need to persist
    return compact(
        map(toUpdate, (offense, index) =>
            newOrders[index] !== offense.offenseOrder
                ? {
                      ...offense,
                      offenseOrder: newOrders[index],
                  }
                : undefined
        )
    );
};

export const buildStubOffenseIncidentCardAnchor = ({ index }) =>
    `stub-offense-incident-card-${index}`;

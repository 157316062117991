import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { AttributeTypeEnum } from '@mark43/rms-api';
import { usePreloadAttributes } from '~/client-common/core/hooks/usePreloadAttributes';
import {
    CasesTable,
    CasesTableColumnOverrides,
    CellWrap,
    columnKeys,
} from '../../../cases/core/components/CasesTable';
import { ProfileRouteParams, getEntityDataFromRouteParams } from '../helpers/routeHelpers';
import { getEntityProfileSearchQuery } from '../helpers/searchHelpers';
import { currentEntityProfileIsMasterProfileSelector } from '../state/ui';
import { EntityProfileInvolvementCell } from './cases/EntityProfileInvolvementCell';

type EntityProfileCasesTableProps = WithRouterProps<ProfileRouteParams>;

const columnOverrides: CasesTableColumnOverrides = {
    [columnKeys.involvement]: {
        cell: ({ row }) => (
            <CellWrap>
                <EntityProfileInvolvementCell elasticCase={row.original} />
            </CellWrap>
        ),
    },
};

const EntityProfileCasesTable: FC<EntityProfileCasesTableProps> = ({ params }) => {
    const { entityId, entityType } = getEntityDataFromRouteParams(params);
    // @ts-expect-error `currentEntityProfileIsMasterProfileSelector` has incorrect type
    const isMasterProfile: boolean = useSelector(currentEntityProfileIsMasterProfileSelector);
    const searchQuery = getEntityProfileSearchQuery(entityId, entityType, isMasterProfile);

    usePreloadAttributes([AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE.name]);

    return <CasesTable searchQuery={searchQuery} columnOverrides={columnOverrides} />;
};

export default withRouter(EntityProfileCasesTable);

import { last, sortBy } from 'lodash';

// helpers
import { valuesToArray } from '../../../../helpers/logicHelpers';

const sortReportUcrs = (reportUcrs) => {
    const reportUcrsArray = valuesToArray(reportUcrs);
    return sortBy(reportUcrsArray, ['createdDateUtc', 'id']);
};

export const filterReportUcrsOnEventCard = (reportUcrs) => {
    const sortedReportUcrs = sortReportUcrs(reportUcrs);
    return last(sortedReportUcrs);
};

import React from 'react';
import { useSelector } from 'react-redux';
import { lifecycleOptions } from 'markformythree';
import styled from 'styled-components';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { hydratedChargeViewModelsForChargesWhereSelector } from '~/client-common/core/domain/charges/state/ui';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { ArbiterMFTNItems } from '../../../../core/forms/components/NItems';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { formName } from '../../state/forms/chargesForm';
import testIds from '../../../../../core/testIds';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTNibrsOffenseCodeIdSelect } from '../../../../core/forms/components/selects/NibrsCodeSelect';
import { ArbiterMFTBooleanButtonRadio } from '../../../../core/forms/components/button-radios/BooleanButtonRadio';
import IndentedFields from '../../../../core/components/IndentedFields';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import Row from '../../../../core/components/Row';
import AddEntityLink from '../../../../core/components/AddEntityLink';
import ArrestChargesSidePanel from '../arrest-charges-side-panel/ArrestChargesSidePanel';
import { ChargeSummaryView } from './ChargeSummaryView';

const strings = componentStrings.reports.core.ChargesCard;

const PaddedBottomRow = styled(Row)`
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
`;

const ChargesCardForm = function _ChargesCardForm({ index, arrest, showNibrsCode }) {
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const { id: arrestId, reportId } = arrest;
    const hasNibrsAbility = currentUserHasAbility(abilitiesEnum.REPORTING.NIBRS_CODING);

    const hydratedChargeViewModelsForChargesWhere = useSelector(
        hydratedChargeViewModelsForChargesWhereSelector
    );

    // @ts-expect-error client-common isn't typed for this
    const hydratedChargeViewModels = hydratedChargeViewModelsForChargesWhere({
        arrestId,
    });

    return (
        <CardSection>
            <ArbiterForm
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                name={formName}
                context={formName}
                index={index}
                render={() => {
                    return (
                        <>
                            {showNibrsCode && (
                                <PaddedBottomRow>
                                    <ArbiterMFTNibrsOffenseCodeIdSelect
                                        path="arrestNibrsOffenseCodeId"
                                        length="md"
                                        disabled={!hasNibrsAbility}
                                    />
                                </PaddedBottomRow>
                            )}
                            <ArbiterMFTNItems
                                path="charges"
                                formName={formName}
                                formIndex={index}
                                disabled={true}
                                render={({ item }) => {
                                    const chargeId = item.id;

                                    // We don't need to sort these here because they are already being sorted in
                                    // "buildChargesFormModel"
                                    const hydratedChargeViewModel = hydratedChargeViewModels.find(
                                        (item) => item.chargeViewModel.id === chargeId
                                    );

                                    // We should almost never hit this case, but adding it as a safe guard
                                    if (!hydratedChargeViewModel) {
                                        return null;
                                    }

                                    return (
                                        <>
                                            <ChargeSummaryView
                                                hydratedChargeViewModel={hydratedChargeViewModel}
                                                summaryMode={false}
                                                chargesFormIndex={index}
                                            />
                                            <IndentedFields>
                                                <ArbiterMFTBooleanButtonRadio path="wasCitationIssued" />
                                                <ArbiterMFTText path="citationNumber" width={200} />
                                            </IndentedFields>
                                        </>
                                    );
                                }}
                            />
                        </>
                    );
                }}
            />
            <ArrestChargesSidePanel
                overlayId={`${overlayIdEnum.ARREST_CHARGES_SIDE_PANEL_CHARGES_CARD}.${index}`}
                reportId={reportId}
                formIndex={index}
                renderButton={({ open, setCloseFocusRefs }) => {
                    return (
                        <AddEntityLink
                            testId={testIds.CHARGES_ADD_EDIT}
                            onClick={open}
                            setRef={setCloseFocusRefs}
                        >
                            {strings.addCharges}
                        </AddEntityLink>
                    );
                }}
            />
        </CardSection>
    );
};

export default ChargesCardForm;

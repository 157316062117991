import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import {
    ButtonGroup,
    ConfirmModal,
    Menu,
    MenuTrigger,
    MenuContent,
    MenuItem,
    Tooltip,
    useToast,
    Text,
} from 'arc';
import { BriefingStateEnum, EntityTypeEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { Briefing, withBriefing } from '~/client-common/core/domain/briefings/state/data';

import Subheader from '../../../core/components/Subheader';
import GenericPermissionsModal from '../../../core/permissions/components/GenericPermissionsModal';
import { IconButton } from '../../../core/components/IconButton';
import errorToMessage from '../../../core/errors/utils/errorToMessage';
import testIds from '../../../../core/testIds';
import { openTaskSidePanelForEntity } from '../../../tasks/core/state/ui';

import { AUTOSAVE_SECONDS_INTERVAL, TOOLTIP_DELAY_MS } from '../../configuration';
import { BRIEFINGS_ROUTES } from '../../configuration/routes';
import { BriefingRoutingModalType } from '../../types';
import briefingResource from '../../resources/briefingResource';
import useBriefingDeletionConfirmationModal from '../../hooks/useBriefingDeletionConfirmationModal';
import useBriefingPermissions from '../../hooks/useBriefingPermissions';
import useBriefingPermissionsModal from '../../hooks/useBriefingPermissionsModal';
import {
    currentUserCanCreateBriefingTasksSelector,
    openBriefingAttachmentsSidePanel,
    openBriefingRoutingModal,
} from '../../state/ui';
import { getEditorContentByBriefingId } from '../../utils';
import { Actions, ActionButton } from '../../utils/styledComponents';
import BriefingRoutingModal from '../BriefingRoutingModal';
import BriefingTitle from './BriefingTitle';

interface EditModeHeaderProps {
    briefing: Briefing;
    disableActions?: boolean;
}

const strings = componentStrings.briefings.core;

const MENU_ITEMS = {
    EDIT_ATTACHMENTS: 'EDIT_ATTACHMENTS',
    CREATE_TASK: 'CREATE_TASK',
    DELETE: 'DELETE',
} as const;
type MenuItemKey = keyof typeof MENU_ITEMS;
type MenuItem = typeof MENU_ITEMS[MenuItemKey];

const briefingsDashboardPath = BRIEFINGS_ROUTES.root.url();

const EditModeHeader = withRouter<EditModeHeaderProps>(({ briefing, disableActions, router }) => {
    const [isPosting, setIsPosting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const canCreateTasks = useSelector(currentUserCanCreateBriefingTasksSelector);

    const dispatch = useDispatch();
    const toast = useToast();

    const { canDelete, canEdit } = useBriefingPermissions(briefing);

    const { openPermissionsModal, permissionsModalProps } = useBriefingPermissionsModal(briefing);
    const { openDeletionModal, deletionModalProps } = useBriefingDeletionConfirmationModal(
        briefing.id,
        router
    );

    const isDraftBriefing = briefing.state === BriefingStateEnum.DRAFT.name;

    const handleBriefingPosting = async () => {
        setIsPosting(true);
        try {
            // we want to save the latest briefing body before posting and routing away from the page
            const body = getEditorContentByBriefingId(briefing.id);
            await briefingResource.updateBriefingBody(briefing.id, { body });

            await briefingResource.postBriefing(briefing.id);
            router.push(briefingsDashboardPath);
        } catch (e) {
            setIsPosting(false);
            toast({
                status: 'error',
                description: errorToMessage(e, strings.postFailToast),
            });
        }
    };

    const handleBriefingSaving = async (exit = false) => {
        setIsSaving(true);
        try {
            const body = getEditorContentByBriefingId(briefing.id);
            const response = await briefingResource.updateBriefingBody(briefing.id, { body });
            dispatch(withBriefing(response));
            if (exit) {
                router.push(briefingsDashboardPath);
            }
            toast({
                status: 'default',
                description: strings.saveSuccessToast,
                duration: 3000,
            });
        } catch (e) {
            toast({
                status: 'error',
                description: errorToMessage(e, strings.saveFailToast),
            });
        } finally {
            if (!exit) {
                setIsSaving(false);
            }
        }
    };

    const handleBriefingRoutingEdit = () => {
        dispatch(openBriefingRoutingModal(BriefingRoutingModalType.EDIT, briefing.id));
    };

    const handleTaskCreation = () => {
        dispatch(
            openTaskSidePanelForEntity([
                {
                    entityId: briefing.id,
                    entityType: EntityTypeEnum.BRIEFING.name,
                    entityTitle: briefing.title,
                },
            ])
        );
    };

    const handleAttachmentsEdit = () => {
        dispatch(openBriefingAttachmentsSidePanel(briefing.id));
    };

    const handleMenuItemClick = (menuItem: MenuItem) => {
        switch (menuItem) {
            case MENU_ITEMS.CREATE_TASK:
                handleTaskCreation();
                break;
            case MENU_ITEMS.EDIT_ATTACHMENTS:
                handleAttachmentsEdit();
                break;
            case MENU_ITEMS.DELETE:
                openDeletionModal();
                break;
            default:
                return;
        }
    };

    const menuItems = [
        {
            name: MENU_ITEMS.EDIT_ATTACHMENTS,
            label: strings.editAttachments,
            testId: testIds.BRIEFING_EDIT_ATTACHMENTS_MENU_BUTTON,
            enabled: canEdit,
        },
        {
            name: MENU_ITEMS.CREATE_TASK,
            label: strings.createTask,
            testId: testIds.BRIEFING_CREATE_TASK_MENU_BUTTON,
            enabled: canEdit && canCreateTasks,
        },
        {
            name: MENU_ITEMS.DELETE,
            label: strings.delete,
            testId: testIds.BRIEFING_DELETE_MENU_BUTTON,
            enabled: canDelete,
        },
    ];
    const enabledMenuItems = menuItems.filter(({ enabled }) => enabled);

    return (
        <Subheader
            title={<BriefingTitle title={briefing.title} briefingState={briefing.state} />}
            content={
                isDraftBriefing && (
                    <Text fontSize="sm" color="tertiary">
                        {strings.autosave(AUTOSAVE_SECONDS_INTERVAL)}
                    </Text>
                )
            }
        >
            <Actions>
                {isDraftBriefing && canEdit && (
                    <ActionButton
                        variant="solid"
                        isTextTransformNone
                        disabled={disableActions || isPosting}
                        testId={testIds.BRIEFING_POST_BUTTON}
                        onClick={handleBriefingPosting}
                    >
                        {strings.postBriefing}
                    </ActionButton>
                )}
                {canEdit && (
                    <Menu>
                        <ButtonGroup isAttached>
                            <ActionButton
                                variant="outline"
                                isTextTransformNone
                                disabled={disableActions || isSaving}
                                testId={testIds.BRIEFING_SAVE_BUTTON}
                                onClick={() => handleBriefingSaving()}
                            >
                                {strings.save}
                            </ActionButton>
                            <MenuTrigger isDisabled={disableActions} asChild>
                                <IconButton
                                    disabled={disableActions}
                                    aria-label={strings.saveMenu}
                                    icon="Open"
                                />
                            </MenuTrigger>
                        </ButtonGroup>
                        <MenuContent style={{ minWidth: 'min-content' }} align="end">
                            <MenuItem
                                disabled={disableActions || isSaving}
                                data-test-id={testIds.BRIEFING_SAVE_EXIT_MENU_BUTTON}
                                onSelect={() => handleBriefingSaving(true)}
                            >
                                {strings.saveAndExit}
                            </MenuItem>
                        </MenuContent>
                    </Menu>
                )}
                <Tooltip content={strings.managePermissions} delayDuration={TOOLTIP_DELAY_MS}>
                    <IconButton
                        icon="Permissions"
                        disabled={disableActions}
                        aria-label={strings.managePermissions}
                        testId={testIds.BRIEFING_MANAGE_PERMISSIONS_BUTTON}
                        onClick={openPermissionsModal}
                    />
                </Tooltip>
                {canEdit && (
                    <Tooltip content={strings.editRoutings} delayDuration={TOOLTIP_DELAY_MS}>
                        <IconButton
                            icon="Edit"
                            disabled={disableActions}
                            aria-label={strings.editRoutings}
                            testId={testIds.BRIEFING_EDIT_ROUTINGS_BUTTON}
                            onClick={handleBriefingRoutingEdit}
                        />
                    </Tooltip>
                )}
                {!!enabledMenuItems.length && (
                    <Menu>
                        <MenuTrigger isDisabled={disableActions} asChild>
                            <IconButton
                                icon="Menu"
                                disabled={disableActions}
                                aria-label={strings.menu}
                                testId={testIds.BRIEFING_ACTIONS_MENU_BUTTON}
                            />
                        </MenuTrigger>
                        <MenuContent>
                            {enabledMenuItems.map(({ name, label, testId }) => (
                                <MenuItem
                                    key={name}
                                    disabled={disableActions}
                                    data-test-id={testId}
                                    onSelect={() => handleMenuItemClick(name)}
                                >
                                    {label}
                                </MenuItem>
                            ))}
                        </MenuContent>
                    </Menu>
                )}
            </Actions>
            <GenericPermissionsModal {...permissionsModalProps} />
            {canEdit && <BriefingRoutingModal />}
            {canDelete && <ConfirmModal {...deletionModalProps} />}
        </Subheader>
    );
});

export default EditModeHeader;

import { AttributeTypeEnum, RoleTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { uniq, size } from 'lodash';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { Observer, lifecycleOptions, Fields } from 'markformythree';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import useFields, {
    useCaseFieldName,
    useTargetProfileFieldName,
} from '~/client-common/core/fields/hooks/useFields';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { CASE_STATUS_STATUS_ATTR_ID } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
// Helpers

// Selectors
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { statusIsClosedSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';

import * as fields from '~/client-common/core/enums/universal/fields';
import {
    allRoleFormatsByRoleIdSelector,
    currentUserUserRoleIdSelector,
} from '~/client-common/core/domain/roles/state/data';
import { ToolTipContainerContext } from '../../../core/context/ToolTipContainerContext';
import { ArbiterMFTFieldset, CollapsibleFieldset } from '../../../core/forms/components/Fieldset';
import testIds from '../../../../core/testIds';
import { currentUserCasePermissionsSelector, caseStatusOptionsSelector } from '../state/ui';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
} from '../../../core/current-user/state/ui';

// Components
import { ArbiterMFTRoleSelect } from '../../../core/forms/components/selects/RoleSelect';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTSwitch } from '../../../core/forms/components/Switch';
import { ArbiterMFTTextArea } from '../../../core/forms/components/TextArea';

import { ArbiterMFTText } from '../../../core/forms/components/Text';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Row from '../../../core/components/Row';
import { ArbiterMFTCaseDefinitionSelect } from '../../../core/forms/components/selects/CaseDefinitionSelect';
import { ArbiterMFTDatePicker } from '../../../core/forms/components/DatePicker';
import { SecondarySectionHeader } from '../../../core/components/typography';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';

import {
    modalHalfFormWidth,
    modalSmallFormWidth,
    dateTimePickerTotalWidth,
} from '../../../../legacy-redux/configs/formsConfig';
import { formConfiguration } from '../state/form/createManageCaseForm';
import { ExternalReportStatusFieldset } from '../../../core/external-report-statuses/components/ExternalReportStatusFieldset';
import { OffenseCaseStatusFieldset } from '../../../core/offense-case-statuses/components/OffenseCaseStatusFieldset';
import { ReportStatusesMFTNItems } from './ReportStatusesMFTNItems';

const strings = componentStrings.cases.core.CreateManageCaseForm;

const fullFormWidth = 340;

const Section = styled.div`
    margin: 16px auto;
`;

const AssignToMeButton = styled(Button)`
    margin-top: 19px;
`;

const ReportStatusesWrapper = styled.div`
    padding: 20px;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
`;

const HelpText = styled(Row)`
    margin-bottom: 16px;
`;

const ReportStatusesFieldsetWrapper = styled.div`
    position: relative;
`;

const ExpandCollapseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const CaseStatusFieldset = ({ form, statusIsClosed, setCaseStatusDefaultDate }) => {
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const fieldDisplayNames = useFields([CASE_STATUS_STATUS_ATTR_ID]);
    const caseStatusTitle = fieldDisplayNames.CASE_STATUS_STATUS_ATTR_ID;
    const userHasEditCaseStatusAbility = currentUserHasAbility(abilitiesEnum.CASES.EDIT_STATUSES);
    const caseStatusFilter = useSelector(caseStatusOptionsSelector);

    return (
        <ArbiterMFTFieldset path="caseStatus" title={caseStatusTitle}>
            <ArbiterMFTAttributeSelect
                attributeType={AttributeTypeEnum.CASE_STATUS.name}
                disabled={!userHasEditCaseStatusAbility}
                width={modalHalfFormWidth}
                path="statusAttrId"
                filterOptions={caseStatusFilter}
                onChange={(caseStatusAttrId) => {
                    if (statusIsClosed(caseStatusAttrId)) {
                        setCaseStatusDefaultDate(form, 'caseStatus.statusDateUtc');
                    }
                }}
            />
            <div>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.DIVISION.name}
                    disabled={!userHasEditCaseStatusAbility}
                    width={modalSmallFormWidth}
                    path="closedByDivisionAttrId"
                />
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                    disabled={!userHasEditCaseStatusAbility}
                    width={modalSmallFormWidth}
                    path="closedByUnitAttrId"
                />
                <ArbiterMFTDatePicker
                    disabled={!userHasEditCaseStatusAbility}
                    width={dateTimePickerTotalWidth}
                    includeTime={true}
                    path="statusDateUtc"
                />
            </div>
        </ArbiterMFTFieldset>
    );
};

const CreateManageCaseForm = (props) => {
    const {
        userCasePermissions,
        onAssignToMe,
        onAssignAssistingToMe,
        onAddMeAsSupervisor,
        statusIsClosed,
        setReportCaseStatusDefaultStatusDate,
        setCaseStatusDefaultDate,
        isBulkCreate,
        helpText,
        fieldDisplayNames,
    } = props;

    const { canEventuallyApprove } = userCasePermissions;
    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();
    const { singularTargetProfileFieldName: targetProfileFieldName } = useTargetProfileFieldName();
    const externalReportStatusTitle =
        fieldDisplayNames.EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID;
    const internalReportStatusTitle = fieldDisplayNames.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID;
    const offenseStatusTitle = fieldDisplayNames.OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID;

    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={RefContextEnum.FORM_CREATE_MANAGE_CASE.name}
            context={RefContextEnum.FORM_CREATE_MANAGE_CASE.name}
            configuration={formConfiguration}
            initialState={{
                supervisors: [],
                assistingInvestigators: [],
                targetProfileCategoryAttrIds: [],
                targetProfileAreaAttrIds: [],
            }}
            render={(form) => (
                <>
                    <HelpText>{helpText}</HelpText>
                    <SecondarySectionHeader>
                        {strings.sectionTitles.caseInformation(caseFieldName)}
                    </SecondarySectionHeader>
                    <Section>
                        {!isBulkCreate && (
                            <Row>
                                <ArbiterMFTText
                                    width={modalHalfFormWidth}
                                    path="title"
                                />
                            </Row>
                        )}
                        <Row>
                            <ArbiterMFTCaseDefinitionSelect
                                clearable={false}
                                path="caseDefinitionId"
                                width={modalHalfFormWidth}
                            />
                            <ArbiterMFTDatePicker
                                disabled={!isBulkCreate && !canEventuallyApprove}
                                path="dueDateUtc"
                                width={modalHalfFormWidth}
                            />
                        </Row>
                    </Section>
                    <SecondarySectionHeader>
                        {strings.sectionTitles.personnel}
                    </SecondarySectionHeader>
                    <Section>
                        <Row>
                            <ArbiterMFTRoleSelect
                                roleTypes={[RoleTypeEnum.USER.name]}
                                path="assignee"
                                width={modalHalfFormWidth}
                            />
                            <AssignToMeButton
                                className={buttonTypes.SECONDARY}
                                onClick={onAssignToMe.bind(null, form)}
                                testId={testIds.ASSIGN_CASE_TO_ME_BUTTON}
                            >
                                {strings.buttonText.addMe}
                            </AssignToMeButton>
                        </Row>
                        <Row>
                            <ArbiterMFTRoleSelect
                                roleTypes={[RoleTypeEnum.USER.name]}
                                path="assistingInvestigators"
                                width={fullFormWidth}
                                multiple={true}
                            />
                            <AssignToMeButton
                                className={buttonTypes.SECONDARY}
                                onClick={onAssignAssistingToMe.bind(null, form)}
                                testId={testIds.ASSIGN_CASE_TO_ME_BUTTON}
                            >
                                {strings.buttonText.addMe}
                            </AssignToMeButton>
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                                path="assignedPersonnelUnitAttrId"
                                width={modalHalfFormWidth}
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTRoleSelect
                                roleTypes={[RoleTypeEnum.USER.name]}
                                path="supervisors"
                                width={fullFormWidth}
                                multiple={true}
                            />
                            <AssignToMeButton
                                className={buttonTypes.SECONDARY}
                                onClick={onAddMeAsSupervisor.bind(null, form)}
                            >
                                {strings.buttonText.addMe}
                            </AssignToMeButton>
                        </Row>
                    </Section>
                    <Observer
                        subscriptions={{
                            reportCaseStatuses: 'reportCaseStatuses',
                            externalReportStatus: ['externalReportStatus', Fields.DEEP_MODEL],
                            offenseCaseStatuses: 'offenseCaseStatuses',
                        }}
                        render={({
                            reportCaseStatuses,
                            offenseCaseStatuses,
                            externalReportStatus,
                        }) => (
                            <>
                                <CaseStatusFieldset
                                    form={form}
                                    statusIsClosed={statusIsClosed}
                                    setCaseStatusDefaultDate={setCaseStatusDefaultDate}
                                />
                                <FeatureFlagged flag="RMS_CLEARANCE_IMPROVEMENTS_ENABLED">
                                    {!!externalReportStatus?.reportingEventId && (
                                        <Row>
                                            <ToolTipContainerContext.Provider
                                                value={`[data-test-id='${overlayIdEnum.CREATE_MANAGE_CASE_MODAL}'] [data-test-id='${testIds.MODAL_CONTENT}']`}
                                            >
                                                <ExternalReportStatusFieldset
                                                    title={externalReportStatusTitle}
                                                    context={
                                                        RefContextEnum.FORM_CREATE_MANAGE_CASE.name
                                                    }
                                                />
                                            </ToolTipContainerContext.Provider>
                                        </Row>
                                    )}
                                </FeatureFlagged>
                                {!!size(reportCaseStatuses) && !isBulkCreate && (
                                    <ReportStatusesFieldsetWrapper>
                                        <CollapsibleFieldset
                                            collapsed={false}
                                            highlightOnFocus={false}
                                            title={internalReportStatusTitle}
                                            expandedChildren={
                                                <ReportStatusesWrapper>
                                                    <ReportStatusesMFTNItems
                                                        form={form}
                                                        statusIsClosed={statusIsClosed}
                                                        setReportCaseStatusDefaultStatusDate={
                                                            setReportCaseStatusDefaultStatusDate
                                                        }
                                                    />
                                                </ReportStatusesWrapper>
                                            }
                                        >
                                            {(expandCollapseButton) => (
                                                <ExpandCollapseButton>
                                                    {expandCollapseButton}
                                                </ExpandCollapseButton>
                                            )}
                                        </CollapsibleFieldset>
                                    </ReportStatusesFieldsetWrapper>
                                )}
                                {!!size(offenseCaseStatuses) && (
                                    <ReportStatusesFieldsetWrapper>
                                        <CollapsibleFieldset
                                            collapsed={false}
                                            highlightOnFocus={false}
                                            title={offenseStatusTitle}
                                            expandedChildren={
                                                <OffenseCaseStatusFieldset
                                                    context={
                                                        RefContextEnum.FORM_CREATE_MANAGE_CASE.name
                                                    }
                                                />
                                            }
                                        >
                                            {(expandCollapseButton) => (
                                                <ExpandCollapseButton>
                                                    {expandCollapseButton}
                                                </ExpandCollapseButton>
                                            )}
                                        </CollapsibleFieldset>
                                    </ReportStatusesFieldsetWrapper>
                                )}
                            </>
                        )}
                    />
                    <FeatureFlagged flag="RMS_TARGET_PROFILES_ENABLED">
                        <SecondarySectionHeader>{targetProfileFieldName}</SecondarySectionHeader>
                        <Section>
                            <Row>
                                <ArbiterMFTSwitch path="isTargetProfile" />
                                <ArbiterMFTDatePicker
                                    path="targetProfileReviewDateUtc"
                                    width={dateTimePickerTotalWidth}
                                />
                            </Row>
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    path="targetProfileCategoryAttrIds"
                                    attributeType={AttributeTypeEnum.TARGET_PROFILE_CATEGORY.name}
                                    multiple
                                />
                                <ArbiterMFTAttributeSelect
                                    path="targetProfilePriorityAttrId"
                                    attributeType={AttributeTypeEnum.TARGET_PROFILE_PRIORITY.name}
                                />
                            </Row>
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    path="targetProfileAreaAttrIds"
                                    attributeType={[
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_1.name,
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_2.name,
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_3.name,
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_4.name,
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_5.name,
                                    ]}
                                    multiple
                                />
                            </Row>
                            <Row>
                                <ArbiterMFTTextArea path="targetProfileNarrative" rows={6} />
                            </Row>
                        </Section>
                    </FeatureFlagged>
                </>
            )}
        />
    );
};

export default compose(
    withFields([
        fields.EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID,
        fields.OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
        fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
    ]),
    connect(
        createStructuredSelector({
            allRoleFormatsByRoleId: allRoleFormatsByRoleIdSelector,
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
            userCasePermissions: currentUserCasePermissionsSelector,
            currentUserUserRoleId: currentUserUserRoleIdSelector,
            statusIsClosed: statusIsClosedSelector,
        })
    ),
    withHandlers({
        onAssignToMe({ currentUserUserRoleId }) {
            return (form) => form.set('assignee', currentUserUserRoleId);
        },
        onAssignAssistingToMe({ currentUserUserRoleId }) {
            return (form) => {
                const investigators = form.get('assistingInvestigators');
                form.set('assistingInvestigators', uniq([...investigators, currentUserUserRoleId]));
            };
        },
        onAddMeAsSupervisor({ currentUserUserRoleId }) {
            return (form) => {
                const supervisors = form.get('supervisors');
                form.set('supervisors', uniq([...supervisors, currentUserUserRoleId]));
            };
        },
        setCaseStatusDefaultDate() {
            return (form, pathToSet) => {
                form.set(pathToSet, nowUtc());
            };
        },
        setReportCaseStatusDefaultStatusDate() {
            return (form, index) => {
                form.set(`reportCaseStatuses[${index}].statusDateUtc`, nowUtc());
            };
        },
    })
)(CreateManageCaseForm);

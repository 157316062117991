import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';

import Popover from '../../../core/box/components/Popover';

import itemPopoverForm from '../state/forms/itemPopoverForm';
import ItemPopoverForm from './ItemPopoverForm';

const itemPopoverContext = {
    name: boxEnum.ITEM_POPOVER,
};

function ItemPopover({ formIsValid, onSubmit, context }) {
    const popoverContext = context || itemPopoverContext;

    return (
        <Popover context={popoverContext} onSave={onSubmit} saveDisabled={!formIsValid}>
            <ItemPopoverForm popoverContext={popoverContext} />
        </Popover>
    );
}

const mapStateToProps = createStructuredSelector({
    formIsValid: itemPopoverForm.selectors.formIsValidSelector,
});

/**
 * Item popover containing a form. Submitting it opens the item side panel.
 */
export default connect(mapStateToProps)(ItemPopover);

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RefContextEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { RmsDispatch } from '../../../../core/typings/redux';
import Modal from '../../../core/overlays/components/Modal';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { currentCaseIdSelector } from '../../core/state/ui';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import {
    submitWillNotInvestigateCommentForm,
    resetWillNotInvestigateCommentForm,
} from '../state/ui';
import { removeFromCaseAndMarkWillNotInvestigate } from '../../core/state/data';
import { convertFromFormModelToRequest } from '../../core/state/form/reasonForRemovalForm';
import WillNotInvestigateCommentForm from './WillNotInvestigateCommentForm';

interface WillNotInvestigateModalProps {
    fieldDisplayName: string;
    reportIds: number[];
    buttonElement: React.ReactNode;
}

const strings = componentStrings.cases.unassignedReports.WillNotInvestigateModal;

const BoldedText = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const FormDescription = styled.div`
    margin-bottom: 20px;
    font-size: var(--arc-fontSizes-sm);
`;

const WillNotInvestigateModal: React.FC<WillNotInvestigateModalProps> = ({
    fieldDisplayName,
    reportIds,
    buttonElement,
}) => {
    const dispatch = useDispatch<RmsDispatch>();

    const overlayStore = useOverlayStore();

    const { getForm } = useFormGetter();
    const form = getForm(RefContextEnum.FORM_REASON_FOR_REMOVAL_MODAL.name);
    const handleAfterClose = useCallback(() => {
        dispatch(resetWillNotInvestigateCommentForm());
        overlayStore.close(overlayIdEnum.REASON_FOR_REMOVAL_MODAL);
    }, [dispatch, overlayStore]);

    const currentCaseId = useSelector(currentCaseIdSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;

    const handleSave = useCallback(() => {
        if (currentCaseId && form) {
            return form.submit().then((result) => {
                const request = convertFromFormModelToRequest(
                    result.form.getState().model || {},
                    individualReportSelectionEnabled ? reportIds : undefined
                );
                return dispatch(removeFromCaseAndMarkWillNotInvestigate(request, reportIds));
            });
        } else {
            return dispatch(submitWillNotInvestigateCommentForm(reportIds));
        }
    }, [currentCaseId, form, individualReportSelectionEnabled, dispatch, reportIds]);

    const isBulk = reportIds.length > 1;

    return (
        <Modal
            buttonElement={buttonElement}
            id={overlayIdEnum.WILL_NOT_INVESTIGATE_MODAL}
            okText={fieldDisplayName}
            onAfterClose={handleAfterClose}
            onSave={handleSave}
            shouldCloseOnOverlayClick={true}
            title={isBulk ? strings.bulkTitle(fieldDisplayName) : strings.title(fieldDisplayName)}
        >
            {isBulk && (
                <FormDescription>
                    {strings.description}
                    <BoldedText>{strings.boldDescription(reportIds.length)}</BoldedText>
                </FormDescription>
            )}
            <WillNotInvestigateCommentForm />
        </Modal>
    );
};

export default WillNotInvestigateModal;

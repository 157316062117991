import { createSelector } from 'reselect';
import { first } from 'lodash';
import { FolderContentView } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'folderContentViews';

const folderContentViewsModule = createNormalizedModule<FolderContentView>({
    type: NEXUS_STATE_PROP,
    key: 'folderId',
});

// SELECTORS
export const folderContentViewsSelector = folderContentViewsModule.selectors.entitiesSelector;
export const folderContentViewByIdSelector = folderContentViewsModule.selectors.entityByIdSelector;
export const folderContentViewWhereSelector =
    folderContentViewsModule.selectors.entitiesWhereSelector;

export const folderContentViewByFolderIdSelector = createSelector(
    folderContentViewWhereSelector,
    (folderContentViewWhere) => (folderId: number) => first(folderContentViewWhere({ folderId }))
);

// REDUCER
export default folderContentViewsModule.reducerConfig;

import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import StyledLocationCell from './StyledLocationCell';

export default function PrimaryLocationCell(elasticReport) {
    const primaryLocation = getViewModelProperties(elasticReport).primaryLocation;
    return <StyledLocationCell locationViewModel={primaryLocation} />;
}

export function ReportTakenLocationCell(elasticReport) {
    const reportTakenLocation = getViewModelProperties(elasticReport).reportTakenLocation;
    return <StyledLocationCell locationViewModel={reportTakenLocation} />;
}

import {
    UsageSourceModuleEnum,
    EntityTypeEnum,
    UsageActionEnum,
    UsageCompletionEnum,
} from '@mark43/rms-api';

import { storeCasesDashboardTab } from '../core/state/ui';
import { casesTabEnum } from '../core/configuration';
import { executeSavedSearchFromQueryParam } from '../../search/core/state/ui';
import { createUsageLog } from '../../admin/usage-logs/state/data';
import { unassignedReportsSearch, searchInitialUnassignedReports } from './state/ui';

const TAB_NAME = casesTabEnum.UNASSIGNED;

export function onEnter(nextState, replace) {
    this.dispatch(unassignedReportsSearch.actionCreators.setIsInitialSearch(true));
    this.dispatch(storeCasesDashboardTab(TAB_NAME));
    this.dispatch(
        executeSavedSearchFromQueryParam({
            searchModule: unassignedReportsSearch,
            nextState,
            replace,
        })
    ).then((executed) => {
        if (!executed) {
            this.dispatch(searchInitialUnassignedReports());
        }
    });
    this.dispatch(
        createUsageLog({
            primaryEntityType: EntityTypeEnum.CASE.name,
            action: UsageActionEnum.VIEWED_UNASSIGNED_CASES_DASHBOARD.name,
            completion: UsageCompletionEnum.SUCCEEDED.name,
            sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
        })
    );
}

import { RoleTypeEnum, RefContextEnum, UserProfileView } from '@mark43/rms-api';
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { compose, withState } from 'recompose';
import { noop } from 'lodash';
import { createField, createFormConfiguration, lifecycleOptions } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import * as fields from '~/client-common/core/enums/universal/fields';

import { Button as ArcButton } from '../../../../core/components/Button';

import { currentUserProfileSelector } from '../../../../core/current-user/state/ui';
import { ArbiterMFTRoleSelect } from '../../../../core/forms/components/selects/RoleSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import Row from '../../../../core/components/Row';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import authResource from '../../../../../legacy-redux/resources/authResource';
import { AnalyticsPropertyEnum } from '../../../../analytics/constants/analyticsEnum';
import { AnalyticsContextProviderWithAdditionalData } from '../../../../core/context/AnalyticsContext';
import testIds from '../../../../../core/testIds';
import { submitReport } from '../../state/ui/submissions';
import { embeddedReportShortTitlesSelector } from '../../state/ui/arrestBlock';
import formsRegistry from '../../../../../core/formsRegistry';
import { focusFirstElementOnCard } from '../../utils/cardHelpers';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';
import { RmsDispatch } from '../../../../../core/typings/redux';
import _CardWithApprovalStatusIcon from './CardWithApprovalStatusIcon';

const { NORMAL, USER } = RoleTypeEnum;

const strings = componentStrings.reports.core.ReportStatusCommentsCard;

const CardWithApprovalStatusIcon = styled(_CardWithApprovalStatusIcon)`
    font-size: var(--arc-fontSizes-sm);
`;

const DividingLine = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    margin-top: 10px;
    width: 100%;
    margin-bottom: 23px;
`;

const ErrorText = styled.p`
    color: ${(props) => props.theme.colors.red};
    min-height: 20px;
    margin-bottom: 0;
`;
interface SubmissionDraftCardOutsidePropsT {
    className?: string;
    disabled: boolean;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
}
interface SubmissionDraftCardInsidePropsT extends SubmissionDraftCardOutsidePropsT {
    submitForm: (currentUserProfile: UserProfileView) => () => void;
    isSubmitting: boolean;
    errorMessage: string;
    currentUserProfile: UserProfileView | undefined;
}

function SubmissionDraftCard({
    submitForm,
    className,
    isSubmitting,
    errorMessage,
    currentUserProfile,
    disabled,
    reportSealingInfo,
}: SubmissionDraftCardInsidePropsT) {
    useEffect(() => {
        focusFirstElementOnCard('report-status-comments-card');
    }, []);

    const embeddedReportCount = useSelector(embeddedReportShortTitlesSelector).length;

    const isSsoUser = currentUserProfile?.isSsoUser;

    return (
        <CardWithApprovalStatusIcon
            approvalStatus={approvalStatusClientEnum.DRAFT}
            reportSealingInfo={reportSealingInfo}
            title={strings.draft.submitTitle}
            className={className}
            latestHistoryText={strings.draft.returnToDraft}
            testId={testIds.REPORT_STATUS_COMMENTS_SUBMISSION_DRAFT_CARD}
        >
            {!disabled && (
                <ArbiterForm
                    name={RefContextEnum.FORM_REPORT_SUBMISSION.name}
                    context={RefContextEnum.FORM_REPORT_SUBMISSION.name}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    configuration={createFormConfiguration({
                        roleIds: createField<number[]>({
                            fieldName: fields.DISPLAY_ONLY_REPORT_SUBMISSION_NOTIFY_ROLE_IDS,
                        }),
                        userIds: createField<number[]>({
                            fieldName: fields.DISPLAY_ONLY_REPORT_SUBMISSION_NOTIFY_USER_IDS,
                        }),
                        password: createField<string>({
                            fieldName: fields.DISPLAY_ONLY_REPORT_SUBMISSION_PASSWORD,
                        }),
                        isSsoUser: createField<boolean>({
                            fieldName: fields.DISPLAY_ONLY_REPORT_SUBMISSION_IS_SSO_USER,
                        }),
                    })}
                    initialState={{
                        roleIds: [],
                        userIds: [],
                        password: '',
                        isSsoUser,
                    }}
                    render={() => (
                        <>
                            <p>{strings.draft.warning}</p>
                            <Row>
                                <ArbiterMFTRoleSelect
                                    length="lg"
                                    path="userIds"
                                    multiple={true}
                                    roleTypes={[USER.name]}
                                />
                                <ArbiterMFTRoleSelect
                                    noAsyncAction={true}
                                    path="roleIds"
                                    length="lg"
                                    multiple={true}
                                    roleTypes={[NORMAL.name]}
                                />
                            </Row>
                            {!isSsoUser && <DividingLine />}
                            <Row>
                                <ArbiterMFTText
                                    onPressEnter={
                                        currentUserProfile ? submitForm(currentUserProfile) : noop
                                    }
                                    isPassword={true}
                                    path="password"
                                    length="lg"
                                />
                            </Row>
                        </>
                    )}
                />
            )}
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            <AnalyticsContextProviderWithAdditionalData
                analyticsKeyToAdd={AnalyticsPropertyEnum.REPORT_COUNT}
                analyticsValueToAdd={embeddedReportCount + 1}
            >
                <ArcButton
                    isTextTransformNone
                    variant="solid"
                    isDisabled={isSubmitting || disabled}
                    onClick={currentUserProfile ? submitForm(currentUserProfile) : noop}
                    testId={testIds.REPORT_STATUS_COMMENTS_CARD_SUBMIT_REPORT_BUTTON}
                >
                    {embeddedReportCount > 0
                        ? strings.core.bulkSubmit(embeddedReportCount + 1)
                        : strings.core.submit}
                </ArcButton>
            </AnalyticsContextProviderWithAdditionalData>
        </CardWithApprovalStatusIcon>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUserProfile: currentUserProfileSelector,
});

const mapDispatchToProps = (
    dispatch: RmsDispatch,
    ownProps: {
        isSubmitting: boolean;
        setIsSubmitting: (updateFn: boolean | ((value: boolean) => boolean)) => void;
        errorMessage: string;
        setErrorMessage: (updateFn: string | ((value: string) => string)) => void;
    }
) => ({
    submitForm: (currentUserProfile: UserProfileView) => () => {
        ownProps.setIsSubmitting(true);
        const form = formsRegistry.get(RefContextEnum.FORM_REPORT_SUBMISSION.name);
        form?.submit()
            .then(() => {
                const email = currentUserProfile.primaryEmail;
                ownProps.setErrorMessage('');
                // SSO users do not have a password with us and we do not currently
                // support e-signatures via SSO providers, so we just skip the
                // check altogether
                const basePromise = currentUserProfile.isSsoUser
                    ? Promise.resolve(true)
                    : authResource.dryAuthenticate({
                          username: email,
                          password: form.get('password') as string | undefined,
                      });
                basePromise
                    .then(() => {
                        dispatch(submitReport());
                        ownProps.setIsSubmitting(false);
                    })
                    .catch((err: Error) => {
                        form.resetModel();
                        ownProps.setErrorMessage(err.message);
                        ownProps.setIsSubmitting(false);
                    });
            })
            .catch(() => {
                ownProps.setIsSubmitting(false);
            });
    },
});

export default compose<SubmissionDraftCardInsidePropsT, SubmissionDraftCardOutsidePropsT>(
    withState('isSubmitting', 'setIsSubmitting', false),
    withState('errorMessage', 'setErrorMessage', ''),
    connect(mapStateToProps, mapDispatchToProps)
)(SubmissionDraftCard);

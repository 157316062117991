import React from 'react';
import styled from 'styled-components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

const CellWrapper = styled.div`
    margin-left: 12px;
`;
const StorageLocationCell = (data) => {
    const { storageLocationFullDisplayPath } = getViewModelProperties(data);

    return <CellWrapper>{storageLocationFullDisplayPath}</CellWrapper>;
};

export default StorageLocationCell;

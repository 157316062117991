import React, { FC } from 'react';
import styled from 'styled-components';
import { Modal, Text, Icon, Box, Button } from 'arc';
import { Link as ReactRouterLink } from 'react-router';
import { useSelector } from 'react-redux';
import { cssVar } from '@arc/core';

import { elasticCasesViewModelForElasticCasesSelector } from '~/client-common/core/domain/elastic-cases/state/data';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { useFormattedDate } from '~/client-common/core/dates/hooks';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { formatElasticAttributes } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { CASE_TARGET_PROFILE_NARRATIVE } from '~/client-common/core/enums/universal/fields';

import testIds from '../../../core/testIds';
import { CaseProfileImage } from '../../core/components/ProfileImage';

const StyledReactRouterLink = styled(ReactRouterLink)`
    display: flex;
    gap: 4px;
    align-items: center;

    &:hover {
        text-decoration: underline;
    }
`;

export type TargetProfilesSnapshotModalProps = {
    isOpen: boolean;
    onClose: () => void;
    caseItem: ReturnType<ReturnType<typeof elasticCasesViewModelForElasticCasesSelector>>[0] | null;
};

export const TargetProfilesSnapshotModal: FC<TargetProfilesSnapshotModalProps> = ({
    isOpen,
    onClose,
    caseItem,
}) => {
    const {
        caseNumber,
        title,
        id,
        targetProfileCategoryAttrDetails = [],
        targetProfileAreaAttrDetails = [],
        targetProfileReviewDateUtc,
        assigneeRoleId = -1,
        targetProfileNarrative,
        primaryImagePath,
    } = caseItem || {};

    const resultTitle = `${caseNumber} - ${title}`;

    const firstCaptionContent = joinTruthyValues(
        [
            formatElasticAttributes(targetProfileCategoryAttrDetails),
            formatElasticAttributes(targetProfileAreaAttrDetails),
        ],
        '   |   '
    );

    const formattedTargetProfileReviewDateUtc = useFormattedDate(
        targetProfileReviewDateUtc,
        'formDate'
    );

    const fields = useFields([CASE_TARGET_PROFILE_NARRATIVE]);

    const allRoleFormatsByRoleId = useSelector(allRoleFormatsByRoleIdSelector);

    const assignee = allRoleFormatsByRoleId(assigneeRoleId).default;

    const secondCaptionContent = joinTruthyValues(
        [
            formattedTargetProfileReviewDateUtc &&
                `Review - ${formattedTargetProfileReviewDateUtc}`,
            assignee && `Assignee - ${assignee}`,
        ],
        '  |  '
    );

    return (
        <Modal
            data-test-id={testIds.TARGET_PROFILE_SNAPSHOT_MODAL}
            onClose={onClose}
            title={resultTitle}
            isOpen={isOpen}
            isCentered
            size="lg"
            footer={
                <Button variant="ghost" size="md" onClick={onClose}>
                    Close
                </Button>
            }
            body={
                <Box display="flex" flexDirection="column" gap={cssVar('arc.space.6')}>
                    <Box display="flex" gap={cssVar('arc.space.3')}>
                        <CaseProfileImage url={primaryImagePath} size={cssVar('arc.space.16')} />
                        <div data-test-id={testIds.TARGET_PROFILE_SNAPSHOT_MODAL_DETAILS}>
                            <StyledReactRouterLink to={`/cases/${id}/summary`} color="cobaltBlue" target="_blank">
                                <Text color="inherit" fontWeight="bold" variant="headingSm">
                                    {resultTitle}
                                </Text>
                                <Icon icon="OpenNewWindow" size="sm" />
                            </StyledReactRouterLink>
                            {firstCaptionContent && (
                                <Text
                                    variant="caption"
                                    css={{
                                        whiteSpace: 'pre',
                                        marginBottom: cssVar('arc.space.half'),
                                        marginTop: cssVar('arc.space.1'),
                                    }}
                                >
                                    {firstCaptionContent}
                                </Text>
                            )}
                            {secondCaptionContent && (
                                <Text variant="caption" css={{ whiteSpace: 'pre' }}>
                                    {secondCaptionContent}
                                </Text>
                            )}
                        </div>
                    </Box>
                    {targetProfileNarrative && (
                        <div data-test-id={testIds.TARGET_PROFILE_SNAPSHOT_MODAL_NARRATIVE}>
                            <Text
                                variant="headingXs"
                                css={{ marginBottom: cssVar('arc.space.half') }}
                            >
                                {fields.CASE_TARGET_PROFILE_NARRATIVE}
                            </Text>
                            <Text variant="bodyMd">{targetProfileNarrative}</Text>
                        </div>
                    )}
                </Box>
            }
        />
    );
};

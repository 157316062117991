import evidenceExportResource from '../../resources/evidenceExportResource';
import {
    expandMyExports,
    pollForExports,
} from '../../../../../legacy-redux/actions/exportsActions';

export const EVIDENCE_CSV_EXPORT_START = 'evidence-csv-export/EVIDENCE_CSV_EXPORT_START';
export const EVIDENCE_CSV_EXPORT_SUCCESS = 'evidence-csv-export/EVIDENCE_CSV_EXPORT_SUCCESS';
export const EVIDENCE_CSV_EXPORT_FAILURE = 'evidence-csv-export/EVIDENCE_CSV_EXPORT_FAILURE';

function evidenceCsvExportStart() {
    return { type: EVIDENCE_CSV_EXPORT_START };
}
function evidenceCsvExportSuccess() {
    return { type: EVIDENCE_CSV_EXPORT_SUCCESS };
}
function evidenceCsvExportFailure(errorMessage) {
    return { type: EVIDENCE_CSV_EXPORT_FAILURE, payload: errorMessage, error: true };
}

export function exportSearchResults(evidenceSearchWorkOrders) {
    return (dispatch) => {
        dispatch(evidenceCsvExportStart());

        return evidenceExportResource
            .export(evidenceSearchWorkOrders)
            .then(() => {
                dispatch(evidenceCsvExportSuccess());
                dispatch(expandMyExports());
                dispatch(pollForExports());
            })
            .catch((err) => dispatch(evidenceCsvExportFailure(err.message)));
    };
}

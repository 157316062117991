import React from 'react';

import { getDropdownOptionsFromObject } from '~/client-common/helpers/dropdownOptionHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { arbiterMFTInput } from '../../../arbiter';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const { options } = componentStrings.forms.select.DispositionApprovalLevelSelect;
const dispositionApprovalLevelOptions = getDropdownOptionsFromObject(options);

/**
 * `Select` dropdown for disposition approval levels.
 * @param {Object} props
 */
function DispositionApprovalLevelSelect(props) {
    return <Select options={dispositionApprovalLevelOptions} {...props} />;
}

export const RRFDispositionApprovalLevelSelect = connectRRFInput(DispositionApprovalLevelSelect);
export const ArbiterMFTDispositionApprovalLevelSelect = arbiterMFTInput(
    DispositionApprovalLevelSelect
);

import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { excludedAttachmentLinkTypesForChainOfCustodyExport } from '../config';
import { returnToPreviousRoute } from '../../../core/session-history';
import FormExports from '../../../../legacy-redux/components/exports/FormExports';
import chainOfCustodyExportResource from '../resources/chainOfCustodyExportResource';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';

const strings = componentStrings.exports.CombinedExports;

function ChainOfCustodyExport({ returnToPreviousRoute }) {
    return (
        <ScrollableUnderSubheader style={{ padding: '20px 0 60px' }}>
            <FormExports
                useExportRouteToggle={false}
                closeCallback={returnToPreviousRoute}
                exportResourceMethod={chainOfCustodyExportResource.exportChainOfCustody}
                includeAttachments={true}
                excludeAttachmentLinkTypes={excludedAttachmentLinkTypesForChainOfCustodyExport}
                defaultFilename={strings.title}
            />
        </ScrollableUnderSubheader>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    returnToPreviousRoute: () =>
        dispatch(returnToPreviousRoute(ownProps.router, '/evidence/dashboard')),
});

/**
 * The Chain of Custody export component
 * The selection of which chain of custodies should be printed
 */
export default compose(withRouter, connect(null, mapDispatchToProps))(ChainOfCustodyExport);

import React, { useMemo } from 'react';
import { EntityTypeEnum } from '@mark43/rms-api';

import { OmitFromUnion, PersonCustomCautionsData } from '../configuration';
import { preparePersonCustomCautions } from '../helpers/customCautions';
import { EnhancedCautions, EnhancedCautionsProps } from './EnhancedCautions';

type PersonEnhancedCautionsProps = OmitFromUnion<
    EnhancedCautionsProps,
    'customCautions' | 'entityType'
> &
    PersonCustomCautionsData;

export const PersonEnhancedCautions: React.FC<PersonEnhancedCautionsProps> = ({
    isActiveTarget,
    isJuvenile,
    isSuspectedGangMember,
    isVulnerable,
    dateVulnerableTo,
    hasPotentialActiveWarrant,
    ...enhancedCautionsProps
}) => {
    const customCautions = useMemo(
        () =>
            preparePersonCustomCautions({
                isActiveTarget,
                isJuvenile,
                isSuspectedGangMember,
                isVulnerable,
                dateVulnerableTo,
                hasPotentialActiveWarrant,
            }),
        [
            isActiveTarget,
            isJuvenile,
            isSuspectedGangMember,
            isVulnerable,
            dateVulnerableTo,
            hasPotentialActiveWarrant,
        ]
    );
    return (
        <EnhancedCautions
            {...enhancedCautionsProps}
            entityType={EntityTypeEnum.PERSON_PROFILE.name}
            customCautions={customCautions}
        />
    );
};

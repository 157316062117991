import { CasePrintingTemplate } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'casePrintingTemplates';

const casePrintingTemplatesModule = createNormalizedModule<CasePrintingTemplate>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// ACTIONS
export const storeCasePrintingTemplates = casePrintingTemplatesModule.actionCreators.storeEntities;

export const deleteCasePrintingTemplatesWhere =
    casePrintingTemplatesModule.actionCreators.deleteEntitiesWhere;

// SELECTORS
export const casePrintingTemplatesSelector = casePrintingTemplatesModule.selectors.entitiesSelector;

// REDUCER
export default casePrintingTemplatesModule.reducerConfig;

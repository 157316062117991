import factory from '../../../../../legacy-redux/actions/types/actionTypesFactory';
import resource from '../../../../evidence/label-printing/resources/barcodeLabelResource';
import evidenceConfigAdminForm from '../forms/evidenceConfigAdminForm';

export const actionTypes = factory('evidence', [
    'LOAD_LABEL_PREVIEW_START',
    'LOAD_LABEL_PREVIEW_SUCCESS',
    'LOAD_LABEL_PREVIEW_FAILURE',
]);

function loadLabelPreviewStart() {
    return {
        type: actionTypes.LOAD_LABEL_PREVIEW_START,
    };
}

function loadLabelPreviewSuccess(labelPreviewBase64) {
    return {
        type: actionTypes.LOAD_LABEL_PREVIEW_SUCCESS,
        payload: labelPreviewBase64,
    };
}

function loadLabelPreviewFailure(errorMessage) {
    return {
        type: actionTypes.LOAD_LABEL_PREVIEW_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

export function loadLabelPreview() {
    return function (dispatch, getState) {
        dispatch(loadLabelPreviewStart());

        const { labelLayoutElements } = evidenceConfigAdminForm.selectors.formModelSelector(
            getState()
        );

        return resource
            .getLabelPreview(labelLayoutElements)
            .then(({ preview }) => dispatch(loadLabelPreviewSuccess(preview)))
            .catch((err) => dispatch(loadLabelPreviewFailure(err.message)));
    };
}

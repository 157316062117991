import { first, filter } from 'lodash';
import { LegacySelectOption } from '../../../core/forms/helpers/selectHelpers';

export const purposeCodes: LegacySelectOption[] = [
    {
        value: 'C',
        display: 'C - Criminal Justice',
    },
    {
        value: 'I',
        display: 'I - Immigration Enforcement',
    },
    {
        value: 'U',
        display: 'U - Investigate Violations of Title 8, section 1325',
    },
];

export const getPurposeCodeDisplayValue = (code: string): string => {
    const purposeCode = first(filter(purposeCodes, { value: code }));
    if (!purposeCode) {
        return '';
    }
    return purposeCode.display;
};

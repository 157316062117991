import React from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { casesSelector } from '~/client-common/core/domain/cases/state/data';
import { ExportPageWithColumns } from '../../../../legacy-redux/components/exports/LayoutComponents';
import { exportPageReleaseTrackingConfigSelector } from '../../../../legacy-redux/selectors/exportsSelectors';
import BulkCasesExportForm from '../../bulk-exports/components/BulkCasesExportForm';

function BulkCasesExports() {
    return <ExportPageWithColumns leftColumn={<BulkCasesExportForm />} rightColumn={<></>} />;
}

const mapStateToProps = createStructuredSelector({
    cases: casesSelector,
    exportPageReleaseTrackingConfig: exportPageReleaseTrackingConfigSelector,
});

export default compose(withRouter, connect(mapStateToProps))(BulkCasesExports);

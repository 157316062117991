export default {
    noSentryUrl: 'NO_SENTRY_URL',

    linkTypeLetters: {
        4: 'V',
        125: 'V',
        5: 'S',
        8: 'S',
        13: 'M',
        15: 'W',
        108: 'W',
        28: 'D',
        50: 'O',
        71: 'O',
        14: 'SB',
        237: 'SB', // SUBJECT_IN_STOP
        114: 'SB', // SUBJECT_IN_FIELD_CONTACT
        132: 'SB',
        133: 'OR',
        56: 'R',
        53: 'C',
        205: 'CP', // COMPLAINANT_IN_REPORT
        169: 'LC',
        191: 'RT',
        192: 'RA',
        30: 'SB', // SUBJECT_OF_WARRANT
        207: 'SB', // SUBJECT_IN_TRAFFIC_CRASH
        209: 'R', // SUBJECT_IN_CITATION
        215: 'P', // INVOLVED_PERSON_IN_REPORT
        216: 'O', // INVOLVED_ORG_IN_REPORT
        223: 'SB', // SUBJECT_IN_BEHAVIORAL_CRISIS
        232: 'O', // OTHER_NAME_IN_BEHAVIORAL_CRISIS
        238: 'O', // OTHER_NAME_IN_STOP
        233: 'O', // OTHER_NAME_IN_FIELD_CONTACT
        272: 'O', // CONFIGURED_ENTITY_NAME_IN_REPORT
    },
} as const;

export const SUPPORT_URL = "https://get.mark43.help/s/";

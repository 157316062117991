import _, { compact, map, uniqBy, mapValues, sortBy, keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';

import { elasticPersonViewModelsSelector } from '~/client-common/core/domain/elastic-persons/state/ui';
import { elasticOrganizationViewModelsSelector } from '~/client-common/core/domain/elastic-organizations/state/ui';
import { elasticInvolvedLocationViewModelsSelector } from '~/client-common/core/domain/elastic-involved-locations/state/ui';
import { elasticVehicleViewModelsSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import { elasticPropertyViewModelsSelector } from '~/client-common/core/domain/elastic-property/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { elasticReportByIdSelector } from '~/client-common/core/domain/elastic-reports/state/data';
import { elasticWarrantByIdSelector } from '~/client-common/core/domain/elastic-warrants/state/data';
import { itemCaseLinksWhereSelector } from '~/client-common/core/domain/item-case-links/state/data';
import { nameCaseLinksWhereSelector } from '~/client-common/core/domain/name-case-links/state/data';
import { formatInvolvementSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { getTruthyValuesByKey } from '~/client-common/helpers/logicHelpers';

import { currentCaseIdSelector, currentCaseViewSelector } from '../../../core/state/ui';
import { createGenericNameCaseLinks, createGenericItemCaseLinks } from '../../helpers';
import * as actionTypes from './action/types';

const currentCaseLinkedProfilesSelector = (state) => state.ui.cases.linkedProfiles;

export const currentCaseLinkedProfileViewModelsSelector = createSelector(
    currentCaseLinkedProfilesSelector,
    elasticPersonViewModelsSelector,
    elasticOrganizationViewModelsSelector,
    elasticInvolvedLocationViewModelsSelector,
    elasticVehicleViewModelsSelector,
    elasticPropertyViewModelsSelector,
    elasticReportByIdSelector,
    elasticWarrantByIdSelector,
    (
        currentCaseLinkedProfileIds,
        elasticPersonViewModels,
        elasticOrganizationViewModels,
        elasticLocationViewModels,
        elasticVehicleViewModels,
        elasticPropertyViewModels,
        elasticReportById,
        elasticWarrantById
    ) => {
        return mapValues(
            {
                relatedReports: compact(
                    map(currentCaseLinkedProfileIds.relatedReportIds, elasticReportById)
                ),
                relatedWarrants: compact(
                    map(currentCaseLinkedProfileIds.relatedWarrantIds, elasticWarrantById)
                ),
                relatedPersons: _(currentCaseLinkedProfileIds.relatedPersonIds)
                    .map((personProfileId) => elasticPersonViewModels[personProfileId])
                    .compact()
                    .sortBy('lastName', 'firstName')
                    .value(),
                relatedOrganizations: _(currentCaseLinkedProfileIds.relatedOrganizationIds)
                    .map(
                        (organizationProfileId) =>
                            elasticOrganizationViewModels[organizationProfileId]
                    )
                    .compact()
                    .sortBy('name')
                    .value(),
                relatedLocations: _(currentCaseLinkedProfileIds.relatedLocationIds)
                    .map((locationId) => elasticLocationViewModels[locationId])
                    .compact()
                    .sortBy('aliases[0]')
                    .value(),
                relatedVehicles: _(currentCaseLinkedProfileIds.relatedVehicleIds)
                    .map((vehicleId) => elasticVehicleViewModels[vehicleId])
                    .sortBy(
                        (elasticVehicleViewModel) =>
                            getViewModelProperties(elasticVehicleViewModel).makeAttrId
                    )
                    .value(),
                relatedProperties: _(currentCaseLinkedProfileIds.relatedPropertyIds)
                    .map((propertyId) => elasticPropertyViewModels[propertyId])
                    .compact()
                    .sortBy('itemTypeAttrId')
                    .value(),
            },
            (linkedProfiles) => uniqBy(linkedProfiles, 'id')
        );
    }
);

const relatedReportsByIdViewModelSelector = createSelector(
    currentCaseLinkedProfileViewModelsSelector,
    ({ relatedReports }) => {
        const relatedReportsById = {
            [EntityTypeEnum.PERSON_PROFILE.name]: keyBy(
                relatedReports.flatMap(({ involvedPersons }) => involvedPersons),
                'person.masterPersonId'
            ),
            [EntityTypeEnum.ORGANIZATION_PROFILE.name]: keyBy(
                relatedReports.flatMap(({ involvedOrganizations }) => involvedOrganizations),
                'org.masterOrganizationId'
            ),
            [EntityTypeEnum.ITEM_PROFILE.name]: keyBy(
                relatedReports.flatMap(({ involvedVehicles }) => involvedVehicles),
                'vehicle.masterVehicleId'
            ),
        };

        return relatedReportsById;
    }
);

export const checkProfileIsFromReportSelector = createSelector(
    relatedReportsByIdViewModelSelector,
    (relatedReportsById) => (masterProfileId, entityType) => {
        return masterProfileId in relatedReportsById[entityType];
    }
);

const caseLinkedProfilesFormCaseLinksSelector = createSelector(
    currentCaseIdSelector,
    itemCaseLinksWhereSelector,
    nameCaseLinksWhereSelector,
    (currentCaseId, itemCaseLinksWhere, nameCaseLinksWhere) => {
        return {
            personNameCaseLinks: nameCaseLinksWhere({
                caseId: currentCaseId,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
            }),

            orgNameCaseLinks: nameCaseLinksWhere({
                caseId: currentCaseId,
                entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            }),
            vehicleItemCaseLinks: itemCaseLinksWhere({
                caseId: currentCaseId,
                entityType: EntityTypeEnum.ITEM_PROFILE.name,
            }),
        };
    }
);

export const caseLinkedProfilesFormViewModelSelector = createSelector(
    currentCaseViewSelector,
    currentCaseLinkedProfilesSelector,
    caseLinkedProfilesFormCaseLinksSelector,
    checkProfileIsFromReportSelector,
    (currentCaseViewModel, linkedProfiles, caseLinks, checkProfileIsFromReport) => {
        const personNameCaseLinks = createGenericNameCaseLinks({
            relatedEntityIds: linkedProfiles.relatedPersonIds,
            nameCaseLinks: caseLinks.personNameCaseLinks,
            entityType: EntityTypeEnum.PERSON_PROFILE.name,
            checkProfileIsFromReport,
        });

        const orgNameCaseLinks = createGenericNameCaseLinks({
            relatedEntityIds: linkedProfiles.relatedOrganizationIds,
            nameCaseLinks: caseLinks.orgNameCaseLinks,
            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            checkProfileIsFromReport,
        });

        const vehicleItemCaseLinks = createGenericItemCaseLinks({
            relatedEntityIds: linkedProfiles.relatedVehicleIds,
            itemCaseLinks: caseLinks.vehicleItemCaseLinks,
            entityType: EntityTypeEnum.ITEM_PROFILE.name,
        });

        const linkMapper = (link) => extendCaseLinkWithCaseProps(link, currentCaseViewModel);

        // split links into those which come from reports and regular
        // because profiles coming report links do not require involvement attr select to be filled
        return {
            personNameCaseLinks: personNameCaseLinks.regular.map(linkMapper),
            personNameCaseLinksFromReport: personNameCaseLinks.fromReport.map(linkMapper),
            orgNameCaseLinks: orgNameCaseLinks.regular.map(linkMapper),
            orgNameCaseLinksFromReport: orgNameCaseLinks.fromReport.map(linkMapper),
            vehicleItemCaseLinks: vehicleItemCaseLinks.map(linkMapper),
        };
    }
);

export const extendCaseLinkWithCaseProps = (caseLink, caseViewModel) => ({
    ...caseLink,
    // used for rules only
    isTargetProfile: caseViewModel?.isTargetProfile,
});

export const formatPersonInvolvementSelector = createSelector(
    formatInvolvementSelector,
    currentCaseLinkedProfileViewModelsSelector,
    attributesSelector,
    nameCaseLinksWhereSelector,
    currentCaseIdSelector,
    (
        formatInvolvement,
        { relatedReports = [], relatedWarrants = [] },
        attributes,
        nameCaseLinksWhere,
        currentCaseId
    ) =>
        (personId) => {
            const predicate = {
                caseId: currentCaseId,
                nameId: personId,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
            };

            const nameCaseLinks = nameCaseLinksWhere(predicate);

            const involvementTypeAttrIds = getTruthyValuesByKey(
                nameCaseLinks,
                'involvementTypeAttrId'
            );

            const warrantLinkTypes = relatedWarrants.reduce((acc, { subject: elasticPerson }) => {
                const idsAreEqual =
                    personId === (elasticPerson?.masterPersonId || elasticPerson?.id);

                if (idsAreEqual) {
                    acc.push(elasticPerson[LinkTypesEnum.SUBJECT_OF_WARRANT]);
                }

                return acc;
            }, []);

            const relevantElasticInvolvedPersons = relatedReports
                .flatMap(({ involvedPersons }) => involvedPersons)
                .filter((eip) => personId === (eip.person.masterPersonId || eip.person.id));

            const reportLinkTypes = relevantElasticInvolvedPersons
                .filter(({ subjectTypeAttrId }) => !subjectTypeAttrId)
                .map(({ involvement }) => involvement);

            const reportSubjectTypeAttrIds = sortBy(
                relevantElasticInvolvedPersons,
                'nameReportLinkId'
            )
                .map(({ subjectTypeAttrId }) => subjectTypeAttrId)
                .filter(Boolean);

            return formatInvolvement(
                reportLinkTypes.concat(warrantLinkTypes),
                reportSubjectTypeAttrIds.concat(involvementTypeAttrIds),
                attributes
            );
        }
);

export const formatOrganizationInvolvementSelector = createSelector(
    formatInvolvementSelector,
    currentCaseLinkedProfileViewModelsSelector,
    attributesSelector,
    nameCaseLinksWhereSelector,
    currentCaseIdSelector,
    (formatInvolvement, { relatedReports = [] }, attributes, nameCaseLinksWhere, currentCaseId) =>
        (orgId) => {
            const predicate = {
                caseId: currentCaseId,
                nameId: orgId,
                entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            };

            const nameCaseLinks = nameCaseLinksWhere(predicate);
            const involvementTypeAttrIds = getTruthyValuesByKey(
                nameCaseLinks,
                'involvementTypeAttrId'
            );

            const relevantElasticInvolvedOrgs = relatedReports
                .flatMap(({ involvedOrganizations }) => involvedOrganizations)
                .filter((eio) => orgId === (eio.org.masterOrganizationId || eio.org.id));

            const linkTypes = relevantElasticInvolvedOrgs
                .filter((elasticInvolvedOrg) => !elasticInvolvedOrg?.subjectTypeAttrDetail?.id)
                .map(({ involvement }) => involvement);

            const subjectTypeAttrIds = sortBy(relevantElasticInvolvedOrgs, 'nameReportLinkId')
                .map((elasticInvolvedOrg) => elasticInvolvedOrg?.subjectTypeAttrDetail?.id)
                .filter(Boolean);

            return formatInvolvement(
                linkTypes,
                subjectTypeAttrIds.concat(involvementTypeAttrIds),
                attributes
            );
        }
);

/*
    - selector that computes the number of linked profiles based on the object of arrays of linked profiles (currentCaseLinkedProfileViewModelsSelector)
    - goes through each array of possible profiles, but excludes warrants and reports since they show up in the summary section
*/
export const currentCaseLinkedProfilesCountSelector = createSelector(
    currentCaseLinkedProfileViewModelsSelector,
    (currentCaseLinkedProfilesByType) =>
        _.keys(currentCaseLinkedProfilesByType)
            .map((type) =>
                type !== 'relatedReports' && type !== 'relatedWarrants'
                    ? currentCaseLinkedProfilesByType[type].length
                    : 0
            )
            .reduce((a, b) => a + b)
);

export default function currentCaseLinkedProfilesReducer(
    state = {
        relatedLocationIds: [],
        relatedOrganizationIds: [],
        relatedPersonIds: [],
        relatedPropertyIds: [],
        relatedReportIds: [],
        relatedVehicleIds: [],
        relatedWarrantIds: [],
    },
    action
) {
    switch (action.type) {
        case actionTypes.SET_CURRENT_CASE_RELATED_ENTITIES:
            const {
                elasticInvolvedLocations,
                elasticOrganizations,
                elasticPersons,
                elasticProperty,
                elasticReports,
                elasticVehicles,
                elasticWarrants,
            } = action.payload;
            return {
                relatedLocationIds: map(elasticInvolvedLocations, 'id'),
                relatedOrganizationIds: map(elasticOrganizations, 'id'),
                relatedPersonIds: map(elasticPersons, 'id'),
                relatedPropertyIds: map(elasticProperty, 'id'),
                relatedReportIds: map(elasticReports, 'id'),
                relatedVehicleIds: map(elasticVehicles, 'id'),
                relatedWarrantIds: map(elasticWarrants, 'id'),
            };
        default:
            return state;
    }
}

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { createModalSelector } from '../../../core/box/state/ui';

import Modal from '../../../../legacy-redux/components/core/Modal';
import { submitDeleteCaseModal } from '../state/ui';

const context = { name: boxEnum.DELETE_CASE_MODAL };
const strings = componentStrings.cases.core.DeleteCaseModal;

export default compose(
    withRouter,
    connect(
        createStructuredSelector({
            canDeleteCase: createModalSelector(context, 'canDeleteCase'),
        }),
        (dispatch, props) => ({
            submitDeleteCase: () => dispatch(submitDeleteCaseModal({ router: props.router })),
        })
    )
)(function DeleteCaseModal({ canDeleteCase, submitDeleteCase }) {
    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();

    return (
        <Modal
            context={context}
            title={strings.title(caseFieldName)}
            onSave={submitDeleteCase}
            saveDisabled={!canDeleteCase}
        >
            {canDeleteCase
                ? strings.confirmationText(caseFieldName)
                : strings.cannotDeleteText(caseFieldName)}
        </Modal>
    );
});

import React from 'react';
import { values } from 'lodash';

import statePlaneZoneEnum from '~/client-common/core/enums/universal/statePlaneZoneEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Select from './Select';

const { label } = componentStrings.forms.select.StatePlaneZoneSelect;
const options = values(statePlaneZoneEnum);

function StatePlaneZoneSelect({ ...otherProps }) {
    return <Select label={label} options={options} {...otherProps} />;
}

export default StatePlaneZoneSelect;

import { createSelector } from 'reselect';
import { compact, concat, isArray } from 'lodash';

import { AttributeTypeEnumType } from '@mark43/rms-api';

import { AttributeViewModel } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

// helpers
import {
    attributesByTypeSelector,
    attributeTypeHasActiveAttributesSelector,
} from '~/client-common/core/domain/attributes/state/data';
import {
    attributesOptionsByTypeForParentAttrIdIsEmptySelector,
    attributeOptionsByTypeSelector,
} from '~/client-common/core/domain/attributes/state/ui';

import { currentUserDepartmentIdSelector } from '../../../current-user/state/ui';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import { AttributeLoadingState } from '../../utils/types';

import { uiReducer } from './reducer';

const attributesDepartmentIdFilter = (
    departmentIdToFilterBy: number | undefined,
    predicate?: (attribute: AttributeViewModel) => boolean
) => (props: AttributeViewModel & { departmentId?: number }) => {
    const { deptId, departmentId } = props;
    // filter out attributes which do not belong to the current
    // department. Global attributes have no department id set
    // but must be included in this list.
    //
    // We check `deptId` and `departmentId` because attributes
    // loaded on bootstrap are instances of `AttributeView`
    // instead of `Attribute` for performance reasons regarding IE
    const attributeIsInCurrentDepartment =
        deptId === departmentIdToFilterBy ||
        departmentId === departmentIdToFilterBy ||
        (!deptId && !departmentId);

    if (!attributeIsInCurrentDepartment || !predicate) {
        return attributeIsInCurrentDepartment;
    } else {
        return predicate(props);
    }
};

export const attributesByTypeForCurrentDepartmentSelector = createSelector(
    currentUserDepartmentIdSelector,
    attributesByTypeSelector,
    (currentUserDepartmentId, attributesByType) => (type: AttributeTypeEnumType) =>
        attributesByType(type, attributesDepartmentIdFilter(currentUserDepartmentId))
);

export const attributeTypeHasActiveAttributesForCurrentDepartmentSelector = createSelector(
    currentUserDepartmentIdSelector,
    attributeTypeHasActiveAttributesSelector,
    (currentUserDepartmentId, attributeTypeHasActiveAttributes) => (type: AttributeTypeEnumType) =>
        attributeTypeHasActiveAttributes(
            type,
            attributesDepartmentIdFilter(currentUserDepartmentId)
        )
);

export const attributeOptionsByTypeForCurrentDepartmentSelector = createSelector(
    currentUserDepartmentIdSelector,
    attributeOptionsByTypeSelector,
    (currentUserDepartmentId, attributeOptionsByType) => ({
        type,
        includeExpired = false,
        additionalIds = [],
        includeAbbr,
        predicate,
        sortByIteratees,
    }: {
        type: AttributeTypeEnumType;
        includeExpired: boolean;
        additionalIds: number[];
        includeAbbr?: boolean;
        predicate?: (attribute: AttributeViewModel & { departmentId?: number }) => boolean;
        sortByIteratees?: (keyof AttributeViewModel)[];
    }) => {
        additionalIds = concat([], additionalIds); // convert to array
        return attributeOptionsByType({
            type,
            includeExpired,
            additionalIds,
            includeAbbr,
            predicate: attributesDepartmentIdFilter(currentUserDepartmentId, predicate),
            sortByIteratees,
        });
    }
);

export const attributesOptionsByTypeForParentAttrIdIsEmptyForCurrentDepartmentSelector = createSelector(
    currentUserDepartmentIdSelector,
    attributesOptionsByTypeForParentAttrIdIsEmptySelector,
    (currentUserDepartmentId, attributesOptionsByTypeForParentAttrIdIsEmpty) => (
        attributeType: AttributeTypeEnumType,
        parentAttributeId: number
    ) =>
        attributesOptionsByTypeForParentAttrIdIsEmpty(
            attributeType,
            parentAttributeId,
            attributesDepartmentIdFilter(currentUserDepartmentId)
        )
);

export const attributeLoadingStateSelectorFactory = () =>
    createSelector(
        (state: RootState) => state.ui.attributeLoadingState,
        (
            state: RootState,
            props: { attributeType: AttributeTypeEnumType | AttributeTypeEnumType[] }
        ) => props.attributeType,
        (attributeLoadingState, attributeType) => {
            const accumulator: Partial<AttributeLoadingState> = {};
            return (Array.isArray(attributeType) ? attributeType : [attributeType]).reduce(
                (acc, type) => {
                    acc[type] = attributeLoadingState[type] || {
                        loaded: false,
                        loading: false,
                        error: false,
                    };
                    return acc;
                },
                accumulator
            );
        }
    );

export const attributeLoadingStateByAttributeTypeSelector = createSelector(
    (state: RootState) => state.ui.attributeLoadingState,
    (attributeLoadingState: AttributeLoadingState) => (
        attributeType: AttributeTypeEnumType | AttributeTypeEnumType[]
    ) => {
        const attributeTypes = compact(isArray(attributeType) ? attributeType : [attributeType]);
        return attributeTypes.reduce<Partial<AttributeLoadingState>>((acc, type) => {
            acc[type] = attributeLoadingState[type] || {
                loaded: false,
                loading: false,
                error: false,
            };
            return acc;
        }, {});
    }
);

export default uiReducer;

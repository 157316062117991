let resource;
export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Sealing Resource',
        methods: {
            sealReport(sealingRequest) {
                return req({
                    method: 'POST',
                    url: 'reporting/sealing/seal',
                    data: sealingRequest,
                });
            },
            unsealReport(unsealingRequest) {
                return req({
                    method: 'POST',
                    url: 'reporting/sealing/unseal',
                    data: unsealingRequest,
                });
            },
            updateSealedReport(sealingRequest) {
                return req({
                    method: 'PUT',
                    url: 'reporting/sealing/seal',
                    data: sealingRequest,
                });
            },
            loadSealedReportView(reportId) {
                return req({
                    method: 'GET',
                    url: `reporting/sealing/report/${reportId}`,
                });
            },
            loadSealedReportRelatedEntities(reportId) {
                return req({
                    method: 'GET',
                    url: `reporting/sealing/report/${reportId}/related_entities`,
                });
            },
            loadCourtOrders(reportId) {
                return req({
                    method: 'GET',
                    url: `reporting/sealing/court_orders/report/${reportId}`,
                });
            },
        },
    });
}

/**
 * Resource for sealing. We need to build on app boot and use the getter
 * below to prevent a cirular dependency issue
 * @type {function}
 */
/* eslint-disable-next-line import/no-anonymous-default-export */
export default () => resource;

import styled from 'styled-components';

// default styles
const MapContainer = styled.div`
    width: 250px;
    height: 250px;
    position: absolute;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    top: 0;
    right: 0;
    border: ${(props) => props.theme.colors.darkGrey} 1px solid;
`;

export default MapContainer;

import React, { useCallback } from 'react';
import { compact } from 'lodash';
import { FilterMenuRenderProps, FilterMenuList } from 'arc';
import { mapWithinLastPeriodsToOptions } from '~/client-common/core/dates/utils/dateRangeHelpers';
import dateRangeTypeEnum from '~/client-common/core/enums/client/dateRangeTypeEnum';

export const DateRangeFilter: React.FC<
    FilterMenuRenderProps & {
        setShowDatePickerModal: (val: boolean) => void;
        periodOptions: string[];
        includeNoFilterOption?: boolean;
        includeCustomFilterOption?: boolean;
    }
> = ({
    setAppliedFilters,
    appliedOptions,
    setShowDatePickerModal,
    periodOptions,
    includeNoFilterOption,
    includeCustomFilterOption,
}) => {
    const onSelect = useCallback(
        (item) => {
            setAppliedFilters(item);
            if (item.value === dateRangeTypeEnum.CUSTOM_RANGE) {
                setShowDatePickerModal(true);
                return;
            }
        },
        [setShowDatePickerModal, setAppliedFilters]
    );

    const options = compact([
        includeNoFilterOption
            ? {
                  display: 'All Time',
                  value: dateRangeTypeEnum.NO_FILTER,
              }
            : undefined,
        ...mapWithinLastPeriodsToOptions(periodOptions),
        includeCustomFilterOption
            ? {
                  display: 'Custom Range',
                  value: dateRangeTypeEnum.CUSTOM_RANGE,
              }
            : undefined,
    ]).map((item, index) => {
        return {
            ...item,
            label: item.display,
            id: index,
        };
    });

    return (
        <FilterMenuList
            options={options}
            appliedOptions={appliedOptions}
            onSelect={onSelect}
            selectionType="single"
        />
    );
};

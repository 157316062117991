import { LabelPrinter } from '@mark43/evidence-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'labelPrinters';

const labelPrintersModule = createNormalizedModule<LabelPrinter>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const labelPrintersSelector = labelPrintersModule.selectors.entitiesSelector;

// REDUCER
export default labelPrintersModule.reducerConfig;

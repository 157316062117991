import { intersection, map } from 'lodash';
import { RefContextEnum } from '@mark43/rms-api';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import { useFormGetter } from '../forms/hooks/useFormGetter';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

export const getVictimIsSuspect = (getState: () => RootState) => (offenseId?: number) => {
    const state = getState();

    if (!checkIfDepartmentIsNibrs(currentUserDepartmentProfileSelector(state))) {
        return false;
    }

    const { getForm } = useFormGetter();

    const form = getForm(RefContextEnum.FORM_OFFENSE.name, offenseId);

    if (typeof form === 'undefined') {
        return false;
    }

    // @ts-expect-error remove this when offenseForm.js becomes typed
    const { victims, suspects } = form.getState().model.links || {};
    return intersection(map(victims, 'nameId'), map(suspects, 'nameId')).length > 0;
};

import React from 'react';
import Link from '../../../../../modules/core/components/links/Link';
import { CourtOrderCellProps } from './CourtOrderCellProps';

export default function ReportEventNumberCell({
    reportingEventNumber,
    reportId,
}: CourtOrderCellProps) {
    return (
        <Link hoverOnUnderline={true} to={`reports/${reportId}`}>
            {reportingEventNumber}
        </Link>
    );
}

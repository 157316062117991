import { GpsRetentionPolicyDto } from '@mark43/gps-api';
import { NEXUS_STATE_PROP as GPS_RETENTION_NEXUS_STATE_PROP } from '~/client-common/core/domain/cad-gps-retention/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { RmsAction, RmsDispatch } from '../../../core/typings/redux';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { currentUserHasAbilitySelector } from '../../core/current-user/state/ui';
import redirectInsufficientPermissions from '../../core/utils/redirectInsufficientPermissions';
import * as actionTypes from './action-types';
import { gpsRetentionResource } from './resource';
import { prefillGpsRetentionAdminForm } from './state/ui';

function loadGpsRetentionInfoStart() {
    return {
        type: actionTypes.LOAD_GPS_RETENTION_INFO_START,
    } as const;
}

function loadGpsRetentionInfoSuccess(gpsRetentionInfos: GpsRetentionPolicyDto[]) {
    return {
        type: actionTypes.LOAD_GPS_RETENTION_INFO_SUCCESS,
        payload: gpsRetentionInfos,
    } as const;
}

function loadGpsRetentionInfoFailure(err: Error) {
    return {
        type: actionTypes.LOAD_GPS_RETENTION_INFO_FAILURE,
        payload: err,
        error: true,
    } as const;
}

function saveGpsRetentionPolicyStart() {
    return {
        type: actionTypes.SAVE_GPS_RETENTION_POLICY_START,
    } as const;
}

function saveGpsRetentionPolicyFailure(errorMessage: string) {
    return {
        type: actionTypes.SAVE_GPS_RETENTION_POLICY_FAILURE,
        payload: { errorMessage },
    } as const;
}

const loadGpsRetentionInfo = (): RmsAction<Promise<void>> => {
    return (dispatch, getState, { nexus }) => {
        dispatch(loadGpsRetentionInfoStart());

        return gpsRetentionResource
            .getDepartmentInfo()
            .then((gpsRetentionInfo) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [GPS_RETENTION_NEXUS_STATE_PROP]:
                                gpsRetentionInfo.gpsRetentionPolicyDtos,
                        },
                        loadGpsRetentionInfoSuccess(gpsRetentionInfo.gpsRetentionPolicyDtos)
                    )
                );

                if (gpsRetentionInfo.gpsRetentionPolicyDtos?.length) {
                    dispatch(
                        prefillGpsRetentionAdminForm(gpsRetentionInfo.gpsRetentionPolicyDtos[0])
                    );
                }
            })
            .catch((err) => {
                dispatch(loadGpsRetentionInfoFailure(err));
                throw err;
            });
    };
};

export function saveGpsRetentionPolicy(
    gpsRetentionPolicyDto: GpsRetentionPolicyDto
): RmsAction<Promise<GpsRetentionPolicyDto>> {
    return (dispatch) => {
        dispatch(saveGpsRetentionPolicyStart());

        return gpsRetentionResource
            .saveGpsRetentionPolicy({
                gpsRetentionPolicyDto,
            })
            .then((result) => {
                return result.gpsRetentionPolicyDto;
            })
            .catch((error: Error) => {
                dispatch(saveGpsRetentionPolicyFailure(error.message));
                throw error;
            });
    };
}

export type GpsRetentionAdminActions =
    | ReturnType<typeof loadGpsRetentionInfoStart>
    | ReturnType<typeof loadGpsRetentionInfoSuccess>
    | ReturnType<typeof loadGpsRetentionInfoFailure>;

export function onEnter(this: { dispatch: RmsDispatch; getState: () => RootState }) {
    const hasPermissions = currentUserHasAbilitySelector(this.getState())(
        abilitiesEnum.CAD.GPS_RETENTION_ADMIN
    );

    if (hasPermissions) {
        this.dispatch(loadGpsRetentionInfo());
    } else {
        this.dispatch(redirectInsufficientPermissions());
    }
}

import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { FolderUpdateRequest } from '@mark43/rms-api';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import formsRegistry from '../../../../core/formsRegistry';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import Modal from '../../../core/overlays/components/Modal';
import { updateFolder } from '../state/data/folders';

import { CurrentFolderIdContext, SelectedRowsContext } from '../contexts';
import UpdateFolderForm from './UpdateFolderForm';

const overlayId = overlayIdEnum.UPDATE_FOLDER_MODAL;
const strings = componentStrings.cases.core.UpdateFolderModal;

const UpdateFolderModal: React.FC = () => {
    const overlayStore = useOverlayStore();
    const dispatch = useDispatch();

    const { setSelectedRows } = useContext(SelectedRowsContext);

    const { currentFolderId } = useContext(CurrentFolderIdContext);

    const handleCancel = useCallback(() => {
        return overlayStore.close(overlayId);
    }, [overlayStore]);

    const handleSave = useCallback(async () => {
        const form = formsRegistry.get(formClientEnum.FORM_UPDATE_FOLDER);
        const folderName = form?.getState().model.name;

        if (!folderName) {
            throw new Error('Folder name cannot be empty');
        }

        // The initial value of currentFolderId is undefined.
        // This case should probably never happen but let's check for
        // validity.
        if (!currentFolderId) {
            throw new Error('Please select a folder to update');
        }

        const folderData: FolderUpdateRequest = {
            id: currentFolderId,
            name: folderName.toString(),
        };

        await dispatch(updateFolder(folderData));
        if (setSelectedRows) {
            setSelectedRows([]);
        }
    }, [currentFolderId, dispatch, setSelectedRows]);

    return (
        <Modal
            id={overlayId}
            title={strings.title}
            okText={strings.okText}
            cancelText={strings.cancelText}
            onSave={handleSave}
            onCancel={handleCancel}
        >
            <UpdateFolderForm />
        </Modal>
    );
};

export default UpdateFolderModal;

import React from 'react';
import { EntityTypeEnum } from '@mark43/rms-api';
import { ENTITIES } from 'nexus-mark43';
import { chain } from 'lodash';

import { SAVE_PERSON_PROFILE_SUCCESS } from '~/client-common/core/domain/person-profiles/state/data';
import { SAVE_ORGANIZATION_PROFILE_SUCCESS } from '~/client-common/core/domain/organization-profiles/state/data';
import {
    DELETE_HYDRATED_ITEM_SUCCESS,
    SAVE_HYDRATED_ITEM_SUCCESS,
} from '~/client-common/core/domain/item-profiles/state/data';
import { DELETE_NAME_REPORT_LINK_SUCCESS } from '~/client-common/core/domain/name-report-links/state/data';
import {
    REPLACE_ARREST_CARD_BUNDLE_SUCCESS,
    UPSERT_TOW_VEHICLE_RELEASE_CARD_BUNDLE_ACTION,
} from '~/client-common/core/domain/reports/state/ui';
import { UPDATE_ARREST_DEFENDANT_ID } from '~/client-common/core/domain/arrests/state/data';
import { RelationshipsPrefillContextProvider } from '../../../../core/context/RelationshipsPrefillContext';
import { createSideEffect } from '../../../../../core/side-effects/createSideEffect';
import { registerSideEffect } from '../../../../../core/side-effects/sideEffectsRegistry';
import { refreshRelationshipsForm } from '../../state/forms/relationshipsForm';
import { CREATE_UNKNOWN_PERSON_SUCCESS } from '../../../../core/persons/state/data';
import { DRAGON_CORE_ENTITY_UPDATE } from '../../../../dragon/components/rms/state';
import { RelationshipsCardSummary } from './RelationshipsCardSummary';
import { RelationshipsCardForm } from './RelationshipsCardForm';

export const RelationshipsCardContent = ({ summaryMode, involvedNameViewModels }) => {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const onSelectTab = (key) => setSelectedTab(key);

    React.useEffect(() => {
        return registerSideEffect(
            createSideEffect({
                constraint: [
                    // Triggered when adding a NRL
                    SAVE_PERSON_PROFILE_SUCCESS,
                    SAVE_ORGANIZATION_PROFILE_SUCCESS,
                    CREATE_UNKNOWN_PERSON_SUCCESS,

                    // Trigger when updating defendant
                    UPDATE_ARREST_DEFENDANT_ID,

                    // Triggered when removing a NRL
                    DELETE_NAME_REPORT_LINK_SUCCESS,

                    // Triggered when dragon removes entities and potentially their children,
                    // which _might_ include name report links.
                    DRAGON_CORE_ENTITY_UPDATE,

                    // Triggered when either removing/adding a NRL
                    SAVE_HYDRATED_ITEM_SUCCESS,
                    DELETE_HYDRATED_ITEM_SUCCESS,
                    REPLACE_ARREST_CARD_BUNDLE_SUCCESS,
                    UPSERT_TOW_VEHICLE_RELEASE_CARD_BUNDLE_ACTION,
                ],
                callback: ({ store, action }) => {
                    // `DRAGON_CORE_ENTITY_UPDATE` gets dispatched whenever dragon updates entities, so it might not always contain
                    // name report link changes. In case it does not, we simply return early.
                    if (
                        action.type === DRAGON_CORE_ENTITY_UPDATE &&
                        !action.meta?.[ENTITIES]?.nameReportLinks
                    ) {
                        return;
                    }
                    store.dispatch(refreshRelationshipsForm());
                },
            })
        );
    });

    const personProfileIds = React.useMemo(
        () =>
            chain(involvedNameViewModels)
                .filter({
                    nameEntityType: EntityTypeEnum.PERSON_PROFILE.name,
                })
                .map('masterPersonId')
                .value(),
        [involvedNameViewModels]
    );

    return summaryMode ? (
        <RelationshipsCardSummary
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
            involvedNameViewModels={involvedNameViewModels}
        />
    ) : (
        <RelationshipsPrefillContextProvider personProfileIds={personProfileIds}>
            <RelationshipsCardForm
                selectedTab={selectedTab}
                onSelectTab={onSelectTab}
                involvedNameViewModels={involvedNameViewModels}
                personProfileIds={personProfileIds}
            />
        </RelationshipsPrefillContextProvider>
    );
};

import { map, filter } from 'lodash';
import batchOperationResource from '../../../../core/forms/resources/batchOperationResource';

export const GRANT_PERMISSIONS_START = 'bulk-entity-permissions/GRANT_PERMISSIONS_START';
export const GRANT_PERMISSIONS_SUCCESS = 'bulk-entity-permissions/GRANT_PERMISSIONS_SUCCESS';
export const GRANT_PERMISSIONS_FAILURE = 'bulk-entity-permissions/GRANT_PERMISSIONS_FAILURE';

export const GET_PERMISSION_STATUSES_START =
    'bulk-entity-permissions/GET_PERMISSION_STATUSES_START';
export const GET_PERMISSION_STATUSES_SUCCESS =
    'bulk-entity-permissions/GET_PERMISSION_STATUSES_SUCCESS';
export const GET_PERMISSION_STATUSES_FAILURE =
    'bulk-entity-permissions/GET_PERMISSION_STATUSES_FAILURE';

function grantPermissionsStart() {
    return {
        type: GRANT_PERMISSIONS_START,
    };
}

function grantPermissionsSuccess(bulkEntityPermissionRequests) {
    return {
        type: GRANT_PERMISSIONS_SUCCESS,
        payload: bulkEntityPermissionRequests,
    };
}

function grantPermissionsFailure(errMsg) {
    return {
        type: GRANT_PERMISSIONS_FAILURE,
        payload: errMsg,
    };
}

function getPermissionStatusesStart() {
    return {
        type: GET_PERMISSION_STATUSES_START,
    };
}

function getPermissionStatusesSuccess(statuses) {
    return {
        type: GET_PERMISSION_STATUSES_SUCCESS,
        payload: statuses,
    };
}

function getPermissionStatusesFailure(errMsg) {
    return {
        type: GET_PERMISSION_STATUSES_FAILURE,
        payload: errMsg,
    };
}

export function grantPermissions({
    entityDepartmentId,
    permissionDepartmentId,
    batchEntityPermissionRequests,
}) {
    const batchEntityPermissionGrantRequests = map(
        filter(batchEntityPermissionRequests, (request) => request.operationType),
        (request) => {
            return {
                entityDepartmentId,
                permissionDepartmentId,
                ...request,
            };
        }
    );

    return (dispatch) => {
        dispatch(grantPermissionsStart());
        return batchOperationResource
            .batchGrantEntityPermissions(batchEntityPermissionGrantRequests)
            .then(() => dispatch(grantPermissionsSuccess(batchEntityPermissionGrantRequests)))
            .catch((err) => dispatch(grantPermissionsFailure(err.message)));
    };
}

export function getPermissionStatuses() {
    return (dispatch) => {
        dispatch(getPermissionStatusesStart());
        return batchOperationResource
            .getBatchOperationStatusesForUser(true)
            .then((data) => dispatch(getPermissionStatusesSuccess(data)))
            .catch((err) => dispatch(getPermissionStatusesFailure(err.message)));
    };
}

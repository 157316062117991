import { TrafficCrashCustomAttribute } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'trafficCrashCustomAttributes';
const trafficCrashCustomAttributeModule = createNormalizedModule<TrafficCrashCustomAttribute>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS
export const trafficCrashCustomAttributesSelector =
    trafficCrashCustomAttributeModule.selectors.entitiesSelector;

// REDUCER
export default trafficCrashCustomAttributeModule.reducerConfig;

import { Impound } from '@mark43/rms-api';
import { first } from 'lodash';
import { createSelector } from 'reselect';

// helpers
import createNormalizedModule from '../../../../utils/createNormalizedModule';

// resources
import getImpoundResource from '../../resources/impoundsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const UPDATE_IMPOUND_START = 'impounds/UPDATE_IMPOUND_START';
const UPDATE_IMPOUND_SUCCESS = 'impounds/UPDATE_IMPOUND_SUCCESS';
const UPDATE_IMPOUND_FAILURE = 'impounds/UPDATE_IMPOUND_FAILURE';

function updateImpoundStart(impound: Impound) {
    return {
        type: UPDATE_IMPOUND_START,
        payload: impound,
    };
}

function updateImpoundSuccess(impound: Impound) {
    return {
        type: UPDATE_IMPOUND_SUCCESS,
        payload: impound,
    };
}

function updateImpoundFailure(message: string) {
    return {
        type: UPDATE_IMPOUND_FAILURE,
        payload: message,
    };
}

// MODULE
export const NEXUS_STATE_PROP = 'impounds';

const impoundsModule = createNormalizedModule<Impound>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// ACTIONS
const storeImpounds = impoundsModule.actionCreators.storeEntities;

export function updateImpound(impound: Impound): ClientCommonAction<Promise<Impound>> {
    return (dispatch) => {
        const resource = getImpoundResource();
        dispatch(updateImpoundStart(impound));
        return resource
            .updateImpound(impound)
            .then((impound: Impound) => {
                dispatch(storeImpounds(impound));
                dispatch(updateImpoundSuccess(impound));
                return impound;
            })
            .catch((err: Error) => {
                dispatch(updateImpoundFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const impoundsSelector = impoundsModule.selectors.entitiesSelector;
const impoundsWhereSelector = impoundsModule.selectors.entitiesWhereSelector;

// Only 1 `Impound` per report
export const impoundByReportIdSelector = createSelector(
    impoundsWhereSelector,
    (impoundsWhere) => (reportId: number) => first(impoundsWhere({ reportId }))
);

// REDUCER
export default impoundsModule.reducerConfig;

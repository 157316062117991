const BASE_URL = '/cad/api';
const PATH = 'duplicate_event_search_config';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Duplicate Event Search Config Resource',
        methods: {
            getDuplicateEventSearchConfig() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: PATH,
                });
            },
            upsertDuplicateEventSearchConfig(duplicateEventSearchConfig) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: PATH,
                    data: duplicateEventSearchConfig,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual, uniqWith } from 'lodash';
import { atfManufacturersSelector } from '~/client-common/core/domain/etrace-atf/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { getDropdownOptionsFromStrings } from '~/client-common/helpers/dropdownOptionHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import AsyncSelect from '../../../../../core/forms/components/selects/AsyncSelect';
import { LegacySelectOption } from '../../../../../core/forms/helpers/selectHelpers';
import atfManufacturerResource from '../../../resources/atfManufacturerResource';
import { arbiterMFTInput } from '../../../../../core/arbiter';
import { SelectProps } from '../../../../../core/forms/components/selects/Select';
import { logError } from '../../../../../../core/logging';

const atfManufacturerMustBeSelectedErrorMessage = componentStrings.reports.core.ItemSidePanel.atfManufacturerMustBeSelectedErrorMessage;

type PropsT = {
    atfManufacturerId: number;
} & SelectProps<LegacySelectOption, false>;

const AtfModelSelect: React.FC<PropsT > = ({ atfManufacturerId, ...props }) => {
    const [atfModelOptions, setAtfModelOptions] = useState<
        LegacySelectOption[]
    >([]);

    const atfManufacturers = useSelector(atfManufacturersSelector);
    const selectedAtfManufacturer = atfManufacturers[atfManufacturerId];

    const searchForAtfModelsSuccess = useCallback(
        (atfModels: string[]) => {
            const options = getDropdownOptionsFromStrings(atfModels);
            const selectedOptionArray = props.value ? getDropdownOptionsFromStrings([props.value.toString()]) : [];
            setAtfModelOptions(uniqWith(options.concat(selectedOptionArray), isEqual));
    }, [props.value]);

    const searchForAtfModels = useCallback((query?: string) => {
        const {code, countryAbbreviation} = selectedAtfManufacturer;
        return atfManufacturerResource.searchForAtfModels({
            query: {
                aftModel: query,
                atfManufacturerCode: code,
                countryAbbreviation,
            },
            from: 0,
            size: 20,
            sorts: [],
        });
    }, [selectedAtfManufacturer]);

    const { callResource: fetchAtfModels } = useResourceDeferred(
        searchForAtfModels,
        searchForAtfModelsSuccess
    );

    const fetchAtfModelsWhenAtfManufacturerSelected = useCallback((query?: string) => {
        if (!selectedAtfManufacturer) {
            logError(atfManufacturerMustBeSelectedErrorMessage);
            return Promise.resolve();
        }
        return fetchAtfModels(query);
    }, [fetchAtfModels, selectedAtfManufacturer]);
    
    useEffect(() => {
        if (!props.value) {
            fetchAtfModelsWhenAtfManufacturerSelected(undefined);
        }
    }, [fetchAtfModelsWhenAtfManufacturerSelected, props.value]);
    
    useEffect(() => {
        const selectedOptionArray = props.value ? getDropdownOptionsFromStrings([props.value.toString()]) : [];
        setAtfModelOptions((prevOptions) => uniqWith(prevOptions.concat(selectedOptionArray), isEqual));    
    }, [props.value]);

    return <AsyncSelect asyncAction={fetchAtfModelsWhenAtfManufacturerSelected} options={atfModelOptions} {...props} />;
};

export const ArbiterAtfModelSelect = arbiterMFTInput(AtfModelSelect);

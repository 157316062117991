import _, { map } from 'lodash';
import { createSelector } from 'reselect';

// helpers
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { dateTimeRangeFormatter } from '../../../../dates/utils/dateHelpers';
import { formatFullName } from '../../../mini-users/utils/miniUsersHelpers';

// selectors
import { cadTicketsWhereSelector } from '../data';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { currentDepartmentDateFormatterSelector } from '../../../current-user/state/ui';

export const primaryResponderFormatter = function (unit, formatUserById) {
    if (unit.memberId) {
        return formatUserById(unit.memberId);
    }
    return formatFullName({ first: unit.memberName, badge: unit.memberIdentifier });
};

const responderMembersFormatter = function (units, formatUserById) {
    if (units) {
        return _(units)
            .filter((unit) => unit.isPrimaryOnEvent)
            .map((unit) => primaryResponderFormatter(unit, formatUserById))
            .join(', ');
    }
};

const responderUnitsFormatter = function (units) {
    if (units) {
        return _(units)
            .filter((unit) => unit.isPrimaryOnEvent)
            .map((unit) => unit.unitCallSign)
            .sort()
            .uniq()
            .join(', ');
    }
};

const getCadTicketsViewModelWhere = function (
    formatUserById,
    formatAttributeById,
    cadTicketsWhere,
    dateTimeFormatter
) {
    return (reportingEventNumber, cadAgencyEventNumber) => {
        const filteredCadTickets = cadAgencyEventNumber
            ? cadTicketsWhere(
                  (cadTicket) => cadTicket.cadAgencyEventNumber === cadAgencyEventNumber
              )
            : cadTicketsWhere((cadTicket) =>
                  _.includes(cadTicket.reportingEventNumbers, reportingEventNumber)
              );

        const cadTickets = map(filteredCadTickets, (cadTicket) => {
            return {
                ...cadTicket,
            };
        });
        const viewModelBuilder = buildViewModel({
            mappers: [
                allSingleAttributeValuesMapper,
                ({ cadAgencyEventNumber }) => ({ cadAgencyEventNumber }),
                ({ eventStartDateUtc, eventClosedDateUtc }) => ({
                    eventDate: dateTimeRangeFormatter(
                        eventStartDateUtc,
                        eventClosedDateUtc,
                        dateTimeFormatter
                    ),
                }),
                ({ fullAddress }) => ({ fullAddress }),
                ({ secondaryEventTypeAttrId, secondaryEventType }) => ({
                    callForService:
                        formatAttributeById(secondaryEventTypeAttrId) || secondaryEventType,
                }),
                ({ unitsAndMembers }) => ({
                    // these are the primary units and primary officers
                    responderUnitsCommaSeparated: responderUnitsFormatter(unitsAndMembers),
                    responderMembersCommaSeparated: responderMembersFormatter(
                        unitsAndMembers,
                        formatUserById
                    ),
                }),
                ({ callerPhoneNumber }) => ({ callerPhoneNumber }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        const mappedValues = map(cadTickets, viewModelBuilder);
        return mappedValues;
    };
};

export const cadTicketsViewModelWhereSelector = createSelector(
    formatMiniUserByIdSelector,
    formatAttributeByIdSelector,
    cadTicketsWhereSelector,
    currentDepartmentDateFormatterSelector,
    getCadTicketsViewModelWhere
);

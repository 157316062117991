import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import {
    NAME_NAME_LINK_DATE_EFFECTIVE_FROM,
    NAME_NAME_LINK_DATE_EFFECTIVE_TO,
} from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import InvolvedNamesCardTabs from '../../../../../legacy-redux/components/reports/InvolvedNamesCardTabs';
import { NoResults } from '../../../../core/components/NoResults';

const RowsContainer = styled.div`
    margin-bottom: 10px;
`;

const strings = componentStrings.reports.InvolvedNamesCard;

export function RelationshipsCardSummary({ involvedNameViewModels, selectedTab, onSelectTab }) {
    const fieldDisplayNames = useFields([
        NAME_NAME_LINK_DATE_EFFECTIVE_FROM,
        NAME_NAME_LINK_DATE_EFFECTIVE_TO,
    ]);
    return (
        <InvolvedNamesCardTabs
            selectedTab={selectedTab}
            onSelectTab={(key) => onSelectTab(key)}
            viewModels={involvedNameViewModels}
        >
            {(involvedNameViewModel) => {
                const rows = map(
                    involvedNameViewModel.display.nameNameLinks,
                    ({ label, content, dateEffectiveFrom, dateEffectiveTo }, index) => (
                        <RowsContainer key={index}>
                            <SummaryRow label={label}>{content}</SummaryRow>
                            <FeatureFlagged flag="RMS_RELATIONSHIP_PREFILL_ENABLED">
                                {dateEffectiveFrom && (
                                    <SummaryRow
                                        label={fieldDisplayNames.NAME_NAME_LINK_DATE_EFFECTIVE_FROM}
                                    >
                                        {dateEffectiveFrom}
                                    </SummaryRow>
                                )}
                            </FeatureFlagged>
                            <FeatureFlagged flag="RMS_RELATIONSHIP_PREFILL_ENABLED">
                                {dateEffectiveTo && (
                                    <SummaryRow
                                        label={fieldDisplayNames.NAME_NAME_LINK_DATE_EFFECTIVE_TO}
                                    >
                                        {dateEffectiveTo}
                                    </SummaryRow>
                                )}
                            </FeatureFlagged>
                        </RowsContainer>
                    )
                );

                const list =
                    rows.length > 0 ? (
                        <SummaryList labelWidth={197} contentWidth={184}>
                            {rows}
                        </SummaryList>
                    ) : (
                        <NoResults marginTop="26px">
                            {strings.relationships.noDataMessage}
                        </NoResults>
                    );

                return <>{list}</>;
            }}
        </InvolvedNamesCardTabs>
    );
}

import { Fieldset, Form, lifecycleOptions } from 'markformythree';
import React from 'react';
import { findIndex, get, isEmpty } from 'lodash';
import { NameReportLink, RefContextEnum } from '@mark43/rms-api';

import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import Row from '../../../../core/components/Row';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import IndentedFields from '../../../../core/components/IndentedFields';
import { ArbiterMFTBooleanButtonRadio } from '../../../../core/forms/components/button-radios/BooleanButtonRadio';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { FormConfigurationRenderer } from '../../../../core/markformythree-arbiter/dynamic-fields/components/FormConfigurationRenderer';
import { CUSTOM_PROPERTIES_KEY_NAME } from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';

interface OffenseCardProps {
    nameLink: NameReportLink;
    summaryMode: boolean;
    offenseFormIndex: number;
}

export const OffenseWitnessAdditionalFields: React.FC<OffenseCardProps> = ({
    nameLink,
    summaryMode,
    offenseFormIndex,
}) => {
    if (summaryMode) {
        return (
            <SummaryList labelWidth={150} contentWidth={350}>
                <SummaryRow
                    fieldName={
                        fields.NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_WAS_SEARCH_PERFORMED
                    }
                >
                    {booleanToYesNo(nameLink.wasSearchPerformed)}
                </SummaryRow>
                {!isEmpty(nameLink.statement) && (
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_WAS_STATEMENT_TAKEN
                        }
                    >
                        {booleanToYesNo(true)}
                    </SummaryRow>
                )}
                <SummaryRow
                    fieldName={fields.NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_STATEMENT}
                >
                    {nameLink.statement}
                </SummaryRow>

                <Form
                    name={RefContextEnum.FORM_OFFENSE.name}
                    index={offenseFormIndex}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    render={(form) => {
                        const witnessData = get(form.getState(), 'model.links.witnesses');
                        const nameLinkIndex = findIndex(witnessData, { nameId: nameLink.nameId });
                        if (nameLinkIndex <= -1) {
                            return null;
                        }
                        return (
                            <FormConfigurationRenderer
                                form={form}
                                absoluteFormPath={`links.witnesses[${nameLinkIndex}].${CUSTOM_PROPERTIES_KEY_NAME}`}
                                mode="SUMMARY"
                            />
                        );
                    }}
                />
            </SummaryList>
        );
    }

    return (
        <Form
            name={RefContextEnum.FORM_OFFENSE.name}
            index={offenseFormIndex}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            render={(form) => {
                const witnessData = get(form.getState(), 'model.links.witnesses');
                const nameLinkIndex = findIndex(witnessData, { nameId: nameLink.nameId });
                return (
                    nameLinkIndex > -1 && (
                        <>
                            <Row>
                                <Fieldset
                                    path={`links.witnesses[${nameLinkIndex}]`}
                                    render={() => (
                                        <>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="wasSearchPerformed" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="wasStatementTaken" />
                                            </Row>
                                            <IndentedFields>
                                                <Row>
                                                    <ArbiterMFTTextArea
                                                        path="statement"
                                                        length="lg"
                                                    />
                                                </Row>
                                            </IndentedFields>
                                        </>
                                    )}
                                />
                            </Row>
                            <FormConfigurationRenderer
                                form={form}
                                absoluteFormPath={`links.witnesses[${nameLinkIndex}].${CUSTOM_PROPERTIES_KEY_NAME}`}
                                mode="FORM"
                            />
                        </>
                    )
                );
            }}
        />
    );
};

import { EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get, noop } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { lifecycleOptions } from 'markformythree';
import {
    NAME_REPORT_LINK_LINK_TYPE_MISSING_PERSON_IN_REPORT_LINK_TYPE,
    NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_LINK_TYPE,
    NAME_REPORT_LINK_LINK_TYPE_LAST_KNOWN_CONTACT_IN_REPORT_LINK_TYPE,
    LOCATION_ENTITY_LINK_LINK_TYPE_LAST_KNOWN_LOCATION_LOCATION_ID,
    LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_WHERE_LOCATED_LOCATION_ID,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import { missingPersonsByReportIdSelector } from '~/client-common/core/domain/missing-persons/state/data';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { createNItemsIdAdder, createNItemsIdRemover } from '../../utils/nItemsHelpers';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    formName,
    LAST_KNOWN_LOCATION_DESCRIPTION_FULL_PATH,
    LAST_KNOWN_LOCATION_POSITION_ATTR_ID_FULL_PATH,
    LOCATION_WHERE_LOCATED_DESCRIPTION_FULL_PATH,
    LOCATION_WHERE_LOCATED_POSITION_ATTR_ID_FULL_PATH,
    getMissingPersonsForm,
    createMissingPersonsForm,
    refreshMissingPersonsForm,
    LAST_KNOWN_CONTACTS_PATH,
    WITNESSES_PATH,
    LAST_KNOWN_LOCATION_LOCATION_ID_FULL_PATH,
    LOCATION_WHERE_LOCATED_LOCATION_ID_FULL_PATH,
} from '../../state/forms/missingPersonsForm';
import { registerForm } from '../../state/ui';
import missingPersonsCard from '../../state/ui/missingPersonsCard';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import withCard from '../../utils/withCard';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import testIds from '../../../../../core/testIds';
import { registerCard } from '../../utils/cardsRegistry';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import MissingPersonsCardForm from './MissingPersonsCardForm';
import MissingPersonsCardSummary from './MissingPersonsCardSummary';


const strings = componentStrings.reports.core.MissingPersonsCard;

class MissingPersonsCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            refreshMissingPersonsForm,
            currentReportId: reportId,
            formatFieldByName,
            arbiter,
        } = this.props;

        const form = createMissingPersonsForm({
            formatFieldByName,
            arbiter,
            initialState: refreshMissingPersonsForm({ reportId }),
        });

        registerForm({ form });
        registerCard({
            cardModule: missingPersonsCard,
            onSave: this.onSave,
        });
    }

    formsRegistryDeferredOperationDisposer = noop;
    eventInfoFormEventStartUtcObserveDisposer = noop;

    componentDidMount() {
        const { currentReportId, eventDetailByReportId } = this.props;
        // initialize `eventStartUtc`
        this.handleEventInfoFormEventStartUtcChange({
            eventStartUtc: get(eventDetailByReportId(currentReportId), 'eventStartUtc'),
        });

        this.formsRegistryDeferredOperationDisposer = formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_EVENT_INFO.name,
            undefined,
            (eventInfoForm) => {
                // observe `eventStartUtc` changes
                const observeEventStartUtcConfig = {
                    subscriptions: {
                        eventStartUtc: 'eventStartUtc',
                    },
                    callback: (data) => this.handleEventInfoFormEventStartUtcChange(data),
                };
                this.eventInfoFormEventStartUtcObserveDisposer = eventInfoForm.observe(
                    observeEventStartUtcConfig
                );
            }
        );
    }

    componentWillUnmount() {
        this.eventInfoFormEventStartUtcObserveDisposer();
        this.formsRegistryDeferredOperationDisposer();
        formsRegistry.unregister(formName);
    }

    handleEventInfoFormEventStartUtcChange({ eventStartUtc }) {
        const form = getMissingPersonsForm();
        form.set('eventStartUtc', eventStartUtc);
    }

    onEdit = () => {
        return this.props.editCallback(() => this.props.onEdit());
    };

    onMissingPersonAdd = (nameReportLink) => {
        const form = getMissingPersonsForm();
        form.set('missingPerson', get(nameReportLink, 'nameId'));
    };

    onMissingPersonRemove = () => {
        const form = getMissingPersonsForm();
        form.set('missingPerson', null);
    };

    onWitnessAdd = (nameReportLink) => {
        return createNItemsIdAdder({
            getForm: getMissingPersonsForm,
            path: 'witnesses',
            id: get(nameReportLink, 'nameId'),
        });
    };

    onWitnessRemove = (nameReportLink) => {
        return createNItemsIdRemover({
            getForm: getMissingPersonsForm,
            path: 'witnesses',
            id: get(nameReportLink, 'nameId'),
        });
    };

    onLastKnownContactAdd = (nameReportLink) => {
        return createNItemsIdAdder({
            getForm: getMissingPersonsForm,
            path: 'lastKnownContacts',
            id: get(nameReportLink, 'nameId'),
        });
    };

    onLastKnownContactRemove = (nameReportLink) => {
        return createNItemsIdRemover({
            getForm: getMissingPersonsForm,
            path: 'lastKnownContacts',
            id: get(nameReportLink, 'nameId'),
        });
    };

    handleLastKnownLocationLocationAdd = (location, modelEntityLink) => {
        const form = getMissingPersonsForm();
        form.set('lastKnownLocation', modelEntityLink);
    };

    handleLastKnownLocationLocationRemove = () => {
        const form = getMissingPersonsForm();
        form.set('lastKnownLocation', undefined);
    };

    handleLocationWhereLocatedLocationAdd = (location, modelEntityLink) => {
        const form = getMissingPersonsForm();
        form.set('locationWhereLocated', modelEntityLink);
    };

    handleLocationWhereLocatedLocationRemove = () => {
        const form = getMissingPersonsForm();
        form.set('locationWhereLocated', undefined);
    };

    onSaveProgress = ({ customEventType, changeToSummaryModeAfterSubmission } = {}) => {
        const form = getMissingPersonsForm();
        return this.props.onSaveProgress(form, {
            customEventType,
            changeToSummaryModeAfterSubmission,
        });
    };

    onSave = () => {
        const form = getMissingPersonsForm();
        return this.props.onSave(form);
    };

    render() {
        const {
            card = {},
            currentReportId: reportId,
            reportById,
            missingPersonsByReportId,
            currentReportCardUITitleByType,
        } = this.props;
        const {
            anchor,
            summaryMode: cardSummaryMode,
            errorMessages,
            canEditReportCardStatus,
            saving,
        } = card;
        const cardTitle = currentReportCardUITitleByType(reportCardEnum.MISSING_PERSONS.id);
        const missingPerson = missingPersonsByReportId(reportId);
        const renForRecents = get(reportById(reportId), 'reportingEventNumber');

        return (
            <Card
                className={anchor}
                anchor={anchor}
                title={cardTitle}
                testId={testIds.MISSING_PERSONS_CARD}
                renderContent={(summaryMode) => {
                    const baseNameSummaryViewWrapperProps = {
                        renForRecents,
                        summaryMode,
                        reportId,
                        show: { people: true },
                    };

                    return (
                        <>
                            {summaryMode ? (
                                <MissingPersonsCardSummary
                                    reportId={reportId}
                                    missingPerson={missingPerson}
                                />
                            ) : (
                                <MissingPersonsCardForm />
                            )}
                            <CardSection
                                testId={testIds.MISSING_PERSONS_PERSON_SECTION}
                                fieldName={
                                    NAME_REPORT_LINK_LINK_TYPE_MISSING_PERSON_IN_REPORT_LINK_TYPE
                                }
                            >
                                <NameSummaryViewWrapper
                                    {...baseNameSummaryViewWrapperProps}
                                    addNameButtonText={strings.missingPersonAddButtonText}
                                    contextType={EntityTypeEnum.MISSING_PERSON.name}
                                    contextId={missingPerson.id}
                                    parentEntityType={EntityTypeEnum.REPORT.name}
                                    parentId={reportId}
                                    linkType={LinkTypesEnum.MISSING_PERSON_IN_REPORT}
                                    limitToOne={true}
                                    onAddSuccess={this.onMissingPersonAdd}
                                    onRemoveSuccess={this.onMissingPersonRemove}
                                />
                            </CardSection>
                            <ArbiterForm
                                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                                name={formName}
                                context={formName}
                                render={() => (
                                    <>
                                        <VisibilityObserver
                                            path={LAST_KNOWN_LOCATION_LOCATION_ID_FULL_PATH}
                                            formName={formName}
                                            render={({ hidden }) =>
                                                !hidden && (
                                                    <CardSection
                                                    testId={
                                                        testIds.MISSING_PERSONS_LAST_KNOWN_LOCATION_SECTION
                                                    }
                                                    fieldName={
                                                        LOCATION_ENTITY_LINK_LINK_TYPE_LAST_KNOWN_LOCATION_LOCATION_ID
                                                    }
                                                >
                                                    <LocationSummaryViewWrapperWithFormFields
                                                        summaryMode={summaryMode}
                                                        onLocationAdd={
                                                            this.handleLastKnownLocationLocationAdd
                                                        }
                                                        onLocationRemove={
                                                            this.handleLastKnownLocationLocationRemove
                                                        }
                                                        entityType={EntityTypeEnum.REPORT.name}
                                                        entityId={reportId}
                                                        linkType={LinkTypesEnum.LAST_KNOWN_LOCATION}
                                                        locationDescriptionPath={() =>
                                                            LAST_KNOWN_LOCATION_DESCRIPTION_FULL_PATH
                                                        }
                                                        locationPositionAttrIdPath={() =>
                                                            LAST_KNOWN_LOCATION_POSITION_ATTR_ID_FULL_PATH
                                                        }
                                                    />
                                                </CardSection>
                                            )}
                                        />

                                        <VisibilityObserver
                                            path={LOCATION_WHERE_LOCATED_LOCATION_ID_FULL_PATH}
                                            formName={formName}
                                            render={({ hidden }) =>
                                                !hidden && (
                                                    <CardSection
                                                        fieldName={
                                                            LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_WHERE_LOCATED_LOCATION_ID
                                                        }
                                                        testId={
                                                            testIds.MISSING_PERSONS_LOCATION_RETURNED_SECTION
                                                        }
                                                    >
                                                        <LocationSummaryViewWrapperWithFormFields
                                                            summaryMode={summaryMode}
                                                            onLocationAdd={
                                                                this.handleLocationWhereLocatedLocationAdd
                                                            }
                                                            onLocationRemove={
                                                                this.handleLocationWhereLocatedLocationRemove
                                                            }
                                                            entityType={EntityTypeEnum.REPORT.name}
                                                            entityId={reportId}
                                                            linkType={LinkTypesEnum.LOCATION_WHERE_LOCATED}
                                                            locationDescriptionPath={() =>
                                                                LOCATION_WHERE_LOCATED_DESCRIPTION_FULL_PATH
                                                            }
                                                            locationPositionAttrIdPath={() =>
                                                                LOCATION_WHERE_LOCATED_POSITION_ATTR_ID_FULL_PATH
                                                            }
                                                        />
                                                 </CardSection>
                                            )}
                                        />
                                    </>
                                )}
                            />

                            <VisibilityObserver
                                path={WITNESSES_PATH}
                                formName={formName}
                                render={({ hidden }) =>
                                    !hidden && (
                                        <CardSection
                                        fieldName={NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_LINK_TYPE}
                                        testId={testIds.MISSING_PERSONS_WITNESS_SECTION}
                                    >
                                        <NameSummaryViewWrapper
                                            {...baseNameSummaryViewWrapperProps}
                                            addNameButtonText={strings.witnessAddButtonText}
                                            contextType={EntityTypeEnum.REPORT.name}
                                            contextId={reportId}
                                            parentEntityType={EntityTypeEnum.REPORT.name}
                                            parentId={reportId}
                                            linkType={LinkTypesEnum.WITNESS_IN_OFFENSE}
                                            onAddSuccess={this.onWitnessAdd}
                                            onRemoveSuccess={this.onWitnessRemove}
                                        />
                                    </CardSection>
                                )}
                            />

                            <VisibilityObserver
                                path={LAST_KNOWN_CONTACTS_PATH}
                                formName={formName}
                                render={({ hidden }) =>
                                    !hidden && (
                                        <CardSection
                                            fieldName={
                                                NAME_REPORT_LINK_LINK_TYPE_LAST_KNOWN_CONTACT_IN_REPORT_LINK_TYPE
                                            }
                                            testId={testIds.MISSING_PERSONS_LAST_KNOWN_CONTACTS_SECTION}
                                        >
                                            <NameSummaryViewWrapper
                                                {...baseNameSummaryViewWrapperProps}
                                                addNameButtonText={strings.lastKnownContactAddButtonText}
                                                contextType={EntityTypeEnum.REPORT.name}
                                                contextId={reportId}
                                                parentEntityType={EntityTypeEnum.REPORT.name}
                                                parentId={reportId}
                                                linkType={LinkTypesEnum.LAST_KNOWN_CONTACT_IN_REPORT}
                                                onAddSuccess={this.onLastKnownContactAdd}
                                                onRemoveSuccess={this.onLastKnownContactRemove}
                                            />
                                        </CardSection>
                                )}
                            />
                        </>
                    );
                }}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={cardSummaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                saving={saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class MissingPersonsCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => (
                    <MissingPersonsCard ref={this.setRef} {...this.props} arbiter={arbiter} />
                )}
            </RMSArbiterProvider>
        );
    }
}

export default compose(
    withCard(missingPersonsCard),
    connect(
        createStructuredSelector({
            formatFieldByName: formatFieldByNameSelector,
            missingPersonsByReportId: missingPersonsByReportIdSelector,
            eventDetailByReportId: eventDetailByReportIdSelector,
            reportById: reportByIdSelector,
            currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
        }),
        { refreshMissingPersonsForm },
        null,
        { forwardRef: true }
    )
)(MissingPersonsCardWrapper);

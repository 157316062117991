import {
    DexMessagesclipsResource,
    ClipsPrefillReportView,
    ClipsPrefillWarrantView,
} from '@mark43/dex-api';
import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Clips Resource',
    methods: {
        /**
         * Submit report information to be used in CLIPS prefill submission
         * @param {ClipsPrefillReportView} clipsReportPrefillData
         * @returns
         */
        submitClipsReport(clipsReportPrefillData: ClipsPrefillReportView) {
            return req<DexMessagesclipsResource.SubmitClipsReport>({
                method: 'POST',
                baseUrl: '/dex/api',
                url: `dex_messages/clips/report`,
                data: clipsReportPrefillData,
            });
        },
        /**
         * Submit warrant information to be use in CLIPS prefill submission
         * @param {ClipsPrefillWarrantView} clipsWarrantPrefillData
         * @returns
         */
        submitClipsWarrant(clipsWarrantPrefillData: ClipsPrefillWarrantView) {
            return req<DexMessagesclipsResource.SubmitClipsWarrants>({
                method: 'POST',
                baseUrl: '/dex/api',
                url: `dex_messages/clips/warrant`,
                data: clipsWarrantPrefillData,
            });
        },
    },
});

import { SystemRuleEnum } from '@mark43/rms-api';
import _, { compact, map, uniq } from 'lodash';
import { statusIsClosedSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import addRuleId from '../../../../../legacy-redux/validation/helpers/addRuleId';
import { isNewCaseSelector } from '../ui';

export const assigneeRequiredIfNoAssignedUnit = addRuleId(
    SystemRuleEnum.CASE_DETAILS_ASSIGNEE_REQUIRED_IF_NO_ASSIGNED_UNIT.name,
    (formData) => {
        const { caseInformation } = formData;
        if (!caseInformation.assignedPersonnelUnitAttrId && !caseInformation.assignee) {
            return false;
        }
        return true;
    }
);

export const assignedUnitRequiredIfNoAssignee = addRuleId(
    SystemRuleEnum.CASE_DETAILS_ASSIGNED_UNIT_REQUIRED_IF_NO_ASSIGNEE.name,
    (formData, value) => {
        const { caseInformation } = formData;
        if (!caseInformation.assignee && !value) {
            return false;
        }
        return true;
    }
);

const noDuplicates = (ids) => {
    // don't validate if there are no duplicates
    if (ids.length === 0) {
        return true;
    }

    const origLength = ids.length;
    const newLength = uniq(ids).length;

    return origLength === newLength;
};

export const noDuplicateCaseRoleLinks = addRuleId(
    SystemRuleEnum.CASE_DETAILS_NO_DUPLICATE_CASE_ROLE_LINKS.name,
    (formData) => {
        const { supervisors, caseInformation } = formData;
        const allRoleIds = compact([caseInformation.assignee, ...map(supervisors, 'roleId')]);
        return noDuplicates(allRoleIds);
    }
);

export const noDuplicateCaseRoleLinksAssigneeAssistingInvestigator = addRuleId(
    SystemRuleEnum.CASE_DETAILS_NO_DUPLICATE_CASE_ROLE_LINKS_ASSIGNEE_ASSISTING_INVESTIGATOR.name,
    (formData) => {
        const { caseInformation } = formData;
        const allRoleIds = compact([
            caseInformation.assignee,
            ...caseInformation.assistingInvestigators,
        ]);
        return noDuplicates(allRoleIds);
    }
);

export const noDuplicateCaseRoleLinksAssistingInvestigatorSupervisor = addRuleId(
    SystemRuleEnum.CASE_DETAILS_NO_DUPLICATE_CASE_ROLE_LINKS_ASSISTING_INVESTIGATOR_SUPERVISOR.name,
    (formData) => {
        const { supervisors, caseInformation } = formData;
        const allRoleIds = compact([
            ...caseInformation.assistingInvestigators,
            ...map(supervisors, 'roleId'),
        ]);
        return noDuplicates(allRoleIds);
    }
);

export const caseStatusClosedByDivisionRequiredIfStatusClosed = addRuleId(
    SystemRuleEnum.CASE_DETAILS_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID_REQUIRED_IF_STATUS_CLOSED
        .name,
    (formData, value, state) => {
        const { caseStatus } = formData;
        if (statusIsClosedSelector(state)(caseStatus.statusAttrId)) {
            return !!value;
        }
        return true;
    }
);

export const caseStatusClosedByUnitRequiredIfStatusClosed = addRuleId(
    SystemRuleEnum.CASE_DETAILS_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID_REQUIRED_IF_STATUS_CLOSED.name,
    (formData, value, state) => {
        const { caseStatus } = formData;
        if (statusIsClosedSelector(state)(caseStatus.statusAttrId)) {
            return !!value;
        }
        return true;
    }
);

export const caseStatusStatusDateRequiredIfStatusClosed = addRuleId(
    SystemRuleEnum.CASE_DETAILS_CASE_STATUS_STATUS_DATE_UTC_REQUIRED_IF_STATUS_CLOSED.name,
    (formData, value, state) => {
        const { caseStatus } = formData;
        if (statusIsClosedSelector(state)(caseStatus.statusAttrId)) {
            return !!value;
        }
        return true;
    }
);

// TODO-VALIDATION-NITEMS would be great to support field level validation for NItems
export const caseDetailsReportCaseStatusClosedByDivisionRequiredIfStatusClosed = addRuleId(
    SystemRuleEnum
        .CASE_DETAILS_REPORT_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID_REQUIRED_IF_STATUS_CLOSED.name,
    (formData, value, state) => {
        const { caseStatus } = formData;
        const { reportCaseStatuses } = caseStatus;

        return _(reportCaseStatuses)
            .compact()
            .every((status) => {
                const isClosed = statusIsClosedSelector(state)(status.caseStatusAttrId);
                return !isClosed || status.closedByDivisionAttrId;
            });
    }
);

// TODO-VALIDATION-NITEMS would be great to support field level validation for NItems
export const caseDetailsReportCaseStatusClosedByUnitRequiredIfStatusClosed = addRuleId(
    SystemRuleEnum.CASE_DETAILS_REPORT_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID_REQUIRED_IF_STATUS_CLOSED
        .name,
    (formData, value, state) => {
        const { caseStatus } = formData;
        const { reportCaseStatuses } = caseStatus;
        return _(reportCaseStatuses)
            .compact()
            .every((status) => {
                const isClosed = statusIsClosedSelector(state)(status.caseStatusAttrId);
                return !isClosed || status.closedByUnitAttrId;
            });
    }
);

// TODO-VALIDATION-NITEMS would be great to support field level validation for NItems
export const caseDetailsReportCaseStatusStatusDateRequiredIfStatusClosed = addRuleId(
    SystemRuleEnum.CASE_DETAILS_REPORT_CASE_STATUS_STATUS_DATE_UTC_REQUIRED_IF_STATUS_CLOSED.name,
    (formData, value, state) => {
        const { caseStatus } = formData;
        const { reportCaseStatuses } = caseStatus;
        return _(reportCaseStatuses)
            .compact()
            .every((status) => {
                const isClosed = statusIsClosedSelector(state)(status.caseStatusAttrId);
                return !isClosed || status.statusDateUtc;
            });
    }
);

export const localIdRequiredIfNotNewCase = addRuleId(
    SystemRuleEnum.CASE_DETAILS_LOCAL_ID_REQUIRED_IF_NOT_NEW_CASE.name,
    (formData, value, state) => {
        const { caseInformation } = formData;
        if (!caseInformation.localId && !isNewCaseSelector(state)) {
            return false;
        }
        return true;
    }
);

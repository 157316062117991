import React from 'react';
import styled from 'styled-components';
import { compact, map } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { associatedDepartmentProfilesSelector } from '../../current-user/state/ui';
import Link from '../../components/links/Link';
import _Divider from '../../../../legacy-redux/components/core/Divider';
import {
    switchDeptsFromNavDropdown,
    openAccountSwitcherModal,
    loadCadSettingsForCurrentUser,
    cadUserSettingsSelector,
} from '../state/ui';
import testIds from '../../../../core/testIds';
import { switchToCad } from '../state/data';

const strings = componentStrings.cobalt.navigation.core.AccountSwitcherLink;

const Divider = styled(_Divider)`
    margin: 8px 0;
    border-color: ${(props) => props.theme.colors.lightGrey};
`;

const mapStateToProps = createStructuredSelector({
    associatedDepartmentProfiles: associatedDepartmentProfilesSelector,
    cadUserSettings: cadUserSettingsSelector,
});

const mapDispatchToProps = {
    openAccountSwitcherModal,
    switchDeptsFromNavDropdown,
    switchToCad,
    loadCadSettingsForCurrentUser,
};

const LinkToCad = ({ onClick }) => {
    return (
        <Link
            className="dropdown-menu-option"
            onClick={onClick}
            testId={testIds.INITIALS_DROPDOWN_OPEN_CAD}
        >
            {strings.goToCad}
        </Link>
    );
};

/* Component to display all the Account Switcher Links on the main Navigation menu bar:
 * 0 other depts or sso user should only display the link to the CAD menu (if enabled).
 * 1-2 other depts should display those other depts.
 * 3+ other depts should display 2 other depts + View All Departments”.
 */
function AccountSwitcherLink({
    associatedDepartmentProfiles,
    openAccountSwitcherModal,
    switchDeptsFromNavDropdown,
    switchToCad,
    cadUserSettings,
    loadCadSettingsForCurrentUser,
}) {
    React.useEffect(() => {
        // If there is no cadDeptId, then attempt to fetch one
        if (!cadUserSettings.cadDepartmentId) {
            loadCadSettingsForCurrentUser();
        }
    }, [cadUserSettings.cadDepartmentId, loadCadSettingsForCurrentUser]);
    const { hasCadModule, hasFirstResponderAbility, hasDispatcherAbility } = cadUserSettings;
    const renderDepartmentLinks = associatedDepartmentProfiles.length > 0;
    const canRenderLinkToCad = hasCadModule && (hasFirstResponderAbility || hasDispatcherAbility);
    const switchDepartmentsLinks = associatedDepartmentProfiles.slice(0, 2).map((deptProf) => (
        <Link
            key={deptProf.departmentId}
            title={strings.switchTo(deptProf)}
            className="dropdown-menu-option"
            onClick={() => switchDeptsFromNavDropdown(deptProf.department.subDomain)}
        >
            {strings.switchTo(deptProf)}
        </Link>
    ));
    const viewAllDepartmentsLink = associatedDepartmentProfiles.length > 2 && (
        <Link className="dropdown-menu-option" onClick={openAccountSwitcherModal}>
            {strings.viewAllDepartments}
        </Link>
    );

    const links = compact([
        canRenderLinkToCad && <LinkToCad onClick={() => switchToCad(cadUserSettings)} />,
        renderDepartmentLinks && switchDepartmentsLinks,
        renderDepartmentLinks && viewAllDepartmentsLink,
    ]);
    const linkItems = map(links, (link, key) => <div key={key}>{link}</div>);
    return (
        !!links.length && (
            <div>
                <Divider />
                {linkItems}
                <Divider />
            </div>
        )
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSwitcherLink);

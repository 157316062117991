import { EvidencePrintablesRequest, Printable } from '@mark43/evidence-api';
import createLinkModule from '../../../../utils/createLinkModule';
import buildEvidencePrintablesResource from '../../resources/evidencePrintablesResource';
import { ClientCommonAction } from '../../../../../redux/types';

/**
 * This module does not store all Printables, it only stores Printables which are loaded from the Evidence API.
 * attachment-folder-printables is a similar module.
 */
const evidencePrintablesModule = createLinkModule<Printable>({
    type: 'evidencePrintables',
    keys: ['printingTemplateId', 'entityId', 'title'],
});

const LOAD_EVIDENCE_PRINTABLES_START = 'evidence-printables/LOAD_EVIDENCE_PRINTABLES_START';
export const LOAD_EVIDENCE_PRINTABLES_FAILURE =
    'evidence-printables/LOAD_EVIDENCE_PRINTABLES_FAILURE';
const LOAD_EVIDENCE_PRINTABLES_SUCCESS = 'evidence-printables/LOAD_EVIDENCE_PRINTABLES_SUCCESS';

const storeEvidencePrintables = evidencePrintablesModule.actionCreators.storeLinks;
export const removeEvidencePrintablesWhere =
    evidencePrintablesModule.actionCreators.removeLinksWhere;

function loadEvidencePrintablesStart() {
    return { type: LOAD_EVIDENCE_PRINTABLES_START };
}
function loadEvidencePrintablesSuccess(printables: Printable[]) {
    return { type: LOAD_EVIDENCE_PRINTABLES_SUCCESS, payload: printables };
}
function loadEvidencePrintablesFailure(errorMessage: string) {
    return { type: LOAD_EVIDENCE_PRINTABLES_FAILURE, payload: errorMessage, error: true };
}

export function loadEvidencePrintables(
    options: EvidencePrintablesRequest
): ClientCommonAction<Promise<Printable[]>> {
    return function (dispatch) {
        dispatch(loadEvidencePrintablesStart());
        const resource = buildEvidencePrintablesResource();

        return resource
            .getEvidencePrintables(options)
            .then((printables: Printable[]) => {
                dispatch(storeEvidencePrintables(printables));
                dispatch(loadEvidencePrintablesSuccess(printables));
                return printables;
            })
            .catch((err: Error) => {
                dispatch(loadEvidencePrintablesFailure(err.message));
                throw err;
            });
    };
}

export const evidencePrintablesSelector = evidencePrintablesModule.selectors.linksSelector;
export const evidencePrintablesWhereSelector =
    evidencePrintablesModule.selectors.linksWhereSelector;

export default evidencePrintablesModule.reducerConfig;

import { ItemReportingEventLink } from '@mark43/rms-api';
import _, { get } from 'lodash';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { reportShortTitleByReportIdSelector } from '../../../report-short-titles/state/data';
import { elasticReportByIdSelector } from '../../../elastic-reports/state/data';
import { evidenceReportsSelector } from '../../../evidence-reports/state/data';

export const NEXUS_STATE_PROP = 'itemReportingEventLinks';

const itemReportingEventLinksModule = createNormalizedModule<ItemReportingEventLink>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const itemReportingEventLinksSelector =
    itemReportingEventLinksModule.selectors.entitiesSelector;

/**
 * Find the REN number for a given master item in the context of the given report.
 */
export const getReportingEventNumberSelector = createSelector(
    reportShortTitleByReportIdSelector,
    elasticReportByIdSelector,
    evidenceReportsSelector,
    (reportShortTitleByReportId, elasticReportById, evidenceReports) => (reportId: number) =>
        // Across all the features that display RENs for evidence items, we cannot rely on a single
        // model to exist for getting each REN.
        // Any feature that loads EvidenceHydratedItem will store evidenceReports
        // (EvidenceReportView.java) in Redux state. The item basket stores only evidenceReports.
        // Many features store reportShortTitles.
        // The entity profile page stores only elasticReports.
        get(
            evidenceReports[reportId] ||
                reportShortTitleByReportId(reportId) ||
                elasticReportById(reportId),
            'reportingEventNumber'
        )
);

/**
 * Find the REN and sequence number for the given master item in the context of
 *   the given report.
 *
 * It is possible for the sequence number to be missing due to data migration
 *   problems around `itemReportingEventLinks`.
 */
export const getRenSequenceNumberSelector = createSelector(
    getReportingEventNumberSelector,
    itemReportingEventLinksSelector,
    (getReportingEventNumber, itemReportingEventLinks) => (
        masterItemId: number,
        reportId: number
    ) => {
        const reportingEventNumber = getReportingEventNumber(reportId);
        const sequenceNumber = !!reportingEventNumber
            ? _.chain(itemReportingEventLinks)
                  .find({
                      masterItemProfileId: masterItemId,
                      reportingEventNumber,
                  })
                  .get('sequenceNumber')
                  .value()
            : undefined;

        return { reportingEventNumber, sequenceNumber };
    }
);

// REDUCER
export default itemReportingEventLinksModule.reducerConfig;

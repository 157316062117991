import { get, some } from 'lodash';
import { Charge, RefContextEnum } from '@mark43/rms-api';
import { offenseByIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { useFormGetter } from '../forms/hooks/useFormGetter';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

export const getChargeOffenseCodeIdMatchesLinkedOffense = (getState: () => RootState) => () => {
    const { getForm } = useFormGetter();
    const form = getForm(RefContextEnum.FORM_ARREST_CHARGES_N_CHARGES_SIDE_PANEL.name);

    if (typeof form === 'undefined') {
        return false;
    }
    const charges = form.getState().model.charges as Partial<Charge>[];

    const result = !some(charges, (charge) => {
        const linkedOffenseCodeId = get(
            offenseByIdSelector(getState())(charge.offenseId),
            'offenseCodeId'
        );
        return charge.chargeOffenseCodeId !== linkedOffenseCodeId;
    });
    return result;
};

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { map, compact, isEmpty } from 'lodash';
import styled from 'styled-components';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportShortTitleViewModelByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { submitDeleteWarrantModal } from '../state/ui';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { createModalSelector } from '../../../core/box/state/ui';
import WarrantLinkedArrestRow from './WarrantLinkedArrestRow';

const context = {
    name: boxEnum.DELETE_WARRANT_MODAL,
};
const strings = componentStrings.warrants.warrant.DeleteWarrantModal;

const LinkedArrestRowsContainer = styled.div`
    margin-top: 10px;
`;

/**
 * Modal for deleting a warrant.
 */
export default compose(
    withRouter,
    connect(
        createStructuredSelector({
            canDeleteWarrant: createModalSelector(context, 'canDeleteWarrant'),
            linkedArrestReportIds: createModalSelector(context, 'linkedArrestReportIds'),
            reportShortTitleViewModelByReportId: reportShortTitleViewModelByReportIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
            formatMiniUserById: formatMiniUserByIdSelector,
        }),
        (dispatch, props) => ({
            submitDeleteWarrant: () => dispatch(submitDeleteWarrantModal({ router: props.router })),
        })
    )
)(function DeleteWarrantModal({
    canDeleteWarrant,
    linkedArrestReportIds,
    reportShortTitleViewModelByReportId,
    departmentNameFromConsortiumLinksByDepartmentId,
    formatMiniUserById,
    submitDeleteWarrant,
}) {
    const linkedArrestReportShortTitles = compact(
        map(linkedArrestReportIds, reportShortTitleViewModelByReportId)
    );
    const linkedArrestRows = map(linkedArrestReportShortTitles, (reportShortTitle) => (
        <WarrantLinkedArrestRow
            key={reportShortTitle.reportId}
            reportShortTitle={reportShortTitle}
            departmentName={departmentNameFromConsortiumLinksByDepartmentId(
                reportShortTitle.departmentId
            )}
            reportOwnerName={formatMiniUserById(reportShortTitle.currentSubmissionOwnerId, {
                firstNameAsInitial: true,
            })}
        />
    ));

    const failureMessage = isEmpty(linkedArrestReportShortTitles)
        ? strings.cannotDeleteNoArrestsText
        : strings.cannotDeleteText;

    return (
        <Modal
            context={context}
            title={strings.title}
            onSave={submitDeleteWarrant}
            saveDisabled={!canDeleteWarrant}
            okText={strings.okText}
        >
            <div>{canDeleteWarrant ? strings.confirmationText : failureMessage}</div>
            <LinkedArrestRowsContainer>{linkedArrestRows}</LinkedArrestRowsContainer>
        </Modal>
    );
});

import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { AtfManufacturer } from '@mark43/rms-api';
import { atfManufacturerOptionsSelector } from '~/client-common/core/domain/etrace-atf/state/ui';
import { saveAtfManufacturers } from '~/client-common/core/domain/etrace-atf/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import AsyncSelect from '../../../../../core/forms/components/selects/AsyncSelect';
import { arbiterMFTInput } from '../../../../../core/arbiter';
import atfManufacturerResource from '../../../resources/atfManufacturerResource';
import reactReduxFormHelpers from '../../../../../../legacy-redux/helpers/reactReduxFormHelpers';

const { connectRRFInput } = reactReduxFormHelpers;

const AtfManufacturerSelect: React.FC = (props) => {
    const atfManufacturerOptions = useSelector(atfManufacturerOptionsSelector);
    const dispatch = useDispatch();

    const searchForAtfManufacturersSuccess = useCallback(
        (atfManufacturers: AtfManufacturer[]) => {
            return dispatch(saveAtfManufacturers({ atfManufacturers }));
        },
        [dispatch]
    );

    const searchForAtfManufacturers = useCallback((query: string) => {
        return atfManufacturerResource.searchForAtfManufacturers({
            query: {
                name: query,
                includeInactive: false,
            },
            from: 0,
            size: 20,
            sorts: [],
        });
    }, []);

    const { callResource: fetchAtfManufacturers } = useResourceDeferred(
        searchForAtfManufacturers,
        searchForAtfManufacturersSuccess
    );

    return <AsyncSelect asyncAction={fetchAtfManufacturers} options={atfManufacturerOptions} {...props} />;
};

// @ts-expect-error client-common to client RND-7529
export const RRFAftManufacturerSelect = connectRRFInput(AtfManufacturerSelect);
export const ArbiterAtfManufacturerSelect = arbiterMFTInput(AtfManufacturerSelect);

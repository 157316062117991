import { get, map, size } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { latestChainOfCustodyIdForMasterItemIdSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import {
    requestDispositions,
    reviewDispositions,
    resetDispositions,
} from '~/client-common/core/domain/disposition-events/state/data';
import { itemEvidenceStateByChainOfCustodyIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import formsRegistry from '../../../../core/formsRegistry';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import {
    ACTION_TYPES,
    DispositionActionType,
    createDispositionEvent,
    propsByActionType,
} from './dispositionActionSidePanelProps';

const strings = componentStrings.reports.custodialPropertySummary.DispositionActionSidePanel;
const overlayId = overlayIdEnum.DISPOSITION_ACTION_SIDE_PANEL;

interface DispositionActionSidePanelCustomProperties {
    actionType: DispositionActionType;
    masterItemIds: number[];
}

/**
 * Render this component if you need the side panel to be rendered permanently, e.g. when the side panel is opened from
 * an async dropdown menu which unmounts as soon as a dropdown menu option is clicked.
 *
 * Otherwise, it is better to use `withDispositionActionSidePanel` instead, which is a way to render the side panel
 * together with the component that opens it.
 */
export default function DispositionActionSidePanel({
    onSave,
}: {
    onSave?: () => void;
}): JSX.Element {
    const dispatch = useDispatch();
    const itemEvidenceStateByChainOfCustodyId = useSelector(
        itemEvidenceStateByChainOfCustodyIdSelector
    );
    const latestChainOfCustodyIdForMasterItemId = useSelector(
        latestChainOfCustodyIdForMasterItemIdSelector
    );

    return (
        <OverlayBaseHelper<DispositionActionSidePanelCustomProperties> id={overlayId}>
            {(renderProps) => {
                const {
                    customProperties: { actionType, masterItemIds },
                    errors,
                } = renderProps.overlayBase.overlayState;
                const { Form, formType, title } = propsByActionType[actionType];

                return (
                    <PortalSidePanel
                        closePanel={() => {
                            renderProps.closePanel();
                            formsRegistry.unregister(formType);
                        }}
                        errorMessages={errors}
                        saveText={strings.confirmButton}
                        savePanel={() => {
                            const form = formsRegistry.get(formType);
                            if (!form) {
                                return;
                            }
                            return form
                                .submit()
                                .then(() => {
                                    const dispositionEvents = map(masterItemIds, (masterItemId) => {
                                        const chainOfCustodyId = latestChainOfCustodyIdForMasterItemId(
                                            masterItemId
                                        );
                                        if (!chainOfCustodyId) {
                                            throw new Error(
                                                'Cannot update disposition due to missing data, please refresh and try again.'
                                            );
                                        }
                                        const itemEvidenceState = itemEvidenceStateByChainOfCustodyId(
                                            chainOfCustodyId
                                        );
                                        return createDispositionEvent(
                                            actionType,
                                            form,
                                            itemEvidenceState
                                        );
                                    });

                                    switch (actionType) {
                                        case ACTION_TYPES.REQUEST_DISPOSITION:
                                            return dispatch(
                                                requestDispositions(
                                                    masterItemIds,
                                                    dispositionEvents
                                                )
                                            );
                                        case ACTION_TYPES.RESET_RETENTION_POLICY:
                                            return dispatch(
                                                resetDispositions(masterItemIds, dispositionEvents)
                                            );
                                        default:
                                            return dispatch(
                                                reviewDispositions(masterItemIds, dispositionEvents)
                                            );
                                    }
                                })
                                .then(() => {
                                    renderProps.closePanel();
                                    formsRegistry.unregister(formType);
                                    if (onSave) {
                                        onSave();
                                    }
                                })
                                .catch((err) =>
                                    renderProps.overlayBase.overlayStore.setError(
                                        overlayId,
                                        err.message || get(err, 'validationResult.formErrors')
                                    )
                                );
                        }}
                        title={title}
                        userHasAttemptedSave={!!size(errors)}
                        isAtBottomOfStack={() =>
                            renderProps.overlayBase.overlayStore.isOverlayAtBottomOfStack(overlayId)
                        }
                    >
                        <Form />
                    </PortalSidePanel>
                );
            }}
        </OverlayBaseHelper>
    );
}

import Promise from 'bluebird';
// TODO PRINTING-REFACTOR evalute the 'DI' pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Export Releases Resource',
        methods: {
            getExportReleases(entityType, entityId) {
                return req({
                    method: 'GET',
                    url: 'print/releases',
                    params: {
                        entity_type: entityType,
                        entity_id: entityId,
                    },
                });
            },
            createExportReleases(releases) {
                return req({
                    method: 'POST',
                    url: 'print/releases',
                    data: releases,
                });
            },
            updateExportRelease(release) {
                return req({
                    method: 'PUT',
                    url: 'print/releases',
                    data: release,
                });
            },
            getAllExportReleaseSettings() {
                return req({
                    method: 'GET',
                    url: 'print/release/settings',
                });
            },
            upsertExportReleaseSetting(exportReleaseSetting) {
                return Promise.resolve(exportReleaseSetting);
                // return req({
                //     method: 'PUT',
                //     url: 'print/release/setting',
                //     data: exportReleaseSetting
                // });
            },
        },
    });
}

/**
 * Resources for Export Releases. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

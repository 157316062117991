import { ProductModuleEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';

import AttachmentDetails from '../../core/components/EntityProfileAttachmentDetails';
import EntityProfileLinkDetails from '../../core/components/EntityProfileLinkDetails';
import LegacyEntityDetails from '../../core/components/LegacyEntityDetails';
import {
    propertyDetailsSelector,
    currentEntityProfileIsMasterProfileSelector,
} from '../../core/state/ui';
import EntityProfileSection from '../../core/components/EntityProfileSection';
import { EntityProfileActiveTarget } from '../../core/components/EntityProfileActiveTarget/EntityProfileActiveTarget';
import ChainOfCustody from '../../../evidence/chain-of-custody/components/ChainOfCustody';
import ChainOfCustodyTitle from '../../../evidence/chain-of-custody/components/ChainOfCustodyTitle';
import DispositionNoticeBar from '../../../evidence/disposition/components/DispositionNoticeBar';
import FirearmIdentificationDetails from './EntityProfileFirearmIdentificationDetails';
import ItemIdentificationDetails from './EntityProfileItemIdentificationDetails';

function EntityProfilePropertyDetails({ propertyDetails, isMaster }) {
    const identificationDetails =
        propertyDetails.itemType === globalAttributes.itemType.firearm ? (
            <FirearmIdentificationDetails firearmDetails={propertyDetails.firearmDetails} />
        ) : (
            <ItemIdentificationDetails itemDetails={propertyDetails.itemDetails} />
        );

    return propertyDetails ? (
        <div>
            <EntityProfileActiveTarget />
            <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
                <OnlyWithAbility has={abilitiesEnum.EVIDENCE.VIEW_GENERAL}>
                    <DispositionNoticeBar masterItemId={propertyDetails.itemDetails.masterItemId} />
                </OnlyWithAbility>
            </ProductModuled>
            {identificationDetails}
            {propertyDetails.ownerDetails.length > 0 && (
                <EntityProfileLinkDetails
                    linkDetails={propertyDetails.ownerDetails}
                    parentNameItemAssociation={globalAttributes.nameItemAssociationGlobal.owner}
                />
            )}
            {propertyDetails.claimantDetails.length > 0 && (
                <EntityProfileLinkDetails
                    linkDetails={propertyDetails.claimantDetails}
                    parentNameItemAssociation={globalAttributes.nameItemAssociationGlobal.claimant}
                />
            )}
            {propertyDetails.collectorDetails.length > 0 && (
                <EntityProfileLinkDetails
                    linkDetails={propertyDetails.collectorDetails}
                    parentNameItemAssociation={globalAttributes.nameItemAssociationGlobal.collector}
                />
            )}
            {propertyDetails.genericAssociationDetails.length > 0 && (
                <EntityProfileLinkDetails
                    linkDetails={propertyDetails.genericAssociationDetails}
                    parentNameItemAssociation={globalAttributes.nameItemAssociationGlobal.generic}
                />
            )}
            {propertyDetails.legacyEntityDetails.length > 0 && (
                <LegacyEntityDetails legacyEntityDetails={propertyDetails.legacyEntityDetails} />
            )}
            {isMaster && (
                <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
                    <OnlyWithAbility has={abilitiesEnum.EVIDENCE.VIEW_GENERAL}>
                        <EntityProfileSection
                            title={
                                <ChainOfCustodyTitle
                                    masterItemId={propertyDetails.itemDetails.masterItemId}
                                />
                            }
                        >
                            <ChainOfCustody
                                masterItemId={propertyDetails.itemDetails.masterItemId}
                            />
                        </EntityProfileSection>
                    </OnlyWithAbility>
                </ProductModuled>
            )}
            <AttachmentDetails
                entityId={propertyDetails.itemDetails.id}
                linkType={[LinkTypesEnum.ITEM_PROFILE_PHOTO, LinkTypesEnum.ITEM_PROFILE_ATTACHMENT]}
            />
        </div>
    ) : (
        <div />
    );
}

const mapStateToProps = createStructuredSelector({
    propertyDetails: propertyDetailsSelector,
    isMaster: currentEntityProfileIsMasterProfileSelector,
});

export default connect(mapStateToProps)(EntityProfilePropertyDetails);

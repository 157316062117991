import React, { useEffect } from 'react';
import { ExportPresetView, ExportReportVersionEnum } from '@mark43/rms-api';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { isEmpty, map, findLastIndex, filter, partition } from 'lodash';
import { loadExportPresets } from '~/client-common/core/domain/export-presets/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Link from '../../../../core/components/links/Link';
import { exportPDF } from '../../../../../legacy-redux/actions/exportsActions';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import _DropdownMenu from '../../../../core/components/DropdownMenu';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { loadReportExports } from '../../state/ui';
import {
    enabledExportPresetsForDropdownSelector,
    convertExportPresetToFormData,
    packetsForExportPresetSelector,
    saveExportRelease,
    sortExportPresets,
    displayPrintOfficerSnapshotExportPresetInExportDropdownSelector,
} from '../../../../admin/export-presets/state/data';
import testIds from '../../../../../core/testIds';
import { RmsAction } from '../../../../../core/typings/redux';
import Divider from '../../../../../legacy-redux/components/core/Divider';
import ReportHeaderExportsButton from './ReportHeaderExportButton';

const strings = componentStrings.core.header.ReportHeaderExportsDropdown;

const DropdownMenu = styled(_DropdownMenu)`
    margin: 0;

    .dropdown-menu-button {
        background-color: ${(props) => props.theme.colors.extraLightGrey};
    }
`;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
`;

const StyledDivider = styled(Divider)`
    margin: 8px 0;
    border-color: ${(props) => props.theme.colors.lightGrey};
`;

export function executeExportPreset(exportPreset: ExportPresetView): RmsAction<void> {
    return (dispatch, getState) => {
        const isReleaseTrackingEnabled = applicationSettingsSelector(getState())
            .RELEASE_TRACKING_ENABLED;
        const packets = packetsForExportPresetSelector(getState())(exportPreset);
        const exportFormData = convertExportPresetToFormData(exportPreset, packets);

        if (isReleaseTrackingEnabled) {
            dispatch(saveExportRelease(exportPreset, packets, exportFormData));
        } else {
            dispatch(exportPDF(exportFormData, packets));
        }
    };
}

const ReportHeaderExportsDropdown: React.FC = () => {
    const currentReportId = useSelector(currentReportIdSelector);
    const enabledExportPresets = useSelector(enabledExportPresetsForDropdownSelector);
    const displayPrintOfficerSnapshotExportPresetInExportDropdown = useSelector(
        displayPrintOfficerSnapshotExportPresetInExportDropdownSelector
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadExportPresets());
        if (currentReportId) {
            dispatch(loadReportExports(currentReportId));
        }
    }, [dispatch, currentReportId]);

    const renderDropdown = !isEmpty(enabledExportPresets);

    const defaultExportPresetPredicate = { isDefaultExportPreset: true };

    const sortedEnabledExportPresets = sortExportPresets(enabledExportPresets);
    const [
        sortedEnabledDefaultExports,
        sortedEnabledOtherExportPresets,
    ] = partition<ExportPresetView>(sortedEnabledExportPresets, defaultExportPresetPredicate);

    const defaultPresets = displayPrintOfficerSnapshotExportPresetInExportDropdown
        ? sortedEnabledDefaultExports
        : filter(sortedEnabledDefaultExports, (exportPreset) => {
              return exportPreset.exportReportVersion === ExportReportVersionEnum.CURRENT.name;
          });

    const allEnabledExportPresets = [...defaultPresets, ...sortedEnabledOtherExportPresets];

    const indexOfLastDefaultExportPreset = findLastIndex(
        allEnabledExportPresets,
        defaultExportPresetPredicate
    );

    const dropdownMenu = (
        <DropdownMenu
            dropRight={true}
            buttonContent={
                <DropdownMenuButtonContainer
                    data-test-id={testIds.REPORT_HEADER_EXPORTS_DROPDOWN_BUTTON}
                >
                    <Icon size={16} type={iconTypes.EXPORT} />
                    <Icon size={16} type={iconTypes.TRIANGLE_DOWN} />
                </DropdownMenuButtonContainer>
            }
            width={250}
            tooltip={strings.tooltip}
        >
            {renderDropdown && (
                <div>
                    {/* Default Presets */}
                    {map(allEnabledExportPresets, (enabledExportPreset, index) => {
                        const isDefaultAndCurrentVersion =
                            enabledExportPreset.exportReportVersion ===
                                ExportReportVersionEnum.CURRENT.name &&
                            enabledExportPreset.isDefaultExportPreset;

                        const displayTitle = isDefaultAndCurrentVersion
                            ? `${enabledExportPreset.title} ${strings.shortcutCommand}`
                            : `${enabledExportPreset.title}`;

                        return (
                            <div key={enabledExportPreset.id}>
                                <Link
                                    testId={
                                        enabledExportPreset.isDefaultExportPreset
                                            ? testIds.REPORT_HEADER_EXPORTS_DEFAULT_LINK
                                            : testIds.REPORT_HEADER_EXPORTS_MENU_EXPORT_LINK
                                    }
                                    className="dropdown-menu-option"
                                    onClick={() =>
                                        dispatch(executeExportPreset(enabledExportPreset))
                                    }
                                >
                                    {displayTitle}
                                </Link>
                                {index === indexOfLastDefaultExportPreset && <StyledDivider />}
                            </div>
                        );
                    })}
                </div>
            )}
            <StyledDivider />
            <ReportHeaderExportsButton asDropdownOption={true} />
        </DropdownMenu>
    );
    return renderDropdown ? dropdownMenu : <ReportHeaderExportsButton />;
};

export default ReportHeaderExportsDropdown;

import React from 'react';
import { size } from 'lodash';
import styled from 'styled-components';
import { Form, Observer, lifecycleOptions } from 'markformythree';
import {
    EntityTypeEnumType,
    LinkTypesEnumType,
    EntityTypeEnum,
    AttributeTypeEnum,
} from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';

import testIds from '../../../../core/testIds';
import {
    createOrganizationSearchToAddFormConfiguration,
    ORGANIZATION_SEARCH_TO_ADD_FORM,
    OrganizationSearchToAddFormConfiguration,
} from '../../../search/search-to-add/state/forms/organizationSearchToAddForm';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { MFTText } from '../../forms/components/Text';
import { MFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { clearfix } from '../../styles/mixins';
import ButtonWithLoadingIndicator from '../../components/ButtonWithLoadingIndicator';
import RecentEntities from '../../recent-entities/components/RecentEntities';
import _OrganizationPill from './OrganizationPill';

type OrganizationSearchToAddProps = {
    contextId: number;
    hideRecentEntities: boolean;
    linkType: LinkTypesEnumType;
    loading: boolean;
    onClickRecentEntity: (id: number) => void;
    onClickSearch: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    ownerId: number;
    ownerType: EntityTypeEnumType;
    renForRecents?: number;
};
type QuickSearchQuerySubscription = {
    quickSearchQuery?: string;
};

const strings = componentStrings.core.OrganizationSearchToAdd;

const FormContainer = styled.div`
    ${clearfix};
`;

const OrganizationPill = styled(_OrganizationPill)`
    margin-top: 10px;
`;

const OrganizationSearchToAdd = (props: OrganizationSearchToAddProps) => {
    const configuration: OrganizationSearchToAddFormConfiguration = createOrganizationSearchToAddFormConfiguration();
    const {
        onClickSearch,
        loading,
        onClickRecentEntity,
        renForRecents,
        ownerType,
        ownerId,
        contextId,
        linkType,
        hideRecentEntities,
    } = props;
    const renderRecentEntity = ({ recentEntityId }: { recentEntityId: number }) => (
        <OrganizationPill onClick={onClickRecentEntity} id={recentEntityId} key={recentEntityId} />
    );
    const organizationFieldName = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];
    return (
        <div>
            <Form
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                name={ORGANIZATION_SEARCH_TO_ADD_FORM}
                configuration={configuration}
                render={(form) => {
                    const CHAR_COUNT_TO_ENABLE_SUBMIT = 3;
                    const handlePressEnter = (
                        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => {
                        const quickSearchQuery = form.get().quickSearchQuery;
                        if (size(quickSearchQuery) >= CHAR_COUNT_TO_ENABLE_SUBMIT) {
                            onClickSearch(e);
                        }
                    };

                    return (
                        <FormContainer>
                            <MFTText
                                path="quickSearchQuery"
                                label={strings.fieldLabels.quickSearchQuery(organizationFieldName)}
                                placeholder={strings.fieldPlaceholders.quickSearchQuery}
                                onPressEnter={handlePressEnter}
                                // @ts-expect-error FormElement props are not typed
                                fieldNameAlternate="NAME_SIDE_PANEL_NAME_SEARCH_NAME_INPUT"
                            />
                            <MFTText
                                path="locationStreetAddress"
                                label={strings.fieldLabels.locationStreetAddress}
                                onPressEnter={handlePressEnter}
                                length="lg"
                            />
                            <MFTAttributeSelect
                                attributeType={[AttributeTypeEnum.ORGANIZATION_TYPE.name]}
                                path="organizationTypeAttrIds"
                                label={strings.fieldLabels.organizationTypeAttrIds(
                                    organizationFieldName
                                )}
                                length="lg"
                            />
                            <Observer<
                                QuickSearchQuerySubscription,
                                OrganizationSearchToAddFormConfiguration
                            >
                                subscriptions={{
                                    quickSearchQuery: 'quickSearchQuery',
                                }}
                                formName={ORGANIZATION_SEARCH_TO_ADD_FORM}
                                render={({ quickSearchQuery }) => (
                                    <ButtonWithLoadingIndicator
                                        loading={loading}
                                        disabled={
                                            size(quickSearchQuery) < CHAR_COUNT_TO_ENABLE_SUBMIT
                                        }
                                        className={buttonTypes.PRIMARY}
                                        onClick={onClickSearch}
                                        testId={testIds.NAME_SIDE_PANEL_SEARCH_BUTTON}
                                    >
                                        {strings.search}
                                    </ButtonWithLoadingIndicator>
                                )}
                            />
                        </FormContainer>
                    );
                }}
            />
            {!hideRecentEntities && (
                <RecentEntities
                    renForRecents={renForRecents}
                    linkType={linkType}
                    ownerType={ownerType}
                    ownerId={ownerId}
                    contextId={contextId}
                    entityType={EntityTypeEnum.ORGANIZATION_PROFILE.name}
                    entityDisplayValue={organizationFieldName}
                    onClickRecentEntity={onClickRecentEntity}
                    renderRecentEntity={renderRecentEntity}
                />
            )}
        </div>
    );
};

export default OrganizationSearchToAdd;

import React from 'react';
import { keys, isArray, isObject, map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import Modal, { OverrideModalPropsFunction } from '../../../../core/overlays/components/Modal';

type SubmissionErrorModalCustomProps = {
    errors: Record<string, string>;
    title?: string;
};

const strings = componentStrings.reports.core.ReportStatusCommentsCard.submissions.errors.modal;

const SubmissionErrorModal: React.FC = () => {
    const overrideModalProps: OverrideModalPropsFunction<SubmissionErrorModalCustomProps> = (
        overlayProps
    ) => {
        const {
            overlayState: {
                customProperties: { errors, title },
            },
        } = overlayProps;

        const children = (
            <>
                {isArray(errors) && (
                    <ul>
                        {map(errors, (e, index) => (
                            <li key={index}>{e.message || e}</li>
                        ))}
                    </ul>
                )}
                {!isArray(errors) && isObject(errors) && (
                    <ul>
                        {map(keys(errors), (panelName, index) => (
                            <React.Fragment key={index}>
                                <li>{panelName}</li>
                                <ul>
                                    {map(errors[panelName], (e, i) => (
                                        <li key={i}>{e}</li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        ))}
                    </ul>
                )}
            </>
        );

        return {
            children,
            title: title || strings.title,
        };
    };

    return (
        <Modal<SubmissionErrorModalCustomProps>
            cancelText={strings.cancelText}
            errorHeader={true}
            id={overlayIdEnum.SUBMISSION_ERROR_MODAL}
            okText={null}
            overrideModalProps={overrideModalProps}
        />
    );
};

export default SubmissionErrorModal;

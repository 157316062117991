import React, { useState, useCallback } from 'react';
import { map, chain } from 'lodash';
import styled from 'styled-components';
import { Text, Stack, HStack } from 'arc';
import { EntityTypeEnum, EntityTypeEnumType, UsageLog } from '@mark43/rms-api';
import { useResource } from '~/client-common/core/hooks/useResource';
import getUsageLogsResource from '~/client-common/core/domain/usage-logs/resources/usageLogsResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';
import Icon, { iconTypes } from '../../core/components/Icon';
import Link from '../../core/components/links/Link';
import { NUM_RECENTLY_VIEWED } from '../configuration';
import NoDataBlock from '../../core/components/NoDataBlock';
import { DashboardLeftSidebarLoader } from './DashboardLoaders';

const strings = componentStrings.personalDashboard.RecentlyViewed;

type RecentlyViewedReportsProps = {
    hideLoadingBar?: boolean;
    entityFilter?: EntityTypeEnumType;
    getRecentlyViewedIds?: (usageLogs: UsageLog[]) => void;
    renderCustomComponent?: (entityId: number) => JSX.Element | null;
};

const StyledLink = styled(Link)`
    padding: var(--arc-space-1) var(--arc-space-3);
    border-radius: var(--arc-radii-base);
    color: var(--arc-colors-icon-default);

    &:hover {
        background-color: var(--arc-colors-interactive-hover);
    }
`;

const StyledIcon = styled(Icon)`
    margin-top: var(--arc-space-1);
`;

function RecentlyViewedEntity({ usageLog }: { usageLog: UsageLog }) {
    let iconType;
    let pathname;
    switch (usageLog.primaryEntityType) {
        case EntityTypeEnum.REPORT.name:
            iconType = iconTypes.REPORT;
            pathname = `/reports/${usageLog.primaryEntityId}`;
            break;
        case EntityTypeEnum.CASE.name:
            iconType = iconTypes.CASE;
            pathname = `/cases/${usageLog.primaryEntityId}`;
            break;
        default:
            return <div />;
    }
    return (
        <StyledLink to={pathname}>
            <HStack align="start">
                <StyledIcon type={iconType} />
                <Stack spacing="0">
                    <Text>{usageLog.primaryEntityTitle}</Text>
                    <Text color="tertiary" fontSize="sm">
                        <FormattedDate
                            date={usageLog.usageLogDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                        />
                    </Text>
                </Stack>
            </HStack>
        </StyledLink>
    );
}

export const RecentlyViewedReports = ({
    hideLoadingBar,
    entityFilter,
    getRecentlyViewedIds,
    renderCustomComponent,
}: RecentlyViewedReportsProps) => {
    const [usageLogs, setUsageLogs] = useState<UsageLog[]>([]);

    const loadRecentlyViewedUsageLogs = useCallback(
        () =>
            getUsageLogsResource().getRecentlyViewedUsageLogs(
                {
                    entityTypes: entityFilter
                        ? [entityFilter]
                        : [EntityTypeEnum.REPORT.name, EntityTypeEnum.CASE.name],
                    // + 2 because there may be 1 usage log each for viewing the reports dashboard and the
                    // cases dashboard
                    size: NUM_RECENTLY_VIEWED + 2,
                },
                { hideLoadingBar }
            ),
        [hideLoadingBar, entityFilter]
    );

    const onResourceSuccess = useCallback(
        ({ items }: { items: UsageLog[] }) => {
            const usageLogs = chain(items)
                .filter(
                    ({ primaryEntityId, primaryEntityTitle }) =>
                        !!primaryEntityId && !!primaryEntityTitle
                )
                .uniqBy('primaryEntityId')
                .take(NUM_RECENTLY_VIEWED)
                .value();
            setUsageLogs(usageLogs);
            if (getRecentlyViewedIds) {
                getRecentlyViewedIds(usageLogs);
            }
        },
        [getRecentlyViewedIds]
    );

    const loadingState = useResource(loadRecentlyViewedUsageLogs, onResourceSuccess);

    const { isLoading, errorMessage } = loadingState;

    if (isLoading && usageLogs.length === 0) {
        return <DashboardLeftSidebarLoader />;
    }

    if (errorMessage) {
        return <NoDataBlock>{strings.loadError}</NoDataBlock>;
    }
    if (!isLoading && usageLogs.length === 0) {
        return <NoDataBlock>{strings.noResults}</NoDataBlock>;
    }

    const recentlyViewedEntities = renderCustomComponent
        ? map(usageLogs, (usageLog: UsageLog) => renderCustomComponent(usageLog.primaryEntityId))
        : map(usageLogs, (usageLog: UsageLog) => (
              <RecentlyViewedEntity key={usageLog.id} usageLog={usageLog} />
          ));

    return <Stack>{recentlyViewedEntities}</Stack>;
};

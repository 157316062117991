import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { EntityFlexRow } from '../../core/components/RecordPrivacyRow';
import Tag from '../../core/components/Tag';
import testIds from '../../../../core/testIds';
import { Button as ArcButton } from '../../../core/components/Button';

const InvolvedPersonName = styled.span`
    flex: 1 1 100%;
    font-size: var(--arc-fontSizes-sm);
`;

const { recordPrivacy } = componentStrings;

export default class InvolvedPersonRow extends React.Component {
    handleSealClick = () => this.props.onSealClick(this.props.person);
    handleUnsealClick = () => this.props.onUnsealClick(this.props.person);
    render() {
        const { sealed, person, disabled } = this.props;
        return (
            <EntityFlexRow>
                {sealed && <Tag>{recordPrivacy.sealing.InvolvedPersonRow.sealedTag}</Tag>}
                <InvolvedPersonName>{formatFullName(person)}</InvolvedPersonName>
                <ArcButton
                    isActive={sealed}
                    isDisabled={disabled}
                    variant="outline"
                    onClick={sealed ? this.handleUnsealClick : this.handleSealClick}
                    testId={testIds.SEAL_PERSON_BUTTON}
                    minWidth="100px"
                >
                    {recordPrivacy.sealing.InvolvedPersonRow.seal}
                </ArcButton>
            </EntityFlexRow>
        );
    }
}

import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'arc';
import { withRouter, WithRouterProps } from 'react-router';

import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import CreateManageCaseModal from '../../../../cases/core/components/CreateManageCaseModal';
import { openCreateCaseModal } from '../../../../cases/core/state/ui';
import { ProfileRouteParams, getEntityDataFromRouteParams } from '../../helpers/routeHelpers';
import { getCaseLinksFromEntity } from '../../helpers/caseLinksHelpers';

const strings = componentStrings.cases.core.CaseHeader;

type CreateProfileLinkedCaseButtonProps = WithRouterProps<ProfileRouteParams>;

const CreateProfileLinkedCaseButton: FC<CreateProfileLinkedCaseButtonProps> = ({ params }) => {
    const dispatch = useDispatch();

    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();

    const handleClick = () => {
        dispatch(openCreateCaseModal());
    };

    const entity = getEntityDataFromRouteParams(params);
    const { nameCaseLinks, itemCaseLinks } = getCaseLinksFromEntity(entity);

    return (
        <>
            <Button variant="solid" leftIcon="Add" onClick={handleClick}>
                {strings.createCase(caseFieldName)}
            </Button>
            <CreateManageCaseModal
                title={strings.manageCase(caseFieldName)}
                okText={strings.save}
                isBulkCreate={false}
                includeLocalIdInTitle
                nameCaseLinks={nameCaseLinks}
                itemCaseLinks={itemCaseLinks}
            />
        </>
    );
};

export default withRouter(CreateProfileLinkedCaseButton);

import { get, map, omit, pick } from 'lodash';
import { createSelector } from 'reselect';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import {
    formDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import { organizationFieldsetViewModel } from '../../../../../legacy-redux/configs/fieldsetsConfig';

const advancedSearchOrganizationsFormFieldViewModels = {
    ...buildFlatFormFieldViewModels([
        // fields in the form that are not nested
        'fuzzyMatchingEnabled',
    ]),
    organization: organizationFieldsetViewModel,
};

/**
 * Convert the given search query model to form model state. While the query is
 *   flat, the form model state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertAdvancedSearchOrganizationsElasticQueryToFormModel(elasticQuery) {
    return buildFormModel(
        {
            fuzzyMatchingEnabled: get(elasticQuery, 'fuzzyMatchingEnabled'),
            organization: pick(elasticQuery, map(organizationFieldsetViewModel.fields, 'key')),
        },
        advancedSearchOrganizationsFormFieldViewModels
    );
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertAdvancedSearchOrganizationsFormModelToElasticQuery(formModel = {}) {
    return filterFormData({
        fuzzyMatchingEnabled: formModel.fuzzyMatchingEnabled,
        ...formModel.organization,
    });
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object} boundSelectors extra selectors from search module
 * @return {Object}   Array of filter group view models.
 */
export function convertAdvancedSearchOrganizationsFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    const formatFieldByName = boundSelectors.formatFieldByName;
    const organizationLabel = formatFieldByName(DISPLAY_ONLY_ORGANIZATION_LABEL);

    const fieldViewModels = {
        ...advancedSearchOrganizationsFormFieldViewModels,
        organization: {
            ...advancedSearchOrganizationsFormFieldViewModels.organization,
            fields: {
                ...organizationFieldsetViewModel.fields,
                name: {
                    ...organizationFieldsetViewModel.fields.name,
                    label: organizationFieldsetViewModel.fields.name.label(organizationLabel),
                },
            },
        },
    };

    return convertFormModelToFilterGroups(
        formModel,
        fieldViewModels,
        formatFieldValue,
        formatFieldByName
    );
}

const advancedSearchOrganizationsForm = createFormModule({
    formName: formClientEnum.ADVANCED_SEARCH_ORGANIZATIONS,
    fieldViewModels: advancedSearchOrganizationsFormFieldViewModels,
    convertToFormModel: convertAdvancedSearchOrganizationsElasticQueryToFormModel,
    convertFromFormModel: convertAdvancedSearchOrganizationsFormModelToElasticQuery,
});

advancedSearchOrganizationsForm.selectors.formIsEmptySelector = createSelector(
    advancedSearchOrganizationsForm.selectors.formModelSelector,
    (formModel) => formDataIsEmpty(omit(formModel, 'fuzzyMatchingEnabled'))
);

/**
 * Module of the advanced search organizations form.
 * @type {Object}
 */
export default advancedSearchOrganizationsForm;

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import type { InjectedRouter } from 'react-router';
import { ConfirmModalProps, useToast } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';

import errorToMessage from '../../core/errors/utils/errorToMessage';

import briefingResource from '../resources/briefingResource';
import { briefingsDashboardSearch } from '../state/ui';
import { BRIEFINGS_ROUTES } from '../configuration/routes';

const strings = componentStrings.briefings.core;

// Should be used in combination with ConfirmationModal
// Provides all the necessary props
const useBriefingDeletionConfirmationModal = (
    briefingId: number,
    router: InjectedRouter,
    redirect = true
) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();
    const toast = useToast();

    const openDeletionModal = () => setIsModalOpen(true);
    const closeDeletionModal = () => setIsModalOpen(false);

    const confirmModal = async () => {
        try {
            await briefingResource.deleteBriefing(briefingId);
            // @ts-expect-error client-common to client RND-7529
            dispatch(briefingsDashboardSearch.actionCreators.removeResult(briefingId));
            if (redirect) {
                router.push(BRIEFINGS_ROUTES.root.url());
            }
        } catch (e) {
            toast({
                status: 'error',
                description: errorToMessage(e, strings.deleteFailToast),
            });
        }
    };

    const deletionModalProps: ConfirmModalProps = {
        isOpen: isModalOpen,
        title: strings.delete,
        body: strings.deleteModalBody,
        confirmLabel: strings.deleteModalConfirm,
        cancelLabel: strings.deleteModalCancel,
        isDangerous: true,
        onConfirm: confirmModal,
        onClose: closeDeletionModal,
        onCancel: closeDeletionModal,
    };

    return { openDeletionModal, deletionModalProps };
};

export default useBriefingDeletionConfirmationModal;

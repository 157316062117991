import React from 'react';
import styled from 'styled-components';

import { Text as ArcText, cssVar } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Icon, { iconTypes, iconSizes } from '../Icon';

const strings = componentStrings.core.BackBanner;

const BackContainer = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 3px 3px -3px ${(props) => props.theme.colors.shadow};
    ${(props) =>
        props.disabled &&
        `
        opacity: .5;
        pointer-events: none;
        cursor: default;
    `};
`;

const BackButtonText = styled(ArcText)`
    color: ${cssVar('arc.colors.brand.default')};
    opacity: ${(props) => (props.disabled ? 0.4 : undefined)};
    margin-left: 4px;
`;

const BackButtonWithChevron = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const BackBanner = ({
    className,
    onClickBack,
    rightContent,
    disabled = false,
    backButtonText = strings.back,
}) => (
    <BackContainer disabled={disabled} className={className}>
        <BackButtonWithChevron onClick={onClickBack}>
            <Icon color="cobaltBlue" type={iconTypes.OPEN_LEFT} size={iconSizes.MEDIUM} />
            <BackButtonText disabled={disabled}>{backButtonText}</BackButtonText>
        </BackButtonWithChevron>
        {rightContent && <div>{rightContent}</div>}
    </BackContainer>
);

export default BackBanner;

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
// @ts-expect-error no .d.ts file for react-composer
import Composer from 'react-composer';
import { WarrantActivity as WarrantActivityT } from '@mark43/rms-api';

import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { FormattedDate } from '~/client-common/core/dates/components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import {
    deleteWarrantActivity as _deleteWarrantActivity,
    warrantActivitiesWhereSelector,
} from '~/client-common/core/domain/warrant-activities/state/data';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import RecordSidebarSection from '../../../records/core/components/sidebar/RecordSidebarSection';

import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { openWarrantActivitySidePanel } from '../state/ui';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';

const strings = componentStrings.warrants.warrant.WarrantActivities;
const INITIAL_NUM_OF_ACTIVITIES = 5;
const SHOW_MORE_INTERVAL = 5;

const WarrantActivity = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
`;

const ActivityType = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const ActivityNotes = styled.div`
    font-style: italic;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    padding-top: 5px;
`;

const ActivityInfo = styled.div`
    font-size: var(--arc-fontSizes-sm);
    width: 245px;
`;

const EditButtons = styled.div`
    width: 40px;
`;

const NoActivities = styled.div`
    padding: 10px 0;
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: var(--arc-fontSizes-md);
    color: ${(props) => props.theme.colors.darkGrey};
`;

const WarrantActivities = ({ warrantId }: { warrantId: number }) => {
    const dispatch = useDispatch();
    const addNewWarrantActivity = useCallback(
        () =>
            // prefill activity date with current time
            dispatch(openWarrantActivitySidePanel({ warrantId, activityDateUtc: nowUtc() })),
        [dispatch, warrantId]
    );
    const editWarrantActivity = useCallback(
        (warrantActivity: WarrantActivityT) =>
            dispatch(openWarrantActivitySidePanel(warrantActivity)),
        [dispatch]
    );
    const deleteWarrantActivity = useCallback(
        (id: number) => dispatch(_deleteWarrantActivity(id)),
        [dispatch]
    );

    const warrantActivitiesWhere = useSelector(warrantActivitiesWhereSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const canAddWarrantActivities = currentUserHasAbility(
        abilitiesEnum.WARRANTS.ADD_WARRANT_ACTIVITIES
    );
    const canEditDeleteWarrantActivities = currentUserHasAbility(
        abilitiesEnum.WARRANTS.EDIT_DELETE_WARRANT_ACTIVITIES
    );
    const [numDisplayed, setNumDisplayed] = useState(INITIAL_NUM_OF_ACTIVITIES);

    const warrantActivities = warrantActivitiesWhere({ warrantId });
    const totalNumberOfActivities = warrantActivities.length;

    return (
        <RecordSidebarSection
            title={strings.title}
            onEditIconType={iconTypes.ADD}
            onEditLabel={strings.add}
            onEdit={canAddWarrantActivities ? () => addNewWarrantActivity() : null}
        >
            {totalNumberOfActivities > 0 ? (
                <div>
                    {_(warrantActivities)
                        .sortBy(['activityDateUtc', 'createdDateUtc'])
                        .reverse()
                        .slice(0, numDisplayed)
                        .map((warrantActivity) => {
                            const formattedDate = (
                                <FormattedDate
                                    key={warrantActivity.activityDateUtc}
                                    date={warrantActivity.activityDateUtc}
                                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                                />
                            );
                            const formattedUser = warrantActivity.activityPerformedByUserId ? (
                                <ConnectedFormattedUser
                                    key={warrantActivity.activityPerformedByUserId}
                                    userId={warrantActivity.activityPerformedByUserId}
                                    format={FORMATS.FULL_NAME}
                                />
                            ) : null;
                            return (
                                <WarrantActivity key={warrantActivity.id}>
                                    <ActivityInfo>
                                        <ActivityType>
                                            <ConnectedFormattedAttribute
                                                attributeId={warrantActivity.activityTypeAttrId}
                                            />
                                        </ActivityType>
                                        <Composer components={[formattedDate, formattedUser]}>
                                            {([date, user]: [
                                                date: JSX.Element,
                                                user: JSX.Element
                                            ]) => (
                                                <div>{`${date}${!!user ? ` by ${user}` : ''}`}</div>
                                            )}
                                        </Composer>
                                        {warrantActivity.notes && (
                                            <ActivityNotes>{warrantActivity.notes}</ActivityNotes>
                                        )}
                                    </ActivityInfo>
                                    {canEditDeleteWarrantActivities && (
                                        <EditButtons>
                                            <Button
                                                className={buttonTypes.ICON_LINK}
                                                iconLeft={iconTypes.EDIT}
                                                onClick={() => editWarrantActivity(warrantActivity)}
                                            />
                                            <Button
                                                className={buttonTypes.ICON_LINK}
                                                iconLeft={iconTypes.TRASH_CAN}
                                                onClick={() =>
                                                    deleteWarrantActivity(warrantActivity.id)
                                                }
                                            />
                                        </EditButtons>
                                    )}
                                </WarrantActivity>
                            );
                        })
                        .value()}
                    {numDisplayed < totalNumberOfActivities && (
                        <Button
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.ADD} />}
                            onClick={() =>
                                setNumDisplayed(
                                    (prevNumDisplayed) => prevNumDisplayed + SHOW_MORE_INTERVAL
                                )
                            }
                        >
                            {strings.showMore}
                        </Button>
                    )}
                </div>
            ) : (
                <NoActivities>{strings.noActivities}</NoActivities>
            )}
        </RecordSidebarSection>
    );
};

export default WarrantActivities;

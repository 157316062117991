import { createSelector } from 'reselect';
import { mapValues, filter, values } from 'lodash';
import { TrafficCrashRoadway } from '@mark43/rms-api';

import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
    ViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { trafficCrashByReportIdSelector } from '../../../traffic-crashes/state/data';
import { trafficCrashRoadwaysSelector } from '../data';
import { ModuleShape } from '../../../../utils/createNormalizedModule';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';

type TrafficCrashRoadwayViewModelProps = {
    gradeDirectionOfSlopeAttrId?: string;
    partOfNationalHighwaySystemAttrId?: string;
    roadwayFunctionalClassAttrId?: string;
    roadwayDirectionAttrId?: string;
    accessControlAttrId?: string;
    roadwayLightingAttrId?: string;
    longitudinalEdgelineTypeAttrId?: string;
    longitudinalCenterlineTypeAttrId?: string;
    longitudinalLaneLineMarkingsAttrId?: string;
    typeOfBicycleFacilityAttrId?: string;
    signedBicycleRouteAttrId?: string;
    mainlineNumLanesAtIntersectionAttrId?: string;
    crossStreetNumLanesAtIntersectionAttrId?: string;
};

export type TrafficCrashRoadwayViewModel = ViewModel<
    TrafficCrashRoadway,
    TrafficCrashRoadwayViewModelProps
>;

const trafficCrashRoadwayViewModelsSelector = createSelector(
    formatAttributeByIdSelector,
    trafficCrashRoadwaysSelector,
    (formatAttributeById, trafficCrashRoadways): ModuleShape<TrafficCrashRoadwayViewModel> => {
        const viewModel = buildViewModel<TrafficCrashRoadway, TrafficCrashRoadwayViewModelProps>({
            mappers: [allSingleAttributeValuesMapper, boolToDisplayMapper],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(trafficCrashRoadways, viewModel);
    }
);

export const trafficCrashRoadwayViewModelsByReportIdSelector = createSelector(
    trafficCrashRoadwayViewModelsSelector,
    trafficCrashByReportIdSelector,
    (trafficCrashRoadwayViewModels, trafficCrashByReportId) => (reportId: number) => {
        const trafficCrashId = trafficCrashByReportId(reportId)?.id;
        return !!trafficCrashId
            ? filter(
                  values(trafficCrashRoadwayViewModels),
                  (viewModel) => viewModel.crashId === trafficCrashId
              )
            : [];
    }
);

import { filter, isEmpty, noop } from 'lodash';
import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import { formDataIsEmpty } from '~/client-common/helpers/formHelpers';
import { formatRangeFieldValue } from '~/client-common/helpers/rangeHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';

const { ATTRIBUTE, RANGE, ROLE, USER } = fieldTypeClientEnum;
const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;
const { labels, dateOptions } = componentStrings.tasks.dashboard.TaskDashboardSearchForm;
const dateTypeLabels = {
    [dateTypeEnum.CREATED]: dateOptions.createdDate,
    [dateTypeEnum.MODIFIED]: dateOptions.updatedDate,
    [dateTypeEnum.DUE]: dateOptions.dueDate,
    [dateTypeEnum.COMPLETED]: dateOptions.statusDate,
};

export default function convertTasksDashboardSearchFormModelToFilterGroups(
    formModel = {},
    formatFieldValue = noop,
    boundSelectors
) {
    const parsedDaysUntilDue = parseInt(formModel.daysUntilTasksDue);
    const daysUntilTasksDueValue = Number.isFinite(parsedDaysUntilDue) ? parsedDaysUntilDue : undefined;

    const filterGroups = [
        !formDataIsEmpty(formModel.dateRange)
            ? {
                  path: 'dateRange',
                  label: dateTypeLabels[formModel.dateType],
                  display: formatRangeFieldValue(
                      formModel.dateRange,
                      [
                          {
                              key: 'withinLastPeriod',
                              type: RANGE,
                              rangeKey: DATE_TIME_RANGE,
                              rangeType: WITHIN_LAST_PERIOD,
                          },
                          {
                              key: 'toDatePeriod',
                              type: RANGE,
                              rangeKey: DATE_TIME_RANGE,
                              rangeType: TO_DATE_PERIOD,
                          },
                          {
                              key: 'startDateUtc',
                              type: RANGE,
                              rangeKey: DATE_TIME_RANGE,
                              rangeType: RANGE_START,
                          },
                          {
                              key: 'endDateUtc',
                              type: RANGE,
                              rangeKey: DATE_TIME_RANGE,
                              rangeType: RANGE_END,
                          },
                      ],
                      boundSelectors.dateTimeFormatter
                  ),
              }
            : undefined,
        {
            path: 'daysUntilTasksDue',
            label: labels.daysUntilTaskDue,
            display: formatFieldValue(daysUntilTasksDueValue),
        },
        {
            path: 'title',
            label: boundSelectors.formatFieldByName(fields.TASK_TITLE),
            display: formModel.title,
        },
        {
            path: 'priorityAttrIdsUntransformed',
            label: boundSelectors.formatFieldByName(fields.TASK_PRIORITY_ATTR_ID),
            display: formatFieldValue(formModel.priorityAttrIdsUntransformed, {
                type: ATTRIBUTE,
            }),
        },
        {
            path: 'statusAttrIdsUntransformed',
            label: boundSelectors.formatFieldByName(fields.TASK_STATUS_ATTR_ID),
            display: formatFieldValue(formModel.statusAttrIdsUntransformed, {
                type: ATTRIBUTE,
            }),
        },
        {
            path: 'isOverdue',
            label: labels.isOverdue,
            display: formatFieldValue(formModel.isOverdue),
        },
        {
            path: 'creatorUserIds',
            label: boundSelectors.formatFieldByName(fields.DISPLAY_ONLY_TASK_CREATED_BY),
            display: formatFieldValue(formModel.creatorUserIds, { type: USER }),
        },
        {
            path: 'assigneeRoleIds',
            label: boundSelectors.formatFieldByName(fields.TASK_ASSIGNEE_ROLE_ID),
            display: formatFieldValue(formModel.assigneeRoleIds, { type: ROLE }),
        },
        {
            path: 'typeAttrIdsUntransformed',
            label: boundSelectors.formatFieldByName(fields.TASK_TYPE_ATTR_ID),
            display: formatFieldValue(formModel.typeAttrIdsUntransformed, { type: ATTRIBUTE }),
        },
        {
            path: 'sourceNumbers',
            label: boundSelectors.formatFieldByName(fields.DISPLAY_ONLY_TASK_SOURCE_NUMBER),
            display: isEmpty(formModel.sourceNumbers) ? undefined : formModel.sourceNumbers,
        },
    ];

    return filter(filterGroups, 'display');
}

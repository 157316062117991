import React from 'react';
import { noop } from 'lodash';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { RRFTimePicker } from './TimePicker';
import FormElement from './FormElement';

const TimeRangeElementContainer = styled(FormElement)`
    max-width: 16rem;

    && {
        margin-bottom: 0;
    }

    .mark43-form-label {
        margin-bottom: -4px;
    }
`;

const TimeRangeDash = styled.div`
    float: left;
    display: flex;
    align-items: center;
    margin-top: 6px;
    height: 30px;
    margin-left: -7px;
`;

const RRFTimeRangeElement = ({ onChange = noop, label, ...restProps }) => (
    <TimeRangeElementContainer
        label={label || componentStrings.reports.dashboard.DateTimeFieldset.labels.shiftTimeRange}
        {...restProps}
    >
        <RRFTimePicker path="startTime" width={56} onChange={onChange} label={''} />
        <TimeRangeDash>-</TimeRangeDash>
        <RRFTimePicker path="endTime" width={56} onChange={onChange} />
    </TimeRangeElementContainer>
);

export default RRFTimeRangeElement;

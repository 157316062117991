import { chain, map, omit, keyBy, indexBy } from 'lodash';

/**
 * Convert the given data array into an object with a uniquely identifying key
 *   for each item.
 * @param  {Object[]} items
 * @param  {string}   [key='id']
 * @return {Object[]}
 */
export function normalize(items, key = 'id') {
    return (keyBy || indexBy)(items, key);
}

/**
 * The client-only property name used in `addTransientKeys` by default.
 * @type {Symbol}
 */
export const TRANSIENT_KEY = 'TRANSIENT_KEY';

/**
 * Add a unique property to each element of the given array. This is useful for
 *   data models that don't have unique ids. Keep these new properties in the
 *   client model only and remove them before making server requests with
 *   `removeTransientKeys`.
 * @param  {Object[]} items
 * @param  {string}   [key=TRANSIENT_KEY]
 * @return {Object[]}
 */
export function addTransientKeys(items, key = TRANSIENT_KEY) {
    return map(items, (item, index) => ({
        ...item,
        [key]: index,
    }));
}

/**
 * Remove the transient properties added with `addTransientKeys`.
 * @param  {Object[]} items
 * @param  {string}   [key=TRANSIENT_KEY]
 * @return {Object[]}
 */
export function removeTransientKeys(items, key = TRANSIENT_KEY) {
    return map(items, (item) => omit(item, key));
}

/**
 * Standard fields on versionable objects.  Fields map to
 * {@link /server-util/data/data-common/src/main/java/mark43/data/versioning/VersionableBase.java}
 * on the backend.  Both `id` and `departmentId` are added here because they are versionable base
 * fields as well, but appear in separate interfaces.
 */
export const versionableBaseFieldKeys = [
    'id',
    'departmentId',
    'rmsEventId',
    'createdBy',
    'createdDateUtc',
    'updatedBy',
    'updatedDateUtc',
];

export const reportVersionableBaseFieldKeys = [...versionableBaseFieldKeys, 'reportId'];

/**
 * Check if a report object is essentially "empty" with no meaningful user inputted data
 * @param  {Object} baseObject Object to check
 * @param  {String[]}  options.extraFields         Extra fields to exclude
 * @return {Boolean}                            Whether or not the object is empty
 */
export const objectOnlyHasMetadata = (baseObject, fieldKeys = []) =>
    chain(baseObject).omit(fieldKeys).isEmpty().value();

import * as React from 'react';
import DataLoader from 'dataloader';

import { ResolvedEntityDataLoaderResult, ReportOptionCoreEntityType } from '../rms-types';
import { createCoreEntityDataLoader } from '../utils/create-core-entity-data-loader';

export type DragonReportCoreEntityDataLoader = DataLoader<
    string,
    ResolvedEntityDataLoaderResult,
    number | string
>;
type DragonCoreEntityDataLoaderContextValue = Map<
    ReportOptionCoreEntityType,
    DragonReportCoreEntityDataLoader
>;

const DragonCoreEntityDataLoaderContext = React.createContext<
    DragonCoreEntityDataLoaderContextValue | undefined
>(undefined);

function useDragonReportCoreEntityDataLoaderContext(
    context: React.Context<DragonCoreEntityDataLoaderContextValue | undefined>
): DragonCoreEntityDataLoaderContextValue {
    const value = React.useContext(context);
    if (!value) {
        throw new Error(
            `Could not find Dragon vehicle options data loader in context. Please ensure that an instance of '${context.Provider.name}' has been rendered in the component hierarchy above.`
        );
    }

    return value;
}

/**
 * Context that initializes data loaders for all supported core entities and makes them available to downstream consumers
 */
export function DragonReportCoreEntityOptionsDataLoaderContextProvider({
    reportId,
    children,
}: React.PropsWithChildren<{
    reportId: number;
}>): JSX.Element {
    const dataLoader = React.useMemo(
        () =>
            // preemptively create data loaders for all supported core entities, even if they are not on a report. This really should not be
            // any problem, but if it turns out that this slows us down at some point, we can switch to lazily instantiating loaders when they are being accessed
            new Map(
                (['VEHICLE', 'COURT', 'FIREARM', 'ITEM_PROFILE'] as const).map(
                    (entityType) =>
                        [entityType, createCoreEntityDataLoader({ entityType, reportId })] as const
                )
            ),
        [reportId]
    );
    return (
        <DragonCoreEntityDataLoaderContext.Provider value={dataLoader}>
            {children}
        </DragonCoreEntityDataLoaderContext.Provider>
    );
}

/**
 * Provides an options dataloader for the given core entity type
 */
export function useDragonReportCoreEntityDataLoader(
    entityType: ReportOptionCoreEntityType
): DragonReportCoreEntityDataLoader {
    const dataLoader = useDragonReportCoreEntityDataLoaderContext(
        DragonCoreEntityDataLoaderContext
    ).get(entityType);
    if (!dataLoader) {
        throw new Error(
            `Unexpectedly did not find options data loader for entity type ${entityType}`
        );
    }

    return dataLoader;
}

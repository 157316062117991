import { chain } from 'lodash';

import type { PacketOption } from './exportHelpers';
import isFolderPacket from './isFolderPacket';

/**
 * Find all the folder ids within the given packets. The packets are not nested.
 */
export default function getFolderIdsInPackets(packetOptions: PacketOption[]): number[] {
    return chain(packetOptions)
        .filter((packetOption) => isFolderPacket(packetOption))
        .map('rawPacket.entityId')
        .uniq()
        .value();
}

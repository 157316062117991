import _ from 'lodash';
import { createSelector } from 'reselect';
import { DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE } from '../../../../enums/universal/fields';
import { formatFieldByNameSelector } from '../../../../fields/state/config';
import { prettify } from '../../../../../helpers/stringHelpers';
import { replaceTextIfEvidenceModuleNameIsNotEvidence } from '../../../../../helpers/evidenceModuleNameReplacementHelpers';
import { csvExportColumnsSelector } from '../data';

/**
 * All the CSV export columns in state are tranformed into checkbox tree options.
 *   and sorted by display and parentValue.
 * @type {Object[]}
 */
export const csvExportColumnOptionsSelector = createSelector(
    csvExportColumnsSelector,
    formatFieldByNameSelector,
    (csvExportColumns, formatFieldByName) =>
        _(csvExportColumns)
            .map(({ id, displayName, groupName }) => {
                // This is a temporary solution for RMS-15751
                // to replace the groupName with "Evidence" string
                // as configured value under field name DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE.
                // We might make all the groupName configurable in the future,
                // and then remove this patch replacement.

                const prettifiedEvidenceModuleName = prettify(
                    formatFieldByName(DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE)
                );
                // currently find one groupName === 'Property & Evidence Data'
                const temporarySolutionGroupName = replaceTextIfEvidenceModuleNameIsNotEvidence(
                    groupName,
                    prettifiedEvidenceModuleName
                );

                return {
                    value: id,
                    parentValue: temporarySolutionGroupName,
                    display: displayName,
                };
            })
            .sortBy('display')
            .sortBy('parentValue')
            .value()
);

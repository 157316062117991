import React, { useState } from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { CollapsibleSectionWithStickyHeader } from '../../../core/components/CollapsibleSectionWithStickyHeader';
import CaseNotesTable from './CaseNotesTable';

const strings = componentStrings.cases.caseSummary.CaseNotes;

export default function CaseNotes({ caseNotes }) {
    const [isSectionOpen, toggleSectionOpen] = useState(true);

    return (
        <CollapsibleSectionWithStickyHeader
            isOpen={isSectionOpen}
            title={strings.title}
            description=""
            containerSelector=".case-content-container"
            toggleOpen={toggleSectionOpen}
        >
            <CaseNotesTable caseNotes={caseNotes} />
        </CollapsibleSectionWithStickyHeader>
    );
}

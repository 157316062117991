import React, { useCallback, useRef, useMemo } from 'react';
import { isNil, map, xorWith, isEqual, find } from 'lodash';
import { EntityTypeEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import { Avatar, Checkbox, HStack, VStack, DataList, DataItem } from 'arc';
import { TableColumn } from 'components-mark43';
import { InjectedRouter, withRouter } from 'react-router';
import { EFileInvolvedProfileGridRowT, EFileLinkTypesT } from '../../../../types';
import { useEFileContext } from '../../../..';
import { ENTITY_TO_ICON_MAP } from '../../../../constants';
import { PopoutLink as _PopoutLink } from '../../../../../core/components/links/Link';
import { IceCreamMenu } from '../IceCreamMenu';
import { EFileTableBodyRow } from '../EFileTableBodyRow';
import { EFilePersonLabels } from './EFilePersonLabels';

type RenderBodyRowT<T> = {
    index: number;
    item: T;
    linkType: EFileLinkTypesT;
    router: InjectedRouter;
};

const NameVStack = styled(VStack)`
    max-width: 225px;
`;

const NameDataColumn = styled(TableColumn)`
    width: 244px;
`;

const PopoutLink = styled(_PopoutLink)`
    font-size: var(--arc-fontSizes-sm);
`;

const IdentifiersTableColumn = styled(TableColumn)`
    width: 130px;
`;

const LocationDataTableColumn = styled(TableColumn)`
    width: 147px;
    padding-left: 5px;
`;

const OffensesTableColumn = styled(TableColumn)`
    width: 155px;
`;

const ActionsTableColumn = styled(TableColumn)`
    width: 25px;
    margin-right: 3px;
`;

const CheckboxTableColumn = styled(TableColumn)`
    margin: 0 10px;
`;

export const EFileInvolvedProfilesGridRows = withRouter(
    ({ item, linkType, router }: RenderBodyRowT<EFileInvolvedProfileGridRowT>) => {
        const iceCreamMenuRef = useRef<HTMLDivElement | null>(null);
        const {
            actions: {
                grid: { setEFileSelectedInvolvedProfiles },
            },
            getters: {
                efile: { getEFileViewModel },
                grid: { getEFileSelectedInvolvedProfiles },
            },
        } = useEFileContext();

        const eFileViewModel = getEFileViewModel();

        if (!eFileViewModel) {
            return null;
        }

        const isPerson = item.nameData.entityType === EntityTypeEnum.PERSON_PROFILE.name;

        const entityPageLink = isPerson
            ? `/profiles/persons/${item.nameData.entityId}/details`
            : `/profiles/organizations/${item.nameData.entityId}/details`;

        const onClick = useCallback(() => {
            // Only navigate to details page if not clicking on ice cream menu (ie. ice cream menu not on DOM)
            if (isNil(iceCreamMenuRef.current)) {
                const defendantDetailsRoute =
                    item.nameData.entityType === EntityTypeEnum.PERSON_PROFILE.name
                        ? 'person'
                        : 'organization';
                if (!item.isDeactivated) {
                    router.push(
                        `e-file/${eFileViewModel.id}/defendants/${item.nameData.entityId}/${defendantDetailsRoute}`
                    );
                }
            }
        }, [
            eFileViewModel.id,
            item.isDeactivated,
            item.nameData.entityId,
            item.nameData.entityType,
            router,
        ]);

        const transformedInvolvedProfile = useMemo(() => {
            return {
                entityId: item.nameData.entityId,
                entityType: item.nameData.entityType,
            };
        }, [item]);

        const checkBoxOnChange = useCallback(() => {
            const selectedProfiles = getEFileSelectedInvolvedProfiles();

            setEFileSelectedInvolvedProfiles(
                xorWith(selectedProfiles, [transformedInvolvedProfile], isEqual)
            );
        }, [
            getEFileSelectedInvolvedProfiles,
            setEFileSelectedInvolvedProfiles,
            transformedInvolvedProfile,
        ]);

        return (
            <EFileTableBodyRow
                onClick={onClick}
                key={item.eFileLinkId}
                isDeactivated={item.isDeactivated}
                linkType={linkType}
            >
                <CheckboxTableColumn>
                    <Checkbox
                        isDisabled={!eFileViewModel.canEdit}
                        isChecked={
                            !!find(getEFileSelectedInvolvedProfiles(), transformedInvolvedProfile)
                        }
                        onChange={checkBoxOnChange}
                        onClickCapture={(e) => e.stopPropagation()}
                    />
                </CheckboxTableColumn>
                <NameDataColumn>
                    <HStack alignItems="flex-start">
                        <Avatar
                            src={item.nameData.imageUrl}
                            icon={ENTITY_TO_ICON_MAP[item.nameData.entityType]}
                            size="md"
                        />
                        <NameVStack alignItems="flex-start">
                            <PopoutLink
                                to={entityPageLink}
                                onClickCapture={(e) => e.stopPropagation()}
                            >
                                {item.nameData.name}
                            </PopoutLink>
                            {isPerson && <EFilePersonLabels personId={item.nameData.entityId} />}
                        </NameVStack>
                    </HStack>
                </NameDataColumn>
                <IdentifiersTableColumn>
                    <DataList>
                        {map(item.identifiers, (identifier, index) => {
                            return (
                                <DataItem key={index} dataKey={identifier.label}>
                                    {identifier.value}
                                </DataItem>
                            );
                        })}
                    </DataList>
                </IdentifiersTableColumn>
                <LocationDataTableColumn>
                    <DataList>
                        {map(item.locationData, (location, index) => {
                            return (
                                <DataItem key={index} dataKey={location.label}>
                                    {location.value}
                                </DataItem>
                            );
                        })}
                    </DataList>
                </LocationDataTableColumn>
                {/* Offense - Phase 2 */}
                <OffensesTableColumn />
                <ActionsTableColumn>
                    <IceCreamMenu
                        ref={iceCreamMenuRef}
                        isDeactivated={item.isDeactivated}
                        eFileId={eFileViewModel.id}
                        eFileLinkId={item.eFileLinkId}
                        contextedProfileId={item.nameData.entityId}
                        linkType={linkType}
                    />
                </ActionsTableColumn>
            </EFileTableBodyRow>
        );
    }
);

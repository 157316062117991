import React, { useContext, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';

import { Draggable, Droppable } from 'react-beautiful-dnd';
import { EntityTypeEnum } from '@mark43/rms-api';
import { map, orderBy, find, parseInt, isEmpty, first, filter } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { FormattedDate } from '~/client-common/core/dates/components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { folderContentViewByIdSelector } from '~/client-common/core/domain/folder-content-views/state/data';
import { topLevelFoldersByOwnerIdSelector } from '~/client-common/core/domain/folders/state/data';

import LegacyButton, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import testIds from '../../../../core/testIds';
import { iconTypes } from '../../../core/components/Icon';
import {
    DraggableCloneWrapper,
    DraggableClone,
    DroppablePlaceHolderWrapper,
    DraggableWrapper,
} from '../../core/components/drag-and-drop';
import {
    currentCaseSelector,
    currentCaseNotesByFolderIdSelector,
    canModifyCaseNoteForCurrentCaseInFolderSelector,
    canCurrentUserEditCurrentCaseNotesSelector,
    canCurrentUserEditCurrentCaseSelector,
} from '../../core/state/ui';
import { currentUserIdSelector } from '../../../core/current-user/state/ui';

import { openErrorModal } from '../../../../legacy-redux/actions/boxActions';
import { addNoteToCase } from '../state/data';
import { Button as _Button } from '../../../core/components/Button';
import KebabMenu from '../../core/components/KebabMenu';
import { getCaseFolderPath } from '../../core/utils/getCaseFolderPath';
import { DraggingIdContext, SelectedRowsContext } from '../../core/contexts';
import {
    createFolderDroppableId,
    createCaseNoteDroppableId,
    getDragStyle,
} from '../../core/utils/dragAndDropHelpers';
import {
    kebabCanCreateNewFolder,
    kebabCanDelete,
    kebabCanRename,
    kebabCanMoveFromFolder,
} from '../../core/utils/kebabMenuHelpers';
import { downloadContent } from '../../core/state/data/folderContents';
import CaseRowCheckbox from '../../core/components/CaseRowCheckbox';

import CaseNoteEditor from './CaseNoteEditor';
import ReadonlyCaseNote from './ReadonlyCaseNote';
import CaseNoteFolderRow from './CaseNotesFolderRow';

function stripHTML(dirtyString) {
    const container = document.createElement('div');
    container.innerHTML = dirtyString;
    return container.textContent || container.innerText;
}

const strings = componentStrings.cases.caseSummary.CaseNotes;

const AddButton = styled(LegacyButton)`
    margin: 6px 12px;
    padding: 10px 8px;
`;

const NotesListBody = styled.div`
    margin-top: ${(props) => (props.caseFolderingEnabled ? '60px' : 0)};
    overflow: auto;
    height: ${(props) => (props.caseFolderingEnabled ? 'calc(100% - 60px)' : '100%')};
`;
const NotesListWrapper = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 292px;
    background-color: ${(props) => props.theme.colors.white};
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const NotesListStickyHeader = styled.div`
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    width: 292px;
    height: 61px;
    background: ${(props) => props.theme.colors.white};
    border-width: 1px;
    border-style: none solid solid none;
    border-color: ${(props) => props.theme.colors.lightGrey};
    z-index: 10;
    padding: 16px 9px 14px 24px;
`;

const Note = styled.div`
    display: flex;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 12px 18px;
    cursor: pointer;
    font-size: var(--arc-fontSizes-sm);
    background-color: ${(props) =>
        props.active ? props.theme.colors.lightBlue : props.theme.colors.white};
    border-left: 6px solid
        ${(props) => (props.focused ? props.theme.colors.cobaltBlue : props.theme.colors.white)};

    &:hover {
        border-left: 6px solid ${(props) => props.theme.colors.cobaltBlue};
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

const NoteTitle = styled.div`
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-weight: 600;
`;

const NoteDetails = styled.div`
    margin: 6px 0;
`;

const NoteSummary = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-sm);
    margin-bottom: 6px;
`;

const NoteEditorContainer = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 292px;
    width: 512px;
    background-color: ${(props) => props.theme.colors.white};
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const NotesActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
`;

const Button = styled(_Button)`
    margin-left: 8px;
`;

const CaseNoteDraggableWrapper = styled(DraggableWrapper)`
    height: 100px;
`;

/**
 * The case notes page displays notes and allows the user to add/edit/delete
 *   notes.
 */
const CaseNotes = ({ router, params, currentCase, currentUserId, downloadSelected }) => {
    const folderContentViewById = useSelector(folderContentViewByIdSelector);
    const topLevelFoldersByOwnerId = useSelector(topLevelFoldersByOwnerIdSelector);
    const currentCaseNotesByFolderId = useSelector(currentCaseNotesByFolderIdSelector);

    const canModifyCaseNoteForCurrentCaseInFolder = useSelector(
        canModifyCaseNoteForCurrentCaseInFolderSelector
    );
    const canCurrentUserEditCases = useSelector(canCurrentUserEditCurrentCaseNotesSelector);
    const canCurrentUserEditCurrentCase = useSelector(canCurrentUserEditCurrentCaseSelector);

    const applicationSettings = useSelector(applicationSettingsSelector);

    const selectedNoteId = parseInt(params.noteId);
    const folderId = parseInt(params.folderId);
    const isTopLevel = !params.folderId;

    const currentCaseNotes = currentCaseNotesByFolderId(Number(params.folderId));

    const manageableCaseNotes = filter(currentCaseNotes, (caseNote) => {
        return canModifyCaseNoteForCurrentCaseInFolder(caseNote.id, folderId);
    });

    const sortedNotes = orderBy(currentCaseNotes, 'createdDateUtc', 'desc');
    const selectedCaseNote = find(currentCaseNotes, { id: selectedNoteId });
    const hasPermissionForCaseNote = canModifyCaseNoteForCurrentCaseInFolder(
        selectedNoteId,
        folderId
    );

    const dispatch = useDispatch();

    const {
        selectedRows,
        areAllElementsSelected,
        handleRowSelect,
        isRowSelected,
        selectAllClick,
    } = useContext(SelectedRowsContext);

    const { draggingId } = useContext(DraggingIdContext);

    // Go to the first note if one exists and it a note has not been selected yet.

    useEffect(() => {
        if (
            applicationSettings.RMS_CASE_FOLDERING_ENABLED &&
            sortedNotes &&
            sortedNotes.length > 0 &&
            !selectedNoteId
        ) {
            const firstCaseNote = first(sortedNotes);
            navigateToCaseNote(firstCaseNote.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedNotes]);

    if (!currentCase) {
        return null;
    }

    const navigateToCaseNote = (caseNoteId) => {
        const notesPath = getCaseFolderPath({
            caseId: currentCase.id,
            entityType: EntityTypeEnum.CASE_NOTE.name,
            folderId,
            noteId: caseNoteId,
        });

        router.push(notesPath);
    };

    const createCaseNote = () => {
        const newNote = {
            caseId: currentCase.id,
            title: 'Untitled',
            author: currentUserId,
            content: '',
        };
        dispatch(addNoteToCase(newNote))
            .then((newCaseNote) => {
                navigateToCaseNote(newCaseNote.id);
            })
            .catch((error) => {
                dispatch(
                    openErrorModal({
                        title: strings.errorFailedToSaveCaseNotePleaseTryAgain.title,
                        paragraphs: [error && error.message],
                    })
                );
            });
    };

    const createCaseNoteAndAddToFolder = () => {
        const newNote = {
            caseId: currentCase.id,
            title: 'Untitled',
            author: currentUserId,
            content: '',
        };

        dispatch(addNoteToCase(newNote, Number(params.folderId)))
            .then((newCaseNote) => {
                navigateToCaseNote(newCaseNote.id);
            })
            .catch((error) => {
                dispatch(
                    openErrorModal({
                        title: strings.errorFailedToSaveCaseNotePleaseTryAgain.title,
                        paragraphs: [error && error.message],
                    })
                );
            });
    };

    let foldersToRender = [];
    if (folderId) {
        const folderContentView = folderContentViewById(folderId);
        foldersToRender = folderContentView ? folderContentView?.directSubFolders : [];
    } else {
        const topLevelFolders = topLevelFoldersByOwnerId(
            parseInt(currentCase?.id),
            EntityTypeEnum.CASE_NOTE.name
        );
        if (topLevelFolders) {
            foldersToRender = topLevelFolders;
        }
    }

    return (
        <>
            <NotesListWrapper data-test-id={testIds.CASE_NOTES_LIST}>
                <FeatureFlagged flag="RMS_CASE_FOLDERING_ENABLED">
                    <NotesListStickyHeader>
                        <CaseRowCheckbox
                            value={areAllElementsSelected({
                                elements: [...currentCaseNotes, ...foldersToRender],
                                idKey: 'id',
                            })}
                            onChange={() => {
                                selectAllClick({
                                    elements: manageableCaseNotes,
                                    idKey: 'id',
                                    folders: foldersToRender,
                                    entityType: EntityTypeEnum.CASE_NOTE.name,
                                });
                            }}
                            entityType={EntityTypeEnum.CASE_NOTE.name}
                        />
                        <NotesActionWrapper>
                            <KebabMenu
                                folderId={parseInt(params.folderId)}
                                entityTypeId={EntityTypeEnum.CASE_NOTE.name}
                                disabled={!canCurrentUserEditCurrentCase}
                                downloadSelected={downloadSelected}
                                canRename={kebabCanRename({
                                    canCurrentUserEditCurrentCase,
                                    selectedRows,
                                })}
                                canDelete={kebabCanDelete({
                                    canCurrentUserEditCurrentCase,
                                    selectedRows,
                                })}
                                canRemoveFromFolder={kebabCanMoveFromFolder({
                                    canCurrentUserEditCurrentCase,
                                    selectedRows,
                                    entityType: EntityTypeEnum.CASE_NOTE.name,
                                    isTopLevel,
                                })}
                                canCreateNewFolder={kebabCanCreateNewFolder({
                                    canCurrentUserEditCurrentCase,
                                    entityType: EntityTypeEnum.CASE_NOTE.name,
                                })}
                            />
                            <Button
                                className={buttonTypes.PRIMARY}
                                onClick={createCaseNoteAndAddToFolder}
                                leftIcon="Add"
                                variant="solid"
                                disabled={!canCurrentUserEditCases}
                                testId={testIds.CASE_NOTE_ADD}
                            >
                                {strings.addNote}
                            </Button>
                        </NotesActionWrapper>
                    </NotesListStickyHeader>
                </FeatureFlagged>
                <NotesListBody
                    caseFolderingEnabled={applicationSettings.RMS_CASE_FOLDERING_ENABLED}
                >
                    <FeatureFlagged
                        flag="RMS_CASE_FOLDERING_ENABLED"
                        fallback={
                            canCurrentUserEditCases && (
                                <AddButton
                                    onClick={createCaseNote}
                                    iconLeft={iconTypes.ADD}
                                    className={buttonTypes.ICON_LINK}
                                    testId={testIds.CASE_NOTE_ADD}
                                >
                                    {strings.addNote}
                                </AddButton>
                            )
                        }
                    />
                    {map(sortedNotes, (caseNote, index) => {
                        const { id: caseNoteId, title } = caseNote;
                        const summary = stripHTML(caseNote.content);
                        const isActive = applicationSettings.RMS_CASE_FOLDERING_ENABLED
                            ? isRowSelected(caseNoteId)
                            : selectedNoteId === caseNoteId;

                        return (
                            <Droppable
                                droppableId={createCaseNoteDroppableId(caseNoteId)}
                                renderClone={(provided) => (
                                    <DraggableCloneWrapper
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >
                                        <DraggableClone displayName={title} />
                                    </DraggableCloneWrapper>
                                )}
                                isDropDisabled={true}
                                key={`droppable-casenote-${caseNoteId}`}
                            >
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <Draggable
                                            draggableId={`${caseNoteId}`}
                                            index={index}
                                            key={`draggable-attachment-${caseNoteId}`}
                                            isDragDisabled={!isRowSelected(caseNoteId)}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getDragStyle(
                                                        provided.draggableProps,
                                                        snapshot
                                                    )}
                                                >
                                                    <Note
                                                        key={caseNote.id}
                                                        active={isActive}
                                                        focused={selectedNoteId === caseNote.id}
                                                        onClick={() =>
                                                            navigateToCaseNote(caseNote.id)
                                                        }
                                                    >
                                                        <FeatureFlagged flag="RMS_CASE_FOLDERING_ENABLED">
                                                            <div>
                                                                <CaseRowCheckbox
                                                                    value={isRowSelected(
                                                                        caseNote.id
                                                                    )}
                                                                    onChange={() =>
                                                                        handleRowSelect(
                                                                            caseNote.id,
                                                                            isRowSelected(
                                                                                caseNote.id
                                                                            ),
                                                                            false,
                                                                            true,
                                                                            EntityTypeEnum.CASE_NOTE
                                                                                .name
                                                                        )
                                                                    }
                                                                    entityType={
                                                                        EntityTypeEnum.CASE_NOTE
                                                                            .name
                                                                    }
                                                                    caseNoteId={caseNoteId}
                                                                    folderId={folderId}
                                                                />
                                                            </div>
                                                        </FeatureFlagged>
                                                        <div
                                                            data-test-id={testIds.CASE_NOTE_SUMMARY}
                                                        >
                                                            <NoteTitle>
                                                                {caseNote.title || '--'}
                                                            </NoteTitle>
                                                            <NoteDetails>
                                                                by{' '}
                                                                <ConnectedFormattedUser
                                                                    userId={caseNote.author} // why is this not authorId?
                                                                    format={
                                                                        FORMATS.FULL_NAME_WITH_FIRST_INITIAL_AND_ID_NUMBER
                                                                    }
                                                                />{' '}
                                                                on{' '}
                                                                <FormattedDate
                                                                    date={caseNote.createdDateUtc}
                                                                    format={
                                                                        FormattedDate.FORMATS
                                                                            .TABLE_DATE_TIME
                                                                    }
                                                                />
                                                            </NoteDetails>
                                                            <NoteSummary>
                                                                {summary
                                                                    ? summary.substring(0, 120)
                                                                    : strings.noteIsEmpty}
                                                            </NoteSummary>
                                                        </div>
                                                    </Note>
                                                </div>
                                            )}
                                        </Draggable>
                                        <DroppablePlaceHolderWrapper
                                            isDragging={draggingId === caseNoteId}
                                        >
                                            {provided.placeholder}
                                        </DroppablePlaceHolderWrapper>
                                    </div>
                                )}
                            </Droppable>
                        );
                    })}
                    {!isEmpty(foldersToRender) &&
                        map(foldersToRender, (folder, index) => {
                            const folderId = folder.id;
                            const folderName = folder.name;
                            return (
                                <Droppable
                                    droppableId={createFolderDroppableId(folderId, folderName)}
                                    renderClone={(provided) => (
                                        <DraggableCloneWrapper
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <DraggableClone
                                                displayName={folder.name}
                                                iconType={iconTypes.CASE}
                                            />
                                        </DraggableCloneWrapper>
                                    )}
                                    key={`case-note-droppable-folder-${folderId}`}
                                >
                                    {(provided, dropSnapshot) => (
                                        <CaseNoteDraggableWrapper
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            isDraggingOver={dropSnapshot.isDraggingOver}
                                        >
                                            <Draggable
                                                draggableId={`${folderId}`}
                                                index={index}
                                                key={`draggable-folder${folderId}`}
                                                isDragDisabled={!isRowSelected(folderId)}
                                            >
                                                {(dragProvided, snapshot) => (
                                                    <div
                                                        ref={dragProvided.innerRef}
                                                        {...dragProvided.draggableProps}
                                                        {...dragProvided.dragHandleProps}
                                                        style={getDragStyle(
                                                            dragProvided.draggableProps,
                                                            snapshot
                                                        )}
                                                    >
                                                        <CaseNoteFolderRow
                                                            folder={folder}
                                                            currentCaseId={currentCase.id}
                                                            isDraggingOver={
                                                                dropSnapshot.isDraggingOver
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                            <DroppablePlaceHolderWrapper
                                                isDragging={draggingId === folderId}
                                            >
                                                {provided.placeholder}
                                            </DroppablePlaceHolderWrapper>
                                        </CaseNoteDraggableWrapper>
                                    )}
                                </Droppable>
                            );
                        })}
                </NotesListBody>
            </NotesListWrapper>
            <NoteEditorContainer data-test-id={testIds.CASE_NOTE_CONTAINER}>
                {selectedCaseNote &&
                    (hasPermissionForCaseNote ? (
                        <CaseNoteEditor
                            key={selectedCaseNote.id}
                            caseNote={selectedCaseNote}
                            folderId={Number(params.folderId)}
                        />
                    ) : (
                        <ReadonlyCaseNote caseNote={selectedCaseNote} />
                    ))}
            </NoteEditorContainer>
        </>
    );
};

// these are imported because the observable doesn't get set otherwise
const mapStateToProps = createStructuredSelector({
    currentCase: currentCaseSelector,
    currentUserId: currentUserIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    downloadSelected: (currentFolderId, selectedRows) => {
        dispatch(downloadContent(currentFolderId, selectedRows));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseNotes);

import {
    EntityTypeEnum,
    ChainEventCategoryEnum,
    FileTypeEnum,
    StorageLocationTypeEnum,
    LinkTypesEnum,
    OperationTypeEnum,
} from '@mark43/evidence-api';

import { get, map, reduce, sortBy, difference } from 'lodash';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import styled from 'styled-components';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { chainEventTypesSelector } from '~/client-common/core/domain/chain-event-types/state/data';

import { storageLocationViewModelByIdSelector } from '~/client-common/core/domain/storage-locations/state/ui';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields, { useEvidenceModuleName } from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';

import Row from '../../../core/components/Row';
import { InlineBanner } from '../../../core/components/InlineBanner';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFChainEventTypeSelect } from '../../../core/forms/components/selects/ChainEventTypeSelect';
import { RRFFacilitySelect } from '../../../core/forms/components/selects/FacilitySelect';
import { RRFStorageLocationSelect } from '../../../core/forms/components/selects/StorageLocationSelect';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFTextArea } from '../../../core/forms/components/TextArea';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { RRFUpload } from '../../../core/forms/components/Upload';
import { RRFSignaturePad } from '../../signature-pad';
import { RRFButtonRadio } from '../../../core/forms/components/ButtonRadio';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import _ChainOfCustodyPdfLink from '../../chain-of-custody/components/ChainOfCustodyPdfLink';
import InlineAttachmentsUploader from '../../../attachments/core/components/InlineAttachmentsUploader';

import createChainEventsForm from '../state/forms/createChainEventsForm';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import {
    itemQueueViewModelsSelector,
    subsequentChainEventTypeIdsSelector,
    createChainEventsPanelIsSubmittingSelector,
    createChainEventsPanelErrorMessageSelector,
    itemQueueCountSelector,
} from '../state/ui';
import { CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH } from '../configuration';
import testIds from '../../../../core/testIds';

const { withRRFActions, withRRFFieldValues } = reactReduxFormHelpers;
const { evidenceFacilityGlobal } = globalAttributes;
const strings = componentStrings.evidence.itemQueue.CreateChainEventsForm;
const internalFacilityFilter = [
    evidenceFacilityGlobal.internal,
    evidenceFacilityGlobal.warehouse,
    evidenceFacilityGlobal.temporary,
];
const externalFacilityFilter = [evidenceFacilityGlobal.external, evidenceFacilityGlobal.lab];
const permanentStorageLocationFilter = [StorageLocationTypeEnum.PERMANENT.name];
const temporaryStorageLocationFilter = [StorageLocationTypeEnum.TEMPORARY.name];

const ChainEventTypeRow = compose(
    connect(
        createStructuredSelector({
            chainEventTypes: chainEventTypesSelector,
        })
    ),
    withRRFActions,
    withRRFFieldValues({ eventTypeId: 'eventTypeId' }),
    withHandlers({
        clearConditionalFields({ chainEventTypes, eventTypeId, formActions }) {
            return (newEventTypeId) => {
                // when the selected event type is changed, reset all fields
                // that depend on the event type
                if (eventTypeId !== newEventTypeId) {
                    formActions.reset('facilityId');
                    formActions.reset('storageLocationId');
                    formActions.reset('receivedByEntityId');
                    formActions.reset('receivedByName');

                    const chainEventType = chainEventTypes[newEventTypeId] || {};

                    if (
                        chainEventType.chainEventCategory ===
                            ChainEventCategoryEnum.CHECKED_OUT_PERSON.name ||
                        chainEventType.chainEventCategory ===
                            ChainEventCategoryEnum.INFIELD_TRANSFER.name
                    ) {
                        // set the initial value of receivedByEntityType because
                        // it determines whether 1 of 2 other fields appears
                        // (receivedByEntityId or receivedByName)
                        formActions.change('receivedByEntityType', EntityTypeEnum.USER.name);
                        // reset form ui state
                        formActions.setInitial('receivedByEntityType');
                        // TODO: hardcoded validation, delete after KRA-813
                        formActions.setValidity('receivedByEntityId', {
                            requiredError: false,
                        });
                    } else {
                        formActions.reset('receivedByEntityType');
                    }

                    // clear any uploaded file(s) from this form if upload(s)
                    // are not required for the newly selected chain event type
                    if (!chainEventType.requiresSignature) {
                        formActions.reset('signatureFileId');
                    }
                    if (!chainEventType.requiresIDScan) {
                        formActions.reset('idScanFileId');
                    }
                }
            };
        },
    })
)(function ChainEventTypeRow({ disabled, eventTypeIdFilter, clearConditionalFields }) {
    return (
        <Row>
            <RRFChainEventTypeSelect
                disabled={disabled}
                path="eventTypeId"
                idFilter={eventTypeIdFilter}
                width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                onChange={clearConditionalFields}
            />
        </Row>
    );
});

const checkedOutPersonOrInFieldTransferEntityTypeOptions = [
    { value: EntityTypeEnum.USER.name, display: 'Mark43 User' },
    { value: null, display: 'External Person' },
];

const ConditionalRows = compose(
    connect(
        createStructuredSelector({
            chainEventTypes: chainEventTypesSelector,
            itemQueueViewModels: itemQueueViewModelsSelector,
            storageLocationViewModelById: storageLocationViewModelByIdSelector,
            submitting: createChainEventsPanelIsSubmittingSelector,
        })
    ),
    withRRFActions,
    withRRFFieldValues({
        eventTypeId: 'eventTypeId',
        receivedByEntityType: 'receivedByEntityType',
        receivedByEntityId: 'receivedByEntityId', // TODO: delete after KRA-813
    }),
    withHandlers({
        clearReceivedBy({ formActions, receivedByEntityType, receivedByEntityId }) {
            return () => {
                // when the selected "received by" entity type is changed, reset
                // the other "received by" fields which depend on it
                formActions.reset('receivedByEntityId');
                formActions.reset('receivedByName');
                // TODO: delete after KRA-813
                formActions.setValidity('receivedByEntityId', {
                    requiredError:
                        receivedByEntityType === EntityTypeEnum.USER.name && !receivedByEntityId,
                });
            };
        },
    })
)(function ConditionalRows({
    chainEventTypes,
    eventTypeId,
    receivedByEntityType,
    itemQueueViewModels,
    storageLocationViewModelById,
    clearReceivedBy,
}) {
    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];
    switch (get(chainEventTypes[eventTypeId], 'chainEventCategory')) {
        case ChainEventCategoryEnum.CHECKED_IN_MAIN.name:
            return (
                <Row>
                    <RRFStorageLocationSelect
                        path="storageLocationId"
                        isExpired={false}
                        storageLocationTypeFilter={permanentStorageLocationFilter}
                        evidenceFacilityGlobalAttrIdFilter={internalFacilityFilter}
                        width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                        maxMenuHeight={350}
                        requiredPermissions={[OperationTypeEnum.MANAGE.name]}
                    />
                </Row>
            );
        case ChainEventCategoryEnum.CHECKED_IN_TEMP.name:
            return (
                <Row>
                    <RRFStorageLocationSelect
                        path="storageLocationId"
                        isExpired={false}
                        storageLocationTypeFilter={temporaryStorageLocationFilter}
                        evidenceFacilityGlobalAttrIdFilter={internalFacilityFilter}
                        width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                        maxMenuHeight={350}
                        requiredPermissions={[OperationTypeEnum.MANAGE.name]}
                    />
                </Row>
            );
        case ChainEventCategoryEnum.CHECKED_OUT_LAB.name:
            return (
                <Row>
                    <RRFFacilitySelect
                        path="facilityId"
                        evidenceFacilityGlobalAttrIdFilter={externalFacilityFilter}
                        width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                        requiredPermissions={OperationTypeEnum.MANAGE.name}
                    />
                </Row>
            );
        case ChainEventCategoryEnum.CHECKED_OUT_PERSON.name:
        case ChainEventCategoryEnum.INFIELD_TRANSFER.name:
            return (
                <div>
                    <Row>
                        <RRFButtonRadio
                            path="receivedByEntityType"
                            options={checkedOutPersonOrInFieldTransferEntityTypeOptions}
                            onChange={clearReceivedBy}
                            gutterWidth={0}
                        />
                    </Row>
                    <Row className="indented">
                        {receivedByEntityType === EntityTypeEnum.USER.name ? (
                            <RRFUserSelect
                                path="receivedByEntityId"
                                width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH - 15}
                            />
                        ) : (
                            <RRFText
                                path="receivedByName"
                                label={strings.receivedByPersonName}
                                width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH - 15}
                            />
                        )}
                    </Row>
                </div>
            );
        case ChainEventCategoryEnum.TRANSFERRED.name:
            return (
                <Row>
                    <RRFText
                        path="receivedByName"
                        label={strings.transferredReceivedByName}
                        width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                    />
                </Row>
            );
        case ChainEventCategoryEnum.LOCATION_UPDATED.name:
            // the selectable storage locations must be in the same facility, so
            // first determine the items' current facility ids
            const currentFacilityIds = map(itemQueueViewModels, (itemQueueViewModel) => {
                const storageLocationId = get(
                    itemQueueViewModel,
                    'latestChainEvent.storageLocationId'
                );
                const storageLocationViewModel = storageLocationViewModelById(storageLocationId);
                return get(storageLocationViewModel, 'facilityId');
            });

            // the facility ids must all be identical
            const currentFacilityId = reduce(
                currentFacilityIds,
                (id, id2) => {
                    return id === id2 ? id : null;
                },
                currentFacilityIds[0]
            );

            if (!currentFacilityId) {
                return <InlineBanner status="error">{strings.notInSameFacility}</InlineBanner>;
            }

            return (
                <Row>
                    <RRFStorageLocationSelect
                        path="storageLocationId"
                        isExpired={false}
                        storageLocationTypeFilter={permanentStorageLocationFilter}
                        facilityIdFilter={currentFacilityId}
                        width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                        maxMenuHeight={350}
                        requiredPermissions={[OperationTypeEnum.MANAGE.name]}
                    />
                </Row>
            );
        case ChainEventCategoryEnum.RELEASED.name:
            return (
                <Row>
                    <RRFText
                        path="receivedByName"
                        label={strings.releasedReceivedByName(custodyLabel)}
                        width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                    />
                </Row>
            );
        default:
            return <div />;
    }
});

const ImagePreview = styled.img`
    width: ${CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}px;
`;
const ChainOfCustodyPdfLink = styled(_ChainOfCustodyPdfLink)`
    float: left;
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const _IDScanRow = compose(
    withRRFActions,
    withRRFFieldValues({ eventTypeId: 'eventTypeId' }),
    withState('file', 'setFile', {}),
    withState('showImagePreview', 'setShowImagePreview', false),
    withState('showPdfButton', 'setShowPdfButton', false),
    withHandlers({
        onSuccess: ({ setFile, setShowImagePreview, setShowPdfButton }) => (file) => {
            // TODO: handle non-image, non-pdf file types
            if (file.fileType === FileTypeEnum.PDF.name) {
                setShowPdfButton(true);
            } else {
                setShowImagePreview(true);
            }
            setFile(file);
        },
        onRemove: ({ setFile, setShowPdfButton, setShowImagePreview }) => () => {
            setFile({});
            setShowPdfButton(false);
            setShowImagePreview(false);
        },
    })
)(function IDScanRow({
    path,
    file: { fileWebServerPath, originalFileName },
    showImagePreview,
    showPdfButton,
    attachLabel,
    label,
    onSuccess,
    onRemove,
    className,
}) {
    return (
        <Row className={className}>
            <div className="mark43-form-label mark43-form-row-label">{label}</div>
            {showImagePreview && <ImagePreview src={fileWebServerPath} />}
            {showPdfButton && (
                <ChainOfCustodyPdfLink
                    filePath={fileWebServerPath}
                    fileName={originalFileName}
                    width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                />
            )}
            {(showImagePreview || showPdfButton) && (
                <Button
                    className={buttonTypes.SECONDARY}
                    iconLeft={<Icon size={12} color="cobaltBlue" type={iconTypes.TRASH_CAN} />}
                    onClick={onRemove}
                >
                    {strings.remove}
                </Button>
            )}
            {!showImagePreview && !showPdfButton && (
                <RRFUpload
                    multiple={false}
                    path={path}
                    onSuccess={onSuccess}
                    testId={testIds.CHAIN_EVENT_ID_SCAN_UPLOAD}
                >
                    <Button className={buttonTypes.ICON_LINK} iconLeft={iconTypes.ADD}>
                        {attachLabel}
                    </Button>
                </RRFUpload>
            )}
        </Row>
    );
});

const IDScanRow = styled(_IDScanRow)`
    margin-bottom: 12px;
`;

// form rows for attaching ID scan and signature
// these should only appear after the user has selected a chain event type,
// otherwise there's no validation on these fields
const AttachmentRows = compose(
    connect(null, { changePath: createChainEventsForm.actionCreators.changePath }),
    withRRFFieldValues({ eventTypeId: 'eventTypeId', attachmentFileIds: 'attachmentFileIds' }),
    withHandlers({
        handleFileUpload: (props) => (files) => {
            const fileIds = map(files, 'file.id');
            const updatedAttachmentFileIds = [...(props.attachmentFileIds || []), ...fileIds];
            props.changePath('attachmentFileIds', sortBy(updatedAttachmentFileIds));
        },
        handleFileDelete: (props) => (fileId) => {
            const updatedAttachmentFileIds = difference(props.attachmentFileIds, [fileId]);
            props.changePath('attachmentFileIds', updatedAttachmentFileIds);
        },
    }),
    withRRFActions,
    renderOnlyIf(({ eventTypeId }) => !!eventTypeId)
)(function AttachmentRows({ handleFileUpload, handleFileDelete, formActions }) {
    const evidenceModuleName = useEvidenceModuleName();

    return (
        <div>
            <RRFSignaturePad
                path="signatureFileId"
                label={strings.signature}
                width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                message={strings.signaturePadMessage(evidenceModuleName)}
                onSuccess={(fileCategory) => {
                    formActions.change(`signatureFileCategory`, fileCategory);
                }}
            />
            <IDScanRow
                path="idScanFileId"
                attachLabel={strings.attachIDScan}
                label={strings.idScan}
            />
            <InlineAttachmentsUploader
                label={strings.attachments}
                entityType={EntityTypeEnum.CHAIN_EVENT.name}
                linkType={LinkTypesEnum.CHAIN_EVENT_ATTACHMENT}
                onFileUploadFinish={handleFileUpload}
                onDeleteUpload={handleFileDelete}
                testId={testIds.CHAIN_EVENT_ATTACHMENT_UPLOAD}
            />
        </div>
    );
});

function CreateChainEventsForm({ eventTypeIdFilter, errorMessage, itemQueueCount }) {
    const eventTypeIdFilterLength = eventTypeIdFilter.length;
    const disableForm = itemQueueCount === 0 || eventTypeIdFilterLength === 0;
    const errorContent =
        eventTypeIdFilterLength === 0 ? (
            <InlineBanner status="error">{strings.noSubsequentEventTypeIds}</InlineBanner>
        ) : null;

    return (
        <div>
            {errorContent}
            <ReactReduxForm {...createChainEventsForm}>
                {errorMessage && <InlineBanner status="error">{errorMessage}</InlineBanner>}
                <ChainEventTypeRow disabled={disableForm} eventTypeIdFilter={eventTypeIdFilter} />
                <ConditionalRows />
                <Row>
                    <RRFTextArea
                        disabled={disableForm}
                        path="description"
                        rows={4}
                        width={CREATE_CHAIN_EVENTS_PANEL_CONTENTS_WIDTH}
                    />
                </Row>
                <AttachmentRows />
            </ReactReduxForm>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    eventTypeIdFilter: subsequentChainEventTypeIdsSelector,
    itemQueueCount: itemQueueCountSelector,
    submitting: createChainEventsPanelIsSubmittingSelector,
    errorMessage: createChainEventsPanelErrorMessageSelector,
});

/**
 * Form for an evidence clerk to create a chain event (of the same chain event
 *   type) for every item currently in the item queue.
 */
export default connect(mapStateToProps)(CreateChainEventsForm);

import { formatSvgPath } from '../../helpers';

import Animal from '../../../../../../../../../images/crash-diagram/other/animal.svg';
import Debris from '../../../../../../../../../images/crash-diagram/other/debris.svg';
import Fence from '../../../../../../../../../images/crash-diagram/other/fence.svg';
import FluidSpillWaterOilBlood from '../../../../../../../../../images/crash-diagram/other/fluid-spill-water-oil-blood.svg';
import GuardFenceRail from '../../../../../../../../../images/crash-diagram/other/guard-fence-rail.svg';
import HouseBuilding from '../../../../../../../../../images/crash-diagram/other/house-building.svg';
import ParkedVehicle from '../../../../../../../../../images/crash-diagram/other/parked-vehicle.svg';
import Pedestrian from '../../../../../../../../../images/crash-diagram/other/pedestrian.svg';
import RailroadTrack from '../../../../../../../../../images/crash-diagram/other/railroad-track.svg';
import StreetLightOverhanging from '../../../../../../../../../images/crash-diagram/other/street-light-overhanging.svg';
import StreetLight from '../../../../../../../../../images/crash-diagram/other/street-light.svg';
import TreeOne from '../../../../../../../../../images/crash-diagram/other/tree-1.svg';
import TreeTwo from '../../../../../../../../../images/crash-diagram/other/tree-2.svg';
import TreeThree from '../../../../../../../../../images/crash-diagram/other/tree-3.svg';
import Wall from '../../../../../../../../../images/crash-diagram/other/wall.svg';

export const other = [
    Animal,
    Debris,
    Fence,
    FluidSpillWaterOilBlood,
    GuardFenceRail,
    HouseBuilding,
    ParkedVehicle,
    Pedestrian,
    RailroadTrack,
    StreetLightOverhanging,
    StreetLight,
    TreeOne,
    TreeTwo,
    TreeThree,
    Wall,
].map((svgPath) => formatSvgPath(svgPath));

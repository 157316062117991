// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

const BASE_URL = '/evidence/api';

/**
 * Resources for report bundles that are tailor made for Evidence purposes.
 */
export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Report Resource',
        methods: {
            /**
             * Load a CompleteEvidenceReportView. This is meant to be used when
             *   loading a Custodial Property Summary report. Other types of reports
             *   do not need this many evidence models together.
             * @param  {number} reportId
             * @return {Promise<CompleteEvidenceReportView>}
             */
            getCustodialReportBundle(reportId, excludeEvidenceHydratedItems) {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `evidence/reports/${reportId}/full`,
                    params: {
                        exclude_evidence_items: excludeEvidenceHydratedItems,
                    },
                });
            },

            getEvidenceReportHistory(reportId) {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `evidence/reports/${reportId}/history`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

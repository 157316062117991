import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { map, includes } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';

import { CollapsibleSectionWithStickyHeader } from '../../../core/components/CollapsibleSectionWithStickyHeader';
import Checkbox, { INDETERMINATE } from '../../../core/forms/components/checkboxes/Checkbox';
import { Tooltip } from '../../../core/components/tooltip';
import testIds from '../../../../core/testIds';
import { AttachmentRow } from './AttachmentRow';
import { RowsContainer } from './RowsContainer';

const strings = componentStrings.core.ExportAttachmentsSidePanel.AttachmentSection;

const ModifiedCheckbox = styled(Checkbox)`
    margin-right: 5px;
`;

export const AttachmentSection = React.memo(
    connect((state) => ({
        formatMiniUserById: formatMiniUserByIdSelector(state),
    }))(function AttachmentSection({
        entityId,
        isOpen,
        onSectionToggleClick,
        entityDisplayValuesById,
        filteredAttachments,
        allAttachments,
        selectedAttachments,
        onAttachmentToggle,
        onToggleAllAttachmentsClick,
        isFiltered,
        formatMiniUserById,
    }) {
        const numSelected = selectedAttachments ? selectedAttachments.length : 0;
        const allSelected = allAttachments.length === numSelected;
        const checkboxValue = allSelected || (numSelected > 0 && INDETERMINATE);
        const checkboxDisabled = isFiltered;
        const checkBox = (
            <ModifiedCheckbox
                testId={testIds.EXPORTS_ATTACHMENTS_SIDE_PANEL_CHECKBOX_ALL}
                disabled={checkboxDisabled}
                onChange={
                    !checkboxDisabled &&
                    (() =>
                        onToggleAllAttachmentsClick(
                            entityId,
                            checkboxValue === INDETERMINATE || !checkboxValue
                        ))
                }
                value={checkboxValue}
            />
        );
        return (
            <CollapsibleSectionWithStickyHeader
                isOpen={isOpen}
                toggleOpen={(isOpen) => onSectionToggleClick(entityId, isOpen)}
                title={entityDisplayValuesById[entityId]}
                description={strings.selectedAttachments(numSelected, allAttachments.length)}
                containerSelector=".react-side-panel-content-wrapper"
                headerLeftElement={
                    checkboxDisabled ? (
                        <Tooltip placement="top" content={<div>{strings.selectAllDisabled}</div>}>
                            <div>{checkBox}</div>
                        </Tooltip>
                    ) : (
                        checkBox
                    )
                }
            >
                <RowsContainer>
                    {map(filteredAttachments, (attachment) => (
                        <AttachmentRow
                            key={attachment.attachmentId}
                            attachment={attachment}
                            onAttachmentToggle={onAttachmentToggle}
                            formatMiniUserById={formatMiniUserById}
                            isSelected={
                                numSelected > 0 &&
                                includes(selectedAttachments, attachment.attachmentId)
                            }
                        />
                    ))}
                </RowsContainer>
            </CollapsibleSectionWithStickyHeader>
        );
    })
);

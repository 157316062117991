import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hasBannerSelector } from '../../../legacy-redux/selectors/alertsSelectors';

export default connect(
    createStructuredSelector({
        hasBanner: hasBannerSelector,
    })
)(function Page({
    className,
    children,
    style,
}: {
    className?: string;
    hasBanner: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
}) {
    return (
        <div className={classNames('mark43-page', className)} style={style}>
            {children}
        </div>
    );
});

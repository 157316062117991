import {
    EntityTypeEnumType,
    EntityTypeEnum,
    AdminsecuritydepartmentsResource,
    SupportsearchResource,
} from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';

import { req } from '../../../../lib/ajax';

type Module = 'RMS' | 'CAD' | 'EVIDENCE';
function getBaseUrlForModule(module: Module) {
    switch (module) {
        case 'RMS':
            return '/rms/api';
        case 'CAD':
            return '/cad/api';
        case 'EVIDENCE':
            // evidence is being migrated to search and needs to be prefixed.
            return '/evidence/api/evidence';
        default:
            return '/rms/api';
    }
}

export default createResource({
    name: 'Support Search Sync Resource',
    methods: {
        getAllDepartments() {
            return req<AdminsecuritydepartmentsResource.GetAllDepartments>({
                method: 'GET',
                url: 'admin/security/departments/all',
            });
        },
        syncMulti(module: Module, entityTypes: EntityTypeEnumType[], departments: number[]) {
            return req<SupportsearchResource.KickOffDepartmentSearchSync>({
                method: 'POST',
                url: `support/search/mass/multi/department`,
                baseUrl: getBaseUrlForModule(module),
                // @ts-expect-error JRV-920
                data: {
                    entityTypes,
                    departments,
                },
            });
        },
        syncEntity(module: Module, entityType, entityId) {
            return req<SupportsearchResource.SyncEntity>({
                method: 'PUT',
                url: `support/search/entities/${entityType}/${entityId}/`,
                baseUrl: getBaseUrlForModule(module),
            });
        },
        kickOffSearchSyncForEntities(
            module: Module,
            entityType: EntityTypeEnumType,
            entityIds: number[]
        ) {
            return req<SupportsearchResource.KickOffSearchSyncForEntities>({
                method: 'POST',
                data: entityIds,
                url: `support/search/entities/${entityType}/`,
                baseUrl: getBaseUrlForModule(module),
            });
        },
        syncType(module: Module, entityType: EntityTypeEnumType) {
            return req<SupportsearchResource.KickOffSearchSync>({
                method: 'POST',
                url: `support/search/mass/${entityType}/`,
                // @ts-expect-error JRV-920
                data: {},
                baseUrl: getBaseUrlForModule(module),
            });
        },
        syncRmsReportType(reportDefinitionId: number) {
            return req<SupportsearchResource.KickOffSearchSync>({
                method: 'POST',
                url: `support/search/mass/${EntityTypeEnum.REPORT.name}/`,
                // @ts-expect-error JRV-920
                data: { reportDefinitionId },
                baseUrl: getBaseUrlForModule('RMS'),
            });
        },
        partialSearchSyncEntities(
            module: Module,
            entityType: EntityTypeEnumType,
            startDateTime: string
        ) {
            return req<SupportsearchResource.PartialSearchSyncEntities>({
                method: 'POST',
                url: `support/search/search-sync/${entityType}/`,
                // @ts-expect-error JRV-920
                data: {
                    startDateTime,
                },
                baseUrl: getBaseUrlForModule(module),
            });
        },
        getSyncTypeStatus() {
            return req<SupportsearchResource.GetCurrentSyncStatus>({
                method: 'GET',
                url: 'support/search/mass/status',
            });
        },
        clearSyncType() {
            return req<SupportsearchResource.CleanUpBlacksmithRecords>({
                method: 'POST',
                url: 'support/search/mass/cleanup',
            });
        },
        getAreEntitiesSynced(module: Module, entityType: EntityTypeEnumType, entityIds: number[]) {
            return req<SupportsearchResource.GetAreEntitiesSynced>({
                method: 'GET',
                params: { ids: entityIds },
                url: `support/search/entities/synced/${entityType}/`,
                baseUrl: getBaseUrlForModule(module),
            });
        },
    },
});

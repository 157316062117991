import { find, parseInt } from 'lodash';
import { personProfilesSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

export const getPersonProfileIsDead = (getState: () => RootState) => (nameId: number | string) => {
    const personId = typeof nameId === 'string' ? parseInt(nameId) : nameId;
    const personProfiles = personProfilesSelector(getState());
    const victim = find(personProfiles, (person) => person.id === personId);
    return victim?.isDead;
};

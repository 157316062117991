import React from 'react';
import classNames from 'classnames';
// an alternative is the unopinionated react-modal2 library
import ReactModal, { Props as ReactModalProps } from 'react-modal';

import { useSelector } from 'react-redux';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import withFocusTrap, {
    WithFocusTrapProps,
} from '~/client-common/core/keyboardFocus/withFocusTrap';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';

import testIds from '../../../../core/testIds';
import Button, { buttonTypes, ButtonProps } from '../../../../legacy-redux/components/core/Button';
import { FloatLoadingGray } from '../../../../legacy-redux/components/core/Loading';
import { InlineBanner } from '../../components/InlineBanner';
import { AnalyticsContextProviderWithAdditionalData } from '../../context/AnalyticsContext';
import ReactHotKeysWrapper from '../../hotkeys/components/ReactHotKeysWrapper';
import { computeStyle } from '../utils/styleHelpers';
import { createHotKeysConfig } from '../utils/hotKeyHelpers';

type AllowedReactModalProps = Pick<
    ReactModalProps,
    | 'isOpen'
    | 'onAfterClose'
    | 'onRequestClose'
    | 'shouldCloseOnOverlayClick'
    | 'shouldReturnFocusAfterClose'
    | 'onAfterOpen'
>;
export interface ModalBaseProps extends AllowedReactModalProps {
    additionalFooterContent?: React.ReactNode;
    cancelText?: string | null; // use null to hide button
    contentStyle?: React.CSSProperties;
    dimmedOverlay?: boolean;
    errorHeader?: boolean;
    errorMessages?: string[];
    errorStyle?: React.CSSProperties;
    hideFooter?: boolean;
    loading?: boolean;
    modalClassName?: string;
    modalStyle?: React.CSSProperties;
    okText?: string | null; // use null to hide button
    onCancel?: ButtonProps['onClick'];
    overlayStyle?: React.CSSProperties;
    saveDisabled?: boolean;
    saveModal?: ButtonProps['onClick'];
    saving?: boolean;
    testId?: string;
    hideErrors?: boolean;
    title?: React.ReactNode;
}
interface ModalContentProps extends WithFocusTrapProps {
    additionalFooterContent?: React.ReactNode;
    cancelButtonElement: React.ReactNode;
    contentStyle?: React.CSSProperties;
    children: React.ReactNode;
    errorElement: React.ReactNode;
    hideFooter?: boolean;
    loadingElement: React.ReactNode;
    okButtonElement: React.ReactNode;
}

const ModalContent: React.FC<ModalContentProps> = ({
    additionalFooterContent,
    cancelButtonElement,
    children,
    contentStyle,
    errorElement,
    hideFooter,
    loadingElement,
    okButtonElement,
}: ModalContentProps) => (
    <>
        <div
            className={classNames('modal-content', { 'with-footer': !hideFooter })}
            style={contentStyle}
            data-test-id={testIds.MODAL_CONTENT}
        >
            {errorElement}
            {children}
        </div>
        {!hideFooter && (
            <div className="modal-footer">
                {okButtonElement}
                {cancelButtonElement}
                {additionalFooterContent}
                {loadingElement}
            </div>
        )}
    </>
);

const WrappedModalContent = withFocusTrap(ModalContent);

/**
 * Renders the base UI for every modal
 */
const ModalBase: React.FC<ModalBaseProps> = ({
    additionalFooterContent,
    cancelText = 'Cancel',
    children,
    contentStyle,
    dimmedOverlay = true,
    errorHeader = false,
    errorMessages = [],
    hideFooter = false,
    isOpen,
    okText = 'Ok',
    onAfterClose,
    onAfterOpen,
    onCancel,
    onRequestClose,
    overlayStyle,
    loading,
    modalClassName,
    modalStyle,
    saveDisabled = false,
    saveModal,
    saving,
    shouldCloseOnOverlayClick = false,
    shouldReturnFocusAfterClose = true,
    testId,
    title,
    hideErrors = false,
}) => {
    const titleWrapperClassNames = classNames('modal-title-wrapper', {
        'modal-error': !!errorMessages.length || errorHeader,
    });

    const errorElement = !hideErrors && !!errorMessages.length && (
        <InlineBanner status="error" testId={testIds.MODAL_ERRORS} hasList>
            {errorMessages}
        </InlineBanner>
    );

    const okButtonElement = okText && (
        <Button
            className={buttonTypes.PRIMARY}
            disabled={saveDisabled || saving}
            onClick={saveModal}
            testId={testIds.MODAL_OK}
        >
            {okText}
        </Button>
    );

    const cancelButtonElement = cancelText && (
        <Button
            className={buttonTypes.SECONDARY}
            disabled={saving}
            onClick={onCancel}
            testId={testIds.MODAL_CANCEL}
        >
            {cancelText}
        </Button>
    );

    const loadingElement = (saving || loading) && <FloatLoadingGray />;
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isMainNavEnabled = applicationSettings.RMS_ARC_NAVIGATION_ENABLED;

    return (
        <AnalyticsContextProviderWithAdditionalData
            analyticsKeyToAdd={AnalyticsPropertyEnum.MODAL}
            analyticsValueToAdd={testId}
        >
            <ReactHotKeysWrapper
                hotKeysConfig={createHotKeysConfig<HTMLDivElement>({
                    onClose: onRequestClose,
                    onSave: saveModal,
                })}
            >
                <ReactModal
                    ariaHideApp={false}
                    className={classNames('mark43-modal', 'mark43-react-modal', modalClassName)}
                    isOpen={isOpen}
                    onAfterClose={onAfterClose}
                    onRequestClose={onRequestClose}
                    overlayClassName={classNames('mark43-modal-overlay', {
                        dimmed: !!dimmedOverlay,
                        // This can be removed when the RMS_ARC_NAVIGATION_ENABLED feature flag is torn down
                        'no-header': isMainNavEnabled,
                    })}
                    // Removing this default functionality from `ReactModal`
                    // and handling with `ReactHotKeysWrapper` so
                    // that we can easily track via mixpanel
                    shouldCloseOnEsc={false}
                    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                    shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
                    style={computeStyle({ hideFooter, modalStyle, overlayStyle })}
                    onAfterOpen={onAfterOpen}
                >
                    <div data-test-id={testId}>
                        {(title || errorElement) && (
                            <div className={titleWrapperClassNames}>
                                {title && (
                                    <span
                                        className="modal-title"
                                        data-test-id={testIds.MODAL_TITLE}
                                    >
                                        {title}
                                    </span>
                                )}
                            </div>
                        )}
                        <WrappedModalContent
                            additionalFooterContent={additionalFooterContent}
                            cancelButtonElement={cancelButtonElement}
                            contentStyle={contentStyle}
                            errorElement={errorElement}
                            hideFooter={hideFooter}
                            loadingElement={loadingElement}
                            okButtonElement={okButtonElement}
                        >
                            {children}
                        </WrappedModalContent>
                    </div>
                </ReactModal>
            </ReactHotKeysWrapper>
        </AnalyticsContextProviderWithAdditionalData>
    );
};

export default ModalBase;

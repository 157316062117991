import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import Row from '../../../core/components/Row';
import testIds from '../../../../core/testIds';

import AdvancedSearchErrorText from './AdvancedSearchErrorText';

const strings = componentStrings.search.AdvancedSearchSaveSearchButton;

/**
 * Component that lives in an `AdvancedSearchResultsTableHeader` component.  Component will only be displayed if this elastic search type is savable;
 * the parent `AdvancedSearchResultsTableHeader` will determine this. This component currently will only display the
 * "STAR" button icon.  The purpose of this component is to allow the user to either "SAVE" this search query (if it is not already saved) or "DELETE"
 * this search query (if it is already saved), directly inline with the search results, to provide the user context for deciding if the query
 * is worth saving or deleting.
 * @param {function}  onSaveSearchClick        Function for handling click events on the "STAR" icon.
 * @param {integer}   savedSearchId            Determines what the icon's text should be rendered as.  `NULL` `savedSearchId`s indicate that this
 *                                             search is currently not saved, whereas a value will indicate the id of the saved search query.
 * @param {string}    savedSearchErrorMessage  If a "SAVE" or "DELETE" action throws an error, display this message to the user, under
 *                                             the "STAR" component.
 */
const AdvancedSearchSaveSearchButton = function ({
    onSaveSearchToggleClick,
    savedSearch,
    savedSearchErrorMessage,
}) {
    const isSavedSearch = !!savedSearch && !savedSearch.isAutoSave;
    return (
        <div className="advanced-search-save-search-button">
            <Button
                testId={testIds.ADVANCED_SAVE_SEARCH_BUTTON}
                className={buttonTypes.ICON_LINK}
                iconLeft={
                    <Icon
                        type={
                            isSavedSearch
                                ? iconTypes.SAVED_SEARCH_ACTIVE
                                : iconTypes.SAVED_SEARCH_INACTIVE
                        }
                    />
                }
                hoveredIconLeft={
                    <Icon
                        type={
                            isSavedSearch
                                ? iconTypes.SAVED_SEARCH_INACTIVE
                                : iconTypes.SAVED_SEARCH_ACTIVE
                        }
                    />
                }
                hoveredChildren={
                    isSavedSearch
                        ? strings.labels.savedSearchHover
                        : strings.labels.unsavedSearchHover
                }
                onClick={onSaveSearchToggleClick}
            >
                {isSavedSearch ? strings.labels.savedSearch : strings.labels.unsavedSearch}
            </Button>
            {savedSearchErrorMessage && (
                <Row>
                    <AdvancedSearchErrorText message={savedSearchErrorMessage} />
                </Row>
            )}
        </div>
    );
};

export default AdvancedSearchSaveSearchButton;

import { Form } from 'react-redux-form';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';

const { connectRRF } = reactReduxFormHelpers;

/**
 * Use this form component when building a React Redux Form. Spread in a form
 *   module object created with `createFormModule`, like `<ReactReduxForm
 *   {...module}>`. Do not use RRF's `Form` component directly because we
 *   wrapped our own functionality on the form model path here.
 * @param {Object} props Props get spread into RRF's `Form` component.
 * @param {string} props.formName
 * @param {string} props.path
 * @param {Object} props.validators
 * @param {Object} props.asyncValidators
 * @param {Object} props.selectors
 */
export default connectRRF(Form);

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EntityTypeEnum, EntityTypeEnumType, UsageActionEnumType } from '@mark43/rms-api';
import { createCaseUsageLog } from '../..';

type ActionType = typeof createCaseUsageLog;

const entityTypeToAction: Partial<Record<EntityTypeEnumType, ActionType>> = {
    [EntityTypeEnum.CASE.name]: createCaseUsageLog,
};

export const useDispatchUsageLog = (
    entityType: EntityTypeEnumType,
    usageLogAction: UsageActionEnumType
) => {
    const dispatch = useDispatch();

    const dispatchUsageLog = useCallback(() => {
        const action = entityTypeToAction[entityType];

        if (action) {
            dispatch(action({ usageLogAction }));
        }
    }, [dispatch, entityType, usageLogAction]);

    useEffect(() => {
        dispatchUsageLog();
    }, [dispatchUsageLog]);
};

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/evidence/api';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Custodial Responsible Officer Resource',
        methods: {
            updateCustodialResponsibleOfficer(custodialResponsibleOfficer) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: 'responsible_officer/custodial_report',
                    data: custodialResponsibleOfficer,
                });
            },

            custodialResponsibleOfficerExists(reportId) {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `responsible_officer/custodial_report/${reportId}/exists`,
                });
            },

            getCustodialResponsibleOfficer(reportId) {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `responsible_officer/custodial_report/${reportId}`,
                });
            },
        },
    });
}

/**
 * Resources for Responsible Officers. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import { useSelector } from 'react-redux';
import { historyEventViewModelsByEntityIdSelector } from '~/client-common/core/domain/history-events/state/ui';
import History from '../../../core/histories/components/History';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import WarrantHeader from './WarrantHeader';

type Params = {
    warrantId: number;
};

export const WarrantHistory: React.FC<{
    params: Params;
}> = ({ params }) => {
    const { warrantId } = params;
    const historyEventViewModels = useSelector(historyEventViewModelsByEntityIdSelector);
    return (
        <>
            <WarrantHeader warrantId={warrantId} />
            <ScrollableUnderSubheader>
                <History
                    showFilter={true}
                    historyEventViewModels={historyEventViewModels(warrantId)}
                />
            </ScrollableUnderSubheader>
        </>
    );
};

import { Avatar, Flex } from 'arc';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { arcIconForItemTypeAttrId } from '../../../../../../core/item-profiles/utils/itemIcons';
import ItemInfo from '../../../../../core/components/ItemInfo';
import { ManageEvidenceViewModel } from '../../../../types';

import { useIsHighValue } from '../../../../hooks/useIsHighValue';

const StyledAvatar = styled(Avatar)`
    border-color: var(--arc-colors-negative-default);
`;

const EvidenceDescriptionCell = ({ evidenceItem }: { evidenceItem: ManageEvidenceViewModel }) => {
    const { itemTypeAttrId, isHighValue, isInHighValueContainer, masterItemId } = evidenceItem;

    const highRiskLabelEnabled = useSelector(applicationSettingsSelector)
        .EVIDENCE_AUTO_LOCATION_UPDATE_ENABLED;
    const itemIsHighValue = useIsHighValue({ isHighValue, isInHighValueContainer, masterItemId });
    const showHighRiskLabel = !!highRiskLabelEnabled && itemIsHighValue;

    return (
        <Flex gap="2" alignItems="center" whiteSpace="normal">
            <StyledAvatar
                icon={arcIconForItemTypeAttrId(itemTypeAttrId)}
                size="sm"
                showBorder={showHighRiskLabel}
                aria-label={arcIconForItemTypeAttrId(itemTypeAttrId)}
            />
            <ItemInfo
                viewModel={evidenceItem}
                hideIcon
                hideItemType
                disableLink={true}
                hideRenSequence={true}
            />
        </Flex>
    );
};

export { EvidenceDescriptionCell };

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getWarrantResource from '../../../warrants/resources/warrantsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const NEXUS_STATE_PROP = 'warrantDexReturns';

// model is not in mark43-resources atm
const warrantDexReturnsModule = createNormalizedModule<unknown>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// ACTIONS
const replaceWarrantDexReturnsWhere = warrantDexReturnsModule.actionCreators.replaceEntitiesWhere;

export const warrantDexReturnsWhereSelector =
    warrantDexReturnsModule.selectors.entitiesWhereSelector;

export function getWarrantDexReturns(warrantId: number): ClientCommonAction<Promise<unknown[]>> {
    const warrantResource = getWarrantResource();
    return function (dispatch) {
        return warrantResource
            .getWarrantDexReturns(warrantId)
            .then((updatedWarrantDexReturns: unknown[]) => {
                dispatch(replaceWarrantDexReturnsWhere({ warrantId }, updatedWarrantDexReturns));
                return updatedWarrantDexReturns;
            });
    };
}

// REDUCER
export default warrantDexReturnsModule.reducerConfig;

import { EmailRequestStatusEnum } from '@mark43/rms-api';

import emailResource from '../resources/emailResource';
import { EMAIL_POLLING_DELAY_MS } from '../configuration';
import { ResolvedEmailRequestStatus } from '../types';

const pollingIds: { [emailId: string]: number } = {};

export const pollForEmail = (
    emailId: string,
    onComplete: (status: ResolvedEmailRequestStatus) => void
) => {
    if (!pollingIds[emailId]) {
        pollingIds[emailId] = setInterval(async () => {
            try {
                const { status } = await emailResource.getEmailStatus(emailId);

                if (status !== EmailRequestStatusEnum.PENDING.name) {
                    onComplete(status);
                    clearInterval(pollingIds[emailId]);
                    delete pollingIds[emailId];
                }
            } catch (e) {
                onComplete(EmailRequestStatusEnum.ERROR.name);
                clearInterval(pollingIds[emailId]);
                delete pollingIds[emailId];
            }
        }, EMAIL_POLLING_DELAY_MS);
    }
};

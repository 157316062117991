import React from 'react';
import { Provider, useDispatch } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { TinyMCE } from 'tinymce';
import environmentEnum from '~/client-common/core/enums/client/environmentEnum';

import { E2ETestingContextProvider } from './modules/core/context/E2ETestingContext';
import { ScrollableNodesProvider } from './modules/core/context/ScrollableNodesContext';
import formsRegistry from './core/formsRegistry';
import store from './core/store';
import { RmsDispatch } from './core/typings/redux';
import { RouterComponent } from './routing/Router';
import { OverlayProvider, overlayStore } from './core/overlayManager';
import { bootstrap } from './legacy-redux/actions/authActions';
import { showLoadingMask } from './legacy-redux/actions/globalActions';
import { getAuthToken } from './core/auth';
import { PersonQuickAddContext } from './modules/core/context/PersonQuickAddContext';
import { OrganizationQuickAddContext } from './modules/core/context/OrganizationQuickAddContext';
import { LocationQuickAddContext } from './modules/core/context/LocationQuickAddContext';
import { EsriConfigurationWrapper } from './modules/core/maps/components/EsriConfigurationWrapper';
import { FederatedSearchProviderWrapper } from './modules/federated-search/components/FederatedSearchProviderWrapper';

const AppContent: React.FC = () => {
    const [renderApp, setRenderApp] = React.useState<boolean>(false);
    const dispatch: RmsDispatch = useDispatch();

    React.useEffect(() => {
        // Attempt to bootstrap / auth the user before our router takes over
        // if we haven't done any redirecting and we have a token (cookie),
        // we'll try to authenticate the user
        if (getAuthToken()) {
            dispatch(bootstrap()).finally(() => {
                // We want to wait for bootstrap to load
                // before rendering our app so that our router
                // can correctly feature flags / abilities
                setRenderApp(true);
                document.getElementById('loadingmask')?.remove();
            });
        } else {
            // Render the router immediately if we don't
            // have an auth token -- this should lead
            // to redirecting to the login page
            // via downstream affects
            setRenderApp(true);
            dispatch(showLoadingMask(false));
            document.getElementById('loadingmask')?.remove();
        }
    }, [dispatch]);

    if (!renderApp) {
        return null;
    }

    return (
        <>
            <FederatedSearchProviderWrapper>
                <ScrollableNodesProvider>
                    <EsriConfigurationWrapper>
                        <OverlayProvider>
                            <RouterComponent />
                        </OverlayProvider>
                    </EsriConfigurationWrapper>
                </ScrollableNodesProvider>
            </FederatedSearchProviderWrapper>
        </>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
    interface Window {
        dev: {
            formsRegistry: typeof formsRegistry;
            store: typeof store;
            overlayStore: typeof overlayStore;
        };
        tinymce?: TinyMCE;
        pendo?: typeof pendo;
        /**
         * This property is injected by Cypress when running within e2e or component tests
         * https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
         */
        Cypress: { testingType: 'component' | 'e2e' };
    }
}

if (
    MARK43_ENV === environmentEnum.DEVELOPER ||
    MARK43_ENV === environmentEnum.QA ||
    MARK43_ENV === environmentEnum.DEV43
) {
    window.dev = {
        formsRegistry,
        store,
        overlayStore,
    };
}

const queryClient = new QueryClient({
    defaultOptions: {
        // https://tanstack.com/query/v4/docs/reference/useQuery
        queries: {
            cacheTime: 0,
            // We set this to 'always' because the majority of our uses of useQuery are to hydrate the initial state of some modal/panel, and we know
            // that we always want to do this rather than possibly rely on an already cached value for the query.
            refetchOnMount: 'always',
            // We set this to false but it may make sense to set this to true for "initial hydrate" type calls on something
            // like queues to counteract tab hibernation. Note that turning this to true will do nothing while staleTime
            // is set to Infinity.
            refetchOnWindowFocus: false,
            // We set this to false because we want control over our refetching and will typically do this based on socket
            // disconnect and reconnect. Similar to refetchOnWindowFocus, this will do nothing when set to true if staleTime
            // is set to Infinity.
            refetchOnReconnect: false,
            // This is set to Infinity but will need to be changed if we ever want to modify our default behaviour around the refetch
            // options above.
            staleTime: Infinity,
        },
    },
});

export const App: React.FC = () => {
    const cypress: { testingType: 'component' | 'e2e' } | undefined =
        typeof window.Cypress === 'object' && window.Cypress !== null ? window.Cypress : undefined;
    return (
        <E2ETestingContextProvider
            testEnvironment={
                cypress ? (cypress.testingType === 'component' ? 'COMPONENT' : 'E2E') : 'NONE'
            }
        >
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <PersonQuickAddContext.Provider>
                        <OrganizationQuickAddContext.Provider>
                            <LocationQuickAddContext.Provider>
                                <AppContent />
                            </LocationQuickAddContext.Provider>
                        </OrganizationQuickAddContext.Provider>
                    </PersonQuickAddContext.Provider>
                </QueryClientProvider>
            </Provider>
        </E2ETestingContextProvider>
    );
};

import { WarrantTitle } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'warrantTitles';

const warrantTitlesModule = createNormalizedModule<WarrantTitle>({
    type: NEXUS_STATE_PROP,
    key: 'warrantId',
});

// ACTIONS
export const storeWarrantTitles = warrantTitlesModule.actionCreators.storeEntities;

// SELECTORS
export const warrantTitlesSelector = warrantTitlesModule.selectors.entitiesSelector;

// REDUCER
export default warrantTitlesModule.reducerConfig;

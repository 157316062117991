import { InlineReportCommentView } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'inlineReportComments';

const reportInlineCommentsModule = createLinkModule<InlineReportCommentView>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// SELECTORS
export const reportInlineCommentsViewSelector = reportInlineCommentsModule.selectors.linksSelector;

// ACTIONS
export const storeReportInlineComments = reportInlineCommentsModule.actionCreators.storeLinks;

export default reportInlineCommentsModule.reducerConfig;

import { noop } from 'lodash';
import { OperationTypeEnum } from '@mark43/rms-api';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFStorageLocationSelect } from '../../../core/forms/components/selects/StorageLocationSelect';
import { RRFInventoryTypeSelect } from '../../../core/forms/components/selects/InventoryTypeSelect';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import _ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import _Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import Row from '../../../core/components/Row';
import { inventoriesDashboardSearch } from '../state/ui';

const ReactReduxForm = styled(_ReactReduxForm)`
    border-radius: 4px;
    padding: 19px;
`;

const Button = styled(_Button)`
    clear: none;
    margin-top: 2px;
    color: ${(props) => props.theme.colors.mediumGrey};

    &:hover {
        color: ${(props) => props.theme.colors.cobaltBlue};
    }
`;

const SearchForm = ({ applyFilters, clearAllFilters }) => {
    const strings = componentStrings.evidence.inventories.searchForm;

    const dateRangePicker = (
        <RRFDateRangePicker
            includeTime={false}
            label={strings.dateCompleted}
            onChange={applyFilters}
            paths={{
                withinLastPeriod: 'completedWithinLastPeriod',
                toDatePeriod: 'completedToDatePeriod',
                startDateUtc: 'completedStartDateUtc',
                endDateUtc: 'completedEndDateUtc',
            }}
            width={260}
            withinLastPeriodOptions={['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D']}
            toDatePeriodOptions={['P1M', 'P1Y']}
        />
    );

    const inventoryTypeSelect = (
        <RRFInventoryTypeSelect onChange={applyFilters} path="inventoryType" width={260} />
    );

    const userSelect = (
        <RRFUserSelect multiple={true} onChange={applyFilters} path="ownerUserIds" width={300} />
    );

    const storageLocationSelect = (
        <RRFStorageLocationSelect
            multiple={true}
            onChange={applyFilters}
            path={'storageLocationIds'}
            width={290}
            requiredPermissions={[OperationTypeEnum.READ.name, OperationTypeEnum.MANAGE.name]}
        />
    );

    const clearButton = (
        <Button
            className={buttonTypes.ICON_LINK}
            iconLeft={<Icon size={12} color="mediumGrey" type={iconTypes.CLOSE_X} />}
            hoveredIconLeft={<Icon size={12} color="cobaltBlue" type={iconTypes.CLOSE_X} />}
            onClick={clearAllFilters}
        >
            {strings.clear}
        </Button>
    );

    return (
        <ReactReduxForm className="clearfix" {...inventoriesDashboardSearch.form}>
            <RRFFieldset title={strings.titleForFilter}>
                <Row>
                    {dateRangePicker}
                    {inventoryTypeSelect}
                    {userSelect}
                </Row>

                <Row>{storageLocationSelect}</Row>

                <Row>{clearButton}</Row>
            </RRFFieldset>
        </ReactReduxForm>
    );
};

SearchForm.propTypes = {
    applyFilters: PropTypes.func,
    clearAllFilters: PropTypes.func,
};

SearchForm.defaultProps = {
    applyFilters: noop,
    clearAllFilters: noop,
};

export default SearchForm;

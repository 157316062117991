import _, { compact, map, isArray, reduce, uniq } from 'lodash';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import {
    attributeTypeToNibrsCodeTypeCategoryMap,
    attributeTypeToUcrCodeTypeCategoryMap,
    attributeTypeToUkCodeTypeCategoryMap,
    attributeTypeToCodeTypeCategoryMap,
} from '~/client-common/core/domain/codes/utils/codesHelpers';
import { codeForCodeTypeSourceAttributeIdAndCodeTypeSelector } from '~/client-common/core/domain/attribute-codes/state/ui';

import {
    nibrsCodeForAttributeIdAndCodeTypeCategorySelector,
    ucrCodeForAttributeIdAndCodeTypeCategorySelector,
} from '../../reports/ucr-classification/state/ui/selectors';

const UCR = 'UCR';
const UK = 'UK';
const NIBRS = 'NIBRS';

function getCode(attributeId, source, fieldDetail, getState, onError) {
    const state = getState();

    if (isUndefinedOrNull(attributeId)) {
        return;
    }

    const attributeType = fieldDetail.fieldTypeMappedIdEnum;
    let code;
    if (source === UCR) {
        const ucrCodeForAttributeIdAndCodeTypeCategory = ucrCodeForAttributeIdAndCodeTypeCategorySelector(
            state
        );
        const ucrCodeTypeCategory = attributeTypeToUcrCodeTypeCategoryMap[attributeType];
        code = ucrCodeForAttributeIdAndCodeTypeCategory(attributeId, ucrCodeTypeCategory);
    } else if (source === NIBRS) {
        const nibrsCodeForAttributeIdAndCodeTypeCategory = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(
            state
        );
        const nibrsCodeTypeCategory = attributeTypeToNibrsCodeTypeCategoryMap[attributeType];
        code = !isArray(nibrsCodeTypeCategory)
            ? nibrsCodeForAttributeIdAndCodeTypeCategory(attributeId, nibrsCodeTypeCategory)
            : reduce(
                  nibrsCodeTypeCategory,
                  (code, codeTypeCategory) =>
                      code ||
                      nibrsCodeForAttributeIdAndCodeTypeCategory(attributeId, codeTypeCategory),
                  undefined
              );
    } else if (source === UK) {
        code = codeForCodeTypeSourceAttributeIdAndCodeTypeSelector(state)({
            attributeId,
            codeTypeCategory: attributeTypeToUkCodeTypeCategoryMap[attributeType],
            codeTypeSource: source,
        });
    } else {
        const codeTypeCategory = attributeTypeToCodeTypeCategoryMap[attributeType];
        if (!codeTypeCategory) {
            throw new Error(`Invalid attribute type specified for rule ${attributeType}`);
        }
        code = codeForCodeTypeSourceAttributeIdAndCodeTypeSelector(state)({
            attributeId,
            codeTypeCategory,
            codeTypeSource: source,
        });
    }

    if (!code) {
        if (typeof onError === 'function') {
            const errorObj = {
                message:
                    'Failed to get code by attributeId and code type source in GET_CODE_FOR_ATTRIBUTE_ID_AND_SOURCE',
                extra: {
                    attributeId,
                    fieldDetail,
                    source,
                },
            };

            onError(errorObj);
        }
        return;
    }

    return code;
}

/**
 * Get the code (the `code.code` string) that maps to the given attribute id and source (UCR/NIBRS/other).
 */
export const getCodeForAttributeIdAndSource = ({ getState, onError }) => {
    return (attributeId, { source } = {}, { fieldDetail } = {}) =>
        isArray(attributeId)
            ? compact(
                  uniq(
                      map(attributeId, (id) => getCode(id, source, fieldDetail, getState, onError))
                  )
              )
            : getCode(attributeId, source, fieldDetail, getState, onError);
};

export function getCodeForAttributeIdsAndSource({ getState, onError }) {
    return (attributeId, { source } = {}, { fieldDetail } = {}) => {
        if (!isArray(attributeId)) {
            return getCode(attributeId, source, fieldDetail, getState, onError);
        }

        return _(attributeId)
            .map('castedValue')
            .flatten() // each casted value is either an attrId or an array of attrIds
            .map((value) => getCode(value, source, fieldDetail, getState, onError))
            .uniq()
            .compact()
            .value();
    };
}

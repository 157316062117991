import { AttributeTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { includes, map } from 'lodash';

import { createSelector } from 'reselect';
import entityTypeObjectEnum from '../../../../enums/universal/entityTypeObjectEnum';

import sortNameReportLinks from '../../../name-report-links/utils/sortNameReportLinks';
import sortLocationEntityLinks from '../../../location-entity-links/utils/sortLocationEntityLinks';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';
import {
    locationEntityLinksWhereSelector,
    NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
} from '../../../location-entity-links/state/data';
import {
    fieldContactByReportIdSelector,
    NEXUS_STATE_PROP as FIELD_CONTACTS_NEXUS_STATE_PROP,
} from '../../../field-contacts/state/data';
import {
    reportAttributesWhereSelector,
    NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP,
} from '../../../report-attributes/state/data';
import {
    nameReportLinksWhereSelector,
    NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP,
} from '../../../name-report-links/state/data';

/**
 * `AttributeType`s that are valid for the `ReportAttribute`s in a Community Info Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const COMMUNITY_INFO_CARD_REPORT_ATTRIBUTE_TYPES = [
    AttributeTypeEnum.COMMUNITY_INFORMATION_DISPOSITION.name,
    AttributeTypeEnum.COMMUNITY_INFORMATION_REASON_FOR_REPORT.name,
    AttributeTypeEnum.COMMUNITY_INFORMATION_STATISTICS.name,
];

/**
 * `LinkTypes` that are valid for the `NameReportLink`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const COMMUNITY_INFO_CARD_NAME_REPORT_LINK_TYPES = [LinkTypesEnum.SUBJECT_IN_FIELD_CONTACT];

/**
 * `LinkTypes` that are valid for the `LocationEntityLink`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const COMMUNITY_INFO_CARD_LOCATION_LINK_TYPES = [LinkTypesEnum.LOCATION_OF_FIELD_CONTACT];

const reportAttributesFilter = (reportId) => {
    return (reportAttribute) =>
        reportAttribute.reportId === reportId &&
        includes(COMMUNITY_INFO_CARD_REPORT_ATTRIBUTE_TYPES, reportAttribute.attributeType);
};

export const hydratedCommunityInfoByReportIdSelector = createSelector(
    fieldContactByReportIdSelector,
    reportAttributesWhereSelector,
    nameReportLinksWhereSelector,
    locationEntityLinksWhereSelector,
    (
        fieldContactByReportId,
        reportAttributesWhere,
        nameReportLinksWhere,
        locationEntityLinksWhere
    ) => (reportId) => {
        const fieldContact = fieldContactByReportId(reportId) || {};
        const { id: fieldContactId } = fieldContact;
        const reportAttributes = reportAttributesWhere(reportAttributesFilter(reportId));
        const communityInfoSubjects = nameReportLinksWhere({
            reportId,
            contextType: entityTypeObjectEnum.REPORT.name,
            contextId: reportId,
            linkType: LinkTypesEnum.SUBJECT_IN_FIELD_CONTACT,
        });
        const communityInfoOrganizations = nameReportLinksWhere({
            reportId,
            contextType: entityTypeObjectEnum.REPORT.name,
            contextId: reportId,
            linkType: LinkTypesEnum.ORGANIZATION_IN_FIELD_CONTACT,
        });
        const communityInfoLocations = locationEntityLinksWhere({
            entityType: entityTypeObjectEnum.FIELD_CONTACT.name,
            linkType: LinkTypesEnum.LOCATION_OF_FIELD_CONTACT,
            entityId: fieldContactId,
        });

        const sortedCommunityInfoSubjects = sortNameReportLinks({
            nameReportLinks: communityInfoSubjects,
        });
        const sortedCommunityInfoOrganizations = sortNameReportLinks({
            nameReportLinks: communityInfoOrganizations,
        });
        const sortedCommunityInfoLocations = sortLocationEntityLinks({
            locationEntityLinks: communityInfoLocations,
        });

        return {
            fieldContact,
            reportAttributes,
            communityInfoSubjects: sortedCommunityInfoSubjects,
            communityInfoOrganizations: sortedCommunityInfoOrganizations,
            communityInfoLocations: sortedCommunityInfoLocations,
        };
    }
);

export function replaceCommunityInfoCardBundle({ reportId, communityInfoCardBundle }) {
    return (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) => {
        const resource = getReportCardBundleResource();
        return resource
            .upsertCommunityInfoCard(reportId, communityInfoCardBundle)
            .then((updatedCommunityInfoCardBundle) => {
                const {
                    fieldContact,
                    reportAttributes,
                    nameReportLinks,
                    locationEntityLinks,
                } = updatedCommunityInfoCardBundle;
                const { id: fieldContactId } = fieldContact;
                const predicateReportAttributeObjectsToRemoveBy = map(
                    COMMUNITY_INFO_CARD_REPORT_ATTRIBUTE_TYPES,
                    (attributeType) => ({
                        reportId,
                        attributeType,
                    })
                );
                const predicateNameReportLinksToRemoveBy = map(
                    COMMUNITY_INFO_CARD_NAME_REPORT_LINK_TYPES,
                    (linkType) => ({
                        reportId,
                        linkType,
                    })
                );
                const predicateLocationEntityLinksToRemoveBy = map(
                    COMMUNITY_INFO_CARD_LOCATION_LINK_TYPES,
                    (linkType) => ({
                        linkType,
                        entityType: entityTypeObjectEnum.FIELD_CONTACT.name,
                        entityId: fieldContactId,
                    })
                );

                dispatch(
                    withEntityItems(
                        {
                            [FIELD_CONTACTS_NEXUS_STATE_PROP]: [fieldContact],
                            [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportAttributes,
                            [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinks,
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: locationEntityLinks,
                        },
                        withRemoveMultiple(
                            {
                                [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: predicateReportAttributeObjectsToRemoveBy,
                                [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: predicateNameReportLinksToRemoveBy,
                                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: predicateLocationEntityLinksToRemoveBy,
                            },
                            {
                                type: 'UPSERT_COMMUNITY_INFO_CARD_BUNDLE',
                            }
                        )
                    )
                );

                return updatedCommunityInfoCardBundle;
            });
    };
}

import { take, slice, isUndefined } from 'lodash';

/**
 * Remove from an array a single element determined by index.
 * Third argument is optional single replacement for removed element.
 * @param {Array}  array
 * @param {number} index element to remove
 * @param {*}      [replacement] single element to replace removed one
 * @return {Array}
 */
export function withoutIndex<T>(array: T[], index: number, replacement?: T): T[] {
    return [
        ...take(array, index),
        ...(!isUndefined(replacement) ? [replacement] : []),
        ...slice(array, index + 1),
    ];
}

import { CodeTypeSourceEnum, CodeTypeCategoryEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFCodeSelect } from '../../../core/forms/components/selects/CodeSelect';
import { RRFText } from '../../../core/forms/components/Text';
import FormRow from '../../../core/forms/components/FormRow';
import FormGroup from '../../../core/forms/components/FormGroup';

import { currentUcrSourceSelector } from '../state/ui/selectors';
import {
    showDomesticViolenceOnUcrEvent,
    showWeaponOrForceInvolved,
    showSuffocationOrStrangulation,
    showIsAntiReproductiveRightsCrime,
    showWereSeniorCitizensVictims,
    showNumberOfSeniorCitizenVictims,
} from '../utils/ucrClassificationHelper';
import testIds from '../../../../core/testIds';

const strings = componentStrings.reports.UcrClassificationSidePanel.UcrEventFieldset;

const mapStateToProps = createStructuredSelector({
    currentUcrSource: currentUcrSourceSelector,
});

const WeaponOrForceField = ({ currentUcrSource }) => {
    const field = (
        <RRFCodeSelect
            path="weaponOrForceInvolvedCodeId"
            codeSource={currentUcrSource}
            codeTypeCategory={CodeTypeCategoryEnum.UCR_WEAPON_OR_FORCE.name}
            length="md"
        />
    );
    return currentUcrSource === CodeTypeSourceEnum.FL_UCR.name ? (
        field
    ) : (
        <FormRow hasIndent>{field}</FormRow>
    );
};

export default connect(mapStateToProps)(({ formModelByPath, currentUcrSource }) => {
    const ucrRuleBundle = {
        currentUcrSource,
        ucrEvent: formModelByPath('ucrEvent'),
        ucrOffenseSummaryCodes: map(
            formModelByPath('ucrOffenses'),
            (ucrOffense) => ucrOffense.ucrSummaryCodeCode
        ),
    };
    return (
        <RRFFieldset title={strings.title} path="ucrEvent">
            <FormGroup>
                <RRFAttributeSelect
                    attributeType="CUSTOM_REPORT_CLASSIFICATION"
                    path="customReportClassificationAttrId"
                />
                <RRFAttributeSelect
                    attributeType="EVENT_STATISTICS"
                    path="eventStatistics"
                    multiple={true}
                    testId={testIds.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EVENT_STATISTICS_ATTRIBUTE_ID}
                />
                {showDomesticViolenceOnUcrEvent(ucrRuleBundle) && (
                    <RRFBooleanSelect path="isDomesticViolence" length="md" />
                )}
                {showWeaponOrForceInvolved(ucrRuleBundle) && (
                    <WeaponOrForceField currentUcrSource={currentUcrSource} />
                )}
                {showSuffocationOrStrangulation(ucrRuleBundle) && (
                    <FormRow hasIndent>
                        <RRFCodeSelect
                            path="suffocationOrStrangulationInvolvedCodeId"
                            codeSource={currentUcrSource}
                            codeTypeCategory={
                                CodeTypeCategoryEnum.UCR_SUFFOCATION_OR_STRANGULATION.name
                            }
                            length="md"
                        />
                    </FormRow>
                )}
                <RRFBooleanSelect path="isLeoka" length="md" />
                {showIsAntiReproductiveRightsCrime(ucrRuleBundle) && (
                    <RRFBooleanSelect path="isAntiReproductiveRightsCrime" length="md" />
                )}
                {showWereSeniorCitizensVictims(ucrRuleBundle) && (
                    <RRFBooleanSelect path="wereSeniorCitizensVictims" length="md" />
                )}
                {showNumberOfSeniorCitizenVictims(ucrRuleBundle) && (
                    <FormRow hasIndent>
                        <RRFText path="numberOfSeniorCitizenVictims" length="md" />
                    </FormRow>
                )}
            </FormGroup>
        </RRFFieldset>
    );
});

import React from 'react';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import BlacksmithIntegrationSidePanel from '../../../modules/support/blacksmith/components/BlacksmithIntegrationSidePanel';
import WarrantStubSidePanel from '../../../modules/warrants/core/components/createWarrantStubSidePanel';
import ReportCaseStatusesForm from '../../../modules/reports/core/components/case-statuses/ReportCaseStatusesSidePanel';
import DepartmentProfileAdminForm from '../admin/departmentprofile/DepartmentProfileAdminForm';
import UserProfileAdminForm from '../admin/userprofile/UserProfileAdminForm';
import UserProfileAdminRolesForm from '../admin/userprofile/UserProfileAdminRolesForm';
import FacilityAdminForm from '../../../modules/admin/evidence-facilities/components/FacilityAdminForm';
import StorageLocationAdminForm from '../../../modules/admin/evidence-facilities/components/StorageLocationAdminForm';
import CaseReviewSidePanel from '../../../modules/cases/case-review/components/CaseReviewSidePanel';
import TaskDetailsSidePanel from '../../../modules/cases/task-list/components/TaskDetailsSidePanel';
import NotificationSettingsSidePanel from '../../../modules/notifications/settings/components/NotificationSettingsSidePanel';
import DuplicateNameResultsSidePanel from '../../../modules/core/components/duplicate-name/DuplicateNameResultsSidePanel';
import ArrestUcrClassificationSidePanel from '../../../modules/reports/ucr-classification/components/ArrestUcrClassificationSidePanel';
import UcrClassificationSidePanel from '../../../modules/reports/ucr-classification/components/UcrClassificationSidePanel';
import ConnectWarrantsSidePanel from '../../../modules/warrants/core/components/ConnectWarrantsSidePanel';
import ItemSplitSidePanel from '../../../modules/evidence/item-split/components/ItemSplitSidePanel';
import ItemSplitConfirmationSidePanel from '../../../modules/evidence/item-split/components/ItemSplitConfirmationSidePanel';
import ManageItemsSidePanel from '../../../modules/reports/core/components/items/ManageItemsSidePanel';
import RecentItemsSidePanel from '../../../modules/reports/core/components/items/RecentItemsSidePanel';
import ItemSidePanel from '../../../modules/reports/core/components/items/ItemSidePanel';
import LinkItemsToOffenseSidePanel from '../../../modules/reports/core/components/items/LinkItemsToOffenseSidePanel';
import StaffRemarkSidePanel from '../../../modules/evidence/core/components/StaffRemarkSidePanel';
import AssociatedRecordsSidePanel from '../../../modules/records/associated-records/components/AssociatedRecordsSidePanel';
import WarrantActivitySidePanel from '../../../modules/warrants/warrant/components/WarrantActivitySidePanel';
import WarrantChargesSidePanel from '../../../modules/warrants/warrant/components/WarrantChargesSidePanel';
import GangTrackingSidePanel from '../../../modules/reports/gang-tracking/components/GangTrackingSidePanel';
import BookingSidePanel from '../../../modules/reports/core/components/booking/side-panel/BookingSidePanel';

import { saveDepartmentPanel } from '../../actions/departmentProfileAdminActions';
import { saveUserProfilePanel } from '../../actions/userProfileAdminActions';
import { saveUserProfileRolesPanel } from '../../actions/rolesActions';
import AddNewRoleSidePanel from '../../../modules/admin/roles/components/AddNewRoleSidePanel';
import AssignUserRoleSidePanel from '../../../modules/admin/roles/components/AssignUserRoleSidePanel';
import {
    sidePanelIsOpenSelector,
    sidePanelZIndexOverrideSelector,
} from '../../selectors/boxSelectors';
import zIndexes from '../../../modules/core/styles/zIndexes';
import {
    AdminErrorBoundary,
    CaseManagementErrorBoundary,
    EvidenceErrorBoundary,
    ReportsErrorBoundary,
    WarrantsErrorBoundary,
} from '../../../modules/core/errors/components/ErrorBoundary';

function SidePanels(props) {
    const zIndex = props.sidePanelZIndexOverride || zIndexes.reactSidepanelContainer;
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isMainNavEnabled = applicationSettings.RMS_ARC_NAVIGATION_ENABLED;

    return (
        <div style={{ position: 'absolute', zIndex }}>
            {props.sidePanelIsOpen && (
                <div
                    className={classNames('mark43-modal-overlay dimmed', {
                        'no-header': isMainNavEnabled,
                    })}
                    style={{ zIndex: 5 }}
                />
            )}
            <AdminErrorBoundary>
                <DepartmentProfileAdminForm onSubmit={props.saveDepartmentPanel} />
                <FacilityAdminForm />
                <StorageLocationAdminForm />
                <UserProfileAdminForm
                    onAddSubmit={props.saveAddUserProfilePanel}
                    onEditSubmit={props.saveEditUserProfilePanel}
                />
                <UserProfileAdminRolesForm onSubmit={props.saveUserProfileRolesPanel} />
                <NotificationSettingsSidePanel />
                <AddNewRoleSidePanel />
                <AssignUserRoleSidePanel />
                <BlacksmithIntegrationSidePanel />
            </AdminErrorBoundary>
            <CaseManagementErrorBoundary>
                <TaskDetailsSidePanel />
                <CaseReviewSidePanel />
            </CaseManagementErrorBoundary>
            <ReportsErrorBoundary>
                <ReportCaseStatusesForm />
                <ManageItemsSidePanel />
                {/* RecentItemsSidePanel and ItemSidePanel must be AFTER ManageItemsSidePanel */}
                <RecentItemsSidePanel />
                <ItemSidePanel />
                <LinkItemsToOffenseSidePanel />
                <ArrestUcrClassificationSidePanel />
                <UcrClassificationSidePanel />
                <GangTrackingSidePanel />
                <BookingSidePanel />
                <AssociatedRecordsSidePanel />
            </ReportsErrorBoundary>
            <EvidenceErrorBoundary>
                <ItemSplitSidePanel />
                <ItemSplitConfirmationSidePanel />
                <StaffRemarkSidePanel />
            </EvidenceErrorBoundary>
            <WarrantsErrorBoundary>
                <ConnectWarrantsSidePanel />
                <WarrantStubSidePanel />
                <WarrantActivitySidePanel />
                <WarrantChargesSidePanel />
            </WarrantsErrorBoundary>

            <DuplicateNameResultsSidePanel />
        </div>
    );
}

function mapDispatchToProps(dispatch, { router }) {
    return {
        saveDepartmentPanel: (deptProfile) => dispatch(saveDepartmentPanel(deptProfile)),
        saveAddUserProfilePanel: (userProfile) =>
            dispatch(saveUserProfilePanel({ userProfile, isEditing: false, router })),
        saveEditUserProfilePanel: (userProfile) =>
            dispatch(saveUserProfilePanel({ userProfile, isEditing: true, router })),
        saveUserProfileRolesPanel: (userId, roleIdsToCreate, roleIdsToDelete) =>
            dispatch(saveUserProfileRolesPanel(userId, roleIdsToCreate, roleIdsToDelete)),
    };
}

const mapStateToProps = createStructuredSelector({
    sidePanelIsOpen: sidePanelIsOpenSelector,
    sidePanelZIndexOverride: sidePanelZIndexOverrideSelector,
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SidePanels);

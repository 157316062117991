import { ProductModuleEnum, AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { otherUserId } from '~/client-common/helpers/userHelpers';
import createWarrantStubPanelForm from '../state/forms/createWarrantStubPanelForm';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFTextArea } from '../../../core/forms/components/TextArea';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import Row from '../../../core/components/Row';
import IndentedFields from '../../../core/components/IndentedFields';
import {
    closeCreateWarrantStubPanel,
    saveWarrantFromWarrantStubSidePanel,
} from '../state/ui/createWarrantStubSidePanel';

const strings = componentStrings.warrants.core.WarrantStubSidePanel;

const context = { name: boxEnum.CREATE_WARRANT_SIDE_PANEL };

const mapStateToProps = createStructuredSelector({
    isProductModuleActive: isProductModuleActiveSelector,
    formModelByPath: createWarrantStubPanelForm.selectors.formModelByPathSelector,
});

function WarrantStubSidePanel({ formModelByPath, onSave, onCancel, isProductModuleActive }) {
    const isWarrantModuleActive = isProductModuleActive(ProductModuleEnum.WARRANTS.name);
    return (
        <SidePanel
            title={strings.title}
            onSave={onSave}
            onCancel={onCancel}
            context={context}
            width={470}
        >
            <ReactReduxForm {...createWarrantStubPanelForm}>
                {isWarrantModuleActive && (
                    <Row>
                        <RRFAttributeSelect
                            path="warrantTypeAttrId"
                            width={240}
                            attributeType={AttributeTypeEnum.WARRANT_TYPE.name}
                        />
                    </Row>
                )}
                <Row>
                    <RRFText path="warrantNumber" width={180} />
                    <RRFText path="reportingEventNumber" width={180} />
                </Row>
                <Row>
                    <RRFTextArea path="description" width={360} rows={3} />
                </Row>
                {isWarrantModuleActive && (
                    <Row>
                        <RRFUserSelect
                            path="obtainingOfficerUserId"
                            includeOther={true}
                            width={240}
                        />
                    </Row>
                )}
                {isWarrantModuleActive &&
                    formModelByPath('obtainingOfficerUserId') === otherUserId && (
                        <IndentedFields>
                            <Row>
                                <RRFText path="obtainingOfficerOther" width={240} />
                            </Row>
                        </IndentedFields>
                    )}
                {isWarrantModuleActive && (
                    <Row>
                        <RRFDatePicker path="warrantIssuedDateUtc" width={120} />
                    </Row>
                )}
                {isWarrantModuleActive && (
                    <Row>
                        <RRFText path="issuingJudge" width={240} />
                    </Row>
                )}
                {isWarrantModuleActive && (
                    <Row>
                        <RRFText path="bailAmount" width={240} />
                    </Row>
                )}
                <Row>
                    <RRFBooleanSelect path="isOtherJurisdiction" includeOther={true} width={240} />
                </Row>
                <Row>
                    <RRFText path="issuingAgencyOri" width={240} />
                </Row>
                <Row>
                    <RRFText path="issuingAgencyName" width={240} />
                </Row>
            </ReactReduxForm>
        </SidePanel>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onSave: () => dispatch(saveWarrantFromWarrantStubSidePanel()),
    onCancel: () => dispatch(closeCreateWarrantStubPanel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarrantStubSidePanel);

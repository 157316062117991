import $ from 'jquery';
// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Warrants Resource',
        methods: {
            getDesktopMetadata(port) {
                return $.ajax({
                    method: 'GET',
                    url: `http://localhost:${port}/api/metadata`,
                    dataType: 'json',
                });
            },
            getWarrant(id) {
                return req({
                    method: 'GET',
                    url: `warrants/${id}`,
                });
            },
            getWarrantTitlesForWarrantNumber(warrantNumber) {
                return req({
                    method: 'GET',
                    url: `warrants/title`,
                    params: {
                        warrant_number: warrantNumber, // eslint-disable-line camelcase
                    },
                });
            },
            /**
             * Get an array of `FullWarrant`s given a list of `ids`
             * We use this method instead of a singular `getFullWarrantById`
             * because search also uses this endpoint, and it was easier to
             * just make one endpoint to share.
             * @param  {Number[]} ids List of warrant ids to fetch
             * @return {Promise<Object>}
             */
            getFullWarrantsByIds(ids) {
                return req({
                    method: 'GET',
                    url: `warrants/full`,
                    params: {
                        ids,
                    },
                });
            },
            createWarrant(warrantCreationRequest) {
                return req({
                    method: 'POST',
                    url: 'warrants/create',
                    data: warrantCreationRequest,
                });
            },
            updateWarrant(warrant, warrantRen) {
                return req({
                    method: 'PUT',
                    url: 'warrants',
                    params: {
                        reporting_event_number: warrantRen,
                    },
                    data: warrant,
                });
            },
            deleteWarrant(id) {
                return req({
                    method: 'DELETE',
                    url: `warrants/${id}`,
                });
            },
            createStubWarrant(stubWarrant, warrantRen) {
                return req({
                    method: 'POST',
                    url: 'warrants/stub/create',
                    params: {
                        reporting_event_number: warrantRen,
                    },
                    data: stubWarrant,
                });
            },
            updateStubWarrant(stubWarrant) {
                return req({
                    method: 'PUT',
                    url: 'warrants/stub/update',
                    data: stubWarrant,
                });
            },
            isWarrantDeletable(id) {
                return req({
                    method: 'GET',
                    url: `warrants/${id}/is_deletable`,
                });
            },
            getWarrantsForNumber(warrantNumber) {
                return req({
                    method: 'GET',
                    url: 'warrants',
                    params: {
                        warrant_number: warrantNumber, // eslint-disable-line camelcase
                    },
                });
            },
            updateWarrantStatusByWarrantId(warrantId, warrantStatus) {
                return req({
                    method: 'PUT',
                    url: `warrants/${warrantId}/status`,
                    data: warrantStatus,
                });
            },
            getWarrantAttributesForWarrantId(warrantId) {
                return req({
                    method: 'GET',
                    url: `warrants/${warrantId}/attributes`,
                });
            },
            /**
             * Replace attributes related to a warrant.
             * @param  {Integer} warrantId      The warrant id.
             * @param  {Object[]} attributes    A list of updated warrant attributes
             * @param  {String} attributeType   The Attribute type to replace
             * @return {Promise<Object>}
             */
            replaceWarrantAttributesForWarrantId(warrantId, attributes, attributeType) {
                return req({
                    method: 'PUT',
                    url: `warrants/${warrantId}/attributes`,
                    data: attributes,
                    params: {
                        type: attributeType,
                    },
                });
            },
            // "Charges" (deprecated on a warrant) are different from "Warrant Charges" below
            replaceChargesForWarrantId(warrantId, charges) {
                return req({
                    method: 'PUT',
                    url: `warrants/${warrantId}/charges`,
                    data: charges,
                });
            },
            // "Warrant Charges" are different from "Charges" above
            replaceWarrantChargesForWarrantId(warrantId, charges) {
                return req({
                    method: 'PUT',
                    url: `warrants/charges/${warrantId}`,
                    data: charges,
                });
            },
            upsertWarrantCharges(warrantCharges) {
                return req({
                    method: 'POST',
                    url: 'warrants/charges',
                    data: warrantCharges,
                });
            },
            deleteWarrantCharge(warrantChargeId) {
                return req({
                    method: 'DELETE',
                    url: `warrants/charges/${warrantChargeId}`,
                });
            },
            replaceWarrantDexSubmissionsForWarrantId(warrantId, warrantDexSubmission) {
                return req({
                    method: 'PUT',
                    url: `warrants/${warrantId}/dex_submission`,
                    data: warrantDexSubmission,
                });
            },
            sendWarrantDexSubmission({ warrantId, sendType, machineName }) {
                return req({
                    method: 'POST',
                    url: `warrants/${warrantId}/dex_submission/${sendType}/send`,
                    data: machineName,
                });
            },
            getWarrantDexReturns(warrantId) {
                return req({
                    method: 'GET',
                    url: `warrants/${warrantId}/dex_return`,
                });
            },
            getWarrantHistory(warrantId) {
                return req({
                    method: 'GET',
                    url: `warrants/${warrantId}/history`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
/**
 * Resources for Warrants. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
export default resourceGetter;

import _ from 'lodash';
import { groupAttributeIdsByType } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import {
    internalFieldsForPhoneNumber,
    convertPhoneToFormModel,
} from '~/client-common/helpers/phoneNumberHelpers';
import { userProfileFormFieldList, userProfileCjisExtensionFieldNames } from '../configs/adminConfig';
import { setFormValues } from './formReducerHelpers';

export const convertUserProfileDataStateToFormState = (state, profile) => {
    const profileData = {
        ...profile,
        primaryEmail: profile.user && profile.primaryEmail,
        isDisabled: profile.user && profile.user.isDisabled,
        isSsoUser: profile.isSsoUser,
    };
    const cjisData = { ...profile.userProfileCjisExtension };

    return setFormValues(
        state,
        {
            ...profileData,
            ...cjisData,
        },
        userProfileFormFieldList
    );
}
export const convertPhonesDataStateToFormState = (phones) =>
    _.map(phones, (phone) =>
        setFormValues({}, convertPhoneToFormModel(phone), [
            'phoneNumber',
            ...internalFieldsForPhoneNumber,
        ])
    );

export const convertAttributesDataStateToFormState = (attributes) =>
    setFormValues({}, groupAttributeIdsByType(attributes));

export const convertDutyStatusesDataStateToFormState = (dutyStatuses) =>
    _.map(dutyStatuses, (dutyStatus) =>
        setFormValues({}, dutyStatus, ['dutyStatus', 'dateEffective', 'id'])
    );

export function mapAttributes(attributes, attributeNames, formatAttributeById) {
    return _(attributes).map((attribute) => {
        return {
            ...attribute,
            name: attributeNames[attribute.attributeType],
            value: formatAttributeById(attribute.attributeId),
        };
    });
}

export function convertUserAssignmentsDataStateToFormState(userAssignments) {
    return _.map(userAssignments, (userAssignment) =>
        setFormValues({}, userAssignment, [
            'actingRankAttrId',
            'branchAttrId',
            'bureauAttrId',
            'divisionAttrId',
            'startDateUtc',
            'isDetail',
            'subdivision1AttrId',
            'subdivision2AttrId',
            'subdivision3AttrId',
            'subdivision4AttrId',
            'subdivision5AttrId',
            'rankAttrId',
            'title',
            'endDateUtc',
            'personnelUnitAttrId',
        ])
    );
}

/**
 * This method only converts the fields that are flat on UserProfile, and leaves all other properties intact.
 *   Some of those other properties such as UserAttributes and UserPhones are converted by other helper functions.
 */
export function convertUserProfileFormModel(userProfileFormData, { attributeIsOther }) {
    const { stopUserAssignmentTypeAttrId, isNonbinaryOfficer } = userProfileFormData;
    let { stopUserAssignmentTypeOther, yearsOfExperience } = userProfileFormData;

    if (stopUserAssignmentTypeAttrId && !attributeIsOther(stopUserAssignmentTypeAttrId)) {
        stopUserAssignmentTypeOther = undefined;
    }

    if (yearsOfExperience) {
        const number = _.parseInt(yearsOfExperience, 10);
        yearsOfExperience = number >= 0 ? number : undefined;
    } else {
        yearsOfExperience = undefined;
    }

    return {
        ...userProfileFormData,
        userProfileCjisExtension: _.pick(userProfileFormData, userProfileCjisExtensionFieldNames),
        stopUserAssignmentTypeOther,
        yearsOfExperience,
        isNonbinaryOfficer,
    };
}

export function convertAttributesFormModelToUserAttributes(userId, attributes) {
    // displaying as a single select but storing under user attributes table so needs to be an array
    if (
        !!attributes['STOP_DATA_OFFICER_GENDER'] &&
        !Array.isArray(attributes['STOP_DATA_OFFICER_GENDER'])
    ) {
        attributes['STOP_DATA_OFFICER_GENDER'] = [attributes['STOP_DATA_OFFICER_GENDER']];
    }
    return _(attributes)
        .map((ids, attrType) => {
            return _(ids)
                .map((id) => {
                    return { userProfileId: userId, attributeType: attrType, attributeId: id };
                })
                .value();
        })
        .flatten()
        .value();
}

export function convertPhonesFormModelToUserPhones(userId, phones) {
    return _(phones)
        .map('phoneNumber')
        .compact()
        .map((phoneNumber) => {
            return { userProfileId: userId, phoneNumber };
        })
        .value();
}

import { AttributeTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { attributeTypeHasActiveAttributesForCurrentDepartmentSelector } from '../../modules/core/attributes/state/ui';

export const subdivisionsUsageSelector = createSelector(
    attributeTypeHasActiveAttributesForCurrentDepartmentSelector,
    (attributeTypeHasActiveAttributesForCurrentDepartment) => {
        return {
            subdivision1: !!attributeTypeHasActiveAttributesForCurrentDepartment(
                AttributeTypeEnum.SUBDIVISION_DEPTH_1.name
            ),
            subdivision2: !!attributeTypeHasActiveAttributesForCurrentDepartment(
                AttributeTypeEnum.SUBDIVISION_DEPTH_2.name
            ),
            subdivision3: !!attributeTypeHasActiveAttributesForCurrentDepartment(
                AttributeTypeEnum.SUBDIVISION_DEPTH_3.name
            ),
            subdivision4: !!attributeTypeHasActiveAttributesForCurrentDepartment(
                AttributeTypeEnum.SUBDIVISION_DEPTH_4.name
            ),
            subdivision5: !!attributeTypeHasActiveAttributesForCurrentDepartment(
                AttributeTypeEnum.SUBDIVISION_DEPTH_5.name
            ),
        };
    }
);

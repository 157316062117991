import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map, size } from 'lodash';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import testIds from '../../../../core/testIds';
import { caseReviewsForCurrentCaseViewModelSelector } from '../state/ui';
import CaseReviewForm from './CaseReviewForm';
import CaseReviewComment from './CaseReviewComment';

const strings = componentStrings.cases.caseReviews.CaseReviewSidePanel;

const context = {
    name: boxEnum.CASE_REVIEW_SIDE_PANEL,
};

function CaseReviewSidePanel({ caseReviewViewModels }) {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    const caseReviewComments = map(caseReviewViewModels, (caseReviewViewModel) => {
        return (
            <CaseReviewComment
                key={caseReviewViewModel.id}
                caseReviewViewModel={caseReviewViewModel}
            />
        );
    });

    return (
        <SidePanel
            title={strings.title(caseDisplayName)}
            cancelText={'CLOSE'}
            saveText={null}
            context={context}
        >
            <div className="case-review-side-panel-content">
                <div className="case-review-comments" data-test-id={testIds.CASE_REVIEW_COMMENTS}>
                    {size(caseReviewComments) > 0 ? caseReviewComments : strings.noComments}
                </div>
                <CaseReviewForm />
            </div>
        </SidePanel>
    );
}

const mapStateToProps = createStructuredSelector({
    caseReviewViewModels: caseReviewsForCurrentCaseViewModelSelector,
});

export default connect(mapStateToProps)(CaseReviewSidePanel);

import _ from 'lodash';
import { createSelector } from 'reselect';

import { sortByNaturalOrder } from '../../../../../helpers/arrayHelpers';
import { codesByCodeIdSelector, codesByCodeTypeSourceSelector } from '../data';

export const formatCode = (code) => {
    if (!code) {
        return code;
    }
    return `${code.code} - ${code.description}`;
};

export const formatCodeByIdSelector = createSelector(codesByCodeIdSelector, (codeById) => (id) =>
    formatCode(codeById(id))
);

export const codeOptionsByCodeTypeCategorySelector = createSelector(
    codesByCodeTypeSourceSelector,
    (codesByCodeTypeSource) => (codeTypeSource) =>
        _(codesByCodeTypeSource(codeTypeSource))
            .map((code) => ({
                value: code.id,
                code: code.code,
                display: formatCode(code),
                type: code.codeType.category,
            }))
            .groupBy('type')
            .mapValues((codes) => sortByNaturalOrder(codes, 'display'))
            .value()
);

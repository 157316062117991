import React from 'react';
import styled from 'styled-components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

const CellWrapper = styled.div`
    margin-left: 12px;
`;
const ResponsiblePersonnelCell = (inventory) => {
    const { ownerUserId } = getViewModelProperties(inventory);

    return <CellWrapper>{ownerUserId.fullNameWithFirstInitialAndIdNumber}</CellWrapper>;
};

export default ResponsiblePersonnelCell;

import React from 'react';
import styled from 'styled-components';
import { Status } from '@arc/badge';
import { Text, cssVar, Flex } from 'arc';
import { JmsBooking, JmsBookingStatusEnumType, JmsBookingStatusEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import Highlighter from '../QuickSearchHighlighter';

const StyledBookingInfoWrapper = styled.div`
    min-height: 56px;
    display: flex;
    padding: 8px;
    gap: ${cssVar('arc.space.3')};
`;

const StyledBookingDate = styled(FormattedDate)`
    margin-left: auto;
    font: ${(props) =>
        `${props.theme.fontWeights.regular} var(--arc-fontSizes-md) ${props.theme.fontFamilies.proximaNova}`};
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const getStatusStyleByValue = (status: JmsBookingStatusEnumType) => {
    switch (status) {
        case JmsBookingStatusEnum.RELEASED.name:
            return 'positive';
        case JmsBookingStatusEnum.IN_CUSTODY.name:
        case JmsBookingStatusEnum.IN_TRANSFER.name:
            return 'inProgress';
        case JmsBookingStatusEnum.PENDING_BOOKING.name:
            return 'attention';
        default:
            return 'neutral';
    }
};
export const QuickSearchResultSectionBookingItem = ({
    item,
    query,
}: {
    item: JmsBooking;
    query: string;
}) => {
    const { quickSearch: quickSearchStrings } = componentStrings;
    const searchWords = [query];
    const name = quickSearchStrings.QuickSearchResultsSection.detaineeName(
        item.detainee.lastName,
        item.detainee.firstName
    );
    const title = `${quickSearchStrings.core.elasticSearchTypeHeader.BOOKING} #${item.bookingNumber}`;
    return (
        <StyledBookingInfoWrapper>
            <Flex alignItems={'center'}>
                <Icon color="mediumLightGrey" type={iconTypes.BOOKING} size={20} />
            </Flex>
            <Flex flexDirection={'column'}>
                <Flex gap={cssVar('arc.space.1')}>
                    <Text>
                        <Highlighter searchWords={searchWords} textToHighlight={title} />
                    </Text>
                    <Status status={getStatusStyleByValue(item.status)}>{item.status}</Status>
                </Flex>
                <Text color={'placeholder'} variant={'caption'}>
                    {name}
                </Text>
            </Flex>
            <StyledBookingDate date={item.beginDate} format={FormattedDate.FORMATS.SHORT_DATE} />
        </StyledBookingInfoWrapper>
    );
};

import React, { useMemo } from 'react';
import { chain, filter, find, isEmpty, map, omit } from 'lodash';
import styled from 'styled-components';
import classNames from 'classnames';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import _Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import { Button as ArcButton } from '../../../components/Button';
import testIds from '../../../../../core/testIds';

import { ButtonRadioOption, LegacyButtonRadioOption } from './types';

const Button = styled(_Button)`
    margin: 0;
`;

const ButtonRadioWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 10px 10px;
`;

type ButtonRadioButtonProps = {
    option: ButtonRadioOption;
    onChange: (option: ButtonRadioOption, selected: boolean) => void;
    selected: boolean;
};

const ButtonRadioButton: (props: ButtonRadioButtonProps) => JSX.Element = ({
    onChange,
    option,
    selected,
}) => (
    <FeatureFlagged
        flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
        fallback={
            <Button
                children={option.label}
                className={classNames(buttonTypes.SECONDARY, { selected })}
                onClick={() => onChange(option, selected)}
                testId={testIds.BUTTON_RADIO_BUTTON}
            />
        }
    >
        <ArcButton
            children={option.label}
            onClick={() => onChange(option, selected)}
            testId={testIds.BUTTON_RADIO_BUTTON}
            variant={selected ? 'solid' : 'outline'}
        />
    </FeatureFlagged>
);

type MultiSelectButtonRadioProps<Option extends LegacyButtonRadioOption | ButtonRadioOption> = {
    multiple?: boolean;
    onChange?: (newSelectedOptions: ButtonRadioOption[]) => void;
    options: Option[];
    selectedOptions: ButtonRadioOption[];
};

const MultiSelectButtonRadio: (
    props: MultiSelectButtonRadioProps<LegacyButtonRadioOption | ButtonRadioOption>
) => JSX.Element = ({ multiple = true, onChange, options, selectedOptions }) => {
    const convertedOptions = useMemo(
        () =>
            // check if the options are legacy
            !isEmpty(options) && 'display' in options[0]
                ? map(options as LegacyButtonRadioOption[], (option) => ({
                      ...omit(option, ['display']),
                      label: option.display,
                  }))
                : !isEmpty(options) && 'label' in options[0]
                ? options
                : (options as never),
        [options]
    ) as ButtonRadioOption[];

    const handleChange = (clickedOption: ButtonRadioOption, selected: boolean) => {
        // if it's selected remove it from selected values
        // if it's not selected:
        //      if it's a none option return an array containing only the none option
        //      if it's not a none option add it to selected values and remove any none options
        const newSelectedMultiOptions = selected
            ? filter(selectedOptions, ({ value }) => value !== clickedOption.value)
            : clickedOption.none
            ? [clickedOption]
            : chain(selectedOptions)
                  .filter((option) => !option.none)
                  .concat(clickedOption)
                  .compact()
                  .value();

        const newSelectedSingleOption = [clickedOption];

        const newSelectedOptions = multiple ? newSelectedMultiOptions : newSelectedSingleOption;

        if (onChange) {
            onChange(newSelectedOptions);
        }
    };

    return (
        <ButtonRadioWrapper>
            {map(convertedOptions, (option, index) => (
                <ButtonRadioButton
                    key={index}
                    option={option}
                    selected={!!find(selectedOptions, ({ value }) => value === option.value)}
                    onChange={handleChange}
                />
            ))}
        </ButtonRadioWrapper>
    );
};

export default MultiSelectButtonRadio;

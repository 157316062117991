import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { RefContextEnum } from '@mark43/rms-api';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { submitCreateManageCaseForm, submitCreateManageCaseFormForBulk } from '../state/data';
import { closeCreateManageCaseModal } from '../state/ui';
import { OverlayBase } from '../../../../core/overlayManager';
import { PortalModal } from '../../../../legacy-redux/components/core/Modal';
import { resetReasonForRelationForm } from '../state/form/reasonForRelationForm';
import CreateManageCaseForm from './CreateManageCaseForm';

const CreateManageCaseModal = ({
    title,
    okText,
    onSave,
    onClose,
    includeLocalIdInTitle,
    isBulkCreate,
    helpText,
    onSaveBulkCreate,
    uniqueRensSelected,
    uniqueRecordWithoutRenReportIdsSelected = [],
    onCloseRef,
}) => {
    return (
        <OverlayBase id={overlayIdEnum.CREATE_MANAGE_CASE_MODAL}>
            {(overlayProps) => {
                const localId = overlayProps.overlayState.customProperties.localId;
                const removalRequest = overlayProps.overlayState.customProperties.removalRequest;
                const fullTitle = includeLocalIdInTitle
                    ? joinTruthyValues([title, localId], ' # ')
                    : title;
                return (
                    <PortalModal
                        {...overlayProps}
                        title={fullTitle}
                        okText={okText}
                        id={overlayIdEnum.CREATE_MANAGE_CASE_MODAL}
                        shouldCloseOnOverlayClick={true}
                        onSave={
                            isBulkCreate
                                ? onSaveBulkCreate(
                                      uniqueRensSelected,
                                      uniqueRecordWithoutRenReportIdsSelected
                                  )
                                : () => onSave(removalRequest)
                        }
                        onClose={onClose}
                        modalClassName="case-details-modal"
                        onCloseRef={onCloseRef}
                    >
                        <CreateManageCaseForm isBulkCreate={isBulkCreate} helpText={helpText} />
                    </PortalModal>
                );
            }}
        </OverlayBase>
    );
};

const mapDispatchToProps = (dispatch, { router, nameCaseLinks, itemCaseLinks }) => ({
    onSave: (removalRequest) =>
        dispatch(
            submitCreateManageCaseForm({ router, removalRequest, nameCaseLinks, itemCaseLinks })
        ),
    onSaveBulkCreate: (reportingEventNumbers, recordWithoutRenReportIds) => () =>
        dispatch(
            submitCreateManageCaseFormForBulk({
                reportingEventNumbers,
                recordWithoutRenReportIds,
                router,
            })
        ),
    onClose: () => {
        dispatch(closeCreateManageCaseModal());
        resetReasonForRelationForm(RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name);
    },
});

export default compose(withRouter, connect(null, mapDispatchToProps))(CreateManageCaseModal);

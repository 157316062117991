import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
    Box,
    Text,
    Wrap,
    VStack,
    cssVar,
    HStack,
    Avatar,
    RadioGroup,
    Radio,
    WrapItem,
    Button,
} from 'arc';
import { map, find, range } from 'lodash';
import {
    PhotoLineupView,
    PhotoLineupSlotView,
    HydratedImage,
    OperationTypeEnum,
    Attachment,
} from '@mark43/rms-api';

import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';

import { currentCaseSelector } from '../../core/state/ui';
import testIds from '../../../../core/testIds';
import { useLineupContext } from '../state/ui';
import { POI_VALUE } from '../state/data/createLineupModal';
import { getLineupName } from '../utils/helpers';
import { usePhotoLineupFieldName } from '../hooks/usePhotoLineupFieldName';
import Link from '../../../core/components/links/Link';
import { usePhotoLineupPersonProfiles } from '../hooks/usePhotoLineupPersonProfiles';
import LineupKebabMenu from './LineupKebabMenu';

const strings = componentStrings.cases.casePhotoLineups;
const composeLineupStrings = strings.ComposeLineup;
const caseLineupStrings = strings.CasePhotoLineups;
const MAX_BLANK_SLOTS = 6; // TODO: This needs to be changed to 5 once a suspect can be set since they can't be changed out

const PhotoRadio = styled(Radio)`
    background-color: ${cssVar('arc.colors.surface.background')};
    position: absolute;
    transform: translate(80px, -35px);
    z-index: ${cssVar('arc.zIndices.dropdown')}; // needed to make radio appear on top of photo
`;
const StyleLink = styled(Link)`
    &&:focus {
        box-shadow: none;
    }
`;

export const LineupPhotoWidth = {
    SUMMARY_ROW: '72px',
    CREATE_LINEUP_MODAL: '96px',
    COMPOSE_PAGE: '116px',
    DETAILS_PAGE: '144px',
} as const;

const LineupPhotoRowWidths = {
    SUMMARY_ROW: '472px',
    COMPOSE_PAGE_REFERENCE_AREA: '250px',
    CREATE_LINEUP_MODAL: '512px',
    DETAILS_PAGE: '905px',
} as const;

type LineupSlotProps = {
    photoPath: string | undefined;
    photoWidth: ValueOf<typeof LineupPhotoWidth>;
    onPhotoClick?: () => void;
    removeImage?: () => void;
    isPoiImage?: boolean;
};

export const SlotPhoto = styled(Avatar)<{ $photoWidth: LineupSlotProps['photoWidth'] }>`
    margin-bottom: 4px;
    border: ${cssVar('arc.borders.sm')} ${cssVar('arc.colors.border.default')};
    background-color: ${cssVar('arc.colors.surface.foreground')};
    color: ${cssVar('arc.colors.icon.default')};
    width: ${(props) => props.$photoWidth};
    height: ${(props) => props.$photoWidth};
    border-radius: 0px;

    img {
        /**
         * Lineup photos must not be cropped. When the photo is not a square, we display it in full with letterboxing.
         */
        object-fit: contain;
        border-radius: 0px;
    }
`;

const HoverableSlotPhotoContainer = styled.div`
    position: relative;
`;

const HoverableSlotPhoto = styled(SlotPhoto)`
    ${HoverableSlotPhotoContainer}:hover & {
        filter: brightness(40%);
    }
`;

const RemoveSlotButton = styled(Button)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    ${HoverableSlotPhotoContainer}:hover & {
        visibility: visible;
    }
`;

export const LineupSlot: React.FC<LineupSlotProps> = ({ photoPath, photoWidth, onPhotoClick }) => {
    return <SlotPhoto src={photoPath} $photoWidth={photoWidth} onClick={onPhotoClick} />;
};

const BlankLineupSlot: React.FC<Pick<LineupSlotProps, 'photoWidth'>> = ({ photoWidth }) => {
    return <SlotPhoto $photoWidth={photoWidth} />;
};

type ValueOf<T> = T[keyof T];

type FooterProps = {
    primaryText: string;
    secondaryText?: string;
};

type LineupPhotoRowProps = {
    photoWidth: ValueOf<typeof LineupPhotoWidth>;
    rowWidth: ValueOf<typeof LineupPhotoRowWidths>;
    slots?: PhotoLineupSlotView[];
    images?: HydratedImage[];
    showBlanks?: boolean;
    removeImage?: (id: number) => void;
    mugshotAttachments?: Attachment[];
    renderEachFooter?: (slot: FooterProps) => JSX.Element | null;
    onPhotoClick?: (value: React.SetStateAction<number>) => void;
    personOfInterestMasterId?: number;
};

const LineupPhotoRowSlotPhoto: React.FC<LineupSlotProps> = ({
    photoPath,
    photoWidth,
    onPhotoClick,
    removeImage,
    isPoiImage,
}) => {
    return (
        <>
            {removeImage && !isPoiImage ? (
                <HoverableSlotPhotoContainer>
                    <HoverableSlotPhoto
                        src={photoPath}
                        $photoWidth={photoWidth}
                        onClick={onPhotoClick}
                    />

                    <RemoveSlotButton onClick={removeImage} colorVariant="white" variant="solid">
                        {composeLineupStrings.remove}
                    </RemoveSlotButton>
                </HoverableSlotPhotoContainer>
            ) : (
                <LineupSlot
                    photoPath={photoPath}
                    photoWidth={photoWidth}
                    onPhotoClick={onPhotoClick}
                />
            )}
        </>
    );
};

const LineupPhotoRow: React.FC<LineupPhotoRowProps> = ({
    photoWidth,
    rowWidth,
    slots,
    images,
    showBlanks,
    removeImage,
    mugshotAttachments,
    renderEachFooter,
    onPhotoClick,
    personOfInterestMasterId,
}) => {
    const { relatedPersons } = usePhotoLineupPersonProfiles();
    const blankSlots =
        slots && slots.length < MAX_BLANK_SLOTS
            ? range(slots.length + 1, MAX_BLANK_SLOTS + 1)
            : range(0);

    return (
        <Wrap w={rowWidth} data-test-id={testIds.PHOTO_LINEUP_DETAIL_VIEW}>
            {map(slots, (slot, index) => {
                const { id, imageId, processedImageId, masterPersonId, firstName, lastName } = slot;

                // get the corresponding HydratedImage by calling lodash find on images with the imageId/processedImageId
                // then use the thumbnailLargeFile and fileWebServerPath for image src i.e. path
                // if thumbnailLargeFile does not exist, show the originalFile ( ex. manual photos)
                const foundImage = find(
                    images,
                    (image) => imageId === image.id || processedImageId === image.id
                );
                const path = foundImage?.thumbnailLargeFile
                    ? foundImage?.thumbnailLargeFile?.fileWebServerPath
                    : foundImage?.originalFile?.fileWebServerPath;

                const getName = () => {
                    if (firstName && lastName) {
                        return `${firstName} ${lastName}`;
                    }
                    if (masterPersonId) {
                        // @ts-expect-error client-common to client RND-7529
                        return getLineupName(masterPersonId, relatedPersons);
                    }
                    return 'Unknown';
                };

                if (!path) {
                    return null;
                }

                return (
                    <WrapItem key={`${id}-${index}`}>
                        <VStack>
                            <LineupPhotoRowSlotPhoto
                                photoPath={path}
                                photoWidth={photoWidth}
                                onPhotoClick={() =>
                                    onPhotoClick ? onPhotoClick(imageId) : undefined
                                }
                                removeImage={removeImage ? () => removeImage(imageId) : undefined}
                                isPoiImage={slot.masterPersonId === personOfInterestMasterId}
                            />
                            {renderEachFooter && (
                                <Box width={photoWidth}>
                                    {renderEachFooter({
                                        primaryText: `${index + 1}`,
                                        secondaryText: getName(),
                                    })}
                                </Box>
                            )}
                        </VStack>
                    </WrapItem>
                );
            })}
            {showBlanks &&
                map(blankSlots, (blank) => (
                    <VStack key={blank}>
                        <BlankLineupSlot photoWidth={photoWidth} />
                        <Box width={photoWidth}>
                            <Text variant="headingXs" align="center">
                                {blank}
                            </Text>
                        </Box>
                    </VStack>
                ))}
            {map(mugshotAttachments, (mugshot) => {
                const { id, attachmentId, image, createdDateUtc } = mugshot;
                const path = image?.originalFile?.fileWebServerPath;
                if (!path) {
                    return null;
                }

                return (
                    <VStack spacing={cssVar('arc.space.px')} key={id}>
                        <PhotoRadio
                            value={attachmentId}
                            data-test-id={testIds.PHOTO_LINEUP_CREATE_MODAL_MUGSHOT}
                        >
                            <LineupSlot
                                key={id}
                                photoPath={path}
                                photoWidth={photoWidth}
                                onPhotoClick={() =>
                                    onPhotoClick ? onPhotoClick(image.id) : undefined
                                }
                            />
                        </PhotoRadio>
                        {renderEachFooter && (
                            <Box width={photoWidth} textAlign="center">
                                {renderEachFooter({ primaryText: createdDateUtc })}
                            </Box>
                        )}
                    </VStack>
                );
            })}
        </Wrap>
    );
};

export const LineupComposeColumn: React.FC<{
    lineupView: PhotoLineupView | undefined;
    onRemove: (id: number) => void;
}> = ({ lineupView, onRemove }) => {
    const lineupFieldName = usePhotoLineupFieldName();
    const { selectedLineup, setSelectedLineup } = useLineupContext();
    const { relatedPersons } = usePhotoLineupPersonProfiles();

    useEffect(() => {
        if (!selectedLineup.name && lineupView) {
            const { photoLineup, title, canExport, isExported } = lineupView;
            // @ts-expect-error client-common to client RND-7529
            const name = getLineupName(photoLineup.personOfInterestMasterId, relatedPersons);

            setSelectedLineup({
                title,
                name,
                lineupId: photoLineup.id,
                canExport,
                isExported,
            });
        }
    }, [lineupView, relatedPersons, selectedLineup.name, setSelectedLineup]);

    if (!lineupView) {
        return null;
    }

    const { images, photoLineupSlotViews, title, photoLineup } = lineupView;

    const renderFooter = (slot: FooterProps) => {
        if (!slot) {
            return null;
        }
        return (
            <Text variant="headingXs" align="center">
                {slot.primaryText}
            </Text>
        );
    };

    return (
        <VStack align={'flex-start'}>
            <Box w={240}>
                <Text
                    variant="headingSm"
                    data-test-id={testIds.PHOTO_LINEUP_COMPOSE_LINEUP_TITLE}
                    style={{ paddingBottom: cssVar('arc.space.1') }}
                >
                    {composeLineupStrings.title(lineupFieldName, title)}
                </Text>
            </Box>
            <LineupPhotoRow
                photoWidth={LineupPhotoWidth.COMPOSE_PAGE}
                rowWidth={LineupPhotoRowWidths.COMPOSE_PAGE_REFERENCE_AREA}
                slots={photoLineupSlotViews}
                images={images}
                showBlanks={true}
                renderEachFooter={renderFooter}
                removeImage={onRemove}
                personOfInterestMasterId={photoLineup.personOfInterestMasterId}
            />
        </VStack>
    );
};

type LineupSummaryRowProps = {
    lineupView: PhotoLineupView;
    lineupFieldName: string;
    caseId: number;
    openDeleteLineupModal: () => void;
    lineupDetailsURL?: string;
};

export const LineupSummaryRow: React.FC<LineupSummaryRowProps> = ({
    lineupView,
    lineupFieldName,
    caseId,
    openDeleteLineupModal,
    lineupDetailsURL,
}) => {
    const currentCase = useSelector(currentCaseSelector);
    const { relatedPersons } = usePhotoLineupPersonProfiles();
    const { permissionSet } = currentCase || {};
    const {
        images,
        photoLineupSlotViews,
        title,
        canDelete,
        canExport,
        isExported,
        photoLineup,
    } = lineupView;

    // get name of POI for lineup summary
    // @ts-expect-error client-common to client RND-7529
    const name = getLineupName(photoLineup.personOfInterestMasterId, relatedPersons);

    return (
        <HStack spacing={cssVar('arc.space.4')}>
            <StyleLink to={lineupDetailsURL} testId={testIds.PHOTO_LINEUP_DETAIL_LINK}>
                <HStack spacing={cssVar('arc.space.4')}>
                    <Text
                        variant="headingSm"
                        data-test-id={testIds.PHOTO_LINEUP_COMPOSE_LINEUP_TITLE}
                        style={{ width: '235px' }}
                    >
                        {name
                            ? caseLineupStrings.summaryRow(lineupFieldName, title, name)
                            : caseLineupStrings.summaryRowNoName(lineupFieldName, title)}
                    </Text>
                    <LineupPhotoRow
                        photoWidth={LineupPhotoWidth.SUMMARY_ROW}
                        rowWidth={LineupPhotoRowWidths.SUMMARY_ROW}
                        slots={photoLineupSlotViews}
                        images={images}
                    />
                </HStack>
            </StyleLink>
            <OnlyWithEntityPermission
                permissionSet={permissionSet}
                has={OperationTypeEnum.WRITE.name}
            >
                <LineupKebabMenu
                    caseId={caseId}
                    isExported={isExported}
                    canDelete={canDelete}
                    canExport={canExport}
                    name={name}
                    lineupId={photoLineup.id}
                    title={title}
                    openDeleteLineupModal={openDeleteLineupModal}
                />
            </OnlyWithEntityPermission>
        </HStack>
    );
};

export const LineupDetailsRow: React.FC<{ lineupView: PhotoLineupView }> = ({ lineupView }) => {
    const { selectedLineup, setSelectedLineup } = useLineupContext();
    const { relatedPersons } = usePhotoLineupPersonProfiles();
    useEffect(() => {
        if (!selectedLineup.name && lineupView) {
            const { photoLineup, title, canExport, isExported } = lineupView;
            // @ts-expect-error client-common to client RND-7529
            const name = getLineupName(photoLineup.personOfInterestMasterId, relatedPersons);

            setSelectedLineup({
                title,
                name,
                lineupId: photoLineup.id,
                canExport,
                isExported,
            });
        }
    }, [lineupView, relatedPersons, selectedLineup.name, setSelectedLineup]);

    const renderEachFooter = (slot: FooterProps) => {
        if (!slot) {
            return null;
        }
        return (
            <>
                <Text variant="headingXs" align="center">
                    {slot.primaryText}
                </Text>
                <Text variant="bodyMd" align="center">
                    {slot.secondaryText}
                </Text>
            </>
        );
    };

    const { images, photoLineupSlotViews } = lineupView;

    return (
        <LineupPhotoRow
            photoWidth={LineupPhotoWidth.DETAILS_PAGE}
            rowWidth={LineupPhotoRowWidths.DETAILS_PAGE}
            slots={photoLineupSlotViews}
            images={images}
            renderEachFooter={renderEachFooter}
        />
    );
};

const RadioPhotoRow: React.FC<{
    attachments: Attachment[];
    onPhotoClick: (value: React.SetStateAction<number>) => void;
}> = ({ attachments, onPhotoClick }) => {
    const renderEachFooter = (slot: FooterProps) => {
        if (!slot) {
            return null;
        }
        return (
            <Text variant="caption">
                <FormattedDate date={slot.primaryText} format={FormattedDate.FORMATS.FORM_DATE} />
            </Text>
        );
    };

    return (
        <LineupPhotoRow
            photoWidth={LineupPhotoWidth.CREATE_LINEUP_MODAL}
            rowWidth={LineupPhotoRowWidths.CREATE_LINEUP_MODAL}
            mugshotAttachments={attachments}
            renderEachFooter={renderEachFooter}
            onPhotoClick={onPhotoClick}
        />
    );
};

export const MugshotPhotoOptions: React.FC<{
    isShown: boolean;
    photos: Attachment[];
    selectedImageId: number;
    setSelectedImageId: (value: React.SetStateAction<number>) => void;
}> = ({ isShown, photos, selectedImageId, setSelectedImageId }) => {
    if (!isShown) {
        return null;
    }

    return (
        <RadioGroup
            defaultValue={photos.length > 0 ? photos[0].id : POI_VALUE}
            value={selectedImageId}
            onChange={(imageId) => {
                setSelectedImageId(+imageId);
            }}
        >
            <RadioPhotoRow attachments={photos} onPhotoClick={setSelectedImageId} />
        </RadioGroup>
    );
};

import {
    storeCadDesktopConfigs,
    deleteCadDesktopConfig,
} from '~/client-common/core/domain/cad-desktop-configs/state/data';
import cadDesktopConfigResource from '../../resources/cadDesktopConfigResource';
import { departmentProfileSelector } from '../../../../../legacy-redux/selectors/departmentProfileSelectors';
import {
    createImportActionCreator,
    createValidateFileActionCreator,
    createExportActionCreator,
} from '../../../core/utils/importExportHelpers';

export const LOAD_CAD_DESKTOP_CONFIGS_START = 'cad-desktop-configs/LOAD_CAD_DESKTOP_CONFIGS_START';
export const LOAD_CAD_DESKTOP_CONFIGS_SUCCESS =
    'cad-desktop-configs/LOAD_CAD_DESKTOP_CONFIGS_SUCCESS';
export const LOAD_CAD_DESKTOP_CONFIGS_FAILURE =
    'cad-desktop-configs/LOAD_CAD_DESKTOP_CONFIGS_FAILURE';
export const SAVE_CAD_DESKTOP_CONFIG_START = 'cad-desktop-configs/SAVE_CAD_DESKTOP_CONFIG_START';
export const SAVE_CAD_DESKTOP_CONFIG_SUCCESS =
    'cad-desktop-configs/SAVE_CAD_DESKTOP_CONFIG_SUCCESS';
export const SAVE_CAD_DESKTOP_CONFIG_FAILURE =
    'cad-desktop-configs/SAVE_CAD_DESKTOP_CONFIG_FAILURE';
export const DELETE_CAD_DESKTOP_CONFIG_START =
    'cad-desktop-configs/DELETE_CAD_DESKTOP_CONFIG_START';
export const DELETE_CAD_DESKTOP_CONFIG_SUCCESS =
    'cad-desktop-configs/DELETE_CAD_DESKTOP_CONFIG_SUCCESS';
export const DELETE_CAD_DESKTOP_CONFIG_FAILURE =
    'cad-desktop-configs/DELETE_CAD_DESKTOP_CONFIG_FAILURE';
const FETCH_CAD_DESKTOP_CONFIG_HISTORY_START =
    'cad-desktop-configs/FETCH_CAD_DESKTOP_CONFIG_HISTORY_START';
export const FETCH_CAD_DESKTOP_CONFIG_HISTORY_SUCCESS =
    'cad-desktop-configs/FETCH_CAD_DESKTOP_CONFIG_HISTORY_SUCCESS';
const FETCH_CAD_DESKTOP_CONFIG_HISTORY_FAILURE =
    'cad-desktop-configs/FETCH_CAD_DESKTOP_CONFIG_HISTORY_FAILURE';
const FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_START =
    'cad-desktop-configs/FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_START';
export const FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_SUCCESS =
    'cad-desktop-configs/FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_SUCCESS';
const FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_FAILURE =
    'cad-desktop-configs/FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_FAILURE';

const uuid = () => {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};

function loadCadDesktopConfigsStart() {
    return {
        type: LOAD_CAD_DESKTOP_CONFIGS_START,
    };
}

function loadCadDesktopConfigsFailure(errMsg) {
    return {
        type: LOAD_CAD_DESKTOP_CONFIGS_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function loadCadDesktopConfigsSuccess() {
    return {
        type: LOAD_CAD_DESKTOP_CONFIGS_SUCCESS,
    };
}

function fetchCadDesktopConfigHistoryStart(desktopConfigId) {
    return {
        type: FETCH_CAD_DESKTOP_CONFIG_HISTORY_START,
        payload: desktopConfigId,
    };
}

function fetchCadDesktopConfigHistorySuccess(desktopConfigId, histories) {
    return {
        type: FETCH_CAD_DESKTOP_CONFIG_HISTORY_SUCCESS,
        payload: { desktopConfigId, histories },
    };
}

function fetchCadDesktopConfigHistoryFailure(desktopConfigId, err) {
    return {
        type: FETCH_CAD_DESKTOP_CONFIG_HISTORY_FAILURE,
        payload: { desktopConfigId, err },
    };
}

function fetchAllCadDesktopConfigsHistoryStart() {
    return {
        type: FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_START,
    };
}

function fetchAllCadDesktopConfigsHistorySuccess(histories) {
    return {
        type: FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_SUCCESS,
        payload: { histories },
    };
}

function fetchAllCadDesktopConfigsHistoryFailure(err) {
    return {
        type: FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_FAILURE,
        payload: { err },
    };
}

export function loadCadDesktopConfigs() {
    return function (dispatch, getState) {
        dispatch(loadCadDesktopConfigsStart());
        const { departmentId } = departmentProfileSelector(getState());
        return cadDesktopConfigResource
            .getCadDesktopConfigs(departmentId)
            .then((desktopConfigs) => {
                dispatch(storeCadDesktopConfigs(desktopConfigs));
                dispatch(loadCadDesktopConfigsSuccess());
            })
            .catch((err) => {
                dispatch(loadCadDesktopConfigsFailure(err.message));
            });
    };
}

export function loadCadDesktopConfigHistoryData(desktopConfigId) {
    return function (dispatch) {
        dispatch(fetchCadDesktopConfigHistoryStart(desktopConfigId));
        return cadDesktopConfigResource
            .getCadDesktopConfigHistory(desktopConfigId)
            .then((histories) => {
                dispatch(fetchCadDesktopConfigHistorySuccess(desktopConfigId, histories));
            })
            .catch((err) => {
                dispatch(fetchCadDesktopConfigHistoryFailure(desktopConfigId, err));
            });
    };
}

export function loadAllCadDesktopConfigsHistoryData() {
    return function (dispatch) {
        dispatch(fetchAllCadDesktopConfigsHistoryStart());
        return cadDesktopConfigResource
            .getAllCadDesktopConfigsHistory()
            .then((histories) => {
                dispatch(fetchAllCadDesktopConfigsHistorySuccess(histories));
            })
            .catch((err) => {
                dispatch(fetchAllCadDesktopConfigsHistoryFailure(err));
            });
    };
}

function saveCadDesktopConfigStart() {
    return {
        type: SAVE_CAD_DESKTOP_CONFIG_START,
    };
}

function saveCadDesktopConfigSuccess(cadDesktopConfig) {
    return {
        type: SAVE_CAD_DESKTOP_CONFIG_SUCCESS,
        payload: cadDesktopConfig,
    };
}

function saveCadDesktopConfigFailure(errMsg) {
    return {
        type: SAVE_CAD_DESKTOP_CONFIG_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveCadDesktopConfig({ cadDesktopConfig }) {
    return function (dispatch, getState) {
        dispatch(saveCadDesktopConfigStart());
        const uri = `${window.location.host}`;
        const isNewDesktopConfig = !cadDesktopConfig.id;
        const method = isNewDesktopConfig ? 'createCadDesktopConfig' : 'updateCadDesktopConfig';
        const { departmentId, department } = departmentProfileSelector(getState());
        const output = cadDesktopConfig.guid
            ? { ...cadDesktopConfig, departmentId, uri }
            : {
                  ...cadDesktopConfig,
                  departmentId,
                  uri,
                  guid: uuid(),
                  hostName: department.subDomain,
              };

        return cadDesktopConfigResource[method](output)
            .then((desktopConfig) => {
                dispatch(storeCadDesktopConfigs([desktopConfig]));
                return dispatch(saveCadDesktopConfigSuccess(desktopConfig));
            })
            .catch((err) => dispatch(saveCadDesktopConfigFailure(err.message)));
    };
}

function deleteCadDesktopConfigStart() {
    return {
        type: DELETE_CAD_DESKTOP_CONFIG_START,
    };
}

function deleteCadDesktopConfigSuccess(cadDesktopConfig) {
    return {
        type: DELETE_CAD_DESKTOP_CONFIG_SUCCESS,
        payload: cadDesktopConfig,
    };
}

function deleteCadDesktopConfigFailure(errMsg) {
    return {
        type: DELETE_CAD_DESKTOP_CONFIG_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function deleteDesktopConfig(cadDesktopConfig) {
    return function (dispatch) {
        dispatch(deleteCadDesktopConfigStart());
        return cadDesktopConfigResource['deleteCadDesktopConfig'](cadDesktopConfig.id)
            .then(() => {
                dispatch(deleteCadDesktopConfig(cadDesktopConfig.id));
                return dispatch(deleteCadDesktopConfigSuccess(cadDesktopConfig));
            })
            .catch((err) => dispatch(deleteCadDesktopConfigFailure(err.message)));
    };
}

/**
 * Import attributes from an uploaded file.
 * @param  {number} fileId
 * @return {Promise}
 */
const importDesktopConfigs = createImportActionCreator(
    cadDesktopConfigResource.importDesktopConfigs
);

/**
 * Validate that a file is valid for importing new attributes.
 * @param  {number} fileId
 * @return {Promise}
 */
export const validateDesktopConfigImport = createValidateFileActionCreator(
    cadDesktopConfigResource.validateDesktopConfigImport,
    importDesktopConfigs
);

/**
 * Export all attributes into a CSV file.
 * @return {Promise}
 */
export const exportDesktopConfigs = createExportActionCreator(
    cadDesktopConfigResource.exportDesktopConfigs
);

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { Text } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Map } from '~/client-common/core/maps';
import { formatStaticMapUrl } from '~/client-common/core/domain/elastic-involved-locations/state/ui';
import { getDisplaySource } from '~/client-common/core/domain/locations/utils/locationSourceHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import SummaryList from '../../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../../legacy-redux/components/summaries/SummaryRow';
import { useSubPremiseFormatters } from '../../../../../core/locations/hooks/useSubPremiseFormatters';
import VerifiedTag from '../../../../../core/components/tags/VerifiedTag';

const strings = componentStrings.summaries.LocationSummary;

const PlaceNameWrapper = styled.div`
    display: flex;
    align-items: baseline;
`;

const PlaceName = styled.span`
    padding-right: 8px;
`;

const LocationTitleSummaryComponent = ({
    locationBundle,
    showMap,
    boldAddress,
    showCountry,
    applicationSettings,
    showLongitudeAndLatitudeFields,
}) => {
    const { location, unknownLocationId, locationEntityLink } = locationBundle;
    const placeName =
        // prefer using locationEntityLink since not all location shapes have placeName
        !!locationEntityLink && !!locationEntityLink.placeName
            ? locationEntityLink.placeName
            : location.placeName;
    const { formatAddressLinesForLocationBundle } = useSubPremiseFormatters();
    const isSubPremiseSupportEnabled = applicationSettings.SUBPREMISE_SUPPORT_ENABLED;

    const locationSourceText = isSubPremiseSupportEnabled
        ? getDisplaySource(location.source) || getDisplaySource(locationEntityLink?.resolverSource)
        : location.areCoordinatesVerified
          ? strings.verifiedLocation
          : '';

    const locationSource = location.areCoordinatesVerified ? (
        <VerifiedTag content={locationSourceText} />
    ) : (
        <Text variant="bodyMd" color="positive" fontWeight="semibold">
            {locationSourceText}
        </Text>
    );

    const locationLines = formatAddressLinesForLocationBundle({
        locationBundle,
        showCountry,
        hidePlaceName: false,
    });

    const defaultCenter = useMemo(() => {
        return {
            lat: location.latitude,
            lng: location.longitude,
        };
    }, [location.latitude, location.longitude]);

    let staticMap = null;
    const hasLatLng =
        location &&
        typeof location?.latitude === 'number' &&
        typeof location?.longitude === 'number';
    if (applicationSettings.ESRI_MAPS_ENABLED && hasLatLng) {
        staticMap = (
            <Map
                defaultCenter={defaultCenter}
                selectedLocation={location}
                width={370}
                height={160}
            />
        );
    } else {
        const mapSize = '370x160'; // must match css rule for `.location-map`
        const staticMapUrl = showMap && formatStaticMapUrl(location, mapSize);
        staticMap = staticMapUrl && (
            <div className="location-map">
                <img src={staticMapUrl} />
            </div>
        );
    }
    return (
        // We cannot have both location lines, and a noFixed
        <div>
            <FeatureFlagged flag="SUBPREMISE_SUPPORT_ENABLED">
                <PlaceNameWrapper>
                    <PlaceName>{placeName}</PlaceName>
                    {locationSource}
                </PlaceNameWrapper>
            </FeatureFlagged>
            {location.id === unknownLocationId && unknownLocationId !== undefined ? (
                <Text
                    fontSize="var(--arc-fontSizes-sm)"
                    as="div"
                    fontWeight={boldAddress ? 'semibold' : null}
                >
                    {strings.noFixed}
                </Text>
            ) : (
                map(locationLines, (line, index) => {
                    // must key by index because there's no guarantee all of the lines will be unique
                    return (
                        <Text key={index} as="div" fontWeight={boldAddress ? 'semibold' : null}>
                            {line}
                        </Text>
                    );
                })
            )}
            {showMap && staticMap}
            <FeatureFlagged flag="SUBPREMISE_SUPPORT_ENABLED" fallback={locationSource} />
            {showLongitudeAndLatitudeFields && (
                <SummaryList>
                    <SummaryRow label={strings.longitude}>{location.longitude}</SummaryRow>
                    <SummaryRow label={strings.latitude}>{location.latitude}</SummaryRow>
                </SummaryList>
            )}
        </div>
    );
};

export const LocationTitleSummary = connect(
    createStructuredSelector({
        applicationSettings: applicationSettingsSelector,
    })
)(LocationTitleSummaryComponent);

import { useEffect, useRef, useState, useCallback } from 'react';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { FeatureLayerT, PolylineT, WebMapT } from '../../types';
import { spatialReference, useStaticLayerControl } from './staticFeatureLayerHelpers';

type PolylineFeatureLayerProps = {
    polylines?: PolylineT[];
    webmap?: WebMapT;
};

const createPolylineGraphic = (polyline: PolylineT) => {
    // needs to be in format of number array https://developers.arcgis.com/javascript/latest/api-reference/esri-geometry-Polyline.html#paths
    const paths = [
        [polyline.path[0].lng, polyline.path[0].lat],
        [polyline.path[1].lng, polyline.path[1].lat],
    ];
    return new Graphic({
        geometry: {
            // @ts-expect-error esri types seem to be wrong https://developers.arcgis.com/javascript/latest/api-reference/esri-Graphic.html#geometry
            type: 'polyline',
            paths,
        },
    });
};

export const useStaticPolylineFeatureLayer = ({
    polylines = [],
    webmap,
}: PolylineFeatureLayerProps) => {
    const [polylineLayer, setPolylineLayer] = useState<FeatureLayerT>();
    const layerRef = useRef<FeatureLayerT | undefined>();

    const createPolylineGraphics = useCallback((polylines: PolylineT[]) => {
        return polylines.map((polyline: PolylineT) => {
            return createPolylineGraphic(polyline);
        });
    }, []);

    // handles initializing empty feature layer
    useEffect(() => {
        if (webmap && polylines.length > 0) {
            if (!!layerRef.current) {
                webmap.layers.remove(layerRef.current);
            }

            const polylineFeatureLayer = getPolylineLayer();
            setPolylineLayer(polylineFeatureLayer);
            layerRef.current = polylineFeatureLayer;
            webmap.layers.add(polylineFeatureLayer);
        }
    }, [webmap, polylines]);

    useStaticLayerControl({
        layer: polylineLayer,
        currItems: polylines,
        createGraphics: createPolylineGraphics,
    });
};

const polylineIconRenderer = {
    type: 'unique-value',
    field: ' ',
    defaultSymbol: {
        // used when item field dont match anything defined in uniqueValueInfos
        type: 'simple-line',
        color: [0, 0, 0, 1],
        width: 2,
    },
};

const getPolylineLayer = () => {
    const polylineFeatureLayer = new FeatureLayer({
        title: 'Polylines',
        source: [], // initialize with an empty collection
        renderer: polylineIconRenderer as __esri.RendererProperties,
        objectIdField: 'objectId', // This must be defined when creating a layer from `Graphic` objects
        outFields: ['*'],
        geometryType: 'polyline',
        spatialReference,
    });

    return polylineFeatureLayer;
};

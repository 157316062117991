import { ElasticSearchTypeEnum } from '@mark43/rms-api';

import { createStructuredSelector } from 'reselect';
import { createPropertyResultsSelector } from '~/client-common/core/domain/elastic-property/state/ui';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import createSearchModule from '../../../core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import advancedSearchPropertyForm, {
    convertAdvancedSearchPropertyFormModelToFilterGroups,
    convertAdvancedSearchPropertyElasticQueryToFormModel,
} from '../forms/advancedSearchPropertyForm';
import searchResource from '../../../../../legacy-redux/resources/searchResource';

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object}   boundSelectors
 * @return {Object}   Array of filter group view models.
 */
function convertAdvancedSearchPropertyElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    return convertAdvancedSearchPropertyFormModelToFilterGroups(
        convertAdvancedSearchPropertyElasticQueryToFormModel(elasticQuery),
        formatFieldValue,
        boundSelectors
    );
}

/**
 * Redux module for advanced search property.
 * @type {Object}
 */
export default createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.PROPERTY.name,
    baseUiSelector: (state) => state.ui.advancedSearch.property,
    form: advancedSearchPropertyForm,
    elasticQueryToFilterGroups: convertAdvancedSearchPropertyElasticQueryToFilterGroups,
    resourceMethod: elasticSearchResource.searchProperty,
    resultsContainerClassName: 'property-results-container',
    createResultsViewModelsSelector: createPropertyResultsSelector,
    searchResultToRoutePath: ({ id }) => `/profiles/property/${id}`,
    selectorToBind: createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
    }),
    exportResourceMethod: searchResource.exportProperty,
    loadSearchExportPrintablesResourceMethod: searchResource.getPropertySearchExportPrintables,
});

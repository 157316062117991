import { createSelector } from 'reselect';
import { filter, includes, take, isNaN, uniq, chain } from 'lodash';
import {
    itemProfileIdsInReportWithCoCSelector,
    itemProfileIdsInReportWithoutCoCSelector,
} from '~/client-common/core/domain/chain-events/state/data';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { sortItemProfilesSelector } from '~/client-common/core/domain/item-reporting-event-links/state/ui';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { RmsAction } from '../../../../../core/typings/redux';
import { loadEvidenceItems } from '../../../core/state/data/evidenceItems';

const SET_VIEWABLE_ITEM_PROFILE_IDS = 'cps-card/SET_VIEWABLE_ITEM_PROFILE_IDS';
const FETCH_BATCH_EVIDENCE_HYDRATED_ITEMS_START =
    'cps-card/FETCH_BATCH_EVIDENCE_HYDRATED_ITEMS_START';

type StateT = {
    viewableItemProfileIds: number[];
    isLoading: boolean;
};

function setViewableItemProfileIds(itemProfileIds: number[]) {
    return {
        type: SET_VIEWABLE_ITEM_PROFILE_IDS,
        payload: { itemProfileIds },
    } as const;
}

function fetchBatchEvidenceHydratedItemsStart() {
    return {
        type: FETCH_BATCH_EVIDENCE_HYDRATED_ITEMS_START,
    } as const;
}

type ActionsT =
    | ReturnType<typeof setViewableItemProfileIds>
    | ReturnType<typeof fetchBatchEvidenceHydratedItemsStart>;

const cpsCardUiSelector = (state: RootState): StateT => state.ui.evidence.cpsCard;

export const cpsCardViewableItemProfileIdsSelector = createSelector(
    cpsCardUiSelector,
    (uiState) => uiState.viewableItemProfileIds
);

export const cpsCardIsFetchingBatchHydratedEvidenceItems = createSelector(
    cpsCardUiSelector,
    (uiState) => uiState.isLoading
);

const sortedItemProfileIdsSelector = createSelector(
    currentReportIdSelector,
    itemProfilesInReportSelector,
    sortItemProfilesSelector,
    (reportId, itemProfilesInReport, sortItemProfiles) => {
        if (!reportId) {
            return [];
        }

        // @ts-expect-error client-common to client RND-7529
        return chain(itemProfilesInReport(reportId)).sortBy(sortItemProfiles).map('id').value();
    }
);

export const paginatedItemProfileIdsInReportWithCoCSelector = createSelector(
    cpsCardViewableItemProfileIdsSelector,
    itemProfileIdsInReportWithCoCSelector,
    currentReportIdSelector,
    applicationSettingsSelector,
    (viewableItemProfileIds, getItemProfileIdsInReportWithCoC, reportId, applicationSettings) => {
        if (!reportId) {
            return [];
        }
        const itemProfileIdsInReportWithCoC = getItemProfileIdsInReportWithCoC(reportId);

        return applicationSettings.EVIDENCE_PAGINATE_HYDRATED_ITEMS_ENABLED
            ? filter(itemProfileIdsInReportWithCoC, (id) => {
                  return includes(viewableItemProfileIds, id);
              })
            : itemProfileIdsInReportWithCoC;
    }
);

export const paginatedItemProfileIdsInReportWithoutCoCSelector = createSelector(
    cpsCardViewableItemProfileIdsSelector,
    itemProfileIdsInReportWithoutCoCSelector,
    currentReportIdSelector,
    applicationSettingsSelector,
    (
        viewableItemProfileIds,
        getItemProfileIdsInReportWithoutCoC,
        reportId,
        applicationSettings
    ) => {
        if (!reportId) {
            return [];
        }
        const itemProfileIdsInReportWithoutCoC = getItemProfileIdsInReportWithoutCoC(reportId);
        return applicationSettings.EVIDENCE_PAGINATE_HYDRATED_ITEMS_ENABLED
            ? filter(itemProfileIdsInReportWithoutCoC, (id) => {
                  return includes(viewableItemProfileIds, id);
              })
            : itemProfileIdsInReportWithoutCoC;
    }
);

export function fetchPaginatedEvidenceHydratedItems(): RmsAction<Promise<void>> {
    return function (dispatch, getState) {
        const state = getState();
        const isPropertyCardPaginationEnabled = applicationSettingsSelector(state)
            .EVIDENCE_PAGINATE_HYDRATED_ITEMS_ENABLED;

        if (!isPropertyCardPaginationEnabled) {
            return;
        }
        const paginationSizeSettings = applicationSettingsSelector(state)
            .EVIDENCE_CPS_REPORT_PAGINATION_SIZE;

        const paginationSize =
            paginationSizeSettings && isNaN(paginationSizeSettings)
                ? 50
                : Number(paginationSizeSettings);

        const sortedItemProfileIdsForReport = sortedItemProfileIdsSelector(state);

        const viewableItemProfileIds = cpsCardViewableItemProfileIdsSelector(state);
        const nextBatchOfItemProfileIdsToView = viewableItemProfileIds.length + paginationSize;

        const ids = take(sortedItemProfileIdsForReport, nextBatchOfItemProfileIdsToView);
        const addedItemProfileIds = ids.splice(viewableItemProfileIds.length);

        dispatch(fetchBatchEvidenceHydratedItemsStart());

        return (
            // @ts-expect-error evidenceItems.js is not typed
            dispatch(loadEvidenceItems(addedItemProfileIds))
                // @ts-expect-error evidenceItems.js is not typed
                .then(() => {
                    dispatch(setViewableItemProfileIds(addedItemProfileIds));
                })
        );
    };
}

const initialDataState: StateT = {
    viewableItemProfileIds: [],
    isLoading: false,
};

export default makeResettable(
    ENTER_NEW_ROUTE,
    function cpsCardUiReducer(state: StateT = initialDataState, action: ActionsT) {
        switch (action.type) {
            case SET_VIEWABLE_ITEM_PROFILE_IDS:
                return {
                    ...state,
                    viewableItemProfileIds: uniq([
                        ...state.viewableItemProfileIds,
                        ...action.payload.itemProfileIds,
                    ]),
                    isLoading: false,
                };
            case FETCH_BATCH_EVIDENCE_HYDRATED_ITEMS_START:
                return {
                    ...state,
                    isLoading: true,
                };

            default:
                return state;
        }
    },
    // @ts-expect-error client-common to client RND-7529
    initialDataState
);

import { SubmissionMessageView } from '@mark43/rms-api';

/* ACTION TYPES */
export const actionTypes = {
    SET_SUBMISSION_MESSAGES: 'SET_SUBMISSION_MESSAGES',
} as const;

/* ACTIONS */
export const setSubmissionMessage = (submissionMessageViews: SubmissionMessageView[]) => {
    return {
        type: actionTypes.SET_SUBMISSION_MESSAGES,
        payload: {
            submissionMessageViews,
        },
    } as const;
};

export type SubmissionMessageActionTypesT = ReturnType<typeof setSubmissionMessage>;

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'call_taker_station';

export default createResource({
    name: 'Cad Call Taker Station Resource',
    methods: {
        getCadCallTakerStations() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/get_all_for_department`,
            });
        },
        createCadCallTakerStation(cadCallTakerStation) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/upsert`,
                data: cadCallTakerStation,
            });
        },
        updateCadCallTakerStation(cadCallTakerStation) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/upsert`,
                data: cadCallTakerStation,
            });
        },
        getCadCallTakerStationHistory(id) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/${id}/histories`,
            });
        },
        getAllCadCallTakerStationsHistory() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/histories`,
            });
        },

        /**
         * Export all into a CSV.
         * @return {Promise}
         */
        exportCallTakerStations() {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/export`,
            });
        },

        /**
         * Validate that a file is valid for importing new call taker station.
         * @return {Promise}
         */
        validateCallTakerStationImport(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/validate/${fileId}`,
            });
        },

        /**
         * Import call taker stations from an uploaded file.
         * @return {Promise}
         */
        importCallTakerStations(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/${fileId}`,
            });
        },
    },
});

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import createCard from '../../utils/createCard';

const baseCard = createCard({
    name: reportCardEnum.LEGACY_INFO.name,
    baseSelector: (state) => state.ui.report.reportLegacyMetadataCard,
    anchor: 'report-legacy-metadata-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
    },
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { cssVar, Text } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import Tag from '../../../../record-privacy/core/components/Tag';

const strings = componentStrings.reports.core.ChargeTitle;

const SummaryText = styled.span`
    font-size: var(--arc-fontSizes-md);
    color: ${cssVar('arc.colors.text.primary')};
`;

const RedVacatedTag = styled(Tag)`
    font-size: var(--arc-fontSizes-md);
`;

export default connect(
    createStructuredSelector({
        formatOffenseCodeById: formatOffenseCodeByIdSelector,
    })
)(
    /**
     * A helper to render the title of a charge
     *
     * @param {Object} props
     * @param {import('@mark43/rms-api').Charge} [props.charge]
     * @param {boolean} [props.boldSummaryText]
     */
    function ChargeTitle({ charge = {}, formatOffenseCodeById, boldSummaryText }) {
        return (
            <div>
                <Text as="span" fontWeight={'bold'} color="text.primary" fontSize={'md'}>
                    {`Charge ${charge.offenseOrder}: `}
                </Text>
                <SummaryText
                    style={boldSummaryText ? { fontWeight: cssVar('arc.fontWeights.bold') } : {}}
                >
                    {formatOffenseCodeById({ id: charge.chargeOffenseCodeId, includeCode: false })}
                    {charge.isVacated && <RedVacatedTag> {strings.vacated}</RedVacatedTag>}
                </SummaryText>
            </div>
        );
    }
);

import _ from 'lodash';

const MAX_NUM_IMAGE_THUMBNAILS = 7;

// if there are more than 7 images, show the current image in the middle
// to do this, we map images to elements, then sort based on some mod Math
// to convert each index to it's translated index
// ie, for an array of 10 images,
//     8, 9, 0, *1*, 2, 3
// ->  0, 1, 2, *3*, 4, 5
// and then we take the first n number of images
// TODO, may want some way to preload thumbnails so there's not a delay in loading the next one
//      but we should be getting a small enough image from the server that
//      it shouldn't take that long to load anyway
export function createThumbnailViewModels(images, currentIndex) {
    // middle image index would be the floor of half the array length
    const middleImageIndex = _.floor(MAX_NUM_IMAGE_THUMBNAILS / 2);
    return _(images)
        .map((image, index) => {
            return {
                image,
                index,
                isActive: index === currentIndex,
            };
        })
        .sortBy((imageViewModel, index) => {
            return images.length > MAX_NUM_IMAGE_THUMBNAILS
                ? (index + middleImageIndex - currentIndex + images.length) % images.length
                : index;
        })
        .take(MAX_NUM_IMAGE_THUMBNAILS)
        .value();
}

// more than one currentImageViewModel to minimize image rendering lag when navigating left and right
export function createCurrentImageViewModels(images, currentIndex) {
    return _(images)
        .map((image, index) => {
            return {
                image,
                isActive: index === currentIndex,
                title: `(${index + 1}/${images.length})`,
            };
        })
        .slice(_.max([currentIndex - 1, 0]), currentIndex + 2)
        .value();
}

import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { ProtectedEntityOptionDisplayView } from '@mark43/rms-api';
import dragonRmsIntegrationResource from '~/client-common/core/domain/dragon-rms-integration/resources/dragonRmsIntegrationResource';
import {
    protectedEntityOptionDisplayViewByIdSelector,
    protectedEntityOptionDisplayViewWhereSelector,
    storeProtectedEntityOptionDisplayViews,
} from '~/client-common/core/domain/dragon-rms-integration/state/data/protected-option-display-views';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';

import Select from '../../../../core/forms/components/selects/Select';
import { FetchError } from './common';

const { connectRRFInput } = reactReduxFormHelpers;

const CONFIGURED_ENTITY_INSTANCE_OPTION_PAGE_SIZE = 3;
const CONFIGURED_ENTITY_INSTANCE_OPTION_QUERY_DEBOUNCE_MS = 500;

function sortByLabel(a: { label: string }, b: { label: string }): 1 | -1 | 0 {
    if (a.label === b.label) {
        return 0;
    }
    return a.label > b.label ? 1 : -1;
}

/**
 * Handles fetching and displaying of instance options for a given configured entity key name.
 */
export const ConfiguredEntityInstanceOptionSelect = connectRRFInput(
    // @ts-expect-error client-common to client RND-7529
    function ConfiguredEntityInstanceOptionSelect({
        onChange,
        onBlur,
        onFocus,
        label,
        configuredEntityTypeKeyName,
        value,
    }: {
        path: string;
        label: string;
        configuredEntityTypeKeyName: string;
        onChange: (value: unknown) => void;
        onFocus: (value: unknown) => void;
        onBlur: (value: unknown) => void;
        value?: string;
    }): JSX.Element {
        const protectedEntityOptionDisplayViewById = useSelector(
            protectedEntityOptionDisplayViewByIdSelector
        );
        const protectedEntityOptionDisplayViewWhere = useSelector(
            protectedEntityOptionDisplayViewWhereSelector
        );
        const dispatch = useDispatch();

        // Filter query is initally an empty string to ensure that our query runs without any filter.
        // We allow `undefined` to be set as a special value which we can set our filter to later
        // to prevent the filter query from running again when the input is blurred and the filter is reset.
        const [filterQuery, setFilterQuery] = React.useState<string | undefined>('');
        const setFilterQueryDebounced = React.useMemo(
            () => debounce(setFilterQuery, CONFIGURED_ENTITY_INSTANCE_OPTION_QUERY_DEBOUNCE_MS),
            [setFilterQuery]
        );
        // This ref is used to determine whether a change in the select's text input should actually trigger a change to our local filter query
        // state. This gives us control over deciding whether a dataset should be filtered locally or via an api call.
        const filteringShouldTriggerQuery = React.useRef<boolean>();

        const getConfiguredEntityTypeOptions = React.useMemo(() => {
            return () => {
                return dragonRmsIntegrationResource.getDisplayOptionsForProtectedConfiguredEntityKeyNames(
                    {
                        keyName: configuredEntityTypeKeyName,
                        queryString: filterQuery,
                        nextCursor: 0,
                        pageSize: CONFIGURED_ENTITY_INSTANCE_OPTION_PAGE_SIZE,
                    }
                );
            };
        }, [configuredEntityTypeKeyName, filterQuery]);

        // NOTE
        // We should think about splitting out initial option queries from filtered queries.
        // That way we could keep and reuse initial queries in a shared context that lives until advanced search
        // gets closed and swap between local and context-based option values.
        const query = useQuery({
            queryKey: [
                'rms-report-search-configured-entity-property-options',
                configuredEntityTypeKeyName,
                // Default to empty string to get around unnecessary refetch when focusing the input
                // after having blurred it, without actually filtering. If we do not default
                // to an empty string, we are changing our query key, which will result in a
                // new query every time we focus the select, due to the query cache being emptied between
                // queries.
                filterQuery ?? '',
            ],
            queryFn: getConfiguredEntityTypeOptions,
            // Only one retry to not delay showing failure feedback to the user for too long
            retry: 1,

            // We only want this query to be enabled either on our first run, or if we need to fetch
            // additional data for filtering. Otherwise we can reuse the data that was fetched
            // via the initial query.
            enabled: filterQuery !== undefined,

            // It is important that we keep data for the previous query until the new query returns
            // as to not cause flickering in the UI. Without this, data would get instantly removed
            // when the filter query changes, deleting all previous select options.
            keepPreviousData: true,

            cacheTime: 120000,
        });

        // Enforce that we are re-querying when the configured entity type key name changes
        // and clean up any residual state between option fetches
        const { refetch } = query;
        React.useEffect(() => {
            refetch();

            return () => {
                setFilterQuery(undefined);
            };
        }, [refetch, configuredEntityTypeKeyName, setFilterQuery]);

        // When the configured entity type key name changes, we have to reset our
        // filtering constraint to ensure that it can be properly reassigned in the effect below.
        React.useEffect(() => {
            filteringShouldTriggerQuery.current = undefined;
        }, [configuredEntityTypeKeyName]);

        // When the query returns we compute if any of our filtering actions should query
        // for additional data. If the initial fetch of the dataset we are looking at is less than our page size
        // there is no need for us to go back to the backend for additional filtering any any point.
        React.useEffect(() => {
            if (
                !query.data ||
                query.isFetching ||
                filteringShouldTriggerQuery.current !== undefined
            ) {
                return;
            }
            filteringShouldTriggerQuery.current = !!query.data?.hasNextPage;
        }, [query.data, query.isFetching]);

        const initiallySelectedOptionView =
            value === 'true' || value === 'false'
                ? // Boolean values are special in the way that they need to be found by their value
                  // not by their instance id. This could potentially lead to an issue where if we
                  // happen to store more than one boolean instance pair, we get an incorrect display
                  // value. If this case does come up we will have to address it then.
                  protectedEntityOptionDisplayViewWhere({ value })?.[0]
                : value
                  ? protectedEntityOptionDisplayViewById(value)
                  : undefined;
        const { options, protectedEntityOptionValueView } = React.useMemo(() => {
            const protectedEntityOptionValueView = query.data?.protectedEntityOptionValueViews.find(
                (optionValueView) => optionValueView.keyName === configuredEntityTypeKeyName
            );

            const optionDisplayViewToOption = (
                optionDisplayView: ProtectedEntityOptionDisplayView
            ) => ({
                label: optionDisplayView.displayValue,
                value: optionDisplayView.value,
            });
            const options =
                protectedEntityOptionValueView?.displayViews.map(optionDisplayViewToOption) ?? [];

            return {
                protectedEntityOptionValueView,
                options: (initiallySelectedOptionView
                    ? options
                          .filter((option) => option.value !== initiallySelectedOptionView?.value)
                          .concat(optionDisplayViewToOption(initiallySelectedOptionView))
                    : options
                )?.sort(sortByLabel),
            };
        }, [query.data, initiallySelectedOptionView, configuredEntityTypeKeyName]);

        if (query.isError) {
            return <FetchError onRefetchClick={query.refetch} />;
        }

        return (
            <Select
                options={options}
                multiple={false}
                length="md"
                label={label}
                disabled={!query.data}
                loading={query.isFetching}
                value={value}
                // When the input gets blurred we have to reset the filter query so that
                // users are not stuck with a filter they cannot remove without taking unintuintive actions
                onFocus={(value) => {
                    setFilterQuery('');
                    onFocus(value);
                }}
                // We use `undefined` as a sentinel value to disable our options query. The benefit over setting the query back to an empty string
                // is that we do not trigger an unnecessary query on blur. Instead we will only query when the user focuses the field again
                onBlur={(value) => {
                    setFilterQuery(undefined);
                    onBlur(value);
                }}
                onQueryChange={(value) => {
                    if (filteringShouldTriggerQuery.current) {
                        setFilterQueryDebounced(value);
                    }
                }}
                onChange={(value: string) => {
                    // We have to keep the lastest selected option around to ensure that it is always available,
                    // even if a user continues to filter through the data set, which might remove the currently selected option from it, or
                    // navigates away from the advanced search page and comes back later.
                    const selectedOption = options?.find((option) => option.value === value);
                    if (selectedOption) {
                        const view = protectedEntityOptionValueView?.displayViews.find(
                            (x) => x.value === value
                        );
                        if (view) {
                            dispatch(storeProtectedEntityOptionDisplayViews([view]));
                        }
                    }
                    onChange(value);
                }}
            />
        );
    }
);

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AttributeTypeEnum } from '@mark43/rms-api';

import { hocCategoryAttrIdsForOffenseCodeIdSelector } from '~/client-common/core/domain/offense-code-hoc-category-links/state/data';

import { currentUserHasAbilitySelector } from '../../../current-user/state/ui';
import { abilitiesEnum } from '../../../abilities';
import { ArbiterMFTAttributeSelect } from './AttributeSelect';

export const UkOffenseCodeCategorySelect = ({
    externalIsLoading = false,
    offenseCodeId,
    onChange,
    disabled,
    path,
}: {
    externalIsLoading?: boolean;
    offenseCodeId: number | undefined;
    onChange?: () => void;
    disabled?: boolean;
    path: string;
}) => {
    const hocCategoryAttrIdsForOffenseCodeId = useSelector(
        hocCategoryAttrIdsForOffenseCodeIdSelector
    );
    const hasNibrsCodingAbility = useSelector(currentUserHasAbilitySelector)(
        abilitiesEnum.REPORTING.NIBRS_CODING
    );

    const offenseCategoryByOffenseCodeFilter = useCallback(
        (options: { value: number }[]) => {
            if (hasNibrsCodingAbility || !offenseCodeId) {
                return options;
            }
            const hocCategoryAttrIds = hocCategoryAttrIdsForOffenseCodeId(offenseCodeId);
            return options.filter((option) =>
                hocCategoryAttrIds.some((hocCategoryAttrId) => option.value === hocCategoryAttrId)
            );
        },
        [hasNibrsCodingAbility, hocCategoryAttrIdsForOffenseCodeId, offenseCodeId]
    );

    return (
        <ArbiterMFTAttributeSelect
            clearable={false}
            includeAbbr={true}
            path={path}
            attributeType={AttributeTypeEnum.UK_OFFENSE_CODE_CATEGORY.name}
            filterOptions={offenseCategoryByOffenseCodeFilter}
            multiple={false}
            disabled={disabled}
            onChange={onChange}
            length="lg"
            showLoadingWhenUnfocused={true}
            externalIsLoading={externalIsLoading}
        />
    );
};

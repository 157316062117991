import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { closeBox } from '../../../../../legacy-redux/actions/boxActions';

const resultsContext = { name: boxEnum.DUPLICATE_NAME_RESULTS_SIDE_PANEL };
// const compareContext = {}; TODO: RMS-6002

export const duplicateNamePrimaryModelSelector = (state) => state.ui.duplicateNames.primary;
export const duplicateNameDuplicateModelsSelector = (state) => state.ui.duplicateNames.duplicates;

const SET_DUPLICATE_NAME_RESULTS = 'duplicateNames/SET_DUPLICATE_NAME_RESULTS';
let saveName; // Knockout hack

export function duplicateNamesReducer(state = {}, action) {
    switch (action.type) {
        case SET_DUPLICATE_NAME_RESULTS:
            return action.payload;
        default:
            return state;
    }
}

export function saveDuplicateNameFromSidePanel() {
    return (dispatch) => {
        saveName();
        dispatch(closeDuplicateNameResultsSidePanel());
    };
}

export function closeDuplicateNameResultsSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(resultsContext));
    };
}

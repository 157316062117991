import { ProductModuleEnum, UsageSourceModuleEnum } from '@mark43/rms-api';
import { get, keys, mapValues, merge, pick } from 'lodash';
import {
    entityTypeParamToEnum,
    entityTypeSectionToUsageActionEnum,
} from '~/client-common/helpers/entityProfileHelpers';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import {
    startPollingForCurrentViewers,
    stopPollingForCurrentViewers,
} from '~/client-common/core/domain/current-viewers/state/polling';

import { closeLoadingModal, openLoadingModal } from '../../legacy-redux/actions/boxActions';
import { createUsageLog } from '../admin/usage-logs/state/data';
import {
    exportsPageLoad,
    loadExportPacketOptions,
} from '../../legacy-redux/actions/exportsActions';
import errors from '../../lib/errors';
import { iconTypes } from '../core/components/Icon';
import testIds from '../../core/testIds';
import redirectToErrorPage from '../core/utils/redirectToErrorPage';
import redirectInsufficientPermissions from '../core/utils/redirectInsufficientPermissions';
import { HistoryType } from '../core/histories/config';
import {
    fetchAndStoreRelatedBookings,
    fetchAndStoreRelatedReports,
    fetchAndStoreReportTypes,
    loadEntityProfile,
} from './core/state/data';
import {
    entityTitleSelector,
    initializeEntityProfile,
    startWarrantsContainerTransitionDelay,
    uiReportsPaginationSelector,
} from './core/state/ui';
import EntityProfileLocations from './core/components/EntityProfileLocations';
import EntityProfileRelationships from './core/components/EntityProfileRelationships';
import EntityProfileProperty from './core/components/EntityProfileProperty';
import EntityProfileVehicles from './core/components/EntityProfileVehicles';
import EntityProfileExports from './core/components/EntityProfileExports';
import EntityProfileHistory from './core/components/EntityProfileHistory';
import EntityProfileTasks from './core/components/EntityProfileTasks';
import EntityProfileCases from './core/components/EntityProfileCases';
import EntityProfileCautions from './core/components/EntityProfileCautions';
import PersonHeader from './persons/components/EntityProfilePersonHeader';
import PersonDetails from './persons/components/EntityProfilePersonDetails';
import PersonReports from './persons/components/EntityProfilePersonReports';
import PersonBookings from './persons/components/EntityProfilePersonJmsBookings';
import PersonWarrants from './persons/components/EntityProfilePersonWarrants';
import OrganizationHeader from './organizations/components/EntityProfileOrganizationHeader';
import OrganizationDetails from './organizations/components/EntityProfileOrganizationDetails';
import OrganizationReports from './organizations/components/EntityProfileOrganizationReports';
import PropertyHeader from './property/components/EntityProfilePropertyHeader';
import PropertyDetails from './property/components/EntityProfilePropertyDetails';
import PropertyReports from './property/components/EntityProfilePropertyReports';
import PropertyStaffRemarks from './property/components/EntityProfilePropertyStaffRemarks';
import VehicleHeader from './vehicles/components/EntityProfileVehicleHeader';
import VehicleDetails from './vehicles/components/EntityProfileVehicleDetails';
import VehicleReports from './vehicles/components/EntityProfileVehicleReports';
import { queryParamDefaults } from './core/configuration';
import entityProfileReportsFilterForm from './core/state/forms/entityProfileReportsFilterForm';
import { customVehicleHistoryFilterHandler } from './core/helpers/filterHelpers';

// maps an entity type (i.e. persons, items, organizations) and a section type
// (i.e. details, reports) to a component which should handle
// a route
// note: in this case "entity types" are lower cased to match router path params,
// rather than referencing our actual entity types enum
const entityProfilesConfigMap = {
    persons: {
        headerComponent: PersonHeader,
        defaultProfileImage: iconTypes.PERSON,
        sections: {
            details: {
                contentComponent: PersonDetails,
                iconType: iconTypes.PERSON,
            },
            reports: {
                contentComponent: PersonReports,
            },
            bookings: {
                contentComponent: PersonBookings,
            },
            cases: {
                contentComponent: EntityProfileCases,
            },
            warrants: {
                contentComponent: PersonWarrants,
            },
            locations: {
                contentComponent: EntityProfileLocations,
            },
            relationships: {
                contentComponent: EntityProfileRelationships,
            },
            property: {
                contentComponent: EntityProfileProperty,
            },
            vehicles: {
                contentComponent: EntityProfileVehicles,
            },
            tasks: { contentComponent: EntityProfileTasks },
            cautions: {
                contentComponent: EntityProfileCautions,
                featureFlag: 'RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED',
            },
        },
    },
    organizations: {
        headerComponent: OrganizationHeader,
        defaultProfileImage: iconTypes.ORGANIZATION,
        sections: {
            details: {
                contentComponent: OrganizationDetails,
                iconType: iconTypes.ORGANIZATION,
            },
            reports: {
                contentComponent: OrganizationReports,
            },
            cases: {
                contentComponent: EntityProfileCases,
            },
            locations: {
                contentComponent: EntityProfileLocations,
            },
            relationships: {
                contentComponent: EntityProfileRelationships,
            },
            property: {
                contentComponent: EntityProfileProperty,
            },
            vehicles: {
                contentComponent: EntityProfileVehicles,
            },
        },
    },
    property: {
        headerComponent: PropertyHeader,
        defaultProfileImage: iconTypes.PROPERTY,
        sections: {
            details: {
                contentComponent: PropertyDetails,
                iconType: iconTypes.PROPERTY,
            },
            reports: {
                contentComponent: PropertyReports,
            },
            locations: {
                contentComponent: EntityProfileLocations,
            },
            remarks: {
                contentComponent: PropertyStaffRemarks,
            },
            history: {
                contentComponent: EntityProfileHistory,
            },
            tasks: { contentComponent: EntityProfileTasks },
        },
    },
    vehicles: {
        headerComponent: VehicleHeader,
        defaultProfileImage: iconTypes.VEHICLE,
        sections: {
            details: {
                contentComponent: VehicleDetails,
                iconType: iconTypes.VEHICLE,
            },
            reports: {
                contentComponent: VehicleReports,
            },
            cases: {
                contentComponent: EntityProfileCases,
            },
            locations: {
                contentComponent: EntityProfileLocations,
            },
            remarks: {
                contentComponent: PropertyStaffRemarks,
            },
            history: {
                contentComponent: EntityProfileHistory,
                showFilter: true,
                historyType: HistoryType.VEHICLE,
                customFilter: customVehicleHistoryFilterHandler,
            },
            tasks: { contentComponent: EntityProfileTasks },
            cautions: {
                contentComponent: EntityProfileCautions,
                featureFlag: 'RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED',
            },
        },
    },
};

const entityProfilesDefaultsMap = {
    details: {
        text: 'Details',
        testId: testIds.ENTITY_SIDEBAR_DETAILS_LINK,
    },
    reports: {
        text: 'Reports',
        iconType: iconTypes.REPORT,
        testId: testIds.ENTITY_SIDEBAR_REPORTS_LINK,
    },
    bookings: {
        text: 'Bookings',
        iconType: iconTypes.BOOKING,
        testId: testIds.ENTITY_SIDEBAR_BOOKINGS_LINK,
        featureFlag: 'RMS_JMS_BOOKINGS_ENABLED',
    },
    locations: {
        text: 'Locations',
        iconType: iconTypes.LOCATION,
        testId: testIds.ENTITY_SIDEBAR_LOCATIONS_LINK,
    },
    relationships: {
        text: 'Relationships',
        iconType: iconTypes.RELATIONSHIP,
        testId: testIds.ENTITY_SIDEBAR_RELATIONSHIPS_LINK,
    },
    property: {
        text: 'Property',
        iconType: iconTypes.PROPERTY,
        testId: testIds.ENTITY_SIDEBAR_PROPERTY_LINK,
    },
    remarks: {
        text: ({ remarksCount }) => `Remarks (${remarksCount})`,
        iconType: iconTypes.NOTE,
        testId: testIds.ENTITY_SIDEBAR_REMARKS_LINK,
    },
    vehicles: {
        text: 'Vehicles',
        iconType: iconTypes.VEHICLE,
        testId: testIds.ENTITY_SIDEBAR_VEHICLES_LINK,
    },
    history: {
        text: 'History',
        iconType: iconTypes.HISTORY,
        testId: testIds.ENTITY_SIDEBAR_HISTORY_LINK,
    },
    warrants: {
        text: 'Warrants',
        iconType: iconTypes.WARRANT,
        productModule: ProductModuleEnum.WARRANTS.name,
        testId: testIds.ENTITY_SIDEBAR_WARRANTS_LINK,
    },
    tasks: {
        text: 'Tasks',
        iconType: iconTypes.TASK,
        featureFlag: 'RMS_TASK_ENTITY_LINKS_ENABLED',
        testId: testIds.ENTITY_SIDEBAR_TASKS_LINK,
    },
    cases: {
        text: ({ casesFieldName }) => casesFieldName,
        iconType: iconTypes.CASE,
        featureFlag: 'RMS_CASE_ENTITY_LINKS_ENABLED',
        testId: testIds.ENTITY_SIDEBAR_CASES_LINK,
    },
    cautions: {
        text: ({ cautionsDisplayName }) => cautionsDisplayName,
        iconType: iconTypes.ALERT,
        testId: testIds.ENTITY_SIDEBAR_CAUTIONS_LINK,
    },
};

/**
 * A map containing entity profile modules. It is keyed by a module name, i.e.
 *   'persons', 'organizations', etc. A module specifies components and other ui
 *   elements which define the ui for a given entity profile type. This map is
 *   created by merging `entityProfileComponentMap` in to `entityProfileSectionMap`.
 *   `entityProfileSectionMap` is used only to implement sensible defaults for
 *   repeated portions of entity profiles ui, which are overriden by any keys/values
 *   in `entityProfileComponentMap`.
 */
export const entityProfileModuleMap = mapValues(entityProfilesConfigMap, (val) =>
    merge({}, { sections: pick(entityProfilesDefaultsMap, keys(val.sections)) }, val)
);

/**
 * This function will be provided to ReactRouter.Route#getComponents and is used
 *   to select which component will handle a given entity profiles route.
 * @method getComponent
 * @param  {Object}     nextState An object containing the next state of the router.
 *   Most importantly this includes path params
 * @param  {Function}   cb        A function with the signature (err, Component)
 *   to be called when a handler component has been determined for the given route.
 * @return {undefined}
 */
function getComponents(headerComponent, contentComponent, cb) {
    if (headerComponent && contentComponent) {
        cb(null, { headerComponent, contentComponent });
    } else {
        cb(new Error('no handler'));
    }
}

export function getEntityProfilesComponents({ params: { entityType, section } }, cb) {
    const headerComponent = get(entityProfileModuleMap, [entityType, 'headerComponent']);
    const contentComponent = get(entityProfileModuleMap, [
        entityType,
        'sections',
        section,
        'contentComponent',
    ]);
    getComponents(headerComponent, contentComponent, cb);
}

export function getReportsComponents({ params: { entityType } }, cb) {
    const headerComponent = get(entityProfileModuleMap, [entityType, 'headerComponent']);
    const contentComponent = get(
        entityProfileModuleMap,
        `${entityType}.sections.reports.contentComponent`
    );
    getComponents(headerComponent, contentComponent, cb);
}

export function getBookingsComponents({ params: { entityType } }, cb) {
    const headerComponent = get(entityProfileModuleMap, [entityType, 'headerComponent']);
    const contentComponent = get(
        entityProfileModuleMap,
        `${entityType}.sections.bookings.contentComponent`
    );
    getComponents(headerComponent, contentComponent, cb);
}

export function getCasesComponents({ params: { entityType } }, cb) {
    const headerComponent = get(entityProfileModuleMap, [entityType, 'headerComponent']);
    const contentComponent = get(
        entityProfileModuleMap,
        `${entityType}.sections.cases.contentComponent`
    );
    getComponents(headerComponent, contentComponent, cb);
}

/**
 * Separate function to get export components because there's no related sidebar link
 * and we need a separate route name for the exports toggling to work
 */
export function getExportComponents({ params: { entityType } }, cb) {
    const HeaderComponent = get(entityProfileModuleMap, [entityType, 'headerComponent']);
    const ContentComponent = EntityProfileExports;
    if (HeaderComponent) {
        cb(null, { headerComponent: HeaderComponent, contentComponent: ContentComponent });
    } else {
        cb(new Error('no handler'));
    }
}

export function onEnter({ params: { entityType, entityId } }, replace) {
    const usageLogAction = entityTypeSectionToUsageActionEnum[entityType];
    const usageLog = {
        primaryEntityType: entityTypeParamToEnum[entityType],
        action: usageLogAction,
        primaryEntityId: entityId,
        sourceModule: UsageSourceModuleEnum.ENTITY_PROFILES.name,
    };
    this.dispatch(openLoadingModal());
    this.dispatch(initializeEntityProfile());
    this.dispatch(loadEntityProfile(entityType, entityId))
        .then(({ profileDepartmentId }) => {
            const state = this.getState();

            this.dispatch(startPollingForCurrentViewers(entityType, entityId));
            this.dispatch(closeLoadingModal());
            this.dispatch(startWarrantsContainerTransitionDelay());

            this.dispatch(
                createUsageLog({
                    ...usageLog,
                    primaryEntityTitle: entityTitleSelector(state)(entityType),
                    primaryEntityDepartmentId: profileDepartmentId,
                })
            );
        })
        .catch((err) => {
            this.dispatch(createUsageLog(usageLog, { errorCode: err.code }));
            // If we receive a `GoneError`, then the profile has been merged.
            // In this case we have to redirect to the new profile page transparently,
            // so we are going to swallow the error
            if (err instanceof errors.GoneError) {
                const { targetId } = err.response;
                let pathname;
                switch (entityType) {
                    case 'persons':
                        pathname = `/profiles/persons/${targetId}`;
                        break;
                    case 'organizations':
                        pathname = `/profiles/organizations/${targetId}`;
                        break;
                    case 'vehicles':
                        pathname = `/profiles/vehicles/${targetId}`;
                        break;
                    default:
                        pathname = ``;
                }
                replace({ pathname });
            } else {
                this.dispatch(redirectToErrorPage({ errorCode: err.code }));
                throw err;
            }
        });
}

export function onSectionEnter({ params: { section } }) {
    const state = this.getState();
    const sectionProductModule = get(entityProfilesDefaultsMap[section], 'productModule');

    if (sectionProductModule && !isProductModuleActiveSelector(state)(sectionProductModule)) {
        this.dispatch(redirectInsufficientPermissions());
    }
}

export function onReportsSectionEnter({ params: { entityType, entityId } }) {
    const state = this.getState();
    const uiReportsPagination = uiReportsPaginationSelector(state);
    const reportsFetched = !!get(uiReportsPagination, 'latestFetchDateUtc');

    if (!reportsFetched) {
        this.dispatch(entityProfileReportsFilterForm.actionCreators.reset());
        this.dispatch(
            fetchAndStoreRelatedReports(
                entityType,
                entityId,
                {
                    from: queryParamDefaults.from,
                    size: queryParamDefaults.size,
                    sort_key: queryParamDefaults.sortKey,
                    sort_type: queryParamDefaults.sortType,
                },
                null
            )
        );
        this.dispatch(fetchAndStoreReportTypes(entityType, entityId));
    }
}

export function onBookingsSectionEnter({ params: { entityId } }) {
    this.dispatch(
        fetchAndStoreRelatedBookings(entityId, queryParamDefaults.from, queryParamDefaults.size)
    );
}

export function exportsOnEnter({ params }) {
    const { entityType, entityId } = params;
    this.dispatch(exportsPageLoad());
    this.dispatch(loadExportPacketOptions(entityTypeParamToEnum[entityType], entityId));
}

/**
 * This function will clear the context from `IS_MASTER` when navigating away
 * from the entity profiles route.
 */
export function onLeave({ params } = {}) {
    if (params.entityType && params.entityId) {
        this.dispatch(stopPollingForCurrentViewers(params.entityType, params.entityId));
    }
}

import { getNextYear } from '../core/dates/utils/dateHelpers';

// Slider config
export const SLIDER_THROTTLE = 16;
export const AGE_RANGE_SLIDER_MIN = 0;
export const AGE_RANGE_SLIDER_MAX = 80;
export const HEIGHT_RANGE_SLIDER_MIN = 54;
export const HEIGHT_RANGE_SLIDER_MAX = 84;
export const WEIGHT_RANGE_SLIDER_MIN = 80;
export const WEIGHT_RANGE_SLIDER_MAX = 350;
export const WEIGHT_RANGE_SLIDER_STEP = 10;
export const VEHICLE_YEAR_RANGE_SLIDER_MIN = 1950;
export const VEHICLE_YEAR_RANGE_SLIDER_MAX = getNextYear();

import { combineReducers } from 'redux';

import notificationsPersistentReducer from '~/client-common/core/domain/user-notifications/state/persistent';
import { globalPersistentReducer } from './globalReducers';

/**
 * State that needs to persist between login and logout. Nothing sensitive
 *   should be stored here.
 */
export default combineReducers({
    notifications: notificationsPersistentReducer,
    global: globalPersistentReducer,
});

import { FieldTypeEnum } from '@mark43/rms-api';

import { isEmpty } from 'lodash';

const { ATTRIBUTE, BOOLEAN, INTEGER } = FieldTypeEnum;

const valueIsEmpty = (value, fieldType) => {
    const cannotCheckEmptiness =
        fieldType === BOOLEAN || fieldType === ATTRIBUTE || fieldType === INTEGER;

    return (
        (!cannotCheckEmptiness && isEmpty(value)) ||
        (cannotCheckEmptiness && (value === undefined || value === null))
    );
};

export default valueIsEmpty;

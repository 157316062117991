import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

import { mustBeInt } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const strings = componentStrings.admin.usageLogs.UsageLogsAdmin;

const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END } = rangeFieldTypeEnum;

/**
 * Fieldset view model.
 */
export default buildFlatFormFieldViewModels([
    {
        key: 'id',
        label: strings.fields.id,
    },
    {
        key: 'parentId',
        label: strings.fields.parentId,
    },
    {
        key: 'userId',
        type: fieldTypeClientEnum.USER,
        label: strings.fields.userId,
    },
    {
        key: 'departmentId',
        label: strings.fields.departmentId,
    },
    {
        key: 'primaryEntityType',
        label: strings.fields.primaryEntityType,
    },
    {
        key: 'primaryEntityId',
        label: strings.fields.primaryEntityId,
        validators: {
            mustBeIntError: mustBeInt,
        },
    },
    {
        key: 'completion',
        label: strings.fields.completion,
    },
    {
        key: 'action',
        label: strings.fields.action,
    },
    {
        key: 'usageLogStartDateUtc',
        type: fieldTypeClientEnum.RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: RANGE_START,
        label: strings.fields.usageLogStartDateUtc,
    },
    {
        key: 'usageLogEndDateUtc',
        type: fieldTypeClientEnum.RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: RANGE_END,
    },
    {
        key: 'excludedIpAddresses',
        label: strings.fields.excludedIpAddresses,
    },
    {
        key: 'departmentIds',
        label: strings.fields.departmentIds,
    },
]);

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import { locationEntityLinksSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_ADDRESS_LINK_TYPE,
    LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_LOCATION_LINK_TYPE,
} from '~/client-common/core/enums/universal/fields';
import { MFTNItems } from '../../forms/components/NItems';

import { CardSection } from '../../../../legacy-redux/components/core/Card';
import { LocationSidePanel } from '../../locations/components/LocationSidePanel';
import {
    renderLocationNItems,
    renderAddButton,
    renderRemoveButton,
} from '../../names/components/nameFormNItemsRenderers';
import {
    convertFormLocationEntityLinkToLocationBundle,
    addLocationToFormFactory,
} from '../../names/util/nameFormHelpers';

const MAILING_ADDRESSES_PATH = 'mailingAddresses';
const PHYSICAL_ADDRESSES_PATH = 'physicalAddresses';

const StyledContentSection = styled(CardSection)`
    padding: 0;
    float: none;

    & > .mark43-react-card-content-section-header {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
`;

const renderLocationAddButton = (addText) => {
    return ({ overlayBase: { open }, setCloseFocusRefs }) => {
        return renderAddButton({
            addItem: open,
            setRef: setCloseFocusRefs,
            addText,
        });
    };
};

export const OrganizationProfileFormAddressFields = connect(
    createStructuredSelector({
        locations: locationsSelector,
        locationEntityLinks: locationEntityLinksSelector,
        formatFieldByName: formatFieldByNameSelector,
    })
)(
    class OrganizationProfileFormAddressFields extends React.Component {
        handleMailingLocationSaveSuccess = addLocationToFormFactory(
            this.props.form,
            MAILING_ADDRESSES_PATH
        );

        handlePhysicalLocationSaveSuccess = addLocationToFormFactory(
            this.props.form,
            PHYSICAL_ADDRESSES_PATH
        );

        convertFormLocationEntityLinkToLocationBundleWithLinkType = (
            formLocationEntityLink,
            linkType
        ) =>
            convertFormLocationEntityLinkToLocationBundle({
                formLocationEntityLink,
                stateData: {
                    locations: this.props.locations,
                    locationEntityLinks: this.props.locationEntityLinks,
                },
                additionalData: {
                    entityId: this.props.entityId,
                    entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                    linkType,
                },
            });

        render() {
            const locationSidePanelProps = {
                entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                entityId: this.props.entityId,
                id: 0,
                temp: true,
            };
            const mailingLocationSidePanelProps = {
                ...locationSidePanelProps,
                linkType: LinkTypesEnum.BUSINESS_ADDRESS,
                onSaveSuccess: this.handleMailingLocationSaveSuccess,
            };
            const physicalLocationSidePanelProps = {
                ...locationSidePanelProps,
                linkType: LinkTypesEnum.BUSINESS_LOCATION,
                onSaveSuccess: this.handlePhysicalLocationSaveSuccess,
            };

            return (
                <div>
                    <StyledContentSection
                        title={this.props.formatFieldByName(
                            LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_ADDRESS_LINK_TYPE
                        )}
                    >
                        <MFTNItems
                            path={MAILING_ADDRESSES_PATH}
                            addItemOnEmpty={false}
                            renderAddButton={undefined}
                            renderRemoveButton={renderRemoveButton}
                            render={({ item }) =>
                                renderLocationNItems({
                                    locationBundleWithUnknownLocationId: this.convertFormLocationEntityLinkToLocationBundleWithLinkType(
                                        item,
                                        LinkTypesEnum.BUSINESS_ADDRESS
                                    ),
                                })
                            }
                            minimumNumberOfItems={0}
                        />
                        <LocationSidePanel
                            overlayId={
                                overlayIdEnum.LOCATION_OVERLAY_ORGANIZATION_SIDE_PANEL_MAILING_ADDRESS
                            }
                            renderButton={renderLocationAddButton(
                                this.props.formatFieldByName(
                                    LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_ADDRESS_LINK_TYPE
                                )
                            )}
                            {...mailingLocationSidePanelProps}
                        />
                    </StyledContentSection>
                    <StyledContentSection
                        title={this.props.formatFieldByName(
                            LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_LOCATION_LINK_TYPE
                        )}
                    >
                        <MFTNItems
                            path={PHYSICAL_ADDRESSES_PATH}
                            addItemOnEmpty={false}
                            renderAddButton={undefined}
                            renderRemoveButton={renderRemoveButton}
                            render={({ item }) =>
                                renderLocationNItems({
                                    locationBundleWithUnknownLocationId: this.convertFormLocationEntityLinkToLocationBundleWithLinkType(
                                        item,
                                        LinkTypesEnum.BUSINESS_LOCATION
                                    ),
                                    renderEndDate: true,
                                })
                            }
                            minimumNumberOfItems={0}
                        />
                        <LocationSidePanel
                            overlayId={
                                overlayIdEnum.LOCATION_OVERLAY_ORGANIZATION_SIDE_PANEL_PHYSICAL_ADDRESS
                            }
                            renderButton={renderLocationAddButton(
                                this.props.formatFieldByName(
                                    LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_LOCATION_LINK_TYPE
                                )
                            )}
                            {...physicalLocationSidePanelProps}
                        />
                    </StyledContentSection>
                </div>
            );
        }
    }
);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, defaultProps, setDisplayName, setPropTypes } from 'recompose';
import keyMirror from 'keymirror';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { attributeOptionsByTypeForCurrentDepartmentSelector } from '../../../attributes/state/ui';
import MultiFieldSelect from './MultiFieldSelect';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const strings = componentStrings.forms.select.CaseStatusSelect;

/**
 * Single-value dropdown (not multiselect) containing all case status attributes
 *   (modeled by `caseStatusAttrId`) as well as an "Unassigned" option (modeled
 *   by `hasCaseStatus = false`). If you don't need the "Unassigned" option, use
 *   the `<AttributeSelect>` component instead of this one.
 * @param {Object}  props
 * @param {Object}  props.fields
 * @param {boolean} [props.includeExpired=false]
 */
const CaseStatusSelect = compose(
    setDisplayName('CaseStatusSelect'),
    setPropTypes({
        includeExpired: PropTypes.bool,
    }),
    connect(
        createStructuredSelector({
            attributeOptionsByTypeForCurrentDepartment: attributeOptionsByTypeForCurrentDepartmentSelector,
        })
    )
)(function CaseStatusSelect({
    fields: { hasCaseStatus, caseStatusAttrId },
    includeExpired = false,
    attributeOptionsByTypeForCurrentDepartment,
    ...otherProps
}) {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    return (
        <MultiFieldSelect
            label={strings.label(caseDisplayName)}
            {...otherProps}
            multiple={false}
            fields={{
                hasCaseStatus: {
                    options: [
                        {
                            value: false,
                            display: strings.options.hasCaseStatus.false,
                        },
                    ],
                    ...hasCaseStatus,
                },
                caseStatusAttrId: {
                    options: attributeOptionsByTypeForCurrentDepartment({
                        type: 'CASE_STATUS',
                        includeExpired,
                    }),
                    ...caseStatusAttrId,
                },
            }}
        />
    );
});

export const RRFCaseStatusSelect = compose(
    setDisplayName('RRFCaseStatusSelect'),
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            hasCaseStatus: null,
            caseStatusAttrId: null,
        }),
    }),
    connectRRFMultiFieldInput
)(CaseStatusSelect);

import { flowRight, map } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import testIds from '../../../../core/testIds';

// Redux
import { evidenceDashboardSearch } from '../state/ui';

// components
import Row from '../../../core/components/Row';

const FilterBody = styled.div`
    margin-top: 20px;
    column-count: 2;
    column-width: 100px;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.darkGrey};
    line-height: 15px;
`;

const FilterTitle = styled.span`
    color: ${(props) => props.theme.colors.mediumGrey};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

function EvidenceDashboardAppliedFilterSummary({ filterGroups }) {
    return (
        <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_SUMMARY}>
            <FilterBody>
                {map(filterGroups, (filterGroup) => {
                    // `key` isn't unique because the form has multiple date range fields
                    return (
                        <div key={`${filterGroup.key} ${filterGroup.label}`}>
                            <FilterTitle>{filterGroup.label}:</FilterTitle>
                            <span>&nbsp;{filterGroup.display}</span>
                        </div>
                    );
                })}
            </FilterBody>
        </Row>
    );
}

const mapStateToProps = createStructuredSelector({
    filterGroups: evidenceDashboardSearch.selectors.filtersSelector,
});

/**
 * This displays the applied filters from the filter modal in a row
 * beneath the search box.
 */
export default flowRight(
    connect(mapStateToProps),
    renderOnlyIf(({ filterGroups }) => filterGroups.length > 0)
)(EvidenceDashboardAppliedFilterSummary);

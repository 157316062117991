import { EntityTypeEnum } from '@mark43/rms-api';
import { chain, compact, get, includes, last, map } from 'lodash';

import { createSelector } from 'reselect';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import {
    saveFacility,
    LOAD_FACILITIES_START,
    LOAD_FACILITIES_SUCCESS,
    LOAD_FACILITIES_FAILURE,
} from '~/client-common/core/domain/facilities/state/data';
import {
    facilityViewModelsSelector,
    formatFacilityByIdSelector,
} from '~/client-common/core/domain/facilities/state/ui';
import {
    loadStorageLocationsInFacility,
    loadStorageLocationChildren,
    loadAllStorageLocationChildrenIds,
    loadAllStorageLocationsIdsInFacility,
    saveStorageLocation,
    expireStorageLocation,
    moveStorageLocation,
    convertStorageLocationToElasticSelector,
    storageLocationsSelector,
    NEXUS_STATE_PROP as STORAGE_LOCATIONS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/storage-locations/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    storageLocationViewModelsSelector,
    storageLocationViewModelByIdSelector,
} from '~/client-common/core/domain/storage-locations/state/ui';
import { MAX_SUB_LOCATION_DEPTH } from '~/client-common/core/domain/storage-locations/constants';
import { loadLocationsLinkedToEntity } from '~/client-common/core/domain/locations/state/data';

import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    NEXUS_STATE_PROP as ELASTIC_STORAGE_LOCATIONS_NEXUS_STATE_PROP,
    elasticStorageLocationsSelector,
} from '~/client-common/core/domain/elastic-storage-locations/state/data';
import {
    generateFacilitiesAdminListItems,
    generateStorageAdminListColumns,
    actualParentStorageSelected,
} from '../../utils/evidenceFacilitiesAdminHelpers';
import storageLocationAdminForm from '../forms/storageLocationAdminForm';
import facilityAdminForm, {
    convertFacilityFormModelToLocationDataState,
} from '../forms/facilityAdminForm';
import { createModalSelector } from '../../../../core/box/state/ui';
import {
    openBox,
    closeBox,
    saveBoxSuccess,
    saveBoxFailure,
    saveBoxHalt,
} from '../../../../../legacy-redux/actions/boxActions';

import { FACILITY_OPTION_VALUE_IN_STORAGE_LOCATION_SELECT } from '../../configuration';

import { openLabelPrintingModal } from '../../../../evidence/label-printing/state/ui';

const TOGGLE_EXPIRED = 'evidence-facilities/TOGGLE_EXPIRED';
const SELECT_FACILITY_START = 'evidence-facilities/SELECT_FACILITY_START';
const SELECT_FACILITY_SUCCESS = 'evidence-facilities/SELECT_FACILITY_SUCCESS';
const SELECT_FACILITY_FAILURE = 'evidence-facilities/SELECT_FACILITY_FAILURE';
const SELECT_STORAGE_LOCATION_START = 'evidence-facilities/SELECT_STORAGE_LOCATION_START';
const SELECT_STORAGE_LOCATION_SUCCESS = 'evidence-facilities/SELECT_STORAGE_LOCATION_SUCCESS';
const SELECT_STORAGE_LOCATION_FAILURE = 'evidence-facilities/SELECT_STORAGE_LOCATION_FAILURE';
const OPEN_NEW_FACILITY_FORM = 'evidence-facilities/OPEN_NEW_FACILITY_FORM';
const OPEN_NEW_STORAGE_FORM = 'evidence-facilities/OPEN_NEW_STORAGE_FORM';
const OPEN_EXISTING_FACILITY_FORM_START = 'evidence-facilities/OPEN_EXISTING_FACILITY_FORM_START';
const OPEN_EXISTING_FACILITY_FORM_SUCCESS =
    'evidence-facilities/OPEN_EXISTING_FACILITY_FORM_SUCCESS';
const OPEN_EXISTING_FACILITY_FORM_FAILURE =
    'evidence-facilities/OPEN_EXISTING_FACILITY_FORM_FAILURE';
const OPEN_EXISTING_STORAGE_FORM = 'evidence-facilities/OPEN_EXISTING_STORAGE_FORM';
const CLOSE_FACILITY_FORM = 'evidence-facilities/CLOSE_FACILITY_FORM';
const CLOSE_STORAGE_FORM = 'evidence-facilities/CLOSE_STORAGE_FORM';

const facilitySidePanelContext = {
    name: boxEnum.FACILITY_ADMIN_SIDE_PANEL,
};

const storageSidePanelContext = {
    name: boxEnum.STORAGE_ADMIN_SIDE_PANEL,
};

const deleteStorageModalContext = {
    name: boxEnum.DELETE_STORAGE_MODAL,
};

const moveStorageModalContext = {
    name: boxEnum.MOVE_STORAGE_MODAL,
};

const strings = componentStrings.admin.evidenceFacilities;

export function toggleExpired() {
    return {
        type: TOGGLE_EXPIRED,
    };
}

function selectFacilityStart(id) {
    return {
        type: SELECT_FACILITY_START,
        payload: id,
    };
}

function selectFacilitySuccess(id) {
    return {
        type: SELECT_FACILITY_SUCCESS,
        payload: id,
    };
}

function selectFacilityFailure(errorMessage) {
    return {
        type: SELECT_FACILITY_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Select a facility. This will load all storage locations in the facility, and
 *   display all storage locations of depth 1 in the facility (in the second
 *   column).
 * @param  {number|null} [id]
 * @return {Promise}
 */
export function selectFacility(facilityId) {
    return function (dispatch, getState) {
        dispatch(selectFacilityStart(facilityId));

        // clear any selected storage location
        dispatch(selectStorageLocation(null));

        if (!facilityId) {
            // clear the selection
            dispatch(selectFacilitySuccess(null));
        } else if (includes(cachedFacilityIdsSelector(getState()), facilityId)) {
            // if the top level storage locations in this facility are already
            // cached, don't make another request
            dispatch(selectFacilitySuccess(facilityId));
        } else {
            return dispatch(loadStorageLocationsInFacility(facilityId))
                .then(() => {
                    dispatch(selectFacilitySuccess(facilityId));
                })
                .catch((err) => {
                    dispatch(selectFacilityFailure(err.message));
                });
        }
    };
}

function selectStorageLocationStart(id) {
    return {
        type: SELECT_STORAGE_LOCATION_START,
        payload: id,
    };
}

function selectStorageLocationSuccess(id) {
    return {
        type: SELECT_STORAGE_LOCATION_SUCCESS,
        payload: id,
    };
}

function selectStorageLocationFailure(errorMessage) {
    return {
        type: SELECT_STORAGE_LOCATION_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Select a storage location and load all its direct children. All parent
 *   locations of this location will be highlighted.
 * @param  {number|null} [id]
 */
export function selectStorageLocation(storageLocationId) {
    return function (dispatch, getState) {
        dispatch(selectStorageLocationStart(storageLocationId));

        if (!storageLocationId) {
            // clear the selection
            dispatch(selectStorageLocationSuccess(null));
        } else {
            const storageLocationViewModel = storageLocationViewModelByIdSelector(getState())(
                storageLocationId
            );
            const depth = 1 + get(storageLocationViewModel, 'parentStorageLocationIds.length', 0);
            // if the storage location is at max depth, it has no children to
            // load
            if (depth >= MAX_SUB_LOCATION_DEPTH) {
                dispatch(selectStorageLocationSuccess(storageLocationId));
            } else {
                return dispatch(loadStorageLocationChildren(storageLocationId))
                    .then(() => {
                        dispatch(selectStorageLocationSuccess(storageLocationId));
                    })
                    .catch((err) => {
                        dispatch(selectStorageLocationFailure(err.message));
                    });
            }
        }
    };
}

function openNewFacilityFormRaw() {
    return {
        type: OPEN_NEW_FACILITY_FORM,
    };
}

/**
 * Open a side panel form for creating a new facility.
 */
export function openNewFacilityForm() {
    return function (dispatch) {
        // clear the form
        dispatch(facilityAdminForm.actionCreators.reset());
        // open the side panel
        dispatch(openNewFacilityFormRaw());
        dispatch(openBox(facilitySidePanelContext));
    };
}

function openNewStorageFormRaw(facilityId, parentStorageLocationIds) {
    return {
        type: OPEN_NEW_STORAGE_FORM,
        payload: { facilityId, parentStorageLocationIds },
    };
}

/**
 * Open a side panel form for creating a new storage location.
 */
export function openNewStorageForm(facilityId, parentStorageLocationIds) {
    return function (dispatch) {
        // clear the form
        dispatch(storageLocationAdminForm.actionCreators.reset());
        dispatch(setParentStorageLocation(last(parentStorageLocationIds)));
        // open the side panel
        dispatch(openNewStorageFormRaw(facilityId, parentStorageLocationIds));
        dispatch(
            openBox(storageSidePanelContext, {
                isNew: true,
                facilityId,
                parentStorageLocationIds,
            })
        );
    };
}

function openExistingFacilityFormStart(id) {
    return {
        type: OPEN_EXISTING_FACILITY_FORM_START,
        payload: id,
    };
}

function openExistingFacilityFormSuccess() {
    return {
        type: OPEN_EXISTING_FACILITY_FORM_SUCCESS,
    };
}

function openExistingFacilityFormFailure(errorMessage) {
    return {
        type: OPEN_EXISTING_FACILITY_FORM_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Open a side panel form for editing an existing facility.
 */
export function openExistingFacilityForm(id) {
    return function (dispatch, getState) {
        // open the side panel
        dispatch(openExistingFacilityFormStart(id));
        dispatch(openBox(facilitySidePanelContext));
        // load the location linked to the facility
        dispatch(loadLocationsLinkedToEntity(EntityTypeEnum.EVIDENCE_FACILITY.name, id))
            .then(() => {
                const state = getState();
                const facilityViewModel = facilityViewModelsSelector(state)[id];
                // fill the form
                dispatch(openExistingFacilityFormSuccess());
                dispatch(facilityAdminForm.actionCreators.change(facilityViewModel));
            })
            .catch((err) => {
                dispatch(openExistingFacilityFormFailure(err.message));
            });
    };
}

function openExistingStorageFormRaw(storageLocationId) {
    return {
        type: OPEN_EXISTING_STORAGE_FORM,
        payload: storageLocationId,
    };
}

/**
 * For storageLocationAdminForm, action to set parent location select dropdown.
 * @param  {number|undefined} parentStorageLocationId
 * @return {Promise}
 */
export function setParentStorageLocation(parentStorageLocationId) {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const changePath = storageLocationAdminForm.actionCreators.changePath;

        if (!!parentStorageLocationId) {
            const storageLocationExists = !!elasticStorageLocationsSelector(state)[
                parentStorageLocationId
            ];

            if (!storageLocationExists) {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [ELASTIC_STORAGE_LOCATIONS_NEXUS_STATE_PROP]: [
                                convertStorageLocationToElasticSelector(state)(
                                    parentStorageLocationId
                                ),
                            ],
                        },
                        { type: 'STORE_ELASTIC_STORAGE_LOCATIONS' }
                    )
                );
            }
            return dispatch(changePath('parentStorageLocationId', parentStorageLocationId));
        } else {
            return dispatch(
                changePath(
                    'parentStorageLocationId',
                    FACILITY_OPTION_VALUE_IN_STORAGE_LOCATION_SELECT
                )
            );
        }
    };
}

/**
 * Open a side panel form for editing an existing storage location.
 */
export function openExistingStorageForm(storageLocationId) {
    return function (dispatch, getState) {
        const state = getState();
        const storageLocationViewModel = storageLocationViewModelByIdSelector(state)(
            storageLocationId
        );
        const { parentStorageLocationIds } = storageLocationViewModel;

        // fill the form
        dispatch(storageLocationAdminForm.actionCreators.change(storageLocationViewModel));
        dispatch(setParentStorageLocation(last(parentStorageLocationIds)));
        // open the side panel
        dispatch(openExistingStorageFormRaw(storageLocationId));
        dispatch(
            openBox(storageSidePanelContext, {
                isNew: false,
                storageLocationId,
            })
        );
    };
}

function closeFacilityFormRaw() {
    return {
        type: CLOSE_FACILITY_FORM,
    };
}

/**
 * Close the facility side panel form.
 */
export function closeFacilityForm() {
    return function (dispatch) {
        dispatch(closeFacilityFormRaw());
        dispatch(closeBox(facilitySidePanelContext));
        dispatch(facilityAdminForm.actionCreators.reset());
    };
}

function closeStorageFormRaw() {
    return {
        type: CLOSE_STORAGE_FORM,
    };
}

/**
 * Close the storage location side panel form.
 */
export function closeStorageForm() {
    return function (dispatch) {
        dispatch(closeStorageFormRaw());
        dispatch(closeBox(storageSidePanelContext));
        dispatch(storageLocationAdminForm.actionCreators.reset());
    };
}

/**
 * Submit the facility admin form to create/update a facility. After success,
 *   close the box and select the facility if it is new.
 */
export function submitFacilityForm() {
    return function (dispatch, getState) {
        // submit the form
        return dispatch(
            facilityAdminForm.actionCreators.submit((formModel) => {
                const state = getState();
                const isNew = newFacilityFormIsOpenSelector(state);
                // these values are null/undefined if the facility is new
                const id = facilityFormFacilityIdSelector(state);
                const facilityViewModel = facilityViewModelsSelector(state)[id];

                // convert form model to data state
                const facility = facilityAdminForm.convertFromFormModel(formModel, id);
                const location = convertFacilityFormModelToLocationDataState(
                    formModel,
                    id,
                    get(facilityViewModel, 'locationId')
                );

                // make the API request
                return dispatch(saveFacility(isNew, facility, location))
                    .then((facility) => {
                        // if a new facility was created, select it in the UI
                        if (isNew) {
                            dispatch(selectFacility(facility.id));
                        }
                        // close the side panel and reset state
                        dispatch(saveBoxSuccess(facilitySidePanelContext));
                        dispatch(closeFacilityForm());
                    })
                    .catch((err) => {
                        // API error
                        dispatch(saveBoxFailure(facilitySidePanelContext, err.message));
                    });
            })
        ).catch(() => {
            // validation error
            dispatch(saveBoxFailure(facilitySidePanelContext));
        });
    };
}

/**
 * Submit the modal that moves a storage location
 */
export function submitLocationMoveConfirmationModal(sourceLocationId, destinationLocationId) {
    return (dispatch, getState) =>
        dispatch(moveStorageLocation(sourceLocationId, destinationLocationId))
            .then(() => {
                dispatch(saveBoxSuccess(moveStorageModalContext));
                dispatch(saveBoxSuccess(storageSidePanelContext));
                dispatch(closeStorageForm());

                // update state to reflect changes
                if (!!destinationLocationId) {
                    dispatch(loadStorageLocationChildren(destinationLocationId));
                } else {
                    const facilityId = selectedFacilityIdSelector(getState());
                    dispatch(loadStorageLocationsInFacility(facilityId));
                }
            })
            .catch((err) => {
                const { parentStorageLocationIds } = storageFormStorageLocationViewModelSelector(
                    getState()
                );

                // reset parent location select
                dispatch(setParentStorageLocation(last(parentStorageLocationIds)));

                dispatch(closeBox(moveStorageModalContext));
                dispatch(saveBoxFailure(storageSidePanelContext, err.message));
            });
}

/**
 * Submit the storage location admin form to create/update a storage location.
 *   After success, close the box and select the storage location if it is new.
 */
export function submitStorageForm() {
    return function (dispatch, getState) {
        // submit the form
        dispatch(
            storageLocationAdminForm.actionCreators.submit((formModel) => {
                const state = getState();
                const isNew = newStorageFormIsOpenSelector(state);
                const storageLocationId = storageFormStorageLocationIdSelector(state);
                const storageLocationViewModel = storageLocationViewModelByIdSelector(state)(
                    storageLocationId
                );
                const newFacilityId = storageFormFacilityIdSelector(state);
                const newParentStorageLocationIds = storageFormParentStorageLocationIdsSelector(
                    state
                );
                // convert form model to data state
                const storageLocation = storageLocationAdminForm.convertFromFormModel(
                    formModel,
                    storageLocationViewModel,
                    isNew,
                    newFacilityId,
                    newParentStorageLocationIds
                );

                // make the API request
                return dispatch(saveStorageLocation(isNew, storageLocation))
                    .then((storageLocation) => {
                        const isStorageLocationMove = !actualParentStorageSelected(
                            last(storageLocation.parentStorageLocationIds),
                            formModel.parentStorageLocationId
                        );

                        const applicationSettings = applicationSettingsSelector(state);

                        if (applicationSettings.EVIDENCE_AUTO_LOCATION_UPDATE_ENABLED && !isNew) {
                            const isIsHighValueUpdated =
                                storageLocationViewModel.formerIsHighValue !==
                                storageLocation.isHighValue;
                            if (isIsHighValueUpdated) {
                                dispatch(
                                    updateIsHighValueInStorageLocationChildrenAndGrandchildren(
                                        storageLocation.id,
                                        storageLocation.isHighValue
                                    )
                                );
                            }
                        }

                        if (isStorageLocationMove) {
                            // stop loading indicator in background
                            dispatch(saveBoxHalt(storageSidePanelContext));

                            const { parentStorageLocationId } = formModel;
                            const sourceLocationId = storageLocationId;
                            let destinationLocationId = parentStorageLocationId;

                            // selecting current facility as parent location
                            if (
                                parentStorageLocationId ===
                                FACILITY_OPTION_VALUE_IN_STORAGE_LOCATION_SELECT
                            ) {
                                destinationLocationId = null;
                            }

                            return dispatch(
                                openBox(moveStorageModalContext, {
                                    sourceLocationId,
                                    destinationLocationId,
                                })
                            );
                        }

                        // if a new storage was created, select it in the UI
                        if (isNew) {
                            dispatch(selectStorageLocation(storageLocation.id));
                        }
                        // close the side panel and reset state
                        dispatch(saveBoxSuccess(storageSidePanelContext));
                        dispatch(closeStorageForm());
                    })
                    .catch((err) => {
                        // API error
                        dispatch(saveBoxFailure(storageSidePanelContext, err.message));
                    });
            })
        ).catch(() => {
            // validation error
            dispatch(saveBoxFailure(storageSidePanelContext));
        });
    };
}

/**
 * Open a modal that either confirms the user wants to expire a storage
 *   location, or tells them expiration is not possible.
 */
export function openDeleteStorageModal(storageLocationId) {
    return function (dispatch) {
        dispatch(openBox(deleteStorageModalContext, { storageLocationId }));
    };
}

/**
 * Print preview all (including all subsequent children) barcode labels for a specific storage location.
 * @param  {number} storageLocationId
 * @param  {boolean} includeExpired
 * @param  {boolean} includeDeleted
 * @return {Promise}
 */
export const printPreviewBarcodeLabelsByStorageLocationId = (
    storageLocationId,
    includeExpired,
    includeDeleted
) => (dispatch, getState) =>
    dispatch(
        loadAllStorageLocationChildrenIds(storageLocationId, includeExpired, includeDeleted)
    ).then((storageLocationIds) => {
        const storageLocationViewModel = storageLocationViewModelByIdSelector(getState())(
            storageLocationId
        );

        dispatch(
            openLabelPrintingModal({
                parentStorageLocationFullDisplayPath: get(
                    storageLocationViewModel,
                    'fullDisplayPath'
                ),
                storageLocationIds,
            })
        );
    });

/**
 * Print preview all (including all subsequent children) barcode labels for a specific facility.
 * @param  {number} facilityId
 * @param  {boolean} includeExpired
 * @param  {boolean} includeDeleted
 * @return {Promise}
 */
export const printPreviewBarcodeLabelsByFacilityId = (
    facilityId,
    includeExpired,
    includeDeleted
) => (dispatch, getState) =>
    dispatch(loadAllStorageLocationsIdsInFacility(facilityId, includeExpired, includeDeleted)).then(
        (storageLocationIds) =>
            dispatch(
                openLabelPrintingModal({
                    parentStorageLocationFullDisplayPath: formatFacilityByIdSelector(getState())(
                        facilityId
                    ),
                    storageLocationIds,
                })
            )
    );

/**
 * Submit the modal that expires a storage location and its descendants.
 */
export function submitDeleteStorageModal() {
    return function (dispatch, getState) {
        const state = getState();
        const storageLocationId = createModalSelector(
            deleteStorageModalContext,
            'storageLocationId'
        )(state);
        const storageLocationViewModel = storageLocationViewModelByIdSelector(state)(
            storageLocationId
        );

        if (storageLocationViewModel) {
            dispatch(expireStorageLocation(storageLocationId))
                .then(() => {
                    // close the modal
                    dispatch(saveBoxSuccess(deleteStorageModalContext));
                    // re-select the storage location to re-render it;
                    // otherwise, its visual status wouldn't change from active
                    // to expired
                    dispatch(selectStorageLocation(storageLocationId));
                })
                .catch((err) => {
                    dispatch(saveBoxFailure(deleteStorageModalContext, err.message));
                });
        } else {
            dispatch(
                saveBoxFailure(deleteStorageModalContext, strings.DeleteStorageModal.notFoundError)
            );
        }
    };
}

export function updateStorageLocationsAfterHighValueChange(
    storageLocations,
    updatedLocationId,
    isInHighValueContainer
) {
    return map(storageLocations, (storageLocation) => {
        if (includes(storageLocation.parentStorageLocationIds, updatedLocationId)) {
            return {
                ...storageLocation,
                isInHighValueContainer,
            };
        }
        return storageLocation;
    });
}

function updateIsHighValueInStorageLocationChildrenAndGrandchildren(
    updatedLocationId,
    isInHighValueContainer
) {
    return function (dispatch, getState, dependencies) {
        const storageLocations = storageLocationsSelector(getState());
        const updatedStorageLocations = updateStorageLocationsAfterHighValueChange(
            storageLocations,
            updatedLocationId,
            isInHighValueContainer
        );
        dispatch(
            dependencies.nexus.withEntityItems(
                {
                    [STORAGE_LOCATIONS_NEXUS_STATE_PROP]: updatedStorageLocations,
                },
                { type: 'UPDATE_STORAGE_LOCATION_CHILDREN_AND_GRANDCHILDREN' }
            )
        );
    };
}

const evidenceFacilitiesAdminUiSelector = (state) => state.ui.evidenceFacilitiesAdmin;

/**
 * Whether expired facilities and storage locations are currently visible in the admin lists.
 * @type {boolean}
 */
export const showExpiredSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ showExpired }) => showExpired
);

/**
 * Whether facilities are currently being loaded.
 * @type {boolean}
 */
export const loadingFacilitiesSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ loadingFacilities }) => loadingFacilities
);

/**
 * @type {string}
 */
export const loadingFacilitiesErrorMessageSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ loadingFacilitiesErrorMessage }) => loadingFacilitiesErrorMessage
);

/**
 * Whether a facility location is currently being loaded.
 * @type {boolean}
 */
export const loadingFacilityLocationSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ loadingFacilityLocation }) => loadingFacilityLocation
);

/**
 * Whether storage in a facility are currently being loaded.
 * @type {boolean}
 */
export const loadingStorageLocationsSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ loadingStorageLocations }) => loadingStorageLocations
);

/**
 * @type {string}
 */
export const loadingStorageLocationsErrorMessageSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ loadingStorageLocationsErrorMessage }) => loadingStorageLocationsErrorMessage
);

/**
 * The currently selected facility id in the left column.
 * @type {number|null}
 */
export const selectedFacilityIdSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ selectedFacilityId }) => selectedFacilityId
);

/**
 * Ids of the facilities that have been successfully selected, with their top
 *   level storage locations loaded and cached in data state.
 * @type {boolean}
 */
const cachedFacilityIdsSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ cachedFacilityIds }) => cachedFacilityIds
);

/**
 * Whether the form for creating a new facility is open.
 * @type {boolean}
 */
export const newFacilityFormIsOpenSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ newFacilityFormIsOpen }) => newFacilityFormIsOpen
);

/**
 * Whether the form for creating a new storage location is open.
 * @type {boolean}
 */
export const newStorageFormIsOpenSelector = createModalSelector(storageSidePanelContext, 'isNew');

/**
 * Id of the facility currently being edited in the facility admin form.
 * @type {number|null}
 */
const facilityFormFacilityIdSelector = createSelector(
    evidenceFacilitiesAdminUiSelector,
    ({ facilityFormFacilityId }) => facilityFormFacilityId
);

/**
 * View model of the currently selected facility in the left column.
 * @type {Object|undefined}
 */
const selectedFacilityViewModelSelector = createSelector(
    facilityViewModelsSelector,
    evidenceFacilitiesAdminUiSelector,
    (facilityViewModels, { selectedFacilityId }) => facilityViewModels[selectedFacilityId]
);

/**
 * Id of the storage location currently being edited in the storage location
 *   admin form.
 * @type {number|undefined|null}
 */
const storageFormStorageLocationIdSelector = createModalSelector(
    storageSidePanelContext,
    'storageLocationId'
);

/**
 * Facility id of a new storage location to be created in the storage location
 *   admin form.
 * @type {number|null}
 */
export const storageFormFacilityIdSelector = createModalSelector(
    storageSidePanelContext,
    'facilityId'
);

/**
 * The parentStorageLocationIds of the storage location being edited.
 * @type {number|null}
 */
const storageFormParentStorageLocationIdsSelector = createModalSelector(
    storageSidePanelContext,
    'parentStorageLocationIds'
);

/**
 * View model of the storage location currently selected on the admin page (the
 *   child at the highest depth). This does not have to be the same as the
 *   storage location currently being edited in the side panel.
 * @type {Object|undefined}
 */
const selectedStorageLocationViewModelSelector = createSelector(
    storageLocationViewModelByIdSelector,
    evidenceFacilitiesAdminUiSelector,
    (storageLocationViewModelById, { selectedStorageLocationId }) =>
        storageLocationViewModelById(selectedStorageLocationId)
);

/**
 * View model of the storage location currently being edited in the side panel.
 *   This does not have to be the same as the child storage location currently
 *   selected on the admin page.
 * @type {Object|undefined}
 */
export const storageFormStorageLocationViewModelSelector = createSelector(
    storageLocationViewModelByIdSelector,
    storageFormStorageLocationIdSelector,
    (storageLocationViewModelById, storageFormStorageLocationId) =>
        storageLocationViewModelById(storageFormStorageLocationId)
);

/**
 * View model version of `fullSelectedPathSelector`.
 * @type {Object[]}
 */
const viewModelsInFullSelectedPathSelector = createSelector(
    selectedFacilityViewModelSelector,
    selectedStorageLocationViewModelSelector,
    storageLocationViewModelByIdSelector,
    (selectedFacilityViewModel, selectedStorageLocationViewModel, storageLocationViewModelById) => {
        return compact([
            selectedFacilityViewModel,
            ...chain(selectedStorageLocationViewModel)
                .get('parentStorageLocationIds')
                .map((storageLocationId) => storageLocationViewModelById(storageLocationId))
                .value(),
            selectedStorageLocationViewModel,
        ]);
    }
);

/**
 * @type {Object[]}
 */
export const facilityAdminListItemsSelector = createSelector(
    facilityViewModelsSelector,
    selectedFacilityIdSelector,
    showExpiredSelector,
    generateFacilitiesAdminListItems
);

/**
 * @type {Object[]}
 */
export const storageAdminListColumnsSelector = createSelector(
    storageLocationViewModelsSelector,
    viewModelsInFullSelectedPathSelector,
    showExpiredSelector,
    generateStorageAdminListColumns
);

export default function evidenceFacilitiesAdminUiReducer(
    state = {
        showExpired: false,
        loadingFacilities: false,
        loadingFacilitiesErrorMessage: null,
        loadingFacilityLocation: false,
        loadingFacilityLocationErrorMessage: false,
        loadingStorageLocations: false,
        loadingStorageLocationsErrorMessage: null,
        selectedFacilityId: null,
        selectedStorageLocationId: null,
        cachedFacilityIds: [],
        cachedParentStorageLocationIds: [],
        newFacilityFormIsOpen: false,
        facilityFormFacilityId: null,
    },
    action
) {
    switch (action.type) {
        case TOGGLE_EXPIRED:
            return {
                ...state,
                showExpired: !state.showExpired,
            };
        case LOAD_FACILITIES_START:
            return {
                ...state,
                loadingFacilities: true,
                loadingFacilitiesErrorMessage: null,
                cachedFacilityIds: [],
            };
        case LOAD_FACILITIES_SUCCESS:
            return {
                ...state,
                loadingFacilities: false,
            };
        case LOAD_FACILITIES_FAILURE:
            return {
                ...state,
                loadingFacilities: false,
                loadingFacilitiesErrorMessage: action.payload,
            };
        case SELECT_FACILITY_START:
            return {
                ...state,
                loadingStorageLocations: true,
                loadingStorageLocationsErrorMessage: null,
                // don't wait for the API response before updating the UI
                selectedFacilityId: action.payload,
            };
        case SELECT_FACILITY_SUCCESS:
            return {
                ...state,
                loadingStorageLocations: false,
                cachedFacilityIds: !!action.payload
                    ? [...state.cachedFacilityIds, action.payload]
                    : state.cachedFacilityIds,
            };
        case SELECT_FACILITY_FAILURE:
            return {
                ...state,
                loadingStorageLocations: false,
                loadingStorageLocationsErrorMessage: action.payload,
            };
        case SELECT_STORAGE_LOCATION_START:
            return {
                ...state,
                loadingStorageLocations: true,
                loadingStorageLocationsErrorMessage: null,
                // don't wait for the API response before updating the UI
                selectedStorageLocationId: action.payload,
            };
        case SELECT_STORAGE_LOCATION_SUCCESS:
            return {
                ...state,
                loadingStorageLocations: false,
                cachedParentStorageLocationIds: !!action.payload
                    ? [...state.cachedParentStorageLocationIds, action.payload]
                    : state.cachedParentStorageLocationIds,
            };
        case SELECT_STORAGE_LOCATION_FAILURE:
            return {
                ...state,
                loadingStorageLocations: false,
                loadingStorageLocationsErrorMessage: action.payload,
            };
        case OPEN_NEW_FACILITY_FORM:
            return {
                ...state,
                newFacilityFormIsOpen: true,
            };
        case OPEN_EXISTING_FACILITY_FORM_START:
            return {
                ...state,
                loadingFacilityLocation: true,
                loadingFacilityLocationErrorMessage: null,
                facilityFormFacilityId: action.payload,
            };
        case OPEN_EXISTING_FACILITY_FORM_SUCCESS:
            return {
                ...state,
                loadingFacilityLocation: false,
            };
        case OPEN_EXISTING_FACILITY_FORM_FAILURE:
            return {
                ...state,
                loadingFacilityLocation: false,
                loadingFacilityLocationErrorMessage: action.payload,
            };
        case OPEN_EXISTING_STORAGE_FORM:
            return {
                ...state,
            };
        case CLOSE_FACILITY_FORM:
            return {
                ...state,
                newFacilityFormIsOpen: false,
                facilityFormFacilityId: null,
            };
        default:
            return state;
    }
}

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { max } from 'lodash';

import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    REPORT_RECORD_NUMBER,
    DISPLAY_ONLY_ORGANIZATION_LABEL,
    DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';

import advancedSearchReports from '../state/ui';
import StaleQueryNotificationBar from '../../core/components/StaleQueryNotificationBar';
import AdvancedSearchResultsTableHeader from '../../core/components/AdvancedSearchResultsTableHeader';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import ReportingEventNumberCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportingEventNumberCell';
import ReportOwnerCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportOwnerCell';
import ReportTypeCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportTypeCell';
import StatCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/StatCell';
import PrimaryLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/PrimaryLocationCell';
import PersonsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/PersonsCell';
import OrganizationsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/OrganizationsCell';
import PropertyCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/PropertyCell';
import VehiclesCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/VehiclesCell';
import RoutingLabelsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/RoutingLabelsCell';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';
import { savedSearchIsStale } from '../../core/utils/savedSearchIsStale';
import { useDashboardScroll } from '../../core/hooks/useDashboardScroll';
import useOnSaveSearchToggleHandler from '../../core/hooks/useOnSaveSearchToggleHandler';

const EventStartUtcCell = dateCellFactory('eventStartUtc');
const UpdatedDateUtcCell = dateCellFactory('updatedDateUtc');
const ReportCreationDateCell = dateCellFactory('reportCreationDateUtc');

// 107 is the height of the table header
const ClippableSearchTable = clippable({ bottomBuffer: 107 })(Table);

const strings = componentStrings.search.AdvancedSearchReportsResults;

const columnKeyToValueMap = {
    eventStartUtc: sortKeyEnum.REPORT_EVENT_START_UTC,
    relevance: sortKeyEnum.RELEVANCE,
    updatedDateUtc: sortKeyEnum.REPORT_UPDATED_DATE_UTC,
    reportCreationDateUtc: sortKeyEnum.REPORT_REPORT_CREATION_DATE_UTC,
    reportingEventNumber: sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER,
};

function AdvancedSearchReportsResults({
    // Current Query and Search Results
    results = [],
    query: {
        savedSearchId,
        elasticQuery,
        from,
        size,
        sortKey: currentSortKey,
        sortType: currentSortType,
    },
    totalCount: totalResults,
    activeColumnKeys,

    // Table UI State
    tableLoading,
    searchFilters,
    selectedRows,
    highlightedRows,

    // Event Handlers
    handleRowSelect,
    handleRowClick,
    handleDateOptionClick,
    handleEntityOptionClick,
    handlePaginationClick,
    handleSizeChange,
    handleSortTypeClick,
    handleRenLinkClick,

    // Saved Search
    savedSearchErrorMessage,
    savedSearches,

    // field names
    formatFieldByName,
    applicationSettings,
    router,
}) {
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: advancedSearchReports,
        isScrollToElement: true,
    });

    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const activeDateColumnKey = activeColumnKeys.date;
    const recordsWithoutARenEnabled = applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;
    const onSaveSearch = useOnSaveSearchToggleHandler(advancedSearchReports);
    const organizationLabel = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];

    return (
        <div>
            {savedSearchIsStale(savedSearches, savedSearchId) && <StaleQueryNotificationBar />}
            <ClippableSearchTable
                data={results}
                onRowClick={handleRowClick(getCurrentScrollPosition)}
                sortKey={currentSortKey}
                sortType={currentSortType}
                noRowsText={strings.noResults}
                disableBody={tableLoading}
                selectableRows={true}
                selectedRows={selectedRows}
                highlightedRows={highlightedRows}
                onSelectRow={handleRowSelect}
                containerClassName="search-results-table"
                rowComponent={ElasticResultRow}
                disableRowSelectWhen={(row) => row && !canRead(row.permissionSet)}
                tableCheckboxMargin="10px"
            >
                <TableHeader>
                    <AdvancedSearchResultsTableHeader
                        from={from}
                        to={from + results.length}
                        searchSize={size}
                        onSizeChange={handleSizeChange}
                        totalResults={totalResults}
                        currentPage={currentPage}
                        searchFilters={searchFilters}
                        onPaginationClick={(lastPage, nextPage) =>
                            handlePaginationClick(nextPage, size)
                        }
                        isSearchSavable={true}
                        onSaveSearchToggleClick={onSaveSearch}
                        savedSearchId={savedSearchId}
                        savedSearchErrorMessage={savedSearchErrorMessage}
                        searchModule={advancedSearchReports}
                        savedSearches={savedSearches}
                        elasticQuery={{ elasticQuery }}
                        onSaveSearch={onSaveSearch}
                    />
                    <TableColumns>
                        <OptionsTableColumn
                            display={({ display }) => display}
                            columnKey={activeDateColumnKey}
                            activeValue={columnKeyToValueMap[activeDateColumnKey]}
                            activeSortType={
                                currentSortKey === columnKeyToValueMap[activeDateColumnKey]
                                    ? currentSortType
                                    : undefined
                            }
                            onOptionClick={handleDateOptionClick}
                            onSortTypeClick={handleSortTypeClick}
                            width={127}
                            popoutWidth={160}
                        >
                            <OptionsTableColumnOption
                                display={strings.columns.relevance.label}
                                value={sortKeyEnum.RELEVANCE}
                                columnKey="relevance"
                            />
                            <OptionsTableColumnOption
                                display={strings.columns.eventDate.label}
                                value={sortKeyEnum.REPORT_EVENT_START_UTC}
                                columnKey="eventStartUtc"
                                sortOptions={[
                                    {
                                        display: strings.columns.eventDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.columns.eventDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                            <OptionsTableColumnOption
                                display={strings.columns.modifiedDate.label}
                                value={sortKeyEnum.REPORT_UPDATED_DATE_UTC}
                                columnKey="updatedDateUtc"
                                sortOptions={[
                                    {
                                        display: strings.columns.modifiedDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.columns.modifiedDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                            <OptionsTableColumnOption
                                display={strings.columns.creationDate.label}
                                value={sortKeyEnum.REPORT_REPORT_CREATION_DATE_UTC}
                                columnKey="reportCreationDateUtc"
                                sortOptions={[
                                    {
                                        display: strings.columns.creationDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.columns.creationDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                        </OptionsTableColumn>
                        <OptionsTableColumn
                            columnKey="reportingEventNumber"
                            display={({ display }) => display}
                            activeValue={sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER}
                            activeSortType={
                                currentSortKey === sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER
                                    ? currentSortType
                                    : undefined
                            }
                            onSortTypeClick={handleSortTypeClick}
                            width={129}
                            popoutWidth={160}
                        >
                            <OptionsTableColumnOption
                                display={
                                    recordsWithoutARenEnabled
                                        ? `${formatFieldByName(
                                              REPORT_REPORTING_EVENT_NUMBER
                                          )} / ${formatFieldByName(REPORT_RECORD_NUMBER)}`
                                        : formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
                                }
                                value={sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER}
                                columnKey="reportingEventNumber"
                                sortOptions={[
                                    {
                                        display: strings.columns.ren.ascendingSort,
                                        sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                    },
                                    {
                                        display: strings.columns.ren.descendingSort,
                                        sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                    },
                                ]}
                            />
                        </OptionsTableColumn>
                        <TableColumn
                            display={strings.columns.reportType.label}
                            columnKey="reportType"
                            width={152}
                        />
                        <TableColumn
                            display={formatFieldByName(DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL)}
                            columnKey="reportOwner"
                            width={110}
                        />
                        <OptionsTableColumn
                            display={({ display }) => display}
                            columnKey={activeColumnKeys.entity}
                            activeValue={activeColumnKeys.entity}
                            onOptionClick={handleEntityOptionClick}
                            width={236}
                        >
                            <OptionsTableColumnOption
                                display={strings.columns.location.label}
                                value="locations"
                            />
                            <OptionsTableColumnOption
                                display={strings.columns.persons.label}
                                value="persons"
                            />
                            <OptionsTableColumnOption
                                display={strings.columns.organizations.label(organizationLabel)}
                                value="organizations"
                            />
                            <OptionsTableColumnOption
                                display={strings.columns.property.label}
                                value="property"
                            />
                            <OptionsTableColumnOption
                                display={strings.columns.vehicles.label}
                                value="vehicles"
                            />
                        </OptionsTableColumn>
                        <TableColumn
                            display={strings.routingLabelsColumnHeader}
                            columnKey="routingLabels"
                            width={116}
                        />
                        <TableColumn
                            display={strings.columns.status.label}
                            columnKey="stat"
                            width={50}
                        />
                    </TableColumns>
                </TableHeader>
                <TableBody>
                    <EventStartUtcCell columnKey="eventStartUtc" />
                    <EventStartUtcCell columnKey="relevance" />

                    <UpdatedDateUtcCell columnKey="updatedDateUtc" />
                    <ReportCreationDateCell columnKey="reportCreationDateUtc" />
                    <ReportingEventNumberCell
                        columnKey="reportingEventNumber"
                        handleRenLinkClick={handleRenLinkClick(getCurrentScrollPosition)}
                    />
                    <ReportTypeCell columnKey="reportType" />
                    <ReportOwnerCell columnKey="reportOwner" />
                    <PrimaryLocationCell columnKey="locations" />
                    <PersonsCell columnKey="persons" />
                    <OrganizationsCell columnKey="organizations" />
                    <PropertyCell columnKey="property" />
                    <VehiclesCell columnKey="vehicles" />
                    <RoutingLabelsCell columnKey="routingLabels" supportSyncStatusEnabled={true} />
                    <StatCell columnKey="stat" />
                </TableBody>
            </ClippableSearchTable>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    query: advancedSearchReports.selectors.currentQuerySelector,
    results: advancedSearchReports.selectors.currentResultsViewModelsSelector,
    selectedRows: advancedSearchReports.selectors.selectedRowsSelector,
    highlightedRows: advancedSearchReports.selectors.highlightedRowsSelector,
    totalCount: advancedSearchReports.selectors.totalCountSelector,
    tableLoading: advancedSearchReports.selectors.tableLoadingSelector,
    searchFilters: advancedSearchReports.selectors.filtersSelector,
    activeColumnKeys: advancedSearchReports.selectors.activeColumnKeysSelector,
    savedSearchErrorMessage: advancedSearchReports.selectors.loadSavedSearchErrorMessageSelector,
    formatFieldByName: formatFieldByNameSelector,
    applicationSettings: applicationSettingsSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleRowSelect(newSelectedRows) {
        dispatch(advancedSearchReports.actionCreators.selectRows(newSelectedRows));
    },
    handleRowClick(getCurrentScrollPosition) {
        return (elasticReport, rowIndex) => {
            dispatch(
                advancedSearchReports.actionCreators.openSearchResult(
                    elasticReport,
                    rowIndex,
                    router,
                    getCurrentScrollPosition()
                )
            );
        };
    },
    handleDateOptionClick({ columnKey, value, sortOptions }) {
        dispatch(advancedSearchReports.actionCreators.setActiveColumnKey('date', columnKey));
        dispatch(
            advancedSearchReports.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleSortTypeClick(sortType, activeOption) {
        dispatch(
            advancedSearchReports.actionCreators.search({
                sortKey: activeOption.value,
                sortType,
            })
        );
    },
    handleEntityOptionClick({ value }) {
        dispatch(advancedSearchReports.actionCreators.setActiveColumnKey('entity', value));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            advancedSearchReports.actionCreators.search({ from: nextPage * size - size, size })
        );
    },
    handleSizeChange(size) {
        dispatch(advancedSearchReports.actionCreators.search({ from: 0, size }));
    },
    handleRenLinkClick(getCurrentScrollPosition) {
        return (event) => {
            event.stopPropagation();
            dispatch(
                advancedSearchReports.actionCreators.setScrollPosition(getCurrentScrollPosition())
            );
        };
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AdvancedSearchReportsResults);

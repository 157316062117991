import React from 'react';
import classNames from 'classnames';

export default function AdminWorkAreaWrapper({ children, className = '', wrapsForm = false }) {
    return (
        <div
            className={classNames(
                'admin-work-area-wrapper',
                wrapsForm && 'admin-work-area-wraps-form',
                className
            )}
            wrapsForm={wrapsForm}
        >
            {children}
        </div>
    );
}

import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import ElasticModelWrapper from '../../../../legacy-redux/components/core/tables/ElasticModelWrapper';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
} from './EntityProfileSection';
import EntityProfileSummaryWrapper from './EntityProfileSummaryWrapper';

function OwnerDetail({ strings, detail }) {
    const addressLines = _.map(detail.address, (line) => <div key={line}>{line}</div>);
    const proofOfOwnershipContent = strings.labels.proofOfOwnership ? (
        <SummaryRow label={strings.labels.proofOfOwnership}>{detail.proofOfOwnership}</SummaryRow>
    ) : null;
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isPoleEnabled = applicationSettings['RMS_POLE_DATA_CAPTURE_ENABLED'];

    return (
        <div className="linked-div">
            <SummaryRow label={strings.labels.name}>{detail.name}</SummaryRow>
            <SummaryRow label={strings.labels.address} hidden={!detail.address}>
                {addressLines}
                <div>
                    <span className="entity-profile-italicized">
                        {detail.moreLocationsCount > 0 &&
                            `+ ${detail.moreLocationsCount} other addresses`}
                    </span>
                </div>
            </SummaryRow>
            <SummaryRow label={strings.labels.phone} hidden={!detail.phone}>
                <div>{detail.phone}</div>
                <div>
                    <span className="entity-profile-italicized">
                        {detail.morePhoneNumbersCount > 0 &&
                            `+ ${detail.morePhoneNumbersCount} more`}
                    </span>
                </div>
            </SummaryRow>
            <SummaryRow label={strings.labels.dateEffectiveFrom} hidden={!isPoleEnabled}>
                {detail.dateEffectiveFrom}
            </SummaryRow>
            <SummaryRow label={strings.labels.dateEffectiveTo} hidden={!isPoleEnabled}>
                {detail.dateEffectiveTo}
            </SummaryRow>
            {proofOfOwnershipContent}
        </div>
    );
}

function mapLinkDetailsRows(strings, profileDetails) {
    return _.map(profileDetails, (detail, index) => {
        const linkTo = detail.isOrg
            ? `/profiles/organizations/${detail.nameId}`
            : `/profiles/persons/${detail.nameId}`;
        return (
            <ElasticModelWrapper key={index} entity={detail} isOwnerDetail={true}>
                {({ hasReadAccess }) => (
                    <EntityProfileSummaryWrapper
                        linkUrl={linkTo}
                        hasReadAccess={hasReadAccess}
                        isOwnerDetail={true}
                    >
                        <OwnerDetail strings={strings} detail={detail} />
                    </EntityProfileSummaryWrapper>
                )}
            </ElasticModelWrapper>
        );
    });
}

const parentNameItemAssociationToStringsMap = {
    [globalAttributes.nameItemAssociationGlobal.owner]:
        componentStrings.entityProfiles.item.OwnerDetails,
    [globalAttributes.nameItemAssociationGlobal.claimant]:
        componentStrings.entityProfiles.item.ClaimantDetails,
    [globalAttributes.nameItemAssociationGlobal.collector]:
        componentStrings.entityProfiles.item.CollectorDetails,
    [globalAttributes.nameItemAssociationGlobal.generic]:
        componentStrings.entityProfiles.item.GenericAssociatedNamesDetails,
};
export default function EntityProfileLinkDetails({
    parentNameItemAssociation = globalAttributes.nameItemAssociationGlobal.owner,
    linkDetails,
}) {
    const strings = parentNameItemAssociationToStringsMap[parentNameItemAssociation] || {};
    const partitionedLinkDetails = _.partition(linkDetails, (detail, index) => index % 2);
    const leftLinkDetailRows = mapLinkDetailsRows(strings, partitionedLinkDetails[1]);
    const rightLinkDetailRows = mapLinkDetailsRows(strings, partitionedLinkDetails[0]);

    return (
        <EntityProfileSection title={strings.title}>
            <EntityProfileSummaryColumn className="owner-details">
                <EntityProfileDetailSummaryList contentWidth={180}>
                    {leftLinkDetailRows}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
            <EntityProfileSummaryColumn className="owner-details">
                <EntityProfileDetailSummaryList contentWidth={180}>
                    {rightLinkDetailRows}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}

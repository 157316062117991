import styled from 'styled-components';

const SidebarControlsWrap = styled.div`
    display: none;
    margin-left: auto;
    margin-right: 0.25em;
    align-items: center;
`;

export default SidebarControlsWrap;

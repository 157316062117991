import React, { useCallback } from 'react';
import { NibrsHistoryView, ComplianceExportHistoryView, ExportIntervalTypeEnum } from '@mark43/rms-api';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
    Form,
    lifecycleOptions,
    formEvents,
    createFormConfiguration,
    createField,
} from 'markformythree';
import * as yup from 'yup';
import { noop } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { closeBox, openBox, saveBoxHalt } from '../../../legacy-redux/actions/boxActions';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../core/validation/yupMftValidation';
import { useFormGetter } from '../../core/forms/hooks/useFormGetter';
import { RmsDispatch } from '../../../core/typings/redux';
import { exportNibrsDeleteHistoryModalContext, NIBRS_COMPLIANCE_TYPE } from '../configuration';
import nibrsExportResource from '../resources/nibrsExportResource';
import complianceExportResource from '../resources/complianceExportResource';
import ComplianceMonthYearSelect from './ComplianceMonthYearSelect';
import ComplianceDeleteHistoryButton from './ComplianceDeleteHistoryButton';
import ComplianceDateRangeSelect from "./ComplianceDateRangeSelect";

type ComplianceExportHistoryDeleteHistoryProps = {
    lastHistoryData: NibrsHistoryView | ComplianceExportHistoryView | undefined;
    firstHistoryData: NibrsHistoryView | ComplianceExportHistoryView | undefined;
    agencyId: number | undefined;
    complianceType: string | undefined;
    onSubmit: () => void;
    isLoading: boolean;
    exportIntervalType: string;
};

const strings = componentStrings.compliance.ComplianceExportHistory;
const validationStrings = componentStrings.validation;

const complianceExportDeleteHistoryFormConfiguration = createFormConfiguration({
    exportDateUtc: createField<string>({}),
});

const validationSchema = yup.object().shape({
    exportDateUtc: yup.string().required(validationStrings.requiredError),
});

const FormContent = styled.div`
    display: flex;
    align-items: center;
`;

const ComplianceExportHistoryDeleteHistoryForm: React.FC<ComplianceExportHistoryDeleteHistoryProps> = (
    props
) => {
    const {
        onSubmit,
        isLoading,
        lastHistoryData,
        firstHistoryData,
        agencyId,
        complianceType,
        exportIntervalType,
    } = props;

    const { getForm } = useFormGetter();
    const dispatch = useDispatch<RmsDispatch>();

    const handleValidation = useCallback(({ formState, eventType }) => {
        const $form = formState.ui.$form;

        return validationSchema
            .validate(formState.model, { abortEarly: false })
            .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
            .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
    }, []);

    const deleteNibrsExportHistoryFromDate = useCallback(
        (agencyProfileId, exportDateUtc, complianceType) => {
            if (complianceType === NIBRS_COMPLIANCE_TYPE.name) {
                return nibrsExportResource.deleteNibrsExportHistoryFromDate(
                    agencyProfileId,
                    exportDateUtc
                );
            }
            return complianceExportResource.deleteComplianceExportHistoryFromDate(
                agencyProfileId,
                exportDateUtc,
                complianceType
            );
        },
        []
    );

    const lastExportedDate = !!lastHistoryData
        ? `${lastHistoryData.exportYear}-${lastHistoryData.exportMonth}`
        : '';
    const firstExportedDate = !!firstHistoryData
        ? `${firstHistoryData.exportYear}-${firstHistoryData.exportMonth}`
        : '';

    const lastExportedFullDate = !!lastHistoryData
        ? `${lastHistoryData.exportYear}-${lastHistoryData.exportMonth}-${lastHistoryData.exportDay ?? 1}`
        : '';
    const firstExportedFullDate = !!firstHistoryData
        ? `${firstHistoryData.exportYear}-${firstHistoryData.exportMonth}-${firstHistoryData.exportDay ?? 1}`
        : '';

    const handleDeleteComplianceHistory = useCallback(
        (form) => {
            form.submit()
                .then(async () => {
                    const exportDateUtc = form.get('exportDateUtc');
                    await deleteNibrsExportHistoryFromDate(agencyId, exportDateUtc, complianceType);
                    dispatch(saveBoxHalt(exportNibrsDeleteHistoryModalContext));
                    dispatch(closeBox(exportNibrsDeleteHistoryModalContext));
                    return onSubmit();
                })
                .catch(noop);
        },
        [agencyId, complianceType, dispatch, onSubmit, deleteNibrsExportHistoryFromDate]
    );

    const openDownloadModal = useCallback(() => {
        const form = getForm(formClientEnum.COMPLIANCE_DELETE_HISTORY);

        form?.submit()
            .then(() => dispatch(openBox(exportNibrsDeleteHistoryModalContext)))
            // no validation messages, required errors are displayed inline on inputs
            .catch(noop);
    }, [dispatch, getForm]);

    return (
        <Form
            configuration={complianceExportDeleteHistoryFormConfiguration}
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            initialState={{
                exportDateUtc: undefined,
            }}
            onValidate={handleValidation}
            validationEvents={[
                { eventType: formEvents.FORM_SUBMIT },
                { eventType: formEvents.INPUT_BLUR },
                { eventType: formEvents.INPUT_CHANGE },
            ]}
            name={formClientEnum.COMPLIANCE_DELETE_HISTORY}
            render={(form) => {
                return (
                    <FormContent>
                        {(exportIntervalType === ExportIntervalTypeEnum.TN_WEEKLY.name) &&
                            <ComplianceDateRangeSelect
                                agencyId={agencyId}
                                isClearable={false}
                                exportIntervalType={exportIntervalType}
                                lastExportedDate={lastExportedFullDate}
                                firstExportedDate={firstExportedFullDate}
                                isDisabled={!lastExportedDate}
                                label={strings.label.deleteHistoryFromMonth}
                                onChange={noop}
                                width={220}
                                showRange={false}
                                ignoreMaxMonthsOptions={true}
                            />}
                        {(exportIntervalType === ExportIntervalTypeEnum.MONTHLY.name) &&
                            <ComplianceMonthYearSelect
                                agencyId={agencyId}
                                clearable={false}
                                disabled={!lastExportedDate}
                                label={strings.label.deleteHistoryFromMonth}
                                lastExportedDate={lastExportedDate}
                                firstExportedDate={firstExportedDate}
                                ignoreMaxMonthsOptions={true}
                                path="exportDateUtc"
                                width={320}
                                onChange={noop}
                            />
                        }
                        <ComplianceDeleteHistoryButton
                            disabled={!form.getUi().valid || isLoading || !lastExportedDate}
                            deleteComplianceHistory={() => handleDeleteComplianceHistory(form)}
                            openDownloadModal={openDownloadModal}
                        />
                    </FormContent>
                );
            }}
        />
    );
};

export default ComplianceExportHistoryDeleteHistoryForm;

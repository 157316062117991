import type { EditorOptions } from 'tinymce';
import { useToast } from 'arc';
import get from 'lodash/get';
import { EntityTypeEnumType } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import editorResource from '~/client-common/core/domain/editor/resources/editorResource';
import errorToMessage from '../../errors/utils/errorToMessage';

const strings = componentStrings.core.Editor.errors;

type UseEditorImageProps = {
    entityId?: number;
    entityType: EntityTypeEnumType;
};
export const useEditorImage = ({
    entityId,
    entityType,
}: UseEditorImageProps): EditorOptions['images_upload_handler'] => {
    const toast = useToast();

    if (!entityId) {
        return undefined;
    }

    return async (blobInfo) => {
        const formData = new FormData();
        formData.append('my-file', blobInfo.blob(), blobInfo.filename());

        try {
            const attachment = await editorResource.uploadImage(entityId, entityType, formData);
            const imageResource = get(attachment, 'image.originalFile.fileResourcePath');
            if (!imageResource) {
                throw new Error(strings.imageUploadFailToast);
            }

            return imageResource;
        } catch (e) {
            toast({
                status: 'error',
                description: errorToMessage(e, strings.imageUploadFailToast),
            });
            return '';
        }
    };
};

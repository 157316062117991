import { ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';

const getLinkUrl = ({ itemType, item }) => {
    let path = '';
    switch (itemType) {
        case ElasticSearchTypeEnum.PERSON.name:
            path = `/profiles/persons/${item.id}`;
            break;
        case ElasticSearchTypeEnum.REPORT.name:
            path = `/reports/${item.id}`;
            break;
        case ElasticSearchTypeEnum.WARRANT.name:
            path = `/warrants/${item.id}`;
            break;
        case ElasticSearchTypeEnum.ORGANIZATION.name:
            path = `/profiles/organizations/${item.id}/details`;
            break;
        case ElasticSearchTypeEnum.CASE.name:
            path = `/cases/${item.id}/summary`;
            break;
        case ElasticSearchTypeEnum.PROPERTY.name:
            path = `/profiles/property/${item.id}/details`;
            break;
        case ElasticSearchTypeEnum.VEHICLE.name:
            path = `/profiles/vehicles/${item.id}/details`;
            break;
        case 'E_FILE': // There's no ElasticSearchTypeEnum.E_FILE.name yet
            path = `/e-file/${item.id}/summary`;
            break;
        case EntityTypeEnum.BOOKING.name:
            path = `/bookings/${item.id}`;
            break;
        default:
            path = '';
    }

    return path;
};

export default getLinkUrl;

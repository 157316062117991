import { get } from 'lodash';

const ARRAY_INDEX_SENTINEL = '.[INDEX].';

/**
 * This function is used to expand all possible paths for a given path pattern in the form of `a.b.c.[INDEX].d.e`.
 * The pattern can contain any number of array index sentinels ([INDEX]). Whenever a sentinel is encountered the
 * value in the provided `value` object is expected to be an array. If and array with entries is found, the paths
 * will be expanded for each index in the array.
 *
 * If a path cannot be fully expanded due to missing values it will be ignored, ensuring that only
 * valid paths are returned.
 */
export function expandArraySentinelPaths(path: string, value: Record<string, unknown>): string[] {
    if (!path.includes(ARRAY_INDEX_SENTINEL)) {
        return [path];
    }

    const arrayIndexSentinelPos = path.indexOf(ARRAY_INDEX_SENTINEL);
    const pathSegment = path.substring(0, arrayIndexSentinelPos);
    const remaining = path.substring(arrayIndexSentinelPos + ARRAY_INDEX_SENTINEL.length);
    const arrayValuesForPaths = get(value, pathSegment);

    return Array.isArray(arrayValuesForPaths) && arrayValuesForPaths.length
        ? arrayValuesForPaths.flatMap((value, index) =>
              expandArraySentinelPaths(remaining, value).map(
                  (nestedPath) => `${pathSegment}.${index}.${nestedPath}`
              )
          )
        : [];
}

import { mapValues, reduce } from 'lodash';
import { involvedProfilesSidebarConfig } from '../../helpers/involvedProfilesSidebarConfig';

const SET_IS_OPEN_BY_KEY = 'SET_IS_OPEN_BY_KEY';
const SET_IS_OPEN_FOR_ALL = 'SET_IS_OPEN_FOR_ALL';

export const setIsOpenByKey = (key, isOpen) => {
    return {
        type: SET_IS_OPEN_BY_KEY,
        payload: {
            key,
            isOpen,
        },
    };
};

export const setIsOpenForAll = (isOpen) => {
    return {
        type: SET_IS_OPEN_FOR_ALL,
        payload: {
            isOpen,
        },
    };
};

export function collapsibleStateReducer(state, action) {
    switch (action.type) {
        case SET_IS_OPEN_BY_KEY:
            return {
                ...state,
                [action.payload.key]: action.payload.isOpen,
            };
        case SET_IS_OPEN_FOR_ALL:
            return mapValues(state, () => action.payload.isOpen);
        default:
            return state;
    }
}

export const initialCollapsibleState = reduce(
    involvedProfilesSidebarConfig,
    (acc, { key }) => {
        // All open by default
        acc[key] = true;
        return acc;
    },
    {}
);

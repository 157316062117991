import { findIndex, map, noop, reject } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { InlineBanner } from 'arc';
import {
    EFileMaterialSearchRequest,
    EntityTypeEnum,
    SearchResultEFileAttachmentSearchView,
} from '@mark43/rms-api';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import {
    buildAttachmentViewModel,
    convertAttachmentViewModelsForLightboxGallery,
} from '~/client-common/core/domain/attachments/state/ui';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { SkeletonTable } from '../../../../core/components/Skeleton';
import RMSTableComposite from '../../../../core/components/RMSTableComposite';
import Pagination from '../../../../../legacy-redux/components/core/Pagination';
import TableResultsSummary from '../../../../../legacy-redux/components/core/tables/TableResultsSummary';
import { openImageGalleryLightbox } from '../../../../../legacy-redux/actions/imageGalleryLightboxActions';
import { ATTACHMENTS_PAGE_SIZE } from '../../../constants';
import { useEFileContext, usePagination } from '../../../hooks';
import { eFileResource } from '../../../resources/eFileResource';
import { ColumnT, EFileSearchRequest, EFileAttachmentGridRowT } from '../../../types';
import { EFileMaterialAttachmentsHeaderRow } from '../table/attachments/EFileMaterialAttachmentsHeaderRow';
import { EFileMaterialAttachmentsGridRow } from '../table/attachments/EFileMaterialAttachmentsGridRow';
import { useDateTimeFormatter } from '../../../../core/current-user/hooks/dateTimeFormats';
import { useMaterialAttachments } from './EFileMaterialAttachmentsProvider';

const strings = componentStrings.eFiles.materials.attachments.grid;

const PaginationSummaryComponentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0.5em;
`;

const PaginationComponentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
`;

const columns: ColumnT[] = [
    {
        key: 'name',
        width: 293,
        title: strings.columns.name,
        sortable: false,
        filterable: false,
    },
    {
        key: 'source',
        width: 196,
        title: strings.columns.source,
        sortable: false,
        filterable: false,
    },
    {
        key: 'createdDate',
        width: 176,
        title: strings.columns.createdDate,
        sortable: false,
        filterable: false,
    },
    {
        key: 'actions',
        width: 45,
        title: '',
        sortable: false,
        filterable: false,
    },
];

export const EFileMaterialAttachmentsGrid = ({
    eFileId,
    setOnImport,
}: {
    eFileId: number;
    setOnImport: React.Dispatch<React.SetStateAction<() => void>>;
}): JSX.Element => {
    const initialQuery: EFileSearchRequest<EFileMaterialSearchRequest> = React.useMemo(
        () => ({
            query: {
                efileId: eFileId,
                entityTypes: [EntityTypeEnum.ATTACHMENT.name],
            },
            from: 0,
            size: ATTACHMENTS_PAGE_SIZE,
            sorts: [],
        }),
        [eFileId]
    );

    const [searchView, setSearchView] = React.useState<
        SearchResultEFileAttachmentSearchView | undefined
    >(undefined);

    const {
        getters: {
            efile: { getEFileViewModel },
        },
    } = useEFileContext();

    const { setSelectedMaterialIds } = useMaterialAttachments();

    const eFileViewModel = getEFileViewModel();

    const resource = React.useCallback(
        (searchRequest: EFileSearchRequest<EFileMaterialSearchRequest>, eFileId?: number) => {
            setSelectedMaterialIds([]);
            return eFileId
                ? eFileResource.searchEFileAttachments({
                      ...searchRequest,
                      query: {
                          ...searchRequest.query,
                          efileId: eFileId,
                      },
                  })
                : Promise.resolve(undefined);
        },
        [setSelectedMaterialIds]
    );

    const onSuccess = React.useCallback(
        (view: SearchResultEFileAttachmentSearchView | undefined) => {
            if (view) {
                setSearchView(view);
            }
        },
        []
    );
    const { callResource, loading } = useResourceDeferred(resource, onSuccess);

    // load initial results when the attachments tab is opened
    React.useEffect(() => {
        callResource(initialQuery, eFileId);
    }, [callResource, initialQuery, eFileId]);

    // load results when the user clicks to a different page
    const {
        loading: onPaginationLoad,
        page,
        from,
        to,
        onPaginationClick,
        reloadCurrentPage,
    } = usePagination<EFileMaterialSearchRequest, SearchResultEFileAttachmentSearchView>({
        eFileId,
        total: searchView?.totalCount || 0,
        initialSearchQuery: initialQuery,
        pageSize: ATTACHMENTS_PAGE_SIZE,
        resourceCall: resource,
        onSuccess,
        onError: noop,
    });

    React.useEffect(() => {
        setOnImport(() => reloadCurrentPage);
    }, [setOnImport, reloadCurrentPage]);

    const dispatch = useDispatch();
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);
    const dateTimeFormatter = useDateTimeFormatter();
    // when a filename is clicked, open the image gallery lightbox
    // this handler is defined here rather than in GridRows because the lightbox needs to receive all attachment data together
    const openLightbox = React.useCallback(
        (attachmentId: number) => {
            if (searchView?.items) {
                const attachmentViewModels = map(searchView.items, ({ attachment }) =>
                    buildAttachmentViewModel(attachment, formatMiniUserById)
                );
                const lightboxViewModels = convertAttachmentViewModelsForLightboxGallery(
                    attachmentViewModels,
                    dateTimeFormatter
                );
                const index = findIndex(attachmentViewModels, { attachmentId });
                dispatch(openImageGalleryLightbox(lightboxViewModels, index));
            }
        },
        [searchView?.items, dispatch, formatMiniUserById, dateTimeFormatter]
    );

    if (loading.errorMessage || onPaginationLoad.errorMessage) {
        return (
            <InlineBanner status="error">
                {loading.errorMessage || onPaginationLoad.errorMessage}
            </InlineBanner>
        );
    } else if (loading.isLoading || onPaginationLoad.isLoading || !searchView) {
        return <SkeletonTable />;
    }

    const items: EFileAttachmentGridRowT[] = map(searchView.items, (attachment) => {
        return {
            attachment: attachment.attachment,
            materialId: attachment.material.id,
            sourceCaseTitle: attachment.sourceCaseTitle,
            sourceReportTitle: attachment.sourceReportTitle,
        };
    });

    return (
        <RMSTableComposite
            columns={columns}
            items={items}
            noDataText={strings.noDataText}
            includeFilter={false}
            paginationOptions={{
                pageSize: ATTACHMENTS_PAGE_SIZE,
            }}
            renderHeaderRow={({ columns }: { columns: ColumnT[] }) => (
                <EFileMaterialAttachmentsHeaderRow
                    columns={columns}
                    canEdit={!!eFileViewModel?.canEdit}
                    materialIds={map(items, (row) => row.materialId)}
                />
            )}
            renderBodyRow={({ item }: { item: EFileAttachmentGridRowT }) => {
                const materialId = item.materialId;
                return (
                    <EFileMaterialAttachmentsGridRow
                        key={materialId}
                        attachmentRow={item}
                        openLightbox={openLightbox}
                        onRemove={() => {
                            setSearchView((searchView) =>
                                searchView
                                    ? {
                                          ...searchView,
                                          totalCount: searchView.totalCount - 1,
                                          items: reject(
                                              searchView.items,
                                              (item) => item.material.id === materialId
                                          ),
                                      }
                                    : undefined
                            );
                        }}
                    />
                );
            }}
            renderLayout={({ tableComponent }: { tableComponent: JSX.Element }) => {
                return (
                    <>
                        <PaginationSummaryComponentWrapper>
                            <TableResultsSummary
                                from={from}
                                to={to}
                                totalResults={searchView.totalCount}
                                className={null}
                            />
                        </PaginationSummaryComponentWrapper>
                        {tableComponent}
                        {searchView.totalCount > 0 && (
                            <PaginationComponentWrapper>
                                <Pagination
                                    currentPage={page}
                                    itemsPerPage={ATTACHMENTS_PAGE_SIZE}
                                    itemCount={searchView.totalCount}
                                    onClick={onPaginationClick}
                                    maxEdgeItems={1}
                                    className={null}
                                    gapText={null}
                                />
                            </PaginationComponentWrapper>
                        )}
                    </>
                );
            }}
        />
    );
};

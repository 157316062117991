import React from 'react';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// configs
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';

import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';

const AgencyNameContainer = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

function InfoCell({
    elasticModel,
    formatFieldByName,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}) {
    const agencyEventNumber = elasticModel.agencyEventNumber;
    const reportingEventNumber = elasticModel.reportingEventNumber;

    const isExternalAgency = elasticModel.departmentId !== currentUserDepartmentId;
    const departmentDisplayTag = isExternalAgency
        ? ` (${departmentNameFromConsortiumLinksByDepartmentId(elasticModel.departmentId)})`
        : undefined;

    return (
        <div className="elastic-cad-ticket-info">
            {agencyEventNumber && (
                <div className="ticket-number">
                    <div className="number-label">CAD #:</div>
                    <div>{agencyEventNumber}</div>
                </div>
            )}
            {reportingEventNumber && (
                <div className="ticket-number">
                    <div className="number-label">
                        {`${formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)}:`}
                    </div>
                    <div>{reportingEventNumber}</div>
                </div>
            )}
            {departmentDisplayTag && (
                <AgencyNameContainer>{departmentDisplayTag}</AgencyNameContainer>
            )}
        </div>
    );
}

export default compose(
    mapProps((ownerProps) => ({ elasticModel: ownerProps })),
    connect(
        createStructuredSelector({
            formatFieldByName: formatFieldByNameSelector,
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
        })
    )
)(InfoCell);

import React from 'react';
import styled from 'styled-components';
import _, { difference, map } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { sortedCurrentViewersViewModelsForEntityIdAndTypeWithoutUserIdSelector } from '~/client-common/core/domain/current-viewers/state/ui';
import { ICON_FLASH_DURATION } from '~/client-common/core/domain/current-viewers/configuration';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { omitProps, renderOnlyIf } from '~/client-common/helpers/reactHelpers';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    currentUserIdSelector,
    currentUserHasAbilitySelector,
} from '../../../../core/current-user/state/ui';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import _DropdownMenu from '../../../../core/components/DropdownMenu';

const strings = componentStrings.core.header.RecordsHeaderCurrentViewersToggle;

const MAX_NUMBER = 99;
// dropdown cannot scroll so we will cap it so it displays appropriately
const MAX_DISPLAYED = 10;

const DisplayedViewerCount = styled(_ViewerCount)`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: ${({ displayLength }) => (displayLength < 2 ? 0.75 : 0.625)}rem;
`;

const CurrentViewersDisplay = styled(_CurrentViewer)`
    white-space: nowrap;
    font-size: var(--arc-fontSizes-md);
    text-transform: none;
    padding: 4px 12px;

    &:first-child {
        margin-top: 0;
    }
`;

const DisplayedName = styled.span`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.darkGrey};
`;

const DisplayedId = styled.span`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-style: italic;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    padding-left: 5px;
`;

const LoadMoreContainer = styled.div`
    text-align: center;
    padding: 4px;
`;

const DropdownMenu = styled(omitProps(['flashButton', 'noBottomPadding'])(_DropdownMenu))`
    margin: 0;

    .dropdown-menu-button {
        width: 48px;
        ${(props) =>
            props.flashButton &&
            `background-color: var(--arc-colors-warning-accent);
            border-color: ${props.theme.colors.brightYellow};
        `}
    }

    .dropdown-menu-options {
        min-width: 180px;
        max-height: 410px;
        overflow: auto;
        padding: ${(props) => {
            const bottom = props.noBottomPadding ? 0 : 18;
            return `18px 18px ${bottom}px`;
        }};
    }
`;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
`;

const DescriptionText = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-md);
    padding: 4px 12px;
    text-transform: uppercase;
`;

const DividerBar = styled.div`
    border-top: 2px solid ${(props) => props.theme.colors.lightGrey};
    margin: 0 12px 10px;
`;

function _CurrentViewer({ className, displayName, displayId }) {
    return (
        <div className={className}>
            <DisplayedName>{displayName}</DisplayedName>
            <DisplayedId>{displayId || ''}</DisplayedId>
        </div>
    );
}

function _ViewerCount({ className, number, maxNumber = MAX_NUMBER }) {
    const showCount = number > 0;
    let numberDisplay;

    if (showCount) {
        numberDisplay = number > maxNumber ? <span>{maxNumber}+</span> : <span>{number}</span>;
    }

    return (
        <div className={className}>
            {!showCount && '-'}
            {numberDisplay}
        </div>
    );
}

function formatCurrentViewers(currentViewers, numToDisplay) {
    return _(currentViewers)
        .take(numToDisplay)
        .map((currentViewer) => {
            const key = currentViewer.userId;
            const { name, id } = getViewModelProperties(currentViewer);
            return <CurrentViewersDisplay key={key} displayName={name} displayId={id} />;
        })
        .value();
}

class RecordsHeaderCurrentViewersToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flashButton: false,
            showAll: false,
        };
        this.timeoutId = undefined;
        this.handleShowAll = this.handleShowAll.bind(this);
        this.handleDropdownClose = this.handleDropdownClose.bind(this);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const prevViewers = this.props.sortedCurrentViewersViewModelsForEntityIdAndTypeWithoutUserId(
            {
                entityId: this.props.entityId,
                entityType: this.props.entityType,
                userId: this.props.currentUserId,
            }
        );
        const nextViewers = nextProps.sortedCurrentViewersViewModelsForEntityIdAndTypeWithoutUserId(
            {
                entityId: nextProps.entityId,
                entityType: nextProps.entityType,
                userId: nextProps.currentUserId,
            }
        );
        // need to compare the actual ids, not just array lengths (users could be different)
        const oldIds = map(prevViewers, 'userId');
        const newIds = map(nextViewers, 'userId');
        // Only flash the button when there was an actual difference
        // in users viewing the page. We do not care about users leaving
        // and only want to flash the button when new users join, which
        // is why a simple difference is fine here.
        const diff = difference(newIds, oldIds);

        if (diff.length > 0) {
            this.setState({ flashButton: true }, () => {
                if (this.timeoutId) {
                    // if one already exists (only in case timeout duration > polling
                    // duration -- though it would just yield a consistently flashed
                    // button) just clear it before setting new one.
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = setTimeout(() => {
                    this.setState({ flashButton: false });
                }, ICON_FLASH_DURATION);
            });
        }
    }
    componentWillUnmount() {
        // so the timeout doesn't occur on an unmounted component (no-op)
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleShowAll() {
        // push this into the next event loop so
        // to allow click handling of the dropdown menu to occur
        // which relies on the "Load more" button having a `parentElement`,
        // meaning it must be still rendered into the DOM
        setTimeout(() => this.setState(() => ({ showAll: true })), 0);
    }

    handleDropdownClose() {
        this.setState(() => ({ showAll: false }));
    }

    render() {
        const { entityId, entityType, currentUserId } = this.props;
        const finalCurrentViewers = this.props.sortedCurrentViewersViewModelsForEntityIdAndTypeWithoutUserId(
            {
                entityId,
                entityType,
                userId: currentUserId,
            }
        );
        const count = finalCurrentViewers.length || 0;
        const len = count.toString().length;

        const maxDisplay = this.state.showAll ? Infinity : MAX_DISPLAYED;
        const shouldDisplayLoadMore = count > maxDisplay;

        return (
            <DropdownMenu
                buttonDisabled={count === 0}
                flashButton={this.state.flashButton}
                buttonContent={
                    <DropdownMenuButtonContainer>
                        <Icon
                            type={iconTypes.BINOCULARS}
                            size={len > 2 ? 18 : 20}
                            color="cobaltBlue"
                        />
                        <DisplayedViewerCount number={count} displayLength={len} />
                    </DropdownMenuButtonContainer>
                }
                noOptionDropdownClose={true}
                noBottomPadding={!this.state.showAll && shouldDisplayLoadMore}
                onClose={this.handleDropdownClose}
                tooltip={strings.tooltip}
            >
                <DescriptionText>{strings.description}</DescriptionText>
                <DividerBar />
                <div>{formatCurrentViewers(finalCurrentViewers, maxDisplay)}</div>
                {shouldDisplayLoadMore ? (
                    <LoadMoreContainer>
                        <Button
                            className={buttonTypes.ICON_LINK}
                            onClick={this.handleShowAll}
                            iconLeft={<Icon type={iconTypes.ADD} size={16} color="cobaltBlue" />}
                            float="none"
                            display="inline-flex"
                        >
                            {strings.loadAll}
                        </Button>
                    </LoadMoreContainer>
                ) : (
                    <div />
                )}
            </DropdownMenu>
        );
    }
}

/**
 * Dropdown menu in the report header for linking a new report to the current
 * report. This is rendered only if the current user has the relevant
 * permissions and there are available report types to be linked.
 */
const mapStateToProps = createStructuredSelector({
    sortedCurrentViewersViewModelsForEntityIdAndTypeWithoutUserId: sortedCurrentViewersViewModelsForEntityIdAndTypeWithoutUserIdSelector,
    currentUserId: currentUserIdSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
});

export default compose(
    connect(mapStateToProps),
    renderOnlyIf(({ currentUserHasAbility }) =>
        currentUserHasAbility(abilitiesEnum.CORE.CURRENT_VIEWERS)
    )
)(RecordsHeaderCurrentViewersToggle);

import { ElasticSearchTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { flatten, forEach, get, map, includes, compact, concat, values } from 'lodash';
import keyMirror from 'keymirror';

import { createSelector, createStructuredSelector } from 'reselect';
import getReportCaseStatusesResource from '~/client-common/core/domain/report-case-statuses/resources/reportCaseStatusResource';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { caseStatusGlobalAttrIdToChildAttrIdsSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
} from '~/client-common/core/fields/state/config';
import { nibrsOffenseCodeByCodeSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { openBox } from '../../../../../legacy-redux/actions/boxActions';
import { scrollToTop } from '../../../../core/components/ScrollableUnderSubheader';
import { casesBatchPolling } from '../../../batch-operations/state/ui';

import createSearchModule from '../../../../search/core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import unassignedReportsSearchForm, {
    convertUnassignedReportsSearchFormModelToFilterGroups,
    convertUnassignedReportsSearchElasticQueryToFormModel,
} from '../forms/unassignedReportsSearchForm';
import { reportsSearchQueryParamDefaults } from '../../../core/configuration';
import createElasticReportsResultsSelector from '../../../../search/core/utils/createReportsResultsSelector';
import { currentDepartmentIdForUserInConsortiumSelector } from '../../../../core/current-user/state/ui';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../../search/core/state/ui';
import recursivelyConvertElasticLocationQueries from '../../../../search/core/utils/recursivelyConvertElasticLocationQueries';
import reportsResource from '../../../../reports/core/resources/reportsResource';
import buildReportIdentifierView from '../../../../reports/core/utils/buildReportIdentifierView';

const NEW_CASES_BASE_QUERY = {
    hasCaseStatus: undefined,
    caseStatusAttrIds: [],
};

const inactiveCaseAttrIdsSelector = createSelector(
    caseStatusGlobalAttrIdToChildAttrIdsSelector,
    (caseStatusGlobalAttrIdToChildAttrIds) =>
        caseStatusGlobalAttrIdToChildAttrIds[globalAttributes.caseStatus.inactive]
);

const notOpenCaseStatusAttrIdsSelector = createSelector(
    caseStatusGlobalAttrIdToChildAttrIdsSelector,
    (caseStatusGlobalAttrIdToChildAttrIds) =>
        compact(
            concat(
                caseStatusGlobalAttrIdToChildAttrIds[globalAttributes.caseStatus.inactive],
                caseStatusGlobalAttrIdToChildAttrIds[globalAttributes.caseStatus.closed],
                caseStatusGlobalAttrIdToChildAttrIds[globalAttributes.caseStatus.unfounded]
            )
        )
);

const willNotInvestigateBaseQuerySelector = createSelector(
    inactiveCaseAttrIdsSelector,
    (inactiveCaseAttrIds) => {
        return {
            hasCaseStatus: true,
            caseStatusAttrIds: inactiveCaseAttrIds,
            excludedCaseStatusAttrIds: undefined,
        };
    }
);

const newUnassignedReportsBaseQuerySelector = createSelector(
    notOpenCaseStatusAttrIdsSelector,
    (notOpenCaseAttrIds) => {
        return {
            ...NEW_CASES_BASE_QUERY,
            excludedCaseStatusAttrIds: notOpenCaseAttrIds,
        };
    }
);

export const unassignedReportsSearch = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.REPORT_NOT_ASSIGNED_TO_CASE.name,
    baseUiSelector: (state) => state.ui.cases.unassignedReports,
    form: unassignedReportsSearchForm,
    elasticQueryToFilterGroups: convertUnassignedReportsSearchElasticQueryToFilterGroups,
    defaultTableState: {
        from: reportsSearchQueryParamDefaults.FROM,
        size: reportsSearchQueryParamDefaults.SIZE,
        // specify the default sort because it's not relevance sort
        sortKey: sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER,
        sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
        activeColumnKeys: {
            key: 'eventStartUtc',
            entity: 'locations',
        },
    },
    searchResultToRoutePath: ({ id }) => `/reports/${id}`,
    resourceMethod: elasticSearchResource.searchReports,
    resultsContainerClassName: 'unassigned-reports-results-container',
    createResultsViewModelsSelector: createElasticReportsResultsSelector,
    selectorToBind: createStructuredSelector({
        combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        formatFieldByName: formatFieldByNameSelector,
        nibrsOffenseCodeByCode: nibrsOffenseCodeByCodeSelector,
        applicationSettings: applicationSettingsSelector,
    }),
    transformElasticQueryBeforeSearchSelector: (state) => (elasticQuery) => {
        const transformIds = transformElasticQueryIdsToDisplayValuesSelector(state);
        const queryWithTransformedIds = transformIds(elasticQuery);
        return recursivelyConvertElasticLocationQueries(queryWithTransformedIds, {
            legacyQueryProp: 'involvedLocations',
            newQueryProp: 'involvedElasticLocations',
        });
    },
});

export const removeUnassignedReportsSearchResult =
    unassignedReportsSearch.actionCreators.removeResult;

export function clearUnassignedReportsSelection() {
    return (dispatch) => {
        dispatch(unassignedReportsSearch.actionCreators.selectRows([]));
    };
}

export const tabKeys = keyMirror({
    NEW: null,
    WILL_NOT_INVESTIGATE: null,
});

export function resetWillNotInvestigateCommentForm() {
    return (dispatch, getState, { formsRegistry }) => {
        const willNotInvestigateCommentForm = formsRegistry.get(
            RefContextEnum.FORM_WILL_NOT_INVESTIGATE.name
        );
        if (willNotInvestigateCommentForm) {
            willNotInvestigateCommentForm.resetModel();
        }
    };
}

export function submitWillNotInvestigateCommentForm(reportIds) {
    return (dispatch) => {
        const isBulk = reportIds.length > 1;
        if (isBulk) {
            return dispatch(bulkMarkReportsWillNotInvestigate(reportIds));
        } else {
            return dispatch(markReportWillNotInvestigateFromForm(reportIds[0]));
        }
    };
}

function markReportWillNotInvestigateFromForm(reportId) {
    return (dispatch, getState, { formsRegistry }) => {
        const willNotInvestigateCommentForm = formsRegistry.get(
            RefContextEnum.FORM_WILL_NOT_INVESTIGATE.name
        );

        const { comments } = willNotInvestigateCommentForm.get();

        return dispatch(markReportWillNotInvestigate(reportId, comments)).then(
            willNotInvestigateCommentForm.resetModel
        );
    };
}

export function markReportWillNotInvestigate(reportId, comments) {
    return (dispatch) =>
        getReportCaseStatusesResource()
            .willNotInvestigate(reportId, comments)
            .then(({ reportCaseStatus }) => {
                dispatch(unassignedReportsSearch.actionCreators.selectRows([]));
                dispatch(unassignedReportsSearch.actionCreators.removeResult(reportCaseStatus.id));
            });
}

function bulkMarkReportsWillNotInvestigate(reportIds) {
    return (dispatch, getState, { formsRegistry }) => {
        const willNotInvestigateCommentForm = formsRegistry.get(
            RefContextEnum.FORM_WILL_NOT_INVESTIGATE.name
        );
        const comments = willNotInvestigateCommentForm
            ? willNotInvestigateCommentForm.get().comments
            : undefined;

        return getReportCaseStatusesResource()
            .bulkWillNotInvestigate(reportIds, comments)
            .then(() => {
                dispatch(casesBatchPolling());
                if (willNotInvestigateCommentForm) {
                    willNotInvestigateCommentForm.resetModel();
                }
                // dashboard displays stale results. scroll to top for message
                // at top of dashboard directing users to refresh dashboard
                scrollToTop();
            });
    };
}

export function onSaveReasonForRelationSidePanelOnUnassignedReports(reports) {
    return (dispatch) => {
        // get reports that have the same REN
        const reportIdentifierView = buildReportIdentifierView(reports);
        return reportsResource
            .findReportTitlesByReportIdentifier(reportIdentifierView)
            .then(({ reportShortTitlesByREN, reportShortTitlesByRecordWithoutRENRecordNumber }) => {
                const reportIds = [
                    ...map(flatten(values(reportShortTitlesByREN)), 'reportId'),
                    ...map(
                        flatten(values(reportShortTitlesByRecordWithoutRENRecordNumber)),
                        'reportId'
                    ),
                ];

                // Remove reports from dashboard by report ID.
                // This includes reports that have the same REN
                forEach(reportIds, (reportId) => {
                    dispatch(removeUnassignedReportsSearchResult(reportId));
                });

                dispatch(unassignedReportsSearch.actionCreators.selectRows([]));
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function setQueryFromTabClick(tabKey) {
    return (dispatch, getState) => {
        const state = getState();
        const currentElasticQuery = get(
            unassignedReportsSearch.selectors.currentQuerySelector(state),
            'elasticQuery',
            {}
        );
        const departmentId = currentDepartmentIdForUserInConsortiumSelector(state);
        let augmentQuery;

        // eslint-disable-next-line default-case
        switch (tabKey) {
            case tabKeys.NEW:
                augmentQuery = newUnassignedReportsBaseQuerySelector(state);
                break;
            case tabKeys.WILL_NOT_INVESTIGATE:
                augmentQuery = willNotInvestigateBaseQuerySelector(state);
                break;
        }

        dispatch(
            unassignedReportsSearchForm.actionCreators.change({
                ...currentElasticQuery,
                ...augmentQuery,
                departmentIds: departmentId ? [departmentId] : undefined,
            })
        );
    };
}

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
function convertUnassignedReportsSearchElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    return convertUnassignedReportsSearchFormModelToFilterGroups(
        convertUnassignedReportsSearchElasticQueryToFormModel(elasticQuery, null, boundSelectors),
        formatFieldValue,
        boundSelectors
    );
}

/**
 * Open the narrative modal for the given report.
 * @param {Object} props Elastic report view model properties to be displayed in
 *   the modal.
 */
export function openNarrativeModal(props) {
    return function (dispatch) {
        return dispatch(
            openBox(
                {
                    name: boxEnum.NARRATIVE_MODAL,
                },
                props
            )
        );
    };
}

export const activeTabKeySelector = createSelector(
    unassignedReportsSearchForm.selectors.formModelByPathSelector,
    (formModelByPath) => {
        // Switching a couple things here:
        //
        // * Since we switched `hasCaseStatus` to be undefined for `NEW`
        // and `true` for `WILL_NOT_INVESTIGATE`, this is now swapped
        //
        // * We are now pulling this information from form state instead
        // of the current query, because we need to know the tab
        // before executing the search
        return formModelByPath('reportDetails.hasCaseStatus') === true
            ? tabKeys.WILL_NOT_INVESTIGATE
            : tabKeys.NEW;
    }
);

export function clearSearchForm() {
    return (dispatch, getState) => {
        const state = getState();
        const willNotInvestigateBaseQuery = willNotInvestigateBaseQuerySelector(state);
        const newUnassignedReportsBaseQuery = newUnassignedReportsBaseQuerySelector(state);

        const excludedCaseStatusAttrIds = get(
            newUnassignedReportsBaseQuery,
            'excludedCaseStatusAttrIds'
        );

        const activeTabKey = activeTabKeySelector(state);

        const caseStatusAttrIds =
            activeTabKey === tabKeys.WILL_NOT_INVESTIGATE
                ? get(willNotInvestigateBaseQuery, 'caseStatusAttrIds')
                : get(newUnassignedReportsBaseQuery, 'caseStatusAttrIds');

        // keep current hasCaseStatus, as it determines which results tab we're on (New or Will Not Investigate)
        // also keep caseStatusAttrIds so that clearing filters doesn't affect the base queries for the tabs
        // and hardcode isAssignedInCase: false since we only care about unassigned reports
        dispatch(
            unassignedReportsSearchForm.actionCreators.change({
                hasCaseStatus: unassignedReportsSearchForm.selectors.formModelByPathSelector(state)(
                    'reportDetails.hasCaseStatus'
                ),
                caseStatusAttrIds,
                excludedCaseStatusAttrIds:
                    activeTabKey === tabKeys.NEW ? excludedCaseStatusAttrIds : undefined,

                isAssignedInCase: false,
                departmentIds: [currentDepartmentIdForUserInConsortiumSelector(state)],
            })
        );
    };
}

/**
 * Make the default search for unassigned reports. This action is meant to be
 *   dispatched when the unassigned reports route is entered.
 */
export function searchInitialUnassignedReports() {
    return function (dispatch, getState) {
        const state = getState();
        dispatch(unassignedReportsSearch.actionCreators.loadAndExecuteLatestAutoSavedSearch()).then(
            (result) => {
                if (!result) {
                    const currentUserDepartmentId = [
                        currentDepartmentIdForUserInConsortiumSelector(state),
                    ];
                    const applicationSettings = applicationSettingsSelector(state);
                    const additionalDataForConvertFromFormModel = {
                        recordsWithoutRenEnabled:
                            applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED,
                    };
                    // the first enter is essentially the same as clicking the New Tab
                    // so we want to make sure we set the correct `hasCaseStatus` value
                    dispatch(setQueryFromTabClick(tabKeys.NEW));
                    return dispatch(
                        unassignedReportsSearch.actionCreators.search(
                            {
                                formData: {
                                    reportDetails: newUnassignedReportsBaseQuerySelector(state),
                                    departmentIds: currentUserDepartmentId,
                                },
                                additionalDataForConvertFromFormModel,
                            },
                            // do not auto-save the default search so that
                            // a faulty request cannot cause your latest saved-search
                            // to be overwritten by the default search
                            { cacheBust: true, autoSave: false }
                        )
                    );
                }
            }
        );
    };
}

export function submitUnassignedReportsSearchForm(
    additionalDataForConvertFromFormModel,
    sortData = {}
) {
    return (dispatch) => {
        dispatch(
            unassignedReportsSearch.form.actionCreators.submit((formData) => {
                dispatch(
                    unassignedReportsSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                            additionalDataForConvertFromFormModel,
                            ...sortData,
                        },
                        { cacheBust: true }
                    )
                );
            })
        );
    };
}

export function isCaseStatusAttributeParentIdValidForWillNotInvestigate(parentId) {
    return includes([globalAttributes.caseStatus.inactive], parentId);
}

/** @typedef {import('redux').Reducer} Reducer */

/**
 * Redux module for unassigned reports search.
 * TODO export members of this module individually
 * @type {Reducer<any, any>}
 */
export default unassignedReportsSearch.reducers.uiReducer;

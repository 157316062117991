import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { CaseTitle, ReportShortTitle } from '@mark43/rms-api';
import {
    Checkbox,
    Avatar,
    IconButton as _IconButton,
    Menu,
    MenuContent,
    MenuItem,
    MenuTrigger,
    useToast,
} from 'arc';
import { xorWith, isEqual, includes } from 'lodash';
import { TableColumn } from 'components-mark43';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import PropertyTitle from '~/client-common/core/domain/item-profiles/components/PropertyTitle';
import { FormattedDate } from '~/client-common/core/dates/components';
import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatTitleForVehicle } from '~/client-common/core/domain/vehicles/utils/vehicleHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields, { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import errorToMessage from '../../../../../core/errors/utils/errorToMessage';
import { arcIconForItemTypeAttrId } from '../../../../../core/item-profiles/utils/itemIcons';
import { CustomLink, PopoutLink } from '../../../../../core/components/links/Link';
import { getCaseTitle } from '../../../../../records/core/helpers/caseStringFormatters';
import { eFileResource } from '../../../../resources';
import { useEFileContext, useEFileDisplayName } from '../../../../hooks';
import { EFileTableBodyRow } from '../EFileTableBodyRow';
import { useMaterialItems } from '../../materials/EFileMaterialItemsProvider';
import { EFileItemGridRowT } from '../../../../types';

const strings = componentStrings.eFiles.materials.items.grid;

const Link = styled(CustomLink)`
    text-decoration: none;
`;

const IconButton = styled(_IconButton)`
    border: none;
`;

const AvatarColumn = styled(TableColumn)`
    width: 40px;
`;

const DescriptionColumn = styled(TableColumn)`
    width: 253px;
    padding-left: 10px;
    padding-right: 10px;
    word-break: break-word;
`;

const SourceColumn = styled(TableColumn)`
    width: 196px;
    padding-left: 10px;
    padding-right: 10px;
`;

const CreatedDateColumn = styled(TableColumn)`
    width: 176px;
    padding-left: 10px;
    padding-right: 10px;
`;

const ActionsTableColumn = styled(TableColumn)`
    width: 25px;
    margin-right: 3px;
`;

const CheckboxTableColumn = styled(TableColumn)`
    margin: 0 10px;
`;

const CaseLink: React.FC<{ caseTitle: CaseTitle }> = ({ caseTitle }) => {
    const { singularCaseFieldName } = useCaseFieldName();

    const title = getCaseTitle({
        localId: caseTitle.localId,
        caseDefinition: caseTitle.caseDefinitionName,
        caseFieldName: '',
    });

    return (
        <div>
            {singularCaseFieldName}:{' '}
            <PopoutLink to={`/cases/${caseTitle.caseId}`}>{title}</PopoutLink>
        </div>
    );
};

const ReportLink: React.FC<{ reportShortTitle: ReportShortTitle }> = ({ reportShortTitle }) => {
    const fieldDisplayNames = useFields([fields.REPORT_REPORTING_EVENT_NUMBER]);

    const label = reportShortTitle.reportingEventNumber ? strings.report : strings.record;
    const title = joinTruthyValues(
        [
            reportShortTitle.reportingEventNumber
                ? fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER
                : undefined,
            reportShortTitle.reportingEventNumber,
            reportShortTitle.shortTitle,
        ],
        ' '
    );

    return (
        <div>
            {label}: <PopoutLink to={`/reports/${reportShortTitle.reportId}`}>{title}</PopoutLink>
        </div>
    );
};

export const EFileMaterialItemsGridRow: React.FC<{
    itemRow: EFileItemGridRowT;
    onRemove: () => void;
}> = ({ itemRow: { itemProfile, materialId, sourceCaseTitle, sourceReportTitle }, onRemove }) => {
    const {
        getters: {
            efile: { getEFileViewModel },
        },
    } = useEFileContext();

    const { selectedMaterialIds, setSelectedMaterialIds } = useMaterialItems();

    const { eFileDisplayName } = useEFileDisplayName();
    const toast = useToast();
    const onClickRemove = React.useCallback(async () => {
        try {
            const success = await eFileResource.removeMaterials([materialId]);
            if (success) {
                onRemove();
            } else {
                throw new Error();
            }
        } catch (err) {
            toast({
                status: 'error',
                description: `${strings.removeError} ${errorToMessage(err)}`,
            });
        }
    }, [onRemove, materialId, toast]);

    const formatAttributeById = useSelector(formatAttributeByIdSelector);

    const checkBoxOnChange = useCallback(() => {
        setSelectedMaterialIds(xorWith(selectedMaterialIds, [materialId], isEqual));
    }, [setSelectedMaterialIds, selectedMaterialIds, materialId]);

    return (
        <EFileTableBodyRow>
            <CheckboxTableColumn>
                <Checkbox
                    isDisabled={!getEFileViewModel()?.canEdit}
                    onChange={checkBoxOnChange}
                    isChecked={includes(selectedMaterialIds, materialId)}
                />
            </CheckboxTableColumn>
            <AvatarColumn>
                {/* RMS-17994 set `src` to the optional thumbnail after it's hydrated in EFileItemSearchView */}
                <Avatar icon={arcIconForItemTypeAttrId(itemProfile.itemTypeAttrId)} size="md" />
            </AvatarColumn>
            <DescriptionColumn>
                {/* RMS-16384 item page */}
                <Link>
                    {
                        // RMS-17994 remove this ternary after Vehicles get hydrated into EFileItemSearchView
                        itemProfile.itemTypeAttrId === globalAttributes.itemType.vehicle ? (
                            // @ts-expect-error client-common to client RND-7529
                            formatTitleForVehicle({
                                description: itemProfile.description,
                                itemCategory: formatAttributeById(itemProfile.itemCategoryAttrId),
                                vehicleMake: itemProfile.itemMake,
                                vehicleModel: itemProfile.itemModel,
                            })
                        ) : (
                            <PropertyTitle itemProfile={itemProfile} />
                        )
                    }
                </Link>
            </DescriptionColumn>
            <SourceColumn>
                {sourceCaseTitle ? <CaseLink caseTitle={sourceCaseTitle} /> : undefined}
                {sourceReportTitle ? (
                    <ReportLink reportShortTitle={sourceReportTitle} />
                ) : undefined}
            </SourceColumn>
            <CreatedDateColumn>
                <FormattedDate
                    date={itemProfile.createdDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                />
                <ConnectedFormattedUser
                    userId={itemProfile.createdBy}
                    format={FORMATS.FULL_NAME_WITH_FIRST_INITIAL}
                >
                    {(user) => <>by {user}</>}
                </ConnectedFormattedUser>
            </CreatedDateColumn>
            <ActionsTableColumn>
                <Menu>
                    <MenuTrigger asChild>
                        <IconButton icon="MoreActions" />
                    </MenuTrigger>
                    <MenuContent align="end">
                        <MenuItem onClick={onClickRemove}>
                            {strings.remove(eFileDisplayName)}
                        </MenuItem>
                    </MenuContent>
                </Menu>
            </ActionsTableColumn>
        </EFileTableBodyRow>
    );
};

import React from 'react';
import { Map, MapPropsT } from '~/client-common/core/maps';
import MapContainer from './MapContainer';

export default function EsriSimpleMapWrapper({
    className,
    styles,
    ...props
}: MapPropsT & { className?: string; styles?: React.CSSProperties }) {
    return (
        <MapContainer className={className} style={styles}>
            <Map {...props} />
        </MapContainer>
    );
}

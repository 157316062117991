import { OffenseCaseStatus } from '@mark43/rms-api';
import { createField, InferFormDataShape, createNItems } from 'markformythree';
import { pick, keys, map, keyBy } from 'lodash';

import {
    EVENT_DETAIL_EVENT_START_UTC,
    OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
    OFFENSE_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
    OFFENSE_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID,
    OFFENSE_CASE_STATUS_STATUS_DATE_UTC,
} from '~/client-common/core/enums/universal/fields';
import { getEventDatesFromEventForm } from '../../../../reports/core/state/forms/eventInfoForm';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { RmsAction } from '../../../../../core/typings/redux';

export const offenseCaseStatusesConfiguration = createNItems({
    fieldName: OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
    fields: {
        // These fields are not used for the form -- they are
        // just used in the `convertToFormModel` method
        id: createField<number>({}),
        offenseId: createField<number>({}),
        title: createField<string>({}),
        reportId: createField<number>({}),
        caseStatusAttrId: createField<number>({
            fieldName: OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
        }),
        closedByDivisionAttrId: createField<number>({
            fieldName: OFFENSE_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
        }),
        closedByUnitAttrId: createField<number>({
            fieldName: OFFENSE_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID,
        }),
        statusDateUtc: createField<string>({
            fieldName: OFFENSE_CASE_STATUS_STATUS_DATE_UTC,
        }),
        eventStartUtc: createField<string>({
            fieldName: EVENT_DETAIL_EVENT_START_UTC,
        }),
    },
});

export const convertToOffenseCaseStatusesFormModel = (
    offenseCaseStatuses: OffenseCaseStatus[]
): RmsAction<(Partial<OffenseCaseStatus> & { eventStartUtc?: string })[]> => (
    dispatch,
    getState
) => {
    const currentReportId = currentReportIdSelector(getState());
    const eventDates = dispatch(getEventDatesFromEventForm(currentReportId));
    const eventStartUtc = eventDates.eventStartUtc;
    return map(offenseCaseStatuses, (offenseCaseStatus) => {
        return {
            ...pick(offenseCaseStatus, keys(offenseCaseStatusesConfiguration.fields)),
            eventStartUtc,
        };
    });
};

export const convertFromOffenseCaseStatusesFormModel = (
    formOffenseCaseStatuses: InferFormDataShape<typeof offenseCaseStatusesConfiguration>,
    dataOffenseCaseStatuses?: OffenseCaseStatus[]
) => {
    const offenseIdToOffenseCaseStatus = keyBy(dataOffenseCaseStatuses, 'offenseId');

    return map(formOffenseCaseStatuses, (formOffenseCaseStatus) => {
        if (formOffenseCaseStatus.offenseId !== undefined) {
            return {
                ...offenseIdToOffenseCaseStatus[formOffenseCaseStatus.offenseId],
                ...pick(formOffenseCaseStatus, keys(offenseCaseStatusesConfiguration.fields)),
            };
        } else {
            return {
                ...pick(formOffenseCaseStatus, keys(offenseCaseStatusesConfiguration.fields)),
            };
        }
    });
};

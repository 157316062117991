import { useRef } from 'react';

/**
 * Custom memoization function that allows comparison via a function and returns a stable value.
 * This is preferrable to other memoization functions, e.g. from lodash, as it will go out of scope when a component unmounts.
 */
export function useMemoEquals<T>(next: T, equals: (current: T | undefined, next: T) => boolean): T {
    const valueRef = useRef<T>(next);
    if (!equals(valueRef.current, next)) {
        valueRef.current = next;
    }

    return valueRef.current;
}

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_PATH = 'cases/definitions';

export default createResource({
    name: 'Case Definition Resource',
    methods: {
        getCaseDefinitions() {
            return req({
                method: 'GET',
                url: BASE_PATH,
            });
        },
        createCaseDefinition(caseDefinition) {
            return req({
                method: 'POST',
                url: BASE_PATH,
                data: caseDefinition,
            });
        },
        updateCaseDefinition(caseDefinition) {
            return req({
                method: 'PUT',
                url: `${BASE_PATH}/${caseDefinition.id}`,
                data: caseDefinition,
            });
        },
        deleteCaseDefinition(caseDefinitionId) {
            return req({
                method: 'DELETE',
                url: `cases/definitions/${caseDefinitionId}`,
            });
        },
        setCaseDefaultRoleLinks(caseDefinitionId, caseDefaultRoleLinks) {
            return req({
                method: 'PUT',
                url: `${BASE_PATH}/${caseDefinitionId}/links/roles`,
                data: caseDefaultRoleLinks,
            });
        },
        getTasks(ownerId) {
            return req({
                method: 'GET',
                url: `${BASE_PATH}/${ownerId}/tasks`,
            });
        },
        createTask(ownerId, task) {
            return req({
                method: 'POST',
                url: `${BASE_PATH}/${ownerId}/tasks`,
                data: task,
            });
        },
        updateTask(ownerId, taskId, task) {
            return req({
                method: 'PUT',
                url: `${BASE_PATH}/${ownerId}/tasks/${taskId}`,
                data: task,
            });
        },
        upsertTasks(ownerId, tasks) {
            return req({
                method: 'PUT',
                url: `${BASE_PATH}/${ownerId}/tasks`,
                data: tasks,
            });
        },
        getDefaultFolders(ownerType, ownerId) {
            return req({
                method: 'GET',
                url: `default_folders/${ownerType}/${ownerId}`,
            });
        },
        replaceDefaultFolders(ownerType, ownerId, defaultFolders) {
            return req({
                method: 'PUT',
                url: `default_folders/${ownerType}/${ownerId}`,
                data: defaultFolders,
            });
        },
    },
});

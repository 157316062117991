import { EntityTypeEnum } from '@mark43/rms-api';
import { flatMap, map } from 'lodash';
import { convertLocationBundlesToLocationViews } from '~/client-common/core/domain/locations/utils/locationHelpers';
import { augmentActionAndStoreRmsHydratedPersonProfiles } from '~/client-common/core/domain/person-profiles/state/data';
import getFieldNoteResource from '~/client-common/core/domain/field-notes/resources/fieldNoteResource';
import { NEXUS_STATE_PROP as FIELD_NOTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/field-notes/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINK_NEXUS_STATE_PROP } from '~/client-common/core/domain/location-entity-links/state/data';
import { NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/locations/state/data';
import { NEXUS_STATE_PROP as FIELD_NOTE_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/field-note-entity-links/state/data';

export const getHydratedFieldNotesForCurrentUser = ({ entityType, overlayId }) => (
    dispatch,
    getState,
    { overlayStore, nexus }
) => {
    const fieldNotesResource = getFieldNoteResource();
    const config =
        entityType === EntityTypeEnum.PERSON_PROFILE.name
            ? {
                  includePersons: true,
              }
            : {
                  includeLocations: true,
              };
    overlayStore.setLoading(overlayId, true);
    fieldNotesResource
        .getHydratedFieldNotesForCurrentUser(config)
        .then((hydratedFieldNotes) => {
            const locationBundles = flatMap(hydratedFieldNotes, 'locations');
            const locationEntityLinks = flatMap(locationBundles, 'entityLinks');
            const locations = convertLocationBundlesToLocationViews(locationBundles);
            const persons = flatMap(hydratedFieldNotes, 'persons');
            const fieldNotes = map(hydratedFieldNotes, 'fieldNote');
            const fieldNoteEntityLinks = flatMap(hydratedFieldNotes, 'fieldNoteEntityLinks');
            dispatch(
                augmentActionAndStoreRmsHydratedPersonProfiles(
                    { type: 'STORE_FIELD_NOTE_PERSON_PROFILES' },
                    persons
                )
            );
            dispatch(
                nexus.withEntityItems(
                    {
                        [FIELD_NOTES_NEXUS_STATE_PROP]: fieldNotes,
                        [LOCATION_ENTITY_LINK_NEXUS_STATE_PROP]: locationEntityLinks,
                        [LOCATIONS_NEXUS_STATE_PROP]: locations,
                        [FIELD_NOTE_ENTITY_LINKS_NEXUS_STATE_PROP]: fieldNoteEntityLinks,
                    },
                    { type: 'STORE_HYDRATED_FIELD_NOTES' }
                )
            );
            overlayStore.setLoading(overlayId, false);
            return hydratedFieldNotes;
        })
        .catch((err) => overlayStore.setError(overlayId, err.message));
};

import React from 'react';
import { connect } from 'react-redux';
import { reduce, compact, map, keys, debounce, mapValues, sortBy } from 'lodash';
import styled from 'styled-components';
import { compose, withPropsOnChange } from 'recompose';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../core/testIds';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import { exportAttachmentsSelector, loadAttachments } from '../state/data';
import { groupAttachmentsByEntityIdSelector } from '../state/ui';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { filterAttachmentsByFilename } from '../utils/filterAttachmentsByFilename';
import { NoResults } from '../../../core/components/NoResults';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { computeSelectedAttachments } from '../utils/computeSelectedAttachments';
import { FilterInput } from './FilterInput';
import { AttachmentSection } from './AttachmentSection';

const strings = componentStrings.core.ExportAttachmentsSidePanel;

const ScreenContainer = styled.div`
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;
`;

const ExportAttachmentsSidePanelBase = compose(
    connect(
        (state, ownProps) => ({
            attachments: exportAttachmentsSelector(state)(ownProps.excludeAttachmentLinkTypes),
            groupAttachmentsByEntityId: groupAttachmentsByEntityIdSelector(state),
            applicationSettings: applicationSettingsSelector(state),
        }),
        {
            loadAttachments,
        }
    ),
    withPropsOnChange(
        ['groupAttachmentsByEntityId', 'attachments', 'entities'],
        ({ groupAttachmentsByEntityId, attachments, entities }) => {
            return {
                attachmentsByEntity: groupAttachmentsByEntityId({
                    attachments,
                    entities,
                }),
                entityDisplayValuesById: reduce(
                    entities,
                    (acc, entity) => {
                        acc[entity.entityId] = entity.display;
                        if (entity.rawPacket?.entityId) {
                            acc[entity.rawPacket.entityId] = entity.rawPacket.title;
                        }
                        return acc;
                    },
                    {}
                ),
            };
        }
    )
)(
    class ExportAttachmentsSidePanelBase extends React.PureComponent {
        state = {
            selectedAttachments: this.props.initialSelectedAttachments || {},
            openSections: {},
            filter: '',
            panelHasOpened: false,
        };

        constructor(...args) {
            super(...args);
            this.handleFilterChange = debounce(this.handleFilterChange, 300);
        }

        componentDidMount() {
            this.loadAttachments();
        }

        loadAttachments = () =>
            this.props.loadAttachments({
                entities: this.props.entities,
                excludeAttachmentLinkTypes: this.props.excludeAttachmentLinkTypes,
                overlayId: this.props.overlayId,
                onSuccess: this.handleAttachmentsLoadSuccess,
            });

        handleFilterChange = (value) => {
            this.setState({
                filter: value,
            });
        };

        handlePanelOnRest = () => this.setState({ panelHasOpened: true });

        /**
         * Creates initial selection states for loaded attachments
         * that match the entities provided via props. Omits
         * any entity which is already has a selection state defined in `props.initialSelectedAttachments`.
         *
         * @param {Object[]} [attachments] The loaded attachments
         */
        handleAttachmentsLoadSuccess = (attachments) => {
            // this is a bit gross but seems to be the easiest way for us to get a proper
            // initial selection state.
            // A better solution would be to lift state up into the parent component,
            // but with the current exports page that is rather infeasible
            if (attachments) {
                const { initialSelectedAttachments } = this.props;
                const attachmentsByEntity = this.props.groupAttachmentsByEntityId({
                    entities: this.props.entities,
                    attachments,
                });
                this.setState({
                    selectedAttachments: computeSelectedAttachments({
                        attachmentsByEntity,
                        initialSelectedAttachments,
                    }),
                    openSections: keys(attachmentsByEntity).reduce((acc, entityId) => {
                        acc[entityId] = true;
                        return acc;
                    }, {}),
                });
            }
        };

        handleSectionToggleClick = (sectionKey, isOpen) =>
            this.setState({ openSections: { ...this.state.openSections, [sectionKey]: isOpen } });

        /**
         * Adds or removes an attachment from the selected attachment set of an entity
         *
         * @param {object} attachment The attachment that was toggled
         * @param {boolean} isSelected Whether or not the attachment is selected
         */
        handleAttachmentToggle = (attachment, isSelected) => {
            let attachmentIdsForEntity = (
                this.state.selectedAttachments[attachment.entityId] || []
            ).slice();

            if (isSelected) {
                attachmentIdsForEntity.push(attachment.attachmentId);
            } else {
                attachmentIdsForEntity = attachmentIdsForEntity.filter(
                    (id) => id !== attachment.attachmentId
                );
            }

            this.setState({
                selectedAttachments: {
                    ...this.state.selectedAttachments,
                    [attachment.entityId]: attachmentIdsForEntity,
                },
            });
        };

        /**
         * Marks all attachments for a given entity id as selected or de-selected
         *
         * @param {string|number} entityId The entity id to select/de-select all entities for
         * @param {boolean} selectAll Whether or not to select all entities
         */
        handeToggleAllAttachmentsClick = (entityId, selectAll) => {
            this.setState({
                selectedAttachments: {
                    ...this.state.selectedAttachments,
                    [entityId]: selectAll
                        ? map(
                              this.props.attachmentsByEntity[entityId],
                              (attachment) => attachment.attachmentId
                          )
                        : [],
                },
            });
        };

        handleSavePanel = () => {
            this.props.onPanelSave(this.state.selectedAttachments);
            this.props.savePanel();
        };

        render() {
            const { isLoading, errors } = this.props.overlayBase.overlayState;
            const hasErrors = !!errors.length;

            const sections =
                !isLoading &&
                !hasErrors &&
                compact(
                    map(
                        sortBy(
                            mapValues(this.props.attachmentsByEntity, (attachments, entityId) => ({
                                attachments,
                                entityId,
                            })),
                            ({ entityId }) => this.props.entityDisplayValuesById[entityId]
                        ),
                        ({ attachments, entityId }) => {
                            if (!attachments.length) {
                                return null;
                            } else if (this.state.filter) {
                                // we can memoize this if it becomes a performance issue (unlikely)
                                attachments = filterAttachmentsByFilename(
                                    this.state.filter,
                                    attachments
                                );
                                if (!attachments.length) {
                                    return null;
                                }
                            }
                            return (
                                <AttachmentSection
                                    isOpen={this.state.openSections[entityId]}
                                    onSectionToggleClick={this.handleSectionToggleClick}
                                    entityId={entityId}
                                    key={entityId}
                                    entityDisplayValuesById={this.props.entityDisplayValuesById}
                                    filteredAttachments={attachments}
                                    allAttachments={attachments}
                                    selectedAttachments={this.state.selectedAttachments[entityId]}
                                    onAttachmentToggle={this.handleAttachmentToggle}
                                    onToggleAllAttachmentsClick={
                                        this.handeToggleAllAttachmentsClick
                                    }
                                    isFiltered={!!this.state.filter}
                                />
                            );
                        }
                    )
                );

            return (
                <PortalSidePanel
                    noPadding={true}
                    savePanel={this.handleSavePanel}
                    closePanel={this.props.closePanel}
                    title={strings.title}
                    testId={testIds.EXPORTS_ATTACHMENTS_SIDE_PANEL}
                    isAtBottomOfStack={this.props.overlayBase.isAtBottomOfStack}
                    onRest={this.handlePanelOnRest}
                >
                    <ScreenContainer>
                        {/* Using `panelHasOpened` to defer content rendering to prevent sluggish animation */}
                        {(!this.state.panelHasOpened || isLoading) && <SimpleLoading />}
                        {this.state.panelHasOpened && !isLoading && (
                            <>
                                {!hasErrors && (
                                    <FilterInput
                                        onChange={this.handleFilterChange}
                                        testId={testIds.EXPORTS_ATTACHMENTS_SIDE_PANEL_FILTER_INPUT}
                                    />
                                )}
                                {hasErrors && (
                                    <>
                                        <InlineBanner status="error">
                                            {map(errors, (error) => (
                                                <span key={error}>{error}</span>
                                            ))}
                                        </InlineBanner>
                                        <Button
                                            className={buttonTypes.PRIMARY}
                                            onClick={this.loadAttachments}
                                        >
                                            {strings.retry}
                                        </Button>
                                    </>
                                )}
                                {sections}
                                {!sections.length && !hasErrors && (
                                    <NoResults marginTop="0">
                                        {strings.noAttachmentsFound}
                                    </NoResults>
                                )}
                            </>
                        )}
                    </ScreenContainer>
                </PortalSidePanel>
            );
        }
    }
);

export const ExportAttachmentsSidePanel = ({ id, renderButton, ...props }) => (
    <OverlayBaseHelper id={id} renderButton={renderButton}>
        {(renderProps) => (
            <ExportAttachmentsSidePanelBase overlayId={id} {...props} {...renderProps} />
        )}
    </OverlayBaseHelper>
);

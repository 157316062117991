import { EntityTypeEnum } from '@mark43/rms-api';
import { filter, map, remove } from 'lodash';
import Promise from 'bluebird';

import { storeAttachments } from '~/client-common/core/domain/attachments/state/data';
import getAttachmentsResource from '~/client-common/core/domain/attachments/resources/attachmentsResource';

const POLL_ATTACHMENTS_MAX_ATTEMPTS = 5;
const POLL_MS = 2000;

const POLL_IMPORT_EVENT_FAILURE = 'attachment-polling/ATTACHMENT_POLL_FAILURE';

function pollImportEventFailure(errorMessage) {
    return {
        type: POLL_IMPORT_EVENT_FAILURE,
        payload: errorMessage,
    };
}

export function checkForAttachmentThumbnails(attachments) {
    return (dispatch) => {
        const attachmentsForPolling = filterAttachmentsForPolling(attachments);
        if (attachmentsForPolling.length > 0) {
            dispatch(pollAttachmentUploads(map(attachmentsForPolling, 'attachmentId')));
        }
    };
}

// This only accepts attachmentIds that have attachments of type IMAGE
// it will not poll for videos
function pollAttachmentUploads(attachmentIds, pollCount = 1) {
    const attachmentsResource = getAttachmentsResource();
    return (dispatch) => {
        if (pollCount >= POLL_ATTACHMENTS_MAX_ATTEMPTS) {
            return;
        }
        return Promise.delay(POLL_MS)
            .then(() =>
                attachmentsResource.getAttachmentsForAttachmentIds(attachmentIds, {
                    hideLoadingBar: true,
                })
            )
            .then((attachments) => {
                const removedAttachments = remove(attachments, (attachment) => {
                    return imageHasCompletedProcessing(attachment.image);
                });
                dispatch(storeAttachments(removedAttachments));
                if (attachments.length === 0) {
                    return;
                } else {
                    return dispatch(
                        pollAttachmentUploads(map(attachments, 'attachmentId'), pollCount + 1)
                    );
                }
            })
            .catch((err) => {
                dispatch(pollImportEventFailure(err.message));
            });
    };
}

// large file is used as that is the only one generated on PDFs
function imageHasCompletedProcessing(image) {
    return image && image.thumbnailLargeFileId;
}

// returns a new array containing the attachments that are of type IMAGE
// and have not had processing completed already
// attachments with the entity type FILE will not have an image field
function filterAttachmentsForPolling(attachments) {
    return filter(attachments, (attachment) => {
        return (
            attachment.attachmentType === EntityTypeEnum.IMAGE.name &&
            !imageHasCompletedProcessing(attachment.image)
        );
    });
}

import factory from './actionTypesFactory';

export default factory('attributesadmin', [
    'PAGE_LOAD_START',
    'PAGE_LOAD_SUCCESS',
    'PAGE_LOAD_ERROR',
    'SELECT_ATTRIBUTE_TYPE_START',
    'SELECT_ATTRIBUTE_TYPE_SUCCESS',
    'SELECT_ATTRIBUTE_TYPE_FAILURE',
    'SELECT_ATTRIBUTE_TYPE_OPTION_START',
    'SELECT_ATTRIBUTE_TYPE_OPTION_SUCCESS',
    'SELECT_ATTRIBUTE_TYPE_OPTION_FAILURE',
    'SELECT_ATTRIBUTE_START',
    'SELECT_ATTRIBUTE_SUCCESS',
    'SELECT_ATTRIBUTE_FAILURE',
    'OPEN_NEW_ATTRIBUTE_FORM',
    'SAVE_ATTRIBUTE_START',
    'SAVE_ATTRIBUTE_SUCCESS',
    'SAVE_ATTRIBUTE_FAILURE',
    'FETCH_ATTRIBUTE_TYPE_HISTORY_START',
    'FETCH_ATTRIBUTE_TYPE_HISTORY_SUCCESS',
    'FETCH_ATTRIBUTE_TYPE_HISTORY_FAILURE',
    'TOGGLE_EXPIRED_ATTRIBUTES',
    'SAVE_ATTRIBUTE_TYPE_LINK_TYPES',
]);

import { useCallback, useState } from 'react';

const useUpdateFormNotMatchingErrorsState = (supportsErrors: boolean, resetErrors: () => void) => {
    const [isFormMatchingErrorsGenerated, setIsFormMatchingErrorsGenerated] = useState(false);
    const updateFormNotMatchingErrorsState = useCallback(() => {
        if (!isFormMatchingErrorsGenerated || !supportsErrors) {
            return;
        }

        resetErrors();
        setIsFormMatchingErrorsGenerated(false);
    }, [
        isFormMatchingErrorsGenerated,
        resetErrors,
        setIsFormMatchingErrorsGenerated,
        supportsErrors,
    ]);

    return {
        updateFormNotMatchingErrorsState,
        isFormMatchingErrorsGenerated,
        setIsFormMatchingErrorsGenerated,
    };
};

export default useUpdateFormNotMatchingErrorsState;

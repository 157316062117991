import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { createFormConfiguration, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import Row from '../../../core/components/Row';
import { ArbiterMFTTextArea } from '../../../core/forms/components/TextArea';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';

const WillNotInvestigateCommentForm = () => {
    return (
        <ArbiterForm
            name={RefContextEnum.FORM_WILL_NOT_INVESTIGATE.name}
            context={RefContextEnum.FORM_WILL_NOT_INVESTIGATE.name}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            configuration={createFormConfiguration({
                comments: {
                    fieldName: fields.REPORT_COMMENT_COMMENT,
                },
            })}
            render={() => (
                <Row>
                    <ArbiterMFTTextArea path="comments" rows={5} resizable={false} />
                </Row>
            )}
        />
    );
};

export default WillNotInvestigateCommentForm;

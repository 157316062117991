import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { timeZoneOptionsSelector } from '~/client-common/core/constants/state/constants';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../../core/testIds';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const timeZoneStrings = componentStrings.forms.select.TimeZoneSelect;

/**
 * Select dropdown for time zones.
 * @param {Object} props
 * @param {string} [props.label] Set this prop to use a custom label different
 *   from the default label.
 */
const TimeZoneSelect = compose(
    setDisplayName('TimeZoneSelect'),
    connect(
        createStructuredSelector({
            timeZoneOptions: timeZoneOptionsSelector,
        })
    )
)(function TimeZoneSelect({ timeZoneOptions, ...otherProps }) {
    return (
        <Select
            label={timeZoneStrings.label}
            options={timeZoneOptions}
            testId={testIds.TIMEZONE_SELECT}
            {...otherProps}
        />
    );
});

export default TimeZoneSelect;
export const RRFTimeZoneSelect = connectRRFInput(TimeZoneSelect);

import React from 'react';
import { Button } from '../../../core/components/Button';

const ViewQueriesButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <Button variant="ghost" leadingVisual="History" onClick={onClick}>
            View Queries
        </Button>
    );
};

export default ViewQueriesButton;

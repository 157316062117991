import { UsageSourceModuleEnum } from '@mark43/rms-api';

import keyMirror from 'keymirror';
import { getContextedOrMasterOrganizationProfile } from '~/client-common/core/domain/organization-profiles/state/data';

import {
    refreshOrganizationProfileForm,
    createOrganizationProfileFormInitialAddState,
} from '../forms/organizationProfileForm';

export const fetchAndBuildOrganizationProfileFormModel = (params) => (
    dispatch,
    getState,
    { sentry }
) => {
    const {
        organizationProfileId,
        ownerType,
        ownerId,
        onSuccess,
        isEditingExistingOrganization,
        linkType,
    } = params;

    let promiseToReturn = Promise.resolve({ idToPrefillWith: null });

    const useDataInStore = !organizationProfileId || isEditingExistingOrganization;
    if (useDataInStore) {
        promiseToReturn = Promise.resolve({ idToPrefillWith: organizationProfileId });
    } else {
        promiseToReturn = dispatch(
            getContextedOrMasterOrganizationProfile({
                masterOrContextedProfileId: organizationProfileId,
                ownerType,
                ownerId,
            })
        ).then((hydratedOrganization) => ({
            idToPrefillWith: hydratedOrganization.organizationProfile.id,
        }));
    }

    return promiseToReturn
        .catch((err) => {
            sentry.withScope((scope) => {
                scope.setTag('module', UsageSourceModuleEnum.ENTITY_PROFILES.name);
                scope.setExtra('params', params);
                sentry.captureException(err);
            });
            return {
                idToPrefillWith: organizationProfileId,
            };
        })
        .then(({ idToPrefillWith }) => {
            const formModel = idToPrefillWith
                ? dispatch(
                      refreshOrganizationProfileForm({
                          organizationProfileId: idToPrefillWith,
                          linkType,
                      })
                  )
                : dispatch(createOrganizationProfileFormInitialAddState({ linkType }));
            if (onSuccess) {
                onSuccess(formModel);
            }
            return formModel;
        });
};

export const ORGANIZATION_SIDE_PANEL_SCREENS = keyMirror({
    // Go to the search to add screen
    SEARCH_FORM: null,
    // Go to the search results screen
    SEARCH_RESULTS: null,
    // Go to the edit profile screen for an existing profile
    PROFILE_EDIT: null,
    // Go to the edit profile for adding a new profile
    PROFILE_ADD_NEW: null,
});

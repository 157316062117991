import { AttributeTypeEnum, CodeTypeCategoryEnum } from '@mark43/rms-api';
import { flowRight, reject } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Observer } from 'markformythree';
import { Flex } from 'arc';

import { propertyLossCodes } from '~/client-common/core/constants/nibrsCodes';
import { PROPERTY_STATUS_PROPERTY_STATUS_ATTR_ID } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import Divider from '../../../../../core/components/Divider';
import { MFTNItems, RemoveButton } from '../../../../../core/forms/components/NItems';
import FormRow from '../../../../../core/forms/components/FormRow';
import FormGroup from '../../../../../core/forms/components/FormGroup';
import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTCheckbox } from '../../../../../core/forms/components/checkboxes/Checkbox';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import {
    MFTAutofillDateButton,
    autofillDateTypeEnum,
} from '../../../../../core/components/AutofillDateButton';

import testIds from '../../../../../../core/testIds';
import { nibrsCodeForAttributeIdAndCodeTypeCategorySelector } from '../../../../ucr-classification/state/ui/selectors';

const strings = componentStrings.reports.core.PropertyStatusesFieldset;

const RemoveButtonElement = styled(RemoveButton)`
    margin-top: var(--arc-space-6);
`;

function PropertyStatusesFieldset({
    form,
    helpTextCollisionBoundary,
    isVehicle,
    fieldDisplayNames,
    filterOutSeized = false,
    nibrsCodeForAttributeIdAndCodeTypeCategory,
}) {
    const title = isVehicle ? strings.vehicleSectionTitle : strings.propertySectionTitle;
    const filterOptions = filterOutSeized
        ? (opts) =>
              reject(
                  opts,
                  (o) =>
                      nibrsCodeForAttributeIdAndCodeTypeCategory(
                          o.value,
                          CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name
                      ) === propertyLossCodes.seized
              )
        : null;

    return (
        <SidePanelSection title={title} testId={testIds.ITEM_SIDE_PANEL_PROPERTY_STATUSES_SECTION}>
            <MFTNItems
                path="propertyStatuses"
                renderRemoveButton={undefined}
                addText={fieldDisplayNames.PROPERTY_STATUS_PROPERTY_STATUS_ATTR_ID}
                childFieldKeys={[
                    'id',
                    'propertyStatusAttrId',
                    'marijuanaTypeAttrId',
                    'marijuanaQuantity',
                    'vehicleRecoveryTypeAttrId',
                    'statusDateUtc',
                    'declaredValue',
                    'declaredValueUnknown',
                    'forfeitureValue',
                ]}
                render={({ index, items, removeItem }) => {
                    return (
                        <>
                            <FormGroup>
                                <Flex justifyContent="space-between">
                                    <ArbiterMFTAttributeSelect
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        path="propertyStatusAttrId"
                                        attributeType={AttributeTypeEnum.PROPERTY_LOSS.name}
                                        length="lg"
                                        filterOptions={filterOptions}
                                    />
                                    {(index > 0 || items.length > 1) && (
                                        <RemoveButtonElement onClick={removeItem} />
                                    )}
                                </Flex>
                                <ArbiterMFTAttributeSelect
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="vehicleRecoveryTypeAttrId"
                                    attributeType={AttributeTypeEnum.VEHICLE_RECOVERY_TYPE.name}
                                    length="lg"
                                />
                                <Flex>
                                    <ArbiterMFTDatePicker
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        path="statusDateUtc"
                                    />
                                    <MFTAutofillDateButton
                                        onClick={(_e, { eventStartUtc }) => {
                                            const path = `propertyStatuses[${index}].statusDateUtc`;
                                            form.set(path, eventStartUtc);
                                            form.resetUi(path);
                                        }}
                                        alwaysRender={true}
                                        autofillDateType={autofillDateTypeEnum.START_DATE}
                                        startDatePath="statusDateUtc"
                                    />
                                </Flex>
                                <FormRow>
                                    <Observer
                                        pathIsRelative={true}
                                        subscriptions={{
                                            declaredValueUnknown: 'declaredValueUnknown',
                                        }}
                                        render={({ declaredValueUnknown }) => (
                                            <ArbiterMFTText
                                                helpTextCollisionBoundary={
                                                    helpTextCollisionBoundary
                                                }
                                                path="declaredValue"
                                                disabled={declaredValueUnknown}
                                                length="md"
                                                onChange={(value) => {
                                                    form.set(
                                                        `propertyStatuses[${index}].declaredValue`,
                                                        value
                                                    );
                                                    if (!!value) {
                                                        form.set(
                                                            `propertyStatuses[${index}].declaredValueUnknown`,
                                                            false
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <ArbiterMFTText
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        path="forfeitureValue"
                                        length="md"
                                    />
                                </FormRow>
                                <ArbiterMFTCheckbox
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="declaredValueUnknown"
                                    onChange={(value) => {
                                        form.set(
                                            `propertyStatuses[${index}].declaredValueUnknown`,
                                            value
                                        );
                                        if (value) {
                                            // clear Declared Value when Unknown is checked
                                            form.resetModel(
                                                `propertyStatuses[${index}].declaredValue`,
                                                undefined
                                            );
                                            form.resetUi(
                                                `propertyStatuses[${index}].declaredValue`
                                            );
                                        }
                                    }}
                                />
                                <ArbiterMFTAttributeSelect
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="marijuanaTypeAttrId"
                                    attributeType={AttributeTypeEnum.TYPE_OF_MARIJUANA.name}
                                    length="lg"
                                />
                                <ArbiterMFTText
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="marijuanaQuantity"
                                    length="lg"
                                />
                            </FormGroup>
                            <Divider />
                        </>
                    );
                }}
            />
        </SidePanelSection>
    );
}

const mapStateToProps = createStructuredSelector({
    nibrsCodeForAttributeIdAndCodeTypeCategory: nibrsCodeForAttributeIdAndCodeTypeCategorySelector,
});

/**
 * Fieldset in the item entry v2 form. Only some PropertyStatus fields are included; other
 *   PropertyStatus fields exist in other fieldsets because they should have the same value across
 *   all propertyStatuses linked to the itemProfile.
 */
export default flowRight(
    withFields([PROPERTY_STATUS_PROPERTY_STATUS_ATTR_ID]),
    connect(mapStateToProps)
)(PropertyStatusesFieldset);

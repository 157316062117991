import { get } from 'lodash';
import { createDepartmentProfileToOptionMapper } from '../../../../../helpers/departmentProfilesHelper';

export const departmentProfileIsInConsortiumTransientKey = 'isInConsortium';

export const mapDepartmentProfileToOptionWithDisplayNameSubDomainAndDepartmentStatus = createDepartmentProfileToOptionMapper(
    (departmentProfile) =>
        [
            get(departmentProfile, 'displayName'),
            `(${get(departmentProfile, 'department.subDomain')})`,
            `(${get(departmentProfile, 'department.departmentStatus')})`,
        ].join(' ')
);

import { filter, map, ListIterateeCustom } from 'lodash';
import { createSelector } from 'reselect';
import { NameAttribute } from '@mark43/rms-api';
import { nameAttributesSelector } from '../data';
import { buildViewModel, ViewModel } from '../../../../../helpers/viewModelHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';

type NameAttributeViewModelProperties = {
    displayFormats: {
        description: string;
        descriptionWithParent: string;
        attributeWithoutDescription: string;
    };
};

export type NameAttributeViewModel = ViewModel<NameAttribute, NameAttributeViewModelProperties>;

const nameAttributesViewModelSelector = createSelector(
    nameAttributesSelector,
    formatAttributeByIdSelector,
    (nameAttributes, formatAttributeById) => {
        const makeViewModel = buildViewModel<NameAttribute, NameAttributeViewModelProperties>({
            recursive: true,
            mappers: [
                ({ attributeId, description }) => {
                    return {
                        displayFormats: {
                            description: joinTruthyValues(
                                [formatAttributeById(attributeId), description],
                                ': '
                            ),
                            descriptionWithParent: joinTruthyValues(
                                [
                                    formatAttributeById(
                                        attributeId,
                                        true /* sort */,
                                        true /* include parent */
                                    ),
                                    description,
                                ],
                                ': '
                            ),
                            attributeWithoutDescription: formatAttributeById(attributeId),
                        },
                    };
                },
            ],
        });
        return map(nameAttributes, makeViewModel);
    }
);

export const nameAttributeViewModelsWhereSelector = createSelector(
    nameAttributesViewModelSelector,
    (nameAttributeViewModels) => (predicate: ListIterateeCustom<NameAttributeViewModel, boolean>) =>
        filter<NameAttributeViewModel>(nameAttributeViewModels, predicate)
);

import { filter, reject, first, find, map, compact, some } from 'lodash';
import { createSelector } from 'reselect';
import { FileStatusEnum } from '@mark43/rms-api';
import getAttachmentsResource from '~/client-common/core/domain/attachments/resources/attachmentsResource';
import {
    storeAttachments,
    deleteAttachment,
    attachmentsWhereSelector,
} from '~/client-common/core/domain/attachments/state/data';
import { convertFileUploadResponsesToAttachmentLinks } from '~/client-common/core/domain/attachments/state/ui';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { openBox, saveBoxFailure, closeBox } from '../../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../../core/box/state/ui';
import { pollingFileSecurityScannerEnd } from './securityScanning';

const modalStrings = componentStrings.reports.core.AttachmentDeletionModal;
const confirmationModalContext = { name: boxEnum.CONFIRMATION_MODAL };

const UPDATE_UPLOADING_FILES = 'inlineAttachments/UPDATE_UPLOADING_FILES';
const ADD_UPLOADING_FILES = 'inlineAttachments/ADD_UPLOADING_FILES';
const UPDATE_UPLOADS = 'inlineAttachments/UPDATE_UPLOADS';
const UPLOAD_FILE_SUCCESS = 'inlineAttachments/UPLOAD_FILE_SUCCESS';
const UPLOAD_FILE_START = 'inlineAttachments/UPLOAD_FILE_START';
const UPLOAD_FILE_FAILURE = 'inlineAttachments/UPLOAD_FILE_FAILURE';
const SAVE_ATTACHMENTS_START = 'inlineAttachments/SAVE_ATTACHMENTS_START';
const SAVE_ATTACHMENTS_SUCCESS = 'inlineAttachments/SAVE_ATTACHMENTS_SUCCESS';
const SAVE_ATTACHMENTS_FAILURE = 'inlineAttachments/SAVE_ATTACHMENTS_FAILURE';
const DELETE_ATTACHMENT_START = 'inlineAttachments/DELETE_ATTACHMENT_START';
const DELETE_ATTACHMENT_SUCCESS = 'inlineAttachments/DELETE_ATTACHMENT_SUCCESS';
const DELETE_ATTACHMENT_FAILURE = 'inlineAttachments/DELETE_ATTACHMENT_FAILURE';
const UPDATE_DELETED_ATTACHMENTS = 'inlineAttachments/UPDATE_DELETED_ATTACHMENTS';
const CLEAR_DELETED_ATTACHMENTS = 'inlineAttachments/CLEAR_DELETED_ATTACHMENTS';

// selectors
const inlineAttachmentsUploaderUiSelector = (state) =>
    state.ui.attachments.inlineAttachmentsUploader;

export const uploadedFilesSelector = createSelector(
    inlineAttachmentsUploaderUiSelector,
    ({ uploadedFiles }) => uploadedFiles
);

export const uploadedFilesWhereSelector = createSelector(
    uploadedFilesSelector,
    (uploadedFiles) => (predicate) => {
        return filter(uploadedFiles, predicate);
    }
);

export const uploadingFilesSelector = createSelector(
    inlineAttachmentsUploaderUiSelector,
    ({ uploadingFiles }) => uploadingFiles
);

export const uploadingFilesWhereSelector = createSelector(
    uploadingFilesSelector,
    (uploadingFiles) => (predicate) => {
        return filter(uploadingFiles, predicate);
    }
);

const confirmationModalAttachmentIdSelector = createModalSelector(
    confirmationModalContext,
    'attachmentId'
);

// actions
export function updateUploadingFiles(uploads) {
    return {
        type: UPDATE_UPLOADING_FILES,
        payload: uploads,
    };
}

function addUploadingFiles(uploads) {
    return {
        type: ADD_UPLOADING_FILES,
        payload: uploads,
    };
}

export function updateUploads(uploads) {
    return {
        type: UPDATE_UPLOADS,
        payload: uploads,
    };
}

function saveAttachmentsStart() {
    return {
        type: SAVE_ATTACHMENTS_START,
    };
}

function saveAttachmentsSuccess() {
    return {
        type: SAVE_ATTACHMENTS_SUCCESS,
    };
}

function saveAttachmentsFailure(errMsg) {
    return {
        type: SAVE_ATTACHMENTS_FAILURE,
        payload: errMsg,
    };
}

export function clearUploads() {
    return (dispatch) => {
        dispatch(updateUploads([]));
        dispatch(pollingFileSecurityScannerEnd());
    };
}

function updateDeletedAttachments(attachmentId) {
    return {
        type: UPDATE_DELETED_ATTACHMENTS,
        payload: attachmentId,
    };
}

export function clearDeletedAttachments() {
    return {
        type: CLEAR_DELETED_ATTACHMENTS,
    };
}

function deleteAttachmentStart() {
    return {
        type: DELETE_ATTACHMENT_START,
    };
}

function deleteAttachmentSuccess() {
    return {
        type: DELETE_ATTACHMENT_SUCCESS,
    };
}

function deleteAttachmentFailure(errMsg) {
    return {
        type: DELETE_ATTACHMENT_FAILURE,
        payload: errMsg,
    };
}

export function onUploadFailure(error, errorType, failedFiles) {
    return (dispatch, getState) => {
        const oldUploadingFiles = uploadingFilesSelector(getState());
        const newUploadingFiles = map(oldUploadingFiles, (oldUploadingFile) => {
            const newFile = { ...oldUploadingFile };
            if (
                some(
                    failedFiles,
                    (failedFile) =>
                        oldUploadingFile.file.id === failedFile.preview ||
                        failedFile?.fileStatus === FileStatusEnum.FAIL.name
                )
            ) {
                newFile.file.uploadFailed = true;
                newFile.file.errorMessage = error;
                newFile.file.isLoading = false;
            }
            return newFile;
        });
        dispatch(updateUploadingFiles(newUploadingFiles));
    };
}

// want to remove every instance in uploadingFiles that match originalFiles
export function saveUploadedFiles(uploadedFiles, entityId, linkType) {
    return (dispatch, getState) => {
        const oldUploadingFiles = uploadingFilesSelector(getState());
        const newUploadingFiles = filter(oldUploadingFiles, (oldUploadingFile) => {
            const match = find(
                uploadedFiles,
                (uploadedFile) => uploadedFile.preview === oldUploadingFile.file.id
            );
            return !match;
        });

        // Filter out any uploadedFiles that were deleted or failed while they were uploading
        const activeUploadedFiles = compact(
            map(uploadedFiles, (uploadedFile, index) => {
                const match = find(
                    oldUploadingFiles,
                    (oldUploadingFile) =>
                        oldUploadingFile.file.id === uploadedFile.preview &&
                        !oldUploadingFile.file.isDeleted
                );
                if (match) {
                    uploadedFiles[index].entityId = entityId;
                    uploadedFiles[index].linkType = linkType;
                    return uploadedFiles[index];
                } else {
                    return null;
                }
            })
        );
        dispatch(updateUploadingFiles(newUploadingFiles));
        const oldUploads = uploadedFilesSelector(getState());
        dispatch(updateUploads([...activeUploadedFiles, ...oldUploads]));
    };
}

export function startFileUpload(files, entityId, linkType) {
    return (dispatch) => {
        const fileUploads = map(files, (file) => ({
            file: {
                id: file.preview,
                fileWebServerPath: '',
                originalFileName: file.name,
                isLoading: true,
                isDeleted: false,
                uploadFailed: false,
            },
            entityId,
            linkType,
        }));
        dispatch(addUploadingFiles(fileUploads));
    };
}

export function clearErroredUploads(uploadingFiles, rejectPredicate) {
    return (dispatch, getState) => {
        //  Filter out errors somewhere
        const newUploadingFiles = reject(uploadingFiles, {
            file: {
                uploadFailed: true,
            },
        });
        const oldUploadingFiles = reject(uploadingFilesSelector(getState()), rejectPredicate);
        dispatch(updateUploadingFiles([...oldUploadingFiles, ...newUploadingFiles]));
    };
}

export function deleteUpload(uploadedFiles, uploadId, rejectPredicate) {
    return (dispatch, getState) => {
        const newUploads = filter(uploadedFiles, (upload) => {
            return upload.file.id !== uploadId;
        });
        const oldUploads = reject(uploadedFilesSelector(getState()), rejectPredicate);
        dispatch(updateUploads([...oldUploads, ...newUploads]));
    };
}

export function deleteUploadingFile(uploadingFiles, uploadId, rejectPredicate) {
    return (dispatch, getState) => {
        const newUploadingFiles = map(uploadingFiles, (uploadingFile) => {
            const newFile = {
                ...uploadingFile,
            };
            if (uploadingFile.file.id === uploadId) {
                newFile.file.isDeleted = true;
            }
            return newFile;
        });
        const oldUploadingFiles = reject(uploadingFilesSelector(getState()), rejectPredicate);
        dispatch(updateUploadingFiles([...oldUploadingFiles, ...newUploadingFiles]));
    };
}

export function saveInlineAttachments(config) {
    return (dispatch, getState) => {
        const uploads = uploadedFilesSelector(getState());
        if (uploads.length > 0) {
            const attachmentsResource = getAttachmentsResource();
            dispatch(saveAttachmentsStart());
            const attachments = convertFileUploadResponsesToAttachmentLinks(uploads, config);
            return attachmentsResource
                .saveAttachmentsForEntities({
                    entityType: config.entityType,
                    entityIds: [config.entityId],
                    attachments,
                    removeOthers: false,
                })
                .then((attachments) => {
                    dispatch(storeAttachments(attachments));
                    dispatch(saveAttachmentsSuccess());
                    const newUploadedFiles = uploadedFilesWhereSelector(getState())(
                        ({ entityId }) => !!entityId && entityId !== config.entityId
                    );
                    dispatch(updateUploads(newUploadedFiles));
                    const newUploadingFiles = uploadingFilesWhereSelector(getState())(
                        ({ entityId }) => !!entityId && entityId !== config.entityId
                    );
                    dispatch(updateUploadingFiles(newUploadingFiles));
                    dispatch(clearDeletedAttachments());
                })
                .catch((err) => {
                    dispatch(saveAttachmentsFailure(err.message));
                    throw err;
                });
        }
        return Promise.resolve();
    };
}

function confirmDeleteAttachment(attachment) {
    return (dispatch) => {
        const attachmentsResource = getAttachmentsResource();
        dispatch(deleteAttachmentStart());
        return attachmentsResource
            .deleteAttachment(attachment)
            .then(() => {
                dispatch(deleteAttachmentSuccess());
                dispatch(deleteAttachment(attachment.id));
                return;
            })
            .catch((err) => {
                dispatch(deleteAttachmentFailure(err.message));
                dispatch(saveBoxFailure(confirmationModalContext, err.message));
                throw err;
            });
    };
}

export function openConfirmationModal(attachmentId, saveCallback) {
    return (dispatch) => {
        const modalStore = {
            title: modalStrings.title,
            message: modalStrings.message,
            saveCallback,
            attachmentId,
        };
        dispatch(openBox(confirmationModalContext, modalStore));
    };
}

function closeConfirmationModal() {
    return (dispatch) => {
        dispatch(closeBox(confirmationModalContext));
    };
}

export function saveConfirmationModal() {
    return function (dispatch, getState) {
        const state = getState();
        const attachmentId = confirmationModalAttachmentIdSelector(state);
        const attachment = first(attachmentsWhereSelector(state)({ id: attachmentId }));

        return dispatch(confirmDeleteAttachment(attachment))
            .then(() => {
                dispatch(updateDeletedAttachments(attachmentId));
                dispatch(closeConfirmationModal());
                return attachment;
            })
            .catch((err) => {
                dispatch(deleteAttachmentFailure(err.message));
                throw err;
            });
    };
}

export default function inlineAttachmentsUploaderUiReducer(
    state = {
        uploadedFiles: [],
        uploadingFiles: [],
        errorMessages: [],
        attachments: [],
        deletedAttachmentIds: [],
    },
    action
) {
    switch (action.type) {
        case UPDATE_UPLOADS:
            return {
                ...state,
                uploadedFiles: action.payload,
            };
        case UPDATE_UPLOADING_FILES:
            return {
                ...state,
                uploadingFiles: action.payload,
            };
        case ADD_UPLOADING_FILES:
            return {
                ...state,
                uploadingFiles: [...action.payload, ...state.uploadingFiles],
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                uploadedFiles: [...state.uploadedFiles, ...action.payload],
            };
        case UPLOAD_FILE_START:
            return {
                ...state,
                uploadedFiles: [...action.payload, ...state.uploadedFiles],
            };
        case UPLOAD_FILE_FAILURE:
        case SAVE_ATTACHMENTS_FAILURE:
        case DELETE_ATTACHMENT_FAILURE:
            return {
                ...state,
                errorMessages: [action.payload],
            };
        case UPDATE_DELETED_ATTACHMENTS:
            return {
                ...state,
                deletedAttachmentIds: [...state.deletedAttachmentIds, action.payload],
            };
        case CLEAR_DELETED_ATTACHMENTS:
            return {
                ...state,
                deletedAttachmentIds: [],
            };
        default:
            return state;
    }
}

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { replaceSupplementCardBundle } from '~/client-common/core/domain/reports/state/ui';
import { isStopReportSelector } from '~/client-common/core/domain/stops/state/ui';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    setReportAgencyId as legacyReduxSetReportAgencyId,
    setReportDescription as legacyReduxSetReportDescription,
} from '../../../../../legacy-redux/actions/reportsActions';

import { buildSupplementCardBundle, refreshSupplementInfoForm } from '../forms/supplementInfoForm';
import createCard from '../../utils/createCard';
import { updateEventDatesOnStopForm } from '../forms/stopForm';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.SUPPLEMENT_INFO.name,
    baseSelector: (state) => state.ui.report.supplementInfoCard,
    anchor: 'supplement-info-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);
                const report = currentReportSelector(state);
                const reportId = report.id;

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const supplementCardBundle = dispatch(
                                    buildSupplementCardBundle({ reportId, form })
                                );

                                return [
                                    dispatch(
                                        replaceSupplementCardBundle({
                                            reportId,
                                            supplementCardBundle,
                                        })
                                    )
                                        // Needed, because `report` objects live in 2 branches of `state`; the `reports`
                                        // branch and the `reportsTODO` branch.  The `reports` branch has actions + reducer
                                        // that lives in `client/` whereas `reportTODO` lives in `client-common`.
                                        // Cannot be moved into the `replaceSupplementCardBundle` function because it is
                                        // a `client-common` function, so this logic must live here.
                                        .then(({ updatedReport }) => {
                                            dispatch(legacyReduxSetReportAgencyId(updatedReport));
                                            dispatch(
                                                legacyReduxSetReportDescription(updatedReport)
                                            );
                                        }),
                                ];
                            },
                            onSavingSuccess: () => {
                                const isStopReport = isStopReportSelector(getState())(reportId);
                                const formModel = form.getState().model;

                                if (isStopReport) {
                                    dispatch(
                                        updateEventDatesOnStopForm(
                                            formModel.eventStartUtc,
                                            formModel.eventEndUtc
                                        )
                                    );
                                }
                                // Always re-sync `formModel` state.
                                dispatch(refreshSupplementInfoForm({ reportId }));
                            },
                        })
                    );
                }
            };
        },
    },
};

import { GpsRetentionPolicyDto } from '@mark43/gps-api';
import { createSelector } from 'reselect';
import { GpsRetentionAdminActions, saveGpsRetentionPolicy } from '../..';
import { RmsAction } from '../../../../../core/typings/redux';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import {
    GPS_RETENTION_FORM,
    convertFromFormModel,
    convertToFormModel,
} from '../form/gpsRetentionForm';

export function resetGpsRetentionForm(): RmsAction<void> {
    return (dispatch, getState, { formsRegistry }) => {
        formsRegistry.maybeDeferredOperation(GPS_RETENTION_FORM, undefined, (form) => {
            form.resetUi();
            form.resetModel();
        });
    };
}

export function prefillGpsRetentionAdminForm(
    gpsRetentionPolicyDto: GpsRetentionPolicyDto
): RmsAction<void> {
    return (dispatch, getState, { formsRegistry }) => {
        dispatch(resetGpsRetentionForm());

        const formModel = convertToFormModel(gpsRetentionPolicyDto);

        formsRegistry.maybeDeferredOperation(GPS_RETENTION_FORM, undefined, (form) => {
            form.set('', formModel);
        });
    };
}

export function submitGpsRetentionAdminForm(): RmsAction<Promise<void>> {
    return function (dispatch, getState, { formsRegistry }) {
        const form = formsRegistry.get(GPS_RETENTION_FORM);

        if (!form) {
            return Promise.resolve();
        }

        return form
            .submit()
            .then(({ form }) => {
                const toSave = convertFromFormModel(form.getState().model);

                return dispatch(saveGpsRetentionPolicy(toSave as GpsRetentionPolicyDto))
                    .then((gpsRetentionPolicyDto) => {
                        dispatch(prefillGpsRetentionAdminForm(gpsRetentionPolicyDto));
                    })
                    .catch((err: Error) => {
                        throw err;
                    });
            })
            .catch((err: Error) => {
                throw err;
            });
    };
}

const gpsRetentionAdminSelector = (state: RootState) => state.ui.gpsRetentionAdmin;

// const gpsRetentionAdminLodingSelector = createSelector(
//     gpsRetentionAdminSelector,
//     (gpsRetentionAdmin) => {
//         return gpsRetentionAdmin.isLoading;
//     }
// );

export const gpsRetentionAdminSavingSelector = createSelector(
    gpsRetentionAdminSelector,
    (gpsRetentionAdmin) => {
        return gpsRetentionAdmin.isSaving;
    }
);

// const gpsRetentionAdminErrorMessageSelector = createSelector(
//     gpsRetentionAdminSelector,
//     (gpsRetentionAdmin) => gpsRetentionAdmin.errorMessage
// );

const initialUiState: GpsRetentionAdminState = {
    isSaving: false,
    isLoading: false,
    errorMessage: undefined,
};

type GpsRetentionAdminState = {
    isSaving: boolean;
    isLoading: boolean;
    errorMessage?: string;
};

function gpsRetentionAdminUiReducer(
    state = initialUiState,
    action: GpsRetentionAdminActions
): GpsRetentionAdminState {
    switch (action.type) {
        case 'LOAD_GPS_RETENTION_INFO_START':
            return {
                ...state,
                isLoading: true,
                errorMessage: undefined,
            };
        case 'LOAD_GPS_RETENTION_INFO_SUCCESS':
            return {
                ...state,
                isLoading: false,
                errorMessage: undefined,
            };
        case 'LOAD_GPS_RETENTION_INFO_FAILURE':
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.message,
            };
        default:
            return state;
    }
}

export default gpsRetentionAdminUiReducer;

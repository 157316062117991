import { filter, find, includes, chain, map } from 'lodash';
import { FileUploadIntermediate, FileUploadResponse, FileStatusEnumType } from '@mark43/rms-api';

interface S3FileUploadType extends FileUploadIntermediate {
    preview: string;
    file: File;
}

export type UploadingFilesT = {
    entityId?: number;
    file: {
        id: string;
        isDeleted: boolean;
        fileWebServerPath: string;
        originalFileName: string;
        isLoading: boolean;
        uploadFailed: boolean;
    };
    config?: {
        entityId: number;
    };
};

export function getFilesByStatus(files: FileUploadResponse[], status: FileStatusEnumType) {
    return filter(files, ({ file }) => file.fileStatus === status);
}

export function getFilesToRemove(
    files: FileUploadResponse[],
    s3Uploads: S3FileUploadType[],
    uploadingFiles: UploadingFilesT[]
): FileUploadResponse[] {
    const deletedUploadingFiles = filter(uploadingFiles, 'file.isDeleted');

    const filePreviews: string[] = map(deletedUploadingFiles, 'file.id');

    return filter(files, ({ file }) => {
        // remove files if it has been uploaded and deleted from the UI via trash icon button
        const s3Upload = find(
            s3Uploads,
            ({ fileServerFile }) => fileServerFile.id === file.fileServerId
        );
        if (s3Upload) {
            return includes(filePreviews, s3Upload.preview);
        }
        return false;
    });
}

export function getUploadingFilesToRemove(
    files: FileUploadResponse[],
    s3Uploads: S3FileUploadType[],
    uploadingFiles: UploadingFilesT[]
): UploadingFilesT[] {
    return chain(files)
        .map(({ file }) => {
            const s3Upload = find(
                s3Uploads,
                ({ fileServerFile }) => fileServerFile.id === file.fileServerId
            );
            return s3Upload;
        })
        .compact()
        .map((item) => {
            const uploadedFile = find(uploadingFiles, (uploadingFile) => {
                return uploadingFile.file.id === item.preview;
            });
            return uploadedFile;
        })
        .compact()
        .value();
}

import { filter } from 'lodash';
import { DefaultFolderView } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createLinkModule from '../../../../utils/createLinkModule';

const NEXUS_STATE_PROP = 'defaultFolders';

interface DefaultFolders extends DefaultFolderView {
    id: number;
}

const defaultFoldersModule = createLinkModule<DefaultFolders>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// ACTIONS
export const storeDefaultFolders = defaultFoldersModule.actionCreators.storeLinks;
export const replaceDefaultFoldersWhere = defaultFoldersModule.actionCreators.replaceLinksWhere;

// SELECTORS
const defaultFoldersSelector = defaultFoldersModule.selectors.linksSelector;
export const defaultFoldersByOwnerIdSelector = createSelector(
    defaultFoldersSelector,
    (defaultFolders) => (ownerId: number) =>
        filter(defaultFolders, ({ folder }) => folder.ownerId === ownerId)
);

// REDUCER
export default defaultFoldersModule.reducerConfig;

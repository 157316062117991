import { RefContextEnum } from '@mark43/rms-api';

import { get, first } from 'lodash';
import { filterFormData } from '~/client-common/helpers/formHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';

import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { RESOLVER } = fieldTypeClientEnum;

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'id',
    },
    {
        key: 'reportId',
    },
    {
        type: RESOLVER,
        key: 'involvedVehicle',
        fieldName: fields.ITEM_PROFILE_ID,
        resolver: (state) => () =>
            get(first(itemProfilesInReportSelector(state)(currentReportIdSelector(state))), 'id'),
    },
    {
        key: 'vehicleLocked',
        fieldName: fields.IMPOUND_VEHICLE_LOCKED,
    },
    {
        key: 'keysInVehicle',
        fieldName: fields.IMPOUND_KEYS_IN_VEHICLE,
    },
    {
        key: 'nicNumberOriginal',
        fieldName: fields.IMPOUND_NIC_NUMBER_ORIGINAL,
    },
    {
        key: 'ocaNumberOriginal',
        fieldName: fields.IMPOUND_OCA_NUMBER_ORIGINAL,
    },
    {
        key: 'originatingAgencyOriginal',
        fieldName: fields.IMPOUND_ORIGINATING_AGENCY_ORIGINAL,
    },
    {
        key: 'nicNumberCancellation',
        fieldName: fields.IMPOUND_NIC_NUMBER_CANCELLATION,
    },
    {
        key: 'ocaNumberCancellation',
        fieldName: fields.IMPOUND_OCA_NUMBER_CANCELLATION,
    },
    {
        key: 'originatingAgencyCancellation',
        fieldName: fields.IMPOUND_ORIGINATING_AGENCY_CANCELLATION,
    },
]);

const convertToFormModel = (impound) => {
    return impound;
};

const convertFromFormModel = (formModel) => {
    const filteredImpound = filterFormData(formModel);
    return filteredImpound;
};

export default createFormModule({
    context: RefContextEnum.IMPOUND.name,
    fieldViewModels,
    convertToFormModel,
    convertFromFormModel,
});

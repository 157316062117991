import { isEmpty, sortBy } from 'lodash';

import {
    GroupedOptions,
    SelectOptions,
    SelectOption,
} from '../../../core/forms/helpers/selectHelpers';

type OptionsType<Option extends SelectOption> =
    | SelectOptions<Option>
    | GroupedOptions<Option>
    | undefined;

const SORT_END = 999;
const getOrder = (label?: string) => {
    switch (label) {
        case 'Yes':
            return 1;
        case 'No':
            return 2;
        case 'Pending':
            return 3;
        case 'Unknown':
            return 4;
        default:
            return SORT_END;
    }
};

export const sortExtendedBooleanAttributes = <Option extends SelectOption>(
    options: OptionsType<Option>
) => {
    if (isEmpty(options)) {
        return [];
    }

    return sortBy(options, [(o: Option) => getOrder(o?.label)]);
};

import { isString, map } from 'lodash';
import moment from 'moment-timezone';

import dateRangeTypeEnum, {
    ToDatePeriodOption,
    WithinLastPeriodOption,
} from '../../enums/client/dateRangeTypeEnum';
import { DateTimeFormatter, dateTimeDefaultFormatter } from './dateHelpers';

const {
    NO_FILTER,
    WITHIN_LAST_PERIOD,
    TO_DATE_PERIOD,
    CUSTOM_RANGE,
    CUSTOM_TIME,
} = dateRangeTypeEnum;

// This is a work around to specify all to date time span: a period of hunderd years from today until today
export const ALL_TO_DATE = 'P100Y1D';

const strings = {
    periodUnits: {
        H: 'Hour',
        D: 'Day',
        M: 'Month',
        Y: 'Year',
    },
    noFilter: 'All Time',
    withinLastPeriodPrefix: 'Last',
    toDatePeriodSuffix: 'to Date',
    allToDatePeriodPrefix: 'All',
    customRange: 'Custom Range',
    saveCustomRange: 'Apply',
    labels: {
        startDateUtc: 'Start Date / Time',
        endDateUtc: 'End Date / Time',
    },
};

/**
 * @param    [value] String representing a date/time period. Examples:
 *   'PT48H', 'P7D', 'P1Y'.
 *   refactor target: replace with moment.duration
 */
export function parsePeriod(value?: string) {
    if (!value) {
        return false;
    }

    const matches = value.match(/^PT?([\d-]+)([SHDMY])$/);
    if (!matches) {
        return false;
    }

    const [, amount, unit] = matches;
    return { amount: parseInt(amount, 10), unit };
}

/**
 * Format the display string for a `withinLastPeriod` value.
 */
export function formatWithinLastPeriod(value: string) {
    // this will cause an error if value is no defined
    const { amount, unit } = parsePeriod(value) as { amount: number; unit: 'H' | 'D' | 'M' | 'Y' };
    const amountLabel = amount > 1 ? `${amount} ` : '';
    const unitLabel = `${strings.periodUnits[unit]}${amount > 1 ? 's' : ''}`;
    return `${strings.withinLastPeriodPrefix} ${amountLabel}${unitLabel}`;
}

/**
 * Format the display string for a `toDatePeriod` value.
 */
export function formatToDatePeriod(value: string) {
    if (value === ALL_TO_DATE) {
        return `${strings.allToDatePeriodPrefix} ${strings.toDatePeriodSuffix}`;
    }
    const { unit } = parsePeriod(value) as { amount: number; unit: 'H' | 'D' | 'M' | 'Y' };
    return `${strings.periodUnits[unit]} ${strings.toDatePeriodSuffix}`;
}

/**
 * Format the display string for a custom date range. If no dates are given,
 *   return the default placeholder string.
 */
export function formatCustomRange(
    startDateUtc?: moment.MomentInput,
    endDateUtc?: moment.MomentInput,
    includeTime?: boolean,
    dateTimeFormatter?: DateTimeFormatter
) {
    if (!startDateUtc && !endDateUtc) {
        return strings.customRange;
    }

    const formatter = dateTimeFormatter ?? dateTimeDefaultFormatter;
    const formatFunction = formatter[includeTime ? 'formatDateTime' : 'formatDate'];
    return `${formatFunction(startDateUtc)} - ${formatFunction(endDateUtc)}`;
}

function mapWithinLastPeriodToOptionValue(value: string) {
    return `${WITHIN_LAST_PERIOD}_${value}`;
}

function mapToDatePeriodToOptionValue(value: string) {
    return `${TO_DATE_PERIOD}_${value}`;
}

/**
 * Format the given `withinLastPeriod` values as dropdown options. The option
 *   values get a prefix in order for them to be distinguished from
 *   `toDatePeriod` option values, which can be identical otherwise.
 */
export function mapWithinLastPeriodsToOptions(values: string[]) {
    return map(values, (value) => ({
        display: formatWithinLastPeriod(value),
        value: mapWithinLastPeriodToOptionValue(value),
    }));
}

/**
 * Format the given `toDatePeriod` values as dropdown options. The option values
 *   get a prefix in order for them to be distinguished from `withinLastPeriod`
 *   option values, which can be identical otherwise.
 */
export function mapToDatePeriodsToOptions(values: string[]) {
    return map(values, (value) => ({
        display: formatToDatePeriod(value),
        value: mapToDatePeriodToOptionValue(value),
    }));
}

/**
 * @param    [optionValue]  Date range dropdown option value, e.g.
 *   'TO_DATE_PERIOD_P1M', 'CUSTOM_RANGE'.
 * @property type  The date range type.
 * @property value Set only when the date range type is a date/time
 *   period.
 */
function parseDateRangeOptionValue(optionValue: string) {
    const matches =
        optionValue && optionValue.match(`^(${WITHIN_LAST_PERIOD}|${TO_DATE_PERIOD})_(.*)$`);

    if (matches) {
        const [, type, value] = matches;
        return { type, value };
    }

    return { type: optionValue };
}

/**
 * @param    [optionValue]  Date range dropdown option value, e.g.
 *   'TO_DATE_PERIOD_P1M', 'CUSTOM_RANGE'.
 * @param    [startDateUtc] Only needed for custom range.
 * @param    [endDateUtc]   Only needed for custom range.
 * @return   Any of the properties can be undefined depending on the
 *   values.
 */
export function convertDateRangeOptionValueToFieldValues(
    optionValue: string,
    startDateUtc: moment.MomentInput,
    endDateUtc: moment.MomentInput,
    startBeforeDateUtc: moment.MomentInput,
    endAfterDateUtc: moment.MomentInput
) {
    const { type, value } = parseDateRangeOptionValue(optionValue);

    switch (type) {
        case NO_FILTER:
            return {};
        case WITHIN_LAST_PERIOD:
            return { withinLastPeriod: value };
        case TO_DATE_PERIOD:
            return { toDatePeriod: value };
        case CUSTOM_RANGE:
            return { startDateUtc, endDateUtc };
        case CUSTOM_TIME:
            return { startBeforeDateUtc, endAfterDateUtc };
        default:
            return {};
    }
}

/**
 * Inverse operation of `convertDateRangeOptionValueToFieldValues()`.
 * @param  fieldValues
 * @return Date range dropdown option value.
 */
export function convertDateRangeFieldValuesToOptionValue({
    withinLastPeriod,
    toDatePeriod,
    startDateUtc,
    endDateUtc,
    startBeforeDateUtc,
    endAfterDateUtc,
}: {
    withinLastPeriod?: string;
    toDatePeriod?: string;
    startDateUtc?: moment.MomentInput;
    endDateUtc?: moment.MomentInput;
    startBeforeDateUtc?: moment.MomentInput;
    endAfterDateUtc?: moment.MomentInput;
}) {
    if (withinLastPeriod) {
        return mapWithinLastPeriodToOptionValue(withinLastPeriod);
    } else if (toDatePeriod) {
        return mapToDatePeriodToOptionValue(toDatePeriod);
    } else if (startDateUtc || endDateUtc) {
        return CUSTOM_RANGE;
    } else if (startBeforeDateUtc && startBeforeDateUtc === endAfterDateUtc) {
        return CUSTOM_TIME;
    } else {
        return NO_FILTER;
    }
}

export function formatDateRange(
    dateStart?: string | number | null,
    dateEnd?: string | number | null,
    noDateDisplay = 'Unknown'
) {
    if (dateStart && dateEnd) {
        return `${dateStart} - ${dateEnd}`;
    } else if (dateStart) {
        return `${dateStart} - ?`;
    } else if (dateEnd) {
        return `? - ${dateEnd}`;
    } else {
        return noDateDisplay;
    }
}

/**
 * Convert a string representing a number of days to a ISO 8601 period string.
 * @param  days Not a number because this is expected to come from an
 *   input filled in by the user.
 */
export function convertDaysToDayPeriod(days: string) {
    return isString(days) && !!days ? `P${days}D` : undefined;
}

const validUnits = ['D', 'M', 'Y'];
type PeriodUnit = typeof validUnits[number];
const isValidPeriod = (x: PeriodUnit): x is PeriodUnit => validUnits.includes(x);
export function convertAmountAndUnitToPeriod(amount: string, unit: PeriodUnit) {
    return `P${amount}${isValidPeriod(unit) ? unit : 'D'}`;
}

export const dateDifferenceInYears = (start?: moment.MomentInput, end?: moment.MomentInput) => {
    if (!start || !end) {
        return '';
    }

    const startDate = moment(start, moment.ISO_8601);
    const endDate = moment(end, moment.ISO_8601);

    return endDate.diff(startDate, 'years');
};

export const getDateRangeOptions = ({
    withinLastPeriodOptions,
    toDatePeriodOptions,
    includeNoFilterOption,
    includeCustomFilterOption,
}: {
    withinLastPeriodOptions: WithinLastPeriodOption[];
    toDatePeriodOptions: ToDatePeriodOption[];
    includeNoFilterOption?: boolean;
    includeCustomFilterOption?: boolean;
}) => {
    return [
        includeNoFilterOption
            ? {
                  display: strings.noFilter,
                  value: NO_FILTER,
              }
            : undefined,
        ...mapWithinLastPeriodsToOptions(withinLastPeriodOptions),
        ...mapToDatePeriodsToOptions(toDatePeriodOptions),
        includeCustomFilterOption
            ? {
                  display: strings.customRange,
                  value: CUSTOM_RANGE,
              }
            : undefined,
    ].filter((item): item is NonNullable<typeof item> => Boolean(item));
};

import { EntityTypeEnum } from '@mark43/rms-api';

import { map, size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import withFields from '~/client-common/core/fields/components/withFields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_ITEM_PROFILE_ADD_ITEM_REMARKS_LABEL,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import Divider from '../../../../legacy-redux/components/core/Divider';
import AddEntityLink from '../../../core/components/AddEntityLink';
import _NoDataBlock from '../../../core/components/NoDataBlock';
import StaffRemarks from '../../../evidence/core/components/StaffRemarks';
import { openStaffRemarkSidePanel } from '../../../evidence/core/state/ui';
import {
    currentEntityProfileIdSelector as masterItemId,
    staffRemarksDataSelector as staffRemarksData,
} from '../../core/state/ui';
import testIds from '../../../../core/testIds';

const STRINGS = componentStrings.entityProfiles.EntityProfilePropertyStaffRemarks;
const { ITEM_PROFILE } = EntityTypeEnum;

const NoDataBlock = styled(_NoDataBlock)`
    margin-top: -6px;
    padding-left: 24px;
    padding-right: 24px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const Header = styled.div`
    color: ${(props) => props.theme.colors.mediumGrey};
    flex: 0 0 auto;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    text-transform: uppercase;
    width: 105px;
`;

const Content = styled.div`
    flex: 1 1 auto;
    padding-top: 3px;
`;

const Title = styled.div`
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: var(--arc-fontSizes-sm);
    letter-spacing: normal;
    line-height: 15px;
    text-transform: none;
`;

const SubTitle = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    line-height: 15px;
`;

const StaffRemarksContent = styled.div`
    &:last-child {
        margin-bottom: 14px;
    }
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`;

const _ReportRemarks = ({ className, onAddItemRemarks, itemRemarks, reportRemarks }) => {
    const reportRemarksCount = size(reportRemarks);
    const itemRemarksCount = size(itemRemarks);
    const labels = useFields(DISPLAY_ONLY_ITEM_PROFILE_ADD_ITEM_REMARKS_LABEL);
    return (
        <div className={className}>
            <StaffRemarksContent>
                {reportRemarksCount > 0 && (
                    <StaffRemarks staffRemarks={reportRemarks} deletable={true} />
                )}

                {reportRemarksCount === 0 && (
                    <div>
                        <NoDataBlock>{STRINGS.labels.noRemarks}</NoDataBlock>
                    </div>
                )}
            </StaffRemarksContent>

            <StaffRemarksContent>
                <SubTitle>{STRINGS.labels.itemRemarks}</SubTitle>

                <div>
                    <AddEntityLink
                        onClick={onAddItemRemarks}
                        testId={testIds.ENTITY_PROFILE_ADD_STAFF_REMARK}
                    >
                        {labels[DISPLAY_ONLY_ITEM_PROFILE_ADD_ITEM_REMARKS_LABEL]}
                    </AddEntityLink>
                </div>

                {itemRemarksCount > 0 && (
                    <StaffRemarks staffRemarks={itemRemarks} deletable={true} />
                )}
            </StaffRemarksContent>
        </div>
    );
};

const ReportRemarks = styled(_ReportRemarks)`
    padding-top: 10px;
    width: 400px;
`;

const EntityProfilePropertyStaffRemarks = ({
    fieldDisplayNames,
    onAddItemRemarks,
    router,
    staffRemarksData,
}) => (
    <div>
        <Divider />

        <Wrapper data-test-id={testIds.ENTITY_PROFILE_STAFF_REMARK}>
            <Header>{STRINGS.title}</Header>

            <Content>
                {map(
                    staffRemarksData,
                    (
                        {
                            custodialReportId,
                            itemProfileId,
                            itemRemarks,
                            renSequenceNumber,
                            reportRemarks,
                        },
                        index
                    ) => {
                        const reportRemarksTitle = STRINGS.labels.reportRemarks(
                            fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER,
                            renSequenceNumber
                        );

                        return (
                            <div key={`staffRemarks_${index}`}>
                                <Title
                                    onClick={() => router.push(`/reports/${custodialReportId}`)}
                                    data-test-id={testIds.ENTITY_PROFILE_REN_LINK}
                                    tabIndex={0}
                                >
                                    {reportRemarksTitle}
                                </Title>
                                <ReportRemarks
                                    itemRemarks={itemRemarks}
                                    onAddItemRemarks={() => onAddItemRemarks(itemProfileId)}
                                    reportRemarks={reportRemarks}
                                />
                            </div>
                        );
                    }
                )}
            </Content>
        </Wrapper>
    </div>
);

const mapStateToProps = createStructuredSelector({
    masterItemId,
    staffRemarksData,
});

const mapDispatchToProps = (dispatch) => ({
    openStaffRemarkSidePanel: (itemProfileId) =>
        dispatch(openStaffRemarkSidePanel(ITEM_PROFILE.name, [itemProfileId])),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withFields([REPORT_REPORTING_EVENT_NUMBER]),

    withHandlers({
        onAddItemRemarks({ openStaffRemarkSidePanel }) {
            return (itemProfileId) => openStaffRemarkSidePanel(itemProfileId);
        },
    }),

    withRouter
)(EntityProfilePropertyStaffRemarks);

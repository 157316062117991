import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Chain Event Types',
    methods: {
        createChainEventType(chainEventType) {
            return req({
                method: 'POST',
                baseUrl: '/evidence/api',
                url: 'chain_of_custody/event_types',
                data: chainEventType,
            });
        },
        updateChainEventType(chainEventType) {
            return req({
                method: 'PUT',
                baseUrl: '/evidence/api',
                url: `chain_of_custody/event_types/${chainEventType.id}`,
                data: chainEventType,
            });
        },
    },
});

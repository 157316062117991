import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { map, size, capitalize, difference, uniq } from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import pluralize from 'pluralize';

import { createStructuredSelector } from 'reselect';
import { cssVar } from 'arc';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';

import { InlineBanner } from '../../components/InlineBanner';
import {
    REN_IDENTIFIER,
    REPORT_IDENTIFIER,
    recentEntitiesForOwnerTypeOwnerIdAndEntityTypeSelector,
} from '../state/ui';
import { SubsectionTitleText } from '../../components/typography';
import { SimpleLoading as _SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import { NoResults } from '../../components/NoResults';

const strings = componentStrings.core.RecentEntities;

const RecentEntitiesTitle = styled.div`
    border-bottom: 2px solid ${(props) => props.theme.colors.mediumGrey};
    overflow: hidden;
`;

const RecentEntitiesTitleText = styled(SubsectionTitleText)`
    float: left;
    color: ${cssVar('arc.colors.text.tertiary')};
`;

const StyledInlineBanner = styled(InlineBanner)`
    margin-top: 10px;
`;

const SimpleLoading = styled(_SimpleLoading)`
    .loading-small {
        height: 14px;
        width: 14px;
        margin-left: 4px;
        margin-top: 0;
    }
`;

/**
 * Use this component to render a list of recent entities, filtered
 * by the provided props.
 *
 * This component only selects and renders data
 * from the redux store - it does not populate it.
 *
 * @typedef Props
 * @prop {Number} [renForRecents]
 *      The REN to fetch recent entities for. With the development
 *      of RENless reports, this can potentially be undefined
 *
 * @prop {Number} linkType
 *      The supplied linkType will be used to fetch nameReportLinks,
 *      and will filter these from the recent entities list
 *
 * @prop {String} ownerType
 *      If `renForRecents` was supplied, this will be overridden with 'REN'
 *      Otherwise, this will be the owner for which we are fetching recent entities for
 *      (e.g REPORT, WARRANT, etc...)
 *
 * @prop {Number} ownerId
 *      The `id` associated with the `ownerType`. If `renForRecents`
 *      was supplied, this will be overridden with that value. Otherwise, it can be things
 *      like `reportId`, `warrantId`, etc... It is important that the `ownerType`
 *      matches up with the `ownerId`.
 *
 * @prop {Number} contextId
 *      The `id` associated with the specific context in question.
 *      For instance, when dealing with multiple offense cards,
 *      this represents the `id` of the offense
 *
 * @prop {String} entityType
 *      Determines what kind of entity to fetch and return
 *
 * @prop {String} entityDisplayValue
 *      The configured display value from the corresponding field to render as text
 *
 * @prop {Function} onClickRecentEntity
 *      Determines what happens when a recent entity result is clicked
 *
 * @prop {Function} renderRecentEntity
 *      Render prop to customize the display of each recent entity result
 *
 * @param {Props} props
 */
const RecentEntities = ({
    renForRecents,
    entityType,
    renderRecentEntity,
    formatFieldByName,
    recentEntitiesForOwnerTypeOwnerIdAndEntityType,
    ownerType,
    ownerId,
    locationEntityLinksWhere,
    linkType,
    nameReportLinksWhere,
    contextId,
    entityDisplayValue,
}) => {
    const { recentEntityIds, loading, error } =
        recentEntitiesForOwnerTypeOwnerIdAndEntityType({
            ownerType: renForRecents ? REN_IDENTIFIER : ownerType || REPORT_IDENTIFIER,
            ownerId: renForRecents || ownerId,
            entityType,
        }) || {};
    const uniqRecentEntityIds = uniq(recentEntityIds);
    let filteredRecentEntityIds;
    if (entityType === EntityTypeEnum.LOCATION.name) {
        filteredRecentEntityIds = difference(
            uniqRecentEntityIds,
            map(locationEntityLinksWhere({ linkType, entityId: ownerId }), 'locationId')
        );
    } else {
        // filter out the person/organization that's already on the current card + link
        // TODO per WAVE-2549: filter existing owners or claimants when adding a new owner or claimant to an item card
        filteredRecentEntityIds = difference(
            uniqRecentEntityIds,
            map(
                nameReportLinksWhere({
                    // Filter links with same link type
                    linkType,
                    // From the same report
                    reportId: ownerId,
                    // Of the same entity type
                    entityType,
                    // On the same card
                    contextId,
                }),
                'nameId'
            )
        );
    }

    const entityTypeDisplayName =
        entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name
            ? pluralize(entityDisplayValue).toLowerCase()
            : strings.entityTypeDisplayName[entityType];
    const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);

    return (
        <div>
            <RecentEntitiesTitle>
                <RecentEntitiesTitleText>
                    {strings.listTitle({
                        renDisplayName,
                        entityTypeDisplayName: capitalize(entityTypeDisplayName),
                        ren: renForRecents,
                    })}
                </RecentEntitiesTitleText>
            </RecentEntitiesTitle>

            {error && <StyledInlineBanner status="error">{error}</StyledInlineBanner>}
            {loading && <SimpleLoading />}
            {!loading &&
                (size(filteredRecentEntityIds) ? (
                    map(filteredRecentEntityIds, (recentEntityId) =>
                        renderRecentEntity({ recentEntityId })
                    )
                ) : (
                    <NoResults marginBottom="30px">
                        {strings.emptyList({
                            renDisplayName,
                            entityTypeDisplayName,
                            ren: renForRecents,
                        })}
                    </NoResults>
                ))}
        </div>
    );
};

export default connect(
    createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
        recentEntitiesForOwnerTypeOwnerIdAndEntityType: recentEntitiesForOwnerTypeOwnerIdAndEntityTypeSelector,
        nameReportLinksWhere: nameReportLinksWhereSelector,
        locationEntityLinksWhere: locationEntityLinksWhereSelector,
    })
)(RecentEntities);

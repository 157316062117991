import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { N_ITEMS_FIELDSET, ATTRIBUTE } = fieldTypeClientEnum;

/**
 * View model for the `OwnerClaimantNItems` component.
 * Note: No fields for claimants.
 * @type {Object}
 */
export default {
    type: N_ITEMS_FIELDSET,
    key: 'owners',
    fields: buildFlatFormFieldViewModels([
        {
            key: 'proofOfOwnershipAttrId',
            type: ATTRIBUTE,
            fieldName: fields.NAME_ITEM_LINK_PROOF_OF_OWNERSHIP_ATTR_ID,
        },
        {
            key: 'proofOfOwnershipOther',
            fieldName: fields.NAME_ITEM_LINK_PROOF_OF_OWNERSHIP_OTHER,
        },
    ]),
};

import { useBreakpointValue } from 'arc';

export const useScreenBreakpoint = () => {
    const breakpoint = useBreakpointValue({
        base: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 5,
        '2xl': 6,
    } as const);

    const isMobileSm = breakpoint && breakpoint < 2;
    const isMobile = breakpoint && breakpoint < 3;
    const isLargeScreen = breakpoint && breakpoint > 3;
    const isMediumScreen = breakpoint && breakpoint > 2;

    return { isMobileSm, isMobile, isLargeScreen, isMediumScreen };
};

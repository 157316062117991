import _, { parseInt } from 'lodash';
import { createSelector } from 'reselect';

import { reportPrintingTemplatesSelector } from '~/client-common/core/domain/report-printing-templates/state/data';
import reportPrintingTemplatesAdminForm from '../forms/reportPrintingTemplatesAdminForm';

const SELECT_REPORT_PRINTING_TEMPLATE_START =
    'report-printing-templates/SELECT_REPORT_PRINTING_TEMPLATE_START';
const SELECT_REPORT_PRINTING_TEMPLATE_SUCCESS =
    'report-printing-templates/SELECT_REPORT_PRINTING_TEMPLATE_SUCCESS';
const SELECT_REPORT_PRINTING_TEMPLATE_FAILURE =
    'report-printing-templates/SELECT_REPORT_PRINTING_TEMPLATE_FAILURE';
const OPEN_NEW_REPORT_PRINTING_TEMPLATE_FORM =
    'report-printing-templates/OPEN_NEW_REPORT_PRINTING_TEMPLATE_FORM';
const CLEAR_REPORT_PRINTING_TEMPLATE_ADMIN =
    'report-printing-templates/CLEAR_REPORT_PRINTING_TEMPLATE_ADMIN';
export const SAVE_REPORT_PRINTING_TEMPLATE_START =
    'report-printing-templates/SAVE_REPORT_PRINTING_TEMPLATE_START';
export const SAVE_REPORT_PRINTING_TEMPLATE_SUCCESS =
    'report-printing-templates/SAVE_REPORT_PRINTING_TEMPLATE_SUCCESS';
export const SAVE_REPORT_PRINTING_TEMPLATE_FAILURE =
    'report-printing-templates/SAVE_REPORT_PRINTING_TEMPLATE_FAILURE';
export const DELETE_REPORT_PRINTING_TEMPLATE_START =
    'report-printing-templates/DELETE_REPORT_PRINTING_TEMPLATE_START';
export const DELETE_REPORT_PRINTING_TEMPLATE_SUCCESS =
    'report-printing-templates/DELETE_REPORT_PRINTING_TEMPLATE_SUCCESS';
export const DELETE_REPORT_PRINTING_TEMPLATE_FAILURE =
    'report-printing-templates/DELETE_REPORT_PRINTING_TEMPLATE_FAILURE';
export const LOAD_REPORT_PRINTING_TEMPLATES_START =
    'report-printing-templates/LOAD_REPORT_PRINTING_TEMPLATES_START';
export const LOAD_REPORT_PRINTING_TEMPLATES_SUCCESS =
    'report-printing-templates/LOAD_REPORT_PRINTING_TEMPLATES_SUCCESS';
export const LOAD_REPORT_PRINTING_TEMPLATES_FAILURE =
    'report-printing-templates/LOAD_REPORT_PRINTING_TEMPLATES_FAILURE';

function selectReportPrintingTemplateStart() {
    return {
        type: SELECT_REPORT_PRINTING_TEMPLATE_START,
    };
}

function selectReportPrintingTemplateSuccess(printingTemplateId) {
    return {
        type: SELECT_REPORT_PRINTING_TEMPLATE_SUCCESS,
        payload: printingTemplateId,
    };
}

function selectReportPrintingTemplateFailure(err) {
    return {
        type: SELECT_REPORT_PRINTING_TEMPLATE_FAILURE,
        payload: err,
    };
}

export function selectReportPrintingTemplate(printingTemplateId) {
    return (dispatch, getState) => {
        dispatch(selectReportPrintingTemplateStart());
        const state = getState();
        const printingTemplate = reportPrintingTemplatesSelector(state)[printingTemplateId];
        if (printingTemplate) {
            dispatch(reportPrintingTemplatesAdminForm.actionCreators.change(printingTemplate));
            dispatch(selectReportPrintingTemplateSuccess(printingTemplateId));
        } else {
            dispatch(selectReportPrintingTemplateFailure('Failed to get printing template'));
        }
    };
}

export function openNewReportPrintingTemplateForm() {
    return {
        type: OPEN_NEW_REPORT_PRINTING_TEMPLATE_FORM,
    };
}

function clearReportPrintingTemplatesAdminForm() {
    return {
        type: CLEAR_REPORT_PRINTING_TEMPLATE_ADMIN,
    };
}

export function clearReportPrintingTemplatesAdmin() {
    return (dispatch) => {
        dispatch(reportPrintingTemplatesAdminForm.actionCreators.reset());
        return dispatch(clearReportPrintingTemplatesAdminForm());
    };
}

export const reportPrintingTemplatesAdminUiSelector = (state) =>
    state.ui.reportPrintingTemplatesAdmin;

// list of template configs
export const reportPrintingTemplatesListItemsSelector = createSelector(
    reportPrintingTemplatesSelector,
    reportPrintingTemplatesAdminUiSelector,
    (reportPrintingTemplates, ui) =>
        _(reportPrintingTemplates)
            .map((template) => {
                return {
                    path: `/support/printing/reports/${template.id}`,
                    title: template.title,
                    key: template.id,
                    selected: template.id === parseInt(ui.selectedReportPrintingTemplateId),
                };
            })
            .sortBy('title')
            .value()
);

export default function reportPrintingTemplatesAdminUiReducer(
    state = {
        loadingSelectedReportPrintingTemplate: false,
        loadingReportPrintingTemplates: false,
        loadingReportPrintingTemplatesErrorMessage: null,
        formErrorMessage: null,
        formLoading: false,
        savingReportPrintingTemplateSuccess: false,
        selectedReportPrintingTemplateId: null,
        newReportPrintingTemplate: false,
    },
    action
) {
    switch (action.type) {
        case LOAD_REPORT_PRINTING_TEMPLATES_START:
            return {
                ...state,
                loadingReportPrintingTemplates: true,
                loadingReportPrintingTemplatesErrorMessage: null,
            };
        case LOAD_REPORT_PRINTING_TEMPLATES_SUCCESS:
            return {
                ...state,
                loadingReportPrintingTemplatesErrorMessage: null,
                loadingReportPrintingTemplates: false,
            };
        case LOAD_REPORT_PRINTING_TEMPLATES_FAILURE:
            return {
                ...state,
                loadingReportPrintingTemplatesErrorMessage: action.payload,
                loadingReportPrintingTemplates: false,
            };
        case SAVE_REPORT_PRINTING_TEMPLATE_FAILURE:
            return {
                ...state,
                formErrorMessage: action.payload,
                savingReportPrintingTemplateSuccess: false,
            };
        case SAVE_REPORT_PRINTING_TEMPLATE_SUCCESS:
            return {
                ...state,
                formErrorMessage: null,
                savingReportPrintingTemplateSuccess: true,
            };
        case SELECT_REPORT_PRINTING_TEMPLATE_START:
            return {
                ...state,
                loadingSelectedReportPrintingTemplate: true,
                loadingReportPrintingTemplatesErrorMessage: null,
                formErrorMessage: null,
                savingReportPrintingTemplateSuccess: false,
                selectedReportPrintingTemplateId: null,
            };
        case SELECT_REPORT_PRINTING_TEMPLATE_SUCCESS:
            return {
                ...state,
                loadingSelectedReportPrintingTemplate: false,
                selectedReportPrintingTemplateId: action.payload,
                newReportPrintingTemplate: false,
            };
        case SELECT_REPORT_PRINTING_TEMPLATE_FAILURE:
            return {
                ...state,
                loadingReportPrintingTemplatesErrorMessage: action.payload,
                loadingSelectedReportPrintingTemplate: false,
            };
        case OPEN_NEW_REPORT_PRINTING_TEMPLATE_FORM:
            return {
                ...state,
                loadingSelectedReportPrintingTemplate: false,
                loadingReportPrintingTemplatesErrorMessage: null,
                formErrorMessage: null,
                savingReportPrintingTemplateSuccess: false,
                selectedReportPrintingTemplateId: null,
                newReportPrintingTemplate: true,
            };
        case CLEAR_REPORT_PRINTING_TEMPLATE_ADMIN:
            return {
                ...state,
                newReportPrintingTemplate: false,
                selectedReportPrintingTemplateId: null,
                loadingSelectedReportPrintingTemplate: false,
            };
        default:
            return state;
    }
}

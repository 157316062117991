import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import Modal from '../../../../core/overlays/components/Modal';
import { submitRemoveRenFromReport } from '../../state/ui/core';
import { RmsDispatch } from 'src/scripts/core/typings/redux';

interface RemoveRenModalProps {
    closeFocusRef: React.RefObject<HTMLElement>;
    renDisplayName: string;
    reportId: number;
    reportingEventNumber: string;
}

const strings = componentStrings.reports.RemoveRenModal;

const RemoveRenModal: React.FC<RemoveRenModalProps> = ({
    closeFocusRef,
    renDisplayName,
    reportId,
    reportingEventNumber,
}) => {
    const dispatch = useDispatch<RmsDispatch>();
    const handleSave = useCallback(() => dispatch(submitRemoveRenFromReport(reportId)), [
        dispatch,
        reportId,
    ]);

    return (
        <Modal
            cancelFocusRef={closeFocusRef}
            id={overlayIdEnum.REMOVE_REN_MODAL}
            okText={strings.okText}
            onSave={handleSave}
            saveFocusRef={closeFocusRef}
            shouldCloseOnOverlayClick={true}
            title={strings.title(renDisplayName)}
        >
            {strings.message(renDisplayName, reportingEventNumber)}
        </Modal>
    );
};

export default RemoveRenModal;

import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { Text, cssVar } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import _AddPill from '../AddPill';
import ButtonWithLoadingIndicator from '../ButtonWithLoadingIndicator';
import FocusWrapper from '../FocusWrapper';

const AddPill = styled(_AddPill)`
    margin-bottom: 10px;
`;

const ViewMoreButton = styled(ButtonWithLoadingIndicator)`
    float: none;
    margin: 0;
    background-color: transparent;
`;

const NoResultsText = styled(Text)`
    text-align: center;
    margin-bottom: 10px;
    color: ${cssVar('arc.colors.text.tertiary')};
`;

const strings = componentStrings.core.PersonSidePanel.SidePanelSearchResults;

export const SidePanelSearchResults = ({
    onClickAddNew,
    searchResultIds,
    onClickViewMore,
    showViewMore,
    loading,
    renderSearchResult,
    noResultsString,
    addNewString,
    viewMoreString,
    addNewButtonTestId,
}) => (
    <FocusWrapper>
        {!searchResultIds.length && <NoResultsText>{noResultsString}</NoResultsText>}
        {onClickAddNew && (
            <AddPill onClick={onClickAddNew} testId={addNewButtonTestId}>
                {addNewString}
            </AddPill>
        )}
        <FeatureFlagged flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED">
            <Text variant="bodyMd" style={{ marginBottom: 8 }}>
                {strings.searchResults(searchResultIds.length)}
            </Text>
        </FeatureFlagged>
        {map(searchResultIds, renderSearchResult)}
        {showViewMore && (
            <ViewMoreButton loading={loading} onClick={onClickViewMore}>
                {viewMoreString}
            </ViewMoreButton>
        )}
    </FocusWrapper>
);

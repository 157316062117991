import React from 'react';
import classNames from 'classnames';

const Tag: React.FC<{ className?: string }> = ({ children, className }) => {
    return (
        <div className={classNames('tag', className)}>
            <div className={classNames('tag-square', className)} />
            <div className={classNames('tag-text', className)}>{children}</div>
        </div>
    );
};

export default Tag;

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { compact, isEmpty, head, size } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Observer, lifecycleOptions, formEvents } from 'markformythree';

import Promise from 'bluebird';
import { OFFENSE_TYPE_OFFENSE_CODE_FLAGS } from '~/client-common/core/domain/offense-codes/constants';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { nibrsOffenseCodesForOffenseCodeIdForOffenseCodeIdSelector } from '~/client-common/core/domain/offense-code-nibrs-code-links/state/data';

import testIds from '../../../../../../core/testIds';
import {
    formName,
    offenseLocationDescriptionPath,
    offensePositionAttrIdPath,
    getFormConfiguration,
} from '../../../state/forms/arrest-charges-side-panel/stubOffenseForm';
import formsRegistry from '../../../../../../core/formsRegistry';
import ArbiterForm from '../../../../../core/markformythree-arbiter/ArbiterForm';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import Row from '../../../../../core/components/Row';
import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { ArbiterMFTOffenseCodeSelect } from '../../../../../core/forms/components/selects/OffenseCodeSelect';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import { saveStubOffenseSidePanel } from '../../../state/ui/arrest-charges-side-panel/stubOffense';
import {
    MFTAutofillDateButton,
    autofillDateTypeEnum,
} from '../../../../../core/components/AutofillDateButton';
import { ArbiterMFTFilteredNibrsOffenseCodeIdSelect } from '../../../../../core/forms/components/selects/FilteredNibrsCodeSelect';

const offenseCardComponentStrings = componentStrings.reports.core.OffenseCard;

export const unregisterStubOffenseScreenForm = () => formsRegistry.unregister(formName);

export const onSaveSidePanel = () => (dispatch) => {
    const form = formsRegistry.get(formName);
    const { formErrors, success } = form.validate({ eventType: formEvents.FORM_SUBMIT });

    if (isEmpty(formErrors) && success) {
        return dispatch(saveStubOffenseSidePanel({ form })).then((createdStubOffense) => {
            unregisterStubOffenseScreenForm();
            return createdStubOffense;
        });
    }
    return Promise.reject(formErrors);
};

export const onCancelSidePanel = unregisterStubOffenseScreenForm;

const StubOffenseScreen = ({ screenState }) => {
    const { isPerformingAsyncAction } = screenState;
    const getNibrsCodes = useSelector(nibrsOffenseCodesForOffenseCodeIdForOffenseCodeIdSelector);

    const handleOffenseCodeChange = (offenseCodeId) => {
        const nibrsCodes = getNibrsCodes(offenseCodeId) || {};
        const nibrsCode = size(nibrsCodes) === 1 ? head(nibrsCodes) : {};
        formsRegistry.maybeDeferredOperation(formName, undefined, (form) => {
            form.set('nibrsOffenseCodeId', nibrsCode.id);
        });
    };

    const offenseDisplayName = useOffenseFieldName();

    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            configuration={getFormConfiguration()}
            render={(form) => {
                return (
                    <>
                        <Row>
                            <ArbiterMFTOffenseCodeSelect
                                path="offenseCodeId"
                                length="lg"
                                flags={OFFENSE_TYPE_OFFENSE_CODE_FLAGS}
                                autoFocus={true}
                                disabled={isPerformingAsyncAction}
                                clearable={false}
                                onChange={handleOffenseCodeChange}
                            />
                        </Row>
                        <Observer
                            subscriptions={{
                                offenseCodeId: 'offenseCodeId',
                            }}
                            render={({ offenseCodeId }) => {
                                return offenseCodeId ? (
                                    <ArbiterMFTFilteredNibrsOffenseCodeIdSelect
                                        path="nibrsOffenseCodeId"
                                        offenseCodeId={offenseCodeId}
                                        clearable={false}
                                        width={370}
                                    />
                                ) : null;
                            }}
                        />
                        <Row>
                            <ArbiterMFTDatePicker
                                path="offenseDateUtc"
                                includeTime={true}
                                disabled={isPerformingAsyncAction}
                            />
                            <MFTAutofillDateButton
                                onClick={(_e, { eventStartUtc }) =>
                                    form.set('offenseDateUtc', eventStartUtc)
                                }
                                autofillDateType={autofillDateTypeEnum.START_DATE_TIME}
                                startDatePath="offenseDateUtc"
                            />
                        </Row>
                        <SidePanelSection
                            title={offenseCardComponentStrings.offenseLocationSectionTitle(
                                offenseDisplayName
                            )}
                            testId={testIds.OFFENSE_LOCATION_SECTION}
                        >
                            <Observer
                                subscriptions={{
                                    unsavedLocationEntityLink: 'unsavedLocationEntityLink',
                                }}
                                render={({ unsavedLocationEntityLink }) => {
                                    return (
                                        <LocationSummaryViewWrapperWithFormFields
                                            summaryMode={false}
                                            entityType={EntityTypeEnum.OFFENSE.name}
                                            entityId={undefined}
                                            linkType={LinkTypesEnum.OFFENSE_LOCATION}
                                            locationDescriptionPath={() =>
                                                offenseLocationDescriptionPath
                                            }
                                            locationPositionAttrIdPath={() =>
                                                offensePositionAttrIdPath
                                            }
                                            additionalLocationEntityLinks={compact([
                                                unsavedLocationEntityLink,
                                            ])}
                                            onLocationAdd={(location, modelEntityLink) => {
                                                const { linkType } = modelEntityLink;
                                                form.set('location.linkType', linkType);
                                                form.set('location.positionAttrId', undefined);
                                                form.set('location.description', undefined);
                                                form.set(
                                                    'unsavedLocationEntityLink',
                                                    modelEntityLink
                                                );
                                            }}
                                            onLocationRemove={() => {
                                                form.set('location.linkType', undefined);
                                                form.set('location.positionAttrId', undefined);
                                                form.set('location.description', undefined);
                                                form.set('unsavedLocationEntityLink', undefined);
                                            }}
                                        />
                                    );
                                }}
                            />
                        </SidePanelSection>
                    </>
                );
            }}
        />
    );
};

export default StubOffenseScreen;

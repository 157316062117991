import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import componentStrings from '~/client-common/core/strings/componentStrings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';

import { submitAddNewRoleForm, closeAddNewRoleSidePanel, isLoadingSelector } from '../state/ui';
import AddNewRoleForm from './AddNewRoleForm';

const string = componentStrings.admin.roles.ManageRoles.AddNewRoleSidePanel;
const context = {
    name: boxEnum.ADMIN_NEW_ROLE_SIDE_PANEL,
};

function AddNewRoleSidePanel({ submitForm, closeSidePanel, isLoading }) {
    return (
        <SidePanel
            context={context}
            title={string.title}
            onSave={submitForm}
            onClose={closeSidePanel}
            width={475}
        >
            {isLoading ? <SimpleLoading /> : <AddNewRoleForm />}
        </SidePanel>
    );
}

const mapStateToProps = createStructuredSelector({
    isLoading: isLoadingSelector,
});

export default compose(
    withRouter,
    connect(mapStateToProps, (dispatch, { router }) => ({
        submitForm: () => dispatch(submitAddNewRoleForm(router)),
        closeSidePanel: () => dispatch(closeAddNewRoleSidePanel()),
    }))
)(AddNewRoleSidePanel);

import _ from 'lodash';

import actionTypes from '../actions/types/locationAliasesAdminActionTypes';

const initialDataState = {
    aliases: {},
};

export function locationAliasesAdminDataReducer(state = initialDataState, action) {
    let alias;

    switch (action.type) {
        case actionTypes.LOAD_ALIASES_SUCCESS:
            return {
                ...state,
                aliases: action.payload,
            };
        case actionTypes.SAVE_ALIAS_SUCCESS:
            alias = action.payload;

            return {
                ...state,
                aliases: {
                    ...state.aliases,
                    [alias.id]: alias,
                },
            };
        case actionTypes.DELETE_ALIAS_SUCCESS:
            alias = action.payload;

            return {
                ...state,
                aliases: _.omit(state.aliases, alias.id),
            };
        default:
            return state;
    }
}

const initialUiState = {
    aliases: {}, // computed properties not in the data state
    loadingList: false,
    listError: null,
    loadingAlias: false,
    submittingForm: false,
    aliasLoadError: null,
    formError: null,
};

export function locationAliasesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case actionTypes.LOAD_ALIASES_START:
            return {
                ...state,
                loadingList: true,
                listError: null,
            };
        case actionTypes.LOAD_ALIASES_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case actionTypes.LOAD_ALIASES_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload.message,
            };
        case actionTypes.SELECT_ALIAS_START:
        case actionTypes.OPEN_NEW_ALIAS_FORM_START:
            return {
                ...state,
                loadingAlias: true,
                aliasLoadError: null,
                formError: null,
            };
        case actionTypes.SELECT_ALIAS_SUCCESS:
        case actionTypes.OPEN_NEW_ALIAS_FORM_SUCCESS:
            return {
                ...state,
                loadingAlias: false,
            };
        case actionTypes.SELECT_ALIAS_FAILURE:
        case actionTypes.OPEN_NEW_ALIAS_FORM_FAILURE:
            return {
                ...state,
                loadingAlias: false,
                aliasLoadError: action.payload.message,
            };
        case actionTypes.SAVE_ALIAS_START:
        case actionTypes.DELETE_ALIAS_START:
            return {
                ...state,
                submittingForm: true,
                formError: null,
            };
        case actionTypes.SAVE_ALIAS_SUCCESS:
            const alias = action.payload;

            return {
                ...state,
                aliases: {
                    ...state.aliases,
                    [alias.id]: {
                        ...alias,
                    },
                },
                submittingForm: false,
            };
        case actionTypes.DELETE_ALIAS_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case actionTypes.SAVE_ALIAS_FAILURE:
        case actionTypes.DELETE_ALIAS_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload.message,
            };
        default:
            return state;
    }
}

import _, { get, map } from 'lodash';
import React from 'react';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatAttributeWithOther } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

export default function IdentifiersCell(elasticOrganization) {
    const { phoneNumbers, emailAddresses, nameIdentifiers } = elasticOrganization;

    const phoneNumberComponents = _(phoneNumbers)
        .map((phoneNumber) => <div key={phoneNumber}>{phoneNumber}</div>)
        .take(3)
        .value();
    const morePhoneNumbersCount = phoneNumbers.length - 3;
    const phoneNumbersInfo = phoneNumbers.length ? (
        <div className="info-group">
            <div className="info-label">Phone #:</div>
            <div className="info-text">
                {phoneNumberComponents}
                {morePhoneNumbersCount > 0 && (
                    <div className="elastic-row-show-more">{`+ ${morePhoneNumbersCount} more`}</div>
                )}
            </div>
        </div>
    ) : undefined;

    const nameIdentifiersInfo = get(nameIdentifiers, 'length') > 0 && (
        <div className="info-group">
            {map(nameIdentifiers, (nameIdentifier, index) => {
                const { identifier, nameIdentifierTypeOther } = nameIdentifier;
                const { nameIdentifierTypeAttrId } = getViewModelProperties(nameIdentifier);
                const nameIdentifierType = formatAttributeWithOther(
                    nameIdentifierTypeAttrId,
                    nameIdentifierTypeOther
                );

                return (
                    <div key={index}>
                        <div className="info-label">{nameIdentifierType}:</div>
                        <div className="info-text">{identifier}</div>
                    </div>
                );
            })}
        </div>
    );

    const emailAddressesInfo = get(emailAddresses, 'length') > 0 && (
        <div className="info-group">
            <div className="info-label">Email(s):</div>
            <div className="info-text">
                {map(emailAddresses, (emailAddress, index) => (
                    <div key={index}>{emailAddress}</div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="elastic-organization-identifiers">
            {phoneNumbersInfo}
            {emailAddressesInfo}
            {nameIdentifiersInfo}
        </div>
    );
}

import { head } from 'lodash';
import { Citation } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'citations';

const citationsModule = createNormalizedModule<Citation>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

export const citationsWhereSelector = citationsModule.selectors.entitiesWhereSelector;
// Only 1 `Citation` per report
export const citationByReportIdSelector = createSelector(
    citationsWhereSelector,
    (citationsWhere) => (reportId: number) => head(citationsWhere({ reportId }))
);

export default citationsModule.reducerConfig;

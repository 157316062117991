import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { first, omit } from 'lodash';
import warrantCardEnum from '~/client-common/core/enums/client/warrantCardEnum';
import {
    updateWarrantDexSubmissionsForWarrantId,
    warrantDexSubmissionsWhereSelector,
} from '~/client-common/core/domain/warrant-dex-submissions/state/data';
import createCard from '../../../../reports/core/utils/createCard';
import formsRegistry from '../../../../../core/formsRegistry';

const baseCard = createCard({
    name: warrantCardEnum.WARRANT_DEX_SUBMISSION,
    baseSelector: (state) => state.ui.warrants.warrant.warrantDexSubmissionCard,
    anchor: 'warrant-dex-submission-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        transitionToEditMode({ warrantId } = {}) {
            return (dispatch, getState) => {
                const warrantDexSubmission =
                    first(warrantDexSubmissionsWhereSelector(getState())({ warrantId })) || {};
                formsRegistry.maybeDeferredOperation(
                    RefContextEnum.FORM_WARRANT_DEX_SUBMISSION.name,
                    undefined,
                    (form) => {
                        form.set('', {
                            ...omit(
                                warrantDexSubmission,
                                'extraditionLimitationAttrId',
                                'extraditionLimitationAttrType'
                            ),
                            felonyExtraditionLimitationAttrId:
                                warrantDexSubmission.extraditionLimitationAttrType ===
                                AttributeTypeEnum.WARRANT_FELONY_EXTRADITION.name
                                    ? warrantDexSubmission.extraditionLimitationAttrId
                                    : null,
                            misdemeanorExtraditionLimitationAttrId:
                                warrantDexSubmission.extraditionLimitationAttrType ===
                                AttributeTypeEnum.WARRANT_MISDEMEANOR_EXTRADITION.name
                                    ? warrantDexSubmission.extraditionLimitationAttrId
                                    : null,
                        });
                    }
                );
                dispatch(baseCard.actionCreators.transitionToEditMode());
                baseCard.scrollToTop();
            };
        },
        save({ warrantId } = {}) {
            return (dispatch) => {
                const form = formsRegistry.get(RefContextEnum.FORM_WARRANT_DEX_SUBMISSION.name);

                return form
                    .submit()
                    .then((result) => {
                        const form = result.form;
                        const warrantDexSubmission = {
                            ...omit(
                                form.getState().model,
                                'felonyExtraditionLimitationAttrId',
                                'misdemeanorExtraditionLimitationAttrId'
                            ),
                            ...(form.getUi('felonyExtraditionLimitationAttrId').hidden
                                ? {}
                                : {
                                      extraditionLimitationAttrId: form.get(
                                          'felonyExtraditionLimitationAttrId'
                                      ),
                                      extraditionLimitationAttrType:
                                          AttributeTypeEnum.WARRANT_FELONY_EXTRADITION.name,
                                  }),
                            ...(form.getUi('misdemeanorExtraditionLimitationAttrId').hidden
                                ? {}
                                : {
                                      extraditionLimitationAttrId: form.get(
                                          'misdemeanorExtraditionLimitationAttrId'
                                      ),
                                      extraditionLimitationAttrType:
                                          AttributeTypeEnum.WARRANT_MISDEMEANOR_EXTRADITION.name,
                                  }),
                            warrantId,
                        };

                        return dispatch(
                            updateWarrantDexSubmissionsForWarrantId(warrantId, warrantDexSubmission)
                        ).then(() => {
                            dispatch(baseCard.actionCreators.savingSuccess());
                            baseCard.scrollToTop();
                            form.resetModel();
                        });
                    })
                    .catch((err) => {
                        const errorMessages = err.validationResult
                            ? err.validationResult.formErrors
                            : err.message;
                        dispatch(baseCard.actionCreators.savingFailure(errorMessages));
                        baseCard.scrollToTop();
                    });
            };
        },
    },
};

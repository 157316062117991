import { createSelector } from 'reselect';

import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import {
    currentUserDepartmentIdSelector,
    currentUserHasAbilitySelector,
} from '../../../../core/current-user/state/ui';

export const canEditLabelsForReportIdSelector = createSelector(
    currentUserDepartmentIdSelector,
    currentUserHasAbilitySelector,
    reportByIdSelector,
    (currentUserDepartmentId, currentUserHasAbility, reportById) => (reportId: number) => {
        const report = reportById(reportId);
        const currentUserHasManageReportLabelsAbility = currentUserHasAbility(
            abilitiesEnum.REPORTING.MANAGE_REPORT_LABELS
        );

        return (
            currentUserHasManageReportLabelsAbility &&
            !report?.isSealed &&
            currentUserDepartmentId === report?.departmentId
        );
    }
);

import { MessageGroupDto } from '@mark43/cad-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'cadMessagingGroups';
const cadMessagingGroupsModule = createNormalizedModule<MessageGroupDto>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeCadMessagingGroups = cadMessagingGroupsModule.actionCreators.storeEntities;

// SELECTORS
export const cadMessagingGroupsSelector = cadMessagingGroupsModule.selectors.entitiesSelector;

// REDUCER
export default cadMessagingGroupsModule.reducerConfig;

import { EntityTypeEnum, PreferencePermissionEnum, PreferenceEnum } from '@mark43/rms-api';
import { includes, memoize, find, get, map, filter, groupBy } from 'lodash';
import { createSelector } from 'reselect';
import { preferenceLinksSelector } from '~/client-common/core/domain/preference-links/state/data';
import { departmentPreferencesSelector } from '~/client-common/core/domain/department-preferences/state/data';
import {
    LOAD_PREFERENCE_LINKS_FAILURE,
    LOAD_DEPARTMENT_PREFERENCES_FAILURE,
    UPSERT_PREFERENCE_LINK_FAILURE,
    UPSERT_DEPARTMENT_PREFERENCE_FAILURE,
} from '../data';

// These preferences are to excluded from this page because they are used
// at the agency level as oppose to the department level.
const preferencesToExclude = [
    PreferenceEnum.AUTOMATIC_REN_GENERATION_CALL_FOR_SERVICE_BASED.name,
    PreferenceEnum.AUTOMATIC_REN_GENERATION_UNIT_STATUS_BASED.name,
    PreferenceEnum.IS_DISPOSITION_REQUIRED_TO_CLOSE.name,
];

const initialUiState = {
    error: null,
};

export default function preferencesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_DEPARTMENT_PREFERENCES_FAILURE:
        case LOAD_PREFERENCE_LINKS_FAILURE:
        case UPSERT_DEPARTMENT_PREFERENCE_FAILURE:
        case UPSERT_PREFERENCE_LINK_FAILURE:
            return {
                error: action.payload,
            };
        default:
            return state;
    }
}

const departmentPreferenceByPreferenceSelector = createSelector(
    departmentPreferencesSelector,
    (departmentPreferences) =>
        memoize((preference) => {
            return find(departmentPreferences, { preference });
        })
);

export const departmentPreferenceLinksDisplaySelector = createSelector(
    departmentPreferenceByPreferenceSelector,
    preferenceLinksSelector,
    (departmentPreferenceByPreference, preferenceLinks) =>
        map(
            filter(preferenceLinks, { entityType: EntityTypeEnum.DEPARTMENT.name }),
            (preferenceLink) => {
                const departmentPreferenceObject = departmentPreferenceByPreference(
                    preferenceLink.preference
                );
                const preferencePermissionSet = get(
                    departmentPreferenceObject,
                    'preferencePermissionSet',
                    []
                );
                const departmentPreferenceId = get(departmentPreferenceObject, 'id');
                return {
                    ...preferenceLink,
                    preferencePermissionSet,
                    departmentPreferenceId,
                    isOverridable: includes(
                        preferencePermissionSet,
                        PreferencePermissionEnum.USER.name
                    ),
                };
            }
        )
);

export const departmentPreferenceEnabledSelector = createSelector(
    departmentPreferenceLinksDisplaySelector,
    (departmentPreferenceLinksDisplay) => {
        return departmentPreferenceLinksDisplay.reduce((obj, preferenceLink) => {
            obj[preferenceLink.preference] = preferenceLink.booleanValue;
            return obj;
        }, {});
    }
);

export const agencyPreferenceLinksDisplaySelector = createSelector(
    departmentPreferenceByPreferenceSelector,
    preferenceLinksSelector,
    (departmentPreferenceByPreference, preferenceLinks) =>
        map(
            filter(preferenceLinks, { entityType: EntityTypeEnum.AGENCY.name }),
            (preferenceLink) => {
                const departmentPreferenceObject = departmentPreferenceByPreference(
                    preferenceLink.preference
                );
                const preferencePermissionSet = get(
                    departmentPreferenceObject,
                    'preferencePermissionSet',
                    []
                );
                const departmentPreferenceId = get(departmentPreferenceObject, 'id');
                return {
                    ...preferenceLink,
                    preferencePermissionSet,
                    departmentPreferenceId,
                    isOverridable: includes(
                        preferencePermissionSet,
                        PreferencePermissionEnum.USER.name
                    ),
                };
            }
        )
);

export const groupedDepartmentPreferenceLinksSelector = createSelector(
    departmentPreferenceLinksDisplaySelector,
    (departmentPreferenceLinks) => {
        departmentPreferenceLinks = filter(departmentPreferenceLinks, (link) => {
            return !includes(preferencesToExclude, link.preference);
        });
        return groupBy(departmentPreferenceLinks, 'preferenceGroup');
    }
);

const preferencesAdminSelector = (state) => state.ui.preferencesAdmin;

export const preferencesAdminErrorSelector = createSelector(
    preferencesAdminSelector,
    (preferencesAdmin) => preferencesAdmin.error
);

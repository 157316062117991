import React from 'react';
import styled from 'styled-components';

import Checkbox from './Checkbox';

const Label = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const CheckboxContainer = styled(Checkbox)`
    box-sizing: border-box;
    padding: 3px 4px 5px;
    min-width: auto;
    line-height: normal;
    text-align: center;
    font-size: var(--arc-fontSizes-sm);
    border-radius: 4px;
    margin: 0 10px 12px 0;
    height: 30px;
    color: ${(props) => props.theme.colors.cobaltBlue};
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    border: 1px solid ${(props) => props.theme.colors.mediumBlue};
    text-transform: uppercase;
`;

function ButtonCheckbox({ onChange, className, label, value }) {
    return (
        <CheckboxContainer
            className={className}
            onChange={onChange}
            value={value}
            label={<Label>{label}</Label>}
        />
    );
}

/**
 * A checkbox that appears inside a button, with an optional label. There is no
 *   functional difference between this and a normal `Checkbox`, only visual.
 *   This is intended to be used as a "check all/partially checked" button to
 *   indirectly check child checkboxes and to indicate whether child checkboxes
 *   are checked.
 * @param {string} [props.className] Class that applies to the button
 */
export default styled(ButtonCheckbox)`
    ${({ label }) => (label ? 'width: auto;' : '')};
`;

import React, { useCallback, useContext } from 'react';
import { includes, map } from 'lodash';
import { Folder, EntityTypeEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { FormattedDate } from '~/client-common/core/dates/components';

import { getCaseFolderPath } from '../../core/utils/getCaseFolderPath';
import { DraggingIdContext, SelectedRowsContext } from '../../core/contexts';

import testIds from '../../../../core/testIds';
import Link from '../../../core/components/links/Link';
import CaseRowCheckbox from '../../core/components/CaseRowCheckbox';

import _Icon, { iconTypes } from '../../../core/components/Icon';

const NoteFolderWrapper = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 12px 18px;
    cursor: pointer;
    font-size: var(--arc-fontSizes-sm);
    border-left: 6px solid ${(props) => props.theme.colors.white};

    &:hover {
        border-left: 6px solid ${(props) => props.theme.colors.cobaltBlue};
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

const FolderNameWrapper = styled.div`
    display: flex;
`;

const Icon = styled(_Icon)`
    margin-right: 10px;
`;

const UploadDateText = styled.span`
    font-style: italic;
    color: ${(props) => props.theme.colors.lowContrastGrey};
    font-size: var(--arc-fontSizes-sm);
    margin: 6px 0;
`;

interface CaseNoteRowProps {
    isRowSelected: boolean;
    isGhosting?: boolean;
    isDraggingOver?: boolean;
}

const CaseNoteRow = styled.div<CaseNoteRowProps>`
    font-size: var(--arc-fontSizes-md);
    background: ${({ isRowSelected = false, isGhosting, isDraggingOver, theme }) => {
        if (isGhosting) {
            return theme.colors.extraLightGrey;
        }
        if (isRowSelected) {
            return theme.colors.lightBlue;
        }
        if (isDraggingOver) {
            return theme.colors.lightBlue;
        }

        return theme.colors.white;
    }};
`;

const CaseNoteFolderRow: React.FC<{
    folder: Folder;
    currentCaseId: number;
    isDraggingOver: boolean;
}> = ({ folder, currentCaseId }) => {
    const { draggingId } = useContext(DraggingIdContext);
    const { handleRowSelect, isRowSelected, selectedRows } = useContext(SelectedRowsContext);
    const getMiniUserById = useSelector(getMiniUserByIdSelector);

    // When user select multiple rows and drag these rows into a folder,
    // the rows that were selected, but not exactly the one being draged are
    // consider as "ghost". The ghost row will be styled a bit differently
    // e.g greying out background color
    const isGhosting = useCallback(
        (folderId: number): boolean => {
            if (!draggingId) {
                return false;
            }
            const selectedRowIds = map(selectedRows, 'rowId');
            return draggingId !== folderId && includes(selectedRowIds, folderId);
        },
        [draggingId, selectedRows]
    );
    if (!folder) {
        return null;
    }

    const { id: folderId, name, createdDateUtc, updatedBy, isEditable } = folder;

    const user = getMiniUserById(updatedBy);
    const isSelected = isRowSelected !== undefined ? isRowSelected(folderId) : false;

    return (
        <CaseNoteRow
            isRowSelected={isSelected}
            isGhosting={isGhosting(folderId)}
            data-test-id={testIds.CASE_NOTES_LIST_FOLDER_ROW}
            data-test-display={name}
        >
            <NoteFolderWrapper>
                <CaseRowCheckbox
                    value={isSelected}
                    onChange={() => {
                        if (handleRowSelect) {
                            handleRowSelect(
                                folderId,
                                isSelected,
                                true,
                                isEditable,
                                EntityTypeEnum.CASE_NOTE.name
                            );
                        }
                    }}
                    entityType={EntityTypeEnum.CASE_NOTE.name}
                />
                <div>
                    <FolderNameWrapper>
                        <Icon color="cobaltBlue" size={18} type={iconTypes.CASE} />
                        <Link
                            to={getCaseFolderPath({
                                caseId: currentCaseId,
                                entityType: EntityTypeEnum.CASE_NOTE.name,
                                folderId,
                            })}
                            testId={testIds.CASE_NOTES_LIST_FOLDER_LINK}
                        >
                            {name}
                        </Link>
                    </FolderNameWrapper>
                    <UploadDateText>
                        <FormattedDate
                            date={createdDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                        />
                        {user && <span> by {user.firstInitialAndLastName}</span>}
                    </UploadDateText>
                </div>
            </NoteFolderWrapper>
        </CaseNoteRow>
    );
};

export default CaseNoteFolderRow;

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';

const advancedSearchExportForm = createFormModule({
    formName: formClientEnum.ADVANCED_SEARCH_EXPORT,
});

/**
 * Module of search export form. Can be opened from any advanced search
 * tab with export functionality
 * @type {Object}
 */
export default advancedSearchExportForm;

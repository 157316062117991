import { createSelector } from 'reselect';
import themeEnum from '../themeEnum';
import lightModeTheme from '../theme';
import darkModeTheme from '../theme.dark';
import {
    loadArbitraryDataFromLocalStorage,
    saveArbitraryDataToLocalStorage,
} from '../../local-storage';
// Selectors
// returns the enum value of the current theme
export const currentThemeNameSelector = (state) => state.theme.theme;

// This returns theme objects
export const currentThemeSelector = createSelector(currentThemeNameSelector, (currentThemeName) => {
    switch (currentThemeName) {
        case themeEnum.LIGHT_MODE:
            return lightModeTheme;
        case themeEnum.DARK_MODE:
            return darkModeTheme;
        default:
            return lightModeTheme;
    }
});

// Actions
const CHANGE_THEME = 'theme/CHANGE_THEME';

export function loadTheme() {
    return (dispatch) => {
        dispatch(loadArbitraryDataFromLocalStorage('theme')).then((theme) => {
            if (theme) {
                // old enum is LIGHT_MODE and DARK_MODE, use old enum to get new arc enum value
                if (themeEnum[theme]) {
                    dispatch(changeTheme(themeEnum[theme]));
                    // store new enum value. can remove this branch after X months
                    dispatch(saveArbitraryDataToLocalStorage('theme', themeEnum[theme]));
                } else {
                    dispatch(changeTheme(theme));
                }
            }
        });
    };
}

export function toggleTheme() {
    return (dispatch, getState) => {
        const currentTheme = currentThemeNameSelector(getState());
        const newTheme =
            currentTheme === themeEnum.LIGHT_MODE ? themeEnum.DARK_MODE : themeEnum.LIGHT_MODE;
        dispatch(changeTheme(newTheme));
        dispatch(saveArbitraryDataToLocalStorage('theme', newTheme));
    };
}

function changeTheme(newTheme) {
    return {
        type: CHANGE_THEME,
        payload: newTheme,
    };
}

// Reducers
export default function themeReducer(
    state = {
        theme: themeEnum.LIGHT_MODE,
    },
    action
) {
    switch (action.type) {
        case CHANGE_THEME:
            return {
                ...state,
                theme: action.payload,
            };
        default:
            return state;
    }
}

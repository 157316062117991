import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { formatFieldNames, Field, formatFieldByNameSelector } from '../state/config';

/**
 * HOC to format field names
 * @return               Object in the shape of { [fieldName]: displayName }
 */
export default function withFields<T extends Field[]>(fieldNamesOrFunc: T | (() => T)) {
    const fieldNames =
        typeof fieldNamesOrFunc === 'function' ? fieldNamesOrFunc() : fieldNamesOrFunc;
    return connect(
        createSelector(formatFieldByNameSelector, (fieldNameFormatter) => ({
            fieldDisplayNames: formatFieldNames(fieldNameFormatter, fieldNames),
        }))
    );
}

import React from 'react';
import { connect } from 'react-redux';

import RecordsHeaderBookingButton from '../../../../records/core/components/header/RecordsHeaderBookingButton';
import { openBox } from '../../../../../legacy-redux/actions/boxActions';
import { fillBookingSidePanel, BOOKING_SIDE_PANEL_CONTEXT } from '../../state/ui';

function ReportHeaderBookingButton({ className, openBookingSidePanel }) {
    return <RecordsHeaderBookingButton className={className} onClick={openBookingSidePanel} />;
}

const mapDispatchToProps = (dispatch) => ({
    openBookingSidePanel: () => {
        dispatch(fillBookingSidePanel());
        dispatch(openBox(BOOKING_SIDE_PANEL_CONTEXT));
    },
});

export default connect(null, mapDispatchToProps)(ReportHeaderBookingButton);

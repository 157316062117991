import _ from 'lodash';
import cobaltHistory from '../cobaltHistory';

function stripState(hash, stateString) {
    const pattern = new RegExp(`/${stateString}.*$`);
    return hash.replace(pattern, '');
}

export default function createStateToggleFunction(stateString, conflictingStates) {
    return () => {
        if (window.location.hash.indexOf(stateString) !== -1) {
            cobaltHistory.push(stripState(_.trimStart(window.location.hash, '#'), stateString));
        } else {
            cobaltHistory.push(
                `${_.trimStart(
                    stripFakedStates(window.location.hash, conflictingStates),
                    '#'
                )}/${stateString}`
            );
        }
    };
}

function stripFakedStates(hash, conflictingStates) {
    return _.reduce(conflictingStates, (result, state) => result.replace(`/${state}`, ''), hash);
}

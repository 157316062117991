import { CliArgumentConfigurationView } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const commandLineModule = createNormalizedModule<CliArgumentConfigurationView>({
    type: 'commandLine',
});

// ACTIONS
export const storeCommandLineArguments = commandLineModule.actionCreators.storeEntities;

export const deleteCommandLineArgument = commandLineModule.actionCreators.deleteEntity;

// SELECTORS
export const commandLineArgumentsSelector = commandLineModule.selectors.entitiesSelector;
export const commandLineArgumentsByIdSelector = commandLineModule.selectors.entityByIdSelector;

export default commandLineModule.reducerConfig;

import { get, map, flatten } from 'lodash';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import accountStatusEnum from '~/client-common/core/enums/client/accountStatusEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import {
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFlatFilters } from '../../../../../legacy-redux/helpers/formFilterHelpers';

import { createFormModule } from '../../../../core/forms';

const { ABILITY, ATTRIBUTE, ROLE, ACCOUNT_STATUS } = fieldTypeClientEnum;
const strings = componentStrings.admin.users.UsersDashboardSearchForm.filterModal;

const usersDashboardFieldViewModels = {
    ...buildFlatFormFieldViewModels(['quickSearchQuery']),
    dutyStatuses: {
        key: 'dutyStatuses',
        label: strings.dutyStatuses,
        type: undefined,
    },
    rankAttrIds: {
        key: 'rankAttrIds',
        label: strings.rankAttrIds,
        type: ATTRIBUTE,
    },
    unitAttrIds: {
        key: 'unitAttrIds',
        label: strings.unitAttrIds,
        type: ATTRIBUTE,
    },
    roleIds: {
        key: 'roleIds',
        label: strings.roleIds,
        type: ROLE,
    },
    abilityIds: {
        key: 'abilityIds',
        label: strings.abilityIds,
        type: ABILITY,
    },
    employeeTypeAttrIds: {
        key: 'employeeTypeAttrIds',
        label: strings.employeeTypeAttrIds,
        type: ATTRIBUTE,
    },
    accountStatus: {
        key: 'accountStatus',
        label: strings.accountStatus,
        type: ACCOUNT_STATUS,
    },
};

export function convertUsersDashboardSearchElasticQueryToFormModel(elasticQuery = {}) {
    const accountStatus = convertDisabledValueToAccountStatus(elasticQuery.isDisabled);
    const data = {
        quickSearchQuery: elasticQuery.quickSearchQuery,
        dutyStatuses: flatten(map(elasticQuery.dutyStatusHistories, 'dutyStatuses')),
        roleIds: flatten(map(elasticQuery.roles, 'ids')),
        abilityIds: flatten(map(elasticQuery.roles, 'abilityIds')),
        employeeTypeAttrIds: get(elasticQuery, 'employeeTypeAttrIds'),
        rankAttrIds: flatten(map(elasticQuery.userAssignments, 'rankAttrIds')),
        unitAttrIds: flatten(map(elasticQuery.userAssignments, 'personnelUnitAttrIds')),
        accountStatus,
    };
    return buildFormModel(data, usersDashboardFieldViewModels);
}

// Following two helper functions exist to convert between bool and enum.
// Endpoint expects `isDisabled :: boolean`
const convertDisabledValueToAccountStatus = (isDisabled) => {
    switch (isDisabled) {
        case false:
            return accountStatusEnum.ACTIVE;
        case true:
            return accountStatusEnum.INACTIVE;
        default:
            return undefined;
    }
};

const convertAccountStatusToDisabledValue = (accountStatus) => {
    switch (accountStatus) {
        case accountStatusEnum.ACTIVE:
            return false;
        case accountStatusEnum.INACTIVE:
            return true;
        default:
            return undefined;
    }
};

function convertUsersDashboardSearchFormModelToElasticQuery(formModel = {}) {
    const now = nowUtc();
    const { rankAttrIds, unitAttrIds, dutyStatuses } = formModel;
    // we have to check for existence and `length` because the form model
    // will contain an empty array and not `undefined`, if a user selects and
    // subsequently removes all values from a multi-select
    const userAssignmentDateUtc =
        (rankAttrIds && rankAttrIds.length) || (unitAttrIds && unitAttrIds.length)
            ? now
            : undefined;
    const dutyStatusesEffectiveDateUtc = dutyStatuses && dutyStatuses.length ? now : undefined;
    const isDisabled = convertAccountStatusToDisabledValue(formModel.accountStatus);

    return filterFormData(
        {
            quickSearchQuery: formModel.quickSearchQuery,
            dutyStatusHistories: [
                {
                    dutyStatuses,
                    dateEffectiveStartBefore: dutyStatusesEffectiveDateUtc,
                    dateEffectiveEndAfter: dutyStatusesEffectiveDateUtc,
                },
            ],
            roles: [
                {
                    ids: formModel.roleIds,
                    abilityIds: formModel.abilityIds,
                },
            ],
            employeeTypeAttrIds: formModel.employeeTypeAttrIds,
            userAssignments: [
                {
                    startBeforeDateUtc: userAssignmentDateUtc,
                    endAfterDateUtc: userAssignmentDateUtc,
                    rankAttrIds,
                    personnelUnitAttrIds: unitAttrIds,
                },
            ],
            isDisabled,
        },
        usersDashboardFieldViewModels
    );
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
export function convertUsersDashboardSearchFormModelToFilterGroups(formModel, formatFieldValue) {
    return convertFormModelToFlatFilters(
        formModel,
        usersDashboardFieldViewModels,
        formatFieldValue
    );
}

const usersDashboardSearchForm = createFormModule({
    formName: formClientEnum.USERS_DASHBOARD_ADMIN,
    fieldViewModels: usersDashboardFieldViewModels,
    convertToFormModel: convertUsersDashboardSearchElasticQueryToFormModel,
    convertFromFormModel: convertUsersDashboardSearchFormModelToElasticQuery,
});

export default usersDashboardSearchForm;

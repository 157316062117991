import { createSelector } from 'reselect';

import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { approvalStatusSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { baseSelector as narrativeCardsBaseSelector } from './narrativeCard';

export const enableAddInlineCommentsSelector = createSelector(
    approvalStatusSelector,
    currentUserHasAbilitySelector,
    narrativeCardsBaseSelector,
    (approvalStatus, currentUserHasAbility, narrativeCards) => (reportId: number) => {
        return (
            approvalStatus !== approvalStatusClientEnum.DRAFT &&
            currentUserHasAbility(abilitiesEnum.REPORTING.ADD_DELETE_REPORT_COMMENTS) &&
            currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_GENERAL) &&
            narrativeCards[reportId] &&
            narrativeCards[reportId].summaryMode
        );
    }
);

export const canViewInlineCommentsSelector = createSelector(
    currentUserHasAbilitySelector,
    applicationSettingsSelector,
    (currentUserHasAbility, applicationSettings) =>
        currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_GENERAL) &&
        (currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_REPORT_COMMENTS) ||
            currentUserHasAbility(abilitiesEnum.REPORTING.ADD_DELETE_REPORT_COMMENTS)) &&
        !!applicationSettings.RMS_INLINE_NARRATIVE_COMMENTS_ENABLED
);

export const canResolveInlineCommentsSelector = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) =>
        currentUserHasAbility(abilitiesEnum.REPORTING.ADD_DELETE_REPORT_COMMENTS) &&
        currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_GENERAL)
);

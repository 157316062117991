import React from 'react';
import styled from 'styled-components';
import { cssVar, EmptyState, Spinner } from 'arc';
import { InjectedRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { useReportUiStateContext } from '../context/ReportUiStateProvider';
import { logWarning } from '../../../../core/logging';
import AssociatedRecordsSection from '../../../records/associated-records/components/AssociatedRecordsSection';
import { currentReportIsSnapshotSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { RMSCoreCard } from './RMSCoreCard';
import { DragonForm } from './DragonForm';

type CardProps = {
    reportId: number;
    router: InjectedRouter;
    editCallback: (callback: () => void) => void;
};

const Container = styled.div`
    margin-top: ${cssVar('arc.space.6')};
    display: flex;
    justify-content: center;
`;

export const OrderedReportCards = ({
    reportId,
    router,
    editCallback,
}: CardProps): JSX.Element | null => {
    const { loadingState, orderedReportCardLinks } = useReportUiStateContext();
    const currentReportIsSnapshot = useSelector(currentReportIsSnapshotSelector);

    if (loadingState.isLoading) {
        return (
            <Container>
                <Spinner size="lg" />
            </Container>
        );
    }

    if (loadingState.errorMessage) {
        return (
            <Container>
                <EmptyState
                    title="An unexpected error occurred"
                    subtitle={loadingState.errorMessage}
                    visual="Card"
                />
            </Container>
        );
    }

    return (
        <>
            {orderedReportCardLinks.map(({ id, cardId, externalCardId, downloadableCard }) => {
                const isRmsCoreCard = !!cardId;
                const isDragonCard = !!externalCardId;
                if (isRmsCoreCard) {
                    return (
                        <RMSCoreCard
                            key={cardId}
                            router={router}
                            cardId={cardId}
                            downloadableCard={downloadableCard}
                            editCallback={editCallback}
                        />
                    );
                } else if (isDragonCard) {
                    return <DragonForm key={externalCardId} formConfigurationId={externalCardId} />;
                }
                logWarning(
                    'A ordered card link needs to have at least one cardId or externalCardId specified',
                    {
                        orderedCardLinkId: id,
                    }
                );
                return null;
            })}
            {!currentReportIsSnapshot && orderedReportCardLinks.length > 0 && (
                <AssociatedRecordsSection reportId={reportId} router={router} />
            )}
        </>
    );
};

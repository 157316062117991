import { InventoryTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

const CellWrapper = styled.div`
    margin-left: 12px;
`;
const { AUDIT, INVENTORY } = InventoryTypeEnum;

const InventoryTypeCell = (inventory) => {
    const { inventoryType } = getViewModelProperties(inventory);
    const type =
        inventoryType === AUDIT.name
            ? 'Audit'
            : inventoryType === INVENTORY.name
            ? 'Full inventory'
            : '';

    return <CellWrapper>{type}</CellWrapper>;
};

export default InventoryTypeCell;

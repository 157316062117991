import { createSelector } from 'reselect';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import getAssociatedRecordsResource from '~/client-common/core/domain/reports/resources/associatedRecordsResource';

const SET_ASSOCIATED_RECORD_LIST_SORT = 'case-associated-records/SET_ASSOCIATED_RECORD_LIST_SORT';
const RESET_CURRENT_CASE_ASSOCIATED_RECORDS_UI =
    'case-associated-records/RESET_CURRENT_CASE_ASSOCIATED_RECORDS_UI';
const LOAD_CASE_ASSOCIATED_RECORDS = 'case-associated-records/LOAD_CASE_ASSOCIATED_RECORDS';

export const associatedRecordListSortTypes = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
};

export function setAssociatedRecordSort(sortKey) {
    return (dispatch, getState) => {
        const currentTableUi = caseAssociatedRecordsTableUiSelector(getState());
        dispatch({
            type: SET_ASSOCIATED_RECORD_LIST_SORT,
            payload: {
                sortKey,
                sortType:
                    currentTableUi.sortKey === sortKey &&
                    currentTableUi.sortType === associatedRecordListSortTypes.ASCENDING
                        ? associatedRecordListSortTypes.DESCENDING
                        : associatedRecordListSortTypes.ASCENDING,
            },
        });
    };
}

export function loadCaseAssociatedRecords(caseId) {
    return (dispatch, getState, dependencies) => {
        return getAssociatedRecordsResource()
            .getAssociatedRecordsForCase(caseId)
            .then((associatedRecords) => {
                dispatch(
                    dependencies.nexus.withEntityItems(associatedRecords, {
                        type: LOAD_CASE_ASSOCIATED_RECORDS,
                    })
                );
                return associatedRecords;
            });
    };
}

const currentCaseAssociatedRecordsUiSelector = (state) => state.ui.cases.associatedRecords;

export const caseAssociatedRecordsTableUiSelector = createSelector(
    currentCaseAssociatedRecordsUiSelector,
    (currentCaseAssociatedRecordsUi) => currentCaseAssociatedRecordsUi
);

export default makeResettable(
    RESET_CURRENT_CASE_ASSOCIATED_RECORDS_UI,
    function currentCaseAssociatedRecordsReducer(
        state = {
            sortKey: 'associatedRecordTitle',
            sortType: associatedRecordListSortTypes.ASCENDING,
        },
        action
    ) {
        switch (action.type) {
            case SET_ASSOCIATED_RECORD_LIST_SORT:
                return {
                    ...state,
                    sortKey: action.payload.sortKey,
                    sortType: action.payload.sortType,
                };
            default:
                return state;
        }
    }
);

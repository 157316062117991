export const USERS_PER_PAGE = 50;

export const MINIMUM_CHARACTERS_FOR_FILTER = 2;

export const editRoleUsersTableColumWidths = {
    name: 280,
    canManage: 165,
    lastModified: 400,
    remove: 85,
};

export const editRoleAbilitiesTableColumnWidths = {
    category: 165,
    active: 165,
    nameDescription: 440,
    enabled: 160,
};

import { get } from 'lodash';
import { FieldDetail, FieldTypeEnum } from '@mark43/rms-api';
import { withOffenseDisplayName } from '../fields/helpers/withOffenseDisplayName';

export default {
    field: {
        required: 'Required',
        invalidRange: 'Invalid Range',
        renSearchReportingEventNumberMustBeInt: 'Must be a number',
        requiredIfNoAssignee: 'Required if no assignee',
        offenseAssaultHomicideCompletedRequired: withOffenseDisplayName(
            ({ singular }) => `Must be "completed" for this ${singular}`
        ),
        offenseJustifiableHomicideHateCrimeNone: withOffenseDisplayName(
            ({ singular }) => `Must be "No" for this ${singular}`
        ),
        offenseItemCategoryDocumentsCreditCardsDeclaredValueZero:
            'Must be 0 for this item category',
        offenseMarijuanaOpiumHallucinogensNumberOfPlants:
            'Not a valid measurement for this item category',
        offenseItemCategoryVehicleQuantityOne: withOffenseDisplayName(
            ({ singular }) => `Must be 1 for this ${singular}`
        ),
        personRapeVictimSexMaleFemaleRequired: 'Cannot be Unknown',
        chainEventSignatureRequiresSignatureEnabled: 'This update requires a signature',
        chainEventIdScanRequiresIdScanEnabled: 'This update requires a photo ID',
        retentionPolicyRequiresPeriodOrIsInfinite:
            'Either Retention Period or Store Indefinitely is required',
        // Field Configuration Admin Form
        fieldConfigurationAdminMinGreaterThanOrEqualToFieldDetailMin: (fieldConfigurationsAdminFormModel: {
            fieldDetail: FieldDetail;
        }) => {
            const { fieldDetail } = fieldConfigurationsAdminFormModel;
            return `Must be greater than or equal to ${get(fieldDetail, 'logicalMin')}`;
        },
        fieldConfigurationAdminMaxLessThanOrEqualToFieldDetailMax: (fieldConfigurationsAdminFormModel: {
            fieldDetail: FieldDetail;
        }) => {
            const { fieldDetail } = fieldConfigurationsAdminFormModel;
            return `Must be less than or equal to ${get(fieldDetail, 'logicalMax')}`;
        },
    },

    min: {
        [FieldTypeEnum.STRING.name]: (min: number) => `Must be at least ${min} characters long`,
        [FieldTypeEnum.NUMBER.name]: (min: number) => `Must be at least ${min}`,
        [FieldTypeEnum.INTEGER.name]: (min: number) => `Must be at least ${min}`,
        // we currently only have rules that compare date to now, but may need other error messages for dates in the future
        [FieldTypeEnum.DATE.name]: () => 'Must be in the future',
        [FieldTypeEnum.DATETIME.name]: () => 'Must be in the future',
    },

    max: {
        [FieldTypeEnum.STRING.name]: (max: number) => `Cannot be longer than ${max} characters`,
        [FieldTypeEnum.NUMBER.name]: (max: number) => `Cannot be greater than ${max}`,
        [FieldTypeEnum.INTEGER.name]: (max: number) => `Cannot be greater than ${max}`,
        // we currently only have rules that compare date to now, but may need other error messages for dates in the future
        [FieldTypeEnum.DATE.name]: () => 'Cannot be in the future',
        [FieldTypeEnum.DATETIME.name]: () => 'Cannot be in the future',
    },

    exactLength: {
        [FieldTypeEnum.STRING.name]: (exactLength: number) => `Must be ${exactLength} characters`,
    },

    fieldType: {
        [FieldTypeEnum.NUMBER.name]: 'Must be a number',
        [FieldTypeEnum.INTEGER.name]: 'Must be a number',
    },

    panel: {
        // generic
        generic: 'One or more issues need to be fixed',
        ucrGeneric: 'One or more issues need to be fixed under UCR',
        failedToSaveGeneric: 'Failed to save, please try again',
        // specific
        fieldRequired: (fieldDisplayName: string) => `${fieldDisplayName} is required`,
        fieldUnique: (fieldDisplayName: string) => `Each ${fieldDisplayName} must be unique`,
        emancipationDate: 'Emancipation Date must be after Date of Birth.',
        noneInjury: 'Person cannot have both "None" and another Injury Type',
        crossStreets: 'Cannot include only one cross street',
        streetAddressOrCrossStreets: 'Must include street address or cross streets',
        subjectRequired: 'A subject is required',
        chargesRequired: 'At least one charge is required',
        phoneLength: 'Phone number must be fewer than 15 digits',
        noStolenVehicle: withOffenseDisplayName(
            ({ plural }) => `Larceny ${plural} cannot involve stolen vehicles`
        ),
        personCannotBeUnknown: 'This person cannot be unknown',
        knownPersonVictim: withOffenseDisplayName(
            ({ singular }) => `This ${singular} requires a known person as a victim`
        ),
        personVictim: withOffenseDisplayName(
            ({ singular }) => `This ${singular} requires a person as a victim`
        ),
        propertyRequired: withOffenseDisplayName(
            ({ singular }) => `This ${singular} requires property`
        ),
        victimNotSociety: 'Victim cannot be Society',
        dobOrAgeRange: 'A Date of Birth or Estimated Age Range is required',
        offenseDateOrDateUnknownRequired: withOffenseDisplayName<{ canBeUnknown: boolean }>(
            ({ singularCap }, { canBeUnknown }) =>
                canBeUnknown
                    ? `${singularCap} date must be entered or marked as unknown`
                    : `${singularCap} date is required`
        ),
        homeAddress: 'This person must have a home address',
        orgAddress: 'This organization must have an address',
        personAddress: 'A home or work address is required',
        suspectRequired: withOffenseDisplayName(
            ({ singular }) => `An ${singular} must have at least one suspect`
        ),
        victimRequired: withOffenseDisplayName(
            ({ singular }) => `An ${singular} must have at least one victim`
        ),
        offenseLocation: withOffenseDisplayName(
            ({ singularCap }) => `${singularCap} must have a location`
        ),
        incidentLocation: 'Incident Location is required',
        // These error messages specify field labels so that they can be used in a Staff Review rejection comment
        justifiableHomicide: 'Justifiable Homicide Circumstance is required',
        negligentManslaughter: 'Negligent Manslaughter Circumstance is required',
        cargoTheft: 'Includes Cargo Theft is required',
        entryForced: 'Was Method of Entry Forced? is required',
        propertyStatusRecoveredByOtherNameRequired: 'Recovering Person is required',
        offenseAssaultHomicideCompletedRequired: withOffenseDisplayName(
            ({ singularCap, plural }) =>
                `${singularCap} must be "Completed" for Assault and Homicide ${plural}`
        ),
        offenseJustifiableHomicideHateCrimeNone: withOffenseDisplayName(
            ({ plural }) => `Suspected Hate Crime must be "No" for Justifiable Homicide ${plural}`
        ),
        offenseAttemptedRequiresPropertyStatusNoneUnknown: withOffenseDisplayName(
            ({ plural }) =>
                `Item status(es) must be "None" or "Unknown" for "Attempted" Crimes Against Property, Gambling, Kidnapping, or Drug/Narcotic ${plural}`
        ),
        offenseStolenPropertyCompletedPropertyStatusNoneRecovered: withOffenseDisplayName(
            ({ plural }) =>
                `Item status(es) must be "None" or "Recovered" for "Completed" Stolen Property ${plural}`
        ),
        offenseBneAbductionBriberyCompletedPropertyStatusNoneRecoveredStolenUnknown: withOffenseDisplayName(
            ({ plural }) =>
                `Item status(es) must be "None", "Recovered", "Stolen", or "Unknown" for "Completed" Kidnapping, Burglary, and Bribery ${plural}`
        ),
        offenseDrugsCompletedPropertyStatusNoneSeized: withOffenseDisplayName(
            ({ plural }) =>
                `Item status(es) must be "None" or "Seized" for "Completed" Drug/Narcotic Violations and Drug Equipment Violations ${plural}`
        ),
        offenseArsonCompletedPropertyStatusBurned: withOffenseDisplayName(
            ({ plural }) => `Item status(es) must be "Burned" for "Completed" Arson ${plural}`
        ),
        offenseForgeryCompletedPropertyStatusForgedRecoveredSeized: withOffenseDisplayName(
            ({ plural }) =>
                `Item status(es) must be "Counterfeited/Forged", "Recovered", or "Seized" for "Completed" Counterfeiting/Forgery ${plural}`
        ),
        offenseDamageCompletedPropertyStatusDamaged: withOffenseDisplayName(
            ({ plural }) =>
                `Item status(es) must be "Destroyed/Damaged/Vandalized" for "Completed" Destruction/Damage/Vandalism of Property ${plural}`
        ),
        offenseInvolvedChildrenNoChildName: withOffenseDisplayName(
            ({ singular }) => `Every child in an ${singular} must have a name`
        ),
        offenseInvolvedChildrenDupeChildNames: withOffenseDisplayName(
            ({ singular }) => `Every child in an ${singular} must have a unique name`
        ),
        offenseTheftFraudCompletedPropertyStatusRecoveredStolen: withOffenseDisplayName(
            ({ plural }) =>
                `Item status(es) must be "Recovered" or "Stolen" for "Completed" Robbery, Extortion, Larceny, Motor Vehicle Theft, Fraud, and Embezzlement ${plural}`
        ),
        offenseGamblingCompletedPropertyStatusSeized: withOffenseDisplayName(
            ({ plural }) => `Item status(es) must be "Seized" for "Completed" Gambling ${plural}`
        ),
        offensePropertyLossDeclaredValueRequired:
            'Property Value cannot be "Unknown" unless item status is "None" or "Unknown"',
        offenseItemCategoryDocumentsCreditCardsDeclaredValueZero:
            'Item value must be 0 for item category "Credit/Debit Cards", "Nonnegotiable Instruments", "Documents/Personal or Business", "Identity Documents", or "Identity - Intangible"',
        offenseMarijuanaOpiumHallucinogensNumberOfPlants:
            'Drug Measurement "Number of Plants" can only be used for "Marijuana" (M), "Opium" (G), or "Other Hallucinogens" (K)',
        offenseItemCategoryVehicleQuantityOne:
            'Vehicles must always be itemized. (For motor vehicle properties, quantity must be 1.)',
        offenseRapeVictimSexMaleFemaleRequired: withOffenseDisplayName(
            ({ plural }) => `Sex must be "Male" or "Female" for Rape ${plural}`
        ),
        personRapeVictimSexMaleFemaleRequired: withOffenseDisplayName(
            ({ plural }) => `Sex must be "Male" or "Female" for Rape ${plural}`
        ),
        offenseKidnappingAbductionRequiresProperty: withOffenseDisplayName(
            ({ plural }) => `Must add property loss for Kidnapping/Abduction ${plural}`
        ),
        offenseBriberyRequiresProperty: withOffenseDisplayName(
            ({ plural }) => `Must add property loss for Bribery ${plural}`
        ),
        offenseBurglaryRequiresProperty: withOffenseDisplayName(
            ({ plural }) => `Must add property loss for Burglary ${plural}`
        ),
        offenseDateRequiredForLawEnforcementAsVictimNibrs: withOffenseDisplayName(
            ({ singularCap }) =>
                `${singularCap} Date is required when victim is a law enforcement officer`
        ),

        // NIBRS Property on Offenses, these strings appear in other places besides the offense
        // panel
        nibrsDisallowedItems: withOffenseDisplayName(
            ({ singular }) =>
                `This ${singular} does not allow property or vehicles to be linked for the purpose of NIBRS reporting`
        ),
        nibrsDisallowedProperty: withOffenseDisplayName(
            ({ singular }) =>
                `This ${singular} does not allow property to be linked for the purpose of NIBRS reporting`
        ),
        nibrsDisallowedVehicle: withOffenseDisplayName(
            ({ singular }) =>
                `This ${singular} does not allow vehicles to be linked for the purpose of NIBRS reporting`
        ),
        nibrsItemInvolvementDisallowedItems: withOffenseDisplayName(
            ({ singular }) =>
                `This ${singular} does not allow property or vehicles to be linked for the purpose of NIBRS reporting when Item Involvement is “No Property or Vehicles Involved” or “Unknown If Property or Vehicles Involved”.`
        ),
        nibrsItemInvolvementDisallowedProperty: withOffenseDisplayName(
            ({ singular }) =>
                `This ${singular} does not allow property to be linked for the purpose of NIBRS reporting when Item Involvement is “No Property or Vehicles Involved” or “Unknown If Property or Vehicles Involved”.`
        ),
        nibrsItemInvolvementDisallowedVehicle: withOffenseDisplayName(
            ({ singular }) =>
                `This ${singular} does not allow vehicles to be linked for the purpose of NIBRS reporting when Item Involvement is “No Property or Vehicles Involved” or “Unknown If Property or Vehicles Involved”.`
        ),
        // both strings should always be provided, even though we handle when they are empty
        nibrsDisallowedStatus: withOffenseDisplayName<{
            status: string;
            offenseNibrsCode: string;
        }>(
            ({ singular }, { status, offenseNibrsCode }) =>
                `Property Status ${status || ''} cannot be linked to this ${singular} ${
                    offenseNibrsCode ? `(NIBRS Code ${offenseNibrsCode})` : ''
                }`
        ),
        nibrsDisallowedCategory: withOffenseDisplayName<{
            category: string;
            offenseNibrsCode: string;
        }>(
            ({ singular }, { category, offenseNibrsCode }) =>
                `Property Category ${category || ''} cannot be linked to this ${singular} ${
                    offenseNibrsCode ? `(NIBRS Code ${offenseNibrsCode})` : ''
                }`
        ),
        nibrsOffenseRequiresLinkedItems: withOffenseDisplayName(
            ({ singular }) =>
                `This ${singular} requires property or vehicles to be linked for the purpose of NIBRS reporting.`
        ),
        nibrsOffenseRequiresLinkedProperty: withOffenseDisplayName(
            ({ singular }) =>
                `This ${singular} requires property to be linked for the purpose of NIBRS reporting.`
        ),
        nibrsOffenseRequiresLinkedVehicles: withOffenseDisplayName(
            ({ singular }) =>
                `This ${singular} requires vehicles to be linked for the purpose of NIBRS reporting.`
        ),
        nibrsRecoveredPropertyImpliesStolen: 'A recovered item must first have a stolen status',
        nibrsRecoveredPropertyImpliesStolenForVehiclePartsAndAccessories: withOffenseDisplayName(
            ({ singular }) =>
                `Vehicle Parts/Accessories with Recovered status must have a linked Stolen status or a linked Stolen Vehicle to the ${singular}.`
        ),

        // cases
        assigneeOrAssignedUnitRequired: 'Either Assignee or Assigned Unit is required',
        cannotHaveAssigneeSupervisorDupe:
            'Cannot have Assignee and Supervisor as the same person or duplicate Supervisors',
        cannotHaveAssigneeAssistingInvestigatorDupe:
            'Cannot have Assignee and Assisting Investigator as the same person or duplicate Assisting Investigators',
        cannotHaveAssistingInvestigatorSupervisorDupe:
            'Cannot have Assisting Investigator and Supervisor as the same person or duplicate Assisting Investigators/Supervisors',
        cannotHaveOtherPersonnelDupe: 'Cannot have duplicate Other Personnel',
        allUsersRolesMustHaveAccessLevel: 'All User/Role(s) must have access level specified',
        closedByFieldsAreRequiredIfStatusClosed: 'All Report Case Status fields must be filled out',

        // Tow Vehicle
        towVehicleRequiresOwner: 'Owner is required',
        towVehicleOwnerRequiresPhoneNumber: 'At least one phone number is required',
        towVehicleOwnerRequiresHomeAddress: 'At least one home address is required',

        // UCR Classification
        ucrEventNumberOfSeniorCitizenVictimsRequiredWhenShown:
            'Number of Senior Citizen Victims is required',
        ucrEventWeaponOrForceInvolvedRequiredWhenShown: 'Weapon or Force Involved is required',
        ucrOffenseRobberyLocationTypeRequiredWhenShown: 'Robbery Location Type is required',
        ucrOffenseBuildingInhabitedRequiredWhenShown: 'Building Inhabited is required',
        ucrOffenseBurglaryResidenceTypeRequiredWhenShown: 'Burglary Residence Type is required',
        ucrOffenseBurglaryTimeRequiredWhenShown: 'Burglary Time is required',
        ucrOffenseLarcenyTheftCategoryRequiredWhenShown: 'Larceny-Theft Category is required',
        ucrOffenseOrEventClassificationRequired: withOffenseDisplayName(
            ({ singularCap }) =>
                `Event Classification or UCR ${singularCap} Classification is required`
        ),
        ucrOffenseOnlyOneLarceny: withOffenseDisplayName(
            ({ singularCap }) =>
                `Only one UCR ${singularCap} Classification in the Larceny category may be present`
        ),
        ucrOffenseBurglaryTimeAndPlace: withOffenseDisplayName(
            ({ plural }) =>
                `Burglary Time and Burlgary Residence Type must be the same on all burglary ${plural}`
        ),
        ucrOffenseRobberyPlace: withOffenseDisplayName(
            ({ plural }) => `Robbery Location Type must be the same on all robbery ${plural}`
        ),
        ucrPropertyRecoveryTypeRequiredWhenShown: 'Vehicle Recovery Type is required',
        ucrPropertyRecoveryDateRequiredWhenShown: 'Recovery Date is required',
        ucrPropertyBurnedRequireOneUcrOffenseArson: withOffenseDisplayName(
            ({ singularCap }) =>
                `Items with Property Status = Burned can only be saved when there is at least one UCR ${singularCap} Classification in the Arson category`
        ),
        ucrOffenseNoDuplicateCodes: withOffenseDisplayName(
            ({ singularCap }) =>
                `No two ${singularCap} Classifications may have the same UCR Summary ${singularCap} Code`
        ),
        ucrOffenseLarcenyAmountMatchesStolenProperty:
            'The sum of stolen property value must match the selected Larceny-Theft Category',
        ucrPropertyNoStolenPropertyIfOffenseIsAssault: withOffenseDisplayName(
            ({ singularCap }) =>
                `Stolen property cannot be added to UCR ${singularCap} Classifications in the Assault category. Consider changing the UCR ${singularCap} Classification to the Robbery category`
        ),
        ucrOffenseOnlyOneArsonOffensePerReport: withOffenseDisplayName(
            ({ singularCap }) =>
                `Only one UCR ${singularCap} Classification in the Arson category may be present`
        ),
        ucrOffenseAdditionalUcrCodesMustBeArsonHumanTraffickingOrHomicide: withOffenseDisplayName(
            ({ singularCap }) =>
                `If multiple UCR ${singularCap} Classifications are present, additional classifications must be Arson, Human Trafficking, or Criminal Homicide - Murder`
        ),
        ucrOffenseAdditionalUcrCodeHomicideMustHaveUcrStatusUnfounded: withOffenseDisplayName(
            ({ singularCap }) =>
                `When in the presence of additional UCR ${singularCap} Classifications, Criminal Homicide - Murder must have UCR Status = Unfounded`
        ),
        ucrSuffocationOrStrangulationRequiredWhenShown: 'Suffocation or Strangulation is required',
        ucrIsDomesticViolenceRequiredWhenShown: 'Domestic Violence is required',
        ucrOffenseRelationshipCodeIdRequiredWhenShown:
            'Relationship of Victim to Offender is required',
        ucrOffenseHomicideWeaponRequiredWhenShown: 'Homicide Weapon or Force Involved is required',
        ucrOffenseArsonLocationRequiredWhenShown: 'Arson Location is required',
        ucrOffenseHomicideRequiredWhenShown: 'Homicide Circumstance Code is required',
        ucrOffenseNegligentManslaughterRequiredWhenShown:
            'Negligent Manslaughter Circumstance Code is required',

        // security
        someRoleNeedsAtLeastManagePermissionError:
            'At least one role must have Can Manage permissions',
        roleNeedsAtLeastPermissionError: (roleName: string, permissionLevel: string) =>
            `"${roleName}" must have at least ${permissionLevel} permissions`,

        // Field Configuration Admin Form
        fieldConfigurationAdminMinLessThanOrEqualToMax:
            'Field Configuration minimum must be less than or equal to maximum',
        fieldConfigurationAdminDateMinMaxCannotBothBeNow: `Field Configuration for field types ${FieldTypeEnum.DATE.name} and ${FieldTypeEnum.DATETIME.name} cannot have minimum and maximum both be now`,

        // create export release
        exportReleaseReleasedToOtherRequired:
            'Released To is required - If "Other" is chosen, "Other" must be specified',

        // evidence
        itemIdentifierBagNumberRequiredForEvidence: ({
            evidenceModuleName,
        }: {
            evidenceModuleName: string;
        }) =>
            `An ${evidenceModuleName?.toLowerCase()} bag number (identifier) is required for an item in police custody`,
        selectInformationCardRequired: 'Select Information card required',
    },

    submission: {
        subjectRequired: 'At least one subject is required',
        useOfForceReportSubjectOrOfficerMustHaveUsedForce:
            'At least one Subject must have used force on Officer or Officer must have used force on at least one Subject',
        routingLabelsRequired: 'At least one label is required before this report can be approved.',
        offenseRequired: withOffenseDisplayName(
            ({ singularCap }) => `At least one ${singularCap} or Incident is required`
        ),
        victimsRequireInjuryNibrs: (name: string, code: string) =>
            `Victims of ${code} must have an injury, but victim ${name} does not have one`,
        victimsRequireRelationshipNibrsUcr: withOffenseDisplayName<{
            name: string;
            code: string;
        }>(
            ({ plural }, { name, code }) =>
                `Victims of ${code} must have offender relationships for all ${plural} they are tied to, but victim ${name} is missing one or more`
        ),
        exclusiveOffensesNibrs: (name: string, code1: string, code2: string) =>
            `${name} cannot be a victim of both ${code1} and ${code2} on the same report`,
    },
};

import { Button, Flex, IconButton, Popover, PopoverContent, PopoverTrigger, VStack } from 'arc';
import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Form, lifecycleOptions } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
} from '~/client-common/core/fields/state/config';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import * as fieldNames from '~/client-common/core/enums/universal/fields';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { getSubdivisionShownMap } from '~/client-common/core/domain/locations/utils/subdivisionHelpers';
import { MFTAttributeSelect } from '../../core/forms/components/selects/AttributeSelect';
import { MFTConsortiumDepartmentSelect } from '../../core/forms/components/selects/ConsortiumDepartmentSelect';
import { FORM_NAME } from '../state/ui';

const strings = componentStrings.personalDashboard.filters;

const Filters = styled.div`
    width: 300px;
    margin: auto;
    z-index: 1;
    overflow-y: auto;
`;

const oldSubdivisionMappings = {
    SUBDIVISION_DEPTH_1: 'subdivision1',
    SUBDIVISION_DEPTH_2: 'subdivision2',
    SUBDIVISION_DEPTH_3: 'subdivision3',
    SUBDIVISION_DEPTH_4: 'subdivision4',
    SUBDIVISION_DEPTH_5: 'subdivision5',
};

const width = 269;

class RecentArrestsFilters extends React.Component {
    state = {
        showFilters: false,
    };

    subdivisionShownMap = getSubdivisionShownMap(
        this.props.fieldConfigurationContextByContextAndFieldName
    );

    submitForm = (submissionPromise) => {
        submissionPromise.then(({ form }) => {
            const subdivisionAttrIds = form.get('subdivisionAttrIds');
            const subdivision1AttrIds = form.get('subdivision1AttrIds');
            const subdivision2AttrIds = form.get('subdivision2AttrIds');
            const subdivision3AttrIds = form.get('subdivision3AttrIds');
            const subdivision4AttrIds = form.get('subdivision4AttrIds');
            const subdivision5AttrIds = form.get('subdivision5AttrIds');

            const filterParams = {
                departmentId: form.get('departmentId'),
            };

            if (!!subdivisionAttrIds && subdivisionAttrIds.length > 0) {
                const subdivisionType = this.props.getAttributeById(subdivisionAttrIds).type;
                const key = oldSubdivisionMappings[subdivisionType];
                filterParams[key] = subdivisionAttrIds;
            }
            if (!!subdivision1AttrIds && subdivision1AttrIds.length > 0) {
                const subdivisionType = this.props.getAttributeById(subdivision1AttrIds).type;
                const key = oldSubdivisionMappings[subdivisionType];
                filterParams[key] = subdivision1AttrIds;
            }
            if (!!subdivision2AttrIds && subdivision2AttrIds.length > 0) {
                const subdivisionType = this.props.getAttributeById(subdivision2AttrIds).type;
                const key = oldSubdivisionMappings[subdivisionType];
                filterParams[key] = subdivision2AttrIds;
            }
            if (!!subdivision3AttrIds && subdivision3AttrIds.length > 0) {
                const subdivisionType = this.props.getAttributeById(subdivision3AttrIds).type;
                const key = oldSubdivisionMappings[subdivisionType];
                filterParams[key] = subdivision3AttrIds;
            }
            if (!!subdivision4AttrIds && subdivision4AttrIds.length > 0) {
                const subdivisionType = this.props.getAttributeById(subdivision4AttrIds).type;
                const key = oldSubdivisionMappings[subdivisionType];
                filterParams[key] = subdivision4AttrIds;
            }
            if (!!subdivision5AttrIds && subdivision5AttrIds.length > 0) {
                const subdivisionType = this.props.getAttributeById(subdivision5AttrIds).type;
                const key = oldSubdivisionMappings[subdivisionType];
                filterParams[key] = subdivision5AttrIds;
            }
            this.setState({
                showFilters: false,
            });

            this.props.submitArrestsFilter(filterParams);
        });
    };

    render() {
        return (
            <Popover data-testid="recent-arrest-filter-popover" isOpen={this.state.showFilters}>
                <PopoverTrigger asChild>
                    <IconButton
                        size="sm"
                        onClick={() => this.setState({ showFilters: !this.state.showFilters })}
                        icon="Filter"
                        aria-label={strings.button}
                    />
                </PopoverTrigger>
                <PopoverContent>
                    <VStack>
                        <Filters className={this.props.className}>
                            <Form
                                persist={lifecycleOptions.REGISTER_AND_UNREGISTER}
                                name={FORM_NAME}
                                onSubmit={this.submitForm}
                                render={({ submit }) => (
                                    <div>
                                        <FeatureFlagged
                                            flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED"
                                            fallback={
                                                <div className={this.props.className}>
                                                    <Flex
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        {this.props
                                                            .consortiumDepartmentLinksAvailable && (
                                                            <MFTConsortiumDepartmentSelect
                                                                width={width}
                                                                path="departmentId"
                                                                label={strings.primaryAgency}
                                                            />
                                                        )}
                                                        <MFTAttributeSelect
                                                            label={
                                                                this.props.combinedSubdivisionsLabel
                                                            }
                                                            multiple={true}
                                                            attributeType={[
                                                                AttributeTypeEnum
                                                                    .SUBDIVISION_DEPTH_1.name,
                                                                AttributeTypeEnum
                                                                    .SUBDIVISION_DEPTH_2.name,
                                                                AttributeTypeEnum
                                                                    .SUBDIVISION_DEPTH_3.name,
                                                                AttributeTypeEnum
                                                                    .SUBDIVISION_DEPTH_4.name,
                                                                AttributeTypeEnum
                                                                    .SUBDIVISION_DEPTH_5.name,
                                                            ]}
                                                            width={width}
                                                            path="subdivisionAttrIds"
                                                        />
                                                    </Flex>
                                                </div>
                                            }
                                        >
                                            <Flex flexDirection="column" alignItems="center">
                                                {this.subdivisionShownMap[
                                                    fieldNames
                                                        .LOCATION_ENTITY_LINK_SUBDIVISION_1_ATTR_ID
                                                ] && (
                                                    <MFTAttributeSelect
                                                        attributeType={
                                                            AttributeTypeEnum.SUBDIVISION_DEPTH_1
                                                                .name
                                                        }
                                                        multiple={true}
                                                        includeExpired={true}
                                                        width={width}
                                                        label={this.props.formatFieldByName(
                                                            fieldNames.LOCATION_ENTITY_LINK_LINK_TYPE_ARREST_LOCATION_SUBDIVISION_1_ATTR_ID
                                                        )}
                                                        path="subdivision1AttrIds"
                                                    />
                                                )}
                                                {this.subdivisionShownMap[
                                                    fieldNames
                                                        .LOCATION_ENTITY_LINK_SUBDIVISION_2_ATTR_ID
                                                ] && (
                                                    <MFTAttributeSelect
                                                        attributeType={
                                                            AttributeTypeEnum.SUBDIVISION_DEPTH_2
                                                                .name
                                                        }
                                                        multiple={true}
                                                        includeExpired={true}
                                                        width={width}
                                                        label={this.props.formatFieldByName(
                                                            fieldNames.LOCATION_ENTITY_LINK_LINK_TYPE_ARREST_LOCATION_SUBDIVISION_2_ATTR_ID
                                                        )}
                                                        path="subdivision2AttrIds"
                                                    />
                                                )}
                                                {this.subdivisionShownMap[
                                                    fieldNames
                                                        .LOCATION_ENTITY_LINK_SUBDIVISION_3_ATTR_ID
                                                ] && (
                                                    <MFTAttributeSelect
                                                        attributeType={
                                                            AttributeTypeEnum.SUBDIVISION_DEPTH_3
                                                                .name
                                                        }
                                                        multiple={true}
                                                        includeExpired={true}
                                                        width={width}
                                                        label={this.props.formatFieldByName(
                                                            fieldNames.LOCATION_ENTITY_LINK_LINK_TYPE_ARREST_LOCATION_SUBDIVISION_3_ATTR_ID
                                                        )}
                                                        path="subdivision3AttrIds"
                                                    />
                                                )}
                                                {this.subdivisionShownMap[
                                                    fieldNames
                                                        .LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID
                                                ] && (
                                                    <MFTAttributeSelect
                                                        attributeType={
                                                            AttributeTypeEnum.SUBDIVISION_DEPTH_4
                                                                .name
                                                        }
                                                        multiple={true}
                                                        includeExpired={true}
                                                        width={width}
                                                        label={this.props.formatFieldByName(
                                                            fieldNames.LOCATION_ENTITY_LINK_LINK_TYPE_ARREST_LOCATION_SUBDIVISION_4_ATTR_ID
                                                        )}
                                                        path="subdivision4AttrIds"
                                                    />
                                                )}
                                                {this.subdivisionShownMap[
                                                    fieldNames
                                                        .LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID
                                                ] && (
                                                    <MFTAttributeSelect
                                                        attributeType={
                                                            AttributeTypeEnum.SUBDIVISION_DEPTH_5
                                                                .name
                                                        }
                                                        multiple={true}
                                                        includeExpired={true}
                                                        width={width}
                                                        label={this.props.formatFieldByName(
                                                            fieldNames.LOCATION_ENTITY_LINK_LINK_TYPE_ARREST_LOCATION_SUBDIVISION_5_ATTR_ID
                                                        )}
                                                        path="subdivision5AttrIds"
                                                    />
                                                )}
                                            </Flex>
                                        </FeatureFlagged>
                                        <Flex
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            w="280px"
                                        >
                                            <Button
                                                variant="ghost"
                                                onClick={() => {
                                                    this.setState({ showFilters: false });
                                                }}
                                            >
                                                {strings.cancel}
                                            </Button>
                                            <Button variant="solid" onClick={submit}>
                                                {strings.submit}
                                            </Button>
                                        </Flex>
                                    </div>
                                )}
                            />
                        </Filters>
                    </VStack>
                </PopoverContent>
            </Popover>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    getAttributeById: getAttributeByIdSelector,
    formatFieldByName: formatFieldByNameSelector,
    fieldConfigurationContextByContextAndFieldName: fieldConfigurationContextByContextAndFieldNameSelector,
});

export default connect(mapStateToProps)(RecentArrestsFilters);

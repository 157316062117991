import { NEXUS_STATE_PROP as TOW_VEHICLE_RELEASES_NEXUS_STATE_PROP } from '../../../tow-vehicle-releases/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

export const UPSERT_TOW_VEHICLE_RELEASE_CARD_BUNDLE_ACTION =
    'UPSERT_TOW_VEHICLE_RELEASE_CARD_BUNDLE';

export function replaceTowVehicleReleaseCardBundle({ reportId, towVehicleReleaseCardBundle }) {
    return function (dispatch, getState, { nexus: { withEntityItems } }) {
        const resource = getReportCardBundleResource();
        return resource
            .upsertTowVehicleReleaseCard(reportId, towVehicleReleaseCardBundle)
            .then((updatedTowVehicleReleaseCardBundle) => {
                const { towVehicleRelease } = updatedTowVehicleReleaseCardBundle;

                dispatch(
                    withEntityItems(
                        {
                            [TOW_VEHICLE_RELEASES_NEXUS_STATE_PROP]: [towVehicleRelease],
                        },
                        {
                            type: UPSERT_TOW_VEHICLE_RELEASE_CARD_BUNDLE_ACTION,
                        }
                    )
                );

                return updatedTowVehicleReleaseCardBundle;
            });
    };
}

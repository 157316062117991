import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';

import { compact, map } from 'lodash';
import { NEXUS_STATE_PROP as TOW_VEHICLE_CHECK_INS_NEXUS_STATE_PROP } from '../../../tow-vehicle-check-ins/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '../../../location-entity-links/state/data';
import { towVehicleByReportIdSelector } from '../../../tow-vehicles/state/data';

import getReportCardBundleResource from '../../resources/reportCardBundleResource';

/**
 * `LinkTypes` that are valid for the `LocationEntityLinks`s in an Tow Vehicle Check In Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const TOW_VEHICLE_CHECK_IN_CARD_LOCATION_LINK_TYPES = [LinkTypesEnum.TOW_VEHICLE_STORAGE_LOCATION];

export function replaceTowVehicleCheckInCardBundle({ reportId, towVehicleCheckInCardBundle }) {
    return function (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) {
        const resource = getReportCardBundleResource();
        return resource
            .upsertTowVehicleCheckInCard(reportId, towVehicleCheckInCardBundle)
            .then((updatedTowVehicleCheckInCardBundle) => {
                const state = getState();
                const {
                    towVehicleCheckIn,
                    locationEntityLink,
                } = updatedTowVehicleCheckInCardBundle;

                const { id: towVehicleId } = towVehicleByReportIdSelector(state)(reportId);
                const predicateLocationEntityLinksToRemoveBy = map(
                    TOW_VEHICLE_CHECK_IN_CARD_LOCATION_LINK_TYPES,
                    (linkType) => ({
                        linkType,
                        entityType: EntityTypeEnum.RMS_TOW_VEHICLE.name,
                        entityId: towVehicleId,
                    })
                );

                dispatch(
                    withEntityItems(
                        {
                            [TOW_VEHICLE_CHECK_INS_NEXUS_STATE_PROP]: [towVehicleCheckIn],
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: compact([locationEntityLink]),
                        },
                        withRemoveMultiple(
                            {
                                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: predicateLocationEntityLinksToRemoveBy,
                            },
                            {
                                type: 'UPSERT_TOW_VEHICLE_RELEASE_CARD_BUNDLE',
                            }
                        )
                    )
                );

                return updatedTowVehicleCheckInCardBundle;
            });
    };
}

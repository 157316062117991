import React from 'react';

import styled from 'styled-components';
import createElement from '../../utils/recompose.createElement';

import ImageCell from '../../../../legacy-redux/components/core/tables/elasticPersonTable/ImageCell';
import BiographicalInfoCell from '../../../../legacy-redux/components/core/tables/elasticPersonTable/BiographicalInfoCell';
import IdentifiersCell from '../../../../legacy-redux/components/core/tables/elasticPersonTable/IdentifiersCell';
import { clearfix } from '../../styles/mixins';

const Content = styled.div`
    border-bottom: ${(props) =>
        props.isPrimary ? '0px' : `1px solid ${props.theme.colors.lightGrey}`};
    padding: 20px 0 20px 10px;
    ${clearfix};
`;

const DuplicateImageCell = styled(ImageCell)`
    margin-right: 20px;
    width: ${(props) => (props.isPrimary ? '65px' : '80px')};
    height: ${(props) => (props.isPrimary ? '65px' : '80px')};
    display: inline-block;
`;

const DuplicateInfoCell = styled.div`
    display: inline-block;
    width: ${(props) => (props.isPrimary ? '333px' : '318px')};
    vertical-align: top;
`;

const DuplicateTopCell = styled.div`
    margin-bottom: 8px;
    ${clearfix};
`;

export default function DuplicateNameResult({ elasticPerson, buttonSave, isPrimary }) {
    return (
        <Content isPrimary={isPrimary}>
            <DuplicateImageCell
                primaryMugshotPath={elasticPerson.primaryMugshotPath}
                isPrimary={isPrimary}
                isSmall={isPrimary}
                {...elasticPerson}
            />
            <DuplicateInfoCell isPrimary={isPrimary}>
                <DuplicateTopCell>
                    {buttonSave}
                    {createElement(BiographicalInfoCell, {
                        ...elasticPerson,
                        showModifiedDate: false,
                        showCautions: false,
                    })}
                </DuplicateTopCell>
                {createElement(IdentifiersCell, { ...elasticPerson, inSidePanel: true })}
            </DuplicateInfoCell>
        </Content>
    );
}

import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { filterArrayForInput } from '~/client-common/helpers/filterHelpers';

/**
 * Filters a list of attachments by file name
 */
export function filterAttachmentsByFilename(filterValue, attachments) {
    return filterArrayForInput({
        filterValue,
        models: attachments,
        getFilterValueFromModel: (attachment) => {
            if (attachment.folder) {
                return attachment.folder.name;
            }
            return getAttachmentFile(attachment).originalFileName;
        },
    });
}

import { find } from 'lodash';
import { ElasticPerson } from '@mark43/rms-api';

export const getLineupName = (poiMasterId: number, relatedPersons: ElasticPerson[]): string => {
    const personOfInterest = find(
        relatedPersons,
        (person: ElasticPerson) => person.id === poiMasterId && !person.isJuvenile
    );

    // display 'Unknown' if the name hasn't loaded into redux state yet
    let name = 'Unknown';
    if (personOfInterest?.masterPersonFullName) {
        name = `${personOfInterest?.masterPersonFullName}`;
    } else if (personOfInterest?.firstName) {
        name = `${personOfInterest?.firstName}`;
    } else if (personOfInterest?.lastName) {
        name = `${personOfInterest.lastName}`;
    }

    return name;
};

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FullOffenseCode, RefContextEnum } from '@mark43/rms-api';

import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-codes/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NIBRS_CODE_LINKS_NEXUS_STATE_PROP } from '~/client-common//core/domain/offense-code-nibrs-code-links/state/data';
import { NEXUS_STATE_PROP as UK_OFFENSE_CODE_EXTENSION_NEXUS_STATE_PROP } from '~/client-common/core/domain/uk-offense-code-extensions/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODE_HOC_CATEGORY_LINKS_STATE_PROP } from '~/client-common/core/domain/offense-code-hoc-category-links/state/data';
import getOffenseCodesResource from '~/client-common/core/domain/offense-codes/resources/offenseCodesResource';

import { RmsDispatch } from '../../../../core/typings/redux';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';

export const useGetValidOffenseCodeByNameAndDate = (index: string) => {
    const dispatch: RmsDispatch = useDispatch();
    const { getForm } = useFormGetter();
    const offenseCodesResource = getOffenseCodesResource();
    const onSuccess = useCallback(
        (fullOffenseCode: FullOffenseCode) => {
            const form = getForm(RefContextEnum.FORM_OFFENSE.name, index);

            dispatch((dispatch, getState, dependencies) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [OFFENSE_CODES_NEXUS_STATE_PROP]: [fullOffenseCode.offenseCode],
                            [OFFENSE_CODES_NIBRS_CODE_LINKS_NEXUS_STATE_PROP]:
                                fullOffenseCode.offenseCodeNibrsCodeLinks,
                            [OFFENSE_CODE_HOC_CATEGORY_LINKS_STATE_PROP]:
                                fullOffenseCode.offenseCodeHocCategoryLinks,
                            [UK_OFFENSE_CODE_EXTENSION_NEXUS_STATE_PROP]: [
                                fullOffenseCode.ukOffenseCodesData,
                            ],
                        },
                        { type: 'STORE_TIME_WINDOW_OFFENSE_CODE' }
                    )
                );
            });

            const hocCategories =
                fullOffenseCode.offenseCodeHocCategoryLinks?.map(
                    ({ hocCategoryAttrId }: { hocCategoryAttrId: number }) => hocCategoryAttrId
                ) || [];
            const hocCategory = hocCategories.length === 1 ? hocCategories[0] : undefined;

            form?.set('offense.offenseCodeId', fullOffenseCode.offenseCode.id);
            form?.set('offenseAttributes.UK_OFFENSE_CODE_CATEGORY.attributeIds', hocCategory);
        },
        [dispatch, getForm, index]
    );
    const {
        callResource: callGetValidOffenseCodeByNameAndDate,
        loading: { isLoading: isLoadingValidOffenseCodeByNameAndDate },
    } = useResourceDeferred(offenseCodesResource.getValidOffenseCodeByNameAndDate, onSuccess);
    return { callGetValidOffenseCodeByNameAndDate, isLoadingValidOffenseCodeByNameAndDate };
};

import styled from 'styled-components';

import Radio, { MFTRadio } from '../../../../../../core/forms/components/Radio';

const RadioWithPaddedOptionsStyle = `
    clear: both;

    .radio-group {
        margin-left: 0;
    }

    .radio-option.newline {
        margin-bottom: 13px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const RadioWithPaddedOptions = styled(Radio)`
    ${RadioWithPaddedOptionsStyle}
`;
export const MFTRadioWithPaddedOptions = styled(MFTRadio)`
    ${RadioWithPaddedOptionsStyle}
`;

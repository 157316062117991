import React from 'react';
import { LinkTypesEnum } from '@mark43/rms-api';
import { HStack, Text } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ImportInvolvedProfilesSidePanel } from '../sidepanel/ImportInvolvedProfilesSidePanel';
import { EFileDefendantsGrid } from './EFileDefendantsGrid';

const strings = componentStrings.eFiles.defendants;

const Header = () => {
    return (
        <HStack justify="space-between" align="start">
            <Text variant="headingMd">{strings.header.title}</Text>
            <ImportInvolvedProfilesSidePanel linkType={LinkTypesEnum.DEFENDANT_IN_EFILE} />
        </HStack>
    );
};

export const EFileDefendantsPage = () => {
    return (
        <div>
            <Header />
            <EFileDefendantsGrid />
        </div>
    );
};

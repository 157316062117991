import { createSelector } from 'reselect';
import { mapValues, find, filter, values, map, sortBy, compact } from 'lodash';
import { TrafficCrashPerson, AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';

import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
    ViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { trafficCrashPersonsSelector } from '../data';
import { nameAttributesWhereSelector } from '../../../name-attributes/state/data';
import { trafficCrashPersonOffensesByPersonIdSelector } from '../../../traffic-crash-person-offenses/state/data';
import { entityOrderedAttributesWhereSelector } from '../../../entity-ordered-attributes/state/data';
import { ModuleShape } from '../../../../utils/createNormalizedModule';
import { formatOffenseCodeByIdSelector } from '../../../offense-codes/state/ui';

import { formatAttributeByIdSelector } from '../../../attributes/state/data';

type TrafficCrashPersonViewModelProps = {
    offenseCodes?: string;
    seatingPositionAttrId?: string;
    airbagDeployed?: string;
    driverActions?: string;
    driverLicenseRestrictions?: string;
    conditions?: string;
    drugTestResult?: string;
    nonMotoristSafetyEquipment?: string;
    personTypeAttrId?: string;
    incidentResponderAttrId?: string;
    restraintsAndHelmetsAttrId?: string;
    restraintSystemsImproperUseAttrId?: string;
    ejectionAttrId?: string;
    driverLicenseJurisdictionAttrId?: string;
    driverLicenseClassAttrId?: string;
    driverLicenseCdlAttrId?: string;
    speedingRelatedAttrId?: string;
    alcoholInterlockPresentAttrId?: string;
    distractedByAttrId?: string;
    distractedBySourceAttrId?: string;
    lawEnforcementSuspectsAlcoholUseAttrId?: string;
    alcoholTestStatusAttrId?: string;
    alcoholTestTypeAttrId?: string;
    alcoholTestResultAttrId?: string;
    lawEnforcementSuspectsDrugUseAttrId?: string;
    drugTestStatusAttrId?: string;
    drugTestTypeAttrId?: string;
    medicalTransportSourceAttrId?: string;
    injurySeverityAttrId?: string;
    attemptedAvoidanceManeuverAttrId?: string;
    fatalAlcoholTestTypeAttrId?: string;
    fatalDrugTestTypeAttrId?: string;
    nonMotoristActionPriorToCrashAttrId?: string;
    nonMotoristOriginOrDestinationAttrId?: string;
    nonMotoristContributingActionsAttrId?: string;
    nonMotoristLocationAtTimeOfCrashAttrId?: string;
    initialContactPointOnNonMotoristAttrId?: string;
    cmvLicenseStatusAttrId?: string;
    cmvLicenseComplianceAttrId?: string;
};

type TrafficCrashPersonViewModel = ViewModel<TrafficCrashPerson, TrafficCrashPersonViewModelProps>;

const trafficCrashPersonViewModelsSelector = createSelector(
    trafficCrashPersonsSelector,
    formatAttributeByIdSelector,
    nameAttributesWhereSelector,
    formatOffenseCodeByIdSelector,
    trafficCrashPersonOffensesByPersonIdSelector,
    entityOrderedAttributesWhereSelector,
    (
        trafficCrashPersons,
        formatAttributeById,
        nameAttributesWhere,
        formatOffenseCodeById,
        trafficCrashPersonOffensesByPersonId,
        entityOrderedAttributesWhere
    ): ModuleShape<TrafficCrashPersonViewModel> => {
        const viewModel = buildViewModel<TrafficCrashPerson, TrafficCrashPersonViewModelProps>({
            mappers: [
                allSingleAttributeValuesMapper,
                boolToDisplayMapper,
                ({ personId }) => {
                    const personAttributes = nameAttributesWhere({
                        nameId: personId,
                        entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    });
                    const entityOrderedAttributes = entityOrderedAttributesWhere({
                        entityId: personId,
                        entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    });

                    const airbagDeployed = formatAttributeById(
                        map(
                            filter(
                                personAttributes,
                                (pa) =>
                                    pa.attributeType === AttributeTypeEnum.QC_AIR_BAG_DEPLOYED.name
                            ),
                            'attributeId'
                        )
                    );

                    const driverActions = formatAttributeById(
                        map(
                            sortBy(
                                filter(entityOrderedAttributes, {
                                    attributeType: AttributeTypeEnum.QC_DRIVER_ACTIONS.name,
                                }),
                                'sequenceOrder'
                            ),
                            'attributeId'
                        ),
                        false
                    );

                    const driverLicenseRestrictions = formatAttributeById(
                        map(
                            filter(
                                personAttributes,
                                (pa) =>
                                    pa.attributeType ===
                                    AttributeTypeEnum.QC_DRIVER_LICENSE_RESTRICTIONS.name
                            ),
                            'attributeId'
                        )
                    );

                    const conditions = formatAttributeById(
                        map(
                            filter(
                                personAttributes,
                                (pa) => pa.attributeType === AttributeTypeEnum.QC_CONDITION.name
                            ),
                            'attributeId'
                        )
                    );

                    const drugTestResult = formatAttributeById(
                        map(
                            filter(
                                personAttributes,
                                (pa) =>
                                    pa.attributeType === AttributeTypeEnum.QC_DRUG_TEST_RESULT.name
                            ),
                            'attributeId'
                        )
                    );

                    const nonMotoristSafetyEquipment = formatAttributeById(
                        map(
                            filter(
                                personAttributes,
                                (pa) =>
                                    pa.attributeType ===
                                    AttributeTypeEnum.QC_NON_MOTORIST_SAFETY_EQUIPMENT.name
                            ),
                            'attributeId'
                        )
                    );

                    const crashPersonOffenses = trafficCrashPersonOffensesByPersonId(personId);
                    const offenseCodeIds = compact(map(crashPersonOffenses, 'offenseCodeId'));
                    const offenseCodeOthers = compact(
                        map(crashPersonOffenses, 'offenseCodeOther')
                    ).join(', ');

                    const offenseCodeDisplays = formatOffenseCodeById({
                        id: offenseCodeIds,
                        includeCode: false,
                    });

                    const offenseCodes = compact([offenseCodeDisplays, offenseCodeOthers]).join(
                        ', '
                    );

                    return {
                        airbagDeployed,
                        driverActions,
                        driverLicenseRestrictions,
                        conditions,
                        drugTestResult,
                        nonMotoristSafetyEquipment,
                        offenseCodes,
                    };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(trafficCrashPersons, viewModel);
    }
);

export const trafficCrashPersonViewModelsByPersonIdSelector = createSelector(
    trafficCrashPersonViewModelsSelector,
    (trafficCrashPersonViewModels) => (personId: number) =>
        find(values(trafficCrashPersonViewModels), { personId })
);

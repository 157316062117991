import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { ClientApprovalStatusEnumType } from '@mark43/rms-api';
import { Text } from 'arc';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import CheckboxComponent from '../../../core/forms/components/checkboxes/Checkbox';
import { Checkbox } from '../../../core/forms/components/checkboxes/CheckboxTree';
import { PacketOption } from '../../../exports/core/utils/exportHelpers';
import ApprovalStatusIcon from '../../../records/core/components/ApprovalStatusIcon';
import {
    CollapsibleSectionWithStickyHeader,
    Header,
    HeaderButton,
} from '../../../core/components/CollapsibleSectionWithStickyHeader';

const StyledApprovalStatusIcon = styled(ApprovalStatusIcon)`
    position: relative;
`;

const StyledCheckboxComponent = styled(CheckboxComponent)`
    margin-top: 9px;
`;

const Divider = styled.div`
    clear: both;
    color: lightgrey;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    overflow: hidden;

    .mark43-form-field,
    .mark43-form-label {
        width: 100%;
    }
`;

const StyledCollapsibleSectionWithStickyHeader = styled(CollapsibleSectionWithStickyHeader)`
    ${/* sc-selector */ Header} {
        background: ${(props) => props.theme.colors.white};
        border-top: 0;
        align-items: stretch;
        padding: 10px 10px 12px 0;

        .mark43-form-label {
            width: auto;
        }
        .mark43-form-field {
            margin: 6px 5px 0 0;
            width: auto;
        }
    }
    ${/* sc-selector */ HeaderButton} {
        align-items: stretch;
    }
`;

const Dropdown = styled.div`
    background: ${(props) => props.theme.colors.extraLightGrey};
    padding-top: 10px;
    padding-left: 35px;
    padding-right: 35px;
    overflow: hidden;
    clear: both;
`;

const Indicator = styled.div`
    display: inline-block;
    background-color: ${(props) => props.theme.colors.brightBlue};
    color: ${(props) => props.theme.colors.white};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    border-radius: 4px;
    font-size: var(--arc-fontSizes-sm);
    padding: 2px 5px;
    margin-right: 5px;
    vertical-align: top;
`;

const Label = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface Tree {
    value: string;
    children: Tree[];
}

export default class ReportExportComponent extends React.Component<{
    checked?: boolean;
    display?: string;
    testId?: string;
    privacyDisplay?: string;
    isToggleExpanded: boolean;
    allNodes: PacketOption[];
    changeHandler?: (value: string, newValue: boolean, allNodes: PacketOption[]) => void;
    onExpandCollapseClick: (val: boolean) => void;
    clientApprovalStatus?: ClientApprovalStatusEnumType;
    noteDisplay?: string;
    disabled?: boolean;
    indicatorText?: string;
    isAssociatedRecord?: boolean;
    children: Tree[];
    value: string;
}> {
    render() {
        const subTree = map(this.props.children, (child) => {
            return (
                <Checkbox
                    allNodes={this.props.allNodes}
                    {...child}
                    key={child.value}
                    testId={this.props.testId}
                />
            );
        });

        const subTreeGroup = subTree.length > 0 ? <div>{subTree}</div> : '';

        return (
            <Divider>
                <StyledCollapsibleSectionWithStickyHeader
                    isOpen={this.props.isToggleExpanded}
                    toggleOpen={this.props.onExpandCollapseClick}
                    headerLeftElement={
                        <StyledCheckboxComponent
                            testId={this.props.testId}
                            onChange={this._handleChange.bind(this)}
                            value={this.props.checked}
                            disabled={this.props.disabled}
                        />
                    }
                    renderTitle={() => (
                        <Label>
                            <div>
                                <FeatureFlagged
                                    flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
                                    fallback={
                                        <Text
                                            variant="headingSm"
                                            as="div"
                                            fontWeight="semibold"
                                            color="secondary"
                                        >
                                            {this.props.display} {'  '}
                                            <StyledApprovalStatusIcon
                                                approvalStatus={this.props.clientApprovalStatus}
                                                size={15}
                                                isSealed={false}
                                                isPartiallySealed={false}
                                                isVacated={false}
                                                className={undefined}
                                            />
                                            {!!this.props.indicatorText && (
                                                <span>
                                                    {'  '}
                                                    <Indicator>
                                                        {this.props.indicatorText}
                                                    </Indicator>
                                                </span>
                                            )}
                                        </Text>
                                    }
                                >
                                    <Text
                                        variant="headingSm"
                                        as="div"
                                        fontWeight="semibold"
                                        color="secondary"
                                    >
                                        {this.props.display} {'  '}
                                        <StyledApprovalStatusIcon
                                            approvalStatus={this.props.clientApprovalStatus}
                                            size={15}
                                            isSealed={false}
                                            isPartiallySealed={false}
                                            isVacated={false}
                                            className={undefined}
                                        />
                                        {!!this.props.indicatorText && (
                                            <span>
                                                {'  '}
                                                <Indicator>{this.props.indicatorText}</Indicator>
                                            </span>
                                        )}
                                    </Text>
                                </FeatureFlagged>
                                <Text fontSize="sm" color="secondary" isItalic>
                                    {this.props.privacyDisplay}
                                </Text>
                                <FeatureFlagged
                                    flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
                                    fallback={
                                        <Text fontSize="sm" color="tertiary">
                                            {this.props.noteDisplay}
                                        </Text>
                                    }
                                >
                                    <Text variant="bodyMd">{this.props.noteDisplay}</Text>
                                </FeatureFlagged>
                            </div>
                        </Label>
                    )}
                >
                    <Dropdown>{subTreeGroup}</Dropdown>
                </StyledCollapsibleSectionWithStickyHeader>
            </Divider>
        );
    }

    _handleChange() {
        let newValue: boolean;
        if (this.props.checked === false) {
            newValue = true;
        } else {
            newValue = false;
        }
        if (this.props.changeHandler) {
            this.props.changeHandler(this.props.value, newValue, this.props.allNodes);
        }

        if (this.props.onExpandCollapseClick) {
            this.props.onExpandCollapseClick(newValue);
        }
    }
}

import { createSelector } from 'reselect';
import { Court, CourtUpdateRequest, CourtCreationRequest } from '@mark43/rms-api';
import { InjectedRouter } from 'react-router';
import { chain, first } from 'lodash';
import { courtsSelector, courtsWhereSelector } from '~/client-common/core/domain/courts/state/data';
import {
    COURTS_ADMIN_FORM,
    convertFromFormModel,
    convertToFormModel,
} from '../form/courtsAdminForm';
import { RmsAction } from '../../../../../core/typings/redux';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import {
    createCourt,
    updateCourt,
    LOAD_COURTS_START,
    LOAD_COURTS_SUCCESS,
    LOAD_COURTS_FAIL,
    CREATE_COURT_START,
    CREATE_COURT_SUCCESS,
    CREATE_COURT_FAIL,
    UPDATE_COURT_START,
    UPDATE_COURT_SUCCESS,
    UPDATE_COURT_FAIL,
    CourtActionType,
} from '../data';

export const courtsAdminListSelector = createSelector(
    courtsSelector,
    (courts) => (selectedId: number) => {
        return chain(courts)
            .values()
            .map(({ courtName, id }) => ({
                title: courtName,
                key: id,
                path: `admin/courts/${id}`,
                selected: selectedId === id,
            }))
            .value();
    }
);

export function submitCourtAdminForm(
    selectedId: number | undefined,
    router: InjectedRouter
): RmsAction<Promise<void>> {
    return function (dispatch, getState, { formsRegistry }) {
        const form = formsRegistry.get(COURTS_ADMIN_FORM);

        if (!form) {
            return Promise.resolve();
        }

        return form
            .submit()
            .then(({ form }) => {
                const courtToUpsert = convertFromFormModel(form.getState().model);

                return dispatch(
                    !!selectedId
                        ? updateCourt(courtToUpsert as CourtUpdateRequest)
                        : createCourt(courtToUpsert as CourtCreationRequest)
                ).then((court: Court) => {
                    form.set('', convertToFormModel(court));
                    if (selectedId !== court.id) {
                        router.push(`/admin/courts/${court.id}`);
                    }
                });
            })
            .catch((err: Error) => {
                throw err;
            });
    };
}

export function resetCourtAdminForm(): RmsAction<void> {
    return (dispatch, getState, { formsRegistry }) => {
        formsRegistry.maybeDeferredOperation(COURTS_ADMIN_FORM, undefined, (form) => {
            form.resetUi();
            form.resetModel();
        });
    };
}

export function prefillCourtAdminForm(courtId: number): RmsAction<void> {
    return (dispatch, getState, { formsRegistry }) => {
        dispatch(resetCourtAdminForm());
        const court = first(
            courtsWhereSelector(getState())({
                id: courtId,
            })
        );

        if (!!court) {
            const formModel = convertToFormModel(court);

            formsRegistry.maybeDeferredOperation(COURTS_ADMIN_FORM, undefined, (form) => {
                form.set('', formModel);
            });
        }
    };
}

const courtsAdminSelector = (state: RootState) => state.ui.courtsAdmin;

export const courtsAdminLoadingSelector = createSelector(
    courtsAdminSelector,
    (courtsAdmin) => courtsAdmin.isLoading
);

export const courtsAdminSavingSelector = createSelector(
    courtsAdminSelector,
    (courtsAdmin) => courtsAdmin.isSaving
);

export const courtsAdminErrorMessageSelector = createSelector(
    courtsAdminSelector,
    (courtsAdmin) => courtsAdmin.errorMessage
);

const initialUiState: CourtsAdminState = {
    isSaving: false,
    isLoading: false,
    errorMessage: undefined,
};

type CourtsAdminState = {
    isSaving: boolean;
    isLoading: boolean;
    errorMessage?: string;
};

function courtsAdminUiReducer(state = initialUiState, action: CourtActionType): CourtsAdminState {
    switch (action.type) {
        case CREATE_COURT_START:
        case UPDATE_COURT_START:
            return {
                ...state,
                isSaving: true,
                errorMessage: undefined,
            };
        case CREATE_COURT_SUCCESS:
        case UPDATE_COURT_SUCCESS:
            return {
                ...state,
                isSaving: false,
                errorMessage: undefined,
            };
        case CREATE_COURT_FAIL:
        case UPDATE_COURT_FAIL:
            return {
                ...state,
                errorMessage: action.payload?.errorMessage,
                isSaving: false,
            };
        case LOAD_COURTS_START:
            return {
                ...state,
                isLoading: true,
                errorMessage: undefined,
            };
        case LOAD_COURTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                errorMessage: undefined,
            };
        case LOAD_COURTS_FAIL:
            return {
                ...state,
                errorMessage: action.payload?.errorMessage,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default courtsAdminUiReducer;

import React from 'react';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { hasBannerSelector } from '../../../../../legacy-redux/selectors/alertsSelectors';
import { additionalContentTopSelector } from '../../../../../legacy-redux/selectors/globalSelectors';

const mapStateToProps = createStructuredSelector({
    hasBanner: hasBannerSelector,
    additionalContentTop: additionalContentTopSelector,
});

export default connect(mapStateToProps)(function RecordsSidebar({ children, className }) {
    return (
        <div className={classNames('report-sidebar-wrapper', className)}>
            <div className="report-sidebar">
                <div className="report-sidebar-content">{children}</div>
            </div>
        </div>
    );
});

import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { EntityTypeEnum } from '@mark43/rms-api';

import applicationConfigurationQueryParamsEnum from '~/client-common/core/enums/client/applicationConfigurationQueryParamsEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { formatReportDefinitionByReportIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';
import {
    currentReportIdSelector,
    reportIsLoadingSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import Page from '../../../core/components/Page';
import { AnalyticsContextProviderWithAdditionalData } from '../../../core/context/AnalyticsContext';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import Modal, { OverrideModalPropsFunction } from '../../../core/overlays/components/Modal';
import { SubmissionMessagesProvider } from '../../../submissions/state/context';
import { OverlayBase } from '../../../../core/overlayManager';
import { useViewport } from '../../../core/utils/useViewport';
import ReportPrefillModal from './ReportPrefillModal';
import ReportHeader from './header/ReportHeader';

const { CHROMELESS } = applicationConfigurationQueryParamsEnum;

const PageContainer = styled(Page)`
    display: flex;
`;

// Anytime the report changes, generate a new "session id"
// we use this for analytics tracking, so we can map
// analytics to a particular "session" on the report page
const useCreateReportSessionId = (currentReportId: number) => {
    const [reportSessionId, setReportSessionId] = React.useState(uuidv4());
    // Anytime the report changes, start tracking a new session
    React.useEffect(() => {
        setReportSessionId(uuidv4());
    }, [currentReportId]);

    return reportSessionId;
};

const Report: React.FC<RouteComponentProps<{ reportId: string }, never>> = ({
    router,
    location,
    children,
}) => {
    // context of this component is Report, therefore safe to cast as number
    const currentReportId = useSelector(currentReportIdSelector) as number;
    const formatReportDefinitionByReportId = useSelector(formatReportDefinitionByReportIdSelector);
    const reportIsLoading = useSelector(reportIsLoadingSelector);
    const reportSessionId = useCreateReportSessionId(currentReportId);

    const overrideModalProps: OverrideModalPropsFunction<Record<string, unknown>> = (
        overlayProps
    ) => ({
        // TODO: should have only one thing responsible for managing open state,
        // remove dependency on redux reportIsLoading state
        isOpen: overlayProps.overlayState.isOpen || reportIsLoading,
    });

    const isChromeless = !!location.query[CHROMELESS];

    useViewport();

    return (
        <AnalyticsContextProviderWithAdditionalData
            analyticsKeyToAdd={AnalyticsPropertyEnum.REPORT_SESSION_ID}
            analyticsValueToAdd={reportSessionId}
        >
            <AnalyticsContextProviderWithAdditionalData
                analyticsKeyToAdd={AnalyticsPropertyEnum.REPORT_DEFINITION}
                analyticsValueToAdd={formatReportDefinitionByReportId(currentReportId)}
            >
                <SubmissionMessagesProvider
                    entityId={currentReportId}
                    entityType={EntityTypeEnum.REPORT.name}
                >
                    <PageContainer>
                        <OverlayBase id={overlayIdEnum.REPORT_LOADING_MODAL}>
                            {(reportLoadingModalOverlayState) => {
                                return (
                                    <OverlayBase id={overlayIdEnum.REPORT_PREFILL_MODAL}>
                                        {(reportPrefillModalOverlayState) => {
                                            const hideReport =
                                                reportIsLoading ||
                                                reportLoadingModalOverlayState.overlayState
                                                    .isOpen ||
                                                reportPrefillModalOverlayState.overlayState.isOpen;
                                            return (
                                                !hideReport && (
                                                    <>
                                                        <ReportHeader
                                                            router={router}
                                                            hideAllButtons={isChromeless}
                                                        />
                                                        {children}
                                                    </>
                                                )
                                            );
                                        }}
                                    </OverlayBase>
                                );
                            }}
                        </OverlayBase>
                        <Modal
                            // no button because Modal is opened on route change
                            hideFooter={true}
                            id={overlayIdEnum.REPORT_LOADING_MODAL}
                            overrideModalProps={overrideModalProps}
                        >
                            <div className="mark43-loading-modal-content">
                                <SimpleLoading />
                                <div>Retrieving Report...</div>
                            </div>
                        </Modal>
                        <ReportPrefillModal />
                    </PageContainer>
                </SubmissionMessagesProvider>
            </AnalyticsContextProviderWithAdditionalData>
        </AnalyticsContextProviderWithAdditionalData>
    );
};

export default Report;

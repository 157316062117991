import React from 'react';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import CompletedDateCell from '../../search-results/components/CompletedDateCell';
import StorageLocationCell from '../../search-results/components/StorageLocationCell';
import InventoryTypeCell from '../../search-results/components/InventoryTypeCell';
import MissingItemsCountCell from './MissingItemsCountCell';
import ResponsiblePersonnelCell from './ResponsiblePersonnelCell';

const SearchResultsTableBody = () => (
    <TableBody>
        <CompletedDateCell columnKey="completedDateUtc" />
        <StorageLocationCell columnKey="storageLocationName" />
        <ResponsiblePersonnelCell columnKey="ownerUserId" />
        <InventoryTypeCell columnKey="inventoryType" />
        <MissingItemsCountCell columnKey="missingItemsCount" />
    </TableBody>
);

export default SearchResultsTableBody;

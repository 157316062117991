import { ElasticPerson } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'elasticPersons';
const elasticPersonsModule = createNormalizedModule<ElasticPerson>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const elasticPersonsSelector = elasticPersonsModule.selectors.entitiesSelector;

// REDUCER
export default elasticPersonsModule.reducerConfig;

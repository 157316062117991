import { BlacksmithTagEnum } from '@mark43/partnerships-api';
import { createSelector } from 'reselect';
import _ from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    integrationsSelector,
    deleteIntegration,
    storeIntegrations,
} from '~/client-common/core/domain/integrations/state/data';
import { integrationJobRunsSelector } from '~/client-common/core/domain/integration-job-runs/state/data';
import { commandStatusHistoriesWhereSelector } from '~/client-common/core/domain/command-status-histories/state/data';
import {
    openBox,
    closeBox,
    saveBoxSuccess,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import { BLACKSMITH_INTEGRATION_FORM } from '../forms';
import formsRegistry from '../../../../../core/formsRegistry';
import resource from '../../resources/blacksmithIntegrationResource';
import boxActionTypes from '../../../../../legacy-redux/actions/types/boxActionTypes';
import {
    DELETE_BLACKSMITH_INTEGRATION_START,
    deleteBlacksmithIntegrationStart,
    DELETE_BLACKSMITH_INTEGRATION_SUCCESS,
    deleteBlacksmithIntegrationSuccess,
    KICK_OFF_BLACKSMITH_INTEGRATION_START,
    kickOffBlacksmithIntegrationStart,
    KICK_OFF_BLACKSMITH_INTEGRATION_SUCCESS,
    kickOffBlacksmithIntegrationSuccess,
} from '../data';

const BLACKSMITH_INTEGRATION_BOX_CONTEXT = { name: boxEnum.BLACKSMITH_INTEGRATION_SIDE_PANEL };
const DELETE_ROLE_CONFIRMATION_MODAL_CONTEXT = {
    name: boxEnum.BLACKSMITH_INTEGRATION_DELETION_MODAL,
};
const KICK_OFF_CONFIRMATION_MODAL_CONTEXT = {
    name: boxEnum.BLACKSMITH_INTEGRATION_KICK_OFF_CONFIRMATION_MODAL,
};

const getDefaultApiUrl = (path) => `${window.location.origin}/${path}/api`;

export const defaultIntegrationPrefill = {
    serializedInput: '{}',
    createNewUser: false,
    rmsApiBaseUrl: getDefaultApiUrl('rms'),
    cadApiBaseUrl: getDefaultApiUrl('cad'),
    evidenceApiBaseUrl: getDefaultApiUrl('evidence'),
    dataExchangeApiBaseUrl: getDefaultApiUrl('dex'),
    notificationsApiBaseUrl: getDefaultApiUrl('notification'),
    partnershipsApiBaseUrl: getDefaultApiUrl('partnerships'),
    timeout: 'PT10M',
    tags: [BlacksmithTagEnum.SQL.name],
};

export function closeBlacksmithIntegrationSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(BLACKSMITH_INTEGRATION_BOX_CONTEXT));
    };
}
export function openBlacksmithIntegrationSidePanel(integration, id) {
    return (dispatch, getState) => {
        dispatch(
            openBox(BLACKSMITH_INTEGRATION_BOX_CONTEXT, { prefill: integration, isEdit: !!id })
        );
        const state = getState();
        // let form render on initial open
        setTimeout(() => {
            const integrationForm = formsRegistry.get(BLACKSMITH_INTEGRATION_FORM);
            const integrationPrefill = blacksmithIntegrationPrefillSelector(state);
            const currentUserDepartmentId = currentUserDepartmentIdSelector(state);

            integrationForm.set('', {
                ...defaultIntegrationPrefill,
                ...integrationPrefill,
                ...(id
                    ? { id }
                    : {
                          id: null,
                          departmentId: currentUserDepartmentId,
                      }),
            });
        }, 0);
    };
}

export function openDeleteConfirmationModal(blacksmithIntegrationId) {
    return (dispatch) => {
        dispatch(openBox(DELETE_ROLE_CONFIRMATION_MODAL_CONTEXT));
        dispatch(deleteBlacksmithIntegrationStart(blacksmithIntegrationId));
    };
}

export function closeDeleteConfirmationModal() {
    return (dispatch) => {
        dispatch(closeBox(DELETE_ROLE_CONFIRMATION_MODAL_CONTEXT));
    };
}

export function openJobKickoffConfirmationModal(blacksmithIntegrationId) {
    return (dispatch) => {
        dispatch(openBox(KICK_OFF_CONFIRMATION_MODAL_CONTEXT));
        dispatch(kickOffBlacksmithIntegrationStart(blacksmithIntegrationId));
    };
}

function closeJobKickoffConfirmationModal() {
    return (dispatch) => {
        dispatch(closeBox(KICK_OFF_CONFIRMATION_MODAL_CONTEXT));
    };
}

export function submitBlacksmithIntegrationForm(isEdit) {
    return (dispatch) => {
        const integrationForm = formsRegistry.get(BLACKSMITH_INTEGRATION_FORM);
        const integration = integrationForm.get();
        const saveIntegration = isEdit ? resource.updateIntegration : resource.createIntegration;

        saveIntegration(integration)
            .then(() => {
                dispatch(saveBoxSuccess(BLACKSMITH_INTEGRATION_BOX_CONTEXT));
                dispatch(storeIntegrations([integration]));
                integrationForm.resetModel();
            })
            .catch(() => dispatch(saveBoxFailure(BLACKSMITH_INTEGRATION_BOX_CONTEXT)));
    };
}

export function kickOffJobAsync() {
    return (dispatch, getState) => {
        const state = getState();
        const blacksmithIntegrationId = blacksmithIntegrationToKickOffIdSelector(state);

        resource
            .kickOffIntegrationAsync(blacksmithIntegrationId)
            .then(() => {
                dispatch(kickOffBlacksmithIntegrationSuccess());
                dispatch(closeJobKickoffConfirmationModal());
            })
            .catch(() => {
                dispatch(closeJobKickoffConfirmationModal());
            });
    };
}

export function deleteBlacksmithIntegration() {
    return (dispatch, getState) => {
        const state = getState();
        const blacksmithIntegrationId = blacksmithIntegrationToDeleteIdSelector(state);

        resource
            .deleteIntegration(blacksmithIntegrationId)
            .then(() => {
                dispatch(deleteBlacksmithIntegrationSuccess());
                dispatch(deleteIntegration(blacksmithIntegrationId));
                dispatch(closeDeleteConfirmationModal());
            })
            .catch(() => {
                dispatch(closeDeleteConfirmationModal());
            });
    };
}

const blacksmithUiSelector = (state) => state.ui.blacksmith;

const blacksmithIntegrationPrefillSelector = createSelector(
    blacksmithUiSelector,
    (blacksmithUi) => blacksmithUi.integrationPrefill
);

export const isBlacksmithIntegrationSidePanelEditSelector = createSelector(
    blacksmithUiSelector,
    (blacksmithUi) => blacksmithUi.isSidePanelEdit
);

const blacksmithIntegrationToDeleteIdSelector = createSelector(
    blacksmithUiSelector,
    (blacksmithUi) => blacksmithUi.blacksmithIntegrationToDeleteId
);

export const blacksmithIntegrationToDeleteSelector = createSelector(
    integrationsSelector,
    blacksmithIntegrationToDeleteIdSelector,
    (integrations, blacksmithIntegrationToDeleteId) => {
        return integrations[blacksmithIntegrationToDeleteId];
    }
);

const statusHistoriesForJobRunSelector = createSelector(
    commandStatusHistoriesWhereSelector,
    (commandStatusHistoryWhere) => (commandRecordId) => {
        return commandStatusHistoryWhere({ commandRecordId });
    }
);

export const integrationJobRunsForIntegrationByIdSelector = createSelector(
    integrationJobRunsSelector,
    statusHistoriesForJobRunSelector,
    (integrationJobRuns, getStatusHistoriesForJobRun) => (integrationId) => {
        const integrationJobRunsArray = _.filter(integrationJobRuns, { integrationId });
        const sentryArmadaUrl =
            'https://sentry.mark43.io/organizations/mark43/issues/?query=is%3Aunresolved+command_record_id%3A';

        const mappedArray = _.map(integrationJobRunsArray, (item) => {
            const commandStatusHistories = getStatusHistoriesForJobRun(item.commandRecordId);
            return {
                ...item,
                commandStatusHistories,
                sentryLink: `${sentryArmadaUrl}${item.commandRecordId}`,
            };
        });
        return mappedArray;
    }
);

const blacksmithIntegrationToKickOffIdSelector = createSelector(
    blacksmithUiSelector,
    (blacksmithUi) => blacksmithUi.blacksmithIntegrationToKickOffId
);

const initialState = {
    blacksmithIntegrationToDeleteId: null,
    blacksmithIntegrationToKickOffId: null,
    integrationPrefill: {},
    isSidePanelEdit: false,
};

export default function blacksmithUiReducer(state = initialState, action) {
    switch (action.type) {
        case boxActionTypes.OPEN_BOX:
            if (
                action.meta &&
                action.meta.name === boxEnum.BLACKSMITH_INTEGRATION_SIDE_PANEL &&
                action.payload
            ) {
                return {
                    ...state,
                    integrationPrefill: action.payload.prefill,
                    isSidePanelEdit: action.payload.isEdit,
                };
            } else {
                return state;
            }
        case boxActionTypes.CLOSE_BOX:
            if (action.meta && action.meta.name === boxEnum.BLACKSMITH_INTEGRATION_SIDE_PANEL) {
                return {
                    ...state,
                    integrationPrefill: {},
                    isSidePanelEdit: false,
                };
            } else {
                return state;
            }
        case DELETE_BLACKSMITH_INTEGRATION_START:
            return {
                ...state,
                blacksmithIntegrationToDeleteId: action.payload.blacksmithIntegrationId,
            };
        case DELETE_BLACKSMITH_INTEGRATION_SUCCESS:
            return {
                ...state,
                blacksmithIntegrationToDeleteId: null,
            };
        case KICK_OFF_BLACKSMITH_INTEGRATION_START:
            return {
                ...state,
                blacksmithIntegrationToKickOffId: action.payload.blacksmithIntegrationId,
            };
        case KICK_OFF_BLACKSMITH_INTEGRATION_SUCCESS:
            return {
                ...state,
                blacksmithIntegrationToKickOffId: null,
            };
        default:
            return state;
    }
}

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import { filter } from 'lodash';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';
import { PersonLabels as _PersonLabels } from '../../../../../modules/core/components/tags/PersonLabels';
import { PersonEnhancedCautions as _PersonEnhancedCautions } from '../../../../../modules/core/cautions/components/PersonEnhancedCautions';

const strings = componentStrings.core.tables.elasticPersonTable.BiographicalInfoCell;

const AgencyContainer = styled.div`
    margin-top: 10px;
`;
const AgencyText = styled.span`
    color: ${(props) => props.theme.colors.lightGrey};
    text-transform: uppercase;
`;

const PersonLabels = styled(_PersonLabels)`
    margin-top: 6px;
`;

const PersonEnhancedCautions = styled(_PersonEnhancedCautions)`
    margin-top: 6px;
`;

function BiographicalInfoCell({
    elasticPerson,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}) {
    /*
    const {
        showModifiedDate = true,
        ...elasticPerson
    } = props
    *
    * This is what I wanted to do, but Symbol does not get carried over in object spreading
    * https://github.com/facebook/react/issues/7552
    *
    * Additionally, lodash's omit doesn't copy symbols until 4.6.1, and we're on 3.10.0 (8/24/17)
    * https://github.com/lodash/lodash/issues/2081
    */
    const {
        showModifiedDate = true,
        showCautions = true,
        nameAttributes,
        isJuvenile,
        hasPotentialActiveWarrant,
        isSuspectedGangMember,
        departmentId,
        updatedDateUtc,
        isVulnerable,
        dateVulnerableTo,
        cautions,
    } = elasticPerson;
    const { fullName, nicknames } = getViewModelProperties(elasticPerson);
    const isExternalAgency = departmentId !== currentUserDepartmentId;
    const departmentName = isExternalAgency
        ? departmentNameFromConsortiumLinksByDepartmentId(departmentId)
        : undefined;

    return (
        <div className="elastic-person-biographical-info">
            <div className="elastic-person-name">
                <span>{fullName}</span>
            </div>
            {nicknames && <div className="elastic-person-nicknames">{`aka: ${nicknames}`}</div>}
            {showCautions && (
                <FeatureFlagged
                    flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <PersonLabels
                            elasticNameAttributes={filter(nameAttributes, {
                                nameAttrDetail: {
                                    type: AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
                                },
                            })}
                            isJuvenile={isJuvenile}
                            isVulnerable={isVulnerable}
                            dateVulnerableTo={dateVulnerableTo}
                            hasPotentialActiveWarrant={hasPotentialActiveWarrant}
                            isSuspectedGangMember={isSuspectedGangMember}
                            size="md"
                        />
                    }
                >
                    <PersonEnhancedCautions
                        cautions={cautions}
                        isJuvenile={isJuvenile}
                        isVulnerable={isVulnerable}
                        dateVulnerableTo={dateVulnerableTo}
                        hasPotentialActiveWarrant={hasPotentialActiveWarrant}
                        isSuspectedGangMember={isSuspectedGangMember}
                    />
                </FeatureFlagged>
            )}
            {showModifiedDate && (
                <div className="elastic-person-modified-date-container">
                    <span className="elastic-person-modified-text">{strings.modified}: </span>
                    <span className="elastic-person-modified-date">
                        <FormattedDate
                            date={updatedDateUtc}
                            format={FormattedDate.FORMATS.FORM_DATE}
                        />
                    </span>
                </div>
            )}
            {departmentName && (
                <AgencyContainer>
                    <AgencyText>{strings.agency}: </AgencyText>
                    <span>{departmentName}</span>
                </AgencyContainer>
            )}
        </div>
    );
}

export default compose(
    mapProps((ownerProps) => ({ elasticPerson: ownerProps })),
    connect(
        createStructuredSelector({
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
        })
    )
)(BiographicalInfoCell);

import _, { parseInt } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { cadServiceRotationProvidersSelector } from '~/client-common/core/domain/cad-service-rotation-providers/state/data';
import { cadServiceTypesSelector } from '~/client-common/core/domain/cad-service-rotation-types/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    processHistoryEvent,
    processChangeSet,
} from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';

import { currentDepartmentDateFormatsSelector } from '~/client-common/core/domain/current-user/state/ui';
import { formatUserByIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';

import { routeNameSelector } from '../../../../../routing/routerModule';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';

import cadServiceProvidersAdminForm from '../forms/AdminForm';
import {
    LOAD_CAD_SERVICE_PROVIDER_START,
    LOAD_CAD_SERVICE_PROVIDER_SUCCESS,
    LOAD_CAD_SERVICE_PROVIDER_FAILURE,
    SAVE_CAD_SERVICE_PROVIDER_START,
    SAVE_CAD_SERVICE_PROVIDER_SUCCESS,
    SAVE_CAD_SERVICE_PROVIDER_FAILURE,
    FETCH_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS,
    FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS,
    LOAD_CAD_SERVICE_PROVIDER_TYPE_START,
    LOAD_CAD_SERVICE_PROVIDER_TYPE_SUCCESS,
    LOAD_CAD_SERVICE_PROVIDER_TYPE_FAILURE,
} from '../data';
import authActionTypes from '../../../../../legacy-redux/actions/types/authActionTypes';

const { BOOTSTRAP_SUCCESS } = authActionTypes;
const SELECT_CAD_SERVICE_PROVIDER_START =
    'cad-service-rotation-providers/SELECT_CAD_SERVICE_PROVIDER_START';
const SELECT_CAD_SERVICE_PROVIDER_SUCCESS =
    'cad-service-rotation-providers/SELECT_CAD_SERVICE_PROVIDER_SUCCESS';
const SELECT_CAD_SERVICE_PROVIDER_FAILURE =
    'cad-service-rotation-providers/SELECT_CAD_SERVICE_PROVIDER_FAILURE';
const OPEN_NEW_CAD_SERVICE_PROVIDER_FORM =
    'cad-service-rotation-providers/OPEN_NEW_CAD_SERVICE_PROVIDERS_FORM';
const ADD_SELECTED_TYPE_CAPABILITY =
    'cad-service-rotation-providers/ADD_SELECTED_PROVIDER_CAPABILITY';
const ADD_NEW_LOCATION = 'cad-service-rotation-providers/ADD_NEW_LOCATION';
const CLEAR_CAD_SERVICE_PROVIDER_DEFAULT_ADDRESS =
    'cad-service-rotation-providers/CLEAR_CAD_SERVICE_PROVIDER_DEFAULT_ADDRESS';
const SELECT_CAD_SERVICE_PROVIDER_TYPE_START =
    'cad-service-rotation-providers/SELECT_CAD_SERVICE_PROVIDER_TYPE_START';
const SELECT_CAD_SERVICE_PROVIDER_TYPE_SUCCESS =
    'cad-service-rotation-providers/SELECT_CAD_SERVICE_PROVIDER_TYPE_SUCCESS';
const SELECT_CAD_SERVICE_PROVIDER_TYPE_FAILURE =
    'cad-service-rotation-providers/SELECT_CAD_SERVICE_PROVIDER_TYPE_FAILURE';
const UPDATE_CAD_SERVICE_PROVIDER = 'cad-service-rotation-providers/UPDATE_CAD_SERVICE_PROVIDER';

export function addNewLocation(location) {
    const locationId = location.id;
    return (dispatch) => {
        dispatch(
            cadServiceProvidersAdminForm.actionCreators.changePath(
                'cadServiceProvider.locationId',
                locationId
            )
        );
    };
}

function selectCadServiceProviderStart() {
    return {
        type: SELECT_CAD_SERVICE_PROVIDER_START,
    };
}

function selectCadServiceProviderSuccess(id) {
    return {
        type: SELECT_CAD_SERVICE_PROVIDER_SUCCESS,
        payload: id,
    };
}

function selectCadServiceProviderFailure() {
    return {
        type: SELECT_CAD_SERVICE_PROVIDER_FAILURE,
    };
}

function selectCadServiceProviderTypeStart() {
    return {
        type: SELECT_CAD_SERVICE_PROVIDER_TYPE_START,
    };
}

function selectCadServiceProviderTypeSuccess(id) {
    return {
        type: SELECT_CAD_SERVICE_PROVIDER_TYPE_SUCCESS,
        payload: id,
    };
}

function selectCadServiceProviderTypeFailure() {
    return {
        type: SELECT_CAD_SERVICE_PROVIDER_TYPE_FAILURE,
    };
}

export function updateCadServiceProvider(updatedServiceProvider) {
    return {
        type: UPDATE_CAD_SERVICE_PROVIDER,
        payload: updatedServiceProvider,
    };
}

export function selectCadServiceType(id) {
    return (dispatch, getState) => {
        dispatch(selectCadServiceProviderTypeStart());
        const state = getState();
        const selectedType = cadServiceTypesSelector(state)[id];

        if (selectedType) {
            dispatch(selectCadServiceProviderTypeSuccess(id));
        } else {
            // TODO: remove this crappy interval when migrating the router, this is
            // a workaround for onEnter/onLeave hooks not respecting parent/child relationships
            let attempts = 0;

            const intervalId = window.setInterval(() => {
                const state = getState();
                const cadServiceType = cadServiceTypesSelector(state)[id];

                if (cadServiceType) {
                    window.clearInterval(intervalId);
                    dispatch(selectCadServiceProviderTypeSuccess(id));
                }

                attempts++;
                if (attempts > 300) {
                    window.clearInterval(intervalId);

                    return dispatch(
                        selectCadServiceProviderTypeFailure('Failed to get cad service type.')
                    );
                }
            }, 1000);
        }
    };
}

export function selectCadServiceProvider(typeId, id) {
    return (dispatch, getState) => {
        dispatch(selectCadServiceProviderStart());
        const state = getState();
        const cadServiceProvider = cadServiceRotationProvidersSelector(state)[id];

        if (cadServiceProvider) {
            dispatch(cadServiceProvidersAdminForm.actionCreators.change({ cadServiceProvider }));
            dispatch(
                cadServiceProvidersAdminForm.actionCreators.changePath(
                    'cadServiceProvider.locationId',
                    cadServiceProvider.locationId
                )
            );
            dispatch(selectCadServiceProviderSuccess(id));
        } else {
            // TODO: remove this crappy interval when migrating the router, this is
            // a workaround for onEnter/onLeave hooks not respecting parent/child relationships
            let attempts = 0;

            const intervalId = window.setInterval(() => {
                const state = getState();
                const cadServiceProvider = cadServiceRotationProvidersSelector(state)[id];

                if (cadServiceProvider) {
                    window.clearInterval(intervalId);
                    dispatch(
                        cadServiceProvidersAdminForm.actionCreators.change({ cadServiceProvider })
                    );
                    dispatch(
                        cadServiceProvidersAdminForm.actionCreators.changePath(
                            'cadServiceProvider.locationId',
                            cadServiceProvider.locationId
                        )
                    );
                    dispatch(selectCadServiceProviderSuccess(id));
                }

                attempts++;
                if (attempts > 300) {
                    window.clearInterval(intervalId);

                    return dispatch(
                        selectCadServiceProviderFailure('Failed to get cad service type.')
                    );
                }
            }, 1000);
        }
    };
}

export function openNewCadServiceProviderForm() {
    return {
        type: OPEN_NEW_CAD_SERVICE_PROVIDER_FORM,
    };
}

const initialUiState = {
    selectedLocationId: null,
    serviceProviders: [],
    serviceTypes: [],
    selectedCadServiceType: null,
    selectedCadServiceTypeId: null,
    selectedCadServiceProvider: null,
    selectedCadServiceProviderId: null,
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    cadServiceProviderLoadError: null,
    histories: {},
    allHistories: [], // this is a stop-gap solution. Normally we would normalize all of these by serviceProvider id or
    // but there is no consistent way to discern the serviceProvider id in history objects.
};

export default function cadServiceProvidersAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case BOOTSTRAP_SUCCESS:
            return {
                ...state,
            };
        case ADD_NEW_LOCATION:
            return {
                ...state,
                selectedLocationId: action.payload,
            };
        case FETCH_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS:
            return {
                ...state,
                histories: action.payload.histories,
            };
        case FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS:
            return {
                ...state,
                allHistories: action.payload.histories,
            };
        case LOAD_CAD_SERVICE_PROVIDER_TYPE_START:
            return {
                ...state,
                loadingList: true,
                selectedCadServiceProviderTypeId: null,
                selectedCadServiceProviderType: null,
                listError: null,
            };
        case LOAD_CAD_SERVICE_PROVIDER_TYPE_SUCCESS:
            return {
                ...state,
                serviceTypes: action.payload,
                loadingList: false,
            };
        case LOAD_CAD_SERVICE_PROVIDER_TYPE_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case LOAD_CAD_SERVICE_PROVIDER_START:
            return {
                ...state,
                loadingList: true,
                selectedCadServiceProviderId: null,
                selectedCadServiceProvider: null,
                listError: null,
            };
        case LOAD_CAD_SERVICE_PROVIDER_SUCCESS:
            return {
                ...state,
                serviceProviders: action.payload,
                loadingList: false,
            };
        case LOAD_CAD_SERVICE_PROVIDER_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case SAVE_CAD_SERVICE_PROVIDER_START:
            return {
                ...state,
                formError: null,
                submittingForm: true,
            };
        case SAVE_CAD_SERVICE_PROVIDER_SUCCESS:
            return {
                ...state,
                formError: null,
                submittingForm: false,
            };
        case SAVE_CAD_SERVICE_PROVIDER_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_CAD_SERVICE_PROVIDER_TYPE_SUCCESS:
            const selectedCadServiceTypeId = parseInt(action.payload);
            return {
                ...state,
                formError: null,
                loadingList: false,
                cadServiceTypeLoadError: null,
                selectedCadServiceTypeId,
                selectedCadServiceType: state.serviceTypes
                    ? state.serviceTypes.find((item) => {
                          return item.id === selectedCadServiceTypeId;
                      })
                    : null,
            };
        case SELECT_CAD_SERVICE_PROVIDER_TYPE_FAILURE:
            return {
                ...state,
                loadingList: false,
                cadServiceTypeLoadError: action.payload,
            };
        case SELECT_CAD_SERVICE_PROVIDER_SUCCESS:
            const selectedCadServiceProviderId = parseInt(action.payload);
            return {
                ...state,
                formError: null,
                loadingList: false,
                cadServiceProviderLoadError: null,
                selectedCadServiceProviderId,
                selectedCadServiceProvider: state.serviceProviders.find((item) => {
                    return item.id === selectedCadServiceProviderId;
                }),
            };
        case SELECT_CAD_SERVICE_PROVIDER_FAILURE:
            return {
                ...state,
                cadServiceProviderLoadError: action.payload,
            };
        case OPEN_NEW_CAD_SERVICE_PROVIDER_FORM:
            return {
                ...state,
                formError: null,
                cadServiceTypeLoadError: null,
                cadServiceProviderLoadError: null,
                selectedCadServiceProviderId: null,
                selectedCadServiceProvider: null,
            };
        case CLEAR_CAD_SERVICE_PROVIDER_DEFAULT_ADDRESS:
            return {
                ...state,
                selectedLocationId: null,
            };
        case UPDATE_CAD_SERVICE_PROVIDER:
            const updatedServiceProviders = state.serviceProviders.map((provider) => {
                if (provider.id !== action.payload.id) {
                    return provider;
                }

                return action.payload;
            });

            return {
                ...state,
                serviceProviders: updatedServiceProviders,
                selectedCadServiceProvider: action.payload,
            };
        case ADD_SELECTED_TYPE_CAPABILITY:
            return {
                ...state,
                serviceTypes: [
                    ...state.serviceTypes,
                    ...state.serviceTypes
                        .find((item) => {
                            return item.id === state.selectedCadServiceTypeId;
                        })
                        .Capabilities.push({ code: '', name: '' }),
                ],
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.cadServiceProviders;

export const cadServiceProvidersViewModelByIdSelector = createSelector(
    cadServiceRotationProvidersSelector,
    (cadServiceProviders) => (id) => {
        return cadServiceProviders[id]
            ? {
                  ...cadServiceProviders[id],
              }
            : undefined;
    }
);

// all service types as admin list items, to be used for filtering providers
// by type; pass this array into the `items` prop of `AdminList`
export const serviceTypesAdminListItemsSelector = createSelector(
    uiSelector,
    routeNameSelector,
    applicationSettingsSelector,
    (ui, routeName, { ENHANCED_SERVICE_ROTATION: isEnhancedServiceRotationEnabled }) => {
        if (!ui.serviceTypes || ui.serviceTypes.length === 0) {
            return [];
        }
        const t = ui.serviceTypes;
        return _(t)
            .map((type) => {
                const selected = type.id === ui.selectedCadServiceTypeId;
                const newProviderSelected =
                    routeName ===
                    `${
                        isEnhancedServiceRotationEnabled ? 'ENHANCED_' : ''
                    }ADMIN_CAD_SERVICE_PROVIDERS_NEW`;
                const pathSuffix =
                    selected && ui.selectedServiceProviderId && !newProviderSelected
                        ? `/${ui.selectedServiceProviderId}`
                        : '';
                return {
                    path: `/admin/cad/cad-service-rotation-providers/${
                        isEnhancedServiceRotationEnabled ? 'enhanced/provider' : type.id
                    }${pathSuffix}`,
                    title: `${type.code.toUpperCase()}-${type.name.toUpperCase()}`,
                    subtitle: type.description,
                    key: type.id,
                    selected,
                    serviceType: type,
                };
            })
            .sortBy('title')
            .value();
    }
);

// list of cad serviceProviders
export const serviceProvidersListItemsSelector = createSelector(
    uiSelector,
    cadServiceRotationProvidersSelector,
    routeNameSelector,
    applicationSettingsSelector,
    (
        ui,
        serviceProviders,
        routeName,
        { ENHANCED_SERVICE_ROTATION: isEnhancedServiceRotationEnabled }
    ) => {
        return _(serviceProviders)
            .map((serviceProvider) => {
                const historyView = routeName === 'ADMIN_CAD_ALL_SERVICE_PROVIDERS_HISTORY';
                const path = `/admin/cad/cad-service-rotation-providers/${
                    isEnhancedServiceRotationEnabled
                        ? 'enhanced/provider'
                        : serviceProvider.rotationServiceTypeId
                }/${serviceProvider.id}${historyView ? '/history' : ''}`;

                return serviceProvider
                    ? {
                          path,
                          title: serviceProvider.name.toUpperCase(),
                          subtitle: serviceProvider.description,
                          key: serviceProvider.id,
                          selected: serviceProvider.id === ui.selectedCadServiceProviderId,
                          rotationServiceTypeId: serviceProvider.rotationServiceTypeId,
                      }
                    : null;
            })
            .sortBy('title')
            .value();
    }
);

export const historiesForCadServiceProviderSelector = createSelector(
    uiSelector,
    formatAttributeByIdSelector,
    formatUserByIdSelector,
    getAttributeByIdSelector,
    currentDepartmentDateFormatsSelector,
    (ui, formatAttributeById, userById, getAttributeById, dateTimeFormats) => {
        const histories = ui.histories ? ui.histories : [];

        const displayDate = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDate);
            }
            return '';
        };

        const displayDateTime = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDateTime);
            }
            return '';
        };

        const args = {
            formatAttributeById,
            userById,
            displayDate,
            displayDateTime,
            attributeById: getAttributeById,
        };

        const historiesReturn = _.map(_.sortBy(histories, 'timestampUtc'), (history) => {
            const processed = processHistoryEvent(history, args);
            const newObj = {
                ...processed,
                user: userById(processed.changedBy),
                changes: _.map(processed.changeSet, (changeSet) =>
                    processChangeSet(changeSet, args, processed)
                ),
            };

            return newObj;
        }).reverse();

        return historiesReturn;
    }
);

export const hasImportExportServiceProviderPermissions = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.CAD.MANAGE_CAD_SERVICE_ROTATION)
);

import $ from 'jquery';
import keyMirror from 'keymirror';
import { map, find, forEach } from 'lodash';
import { FileServerFile, FileUploadIntermediate, FileUploadResponse } from '@mark43/rms-api';
import * as Sentry from '@sentry/browser';
import componentStrings from '~/client-common/core/strings/componentStrings';

const strings = componentStrings.attachments.upload;

const MAX_FILE_SIZE = 3221225472;

export const UploadErrorType = keyMirror({
    UPLOAD_FAILURE: null,
    MAXIMUM_EXCEEDED: null,
    UPLOAD_DENIED: null,
    MAX_FILE_SIZE_EXCEEDED: null,
});

interface S3FileUploadType extends FileUploadIntermediate {
    preview: string;
    file: File;
}

export const getUploadResponsesFromUploadIntermediates = (
    fileUploadResponses: FileUploadResponse[],
    successfulFileUploadIntermediates: S3FileUploadType[],
    fileServerFiles?: FileServerFile[]
) => {
    if (fileServerFiles) {
        return map(fileUploadResponses, (fileUploadResponse, i) => {
            const preview = find(successfulFileUploadIntermediates, (sfui) => {
                return sfui.fileServerFile.id === fileServerFiles[i].id;
            })?.preview;

            return {
                ...fileUploadResponse,
                preview,
            };
        });
    } else {
        return map(fileUploadResponses, (fileUploadResponse, i) => {
            const preview = find(successfulFileUploadIntermediates, (sfui) => {
                return sfui.fileServerFile.id === fileUploadResponses[i].file.fileServerId;
            })?.preview;

            return {
                ...fileUploadResponse,
                preview,
            };
        });
    }
};

export function validateUploadBegin(
    fileUploadIntermediates: FileUploadIntermediate[],
    fileNames: string[]
) {
    // do check and error logging here
    if (fileUploadIntermediates.length !== fileNames.length) {
        Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.Error);
            scope.setExtra('fileNames', fileNames);
            scope.setExtra('fileUploadIntermediates', fileUploadIntermediates);
            Sentry.captureMessage(
                'The files names and file upload intermediates from the server in upload/begin are different lengths'
            );
        });
        throw new Error('Error uploading files');
    }
}

// ajax promise for s3 upload here
export function uploadFileToS3(
    viewFileUploadIntermediate: S3FileUploadType,
    onFinish: (viewFileUploadIntermediate: S3FileUploadType) => void
) {
    // For unclear reasons, $.ajax does not return a Promise
    return new Promise((resolve, reject) =>
        $.ajax({
            contentType: viewFileUploadIntermediate.contentType,
            ...(!viewFileUploadIntermediate.isAzure && {
                headers: {
                    'x-amz-server-side-encryption': 'AES256',
                },
            }),
            processData: false,
            method: 'PUT',
            url: viewFileUploadIntermediate.url,
            data: viewFileUploadIntermediate.file,
        })
            .done(() => {
                // If we want a method to run on every file upload success
                // we'll have to change how this component is used because we don't get
                // the file object until the `/complete` call
                resolve();
                onFinish(viewFileUploadIntermediate);
            })
            .fail((error) => {
                reject(error);
                throw error;
            })
    );
}

type ValidationType = {
    condition: (uploadIntermediate: S3FileUploadType) => boolean;
    onErrorParams: [errorMessage: string, errorType: keyof typeof UploadErrorType];
};

export const validateAndFilterOutFiles = (
    uploadIntermediates: S3FileUploadType[],
    onError: (
        errMessage: string,
        errorType: keyof typeof UploadErrorType,
        file: S3FileUploadType
    ) => void
) => {
    const validations: ValidationType[] = [
        {
            condition: ({ isDenied }) => !isDenied,
            onErrorParams: [strings.uploadFailedDenied, UploadErrorType.UPLOAD_DENIED],
        },
        {
            condition: ({ file }) => file.size < MAX_FILE_SIZE,
            onErrorParams: [
                strings.uploadFailedDueToFileSize,
                UploadErrorType.MAX_FILE_SIZE_EXCEEDED,
            ],
        },
    ];

    const successfulUploadIntermediates: S3FileUploadType[] = [];
    forEach(uploadIntermediates, (uploadIntermediate) => {
        let valid = true;
        forEach(validations, (validation) => {
            if (!validation.condition(uploadIntermediate)) {
                onError(...validation.onErrorParams, uploadIntermediate);
                valid = false;
            }
        });

        if (valid) {
            successfulUploadIntermediates.push(uploadIntermediate);
        }
    });

    return successfulUploadIntermediates;
};

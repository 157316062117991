import { useCallback } from 'react';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { useOverlayStore } from '../../core/overlays/hooks/useOverlayStore';

export function useGenericSubmissionsModals() {
    const overlayStore = useOverlayStore();

    const openCreateGenericSubmissionsModal = useCallback(
        () => overlayStore.open(overlayIdEnum.CREATE_GENERIC_SUBMISSIONS_MODAL),
        [overlayStore]
    );

    return { openCreateGenericSubmissionsModal };
}

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { compose, withPropsOnChange } from 'recompose';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { agencyProfilesWhereSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import testIds from '../../../../core/testIds';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import FormSection from '../../../core/forms/components/FormSection';
import { PopoutLink } from '../../../core/components/links/Link';

import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import HelpText from '../../../../legacy-redux/components/core/HelpText';
import {
    reportsDashboardSearch,
    submitReportsDashboardSearch,
    resetReportsDashboard,
} from '../state/ui';
import advancedSearchReports from '../../../search/reports/state/ui';
import { SavedSearchDropdownButton } from '../../../search/saved-search/components/SavedSearchDropdownButton';
import { saveFormDataToLocalStorage } from '../../../core/local-storage';

import { SearchName } from '../../../search/core/components/SearchName';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import useOnSaveSearchToggleHandler from '../../../search/core/hooks/useOnSaveSearchToggleHandler';
import useSaveAsNewSearch from '../../../search/core/hooks/useSaveAsNewSearch';
import CreateSavedSearchModal from '../../../search/saved-search/components/CreateSavedSearchModal';
import { DeleteSavedSearchConfirmationModal } from '../../../search/saved-search/components/SavedSearchConfirmationModals';
import ReportDetailsFieldset from './ReportDetailsFieldset';
import PersonnelFieldset from './PersonnelFieldset';
import DateTimeFieldset from './DateTimeFieldset';

const strings = componentStrings.reports.dashboard;

const ReportsSearchFormContainer = styled(ReactReduxForm)`
    background: ${(props) => props.theme.colors.white};
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const ReportsFormSection = styled(FormSection)`
    && {
        padding-top: 22px;
        padding-bottom: 22px;
    }
`;

const ClearFiltersButton = styled(Button)`
    margin-left: 15px;
    color: ${(props) => props.theme.colors.highContrastGrey};
    fill: ${(props) => props.theme.colors.highContrastGrey};
    display: inline-block;
    padding: 6px 0;
`;

const SaveSearchButton = styled(Button)`
    display: inline-block;
    padding: 6px 0;
`;

const HelpTextContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-bottom: 4px;
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--arc-space-3);
`;

const SearchButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
`;

function ReportsDashboardSearchForm({
    query,
    onSubmit,
    onReset,
    onGoToAdvancedSearch,
    hasSingleAgencyProfile,
    currentSavedSearch,
}) {
    const isSavedSearch = query.savedSearchId && !query.isAutoSave && !query.isShared;
    const onSaveSearchToggle = useOnSaveSearchToggleHandler(reportsDashboardSearch);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const savedSearchSharingEnabled = applicationSettings.RMS_SAVED_SEARCH_SHARING_ENABLED;
    const saveAsNewSearchHandler = useSaveAsNewSearch(reportsDashboardSearch);

    return (
        <ReportsSearchFormContainer {...reportsDashboardSearch.form}>
            <ReportsFormSection>
                <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={false} />
                <DateTimeFieldset onSubmit={onSubmit} />
                <PersonnelFieldset onSubmit={onSubmit} />
                <ReportDetailsFieldset
                    hideAgencySelect={hasSingleAgencyProfile}
                    onSubmit={onSubmit}
                />
                <BottomRow>
                    <SearchButtons>
                        <SavedSearchDropdownButton searchModule={reportsDashboardSearch} />
                        <FeatureFlagged
                            flag="RMS_SAVED_SEARCH_SHARING_ENABLED"
                            fallback={
                                <SaveSearchButton
                                    className={buttonTypes.ICON_LINK}
                                    iconLeft={
                                        <Icon
                                            type={
                                                isSavedSearch
                                                    ? iconTypes.SAVED_SEARCH_ACTIVE
                                                    : iconTypes.SAVED_SEARCH_INACTIVE
                                            }
                                        />
                                    }
                                    hoveredIconLeft={
                                        <Icon
                                            type={
                                                isSavedSearch
                                                    ? iconTypes.SAVED_SEARCH_INACTIVE
                                                    : iconTypes.SAVED_SEARCH_ACTIVE
                                            }
                                        />
                                    }
                                    onClick={onSaveSearchToggle}
                                    testId={testIds.REPORTS_DASHBOARD_SAVE_SEARCH_BUTTON}
                                >
                                    {isSavedSearch
                                        ? strings.SearchFooter.unsaveSearch
                                        : strings.SearchFooter.saveSearch}
                                </SaveSearchButton>
                            }
                        />
                        <CreateSavedSearchModal
                            onSave={
                                savedSearchSharingEnabled
                                    ? saveAsNewSearchHandler
                                    : onSaveSearchToggle
                            }
                        />
                        <DeleteSavedSearchConfirmationModal searchModule={reportsDashboardSearch} />
                        <ClearFiltersButton
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.CLOSE_X} size={12} />}
                            onClick={onReset}
                        >
                            {strings.SearchFooter.clear}
                        </ClearFiltersButton>
                    </SearchButtons>
                    <div>
                        <Button
                            className={buttonTypes.ICON_LINK}
                            onClick={() => onGoToAdvancedSearch(query)}
                        >
                            <PopoutLink to={'/search/reports'}>
                                {strings.SearchFooter.advancedSearch}
                            </PopoutLink>
                        </Button>
                        <HelpTextContainer>
                            <HelpText content={strings.SearchFooter.helpText} />
                        </HelpTextContainer>
                    </div>
                </BottomRow>
            </ReportsFormSection>
        </ReportsSearchFormContainer>
    );
}

const mapStateToProps = createStructuredSelector({
    query: reportsDashboardSearch.selectors.currentQuerySelector,
    agencyProfilesWhere: agencyProfilesWhereSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    currentSavedSearch: reportsDashboardSearch.selectors.currentSavedSearchSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => {
        dispatch(submitReportsDashboardSearch());
    },
    onReset: () => {
        dispatch(resetReportsDashboard());
        dispatch(reportsDashboardSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(reportsDashboardSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
    onGoToAdvancedSearch: (query) => {
        dispatch(advancedSearchReports.form.actionCreators.change(query.elasticQuery));
        dispatch(saveFormDataToLocalStorage(advancedSearchReports.form));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['agencyProfilesWhere'],
        ({ currentUserDepartmentId, agencyProfilesWhere }) => ({
            hasSingleAgencyProfile:
                agencyProfilesWhere({
                    departmentId: currentUserDepartmentId,
                }).length === 1,
        })
    )
)(ReportsDashboardSearchForm);

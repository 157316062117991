import _, {
    forEach,
    get,
    reduce,
    concat,
    compact,
    head,
    isFunction,
    join,
    reject,
    isEmpty,
    every,
} from 'lodash';
import {
    ExportReportVersionEnum,
    EntityTypeEnum,
    PaperFormatEnum,
    PrintingDataTypeEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    UsageSourceModuleEnum,
} from '@mark43/rms-api';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { storeAttachments } from '~/client-common/core/domain/attachments/state/data';
import getAttachmentsResource from '~/client-common/core/domain/attachments/resources/attachmentsResource';
import {
    printingDataTypeToEntityType,
    convertExportOptionsToExportConfigs,
} from '~/client-common/helpers/exportHelpers';
import { joinTruthyValues, prettify } from '~/client-common/helpers/stringHelpers';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import buildPrintablesResource from '~/client-common/core/domain/printables/resources/printablesResource';
import { NEXUS_STATE_PROP as ATTACHMENT_FOLDER_PRINTABLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attachment-folder-printables/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import { reportShortTitleViewModelByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { personProfileViewModelByIdSelector } from '~/client-common/core/domain/person-profiles/state/ui';
import getExportsResource from '~/client-common/core/domain/exports/resources/exportsResource';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    REPORT_RECORD_NUMBER,
    DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE,
} from '~/client-common/core/enums/universal/fields';
import { replaceTextIfEvidenceModuleNameIsNotEvidence } from '~/client-common/helpers/evidenceModuleNameReplacementHelpers';
import { inventoryViewModelByIdSelector } from '~/client-common/core/domain/inventories/state/ui';
import { partitionAttachmentAndFolderIdsSelector } from '../../modules/exports/core/state/data/partitionAttachmentAndFolderIds';
import partitionFolderPrintablesContainingAttachmentsAndNonAttachmentFolderPrintables from '../../modules/exports/core/utils/partitionFolderPrintablesContainingAttachmentsAndNonAttachmentFolderPrintables';
import isPrintableOfPrintingDataType from '../../modules/exports/core/utils/isPrintableOfPrintingDataType';
import folderPrintablesToWorkOrders from '../../modules/exports/core/utils/folderPrintablesToWorkOrders';
import computeZipTitle from '../../modules/exports/core/utils/computeZipTitle';
import computePaperFormat from '../../modules/exports/core/utils/computePaperFormat';
import { createCaseUsageLog } from '../../modules/cases';
import { createUsageLog } from '../../modules/admin/usage-logs/state/data';
import { currentWarrantSelector } from '../../modules/warrants/warrant/state/ui';
import { currentReportSelector } from '../selectors/reportSelectors';
import { exportsFormDataSelector } from '../selectors/exportsSelectors';

import { Mark43Error } from '../../lib/errors';
import { alwaysIncludeChildPrintables } from '../helpers/exportHelper';
import exportsActionTypes from './types/exportsActionTypes';
import { showLoadingBar } from './globalActions';

const {
    EXPORTS_PAGELOAD,
    PRINT,
    PRINT_FAILURE,
    PRINT_REFRESH,
    LOAD_PACKET_OPTIONS,
    LOAD_PACKET_OPTIONS_SUCCESS,
    LOAD_PACKET_OPTIONS_FAILURE,
    EXPAND_MY_EXPORTS,
    COLLAPSE_MY_EXPORTS,
    CLEAR_EXPORTS,
    CLEAR_EXPORTS_FAILURE,
    REMOVE_FILES_FROM_MY_EXPORTS,
    SET_REDACTION_WORK_ORDER,
    SET_POSITION_RIGHT,
} = exportsActionTypes;

// it's kinda state.... but for sake of
// 100% confidence i need it in here
// cuz otherwise async could have us double polling i think...
let isAlreadyLongPolling = false;
let isAlreadyInteralExportsLongPolling = false;

function kickOffClearExports() {
    return {
        type: CLEAR_EXPORTS,
    };
}

function kickOffPrint() {
    return {
        type: PRINT,
    };
}

function refreshExports(currentExports) {
    return {
        type: PRINT_REFRESH,
        payload: currentExports,
    };
}

export function removeFilesFromMyExports(fileIds) {
    return {
        type: REMOVE_FILES_FROM_MY_EXPORTS,
        payload: fileIds,
    };
}

function clearExportsFailure() {
    return {
        type: CLEAR_EXPORTS_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to clear exports'),
    };
}

function loadPacketOptions() {
    return {
        type: LOAD_PACKET_OPTIONS,
    };
}

function loadedPacketOptions(options) {
    return {
        type: LOAD_PACKET_OPTIONS_SUCCESS,
        payload: options,
    };
}

function failedLoadingPacketOptions() {
    return {
        type: LOAD_PACKET_OPTIONS_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to get packet options'),
    };
}

function longPoll(dispatch) {
    if (isAlreadyLongPolling) {
        return;
    }
    isAlreadyLongPolling = true;
    _.delay(() => {
        dispatch(pollForExports());
        isAlreadyLongPolling = false;
    }, 3000);
}

function longEntityInternalReportsPoll(dispatch, entityMetaData) {
    if (isAlreadyInteralExportsLongPolling) {
        return;
    }
    isAlreadyInteralExportsLongPolling = true;
    _.delay(() => {
        dispatch(pollForInternalExports(entityMetaData));
        isAlreadyInteralExportsLongPolling = false;
    }, 3000);
}

function handlePrintResults(dispatch, data) {
    // if any of the files are still downloading,
    // kick off the long poll in another 5 seconds
    if (
        _.some(data, (file) => {
            return !file.file && !file.failedPrint;
        })
    ) {
        longPoll(dispatch);
    }
    dispatch(refreshExports(data));
}

function handleInternalPrintResults(dispatch, data, entityMetaData) {
    if (
        _.some(data, (file) => {
            return !file.file && !file.failedPrint;
        })
    ) {
        longEntityInternalReportsPoll(dispatch, entityMetaData);
    }

    const isExportingComplete = every(data, (file) => !!file.file);

    if (isExportingComplete && !!entityMetaData) {
        dispatch(associateMyExportsToEntity(data, entityMetaData));
    }
}

function associateMyExportsToEntity(data, entityMetaData) {
    return function (dispatch) {
        const { entityId, entityType, linkType, onAssociationComplete } = entityMetaData;

        const attachmentsResource = getAttachmentsResource();
        const ids = data.map((item) => item.id);
        attachmentsResource
            .saveAttachmentsForEntities({
                entityType,
                entityIds: [entityId],
                attachments: data.map((item) => {
                    return {
                        attachmentId: item.file.id,
                        attachmentType: 'FILE',
                        entityId,
                        entityType,
                        id: -1,
                        linkType,
                    };
                }),
                removeOthers: false,
            })
            .then((savedAttachments) => {
                // eslint-disable-next-line no-restricted-syntax
                dispatch(storeAttachments(savedAttachments));
                dispatch(clearExports(ids));
                dispatch(showLoadingBar(false));
                if (isFunction(onAssociationComplete)) {
                    onAssociationComplete();
                }
            });
    };
}

function printFailure(err) {
    return {
        type: PRINT_FAILURE,
        error: true,
        payload: new Mark43Error(err.message),
    };
}

const getFolderContentPath = (
    caseEnhancementsEnabled,
    formatFieldByName,
    parentPrintable,
    printable
) => {
    if (
        caseEnhancementsEnabled &&
        formatFieldByName &&
        isPrintableOfPrintingDataType(parentPrintable, PrintingDataTypeEnum.CASE.name)
    ) {
        const renDisplay = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
        const recordNumberDisplay = formatFieldByName(REPORT_RECORD_NUMBER);

        if (printable.reportingEventNumber && renDisplay) {
            return join([renDisplay, printable.reportingEventNumber], '');
        } else if (printable.recordNumber && recordNumberDisplay) {
            return join([recordNumberDisplay, printable.recordNumber], '');
        }
    }

    return null;
};

const printableToWorkOrder = (
    {
        parentPrintable,
        printable,
        includeAttachments,
        includeHistoryEvents,
        onlyIncludeFieldsWithData,
        includeWarrantActivities,
        includeConfidentialInformation,
        selectedAttachmentsToInclude,
        partitionAttachmentAndFolderIds,
        includeNameAddendums,
        redactedFields = [],
        mergeAttachments,
        forceZip,
        zipTitle,
        paperFormat,
        isExportPreview,
        excludedAttachmentTypes = [],
    },
    applicationSettings,
    formatFieldByName
) => {
    let attachmentsConfig;

    if (includeAttachments) {
        // If the attachment sidepanel has not been interacted with,
        // `selectedAttachmentsToInclude` will return `undefined`, as opposed to an empty array.
        //
        // We want this distinction because `undefined` implies `includeAll`
        // where as empty array implies 'include nothing'
        const parentAttachments = selectedAttachmentsToInclude[printable.entityId];
        const childAttachments = reduce(
            printable.childrenContainingAttachments,
            (accumulator, child) => {
                if (selectedAttachmentsToInclude[child.id]) {
                    // Compacting because accumulator can be undefined for the first occurance
                    return compact(concat(accumulator, selectedAttachmentsToInclude[child.id]));
                }
                return accumulator;
            },
            undefined
        );
        let selectedAttachmentIds =
            parentAttachments || childAttachments
                ? compact(concat(parentAttachments, childAttachments))
                : undefined;

        if (selectedAttachmentIds && typeof partitionAttachmentAndFolderIds === 'function') {
            // `selectedAttachmentIds` sometimes contains non-attachment ids
            selectedAttachmentIds = partitionAttachmentAndFolderIds(selectedAttachmentIds)
                .attachmentIds;
        }

        attachmentsConfig = {
            includeAll:
                isPrintableOfPrintingDataType(printable, PrintingDataTypeEnum.PARENT_FOLDER.name) ||
                isPrintableOfPrintingDataType(printable, PrintingDataTypeEnum.FOLDER.name)
                    ? !isEmpty(selectedAttachmentsToInclude[printable.entityId])
                    : !selectedAttachmentIds,
            attachmentIds: selectedAttachmentIds,
            mergeAttachments,
            forceZip,
            excludedAttachmentTypes,
        };
    } else {
        attachmentsConfig = {
            forceZip,
        };
    }

    const reportVersionFileName =
        printable.exportReportVersion === ExportReportVersionEnum.OFFICER.name
            ? printable.officerReportFileName
            : printable.fileName;

    const fileName = reportVersionFileName
        ? joinTruthyValues([printable.groupTitle, reportVersionFileName], ' - ')
        : undefined;

    const folderContentPath = getFolderContentPath(
        applicationSettings?.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE,
        formatFieldByName,
        parentPrintable,
        printable
    );

    return {
        dataType: printable.dataType,
        millerIdMap: printable.exportData
            ? printable.exportData.idMap
            : { [printingDataTypeToEntityType[printable.dataType]]: printable.entityId },
        printingTemplateId: printable.printingTemplateId,
        releaseId: printable.releaseId,
        exportReportVersion: printable.exportReportVersion,
        rmsEventId: printable.rmsEventId,
        paperFormat,
        // The `title` appears in the export tray UI. Be careful when changing `title` or `fileName`, as they are
        // easily confused with each other. See https://github.com/mark43/mark43/blob/3cec25cbf1319448a2d8d64862da13bbb7a86d41/cobalt-core-client/paper-mill-client/src/main/java/com/mark43/papermill/framework/PaperBase.java#L52
        title: zipTitle || joinTruthyValues([printable.groupTitle, printable.title], ' - '),
        // If the export is a zip file, then `fileName` is the name of this file within that zip.
        fileName,
        // If the export is a zip file, then `zipTitle` is the filename of that zip.
        zipTitle,
        attachmentsConfig,
        exportConfigs: {
            ...convertExportOptionsToExportConfigs(printable.exportOptions),
            includeHistoryEvents,
            onlyIncludeFieldsWithData,
            includeWarrantActivities,
            includeConfidentialInformation,
            includeNameAddendums,
            fieldsToRedact: redactedFields,
            isExportPreview,
        },
        isAssociatedRecord: printable.isAssociatedRecord,
        folderContentPath,
    };
};

const printableToChildWorkOrders = (
    {
        printable,
        includeAttachments,
        includeHistoryEvents,
        onlyIncludeFieldsWithData,
        includeWarrantActivities,
        includeConfidentialInformation,
        selectedAttachmentsToInclude,
        partitionAttachmentAndFolderIds,
        includeNameAddendums,
        redactedFields,
        mergeAttachments,
        forceZip,
        isExportPreview,
        excludedAttachmentTypes,
    },
    applicationSettings,
    formatFieldByName
) => {
    const childPrintables = flattenChildPrintables(printable.childPrintables);
    const workOrderChildren = _.map(childPrintables, (x) =>
        printableToWorkOrder(
            {
                parentPrintable: printable,
                printable: x,
                includeAttachments,
                includeHistoryEvents,
                onlyIncludeFieldsWithData,
                includeWarrantActivities,
                includeConfidentialInformation,
                selectedAttachmentsToInclude,
                partitionAttachmentAndFolderIds,
                includeNameAddendums,
                redactedFields,
                mergeAttachments,
                forceZip,
                paperFormat: PaperFormatEnum.PDF.name,
                isExportPreview,
                excludedAttachmentTypes,
            },
            applicationSettings,
            formatFieldByName
        )
    );

    const prefixFileChildren = _.map(printable.prefixPdfs, (prefixFile) => ({
        preRenderedPdfFile: prefixFile,
    }));
    const folderChildren = folderPrintablesToWorkOrders({
        printables: [printable],
        selectedAttachmentsToInclude,
        partitionAttachmentAndFolderIds,
    });

    return [...workOrderChildren, ...prefixFileChildren, ...folderChildren];
};

// For certain printing data types we always want to include their child printables in the work order
function flattenChildPrintables(printables) {
    const children = [...printables];
    forEach(printables, (printable) => {
        if (alwaysIncludeChildPrintables(printable.dataType)) {
            children.push(...printable.childPrintables);
        }
    });
    return children;
}

export function getPrintRequestObject(
    formData,
    selectedPackets,
    {
        isExportPreview,
        applicationSettings,
        partitionAttachmentAndFolderIds,
        formatFieldByName,
    } = {}
) {
    const includeAttachments = String(formData.includeFiles) === 'true';
    const includeBulkAttachments = String(formData.includeAttachmentForBulkExport) === 'true';
    const includeHistoryEvents = String(formData.includeHistoryEvents) === 'true';
    const onlyIncludeFieldsWithData = String(formData.onlyIncludeFieldsWithData) === 'true';
    const includeWarrantActivities = String(formData.includeWarrantActivities) === 'true';
    const includeNameAddendums = String(formData.includeNameAddendums) === 'true';
    const redactFields = String(formData.redactFields) === 'true';
    const mergeAttachments = String(formData.mergeAttachments) === 'true';
    const forceZip =
        String(formData.includeZipFilesForBulkExport) === 'true' ||
        // forceZip must be set when the parent WorkOrder is a folder (FOLDER or PARENT_FOLDER) - except when combining PDFs
        // the child WorkOrders do not need forceZip to be set
        (selectedPackets &&
            formData.combinedPDF &&
            (isPrintableOfPrintingDataType(
                selectedPackets?.[0],
                PrintingDataTypeEnum.PARENT_FOLDER.name
            ) ||
                isPrintableOfPrintingDataType(
                    selectedPackets?.[0],
                    PrintingDataTypeEnum.FOLDER.name
                )));

    let { redactedFields = [] } = formData;
    const { excludedAttachmentTypes = [] } = formData;

    // reset all redacted values if redaction is disabled
    if (!redactFields) {
        redactedFields = [];
    }

    const includeConfidentialInformation =
        String(formData.includeConfidentialInformation) === 'true';
    const { selectedAttachmentsToInclude = {} } = formData;

    return _.map(selectedPackets, (printable) => ({
        ...printableToWorkOrder(
            {
                printable,
                includeAttachments: includeAttachments || includeBulkAttachments,
                includeHistoryEvents,
                onlyIncludeFieldsWithData,
                includeWarrantActivities,
                includeConfidentialInformation,
                selectedAttachmentsToInclude,
                partitionAttachmentAndFolderIds,
                includeNameAddendums,
                redactedFields,
                mergeAttachments,
                forceZip,
                zipTitle: computeZipTitle(printable, {
                    folderingEnabled: applicationSettings?.RMS_CASE_FOLDERING_ENABLED,
                }),
                paperFormat: computePaperFormat(printable, {
                    folderingEnabled: applicationSettings?.RMS_CASE_FOLDERING_ENABLED,
                    caseEnhancementsEnabled:
                        applicationSettings?.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE,
                    combinedPDF: formData.combinedPDF,
                }),
                isExportPreview,
                excludedAttachmentTypes,
            },
            applicationSettings,
            formatFieldByName
        ),
        children: printableToChildWorkOrders(
            {
                printable,
                includeAttachments: includeAttachments || includeBulkAttachments,
                includeHistoryEvents,
                onlyIncludeFieldsWithData,
                includeWarrantActivities,
                includeConfidentialInformation,
                selectedAttachmentsToInclude,
                partitionAttachmentAndFolderIds,
                includeNameAddendums,
                redactedFields,
                mergeAttachments,
                isExportPreview,
                excludedAttachmentTypes,
            },
            applicationSettings,
            formatFieldByName
        ),
    }));
}

export function pollForExports() {
    return function (dispatch) {
        getExportsResource()
            .pollForExports()
            .then((results) => {
                handlePrintResults(dispatch, results);
            })
            .catch(() => {
                // TODO should probably try again!?? or not!??
            });
    };
}

function pollForInternalExports(entityMetaData) {
    return function (dispatch) {
        getExportsResource()
            .pollForInternalExports()
            .then((results) => {
                handleInternalPrintResults(dispatch, results, entityMetaData);
            })
            .catch(() => {
                // TODO should probably try again!?? or not!??
            });
    };
}

export function exportsPageLoad() {
    return {
        type: EXPORTS_PAGELOAD,
    };
}

export function clearExports(ids) {
    return function (dispatch) {
        dispatch(kickOffClearExports());
        return getExportsResource()
            .clearExports(ids)
            .then((results) => {
                dispatch(refreshExports(results));
            })
            .catch(() => {
                dispatch(clearExportsFailure());
            });
    };
}

export function loadExportPacketOptionsForReportDefinitions(ids) {
    return function (dispatch) {
        dispatch(loadPacketOptions());
        return buildPrintablesResource()
            .getReportDefinitionPrintables(ids)
            .then((printables) => {
                dispatch(loadedPacketOptions(printables));
            })
            .catch(() => {
                dispatch(failedLoadingPacketOptions());
            });
    };
}

export function loadExportPacketOptions(type, id) {
    return function (dispatch, getState, { nexus: { withEntityItems } }) {
        dispatch(loadPacketOptions());

        let resourceMethod;
        const resource = buildPrintablesResource();
        switch (type) {
            case PrintingDataTypeEnum.CASE.name:
                resourceMethod = (caseId) =>
                    resource
                        .getCasePrintables(caseId, {
                            /**
                             * By default, exclude printables which represent folder contents (such as case notes inside
                             * a folder) because those folders are exportable directly - this endpoint already returns
                             * printables which represent the folders themselves.
                             */
                            excludeFoldered: true,
                        })
                        .then((printables) => {
                            const state = getState();
                            const applicationSettings = applicationSettingsSelector(state);
                            if (applicationSettings.RMS_CASE_FOLDERING_ENABLED) {
                                // attachment folders are displayed in the attachments side panel, not in the main
                                // export screen's checkbox screen, so we store them separately
                                const [
                                    folderPrintablesContainingAttachments,
                                    nonAttachmentFolderPrintables,
                                ] = partitionFolderPrintablesContainingAttachmentsAndNonAttachmentFolderPrintables(
                                    printables
                                );

                                if (folderPrintablesContainingAttachments.length > 0) {
                                    dispatch(
                                        withEntityItems(
                                            {
                                                [ATTACHMENT_FOLDER_PRINTABLES_NEXUS_STATE_PROP]: folderPrintablesContainingAttachments,
                                            },
                                            { type: 'STORE_ATTACHMENT_FOLDER_PRINTABLES' }
                                        )
                                    );
                                }
                                return nonAttachmentFolderPrintables;
                            } else {
                                return reject(printables, {
                                    dataType: PrintingDataTypeEnum.PARENT_FOLDER.name,
                                });
                            }
                        });
                break;
            case PrintingDataTypeEnum.REPORT.name:
                resourceMethod = resource.getReportPrintables;
                break;
            case PrintingDataTypeEnum.CHAIN_OF_CUSTODY.name:
                resourceMethod = resource.getChainOfCustodyPrintables;
                break;
            case PrintingDataTypeEnum.WARRANT.name:
                resourceMethod = resource.getWarrantPrintables;
                break;
            case PrintingDataTypeEnum.PERSON_PROFILE.name:
                resourceMethod = resource.getPersonProfilePrintables;
                break;
            case PrintingDataTypeEnum.PHOTO_LINEUP.name:
                resourceMethod = resource.getPhotoLineupPrintables;
                break;
            default:
                break;
        }

        return resourceMethod(id)
            .then((printables) => {
                dispatch(loadedPacketOptions(printables));
            })
            .catch(() => {
                dispatch(failedLoadingPacketOptions());
                return Promise.reject(new Mark43Error('Failed to get packet options'));
            });
    };
}

function createPrintUsageLogs(workOrders, usageLogCompletion) {
    return (dispatch, getState) => {
        const reduxState = getState();
        if (Array.isArray(workOrders)) {
            forEach(workOrders, (workOrder) => {
                switch (workOrder.dataType) {
                    case PrintingDataTypeEnum.CASE.name:
                        dispatch(
                            createCaseUsageLog({
                                usageLogAction: UsageActionEnum.EXPORTED_CASE_JACKET_PDF.name,
                                usageLogCompletion,
                            })
                        );
                        break;
                    case PrintingDataTypeEnum.CASE_NOTE.name:
                        dispatch(
                            createCaseUsageLog({
                                usageLogAction: UsageActionEnum.EXPORTED_CASE_NOTE_PDF.name,
                                usageLogCompletion,
                            })
                        );
                        break;
                    case PrintingDataTypeEnum.WARRANT.name:
                        const warrant = currentWarrantSelector(reduxState);
                        dispatch(
                            createUsageLog({
                                primaryEntityType: EntityTypeEnum.WARRANT.name,
                                primaryEntityTitle: warrant.warrantNumber,
                                primaryEntityId: warrant.id,
                                primaryEntityDepartmentId: warrant.departmentId,
                                action: UsageActionEnum.EXPORTED_WARRANT.name,
                                completion: usageLogCompletion,
                                sourceModule: UsageSourceModuleEnum.WARRANTS.name,
                            })
                        );
                        break;
                    case PrintingDataTypeEnum.REPORT.name:
                        const currentReport = currentReportSelector(reduxState);
                        const reportShortTitleViewModel = reportShortTitleViewModelByReportIdSelector(
                            reduxState
                        )(currentReport.id);
                        const { shortTitleWithRen } = getViewModelProperties(
                            reportShortTitleViewModel
                        );
                        dispatch(
                            createUsageLog({
                                primaryEntityType: EntityTypeEnum.REPORT.name,
                                primaryEntityTitle: shortTitleWithRen,
                                primaryEntityId: currentReport.id,
                                primaryEntityDepartmentId: currentReport.departmentId,
                                action: UsageActionEnum.EXPORTED_REPORT.name,
                                completion: usageLogCompletion,
                                sourceModule: UsageSourceModuleEnum.REPORTS.name,
                            })
                        );
                        break;
                    case PrintingDataTypeEnum.PERSON_PROFILE.name:
                        const personId = get(
                            workOrder.millerIdMap,
                            PrintingDataTypeEnum.PERSON_PROFILE.name
                        );
                        const personProfile = personProfileViewModelByIdSelector(reduxState)(
                            personId
                        );
                        const { fullName } = getViewModelProperties(personProfile);
                        dispatch(
                            createUsageLog({
                                primaryEntityType: EntityTypeEnum.PERSON_PROFILE.name,
                                primaryEntityTitle: fullName,
                                primaryEntityId: personId,
                                primaryEntityDepartmentId: personProfile.departmentId,
                                action: UsageActionEnum.EXPORTED_PERSON_PROFILE.name,
                                completion: usageLogCompletion,
                                sourceModule: UsageSourceModuleEnum.ENTITY_PROFILES.name,
                            })
                        );
                        break;
                    default:
                        break;
                }
            });
        } else {
            switch (workOrders.dataType) {
                case PrintingDataTypeEnum.INVENTORY.name:
                    const inventoryId = workOrders.inventoryId;
                    const currentInventory = inventoryViewModelByIdSelector(reduxState)(
                        inventoryId
                    );
                    dispatch(
                        createUsageLog({
                            primaryEntityId: inventoryId,
                            primaryEntityType: EntityTypeEnum.EVIDENCE_INVENTORY.name,
                            primaryEntityTitle: `Export ${workOrders.title}`,
                            primaryEntityDepartmentId: currentInventory.departmentId,
                            sourceModule: UsageSourceModuleEnum.EVIDENCE.name,
                            action: UsageActionEnum.EXPORTED_INVENTORY.name,
                            completion: usageLogCompletion,
                        })
                    );
                    break;
                default:
                    break;
            }
        }
    };
}

// we need selectedPackets to be passed in, instead of using
// selectedPacketsSelector here, b/c the form state may already be destroyed
export function exportPDF(
    formData,
    selectedPackets,
    exportResourceMethod,
    onSuccess,
    entityMetadata
) {
    const resourceMethod = isUndefinedOrNull(exportResourceMethod)
        ? getExportsResource().print
        : exportResourceMethod;
    return function (dispatch, getState) {
        const state = getState();
        const requestData = getPrintRequestObject(formData, selectedPackets, {
            isExportPreview: false,
            applicationSettings: applicationSettingsSelector(state),
            partitionAttachmentAndFolderIds: partitionAttachmentAndFolderIdsSelector(state),
            formatFieldByName: formatFieldByNameSelector(state),
        });
        dispatch(
            exportDocument({
                requestData,
                exportResourceMethod: resourceMethod,
                onSuccess,
                entityMetadata,
            })
        );
    };
}

export function exportPDFFromWorkOrder(workOrder, onSuccess2) {
    return function (dispatch) {
        dispatch(
            exportDocument({
                requestData: [workOrder],
                exportResourceMethod: getExportsResource().print,
                onSuccess: onSuccess2,
            })
        );
    };
}

export function exportDocument({
    requestData: requestData,
    exportResourceMethod: exportResourceMethod,
    onSuccess: onSuccess,
    disableUsageLogs: disableUsageLogs,
    entityMetadata = undefined,
}) {
    return function (dispatch, getState) {
        dispatch(kickOffPrint());
        const state = getState();

        // this is a temporary solution for RMS-15751,
        // to replace the word evidence in fileName as
        // display name of field DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE

        let textReplacedRequestData = requestData;
        const isAssociatingExportToAnEntity = !!entityMetadata;

        // requestData might be an object from compliance module,
        // and has no values need to be replaced
        if (Array.isArray(requestData)) {
            const prettifiedEvidenceModuleName = prettify(
                formatFieldByNameSelector(state)(DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE)
            );

            textReplacedRequestData = requestData.map((request) => ({
                ...request,
                fileName: replaceTextIfEvidenceModuleNameIsNotEvidence(
                    request.fileName,
                    prettifiedEvidenceModuleName
                ),
                keepFileForever: isAssociatingExportToAnEntity,
                isInternal: isAssociatingExportToAnEntity,
            }));
        }

        return exportResourceMethod(textReplacedRequestData, isAssociatingExportToAnEntity)
            .then((results) => {
                if (isAssociatingExportToAnEntity) {
                    dispatch(showLoadingBar(true));
                }

                const handleResultsMethod = isAssociatingExportToAnEntity
                    ? handleInternalPrintResults
                    : handlePrintResults;

                const filteredResults = isAssociatingExportToAnEntity
                    ? results.filter((item) => item.isInternal)
                    : results.filter((item) => !item.isInternal);

                handleResultsMethod(dispatch, filteredResults, entityMetadata);

                if (!disableUsageLogs) {
                    dispatch(createPrintUsageLogs(requestData, UsageCompletionEnum.SUCCEEDED.name));
                }
                if (isFunction(onSuccess)) {
                    onSuccess(results);
                }
            })
            .catch((err) => {
                dispatch(showLoadingBar(false));
                dispatch(printFailure(err));
                if (!disableUsageLogs) {
                    dispatch(
                        createPrintUsageLogs(requestData, UsageCompletionEnum.SERVER_ERROR.name)
                    );
                }
            });
    };
}

export function collapseMyExports() {
    return {
        type: COLLAPSE_MY_EXPORTS,
    };
}

export function expandMyExports() {
    return {
        type: EXPAND_MY_EXPORTS,
    };
}

export function setPositionRight(positionRight) {
    return {
        type: SET_POSITION_RIGHT,
        payload: positionRight,
    };
}

// bulk files export
const FILES_EXPORT_START = 'exports/FILES_EXPORT_START';
const FILES_EXPORT_SUCCESS = 'exports/FILES_EXPORT_SUCCESS';
const FILES_EXPORT_FAILURE = 'exports/FILES';

const filesExportStart = (fileIds, title) => ({
    type: FILES_EXPORT_START,
    payload: { fileIds, title },
});

const filesExportSuccess = (userExports) => ({
    type: FILES_EXPORT_SUCCESS,
    payload: userExports,
});

const filesExportFailure = (errorMessage) => ({
    type: FILES_EXPORT_FAILURE,
    payload: errorMessage,
});

export function exportFiles(fileIds, title) {
    return (dispatch) => {
        dispatch(filesExportStart(fileIds, title));
        getExportsResource()
            .bulkExportFiles(fileIds, title)
            .then((userExports) => {
                dispatch(filesExportSuccess(userExports));
                dispatch(pollForExports());
                dispatch(expandMyExports());
            })
            .catch((err) => {
                dispatch(filesExportFailure(err.message));
                throw err;
            });
    };
}

const setRedactionWorkOrder = (workOrder) => ({
    type: SET_REDACTION_WORK_ORDER,
    payload: workOrder,
});

export const setRedactionWorkOrderFromExportsFormData = (selectedPackets) => {
    return (dispatch, getState) => {
        const state = getState();
        const exportsFormData = exportsFormDataSelector(state);
        const workOrder = head(
            getPrintRequestObject(exportsFormData, selectedPackets, {
                isExportPreview: true,
                applicationSettings: applicationSettingsSelector(state),
                partitionAttachmentAndFolderIds: partitionAttachmentAndFolderIdsSelector(state),
                formatFieldByName: formatFieldByNameSelector(state),
            })
        );
        dispatch(setRedactionWorkOrder(workOrder));
    };
};

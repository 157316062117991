import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map, slice } from 'lodash';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';

import { Tooltip } from '../../../../../modules/core/components/tooltip';
import SyncStatusButton from './SyncStatusButton';

const NUMBER_TO_DISPLAY = 3;

const LabelSpan = styled.span`
    margin-left: -10px;
`;

const Label = styled.li`
    font-size: var(--arc-fontSizes-sm);
`;

const LabelContainer = styled.ul`
    font-size: var(--arc-fontSizes-md);
    padding-left: 0;
    margin: 0;
    list-style-position: inside;
`;

const CellContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const BlockLabel = styled(Label)`
    clear: both;
`;

const MoreLabels = styled.span`
    padding: 5px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-sm);
    cursor: default;
`;

function RoutingLabelsCell({
    id: reportId,
    routingLabelAttrDetails,
    formatAttributeById,
    className,
    supportSyncStatusEnabled,
}) {
    const convertedLabels = map(routingLabelAttrDetails, (label) => {
        return {
            ...label,
            id: label.attributeId || label.id,
        };
    });

    const additionalLabels =
        convertedLabels.length > NUMBER_TO_DISPLAY
            ? slice(convertedLabels, NUMBER_TO_DISPLAY)
            : null;
    const labelContent = (id) => <LabelSpan>{formatAttributeById(id)}</LabelSpan>;
    return (
        <CellContainer>
            {supportSyncStatusEnabled && <SyncStatusButton reportId={reportId} />}
            {convertedLabels.length > 0 && (
                <LabelContainer className={className}>
                    {slice(convertedLabels, 0, NUMBER_TO_DISPLAY).map((label) => {
                        return <Label key={label.id}>{labelContent(label.id)}</Label>;
                    })}
                    {additionalLabels && (
                        <Tooltip
                            side="bottom"
                            align="start"
                            content={map(additionalLabels, (label) => (
                                <div key={label.id}>
                                    <BlockLabel>{labelContent(label.id)}</BlockLabel>
                                </div>
                            ))}
                        >
                            <MoreLabels>+{additionalLabels.length}</MoreLabels>
                        </Tooltip>
                    )}
                </LabelContainer>
            )}
        </CellContainer>
    );
}

const mapStateToProps = createStructuredSelector({
    formatAttributeById: formatAttributeByIdSelector,
});

export default connect(mapStateToProps)(RoutingLabelsCell);

import { Facility } from '@mark43/evidence-api';
import { LocationView } from '@mark43/rms-api';
import { identity, set, size } from 'lodash';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { saveLocation } from '../../../locations/state/data';
import getFacilitiesResource from '../../resources/facilitiesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'facilities';

const facilitiesModule = createNormalizedModule<Facility>({
    type: NEXUS_STATE_PROP,
});

// ACTION TYPES
export const LOAD_FACILITIES_START = 'facilities/LOAD_FACILITIES_START';
export const LOAD_FACILITIES_SUCCESS = 'facilities/LOAD_FACILITIES_SUCCESS';
export const LOAD_FACILITIES_FAILURE = 'facilities/LOAD_FACILITIES_FAILURE';
const SAVE_FACILITY_START = 'facilities/SAVE_FACILITY_START';
const SAVE_FACILITY_SUCCESS = 'facilities/SAVE_FACILITY_SUCCESS';
const SAVE_FACILITY_FAILURE = 'facilities/SAVE_FACILITY_FAILURE';

// ACTIONS
const replaceFacilitiesWhere = facilitiesModule.actionCreators.replaceEntitiesWhere;

function loadFacilitiesStart() {
    return {
        type: LOAD_FACILITIES_START,
    };
}
function loadFacilitiesSuccess(facilities: Facility[]) {
    return {
        type: LOAD_FACILITIES_SUCCESS,
        payload: facilities,
    };
}
function loadFacilitiesFailure(errorMessage: string) {
    return {
        type: LOAD_FACILITIES_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function saveFacilityStart() {
    return {
        type: SAVE_FACILITY_START,
    };
}
function saveFacilitySuccess(facility: Facility) {
    return {
        type: SAVE_FACILITY_SUCCESS,
        payload: facility,
    };
}
function saveFacilityFailure(errorMessage: string) {
    return {
        type: SAVE_FACILITY_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

/**
 * Load all facilities in the department and their locations. Storage locations
 *   within the facilities are not included.
 */
export function loadFacilities(): ClientCommonAction<Promise<Facility[]> | undefined> {
    const facilitiesResource = getFacilitiesResource();

    return function (dispatch, getState) {
        if (size(facilitiesSelector(getState())) > 0) {
            return;
        }

        dispatch(loadFacilitiesStart());

        return facilitiesResource
            .getFacilities()
            .then((facilities: Facility[]) => {
                dispatch(loadFacilitiesSuccess(facilities));
                dispatch(replaceFacilitiesWhere(identity, facilities));
                return facilities;
            })
            .catch((err: Error) => dispatch(loadFacilitiesFailure(err.message)));
    };
}

/**
 * Create or update a facility.
 */
export function saveFacility(
    isNew: boolean,
    facility: Facility,
    location: LocationView
): ClientCommonAction<Promise<Facility>> {
    const facilitiesResource = getFacilitiesResource();

    return function (dispatch) {
        dispatch(saveFacilityStart());

        const promise: Promise<Facility> = isNew
            ? facilitiesResource.createFacility(facility)
            : facilitiesResource.updateFacility(facility.id, facility);

        return promise
            .then((facility) => {
                // update data state
                dispatch(saveFacilitySuccess(facility));
                dispatch(replaceFacilitiesWhere({ id: facility.id }, [facility]));
                return facility;
            })
            .then((facility) => {
                if (isNew) {
                    // fill in the location entity link model
                    set(location, 'entityLinks[0].entityId', facility.id);
                }
                // save the location and location entity link
                return [facility, dispatch(saveLocation({ location }))];
            })
            .spread<Facility>((facility: Facility) => facility)
            .catch((err: Error) => {
                dispatch(saveFacilityFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const facilitiesSelector = facilitiesModule.selectors.entitiesSelector;

// REDUCER
export default facilitiesModule.reducerConfig;

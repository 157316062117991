import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportDefinitionByReportIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { reportDefinitionHasArrestCard } from '~/client-common/helpers/reportDefinitionsHelpers';
import { detentionsForReportIdSelector } from '~/client-common/core/domain/detentions/state/data';

import { CustomLink } from '../../../../core/components/links/Link';
import {
    currentReportSelector,
    currentReportIsSnapshotSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { setAdditionalContentTop } from '../../../../../legacy-redux/actions/globalActions';
import { doesVacatedChargeExistForReportIdSelector } from '../../../../record-privacy/vacating/state/ui';
import {
    isInvolvedPersonSealedForReportIdSelector,
    doesSealedChargeExistForReportIdSelector,
} from '../../../../record-privacy/sealing/state/ui';

import RestrictedReportBanner from './RestrictedReportBanner';

const strings = componentStrings.reports.core.ReportHeader;

const LegacyReportBanner = styled.div`
    height: 15px;
    background-color: ${(props) => props.theme.colors.brightYellow};
    background-image: url(../images/importedreport.png);
    background-repeat: repeat-x;
`;

const SnapshotBanner = styled.div`
    font-size: var(--arc-fontSizes-md);
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.cobaltBlue};
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
`;

const LinkToOriginalReport = styled(CustomLink)`
    color: ${(props) => props.theme.colors.white};
    padding-left: 10px;
`;

const mapStateToProps = createStructuredSelector({
    currentReport: currentReportSelector,
    reportDefinitionByReportId: reportDefinitionByReportIdSelector,
    isInvolvedPersonSealedForReportId: isInvolvedPersonSealedForReportIdSelector,
    doesVacatedChargeExistForReportId: doesVacatedChargeExistForReportIdSelector,
    doesSealedChargeExistForReportId: doesSealedChargeExistForReportIdSelector,
    detentionsForReportId: detentionsForReportIdSelector,
    isSnapshot: currentReportIsSnapshotSelector,
});

class ReportHeader extends React.Component {
    handleSnapshotBannerRef = (ref) => (this.snapshotBannerRef = ref);
    handleLegacyBannerRef = (ref) => (this.legacyBannerRef = ref);
    handleRecordPrivacyBannerRef = (ref) => (this.recordPrivacyRef = ref);
    handleDetentionDispositionBannerRef = (ref) => (this.detentionDispositionBannerRef = ref);

    componentDidMount() {
        let totalAdditionalHeight = 0;
        if (this.snapshotBannerRef) {
            const { height: snapshotBannerHeight } = this.snapshotBannerRef.getBoundingClientRect();
            totalAdditionalHeight = totalAdditionalHeight + snapshotBannerHeight;
        }

        if (this.legacyBannerRef) {
            const { height: legacyBannerHeight } = this.legacyBannerRef.getBoundingClientRect();
            totalAdditionalHeight = totalAdditionalHeight + legacyBannerHeight;
        }

        if (this.recordPrivacyRef) {
            const {
                height: recordPrivacyBannerHeight,
            } = this.recordPrivacyRef.getBoundingClientRect();
            totalAdditionalHeight = totalAdditionalHeight + recordPrivacyBannerHeight;
        }

        if (this.detentionDispositionBannerRef) {
            const {
                height: detentionDispositionBannerHeight,
            } = this.detentionDispositionBannerRef.getBoundingClientRect();
            totalAdditionalHeight = totalAdditionalHeight + detentionDispositionBannerHeight;
        }

        this.props.setAdditionalContentTop(totalAdditionalHeight);
    }

    componentWillUnmount() {
        this.props.setAdditionalContentTop(0);
    }

    render() {
        const {
            currentReport,
            reportDefinitionByReportId,
            isSnapshot,
            router,
            isInvolvedPersonSealedForReportId,
            doesVacatedChargeExistForReportId,
            doesSealedChargeExistForReportId,
            detentionsForReportId,
        } = this.props;

        if (!currentReport) {
            return null;
        }

        const currentReportId = currentReport.id;
        const reportDefinition = reportDefinitionByReportId(currentReportId);

        const isArrestReport = reportDefinitionHasArrestCard(reportDefinition);
        const reportContainsVacatedCharge =
            isArrestReport && doesVacatedChargeExistForReportId(currentReportId);
        const reportContainsSealedCharge =
            isArrestReport && doesSealedChargeExistForReportId(currentReportId);
        const reportContainsSealedPerson = isInvolvedPersonSealedForReportId(currentReportId);
        const isReportSealed = currentReport.isSealed;
        const isReportMarkedForPrivacy =
            reportContainsVacatedCharge ||
            reportContainsSealedCharge ||
            reportContainsSealedPerson ||
            isReportSealed;
        const isArrestUpdatedToDetention =
            isArrestReport && !!detentionsForReportId(currentReportId)?.length;

        // Always prioritize sealed information over vacated information.
        let recordPrivacyBannerText;
        if (isReportSealed) {
            recordPrivacyBannerText = strings.sealedReportBannerText;
        } else if (reportContainsSealedPerson) {
            recordPrivacyBannerText = strings.partiallySealedReportBannerText;
        } else if (reportContainsSealedCharge) {
            recordPrivacyBannerText = strings.sealedChargesBannerText;
        } else {
            recordPrivacyBannerText = strings.vacatedChargesBannerText;
        }

        const reportPagePath = `/reports/${currentReportId}`;

        return (
            <>
                {currentReport.isLegacyReport && (
                    <LegacyReportBanner ref={this.handleLegacyBannerRef} />
                )}
                {isReportMarkedForPrivacy && (
                    <RestrictedReportBanner
                        getRef={this.handleRecordPrivacyBannerRef}
                        bannerText={recordPrivacyBannerText}
                    />
                )}
                {isArrestUpdatedToDetention && (
                    <RestrictedReportBanner
                        getRef={this.handleDetentionDispositionBannerRef}
                        bannerText={strings.detentionDispositionBannerText}
                    />
                )}
                {isSnapshot && (
                    <SnapshotBanner ref={this.handleSnapshotBannerRef}>
                        {strings.reportSnapshotBannerText}
                        <LinkToOriginalReport
                            to={reportPagePath}
                            onClick={() => {
                                router.push(reportPagePath);
                                // force refresh the page to clear out current report data from state
                                window.location.reload();
                            }}
                        >
                            {strings.viewOriginalReport}
                        </LinkToOriginalReport>
                    </SnapshotBanner>
                )}
            </>
        );
    }
}

/**
 * Component for rendering any banners to be displayed between the report header
 * and the report content
 */
export default connect(mapStateToProps, {
    setAdditionalContentTop,
})(ReportHeader);

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Flex } from 'arc';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { warrantViewModelByIdSelector } from '~/client-common/core/domain/warrants/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { currentWarrantIdSelector } from '../state/ui';
import AttachmentsCard from '../../../core/components/cards/AttachmentsCard';
import { BaseScrollableUnderSubheader } from '../../../core/components/ScrollableUnderSubheader';
import WarrantDexSubmissionCard from './WarrantDexSubmissionCard';
import WarrantChargesCard from './WarrantChargesCard';
import WarrantDetailsCard from './WarrantDetailsCard';
import WarrantSidebar from './WarrantSidebar';
import WarrantHeader from './WarrantHeader';

const WarrantCardsContainer = styled.div`
    margin-bottom: 48px;
    height: fit-content;
    width: 590px;
    display: flex;
    flex-direction: column;
`;

const StyledPageContent = styled(Flex)`
    width: 100%;
    position: sticky;
    top: 0;
    flex-direction: row;
    overflow-y: auto;
    justify-content: center;
`;

function WarrantPage({ currentWarrantId, warrantViewModelById, canEdit }) {
    const { warrantShortTitle } = getViewModelProperties(warrantViewModelById(currentWarrantId));
    return (
        <>
            <WarrantHeader warrantId={currentWarrantId} />
            <BaseScrollableUnderSubheader>
                <StyledPageContent>
                    <WarrantCardsContainer>
                        <WarrantDetailsCard warrantId={currentWarrantId} canEdit={canEdit} />
                        <WarrantChargesCard entityId={currentWarrantId} canEdit={canEdit} />
                        <AttachmentsCard
                            entityTitle={warrantShortTitle}
                            entityType={EntityTypeEnum.WARRANT.name}
                            entityId={currentWarrantId}
                            linkType={LinkTypesEnum.WARRANT_ATTACHMENT}
                            canEdit={canEdit}
                        />
                        <WarrantDexSubmissionCard warrantId={currentWarrantId} canEdit={canEdit} />
                    </WarrantCardsContainer>
                    <WarrantSidebar warrantId={currentWarrantId} />
                </StyledPageContent>
            </BaseScrollableUnderSubheader>
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    currentWarrantId: currentWarrantIdSelector,
    warrantViewModelById: warrantViewModelByIdSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
});

export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['currentWarrantId', 'warrantViewModelById', 'currentUserHasAbility'],
        ({ currentWarrantId, warrantViewModelById, currentUserHasAbility }) => ({
            canEdit:
                getViewModelProperties(warrantViewModelById(currentWarrantId)).canWrite &&
                currentUserHasAbility(abilitiesEnum.WARRANTS.EDIT_GENERAL),
        })
    )
)(WarrantPage);

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { openBox, closeBox } from '../../../../../legacy-redux/actions/boxActions';

export function openConfirmationBar(payload) {
    return openBox(
        {
            name: boxEnum.CONFIRMATION_BAR,
        },
        payload
    );
}

export function closeConfirmationBar() {
    return closeBox({
        name: boxEnum.CONFIRMATION_BAR,
    });
}

export const confirmationBarSelector = (state) => state.ui.box.CONFIRMATION_BAR;

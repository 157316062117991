/* eslint-disable camelcase */
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Offense Codes Resource',
        methods: {
            searchForOffenseCodes({
                q,
                includeExpired,
                includeDisabled,
                includeIsIncidentType,
                includeIsOffenseType,
                includeIsCrimeCharge,
                includeIsWarrantChargeLocal,
                includeIsWarrantChargeExternal,
                includeIsDuiCharge,
                includeIsNonCustodialCharge,
                includeIsTrafficCharge,
                statuteCodeSetAttrIds,
                size,
                from,
            }) {
                return req({
                    method: 'GET',
                    url: 'offense/code',
                    params: {
                        q,
                        include_expired: includeExpired,
                        include_disabled: includeDisabled,
                        include_is_incident_type: includeIsIncidentType,
                        include_is_offense_type: includeIsOffenseType,
                        include_is_crime_charge: includeIsCrimeCharge,
                        include_is_warrant_charge_local: includeIsWarrantChargeLocal,
                        include_is_warrant_charge_external: includeIsWarrantChargeExternal,
                        include_is_dui_charge: includeIsDuiCharge,
                        include_is_non_custodial_charge: includeIsNonCustodialCharge,
                        include_is_traffic_charge: includeIsTrafficCharge,
                        statute_code_set_attr_id: statuteCodeSetAttrIds,
                        size,
                        from,
                    },
                });
            },
            searchForFullOffenseCodes({
                q,
                includeExpired,
                includeDisabled,
                includeIsIncidentType,
                includeIsOffenseType,
                includeIsCrimeCharge,
                includeIsWarrantChargeLocal,
                includeIsWarrantChargeExternal,
                includeIsDuiCharge,
                includeIsNonCustodialCharge,
                includeIsTrafficCharge,
                statuteCodeSetAttrIds,
                size,
                from,
            }) {
                return req({
                    method: 'GET',
                    url: 'offense/code/full',
                    params: {
                        q,
                        include_expired: includeExpired,
                        include_disabled: includeDisabled,
                        include_is_incident_type: includeIsIncidentType,
                        include_is_offense_type: includeIsOffenseType,
                        include_is_crime_charge: includeIsCrimeCharge,
                        include_is_warrant_charge_local: includeIsWarrantChargeLocal,
                        include_is_warrant_charge_external: includeIsWarrantChargeExternal,
                        include_is_dui_charge: includeIsDuiCharge,
                        include_is_non_custodial_charge: includeIsNonCustodialCharge,
                        include_is_traffic_charge: includeIsTrafficCharge,
                        statute_code_set_attr_id: statuteCodeSetAttrIds,
                        size,
                        from,
                    },
                });
            },
            getValidOffenseCodeByNameAndDate({ displayName, dateTime }) {
                return req({
                    method: 'GET',
                    url: 'offense/code/full/basis_date',
                    params: {
                        display_name: displayName,
                        date_time: dateTime,
                    },
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

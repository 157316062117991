import { logWarning } from '../../../core/logging';

export const getMachineNameFromMetadata = ({
    data,
    port,
}: {
    data: { machineName: string | undefined } | string | undefined;
    port: string | number | boolean | null | undefined;
}) => {
    let machineName;

    if (typeof data === 'string') {
        try {
            const parsedData = JSON.parse(data);
            machineName = parsedData?.machineName;
        } catch (error) {
            logWarning('Failed to parse data from getDesktopMetadata', { data, port });
            throw new Error('Failed to parse data from getDesktopMetadata');
        }
    } else if (typeof data === 'object') {
        machineName = data.machineName;
    }

    if (typeof machineName !== 'string') {
        logWarning('Did not get a machineName from getDesktopMetadata', { data, port });
        throw new Error('Did not get a machineName from getDesktopMetadata');
    }
    return machineName;
};

import React from 'react';
import { connect } from 'react-redux';
import { isFunction } from 'lodash';
import { createStructuredSelector } from 'reselect';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import Modal from '../../../../../legacy-redux/components/core/Modal';
import { closeBox } from '../../../../../legacy-redux/actions/boxActions';
import {
    submitOffenseIncidentDeletionModal,
    offenseIncidentDeletionModalContext,
    offenseIncidentDeletionModalEntityDataSelector,
} from '../../state/ui';
import useQuickAddCallbacks from '../../hooks/useQuickAddCallbacks';

const strings = componentStrings.reports.core.OffenseIncidentDeletionModal;

function OffenseIncidentDeletionModal({ closeBox, deleteEntity, entityData }) {
    const { nameQuickAddCallback } = useQuickAddCallbacks();
    const offenseDisplayName = useOffenseFieldName();

    return (
        <Modal
            context={offenseIncidentDeletionModalContext}
            title={entityData ? strings.title(entityData.isIncident, offenseDisplayName) : ''}
            okText={strings.okText}
            onClose={closeBox}
            onSave={() =>
                deleteEntity().then(() => {
                    if (isFunction(nameQuickAddCallback)) {
                        nameQuickAddCallback();
                    }
                })
            }
        >
            {entityData ? strings.message(entityData.isIncident, offenseDisplayName) : ''}
        </Modal>
    );
}

const mapDispatchToProps = {
    closeBox: () => closeBox(offenseIncidentDeletionModalContext),
    deleteEntity: () => submitOffenseIncidentDeletionModal(),
};

export default connect(
    createStructuredSelector({
        entityData: offenseIncidentDeletionModalEntityDataSelector,
    }),
    mapDispatchToProps
)(OffenseIncidentDeletionModal);

import { createSelector } from 'reselect';
import routesConfig from '../../routing/routesConfig';
import { routeNameSelector } from '../../routing/routerModule';

const globalUiState = (state) => state.ui.global;
const globalPersistentState = (state) => state.persistent.global;
export const windowWidthSelector = (state) => state.ui.global.windowWidth;
export const windowHeightSelector = (state) => state.ui.global.windowHeight;
export const shouldShowFieldNamesSelector = (state) => state.ui.global.showFieldNames;

export const globalErrorSelector = (state) => state.ui.error;

export const currentRouteHasLegacySubheaderSelector = createSelector(
    routeNameSelector,
    (routeName) =>
        routeName && routesConfig[routeName] ? routesConfig[routeName].hasLegacySubheader : false
);

export const loadingBarVisibleSelector = (state) => !!state.ui.global.loadingBarVisible;
export const additionalContentTopSelector = (state) => state.ui.global.additionalContentTop;
export const overrideCreateReportModalV2Selector = (state) =>
    state.ui.global.overrideCreateReportModalV2;

// the black / flashing "mark43" loading mask
export const loadingMaskVisibleSelector = createSelector(
    globalUiState,
    ({ loadingMaskVisible }) => !!loadingMaskVisible
);

// for possible statuses see DepartmentStatusEnum
export const departmentStatusIndicatorSelector = createSelector(
    globalPersistentState,
    ({ departmentStatusIndicator }) => departmentStatusIndicator
);

import {
    UsageSourceModuleEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    EntityTypeEnum,
} from '@mark43/rms-api';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router';
import { map, parseInt, orderBy, first } from 'lodash';
import styled from 'styled-components';
import { Box } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { fieldNotesWhereSelector } from '~/client-common/core/domain/field-notes/state/data';
import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import { NoResults } from '../../../core/components/NoResults';
import { RmsMobileErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import { loadFieldNotes } from '../state/data';

import { createUsageLog } from '../../../admin/usage-logs/state/data';
import { RmsDispatch } from '../../../../core/typings/redux';
import NotepadSidebar from './NotepadSidebar';
import NotepadListItem from './NotepadListItem';

const strings = componentStrings.notepad;

const LayoutWrapper = styled.div`
    flex: 1;
    min-height: 0;
`;

const PageContent = styled.div`
    display: flex;
    flex: 1;
    min-height: 0;
`;

const SideNavColumn = styled(Box)`
    width: 10rem;
    overflow-y: auto;
    border-right: solid 1px var(--arc-colors-border-default);
`;

const MobileListColumn = styled(Box)`
    overflow: auto;
    flex: 1;
    width: 18rem;
    display: block;
    background-color: ${(props) => props.theme.colors.white};
    border-right: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    overflow-y: auto;
    border-right: solid 1px var(--arc-colors-border-default);
`;

const MobileCollectionColumn = styled(Box)`
    background-color: ${(props) => props.theme.colors.white};
    min-height: 100%;
    flex: 1;
    overflow-y: auto;
    border-right: solid 1px var(--arc-colors-border-default);
`;

/**
 * Route component for the mobile section container.
 */
const mapStateToProps = createStructuredSelector({
    fieldNotesWhere: fieldNotesWhereSelector,
});

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    loadFieldNotes: (isArchived: boolean) => {
        const action = isArchived
            ? UsageActionEnum.VIEWED_MOBILE_COLLECTION_ARCHIVE.name
            : UsageActionEnum.VIEWED_MOBILE_COLLECTIONS.name;
        return dispatch(loadFieldNotes(isArchived))
            .then((results) => {
                dispatch(
                    createUsageLog({
                        action,
                        primaryEntityType: EntityTypeEnum.FIELD_NOTE.name,
                        completion: UsageCompletionEnum.SUCCEEDED.name,
                        sourceModule: UsageSourceModuleEnum.RMS_MOBILE.name,
                    })
                );
                return results;
            })
            .catch((err) => {
                dispatch(
                    createUsageLog({
                        action,
                        primaryEntityType: EntityTypeEnum.FIELD_NOTE.name,
                        completion: UsageCompletionEnum.SERVER_ERROR.name,
                        sourceModule: UsageSourceModuleEnum.RMS_MOBILE.name,
                    })
                );
                throw err;
            });
    },
});
const connector = connect(mapStateToProps, mapDispatchToProps);

interface State {
    loading: boolean;
}

type Props = RouteComponentProps<{ id: string }, Record<string, unknown>> &
    ConnectedProps<typeof connector>;

class Notepad extends React.Component<Props, State> {
    state: State = {
        loading: true,
    };

    componentDidMount() {
        const isArchiveRoute = this.props.location.pathname.includes('archive');
        this.props.loadFieldNotes(isArchiveRoute).then((fieldNotes) => {
            this.setState({
                loading: false,
            });
            if (!this.props.params.id) {
                const firstFieldNote = first(orderBy(fieldNotes, ['updatedDateUtc'], ['desc']));
                if (firstFieldNote) {
                    this.props.router.push(`/mobile/collections/${firstFieldNote.id}`);
                }
            }
        });
    }

    componentDidUpdate(prevProps: Props) {
        const oldIsArchiveRoute = prevProps.location.pathname.includes('archive');
        const isArchiveRoute = this.props.location.pathname.includes('archive');
        if (isArchiveRoute !== oldIsArchiveRoute) {
            this.props.loadFieldNotes(isArchiveRoute);
        }
    }

    render() {
        const { fieldNotesWhere, children, params, location } = this.props;
        const isArchiveRoute = location.pathname.includes('archive');
        const fieldNotes = orderBy(
            fieldNotesWhere({ isArchived: isArchiveRoute }),
            ['updatedDateUtc'],
            ['desc']
        );
        const selectedId = parseInt(params.id);
        return (
            <RmsMobileErrorBoundary>
                <Page>
                    <Subheader title={strings.title} />
                    <LayoutWrapper className="mark43-page-under-subheader">
                        <PageContent className="mark43-auto-center">
                            <SideNavColumn>
                                <NotepadSidebar />
                            </SideNavColumn>
                            <MobileListColumn>
                                {fieldNotes.length === 0 && !this.state.loading ? (
                                    <NoResults>
                                        {isArchiveRoute
                                            ? strings.noArchivedCollections
                                            : strings.noCollections}
                                    </NoResults>
                                ) : (
                                    map(fieldNotes, (fieldNote) => {
                                        return (
                                            <NotepadListItem
                                                isArchiveRoute={isArchiveRoute}
                                                active={fieldNote.id === selectedId}
                                                key={fieldNote.id}
                                                fieldNote={fieldNote}
                                            />
                                        );
                                    })
                                )}
                            </MobileListColumn>
                            <MobileCollectionColumn>{children}</MobileCollectionColumn>
                        </PageContent>
                    </LayoutWrapper>
                </Page>
            </RmsMobileErrorBoundary>
        );
    }
}

export default compose<Props, Props>(withRouter, connector)(Notepad);

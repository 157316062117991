import React from 'react';
import _ from 'lodash';
import { Skeleton, SkeletonText, Stack, HStack, cssVar, Box, SimpleGrid } from 'arc';

export const DashboardRightCardsLoader = function ({ hasHeader = true, rows = 5 }) {
    const Header = () => {
        return (
            <HStack
                padding="var(--arc-space-3) 0"
                borderBottom="solid 1px var(--arc-colors-border-default)"
                spacing="var(--arc-space-6)"
            >
                <Box width="25%" height="0.5rem">
                    <Skeleton width="8rem" height="100%" />
                </Box>
                <Box width="15%" height="0.5rem">
                    <Skeleton width="4rem" height="100%" />
                </Box>
                <Box width="50%" height="0.5rem">
                    <Skeleton width="8rem" height="100%" />
                </Box>
                <Box width="10%" height="0.5rem">
                    <Skeleton width="2rem" height="100%" />
                </Box>
            </HStack>
        );
    };
    const body = _.range(rows).map((i) => {
        return (
            <HStack
                spacing="var(--arc-space-6)"
                key={`loader-${i}`}
                padding="var(--arc-space-3) 0"
                borderBottom="solid 1px var(--arc-colors-border-default)"
            >
                <Skeleton width="25%" height=".75rem" />
                <Skeleton width="15%" height=".75rem" />
                <Skeleton width="50%" height=".75rem" />
                <Skeleton width="10%" height=".75rem" />
            </HStack>
        );
    });

    return (
        <Stack mt="8px" spacing={0}>
            {hasHeader && <Header />}
            {body}
        </Stack>
    );
};

export const DashboardLeftSidebarLoader = function ({ rows = 3 }) {
    const body = _.range(rows).map((i) => {
        return (
            <HStack align="top" key={`loader-${i}`}>
                <Skeleton width="24px" />
                <SkeletonText width="100%" noOfLines={2} />
            </HStack>
        );
    });

    return (
        <Stack spacing={cssVar('arc-space-4')} mt={cssVar('arc-space-2')}>
            {body}
        </Stack>
    );
};

export const DashboardRecentArrestsLoader = function () {
    const arrestShape = (
        <HStack align="top" spacing="var(--arc-space-3)">
            <Skeleton width="86px" height="86px" />
            <Stack spacing={3} flex="1">
                <Skeleton width="50%" height="1rem" />
                <SkeletonText noOfLines={2} />
            </Stack>
        </HStack>
    );
    return (
        <SimpleGrid mt={cssVar('arc-space-4')} columns={2} gap={cssVar('arc-space-4')}>
            {arrestShape}
            {arrestShape}
            {arrestShape}
            {arrestShape}
            {arrestShape}
            {arrestShape}
        </SimpleGrid>
    );
};

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Report Resource',
        methods: {
            getReportShortTitles(reportIds) {
                return req({
                    method: 'POST',
                    url: 'reports/titles',
                    data: reportIds,
                });
            },
            getReportShortTitlesByReportingEventId(reportingEventId) {
                return req({
                    method: 'GET',
                    url: `reports/titles/${reportingEventId}`,
                });
            },
        },
    });
}

/**
 * Resources for ReportShortTitles. This is temporarily a function to avoid
 *   dependency problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import styled from 'styled-components';
import rmsTheme from '../../core/styles/theme';

const ComplianceErrorNotification = styled.div<{
    theme?: typeof rmsTheme;
    fontSize?: string | number;
    padding?: string;
    textAlign?: string;
}>`
    font: ${(props) => {
        const fontWeight = props.theme.fontWeights.regular;
        const fontFamily = props.theme.fontFamilies.proximaNova;
        const fontSize = props.fontSize || 0.875;
        return `${fontWeight} ${fontSize}rem ${fontFamily}`;
    }};
    color: ${(props) => props.theme.colors.red};
    padding: ${(props) => props.padding || '20px 10px'};
    text-align: ${(props) => props.textAlign || 'center'};
    font-style: italic;
`;

export default ComplianceErrorNotification;

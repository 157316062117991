import { AttributeTypeEnum, CrashDetailCardBundle, ReportAttribute } from '@mark43/rms-api';

import { NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-attributes/state/data';
import {
    CrashDetailCardBundleForUpsert,
    NEXUS_STATE_PROP,
    updateCrashEventInfoFailure,
    updateCrashEventInfoStart,
    updateCrashEventInfoSuccess,
} from '~/client-common/core/domain/crash-details/state/data';

import { RmsAction } from '../../../../../core/typings/redux';
import crashDetailResource from '../../resources/crashDetailResource';

export const CRASH_EVENT_INFO_REPORT_ATTRIBUTE_TYPES = [
    AttributeTypeEnum.WEATHER.name,
    AttributeTypeEnum.QC_ROADWAY_SURFACE_CONDITION.name,
    AttributeTypeEnum.QC_LIGHT_CONDITION.name,
    AttributeTypeEnum.QC_CRASH_SEVERITY.name,
    AttributeTypeEnum.CRASH_FIXED_OBJECT_TYPE.name,
    AttributeTypeEnum.QC_FIRST_HARMFUL_EVENT.name,
    AttributeTypeEnum.CRASH_CONTRIBUTING_FACTOR.name,
    AttributeTypeEnum.CRASH_MUNICIPALITY_CODE.name,
] as const;

const crashEventInfoAttributesFilter = (reportId: number) => (reportAttribute: ReportAttribute) =>
    reportAttribute.reportId === reportId &&
    CRASH_EVENT_INFO_REPORT_ATTRIBUTE_TYPES.some(
        (attributeType) => attributeType === reportAttribute.attributeType
    );

export const updateCrashEventInfo =
    (
        crashDetailCardBundle: CrashDetailCardBundleForUpsert,
        reportId: number
    ): RmsAction<Promise<CrashDetailCardBundle>> =>
    (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) => {
        dispatch(updateCrashEventInfoStart(crashDetailCardBundle));
        return crashDetailResource
            .upsertCrashDetailCard(crashDetailCardBundle, reportId)
            .then((updatedCardBundle) => {
                dispatch(
                    withRemoveMultiple(
                        {
                            [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]:
                                crashEventInfoAttributesFilter(reportId),
                        },

                        withEntityItems(
                            {
                                [NEXUS_STATE_PROP]: [updatedCardBundle.crashDetail],
                                [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]:
                                    updatedCardBundle.reportAttributes,
                            },
                            { type: 'STORE_CRASH_EVENT_INFO' }
                        )
                    )
                );
                dispatch(updateCrashEventInfoSuccess(updatedCardBundle));
                return updatedCardBundle;
            })
            .catch((err: Error) => {
                dispatch(updateCrashEventInfoFailure(err.message));
                throw err;
            });
    };

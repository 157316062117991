import React, { useState } from 'react';
import { first, get, size, take } from 'lodash';
import styled from 'styled-components';

import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';

import componentStrings from '~/client-common/core/strings/componentStrings';

import _Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import NoteContentCell from '../../../../legacy-redux/components/core/tables/caseNotesTable/NoteContentCell';
import NoteTitleAuthorDateCell from '../../../../legacy-redux/components/core/tables/caseNotesTable/NoteTitleAuthorDateCell';

const strings = componentStrings.cases.caseSummary.CaseNotesTable;

const MAX_NOTES_SHOWN = 100;

const Table = styled(_Table)`
    min-height: 170px;
`;

function CaseNotesTable({ caseNotes }) {
    const sortColumn = (
        <OptionsTableColumnOption
            display={strings.columns.titleAuthorDate}
            value="noteTitleAuthorDate"
            sortOptions={[
                {
                    display: strings.sortDsc,
                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                },
                {
                    display: strings.sortAsc,
                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                },
            ]}
        />
    );
    const [activeSortColumn, setActiveSortColumn] = useState(sortColumn.props);
    const [tableSort, setTableSort] = useState(
        get(first(sortColumn.props.sortOptions), 'sortType')
    );
    const handleSortTypeClick = function (selectedSortType) {
        setTableSort(selectedSortType);
    };
    // Show the most recent N `CaseNote`s in the table
    const caseNotesShownInTable =
        size(caseNotes) > MAX_NOTES_SHOWN
            ? take(
                  sortByNaturalOrder(caseNotes, ['createdDateUtc', 'id'], ['desc', 'desc']),
                  MAX_NOTES_SHOWN
              )
            : caseNotes;
    const sortedCaseNotes = take(
        sortByNaturalOrder(
            caseNotesShownInTable,
            ['createdDateUtc', 'id'],
            [tableSort === sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT ? 'desc' : 'asc', 'desc']
        ),
        MAX_NOTES_SHOWN
    );

    return (
        <Table
            data={sortedCaseNotes}
            noRowsText={strings.noResult}
            simpleStyles={false}
            className="case-notes-table"
            tableColumnsClassName="no-top-border"
        >
            <TableHeader>
                <TableColumns>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeSortColumn.value}
                        activeValue={activeSortColumn.value}
                        activeSortType={tableSort}
                        onOptionClick={setActiveSortColumn}
                        onSortTypeClick={handleSortTypeClick}
                        width={214}
                        popoutWidth={214}
                    >
                        {sortColumn}
                    </OptionsTableColumn>
                    <TableColumn
                        display={strings.columns.note}
                        columnKey="noteContent"
                        width={550}
                    />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <NoteTitleAuthorDateCell columnKey="noteTitleAuthorDate" />
                <NoteContentCell columnKey="noteContent" />
            </TableBody>
        </Table>
    );
}

export default CaseNotesTable;

import React from 'react';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { NAME_CASE_LINK_TARGET_PROFILE_LEVEL_ATTR_ID } from '~/client-common/core/enums/universal/fields';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import CaseProfileIndicator from './CaseProfileIndicator';

export default function CaseTargetLevelIndicator({ attributeIds }: { attributeIds: number[] }) {
    const displayFields = useFields(NAME_CASE_LINK_TARGET_PROFILE_LEVEL_ATTR_ID);

    return (
        <CaseProfileIndicator title={displayFields.NAME_CASE_LINK_TARGET_PROFILE_LEVEL_ATTR_ID}>
            {
                attributeIds
                    .map((attrId) => (
                        <ConnectedFormattedAttribute attributeId={attrId} key={attrId} />
                    ))
            }
        </CaseProfileIndicator>
    );
}

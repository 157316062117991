import { ReportStatusHistory } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import _ from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'reportStatusHistories';

const reportStatusHistoriesModule = createNormalizedModule<ReportStatusHistory>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS

const reportStatusHistoriesWhereSelector =
    reportStatusHistoriesModule.selectors.entitiesWhereSelector;

export const reportStatusHistoriesByReportIdSelector = createSelector(
    reportStatusHistoriesWhereSelector,
    (reportStatusHistoriesWhere) => (reportId: number) => reportStatusHistoriesWhere({ reportId })
);
export const orderedReportStatusHistoriesByReportIdSelector = createSelector(
    reportStatusHistoriesWhereSelector,
    (reportStatusHistoriesWhere) => (reportId: number) => {
        const reportHistories = reportStatusHistoriesWhere({ reportId });
        return _(reportHistories)
            .filter((submission) => {
                return submission && !!submission.createdBy;
            })
            .sortBy(['createdDateUtc', 'rmsEventId'])
            .reverse()
            .value();
    }
);

// REDUCER
export default reportStatusHistoriesModule.reducerConfig;

import React, { useContext } from 'react';
import { EntityTypeEnumType } from '@mark43/rms-api';
import styled from 'styled-components';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import OverlayButton from '../../../core/overlays/components/OverlayButton';
import { Tooltip } from '../../../core/components/tooltip';
import { TestId } from '../../../../core/testIds';
import { CurrentFolderIdContext } from '../contexts';
import { EntityTypeIdContext } from './CreateFolderModal';

const strings = componentStrings.cases.core.KebabMenu;

interface FolderOverlayButtonProps {
    children: React.ReactNode;
    overlayId: string;
    folderId: number;
    entityTypeId: EntityTypeEnumType;
    testId?: TestId;
}

const StyledOverlayButtonDiv = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const FolderOverlayButton: React.FC<FolderOverlayButtonProps> = ({
    children,
    overlayId,
    folderId,
    entityTypeId,
    testId,
}) => {
    const { setCurrentFolderId } = useContext(CurrentFolderIdContext);
    const { setEntityTypeId } = useContext(EntityTypeIdContext);

    return (
        <OverlayButton id={overlayId}>
            {(openOverlay: () => void) => (
                <Tooltip content={<span>{strings.createNewFolder}</span>} side="top">
                    <StyledOverlayButtonDiv
                        children={children}
                        onClick={() => {
                            openOverlay();
                            if (setCurrentFolderId) {
                                setCurrentFolderId(folderId);
                            }
                            if (setEntityTypeId) {
                                setEntityTypeId(entityTypeId);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === keyCodeEnum.ENTER) {
                                openOverlay();
                                if (setCurrentFolderId) {
                                    setCurrentFolderId(folderId);
                                }
                                if (setEntityTypeId) {
                                    setEntityTypeId(entityTypeId);
                                }
                            }
                        }}
                        tabIndex={0}
                        data-test-id={testId}
                    />
                </Tooltip>
            )}
        </OverlayButton>
    );
};

export default FolderOverlayButton;

import {
    RefContextEnum,
    CoreModelEnum,
    LocationView,
    EntityTypeEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';
import {
    createFormConfiguration,
    createField,
    createFieldset,
    InferFormDataShape,
} from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import {
    CrashLocationWithCustomProperties,
    crashLocationsWhereSelector,
    CrashLocationCardBundleForUpsert,
} from '~/client-common/core/domain/crash-locations/state/data';
import { sortedLocationBundlesForLocationEntityLinksWhereSelector } from '~/client-common/core/domain/locations/state/ui';

import { buildFormConfigurationForCoreModel } from '../../../../core/markformythree-arbiter/dynamic-fields/helpers/buildFormConfigurationForCoreModel';
import { BuildFormConfigurationForCoreModelAdditionalData } from '../../../../core/markformythree-arbiter/dynamic-fields/types';
import { CUSTOM_PROPERTIES_KEY_NAME } from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';

import formsRegistry from '../../../../../core/formsRegistry';
import { RmsAction } from '../../../../../core/typings/redux';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

export const TRAFFIC_CRASH_LOCATION_PATH = 'location';
export const TRAFFIC_CRASH_LONGITUDE_PATH = `${TRAFFIC_CRASH_LOCATION_PATH}.longitude`;
export const TRAFFIC_CRASH_LATITUDE_PATH = `${TRAFFIC_CRASH_LOCATION_PATH}.latitude`;

export const formName = RefContextEnum.FORM_CRASH_LOCATION_INFO.name;
const getTrafficCrashLocationForm = () => formsRegistry.get(formName);

export const trafficCrashLocationFormConfiguration = (
    additionalData: BuildFormConfigurationForCoreModelAdditionalData
) =>
    createFormConfiguration({
        [TRAFFIC_CRASH_LOCATION_PATH]: createFieldset({
            fieldName: fields.DISPLAY_ONLY_TRAFFIC_CRASH_LOCATION_WRAPPER,
            fields: {
                id: createField<number>({}),
                locationId: createField<number>({
                    fieldName: fields.LOCATION_VIEW_ID,
                }),
                longitude: createField<number>({
                    fieldName: fields.LOCATION_VIEW_LONGITUDE,
                }),
                latitude: createField<number>({
                    fieldName: fields.LOCATION_VIEW_LATITUDE,
                }),
            },
        }),
        ...buildFormConfigurationForCoreModel({
            keyName: CUSTOM_PROPERTIES_KEY_NAME,
            context: formName,
            coreModelName: CoreModelEnum.CRASH_LOCATION.name,
            additionalData,
        }),
    });

export const convertToFormModel = (
    locationView: Pick<LocationView, 'id' | 'longitude' | 'latitude'>,
    crashLocation?: CrashLocationWithCustomProperties
) => {
    return {
        [TRAFFIC_CRASH_LOCATION_PATH]: {
            locationId: locationView?.id,
            longitude: locationView?.longitude,
            latitude: locationView?.latitude,
            id: crashLocation?.id,
        },
        [CUSTOM_PROPERTIES_KEY_NAME]: crashLocation?.customProperties,
    };
};

export type CrashLocationFormConfiguration = ReturnType<
    typeof trafficCrashLocationFormConfiguration
>;
type CrashLocationFormDataShape = InferFormDataShape<CrashLocationFormConfiguration>;

export const buildCrashLocationFormModel = (): RmsAction<void> => (dispatch, getState) => {
    const state = getState();
    const form = getTrafficCrashLocationForm();
    const reportId = currentReportIdSelector(state);

    if (!form || !reportId) {
        return;
    }
    const locationView = sortedLocationBundlesForLocationEntityLinksWhereSelector(state)({
        entityType: EntityTypeEnum.REPORT.name,
        entityId: reportId,
        linkType: LinkTypesEnum.LOCATION_OF_TRAFFIC_CRASH,
    })[0]?.location;

    const crashLocation = crashLocationsWhereSelector(state)({ reportId })[0];
    const formModel = convertToFormModel(locationView, crashLocation);
    form.set('', formModel);
};

export const convertFromFormModel = (
    crashLocationFormModel: CrashLocationFormDataShape,
    currentReportId: number
): CrashLocationCardBundleForUpsert | undefined => {
    // @ts-expect-error needs a new util type
    const { location, customProperties } = crashLocationFormModel;
    if (!location || !location?.locationId) {
        return;
    }
    return {
        crashLocation: {
            id: location.id,
            locationId: location.locationId,
            reportId: currentReportId,
            [CUSTOM_PROPERTIES_KEY_NAME]: customProperties,
        },
    };
};

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose, withPropsOnChange } from 'recompose';
import _, { includes, orderBy } from 'lodash';
import { mediaQueries } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { exportReleaseViewModelsWhereSelector } from '~/client-common/core/domain/export-releases/state/ui';
import { loadExportReleases } from '~/client-common/core/domain/export-releases/state/data';
import { emailExportReleaseViewModelsWhereSelector } from '~/client-common/core/domain/email-export-releases/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { OnlyWithAbility } from '../../../abilities';
import { releaseTrackingUiSelector } from '../state/ui';
import { SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { NoResults } from '../../../components/NoResults';
import { InlineBanner } from '../../../components/InlineBanner';
import { VirtualizedList } from '../../../components/VirtualizedList';
import testIds from '../../../../../core/testIds';
import { SecondarySectionHeader } from '../../../components/typography';
import SendEmailModal from '../../../emails/components/SendEmailModal';
import ReleaseHistoryBlock from './ReleaseHistoryBlock';

import { EmailReleaseHistoryBlock } from './EmailReleaseHistoryBlock';

const strings = componentStrings.exports.releaseTracking.components.ReleaseTracking;

const mapStateToProps = createStructuredSelector({
    applicationSetings: applicationSettingsSelector,
    exportReleaseViewModelsWhere: exportReleaseViewModelsWhereSelector,
    emailExportReleaseViewModelsWhere: emailExportReleaseViewModelsWhereSelector,
    ui: releaseTrackingUiSelector,
});

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FlexColumn = styled(VirtualizedList)`
    flex: 1 1 100%;
    overflow: visible;

    @media (min-width: ${mediaQueries.md}) {
        overflow: auto;
    }
`;

class ReleaseTrackingBase extends React.Component {
    state = { exportReleaseShowDetails: {} };

    componentDidMount() {
        this.props.loadExportReleases(this.props.entityType, this.props.entityId);
    }

    toggleOpen = (id) => {
        this.setState((state) => ({
            exportReleaseShowDetails: {
                ...state.exportReleaseShowDetails,
                [id]: !state.exportReleaseShowDetails[id],
            },
        }));
    };

    render() {
        const { exportReleaseViewModels, ui } = this.props;
        const filteredExportReleaseViewModels = _(exportReleaseViewModels).filter('fileId').value();

        return (
            <>
                <FlexContainer data-test-id={testIds.EXPORTS_RELEASE_CONTAINER}>
                    {ui.loading ? (
                        <SimpleLoading />
                    ) : (
                        <>
                            <SecondarySectionHeader>{strings.title}</SecondarySectionHeader>
                            {ui.error ? (
                                <InlineBanner status="error">{ui.error}</InlineBanner>
                            ) : filteredExportReleaseViewModels.length > 0 ? (
                                <FlexColumn items={filteredExportReleaseViewModels}>
                                    {(exportReleaseViewModel) => {
                                        const { type } = getViewModelProperties(
                                            exportReleaseViewModel
                                        );

                                        if (type === 'EMAIL') {
                                            return (
                                                <EmailReleaseHistoryBlock
                                                    key={exportReleaseViewModel.id}
                                                    emailExportRelease={exportReleaseViewModel}
                                                    showDetails={
                                                        this.state.exportReleaseShowDetails[
                                                            exportReleaseViewModel.id
                                                        ]
                                                    }
                                                    toggleOpen={() =>
                                                        this.toggleOpen(exportReleaseViewModel.id)
                                                    }
                                                />
                                            );
                                        }

                                        return (
                                            <ReleaseHistoryBlock
                                                key={exportReleaseViewModel.id}
                                                exportReleaseId={exportReleaseViewModel.id}
                                                exportReleaseViewModel={exportReleaseViewModel}
                                                showDetails={
                                                    this.state.exportReleaseShowDetails[
                                                        exportReleaseViewModel.id
                                                    ]
                                                }
                                                toggleOpen={() =>
                                                    this.toggleOpen(exportReleaseViewModel.id)
                                                }
                                            />
                                        );
                                    }}
                                </FlexColumn>
                            ) : (
                                <NoResults>{strings.noReleases}</NoResults>
                            )}
                        </>
                    )}
                </FlexContainer>
                <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                    <SendEmailModal />
                </FeatureFlagged>
            </>
        );
    }
}

export const ReleaseTracking = compose(
    connect(mapStateToProps, { loadExportReleases }),
    withPropsOnChange(
        [
            'entityType',
            'entityId',
            'applicationSettings',
            'exportReleaseViewModelsWhere',
            'emailExportReleaseViewModelsWhere',
            'ui',
        ],
        ({
            entityType,
            entityId,
            applicationSetings,
            exportReleaseViewModelsWhere,
            emailExportReleaseViewModelsWhere,
            ui: { releaseIds },
        }) => {
            let exportReleaseViewModels = [];
            if (entityType === EntityTypeEnum.REPORT.name) {
                // Reports is a little different from the other cases
                // For reports, we want to get the export releases for all reports under the same REN
                const exportReleases = exportReleaseViewModelsWhere(({ id }) =>
                    includes(releaseIds, id)
                );

                let emailExportReleases = [];
                if (!!applicationSetings.RMS_DIRECT_EMAILS_ENABLED) {
                    emailExportReleases = emailExportReleaseViewModelsWhere(({ id }) =>
                        includes(releaseIds, id)
                    );
                }
                exportReleaseViewModels = [...exportReleases, ...emailExportReleases];
            } else {
                exportReleaseViewModels = exportReleaseViewModelsWhere({ entityType, entityId });
            }
            return {
                exportReleaseViewModels: orderBy(
                    exportReleaseViewModels,
                    ['releasedDateUtc'],
                    ['desc']
                ),
            };
        }
    )
)(function ReleaseTrackingWrapper(props) {
    return (
        <FeatureFlagged flag="RELEASE_TRACKING_ENABLED">
            <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_EXPORT_RELEASE_TRACKING}>
                <ReleaseTrackingBase {...props} />
            </OnlyWithAbility>
        </FeatureFlagged>
    );
});

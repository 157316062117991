import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import {
    formatAttributeWithOtherSelector,
    formatAttributeByIdSelector,
    formatLinkAttributesSelector,
    formatAttributeAbbrevAndValueByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';

import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import testIds from '../../../../../core/testIds';

const mapStateToProps = createStructuredSelector({
    formatAttributeById: formatAttributeByIdSelector,
    formatAttributeWithOther: formatAttributeWithOtherSelector,
    formatAttributeAbbrevAndValueById: formatAttributeAbbrevAndValueByIdSelector,
    formatLinkAttributes: formatLinkAttributesSelector,
    formatMiniUserById: formatMiniUserByIdSelector,
});

const ArrestCardSummary = connect(mapStateToProps)(function _ArrestCardSummary({
    arrest = {},
    arrestAttributes,
    formatAttributeById,
    formatAttributeAbbrevAndValueById,
    formatAttributeWithOther,
    formatLinkAttributes,
    formatMiniUserById,
}) {
    return (
        <SummaryList labelWidth={160} contentWidth={380} testId={testIds.CARD_SUMMARY}>
            <SummaryRowDate
                fieldName={fields.ARREST_ARREST_DATE_UTC}
                date={arrest.arrestDateUtc}
                format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
            />
            <SummaryRow fieldName={fields.ARREST_ARRESTING_AGENCY_ATTR_ID}>
                {formatAttributeAbbrevAndValueById(arrest.arrestingAgencyAttrId)}
            </SummaryRow>
            <SummaryRow fieldName={fields.ARREST_ARREST_TYPE_ATTR_ID}>
                {formatAttributeById(arrest.arrestTypeAttrId)}
            </SummaryRow>
            <SummaryRow
                fieldName={fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_STATUS_ATTRIBUTE_ID}
            >
                {formatLinkAttributes(arrestAttributes, AttributeTypeEnum.ARREST_STATUS.name)}
            </SummaryRow>
            <SummaryRow fieldName={fields.ARREST_TICKET_NUMBERS}>{arrest.ticketNumbers}</SummaryRow>
            <SummaryRow
                fieldName={fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_TACTICS_ATTRIBUTE_ID}
            >
                {formatLinkAttributes(arrestAttributes, AttributeTypeEnum.ARREST_TACTICS.name)}
            </SummaryRow>
            <SummaryRow fieldName={fields.ARREST_ARRESTING_OFFICER_ID}>
                {formatMiniUserById(arrest.arrestingOfficerId)}
            </SummaryRow>
            <SummaryRow
                fieldName={
                    fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARRESTEE_WAS_ARMED_WITH_ATTRIBUTE_ID
                }
            >
                {formatLinkAttributes(
                    arrestAttributes,
                    AttributeTypeEnum.ARRESTEE_WAS_ARMED_WITH.name
                )}
            </SummaryRow>
            <SummaryRow fieldName={fields.ARREST_IS_ARRESTEE_SUSPECTED_USING}>
                {booleanToYesNo(arrest.isArresteeSuspectedUsing)}
            </SummaryRow>
            <SummaryRow
                fieldName={
                    fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_ARRESTEE_SUSPECTED_USING_ATTRIBUTE_ID
                }
            >
                {formatLinkAttributes(
                    arrestAttributes,
                    AttributeTypeEnum.ARREST_ARRESTEE_SUSPECTED_USING.name
                )}
            </SummaryRow>
            <SummaryRow
                fieldName={fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_STATISTICS_ATTRIBUTE_ID}
            >
                {formatLinkAttributes(arrestAttributes, AttributeTypeEnum.ARREST_STATISTICS.name)}
            </SummaryRow>
            <SummaryRow fieldName={fields.ARREST_RELEASED_BY_OFFICER_ID}>
                {/* Used by migrations and only shown in summary mode, not edit mode. */}
                {formatMiniUserById(arrest.releasedByOfficerId)}
            </SummaryRow>
            <SummaryRow fieldName={fields.ARREST_JUVENILE_TRIED_AS_ADULT}>
                {booleanToYesNo(arrest.juvenileTriedAsAdult)}
            </SummaryRow>
            <SummaryRow fieldName={fields.ARREST_JUVENILE_TRIED_AS_ADULT_NOTES}>
                {arrest.juvenileTriedAsAdultNotes}
            </SummaryRow>
            <FeatureFlagged flag="ARRESTS_SHOW_ADVISED_RIGHTS">
                <SummaryRow fieldName={fields.ARREST_ADVISED_RIGHTS}>
                    {booleanToYesNo(arrest.advisedRights)}
                </SummaryRow>
                <SummaryRow fieldName={fields.ARREST_ADVISED_RIGHTS_OFFICER_ID}>
                    {formatMiniUserById(arrest.advisedRightsOfficerId)}
                </SummaryRow>
                <SummaryRow fieldName={fields.ARREST_ADVISED_RIGHTS_RESPONSE_ATTR_ID}>
                    {formatAttributeWithOther({
                        attributeId: arrest.advisedRightsResponseAttrId,
                        other: arrest.advisedRightsResponseOther,
                        joinWith: ' - ',
                    })}
                </SummaryRow>
            </FeatureFlagged>
            <SummaryRow fieldName={fields.ARREST_WAS_ARRESTEE_FINGERPRINTED}>
                {booleanToYesNo(arrest.wasArresteeFingerprinted)}
            </SummaryRow>
            <SummaryRow fieldName={fields.ARREST_INCIDENT_TRANSACTION_NUMBER}>
                {arrest.incidentTransactionNumber}
            </SummaryRow>
            <SummaryRow fieldName={fields.ARREST_WARRANT_SIGNED_BY_ATTR_ID}>
                {formatAttributeById(arrest.warrantSignedByAttrId)}
            </SummaryRow>
        </SummaryList>
    );
});

export default ArrestCardSummary;

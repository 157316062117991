import { Box, Flex, IconButton } from 'arc';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import testIds from '../../../../core/testIds';
import ScrollableUnderSubheader, {
    BaseScrollableUnderSubheader,
} from '../../../core/components/ScrollableUnderSubheader';
import {
    EvidenceDashboardWrapper,
    EvidenceTableWrapper,
    LegacyEvidenceDashboardWrapper,
} from '../../dashboard/containers/EvidenceDashboard';
import TableFooter from '../../search-results/components/ResultsTableFooter';
import _SearchResults from '../../search-results/containers/SearchResults';
import InventoriesDashboardSearchResultFooter from '../components/InventoriesDashboardSearchResultFooter';
import SearchForm from '../components/SearchForm';
import TableBody from '../components/SearchResultsTableBody';
import TableHeader from '../components/SearchResultsTableHeader';

import { InventoriesDashboardDataTable } from '../components/dashboard/InventoriesDashboardDataTable';
import { InventoriesDashboardFilters } from '../components/dashboard/InventoriesDashboardFilters';

import { applyFilters, clearAllFilters, inventoriesDashboardSearch } from '../state/ui';

const SearchResults = styled(_SearchResults)`
    tr.cobalt-table-row {
        display: table-row !important;
    }

    th.cobalt-table-column,
    td.cobalt-table-cell {
        display: table-cell !important;
    }
`;

const StyledIconButton = styled(IconButton)`
    margin: var(--arc-space-3) var(--arc-space-3) 0;
    align-self: flex-end;
`;

const querySelector = inventoriesDashboardSearch.selectors.currentQuerySelector;

const InventorySearchForm = () => {
    const dispatch = useDispatch();
    const applyInventoryFilters = () => dispatch(applyFilters());
    const clearAppliedInventoryFilters = () => dispatch(clearAllFilters());
    return (
        <SearchForm
            applyFilters={applyInventoryFilters}
            clearAllFilters={clearAppliedInventoryFilters}
        />
    );
};

const LegacyInventoriesDashboard = ({ className, handleRowClick }) => {
    return (
        <ScrollableUnderSubheader contentClassnames="dashboard-under-subheader">
            <div className="dashboard-content-container">
                <div data-test-id={testIds.INVENTORIES_DASHBOARD} className={className}>
                    <SearchResults
                        TableHeader={TableHeader}
                        TableBody={TableBody}
                        TableFooter={TableFooter}
                        handleSortKeyClickAction={inventoriesDashboardSearch.actionCreators.search}
                        handleSortTypeClickAction={inventoriesDashboardSearch.actionCreators.search}
                        handlePaginationClickAction={
                            inventoriesDashboardSearch.actionCreators.search
                        }
                        handleRowClick={handleRowClick}
                        handleSelectRowsAction={
                            inventoriesDashboardSearch.actionCreators.selectRows
                        }
                        handleSizeChangeAction={inventoriesDashboardSearch.actionCreators.search}
                        handleSelectAllRows={
                            inventoriesDashboardSearch.actionCreators.selectAllResults
                        }
                        handleClearAllSelectedRows={
                            inventoriesDashboardSearch.actionCreators.selectRows
                        }
                        queryFromSelector={createSelector(
                            querySelector,
                            (currentQuery) => currentQuery.from
                        )}
                        querySizeSelector={createSelector(
                            querySelector,
                            (currentQuery) => currentQuery.size
                        )}
                        querySortKeySelector={createSelector(
                            querySelector,
                            (currentQuery) => currentQuery.sortKey
                        )}
                        querySortTypeSelector={createSelector(
                            querySelector,
                            (currentQuery) => currentQuery.sortType
                        )}
                        queryResultsSelector={
                            inventoriesDashboardSearch.selectors.currentResultsViewModelsSelector
                        }
                        selectableRows={true}
                        selectedRowsSelector={
                            inventoriesDashboardSearch.selectors.selectedRowsSelector
                        }
                        totalResultsSelector={
                            inventoriesDashboardSearch.selectors.totalCountSelector
                        }
                        SearchForm={InventorySearchForm}
                    />
                    <InventoriesDashboardSearchResultFooter />
                </div>
            </div>
        </ScrollableUnderSubheader>
    );
};

const _InventoriesDashboard = ({ className, handleRowClick }) => {
    const [showFilters, setShowFilters] = useState(false);
    return (
        <FeatureFlagged
            flag="RMS_EVIDENCE_DASHBOARD_REFRESH_ENABLED"
            fallback={
                <LegacyEvidenceDashboardWrapper>
                    <LegacyInventoriesDashboard
                        className={className}
                        handleRowClick={handleRowClick}
                    />
                </LegacyEvidenceDashboardWrapper>
            }
        >
            <BaseScrollableUnderSubheader fullscreen contentClassnames="dashboard-under-subheader">
                <EvidenceDashboardWrapper fullscreen>
                    <FeatureFlagged
                        flag="RMS_EVIDENCE_DASHBOARD_FILTERS_REFRESH_ENABLED"
                        fallback={
                            <Flex flexDirection="column">
                                <StyledIconButton
                                    data-test-id={testIds.FILTER_BUTTON}
                                    onClick={() => setShowFilters((prev) => !prev)}
                                    aria-label="Toggle Filters"
                                    icon="Filter"
                                    variant={showFilters ? 'solid' : undefined}
                                />
                                <Box display={showFilters ? 'block' : 'none'}>
                                    <InventorySearchForm />
                                </Box>
                            </Flex>
                        }
                    >
                        <InventoriesDashboardFilters />
                    </FeatureFlagged>
                    <EvidenceTableWrapper>
                        <InventoriesDashboardDataTable />
                    </EvidenceTableWrapper>
                </EvidenceDashboardWrapper>
            </BaseScrollableUnderSubheader>
        </FeatureFlagged>
    );
};

_InventoriesDashboard.propTypes = {
    className: PropTypes.string,
};

_InventoriesDashboard.defaultsProps = {
    className: '',
};

const InventoriesDashboard = styled(_InventoriesDashboard)`
    padding: 20px;
`;

export default compose(
    withRouter,
    withHandlers({
        handleRowClick: ({ router }) => ({ id: inventoryId }) =>
            router.push(`/evidence/inventories/${inventoryId}`),
    })
)(InventoriesDashboard);

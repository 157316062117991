import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import {
    TOW_VEHICLE_CHECK_IN_LOT_LOCATION,
    TOW_VEHICLE_CHECK_IN_DAMAGE_ON_VEHICLE,
    TOW_VEHICLE_CHECK_IN_LICENSE_IN_VEHICLE,
    TOW_VEHICLE_CHECK_IN_INSURANCE_IN_VEHICLE,
    TOW_VEHICLE_CHECK_IN_REGISTRATION_IN_VEHICLE,
    TOW_VEHICLE_CHECK_IN_VEHICLE_HELD_AS_EVIDENCE,
} from '~/client-common/core/enums/universal/fields';
import { towVehicleCheckInViewModelByReportIdSelector } from '~/client-common/core/domain/tow-vehicle-check-ins/state/ui';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import testIds from '../../../../../core/testIds';

const mapStateToProps = createStructuredSelector({
    towVehicleCheckInViewModelByReportId: towVehicleCheckInViewModelByReportIdSelector,
});

const TowVehicleCheckInCardSummary = connect(mapStateToProps)(
    function _TowVehicleCheckInCardSummary({ reportId, towVehicleCheckInViewModelByReportId }) {
        const towVehicleCheckInViewModel = towVehicleCheckInViewModelByReportId(reportId) || {};
        const {
            licenseInVehicle,
            insuranceInVehicle,
            registrationInVehicle,
            vehicleHeldAsEvidence,
        } = getViewModelProperties(towVehicleCheckInViewModel);

        return (
            <SummaryList labelWidth={160} contentWidth={380}>
                <CardSection testId={testIds.CARD_SUMMARY}>
                    <SummaryRow fieldName={TOW_VEHICLE_CHECK_IN_LOT_LOCATION}>
                        {get(towVehicleCheckInViewModel, 'lotLocation')}
                    </SummaryRow>
                    <SummaryRow fieldName={TOW_VEHICLE_CHECK_IN_DAMAGE_ON_VEHICLE}>
                        {get(towVehicleCheckInViewModel, 'damageOnVehicle')}
                    </SummaryRow>
                    <SummaryRow fieldName={TOW_VEHICLE_CHECK_IN_LICENSE_IN_VEHICLE}>
                        {licenseInVehicle}
                    </SummaryRow>
                    <SummaryRow fieldName={TOW_VEHICLE_CHECK_IN_INSURANCE_IN_VEHICLE}>
                        {insuranceInVehicle}
                    </SummaryRow>
                    <SummaryRow fieldName={TOW_VEHICLE_CHECK_IN_REGISTRATION_IN_VEHICLE}>
                        {registrationInVehicle}
                    </SummaryRow>
                    <SummaryRow fieldName={TOW_VEHICLE_CHECK_IN_VEHICLE_HELD_AS_EVIDENCE}>
                        {vehicleHeldAsEvidence}
                    </SummaryRow>
                </CardSection>
            </SummaryList>
        );
    }
);

export default TowVehicleCheckInCardSummary;

import { StopAnonymousSubject } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'stopAnonymousSubjects';

const stopAnonymousSubjectsModule = createLinkModule<StopAnonymousSubject>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// SELECTORS

export const stopAnonymousSubjectsWhereSelector =
    stopAnonymousSubjectsModule.selectors.linksWhereSelector;

// REDUCER
export default stopAnonymousSubjectsModule.reducerConfig;

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const locationCautionsSubPremiseFormFieldViewModels = {
    subPremise: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'subPremise',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'name',
                validators: {
                    requiredError: requiredString,
                },
            },
        ]),
    },
};

function convertToFormModel(name) {
    return {
        subPremise: {
            name,
        },
    };
}

function convertFromFormModel(model) {
    return model.subPremise.name;
}

/**
 * Module of the location cautions subpremise form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.LOCATION_CAUTIONS_SUBPREMISE_ADMIN,
    fieldViewModels: locationCautionsSubPremiseFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import React from 'react';
import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_REPORT_ADD_DE_ESCALATION_TYPE_LABEL } from '~/client-common/core/enums/universal/fields';

import Row from '../../../../core/components/Row';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTNItems } from '../../../../core/forms/components/NItems';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import IndentedFields from '../../../../core/components/IndentedFields';

type UseOfForceSubjectDeEscalationNItemsProps = {
    path: string;
    formIndex: number;
};

const UseOfForceSubjectDeEscalationNItemsForm: React.FC<UseOfForceSubjectDeEscalationNItemsProps> = (
    props
) => {
    const { path, formIndex } = props;

    const addDeEscalationTypeLabel = useFields(DISPLAY_ONLY_REPORT_ADD_DE_ESCALATION_TYPE_LABEL)[
        DISPLAY_ONLY_REPORT_ADD_DE_ESCALATION_TYPE_LABEL
    ];

    return (
        <ArbiterMFTNItems
            formName={RefContextEnum.FORM_USE_OF_FORCE_SUBJECT.name}
            addItemOnEmpty={true}
            addText={addDeEscalationTypeLabel}
            formIndex={formIndex}
            path={path}
            childFieldKeys={['deEscalationTypeAttrId', 'deEscalationSuccessful']}
            render={() => (
                <>
                    <Row>
                        <ArbiterMFTAttributeSelect
                            path="deEscalationTypeAttrId"
                            length="md"
                            attributeType={
                                AttributeTypeEnum.USE_OF_FORCE_SUBJECT_DE_ESCALATION_TYPE.name
                            }
                        />
                    </Row>
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTBooleanSelect path="deEscalationSuccessful" length="md" />
                        </Row>
                    </IndentedFields>
                </>
            )}
        />
    );
};

export default UseOfForceSubjectDeEscalationNItemsForm;

import React from 'react';

const FocusInside: React.FC = ({ children }) => {
    return <span data-focus-inside={true}>{children}</span>;
};

/**
 * Wrap this around the child that should be focused when the component is mounted. This is only needed when it's not
 *   the first child.
 */
export default FocusInside;

import { RuleConfigurationContext } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'ruleConfigurationContexts';

const ruleConfigurationContextsModule = createLinkModule<RuleConfigurationContext>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// SELECTORS
export const ruleConfigurationContextsSelector =
    ruleConfigurationContextsModule.selectors.linksSelector;
export const ruleConfigurationContextsWhereSelector =
    ruleConfigurationContextsModule.selectors.linksWhereSelector;

// REDUCER
export default ruleConfigurationContextsModule.reducerConfig;

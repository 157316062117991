import { createSelector } from 'reselect';
import { filter, keyBy, find, uniqBy, flatMap } from 'lodash';

import { reportDefinitionsSelector } from '~/client-common/core/domain/report-definitions/state/data';
import {
    reportDefinitionHasArrestCard,
    reportDefinitionHasOffenseCard,
    reportDefinitionHasBehavioralCrisisCard,
    reportDefinitionHasCustodialPropertySummaryCard,
    reportDefinitionHasSupplementOffenseCard,
    reportDefinitionHasSupplementIncidentCard,
    reportDefinitionHasSupplementInfoCard,
    mapReportDefinitionsToOptions,
    mapReportDefinitionsToVerboseOptions,
    isCustomReportDefinition,
} from '~/client-common/helpers/reportDefinitionsHelpers';
import { currentUserDepartmentIdSelector } from '../../../current-user/state/ui';

export const reportDefinitionsForCurrentDepartmentSelector = createSelector(
    reportDefinitionsSelector,
    currentUserDepartmentIdSelector,
    (reportDefinitions, currentUserDepartmentId) =>
        keyBy(filter(reportDefinitions, { departmentId: currentUserDepartmentId }), 'id')
);

export const allReportDefinitionOptionsForCurrentDepartmentSelector = createSelector(
    reportDefinitionsForCurrentDepartmentSelector,
    (reportDefinitions) => mapReportDefinitionsToVerboseOptions(reportDefinitions)
);

export const reportDefinitionOptionsForCurrentDepartmentSelector = createSelector(
    reportDefinitionsForCurrentDepartmentSelector,
    (reportDefinitions) => {
        const enabledReportDefinitions = filter(reportDefinitions, { isEnabled: true });
        return mapReportDefinitionsToOptions(enabledReportDefinitions);
    }
);

export const reportCardsForCurrentDepartmentSelector = createSelector(
    reportDefinitionsForCurrentDepartmentSelector,
    (reportDefinitions) => uniqBy(flatMap(reportDefinitions, 'cards'), 'id')
);

export const arrestReportDefinitionForCurrentDepartmentSelector = createSelector(
    reportDefinitionsForCurrentDepartmentSelector,
    (reportDefinitions) => find(reportDefinitions, reportDefinitionHasArrestCard)
);

/**
 * Selector will specifically only find the Offense report definition.
 * Selector expects that there is only 1 report definition with an `offense` card.
 */
export const offenseReportDefinitionForCurrentDepartmentSelector = createSelector(
    reportDefinitionsForCurrentDepartmentSelector,
    (reportDefinitions) => find(reportDefinitions, reportDefinitionHasOffenseCard)
);

/**
 * The following report definitions contain the supplement card, and we have to identify the first one.
 * 1. Supplement report
 * 2. Behavioral Crisis report
 * 3. Offense Modifying Supplement report
 * 4. Any custom created report configured with the supplement card
 */
export const supplementReportDefinitionForCurrentDepartmentSelector = createSelector(
    reportDefinitionsForCurrentDepartmentSelector,
    (reportDefinitions) =>
        find(reportDefinitions, (reportDefinition) => {
            const hasSupplementInfoCard = reportDefinitionHasSupplementInfoCard(reportDefinition);
            const hasBehavioralCrisisCard = reportDefinitionHasBehavioralCrisisCard(
                reportDefinition
            );
            const hasSupplementOffenseOrSupplementIncidentCard =
                reportDefinitionHasSupplementOffenseCard(reportDefinition) ||
                reportDefinitionHasSupplementIncidentCard(reportDefinition);
            return (
                hasSupplementInfoCard &&
                !hasBehavioralCrisisCard &&
                !hasSupplementOffenseOrSupplementIncidentCard &&
                !isCustomReportDefinition(reportDefinition)
            );
        })
);

/**
 * Selector will specifically only find the Custodial Property Summary report
 *   definition. Selector expects that there is only 1 report definition with a
 *   custodial property summary card.
 */
export const custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector = createSelector(
    reportDefinitionsForCurrentDepartmentSelector,
    (reportDefinitions) => find(reportDefinitions, reportDefinitionHasCustodialPropertySummaryCard)
);

/**
 * Selector will specifically only find the Offense Modifying Supplement report
 *   definition. Selector expects that there is only 1 report definition with a
 *   supplement offense or supplement incident card.
 */
export const offenseModifyingSupplementReportDefinitionForCurrentDepartmentSelector = createSelector(
    reportDefinitionsForCurrentDepartmentSelector,
    (reportDefinitions) =>
        find(reportDefinitions, reportDefinitionHasSupplementOffenseCard) ||
        find(reportDefinitions, reportDefinitionHasSupplementIncidentCard)
);

import React, { ReactNode } from 'react';
import { Optional } from 'utility-types';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { SimpleLoading } from '../../../legacy-redux/components/core/Loading';
import { InlineBanner } from './InlineBanner';
import DropdownMenu, { DropdownMenuProps } from './DropdownMenu';

interface AsyncDropdownMenuProps<T> extends Optional<DropdownMenuProps, 'width' | 'children'> {
    descriptionText?: ReactNode;
    fetchOptions: () => Promise<T>;
    // Callback function that is passed the results of our fetch call
    mapResultOptions: (options: T, closeMenu: () => void) => ReactNode;
    noOptionDropdownClose?: boolean;
    innerRef?: React.Ref<HTMLElement>;
}

interface AsyncDropdownMenuState<T> {
    error: boolean;
    isLoading: boolean;
    options: T | null;
}
const initialState = {
    error: false,
    isLoading: false,
    options: null,
};

export class AsyncDropdownMenu<T> extends React.Component<
    AsyncDropdownMenuProps<T>,
    AsyncDropdownMenuState<T>
> {
    constructor(props: Readonly<AsyncDropdownMenuProps<T>>) {
        super(props);
        this.state = initialState;
        this.handleDropdownMenuOpen = this.handleDropdownMenuOpen.bind(this);
        this.handleDropdownMenuClose = this.handleDropdownMenuClose.bind(this);
    }

    handleDropdownMenuOpen() {
        this.setState({ ...initialState, isLoading: true });
        this.props
            .fetchOptions()
            .then((options) =>
                this.setState({
                    error: false,
                    isLoading: false,
                    options,
                })
            )
            .catch(() =>
                this.setState({
                    error: true,
                    isLoading: false,
                    options: null,
                })
            );
    }

    handleDropdownMenuClose() {
        this.setState({ ...initialState });
    }

    render() {
        const { innerRef, descriptionText, width = 250, mapResultOptions, ...props } = this.props;
        const { error, isLoading, options } = this.state;

        return (
            <DropdownMenu
                {...props}
                width={width}
                onOpen={this.handleDropdownMenuOpen}
                onClose={this.handleDropdownMenuClose}
                ref={innerRef}
            >
                {(closeMenu) => (
                    <>
                        {isLoading && <SimpleLoading />}
                        {!isLoading && options && (
                            <div>
                                {descriptionText}
                                {mapResultOptions(options, closeMenu)}
                            </div>
                        )}
                        {!isLoading && error && (
                            <InlineBanner status="error">
                                {componentStrings.core.AsyncDropdownMenu.error}
                            </InlineBanner>
                        )}
                    </>
                )}
            </DropdownMenu>
        );
    }
}

function renderFunction<T>(
    props: Omit<AsyncDropdownMenuProps<T>, 'innerRef'>,
    ref: AsyncDropdownMenuProps<T>['innerRef']
) {
    return <AsyncDropdownMenu {...props} innerRef={ref} />;
}

export default React.forwardRef(renderFunction);

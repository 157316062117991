import React, { FC } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';

import { InlineBanner } from 'arc';
import { AttributeTypeEnum, EFileTitle, EntityTypeEnum, ProfileView } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatName } from '~/client-common/core/domain/mini-users/utils/miniUsersHelpers';

import Link from '../../../core/components/links/Link';
import { SkeletonTable } from '../../../core/components/Skeleton';
import RMSTableComposite, {
    TableBodyColumn,
    TableBodyRow,
} from '../../../core/components/RMSTableComposite';
import { useDateTimeFormatter } from '../../../core/current-user/hooks/dateTimeFormats';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import TableHeaderButton from '../../../../legacy-redux/components/core/tables/TableHeaderButton';
import { useEFileDisplayName, useEFilesRelatedToEntity } from '../../../e-files/hooks';

interface EFileTableRow {
    createdDateUtc: string;
    eFileNumber: string;
    eFileId: string;
    ownerName: string;
    defendants: ProfileView[];
}

const DEFAULT_SORT = {
    sortKey: 'createdDateUtc',
    sortType: 'ASCENDING',
};

const strings = componentStrings.cases.caseEFiles.CaseEFiles;

const PaginationSummaryComponentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
`;

const StyledTable = styled(RMSTableComposite)`
    ${TableHeaderButton} {
        line-height: 22px;
    }
    ${TableBodyColumn} {
        padding: 15px 10px;
    }
    ${TableBodyRow} {
        border-color: ${(props) => props.theme.colors.lightGrey};
    }
`;

const useCaseEFilesTableColumns = () => {
    const dateTimeFormatter = useDateTimeFormatter();
    const { eFileDisplayName } = useEFileDisplayName();

    return [
        {
            key: 'createdDateUtc',
            width: '175px',
            title: strings.grid.columns.createdDate,
            filtrable: false,
            renderCellContent: ({ item: { createdDateUtc } }: { item: EFileTableRow }) => {
                return dateTimeFormatter.formatDate(createdDateUtc);
            },
        },
        {
            key: 'eFileNumber',
            width: '225px',
            title: strings.grid.columns.eFileNumber(eFileDisplayName),
            filtrable: false,
            renderCellContent: ({ item: { eFileId, eFileNumber } }: { item: EFileTableRow }) => {
                return <Link to={`/e-file/${eFileId}/defendants`}>{eFileNumber}</Link>;
            },
        },
        {
            key: 'ownerName',
            width: '150px',
            title: strings.grid.columns.ownerName,
            filtrable: false,
        },
        {
            key: 'defendants',
            width: '175px',
            title: strings.grid.columns.defendants,
            renderCellContent: ({ item: { defendants } }: { item: EFileTableRow }) => {
                const firstThreeDefs = defendants.slice(0, 3);
                const otherDefs = defendants.slice(3);

                const visibleDefs = firstThreeDefs.map(({ firstName, lastName }, index) => (
                    <div key={index}>{formatName({ first: firstName, last: lastName })}</div>
                ));

                return otherDefs.length === 0
                    ? visibleDefs
                    : [...visibleDefs, <div key={'other'}>+{otherDefs.length}</div>];
            },
            filtrable: false,
        },
        {
            key: 'status',
            width: '150px',
            title: strings.grid.columns.status,
            filtrable: false,
        },
    ];
};

export const CaseEFilesTable: FC<{ caseId: number }> = ({ caseId }) => {
    const { eFileTitles, loading } = useEFilesRelatedToEntity({
        entityId: caseId,
        entityType: EntityTypeEnum.CASE.name,
    });

    const { eFileDisplayName } = useEFileDisplayName();
    const columns = useCaseEFilesTableColumns();

    if (loading.errorMessage) {
        return <InlineBanner status="error">{loading.errorMessage}</InlineBanner>;
    } else if (loading.isLoading) {
        return <SkeletonTable />;
    }

    const items = eFileTitles.map(convertEFileTitleToTableItem);

    return (
        <StyledTable
            columns={columns}
            items={items}
            noDataText={strings.noDataText(eFileDisplayName)}
            includeFilter={false}
            defaultSortConfig={DEFAULT_SORT}
            renderLayout={({ tableComponent }: { tableComponent: JSX.Element }) => {
                return (
                    <>
                        <PaginationSummaryComponentWrapper>
                            <TableResultsSummary
                                from={0}
                                to={eFileTitles.length}
                                totalResults={eFileTitles.length}
                                className={null}
                            />
                        </PaginationSummaryComponentWrapper>
                        {tableComponent}
                    </>
                );
            }}
        />
    );
};

export function convertEFileTitleToTableItem({
    attributes,
    createdDateUtc,
    localId,
    efileId,
    title,
    profiles: { owner, defendants },
}: EFileTitle) {
    const eFileStatusAttribute = find(attributes, { type: AttributeTypeEnum.E_FILE_STATUS.name });

    return {
        createdDateUtc,
        eFileNumber: `${localId} - ${title}`,
        eFileId: efileId,
        ownerName: owner
            ? formatName({ first: owner.firstName, last: owner.lastName }, true)
            : null,
        status: eFileStatusAttribute ? eFileStatusAttribute.displayValue : null,
        defendants,
    };
}

import { GpsRetentionPolicyDto } from '@mark43/gps-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'gps-retention';

const gpsRetentionModule = createNormalizedModule<GpsRetentionPolicyDto>({
    type: NEXUS_STATE_PROP,
    key: 'departmentId',
});

export default gpsRetentionModule.reducerConfig;

import { createSelector } from 'reselect';
import { CommandRecord, Integration } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { integrationByIdSelector } from '../../../integrations/state/data';
import { integrationJobRunsWhereSelector } from '../../../integration-job-runs/state/data';

const commandRecordModule = createNormalizedModule<CommandRecord>({
    type: 'commandRecords',
});

// SELECTORS

export const commandRecordByIdSelector = commandRecordModule.selectors.entityByIdSelector;

export const mostRecentCommandRecordByIntegrationIdSelector = createSelector(
    integrationByIdSelector,
    integrationJobRunsWhereSelector,
    commandRecordByIdSelector,
    (integrationById, integrationJobRunsWhere, commandRecordById) => (integrationId: number) => {
        const integration = integrationById(integrationId) as Integration;
        const integrationJobRun = integrationJobRunsWhere({ integrationId: integration.id });
        if (!integrationJobRun || integrationJobRun.length === 0) {
            return {};
        }
        const commandRecord = commandRecordById(integrationJobRun[0].commandRecordId) || {};
        return commandRecord;
    }
);

// REDUCER
export default commandRecordModule.reducerConfig;

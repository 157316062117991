import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { FormattedDate } from '~/client-common/core/dates/components';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import Label from '../../../core/components/typography/Label';
import testIds from '../../../../core/testIds';
import { useOpenShareModal } from '../../core/hooks/useShareSearch';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import SavedSearchMoreOptionsMenu from './SavedSearchMoreOptionsMenu';
import SavedSearchRenameForm from './SavedSearchRenameForm';
import SavedSearchErrorMessage from './SavedSearchErrorMessage';

const strings = componentStrings.search.savedSearch.SavedSearch;
const notifications = strings.notifications;

const StyledLabel = styled(Label)`
    border-color: var(--arc-colors-raw-purple-500);
    background-color: var(--arc-colors-raw-purple-500);
`;

const SaveSearchContent = styled.div`
    flex: 1 0;
    min-height: 55px;
`;

const SavedSearchSeparator = styled.div`
    border: 1px solid ${(props) => props.theme.colors.extraLightGrey};
`;

const SavedSearchSummaryName = styled.div`
    text-transform: uppercase;
    display: inline-block;
    color: ${(props) => props.theme.colors.cobaltBlue};
    margin-right: 5px;
`;

const SavedSearchSummaryLastExecuted = styled.div`
    font-style: italic;
    display: inline-block;
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const SavedSearchQuerySummary = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-sm);
`;

const Wrapper = styled.div`
    padding: 12px 5px 5px;
    display: flex;
    ${(props) => !(props.currentlyRenaming || props.stale) && `cursor: pointer;`};

    &:hover {
        background: ${(props) =>
            !(props.currentlyRenaming || props.stale) && props.theme.colors.extraLightGrey};
    }
`;

const StaleQueryNotificationWrapper = styled.div`
    color: ${(props) => props.theme.colors.mediumGrey};
    font-style: italic;
    font-size: var(--arc-fontSizes-md);
`;

const SavedSearch = ({
    executeSavedSearch,
    savedSearch,
    onOpenSavedSearchRenameForm,
    includeSeparator,
    onSavedSearchExecuteSuccess,
    onSavedSearchRename,
    onSavedSearchSubscribe,
    onSavedSearchDelete,
    onFavoriteSavedSearch,
    savedSearches,
    hideSubscribeOption,
    setRenameSavedSearchSection,
    savedSearchSection,
}) => {
    const viewModelProperties = getViewModelProperties(savedSearch);

    const separator = includeSeparator ? <SavedSearchSeparator /> : undefined;

    const { isQueryStale, isSubscription, isShared } = savedSearch;

    const overlayStore = useOverlayStore();

    const openShareModal = useOpenShareModal(savedSearch);

    const onSavedSearchExecute = useCallback(() => {
        return executeSavedSearch(savedSearch.id).then((result) => {
            if (onSavedSearchExecuteSuccess) {
                onSavedSearchExecuteSuccess(result);
            }
            overlayStore.close(overlayIdEnum.SAVED_SEARCH_MODAL);
        });
    }, [executeSavedSearch, onSavedSearchExecuteSuccess, overlayStore, savedSearch]);

    const lastExecutedSummary = (
        <SavedSearchSummaryLastExecuted>
            {`${strings.labels.lastExecutedDateUtc} `}
            <FormattedDate
                format={FormattedDate.FORMATS.FORM_DATE_TIME_SEC}
                date={savedSearch.lastExecutedDateUtc}
            />
        </SavedSearchSummaryLastExecuted>
    );

    const copySavedSearch = useCallback(() => {
        overlayStore.open(overlayIdEnum.COPY_SAVED_SEARCH_CONFIRMATION_MODAL, { savedSearch });
    }, [overlayStore, savedSearch]);

    const deleteSharedSavedSearch = useCallback(() => {
        overlayStore.open(overlayIdEnum.DELETE_SAVED_SEARCH_CONFIRMATION_MODAL, { savedSearch });
    }, [overlayStore, savedSearch]);

    // Need to check if both the saved search id is being renamed and where it is being renamed,
    // since a saved search can appear in multiple sections
    const currentlyRenaming =
        viewModelProperties.renaming && viewModelProperties.renamingSection === savedSearchSection;

    return (
        <>
            <Wrapper
                stale={isQueryStale}
                currentlyRenaming={currentlyRenaming}
                data-test-id={testIds.SAVED_SEARCH_ROW}
            >
                <SaveSearchContent
                    onClick={isQueryStale || currentlyRenaming ? undefined : onSavedSearchExecute}
                >
                    {/* If user is not currently renaming this saved search and this saved search has an existing error... */}
                    {!currentlyRenaming && viewModelProperties.errorMessage && (
                        <div>
                            <SavedSearchErrorMessage>
                                {viewModelProperties.errorMessage}
                            </SavedSearchErrorMessage>
                        </div>
                    )}

                    {currentlyRenaming ? (
                        <SavedSearchRenameForm
                            initialValues={{ name: savedSearch.name }}
                            onSavedSearchRename={onSavedSearchRename}
                            savedSearchId={savedSearch.id}
                            savedSearches={savedSearches}
                        />
                    ) : (
                        <>
                            <SavedSearchSummaryName
                                data-test-id={testIds.SAVED_SEARCH_SUMMARY_NAME}
                            >
                                {savedSearch.name}
                            </SavedSearchSummaryName>
                            <FeatureFlagged
                                flag="RMS_SAVED_SEARCH_SHARING_ENABLED"
                                fallback={lastExecutedSummary}
                            >
                                {!isShared && lastExecutedSummary}
                            </FeatureFlagged>
                            <FeatureFlagged flag="RMS_SUBSCRIPTIONS_ENABLED">
                                {isSubscription && <Label>{strings.labels.subscribed}</Label>}
                            </FeatureFlagged>
                            <FeatureFlagged flag="RMS_SAVED_SEARCH_SHARING_ENABLED">
                                {isShared && <StyledLabel>{strings.labels.shared}</StyledLabel>}
                            </FeatureFlagged>
                            <SavedSearchQuerySummary>
                                {viewModelProperties.elasticQueryDisplay}
                            </SavedSearchQuerySummary>
                            {isQueryStale && (
                                <StaleQueryNotificationWrapper>
                                    {notifications.staleQuery}
                                </StaleQueryNotificationWrapper>
                            )}
                        </>
                    )}
                </SaveSearchContent>

                <SavedSearchMoreOptionsMenu
                    onEdit={() => {
                        setRenameSavedSearchSection(savedSearchSection);
                        onOpenSavedSearchRenameForm(savedSearch.id);
                    }}
                    onDelete={savedSearch.isShared ? deleteSharedSavedSearch : onSavedSearchDelete}
                    onShare={openShareModal}
                    onSubscribe={hideSubscribeOption ? undefined : onSavedSearchSubscribe}
                    onFavorite={onFavoriteSavedSearch}
                    savedSearch={savedSearch}
                    onCopy={copySavedSearch}
                />
            </Wrapper>
            {separator}
        </>
    );
};

const mapDispatchToProps = (dispatch, { searchModule }) => {
    return {
        executeSavedSearch: (savedSearch) =>
            dispatch(searchModule.actionCreators.executeSavedSearch(savedSearch)),
        openSavedSearchRenameForm: (savedSearchId) =>
            dispatch(searchModule.actionCreators.openSavedSearchRenameForm(savedSearchId)),
        renameSavedSearch: (savedSearchId, newSavedSearchName) =>
            dispatch(
                searchModule.actionCreators.renameSavedSearch(savedSearchId, newSavedSearchName)
            ),
        deleteSavedSearch: (savedSearchId) =>
            dispatch(searchModule.actionCreators.deleteSavedSearch(savedSearchId)),
        subscribeSavedSearch: (savedSearch, subscribe) =>
            dispatch(searchModule.actionCreators.subscribeSavedSearch(savedSearch, subscribe)),
        setFavoriteSavedSearch: (savedSearchId, favorite) =>
            dispatch(searchModule.actionCreators.setFavoriteSavedSearch(savedSearchId, favorite)),
        setRenameSavedSearchSection: (savedSearchSection) =>
            dispatch(searchModule.actionCreators.setRenameSavedSearchSection(savedSearchSection)),
    };
};

export default compose(
    connect(null, mapDispatchToProps),
    withHandlers({
        onOpenSavedSearchRenameForm: ({ savedSearch, openSavedSearchRenameForm }) => () => {
            return openSavedSearchRenameForm(savedSearch.id);
        },
        onSavedSearchRename: ({ savedSearch, renameSavedSearch }) => (newSavedSearchName) => {
            const { id } = savedSearch;
            return renameSavedSearch(id, newSavedSearchName);
        },
        onSavedSearchDelete: ({ savedSearch, deleteSavedSearch }) => () => {
            const { id } = savedSearch;
            return deleteSavedSearch(id);
        },
        onSavedSearchSubscribe: ({ savedSearch, subscribeSavedSearch }) => () => {
            const { isSubscription } = savedSearch;
            return subscribeSavedSearch(savedSearch, !isSubscription);
        },
        onFavoriteSavedSearch: ({ savedSearch, setFavoriteSavedSearch }) => () => {
            const { id, isFavorite } = savedSearch;
            return setFavoriteSavedSearch(id, !isFavorite);
        },
    })
)(SavedSearch);

import React, { useCallback } from 'react';
import { RefContextEnum } from '@mark43/rms-api';
import { InjectedRouter, withRouter } from 'react-router';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import Modal, { OverrideModalPropsFunction } from '../../../core/overlays/components/Modal';
import { useEFileDisplayName } from '../../hooks';
import { EFileDetailsModalPropsT } from '../../types';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { convertFromFormModelToEFileCreation } from '../state/forms/eFileDetailsForm';
import { eFileResource } from '../../resources/eFileResource';
import EFileDetailsForm from './EFileDetailsForm';

const overlayId = overlayIdEnum.E_FILE_CREATION_MODAL;
const strings = componentStrings.eFiles.core.eFileDetailsModal;

type EFileDetailsModalT = {
    router: InjectedRouter;
};

export const EFileCreationModal = withRouter(({ router }: EFileDetailsModalT) => {
    const { eFileDisplayName } = useEFileDisplayName();
    const { getForm } = useFormGetter();

    const onSave = useCallback(
        async (customProperties: EFileDetailsModalPropsT) => {
            const form = getForm(RefContextEnum.FORM_E_FILE_DETAILS_MODAL.name);
            if (!form) {
                return new Error('Failed to find form');
            }

            const result = await form.submit();
            if (result && result.validationResult) {
                if (result.validationResult.success) {
                    const eFileCreationRequest = convertFromFormModelToEFileCreation(
                        result.form.getState().model,
                        customProperties
                    );
                    if (eFileCreationRequest) {
                        try {
                            const eFile = await eFileResource.createEFile(eFileCreationRequest);

                            router.push(`/e-file/${eFile.efile.id}/defendants`);
                            return [];
                        } catch (err) {
                            throw err;
                        }
                    } else {
                        return new Error('Failed to convert form');
                    }
                }
                return result.validationResult.formErrors;
            }
            return [];
        },
        [getForm, router]
    );

    const overrideModalProps: OverrideModalPropsFunction<EFileDetailsModalPropsT> = (
        overlayProps
    ) => {
        const {
            overlayState: {
                customProperties: { entityId, entityType },
            },
        } = overlayProps;

        return {
            children: <EFileDetailsForm />,
            entityId,
            entityType,
        };
    };

    return (
        <Modal
            id={overlayId}
            title={strings.createEFile(eFileDisplayName)}
            okText={strings.saveText}
            overrideModalProps={overrideModalProps}
            autoClose={false}
            onSave={onSave}
        />
    );
});

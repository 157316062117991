import { RuleConditionArg } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'ruleConditionArgs';

const ruleConditionArgsModule = createNormalizedModule<RuleConditionArg>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const ruleConditionArgsSelector = ruleConditionArgsModule.selectors.entitiesSelector;
export const ruleConditionArgsWhereSelector =
    ruleConditionArgsModule.selectors.entitiesWhereSelector;

// REDUCER
export default ruleConditionArgsModule.reducerConfig;

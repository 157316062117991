import styled from 'styled-components';
import { FormattedDate } from '~/client-common/core/dates/components';

export default styled(FormattedDate)`
    font: ${(props) =>
        `${props.theme.fontWeights.regular} var(--arc-fontSizes-md) ${props.theme.fontFamilies.proximaNova}`};
    color: ${(props) => props.theme.colors.mediumLightGrey};
    position: absolute;
    right: 10px;
    top: 10px;
    width: ${(props) => props.width || '80px'};
    text-align: right;
`;

/**
 * Override all react-modal default overlay styles
 */
export const defaultOverlayStyles = {
    position: undefined,
    top: undefined,
    left: undefined,
    right: undefined,
    bottom: undefined,
    backgroundColor: undefined,
} as const;

/**
 * Override all react-modal default content styles
 */
export const defaultContentStyles = {
    position: undefined,
    top: undefined,
    left: undefined,
    right: undefined,
    bottom: undefined,
    border: undefined,
    background: undefined,
    overflow: undefined,
    WebkitOverflowScrolling: undefined,
    borderRadius: undefined,
    outline: undefined,
    padding: undefined,
} as const;

import { get, some, compact, map, isEmpty, every } from 'lodash';

import {
    PersonProfile,
    RefContextEnum,
    LinkTypesEnum,
    NameNameLink,
    NameReportLink,
    OrganizationProfile,
    LinkTypesEnumType,
} from '@mark43/rms-api';
import { organizationProfileByIdSelector } from '~/client-common/core/domain/organization-profiles/state/data';

import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { offensesByReportIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { useFormGetter } from '../forms/hooks/useFormGetter';
import { relationshipsDataSelector } from '../../reports/core/state/ui/relationships';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { currentReportIdSelector } from '../../../legacy-redux/selectors/reportSelectors';

import { offenseFormConfiguration } from '../../reports/core/state/forms/offenseForm';
import formsRegistry from '../../../core/formsRegistry';
import { victimHasValidDomesticViolenceRelationship } from './helpers/victimHasValidDomesticViolenceRelationship';
import { DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP } from './helpers/domesticViolenceRelationshipsByRegionalGroup';
import { FieldDescriptorType } from './helpers/types';

type RelationshipDataType = {
    nameNameLinks: NameNameLink[];
    nameReportLinks: NameReportLink[];
    personProfiles: PersonProfile[];
    organizationProfiles: OrganizationProfile[];
};

type GetRelevantRelationshipExistsWhereProps = {
    // this is how the boolean comes from sql
    reverse?: '0' | '1';
};

const getIdsForLinkTypeFromRelationshipData = (
    relationshipData: RelationshipDataType,
    targetLinkType: LinkTypesEnumType
) =>
    compact(
        map(get(relationshipData, 'nameReportLinks'), (nrl) => {
            if (nrl.linkType === targetLinkType) {
                return { nameId: nrl.nameId, offenseId: nrl.contextId };
            }
            return;
        })
    );

// Update this RFG to support `relationshipLinkTypes` before RMS_RELATIONSHIP_PREFILL_ENABLED gets teared down.
// https://mark43.atlassian.net/browse/CHI-2249
/**
 *
 * This RFG will do the following:
 *  - grab all offenses in the offense report that have `isDomesticViolence === true`
 *  - grab all the `suspects` or `offenders` from each of the offenses
 *  - grab all relationships that are valid DV relationships for the regional group/compliance group
 *  - for every offense, ensure that at least one victim has a valid DV relationship
 */
export const getRelevantRelationshipExistsInEachDomesticViolenceOffense = (
    getState: () => RootState
) => (
    _fieldDescriptor: FieldDescriptorType,
    { reverse }: GetRelevantRelationshipExistsWhereProps
) => {
    // comes from DB as "0" or "1", convert to boolean
    const castReverse = typeof reverse === 'string' ? !!parseInt(reverse) : !!reverse;
    const state = getState();
    if (!checkIfDepartmentIsNibrs(currentUserDepartmentProfileSelector(state))) {
        return true;
    }
    const currentUserDepartmentProfile = currentUserDepartmentProfileSelector(state);
    if (!currentUserDepartmentProfile) {
        return true;
    }
    // Collect offenses for current report
    const currentReportId = currentReportIdSelector(state);
    if (!currentReportId) {
        return true;
    }
    const offenses = offensesByReportIdSelector(state)(currentReportId);
    if (isEmpty(offenses)) {
        return true;
    }
    const organizationProfileById = organizationProfileByIdSelector(state);
    const relationshipData: RelationshipDataType = relationshipsDataSelector(state);
    const victims = getIdsForLinkTypeFromRelationshipData(
        relationshipData,
        LinkTypesEnum.VICTIM_IN_OFFENSE
    );

    // Collect current state of FORM_RELATIONSHIPS
    const { getForm } = useFormGetter();
    // any regional group for which you want to use this RFG must be
    // added to DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP
    const nibrsRegionalGroup = currentUserDepartmentProfile.nibrsRegionalGroup as keyof typeof DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP;

    // similarly, if we are using compliance group for validation, then the
    // relationships must be added to DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_COMPLIANCE_GROUP
    const complianceGroup = currentUserDepartmentProfile.complianceGroup;

    if (!nibrsRegionalGroup && !complianceGroup) {
        return !castReverse;
    }

    // By default, we want to check all offenses that have `isDomesticViolence == true`,
    // with `reverse` argument set to true, we want to check offenses where `isDomesticViolence == false`.
    const domesticViolenceValueToCheck = !castReverse;
    const domesticViolenceOffenseIds: number[] = compact(
        map(offenses, (offense) => {
            const offenseForm = getForm<
                ReturnType<typeof offenseFormConfiguration>,
                typeof RefContextEnum.FORM_OFFENSE.name
            >(RefContextEnum.FORM_OFFENSE.name, offense.id);
            if (!offenseForm) {
                return;
            }
            const isDomesticViolence = offenseForm.getState().model.offense?.isDomesticViolence;
            // Default: skip offenses that don't have `isDomesticViolence` flag, or it was set to `No`
            // Reverse: check offenses where `isDomesticViolence` flag is `No` or don't have values
            if (!castReverse) {
                return !!isDomesticViolence ? offense.id : null;
            } else {
                return isUndefinedOrNull(isDomesticViolence) ||
                    isDomesticViolence !== domesticViolenceValueToCheck
                    ? null
                    : offense.id;
            }
        })
    );

    /**
     * For the provided offense, determine if there are any valid relationships
     */
    const offenseHasValidRelationship = (offenseId: number) => {
        const victimIds = victims
            .filter((victim) => victim.offenseId === offenseId)
            .map((victim) => victim.nameId);

        return victimIds.some((victimId) => {
            if (complianceGroup) {
                return victimHasValidDomesticViolenceRelationship({
                    formsRegistry,
                    relationshipData,
                    victimId,
                    complianceGroup,
                    organizationProfileById,
                });
            } else if (nibrsRegionalGroup) {
                return victimHasValidDomesticViolenceRelationship({
                    formsRegistry,
                    relationshipData,
                    victimId,
                    nibrsRegionalGroup,
                    organizationProfileById,
                });
            }
            return false;
        });
    };

    // By default, we want to check that all offenses with `isDomesticViolence == true` have "Domestic" relationship,
    // with `reverse` argument set to true, we want to find any offense that has "Domestic" relationship,
    // but `isDomesticViolence == false`
    if (castReverse) {
        return some(domesticViolenceOffenseIds, offenseHasValidRelationship);
    } else {
        return every(domesticViolenceOffenseIds, offenseHasValidRelationship);
    }
};

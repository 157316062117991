import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { InventoryTypeEnum } from '@mark43/evidence-api';
import { FilterList, FilterT, VisibleFilter } from 'arc';
import { useDateTimeFormatter } from '../../../../core/current-user/hooks/dateTimeFormats';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import { CustomDateRangeModal } from '../../../../core/forms/components/CustomDateRangeModal';
import { inventoriesDashboardSearch } from '../../state/ui';
import {
    COMPLETION_DATE_TIME_RANGE_FILTER_ID,
    INVENTORY_TYPE_FILTER_ID,
    RESPONSIBLE_PERSONNELL_FILTER_ID,
    STORAGE_LOCATION_FILTER_ID,
    convertInventoryDashboardArcFiltersToSearchFormData,
} from '../../../dashboard-filters/helpers';
import { DateRangeFilter } from '../../../dashboard-filters/components/DateRangeFilter';
import { UserSelectFilter } from '../../../dashboard-filters/components/UserSelectFilter';
import { StorageLocationFilter } from '../../../dashboard-filters/components/StorageLocationFilter';

const InventoriesFilterListWrapper = styled.div`
    margin: 10px 0;
`;

const CustomRangeWrapper = styled.div`
    margin-left: 6px;
    padding: 10px 0;
    font-style: italic;
`;

export const InventoriesDashboardFilters = () => {
    const dateTimeFormatter = useDateTimeFormatter();
    const [startDateUtcValue, setStartDateUtcValue] = useState<string | undefined>(undefined);
    const [endDateUtcValue, setEndDateUtcValue] = useState<string | undefined>(undefined);
    const [showDatePickerModal, setShowDatePickerModal] = useState(false);
    const [localFilters, setLocalFilters] = useState<VisibleFilter<string>[]>([]);
    const dispatch = useDispatch();
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const closeCustomDateRangeModal = React.useCallback(() => {
        setShowDatePickerModal(false);
    }, []);

    const onApplyFilters = useCallback(
        (formData) => {
            dispatch(
                // @ts-expect-error need to type search module abstraction
                inventoriesDashboardSearch.actionCreators.search(
                    {
                        formData,
                        from: 0,
                    },
                    { cacheBust: true }
                )
            );
        },
        [dispatch]
    );

    const filterList: FilterT[] = [
        {
            id: COMPLETION_DATE_TIME_RANGE_FILTER_ID,
            name: 'Completion Date/Time Range',
            render: (renderProps) => {
                return (
                    <DateRangeFilter
                        {...renderProps}
                        setShowDatePickerModal={setShowDatePickerModal}
                        periodOptions={['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D', 'P1M', 'P1Y']}
                        includeCustomFilterOption={true}
                        includeNoFilterOption={true}
                    />
                );
            },
            isAlwaysVisible: true,
            dynamicValues: true,
        },
        {
            id: INVENTORY_TYPE_FILTER_ID,
            name: 'Inventory Type',
            options: [
                {
                    label: 'Audit',
                    value: InventoryTypeEnum.AUDIT.name,
                    id: 1,
                },
                {
                    label: 'Inventory',
                    value: InventoryTypeEnum.INVENTORY.name,
                    id: 2,
                },
            ],
            selectionType: 'single',
            isAlwaysVisible: true,
        },
        {
            id: RESPONSIBLE_PERSONNELL_FILTER_ID,
            name: 'Responsible Personnel',
            render: (renderProps) => {
                return <UserSelectFilter {...renderProps} />;
            },
            selectionType: 'multiple',
            isAlwaysVisible: true,
        },
        {
            id: STORAGE_LOCATION_FILTER_ID,
            name: 'Storage Location',
            render: (renderProps) => {
                return <StorageLocationFilter {...renderProps} />;
            },
            selectionType: 'multiple',
            isAlwaysVisible: true,
        },
    ];

    if (!currentUserDepartmentId) {
        return null;
    }

    return (
        <InventoriesFilterListWrapper>
            <FilterList
                filters={filterList}
                onFiltersChange={(newFilters) => {
                    setLocalFilters(newFilters);
                    const completionDateTimeRangeFilter = newFilters.find(
                        (filter) => filter.id === COMPLETION_DATE_TIME_RANGE_FILTER_ID
                    );

                    if (!completionDateTimeRangeFilter) {
                        setStartDateUtcValue(undefined);
                        setEndDateUtcValue(undefined);
                    }

                    const formData = convertInventoryDashboardArcFiltersToSearchFormData(
                        newFilters,
                        currentUserDepartmentId,
                        startDateUtcValue,
                        endDateUtcValue
                    );
                    if (formData) {
                        onApplyFilters(formData);
                    }
                }}
            />
            {startDateUtcValue && endDateUtcValue && (
                <CustomRangeWrapper>
                    {`Custom Range: ${dateTimeFormatter.formatDate(
                        startDateUtcValue
                    )}-${dateTimeFormatter.formatDate(startDateUtcValue)}`}
                </CustomRangeWrapper>
            )}
            <CustomDateRangeModal
                onCancel={closeCustomDateRangeModal}
                onRequestClose={closeCustomDateRangeModal}
                isOpen={showDatePickerModal}
                startDateUtcValueInitialValue={''}
                endDateUtcValueInitialValue={''}
                includeTime={false}
                granularity={'month'}
                onSave={({ startDateUtcValue, endDateUtcValue }) => {
                    setStartDateUtcValue(startDateUtcValue);
                    setEndDateUtcValue(endDateUtcValue);
                    setShowDatePickerModal(false);
                    const formData = convertInventoryDashboardArcFiltersToSearchFormData(
                        localFilters,
                        currentUserDepartmentId,
                        startDateUtcValue,
                        endDateUtcValue
                    );

                    if (formData) {
                        onApplyFilters(formData);
                    }
                }}
            />
        </InventoriesFilterListWrapper>
    );
};

import { FileCategoryEnum, UsageActionEnum, EntityTypeEnum } from '@mark43/rms-api';
import { find, get, noop, map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, lifecycle } from 'recompose';
import classNames from 'classnames';
import styled from 'styled-components';
import { Flex } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import _ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { overlayStore } from '../../../core/overlayManager';
import { emailAttachments } from '../../../modules/core/emails/helpers';
import { horizCenter, horizVertCenter } from '../../../modules/core/styles/mixins';
import { hasPdfViewer } from '../../../modules/core/feature-detection';
import testIds from '../../../core/testIds';

// selectors
import {
    imageGalleryViewModelsSelector,
    imageGalleryCurrentIndexSelector,
    imageGalleryEmailsEnabledSelector,
} from '../../selectors/imageGallerySelectors';

// actions
import { nextImage, previousImage, setCurrentIndex } from '../../actions/imageGalleryActions';

// components
import Icon, { iconTypes, iconSizes } from '../../../modules/core/components/Icon';
import MediaPlayer from '../../../modules/core/components/MediaPlayer/MediaPlayer';
import SendEmailModal from '../../../modules/core/emails/components/SendEmailModal';
import {
    AttachmentDownloadButton,
    AttachmentEmailButton,
} from '../../../modules/attachments/core/components/AttachmentActionButtons';

// configs
import { fileIconTypeForFileCategory } from '../../../modules/attachments/files/helpers';

import { createUsageLog } from '../../../modules/admin/usage-logs/state/data';
import Button, { buttonTypes } from './Button';

const imageGalleryStrings = componentStrings.core.ImageGallery;

const NavButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;

    .textvalignmiddle {
        padding-left: 0;
    }
`;
const MediaPlayerWrapper = styled.div`
    width: 640px;
    padding-top: 35px;
    margin: 0 auto;
`;

function createViewAttachmentUsageLog(viewModels, generateAttachmentUsageLog) {
    const imageViewModels = viewModels.currentImages;
    const activeImage = find(imageViewModels, { isActive: true });
    generateAttachmentUsageLog(activeImage.image, UsageActionEnum.VIEWED_ATTACHMENT.name);
}

/**
 * Use the component to show a set of scrollabe images
 * @param {Object} options.viewModels      view models to be displayed
 * @param {function} options.nextImage       function that dispatches action to inc currentIndex
 * @param {function} options.previousImage   function that dispatches action to dec currentIndex
 * @param {function} options.setCurrentIndex function that dispatches action to set currentIndex
 */
function ImageGallery({
    // from selectors
    viewModels,
    // from actions
    nextImage,
    previousImage,
    setCurrentIndex,
    generateAttachmentUsageLog,
    usageLogParams, // params for usage Log - title, source
    emailsEnabled,
    openEmailModal,
}) {
    const previousButton = (
        <NavButton
            className={`${buttonTypes.ICON_LINK} image-gallery-nav-button`}
            onClick={previousImage}
        >
            <Icon
                color="mediumGrey"
                size={iconSizes.XXLARGE}
                type={iconTypes.OPEN_LEFT}
                className="image-gallery-nav-icon"
            />
        </NavButton>
    );
    const nextButton = (
        <NavButton
            className={`${buttonTypes.ICON_LINK} image-gallery-nav-button`}
            onClick={nextImage}
        >
            <Icon
                color="mediumGrey"
                size={iconSizes.XXLARGE}
                className="image-gallery-nav-icon"
                type={iconTypes.OPEN_RIGHT}
            />
        </NavButton>
    );

    const currentContent = map(viewModels.currentImages, (imageViewModel, index) => {
        const { image, isActive, title } = imageViewModel;

        return (
            <CurrentImageDetails
                key={index}
                image={image}
                isActive={isActive}
                title={title}
                onClickDownload={() =>
                    usageLogParams
                        ? generateAttachmentUsageLog(
                              image,
                              UsageActionEnum.DOWNLOADED_ATTACHMENT.name
                          )
                        : noop
                }
                onClickEmail={() => openEmailModal(image)}
                emailsEnabled={emailsEnabled}
            />
        );
    });

    const thumbnails = map(viewModels.thumbnails, (thumbnailViewModel) => {
        return (
            <ImageGalleryThumbnail
                key={thumbnailViewModel.index}
                image={thumbnailViewModel.image}
                isActive={thumbnailViewModel.isActive}
                index={thumbnailViewModel.index}
                setCurrentIndex={setCurrentIndex}
            />
        );
    });
    return (
        <>
            <div className="image-gallery">
                <div className="image-gallery-thumbnails">{thumbnails}</div>
                <div className="image-gallery-nav right">{nextButton}</div>
                <div className="image-gallery-nav left">{previousButton}</div>
                {currentContent}
            </div>
            <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                {emailsEnabled && <SendEmailModal />}
            </FeatureFlagged>
        </>
    );
}

const MediaIconWrapper = styled.div`
    ${horizVertCenter};
    height: 400px;
    width: 400px;
    background-color: ${(props) => props.theme.colors.black};
`;

const MediaIcon = styled(Icon)`
    ${horizVertCenter};
`;

const PdfRenderer = styled.object`
    display: block;
    width: ${(props) => props.theme.widths.container}px;
    margin: auto;
    height: 100%;
`;

const PdfWrapper = styled.div`
    height: 100%;
`;

const ImgWithExifOrientation = styled(_ImgWithExifOrientation)`
    position: absolute;
    height: 100%;
    width: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

function getMedia({ url, altText, fileCategory }) {
    if (url) {
        switch (fileCategory) {
            case FileCategoryEnum.IMAGE.name:
                return <ImgWithExifOrientation src={url} alt={altText} />;
            case FileCategoryEnum.VIDEO.name:
                return (
                    <MediaPlayerWrapper>
                        <MediaPlayer url={url} />
                    </MediaPlayerWrapper>
                );
            case FileCategoryEnum.FILE.name:
                return (
                    <MediaIconWrapper>
                        <MediaIcon
                            type={fileIconTypeForFileCategory(fileCategory)}
                            color="brightBlue"
                            size={iconSizes.XXLARGE}
                        />
                    </MediaIconWrapper>
                );
            case FileCategoryEnum.PDF.name:
                return (
                    <PdfWrapper>
                        {hasPdfViewer ? (
                            <PdfRenderer
                                data={`${url}#toolbar=0&zoom=page-width`}
                                type={'application/pdf'}
                            >
                                <MediaIcon
                                    type={fileIconTypeForFileCategory(fileCategory)}
                                    color="brightBlue"
                                    size={iconSizes.XXLARGE}
                                />
                            </PdfRenderer>
                        ) : (
                            <MediaIconWrapper>
                                <MediaIcon
                                    type={fileIconTypeForFileCategory(fileCategory)}
                                    color="brightBlue"
                                    size={iconSizes.XXLARGE}
                                />
                            </MediaIconWrapper>
                        )}
                        ;
                    </PdfWrapper>
                );
            case FileCategoryEnum.AUDIO.name:
                return (
                    <MediaPlayerWrapper>
                        <MediaPlayer url={url} />
                    </MediaPlayerWrapper>
                );
            default:
                return <ImgWithExifOrientation src={url} alt={altText} />;
        }
    } else {
        return (
            <MediaIconWrapper>
                <MediaIcon
                    type={fileIconTypeForFileCategory(fileCategory)}
                    color="brightBlue"
                    size={iconSizes.XXLARGE}
                />
            </MediaIconWrapper>
        );
    }
}

const ImageGalleryTitle = styled.div`
    font-size: var(--arc-fontSizes-lg);
    color: var(--arc-colors-raw-whiteAlpha-900);
    line-height: 28px;
`;

const ImageGalleryDetails = styled.div`
    font-size: var(--arc-fontSizes-md);
    color: var(--arc-colors-raw-whiteAlpha-700);
    line-height: 20px;
`;

const ImageGalleryTextWrapper = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    ${horizCenter} display: flex;
    top: 425px;
`;

const ImageGalleryDescription = styled.div`
    color: var(--arc-colors-raw-whiteAlpha-700);
    padding-bottom: 40px;
    white-space: pre-line;
    max-width: 500px;
`;

const ImageGalleryText = styled.div`
    padding-left: 10px;
`;

function CurrentImageDetails({
    image,
    isActive,
    title,
    onClickDownload,
    onClickEmail,
    emailsEnabled = false,
}) {
    const currentImageClasses = classNames('image-gallery-content', {
        active: isActive,
    });

    return (
        <div className={currentImageClasses}>
            <div className="image-gallery-current-image">{getMedia(image)}</div>
            <ImageGalleryTextWrapper data-test-id={testIds.IMAGE_GALLERY_LIGHTBOX_TEXT}>
                <Flex gap={1}>
                    <AttachmentDownloadButton href={image.url} onDownload={onClickDownload} />

                    <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                        {emailsEnabled && (
                            <AttachmentEmailButton
                                contentLength={image.fileContentLength}
                                onEmail={onClickEmail}
                            />
                        )}
                    </FeatureFlagged>
                </Flex>

                <ImageGalleryText>
                    <ImageGalleryTitle>{image.originalFileName}</ImageGalleryTitle>
                    <ImageGalleryTitle>{title}</ImageGalleryTitle>
                    <ImageGalleryDetails>
                        {imageGalleryStrings.uploadInfo(image.uploadedDate, image.uploadedBy)}
                    </ImageGalleryDetails>
                    <ImageGalleryDescription>{image.description}</ImageGalleryDescription>
                </ImageGalleryText>
            </ImageGalleryTextWrapper>
        </div>
    );
}

const ImageGalleryThumbnail = withHandlers({
    onClick: ({ setCurrentIndex }) => (index) => () => setCurrentIndex(index),
})(function ImageGalleryThumbnail({ image, isActive, index, onClick }) {
    const thumbnailClassName = classNames('image-gallery-thumbnail', {
        active: isActive,
    });
    return (
        <div className={thumbnailClassName} onClick={onClick(index)}>
            {image.thumbnail ? (
                <ImgWithExifOrientation src={image.thumbnail} alt={image.altText} />
            ) : (
                <MediaIcon
                    type={fileIconTypeForFileCategory(image.fileCategory)}
                    color="brightBlue"
                />
            )}
            <div className="image-gallery-thumbnail-overlay" />
        </div>
    );
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    nextImage: () => dispatch(nextImage()),
    previousImage: () => dispatch(previousImage()),
    setCurrentIndex: (index) => dispatch(setCurrentIndex(index)),
    generateAttachmentUsageLog: (image, usageLogAction) => {
        const { usageLogParams } = ownProps;
        const { sourceDescription, attachmentId, originalFileName, entityId, entityType } = image;
        const entityIsFromCase =
            entityType === EntityTypeEnum.CASE_NOTE.name || entityType === EntityTypeEnum.TASK.name;
        const primaryUsageLogEntityTitle =
            sourceDescription || get(usageLogParams, 'primaryEntityTitle') || '';
        const attachmentUsageLog = {
            sourceModule: usageLogParams.sourceModule,
            primaryEntityType: entityIsFromCase ? EntityTypeEnum.CASE.name : entityType,
            primaryEntityId: entityIsFromCase ? get(usageLogParams, 'primaryEntityId') : entityId,
            action: usageLogAction,
            primaryEntityTitle: `${primaryUsageLogEntityTitle}: ${attachmentId} - ${originalFileName}`,
        };
        dispatch(createUsageLog(attachmentUsageLog));
    },
    openEmailModal: (lightboxAttachment) => {
        const handleEmailSave = (emailData) => {
            const { attachmentId, entityType, entityId } = lightboxAttachment;
            return emailAttachments({
                emailData,
                attachmentFileIds: attachmentId ? [attachmentId] : [],
                entityType,
                entityId,
            });
        };

        overlayStore.open(overlayIdEnum.SEND_EMAIL_MODAL, {
            onSave: handleEmailSave,
        });
    },
});

const mapStateToProps = createStructuredSelector({
    viewModels: imageGalleryViewModelsSelector,
    currentIndex: imageGalleryCurrentIndexSelector,
    emailsEnabled: imageGalleryEmailsEnabledSelector,
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
        componentDidUpdate(prevProps) {
            const { viewModels, usageLogParams, generateAttachmentUsageLog } = this.props;
            if (
                // make sure that we only generate a usageLog if currentIndex changes
                prevProps.currentIndex !== this.props.currentIndex &&
                usageLogParams
            ) {
                createViewAttachmentUsageLog(viewModels, generateAttachmentUsageLog);
            }
        },
        componentDidMount() {
            const { viewModels, usageLogParams, generateAttachmentUsageLog } = this.props;
            if (usageLogParams) {
                // for the first image we view
                createViewAttachmentUsageLog(viewModels, generateAttachmentUsageLog);
            }
        },
    })
)(ImageGallery);

import React from 'react';
import { pure } from 'recompose';

import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import ItemIdentifierNItems from '../../../reports/core/components/item-profile/ItemIdentifierNItems';
import { itemIdentifiersFieldsetViewModel } from '../../../../legacy-redux/configs/fieldsetsConfig';

const { title } = itemIdentifiersFieldsetViewModel;

/**
 * @param {Object}  props
 * @param {boolean} [props.collapsed=false]
 * @param {boolean} [props.fuzzyMatchingEnabled]
 */
export default pure(function ItemIdentifiersFieldset({
    collapsed = false,
    fuzzyMatchingEnabled,
    ...otherProps
}) {
    return (
        <RRFFieldset highlightOnFocus={false} path="itemIdentifiers" title={title} {...otherProps}>
            {!collapsed && (
                <ItemIdentifierNItems
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    addItemOnDirty={true}
                    disableOtherDescription={true}
                    includeExpired={true}
                />
            )}
        </RRFFieldset>
    );
});

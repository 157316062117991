import { cloneDeep, filter, find, keys, map, mapValues, merge, reduce } from 'lodash';
import { createField } from 'markformythree';
import {
    ExportOptionTypeEnumType,
    ExportPresetOption,
    ExportOptionTypeEnum,
} from '@mark43/rms-api';
import { PickByValue as Pick } from 'utility-types';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds, { TestId } from '../../../../../core/testIds';
import type { ExportPresetFormDataShape } from './exportPresetsForm';

const strings = componentStrings.exports.options;

export const exportOptionTypesToDisplayNames = {
    [ExportOptionTypeEnum.ONLY_INCLUDE_FIELDS_WITH_DATA.name]: strings.onlyIncludeFieldsWithData,
    [ExportOptionTypeEnum.INCLUDE_NAME_ADDENDUM.name]: strings.includeNameAddendums,
    [ExportOptionTypeEnum.INCLUDE_HISTORY_EVENTS.name]: strings.includeHistoryEvents,
    [ExportOptionTypeEnum.INCLUDE_CONFIDENTIAL_INFORMATION.name]:
        strings.includeConfidentialInformation,
    [ExportOptionTypeEnum.INCLUDE_ATTACHMENT_FILES.name]: strings.includeAttachmentFiles,
} as const;

export type ExportOptionType = keyof typeof exportOptionTypesToDisplayNames;

export const exportOptionTypesToTestIds: Record<ExportOptionType, TestId> = {
    [ExportOptionTypeEnum.ONLY_INCLUDE_FIELDS_WITH_DATA.name]: testIds.EXPORT_PRESETS_ADMIN_EXPORT_OPTION_FIELDS_WITH_DATA,
    [ExportOptionTypeEnum.INCLUDE_NAME_ADDENDUM.name]: testIds.EXPORT_PRESETS_ADMIN_EXPORT_OPTION_ADDENUM,
    [ExportOptionTypeEnum.INCLUDE_HISTORY_EVENTS.name]: testIds.EXPORT_PRESETS_ADMIN_EXPORT_OPTION_HISTORY,
    [ExportOptionTypeEnum.INCLUDE_CONFIDENTIAL_INFORMATION.name]:
    testIds.EXPORT_PRESETS_ADMIN_EXPORT_OPTION_CONFIDENTIAL,
    [ExportOptionTypeEnum.INCLUDE_ATTACHMENT_FILES.name]: testIds.EXPORT_PRESETS_ADMIN_EXPORT_OPTION_ATTACHMENTS,
} as const;

const mergeAttachmentsField = {
    [ExportOptionTypeEnum.MERGE_ATTACHMENTS.name]: {},
};

export const exportOptionFields = mapValues(
    merge(cloneDeep(exportOptionTypesToDisplayNames), mergeAttachmentsField),
    () => createField<boolean>({})
);

export function convertExportPresetOptionsFromFormModel(
    exportPresetFormModel: ExportPresetFormDataShape
): Pick<ExportPresetOption, 'departmentId' | 'exportPresetId' | 'exportOptionType'>[] {
    const enabledOptionTypes = filter(
        keys(exportOptionFields),
        (optionType: keyof typeof exportOptionFields) =>
            // @ts-expect-error need this while mergeAttachments has a string value instead of boolean
            exportPresetFormModel[optionType] !== 'false' && exportPresetFormModel[optionType]
    );
    return map(enabledOptionTypes, (optionType) => {
        return {
            departmentId: exportPresetFormModel.departmentId,
            exportPresetId: exportPresetFormModel.id,
            exportOptionType: optionType,
        };
    });
}

export function convertExportPresetOptionsToFormModel(exportPresetOptions: ExportPresetOption[]) {
    const isMergeAttachmentsIncluded = find(
        exportPresetOptions,
        (option) => option.exportOptionType === ExportOptionTypeEnum.MERGE_ATTACHMENTS.name
    );
    return {
        ...reduce<ExportPresetOption, Partial<Record<ExportOptionTypeEnumType, true>>>(
            exportPresetOptions,
            (acc, option) => {
                acc[option.exportOptionType] = true;
                return acc;
            },
            {}
        ),
        ...(!isMergeAttachmentsIncluded
            ? {
                  // need to explicitly set merge attachments so radio button renders correctly
                  [ExportOptionTypeEnum.MERGE_ATTACHMENTS.name]: false,
              }
            : {}),
    };
}

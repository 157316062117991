import _, { forEach, get, map } from 'lodash';
import fieldTypeClientEnum from '../enums/client/fieldTypeClientEnum';

export default function formDataToArbiterDataRRF(
    state,
    dependencies,
    formModel,
    fieldViewModels,
    arbiterData = {}
) {
    let globalMaxNItemId = 0;
    const process = (tree, basePath = '', nItemId, nItemsParents) => {
        forEach(tree, (subtree, fieldPath) => {
            const combinedPath = basePath ? `${basePath}.${fieldPath}` : fieldPath;
            // then this thing is a field, and should be added directly
            if (subtree.fieldName && !subtree.resolver) {
                arbiterData[subtree.fieldName] = {
                    value: get(formModel, combinedPath),
                    meta: {
                        path: combinedPath,
                    },
                };
            } else if (subtree.fieldName && subtree.resolver) {
                arbiterData[subtree.fieldName] = {
                    value: subtree.resolver(state, dependencies)(formModel),
                    resolver: subtree.resolver,
                    meta: {
                        path: combinedPath,
                    },
                };
            } else if (
                subtree.type === fieldTypeClientEnum.N_ITEMS_FIELDSET ||
                subtree.type === fieldTypeClientEnum.N_FIELDSETS
            ) {
                const keys = _.keys(subtree.fields);
                const members = get(formModel, combinedPath);
                const fieldNames = map(keys, (key) => subtree.fields[key].fieldName);
                forEach(fieldNames, (fieldName) => {
                    if (!arbiterData[fieldName]) {
                        arbiterData[fieldName] = [];
                    }
                });
                forEach(members, (member, index) => {
                    ++globalMaxNItemId;
                    forEach(keys, (key) => {
                        const fieldName = subtree.fields[key].fieldName;
                        const fullPath = `${combinedPath}[${index}].${key}`;
                        let value = get(formModel, fullPath);
                        if (subtree.fields[key].resolver) {
                            value = subtree.fields[key].resolver(state, dependencies)(formModel, {
                                index,
                            });
                        }
                        const fieldDescriptor = {
                            value,
                            castedValue: undefined,
                            resolver: subtree.fields[key].resolver,
                            nItemInfo: {
                                id: globalMaxNItemId,
                                index,
                                // TODO support nested NItems
                                parents: [],
                            },
                            meta: {
                                path: fullPath,
                            },
                        };
                        if (Array.isArray(arbiterData[fieldName])) {
                            arbiterData[fieldName].push(fieldDescriptor);
                        } else {
                            arbiterData[fieldName] = [fieldDescriptor];
                        }
                    });
                });
            } else {
                process(subtree.fields, combinedPath, nItemId, nItemsParents);
            }
        });
    };
    process(fieldViewModels);
    return arbiterData;
}

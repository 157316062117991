import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { Form, Fieldset as MFTFieldset, lifecycleOptions } from 'markformythree';
import React from 'react';

import { findIndex, get } from 'lodash';
import {
    NAME_REPORT_LINK_OFFENDER_LIVED_WITH_VICTIM_ATTR_ID,
    NAME_REPORT_LINK_SUBSTANCE_ABUSE_ATTR_ID,
    NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_SUSPECT_STATUS_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import Row from '../../../../core/components/Row';
import { FormConfigurationRenderer } from '../../../../core/markformythree-arbiter/dynamic-fields/components/FormConfigurationRenderer';
import { CUSTOM_PROPERTIES_KEY_NAME } from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import AddArrestReportButton from './AddArrestReportButton';

class OffenseOffenderFields extends React.PureComponent {
    render() {
        const { nameLinkIndex } = this.props;
        return (
            <Row>
                <MFTFieldset
                    path="links"
                    render={() => (
                        <MFTFieldset
                            path="offenders"
                            render={() => (
                                <MFTFieldset
                                    path={`[${nameLinkIndex}]`}
                                    render={() => (
                                        <>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="offenderLivedWithVictimAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.OFFENDER_HOUSEHOLD_STATUS
                                                            .name
                                                    }
                                                    length="lg"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="substanceAbuseAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.SUBSTANCE_ABUSE.name
                                                    }
                                                    length="lg"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="suspectStatusAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.SUSPECT_STATUS.name
                                                    }
                                                    length="lg"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="suspectSuspectedDrugTypeAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.SUSPECTED_DRUG_TYPE.name
                                                    }
                                                    multiple={true}
                                                    length="lg"
                                                />
                                            </Row>
                                        </>
                                    )}
                                />
                            )}
                        />
                    )}
                />
            </Row>
        );
    }
}
export class OffenseOffenderAdditionalFields extends React.PureComponent {
    render() {
        const { nameLink, summaryMode, formatAttributeById, offenseFormIndex } = this.props;

        if (summaryMode) {
            return (
                <Form
                    name={RefContextEnum.FORM_OFFENSE.name}
                    index={offenseFormIndex}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    render={(form) => {
                        const offenderData = get(form.getState(), 'model.links.offenders');
                        const nameLinkIndex = findIndex(offenderData, { nameId: nameLink.nameId });
                        if (nameLinkIndex <= -1) {
                            return null;
                        }
                        return (
                            <SummaryList labelWidth={150} contentWidth={350}>
                                <SummaryRow
                                    fieldName={NAME_REPORT_LINK_OFFENDER_LIVED_WITH_VICTIM_ATTR_ID}
                                >
                                    {formatAttributeById(nameLink.offenderLivedWithVictimAttrId)}
                                </SummaryRow>
                                <SummaryRow fieldName={NAME_REPORT_LINK_SUBSTANCE_ABUSE_ATTR_ID}>
                                    {formatAttributeById(nameLink.substanceAbuseAttrId)}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_SUSPECT_STATUS_ATTR_ID
                                    }
                                >
                                    {formatAttributeById(nameLink.suspectStatusAttrId)}
                                </SummaryRow>
                                <FormConfigurationRenderer
                                    form={form}
                                    absoluteFormPath={`links.offenders[${nameLinkIndex}].${CUSTOM_PROPERTIES_KEY_NAME}`}
                                    mode="SUMMARY"
                                />
                            </SummaryList>
                        );
                    }}
                />
            );
        }

        return (
            <>
                <Form
                    name={RefContextEnum.FORM_OFFENSE.name}
                    index={offenseFormIndex}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    render={(form) => {
                        const offenderData = get(form.getState(), 'model.links.offenders');
                        const nameLinkIndex = findIndex(offenderData, { nameId: nameLink.nameId });
                        // Only if we found the `nameLinkIndex` in our m43 form,
                        // should we attempt to render the corresponding form elements
                        return (
                            nameLinkIndex > -1 && (
                                <>
                                    <OffenseOffenderFields
                                        form={form}
                                        nameLinkIndex={nameLinkIndex}
                                    />
                                    <FormConfigurationRenderer
                                        form={form}
                                        absoluteFormPath={`links.offenders[${nameLinkIndex}].${CUSTOM_PROPERTIES_KEY_NAME}`}
                                        mode="FORM"
                                    />
                                </>
                            )
                        );
                    }}
                />
                <Row>
                    <AddArrestReportButton defendantId={nameLink.nameId} />
                </Row>
            </>
        );
    }
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router';

import RecordsHeaderHistoryButton from '../../../../records/core/components/header/RecordsHeaderHistoryButton';
import { routeNameSelector } from '../../../../../routing/routerModule';
import routesConfig from '../../../../../routing/routesConfig';

function ReportHeaderHistoryButton({ routeName, onClick }) {
    return (
        <RecordsHeaderHistoryButton
            onClick={onClick}
            active={routesConfig.REPORT_HISTORY.name === routeName}
        />
    );
}

/**
 * Button in the report header for viewing the report history.
 */
export default compose(
    withRouter,
    connect(createStructuredSelector({ routeName: routeNameSelector })),
    withHandlers({
        // This will append 'history' to the given path name and push that new URL
        // routeName: the name of the currently active route in the URL
        onClick: ({ router, routeName, reportId }) => () => {
            const isHistoryView = routesConfig.REPORT_HISTORY.name === routeName;
            if (isHistoryView) {
                router.push(`/reports/${reportId}`);
            } else {
                router.push(`/reports/${reportId}/history`);
            }
        },
    })
)(ReportHeaderHistoryButton);

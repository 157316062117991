import { AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';

import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { hydratedIncidentByIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { attributesByTypeSelector } from '~/client-common/core/domain/attributes/state/data';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';

import { currentUserDepartmentProfileSelector } from '../../../../core/current-user/state/ui';
import { convertToFormModel } from '../forms/incidentForm';

export const refreshIncidentForm = ({ incidentId }) => (dispatch, getState) => {
    const state = getState();
    const { incident, offenseAttributes, offenseCode } = hydratedIncidentByIdSelector(state)(
        incidentId
    );
    const nameReportLinks = nameReportLinksWhereSelector(state)({
        contextId: incidentId,
        contextType: EntityTypeEnum.OFFENSE.name,
    });
    const locationLinks = locationEntityLinksWhereSelector(state)({
        entityId: incidentId,
        entityType: EntityTypeEnum.OFFENSE.name,
    });
    const statuteCodeSetAttributes = attributesByTypeSelector(state)(
        AttributeTypeEnum.OFFENSE_CODE_STATUTE_CODE_SET.name
    );
    const departmentSubDomain = currentUserDepartmentProfileSelector(state).department.subDomain;
    const fieldConfigurationContextByContextAndFieldName = fieldConfigurationContextByContextAndFieldNameSelector(
        state
    );

    return convertToFormModel({
        incident,
        offenseAttributes,
        nameReportLinks,
        locationLinks,
        statuteCodeSetAttributes,
        departmentSubDomain,
        offenseCode,
        fieldConfigurationContextByContextAndFieldName,
    });
};

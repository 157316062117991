import { get, map, size } from 'lodash';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import React from 'react';

import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import withFields from '~/client-common/core/fields/components/withFields';
import { citationViewModelByReportIdSelector } from '~/client-common/core/domain/citations/state/ui';
import { citationChargeViewModelsByCitationIdSelector } from '~/client-common/core/domain/citation-charges/state/ui';
import { buildCitationChargeOffenseCodeIdDisplayName } from '~/client-common/core/domain/reports/state/ui/citations';
import sortCitationCharges from '~/client-common/core/domain/citation-charges/utils/sortCitationCharges';

import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';

const mapStateToProps = createStructuredSelector({
    citationViewModelByReportId: citationViewModelByReportIdSelector,
    citationChargeViewModelsByCitationId: citationChargeViewModelsByCitationIdSelector,
});

const CitationCardSummary = compose(
    connect(mapStateToProps),
    withFields([
        fields.CITATION_CHARGE_OFFENSE_CODE_ID,
        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CITATION_STATISTICS_ATTRIBUTE_ID,
    ])
)(function _CitationCardSummary({
    citationViewModelByReportId,
    citationChargeViewModelsByCitationId,
    fieldDisplayNames,
    reportId,
}) {
    const citationViewModel = citationViewModelByReportId(reportId);
    const { id: citationId } = citationViewModel;
    const citationChargeViewModels = citationChargeViewModelsByCitationId(citationId);

    const citationViewModelProperties = getViewModelProperties(citationViewModel);
    const citationStatisticsAttributesDisplay = get(
        citationViewModelProperties,
        'citationStatisticsAttributesDisplay'
    );

    const citationChargeOffenseCodeIdDisplayName =
        fieldDisplayNames.CITATION_CHARGE_OFFENSE_CODE_ID;
    const citationChargeRows = map(
        sortCitationCharges({ citationCharges: citationChargeViewModels }),
        (citationChargeViewModel) => {
            const citationChargeViewModelProperties = getViewModelProperties(
                citationChargeViewModel
            );
            const offenseCodeLabel = buildCitationChargeOffenseCodeIdDisplayName(
                citationChargeOffenseCodeIdDisplayName,
                citationChargeViewModel.chargeOrder
            );

            return (
                <div key={citationChargeViewModel.id}>
                    <SummaryRow label={offenseCodeLabel}>
                        {get(citationChargeViewModelProperties, 'offenseCodeDisplayName')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.CITATION_CHARGE_CHARGE_RESULT_ATTR_ID}>
                        {get(citationChargeViewModelProperties, 'chargeResultAttrId')}
                    </SummaryRow>
                </div>
            );
        }
    );

    return (
        <SummaryList labelWidth={160} contentWidth={380}>
            <CardSection>
                <SummaryRow fieldName={fields.CITATION_CITATION_NUMBER}>
                    {get(citationViewModel, 'citationNumber')}
                </SummaryRow>
                <SummaryRowDate
                    fieldName={fields.CITATION_ISSUED_DATE_UTC}
                    date={get(citationViewModel, 'issuedDateUtc')}
                />
                <SummaryRow fieldName={fields.CITATION_CITATION_TYPE_ATTR_ID}>
                    {get(citationViewModelProperties, 'citationTypeDisplay')}
                </SummaryRow>
                <SummaryRow fieldName={fields.CITATION_CITATION_POSTED_SPEED}>
                    {get(citationViewModel, 'citationPostedSpeed')}
                </SummaryRow>
                <SummaryRow fieldName={fields.CITATION_CITATION_ACTUAL_SPEED}>
                    {get(citationViewModel, 'citationActualSpeed')}
                </SummaryRow>
                {citationChargeRows}
                {size(citationStatisticsAttributesDisplay) > 0 && (
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CITATION_STATISTICS_ATTRIBUTE_ID
                        }
                    >
                        {citationStatisticsAttributesDisplay}
                    </SummaryRow>
                )}
            </CardSection>
        </SummaryList>
    );
});

export default CitationCardSummary;

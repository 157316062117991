import { debounce, values, findIndex, ceil } from 'lodash';
import sortKeyEnum from '../core/enums/universal/sortKeyEnum';
import sortTypeEnum from '../core/enums/universal/sortTypeEnum';

const TYPEAHEAD_THROTTLE = 300;

/**
 * DEBOUNCES the given search function.
 */
export function rateLimitTypeahead(search: () => void, typeaheadThrottle = TYPEAHEAD_THROTTLE) {
    return debounce(search, typeaheadThrottle, {
        trailing: true, // search at the end of each interval, not the beginning
        leading: false,
    });
}

export function nextSortType(sortType: keyof typeof sortTypeEnum, sortTypes = sortTypeEnum) {
    const sortTypeValues = values(sortTypes);
    const currentIndex = findIndex(sortTypeValues, (type) => type === sortType);
    return sortTypeValues[currentIndex === sortTypeValues.length - 1 ? 0 : currentIndex + 1];
}

export function fromSizeToPage(from: number, size: number) {
    return ceil(from / size);
}

export function pageSizeToFrom(page: number, size: number) {
    return page * size - size;
}

/**
 * Every advanced search is cached based on its query parameters. This helper
 *   builds a unique string for each set of parameters, to be used for storage
 *   in state.
 * @param  {number} [query.from]
 * @param  {number} [query.size]
 * @param  {string} [query.sortKey]
 * @param  {string} [query.sortType]
 * @return {string}
 */
export function buildHashKeyForSearchQuery({
    from,
    size,
    sortKey,
    sortType,
}: {
    from: number;
    size: number;
    sortKey: keyof typeof sortKeyEnum;
    sortType: keyof typeof sortTypeEnum;
}) {
    return `${from}~${size}~${sortKey}~${sortType}`;
}

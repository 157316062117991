import { useCallback } from 'react';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { useOverlayStore } from '../../core/overlays/hooks/useOverlayStore';
import { EFileDetailsModalPropsT } from '../types';

export function useEFileDetailsModal() {
    const overlayStore = useOverlayStore<EFileDetailsModalPropsT>();

    const openUpdateEFile = useCallback(
        () => overlayStore.open(overlayIdEnum.UPSERT_E_FILE_MODAL),
        [overlayStore]
    );

    const openCreateEFile = useCallback(
        (modalProps: EFileDetailsModalPropsT) =>
            overlayStore.open(overlayIdEnum.E_FILE_CREATION_MODAL, modalProps),
        [overlayStore]
    );

    return { openCreateEFile, openUpdateEFile };
}

import styled from 'styled-components';
import SidebarControlsWrap from './SidebarControlsWrap';

interface FolderNameWrapperProps {
    folderDepth: number;
    isPathActive: boolean;
    isDraggingOver: boolean;
    draggingId?: number;
}

const FolderNameWrapper = styled.div<FolderNameWrapperProps>`
    display: flex;
    align-items: center;
    padding-left: ${({ folderDepth }: { folderDepth: number }) => {
        if (folderDepth === 1) {
            return `19px`;
        }
        return `${folderDepth * 12}px`;
    }};
    border: ${({ isDraggingOver, theme }) =>
        isDraggingOver ? `2px solid ${theme.colors.cobaltBlue};` : 'none'};
    background-color: ${({ isPathActive, theme, isDraggingOver }) => {
        if (isDraggingOver) {
            return theme.colors.lightBlue;
        }
        if (isPathActive) {
            return theme.colors.cobaltBlue;
        }

        return 'none';
    }};
    box-sizing: border-box;

    && .case-sidebar-link {
        padding: 0 10px;
        display: flex;
        align-items: inherit;
        color: ${({ isPathActive, theme }) => {
            if (isPathActive) {
                return theme.colors.white;
            }
            return theme.colors.cobaltBlue;
        }};
        background-color: ${({ isPathActive, theme, isDraggingOver }) => {
            if (isDraggingOver) {
                return theme.colors.lightBlue;
            }
            if (isPathActive) {
                return theme.colors.cobaltBlue;
            }
            return 'none';
        }};
    }

    & span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & svg {
        margin-right: 8px;
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        color: ${({ theme, isPathActive, isDraggingOver }) =>
            isPathActive && !isDraggingOver ? theme.colors.white : theme.colors.cobaltBlue};
        background-color: ${({ theme, isPathActive, isDraggingOver, draggingId }) =>
            isDraggingOver
                ? theme.colors.lightBlue
                : draggingId
                ? 'none'
                : isPathActive
                ? theme.colors.cobaltBlue
                : 'var(--arc-colors-interactive-hover)'};
    }

    &:hover {
        color: ${({ theme, isPathActive, draggingId }) =>
            isPathActive && !draggingId ? theme.colors.white : theme.colors.cobaltBlue};
        background-color: ${({ theme, isPathActive, isDraggingOver, draggingId }) =>
            isDraggingOver
                ? theme.colors.lightBlue
                : draggingId
                ? 'none'
                : isPathActive
                ? theme.colors.cobaltBlue
                : 'var(--arc-colors-interactive-hover)'};
    }
    &:hover ${SidebarControlsWrap} {
        display: flex;
    }

    &:hover polygon,
    &:hover path {
        fill: ${({ theme, isPathActive, draggingId }) =>
            isPathActive && !draggingId ? theme.colors.white : theme.colors.cobaltBlue};
    }

    & polygon,
    & path {
        fill: ${({ isPathActive, theme, isDraggingOver }) => {
            if (isDraggingOver) {
                return theme.colors.cobaltBlue;
            }
            if (isPathActive) {
                return theme.colors.white;
            }
            return theme.colors.cobaltBlue;
        }};
    }
`;

export default FolderNameWrapper;

import { EntityTypeEnum } from '@mark43/rms-api';
import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import { map, orderBy, debounce, compact } from 'lodash';

import styled from 'styled-components';
import { fieldNotesWhereSelector } from '~/client-common/core/domain/field-notes/state/data';
import { sortedLocationBundlesForLocationEntityLinksWhereSelector } from '~/client-common/core/domain/locations/state/ui';
import { locationByIdSelector } from '~/client-common/core/domain/locations/state/data';
import { fieldNoteEntityLinksWhereSelector } from '~/client-common/core/domain/field-note-entity-links/state/data';
import { personProfileViewModelByIdSelector } from '~/client-common/core/domain/person-profiles/state/ui';
import { personProfileByIdSelector } from '~/client-common/core/domain/person-profiles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { CollapsibleSectionWithStickyHeader } from '../../../core/components/CollapsibleSectionWithStickyHeader';
import { getHydratedFieldNotesForCurrentUser } from '../state/data/notepadLinksSidePanel';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import _LocationPill from '../../../core/locations/components/LocationPill';
import _PersonProfilePill from '../../../core/persons/components/PersonProfilePill';

import {
    filterPersonProfileViewModelsByFilter,
    filterLocationBundlesByFilter,
} from '../utils/filterNotepadLinks';
import { useDateTimeFormatter } from '../../../core/current-user/hooks/dateTimeFormats';
import { FilterInput } from './FilterInput';

const strings = componentStrings.notepad.sidePanel;

const Container = styled.div`
    margin-top: 10px;
    margin-bottom: 40px;
`;

const LocationPill = styled(_LocationPill)`
    margin-bottom: 10px;
`;

const PersonProfilePill = styled(_PersonProfilePill)`
    margin-bottom: 10px;
`;

const SidePanelContainer = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;

class NoteEntityDisplay extends React.Component {
    state = {
        isOpen: true,
    };

    render() {
        const { fieldNote, items, name, dateTimeFormatter } = this.props;
        if (items.length === 0) {
            return null;
        }
        const title = fieldNote.reportingEventNumber
            ? fieldNote.reportingEventNumber
            : dateTimeFormatter.formatDateTimeInSentence(fieldNote.createdDateUtc);
        return (
            <CollapsibleSectionWithStickyHeader
                isOpen={this.state.isOpen}
                toggleOpen={(isOpen) => this.setState({ isOpen })}
                title={title}
                description={`${items.length} ${items.length === 1 ? name : `${name}s`}`}
                containerSelector=".react-side-panel-content-wrapper"
            >
                <Container>
                    {map(items, (item) => {
                        return this.props.renderItem(item);
                    })}
                </Container>
            </CollapsibleSectionWithStickyHeader>
        );
    }
}

const NoteWithPersons = ({
    fieldNote,
    fieldNoteEntityLinksWhere,
    personProfileViewModelById,
    personProfileById,
    onClick,
    filterValue,
}) => {
    const dateTimeFormatter = useDateTimeFormatter();

    let filteredPersonProfileViewModels = compact(
        map(
            fieldNoteEntityLinksWhere({
                fieldNoteId: fieldNote.id,
            }),
            (fieldNoteEntityLink) => {
                return personProfileViewModelById(fieldNoteEntityLink.entityId);
            }
        )
    );
    if (filterValue) {
        filteredPersonProfileViewModels = filterPersonProfileViewModelsByFilter(
            filterValue,
            filteredPersonProfileViewModels
        );
    }

    return (
        <NoteEntityDisplay
            fieldNote={fieldNote}
            items={filteredPersonProfileViewModels}
            name={strings.personName}
            dateTimeFormatter={dateTimeFormatter}
            renderItem={(personProfileViewModel) => {
                return (
                    <PersonProfilePill
                        key={personProfileViewModel.id}
                        id={personProfileViewModel.id}
                        onClick={() => onClick(personProfileById(personProfileViewModel.id))}
                    />
                );
            }}
        />
    );
};

const NoteWithLocations = ({
    fieldNote,
    locationById,
    sortedLocationBundlesForLocationEntityLinksWhere,
    onClick,
    filterValue,
}) => {
    const dateTimeFormatter = useDateTimeFormatter();
    const isSubPremiseSupportEnabled = useSelector(applicationSettingsSelector)
        .SUBPREMISE_SUPPORT_ENABLED;

    let filteredLocationBundles = sortedLocationBundlesForLocationEntityLinksWhere({
        entityId: fieldNote.id,
        entityType: EntityTypeEnum.FIELD_NOTE.name,
    });

    if (filterValue) {
        filteredLocationBundles = filterLocationBundlesByFilter(
            filterValue,
            filteredLocationBundles,
            isSubPremiseSupportEnabled
        );
    }
    return (
        <NoteEntityDisplay
            fieldNote={fieldNote}
            items={filteredLocationBundles}
            name={strings.locationName}
            dateTimeFormatter={dateTimeFormatter}
            renderItem={({ location }) => (
                <LocationPill
                    key={location.id}
                    location={location}
                    onClick={() => onClick(locationById(location.id))}
                />
            )}
        />
    );
};

class NotepadLinksSidePanelWithoutOverlayBaseUnconnected extends Component {
    state = {
        filter: '',
    };
    constructor(...args) {
        super(...args);
        this.handleFilterChange = debounce(this.handleFilterChange, 300);
    }

    componentDidMount() {
        const { entityType, id } = this.props;
        this.props.getHydratedFieldNotesForCurrentUser({ entityType, overlayId: id });
    }

    // TODO: WAVE-2141 - Implement in a future ticket to get the data hooking
    // up properly.  It already works
    // when opening from KO, just need to fix it when
    // opening from a react side panel
    handleEntityClick = (...args) => {
        const { onEntityClick, savePanel } = this.props;
        onEntityClick(...args);
        savePanel();
    };

    handleFilterChange = (value) => {
        this.setState({
            filter: value,
        });
    };

    render() {
        const {
            fieldNotesWhere,
            fieldNoteEntityLinksWhere,
            personProfileViewModelById,
            sortedLocationBundlesForLocationEntityLinksWhere,
            entityType,
            onClose,
            isLoading,
            errorMessages,
            locationById,
            personProfileById,
        } = this.props;

        const fieldNotes = orderBy(
            fieldNotesWhere({ isArchived: false }),
            ['updatedDateUtc'],
            ['desc']
        );
        const title =
            entityType === EntityTypeEnum.PERSON_PROFILE.name
                ? strings.personsTitle
                : strings.locationsTitle;
        return (
            <PortalSidePanel
                noPadding={true}
                userHasAttemptedSave={errorMessages.length > 0}
                errorMessages={errorMessages}
                closePanel={onClose}
                saveText={null}
                title={title}
                hideFooter={false}
            >
                <SidePanelContainer>
                    {!isLoading && (
                        <FilterInput
                            autoFocus={true}
                            onChange={this.handleFilterChange}
                            placeholder={
                                entityType === EntityTypeEnum.PERSON_PROFILE.name
                                    ? strings.filterPersons
                                    : strings.filterLocations
                            }
                        />
                    )}
                    {isLoading && <SimpleLoading float="none" />}
                    {map(fieldNotes, (fieldNote) => {
                        if (entityType === EntityTypeEnum.PERSON_PROFILE.name) {
                            return (
                                <NoteWithPersons
                                    onClick={this.handleEntityClick}
                                    entityType={entityType}
                                    key={fieldNote.id}
                                    fieldNote={fieldNote}
                                    fieldNoteEntityLinksWhere={fieldNoteEntityLinksWhere}
                                    personProfileViewModelById={personProfileViewModelById}
                                    personProfileById={personProfileById}
                                    filterValue={this.state.filter}
                                />
                            );
                        } else if (entityType === EntityTypeEnum.LOCATION.name) {
                            return (
                                <NoteWithLocations
                                    onClick={this.handleEntityClick}
                                    entityType={entityType}
                                    key={fieldNote.id}
                                    fieldNote={fieldNote}
                                    locationById={locationById}
                                    sortedLocationBundlesForLocationEntityLinksWhere={
                                        sortedLocationBundlesForLocationEntityLinksWhere
                                    }
                                    filterValue={this.state.filter}
                                />
                            );
                        }
                    })}
                </SidePanelContainer>
            </PortalSidePanel>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    fieldNotesWhere: fieldNotesWhereSelector,
    sortedLocationBundlesForLocationEntityLinksWhere: sortedLocationBundlesForLocationEntityLinksWhereSelector,
    fieldNoteEntityLinksWhere: fieldNoteEntityLinksWhereSelector,
    personProfileViewModelById: personProfileViewModelByIdSelector,
    locationById: locationByIdSelector,
    personProfileById: personProfileByIdSelector,
});

const mapDispatchToProps = {
    getHydratedFieldNotesForCurrentUser,
};

const NotepadLinksSidePanelWithoutOverlayBase = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotepadLinksSidePanelWithoutOverlayBaseUnconnected);

/**
 * Overlay dispatcher for opening NotepadLinks side panel
 *
 * @typedef {import('../../../core/components/OverlayBaseHelper').OverlayRenderProps} OverlayRenderProps;
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.entityType
 * @param {({id: number}) => void} props.onEntityClick
 * @param {() => void} [props.onClose]
 * @param {(renderProps: OverlayRenderProps) => JSX.Element} props.renderButton
 * @returns {JSX.Element}
 */
export const NotepadLinksSidePanel = ({ id, renderButton, onClose, ...props }) => (
    <OverlayBaseHelper id={id} renderButton={renderButton}>
        {(renderProps) => (
            <NotepadLinksSidePanelWithoutOverlayBase
                errorMessages={renderProps.overlayBase.overlayState.errors}
                onClose={() => {
                    if (onClose) {
                        onClose();
                    }
                    renderProps.closePanel();
                }}
                isLoading={renderProps.overlayBase.overlayState.isLoading}
                id={id}
                savePanel={renderProps.savePanel}
                {...props}
            />
        )}
    </OverlayBaseHelper>
);

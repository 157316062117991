import _, { get, isArray } from 'lodash';
import { createSelector } from 'reselect';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import { agencyProfilesSelector } from '../data';

/**
 * Right now this only uses the agencyOri as the display option
 * but could easily be configured to use any other field
 * @param  {[type]} agencyProfilesSelector [description]
 * @param  {[type]} (agencyProfiles)       [description]
 * @return {[type]}                        [description]
 */
export const agencyProfilesWithOriOptionsWhereSelector = createSelector(
    agencyProfilesSelector,
    (agencyProfiles) => (predicate) => {
        // Having an agencyOri is not guaranteed
        let wrapper = _(agencyProfiles).filter('agencyOri');

        if (predicate) {
            wrapper = wrapper.filter(predicate);
        }

        return wrapper
            .map((agencyProfile) => {
                return {
                    display: `${agencyProfile.agencyOri} - ${agencyProfile.agencyName}`,
                    value: agencyProfile.id,
                };
            })
            .sortBy('display')
            .value();
    }
);

export const agencyProfileOptionsWhereSelector = createSelector(
    agencyProfilesSelector,
    (agencyProfiles) => (predicate) => {
        return _(agencyProfiles)
            .filter(predicate)
            .map((agencyProfile) => {
                return {
                    display: agencyProfile.agencyName,
                    value: agencyProfile.id,
                };
            })
            .sortBy('display')
            .value();
    }
);

export const formatAgencyProfileByIdSelector = createSelector(
    agencyProfilesSelector,
    (agencyProfiles) => {
        const mapper = (id) => get(agencyProfiles, `${id}.agencyName`, '');
        return (id) =>
            isArray(id) ? joinTruthyValues(_(id).map(mapper).sortBy().value()) : mapper(id);
    }
);

import React, { FC } from 'react';
import { Case } from '@mark43/rms-api';
import { useDisclosure } from 'arc';
import { useSelector } from 'react-redux';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useTargetProfileFieldName } from '~/client-common/core/fields/hooks/useFields';
import { caseElasticAttributesByCaseIdSelector } from '~/client-common/core/domain/case-attributes/state/data';

import { CollapsibleSectionWithStickyHeader } from '../../../core/components/CollapsibleSectionWithStickyHeader';
import CaseTargetProfileDetails from './CaseTargetProfileDetails';

const strings = componentStrings.cases.core.CaseTargetProfileDetails;

const CaseTargetProfileSection: FC<{ caseViewModel: Case; className: string }> = ({
    caseViewModel,
    className,
}) => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
    const { singularTargetProfileFieldName } = useTargetProfileFieldName();

    const getCaseAttributesByCaseId = useSelector(caseElasticAttributesByCaseIdSelector);
    const caseAttributes = getCaseAttributesByCaseId(caseViewModel.id);

    return (
        <CollapsibleSectionWithStickyHeader
            isOpen={isOpen}
            title={strings.targetProfileDetails(singularTargetProfileFieldName)}
            containerSelector=".case-content-container"
            toggleOpen={onToggle}
            className={className}
        >
            <CaseTargetProfileDetails
                caseAttributes={caseAttributes}
                narrative={caseViewModel.targetProfileNarrative}
                reviewDate={caseViewModel.targetProfileReviewDateUtc}
            />
        </CollapsibleSectionWithStickyHeader>
    );
};

export default CaseTargetProfileSection;

import { AttributeTypeEnum } from '@mark43/rms-api';
import { filter, map } from 'lodash';

import { createSelector } from 'reselect';

// helpers
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';
// selectors
import { formatAttributeByIdSelector, attributesSelector } from '../../../attributes/state/data';

import { reportAttributesSelector } from '../data';

const reportAttributeViewModelsSelector = createSelector(
    reportAttributesSelector,
    formatAttributeByIdSelector,
    attributesSelector,
    (reportAttributes, formatAttributeById, attributes) => {
        const viewModel = buildViewModel({
            mappers: [
                ({ attributeId }) => ({
                    attributeId: formatAttributeById(attributeId),
                }),
                ({ attributeId }) => ({
                    isOther: attributes[attributeId].other,
                }),
            ],
        });

        return map(reportAttributes, viewModel);
    }
);

export const reportAttributeViewModelsWhereSelector = createSelector(
    reportAttributeViewModelsSelector,
    (reportAttributeViewModels) => (predicate) => filter(reportAttributeViewModels, predicate)
);

export const reportLabelAttributeViewModelsForReportIdSelector = createSelector(
    reportAttributeViewModelsWhereSelector,
    (reportAttributeViewModels) => (reportId) => {
        return reportAttributeViewModels({
            reportId,
            attributeType: AttributeTypeEnum.ROUTING_LABEL.name,
        });
    }
);

import { ReportPrintingTemplate } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const reportPrintingTemplatesModule = createNormalizedModule<ReportPrintingTemplate>({
    type: 'reportPrintingTemplates',
    key: 'id',
});

// ACTIONS
export const storeReportPrintingTemplates =
    reportPrintingTemplatesModule.actionCreators.storeEntities;

export const deleteReportPrintingTemplatesWhere =
    reportPrintingTemplatesModule.actionCreators.deleteEntitiesWhere;

// SELECTORS
export const reportPrintingTemplatesSelector =
    reportPrintingTemplatesModule.selectors.entitiesSelector;

// REDUCER
export default reportPrintingTemplatesModule.reducerConfig;

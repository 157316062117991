import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withContext, withState } from 'recompose';
import classNames from 'classnames';

const Fieldset = compose(
    withState('focused', 'setFocused', false),
    withContext(
        {
            onInputChange: PropTypes.func,
            onInputFocus: PropTypes.func,
            onInputBlur: PropTypes.func,
        },
        ({ onInputChange, highlightOnFocus = true, setFocused }) => ({
            onInputChange,
            ...(highlightOnFocus
                ? {
                      onInputFocus: () => setFocused(true),
                      onInputBlur: () => setFocused(false),
                  }
                : {}),
        })
    )
)((props) => {
    const {
        title,
        showHeader = true,
        deleteButton,
        visible = true,
        focused,
        className,
        children,
        styles,
    } = props;

    if (!visible) {
        return <div />;
    }

    return (
        <fieldset className={classNames(styles.wrapper, { [styles.focused]: focused }, className)}>
            {showHeader && title && (
                <div className={styles.header}>
                    {deleteButton}
                    {title}
                </div>
            )}
            {children}
        </fieldset>
    );
});

/**
 * A group of form fields. This component adds additional functionality to the
 *   native `<fieldset>` element. The default behavior is the entire fieldset
 *   gets highlighted when any input inside it is focused.
 * @param {Object}   props Other props get spread into the `<fieldset>`.
 * @param {string}   [props.title] Optional title to appear in a header.
 * @param {boolean}  [props.showHeader=true] Set to `false` to hide the header
 *   even with the title and/or delete button are provided.
 * @param {boolean} [props.collapsed=true] Optional set initial collapsed state
 * @param {ReactElement} [props.deleteButton] Optional delete button to include
 *   in the header.
 * @param {boolean}  [props.highlightOnFocus=true] When any input inside this
 *   fieldset is focused, the whole fieldset gets highlighted to show the user
 *   where they are in the form.
 * @param {string}   [props.visible=true] Set to `false` to hide this fieldset.
 * @param {function} [props.onInputChange] Handler to call whenver an input
 *   inside this fieldset has its value changed.
 * @param {Object} [props.styles] App specific classname map. Keys are wrapper, focused, header
 */
export default pure(Fieldset);

const CollapsibleFieldset = compose(
    pure,
    withState('collapsed', 'setCollapsed', ({ collapsed = true }) => collapsed)
)(
    ({
        collapsed,
        setCollapsed,
        children,
        expandedChildren,
        styles,
        ExpandCollapseButton,
        ...otherProps
    }) => {
        const { collapsible, ...fieldsetStyles } = styles;
        return (
            <Fieldset {...otherProps} styles={fieldsetStyles} className={collapsible}>
                {
                    // the children that always appear regardless of collapsed
                    // state, and which renders the expand/collapse button
                    children(
                        <ExpandCollapseButton collapsed={collapsed} setCollapsed={setCollapsed} />,
                        collapsed,
                        setCollapsed
                    )
                }
                {
                    // the children that only appear when expanded
                    !collapsed && expandedChildren
                }
            </Fieldset>
        );
    }
);

/**
 * A `<Fieldset>` that is collapsible.
 * @param {Object}       props                  Other props get spread into the
 *   parent `<Fieldset>`.
 * @param {function}     props.children         `(expandCollapseButton,
 *   collapsed, setCollapsed) => ReactElement`, the children to be rendered in
 *   the collapsed state.
 * @param {ReactElement} props.expandedChildren The additional children to be
 *   rendered after `props.children` in the expanded state.
 * @param {Object} [props.styles] App specific classname map. Keys are `collapsible`, and the keys for the base fieldset class
 * @param {ReactComponent} [props.ExpandCollapseButton] App-specific button to control the fieldset. Accepts `collapsed` and `setCollapsed` as props
 */
export { CollapsibleFieldset };

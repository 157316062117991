import { SelectChangeEvent } from '@arc/select';
import { fromSizeToPage, pageSizeToFrom } from '~/client-common/helpers/searchHelpers';

const paginationDefaults = {
    FROM: 0,
    SIZE: 25,
};

export type Pagination = Partial<{
    from: number;
    size: number;
}>;

export type PaginationChangeHandler = (pagination: Pagination) => void;

export const useDataTablePagination = (
    totalSize: number,
    { from = paginationDefaults.FROM, size = paginationDefaults.SIZE }: Pagination,
    onChange: PaginationChangeHandler
) => {
    const prevPage = fromSizeToPage(from + 1, size);
    const maxPage = fromSizeToPage(totalSize, size);
    const currentPage = Math.max(1, Math.min(prevPage, maxPage) ?? 1);

    const handlePageChange = (page: number) => {
        onChange({ from: pageSizeToFrom(page, size), size });
    };

    const handleSizeChange = (newSize: number) => {
        onChange({ from: paginationDefaults.FROM, size: newSize });
    };

    const handleNextPage = () => {
        handlePageChange(currentPage + 1);
    };

    const handlePrevPage = () => {
        handlePageChange(currentPage - 1);
    };

    const handlePageIndexChange = (e: SelectChangeEvent) => {
        handlePageChange(Number(e.target.value) + 1);
    };

    const handlePageSizeChange = (e: SelectChangeEvent) => {
        handleSizeChange(Number(e.target.value));
    };

    return {
        pageIndex: currentPage - 1,
        pageSize: size,
        totalSize,
        onPageIndexChange: totalSize > size ? handlePageIndexChange : undefined,
        onPageSizeChange: handlePageSizeChange,
        onNextPage: handleNextPage,
        onPrevPage: handlePrevPage,
    };
};

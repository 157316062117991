import { RefContextEnum } from '@mark43/rms-api';
import { lifecycleOptions } from 'markformythree';
import React, { useState, useCallback } from 'react';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    CREATE_DISPOSITION_EVENT_VIEW_MANUAL_RETENTION_PERIOD,
    CREATE_DISPOSITION_EVENT_VIEW_SPECIFIC_DATE,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { MFTText } from '../../../../core/forms/components/Text';
import Row from '../../../../core/components/Row';
import { initialState, formConfiguration } from '../../state/forms/holdItemForm';
import { MFTRadio } from '../../../../core/forms/components/Radio';
import { MFTSelect } from '../../../../core/forms/components/selects/Select';
import { MFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { periodUnitOptions } from '../../../../admin/evidence-retention-policies/components/RetentionPolicyAdminForm';

const strings = componentStrings.reports.custodialPropertySummary.DispositionActionSidePanel;

/**
 * Form for a primary disposition reviewer to hold an item, which basically
 *   means reject it from going further in the disposition process and keep the
 *   item longer for the entered number of days. TODO: remove hardcoded labels
 *   after field configs are created
 */
const HoldItemForm = () => {
    const [durationDisabled, setDurationDisabled] = useState(true);
    const [specificDateDisabled, setSpecificDateDisabled] = useState(true);

    const {
        CREATE_DISPOSITION_EVENT_VIEW_MANUAL_RETENTION_PERIOD: durationField,
        CREATE_DISPOSITION_EVENT_VIEW_SPECIFIC_DATE: specificDateField,
    } = useFields([
        CREATE_DISPOSITION_EVENT_VIEW_SPECIFIC_DATE,
        CREATE_DISPOSITION_EVENT_VIEW_MANUAL_RETENTION_PERIOD,
    ]);

    const onChangeDuration = useCallback(
        (e, form) => {
            if (
                e.target.checked ||
                (specificDateDisabled && durationDisabled) ||
                (!specificDateDisabled && durationDisabled)
            ) {
                form.set('isSpecificDate', undefined);
                form.set('specificDate', undefined);
                setDurationDisabled(false);
                setSpecificDateDisabled(true);
            }
        },
        [durationDisabled, specificDateDisabled]
    );

    const onChangeSpecificDate = useCallback(
        (e, form) => {
            if (
                e.target.checked ||
                (specificDateDisabled && durationDisabled) ||
                (specificDateDisabled && !durationDisabled)
            ) {
                form.set('isDuration', undefined);
                form.set('periodAmount', undefined);
                form.set('periodUnit', undefined);
                setSpecificDateDisabled(false);
                setDurationDisabled(true);
            }
        },
        [durationDisabled, specificDateDisabled]
    );
    return (
        <ArbiterForm
            context={RefContextEnum.FORM_EVIDENCE_DISPOSITION_HOLD.name}
            configuration={formConfiguration}
            initialState={initialState}
            name={RefContextEnum.FORM_EVIDENCE_DISPOSITION_HOLD.name}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            render={(form) => (
                <>
                    <Row>
                        <label className="mark43-form-label mark43-form-element-label mark43-form-row-label">
                            {strings.holdDuration}
                        </label>
                    </Row>
                    <Row>
                        <MFTRadio
                            path="isDuration"
                            options={[
                                {
                                    value: true,
                                    label: durationField,
                                },
                            ]}
                            size="md"
                            value={!durationDisabled}
                            onChange={(e) => onChangeDuration(e, form)}
                        />
                    </Row>
                    <Row onClick={(e) => onChangeDuration(e, form)}>
                        <MFTText path="periodAmount" length="sm" disabled={durationDisabled} />
                        <MFTSelect
                            path="periodUnit"
                            length="sm"
                            options={periodUnitOptions}
                            disabled={durationDisabled}
                        />
                    </Row>
                    <Row>
                        <MFTRadio
                            path="isSpecificDate"
                            options={[
                                {
                                    value: true,
                                    label: specificDateField,
                                },
                            ]}
                            size="md"
                            value={!specificDateDisabled}
                            onChange={(e) => onChangeSpecificDate(e, form)}
                        />
                    </Row>
                    <Row onClick={(e) => onChangeSpecificDate(e, form)}>
                        <MFTDatePicker
                            path="specificDate"
                            width={115}
                            disabled={specificDateDisabled}
                        />
                    </Row>

                    <Row>
                        <ArbiterMFTTextArea path="comment" rows={6} />
                    </Row>
                </>
            )}
        />
    );
};

export default HoldItemForm;

import { filter, map, pick, uniqBy, values } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityTypeEnum, EntityTypeEnumType, TasksView } from '@mark43/rms-api';

import { taskViewsSelector } from '~/client-common/core/domain/taskViews/state/data';
import { taskEntityLinksSelector } from '~/client-common/core/domain/task-entity-links/state/data';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { useResource } from '~/client-common/core/hooks/useResource';
import { RmsDispatch } from '../../../../core/typings/redux';
import { withTasksView } from '../data';
import sortTasks from '../utils/sortTasks';
import taskResource from '../resources/taskResource';

/**
 * Fetch sorted tasks for linked entity. The data is stored in Redux Nexus state and returned from that state, so that
 * subsequent updates to the tasks will continue to be returned from this hook.
 */
export const useGetTasksForLinkedEntity = (entityId: number, entityType: EntityTypeEnumType) => {
    const dispatch = useDispatch<RmsDispatch>();

    const callResource = useCallback(() => {
        switch (entityType) {
            case EntityTypeEnum.ITEM_PROFILE.name:
            case EntityTypeEnum.PERSON_PROFILE.name:
            case EntityTypeEnum.REPORT.name:
                return taskResource.getTasksForLinkedEntity(entityId, entityType);
            case EntityTypeEnum.WARRANT.name:
            case EntityTypeEnum.BRIEFING.name:
            default:
                return taskResource.getTasksForEntity(entityType, entityId);
        }
    }, [entityId, entityType]);
    const onSuccess = useCallback(
        (result: TasksView) => {
            // store shared data in redux nexus state.
            dispatch(withTasksView(result));
        },
        [dispatch]
    );

    const { errorMessage, isLoading } = useResource(callResource, onSuccess);

    const allTasks = useSelector(taskViewsSelector);
    const allTaskEntityLinks = useSelector(taskEntityLinksSelector);
    const ownedTasks = filter(allTasks, { ownerId: entityId, ownerType: entityType });
    const taskEntityLinks = filter(allTaskEntityLinks, { entityId, entityType });
    const linkedTasks = values(pick(allTasks, map(taskEntityLinks, 'taskId')));
    const tasks = uniqBy([...ownedTasks, ...linkedTasks], 'id');
    const parentAttributeIdByAttributeId = useSelector(parentAttributeIdByAttributeIdSelector);
    const sortedTasks = sortTasks(tasks, { parentAttributeIdByAttributeId });

    return { errorMessage, isLoading, tasks: sortedTasks, taskEntityLinks };
};

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import {
    filter,
    keys,
    mergeWith,
    isArray,
    groupBy,
    reject,
    compact,
    get,
    includes,
    map,
} from 'lodash';
import { arrestForReportIdSelector } from '../../../arrests/state/data';
import { itemProfilesWhereSelector } from '../../../item-profiles/state/data';
import { nameItemLinksWhereSelector } from '../../../name-item-links/state/data';
import { nameReportLinksWhereSelector } from '../../../name-report-links/state/data';
import { personProfileByIdSelector } from '../../../person-profiles/state/data';

import { organizationProfileByIdSelector } from '../../../organization-profiles/state/data';

const linksByNameIdForReportIdSelector = createSelector(
    arrestForReportIdSelector,
    itemProfilesWhereSelector,
    nameItemLinksWhereSelector,
    nameReportLinksWhereSelector,
    (arrestForReportId, itemProfilesWhere, nameItemLinksWhere, nameReportLinksWhere) => (
        reportId
    ) => {
        const nameReportLinks = filter(nameReportLinksWhere({ reportId }), (nrl) => {
            // TODO Only recently have we made a DEFENDANT nrl to capture a defendant's statement (NRL.statement)
            // It will soon be a true NRL with: https://mark43.atlassian.net/browse/RMS-11189
            // We need to filter this out here since we're still creating a mock defendant below
            return nrl.linkType !== LinkTypesEnum.DEFENDANT;
        });

        const itemProfileIds = map(itemProfilesWhere({ ownerId: reportId }), 'id');
        const nameItemLinks = nameItemLinksWhere(({ itemProfileId }) =>
            includes(itemProfileIds, itemProfileId)
        );

        // Defendants
        const defendantId = get(arrestForReportId(reportId), 'defendantId');
        const fakeDefendantGroup = defendantId
            ? {
                  [defendantId]: [
                      // create a fake entity link here to structure
                      // defendants like everyone else
                      {
                          nameId: defendantId,
                          entityType: EntityTypeEnum.PERSON_PROFILE.name,
                          reportId,
                          linkType: LinkTypesEnum.DEFENDANT_IN_ARREST,
                          // There can only be one defendant
                          linkTypeSequenceNumber: 1,
                      },
                  ],
              }
            : {};

        const groupedNameReportLinks = groupBy(nameReportLinks, 'nameId');
        const groupedNameItemLinks = groupBy(nameItemLinks, 'nameId');

        const linksGroupedByNameId = mergeWith(
            {},
            groupedNameItemLinks,
            groupedNameReportLinks,
            fakeDefendantGroup,
            (objValue, srcValue) => (isArray(objValue) ? objValue.concat(srcValue) : undefined)
        );

        return linksGroupedByNameId;
    }
);

export const personProfilesWithLinksForReportIdSelector = createSelector(
    linksByNameIdForReportIdSelector,
    personProfileByIdSelector,
    (linksByNameIdForReportId, personProfileById) => (reportId) => {
        const linksByNameId = linksByNameIdForReportId(reportId);
        return compact(
            map(keys(linksByNameId), (nameId) => {
                const personProfile = personProfileById(nameId);
                return (
                    !!personProfile && {
                        links: linksByNameId[nameId],
                        entity: personProfile,
                    }
                );
            })
        );
    }
);

export const organizationProfilesWithLinksForReportIdSelector = createSelector(
    linksByNameIdForReportIdSelector,
    organizationProfileByIdSelector,
    (linksByNameIdForReportId, organizationProfileById) => (reportId) => {
        const linksByNameId = linksByNameIdForReportId(reportId);
        return compact(
            map(keys(linksByNameId), (nameId) => {
                const organizationProfile = organizationProfileById(nameId);
                return (
                    !!organizationProfile && {
                        links: linksByNameId[nameId],
                        entity: organizationProfile,
                    }
                );
            })
        );
    }
);

// Get all `PERSON_PROFILE`s associated with a `REPORT`.
// Includes:
//  - `NameReportLink`s
//  - `NameItemLink`s
//  - Defendants
export const personProfilesForReportIdSelector = createSelector(
    personProfilesWithLinksForReportIdSelector,
    (personProfilesWithLinksForReportId) => (reportId) => {
        const personProfilesWithLinks = personProfilesWithLinksForReportId(reportId);
        return map(personProfilesWithLinks, 'entity');
    }
);

// Get all `ORGANIZATION_PROFILE`s associated with a `REPORT`.
// Includes:
//  - `NameReportLink`s
//  - `NameItemLink`s
//  - `isSociety` orgs (when `includeSocietyProfiles` is true, which is the default)
export const organizationProfilesForReportIdSelector = createSelector(
    organizationProfilesWithLinksForReportIdSelector,
    (organizationProfilesWithLinksForReportId) => ({ reportId, includeSocietyProfiles = true }) => {
        const organizationProfilesWithLinks = organizationProfilesWithLinksForReportId(reportId);

        const organizationProfiles = map(organizationProfilesWithLinks, 'entity');

        return !includeSocietyProfiles
            ? reject(organizationProfiles, (organizationProfile) =>
                  get(organizationProfile, 'isSociety')
              )
            : organizationProfiles;
    }
);

import React, { useContext } from 'react';
import { WithRouterProps, withRouter } from 'react-router';
import { EntityTypeEnumType, EntityTypeEnum } from '@mark43/rms-api';
import { chain } from 'lodash';
import styled from 'styled-components';
import classNames from 'classnames';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../core/testIds';
import Icon, { iconTypes } from '../../../core/components/Icon';
import _DropdownMenu from '../../../core/components/DropdownMenu';
import OverlayButton from '../../../core/overlays/components/OverlayButton';
import { CurrentFolderIdContext, SelectedRowsContext, KebabMenuContext } from '../contexts';
import RemoveFromFolderMenuItem from './kebabMenu/RemoveFromFolderMenuItem';
import { EntityTypeIdContext } from './CreateFolderModal';

const strings = componentStrings.cases.core.KebabMenu;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
`;

const DropdownMenu = styled(_DropdownMenu)`
    &.sidebar > .dropdown-menu-button {
        border: 0;
        min-width: 0;
    }

    .dropdown-menu-options-container {
        width: 200px;
    }
`;

export enum KebabSources {
    SIDEBAR = 'SIDEBAR',
    CASE_ATTACHMENTS = 'CASE_ATTACHMENTS',
}
export enum KebabMenuActions {
    REMOVE_FROM_FOLDER = 'REMOVE_FROM_FOLDER',
    RENAME = 'RENAME',
    DELETE = 'DELETE',
    CREATE_NEW_FOLDER = 'CREATE_NEW_FOLDER',
}

interface DropdownMenuOptionForOverlayProps {
    children: React.ReactNode;
    closeMenu: () => void;
    overlayId: string;
    folderId: number | undefined;
    entityTypeId: EntityTypeEnumType;
    disabled?: boolean;
    isSidebar?: boolean;
    initiatedAction: KebabMenuActions;
    parentFolderId?: number;
}

const DropdownMenuOptionForOverlay: React.FC<DropdownMenuOptionForOverlayProps> = ({
    children,
    closeMenu,
    overlayId,
    folderId,
    entityTypeId,
    disabled,
    isSidebar,
    initiatedAction,
    parentFolderId,
}) => {
    const {
        setCurrentFolderId,
        setSelectedContentItemsToDelete,
        setFolderIdsToDelete,
        setParentFolderIdOfCurrentFolder,
    } = useContext(CurrentFolderIdContext);

    const { setEntityTypeId } = useContext(EntityTypeIdContext);
    const { selectedRows } = useContext(SelectedRowsContext);
    const { setSource, setInitiatedAction } = useContext(KebabMenuContext);

    if (disabled) {
        return <div className={classNames('dropdown-menu-option', 'disabled')}>{children}</div>;
    }

    return (
        <OverlayButton id={overlayId}>
            {(openOverlay: () => void) => (
                <div
                    data-test-id={testIds.FOLDER_KEBAB_MENU_OPTION}
                    children={children}
                    className="dropdown-menu-option"
                    onClick={() => {
                        openOverlay();
                        if (setCurrentFolderId) {
                            setCurrentFolderId(folderId);
                        }
                        if (setEntityTypeId) {
                            setEntityTypeId(entityTypeId);
                        }
                        /**
                         * parentFolderId assignment for router to navigate back to the
                         * parent folder(*if exists) when selected folder is deleted
                         */
                        if (setParentFolderIdOfCurrentFolder) {
                            setParentFolderIdOfCurrentFolder(parentFolderId);
                        }

                        // We need this so that the delete modal know when to navigate
                        // back to the attachments tab
                        if (setSource) {
                            setSource(
                                isSidebar ? KebabSources.SIDEBAR : KebabSources.CASE_ATTACHMENTS
                            );
                        }

                        if (setInitiatedAction) {
                            setInitiatedAction(initiatedAction);
                        }

                        if (setFolderIdsToDelete && isSidebar && folderId) {
                            setFolderIdsToDelete([folderId]);
                        } else if (
                            setFolderIdsToDelete &&
                            setSelectedContentItemsToDelete &&
                            selectedRows &&
                            selectedRows.length > 0
                        ) {
                            const folderIds = chain(selectedRows)
                                .filter((row) => row.isFolder)
                                .map('rowId')
                                .value();

                            // We need to know the entityType so we can display a dynamic banner
                            // after we exclude non Case Entity Types in the DeleteCaseFoldersAttachmentsAndCaseNotesModal.
                            const selectedContent = chain(selectedRows)
                                .filter((row) => !row.isFolder)
                                .map((row) => ({
                                    id: row.rowId,
                                    rowEntityType: row.entityType,
                                }))
                                .value();

                            setFolderIdsToDelete(folderIds);
                            setSelectedContentItemsToDelete(selectedContent);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === keyCodeEnum.ENTER) {
                            openOverlay();
                            closeMenu();
                            if (setCurrentFolderId) {
                                setCurrentFolderId(folderId);
                            }
                            if (setEntityTypeId) {
                                setEntityTypeId(entityTypeId);
                            }
                        }
                    }}
                />
            )}
        </OverlayButton>
    );
};

interface KebabMenuProps {
    folderId: number;
    entityTypeId: EntityTypeEnumType;
    sidebarLook: boolean;
    downloadSelected?: (
        currentFolderId: string | number | undefined,
        selectedRows: unknown[]
    ) => void;
    download?: () => void;
    canRename?: boolean;
    canDelete?: boolean;
    canCreateNewFolder?: boolean;
    canRemoveFromFolder?: boolean;
    disabled?: boolean;
    parentFolderId?: number;
}

const KebabMenu: React.FC<KebabMenuProps & WithRouterProps<{ folderId: string }>> = ({
    params,
    folderId,
    entityTypeId,
    sidebarLook,
    downloadSelected,
    canRename,
    canDelete,
    canRemoveFromFolder,
    canCreateNewFolder = true,
    disabled,
    parentFolderId,
}) => {
    const currentFolderId = params.folderId ? parseInt(params.folderId, 10) : undefined;
    const { selectedRows } = useContext(SelectedRowsContext);

    const renderDropdownMenuOptions = (closeMenu: () => void) => (
        <>
            {selectedRows?.length ? (
                <div
                    className="dropdown-menu-option"
                    onClick={() => downloadSelected?.(currentFolderId, selectedRows)}
                >
                    {`${strings.download} (${selectedRows.length})`}
                </div>
            ) : (
                <div
                    className="dropdown-menu-option"
                    onClick={() => downloadSelected?.(currentFolderId, [])}
                >{`${strings.download} All`}</div>
            )}
            <DropdownMenuOptionForOverlay
                closeMenu={closeMenu}
                overlayId={overlayIdEnum.DELETE_CASE_ATTACHMENTS_FOLDER_MODAL}
                folderId={folderId}
                entityTypeId={entityTypeId}
                isSidebar={false}
                disabled={!canDelete}
                initiatedAction={KebabMenuActions.DELETE}
            >
                {strings.delete}
                {` (${selectedRows.length})`}
            </DropdownMenuOptionForOverlay>
            <DropdownMenuOptionForOverlay
                closeMenu={closeMenu}
                overlayId={overlayIdEnum.CREATE_FOLDER_MODAL}
                disabled={!canCreateNewFolder}
                folderId={folderId}
                entityTypeId={entityTypeId}
                initiatedAction={KebabMenuActions.CREATE_NEW_FOLDER}
            >
                {strings.createNewFolder}
            </DropdownMenuOptionForOverlay>
            <RemoveFromFolderMenuItem
                initiatedAction={KebabMenuActions.REMOVE_FROM_FOLDER}
                disabled={!canRemoveFromFolder}
            />
            <DropdownMenuOptionForOverlay
                closeMenu={closeMenu}
                overlayId={overlayIdEnum.UPDATE_FOLDER_MODAL}
                folderId={selectedRows?.[0]?.rowId}
                entityTypeId={entityTypeId}
                disabled={!canRename}
                initiatedAction={KebabMenuActions.RENAME}
            >
                {strings.rename}
            </DropdownMenuOptionForOverlay>
        </>
    );

    const renderSidebarMenuOptions = (closeMenu: () => void) => (
        <>
            <div className="dropdown-menu-option">{strings.download}</div>
            <DropdownMenuOptionForOverlay
                closeMenu={closeMenu}
                overlayId={overlayIdEnum.DELETE_CASE_ATTACHMENTS_FOLDER_MODAL}
                folderId={folderId}
                entityTypeId={entityTypeId}
                disabled={!canDelete}
                isSidebar={true}
                initiatedAction={KebabMenuActions.DELETE}
                parentFolderId={parentFolderId}
            >
                {strings.delete}
            </DropdownMenuOptionForOverlay>
            <DropdownMenuOptionForOverlay
                closeMenu={closeMenu}
                overlayId={overlayIdEnum.UPDATE_FOLDER_MODAL}
                folderId={folderId}
                entityTypeId={entityTypeId}
                disabled={!canRename}
                initiatedAction={KebabMenuActions.RENAME}
            >
                {strings.rename}
            </DropdownMenuOptionForOverlay>
        </>
    );

    return (
        <>
            <DropdownMenu
                disabled={disabled}
                className={classNames(sidebarLook && 'sidebar')}
                buttonContent={
                    <DropdownMenuButtonContainer data-test-id={testIds.FOLDER_KEBAB_MENU_BUTTON}>
                        <Icon color="cobaltBlue" size={16} type={iconTypes.MORE_ACTIONS} />
                        {!sidebarLook && (
                            <Icon color="cobaltBlue" size={16} type={iconTypes.TRIANGLE_DOWN} />
                        )}
                    </DropdownMenuButtonContainer>
                }
                dropRight={sidebarLook || entityTypeId === EntityTypeEnum.CASE_NOTE.name}
                data-test-id={testIds.FOLDER_KEBAB_MENU}
            >
                {sidebarLook ? renderSidebarMenuOptions : renderDropdownMenuOptions}
            </DropdownMenu>
        </>
    );
};

export default withRouter(KebabMenu);

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { citationByReportIdSelector } from '~/client-common/core/domain/citations/state/data';
import { formatNameReportLinkTypeIdSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    CITATION_LOCATION_PATH,
    CITATION_LOCATION_DESCRIPTION_FULL_PATH,
    CITATION_LOCATION_POSITION_ATTR_ID_FULL_PATH,
    CITATION_RECIPIENT_PATH,
    formName,
    getCitationForm,
    createCitationForm,
    buildCitationCardFormModel,
} from '../../state/forms/citationForm';
import { registerForm } from '../../state/ui';
import withCard from '../../utils/withCard';
import citationCard from '../../state/ui/citationCard';
import testIds from '../../../../../core/testIds';
import { registerCard } from '../../utils/cardsRegistry';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import CitationCardForm from './CitationCardForm';
import CitationCardSummary from './CitationCardSummary';

class CitationCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            buildCitationCardFormModel,
            currentReportId: reportId,
        } = this.props;

        const form = createCitationForm({
            initialState: buildCitationCardFormModel({ reportId }),
            arbiter,
            formatFieldByName,
        });

        registerForm({ form });
        registerCard({
            cardModule: citationCard,
            onSave: this.onSave,
        });
    }

    componentWillUnmount() {
        formsRegistry.unregister(formName);
    }

    handleCitationLocationAdd = (location, locationEntityLinkModel) => {
        const form = getCitationForm();
        form.set(CITATION_LOCATION_PATH, locationEntityLinkModel);
    };

    handleCitationLocationRemove = () => {
        const form = getCitationForm();
        form.set(CITATION_LOCATION_PATH, undefined);
    };

    handleCitationRecipientAdd = (nameReportLink) => {
        const form = getCitationForm();
        form.set(CITATION_RECIPIENT_PATH, get(nameReportLink, 'linkType'));
    };

    handleCitationRecipientRemove = () => {
        const form = getCitationForm();
        form.set(CITATION_RECIPIENT_PATH, undefined);
    };

    onEdit = () => {
        this.props.editCallback(() => this.props.onEdit());
    };

    onSaveProgress = () => {
        const form = getCitationForm();
        return this.props.onSaveProgress(form, {});
    };

    onSave = () => {
        const form = getCitationForm();
        return this.props.onSave(form);
    };

    render() {
        const {
            card = {},
            currentReportId: reportId,
            reportById,
            citationByReportId,
            formatNameReportLinkTypeId,
            currentReportCardUITitleByType,
        } = this.props;
        const {
            anchor,
            summaryMode: cardSummaryMode,
            errorMessages,
            canEditReportCardStatus,
            saving,
        } = card;

        const cardTitle = currentReportCardUITitleByType(reportCardEnum.CITATION.id);
        const citation = citationByReportId(reportId);
        const citationId = citation.id;
        const renForRecents = get(reportById(reportId), 'reportingEventNumber');
        const citationRecipientTitle = formatNameReportLinkTypeId(
            LinkTypesEnum.SUBJECT_IN_CITATION
        );

        return (
            <Card
                testId={testIds.CITATION_CARD}
                className={anchor}
                anchor={anchor}
                title={cardTitle}
                renderContent={(summaryMode) => {
                    return (
                        <>
                            {summaryMode ? (
                                <CitationCardSummary reportId={reportId} />
                            ) : (
                                <CitationCardForm />
                            )}
                            <ArbiterForm
                                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                                name={formName}
                                context={formName}
                                render={() => {
                                    return (
                                        <CardSection
                                            testId={testIds.CITATION_LOCATION_SECTION}
                                            fieldName={
                                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_CITATION_LOCATION_ID
                                            }
                                        >
                                            <LocationSummaryViewWrapperWithFormFields
                                                summaryMode={summaryMode}
                                                onLocationAdd={this.handleCitationLocationAdd}
                                                onLocationRemove={this.handleCitationLocationRemove}
                                                entityType={EntityTypeEnum.CITATION.name}
                                                entityId={citationId}
                                                linkType={LinkTypesEnum.LOCATION_OF_CITATION}
                                                locationDescriptionPath={() =>
                                                    CITATION_LOCATION_DESCRIPTION_FULL_PATH
                                                }
                                                locationPositionAttrIdPath={() =>
                                                    CITATION_LOCATION_POSITION_ATTR_ID_FULL_PATH
                                                }
                                            />
                                        </CardSection>
                                    );
                                }}
                            />
                            <CardSection
                                testId={testIds.SUBJECT_IN_CITATION_SECTION}
                                fieldName={
                                    fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_CITATION_LINK_TYPE
                                }
                            >
                                <NameSummaryViewWrapper
                                    renForRecents={renForRecents}
                                    summaryMode={summaryMode}
                                    addNameButtonText={citationRecipientTitle}
                                    reportId={reportId}
                                    contextType={EntityTypeEnum.CITATION.name}
                                    contextId={citationId}
                                    parentEntityType={EntityTypeEnum.REPORT.name}
                                    parentId={reportId}
                                    linkType={LinkTypesEnum.SUBJECT_IN_CITATION}
                                    show={{ people: true, organizations: true }}
                                    limitToOne={true}
                                    onAddSuccess={this.handleCitationRecipientAdd}
                                    onRemoveSuccess={this.handleCitationRecipientRemove}
                                />
                            </CardSection>
                        </>
                    );
                }}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={cardSummaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                saving={saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class CitationCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => <CitationCard ref={this.setRef} {...this.props} arbiter={arbiter} />}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    citationByReportId: citationByReportIdSelector,
    formatFieldByName: formatFieldByNameSelector,
    formatNameReportLinkTypeId: formatNameReportLinkTypeIdSelector,
    reportById: reportByIdSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = {
    buildCitationCardFormModel,
};

export default compose(
    withCard(citationCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(CitationCardWrapper);

import React from 'react';
import styled from 'styled-components';

import ReportPermissionsModal from '../../../../core/permissions/components/ReportPermissionsModal';

const Container = styled.span`
    display: inline-flex;
`;

function ReportHeaderPermissionsButton({ currentReportId, reportShortTitle }) {
    return (
        <Container>
            <ReportPermissionsModal
                reportShortTitle={reportShortTitle}
                reportId={currentReportId}
            />
        </Container>
    );
}
export default ReportHeaderPermissionsButton;

import { RefContextEnum } from '@mark43/rms-api';
import * as fields from '~/client-common/core/enums/universal/fields';

import baseCourtOrderFormFieldViewModels from '../../../core/state/forms/baseCourtOrderFormFieldViewModels';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const fieldViewModels = {
    ...buildFlatFormFieldViewModels([
        {
            key: 'chargeIdsToSeal',
            fieldName: fields.CHARGE_ID,
        },
        {
            key: 'personIdToSeal',
            fieldName: fields.PERSON_PROFILE_ID,
        },
        {
            key: 'sealingType',
            fieldName: fields.COURT_ORDER_SEALING_TYPE,
        },
        {
            key: 'statuteSealingPersonIdsToSeal',
            fieldName: fields.STATUTE_SEALING_PERSON_IDS_TO_SEAL,
        },
    ]),
    ...baseCourtOrderFormFieldViewModels,
};

export default createFormModule({
    context: RefContextEnum.FORM_REPORT_SEALING.name,
    fieldViewModels,
});

import { isEmpty, map } from 'lodash';
import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    TrafficCrashRoadwayViewModel,
    trafficCrashRoadwayViewModelsByReportIdSelector,
} from '~/client-common/core/domain/traffic-crash-roadways/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import testIds from '../../../../core/testIds';
import { CardSection } from '../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import SummarySectionDivider from '../../../../legacy-redux/components/summaries/SummarySectionDivider';
import { TrafficCrashEntityDetailSummarySection } from './TrafficCrashEntityDetailSummarySection';

const strings = componentStrings.trafficCrashRoadwaySummary;

const TrafficCrashRoadwaySummary = ({
    viewModel,
    index,
}: {
    viewModel: TrafficCrashRoadwayViewModel;
    index: number;
}) => {
    if (!viewModel) {
        return null;
    }

    const viewModelProperties = getViewModelProperties(viewModel);

    return (
        <div>
            {index > 0 && <SummarySectionDivider className="summary-section-divider" />}
            <SummaryRow label={strings.roadwayDirectionAttrId}>
                {viewModelProperties.roadwayDirectionAttrId}
            </SummaryRow>
            <SummaryRow label={strings.roadwayName}>{viewModel.roadwayName}</SummaryRow>
            <SummaryRow label={strings.structureIdentificationNumber}>
                {viewModel.structureIdentificationNumber}
            </SummaryRow>
            <SummaryRow label={strings.roadwayCurvatureRadius}>
                {viewModel.roadwayCurvatureRadius}
            </SummaryRow>
            <SummaryRow label={strings.roadwayCurvatureLength}>
                {viewModel.roadwayCurvatureLength}
            </SummaryRow>
            <SummaryRow label={strings.roadwayCurvatureElevation}>
                {viewModel.roadwayCurvatureElevation}
            </SummaryRow>
            <SummaryRow label={strings.gradeDirectionOfSlopeAttrId}>
                {viewModelProperties.gradeDirectionOfSlopeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.gradePercentOfSlope}>
                {viewModel.gradePercentOfSlope}
            </SummaryRow>
            <SummaryRow label={strings.partOfNationalHighwaySystemAttrId}>
                {viewModelProperties.partOfNationalHighwaySystemAttrId}
            </SummaryRow>
            <SummaryRow label={strings.roadwayFunctionalClassAttrId}>
                {viewModelProperties.roadwayFunctionalClassAttrId}
            </SummaryRow>
            <SummaryRow label={strings.annualAverageDailyTrafficYear}>
                {viewModel.annualAverageDailyTrafficYear}
            </SummaryRow>
            <SummaryRow label={strings.annualAverageDailyTraffic}>
                {viewModel.annualAverageDailyTraffic}
            </SummaryRow>
            <SummaryRow label={strings.annualAverageDailyTrafficTruckCount}>
                {viewModel.annualAverageDailyTrafficTruckCount}
            </SummaryRow>
            <SummaryRow label={strings.annualAverageDailyTrafficMotorcycleCount}>
                {viewModel.annualAverageDailyTrafficMotorcycleCount}
            </SummaryRow>
            <SummaryRow label={strings.laneWidth}>{viewModel.laneWidth}</SummaryRow>
            <SummaryRow label={strings.leftShoulderWidth}>{viewModel.leftShoulderWidth}</SummaryRow>
            <SummaryRow label={strings.rightShoulderWidth}>
                {viewModel.rightShoulderWidth}
            </SummaryRow>
            <SummaryRow label={strings.widthOfMedian}>{viewModel.widthOfMedian}</SummaryRow>
            <SummaryRow label={strings.accessControlAttrId}>
                {viewModelProperties.accessControlAttrId}
            </SummaryRow>
            <SummaryRow label={strings.railwayCrossingId}>{viewModel.railwayCrossingId}</SummaryRow>
            <SummaryRow label={strings.roadwayLightingAttrId}>
                {viewModelProperties.roadwayLightingAttrId}
            </SummaryRow>
            <SummaryRow label={strings.longitudinalEdgelineTypeAttrId}>
                {viewModelProperties.longitudinalEdgelineTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.longitudinalCenterlineTypeAttrId}>
                {viewModelProperties.longitudinalCenterlineTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.longitudinalLaneLineMarkingsAttrId}>
                {viewModelProperties.longitudinalLaneLineMarkingsAttrId}
            </SummaryRow>
            <SummaryRow label={strings.typeOfBicycleFacilityAttrId}>
                {viewModelProperties.typeOfBicycleFacilityAttrId}
            </SummaryRow>
            <SummaryRow label={strings.signedBicycleRouteAttrId}>
                {viewModelProperties.signedBicycleRouteAttrId}
            </SummaryRow>
            <SummaryRow label={strings.mainlineNumLanesAtIntersectionAttrId}>
                {viewModelProperties.mainlineNumLanesAtIntersectionAttrId}
            </SummaryRow>
            <SummaryRow label={strings.crossStreetNumLanesAtIntersectionAttrId}>
                {viewModelProperties.crossStreetNumLanesAtIntersectionAttrId}
            </SummaryRow>
            <SummaryRow label={strings.totalVolumeOfEnteringVehiclesAadtYear}>
                {viewModel.totalVolumeOfEnteringVehiclesAadtYear}
            </SummaryRow>
            <SummaryRow label={strings.totalVolumeOfEnteringVehiclesAadt}>
                {viewModel.totalVolumeOfEnteringVehiclesAadt}
            </SummaryRow>
            <TrafficCrashEntityDetailSummarySection
                entityId={viewModel.id}
                entityType={EntityTypeEnum.TRAFFIC_CRASH_ROADWAY.name}
            />
        </div>
    );
};

const TrafficCrashRoadwaySummarySection = ({ reportId }: { reportId: number }) => {
    const trafficCrashRoadwayViewModelByReportId = useSelector(
        trafficCrashRoadwayViewModelsByReportIdSelector
    );
    const viewModels = trafficCrashRoadwayViewModelByReportId(reportId);
    const rows = map(viewModels, (viewModel, index) => (
        <TrafficCrashRoadwaySummary key={viewModel.id} viewModel={viewModel} index={index} />
    ));

    const list = (
        <SummaryList labelWidth={200} contentWidth={340}>
            {rows}
        </SummaryList>
    );

    return !isEmpty(rows) ? (
        <CardSection title={strings.title} testId={testIds.TRAFFIC_CRASH_ROADWAY_SUMMARY}>
            {list}
        </CardSection>
    ) : null;
};

export default TrafficCrashRoadwaySummarySection;

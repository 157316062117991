import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { openBox } from '../../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../../core/box/state/ui';
import { loadItemQueue } from '../../../core/state/data';

export const popoverContext = { name: boxEnum.ITEM_QUEUE_POPOVER };

// Actions
/**
 * Open the Item Queue Popover
 * @param {boolean} options.loadQueue whether to load the queue before opening the popover
 */
export function openItemQueuePopover({ loadQueue = true } = {}) {
    return (dispatch) => {
        if (loadQueue) {
            dispatch(openBox(popoverContext));
            return dispatch(loadItemQueue());
        } else {
            return dispatch(openBox(popoverContext));
        }
    };
}

// Selectors
/**
 * Return the isOpen state of Item Queue Popover
 * @return {boolean}
 */
export const itemQueuePopoverIsOpenSelector = createModalSelector(popoverContext, 'isOpen');

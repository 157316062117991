import getChargesResource from '~/client-common/core/domain/charges/resources/chargesResource';
import storeChargeWarrantView from './storeChargeWarrantView';
import { searchElasticWarrants } from './findWarrantSearch';

export const selectWarrant = ({ warrantId }) => (dispatch) => {
    return getChargesResource()
        .getChargeWarrantViewForWarrantId({ warrantId })
        .then((chargeWarrantView) => {
            const { warrant } = chargeWarrantView;
            dispatch(
                // eslint-disable-next-line no-restricted-syntax
                storeChargeWarrantView({
                    chargeWarrantView,
                    action: {
                        type: 'ARREST_CHARGES_SIDE_PANEL_FIND_WARRANT_SEARCH_SELECT_WARRANT',
                    },
                })
            );
            return warrant;
        });
};

export const performFindWarrantSearchMoreResults = ({ elasticQuery, from, size }) => (dispatch) => {
    const newFrom = from + size;
    return dispatch(
        searchElasticWarrants({
            elasticQuery,
            from: newFrom,
            size,
            actionType: { type: 'FIND_WARRANT_SEARCH_ELASTIC_WARRANTS_MORE_RESULTS' },
        })
    );
};

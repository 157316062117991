import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

const BASE = 'users/profiles';
const ADMIN_BASE = 'admin/user';

export default createResource({
    name: 'User Profile Admin Resource',
    methods: {
        // user profile resources
        getProfile(userId) {
            return req({
                method: 'GET',
                url: `${BASE}/${userId}`,
            });
        },
        getCurrentFullProfile() {
            return req({
                method: 'GET',
                url: `${BASE}/current/full`,
            });
        },
        /**
         * @deprecated -- use {@link /client-common/core/domain/user-profiles/resources/userProfileResource#getFullUserProfile} instead.
         */
        getFullProfile(userId) {
            return req({
                method: 'GET',
                url: `${BASE}/${userId}/full`,
            });
        },
        updateProfile(userProfile) {
            return req({
                method: 'PUT',
                url: `${BASE}/${userProfile.userId}`,
                data: userProfile,
            });
        },
        getUserAttributes(userId) {
            return req({
                method: 'GET',
                url: `${BASE}/${userId}/attributes`,
            });
        },
        updateAttributes(userId, attributes) {
            return req({
                method: 'PUT',
                url: `${BASE}/${userId}/attributes`,
                data: attributes,
            });
        },
        getUserDutyStatuses(userId) {
            return req({
                method: 'GET',
                url: `${BASE}/${userId}/duty_statuses`,
            });
        },
        updateUserDutyStatuses(userId, dutyStatuses) {
            return req({
                method: 'PUT',
                url: `${BASE}/${userId}/duty_statuses`,
                data: dutyStatuses,
            });
        },
        getUserPhones(userId) {
            return req({
                method: 'GET',
                url: `${BASE}/${userId}/phones`,
            });
        },
        updateUserPhones(userId, phones) {
            return req({
                method: 'POST',
                url: `${BASE}/phones?userId=${userId}`,
                data: phones,
            });
        },
        // admin-only resources
        updateUserAccount(user) {
            return req({
                method: 'PUT',
                url: `${ADMIN_BASE}/account`,
                data: {
                    userId: user.id,
                    userToUpdate: user,
                    primaryEmail: user.primaryEmail,
                    isSsoUser: user.isSsoUser,
                },
            });
        },
        createUser(createUserRequest) {
            const {
                user,
                userProfile,
                dutyStatus,
                primaryEmail,
                isSsoUser,
                isEmailConfirmed,
                userProfileCjisExtension,
            } = createUserRequest;
            return req({
                method: 'POST',
                url: 'admin/user',
                data: {
                    dutyStatus,
                    primaryEmail,
                    isSsoUser,
                    isEmailConfirmed,
                    user,
                    userProfile,
                    userProfileCjisExtension,
                },
            });
        },
        resendUserEmailConfirmation(userIds) {
            return req({
                method: 'POST',
                url: `${ADMIN_BASE}/confirm_emails`,
                data: userIds,
            });
        },
        disableUser(userId) {
            return req({
                method: 'DELETE',
                url: `${ADMIN_BASE}/${userId}`,
            });
        },
        disableUsers(userIds) {
            return req({
                method: 'PUT',
                url: `${ADMIN_BASE}/delete`,
                data: userIds,
            });
        },
        enableUser(userId) {
            return req({
                method: 'PUT',
                url: `${ADMIN_BASE}/${userId}`,
            });
        },

        unlockUserAccount(userId) {
            return req({
                method: 'PUT',
                url: `${ADMIN_BASE}/${userId}/unlock`,
            });
        },

        toggleApplicationSetting(settingName, isSettingEnabled) {
            return req({
                method: 'POST',
                url: `${BASE}/enable_application_setting`,
                data: { settingName, isSettingEnabled },
            });
        },

        getInactiveUsers(from = 0, size = 25) {
            return req({
                method: 'GET',
                url: `${BASE}/inactive?from=${from}&size=${size}`,
            });
        },
    },
});

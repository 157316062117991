import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';

import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { reasonForRemovalForm } from '../state/form/reasonForRemovalForm';
import Row from '../../../core/components/Row';
import IndentedFields from '../../../core/components/IndentedFields';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import { N_ITEM_FIELD_FULL_WIDTH } from '../../../core/names/config/formWidths';

const ReasonForRemovalForm = () => {
    return (
        <ArbiterForm
            {...reasonForRemovalForm}
            render={() => (
                <>
                    <Row>
                        <ArbiterMFTAttributeSelect
                            path="reasonForRemovalAttrId"
                            attributeType={AttributeTypeEnum.REASON_FOR_REMOVAL.name}
                            width={N_ITEM_FIELD_FULL_WIDTH}
                        />
                    </Row>
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTText
                                path="reasonForRemovalOther"
                                width={N_ITEM_FIELD_FULL_WIDTH}
                            />
                        </Row>
                    </IndentedFields>
                </>
            )}
        />
    );
};

export default ReasonForRemovalForm;

import invariant from 'invariant';
import { sortBy } from 'lodash';
import { ASSOCIATED_RECORDS_CARD_ID } from '../../modules/reports/core/configuration';
import type {
    ReportSidebarEntry,
    ReportDefinitionOrderedCard,
} from '../../modules/reports/core/types';

const getOrderedCardId = (orderedCardLink: ReportDefinitionOrderedCard) => {
    const { cardId, externalCardId } = orderedCardLink;
    const id = cardId || externalCardId;
    invariant(id, 'An ordered card link must have either cardId or externalCardId specified.');
    return id;
};

export const orderSidebarAnchorLinks = (
    reportCardAnchors: ReportSidebarEntry[],
    orderedCardLinks: ReportDefinitionOrderedCard[]
) => {
    const associatedRecordsAnchor = reportCardAnchors.find(
        (card) => card.cardId === ASSOCIATED_RECORDS_CARD_ID
    );

    const reportCardAnchorsMap = new Map(
        reportCardAnchors.map((anchor) => [
            anchor.cardId,
            reportCardAnchors.filter((item) => item.cardId === anchor.cardId),
        ])
    );

    const orderedCardAnchors = sortBy(orderedCardLinks, 'ordinal').reduce<ReportSidebarEntry[]>(
        (sidebarAnchors, orderedCardLink) => {
            const cardId = getOrderedCardId(orderedCardLink);
            const anchors = reportCardAnchorsMap.get(cardId);
            const totalNumberOfAnchorsForCard = anchors?.length;

            if (totalNumberOfAnchorsForCard && totalNumberOfAnchorsForCard > 0) {
                sidebarAnchors = [...sidebarAnchors, ...anchors];
            }

            return sidebarAnchors;
        },
        []
    );
    if (associatedRecordsAnchor) {
        orderedCardAnchors.push(associatedRecordsAnchor);
    }

    return orderedCardAnchors;
};

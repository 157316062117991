import React from 'react';

import _, { filter, map } from 'lodash';
import styled from 'styled-components';
import { SimpleGrid, cssVar } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import Text from '../forms/components/Text';
import Icon, { iconTypes } from './Icon';

const ClearFilterButton = styled(Button)`
    color: ${(props) => props.theme.colors.lightGrey};
    fill: ${(props) => props.theme.colors.lightGrey};
    margin-top: 6px;

    &:hover {
        border: 0;
        color: ${(props) => props.theme.colors.darkGrey};
        fill: ${(props) => props.theme.colors.darkGrey};
    }
`;

const FilterableListSearchWrapper = styled.div`
    margin-bottom: 12px;
`;

class FilterableList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
        };
        this.filterInput = React.createRef();
        this._handleFilterChange = this._handleFilterChange.bind(this);
        this._handleClearFilter = this._handleClearFilter.bind(this);
    }

    componentDidMount() {
        if (this.filterInput && this.filterInput.current) {
            this.filterInput.current.focus();
        }
    }

    _displayOptions(query) {
        return filter(this.props.options, (option) => {
            query = query && query.toLowerCase();

            if (!query) {
                return true;
            }

            return _.includes(option.display.toLowerCase(), query);
        });
    }

    _handleClearFilter() {
        this.setState({
            query: '',
        });
        if (this.filterInput.current) {
            this.filterInput.current.focus();
        }
    }

    _handleFilterChange(query) {
        this.setState({
            query,
        });
    }

    render() {
        const query = this.state.query;

        return (
            <div className="filterable-list-wrapper" data-test-id={this.props.testId}>
                <FilterableListSearchWrapper className="clearfix">
                    <Text
                        leftIcon="Search"
                        setRef={(input) => {
                            this.filterInput.current = input;
                        }} // eslint-disable-line
                        value={query}
                        placeholder={this.props.placeholderText}
                        onChange={this._handleFilterChange}
                        textInputOnly={true}
                    />
                    {query.length > 0 && (
                        <ClearFilterButton
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.CLOSE_X} size={12} />}
                            onClick={this._handleClearFilter}
                        >
                            {componentStrings.filterableList.clearButtonText}
                        </ClearFilterButton>
                    )}
                </FilterableListSearchWrapper>
                <SimpleGrid
                    height="100%"
                    overflowY="auto"
                    columns={{ base: '1', sm: '2' }}
                    gap={cssVar('arc.space.2')}
                    // This keeps the box shadow from being cut off on the focus state of the tiles.
                    padding="3px"
                >
                    {map(this._displayOptions(query), this.props.optionsMapper)}
                </SimpleGrid>
            </div>
        );
    }
}

export default FilterableList;

import React, { PropsWithChildren } from 'react';
import { EmDash } from './HtmlEntities';

export const RestrictVisibilityWithDash = ({
    restricted,
    children,
}: PropsWithChildren<{ restricted?: boolean }>) => {
    if (restricted) {
        return <EmDash />;
    }

    return <>{children || <EmDash />}</>;
};

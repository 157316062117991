import { OperationTypeEnum } from '@mark43/rms-api';
import React, { useRef } from 'react';

import styled from 'styled-components';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import Icon, { iconTypes } from '../../../core/components/Icon';
import DropdownMenu from '../../../core/components/DropdownMenu';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import OverlayButton from '../../../core/overlays/components/OverlayButton';
import testIds from '../../../../core/testIds';
import CasePermissionsModal from './CasePermissionsModal';
import ApplyCasePermissionsToReportsModal from './ApplyCasePermissionsToReportsModal';

const strings = componentStrings.cases.core.CaseHeaderPermissionsButton;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
`;

const Container = styled.span`
    display: inline-flex;
`;

function DropdownMenuOptionForOverlay({ children, closeMenu, overlayId }) {
    return (
        <OverlayButton id={overlayId}>
            {(openOverlay) => (
                <div
                    children={children}
                    className="dropdown-menu-option"
                    onClick={openOverlay}
                    onKeyDown={(e) => {
                        if (e.keyCode === keyCodeEnum.ENTER) {
                            openOverlay();
                            closeMenu();
                        }
                    }}
                    data-test-id={testIds.CASE_PERMISSIONS_OPTION}
                />
            )}
        </OverlayButton>
    );
}

function CaseHeaderPermissionsButton({ caseId, permissionSet }) {
    const closeFocusRef = useRef();
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    const renderDropdownMenuOptions = (closeMenu) => (
        <>
            <DropdownMenuOptionForOverlay
                closeMenu={closeMenu}
                overlayId={overlayIdEnum.CASE_PERMISSIONS_OVERLAY}
            >
                {strings.managePermissions}
            </DropdownMenuOptionForOverlay>
            <OnlyWithEntityPermission
                permissionSet={permissionSet}
                has={OperationTypeEnum.MANAGE.name}
            >
                <OnlyWithAbility has={abilitiesEnum.CASES.EDIT_GENERAL}>
                    <DropdownMenuOptionForOverlay
                        closeMenu={closeMenu}
                        overlayId={overlayIdEnum.APPLY_CASE_PERMISSIONS_TO_REPORTS_OVERLAY}
                    >
                        {strings.applyCasePermissionsToAllReports(caseDisplayName)}
                    </DropdownMenuOptionForOverlay>
                </OnlyWithAbility>
            </OnlyWithEntityPermission>
        </>
    );

    return (
        <Container>
            <DropdownMenu
                buttonContent={
                    <DropdownMenuButtonContainer>
                        <Icon size={16} type={iconTypes.PERMISSIONS} />
                        <Icon size={16} type={iconTypes.TRIANGLE_DOWN} />
                    </DropdownMenuButtonContainer>
                }
                ref={closeFocusRef}
                width={285}
                tooltip={strings.managePermissions}
                testId={testIds.CASE_PERMISSIONS_BUTTON}
            >
                {renderDropdownMenuOptions}
            </DropdownMenu>
            <CasePermissionsModal caseId={caseId} closeFocusRef={closeFocusRef} />
            <ApplyCasePermissionsToReportsModal caseId={caseId} closeFocusRef={closeFocusRef} />
        </Container>
    );
}

export default CaseHeaderPermissionsButton;

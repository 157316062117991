import { EventDetail, EventDetailBundle } from '@mark43/rms-api';
import { forEach, map, size } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { replaceParamedicsWhere } from '../../../paramedics/state/data';
import getResource from '../../resources/eventDetailsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const eventDetailsModule = createNormalizedModule<EventDetail>({
    type: 'eventDetails',
    key: 'reportId',
});

const UPDATE_EVENT_DETAIL_START = 'event-details/UPDATE_EVENT_DETAIL_START';
const UPDATE_EVENT_DETAIL_SUCCESS = 'event-details/UPDATE_EVENT_DETAIL_SUCCESS';
const UPDATE_EVENT_DETAIL_FAILURE = 'event-details/UPDATE_EVENT_DETAIL_FAILURE';

function updateEventDetailStart(eventDetail: EventDetail) {
    return {
        type: UPDATE_EVENT_DETAIL_START,
        payload: eventDetail,
    };
}

function updateEventDetailSuccess(eventDetail: EventDetail) {
    return {
        type: UPDATE_EVENT_DETAIL_SUCCESS,
        payload: eventDetail,
    };
}

function updateEventDetailFailure(message: string) {
    return {
        type: UPDATE_EVENT_DETAIL_FAILURE,
        payload: message,
    };
}

export function updateEventDetail(
    eventDetailToSave: EventDetail
): ClientCommonAction<Promise<EventDetail>> {
    return (dispatch) => {
        const resource = getResource();
        dispatch(updateEventDetailStart(eventDetailToSave));
        return resource
            .updateEventDetail(eventDetailToSave)
            .then((eventDetail: EventDetail) => {
                dispatch(storeEventDetails(eventDetail));
                dispatch(updateEventDetailSuccess(eventDetail));
                return eventDetail;
            })
            .catch((err: Error) => {
                dispatch(updateEventDetailFailure(err.message));
                throw err;
            });
    };
}

export function updateMultipleReportEventDetails(
    eventDetailBundle: EventDetailBundle,
    reportId: number
): ClientCommonAction<Promise<EventDetailBundle>> {
    return (dispatch) => {
        if (eventDetailBundle && eventDetailBundle.eventDetail) {
            const resource = getResource();
            dispatch(updateEventDetailStart(eventDetailBundle.eventDetail));
            return resource
                .updateEventDetailsFull(eventDetailBundle, reportId)
                .then((updatedEventDetailBundle: EventDetailBundle) => {
                    const savedEventDetailBundles = [
                        updatedEventDetailBundle,
                        ...updatedEventDetailBundle.alignedEventDetailBundles,
                    ];
                    return savedEventDetailBundles;
                })
                .catch((err: Error) => {
                    dispatch(updateEventDetailFailure(err.message));
                    throw err;
                });
        }
    };
}

function storeParamedicsForEventDetail(eventDetail: EventDetail): ClientCommonAction<void> {
    return (dispatch) => {
        if (eventDetail) {
            const paramedics = eventDetail.paramedics;

            if (size(paramedics) > 0) {
                const paramedicObjects = map(paramedics, (paramedic) => ({
                    ...paramedic,
                    reportId: eventDetail.reportId,
                }));
                dispatch(
                    replaceParamedicsWhere({ reportId: eventDetail.reportId }, paramedicObjects)
                );
            } else {
                dispatch(replaceParamedicsWhere({ reportId: eventDetail.reportId }, []));
            }
        }
    };
}

// ACTIONS
// Though paramedics are not "real entities" we do store them in their own place in state.
// Paramedics are embedded on the event details model, and as such we need to ensure that any
// time any event detail is stored or updated that we update / store / delete related paramedics
// as necessary.
export const storeEventDetails = (
    eventDetails: EventDetail | EventDetail[]
): ClientCommonAction<void> => {
    return (dispatch) => {
        dispatch(eventDetailsModule.actionCreators.storeEntities(eventDetails));
        if (Array.isArray(eventDetails)) {
            forEach(eventDetails, (eventDetail) => {
                dispatch(storeParamedicsForEventDetail(eventDetail));
            });
        } else {
            dispatch(storeParamedicsForEventDetail(eventDetails));
        }
    };
};

// SELECTORS
export const eventDetailsSelector = eventDetailsModule.selectors.entitiesSelector;

export const eventDetailByReportIdSelector = eventDetailsModule.selectors.entityByIdSelector;

// REDUCER
export default eventDetailsModule.reducerConfig;

import React from 'react';
import styled from 'styled-components';

import GenericContainer from './GenericContainer';

const FieldDisplayContainer = styled.div`
    display: inline-block;
    vertical-align: top;
    width: ${(props) => props.width || '50%'};
`;

const FieldDisplay = ({ fieldDisplayNames, field, children, theme, width }) => (
    <FieldDisplayContainer width={width}>
        {field && (
            <GenericContainer color={theme.colors.darkGrey}>
                {`${fieldDisplayNames[field]}: `}
            </GenericContainer>
        )}
        <GenericContainer color={theme.colors.mediumLightGrey}>{children}</GenericContainer>
    </FieldDisplayContainer>
);

export default FieldDisplay;

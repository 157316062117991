import _ from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

import {
    checkPhoneLength,
    checkPhoneValidCharacters,
} from '../../../../../legacy-redux/validation/rules/phoneValidationHelpers';

const cadServiceProvidersAdminFormFieldViewModels = {
    cadServiceProvider: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadServiceProvider',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'name',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'capabilityIds',
            },
            {
                key: 'description',
            },
            {
                key: 'rotationServiceTypeId',
            },
            {
                key: 'phoneNumber',
                validators: {
                    requiredError: requiredString,
                    phoneInvalidCharactersError: checkPhoneValidCharacters,
                    phoneLengthError: checkPhoneLength,
                },
            },
            {
                key: 'locationId',
            },
            {
                key: 'rotationServiceTypesIds',
            },
            {
                key: 'isTowRotationService',
            },
        ]),
    },
};

function convertToFormModel({ cadServiceProvider = {} }) {
    cadServiceProvider.capabilityIds = _.map(cadServiceProvider.capabilities, (x) => {
        return x.id;
    });
    cadServiceProvider.rotationServiceTypesIds =
        (cadServiceProvider.rotationServiceTypes &&
            cadServiceProvider.rotationServiceTypes.map((serviceType) => serviceType.id)) ||
        [];

    return {
        cadServiceProvider: {
            ...cadServiceProvider,
        },
    };
}

function convertFromFormModel(formModel = {}, isEnhancedServiceRotationEnabled, serviceTypes) {
    if (!isEnhancedServiceRotationEnabled) {
        return formModel;
    }

    const {
        rotationServiceTypesIds = [],
        rotationServiceTypeId,
        towCompanyAttrId,
        isTowRotationService,
        ...remainingServiceProviderParams
    } = formModel.cadServiceProvider;

    const rotationServiceTypes = rotationServiceTypesIds
        .map((id) => serviceTypes.find((serviceType) => serviceType.id === id) ?? null)
        .filter((serviceType) => !!serviceType);

    return {
        cadServiceProvider: {
            ...remainingServiceProviderParams,
            ...(isTowRotationService && towCompanyAttrId ? { towCompanyAttrId } : {}),
            isTowRotationService,
            rotationServiceTypeId: rotationServiceTypeId || rotationServiceTypesIds[0],
            rotationServiceTypes,
        },
    };
}

/**
 * Module of the cad Service Provider admin form.
 * @type {Object}
 */
const formModule = createFormModule({
    formName: formClientEnum.CAD_SERVICE_PROVIDERS_ADMIN,
    fieldViewModels: cadServiceProvidersAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

function validateEnhancedServiceRotationRequiredFields(fieldsNames) {
    return (dispatch, getState) => {
        let areFieldsValid = true;
        const formModel = formModule.selectors.formModelSelector(getState());
        const setValidity = formModule.actionCreators.setValidity;

        fieldsNames.forEach((fieldName) => {
            const fieldValue = formModel.cadServiceProvider[fieldName];
            const isFieldValid = Array.isArray(fieldValue) ? fieldValue.length > 0 : !!fieldValue;

            dispatch(
                setValidity(`cadServiceProvider.${fieldName}`, {
                    requiredError: isFieldValid,
                })
            );

            if (!isFieldValid) {
                areFieldsValid = false;
            }
        });

        return areFieldsValid;
    };
}

export function validateForm(isEnhancedServiceRotationEnabled) {
    return (dispatch, getState) => {
        if (!isEnhancedServiceRotationEnabled) {
            return formModule.selectors.formIsValidSelector(getState());
        }

        return dispatch(validateEnhancedServiceRotationRequiredFields(['rotationServiceTypesIds']));
    };
}

export default formModule;

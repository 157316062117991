import { first } from 'lodash';
import { createSelector } from 'reselect';
import { BehavioralCrisis } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'behavioralCrises';

const behavioralCrisesModule = createNormalizedModule<BehavioralCrisis>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS

const behavioralCrisesWhereSelector = behavioralCrisesModule.selectors.entitiesWhereSelector;
export const behavioralCrisisByReportIdSelector = createSelector(
    behavioralCrisesWhereSelector,
    (behavioralCrisesWhere) => (reportId: number) => first(behavioralCrisesWhere({ reportId }))
);

// REDUCER
export default behavioralCrisesModule.reducerConfig;

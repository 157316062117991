import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../core/components/Icon';
import { cancelConfirmation, submitItemSplitForm } from '../state/ui';
import ItemSplitConfirmationSummaries from './ItemSplitConfirmationSummaries';

const strings = componentStrings.evidence.itemSplit.ItemSplitConfirmationSidePanel;
const context = { name: boxEnum.ITEM_SPLIT_CONFIRMATION_SIDE_PANEL };

const SidePanelContainer = styled.div`
    flex: 100%;
    width: 100%;
    overflow: auto;
    padding-bottom: 30px;

    &,
    * {
        box-sizing: border-box;
    }
`;
const BackButton = styled(Button)`
    margin: 10px 0 0 24px;
    font-size: var(--arc-fontSizes-lg);

    & .textvalignmiddle {
        vertical-align: baseline;
    }
`;

function ItemSplitConfirmationSidePanel({ cancelConfirmation, submitItemSplitForm }) {
    return (
        <SidePanel
            context={context}
            title={strings.title}
            saveText={strings.labels.confirm}
            useDefaultStyle={false}
            onCancel={cancelConfirmation}
            onSave={submitItemSplitForm}
        >
            <SidePanelContainer>
                <BackButton
                    iconLeft={iconTypes.OPEN_LEFT}
                    onClick={cancelConfirmation}
                    className={buttonTypes.ICON_LINK}
                >
                    {strings.labels.back}
                </BackButton>
                <ItemSplitConfirmationSummaries />
            </SidePanelContainer>
        </SidePanel>
    );
}

/**
 * Before submitting Item Split form, display summary of changes in Side Panel
 */
export default compose(connect(null, { cancelConfirmation, submitItemSplitForm }))(
    ItemSplitConfirmationSidePanel
);

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import PropertyStatusSummary from '../../../reports/core/components/property-status/PropertyStatusSummary';
import { PropertyStatusLabel } from '../../../reports/custodial-property-summary/components/PropertyStatusContent';
import ItemInfo from '../../core/components/ItemInfo';
import { itemSplitSummaryViewModelsSelector } from '../state/ui';
import testIds from '../../../../core/testIds';

const strings = componentStrings.evidence.itemSplit.ItemSplitConfirmationSummaries;

const ItemSplitSummaryContainer = styled.div`
    margin: 30px 0 0 28px;
`;
const Indent = styled.div`
    margin: 5px 0 0 25px;
`;
const ItemHeader = styled.div`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    border-bottom: ${(props) => props.theme.colors.lightGrey} solid 1px;
    margin: 0 20px 20px 0;
    padding: 5px 0 5px 10px;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    text-transform: uppercase;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

function ItemSplitSummary({ header, summaryViewModel }) {
    const { itemProfile, propertyStatus } = summaryViewModel;
    const propertyStatusViewModelProps = getViewModelProperties(propertyStatus);

    return (
        <ItemSplitSummaryContainer data-test-id={testIds.ITEM_SPLIT_SUMMARY_CONTAINER}>
            <ItemHeader>{header}</ItemHeader>
            <ItemInfo
                viewModel={itemProfile}
                width={410}
                disableLink={true}
                hideRenSequence={true}
                hideItemType={true}
                hideSerialNumber={true}
                hideItemIdentifiers={true}
            />
            <Indent>
                <PropertyStatusLabel>
                    {propertyStatusViewModelProps.propertyStatusAttrId}
                </PropertyStatusLabel>
                <PropertyStatusSummary
                    statusDateUtc={propertyStatus.statusDateUtc}
                    quantity={propertyStatusViewModelProps.quantity}
                    reasonForPoliceCustodyAttrId={
                        propertyStatusViewModelProps.reasonForPoliceCustodyAttrId
                    }
                    facilityName={propertyStatus.storageFacility}
                    declaredValue={propertyStatusViewModelProps.declaredValue}
                    measurementUnitsAttrId={propertyStatusViewModelProps.measurementUnitsAttrId}
                    labelWidth={170}
                    contentWidth={200}
                />
            </Indent>
        </ItemSplitSummaryContainer>
    );
}

function ItemSplitConfirmationSummaries({ itemSplitSummaryViewModels }) {
    return (
        <div>
            <ItemSplitSummary
                header={strings.labels.newItem}
                summaryViewModel={itemSplitSummaryViewModels.newItem}
            />
            <ItemSplitSummary
                header={strings.labels.existingItem}
                summaryViewModel={itemSplitSummaryViewModels.existingItem}
            />
        </div>
    );
}

/**
 * Redux connected container for Item Split Confirmation Side Panel
 *   Separate because connect directly to ItemSplitSidePanel causes too many re-renders
 */
export default connect(
    createStructuredSelector({
        itemSplitSummaryViewModels: itemSplitSummaryViewModelsSelector,
    })
)(ItemSplitConfirmationSummaries);

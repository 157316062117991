import React from 'react';
import { connect } from 'react-redux';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import Modal from '../core/Modal';
import { closeBox } from '../../actions/boxActions';

const context = {
    name: boxEnum.APP_LOAD_FAILURE_MODAL,
};

function AppLoadFailureModal({ dispatch }) {
    return (
        <Modal
            context={context}
            okText={null}
            cancelText="Ok"
            title="Error"
            errorHeader={true}
            overlayStyle={{ top: 0 }}
            onClose={() => dispatch(closeBox(context))}
        >
            Failed to load application, please check your connection and try again
        </Modal>
    );
}

export default connect()(AppLoadFailureModal);

import { CadChannel, CadChannelHistory } from '@mark43/cad-api';
import * as actionTypes from './actionTypes';
import { CadChannelsAdminActionsT } from './actions';

type CadChannelsAdminStateT = {
    cadChannels: CadChannel[];
    loadingList: boolean;
    submittingForm: boolean;
    listError?: string;
    formError?: string;
    cadChannelHistory: CadChannelHistory[];
    isLoadingHistory: boolean;
    loadingHistoryError?: string;
};

const initialState: CadChannelsAdminStateT = {
    cadChannels: [],
    loadingList: false,
    submittingForm: false,
    listError: undefined,
    formError: undefined,
    cadChannelHistory: [],
    isLoadingHistory: false,
    loadingHistoryError: undefined,
};

export const cadChannelsAdminReducer = (state = initialState, action: CadChannelsAdminActionsT) => {
    switch (action.type) {
        case actionTypes.LOAD_CAD_CHANNELS_START:
            return {
                ...state,
                loadingList: true,
                listError: undefined,
            };
        case actionTypes.LOAD_CAD_CHANNELS_SUCCESS:
            return {
                ...state,
                loadingList: false,
                cadChannels: action.payload,
            };
        case actionTypes.LOAD_CAD_CHANNELS_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case actionTypes.SAVE_CAD_CHANNEL_START:
            return {
                ...state,
                submittingForm: true,
                formError: undefined,
            };
        case actionTypes.SAVE_CAD_CHANNEL_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case actionTypes.SAVE_CAD_CHANNEL_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case actionTypes.LOAD_CAD_CHANNEL_HISTORY_START:
            return {
                ...state,
                isLoadingHistory: true,
                loadingHistoryError: undefined,
            };
        case actionTypes.LOAD_CAD_CHANNEL_HISTORY_SUCCESS:
            return {
                ...state,
                isLoadingHistory: false,
                loadingHistoryError: undefined,
                cadChannelHistory: action.payload,
            };
        case actionTypes.LOAD_CAD_CHANNEL_HISTORY_FAILURE:
            return {
                ...state,
                isLoadingHistory: false,
                loadingHistoryError: undefined,
                cadChannelHistory: [],
            };
        default:
            return state;
    }
};

import { AlertScheduleView } from '@mark43/notifications-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import buildAlertSchedulesResource from '../../resources/alertSchedulesResource';
import { ClientCommonAction } from '../../../../../redux/types';

const alertSchedulesModule = createNormalizedModule<AlertScheduleView>({
    type: 'alertSchedules',
    key: 'alertScheduleId',
});

const LOAD_ALERT_SCHEDULES_START = 'alert-schedules/LOAD_ALERT_SCHEDULES_START';
const LOAD_ALERT_SCHEDULES_SUCCESS = 'alert-schedules/LOAD_ALERT_SCHEDULES_SUCCESS';
const LOAD_ALERT_SCHEDULES_FAILURE = 'alert-schedules/LOAD_ALERT_SCHEDULES_FAILURE';
const UPSERT_ALERT_SCHEDULE_START = 'alert-schedules/UPSERT_ALERT_SCHEDULE_START';
const UPSERT_ALERT_SCHEDULE_SUCCESS = 'alert-schedules/UPSERT_ALERT_SCHEDULE_SUCCESS';
const UPSERT_ALERT_SCHEDULE_FAILURE = 'alert-schedules/UPSERT_ALERT_SCHEDULE_FAILURE';
const DELETE_ALERT_SCHEDULE_START = 'alert-schedules/DELETE_ALERT_SCHEDULE_START';
const DELETE_ALERT_SCHEDULE_SUCCESS = 'alert-schedules/DELETE_ALERT_SCHEDULE_SUCCESS';
const DELETE_ALERT_SCHEDULE_FAILURE = 'alert-schedules/DELETE_ALERT_SCHEDULE_FAILURE';

const storeAlertSchedules = alertSchedulesModule.actionCreators.storeEntities;
const removeAlertSchedule = alertSchedulesModule.actionCreators.deleteEntity;

function loadAlertSchedulesStart() {
    return { type: LOAD_ALERT_SCHEDULES_START };
}
function loadAlertSchedulesSuccess() {
    return { type: LOAD_ALERT_SCHEDULES_SUCCESS };
}
function loadAlertSchedulesFailure(errorMessage: string) {
    return { type: LOAD_ALERT_SCHEDULES_FAILURE, payload: errorMessage, error: true };
}
function upsertAlertScheduleStart() {
    return { type: UPSERT_ALERT_SCHEDULE_START };
}
function upsertAlertScheduleSuccess() {
    return { type: UPSERT_ALERT_SCHEDULE_SUCCESS };
}
function upsertAlertScheduleFailure(errorMessage: string) {
    return { type: UPSERT_ALERT_SCHEDULE_FAILURE, payload: errorMessage, error: true };
}
function deleteAlertScheduleStart() {
    return { type: DELETE_ALERT_SCHEDULE_START };
}
function deleteAlertScheduleSuccess() {
    return { type: DELETE_ALERT_SCHEDULE_SUCCESS };
}
function deleteAlertScheduleFailure(errorMessage: string) {
    return { type: DELETE_ALERT_SCHEDULE_FAILURE, payload: errorMessage, error: true };
}

export function loadAlertSchedules(): ClientCommonAction<Promise<AlertScheduleView[]>> {
    return function (dispatch) {
        dispatch(loadAlertSchedulesStart());
        const resource = buildAlertSchedulesResource();

        return resource
            .getAlertSchedules()
            .then((alertSchedules: AlertScheduleView[]) => {
                dispatch(storeAlertSchedules(alertSchedules));
                dispatch(loadAlertSchedulesSuccess());
                return alertSchedules;
            })
            .catch((error: Error) => {
                dispatch(loadAlertSchedulesFailure(error.message));
                throw error;
            });
    };
}

export function upsertAlertSchedule(
    alertSchedule: AlertScheduleView
): ClientCommonAction<Promise<AlertScheduleView>> {
    return (dispatch) => {
        dispatch(upsertAlertScheduleStart());
        const { updateAlertSchedule, createAlertSchedule } = buildAlertSchedulesResource();
        const upsertMethod = alertSchedule.alertScheduleId
            ? updateAlertSchedule
            : createAlertSchedule;

        return upsertMethod(alertSchedule)
            .then((alertSchedule: AlertScheduleView) => {
                dispatch(storeAlertSchedules(alertSchedule));
                dispatch(upsertAlertScheduleSuccess());
                return alertSchedule;
            })
            .catch((error: Error) => {
                dispatch(upsertAlertScheduleFailure(error.message));
                throw error;
            });
    };
}

export function deleteAlertSchedule(alertScheduleId: number): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        dispatch(deleteAlertScheduleStart());
        const resource = buildAlertSchedulesResource();

        return resource
            .deleteAlertSchedule(alertScheduleId)
            .then(() => {
                dispatch(removeAlertSchedule(alertScheduleId));
                dispatch(deleteAlertScheduleSuccess());
            })
            .catch((error: Error) => {
                dispatch(deleteAlertScheduleFailure(error.message));
                throw error;
            });
    };
}

export const alertSchedulesSelector = alertSchedulesModule.selectors.entitiesSelector;
export const alertScheduleByIdSelector = alertSchedulesModule.selectors.entityByIdSelector;

export default alertSchedulesModule.reducerConfig;

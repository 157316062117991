import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { NavAppContent, Flex } from 'arc';
import styled from 'styled-components';
import DocumentTitle from 'react-document-title';
import applicationConfigurationQueryParamsEnum from '~/client-common/core/enums/client/applicationConfigurationQueryParamsEnum';
import { beginPollingForNotifications } from '~/client-common/core/domain/user-notifications/state/persistent';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { userIsLoggedInSelector } from '../../../modules/core/current-user/state/ui';
import errors from '../../../lib/errors';
import { showInactivityModalBasedOnExpirationSeconds, logout } from '../../actions/authActions';
import RmsGlobalHotKeys from '../../../modules/core/hotkeys/components/RmsGlobalHotKeys';
import testIds from '../../../core/testIds';

import ErrorManager from '../../../modules/core/components/ErrorManager';
import MyExports from '../exports/MyExports';
import ConfirmationBar from '../../../modules/core/confirmation-bar/components/ConfirmationBar';
import { Banner } from '../alerts/Banner';
import { NO_ROUTE_NAME, routeNameSelector } from '../../../routing/routerModule';
import {
    currentRouteHasLegacySubheaderSelector,
    loadingBarVisibleSelector,
    additionalContentTopSelector,
    globalErrorSelector,
} from '../../selectors/globalSelectors';
import { hasBannerSelector } from '../../selectors/alertsSelectors';
import ErrorBoundary from '../../../modules/core/errors/components/ErrorBoundary';
import CreateReportModalV2 from '../../../modules/reports/core/components/CreateReportModalV2';
import CreateReportModal from '../../../modules/reports/core/components/CreateReportModal';
import { selectRouteTitleByKey } from '../../../routing/routesConfig';
import LeftNavigation from '../../../modules/core/components/Navigation/LeftNavigation';
import zIndexes from '../../../modules/core/styles/zIndexes';
import AppContent from './AppContent';
import Modals from './Modals';
import SidePanels from './SidePanels';
import Lightboxes from './Lightboxes';
import Navigation from './Navigation';
import LegacyLoadingBar, { LoadingBar } from './LegacyLoadingBar';
import LegacySubheader from './LegacySubheader';

const { CHROMELESS } = applicationConfigurationQueryParamsEnum;

const StyledNavAppContent = styled(NavAppContent)`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    margin-right: ${(props) => props.$hasHeader === false && '0'};
`;

// This can be removed when the RMS_ARC_NAVIGATION_ENABLED feature flag is torn down.
const StyledAppWrapper = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
`;

class App extends React.Component {
    render() {
        const {
            children,
            location,
            loadingBarVisible,
            currentRouteHasLegacySubheader,
            pageTitle,
            hasBanner,
            additionalContentTop,
            globalError,
        } = this.props;
        const alert = hasBanner ? <Banner /> : null;
        const isChromeless = !!location.query[CHROMELESS];
        const hasHeader = !isChromeless;

        function renderAppContent() {
            return (
                <ErrorManager error={globalError}>
                    <ErrorBoundary>
                        <LegacySubheader hasBanner={hasBanner} hasHeader={hasHeader} />
                        <CreateReportModal />
                        <CreateReportModalV2 />
                        <AppContent
                            currentRouteHasLegacySubheader={currentRouteHasLegacySubheader}
                            hasBanner={hasBanner}
                            additionalContentTop={additionalContentTop}
                            hasHeader={hasHeader}
                        >
                            {/*
                            children is whatever component is selected by our router -
                            for now this includes the subheader but ultimately the
                            subheader should be rewritten in react and included
                            as a direct child of App
                            */}
                            {children}
                        </AppContent>
                        <MyExports />
                        <SidePanels />
                        <Modals />
                        <Lightboxes />
                        <ConfirmationBar />
                        <RmsGlobalHotKeys />
                    </ErrorBoundary>
                </ErrorManager>
            );
        }

        return (
            <FeatureFlagged
                flag="RMS_ARC_NAVIGATION_ENABLED"
                fallback={
                    <DocumentTitle title={pageTitle}>
                        <StyledAppWrapper data-test-id={testIds.APP_WRAPPER}>
                            <Flex flexDirection="column">
                                {hasHeader && <Navigation location={location} />}
                                {alert}
                                <LegacyLoadingBar visible={loadingBarVisible} />
                            </Flex>
                            {renderAppContent()}
                        </StyledAppWrapper>
                    </DocumentTitle>
                }
            >
                <DocumentTitle title={pageTitle}>
                    <>
                        {hasHeader && <LeftNavigation location={location} />}
                        <StyledNavAppContent
                            id="NavAppContent"
                            $hasHeader={hasHeader}
                            data-test-id={testIds.APP_WRAPPER}
                        >
                            <Flex flexDirection="column">
                                {alert}
                                <LoadingBar
                                    visible={loadingBarVisible}
                                    style={{
                                        position: 'fixed',
                                        zIndex: `${zIndexes.loadingBar}`,
                                    }}
                                />
                            </Flex>
                            {renderAppContent()}
                        </StyledNavAppContent>
                    </>
                </DocumentTitle>
            </FeatureFlagged>
        );
    }

    componentDidMount() {
        const { beginPollingForNotifications } = this.props;
        beginPollingForNotifications({
            onSuccess: ({ dispatch, result }) => {
                showInactivityModalBasedOnExpirationSeconds(result.expirationSeconds, dispatch);
            },
            onUnauthorizedError: (dispatch) => dispatch(logout(true)),
        });
    }
}

const pageTitleSelector = createSelector([routeNameSelector, (state) => state], (
    routeName /* RouteConfigKey & typeof NO_ROUTE_NAME */,
    state
) => {
    return routeName === NO_ROUTE_NAME
        ? 'Mark43'
        : // currently name === key
          `${selectRouteTitleByKey(routeName)(state)} - Mark43`;
});

const mapStateToProps = createStructuredSelector({
    loadingBarVisible: loadingBarVisibleSelector,
    currentRouteHasLegacySubheader: currentRouteHasLegacySubheaderSelector,
    pageTitle: pageTitleSelector,
    hasBanner: hasBannerSelector,
    additionalContentTop: additionalContentTopSelector,
    globalError: globalErrorSelector,
});

const mapDispatchToProps = (dispatch) => ({
    beginPollingForNotifications: (options) =>
        dispatch(
            beginPollingForNotifications({
                ...options,
                userIsLoggedInSelector,
                UnauthorizedError: errors.UnauthorizedError,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

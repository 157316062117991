import * as React from 'react';
import { Switch as _Switch } from 'arc';
import { InlineBanner } from '@arc/banner';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';

const strings = componentStrings.search.AdvancedSearchReportsForm.dragon.optionsFetchError;
// The `Switch` component relies on `content-box` for its styling, which is a problem
// within advanced search, since we are forcing everything to be `box-sizing` styled.
// To get around this we are wrapping `Switch` and specifically setting the affected
// classes back to `content-box`. The longterm fix here should be
//  for `arc` to move away from `content-box`.
export const Switch = styled(_Switch)`
    & .chakra-switch__track {
        box-sizing: content-box;
    }
`;

export function FetchError({
    description,
    onRefetchClick,
    title,
}: {
    onRefetchClick: () => void;
    description?: string;
    title?: string;
}): JSX.Element {
    return (
        <InlineBanner
            actions={[
                {
                    children: strings.default.retry,
                    onClick: onRefetchClick,
                },
            ]}
            description={description ?? strings.default.description}
            status="error"
            title={title ?? strings.default.title}
        />
    );
}

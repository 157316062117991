import React from 'react';
import styled from 'styled-components';

import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';

import CardWithApprovalStatusIcon from './CardWithApprovalStatusIcon';

interface PendingCardPropsT {
    title?: string;
    className?: string;
}

function _PendingCard(props: PendingCardPropsT) {
    return (
        <CardWithApprovalStatusIcon
            approvalStatus={approvalStatusClientEnum.SUBMITTED}
            {...props}
            showHistory={false}
        />
    );
}

const PendingCard = styled(_PendingCard)`
    border-color: ${(props) => props.theme.colors.brightYellow};
    background-color: ${(props) => props.theme.colors.lightYellow};
`;

/**
 * Approval status card in the "pending" state. This is yellow with a yellow P
 *   icon at the top left.
 */
export default PendingCard;

import React from 'react';

import strings from '~/client-common/core/strings/componentStrings';

import { iconTypes, iconSizes } from '../../../core/components/Icon';
import Icon from './RecordPrivacyIcon';
import { PreviouslySealedNoteContainer } from './RecordPrivacyContainers';

const PreviouslySealedNote = () => (
    <PreviouslySealedNoteContainer>
        <Icon type={iconTypes.PERMISSIONS} color="darkGrey" size={iconSizes.SMALL} />
        {strings.recordPrivacy.shared.PreviouslySealedNote.narrativePreviouslySealedNote}
    </PreviouslySealedNoteContainer>
);

export default PreviouslySealedNote;

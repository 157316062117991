import { ProductModuleEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';

import AttachmentDetails from '../../core/components/EntityProfileAttachmentDetails';
import EntityProfileLinkDetails from '../../core/components/EntityProfileLinkDetails';
import LegacyEntityDetails from '../../core/components/LegacyEntityDetails';
import { EntityProfileActiveTarget } from '../../core/components/EntityProfileActiveTarget/EntityProfileActiveTarget';
import ChainOfCustody from '../../../evidence/chain-of-custody/components/ChainOfCustody';
import ChainOfCustodyTitle from '../../../evidence/chain-of-custody/components/ChainOfCustodyTitle';
import {
    vehicleDetailsSelector,
    currentEntityProfileIsMasterProfileSelector,
} from '../../core/state/ui';
import DispositionNoticeBar from '../../../evidence/disposition/components/DispositionNoticeBar';
import EntityProfileSection from '../../core/components/EntityProfileSection';

import AppearanceDetails from './EntityProfileVehicleAppearanceDetails';
import IdentificationDetails from './EntityProfileVehicleIdentificationDetails';

function EntityProfileVehicleDetails({ vehicleDetails, isMaster }) {
    return !vehicleDetails ? (
        <div />
    ) : (
        <div>
            <EntityProfileActiveTarget />
            <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
                <OnlyWithAbility has={abilitiesEnum.EVIDENCE.VIEW_GENERAL}>
                    <DispositionNoticeBar
                        masterItemId={vehicleDetails.vehicleViewModel.masterItemId}
                    />
                </OnlyWithAbility>
            </ProductModuled>
            <IdentificationDetails vehicleViewModel={vehicleDetails.vehicleViewModel} />
            <AppearanceDetails vehicleViewModel={vehicleDetails.vehicleViewModel} />
            {vehicleDetails.ownerDetails.length > 0 && (
                <EntityProfileLinkDetails
                    linkDetails={vehicleDetails.ownerDetails}
                    parentNameItemAssociation={globalAttributes.nameItemAssociationGlobal.owner}
                />
            )}
            {vehicleDetails.claimantDetails.length > 0 && (
                <EntityProfileLinkDetails
                    linkDetails={vehicleDetails.claimantDetails}
                    parentNameItemAssociation={globalAttributes.nameItemAssociationGlobal.claimant}
                />
            )}
            {vehicleDetails.collectorDetails.length > 0 && (
                <EntityProfileLinkDetails
                    linkDetails={vehicleDetails.collectorDetails}
                    parentNameItemAssociation={globalAttributes.nameItemAssociationGlobal.collector}
                />
            )}
            {vehicleDetails.genericAssociationDetails.length > 0 && (
                <EntityProfileLinkDetails
                    linkDetails={vehicleDetails.genericAssociationDetails}
                    parentNameItemAssociation={globalAttributes.nameItemAssociationGlobal.generic}
                />
            )}
            {vehicleDetails.legacyEntityDetails.length > 0 && (
                <LegacyEntityDetails legacyEntityDetails={vehicleDetails.legacyEntityDetails} />
            )}
            {isMaster && (
                <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
                    <OnlyWithAbility has={abilitiesEnum.EVIDENCE.VIEW_GENERAL}>
                        <EntityProfileSection
                            title={
                                <ChainOfCustodyTitle
                                    masterItemId={vehicleDetails.vehicleViewModel.masterItemId}
                                />
                            }
                        >
                            <ChainOfCustody
                                masterItemId={vehicleDetails.vehicleViewModel.masterItemId}
                            />
                        </EntityProfileSection>
                    </OnlyWithAbility>
                </ProductModuled>
            )}
            <AttachmentDetails
                entityId={vehicleDetails.vehicleViewModel.id}
                linkType={[
                    LinkTypesEnum.ITEM_PROFILE_PHOTO,
                    LinkTypesEnum.ITEM_PROFILE_ATTACHMENT,
                    LinkTypesEnum.VEHICLE_LICENSE_PLATE_PHOTO,
                ]}
            />
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    vehicleDetails: vehicleDetailsSelector,
    isMaster: currentEntityProfileIsMasterProfileSelector,
});

export default connect(mapStateToProps)(EntityProfileVehicleDetails);

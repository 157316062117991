import { AttributeTypeEnum } from '@mark43/rms-api';
import { map } from 'lodash';
import { personGangTrackingForPersonIdSelector } from '~/client-common/core/domain/person-gang-trackings/state/data';

import { getAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';

export function convertFromFormGangTrackings(formModel, personProfileId) {
    return (dispatch, getState) => {
        const { isAllegedGangMember, gangNameAttrId, gangNameOther } = formModel;
        if (!isAllegedGangMember) {
            return [];
        }
        const state = getState();
        const formGangNameAttribute = getAttributeByIdSelector(state)(gangNameAttrId);
        const existingGangTracking = personGangTrackingForPersonIdSelector(state)(personProfileId);
        const gangAttributeData =
            formGangNameAttribute.type === AttributeTypeEnum.GANG_NAME.name
                ? {
                      gangNameAttrId,
                  }
                : {
                      gangNameAttrId: formGangNameAttribute.parentId,
                      gangSubgroupAttrId: gangNameAttrId,
                  };

        return [
            {
                ...gangAttributeData,
                ...(existingGangTracking && { id: existingGangTracking.id }),
                gangNameOther,
            },
        ];
    };
}

export function convertFromFormGangCriteria(formModel) {
    const gangCriteria = formModel.isAllegedGangMember ? formModel.gangCriteria : [];
    return {
        gangCriteria: map(gangCriteria, ({ attributeId, description }) => ({
            attributeId,
            description,
            attributeType: AttributeTypeEnum.GANG_CRITERIA.name,
        })),
    };
}

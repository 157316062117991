import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
} from '../../../../modules/core/current-user/state/ui';

const mapStateToProps = createStructuredSelector({
    currentUserHasAbility: currentUserHasAbilitySelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
});

function ElasticCell({ currentUserDepartmentId, currentUserHasAbility, children, row }) {
    const permissionSet = row.permissionSet;

    const hasCourtOrderAbility = currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_COURT_ORDERS);
    const isClickable =
        canRead(permissionSet) ||
        (row.isSealed && currentUserDepartmentId === row.departmentId && hasCourtOrderAbility);
    return children(isClickable);
}

export default connect(mapStateToProps)(ElasticCell);

import React from 'react';
import classNames from 'classnames';

export type NoDataBlockProps = {
    className?: string;
    testId?: string;
    children: React.ReactNode;
};
const NoDataBlock: React.FC<NoDataBlockProps> = ({ children, className, testId }) => {
    return (
        <div data-test-id={testId} className={classNames('no-data-block', className)}>
            {children}
        </div>
    );
};

export default NoDataBlock;

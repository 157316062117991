import { get } from 'lodash';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import * as armadaActions from '../legacy-redux/actions/armadaActions';
import * as attributesAdminActions from '../legacy-redux/actions/attributesAdminActions';
import * as departmentIPsAdminActions from '../legacy-redux/actions/departmentIPsAdminActions';
import * as customReportsAdminActions from '../legacy-redux/actions/customReportsAdminActions';
import * as departmentProfileAdminActions from '../legacy-redux/actions/departmentProfileAdminActions';
import * as userProfileAdminActions from '../legacy-redux/actions/userProfileAdminActions';
import * as shapefilesAdminActions from '../legacy-redux/actions/shapefilesAdminActions';
import * as locationAliasesAdminActions from '../legacy-redux/actions/locationAliasesAdminActions';
import * as geoAliasesAdminActions from '../modules/admin/geo-aliases';
import * as authActions from '../legacy-redux/actions/authActions';

// directly accessing the Redux store
import store from '../core/store';
import checkPagePermissions from '../modules/core/utils/checkPagePermissions';
import {
    userIsBootstrappingSelector,
    userIsLoggedInSelector,
} from '../modules/core/current-user/state/ui';
import handleAuthRedirect from './utils/handleAuthRedirect';

/**
 * This pattern of loading data for admin pages is deprecated. When creating a new admin page, use hooks to load data
 * for the component.
 */
const routeLoadersFactory = (dispatch) => ({
    loginFormOnEnter(nextState, replace, cb) {
        const state = store.getState();
        const userIsBootstrapping = userIsBootstrappingSelector(state);
        const userIsLoggedIn = userIsLoggedInSelector(state);
        // When a user is already logged in or bootstrapping
        // we can return early as we don't need to check for
        // anything login related.
        if (userIsLoggedIn || userIsBootstrapping) {
            return cb();
        }

        handleAuthRedirect({ location: nextState.location });
    },
    loadArmadaCommandsPage() {
        const shouldContinue = dispatch(checkPagePermissions(abilitiesEnum.SUPPORT.ARMADA));

        if (shouldContinue) {
            dispatch(armadaActions.loadFailedCommands());
            dispatch(armadaActions.loadPendingCommands());
            dispatch(armadaActions.loadRunningCommands());
            dispatch(armadaActions.loadCurrentCommands());
        }
    },
    loadArmadaSchedulesPage() {
        const shouldContinue = dispatch(checkPagePermissions(abilitiesEnum.SUPPORT.ARMADA));

        if (shouldContinue) {
            dispatch(armadaActions.loadSchedules());
        }
    },
    loadUserProfileAdminPage(nextState) {
        const userIdInt = nextState.params.userId
            ? parseInt(nextState.params.userId)
            : nextState.params.userId;
        dispatch(userProfileAdminActions.pageLoad(userIdInt));
        dispatch(userProfileAdminActions.resetUserProfileAdminState());
    },
    resetUserProfileAdminState() {
        dispatch(userProfileAdminActions.resetUserProfileAdminState());
    },
    loadAttributesAdminPage() {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_GLOBAL_ATTRIBUTE_CONFIGURATION)
        );

        if (shouldContinue) {
            dispatch(attributesAdminActions.pageLoad());
        }
    },
    loadAttributeType(nextState) {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_GLOBAL_ATTRIBUTE_CONFIGURATION)
        );

        if (shouldContinue) {
            const attributeType = nextState.params.attributeType;
            dispatch(attributesAdminActions.selectAttributeType(attributeType));
        }
    },
    loadAttributeTypeHistory(nextState) {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_GLOBAL_ATTRIBUTE_CONFIGURATION)
        );

        if (shouldContinue) {
            const attributeType = nextState.params.attributeType;
            dispatch(attributesAdminActions.fetchAttributeTypeHistory(attributeType));
        }
    },
    loadAttribute(nextState) {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_GLOBAL_ATTRIBUTE_CONFIGURATION)
        );

        if (shouldContinue) {
            const attributeId = nextState.params.attributeId;
            dispatch(attributesAdminActions.selectAttributeById(attributeId));
        }
    },
    openNewAttributeForm() {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.EDIT_GLOBAL_ATTRIBUTE_CONFIGURATION)
        );

        if (shouldContinue) {
            dispatch(attributesAdminActions.openNewAttributeForm());
        }
    },
    loadDepartmentIPsAdminPage() {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.EDIT_SECURITY_SETTINGS)
        );
        if (shouldContinue) {
            dispatch(departmentIPsAdminActions.pageLoad());
        }
    },
    loadCreateNewCustomReportAdminPage() {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.EDIT_REPORT_CONFIGURATION)
        );

        if (shouldContinue) {
            dispatch(customReportsAdminActions.pageLoad());
        }
    },
    loadCustomReportsAdminPage(nextState) {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_REPORT_CONFIGURATION)
        );

        if (shouldContinue) {
            const definitionIdParam = parseInt(get(nextState, 'params.id'));
            const definitionId = isNaN(definitionIdParam) ? null : definitionIdParam;
            dispatch(customReportsAdminActions.pageLoad(definitionId));
        }
    },
    loadDepartmentProfileAdminPage() {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_DEPARTMENT_CONFIGURATION)
        );
        if (shouldContinue) {
            dispatch(departmentProfileAdminActions.pageLoad());
        }
    },
    loadDepartmentResetAdminPage() {
        dispatch(checkPagePermissions(abilitiesEnum.SUPPORT.DEPARTMENT_RESET));
    },
    loadShapefilesAdminPage() {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_LOCATION_MANAGEMENT)
        );
        if (shouldContinue) {
            dispatch(shapefilesAdminActions.pageLoad());
        }
    },
    loadLocationAliasesAdminPage() {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_LOCATION_MANAGEMENT)
        );

        if (shouldContinue) {
            dispatch(locationAliasesAdminActions.loadAliases());
        }
    },
    loadGeoAliasesAdminPage() {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_LOCATION_MANAGEMENT)
        );

        if (shouldContinue) {
            dispatch(geoAliasesAdminActions.loadGeoAliases());
        }
    },
    loadLocationAlias(nextState) {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.VIEW_LOCATION_MANAGEMENT)
        );

        if (shouldContinue) {
            const locationAliasId = nextState.params.locationAliasId;
            dispatch(locationAliasesAdminActions.selectAlias(locationAliasId));
        }
    },
    openNewLocationAliasForm(nextState) {
        const shouldContinue = dispatch(
            checkPagePermissions(abilitiesEnum.ADMIN.DELETE_LOCATION_MANAGEMENT)
        );

        if (shouldContinue) {
            const locationId = nextState.location.query.locationId;
            dispatch(locationAliasesAdminActions.openNewAliasForm(locationId));
        }
    },
    logoutOnEnter() {
        dispatch(authActions.logout());
    },
});

export default routeLoadersFactory;

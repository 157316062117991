import { createSelector } from 'reselect';
import { CitationCharge } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'citationCharges';

const citationChargesModule = createLinkModule<CitationCharge>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

export const citationChargesWhereSelector = citationChargesModule.selectors.linksWhereSelector;
export const citationChargesByCitationIdSelector = createSelector(
    citationChargesWhereSelector,
    (citationChargesWhere) => (citationId: number) => citationChargesWhere({ citationId })
);

export default citationChargesModule.reducerConfig;

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import stringsConfig from '~/client-common/core/strings';
import { CASE_LOCAL_ID } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { createModalSelector } from '../../../core/box/state/ui';
import { submitCaseDetails } from '../state/data';
import { closeBox } from '../../../../legacy-redux/actions/boxActions';
import Modal from '../../../../legacy-redux/components/core/Modal';
import CaseDetailsForm from './CaseDetailsForm';

const strings = stringsConfig.components.cases.core.CaseDetailsModal;

const context = {
    name: boxEnum.CASE_DETAILS_MODAL,
};

// NOTE: this is a modal but ideally would be a lightbox
function CaseDetailsModal({ caseNumber, fieldDisplayNames, onSubmit, closeBox }) {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const title = caseNumber
        ? strings.titleWithNumber(fieldDisplayNames.CASE_LOCAL_ID, caseNumber)
        : strings.title(caseDisplayName);
    return (
        <Modal
            context={context}
            title={title}
            onClose={closeBox}
            onSave={onSubmit}
            keyboard={false}
            okText={strings.okText}
            modalClassName="case-details-modal"
        >
            <CaseDetailsForm />
        </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
    caseNumber: createModalSelector(context, 'caseNumber'),
});

const mapDispatchToProps = (dispatch, { router }) => ({
    onSubmit: () => dispatch(submitCaseDetails(router)),
    closeBox: () => dispatch(closeBox(context)),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withFields([CASE_LOCAL_ID])
)(CaseDetailsModal);

import { EntityTypeEnum } from '@mark43/rms-api';
import { at } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatTitleForVehicleSelector } from '~/client-common/core/domain/vehicles/state/ui';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { formatTitleForPropertyRecord } from '../../../../../../../legacy-redux/helpers/propertyRecordHelpers';
import { openConfirmationBar } from '../../../../../../core/confirmation-bar/state/ui';

import testIds from '../../../../../../../core/testIds';
import { abilitiesEnum, OnlyWithAbility } from '../../../../../../core/abilities';
import { Button } from '../../../../../../core/components/Button';
import { ConditionalTooltip } from '../../../../../../core/components/tooltip';
import { useAbilitySelector } from '../../../../../../core/current-user/hooks/useAbilitySelector';
import TaskSidePanel from '../../../../../../tasks/core/components/TaskSidePanel';
import { openTaskSidePanelForEntity } from '../../../../../../tasks/core/state/ui';
import { evidenceDashboardSearch } from '../../../../state/ui';
import type { ManageEvidenceViewModel } from '../../../../types';

const strings = componentStrings.evidence.dashboard.EvidenceDashboardSearchResults;

export const NewTaskBulkAction = () => {
    const results: ManageEvidenceViewModel[] = useSelector(
        // @ts-expect-error Need to Type the createSearchModule abstraction
        evidenceDashboardSearch.selectors.currentResultsViewModelsSelector
    );
    const formatTitleForVehicle = useSelector(formatTitleForVehicleSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const hasLimitAbility = useAbilitySelector(abilitiesEnum.CORE.TASK_ENTITY_LINKING_LIMIT);
    const taskLimit = Number(applicationSettings.RMS_TASK_ENTITY_LINKING_LIMIT);

    // TODO: When `createSearchModule` is typed, remove number[]
    const selectedRows: number[] = useSelector(
        // @ts-expect-error Need to Type the createSearchModule abstraction
        evidenceDashboardSearch.selectors.selectedRowsSelector
    );
    const selectedResults = at(results, selectedRows);
    const dispatch = useDispatch();

    const handleNewTaskButtonClick = useCallback(() => {
        const taskEntityLinks = selectedResults.map((result) => {
            const isVehicle = result.itemTypeAttrId === globalAttributes.itemType.vehicle;
            const itemTitle = isVehicle
                ? formatTitleForVehicle(result)
                : formatTitleForPropertyRecord(result, false);
            return {
                entityId: result.masterItemId,
                entityType: EntityTypeEnum.ITEM_PROFILE.name,
                entityTitle: itemTitle,
            };
        });

        dispatch(openTaskSidePanelForEntity(taskEntityLinks));
    }, [dispatch, formatTitleForVehicle, selectedResults]);

    const handleTaskSidePanelSaveSuccess = useCallback(() => {
        dispatch(openConfirmationBar({ message: strings.taskSaveConfirmation }));
    }, [dispatch]);

    const isDisabled = !!(
        applicationSettings.RMS_TASK_ENTITY_LINKS_ENABLED &&
        hasLimitAbility &&
        taskLimit > 1 &&
        selectedResults.length > taskLimit
    );
    return (
        <FeatureFlagged flag="RMS_TASK_ENTITY_LINKS_ENABLED">
            <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
                <ConditionalTooltip
                    condition={isDisabled}
                    content={strings.actionButtons.disabledNewTaskTooltip(taskLimit)}
                >
                    <Button
                        isTextTransformNone
                        leadingVisual="Task"
                        onClick={handleNewTaskButtonClick}
                        disabled={isDisabled}
                        testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_NEW_TASK}
                    >
                        {strings.actionButtons.newTask}
                    </Button>
                </ConditionalTooltip>
                <TaskSidePanel onSave={handleTaskSidePanelSaveSuccess} />
            </OnlyWithAbility>
        </FeatureFlagged>
    );
};

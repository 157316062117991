import { compact, map, range } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import DropdownMenu from '../../../core/components/DropdownMenu';
import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';
import testIds from '../../../../core/testIds';

const CardReorderButtonContainer = styled.div`
    margin-right: 10px;

    && .dropdown-menu-options-container {
        top: 1.2em;
    }

    && .dropdown-menu-options {
        min-width: 0;
        right: auto;
        left: -0.6em;
    }

    && .dropdown-menu-button {
        height: auto;
        width: auto;
        margin: 0;
        padding: 0;
        border: none;
        color: ${(props) => props.theme.colors.white};
        fill: ${(props) => props.theme.colors.white};

        &:hover {
            color: ${(props) => props.theme.colors.white};
            fill: ${(props) => props.theme.colors.white};
        }
    }
`;

class ReorderDropdownItem extends React.Component {
    handleClick = () => {
        const { offense, sourceIndex, targetIndex } = this.props;
        return this.props.onClick({
            offenseId: offense.id,
            fromIndex: sourceIndex,
            toIndex: targetIndex,
            reportId: offense.reportId,
        });
    };

    render() {
        return (
            <div
                className="dropdown-menu-option"
                onClick={this.handleClick}
                data-test-id={testIds.DROPDOWN_MENU_OPTION}
            >
                {this.props.children}
            </div>
        );
    }
}

export default class OffenseIncidentReorderDropdown extends React.Component {
    render() {
        return (
            <CardReorderButtonContainer>
                <DropdownMenu
                    buttonContent={<Icon type={iconTypes.TRIANGLE_DOWN} size={iconSizes.MEDIUM} />}
                    className="offense-reorder-dropdown"
                    minWidth={64}
                    testId={testIds.REORDER_DROPDOWN_MENU}
                >
                    {compact(
                        map(range(0, this.props.maxOrder), (index) => {
                            const { offense } = this.props;
                            const ordering = index + 1;
                            return ordering === offense.offenseOrder ? undefined : (
                                <ReorderDropdownItem
                                    key={index}
                                    offense={offense}
                                    sourceIndex={offense.offenseOrder - 1}
                                    targetIndex={index}
                                    onClick={this.props.onReorder}
                                >
                                    {ordering}
                                </ReorderDropdownItem>
                            );
                        })
                    )}
                </DropdownMenu>
            </CardReorderButtonContainer>
        );
    }
}

import _, {
    concat,
    compact,
    filter,
    find,
    findIndex,
    flatMap,
    get,
    groupBy,
    map,
    mapValues,
    omit,
    pick,
} from 'lodash';
import {
    createFieldset,
    createFormConfiguration,
    createNItems,
    _Form,
    createField,
    InferFormDataShape,
} from 'markformythree';
import {
    Attribute,
    AttributeTypeEnum,
    AttributeTypeEnumType,
    AttributeView,
    EntityTypeEnum,
    LinkTypesEnum,
    RefContextEnum,
    ReportAttribute,
    StopEntityAttribute,
} from '@mark43/rms-api';

import * as fields from '~/client-common/core/enums/universal/fields';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import { assignDescriptionToStopEntityAttributes } from '~/client-common/core/domain/stop-entity-attributes/utils/stopEntityAttributesHelpers';
import {
    attributesSelector,
    storeAttributes,
} from '~/client-common/core/domain/attributes/state/data';
import { hydratedStopByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/stops';
import { atlQRuleIsActiveSelector } from '~/client-common/core/domain/stops/state/data';
import {
    convertStopAnonymousSubjectsDataModelToFormModel,
    convertStopAnonymousSubjectsFormModelToDataModel,
    convertStopAnonymousSubjectsFormModelToStopEntityAttributeDataModel,
    convertStopAnonymousSubjectsFormModelToRipaSubjectOffenseCodesDataModel,
    STOP_ANONYMOUS_SUBJECTS_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH,
    STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH,
    STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_PERCEIVED_DISABILITY_PATH,
    STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH,
    STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_SUSPICION_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH,
    STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH,
    STOP_ANONYMOUS_SUBJECT_ACTIONS_TAKEN_PATH,
    STOP_ANONYMOUS_SUBJECT_NON_FORCIBLE_ACTION_TAKEN_PATH,
    STOP_ANONYMOUS_SUBJECT_FORCIBLE_ACTION_TAKEN_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_FOR_SEARCH_PATH,
    STOP_ANONYMOUS_SUBJECT_BASIS_FOR_PROPERTY_SEIZURE_PATH,
    STOP_ANONYMOUS_SUBJECT_TYPE_OF_PROPERTY_SEIZED_PATH,
    STOP_ANONYMOUS_SUBJECT_CONTRABAND_OR_EVIDENCE_PATH,
    STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH,
    STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH,
    STOP_ANONYMOUS_SUBJECT_WARNING_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_VERBAL_WARNING_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_WRITTEN_WARNING_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_CITATION_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_IN_FIELD_CITE_RELEASE_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_CUSTODIAL_ARREST_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH,
    STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH,
    STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_GIVEN_TO_STOPPED_PERSON_ATTR_ID_PATH,
} from '~/client-common/core/domain/stop-anonymous-subjects/utils/stopAnonymousSubjectHelpers';
import buildLocationEntityLinkKey from '~/client-common/core/domain/location-entity-links/utils/buildLocationEntityLinkKey';
import { ripaSubjectOffenseCodesSelector } from '~/client-common/core/domain/ripa-subject-offense-codes/state/data';
import { filterNitemsHiddenValues } from '~/client-common/helpers/formHelpers';
import { FormatFieldByName } from '~/client-common/core/fields/state/config';
import { ModuleShape } from '~/client-common/redux/state';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import userProfileAdminResource from '../../../../../legacy-redux/resources/userProfileAdminResource';

import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import { RmsAction, RmsDispatch } from '../../../../../core/typings/redux';
import { loadAttributesForType } from '../../../../core/attributes/state/ui/loadAttributesForType';
import { userProfileSelector } from '../../../../../legacy-redux/selectors/userSelectors';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import addYOEAndHiredDate from '../../utils/yearsOfExperienceHelpers';
import { getEventDatesFromEventForm } from './eventInfoForm';

export const formName = RefContextEnum.FORM_STOP.name;
export const STOP_SUBJECTS_PATH = 'subjects';
export const STOP_SUBJECT_SUBJECT_TYPE_ATTR_ID_PATH = 'subjectTypeAttrId';
export const STOP_SUBJECT_SUBJECT_TYPE_OTHER_PATH = 'subjectTypeOther';
export const STOP_SUBJECT_ADD_ADDITIONAL_SUBJECTS_BUTTON_PATH =
    'stopSubjectAddAdditionalSubjectsButton';
export const STOP_SUBJECT_WAS_THIS_PERSON_STOPPED_PATH = 'wasThisPersonStopped';
export const STOP_SUBJECT_STOP_START_UTC_PATH = 'stopStartUtc';
export const STOP_SUBJECT_STOP_END_UTC_PATH = 'stopEndUtc';
export const STOP_SUBJECT_WAS_THIS_PERSON_SEARCHED_PATH = 'wasFrisked';
export const STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_PATH = 'personSearch';
export const STOP_SUBJECT_WAS_THIS_PROPERTY_SEARCHED_PATH = 'wasSearchPerformed';
export const STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_PATH = 'propertySearch';
export const STOP_OTHER_NAMES_PATH = 'otherNames';
export const STOP_OTHER_NAME_SUBJECT_TYPE_ATTR_ID_PATH = 'subjectTypeAttrId';
export const STOP_OTHER_NAME_SUBJECT_TYPE_OTHER_PATH = 'subjectTypeOther';
export const STOP_LOCATIONS_PATH = 'stopLocations';
export const STOP_LOCATION_DESCRIPTION_PATH = 'description';
export const STOP_SUBJECT_SUBJECT_LOCATION_PATH = 'subjectLocation';
export const STOP_LOCATION_POSITION_ATTR_ID_PATH = 'positionAttrId';
export const STOP_LINKS_LOCATION_PATH = 'links.location';
export const STOP_IS_LOCATION_K_12_PUBLIC_SCHOOL_PATH = 'stop.isLocationK12PublicSchool';

export const getStopForm = () => formsRegistry.get(formName);

const stopSubjectsPersonSearchFieldsetConfiguration = createFieldset({
    fieldName: fields.DISPLAY_ONLY_STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_WRAPPER,
    fields: {
        typeOfSearchAttrId: createField<number>({
            fieldName: fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_TYPE_OF_SEARCH_ATTRIBUTE_ID,
        }),
        reasonForSearchAttrIds: createField<number[]>({
            fieldName: fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_SEARCH_ATTRIBUTE_ID,
        }),
        reasonForSearchDescription: createField<string>({
            fieldName: fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_SEARCH_DESCRIPTION,
        }),
        typeOfPropertySeizedAttrIds: createField<number[]>({
            fieldName:
                fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_TYPE_OF_PROPERTY_SEIZED_ATTRIBUTE_ID,
        }),
        typeOfPropertySeizedDescription: createField<string>({
            fieldName:
                fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_TYPE_OF_PROPERTY_SEIZED_DESCRIPTION,
        }),
    },
});
const stopSubjectsPropertySearchFieldsetConfiguration = createFieldset({
    fieldName: fields.DISPLAY_ONLY_STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_WRAPPER,
    fields: {
        typeOfSearchAttrId: createField<number>({
            fieldName:
                fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_PROPERTY_TYPE_OF_SEARCH_ATTRIBUTE_ID,
        }),
        reasonForSearchAttrIds: createField<number[]>({
            fieldName:
                fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_PROPERTY_REASON_FOR_SEARCH_ATTRIBUTE_ID,
        }),
        reasonForSearchDescription: createField<string>({
            fieldName:
                fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_PROPERTY_REASON_FOR_SEARCH_DESCRIPTION,
        }),
        objectOfSearchAttrIds: createField<number[]>({
            fieldName: fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_OBJECT_OF_SEARCH_ATTRIBUTE_ID,
        }),
        objectOfSearchDescription: createField<string>({
            fieldName: fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_OBJECT_OF_SEARCH_DESCRIPTION,
        }),
        typeOfPropertySeizedAttrIds: createField<number[]>({
            fieldName:
                fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED_ATTRIBUTE_ID,
        }),
        typeOfPropertySeizedDescription: createField<string>({
            fieldName:
                fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED_DESCRIPTION,
        }),
    },
});

const stopFormConfiguration = createFormConfiguration({
    stop: createFieldset({
        fields: {
            id: createField<number>({}),
            wasVehicleStopWithArrest: createField<boolean>({
                fieldName: fields.STOP_WAS_VEHICLE_STOP_WITH_ARREST,
            }),
            completingTcoleReport: createField<boolean>({
                fieldName: fields.STOP_COMPLETING_TCOLE_REPORT,
            }),
            atlQ1: createField<boolean>({
                fieldName: fields.STOP_ATL_Q_1,
            }),
            atlQ2: createField<boolean>({
                fieldName: fields.STOP_ATL_Q_2,
            }),
            contactTypeAttrId: createField<number>({
                fieldName: fields.STOP_CONTACT_TYPE_ATTR_ID,
            }),
            reasonForStopAttrId: createField<number>({
                fieldName: fields.STOP_REASON_FOR_STOP_ATTR_ID,
            }),
            reasonForStopOther: createField<string>({
                fieldName: fields.STOP_REASON_FOR_STOP_OTHER,
            }),
            wasSubjectScreenedAttrId: createField<boolean>({
                fieldName: fields.STOP_WAS_SUBJECT_SCREENED_ATTR_ID,
            }),
            wasSubjectSearched: createField<boolean>({
                fieldName: fields.STOP_WAS_SUBJECT_SEARCHED,
            }),
            reasonForSearchAttrId: createField<number>({
                fieldName: fields.STOP_REASON_FOR_SEARCH_ATTR_ID,
            }),
            // this field is unused and not rendered in the form
            reasonForSearchOther: createField<boolean>({
                fieldName: fields.STOP_REASON_FOR_SEARCH_OTHER,
            }),
            wasSearchConsented: createField<boolean>({
                fieldName: fields.STOP_WAS_SEARCH_CONSENTED,
            }),
            forceResultedInInjury: createField<boolean>({
                fieldName: fields.STOP_FORCE_RESULTED_IN_INJURY,
            }),
            wasContrabandDiscovered: createField<boolean>({
                fieldName: fields.STOP_WAS_CONTRABAND_DISCOVERED,
            }),
            contrabandTypeAttrId: createField<number>({
                fieldName: fields.STOP_CONTRABAND_TYPE_ATTR_ID,
            }),
            contrabandTypeOther: createField<string>({
                fieldName: fields.STOP_CONTRABAND_TYPE_OTHER,
            }),
            resultOfStopAttrId: createField<number>({
                fieldName: fields.STOP_RESULT_OF_STOP_ATTR_ID,
            }),
            arrestBasedOnAttrId: createField<number>({
                fieldName: fields.STOP_ARREST_BASED_ON_ATTR_ID,
            }),
            wasRaceKnown: createField<boolean>({
                fieldName: fields.STOP_WAS_RACE_KNOWN,
            }),
            violationNumber: createField<string>({
                fieldName: fields.STOP_VIOLATION_NUMBER,
            }),
            narrative1: createField<string>({
                fieldName: fields.STOP_NARRATIVE_1,
            }),
            narrative2: createField<string>({
                fieldName: fields.STOP_NARRATIVE_2,
            }),
            narrative3: createField<string>({
                fieldName: fields.STOP_NARRATIVE_3,
            }),
            narrative4: createField<string>({
                fieldName: fields.STOP_NARRATIVE_4,
            }),
            narrative5: createField<string>({
                fieldName: fields.STOP_NARRATIVE_5,
            }),
            userYearsOfExperience: createField<number>({
                fieldName: fields.STOP_USER_YEARS_OF_EXPERIENCE,
            }),
            userAssignmentTypeAttrId: createField<number>({
                fieldName: fields.STOP_USER_ASSIGNMENT_TYPE_ATTR_ID,
            }),
            userAssignmentTypeOther: createField<string>({
                fieldName: fields.STOP_USER_ASSIGNMENT_TYPE_OTHER,
            }),
            officerRaceOrEthnicityAttrIds: createField<number[]>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_DATA_OFFICER_RACE_ATTRIBUTE_ID,
            }),
            officerGender: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_DATA_OFFICER_GENDER_ATTRIBUTE_ID,
            }),
            isNonbinaryOfficer: createField<boolean>({
                fieldName: fields.STOP_IS_NONBINARY_OFFICER,
            }),
            isNonPrimaryAgencyInConjunctionNonReportingAgency: createField<number>({
                fieldName: fields.STOP_IS_NON_PRIMARY_AGENCY_IN_CONJUNCTION_NON_REPORTING_AGENCY,
            }),
            startDateUtc: createField<string>({
                fieldName: fields.STOP_START_DATE_UTC,
            }),
            endDateUtc: createField<string>({
                fieldName: fields.STOP_END_DATE_UTC,
            }),
            wasResponseToCallForService: createField<boolean>({
                fieldName: fields.STOP_WAS_RESPONSE_TO_CALL_FOR_SERVICE,
            }),
            wasPropertySearchConsented: createField<boolean>({
                fieldName: fields.STOP_WAS_PROPERTY_SEARCH_CONSENTED,
            }),
            wasStopInvolved: createField<boolean>({
                fieldName: fields.STOP_WAS_STOP_INVOLVED,
            }),
            typeOfStopAttrId: createField<number>({
                fieldName: fields.STOP_TYPE_OF_STOP_ATTR_ID,
            }),
            wasWelfareWellnessCheckInvolved: createField<boolean>({
                fieldName: fields.STOP_WAS_WELFARE_WELLNESS_CHECK_INVOLVED,
            }),
            wasArrestInvolved: createField<boolean>({
                fieldName: fields.STOP_WAS_ARREST_INVOLVED,
            }),
            wasInformationCollectedInOffenseReport: createField<boolean>({
                fieldName: fields.STOP_WAS_INFORMATION_COLLECTED_IN_OFFENSE_REPORT,
            }),
            dojSchoolId: createField<string>({
                fieldName: fields.STOP_DOJ_SCHOOL_ID,
            }),
            isLocationK12PublicSchool: createField<boolean>({
                fieldName: fields.STOP_IS_LOCATION_K_12_PUBLIC_SCHOOL,
            }),
        },
    }),
    reportAttributes: createFieldset({
        fields: {
            fieldContactDispositionAttrIds: createField<number[]>({
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_DISPOSITION_ATTRIBUTE_ID,
            }),
            fieldContactDispositionDescription: createField<string>({
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_DISPOSITION_DESCRIPTION,
            }),
            contrabandTypeAttrIds: createField<number[]>({
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_INVOLVED_ATTRIBUTE_ID,
            }),
            reasonForStopAttrIds: createField<number[]>({
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_REASON_FOR_STOP_ATTRIBUTE_ID,
            }),
            reasonForStopDescription: createField<string>({
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_REASON_FOR_STOP_DESCRIPTION,
            }),
        },
    }),
    event: createFieldset({
        fields: {
            eventStartUtc: createField<string>({
                fieldName: fields.EVENT_DETAIL_EVENT_START_UTC,
            }),
            eventEndUtc: createField<string>({
                fieldName: fields.EVENT_DETAIL_EVENT_END_UTC,
            }),
        },
    }),
    [STOP_ANONYMOUS_SUBJECTS_PATH]: createNItems({
        fields: {
            id: createField<number>({}),
            [STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH]: createField<boolean>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT,
            }),
            [STOP_ANONYMOUS_SUBJECT_IS_PERCEIVED_DISABILITY_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_DISABILITY_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH]: createField<boolean>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY,
            }),
            [STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH]: createField<boolean>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED,
            }),
            [STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH]: createField<boolean>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED,
            }),
            [STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH]: createField<number>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH]: createField<boolean>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_IS_NONBINARY,
            }),
            [STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_RACE_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH]: createField<number>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE,
            }),
            [STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH]: createField<boolean>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_IS_STUDENT,
            }),
            [STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH]: createField<number>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH]: createField<string>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE,
            }),
            [STOP_ANONYMOUS_SUBJECT_REASON_GIVEN_TO_STOPPED_PERSON_ATTR_ID_PATH]: createField<number>(
                {
                    fieldName:
                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_REASON_GIVEN_TO_STOPPED_PERSON_ATTRIBUTE_ID,
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH]: createField<number>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_CJIS_OFFENSE_CODE_PATH]: createField<number>({
                fieldName:
                    fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_REASON_FOR_STOP_1_RIPA_OFFENSE_CODE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_SUSPICION_TYPE_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_PROBABLE_CAUSE_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH]: createField<number>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH]: createField<number>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_SUSPICION_CJIS_OFFENSE_CODE_PATH]: createField<number>({
                fieldName:
                    fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_REASON_FOR_STOP_2_RIPA_OFFENSE_CODE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_CJIS_OFFENSE_CODE_PATH]: createField<number>({
                fieldName:
                    fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_REASON_FOR_STOP_7_RIPA_OFFENSE_CODE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_ACTIONS_TAKEN_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_ACTION_TAKEN_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_NON_FORCIBLE_ACTION_TAKEN_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_NON_FORCIBLE_ACTION_TAKEN_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_FORCIBLE_ACTION_TAKEN_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_FORCIBLE_ACTION_TAKEN_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_REASON_FOR_SEARCH_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_SEARCH_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH]: createField<string>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE,
            }),
            [STOP_ANONYMOUS_SUBJECT_BASIS_FOR_PROPERTY_SEIZURE_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_BASIS_FOR_PROPERTY_SEIZURE_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_TYPE_OF_PROPERTY_SEIZED_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_TYPE_OF_PROPERTY_SEIZED_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_CONTRABAND_OR_EVIDENCE_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_CONTRABAND_OR_EVIDENCE_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH]: createField<number>({
                fieldName:
                    fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_RESULT_OF_STOP_ATTRIBUTE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_WARNING_CJIS_OFFENSE_CODE_PATH]: createField<number>({
                fieldName:
                    fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_2_RIPA_OFFENSE_CODE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_CITATION_CJIS_OFFENSE_CODE_PATH]: createField<number>({
                fieldName:
                    fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_3_RIPA_OFFENSE_CODE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_IN_FIELD_CITE_RELEASE_CJIS_OFFENSE_CODE_PATH]: createField<number>(
                {
                    fieldName:
                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_4_RIPA_OFFENSE_CODE_ID,
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_CUSTODIAL_ARREST_CJIS_OFFENSE_CODE_PATH]: createField<number>({
                fieldName:
                    fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_6_RIPA_OFFENSE_CODE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_VERBAL_WARNING_CJIS_OFFENSE_CODE_PATH]: createField<number>({
                fieldName:
                    fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_8_RIPA_OFFENSE_CODE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_WRITTEN_WARNING_CJIS_OFFENSE_CODE_PATH]: createField<number>({
                fieldName:
                    fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_9_RIPA_OFFENSE_CODE_ID,
            }),
            [STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH]: createField<boolean>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_IS_PASSENGER,
            }),
            [STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH]: createField<boolean>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_IS_UNHOUSED,
            }),
            [STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH]: createField<boolean>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_IS_RESIDENCE,
            }),
            [STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH]: createField<number>({
                fieldName: fields.STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID,
            }),
        },
    }),
    [STOP_SUBJECTS_PATH]: createNItems({
        // This is the field that determines hide/show state of this entire NItems
        fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_LINK_TYPE,
        fields: {
            nameId: createField<number>({}),
            // field)s used only in rules
            id: createField<number>({
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_ID,
            }),
            linkType: createField<number>({
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_LINK_TYPE,
            }),
            // fields in UI
            [STOP_SUBJECT_SUBJECT_TYPE_ATTR_ID_PATH]: createField<number>({
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_SUBJECT_TYPE_ATTR_ID,
            }),
            [STOP_SUBJECT_SUBJECT_TYPE_OTHER_PATH]: createField<string>({
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_SUBJECT_TYPE_OTHER,
            }),
            [STOP_SUBJECT_WAS_THIS_PERSON_STOPPED_PATH]: createField<boolean>({
                fieldName: fields.NAME_REPORT_LINK_WAS_THIS_PERSON_STOPPED,
            }),
            [STOP_SUBJECT_STOP_START_UTC_PATH]: createField<string>({
                fieldName: fields.NAME_REPORT_LINK_STOP_START_UTC,
            }),
            [STOP_SUBJECT_STOP_END_UTC_PATH]: createField<string>({
                fieldName: fields.NAME_REPORT_LINK_STOP_END_UTC,
            }),
            [STOP_SUBJECT_WAS_THIS_PERSON_SEARCHED_PATH]: createField<boolean>({
                fieldName: fields.NAME_REPORT_LINK_WAS_FRISKED,
            }),
            [STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_PATH]: createNItems({
                ...stopSubjectsPersonSearchFieldsetConfiguration,
            }),
            [STOP_SUBJECT_WAS_THIS_PROPERTY_SEARCHED_PATH]: createField<boolean>({
                fieldName: fields.NAME_REPORT_LINK_WAS_SEARCH_PERFORMED,
            }),
            [STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_PATH]: createNItems({
                ...stopSubjectsPropertySearchFieldsetConfiguration,
            }),
            // Only allow locations for Stop Subjects. No locations for otherNames.
            // arbiter-markformythree does not support createFieldset nested inside createNItems
            [STOP_SUBJECT_SUBJECT_LOCATION_PATH]: createNItems({
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_PERSON_STOP_LINK_TYPE,
                fields: {
                    description: createField<string>({
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_PERSON_STOP_DESCRIPTION,
                    }),
                    linkType: createField<number>({
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_PERSON_STOP_LINK_TYPE,
                    }),
                    locationId: createField<number>({
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_PERSON_STOP_LOCATION_ID,
                    }),
                    positionAttrId: createField<number>({
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_PERSON_STOP_POSITION_ATTR_ID,
                    }),
                },
            }),
        },
    }),
    // used for a rule to disallow multiple subjects
    [STOP_SUBJECT_ADD_ADDITIONAL_SUBJECTS_BUTTON_PATH]: createField({
        fieldName: fields.DISPLAY_ONLY_STOP_SUBJECT_WRAPPER,
    }),
    [STOP_OTHER_NAMES_PATH]: createNItems({
        fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_STOP_LINK_TYPE,
        fields: {
            nameId: createField<number>({}),
            linkType: createField<number>({
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_STOP_LINK_TYPE,
            }),
            [STOP_OTHER_NAME_SUBJECT_TYPE_ATTR_ID_PATH]: createField<number>({
                fieldName:
                    fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_STOP_SUBJECT_TYPE_ATTR_ID,
            }),
            [STOP_OTHER_NAME_SUBJECT_TYPE_OTHER_PATH]: createField<string>({
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_STOP_SUBJECT_TYPE_OTHER,
            }),
        },
    }),
    [STOP_LOCATIONS_PATH]: createNItems({
        fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_STOP_LINK_TYPE,
        fields: {
            locationId: createField<number>({
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_STOP_LOCATION_ID,
            }),
            linkType: createField<number>({
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_STOP_LINK_TYPE,
            }),
            [STOP_LOCATION_POSITION_ATTR_ID_PATH]: createField<number>({
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_STOP_POSITION_ATTR_ID,
            }),
            [STOP_LOCATION_DESCRIPTION_PATH]: createField<string>({
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_STOP_DESCRIPTION,
            }),
        },
    }),
    atlWrapper: createField({
        fieldName: fields.DISPLAY_STOP_ATL_WRAPPER,
    }),
    links: createFieldset({
        fields: {
            location: createFieldset({
                fields: {
                    typeAttrId: createField<number>({
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_STOP_TYPE_ATTR_ID,
                    }),
                },
            }),
        },
    }),
});
export type StopFormConfiguration = typeof stopFormConfiguration;
type StopMFTFormDataShape = InferFormDataShape<StopFormConfiguration>;
type StopSubjectsPersonSearchFieldsetConfiguration = typeof stopSubjectsPersonSearchFieldsetConfiguration;
type StopSubjectsPersonSearchMFTFormDataShape = InferFormDataShape<StopSubjectsPersonSearchFieldsetConfiguration>;
type StopSubjectsPropertySearchFieldsetConfiguration = typeof stopSubjectsPropertySearchFieldsetConfiguration;
type StopSubjectsPropertySearchMFTFormDataShape = InferFormDataShape<StopSubjectsPropertySearchFieldsetConfiguration>;
export const createStopForm = (
    options: {
        initialState?: StopMFTFormDataShape;
        arbiter?: unknown;
        formatFieldByName?: FormatFieldByName;
    } = {}
) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: stopFormConfiguration,
    });
};

function attributeLoadError(err: unknown) {
    return {
        type: 'ATTRIBUTE_LOAD_ERROR',
        error: true,
        payload: err,
    };
}

/**
 * Prefill user-related fields in the Stop card using the current user's UserProfile.
 */
export function prefillStopUserFields(): RmsAction<void> {
    return (dispatch, getState) => {
        const state = getState();
        const userProfile = userProfileSelector(state);

        userProfileAdminResource
            .getUserAttributes(userProfile.userId)
            .then((attributes: unknown) => {
                const userAttributes = attributes as Attribute[];

                dispatch(storeAttributes(map(userAttributes, convertAttributeToAttributeView)));

                const userOfficerRace = _(userAttributes)
                    .filter({ attributeType: AttributeTypeEnum.STOP_DATA_OFFICER_RACE.name })
                    .map('attributeId')
                    .value();
                const userOfficerGender = _(userAttributes)
                    .filter({ attributeType: AttributeTypeEnum.STOP_DATA_OFFICER_GENDER.name })
                    .map('attributeId')
                    .first();
                const form = getStopForm();
                if (!form) {
                    return;
                }
                const stopModel = get(form.getState(), 'model.stop') || {};

                // 0 must be checked to avoid false negatives because years is either an integer or a string
                const prefillYears =
                    !stopModel.userYearsOfExperience &&
                    stopModel.userYearsOfExperience !== 0 &&
                    (!!userProfile.yearsOfExperience ||
                        userProfile.yearsOfExperience === 0 ||
                        !!userProfile.dateHired ||
                        userProfile.dateHired);
                const prefillUserAssignmentTypeAttrId =
                    !stopModel.userAssignmentTypeAttrId &&
                    !!userProfile.stopUserAssignmentTypeAttrId;
                const prefillUserAssignmentTypeOther =
                    prefillUserAssignmentTypeAttrId &&
                    !stopModel.userAssignmentTypeOther &&
                    !!userProfile.stopUserAssignmentTypeOther;
                const prefillOfficerRace =
                    stopModel.officerRaceOrEthnicityAttrIds.length === 0 &&
                    userOfficerRace.length > 0;
                const prefillOfficerGender = !stopModel.officerGender && !!userOfficerGender;
                const prefillOfficerNonbinary =
                    !stopModel.isNonbinaryOfficer && userProfile.isNonbinaryOfficer != null;

                if (
                    prefillYears ||
                    prefillUserAssignmentTypeAttrId ||
                    prefillUserAssignmentTypeOther ||
                    prefillOfficerRace ||
                    prefillOfficerGender ||
                    prefillOfficerNonbinary
                ) {
                    form.transaction(() => {
                        if (prefillYears) {
                            const totalYears = addYOEAndHiredDate(
                                userProfile.yearsOfExperience,
                                userProfile.dateHired
                            );
                            form.set('stop.userYearsOfExperience', totalYears);
                        }
                        if (prefillUserAssignmentTypeAttrId) {
                            form.set(
                                'stop.userAssignmentTypeAttrId',
                                userProfile.stopUserAssignmentTypeAttrId
                            );
                        }
                        if (prefillUserAssignmentTypeOther) {
                            form.set(
                                'stop.userAssignmentTypeOther',
                                userProfile.stopUserAssignmentTypeOther
                            );
                        }
                        if (prefillOfficerRace) {
                            form.set('stop.officerRaceOrEthnicityAttrIds', userOfficerRace);
                        }
                        if (prefillOfficerGender) {
                            form.set('stop.officerGender', userOfficerGender);
                        }
                        if (prefillOfficerNonbinary) {
                            form.set('stop.isNonbinaryOfficer', userProfile.isNonbinaryOfficer);
                        }
                    });
                }

                if (prefillUserAssignmentTypeAttrId || prefillUserAssignmentTypeOther) {
                    // When the form first mounts, the Other field won't become visible unless the attribute is known to be Other,
                    // and the attribute isn't always stored in state because
                    // 1. this prefill is done in front end and not back end
                    // 2. UserProfile's attributes are not included in bootstrap since it's part of UserSettingsView, not DepartmentSettingsView
                    // 3. ReportGatherer does not gather UserProfiles
                    // The best alternative to this is to move the prefill to back end
                    dispatch(
                        loadAttributesForType({
                            attributeType: [AttributeTypeEnum.STOP_USER_ASSIGNMENT_TYPE.name],
                        })
                    ).then(() => {
                        // trigger rules to run again, in order to show userAssignmentTypeOther
                        form.set(
                            'stop.userAssignmentTypeAttrId',
                            userProfile.stopUserAssignmentTypeAttrId
                        );
                    });
                }
            })
            .catch((err) => dispatch(attributeLoadError(err)));
    };
}

/**
 * The 2 Atlanta checkboxes have the unique behavior of being checked by default, which means false
 *   and undefined are different states, and undefined must convert to true.
 *
 * When the checkbox fields are visible and the rule to show/hide Stop fields based on the
 *   checkboxes is enabled, we convert any non-false value to true in order to auto-check the
 *   checkboxes.
 *
 * On the other hand, when the fields are hidden or the rule is disabled, we convert all values to
 *    undefined. The db should not store false when checkboxes don't exist.
 * @param {} fields
 * @param {*} atlQRuleIsActive
 */
export function convertAtlQsToFormModel(
    fields: { atlQ1: boolean | undefined; atlQ2: boolean | undefined },
    atlQRuleIsActive: boolean
) {
    if (atlQRuleIsActive) {
        return mapValues(fields, (q) => (typeof q === 'boolean' ? q : true));
    } else {
        return {
            atlQ1: undefined,
            atlQ2: undefined,
        };
    }
}

function filterReportAttributeIds(
    reportAttributes: ReportAttribute[],
    attributeType: AttributeTypeEnumType
) {
    return _(reportAttributes).filter({ attributeType }).map('attributeId').value();
}

// return a single row within one subject search NItems, for a single search type under person search or property search
function buildSubjectSearchNItem({
    stopEntityAttributes,
    typeOfSearchAttrId,
    isPerson,
}: {
    stopEntityAttributes: StopEntityAttribute[];
    typeOfSearchAttrId: number;
    isPerson: boolean;
}) {
    const stopEntityAttributesWithTypeOfSearch = filter(stopEntityAttributes, {
        mapToAttributeId: typeOfSearchAttrId,
    });

    const filterAttributeIds = (attributeType: AttributeTypeEnumType) =>
        _(stopEntityAttributesWithTypeOfSearch)
            .filter({ attributeType })
            .map('attributeId')
            .uniq()
            .value();

    const getDescription = (attributeType: AttributeTypeEnumType) =>
        getDescriptionForAttributeLinks(
            filter(stopEntityAttributesWithTypeOfSearch, { attributeType })
        );

    const reasonForSearchAttributeType = isPerson
        ? AttributeTypeEnum.REASON_FOR_SEARCH.name
        : AttributeTypeEnum.PROPERTY_REASON_FOR_SEARCH.name;
    const propertySeizedAttributeType = isPerson
        ? AttributeTypeEnum.STOP_TYPE_OF_PROPERTY_SEIZED.name
        : AttributeTypeEnum.STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED.name;

    return {
        typeOfSearchAttrId,
        reasonForSearchAttrIds: filterAttributeIds(reasonForSearchAttributeType),
        reasonForSearchDescription: getDescription(reasonForSearchAttributeType),
        ...(!isPerson
            ? {
                  objectOfSearchAttrIds: filterAttributeIds(
                      AttributeTypeEnum.OBJECT_OF_SEARCH.name
                  ),
                  objectOfSearchDescription: getDescription(
                      AttributeTypeEnum.OBJECT_OF_SEARCH.name
                  ),
              }
            : {}),
        typeOfPropertySeizedAttrIds: filterAttributeIds(propertySeizedAttributeType),
        typeOfPropertySeizedDescription: getDescription(propertySeizedAttributeType),
    };
}

// filter the given stopEntityAttributes for one subject
// and return the NItems field values associated with person search and property search
function convertStopEntityAttributesToSubjectSearchNItems({
    entityId,
    stopEntityAttributes,
}: {
    entityId: number;
    stopEntityAttributes: StopEntityAttribute[];
}) {
    const personStopEntityAttributes = filter(stopEntityAttributes, {
        entityId,
        isPerson: true,
    });
    const personSearchNItems = _(personStopEntityAttributes)
        .filter({ attributeType: AttributeTypeEnum.TYPE_OF_SEARCH.name })
        .map('attributeId')
        .sortBy()
        .uniq()
        .map((typeOfSearchAttrId) =>
            buildSubjectSearchNItem({
                stopEntityAttributes: personStopEntityAttributes,
                typeOfSearchAttrId,
                isPerson: true,
            })
        )
        .value();

    const propertyStopEntityAttributes = filter(stopEntityAttributes, {
        entityId,
        isPerson: false,
    });
    const propertySearchNItems = _(propertyStopEntityAttributes)
        .filter({ attributeType: AttributeTypeEnum.PROPERTY_TYPE_OF_SEARCH.name })
        .map('attributeId')
        .sortBy()
        .uniq()
        .map((typeOfSearchAttrId) =>
            buildSubjectSearchNItem({
                stopEntityAttributes: propertyStopEntityAttributes,
                typeOfSearchAttrId,
                isPerson: false,
            })
        )
        .value();

    return {
        [STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_PATH]: personSearchNItems,
        [STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_PATH]: propertySearchNItems,
    };
}

function getDescriptionFromAttributes(
    reportAttributes: ReportAttribute[],
    attributeType: AttributeTypeEnumType
) {
    const filteredReportAttributes = filter(reportAttributes, { attributeType });
    return getDescriptionForAttributeLinks(filteredReportAttributes);
}

export function updateEventDatesOnStopForm(eventStartUtc: string, eventEndUtc: string) {
    return () => {
        formsRegistry.maybeDeferredOperation(formName, undefined, (form) => {
            form.set('event.eventStartUtc', eventStartUtc);
            form.set('event.eventEndUtc', eventEndUtc);
        });
    };
}

export const buildStopCardFormModel = ({ reportId }: { reportId: number }) => (
    dispatch: RmsDispatch,
    getState: () => RootState
) => {
    const state = getState();
    const {
        stop = {},
        reportAttributes,
        subjects,
        stopEntityAttributes,
        otherNames,
        locationEntityLinks,
        stopAnonymousSubjects,
        ripaSubjectOffenseCodes,
    } = hydratedStopByReportIdSelector(state)(reportId);

    const locationEntityLinksByType = groupBy(locationEntityLinks, 'linkType');

    const currentReportId = currentReportIdSelector(state);
    const eventDates = dispatch(getEventDatesFromEventForm(currentReportId));

    return {
        stop: {
            ...pick(stop, [
                'id',
                'contactTypeAttrId',
                'reasonForStopAttrId',
                'reasonForStopOther',
                'wasSubjectScreenedAttrId',
                'wasSubjectSearched',
                'reasonForSearchAttrId',
                'wasSearchConsented',
                'forceResultedInInjury',
                'wasContrabandDiscovered',
                'contrabandTypeAttrId',
                'contrabandTypeOther',
                'resultOfStopAttrId',
                'arrestBasedOnAttrId',
                'wasRaceKnown',
                'violationNumber',
                'narrative1',
                'narrative2',
                'narrative3',
                'narrative4',
                'narrative5',
                'userYearsOfExperience',
                'userAssignmentTypeAttrId',
                'userAssignmentTypeOther',
                'isNonbinaryOfficer',
                'isNonPrimaryAgencyInConjunctionNonReportingAgency',
                'startDateUtc',
                'endDateUtc',
                'wasResponseToCallForService',
                'wasPropertySearchConsented',
                'wasVehicleStopWithArrest',
                'completingTcoleReport',
                'wasStopInvolved',
                'typeOfStopAttrId',
                'wasWelfareWellnessCheckInvolved',
                'wasArrestInvolved',
                'wasInformationCollectedInOffenseReport',
                'dojSchoolId',
                'isLocationK12PublicSchool',
            ]),
            officerRaceOrEthnicityAttrIds: _(stopEntityAttributes)
                .filter({ attributeType: AttributeTypeEnum.STOP_DATA_OFFICER_RACE.name })
                .map('attributeId')
                .value(),
            officerGender: _(stopEntityAttributes)
                .filter({ attributeType: AttributeTypeEnum.STOP_DATA_OFFICER_GENDER.name })
                .map('attributeId')
                .first(),
            ...convertAtlQsToFormModel(
                // @ts-expect-error client-common transport to client
                { atlQ1: stop.atlQ1, atlQ2: stop.atlQ2 },
                atlQRuleIsActiveSelector(state)
            ),
        },
        event: eventDates,
        [STOP_SUBJECTS_PATH]: map(subjects, (subject) => {
            const subjectLocation = find(
                locationEntityLinksByType[LinkTypesEnum.LOCATION_OF_PERSON_STOP],
                {
                    entityId: subject.nameId,
                }
            );

            return {
                ...subject,
                ...convertStopEntityAttributesToSubjectSearchNItems({
                    entityId: subject.nameId,
                    // @ts-expect-error client-common transport to client
                    stopEntityAttributes,
                }),
                [STOP_SUBJECT_SUBJECT_LOCATION_PATH]: subjectLocation ? [subjectLocation] : [],
            };
        }),
        reportAttributes: {
            fieldContactDispositionAttrIds: filterReportAttributeIds(
                // @ts-expect-error client-common transport to client
                reportAttributes,
                AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name
            ),
            fieldContactDispositionDescription: getDescriptionFromAttributes(
                // @ts-expect-error client-common transport to client
                reportAttributes,
                AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name
            ),
            contrabandTypeAttrIds: filterReportAttributeIds(
                // @ts-expect-error client-common transport to client
                reportAttributes,
                AttributeTypeEnum.WEAPON_INVOLVED.name
            ),
            reasonForStopAttrIds: filterReportAttributeIds(
                // @ts-expect-error client-common transport to client
                reportAttributes,
                AttributeTypeEnum.STOP_REASON_FOR_STOP.name
            ),
            reasonForStopDescription: getDescriptionFromAttributes(
                // @ts-expect-error client-common transport to client
                reportAttributes,
                AttributeTypeEnum.STOP_REASON_FOR_STOP.name
            ),
            reasonGivenToStoppedPersonAttrIds: filterReportAttributeIds(
                // @ts-expect-error client-common transport to client
                reportAttributes,
                AttributeTypeEnum.SUBJECT_DATA_REASON_GIVEN_TO_STOPPED_PERSON.name
            ),
        },
        links: {
            location: find(locationEntityLinksByType[LinkTypesEnum.LOCATION_OF_STOP]),
        },
        [STOP_ANONYMOUS_SUBJECTS_PATH]: convertStopAnonymousSubjectsDataModelToFormModel({
            // @ts-expect-error client-common transport to client
            stopAnonymousSubjects,
            // @ts-expect-error client-common transport to client
            stopEntityAttributes,
            // @ts-expect-error client-common transport to client
            ripaSubjectOffenseCodes,
        }),
        [STOP_OTHER_NAMES_PATH]: otherNames,
        [STOP_LOCATIONS_PATH]: locationEntityLinksByType[LinkTypesEnum.LOCATION_OF_STOP],
    };
};

const convertReportAttributeFormModelToDataModel = ({
    attributes,
    reportId,
    attributeTypeName,
    attributeIds = [],
    description,
}: {
    attributes: ModuleShape<AttributeView>;
    reportId: number;
    attributeTypeName: AttributeTypeEnumType;
    attributeIds: number[] | undefined;
    description?: string;
}) => {
    return assignDescriptionToReportAttributes(
        map(attributeIds, (attributeId) => {
            return {
                reportId,
                attributeType: attributeTypeName,
                attributeId,
            };
        }),
        attributes,
        description
    );
};

// same as `convertReportAttributeFormModelToDataModel` above, but for stopEntityAttributes
const convertSubjectSearchFieldToStopEntityAttribute = ({
    stopId,
    nameId,
    isPerson,
    mapToAttributeId,
    attributes,
    attributeType,
    attributeIds = [],
    description,
}: {
    stopId: number;
    nameId: number;
    isPerson: boolean;
    mapToAttributeId?: number;
    attributes: ModuleShape<AttributeView>;
    attributeType: AttributeTypeEnumType;
    attributeIds: number[] | undefined;
    description: string | undefined;
}) => {
    const stopEntityAttributes = map(attributeIds, (attributeId) => ({
        stopId,
        entityType: EntityTypeEnum.PERSON_PROFILE.name,
        entityId: nameId,
        isPerson,
        mapToAttributeId,
        attributeType,
        attributeId,
    }));
    return assignDescriptionToStopEntityAttributes(stopEntityAttributes, attributes, description);
};

// given an NItems for either person search or property search from the form,
// build the stopEntityAttributes to be saved on the server
const convertSubjectSearchNItemsToStopEntityAttributes = ({
    stopId,
    nameId,
    isPerson,
    nItems,
    attributes,
}: {
    stopId: number;
    nameId: number;
    isPerson: boolean;
    nItems: typeof isPerson extends true
        ? StopSubjectsPersonSearchMFTFormDataShape[]
        : StopSubjectsPropertySearchMFTFormDataShape[];
    attributes: ModuleShape<AttributeView>;
}) => {
    const convertAttributes = (options: {
        mapToAttributeId: number | undefined;
        attributeType: AttributeTypeEnumType;
        attributeIds: number[];
        description: string | undefined;
    }) =>
        get(options.attributeIds, 'length') > 0
            ? convertSubjectSearchFieldToStopEntityAttribute({
                  stopId,
                  nameId,
                  isPerson,
                  attributes,
                  ...options,
              })
            : [];

    return flatMap(nItems, (nItem) =>
        nItem.typeOfSearchAttrId
            ? [
                  ...convertAttributes({
                      mapToAttributeId: undefined,
                      attributeType: isPerson
                          ? AttributeTypeEnum.TYPE_OF_SEARCH.name
                          : AttributeTypeEnum.PROPERTY_TYPE_OF_SEARCH.name,
                      attributeIds: [nItem.typeOfSearchAttrId],
                      description: undefined,
                  }),
                  ...convertAttributes({
                      mapToAttributeId: nItem.typeOfSearchAttrId,
                      attributeType: isPerson
                          ? AttributeTypeEnum.REASON_FOR_SEARCH.name
                          : AttributeTypeEnum.PROPERTY_REASON_FOR_SEARCH.name,
                      attributeIds: nItem.reasonForSearchAttrIds || [],
                      description: nItem.reasonForSearchDescription,
                  }),
                  ...convertAttributes({
                      mapToAttributeId: nItem.typeOfSearchAttrId,
                      attributeType: AttributeTypeEnum.OBJECT_OF_SEARCH.name,
                      attributeIds: nItem.objectOfSearchAttrIds || [],
                      description: nItem.objectOfSearchDescription,
                  }),
                  ...convertAttributes({
                      mapToAttributeId: nItem.typeOfSearchAttrId,
                      attributeType: isPerson
                          ? AttributeTypeEnum.STOP_TYPE_OF_PROPERTY_SEIZED.name
                          : AttributeTypeEnum.STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED.name,
                      attributeIds: nItem.typeOfPropertySeizedAttrIds || [],
                      description: nItem.typeOfPropertySeizedDescription,
                  }),
              ]
            : []
    );
};

export const buildStopCardBundle = ({
    reportId,
    form,
}: {
    reportId: number;
    form: _Form<StopFormConfiguration>;
}) => (dispatch: RmsDispatch, getState: () => RootState) => {
    const state = getState();
    const {
        stop = {},
        reportAttributes = {},
        [STOP_SUBJECTS_PATH]: subjects = [],
        [STOP_OTHER_NAMES_PATH]: otherNames = [],
        [STOP_LOCATIONS_PATH]: stopLocations = [],
        [STOP_ANONYMOUS_SUBJECTS_PATH]: stopAnonymousSubjectsFormModel = [],
    } = form.getState().model;

    const { stopAnonymousSubjects: stopAnonymousSubjectsFormUI } = form.getState().ui;

    if ((!stop.atlQ1 || !stop.atlQ2) && atlQRuleIsActiveSelector(state)) {
        // if either Atlanta checkbox is unchecked, clear all other fields
        return {
            stop: {
                ...pick(stop, ['id', 'atlQ1', 'atlQ2']),
                reportId,
            },
            reportAttributes: [],
            nameReportLinks: [],
            locationEntityLinks: [],
            stopAnonymousSubjects: [],
        };
    }

    const stopAnonymousSubjects = filterNitemsHiddenValues(
        stopAnonymousSubjectsFormModel,
        stopAnonymousSubjectsFormUI
    );

    const attributes = attributesSelector(state);
    const ripaSubjectOffenseCodes = ripaSubjectOffenseCodesSelector(state);

    const stopSubjectLocationEntityLinks = compact(
        flatMap(subjects, STOP_SUBJECT_SUBJECT_LOCATION_PATH)
    );
    const locationEntityLinks = concat(stopLocations, stopSubjectLocationEntityLinks);

    return {
        stop: {
            ...stop,
            reportId,
        },
        reportAttributes: [
            ...convertReportAttributeFormModelToDataModel({
                attributes,
                reportId,
                attributeTypeName: AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name,
                attributeIds: reportAttributes.fieldContactDispositionAttrIds,
                description: reportAttributes.fieldContactDispositionDescription,
            }),
            ...convertReportAttributeFormModelToDataModel({
                attributes,
                reportId,
                attributeTypeName: AttributeTypeEnum.WEAPON_INVOLVED.name,
                attributeIds: reportAttributes.contrabandTypeAttrIds,
            }),
            ...convertReportAttributeFormModelToDataModel({
                attributes,
                reportId,
                attributeTypeName: AttributeTypeEnum.STOP_REASON_FOR_STOP.name,
                attributeIds: reportAttributes.reasonForStopAttrIds,
                description: reportAttributes.reasonForStopDescription,
            }),
        ],
        nameReportLinks: [
            ...map(subjects, (nameReportLink) => ({
                ...omit(nameReportLink, [
                    STOP_SUBJECT_SUBJECT_LOCATION_PATH,
                    STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_PATH,
                    STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_PATH,
                ]),
                linkType: LinkTypesEnum.SUBJECT_IN_STOP,
            })),
            ...map(otherNames, (nameReportLink) => ({
                ...nameReportLink,
                linkType: LinkTypesEnum.OTHER_NAME_IN_STOP,
            })),
        ],
        stopEntityAttributes: [
            ...map(stop.officerRaceOrEthnicityAttrIds, (attributeId) => ({
                stopId: stop.id,
                isPerson: false,
                attributeType: AttributeTypeEnum.STOP_DATA_OFFICER_RACE.name,
                attributeId,
                entityType: EntityTypeEnum.STOP.name,
                entityId: stop.id,
            })),
            ...map(
                !!stop.officerGender
                    ? [
                          {
                              stopId: stop.id,
                              isPerson: false,
                              attributeType: AttributeTypeEnum.STOP_DATA_OFFICER_GENDER.name,
                              attributeId: stop.officerGender,
                              entityType: EntityTypeEnum.STOP.name,
                              entityId: stop.id,
                          },
                      ]
                    : []
            ),
            ...flatMap(subjects, (subject) =>
                stop.id && subject.nameId
                    ? [
                          ...convertSubjectSearchNItemsToStopEntityAttributes({
                              stopId: stop.id,
                              nameId: subject.nameId,
                              isPerson: true,
                              // Type '{ [x: string]: undefined; [x: number]: undefined; } | undefined' is not assignable to type
                              // 'InferFieldsetOrNitems<{ fieldName: string; fields: { typeOfSearchAttrId: number & MFTFieldConfigurationDiscriminator; reasonForSearchAttrIds: number[] & MFTFieldConfigurationDiscriminator; ... 4 more ...;
                              //     typeOfPropertySeizedDescription: string & MFTFieldConfigurationDiscriminator; }; } & MFTFieldsetConfigurationDis...'.
                              // Type 'undefined' is not assignable to type 'InferFieldsetOrNitems<{ fieldName: string; fields: { typeOfSearchAttrId: number & MFTFieldConfigurationDiscriminator; reasonForSearchAttrIds: number[] & MFTFieldConfigurationDiscriminator; ... 4 more ...;
                              //     typeOfPropertySeizedDescription: string & MFTFieldConfigurationDiscriminator; }; } & MFTFieldsetConfigurationDis...'.ts(2322)
                              // @ts-expect-error see above
                              nItems: subject[STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_PATH],
                              attributes,
                          }),
                          ...convertSubjectSearchNItemsToStopEntityAttributes({
                              stopId: stop.id,
                              nameId: subject.nameId,
                              isPerson: false,
                              // Type '{ [x: string]: undefined; [x: number]: undefined; } | undefined' is not assignable to type
                              // 'InferFieldsetOrNitems<{ fieldName: string; fields: { typeOfSearchAttrId: number & MFTFieldConfigurationDiscriminator; reasonForSearchAttrIds: number[] & MFTFieldConfigurationDiscriminator; ... 4 more ...;
                              //    typeOfPropertySeizedDescription: string & MFTFieldConfigurationDiscriminator; }; } & MFTFieldsetConfigurationDis...'.
                              // Type 'undefined' is not assignable to type 'InferFieldsetOrNitems<{ fieldName: string; fields: { typeOfSearchAttrId: number & MFTFieldConfigurationDiscriminator; reasonForSearchAttrIds: number[] & MFTFieldConfigurationDiscriminator; ... 4 more ...;
                              //    typeOfPropertySeizedDescription: string & MFTFieldConfigurationDiscriminator; }; } & MFTFieldsetConfigurationDis...'.ts(2322)
                              // @ts-expect-error see above
                              nItems: subject[STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_PATH],
                              attributes,
                          }),
                      ]
                    : []
            ),
            ...flatMap(stopAnonymousSubjects, (stopAnonymousSubject) => {
                // Only parse StopEntityAttributes of StopAnonymousSubjects here if Anonymous Subject exists
                // If not, StopEntityAttribute should be nested in StopAnonymousSubjects model
                if (stopAnonymousSubject.id && stop.id) {
                    return [
                        ...convertStopAnonymousSubjectsFormModelToStopEntityAttributeDataModel({
                            stopId: stop.id,
                            stopAnonymousSubject,
                        }),
                    ];
                }
                return [];
            }),
        ],
        locationEntityLinks,
        stopAnonymousSubjects:
            stop.id &&
            convertStopAnonymousSubjectsFormModelToDataModel({
                stopAnonymousSubjects,
                stopId: stop.id,
                reportId,
                ripaSubjectOffenseCodes,
            }),
        ripaSubjectOffenseCodes: [
            ...flatMap(stopAnonymousSubjects, (stopAnonymousSubject) => {
                // Only parse ripaSubjectOffenseCodes of StopAnonymousSubjects here if Anonymous Subject exists
                // If not, ripaSubjectOffenseCodes should be nested in StopAnonymousSubjects model
                if (stopAnonymousSubject.id) {
                    return stop.id
                        ? [
                              ...convertStopAnonymousSubjectsFormModelToRipaSubjectOffenseCodesDataModel(
                                  {
                                      stopId: stop.id,
                                      stopAnonymousSubject,
                                      ripaSubjectOffenseCodes,
                                  }
                              ),
                          ]
                        : [];
                }
                return [];
            }),
        ],
    };
};

export const refreshStopForm = ({ reportId }: { reportId: number }) => (dispatch: RmsDispatch) => {
    const form = getStopForm();
    const formModel = dispatch(buildStopCardFormModel({ reportId }));
    form?.set('', formModel);
};

export const findIndexOfNItemsNameLink = ({
    path,
    nameId,
}: {
    path: typeof STOP_OTHER_NAMES_PATH | typeof STOP_SUBJECTS_PATH;
    nameId: number;
}) => {
    const form = getStopForm();
    if (!form) {
        return undefined;
    }
    const {
        model: { [path]: nameLinks },
    } = form.getState();
    return findIndex(nameLinks, { nameId });
};

export const findIndexOfNItemsLocationEntityLink = ({
    path,
    locationEntityLinkKey,
}: {
    path: typeof STOP_LOCATIONS_PATH;
    locationEntityLinkKey: number;
}) => {
    const form = getStopForm();
    if (!form) {
        return undefined;
    }
    const {
        model: { [path]: locationEntityLinks },
    } = form.getState();
    const formLocationEntityLinkKeys = map(locationEntityLinks, buildLocationEntityLinkKey);
    return findIndex(
        formLocationEntityLinkKeys,
        // @ts-expect-error client-common transport to client
        (formLocationEntityLinkKey) => formLocationEntityLinkKey === locationEntityLinkKey
    );
};

import React from 'react';
import { isFunction, reduce, size } from 'lodash';
import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { cssVar } from 'arc';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { useCaseFieldName, useFieldName } from '~/client-common/core/fields/hooks/useFields';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { DISPLAY_ONLY_CAUTION_LABEL } from '~/client-common/core/enums/universal/fields';

import Icon, { iconTypes } from '../../../../core/components/Icon';
import { entityProfileModuleMap } from '../../..';
import {
    currentEntityProfileIdSelector as masterItemId,
    staffRemarksDataSelector as staffRemarksData,
} from '../../state/ui';
import testIds from '../../../../../core/testIds';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 40px;
    padding-left: 19px;
    font-size: ${cssVar('arc.fontSizes.sm')};
    color: ${(props) =>
        props.active ? cssVar('arc.colors.text.inverse') : cssVar('arc.colors.brand.default')};
    ${(props) =>
        props.active ? `background-color: ${cssVar('arc.colors.selected.default')}` : ''};

    &:hover {
        color: ${cssVar('arc.colors.brand.default')};
        background-color: ${cssVar('arc.colors.interactive.hover')};

        path,
        polygon {
            fill: ${cssVar('arc.colors.brand.default')};
        }
    }
`;

function EntityProfileSidebarLink({
    active,
    entityId,
    entityType,
    itemType,
    profileSection,
    staffRemarksDataCount,
    testId,
}) {
    const iconType =
        itemType === globalAttributes.itemType.firearm && profileSection === 'details'
            ? iconTypes.FIREARM
            : entityProfileModuleMap[entityType].sections[profileSection].iconType;

    const { text } = entityProfileModuleMap[entityType].sections[profileSection];
    const { pluralCaseFieldName } = useCaseFieldName();
    const { plural: cautionsDisplayName } = useFieldName(DISPLAY_ONLY_CAUTION_LABEL);
    const label = isFunction(text)
        ? text({
              remarksCount: staffRemarksDataCount,
              casesFieldName: pluralCaseFieldName,
              cautionsDisplayName,
          })
        : text;

    return (
        <Link
            to={`/profiles/${entityType}/${entityId}/${profileSection}`}
            target="_self"
            data-test-id={testId || testIds.ENTITY_SIDEBAR_LINK}
        >
            <Wrapper active={active}>
                <Icon
                    color={
                        active
                            ? 'var(--arc-colors-brand-content)'
                            : 'var(--arc-colors-brand-default)'
                    }
                    size={20}
                    type={iconType}
                />
                {label}
            </Wrapper>
        </Link>
    );
}

const mapStateToProps = createStructuredSelector({
    masterItemId,
    staffRemarksData,
});

export default compose(
    connect(mapStateToProps),
    withPropsOnChange(['staffRemarksData'], ({ staffRemarksData }) => ({
        staffRemarksDataCount: reduce(
            staffRemarksData,
            (count, { itemRemarks, reportRemarks }) =>
                count + size(itemRemarks) + size(reportRemarks),
            0
        ),
    }))
)(EntityProfileSidebarLink);

import { FormRow as _FormRow } from 'arc';
import styled from 'styled-components';

/** Temporary wrapper to make FormRow work better in RMS right now.
 * The ARC FormRow adds vertical and horizontal gap. The RMS form fields however already have margin applied.
 * This causes a doubling up of space vertically (though not horizontally).
 * This component can be removed when form fields don't have default margin applied.
 */
const FormRow = styled(_FormRow)`
    --arc-form-row-spacing-stack: 0;
`;

export default FormRow;

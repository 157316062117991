import { chain } from 'lodash';
import { CallTakerStation } from '@mark43/cad-api';
import { createSelector } from 'reselect';
import createNormalizedModule, { ModuleShape } from '../../../../utils/createNormalizedModule';

const cadCallTakerStationsModule = createNormalizedModule<CallTakerStation>({
    type: 'cadCallTakerStations',
});

// ACTIONS
export const storeCadCallTakerStations = cadCallTakerStationsModule.actionCreators.storeEntities;

/**
 * Format the given station as a dropdown/checkbox option.
 */
function mapCadCallTakerStationToOption({
    id,
    displayValue,
    startDateUtc,
    endDateUtc,
}: CallTakerStation) {
    return {
        value: id,
        display: displayValue,
        startDateUtc,
        endDateUtc,
    };
}

/**
 * Format the given cad stations as dropdown/checkbox options, sorted by
 *   display string.
 */
function mapCadCallTakerStationsToOptions(cadCallTakerStations: ModuleShape<CallTakerStation>) {
    return chain(cadCallTakerStations)
        .map(mapCadCallTakerStationToOption)
        .sortBy('display')
        .value();
}

// SELECTORS
export const cadCallTakerStationsSelector = cadCallTakerStationsModule.selectors.entitiesSelector;

export const cadCallTakerStationOptionsSelector = createSelector(
    cadCallTakerStationsSelector,
    mapCadCallTakerStationsToOptions
);

// REDUCER
export default cadCallTakerStationsModule.reducerConfig;

import { EntityTypeEnumType, EntityTypeEnum } from '@mark43/rms-api';
/**
 * Dragon query configurations store entity types by their id, but our component requires
 * the string name. We use this reverse lookup enum to find the correct name for a given link type id
 */
export const reverseLookupEntityTypeEnum = (Object.entries(EntityTypeEnum) as [
    EntityTypeEnumType,
    { value: number }
][]).reduce<Record<string, EntityTypeEnumType>>((acc, [key, value]) => {
    acc[value.value] = key;
    return acc;
}, {});

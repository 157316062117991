import { EntityTypeEnum, DeduplicatePersonProfileDecisionEnum } from '@mark43/rms-api';
import { filter, includes, map, pickBy, flatMap, get } from 'lodash';

import getPersonProfileResource from '~/client-common/core/domain/person-profiles/resources/personProfileResource';
import getOrganizationProfileResource from '~/client-common/core/domain/organization-profiles/resources/organizationProfileResource';
import getItemProfileResource from '~/client-common/core/domain/item-profiles/resources/itemProfileResource';

import { NEXUS_STATE_PROP as PERSON_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-profiles/state/data';
import { NEXUS_STATE_PROP as ORGANIZATION_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/organization-profiles/state/data';
import { NEXUS_STATE_PROP as VEHICLE_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicles/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    personGangTrackingForPersonIdSelector,
    NEXUS_STATE_PROP as PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/person-gang-trackings/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';

import personProfileMergeableFields from '../../configuration/personProfileMergeableFields';
import organizationProfileMergeableFields from '../../configuration/organizationProfileMergeableFields';
import vehicleProfileMergeableFields from '../../configuration/vehicleProfileMergeableFields';
import computeMergedModel from '../../utils/computeMergedModel';
import computePropSourceMap from '../../utils/computePropSourceMap';
import {
    HYDRATED_PERSON_PROFILE_PROP,
    HYDRATED_ORGANIZATION_PROFILE_PROP,
    PERSON_GANG_TRACKINGS_PROP,
    HYDRATED_VEHICLE_PROP,
} from '../../configuration/constants';
import {
    LOAD_PROFILES_START,
    LOAD_PROFILES_SUCCESS,
    LOAD_PROFILES_FAILURE,
    RESET_STATE,
    STORE_SELECTED_VALUES,
} from './actionTypes';
import {
    selectedValuesSelector,
    profilesToMergeSelector,
    entityTypeToMergeSelector,
} from './selectors';

const { PERSON_PROFILE, ORGANIZATION_PROFILE, VEHICLE } = EntityTypeEnum;

export const storeSelectedValues = ({ selectedValues }) => ({
    type: STORE_SELECTED_VALUES,
    payload: selectedValues,
});
export const loadProfilesStart = ({ entityIds, entityType }) => ({
    type: LOAD_PROFILES_START,
    payload: { entityIds, entityType },
});
const loadProfilesSuccess = (entityItems, withEntityItems) =>
    withEntityItems(entityItems, { type: LOAD_PROFILES_SUCCESS });
const loadProfilesFailure = (err) => ({ type: LOAD_PROFILES_FAILURE, payload: err });

export const loadEntityProfilesForIdsAndType = ({ entityIds, entityType }) => (
    dispatch,
    getState,
    dependencies
) => {
    dispatch(loadProfilesStart({ entityIds, entityType }));
    switch (entityType) {
        case PERSON_PROFILE.name: {
            const resource = getPersonProfileResource();
            return Promise.all([
                resource.getPersonProfile(entityIds[0]),
                resource.getPersonProfile(entityIds[1]),
            ])
                .then((hydratedProfiles) => {
                    dispatch(
                        loadProfilesSuccess(
                            {
                                [PERSON_PROFILES_NEXUS_STATE_PROP]: map(
                                    hydratedProfiles,
                                    HYDRATED_PERSON_PROFILE_PROP
                                ),
                                [PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP]: flatMap(
                                    hydratedProfiles,
                                    PERSON_GANG_TRACKINGS_PROP
                                ),
                                [ATTRIBUTES_NEXUS_STATE_PROP]: flatMap(
                                    hydratedProfiles,
                                    'attributes'
                                ),
                            },
                            dependencies.nexus.withEntityItems
                        )
                    );
                })
                .catch((err) => dispatch(loadProfilesFailure(err)));
        }
        case ORGANIZATION_PROFILE.name: {
            const resource = getOrganizationProfileResource();
            return Promise.all([
                resource.getOrganizationProfile(entityIds[0]),
                resource.getOrganizationProfile(entityIds[1]),
            ])
                .then((hydratedProfiles) => {
                    dispatch(
                        loadProfilesSuccess(
                            {
                                [ORGANIZATION_PROFILES_NEXUS_STATE_PROP]: map(
                                    hydratedProfiles,
                                    HYDRATED_ORGANIZATION_PROFILE_PROP
                                ),
                            },
                            dependencies.nexus.withEntityItems
                        )
                    );
                })
                .catch((err) => dispatch(loadProfilesFailure(err)));
        }

        case VEHICLE.name: {
            const resource = getItemProfileResource();
            return Promise.all([
                resource.getHydratedItem(entityIds[0]),
                resource.getHydratedItem(entityIds[1]),
            ])
                .then((hydratedProfiles) => {
                    dispatch(
                        loadProfilesSuccess(
                            {
                                [VEHICLE_NEXUS_STATE_PROP]: map(
                                    hydratedProfiles,
                                    HYDRATED_VEHICLE_PROP
                                ),
                                [ATTRIBUTES_NEXUS_STATE_PROP]: flatMap(
                                    hydratedProfiles,
                                    'attributes'
                                ),
                            },
                            dependencies.nexus.withEntityItems
                        )
                    );
                })
                .catch((err) => dispatch(loadProfilesFailure(err)));
        }

        default:
            throw new Error(`Entity type "${entityType}" not supported for merging`);
    }
};

export const resetMergeEntitiesState = () => ({ type: RESET_STATE });

export const initiateEntityMerge = ({ selectedValues }) => (dispatch) => {
    dispatch(storeSelectedValues({ selectedValues }));
};

const getMergedProfileData = ({ entityType }) => (dispatch, getState) => {
    const state = getState();
    const models = profilesToMergeSelector(state);
    const selectedValues = selectedValuesSelector(state);

    const [leftModel, rightModel] = models;
    let fieldConfig;
    switch (entityType) {
        case PERSON_PROFILE.name:
            fieldConfig = personProfileMergeableFields;
            break;
        case ORGANIZATION_PROFILE.name:
            fieldConfig = organizationProfileMergeableFields;
            break;
        case VEHICLE.name:
            fieldConfig = vehicleProfileMergeableFields;
            break;
        default:
            throw new Error(
                'Unsupported vehicle merging type, not of type Persons, Organizations, or Vehicles'
            );
    }

    const applicationSettings = applicationSettingsSelector(state);
    const disabledFieldProps = map(
        filter(fieldConfig, ({ featureFlag }) => featureFlag && !applicationSettings[featureFlag]),
        ({ prop }) => prop
    );

    const propsToPick = pickBy(
        computePropSourceMap({
            values: selectedValues,
            fieldConfig,
        }),
        (value, prop) => !includes(disabledFieldProps, prop)
    );

    const mergedProfile = computeMergedModel({
        propsToPick,
        leftModel,
        rightModel,
    });

    const gangModelPicked = get(propsToPick, 'suspectedGangAffiliationAttrDisplay');
    const personGangTrackings = [];
    const personGangTracking = personGangTrackingForPersonIdSelector(state)(
        get(models[gangModelPicked], 'id')
    );
    if (personGangTracking) {
        personGangTrackings.push(personGangTracking);
    }

    const sourceProfileIds = map(models, 'id');

    return { mergeProfile: mergedProfile, idsToMerge: sourceProfileIds, personGangTrackings };
};

export const getMasterPersonMergeRequestData = ({ entityType }) => (dispatch) => {
    const { mergeProfile, idsToMerge, personGangTrackings } = dispatch(
        getMergedProfileData({ entityType })
    );
    return {
        mergeProfile,
        idsToMerge,
        personGangTrackings,
    };
};

export const saveMergedProfiles = () => (dispatch, getState) => {
    const entityType = entityTypeToMergeSelector(getState());
    if (entityType === PERSON_PROFILE.name) {
        const masterPersonMergeRequest = dispatch(getMasterPersonMergeRequestData({ entityType }));
        return getPersonProfileResource()
            .updateDuplicatePersonCandidate({
                decision: DeduplicatePersonProfileDecisionEnum.YES.name,
                masterPersonMergeRequest,
            })
            .then(({ person1 }) => {
                return { pathname: `/profiles/persons/${person1.id}` };
            });
    } else if (entityType === ORGANIZATION_PROFILE.name) {
        const { mergeProfile, idsToMerge } = dispatch(
            getMergedProfileData({ isPersonMerge: false, entityType })
        );
        return getOrganizationProfileResource()
            .mergeOrganizationProfiles({
                mergeProfile,
                idsToMerge,
            })
            .then(({ id }) => {
                return { pathname: `/profiles/organizations/${id}` };
            });
    } else if (entityType === VEHICLE.name) {
        const { mergeProfile, idsToMerge } = dispatch(getMergedProfileData({ entityType }));
        return getItemProfileResource()
            .mergeVehicleProfiles({
                mergeProfile,
                idsToMerge,
            })
            .then(({ id }) => {
                return { pathname: `/profiles/vehicles/${id}` };
            });
    }
};

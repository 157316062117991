import _, {
    difference,
    pick,
    find,
    first,
    mapKeys,
    invert,
    startsWith,
    chain,
    upperFirst,
} from 'lodash';

import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { dateTypeOptions } from '~/client-common/core/dates/utils/dateHelpers';
import {
    includeLocationSubdivisions,
    getSubdivisionsFromLocation,
    getElasticQuerySubdivisionAttrIds,
} from '~/client-common/core/domain/locations/utils/subdivisionHelpers';
import { createFormModule } from '../../../../core/forms';
import { filterFormData, buildFormModel } from '../../../../../legacy-redux/helpers/formHelpers';
import targetProfileSearchFieldset from '../../../target-profile/state/forms/targetProfileSearchFieldset';
import myCasesSearchPersonnelFieldsetViewModel from './myCasesSearchPersonnelFieldset';
import myCasesSearchCaseInformationFieldsetViewModel from './myCasesSearchCaseInformationFieldset';

export const myCasesSearchFormFieldViewModels = {
    personnel: myCasesSearchPersonnelFieldsetViewModel,
    caseInformation: myCasesSearchCaseInformationFieldsetViewModel,
    targetProfile: targetProfileSearchFieldset,
};

const elasticQueryDateFields = [
    'assignedStartDateUtc',
    'assignedEndDateUtc',
    'assignedToDatePeriod',
    'assignedWithinLastPeriod',
    'createdStartDateUtc',
    'createdEndDateUtc',
    'createdToDatePeriod',
    'createdWithinLastPeriod',
    'dueStartDateUtc',
    'dueEndDateUtc',
    'dueToDatePeriod',
    'dueWithinLastPeriod',
    'updatedStartDateUtc',
    'updatedEndDateUtc',
    'updatedToDatePeriod',
    'updatedWithinLastPeriod',
];

const dueDateRelatedDateFields = [
    'dueStartDateUtc',
    'dueEndDateUtc',
    'dueToDatePeriod',
    'dueWithinLastPeriod',
];

const dateTypePrefixes = ['assigned', 'updated', 'created', 'due'];

const dateTypeToFieldPrefix = {
    [dateTypeEnum.ASSIGNED]: 'assigned',
    [dateTypeEnum.MODIFIED]: 'updated',
    [dateTypeEnum.CREATED]: 'created',
    [dateTypeEnum.DUE]: 'due',
};

const dateFieldPrefixToDateType = invert(dateTypeToFieldPrefix);

const dateRangeFields = ['toDatePeriod', 'withinLastPeriod', 'startDateUtc', 'endDateUtc'];

/**
 * Use values provided in the `dateType` field and by the DateRangePicker
 *   to determine which fields to include in our ElasticCaseQuery
 * @method dateFormFieldsToElasticQueryFields
 * @param  {Object} formModel
 * @return {Object}
 */
function dateFormFieldsToElasticQueryFields({ dateType, ...otherFields } = {}) {
    const relevantFields = pick(otherFields, ...dateRangeFields);

    return mapKeys(
        relevantFields,
        (val, key) => `${dateTypeToFieldPrefix[dateType]}${upperFirst(key)}`
    );
}

/**
 * Convert the given search query model to form state. While the query is flat,
 *   the form state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertMyCasesSearchElasticQueryToFormModel(elasticQuery = {}) {
    const dateFields =
        elasticQuery.isOverdue === null
            ? elasticQueryDateFields
            : difference(elasticQueryDateFields, dueDateRelatedDateFields);

    const firstDateField = _(elasticQuery).pick(dateFields).keys().head();

    const dateType = find(dateFieldPrefixToDateType, (dateType, dateFieldPrefix) =>
        startsWith(firstDateField, dateFieldPrefix)
    );

    const dateFieldsOnly = pick(elasticQuery, dateFields);

    const mappedDateFields = mapKeys(dateFieldsOnly, (val, key) => {
        return chain(dateTypePrefixes)
            .reduce((acc, dateTypePrefix) => acc.replace(dateTypePrefix, ''), key)
            .camelCase()
            .value();
    });

    const subdivisions = getElasticQuerySubdivisionAttrIds(elasticQuery, 'involvedLocations');

    const targetProfileReviewDateRangeQuery = elasticQuery.targetProfileReviewDateRangeQuery || {};
    const targetProfileDateFieldsOnly = pick(targetProfileReviewDateRangeQuery, ...dateRangeFields);

    return buildFormModel(
        {
            personnel: {
                supervisorRoleIds: elasticQuery.supervisorRoleIds,
                hasSupervisor: [elasticQuery.hasSupervisor],
            },
            caseInformation: {
                currentStatusAttrIds: elasticQuery.currentStatusAttrIds,
                caseDefinitionId: first(elasticQuery.caseDefinitionIds),
                caseNumber: first(elasticQuery.caseNumbers),
                reportingEventNumber: first(elasticQuery.reportingEventNumbers),
                reportIdentifier: first(elasticQuery.reportIdentifiers),
                approvalStatuses: elasticQuery.approvalStatuses,
                caseTitle: elasticQuery.title,
                ...subdivisions,
                isOverdue: elasticQuery.isOverdue,
                dateType: dateType || first(dateTypeOptions).value,
                offenseCodeIds: elasticQuery.offenseCodeIds,
                securityClassificationAttrId: elasticQuery.securityClassificationAttrId,
                ...mappedDateFields,
            },
            targetProfile: {
                isTargetProfile: elasticQuery.isTargetProfile,
                targetProfileCategoryAttrIds: elasticQuery.targetProfileCategoryAttrIds,
                targetProfilePriorityAttrIds: elasticQuery.targetProfilePriorityAttrIds,
                ...targetProfileDateFieldsOnly,
            },
        },
        myCasesSearchFormFieldViewModels
    );
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
function convertMyCasesSearchFormModelToElasticQuery(formModel = {}) {
    const personnel = formModel.personnel || {};
    const caseInformation = formModel.caseInformation || {};
    const targetProfile = formModel.targetProfile || {};
    const targetProfileDateRangeFields = pick(targetProfile, ...dateRangeFields);
    const dateFields = dateFormFieldsToElasticQueryFields(caseInformation);

    const result = filterFormData(
        {
            ...dateFields,
            currentStatusAttrIds: caseInformation.currentStatusAttrIds,
            assigneeRoleIds: formModel.assigneeRoleIds,
            includeAssistingInvestigators: formModel.includeAssistingInvestigators,
            supervisorRoleIds: personnel.supervisorRoleIds,
            hasSupervisor: first(personnel.hasSupervisor),
            caseDefinitionIds: [caseInformation.caseDefinitionId],
            title: caseInformation.caseTitle,
            approvalStatuses: caseInformation.approvalStatuses,
            caseNumbers: [caseInformation.caseNumber],
            reportingEventNumbers: [caseInformation.reportingEventNumber],
            reportIdentifiers: [caseInformation.reportIdentifier],
            involvedLocations: [
                ...(includeLocationSubdivisions(formModel.caseInformation)
                    ? [getSubdivisionsFromLocation(formModel.caseInformation)]
                    : []),
            ],
            isOverdue: caseInformation.isOverdue,
            offenseCodeIds: caseInformation.offenseCodeIds || [],
            securityClassificationAttrId: caseInformation.securityClassificationAttrId,
            isTargetProfile: targetProfile.isTargetProfile,
            targetProfileReviewDateRangeQuery: targetProfileDateRangeFields,
            targetProfileCategoryAttrIds: targetProfile.targetProfileCategoryAttrIds,
            targetProfilePriorityAttrIds: targetProfile.targetProfilePriorityAttrIds,
        },
        myCasesSearchFormFieldViewModels
    );
    return result;
}

const myCasesSearchForm = createFormModule({
    formName: formClientEnum.MY_CASES_SEARCH,
    fieldViewModels: myCasesSearchFormFieldViewModels,
    convertToFormModel: convertMyCasesSearchElasticQueryToFormModel,
    convertFromFormModel: convertMyCasesSearchFormModelToElasticQuery,
});

/**
 * Module of the my cases search/filter form.
 * @type {Object}
 */
export default myCasesSearchForm;

import React from 'react';
import { Attachment, CardField } from '@mark43/rms-api';
import { getThumbnailPath } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { PersonProfileImage, profileImageSizes } from '../../core/components/ProfileImage';
import { BookingDetails } from './BookingDetails';

type PersonalIdentificationCardProps = {
    mugshot?: Attachment;
    fields: CardField[];
};

export const PersonalIdentification: React.FC<PersonalIdentificationCardProps> = ({
    mugshot,
    fields,
}) => {
    const thumbnailPath = mugshot ? getThumbnailPath(mugshot) : undefined;
    return (
        <>
            <PersonProfileImage
                isUnknown={false}
                size={profileImageSizes.USER_PROFILE}
                url={thumbnailPath}
                noBorder={true}
            />
            <BookingDetails fields={fields} />
        </>
    );
};

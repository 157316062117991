import { EntityTypeEnum, UsageSourceModuleEnum } from '@mark43/rms-api';

import { createSelector } from 'reselect';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { getContextedOrMasterPersonProfile } from '~/client-common/core/domain/person-profiles/state/data';
import { isIdentified } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import {
    currentReportIdSelector,
    offenseModifyingSupplementRenOffenseReportIdSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { getFormContextForLinkType } from '../../utils/getFormContextForLinkType';

import {
    refreshPersonProfileForm,
    createPersonProfileFormInitialAddState,
} from '../forms/personProfileForm';
import {
    getPersonProfileFromDexPerson,
    mergeDexFormModelWithPersonFormModel,
} from '../forms/convertDexPersonToPersonProfile';
import { attributeOptionsByTypeForCurrentDepartmentSelector } from '../../../attributes/state/ui';

/**
 * Action that is responsible for setting the Person Profile Form model based
 * on the a specific link type.
 *
 * @param  {import('@mark43/rms-api').LinkTypesEnumType} selectedLinkType  - linkType param
 * @param  {Object} formModel - The form model for the Person Profile form
 */
export const setPersonProfileFormModelBySelectedLinkType = (selectedLinkType, formModel) => {
    return (dispatch, getState, { formsRegistry }) => {
        const formContext = getFormContextForLinkType(selectedLinkType);
        const form = formsRegistry.get(formContext);
        if (form) {
            form.set('', formModel);
        }
    };
};

export const fetchAndBuildPersonProfileFormModel = (params) => (dispatch, getState, { sentry }) => {
    const {
        personProfileId,
        ownerType,
        ownerId,
        personType,
        onSuccess,
        nibrsOffenseCode,
        dexPerson,
        cadProfileId,
    } = params;

    const promiseToReturn =
        isUndefinedOrNull(personProfileId) || ownerType === EntityTypeEnum.CAD_AGENCY_EVENT.name
            ? // If we're creating a new person from a suggested CAD profile we will fill from dataNexus
              // If we're creating a new person, then `personProfileId` will be null.
              Promise.resolve({ idToPrefillWith: personProfileId })
            : // If a `masterPersonId` was supplied and a contexted profile
              // exists for the given `ownerId`, this will return the
              // contexted profile
              //
              // If a `masterPersonId` was supplied and a contexted profile
              // does not exist for the given `ownerId`, this will return
              // the master profile
              //
              // If a `contextedPersonId` was supplied, and one exists
              // for the given `ownerId`, this will return the contexted
              // profile
              //
              // If a `contextedPersonId` was supplied, and one does not exist
              // for the given `ownerId`, this will return nothing
              // (but we should not get into this case)
              //
              // TODO: (WAVE-2580) This does not handle the following case:
              // 1) Unknown from a different report was clicked (via involved entities)
              // 2) We want to identify as an unknown from a different report (via involved entities)
              dispatch(
                  getContextedOrMasterPersonProfile({
                      masterOrContextedProfileId: personProfileId,
                      ownerType,
                      ownerId,
                  })
              ).then((rmsHydratedPerson) => ({ idToPrefillWith: rmsHydratedPerson.id }));

    return (
        promiseToReturn
            .then(({ idToPrefillWith }) => {
                const formModel = idToPrefillWith
                    ? dispatch(
                          refreshPersonProfileForm({
                              personProfileId: idToPrefillWith,
                              personType,
                              nibrsOffenseCode,
                              ownerEntityType: ownerType,
                              ownerEntityId: ownerId,
                              cadProfileId,
                          })
                      )
                    : dispatch(
                          createPersonProfileFormInitialAddState({
                              personType,
                              nibrsOffenseCode,
                          })
                      );

                if (dexPerson) {
                    const getAttributes = ({ type }) =>
                        attributeOptionsByTypeForCurrentDepartmentSelector(getState())({ type });
                    const dexFormModel = getPersonProfileFromDexPerson(dexPerson, getAttributes);
                    return mergeDexFormModelWithPersonFormModel(dexFormModel, formModel);
                }
                return formModel;
            })
            .then((formModel) => {
                if (onSuccess) {
                    onSuccess(formModel);
                }
                return formModel;
            })
            // For now, catching will not throw. We will just attempt to prefill
            // the profile with the information we already have in `dataNexus` (and always call `onSuccess`)
            // If we do fall into the `catch` statement, it means that something else is wrong
            .catch((err) => {
                sentry.withScope((scope) => {
                    scope.setTag('module', UsageSourceModuleEnum.ENTITY_PROFILES.name);
                    scope.setExtra('params', params);
                    sentry.captureException(err);
                });
                return { idToPrefillWith: personProfileId };
            })
    );
};

// We only want to show identify if
// * The person is not yet identified
// * The person is from a different report in the REN
// * We have permissions to view/edit the person
// We do not allow persons from an OMS report to be identified
// * Instead, users can edit the profile and modify its first/last name to be known
export const showIdentifyUnknownForPersonSelector = createSelector(
    offenseModifyingSupplementRenOffenseReportIdSelector,
    currentReportIdSelector,
    (offenseModifyingSupplementRenOffenseReportId, currentReportId) => (person) => {
        const { ownerId } = person;
        const isOMSAndPersonFromOffenseReport =
            offenseModifyingSupplementRenOffenseReportId === ownerId;
        const isPersonFromDifferentReport = ownerId !== currentReportId;
        const isPersonIdentified = isIdentified(person);
        const canViewPerson = canRead(person.permissionSet);
        return (
            !isPersonIdentified &&
            !isOMSAndPersonFromOffenseReport &&
            isPersonFromDifferentReport &&
            canViewPerson
        );
    }
);

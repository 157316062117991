import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { get } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';

import styled from 'styled-components';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    warrantsSelector,
    updateSubjectPersonProfileIdForWarrantId,
} from '~/client-common/core/domain/warrants/state/data';
import { WARRANT_SUBJECT_PERSON_PROFILE_ID } from '~/client-common/core/enums/universal/fields';
import { hideVehicleInformationSelector } from '~/client-common/core/domain/warrants/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { otherUserId } from '~/client-common/helpers/userHelpers';
import { RRFLocationSummaryViewWrapperWithFormFields } from '../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import warrantDetailsForm from '../state/forms/warrantDetailsForm';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import Row from '../../../core/components/Row';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFWarrantStatusAttributeSelect } from '../../../core/forms/components/selects/WarrantStatusAttributeSelect';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFTextArea } from '../../../core/forms/components/TextArea';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { CardSection } from '../../../../legacy-redux/components/core/Card';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import IndentedFields from '../../../core/components/IndentedFields';
import NameSummaryViewWrapper from '../../../core/components/NameSummaryViewWrapper';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import testIds from '../../../../core/testIds';

const { WithFieldState } = reactReduxFormHelpers;
const strings = componentStrings.warrants.warrant.WarrantDetailsCard;

const HasNoBailCheckbox = styled(RRFCheckbox)`
    margin-top: 20px;
`;

function WarrantDetailsForm({
    warrantId,
    reportingEventNumber,
    subjectPersonProfileId,
    formModelByPath,
    subjectFieldDisplayName,
    handleRemoveSubject,
    onHasNoBailClick,
    hideVehicleInformation,
    handleAddWarrantSubject,
}) {
    return (
        <ReactReduxForm {...warrantDetailsForm}>
            <CardSection
                testId={testIds.WARRANT_SUBJECT_SECTION}
                fieldName={fields.WARRANT_SUBJECT_PERSON_PROFILE_ID}
            >
                <NameSummaryViewWrapper
                    nameId={subjectPersonProfileId}
                    linkType={LinkTypesEnum.SUBJECT_OF_WARRANT}
                    parentEntityType={EntityTypeEnum.WARRANT.name}
                    parentId={warrantId}
                    renForRecents={reportingEventNumber}
                    summaryMode={false}
                    show={{ people: true }}
                    onAddSuccess={handleAddWarrantSubject}
                    onDelete={handleRemoveSubject}
                    hideQuickAdd={true}
                    limitToOne={true}
                    addNameButtonText={subjectFieldDisplayName}
                    personOverlayIdPrefix={overlayIdEnum.PERSON_OVERLAY_WARRANT_DETAILS_FORM}
                />
            </CardSection>
            <CardSection
                title={strings.warrantInformation}
                testId={testIds.WARRANT_INFORMATION_SECTION}
            >
                <Row>
                    <RRFDatePicker path="warrantIssuedDateUtc" width={120} />
                    <RRFDatePicker path="warrantReceivedDateUtc" width={120} />
                </Row>
                <Row>
                    <RRFWarrantStatusAttributeSelect path="warrantStatusAttrId" width={240} />
                </Row>
                <Row>
                    <RRFAttributeSelect
                        path="warrantTypeAttrId"
                        width={240}
                        attributeType="WARRANT_TYPE"
                    />
                </Row>
                <Row>
                    <RRFBooleanSelect path="isOtherJurisdiction" width={140} />
                </Row>
                <Row>
                    <RRFText
                        path="bailAmount"
                        width={240}
                        disabled={formModelByPath('hasNoBail')}
                    />
                    <HasNoBailCheckbox path="hasNoBail" onChange={onHasNoBailClick} />
                </Row>
                <Row>
                    <RRFUserSelect path="obtainingOfficerUserId" includeOther={true} width={240} />
                </Row>
                {formModelByPath('obtainingOfficerUserId') === otherUserId && (
                    <IndentedFields>
                        <Row>
                            <RRFText path="obtainingOfficerOther" width={240} />
                        </Row>
                    </IndentedFields>
                )}
                <Row>
                    <RRFBooleanSelect path="hasNightService" width={140} />
                </Row>
                <Row>
                    <RRFText path="daysToServe" width={240} />
                </Row>
                <Row>
                    <RRFText path="issuingAgencyOri" width={240} />
                </Row>
                <Row>
                    <RRFAttributeSelect
                        path="issuingAgencyNameAttrId"
                        attributeType="ISSUING_AGENCY_NAME"
                        width={240}
                    />
                </Row>
                <IndentedFields>
                    <Row>
                        <RRFText path="issuingAgencyNameOther" width={240} />
                    </Row>
                </IndentedFields>
                <Row>
                    <RRFAttributeSelect
                        path="warrantStatisticAttrIds"
                        attributeType="WARRANT_STATISTIC"
                        multiple={true}
                        width={240}
                    />
                </Row>
                <Row>
                    <RRFTextArea path="description" width={360} rows={3} />
                </Row>
            </CardSection>
            <CardSection
                title={strings.courtInformation}
                testId={testIds.WARRANT_COURT_INFORMATION_SECTION}
            >
                <Row>
                    <RRFText path="courtCaseNumber" width={240} />
                </Row>
                <Row>
                    <RRFText path="courtName" width={240} />
                </Row>
                <Row>
                    <RRFTextArea path="courtAddress" width={360} />
                </Row>
                <Row>
                    <RRFText path="courtPhoneNumber" width={240} />
                </Row>
                <Row>
                    <RRFText path="issuingJudge" width={240} />
                </Row>
                <Row>
                    <RRFTextArea path="warrantNotes" width={360} />
                </Row>
                <Row>
                    <RRFText path="issuingCourtOri" width={240} />
                </Row>
            </CardSection>
            <WithFieldState path="warrantLocation.locationId">
                {(fieldState) =>
                    fieldState.isHidden ? (
                        false
                    ) : (
                        <CardSection
                            testId={testIds.WARRANT_LOCATION_SECTION}
                            fieldName={
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_WARRANT_LOCATION_ID
                            }
                        >
                            <RRFLocationSummaryViewWrapperWithFormFields
                                entityType={EntityTypeEnum.WARRANT.name}
                                entityId={warrantId}
                                linkType={LinkTypesEnum.LOCATION_OF_WARRANT}
                                summaryMode={false}
                                locationPath={'warrantLocation'}
                                hideQuickAdd={true}
                                locationPositionAttrIdPath={() => 'positionAttrId'}
                            />
                        </CardSection>
                    )
                }
            </WithFieldState>
            {!hideVehicleInformation && (
                <CardSection
                    title={strings.vehicleInformation}
                    testId={testIds.WARRANT_VEHICLE_INFORMATION_SECTION}
                >
                    <Row>
                        <RRFText path="licensePlateState" width={240} />
                    </Row>
                    <Row>
                        <RRFText path="licensePlateNumber" width={240} />
                    </Row>
                    <Row>
                        <RRFText path="vehicleMake" width={240} />
                    </Row>
                    <Row>
                        <RRFText path="vehicleModel" width={240} />
                    </Row>
                    <Row>
                        <RRFText path="vehicleYear" width={240} />
                    </Row>
                    <Row>
                        <RRFText path="vehicleColor" width={240} />
                    </Row>
                </CardSection>
            )}
        </ReactReduxForm>
    );
}

const mapStateToProps = createStructuredSelector({
    warrants: warrantsSelector,
    formModelByPath: warrantDetailsForm.selectors.formModelByPathSelector,
    formatFieldByName: formatFieldByNameSelector,
    hideVehicleInformation: hideVehicleInformationSelector,
});

export default compose(
    connect(mapStateToProps, (dispatch, props) => ({
        handleRemoveSubject: () => {
            dispatch(warrantDetailsForm.actionCreators.changePath('subjectPersonProfileId', null));
            return dispatch(
                updateSubjectPersonProfileIdForWarrantId(
                    props.warrantId /* warrantId */,
                    null /* subjectPersonProfileId */
                )
            );
        },
        handleHasNoBailClick: (hasNoBail) => {
            if (!hasNoBail) {
                dispatch(warrantDetailsForm.actionCreators.changePath('bailAmount', null));
            }
        },
        handleAddWarrantSubject: ({ nameId }) => {
            // Updated the form state
            dispatch(
                warrantDetailsForm.actionCreators.changePath('subjectPersonProfileId', nameId)
            );
            // Update the entity in data nexus
            dispatch(
                updateSubjectPersonProfileIdForWarrantId(
                    props.warrantId /* warrantId */,
                    nameId /* subjectPersonProfileId */
                )
            );
        },
    })),
    withPropsOnChange(['warrantId', 'warrants'], ({ warrantId, warrants, formatFieldByName }) => {
        const warrant = warrants[warrantId];
        const subjectPersonProfileId = get(warrant, 'subjectPersonProfileId');
        const reportingEventNumber = get(warrant, 'reportingEventNumber');
        const subjectFieldDisplayName = formatFieldByName(WARRANT_SUBJECT_PERSON_PROFILE_ID);

        return {
            reportingEventNumber,
            subjectPersonProfileId,
            subjectFieldDisplayName,
        };
    }),
    withHandlers({
        onHasNoBailClick({ formModelByPath, handleHasNoBailClick }) {
            return () => {
                handleHasNoBailClick(formModelByPath('hasNoBail'));
            };
        },
    })
)(WarrantDetailsForm);

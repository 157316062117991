import { createSelector } from 'reselect';
import { flatMap } from 'lodash';

import {
    warrantViewModelBySubjectIdSelector,
    warrantViewModelsBySubjectMasterIdSelector,
} from '~/client-common/core/domain/warrants/state/ui';

const baseSelector = (state) => state.ui.entityProfiles;
export const uiSelector = createSelector(baseSelector, ({ ui }) => ui);
export const uiReportsSelector = createSelector(baseSelector, ({ reports }) => reports);
export const uiBookingsSelector = createSelector(baseSelector, ({ bookings }) => bookings);

/**
 * The id of the entity profile currently being viewed.
 * @type {number}
 */
export const currentEntityProfileIdSelector = createSelector(
    uiSelector,
    ({ currentEntityProfileId }) => currentEntityProfileId
);

/**
 * The id of the master version of the current entity profile currently being viewed.
 * @type {number}
 */
export const currentEntityProfileMasterProfileIdSelector = createSelector(
    uiSelector,
    ({ currentEntityProfileMasterProfileId }) => currentEntityProfileMasterProfileId
);

/**
 * the ids of the children of this profile
 * @type {[number]}
 */
export const currentEntityProfileChildrenIdsSelector = createSelector(
    uiSelector,
    ({ childrenIds }) => childrenIds
);

/**
 * the ids of the linked master profiles
 * @type {[number]}
 */
const currentEntityProfileLinkedMasterProfileIdsSelector = createSelector(
    uiSelector,
    ({ linkedMasterProfileIds }) => linkedMasterProfileIds
);

/**
 * The owner context of the current entity profile
 * @type {Object}
 */
export const currentEntityProfileOwnerContextSelector = createSelector(
    uiSelector,
    ({ currentEntityProfileOwnerType, currentEntityProfileOwnerId }) => {
        return {
            ownerType: currentEntityProfileOwnerType,
            ownerId: currentEntityProfileOwnerId,
        };
    }
);

/*
 * The title of the owner of the current entity profile
 * @type {Object}
 */
export const currentEntityProfileOwnerTitleSelector = createSelector(
    uiSelector,
    ({ currentEntityProfileOwnerTitle }) => currentEntityProfileOwnerTitle
);

export const currentProfileWarrantViewModelsSelector = createSelector(
    currentEntityProfileIdSelector,
    currentEntityProfileMasterProfileIdSelector,
    currentEntityProfileChildrenIdsSelector,
    currentEntityProfileLinkedMasterProfileIdsSelector,
    warrantViewModelBySubjectIdSelector,
    warrantViewModelsBySubjectMasterIdSelector,
    (
        profileId,
        masterProfileId,
        childrenIds,
        linkedMasterProfileIds,
        warrantViewModelsBySubjectId,
        warrantViewModelsBySubjectMasterId
    ) => {
        const localProfileWarrantViewModels = flatMap([profileId, ...childrenIds], (childId) =>
            warrantViewModelsBySubjectId(childId)
        );

        // masterProfileId exists when we're viewing a contexted profile, but does not exist when we're viewing a master profile.
        if (masterProfileId) {
            return localProfileWarrantViewModels;
        }

        const consortiumProfileWarrantViewModels = flatMap(linkedMasterProfileIds, (masterId) =>
            warrantViewModelsBySubjectMasterId(masterId)
        );
        return localProfileWarrantViewModels.concat(consortiumProfileWarrantViewModels);
    }
);

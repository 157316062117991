import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { flowRight, min, pick } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import taskResource from '../../tasks/core/resources/taskResource';

import TaskTable, {
    columnKeys,
    TaskHeaderRow,
    TasksHeaderTableColumn,
} from '../../tasks/core/components/TaskTable';
import { withTasksView } from '../../tasks/core/data';
import { Button } from '../../core/components/Button';
import { MAX_MY_TASKS, MY_TASKS_INTERVAL } from '../configuration';
import { NoDataBlock, ShowMoreButton, DashboardCard } from './DashboardSection';
import { DashboardRightCardsLoader } from './DashboardLoaders';

const strings = componentStrings.personalDashboard.MyTasks;

// configures table to display up to MAX_MY_TASKS number of rows AND hides Table pagination
const paginationOptions = { pageSize: MAX_MY_TASKS };
const defaultColumnConfigurations = [
    { key: columnKeys.title, width: 312 },
    { key: columnKeys.dueDateUtc, width: 115 },
    { key: columnKeys.source, width: 192 },
];

const MyTasksTable = styled(TaskTable)`
    min-width: min-content;

    ${/* sc-selector */ TasksHeaderTableColumn} {
        border-right: unset;
        height: 35px;
    }

    && ${/* sc-selector */ TaskHeaderRow} {
        box-shadow: unset;
    }
`;

const MyTasksContent = flowRight(
    withRouter,
    connect(null, { withTasksView })
)(function MyTasksContent({ router, withTasksView, hideLoadingBar }) {
    const [paginationParams, setPaginationParams] = useState({ from: 0, size: MY_TASKS_INTERVAL });
    const [tasks, setTasks] = useState([]);
    const [queryTotalCount, setQueryTotalCount] = useState();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const taskEnhancementsEnabled = applicationSettings['RMS_TASK_ENHANCEMENTS_ENABLED'];
    const columnConfigurations = taskEnhancementsEnabled
        ? [
              { key: columnKeys.title, width: 150 },
              { key: columnKeys.source, width: 150 },
              { key: columnKeys.dueDateUtc, width: 100 },
              { key: columnKeys.priorityAttrId, width: 95 },
              { key: columnKeys.typeAttrId, width: 100 },
              { key: columnKeys.status, width: 100 },
          ]
        : defaultColumnConfigurations;
    const loadMyTasks = useCallback(
        () => taskResource.getMyTasks(paginationParams, { hideLoadingBar }),
        [paginationParams, hideLoadingBar]
    );
    const onResourceSuccess = useCallback(
        (result) => {
            // tasks are stored in local component state
            setTasks(result.tasks);
            setQueryTotalCount(result.totalCount);
            // other domains are stored in global redux state
            withTasksView(pick(result, 'attributes', 'attachments'));
        },
        [withTasksView]
    );

    const { callResource, loading } = useResourceDeferred(loadMyTasks, onResourceSuccess);
    useEffect(() => {
        callResource();
    }, [callResource]);

    const showMore = useCallback(() => {
        const maxDisplayCount = min([queryTotalCount, MAX_MY_TASKS]);

        if (tasks.length >= maxDisplayCount) {
            router.push('/tasks');
            return;
        }

        setPaginationParams((prev) => ({ from: prev.from + prev.size, size: prev.size }));
    }, [queryTotalCount, tasks, router]);

    if (loading.errorMessage) {
        return <NoDataBlock>{strings.loadError}</NoDataBlock>;
    }

    if (tasks.length <= 0 && !loading.isLoading) {
        return <NoDataBlock>{strings.noResults}</NoDataBlock>;
    }

    return (
        <div>
            {loading.isLoading ? (
                <DashboardRightCardsLoader />
            ) : (
                <>
                    <MyTasksTable
                        columnConfigurations={columnConfigurations}
                        hiddenColumnKeys={[columnKeys.assigneeRoleId]}
                        paginationOptions={paginationOptions}
                        tasks={tasks}
                        canDelete={false}
                        onSave={callResource}
                        canSortColumns={false}
                    />
                    {queryTotalCount > MY_TASKS_INTERVAL && (
                        <ShowMoreButton isSticky onClick={showMore}>
                            {strings.showMore}
                        </ShowMoreButton>
                    )}
                </>
            )}
        </div>
    );
});

function MyTasks({ dragHandleProps, isDragging }) {
    return (
        <DashboardCard
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
            actions={
                <Button isTextTransformNone variant="ghost" asLink to="/tasks">
                    {strings.viewAll}
                </Button>
            }
            title={strings.title}
        >
            <MyTasksContent hideLoadingBar={true} />
        </DashboardCard>
    );
}

export default MyTasks;

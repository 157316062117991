import { AttributeTypeEnum, EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { filter } from 'lodash';
import styled from 'styled-components';
import { lifecycleOptions } from 'markformythree';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    DISPLAY_ONLY_ORGANIZATION_PROFILE_PHONE_LABEL,
    DISPLAY_ONLY_ORGANIZATION_PROFILE_EMAIL_LABEL,
    DISPLAY_ONLY_ORGANIZATION_PROFILE_IDENTIFIER_LABEL,
    DISPLAY_ONLY_ORGANIZATION_PROFILE_ATTACHMENT_LABEL,
} from '~/client-common/core/enums/universal/fields';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { responsiveStack } from '../../styles/mixins';
import { createOrganizationProfileFormConfiguration } from '../state/forms/createOrganizationProfileFormConfiguration';
import { fetchAndBuildOrganizationProfileFormModel } from '../state/ui';
import FocusWrapper from '../../components/FocusWrapper';

import formsRegistry from '../../../../core/formsRegistry';

// components
import ArbiterForm from '../../markformythree-arbiter/ArbiterForm';
import { ArbiterMFTAttributeSelect as _ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTText as _ArbiterMFTText } from '../../forms/components/Text';
import FormGroup from '../../forms/components/FormGroup';
import FormRow from '../../forms/components/FormRow';
import { MFTNItems } from '../../forms/components/NItems';
import { DashedDivider } from '../../../../legacy-redux/components/core/Divider';
import InlineAttachmentsUploader from '../../../attachments/core/components/InlineAttachmentsUploader';
import { InlineAttachmentsEntityProfileUploader } from '../../names/components/InlineAttachmentsEntityProfileUploader';
import testIds from '../../../../core/testIds';
import {
    renderOrganizationPhoneNumbersNItems,
    renderOrganizationEmailsNItems,
    renderAddButton,
    renderIdentifiersNItems,
} from '../../names/components/nameFormNItemsRenderers';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';

import {
    uploadingFilesSelector,
    updateUploadingFiles,
    clearUploads,
    clearDeletedAttachments,
} from '../../../attachments/core/state/ui/inlineAttachmentsUploader';
import { OrganizationProfileFormAddressFields } from './OrganizationProfileFormAddressFields';

// constants

const { OrganizationProfileForm: strings } = componentStrings.core;

const ArbiterMFTText = styled(_ArbiterMFTText)`
    width: 100%;
`;

const ArbiterMFTAttributeSelect = styled(_ArbiterMFTAttributeSelect)`
    width: 100%;
`;

const StyledInlineAttachmentsUploader = styled(InlineAttachmentsUploader)`
    align-items: flex-start;
    flex-direction: column;
`;

const CoreDetailsWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    gap: var(--arc-space-4);
    ${responsiveStack()}
`;

class OrganizationProfileFormUnconnected extends React.Component {
    state = {
        // Profile loading is now asyncronous
        initialState: null,
    };

    componentDidMount() {
        const onSuccess = (formModel) => this.setState({ initialState: formModel });

        this.formConfiguration = createOrganizationProfileFormConfiguration();
        this.props.fetchAndBuildOrganizationProfileFormModel({
            isEditingExistingOrganization: this.props.isEditingExistingOrganization,
            organizationProfileId: this.props.id,
            ownerId: this.props.ownerId,
            ownerType: this.props.ownerType,
            onSuccess,
            linkType: this.props.linkType,
        });
    }

    handleFileUploadChanges(uploadingFiles) {
        const activeUploadingFiles = filter(
            uploadingFiles,
            ({ file }) => !file.isDeleted && (file.isLoading || file.uploadFailed)
        );

        this.props.setSaveButtonDisabled(activeUploadingFiles.length > 0);

        const failedUploadingFiles = filter(
            uploadingFiles,
            ({ file }) => file.uploadFailed && !file.isDeleted
        );

        if (failedUploadingFiles.length === 0) {
            this.props.removePanelErrorMessage(
                strings.Attachments.attachmentUploadFailedPleaseTryAgain
            );
        } else {
            this.props.appendPanelErrorMessageIfNotExists(
                strings.Attachments.attachmentUploadFailedPleaseTryAgain
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.uploadingFiles !== prevProps.uploadingFiles) {
            this.handleFileUploadChanges(this.props.uploadingFiles);
        }
    }

    componentWillUnmount() {
        // reset `InlineAttachmentsUploader`'s state as it's global
        this.props.updateUploadingFiles([]);
        this.props.clearUploads();
        this.props.clearDeletedAttachments();
        formsRegistry.unregister(RefContextEnum.FORM_ORGANIZATION_SIDE_PANEL.name);
    }

    renderForm = (form) => {
        return (
            <FocusWrapper data-test-id={testIds.NAME_SIDE_PANEL_FORM_WRAPPER}>
                <CoreDetailsWrapper>
                    <StyledInlineAttachmentsUploader
                        entityId={this.props.id}
                        entityType={EntityTypeEnum.ORGANIZATION_PROFILE.name}
                        linkType={LinkTypesEnum.ORGANIZATION_PROFILE_PHOTO}
                        // We do not utilize any callbacks here because they will fire before we
                        // we have received updated props. This leads to an issue with the detection
                        // of currently running uploads because we are always lagging behing the component.
                        // Instead we solely rely on `this.props.uploadingFiles` to determine the current state
                    >
                        {(inlineAttachmentsRenderProps) => (
                            <InlineAttachmentsEntityProfileUploader
                                {...inlineAttachmentsRenderProps}
                            />
                        )}
                    </StyledInlineAttachmentsUploader>
                    <FormGroup>
                        <ArbiterMFTText path="name" />
                        <ArbiterMFTAttributeSelect
                            path="organizationTypeAttrId"
                            attributeType={AttributeTypeEnum.ORGANIZATION_TYPE.name}
                        />
                        <ArbiterMFTAttributeSelect
                            path="industryAttrId"
                            attributeType={AttributeTypeEnum.INDUSTRY.name}
                        />
                        <FormRow hasIndent>
                            <ArbiterMFTText path="industryOther" length="lg" />
                        </FormRow>
                    </FormGroup>
                </CoreDetailsWrapper>
                <DashedDivider />
                <div className="person-org-side-panel-phones">
                    <MFTNItems
                        path="phoneNumbers"
                        addItemOnEmpty={false}
                        addText={this.props.formatFieldByName(
                            DISPLAY_ONLY_ORGANIZATION_PROFILE_PHONE_LABEL
                        )}
                        hideAddButtonOnEmptyItem={true}
                        showLabelForFirstItemOnly={true}
                        childFieldKeys={['phoneType', 'displayNumber']}
                        render={renderOrganizationPhoneNumbersNItems}
                        renderRemoveButton={undefined}
                        renderAddButton={renderAddButton}
                    />
                </div>
                <OrganizationProfileFormAddressFields form={form} entityId={this.props.id} />
                <DashedDivider />
                <div className="person-org-side-panel-emails">
                    <MFTNItems
                        path="emails"
                        addItemOnEmpty={false}
                        addText={this.props.formatFieldByName(
                            DISPLAY_ONLY_ORGANIZATION_PROFILE_EMAIL_LABEL
                        )}
                        hideAddButtonOnEmptyItem={true}
                        showLabelForFirstItemOnly={true}
                        childFieldKeys={['emailType', 'emailAddress']}
                        render={renderOrganizationEmailsNItems}
                        renderRemoveButton={undefined}
                        renderAddButton={renderAddButton}
                    />
                </div>
                <DashedDivider />
                <div className="person-org-side-panel-identifiers">
                    <MFTNItems
                        path="identifiers"
                        addItemOnEmpty={false}
                        addText={this.props.formatFieldByName(
                            DISPLAY_ONLY_ORGANIZATION_PROFILE_IDENTIFIER_LABEL
                        )}
                        hideAddButtonOnEmptyItem={true}
                        childFieldKeys={['nameIdentifierTypeAttrId', 'identifier']}
                        render={renderIdentifiersNItems}
                        renderAddButton={renderAddButton}
                        renderRemoveButton={undefined}
                    />
                </div>
                <DashedDivider />
                <InlineAttachmentsUploader
                    entityId={this.props.id}
                    entityType={EntityTypeEnum.ORGANIZATION_PROFILE.name}
                    linkType={LinkTypesEnum.ORGANIZATION_PROFILE_ATTACHMENT}
                    buttonText={this.props.formatFieldByName(
                        DISPLAY_ONLY_ORGANIZATION_PROFILE_ATTACHMENT_LABEL
                    )}
                    // We do not utilize any callbacks here because they will fire before we
                    // we have received updated props. This leads to an issue with the detection
                    // of currently running uploads because we are always lagging behing the component.
                    // Instead we solely rely on `this.props.uploadingFiles` to determine the current state
                />
            </FocusWrapper>
        );
    };
    render() {
        return this.state.initialState ? (
            <ArbiterForm
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                name={RefContextEnum.FORM_ORGANIZATION_SIDE_PANEL.name}
                context={RefContextEnum.FORM_ORGANIZATION_SIDE_PANEL.name}
                configuration={this.formConfiguration}
                render={this.renderForm}
                initialState={this.state.initialState}
            />
        ) : (
            <SimpleLoading />
        );
    }
}

export const OrganizationProfileForm = connect(
    createStructuredSelector({
        uploadingFiles: uploadingFilesSelector,
        formatFieldByName: formatFieldByNameSelector,
    }),
    {
        fetchAndBuildOrganizationProfileFormModel,
        updateUploadingFiles,
        clearUploads,
        clearDeletedAttachments,
    }
)(OrganizationProfileFormUnconnected);

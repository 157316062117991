import { FormEnum } from '@mark43/rms-api';
import { WARRANT_WARRANT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const changeWarrantNumberFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'warrantNumber',
    },
    {
        key: 'newWarrantNumber',
        fieldName: WARRANT_WARRANT_NUMBER,
    },
]);

const changeWarrantNumberForm = createFormModule({
    formName: FormEnum.CHANGE_WARRANT_NUMBER.name,
    fieldViewModels: changeWarrantNumberFormFieldViewModels,
});

/**
 * Module of the change warrant number form, for changing an existing warrant's
 * `warrantNumber`.
 * @type {Object}
 */
export default changeWarrantNumberForm;

import Promise from 'bluebird';
import {
    camelCase,
    filter,
    findKey,
    forEach,
    get,
    head,
    includes,
    isArray,
    isEmpty,
    isString,
    map,
    mapKeys,
    pick,
    pickBy,
    reduce,
    debounce as throttle,
} from 'lodash';
import { actions, modelReducer, formReducer } from 'react-redux-form';
import { createSelector } from 'reselect';
import invariant from 'invariant';
import { ruleTypes } from 'arbiter-mark43';

// selectors
import { nonInternalStaticallyHiddenFieldConfigurationsInContextSelector } from '../../../../../core/domain/field-configuration-contexts/state/data';

// helpers
import { formDataIsEmpty } from '../../../../../helpers/formHelpers';
import formDataToArbiterData from '../../../../../core/arbiter-utils/formDataToArbiterData';
import produceRuleEffectsRRF from '../../../../../core/arbiter-utils/produceRuleEffectsRRF';

// https://github.com/jshanson7/throttle-action/blob/master/src/index.js
function throttleAction(action, wait, options) {
    // for options see: https://lodash.com/docs/4.17.4#throttle
    const throttled = throttle(
        (dispatch, actionArgs) => dispatch(action(...actionArgs)),
        wait,
        options
    );

    // see: https://github.com/gaearon/redux-thunk
    const thunk = (...actionArgs) => (dispatch) => throttled(dispatch, actionArgs);

    // provide hook to _.throttle().cancel() to cancel any trailing invocations
    thunk.cancel = throttled.cancel;

    return thunk;
}

function createParseFieldProperties(propertyName) {
    // factory function
    return function parse(fieldViewModels, fieldsetTypes, multiTypes = []) {
        const propertyValues = reduce(
            fieldViewModels,
            (accPropertyValues, fieldViewModel) => {
                const { type, key, fields, fieldName } = fieldViewModel;
                let fieldPropertyValues = {};
                if (includes(fieldsetTypes, type)) {
                    fieldPropertyValues = {
                        ...mapKeys(
                            parse(fields, fieldsetTypes, multiTypes),
                            (fieldPropertyValues, path) =>
                                includes(multiTypes, type) ? `${key}[].${path}` : `${key}.${path}`
                        ),
                        ...(fieldName && propertyName === 'fieldName' ? { [key]: fieldName } : {}),
                    };
                } else if (fieldViewModel[propertyName]) {
                    fieldPropertyValues = {
                        [key]: fieldViewModel[propertyName],
                    };
                }

                return {
                    ...accPropertyValues,
                    ...fieldPropertyValues,
                };
            },
            {}
        );

        return !isEmpty(propertyValues) ? propertyValues : undefined;
    };
}

const parseFieldValidators = createParseFieldProperties('validators');

/**
 * Convert validators from the shapes that our form module pattern accepts to
 *   the shape that React Redux Form accepts.
 * @param  {Object}   formValidators
 * @param  {Object}   fieldViewModels
 * @param  {string[]} fieldsetTypes   Members of fieldTypeClientEnum that represent
 *   fieldsets. Used for recursion in this function.
 * @return {Object|undefined} If there are no validators at all, return
 *   undefined, not an empty object.
 */
export function buildValidators(formValidators, fieldViewModels, fieldsetTypes) {
    const validators = {
        ...(formValidators ? { '': formValidators } : {}),
        ...parseFieldValidators(fieldViewModels, fieldsetTypes),
    };

    return !isEmpty(validators) ? validators : undefined;
}

/**
 * Convert async validators from the shapes that our form module pattern accepts
 *   to the shape that React Redux Form accepts.
 * @param  {Object}   fieldViewModels
 * @param  {string[]} fieldsetTypes   Members of fieldTypeClientEnum that represent
 *   fieldsets. Used for recursion in this function.
 * @return {Object|undefined} If there are no async validators at all, return
 *   undefined, not an empty object.
 */
const buildAsyncValidators = createParseFieldProperties('asyncValidators');

const buildFieldNames = createParseFieldProperties('fieldName');

function parseErrorMessage(errors) {
    if (typeof errors === 'string') {
        return errors;
    } else if (typeof errors === 'object') {
        return head(filter(errors, isString));
    }
    return;
}

/**
 * Form module factory-factory for form modules across both RMS and CAD. This should
 * not be called directly. Instead use createFormModule in the respective CAD/RMS directories
 * which calls this function and fills in the app-specific parameters.
 * @param {string[]} fieldsetTypes Enum of the different fieldsets used in the app. See fieldTypeClientEnum in client/
 * @param {string[]} multiTypes Field types whose data is an array. Must be a subset of fieldsetTypes
 * @param {Object} validationStrings Config of validation strings
 * @param {Function} formModelsSelector Selector for the base path of form data models
 * @param {Function} allFormUiSelector Selector for form-ui in state
 * @param {Function} joinFormModelPath Helper from reactReduxFormHelpers build a path to a field in the model state
 * @param {Function} validationRunnerAction Action to dispatch to run validation
 */
export default function createCreateFormModule(
    fieldsetTypes,
    multiTypes,
    validationStrings,
    formModelsSelector,
    allFormUiSelector,
    joinFormModelPath,
    validationRunnerAction,
    getArbiter
) {
    // return a default function for `convertToFormModel`
    function defaultConvertToFormModel(fieldViewModels) {
        return (sourceModel) => pick(sourceModel, map(fieldViewModels, 'key'));
    }

    // the default function for `convertFromFormModel`
    const defaultConvertFromFormModel = (sourceModel, formModel) => ({
        ...sourceModel,
        ...formModel,
    });

    /**
     * Create a module for a form which includes action creators, reducers, and
     *   other properties relevant to the form.
     * @param {string}   options.context  Arbiter validation context.  Maps to a value in
     *   RefContextEnum.
     * @param {string}   options.formName Form name in constant case. Should be
     *   a value from {@link client-common/core/enums/client/formClientEnum.js}.
     * @param {Object}   [options.fieldViewModels]
     * @param {Object}   [options.formFalidators] Form-wide validators that
     *   apply to multiple fields at once. Validators that apply to individual
     *   fields belong in `fieldViewModels`, not here.
     * @param {Object}   [options.asyncValidators] Async validators for all
     *   fields.
     * @param {function} [options.convertToFormModel] Function that converts the
     *   source model (usually data state) to form model state.
     * @param {function} [options.convertFromFormModel] Function that converts
     *   form model state to the form model's source model (usually data state).
     * @param {function} [options.ruleRunnerConfigurationSelector] Function specifies an
     *   `effectsConfiguration` for producing effects after running rules.
     * @return   {Object}
     * @property {string} formName
     * @property {string} convertToFormModel
     * @property {string} convertFromFormModel
     * @property {string} path Relative path to the form model/ui state. This is
     *   the form name in camelCase.
     * @property {Object} validators
     * @property {Object} asyncValidators
     * @property {Object} actionCreators
     * @property {Object} actionCreators.change Action creator that takes in a
     *   data object for the new form model.
     * @property {Object} actionCreators.submit Action creator that takes in a
     *   callback, which should return a promise. The RRF submit action gets
     *   dispatched when the promise resolves/rejects.
     * @property {Object} actionCreators.validate Validate the form, which means
     *   validation error messages will appear if the form is invalid.
     * @property {Object} actionCreators.setValidity Action creator that changes
     *   the valid/invalid state at the given path.
     * @property {Object} actionCreators.reset Action creator that clears the
     *   whole form or a specific path within the form (optional).
     * @property {Object} reducers
     * @property {Object} selectors
     */
    function createFormModule({
        context,
        formName,
        fieldViewModels,
        formValidators,
        convertToFormModel = defaultConvertToFormModel(fieldViewModels),
        convertFromFormModel = defaultConvertFromFormModel,
        validationContext = { form: formName },
        initialState,
        ruleRunnerConfigurationSelector,
    }) {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_Operators#Bitwise_XOR
        invariant(
            !!context ^ !!formName,
            'Must provide either context or formName to createFormModule'
        );

        const resolvedFormName = formName || context;
        const modern = !!context;
        const path = camelCase(resolvedFormName); // relative path to form model/ui state
        const modelPath = joinFormModelPath(true, path);
        const validators = buildValidators(formValidators, fieldViewModels, fieldsetTypes);
        const asyncValidators = buildAsyncValidators(fieldViewModels, fieldsetTypes);
        const actionTypes = buildActionTypes(resolvedFormName);
        const selectors = buildSelectors(path, modern);
        const fieldNamesForValidators = buildFieldNames(fieldViewModels, fieldsetTypes, multiTypes);
        const fieldNames = buildFieldNames(fieldViewModels, fieldsetTypes);
        const validate = modern
            ? buildArbiterValidationActionCreator(
                  modelPath,
                  context,
                  selectors,
                  fieldViewModels,
                  ruleRunnerConfigurationSelector
              )
            : buildValidateActionCreator(
                  modelPath,
                  validationContext,
                  validators,
                  selectors,
                  fieldNamesForValidators
              );
        const throttledValidate = throttleAction(validate, 150, { leading: true, trailing: true });

        return {
            // original properties
            formName,
            context,
            convertToFormModel,
            convertFromFormModel,
            // computed properties
            modern,
            path,
            fieldNames,
            validationContext,
            validators,
            asyncValidators,
            actionCreators: {
                change: buildChangeActionCreator(modelPath, convertToFormModel),
                changePath: buildChangePathActionCreator(modelPath),
                push: buildPushActionCreator(modelPath),
                remove: buildRemoveActionCreator(modelPath),
                setFormPending: buildSetFormPendingActionCreator(modelPath),
                setTouched: buildSetTouchedActionCreator(modelPath),
                submit: buildSubmitActionCreator(modelPath, selectors, validate, modern),
                validate,
                clearHiddenFields: buildClearHiddenFieldsActionCreator(
                    modelPath,
                    context,
                    selectors,
                    fieldViewModels,
                    validate
                ),
                throttledValidate,
                setValidity: buildSetValidityActionCreator(modelPath),
                reset: buildResetActionCreator(actionTypes.reset, modelPath),
                resetValidity: buildResetValidityActionCreator(modelPath),
            },
            reducers: {
                formModelReducer: buildFormModelReducer(modelPath, initialState),
                formUiReducer: buildFormUiReducer(modelPath, initialState),
            },
            selectors,
        };
    }

    function buildActionTypes(resolvedFormName) {
        return {
            reset: `RESET_${resolvedFormName}`,
        };
    }

    function buildChangeActionCreator(modelPath, convertToFormModel) {
        return (...args) => (dispatch) => {
            // change all fields in the form
            dispatch(actions.change(modelPath, convertToFormModel(...args)));

            // reset the form ui state, which will clear previous any
            // validation error messages
            // this resets pristine, which is used in logic for validation
            dispatch(actions.setInitial(modelPath));
        };
    }

    function buildChangePathActionCreator(modelPath) {
        return (subPath, newValue, shouldResetFormUi = false) => (dispatch) => {
            // change the value at the specified path
            dispatch(actions.change(joinFormModelPath(false, modelPath, subPath), newValue));

            if (shouldResetFormUi) {
                // reset the form ui state, which will clear previous any
                // validation error messages
                // this resets pristine, which is used in logic for validation
                dispatch(actions.setInitial(modelPath));
            }
        };
    }

    function buildPushActionCreator(modelPath) {
        return (subPath, item) => (dispatch) => {
            // add item to the specified path
            dispatch(actions.push(joinFormModelPath(false, modelPath, subPath), item));
        };
    }

    function buildRemoveActionCreator(modelPath) {
        return (subPath, index) => (dispatch) => {
            // remove the index at the specified path
            dispatch(actions.remove(joinFormModelPath(false, modelPath, subPath), index));
        };
    }

    function buildClearHiddenFieldsActionCreator(
        modelPath,
        context,
        selectors,
        fieldViewModels,
        validate
    ) {
        return () => (dispatch, getState, dependencies) => {
            dispatch(validate({ ruleTypes: [ruleTypes.HIDE] }, { clearHiddenFields: true }));

            const state = getState();
            const formModel = selectors.formModelSelector(state);
            const arbiterData = formDataToArbiterData(
                state,
                dependencies,
                formModel,
                fieldViewModels
            );
            const hiddenFieldConfigurations = nonInternalStaticallyHiddenFieldConfigurationsInContextSelector(
                state
            )(context);
            forEach(hiddenFieldConfigurations, (fieldConfiguration) => {
                const fieldData = arbiterData[fieldConfiguration.fieldName];
                if (!fieldData) {
                    return;
                }

                // if the field is flat, `fieldData` is an object; if the field is part of an NItems,
                // then it is an array of objects.
                if (isArray(fieldData)) {
                    forEach(fieldData, (item) => {
                        const fieldPath = joinFormModelPath(false, modelPath, item.meta.path);
                        dispatch(actions.change(fieldPath, undefined));
                    });
                } else {
                    const fieldPath = joinFormModelPath(false, modelPath, fieldData.meta.path);
                    dispatch(actions.change(fieldPath, undefined));
                }
            });
        };
    }

    function buildSubmitActionCreator(modelPath, selectors, validate, modern) {
        return (callback, options = {}) => {
            const { forceSubmit = false } = options;

            return (dispatch, getState) => {
                // reset validity of the form, and let validation runners update the validity
                dispatch(actions.resetValidity(modelPath));

                // if we're using arbiter validation, we're going to fire the rule runner
                // configured to null out values on all hidden fields before doing real validation
                if (modern) {
                    dispatch(actions.setPending(modelPath, true));
                    dispatch(
                        validate(
                            {
                                // only run hide rules
                                ruleTypes: [ruleTypes.HIDE],
                            },
                            {
                                clearHiddenFields: true,
                            }
                        )
                    );
                }

                const panelErrors = dispatch(validate());

                if (
                    panelErrors === true ||
                    (modern && typeof panelErrors === 'object' && panelErrors.success === true)
                ) {
                    const formModel = selectors.formModelSelector(getState());
                    const result = callback(formModel);
                    const promise = result
                        ? Promise.resolve(result)
                        : Promise.reject(result).catch(() => false);
                    // the form's submitted state gets updated after this
                    // promise is resolved or rejected
                    dispatch(actions.submit(modelPath, promise));
                    return promise;
                } else if (forceSubmit) {
                    const formModel = selectors.formModelSelector(getState());
                    const result = callback(formModel);
                    const promise = result
                        ? Promise.reject(result)
                        : Promise.reject(result).catch(() => false);
                    // the form's submitted state gets updated after this
                    // promise is resolved or rejected
                    dispatch(actions.submit(modelPath, promise));
                    return Promise.reject(panelErrors);
                } else {
                    return Promise.reject(panelErrors);
                }
            };
        };
    }

    function buildArbiterValidationActionCreator(
        modelPath,
        context,
        selectors,
        fieldViewModels,
        ruleRunnerConfigurationSelector
    ) {
        return (runConfiguration, effectsConfiguration) => (dispatch, getState, dependencies) => {
            const state = getState();
            const ruleRunnerConfiguration = ruleRunnerConfigurationSelector
                ? ruleRunnerConfigurationSelector(state)({ effectsConfiguration })
                : { effectsConfiguration };
            // we clear all validation errors before running validation
            // dispatch(actions.setInitial(modelPath));
            // the form has to be touched to show any validation errors
            dispatch(actions.setTouched(modelPath));
            const formModel = selectors.formModelSelector(getState());
            // convert our form's data to a format accepted by arbiter
            const arbiterData = formDataToArbiterData(
                state,
                dependencies,
                formModel,
                fieldViewModels
            );
            // run all rules for our form and get the results
            const rulesResults = getArbiter().runRules(
                context,
                arbiterData,
                formModel,
                runConfiguration
            );
            // the call to produceRuleEffectsRRF also side effectfully performs
            // effects on specific fields as well as returning a list of panelErrors
            const validationResult = dispatch(
                produceRuleEffectsRRF(
                    modelPath,
                    context,
                    selectors,
                    rulesResults,
                    ruleRunnerConfiguration.effectsConfiguration
                )
            );

            return validationResult;
        };
    }

    function buildValidateActionCreator(
        modelPath,
        validationContext,
        validators,
        selectors,
        allFieldNames
    ) {
        return (subPath) => (dispatch, getState) => {
            // the form has to be touched to show any validation errors
            dispatch(actions.setTouched(modelPath));

            let formModel = selectors.formModelSelector(getState());
            let fieldNames = allFieldNames;

            if (!!subPath) {
                // fieldNames are keyed by the full form path. the form model shape has to match
                formModel = { [subPath]: selectors.formModelByPathSelector(getState())(subPath) };
                fieldNames = pickBy(allFieldNames, (fieldName, path) => includes(path, subPath));
            }

            // native validators
            // must use the undocumented validateFields() instead of
            // validate() to validate multiple fields; this updates the
            // `valid` state used below.
            dispatch(actions.validateFields(modelPath, validators));
            // NOTE: we don't use the same instance of state here because it may have changed
            // from the validation above
            const valid = selectors.formPathIsValidSelector(getState())();

            // run db rules - these errors will override errors set by the above,
            // so db rule err messages are shown first
            let panelErrors = [];
            if (validationRunnerAction) {
                panelErrors = dispatch(
                    validationRunnerAction(validationContext, formModel, fieldNames, modelPath)
                );
            }

            if (valid && !panelErrors.length) {
                return true;
            } else {
                return panelErrors;
            }
        };
    }

    function buildSetValidityActionCreator(modelPath) {
        return (path, validity, options) => (dispatch) => {
            path = joinFormModelPath(false, modelPath, path);
            dispatch(actions.setDirty(path));
            dispatch(actions.setValidity(path, validity, options));
        };
    }

    function buildResetActionCreator(actionType, modelPath) {
        const resetRaw = (path) => ({
            type: actionType,
            payload: path,
        });

        return (path) => (dispatch) => {
            path = joinFormModelPath(false, modelPath, path);
            dispatch(actions.reset(path));
            dispatch(resetRaw(path));
        };
    }

    function buildResetValidityActionCreator(modelPath) {
        return (path, omitKeys = false) => (dispatch) => {
            path = joinFormModelPath(false, modelPath, path);
            dispatch(actions.resetValidity(path, omitKeys));
        };
    }

    function buildSetFormPendingActionCreator(modelPath) {
        return () => (dispatch) => dispatch(actions.setPending(modelPath, true));
    }

    function buildSetTouchedActionCreator(modelPath) {
        return () => (dispatch) => dispatch(actions.setTouched(modelPath));
    }

    /**
     * @see https://davidkpiano.gitbooks.io/react-redux-form/content/model_reducer.html
     */
    function buildFormModelReducer(modelPath, initialState) {
        return modelReducer(modelPath, initialState);
    }

    /**
     * @see https://davidkpiano.gitbooks.io/react-redux-form/content/form_reducer.html
     */
    function buildFormUiReducer(modelPath, initialState) {
        return formReducer(modelPath, initialState);
    }

    function buildSelectors(formPath, modern) {
        const parseRelativePath = (path) => path.replace(`${formPath}.`, '');

        /**
         * Model state of a form, which contains its currently filled field values.
         */
        const formModelSelector = createSelector(
            formModelsSelector,
            (formModels) => formModels[formPath]
        );

        /**
         * UI state of a form, which contains whether the form and its fields are
         *   touched, dirty, passing validation, etc.
         */
        const formUiSelector = createSelector(allFormUiSelector, (formUis) => formUis[formPath]);

        /**
         * Get the current value of a field, or the model of a form.
         * @param  {string} path Path to the field model in state, relative to the
         *   form path. Example: `'endDateUtc'`. Note the form name is not included.
         * @return {*}
         */
        const formModelByPathSelector = createSelector(formModelSelector, (formModel) => (path) =>
            get(formModel, parseRelativePath(path))
        );

        const createFormModelSelector = (path) => {
            return createSelector(formModelSelector, (formModel) =>
                get(formModel, path ? parseRelativePath(path) : '')
            );
        };

        /**
         * Return the form ui state for the given path within a form
         * @param {string} path Path to the field model in state.
         * @return {Object}
         */
        const formUiByPathSelector = createSelector(formUiSelector, (formUi) => (path) => {
            const intermediate = get(formUi, parseRelativePath(path));
            if (!intermediate) {
                return undefined;
            } else if (intermediate.$form) {
                return intermediate.$form;
            } else {
                return intermediate;
            }
        });

        /**
         * Return a selector that gets the current value of a field or the model of
         *   a form.
         * @param  {string} path Path to the field model in state, relative to the
         *   form path.
         * @return {function}
         */
        const buildFormModelSelectorByPath = (path) =>
            createSelector(formModelSelector, (formModel) =>
                get(formModel, parseRelativePath(path))
            );

        const formFieldIsTouchedSelector = createSelector(
            formUiByPathSelector,
            (formUiByPath) => (fieldPath) => !!get(formUiByPath(fieldPath), 'touched')
        );

        /**
         * Return whether the form or field at the given path has been touched. A
         *   field is considered touched when it is first blurred, or when the form
         *   is touched. A form is considered touched when any field inside it is
         *   touched, or when it is submitted.
         * @param  {string} path Path to the form/field model in state.
         * @return {boolean}
         */
        const formPathIsTouchedSelector = createSelector(
            formUiSelector,
            formFieldIsTouchedSelector,
            (formUi, formFieldIsTouched) => (path) =>
                formFieldIsTouched(path) ||
                // whether the parent form is touched
                !!get(formUi, '$form.touched')
        );

        /**
         * Return whether the form or field at the given path has been interacted with.
         *   Whenever a field is set to pristine, the entire form is set to:
         *   pristine if all other fields are pristine
         *   otherwise, dirty.
         * @param  {string} path Path to the form/field model in state.
         * @return {boolean}
         */
        const formPathIsPristineSelector = createSelector(
            formUiSelector,
            formUiByPathSelector,
            (formUi, formUiByPath) => (path) =>
                // whether the field itself is pristine
                !!get(formUiByPath(path), 'pristine') ||
                // whether the parent form is pristine
                !!get(formUi, '$form.pristine')
        );

        /**
         * Return whether the form or field at the given path is passing validation.
         * @param  {string} path Path to the form/field model in state.
         * @return {boolean}
         */
        const formPathIsValidSelector = createSelector(
            formUiSelector,
            formUiByPathSelector,
            (formUi, formUiByPath) => (path) =>
                // if path is empty, use the whole form ui; otherwise, use
                // the field ui
                path ? !!get(formUiByPath(path), 'valid') : !!get(formUi, '$form.valid')
        );

        /**
         * Return whether the whole form is pristine.
         * More info: https://readme.mark43.io/guides/products/rms/rms-frontend/rmsfe-form-modules/#when-does-validation-run
         * @type {boolean}
         */
        const formIsPristineSelector = createSelector(
            formPathIsPristineSelector,
            (formPathIsPristine) => formPathIsPristine('')
        );

        /**
         * Return whether the whole form is passing validation
         * @type {boolean}
         */
        const formIsValidSelector = createSelector(formPathIsValidSelector, (formPathIsValid) =>
            formPathIsValid('')
        );

        /**
         * Return an error message for the form or field at the given path, or
         *   `undefined` if there's no error.
         * @param  {string} path Path to the form/field model in state.
         * @return {string|undefined}
         */
        const formErrorMessageByPathSelector = createSelector(
            formUiSelector,
            formUiByPathSelector,
            (formUi, formUiByPath) => (path) => {
                // if path is empty, use the whole form ui; otherwise, use
                // the field ui
                const ui = path ? formUiByPath(path) : get(formUi, '$form');

                if (modern) {
                    if (!ui) {
                        return;
                    }
                    return parseErrorMessage(ui.errors);
                }

                // if the form/field is valid or doesn't exist in state yet,
                // there's no error
                if (!ui || ui.valid) {
                    return;
                }

                // find the first validation rule that is violated
                const invalidRuleKey = findKey(ui.validity, (rule) => !rule);

                // invalidRuleKey looks only at client-only validation rules,
                // and parseErrorMessage gives only the db validation error
                return invalidRuleKey
                    ? validationStrings[invalidRuleKey]
                    : parseErrorMessage(ui.errors);
            }
        );

        /**
         * Whether all the inputs in the form have empty values.
         * @type {boolean}
         */
        const formIsEmptySelector = createSelector(formModelSelector, formDataIsEmpty);

        const formSubmittedSelector = createSelector(
            formUiSelector,
            (formUi) => !!get(formUi, '$form.submitted')
        );

        const formSubmitFailedSelector = createSelector(
            formUiSelector,
            (formUi) => !!get(formUi, '$form.submitFailed')
        );

        const formPendingSelector = createSelector(
            formUiSelector,
            (formUi) => !!get(formUi, '$form.pending')
        );

        const formHasBeenSubmittedSelector = createSelector(
            formSubmittedSelector,
            formSubmitFailedSelector,
            formPendingSelector,
            (formSubmitted, formSubmitFailed, formPending) =>
                formSubmitted || formSubmitFailed || formPending
        );

        return {
            formModelSelector,
            formUiSelector,
            formModelByPathSelector,
            formUiByPathSelector,
            buildFormModelSelectorByPath,
            formPathIsTouchedSelector,
            formFieldIsTouchedSelector,
            formPathIsPristineSelector,
            formPathIsValidSelector,
            formIsPristineSelector,
            formIsValidSelector,
            formErrorMessageByPathSelector,
            formIsEmptySelector,
            formPendingSelector,
            formHasBeenSubmittedSelector,
            createFormModelSelector,
        };
    }
    return createFormModule;
}

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { formatErrorCode } from '~/client-common/helpers/errorHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../core/testIds';
import { currentThemeNameSelector } from '../styles/state';
import lightModeError from '../../../../images/error/error_lightmode.svg';
import darkModeError from '../../../../images/error/error_darkmode.svg';
import themeEnum from '../styles/themeEnum';
import { CustomLink } from './links/Link';

const strings = componentStrings.errorPage;

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center 180px;
    background-image: url('${(props) => props.backgroundImage}');
`;

const TextWrapper = styled.div`
    margin: 260px auto;
    width: ${(props) => props.theme.widths.container}px;
`;

const Title = styled.div`
    line-height: 26px;
    font-size: var(--arc-fontSizes-2xl);
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.darkGrey};
`;

const Body = styled.div`
    margin-top: 20px;
    width: 330px;
    line-height: 15px;
    font-size: var(--arc-fontSizes-sm);
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    color: ${(props) => props.theme.colors.darkGrey};
`;

const mapStateToProps = createStructuredSelector({
    currentThemeName: currentThemeNameSelector,
    applicationSettings: applicationSettingsSelector,
});

function ErrorPage({
    currentThemeName,
    applicationSettings,
    error: { errorCode, errorId } = {},
    router,
}) {
    const backgroundImage =
        currentThemeName === themeEnum.LIGHT_MODE ? lightModeError : darkModeError;
    const useSpecificMessages = applicationSettings.ERROR_PAGE_SPECIFIC_CODE_ENABLED;
    const errorMessage = formatErrorCode(errorCode, useSpecificMessages);
    return (
        <Background backgroundImage={backgroundImage}>
            <TextWrapper>
                <Title data-test-id={testIds.ERROR_TITLE}>{errorMessage.title}</Title>
                <Body data-test-id={testIds.ERROR_BODY}>{errorMessage.body}</Body>
                <Body>{strings.support}</Body>
                {errorId ? <Body>{`Error ID: ${errorId}`}</Body> : null}
                <Body>
                    <CustomLink onClick={() => router.goBack()}>{strings.goBackLink}</CustomLink>
                </Body>
            </TextWrapper>
        </Background>
    );
}

export default compose(withRouter, connect(mapStateToProps))(ErrorPage);

import { PrintingTemplate, PrintResource } from '@mark43/rms-api';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Printing Templates',
    methods: {
        getStrippedPrintingTemplates(includeDisabled = false) {
            return req<PrintResource.GetPrintingFormTemplatesByIds>({
                method: 'GET',
                url: 'print/templates',
                params: {
                    include_contents: false,
                    include_disabled: includeDisabled,
                },
            });
        },
        createPrintingTemplate(template: PrintingTemplate, fileId?: number) {
            return req<PrintResource.CreatePrintingFormTemplate>({
                method: 'POST',
                url: 'print/templates',
                data: template,
                params: {
                    file_id: fileId,
                },
            });
        },
        updatePrintingTemplate(template: PrintingTemplate, fileId?: number) {
            return req<PrintResource.UpdatePrintingFormTemplate>({
                method: 'PUT',
                url: `print/template/${template.id}/`,
                data: template,
                params: {
                    file_id: fileId,
                },
            });
        },
        deletePrintingTemplate(templateId: string | number) {
            return req<PrintResource.DeletePrintingFormTemplate>({
                method: 'DELETE',
                url: `print/template/${templateId}/`,
            });
        },
    },
});

import { RefContextEnum } from '@mark43/rms-api';
import { get, isEmpty, map } from 'lodash';
import { createFormConfiguration, createNItems, _Form } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import {
    ARREST_NIBRS_CODE,
    CHARGE_ID,
    CHARGE_WAS_CITATION_ISSUED,
    CHARGE_CITATION_NUMBER,
    DISPLAY_ONLY_CHARGES_CARD_FORM_CHARGES_N_ITEMS_WRAPPER,
} from '~/client-common/core/enums/universal/fields';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import { nibrsOffenseCodesSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { nibrsOffenseCodeForFirstReportableChargeSelector } from '~/client-common/core/domain/reports/state/ui/charges';
import sortCharges from '~/client-common/core/domain/charges/utils/sortCharges';

import formsRegistry from '../../../../../core/formsRegistry';

import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import { validateMadLibsParameters } from '../../utils/madLibsConfig';

export const formName = RefContextEnum.FORM_CHARGES.name;

export const MADLIBS_FORM_MODEL_KEY = 'MADLIBS';

export const getChargesForm = (index) => formsRegistry.get(formName, index);

export const createChargesForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            arrestNibrsOffenseCodeId: {
                fieldName: ARREST_NIBRS_CODE,
            },
            charges: createNItems({
                fieldName: DISPLAY_ONLY_CHARGES_CARD_FORM_CHARGES_N_ITEMS_WRAPPER,
                fields: {
                    id: {
                        fieldName: CHARGE_ID,
                    },
                    wasCitationIssued: {
                        fieldName: CHARGE_WAS_CITATION_ISSUED,
                    },
                    citationNumber: {
                        fieldName: CHARGE_CITATION_NUMBER,
                    },
                },
            }),
            [MADLIBS_FORM_MODEL_KEY]: createNItems({
                fields: {
                    offenseWordingId: {},
                    chargeId: {},
                    fieldValues: {},
                    offenseWordingTemplateId: {},
                    isValid: {},
                },
            }),
        }),
    });
};

const buildDefaultChargeFormModel = () => {
    return { id: undefined };
};

const buildChargesFormModel = ({ arrestId }) => (dispatch, getState) => {
    const state = getState();
    const chargesForArrest = chargesWhereSelector(state)({ arrestId });
    const charges = isEmpty(chargesForArrest)
        ? [buildDefaultChargeFormModel()]
        : sortCharges({ charges: chargesForArrest });
    return { charges };
};

export const buildChargesCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const arrest = arrestForReportIdSelector(state)(reportId) || {};
    const { id: arrestId, nibrsCode: arrestNibrsOffenseCode } = arrest;
    const arrestNibrsOffenseCodeId = get(arrestNibrsOffenseCode, 'id');
    const { charges } = dispatch(buildChargesFormModel({ arrestId }));

    return {
        arrestNibrsOffenseCodeId,
        charges,
        MADLIBS: [],
    };
};

export const convertChargesMadLibsToFullOffenseWordings = (madLibsFormModel) => {
    return map(madLibsFormModel, (item) => {
        return {
            offenseCodeWording: {
                id: item.offenseWordingId,
                chargeId: item.chargeId,
                offenseCodeWordingTemplateId: item.offenseWordingTemplateId,
            },
            offenseCodeWordingFieldValues: item.fieldValues,
        };
    });
};

export const buildChargesCardBundle = ({ form }) => (dispatch, getState) => {
    const state = getState();
    const applicationSettings = applicationSettingsSelector(state);

    const {
        model: { arrestNibrsOffenseCodeId, MADLIBS: madlibsFormModel, charges },
    } = form.getState();

    const arrestNibrsOffenseCode = nibrsOffenseCodesSelector(state)[arrestNibrsOffenseCodeId];

    const fullOffenseCodeWordings = applicationSettings.RMS_OFFENSE_CODE_WORDING
        ? convertChargesMadLibsToFullOffenseWordings(madlibsFormModel)
        : [];

    return {
        arrestNibrsOffenseCode,
        fullOffenseCodeWordings,
        charges: applicationSettings.RMS_MA_CHARGE_CITATION_ENABLED ? charges : [],
    };
};

export const refreshChargesForm = ({ reportId, index }) => (dispatch) => {
    const form = getChargesForm(index);
    if (!isUndefinedOrNull(form)) {
        const formModel = dispatch(buildChargesCardFormModel({ reportId }));
        form.set('', formModel);
    }
};

export const computeDefaultNibrsOffenseCode = ({ arrestId }) => (dispatch, getState) => {
    const state = getState();
    return nibrsOffenseCodeForFirstReportableChargeSelector(state)(arrestId);
};

export const prefillChargesCardDependentArrestNibrsCodeField = ({ arrestId, formIndex }) => (
    dispatch
) => {
    const form = getChargesForm(formIndex);
    if (!isUndefinedOrNull(form)) {
        const defaultNibrsOffenseCode = dispatch(computeDefaultNibrsOffenseCode({ arrestId }));
        const arrestNibrsOffenseCodeId = get(defaultNibrsOffenseCode, 'id');
        form.set('arrestNibrsOffenseCodeId', arrestNibrsOffenseCodeId);
    }
};

export const refreshChargesCardDependentChargesData = ({ arrestId, formIndex }) => (dispatch) => {
    const form = getChargesForm(formIndex);
    if (!isUndefinedOrNull(form)) {
        const { charges } = dispatch(buildChargesFormModel({ arrestId }));
        form.set('charges', charges);
    }
};

export const initialChargesFormModelMadLibs = ({
    arrestId,
    chargeId,
    offenseWordingTemplateFieldValues,
    offenseWordingId,
    selectedOffenseWordTemplate,
    chargesFormIndex,
}) => (dispatch) => {
    const form = getChargesForm(chargesFormIndex);
    if (!isUndefinedOrNull(form)) {
        const { charges } = dispatch(buildChargesFormModel({ arrestId }));
        const charge = charges.find((charge) => charge.id === chargeId);
        if (charge) {
            const isValidMadLibsEntry = validateMadLibsParameters(
                selectedOffenseWordTemplate.wording,
                offenseWordingTemplateFieldValues
            );

            upsertMadLibsChargeConfig({
                chargesForm: form,
                chargeId: charge.id,
                configuration: {
                    offenseWordingId,
                    chargeId: charge.id,
                    fieldValues: offenseWordingTemplateFieldValues,
                    offenseWordingTemplateId: selectedOffenseWordTemplate.id,
                    isValid: isValidMadLibsEntry,
                },
            });
        }
    }
};

export const upsertMadLibsChargeConfig = ({ chargesForm, chargeId, configuration }) => {
    const madLibsConfigState = chargesForm.get(MADLIBS_FORM_MODEL_KEY) ?? [];
    const index = madLibsConfigState.findIndex((item) => item.chargeId === chargeId);

    if (index >= 0) {
        chargesForm.set(`${MADLIBS_FORM_MODEL_KEY}[${index}]`, configuration);
    } else {
        chargesForm.push(MADLIBS_FORM_MODEL_KEY, configuration);
    }
};

export const getChargeMadLibsConfiguration = ({ chargesForm, chargeId }) => {
    const madLibsConfiguration = chargesForm.get(MADLIBS_FORM_MODEL_KEY) ?? [];
    return madLibsConfiguration.find((item) => item.chargeId === chargeId);
};

import React from 'react';
import { change } from 'redux-form-mark43';
import { connect } from 'react-redux';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import ImageUpload from '../../../../modules/core/forms/components/ImageUpload';

function UserProfileAdminPhotoUpload({ profile, setUserProfilePhotoId, removeUserProfilePhotoId }) {
    const imageUrl =
        getViewModelProperties(profile.userProfilePhoto).thumbnailPath ||
        getViewModelProperties(profile.userProfilePhoto).path;

    return (
        <ImageUpload
            onRemove={() => removeUserProfilePhotoId()}
            onSuccess={(file) => setUserProfilePhotoId(file.image.id)}
            height="120px"
            width="120px"
            imageUrl={imageUrl}
        />
    );
}

const mapDispatchToProps = (dispatch) => ({
    setUserProfilePhotoId(userProfilePhotoId) {
        dispatch(change('userProfileAdmin', 'userProfilePhotoId', userProfilePhotoId));
    },
    removeUserProfilePhotoId() {
        dispatch(change('userProfileAdmin', 'removedUserProfilePhotoId', true));
        dispatch(change('userProfileAdmin', 'userProfilePhotoId', null));
    },
});

export default connect(null, mapDispatchToProps)(UserProfileAdminPhotoUpload);

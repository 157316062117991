import { SystemRuleEnum } from '@mark43/rms-api';
import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data/';
import addRuleId from '../../../../../../legacy-redux/validation/helpers/addRuleId';

export const exportReleaseReleasedToOtherRequired = addRuleId(
    SystemRuleEnum.EXPORT_RELEASE_RELEASED_TO_OTHER_REQUIRED.name,
    ({ releasedToAttrId, releasedToOther }, _value, state) => {
        const isOther = attributeIsOtherSelector(state)(releasedToAttrId);
        if (isOther && !releasedToOther) {
            return false;
        } else {
            return true;
        }
    }
);

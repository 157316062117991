import styled from 'styled-components';
import { mediaQueries } from 'arc';

import { ArbiterMFTCheckbox } from '../../forms/components/checkboxes/Checkbox';

export const CheckboxCenteredWithTextField = styled(ArbiterMFTCheckbox)`
    @media (min-width: ${mediaQueries.sm}) {
        margin-top: 28px;
    }
`;

import React from 'react';
import { noop } from 'lodash';

import { ExportIntervalTypeEnum, ExportIntervalTypeEnumType } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { MFTDateRangePicker } from '../../core/forms/components/DateRangePicker';
import { MFTYearSelect } from '../../core/forms/components/selects/YearSelect';
import { COMPLIANCE_YEAR_SELECT_DEFAULT_MAX_YEARS } from '../configuration';
import ComplianceMonthYearSelect from './ComplianceMonthYearSelect';
import ComplianceDateRangeSelect from './ComplianceDateRangeSelect';

type ComplianceDateSelectProps = {
    agencyId?: number;
    disabled: boolean;
    exportIntervalType?: typeof ExportIntervalTypeEnum[ExportIntervalTypeEnumType]['name'];
    lastExportedDate?: string;
    onChange: () => void;
    yearSelectMaxYears?: number;
};

const labels = componentStrings.compliance.ComplianceDateSelect.labels;

const buildYearOptions = (
    yearSelectMaxYears: number = COMPLIANCE_YEAR_SELECT_DEFAULT_MAX_YEARS
) => {
    // TODO: check with product for acceptable year ranges
    const thisYear: number = new Date().getFullYear() + 1;
    const endYear: number = thisYear - yearSelectMaxYears;
    const years: { display: string; value: string }[] = [];

    for (let year = thisYear; year >= endYear; year--) {
        years.push({
            display: `${year}`,
            value: `${year}`,
        });
    }

    return years;
};

const dateRangeFields = {
    startDateUtc: { onChange: noop },
    endDateUtc: { onChange: noop },
    toDatePeriod: { onChange: noop },
    withinLastPeriod: { onChange: noop },
};

const ComplianceDateSelect: React.FC<ComplianceDateSelectProps> = ({
    agencyId,
    exportIntervalType,
    disabled,
    lastExportedDate,
    onChange,
    yearSelectMaxYears,
}) => {
    switch (exportIntervalType) {
        case ExportIntervalTypeEnum.TN_WEEKLY.name:
            return (
                <ComplianceDateRangeSelect
                    agencyId={agencyId}
                    isClearable={false}
                    exportIntervalType={exportIntervalType}
                    lastExportedDate={lastExportedDate}
                    isDisabled={disabled}
                    onChange={onChange}
                    label={labels.exportIntervalTypeWeekly}
                    width={220}
                    showRange={true}
                />);
        case ExportIntervalTypeEnum.NONE.name:
            // TODO: endDateUtc is not persisting in form.
            return (
                <MFTDateRangePicker
                    disabled={disabled}
                    fields={dateRangeFields}
                    includeNoFilterOption={false}
                    label={labels.exportIntervalTypeCustom}
                    onChange={onChange}
                    path="dateRange"
                    width={220}
                />
            );
        case ExportIntervalTypeEnum.MONTHLY.name:
            return (
                <ComplianceMonthYearSelect
                    agencyId={agencyId}
                    clearable={false}
                    disabled={disabled}
                    label={labels.exportIntervalTypeMonthly}
                    lastExportedDate={lastExportedDate}
                    onChange={onChange}
                    path="exportDateUtc"
                    width={120}
                />
            );
        case ExportIntervalTypeEnum.YEARLY.name:
            return (
                <MFTYearSelect
                    width={120}
                    label={labels.exportIntervalTypeYearly}
                    disabled={disabled}
                    path="year"
                    options={buildYearOptions(yearSelectMaxYears)}
                    onChange={onChange}
                />
            );
        default:
            return null;
    }
};

export default ComplianceDateSelect;

import { AttributeTypeEnum } from '@mark43/rms-api';
import { head, map } from 'lodash';

import { createSelector } from 'reselect';
import {
    allSingleAttributeValuesMapper,
    buildViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { citationsWhereSelector } from '../data';
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '../../../attributes/state/data';
import { formatAttributeLinkViewModels } from '../../../attributes/state/ui';
import { reportAttributeViewModelsWhereSelector } from '../../../report-attributes/state/ui';

const buildCitationViewModelSelector = createSelector(
    reportAttributeViewModelsWhereSelector,
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
    (reportAttributeViewModelsWhere, formatAttributeById, formatAttributeWithOther) => {
        const viewModel = buildViewModel({
            mappers: [
                allSingleAttributeValuesMapper,
                ({ citationTypeAttrId, citationTypeOther }) => {
                    return {
                        citationTypeDisplay: formatAttributeWithOther({
                            attributeId: citationTypeAttrId,
                            other: citationTypeOther,
                        }),
                    };
                },
                ({ reportId }) => {
                    const citationStatisticsViewModels = reportAttributeViewModelsWhere({
                        reportId,
                        attributeType: AttributeTypeEnum.CITATION_STATISTICS.name,
                    });

                    return {
                        citationStatisticsAttributesDisplay: formatAttributeLinkViewModels(
                            citationStatisticsViewModels
                        ),
                    };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });

        return viewModel;
    }
);

const citationViewModelsWhereSelector = createSelector(
    citationsWhereSelector,
    buildCitationViewModelSelector,
    (citationsWhere, buildCitationViewModel) => (predicate) =>
        map(citationsWhere(predicate), buildCitationViewModel)
);

export const citationViewModelByReportIdSelector = createSelector(
    citationViewModelsWhereSelector,
    (citationViewModelsWhere) => (reportId) => head(citationViewModelsWhere({ reportId }))
);

import factory from './actionTypesFactory';

export default factory('entityPermissions', [
    'OPEN_PERMISSIONS_MODAL',
    'LOAD_PERMISSIONS_SUCCESS',
    'UPDATE_PERMISSIONS_START',
    'UPDATE_PERMISSIONS_SUCCESS',
    'UPDATE_PERMISSIONS_FAILURE',
    'STORE_HANDLE_SUBMIT',
]);

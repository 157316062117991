import React, { useState, useCallback } from 'react';
import { Flex, Banner, cssVar } from 'arc';
import styled from 'styled-components';
import { PhotoLineupView } from '@mark43/rms-api';
import { RouteComponentProps } from 'react-router';
import { useResource } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import casePhotoLineupResource from '../../core/resources/casePhotoLineupResource';
import { useLineupContext } from '../state/ui';
import { getLineupName } from '../utils/helpers';
import { usePhotoLineupPersonProfiles } from '../hooks/usePhotoLineupPersonProfiles';
import { LineupDetailsRow } from './LineupPhotoRow';

const strings = componentStrings.cases.casePhotoLineups.DetailsLineup;

const ExportPage = styled(Flex)`
    position: relative;
    background-color: ${cssVar('arc.colors.surface.background')};
    height: 100%;
`;

const LineupContainer = styled.div`
    margin: 24px 0;
`;

type Params = {
    caseId: string;
    photoLineupId: string;
};

const LineupDetailsPage: React.FC<RouteComponentProps<Params, Record<string, unknown>>> = ({
    params,
}) => {
    const [lineup, setLineup] = useState<PhotoLineupView>();
    const caseId = parseInt(params.caseId);
    const photoLineupId = parseInt(params.photoLineupId);
    const { setSelectedLineup } = useLineupContext();
    const { relatedPersons } = usePhotoLineupPersonProfiles();

    const loadLineup = useCallback(
        () => casePhotoLineupResource.getPhotoLineupForCase(caseId, photoLineupId),
        [caseId, photoLineupId]
    );

    const onResourceSuccess = useCallback(
        (photoLineupView: PhotoLineupView) => {
            const { title, photoLineup, canExport, isExported } = photoLineupView;
            setLineup(photoLineupView);
            setSelectedLineup({
                title,
                // @ts-expect-error client-common to client RND-7529
                name: getLineupName(photoLineup.personOfInterestMasterId, relatedPersons),
                lineupId: photoLineup.id,
                canExport,
                isExported,
            });
        },
        [setLineup, setSelectedLineup, relatedPersons]
    );

    const { isLoading, errorMessage } = useResource(loadLineup, onResourceSuccess);

    if (isLoading || !lineup) {
        return null;
    }

    return (
        <>
            {errorMessage && (
                <Banner status="error" description={errorMessage} title={strings.errorText} />
            )}
            <ExportPage justify="center">
                <LineupContainer>
                    <LineupDetailsRow lineupView={lineup} />
                </LineupContainer>
            </ExportPage>
        </>
    );
};

export default LineupDetailsPage;

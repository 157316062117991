import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { intersection, some } from 'lodash';
import {
    QuickSearchResult,
    JmsSearchResultListJmsBooking,
    SearchQueryObject,
} from '@mark43/rms-api';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { RmsDispatch } from '../../../../core/typings/redux';
import elasticSearchResource from '../../../../legacy-redux/resources/elasticSearchResource';
import {
    QUICK_SEARCH_QUERY_SIZE,
    QuickSearchResultSearchTypes,
    quickSearchResultTypeToPropMap,
} from '../config';
import {
    allEntityTypes,
    allowedQuickSearchTypesSelector,
    stashAttributesForSearchResults,
} from '../state/ui';

export type TriggerQuickSearchOptions = {
    allowedEntityTypes: typeof allEntityTypes;
    excludeExternalAgencyResults: boolean;
};
export type TriggerQuickSearchLoadMoreForTypeOptions = {
    type: QuickSearchResultSearchTypes;
    excludeExternalAgencyResults: boolean;
};

interface QuickSearchResultWithBooking extends QuickSearchResult {
    bookings: JmsSearchResultListJmsBooking & { query: SearchQueryObject };
}

export function useQuickSearch() {
    // Prefer ref because value was stale in callbacks.
    const loadingSearchType = useRef<QuickSearchResultSearchTypes>();
    const [results, setResults] = useState<QuickSearchResultWithBooking>();
    const [query, setQuery] = useState('');

    const dispatch = useDispatch<RmsDispatch>();
    const allowedQuickSearchTypes = useSelector(allowedQuickSearchTypesSelector);

    const hasResults = some(allowedQuickSearchTypes, (quickSearchType) => {
        const sectionData = results?.[quickSearchResultTypeToPropMap[quickSearchType]];
        return sectionData?.items.length;
    });

    const resourceCallback = useCallback(
        (searchResult: QuickSearchResultWithBooking) => {
            // if we have no result, then we have to assume that
            // the promise was cancelled (since our ajax implementation
            // swallows cancellation errors)
            if (!searchResult) {
                return;
            }

            dispatch(stashAttributesForSearchResults(searchResult));
            setResults((prevState) => {
                // new search -> no prevState and no loadMore type -> overwrite all results
                if (!prevState || !loadingSearchType.current) {
                    return searchResult;
                }

                const searchType = quickSearchResultTypeToPropMap[loadingSearchType.current];
                loadingSearchType.current = undefined;

                return {
                    ...prevState,
                    [searchType]: {
                        // query and other metadata
                        ...searchResult[searchType],
                        // loadMore -> append results to searchType.items
                        items: [...prevState[searchType].items, ...searchResult[searchType].items],
                    },
                };
            });
        },
        [dispatch]
    );

    const { loading, callResource, resetLoadingState } = useResourceDeferred(
        elasticSearchResource.searchAll,
        resourceCallback
    );

    // same loading state for both new search and loadMore
    // prevent new search loading ui from showing during loadMore
    const isLoading = loading.isLoading && !loadingSearchType.current;

    // TODO: test loading state
    const isSectionLoadingForType = useCallback(
        (searchType: QuickSearchResultSearchTypes) =>
            loading.isLoading && searchType === loadingSearchType.current,
        [loading.isLoading]
    );

    const triggerQuickSearch = useCallback(
        (options: TriggerQuickSearchOptions) => {
            const { allowedEntityTypes, excludeExternalAgencyResults } = options;

            const searchTypes = intersection(allowedQuickSearchTypes, allowedEntityTypes);

            callResource({
                excludeExternalAgencyResults,
                query,
                searchTypes,
                size: QUICK_SEARCH_QUERY_SIZE,
            });
        },
        [allowedQuickSearchTypes, callResource, query]
    );

    const triggerQuickSearchLoadMoreForType = useCallback(
        (options: TriggerQuickSearchLoadMoreForTypeOptions) => {
            const { type, excludeExternalAgencyResults } = options;
            const searchResult = results?.[quickSearchResultTypeToPropMap[type]];
            const from = (searchResult?.query?.from || 0) + QUICK_SEARCH_QUERY_SIZE;

            loadingSearchType.current = type;
            callResource({
                excludeExternalAgencyResults,
                from,
                query,
                searchTypes: [type],
                size: QUICK_SEARCH_QUERY_SIZE,
            });
        },
        [callResource, query, results]
    );

    const resetQuickSearchState = useCallback(() => {
        setResults(undefined);
        loadingSearchType.current = undefined;
        setQuery('');
        resetLoadingState();
    }, [resetLoadingState]);

    const updateQuickSearchQuery = useCallback(
        (query: string) => {
            setQuery(query);
            if (loading.errorMessage) {
                resetLoadingState();
            }
        },
        [loading.errorMessage, resetLoadingState]
    );

    return {
        error: loading.errorMessage,
        hasResults,
        isLoading,
        isSectionLoadingForType,
        resetQuickSearchState,
        resultTypes: allowedQuickSearchTypes,
        results,
        query,
        triggerQuickSearch,
        triggerQuickSearchLoadMoreForType,
        updateQuickSearchQuery,
    };
}

import { filter, first, mapValues, map } from 'lodash';
import { createSelector } from 'reselect';
import { CaseStatus } from '@mark43/rms-api';

import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { dateTimeFormats, formatISODate } from '../../../../dates/utils/dateHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
    attributesWithParentAttributeIdSelector,
} from '../../../attributes/state/data';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';
import { caseStatusesSelector } from '../data';
import globalAttributes from '../../../../legacy-constants/globalAttributes';

const caseStatusViewModelsSelector = createSelector(
    caseStatusesSelector,
    formatAttributeByIdSelector,
    formatMiniUserByIdSelector,
    (caseStatuses, formatAttributeById, formatMiniUserById) => {
        const viewModel = buildViewModel<CaseStatus>({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ updatedBy }) => ({
                    updatedBy: formatMiniUserById(updatedBy, { firstNameAsInitial: true }),
                }),
                ({ statusAttrId, statusDateUtc, updatedBy }) => ({
                    summary: joinTruthyValues(
                        [
                            `Case Status: ${formatAttributeById(statusAttrId)}`,
                            // annoying to have this here, but done to avoid calling that helper on undefined
                            statusDateUtc
                                ? formatISODate(statusDateUtc, dateTimeFormats.summaryDate)
                                : statusDateUtc,
                            `by ${formatMiniUserById(updatedBy, { firstNameAsInitial: true })}`,
                        ],
                        ' '
                    ),
                }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(caseStatuses, viewModel);
    }
);
export const caseStatusViewModelByCaseIdSelector = createSelector(
    caseStatusViewModelsSelector,
    (caseStatusViewModel) =>
        // we should only have 1 case status per case
        (caseId: number) => first(filter(caseStatusViewModel, { caseId }))
);

export const statusIsClosedSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (statusAttrId: number) =>
        statusAttrId
            ? parentAttributeIdByAttributeId(statusAttrId) === globalAttributes.caseStatus.closed
            : false
);

export const statusIsCanceledSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (statusAttrId: number) =>
        statusAttrId
            ? parentAttributeIdByAttributeId(statusAttrId) === globalAttributes.caseStatus.canceled
            : false
);

export const statusIsInactiveSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (statusAttrId: number) =>
        statusAttrId
            ? parentAttributeIdByAttributeId(statusAttrId) === globalAttributes.caseStatus.inactive
            : false
);

export const statusIsUnfoundedSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (statusAttrId: number) =>
        statusAttrId
            ? parentAttributeIdByAttributeId(statusAttrId) === globalAttributes.caseStatus.unfounded
            : false
);

export const caseStatusGlobalAttrIdToChildAttrIdsSelector = createSelector(
    attributesWithParentAttributeIdSelector,
    (attributesWithParentAttributeId) =>
        mapValues(
            {
                // We filter to ensure that included attributes are CASE_STATUS attributes because it is possible that attributes of other types map to a global case status
                // attribute as their parent
                [globalAttributes.caseStatus.open]: filter(
                    attributesWithParentAttributeId(globalAttributes.caseStatus.open),
                    (attr) => attr.type === 'CASE_STATUS'
                ),
                [globalAttributes.caseStatus.closed]: filter(
                    attributesWithParentAttributeId(globalAttributes.caseStatus.closed),
                    (attr) => attr.type === 'CASE_STATUS'
                ).concat(
                    filter(
                        attributesWithParentAttributeId(globalAttributes.caseStatus.unfounded),
                        (attr) => attr.type === 'CASE_STATUS'
                    )
                ),
                [globalAttributes.caseStatus.inactive]: filter(
                    attributesWithParentAttributeId(globalAttributes.caseStatus.inactive),
                    (attr) => attr.type === 'CASE_STATUS'
                ),
                [globalAttributes.caseStatus.canceled]: filter(
                    attributesWithParentAttributeId(globalAttributes.caseStatus.canceled),
                    (attr) => attr.type === 'CASE_STATUS'
                ),
            },
            (attributeList) => map(attributeList, 'id')
        )
);

import { get, omit, pick } from 'lodash';
import { combineReducers } from 'redux';
import { searchInventories } from '~/client-common/core/domain/inventories/state/data';
import { createInventoriesViewModelSelector } from '~/client-common/core/domain/inventories/state/ui';
import { saveFormDataToLocalStorage } from '../../../../core/local-storage';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import { queryParamDefaults } from '../../configuration';
import inventoryExportResource from '../../resources/inventoriesExportResource';
import inventoriesDashboardSearchForm from '../forms/inventoriesDashboardSearchForm';

export const applyFilters = () => (dispatch) => {
    dispatch(
        inventoriesDashboardSearch.form.actionCreators.submit((formData) => {
            dispatch(
                inventoriesDashboardSearch.actionCreators.search(
                    {
                        formData,
                        from: 0,
                    },
                    { cacheBust: true }
                )
            );
        })
    );
};

export const clearAllFilters = () => (dispatch) => {
    dispatch(inventoriesDashboardSearch.form.actionCreators.reset());
    dispatch(
        inventoriesDashboardSearch.form.actionCreators.submit((formData) => {
            dispatch(saveFormDataToLocalStorage(inventoriesDashboardSearchForm));

            // make the search
            dispatch(
                inventoriesDashboardSearch.actionCreators.search(
                    {
                        formData,
                        from: 0,
                    },
                    { cacheBust: true }
                )
            );
        })
    );
};

/**
 * Inventory Search returns a slightly different data shape from the standard elastic search.
 * @param  {Object} searchResult inventory search result
 * @return {Object} searchResult suitable for searchModule
 */
function transformInventorySearchResultToElasticSearchResult(searchResult) {
    const { inventories: items, query, totalCount } = searchResult;

    return {
        items,
        query: {
            ...pick(query, ['from', 'size']),
            ...get(query, 'sorts[0]', {}),
        },
        totalCount,
    };
}

export const inventoriesDashboardSearch = createSearchModule({
    elasticSearchType: 'INVENTORIES',
    baseUiSelector: (state) => state.ui.evidence.inventories.search,
    form: inventoriesDashboardSearchForm,
    defaultTableState: {
        from: queryParamDefaults.FROM,
        size: queryParamDefaults.SIZE,
        sortKey: queryParamDefaults.SORT_KEY,
        sortType: queryParamDefaults.SORT_TYPE,
    },
    resourceMethod: (query, from, querySize, sortKey, sortType, dispatch) =>
        dispatch(
            searchInventories({
                query: {
                    ...query,
                    isCompleted: true,
                },
                from,
                size: querySize,
                sorts: [
                    {
                        sortKey,
                        sortType,
                    },
                ],
            })
        ).then((searchResult) => transformInventorySearchResultToElasticSearchResult(searchResult)),
    resultsContainerClassName: 'dashboard-search-results',
    createResultsViewModelsSelector: createInventoriesViewModelSelector,
    exportResourceMethod: (query, printingTemplateIds) => {
        // transform the `elasticQuery` variable from buildExportResultsActionCreator into a
        // SqlSearchQuery<InventorySqlQuery> which is different from an elastic query
        return inventoryExportResource.exportInventorySearchResults(
            {
                query: {
                    ...omit(query, 'ids'),
                    inventoryIds: query?.ids,
                },
                from: 0,
                // export all selected results; this is the value of ElasticConstants.MAX_SIZE
                size: 25000,
            },
            printingTemplateIds
        );
    },
});

export default combineReducers({ search: inventoriesDashboardSearch.reducers.uiReducer });

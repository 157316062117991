import React from 'react';
import { createStructuredSelector } from 'reselect';
import _, { map } from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { reportExternalLinksWhereSelector } from '~/client-common/core/domain/report-external-links/state/data';
import {
    reportReportLinksByReportIdSelector,
    suggestedReportReportLinksByReportIdSelector,
} from '~/client-common/core/domain/report-report-links/state/data';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    formatAttributeByIdSelector,
    attributeIsOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { isOffenseModifyingSupplementReportSelector } from '~/client-common/core/domain/report-definitions/state/data';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import testIds from '../../../../core/testIds';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import { Button as ArcButton } from '../../../core/components/Button';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { approvalStatusSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import Tabs, { Tab } from '../../../../legacy-redux/components/core/Tabs';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { openAssociatedRecordsSidePanel, saveReportReportLink } from '../state/ui';
import ReactHotKeysWrapper from '../../../core/hotkeys/components/ReactHotKeysWrapper';
import { cardScrollHotKeyConfigForAnchor } from '../../../reports/core/state/ui/cards';
import ReportReportLink from './ReportReportLink';
import ReportExternalLink from './ReportExternalLink';

import { Container, BodyHeavy, SectionTitle } from './AssociatedRecordsUiComponents';

const strings = componentStrings.records.associatedRecords;

const AssociationGroupContainer = styled.div`
    margin-top: 5px;
`;

const StyledTab = styled(Tab)`
    width: 100%;
`;

const ASSOCIATED_RECORDS_DATA_ANCHOR = 'associated-records';

function AssociatedRecordsContent({
    reportReportLinks,
    reportExternalLinks,
    openSidePanel,
    formatAttributeById,
    attributeIsOther,
    currentReportId,
    disableEditing,
}) {
    // if an isOther reasonForAssociationAttrId is provided we group them on the secondary field
    // reasonForAssociationOther
    const groupedLinks = _(reportExternalLinks)
        .concat(reportReportLinks)
        .groupBy(({ reasonForAssociationOther, reasonForAssociationAttrId }) => {
            return attributeIsOther(reasonForAssociationAttrId)
                ? reasonForAssociationOther
                : formatAttributeById(reasonForAssociationAttrId);
        })
        .value();

    return (
        <div>
            {!disableEditing && (
                <OnlyWithAbility has={abilitiesEnum.REPORTING.MANAGE_ASSOCIATED_RECORDS}>
                    <FeatureFlagged
                        flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                        fallback={
                            <Button
                                float="none"
                                display="inline-block"
                                onClick={openSidePanel}
                                className={buttonTypes.SECONDARY}
                                testId={testIds.ADD_EDIT_ASSOCIATED_RECORDS}
                            >
                                {strings.button}
                            </Button>
                        }
                    >
                        <ArcButton
                            style={{ marginTop: '12px' }}
                            variant="outline"
                            onClick={openSidePanel}
                            testId={testIds.ADD_EDIT_ASSOCIATED_RECORDS}
                        >
                            {strings.button}
                        </ArcButton>
                    </FeatureFlagged>
                </OnlyWithAbility>
            )}

            {map(groupedLinks, (linkGroup, reasonForAssociationAttribute) => {
                return (
                    <AssociationGroupContainer
                        key={reasonForAssociationAttribute}
                        data-test-id={testIds.ASSOCIATED_RECORD_LINK_CONTAINER}
                    >
                        <BodyHeavy>{reasonForAssociationAttribute}</BodyHeavy>
                        {map(linkGroup, (reportLink) => {
                            if (reportLink.isExternal) {
                                return (
                                    <ReportExternalLink
                                        key={reportLink.id}
                                        reportLink={reportLink}
                                        formatAttributeById={formatAttributeById}
                                        attributeIsOther={attributeIsOther}
                                    />
                                );
                            }
                            return (
                                <ReportReportLink
                                    key={reportLink.id}
                                    reportLink={reportLink}
                                    currentReportId={currentReportId}
                                />
                            );
                        })}
                    </AssociationGroupContainer>
                );
            })}
        </div>
    );
}

function AssociatedRecordsSuggestedAssociations({
    suggestedReportReportLinks,
    formatAttributeById,
    attributeIsOther,
    currentReportId,
    saveSuggestedRecord,
    disableEditing,
}) {
    const groupedLinks = _(suggestedReportReportLinks)
        .groupBy(({ reasonForAssociationOther, reasonForAssociationAttrId }) => {
            return attributeIsOther(reasonForAssociationAttrId)
                ? reasonForAssociationOther
                : formatAttributeById(reasonForAssociationAttrId);
        })
        .value();
    return (
        <div>
            {map(groupedLinks, (linkGroup, reasonForAssociationAttribute) => {
                return (
                    <AssociationGroupContainer key={reasonForAssociationAttribute}>
                        <BodyHeavy>{reasonForAssociationAttribute}</BodyHeavy>
                        {map(linkGroup, (reportLink) => {
                            return (
                                <ReportReportLink
                                    key={reportLink.id}
                                    reportLink={reportLink}
                                    currentReportId={currentReportId}
                                    saveSuggestedRecord={
                                        disableEditing ? null : saveSuggestedRecord
                                    }
                                />
                            );
                        })}
                    </AssociationGroupContainer>
                );
            })}
        </div>
    );
}

const hotKeysConfig = cardScrollHotKeyConfigForAnchor(ASSOCIATED_RECORDS_DATA_ANCHOR);

class AssociatedRecordsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 1,
        };
    }

    onSelectTab = (key) => {
        this.setState({
            selectedTab: key,
        });
    };

    render() {
        const {
            openSidePanel,
            reportExternalLinksWhere,
            reportReportLinksByReportId,
            reportId,
            formatAttributeById,
            attributeIsOther,
            consortiumDepartmentLinksAvailable,
            currentUserDepartmentId,
            suggestedReportReportLinksByReportId,
            saveSuggestedRecord,
            reportById,
            approvalStatus,
            isOmsReport,
            featureFlags,
        } = this.props;

        const isInConsortium = consortiumDepartmentLinksAvailable;
        const report = reportById(reportId);
        if (!report) {
            return null;
        }

        // all editing is disabled if you're looking at a report on another department or if the current report is a completed OMS report
        const reportIsDifferentDepartment = currentUserDepartmentId !== report.departmentId;
        const isCompletedOmsReport =
            isOmsReport(reportId) && approvalStatus === approvalStatusClientEnum.COMPLETED;

        const reportExternalLinks = map(reportExternalLinksWhere({ reportId }), (rel) => ({
            isExternal: true,
            ...rel,
        }));
        const reportReportLinks = reportReportLinksByReportId(reportId);
        const suggestedReportReportLinks = suggestedReportReportLinksByReportId(reportId);

        const mainTabTitle = `${strings.title} (${
            reportExternalLinks.length + reportReportLinks.length
        })`;

        const associatedRecordContent = (
            <AssociatedRecordsContent
                reportReportLinks={reportReportLinks}
                reportExternalLinks={reportExternalLinks}
                openSidePanel={openSidePanel}
                formatAttributeById={formatAttributeById}
                attributeIsOther={attributeIsOther}
                currentReportId={reportId}
                disableEditing={
                    reportIsDifferentDepartment ||
                    (isCompletedOmsReport && featureFlags['RMS_NEW_OMS_WORKFLOW_ENABLED'])
                }
            />
        );
        return (
            <ReactHotKeysWrapper hotKeysConfig={hotKeysConfig}>
                <Container
                    data-anchor={ASSOCIATED_RECORDS_DATA_ANCHOR}
                    data-test-id={testIds.ASSOCIATED_RECORDS_SECTION}
                >
                    {isInConsortium ? (
                        <Tabs selectedTab={this.state.selectedTab} onSelectTab={this.onSelectTab}>
                            <StyledTab key="1" title={mainTabTitle}>
                                {associatedRecordContent}
                            </StyledTab>
                            <StyledTab
                                key="2"
                                title={`${strings.suggestedAssociations} (${suggestedReportReportLinks.length})`}
                            >
                                <AssociatedRecordsSuggestedAssociations
                                    suggestedReportReportLinks={suggestedReportReportLinks}
                                    formatAttributeById={formatAttributeById}
                                    attributeIsOther={attributeIsOther}
                                    currentReportId={reportId}
                                    saveSuggestedRecord={saveSuggestedRecord}
                                    disableEditing={
                                        reportIsDifferentDepartment ||
                                        (isCompletedOmsReport &&
                                            featureFlags['RMS_NEW_OMS_WORKFLOW_ENABLED'])
                                    }
                                />
                            </StyledTab>
                        </Tabs>
                    ) : (
                        <div>
                            <SectionTitle>{mainTabTitle}</SectionTitle>
                            {associatedRecordContent}
                        </div>
                    )}
                </Container>
            </ReactHotKeysWrapper>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    reportExternalLinksWhere: reportExternalLinksWhereSelector,
    reportReportLinksByReportId: reportReportLinksByReportIdSelector,
    formatAttributeById: formatAttributeByIdSelector,
    attributeIsOther: attributeIsOtherSelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    suggestedReportReportLinksByReportId: suggestedReportReportLinksByReportIdSelector,
    reportById: reportByIdSelector,
    approvalStatus: approvalStatusSelector,
    isOmsReport: isOffenseModifyingSupplementReportSelector,
    featureFlags: applicationSettingsSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    openSidePanel: () => dispatch(openAssociatedRecordsSidePanel(ownProps.reportId)),
    saveSuggestedRecord: (reportReportLink) =>
        dispatch(saveReportReportLink(ownProps.reportId, reportReportLink)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedRecordsSection);

import { OperationTypeEnum } from '@mark43/rms-api';
import { mapValues } from 'lodash';

import { createSelector } from 'reselect';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { warrantTitlesSelector } from '../data';
import { formatWarrantShortTitle } from '../../../../../helpers/warrantHelpers';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

export const warrantTitleViewModelsSelector = createSelector(
    warrantTitlesSelector,
    formatAttributeByIdSelector,
    formatMiniUserByIdSelector,
    (warrantTitles, formatAttributeById, formatMiniUserById) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ obtainingOfficerUserId, obtainingOfficerOther }) => ({
                    obtainingOfficerUserDisplay: joinTruthyValues(
                        [formatMiniUserById(obtainingOfficerUserId), obtainingOfficerOther],
                        ': '
                    ),
                    obtainingOfficerUserShortDisplay: joinTruthyValues(
                        [
                            formatMiniUserById(obtainingOfficerUserId, {
                                firstNameAsInitial: true,
                            }),
                            obtainingOfficerOther,
                        ],
                        ': '
                    ),
                }),
                ({ warrantNumber, warrantTypeAttrId }) => ({
                    warrantShortTitle: formatWarrantShortTitle({
                        warrantNumber,
                        warrantType: formatAttributeById(warrantTypeAttrId),
                    }),
                }),
                ({ maxOpType }) => {
                    return {
                        // SEARCH is the lowest possible operation type,
                        // so if the max operation type is not null,
                        // and it's anything other than SEARCH, that means user
                        // has READ abilities
                        canRead: maxOpType && maxOpType !== OperationTypeEnum.SEARCH.name,
                    };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(warrantTitles, viewModel);
    }
);

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Subdivisions Resource',
        methods: {
            getSubdivisionsView() {
                return req({
                    method: 'GET',
                    url: 'master/locations/subdivisions/primary',
                });
            },
            setupSubdivisions(subdivisionsSetupView) {
                return req({
                    method: 'POST',
                    url: 'master/locations/subdivisions/setup',
                    data: subdivisionsSetupView,
                });
            },
            setupSubdivisionsPreview(subdivisionsSetupView) {
                return req({
                    method: 'POST',
                    url: 'master/locations/subdivisions/setup/preview',
                    data: subdivisionsSetupView,
                });
            },
            promoteToPrimary(subdivisionId) {
                return req({
                    method: 'PUT',
                    url: `master/locations/subdivisions/primary/${subdivisionId}`,
                    data: subdivisionId,
                });
            },
            demoteFromPrimary(subdivisionId) {
                return req({
                    method: 'DELETE',
                    url: `master/locations/subdivisions/primary/${subdivisionId}`,
                    data: subdivisionId,
                });
            },
            deleteSubdivision(depthType) {
                return req({
                    method: 'DELETE',
                    url: `master/locations/subdivisions/${depthType}`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

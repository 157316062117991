import { ElasticSearchTypeEnum, EntityTypeEnum, QuickSearchResult } from '@mark43/rms-api';
import { QuickSearchResultSearchTypes, quickSearchResultTypeToPropMap } from '../config';

/**
 * This function is mainly to discriminate the SearchTypes union.
 *   The returned item will have the proper Elastic model type.
 */
export function getSelectedResult(
    type: QuickSearchResultSearchTypes,
    index: number,
    results: QuickSearchResult | undefined
) {
    if (!results) {
        return;
    }

    switch (type) {
        case ElasticSearchTypeEnum.CAD_TICKET.name:
            return {
                item: results[quickSearchResultTypeToPropMap[type]].items[index],
                type,
                entityType: EntityTypeEnum.CAD_TICKET.name,
            };
        case ElasticSearchTypeEnum.CASE.name:
            return {
                item: results[quickSearchResultTypeToPropMap[type]].items[index],
                type,
                entityType: EntityTypeEnum.CASE.name,
            };
        case ElasticSearchTypeEnum.ORGANIZATION.name:
            return {
                item: results[quickSearchResultTypeToPropMap[type]].items[index],
                type,
                entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            };
        case ElasticSearchTypeEnum.PERSON.name:
            return {
                item: results[quickSearchResultTypeToPropMap[type]].items[index],
                type,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
            };
        case ElasticSearchTypeEnum.PROPERTY.name:
            return {
                item: results[quickSearchResultTypeToPropMap[type]].items[index],
                type,
                entityType: EntityTypeEnum.ITEM_PROFILE.name,
            };
        case ElasticSearchTypeEnum.REPORT.name:
            return {
                item: results[quickSearchResultTypeToPropMap[type]].items[index],
                type,
                entityType: EntityTypeEnum.REPORT.name,
            };
        case ElasticSearchTypeEnum.VEHICLE.name:
            return {
                item: results[quickSearchResultTypeToPropMap[type]].items[index],
                type,
                entityType: EntityTypeEnum.ITEM_PROFILE.name,
            };
        case ElasticSearchTypeEnum.WARRANT.name:
            return {
                item: results[quickSearchResultTypeToPropMap[type]].items[index],
                type,
                entityType: EntityTypeEnum.WARRANT.name,
            };
        default:
            return;
    }
}

export type SearchResultWithType = NonNullable<ReturnType<typeof getSelectedResult>>;

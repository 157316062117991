import React from 'react';
import styled from 'styled-components';

import { isValueDisplayable } from '~/client-common/helpers/logicHelpers';

import Icon, { iconTypes } from '../../../modules/core/components/Icon';
import { InfoMessage } from '../../../modules/core/components/InfoMessage';

const InfoMessageText = styled.div`
    margin-left: 10px;
    padding-right: 10px;
`;

const EntityPermissionsMessage = ({ infoMessage }) => {
    return isValueDisplayable(infoMessage) ? (
        <InfoMessage>
            <Icon size={18} type={iconTypes.PERMISSIONS} color={'cobaltBlue'} />
            <InfoMessageText>{infoMessage}</InfoMessageText>
        </InfoMessage>
    ) : null;
};

export default EntityPermissionsMessage;

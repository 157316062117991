import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

export default function SubdivisionCell(elasticCadTicket) {
    const { cadTicketLocation } = getViewModelProperties(elasticCadTicket);

    if (!cadTicketLocation || !getViewModelProperties(cadTicketLocation).subdivisionAttrIds) {
        return <div className="elastic-cad-ticket-location">No Subdivisions</div>;
    }

    return (
        <div>
            <div className="subdivisions-line">
                <span className="subdivisions-info">
                    {getViewModelProperties(cadTicketLocation).subdivisionAttrIds}
                </span>
            </div>
        </div>
    );
}

import { createSelector } from 'reselect';
import _, { get, parseInt } from 'lodash';

import {
    LOAD_STREET_ALIASES_START,
    LOAD_STREET_ALIASES_SUCCESS,
    LOAD_STREET_ALIASES_FAILURE,
    SAVE_STREET_ALIAS_SUCCESS,
    SAVE_STREET_ALIAS_START,
    SAVE_STREET_ALIAS_FAILURE,
    DELETE_STREET_ALIAS_START,
    DELETE_STREET_ALIAS_SUCCESS,
    DELETE_STREET_ALIAS_FAILURE,
    streetAliasesSelector,
} from '~/client-common/core/domain/street-aliases/state/data';

import {
    streetsSelector,
    streetByIdSelector,
} from '~/client-common/core/domain/streets/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';

const OPEN_NEW_STREET_ALIASES_FORM = 'street-aliases/OPEN_NEW_STREET_ALIASES_FORM';
const SELECT_STREET_ID = 'street-aliases/SELECT_STREET_ID';
const CLOSE_STREET_ALIAS_FORM = 'street-aliases/CLOSE_STREET_ALIAS_FORM';

export function openNewStreetAliasesForm() {
    return {
        type: OPEN_NEW_STREET_ALIASES_FORM,
    };
}

export function selectStreetId(streetId) {
    return {
        type: SELECT_STREET_ID,
        payload: streetId,
    };
}

export function closeStreetAliasForm() {
    return {
        type: CLOSE_STREET_ALIAS_FORM,
    };
}

const initialUiState = {
    loadingList: false,
    listError: null,
    selectedStreetId: null,
    saving: false,
    savingError: null,
};

export default function streetAliasesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_STREET_ALIASES_START:
            return {
                ...state,
                loadingList: true,
                listError: null,
                selectedStreetId: null,
            };
        case LOAD_STREET_ALIASES_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_STREET_ALIASES_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case DELETE_STREET_ALIAS_SUCCESS:
            return {
                ...state,
                selectedStreetId: null,
                saving: false,
            };
        case SAVE_STREET_ALIAS_SUCCESS:
            return {
                ...state,
                saving: false,
                savingError: null,
            };
        case SAVE_STREET_ALIAS_FAILURE:
        case DELETE_STREET_ALIAS_FAILURE:
            return {
                ...state,
                saving: false,
                savingError: action.payload ? action.payload : null,
            };
        case OPEN_NEW_STREET_ALIASES_FORM:
            return {
                ...state,
                selectedStreetId: null,
            };
        case SELECT_STREET_ID:
            return {
                ...state,
                selectedStreetId: parseInt(action.payload),
                savingError: null,
            };
        case SAVE_STREET_ALIAS_START:
        case DELETE_STREET_ALIAS_START:
            return {
                ...state,
                saving: true,
            };
        case CLOSE_STREET_ALIAS_FORM:
            return {
                ...state,
                selectedStreetId: false,
                savingError: null,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.streetAliasesAdmin;

export const streetAliasesListSelector = createSelector(
    uiSelector,
    streetAliasesSelector,
    streetByIdSelector,
    ({ selectedStreetId, loadingList }, aliases, streetById) => {
        return loadingList
            ? []
            : _(aliases)
                  .groupBy('streetId')
                  .map((aliasesForStreet, streetId) => {
                      const street = streetById(streetId);

                      return {
                          ...street,
                          title: get(street, 'name'),
                          key: streetId,
                          selected: street.id === selectedStreetId,
                          subtitle: `${aliasesForStreet.length} ${
                              aliasesForStreet.length === 1 ? 'Alias' : 'Aliases'
                          }`,
                      };
                  })
                  .sortBy('title')
                  .value();
    }
);

export const streetAliasesByStreetIdSelector = createSelector(
    streetAliasesSelector,
    (streetAliases) =>
        _.memoize((streetId) => {
            return _.filter(streetAliases, { streetId: parseInt(streetId) });
        })
);

export const selectedStreetSelector = createSelector(
    uiSelector,
    streetByIdSelector,
    ({ selectedStreetId }, streetById) => selectedStreetId && streetById(selectedStreetId)
);

export const streetsWithoutAliasesOptionsSelector = createSelector(
    uiSelector,
    streetAliasesSelector,
    streetsSelector,
    streetByIdSelector,
    ({ selectedStreetId }, streetAliases, streets, streetById) => {
        const streetAliasesGroupedByStreetId = _(streetAliases).groupBy('streetId').value();

        const streetOptions = _(streets)
            .filter((street) => !streetAliasesGroupedByStreetId[street.id])
            .map((street) => {
                return {
                    display: street.name,
                    value: street.id,
                };
            })
            .value();

        const selectedStreet = streetById(selectedStreetId);
        if (selectedStreet) {
            streetOptions.push({
                display: selectedStreet.name,
                value: selectedStreet.id,
            });
        }

        return streetOptions;
    }
);

export const hasStreetAliasImportSelector = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.ADMIN.STREET_ALIAS_IMPORT)
);

export const hasStreetAliasExportSelector = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.ADMIN.STREET_ALIAS_EXPORT)
);

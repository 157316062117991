import isEmpty from 'lodash/isEmpty';
import {
    Attachment,
    AttachmentsConfig,
    PrintingDataTypeEnumType,
    WorkOrder,
} from '@mark43/rms-api';

import { computeTotalFileSize } from '../../../attachments/files/helpers';

interface CompositeAttachmentsConfig {
    [entityId: number]: {
        entityType: PrintingDataTypeEnumType;
        attachmentsConfig: AttachmentsConfig;
    };
}

type EntityTypeToIdsMap = {
    [entityType in PrintingDataTypeEnumType]?: string[];
};

// Recursively traverses workOrder tree to get attachmentsConfig and type for every entity id.
// Any entity might have multiple work orders (packets) in the tree, but their attachmentsConfigs are identical.
export const createCompositeAttachmentsConfig = (
    workOrder: WorkOrder | null,
    config: CompositeAttachmentsConfig = {}
) => {
    if (!workOrder) {
        return config;
    }

    const entityType = workOrder.dt;
    const entityId = workOrder.mim[entityType];
    const attachmentsConfig = workOrder.attachmentsConfig;

    // do not add attachmentsConfig if we already have it for this entityId or if it is empty
    if (!(entityId in config || isEmpty(attachmentsConfig))) {
        config[entityId] = {
            entityType,
            attachmentsConfig: attachmentsConfig as AttachmentsConfig,
        };
    }

    if (workOrder.c?.length) {
        workOrder.c.forEach((childWorkOrder) =>
            createCompositeAttachmentsConfig(childWorkOrder, config)
        );
    }
    return config;
};

// Builds an object that can be passed to loadAttachmentsByEntityIdsAndTypes
export const getEntityTypeToIdsMapByAttachmentsConfig = (config: CompositeAttachmentsConfig) => {
    const map: EntityTypeToIdsMap = {};

    for (const entityId in config) {
        const { entityType, attachmentsConfig } = config[entityId];

        if (attachmentsConfig.includeAll || attachmentsConfig.attachmentIds.length) {
            (map[entityType] ??= []).push(entityId);
        }
    }
    return map;
};

export const calculateAttachmentsSize = (
    compositeConfig: CompositeAttachmentsConfig,
    attachmentsByEntityId: Record<string, Attachment[]>
) => {
    let size = 0;

    for (const entityId in compositeConfig) {
        const attachmentsConfig = compositeConfig[entityId].attachmentsConfig;
        const entityAttachments = attachmentsByEntityId[entityId];

        if (entityAttachments?.length) {
            const includedAttachments = attachmentsConfig.includeAll
                ? entityAttachments
                : entityAttachments.filter(({ attachmentId }) =>
                      attachmentsConfig.attachmentIds.includes(attachmentId)
                  );
            size += computeTotalFileSize(includedAttachments);
        }
    }
    return size;
};

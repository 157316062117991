import React from 'react';
import { Observer, Fields, WithRegistry } from 'markformythree';
import { ArbiterField } from '../../arbiter';

// Note: Currently this observer only supports being rendered under an <ArbiterProvider/>
// Note 2: Currently this observer only supports absolute paths
export function VisibilityObserver({ path, render, formName, formIndex }) {
    return (
        <WithRegistry
            render={({ registry }) => (
                <Observer
                    formName={formName}
                    formIndex={formIndex}
                    subscriptions={{
                        hidden: [path, Fields.HIDDEN],
                    }}
                    render={({ hidden: dynamicallyHidden }) => (
                        <ArbiterField
                            fieldName={
                                registry.get(formName, formIndex).getConfigurationForPath(path)
                                    .fieldName
                            }
                        >
                            {({ fieldConfigurationContext }) => {
                                // `hidden` is a computed value, and is true if the field is hidden dynamically
                                // or statically - false otherwise
                                return render({
                                    hidden:
                                        dynamicallyHidden ||
                                        fieldConfigurationContext.isStaticallyHidden,
                                });
                            }}
                        </ArbiterField>
                    )}
                />
            )}
        />
    );
}

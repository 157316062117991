import React from 'react';
import { createStructuredSelector, createSelector } from 'reselect';
import { connect } from 'react-redux';
import { map } from 'lodash';
import styled, { withTheme } from 'styled-components';

import { buildElasticPersonViewModelSelector } from '~/client-common/core/domain/elastic-persons/state/ui';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    duplicateNamePrimaryModelSelector,
    duplicateNameDuplicateModelsSelector,
    closeDuplicateNameResultsSidePanel,
    saveDuplicateNameFromSidePanel,
} from '../../duplicate-names/state/ui';
import {
    ContentSubsection,
    ContentSection,
} from '../../../../legacy-redux/components/core/Content';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../Icon';
import HelpText from '../../../../legacy-redux/components/core/HelpText';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import DuplicateNameResult from './DuplicateNameResult';

const strings = componentStrings.reports.core.DuplicateNameResultsSidePanel;

const context = {
    name: boxEnum.DUPLICATE_NAME_RESULTS_SIDE_PANEL,
};

const ButtonSave = styled(Button)`
    float: right;
    margin: 0;
`;

const PrimarySubsection = styled(ContentSubsection)`
    margin-bottom: 10px;
    margin-top: 30px;
`;

const DuplicateSubsection = styled(ContentSection)`
    padding: 0;
`;

const NumFound = styled.span`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    text-transform: none;
    font-size: var(--arc-fontSizes-md);
    margin-left: 20px;
`;

function DuplicateNameResultsSidePanel({
    primary = [],
    duplicates = [],
    onClickBack,
    onSaveNew,
    onCompareNew,
    theme,
}) {
    const duplicateNameResults = map(duplicates, (duplicate) => (
        <DuplicateNameResult
            key={duplicate.id}
            elasticPerson={duplicate}
            buttonSave={
                <ButtonSave className={buttonTypes.PRIMARY} onClick={onCompareNew}>
                    {strings.compareToNew}
                </ButtonSave>
            }
            isPrimary={false}
        />
    ));
    return (
        <SidePanel title={strings.title} context={context} width={470} hideFooter={true}>
            <Button
                className={buttonTypes.ICON_LINK}
                iconLeft={iconTypes.OPEN_LEFT}
                onClick={onClickBack}
            >
                {strings.back}
            </Button>
            <PrimarySubsection
                title={strings.newPerson}
                headerStyle={{ width: `${theme.widths.sectionHeader.sidePanel}px` }}
            >
                <DuplicateNameResult
                    elasticPerson={primary}
                    buttonSave={
                        <ButtonSave className={buttonTypes.SECONDARY} onClick={onSaveNew}>
                            {strings.saveNew}
                        </ButtonSave>
                    }
                    isPrimary={true}
                />
            </PrimarySubsection>
            <DuplicateSubsection
                title={
                    <div>
                        {strings.similarExistingProfiles}
                        <NumFound>({duplicates.length} found)</NumFound>
                        <HelpText content={strings.helpText} />
                    </div>
                }
                headerStyle={{ width: `${theme.widths.sectionHeader.sidePanel}px` }}
            >
                {duplicateNameResults}
            </DuplicateSubsection>
        </SidePanel>
    );
}

const primarySelector = createSelector(
    duplicateNamePrimaryModelSelector,
    buildElasticPersonViewModelSelector,
    (primary, viewModel) => viewModel(primary)
);

const duplicateSelector = createSelector(
    duplicateNameDuplicateModelsSelector,
    buildElasticPersonViewModelSelector,
    (duplicates, viewModel) => map(duplicates, viewModel)
);

const mapStateToProps = createStructuredSelector({
    primary: primarySelector,
    duplicates: duplicateSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onClickBack: () => dispatch(closeDuplicateNameResultsSidePanel()),
    onSaveNew: () => dispatch(saveDuplicateNameFromSidePanel()),
    onCompareNew: () => {
        // RMS-6002: dispatch(openDuplicateNameCompareSidePanel())
    },
});

export default withTheme(
    connect(mapStateToProps, mapDispatchToProps)(DuplicateNameResultsSidePanel)
);

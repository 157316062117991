import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { FormsProvider } from 'markformythree';
import applicationConfigurationQueryParamsEnum from '~/client-common/core/enums/client/applicationConfigurationQueryParamsEnum';
import Mark43ThemeProvider from '../../../modules/core/styles/Mark43ThemeProvider';
import themeEnum from '../../../modules/core/styles/themeEnum';
import { userIsLoggedInSelector } from '../../../modules/core/current-user/state/ui';
import { loadingMaskVisibleSelector } from '../../selectors/globalSelectors';
import formsRegistry from '../../../core/formsRegistry';
import LoadingMask from './LoadingMask';
import App from './App';

const { FORCE_NIGHTMODE } = applicationConfigurationQueryParamsEnum;

const cobaltSelector = createStructuredSelector({
    userIsLoggedIn: userIsLoggedInSelector,
    loadingMaskVisible: loadingMaskVisibleSelector,
});

function Cobalt({
    userIsLoggedIn,
    loadingMaskVisible,
    location,
    forceDarkMode,
    appPage,
    children,
}) {
    let forceMode = null;
    // lightMode takes precedence
    if (!userIsLoggedIn) {
        forceMode = themeEnum.LIGHT_MODE;
    } else if (forceDarkMode) {
        forceMode = themeEnum.DARK_MODE;
    }
    let content;

    if (userIsLoggedIn) {
        content = <App location={location}>{children || appPage}</App>;
    } else if (!userIsLoggedIn && loadingMaskVisible) {
        content = <LoadingMask />;
    }

    return (
        <FormsProvider registry={formsRegistry}>
            <Mark43ThemeProvider forceMode={forceMode}>{content}</Mark43ThemeProvider>
        </FormsProvider>
    );
}

export default compose(
    connect(cobaltSelector),
    withProps(({ location }) => ({
        forceDarkMode: location.query[FORCE_NIGHTMODE],
    }))
)(Cobalt);

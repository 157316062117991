import * as React from 'react';
import { NItems as MFTNItems, RenderProps } from 'markformythree';
import { DragonFormsResource } from '@mark43/rms-api';
import styled from 'styled-components';
import { NitemsUiOptions, FieldValue, NItemsConfiguration } from 'dragon-react';
import { InlineBanner } from 'arc';

import { useDispatch } from 'react-redux';
import { req } from '../../../../../lib/ajax';
import { AddButton, RemoveButton } from '../../../../core/forms/components/NItems';
import NItemsItemSection from '../../../../core/forms/components/NItemsItemSection';
import Row from '../../../../core/components/Row';

import { useSummaryValueForPath } from '../../../hooks/use-summary-value-for-path';
import {
    DragonCurrentFormValue,
    ParentFormPath,
    RMSDragonConfigurationExtensions,
} from '../../../rms-types';
import errorToMessage from '../../../../core/errors/utils/errorToMessage';
import { InlineFormReferenceWrapper } from '../inline-form-reference-wrapper';
import { InlineFormReferenceSummaryWrapper } from '../inline-form-reference-summary-wrapper';

import { useDragonCurrentFormNestingPathContext } from '../../../context/dragon-current-form';
import { getConfiguredFormPath } from '../../../utils/get-configured-form-path';
import { SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { syncCoreInstancesAction } from '../state';
import { isDragonInlineFormReferenceState } from '../../../utils/is-dragon-inline-form-reference-state';
import { getCoreModelOrDragonInstanceId } from '../../../utils/dragon-instance-ids';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { deleteInlineFormReference } from '../../../state';
import { getDataAttributes } from '../utils/get-data-attributes';
import { getInitialValuesForNItem } from '../utils/get-initial-values-for-n-item';
import { uiConfigurationIdToNumber } from '../../../utils/ui-configuration-id-to-number';
import { RMSDragonFieldsetHelper, RMSDragonNItemsNestingCounter } from './shared';

const NItemsRow = styled(Row)`
    & + & {
        margin-top: 12px;
    }
`;

function createInlineFormReferenceInstance({
    configuredFormId,
    parentReferencingUiConfigurationId,
    parentFormPathInstance,
}: {
    configuredFormId: number;
    parentReferencingUiConfigurationId: string;
    parentFormPathInstance: ParentFormPath;
}): Promise<NonNullable<DragonFormsResource.CreateInlineFormReferenceInstance['returns']['data']>> {
    return req<DragonFormsResource.CreateInlineFormReferenceInstance>({
        method: 'POST',
        url: 'dragon/forms/inline/create',
        hideLoadingBar: true,
        data: {
            formConfigurationId: configuredFormId,
            parentReferencingUiConfigurationId: uiConfigurationIdToNumber(
                parentReferencingUiConfigurationId
            ),
            parentFormPathInstance,
        },
    });
}

type DragonNItemsState =
    | {
          state: 'IDLE' | 'LOADING';
      }
    | {
          state: 'ERROR';
          errorType: 'CREATION' | 'DELETION';
          error: string;
      };

type CreateNItemsInstanceAction = {
    type: 'CREATE_NITEMS_INSTANCE';
};

type NItemsInstanceCreationFailedAction = {
    type: 'NITEMS_INSTANCE_CREATION_FAILED';
    payload: string;
};

type NItemsInstanceCreationSucceededAction = {
    type: 'NITEMS_INSTANCE_CREATION_SUCCEEDED';
};

type RemoveNItemsInstanceAction = {
    type: 'REMOVE_NITEMS_INSTANCE';
};

type NItemsInstanceRemovalFailedAction = {
    type: 'NITEMS_INSTANCE_REMOVAL_FAILED';
    payload: string;
};

type NItemsInstanceRemovalSucceededAction = {
    type: 'NITEMS_INSTANCE_REMOVAL_SUCCEEDED';
};

type Actions =
    | CreateNItemsInstanceAction
    | NItemsInstanceCreationFailedAction
    | NItemsInstanceCreationSucceededAction
    | RemoveNItemsInstanceAction
    | NItemsInstanceRemovalFailedAction
    | NItemsInstanceRemovalSucceededAction;

function reducer(state: DragonNItemsState, action: Actions): DragonNItemsState {
    switch (action.type) {
        case 'REMOVE_NITEMS_INSTANCE':
        case 'CREATE_NITEMS_INSTANCE': {
            return {
                state: 'LOADING',
            };
        }
        case 'NITEMS_INSTANCE_REMOVAL_FAILED': {
            return {
                state: 'ERROR',
                errorType: 'DELETION',
                error: action.payload,
            };
        }
        case 'NITEMS_INSTANCE_CREATION_FAILED': {
            return {
                state: 'ERROR',
                errorType: 'CREATION',
                error: action.payload,
            };
        }
        case 'NITEMS_INSTANCE_REMOVAL_SUCCEEDED':
        case 'NITEMS_INSTANCE_CREATION_SUCCEEDED': {
            return {
                state: 'IDLE',
            };
        }
        default: {
            return state;
        }
    }
}

async function handleNitemsRemoval({
    configuration,
    currentFormNestingPath,
    dispatch,
    nitemsProps,
    rmsDispatch,
}: {
    dispatch: (action: Actions) => void;
    nitemsProps: RenderProps<FieldValue>;
    currentFormNestingPath: DragonCurrentFormValue[];
    configuration: NItemsConfiguration<RMSDragonConfigurationExtensions>;
    rmsDispatch: RmsDispatch;
}) {
    // this should never happen and this runtime type check only exist to narrow our type and validate
    // our assumptions
    if (!isDragonInlineFormReferenceState(nitemsProps.item)) {
        throw new Error('Unexpectedly did not find inline form reference state for n-items value');
    }

    try {
        dispatch({
            type: 'REMOVE_NITEMS_INSTANCE',
        });
        const deletionResponse = await deleteInlineFormReference({
            instanceIds: [getCoreModelOrDragonInstanceId(nitemsProps.item)],
            parentFormPathInstance: getConfiguredFormPath(currentFormNestingPath, {
                onlyParentPath: false,
            }),
            parentReferencingUiConfigurationId: configuration.id,
            configuredFormId: configuration.formConfigurationId,
        });
        dispatch({
            type: 'NITEMS_INSTANCE_REMOVAL_SUCCEEDED',
        });
        // remove item from form
        nitemsProps.removeItem();

        // remove all deleted entities from rms state
        rmsDispatch(
            syncCoreInstancesAction(
                deletionResponse.deletedCoreInstances,
                deletionResponse.updatedCoreInstances
            )
        );
    } catch (e) {
        dispatch({
            type: 'NITEMS_INSTANCE_REMOVAL_FAILED',
            payload: errorToMessage(e),
        });
    }
}

export function NItems(
    componentProps: NitemsUiOptions<RMSDragonConfigurationExtensions>
): JSX.Element {
    const { props, renderChildren, configuration } = componentProps;
    const [dragonNitemsState, dispatch] = React.useReducer(reducer, { state: 'IDLE' });
    const currentFormNestingPath = useDragonCurrentFormNestingPathContext();
    const rmsDispatch: RmsDispatch = useDispatch();

    return (
        <RMSDragonFieldsetHelper
            key={props.key}
            label={props.label}
            {...getDataAttributes({
                label: configuration.ui.label,
                fullyQualifiedPath: componentProps.fullyQualifiedPath,
            })}
            render={() => (
                <RMSDragonNItemsNestingCounter
                    render={({ nestingLevel }) => (
                        <MFTNItems<FieldValue>
                            {...props}
                            addItemOnEmpty={false}
                            addText="Add"
                            renderRowContainer={(props) => (
                                <NItemsRow key={props.index}>{props.itemElement}</NItemsRow>
                            )}
                            render={(nitemsProps) => (
                                <InlineFormReferenceWrapper
                                    options={{
                                        ...componentProps,
                                        // we have to append the index to the path in order to get the correct value out of our form state
                                        fullyQualifiedPath: `${componentProps.fullyQualifiedPath}[${nitemsProps.index}]`,
                                    }}
                                >
                                    <NItemsItemSection
                                        deleteButton={
                                            <RemoveButton
                                                isDisabled={dragonNitemsState.state === 'LOADING'}
                                                onClick={() =>
                                                    handleNitemsRemoval({
                                                        configuration,
                                                        currentFormNestingPath,
                                                        dispatch,
                                                        nitemsProps,
                                                        rmsDispatch,
                                                    })
                                                }
                                                className={undefined}
                                            />
                                        }
                                        nestingLevel={nestingLevel}
                                    >
                                        {renderChildren({
                                            index: nitemsProps.index,
                                            key: nitemsProps.index,
                                        })}
                                    </NItemsItemSection>
                                </InlineFormReferenceWrapper>
                            )}
                            renderRemoveButton={undefined}
                            renderAddButton={({ addText, addItem }) => (
                                <Row className="mark43-n-items-add-row">
                                    {dragonNitemsState.state === 'LOADING' ? (
                                        <SimpleLoading />
                                    ) : null}
                                    {dragonNitemsState.state === 'ERROR' ? (
                                        <InlineBanner
                                            status="error"
                                            title=""
                                            description={
                                                dragonNitemsState.errorType === 'CREATION'
                                                    ? 'Failed to add item, please try again'
                                                    : 'Failed to remove item, please try again'
                                            }
                                        />
                                    ) : null}
                                    <AddButton
                                        isDisabled={dragonNitemsState.state === 'LOADING'}
                                        onClick={async () => {
                                            try {
                                                dispatch({
                                                    type: 'CREATE_NITEMS_INSTANCE',
                                                });
                                                const inlineFormValuesView = await createInlineFormReferenceInstance(
                                                    {
                                                        parentFormPathInstance: getConfiguredFormPath(
                                                            currentFormNestingPath,
                                                            { onlyParentPath: false }
                                                        ),
                                                        parentReferencingUiConfigurationId:
                                                            configuration.id,
                                                        configuredFormId:
                                                            configuration.formConfigurationId,
                                                    }
                                                );
                                                dispatch({
                                                    type: 'NITEMS_INSTANCE_CREATION_SUCCEEDED',
                                                });
                                                addItem(
                                                    getInitialValuesForNItem(
                                                        inlineFormValuesView,
                                                        configuration
                                                    )
                                                );
                                            } catch (e) {
                                                dispatch({
                                                    type: 'NITEMS_INSTANCE_CREATION_FAILED',
                                                    payload: errorToMessage(e),
                                                });
                                            }
                                        }}
                                    >
                                        {addText}
                                    </AddButton>
                                </Row>
                            )}
                        />
                    )}
                />
            )}
        />
    );
}

const NItemsSummaryItemWrapper = styled.div<{ nestingLevel: number }>`
    display: flex;
    flex-direction: column;
    clear: both;
    padding: 5px;
    margin-bottom: 12px;
    background-color: ${(props) =>
        props.nestingLevel % 2 === 0
            ? props.theme.colors.extraLightGrey
            : // theme doesn't support this kind of gray at the moment
              '#e2e2e2'};
    border-radius: 5px;
`;

export function NItemsSummary(
    componentProps: NitemsUiOptions<RMSDragonConfigurationExtensions>
): JSX.Element {
    const { props, renderChildren, fullyQualifiedPath } = componentProps;
    const value = useSummaryValueForPath(fullyQualifiedPath);
    if (!Array.isArray(value)) {
        throw new Error(`Unexpectedly did not find array value for path ${fullyQualifiedPath}`);
    }
    return (
        <RMSDragonFieldsetHelper
            key={props.key}
            label={props.label}
            render={() => (
                <RMSDragonNItemsNestingCounter
                    render={({ nestingLevel }) => (
                        <>
                            {value.map((fn, index) => (
                                <InlineFormReferenceSummaryWrapper
                                    key={index}
                                    options={{
                                        ...componentProps,
                                        fullyQualifiedPath: `${componentProps.fullyQualifiedPath}[${index}]`,
                                    }}
                                >
                                    <NItemsSummaryItemWrapper nestingLevel={nestingLevel}>
                                        {renderChildren({ index, key: index })}
                                    </NItemsSummaryItemWrapper>
                                </InlineFormReferenceSummaryWrapper>
                            ))}
                        </>
                    )}
                />
            )}
        />
    );
}

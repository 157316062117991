import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavCollapse } from '@arc/navigation';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { useScreenBreakpoint } from '../../../core/utils/useScreenBreakpoint';
import Card, { CardSection } from '../../../../legacy-redux/components/core/Card';
import theme from '../../../core/styles/theme';
import Icon from '../../../core/components/Icon';
import { TinyArbiterMFTNarrativeCardEditor } from './narrative/TinyArbiterMFTNarrativeCardEditor';

// This is how we can add typings to styled components that are still in javascript
// https://stackoverflow.com/a/64123669
/**
 * @typedef {{
 *   isExpandedFullScreen?: boolean;
 * }} NarrativeDisplayContainerProps */
export const NarrativeDisplayContainer =
    /** @type {import('styled-components').ThemedStyledFunction<'div', {}, NarrativeDisplayContainerProps, never>} */
    (styled.div)`
        color: ${(props) => props.theme.colors.darkGrey};
        font-size: var(--arc-fontSizes-md);
        box-sizing: border-box;
        height: 100%;
        border-top: 1px solid ${(props) => props.theme.colors.extraLightGrey};

        & form {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        ${(props) => (props.isExpandedFullScreen ? 'overflow: auto;' : '')}
    `;

export const CardSectionWithEqualPadding = styled(CardSection)`
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    float: none;

    > *:not(:first-child) {
        margin-top: 10px;
    }
`;

export const TinyStyledArbiterMFTNarrativeCardEditor = styled(TinyArbiterMFTNarrativeCardEditor)`
    .tox-editor-header {
        ${(props) =>
            props.isSticky &&
            `
            position: sticky;
            top: ${props.offset}px;
        `}
    }
`;

/**
 * Use this string as the css `top` value for the expanded narrative card.
 */
function computeTop(hasBanner, isMobile, hasHeader = 'true', isMainNavEnabled) {
    const headerHeightWithLeftNav = isMobile ? 48 : 0;
    const headerHeight = isMainNavEnabled
        ? headerHeightWithLeftNav
        : hasHeader
        ? theme.heights.header
        : 0;
    const subheaderHeight = isMobile ? 144 : 81;
    const bannerHeight = hasBanner ? (isMobile ? 106 : theme.heights.alertBanner) : 0;

    return `${headerHeight + bannerHeight + subheaderHeight}px`;
}

function computeWidth(showOpenInlineComments, isMobile) {
    if (showOpenInlineComments) {
        return isMobile ? '50%' : '65%';
    }
    return 'auto';
}

const StyledCardWithExpandedSupport = styled(Card)`
    ${(props) =>
        props.isExpandedFullScreen &&
        `
            position: fixed;
            top: ${computeTop(
                props.hasBanner,
                props.$isMobile,
                props.hasHeader,
                props.$isMainNavEnabled
            )};
            right: 0;
            left: 0;
            bottom: 0;
            width: ${computeWidth(props.showOpenInlineComments, props.isMobile)};
            z-index: 5000;
            margin-top: 0;
            // Stomp on the styles for Card in ArrestBlock
            margin-bottom: 0 !important;
            ${
                props.$isMainNavEnabled &&
                `@media(min-width: 48em) {
                    left: 88px;
                };`
            }
            ${
                props.$isMainNavEnabled &&
                `@media(min-width: 80em) {
                    left: ${props.$isNavCollapsed ? '88px' : '224px'};
                };`
            }
            }
        `}

    && .mark43-react-card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }
`;

export const CardWithExpandedSupport = (props) => {
    const { isMobile } = useScreenBreakpoint();
    // This can be removed when the RMS_ARC_NAVIGATION_ENABLED feature flag is torn down.
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isMainNavEnabled = applicationSettings.RMS_ARC_NAVIGATION_ENABLED;
    const [navCollapse] = useNavCollapse();

    return (
        <StyledCardWithExpandedSupport
            $isMobile={isMobile}
            $isMainNavEnabled={isMainNavEnabled}
            $isNavCollapsed={navCollapse}
            {...props}
        />
    );
};

export const ExpandCollapseIcon = styled(Icon)`
    fill: ${(props) =>
        props.summaryMode ? props.theme.colors.cobaltBlue : props.theme.colors.white};
`;

import { Mention, MentionCautions } from '../types';
import { CautionComputedData } from '../../../../../cautions/configuration';
import { ArcPriorityEnum } from '../../../../../components/tags/helpers';
import { getTruthyValues } from '../utils';

const getCautions = (mentionCautions?: MentionCautions) => {
    if (!mentionCautions?.cautions?.length) {
        return;
    }

    const { cautions, hiddenCautionsCounter } = mentionCautions;

    const types: Record<ArcPriorityEnum, string> = {
        [ArcPriorityEnum.PriorityOne]: 'caution--priority-one',
        [ArcPriorityEnum.PriorityTwo]: 'caution--priority-two',
        [ArcPriorityEnum.PriorityThree]: 'caution--priority-three',
        [ArcPriorityEnum.PriorityFour]: 'caution--default',
    };

    const renderCaution = ({ label, priority }: CautionComputedData) => `
        <div class="caution ${types[priority]}">
            <p class="caution__name" title="${label}">${label}</p>
        </div>
    `;

    return `
        <style>
            div.caution,
            .tox div.caution {
                display: flex;
                padding: 0.125rem 0.25rem;
                align-items: center;
                gap: var(--arc-space-2, 0.25rem);

                border-radius: 0.25rem;
                background: var(--arc-colors-neutral-default, #DAE0E5);
                color: var(--arc-colors-text-primary, #1E1F20);

                height: 1.125rem;
                max-width: 15rem;
            }
            p.caution__name,
            .tox p.caution__name,
            p.caution-extra,
            .tox p.caution-extra {
                font-size: var(--arc-fontSizes-xs, 0.75rem);
                font-style: normal;
                font-weight: 600;
                line-height: 0.75rem;
            }
            p.caution__name,
            .tox p.caution__name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            div.caution-extra,
            .tox div.caution-extra {
                color: var(--arc-colors-text-primary, #1E1F20);
            }
            div.caution--priority-one,
            .tox div.caution--priority-one {
                background: var(--arc-colors-negative-default, #C22E31);
                color: var(--arc-caution-text, #FFFFFF);
            }
            div.caution--priority-two,
            .tox div.caution--priority-two {
                background: var(--arc-colors-raw-orange-100, #FDE7C6);
                color: var(--arc-colors-raw-orange-800, #602314);
            }
            div.caution--priority-three,
            .tox div.caution--priority-three {
                background: var(--arc-colors-raw-purple-100, #E9D8FD);
                color: var(--arc-colors-raw-purple-800, #44337A);
            }
        </style>
        
        ${cautions.map(renderCaution).join('')}
        ${hiddenCautionsCounter ? `<p class="caution-extra">+${hiddenCautionsCounter}</p>` : ''}
    `;
};

const getDescription = (descriptions?: string[]) => {
    if (!descriptions || !descriptions.length) {
        return;
    }

    return `${descriptions.join(`<span class="text-divider"></span>`)}`;
};

const getExtraInfo = (extraInfo?: string[]) => {
    if (!extraInfo || !extraInfo.length) {
        return;
    }

    return `${getTruthyValues(extraInfo).map((item) => `<p class="extra-info">${item}</p>`)}`;
};

const getNameWithCreatedDate = (name: string, createdDate?: string) => {
    if (!createdDate) {
        return `<p class="card__name">${name}</p>`;
    }

    return `
        <style>
            div.card .card__name-wrapper,
            .tox div.card .card__name-wrapper {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: var(--arc-space-2);
                width: 100%;
            }
            div.card .card__created-date,
            .tox div.card .card__created-date {
                color: var(--arc-colors-text-tertiary, #6D7278);
                font-size: var(--arc-fontSizes-xs, 0.75rem);
                font-family: var(--arc-fonts-body);
                font-style: normal;
                font-weight: 400;
            }
        </style>
        <div class="card__name-wrapper">
            <p class="card__name">${name}</p>
            <p class="card__created-date">${createdDate}</p>
        </div>
    `;
};

type CardPopoverProps = {
    mention: Mention;
};

export const cardPopover = ({ mention }: CardPopoverProps) => {
    const { name, photo, defaultIcon, createdDate } = mention;

    const cautions = getCautions(mention.cautions);
    const description = getDescription(mention.descriptions);
    const extraInfo = getExtraInfo(mention.extraInfo);
    const nameWithCreatedDate = getNameWithCreatedDate(name, createdDate);

    return `
        <style>
            div.card,
            .tox div.card {
                background: white;
                border-radius: 0.375rem;
                border-top: 1px solid var(--arc-colors-border-default, #DAE0E5);
                border-right: var(--arc-space-px, 1px) solid var(--arc-colors-border-default, #DAE0E5);
                border-bottom: var(--arc-space-px, 1px) solid var(--arc-colors-border-default, #DAE0E5);
                border-left: var(--arc-space-px, 1px) solid var(--arc-colors-border-default, #DAE0E5);
                background: var(--arc-colors-surface-foreground, #FFF);

                box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);

                display: flex;
                width: 100%;
                min-width: 12.5rem;
                max-width: 20.5rem;
                padding: var(--arc-space-2, 0.5rem);

                align-items: flex-start;
                align-content: flex-start;
                gap: 0.5rem var(--arc-space-2, 0.5rem);

                font-size: var(--arc-fontSizes-sm, 0.875rem);
                font-family: var(--arc-fonts-body);
            }
            div.card .card__wrapper,
            .tox div.card .card__wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: var(--arc-space-half);
                width: 100%;
            }

            div.card .card__name,
            .tox div.card .card__name {
                color: var(--arc-colors-text-primary, #1E1F20);

                font-family: var(--arc-fonts-body);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                word-break: break-word;
            }

            div.card .mention-default-avatar,
            .tox div.card .mention-default-avatar,
            div.card div.card__avatar,
            .tox div.card div.card__avatar {
                position: relative;
                display: flex;
                width: 2.5rem;
                height: 2.5rem;
                align-items: center;
                gap: var(--arc-space-8, 2rem)
                flex-shrink: 0;
                overflow: hidden;

                border-radius: 0.375rem;
                background: var(--arc-colors-border-default, #DAE0E5);
                color: var(--arc-colors-text-tertiary, #6D7278)
            }

            div.card .mention-default-icon,
            .tox div.card .mention-default-icon {
                display: flex;
                width: 1.5rem;
                height: 1.5rem;
                align-items: center;
                flex-shrink: 0;
                overflow: hidden;
            }

            div.card .mention-default-avatar svg,
            .tox div.card .mention-default-avatar svg,
            div.card div.card__avatar svg,
            .tox div.card div.card__avatar svg {
                padding: var(--arc-space-2, 0.5rem);
            }

            div.card div.card__avatar img,
            .tox div.card div.card__avatar img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            div.card p.card__description,
            .tox div.card p.card__description {
                color: var(--arc-colors-text-secondary, #43464A);
                font-size: var(--arc-fontSizes-xs, 0.75rem);
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                word-break: break-word;
            }

            div.card .card__extra-info,
            .tox div.card .card__extra-info {
                color: var(--arc-colors-text-secondary, #43464A);
                font-size: var(--arc-fontSizes-xs, 0.75rem);
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }

            div.card .card__cautions,
            .tox div.card .card__cautions {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: var(--arc-space-half, 0.25rem)
            }

            div.card span.text-divider,
            .tox div.card  span.text-divider {
                display: inline-flex;
                width: 0.0625rem;
                height: 0.75rem;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: var(--arc-colors-border-default, #DAE0E5);
                margin: var(--arc-space-1, 0.25rem);
                vertical-align: middle;
            }
        </style>

        <div class="card">
            ${photo ? `<div class="card__avatar"><img src="${photo}"></div>` : defaultIcon}

            <div class="card__wrapper">
                ${nameWithCreatedDate}
                ${cautions ? `<div class="card__cautions">${cautions}</div>` : ''}
                ${description ? `<p class="card__description">${description}</p>` : ''}
                ${extraInfo ? `<div class="card__extra-info">${extraInfo}</div>` : ''}
            </div>
        </div>
    `;
};

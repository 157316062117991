import keyMirror from 'keymirror';

export default keyMirror({
    NO_FILTER: null,
    WITHIN_LAST_PERIOD: null,
    TO_DATE_PERIOD: null,
    CUSTOM_RANGE: null,
    CUSTOM_TIME: null,
});

export type WithinLastPeriodOption = `${'P' | 'PT'}${number}${'H' | 'D'}`;
export type ToDatePeriodOption = `${'P' | 'PT'}${number}${'M' | 'Y'}`;

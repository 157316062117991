import {
    EntityTypeEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    UsageSourceModuleEnum,
} from '@mark43/rms-api';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import { formatCaseName } from '~/client-common/core/domain/cases/utils/caseHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import {
    startPollingForCurrentViewers,
    stopPollingForCurrentViewers,
} from '~/client-common/core/domain/current-viewers/state/polling';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { rootFolderByFolderIdSelector } from '~/client-common/core/domain/folders/state/data';
import { retrieveCaseTasks } from '../tasks/cases/state/ui';
import redirectToErrorPage from '../core/utils/redirectToErrorPage';
import { createUsageLog } from '../admin/usage-logs/state/data';
import { closeLoadingModal, openLoadingModal } from '../../legacy-redux/actions/boxActions';
import checkPagePermissions from '../core/utils/checkPagePermissions';
import { tasksDashboardSearch } from '../tasks/dashboard/state/ui';
import { resetCurrentCaseAttachmentsUi } from './case-attachments/state/ui';
import { getFolderContents } from './core/state/data/folderContents';
import {
    activeTabSelector,
    currentCaseNoteSelector,
    currentCaseViewSelector,
    getCompleteCaseView,
    resetCurrentCaseGeneralUi,
    setCurrentCaseNoteId,
} from './core/state/ui';
import { getFolderHierarchyForFolder, getTopLevelFolders } from './core/state/data/folders';
import { updateOrphanContents } from './core/state/data/orphanedContents';

import { setCurrentCaseTasks } from './task-list/state/ui';
import { setCurrentCaseLinkedProfiles } from './linked-profiles/state/ui/action/creators';
import { loadCaseAssociatedRecords } from './case-associated-records/state/ui';

/**
 * Hook for when a cases route is entered.
 */
export function casesOnEnter({ params }) {
    // if the user doesn't have permission to view cases, redirect them out
    const shouldContinue = this.dispatch(checkPagePermissions(abilitiesEnum.CASES.VIEW_GENERAL));
    if (!shouldContinue) {
        this.dispatch(
            createUsageLog({
                primaryEntityType: EntityTypeEnum.CASE.name,
                primaryEntityId: parseInt(params.caseId),
                primaryEntityTitle: '', // unknown if you don't have perms
                sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
                action: UsageActionEnum.VIEWED_CASE.name,
                completion: UsageCompletionEnum.INSUFFICIENT_PERMISSIONS.name,
            })
        );
        return;
    }
}

/**
 * We save the previously selected tab so this functions as a dynamic IndexRedirect
 */
export function casesRedirectOnEnter(config, replace) {
    const activeTab = activeTabSelector(this.getState());
    // Handling redirect to mine if a tab hasn't been selected before
    // otherwise redirect to that tab
    if (!activeTab) {
        // The index redirect route
        replace('/cases/mine');
    } else {
        replace(`/cases/${activeTab}`);
    }
}

export function caseOnEnter({ params }) {
    const caseId = parseInt(params.caseId);
    this.dispatch(openLoadingModal());
    this.dispatch(tasksDashboardSearch.actionCreators.resetState());
    this.dispatch(retrieveCaseTasks(caseId));
    this.dispatch(getTopLevelFolders(params.caseId, EntityTypeEnum.ATTACHMENT.name));
    this.dispatch(getTopLevelFolders(params.caseId, EntityTypeEnum.CASE_NOTE.name));
    this.dispatch(getCompleteCaseView(params.caseId, false))
        .then((completeCaseView) => {
            this.dispatch(loadCaseAssociatedRecords(caseId)).then(() => {
                if (
                    !applicationSettingsSelector(this.getState())
                        .RMS_TASK_AND_REQUEST_TRACKING_ENABLED
                ) {
                    // primarily used for number of pending tasks on the tab title.
                    // use retrieveCaseTasks for actual tasks and sort order.
                    this.dispatch(setCurrentCaseTasks(completeCaseView.caseTaskViews));
                }

                if (applicationSettingsSelector(this.getState()).RMS_CASE_FOLDERING_ENABLED) {
                    this.dispatch(updateOrphanContents());
                }

                this.dispatch(setCurrentCaseLinkedProfiles(completeCaseView));
                this.dispatch(startPollingForCurrentViewers(EntityTypeEnum.CASE.name, caseId));
                this.dispatch(closeLoadingModal());

                const caseInCaseView = completeCaseView.cases[0] || {};

                this.dispatch(
                    createUsageLog(
                        {
                            primaryEntityType: EntityTypeEnum.CASE.name,
                            primaryEntityId: caseId,
                            primaryEntityTitle: formatCaseName(
                                caseInCaseView.localId,
                                caseInCaseView.title
                            ),
                            primaryEntityDepartmentId: caseInCaseView.departmentId,
                            sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
                            action: UsageActionEnum.VIEWED_CASE.name,
                            completion: UsageCompletionEnum.SUCCEEDED.name,
                        },
                        { isParent: true }
                    )
                );
            });
        })
        .catch((err) => {
            this.dispatch(
                createUsageLog({
                    primaryEntityType: EntityTypeEnum.CASE.name,
                    primaryEntityId: caseId,
                    primaryEntityTitle: '', // if it fails, we won't know the case name
                    sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
                    action: UsageActionEnum.VIEWED_CASE.name,
                    completion: UsageCompletionEnum.SERVER_ERROR.name,
                })
            );
            this.dispatch(redirectToErrorPage({ errorCode: err.code }));
        });
}

export function createCaseUsageLog({
    usageLogAction,
    usageLogCompletion = UsageCompletionEnum.SUCCEEDED.name,
}) {
    return (dispatch, getState) => {
        const currentCase = currentCaseViewSelector(getState());

        if (currentCase) {
            const caseName = getViewModelProperties(currentCase).caseName;

            return dispatch(
                createUsageLog({
                    primaryEntityType: EntityTypeEnum.CASE.name,
                    primaryEntityId: currentCase.id,
                    primaryEntityTitle: caseName,
                    primaryEntityDepartmentId: currentCase.departmentId,
                    sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
                    action: usageLogAction,
                    completion: usageLogCompletion,
                })
            );
        }
    };
}

function createCaseNoteUsageLog() {
    return (dispatch, getState) => {
        const state = getState();
        const currentCase = currentCaseViewSelector(state);
        const currentCaseNote = currentCaseNoteSelector(state);

        if (currentCase && currentCaseNote) {
            const caseNoteName = currentCaseNote.title;

            return dispatch(
                createUsageLog({
                    primaryEntityType: EntityTypeEnum.CASE_NOTE.name,
                    primaryEntityId: currentCaseNote.id,
                    primaryEntityTitle: `${caseNoteName}`,
                    primaryEntityDepartmentId: currentCase.departmentId,
                    sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
                    action: UsageActionEnum.VIEWED_CASE_NOTE.name,
                    completion: UsageCompletionEnum.SUCCEEDED.name,
                })
            );
        }
    };
}

export function caseSummaryOnEnter() {
    return this.dispatch(
        createCaseUsageLog({
            usageLogAction: UsageActionEnum.VIEWED_CASE_SUMMARY.name,
        })
    );
}

export function caseNotesOnEnter() {
    return this.dispatch(
        createCaseUsageLog({
            usageLogAction: UsageActionEnum.VIEWED_CASE_NOTES_LIST.name,
        })
    );
}

export function caseNoteOnEnter({ params }) {
    const noteId = parseInt(params.noteId);
    return Promise.all([
        this.dispatch(setCurrentCaseNoteId(noteId)),
        this.dispatch(createCaseNoteUsageLog()),
    ]);
}

export function caseLinkedProfilesOnEnter() {
    return this.dispatch(
        createCaseUsageLog({
            usageLogAction: UsageActionEnum.VIEWED_CASE_LINKED_PROFILE.name,
        })
    );
}

export function caseTaskListOnEnter() {
    return this.dispatch(
        createCaseUsageLog({
            usageLogAction: UsageActionEnum.VIEWED_CASE_TASK_LIST.name,
        })
    );
}

export function caseAttachmentsOnEnter() {
    return this.dispatch(
        createCaseUsageLog({
            usageLogAction: UsageActionEnum.VIEWED_CASE_ATTACHMENTS.name,
        })
    );
}

export function caseAttachmentFolderOnEnter({ params }) {
    const { caseId, folderId } = params;

    const topFolderId = rootFolderByFolderIdSelector(this.getState())(
        folderId,
        EntityTypeEnum.ATTACHMENT.name
    );

    this.dispatch(getFolderContents(folderId, EntityTypeEnum.ATTACHMENT.name));
    this.dispatch(
        getFolderHierarchyForFolder(caseId, EntityTypeEnum.ATTACHMENT.name, folderId, topFolderId)
    );
}

export function caseNoteFolderOnEnter({ params }) {
    const { caseId, folderId } = params;

    const topFolderId = rootFolderByFolderIdSelector(this.getState())(
        folderId,
        EntityTypeEnum.CASE_NOTE.name
    );

    this.dispatch(getFolderContents(folderId, EntityTypeEnum.CASE_NOTE.name));
    this.dispatch(
        getFolderHierarchyForFolder(caseId, EntityTypeEnum.CASE_NOTE.name, folderId, topFolderId)
    );
}

export function caseAssociatedRecordsOnEnter() {
    return this.dispatch(
        createCaseUsageLog({
            usageLogAction: UsageActionEnum.VIEWED_CASE_ASSOCIATED_RECORDS.name,
        })
    );
}

export function caseOnLeave({ params } = {}) {
    if (params && params.caseId) {
        this.dispatch(stopPollingForCurrentViewers(EntityTypeEnum.CASE.name, params.caseId));
    }
    this.dispatch(resetCurrentCaseGeneralUi());
    // reset the attachments sorting
    this.dispatch(resetCurrentCaseAttachmentsUi());
}

import _, { get, map } from 'lodash';
import { ChainOfCustody } from '@mark43/evidence-api';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'chainOfCustodies';

const chainOfCustodiesModule = createNormalizedModule<ChainOfCustody>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeChainOfCustodies = chainOfCustodiesModule.actionCreators.storeEntities;

// SELECTORS
export const chainOfCustodiesSelector = chainOfCustodiesModule.selectors.entitiesSelector;
export const chainOfCustodiesWhereSelector = chainOfCustodiesModule.selectors.entitiesWhereSelector;

/**
 * Latest chain of custody for a given master item ID.
 */
export const latestChainOfCustodyForMasterItemIdSelector = createSelector(
    chainOfCustodiesWhereSelector,
    (chainOfCustodiesWhere) => (masterItemId: number) =>
        _(chainOfCustodiesWhere({ masterItemId })).sortBy('createdDateUtc').last()
);

/**
 * Latest chain of custody ID for a given master item ID.
 */
export const latestChainOfCustodyIdForMasterItemIdSelector = createSelector(
    latestChainOfCustodyForMasterItemIdSelector,
    (latestChainOfCustodyForMasterItemId) => (masterItemId: number) =>
        get(latestChainOfCustodyForMasterItemId(masterItemId), 'id')
);

/**
 * Latest chain of custody for each master item ID given a list of them.
 */
export const latestChainOfCustodiesForMasterItemIdsSelector = createSelector(
    latestChainOfCustodyForMasterItemIdSelector,
    (latestChainOfCustodyForMasterItemId) => (masterItemIds: number[]) =>
        map(masterItemIds, latestChainOfCustodyForMasterItemId)
);

// REDUCER
/**
 * A chain of custody is a model in itself that is linked to a collection of
 *   chain events. It contains data that each chain event doesn't have, such as
 *   `masterItemId` and `reportId`. In code, we call them `chainOfCustodies`
 *   rather than `chainsOfCustody` by convention.
 */
export default chainOfCustodiesModule.reducerConfig;

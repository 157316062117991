import { useReducer } from 'react';

const loadingActionTypes = {
    LOADING_START: 'LOADING_START',
    LOADING_SUCCESS: 'LOADING_SUCCESS',
    LOADING_ERROR: 'LOADING_ERROR',
} as const;

/**
 * Public action creators for updating loading state
 */
export const loadingActionCreators = {
    loadingStart: () => ({ type: loadingActionTypes.LOADING_START }),
    loadingSuccess: () => ({ type: loadingActionTypes.LOADING_SUCCESS }),
    loadingError: (errorMessage: string) => ({
        type: loadingActionTypes.LOADING_ERROR,
        payload: errorMessage,
    }),
} as const;

export type LoadingState = {
    errorMessage: string;
    isLoading: boolean;
};

type ActionTypes = ReturnType<typeof loadingActionCreators[keyof typeof loadingActionCreators]>;

/**
 * Loading state hook
 */
export function useLoadingState() {
    const initialState = { errorMessage: '', isLoading: false };

    function reducer(state: LoadingState, action: ActionTypes): LoadingState {
        switch (action.type) {
            case loadingActionTypes.LOADING_START:
                return { errorMessage: '', isLoading: true };
            case loadingActionTypes.LOADING_SUCCESS:
                return initialState;
            case loadingActionTypes.LOADING_ERROR:
                return { errorMessage: action.payload, isLoading: false };
            default:
                return state;
        }
    }

    return useReducer(reducer, initialState);
}

import { EntityTypeEnum, FileStatusEnum } from '@mark43/rms-api';
import {
    chain,
    compact,
    filter,
    find,
    forEach,
    includes,
    map,
    min,
    pick,
    slice,
    some,
} from 'lodash';
import Promise from 'bluebird';
import { createSelector } from 'reselect';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import getAttachmentsResource from '~/client-common/core/domain/attachments/resources/attachmentsResource';
import {
    storeAttachments,
    updateFilesForAttachments,
} from '~/client-common/core/domain/attachments/state/data';
import {
    sortedAugmentedAttachmentViewModelsWhereSelector,
    convertFileUploadResponseToAttachmentLink,
} from '~/client-common/core/domain/attachments/state/ui';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { filterFormData } from '~/client-common/helpers/formHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { updateFiles } from '~/client-common/core/domain/files/state/data';

import {
    DEFAULT_ATTACHMENTS_SHOWN_COUNT,
    UNPERSISTED_ENTITY_ID,
    VIEW_MORE_INCREMENT,
} from '../../configuration';
import { setAttachmentCardTitle } from '../../../../../legacy-redux/actions/reportsActions';
import { createFolderContents } from '../../../../cases/core/state/data/folderContents';
import { updateOrphanContents } from '../../../../cases/core/state/data/orphanedContents';
import { hasFailedUploads } from '../../utils/uploadFilesHelpers';
import { currentCaseAttachmentsByFolderIdSelector, currentCaseFolderAttachmentsRowViewModelSelector } from '../../../../cases/core/state/ui';

import { checkForAttachmentThumbnails } from './attachmentsPolling';
import { pollingFileSecurityScannerEnd } from './securityScanning';

const attachmentSidePanelStrings = componentStrings.reports.core.AttachmentsSidePanel;

const ATTACHMENT_SIDEPANEL_FORM = 'ATTACHMENT_SIDEPANEL_FORM';
const UPDATE_UPLOADS = 'attachmentsSidePanel/UPDATE_UPLOADS';
const ADD_UPLOADING_FILES = 'attachmentsSidePanel/ADD_UPLOADING_FILES';
const REPLACE_UPLOADING_FILES = 'attachmentsSidePanel/REPLACE_UPLOADING_FILES';
const SAVE_ATTACHMENTS_START = 'attachmentsSidePanel/SAVE_ATTACHMENTS_START';
const SAVE_ATTACHMENTS_SUCCESS = 'attachmentsSidePanel/SAVE_ATTACHMENTS_SUCCESS';
const SAVE_ATTACHMENTS_FAILURE = 'attachmentsSidePanel/SAVE_ATTACHMENTS_FAILURE';
const DELETE_ATTACHMENT_FAILURE = 'attachmentsSidePanel/DELETE_ATTACHMENT_FAILURE';
const SET_ATTACHMENTS_SHOWN_COUNT = 'attachmentsSidePanel/SET_ATTACHMENTS_SHOWN_COUNT';

// selectors
const attachmentsSidePanelUiSelector = (state) => state.ui.attachments.attachmentsSidePanel;

export const uploadedFilesSelector = createSelector(
    attachmentsSidePanelUiSelector,
    ({ uploadedFiles }) => uploadedFiles
);

export const uploadingFilesSelector = createSelector(
    attachmentsSidePanelUiSelector,
    ({ uploadingFiles }) => uploadingFiles
);

export const attachmentsShownCountSelector = createSelector(
    attachmentsSidePanelUiSelector,
    ({ attachmentsShownCount }) => attachmentsShownCount
);

const attachmentsFilesImagesSelector = createSelector(
    sortedAugmentedAttachmentViewModelsWhereSelector,
    (sortedAugmentedAttachmentViewModelsWhere) => ({
        entityType,
        entityId,
        imageLinkType,
        attachmentLinkType,
    }) => {
        const attachmentImages = sortedAugmentedAttachmentViewModelsWhere({
            entityType,
            entityId,
            linkType: imageLinkType,
        });
        const attachmentFiles = sortedAugmentedAttachmentViewModelsWhere({
            entityType,
            entityId,
            linkType: attachmentLinkType,
        });
        const attachments = attachmentFiles.concat(attachmentImages);
        return attachments;
    }
);

export const currentAttachmentsByFolderIdAndEntityTypeSelector = createSelector(
    currentCaseAttachmentsByFolderIdSelector,
    attachmentsFilesImagesSelector,
    currentCaseFolderAttachmentsRowViewModelSelector,
    applicationSettingsSelector,
    (currentCaseAttachmentsByFolderId, attachmentsFilesImages, convertAttachmentsToCaseAttachmentsRowViewModels, applicationSettings) => ({
        folderId,
        entityType,
        entityLinkDetails,
    }) => {
        let attachments = attachmentsFilesImages(entityLinkDetails);
        // This selector only suports CASE entityType, for other entityTypes
        // we need to update switch logic below
        switch (entityType) {
            case EntityTypeEnum.CASE.name:

                if (applicationSettings.RMS_CASE_FOLDERING_ENABLED) {
                    attachments = convertAttachmentsToCaseAttachmentsRowViewModels(
                        folderId
                    ).filter((row) => row.entityType === entityType);
                } else {
                    attachments = currentCaseAttachmentsByFolderId(folderId);
                }

                break;
            default:
                break;
        }
        return attachments;
    }
);

export const existingUploadingFilesSelector = createSelector(
    uploadingFilesSelector,
    (uploadingFiles) => {
        return filter(uploadingFiles, (uploadingFile) => {
            return !uploadingFile.file.isDeleted;
        });
    }
);

export const uploadingImagesSelector = createSelector(
    existingUploadingFilesSelector,
    (existingUploadingFiles) => (imageLinkType) => {
        return filter(existingUploadingFiles, (uploadingFile) => {
            return uploadingFile.config.linkType === imageLinkType;
        });
    }
);

export const uploadedImagesSelector = createSelector(
    uploadedFilesSelector,
    (uploadedFiles) => (imageLinkType) => {
        return filter(uploadedFiles, (uploadingFile) => {
            return uploadingFile.config.linkType === imageLinkType;
        });
    }
);

// actions

export function uploadFileFailure(error, failedFiles) {
    return (dispatch, getState) => {
        const oldUploadingFiles = uploadingFilesSelector(getState());
        const newUploadingFiles = map(oldUploadingFiles, (oldUploadingFile) => {
            const newFile = { ...oldUploadingFile };
            if (
                some(
                    failedFiles,
                    (failedFile) =>
                        oldUploadingFile.file.id === failedFile.preview ||
                        failedFile?.fileStatus === FileStatusEnum.FAIL.name
                )
            ) {
                newFile.file.uploadFailed = true;
                newFile.file.errorMessage = error;
                newFile.file.isLoading = false;
            }
            return newFile;
        });

        dispatch(replaceUploadingFiles(newUploadingFiles));

        if (hasFailedUploads(newUploadingFiles)) {
            return attachmentSidePanelStrings.uploadErrorMessage;
        }
    };
}

export function updateUploads(uploads) {
    return {
        type: UPDATE_UPLOADS,
        payload: uploads,
    };
}

export function addUploadingFiles(uploadingFiles) {
    return { type: ADD_UPLOADING_FILES, payload: uploadingFiles };
}

function replaceUploadingFiles(uploads) {
    return {
        type: REPLACE_UPLOADING_FILES,
        payload: uploads,
    };
}

function saveAttachmentsStart() {
    return {
        type: SAVE_ATTACHMENTS_START,
    };
}

function saveAttachmentsSuccess() {
    return {
        type: SAVE_ATTACHMENTS_SUCCESS,
    };
}

export function viewMoreAttachments(attachmentsShownCount, attachments) {
    return (dispatch, getState, { formsRegistry }) => {
        const attachmentsCount =
            attachmentsShownCount +
            min([attachments.length - attachmentsShownCount, VIEW_MORE_INCREMENT]);
        const form = formsRegistry.get(ATTACHMENT_SIDEPANEL_FORM);
        const newlyShownAttachments = slice(attachments, attachmentsShownCount, attachmentsCount);
        forEach(newlyShownAttachments, (attachment) =>
            form.push(
                'fileDescriptions',
                pick(getAttachmentFile(attachment), ['id', 'description'])
            )
        );
        dispatch(setAttachmentsShownCount(attachmentsCount));
    };
}

function setAttachmentsShownCount(attachmentsCount) {
    return {
        type: SET_ATTACHMENTS_SHOWN_COUNT,
        payload: attachmentsCount,
    };
}

export function initializeAttachmentsSidePanelForm(entityLinkDetails) {
    return (dispatch, getState, { formsRegistry }) => {
        const state = getState();
        const attachments = attachmentsFilesImagesSelector(state)(entityLinkDetails);
        const shownAttachments = slice(attachments, 0, attachmentsShownCountSelector(state));
        const uploadedFiles = uploadedFilesSelector(state);

        const files =
            entityLinkDetails.entityId === UNPERSISTED_ENTITY_ID
                ? map(uploadedFiles, 'file')
                : map(shownAttachments, (attachment) => getAttachmentFile(attachment));

        const fileDescriptions = map(files, (file) => pick(file, ['id', 'description']));

        formsRegistry.maybeDeferredOperation(ATTACHMENT_SIDEPANEL_FORM, undefined, (form) => {
            form.set('fileDescriptions', fileDescriptions);
        });
    };
}

export function cleanupAttachmentsSidePanel() {
    return (dispatch) => {
        dispatch(setAttachmentsShownCount(DEFAULT_ATTACHMENTS_SHOWN_COUNT));
        dispatch(clearUploads());
        dispatch(replaceUploadingFiles([]));
    };
}

export function deleteUpload(uploadedFiles, uploadId) {
    return (dispatch) => {
        const newUploads = filter(uploadedFiles, (upload) => {
            return upload.file.id !== uploadId;
        });
        dispatch(updateUploads(newUploads));
    };
}

export function clearUploads() {
    return (dispatch) => {
        dispatch(updateUploads([]));
        dispatch(pollingFileSecurityScannerEnd());
    };
}

export function clearDirtyFilesFromSidePanel(dirtyUploadingFiles) {
    return (dispatch, getState) => {
        const state = getState();
        const uploadingFiles = uploadingFilesSelector(state);
        const dirtyUploadingFileIds = map(dirtyUploadingFiles, 'file.id');

        const newUploadingFiles = chain(uploadingFiles)
            .filter((item) => {
                return !includes(dirtyUploadingFileIds, item.file.id);
            })
            .value();

        dispatch(replaceUploadingFiles(newUploadingFiles));
    };
}

// want to remove every instance in uploadingFiles that match the uploadedFiles
export function saveUploadedFiles(uploadedFiles) {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(ATTACHMENT_SIDEPANEL_FORM);
        const oldUploadingFiles = uploadingFilesSelector(getState());
        const newUploadingFiles = filter(oldUploadingFiles, (oldUploadingFile) => {
            const match = find(
                uploadedFiles,
                (uploadedFile) => uploadedFile.preview === oldUploadingFile.file.id
            );
            return !match;
        });
        // Filter out any uploadedFiles that were deleted while they were uploading
        const activeUploadedFiles = compact(
            map(uploadedFiles, (uploadedFile, index) => {
                const match = find(
                    oldUploadingFiles,
                    (oldUploadingFile) =>
                        uploadedFile.preview === oldUploadingFile.file.id &&
                        !oldUploadingFile.file.isDeleted
                );
                return match ? uploadedFiles[index] : null;
            })
        );
        dispatch(replaceUploadingFiles(newUploadingFiles));
        const oldUploads = uploadedFilesSelector(getState());

        dispatch(updateUploads([...activeUploadedFiles, ...oldUploads]));
        const formState = form.get('fileDescriptions');
        forEach(activeUploadedFiles, (upload) => {
            formState.unshift({
                id: upload.file.id,
            });
        });
        form.set('fileDescriptions', formState);

        return hasFailedUploads(newUploadingFiles);
    };
}

export function saveAttachmentsSidePanelForm(
    uploadedFiles,
    { entityId, entityType },
    currentFolderId
) {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(ATTACHMENT_SIDEPANEL_FORM);
        const applicationSettings = applicationSettingsSelector(getState());
        return form.submit().then((result) => {
            if (entityId === UNPERSISTED_ENTITY_ID) {
                return dispatch(updateFiles(result.form.getState().model.fileDescriptions)).then(
                    (files) => {
                        const updatedUploads = map(uploadedFiles, (uploadedFile) => {
                            const file = find(files, { id: uploadedFile.file.id });

                            if (!file) {
                                return uploadedFile;
                            }

                            return { ...uploadedFile, file };
                        });
                        dispatch(updateUploads(updatedUploads));
                    }
                );
            } else if (uploadedFiles.length > 0) {
                const attachmentsResource = getAttachmentsResource();
                dispatch(saveAttachmentsStart());
                const attachments = uploadedFiles.map((upload) =>
                    convertFileUploadResponseToAttachmentLink(upload, upload.config)
                );

                const saveAttachments = attachmentsResource.saveAttachmentsForEntities({
                    entityType,
                    entityIds: [entityId],
                    attachments,
                    removeOthers: false,
                });
                const upsertFileDescriptions = dispatch(
                    updateFiles(result.form.getState().model.fileDescriptions)
                );
                return Promise.all([saveAttachments, upsertFileDescriptions]).then(
                    ([newAttachments, files]) => {
                        dispatch(checkForAttachmentThumbnails(newAttachments));
                        dispatch(storeAttachments(newAttachments));
                        dispatch(
                            updateFilesForAttachments(
                                {
                                    entityType,
                                    entityId,
                                },
                                files
                            )
                        );
                        dispatch(saveAttachmentsSuccess());
                        if (entityType === EntityTypeEnum.REPORT.name) {
                            dispatch(setAttachmentCardTitle(entityId));
                        }

                        dispatch(updateUploads([]));

                        if (currentFolderId) {
                            const attachmentIds = map(
                                newAttachments,
                                ({ attachmentId }) => attachmentId
                            );
                            const folderContent = {
                                contentIds: attachmentIds,
                                entityTypeId: EntityTypeEnum.ATTACHMENT.name,
                                folderId: currentFolderId,
                            };
                            return dispatch(
                                createFolderContents({
                                    folderContent,
                                    sourceFolderId: currentFolderId,
                                })
                            ).then(() => {
                                return true;
                            });
                        }
                        if (
                            applicationSettings.RMS_CASE_FOLDERING_ENABLED &&
                            !currentFolderId &&
                            entityType === EntityTypeEnum.CASE.name &&
                            newAttachments.length > 0
                        ) {
                            // TODO: Save this attachment into redux state directly
                            //       without making the API call.
                            //       CHI-1221
                            dispatch(updateOrphanContents());
                        }
                    }
                );
            } else {
                return dispatch(
                    updateFiles(filterFormData(result.form.getState().model.fileDescriptions))
                ).then((files) => {
                    dispatch(
                        updateFilesForAttachments(
                            {
                                entityType,
                                entityId,
                            },
                            files
                        )
                    );
                    if (entityType === EntityTypeEnum.REPORT.name) {
                        dispatch(setAttachmentCardTitle(entityId));
                    }

                    return true;
                });
            }
        });
    };
}

export function deleteUploadingFile(uploadingFiles, uploadId) {
    return (dispatch) => {
        const newUploadingFiles = map(uploadingFiles, (uploadingFile) => {
            const newFile = {
                ...uploadingFile,
            };
            if (uploadingFile.file.id === uploadId) {
                newFile.file.isDeleted = true;
            }
            return newFile;
        });

        dispatch(replaceUploadingFiles(newUploadingFiles));

        return hasFailedUploads(newUploadingFiles);
    };
}

export default function overlayAttachmentsSidePanelUiReducer(
    state = {
        uploadedFiles: [],
        uploadingFiles: [],
        errorMessages: [],
        attachmentsShownCount: DEFAULT_ATTACHMENTS_SHOWN_COUNT,
    },
    action
) {
    switch (action.type) {
        case UPDATE_UPLOADS:
            return {
                ...state,
                uploadedFiles: action.payload,
            };
        case REPLACE_UPLOADING_FILES:
            return {
                ...state,
                uploadingFiles: action.payload,
            };
        case ADD_UPLOADING_FILES:
            return {
                ...state,
                uploadingFiles: [...action.payload, ...state.uploadingFiles],
            };
        case SAVE_ATTACHMENTS_FAILURE:
        case DELETE_ATTACHMENT_FAILURE:
            return {
                ...state,
                errorMessages: [action.payload],
            };
        case SET_ATTACHMENTS_SHOWN_COUNT:
            return {
                ...state,
                attachmentsShownCount: action.payload,
            };
        default:
            return state;
    }
}

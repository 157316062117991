import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { useSubPremiseFormatters } from '../../../../../modules/core/locations/hooks/useSubPremiseFormatters';

const CellContainer = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

const SubdivisionsLine = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    text-transform: none;
`;

const LocationInfo = styled.div`
    color: ${(props) => props.theme.colors.darkGrey};
    margin-top: ${(props) => props.hasSubdivisions && '10px'};
`;

const StyledLocationCell = ({ locationViewModel }) => {
    const { buildElasticLocationLines } = useSubPremiseFormatters();

    if (locationViewModel) {
        const subdivisions = getViewModelProperties(locationViewModel).subdivisionAttrIds;
        return (
            <CellContainer>
                {subdivisions && (
                    <SubdivisionsLine>
                        <span className="subdivisions-info">{subdivisions}</span>
                    </SubdivisionsLine>
                )}
                <LocationInfo hasSubdivisions={!!subdivisions}>
                    {map(buildElasticLocationLines(locationViewModel), (line) => (
                        <div key={line}>{line}</div>
                    ))}
                </LocationInfo>
            </CellContainer>
        );
    }

    return <CellContainer />;
};

export default StyledLocationCell;

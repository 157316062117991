import pluralize from 'pluralize';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_E_FILE } from '~/client-common/core/enums/universal/fields';

export function useEFileDisplayName() {
    const eFileDisplayName = useFields(DISPLAY_ONLY_E_FILE)[DISPLAY_ONLY_E_FILE];
    return {
        pluralEFileDisplayName: pluralize(eFileDisplayName),
        eFileDisplayName,
    };
}

import { NEXUS_STATE_PROP as HISTORY_EVENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/history-events/state/data';
import { augmentHistoryEvents } from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import entityProfilesResource from '../../../../evidence/core/resources/evidenceItemHistoryResource';

const LOAD_ITEM_PROFILE_HISTORY_START = 'report-history/LOAD_ITEM_PROFILE_HISTORY_START';
const LOAD_ITEM_PROFILE_HISTORY_SUCCESS = 'report-history/LOAD_ITEM_PROFILE_HISTORY_SUCCESS';
const LOAD_ITEM_PROFILE_HISTORY_FAILURE = 'report-history/LOAD_ITEM_PROFILE_HISTORY_FAILURE';

function loadEntityProfilesHistoryStart() {
    return { type: LOAD_ITEM_PROFILE_HISTORY_START };
}

function loadEntityProfilesHistorySuccess() {
    return { type: LOAD_ITEM_PROFILE_HISTORY_SUCCESS };
}

function loadEntityProfilesHistoryFailure() {
    return { type: LOAD_ITEM_PROFILE_HISTORY_FAILURE };
}

/**
 * Load an entity profile's history
 * @param itemProfileId
 * @returns {function(*): Promise<T | never>}
 */
export function loadEntityProfileHistory(itemProfileId) {
    return (dispatch, getState, dependencies) => {
        dispatch(loadEntityProfilesHistoryStart());
        return entityProfilesResource
            .getItemProfileHistory(itemProfileId)
            .then((historyEvents) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [HISTORY_EVENTS_NEXUS_STATE_PROP]: augmentHistoryEvents(
                                historyEvents,
                                itemProfileId
                            ),
                        },
                        loadEntityProfilesHistorySuccess()
                    )
                );
                return historyEvents;
            })
            .catch((err) => {
                dispatch(loadEntityProfilesHistoryFailure(err.message));
                throw err;
            });
    };
}

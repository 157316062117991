import { Table as _Table, TableColumn as Column, TableRow as Row } from 'components-mark43';
import _, { chain, size, flatMap, map, filter, identity } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange, withState, withHandlers, lifecycle } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import _ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import FormattedAttribute from '~/client-common/core/domain/attributes/components/FormattedAttribute';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import PropertyTitle from '~/client-common/core/domain/item-profiles/components/PropertyTitle';
import { deduplicatePropertyStatuses } from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import { isPropertyStatusAllowedOnOffense } from '~/client-common/core/domain/nibrs-offense-codes/utils/nibrsPropertyHelpers';
import ConnectedFormattedPropertyStatusQuantityForItem from '~/client-common/core/domain/property-statuses/components/ConnectedFormattedPropertyStatusQuantityForItem';
import { propertyStatusesByItemProfileIdSelector } from '~/client-common/core/domain/property-statuses/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { offenseViewModelByIdSelector } from '~/client-common/core/domain/offenses/state/ui';
import { federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategorySelector } from '~/client-common/core/domain/attribute-codes/state/ui';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import _SidePanel from '../../../../../legacy-redux/components/core/SidePanel';
import _Checkbox, {
    INDETERMINATE,
    CheckboxBox,
    CheckboxDescription,
    CheckboxLabel,
} from '../../../../core/forms/components/checkboxes/Checkbox';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import {
    closeLinkItemsToOffenseSidePanel,
    submitLinkItemsToOffenseSidePanel,
    linkItemsToOffenseSidePanelIsVehicleSelector,
    linkItemsToOffenseSidePanelOffenseIdSelector,
    linkItemsToOffenseSidePanelOffenseNibrsCodeSelector,
    linkItemsToOffenseSidePanelOwnerIdSelector,
    linkItemsToOffenseSidePanelNibrsAllowedPropertySelector,
} from '../../state/ui';

const SidePanel = styled(_SidePanel)`
    .react-side-panel-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    && .react-side-panel-content-wrapper {
        padding: 0;
    }
`;

const Wrapper = styled(Column)`
    width: 100%;
`;

const Table = styled(_Table)`
    display: block;
    flex: 1 1 auto;
    overflow: auto;
    padding: 16px 0;
    word-break: break-word;
`;

const BodyColumn = styled(Column)`
    border-bottom: 2px solid ${(props) => props.theme.colors.extraLightGrey};
    font-size: var(--arc-fontSizes-sm);
    margin: 0 16px;
    padding: 10px;
`;

const Checkbox = styled(_Checkbox)`
    /* stylelint-disable */
    ${/* sc-selector */ CheckboxBox} {
        flex: 0 0 auto;
    }

    ${/* sc-selector */ CheckboxDescription} {
        flex: 1 1 auto;
    }

    ${/* sc-selector */ CheckboxLabel} {
        display: flex;
        float: none;
    }
    float: none;
    margin-bottom: 0;
    /* stylelint-enable */
`;

const NibrsMessageRow = styled.div`
    margin-top: 5px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-md);
    font-style: italic;
`;

const Header = styled(
    ({
        className,
        propertyStatusIdsSize,
        onToggleAllCheckboxes,
        selectedPropertyStatusIdsSize,
    }) => {
        const strings = componentStrings.reports.core.RecentItemsSidePanel;
        const checkboxesAllSelected =
            selectedPropertyStatusIdsSize > 0 &&
            propertyStatusIdsSize > 0 &&
            selectedPropertyStatusIdsSize === propertyStatusIdsSize;
        const checkboxValue = checkboxesAllSelected
            ? true
            : selectedPropertyStatusIdsSize > 0
            ? INDETERMINATE
            : false;

        return (
            <Row className={className}>
                <Column>
                    <Checkbox
                        label={strings.selectAll}
                        onChange={onToggleAllCheckboxes}
                        value={checkboxValue}
                    />
                </Column>
            </Row>
        );
    }
)`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    box-shadow: 0 3px 3px -3px ${(props) => props.theme.colors.darkGrey};
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.55px;
    padding: 19px 26px;
    text-transform: uppercase;
`;

const PropertyTitleAndQuantity = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const ConnectedFormattedAttribute = styled(_ConnectedFormattedAttribute)`
    display: block;
`;

const PropertyStatusCheckbox = compose(
    withPropsOnChange(['statusAttrId', 'message'], ({ statusAttrId, message }) => {
        const attribute = <ConnectedFormattedAttribute attributeId={statusAttrId} />;
        return {
            label: !message ? (
                attribute
            ) : (
                <div>
                    {attribute}
                    <NibrsMessageRow>{message}</NibrsMessageRow>
                </div>
            ),
        };
    }),
    withPropsOnChange(['selectedStatusIds'], ({ statusId, selectedStatusIds }) => ({
        value: selectedStatusIds.indexOf(statusId) >= 0,
    })),
    withHandlers({
        onChange: ({ onChange, statusId }) => () => onChange(statusId),
    })
)(Checkbox);

const PropertyStatuses = styled(
    ({ className, onToggleCheckbox, propertyStatuses, selectedStatusIds }) =>
        chain(propertyStatuses)
            .map(({ id, propertyStatusAttrId, message, disabled }) => (
                <Row key={id} className={className}>
                    <Column>
                        <PropertyStatusCheckbox
                            onChange={onToggleCheckbox}
                            selectedStatusIds={selectedStatusIds}
                            statusAttrId={propertyStatusAttrId}
                            statusId={id}
                            message={message}
                            disabled={disabled}
                        />
                    </Column>
                </Row>
            ))
            .thru((list) => <div>{list}</div>)
            .value()
)`
    margin-top: 10px;
`;

const LinkItemsToOffenseSidePanel = ({
    closeLinkItemsToOffenseSidePanel,
    isVehicle,
    itemProfiles,
    offenseTitle,
    onToggleAllCheckboxes,
    onToggleCheckbox,
    propertyStatusIdsSize,
    selectedPropertyStatusIds,
    submitLinkItemsToOffenseSidePanel,
}) => {
    const offenseDisplayName = useOffenseFieldName();
    const context = { name: boxEnum.LINK_ITEMS_TO_OFFENSE_SIDE_PANEL };
    const selectedPropertyStatusIdsSize = size(selectedPropertyStatusIds);
    const strings = componentStrings.reports.core.LinkItemsToOffenseSidePanel;
    const saveText = isVehicle
        ? strings.linkVehiclesToOffenseButton(offenseDisplayName)
        : strings.linkPropertyToOffenseButton(offenseDisplayName);

    const title = isVehicle
        ? strings.linkVehiclesToOffenseTitle(offenseDisplayName, offenseTitle)
        : strings.linkPropertyToOffenseTitle(offenseDisplayName, offenseTitle);

    const renderBodyRow = ({ data: itemProfile }) => {
        const { id, itemTypeAttrId } = itemProfile;
        const itemQuantity = !isVehicle && (
            <ConnectedFormattedPropertyStatusQuantityForItem itemProfileId={id}>
                {(renderQuantity) => (
                    <span>
                        {' ('}
                        {renderQuantity}
                        {')'}
                    </span>
                )}
            </ConnectedFormattedPropertyStatusQuantityForItem>
        );

        const itemType = !isVehicle && (
            <ConnectedFormattedAttribute
                attributeId={itemTypeAttrId}
                format={FormattedAttribute.FORMATS.ITEM_TYPE}
            />
        );

        return (
            <BodyColumn key="1">
                <Row>
                    <Column>
                        <PropertyTitleAndQuantity>
                            <PropertyTitle itemProfile={itemProfile} />
                            {itemQuantity}
                        </PropertyTitleAndQuantity>
                        {itemType}
                    </Column>
                </Row>
                <PropertyStatuses
                    propertyStatuses={itemProfile.visiblePropertyStatuses}
                    onToggleCheckbox={onToggleCheckbox}
                    selectedStatusIds={selectedPropertyStatusIds}
                />
            </BodyColumn>
        );
    };

    return (
        <SidePanel
            context={context}
            onSave={submitLinkItemsToOffenseSidePanel}
            onCancel={closeLinkItemsToOffenseSidePanel}
            saveText={saveText}
            title={title}
        >
            <Wrapper>
                <Header
                    onToggleAllCheckboxes={onToggleAllCheckboxes}
                    propertyStatusIdsSize={propertyStatusIdsSize}
                    selectedPropertyStatusIdsSize={selectedPropertyStatusIdsSize}
                />
                <Table data={itemProfiles} renderBodyRow={renderBodyRow} />
            </Wrapper>
        </SidePanel>
    );
};

/**
 * Side panel for linking items to an offense via propertyStatuses.
 */
export default compose(
    connect(
        createStructuredSelector({
            applicationSettings: applicationSettingsSelector,
            isVehicle: linkItemsToOffenseSidePanelIsVehicleSelector,
            itemProfilesInReport: itemProfilesInReportSelector,
            offenseId: linkItemsToOffenseSidePanelOffenseIdSelector,
            offenseTitle: createSelector(
                linkItemsToOffenseSidePanelOffenseIdSelector,
                offenseViewModelByIdSelector,
                (offenseId, offenseViewModelBy) =>
                    chain(offenseId)
                        .thru(offenseViewModelBy)
                        .thru(getViewModelProperties)
                        .get('offenseCode')
                        .value()
            ),
            offenseNibrsCode: linkItemsToOffenseSidePanelOffenseNibrsCodeSelector,
            ownerId: linkItemsToOffenseSidePanelOwnerIdSelector,
            nibrsAllowedProperty: linkItemsToOffenseSidePanelNibrsAllowedPropertySelector,
            federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory: federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategorySelector,
            formatAttributeById: formatAttributeByIdSelector,
            formatFieldByName: formatFieldByNameSelector,
            propertyStatusesByItemProfileId: propertyStatusesByItemProfileIdSelector,
            currentUserDepartmentId: currentUserDepartmentIdSelector,
        }),
        {
            closeLinkItemsToOffenseSidePanel,
            submitLinkItemsToOffenseSidePanel,
        }
    ),
    withPropsOnChange(
        [
            'applicationSettings',
            'isVehicle',
            'itemProfilesInReport',
            'propertyStatusesByItemProfileId',
            'offenseId',
            'ownerId',
            'nibrsAllowedProperty',
            'federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory',
            'currentUserDepartmentId',
            'offenseNibrsCode',
            'formatAttributeById',
        ],
        ({
            applicationSettings,
            isVehicle,
            itemProfilesInReport,
            propertyStatusesByItemProfileId,
            offenseId,
            ownerId,
            nibrsAllowedProperty,
            federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory,
            currentUserDepartmentId,
            offenseNibrsCode,
            formatAttributeById,
            formatFieldByName,
        }) => {
            const itemProfiles = chain(ownerId)
                .thru(itemProfilesInReport)
                .filter(({ itemTypeAttrId }) =>
                    isVehicle
                        ? itemTypeAttrId === globalAttributes.itemType.vehicle
                        : itemTypeAttrId !== globalAttributes.itemType.vehicle
                )
                .map((itemProfile) => ({
                    // ad hoc view model shape just for this component
                    ...itemProfile,
                    ...chain(itemProfile.id)
                        .thru(propertyStatusesByItemProfileId)
                        .thru((propertyStatuses) => ({
                            // for passing into the form submit handler, since it needs to know
                            // about all propertyStatus to know what to create/update/delete
                            allPropertyStatuses: propertyStatuses,
                            // for passing into <Table> to be displayed, since we can't show 2
                            // Burned statuses for example
                            visiblePropertyStatuses: map(
                                deduplicatePropertyStatuses(propertyStatuses, offenseId),
                                !applicationSettings.RMS_ITEM_ENTRY_V2_NIBRS_VALIDATION
                                    ? identity
                                    : (propertyStatus) => {
                                          const {
                                              allowed,
                                              message,
                                          } = isPropertyStatusAllowedOnOffense({
                                              allPropertyStatuses: propertyStatuses,
                                              propertyStatus,
                                              itemProfile,
                                              nibrsAllowedProperty,
                                              federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory,
                                              currentUserDepartmentId,
                                              offenseNibrsCode,
                                              formatAttributeById,
                                              formatFieldByName,
                                          });
                                          return {
                                              ...propertyStatus,
                                              // if a disallowed status is already linked to this
                                              // offense, it must be enabled for the user to be able
                                              // to unlink it
                                              disabled:
                                                  !allowed &&
                                                  propertyStatus.offenseId !== offenseId,
                                              message,
                                          };
                                      }
                            ),
                        }))
                        .value(),
                }))
                .value();
            const allPropertyStatuses = flatMap(itemProfiles, 'allPropertyStatuses');
            const visiblePropertyStatuses = flatMap(itemProfiles, 'visiblePropertyStatuses');

            return {
                itemProfiles,
                allPropertyStatuses,
                visiblePropertyStatuses,
                propertyStatusIdsSize: size(visiblePropertyStatuses),
                initialSelectedPropertyStatusIds: map(
                    filter(visiblePropertyStatuses, { offenseId }),
                    'id'
                ),
            };
        }
    ),
    withState('selectedPropertyStatusIds', 'setSelectedPropertyStatusIds', []),
    lifecycle({
        // hack to set initial selectedPropertyStatusIds. side panels mount on app load and
        // withState initial function with props will not re-run on prop changes.
        UNSAFE_componentWillReceiveProps(nextProps) {
            if (
                this.props.initialSelectedPropertyStatusIds !==
                nextProps.initialSelectedPropertyStatusIds
            ) {
                nextProps.setSelectedPropertyStatusIds(nextProps.initialSelectedPropertyStatusIds);
            }
        },
    }),
    withHandlers({
        onToggleAllCheckboxes: ({
            visiblePropertyStatuses,
            selectedPropertyStatusIds,
            setSelectedPropertyStatusIds,
        }) => {
            const enabledPropertyStatusIds = _(visiblePropertyStatuses)
                .reject({ disabled: true })
                .map('id')
                .value();
            return () =>
                size(enabledPropertyStatusIds) !== size(selectedPropertyStatusIds)
                    ? setSelectedPropertyStatusIds(enabledPropertyStatusIds)
                    : setSelectedPropertyStatusIds([]);
        },
        onToggleCheckbox: ({ selectedPropertyStatusIds, setSelectedPropertyStatusIds }) => (
            propertyStatusId
        ) => {
            if (selectedPropertyStatusIds.indexOf(propertyStatusId) >= 0) {
                return chain(selectedPropertyStatusIds)
                    .without(propertyStatusId)
                    .thru(setSelectedPropertyStatusIds)
                    .value();
            }

            return chain(selectedPropertyStatusIds)
                .concat(propertyStatusId)
                .thru(setSelectedPropertyStatusIds)
                .value();
        },
        closeLinkItemsToOffenseSidePanel: ({
            closeLinkItemsToOffenseSidePanel,
            setSelectedPropertyStatusIds,
        }) => () => {
            setSelectedPropertyStatusIds([]);
            closeLinkItemsToOffenseSidePanel();
        },
        submitLinkItemsToOffenseSidePanel: ({
            selectedPropertyStatusIds,
            setSelectedPropertyStatusIds,
            submitLinkItemsToOffenseSidePanel,
            allPropertyStatuses,
        }) => () => {
            submitLinkItemsToOffenseSidePanel(
                selectedPropertyStatusIds,
                allPropertyStatuses
            ).then(() => setSelectedPropertyStatusIds([]));
        },
    })
)(LinkItemsToOffenseSidePanel);

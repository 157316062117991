import { createSelector } from 'reselect';
import {
    compact,
    filter,
    find,
    first,
    get,
    groupBy,
    includes,
    isEmpty,
    map,
    mapValues,
    omit,
    orderBy,
    sortBy,
    uniq,
    flatten,
    partition,
    values,
    some,
    chain,
} from 'lodash';
import {
    ExportPresetView,
    ExportRelease,
    ClientApprovalStatusEnum,
    Printable,
    ExportOptionTypeEnum,
} from '@mark43/rms-api';
import { createExportReleases } from '~/client-common/core/domain/export-releases/state/data';
import { isCoverSheet, mapGivenPacketsToPrintables } from '~/client-common/helpers/exportHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { exportPresetsSelector } from '~/client-common/core/domain/export-presets/state/data';
import { reportIdsForRenSelector } from '~/client-common/core/domain/case-links/state/data';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { reportDefinitionsForRenSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { orderedReportStatusHistoriesByReportIdSelector } from '~/client-common/core/domain/report-status-histories/state/data';
import {
    createExportReleasesFromPackets,
    getPacketsWithReleases,
} from '../../../../core/pdf-exports/release-tracking/state/ui';
import { exportPDF } from '../../../../../legacy-redux/actions/exportsActions';
import {
    exportDefaultGroupTitleSelector,
    rawPacketOptionsSelector,
} from '../../../../../legacy-redux/selectors/exportsSelectors';
import {
    currentReportIdSelector,
    currentReportSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { RmsAction } from '../../../../../core/typings/redux';

const reportExportsStrings = componentStrings.reports.core.ReportExports;

const exportPresetsForCurrentRenSelector = createSelector(
    exportPresetsSelector,
    currentReportSelector,
    reportDefinitionsForRenSelector,
    (exportPresets, currentReport, reportDefinitionsForRen) => {
        if (!currentReport) {
            return [];
        }
        const reportDefinitionIds = chain([currentReport.reportDefinitionId])
            .concat(
                reportDefinitionsForRen(
                    currentReport?.reportingEventNumber || '',
                    currentReport.departmentId
                )
            )
            .compact()
            .uniq()
            .value();
        return filter(exportPresets, (exportPreset) => {
            const exportPresetReportTypes = exportPreset.exportPresetReportTypes;
            const reportTypeReportDefIds = compact(
                uniq(map(exportPresetReportTypes, 'reportDefinitionId'))
            );
            return (
                exportPreset.isDefaultExportPreset ||
                !isEmpty(
                    filter(reportTypeReportDefIds, (reportTypeReportDefId) =>
                        includes(reportDefinitionIds, reportTypeReportDefId)
                    )
                )
            );
        });
    }
);

export const enabledExportPresetsForDropdownSelector = createSelector(
    applicationSettingsSelector,
    exportPresetsForCurrentRenSelector,
    (applicationSettings, exportPresetsForCurrentRen) => {
        let enabledExports = filter(exportPresetsForCurrentRen, {
            isDisplayedInExportDropdown: true,
        });
        // if an export preset was saved before this flag was enabled
        // it could possibly be in an invalid state
        if (applicationSettings.RELEASE_TRACKING_ENABLED) {
            enabledExports = filter(
                enabledExports,
                (exportPreset) => exportPreset.releasedToAttrId !== null
            );
        }
        return enabledExports;
    }
);

const enabledExportPresetsForExportPageSelector = createSelector(
    exportPresetsForCurrentRenSelector,
    (exportPresetsForCurrentRen) =>
        filter(exportPresetsForCurrentRen, { isDisplayedOnExportPage: true })
);

function mapExportPresetToOption({ id, title }: ExportPresetView) {
    return {
        value: id,
        display: title,
    };
}

function mapExportPresetsToOptions(exportPresets: ExportPresetView[]) {
    return sortBy(map(exportPresets, mapExportPresetToOption), 'display');
}

export const exportPresetsForExportPageOptionsSelector = createSelector(
    enabledExportPresetsForExportPageSelector,
    (enabledExportPresetsForExportPage) =>
        mapExportPresetsToOptions(enabledExportPresetsForExportPage)
);

export const defaultExportPresetSelector = createSelector(
    enabledExportPresetsForDropdownSelector,
    (enabledExportPresets) => {
        return first(
            filter(enabledExportPresets, (exportPreset) => exportPreset.isDefaultExportPreset)
        );
    }
);

const defaultPrintingTemplateIdSelector = createSelector(
    defaultExportPresetSelector,
    (defaultExportPreset) => {
        const defaultReportPrintingTemplate = !!defaultExportPreset
            ? first(defaultExportPreset.reportPrintingTemplates)
            : undefined;
        return defaultReportPrintingTemplate?.printingTemplateId;
    }
);

type FormData = {
    packets: {
        value: string[];
    };
    onlyIncludeFieldsWithData: boolean;
    includeNameAddendums: boolean;
    includeHistoryEvents: boolean;
    includeConfidentialInformation: boolean;
    includeFiles: boolean;
    mergeAttachments: boolean;
};

export function saveExportRelease(
    exportPreset: ExportPresetView,
    packets: Printable[],
    formData: FormData
): RmsAction<Promise<void>> {
    return (dispatch, getState) => {
        const releaseFormModel = {
            additionalInformation: exportPreset.remarks,
            releasedToAttrId: exportPreset.releasedToAttrId,
        };
        const exportReleases = createExportReleasesFromPackets({
            selectedPackets: packets,
            formModel: releaseFormModel,
            state: getState(),
        });
        return dispatch(createExportReleases(exportReleases)).then(
            (createdExportReleases: ExportRelease[]) => {
                const packetsWithReleases = getPacketsWithReleases({
                    selectedPackets: packets,
                    createdExportReleases,
                });
                // kick off the actual export
                dispatch(exportPDF(formData, packetsWithReleases));
            }
        );
    };
}

export const sortExportPresets = (exportPresets: ExportPresetView[]) => {
    return orderBy(exportPresets, ['isDefaultExportPreset', 'title'], ['desc', 'asc']);
};

const rawPacketsForCurrentReportSelector = createSelector(
    [currentReportIdSelector, defaultPrintingTemplateIdSelector, rawPacketOptionsSelector],
    (currentReportId, defaultPrintingTemplateId, rawPacketOptions) => {
        return filter(
            rawPacketOptions,
            (rawPacket) =>
                rawPacket.entityId === currentReportId &&
                rawPacket.printingTemplateId === defaultPrintingTemplateId
        );
    }
);

const rawPacketsForCurrentRenSelector = createSelector(
    [currentReportSelector, reportIdsForRenSelector, rawPacketOptionsSelector],
    (currentReport, reportIdsForRen, rawPacketOptions) => (exportPreset: ExportPresetView) => {
        if (!currentReport) {
            return [];
        }
        const exportPresetReportTypes = exportPreset.exportPresetReportTypes;
        const reportIdsForCurrentRen = reportIdsForRen(
            currentReport?.reportingEventNumber || '',
            currentReport.departmentId
        );
        // TODO: should prefer mapping over exportPreset.exportPresetReportTypes
        // to preserve export position ordering.
        const filteredRawPacketOptions = filter(rawPacketOptions, (rawPacket) => {
            const exportPresetReportType = find(
                exportPresetReportTypes,
                (eprt) =>
                    eprt.reportPrintingTemplateId === rawPacket.reportPrintingTemplateId &&
                    (eprt.reportDefinitionId === rawPacket.reportDefinitionId ||
                        isUndefinedOrNull(rawPacket.reportDefinitionId))
            );
            if (!!exportPresetReportType) {
                rawPacket.exportOptions = compact(
                    map(get(rawPacket, 'exportOptions', []), (exportOption) => {
                        const reportTypeExportConfigs =
                            exportPresetReportType.reportTypeExportConfigs;
                        const existingConfig = find(
                            reportTypeExportConfigs,
                            (rtec) => rtec.exportOptionName === exportOption.exportOptionName
                        );
                        if (!!existingConfig) {
                            return {
                                ...existingConfig,
                                isEnabled: true,
                            };
                        }
                        return undefined;
                    })
                );

                // temporarily add exportPosition to facilitate sorting
                rawPacket.exportPosition = exportPresetReportType.exportPosition;
            }
            return !!exportPresetReportType && includes(reportIdsForCurrentRen, rawPacket.entityId);
        });

        const sortedRawPacketOptions = sortBy(filteredRawPacketOptions, 'exportPosition');
        return map(sortedRawPacketOptions, (packetOption) => omit(packetOption, 'exportPosition'));
    }
);

export const packetsForExportPresetSelector = createSelector(
    [
        rawPacketsForCurrentReportSelector,
        rawPacketsForCurrentRenSelector,
        exportDefaultGroupTitleSelector,
    ],
    (rawPacketsForCurrentReport, rawPacketsForCurrentRen, exportDefaultGroupTitle) => (
        exportPreset: ExportPresetView
    ) => {
        const rawPackets = !!exportPreset.isDefaultExportPreset
            ? rawPacketsForCurrentReport
            : rawPacketsForCurrentRen(exportPreset);

        const packets = map(rawPackets, (packet) => {
            return { ...packet, exportReportVersion: exportPreset.exportReportVersion };
        });

        // Ensure that the packets are
        // grouped by report definition,
        // and that the cover sheet is printed first
        // within each group
        const groupedAndSortedRawPackets = mapValues(
            groupBy(packets, 'reportDefinitionId'),
            (packetsForReportDefinition) => {
                return flatten(
                    partition(packetsForReportDefinition, (packet) => {
                        return isCoverSheet(packet.groupTitle);
                    })
                );
            }
        );

        return mapGivenPacketsToPrintables(
            flatten(values(groupedAndSortedRawPackets)),
            exportDefaultGroupTitle,
            reportExportsStrings.defaultTitle
        );
    }
);

const fieldNamesToExportOptionTypes = {
    onlyIncludeFieldsWithData: ExportOptionTypeEnum.ONLY_INCLUDE_FIELDS_WITH_DATA.name,
    includeNameAddendums: ExportOptionTypeEnum.INCLUDE_NAME_ADDENDUM.name,
    includeHistoryEvents: ExportOptionTypeEnum.INCLUDE_HISTORY_EVENTS.name,
    includeConfidentialInformation: ExportOptionTypeEnum.INCLUDE_CONFIDENTIAL_INFORMATION.name,
    includeFiles: ExportOptionTypeEnum.INCLUDE_ATTACHMENT_FILES.name,
    mergeAttachments: ExportOptionTypeEnum.MERGE_ATTACHMENTS.name,
};

export const convertExportOptionsToFormData = (exportPreset: ExportPresetView) => {
    const exportPresetOptions = exportPreset.exportPresetOptions;
    const exportPresetOptionTypes = map(exportPresetOptions, 'exportOptionType');
    return mapValues(fieldNamesToExportOptionTypes, (fieldName) => {
        return includes(exportPresetOptionTypes, fieldName);
    });
};

export const convertExportPresetToFormData = (
    exportPreset: ExportPresetView,
    packets: Printable[]
): FormData => {
    const exportOptions = convertExportOptionsToFormData(exportPreset);
    const packetTitles = map(packets, 'title');
    return {
        ...exportOptions,
        packets: {
            value: packetTitles,
        },
    };
};

export const displayPrintOfficerSnapshotExportPresetInExportDropdownSelector = createSelector(
    applicationSettingsSelector,
    orderedReportStatusHistoriesByReportIdSelector,
    currentReportIdSelector,
    (applicationSettings, orderedReportStatusHistoriesByReportId, currentReportId) => {
        if (!applicationSettings.RMS_OFFICER_REPORT_SNAPSHOT_ENABLED) {
            return false;
        }

        if (!currentReportId) {
            return false;
        }

        const orderedReportStatusHistories = orderedReportStatusHistoriesByReportId(
            currentReportId
        );

        const hasBeenSupervisorApprovedAtLeastOnce = some(orderedReportStatusHistories, {
            clientApprovalStatus: ClientApprovalStatusEnum.PENDING_SECONDARY_REVIEW.name,
        });

        return hasBeenSupervisorApprovedAtLeastOnce;
    }
);

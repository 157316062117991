import { parseInt, map, filter } from 'lodash';
import { withPropsOnChange } from 'recompose';

import * as fields from '~/client-common/core/enums/universal/fields';

export default withPropsOnChange(
    ['courtOrdersByReportId', 'routeParams', 'formatFieldByName'],
    ({ courtOrdersByReportId, routeParams, formatFieldByName, formatAttributeById }) => {
        const courtOrdersForReport = filter(
            courtOrdersByReportId(parseInt(routeParams.reportId)),
            // vacting court orders cannot be edited or unsealed,
            // so we must filter them out
            ({ isVacate }) => !isVacate
        );
        const courtCaseLabel = formatFieldByName(fields.COURT_ORDER_COURT_CASE_NUMBER);

        return {
            courtOrderOptions: map(
                courtOrdersForReport,
                ({ courtCaseNumber, id, involvedPersonFullName, statuteCodeAttrId }) => {
                    const statuteOption = statuteCodeAttrId
                        ? `${formatAttributeById(statuteCodeAttrId)} - ${involvedPersonFullName}`
                        : null;
                    return {
                        display: statuteOption ?? `${courtCaseLabel}: ${courtCaseNumber}`,
                        value: id,
                    };
                }
            ),
            courtOrdersForReport,
        };
    }
);

import { NotificationCategoryEnum, NotificationPriorityEnum } from '@mark43/rms-api';
import * as fields from './fields';

/**
 * Must match server enum {@link
 *   notifications-client/src/main/java/com/mark43/notifications/client/NotificationType.java}
 * @type {Object}
 */
export default {
    TEST: {
        // deprecated
        name: 'TEST',
        value: 1,
        category: NotificationCategoryEnum.EVIDENCE.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Test Notification',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    EVIDENCE_ITEM_OVERDUE_USER: {
        name: 'EVIDENCE_ITEM_OVERDUE_USER',
        value: 3,
        category: NotificationCategoryEnum.EVIDENCE.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Item(s) Overdue',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    EVIDENCE_ITEM_OVERDUE_CLERK: {
        name: 'EVIDENCE_ITEM_OVERDUE_CLERK',
        value: 4,
        category: NotificationCategoryEnum.EVIDENCE.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Item(s) Overdue - Personnel Notified',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    EVIDENCE_REVIEW_ITEM_FOR_DISPOSITION: {
        name: 'EVIDENCE_REVIEW_ITEM_FOR_DISPOSITION',
        value: 5,
        category: NotificationCategoryEnum.EVIDENCE.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Review Item(s) for Disposition',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    EVIDENCE_ITEM_APPROVED_FOR_DISPOSITION: {
        name: 'EVIDENCE_ITEM_APPROVED_FOR_DISPOSITION',
        value: 6,
        category: NotificationCategoryEnum.EVIDENCE.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Item(s) Approved for Disposition',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    EVIDENCE_ITEM_APPROVED_FOR_RELEASE: {
        name: 'EVIDENCE_ITEM_APPROVED_FOR_RELEASE',
        value: 28,
        category: NotificationCategoryEnum.EVIDENCE.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Item(s) Approved for Release',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_REN_UPDATED: {
        name: 'REPORT_REN_UPDATED',
        value: 7,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: (displayValue: string) => `${displayValue} Updated`,
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
        fieldName: fields.REPORT_REPORTING_EVENT_NUMBER,
    },
    REPORT_RETURNED_TO_DRAFT_AFTER_SECONDARY_APPROVAL: {
        name: 'REPORT_RETURNED_TO_DRAFT_AFTER_SECONDARY_APPROVAL',
        value: 8,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Returned to Draft',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_APPROVED: {
        name: 'REPORT_APPROVED',
        value: 9,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Approved',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_REJECTED: {
        name: 'REPORT_REJECTED',
        value: 10,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Rejected - Supervisor',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_REJECTED_BY_SECONDARY: {
        name: 'REPORT_REJECTED_BY_SECONDARY',
        value: 11,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Rejected - Staff Review',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_APPROVAL_REQUESTED: {
        name: 'REPORT_APPROVAL_REQUESTED',
        value: 12,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Approval Requested',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    ASSOCIATED_RECORD_ADDED_TO_REPORT: {
        name: 'ASSOCIATED_RECORD_ADDED_TO_REPORT',
        value: 34,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Associated Record Added to Your Report',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    SUGGESTED_ASSOCIATED_RECORD_ADDED_TO_REPORT: {
        name: 'SUGGESTED_ASSOCIATED_RECORD_ADDED_TO_REPORT',
        value: 35,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Suggested Associated Record Added to Your Report',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    DUPLICATE_PROFILES_IDENTIFIED: {
        name: 'DUPLICATE_PROFILES_IDENTIFIED',
        value: 31,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Duplicate Profiles Identified',
        webDelivery: false,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_COMMENT_CREATED: {
        name: 'REPORT_COMMENT_CREATED',
        value: 43,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Comment Created',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_DELETED: {
        name: 'REPORT_DELETED',
        value: 59,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Deleted',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_PERMISSION_MODIFIED: {
        name: 'REPORT_PERMISSION_MODIFIED',
        value: 60,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Permission Modified',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_AUTHOR_ADDED: {
        name: 'REPORT_AUTHOR_ADDED',
        value: 61,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Author Added',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_OWNER_MODIFIED: {
        name: 'REPORT_OWNER_MODIFIED',
        value: 62,
        category: NotificationCategoryEnum.REPORTS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Owner Modified',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_IN_CASE_RETURNED_TO_DRAFT: {
        name: 'REPORT_IN_CASE_RETURNED_TO_DRAFT',
        value: 13,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report In Your Case Returned to Draft',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_IN_CASE_APPROVED: {
        name: 'REPORT_IN_CASE_APPROVED',
        value: 14,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report In Your Case Approved by Supervisor',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_IN_CASE_REJECTED: {
        name: 'REPORT_IN_CASE_REJECTED',
        value: 15,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report In Your Case Rejected by Supervisor',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_ADDED_TO_CASE: {
        name: 'REPORT_ADDED_TO_CASE',
        value: 16,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Added to Your Case',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_REMOVED_FROM_CASE: {
        name: 'REPORT_REMOVED_FROM_CASE',
        value: 17,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Report Removed from Your Case',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    USER_CASE_ASSIGNMENT: {
        name: 'USER_CASE_ASSIGNMENT',
        value: 18,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'New Case Assignment - Assignee',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    USER_CASE_UNASSIGNMENT: {
        name: 'USER_CASE_UNASSIGNMENT',
        value: 33,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case Assignee Removed - Assignee',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    USER_CASE_ASSIGNMENT_UPDATED_SUPERVISOR: {
        name: 'USER_CASE_ASSIGNMENT_UPDATED_SUPERVISOR',
        value: 36,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case Assignee Updated - Supervisor',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    UNIT_CASE_ASSIGNMENT: {
        name: 'UNIT_CASE_ASSIGNMENT',
        value: 19,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'New Case Assignment - Unit',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    CASE_NOTE_ADDED: {
        name: 'CASE_NOTE_ADDED',
        value: 20,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case Note Added to Your Case',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    CASE_NOTE_DELETED: {
        name: 'CASE_NOTE_DELETED',
        value: 21,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case Note Removed from Your Case',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    CASE_STATUS_CHANGED: {
        name: 'CASE_STATUS_CHANGED',
        value: 22,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case or Report Status Changed',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    CASE_REVIEW_REQUESTED: {
        name: 'CASE_REVIEW_REQUESTED',
        value: 23,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case Review Requested',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    CASE_REVIEWED: {
        name: 'CASE_REVIEWED',
        value: 24,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case Reviewed',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    CASE_APPROVED: {
        name: 'CASE_APPROVED',
        value: 25,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case Approved by Supervisor',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    CASE_REJECTED: {
        name: 'CASE_REJECTED',
        value: 26,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case Rejected by Supervisor',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    REPORT_CASE_STATUS_CHANGED: {
        name: 'REPORT_CASE_STATUS_CHANGED',
        value: 27,
        category: NotificationCategoryEnum.CASES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Case Report Status Changed',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    USER_TASK_ASSIGNMENT: {
        name: 'USER_TASK_ASSIGNMENT',
        value: 39,
        category: NotificationCategoryEnum.TASKS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Task Assignment - Assignee',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    TASK_STATUS_CHANGED: {
        name: 'TASK_STATUS_CHANGED',
        value: 40,
        category: NotificationCategoryEnum.TASKS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Task Status Changed - Creator',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    SUBSCRIPTION_HIT: {
        name: 'SUBSCRIPTION_HIT',
        value: 41,
        category: NotificationCategoryEnum.SUBSCRIPTIONS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'New Results in Subscribed Saved Search',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    TASK_DUE_TODAY: {
        name: 'TASK_DUE_TODAY',
        value: 42,
        category: NotificationCategoryEnum.TASKS.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Task Due Today',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    SHARED_SAVED_SEARCH: {
        name: 'SHARED_SAVED_SEARCH',
        value: 44,
        category: NotificationCategoryEnum.SAVED_SEARCHES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'New Saved Search Shared With You',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    TEST_CAD_HIGH: {
        name: 'TEST_CAD_HIGH',
        value: 45,
        category: NotificationCategoryEnum.CAD.name,
        priority: NotificationPriorityEnum.HIGH.name,
        display: 'Test CAD High Priority Notification',
        webDelivery: true,
        emailDelivery: false,
        pushDelivery: false,
    },
    TEST_CAD_MEDIUM: {
        name: 'TEST_CAD_MEDIUM',
        value: 46,
        category: NotificationCategoryEnum.CAD.name,
        priority: NotificationPriorityEnum.MEDIUM.name,
        display: 'Test CAD Medium Priority Notification',
        webDelivery: true,
        emailDelivery: false,
        pushDelivery: false,
    },
    SHARED_SAVED_SEARCH_UPDATED: {
        name: 'SHARED_SAVED_SEARCH_UPDATED',
        value: 47,
        category: NotificationCategoryEnum.SAVED_SEARCHES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Saved Search Shared With You - Updated',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    SHARED_SAVED_SEARCH_DELETED: {
        name: 'SHARED_SAVED_SEARCH_DELETED',
        value: 48,
        category: NotificationCategoryEnum.SAVED_SEARCHES.name,
        priority: NotificationPriorityEnum.LOW.name,
        display: 'Saved Search Shared With You - Deleted',
        webDelivery: true,
        emailDelivery: true,
        pushDelivery: false,
    },
    BOLO: {
        name: 'BOLO',
        value: 49,
        category: NotificationCategoryEnum.CAD.name,
        priority: NotificationPriorityEnum.MEDIUM.name,
        display: 'BOLO',
        webDelivery: true,
        emailDelivery: false,
        pushDelivery: false,
    },
    OFFICER_EMERGENCY: {
        name: 'OFFICER_EMERGENCY',
        value: 50,
        category: NotificationCategoryEnum.CAD.name,
        priority: NotificationPriorityEnum.HIGH.name,
        display: 'Officer Emergency',
        webDelivery: true,
        emailDelivery: false,
        pushDelivery: false,
    },
    INCOMING_ASSIGNMENT: {
        name: 'INCOMING_ASSIGNMENT',
        value: 51,
        category: NotificationCategoryEnum.CAD.name,
        priority: NotificationPriorityEnum.HIGH.name,
        display: 'New Assignment',
        webDelivery: false,
        emailDelivery: false,
        pushDelivery: true,
    },
    HIGH_PRIORITY_NARRATIVE: {
        name: 'HIGH_PRIORITY_NARRATIVE',
        value: 52,
        category: NotificationCategoryEnum.CAD.name,
        priority: NotificationPriorityEnum.HIGH.name,
        display: 'New high priority narrative',
        webDelivery: false,
        emailDelivery: false,
        pushDelivery: true,
    },
    RFA_URGENT_MESSAGE: {
        name: 'RFA_URGENT_MESSAGE',
        value: 55,
        category: NotificationCategoryEnum.CAD.name,
        priority: NotificationPriorityEnum.MEDIUM.name,
        display: 'RFA Urgent Message',
        webDelivery: true,
        emailDelivery: false,
        pushDelivery: false,
    },
    RFA_TIMED_OUT: {
        name: 'RFA_TIMED_OUT',
        value: 56,
        category: NotificationCategoryEnum.CAD.name,
        priority: NotificationPriorityEnum.MEDIUM.name,
        display: 'RFA Timed Out',
        webDelivery: true,
        emailDelivery: false,
        pushDelivery: false,
    },
    RFA_FAILURE: {
        name: 'RFA_FAILURE',
        value: 57,
        category: NotificationCategoryEnum.CAD.name,
        priority: NotificationPriorityEnum.MEDIUM.name,
        display: 'RFA Failure',
        webDelivery: true,
        emailDelivery: false,
        pushDelivery: false,
    },
} as const;

import { currentCaseSelector, saveOrphanedCaseContents } from '../ui';
import folderContentResource from '../../resources/folderContentsResource';
import { RmsAction } from '../../../../../core/typings/redux';

export function updateOrphanContents(): RmsAction<Promise<void>> {
    return (dispatch, getState) => {
        const currentCase = currentCaseSelector(getState());

        if (!currentCase?.id) {
            return Promise.resolve();
        }

        return folderContentResource
            .getOrphanContents(currentCase.id)
            .then(({ attachments = [], caseNotes = [] }) => {
                dispatch(
                    saveOrphanedCaseContents({
                        attachments,
                        caseNotes,
                    })
                );
            });
    };
}

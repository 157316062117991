import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { noop } from 'lodash';
import { Counter } from 'arc';
import testIds from '../../../../../core/testIds';
import Link from '../../../../core/components/links/Link';
import Icon from '../../../../core/components/Icon';

const Title = styled.div`
    margin-top: 2px;
    font-size: 9px;
`;

const IconWrapper = styled.div`
    display: inline-block;
    position: relative;
`;

const CounterBadge = styled(Counter)`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -40%);
`;

function NavigationLink({
    to,
    text,
    iconType,
    selected = false,
    onClick = noop,
    count = null,
    className,
    testId,
}) {
    const navLinkContent = (
        <div className={classNames('nav-link', { 'nav-selected': selected })}>
            <IconWrapper>
                <Icon size={20} type={iconType} />
                {count > 0 && (
                    <CounterBadge
                        data-test-id={testIds['arc-nav-item-counter']}
                        variant="important"
                        children={count}
                    />
                )}
            </IconWrapper>
            {text && <Title>{text}</Title>}
        </div>
    );
    const navLinkClassName = classNames('nav-box', className);
    if (!to) {
        return (
            <div className={navLinkClassName} onClick={onClick} data-test-id={testId}>
                {navLinkContent}
            </div>
        );
    }

    return (
        <Link className={navLinkClassName} to={to} testId={testId}>
            {navLinkContent}
        </Link>
    );
}

/**
 * Component to display a single Navigation Link on the main Navigation
 * menu bar.
 * @param {string|object} to  passed to React Router Link prop
 * @param {string} text       label text
 * @param {string} iconType     icon name
 * @param {boolean} selected  used to display active-state
 * @param {function} onClick  optional param, instead of using Link
 */
export default styled(NavigationLink)`
    text-transform: uppercase;
`;

import { FieldTypeEnum } from '@mark43/rms-api';
import _, { get, isEmpty, isFunction, size } from 'lodash';

import moment from 'moment';
import { fieldConfigurationByFieldNameSelector } from '~/client-common/core/domain/field-configurations/state/data';

import { fieldDetailsByFieldNameSelector } from '~/client-common/core/domain/field-details/state/data';
import validationStrings from '~/client-common/core/strings/validationStrings';
import {
    getDynamicRulesForContext,
    getSystemRulesForContext,
    getDynamicRulesForContexts,
    getSystemRulesForContexts,
} from '../../../legacy-redux/selectors/ruleSelectors';
import { currentReportSelector } from '../../../legacy-redux/selectors/reportSelectors';
import errorMessagesByRule from '../../../legacy-redux/configs/validationErrorMessagesByRule';
import { logError } from '../../../core/logging';

const numberRegex = /^[+-]?(\d*\.)?\d+$/;
const integerRegex = /^\d+$/;

/**
 * Validate a field in RRF
 * TODO support attributeTypes
 * @param  {Object} context   context to match rules
 * @param  {Object} formData  Form data
 * @param  {String} fieldName FIELD_NAME
 * @param  {String} modelPath Path to value in form data
 * @return {Object}           Return an error message object { field, panel }
 */
export function validateFieldRRF(context, formData, fieldName, modelPath, state) {
    const rulesForContext = getRules(context, state);
    const filterForField = (rules) =>
        _(rules)
            .filter((rule) => (fieldName ? rule.fieldName === fieldName : true))
            .value();

    const rulesForField = {
        systemRules: filterForField(rulesForContext.systemRules),
        dynamicRules: filterForField(rulesForContext.dynamicRules),
    };

    return runFieldValidationsRRF(formData, rulesForField, fieldName, modelPath, state);
}

function runFieldValidationsRRF(formData, rulesForField, fieldName, modelPath, state) {
    const fieldDetailsByFieldName = fieldDetailsByFieldNameSelector(state);
    const fieldConfigurationByFieldName = fieldConfigurationByFieldNameSelector(state);
    const { systemRules, dynamicRules } = rulesForField;
    const value = get(formData, modelPath);

    // run rules
    // systemRuleErrorMessages is an array of objects
    // dynamicRuleErrorMessage and fieldConfigurationErrorMessage are strings
    const systemRuleErrorMessages = validateFieldSystemRulesRRF(
        systemRules,
        formData,
        value,
        state
    );
    const dynamicRuleFieldErrorMessage = validateFieldDynamicRulesRRF(dynamicRules, value);
    const fieldConfigurationErrorMessage = validateFieldConfigurationRulesRRF(
        fieldDetailsByFieldName,
        fieldConfigurationByFieldName,
        fieldName,
        value
    );

    if (size(dynamicRuleFieldErrorMessage) > 0) {
        // Create custom "FIELD_NAME is required" panel-level error message.
        const fieldDisplayName = get(fieldConfigurationByFieldName, `${fieldName}.displayName`);
        // `displayName` is nullable; for safety, fall back here.
        const dynamicRulePanelErrorMessage = fieldDisplayName
            ? validationStrings.panel.fieldRequired(fieldDisplayName)
            : validationStrings.panel.generic;
        return {
            field: dynamicRuleFieldErrorMessage,
            panel: dynamicRulePanelErrorMessage,
        };
    }

    const fieldErrorMessage =
        fieldConfigurationErrorMessage || _(systemRuleErrorMessages).map('field').head();
    const panelErrorMessage = _(systemRuleErrorMessages).map('panel').compact().head();

    // If we don't have a panel error message yet, but we have a field level error message, then
    // fall back to our generic panel error message.
    if (size(panelErrorMessage) === 0 && size(fieldErrorMessage) > 0) {
        return {
            field: fieldErrorMessage,
            panel: validationStrings.panel.generic,
        };
    }
    return {
        field: fieldErrorMessage,
        panel: panelErrorMessage,
    };
}

/**
 * Validate system rules without associated fields in RRF
 * @param  {Object[]} systemRules list of system rules
 * @param  {Object}   formData    Form data
 * @param  {Object}   state       Redux State
 * @return {Object[]}             List of error message objects (containing
 *   only panel errors)
 */
export function validatePanelSystemRulesRRF(systemRules, formData, state) {
    return _(systemRules)
        .map(({ systemRule, validate }) => {
            if (!validate) {
                logError(`System rule ${systemRule} has no implementation on the client.`);
                return null;
            }
            const isValid = validate(formData, undefined, state);
            if (errorMessagesByRule[systemRule] && !isValid) {
                return {
                    panel: isFunction(errorMessagesByRule[systemRule].panel)
                        ? errorMessagesByRule[systemRule].panel(formData)
                        : errorMessagesByRule[systemRule].panel,
                };
            } else {
                return null;
            }
        })
        .compact()
        .value();
}

/**
 * Validate system rules for a field in RRF
 * @param  {Object[]} systemRules list of system rules
 * @param  {Object} formData    Form data
 * @return {Object[]}             list of error messages { field, panel }
 */
function validateFieldSystemRulesRRF(systemRules, formData, value, state) {
    return _(systemRules)
        .map(({ systemRule, validate }) => {
            if (!validate) {
                logError(`System rule ${systemRule} has no implementation on the client.`);
                return null;
            }
            const isValid = validate(formData, value, state);
            if (!!errorMessagesByRule[systemRule] && !isValid) {
                // The errors can be either a static string or a function for generating dynamic messages
                return {
                    panel: isFunction(errorMessagesByRule[systemRule].panel)
                        ? errorMessagesByRule[systemRule].panel(formData)
                        : errorMessagesByRule[systemRule].panel,
                    field: isFunction(errorMessagesByRule[systemRule].field)
                        ? errorMessagesByRule[systemRule].field(formData)
                        : errorMessagesByRule[systemRule].field,
                };
            } else {
                return null;
            }
        })
        .compact()
        .value();
}

/**
 * Validate dynamic rule for a field in RRF
 * @param  {Object[]} dynamicRules list of dynamic rules
 * @param  {String | Number} value        value of the field
 * @return {string|null} error message
 */
function validateFieldDynamicRulesRRF(dynamicRules, value) {
    // currently dynamic rules here just check if the field is required
    if (!isEmpty(dynamicRules)) {
        // don't want to just check if !value because sometimes 0 is a valid value
        // (such as OTHER for recovering person in item panel)
        if (value === null || value === undefined || value === '') {
            return validationStrings.field.required;
        }
        // valid
        return null;
    } else {
        return null;
    }
}

/**
 * Validate field configurations for a field in RRF
 * @param  {Object} fieldDetailsByFieldName         all field details
 * @param  {Object} fieldConfigurationByFieldName   all field configurations
 * @param  {String} fieldName FIELD_NAME of field to validate
 * @param  {String | Number} value     value of the field
 * @return {String}           error message
 */
function validateFieldConfigurationRulesRRF(
    fieldDetailsByFieldName,
    fieldConfigurationByFieldName,
    fieldName,
    value
) {
    const fieldConfiguration = fieldConfigurationByFieldName[fieldName] || {};
    const fieldDetail = fieldDetailsByFieldName[fieldName] || {};
    let errorMessage = '';

    switch (fieldDetail.fieldType) {
        case FieldTypeEnum.STRING.name:
            if (fieldConfiguration.max) {
                if (!!value && value.length > _.parseInt(fieldConfiguration.max)) {
                    errorMessage = validationStrings.max[fieldDetail.fieldType](
                        fieldConfiguration.max
                    );
                }
            }
            if (fieldConfiguration.min) {
                if (!!value && value.length < _.parseInt(fieldConfiguration.min)) {
                    errorMessage = validationStrings.min[fieldDetail.fieldType](
                        fieldConfiguration.min
                    );
                }
            }
            if (
                fieldConfiguration.max &&
                fieldConfiguration.min &&
                _.parseInt(fieldConfiguration.min) === _.parseInt(fieldConfiguration.max)
            ) {
                if (!!value && value.length !== _.parseInt(fieldConfiguration.min)) {
                    errorMessage = validationStrings.exactLength[fieldDetail.fieldType](
                        fieldConfiguration.min
                    );
                }
            }
            break;
        case FieldTypeEnum.NUMBER.name:
            // check if is a number
            if (!!value && !numberRegex.test(value)) {
                errorMessage = validationStrings.fieldType[fieldDetail.fieldType];
            }
            if (fieldConfiguration.max) {
                if (!!value && _.parseInt(value) > _.parseInt(fieldConfiguration.max)) {
                    errorMessage = validationStrings.max[fieldDetail.fieldType](
                        fieldConfiguration.max
                    );
                }
            }
            if (fieldConfiguration.min) {
                if (!!value && _.parseInt(value) < _.parseInt(fieldConfiguration.min)) {
                    errorMessage = validationStrings.min[fieldDetail.fieldType](
                        fieldConfiguration.min
                    );
                }
            }
            break;
        case FieldTypeEnum.INTEGER.name:
            //  check if is integer
            if (!!value && !integerRegex.test(value)) {
                errorMessage = validationStrings.fieldType[fieldDetail.fieldType];
            }
            if (fieldConfiguration.max) {
                if (!!value && _.parseInt(value) > _.parseInt(fieldConfiguration.max)) {
                    errorMessage = validationStrings.max[fieldDetail.fieldType](
                        fieldConfiguration.max
                    );
                }
            } else if (fieldConfiguration.min) {
                if (!!value && _.parseInt(value) < _.parseInt(fieldConfiguration.min)) {
                    errorMessage = validationStrings.min[fieldDetail.fieldType](
                        fieldConfiguration.min
                    );
                }
            }
            break;
        case FieldTypeEnum.DATE.name:
        case FieldTypeEnum.DATETIME.name:
            const now = moment();

            if (fieldConfiguration.max) {
                const maxDate =
                    fieldConfiguration.max === 'NOW' ? now : moment(fieldConfiguration.max);
                if (!!value && moment(value).isAfter(maxDate, 'minute')) {
                    errorMessage = validationStrings.max[fieldDetail.fieldType](
                        fieldConfiguration.max
                    );
                }
            }
            if (fieldConfiguration.min) {
                const minDate =
                    fieldConfiguration.min === 'NOW' ? now : moment(fieldConfiguration.min);
                if (!!value && moment(value).isBefore(minDate, 'minute')) {
                    errorMessage = validationStrings.min[fieldDetail.fieldType](
                        fieldConfiguration.min
                    );
                }
            }
            break;
        default:
            break;
    }

    return errorMessage;
}

/**
 * Get system and dynamic rules from Redux state given a context object
 * Can also take an array of contexts
 */
export function getRules(context, state) {
    const report = currentReportSelector(state);

    // Injecting report definition id into context!
    if (report) {
        if (_.isArray(context)) {
            context = _.map(context, (singleContext) => ({
                ...singleContext,
                reportDefinitionId: report.reportDefinitionId,
            }));
        } else {
            context = {
                ...context,
                reportDefinitionId: report.reportDefinitionId,
            };
        }
    }

    const isLegacy = get(report, 'isLegacyReport');
    const systemRulesForContext = _.isArray(context)
        ? getSystemRulesForContexts(state, context)
        : getSystemRulesForContext(state, context);
    const dynamicRulesForContext = _.isArray(context)
        ? getDynamicRulesForContexts(state, context)
        : getDynamicRulesForContext(state, context);
    const systemRules = isLegacy
        ? _.filter(systemRulesForContext, (rule) => rule.isLegacyActive)
        : systemRulesForContext;
    const dynamicRules = isLegacy
        ? _.filter(dynamicRulesForContext, (rule) => rule.isLegacyActive)
        : dynamicRulesForContext;
    return { systemRules, dynamicRules };
}

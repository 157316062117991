// core deps
import { createSelector } from 'reselect';
import _, { map, mapValues, sortBy, compact } from 'lodash';
import qs from 'qs';

// selectors
import { elasticInvolvedLocationsSelector } from '../data';
import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '../../../attributes/state/data';

// view model helpers
import {
    buildViewModel,
    allAttributeIdListsMapper,
    allSingleAttributeValuesMapper,
    subdivisionAttrIdsMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatSubPremise } from '../../../locations/utils/subPremiseHelpers';
import { applicationSettingsSelector } from '../../../settings/state/data';

// TODO PRINTING-REFACTOR relocate elasticLocationHelpers, urlHelpers so that these don't have to be inline

let googleStaticMapsApiKey = 'AIzaSyAOvGJzioG47sapmAGR5JXmYczVJlpHgvY';
if (
    typeof googleStaticMapsApiKey !== 'string' ||
    googleStaticMapsApiKey.indexOf('{{googleStaticMapsApiKey') === 0
) {
    googleStaticMapsApiKey = null;
}

function stringifyQuery(arrayFormat, params, options = {}) {
    options = _.defaults(options, {
        snakeCaseKeys: true,
        includeQuestionMark: true,
    });

    params = _.omitBy(params, (value) => value === null || value === undefined);

    if (options.snakeCaseKeys) {
        params = _.mapKeys(params, (value, key) => _.snakeCase(key));
    }

    const queryString = qs.stringify(params, {
        arrayFormat,
    });

    return options.includeQuestionMark && queryString ? `?${queryString}` : queryString;
}

const clientStringifyQuery = _.partial(stringifyQuery, 'brackets');

export function formatAliases(aliases) {
    return sortBy(aliases).join(' / ');
}

export function buildElasticLocationLines(
    elasticLocationOrInvolvedLocation,
    { showSubPremise = true, isSubPremiseSupportEnabled, showAliases = true } = {}
) {
    const { aliases } = elasticLocationOrInvolvedLocation;
    // this check is required because we might have a legacy `ElasticLocation`
    // model or a new `ElasticInvolvedLocation`. Since we might still have
    // legacy data in elastic search we have to support both versions
    const address =
        elasticLocationOrInvolvedLocation.locationAddress || elasticLocationOrInvolvedLocation;
    const {
        streetAddress,
        locality,
        administrativeAreaLevel1,
        postalCode,
        crossStreet1,
        crossStreet2,
    } = address;
    const crossStreets = compact([crossStreet1, crossStreet2]);
    const crossStreetsDisplay =
        crossStreets.length === 1 ? `at ${crossStreets.join()}` : crossStreets.join(' & ');

    if (isSubPremiseSupportEnabled) {
        return compact([
            showAliases && formatAliases(aliases),
            compact([
                compact([streetAddress, locality, administrativeAreaLevel1]).join(', '),
                postalCode,
            ]).join(' '),
            showSubPremise && formatSubPremise(elasticLocationOrInvolvedLocation),
            streetAddress !== crossStreetsDisplay && crossStreetsDisplay,
        ]);
    }

    return compact([
        showAliases && formatAliases(aliases),
        streetAddress,
        showSubPremise && elasticLocationOrInvolvedLocation.subPremise,
        streetAddress !== crossStreetsDisplay && crossStreetsDisplay,
        compact([compact([locality, administrativeAreaLevel1]).join(', '), postalCode]).join(' '),
    ]);
}

export function formatStaticMapUrl({ latitude, longitude }, size = '199x199', zoom = 16) {
    if (!latitude || !longitude) {
        return null;
    }

    return `https://maps.googleapis.com/maps/api/staticmap${clientStringifyQuery({
        key: googleStaticMapsApiKey,
        // 1 level higher than the default, to have more street names
        // and surrounding landmarks appear
        zoom,
        size, // default matches css rule for `.map` in EntityProfileStyles
        scale: 1,
        maptype: 'roadmap',
        /* eslint-disable comma-spacing */
        markers: `size:mid|${latitude},${longitude}`,
    })}`;
}

const buildElasticInvolvedLocationViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
    applicationSettingsSelector,
    (formatAttributeById, formatSubdivisionAttrIds, applicationSettings) =>
        buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                allAttributeIdListsMapper,
                subdivisionAttrIdsMapper,
                (elasticInvolvedLocation) => ({
                    lines: buildElasticLocationLines(elasticInvolvedLocation, {
                        isSubPremiseSupportEnabled: applicationSettings.SUBPREMISE_SUPPORT_ENABLED,
                    }),
                }),
                (elasticInvolvedLocation) => ({
                    // https://github.com/mark43/mark43/blob/50aa3d8b6c5295f11afbde743772dc563e804277/cobalt-rms/master/location/location-common/src/main/java/com/mark43/master/location/view/LocationViewBase.java#L90-L94
                    isVerified: !!(
                        elasticInvolvedLocation.locationAddress &&
                        elasticInvolvedLocation.locationAddress.longitude &&
                        elasticInvolvedLocation.locationAddress.latitude
                    ),
                }),
            ],
            helpers: {
                formatAttributeById,
                formatSubdivisionAttrIds,
            },
        })
);

export const elasticInvolvedLocationViewModelsSelector = createSelector(
    elasticInvolvedLocationsSelector,
    buildElasticInvolvedLocationViewModelSelector,
    (elasticInvolvedLocations, buildElasticInvolvedLocationViewModel) =>
        mapValues(elasticInvolvedLocations, buildElasticInvolvedLocationViewModel)
);

export const createLocationsResultsSelector = (baseSelector) =>
    createSelector(
        baseSelector,
        buildElasticInvolvedLocationViewModelSelector,
        (locationResults, buildElasticInvolvedLocationViewModel) =>
            map(locationResults, buildElasticInvolvedLocationViewModel)
    );

import { get } from 'lodash';

import { useCurrentFormSummaryValue } from './use-current-form-summary-value';

/**
 * This hook returns the value for a given path (e.g. `a.b.c[0].d`) within the static form value
 * for the current form context.
 */
export function useSummaryValueForPath(path: string): unknown {
    return get(useCurrentFormSummaryValue().values, path);
}

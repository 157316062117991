import {
    BriefingsResource,
    BriefingCreationRequest,
    BriefingUpdateRequest,
    BriefingBodyUpdateRequest,
    SqlSearchQueryBriefingSearchSqlQuery,
    BriefingDuplicateRequest,
    ElasticSearchTypeEnumType,
    SavedSearch,
} from '@mark43/rms-api';
import { createResource } from '../../../lib/resources/Resource';
import { req } from '../../../lib/ajax';
import { queryParamDefaults } from '../configuration';

export default createResource({
    name: 'Briefing Resource',
    methods: {
        createBriefing(creationPayload: BriefingCreationRequest) {
            return req<BriefingsResource.CreateBriefing>({
                method: 'POST',
                url: 'briefings',
                data: creationPayload,
            });
        },
        getBriefing(briefingId: number, hideLoadingBar = false) {
            return req<BriefingsResource.GetBriefing>({
                method: 'GET',
                url: `briefings/${briefingId}/`,
                hideLoadingBar,
            });
        },
        updateBriefing(briefingId: number, updatePayload: BriefingUpdateRequest) {
            return req<BriefingsResource.UpdateBriefing>({
                method: 'PUT',
                url: `briefings/${briefingId}/`,
                data: updatePayload,
            });
        },
        updateBriefingBody(briefingId: number, bodyUpdatePayload: BriefingBodyUpdateRequest) {
            return req<BriefingsResource.UpdateBriefingBody>({
                method: 'PUT',
                url: `briefings/${briefingId}/body`,
                data: bodyUpdatePayload,
            });
        },
        postBriefing(briefingId: number) {
            return req<BriefingsResource.PostBriefing>({
                method: 'PUT',
                url: `briefings/${briefingId}/posted`,
            });
        },
        duplicateBriefing(briefingId: number, duplicatePayload: BriefingDuplicateRequest) {
            return req<BriefingsResource.DuplicateBriefing>({
                method: 'POST',
                url: `briefings/${briefingId}/duplicate`,
                data: duplicatePayload,
            });
        },
        deleteBriefing(briefingId: number) {
            return req<BriefingsResource.DeleteBriefing>({
                method: 'DELETE',
                url: `briefings/${briefingId}/`,
            });
        },
        search(
            data: SqlSearchQueryBriefingSearchSqlQuery,
            elasticSearchType?: ElasticSearchTypeEnumType
        ) {
            return req<BriefingsResource.SearchBriefings>({
                method: 'POST',
                url: 'briefings/search',
                data,
                params: elasticSearchType ? { auto_save_type: elasticSearchType } : {},
            });
        },
        executeSavedSearch: (
            savedSearch: SavedSearch,
            { from = queryParamDefaults.FROM, size = queryParamDefaults.SIZE } = {}
        ) => {
            return req<BriefingsResource.ExecuteBriefingsSavedSearch>({
                method: 'POST',
                url: 'briefings/search/saved/execute',
                params: { from, size },
                data: savedSearch,
            });
        },
        getMyBriefings(from = 0, size = 10, hideLoadingBar = true) {
            return req<BriefingsResource.GetUserBriefings>({
                method: 'GET',
                url: 'briefings/mine',
                params: { from, size },
                hideLoadingBar,
            });
        },
    },
});

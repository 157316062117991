import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { every } from 'lodash';
import { withTheme } from 'styled-components';
import { Rectangle, Marker } from 'react-google-maps';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

// map
import { convertToEsriPolygon } from '~/client-common/core/maps/helpers/avlHelpers';
import GoogleMapWrapper from '../../../../modules/core/maps/components/GoogleMapWrapper';
import EsriSimpleMapWrapper from '../../../../modules/core/maps/components/EsriSimpleMapWrapper';
import NoMapContainer from '../../../../modules/core/maps/components/NoMapContainer';

const DEFAULT_MAP_STYLES = {
    height: 350,
    width: 350,
    marginBottom: 10,
};

function DepartmentProfileAdminMap(props) {
    const { locationBias, theme, styles = {}, applicationSettings, zoomToPolygons } = props;
    const boxBorder = {
        north: parseFloat(locationBias.northeastLat),
        south: parseFloat(locationBias.southwestLat),
        east: parseFloat(locationBias.northeastLng),
        west: parseFloat(locationBias.southwestLng),
    };

    const center = {
        lat: parseFloat(locationBias.centerLat),
        lng: parseFloat(locationBias.centerLng),
    };
    const boxCenter = {
        lat: (parseFloat(locationBias.northeastLat) + parseFloat(locationBias.southwestLat)) / 2,
        lng: (parseFloat(locationBias.northeastLng) + parseFloat(locationBias.southwestLng)) / 2,
    };

    const hasCenter = every(center);
    const hasBoxBorder = every(boxBorder);

    const memoizedCenter = useMemo(() => {
        return {
            lat: center.lat,
            lng: center.lng,
        };
    }, [center.lat, center.lng]);

    const polygons = useMemo(() => {
        const vertices = [
            { lng: boxBorder.east, lat: boxBorder.north },
            { lng: boxBorder.east, lat: boxBorder.south },
            { lng: boxBorder.west, lat: boxBorder.south },
            { lng: boxBorder.west, lat: boxBorder.north },
        ];
        const polygons = [];

        if (hasBoxBorder) {
            polygons.push(convertToEsriPolygon(memoizedCenter, vertices, 0));
        }

        return polygons;
    }, [
        boxBorder.east,
        boxBorder.west,
        boxBorder.south,
        boxBorder.north,
        memoizedCenter,
        hasBoxBorder,
    ]);

    if (hasCenter || hasBoxBorder) {
        if (applicationSettings.ESRI_MAPS_ENABLED) {
            return (
                <EsriSimpleMapWrapper
                    styles={{ ...DEFAULT_MAP_STYLES, ...styles.map }}
                    defaultCenter={memoizedCenter}
                    selectedLocation={{ latitude: center.lat, longitude: center.lng }}
                    polygons={polygons}
                    enableMove
                    enableZoom
                    zoom={6}
                    zoomToPolygons={zoomToPolygons}
                />
            );
        } else {
            return (
                <GoogleMapWrapper
                    styles={{ ...DEFAULT_MAP_STYLES, ...styles.map }}
                    center={hasCenter ? memoizedCenter : boxCenter}
                >
                    {hasCenter && <Marker position={memoizedCenter} />}
                    {hasBoxBorder && (
                        <Rectangle
                            bounds={boxBorder}
                            options={{
                                fillColor: theme.colors.cobaltBlue,
                                strokeColor: theme.colors.cobaltBlue,
                                strokeWeight: 1,
                            }}
                        />
                    )}
                </GoogleMapWrapper>
            );
        }
    } else {
        return <NoMapContainer styles={{ ...DEFAULT_MAP_STYLES, ...styles.noMap }} />;
    }
}

export default connect(
    createStructuredSelector({
        applicationSettings: applicationSettingsSelector,
    })
)(withTheme(DepartmentProfileAdminMap));

import { FormEnum } from '@mark43/rms-api';
import {
    ROLE_APPROVAL_APPROVAL_LEVEL,
    ROLE_APPROVAL_APPROVAL_STATUS,
} from '~/client-common/core/enums/universal/fields';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

export default createFormModule({
    formName: FormEnum.ADMIN_ROLE_APPROVAL.name,
    convertToFormModel: ({ approvalLevel, approvalStatus } = {}) => {
        return {
            approvalLevel,
            approvalStatus,
        };
    },
    fieldViewModels: buildFlatFormFieldViewModels([
        {
            key: 'approvalLevel',
            fieldName: ROLE_APPROVAL_APPROVAL_LEVEL,
        },
        {
            key: 'approvalStatus',
            fieldName: ROLE_APPROVAL_APPROVAL_STATUS,
        },
    ]),
});

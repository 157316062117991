// should be exported from './index.js'.
// entityManager dependency loop preventing that.
import { RefContextEnum } from '@mark43/rms-api';
import { get, map, values, uniq, filter, includes, first, intersection } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data/';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { statusIsClosedSelector, statusIsCanceledSelector, caseStatusGlobalAttrIdToChildAttrIdsSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import {
    reportCaseStatusesSelector,
    reportCaseStatusesForValidReportsSelector,
    reportIdsForHasReportCaseStatusEnabledSelector,
    loadReportCaseStatuses,
    saveReportCaseStatuses,
} from '~/client-common/core/domain/report-case-statuses/state/data';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { loadReportShortTitlesByReportingEventId } from '~/client-common/core/domain/report-short-titles/state/data';
import { externalReportStatusForReportingEventIdSelector } from '~/client-common/core/domain/external-report-statuses/state/data';
import { offenseCaseStatusesSelector } from '~/client-common/core/domain/offense-case-statuses/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { currentReportEventIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    saveExternalReportStatus,
    loadExternalReportStatusForReportingEventId,
} from '../../../../core/external-report-statuses/state/data/externalReportStatuses';
import {
    saveOffenseCaseStatuses,
    loadOffenseCaseStatusesForReportingEventId,
    sortedOffenseCaseStatusesForOffenseCaseStatusesAndReportIdsSelector,
} from '../../../../core/offense-case-statuses/state/data/offenseCaseStatuses';
import {
    convertToReportCaseStatusesFormModel,
    convertFromFormModel,
} from '../forms/reportCaseStatusesForm';
import {
    convertFromExternalReportStatusFormModel,
    convertToExternalReportStatusFormModel,
} from '../../../../core/external-report-statuses/state/form/externalReportStatusForm';
import {
    convertFromOffenseCaseStatusesFormModel,
    convertToOffenseCaseStatusesFormModel,
} from '../../../../core/offense-case-statuses/state/form/offenseCaseStatusesForm';

import formsRegistry from '../../../../../core/formsRegistry';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import {
    openBox,
    closeBox,
    saveBoxStart,
    saveBoxSuccess,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';

import { getErrorMessagesFromErrors } from '../../helpers/validationHelpers';

const context = { name: boxEnum.CASE_STATUSES_SIDE_PANEL };

/**
 * Open the case status side panel and load the current report's case
 *   statuses.
 * @param  {number} reportingEventId
 * @return {Promise<Object>}
 */
export function openCaseStatusesSidePanel(reportId, reportingEventId) {
    return function (dispatch, getState) {
        dispatch(openBox(context));

        const state = getState();
        const RMS_CLEARANCE_IMPROVEMENTS_ENABLED = applicationSettingsSelector(state)
            .RMS_CLEARANCE_IMPROVEMENTS_ENABLED;
        const currentUserHasAbility = currentUserHasAbilitySelector(state);
        const userHasViewExternalReportStatusAbility = currentUserHasAbility(
            abilitiesEnum.REPORTING.VIEW_EXTERNAL_REPORT_STATUS
        );
        const userHasViewReportCaseStatusAbility = currentUserHasAbility(
            abilitiesEnum.REPORTING.VIEW_CASE_STATUS
        );

        const shouldLoadExternalReportStatus =
            RMS_CLEARANCE_IMPROVEMENTS_ENABLED &&
            userHasViewExternalReportStatusAbility &&
            reportingEventId;

        const shouldLoadOffenseCaseStatuses =
            userHasViewReportCaseStatusAbility && !!reportingEventId;

        Promise.all([
            userHasViewReportCaseStatusAbility
                ? dispatch(loadReportCaseStatuses(reportId))
                : Promise.resolve([]),
            shouldLoadExternalReportStatus
                ? dispatch(loadExternalReportStatusForReportingEventId(reportingEventId))
                : Promise.resolve(),
            shouldLoadOffenseCaseStatuses
                ? dispatch(loadOffenseCaseStatusesForReportingEventId(reportingEventId))
                : Promise.resolve([]),
            reportingEventId
                ? dispatch(loadReportShortTitlesByReportingEventId(reportingEventId))
                : Promise.resolve([]),
        ]).then(([, externalReportStatus, offenseCaseStatuses, reportShortTitles]) => {
            const reportIds = uniq(map(reportShortTitles, 'reportId'));

            const state = getState();
            const reportCaseStatuses = reportCaseStatusesForValidReportsSelector(state);
            const statusIsCanceledForId = statusIsCanceledSelector(state);
            const adjustedReportCaseStatuses = map(reportCaseStatuses, (reportStatus) => {
                const isCanceledAttr = statusIsCanceledForId(reportStatus.caseStatusAttrId);
                return {
                    isCanceled: isCanceledAttr,
                    ...reportStatus,
                }
            })
            const filteredReportCaseStatuses = filter(adjustedReportCaseStatuses, (reportCaseStatus) =>
                includes(reportIds, reportCaseStatus.reportId)
            );
            // this array may include report ids which are not linked to the report or case currently being viewed
            const reportIdsForHasReportCaseStatusEnabled = reportIdsForHasReportCaseStatusEnabledSelector(
                state
            );
            // narrow down the array a subset of the current report ids
            const reportIdsWithReportCaseStatuses = intersection(reportIdsForHasReportCaseStatusEnabled, reportIds);

            const sortedOffenseCaseStatuses = sortedOffenseCaseStatusesForOffenseCaseStatusesAndReportIdsSelector(
                state
            )({
                offenseCaseStatuses,
                reportShortTitles,
                reports: undefined,
                reportIds,
            });

            formsRegistry.maybeDeferredOperation(
                RefContextEnum.FORM_REPORT_CASE_STATUSES_SIDE_PANEL.name,
                0,
                (form) => {
                    form.set({
                        reportCaseStatuses: convertToReportCaseStatusesFormModel(
                            filteredReportCaseStatuses,
                            reportIdsWithReportCaseStatuses
                        ),
                        externalReportStatus: convertToExternalReportStatusFormModel(
                            externalReportStatus
                        ),
                        offenseCaseStatuses: dispatch(
                            convertToOffenseCaseStatusesFormModel(sortedOffenseCaseStatuses)
                        ),
                    });
                }
            );
        });
    };
}

export function submitReportCaseStatuses() {
    return (dispatch, getState) => {
        const state = getState();
        const RMS_CLEARANCE_IMPROVEMENTS_ENABLED = applicationSettingsSelector(state)
            .RMS_CLEARANCE_IMPROVEMENTS_ENABLED;
        const currentReportEventId = currentReportEventIdSelector(state);
        const currentUserHasAbility = currentUserHasAbilitySelector(state);
        const userHasEditExternalReportStatusAbility = currentUserHasAbility(
            abilitiesEnum.REPORTING.EDIT_EXTERNAL_REPORT_STATUS
        );
        const userHasEditReportCaseStatusAbility = currentUserHasAbility(
            abilitiesEnum.REPORTING.EDIT_CASE_STATUS
        );

        const processFormModel = (formModel) => {
            const state = getState();
            const externalReportStatus = externalReportStatusForReportingEventIdSelector(state)(
                currentReportEventId
            );

            const offenseCaseStatuses = offenseCaseStatusesSelector(state);

            const statusIsClosedForId = statusIsClosedSelector(state);
            const groupedStatuses = caseStatusGlobalAttrIdToChildAttrIdsSelector(state);
            const defaultCanceledCaseStatusAttribute = first(groupedStatuses[globalAttributes.caseStatus.canceled]);
            const formDataCaseStatuses = map(formModel.reportCaseStatuses, (caseStatus) => {
                const {isCanceled, ...trimmedCaseStatus} = caseStatus;
                if(!defaultCanceledCaseStatusAttribute && isCanceled){
                    throw new Error(componentStrings.reports.ReportCaseStatusesForm.noCancelAttributeError);
                }
                if (!statusIsClosedForId(caseStatus.caseStatusAttrId)) {
                    return {
                        ...trimmedCaseStatus,
                        closedByDivisionAttrId: null,
                        closedByUnitAttrId: null,
                        statusDateUtc: null,
                        caseStatusAttrId: isCanceled ? defaultCanceledCaseStatusAttribute : caseStatus.caseStatusAttrId,
                    };
                }
                return trimmedCaseStatus;
            });

            const reportCaseStatuses = values(
                convertFromFormModel(reportCaseStatusesSelector(state), formDataCaseStatuses)
            );

            const shouldSaveExternalReportStatus =
                RMS_CLEARANCE_IMPROVEMENTS_ENABLED &&
                userHasEditExternalReportStatusAbility &&
                get(formModel, 'externalReportStatus.reportingEventId');

            const shouldSaveOffenseCaseStatuses =
                userHasEditReportCaseStatusAbility && currentReportEventId;

            const formDataOffenseCaseStatuses = map(formModel.offenseCaseStatuses, (caseStatus) => {
                if (!caseStatus.caseStatusAttrId) {
                    return {
                        ...caseStatus,
                        closedByDivisionAttrId: null,
                        closedByUnitAttrId: null,
                        statusDateUtc: null,
                    };
                }
                return caseStatus;
            });

            dispatch(saveBoxStart(context));

            return Promise.all([
                userHasEditReportCaseStatusAbility
                    ? dispatch(saveReportCaseStatuses(reportCaseStatuses))
                    : Promise.resolve([]),
                shouldSaveExternalReportStatus
                    ? dispatch(
                          saveExternalReportStatus(
                              convertFromExternalReportStatusFormModel(
                                  formModel.externalReportStatus,
                                  externalReportStatus
                              )
                          )
                      )
                    : Promise.resolve(),
                shouldSaveOffenseCaseStatuses
                    ? dispatch(
                          saveOffenseCaseStatuses(
                              currentReportEventId,
                              convertFromOffenseCaseStatusesFormModel(
                                  formDataOffenseCaseStatuses,
                                  offenseCaseStatuses
                              )
                          )
                      )
                    : Promise.resolve([]),
            ]);
        };

        // If the FF is on, then submit the m43 form.
        // Else submit the react-redux form
        return formsRegistry
            .get(RefContextEnum.FORM_REPORT_CASE_STATUSES_SIDE_PANEL.name)
            .submit()
            .then((result) => result.form.getState().model)
            .then(processFormModel)
            .then(() => {
                dispatch(saveBoxSuccess(context));
                dispatch(closeReportCaseStatusesSidePanel());
            })
            .catch((errors) => {
                const errorMessages = getErrorMessagesFromErrors(errors);
                dispatch(saveBoxFailure(context, errorMessages));
            });
    };
}

export function closeReportCaseStatusesSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(context));
    };
}

import { WarrantDexSendTypeEnum, CodeTypeSourceEnum } from '@mark43/rms-api';
import React from 'react';
import { first, get, some } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import Composer from 'react-composer';

import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import getWarrantsResource from '~/client-common/core/domain/warrants/resources/warrantsResource';
import {
    warrantDexSubmissionsWhereSelector,
    replaceWarrantDexSubmissionsWhere,
} from '~/client-common/core/domain/warrant-dex-submissions/state/data';
import { formatAttributeWithOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import { attributeCodesWhereSelector } from '~/client-common/core/domain/attribute-codes/state/data';
import { codesByCodeIdSelector } from '~/client-common/core/domain/codes/state/data';
import withFields from '~/client-common/core/fields/components/withFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import _Select from '../../../core/forms/components/selects/Select';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import RecordSidebarSection from '../../../records/core/components/sidebar/RecordSidebarSection';
import { logWarning } from '../../../../core/logging';
import { getMachineNameFromMetadata } from '../../../dex/utils';

const strings = componentStrings.warrants.warrant.WarrantDexSidebarSection;

const EntryLevelText = styled.div`
    font-style: italic;
    font-size: var(--arc-fontSizes-sm);
    padding: 10px 0;
`;

const SendButton = styled(Button)`
    margin-top: 19px;
`;

const Select = styled(_Select)`
    float: none;
`;

const FormSection = styled.div`
    display: flex;
`;

const Banner = styled.div`
    position: relative;
    font-style: italic;
    box-sizing: border-box;
    padding: 12px 12px;
    font-size: var(--arc-fontSizes-sm);
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    display: flex;
`;

const EntrySuccessBanner = styled(Banner)`
    background-color: ${(props) => props.theme.colors.lightBlue};
    border: 1px solid ${(props) => props.theme.colors.brightBlue};
`;

const CancelSuccessBanner = styled(Banner)`
    background-color: ${(props) => props.theme.colors.lightYellow};
    border: 1px solid ${(props) => props.theme.colors.brightYellow};
`;

const ErrorBanner = styled(Banner)`
    background-color: var(--arc-colors-negative-accent);
    border: 1px solid var(--arc-colors-negative-default);
`;

class _DataExchange extends React.Component {
    constructor(...props) {
        super(...props);

        this.state = {
            selectedSendType: null,
            dexApiErrorMessage: null,
        };
    }

    handleActionSelectChange = (value) => {
        this.setState({ selectedSendType: value });
    };

    handleSubmit = () => {
        const warrantsResource = getWarrantsResource();
        const port = this.props.applicationSettings.CAD_DESKTOP_INTEGRATION_PORT;

        warrantsResource
            .getDesktopMetadata(port)
            .then((data) => {
                const machineName = getMachineNameFromMetadata({ data, port });
                return warrantsResource
                    .sendWarrantDexSubmission({
                        warrantId: this.props.warrantId,
                        sendType: this.state.selectedSendType,
                        machineName,
                    })
                    .then((response) => {
                        const dexApiErrorMessage = get(response, 'dexApiErrorMessage');
                        const warrantDexSubmission = get(response, 'warrantDexSubmission');
                        this.props.replaceWarrantDexSubmission(warrantDexSubmission);
                        this.setState({ dexApiErrorMessage });
                    })
                    .catch(() => {
                        this.setState({ dexApiErrorMessage: 'Failed to Send' });
                    });
            })
            .catch((error) => {
                logWarning('getDesktopMetadata failed', { error, port });
                this.setState({ dexApiErrorMessage: 'Failed to retrieve machine name' });
            });
    };

    render() {
        const {
            warrantId,
            formatAttributeWithOther,
            warrantDexSubmissionsWhere,
            codesByCodeId,
            fieldDisplayNames,
            attributeCodesWhere,
            router,
        } = this.props;
        let content = null;
        const warrantDexSubmission = first(warrantDexSubmissionsWhere({ warrantId }));
        const entryLevel = warrantDexSubmission
            ? formatAttributeWithOther({
                  attributeId: warrantDexSubmission.warrantEntryLevelCodeAttrId,
                  other: warrantDexSubmission.warrantEntryLevelCodeOther,
              })
            : null;

        const errorMessage = this.state.dexApiErrorMessage;

        const lastSendDateUtc = get(warrantDexSubmission, 'lastSendDateUtc');
        const lastSendType = get(warrantDexSubmission, 'lastSendType');
        const lastSentBy = get(warrantDexSubmission, 'lastSentBy');

        // Only show one of Entry Level Text, Error Banner and Success Banner at a time
        // The hierarchy of which to display is:
        //  1. Error Banner
        //  2. Entry/Cancel Success Banner
        //  3. Entry Level Text
        const showEntrySuccessBanner =
            !errorMessage && lastSendType === WarrantDexSendTypeEnum.ENTRY.name;
        const showCancelSuccessBanner =
            !errorMessage && lastSendType === WarrantDexSendTypeEnum.CANCEL.name;
        const showEntryLevelText =
            !errorMessage && !showEntrySuccessBanner && !showCancelSuccessBanner;

        const successBannerContent = (
            <Composer
                components={[
                    <FormattedDate
                        key={lastSendDateUtc}
                        date={lastSendDateUtc}
                        format={FormattedDate.FORMATS.DATE_TIME_IN_SENTENCE}
                    />,
                    <ConnectedFormattedUser
                        key={lastSentBy}
                        userId={lastSentBy}
                        format={FORMATS.FULL_NAME}
                    />,
                ]}
            >
                {([date, user]) => <div>{`${lastSendType} sent on ${date} by ${user}.`}</div>}
            </Composer>
        );

        const successBanner = (
            <div>
                {showEntrySuccessBanner && (
                    <EntrySuccessBanner>{successBannerContent}</EntrySuccessBanner>
                )}
                {showCancelSuccessBanner && (
                    <CancelSuccessBanner>{successBannerContent}</CancelSuccessBanner>
                )}
            </div>
        );

        const errorBanner = <div>{errorMessage && <ErrorBanner>{errorMessage}</ErrorBanner>}</div>;

        if (!entryLevel) {
            content = (
                <div>
                    {showEntryLevelText && (
                        <EntryLevelText>
                            {strings.entryMustBeEntered(
                                fieldDisplayNames.WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_ATTR_ID
                            )}
                        </EntryLevelText>
                    )}
                    {errorBanner}
                    {successBanner}
                </div>
            );
        } else {
            const attributeCodes = attributeCodesWhere({
                attributeId: warrantDexSubmission.warrantEntryLevelCodeAttrId,
            });
            // only allow form to show if warrant entry level attribute
            // is mapped to a code that has codeType source = "CA_CLETS"
            const showForm = some(attributeCodes, (attributeCode) => {
                const code = codesByCodeId(attributeCode.codeId);
                return get(code, 'codeType.source') === CodeTypeSourceEnum.CA_CLETS.name;
            });
            content = (
                <div>
                    {showEntryLevelText && (
                        <EntryLevelText>
                            {strings.entryLevelText(
                                fieldDisplayNames.WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_ATTR_ID,
                                entryLevel
                            )}
                        </EntryLevelText>
                    )}
                    {errorBanner}
                    {successBanner}
                    {showForm && (
                        <FormSection>
                            <Select
                                options={[
                                    {
                                        value: WarrantDexSendTypeEnum.ENTRY.name,
                                        display: strings.entry,
                                    },
                                    {
                                        value: WarrantDexSendTypeEnum.CANCEL.name,
                                        display: strings.cancel,
                                    },
                                ]}
                                width={200}
                                onChange={this.handleActionSelectChange}
                                value={this.state.selectedSendType}
                                label={strings.action}
                            />
                            <SendButton
                                float={'none'}
                                className={buttonTypes.SECONDARY}
                                onClick={this.handleSubmit}
                                disabled={!this.state.selectedSendType}
                            >
                                {strings.send}
                            </SendButton>
                        </FormSection>
                    )}
                </div>
            );
        }

        return (
            <RecordSidebarSection
                title={strings.dataExchange}
                onEdit={() => {
                    router.push(`/warrants/${warrantId}/returns`);
                }}
                onEditIconType={null}
                onEditLabel={strings.viewReturns}
            >
                {content}
            </RecordSidebarSection>
        );
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    replaceWarrantDexSubmission: (warrantDexSubmission) =>
        dispatch(
            replaceWarrantDexSubmissionsWhere({ warrantId: props.warrantId }, warrantDexSubmission)
        ),
});

export default compose(
    withFields(['WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_ATTR_ID']),
    withRouter,
    connect(
        createStructuredSelector({
            warrantDexSubmissionsWhere: warrantDexSubmissionsWhereSelector,
            formatAttributeWithOther: formatAttributeWithOtherSelector,
            attributeCodesWhere: attributeCodesWhereSelector,
            codesByCodeId: codesByCodeIdSelector,
            applicationSettings: applicationSettingsSelector,
        }),
        mapDispatchToProps
    )
)(_DataExchange);

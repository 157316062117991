import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName, mapProps } from 'recompose';
import { map } from 'lodash';

import { abilitiesSelector } from '~/client-common/core/domain/abilities/state/data';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { currentUserDepartmentIdSelector } from '../../../current-user/state/ui';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

/**
 * Typeahead for selecting abilities.
 * @param {Object} props Same props as `Select`.
 * @param {boolean} [props.useExternalAbilities=false]  Whether to show external or internal abilities
 */
const AbilitySelect = compose(
    setDisplayName('AbilitySelect'),
    connect(
        createStructuredSelector({
            abilities: abilitiesSelector,
            currentUserDepartmentId: currentUserDepartmentIdSelector,
        })
    ),
    mapProps((props) => {
        const options = map(props.abilities, (ability) => ({
            display: ability.name,
            value: ability.id,
        }));
        return {
            options,
            ...props,
        };
    })
)(function AbilitySelect({ options, ...otherProps }) {
    return <Select {...otherProps} options={options} />;
});

export const RRFAbilitySelect = connectRRFInput(AbilitySelect);

import { useCallback } from 'react';
import { forEach, has, isArray, isFunction } from 'lodash';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import {
    getNextFocusableElement,
    getPreviousFocusableElement,
} from '~/client-common/core/keyboardFocus/helpers';

export const onClickToOnEnter = (onClickCallback) => (e) =>
    isFunction(onClickCallback) && e.keyCode === keyCodeEnum.ENTER && onClickCallback();

export const useFocusOnUnmountCallback = (callback, unMountingRef) =>
    useCallback(() => {
        const prevFocusableElement = getPreviousFocusableElement(unMountingRef.current);
        callback(() => {
            const nextFocusableElement = getNextFocusableElement(prevFocusableElement);
            if (nextFocusableElement) {
                nextFocusableElement.focus();
            }
        });
    }, [callback, unMountingRef]);

export const assignToRefs = (refOrRefs) => (localRef) => {
    // We can either get a ref callback function, or a `createRef`,
    // so we need to handle both
    //
    // Ideally, we should only be handling refs in one way
    const forwardedRefs = isArray(refOrRefs) ? refOrRefs : [refOrRefs];
    forEach(forwardedRefs, (forwardedRef) => {
        if (forwardedRef && localRef) {
            if (isFunction(forwardedRef)) {
                forwardedRef(localRef);
            } else if (has(forwardedRef, 'current')) {
                forwardedRef.current = localRef;
            }
        }
    });
};

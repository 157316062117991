import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { renderOnlyIf } from '../../../../legacy-redux/helpers/reactHelpers';
import Header from '../../core/components/header/EntityProfileHeader';
import HeaderMasterName from '../../core/components/header/EntityProfileHeaderMasterName';
import HeaderSecondaryInfoSection from '../../core/components/header/EntityProfileHeaderSecondaryInfoSection';
import HeaderSecondaryInfo from '../../core/components/header/EntityProfileHeaderSecondaryInfo';
import ExternalDepartmentNameSecondaryHeaderInfo from '../../core/components/header/ExternalDepartmentSecondaryHeaderInfo';
import HeaderMenu from '../../core/components/header/EntityProfileHeaderMenu';
import VerifiedTag from '../../../core/components/tags/VerifiedTag';
import { OrganizationSidePanel } from '../../../core/organizations/components/OrganizationSidePanel';
import {
    organizationProfileHeaderDataSelector,
    currentEntityProfileIdSelector,
} from '../../core/state/ui';

function EntityProfileOrganizationHeader({
    orgHeaderData,
    ownerDepartmentName,
    entityId,
    isHidden,
}) {
    // We can only edit master profiles from this view
    // so let's just pass the ownerType as `ORGANIZATION_PROFILE`
    const nameLinkProps = {
        entityId,
        ownerId: entityId,
        ownerType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
    };

    return (
        <Header>
            {isHidden ? (
                // Need to still pass `editProfile` because existance of this prop
                // alters the view (and we want the view to be identical between
                // hidden / visible
                <HeaderMenu
                    editProfileSidePanel={(renderEditButton) => renderEditButton({})}
                    entityName={orgHeaderData.name}
                    isHidden={true}
                />
            ) : (
                <HeaderMenu
                    editProfileSidePanel={(renderEditButton) => (
                        <OrganizationSidePanel
                            overlayId={
                                overlayIdEnum.ORGANIZATION_OVERLAY_ENTITY_PROFILE_ORGANIZATION_HEADER
                            }
                            isEditingMasterProfile={true}
                            renderButton={({ overlayBase: { open }, ...rest }) =>
                                renderEditButton({ open, ...rest })
                            }
                            {...nameLinkProps}
                        />
                    )}
                    entityName={orgHeaderData.name}
                />
            )}
            <HeaderMasterName>{orgHeaderData.name}</HeaderMasterName>
            <HeaderSecondaryInfoSection>
                {orgHeaderData.address && (
                    <HeaderSecondaryInfo>{orgHeaderData.address}</HeaderSecondaryInfo>
                )}
                {orgHeaderData.isVerified && <VerifiedTag />}
                <ExternalDepartmentNameSecondaryHeaderInfo
                    ownerDepartmentName={ownerDepartmentName}
                />
            </HeaderSecondaryInfoSection>
        </Header>
    );
}

const mapStateToProps = createStructuredSelector({
    orgHeaderData: organizationProfileHeaderDataSelector,
    entityId: currentEntityProfileIdSelector,
});

export default compose(
    connect(mapStateToProps, null),
    renderOnlyIf(({ orgHeaderData }) => !!orgHeaderData)
)(EntityProfileOrganizationHeader);

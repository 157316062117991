import React from 'react';
import { useSelector } from 'react-redux';
import { filter, includes } from 'lodash';
import { ItemInvolvementTypeEnum, ItemInvolvementTypeEnumType } from '@mark43/rms-api';

import {
    DISPLAY_ONLY_OFFENSE_ITEM_INVOLVEMENT_TYPE_NONE_OPTION,
    DISPLAY_ONLY_OFFENSE_ITEM_INVOLVEMENT_TYPE_UNKNOWN_OPTION,
    DISPLAY_ONLY_OFFENSE_ITEM_INVOLVEMENT_TYPE_PROPERTY_INVOLVED_OPTION,
} from '~/client-common/core/enums/universal/fields';
import { convertPropertyLossNibrsCodesToItemInvolvementTypes } from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { propertyLossCodes } from '~/client-common/core/constants/nibrsCodes';
import { ValueOf } from '~/client-common/types';
import { arbiterMFTInput } from '../../../arbiter';
import Select, { SelectProps } from './Select';

/**
 * Select dropdown containing all Item Involvement Type options.
 * @param allowedPropertyLossNibrsCodes To filter the options, pass in an array
 *   of NIBRS codes of type PropertyLoss.
 */
function ItemInvolvementTypeSelect({
    allowedPropertyLossNibrsCodes,
    ...otherProps
}: {
    allowedPropertyLossNibrsCodes: ValueOf<typeof propertyLossCodes>[];
} & Omit<SelectProps<{ display: string; value: ItemInvolvementTypeEnumType }>, 'options'>) {
    const formatFieldByName = useSelector(formatFieldByNameSelector);

    const itemInvolvementOptions = [
        {
            display: formatFieldByName(DISPLAY_ONLY_OFFENSE_ITEM_INVOLVEMENT_TYPE_NONE_OPTION),
            value: ItemInvolvementTypeEnum.NONE.name,
        },
        {
            display: formatFieldByName(DISPLAY_ONLY_OFFENSE_ITEM_INVOLVEMENT_TYPE_UNKNOWN_OPTION),
            value: ItemInvolvementTypeEnum.UNKNOWN.name,
        },
        {
            display: formatFieldByName(
                DISPLAY_ONLY_OFFENSE_ITEM_INVOLVEMENT_TYPE_PROPERTY_INVOLVED_OPTION
            ),
            value: ItemInvolvementTypeEnum.PROPERTY_INVOLVED.name,
        },
    ];

    const allowedValues = convertPropertyLossNibrsCodesToItemInvolvementTypes(
        allowedPropertyLossNibrsCodes
    );

    const options =
        allowedValues.length > 0
            ? filter(itemInvolvementOptions, ({ value }) => includes(allowedValues, value))
            : itemInvolvementOptions;

    return <Select options={options} {...otherProps} />;
}

export const ArbiterMFTItemInvolvementTypeSelect = arbiterMFTInput(ItemInvolvementTypeSelect);

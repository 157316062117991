const initialUiState = {
    loadingList: false,
    listError: null,
};

export default function vehicleMakeModelAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.vehicleMakeModelAdmin;

import { EntityTypeEnumType, SubmissionMessageResource, SubmissionMessage } from '@mark43/rms-api';
import { createResource } from '../../../lib/resources/Resource';
import { req } from '../../../lib/ajax';

export default createResource({
    name: 'Submission Message Resource',
    methods: {
        // TODO: Fix the root cause in mark43-resources by creating a new type called `CreateSubmissionMessageT`
        // which only type the relevant properties that are needed to be sent as part of the request.
        sendMessage(submissionMessage: Partial<SubmissionMessage>) {
            return req<SubmissionMessageResource.SendMessage>({
                method: 'POST',
                url: 'submission_message/send',
                data: submissionMessage as SubmissionMessage,
            });
        },
        getSubmissinMessagesForEntity(entityId: number, entityType: EntityTypeEnumType) {
            return req<SubmissionMessageResource.GetAllSubmissionMessages>({
                method: 'GET',
                url: `submission_message/${entityType}/${entityId}/all`,
            });
        },
    },
});

import React from 'react';
import styled from 'styled-components';
import SavedSearchModal from '../../saved-search/components/SavedSearchModal';

const Wrapper = styled.div`
    .saved-search-button-wrapper {
        margin-top: 15px;
    }
`;

/**
 * Component to display in a search form filters section.  Currently, this component only contains a single `SAVED SEARCHES` button.
 * Clicking on this button will change the `tabView` property of the ui state, of the search tab in which this component lives in.
 * The purpose of this component is currently limited to just providing the user with a way to navigate to the `tabView.SAVED_SEARCH` view,
 * so that a user can view their saved searches for a given elastic search type.
 * @param {Object}    actionCreators              The action creators for a tab's search module.
 * @param {function}  onSavedSearchesButtonClick  Function to handle the `SAVED SEARCHES` button click.
 */
const AdvancedSearchOpenSavedSearchesBar = function ({ searchModule }) {
    return (
        <Wrapper className="advanced-search-open-saved-searches-bar">
            <SavedSearchModal searchModule={searchModule} />
        </Wrapper>
    );
};

export default AdvancedSearchOpenSavedSearchesBar;

import { WarrantAttribute, AttributeTypeEnumType } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import getWarrantsResource from '../../../warrants/resources/warrantsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const warrantAttributesModule = createLinkModule<WarrantAttribute>({
    type: 'warrantAttributes',
    keys: ['attributeId', 'warrantId'],
});

// ACTIONS

const replaceWarrantAttributesWhere = warrantAttributesModule.actionCreators.replaceLinksWhere;

// SELECTORS
export const warrantAttributesSelector = warrantAttributesModule.selectors.linksSelector;
export const warrantAttributesWhereSelector = warrantAttributesModule.selectors.linksWhereSelector;

// REDUCER
export default warrantAttributesModule.reducerConfig;

// RESOURCE ACTIONS
const UPDATE_WARRANT_ATTRIBUTE_START = 'warrant-attributes/UPDATE_WARRANT_ATTRIBUTE_START';
const UPDATE_WARRANT_ATTRIBUTE_SUCCESS = 'warrant-attributes/UPDATE_WARRANT_ATTRIBUTE_SUCCESS';
const UPDATE_WARRANT_ATTRIBUTE_FAILURE = 'warrant-attributes/UPDATE_WARRANT_ATTRIBUTE_FAILURE';

function updateWarrantAttributeStart() {
    return {
        type: UPDATE_WARRANT_ATTRIBUTE_START,
    };
}
function updateWarrantAttributeSuccess(warrantAttributeLocation: WarrantAttribute[]) {
    return {
        type: UPDATE_WARRANT_ATTRIBUTE_SUCCESS,
        payload: warrantAttributeLocation,
    };
}
function updateWarrantAttributeFailure(errorMessage: string) {
    return {
        type: UPDATE_WARRANT_ATTRIBUTE_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Replace attributes related to a warrant
 */
export function updateWarrantAttributes(
    warrantId: number,
    warrantAttributes: WarrantAttribute[],
    attributeType: AttributeTypeEnumType
): ClientCommonAction<Promise<WarrantAttribute[]>> {
    const warrantsResource = getWarrantsResource();

    return function (dispatch) {
        dispatch(updateWarrantAttributeStart());

        return warrantsResource
            .replaceWarrantAttributesForWarrantId(warrantId, warrantAttributes, attributeType)
            .then((warrantAttributes: WarrantAttribute[]) => {
                // when putting the received warrantAttributes in to state, we need to know
                // whether or not our original call was constrained by a specific attribute type
                // this will inform whether we replace all attributes for a given warrantId
                // or only those of a given type
                if (attributeType) {
                    dispatch(
                        replaceWarrantAttributesWhere(
                            { warrantId, attributeType },
                            warrantAttributes
                        )
                    );
                } else {
                    dispatch(replaceWarrantAttributesWhere({ warrantId }, warrantAttributes));
                }
                dispatch(updateWarrantAttributeSuccess(warrantAttributes));
                return warrantAttributes;
            })
            .catch((err: Error) => {
                dispatch(updateWarrantAttributeFailure(err.message));
                throw err;
            });
    };
}

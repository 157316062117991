import { isEmpty } from 'lodash';
import * as React from 'react';

type DragonConfiguredPropertyAliasContextValue = Record<string, string | number | undefined>;

const DragonConfiguredPropertyAliasContext = React.createContext<DragonConfiguredPropertyAliasContextValue>(
    {}
);

export function DragonConfiguredPropertyAliasContextProvider({
    value,
    children,
}: React.PropsWithChildren<{ value: DragonConfiguredPropertyAliasContextValue }>): JSX.Element {
    const currentContextValue = useDragonConfiguredPropertyAliasContext();
    const nextContextValue = isEmpty(value)
        ? currentContextValue
        : { ...currentContextValue, ...value };

    return (
        <DragonConfiguredPropertyAliasContext.Provider value={nextContextValue}>
            {children}
        </DragonConfiguredPropertyAliasContext.Provider>
    );
}

export function useDragonConfiguredPropertyAliasContext(): DragonConfiguredPropertyAliasContextValue {
    return React.useContext(DragonConfiguredPropertyAliasContext);
}

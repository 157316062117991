import React from 'react';
import { connect } from 'react-redux';
import { withHandlers } from 'recompose';
import styled from 'styled-components';

import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';

import { openImageGalleryLightbox } from '../../../../legacy-redux/actions/imageGalleryLightboxActions';
import testIds from '../../../../core/testIds';

const ThumbnailWrapper = styled.div`
    cursor: pointer;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 0 10px 10px 0;
    position: relative;
    overflow: hidden;
    border: 1px solid ${(props) => props.theme.colors.extraLightGrey};

    img {
        position: absolute;
        height: 100%;
        width: auto;
    }
`;

/**
 * Shows thumbnails that open the lightbox when you click on them.
 * @param {Object}      image   should be in shape needed for the image gallery
 * @param {Object[]}    images  each element should be in shape needed for the image gallery
 * @param {Number}      index   index of image inside of images
 */
const Thumbnail = withHandlers({
    onClick: ({ openImageGalleryLightbox, images, index }) => () =>
        openImageGalleryLightbox(images, index),
})(function Thumbnail({ image, onClick }) {
    return (
        <ThumbnailWrapper onClick={onClick} data-test-id={testIds.THUMBNAIL_WRAPPER}>
            <ImgWithExifOrientation src={image.thumbnail} alt={image.altText} />
        </ThumbnailWrapper>
    );
});

const mapDispatchToProps = (dispatch) => ({
    openImageGalleryLightbox: (images, index) => dispatch(openImageGalleryLightbox(images, index)),
});

export default connect(null, mapDispatchToProps)(Thumbnail);

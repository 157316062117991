import React from 'react';
import { Form, formEvents, lifecycleOptions } from 'markformythree';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../core/testIds';
import { MFTUserSelect } from '../../forms/components/selects/UserSelect';
import { MFTText } from '../../forms/components/Text';

import {
    sendEmailFormConfiguration,
    validationHandler,
    SendEmailFormConfiguration,
    SendEmailFormData,
} from '../state/forms/sendEmailForm';
import { MFTTextArea } from '../../forms/components/TextArea';
import { MFTExternalEmailsSelect } from '../../forms/components/selects/ExternalEmailsSelect';
import { MAX_EMAIL_BODY } from '../configuration';

const labels = componentStrings.exports.emails.fieldLabels;

interface SendEmailFormProps {
    prefillState?: SendEmailFormData;
}

const SendEmailForm: React.FC<SendEmailFormProps> = ({ prefillState }) => {
    return (
        <Form<SendEmailFormConfiguration>
            configuration={sendEmailFormConfiguration}
            name={formClientEnum.SEND_EMAIL_FORM}
            initialState={prefillState}
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            validationEvents={[{ eventType: formEvents.FORM_SUBMIT }]}
            onValidate={validationHandler}
            render={() => {
                return (
                    <>
                        <MFTExternalEmailsSelect
                            path="externalRecipients"
                            label={labels.externalRecipients}
                            testId={testIds.EMAIL_EXTERNAL_RECIPIENTS_SELECT}
                        />
                        <MFTUserSelect
                            path="internalUsers"
                            label={labels.internalUsers}
                            multiple
                            testId={testIds.EMAIL_INTERNAL_USERS_SELECT}
                        />
                        <MFTText
                            path="subject"
                            label={labels.subject}
                            maxLength={256}
                            testId={testIds.EMAIL_SUBJECT_INPUT}
                        />
                        <MFTTextArea
                            path="message"
                            label={labels.message}
                            rows={5}
                            maxLength={MAX_EMAIL_BODY}
                            resizable
                            width="100%"
                            testId={testIds.EMAIL_MESSAGE_INPUT}
                        />
                    </>
                );
            }}
        />
    );
};

export default SendEmailForm;

import { filter } from 'lodash';

import { Printable, PrintingDataTypeEnum } from '@mark43/rms-api';

import isAttachmentFolderPrintable from './isAttachmentFolderPrintable';
import isPrintableOfPrintingDataType from './isPrintableOfPrintingDataType';

/**
 * Split the given Printables into two arrays. The first array has the Printables which represent folders of
 * attachments. The second array has all remaining Printables.
 */
export default function partitionFolderPrintablesContainingAttachmentsAndNonAttachmentFolderPrintables(
    printables: Printable[]
): [Printable[], Printable[]] {
    // check both attachment and note folders
    const folderPrintablesContainingAttachments = filter(
        printables,
        (printable) =>
            isPrintableOfPrintingDataType(printable, PrintingDataTypeEnum.PARENT_FOLDER.name) &&
            printable.attachmentCount > 0
    );

    // filtering for non-attachment folder printables, so this array will contain note folders if present
    // so they can be displayed on the advanced export options page
    // (unlike attachment folders which only appear in attachments side panel)
    const nonAttachmentFolderPrintables = filter(
        printables,
        (printable) => !isAttachmentFolderPrintable(printable)
    );

    return [folderPrintablesContainingAttachments, nonAttachmentFolderPrintables];
}

import { size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import {
    recentHydratedItemProfileIdsLoadingStatusSelector,
    recentHydratedItemProfileIdsSelector,
} from '~/client-common/core/domain/item-profiles/state/ui';
import Button from '../../../../../legacy-redux/components/core/Button';
import { SimpleLoading as _SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { currentReportRENSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import Icon, { iconTypes, iconSizes } from '../../../../core/components/Icon';
import { openRecentItemsSidePanel } from '../../state/ui';
import testIds from '../../../../../core/testIds';

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px 0;
`;

const Column1 = styled.div`
    color: ${(props) => props.theme.colors.darkGrey};
    flex: 1 1 auto;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    letter-spacing: 0;
    margin: 5px 0;
    text-align: left;
    text-transform: none;
`;

const Column2 = styled.div`
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    margin-left: 10px;
`;

const strings = componentStrings.reports.core.RecentItemsButton;

const SimpleLoading = styled(_SimpleLoading)`
    div {
        margin-top: 15px;
    }
`;

const mapStateToProps = createStructuredSelector({
    isLoading: recentHydratedItemProfileIdsLoadingStatusSelector,
    recentItemProfileIds: recentHydratedItemProfileIdsSelector,
    reportingEventNumber: currentReportRENSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openRecentItemsSidePanel: ({
        isVehicle,
        limitToOne,
        recentItemProfileIds,
        ownerId,
        ownerType,
    }) =>
        dispatch(
            openRecentItemsSidePanel({
                isVehicle,
                itemProfileIds: recentItemProfileIds,
                limitToOne,
                ownerId,
                ownerType,
            })
        ),
});

const _RecentItems = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFields([REPORT_REPORTING_EVENT_NUMBER])
)(
    ({
        className,
        fieldDisplayNames: { REPORT_REPORTING_EVENT_NUMBER },
        isLoading,
        isVehicle,
        limitToOne,
        openRecentItemsSidePanel,
        ownerId,
        ownerType,
        recentItemProfileIds,
        reportingEventNumber,
    }) => {
        const numberOfItems = size(recentItemProfileIds);

        if (isLoading) {
            return (
                <Container>
                    <SimpleLoading />
                </Container>
            );
        } else if (numberOfItems) {
            const label = isVehicle ? (
                <div>
                    {strings.selectVehicle({
                        renDisplayName: REPORT_REPORTING_EVENT_NUMBER,
                        reportingEventNumber,
                    })}
                    <br />
                    {strings.selectNumberOfItems(numberOfItems)}
                </div>
            ) : (
                <div>
                    {strings.selectProperty({
                        renDisplayName: REPORT_REPORTING_EVENT_NUMBER,
                        reportingEventNumber,
                    })}
                    <br />
                    {strings.selectNumberOfItems(numberOfItems)}
                </div>
            );

            return (
                <Button
                    className={className}
                    onClick={() =>
                        openRecentItemsSidePanel({
                            isVehicle,
                            limitToOne,
                            recentItemProfileIds,
                            ownerId,
                            ownerType,
                        })
                    }
                    testId={testIds.RECENT_ITEMS_BUTTON}
                >
                    <Container>
                        <Column1>{label}</Column1>
                        <Column2>
                            <Icon size={iconSizes.MEDIUM} type={iconTypes.OPEN_RIGHT} />
                        </Column2>
                    </Container>
                </Button>
            );
        }

        return null;
    }
);

const RecentItemsButton = styled(_RecentItems)`
    margin: 12px 0;
    padding: 7px 15px;
`;

export default RecentItemsButton;

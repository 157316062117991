import { compact, forEach, noop, map, findIndex, filter, uniqBy, get } from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import { AttributeTypeEnum, EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';

import {
    formatReportTitleForReportIdSelector,
    hydratedOffenseByIdSelector,
} from '~/client-common/core/domain/reports/state/ui';
import { warrantViewModelByIdSelector } from '~/client-common/core/domain/warrants/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { nameAttributesSelector } from '~/client-common/core/domain/name-attributes/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import {
    NEXUS_STATE_PROP as OFFENSES_NEXUS_STATE_PROP,
    deleteOffense,
    bulkUpsertOffenses,
    offensesByReportIdSelector,
    offensesSelector,
} from '~/client-common/core/domain/offenses/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import {
    filterForOffenses,
    filterForIncidents,
    filterForStubOffenseIncidents,
    sortOffenses,
    sortIncidents,
    sortOffenseIncidents,
    offensesWithoutClientSideStubs,
    computeOffensesToReorder,
    buildOffenseCardTitle,
    buildIncidentCardTitle,
    buildStubOffenseIncidentCardAnchor,
} from '~/client-common/core/domain/offenses/utils/offensesHelpers';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { arrestByIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import { reportShortTitleByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { attributesByTypeSelector } from '~/client-common/core/domain/attributes/state/data';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { reportCardTitleByReportIdAndCardIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { currentDepartmentDateFormatsSelector } from '~/client-common/core/domain/current-user/state/ui';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import { convertToFormModel } from '../forms/offenseForm';
import {
    saveBoxStart,
    saveBoxSuccess,
    saveBoxFailure,
    openBox,
} from '../../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../../core/box/state/ui';
import { scrollToDataAnchor } from '../../../../../legacy-redux/helpers/navigationHelpers';
import { currentUserDepartmentProfileSelector } from '../../../../core/current-user/state/ui';
import { offenseURNEnabledSelector } from './offenseURN';
import { itemSummaryViewModelsForCurrentReportSelector } from './itemProfiles';

export const offenseIncidentDeletionModalContext = {
    name: boxEnum.OFFENSE_INCIDENT_DELETION_MODAL,
};
const offenseIncidentDeletionModalStrings =
    componentStrings.reports.core.OffenseIncidentDeletionModal;

const getSortedOffensesForReportWithoutClientSideStubs = (state, { reportId, isIncident }) => {
    const filterOffensesForType = isIncident ? filterForIncidents : filterForOffenses;

    return sortOffenses(
        offensesWithoutClientSideStubs(
            filterOffensesForType(
                offensesByReportIdSelector(state)(reportId),
                offenseCodesSelector(state)
            )
        )
    );
};

export const refreshOffenseForm = ({ offenseId }) => (dispatch, getState) => {
    const state = getState();
    const {
        offense,
        offenseAttributes,
        offenseCode,
        offenseInvolvedChildren,
        offenseSubCrimeLinks,
    } = hydratedOffenseByIdSelector(state)(offenseId);
    const nameReportLinks = nameReportLinksWhereSelector(state)({
        contextId: offenseId,
        contextType: EntityTypeEnum.OFFENSE.name,
    });
    const locationLinks = locationEntityLinksWhereSelector(state)({
        entityId: offenseId,
        entityType: EntityTypeEnum.OFFENSE.name,
    });
    const report = reportByIdSelector(state)(offense.reportId);
    const nameAttributes = nameAttributesSelector(state);
    const statuteCodeSetAttributes = attributesByTypeSelector(state)(
        AttributeTypeEnum.OFFENSE_CODE_STATUTE_CODE_SET.name
    );
    const departmentSubDomain = currentUserDepartmentProfileSelector(state).department.subDomain;
    const fieldConfigurationContextByContextAndFieldName = fieldConfigurationContextByContextAndFieldNameSelector(
        state
    );
    const propertyStatuses = [
        ...itemSummaryViewModelsForCurrentReportSelector(state)(true, undefined, report.id),
        ...itemSummaryViewModelsForCurrentReportSelector(state)(false, undefined, report.id),
    ].map(items => items.mergedPropertyStatus);

    return convertToFormModel({
        offense,
        offenseAttributes,
        offenseCode,
        offenseSubCrimeLinks,
        nameReportLinks,
        nameAttributes,
        locationLinks,
        report,
        offenseInvolvedChildren,
        statuteCodeSetAttributes,
        departmentSubDomain,
        fieldConfigurationContextByContextAndFieldName,
        propertyStatuses,
    });
};

export function openOffenseIncidentDeletionModal({ entityId, isIncident }) {
    return (dispatch) => {
        dispatch(
            openBox(offenseIncidentDeletionModalContext, { entityData: { entityId, isIncident } })
        );
    };
}

export function submitOffenseIncidentDeletionModal() {
    return function (dispatch, getState) {
        const state = getState();
        const offenseDisplayName = formatFieldByNameSelector(state)(DISPLAY_ONLY_OFFENSE);
        const { isIncident, entityId } = offenseIncidentDeletionModalEntityDataSelector(state);
        const { reportId } = offensesSelector(state)[entityId];

        dispatch(saveBoxStart(offenseIncidentDeletionModalContext));
        const offensesForReport = getSortedOffensesForReportWithoutClientSideStubs(state, {
            reportId,
            isIncident,
        });

        // fake reorder the offense we want to delete to the end of the list
        // to compute all changing offense orders
        const offenseIndex = findIndex(offensesForReport, (o) => o.id === entityId);
        const offensesToUpdate = computeOffensesToReorder({
            offenses: offensesForReport,
            fromIndex: offenseIndex,
            toIndex: offensesForReport.length,
        });
        offensesToUpdate.splice(offensesToUpdate.length - 1, 1);

        return dispatch(actuallyDeleteOffense({ offenseId: entityId, reportId, isIncident }))
            .then(() => dispatch(saveBoxSuccess(offenseIncidentDeletionModalContext)))
            .catch((err) => {
                dispatch(
                    saveBoxFailure(
                        offenseIncidentDeletionModalContext,
                        offenseIncidentDeletionModalStrings.errorMessage(
                            isIncident,
                            offenseDisplayName
                        )
                    )
                );
                throw err;
            });
    };
}

const offenseCodeChangeReasonModalContext = { name: boxEnum.OFFENSE_CODE_CHANGE_REASON_MODAL };

export function openOffenseCodeChangeReasonModal({ callback, cancel }) {
    return (dispatch) => {
        dispatch(openBox(offenseCodeChangeReasonModalContext, { callback, cancel }));
    };
}

const applyOffenseReorderToFormState = (updatedOffenses, isIncident, formsRegistry) => {
    // after updating the offenses we have to
    // update local form state as well
    forEach(updatedOffenses, (offense) => {
        const prefix = isIncident ? 'incident' : 'offense';
        const form = formsRegistry.get(
            isIncident ? RefContextEnum.FORM_INCIDENT.name : RefContextEnum.FORM_OFFENSE.name,
            offense.id
        );
        if (form) {
            form.set(`${prefix}.offenseOrder`, offense.offenseOrder);
        }
    });
};

export function actuallyDeleteOffense({
    offenseId,
    reportId,
    isIncident,
    isOffenseTypeChange = false,
}) {
    return function (dispatch, getState, dependencies) {
        const state = getState();
        const offensesForReport = getSortedOffensesForReportWithoutClientSideStubs(state, {
            reportId,
            isIncident,
        });

        // fake reorder the offense we want to delete to the end of the list
        // to compute all changing offense orders
        const offenseIndex = findIndex(offensesForReport, (o) => o.id === offenseId);
        const offensesToUpdate = computeOffensesToReorder({
            offenses: offensesForReport,
            fromIndex: offenseIndex,
            toIndex: offensesForReport.length,
        });
        offensesToUpdate.splice(offensesToUpdate.length - 1, 1);

        return (
            dispatch(deleteOffense(offenseId, isOffenseTypeChange))
                // it is not a problem if actual reordering fails because users can still
                // manually fix it via the UI.
                .then(() =>
                    offensesToUpdate.length
                        ? dispatch(bulkUpsertOffenses(offensesToUpdate))
                              .then(
                                  (updatedOffenses) => {
                                      applyOffenseReorderToFormState(
                                          updatedOffenses,
                                          isIncident,
                                          dependencies.formsRegistry
                                      );
                                  }
                                  // noop here for now, not yet sure what should happen here
                              )
                              .catch(noop)
                        : undefined
                )
        );
    };
}

export const offenseIncidentDeletionModalEntityDataSelector = createModalSelector(
    offenseIncidentDeletionModalContext,
    'entityData'
);

let GLOBAL_CLIENT_SIDE_OFFENSE_STUB_ID_VALUE = 0;

// Injecting the card module because of circular dependencies, due to
// somewhat blurred lines between what goes into different modules (offense.js vs offenseCards.js)
export function addNewClientSideOffense({
    offenseId,
    reportId,
    cardModule,
    isIncident,
    offenseCodeId,
}) {
    return (dispatch, getState, dependencies) => {
        if (!offenseId) {
            GLOBAL_CLIENT_SIDE_OFFENSE_STUB_ID_VALUE--;
        }

        const state = getState();
        const clientSideOffenseId = !!offenseId
            ? offenseId
            : GLOBAL_CLIENT_SIDE_OFFENSE_STUB_ID_VALUE;
        const filterOffensesForType = isIncident ? filterForIncidents : filterForOffenses;
        const offensesForReport = filterOffensesForType(
            offensesByReportIdSelector(state)(reportId),
            offenseCodesSelector(state)
        );
        const maxOrderId =
            (offensesForReport.length && Math.max(...map(offensesForReport, 'offenseOrder'))) || 0;
        dispatch(
            dependencies.nexus.withEntityItems(
                {
                    [OFFENSES_NEXUS_STATE_PROP]: [
                        {
                            id: clientSideOffenseId,
                            reportId,
                            offenseOrder: isIncident ? null : maxOrderId + 1,
                            offenseCodeId,
                            // client-side only prop to determine whether an offense is an incident before
                            // it has been saved with an incident code.
                            isIncident,
                        },
                    ],
                },
                cardModule.actionCreators.createNewCard({ index: clientSideOffenseId })
            )
        );

        cardModule.scrollToTop({
            index: clientSideOffenseId,
        });
    };
}

export function addNewClientSideOffenseIncident({ reportId, scrollToTop }) {
    return (dispatch, getState, dependencies) => {
        GLOBAL_CLIENT_SIDE_OFFENSE_STUB_ID_VALUE--;
        const clientSideOffenseId = GLOBAL_CLIENT_SIDE_OFFENSE_STUB_ID_VALUE;
        dispatch(
            dependencies.nexus.withEntityItems(
                {
                    [OFFENSES_NEXUS_STATE_PROP]: [
                        {
                            id: clientSideOffenseId,
                            reportId,
                            isOffenseIncident: true,
                        },
                    ],
                },
                { type: 'offenseIncidents/ADD_STUB_OFFENSE_INCIDENT' }
            )
        );

        if (scrollToTop) {
            scrollToDataAnchor(buildStubOffenseIncidentCardAnchor({ index: clientSideOffenseId }));
        }
    };
}

export function reorderOffensesForReportId({
    fromIndex,
    toIndex,
    reportId,
    offenseId,
    cardModule,
    isIncident,
}) {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const offensesForReport = getSortedOffensesForReportWithoutClientSideStubs(state, {
            reportId,
            isIncident,
        });
        const offensesToUpdate = computeOffensesToReorder({
            offenses: offensesForReport,
            fromIndex,
            toIndex,
        });

        return dispatch(bulkUpsertOffenses(offensesToUpdate))
            .then(
                (updatedOffenses) => {
                    applyOffenseReorderToFormState(
                        updatedOffenses,
                        isIncident,
                        dependencies.formsRegistry
                    );
                    cardModule.scrollToTop({
                        index: offenseId,
                    });
                }
                // noop here for now, not yet sure what should happen here
            )
            .catch(noop);
    };
}

export const sortedOffensesAndIncidentsForReportIdSelector = createSelector(
    offensesByReportIdSelector,
    offenseCodesSelector,
    (offensesByReportId, offenseCodes) => (reportId) => {
        const offenses = offensesByReportId(reportId);
        return {
            offenses: sortOffenses(filterForOffenses(offenses, offenseCodes)),
            incidents: sortIncidents(filterForIncidents(offenses, offenseCodes)),
        };
    }
);

export const sortedStubOffenseIncidentsForReportIdSelector = createSelector(
    offensesByReportIdSelector,
    (offensesByReportId) => (reportId) => {
        const offenses = offensesByReportId(reportId);
        return sortOffenseIncidents(filterForStubOffenseIncidents(offenses));
    }
);

export const offenseLinkedArrestSelector = createSelector(
    chargesWhereSelector,
    arrestByIdSelector,
    reportShortTitleByReportIdSelector,
    formatReportTitleForReportIdSelector,
    applicationSettingsSelector,
    currentDepartmentDateFormatsSelector,
    (
        chargesWhere,
        arrestById,
        reportShortTitleByReportId,
        formatReportTitleForReportId,
        applicationSettings,
        dateTimeFormats
    ) => (offenseId) => {
        const charges = chargesWhere({ offenseId });
        const arrests = uniqBy(
            compact(
                map(charges, (charge) => arrestById(charge.arrestId)),
                'id'
            )
        );
        return map(arrests, (arrest) => {
            const reportShortTitle = reportShortTitleByReportId(arrest.reportId);
            const title = applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED
                ? reportShortTitle.shortTitle
                : formatReportTitleForReportId(arrest.reportId);
            return {
                id: arrest.reportId,
                title: `${title} - ${moment(arrest.arrestDateUtc).format(
                    dateTimeFormats.summaryDate
                )}`,
            };
        });
    }
);

export const offenseLinkedWarrantSelector = createSelector(
    chargesWhereSelector,
    warrantViewModelByIdSelector,
    (chargesWhere, warrantViewModelById) => (offenseId) => {
        const charges = chargesWhere({ offenseId });
        const chargesWithWarrant = uniqBy(
            filter(charges, (charge) => !!charge.warrantId),
            'warrantId'
        );
        return map(chargesWithWarrant, (charge) => {
            const { warrantShortTitle } = getViewModelProperties(
                warrantViewModelById(charge.warrantId)
            );
            return {
                title: warrantShortTitle,
                id: charge.warrantId,
            };
        });
    }
);

export const sortedOffenseIncidentTitlesForReportIdSelector = createSelector(
    sortedOffensesAndIncidentsForReportIdSelector,
    sortedStubOffenseIncidentsForReportIdSelector,
    offenseCodesSelector,
    formatFieldByNameSelector,
    formatOffenseCodeByIdSelector,
    reportCardTitleByReportIdAndCardIdSelector,
    offenseURNEnabledSelector,
    (
        sortedOffensesAndIncidentsForReportId,
        sortedStubOffenseIncidentsForReportId,
        offenseCodes,
        formatFieldByName,
        formatOffenseCodeById,
        reportCardTitleByReportIdAndCardId,
        offenseURNEnabled
    ) => (reportId) => {
        const {
            offenses: sortedOffenses,
            incidents: sortedIncidents,
        } = sortedOffensesAndIncidentsForReportId(reportId);
        const sortedStubOffenseIncidents = sortedStubOffenseIncidentsForReportId(reportId);
        const sortedOffenseIncidents = [
            ...sortedOffenses,
            ...sortedIncidents,
            ...sortedStubOffenseIncidents,
        ];

        return map(sortedOffenseIncidents, (offenseIncident) => {
            // In this case `offensePanel` points not an offense panel but an offense.
            const isIncident =
                // saved offense/incident
                get(
                    offenseCodes[offenseIncident.offenseCodeId],
                    'isIncidentType',
                    false
                    // client-side offense/incident stub
                ) || offenseIncident.isIncident;
            const anchorprefix = offenseIncident.isOffenseIncident
                ? 'stub-offense-incident'
                : isIncident
                ? 'incident'
                : 'offense';

            const cardEnum = offenseIncident.isOffenseIncident
                ? null
                : isIncident
                ? reportCardEnum.INCIDENT
                : reportCardEnum.OFFENSE;

            const cardId = cardEnum?.id;

            const titlePrefix = cardId
                ? reportCardTitleByReportIdAndCardId(reportId, cardId)
                : undefined;

            const offenseDisplayName = formatFieldByName(DISPLAY_ONLY_OFFENSE);

            return {
                display: offenseIncident.isOffenseIncident
                    ? componentStrings.reports.core.StubOffenseIncidentCard.title(
                          offenseDisplayName
                      )
                    : isIncident
                    ? buildIncidentCardTitle({
                          formattedOffenseCode: formatOffenseCodeById({
                              id: offenseIncident.offenseCodeId,
                              includeCode: false,
                          }),
                          titlePrefix,
                      })
                    : buildOffenseCardTitle({
                          order: offenseIncident.offenseOrder,
                          formattedOffenseCode:
                              (offenseURNEnabled ? offenseIncident.offenseURN : undefined) ||
                              formatOffenseCodeById({
                                  id: offenseIncident.offenseCodeId,
                                  includeCode: false,
                              }),
                          titlePrefix: titlePrefix || offenseDisplayName,
                      }),
                // The anchor is not a mistake - the way we index multiple forms
                // in React demands that we append the id at the end.
                anchor: `${anchorprefix}-card-${offenseIncident.id}`,
                cardName: cardEnum?.name,
                id: offenseIncident.id,
            };
        });
    }
);

import { RefContextEnum } from '@mark43/rms-api';

import { get } from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { sendArrestReportToBooking } from '~/client-common/core/domain/arrests/state/data';
import { reportDefinitionHasCardSelector } from '~/client-common/core/domain/report-definitions/state/data';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    openBox,
    saveBoxSuccess,
    saveBoxFailure,
    closeBox,
} from '../../../../../legacy-redux/actions/boxActions';
import { setReport } from '../../../../../legacy-redux/actions/reportsActions';
import { createModalSelector } from '../../../../core/box/state/ui';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

const reportChangeOwnerModalContext = { name: boxEnum.REPORT_CHANGE_OWNER_MODAL };
const reportSendArrestToBookingContext = { name: boxEnum.REPORT_SEND_ARREST_TO_BOOKING_MODAL };
const reportSendArrestToBookingStrings =
    componentStrings.reports.core.ReportSendArrestToBookingModal;

export function openReportChangeOwnerModal({ callback }) {
    return (dispatch) => {
        dispatch(openBox(reportChangeOwnerModalContext, { callback }));
    };
}

export function openSendArrestToBookingModal(reportId) {
    return (dispatch) => {
        dispatch(openBox(reportSendArrestToBookingContext, { reportId }));
    };
}

export function sendReportToBooking() {
    return (dispatch, getState) => {
        const state = getState();
        const reportId = createModalSelector(reportSendArrestToBookingContext, 'reportId')(state);
        return dispatch(sendArrestReportToBooking(reportId))
            .then(() => {
                dispatch(saveBoxSuccess(reportSendArrestToBookingContext));
                dispatch(closeBox(reportSendArrestToBookingContext)); // clears out payload
            })
            .catch(() => {
                dispatch(
                    saveBoxFailure(
                        reportSendArrestToBookingContext,
                        reportSendArrestToBookingStrings.errorMessage
                    )
                );
            });
    };
}

export function submitRemoveRenFromReport(reportId) {
    return function (dispatch) {
        return getReportResource()
            .updateRen(reportId, null)
            .then((newReport) => {
                return dispatch(setReport(newReport));
            });
    };
}

/**
 * Returns event card start date or supplemental card date on current report.
 * Using redux thunk action instead of selector because we want to get the current data from
 * the formsRegistry.
 * @return {string}
 */
export function getDateFromReportCards() {
    return (dispatch, getState) => {
        const state = getState();
        const reportDefinitionId = currentReportSelector(state).reportDefinitionId;
        const reportDefinitionHasCard = reportDefinitionHasCardSelector(state);

        if (reportDefinitionHasCard(reportDefinitionId, reportCardEnum.EVENT_INFO.id)) {
            const eventInfoForm = formsRegistry.get(RefContextEnum.FORM_EVENT_INFO.name);
            if (eventInfoForm) {
                return eventInfoForm.get('eventStartUtc');
            }
        } else if (reportDefinitionHasCard(reportDefinitionId, reportCardEnum.SUPPLEMENT_INFO.id)) {
            const supplementInfoForm = formsRegistry.get(RefContextEnum.FORM_SUPPLEMENT_INFO.name);
            if (supplementInfoForm) {
                return supplementInfoForm.get('eventStartUtc');
            }
        }
    };
}

/**
 * Registers a form with the global FormsRegistry, overwriting an existing
 * entry if a collision is found.
 * @param {Form}        An MFT `Form` object, that must minimally have a `name`.
 * @param {[number]}    An optional `index`, unique identifier for the `Form`.
 *                      Needed for forms that may exist N times on a page.
 */
export const registerForm = (params = {}) => {
    const { form, index } = params;
    const name = get(form, 'name');

    const existingForm = formsRegistry.get(name, index);
    if (existingForm) {
        formsRegistry.unregister(existingForm, index);
    }
    formsRegistry.register(form, index);
};

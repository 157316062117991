import { FormEnum } from '@mark43/rms-api';
import { map, get, omit, mapValues, pick, sortBy } from 'lodash';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import * as fields from '~/client-common/core/enums/universal/fields';

import {
    parsePeriod,
    convertDaysToDayPeriod,
} from '~/client-common/core/dates/utils/dateRangeHelpers';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import {
    buildFlatFormFieldViewModels,
    buildFormModel,
    filterFormData,
} from '../../../../../legacy-redux/helpers/formHelpers';
import {
    requiredInt,
    mustBeIntAndGreaterThanZero,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const { N_ITEMS_FIELDSET, FIELDSET } = fieldTypeClientEnum;

const labelLayoutElementsFieldsetViewModel = {
    type: N_ITEMS_FIELDSET,
    key: 'labelLayoutElements',
    fields: buildFlatFormFieldViewModels([
        'labelLayoutType',
        'fieldName',
        'isDisplayed',
        'attributeType',
        'parentAttributeId',
    ]),
};

const chainEventTypesFieldsetViewModel = {
    type: N_ITEMS_FIELDSET,
    key: 'chainEventTypes',
    fields: buildFlatFormFieldViewModels([
        'id',
        'name',
        'requiresSignature',
        'requiresIDScan',
        {
            key: 'overduePeriodInDays',
            fieldName: fields.CHAIN_EVENT_TYPE_OVERDUE_PERIOD_IN_DAYS,
            validators: {
                mustBeIntAndGreaterThanZeroError: mustBeIntAndGreaterThanZero,
            },
        },
    ]),
};

const itemTypesFieldsetViewModel = {
    type: FIELDSET,
    key: 'itemTypes',
    fields: buildFlatFormFieldViewModels([
        {
            key: 'vehiclesEnabled',
            fieldName: fields.EVIDENCE_DEPARTMENT_CONFIG_VEHICLES_ENABLED,
        },
    ]),
};

const dispositionPeriodsFieldsetViewModel = {
    type: FIELDSET,
    key: 'dispositionPeriods',
    fields: buildFlatFormFieldViewModels([
        {
            key: 'finalReleaseWaitPeriod',
            fieldName: fields.EVIDENCE_DEPARTMENT_CONFIG_FINAL_RELEASE_WAIT_PERIOD,
        },
    ]),
};

const dispositionApprovalsFieldsetViewModel = {
    type: FIELDSET,
    key: 'dispositionApprovals',
    fields: buildFlatFormFieldViewModels([
        {
            key: 'primaryDispositionApprovalType',
            fieldName: fields.EVIDENCE_DEPARTMENT_CONFIG_PRIMARY_DISPOSITION_APPROVAL_TYPE,
        },
        {
            key: 'primaryDispositionApprovalRoleIds',
            fieldName: fields.DISPOSITION_APPROVAL_ROLE_ROLE_ID,
        },
        {
            key: 'alternatePrimaryDispositionApprovalType',
            fieldName:
                fields.EVIDENCE_DEPARTMENT_CONFIG_ALTERNATE_PRIMARY_DISPOSITION_APPROVAL_TYPE,
        },
        {
            key: 'alternatePrimaryDispositionApprovalRoleIds',
            fieldName: fields.DISPOSITION_APPROVAL_ROLE_ROLE_ID,
        },
        {
            key: 'secondaryDispositionApprovalType',
            fieldName: fields.EVIDENCE_DEPARTMENT_CONFIG_SECONDARY_DISPOSITION_APPROVAL_TYPE,
        },
        {
            key: 'secondaryDispositionApprovalRoleIds',
            fieldName: fields.DISPOSITION_APPROVAL_ROLE_ROLE_ID,
        },
        {
            key: 'alternateSecondaryDispositionApprovalType',
            fieldName:
                fields.EVIDENCE_DEPARTMENT_CONFIG_ALTERNATE_SECONDARY_DISPOSITION_APPROVAL_TYPE,
        },
        {
            key: 'alternateSecondaryDispositionApprovalRoleIds',
            fieldName: fields.DISPOSITION_APPROVAL_ROLE_ROLE_ID,
        },
    ]),
};

const labelPrintingFieldsetViewModel = {
    type: FIELDSET,
    key: 'labelPrinting',
    fields: buildFlatFormFieldViewModels([
        {
            type: N_ITEMS_FIELDSET,
            key: 'labelPrinters',
            fields: buildFlatFormFieldViewModels([
                // the id is hidden field to make sure label printers get updated
                // properly by keeping their identity
                'id',
                {
                    key: 'name',
                    fieldName: fields.LABEL_PRINTER_NAME,
                },
                {
                    key: 'networkAddress',
                    fieldName: fields.LABEL_PRINTER_NETWORK_ADDRESS,
                },
                {
                    key: 'printerAddress',
                    fieldName: fields.LABEL_PRINTER_PRINTER_ADDRESS,
                },
                {
                    key: 'dpi',
                    fieldName: fields.LABEL_PRINTER_DPI,
                },
                {
                    key: 'darkness',
                    fieldName: fields.LABEL_PRINTER_DARKNESS,
                },
                {
                    key: 'printSpeed',
                    fieldName: fields.LABEL_PRINTER_PRINT_SPEED,
                },
                {
                    key: 'slewSpeed',
                    fieldName: fields.LABEL_PRINTER_SLEW_SPEED,
                },
                {
                    key: 'printMethod',
                    fieldName: fields.LABEL_PRINTER_PRINT_METHOD,
                },
            ]),
        },
        {
            key: 'labelPrintDpi',
            fieldName: fields.EVIDENCE_DEPARTMENT_CONFIG_LABEL_PRINT_DPI,
            validators: {
                requiredError: requiredInt,
            },
        },
    ]),
};

const evidenceConfigAdminFormFieldViewModels = {
    labelLayoutElements: labelLayoutElementsFieldsetViewModel,
    chainEventTypes: chainEventTypesFieldsetViewModel,
    itemTypes: itemTypesFieldsetViewModel,
    dispositionPeriods: dispositionPeriodsFieldsetViewModel,
    dispositionApprovals: dispositionApprovalsFieldsetViewModel,
    labelPrinting: labelPrintingFieldsetViewModel,
};

export function convertToFormModel(data = {}, { labelLayoutDisplayNameByField = () => {} } = {}) {
    const {
        chainEventTypes = [],
        evidenceDepartmentConfig = {},
        labelLayoutElements,
        labelPrinters = [],
        ...restOfData
    } = data;

    return buildFormModel(
        {
            ...restOfData,
            chainEventTypes: sortBy(chainEventTypes, 'name'),
            dispositionPeriods: mapValues(
                pick(evidenceDepartmentConfig, 'finalReleaseWaitPeriod'),
                (period) => parsePeriod(period).amount.toString()
            ),
            dispositionApprovals: pick(evidenceDepartmentConfig, [
                'primaryDispositionApprovalType',
                'primaryDispositionApprovalRoleIds',
                'alternatePrimaryDispositionApprovalType',
                'alternatePrimaryDispositionApprovalRoleIds',
                'secondaryDispositionApprovalType',
                'secondaryDispositionApprovalRoleIds',
                'alternateSecondaryDispositionApprovalType',
                'alternateSecondaryDispositionApprovalRoleIds',
            ]),
            itemTypes: pick(evidenceDepartmentConfig, ['vehiclesEnabled']),
            labelLayoutElements: sortBy(labelLayoutElements, labelLayoutDisplayNameByField),
            labelPrinting: {
                labelPrinters: sortBy(labelPrinters, ({ name }) => name.toLowerCase()),
                labelPrintDpi: evidenceDepartmentConfig.labelPrintDpi,
            },
        },
        evidenceConfigAdminFormFieldViewModels
    );
}

export const mapChainEventCategoryToChainEventTypes = (chainEventTypesState, chainEventTypesForm) =>
    map(chainEventTypesForm, (chainEventType) => ({
        ...chainEventType,
        chainEventCategory: get(chainEventTypesState, `${chainEventType.id}.chainEventCategory`),
    }));

function convertFromFormModel(formData, chainEventTypesState) {
    const transformedData = filterFormData({
        ...omit(formData, [
            'itemTypes',
            'chainEventTypes',
            'dispositionPeriods',
            'dispositionApprovals',
            'labelPrinting',
        ]),
        labelPrinters: formData.labelPrinting.labelPrinters,
        evidenceDepartmentConfig: {
            ...formData.itemTypes,
            ...mapValues(formData.dispositionPeriods, (period) => convertDaysToDayPeriod(period)),
            ...formData.dispositionApprovals,
            labelPrintDpi: formData.labelPrinting.labelPrintDpi,
        },
    });

    return {
        ...transformedData,
        chainEventTypes: mapChainEventCategoryToChainEventTypes(
            chainEventTypesState,
            get(formData, 'chainEventTypes')
        ),
    };
}

export default createFormModule({
    formName: FormEnum.EVIDENCE_CONFIG_ADMIN.name,
    fieldViewModels: evidenceConfigAdminFormFieldViewModels,
    convertToFormModel,
    convertFromFormModel,
});

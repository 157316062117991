import _ from 'lodash';
import exportsActionTypes from '../actions/types/exportsActionTypes';

const {
    EXPORTS_PAGELOAD,
    LOAD_PACKET_OPTIONS_SUCCESS,
    LOAD_PACKET_OPTIONS_FAILURE,
    PRINT,
    PRINT_REFRESH,
    COLLAPSE_MY_EXPORTS,
    EXPAND_MY_EXPORTS,
    REMOVE_FILES_FROM_MY_EXPORTS,
    SET_REDACTION_WORK_ORDER,
    SET_POSITION_RIGHT,
} = exportsActionTypes;

const initialDataState = {
    myExports: [],
    packetOptions: [],
    redactionWorkOrder: null,
};

const initialUiState = {
    expanded: false,
    failedToLoadOptions: false,
    loadedOptions: false,
    positionRight: '150px',
};

export function exportsDataReducer(state = initialDataState, action) {
    switch (action.type) {
        case EXPORTS_PAGELOAD:
            return _.assign({}, state, {
                packetOptions: [],
            });
        case LOAD_PACKET_OPTIONS_SUCCESS:
            return _.assign({}, state, {
                packetOptions: action.payload,
            });
        case PRINT_REFRESH:
            return _.assign({}, state, {
                myExports: action.payload,
            });
        case REMOVE_FILES_FROM_MY_EXPORTS:
            const fileIds = action.payload;
            return {
                ...state,
                myExports: _.reject(state.myExports, (myExport) =>
                    _.includes(fileIds, myExport.file.id)
                ),
            };
        case SET_REDACTION_WORK_ORDER:
            return {
                ...state,
                redactionWorkOrder: action.payload,
            };

        default:
            return state;
    }
}

export function exportsUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case EXPORTS_PAGELOAD:
            return _.assign({}, state, {
                failedToLoadOptions: false,
                loadedOptions: false,
            });
        case LOAD_PACKET_OPTIONS_FAILURE:
            return _.assign({}, state, {
                failedToLoadOptions: true,
            });
        case LOAD_PACKET_OPTIONS_SUCCESS:
            return _.assign({}, state, {
                failedToLoadOptions: false,
                loadedOptions: true,
            });
        case PRINT:
            return _.assign({}, state, {
                expanded: true,
            });
        case COLLAPSE_MY_EXPORTS:
            return _.assign({}, state, {
                expanded: false,
            });
        case EXPAND_MY_EXPORTS:
            return _.assign({}, state, {
                expanded: true,
            });
        case SET_POSITION_RIGHT:
            return _.assign({}, state, {
                positionRight: action.payload,
            });

        default:
            return state;
    }
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import {
    createField,
    createFormConfiguration,
    Form,
    formEvents,
    InferFormDataShape,
    lifecycleOptions,
} from 'markformythree';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../core/overlays/components/Modal';
import { MFTCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { MFTText } from '../../../core/forms/components/Text';
import { abilitiesEnum } from '../../../core/abilities';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import {
    convertYupErrorShapeToMFTErrorShape,
    convertYupSuccessShapeToMFTSuccessShape,
} from '../../../core/validation/yupMftValidation';
import { FormState } from '../../../../core/typings/markformythree';
import { RoleSetting } from '../../../core/permissions/components/RecordPermissionsForm';
import useOverlayStoreCustomPropertiesGetter from '../../core/hooks/useOverlayStoreCustomPropertiesGetter';
import SavedSearchModalPermissionContent, {
    useSavedSearchGetEntityPermissions,
} from './SavedSeachModalPermissionContent';

const title = componentStrings.search.savedSearch.SavedSearchEditModal.title;
const labels = componentStrings.search.savedSearch.SavedSearchEditModal.labels;
const validations = componentStrings.search.savedSearch.SavedSearchEditModal.validations;
const contentStyle = { height: 492 };
const modalStyle = { width: 804 };

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SearchNameSection = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
`;

const CheckboxWrapper = styled.span`
    margin-bottom: 5px;
    margin-top: 23px;
`;

const formConfiguration = createFormConfiguration({
    name: createField<string>({}),
    isShared: createField<boolean>({}),
});

type CreateSavedSearchModalMFTFormConfiguration = typeof formConfiguration;

type CreateSavedSearchModalFormModel = InferFormDataShape<CreateSavedSearchModalMFTFormConfiguration> & {
    entityPermissions?: RoleSetting[];
};

interface CreateSavedSearchModalProps {
    onSave?: (formModel: CreateSavedSearchModalFormModel) => void;
}

const validationSchema = yup.object().shape({
    name: yup.string().required(validations.searchNameRequired),
});

function validationHandler({
    formState,
    eventType,
}: {
    formState: FormState<CreateSavedSearchModalMFTFormConfiguration>;
    eventType: string;
}) {
    // @ts-expect-error markformythree upstream fix needed for `formState` type definition
    const $form = formState.ui.$form;

    return validationSchema
        .validate(formState.model, { abortEarly: false })
        .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
        .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
}

const useUserCanShare = () => {
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    return currentUserHasAbility(abilitiesEnum.ADMIN.SAVED_SEARCH_SHARING);
};

const CreateSavedSearchModal: React.FC<CreateSavedSearchModalProps> = ({ onSave }) => {
    const { getForm } = useFormGetter();
    const [shareSearch, setShareSearch] = useState<boolean | undefined>(false);
    const [hideError, setHideError] = useState(true);
    const userCanShare = useUserCanShare();
    const getEntityPermissions = useSavedSearchGetEntityPermissions();
    const getCustomProperties = useOverlayStoreCustomPropertiesGetter<{
        entityPermissions: RoleSetting[];
    }>(overlayIdEnum.SAVED_SEARCH_CREATE_MODAL);
    const initialState = { name: '', isShared: false };
    const validationEvents = [
        { eventType: formEvents.FORM_SUBMIT },
        { eventType: formEvents.INPUT_CHANGE },
    ];

    const handleSave = React.useCallback(() => {
        const form = getForm(formClientEnum.SAVED_SEARCH_CREATE_FORM);
        if (form) {
            return form
                .submit()
                .then(() => {
                    if (onSave) {
                        const entityPermissions = getEntityPermissions();
                        onSave({ entityPermissions, ...form.get() });
                    }
                })
                .catch((err) => {
                    // Why? We only have one input field that's always in view
                    // so we only want to show the error banner for this modal
                    // when the error is not a form field validation error
                    if (!err.form) {
                        setHideError(false);
                    }
                    throw err;
                });
        }
        return undefined;
    }, [onSave, getForm, getEntityPermissions]);

    return (
        <Modal
            okText={shareSearch ? labels.buttonSaveAndShare : labels.buttonSave}
            cancelText={labels.buttonCancel}
            contentStyle={contentStyle}
            id={overlayIdEnum.SAVED_SEARCH_CREATE_MODAL}
            modalStyle={modalStyle}
            shouldCloseOnOverlayClick={false}
            title={title}
            onSave={handleSave}
            hideErrors={hideError}
        >
            <Form
                configuration={formConfiguration}
                initialState={initialState}
                lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                name={formClientEnum.SAVED_SEARCH_CREATE_FORM}
                validationEvents={validationEvents}
                onValidate={validationHandler}
                render={(form) => {
                    const { isShared } = form.get();
                    const { entityPermissions } = getCustomProperties();

                    setShareSearch(isShared);

                    return (
                        <ContentWrapper>
                            <SearchNameSection>
                                <MFTText label={labels.inputName} path="name" />
                                {userCanShare && (
                                    <CheckboxWrapper>
                                        <MFTCheckbox
                                            path="isShared"
                                            labelPosition="right"
                                            label={labels.checkboxShare}
                                            width={300}
                                            onChange={() => setShareSearch(isShared)}
                                        />
                                    </CheckboxWrapper>
                                )}
                            </SearchNameSection>
                            {userCanShare && isShared && (
                                <SavedSearchModalPermissionContent
                                    roleAddItemOnEmpty={false}
                                    initialEntityPermissions={entityPermissions}
                                />
                            )}
                        </ContentWrapper>
                    );
                }}
            />
        </Modal>
    );
};

export default CreateSavedSearchModal;

import React from 'react';
import { get, map, isEmpty } from 'lodash';

import { ExpandedPersonGangTrackingDisplay } from '~/client-common/core/domain/person-gang-trackings/components/GangTrackingDisplay';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    convertPassportsToComponentArray,
    convertIdentifiersToComponentArray,
} from '../../core/helpers/identifierHelpers';
import { ExpectedDateOfBirthSummary } from '../../../records/core/components/summaries/PersonProfileSummary';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
    EntityProfileSubdetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../legacy-redux/components/summaries/SummaryRowDate';
import EntityProfileSectionNoData from '../../core/components/EntityProfileSectionNoData';

const strings = componentStrings.entityProfiles.person.IdentificationDetails;

function IdentificationDetails({ personDetailsViewModel }) {
    const dateOfDeathUtc = get(personDetailsViewModel, 'dateOfDeathUtc');
    const display = personDetailsViewModel ? getViewModelProperties(personDetailsViewModel) : {};
    const fieldDisplayNames = useFields([
        fields.PERSON_PROFILE_RACE_ATTR_ID,
        fields.PERSON_PROFILE_ETHNICITY_ATTR_ID,
    ]);

    const nicknames = personDetailsViewModel
        ? map(personDetailsViewModel.monikers, (monikerObj) => (
              <div key={monikerObj.id}>{monikerObj.moniker}</div>
          ))
        : '';

    const identifiers = convertIdentifiersToComponentArray(
        display.identifiers,
        'nameIdentifierType'
    );

    return (
        <div>
            <EntityProfileSection title={strings.title}>
                <EntityProfileSummaryColumn>
                    <EntityProfileDetailSummaryList>
                        <SummaryRow label={strings.labels.trueName}>
                            {
                                /* currently isn't shown because we don't get FBI data from camden */
                                personDetailsViewModel.trueName
                            }
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.NAME_MONIKER_MONIKER}
                            hidden={!personDetailsViewModel.monikers}
                        >
                            {nicknames}
                        </SummaryRow>
                        <SummaryRow
                            label={strings.labels.birthInfo}
                            hidden={!display.dobAgeRange && !display.placeOfBirth}
                        >
                            {!!display.dobAgeRange && <div>{display.dobAgeRange}</div>}
                            {!!display.placeOfBirth && <div>{display.placeOfBirth}</div>}
                        </SummaryRow>
                        <ExpectedDateOfBirthSummary
                            expectedDateOfBirth={personDetailsViewModel.expectedDateOfBirth}
                            isExpectedDateOfBirthUnknown={
                                personDetailsViewModel.isExpectedDateOfBirthUnknown
                            }
                        />
                        <SummaryRow fieldName={fields.PERSON_PROFILE_IS_PREGNANT_ATTR_ID}>
                            {display.isPregnantAttrId}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_DATE_OF_EMANCIPATION}>
                            {personDetailsViewModel.dateOfEmancipation}
                        </SummaryRow>
                        <SummaryRowDate
                            fieldName={fields.PERSON_PROFILE_DATE_OF_DEATH_UTC}
                            date={dateOfDeathUtc}
                            format={SummaryRowDate.FORMATS.FORM_DATE_TIME}
                        />
                        <SummaryRow fieldName={fields.PERSON_PROFILE_VERIFICATION_OF_DEATH_ATTR_ID}>
                            {display.verificationOfDeathAttrId}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_SEX_ATTR_ID}>
                            {display.sexAttrId}
                        </SummaryRow>
                        <SummaryRow
                            label={`${fieldDisplayNames[fields.PERSON_PROFILE_RACE_ATTR_ID]}/${
                                fieldDisplayNames[fields.PERSON_PROFILE_ETHNICITY_ATTR_ID]
                            }`}
                        >
                            {display.raceEthnicity}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_CITIZENSHIP_ATTR_ID}>
                            {display.citizenshipAttrId}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.PERSON_PROFILE_IS_RESIDENT_OF_JURISDICTION_ATTR_ID}
                        >
                            {display.isResidentOfJurisdictionAttrId}
                        </SummaryRow>
                    </EntityProfileDetailSummaryList>
                </EntityProfileSummaryColumn>
                <EntityProfileSummaryColumn>
                    <EntityProfileDetailSummaryList>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_SSN}>
                            {personDetailsViewModel.ssn}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_NATIONAL_ID_NUMBER}>
                            {personDetailsViewModel.nationalIdNumber}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_ID}>
                            {personDetailsViewModel.masterPersonId}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_FBI_UCN}>
                            {personDetailsViewModel.fbiUcn}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_CRO_ID}>
                            {personDetailsViewModel.croId}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_STATE_ID_NUMBER}>
                            {personDetailsViewModel.stateIdNumber}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_PNC_ID}>
                            {personDetailsViewModel.pncId}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.PERSON_PROFILE_DL_NUMBER}
                            hidden={
                                !(
                                    personDetailsViewModel.dlNumber ||
                                    display.dlState ||
                                    display.dlType ||
                                    display.dlEndorsementAttrId ||
                                    personDetailsViewModel.dlCountry
                                )
                            }
                        >
                            <div>{personDetailsViewModel.dlNumber}</div>
                            <div>{display.dlState}</div>
                            <EntityProfileSubdetailSummaryList>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_DL_STATUS_ATTR_ID}>
                                    {display.dlStatusAttrId}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_DL_TYPE_ATTR_ID}>
                                    {display.dlType}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={fields.PERSON_PROFILE_DL_ENDORSEMENT_ATTR_ID}
                                >
                                    {display.dlEndorsementAttrId}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_DL_COUNTRY}>
                                    {personDetailsViewModel.dlCountry}
                                </SummaryRow>
                            </EntityProfileSubdetailSummaryList>
                        </SummaryRow>
                        {identifiers}
                        <SummaryRow fieldName={fields.PERSON_PROFILE_MARITAL_STATUS_ATTR_ID}>
                            {display.maritalStatusAttrId}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.PERSON_PROFILE_RELIGION_ATTR_ID}
                            hidden={
                                !(display.religionAttrId || personDetailsViewModel.religionOther)
                            }
                        >
                            <div>{display.religionAttrId}</div>
                            <EntityProfileSubdetailSummaryList>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_RELIGION_OTHER}>
                                    {personDetailsViewModel.religionOther}
                                </SummaryRow>
                            </EntityProfileSubdetailSummaryList>
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.PERSON_PROFILE_SEXUAL_ORIENTATION_ATTR_ID}
                            hidden={
                                !(
                                    display.sexualOrientationAttrId ||
                                    personDetailsViewModel.sexualOrientationOther
                                )
                            }
                        >
                            <div>{display.sexualOrientationAttrId}</div>
                            <EntityProfileSubdetailSummaryList>
                                <SummaryRow
                                    fieldName={fields.PERSON_PROFILE_SEXUAL_ORIENTATION_OTHER}
                                >
                                    {personDetailsViewModel.sexualOrientationOther}
                                </SummaryRow>
                            </EntityProfileSubdetailSummaryList>
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_DETAILS}>
                            {personDetailsViewModel.details}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PASSPORT_N_ITEMS_WRAPPER}>
                            {convertPassportsToComponentArray(personDetailsViewModel.passports)}
                        </SummaryRow>
                    </EntityProfileDetailSummaryList>
                </EntityProfileSummaryColumn>
            </EntityProfileSection>
            {display.personGangTracking && display.isMaster && (
                <EntityProfileSection noDivider={true}>
                    <EntityProfileSummaryColumn className="identifying-marks">
                        <EntityProfileDetailSummaryList contentWidth={440}>
                            <SummaryRow fieldName={fields.DISPLAY_SUSPECTED_GANG_AFFILIATION}>
                                <ExpandedPersonGangTrackingDisplay
                                    personGangTracking={display.personGangTracking}
                                />
                            </SummaryRow>
                        </EntityProfileDetailSummaryList>
                    </EntityProfileSummaryColumn>
                </EntityProfileSection>
            )}
        </div>
    );
}

const RenderNoInformation = () => (
    <EntityProfileSection title={strings.title}>
        <EntityProfileSectionNoData />
    </EntityProfileSection>
);

export default renderOnlyIf(
    ({ personDetailsViewModel }) => !isEmpty(personDetailsViewModel),
    RenderNoInformation
)(IdentificationDetails);

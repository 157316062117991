import React, { createContext, useState, useCallback, useContext } from 'react';
import { reduce } from 'lodash';
import { AttributeTypeEnum } from '@mark43/rms-api';

import { useResource } from '~/client-common/core/hooks/useResource';

import attributesAdminResource from '../../../../legacy-redux/resources/attributesAdminResource';

type PersonSidePanelContextT = {
    subjectTypeAttrId?: number;
    selectedLinkType?: number;
    setSelectedLinkTypeBySubjectAttributeId?: (subjectTypeAttrId?: number) => void;
};

const PersonSidePanelContext = createContext<PersonSidePanelContextT>({});

export const usePersonSidePanelContext = () => {
    return useContext(PersonSidePanelContext);
};

type AttributeLinkTypeLinkState = Record<number, number>;

export const PersonSidePanelContextProvider: React.FC = ({ children }) => {
    const [subjectTypeAttrId, setSubjectTypeAttrId] = useState<number>();
    const [selectedLinkType, setSelectedLinkType] = useState<number>();
    const [attributeLinkTypes, setAttributeLinkTypes] = useState<AttributeLinkTypeLinkState>({});

    const onResourceSuccess = useCallback((attributeCodeLinks) => {
        const linkTypes = attributeCodeLinks.linkTypes;
        const reducedAccumulator: AttributeLinkTypeLinkState = {};

        const result = reduce(
            linkTypes,
            (acc, item) => {
                return {
                    ...acc,
                    [item.attributeId]: item.linkType,
                };
            },
            reducedAccumulator
        );

        setAttributeLinkTypes(result);
    }, []);

    const loadFieldContactSubjectTypeAttributeLinkTypes = useCallback(() => {
        return attributesAdminResource.getAttributeTypeMappings(
            AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE.name
        );
    }, []);

    useResource(loadFieldContactSubjectTypeAttributeLinkTypes, onResourceSuccess);

    const setSelectedLinkTypeBySubjectAttributeId = useCallback(
        (subjectTypeAttrId) => {
            const linkType = attributeLinkTypes[subjectTypeAttrId];
            setSubjectTypeAttrId(subjectTypeAttrId);
            setSelectedLinkType(linkType);
        },
        [attributeLinkTypes]
    );

    return (
        <PersonSidePanelContext.Provider
            value={{
                subjectTypeAttrId,
                selectedLinkType,
                setSelectedLinkTypeBySubjectAttributeId,
            }}
        >
            {children}
        </PersonSidePanelContext.Provider>
    );
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, pure, withPropsOnChange } from 'recompose';
import styled from 'styled-components';
import { first, get } from 'lodash';

import { chainEventViewModelsForChainOfCustodyIdSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';

import { renderOnlyIf } from '../../../../../legacy-redux/helpers/reactHelpers';

const LatestChainEventDateTime = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-style: italic;
`;

function CustodialPropertyCardItemLatestChainEvent({ chainEventViewModel }) {
    const viewModelProperties = getViewModelProperties(chainEventViewModel);
    return (
        <div>
            <div>{get(viewModelProperties, 'eventTypeDisplay')}</div>
            <div>{get(viewModelProperties, 'storageLocation.fullDisplayPath')}</div>
            <div>
                {get(viewModelProperties, 'receivedByEntityId.fullNameWithFirstInitialAndIdNumber')}
            </div>
            <div>{get(chainEventViewModel, 'receivedByName')}</div>
            <LatestChainEventDateTime>
                <FormattedDate
                    date={chainEventViewModel.eventDateUtc}
                    format={FormattedDate.FORMATS.FORM_DATE_TIME}
                />
            </LatestChainEventDateTime>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    chainEventViewModelsForChainOfCustodyId: chainEventViewModelsForChainOfCustodyIdSelector,
});

/**
 * Latest chain event section for an item in the custodial property summary report.
 * @param {number} chainOfCustodyId
 */
export default compose(
    pure,
    connect(mapStateToProps),
    withPropsOnChange(
        ['chainOfCustodyId', 'chainEventViewModelsForChainOfCustodyId'],
        ({ chainOfCustodyId, chainEventViewModelsForChainOfCustodyId }) => ({
            chainEventViewModel: first(chainEventViewModelsForChainOfCustodyId(chainOfCustodyId)),
        })
    ),
    renderOnlyIf(({ chainEventViewModel }) => !!chainEventViewModel)
)(CustodialPropertyCardItemLatestChainEvent);

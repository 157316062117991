import { SystemRuleEnum, FieldTypeEnum } from '@mark43/rms-api';
import validationStrings from '~/client-common/core/strings/validationStrings';

const defaultMessages = {
    panel: validationStrings.panel.generic,
    field: validationStrings.field.required,
};

export default {
    [SystemRuleEnum.OFFENSE_ATTRIBUTES_OTHER_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.OFFENSE_LOCATION_REQUIRED.name]: {
        panel: validationStrings.panel.offenseLocation,
    },
    [SystemRuleEnum.OFFENSE_VICTIM_REQUIRED.name]: {
        panel: validationStrings.panel.victimRequired,
    },
    [SystemRuleEnum.OFFENSE_ARSON_BUILDING_INHABITED_REQUIRED_IF_ARSON.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.OFFENSE_SUSPECT_REQUIRED.name]: {
        panel: validationStrings.panel.suspectRequired,
    },
    [SystemRuleEnum.OFFENSE_PROPERTY_STATUSES_REQUIRED.name]: {
        panel: validationStrings.panel.propertyRequired,
    },
    [SystemRuleEnum.OFFENSE_SUSPECT_DOB_OR_EQUIV_REQUIRED.name]: {
        panel: validationStrings.panel.dobOrAgeRange,
    },
    [SystemRuleEnum.OFFENSE_STATEMENT_TAKEN_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.OFFENSE_DATE_OR_DATE_UNKNOWN_REQUIRED.name]: {
        panel: validationStrings.panel.offenseDateOrDateUnknownRequired,
    },
    [SystemRuleEnum.OFFENSE_LEOKA_ACTIVITY_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.OFFENSE_LEOKA_ASSIGNMENT_TYPE_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.OFFENSE_BURG_NUMBER_OF_PREMISES_ENTERED_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.NO_MUTUALLY_EXCLUSIVE_OFFENSES_FOR_SAME_VICTIM.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.INJURY_TYPE_ON_INJURY_OFFENSES_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.RELATIONSHIP_ON_RELATIONSHIP_OFFENSES_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.RELATIONSHIP_ON_RELATIONSHIP_OFFENSES_REQUIRED_NIBRS.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.INCIDENT_LOCATION_REQUIRED.name]: {
        panel: validationStrings.panel.incidentLocation,
    },
    [SystemRuleEnum.LOCATION_STREET_ADDRESS_OR_CROSS_STREET_REQUIRED.name]: {
        panel: validationStrings.panel.streetAddressOrCrossStreets,
    },
    [SystemRuleEnum.LOCATION_LINK_REQUIRE_DEPARTMENT_SUBDIVISIONS.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.PERSON_DRIVER_LICENSE_OTHER_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.PROPERTY_STATUS_RECOVERED_BY_OTHER_NAME_REQUIRED.name]: {
        ...defaultMessages,
        panel: validationStrings.panel.propertyStatusRecoveredByOtherNameRequired,
    },
    [SystemRuleEnum.PERSON_SCHOOL_HISTORY_DISPLAY_NUMBER_SPECIAL_MAX_LENGTH.name]: {
        panel: validationStrings.panel.phoneLength,
        field: validationStrings.field.phoneLength,
    },
    [SystemRuleEnum.DATE_OF_BIRTH_RANGE.name]: {
        ...defaultMessages,
        field: validationStrings.field.invalidRange,
    },
    [SystemRuleEnum.PHONE_SPECIAL_MAX_LENGTH.name]: {
        panel: validationStrings.panel.phoneLength,
        field: validationStrings.field.phoneLength,
    },
    [SystemRuleEnum.ORG_PHONE_SPECIAL_MAX_LENGTH.name]: {
        panel: validationStrings.panel.phoneLength,
        field: validationStrings.field.phoneLength,
    },
    [SystemRuleEnum.PHONE_TYPE_REQUIRED_IF_NUMBER_FILLED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.ORG_PHONE_TYPE_REQUIRED_IF_NUMBER_FILLED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.EMAIL_TYPE_REQUIRED_IF_EMAIL_FILLED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.ID_TYPE_REQUIRED_IF_IDENTIFIER_FILLED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.EMPLOYER_NAME_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.MILITARY_BRANCH_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.MISSING_PERSON_HOME_ADDRESS_REQUIRED.name]: {
        panel: validationStrings.panel.homeAddress,
    },
    [SystemRuleEnum.OFFENSE_CODE_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.OFFENSE_VICTIM_DOB_OR_EQUIV_REQUIRED.name]: {
        panel: validationStrings.panel.dobOrAgeRange,
    },
    [SystemRuleEnum.OFFENSE_VICTIM_ADDRESS_REQUIRED.name]: {
        panel: validationStrings.panel.personAddress,
    },
    [SystemRuleEnum.OFFENSE_JUSTIFIABLE_HOMICIDE_REQUIRED.name]: {
        panel: validationStrings.panel.justifiableHomicide,
    },
    [SystemRuleEnum.OFFENSE_NEGLIGENT_MANSLAUGHTER_REQUIRED.name]: {
        panel: validationStrings.panel.negligentManslaughter,
    },
    [SystemRuleEnum.OFFENSE_INCLUDES_CARGO_THEFT_REQUIRED.name]: {
        panel: validationStrings.panel.cargoTheft,
    },
    [SystemRuleEnum.OFFENSE_BURG_WAS_METHOD_OF_ENTRY_FORCED_REQUIRED.name]: {
        panel: validationStrings.panel.entryForced,
    },
    [SystemRuleEnum.OFFENSE_HATE_BIAS.name]: {
        panel: validationStrings.panel.hateBias,
    },
    [SystemRuleEnum.OFFENSE_AGGRAVATED_ASSAULT_REQUIRED.name]: {
        panel: validationStrings.panel.aggAssault,
    },
    [SystemRuleEnum.OFFENSE_HOMICIDE_CIRCUMSTANCE_REQUIRED.name]: {
        panel: validationStrings.panel.homicideCircumstance,
    },
    [SystemRuleEnum.OFFENSE_WEAPON_OR_FORCE_INVOLVED_REQUIRED.name]: {
        panel: validationStrings.panel.weapon,
    },
    [SystemRuleEnum.OFFENSE_GANG_INFO_REQUIRED.name]: {
        panel: validationStrings.panel.gangInfo,
    },
    [SystemRuleEnum.OFFENSE_ANIMAL_CRUELTY_REQUIRED.name]: {
        panel: validationStrings.panel.animalCruelty,
    },
    [SystemRuleEnum.OFFENSE_CRIMINAL_ACTIVITY_CATEGORY_REQUIRED.name]: {
        panel: validationStrings.panel.criminalActivity,
    },
    [SystemRuleEnum.OFFENSE_IS_LEOKA_REQUIRED.name]: {
        panel: validationStrings.panel.isLeoka,
    },
    [SystemRuleEnum.OFFENSE_LARCENY_CANNOT_HAVE_STOLEN_VEHICLE.name]: {
        panel: validationStrings.panel.noStolenVehicle,
    },
    [SystemRuleEnum.VICTIM_RACE_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.MISSING_PERSON_RACE_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.DEFENDANT_RACE_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.DEFENDANT_ADDRESS_REQUIRED.name]: {
        panel: validationStrings.panel.personAddress,
    },
    [SystemRuleEnum.MISSING_PERSON_DOB_OR_EQUIV_REQUIRED.name]: {
        panel: validationStrings.panel.dobOrAgeRange,
    },
    [SystemRuleEnum.DEFENDANT_DOB_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.KNOWN_PERSON_DATE_OF_EMANCIPATION_MUST_BE_AFTER_DOB.name]: {
        panel: validationStrings.panel.emancipationDate,
    },
    [SystemRuleEnum.KNOWN_PERSON_SEX_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.ORG_VICTIMS_ADDRESS_REQUIRED.name]: {
        panel: validationStrings.panel.orgAddress,
    },
    [SystemRuleEnum.ORG_INDUSTRY_OTHER_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.OFFENSE_SUSPECTED_HATE_CRIME_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.OFFENSE_CRIME_AGAINST_PROPERTY_VICTIM_IS_NOT_SOCIETY.name]: {
        panel: validationStrings.panel.victimNotSociety,
    },
    [SystemRuleEnum.OFFENSE_CRIME_AGAINST_PERSON_HAS_PERSON_VICTIM.name]: {
        panel: validationStrings.panel.personVictim,
    },
    [SystemRuleEnum.OFFENSE_SEX_ASSAULT_HAS_KNOWN_PERSON_VICTIM.name]: {
        panel: validationStrings.panel.knownPersonVictim,
    },
    [SystemRuleEnum.OFFENSE_DOMESTIC_VIOLENCE_HAS_KNOWN_PERSON_VICTIM.name]: {
        panel: validationStrings.panel.knownPersonVictim,
    },
    [SystemRuleEnum.OFFENSE_BURGLARY_CANNOT_HAVE_SOCIETY_VICTIM.name]: {
        panel: validationStrings.panel.victimNotSociety,
    },
    [SystemRuleEnum.INJURIES_CANNOT_HAVE_TYPE_NONE_AND_OTHER_INJURIES.name]: {
        panel: validationStrings.panel.noneInjury,
    },
    [SystemRuleEnum.USE_OF_FORCE_REPORT_SUBJECT_CARD_REQUIRED.name]: {
        panel: validationStrings.submission.subjectRequired,
    },
    [SystemRuleEnum.OFFENSE_REPORT_OFFENSE_OR_INCIDENT_REQUIRED.name]: {
        panel: validationStrings.submission.offenseRequired,
    },
    [SystemRuleEnum.PROPERTY_STATUS_VEHICLE_SEARCH_CONSENT_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.ITEM_PROOF_OF_OWNERSHIP_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.ITEM_PROOF_OF_OWNERSHIP_OTHER_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.ITEM_VEHICLE_BODY_STYLE_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.ITEM_VEHICLE_BODY_STYLE_OTHER_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CREATE_REPORT_REPORTING_EVENT_NUMBER_MUST_BE_INT.name]: {
        ...defaultMessages,
        field: validationStrings.field.renSearchReportingEventNumberMustBeInt,
    },
    [SystemRuleEnum.CASE_DETAILS_NO_DUPLICATE_CASE_ROLE_LINKS.name]: {
        panel: validationStrings.panel.cannotHaveAssigneeSupervisorDupe,
    },
    [SystemRuleEnum.CASE_DETAILS_NO_DUPLICATE_CASE_ROLE_LINKS_ASSIGNEE_ASSISTING_INVESTIGATOR
        .name]: {
        panel: validationStrings.panel.cannotHaveAssigneeAssistingInvestigatorDupe,
    },
    [SystemRuleEnum.CASE_DETAILS_NO_DUPLICATE_CASE_ROLE_LINKS_ASSISTING_INVESTIGATOR_SUPERVISOR
        .name]: {
        panel: validationStrings.panel.cannotHaveAssistingInvestigatorSupervisorDupe,
    },
    [SystemRuleEnum.CASE_DETAILS_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID_REQUIRED_IF_STATUS_CLOSED
        .name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CASE_DETAILS_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID_REQUIRED_IF_STATUS_CLOSED
        .name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CASE_DETAILS_CASE_STATUS_STATUS_DATE_UTC_REQUIRED_IF_STATUS_CLOSED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum
        .CASE_DETAILS_REPORT_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID_REQUIRED_IF_STATUS_CLOSED
        .name]: {
        panel: validationStrings.panel.closedByFieldsAreRequiredIfStatusClosed,
    },
    [SystemRuleEnum.CASE_DETAILS_REPORT_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID_REQUIRED_IF_STATUS_CLOSED
        .name]: {
        panel: validationStrings.panel.closedByFieldsAreRequiredIfStatusClosed,
    },
    [SystemRuleEnum.CASE_DETAILS_REPORT_CASE_STATUS_STATUS_DATE_UTC_REQUIRED_IF_STATUS_CLOSED
        .name]: {
        panel: validationStrings.panel.closedByFieldsAreRequiredIfStatusClosed,
    },
    [SystemRuleEnum.REPORT_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID_REQUIRED_IF_STATUS_CLOSED.name]: {
        panel: validationStrings.panel.closedByFieldsAreRequiredIfStatusClosed,
    },
    [SystemRuleEnum.REPORT_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID_REQUIRED_IF_STATUS_CLOSED.name]: {
        panel: validationStrings.panel.closedByFieldsAreRequiredIfStatusClosed,
    },
    [SystemRuleEnum.REPORT_CASE_STATUS_STATUS_DATE_UTC_REQUIRED_IF_STATUS_CLOSED.name]: {
        panel: validationStrings.panel.closedByFieldsAreRequiredIfStatusClosed,
    },
    [SystemRuleEnum.CASE_DETAILS_ASSIGNEE_REQUIRED_IF_NO_ASSIGNED_UNIT.name]: {
        panel: validationStrings.panel.assigneeOrAssignedUnitRequired,
    },
    [SystemRuleEnum.CASE_DETAILS_ASSIGNED_UNIT_REQUIRED_IF_NO_ASSIGNEE.name]: {
        panel: validationStrings.panel.assigneeOrAssignedUnitRequired,
        field: validationStrings.field.requiredIfNoAssignee,
    },
    [SystemRuleEnum.CASE_DETAILS_LOCAL_ID_REQUIRED_IF_NOT_NEW_CASE.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CASE_REVIEW_MIN_LENGTH_REQUIRED_IF_REJECTED.name]: {
        ...defaultMessages,
        field: validationStrings.min[FieldTypeEnum.STRING.name](20),
    },
    [SystemRuleEnum.OFFENSE_ASSAULT_HOMICIDE_COMPLETED_REQUIRED.name]: {
        panel: validationStrings.panel.offenseAssaultHomicideCompletedRequired,
        field: validationStrings.field.offenseAssaultHomicideCompletedRequired,
    },
    [SystemRuleEnum.OFFENSE_JUSTIFIABLE_HOMICIDE_HATE_CRIME_NONE.name]: {
        panel: validationStrings.panel.offenseJustifiableHomicideHateCrimeNone,
        field: validationStrings.field.offenseJustifiableHomicideHateCrimeNone,
    },
    [SystemRuleEnum.OFFENSE_ATTEMPTED_REQUIRES_PROPERTY_STATUS_NONE_UNKNOWN.name]: {
        panel: validationStrings.panel.offenseAttemptedRequiresPropertyStatusNoneUnknown,
    },
    [SystemRuleEnum.OFFENSE_STOLEN_PROPERTY_COMPLETED_PROPERTY_STATUS_NONE_RECOVERED.name]: {
        panel: validationStrings.panel.offenseStolenPropertyCompletedPropertyStatusNoneRecovered,
    },
    [SystemRuleEnum
        .OFFENSE_BNE_ABDUCTION_BRIBERY_COMPLETED_PROPERTY_STATUS_NONE_RECOVERED_STOLEN_UNKNOWN
        .name]: {
        panel:
            validationStrings.panel
                .offenseBneAbductionBriberyCompletedPropertyStatusNoneRecoveredStolenUnknown,
    },
    [SystemRuleEnum.OFFENSE_DRUGS_COMPLETED_PROPERTY_STATUS_NONE_SEIZED.name]: {
        panel: validationStrings.panel.offenseDrugsCompletedPropertyStatusNoneSeized,
    },
    [SystemRuleEnum.OFFENSE_ARSON_COMPLETED_PROPERTY_STATUS_BURNED.name]: {
        panel: validationStrings.panel.offenseArsonCompletedPropertyStatusBurned,
    },
    [SystemRuleEnum.OFFENSE_FORGERY_COMPLETED_PROPERTY_STATUS_FORGED_RECOVERED_SEIZED.name]: {
        panel: validationStrings.panel.offenseForgeryCompletedPropertyStatusForgedRecoveredSeized,
    },
    [SystemRuleEnum.OFFENSE_DAMAGE_COMPLETED_PROPERTY_STATUS_DAMAGED.name]: {
        panel: validationStrings.panel.offenseDamageCompletedPropertyStatusDamaged,
    },
    [SystemRuleEnum.OFFENSE_THEFT_FRAUD_COMPLETED_PROPERTY_STATUS_RECOVERED_STOLEN.name]: {
        panel: validationStrings.panel.offenseTheftFraudCompletedPropertyStatusRecoveredStolen,
    },
    [SystemRuleEnum.OFFENSE_GAMBLING_COMPLETED_PROPERTY_STATUS_SEIZED.name]: {
        panel: validationStrings.panel.offenseGamblingCompletedPropertyStatusSeized,
    },
    [SystemRuleEnum.OFFENSE_PROPERTY_LOSS_DECLARED_VALUE_REQUIRED.name]: {
        panel: validationStrings.panel.offensePropertyLossDeclaredValueRequired,
        field: defaultMessages.field,
    },
    [SystemRuleEnum.OFFENSE_ITEM_CATEGORY_DOCUMENTS_CREDIT_CARDS_DECLARED_VALUE_ZERO.name]: {
        panel: validationStrings.panel.offenseItemCategoryDocumentsCreditCardsDeclaredValueZero,
        field: validationStrings.field.offenseItemCategoryDocumentsCreditCardsDeclaredValueZero,
    },
    [SystemRuleEnum.OFFENSE_ITEM_CATEGORY_VEHICLE_QUANTITY_ONE.name]: {
        panel: validationStrings.panel.offenseItemCategoryVehicleQuantityOne,
        field: validationStrings.field.offenseItemCategoryVehicleQuantityOne,
    },
    [SystemRuleEnum.OFFENSE_MARIJUANA_OPIUM_HALLUCINOGENS_NUMBER_OF_PLANTS.name]: {
        panel: validationStrings.panel.offenseMarijuanaOpiumHallucinogensNumberOfPlants,
        field: validationStrings.field.offenseMarijuanaOpiumHallucinogensNumberOfPlants,
    },
    [SystemRuleEnum.OFFENSE_RAPE_VICTIM_SEX_MALE_FEMALE_REQUIRED.name]: {
        panel: validationStrings.panel.offenseRapeVictimSexMaleFemaleRequired,
    },
    [SystemRuleEnum.PERSON_RAPE_VICTIM_SEX_MALE_FEMALE_REQUIRED.name]: {
        panel: validationStrings.panel.personRapeVictimSexMaleFemaleRequired,
        field: validationStrings.field.personRapeVictimSexMaleFemaleRequired,
    },
    [SystemRuleEnum.OFFENSE_CERTAIN_OFFENSES_IS_DOMESTIC_VIOLENCE_REQUIRED.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.OFFENSE_KIDNAPPING_ABDUCTION_REQUIRES_PROPERTY.name]: {
        panel: validationStrings.panel.offenseKidnappingAbductionRequiresProperty,
    },
    [SystemRuleEnum.OFFENSE_BRIBERY_REQUIRES_PROPERTY.name]: {
        panel: validationStrings.panel.offenseBriberyRequiresProperty,
    },
    [SystemRuleEnum.OFFENSE_BURGLARY_REQUIRES_PROPERTY.name]: {
        panel: validationStrings.panel.offenseBurglaryRequiresProperty,
    },
    [SystemRuleEnum.OFFENSE_DATE_REQUIRED_FOR_LAW_ENFORCEMENT_AS_VICTIM_NIBRS.name]: {
        panel: validationStrings.panel.offenseDateRequiredForLawEnforcementAsVictimNibrs,
    },
    [SystemRuleEnum.USE_OF_FORCE_REPORT_SUBJECT_OR_OFFICER_MUST_HAVE_USED_FORCE.name]: {
        panel: validationStrings.submission.useOfForceReportSubjectOrOfficerMustHaveUsedForce,
    },
    [SystemRuleEnum.TOW_VEHICLE_REQUIRES_OWNER.name]: {
        panel: validationStrings.panel.towVehicleRequiresOwner,
    },
    [SystemRuleEnum.TOW_VEHICLE_OWNER_REQUIRES_PHONE_NUMBER.name]: {
        panel: validationStrings.panel.towVehicleOwnerRequiresPhoneNumber,
    },
    [SystemRuleEnum.TOW_VEHICLE_OWNER_REQUIRES_HOME_ADDRESS.name]: {
        panel: validationStrings.panel.towVehicleOwnerRequiresHomeAddress,
    },
    [SystemRuleEnum.UCR_EVENT_WEAPON_OR_FORCE_INVOLVED_REQUIRED_WHEN_SHOWN.name]: {
        ...defaultMessages,
        panel: validationStrings.panel.ucrEventWeaponOrForceInvolvedRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_EVENT_NUMBER_OF_SENIOR_CITIZEN_VICTIMS_REQUIRED_WHEN_SHOWN.name]: {
        ...defaultMessages,
        panel: validationStrings.panel.ucrEventNumberOfSeniorCitizenVictimsRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_ADDITIONAL_UCR_CODES_MUST_BE_ARSON_HUMAN_TRAFFICKING_OR_HOMICIDE
        .name]: {
        panel:
            validationStrings.panel
                .ucrOffenseAdditionalUcrCodesMustBeArsonHumanTraffickingOrHomicide,
    },
    [SystemRuleEnum.UCR_OFFENSE_ADDITIONAL_UCR_CODE_HOMICIDE_MUST_HAVE_UCR_STATUS_UNFOUNDED.name]: {
        panel:
            validationStrings.panel.ucrOffenseAdditionalUcrCodeHomicideMustHaveUcrStatusUnfounded,
    },
    [SystemRuleEnum.UCR_OFFENSE_ROBBERY_LOCATION_TYPE_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrOffenseRobberyLocationTypeRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_NO_DUPLICATE_CODES.name]: {
        panel: validationStrings.panel.ucrOffenseNoDuplicateCodes,
    },
    [SystemRuleEnum.UCR_OFFENSE_BUILDING_INHABITED_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrOffenseBuildingInhabitedRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_BURGLARY_RESIDENCE_TYPE_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrOffenseBurglaryResidenceTypeRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_BURGLARY_TIME_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrOffenseBurglaryTimeRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_LARCENY_THEFT_CATEGORY_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrOffenseLarcenyTheftCategoryRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_OR_EVENT_CLASSIFICATION_REQUIRED.name]: {
        panel: validationStrings.panel.ucrOffenseOrEventClassificationRequired,
    },
    [SystemRuleEnum.UCR_OFFENSE_ONLY_ONE_LARCENY.name]: {
        panel: validationStrings.panel.ucrOffenseOnlyOneLarceny,
    },
    [SystemRuleEnum.UCR_OFFENSE_BURGLARY_TIME_AND_PLACE.name]: {
        panel: validationStrings.panel.ucrOffenseBurglaryTimeAndPlace,
    },
    [SystemRuleEnum.UCR_OFFENSE_ROBBERY_PLACE.name]: {
        panel: validationStrings.panel.ucrOffenseRobberyPlace,
    },
    [SystemRuleEnum.UCR_PROPERTY_RECOVERY_TYPE_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrPropertyRecoveryTypeRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_PROPERTY_RECOVERY_DATE_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrPropertyRecoveryDateRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_PROPERTY_BURNED_REQUIRE_ONE_UCR_OFFENSE_ARSON.name]: {
        panel: validationStrings.panel.ucrPropertyBurnedRequireOneUcrOffenseArson,
    },
    [SystemRuleEnum.UCR_OFFENSE_LARCENY_AMOUNT_MATCHES_STOLEN_PROPERTY.name]: {
        panel: validationStrings.panel.ucrOffenseLarcenyAmountMatchesStolenProperty,
    },
    [SystemRuleEnum.UCR_PROPERTY_NO_STOLEN_PROPERTY_IF_OFFENSE_IS_ASSAULT.name]: {
        panel: validationStrings.panel.ucrPropertyNoStolenPropertyIfOffenseIsAssault,
    },
    [SystemRuleEnum.UCR_OFFENSE_ONLY_ONE_ARSON_OFFENSE_PER_REPORT.name]: {
        panel: validationStrings.panel.ucrOffenseOnlyOneArsonOffensePerReport,
    },
    [SystemRuleEnum.UCR_SUFFOCATION_OR_STRANGULATION_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrSuffocationOrStrangulationRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_IS_DOMESTIC_VIOLENCE_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrIsDomesticViolenceRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_RELATIONSHIP_CODE_ID_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrOffenseRelationshipCodeIdRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_HOMICIDE_WEAPON_OR_FORCE_INVOLVED_CODE_ID_REQUIRED_WHEN_SHOWN
        .name]: {
        panel: validationStrings.panel.ucrOffenseHomicideWeaponRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_ARSON_LOCATION_CODE_ID_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrOffenseArsonLocationRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_HOMICIDE_CODE_ID_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrOffenseHomicideRequiredWhenShown,
    },
    [SystemRuleEnum.UCR_OFFENSE_NEGLIGENT_MANSLAUGHTER_CODE_ID_REQUIRED_WHEN_SHOWN.name]: {
        panel: validationStrings.panel.ucrOffenseNegligentManslaughterRequiredWhenShown,
    },
    [SystemRuleEnum.CHAIN_EVENT_CHECKED_IN_MAIN_REQUIRES_STORAGE_LOCATION.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CHAIN_EVENT_CHECKED_OUT_LAB_REQUIRES_FACILITY.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CHAIN_EVENT_LOCATION_UPDATED_REQUIRES_STORAGE_LOCATION.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CHAIN_EVENT_SIGNATURE_REQUIRES_SIGNATURE_ENABLED.name]: {
        ...defaultMessages,
        field: validationStrings.field.chainEventSignatureRequiresSignatureEnabled,
    },
    [SystemRuleEnum.CHAIN_EVENT_ID_SCAN_REQUIRES_ID_SCAN_ENABLED.name]: {
        ...defaultMessages,
        field: validationStrings.field.chainEventIdScanRequiresIdScanEnabled,
    },
    [SystemRuleEnum.CHAIN_EVENT_CHECKED_OUT_PERSON_REQUIRES_RECEIVED_BY_NAME.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CHAIN_EVENT_TRANSFERRED_REQUIRES_RECEIVED_BY_NAME.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CHAIN_EVENT_RELEASED_REQUIRES_RECEIVED_BY_NAME.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.CHAIN_EVENT_CHECKED_IN_TEMP_REQUIRES_STORAGE_LOCATION.name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.RETENTION_POLICY_REQUIRES_PERIOD_OR_IS_INFINITE.name]: {
        ...defaultMessages,
        field: validationStrings.field.retentionPolicyRequiresPeriodOrIsInfinite,
    },
    [SystemRuleEnum
        .ITEM_FACILITY_LINK_STORAGE_LOCATION_ID_REQUIRED_FOR_EVIDENCE_WITHOUT_CHAIN_OF_CUSTODY
        .name]: {
        ...defaultMessages,
    },
    [SystemRuleEnum.ITEM_IDENTIFIER_BAG_NUMBER_REQUIRED_FOR_EVIDENCE.name]: {
        ...defaultMessages,
        panel: validationStrings.panel.itemIdentifierBagNumberRequiredForEvidence,
    },
    [SystemRuleEnum.WARRANT_REQUIRES_CHARGES.name]: {
        panel: validationStrings.panel.chargesRequired,
    },
    [SystemRuleEnum.EXPORT_RELEASE_RELEASED_TO_OTHER_REQUIRED.name]: {
        ...defaultMessages,
        panel: validationStrings.panel.exportReleaseReleasedToOtherRequired,
    },
    [SystemRuleEnum.FIELD_CONFIGURATION_ADMIN_MIN_GREATER_THAN_OR_EQUAL_TO_FIELD_DETAIL_MIN.name]: {
        field: validationStrings.field.fieldConfigurationAdminMinGreaterThanOrEqualToFieldDetailMin,
    },
    [SystemRuleEnum.FIELD_CONFIGURATION_ADMIN_MAX_LESS_THAN_OR_EQUAL_TO_FIELD_DETAIL_MAX.name]: {
        field: validationStrings.field.fieldConfigurationAdminMaxLessThanOrEqualToFieldDetailMax,
    },
    [SystemRuleEnum.FIELD_CONFIGURATION_ADMIN_MIN_LESS_THAN_OR_EQUAL_TO_MAX.name]: {
        panel: validationStrings.panel.fieldConfigurationAdminMinLessThanOrEqualToMax,
    },
    [SystemRuleEnum.FIELD_CONFIGURATION_ADMIN_DATE_MIN_MAX_CANNOT_BOTH_BE_NOW.name]: {
        panel: validationStrings.panel.fieldConfigurationAdminDateMinMaxCannotBothBeNow,
    },
};

import React, { useCallback, useEffect } from 'react';
import { pickBy, identity, first } from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import {
    lifecycleOptions,
    Form,
    createFormConfiguration,
    createField,
    formEvents,
    Observer,
} from 'markformythree';
import { AttributeTypeEnum, LinkTypesEnum, EntityTypeEnum } from '@mark43/rms-api';

import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import {
    convertYupErrorShapeToMFTErrorShape,
    convertYupSuccessShapeToMFTSuccessShape,
} from '../../validation/yupMftValidation';

import { MFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';

import { useFormGetter } from '../../forms/hooks/useFormGetter';
import { Button as ArcButton } from '../../components/Button';

import { usePersonSidePanelContext } from '../context';
import { currentReportIdSelector } from '../../../../legacy-redux/selectors/reportSelectors';

const strings = componentStrings.core.PersonInvolvementTypeSelectionForm;
const validationStrings = strings.validations;
const personInvolvementTypeSelectionFormConfigurations = createFormConfiguration({
    subjectTypeAttrId: createField<number | undefined>({}),
});

const initialState = {
    subjectTypeAttrId: undefined,
};

const validationSchema = yup.object().shape({
    subjectTypeAttrId: yup.string().required(validationStrings.required),
});

const validationHandler: Form['props']['onValidate'] = ({ formState, eventType }) => {
    const $form = formState.ui.$form;

    return validationSchema
        .validate(formState.model, { abortEarly: false })
        .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
        .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
};

const FormContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const NextButton = styled(ArcButton)`
    width: 75px;
`;

export const PersonInvolvmentTypeSelectionForm: React.FC<{
    onNavigate: () => void;
    personEntityId?: number;
}> = ({ onNavigate, personEntityId }) => {
    const nameReportLinksWhere = useSelector(nameReportLinksWhereSelector);
    const reportId = useSelector(currentReportIdSelector);
    const { setSelectedLinkTypeBySubjectAttributeId } = usePersonSidePanelContext();
    const { getForm } = useFormGetter();

    const label = useFields(
        fields.NAME_REPORT_LINK_LINK_TYPE_INVOLVED_PERSON_IN_REPORT_SUBJECT_TYPE_ATTR_ID
    ).NAME_REPORT_LINK_LINK_TYPE_INVOLVED_PERSON_IN_REPORT_SUBJECT_TYPE_ATTR_ID;

    const onNext = useCallback(() => {
        const form = getForm(formClientEnum.PERSON_PROFILE_INVOLVEMENT_TYPE);
        if (form) {
            form.submit()
                .then((result) => {
                    const { subjectTypeAttrId } = result.form.getState().model;

                    if (setSelectedLinkTypeBySubjectAttributeId && subjectTypeAttrId) {
                        // @ts-expect-error ignore difference with 'MFTFieldConfigurationBase' vs 'number'
                        setSelectedLinkTypeBySubjectAttributeId(subjectTypeAttrId);
                        onNavigate();
                    }
                })
                .catch(() => {});
        }
    }, [getForm, onNavigate, setSelectedLinkTypeBySubjectAttributeId]);

    useEffect(() => {
        const form = getForm(formClientEnum.PERSON_PROFILE_INVOLVEMENT_TYPE);

        if (!form) {
            return;
        }

        if (!setSelectedLinkTypeBySubjectAttributeId) {
            return;
        }

        // The form was opened for a new person clear the cache
        if (!personEntityId) {
            setSelectedLinkTypeBySubjectAttributeId(undefined);
            form.set('subjectTypeAttrId', undefined);
            return;
        } else {
            const filter = pickBy(
                {
                    reportId,
                    linkType: LinkTypesEnum.INVOLVED_PERSON_IN_REPORT,
                    contextType: EntityTypeEnum.REPORT.name,
                    contextId: reportId,
                    entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    nameId: personEntityId,
                },
                identity
            );

            const personEntitySubjectTypeAttrId = first(nameReportLinksWhere(filter))
                ?.subjectTypeAttrId;

            form.set('subjectTypeAttrId', personEntitySubjectTypeAttrId);
        }
    }, [
        personEntityId,
        getForm,
        nameReportLinksWhere,
        reportId,
        setSelectedLinkTypeBySubjectAttributeId,
    ]);

    return (
        <Form
            configuration={personInvolvementTypeSelectionFormConfigurations}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formClientEnum.PERSON_PROFILE_INVOLVEMENT_TYPE}
            onValidate={validationHandler}
            validationEvents={[
                { eventType: formEvents.FORM_SUBMIT },
                { eventType: formEvents.INPUT_BLUR },
                { eventType: formEvents.INPUT_CHANGE },
            ]}
            initialState={initialState}
            render={() => {
                return (
                    <FormContentWrapper>
                        <MFTAttributeSelect
                            path="subjectTypeAttrId"
                            attributeType={AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE.name}
                            width={240}
                            label={label}
                        />
                        <Observer
                            subscriptions={{
                                subjectTypeAttrId: 'subjectTypeAttrId',
                            }}
                            render={(formModel) => {
                                const hasValue = !!formModel.subjectTypeAttrId;
                                return (
                                    <NextButton
                                        onClick={onNext}
                                        disabled={!hasValue}
                                        variant="solid"
                                    >
                                        {strings.nextButtonText}
                                    </NextButton>
                                );
                            }}
                        />
                    </FormContentWrapper>
                );
            }}
        />
    );
};

import { ReportShortTitle } from '@mark43/rms-api';
import { joinTruthyValues } from '../../../../helpers/stringHelpers';

/**
 * Creates a short display title for a linked report.
 * @param   `Report`-like object (`Report`, `ElasticReport`, `ReportShortTitle`).
 * @return  short display title
 */
export function formatLinkedReportDisplayTitle({
    reportingEventNumber,
    shortTitle,
}: ReportShortTitle): string {
    return joinTruthyValues([reportingEventNumber, shortTitle], ' ');
}

export function formatArrestTitle({
    recordNumber,
    arrestType,
    reportDefDisplayName = 'Arrest',
}: {
    recordNumber: string;
    reportDefDisplayName?: string;
    arrestType: string;
}) {
    return `${reportDefDisplayName} #${recordNumber} ${arrestType && `(${arrestType})`}`;
}

export function formatFieldContactTitle({
    recordNumber,
    reportDefDisplayName = 'Field Contact',
}: {
    recordNumber: string;
    reportDefDisplayName?: string;
}) {
    return `${reportDefDisplayName} #${recordNumber}`;
}

export function formatCommunityInfoTitle({
    recordNumber,
    reportDefDisplayName = 'Community Information',
}: {
    recordNumber: string;
    reportDefDisplayName?: string;
}) {
    return `${reportDefDisplayName} #${recordNumber}`;
}

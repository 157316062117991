import React from 'react';
import { map } from 'lodash';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useSubPremiseFormatters } from '../../../../../modules/core/locations/hooks/useSubPremiseFormatters';

function LocationCell({ cadTicketLocation, cadDisplayName }) {
    const { buildElasticLocationLines } = useSubPremiseFormatters();

    if (!cadTicketLocation) {
        return (
            <div className="elastic-cad-ticket-location">
                {componentStrings.search.AdvancedSearchCadTicketsResults.location.noLocation(
                    cadDisplayName
                )}
            </div>
        );
    }

    return (
        <div className="elastic-cad-ticket-location">
            {map(buildElasticLocationLines(cadTicketLocation), (line) => (
                <div key={line}>{line}</div>
            ))}
        </div>
    );
}

export default function LocationCellWrapper(elasticCadTicket) {
    const { cadTicketLocation } = getViewModelProperties(elasticCadTicket);
    return (
        <LocationCell
            cadTicketLocation={cadTicketLocation}
            cadDisplayName={elasticCadTicket.cadDisplayName}
        />
    );
}

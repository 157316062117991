import React from 'react';
import classNames from 'classnames';
import { PageContent } from './PageContent';

/**
 * All content below a `<Subheader>. Used for pages where the content does not exceed 100% height of the page
 * or where the contained sections are scrollable such as the Admin column pages like "Attributes."
 * Note: this might be eventually merged with `ScrollableUnderSubheader` in the future.
 * Generally use the `ScrollableUnderSubheader` for most pages.
 */
const UnderSubheader: React.FC<{
    fullscreen?: boolean;
    forceNoClassName?: boolean;
    className?: string;
    style?: React.CSSProperties;
}> = ({
    fullscreen = false,
    forceNoClassName = false,
    className = '',
    children,
    style,
    ...props
}) => {
    return (
        <div className={classNames('mark43-page-under-subheader', className)} {...props}>
            <PageContent
                fullscreen={!forceNoClassName && fullscreen}
                useConditionalClassNames={!forceNoClassName}
                style={style}
            >
                {children}
            </PageContent>
        </div>
    );
};

export default UnderSubheader;

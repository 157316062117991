import { omit } from 'lodash';

export const internalFieldsForPhoneNumber = [
    'baseDisplayNumber',
    'hashedCountryCode',
    'initialCountryCode',
] as const;

/**
 * Different Java classes implement `IFlatPhone` and contain `baseDisplayNumber` and `countryCode` as transient
 * properties.
 */
interface Phone {
    baseDisplayNumber?: string;
    countryCode?: number;
}

interface PhoneFormModel {
    baseDisplayNumber?: string;
    initialCountryCode?: string;
}

export function convertPhoneToFormModel(phone: Phone): PhoneFormModel {
    return {
        ...omit(phone, 'countryCode'),
        initialCountryCode: phone.countryCode
            ? // all Country Code fields in our forms are stored as strings, because the countries API endpoint also
              // returns them as strings
              phone.countryCode.toString()
            : undefined,
    };
}

export function convertPhoneFromFormModel(formModel: PhoneFormModel): Phone {
    return omit(formModel, ...internalFieldsForPhoneNumber);
}

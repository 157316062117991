import { ElasticInvolvedLocation } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'elasticInvolvedLocations';
const elasticInvolvedLocationsModule = createNormalizedModule<ElasticInvolvedLocation>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const elasticInvolvedLocationsSelector =
    elasticInvolvedLocationsModule.selectors.entitiesSelector;

// REDUCER
export default elasticInvolvedLocationsModule.reducerConfig;

import _, { isArray, get, map, some, orderBy } from 'lodash';
import {
    Attribute,
    AttributeView,
    AttributeTypeEnumType,
    ElasticAttributeDetail,
} from '@mark43/rms-api';
import { attributeStatuses } from '../configuration';
import globalAttributes from '../../../legacy-constants/globalAttributes';
import { joinTruthyValues } from '../../../../helpers/stringHelpers';
import { getAdminListStatusFromStartEnd } from '../../../dates/utils/dateHelpers';

const { EXPIRED } = attributeStatuses;

const computeDateStatus = getAdminListStatusFromStartEnd;

/**
 * @return  Display abbreviation.
 */
export function formatAttributeAbbrev(attribute?: Attribute | AttributeView): string | undefined {
    return _.get(attribute, 'abbr') || _.get(attribute, 'displayAbbreviation');
}

/**
 * @return  Display value.
 */
export function formatAttributeValue(
    attribute?: Attribute | AttributeView | ElasticAttributeDetail
): string | undefined {
    return _.get(attribute, 'val') || _.get(attribute, 'displayValue');
}

/**
 * @return  Display abbreviation and value.
 */
export function formatAttributeAbbrevAndValue(attribute?: Attribute | AttributeView) {
    return `${formatAttributeAbbrev(attribute)} - ${formatAttributeValue(attribute)}`;
}

/**
 * Format an attribute for display in summary mode. Intentionally
 * leaves off "(expired)" that you see in edit mode via mapAttributeToOption.
 */
export function formatAttribute(
    attribute?: Attribute | AttributeView | ElasticAttributeDetail,
    parentAttribute?: Attribute | AttributeView
) {
    const display = formatAttributeValue(attribute);
    const parentDisplay = formatAttributeValue(parentAttribute);
    return parentDisplay ? `${parentDisplay} - ${display}` : display;
}

/**
 *  Attribute ids indexed by attributeType.
 */
export function groupAttributeIdsByType(
    attributes: Attribute[]
): {
    [x: string]: Attribute[];
} {
    return _(attributes)
        .groupBy('attributeType')
        .mapValues((ids) => _(ids).map('attributeId').value())
        .value();
}

export interface AttributeViewModel extends AttributeView {
    status?: keyof typeof attributeStatuses;
}

export function buildAttributeViewModels(attributes: AttributeView[]): AttributeViewModel[] {
    const now = new Date();

    return map(attributes, (attribute) => ({
        ...attribute,
        status: computeDateStatus(attribute.start, attribute.end, now),
    }));
}

/**
 * Format the given attributeView as a dropdown/checkbox option.
 * @param    attribute May be a raw attribute or an attribute view
 *   model.
 * @param   [formatAttributeById] For formatting the parent attribute,
 *   if one exists.
 * @param   [includeAbbr=false] Whether to show each attribute's code
 *   before its display name.
 */
function mapAttributeToOption(
    {
        id,
        val,
        status,
        parentId,
        abbr,
        none,
        deptId,
        departmentId,
        other,
    }: AttributeViewModel & { departmentId?: number },
    formatAttributeById: (attrId: number) => string,
    includeAbbr = false
) {
    return {
        value: id,
        other,
        display: includeAbbr ? `${abbr} - ${val}` : val,
        departmentId: deptId || departmentId,
        ...(status === EXPIRED
            ? {
                  status,
                  noteDisplay: '(expired)',
              }
            : {}),
        ...(parentId
            ? {
                  group: formatAttributeById ? formatAttributeById(parentId) : parentId.toString(),
              }
            : {}),
        none,
        parentId,
    };
}

/**
 * Format the given attribute views as dropdown/checkbox options. They are sorted by
 *   status (active/scheduled followed by expired) and then by display string.
 * @param   attributes    May be raw attributes or view models.
 * @param   [includeAbbr] Whether to show each attribute's code before
 *   its display name.
 * @param   sortByIteratees An array of strings referencing properties of an
 * AttributeViewModel to sort by, default ['type', 'val']
 */
export function mapAttributesToOptions(
    attributes: AttributeViewModel[],
    formatAttributeById: (attrId: number) => string,
    includeAbbr?: boolean,
    sortByIteratees: (keyof AttributeViewModel)[] = ['type', 'val']
) {
    return _(attributes)
        .sortBy([({ status }) => (status === EXPIRED ? 2 : 1), ...sortByIteratees])
        .map((option) => mapAttributeToOption(option, formatAttributeById, includeAbbr))
        .value();
}

/**
 * Filter the given attributes down to only attributes of the given type(s),
 *   normalized.
 * @param  attributes
 * @param  [type] If not provided, all the attributes are
 *   included.
 * @return  Attributes keyed by id.
 */
export function filterAttributesByType(
    attributes: AttributeView[],
    type?: AttributeTypeEnumType | AttributeTypeEnumType[]
) {
    return !isArray(type)
        ? _(attributes).filter({ type }).mapKeys('id').value()
        : _(type)
              .map((t) => _.filter(attributes, { type: t }))
              .flatten()
              .mapKeys('id')
              .value();
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function formatAttributeWithOther(attribute: string, other: any, joinWith = ': ') {
    return joinTruthyValues([attribute, other], joinWith);
}

/**
 * Get all global attribute ids.
 * Relies on the fact that global attribute values are nested 1 level deep in
 * `globalAttributes`.
 */
export const globalAttributeIds = _(globalAttributes)
    .map((globalAttrType) => map(globalAttrType))
    .flatten()
    .value();

export const someAttributesHaveGlobalParentAttribute = (
    attributes: AttributeView[],
    globalAttributeId: number
) => some(attributes, (attribute) => get(attribute, 'parentId') === globalAttributeId);

/**
 * This function denormalizes properties on an Attribute by also including certain
 *   values on the key they would belong to on an AttributeView. This is done so that
 *   helper methods which are used on AttributeViews in the rms can be re-used to
 *   work for actual Attribute models.
 */
export function convertAttributeToAttributeView(attribute: Attribute): AttributeView {
    return {
        ...attribute,
        // below are key/value pairs of an AttributeView
        // these are asserted because there's a swagger bug where they are listed as optional
        abbr: attribute.displayAbbreviation as string,
        enabled: attribute.isEnabled,
        none: attribute.isNone,
        other: attribute.isOther,
        start: attribute.startDateUtc,
        end: attribute.endDateUtc,
        type: attribute.attributeType,
        val: attribute.displayValue as string,
        parentId: attribute.parentAttributeId,
        deptId: attribute.departmentId,
        def: attribute.isDefault,
    };
}

export function convertAttributeToElasticAttributeDetail(
    attribute: Attribute
): ElasticAttributeDetail {
    return {
        // asserted because of swagger bug where they are listed as optional
        displayValue: attribute.displayValue as string,
        id: attribute.id,
        type: attribute.attributeType,
    };
}

export function formatElasticAttributes(attributes: ElasticAttributeDetail[]) {
    return orderBy(attributes, ['type', 'displayValue'])
        .map(formatAttributeValue)
        .filter(Boolean)
        .join(', ');
}

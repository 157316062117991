import styled from 'styled-components';

export const SectionTitle = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: var(--arc-fontSizes-md);
    text-transform: uppercase;
    line-height: 1;
    padding-bottom: 6px;
    border-bottom: 6px solid ${(props) => props.theme.colors.lightGrey};
`;

export const Container = styled.div`
    margin-top: 24px;
    background-clip: padding-box;
    font-size: var(--arc-fontSizes-sm);
    line-height: 15px;

    .mark43-tab-link {
        text-transform: uppercase;
    }
`;

export const ReportLinkContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
`;

export const BodyHeavy = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.highContrastGrey};
`;

export const BodyItalic = styled.div`
    font-style: italic;
    color: ${(props) => props.theme.colors.highContrastGrey};
`;

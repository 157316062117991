import { TrafficCrashAttribute } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import createLinkModule from '../../../../utils/createLinkModule';
import { trafficCrashByReportIdSelector } from '../../../traffic-crashes/state/data';

export const NEXUS_STATE_PROP = 'trafficCrashAttributes';
const trafficCrashAttributeModule = createLinkModule<TrafficCrashAttribute>({
    type: NEXUS_STATE_PROP,
    keys: ['crashId', 'attributeId', 'attributeType'],
});

// ACTIONS

// SELECTORS

const trafficCrashAttributesWhereSelector =
    trafficCrashAttributeModule.selectors.linksWhereSelector;

export const trafficCrashAttributesByReportIdSelector = createSelector(
    trafficCrashAttributesWhereSelector,
    trafficCrashByReportIdSelector,
    (trafficCrashAttributesWhere, trafficCrashByReportId) => (reportId: number) => {
        const trafficCrash = trafficCrashByReportId(reportId);
        const crashId = trafficCrash?.id;
        return trafficCrashAttributesWhere({ crashId });
    }
);

// REDUCER
export default trafficCrashAttributeModule.reducerConfig;

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

const EVIDENCE_URL = '/evidence/api';

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Printables Resource',
        methods: {
            getCasePrintables(caseId, { excludeFoldered, includeNestedFolders }) {
                return req({
                    method: 'GET',
                    url: `print/case/printables/${caseId}`,
                    params: {
                        exclude_foldered: excludeFoldered,
                        include_nested: includeNestedFolders,
                    },
                });
            },

            getReportPrintables(reportId) {
                return req({
                    method: 'GET',
                    url: `print/report/printables/${reportId}`,
                });
            },

            getReportDefinitionPrintables(reportDefinitionIds) {
                return req({
                    method: 'GET',
                    url: 'print/report/definition/printables',
                    params: {
                        ids: reportDefinitionIds,
                    },
                });
            },

            getWarrantPrintables(warrantId) {
                return req({
                    method: 'POST',
                    url: `print/warrant/printables/${warrantId}`,
                });
            },

            getPersonProfilePrintables(personProfileId) {
                return req({
                    method: 'POST',
                    url: `print/person_profile/printables/${personProfileId}`,
                });
            },

            getPhotoLineupPrintables(id) {
                return req({
                    method: 'GET',
                    url: `print/photo_lineup/printables/${id.caseId}/${id.photoLineupId}`,
                });
            },

            // DEPRECATED: use evidencePrintablesResource. KRA-1794 will remove this.
            getChainOfCustodyPrintables(masterItemId) {
                return req({
                    method: 'GET',
                    baseUrl: EVIDENCE_URL,
                    url: `evidence/print/item/${masterItemId}/printables`,
                });
            },
        },
    });
}

/**
 * Resources for locations. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

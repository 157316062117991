import {
    LinkTypesEnumType,
    ConfiguredFormQueryConfigurationView,
    EntityTypeEnumType,
} from '@mark43/rms-api';
import { assertNotNullOrUndefined } from '../../core/utils/assert-not-null-or-undefined';
import { assertValidLinkTypeEnumValue } from '../utils/assert-valid-link-type-enum-value';
import { reverseLookupEntityTypeEnum } from '../utils/reverse-lookup-enums';
import { useResolvedQueryConfigurationValues } from './use-resolved-query-configuration-values';

export function useResolvedNameReportLinkQueryConfigurationValues(
    configuredFormQueryConfigurationViews: ConfiguredFormQueryConfigurationView[]
): {
    reportId: number;
    contextType: EntityTypeEnumType;
    contextId: number;
    entityType: EntityTypeEnumType;
    linkType: LinkTypesEnumType;
} {
    // Looking up query configurations in order to map them to properties for `NameSummarViewWrapper` has to rely on string naming
    // of known properties for name report links. We do not have another way of looking these up and this means
    // that we will hard fail, if a name report link form is not properly configured with all the require query configurations.
    return useResolvedQueryConfigurationValues(configuredFormQueryConfigurationViews, {
        reportId: (data) => {
            const reportId = Number(data['reportId']);
            if (isNaN(reportId)) {
                throw new Error('Unexpectedly unable to resolve report id for name report link');
            }
            return reportId;
        },
        contextType: (data) => {
            const rawContextType = data['contextType'];
            assertNotNullOrUndefined(rawContextType, 'Context type configuration missing');

            const contextType = reverseLookupEntityTypeEnum[rawContextType];
            assertNotNullOrUndefined(
                rawContextType,
                'Unexpectedly unable to resolve context type value for name report link'
            );
            return contextType;
        },
        entityType: (data) => {
            const rawEntityType = data['entityType'];
            assertNotNullOrUndefined(rawEntityType, 'Entity type configuration missing');

            const entityType = reverseLookupEntityTypeEnum[rawEntityType];
            assertNotNullOrUndefined(
                entityType,
                'Unexpectedly unable to resolve configured entity type for name report link'
            );

            return entityType;
        },
        contextId: (data) => {
            const contextId = Number(data['contextId']);
            if (isNaN(contextId)) {
                throw new Error('Unexpectedly unable to resolve context id for name report link');
            }
            return contextId;
        },
        linkType: (data) => {
            const linkType = Number(data['linkType']);
            assertValidLinkTypeEnumValue(linkType);
            return linkType;
        },
    });
}

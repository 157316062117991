import PropTypes from 'prop-types';
import React from 'react';
import { IconButton, Tooltip } from 'arc';
import { useDispatch } from 'react-redux';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';

import testIds from '../../../../core/testIds';
import Subheader from '../../../core/components/Subheader';
import { exportInventory } from '../state/ui';

const InventoryNavigation = ({ className, returnToUrl, viewModel }) => {
    const { createdDateUtc, inventoryType } = viewModel;
    const dispatch = useDispatch();
    const strings =
        componentStrings.evidence.inventory.InventoryNavigation[inventoryType] ||
        componentStrings.evidence.inventory.InventoryNavigation.none;

    const { storageLocationName } = getViewModelProperties(viewModel);

    const title = (formattedDate) =>
        joinTruthyValues([strings.title, formattedDate, storageLocationName], ' ');

    return (
        <div className={className}>
            <FormattedDate
                date={createdDateUtc}
                format={FormattedDate.FORMATS.FORM_DATE}
                children={(formattedDate) => (
                    <Subheader
                        title={title(formattedDate)}
                        testId={testIds.SUBHEADER_TITLE}
                        backButtonTo={returnToUrl}
                    >
                        <Tooltip content={strings.export} delayDuration={500} side="bottom">
                            <IconButton
                                icon="Export"
                                size="md"
                                onClick={() => {
                                    dispatch(exportInventory(viewModel.id, title(formattedDate)));
                                }}
                            />
                        </Tooltip>
                    </Subheader>
                )}
            />
        </div>
    );
};

InventoryNavigation.propTypes = {
    className: PropTypes.string,
    returnToUrl: PropTypes.string,
    viewModel: PropTypes.object.isRequired,
};

InventoryNavigation.defaultProps = {
    className: '',
    returnToUrl: '/',
};

export default InventoryNavigation;

import React from 'react';
import { arbiterMFTInput } from '../../../../core/arbiter';
import { Editor, EditorProps } from '../../../../core/editor/components/TinyEditor';

// The Editor is the source of truth, and changes are propagated to the form state.
// We expect the value to come in for the onChange to already be in a serialized html string
// safe to store in the form state.
class TinyNarrativeCardEditorWithoutArbiter extends React.Component<EditorProps> {
    constructor(props: EditorProps, ...rest: never[]) {
        super(props, ...rest);
    }

    // When making updates to local state,
    // always sync them back out to `markformythree`
    onChange = (value: string): void => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }

        // call context handler (m43)
        if (this.context.onInputChange) {
            this.context.onInputChange(value);
        }
    };

    render() {
        return (
            <Editor
                id={this.props.id}
                value={this.props.value}
                form={this.props.form}
                onChange={this.onChange}
                setError={this.props.setError}
                className={this.props.className}
                hideNarrativeGuide={this.props.hideNarrativeGuide}
                mentionsEnabled={this.props.mentionsEnabled}
                inlineCommentsEnabled={this.props.inlineCommentsEnabled}
                setCurrentAutosaveValue={this.props.setCurrentAutosaveValue}
                testId={this.props.testId}
                fieldName={this.props.fieldName}
                editorRef={this.props.editorRef}
                getSummaryMode={this.props.getSummaryMode}
                onValueChange={this.props.onValueChange}
                onToggleComments={this.props.onToggleComments}
                onFullScreenToggle={this.props.onFullScreenToggle}
                setEditor={this.props.setEditor}
                entityId={this.props.entityId}
                entityType={this.props.entityType}
                imagesEnabled={this.props.imagesEnabled as false}
            />
        );
    }
}

export const TinyArbiterMFTNarrativeCardEditor = arbiterMFTInput(
    TinyNarrativeCardEditorWithoutArbiter
);

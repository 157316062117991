import * as React from 'react';
import {
    LocationEntityLinkUiOptions,
    NameReportLinkUiOptions,
    NitemsUiOptions,
} from 'dragon-react';
import { get } from 'lodash';
import { DragonConfiguredPropertyAliasContextProvider } from '../../context/dragon-configured-property-alias';
import { DragonInlineFormReferenceState, RMSDragonConfigurationExtensions } from '../../rms-types';
import { resolveAliasValues } from '../../utils/resolve-alias-values';
import { useCurrentFormInitialValue } from '../../hooks/use-current-form-initial-value';
import { useDragonInlineFormConfigurationContext } from '../../context/dragon-inline-form-configuration';
import { assertNotNullOrUndefined } from '../../../core/utils/assert-not-null-or-undefined';
import { DragonFormNestingPathContextProvider } from '../../context/dragon-current-form';
import {
    getCoreModelOrDragonInstanceId,
    getMetaInstanceView,
} from '../../utils/dragon-instance-ids';

/**
 * Separate wrapper component for summary mode. This is required because in summary mode
 * we have to look up values in the initial state of our forms, whereas in form mode
 * we will look up values based on the live form state. This is because entities, e.g. nitems instances
 *  are added and removed at runtime. If we used the same wrapper for both modes
 * we would fail alias resolution for new values. It is important to note that component's implementation
 * relies on the fact that static initial values are being updated after a form transitions from edit
 * to summary mode.
 *
 * If we ever get into a situation where users add new instances to a form, do not persist it and manage to
 * change back to summary mode, then this component will throw an error as those instance values will be
 * missing from the initial, static form state.
 */
export function InlineFormReferenceSummaryWrapper({
    options,
    children,
}: React.PropsWithChildren<{
    options:
        | NitemsUiOptions<RMSDragonConfigurationExtensions>
        | NameReportLinkUiOptions<RMSDragonConfigurationExtensions>
        | LocationEntityLinkUiOptions<RMSDragonConfigurationExtensions>;
}>): JSX.Element | null {
    const currentFormInitialValue = useCurrentFormInitialValue();
    const indexedInlineForms = useDragonInlineFormConfigurationContext();
    const formConfiguration = indexedInlineForms[options.configuration.formConfigurationId];
    assertNotNullOrUndefined(
        formConfiguration,
        `Unexpectedly did not find configuration for inline form with id "${options.configuration.formConfigurationId}"`
    );
    const { configuredFormPropertyAliasViews } = formConfiguration;
    const formValue = get(
        currentFormInitialValue.values,
        options.fullyQualifiedPath
    ) as DragonInlineFormReferenceState;
    const meta = getMetaInstanceView(formValue);
    const { configuredEntityPropertyValues } = meta;

    return (
        <DragonFormNestingPathContextProvider
            instanceId={getCoreModelOrDragonInstanceId({ __meta: meta })}
            configuredFormId={formConfiguration.id}
            referencingUiConfigurationId={options.configuration.id}
        >
            <DragonConfiguredPropertyAliasContextProvider
                value={resolveAliasValues({
                    configuredEntityPropertyValues,
                    configuredFormPropertyAliasViews,
                })}
            >
                {children}
            </DragonConfiguredPropertyAliasContextProvider>
        </DragonFormNestingPathContextProvider>
    );
}

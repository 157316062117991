import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { createSelector, createStructuredSelector } from 'reselect';
import { get, invert, compact, omit } from 'lodash';
import { combineReducers } from 'redux';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { caseStatusGlobalAttrIdToChildAttrIdsSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { createElasticCasesResultsSelector } from '~/client-common/core/domain/elastic-cases/state/data';

import {
    globalSequenceNumberLabelSelector,
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
} from '~/client-common/core/fields/state/config';
import {
    CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID,
    DISPLAY_CASE_ASSIGNEE,
    DISPLAY_CASE_SUPERVISORS,
    DISPLAY_ONLY_CASE,
    DISPLAY_ONLY_CASE_SEARCH_INCLUDE_ASSISTING_INVESTIGATORS_LABEL,
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import { casesSearchQueryParamDefaults } from '../../../core/configuration';
import { currentDepartmentIdForUserInConsortiumSelector } from '../../../../core/current-user/state/ui';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import searchResource from '../../../../../legacy-redux/resources/searchResource';
import allCasesSearchForm, {
    allCasesSearchFormFieldViewModels,
    convertAllCasesSearchElasticQueryToFormModel,
} from '../forms/allCasesSearchForm';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../../search/core/state/ui';

const UPDATE_TAB_COUNTS = 'CASES_UPDATE_TAB_COUNTS';

const strings = componentStrings.cases.allCases.AllCases.tabs;
const fieldsetStrings = componentStrings.cases.allCases.AllCasesSearchCaseInformationFieldset;

export const pathParamToSearchTab = {
    open: globalAttributes.caseStatus.open,
    inactive: globalAttributes.caseStatus.inactive,
    closed: globalAttributes.caseStatus.closed,
};

const parentAttributeIdAliasMap = {
    [globalAttributes.caseStatus.unfounded]: globalAttributes.caseStatus.closed,
};

export const searchTabToPathParam = invert(pathParamToSearchTab);

const elasticCaseQueryToGlobalCaseStatusAttrIdSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (elasticCaseQuery) => {
        const parentId = parentAttributeIdByAttributeId(
            get(elasticCaseQuery, 'currentStatusAttrIds.[0]')
        );
        // this check is required because we cannot rely on the fact that the first status is
        // the right one. This only worked as long as we stored searches locally. By storing
        // them remote, JS arrays get converted to sets, losing ordering.
        // This map aids us in mapping the "wrong" global parent back to the intended one.
        //
        // An example for this is that the include the "unfounded" cases attribute for the
        // "closed" cases tab. We also include "Exceptionally Cleared", but this attribute
        // properly maps to the global case status attribute "closed" - "unfounded" does not.
        // Because of this we need to manually map "unfounded" back to the "right"
        // category for this use case.
        return parentAttributeIdAliasMap[parentId] || parentId;
    }
);

const casesBaseSelector = (state) => state.ui.cases.allCases;

const casesSearchSelector = createSelector(casesBaseSelector, (baseState) => baseState.search);

const casesUiSelector = createSelector(casesBaseSelector, (baseState) => baseState.ui);

export const casesDashboardTabNameToCountSelector = createSelector(
    casesUiSelector,
    (uiState) => uiState.tabNameToCount
);

export const allCasesSearch = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.CASE.name,
    baseUiSelector: casesSearchSelector,
    searchResultToRoutePath: ({ id }) => `/cases/${id}/summary`,
    form: allCasesSearchForm,
    elasticQueryToFilterGroups: convertAllCasesSearchElasticQueryToFilterGroups,
    loadSearchExportPrintablesResourceMethod: searchResource.getCasesPrintables,
    resourceMethod: elasticSearchResource.searchCases,
    defaultTableState: {
        from: casesSearchQueryParamDefaults.FROM,
        size: casesSearchQueryParamDefaults.SIZE,
        // specify the default sort because it's not relevance sort
        sortKey: sortKeyEnum.CASE_ASSIGNED_DATE_UTC,
        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
        activeColumnKeys: {
            date: 'assignedDateUtc',
            caseInfo: 'caseNumberName',
            personnel: 'investigators',
            location: 'primaryLocation',
        },
    },
    resultsContainerClassName: 'all-cases-results-container',
    createResultsViewModelsSelector: createElasticCasesResultsSelector,
    selectorToBind: createStructuredSelector({
        globalSequenceNumberLabel: globalSequenceNumberLabelSelector,
        combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        formatFieldByName: formatFieldByNameSelector,
        elasticCaseQueryToGlobalCaseStatusAttrId: elasticCaseQueryToGlobalCaseStatusAttrIdSelector,
    }),
});

export function submitAllCasesSearchForm(options = {}) {
    return (dispatch) =>
        dispatch(
            allCasesSearch.form.actionCreators.submit((formData) => {
                const searchResult = dispatch(
                    allCasesSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        {
                            cacheBust: true,
                            ...options,
                        }
                    )
                );
                dispatch(updateCasesTabCounts(formData));
                return searchResult;
            })
        );
}

export function resetAllCasesSearch() {
    return (dispatch) => dispatch(allCasesSearch.actionCreators.resetState());
}

export function resetAllCasesSearchForm() {
    return (dispatch, getState) => {
        // keep current currentStatusAttrIds, as it determines which results tab we're on (Open, Inactive, or Closed)
        const state = getState();
        dispatch(
            allCasesSearchForm.actionCreators.change({
                currentStatusAttrIds: allCasesSearchForm.selectors.formModelByPathSelector(state)(
                    'caseInformation.currentStatusAttrIds'
                ),
                departmentIds: [currentDepartmentIdForUserInConsortiumSelector(state)],
            })
        );
    };
}

/**
 * Set the Current Status Attributes and Current User Department Id (If they are members of a consortium)
 *  to the form model
 * @param  {string}   caseStatusAttrIds the case status attribute ids to load form model with
 * @return {function}
 */
export function setCurrentStatusAndUserDepartmentToForm(caseStatusAttrIds) {
    return (dispatch, getState) => {
        const state = getState();
        const currentUserDepartmentId = [currentDepartmentIdForUserInConsortiumSelector(state)];
        dispatch(
            allCasesSearchForm.actionCreators.change({
                ...get(allCasesSearch.selectors.currentQuerySelector(state), 'elasticQuery', {}),
                currentStatusAttrIds: caseStatusAttrIds,
                departmentIds: currentUserDepartmentId,
            })
        );
    };
}

export const currentElasticQueryCaseStatusGlobalAttrIdSelector = createSelector(
    allCasesSearch.selectors.currentQuerySelector,
    elasticCaseQueryToGlobalCaseStatusAttrIdSelector,
    ({ elasticQuery }, elasticCaseQueryToGlobalCaseStatusAttrId) =>
        elasticCaseQueryToGlobalCaseStatusAttrId(elasticQuery)
);

/**
 * Make the default search for all cases. This action is meant to be
 *   dispatched when the all cases route is entered.
 * @param  {string}   pathParam the path parameter which indicates which tab is selected
 * @return {function}
 */
export function searchInitialAllCases(pathParam) {
    return function (dispatch, getState) {
        dispatch(allCasesSearch.actionCreators.loadAndExecuteLatestAutoSavedSearch()).then(
            (result) => {
                if (!result) {
                    const caseStatusAttrIds = caseStatusGlobalAttrIdToChildAttrIdsSelector(
                        getState()
                    )[pathParamToSearchTab[pathParam]];
                    dispatch(setCurrentStatusAndUserDepartmentToForm(caseStatusAttrIds));
                    dispatch(submitAllCasesSearchForm({ autoSave: false }));
                } else {
                    dispatch(
                        updateCasesTabCounts(
                            allCasesSearchForm.selectors.formModelSelector(getState())
                        )
                    );
                }
            }
        );
    };
}

const updateCasesTabCounts = (formData) => (dispatch, getState) => {
    const elasticQuery = {
        ...omit(allCasesSearch.form.convertFromFormModel(formData), 'currentStatusAttrIds'),
    };
    const transformedQuery = transformElasticQueryIdsToDisplayValuesSelector(getState())(
        elasticQuery
    );
    elasticSearchResource
        .searchCasesByCaseStatusAggregate(transformedQuery)
        .then((searchResults) => {
            dispatch({
                type: UPDATE_TAB_COUNTS,
                payload: get(searchResults, 'buckets', {}),
            });
        });
};

/**
 * Based on the given elastic query, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object}   state
 * @return {Object}   Array of filter group view models.
 */
function convertAllCasesSearchElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    const combinedSubdivisionsLabel = boundSelectors.combinedSubdivisionsLabel;
    const globalSequenceNumberLabel = boundSelectors.globalSequenceNumberLabel;
    const renLabel = boundSelectors.formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
    const caseStatusGlobalAttrId =
        boundSelectors.elasticCaseQueryToGlobalCaseStatusAttrId(elasticQuery);
    const formatFieldByName = boundSelectors.formatFieldByName;

    const caseLabel = formatFieldByName(DISPLAY_ONLY_CASE);

    let tabString;
    switch (caseStatusGlobalAttrId) {
        case globalAttributes.caseStatus.open:
            tabString = strings.open;
            break;
        case globalAttributes.caseStatus.closed:
            tabString = strings.closed;
            break;
        case globalAttributes.caseStatus.inactive:
            tabString = strings.inactive;
            break;
        default:
            tabString = undefined;
    }
    if (tabString) {
        tabString = `${tabString}, `;
    }

    const formModel = convertAllCasesSearchElasticQueryToFormModel(elasticQuery);

    const fieldViewModels = {
        ...allCasesSearchFormFieldViewModels,
        personnel: {
            ...allCasesSearchFormFieldViewModels.personnel,
            fields: {
                ...allCasesSearchFormFieldViewModels.personnel.fields,
                assigneeRoleIds: {
                    ...allCasesSearchFormFieldViewModels.personnel.fields.assigneeRoleIds,
                    label: formatFieldByName(DISPLAY_CASE_ASSIGNEE),
                },
                includeAssistingInvestigators: {
                    ...allCasesSearchFormFieldViewModels.personnel.fields
                        .includeAssistingInvestigators,
                    label: formatFieldByName(
                        DISPLAY_ONLY_CASE_SEARCH_INCLUDE_ASSISTING_INVESTIGATORS_LABEL
                    ),
                },
                supervisorRoleIds: {
                    ...allCasesSearchFormFieldViewModels.personnel.fields.supervisorRoleIds,
                    label: formatFieldByName(DISPLAY_CASE_SUPERVISORS),
                },
                assignedPersonnelUnitAttrIds: {
                    ...allCasesSearchFormFieldViewModels.personnel.fields
                        .assignedPersonnelUnitAttrIds,
                    label: formatFieldByName(CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID),
                },
            },
        },
        caseInformation: {
            ...allCasesSearchFormFieldViewModels.caseInformation,
            title: fieldsetStrings.title(caseLabel),
            fields: {
                ...allCasesSearchFormFieldViewModels.caseInformation.fields,
                startDateUtc: {
                    ...allCasesSearchFormFieldViewModels.caseInformation.fields.startDateUtc,
                    label: formatFieldByName(DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL),
                },
                reportIdentifier: {
                    ...allCasesSearchFormFieldViewModels.caseInformation.fields.reportIdentifier,
                    label: globalSequenceNumberLabel,
                },
                reportingEventNumber: {
                    ...allCasesSearchFormFieldViewModels.caseInformation.fields
                        .reportingEventNumber,
                    label: renLabel,
                },
                subdivisionAttrIds: {
                    ...allCasesSearchFormFieldViewModels.caseInformation.fields.subdivisionAttrIds,
                    label: combinedSubdivisionsLabel,
                },
            },
        },
    };

    return compact([
        tabString ? { label: strings.tabLabel, display: tabString, title: tabString } : undefined,
        ...convertFormModelToFilterGroups(
            {
                personnel: formModel.personnel,
                caseInformation: omit(formModel.caseInformation, 'currentStatusAttrIds'),
            },
            fieldViewModels,
            formatFieldValue,
            formatFieldByName
        ),
    ]);
}

export const updateCasesTabCountsAfterExecutingSavedSearch = (executionResult) => {
    return function (dispatch, getState) {
        if (executionResult) {
            return dispatch(
                updateCasesTabCounts(allCasesSearchForm.selectors.formModelSelector(getState()))
            );
        }
    };
};

const initialUiState = {
    tabNameToCount: {},
};

function uiReducer(state = initialUiState, action) {
    switch (action.type) {
        case UPDATE_TAB_COUNTS:
            return {
                ...state,
                tabNameToCount: action.payload,
            };
        default:
            return state;
    }
}
/** @typedef {import('redux').Reducer} Reducer */
/**
 * Redux module for all cases search.
 * TODO export members of this module individually
 * @type {Reducer<any, any>}
 */
export default combineReducers({
    search: allCasesSearch.reducers.uiReducer,
    ui: uiReducer,
});

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    formName,
    createTowVehicleReleaseForm,
    buildTowVehicleReleaseCardFormModel,
} from '../../state/forms/towVehicleReleaseForm';
import { registerForm } from '../../state/ui';
import withCard from '../../utils/withCard';
import towVehicleReleaseCard from '../../state/ui/towVehicleReleaseCard';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import testIds from '../../../../../core/testIds';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import TowVehicleReleaseCardSummary from './TowVehicleReleaseCardSummary';

class TowVehicleReleaseCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            buildTowVehicleReleaseCardFormModel,
            reportId,
        } = this.props;

        const form = createTowVehicleReleaseForm({
            initialState: buildTowVehicleReleaseCardFormModel({ reportId }),
            arbiter,
            formatFieldByName,
        });

        registerForm({ form });
        // Purposely not registering this card within the `cardsRegistry`, because this Report Card
        // uniquely does not have an edit mode or save functionality.
    }

    componentWillUnmount() {
        formsRegistry.unregister(formName);
    }

    render() {
        const { card = {}, reportId, reportById, currentReportCardUITitleByType } = this.props;
        const { anchor } = card;

        const renForRecents = get(reportById(reportId), 'reportingEventNumber');
        const cardTitle = currentReportCardUITitleByType(reportCardEnum.RELEASE_INFORMATION.id);

        return (
            <Card
                className={anchor}
                anchor={anchor}
                title={cardTitle}
                testId={testIds.RELEASE_INFORMATION_CARD}
                renderContent={() => {
                    return (
                        <>
                            <TowVehicleReleaseCardSummary reportId={reportId} />
                            <CardSection
                                testId={testIds.RELEASE_INFORMATION_CARD_RELEASE_TO_SECTION}
                                fieldName={
                                    fields.NAME_REPORT_LINK_LINK_TYPE_TOW_VEHICLE_RELEASE_TO_LINK_TYPE
                                }
                            >
                                <NameSummaryViewWrapper
                                    renForRecents={renForRecents}
                                    summaryMode={true}
                                    reportId={reportId}
                                    contextType={EntityTypeEnum.REPORT.name}
                                    contextId={reportId}
                                    parentEntityType={EntityTypeEnum.REPORT.name}
                                    parentId={reportId}
                                    linkType={LinkTypesEnum.TOW_VEHICLE_RELEASE_TO}
                                />
                            </CardSection>
                            <CardSection
                                testId={testIds.RELEASE_INFORMATION_CARD_AUTHORIZED_BY_SECTION}
                                fieldName={
                                    fields.NAME_REPORT_LINK_LINK_TYPE_TOW_VEHICLE_RELEASE_AUTHORIZED_BY_LINK_TYPE
                                }
                            >
                                <NameSummaryViewWrapper
                                    renForRecents={renForRecents}
                                    summaryMode={true}
                                    reportId={reportId}
                                    contextType={EntityTypeEnum.REPORT.name}
                                    contextId={reportId}
                                    parentEntityType={EntityTypeEnum.REPORT.name}
                                    parentId={reportId}
                                    linkType={LinkTypesEnum.TOW_VEHICLE_RELEASE_AUTHORIZED_BY}
                                />
                            </CardSection>
                        </>
                    );
                }}
                // No `edit` pencil for the card.  All card editing is done via the `Tow Vehicle Release` side panel.
                // No `save` for the card either.  All validation is done in the `Tow Vehicle Release` side panel.  We
                // explicitly do not run any validation for the card, even if the Side Panel is unfortunately left in
                // an invalid state.  This is because two separate user groups interact with the report -- officers will
                // fill out the report, while later, a different user may actually _release_ the vehicle.  This may happen
                // well after report approval -- therefore, we usually will not be able to validate the `Tow Vehicle Release`
                // card upon report submission.
                onEdit={undefined}
                canEdit={undefined}
                canEditErrorMessage={undefined}
                errors={[]}
                summaryMode={true}
                saving={false}
                onSave={undefined}
            />
        );
    }
}

class TowVehicleReleaseCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => (
                    <TowVehicleReleaseCard ref={this.setRef} {...this.props} arbiter={arbiter} />
                )}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    reportById: reportByIdSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = {
    buildTowVehicleReleaseCardFormModel,
};

export default compose(
    withCard(towVehicleReleaseCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(TowVehicleReleaseCardWrapper);

import { EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { includes } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';

import withFields from '~/client-common/core/fields/components/withFields';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    CASE_LOCAL_ID,
    REPORT_REPORTING_EVENT_NUMBER,
    SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';

import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import RecordsHeaderHamburgerMenu from '../../../records/core/components/header/RecordsHeaderHamburgerMenu';
import { useSecurityClassificationModals } from '../../../security-classification/hooks/useSecurityClassificationModals';
import { EditSecurityClassificationModal } from '../../../security-classification/components/EditSecurityClassificationModal';
import { currentCaseIdSelector, openDeleteCaseModal, openEditCaseRenModal } from '../state/ui';

const strings = componentStrings.cases.core.CaseHeader;

function CaseHeaderHamburgerMenu({
    permissionSet,
    fieldDisplayNames,
    currentUserHasAbility,
    openDeleteCaseModal,
    openEditCaseRenModal,
    currentCaseId,
}) {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const securityClassificationDisplayName =
        fieldDisplayNames.SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID;
    const hasWriteEntityPerm = includes(permissionSet, OperationTypeEnum.WRITE.name);
    const canEditRen =
        currentUserHasAbility(abilitiesEnum.CASES.EDIT_GENERAL) &&
        currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_REPORTING_EVENT_NUMBER);
    const canEditSecurityClassifications =
        currentUserHasAbility(abilitiesEnum.CASES.EDIT_GENERAL) &&
        currentUserHasAbility(abilitiesEnum.SECURITY_CLASSIFICATION.EDIT_SECURITY_CLASSIFICATIONS);

    const { openEditSecurityClassification } = useSecurityClassificationModals();

    return (
        <>
            <RecordsHeaderHamburgerMenu dropdownMenuOptionWidth={220}>
                <FeatureFlagged
                    flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE"
                    fallback={
                        hasWriteEntityPerm &&
                        canEditRen && (
                            <div className="dropdown-menu-option" onClick={openEditCaseRenModal}>
                                {strings.editCaseRen(
                                    fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER
                                )}
                            </div>
                        )
                    }
                />

                {hasWriteEntityPerm && canEditSecurityClassifications && (
                    <div
                        className="dropdown-menu-option"
                        onClick={() =>
                            openEditSecurityClassification({
                                ownerType: EntityTypeEnum.CASE.name,
                                ownerId: currentCaseId,
                            })
                        }
                    >
                        {strings.editSecurityClassification(securityClassificationDisplayName)}
                    </div>
                )}
                {hasWriteEntityPerm && currentUserHasAbility(abilitiesEnum.CASES.DELETE_GENERAL) && (
                    <div className="dropdown-menu-option" onClick={openDeleteCaseModal}>
                        {strings.deleteCase(caseDisplayName)}
                    </div>
                )}
            </RecordsHeaderHamburgerMenu>
            <EditSecurityClassificationModal />
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUserHasAbility: currentUserHasAbilitySelector,
    currentCaseId: currentCaseIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openDeleteCaseModal: () => dispatch(openDeleteCaseModal()),
    openEditCaseRenModal: () => dispatch(openEditCaseRenModal()),
});

/**
 * Hamburger menu in the case header for actions that don't belong in the
 *   other menus. This is rendered only if the current user has permission to
 *   take at least one of the actions.
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFields([
        CASE_LOCAL_ID,
        REPORT_REPORTING_EVENT_NUMBER,
        SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
    ])
)(CaseHeaderHamburgerMenu);

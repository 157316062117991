/**
 * How many times to try polling for a newly linked Custodial Property Summary report
 *   from a non-Custodial report. The poll is considered a failure if there's no
 *   linked Custodial report after this number of requests.
 * @type {number}
 */
export const POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_MAX_ATTEMPTS = 4;
/**
 * The amount of time to wait before executing the first poll call
 * @type {number}
 */
export const POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_INITIAL_DELAY_MS = 500;
/**
 * @type {number}
 * The amount of time to wait after each poll before excuting the next one
 */
export const POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_DELAY_MS = 250;
/**
 * The factor used to calculate the backoff timing
 * Example: with 4 attemts, a delay of 250ms and a factor of 2, we delay our retries 500, 1000, 2000 and 4000ms
 * @type {number}
 */
export const POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_DELAY_FACTOR = 2;

export const ASSOCIATED_RECORDS_CARD_ID = -1;

import React, { useCallback, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import { HStack, Spinner, Text } from 'arc';
import type { MapPropsT, MapRefShapeT } from '../types';
import componentStrings from '../../strings/componentStrings';
import { useEsriSimpleBaseMap, useGeoJSONLayer, useSimpleZoomControls } from './esri-hooks';
import { useStaticPolylineFeatureLayer } from './esri-hooks/useStaticPolylineFeatureLayer';
import { useStaticPolygonFeatureLayer } from './esri-hooks/useStaticPolygonFeatureLayer';
import {
    createSelectedLocationGraphic,
    useStaticSelectedLocationFeatureLayer,
} from './esri-hooks/useStaticSelectedLocationFeatureLayer';
import { useNearestLocationFeatureLayer } from './esri-hooks/useNearestLocationFeatureLayer';

import {
    ESRI_SIMPLE_MAP_DEFAULT_BASE_MAP_MODE,
    ESRI_SIMPLE_MAP_DEFAULT_ZOOM_LEVEL,
} from './config';

const strings = componentStrings.core.maps.Esri;

type OuterWrapperT = {
    width: number;
    height: number;
};

const OuterWrapper = styled.div<OuterWrapperT>`
    border: 1px solid;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
`;

const InnerWrapper = styled.div`
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: relative;
    right: 0;
    bottom: 0;
`;

/**
 * Provide an imperative API for handling various map states
 */
const useMapApi = (
    mapRef: MapPropsT['mapRef'],
    props: {
        GraphicConstructor?: __esri.GraphicConstructor;
        selectedLocationLayer?: __esri.FeatureLayer;
        nearestLocationLayer?: __esri.FeatureLayer;
        mapView?: __esri.MapView;
    }
) => {
    const mapViewRef = useRef<__esri.MapView | undefined>(props.mapView);
    mapViewRef.current = props.mapView;

    const clearGraphicsInLayer = useCallback(
        (layer?: __esri.FeatureLayer) => {
            if (props.GraphicConstructor && layer) {
                return layer.queryFeatures().then((featureSet) => {
                    const graphicsToRemove = featureSet.features;
                    layer?.applyEdits({
                        deleteFeatures: graphicsToRemove,
                    });
                });
            }
            return Promise.resolve();
        },
        [props.GraphicConstructor]
    );

    const replaceGraphicsInLayer = useCallback(
        ({
            layer,
            params,
        }: {
            layer?: __esri.FeatureLayer;
            params: Parameters<MapRefShapeT['setSelectedLocation']>[0];
        }) => {
            if (props.GraphicConstructor) {
                const selectedLocationGraphic = createSelectedLocationGraphic(
                    {
                        latitude: params.lat,
                        longitude: params.lng,
                    },
                    props.GraphicConstructor
                );
                layer?.queryFeatures().then((featureSet) => {
                    const graphicsToRemove = featureSet.features;
                    layer?.applyEdits({
                        deleteFeatures: graphicsToRemove,
                        addFeatures: selectedLocationGraphic ? [selectedLocationGraphic] : [],
                    });
                });
            }
        },
        [props.GraphicConstructor]
    );

    useImperativeHandle(
        mapRef,
        () => {
            return {
                clearNearestLocations: () => {
                    clearGraphicsInLayer(props.nearestLocationLayer);
                },
                clearSelectedLocations: () => {
                    clearGraphicsInLayer(props.selectedLocationLayer);
                },
                setNearestLocation: (params) => {
                    replaceGraphicsInLayer({ layer: props.nearestLocationLayer, params });
                },
                setSelectedLocation: (params) => {
                    replaceGraphicsInLayer({ layer: props.selectedLocationLayer, params });
                },
                ready: () => {
                    // We need this method because `mapViewRef` is asyncronously
                    // set, and so we need a way to make sure the ref is available
                    // before we start acting on it
                    return new Promise((resolve, reject) => {
                        let count = 0;
                        const maxWaitTime = 10000;
                        const interval = 200;
                        const maxIterations = maxWaitTime / interval;

                        const waitForMap = () => {
                            count++;
                            // Check if props.mapView is set
                            if (mapViewRef.current) {
                                mapViewRef.current.when().then(() => {
                                    resolve();
                                });
                            } else {
                                // If we waited 10 seconds and still have no map,
                                // then we are in bad shape
                                if (count > maxIterations) {
                                    reject('There was an error loading the map');
                                }
                                setTimeout(() => {
                                    waitForMap();
                                }, interval);
                            }
                        };

                        waitForMap();
                    });
                },
                updateMap: (params) => {
                    props.mapView?.goTo(
                        {
                            target: params.target
                                ? [params.target.lng, params.target.lat]
                                : undefined,
                            zoom: params.zoom || props.mapView.zoom,
                        },
                        { animate: !!params.animate }
                    );
                },
            };
        },
        [
            clearGraphicsInLayer,
            props.nearestLocationLayer,
            props.selectedLocationLayer,
            props.mapView,
            replaceGraphicsInLayer,
        ]
    );
};

export const EsriSimpleMap: React.FC<MapPropsT> = (props) => {
    const {
        defaultCenter,
        centerForUpdate,
        mapMode = ESRI_SIMPLE_MAP_DEFAULT_BASE_MAP_MODE,
        zoomForUpdate,
        zoom = ESRI_SIMPLE_MAP_DEFAULT_ZOOM_LEVEL,
        onChange,
        selectedLocation,
        polylines,
        polygons,
        webmapPortalId,
        enableMove,
        enableZoom,
        zoomToPolygons,
        width,
        height,
        zoomControlPosition,
        zoomButtonStyle,
        geoJSON,
        onClick,
    } = props;

    const {
        baseMap,
        mapView,
        webmap,
        FeatureLayerConstructor,
        GraphicConstructor,
    } = useEsriSimpleBaseMap(
        defaultCenter,
        zoom,
        enableMove,
        enableZoom,
        mapMode,
        onChange,
        webmapPortalId,
        onClick
    );

    const zoomControls = useSimpleZoomControls({
        mapView,
        centerForUpdate,
        displayOnlyMode: !enableZoom,
        zoomForUpdate,
        zoom,
        zoomControlPosition,
        zoomButtonStyle,
    });

    useStaticPolylineFeatureLayer({
        polylines,
        webmap,
    });

    useStaticPolygonFeatureLayer({
        polygons,
        webmap,
        mapView,
        zoomToPolygons,
    });

    const { layer: selectedLocationLayer } = useStaticSelectedLocationFeatureLayer({
        selectedLocation,
        webmap,
        FeatureLayerConstructor,
        GraphicConstructor,
    });

    const { layer: nearestLocationLayer } = useNearestLocationFeatureLayer({
        webmap,
        FeatureLayerConstructor,
        GraphicConstructor,
    });

    useMapApi(props.mapRef, {
        GraphicConstructor,
        selectedLocationLayer,
        nearestLocationLayer,
        mapView,
    });

    const { isLoading, isError } = useGeoJSONLayer(geoJSON, webmap);

    if (width && height && typeof width === 'number' && typeof height === 'number') {
        return (
            <OuterWrapper width={width} height={height}>
                <InnerWrapper>
                    {baseMap}
                    {zoomControls}
                </InnerWrapper>
            </OuterWrapper>
        );
    }
    // if widht and height is not provided,
    // EsriStaticMap need to be wrapped in a outer div, which defines the width and height of the map.
    return (
        <InnerWrapper>
            {baseMap}
            {zoomControls}
            <HStack style={{ position: 'absolute', left: '8px', bottom: '8px' }}>
                {isLoading && (
                    <>
                        <Spinner size="sm" />
                        <Text variant="headingXs" color="brand">
                            {strings.loading}
                        </Text>
                    </>
                )}
                {isError && (
                    <Text variant="headingXs" color="negative">
                        {strings.loadingError}
                    </Text>
                )}
            </HStack>
        </InnerWrapper>
    );
};

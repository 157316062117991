import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { EFileDefendantPersonView } from '@mark43/rms-api';
import { Flex, InlineBanner, SideNavItem } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResource } from '~/client-common/core/hooks/useResource';
import { augmentActionAndStoreRmsHydratedPersonProfiles } from '~/client-common/core/domain/person-profiles/state/data';
import { SkeletonList } from '../../../../core/components/Skeleton';
import { BaseLinkWithAnalytics } from '../../../../core/components/links/Link';
import { PersonProfileSummary } from '../../../../records/core/components/summaries/PersonProfileSummary';
import { eFileResource } from '../../../resources';
import { useEFileContext } from '../../../hooks';
import { EFileCurrentlyViewingSidebar, EFileDefendantSummaryCard, IceCreamMenu } from '../../..';
import { EFileBreadCrumb } from '../EFileBreadCrumb';

const strings = componentStrings.eFiles.defendantProfile;

const DefendantWrapper = styled.div`
    width: 490px;
    flex: 3 1 490px;
`;

const SidebarWrapper = styled.div`
    width: 240px;
    flex: 1 1 240px;
    margin-left: 15px;
`;

type ParamsT = {
    eFileId: string;
    contextedNameId: string;
};

type EFileDefendantT = RouteComponentProps<ParamsT, Record<string, unknown>>;

export const EFileDefendantPersonPage = ({ params, router }: EFileDefendantT) => {
    const dispatch = useDispatch();
    const {
        actions,
        getters: { selectedEFileEntity },
        handlers: { onFetchError },
    } = useEFileContext();

    const { contextedNameId } = params;
    const eFileId = parseInt(params.eFileId);
    const defendantId = parseInt(contextedNameId);
    const headerData = selectedEFileEntity.getEFileDefendantPersonHeaderViewModel();

    const loadDefendant = useCallback(() => {
        if (eFileId && defendantId) {
            return eFileResource.getDefendantPersonProfile(eFileId, defendantId);
        }
        return Promise.resolve(undefined);
    }, [defendantId, eFileId]);

    const onLoadDefendantSuccess = useCallback(
        (defendantProfileView?: EFileDefendantPersonView) => {
            if (defendantProfileView) {
                actions.selectedEFileEntity.setEFileDefendantPersonProfile(defendantProfileView);
                dispatch(
                    augmentActionAndStoreRmsHydratedPersonProfiles(
                        { type: 'STORE_E_FILE_DEFENDANT_PERSON_PROFILE' },
                        [defendantProfileView.hydratedPerson]
                    )
                );
            }
        },
        [actions.selectedEFileEntity, dispatch]
    );

    const { isLoading, errorMessage } = useResource(
        loadDefendant,
        onLoadDefendantSuccess,
        onFetchError
    );

    const renderProfileSummaryActionButton = useCallback(() => {
        if (!headerData) {
            return null;
        }

        const { isDeactivated, eFileLinkId, eFileLinkType, entityId, eFileId } = headerData;
        return (
            <IceCreamMenu
                ref={undefined}
                router={router}
                isDeactivated={!!isDeactivated}
                eFileId={eFileId}
                eFileLinkId={eFileLinkId}
                contextedProfileId={entityId}
                linkType={eFileLinkType}
            />
        );
    }, [headerData, router]);

    if (errorMessage) {
        return <InlineBanner description={errorMessage} status="error" title={strings.error} />;
    }

    if (isLoading) {
        return <SkeletonList numberOfRows={3} />;
    }

    if (!headerData) {
        return null;
    }

    return (
        <>
            <EFileBreadCrumb
                parentPath={`#/e-file/${eFileId}/defendants`}
                linkType={headerData.eFileLinkType}
                entityName={headerData.entityName}
            />
            <Flex justifyContent={'space-between'} width={'100%'}>
                <DefendantWrapper>
                    <EFileDefendantSummaryCard
                        isDeactivated={!!headerData.isDeactivated}
                        linkType={headerData.eFileLinkType}
                    >
                        <PersonProfileSummary
                            showMugshotThumbnail
                            personProfileId={defendantId}
                            showExpandCollapseButton
                            renderActionButtons={renderProfileSummaryActionButton}
                        />
                    </EFileDefendantSummaryCard>
                </DefendantWrapper>
                <SidebarWrapper>
                    <EFileCurrentlyViewingSidebar>
                        <SideNavItem
                            icon="Card"
                            as={BaseLinkWithAnalytics}
                            to={`/e-file/${eFileId}/summary`}
                            isActive={router.isActive(`/e-file/${eFileId}/summary`, true)}
                            label={'Fake Link to something on page'}
                        />
                    </EFileCurrentlyViewingSidebar>
                </SidebarWrapper>
            </Flex>
        </>
    );
};

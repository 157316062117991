import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import assignUserRoleForm from '../state/forms/assignUserRoleForm';
import Row from '../../../core/components/Row';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import Checkbox from '../../../core/forms/components/checkboxes/Checkbox';

const strings =
    componentStrings.admin.roles.RoleUsersActions.AssignUserRoleSidePanel.AssignUserRoleForm;

function AssignUserRoleForm() {
    const [includeInactiveUsers, setIncludeInactiveUsers] = React.useState(false);

    return (
        <ReactReduxForm {...assignUserRoleForm}>
            <Row>
                <RRFUserSelect
                    label={strings.label}
                    multiple={true}
                    path="assignedUserRole"
                    width={400}
                    effectiveDate={includeInactiveUsers ? null : undefined}
                />
                <Checkbox
                    label={strings.includeInactiveUsers}
                    value={includeInactiveUsers}
                    onChange={setIncludeInactiveUsers}
                />
            </Row>
        </ReactReduxForm>
    );
}

export default AssignUserRoleForm;

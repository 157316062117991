import {
    DashboardSettingsView,
    DashboardResource,
    CadTicketsResource,
    SearchResource,
} from '@mark43/rms-api';

import { createResource } from '../../../lib/resources/Resource';
import { req } from '../../../lib/ajax';

export default createResource({
    name: 'Personal Dashboard Resource',
    methods: {
        getActionRequiredReports({ hideLoadingBar = false } = {}) {
            return req<DashboardResource.GetActionRequiredReports>({
                method: 'GET',
                url: `dashboard/personal`,
                hideLoadingBar,
            });
        },
        getMyCadEvents({ hideLoadingBar = false } = {}) {
            return req<CadTicketsResource.GetCadTicketsForUser>({
                method: 'GET',
                url: `cad_tickets/my_cad_tickets`,
                hideLoadingBar,
            });
        },
        getCustodialReports({ hideLoadingBar = false } = {}) {
            return req<DashboardResource.GetCustodialReports>({
                method: 'GET',
                url: `dashboard/custodial_reports`,
                hideLoadingBar,
            });
        },
        getRecentArrests(
            filters: {
                subdivision1?: number;
                subdivision2?: number;
                subdivision3?: number;
                subdivision4?: number;
                subdivision5?: number;
                departmentId?: number;
            },
            { hideLoadingBar = false } = {}
        ) {
            return req<SearchResource.GetRecentArrests>({
                method: 'GET',
                url: `search/arrests/recent`,
                params: {
                    from: 0,
                    size: 50,
                    ...filters,
                },
                hideLoadingBar,
            });
        },
        getSettings({ hideLoadingBar = false } = {}) {
            return req<DashboardResource.GetDashboardSettings>({
                method: 'GET',
                url: `dashboard/settings`,
                hideLoadingBar,
            });
        },
        upsertSettings(settings: DashboardSettingsView, { hideLoadingBar = false } = {}) {
            return req<DashboardResource.UpsertDashboardSettings>({
                method: 'PUT',
                url: `dashboard/settings`,
                data: settings,
                hideLoadingBar,
            });
        },
        getUserCases({ hideLoadingBar = false } = {}) {
            return req<DashboardResource.UserCases>({
                method: 'GET',
                url: 'dashboard/cases/mine',
                hideLoadingBar,
            });
        },
        getUserWarrants({ hideLoadingBar = false } = {}) {
            return req<DashboardResource.UserWarrants>({
                method: 'GET',
                url: 'dashboard/warrants/mine',
                hideLoadingBar,
            });
        },
    },
});

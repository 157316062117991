import { map } from 'lodash';
import { combineReducers, AnyAction } from 'redux';
import {
    ReportStatusView,
    ReportSubmissionAuthor,
    ReportSupplementView,
    Offense,
    EventDetailBundle,
    SupplementCardPrefillBundle,
    Report,
    NibrsExportedReportSegment,
} from '@mark43/rms-api';

import { normalize } from '~/client-common/helpers/dataHelpers';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { custodialPropertyUiReducer } from '../../modules/reports/custodial-property-summary/state/ui';
import { embeddedReportsUiReducer } from '../../modules/reports/core/state/ui/embeddedReports';

import {
    LOAD_REPORT_HISTORY_START,
    LOAD_REPORT_HISTORY_SUCCESS,
    LOAD_REPORT_HISTORY_FAILURE,
} from '../../modules/reports/core/state/data/reportHistory';
import arrestCard from '../../modules/reports/core/state/ui/arrestCard';
import behavioralCrisisCard from '../../modules/reports/core/state/ui/behavioralCrisisCard';
import bookingCard from '../../modules/reports/core/state/ui/bookingCard';
import chargesCard from '../../modules/reports/core/state/ui/chargesCard';
import citationCard from '../../modules/reports/core/state/ui/citationCard';
import communityInfoCard from '../../modules/reports/core/state/ui/communityInfoCard';
import courtCaseCard from '../../modules/reports/core/state/ui/courtCaseCard';
import custodialPropertyCard from '../../modules/reports/core/state/ui/custodialPropertyCard';
import eventInfoCard from '../../modules/reports/core/state/ui/eventInfoCard';
import fieldContactCard from '../../modules/reports/core/state/ui/fieldContactCard';
import reportLegacyMetadataCard from '../../modules/reports/core/state/ui/reportLegacyMetadataCard';
import impoundCard from '../../modules/reports/core/state/ui/impoundCard';
import incidentCards from '../../modules/reports/core/state/ui/incidentCards';
import involvedProfilesCard from '../../modules/reports/core/state/ui/involvedProfilesCard';
import missingPersonsCard from '../../modules/reports/core/state/ui/missingPersonsCard';
import offenseCards from '../../modules/reports/core/state/ui/offenseCards';
import narrativeCard from '../../modules/reports/core/state/ui/narrativeCard';
import summaryNarrativeCard from '../../modules/reports/core/state/ui/summaryNarrativeCard';
import personnelCard from '../../modules/reports/custodial-property-summary/state/ui/personnelCard';
import propertyCard from '../../modules/reports/core/state/ui/propertyCard';
import relationshipsCard from '../../modules/reports/core/state/ui/relationshipsCard';
import towVehicleReleaseCard from '../../modules/reports/core/state/ui/towVehicleReleaseCard';
import towVehicleCheckInCard from '../../modules/reports/core/state/ui/towVehicleCheckInCard';
import stopCard from '../../modules/reports/core/state/ui/stopCard';
import supplementInfoCard from '../../modules/reports/core/state/ui/supplementInfoCard';
import towVehicleCard from '../../modules/reports/core/state/ui/towVehicleCard';
import towVehicleImpoundCard from '../../modules/reports/core/state/ui/towVehicleImpoundCard';
import trafficCrashCard from '../../modules/reports/core/state/ui/trafficCrashCard';
import crashEventInfoCard from '../../modules/reports/core/state/ui/crashEventInfoCard';
import useOfForceCard from '../../modules/reports/core/state/ui/useOfForceCard';
import useOfForceSubjectCard from '../../modules/reports/core/state/ui/useOfForceSubjectCard';
import vehicleCard from '../../modules/reports/core/state/ui/vehicleCard';
import trafficCrashLocationCard from '../../modules/reports/core/state/ui/trafficCrashLocationCard';
import { ReportUiActions } from '../actions/reportsActions';

function linkedReportsUiReducer(state: number[] = [], action: ReportUiActions) {
    switch (action.type) {
        case 'reports_STORE_LINKED_REPORT_IDS':
            return [...state, ...action.payload];
        case 'reports_FETCH_REPORT_SUCCESS':
        case 'reports_FETCH_REPORT_RELATED_ENTITIES_SUCCESS':
            return action.payload.linkedReportIds;
        default:
            return state;
    }
}

function linkedCaseIdsUiReducer(state: number[] = [], action: ReportUiActions) {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
        case 'reports_FETCH_REPORT_RELATED_ENTITIES_SUCCESS':
            return action.payload.linkedCaseIds;
        default:
            return state;
    }
}

function linkedWarrantsUiReducer(state: number[] = [], action: ReportUiActions) {
    switch (action.type) {
        case 'reports_SET_LINKED_WARRANT_IDS':
            return action.payload;
        case 'reports_FETCH_REPORT_SUCCESS':
        case 'reports_FETCH_REPORT_RELATED_ENTITIES_SUCCESS':
            return action.payload.linkedWarrantIds;
        default:
            return state;
    }
}

function currentReportIdReducer(state: number | null = null, action: ReportUiActions) {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.report.id;
        default:
            return state;
    }
}

function cardsUiReducer(
    state: { anchor: string; display: string }[] = [],
    action: ReportUiActions
) {
    switch (action.type) {
        case 'reports_SET_CARD_SIDEBAR_TITLE':
            const visibleCards = state;
            return map(visibleCards, (card) =>
                card.anchor === action.payload.anchor
                    ? {
                          display: action.payload.title,
                          anchor: action.payload.anchor,
                      }
                    : card
            );
        default:
            return state;
    }
}

function canSubmitReportUiReducer(state = false, action: ReportUiActions) {
    switch (action.type) {
        case 'reports_SET_CAN_SUBMIT':
            return action.payload;
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.canSubmit;
        default:
            return state;
    }
}

function reportIsPackagedUiReducer(state = false, action: ReportUiActions) {
    switch (action.type) {
        case 'reports_SET_REPORT_IS_PACKAGED':
            return action.payload;
        default:
            return state;
    }
}

function reportIsUcrValidatedUiReducer(state = false, action: ReportUiActions) {
    switch (action.type) {
        case 'reports_SET_REPORT_IS_UCR_VALIDATED':
            return action.payload;
        default:
            return state;
    }
}

type ReportStatusViewUiState = ReportStatusView | false;

function reportStatusViewUiReducer(
    state: ReportStatusViewUiState = false,
    action: ReportUiActions
): ReportStatusViewUiState {
    switch (action.type) {
        case 'reports_SET_REPORT_STATUS_VIEW':
            return action.payload;
        case 'reports_REPORT_SUBMISSION_CHANGE':
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.reportStatusView;
        default:
            return state;
    }
}

function reportSubmissionAuthorsUiReducer(
    state: ReportSubmissionAuthor[] = [],
    action: ReportUiActions
) {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.reportSubmissionAuthors;
        default:
            return state;
    }
}

function reportNibrsExportedReportSegmentsUiReducer(
    state: NibrsExportedReportSegment[] = [],
    action: ReportUiActions
) {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.nibrsExportedReportSegments;
        default:
            return state;
    }
}

// TODO: change AnyAction to ReportUiActions after typing reportHistory.js
function reportHistoryUiReducer(
    state: {
        isLoading: boolean;
    } = {
        isLoading: false,
    },
    action: AnyAction
) {
    switch (action.type) {
        case LOAD_REPORT_HISTORY_START:
            return {
                ...state,
                isLoading: true,
            };
        case LOAD_REPORT_HISTORY_FAILURE:
        case LOAD_REPORT_HISTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

type LegacyReportsState = { [index: number]: Report };

export function reportDataReducer(
    state: LegacyReportsState = {},
    action: ReportUiActions
): LegacyReportsState {
    switch (action.type) {
        case 'reports_FETCH_REPORT':
            return {};
        case 'reports_SET_REPORT': {
            const report = action.payload.report;
            return {
                ...state,
                [report.id]: report,
            };
        }
        case 'reports_FETCH_REPORT_SUCCESS': {
            const report = action.payload.report;
            return {
                ...state,
                [report.id]: report,
            };
        }
        case 'reports_STORE_REPORTS':
            // @ts-expect-error client-common to client RND-7529
            return {
                ...state,
                ...normalize(action.payload, 'id'),
            };
        case 'reports_SET_REPORT_AGENCY_ID': {
            const report = action.payload;
            const reportId = report.id;
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    agencyId: report.agencyId,
                },
            };
        }
        case 'reports_SET_REPORT_DESCRIPTION': {
            const report = action.payload;
            const reportId = report.id;
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    description: report.description,
                },
            };
        }
        default:
            return state;
    }
}

type ReportSupplementViewUiState = ReportSupplementView | Record<string, unknown>;

const reportSupplementViewUiReducer = (
    state: ReportSupplementViewUiState = {},
    action: ReportUiActions
): ReportSupplementViewUiState => {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.reportSupplementView;
        default:
            return state;
    }
};

const isLegacyReportReducer = (state = false, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.report.isLegacyReport;
        default:
            return state;
    }
};

type LinkedOffensesUiState = Offense[] | Record<string, unknown>;

const linkedOffensesUiReducer = (
    state: LinkedOffensesUiState = {},
    action: ReportUiActions
): LinkedOffensesUiState => {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.linkedOffenses;
        default:
            return state;
    }
};

const canReviewReportUiReducer = (state = false, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.canReview;
        default:
            return state;
    }
};

const canStaffReviewReportUiReducer = (state = false, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.canStaffReview;
        default:
            return state;
    }
};

const canRejectReportUiReducer = (state = false, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.canReject;
        default:
            return state;
    }
};

const canSecondaryRejectReportUiReducer = (state = false, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.canSecondaryReject;
        default:
            return state;
    }
};

const ignoreValidationReducer = (state = false, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return !!action.payload.report.isLegacyReport;
        default:
            return state;
    }
};

const reportIsLoadingReducer = (state = true, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_FETCH_REPORT':
        case 'reports_LEAVE_REPORT':
            return true;
        case 'reports_FETCH_REPORT_SUCCESS':
        case 'reports_FETCH_REPORT_FAILURE':
            return false;
        default:
            return state;
    }
};

export const lastCreatedReportIdReducer = (state = null, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_SET_LAST_CREATED_REPORT_ID':
            return action.payload;
        default:
            return state;
    }
};

const isSnapshotReducer = (state = false, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_FETCH_REPORT_SUCCESS':
            return action.payload.isSnapshot;
        default:
            return state;
    }
};

type ReportPrefillUiState = {
    [reportCardEnum.EVENT_INFO.name]?: {
        prefillModalSaveSelected: boolean;
        eventDetailBundle: EventDetailBundle;
    };
    [reportCardEnum.SUPPLEMENT_INFO.name]?: {
        prefillModalSaveSelected: boolean;
        supplementCardPrefillBundle: SupplementCardPrefillBundle;
    };
};

const reportPrefillUiReducer = (
    state: ReportPrefillUiState = {},
    action: ReportUiActions
): ReportPrefillUiState => {
    switch (action.type) {
        case 'reports_SET_EVENT_INFO_CARD_PREFILL':
            return {
                ...state,
                [reportCardEnum.EVENT_INFO.name]: action.payload,
            };
        case 'reports_SET_SUPPLEMENT_INFO_CARD_PREFILL':
            return {
                ...state,
                [reportCardEnum.SUPPLEMENT_INFO.name]: action.payload,
            };
        default:
            return state;
    }
};

const baseReportUiReducer = combineReducers({
    reportIsLoading: reportIsLoadingReducer,
    linkedReportIds: linkedReportsUiReducer,
    linkedCaseIds: linkedCaseIdsUiReducer,
    linkedWarrantIds: linkedWarrantsUiReducer,
    linkedOffenses: linkedOffensesUiReducer,
    currentReportId: currentReportIdReducer,
    reportHistory: reportHistoryUiReducer,
    custodialProperty: custodialPropertyUiReducer,
    visibleCards: cardsUiReducer,
    reportSupplementView: reportSupplementViewUiReducer,
    isLegacyReport: isLegacyReportReducer,
    isSnapshot: isSnapshotReducer,
    // permissions / submissions
    canSubmit: canSubmitReportUiReducer,
    canReview: canReviewReportUiReducer,
    canStaffReview: canStaffReviewReportUiReducer,
    canRejectReview: canRejectReportUiReducer,
    canSecondaryRejectReview: canSecondaryRejectReportUiReducer,
    reportIsPackaged: reportIsPackagedUiReducer,
    reportStatusView: reportStatusViewUiReducer,
    reportNibrsExportedReportSegments: reportNibrsExportedReportSegmentsUiReducer,
    reportIsUcrValidated: reportIsUcrValidatedUiReducer,
    reportSubmissionAuthors: reportSubmissionAuthorsUiReducer, // TODO this should probably not be here, it's data not ui
    ignoreValidation: ignoreValidationReducer,
    // cards
    arrestCard: arrestCard.reducer,
    behavioralCrisisCard: behavioralCrisisCard.reducer,
    bookingCard: bookingCard.reducer,
    chargesCard: chargesCard.reducer,
    towVehicleCheckInCard: towVehicleCheckInCard.reducer,
    citationCard: citationCard.reducer,
    communityInfoCard: communityInfoCard.reducer,
    courtCaseCard: courtCaseCard.reducer,
    custodialPropertyCard: custodialPropertyCard.reducer,
    eventInfoCard: eventInfoCard.reducer,
    fieldContactCard: fieldContactCard.reducer,
    reportLegacyMetadataCard: reportLegacyMetadataCard.reducer,
    impoundCard: impoundCard.reducer,
    incidentCards: incidentCards.reducer,
    involvedProfilesCard: involvedProfilesCard.reducer,
    missingPersonsCard: missingPersonsCard.reducer,
    narrativeCard: narrativeCard.reducer,
    summaryNarrativeCard: summaryNarrativeCard.reducer,
    relationshipsCard: relationshipsCard.reducer,
    offenseCards: offenseCards.reducer,
    personnelCard: personnelCard.reducer,
    propertyCard: propertyCard.reducer,
    towVehicleReleaseCard: towVehicleReleaseCard.reducer,
    stopCard: stopCard.reducer,
    supplementInfoCard: supplementInfoCard.reducer,
    towVehicleCard: towVehicleCard.reducer,
    towVehicleImpoundCard: towVehicleImpoundCard.reducer,
    trafficCrashCard: trafficCrashCard.reducer,
    useOfForceCard: useOfForceCard.reducer,
    useOfForceSubjectCard: useOfForceSubjectCard.reducer,
    vehicleCard: vehicleCard.reducer,
    trafficCrashLocationCard: trafficCrashLocationCard.reducer,
    crashEventInfoCard: crashEventInfoCard.reducer,
    prefill: reportPrefillUiReducer,

    // embedded report
    embeddedReports: embeddedReportsUiReducer,
});

type ReportUiState = ReturnType<typeof baseReportUiReducer> | undefined;

export const reportUiReducer = (state: ReportUiState = undefined, action: ReportUiActions) => {
    switch (action.type) {
        case 'reports_CLEAR_ALL_REPORT_UI_DATA':
            return baseReportUiReducer(undefined, action);
        default:
            return baseReportUiReducer(state, action);
    }
};

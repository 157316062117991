import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FederatedSearchProvider } from 'mark43-federated-search';
import { FullUserProfile, ProductModuleEnum } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import getUserProfileResource from '~/client-common/core/domain/user-profiles/resources/userProfileResource';
import {
    currentUserProfileSelector,
    currentUserHasAbilitySelector,
} from '../../core/current-user/state/ui';
import { RmsDispatch } from '../../../core/typings/redux';
import { incrementNotificationCount } from '../state/ui';
import attributeAdminResource from '../../../legacy-redux/resources/attributesAdminResource';
import { abilitiesEnum } from '../../core/abilities';

export const FederatedSearchProviderWrapper = ({
    children,
}: React.PropsWithChildren<Record<never, never>>) => {
    const dispatch: RmsDispatch = useDispatch();
    const userProfile = useSelector(currentUserProfileSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const port = applicationSettings.CAD_DESKTOP_INTEGRATION_PORT;
    const simulationModeIsActive = !!applicationSettings.DEX_SIMULATION_MODE_ENABLED;

    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const canViewDexResults = currentUserHasAbility(abilitiesEnum.RMS_DEX.RMS_DEX_VIEW_RESULTS);
    const canMakeDexInquiries = currentUserHasAbility(abilitiesEnum.RMS_DEX.RMS_DEX_MAKE_INQUIRIES);
    const isProductModuleActive = useSelector(isProductModuleActiveSelector);
    const isRmsDexModuleActive = isProductModuleActive(ProductModuleEnum.RMS_DEX.name);

    const resource = getUserProfileResource();

    const onResultReceived = useCallback(() => {
        dispatch(incrementNotificationCount());
    }, [dispatch]);

    if (!isRmsDexModuleActive || !canViewDexResults || !canMakeDexInquiries) {
        return <>{children}</>;
    }

    return (
        <FederatedSearchProvider
            applicationScope="RMS"
            getAttributeTypeMappings={attributeAdminResource.getAttributeTypeMappings}
            userProfile={userProfile}
            getUserProfile={(userId) =>
                resource
                    .getFullUserProfile(userId)
                    .then((result: FullUserProfile) => result.profile)
            }
            onResultReceived={onResultReceived}
            desktopMetadataPort={
                typeof port === 'string' || typeof port === 'number' ? port : undefined
            }
            simulationModeIsActive={simulationModeIsActive}
        >
            {children}
        </FederatedSearchProvider>
    );
};

import {
    SAVE_LOCATION_CAUTIONS_SUCCESS,
    LOAD_LOCATION_CAUTIONS_START,
    LOAD_LOCATION_CAUTIONS_SUCCESS,
    LOAD_LOCATION_CAUTIONS_FAILURE,
    LOAD_LOCATION_CAUTION_HISTORY_SUCCESS,
    LOAD_LOCATION_CAUTION_HISTORY_START,
} from '../data';

const NEW_SUB_PREMISE = 'NEW SUB-PREMISE';

const CANCEL_EDIT_SUB_PREMISE = 'location-cautions/CANCEL_EDIT_SUB_PREMISE';
const SELECT_LOCATION = 'location-cautions/SELECT_LOCATION';
const EDIT_CAUTION_SUCCESS = 'location-cautions/EDIT_CAUTION_SUCCESS';
const CANCEL_EDIT_CAUTION_SUCCESS = 'location-cautions/CANCEL_EDIT_CAUTION_SUCCESS';
const ADD_NEW_CAUTION_SUCCESS = 'location-cautions/ADD_NEW_CAUTION_SUCCESS';
const ADD_NEW_LOCATION = 'location-cautions/ADD_NEW_LOCATION';
const CREATE_NEW_SUBPREMISE_START = 'location-cautions/CREATE_NEW_SUBPREMISE';
const EDIT_SUB_PREMISE_START = 'location-cautions/EDIT_SUB_PREMISE_START';
const CLEAR_LOCATION_CAUTION_HISTORY = 'location-cautions/CLEAR_LOCATION_CAUTION_HISTORY';

const initialUiState = {
    selectedLocationId: null,
    selectedSubPremise: null,
    editingCautionId: null,
    addingCautionForCategoryAttrId: null,
    editingSubpremiseName: false,
    loadingList: null,
    newLocationId: null,
    creatingNewSubPremise: false,
    editingSubPremise: false,
    history: null,
};

export default function locationCautionsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SELECT_LOCATION:
            return {
                ...state,
                selectedLocationId: action.payload,
                selectedSubPremise: null,
                creatingNewSubPremise: false,
                editingSubPremise: false,
                history: null,
            };
        case EDIT_CAUTION_SUCCESS:
            return {
                ...state,
                editingCautionId: action.payload,
                addingCautionForCategoryAttrId: null,
            };
        case ADD_NEW_CAUTION_SUCCESS:
            return {
                ...state,
                editingCautionId: null,
                addingCautionForCategoryAttrId: action.payload,
            };
        case SAVE_LOCATION_CAUTIONS_SUCCESS:
            return {
                ...state,
                editingCautionId: null,
                addingCautionForCategoryAttrId: null,
                creatingNewSubPremise: false,
            };
        case CANCEL_EDIT_CAUTION_SUCCESS:
            return {
                ...state,
                editingCautionId: null,
                addingCautionForCategoryAttrId: null,
            };
        case LOAD_LOCATION_CAUTIONS_START:
            return {
                ...state,
                loadingList: true,
            };
        case LOAD_LOCATION_CAUTIONS_FAILURE:
        case LOAD_LOCATION_CAUTIONS_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case ADD_NEW_LOCATION:
            return {
                ...state,
                newLocationId: action.payload,
                selectedLocationId: action.payload,
                selectedSubPremise: null,
            };
        case CREATE_NEW_SUBPREMISE_START:
            return {
                ...state,
                creatingNewSubPremise: true,
                selectedSubPremise: NEW_SUB_PREMISE,
            };
        case EDIT_SUB_PREMISE_START:
            return {
                ...state,
                editingSubPremise: true,
            };
        case CANCEL_EDIT_SUB_PREMISE:
            return {
                ...state,
                editingSubPremise: false,
            };
        case LOAD_LOCATION_CAUTION_HISTORY_SUCCESS:
            return {
                ...state,
                history: action.payload,
            };
        case CLEAR_LOCATION_CAUTION_HISTORY:
        case LOAD_LOCATION_CAUTION_HISTORY_START:
            return {
                ...state,
                history: null,
            };
        default:
            return state;
    }
}

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_PATH = 'automatic_ren_generation/preferences';
const BASE_URL = '/cad/api';

export default createResource({
    name: 'Automatic REN Generation Resource',
    methods: {
        getPreferences(agencyId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/${agencyId}`,
            });
        },
        updatePreferences(agencyId, preferences) {
            return req({
                method: 'PUT',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/${agencyId}`,
                data: preferences,
            });
        },
    },
});

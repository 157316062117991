import { FormEnum } from '@mark43/rms-api';
import _ from 'lodash';
import validationStrings from '~/client-common/core/strings/validationStrings';
import * as fields from '~/client-common/core/enums/universal/fields';
import { components } from '~/client-common/core/strings';
import { validateReduxForm } from '../validationRunner';

const strings = components.reports.RenChangeForm;

/**
 * Validation logic on all fields, where an error message would apply to the
 *   entire form and not to any one field.
 * @param  {Object} data
 * @return {string|null} String if error, `null` if no error.
 */
export function renChangeValidator({ newRen, currentRen }, props) {
    const errors = {};
    const renDisplayName = props.formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER);
    // The below should be refactored, which will require some component refactoring.
    // The issue is that the parent component, `RenChangeForm.js`, utilizes this validation function
    // for both `redux-form` and custom validation; the params passed into this validator are unexpected when
    // `redux-form` uses this validator versus when we manually use this validator for custom validation.

    // For now, we will write specific code here to force configurable validation to work;
    // this isn't the end of the world because there isn't too much to validate currently (it's really a single field) & there are
    // only a couple of rules, but really,
    // long term, the component should be refactored to have all custom validation removed & use `redux-form` validation instead.

    // :( -- consts here, but should be component props.
    const formContext = FormEnum.CREATE_REPORT.name; // `Form` component's usage context
    const formFieldNames = {
        // tie each one of the `Redux-Form` fields to a field id
        newRen: fields.REPORT_REPORTING_EVENT_NUMBER,
        currentRen: null,
    };

    const reduxFormError = _.get(
        validateReduxForm({ form: formContext }, formFieldNames)({ newRen }),
        'newRen'
    );
    if (reduxFormError) {
        // Map the error string to our custom error strings.  Use same error strings as defined below, in legacy ad hoc validation.
        if (reduxFormError === validationStrings.field.required) {
            errors._error = strings.requiredError(renDisplayName);
        } else {
            // Our very, very crude mapping above failed.  There is an error, but we cannot resolve the specific REN error message.
            // Let's do the next best thing, and propagate the default error message.
            errors._error = reduxFormError;
        }
    }
    // Legacy ad hoc validation -- left here for reference.
    /*
    if (newRen && newRen.length > 30) {
        errors._error = strings.renTooLongError;
    } else if (!newRen || newRen.length === 0) {
        errors._error = strings.requiredError;
    } else if (newRen === currentRen) {
        errors._error = strings.sameRenError;
    }
    */

    // This validation rule remains from the legacy ad hoc validation.
    // Reason is, that changing from a REN to the same REN is *always* a no-op.
    // This is not something that ever needs to exist in DB or be configurable; it's a fundamental truth.
    // *Note*: This `if` statement below will never be true, when our validation routine (`renChangeValidator`) is triggered by `redux-form`; in this
    // case, `currentRen` param will be populated as a `props` object.  But, leaving this rule as-is, because our custom validation in the parent component
    // will utilize this.
    if (newRen === currentRen) {
        errors._error = strings.sameRenError(renDisplayName);
    }

    return errors;
}

import React from 'react';
import styled from 'styled-components';
import { EmptyState, Spinner, cssVar } from 'arc';
import { Flex, HStack } from '@arc/layout';
import { ButtonGroup } from '@arc/button';
import { IconButton } from '@arc/icon-button';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useFetchCrashDiagramImageFile } from '../hooks';

const crashDiagramEditImageStrings =
    componentStrings.dragon.crashDiagram.CrashDiagramImage.editMode;

const crashDiagramSummaryImageStrings =
    componentStrings.dragon.crashDiagram.CrashDiagramImage.summaryMode;

const CrashDiagramEditImageWrapper = styled.div`
    position: relative;
`;

const CrashDiagramEditButtonWrapper = styled.div`
    position: absolute;
    right: ${cssVar('arc.space.2')};
    top: ${cssVar('arc.space.2')};
`;

type CrashDiagramImageProps =
    | {
          fileId?: number;
          mode: 'EDIT';
          onEdit: () => void;
          onDelete: () => void;
      }
    | {
          fileId?: number;
          mode: 'SUMMARY';
          onEdit: undefined;
          onDelete: undefined;
      };

export const CrashDiagramImage: React.FC<CrashDiagramImageProps> = ({
    fileId,
    mode,
    onEdit,
    onDelete,
}) => {
    const result = useFetchCrashDiagramImageFile(fileId);

    if (!result) {
        return null;
    }

    const { isLoading, svgDataUrl } = result;

    if (isLoading) {
        return (
            <Flex justifyContent="center">
                <Spinner size="lg" />
            </Flex>
        );
    }

    if (!svgDataUrl) {
        return (
            <EmptyState
                title=""
                subtitle={
                    mode === 'EDIT'
                        ? crashDiagramEditImageStrings.emptyState
                        : crashDiagramSummaryImageStrings.emptyState
                }
            />
        );
    }

    return (
        <CrashDiagramEditImageWrapper>
            {mode === 'EDIT' && (
                <CrashDiagramEditButtonWrapper>
                    <HStack spacing="4">
                        <ButtonGroup>
                            <IconButton
                                aria-label="Edit Crash Diagram"
                                icon="Edit"
                                variant="ghost"
                                onClick={onEdit}
                            />
                            <IconButton
                                aria-label="Delete Crash Diagram"
                                icon="TrashCan"
                                variant="ghost"
                                onClick={onDelete}
                            />
                        </ButtonGroup>
                    </HStack>
                </CrashDiagramEditButtonWrapper>
            )}
            <img src={svgDataUrl} alt="Crash Diagram Image" />
        </CrashDiagramEditImageWrapper>
    );
};

import { FileCategoryEnum, FileTypeEnum } from '@mark43/rms-api';
import $ from 'jquery';
import { get, noop, parseInt } from 'lodash';
import keyMirror from 'keymirror';
import React from 'react';
import styled from 'styled-components';

import { Text, TextStyle } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';

import { getAuthorizationHeader } from '../../../../core/auth';
import { dataUriToBlob } from '../../../core/signature-pad/signaturePadHelpers';
import SigWeb from '../utils/sigweb';

import _Upload from '../../../core/forms/components/Upload';
import { InlineBanner } from '../../../core/components/InlineBanner';
import _Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import { FloatLoadingGray as _FloatLoadingGray } from '../../../../legacy-redux/components/core/Loading';
import _ChainOfCustodyPdfLink from '../../chain-of-custody/components/ChainOfCustodyPdfLink';
import testIds from '../../../../core/testIds';

const { connectRRFInput } = reactReduxFormHelpers;
const strings = componentStrings.forms.SignaturePad;

// const BASE_IMAGE_URL = 'https://local.qa.mark43.io/images/signature-pad/ok.bmp';
const BASE_IMAGE_URL = 'http://www.sigplusweb.com/SigWeb';

// the different screen states on the physical signature pad
const SCREENS = keyMirror({
    AGREEMENT: null,
    SIGNATURE: null,
});

const CanvasContainer = styled.div`
    position: relative;
    clear: both;
    margin-bottom: 13px;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    background-color: ${(props) => props.theme.colors.white};
`;

// element overlapping on top of the canvas with action buttons and loading
// spinner; this absolutely positioned element has a border instead of the
// CanvasContainer because the border changes width
const CanvasOverlay = styled.div`
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    padding: 12px 20px 0 20px;
    ${(props) =>
        !props.signaturePadActive
            ? `border: 1px solid ${props.theme.colors.lightGrey};`
            : 'border: 2px solid #72CF0D;'} width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
`;

const Button = styled(_Button)`
    margin-top: 0;
`;

// make the area outside the button not clickable
const UploadButton = styled(_Button)`
    margin: 0;
`;

// loading spinner at the top right of the canvas while the signature pad is
// active
const FloatLoadingGray = styled(_FloatLoadingGray)`
    position: absolute;
    top: 0;
    right: 0;
`;

const PreviewImage = styled.img`
    display: block;
    margin-bottom: 13px;
    width: ${(props) => props.width}px;
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const Upload = styled(_Upload)`
    display: block;
    clear: none;
    float: left;
    margin-right: 10px;
`;

const ChainOfCustodyPdfLink = styled(_ChainOfCustodyPdfLink)`
    float: left;
    margin-bottom: 13px;
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const SignatureHelpText = styled(Text)`
    margin-bottom: 2px;
`;

class _SignaturePad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // error message that appears below the box
            errorMessage: null,
            // whether the signature pad is plugged in to the computer by USB,
            // and this requires the user to have installed SigWeb software too
            signaturePadDetected: false,
            // the screen currently displayed on the signature pad, `null` when
            // the signature pad doesn't need to show anything
            signaturePadScreen: null,
            // url to the signature image, either captured or uploaded. this
            // is the `fileWebServerPath` field on the File model. note that
            // when a new image path is set, `errorMessage` should be reset
            // to null because that always indicates a success, plus we can
            // clear signature pad errors when the user ignores it and
            // chooses to manually upload an image instead
            imagePath: null,
            // file object when user uploads a non-image for signature.
            file: {},
        };
    }

    componentWillUnmount() {
        this.stopCapture();
    }

    getHeight() {
        // the model of signature pad that we use has a 5:1 aspect ratio
        return Math.round(this.props.width / 5);
    }

    detectSignaturePad() {
        try {
            SigWeb.setTabletState(1);
            if (parseInt(SigWeb.getTabletState()) !== 1) {
                this.setState({
                    signaturePadDetected: false,
                    errorMessage: strings.notDetectedError,
                });
                return false;
            }

            const retmod = parseInt(SigWeb.tabletModelNumber());
            SigWeb.setTabletState(0);
            if (retmod !== 11 && retmod !== 12 && retmod !== 15) {
                this.setState({
                    signaturePadDetected: false,
                    errorMessage: strings.notDetectedOrSupportedError,
                });
                return false;
            }

            this.setState({
                signaturePadDetected: true,
                errorMessage: null,
            });
            return true;
        } catch (e) {
            this.setState({
                signaturePadDetected: false,
                errorMessage: strings.sigWebNotRunningError,
            });
            return false;
        }
    }

    // start capturing input from the signature pad
    startCapture() {
        const result = this.detectSignaturePad();
        if (!result) {
            return;
        }

        this.setState({
            signaturePadScreen: SCREENS.AGREEMENT,
        });

        this.lcdX = 0;

        const canvasContext = this.canvas.getContext('2d');
        // this interval has to be cleared for all the API requests to
        // SigWeb's "EventStatus" endpoint to stop, so clear this whenever
        // the signature pad stops being active
        this.eventInterval = window.setInterval(SigWeb.sigWebEvent, 50);
        this.interval = SigWeb.setTabletState(1, canvasContext, 50) || this.interval;
        SigWeb.setLCDCaptureMode(2);
        SigWeb.lcdRefresh(0, 0, 0, 240, 64);
        SigWeb.setJustifyMode(0);
        SigWeb.keyPadClearHotSpotList();
        SigWeb.clearSigWindow(1);
        SigWeb.setDisplayXSize(this.props.width);
        const height = this.getHeight();
        SigWeb.setDisplayYSize(height);
        SigWeb.setImageXSize(this.props.width);
        SigWeb.setImageYSize(height);
        SigWeb.setLCDCaptureMode(2);

        SigWeb.lcdSendGraphicUrl(1, 2, 0, 20, `${BASE_IMAGE_URL}/Sign.bmp`);
        SigWeb.lcdSendGraphicUrl(1, 2, 207, 4, `${BASE_IMAGE_URL}/OK.bmp`);
        SigWeb.lcdSendGraphicUrl(1, 2, 15, 4, `${BASE_IMAGE_URL}/CLEAR.bmp`);

        const lcdSize = SigWeb.lcdGetLCDSize();
        this.lcdX = lcdSize & 0xffff;

        this.writeMessageOnSignaturePad(this.props.message);

        SigWeb.lcdWriteString(0, 2, 15, 45, '9pt Arial', 15, 'Continue');

        SigWeb.keyPadAddHotSpot(0, 1, 12, 40, 40, 15); // Continue

        SigWeb.clearTablet();

        SigWeb.lcdSetWindow(0, 0, 1, 1);
        SigWeb.setSigWindow(1, 0, 0, 1, 1);
        SigWeb.setLCDCaptureMode(2);

        this.setState({
            signaturePadScreen: SCREENS.SIGNATURE,
        });

        SigWeb.setOnSigPenUp(this.processPenUp.bind(this));

        SigWeb.setLCDCaptureMode(2);
    }

    // stop capturing input from the signature pad,and clear its screen
    stopCapture() {
        this.setState({
            signaturePadScreen: null,
        });

        if (this.state.signaturePadDetected) {
            SigWeb.lcdRefresh(0, 0, 0, 240, 64);
            SigWeb.lcdSetWindow(0, 0, 240, 64);
            SigWeb.setSigWindow(1, 0, 0, 240, 64);
            SigWeb.keyPadClearHotSpotList();
            SigWeb.setLCDCaptureMode(1);
            SigWeb.setTabletState(0, this.interval);
        }
    }

    // reset this field and clear the uploaded image regardless of whether
    // the image was captured or uploaded
    reset() {
        window.clearInterval(this.eventInterval);
        this.stopCapture();
        this.setState({
            errorMessage: null,
            imagePath: null,
            file: {},
        });
        if (!!this.props.onRemove) {
            this.props.onRemove();
        }
    }

    processPenUp() {
        if (SigWeb.keyPadQueryHotSpot(0) > 0) {
            // user clicked CONTINUE
            SigWeb.clearSigWindow(1);
            SigWeb.lcdRefresh(1, 16, 45, 50, 15);

            if (this.state.signaturePadScreen === SCREENS.SIGNATURE) {
                SigWeb.lcdRefresh(2, 0, 0, 240, 64);
                SigWeb.clearTablet();
                SigWeb.keyPadClearHotSpotList();
                SigWeb.keyPadAddHotSpot(2, 1, 10, 5, 53, 17); // CLEAR
                SigWeb.keyPadAddHotSpot(3, 1, 197, 5, 19, 17); // OK
                SigWeb.lcdSetWindow(2, 22, 236, 40);
                SigWeb.setSigWindow(1, 0, 22, 240, 40);
            }

            SigWeb.setLCDCaptureMode(2);
        }

        if (SigWeb.keyPadQueryHotSpot(2) > 0) {
            // user clicked CLEAR
            SigWeb.clearSigWindow(1);
            SigWeb.lcdRefresh(1, 10, 0, 53, 17);

            SigWeb.lcdRefresh(2, 0, 0, 240, 64);
            SigWeb.clearTablet();
        }

        if (SigWeb.keyPadQueryHotSpot(3) > 0) {
            // user clicked OK
            SigWeb.clearSigWindow(1);
            SigWeb.lcdRefresh(1, 210, 3, 14, 14);

            // did the recipient write anything onto the signature pad
            // screen?
            if (SigWeb.numberOfTabletPoints() > 0) {
                SigWeb.lcdRefresh(0, 0, 0, 240, 64);
                SigWeb.lcdWriteString(0, 2, 35, 25, '9pt Arial', 15, strings.captureComplete);
                SigWeb.setSigCompressionMode(1);
                SigWeb.setEncryptionMode(2);

                window.clearInterval(this.eventInterval);
                this.saveCapture();
            }
        }

        SigWeb.clearSigWindow(1);
    }

    writeMessageOnSignaturePad(string) {
        const words = string.split(' ');
        let writeData = '';
        let tempData = '';
        let xSize = 0;
        let ySize = 0;
        let i = 0;
        let yPos = 0;

        for (i = 0; i < words.length; i++) {
            tempData += words[i];

            xSize = SigWeb.lcdStringWidth('9pt Arial', tempData);

            if (xSize < this.lcdX) {
                writeData = tempData;
                tempData += ' ';

                xSize = SigWeb.lcdStringWidth('9pt Arial', tempData);

                if (xSize < this.lcdX) {
                    writeData = tempData;
                }
            } else {
                ySize = SigWeb.lcdStringHeight('9pt Arial', tempData);

                SigWeb.lcdWriteString(0, 2, 0, yPos, '9pt Arial', 15, writeData);

                tempData = '';
                writeData = '';
                yPos += ySize;
                i--;
            }
        }

        if (!!writeData) {
            SigWeb.lcdWriteString(0, 2, 0, yPos, '9pt Arial', 15, writeData);
        }
    }

    saveCapture() {
        if (SigWeb.numberOfTabletPoints() > 0) {
            SigWeb.setTabletState(0, this.interval);
            SigWeb.setSigCompressionMode(1);
            SigWeb.setImageXSize(this.props.width);
            SigWeb.setImageYSize(this.getHeight());
            SigWeb.setImagePenWidth(5);
            this.uploadCapturedImage();
        }
    }

    uploadCapturedImage() {
        const blob = dataUriToBlob(this.canvas.toDataURL());
        const formData = new window.FormData();
        formData.append('my-file', blob, 'signature.png');

        // can't use our req() function because it can't automatically fill
        // in the Content-Type header to be multipart/form-data without a
        // boundary - TODO tech debt
        $.ajax({
            contentType: false,
            processData: false,
            headers: {
                Authorization: getAuthorizationHeader(),
            },
            method: 'POST',
            url: '/rms/api/images?title=signature',
            data: formData,
        })
            .done((response) => {
                const file = get(response, 'data[0].file');

                if (file) {
                    const isImage = file.fileCategory === FileCategoryEnum.IMAGE.name;
                    const image = get(response, 'data[0].image');

                    if (isImage && !image) {
                        throw new Error('Invalid upload response');
                    }

                    const id = isImage ? image.id : file.id;

                    this.props.onChange(id);
                    this.props.onSuccess(file.fileCategory);
                    this.setState({
                        imagePath: file.fileWebServerPath,
                        errorMessage: null,
                    });
                } else {
                    throw new Error('Invalid upload response');
                }
            })
            .fail(() => {
                this.setState({
                    errorMessage: strings.canvasUploadError,
                });
            })
            .always(() => {
                this.stopCapture();
            });
    }

    canvasRef(canvas) {
        this.canvas = canvas;
    }

    // handlers for manual upload
    onUploadStart() {
        this.props.onFocus();
        this.props.onStart();
    }

    onUploadSuccess(fileUploadResponses) {
        const file = get(fileUploadResponses, '[0].file');
        if (!file) {
            this.onUploadError();
            return;
        }
        const image = get(fileUploadResponses, '[0].image');
        const { fileCategory } = file;
        const isImage = fileCategory === FileCategoryEnum.IMAGE.name;

        const id = isImage ? image.id : file.id;
        this.props.onChange(id);
        this.props.onBlur(id);
        this.props.onSuccess(fileCategory);

        // TODO: handle non-image, non-pdf file types
        if (file.fileType === FileTypeEnum.PDF.name) {
            this.setState({ file });
        } else {
            this.setState({ imagePath: file.fileWebServerPath });
        }

        this.setState({
            errorMessage: null,
        });
    }

    onUploadError(...args) {
        this.setState({
            errorMessage: strings.manualUploadError,
        });
        this.props.onBlur();
        this.props.onError(...args);
    }

    render() {
        const { width, label, error, className, hasSizeLimits } = this.props;
        const { signaturePadScreen, imagePath, file, errorMessage } = this.state;

        const height = this.getHeight();
        const signaturePadActive = !!signaturePadScreen;
        const hasImage = !!imagePath;
        const pdfPath = file.fileWebServerPath;
        const hasPdf = !!pdfPath;

        return (
            <div className={className}>
                {label && <div className="mark43-form-label mark43-form-row-label">{label}</div>}
                {!hasImage && !hasPdf && (
                    <CanvasContainer width={width} height={height}>
                        <canvas ref={this.canvasRef.bind(this)} width={width} height={height} />
                        <CanvasOverlay
                            width={width}
                            height={height}
                            signaturePadActive={signaturePadActive}
                        >
                            {!signaturePadActive && (
                                <div>
                                    <Button
                                        className={buttonTypes.SECONDARY}
                                        onClick={this.startCapture.bind(this)}
                                    >
                                        {strings.capture}
                                    </Button>
                                    <Upload
                                        multiple={false}
                                        onStart={this.onUploadStart.bind(this)}
                                        onSuccess={this.onUploadSuccess.bind(this)}
                                        onError={this.onUploadError.bind(this)}
                                        testId={testIds.SIGNATURE_PAD_UPLOAD}
                                    >
                                        <UploadButton className={buttonTypes.SECONDARY}>
                                            {strings.upload}
                                        </UploadButton>
                                    </Upload>
                                </div>
                            )}
                            {signaturePadActive && <FloatLoadingGray />}
                        </CanvasOverlay>
                    </CanvasContainer>
                )}
                {hasImage && <PreviewImage src={imagePath} width={width} />}
                {hasSizeLimits && !hasImage && (
                    <SignatureHelpText variant="bodyMd">
                        <TextStyle fontStyle="italic">{strings.signatureUpload}</TextStyle>
                    </SignatureHelpText>
                )}
                {hasPdf && (
                    <ChainOfCustodyPdfLink
                        filePath={pdfPath}
                        fileName={file.originalFileName}
                        width={width}
                    />
                )}
                {(hasImage || hasPdf) && (
                    <Button
                        className={buttonTypes.SECONDARY}
                        iconLeft={<Icon type={iconTypes.TRASH} />}
                        onClick={this.reset.bind(this)}
                    >
                        {strings.remove}
                    </Button>
                )}
                {signaturePadActive && (
                    <Button className={buttonTypes.SECONDARY} onClick={this.reset.bind(this)}>
                        {strings.cancel}
                    </Button>
                )}
                {errorMessage && (
                    <InlineBanner status="error">
                        {
                            errorMessage // from this component itself
                        }
                    </InlineBanner>
                )}
                {error && (
                    <InlineBanner status="error">
                        {
                            error // from outside (used for field validation)
                        }
                    </InlineBanner>
                )}
            </div>
        );
    }
}

const SignaturePad = styled(_SignaturePad)`
    width: ${(props) => props.width}px;

    &,
    * {
        box-sizing: border-box;
    }
`;

SignaturePad.defaultProps = {
    onChange: noop,
    onFocus: noop,
    onBlur: noop,
    onStart: noop,
    onSuccess: noop,
    onError: noop,
};

/**
 * This component allows the user to capture a signature from someone else
 *   through a physical signature pad, or they can manually upload an image as a
 *   fallback. See the Confluence page for details.
 * @param {number} width The canvas has this width, with a calculated height of
 *   this width divided by 5, and 1 to 2 pixels on each edge are covered by a
 *   border from another div.
 * @param {string} label
 * @param {string} message The message to display to the user before they click
 *   Continue and write their signature, it can be like "I agree to these
 *   terms."
 * @param {string} [error]
 */
export default SignaturePad;

export const RRFSignaturePad = connectRRFInput(SignaturePad);

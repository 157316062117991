import styled from 'styled-components';
import _Icon from '../../../../core/components/Icon';
import _Link from '../../../../core/components/links/Link';
import _ApprovalStatusIcon from '../ApprovalStatusIcon';

export const LinkedRecordBodyContainer = styled.div`
    display: inline-block;
    width: calc(100% - 30px);
`;

export const LinkedRecordContainer = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 8px;
`;

export const ApprovalStatusIcon = styled(_ApprovalStatusIcon)`
    margin-left: -1px;
    margin-right: 9px;
    margin-top: 3px;
    vertical-align: top;
`;

export const Icon = styled(_Icon)`
    margin-top: 5px;
    margin-left: -1px;
    margin-right: 9px;
    height: 21px;
    width: 21px;
    vertical-align: top;
    fill: ${(props) =>
        props.disabled ? props.theme.colors.disabled : props.theme.colors.cobaltBlue};
`;

export const DisplayDate = styled.span`
    display: inline-block;
`;

export const Link = styled(_Link)`
    font-size: var(--arc-fontSizes-md);

    &:hover {
        text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
    }
`;

export const Subheader = styled.div`
    color: ${(props) => props.theme.colors.mediumGrey};
    font-style: italic;
    font-size: var(--arc-fontSizes-md);
`;

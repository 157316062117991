import React from 'react';
import _ from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';

const strings = componentStrings.entityProfiles.person.MilitaryDetails;

function mapMilitaryHistoryRows(militaryHistories) {
    return _.map(militaryHistories, (history) => {
        const { dateRange } = getViewModelProperties(history);
        const militaryBranch = _.get(history, 'employerName');
        return (
            <SummaryRow key={history.id} label={strings.labels.militaryBranch}>
                <div>{militaryBranch}</div>
                <div>{dateRange}</div>
            </SummaryRow>
        );
    });
}

export default function MilitaryDetails({ militaryHistories }) {
    const partitionedMilitaryHistories = _.partition(
        militaryHistories,
        (history, index) => index % 2
    );
    const leftMilitaryHistoryRows = mapMilitaryHistoryRows(partitionedMilitaryHistories[1]);
    const rightMilitaryHistoryRows = mapMilitaryHistoryRows(partitionedMilitaryHistories[0]);

    return (
        <EntityProfileSection title={strings.title}>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    {leftMilitaryHistoryRows}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    {rightMilitaryHistoryRows}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}

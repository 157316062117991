import * as React from 'react';
import EsriConfig from '@arcgis/core/config';

/**
 * Component which will set required Esri configuration globally, via the config singleton provided by Esri's SDK.
 */
export function EsriConfiguration({
    children,
    apiKey,
}: React.PropsWithChildren<{ apiKey: string | undefined }>): JSX.Element {
    React.useEffect(() => {
        if (apiKey) {
            EsriConfig.apiKey = apiKey;
        }
    }, [apiKey]);

    return <>{children}</>;
}

import React from 'react';
import { first, get } from 'lodash';
import styled from 'styled-components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

const CellWrapper = styled.div`
    margin-left: 16px;
`;
const DispositionStatus = styled.div`
    color: ${(props) => props.theme.colors.darkGreen};
`;

const LocationCell = (evidenceItem) => {
    const currentChainEvent = first(get(evidenceItem, 'currentChainEvent'));
    const viewModel = getViewModelProperties(evidenceItem);

    return (
        <CellWrapper>
            <div>{viewModel.eventType}</div>
            <div>
                {viewModel.storageLocationNames ||
                    viewModel.facility ||
                    (currentChainEvent && currentChainEvent.receivedByName) ||
                    viewModel.receivedByEntityName}
            </div>
            <DispositionStatus>{viewModel.currentDispositionStatus}</DispositionStatus>
        </CellWrapper>
    );
};

export default LocationCell;

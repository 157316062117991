import { SystemRuleEnum } from '@mark43/rms-api';
import { warrantChargesWhereSelector } from '~/client-common/core/domain/warrant-charges/state/data';
import { currentWarrantIdSelector } from '../ui';
import addRuleId from '../../../../../legacy-redux/validation/helpers/addRuleId';

export const warrantRequiresCharges = addRuleId(
    SystemRuleEnum.WARRANT_REQUIRES_CHARGES.name,
    (formData, value, state) => {
        const currentWarrantId = currentWarrantIdSelector(state);
        if (!currentWarrantId) {
            return true;
        }
        const warrantCharges = warrantChargesWhereSelector(state)({
            warrantId: currentWarrantId,
        });
        return warrantCharges.length > 0;
    }
);

import { orderBy, sortBy } from 'lodash';
import { ReportShortTitle } from '@mark43/rms-api';

export const sortReportShortTitleViewModels = (reportShortTitleViewModels: ReportShortTitle[]) =>
    sortBy(reportShortTitleViewModels, ['shortTitle', 'id']);

export const sortReportShortTitleViewModelsAlphAsc = (
    reportShortTitleViewModels: ReportShortTitle[]
) => orderBy(reportShortTitleViewModels, ['shortTitle', 'id'], ['asc', 'asc']);

export const sortReportShortTitleViewModelsAlphDesc = (
    reportShortTitleViewModels: ReportShortTitle[]
) => orderBy(reportShortTitleViewModels, ['shortTitle', 'id'], ['desc', 'asc']);

export const sortReportShortTitleViewModelsCreatedAsc = (
    reportShortTitleViewModels: ReportShortTitle[]
) => orderBy(reportShortTitleViewModels, ['createdDateUtc', 'id'], ['asc', 'asc']);

export const sortReportShortTitleViewModelsCreatedDesc = (
    reportShortTitleViewModels: ReportShortTitle[]
) => orderBy(reportShortTitleViewModels, ['createdDateUtc', 'id'], ['desc', 'asc']);

export const sortReportShortTitleViewModelsModifiedAsc = (
    reportShortTitleViewModels: ReportShortTitle[]
) => orderBy(reportShortTitleViewModels, ['updatedDateUtc', 'id'], ['asc', 'asc']);

export const sortReportShortTitleViewModelsModifiedDesc = (
    reportShortTitleViewModels: ReportShortTitle[]
) => orderBy(reportShortTitleViewModels, ['updatedDateUtc', 'id'], ['desc', 'asc']);

import { combineReducers } from 'redux';

import authActionTypes from '../actions/types/authActionTypes';
import actionTypes from '../actions/types/userProfileAdminActionTypes';

function userProfileReducer(state = null, action) {
    switch (action.type) {
        case authActionTypes.BOOTSTRAP_SUCCESS:
            return {
                ...action.payload.userData.userProfile,
            };
        case actionTypes.SAVE_USER_PROFILE_SUCCESS:
            const { userId: currentUserId } = state || {};
            const { userId: savedUserId } = action.payload;
            if (currentUserId !== savedUserId) {
                return state;
            }
            return action.payload;
        default:
            return state;
    }
}

function ssoLogoutUrlReducer(state = '', action) {
    switch (action.type) {
        case authActionTypes.BOOTSTRAP_SUCCESS:
            return action.payload.userData.ssoV2LogoutUrl || '';
        default:
            return state;
    }
}

export const userDataReducer = combineReducers({
    profile: userProfileReducer,
    ssoLogoutUrl: ssoLogoutUrlReducer,
});

import _ from 'lodash';
import $ from 'jquery';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import {
    formatFieldValue,
    formatFieldValueRRF,
    formDataIsEmpty,
    nItemFormDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
    formatNItemFieldValues,
} from '~/client-common/helpers/formHelpers';

const { FIELDSET, N_FIELDSETS, N_ITEMS_FIELDSET } = fieldTypeClientEnum;

/**
 * Return whether the given object has the shape of a form field (either Redux
 *   Form or our custom field objects). This is done by checking for the
 *   existence of a property called `value` or `visited`, which may break if
 *   redux-form's API changes.
 * @param  {Object} object
 * @return {boolean}
 */
export function isFormField(object) {
    return _.has(object, 'value') || _.has(object, 'visited');
}

/**
 * Compute a display string for a field's value.
 * @param  {Object} fieldViewModel
 * @param  {Object} format Display string formatting function that come from
 *   selectors, which are needed when the field value is an id of an object
 *   stored in state.
 * @return {string}
 */
export { formatFieldValue };

/**
 * Compute a display string for a field's value.
 * @param  {*}      [value]
 * @param  {Object} fieldViewModel
 * @param  {Object} format Display string formatting function that come from
 *   selectors, which are needed when the field value is an id of an object
 *   stored in state.
 * @return {string}
 */
export { formatFieldValueRRF };

export { formatNItemFieldValues };

/**
 * Build form field objects with useful properties such as labels. No nested
 *   fields allowed.
 * @param  {Array} fieldsOrKeys Each element may be a string (a field key) or an
 *   object with `key` being the only required property.
 * @return {Object} Object with field keys.
 */
export { buildFlatFormFieldViewModels };

/**
 * Build redux-form state from form data and form field view models.
 * @param  {Object|Object[]} [data] Form data (with no `value` properties).
 * @param  {Object} fieldViewModels May be nested.
 * @return {Object|Object[]} Redux-form state.
 */
export function buildFormState(data = {}, fieldViewModels = {}) {
    const mapCollection = _.isArray(data) ? _.map : _.mapValues;

    return mapCollection(fieldViewModels, (fieldViewModel = {}, index) => {
        const { type, key, fields } = fieldViewModel;

        if (type === N_FIELDSETS || type === N_ITEMS_FIELDSET) {
            // array
            return _.map(data[index], (item) => buildFormState(item, fields));
        } else if (type === FIELDSET) {
            // nested fields with a parent structure (fieldset), where the field
            // view models are one level deeper
            return buildFormState(data[index] || {}, fields);
        } else if (key) {
            // single field
            return { value: data[index] };
        } else {
            // nested fields without parent structure
            return buildFormState(data[index] || {}, fieldViewModel);
        }
    });
}

/**
 * Build form model state from data and field view models.
 * @param  {Object|Object[]} [data]
 * @param  {Object}          [fieldViewModels] May be nested.
 * @return {Object|Object[]}
 */
export { buildFormModel };

/**
 * Return whether the given field object has an empty value.
 * @param  {Object} field
 * @return {boolean}
 */
export function fieldIsEmpty({ value } = {}) {
    return (
        _.isUndefined(value) ||
        _.isNull(value) ||
        value === '' ||
        (_.isArray(value) && value.length === 0)
    );
}

/**
 * Return whether the given fields are all empty.
 * @param  {Object[]} fields
 * @return {boolean}
 */
export function fieldsAreEmpty(fields) {
    return _.every(fields, fieldIsEmpty);
}

/**
 * Return whether the `NItems` item is empty, as in whether every field (or
 *   nested fields) inside it is empty.
 * @param  {Object} item
 * @return {boolean}
 */
export function nItemIsEmpty(item) {
    return _.every(item, (field) =>
        isFormField(field) ? fieldIsEmpty(field) : nItemIsEmpty(field)
    );
}

/**
 * Return whether the given form data, which may be a single field or multiple
 *   fields in an object (with no `value` properties) is empty. This function
 *   covers the general cases including an N item and you may need to use a
 *   custom function for a complicated data shape like a nested object, or call
 *   this function on a deeper level within your nested form data object.
 * @param  {Object|Object[]} field
 * @return {boolean}
 */
export { formDataIsEmpty };

/**
 * Return whether the given form data representing an N item is empty. This
 *   function covers the general case and you may need to use a custom function
 *   for complicated data shapes.
 * @param  {Object}  formData
 * @return {boolean}
 */
export { nItemFormDataIsEmpty };

/**
 * Return whether the given form data representing an N item is not empty. This
 *   function covers the general case and you may need to use a custom function
 *   for complicated data shapes.
 * @param  {Object}  formData
 * @return {boolean}
 */
export function nItemFormDataIsNonEmpty(formData) {
    return !_.every(formData, formDataIsEmpty);
}

/**
 * Recursively filter for non-empty form data in the given object or array; all
 *   empty data gets removed in the resulting object or array.
 * @param  {Object|Object[]} formData
 * @return {Object|Object[]} Filtered form data.
 */
export { filterFormData };

/**
 * Filter for non-empty items from an instance of the `NItems` component.
 * @param  {Object[]}               data            Form data of an entire form,
 *   not just the n-items.
 * @param  {string|string[]}        path            The property name or path to
 *   the field in the form that contains the n-items. This gets passed in as the
 *   second argument to `_.get` on the form data.
 * @param  {function|Object|string} filterCondition Condition for an item to be
 *   included and not removed. This gets passed in as the second argument to
 *   `_.filter` on the items.
 * @return {Object[]}
 */
export function filterNItemsFormData(data, path, filterCondition = nItemFormDataIsNonEmpty) {
    const items = _.get(data, path);

    if (items && items.length > 0) {
        const clonedData = _.cloneDeep(data);
        _.set(clonedData, path, _.filter(items, filterCondition));
        return clonedData;
    }

    return data;
}

/**
 * Helper for formtabbing, and allowing custom DOM elements to be treated
 * as inputs within a form.
 *
 * @param {Object}      function        Callback returning a jquery object of tabbable elements in the form.
 * @param {Object}      e               The keydown event
 * @param {Object}      extraClickables JSQuery object of items that you
 *   wish to treat enter keys as clicks for.
 * @param {number}      tabIndex        tabindex, modals and sidepanels should be lower than forms on the main page
 */
export function tabHelper(getElems, e, extraClickables, tabIndex, hijackTabbing) {
    const elems = getElems();
    // make all the elements focusable
    elems.attr('tabindex', 0);
    const firstInput = elems.first();
    const lastInput = elems.last();
    if (hijackTabbing && e.which === keyCodeEnum.TAB) {
        if (!e.shiftKey && lastInput[0] === e.currentTarget) {
            e.preventDefault();
            firstInput.focus();
        } else if (e.shiftKey && firstInput[0] === e.currentTarget) {
            e.preventDefault();
            lastInput.focus();
        } else if (!_(elems.toArray()).find((elem) => elem === e.currentTarget)) {
            // focus on the active input set if we're not focused anywhere
            e.preventDefault();
            firstInput.focus();
        }
    } else if (e.which === keyCodeEnum.ENTER && !e.shiftKey) {
        const targetIndex = _.findIndex(extraClickables.toArray(), (el) => el === e.currentTarget);
        if (targetIndex < 0) {
            return;
        }
        e.preventDefault();
        $(e.currentTarget).trigger('click');
        // Clicking links will often remove or add inputs while hiding the link,
        // so we need a way to update our focus. This focuses the first new element
        // in the updated set of inputs
        const newElems = getElems();
        newElems.attr('tabindex', tabIndex);
        let newestElement = null;
        elems.toArray().forEach((elem, i) => {
            if (newestElement || i >= newElems.length) {
                return;
            } else if (elem !== newElems[i]) {
                newestElement = newElems[i];
            } else if (i === elems.length - 1 && newElems.length > elems.length) {
                newestElement = newElems[i + 1];
            }
        });
        if (newestElement) {
            newestElement.focus();
        }
    }
}

// core deps
import { createSelector } from 'reselect';
import _, { map, mapValues } from 'lodash';

// selectors
import { elasticPersonsSelector } from '../data';
import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '../../../attributes/state/data';
import { formatLocationLinkTypeId } from '../../../../../helpers/linkTypesHelpers';

// view model helpers
import {
    buildViewModel,
    allAttributeIdListsMapper,
    subdivisionAttrIdsMapper,
    buildJoinValuesMapper,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';

// other helpers
import { dateOfBirthToAge } from '../../../../dates/utils/dateHelpers';
import { formatFullName } from '../../../person-profiles/utils/personProfilesHelpers';

export const buildElasticPersonViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
    (formatAttributeById, formatSubdivisionAttrIds) =>
        buildViewModel({
            recursive: true,
            mappers: [
                allAttributeIdListsMapper,
                subdivisionAttrIdsMapper,
                allSingleAttributeValuesMapper,
                (obj) => ({
                    fullName: formatFullName(obj),
                }),
                buildJoinValuesMapper('nicknames'),
                ({ dateOfBirth }) => ({
                    age: dateOfBirthToAge(dateOfBirth),
                }),
                (obj) =>
                    _(obj)
                        .pick('type')
                        .mapValues((type) => formatLocationLinkTypeId(type))
                        .value(),
            ],
            helpers: {
                formatAttributeById,
                formatSubdivisionAttrIds,
            },
        })
);

export const elasticPersonViewModelsSelector = createSelector(
    elasticPersonsSelector,
    buildElasticPersonViewModelSelector,
    (elasticPersons, buildElasticPersonViewModel) =>
        mapValues(elasticPersons, buildElasticPersonViewModel)
);

export function createPersonsResultsSelector(baseSelector) {
    return createSelector(
        baseSelector,
        buildElasticPersonViewModelSelector,
        (personResults, buildElasticPersonViewModel) =>
            map(personResults, buildElasticPersonViewModel)
    );
}

import { UseOfForceSubjectDeEscalation } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'useOfForceSubjectDeEscalations';
const useOfForceSubjectDeEscalationsModule = createNormalizedModule<UseOfForceSubjectDeEscalation>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const replaceUseOfForceSubjectDeEscalations = ({
    subjectId,
    useOfForceSubjectDeEscalationsToReplaceWith = [],
}: {
    subjectId: number;
    useOfForceSubjectDeEscalationsToReplaceWith: UseOfForceSubjectDeEscalation[];
}): ClientCommonAction<void> => {
    return (dispatch) => {
        const removeConditions = { subjectId };

        dispatch(
            useOfForceSubjectDeEscalationsModule.withRemove(
                NEXUS_STATE_PROP,
                removeConditions,
                useOfForceSubjectDeEscalationsModule.withEntityItems(
                    {
                        [NEXUS_STATE_PROP]: useOfForceSubjectDeEscalationsToReplaceWith,
                    },
                    {
                        type: 'REPLACE_USE_OF_FORCE_SUBJECT_DE_ESCALATIONS',
                    }
                )
            )
        );
    };
};

// SELECTORS
export const useOfForceSubjectDeEscalationsWhereSelector =
    useOfForceSubjectDeEscalationsModule.selectors.entitiesWhereSelector;

// REDUCERS
export default useOfForceSubjectDeEscalationsModule.reducerConfig;

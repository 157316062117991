import Promise from 'bluebird';

import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import resource from '../resources/locationsResource';
import actionTypes from './types/locationsActionTypes';

function selectLocationStart(id) {
    return {
        type: actionTypes.SELECT_LOCATION_START,
        payload: id,
    };
}

function selectLocationSuccess(location) {
    return {
        type: actionTypes.SELECT_LOCATION_SUCCESS,
        payload: location,
    };
}

function selectLocationFailure(err) {
    return {
        type: actionTypes.SELECT_LOCATION_FAILURE,
        error: true,
        payload: err,
    };
}

export function selectLocationById(id, { forceRequest = false } = {}) {
    return function (dispatch, getState) {
        dispatch(selectLocationStart(id));

        if (!forceRequest) {
            const locationWithEntityLinks = locationsSelector(getState())[id];

            if (locationWithEntityLinks) {
                return Promise.resolve(dispatch(selectLocationSuccess(locationWithEntityLinks)));
            }
        }

        return resource
            .getLocation(id)
            .then((location) => {
                return dispatch(selectLocationSuccess(location));
            })
            .catch((err) => dispatch(selectLocationFailure(err)));
    };
}

function getCenterlineFileStart(centerlineFile) {
    return {
        type: actionTypes.DOWNLOAD_LOCATION_STREET_CENTERLINE_START,
        payload: centerlineFile,
    };
}

function getCenterlineFileSuccess(centerlineFile) {
    return {
        type: actionTypes.DOWNLOAD_LOCATION_STREET_CENTERLINE_SUCCESS,
        payload: centerlineFile,
    };
}

function getCenterlineFileFailure(err) {
    return {
        type: actionTypes.DOWNLOAD_LOCATION_STREET_CENTERLINE_FAILURE,
        error: true,
        payload: err,
    };
}

function getCenterlineFailure(err) {
    return {
        type: actionTypes.GET_LOCATION_STREET_CENTERLINE_MAPPING_FAILURE,
        error: true,
        payload: err,
    };
}

export function getCenterlineFile() {
    return async function (dispatch) {
        const centerlineMapping = await resource.getCenterline();
        
        try {
            const centerlineFileId = centerlineMapping.fileId;
            dispatch(getCenterlineFileStart(centerlineFileId));
            return resource
                .getCenterlineFile(centerlineFileId)
                .then((file) => {
                window.open(file.fileWebServerPath, '_blank');
                dispatch(getCenterlineFileSuccess(centerlineFileId));
                })
                .catch((err) => dispatch(getCenterlineFileFailure(err)));
        }
        catch(err) {
            dispatch(getCenterlineFailure(err));
        }

        return null;
    }
}
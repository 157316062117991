import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash';

import { AttributeTypeEnum, CodeTypeCategoryEnum, ComplianceGroupEnum } from '@mark43/rms-api';
import * as fields from '~/client-common/core/enums/universal/fields';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
    formatLinkAttributesSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import { hydratedStopByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/stops';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH,
    STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH,
    STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH,
    STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH,
    STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH,
    STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH,
    STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH,
} from '~/client-common/core/domain/stop-anonymous-subjects/utils/stopAnonymousSubjectHelpers';
import { filterStopEntityAttributes } from '~/client-common/core/domain/stop-entity-attributes/utils/stopEntityAttributesHelpers';
import { formatRipaOffenseCodeByIdSelector } from '~/client-common/core/domain/ripa-offense-codes/state/ui';
import { filterRipaOffenseCodeIds } from '~/client-common/core/domain/ripa-offense-codes/utils/ripaOffenseCodesHelpers';
import { filterRipaSubjectOffenseCodes } from '~/client-common/core/domain/ripa-subject-offense-codes/utils/ripaSubjectOffenseCodesHelpers';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import testIds from '../../../../../core/testIds';
import WithComplianceGroup from '../../../../core/components/WithComplianceGroup';
import StopCardLocationSection from './StopCardLocationSection';

const strings = componentStrings.reports.core.StopCard;

const mapStateToProps = createStructuredSelector({
    formatAttributeById: formatAttributeByIdSelector,
    formatAttributeWithOther: formatAttributeWithOtherSelector,
    formatLinkAttributes: formatLinkAttributesSelector,
    formatOffenseCodeById: formatOffenseCodeByIdSelector,
    hydratedStopByReportId: hydratedStopByReportIdSelector,
    formatRipaOffenseCodeById: formatRipaOffenseCodeByIdSelector,
});

const StopCardSummary = connect(mapStateToProps)(
    ({
        reportId,
        hydratedStopByReportId,
        formatAttributeById,
        formatAttributeWithOther,
        formatLinkAttributes,
        formatRipaOffenseCodeById,
    }) => {
        const {
            stop,
            reportAttributes,
            stopAnonymousSubjects,
            stopEntityAttributes,
            ripaSubjectOffenseCodes,
        } = hydratedStopByReportId(reportId);

        if (!stop) {
            return null;
        }

        return (
            <SummaryList labelWidth={220} contentWidth={320}>
                <CardSection
                    title={strings.stopIncidentSectionTitle}
                    testId={testIds.STOP_INCIDENT_SUMMARY_SECTION}
                >
                    <SummaryRow fieldName={fields.STOP_ATL_Q_1}>
                        {booleanToYesNo(stop.atlQ1)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_ATL_Q_2}>
                        {booleanToYesNo(stop.atlQ2)}
                    </SummaryRow>
                    <SummaryRowDate
                        fieldName={fields.STOP_START_DATE_UTC}
                        date={stop.startDateUtc}
                    />
                    <SummaryRowDate fieldName={fields.STOP_END_DATE_UTC} date={stop.endDateUtc} />
                    <SummaryRow fieldName={fields.STOP_WAS_RESPONSE_TO_CALL_FOR_SERVICE}>
                        {booleanToYesNo(stop.wasResponseToCallForService)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_CONTACT_TYPE_ATTR_ID}>
                        {formatAttributeById(stop.contactTypeAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_REASON_FOR_STOP_ATTR_ID}>
                        {formatAttributeWithOther({
                            attributeId: stop.reasonForStopAttrId,
                            other: stop.reasonForStopOther,
                        })}
                    </SummaryRow>
                    <WithComplianceGroup
                        complianceGroup={ComplianceGroupEnum.CALIFORNIA.name}
                        fallback={
                            <SummaryRow fieldName={fields.STOP_NARRATIVE_1}>
                                {stop.narrative1}
                            </SummaryRow>
                        }
                    />
                    <SummaryRow fieldName={fields.STOP_WAS_SUBJECT_SCREENED_ATTR_ID}>
                        {formatAttributeById(stop.wasSubjectScreenedAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_WAS_SUBJECT_SEARCHED}>
                        {booleanToYesNo(stop.wasSubjectSearched)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_WAS_SEARCH_CONSENTED}>
                        {booleanToYesNo(stop.wasSearchConsented)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_FORCE_RESULTED_IN_INJURY}>
                        {booleanToYesNo(stop.forceResultedInInjury)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_WAS_CONTRABAND_DISCOVERED}>
                        {booleanToYesNo(stop.wasContrabandDiscovered)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_CONTRABAND_TYPE_ATTR_ID}>
                        {formatAttributeWithOther({
                            attributeId: stop.contrabandTypeAttrId,
                            other: stop.contrabandTypeOther,
                        })}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_RESULT_OF_STOP_ATTR_ID}>
                        {formatAttributeById(stop.resultOfStopAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_ARREST_BASED_ON_ATTR_ID}>
                        {formatAttributeById(stop.arrestBasedOnAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_WAS_RACE_KNOWN}>
                        {booleanToYesNo(stop.wasRaceKnown)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_VIOLATION_NUMBER}>
                        {stop.violationNumber}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_NARRATIVE_2}>{stop.narrative2}</SummaryRow>
                    <SummaryRow fieldName={fields.STOP_NARRATIVE_3}>{stop.narrative3}</SummaryRow>
                    <SummaryRow fieldName={fields.STOP_NARRATIVE_4}>{stop.narrative4}</SummaryRow>
                    <SummaryRow fieldName={fields.STOP_NARRATIVE_5}>{stop.narrative5}</SummaryRow>
                    <SummaryRow fieldName={fields.STOP_WAS_PROPERTY_SEARCH_CONSENTED}>
                        {booleanToYesNo(stop.wasPropertySearchConsented)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_INVOLVED_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.WEAPON_INVOLVED.name
                        )}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_DISPOSITION_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name
                        )}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_WAS_INFORMATION_COLLECTED_IN_OFFENSE_REPORT}>
                        {booleanToYesNo(stop.wasInformationCollectedInOffenseReport)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_WAS_STOP_INVOLVED}>
                        {booleanToYesNo(stop.wasStopInvolved)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_WAS_WELFARE_WELLNESS_CHECK_INVOLVED}>
                        {booleanToYesNo(stop.wasWelfareWellnessCheckInvolved)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_TYPE_OF_STOP_ATTR_ID}>
                        {formatAttributeById(stop.typeOfStopAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_REASON_FOR_STOP_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.STOP_REASON_FOR_STOP.name
                        )}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.STOP_WAS_ARREST_INVOLVED}>
                        {booleanToYesNo(stop.wasArrestInvolved)}
                    </SummaryRow>
                </CardSection>
                <WithComplianceGroup complianceGroup={ComplianceGroupEnum.CALIFORNIA.name}>
                    <StopCardLocationSection summaryMode={true} stopId={stop.id} stop={stop} />

                    <CardSection
                        title={strings.stopOfficerSectionTitle}
                        testId={testIds.STOP_OFFICER_SUMMARY_SECTION}
                    >
                        <SummaryRow fieldName={fields.STOP_USER_YEARS_OF_EXPERIENCE}>
                            {stop.userYearsOfExperience}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.STOP_USER_ASSIGNMENT_TYPE_ATTR_ID}>
                            {formatAttributeWithOther({
                                attributeId: stop.userAssignmentTypeAttrId,
                                other: stop.userAssignmentTypeOther,
                            })}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_DATA_OFFICER_RACE_ATTRIBUTE_ID
                            }
                        >
                            {formatLinkAttributes(
                                stopEntityAttributes,
                                AttributeTypeEnum.STOP_DATA_OFFICER_RACE.name
                            )}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_DATA_OFFICER_GENDER_ATTRIBUTE_ID
                            }
                        >
                            {formatLinkAttributes(
                                stopEntityAttributes,
                                AttributeTypeEnum.STOP_DATA_OFFICER_GENDER.name
                            )}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.STOP_IS_NONBINARY_OFFICER}>
                            {booleanToYesNo(stop.isNonbinaryOfficer)}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.STOP_IS_NON_PRIMARY_AGENCY_IN_CONJUNCTION_NON_REPORTING_AGENCY
                            }
                        >
                            {booleanToYesNo(stop.isNonPrimaryAgencyInConjunctionNonReportingAgency)}
                        </SummaryRow>
                    </CardSection>
                    {map(stopAnonymousSubjects, (stopAnonymousSubject) => {
                        const filteredStopEntityAttributes = filterStopEntityAttributes({
                            stopEntityAttributes,
                            stopAnonymousSubject,
                        });
                        const filteredRipaSubjectOffenseCodes = filterRipaSubjectOffenseCodes({
                            ripaSubjectOffenseCodes,
                            stopAnonymousSubject,
                        });
                        return (
                            <CardSection
                                title={strings.stopAnonymousSubjectSectionTitle}
                                key={stopAnonymousSubject.id}
                                testId={testIds.STOP_ANONYMOUS_SUBJECT_SUMMARY_SECTION}
                            >
                                <SummaryRow
                                    fieldName={fields.STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT}
                                >
                                    {booleanToYesNo(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_DISABILITY_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.SUBJECT_DATA_DISABILITY.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY
                                    }
                                >
                                    {booleanToYesNo(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_RACE_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.SUBJECT_DATA_RACE.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID
                                    }
                                >
                                    {formatAttributeById(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.STOP_ANONYMOUS_SUBJECT_IS_NONBINARY}>
                                    {booleanToYesNo(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.STOP_ANONYMOUS_SUBJECT_IS_UNHOUSED}>
                                    {booleanToYesNo(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.STOP_ANONYMOUS_SUBJECT_IS_PASSENGER}>
                                    {booleanToYesNo(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.STOP_ANONYMOUS_SUBJECT_IS_RESIDENCE}>
                                    {booleanToYesNo(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE}>
                                    {
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH
                                        ]
                                    }
                                </SummaryRow>
                                <SummaryRow fieldName={fields.STOP_ANONYMOUS_SUBJECT_IS_STUDENT}>
                                    {booleanToYesNo(
                                        stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID
                                    }
                                >
                                    {formatAttributeById(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE
                                    }
                                >
                                    {
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH
                                        ]
                                    }
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_REASON_GIVEN_TO_STOPPED_PERSON_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum
                                            .SUBJECT_DATA_REASON_GIVEN_TO_STOPPED_PERSON.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID
                                    }
                                >
                                    {formatAttributeById(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_REASON_FOR_STOP_1_RIPA_OFFENSE_CODE_ID
                                    }
                                >
                                    {formatRipaOffenseCodeById(
                                        filterRipaOffenseCodeIds({
                                            ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                                            codeTypeCategory:
                                                CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
                                            code: '1',
                                        })
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID
                                    }
                                >
                                    {formatAttributeById(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID
                                    }
                                >
                                    {formatAttributeById(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_REASON_FOR_STOP_2_RIPA_OFFENSE_CODE_ID
                                    }
                                >
                                    {formatRipaOffenseCodeById(
                                        filterRipaOffenseCodeIds({
                                            ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                                            codeTypeCategory:
                                                CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
                                            code: '5',
                                        })
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_SUSPICION_TYPE_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.STOP_SUSPICION_TYPE.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_PROBABLE_CAUSE_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.SUBJECT_DATA_PROBABLE_CAUSE.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_REASON_FOR_STOP_7_RIPA_OFFENSE_CODE_ID
                                    }
                                >
                                    {formatRipaOffenseCodeById(
                                        filterRipaOffenseCodeIds({
                                            ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                                            codeTypeCategory:
                                                CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
                                            code: '7',
                                        })
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_ACTION_TAKEN_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.STOP_ACTION_TAKEN.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_NON_FORCIBLE_ACTION_TAKEN_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.STOP_NON_FORCIBLE_ACTION_TAKEN.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={fields.STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED}
                                >
                                    {booleanToYesNo(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED
                                    }
                                >
                                    {booleanToYesNo(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_SEARCH_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.REASON_FOR_SEARCH.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={fields.STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID}
                                >
                                    {formatAttributeById(
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH
                                        ]
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE
                                    }
                                >
                                    {
                                        stopAnonymousSubject[
                                            STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH
                                        ]
                                    }
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_CONTRABAND_OR_EVIDENCE_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.SUBJECT_DATA_CONTRABAND_OR_EVIDENCE.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_BASIS_FOR_PROPERTY_SEIZURE_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.STOP_BASIS_FOR_PROPERTY_SEIZURE.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_TYPE_OF_PROPERTY_SEIZED_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.STOP_TYPE_OF_PROPERTY_SEIZED.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_FORCIBLE_ACTION_TAKEN_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.STOP_FORCIBLE_ACTION_TAKEN.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_RESULT_OF_STOP_ATTRIBUTE_ID
                                    }
                                >
                                    {formatLinkAttributes(
                                        filteredStopEntityAttributes,
                                        AttributeTypeEnum.SUBJECT_DATA_RESULT_OF_STOP.name
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_2_RIPA_OFFENSE_CODE_ID
                                    }
                                >
                                    {formatRipaOffenseCodeById(
                                        filterRipaOffenseCodeIds({
                                            ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                                            codeTypeCategory:
                                                CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                                            code: '2',
                                        })
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_3_RIPA_OFFENSE_CODE_ID
                                    }
                                >
                                    {formatRipaOffenseCodeById(
                                        filterRipaOffenseCodeIds({
                                            ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                                            codeTypeCategory:
                                                CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                                            code: '3',
                                        })
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_4_RIPA_OFFENSE_CODE_ID
                                    }
                                >
                                    {formatRipaOffenseCodeById(
                                        filterRipaOffenseCodeIds({
                                            ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                                            codeTypeCategory:
                                                CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                                            code: '4',
                                        })
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_6_RIPA_OFFENSE_CODE_ID
                                    }
                                >
                                    {formatRipaOffenseCodeById(
                                        filterRipaOffenseCodeIds({
                                            ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                                            codeTypeCategory:
                                                CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                                            code: '6',
                                        })
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_8_RIPA_OFFENSE_CODE_ID
                                    }
                                >
                                    {formatRipaOffenseCodeById(
                                        filterRipaOffenseCodeIds({
                                            ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                                            codeTypeCategory:
                                                CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                                            code: '8',
                                        })
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={
                                        fields.RIPA_SUBJECT_OFFENSE_CODE_CODE_STOP_RESULT_OF_STOP_9_RIPA_OFFENSE_CODE_ID
                                    }
                                >
                                    {formatRipaOffenseCodeById(
                                        filterRipaOffenseCodeIds({
                                            ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                                            codeTypeCategory:
                                                CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                                            code: '9',
                                        })
                                    )}
                                </SummaryRow>
                            </CardSection>
                        );
                    })}
                </WithComplianceGroup>
            </SummaryList>
        );
    }
);

export default StopCardSummary;

import { Store } from 'redux';
import { ApprovalStatusForCaseEnumType, ElasticCase } from '@mark43/rms-api';
import { iconPaths } from '@arc/icon';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { prettify } from '~/client-common/helpers/stringHelpers';
import { DISPLAY_ONLY_CASE } from '~/client-common/core/enums/universal/fields';
import { formatCaseDefinitionByIdSelector } from '~/client-common/core/domain/case-definitions/state/data';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import {
    formatFieldByNameSelector,
    formatFieldNames,
} from '~/client-common/core/fields/state/config';
import { RootState } from '../../../../../../../legacy-redux/reducers/rootReducer';
import {
    getCaseAssignedTo,
    getCaseStatus,
    getCaseTitle,
} from '../../../../../../records/core/helpers/caseStringFormatters';
import {
    convertApprovalStatusIconTypeToThemeColor,
    convertCaseApprovalStatusToIconType,
} from '../../../../../../records/core/components/ApprovalStatusIcon';
import { currentThemeSelector } from '../../../../../styles/state';
import { iconTypes } from '../../../../../components/Icon';

import { FormatMentionItemMapper, Mention } from '../types';
import { getDefaultIcon, getMentionEntityLinkUrl } from '../utils';

const ICON_TYPE_TO_ICON_MAPPER = {
    [iconTypes.SUPERVISOR_APPROVED]: iconPaths.SupervisorApproved,
    [iconTypes.PENDING]: iconPaths.Pending,
    [iconTypes.REJECTED]: iconPaths.Rejected,
    [iconTypes.DRAFT]: iconPaths.Draft,
};

const getApprovalStatusIcon = ({
    approvalStatus,
    theme,
}: {
    approvalStatus: ApprovalStatusForCaseEnumType;
    theme: ReturnType<typeof currentThemeSelector>;
}) => {
    const caseIconType = convertCaseApprovalStatusToIconType(approvalStatus);
    const iconColor = convertApprovalStatusIconTypeToThemeColor(caseIconType);

    return getDefaultIcon(
        ICON_TYPE_TO_ICON_MAPPER[caseIconType],
        iconColor ? theme.colors[iconColor] : theme.colors.mediumGrey
    );
};

const prepareMentionedItem = (item: ElasticCase, store: Store<RootState>): Mention => {
    const currentTheme = currentThemeSelector(store.getState());
    const formatFieldByName = formatFieldByNameSelector(store.getState());
    const singularCaseFieldName = prettify(
        formatFieldNames(formatFieldByName, [DISPLAY_ONLY_CASE])[DISPLAY_ONLY_CASE]
    );
    const formatCaseDefinitionById = formatCaseDefinitionByIdSelector(store.getState());
    const formatAttributeById = formatAttributeByIdSelector(store.getState());
    const dateFormatter = currentDepartmentDateFormatterSelector(store.getState());
    const allRoleFormatsByRoleId = allRoleFormatsByRoleIdSelector(store.getState());

    const name =
        item.shortTitle ||
        getCaseTitle({
            localId: item.caseNumber || item.localId,
            caseDefinition: formatCaseDefinitionById(item.caseDefinitionId),
            caseFieldName: singularCaseFieldName,
        });

    const getRoleName = (roleId?: number) => {
        if (roleId) {
            const allRoleFormats = allRoleFormatsByRoleId(roleId);
            return 'fullNameWithFirstInitialAndIdNumber' in allRoleFormats
                ? allRoleFormats.fullNameWithFirstInitialAndIdNumber
                : undefined;
        }
        return;
    };
    const caseAssignedTo = getCaseAssignedTo({
        assigneeRole: getRoleName(item.assigneeRoleId),
        assignedPersonnelUnit: formatAttributeById(item.assignedPersonnelUnitAttrId),
    });
    const caseStatus = getCaseStatus({
        caseStatus: formatAttributeById(item.currentStatusAttrId),
    });

    const descriptions = [caseAssignedTo, caseStatus].filter(Boolean) as string[];

    const approvalStatusIcon = getApprovalStatusIcon({
        approvalStatus: item.approvalStatus,
        theme: currentTheme,
    });

    return {
        id: item.id.toString(),
        name,
        text: name,
        descriptions,
        category: 'CASES',
        defaultIcon: approvalStatusIcon,
        url: getMentionEntityLinkUrl({ itemType: 'CASES', itemId: item.id }),
        createdDate: dateFormatter.formatDate(item.createdDateUtc),
    };
};

const formatMapper: FormatMentionItemMapper<ElasticCase> = {
    resourceMethod: 'searchCases',
    prepareMentionedItem,
};

export default formatMapper;

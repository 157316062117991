import _, { get, isArray, map } from 'lodash';
import { createSelector } from 'reselect';

import { vehicleModelsSelector } from '../data';
import { vehicleMakesSelector } from '../../../vehicle-makes/state/data';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

const OTHER_OPTION = {
    value: -1,
    display: 'Other',
};

export const formatVehicleModelByIdSelector = createSelector(
    vehicleModelsSelector,
    (vehicleModels) => (id) => {
        const ids = isArray(id) ? id : [id];
        return joinTruthyValues(
            map(ids, (id) => {
                if (id === OTHER_OPTION.value) {
                    return OTHER_OPTION.display;
                }
                return get(vehicleModels[id], 'modelName');
            })
        );
    }
);

export const vehicleModelOptionsSelector = createSelector(
    vehicleModelsSelector,
    vehicleMakesSelector,
    (vehicleModels, vehicleMakes) => (vehicleModelIds) => {
        return _(vehicleModelIds)
            .map((id) => vehicleModels[id])
            .compact()
            .map((vehicleModel) => ({
                display: vehicleModel.modelName,
                value: vehicleModel.id,
                group: get(vehicleMakes, `[${vehicleModel.vehicleMakeId}].makeName`),
            }))
            .sortBy('display')
            .value();
    }
);

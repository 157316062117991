import { every, isNull, isObject, isUndefined, trim } from 'lodash';
import { safeSplit } from '~/client-common/helpers/stringHelpers';

// this file contains client-only validation rules that work with React Redux
// Form, see https://davidkpiano.gitbooks.io/react-redux-form/content/validation.html

export function requiredString(value) {
    if (isObject(value)) {
        return true;
    }

    return trim(value) !== '';
}

export function mustBeInts(value) {
    if (isObject(value)) {
        return true;
    }

    const values = safeSplit(value, ',');

    return every(values, mustBeInt);
}

export function mustBeInt(value) {
    if (isObject(value)) {
        return true;
    }

    return isUndefined(value) || isNull(value) || trim(value) === '' || /^\d+$/.test(value);
}

export function mustBeIntAndGreaterThanZero(value) {
    return (
        isUndefined(value) || isNull(value) || trim(value) === '' || (mustBeInt(value) && value > 0)
    );
}

export function requiredInt(value) {
    if (isObject(value)) {
        return true;
    }

    return /^\d+$/.test(value);
}

export function requiredNumber(value) {
    if (isObject(value)) {
        return true;
    }

    return /^[\d.]+$/.test(value);
}

/**
 * On NibrsOffenseCode, `id` is unique and `code` is not. Still, `code` is useful for a lot of
 *   validation logic. If you need to distinguish NibrsOffenseCodes with the same `code`, use `id`.
 *   Otherwise, using `code` may be pragmatic.
 */
export const AGGRAVATED_ASSAULT = '13A';
export const SIMPLE_ASSAULT = '13B';
export const INTIMIDATION = '13C';

const nibrsCodes = {
    burglary: '220',
    arson: '200',
    larceny: ['23A', '23B', '23C', '23D', '23E', '23F', '23G', '23H'],
    sexAssault: ['11A', '11B', '11C', '11D', '36A', '36B'],
    crimesAgainstPerson: [
        AGGRAVATED_ASSAULT,
        SIMPLE_ASSAULT,
        INTIMIDATION,
        '09A', // Murder & Non-negligent Manslaughter
        '09B', // Negligent Manslaughter
        '64A', // Human Trafficking, Commercial Sex Acts
        '64B', // Human Trafficking, Involuntary Servitude
        '100', // Kidnapping/Abduction
        '11A', // Rape
        '11B', // Sodomy
        '11C', // Sexual Assault With An Object
        '11D', // Fondling
        '36A', // Incest
        '36B', // Statutory Rape
        '500', // Violation of No Contact Orders (WA_NIBRS)
    ],
    motorVehicleTheft: ['240'],
    robbery: '120',
    justifiableHomicide: '09C',
};

// @ts-expect-error legacy-todo
nibrsCodes.allowsDomesticViolence = Array.prototype.concat.call(
    [],
    nibrsCodes.burglary,
    nibrsCodes.arson,
    nibrsCodes.larceny,
    nibrsCodes.crimesAgainstPerson,
    nibrsCodes.motorVehicleTheft,
    nibrsCodes.robbery,
    nibrsCodes.justifiableHomicide
);

export default nibrsCodes;

/**
 * Must match TypeOfPropertyLossCode.java.
 */
export const propertyLossCodes = {
    none: '1',
    burned: '2',
    counterfeitedForged: '3',
    destroyedDamagedVandalized: '4',
    recovered: '5',
    seized: '6',
    stolen: '7',
    unknown: '8',
    notNibrsReportable: 'X',
} as const;

/**
 * Must match PropertyDescriptionCode.java
 * - Add codes as needed on FE
 */
export const propertyDescriptionCode = {
    vehiclePartsAndAccessories: '38',
} as const;

// data element 38-Sex of Offender
export const sexOfOffenderCodes = {
    female: 'F',
    male: 'M',
    unknown: 'U',
};

// data element 39-Race of Offender
export const raceOfOffenderCodes = {
    white: 'W',
    blackOrAfricanAmerican: 'B',
    americanIndianOrAlaskaNative: 'I',
    asian: 'A',
    unknown: 'U',
};

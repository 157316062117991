import {
    EmailExportRelease as EmailRelease,
    ExportOptionTypeEnumType,
    ExportOptionTypeEnum,
} from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'emailExportReleases';

// not every option type is returned by the BE
type ExcludedExportOptions =
    | typeof ExportOptionTypeEnum.INCLUDE_ATTACHMENT_FILES.name
    | typeof ExportOptionTypeEnum.MERGE_ATTACHMENTS.name;
type ValidExportOptionType = Exclude<ExportOptionTypeEnumType, ExcludedExportOptions>;

// based on loadExportReleases from domain/export-releases/state/data/index.ts
export type EmailExportRelease = EmailRelease & {
    exportOptionTypes: ValidExportOptionType[];
};

const emailExportReleasesModule = createNormalizedModule<EmailExportRelease>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// SELECTORS
export const emailExportReleasesSelector = emailExportReleasesModule.selectors.entitiesSelector;

// REDUCER
export default emailExportReleasesModule.reducerConfig;

import React from 'react';
import keyMirror from 'keymirror';
import { UserProfile, MiniUserView } from '@mark43/rms-api';
import invariant from 'invariant';

import { getChildTypes } from '../../../helpers/reactHelpers';
import {
    allMiniUserFormatsEnum,
    userProfileToMiniUser,
} from '../../domain/mini-users/utils/miniUsersHelpers';
import { EmDash } from '../../../../modules/core/components/HtmlEntities';

const NODE_ENV = process.env.NODE_ENV;

type UserFormatKeys = keyof typeof allMiniUserFormatsEnum;

export interface FormattedUserProps {
    user?: UserProfile | MiniUserView;
    format: UserFormatKeys;
    className?: string;
    children?: (user: string) => React.ReactElement;
}

const FORMATS = keyMirror(allMiniUserFormatsEnum);

type FormattedDateExport = React.FC<FormattedUserProps> & {
    FORMATS: typeof FORMATS;
};

/**
 * Use this component to format a miniUser or userProfile.
 * @param   options.user      (Optional) A miniUser or userProfile object.
 * @param   options.format    A snakecase version of a value from allMiniUserFormatsEnum
 * @param   options.children  (Optional) Function that takes in the result of the formatted string
 * @param   options.className (Optional) String className for resulting span.
 */
const FormattedUser: FormattedDateExport = ({ user, format, children, className }) => {
    if (!user) {
        return <EmDash />;
    }
    const childIsFunction = typeof children === 'function';
    if (NODE_ENV !== 'production') {
        const types = children ? getChildTypes(children).join(', ') : '';
        invariant(
            children === undefined || childIsFunction,
            `FormattedUser only accepts a function as child, but you have nested "${types}".`
        );
        invariant(
            allMiniUserFormatsEnum[format],
            `A valid user format must be given to FormattedUser, was given: ${format}`
        );
    }
    // a userprofile has a userId a mini user just has an id
    const miniUser = 'userId' in user ? userProfileToMiniUser(user) : user;
    const formattedUser = allMiniUserFormatsEnum[format](miniUser);

    // If a function is provided as a child, we call it with the formatted value.
    if (typeof children === 'function') {
        return children(formattedUser);
    }

    return formattedUser ? <span className={className}>{formattedUser}</span> : null;
};

// convert keys to all caps snake case
// so signify that these are constants
FormattedUser.FORMATS = FORMATS;

export default FormattedUser;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { FormattedDate } from '~/client-common/core/dates/components';
import { FormattedUser, FORMATS } from '~/client-common/core/users/components';
import { getMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { userProfilesByIdSelector } from '~/client-common/core/domain/user-profiles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportShortTitleViewModelByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';

import _Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';

import { ReportLinkContainer, BodyItalic } from './AssociatedRecordsUiComponents';
import ReportReportLinkTitle from './ReportReportLinkTitle';

const strings = componentStrings.records.associatedRecords;

const BodyContainer = styled.div`
    ${(props) => props.truncateWidth && `width: 75%`};
    display: inline-block;
`;

const Button = styled(_Button)`
    float: none;
    display: inline-block;
    margin-top: 0;
`;

const ReportReportLinkContainer = styled(ReportLinkContainer)`
    display: flex;
    justify-content: space-between;
`;

function ReportReportLink({
    reportLink,
    currentReportId,
    getMiniUserById,
    userProfilesById,
    saveSuggestedRecord,
}) {
    // we have to check for both because on suggest associations it could be a user from another
    // department
    const user = getMiniUserById(reportLink.updatedBy) || userProfilesById(reportLink.updatedBy);
    return (
        <ReportReportLinkContainer>
            <BodyContainer truncateWidth={saveSuggestedRecord}>
                <ReportReportLinkTitle reportLink={reportLink} currentReportId={currentReportId} />
                <BodyItalic>
                    {strings.associatedBy}{' '}
                    <FormattedUser
                        user={user}
                        format={FORMATS.FULL_NAME_WITH_FIRST_INITIAL_AND_ID_NUMBER}
                    />{' '}
                    on{' '}
                    <FormattedDate
                        date={reportLink.updatedDateUtc}
                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                    />
                </BodyItalic>
            </BodyContainer>
            {saveSuggestedRecord && (
                <OnlyWithAbility has={abilitiesEnum.REPORTING.MANAGE_ASSOCIATED_RECORDS}>
                    <Button
                        onClick={() => saveSuggestedRecord(reportLink)}
                        className={buttonTypes.SECONDARY}
                    >
                        {strings.add}
                    </Button>
                </OnlyWithAbility>
            )}
        </ReportReportLinkContainer>
    );
}

const mapStateToProps = createStructuredSelector({
    getMiniUserById: getMiniUserByIdSelector,
    userProfilesById: userProfilesByIdSelector,
    reportShortTitleViewModelByReportId: reportShortTitleViewModelByReportIdSelector,
    departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
});

export default connect(mapStateToProps)(ReportReportLink);

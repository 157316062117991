import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'dispatch_areas';
const RADIO_BASE_CAHNNEL_PATH = 'radio_channel';

export default createResource({
    name: 'Cad Dispatch Areas Resource',
    methods: {
        getDispatchAreas() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: BASE_PATH,
            });
        },
        getRadioChannelsForDept(deptId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${RADIO_BASE_CAHNNEL_PATH}/department/${deptId}`,
            });
        },
        upsertDispatchArea(dispatchArea) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: BASE_PATH,
                data: dispatchArea,
            });
        },
    },
});

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React, { useEffect } from 'react';
import { get, noop } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { compose, withHandlers } from 'recompose';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { loadExportPresets } from '~/client-common/core/domain/export-presets/state/data';
import {
    exportDefaultGroupTitleSelector,
    exportPageReleaseTrackingConfigSelector,
} from '../../../../legacy-redux/selectors/exportsSelectors';

import FormExports from '../../../../legacy-redux/components/exports/FormExports';
import { ExportPageWithColumns } from '../../../../legacy-redux/components/exports/LayoutComponents';

import { ReleaseTracking } from '../../../core/pdf-exports/release-tracking/components/ReleaseTracking';
import { currentReportSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { loadReportExports } from '../state/ui';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { ReportFormExportsOptions } from './ReportFormExportsOptions';

const strings = componentStrings.reports.core.ReportExports;

const mapStateToProps = createStructuredSelector({
    exportDefaultGroupTitle: exportDefaultGroupTitleSelector,
    exportPageReleaseTrackingConfig: exportPageReleaseTrackingConfigSelector,
    currentReport: currentReportSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
});

const mapDispatchToProps = {
    loadReportExports,
    loadExportPresets,
};

/**
 * Exports page for a Report.
 * @param {function}  options.closeCallback      Special behavior on close
 */
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onRedact: ({ router, currentReport }) => () => {
            const reportId = get(currentReport, 'id');
            if (!!reportId) {
                router.push(`/reports/${reportId}/export/redact`);
            }
        },
    })
)(function ReportExports({
    exportPageReleaseTrackingConfig,
    exportDefaultGroupTitle,
    closeCallback = noop,
    currentReport,
    loadReportExports,
    loadExportPresets,
    onRedact,
    currentUserHasAbility,
}) {
    const { id: reportId } = currentReport;
    useEffect(() => {
        loadReportExports(reportId);
        loadExportPresets();
    }, [loadReportExports, reportId, loadExportPresets]);

    const { showReleaseTracking, isCpsReport } = exportPageReleaseTrackingConfig(
        EntityTypeEnum.REPORT.name,
        reportId
    );
    const currentUserHasRedactReportExportsAbility = currentUserHasAbility(
        abilitiesEnum.CORE.REDACT_REPORT_EXPORTS
    );

    return (
        <ExportPageWithColumns
            leftColumn={
                <FormExports
                    renderFormExportsOptions={({
                        packets,
                        packetOptions,
                        globalExportOptions,
                        handlePacketsChange,
                        handleExportOptionsChange,
                        selectedExportPresetId,
                        setExportPresetId,
                    }) => (
                        <ReportFormExportsOptions
                            entityId={reportId}
                            packets={packets}
                            packetOptions={packetOptions}
                            globalExportOptions={globalExportOptions}
                            handlePacketsChange={handlePacketsChange}
                            handleExportOptionsChange={handleExportOptionsChange}
                            selectedExportPresetId={selectedExportPresetId}
                            setExportPresetId={setExportPresetId}
                        />
                    )}
                    closeCallback={closeCallback}
                    showFieldRedaction={false}
                    useExportRouteToggle={true}
                    includeAttachments={true}
                    showIncludeHistoryEvents={true}
                    showOnlyIncludeFieldsWithData={true}
                    showIncludeNameAddendums={true}
                    entityId={reportId}
                    entityType={EntityTypeEnum.REPORT.name}
                    internalExportAttachmentLinkType={LinkTypesEnum.REPORT_ATTACHMENT}
                    defaultFilename={strings.defaultTitle}
                    defaultGroupTitle={exportDefaultGroupTitle}
                    trackReleases={!isCpsReport}
                    showCancel={false}
                    submitButtonText={strings.export}
                    emailsEnabled={true}
                    onRedact={currentUserHasRedactReportExportsAbility && onRedact}
                />
            }
            rightColumn={
                showReleaseTracking && !isCpsReport ? (
                    <ReleaseTracking entityType={EntityTypeEnum.REPORT.name} entityId={reportId} />
                ) : undefined
            }
        />
    );
});

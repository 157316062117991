/* eslint-disable @typescript-eslint/no-explicit-any */
import invariant from 'invariant';
import { isArray, ListIterateeCustom } from 'lodash';
import { createSelector } from 'reselect';
import { createWithEntityItems } from 'nexus-mark43';
import { createWithRemove, linkModuleCompatStrategy, groupByKeyStrategy } from 'probes-mark43';
import type { GroupedModuleShape, RootState } from '../../redux/state';

const withRemove = createWithRemove({ key: 'config' });
const withEntityItems = createWithEntityItems({ key: 'items' });

// a cache of the names of existing modules, used to ensure that no duplicates
// are created
const existingModules: Record<string, boolean> = {};

/**
 * creates a module for grouped entities that should be normalized and returns actions, selectors and a reducer
 * @param   options.type    actionType, often the snake case of the entity type, eg, VEHICLES
 * @param   options.key     The key to normalize on, defaults to id
 * @param   options.indexKeys The keys to use when de-duping the grouped array
 * @return                  action creators and data reducer
 */
export default function createGroupedModule<T>({
    type,
    key = 'id',
    indexKeys = ['id'],
}: {
    type: string;
    key?: string;
    indexKeys?: string[];
}) {
    invariant(!existingModules[type], "A grouped module for the specified 'type' already exists");

    // for a detailed explanation why the below is necessary
    // refer to `client-common/core/utils/createNormalizedModule.js`
    const defaultEmpty = {};
    const safeBaseSelector = createSelector(
        // @ts-expect-error TODO: Change `type` from string to a union of the strings defined in RootState
        (state: RootState) => state.dataNexus[type] as GroupedModuleShape<T>,
        (value) => value || defaultEmpty
    );

    const actionTypes = createActionTypes(type);
    const actionCreators = createActionCreators<T>(type, actionTypes);
    const selectors = createSelectors<T>(safeBaseSelector);

    existingModules[type] = true;

    const groupStrategy = groupByKeyStrategy(key);
    const linkMerge = linkModuleCompatStrategy({
        keys: indexKeys,
        metaKey: 'meta',
        tagsKey: 'tags',
    });
    const reducerConfig = {
        [type]: {
            groupStrategy,
            indexStrategy: linkMerge.indexStrategy,
            mergeStrategy: linkMerge,
        },
    };

    return {
        actionCreators,
        selectors,
        reducerConfig,
        withEntityItems,
        withRemove,
    };
}

function createActionTypes(type: string) {
    return {
        storeEntities: `core/STORE_${type}S`,
        replaceEntitiesWhere: `core/REPLACE_${type}S_WHERE`,
    };
}

function createActionCreators<T>(type: string, actionTypes: ReturnType<typeof createActionTypes>) {
    return {
        storeEntities: (data: T[] | T = []) =>
            withEntityItems(
                {
                    [type]: isArray(data) ? data : [data],
                },
                { type: actionTypes.storeEntities }
            ),
        replaceEntitiesWhere: (
            predicate: ListIterateeCustom<T, boolean>,
            newEntities: T[] | T = []
        ) =>
            withEntityItems(
                {
                    [type]: isArray(newEntities) ? newEntities : [newEntities],
                },
                withRemove(type, predicate, { type: actionTypes.replaceEntitiesWhere })
            ),
    };
}

function createSelectors<T>(baseSelector: (state: RootState) => GroupedModuleShape<T>) {
    return {
        entitiesSelector: baseSelector,
        entitiesByKeySelector: createSelector(baseSelector, (entities) => (key: string | number) =>
            entities[key]
        ),
    };
}

import { mapValues } from 'lodash';
import { createSelector } from 'reselect';

import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { impoundsSelector } from '../data';

export const impoundViewModelsSelector = createSelector(
    impoundsSelector,
    formatAttributeByIdSelector,
    (impounds, formatAttributeById) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [allSingleAttributeValuesMapper, boolToDisplayMapper],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(impounds, viewModel);
    }
);

import styled from 'styled-components';

export default styled.div`
    padding: 10px 15px;
    background: ${(props) => props.theme.colors.brightBlue};
    color: ${(props) => props.theme.colors.white};
    font: ${(props) =>
        `${props.theme.fontWeights.semiBold} var(--arc-fontSizes-md) ${props.theme.fontFamilies.proximaNova}`};
    text-transform: uppercase;
`;

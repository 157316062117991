import { createSelector } from 'reselect';
import { mapValues, map, filter, includes, find } from 'lodash';
import {
    TrafficCrash,
    Attachment,
    AttributeTypeEnum,
    EntityTypeEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';

import {
    buildViewModel,
    allSingleOfficerIdListsMapper,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
    ViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import { trafficCrashByReportIdSelector, trafficCrashesSelector } from '../data';
import { trafficCrashAttributesByReportIdSelector } from '../../../traffic-crash-attributes/state/data';
import { ModuleShape } from '../../../../utils/createNormalizedModule';
import {
    sortedAugmentedAttachmentViewModelsWhereSelector,
    AttachmentViewModelProps,
} from '../../../attachments/state/ui';

import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { trafficCrashRoadwaysWhereSelector } from '../../../traffic-crash-roadways/state/data';
import { trafficCrashRoadwayEntityLinksWhereSelector } from '../../../traffic-crash-roadway-entity-links/state/data';

type TrafficCrashViewModelProps = {
    weather?: string;
    contributingCircumstances?: string;
    trafficControlDeviceType?: string;
    trafficControlInoperativeMissing?: string;
    crashClassificationOwnershipAttrId?: string;
    crashClassificationCharacteristicsAttrId?: string;
    crashClassificationSecondaryCrashAttrId?: string;
    firstHarmfulEventAttrId?: string;
    locationOfFirstHarmfulEventAttrId?: string;
    mannerOfCrashAttrId?: string;
    lightConditionAttrId?: string;
    roadwaySurfaceConditionAttrId?: string;
    junctionWithinInterchangeAreaAttrId?: string;
    junctionSpecificLocationAttrId?: string;
    intersectionNumApproachesAttrId?: string;
    intersectionOverallGeometryAttrId?: string;
    intersectionOverallTrafficControlDeviceAttrId?: string;
    schoolBusRelatedAttrId?: string;
    workZoneRelatedAttrId?: string;
    workZoneLocationOfCrashAttrId?: string;
    workZoneTypeAttrId?: string;
    workZoneWorkersPresentAttrId?: string;
    workZoneLawEnforcementPresentAttrId?: string;
    crashSeverityAttrId?: string;
    alcoholInvolvementAttrId?: string;
    drugInvolvementAttrId?: string;
    dayOfWeekAttrId?: string;
    hasPropertyDamage?: string;
    attachmentViewModels?: ViewModel<Attachment, AttachmentViewModelProps>[];
    landmarkLocation: string;
    landmarkIntersectingRoadway: string;
    crashRoadwayName?: string;
    intersectingRoadwayName?: string;
    milepostRoadwayDirectionAttrId?: string;
    milepostLocation: string;
    exitRampDirection: string;
    exitRampDistanceUnits: string;
    roadwayDirection: string;
    intersectingRoadway1: string;
    intersectingRoadway2: string;
};

type TrafficCrashViewModel = ViewModel<TrafficCrash, TrafficCrashViewModelProps>;

const trafficCrashViewModelsSelector = createSelector(
    formatAttributeByIdSelector,
    trafficCrashesSelector,
    trafficCrashAttributesByReportIdSelector,
    sortedAugmentedAttachmentViewModelsWhereSelector,
    trafficCrashRoadwaysWhereSelector,
    trafficCrashRoadwayEntityLinksWhereSelector,
    (
        formatAttributeById,
        trafficCrashes,
        trafficCrashAttributesByReportId,
        sortedAugmentedAttachmentViewModelsWhere,
        trafficCrashRoadwaysWhere,
        trafficCrashRoadwayEntityLinksWhere
    ): ModuleShape<TrafficCrashViewModel> => {
        const viewModel = buildViewModel<TrafficCrash, TrafficCrashViewModelProps>({
            mappers: [
                allSingleAttributeValuesMapper,
                allSingleOfficerIdListsMapper,
                boolToDisplayMapper,
                ({
                    id,
                    reportId,
                    landmarkDirectionAttrId,
                    landmarkDistance,
                    landmarkDistanceUnitsAttrId,
                    landmarkName,
                    landmarkIntersectingRoadwayDirectionAttrId,
                    landmarkIntersectingRoadwayDistance,
                    landmarkIntersectingRoadwayName,
                    milepostDistanceUnitsAttrId,
                    milepostDirectionAttrId,
                    milepost,
                    milepostDistance,
                    exitNumberDirectionAttrId,
                    exitNumberDistanceUnitsAttrId,
                    roadwayDirectionAttrId,
                    intersectionDetailsRoadwayDirectionAttrId,
                    intersectionDetailsRoadwayName,
                    additionalIntersectionRoadwayDirectionAttrId,
                    additionalIntersectionRoadwayName,
                }) => {
                    const roadwayEntityLinks = trafficCrashRoadwayEntityLinksWhere({
                        entityId: id,
                        entityType: EntityTypeEnum.TRAFFIC_CRASH.name,
                    });
                    const trafficCrashAttributes = trafficCrashAttributesByReportId(reportId);
                    const weather = formatAttributeById(
                        map(
                            filter(
                                trafficCrashAttributes,
                                (tca) => tca.attributeType === AttributeTypeEnum.WEATHER.name
                            ),
                            'attributeId'
                        )
                    );

                    const contributingCircumstances = formatAttributeById(
                        map(
                            filter(
                                trafficCrashAttributes,
                                (tca) =>
                                    tca.attributeType ===
                                    AttributeTypeEnum.QC_ROADWAY_CONTRIBUTING_CIRCUMSTANCES.name
                            ),
                            'attributeId'
                        )
                    );
                    const trafficControlDeviceType = formatAttributeById(
                        map(
                            filter(
                                trafficCrashAttributes,
                                (tca) =>
                                    tca.attributeType ===
                                    AttributeTypeEnum.QC_TRAFFIC_CONTROLS_TYPE.name
                            ),
                            'attributeId'
                        )
                    );
                    const trafficControlInoperativeMissing = formatAttributeById(
                        map(
                            filter(
                                trafficCrashAttributes,
                                (tca) =>
                                    tca.attributeType ===
                                    AttributeTypeEnum.QC_TRAFFIC_CONTROLS_INOPERATIVE_OR_MISSING
                                        .name
                            ),
                            'attributeId'
                        )
                    );

                    const attachmentViewModels = sortedAugmentedAttachmentViewModelsWhere(
                        (attachmentViewModel) => {
                            const linkTypes = [
                                LinkTypesEnum.TRAFFIC_CRASH_ATTACHMENT,
                                LinkTypesEnum.TRAFFIC_CRASH_EXPORT_ATTACHMENT,
                            ];
                            return (
                                attachmentViewModel.entityId === id &&
                                attachmentViewModel.entityType ===
                                    EntityTypeEnum.TRAFFIC_CRASH.name &&
                                includes(linkTypes, attachmentViewModel.linkType)
                            );
                        }
                    );

                    const landmarkDistanceUnitsDisplay = formatAttributeById(
                        landmarkDistanceUnitsAttrId
                    );
                    const landmarkDirectionDisplay = formatAttributeById(landmarkDirectionAttrId);
                    const landmarkDistanceDisplay = joinTruthyValues(
                        [landmarkDistance, landmarkDistanceUnitsDisplay, landmarkDirectionDisplay],
                        ' '
                    );
                    const landmarkLocation = joinTruthyValues(
                        [landmarkDistanceDisplay, landmarkName],
                        ' from '
                    );

                    const landmarkIntersectingRoadwayDirectionDisplay = formatAttributeById(
                        landmarkIntersectingRoadwayDirectionAttrId
                    );
                    const landmarkIntersectingRoadwayDistanceDisplay = joinTruthyValues(
                        [
                            landmarkIntersectingRoadwayDistance,
                            landmarkIntersectingRoadwayDirectionDisplay,
                        ],
                        ' '
                    );
                    const landmarkIntersectingRoadway = joinTruthyValues(
                        [
                            landmarkIntersectingRoadwayDistanceDisplay,
                            landmarkIntersectingRoadwayName,
                        ],
                        ' from '
                    );

                    const milepostDistanceUnitsDisplay = formatAttributeById(
                        milepostDistanceUnitsAttrId
                    );
                    const milepostDirectionDisplay = formatAttributeById(milepostDirectionAttrId);
                    const milepostDistanceDisplay = joinTruthyValues(
                        [milepostDistance, milepostDistanceUnitsDisplay, milepostDirectionDisplay],
                        ' '
                    );
                    const milepostLocation = joinTruthyValues(
                        [milepostDistanceDisplay, milepost],
                        ' from '
                    );

                    const crashRoadwayLink = find(
                        roadwayEntityLinks,
                        (rel) => rel.linkType === LinkTypesEnum.ROADWAY_OF_TRAFFIC_CRASH
                    );
                    const crashRoadwayName = find(
                        trafficCrashRoadwaysWhere({ id: crashRoadwayLink?.trafficCrashRoadwayId })
                    )?.roadwayName;
                    const intersectingRoadwayLink = find(
                        roadwayEntityLinks,
                        (rel) =>
                            rel.linkType === LinkTypesEnum.INTERSECTING_ROADWAY_OF_TRAFFIC_CRASH
                    );
                    const intersectingRoadwayName = find(
                        trafficCrashRoadwaysWhere({
                            id: intersectingRoadwayLink?.trafficCrashRoadwayId,
                        })
                    )?.roadwayName;

                    const formatRoadway = (
                        roadwayName: string | undefined,
                        roadwayDirectionAttrId: number | undefined
                    ) =>
                        joinTruthyValues(
                            [formatAttributeById(roadwayDirectionAttrId), roadwayName],
                            ' '
                        );
                    const roadwayDirection = formatAttributeById(roadwayDirectionAttrId);
                    const intersectingRoadway1 = formatRoadway(
                        intersectionDetailsRoadwayName,
                        intersectionDetailsRoadwayDirectionAttrId
                    );
                    const intersectingRoadway2 = formatRoadway(
                        additionalIntersectionRoadwayName,
                        additionalIntersectionRoadwayDirectionAttrId
                    );

                    const exitRampDirection = formatAttributeById(exitNumberDirectionAttrId);
                    const exitRampDistanceUnits = formatAttributeById(
                        exitNumberDistanceUnitsAttrId
                    );

                    return {
                        weather,
                        contributingCircumstances,
                        trafficControlDeviceType,
                        trafficControlInoperativeMissing,
                        attachmentViewModels,
                        landmarkLocation,
                        landmarkIntersectingRoadway,
                        milepostLocation,
                        exitRampDirection,
                        exitRampDistanceUnits,
                        crashRoadwayName,
                        intersectingRoadwayName,
                        roadwayDirection,
                        intersectingRoadway1,
                        intersectingRoadway2,
                    };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(trafficCrashes, viewModel);
    }
);

export const trafficCrashViewModelByReportIdSelector = createSelector(
    trafficCrashViewModelsSelector,
    trafficCrashByReportIdSelector,
    (trafficCrashViewModels, trafficCrashByReportId) => (reportId: number) => {
        const trafficCrashId = trafficCrashByReportId(reportId)?.id;
        return !!trafficCrashId ? trafficCrashViewModels[trafficCrashId] : undefined;
    }
);

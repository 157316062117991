import { every } from 'lodash';
import { EntityTypeEnumType, EntityTypeEnum } from '@mark43/rms-api';
import { SelectedRowType } from '../contexts';

export const kebabCanRename = ({
    selectedRows,
    canCurrentUserEditCurrentCase,
}: {
    selectedRows: SelectedRowType[];
    canCurrentUserEditCurrentCase: boolean;
}): boolean => {
    return (
        canCurrentUserEditCurrentCase &&
        selectedRows.length === 1 &&
        !!selectedRows.find((row) => row.isFolder && row.isEditable)
    );
};

export const kebabCanDelete = ({
    selectedRows,
    canCurrentUserEditCurrentCase,
}: {
    selectedRows: SelectedRowType[];
    canCurrentUserEditCurrentCase: boolean;
}): boolean => {
    return (
        canCurrentUserEditCurrentCase &&
        selectedRows.length > 0 &&
        !selectedRows.find((row) => row.isFolder && !row.isEditable)
    );
};

export const kebabCanMoveFromFolder = ({
    canCurrentUserEditCurrentCase,
    isTopLevel,
    selectedRows,
    searchFilter,
    entityType,
}: {
    canCurrentUserEditCurrentCase: boolean;
    isTopLevel?: boolean;
    selectedRows: SelectedRowType[];
    searchFilter?: string;
    entityType: EntityTypeEnumType;
}): boolean => {
    if (entityType === EntityTypeEnum.ATTACHMENT.name) {
        return (
            canCurrentUserEditCurrentCase &&
            !isTopLevel &&
            !searchFilter &&
            every(selectedRows, (row) => !row.isFolder)
        );
    }
    return (
        canCurrentUserEditCurrentCase && !isTopLevel && every(selectedRows, (row) => !row.isFolder)
    );
};

export const kebabCanCreateNewFolder = ({
    canCurrentUserEditCurrentCase,
    searchFilter,
    entityType,
}: {
    canCurrentUserEditCurrentCase: boolean;
    searchFilter?: string;
    entityType: EntityTypeEnumType;
}): boolean => {
    if (entityType === EntityTypeEnum.ATTACHMENT.name) {
        return canCurrentUserEditCurrentCase && !searchFilter;
    }

    return canCurrentUserEditCurrentCase;
};

import { get } from 'lodash';

import { normalize } from '~/client-common/helpers/dataHelpers';
import stringsConfig from '~/client-common/core/strings';
import { Mark43Error } from '../../lib/errors';

import locationAliasesAdminForm from '../../modules/forms/locationAliasesAdminForm';
import resource from '../resources/locationAliasesAdminResource';
import { aliasByIdSelector } from '../selectors/locationAliasesAdminSelectors';
import actionTypes from './types/locationAliasesAdminActionTypes';
import { selectLocationById } from './locationsActions';

const strings = stringsConfig.components.admin.locationaliases;

function loadAliasesStart() {
    return {
        type: actionTypes.LOAD_ALIASES_START,
    };
}

function loadAliasesSuccess(aliases) {
    return {
        type: actionTypes.LOAD_ALIASES_SUCCESS,
        payload: normalize(aliases),
    };
}

function loadAliasesFailure(err) {
    return {
        type: actionTypes.LOAD_ALIASES_FAILURE,
        error: true,
        payload: err,
    };
}

export function loadAliases() {
    return function (dispatch) {
        dispatch(loadAliasesStart());

        return resource
            .getAliases()
            .then((aliases) => dispatch(loadAliasesSuccess(aliases)))
            .catch((err) => dispatch(loadAliasesFailure(err)));
    };
}

function selectAliasStart(id) {
    return {
        type: actionTypes.SELECT_ALIAS_START,
        payload: id,
    };
}

function selectAliasSuccess(alias, location) {
    return {
        type: actionTypes.SELECT_ALIAS_SUCCESS,
        payload: { alias, location },
    };
}

function selectAliasFailure(err) {
    return {
        type: actionTypes.SELECT_ALIAS_FAILURE,
        error: true,
        payload: err,
    };
}

/**
 * Select an alias in the location aliases admin list. Pass in a falsey id to
 *   clear the current selection.
 * @param  {number} [id]
 */
export function selectAlias(id) {
    return function (dispatch, getState) {
        dispatch(selectAliasStart(id));

        // clear the form
        dispatch(locationAliasesAdminForm.actionCreators.reset());

        if (!id) {
            // clear selection
            return dispatch(selectAliasSuccess());
        }

        // first attempt
        const state = getState();
        const alias = aliasByIdSelector(state)(id);

        if (alias) {
            const locationId = get(alias, 'locationId');
            return dispatch(selectLocationById(locationId))
                .then(({ payload }) => {
                    // fill in the form
                    dispatch(locationAliasesAdminForm.actionCreators.change(alias));

                    return dispatch(selectAliasSuccess(alias, payload));
                })
                .catch((err) => dispatch(selectAliasFailure(err)));
        }

        // TODO: remove this crappy interval when migrating the router, this is
        // a workaround for onEnter/onLeave hooks getting called twice each
        let attempts = 0;
        const intervalId = window.setInterval(() => {
            // retry
            const state = getState();
            const alias = aliasByIdSelector(state)(id);

            if (alias) {
                // success, stop the interval
                window.clearInterval(intervalId);

                const locationId = get(alias, 'locationId');
                return dispatch(selectLocationById(locationId))
                    .then(({ payload }) => {
                        // fill in the form
                        dispatch(locationAliasesAdminForm.actionCreators.change(alias));

                        return dispatch(selectAliasSuccess(alias, payload));
                    })
                    .catch((err) => dispatch(selectAliasFailure(err)));
            }

            attempts++;
            if (attempts > 300) {
                // give up, stop the interval and show an error
                window.clearInterval(intervalId);

                const err = new Mark43Error(strings.selectAliasError);
                return dispatch(selectAliasFailure(err));
            }
        }, 1000);
    };
}

function openNewAliasFormStart(locationId) {
    return {
        type: actionTypes.OPEN_NEW_ALIAS_FORM_START,
        payload: { locationId },
    };
}

function openNewAliasFormSuccess(alias, location) {
    return {
        type: actionTypes.OPEN_NEW_ALIAS_FORM_SUCCESS,
        payload: { alias, location },
    };
}

function openNewAliasFormFailure(err) {
    return {
        type: actionTypes.OPEN_NEW_ALIAS_FORM_FAILURE,
        error: true,
        payload: err,
    };
}

export function openNewAliasForm(locationId) {
    return function (dispatch) {
        dispatch(openNewAliasFormStart(locationId));

        // fill in the form
        dispatch(
            locationAliasesAdminForm.actionCreators.change({
                locationId,
                alias: '',
            })
        );

        const alias = { locationId };

        return dispatch(selectLocationById(locationId))
            .then(({ payload }) => {
                return dispatch(openNewAliasFormSuccess(alias, payload));
            })
            .catch((err) => dispatch(openNewAliasFormFailure(err)));
    };
}

function purgeAliasesStart() {
    return {
        type: actionTypes.PURGE_ALIASES_START,
    };
}

function purgeAliasesFailure(err) {
    return {
        type: actionTypes.PURGE_ALIASES_FAILURE,
        error: true,
        payload: err,
    };
}

function saveAliasStart(alias) {
    return {
        type: actionTypes.SAVE_ALIAS_START,
        payload: alias,
    };
}

function saveAliasSuccess(alias) {
    return {
        type: actionTypes.SAVE_ALIAS_SUCCESS,
        payload: alias,
    };
}

function saveAliasFailure(err) {
    return {
        type: actionTypes.SAVE_ALIAS_FAILURE,
        error: true,
        payload: err,
    };
}

export function saveAlias(alias) {
    return function (dispatch) {
        dispatch(saveAliasStart(alias));

        const isNewAlias = !alias.id;
        const method = isNewAlias ? 'createAlias' : 'updateAlias';

        return resource[method](alias)
            .then((alias) => {
                // reload the location since the formatted address has changed
                dispatch(
                    selectLocationById(alias.locationId, {
                        forceRequest: true,
                    })
                );

                return dispatch(saveAliasSuccess(alias));
            })
            .catch((err) => dispatch(saveAliasFailure(err)));
    };
}

export function purgeAliases() {
    return function (dispatch) {
        dispatch(purgeAliasesStart());
        const method = 'purgeAliases';
        return resource[method]()
            .then(() => {
                return dispatch(loadAliases());
            })
            .catch((err) => dispatch(purgeAliasesFailure(err)));
    };
}

function deleteAliasStart(alias) {
    return {
        type: actionTypes.DELETE_ALIAS_START,
        payload: alias,
    };
}

function deleteAliasSuccess(alias) {
    return {
        type: actionTypes.DELETE_ALIAS_SUCCESS,
        payload: alias,
    };
}

function deleteAliasFailure(err) {
    return {
        type: actionTypes.DELETE_ALIAS_FAILURE,
        error: true,
        payload: err,
    };
}

export function deleteAlias(alias) {
    return function (dispatch) {
        dispatch(deleteAliasStart(alias));

        return resource
            .deleteAlias(alias)
            .then(() => dispatch(deleteAliasSuccess(alias)))
            .catch((err) => dispatch(deleteAliasFailure(err)));
    };
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map, get } from 'lodash';
import { propertyTitleForItemProfileSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import { buildItemProfileLink } from '~/client-common/core/domain/item-profiles/utils/itemProfileHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { iconTypes } from '../../../../core/components/Icon';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import EntitySummaryHeader from './EntitySummaryHeader';

const UnconnectedVehicleTitleSummary = ({ vehicleViewModel, propertyTitleForItemProfile }) => {
    const { primaryColorAttrId } = getViewModelProperties(vehicleViewModel);
    const summaryFieldConfig = [
        {
            fieldName: fields.VEHICLE_VIN_NUMBER,
            value: get(vehicleViewModel, 'vinNumber'),
        },
        {
            fieldName: fields.VEHICLE_TAG,
            value: get(vehicleViewModel, 'tag'),
        },
        {
            fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_PRIMARY_COLOR_ATTR_ID,
            value: primaryColorAttrId,
        },
    ];
    return (
        <EntitySummaryHeader
            iconType={iconTypes.VEHICLE}
            name={propertyTitleForItemProfile(vehicleViewModel)}
            linkTo={buildItemProfileLink(vehicleViewModel)}
        >
            <SummaryList>
                {map(summaryFieldConfig, ({ fieldName, value }) => {
                    return (
                        <SummaryRow key={fieldName} fieldName={fieldName}>
                            {value}
                        </SummaryRow>
                    );
                })}
            </SummaryList>
        </EntitySummaryHeader>
    );
};

export const VehicleTitleSummary = connect(
    createStructuredSelector({
        propertyTitleForItemProfile: propertyTitleForItemProfileSelector,
    })
)(UnconnectedVehicleTitleSummary);

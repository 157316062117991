import evidenceItemSplitResource from '../../resources/evidenceItemSplitResource';

const SPLIT_ITEM_START = 'item-split/SPLIT_ITEM_START';
const SPLIT_ITEM_SUCCESS = 'item-split/SPLIT_ITEM_SUCCESS';
const SPLIT_ITEM_FAILURE = 'item-split/SPLIT_ITEM_FAILURE';

function splitItemStart() {
    return { type: SPLIT_ITEM_START };
}
function splitItemSuccess() {
    return { type: SPLIT_ITEM_SUCCESS };
}
function splitItemFailure(errorMessage) {
    return { type: SPLIT_ITEM_FAILURE, error: true, payload: errorMessage };
}

/**
 * Split Item
 * @param  {number}   itemId
 * @param  {Object[]} items
 * @return {Promise<Object[]>}
 */
export function splitItem(itemId, items) {
    return (dispatch) => {
        dispatch(splitItemStart());

        return evidenceItemSplitResource
            .splitEvidenceItem(itemId, items)
            .then((hydratedItems) => {
                dispatch(splitItemSuccess());
                return hydratedItems;
            })
            .catch((error) => {
                dispatch(splitItemFailure(error.message));
                throw error;
            });
    };
}

import { createSelector } from 'reselect';

export const renSearchUiSelector = (state) => state.ui.renSearch;

export const getEnteredReportingEventNumber = createSelector(
    [renSearchUiSelector],
    ({ enteredReportingEventNumber }) => enteredReportingEventNumber
);

export const getEnteredCadAgencyEventNumber = createSelector(
    [renSearchUiSelector],
    ({ enteredCadAgencyEventNumber }) => enteredCadAgencyEventNumber
);

import {
    EvidenceHydratedItem,
    ElasticEntityPermission,
    OperationTypeEnumType,
    UserRole,
} from '@mark43/evidence-api';

import _, { compact, flatMap, map } from 'lodash';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/attachments/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { NEXUS_STATE_PROP as CHAIN_EVENT_TYPES_PROP } from '~/client-common/core/domain/chain-event-types/state/data';
import { NEXUS_STATE_PROP as CHAIN_EVENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/chain-events/state/data';
import { NEXUS_STATE_PROP as CHAIN_OF_CUSTODIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/chain-of-custodies/state/data';
import { NEXUS_STATE_PROP as CUSTODIAL_RESPONSIBLE_OFFICERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/custodial-responsible-officers/state/data';
import { NEXUS_STATE_PROP as DISPOSITION_EVENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/disposition-events/state/data';
import { NEXUS_STATE_PROP as EVIDENCE_REPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/evidence-reports/state/data';
import { NEXUS_STATE_PROP as FACILITIES_PROP } from '~/client-common/core/domain/facilities/state/data';
import { NEXUS_STATE_PROP as FIREARMS_NEXUS_STATE_PROP } from '~/client-common/core/domain/firearms/state/data';
import { NEXUS_STATE_PROP as ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-evidence-states/state/data';
import { NEXUS_STATE_PROP as ITEM_FACILITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-facility-links/state/data';
import { NEXUS_STATE_PROP as ITEM_IDENTIFIERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-identifiers/state/data';
import { NEXUS_STATE_PROP as ITEM_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-profiles/state/data';
import { NEXUS_STATE_PROP as ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-reporting-event-links/state/data';
import { NEXUS_STATE_PROP as MINI_USER_PROP } from '~/client-common/core/domain/mini-users/state/data';
import { NEXUS_STATE_PROP as NAME_ITEM_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-item-links/state/data';
import { NEXUS_STATE_PROP as RETENTION_POLICIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/retention-policies/state/data';
import { NEXUS_STATE_PROP as STAFF_REMARKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/staff-remarks/state/data';
import { NEXUS_STATE_PROP as STORAGE_LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/storage-locations/state/data';
import { NEXUS_STATE_PROP as VEHICLE_MAKES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicle-makes/state/data';
import { NEXUS_STATE_PROP as VEHICLE_MODELS_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicle-models/state/data';
import { NEXUS_STATE_PROP as VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicles/state/data';
import { NEXUS_STATE_PROP as FIREARM_ATF_MANUFACTURERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/etrace-atf/state/data';
import {
    NEXUS_STATE_PROP as EVIDENCE_LOCATION_PERMISSION_STATE_PROP,
    EvidenceLocationPermissionsModule,
} from '~/client-common/core/domain/evidence-location-permissions/state/data';
import { mergeDispositionEventAndState } from '~/client-common/core/domain/disposition-events/utils/dispositionEventsHelpers';
import { convertStorageLocationsToElasticAndStore } from '~/client-common/core/domain/elastic-storage-locations/state/data';
import { RmsAction } from '../../../../core/typings/redux';

import { mergeArrayOfObjects } from './mergeArrayOfObjects';

interface WithEvidenceHydratedItems {
    type: 'evidence/STORE_EVIDENCE_HYDRATED_ITEMS';
    meta: Record<string, unknown>;
}

export function mapHydratedEvidenceItemToNexusProp(hydratedItem: EvidenceHydratedItem) {
    return {
        [ATTRIBUTES_NEXUS_STATE_PROP]:
            _(hydratedItem.attributes).map(convertAttributeToAttributeView).value() || [],
        [ATTACHMENTS_NEXUS_STATE_PROP]: hydratedItem.attachments || [],
        [CHAIN_EVENTS_NEXUS_STATE_PROP]: hydratedItem.chainEvents || [],
        [CHAIN_EVENT_TYPES_PROP]: hydratedItem.chainEventTypes || [],
        [FACILITIES_PROP]: hydratedItem.facilities || [],
        // @ts-expect-error TODO remove this type error ignore after RMS-15373 is done,
        // that EvidenceHydratedItem in mark43-resources has been updated with miniUser
        [MINI_USER_PROP]: hydratedItem.miniUsers || [],
        [CHAIN_OF_CUSTODIES_NEXUS_STATE_PROP]: hydratedItem.chainOfCustodies || [],
        [CUSTODIAL_RESPONSIBLE_OFFICERS_NEXUS_STATE_PROP]: hydratedItem.responsibleOfficers || [],
        [DISPOSITION_EVENTS_NEXUS_STATE_PROP]:
            mergeDispositionEventAndState(
                hydratedItem.dispositionEvents,
                hydratedItem.dispositionStates
            ) || [],
        [EVIDENCE_REPORTS_NEXUS_STATE_PROP]: hydratedItem.evidenceReports || [],
        [FIREARMS_NEXUS_STATE_PROP]: hydratedItem.firearms || [],
        [ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP]: hydratedItem.itemEvidenceStates || [],
        [ITEM_FACILITY_LINKS_NEXUS_STATE_PROP]: hydratedItem.itemFacilityLinks || [],
        [ITEM_IDENTIFIERS_NEXUS_STATE_PROP]: hydratedItem.itemIdentifiers || [],
        [ITEM_PROFILES_NEXUS_STATE_PROP]: hydratedItem.items || [],
        [ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP]:
            hydratedItem.masterItemReportingEventLinks || [],
        [NAME_ITEM_LINKS_NEXUS_STATE_PROP]: hydratedItem.nameItemLinks || [],
        [RETENTION_POLICIES_NEXUS_STATE_PROP]: hydratedItem.retentionPolicies || [],
        [STAFF_REMARKS_NEXUS_STATE_PROP]: hydratedItem.staffRemarks || [],
        [STORAGE_LOCATIONS_NEXUS_STATE_PROP]: hydratedItem.storageLocationViews || [],
        [VEHICLES_NEXUS_STATE_PROP]: hydratedItem.vehicles || [],
        [VEHICLE_MAKES_NEXUS_STATE_PROP]: hydratedItem.vehicleMakes || [],
        [VEHICLE_MODELS_NEXUS_STATE_PROP]: hydratedItem.vehicleModels || [],
        [EVIDENCE_LOCATION_PERMISSION_STATE_PROP]:
            map(hydratedItem.evidenceLocationPermissions, (permission) => ({
                ...permission,
                itemId: hydratedItem.id,
                masterItemId: hydratedItem.item.masterItemId,
            })) || [],
        [FIREARM_ATF_MANUFACTURERS_NEXUS_STATE_PROP]: hydratedItem.atfManufacturers || [],
    };
}

function mapHydratedEvidenceItemsToNexusProp(
    hydratedItems: EvidenceHydratedItem[]
): ReturnType<typeof mapHydratedEvidenceItemToNexusProp> {
    const stateList = hydratedItems.map(mapHydratedEvidenceItemToNexusProp);
    return mergeArrayOfObjects(stateList);
}

export function withEvidenceHydratedItems(
    hydratedItems: EvidenceHydratedItem[],
    withEntityItems: unknown
): WithEvidenceHydratedItems {
    const entities = mapHydratedEvidenceItemsToNexusProp(hydratedItems);
    // @ts-expect-error TODO: Type withEntityItems
    return withEntityItems(entities, { type: 'evidence/STORE_EVIDENCE_HYDRATED_ITEMS' });
}

export function storeEvidenceHydratedItems(
    hydratedItems: EvidenceHydratedItem[]
): RmsAction<EvidenceHydratedItem[]> {
    return function (dispatch, getState, dependencies) {
        dispatch(withEvidenceHydratedItems(hydratedItems, dependencies.nexus.withEntityItems));
        dispatch(
            convertStorageLocationsToElasticAndStore(
                compact(flatMap(hydratedItems, 'storageLocationViews'))
            )
        );

        return hydratedItems;
    };
}

export function storeEvidenceHydratedItemsLite(
    hydratedItems: EvidenceHydratedItem[]
): RmsAction<EvidenceHydratedItem[]> {
    return function (dispatch, getState, dependencies) {
        const entitiesToStore = {
            [CHAIN_EVENTS_NEXUS_STATE_PROP]: flatMap(hydratedItems, 'chainEvents'),
            [CHAIN_OF_CUSTODIES_NEXUS_STATE_PROP]: flatMap(hydratedItems, 'chainOfCustodies'),
            [ITEM_FACILITY_LINKS_NEXUS_STATE_PROP]: flatMap(hydratedItems, 'itemFacilityLinks'),
            [STORAGE_LOCATIONS_NEXUS_STATE_PROP]: flatMap(hydratedItems, 'storageLocationViews'),
            [EVIDENCE_LOCATION_PERMISSION_STATE_PROP]: _(hydratedItems)
                .flatMap((hydratedItem) => {
                    return map(hydratedItem.evidenceLocationPermissions, (permission) => ({
                        ...permission,
                        itemId: hydratedItem.id,
                        masterItemId: hydratedItem.item.masterItemId,
                    }));
                })
                .value(),
        };

        dispatch(
            dependencies.nexus.withEntityItems(entitiesToStore, {
                type: 'evidence/LOAD_EVIDENCE_ITEMS_LITE',
            })
        );
        dispatch(
            convertStorageLocationsToElasticAndStore(flatMap(hydratedItems, 'storageLocationViews'))
        );

        return hydratedItems;
    };
}

export function hasEvidenceLocationPermission(
    evidenceLocationPermsSet: ElasticEntityPermission[][] | EvidenceLocationPermissionsModule[][],
    userRolesById: Record<number, UserRole>,
    permissionType: OperationTypeEnumType
): boolean {
    for (const permissions of evidenceLocationPermsSet) {
        let userHasManagePerm = false;

        if (!permissions) {
            return false;
        }

        for (const perm of permissions) {
            const { operationType, roleId } = perm;
            if (operationType === permissionType && userRolesById[roleId]) {
                userHasManagePerm = true;
            }
        }

        // return false if current user don't have manage permission on one of evidence locations
        if (!userHasManagePerm) {
            return false;
        }
    }
    return true;
}

import React from 'react';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../core/overlays/components/Modal';
import { SimpleLoading } from '../../../legacy-redux/components/core/Loading';

const ClipsSendingModal: React.FC = () => {
    const strings = componentStrings.clips.ClipsSendingModal;

    return (
        <Modal
            id={overlayIdEnum.CLIPS_PREFILL_SENDING_MODAL}
            okText={null}
            cancelText={null}
            hideFooter={true}
        >
            <div className="mark43-loading-modal-content">
                <SimpleLoading />
                <div>{strings.sending}</div>
            </div>
        </Modal>
    );
};

export default ClipsSendingModal;

import React, { useState } from 'react';
import groupBy from 'lodash/groupBy';
import noop from 'lodash/noop';
import { useDispatch, useSelector } from 'react-redux';
import { RefContextEnum, TaskComment, TasksView } from '@mark43/rms-api';

import { taskCommentsSelector } from '~/client-common/core/domain/task-comments/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import useFields from '~/client-common/core/fields/hooks/useFields';
import * as fields from '~/client-common/core/enums/universal/fields';
import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { FormattedDate } from '~/client-common/core/dates/components';

import {
    PortalSidePanel,
    SidePanelSection,
} from '../../../../legacy-redux/components/core/SidePanel';
import { clearUploads } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import { BodyMediumText } from '../../../core/components/typography';
import testIds from '../../../../core/testIds';
import { RmsDispatch } from '../../../../core/typings/redux';
import { Button as ArcButton } from '../../../core/components/Button';
import { iconTypes } from '../../../core/components/Icon';
import Row from '../../../core/components/Row';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import OverlayButton, { buttonTypes } from '../../../core/overlays/components/OverlayButton';
import { todoTaskStatusAttributeIdSelector } from '../data';
import {
    deleteTaskComment,
    PendingComment,
    saveTaskComment,
    saveTaskForm,
    TaskOverlayCustomProperties,
    TaskOverlayMode,
    unregisterTaskForm,
} from '../state/ui';
import TaskEntitiesLinkSidePanel from './TaskEntitiesLinkSidePanel';
import TaskFormComment from './TaskFormComment';
import TaskFormHistory from './TaskFormHistory';
import TaskForm from './TaskForm';
import TaskEntityLinksSection from './TaskEntityLinksSection';
import TaskAddUpdateModal, { useOpenTaskAddUpdateModal } from './TaskAddUpdateModal';
import TaskErrorModal from './TaskErrorModal';

interface TaskSidePanelProps {
    onSave?: (taskView?: TasksView) => void;
    onClose?: () => void;
}

const strings = componentStrings.tasks.core.TaskSidePanel;

const panelId = overlayIdEnum.TASK_OVERLAY;

const TaskSidePanel: React.FC<TaskSidePanelProps> = ({
    onSave = noop,
    onClose = noop,
    ...props
}) => {
    const dispatch = useDispatch<RmsDispatch>();
    const getAttributeById = useSelector(getAttributeByIdSelector);
    const defaultTaskStatusAttributeId = useSelector(todoTaskStatusAttributeIdSelector);
    const [pendingComments, setPendingComments] = useState<PendingComment[]>([]);
    const fieldDisplayNames = useFields(fields.DISPLAY_ONLY_TASK_LINKED_ITEMS);
    const { getForm } = useFormGetter();
    const openTaskAddUpdateModal = useOpenTaskAddUpdateModal();

    const onClosePanel = React.useCallback(() => {
        dispatch(unregisterTaskForm());
        dispatch(clearUploads());
        setPendingComments([]);
        onClose();
    }, [dispatch, onClose]);

    const onSavePanel = React.useCallback(() => {
        dispatch(saveTaskForm(pendingComments))
            .then((tasksView) => {
                setPendingComments([]);
                onSave(tasksView);
            })
            .catch(noop);
    }, [dispatch, onSave, pendingComments]);

    const onSaveComment = (taskId: number | undefined, comment: PendingComment) => {
        if (taskId) {
            dispatch(saveTaskComment(taskId, comment));
        }
    };

    const onDeleteComment = (commentId: number) => {
        dispatch(deleteTaskComment(commentId));
    };

    const groupedComments = groupBy(useSelector(taskCommentsSelector), 'taskId');

    return (
        <>
            <OverlayBaseHelper<TaskOverlayCustomProperties> id={panelId}>
                {(renderProps) => {
                    const {
                        mode,
                        task,
                        taskEntityLinks = [],
                    } = renderProps.overlayBase.overlayState.customProperties;

                    const isEditable = mode !== TaskOverlayMode.VIEW;
                    const editableTitle =
                        mode === TaskOverlayMode.EDIT
                            ? strings.editTaskTitle
                            : strings.newTaskTitle;
                    const isExistingTask = task?.id;
                    const existingComments =
                        task?.id && groupedComments[task?.id] ? groupedComments[task?.id] : [];
                    const hasPendingComments = pendingComments.length > 0;

                    const setFormStatusId = (statusAttrId?: number) => {
                        const form = getForm(RefContextEnum.FORM_TASK.name);
                        if (form) {
                            form.set('statusAttrId', statusAttrId);
                        }
                    };

                    const handleStatusChange = (attributeId: number) => {
                        const selectedOption = getAttributeById(attributeId);

                        if (
                            selectedOption?.parentId === globalAttributes.taskStatusGlobal.completed
                        ) {
                            openTaskAddUpdateModal({
                                task,
                                taskEntityLinks,
                                withoutUpdateRequest: true,
                                onSave: (pendingUpdates) => {
                                    setPendingComments((prevState) => [
                                        ...prevState,
                                        ...pendingUpdates,
                                    ]);
                                },
                                onCancel: () =>
                                    setFormStatusId(
                                        task?.statusAttrId ?? defaultTaskStatusAttributeId
                                    ),
                            });
                        }
                    };

                    return (
                        <PortalSidePanel
                            {...props}
                            id={panelId}
                            testId={testIds.TASK_SIDEPANEL}
                            errorMessages={renderProps.overlayBase.overlayState.errors}
                            userHasAttemptedSave={
                                !!renderProps.overlayBase.overlayState.errors.length
                            }
                            title={isEditable ? editableTitle : 'View Task'}
                            cancelText={isEditable ? 'Cancel' : 'Close'}
                            saveText={isEditable ? 'Save' : null} // null hides the button
                            savePanel={onSavePanel}
                            closePanel={() => {
                                onClosePanel();
                                renderProps.overlayBase.close();
                            }}
                            isLoading={renderProps.overlayBase.overlayState.isLoading}
                            isAtBottomOfStack={() => {
                                return renderProps.overlayBase.overlayStore.isOverlayAtBottomOfStack(
                                    panelId
                                );
                            }}
                        >
                            <FeatureFlagged
                                flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                                fallback={<TaskForm disabled={!isEditable} />}
                            >
                                <TaskForm
                                    onStatusChange={handleStatusChange}
                                    disabled={!isEditable}
                                />
                            </FeatureFlagged>
                            <FeatureFlagged flag="RMS_TASK_ENTITY_LINKS_ENABLED">
                                <SidePanelSection
                                    title={fieldDisplayNames.DISPLAY_ONLY_TASK_LINKED_ITEMS}
                                    testId={testIds.TASK_LINKED_ITEMS_SECTION}
                                >
                                    <TaskEntityLinksSection disabled={!isEditable} />
                                    {isEditable && (
                                        <FeatureFlagged
                                            flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
                                            fallback={
                                                <OverlayButton
                                                    testId={testIds.ADD_LINKED_ITEMS_BUTTON}
                                                    className={buttonTypes.ICON_LINK}
                                                    iconLeft={iconTypes.ADD}
                                                    id={overlayIdEnum.TASK_ENTITIES_LINK_SIDE_PANEL}
                                                    children={strings.addLinkedItem}
                                                />
                                            }
                                        >
                                            <OverlayButton
                                                id={overlayIdEnum.TASK_ENTITIES_LINK_SIDE_PANEL}
                                            >
                                                {(openOverlay: () => void) => (
                                                    <ArcButton
                                                        testId={testIds.ADD_LINKED_ITEMS_BUTTON}
                                                        variant="ghost"
                                                        leadingVisual="Add"
                                                        onClick={openOverlay}
                                                    >
                                                        {strings.addLinkedItem}
                                                    </ArcButton>
                                                )}
                                            </OverlayButton>
                                        </FeatureFlagged>
                                    )}
                                </SidePanelSection>
                                <TaskEntitiesLinkSidePanel />
                            </FeatureFlagged>
                            <FeatureFlagged
                                flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                                fallback={
                                    task && task.createdBy && task.createdDateUtc ? (
                                        <Row testId={testIds.TASK_CREATOR}>
                                            <BodyMediumText color="tertiary">
                                                {task.createdBy && (
                                                    <ConnectedFormattedUser
                                                        userId={task.createdBy}
                                                        format={
                                                            FORMATS.FULL_NAME_WITH_FIRST_INITIAL
                                                        }
                                                    />
                                                )}
                                                {' created task on '}
                                                <BodyMediumText isItalic>
                                                    <FormattedDate
                                                        date={task.createdDateUtc}
                                                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                                                    />
                                                </BodyMediumText>
                                            </BodyMediumText>
                                        </Row>
                                    ) : null
                                }
                            >
                                {(isExistingTask || hasPendingComments) && (
                                    <TaskFormComment
                                        onAddComment={(comment) =>
                                            mode === TaskOverlayMode.EDIT && !hasPendingComments
                                                ? onSaveComment(task?.id, comment)
                                                : setPendingComments([...pendingComments, comment])
                                        }
                                        comments={[...pendingComments, ...existingComments]}
                                        deleteComment={(comment) =>
                                            mode === TaskOverlayMode.EDIT && !hasPendingComments
                                                ? /**
                                                   * The type of `comment` is `PendingComment | TaskComment`.
                                                   * TODO: Type guard `comment` to be `TaskComment` when isEdit is true, and
                                                   * remove this type assertion.
                                                   */
                                                  onDeleteComment((comment as TaskComment).id)
                                                : setPendingComments(
                                                      pendingComments.filter(
                                                          (existingComment) =>
                                                              existingComment.comment !==
                                                                  comment.comment ||
                                                              existingComment.createdDateUtc !==
                                                                  comment.createdDateUtc
                                                      )
                                                  )
                                        }
                                        disabled={!isEditable}
                                    />
                                )}

                                {isExistingTask && <TaskFormHistory task={task} />}
                            </FeatureFlagged>
                        </PortalSidePanel>
                    );
                }}
            </OverlayBaseHelper>

            <FeatureFlagged flag="RMS_TASK_ENHANCEMENTS_ENABLED">
                <TaskAddUpdateModal />
            </FeatureFlagged>
            <TaskErrorModal />
        </>
    );
};

export default TaskSidePanel;

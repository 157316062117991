import { SystemRuleEnum, CodeTypeCategoryEnum } from '@mark43/rms-api';
import _, {
    size,
    includes,
    some,
    every,
    forEach,
    result,
    isNull,
    isUndefined,
    filter,
} from 'lodash';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import validationStrings from '~/client-common/core/strings/validationStrings';
import nibrsCodes from '~/client-common/core/constants/nibrsCodes';
import nibrsCrimeAgainst from '~/client-common/core/constants/nibrsCrimeAgainst';
import { isNibrsArson, isNibrsBurglary } from '~/client-common/helpers/offenseCodesHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';
import addRuleId from '../helpers/addRuleId';
import { setKoModelValidation } from '../helpers/koValidationHelper';
import {
    nibrsCodeForAttributeIdAndCodeTypeCategorySelector,
    currentUcrSourceSelector,
} from '../../../modules/reports/ucr-classification/state/ui/selectors';
import { isUcr } from '../../../modules/reports/ucr-classification/utils/ucrClassificationHelper';

function isUcrDepartment(reduxState) {
    const currentUcrSource = currentUcrSourceSelector(reduxState);
    return isUcr(currentUcrSource);
}

export const offenseCodeRequired = addRuleId(
    SystemRuleEnum.OFFENSE_CODE_REQUIRED.name,
    (offense, errMsg) => {
        const isValid = offense.offense.offenseCodeId() > 0;
        setKoModelValidation(offense.offense.offenseCodeId, isValid, errMsg);
        return isValid;
    }
);

export const justifiableHomicideRequired = addRuleId(
    SystemRuleEnum.OFFENSE_JUSTIFIABLE_HOMICIDE_REQUIRED.name,
    (offense, errMsg) => {
        const isValid =
            offense.offense.showConditionalFields &&
            offense.offense.showConditionalFields.justifiableCircumstance()
                ? !!offense.offense.justifiableHomicideAttrId()
                : true;
        setKoModelValidation(offense.offense.justifiableHomicideAttrId, isValid, errMsg);
        return isValid;
    }
);

export const negligentManslaughterRequired = addRuleId(
    SystemRuleEnum.OFFENSE_NEGLIGENT_MANSLAUGHTER_REQUIRED.name,
    (offense, errMsg) => {
        const isValid =
            offense.offense.showConditionalFields &&
            offense.offense.showConditionalFields.negligentManslaughter()
                ? !!offense.offense.negligentManslaughterAttrId()
                : true;
        setKoModelValidation(offense.offense.negligentManslaughterAttrId, isValid, errMsg);
        return isValid;
    }
);

export const includesCargoTheftRequired = addRuleId(
    SystemRuleEnum.OFFENSE_INCLUDES_CARGO_THEFT_REQUIRED.name,
    (offense, errMsg) => {
        const isValid =
            offense.offense.showConditionalFields &&
            offense.offense.showConditionalFields.cargoTheft()
                ? !!offense.offense.includesCargoTheftSelect()
                : true;
        setKoModelValidation(offense.offense.includesCargoTheftSelect, isValid, errMsg);
        return isValid;
    }
);

export const burgWasMethodOfEntryForcedRequired = addRuleId(
    SystemRuleEnum.OFFENSE_BURG_WAS_METHOD_OF_ENTRY_FORCED_REQUIRED.name,
    (offense, errMsg) => {
        const isValid =
            isNibrsBurglary(offense.offense.nibrsCode()) && !offense.offense.isNonCustodial()
                ? !!offense.offense.burgWasMethodOfEntryForcedSelect()
                : true;
        setKoModelValidation(offense.offense.burgWasMethodOfEntryForcedSelect, isValid, errMsg);
        return isValid;
    }
);

/**
 * Number of Premises Entered is required if the offense selected maps to is 220= Burglary/ Breaking and Entering
 * and the location category chosen maps to 14= Hotel/ Motel or 19= Rental Storage Facility.
 * */
export const burgNumberOfPremisesEnteredRequired = addRuleId(
    SystemRuleEnum.OFFENSE_BURG_NUMBER_OF_PREMISES_ENTERED_REQUIRED.name,
    (offense, errMsg, state) => {
        if (
            isNibrsBurglary(offense.offense.nibrsCode()) &&
            !offense.offense.isNonCustodial() &&
            offense.location.length > 0
        ) {
            const locationCode = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(state)(
                offense.location[0].typeAttrId(),
                CodeTypeCategoryEnum.NIBRS_LOCATION_CATEGORY.name
            );
            const isValid =
                locationCode === '14' || locationCode === '19'
                    ? !!offense.offense.burgNumberOfPremisesEntered()
                    : true;
            setKoModelValidation(offense.offense.burgNumberOfPremisesEntered, isValid, errMsg);
            return isValid;
        }
        return true;
    }
);

export const offenseLocationRequired = addRuleId(
    SystemRuleEnum.OFFENSE_LOCATION_REQUIRED.name,
    (offense) => {
        return offense.location.length > 0;
    }
);

export const incidentLocationRequired = addRuleId(
    SystemRuleEnum.INCIDENT_LOCATION_REQUIRED.name,
    (offense) => {
        return offense.location.length > 0;
    }
);

export const offenseLarcenyCannotHaveStolenVehicle = addRuleId(
    SystemRuleEnum.OFFENSE_LARCENY_CANNOT_HAVE_STOLEN_VEHICLE.name,
    (offense) => {
        if (
            offense.offense.nibrsCode() &&
            includes(nibrsCodes.larceny, offense.offense.nibrsCode().code)
        ) {
            const vehicles = filter(offense.propertyStatuses, (record) => {
                return (
                    record.item.itemTypeAttrId() === globalAttributes.itemType.vehicle &&
                    result(record, 'statuses[0].isStolen')
                );
            });

            return !(vehicles.length > 0);
        } else {
            return true;
        }
    }
);

export const offenseBurglaryCannotHaveSocietyVictim = addRuleId(
    SystemRuleEnum.OFFENSE_BURGLARY_CANNOT_HAVE_SOCIETY_VICTIM.name,
    (offense) => {
        let isValid = true;
        if (isNibrsBurglary(offense.offense.nibrsCode())) {
            const societyVictims = filter(offense.orgVictims, (victim) => {
                return victim.isSociety();
            });
            if (societyVictims.length > 0) {
                isValid = false;
            }
        }
        return isValid;
    }
);

export const offenseCrimeAgainstPropertyVictimIsNotSociety = addRuleId(
    SystemRuleEnum.OFFENSE_CRIME_AGAINST_PROPERTY_VICTIM_IS_NOT_SOCIETY.name,
    (offense) => {
        // cannot have society victim
        let isValid = true;
        if (
            offense.offense.nibrsCode() &&
            offense.offense.nibrsCode().crimeAgainst === nibrsCrimeAgainst.property
        ) {
            const societyVictims = filter(offense.orgVictims, (victim) => {
                return victim.isSociety();
            });
            if (societyVictims.length > 0) {
                isValid = false;
            }
        }
        return isValid;
    }
);

export const offenseCrimeAgainstPersonHasPersonVictim = addRuleId(
    SystemRuleEnum.OFFENSE_CRIME_AGAINST_PERSON_HAS_PERSON_VICTIM.name,
    (offense) => {
        let isValid = true;
        if (
            offense.offense.nibrsCode() &&
            offense.offense.nibrsCode().crimeAgainst === nibrsCrimeAgainst.person
        ) {
            if (offense.personVictimLinks.length < 1) {
                isValid = false;
            }
        }
        return isValid;
    }
);

export const offenseSexAssaultHasKnownPersonVictim = addRuleId(
    SystemRuleEnum.OFFENSE_SEX_ASSAULT_HAS_KNOWN_PERSON_VICTIM.name,
    (offense) => {
        // these are sexual assault offense codes
        if (
            offense.offense.nibrsCode() &&
            includes(nibrsCodes.sexAssault, offense.offense.nibrsCode().code)
        ) {
            const knownPersonVictims = filter(offense.personVictimEntities, (person) => {
                return !person.isUnknown();
            });
            return knownPersonVictims.length > 0;
        } else {
            return true;
        }
    }
);

export const offenseDomesticViolenceHasKnownPersonVictim = addRuleId(
    SystemRuleEnum.OFFENSE_DOMESTIC_VIOLENCE_HAS_KNOWN_PERSON_VICTIM.name,
    (offense) => {
        if (offense.offense.isDomesticViolence()) {
            const knownPersonVictims = filter(offense.personVictimEntities, (person) => {
                return !person.isUnknown();
            });
            return knownPersonVictims.length > 0;
        } else {
            return true;
        }
    }
);

export const offenseHasVictim = addRuleId(
    SystemRuleEnum.OFFENSE_VICTIM_REQUIRED.name,
    (offense) => {
        return (
            offense.isIncident ||
            offense.orgVictims.length > 0 ||
            offense.personVictimLinks.length > 0
        );
    }
);

export const offenseHasSuspect = addRuleId(
    SystemRuleEnum.OFFENSE_SUSPECT_REQUIRED.name,
    (offense) => {
        return (
            offense.isIncident ||
            offense.orgSuspects.length > 0 ||
            offense.personSuspectLinks.length > 0
        );
    }
);

// If this offense requires property (per nibrs designation),
// then there must be property records attached to the offense
export const offensePropertyRecordsRequired = addRuleId(
    SystemRuleEnum.OFFENSE_PROPERTY_STATUSES_REQUIRED.name,
    (offense, errMsg, state) => {
        return (
            isUcrDepartment(state) ||
            (!offense.isIncident && offense.offense.isPropertyRequired() && offense.propertyStatuses
                ? offense.propertyStatuses.length !== 0
                : true)
        );
    }
);

export const offenseIsLeokaRequired = addRuleId(
    SystemRuleEnum.OFFENSE_IS_LEOKA_REQUIRED.name,
    (offense, errMsg) => {
        let allValid = true;
        _.forEach(offense.personVictimLinks, (victim) => {
            const isValid = victim.canIncludeLeoka() // this is a field on the model, only happens in certain cases... check this
                ? !!victim.isLeokaSelect()
                : true;
            setKoModelValidation(victim.isLeokaSelect, isValid, errMsg);
            if (!isValid) {
                allValid = false;
            }
        });
        return allValid;
    }
);

export const offenseLeokaActivityRequired = addRuleId(
    SystemRuleEnum.OFFENSE_LEOKA_ACTIVITY_REQUIRED.name,
    (offense, errMsg) => {
        let allValid = true;
        _.forEach(offense.personVictimLinks, (victim) => {
            const isValid = victim.isLeoka() ? !!victim.leokaActivityAttrId() : true;
            setKoModelValidation(victim.leokaActivityAttrId, isValid, errMsg);
            if (!isValid) {
                allValid = false;
            }
        });
        return allValid;
    }
);

export const offenseLeokaAssignmentTypeRequired = addRuleId(
    SystemRuleEnum.OFFENSE_LEOKA_ASSIGNMENT_TYPE_REQUIRED.name,
    (offense, errMsg) => {
        let allValid = true;
        _.forEach(offense.personVictimLinks, (victim) => {
            const isValid = victim.isLeoka() ? !!victim.leokaAssignmentTypeAttrId() : true;
            setKoModelValidation(victim.leokaAssignmentTypeAttrId, isValid, errMsg);
            if (!isValid) {
                allValid = false;
            }
        });
        return allValid;
    }
);

export const offenseStatementTakenRequired = addRuleId(
    SystemRuleEnum.OFFENSE_STATEMENT_TAKEN_REQUIRED.name,
    (offense, errMsg) => {
        let allValid = true;
        _.forEach(
            _.union(
                offense.personVictimLinks,
                offense.personSuspectLinks,
                offense.personWitnessLinks
            ),
            (person) => {
                const isValid = person.statementTaken() ? !!person.statement() : true;
                setKoModelValidation(person.statement, isValid, errMsg);
                if (!isValid) {
                    allValid = false;
                }
            }
        );
        return allValid;
    }
);

export const offenseIsSuspectedHateCrimeRequired = addRuleId(
    SystemRuleEnum.OFFENSE_SUSPECTED_HATE_CRIME_REQUIRED.name,
    (offense, errMsg) => {
        const isValid =
            offense.offense.isNonCustodial() || !!offense.offense.suspectedHateCrimeSelect();
        setKoModelValidation(offense.offense.suspectedHateCrimeSelect, isValid, errMsg);
        return isValid;
    }
);

export const offenseHateBiasRequired = addRuleId(
    SystemRuleEnum.OFFENSE_HATE_BIAS.name,
    (offense, errMsg) => {
        let isValid = true;
        if (offense.offense.isSuspectedHateCrime()) {
            isValid = offense.hateBias.model.attributes().length > 0;
            setKoModelValidation(offense.hateBias.model.attributes, isValid, errMsg);
        }
        return isValid;
    }
);

export const offenseAggravatedAssaultRequired = addRuleId(
    SystemRuleEnum.OFFENSE_AGGRAVATED_ASSAULT_REQUIRED.name,
    (offense, errMsg) => {
        let isValid = true;
        if (offense.offense.showConditionalFields.aggravatedAssault()) {
            isValid = offense.aggravatedAssault.model.attributes().length > 0;
            setKoModelValidation(offense.aggravatedAssault.model.attributes, isValid, errMsg);
        }
        return isValid;
    }
);

export const offenseHomicideFactorsRequired = addRuleId(
    SystemRuleEnum.OFFENSE_HOMICIDE_CIRCUMSTANCE_REQUIRED.name,
    (offense, errMsg) => {
        let isValid = true;
        if (offense.offense.showConditionalFields.homicideFactors()) {
            isValid = offense.homicideFactors.model.attributes().length > 0;
            setKoModelValidation(offense.homicideFactors.model.attributes, isValid, errMsg);
        }
        return isValid;
    }
);

export const offenseWeaponForceInvolvedRequired = addRuleId(
    SystemRuleEnum.OFFENSE_WEAPON_OR_FORCE_INVOLVED_REQUIRED.name,
    (offense, errMsg) => {
        let isValid = true;
        if (offense.offense.showConditionalFields.weaponForceInvolved()) {
            isValid = offense.weaponForceInvolved.model.attributes().length > 0;
            setKoModelValidation(offense.weaponForceInvolved.model.attributes, isValid, errMsg);
        }
        return isValid;
    }
);

export const offenseGangInfoRequired = addRuleId(
    SystemRuleEnum.OFFENSE_GANG_INFO_REQUIRED.name,
    (offense, errMsg) => {
        let isValid = true;
        if (offense.offense.showConditionalFields.gangInfo()) {
            isValid = offense.gangInfo.model.attributes().length > 0;
            setKoModelValidation(offense.gangInfo.model.attributes, isValid, errMsg);
        }
        return isValid;
    }
);

export const offenseAnimalCrueltyRequired = addRuleId(
    SystemRuleEnum.OFFENSE_ANIMAL_CRUELTY_REQUIRED.name,
    (offense, errMsg) => {
        let isValid = true;
        if (offense.offense.showConditionalFields.animalCruelty()) {
            isValid = offense.animalCruelty.model.attributes().length > 0;
            setKoModelValidation(offense.animalCruelty.model.attributes, isValid, errMsg);
        }
        return isValid;
    }
);

export const offenseCriminalActivitiesRequired = addRuleId(
    SystemRuleEnum.OFFENSE_CRIMINAL_ACTIVITY_CATEGORY_REQUIRED.name,
    (offense, errMsg) => {
        let isValid = true;
        if (offense.offense.showConditionalFields.criminalActivities()) {
            isValid = offense.criminalActivities.model.attributes().length > 0;
            setKoModelValidation(offense.criminalActivities.model.attributes, isValid, errMsg);
        }
        return isValid;
    }
);

function propertyLossRuleIsValid(conditions, propertyStatuses, allowedCodes, state) {
    let isValid = true;
    if (conditions) {
        const nibrsCodeForAttributeIdAndCodeTypeCategory = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(
            state
        );
        isValid = every(propertyStatuses, (propertyStatus) => {
            return every(propertyStatus.statuses, (status) => {
                const code = nibrsCodeForAttributeIdAndCodeTypeCategory(
                    status.propertyStatusAttrId(),
                    CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name
                );
                return includes(allowedCodes, code);
            });
        });
    }
    return isValid;
}

// Action Items refer to https://docs.google.com/spreadsheets/d/12UOBhtspozLf7ah4W6WmD_OHevxERrRf-THFMHnIFMI/edit#gid=457587039

export const offenseAssaultHomicideCompletedRequired = addRuleId(
    SystemRuleEnum.OFFENSE_ASSAULT_HOMICIDE_COMPLETED_REQUIRED.name,
    (offenseCardData, errMsg) => {
        // Action Item 6
        // If NIBRS offense code attribute is Assault or Homicide (09A, 09B, 09C, 13A, 13B, 13C),
        // offense must be competed
        // TODO this should be handled in the UI (prefill 'Offense attempted / completed' field to C=Completed and disable)
        let isValid = true;
        const { offense } = offenseCardData;
        const relevantNibrsOffenseCodes = ['09A', '09B', '09C', '13A', '13B', '13C'];
        if (offense.nibrsCode() && includes(relevantNibrsOffenseCodes, offense.nibrsCode().code)) {
            isValid = offense.wasCompleted();
        }
        setKoModelValidation(offense.wasCompletedSelect, isValid, errMsg);
        return isValid;
    }
);

export const offenseJustifiableHomicideHateCrimeNone = addRuleId(
    SystemRuleEnum.OFFENSE_JUSTIFIABLE_HOMICIDE_HATE_CRIME_NONE.name,
    (offenseCardData) => {
        // Action Item 9
        // If offense code is Justifiable homicide (09C),
        // Suspected hate crime must be no
        // TODO this should be handled in the UI (prefill dropdown to "No" and disable)
        const { offense } = offenseCardData;
        const isValid =
            offense.nibrsCode() && offense.nibrsCode().code === '09C'
                ? offense.isSuspectedHateCrime() === false
                : true;
        // we don't set validation field error here so we don't
        // override the highlighting from OFFENSE_SUSPECTED_HATE_CRIME_REQUIRED
        return isValid;
    }
);

export const offenseAttemptedRequiresPropertyStatusNoneUnknown = addRuleId(
    SystemRuleEnum.OFFENSE_ATTEMPTED_REQUIRES_PROPERTY_STATUS_NONE_UNKNOWN.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 11
        // If a NIBRS offense code with the 'Requires Property' offense flag is 'attempted',
        // the 'type of property loss' (i.e. item status) can only be 1=none or 8=unknown

        // TODO this should be handled in the UI (only show allowed property statuses)
        const { offense, propertyStatuses } = offenseCardData;
        const condition = offense.isPropertyRequired() && offense.wasCompleted() === false;
        const allowedCodes = ['1', '8'];
        return propertyLossRuleIsValid(condition, propertyStatuses, allowedCodes, state);
    }
);

export const offenseStolenPropertyCompletedPropertyStatusNoneRecovered = addRuleId(
    SystemRuleEnum.OFFENSE_STOLEN_PROPERTY_COMPLETED_PROPERTY_STATUS_NONE_RECOVERED.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 12
        // Type of property loss can only be 1 = None or 5 = Recovered when offense code is 280 = Stolen Property Offenses
        // AND offense is completed (C)

        // TODO this should be handled in the UI (only show allowed property statuses)
        const { offense, propertyStatuses } = offenseCardData;
        const condition =
            offense.nibrsCode() &&
            offense.nibrsCode().code === '280' &&
            offense.wasCompleted() === true;
        const allowedCodes = ['1', '5'];
        return propertyLossRuleIsValid(condition, propertyStatuses, allowedCodes, state);
    }
);

export const offenseBneAbductionBriberyCompletedPropertyStatusNoneRecoveredStolenUnknown = addRuleId(
    SystemRuleEnum
        .OFFENSE_BNE_ABDUCTION_BRIBERY_COMPLETED_PROPERTY_STATUS_NONE_RECOVERED_STOLEN_UNKNOWN.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 13
        // Type of property loss can only be 1 = None, 5 = Recovered, 7 = Stolen, or 8 = Unknown
        // when Offense code is 100 = Kidnapping/Abduction, 220 = Burglary/Breaking & Entering or 510 = Bribery
        // AND offense is Completed (C).

        // TODO this should be handled in the UI (only show allowed property statuses)
        const { offense, propertyStatuses } = offenseCardData;
        const relevantNibrsOffenseCodes = ['100', '220', '510'];
        const condition =
            offense.nibrsCode() &&
            includes(relevantNibrsOffenseCodes, offense.nibrsCode().code) &&
            offense.wasCompleted() === true;
        const allowedCodes = ['1', '5', '7', '8'];
        return propertyLossRuleIsValid(condition, propertyStatuses, allowedCodes, state);
    }
);

export const offenseDrugsCompletedPropertyStatusNoneSeized = addRuleId(
    SystemRuleEnum.OFFENSE_DRUGS_COMPLETED_PROPERTY_STATUS_NONE_SEIZED.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 14
        // Type of property loss can only be 1 = None or 6 = Seized when Offense Code is 35A or 35B (drug violations)
        // AND offense is Completed (C)

        // TODO this should be handled in the UI (only show allowed property statuses)
        const { offense, propertyStatuses } = offenseCardData;
        const nibrsCode = offense.nibrsCode() ? offense.nibrsCode().code : undefined;
        const condition =
            (nibrsCode === '35A' || nibrsCode === '35B') && offense.wasCompleted() === true;
        const allowedCodes = ['1', '6'];
        return propertyLossRuleIsValid(condition, propertyStatuses, allowedCodes, state);
    }
);

export const offenseArsonCompletedPropertyStatusBurned = addRuleId(
    SystemRuleEnum.OFFENSE_ARSON_COMPLETED_PROPERTY_STATUS_BURNED.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 15
        // Type of property loss can only be 2 = Burned when
        // Offense Code is 200 = Arson AND offense is Completed (C).

        // TODO this should be handled in the UI (only show allowed property statuses)
        const { offense, propertyStatuses } = offenseCardData;
        const condition = isNibrsArson(offense.nibrsCode()) && offense.wasCompleted() === true;
        const allowedCodes = ['2'];
        return propertyLossRuleIsValid(condition, propertyStatuses, allowedCodes, state);
    }
);

export const offenseArsonBuildingInhabitedRequired = addRuleId(
    SystemRuleEnum.OFFENSE_ARSON_BUILDING_INHABITED_REQUIRED_IF_ARSON.name,
    (offenseCardData, errMsg) => {
        const { offense } = offenseCardData;
        const isValid =
            !isNibrsArson(offense.nibrsCode()) ||
            !isUndefinedOrNull(offense.arsonBuildingInhabitedSelect());
        setKoModelValidation(offense.arsonBuildingInhabitedSelect, isValid, errMsg);
        return isValid;
    }
);

export const offenseForgeryCompletedPropertyStatusForgedRecoveredSeized = addRuleId(
    SystemRuleEnum.OFFENSE_FORGERY_COMPLETED_PROPERTY_STATUS_FORGED_RECOVERED_SEIZED.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 16
        // Type of property loss can only be 3 = Counterfeited/Forged, 5 = Recovered (In Police Custody), or 6 = Seized
        // when Offense Code is 250 = Counterfeiting/Forgery AND offense is Completed (C)

        const { offense, propertyStatuses } = offenseCardData;
        const condition =
            offense.nibrsCode() &&
            offense.nibrsCode().code === '250' &&
            offense.wasCompleted() === true;
        const allowedCodes = ['3', '5', '6'];
        return propertyLossRuleIsValid(condition, propertyStatuses, allowedCodes, state);
    }
);

export const offenseDamageCompletedPropertyStatusDamaged = addRuleId(
    SystemRuleEnum.OFFENSE_DAMAGE_COMPLETED_PROPERTY_STATUS_DAMAGED.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 17 TODO-TEST
        // Type of property loss can only be 4 = Destroyed/Damaged/Vandalized
        // when Offense Code is 290 = Destruction/Dmage/Vandalism of Property AND offense is Completed (C).

        // TODO this should be handled in the UI (only show allowed property statuses)
        const { offense, propertyStatuses } = offenseCardData;
        const condition =
            offense.nibrsCode() &&
            offense.nibrsCode().code === '290' &&
            offense.wasCompleted() === true;
        const allowedCodes = ['4'];
        return propertyLossRuleIsValid(condition, propertyStatuses, allowedCodes, state);
    }
);

export const offenseTheftFraudCompletedPropertyStatusRecoveredStolen = addRuleId(
    SystemRuleEnum.OFFENSE_THEFT_FRAUD_COMPLETED_PROPERTY_STATUS_RECOVERED_STOLEN.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 18
        // Type of property loss can only be 5 = Recovered (inPoliceCustody) or 7 = Stolen
        // When Offense Code is one of the following AND offense is Completed (C): 120, 210, 23A-H, 240, 26A-G, 270.

        // TODO this should be handled in the UI (only show allowed property statuses)
        const { offense, propertyStatuses } = offenseCardData;
        const relevantNibrsOffenseCodes = [
            '120',
            '210',
            '23A',
            '23B',
            '23C',
            '23D',
            '23E',
            '23F',
            '23G',
            '23H',
            '240',
            '26A',
            '26B',
            '26C',
            '26D',
            '26E',
            '26F',
            '26G',
            '270',
        ];
        const condition =
            offense.nibrsCode() &&
            includes(relevantNibrsOffenseCodes, offense.nibrsCode().code) &&
            offense.wasCompleted() === true;
        const allowedCodes = ['5', '7'];
        return propertyLossRuleIsValid(condition, propertyStatuses, allowedCodes, state);
    }
);

export const offenseGamblingCompletedPropertyStatusSeized = addRuleId(
    SystemRuleEnum.OFFENSE_GAMBLING_COMPLETED_PROPERTY_STATUS_SEIZED.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 19
        // Type of property loss can only be 6 = Seized when Offense Code is 39A-D AND offense is completed (C)

        // TODO this should be handled in the UI (only show allowed property statuses)
        const { offense, propertyStatuses } = offenseCardData;
        const relevantNibrsOffenseCodes = ['39A', '39B', '39C', '39D'];
        const condition =
            offense.nibrsCode() &&
            includes(relevantNibrsOffenseCodes, offense.nibrsCode().code) &&
            offense.wasCompleted() === true;
        const allowedCodes = ['6'];
        return propertyLossRuleIsValid(condition, propertyStatuses, allowedCodes, state);
    }
);

export const offensePropertyLossDeclaredValueRequired = addRuleId(
    SystemRuleEnum.OFFENSE_PROPERTY_LOSS_DECLARED_VALUE_REQUIRED.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 21
        // If type of property loss is not 1 - none or 8 - unknown,
        // THEN user must enter Value of Property

        let allValid = true;
        const { propertyStatuses } = offenseCardData;
        const nibrsCodeForAttributeIdAndCodeTypeCategory = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(
            state
        );
        forEach(propertyStatuses, (propertyStatus) => {
            return forEach(propertyStatus.statuses, (status) => {
                const statusCode = nibrsCodeForAttributeIdAndCodeTypeCategory(
                    status.propertyStatusAttrId(),
                    CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name
                );
                if (!(statusCode === '1' || statusCode === '8')) {
                    const declaredValue = status.declaredValue();
                    const hasDeclaredValue =
                        !isUndefinedOrNull(declaredValue) &&
                        // cast value to a string, so that the numeric value
                        // of `0` passes
                        size(`${declaredValue}`) > 0;
                    const isValid = hasDeclaredValue || !!status.declaredValueUnknown();
                    // field configuration validation runs before system rules
                    // prevent passing system rule from overriding failed field config validation
                    if (status.declaredValue.isValid()) {
                        setKoModelValidation(status.declaredValue, isValid, errMsg);
                    }
                    if (!isValid) {
                        allValid = false;
                    }
                }
            });
        });
        return allValid;
    }
);

export const offenseItemCategoryDocumentsCreditCardsDeclaredValueZero = addRuleId(
    SystemRuleEnum.OFFENSE_ITEM_CATEGORY_DOCUMENTS_CREDIT_CARDS_DECLARED_VALUE_ZERO.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 26
        // Item value' must=0 when Property category is: 09 = Credit/Debit Cards; 22 = Nonnegotiable Instruments;
        // 48 = Documents/Personal or Business; 65 = Identity Documents; 66 = Identity - Intangible.

        let allValid = true;
        const { propertyStatuses } = offenseCardData;
        const propertyCategories = ['09', '22', '48', '65', '66'];
        const nibrsCodeForAttributeIdAndCodeTypeCategory = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(
            state
        );
        forEach(propertyStatuses, (propertyStatus) => {
            if (
                includes(
                    propertyCategories,
                    nibrsCodeForAttributeIdAndCodeTypeCategory(
                        propertyStatus.item.itemCategoryAttrId(),
                        CodeTypeCategoryEnum.NIBRS_PROPERTY_CATEGORY.name
                    )
                )
            ) {
                forEach(propertyStatus.statuses, (status) => {
                    const isValid = parseInt(status.declaredValue()) === 0;
                    // field configuration validation runs before system rules
                    // prevent passing system rule from overriding failed field config validation
                    if (status.declaredValue.isValid()) {
                        setKoModelValidation(status.declaredValue, isValid, errMsg);
                    }
                    if (!isValid) {
                        allValid = false;
                    }
                });
            }
        });
        return allValid;
    }
);

export const offenseItemCategoryVehicleQuantityOne = addRuleId(
    SystemRuleEnum.OFFENSE_ITEM_CATEGORY_VEHICLE_QUANTITY_ONE.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 28
        // If 'property category' is a motor vehicle (03, 05, 24, 28, 37), quantity must = 1; in other words, vehicles must always be itemized
        let allValid = true;
        const { propertyStatuses } = offenseCardData;
        const propertyCategories = ['03', '05', '24', '28', '37'];
        const nibrsCodeForAttributeIdAndCodeTypeCategory = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(
            state
        );
        forEach(propertyStatuses, (propertyStatus) => {
            if (
                includes(
                    propertyCategories,
                    nibrsCodeForAttributeIdAndCodeTypeCategory(
                        propertyStatus.item.itemCategoryAttrId(),
                        CodeTypeCategoryEnum.NIBRS_PROPERTY_CATEGORY.name
                    )
                )
            ) {
                forEach(propertyStatus.statuses, (status) => {
                    const isValid = parseInt(status.quantity()) === 1;
                    setKoModelValidation(status.quantity, isValid, errMsg);
                    if (!isValid) {
                        allValid = false;
                    }
                });
            }
        });
        return allValid;
    }
);

export const offenseMarijuanaOpiumHallucinogensNumberOfPlants = addRuleId(
    SystemRuleEnum.OFFENSE_MARIJUANA_OPIUM_HALLUCINOGENS_NUMBER_OF_PLANTS.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 29
        // 'Number of Plants' (NP) is only selectable for DrugCategory 'marijuana' (E), 'opium' (G), and 'other hallucinogens' (K).
        let allValid = true;
        const { propertyStatuses } = offenseCardData;
        const relevantDrugCategories = ['E', 'G', 'K'];
        const nibrsCodeForAttributeIdAndCodeTypeCategory = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(
            state
        );
        forEach(propertyStatuses, (propertyStatus) => {
            const nibrsDrugCategory = nibrsCodeForAttributeIdAndCodeTypeCategory(
                propertyStatus.item.itemCategoryAttrId(),
                CodeTypeCategoryEnum.NIBRS_DRUG_CATEGORY.name
            );
            if (!includes(relevantDrugCategories, nibrsDrugCategory)) {
                forEach(propertyStatus.statuses, (status) => {
                    const measurementCode = nibrsCodeForAttributeIdAndCodeTypeCategory(
                        status.measurementUnitsAttrId(),
                        CodeTypeCategoryEnum.NIBRS_DRUG_MEASUREMENT.name
                    );
                    const isValid = measurementCode !== 'NP';
                    setKoModelValidation(status.measurementUnitsAttrId, isValid, errMsg);
                    if (!isValid) {
                        allValid = false;
                    }
                });
            }
        });
        return allValid;
    }
);

// run on person panel (putting this rule in this file because it's more closely related to offense validation than person validation)
export const personRapeVictimSexMaleFemaleRequired = addRuleId(
    SystemRuleEnum.PERSON_RAPE_VICTIM_SEX_MALE_FEMALE_REQUIRED.name,
    (personPanelData, errMsg, state) => {
        // Action Item 36
        // When offense code is Rape (11a) or Statutory Rape (36b),
        // victim sex can only be M(Male) or F(Female); U is not allowed
        const { person, offenseNibrsCode } = personPanelData;
        if (offenseNibrsCode === '11A' || offenseNibrsCode === '36B') {
            const isValid =
                nibrsCodeForAttributeIdAndCodeTypeCategorySelector(state)(
                    person.sexAttrId(),
                    CodeTypeCategoryEnum.NIBRS_SEX.name
                ) !== 'U';
            setKoModelValidation(person.sexAttrId, isValid, errMsg);
            return isValid;
        }
        return true;
    }
);

// run on offense panel
export const offenseRapeVictimSexMaleFemaleRequired = addRuleId(
    SystemRuleEnum.OFFENSE_RAPE_VICTIM_SEX_MALE_FEMALE_REQUIRED.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 36
        // When offense code is Rape (11a) or Statutory Rape (36b),
        // victim sex can only be M(Male) or F(Female); U is not allowed
        const { offense, personVictimEntities } = offenseCardData;
        if (
            offense.nibrsCode() &&
            (offense.nibrsCode().code === '11A' || offense.nibrsCode().code === '36B')
        ) {
            const nibrsCodeForAttributeIdAndCodeTypeCategory = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(
                state
            );
            return every(personVictimEntities, (victim) => {
                const isValid =
                    nibrsCodeForAttributeIdAndCodeTypeCategory(
                        victim.sexAttrId(),
                        CodeTypeCategoryEnum.NIBRS_SEX.name
                    ) !== 'U';
                setKoModelValidation(victim.sexAttrId, isValid, errMsg);
                return isValid;
            });
        }
        return true;
    }
);

export const offenseCertainOffensesIsDomesticViolenceRequired = addRuleId(
    SystemRuleEnum.OFFENSE_CERTAIN_OFFENSES_IS_DOMESTIC_VIOLENCE_REQUIRED.name,
    (offenseCardData, errMsg) => {
        const { offense } = offenseCardData;
        let isValid = true;
        if (
            offense.nibrsCode() &&
            includes(nibrsCodes.allowsDomesticViolence, offense.nibrsCode().code) &&
            (isNull(offense.isDomesticViolenceSelect()) ||
                isUndefined(offense.isDomesticViolenceSelect()))
        ) {
            isValid = false;
        }
        setKoModelValidation(offense.isDomesticViolenceSelect, isValid, errMsg);
        return isValid;
    }
);

export const offenseKidnappingAbductionRequiresProperty = addRuleId(
    SystemRuleEnum.OFFENSE_KIDNAPPING_ABDUCTION_REQUIRES_PROPERTY.name,
    (offenseCardData) => {
        // Action Item 10
        // NIBRS code Kidnapping/Abduction (100) requires property.
        const { offense, propertyStatuses } = offenseCardData;
        return offense.nibrsCode() && offense.nibrsCode().code === '100'
            ? propertyStatuses.length > 0
            : true;
    }
);

export const offenseBriberyRequiresProperty = addRuleId(
    SystemRuleEnum.OFFENSE_BRIBERY_REQUIRES_PROPERTY.name,
    (offenseCardData) => {
        // Action Item 10
        // NIBRS code Bribery (510) requires property.
        const { offense, propertyStatuses } = offenseCardData;
        return offense.nibrsCode() && offense.nibrsCode().code === '510'
            ? propertyStatuses.length > 0
            : true;
    }
);

export const offenseBurglaryRequiresProperty = addRuleId(
    SystemRuleEnum.OFFENSE_BURGLARY_REQUIRES_PROPERTY.name,
    (offenseCardData, errMsg, state) => {
        // Action Item 10
        // NIBRS code Burglary (220) requires property.
        const { offense, propertyStatuses } = offenseCardData;
        return (
            isUcrDepartment(state) ||
            (isNibrsBurglary(offense.nibrsCode()) ? propertyStatuses.length > 0 : true)
        );
    }
);

export const offenseDateOrDateUnknownRequired = addRuleId(
    SystemRuleEnum.OFFENSE_DATE_OR_DATE_UNKNOWN_REQUIRED.name,
    ({ offense, state }) => {
        const isValid = offense.isOffenseDateUnknown() || !!offense.offenseDateUtc();
        const allowUnknownDate = isNibrsBurglary(offense.nibrsCode());
        const offenseDisplayName = formatFieldByNameSelector(state)(DISPLAY_ONLY_OFFENSE);
        setKoModelValidation(offense.offenseDateUtc, isValid);
        return (
            isValid ||
            validationStrings.panel.offenseDateOrDateUnknownRequired({
                offenseDisplayName,
                canBeUnknown: allowUnknownDate,
            })
        );
    }
);

export const offenseDateRequiredForLawEnforcementAsVictimNibrs = addRuleId(
    SystemRuleEnum.OFFENSE_DATE_REQUIRED_FOR_LAW_ENFORCEMENT_AS_VICTIM_NIBRS.name,
    ({ offense, personVictimLinks }) => {
        const relevantNibrsOffenseCodes = [
            '13A', // Aggravated Assault
            '13B', // Simple Assault
            '13C', // Intimidation
            '09A', // Murder & Non-negligent Manslaughter
        ];
        let isValid = true;

        if (
            offense.nibrsCode() &&
            includes(relevantNibrsOffenseCodes, offense.nibrsCode().code) &&
            personVictimLinks &&
            some(personVictimLinks, (link) => {
                return link.isLeoka();
            }) &&
            offense.isOffenseDateUnknown()
        ) {
            isValid = false;
        }

        if (!offense.offenseDateUtc()) {
            setKoModelValidation(offense.offenseDateUtc, isValid);
        }

        setKoModelValidation(offense.offenseDateKnownSelect, isValid);
        return isValid;
    }
);

import React from 'react';
import { Fieldset as MFTFieldset } from 'markformythree';
import { AttributeTypeEnum } from '@mark43/rms-api';

import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import FormRow from '../../../../../core/forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { HelpTextProps } from '../../../../../../legacy-redux/components/core/HelpText';

export const VehicleLabelsFieldset = ({
    helpTextCollisionBoundary,
}: {
    helpTextCollisionBoundary: HelpTextProps['collisionBoundary'];
}) => {
    return (
        <MFTFieldset path="attributeLinks">
            <ArbiterMFTAttributeSelect
                attributeType={AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name}
                path="vehicleCautionAttrIds"
                multiple={true}
            />
            <FormRow hasIndent>
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="vehicleCautionDescription"
                    length="lg"
                />
            </FormRow>
        </MFTFieldset>
    );
};

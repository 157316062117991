import { AttributeTypeEnum } from '@mark43/rms-api';

import React from 'react';
import { Fieldset as MFTFieldset } from 'markformythree';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import FormRow from '../../../../../core/forms/components/FormRow';

import withItemCategoryFilterOptions from './withItemCategoryFilterOptions';

function OtherItemFieldset({ helpTextCollisionBoundary, itemCategoryFilterOptions }) {
    return (
        <SidePanelSection>
            <MFTFieldset path="otherItem">
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="itemCategoryAttrId"
                    attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
                    filterOptions={itemCategoryFilterOptions}
                    length="lg"
                />
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="description"
                />
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="primaryColorAttrId"
                        attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="secondaryColorAttrId"
                        attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="size"
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="serialNumber"
                        length="md"
                    />

                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="itemMake"
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="itemModel"
                        length="md"
                    />
                </FormRow>
            </MFTFieldset>
        </SidePanelSection>
    );
}

/**
 * Fieldset in the item entry v2 form that appears only when the item type is other item. It
 *   includes fields from the ItemProfile model.
 */
export default withItemCategoryFilterOptions(globalAttributes.itemType.item)(OtherItemFieldset);

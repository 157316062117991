import { Attachment } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'exportedAttachments';
const exportedAttachmentsModule = createGroupedModule<Attachment>({
    type: NEXUS_STATE_PROP,
    key: 'entityId',
});

// SELECTORS
export const exportedAttachmentsByReleaseIdSelector = exportedAttachmentsModule.selectors.entitiesByKeySelector;

// REDUCER
export default exportedAttachmentsModule.reducerConfig;

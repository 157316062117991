import { VisibleFilter } from 'arc';
import { BriefingSearchSqlQuery, BriefingStateEnum } from '@mark43/rms-api';
import { formatFieldValueRRF } from '~/client-common/helpers/formHelpers';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';

import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { getDateRangeOptions } from '~/client-common/core/dates/utils/dateRangeHelpers';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';

import { mapQueryToVisibleFilters } from '../../../core/filters/utils';
import { BriefingFilterOption } from '../../components/dashboard/BriefingsFilters';
import { BRIEFING_QUERY_MAPPER } from './briefingsDashboardFilters';

const { ATTRIBUTE, ROLE, USER } = fieldTypeClientEnum;

const dateRangeOptions = getDateRangeOptions({
    withinLastPeriodOptions: ['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D'],
    toDatePeriodOptions: ['P1M', 'P1Y'],
    includeCustomFilterOption: true,
});

export function formatElasticQueryToFilters(
    elasticQuery: BriefingSearchSqlQuery | undefined,
    formatFieldValue: typeof formatFieldValueRRF,
    boundSelectors: {
        allRoleFormatsByRoleId: ReturnType<typeof allRoleFormatsByRoleIdSelector>;
        dateTimeFormatter: ReturnType<typeof currentDepartmentDateFormatterSelector>;
    }
) {
    if (!elasticQuery) {
        return [];
    }

    const { allRoleFormatsByRoleId, dateTimeFormatter } = boundSelectors;

    const mapper = mapQueryToVisibleFilters(elasticQuery, { dateTimeFormatter, formatFieldValue });

    const visibleFilters: VisibleFilter<BriefingFilterOption>[] = [];

    if (elasticQuery.subdivisionIds.length) {
        visibleFilters.push(
            mapper.getMultiSelectFilter(BRIEFING_QUERY_MAPPER.subdivisionIds, ATTRIBUTE)
        );
    }

    if (elasticQuery.roleIds.length) {
        visibleFilters.push(
            mapper.getCustomMultiSelectFilter(BRIEFING_QUERY_MAPPER.roleIds, (id) => ({
                id,
                value: String(id),
                label:
                    allRoleFormatsByRoleId(Number(id)).default ??
                    formatFieldValue(id, { type: ROLE }),
            }))
        );
    }

    if (elasticQuery.personnelUnitIds.length) {
        visibleFilters.push(
            mapper.getMultiSelectFilter(BRIEFING_QUERY_MAPPER.personnelUnitIds, ATTRIBUTE)
        );
    }

    if (elasticQuery.briefingStateIds.length) {
        visibleFilters.push(
            mapper.getEnumSelectFilter(BRIEFING_QUERY_MAPPER.briefingStateIds, BriefingStateEnum)
        );
    }

    if (elasticQuery.creatorUserIds.length) {
        visibleFilters.push(
            mapper.getMultiSelectFilter(BRIEFING_QUERY_MAPPER.creatorUserIds, USER)
        );
    }

    if (elasticQuery.title) {
        visibleFilters.push(mapper.getTextFilter(BRIEFING_QUERY_MAPPER.title));
    }

    if (elasticQuery.id) {
        visibleFilters.push(mapper.getTextFilter(BRIEFING_QUERY_MAPPER.id));
    }

    if (Object.keys(elasticQuery.postedDateRangeQuery).length) {
        visibleFilters.push(
            mapper.getDateRangeFilter(BRIEFING_QUERY_MAPPER.postedDateRangeQuery, dateRangeOptions)
        );
    }

    if (Object.keys(elasticQuery.expiryDateRangeQuery).length) {
        visibleFilters.push(
            mapper.getDateRangeFilter(BRIEFING_QUERY_MAPPER.expiryDateRangeQuery, dateRangeOptions)
        );
    }

    return visibleFilters;
}

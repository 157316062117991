import { PersonGangTracking } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { first } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'personGangTrackings';

const personGangTrackingsModule = createNormalizedModule<PersonGangTracking>({
    type: NEXUS_STATE_PROP,
});

export const storePersonGangTrackings = personGangTrackingsModule.actionCreators.storeEntities;
export const replacePersonGangTrackingsWhere =
    personGangTrackingsModule.actionCreators.replaceEntitiesWhere;

const personGangTrackingsWhereSelector = personGangTrackingsModule.selectors.entitiesWhereSelector;

export const personGangTrackingForPersonIdSelector = createSelector(
    personGangTrackingsWhereSelector,
    (personGangTrackingsWhere) => (personId: number) =>
        first(personGangTrackingsWhere({ personProfileId: personId }))
);

export const personGangTrackingMergeDataForPersonIdSelector = createSelector(
    personGangTrackingForPersonIdSelector,
    (personGangTrackingForPersonId) => (personId: number) => {
        const personGangTracking = personGangTrackingForPersonId(personId);
        if (!personGangTracking) {
            return {};
        }
        const suspectedGangAffiliationAttrId =
            personGangTracking.gangSubgroupAttrId || personGangTracking.gangNameAttrId;
        const expirationDateUtc = personGangTracking.expirationDateUtc;
        return {
            suspectedGangAffiliationAttrId,
            expirationDateUtc,
        };
    }
);

export default personGangTrackingsModule.reducerConfig;

import { DispositionStatusEnum } from '@mark43/evidence-api';
import React from 'react';
import _ from 'lodash';
import { formatDispositionStatus } from '~/client-common/core/domain/item-evidence-states/utils/formatDisposition';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

const dispositionStatusOptions = _(DispositionStatusEnum)
    .map((dispositionStatus) => ({
        display: formatDispositionStatus(dispositionStatus.name),
        value: dispositionStatus.name,
    }))
    .sortBy('display')
    .value();

function DispositionStatusSelect(props) {
    return <Select options={dispositionStatusOptions} {...props} />;
}

export const RRFDispositionStatusSelect = connectRRFInput(DispositionStatusSelect);

import { map } from 'lodash';
import { createSelector } from 'reselect';
import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatChainEventTypeByIdSelector } from '~/client-common/core/domain/chain-event-types/state/ui';
import { formatFacilityByIdSelector } from '~/client-common/core/domain/facilities/state/ui';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { formatDispositionStatus } from '~/client-common/core/domain/item-evidence-states/utils/formatDisposition';
import { formatStorageLocationNames } from '~/client-common/core/domain/storage-locations/utils/storageLocationHelpers';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '~/client-common/helpers/viewModelHelpers';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';

export function createEvidenceItemsResultsSelector(baseSelector) {
    return createSelector(
        formatAttributeByIdSelector,
        formatSubdivisionAttrIdsSelector,
        baseSelector,
        formatChainEventTypeByIdSelector,
        formatFacilityByIdSelector,
        formatFieldByNameSelector,
        formatMiniUserByIdSelector,
        (
            formatAttributeById,
            formatSubdivisionAttrIds,
            currentResults,
            formatChainEventTypeById,
            formatFacilityById,
            formatFieldByName,
            formatMiniUserById
        ) =>
            map(
                currentResults,
                buildViewModel({
                    recursive: false,
                    mappers: [
                        allSingleAttributeValuesMapper,
                        ({
                            currentChainEvent,
                            reportingEventNumber,
                            itemTypeAttrId,
                            dispositionStatus,
                            sequenceNumber,
                        }) => {
                            const {
                                eventTypeId,
                                facilityId,
                                storageLocationNames = [],
                                receivedByEntityId,
                            } = currentChainEvent[0];

                            return {
                                eventType: formatChainEventTypeById(eventTypeId),
                                facility: formatFacilityById(facilityId),
                                storageLocationNames: formatStorageLocationNames(
                                    storageLocationNames
                                ),
                                reportingEventNumber: `${formatFieldByName(
                                    REPORT_REPORTING_EVENT_NUMBER
                                )}: ${reportingEventNumber}`,
                                isFirearm: itemTypeAttrId === globalAttributes.itemType.firearm,
                                currentDispositionStatus: formatDispositionStatus(
                                    dispositionStatus
                                ),
                                sequenceNumber,
                                receivedByEntityName: formatMiniUserById(receivedByEntityId, {
                                    firstNameAsInitial: true,
                                }),
                            };
                        },
                    ],
                    helpers: {
                        formatAttributeById,
                        formatSubdivisionAttrIds,
                    },
                })
            )
    );
}

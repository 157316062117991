import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import styled from 'styled-components';
import { OperationTypeEnum } from '@mark43/rms-api';
import { last } from 'lodash';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { isExpired } from '~/client-common/core/dates/utils/dateHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import stringsConfig from '~/client-common/core/strings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import storageLocationAdminForm from '../state/forms/storageLocationAdminForm';
import {
    newStorageFormIsOpenSelector,
    storageFormStorageLocationViewModelSelector,
    closeStorageForm,
    submitStorageForm,
    storageFormFacilityIdSelector,
    setParentStorageLocation,
} from '../state/ui';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import AdminListStatus from '../../core/components/AdminDateStatus';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import __Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Row from '../../../core/components/Row';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFTextArea } from '../../../core/forms/components/TextArea';
import { RRFStorageLocationSelect } from '../../../core/forms/components/selects/StorageLocationSelect';
import testIds from '../../../../core/testIds';
import { actualParentStorageSelected } from '../utils/evidenceFacilitiesAdminHelpers';

const { withRRFFieldValues } = reactReduxFormHelpers;
const { ACTIVE, EXPIRED } = attributeStatuses;
const strings = stringsConfig.components.admin.evidenceFacilities.StorageLocationAdminForm;

const context = {
    name: boxEnum.STORAGE_ADMIN_SIDE_PANEL,
};

const _Button = styled(__Button)`
    margin-top: 19px;
    min-width: 0;
`;

const ResetParentButton = compose(
    withRRFFieldValues({
        formParentStorageLocationId: 'parentStorageLocationId',
    }),
    withPropsOnChange(
        ['parentStorageLocationId', 'formParentStorageLocationId', 'parentLocationDisabled'],
        ({ parentStorageLocationId, formParentStorageLocationId, parentLocationDisabled }) => {
            const isActual = actualParentStorageSelected(
                parentStorageLocationId,
                formParentStorageLocationId
            );
            return {
                disabled: parentLocationDisabled || isActual,
            };
        }
    )
)(_Button);

function StorageLocationAdminForm({
    isNew,
    storageLocationViewModel = {},
    onSubmit,
    closeStorageForm,
    storageFormFacilityId,
    onResetParent,
}) {
    const storageIsExpired = isExpired(storageLocationViewModel.expiredDateUtc);
    const {
        facilityId,
        parentStorageLocationIds,
        isInHighValueContainer,
    } = storageLocationViewModel;
    const facilityIdFilter = storageFormFacilityId || facilityId;
    const parentLocationDisabled = isNew || storageIsExpired;

    return (
        <SidePanel
            title={isNew ? strings.titles.add : strings.titles.edit}
            onSave={onSubmit}
            onCancel={closeStorageForm}
            context={context}
            width={593}
        >
            <ReactReduxForm {...storageLocationAdminForm}>
                <Row testId={fields.STORAGE_LOCATION_VIEW_DISPLAY_VALUE}>
                    <RRFText label={strings.labels.displayValue} width={345} path="displayValue" />
                </Row>
                <Row>
                    <RRFStorageLocationSelect
                        path="parentStorageLocationId"
                        label={strings.labels.parentStorageLocation}
                        width={345}
                        disabled={parentLocationDisabled}
                        facilityIdFilter={facilityIdFilter}
                        includeFacilityOption={true}
                        requiredPermissions={[OperationTypeEnum.MANAGE.name]}
                    />
                    <ResetParentButton
                        parentStorageLocationId={last(parentStorageLocationIds)}
                        className={buttonTypes.SECONDARY}
                        onClick={onResetParent}
                        parentLocationDisabled={parentLocationDisabled}
                    >
                        {strings.labels.reset}
                    </ResetParentButton>
                </Row>
                <Row testId={testIds.EVIDENCE_FACILITIES_ADMIN_STORAGE_LOCATION_IS_TEMPORARY}>
                    <RRFCheckbox label={strings.labels.isTemporary} path="isTemporary" />
                </Row>
                <Row>
                    <RRFTextArea
                        label={strings.labels.description}
                        width={550}
                        rows={4}
                        path="description"
                    />
                </Row>
                <Row>
                    <RRFDatePicker
                        label={strings.labels.expiredDateUtc}
                        includeTime={true}
                        path="expiredDateUtc"
                    />
                </Row>
                <FeatureFlagged flag="EVIDENCE_AUTO_LOCATION_UPDATE_ENABLED">
                    <Row>
                        <RRFCheckbox
                            label={strings.labels.isHighValueLocation}
                            disabled={isInHighValueContainer}
                            path="isHighValue"
                            helpText={isInHighValueContainer ? strings.highValueTooltip : undefined}
                        />
                    </Row>
                </FeatureFlagged>
                {!isNew && (
                    <AdminListStatus status={storageIsExpired ? EXPIRED : ACTIVE} dateTime={true} />
                )}
            </ReactReduxForm>
        </SidePanel>
    );
}

const mapStateToProps = createStructuredSelector({
    isNew: newStorageFormIsOpenSelector,
    storageLocationViewModel: storageFormStorageLocationViewModelSelector,
    storageFormFacilityId: storageFormFacilityIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => dispatch(submitStorageForm()),
    closeStorageForm: () => dispatch(closeStorageForm()),
    onResetParent: (storageLocationId) => dispatch(setParentStorageLocation(storageLocationId)),
});

/**
 * Side panel form for creating/editing a storage location.
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onResetParent({ storageLocationViewModel, onResetParent }) {
            const { parentStorageLocationIds } = storageLocationViewModel;
            return () => onResetParent(last(parentStorageLocationIds));
        },
    })
)(StorageLocationAdminForm);

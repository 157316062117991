import keyMirror from 'keymirror';

const reportSidebarSortRelatedRecordsEnum = keyMirror({
    ALPHABETICAL: null,
    MODIFIED_DATE: null,
    CREATED_DATE: null,
    DUE_DATE: null,
    STATUS: null,
});

export type ReportSidebarSortRelatedRecordsEnumType = typeof reportSidebarSortRelatedRecordsEnum[keyof typeof reportSidebarSortRelatedRecordsEnum];

export default reportSidebarSortRelatedRecordsEnum;

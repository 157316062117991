import { EntityTypeEnum } from '@mark43/rms-api';
import { noop } from 'lodash';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import TaskTable, { columnKeys } from '../../core/components/TaskTable';
import testIds from '../../../../core/testIds';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import { currentCaseIdSelector } from '../../../cases/core/state/ui';
import { sortedCurrentCaseTasksSelector } from '../../../cases/task-list/state/ui';
import { openTaskSidePanelForEntity } from '../../core/state/ui';
import { retrieveCaseTasks } from '../state/ui';
import { refreshTasksDashboard } from '../../dashboard/state/ui';

import TaskDashboardSearchResults from '../../dashboard/components/TaskDashboardSearchResults';
import { TaskDashboardSearchResultsParents } from '../../core/config';

const strings = componentStrings.tasks.cases.CaseTasks;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const TableTableWrapper = styled.div`
    padding-top: 10px;
`;

const CreateNewTaskButton = styled(Button)`
    margin-right: 0;
`;

const columnConfigurations = [
    {
        width: 125,
        key: columnKeys.dueDateUtc,
    },
    {
        width: 240,
        key: columnKeys.statusAttrId,
    },
];

const taskEnhancementsColumnConfigurations = [
    {
        width: 133,
        key: columnKeys.title,
    },
    {
        width: 140,
        key: columnKeys.dueDateUtc,
    },
    {
        width: 109,
        key: columnKeys.assigneeRoleId,
    },
    {
        width: 109,
        key: columnKeys.typeAttrId,
    },
    {
        width: 109,
        key: columnKeys.priorityAttrId,
    },
];

const CaseTasks = ({ showCreateTaskButton = true }) => {
    const currentCaseId = useSelector(currentCaseIdSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const taskEnhancementsEnabled = applicationSettings['RMS_TASK_ENHANCEMENTS_ENABLED'];
    const caseTasks = useSelector(sortedCurrentCaseTasksSelector);
    const hiddenColumnKeys = taskEnhancementsEnabled ? ['source'] : [];
    const dispatch = useDispatch();

    const openTaskSidePanel = useCallback(() => {
        dispatch(
            openTaskSidePanelForEntity([
                { entityId: currentCaseId, entityType: EntityTypeEnum.CASE.name },
            ])
        );
    }, [currentCaseId, dispatch]);

    return (
        <Wrapper>
            {showCreateTaskButton && (
                <Header>
                    <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
                        <CreateNewTaskButton
                            className={buttonTypes.PRIMARY}
                            iconLeft={<Icon type={iconTypes.ADD} />}
                            onClick={openTaskSidePanel}
                            testId={testIds.NEW_TASK_BUTTON}
                        >
                            {strings.newTask}
                        </CreateNewTaskButton>
                    </OnlyWithAbility>
                </Header>
            )}
            <TableTableWrapper>
                <FeatureFlagged
                    flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                    fallback={
                        <TaskTable
                            columnConfigurations={columnConfigurations}
                            onSave={() => dispatch(retrieveCaseTasks(currentCaseId))}
                            onDeleteTask={noop}
                            tasks={caseTasks}
                            hiddenColumnKeys={hiddenColumnKeys}
                        />
                    }
                >
                    <TaskDashboardSearchResults
                        columnConfigurations={taskEnhancementsColumnConfigurations}
                        hiddenColumnKeys={hiddenColumnKeys}
                        onSave={() => dispatch(refreshTasksDashboard())}
                        parentComponent={TaskDashboardSearchResultsParents.CASE_TASKS}
                    />
                </FeatureFlagged>
            </TableTableWrapper>
        </Wrapper>
    );
};

export default CaseTasks;

import React from 'react';
import styled from 'styled-components';
import { compose, withHandlers, withState } from 'recompose';

import Icon, { iconTypes } from '../Icon';

const TableHeaderButtonText = styled.span`
    float: left;
`;

const TableHeaderButtonIcon = styled(Icon)`
    margin-left: 3px;
    margin-right: 0;
`;

const _TableHeaderButton = compose(
    withState('isHovered', 'setIsHovered', false),
    withHandlers({
        setIsHoveredTrue({ setIsHovered }) {
            return () => setIsHovered(true);
        },
        setIsHoveredFalse({ setIsHovered }) {
            return () => setIsHovered(false);
        },
        onClick({ onClick, sortKey }) {
            return () => onClick(sortKey);
        },
    })
)(function _TableHeaderButton({
    className,
    children,
    isHovered,
    setIsHoveredTrue,
    setIsHoveredFalse,
    activeSortKey,
    activeSortType,
    sortKey,
    onClick,
}) {
    return (
        <div
            className={className}
            onMouseEnter={setIsHoveredTrue}
            onMouseLeave={setIsHoveredFalse}
            onClick={onClick}
        >
            <TableHeaderButtonText>{children}</TableHeaderButtonText>
            {isHovered && sortKey !== activeSortKey && (
                <TableHeaderButtonIcon type={iconTypes.ARROWUP} />
            )}
            {sortKey === activeSortKey && (
                <TableHeaderButtonIcon
                    fontSize="20px"
                    type={activeSortType === 'ASCENDING' ? iconTypes.ARROWDOWN : iconTypes.ARROWUP}
                />
            )}
        </div>
    );
});

const TableHeaderButton = styled(_TableHeaderButton)`
    display: inline-block;
    padding: 5px 10px;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-sm);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.cobaltBlue};
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }

    i::before {
        float: none;
    }
`;

/**
 * This is a table header that will highlight on hover and sort when you click on it.
 */
export default TableHeaderButton;

import classNames from 'classnames';
import { ProductModuleEnum, EntityTypeEnum } from '@mark43/rms-api';
import { TableColumn as Column } from 'components-mark43';
import _, { chain, map, size, get, includes, some, difference, without } from 'lodash';
import React from 'react';
import { compose, withProps } from 'recompose';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import * as fieldNames from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { propertyStatusesByItemProfileIdSelector } from '~/client-common/core/domain/property-statuses/state/data';
import { vehiclesEnabledSelector } from '~/client-common/core/domain/evidence-department-config/state/data';
import getImportEventResource from '~/client-common/core/domain/import-events/resources/importEventResource';
import { custodialPropertySummaryReportDefinitionSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { formatReportDefinition } from '~/client-common/helpers/reportDefinitionsHelpers';
import { itemFacilityLinksSelector } from '~/client-common/core/domain/item-facility-links/state/data';
import {
    itemHasChainEventsSelector,
    itemCoCStatusIsInPoliceCustodySelector,
} from '~/client-common/core/domain/chain-events/state/data';
import { mergePropertyStatuses } from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { useItemTypeNames } from '~/client-common/core/fields/hooks/useItemTypeNames';
import { requestRecentLocationsForEntity } from '../../../../../legacy-redux/actions/recentEntitiesActions';
import { AnalyticsPropertyEnum } from '../../../../analytics/constants/analyticsEnum';
import { AnalyticsContextProviderWithAdditionalData } from '../../../../core/context/AnalyticsContext';
import { openLabelPrintingModal } from '../../../../evidence/label-printing/state/ui';
import { storeEvidenceHydratedItemsLite } from '../../../../evidence/core/utils/evidenceItemsHelpers';
import { pollForCustodialPropertySummaryReport } from '../../state/data/submission';
import { useAbilitySelector } from '../../../../core/current-user/hooks/useAbilitySelector';
import _SidePanel from '../../../../../legacy-redux/components/core/SidePanel';
import _Checkbox, { INDETERMINATE } from '../../../../core/forms/components/checkboxes/Checkbox';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../../legacy-redux/components/core/Icon';
import { Tooltip } from '../../../../core/components/tooltip';
import {
    saveBoxStart,
    saveBoxHalt,
    saveBoxFailure,
    storeBoxErrorMessages,
} from '../../../../../legacy-redux/actions/boxActions';
import {
    openItemSidePanel,
    closeManageItemsSidePanel,
    manageItemsSidePanelIsVehicleSelector,
    manageItemsSidePanelLimitToOneSelector,
    manageItemsSidePanelOwnerIdSelector,
    manageItemsSidePanelOwnerTypeSelector,
} from '../../state/ui';
import testIds from '../../../../../core/testIds';
import _ManageItemsItemList from './ManageItemsItemList';

const strings = componentStrings.reports.core.ManageItemsSidePanel;
const context = { name: boxEnum.MANAGE_ITEMS_SIDE_PANEL };
const ADD_VEHICLE_BUTTON_CLASSNAME = 'add-vehicle-button';

const SidePanel = styled(_SidePanel)`
    .react-side-panel-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    && .react-side-panel-content-wrapper {
        padding: 0;
    }
`;

const Header = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    box-shadow: 0 3px 3px -3px ${(props) => props.theme.colors.darkGrey};
`;

const HeaderRow = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 26px;
    font-size: var(--arc-fontSizes-sm);
`;

const Wrapper = styled(Column)`
    width: 100%;
`;

const AddButton = styled(Button)`
    box-sizing: border-box;
    width: 130px;

    &:last-child {
        margin-right: 0;
    }
`;

const ManageItemsItemList = styled(_ManageItemsItemList)`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    font-size: var(--arc-fontSizes-sm);
    overflow: auto;
    padding: 16px 0;
`;

const Checkbox = styled(_Checkbox)`
    margin-bottom: 12px;
`;

export const SelectAllCheckbox = ({
    selectableItemProfileIds,
    selectedItemProfileIds,
    setSelectedItemProfileIds,
    showLabel = true,
}) => {
    const checkboxesAllSelected = selectedItemProfileIds.length === selectableItemProfileIds.length;
    const checkboxValue = checkboxesAllSelected
        ? true
        : selectedItemProfileIds.length > 0
        ? INDETERMINATE
        : false;

    const onChange = React.useCallback(() => {
        setSelectedItemProfileIds((selectedItemProfileIds) => {
            if (selectedItemProfileIds.length !== selectableItemProfileIds.length) {
                return selectableItemProfileIds;
            } else {
                return [];
            }
        });
    }, [selectableItemProfileIds, setSelectedItemProfileIds]);

    return (
        <Checkbox
            label={showLabel ? strings.selectAll : ''}
            onChange={onChange}
            value={checkboxValue}
            disabled={selectableItemProfileIds.length === 0}
            testId={testIds.MANAGE_ITEMS_SIDE_PANEL_CHECKBOX_ALL}
        />
    );
};

const CancelButtonContainer = styled.div`
    float: right;
`;

// only item profiles satisfying either condition may be selected for evidence label printing:
// 1. In Police Custody = Yes and
// Drop-Off Location or infieldTransferByOfficerId is filled in
// 2. the item is already in evidence with a Checked In Temp chain event
function itemProfileIsSelectable({
    itemProfile: { id, masterItemId },
    propertyStatusesByItemProfileId,
    itemFacilityLinks,
    itemHasChainEvents,
    itemCoCStatusIsInPoliceCustody,
}) {
    const { isInPoliceCustody, infieldTransferByOfficerId } = mergePropertyStatuses(
        propertyStatusesByItemProfileId(id)
    );
    const hasItemFacilityLink = some(itemFacilityLinks, {
        itemProfileId: id,
    });
    const isLocationOrInfieldTransfer =
        // Is infield transfer with an officer, OR is drop-off in a location.
        // No need to check custodyType here, because it will never be a case
        // that custodyType is a location but only infieldTransferByOfficerId is filled, vice versa.
        // If this happened, it indicates a much bigger problem.
        !!infieldTransferByOfficerId || hasItemFacilityLink;

    return (
        (isInPoliceCustody && isLocationOrInfieldTransfer) ||
        (itemHasChainEvents(masterItemId) && !itemCoCStatusIsInPoliceCustody(masterItemId))
    );
}

/**
 * This hook contains all the evidence state and logic for this side panel. When `evidenceEnabled` is false, none of the
 * other properties should apply.
 */
export function useEvidence({ isVehicle, itemProfiles, ownerId, closeSidePanel }) {
    const dispatch = useDispatch();
    const [selectedItemProfileIds, setSelectedItemProfileIds] = React.useState([]);
    const [importEventFailed, setImportEventFailed] = React.useState(false);
    const isProductModuleActive = useSelector(isProductModuleActiveSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const vehiclesEnabled = useSelector(vehiclesEnabledSelector);
    const propertyStatusesByItemProfileId = useSelector(propertyStatusesByItemProfileIdSelector);
    const itemFacilityLinks = useSelector(itemFacilityLinksSelector);
    const itemHasChainEvents = useSelector(itemHasChainEventsSelector);
    const itemCoCStatusIsInPoliceCustody = useSelector(itemCoCStatusIsInPoliceCustodySelector);
    const hasAbility = useAbilitySelector(abilitiesEnum.EVIDENCE.EDIT_REPORTING);
    const custodialPropertySummaryReportDefinition = useSelector(
        custodialPropertySummaryReportDefinitionSelector
    );
    const custodialPropertySummaryReportDefinitionName = formatReportDefinition(
        custodialPropertySummaryReportDefinition
    );

    const evidenceEnabled =
        itemProfiles.length > 0 &&
        applicationSettings.RMS_EVIDENCE_LABELS_FIRST_ENABLED &&
        hasAbility &&
        isProductModuleActive(ProductModuleEnum.EVIDENCE.name) &&
        (!isVehicle || vehiclesEnabled);

    const closeSidePanelAndResetState = React.useCallback(() => {
        closeSidePanel();
        setSelectedItemProfileIds([]);
        setImportEventFailed(false);
    }, [closeSidePanel, setSelectedItemProfileIds, setImportEventFailed]);

    const selectableItemProfileIds = React.useMemo(
        () =>
            evidenceEnabled
                ? _(itemProfiles)
                      .filter((itemProfile) =>
                          itemProfileIsSelectable({
                              itemProfile,
                              propertyStatusesByItemProfileId,
                              itemFacilityLinks,
                              itemHasChainEvents,
                              itemCoCStatusIsInPoliceCustody,
                          })
                      )
                      .map('id')
                      .value()
                : [],
        [
            evidenceEnabled,
            itemProfiles,
            propertyStatusesByItemProfileId,
            itemFacilityLinks,
            itemHasChainEvents,
            itemCoCStatusIsInPoliceCustody,
        ]
    );

    // automatically unselect any items that have been updated
    React.useEffect(() => {
        const idsToUncheck = difference(selectedItemProfileIds, selectableItemProfileIds);
        if (idsToUncheck.length > 0) {
            setSelectedItemProfileIds(without(selectedItemProfileIds, ...idsToUncheck));
        }
    }, [selectedItemProfileIds, selectableItemProfileIds]);

    const runImportEventOnAllItems = React.useCallback(() => {
        dispatch(storeBoxErrorMessages(context, []));
        return getImportEventResource()
            .importItemsToReport({
                reportId: ownerId,
                hideLoadingBar: true,
            })
            .catch((err) => {
                dispatch(
                    saveBoxFailure(
                        context,
                        `${strings.importEventErrorMessage(
                            custodialPropertySummaryReportDefinitionName
                        )} ${err.message}`
                    )
                );
                setImportEventFailed(true);
                throw err;
            });
    }, [ownerId, custodialPropertySummaryReportDefinitionName, setImportEventFailed, dispatch]);
    const runImportEventOnSelectedItems = React.useCallback(() => {
        dispatch(saveBoxStart(context));
        dispatch(storeBoxErrorMessages(context, []));
        return getImportEventResource()
            .importItemsToReport({
                reportId: ownerId,
                itemProfileIds: selectedItemProfileIds,
                hideLoadingBar: true,
            })
            .catch((err) => {
                dispatch(
                    saveBoxFailure(
                        context,
                        `${strings.importEventErrorMessage(
                            custodialPropertySummaryReportDefinitionName
                        )} ${err.message}`
                    )
                );
                setImportEventFailed(true);
                throw err;
            });
    }, [
        ownerId,
        selectedItemProfileIds,
        custodialPropertySummaryReportDefinitionName,
        setImportEventFailed,
        dispatch,
    ]);
    const onSuccessForSaveProgress = React.useCallback(
        (evidenceHydratedItems) => {
            dispatch(storeEvidenceHydratedItemsLite(evidenceHydratedItems));
            dispatch(pollForCustodialPropertySummaryReport(ownerId));
            closeSidePanelAndResetState();
        },
        [closeSidePanelAndResetState, ownerId, dispatch]
    );
    const onSuccessForPrintLabels = React.useCallback(
        (evidenceHydratedItems) => {
            dispatch(storeEvidenceHydratedItemsLite(evidenceHydratedItems));
            dispatch(
                openLabelPrintingModal({
                    masterItemIds: _(itemProfiles)
                        .filter(({ id }) => includes(selectedItemProfileIds, id))
                        .map('masterItemId')
                        .value(),
                })
            );
            dispatch(saveBoxHalt(context));
        },
        [itemProfiles, selectedItemProfileIds, dispatch]
    );
    const {
        callResource: onSaveProgress,
        loading: { isLoading: saveProgressIsLoading },
    } = useResourceDeferred(runImportEventOnAllItems, onSuccessForSaveProgress);
    const {
        callResource: onPrintLabels,
        loading: { isLoading: printLabelsIsLoading },
    } = useResourceDeferred(runImportEventOnSelectedItems, onSuccessForPrintLabels);

    const itemsAreSelected = evidenceEnabled && selectedItemProfileIds.length > 0;
    const noItemsAreSelected = evidenceEnabled && selectedItemProfileIds.length === 0;

    const renderPrintLabelsButton = React.useCallback(
        (cancelButton) => (
            <CancelButtonContainer>
                {noItemsAreSelected ? (
                    <Tooltip
                        side="top"
                        hasButtonOffset
                        content={
                            noItemsAreSelected ? strings.printLabelsDisabledTooltipText : undefined
                        }
                        collisionBoundary={document.querySelector('.mark43-react-side-panel')}
                    >
                        <span>{cancelButton}</span>
                    </Tooltip>
                ) : (
                    cancelButton
                )}
            </CancelButtonContainer>
        ),
        [noItemsAreSelected]
    );

    const renderFooter = React.useCallback(
        (footer) => (
            <AnalyticsContextProviderWithAdditionalData
                analyticsKeyToAdd={AnalyticsPropertyEnum.TOTAL_ITEM_COUNT}
                analyticsValueToAdd={itemProfiles.length}
            >
                <AnalyticsContextProviderWithAdditionalData
                    analyticsKeyToAdd={AnalyticsPropertyEnum.SELECTABLE_ITEM_COUNT}
                    analyticsValueToAdd={selectableItemProfileIds.length}
                >
                    <AnalyticsContextProviderWithAdditionalData
                        analyticsKeyToAdd={AnalyticsPropertyEnum.SELECTED_ITEM_COUNT}
                        analyticsValueToAdd={selectedItemProfileIds.length}
                    >
                        {footer}
                    </AnalyticsContextProviderWithAdditionalData>
                </AnalyticsContextProviderWithAdditionalData>
            </AnalyticsContextProviderWithAdditionalData>
        ),
        [itemProfiles, selectableItemProfileIds, selectedItemProfileIds]
    );

    const isLoading = saveProgressIsLoading || printLabelsIsLoading;

    const renderLoadingBar = React.useCallback(
        ({ LoadingBar, saving }) => {
            return (
                <LoadingBar visible={saving} maxHeight={18}>
                    {strings.runningImportEvent(custodialPropertySummaryReportDefinitionName)}
                </LoadingBar>
            );
        },
        [custodialPropertySummaryReportDefinitionName]
    );

    return {
        evidenceEnabled,
        selectableItemProfileIds,
        selectedItemProfileIds,
        setSelectedItemProfileIds,
        onSaveProgress: importEventFailed
            ? closeSidePanelAndResetState
            : selectableItemProfileIds.length > 0
            ? onSaveProgress
            : undefined,
        onPrintLabels: itemsAreSelected ? onPrintLabels : undefined,
        saveProgressDisabled: evidenceEnabled ? isLoading : undefined,
        printLabelsDisabled: evidenceEnabled ? noItemsAreSelected || isLoading : undefined,
        renderFooter: evidenceEnabled ? renderFooter : undefined,
        renderPrintLabelsButton: evidenceEnabled ? renderPrintLabelsButton : undefined,
        renderLoadingBar: selectableItemProfileIds.length > 0 ? renderLoadingBar : undefined,
        importEventFailed,
    };
}

const mapStateToProps = createStructuredSelector({
    isVehicle: manageItemsSidePanelIsVehicleSelector,
    itemProfilesInReport: itemProfilesInReportSelector,
    limitToOne: manageItemsSidePanelLimitToOneSelector,
    ownerId: manageItemsSidePanelOwnerIdSelector,
    ownerType: manageItemsSidePanelOwnerTypeSelector,
    reportById: reportByIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onClickAddButton: (ownerId, itemTypeAttrId, reportingEventNumber, isFormHidden) => {
        dispatch(
            openItemSidePanel({
                itemTypeAttrId,
                isVehicle: itemTypeAttrId === globalAttributes.itemType.vehicle,
                ownerId,
                ownerType: EntityTypeEnum.REPORT.name,
                operation: itemSidePanelOperationEnum.CREATE,
                reportingEventNumber,
                isFormHidden,
            })
        );
    },
});

const globalItemAttributeIdToTestId = {
    [globalAttributes.itemType.firearm]: testIds.ADD_FIREARM_BUTTON,
    [globalAttributes.itemType.drugs]: testIds.ADD_DRUGS_BUTTON,
    [globalAttributes.itemType.item]: testIds.ADD_ITEM_BUTTON,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withProps(
        ({
            isVehicle,
            itemProfilesInReport,
            limitToOne,
            onClickAddButton,
            ownerId,
            reportById,
        }) => {
            const reportingEventNumber = get(reportById(ownerId), 'reportingEventNumber');
            const itemProfiles = chain(ownerId)
                .thru(itemProfilesInReport)
                .filter(
                    isVehicle
                        ? ({ itemTypeAttrId }) =>
                              itemTypeAttrId === globalAttributes.itemType.vehicle
                        : ({ itemTypeAttrId }) =>
                              itemTypeAttrId !== globalAttributes.itemType.vehicle
                )
                .value();

            const disableItemLinking = limitToOne && size(itemProfiles) > 0;

            const itemTypeNames = useItemTypeNames();
            const vehicleLabel = itemTypeNames[globalAttributes.itemType.vehicle];

            const itemTypeButtons = isVehicle ? (
                <AddButton
                    className={classNames(ADD_VEHICLE_BUTTON_CLASSNAME, buttonTypes.SECONDARY)}
                    disabled={disableItemLinking}
                    onClick={() =>
                        onClickAddButton(
                            ownerId,
                            globalAttributes.itemType.vehicle,
                            reportingEventNumber,
                            true
                        )
                    }
                    iconLeft={<Icon type={iconTypes.ADD} />}
                    testDisplay={vehicleLabel}
                >
                    {vehicleLabel}
                </AddButton>
            ) : (
                map(
                    [
                        globalAttributes.itemType.firearm,
                        globalAttributes.itemType.drugs,
                        globalAttributes.itemType.item,
                    ],
                    (itemTypeAttrId) => {
                        const label = itemTypeNames[itemTypeAttrId] || '';
                        return (
                            <AddButton
                                className={classNames(buttonTypes.SECONDARY)}
                                key={itemTypeAttrId}
                                onClick={() =>
                                    onClickAddButton(
                                        ownerId,
                                        itemTypeAttrId,
                                        reportingEventNumber,
                                        false
                                    )
                                }
                                iconLeft={<Icon type={iconTypes.ADD} />}
                                testDisplay={label}
                                testId={globalItemAttributeIdToTestId[itemTypeAttrId]}
                            >
                                {label}
                            </AddButton>
                        );
                    }
                )
            );

            const title = isVehicle
                ? strings.addEditVehicleTitle(!limitToOne)
                : strings.addEditPropertyTitle;

            return {
                context,
                disableItemLinking,
                itemProfiles,
                itemTypeButtons,
                title,
            };
        }
    )
)(
    ({
        context,
        disableItemLinking,
        isVehicle,
        itemProfiles,
        itemTypeButtons,
        limitToOne,
        title,
        ownerId,
        ownerType,
    }) => {
        const dispatch = useDispatch();
        const closeSidePanel = React.useCallback(() => {
            dispatch(
                requestRecentLocationsForEntity(
                    map(itemProfiles, (ip) => ip.id),
                    EntityTypeEnum.ITEM_PROFILE.name
                )
            );
            dispatch(closeManageItemsSidePanel(ownerId, isVehicle));
        }, [ownerId, isVehicle, dispatch, itemProfiles]);
        const {
            evidenceEnabled,
            selectableItemProfileIds,
            selectedItemProfileIds,
            setSelectedItemProfileIds,
            onSaveProgress: evidenceOnSaveProgress,
            onPrintLabels,
            saveProgressDisabled,
            printLabelsDisabled,
            renderFooter,
            renderPrintLabelsButton,
            renderLoadingBar,
            importEventFailed,
        } = useEvidence({ isVehicle, itemProfiles, ownerId, closeSidePanel });

        const formatFieldByName = useSelector(formatFieldByNameSelector);

        const toolTipContainer =
            isVehicle && disableItemLinking ? ADD_VEHICLE_BUTTON_CLASSNAME : null;
        const buttons = toolTipContainer ? (
            <Tooltip
                collisionBoundary={document.querySelector('.mark43-react-side-panel')}
                side="top"
                content={strings.limitOneVehicleForThisReportType}
            >
                <div>{itemTypeButtons}</div>
            </Tooltip>
        ) : (
            itemTypeButtons
        );

        return (
            <SidePanel
                className={toolTipContainer}
                context={context}
                onSave={
                    evidenceEnabled && evidenceOnSaveProgress
                        ? evidenceOnSaveProgress
                        : closeSidePanel
                }
                saveText={
                    evidenceEnabled && importEventFailed
                        ? strings.close
                        : formatFieldByName(fieldNames.DISPLAY_ONLY_ITEM_SIDE_PANEL_CLOSE_BUTTON)
                }
                onCancel={onPrintLabels}
                cancelText={evidenceEnabled ? strings.printLabels : ''}
                renderFooter={renderFooter}
                renderCancelButton={renderPrintLabelsButton}
                cancelDisabled={printLabelsDisabled}
                saveDisabled={saveProgressDisabled}
                renderLoadingBar={renderLoadingBar}
                title={title}
                testId={testIds.MANAGE_ITEMS_SIDE_PANEL}
            >
                <Wrapper>
                    <Header>
                        <HeaderRow data-test-id={testIds.ITEM_TYPE_BUTTONS}>{buttons}</HeaderRow>
                        {evidenceEnabled && selectableItemProfileIds.length > 0 ? (
                            <HeaderRow>
                                <SelectAllCheckbox
                                    selectableItemProfileIds={selectableItemProfileIds}
                                    selectedItemProfileIds={selectedItemProfileIds}
                                    setSelectedItemProfileIds={setSelectedItemProfileIds}
                                />
                            </HeaderRow>
                        ) : undefined}
                    </Header>
                    <ManageItemsItemList
                        disableItemLinking={disableItemLinking}
                        itemProfiles={itemProfiles}
                        isVehicle={isVehicle}
                        limitToOne={limitToOne}
                        ownerType={ownerType}
                        ownerId={ownerId}
                        showCheckboxes={evidenceEnabled}
                        selectableItemProfileIds={selectableItemProfileIds}
                        selectedItemProfileIds={selectedItemProfileIds}
                        setSelectedItemProfileIds={setSelectedItemProfileIds}
                    />
                </Wrapper>
            </SidePanel>
        );
    }
);

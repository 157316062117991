import { AttributeTypeEnum } from '@mark43/rms-api';
import { filter, isUndefined } from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure } from 'recompose';
import classNames from 'classnames';
import styled from 'styled-components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import {
    DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL,
    DISPLAY_ONLY_VEHICLE_YEAR_LABEL,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import Row from '../../../core/components/Row';
import { CollapsibleFieldset, RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNFieldsets } from '../../../core/forms/components/NFieldsets';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFVehicleYearRangeSlider } from '../../../core/forms/components/Slider';
import { RRFVehicleMakeSelect } from '../../../core/forms/components/selects/VehicleMakeSelect';
import { RRFVehicleModelSelect } from '../../../core/forms/components/selects/VehicleModelSelect';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import ItemIdentifiersFieldset from './ItemIdentifiersFieldset';
import SearchCapabilityIcon, { SEARCH_CAPABILITY_TYPE_ENUM } from './SearchCapabilityIcon';

const strings = componentStrings.search.fieldsets.CautionsFieldset;

const { withRRFActions, WithFieldState } = reactReduxFormHelpers;

const IconWrapper = styled.span`
    margin-left: 10px;
`;

const StyledItemIdentifiersFieldset = styled(ItemIdentifiersFieldset)`
    max-width: 30rem;
`;

function VehicleFieldsetRow1({ fuzzyMatchingEnabled, expandCollapseButton }) {
    return (
        <Row>
            <RRFVehicleMakeSelect
                multiple={true}
                width={183}
                path="vehicleMakeIds"
                includeOtherOption={true}
            />
            <RRFVehicleModelSelect
                multiple={true}
                width={183}
                path="vehicleModelIds"
                grouped={true}
                includeOtherOption={true}
            />
            <FeatureFlagged
                flag="RMS_WILDCARD_SEARCH_ENABLED"
                fallback={
                    <RRFText
                        width={210}
                        className={classNames({
                            'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                        })}
                        path="tag"
                    />
                }
            >
                <RRFText
                    extraLabelContent={
                        <IconWrapper>
                            <SearchCapabilityIcon
                                variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name}
                            />
                        </IconWrapper>
                    }
                    labelClassName=""
                    path="tag"
                    width={210}
                />
            </FeatureFlagged>
            {expandCollapseButton}
        </Row>
    );
}

const VehicleFieldsetRow2 = withRRFActions(({ fuzzyMatchingEnabled, formActions }) => {
    const fieldDisplayNames = useFields([DISPLAY_ONLY_VEHICLE_YEAR_LABEL]);
    return (
        <Row>
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.STATE.name}
                multiple={true}
                includeExpired={true}
                width={183}
                path="registrationStateAttrIds"
            />
            <RRFText
                width={183}
                className={classNames({
                    'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                })}
                extraLabelContent={
                    <IconWrapper>
                        <SearchCapabilityIcon variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name} />
                    </IconWrapper>
                }
                labelClassName=""
                path="vinNumber"
            />
            <RRFText
                width={148}
                maxLength={4}
                path="yearOfManufacture"
                onChange={() => {
                    // when a year is selected, clear the year range fields
                    formActions.reset('minYearOfManufacture');
                    formActions.reset('maxYearOfManufacture');
                }}
            />
            <RRFVehicleYearRangeSlider
                label={fieldDisplayNames.DISPLAY_ONLY_VEHICLE_YEAR_LABEL}
                width={165}
                onChange={({ rangeStart, rangeEnd }) => {
                    // when a year range is selected, clear the year field
                    if (!isUndefined(rangeStart) || !isUndefined(rangeEnd)) {
                        formActions.reset('yearOfManufacture');
                    }
                }}
            />
        </Row>
    );
});

const VehicleFieldsetRow3 = connect(
    createStructuredSelector({
        parentAttributeIdByAttributeId: parentAttributeIdByAttributeIdSelector,
    })
)(function VehicleFieldsetRow3({ fuzzyMatchingEnabled, parentAttributeIdByAttributeId }) {
    return (
        <Row>
            <RRFText
                width={332}
                className={classNames({
                    'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                })}
                extraLabelContent={
                    <IconWrapper>
                        <SearchCapabilityIcon variant={SEARCH_CAPABILITY_TYPE_ENUM.BOOLEAN.name} />
                    </IconWrapper>
                }
                labelClassName=""
                path="description"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
                multiple={true}
                includeExpired={true}
                filterOptions={(options) =>
                    filter(options, ({ value }) => {
                        // check both parent and grandparent attribute, because some ITEM_CATEGORY
                        // attributes can have child ITEM_CATEGORY attributes, see CHD-2850
                        return (
                            parentAttributeIdByAttributeId(value) ===
                                globalAttributes.itemType.vehicle ||
                            parentAttributeIdByAttributeId(
                                parentAttributeIdByAttributeId(value)
                            ) === globalAttributes.itemType.vehicle
                        );
                    })
                }
                width={345}
                path="itemCategoryAttrIds"
            />
        </Row>
    );
});

function VehicleFieldsetRow4() {
    return (
        <Row>
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.VEHICLE_BODY_STYLE.name}
                multiple={true}
                includeExpired={true}
                width={345}
                path="bodyStyleAttrIds"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                multiple={true}
                includeExpired={true}
                width={183}
                path="primaryColorAttrIds"
            />
        </Row>
    );
}

const VehicleFieldsetRow5 = withRRFActions(
    ({ includePropertyStatusAttrIds, isWide = false, formActions }) => {
        const { RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED } = useSelector(
            applicationSettingsSelector
        );
        const activeWithinRangeLabel = useFields(DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL)[
            DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL
        ];

        if (!includePropertyStatusAttrIds) {
            return <div />;
        }

        return (
            <Row style={{ display: 'flex', alignItems: 'baseline' }}>
                <RRFAttributeSelect
                    attributeType={AttributeTypeEnum.PROPERTY_LOSS.name}
                    multiple={true}
                    grouped={true}
                    showSelectedGroup={false}
                    includeExpired={true}
                    width={RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED && !isWide ? 183 : 248}
                    path="propertyStatusAttrIds"
                />

                <FeatureFlagged
                    flag="RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <FeatureFlagged flag="RMS_VEHICLE_CAUTIONS_ENABLED">
                            <RRFAttributeSelect
                                attributeType={AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name}
                                multiple={true}
                                includeExpired={true}
                                width={320}
                                path="vehicleLabelAttrIds"
                            />
                        </FeatureFlagged>
                    }
                >
                    <WithFieldState path="cautions">
                        {({ fieldModel }) => {
                            const hasCautionAttrIdsSelected = Boolean(
                                fieldModel?.cautionAttrIds?.length
                            );
                            const hasActiveWithinRangeSelected = Boolean(
                                fieldModel?.activeWithinRange
                            );
                            return (
                                <>
                                    <RRFAttributeSelect
                                        attributeType={
                                            AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name
                                        }
                                        multiple={true}
                                        includeExpired={true}
                                        width={isWide ? 200 : 183}
                                        path="cautions.cautionAttrIds"
                                        onChange={(cautionAttrIds) => {
                                            if (cautionAttrIds.length) {
                                                return;
                                            }
                                            formActions.reset(`cautions.activeWithinRange`);
                                            formActions.reset(`cautions.includeInactive`);
                                        }}
                                    />

                                    <RRFDateRangePicker
                                        label={activeWithinRangeLabel}
                                        width={isWide ? 200 : 183}
                                        withinLastPeriodOptions={['PT24H', 'PT48H', 'P7D', 'P28D']}
                                        toDatePeriodOptions={['P1M', 'P1Y']}
                                        paths={{
                                            withinLastPeriod:
                                                'cautions.activeWithinRange.withinLastPeriod',
                                            toDatePeriod: 'cautions.activeWithinRange.toDatePeriod',
                                            startDateUtc: 'cautions.activeWithinRange.startDateUtc',
                                            endDateUtc: 'cautions.activeWithinRange.endDateUtc',
                                        }}
                                        disabled={!hasCautionAttrIdsSelected}
                                        onChange={(dateRange) => {
                                            if (Object.keys(dateRange).length) {
                                                formActions.reset(`cautions.includeInactive`);
                                                return;
                                            }

                                            formActions.reset(`cautions.activeWithinRange`);
                                        }}
                                    />

                                    <RRFCheckbox
                                        path="cautions.includeInactive"
                                        helpText={strings.includeInactiveHelperText}
                                        disabled={
                                            !hasCautionAttrIdsSelected ||
                                            hasActiveWithinRangeSelected
                                        }
                                    />
                                </>
                            );
                        }}
                    </WithFieldState>
                </FeatureFlagged>
            </Row>
        );
    }
);

function VehicleFieldsetRowMakeModelDescription({ fuzzyMatchingEnabled }) {
    return (
        <Row>
            <RRFText
                width={183}
                className={classNames({ 'fuzzy-matching-enabled': fuzzyMatchingEnabled })}
                path="vehicleMakeOther"
            />
            <RRFText
                width={183}
                className={classNames({ 'fuzzy-matching-enabled': fuzzyMatchingEnabled })}
                path="vehicleModelOther"
            />
        </Row>
    );
}

/**
 * Fieldset for a single vehicle.
 * @param {Object}  props
 * @param {Object}  [props.fuzzyMatchingEnabled]
 * @param {boolean} [props.includePropertyStatusAttrIds=false]
 */
export const VehicleFieldset = pure(function VehicleFieldset({
    fuzzyMatchingEnabled,
    includePropertyStatusAttrIds = false,
    ...otherProps
}) {
    return (
        <RRFFieldset path="vehicle" {...otherProps}>
            <VehicleFieldsetRow1 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <VehicleFieldsetRowMakeModelDescription fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <VehicleFieldsetRow2 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <VehicleFieldsetRow3 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <VehicleFieldsetRow4 />
            <VehicleFieldsetRow5
                includePropertyStatusAttrIds={includePropertyStatusAttrIds}
                isWide
            />
            <StyledItemIdentifiersFieldset
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                showHeader={false}
            />
        </RRFFieldset>
    );
});

/**
 * Collapsible fieldset for a single vehicle.
 * @param {Object}  props
 * @param {boolean} [props.fuzzyMatchingEnabled]
 */
function CollapsibleVehicleFieldset({
    fuzzyMatchingEnabled,
    includePropertyStatusAttrIds,
    ...otherProps
}) {
    return (
        <CollapsibleFieldset
            expandedChildren={
                <div>
                    <VehicleFieldsetRowMakeModelDescription
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    />
                    <VehicleFieldsetRow2 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                    <VehicleFieldsetRow3 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                    <VehicleFieldsetRow4 />
                    <VehicleFieldsetRow5
                        includePropertyStatusAttrIds={includePropertyStatusAttrIds}
                    />
                    <StyledItemIdentifiersFieldset
                        highlightOnFocus={false}
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                        showHeader={false}
                    />
                </div>
            }
            {...otherProps}
        >
            {(expandCollapseButton) => (
                <VehicleFieldsetRow1
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    expandCollapseButton={expandCollapseButton}
                />
            )}
        </CollapsibleFieldset>
    );
}

/**
 * N vehicle fieldsets, each of which is collapsible.
 * @param {Object}  props
 * @param {boolean} [props.fuzzyMatchingEnabled]
 * @param {boolean} [props.includePropertyStatusAttrIds=false]
 */
export default pure(function VehiclesFieldset({
    fuzzyMatchingEnabled,
    includePropertyStatusAttrIds = false,
    ...otherProps
}) {
    return (
        <RRFNFieldsets path="vehicles" automaticallyIncludeDeleteButton={false} {...otherProps}>
            {(fieldsetProps) => (
                <CollapsibleVehicleFieldset
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    includePropertyStatusAttrIds={includePropertyStatusAttrIds}
                    {...fieldsetProps}
                />
            )}
        </RRFNFieldsets>
    );
});

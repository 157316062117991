import * as React from 'react';
import { CoreModelEnum, SearchableConfiguredPropertyOptionView } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { RRFText } from '../../../../core/forms/components/Text';
import { RRFUserSelect } from '../../../../core/forms/components/selects/UserSelect';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { NumericInput } from './NumericInput';
import { DateInput } from './DateInput';
import { ConfiguredEntityInstanceOptionSelect } from './ConfiguredEntityInstanceOptionSelect';
import { RRFFormActions } from './types';

const strings = componentStrings.search.AdvancedSearchReportsForm.dragon;

export const ConfiguredEntityPropertyInput = reactReduxFormHelpers.withRRFActions(
    function ConfiguredEntityPropertyInput({
        valueType,
        associatedForeignKeyCoreModelKeyName,
        foreignKeyConfiguredEntityKeyName,
        configuredPropertyKeyName,
        formActions,
    }: Pick<
        SearchableConfiguredPropertyOptionView,
        | 'valueType'
        | 'associatedForeignKeyCoreModelKeyName'
        | 'foreignKeyConfiguredEntityKeyName'
        | 'configuredPropertyKeyName'
    > &
        RRFFormActions): JSX.Element | null {
        const path = 'configuredEntityPropertyInputValue';
        const { change } = formActions;
        const isInitialMount = React.useRef(true);

        // In some parts of our form, users can switch between different inputs based on a previous selection.
        // If they made a selection via the previously shown input and it is targeting the same
        // form path as the next one, then the value of this form path has to be cleared out when the form component unmounts
        // as the value won't make sense in the context of the newly mounted input.
        React.useEffect(() => {
            if (!isInitialMount.current) {
                change(path, undefined);
            }
            isInitialMount.current = false;
        }, [configuredPropertyKeyName, change, path]);

        switch (valueType) {
            case 'STRING': {
                return <RRFText label={strings.inputFields.text.label} path={path} />;
            }
            case 'DATETIME': {
                return <DateInput path={path} />;
                break;
            }
            case 'DECIMAL':
            case 'BIGINT': {
                return (
                    <NumericInput
                        path={path}
                        mode={valueType === 'DECIMAL' ? 'NUMBER' : 'INTEGER'}
                    />
                );
            }
            case 'BOOLEAN':
            case 'ID': {
                if (associatedForeignKeyCoreModelKeyName === CoreModelEnum.USER_PROFILE.keyName) {
                    return (
                        <RRFUserSelect
                            path={path}
                            label={strings.inputFields.id.label}
                            length="md"
                            multiple={false}
                            effectiveDate={null}
                        />
                    );
                }
                if (foreignKeyConfiguredEntityKeyName) {
                    return (
                        <ConfiguredEntityInstanceOptionSelect
                            path={path}
                            label={strings.inputFields.id.label}
                            configuredEntityTypeKeyName={foreignKeyConfiguredEntityKeyName}
                        />
                    );
                }

                return null;
            }
            default: {
                return null;
            }
        }
    }
);

// a little function to help us with reordering the result
// Basically stole this from `react-beautiful-dnd`'s example here:
// https://codesandbox.io/s/k260nyxq9v
export function reOrder<T>(list: T[], startIndex: number, endIndex: number) {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

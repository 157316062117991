import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { itemProfileByIdSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import getWarrantsResource from '~/client-common/core/domain/warrants/resources/warrantsResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import clipsResource from '../../../legacy-redux/resources/clipsResource';
import { useFormGetter } from '../../core/forms/hooks/useFormGetter';
import { Button } from '../../core/components/Button';
import { openConfirmationBar } from '../../core/confirmation-bar/state/ui';
import { useOverlayStore } from '../../core/overlays/hooks/useOverlayStore';

import { clearErrorMessage, ClipsCurrentReportData, setErrorMessage } from '../state/ui';
import { shapeDataForClipsReportSubmission } from '../helpers';
import { ClipsFormData } from '../state/forms/ClipsReportForm';
import testIds, { TestId } from '../../../core/testIds';
import { logWarning } from '../../../core/logging';
import { getMachineNameFromMetadata } from '../../dex/utils';

import ClipsSendingModal from './ClipsSendingModal';

const StyledClipsFooter = styled.div`
    position: relative;
    width: 100%;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    padding: 21px 32px;
    border-top: 1px solid ${(props) => props.theme.colors.black};
    filter: drop-shadow(4px 0px 10px rgba(0, 0, 0, 0.08))
        drop-shadow(-4px 0px 10px rgba(0, 0, 0, 0.08))
        drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.08));
    z-index: 1;
`;

const strings = componentStrings.clips.ClipsFooter;
const banner = componentStrings.clips.ClipsFooter.banner;
const errors = componentStrings.clips.ClipsFooter.errors;
interface ClipsFooterProps {
    navigateTo: string;
    entities: ClipsCurrentReportData;
    setError?: (errorMessage: string) => void;
    testId?: TestId;
}

type UseSendHandlerProps = {
    reportId: number;
    entities: ClipsCurrentReportData;
    onRedirect?: () => void;
};

const useSendHandler = ({ reportId, entities, onRedirect = () => {} }: UseSendHandlerProps) => {
    const dispatch = useDispatch();
    const { getForm } = useFormGetter();
    const overlayStore = useOverlayStore();
    const itemProfileById = useSelector(itemProfileByIdSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);

    const sendClipsReportPrefill = useCallback(() => {
        const form = getForm(formClientEnum.CLIPS_REPORT_PREFILL_FORM);

        if (!form) {
            return Promise.reject(new Error(errors.nothingSelected));
        }

        return form?.submit().then(() => {
            const lastFourOfRen = entities.report?.reportingEventNumber
                ? entities.report.reportingEventNumber.slice(-4)
                : '';
            const formData = form.get() as ClipsFormData;

            const clipsReportPrefillData = shapeDataForClipsReportSubmission(
                formData,
                reportId,
                itemProfileById,
                lastFourOfRen
            );

            const { firearms, vehicles, items, persons } = clipsReportPrefillData;

            if (isEmpty(firearms) && isEmpty(vehicles) && isEmpty(items) && isEmpty(persons)) {
                dispatch(setErrorMessage(errors.nothingSelected));
                throw new Error(errors.nothingSelected);
            }
            const port = applicationSettings.CAD_DESKTOP_INTEGRATION_PORT;

            overlayStore.open(overlayIdEnum.CLIPS_PREFILL_SENDING_MODAL);

            return (
                getWarrantsResource()
                    .getDesktopMetadata(port)
                    // we may not have a machineName if we're in mock mode
                    // let the submitClipsReport call below handle that
                    .catch((error: Error) => {
                        logWarning('getDesktopMetadata failed', { error, port });
                    })
                    .then((data: undefined | { machineName: string | undefined }) => {
                        try {
                            const deviceName = getMachineNameFromMetadata({ data, port });
                            return clipsResource.submitClipsReport({
                                ...clipsReportPrefillData,
                                deviceName,
                            });
                        } catch {
                            // error is logged inside getMachineNameFromMetadata
                            // make call without a deviceName in case of mock mode
                            return clipsResource.submitClipsReport({
                                ...clipsReportPrefillData,
                            });
                        }
                    })
                    .then(() => {
                        dispatch(
                            openConfirmationBar({
                                message: banner.success,
                                isSuccess: true,
                            })
                        );
                    })
                    .catch((err: Error) => {
                        dispatch(
                            openConfirmationBar({
                                message: err?.message ? err.message : banner.error,
                                isError: true,
                            })
                        );
                    })
                    .finally(() => {
                        overlayStore.close(overlayIdEnum.CLIPS_PREFILL_SENDING_MODAL);
                        onRedirect();
                    })
            );
        });
    }, [
        getForm,
        entities,
        reportId,
        itemProfileById,
        applicationSettings.CAD_DESKTOP_INTEGRATION_PORT,
        overlayStore,
        dispatch,
        onRedirect,
    ]);

    const { callResource: handleSend } = useResourceDeferred(sendClipsReportPrefill);

    return handleSend;
};

const ClipsFooter = ({
    navigateTo,
    entities,
    router,
    params,
}: ClipsFooterProps & WithRouterProps) => {
    const dispatch = useDispatch();

    const handleSend = useSendHandler({
        reportId: parseInt(params.reportId),
        entities,
        onRedirect: () => router.push(navigateTo),
    });

    const handleCancel = useCallback(() => {
        dispatch(clearErrorMessage());
        router.goBack();
    }, [router, dispatch]);

    return (
        <StyledClipsFooter data-test-id={testIds.CLIPS_FOOTER}>
            <Button variant="solid" onClick={handleSend}>
                {strings.send}
            </Button>
            <Button variant="ghost" onClick={handleCancel}>
                {strings.cancel}
            </Button>
            <ClipsSendingModal />
        </StyledClipsFooter>
    );
};

export default withRouter(ClipsFooter);

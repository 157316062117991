import { createFormConfiguration } from 'markformythree';

import {
    EVENT_DETAIL_RESPONDING_OFFICER_ID,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';

export const getFormConfiguration = () =>
    createFormConfiguration({
        reportingEventNumber: {
            fieldName: REPORT_REPORTING_EVENT_NUMBER,
        },
        respondingOfficerIds: {
            fieldName: EVENT_DETAIL_RESPONDING_OFFICER_ID,
        },
        // appear in UI, but have hardcoded labels and are not associated with any rules/etc.
        startDateUtc: {},
        endDateUtc: {},
    });

import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { RestrictedAccessWarning } from './ElasticResultRow';

const strings = componentStrings.core.Table;

const RestrictedAccessChildrenWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    display: block;
    margin-left: ${(props) => props.marginLeft || 0};
    margin-bottom: ${(props) => props.marginBottom || 0};
`;

export default function ElasticModelWrapper({ entity, isOwnerDetail, children }) {
    const hasReadAccess = canRead(entity.permissionSet);
    const marginLeft = isOwnerDetail ? '10px' : '0px';
    const marginTop = isOwnerDetail ? '-10px' : '0px';
    const marginBottom = isOwnerDetail ? '10px' : '0px';
    const childrenToRender = children({ entity, hasReadAccess });
    return hasReadAccess ? (
        <div>{childrenToRender}</div>
    ) : (
        <div>
            <RestrictedAccessWarning as="div" marginLeft={marginLeft} marginTop={marginTop}>
                {strings.restrictedAccess}
            </RestrictedAccessWarning>
            <RestrictedAccessChildrenWrapper marginLeft={marginLeft} marginBottom={marginBottom}>
                {childrenToRender}
            </RestrictedAccessChildrenWrapper>
        </div>
    );
}

import React, { PropsWithChildren } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { FormControlBase, FormControlBaseProps } from 'arc';

import testIds from '../../../../core/testIds';
import { HelpTextProps } from '../../../../legacy-redux/components/core/HelpText';
import { InputStyles } from '../helpers/inputStyles';
import FormElementLabel from './FormElementLabel';

interface FormElementInterface {
    className?: string;
    contentWidth?: string | number;
    error?: string | boolean;
    extraLabelContent?: React.ReactNode;
    fieldName?: string;
    fieldNameAlternate?: string;
    forceShowError?: boolean;
    helpText?: string;
    helpTextCollisionBoundary?: HelpTextProps['collisionBoundary'];
    inputStyle?: keyof typeof InputStyles;
    isRequired?: boolean;
    label?: React.ReactNode;
    labelClassName?: string;
    labelMargin?: boolean;
    style?: React.CSSProperties;
    touched?: boolean;
    value?: unknown;
}

export type FormElementWidthOrLengthProps =
    | { length?: FormControlBaseProps['length']; width?: never }
    | { width?: string | number; length?: never };

export type FormElementProps = FormElementInterface & FormElementWidthOrLengthProps;

const FormElement = ({
    children,
    className,
    contentWidth,
    error,
    extraLabelContent,
    fieldName,
    fieldNameAlternate,
    forceShowError,
    helpText,
    helpTextCollisionBoundary,
    inputStyle,
    isRequired,
    label,
    labelClassName = 'mark43-form-row-label',
    labelMargin,
    style,
    touched,
    width,
    length,
}: PropsWithChildren<FormElementProps>) => {
    const formElementStyle = {
        width: length ? undefined : _.isNumber(width) ? `${width}px` : width,
        ...style,
    };
    const contentStyle = {
        width: _.isNumber(contentWidth) ? `${contentWidth}px` : contentWidth,
    };

    // sometimes we don't want a label on text fields!
    const formElementLabel = label && (
        <FormElementLabel
            extraLabelContent={extraLabelContent}
            fieldName={fieldName}
            helpText={helpText}
            helpTextCollisionBoundary={helpTextCollisionBoundary}
            isRequired={isRequired}
            label={label}
            labelClassName={labelClassName}
            width={formElementStyle.width}
        />
    );

    const formElementError = error &&
        typeof error === 'string' &&
        (touched || forceShowError) &&
        (!inputStyle || inputStyle !== InputStyles.REQUIRED_EMPTY_UNTOUCHED) && (
            <div className="mark43-form-error-message" data-test-id={testIds.INPUT_ERROR_MESSAGE}>
                {error}
            </div>
        );

    const elementClasses = classNames('mark43-form-element');
    const fieldClasses = classNames(
        'mark43-form-field',
        {
            'label-margin': labelMargin,
            'default-margin': !labelMargin && !label,
        },
        className
    );
    return (
        <FormControlBase
            length={length}
            className={fieldClasses}
            style={formElementStyle}
            data-test-field-name={fieldName || fieldNameAlternate}
        >
            {formElementLabel}

            <div className={elementClasses} style={contentStyle}>
                {children}
            </div>
            {formElementError}
        </FormControlBase>
    );
};

export default FormElement;

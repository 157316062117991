import React from 'react';

import { formatCode } from '~/client-common/core/domain/codes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';

import { CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';

const strings = componentStrings.reports.core.EventInfoCard;

const UcrPropertySummary = ({ ucrProperty, codesByCodeId }) => {
    return (
        <CardSubsection
            key={ucrProperty.sequenceNumber}
            title={strings.ucrClassificationPropertySectionTitle(ucrProperty.sequenceNumber)}
        >
            <SummaryRow fieldName={fields.UCR_PROPERTY_ADDITIONAL_NOTES}>
                {ucrProperty.additionalNotes}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_PROPERTY_PROPERTY_CATEGORY_CODE_ID}>
                {formatCode(codesByCodeId(ucrProperty.propertyCategoryCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_PROPERTY_PROPERTY_STATUS_CODE_ID}>
                {formatCode(codesByCodeId(ucrProperty.propertyStatusCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_PROPERTY_NEAREST_DOLLAR_VALUE}>
                {ucrProperty.nearestDollarValue}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_PROPERTY_PROPERTY_STATUS_CODE_ID}>
                {formatCode(codesByCodeId(ucrProperty.vehicleRecoveryTypeCodeId))}
            </SummaryRow>
            <SummaryRowDate
                fieldName={fields.UCR_PROPERTY_VEHICLE_RECOVERY_DATE_UTC}
                date={ucrProperty.vehicleRecoveryDateUtc}
                format={SummaryRowDate.FORMATS.SUMMARY_DATE}
            />
        </CardSubsection>
    );
};

export default UcrPropertySummary;

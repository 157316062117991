import _ from 'lodash';
import { createSelector } from 'reselect';
import { printingTemplatesSelector } from '~/client-common/core/domain/printing-templates/state/data';
import { actionTypes as dataActionTypes } from '../data';
import { actionTypes as uiActionTypes } from './actions';

export const printingTemplatesAdminUiSelector = (state) => state.ui.printingTemplatesAdmin;

export default function printingTemplatesAdminUiReducer(
    state = {
        loadingPrintingTemplates: false,
        loadingPrintingTemplatesErrorMessage: null,
        formErrorMessage: null,
        formLoading: false,
        savingPrintingTemplateSuccess: false,
        selectedPrintingTemplateId: null,
        stagedFile: null,
        newTemplate: false,
    },
    action
) {
    switch (action.type) {
        case dataActionTypes.LOAD_PRINTING_TEMPLATES_START:
            return {
                ...state,
                loadingPrintingTemplates: true,
                loadingPrintingTemplatesErrorMessage: null,
            };
        case dataActionTypes.LOAD_PRINTING_TEMPLATES_SUCCESS:
            return {
                ...state,
                loadingPrintingTemplatesErrorMessage: null,
                loadingPrintingTemplates: false,
            };
        case dataActionTypes.LOAD_PRINTING_TEMPLATES_FAILURE:
            return {
                ...state,
                loadingPrintingTemplatesErrorMessage: action.payload,
                loadingPrintingTemplates: false,
            };
        case dataActionTypes.SAVE_PRINTING_TEMPLATE_FAILURE:
            return {
                ...state,
                formErrorMessage: action.payload,
                savingPrintingTemplateSuccess: false,
            };
        case dataActionTypes.SAVE_PRINTING_TEMPLATE_SUCCESS:
            return {
                ...state,
                formErrorMessage: null,
                savingPrintingTemplateSuccess: true,
                stagedFile: null,
            };
        case uiActionTypes.SELECT_PRINTING_TEMPLATE_START:
            return {
                ...state,
                loadingPrintingTemplatesErrorMessage: null,
                formErrorMessage: null,
                savingPrintingTemplateSuccess: false,
                selectedPrintingTemplateId: null,
                stagedFile: null,
            };
        case uiActionTypes.SELECT_PRINTING_TEMPLATE_SUCCESS:
            return {
                ...state,
                selectedPrintingTemplateId: action.payload,
                newTemplate: false,
            };
        case uiActionTypes.SELECT_PRINTING_TEMPLATE_FAILURE:
            return {
                ...state,
                loadingPrintingTemplatesErrorMessage: action.payload,
            };
        case uiActionTypes.OPEN_NEW_PRINTING_TEMPLATE_FORM:
            return {
                ...state,
                loadingPrintingTemplatesErrorMessage: null,
                formErrorMessage: null,
                savingPrintingTemplateSuccess: false,
                selectedPrintingTemplateId: null,
                stagedFile: null,
                newTemplate: true,
            };
        case uiActionTypes.UPLOAD_FILE_START:
            return {
                ...state,
                formLoading: true,
                stagedFile: null,
            };
        case uiActionTypes.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                formLoading: false,
                stagedFile: action.payload,
            };
        case uiActionTypes.UPLOAD_FILE_FAILURE:
            return {
                ...state,
                formLoading: false,
                savingPrintingTemplateSuccess: false,
                formErrorMessage: action.payload,
            };
        case uiActionTypes.CLEAR_PRINTING_TEMPLATE_ADMIN:
            return {
                ...state,
                newTemplate: false,
                selectedPrintingTemplateId: null,
            };
        default:
            return state;
    }
}

export const printingTemplatesListItemsSelector = createSelector(
    printingTemplatesSelector,
    printingTemplatesAdminUiSelector,
    (printingTemplates, ui) =>
        _(printingTemplates)
            .map((printingTemplate) => {
                return {
                    path: `/support/printing/templates/${printingTemplate.id}`,
                    title: printingTemplate.title,
                    key: printingTemplate.id,
                    selected: printingTemplate.id === parseInt(ui.selectedPrintingTemplateId),
                };
            })
            .sortBy('title')
            .value()
);

import _ from 'lodash';
import { createSelector } from 'reselect';
import {
    exportReleaseTypeByIdSelector,
    exportReleaseTypesSelector,
} from '~/client-common/core/domain/export-release-types/state/data';
import exportReleaseAdminForm from '../forms/exportReleaseSettingForm';
import { saveExportReleaseSettings } from '../data';

const SUBMIT_EXPORT_RELEASE_SETTINGS_START =
    'export-release-settings-admin/SUBMIT_EXPORT_RELEASE_SETTINGS_START';
const SUBMIT_EXPORT_RELEASE_SETTINGS_SUCCESS =
    'export-release-settings-admin/SUBMIT_EXPORT_RELEASE_SETTINGS_SUCCESS';
const SUBMIT_EXPORT_RELEASE_SETTINGS_FAILURE =
    'export-release-settings-admin/SUBMIT_EXPORT_RELEASE_SETTINGS_FAILURE';
const SELECT_EXPORT_RELEASE_TYPE_ID = 'export-release-settings-admin/SELECT_EXPORT_RELEASE_TYPE_ID';

function submitExportReleaseSettingStart() {
    return {
        type: SUBMIT_EXPORT_RELEASE_SETTINGS_START,
    };
}
function submitExportReleaseSettingSuccess() {
    return {
        type: SUBMIT_EXPORT_RELEASE_SETTINGS_SUCCESS,
    };
}
function submitExportReleaseSettingFailure(errorMessage) {
    return {
        type: SUBMIT_EXPORT_RELEASE_SETTINGS_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

export function submitExportReleaseSettingForm() {
    return (dispatch) => {
        dispatch(submitExportReleaseSettingStart());
        return dispatch(
            exportReleaseAdminForm.actionCreators.submit((formModel) => {
                // TODO move this into convertFormToData once the data model is clearer
                const exportReleaseSettings = {
                    exportReleaseTypes: [formModel],
                };
                return dispatch(saveExportReleaseSettings(exportReleaseSettings))
                    .then((updatedExportReleaseSettings) => {
                        dispatch(submitExportReleaseSettingSuccess(updatedExportReleaseSettings));
                    })
                    .catch((err) => {
                        dispatch(submitExportReleaseSettingFailure(err.message));
                        throw err;
                    });
            })
        );
    };
}

function selectedExportReleaseTypeId(id) {
    return {
        type: SELECT_EXPORT_RELEASE_TYPE_ID,
        payload: id,
    };
}

export function selectExportReleaseSetting(id) {
    return (dispatch, getState) => {
        dispatch(selectedExportReleaseTypeId(id));
        const state = getState();
        const exportReleaseType = exportReleaseTypeByIdSelector(state)(id);
        dispatch(exportReleaseAdminForm.actionCreators.change(exportReleaseType));
    };
}

const uiSelector = (state) => state.ui.exportReleaseSettingAdmin;

export const exportSettingsAdminListItemsSelector = createSelector(
    exportReleaseTypesSelector,
    uiSelector,
    (exportReleaseTypes, ui) =>
        _(exportReleaseTypes)
            .map((exportReleaseType) => {
                return {
                    path: `admin/export-release-settings/${exportReleaseType.id}`,
                    title: exportReleaseType.releaseTypeName,
                    key: exportReleaseType.id,
                    selected: exportReleaseType.id === ui.selectedExportReleaseTypeId,
                };
            })
            .sortBy('title')
            .value()
);

export default function exportReleaseSettingAdminUiReducer(
    state = {
        selectedExportReleaseTypeId: null,
    },
    action
) {
    switch (action.type) {
        case SELECT_EXPORT_RELEASE_TYPE_ID:
            return {
                selectedExportReleaseTypeId: action.payload,
            };
        default:
            return state;
    }
}

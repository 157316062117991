import { endsWith, forEach, isArray, isEmpty, reduce } from 'lodash';
import { MFTFormConfiguration, _Form } from 'markformythree';

type StringKeyRecord<K extends string, T extends RecordOrEntry> = {
    [P in K]: T;
};
// a value in a form field
type FieldEntryType = boolean | string | number | object | undefined;
// an object mapping strings to a form field values
type FieldsetEntryType = StringKeyRecord<string, FieldEntryType>;
// something in the above two types or an object mapping strings to fieldsets
type RecordOrEntry =
    | FieldEntryType
    | FieldsetEntryType
    | StringKeyRecord<string, FieldsetEntryType>;

const getPathValueMappings = ({
    path,
    item,
}: {
    path: string;
    item: RecordOrEntry;
}): FieldsetEntryType => {
    if (isEmpty(item) || isArray(item) || item instanceof Date || typeof item !== 'object') {
        return { [path]: item };
    }
    const accumulator: FieldsetEntryType = {};
    return reduce(
        item,
        (result, value, key) => ({
            ...result,
            ...getPathValueMappings({ path: `${path}.${key}`, item: value }),
        }),
        accumulator
    );
};

/**
 * A helper for adding to a fieldset by using an individual form.set for each field.
 * This ensures rules are run for each underlying field in the fieldset.
 * This is wrapped in a form.transaction for performance reasons.
 */
export const fieldsetAdder = <T extends MFTFormConfiguration>({
    form,
    path,
    item,
}: {
    form: _Form<T>;
    path: string;
    item: FieldsetEntryType;
}) => {
    const pathValueMappings = getPathValueMappings({ path, item });
    const callback = () => {
        forEach(pathValueMappings, (value, path) => {
            form.set(path, value);
        });
    };
    return form.transaction(callback);
};

/**
 * A helper for removing a fieldset by using an individual form.set for each field.
 * This ensures rules are run for each underlying field in the fieldset.
 * This is wrapped in a form.transaction for performance reasons.
 */
export const fieldsetRemover = <T extends MFTFormConfiguration>({
    form,
    path,
}: {
    form: _Form<T>;
    path: string;
}) => {
    // @ts-expect-error Property 'fields' does not exist on type '(path: string) => unknown'.ts(2339) (on fields)
    //                  Expected 0 arguments, but got 1.ts(2554) (on path)
    const { fields } = form.getConfigurationForPath(path);

    const whoKnows = getPathValueMappings({ path, item: fields });
    const keys = Object.keys(whoKnows);
    const callback = () => {
        forEach(keys, (value) => {
            const trimmedKey = endsWith(value, '.fieldName') ? value.slice(0, -10) : value;
            // adding these individually will ensure all rules run for fields in the configuration
            form.set(trimmedKey, undefined);
        });
        // adding this will ensure removal of anything under the path that is not on the configuration
        form.set(path, undefined);
    };

    return form.transaction(callback);
};

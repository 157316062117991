import { createField, createFormConfiguration, InferFormDataShape } from 'markformythree';

export const personSearchToAddSidePanelFormConfig = createFormConfiguration({
    quickSearchQuery: createField<string>({}),
    dateOfBirthQuickSearchQuery: createField<string>({}),
    masterPersonId: createField<number>({}),

    dlNumber: createField<string>({}),
    dlStateAttrId: createField<number>({}),
    homeAddressQuickSearchQuery: createField<string>({}),
    phoneNumber: createField<string>({}),
});

export const personSearchToAddSuggestionPopoverFormConfig = createFormConfiguration({
    quickSearchQuery: createField<string>({}),
    dateOfBirthQuickSearchQuery: createField<string>({}),
});

export type PersonSearchToAddFormConfiguration = typeof personSearchToAddSidePanelFormConfig;
type PersonSearchToAddFormModel = InferFormDataShape<PersonSearchToAddFormConfiguration>;

export const convertFromFormModel = (
    formModel: Partial<PersonSearchToAddFormModel> = {}
): Partial<PersonSearchToAddFormModel> => {
    const entries = Object.entries(formModel).map(([key, value]) => {
        return [key, value === '' ? null : value];
    });

    return Object.fromEntries(entries);
};

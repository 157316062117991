import {
    ElasticCobaltSortKeyEnumType,
    EntityProfilesResource,
    BookingsResource,
    ProfileReportsFilterDto,
} from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

type Params = { include_configurables?: boolean };
type ParamsWithSearch = Params & {
    from?: number;
    size?: number;
    sort_key?: ElasticCobaltSortKeyEnumType;
    sort_type?:
        | 'DATE_MOST_RECENT_TO_LEAST_RECENT'
        | 'DATE_LEAST_RECENT_TO_MOST_RECENT'
        | 'NUMBER_LOWEST_TO_HIGHEST'
        | 'NUMBER_HIGHEST_TO_LOWEST'
        | 'ALPHABETICAL_A_TO_Z'
        | 'ALPHABETICAL_Z_TO_A';
};

// for the related reports endpoints, params is an object { from, size }
export default createResource({
    name: 'Entity Profiles Resource',
    methods: {
        // persons
        getPersonProfile: (id: number) =>
            req<EntityProfilesResource.GetPersonProfile>({
                url: `entity_profiles/person/${id}/profile`,
            }),
        getPersonProfileReports: (id, params?: ParamsWithSearch, data?: ProfileReportsFilterDto) =>
            req<EntityProfilesResource.GetPersonProfileReports>({
                url: `entity_profiles/person/${id}/reports`,
                method: 'POST',
                params,
                data,
            }),
        getPersonProfileBookings: (id: number, from: number, size: number) =>
            req<BookingsResource.GetBookingsForProfile>({
                url: `jms/api/persons/${id}/bookings`,
                method: 'GET',
                params: {
                    from,
                    size,
                },
            }),
        getPersonProfileReportsTypes: (id: number) =>
            req<EntityProfilesResource.GetPersonProfileReportsTypes>({
                url: `entity_profiles/person/${id}/reports/types`,
            }),
        getPersonProfileLocations: (id: number) =>
            req<EntityProfilesResource.GetPersonProfileLocations>({
                url: `entity_profiles/person/${id}/locations`,
            }),
        getPersonProfilePersons: (id: number) =>
            req<EntityProfilesResource.GetPersonProfilePersons>({
                url: `entity_profiles/person/${id}/persons`,
            }),
        getPersonProfileOrganizations: (id: number) =>
            req<EntityProfilesResource.GetPersonProfileOrgs>({
                url: `entity_profiles/person/${id}/orgs`,
            }),
        getPersonProfileProperty: (id: number) =>
            req<EntityProfilesResource.GetPersonProfileProperty>({
                url: `entity_profiles/person/${id}/property`,
            }),
        getPersonProfileVehicles: (id: number) =>
            req<EntityProfilesResource.GetPersonProfileVehicles>({
                url: `entity_profiles/person/${id}/vehicles`,
            }),
        getPersonProfileWarrants: (id: number) =>
            req<EntityProfilesResource.GetPersonProfileWarrants>({
                url: `entity_profiles/person/${id}/warrants`,
            }),

        // organizations
        getOrganizationProfile: (id: number) =>
            req<EntityProfilesResource.GetOrgProfile>({
                url: `entity_profiles/org/${id}/profile`,
            }),
        getOrganizationProfileReports: (id: number, params?: Params) =>
            req<EntityProfilesResource.GetOrgProfileReports>({
                url: `entity_profiles/org/${id}/reports`,
                params,
            }),
        getOrganizationProfileLocations: (id: number) =>
            req<EntityProfilesResource.GetOrgProfileLocations>({
                url: `entity_profiles/org/${id}/locations`,
            }),
        getOrganizationProfilePersons: (id: number) =>
            req<EntityProfilesResource.GetOrgProfilePersons>({
                url: `entity_profiles/org/${id}/persons`,
            }),
        getOrganizationProfileOrganizations: (id: number) =>
            req<EntityProfilesResource.GetOrgProfileOrgs>({
                url: `entity_profiles/org/${id}/orgs`,
            }),
        getOrganizationProfileProperty: (id: number) =>
            req<EntityProfilesResource.GetOrgProfileProperty>({
                url: `entity_profiles/org/${id}/property`,
            }),
        getOrganizationProfileVehicles: (id: number) =>
            req<EntityProfilesResource.GetOrgProfileVehicles>({
                url: `entity_profiles/org/${id}/vehicles`,
            }),

        // property
        getPropertyProfile: (id: number) =>
            req<EntityProfilesResource.GetPropertyProfile>({
                url: `entity_profiles/property/${id}/profile`,
            }),
        getPropertyProfileReports: (id: number, params: ParamsWithSearch) =>
            req<EntityProfilesResource.GetPropertyProfileReports>({
                url: `entity_profiles/property/${id}/reports`,
                params,
            }),
        getPropertyProfileLocations: (id: number) =>
            req<EntityProfilesResource.GetPropertyProfileLocations>({
                url: `entity_profiles/property/${id}/locations`,
            }),
        getPropertyProfilePersons: (id: number) =>
            req<EntityProfilesResource.GetPropertyProfilePersons>({
                url: `entity_profiles/property/${id}/persons`,
            }),
        getPropertyProfileOrganizations: (id: number) =>
            req<EntityProfilesResource.GetPropertyProfileOrgs>({
                url: `entity_profiles/property/${id}/orgs`,
            }),

        // vehicles
        getVehicleProfile: (id: number) =>
            req<EntityProfilesResource.GetVehicleProfile>({
                url: `entity_profiles/vehicle/${id}/profile`,
            }),
        getVehicleProfileReports: (
            id: number,
            params: ParamsWithSearch,
            data?: ProfileReportsFilterDto
        ) =>
            req<EntityProfilesResource.GetFilteredVehicleProfileReports>({
                url: `entity_profiles/vehicle/${id}/reports`,
                method: 'POST',
                params,
                data,
            }),
        getVehicleProfileReportsTypes: (id: number) =>
            req<EntityProfilesResource.GetVehicleProfileReportsTypes>({
                url: `entity_profiles/vehicle/${id}/reports/types`,
            }),
        getVehicleProfileLocations: (id: number) =>
            req<EntityProfilesResource.GetVehicleProfileLocations>({
                url: `entity_profiles/vehicle/${id}/locations`,
            }),
        getVehicleProfilePersons: (id: number) =>
            req<EntityProfilesResource.GetVehicleProfilePersons>({
                url: `entity_profiles/vehicle/${id}/persons`,
            }),
        getVehicleProfileOrganizations: (id: number) =>
            req<EntityProfilesResource.GetVehicleProfileOrgs>({
                url: `entity_profiles/vehicle/${id}/orgs`,
            }),
    },
});

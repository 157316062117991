import { get, uniq, compact, map, isFunction, includes } from 'lodash';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import { reportDefinitionHasCardSelector } from '~/client-common/core/domain/report-definitions/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import {
    currentReportIdSelector,
    canEditReportCardStatusSelector,
    canEditOffenseIncidentReportCardStatusSelector,
    canEditEventInfoReportCardStatusSelector,
    canEditSummaryNarrativeReportCardStatusSelector,
    disableReportSubmissionButtonsSelector,
    approvalStatusSelector,
    canSubmitReportSelector,
    currentReportSelector,
    reportIsPackagedSelector,
    reportStatusViewSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { mountedOverlayBases } from '../../../../../core/overlayManager';
import { cards } from '../../utils/cardsRegistry';
import { defaultExportPresetSelector } from '../../../../admin/export-presets/state/data';
import { executeExportPreset } from '../../components/header/ReportHeaderExportsDropdown';
import { editMediator } from './submissions';
import { packageReport, validateStaffReview } from './submissionValidations';

export function validateReport() {
    return (dispatch, getState) => {
        const state = getState();
        const currentReport = currentReportSelector(state);
        const reportDefinitionId = get(currentReport, 'reportDefinitionId');
        const disableReportSubmissionButtons = disableReportSubmissionButtonsSelector(state);
        const approvalStatus = approvalStatusSelector(state);
        const canSubmitReport = canSubmitReportSelector(state);
        const isCustodialPropertySummary = reportDefinitionHasCardSelector(state)(
            reportDefinitionId,
            reportCardEnum.CUSTODIAL.id
        );
        const reportIsPackaged = reportIsPackagedSelector(state);
        const reportStatusView = reportStatusViewSelector(state);
        const canStaffReview = get(reportStatusView, 'canStaffReview');

        if (!disableReportSubmissionButtons && !isCustodialPropertySummary) {
            if (
                approvalStatus === approvalStatusClientEnum.DRAFT &&
                canSubmitReport &&
                !reportIsPackaged
            ) {
                dispatch(packageReport());
            }

            if (approvalStatus === approvalStatusClientEnum.APPROVED && canStaffReview) {
                dispatch(validateStaffReview());
            }
        }
    };
}

export function transitionAllCardsToEditMode() {
    return (dispatch, getState) => {
        const state = getState();
        const visibleCardNames = uniq(compact(map(cards, 'name')));
        const canEditReportCardStatus = canEditReportCardStatusSelector(state);
        const canEditCards = get(canEditReportCardStatus, 'canEditReportCard');
        const canEditOffenseIncidentReportCardStatus = canEditOffenseIncidentReportCardStatusSelector(
            state
        );
        const canEditOffenseIncidentCards = get(
            canEditOffenseIncidentReportCardStatus,
            'canEditReportCard'
        );

        const canEditEventInfoReportCardStatus = canEditEventInfoReportCardStatusSelector(state);
        const canEditEventInfoCard = get(canEditEventInfoReportCardStatus, 'canEditReportCard');

        const canEditSummaryNarrativeReportCardStatus = canEditSummaryNarrativeReportCardStatusSelector(
            state
        );
        const canEditSummaryNarrativeCard = get(
            canEditSummaryNarrativeReportCardStatus,
            'canEditReportCard'
        );

        const hasOffenseIncidentCards =
            includes(visibleCardNames, reportCardEnum.OFFENSE.name) ||
            includes(visibleCardNames, reportCardEnum.INCIDENT.name);

        const hasEventInfoCard = includes(visibleCardNames, reportCardEnum.EVENT_INFO.name);
        const hasSummaryNarrativeCard = includes(
            visibleCardNames,
            reportCardEnum.SUMMARY_NARRATIVE.name
        );

        const editMediatorCallback = () => {
            map(cards, (card) => {
                const { name: cardName, editAll, edit, index } = card;

                const isOffenseIncidentCard =
                    cardName === reportCardEnum.OFFENSE.name ||
                    cardName === reportCardEnum.INCIDENT.name;

                const isEventInfoCard = cardName === reportCardEnum.EVENT_INFO.name;
                const isSummaryNarrativeCard = cardName === reportCardEnum.SUMMARY_NARRATIVE.name;
                if (
                    (isOffenseIncidentCard && canEditOffenseIncidentCards) ||
                    (isEventInfoCard && canEditEventInfoCard) ||
                    (isSummaryNarrativeCard && canEditSummaryNarrativeCard) ||
                    (!isOffenseIncidentCard && !isEventInfoCard && canEditCards)
                ) {
                    // Note: The Dynamic Report feature relies on specifying
                    // the card index to determine the report id that it belongs
                    // to, which is why we must pass it to the edit call back.
                    const reportId = currentReportIdSelector(state);
                    if (isFunction(editAll)) {
                        dispatch(editAll({ reportId, index }));
                    } else if (isFunction(edit)) {
                        dispatch(edit({ reportId, index }));
                    }
                }
            });
        };

        if (
            (hasOffenseIncidentCards && canEditOffenseIncidentCards) ||
            (hasEventInfoCard && canEditEventInfoCard) ||
            (hasSummaryNarrativeCard && canEditSummaryNarrativeCard) ||
            canEditCards
        ) {
            dispatch(editMediator(editMediatorCallback));
        }
    };
}

export function printReport() {
    return (dispatch, getState) => {
        const currentReport = currentReportSelector(getState());
        const defaultExportPreset = defaultExportPresetSelector(getState());
        if (!!defaultExportPreset) {
            dispatch(executeExportPreset(defaultExportPreset, currentReport));
        }
    };
}

export function openNibrsAdminSidePanel() {
    return (dispatch, getState, { overlayStore }) => {
        if (!mountedOverlayBases[overlayIdEnum.GENERATED_SEGMENTS_ERRORS_WRAPPER]) {
            return;
        }
        const cancelFocusRef = document.activeElement;
        overlayStore.open(overlayIdEnum.GENERATED_SEGMENTS_ERRORS_WRAPPER, { cancelFocusRef });
    };
}

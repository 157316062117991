import React from 'react';
import HighRiskPropertyLabel from '../../../../../../entity-profiles/property/components/HighRiskPropertyLabel';
import type { ManageEvidenceDashboardResults } from '../../../../types';
import { useIsHighValue } from '../../../../hooks/useIsHighValue';

export const EvidenceCautionCell = ({
    masterItemId,
    isHighValue,
    isInHighValueContainer,
}: Pick<
    ManageEvidenceDashboardResults,
    'masterItemId' | 'isHighValue' | 'isInHighValueContainer'
>) => {
    const itemIsHighValue = useIsHighValue({ masterItemId, isHighValue, isInHighValueContainer });

    if (!itemIsHighValue) {
        return null;
    }

    return <HighRiskPropertyLabel />;
};

import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { COLUMN_WIDTH_24, COLUMN_WIDTH_8 } from '../configuration/constants';
import FlexContainer from './core/FlexContainer';
import Column from './core/Column';
import MergeEntitiesText from './core/MergeEntitiesText';

const MergeNoticeRow = () => (
    <FlexContainer
        flexDirection="row"
        backgroundColor="white"
        justify="flex-start"
        flexBasis="auto"
    >
        <Column
            backgroundColor="var(--arc-colors-surface-accent)"
            width={COLUMN_WIDTH_8}
            borderColor="var(--arc-colors-border-default)"
            borderBottom="1px solid"
            borderRight="1px solid"
        />
        <Column
            width={COLUMN_WIDTH_24}
            borderColor="var(--arc-colors-border-default)"
            borderBottom="1px solid"
        >
            <MergeEntitiesText>
                {componentStrings.mergeEntities.MergeNoticeRow.notice}
            </MergeEntitiesText>
        </Column>
    </FlexContainer>
);

export default MergeNoticeRow;

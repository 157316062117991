import { ReportsResource } from '@mark43/rms-api';
import { CrashLocationCardBundleForUpsert } from '~/client-common/core/domain/crash-locations/state/data';
import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';

export default createResource({
    name: 'Crash Location Resource',
    methods: {
        upsertCrashLocationCard(data: CrashLocationCardBundleForUpsert, reportId: number) {
            return req<ReportsResource.UpsertCrashLocationCardBundle>({
                method: 'PUT',
                url: `reports/${reportId}/cards/crash_location`,
                // @ts-expect-error API type incorrectly requires id and has versionable properties
                data,
            });
        },
    },
});

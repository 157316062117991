import React from 'react';
import { Observer } from 'markformythree';
import styled from 'styled-components';
import { isArray } from 'lodash';
import Row from '../../../components/Row';
import { ArbiterMFTCheckbox } from './Checkbox';

export const createCheckboxPath = (path) =>
    `is${path.substring(0, 1).toUpperCase()}${path.substring(1)}Required`;

const StyledArbiterMFTCheckbox = styled(ArbiterMFTCheckbox)`
    margin-top: 20px;
`;

const CheckboxedField = ({ children, checkboxPath }) => {
    const childrenArray = React.Children.toArray(children);
    const path = childrenArray.length > 0 ? childrenArray[0].props.path : '';
    return (
        <Row>
            <Observer
                subscriptions={{
                    [path]: path,
                }}
                render={(subscriptions, form) => {
                    const fieldValue = subscriptions[path];
                    const forceChecked = isArray(fieldValue) ? fieldValue.length > 0 : !!fieldValue;
                    if (forceChecked) {
                        form.set(checkboxPath, true);
                    }
                    return null;
                }}
            />
            <StyledArbiterMFTCheckbox path={checkboxPath} label={''} />
            {children}
        </Row>
    );
};

export default CheckboxedField;

import { GeoAlias } from '@mark43/rms-api';
import * as actionTypes from './actionTypes';
import {
    GeoAliasesActions,
    GeoAliasesDeleteSuccessT,
    GeoAliasesDeleteFailureT,
    GeoAliasesSaveSuccessT,
    GeoAliasesSaveFailureT,
} from '.';

type GeoAliasesStateT = {
    aliases: GeoAlias[];
    loadingList: boolean;
    submittingForm: boolean;
    listError: string | null | undefined;
    formError: string | null | undefined;
};

const initialState: GeoAliasesStateT = {
    aliases: [],
    loadingList: false,
    submittingForm: false,
    listError: null,
    formError: null,
};

export const geoAliasesAdminReducer = (state = initialState, action: GeoAliasesActions) => {
    switch (action.type) {
        case actionTypes.LOAD_GEO_ALIASES_START:
            return {
                ...state,
                loadingList: true,
                listError: null,
            };
        case actionTypes.LOAD_GEO_ALIASES_SUCCESS:
            return {
                ...state,
                loadingList: false,
                aliases: action.payload,
            };
        case actionTypes.LOAD_GEO_ALIASES_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload.message,
            };
        case actionTypes.SAVE_GEO_ALIAS_START:
        case actionTypes.DELETE_GEO_ALIAS_START:
            return {
                ...state,
                submittingForm: true,
                formError: null,
            };
        case actionTypes.SAVE_GEO_ALIAS_SUCCESS:
            const alias = (action as GeoAliasesSaveSuccessT).payload;
            const currIndex = state.aliases.findIndex((a) => a.id === alias.id);
            const newAliases = [...state.aliases];

            if (!!~currIndex) {
                newAliases[currIndex] = alias;
            } else {
                newAliases.push(alias);
            }

            return {
                ...state,
                aliases: newAliases,
                submittingForm: false,
            };
        case actionTypes.DELETE_GEO_ALIAS_SUCCESS:
            const deletedAlias = (action as GeoAliasesDeleteSuccessT).payload;
            const aliases = [...state.aliases];
            const aliasIdx = aliases.findIndex((a) => a.id === deletedAlias.id);

            if (aliasIdx < 0) {
                return state;
            }

            aliases.splice(aliasIdx, 1);

            return {
                ...state,
                aliases,
                submittingForm: false,
            };
        case actionTypes.SAVE_GEO_ALIAS_FAILURE:
        case actionTypes.DELETE_GEO_ALIAS_FAILURE:
            const modifyErr = (action as GeoAliasesDeleteFailureT | GeoAliasesSaveFailureT).payload;

            return {
                ...state,
                submittingForm: false,
                formError: modifyErr.message,
            };
        default:
            return state;
    }
};

import Big from 'big.js';

/**
 * Use Big to subtract numbers and maintain arbitrary sensible decimals
 * @param  {string} minuend     non-zero number represented as string
 * @param  {string} subtrahend  any user entered string
 * @return {string} the string representation of the subtraction result
 */
export function autoBalanceSubtract(minuend, subtrahend) {
    // if subtrahend is not a number or zero, return minuend
    const subtrahendIsNumber = !!Number(subtrahend);
    if (!subtrahendIsNumber) {
        return minuend;
    }
    const minuendBig = new Big(minuend);

    return minuendBig.minus(subtrahend).toString();
}

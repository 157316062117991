import { LinkTypesEnum, EntityTypeEnum, AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { forEach, filter, flatMap, get, isEmpty, map, omit, size } from 'lodash';
import { reportVersionableBaseFieldKeys } from '~/client-common/helpers/dataHelpers';
import { trimToMinutes } from '~/client-common/core/dates/utils/dateHelpers';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import {
    hydratedEventDetailByReportIdSelector,
    replaceEventDetailCardBundle,
} from '~/client-common/core/domain/reports/state/ui';
import {
    attributesSelector,
    attributeIsOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import {
    updateMultipleReportEventDetails,
    storeEventDetails,
} from '~/client-common/core/domain/event-details/state/data';
import {
    NEXUS_STATE_PROP as AGENCY_PROFILES_NEXUS_STATE_PROP,
    agencyProfileByIdSelector,
} from '~/client-common/core/domain/agency-profiles/state/data';
import { NEXUS_STATE_PROP as PERSON_EMERGENCY_CONTACTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-emergency-contacts/state/data';
import { NEXUS_STATE_PROP as PASSPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/passports/state/data';
import { NEXUS_STATE_PROP as EMPLOYMENT_HISTORIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/employment-histories/state/data';
import { NEXUS_STATE_PROP as IDENTIFYING_MARKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/identifying-marks/state/data';
import {
    locationEntityLinksWhereSelector,
    NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/location-entity-links/state/data';
import { NEXUS_STATE_PROP as LOCATION_NEXUS_STATE_PROP } from '~/client-common/core/domain/locations/state/data';
import { NEXUS_STATE_PROP as NAME_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-attributes/state/data';
import { NEXUS_STATE_PROP as NAME_EMAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-emails/state/data';
import { NEXUS_STATE_PROP as NAME_IDENTIFIERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-identifiers/state/data';
import { NEXUS_STATE_PROP as NAME_MONIKERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-monikers/state/data';
import {
    NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP,
    nameReportLinksWhereSelector,
} from '~/client-common/core/domain/name-report-links/state/data';
import { NEXUS_STATE_PROP as NAME_PHONES_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-phones/state/data';
import { NEXUS_STATE_PROP as ORGANIZATION_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/organization-profiles/state/data';
import { NEXUS_STATE_PROP as PERSON_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-profiles/state/data';
import { NEXUS_STATE_PROP as SCHOOL_HISTORIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/school-histories/state/data';
import { NEXUS_STATE_PROP as PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-gang-trackings/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import {
    currentReportSelector,
    prefillForReportCardSelector,
    isCurrentReportNewSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { convertFromFormModel, convertToFormModel } from '../forms/eventInfoForm';
import { visibleEmbeddedReportIdsSelector } from './embeddedReports';

const buildPrefillPersistentDataToStore = (eventDetailBundleForPrefill) => {
    const entitiesToStore = {};

    // reporting party data
    if (size(get(eventDetailBundleForPrefill, 'nameReportLinks')) > 0) {
        const hydratedPersons = eventDetailBundleForPrefill.hydratedPersons;
        const hydratedOrgs = eventDetailBundleForPrefill.hydratedOrganizations;

        entitiesToStore[NAME_REPORT_LINKS_NEXUS_STATE_PROP] =
            eventDetailBundleForPrefill.nameReportLinks;
        entitiesToStore[PERSON_PROFILES_NEXUS_STATE_PROP] = flatMap(
            hydratedPersons,
            'personProfiles'
        );
        entitiesToStore[IDENTIFYING_MARKS_NEXUS_STATE_PROP] = flatMap(
            hydratedPersons,
            'identifyingMarks'
        );
        entitiesToStore[PERSON_EMERGENCY_CONTACTS_NEXUS_STATE_PROP] = flatMap(
            hydratedPersons,
            'personEmergencyContacts'
        );
        entitiesToStore[PASSPORTS_NEXUS_STATE_PROP] = flatMap(hydratedPersons, 'passports');
        entitiesToStore[EMPLOYMENT_HISTORIES_NEXUS_STATE_PROP] = flatMap(
            hydratedPersons,
            'employmentHistories'
        );
        entitiesToStore[SCHOOL_HISTORIES_NEXUS_STATE_PROP] = flatMap(
            hydratedPersons,
            'schoolHistories'
        );
        entitiesToStore[PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP] = flatMap(
            hydratedPersons,
            'personGangTrackings'
        );
        entitiesToStore[ORGANIZATION_PROFILES_NEXUS_STATE_PROP] = flatMap(
            hydratedOrgs,
            'organizationProfiles'
        );

        const addHydratedNameBaseData = (hydratedNameObjects) => {
            entitiesToStore[NAME_ATTRIBUTES_NEXUS_STATE_PROP] = flatMap(
                hydratedNameObjects,
                'nameAttributes'
            );
            entitiesToStore[NAME_EMAILS_NEXUS_STATE_PROP] = flatMap(hydratedNameObjects, 'emails');
            entitiesToStore[NAME_MONIKERS_NEXUS_STATE_PROP] = flatMap(
                hydratedNameObjects,
                'monikers'
            );
            entitiesToStore[NAME_IDENTIFIERS_NEXUS_STATE_PROP] = flatMap(
                hydratedNameObjects,
                'identifiers'
            );
            entitiesToStore[NAME_PHONES_NEXUS_STATE_PROP] = flatMap(hydratedNameObjects, 'phones');
            const locationViews = flatMap(hydratedNameObjects, 'locationViews');
            entitiesToStore[LOCATION_NEXUS_STATE_PROP] = locationViews;
            entitiesToStore[LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP] = flatMap(
                locationViews,
                'entityLinks'
            );
        };

        addHydratedNameBaseData([...hydratedPersons, ...hydratedOrgs]);
    }

    // report taken location data
    if (size(get(eventDetailBundleForPrefill, 'locationViews')) > 0) {
        const reportTakenLocationViews = eventDetailBundleForPrefill.locationViews;
        const reportTakenLocationEntityLinks = flatMap(reportTakenLocationViews, 'entityLinks');

        if (isUndefinedOrNull(entitiesToStore[LOCATION_NEXUS_STATE_PROP])) {
            entitiesToStore[LOCATION_NEXUS_STATE_PROP] = reportTakenLocationViews;
        } else {
            entitiesToStore[LOCATION_NEXUS_STATE_PROP] = entitiesToStore[
                LOCATION_NEXUS_STATE_PROP
            ].concat(reportTakenLocationViews);
        }

        if (isUndefinedOrNull(entitiesToStore[LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP])) {
            entitiesToStore[
                LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP
            ] = reportTakenLocationEntityLinks;
        } else {
            entitiesToStore[LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP] = entitiesToStore[
                LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP
            ].concat(reportTakenLocationEntityLinks);
        }
    }
    return entitiesToStore;
};

export function prefillEventInfoForm({ eventDetailBundleForPrefill }) {
    return (dispatch, getState, dependencies) => {
        const omitPropKeys = reportVersionableBaseFieldKeys;
        const state = getState();
        const prefillReport = currentReportSelector(state);

        const prefillAgencyProfile = get(eventDetailBundleForPrefill, 'agencyProfile');
        if (
            !isUndefinedOrNull(prefillAgencyProfile) &&
            prefillReport.agencyId !== prefillAgencyProfile.agencyId
        ) {
            dispatch(
                dependencies.nexus.withEntityItems(
                    { [AGENCY_PROFILES_NEXUS_STATE_PROP]: [prefillAgencyProfile] },
                    { type: 'EVENT_CARD_PREFILL_AGENCY_PROFILE' }
                )
            );
            prefillReport.agencyId = prefillAgencyProfile.agencyId;
        }

        const eventDetail = omit(get(eventDetailBundleForPrefill, 'eventDetail'), omitPropKeys);
        const prefillEventDetail = omit(eventDetail, 'paramedics');
        const prefillParamedics = get(eventDetail, 'paramedics') || [];
        const prefillReportNotifications = map(
            get(eventDetailBundleForPrefill, 'reportNotifications'),
            (reportNotification) => omit(reportNotification, omitPropKeys)
        );
        const prefillAssistingPersonnel = map(
            get(eventDetailBundleForPrefill, 'assistingOfficers'),
            (assistingOfficer) => omit(assistingOfficer, omitPropKeys)
        );
        const reportAttributes = map(
            get(eventDetailBundleForPrefill, 'reportAttributes'),
            (reportAttribute) => omit(reportAttribute, omitPropKeys)
        );
        const weatherReportAttributes = filter(reportAttributes, {
            attributeType: AttributeTypeEnum.WEATHER.name,
        });
        const eventStatisticsReportAttributes = filter(reportAttributes, {
            attributeType: AttributeTypeEnum.EVENT_STATISTICS.name,
        });

        const prefillWeatherAttrIds = map(weatherReportAttributes, 'attributeId');
        const prefillWeatherDescription = getDescriptionForAttributeLinks(weatherReportAttributes);
        const prefillEventStatisticsAttrIds = map(eventStatisticsReportAttributes, 'attributeId');
        const prefillEventStatisticsDescription = getDescriptionForAttributeLinks(
            eventStatisticsReportAttributes
        );

        const prefillPersistentDataToStore = buildPrefillPersistentDataToStore(
            eventDetailBundleForPrefill
        );

        const prefillReportTakenLocationLocationEntityLinks = filter(
            get(prefillPersistentDataToStore, LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP),
            {
                entityId: prefillReport.id,
                entityType: EntityTypeEnum.REPORT.name,
                linkType: LinkTypesEnum.LOCATION_OF_EVENT,
            }
        );

        if (!isEmpty(prefillPersistentDataToStore)) {
            dispatch(
                dependencies.nexus.withEntityItems(prefillPersistentDataToStore, {
                    type: 'EVENT_CARD_PERSISTENT_DATA_PREFILL',
                })
            );
        }

        const prefillNameReportLinks = map(
            prefillPersistentDataToStore[NAME_REPORT_LINKS_NEXUS_STATE_PROP],
            'nameId'
        );

        return convertToFormModel(
            prefillEventDetail,
            prefillWeatherAttrIds,
            prefillWeatherDescription,
            prefillEventStatisticsAttrIds,
            prefillEventStatisticsDescription,
            prefillReport,
            prefillReportNotifications,
            prefillAssistingPersonnel,
            prefillParamedics,
            prefillReportTakenLocationLocationEntityLinks,
            prefillNameReportLinks
        );
    };
}

export function prefillEventInfoFormFromCadTicket() {
    return (dispatch, getState) => {
        const state = getState();
        const currentReport = currentReportSelector(state);
        const hydratedEventDetail = hydratedEventDetailByReportIdSelector(state)(currentReport.id);
        const locationEntityLinks = locationEntityLinksWhereSelector(state)({
            entityId: currentReport.id,
            entityType: EntityTypeEnum.REPORT.name,
            linkType: LinkTypesEnum.LOCATION_OF_EVENT,
        });

        // override the existing Event Start Date & Event End Date using the Cad Ticket dates, if
        // they exist.
        const cadTicket = hydratedEventDetail.cadTicket;
        const eventDetail = {
            ...hydratedEventDetail.eventDetail,
            eventStartUtc: trimToMinutes(
                get(cadTicket, 'eventStartDateUtc') ||
                    get(hydratedEventDetail.eventDetail, 'eventStartUtc')
            ),
            eventEndUtc: trimToMinutes(
                get(cadTicket, 'eventClosedDateUtc') ||
                    get(hydratedEventDetail.eventDetail, 'eventEndUtc')
            ),
        };

        const reportingParties = map(
            nameReportLinksWhereSelector(state)({
                reportId: currentReport.id,
                linkType: LinkTypesEnum.REPORTING_PARTY_IN_REPORT,
                contextType: EntityTypeEnum.REPORT.name,
                contextId: currentReport.id,
            }),
            'nameId'
        );

        return convertToFormModel(
            eventDetail,
            hydratedEventDetail.weatherAttrIds,
            hydratedEventDetail.weatherDescription,
            hydratedEventDetail.eventStatisticsAttrIds,
            hydratedEventDetail.eventStatisticsDescription,
            hydratedEventDetail.report,
            hydratedEventDetail.reportNotifications,
            hydratedEventDetail.assistingPersonnel,
            hydratedEventDetail.paramedics,
            locationEntityLinks,
            reportingParties
        );
    };
}

export function refreshEventInfoForm() {
    return (dispatch, getState) => {
        const state = getState();
        const currentReport = currentReportSelector(state);
        const hydratedEventDetail = hydratedEventDetailByReportIdSelector(state)(currentReport.id);
        const locationEntityLinks = locationEntityLinksWhereSelector(state)({
            entityId: currentReport.id,
            entityType: EntityTypeEnum.REPORT.name,
            linkType: LinkTypesEnum.LOCATION_OF_EVENT,
        });

        const reportingParties = map(
            nameReportLinksWhereSelector(state)({
                reportId: currentReport.id,
                linkType: LinkTypesEnum.REPORTING_PARTY_IN_REPORT,
                contextType: EntityTypeEnum.REPORT.name,
                contextId: currentReport.id,
            }),
            'nameId'
        );

        return convertToFormModel(
            hydratedEventDetail.eventDetail,
            hydratedEventDetail.weatherAttrIds,
            hydratedEventDetail.weatherDescription,
            hydratedEventDetail.eventStatisticsAttrIds,
            hydratedEventDetail.eventStatisticsDescription,
            hydratedEventDetail.report,
            hydratedEventDetail.reportNotifications,
            hydratedEventDetail.assistingPersonnel,
            hydratedEventDetail.paramedics,
            locationEntityLinks,
            reportingParties
        );
    };
}

export function getEventInfoFormPrefill() {
    return (dispatch, getState) => {
        const state = getState();
        const eventInfoCardPrefill = prefillForReportCardSelector(state)(
            reportCardEnum.EVENT_INFO.name
        );
        const eventCardPrefillModalSaveSelected = get(
            eventInfoCardPrefill,
            'prefillModalSaveSelected'
        );
        const eventDetailBundle = get(eventInfoCardPrefill, 'eventDetailBundle');

        const shouldPrefillEventInfoForm =
            eventCardPrefillModalSaveSelected === true && !isUndefinedOrNull(eventDetailBundle);
        const isNewReport = isCurrentReportNewSelector(state);
        const shouldPrefillEventInfoFormFromCadTicket =
            isNewReport && isUndefinedOrNull(eventInfoCardPrefill);

        let initialFormState;
        if (shouldPrefillEventInfoForm) {
            initialFormState = dispatch(
                prefillEventInfoForm({
                    eventDetailBundleForPrefill: eventDetailBundle,
                })
            );
        } else if (shouldPrefillEventInfoFormFromCadTicket) {
            initialFormState = dispatch(prefillEventInfoFormFromCadTicket());
        }

        return initialFormState;
    };
}

/*
   This thunk is only used for the RMS_DYNAMIC_REPORT_ENABLED Feature (Dynamic Reports).
   The goal of this thunk is to "align" the event information with all other visible
   arrest reports. It is called when any of these actions are taken in the event info card:
    1. An Event Location is Added.
    2. An reporting party is Added.
    3. When the Event Information card was saved via "Saved Progress".
*/
export function saveEventInfoForm() {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(RefContextEnum.FORM_EVENT_INFO.name);
        if (form) {
            const state = getState();
            const formModel = form.getState().model;
            const currentReport = currentReportSelector(state);
            const currentReportId = currentReport.id;
            const attributes = attributesSelector(state);
            const attributeIsOther = attributeIsOtherSelector(state);
            const visibleArrestReportIds = visibleEmbeddedReportIdsSelector(state);
            const nameReportLinksWhere = nameReportLinksWhereSelector(state);

            const objectModel = convertFromFormModel(
                formModel,
                currentReport,
                attributes,
                attributeIsOther
            );

            return dispatch(
                updateMultipleReportEventDetails(
                    {
                        agencyProfile: agencyProfileByIdSelector(state)(objectModel.agencyId),
                        assistingOfficers: objectModel.assistingPersonnel,
                        locationViews: [
                            {
                                entityLinks: objectModel.locationEntityLinks,
                            },
                        ],
                        eventDetail: objectModel.eventDetail,
                        reportAttributes: [
                            ...objectModel.weatherReportAttributes,
                            ...objectModel.eventStatisticsReportAttributes,
                        ],
                        reportNotifications: objectModel.reportNotifications,
                        nameReportLinks: nameReportLinksWhere({
                            reportId: currentReport.id,
                            linkType: LinkTypesEnum.REPORTING_PARTY_IN_REPORT,
                            contextType: EntityTypeEnum.REPORT.name,
                            contextId: currentReport.id,
                        }),
                        reportIdsToAlign: visibleArrestReportIds,
                    },
                    currentReportId
                )
            ).then((savedEventDetailBundles) => {
                forEach(savedEventDetailBundles, (eventDetailBundle) => {
                    const { eventDetail } = eventDetailBundle;

                    const reportId = eventDetail ? eventDetail.reportId : undefined;

                    if (reportId && eventDetail) {
                        dispatch(storeEventDetails([eventDetail]));
                        dispatch(replaceEventDetailCardBundle(eventDetailBundle));
                    }
                });
            });
        }
    };
}

import _, { groupBy, get } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

// NOTE: This form is currently unused! This code is being kept around in case we
// re-surface the original intended feature.
export default createFormModule({
    formName: formClientEnum.EDIT_ROLE_ABILITIES_MODULES,
    convertToFormModel: (modules, abilities, abilityRoleLinks) => {
        const abilityRoleLinksByModuleId = groupBy(abilityRoleLinks, (abilityRoleLink) =>
            get(abilities, [abilityRoleLink.abilityId, 'moduleId'])
        );

        return {
            modules: _(modules)
                .mapKeys('id')
                .mapValues(({ id }) => ({
                    id,
                    isActive:
                        // TODO remove this guaranteed 'true' value when we've
                        // sorted out UX for turning entire modules on/off
                        true || !!get(abilityRoleLinksByModuleId[id], 'length', 0),
                }))
                .value(),
        };
    },
    fieldViewModels: {
        type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
        key: 'modules',
        fields: buildFlatFormFieldViewModels(['id', 'isActive']),
    },
});

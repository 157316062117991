import _ from 'lodash';
import { RotationListBundle, RotationListArea } from '@mark43/cad-api';
import { createSelector } from 'reselect';
import createNormalizedModule, { ModuleShape } from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'cadRotationLists';

const cadRotationListsModule = createNormalizedModule<RotationListBundle>({
    type: NEXUS_STATE_PROP,
});

function mapCadServiceRotationListAreaToOption({ id, name }: RotationListArea) {
    return {
        value: id,
        display: name,
    };
}

function mapCadServiceRotationListToOption({
    id,
    name,
    rotationListAreas,
    rotationServiceTypeId,
}: RotationListBundle) {
    return {
        value: id,
        display: name,
        rotationListAreasOptions: rotationListAreas.map(mapCadServiceRotationListAreaToOption),
        rotationServiceTypeId,
    };
}

function mapCadServiceRotationListsToOption(
    cadServiceRotationLists: ModuleShape<RotationListBundle>
) {
    return _(cadServiceRotationLists)
        .map(mapCadServiceRotationListToOption)
        .sortBy('display')
        .value();
}

// ACTIONS
export const storeCadServiceRotationLists = cadRotationListsModule.actionCreators.storeEntities;

// REDUCER
export default cadRotationListsModule.reducerConfig;

export const cadRotationListSelector = cadRotationListsModule.selectors.entitiesSelector;

export const cadServiceRotationListsOptionsSelector = createSelector(
    cadRotationListSelector,
    mapCadServiceRotationListsToOption
);

import { PointLocation } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'pointLocations';

const pointLocationsModule = createNormalizedModule<PointLocation>({
    type: NEXUS_STATE_PROP,
});

export default pointLocationsModule.reducerConfig;
export const pointLocationsSelector = pointLocationsModule.selectors.entitiesSelector;

import { AttributeTypeEnum, FieldTypeEnum, FormEnum, SystemRuleEnum } from '@mark43/rms-api';
import _, {
    filter,
    flatten,
    forEach,
    get,
    isArray,
    isNumber,
    isString,
    size,
    values,
} from 'lodash';
import moment from 'moment';
import validationStrings from '~/client-common/core/strings/validationStrings';
import { fieldConfigurationByFieldNameSelector } from '~/client-common/core/domain/field-configurations/state/data';
import { fieldDetailsByFieldNameSelector } from '~/client-common/core/domain/field-details/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE,
    DISPLAY_ONLY_OFFENSE,
} from '~/client-common/core/enums/universal/fields';
import fieldStrings from '~/client-common/configs/fieldStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import store from '../../core/store';
import { storeBoxErrorMessages } from '../actions/boxActions';
import {
    getDynamicRulesForContext,
    getSystemRulesForContext,
    getDynamicRulesForContexts,
    getSystemRulesForContexts,
    rulesSelector,
} from '../selectors/ruleSelectors';
import errorMessagesByRule from '../configs/validationErrorMessagesByRule';
import { logError } from '../../core/logging';
import { currentReportSelector } from '../selectors/reportSelectors';

/**
 * To be called when validating report submission
 *
 * @param context - An object describing where we are running validation in order to match relevant rules to run
 * @param data - The data that submission validation runs on
 * @returns Array of messages easily displayable by our error modal
 */
export function validateSubmission(context, data) {
    const validationObj = validate(data, getRules(context));
    return _.map(validationObj.panelErrorMessages, (msg) => ({ message: msg }));
}

/**
 * To be used as a connectReduxForm() validate callback
 * Only works on dynamic rules right now
 * And only works for submitting one Redux form
 *
 * @param context - An object describing where we are running validation in order to match relevant rules to run
 * @param fieldNameMap - An object whose keys are the names of the fields in the form and values are the
                         client names of each field (see fields.ts)
 */
export function validateReduxForm(context, fieldNameMap) {
    return (data) => {
        const state = store.getState();
        const dataByClientName = replaceFieldNamesWithClientNames(data, fieldNameMap);
        const allRules = getRules(context);
        const dynamicRules = allRules.dynamicRules;
        const systemRules = allRules.systemRules;
        const fieldDetailsByFieldName = fieldDetailsByFieldNameSelector(state);
        const fieldConfigurationByFieldName = fieldConfigurationByFieldNameSelector(state);
        const fieldNamesByClientName = _.invert(fieldNameMap);
        const errorsFromDynamicRulesByField = validateDynamicRulesRedux(
            dynamicRules,
            dataByClientName,
            fieldNamesByClientName,
            context
        );
        const errorsFromFieldConfigurationsByField = validateFieldConfigurationsRedux(
            fieldDetailsByFieldName,
            fieldConfigurationByFieldName,
            dataByClientName,
            fieldNamesByClientName,
            context
        );
        const errorsFromSystemRulesByField = validateSystemRulesRedux(
            systemRules,
            data,
            fieldNamesByClientName
        );
        // Currently, we only display 1 error per field.  So, let's gather errors specifically in the order defined below, for display purposes.
        const errorsByField = {
            ...errorsFromSystemRulesByField,
            ...errorsFromDynamicRulesByField,
            ...errorsFromFieldConfigurationsByField,
        };
        let panelErrorMessages = [];
        if (!_.isEmpty(errorsByField)) {
            panelErrorMessages = values(errorsByField);
        }
        // can't dispatch sync here because redux form runs this during a state transition
        // ensure that `context` param has a `name` property.  The `name` property is a `boxEnum` id.
        if (context.name) {
            _.defer(() => store.dispatch(storeBoxErrorMessages(context, panelErrorMessages)));
        }
        return errorsByField;
    };
}

/**
 * Get system and dynamic rules from Redux state given a context object
 * Can also take an array of contexts
 */
function getRules(context) {
    const state = store.getState();
    const report = currentReportSelector(state);

    // Injecting report definition id into context!
    if (report) {
        if (_.isArray(context)) {
            context = _.map(context, (singleContext) => ({
                ...singleContext,
                reportDefinitionId: report.reportDefinitionId,
            }));
        } else {
            context = {
                ...context,
                reportDefinitionId: report.reportDefinitionId,
            };
        }
    }

    const isLegacy = get(report, 'isLegacyReport');
    const systemRulesForContext = _.isArray(context)
        ? getSystemRulesForContexts(state, context)
        : getSystemRulesForContext(state, context);
    const dynamicRulesForContext = _.isArray(context)
        ? getDynamicRulesForContexts(state, context)
        : getDynamicRulesForContext(state, context);
    const systemRules = isLegacy
        ? _.filter(systemRulesForContext, (rule) => rule.isLegacyActive)
        : systemRulesForContext;
    const dynamicRules = isLegacy
        ? _.filter(dynamicRulesForContext, (rule) => rule.isLegacyActive)
        : dynamicRulesForContext;
    return { systemRules, dynamicRules };
}

/**
 * Runs validation
 *
 * @param data - Data to validate
 * @param rules - The validation rules to run on the data
 * @returns object Whether validation passed and what panel error messages to show
 */
function validate(data, rules) {
    const state = store.getState();
    const rulesById = rulesSelector(state);
    const fieldConfigurationByFieldName = fieldConfigurationByFieldNameSelector(state);
    let { systemRules } = rules;
    const isMutualOffenseShownOnReport = applicationSettingsSelector(state)
        .RMS_NIBRS_NO_MUTUALLY_EXCLUSIVE_OFFENSES_FOR_SAME_VICTIM_SHOW_ON_REPORT_ENABLED;

    if (!isMutualOffenseShownOnReport) {
        systemRules = filter(systemRules, (systemRule) => {
            return (
                systemRule.systemRule !==
                SystemRuleEnum.NO_MUTUALLY_EXCLUSIVE_OFFENSES_FOR_SAME_VICTIM.name
            );
        });
    }
    // run rules
    const failedSystemRules = validateSystemRules(systemRules, data, state);
    const failedSystemRuleIds = filter(failedSystemRules, isNumber);
    const failedSystemRuleMessages = filter(failedSystemRules, isString);
    const failedSystemRuleMessageArrays = filter(failedSystemRules, isArray);

    // compile results
    const rulePanelErrorMessages = _(failedSystemRuleIds)
        .map(getPanelErrorsMapper(rulesById, fieldConfigurationByFieldName))
        .concat(failedSystemRuleMessages)
        .concat(flatten(failedSystemRuleMessageArrays))
        .compact()
        .uniq()
        .value();

    if (size(rulePanelErrorMessages) > 0) {
        return {
            valid: false,
            panelErrorMessages: rulePanelErrorMessages,
        };
    }

    return {
        valid: true,
        panelErrorMessages: [],
    };
}

function getPanelErrorsMapper(rulesById, fieldConfigurationByFieldName) {
    const state = store.getState();
    const formatFieldByName = formatFieldByNameSelector(state);
    const evidenceModuleName = formatFieldByName(DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE);
    const offenseDisplayName = formatFieldByName(DISPLAY_ONLY_OFFENSE);

    return (ruleId) => {
        const rule = rulesById[ruleId];

        // For system rules, always use the defined/hardcoded panel error messages.
        if (rule.systemRule) {
            const panelMessage = errorMessagesByRule[rule.systemRule].panel;
            return typeof panelMessage === 'function'
                ? panelMessage({ evidenceModuleName, offenseDisplayName })
                : panelMessage;
        }

        // For dynamic rules, we'll try our best to extract the failed field's label, so that we can
        // build a "FIELD_NAME is required" message.
        // Legacy "required" fields come in 2 forms: single fields that are required and attribute
        // types that are required.
        if (rule.fieldName) {
            const fieldDisplayName = get(
                fieldConfigurationByFieldName,
                `${rule.fieldName}.displayName`
            );
            // `displayName` is nullable; for safety, fall back here.
            return fieldDisplayName
                ? validationStrings.panel.fieldRequired(fieldDisplayName)
                : validationStrings.panel.generic;
        } else if (rule.attributeType) {
            // look up in `fieldStrings`.  If it doesn't appear in `fieldStrings`, then fall back to
            // getting the displayName from the `AttributeType`.
            return validationStrings.panel.fieldRequired(
                fieldStrings.attributeTypes[rule.attributeType] ||
                    get(AttributeTypeEnum, `${rule.attributeType}.displayName`)
            );
        }

        // Fall back, we couldn't build a meaningful, non-generic message.
        // UCR and Location forms have their own generic messages.
        if (rule.context.form === FormEnum.UCR.name) {
            return validationStrings.panel.ucrGeneric;
        }
        return validationStrings.panel.generic;
    };
}

/**
 * @param rules - validation rules to run that have implementations in code
 * @param data - data we're validating
 * @param reduxState - redux state needed by selectors in some system rules
 * @returns array of failed ids OR pre-formatted error messages
 */
function validateSystemRules(rules, data, reduxState) {
    return _(rules)
        .map((rule) => {
            if (!rule.validate) {
                logError(`System rule ${rule.systemRule} has no implementation on the client`);
                return null;
            }
            const fieldErrorMsg = errorMessagesByRule[rule.systemRule].field;
            const validationOutput = rule.validate(data, fieldErrorMsg, reduxState);
            if (validationOutput === false) {
                return rule.id;
            } else if (validationOutput === true) {
                return null;
            } else {
                return validationOutput;
            }
        })
        .compact()
        .value();
}

const numberRegex = /^[+-]?(\d*\.)?\d+$/;
const integerRegex = /^\d+$/;

/**
 *
 * Returns an object of {fieldName: errorMessage} that redux-form uses to display field errors
 */
function validateDynamicRulesRedux(rules, fieldsByName, fieldNamesByClientName) {
    const failedFields = {};
    _.forEach(rules, (rule) => {
        // clientName is the FIELD_NAME of the rule
        const clientName = rule.fieldName || rule.attributeType;
        const value = fieldsByName[clientName];
        // fieldName is the name of the property on the formData
        const fieldName = fieldNamesByClientName[clientName];
        let ruleFailed = false;
        // fieldsByName is a map of FIELD_NAME to data value
        // instead of propertyName to data value
        if (_.has(fieldsByName, clientName)) {
            // don't want to just check if !value because sometimes 0 is a valid value (such as OTHER for recovering person in item panel)
            if (value === null || value === undefined || value === '') {
                ruleFailed = true;
            }
        }

        if (ruleFailed) {
            const state = store.getState();
            const formatFieldByName = formatFieldByNameSelector(state);
            failedFields[fieldName] = `${formatFieldByName(clientName)} is ${
                validationStrings.field.required
            }`;
        }
    });
    return failedFields;
}

/**
 * Function is responsible for validating system rules.  This is done by calling each system rule's `validate` function.
 * @param {Object[]} systemRules             An array of applicable system rules, for this form context.
 * @param {Object}   reduxFormData           An object representing the `redux-form` data.
 * @param {Object}   fieldNamesByClientName  An object representing the mapping between `redux-form` field names to `field` ids in `fields.ts`.
 */
function validateSystemRulesRedux(systemRules, reduxFormData, fieldNamesByClientName) {
    let failedFields = {};
    _.forEach(systemRules, ({ fieldName, systemRule, validate }) => {
        if (!validate) {
            logError(`System rule ${systemRule} has no implementation on the client.`);
            failedFields = null;
            return;
        }
        const isValid = validate(reduxFormData, fieldNamesByClientName);
        if (!isValid) {
            const dataFieldName = _.get(fieldNamesByClientName, fieldName);
            _.set(failedFields, dataFieldName, _.get(errorMessagesByRule[systemRule], 'field'));
        }
    });
    return failedFields;
}

function validateFieldConfigurationsRedux(
    fieldDetailsByFieldName,
    fieldConfigurationByFieldName,
    fieldsByName,
    fieldNamesByClientName
) {
    const failedFields = {};

    // enforce min/max values
    forEach(fieldConfigurationByFieldName, (fieldConfiguration) => {
        const fieldConfigurationFieldName = fieldConfiguration.fieldName;
        const fieldDetail = fieldDetailsByFieldName[fieldConfigurationFieldName];
        const value = fieldsByName[fieldConfigurationFieldName];
        const fieldName = fieldNamesByClientName[fieldConfigurationFieldName];
        let configFailed = false;
        let errorMessage = '';

        switch (fieldDetail.fieldType) {
            case FieldTypeEnum.STRING.name:
                if (fieldConfiguration.max) {
                    if (!!value && value.length > _.parseInt(fieldConfiguration.max)) {
                        configFailed = true;
                        errorMessage = validationStrings.max[fieldDetail.fieldType](
                            fieldConfiguration.max
                        );
                    }
                }
                if (fieldConfiguration.min) {
                    if (!!value && value.length < _.parseInt(fieldConfiguration.min)) {
                        configFailed = true;
                        errorMessage = validationStrings.min[fieldDetail.fieldType](
                            fieldConfiguration.min
                        );
                    }
                }
                if (
                    fieldConfiguration.max &&
                    fieldConfiguration.min &&
                    _.parseInt(fieldConfiguration.min) === _.parseInt(fieldConfiguration.max)
                ) {
                    if (!!value && value.length !== _.parseInt(fieldConfiguration.min)) {
                        configFailed = true;
                        errorMessage = validationStrings.exactLength[fieldDetail.fieldType](
                            fieldConfiguration.min
                        );
                    }
                }
                break;
            case FieldTypeEnum.NUMBER.name:
                // check if is a number
                if (!!value && !numberRegex.test(value)) {
                    configFailed = true;
                    errorMessage = validationStrings.fieldType[fieldDetail.fieldType];
                }
                if (fieldConfiguration.max) {
                    if (!!value && _.parseInt(value) > _.parseInt(fieldConfiguration.max)) {
                        configFailed = true;
                        errorMessage = validationStrings.max[fieldDetail.fieldType](
                            fieldConfiguration.max
                        );
                    }
                }
                if (fieldConfiguration.min) {
                    if (!!value && _.parseInt(value) < _.parseInt(fieldConfiguration.min)) {
                        configFailed = true;
                        errorMessage = validationStrings.min[fieldDetail.fieldType](
                            fieldConfiguration.min
                        );
                    }
                }
                break;
            case FieldTypeEnum.INTEGER.name:
                //  check if is integer
                if (!!value && !integerRegex.test(value)) {
                    configFailed = true;
                    errorMessage = validationStrings.fieldType[fieldDetail.fieldType];
                }
                if (fieldConfiguration.max) {
                    if (!!value && _.parseInt(value) > _.parseInt(fieldConfiguration.max)) {
                        configFailed = true;
                        errorMessage = validationStrings.max[fieldDetail.fieldType](
                            fieldConfiguration.max
                        );
                    }
                } else if (fieldConfiguration.min) {
                    if (!!value && _.parseInt(value) < _.parseInt(fieldConfiguration.min)) {
                        configFailed = true;
                        errorMessage = validationStrings.min[fieldDetail.fieldType](
                            fieldConfiguration.min
                        );
                    }
                }
                break;
            case FieldTypeEnum.DATE.name:
            case FieldTypeEnum.DATETIME.name:
                const now = moment();

                if (fieldConfiguration.max) {
                    const maxDate =
                        fieldConfiguration.max === 'NOW' ? now : moment(fieldConfiguration.max);
                    if (!!value && moment(value).isAfter(maxDate, 'minute')) {
                        configFailed = true;
                        errorMessage = validationStrings.max[fieldDetail.fieldType](
                            fieldConfiguration.max
                        );
                    }
                }
                if (fieldConfiguration.min) {
                    const minDate =
                        fieldConfiguration.min === 'NOW' ? now : moment(fieldConfiguration.min);
                    if (!!value && moment(value).isBefore(minDate, 'minute')) {
                        configFailed = true;
                        errorMessage = validationStrings.min[fieldDetail.fieldType](
                            fieldConfiguration.min
                        );
                    }
                }
                break;
            default:
                break;
        }
        if (configFailed) {
            failedFields[fieldName] = errorMessage;
        }
    });
    return failedFields;
}

function replaceFieldNamesWithClientNames(data, fieldNameMap) {
    return _.mapKeys(data, (value, fieldName) => fieldNameMap[fieldName]);
}

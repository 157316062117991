const sessionHistoryLocationsSelector = (state) => state.sessionHistory.locations;

/*
 * Different from a goBack method because it doesn't rely on the browser's back
 * This goes forward, to the previous Url
 * If there are no previous Urls that are stored it goes to a default Url
 * that must be provided
 */
export function returnToPreviousRoute(router, defaultRoute) {
    return (dispatch, getState) => {
        const state = getState();
        const locations = sessionHistoryLocationsSelector(state);
        // At index 0 will be the route that's active
        // At index 1 will be the previous route
        if (locations.length > 1) {
            router.push(locations[1]);
        } else {
            router.push(defaultRoute);
        }
    };
}

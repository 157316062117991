import {
    RegionalGroupEnum,
    CodeTypeSourceEnum,
    CodeTypeCategoryEnumType,
    CodeTypeSourceEnumType,
} from '@mark43/rms-api';
import _, { find, first, get, map } from 'lodash';
import { createSelector } from 'reselect';
import { attributeCodesWhereSelector } from '../data';
import { codesByCodeTypeCategoryAndCodeTypeSourceSelector } from '../../../codes/state/data';
import { departmentProfilesSelector } from '../../../department-profiles/state/data';

export const codeForCodeTypeSourceAttributeIdAndCodeTypeSelector = createSelector(
    attributeCodesWhereSelector,
    codesByCodeTypeCategoryAndCodeTypeSourceSelector,
    (attributeCodesWhere, codesByCodeTypeCategoryAndCodeTypeSource) => ({
        attributeId,
        codeTypeCategory,
        codeTypeSource,
    }: {
        attributeId: number;
        codeTypeCategory: CodeTypeCategoryEnumType;
        codeTypeSource: CodeTypeSourceEnumType;
    }) => {
        const codeIds = map(attributeCodesWhere({ attributeId }), 'codeId');
        const codesByCodeTypeCategory = codesByCodeTypeCategoryAndCodeTypeSource(codeTypeSource);
        const codesForAttributeId = _(codeIds)
            .map((codeId) => find(codesByCodeTypeCategory[codeTypeCategory], { id: codeId }))
            .compact()
            .value();
        // get the first code
        return get(first(codesForAttributeId), 'code');
    }
);

export const regionalSourceSelector = createSelector(
    departmentProfilesSelector,
    (departmentProfiles) => (source: 'nibrsSource' | 'ucrSource', departmentId: number) => {
        const departmentProfile = !!departmentId ? departmentProfiles[departmentId] : undefined;
        const regionalGroup = !!departmentProfile
            ? RegionalGroupEnum[departmentProfile?.nibrsRegionalGroup]
            : undefined;
        return get(regionalGroup, source);
    }
);

const nibrsSourceSelector = createSelector(
    regionalSourceSelector,
    (regionalSource) => (departmentId: number) => regionalSource('nibrsSource', departmentId)
);

export const nibrsCodeForAttributeIdCodeTypeCategoryAndDepartmentSelector = createSelector(
    nibrsSourceSelector,
    codeForCodeTypeSourceAttributeIdAndCodeTypeSelector,
    (nibrsSource, codeForCodeTypeSourceAttributeIdAndCodeType) => (
        attributeId: number,
        codeTypeCategory: CodeTypeCategoryEnumType,
        departmentId: number
    ) => {
        const codeTypeSource = nibrsSource(departmentId);
        return codeTypeSource
            ? codeForCodeTypeSourceAttributeIdAndCodeType({
                  attributeId,
                  codeTypeCategory,
                  codeTypeSource,
              })
            : undefined;
    }
);

/**
 * Get codes for an attributeId and codeTypeCategory. This is set up to use values from NIBRS if present
 * and then fall back to regional NIBRS source for the department. If states have code values that don't line
 * up with federal NIBRS values for NIBRS_PROPERTY_LOSS they should be mapped to federal as well due to existing hard
 * coded values. NIBRS will need to added to the codeTypeSource for that regional group in AttributeResource.java as well.
 */
export const federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategorySelector = createSelector(
    nibrsSourceSelector,
    codeForCodeTypeSourceAttributeIdAndCodeTypeSelector,
    (nibrsSource, codeForCodeTypeSourceAttributeIdAndCodeType) => (
        attributeId: number,
        codeTypeCategory: CodeTypeCategoryEnumType,
        currentUserDepartmentId: number
    ) =>
        codeForCodeTypeSourceAttributeIdAndCodeType({
            attributeId,
            codeTypeCategory,
            codeTypeSource: CodeTypeSourceEnum.NIBRS.name,
        }) ||
        codeForCodeTypeSourceAttributeIdAndCodeType({
            attributeId,
            codeTypeCategory,
            codeTypeSource: nibrsSource(currentUserDepartmentId),
        })
);

import React from 'react';
import ReactReduxForm from '../../../forms/components/ReactReduxForm';
import { RRFTextArea } from '../../../forms/components/TextArea';
import createReleaseForm from '../state/forms/createReleaseForm';
import createAttributeWithOtherFields from '../../../forms/components/createAttributeWithOtherFields';

const ExportReleasedToAttributeFields = createAttributeWithOtherFields({
    attributeField: 'releasedToAttrId',
    attributeOtherField: 'releasedToOther',
    attributeType: 'EXPORT_RELEASED_TO',
    width: '100%',
});

export default function CreateReleaseForm() {
    return (
        <ReactReduxForm {...createReleaseForm}>
            <ExportReleasedToAttributeFields />
            <RRFTextArea path="additionalInformation" rows={11} />
        </ReactReduxForm>
    );
}

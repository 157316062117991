const BASE_PATH = 'attributes/codes/type_links';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Attribute Code Type Links Resource',
        methods: {
            loadAttributeCodesTypeLinks() {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}`,
                });
            },

            loadAttributeCodesTypeLinksByAttributeType(attributeType) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/${attributeType}`,
                });
            },

            setAttributeCodeTypeLinksByAttributeType(attributeType, data) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${attributeType}`,
                    data,
                });
            },
        },
    });
}

/**
 * This is temporarily a function to avoid dependency
 *  problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    Attribute,
    AttributeTypeEnum,
    EntityTypeEnum,
    LinkTypesEnum,
    RefContextEnum,
    RoleTypeEnum,
} from '@mark43/rms-api';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { taskStatusOptionsForDisplay } from '~/client-common/helpers/taskStatusHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import { SidePanelSection } from '../../../../legacy-redux/components/core/SidePanel';
import InlineAttachmentsUploader from '../../../attachments/core/components/InlineAttachmentsUploader';
import { UNPERSISTED_ENTITY_ID } from '../../../attachments/core/configuration';
import { Button } from '../../../core/components/Button';
import Row from '../../../core/components/Row';
import {
    currentUserHasAbilitySelector,
    currentUserProfileSelector,
} from '../../../core/current-user/state/ui';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTRoleSelect } from '../../../core/forms/components/selects/RoleSelect';
import { ArbiterMFTDatePicker } from '../../../core/forms/components/DatePicker';
import { ArbiterMFTTextArea } from '../../../core/forms/components/TextArea';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import testIds from '../../../../core/testIds';
import taskForm from '../forms/taskForm';

const strings = componentStrings.tasks.core.TaskSidePanel;

const { NORMAL, USER } = RoleTypeEnum;

const AssignToMyselfButton = styled(Button)`
    margin-top: 20px;
    flex-shrink: 0;
`;

const FlexRow = styled.div`
    display: flex;
    clear: left;
    width: 100%;
`;

const TaskForm: React.FC<{
    onStatusChange?: (attributeId: number) => void;
    disabled?: boolean;
}> = ({ onStatusChange, disabled }) => {
    const { getForm } = useFormGetter();
    const currentUserProfile = useSelector(currentUserProfileSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const hasTaskEditTitleDescriptionAbility = currentUserHasAbility(
        abilitiesEnum.CORE.EDIT_TASK_TITLE_AND_DESCRIPTION
    );
    const hasTaskEditTaskAbility = currentUserHasAbility(abilitiesEnum.CORE.EDIT_TASKS);

    const onAssignToMyself = useCallback(() => {
        const form = getForm(RefContextEnum.FORM_TASK.name);
        if (form) {
            form.set('assigneeRoleId', currentUserProfile?.userRoleId);
        }
    }, [currentUserProfile?.userRoleId, getForm]);

    return (
        <ArbiterForm
            context={RefContextEnum.FORM_TASK.name}
            {...taskForm}
            render={(form) => {
                const formModel = form.get();
                const entityId = formModel?.id ?? UNPERSISTED_ENTITY_ID;
                const statusAttrId = formModel?.statusAttrId;
                const isNewTask = entityId === UNPERSISTED_ENTITY_ID;
                const isTitleDescriptionDisabled =
                    !isNewTask && !hasTaskEditTitleDescriptionAbility;
                const isDisabled = !hasTaskEditTaskAbility || disabled;

                return (
                    <>
                        <Row>
                            <ArbiterMFTText
                                path="title"
                                length="lg"
                                disabled={isTitleDescriptionDisabled || isDisabled}
                            />
                            <ArbiterMFTAttributeSelect
                                path="typeAttrId"
                                length="md"
                                attributeType={AttributeTypeEnum.TASK_TYPE.name}
                                disabled={isDisabled}
                            />
                            <ArbiterMFTAttributeSelect
                                path="priorityAttrId"
                                length="md"
                                attributeType={AttributeTypeEnum.TASK_PRIORITY.name}
                                disabled={isDisabled}
                            />
                            <ArbiterMFTAttributeSelect
                                path="statusAttrId"
                                length="md"
                                filterOptions={taskStatusOptionsForDisplay}
                                attributeType={AttributeTypeEnum.TASK_STATUS.name}
                                onChange={onStatusChange}
                                onAttributeLoadSuccess={(attributes: Attribute[]) => {
                                    const pendingTaskStatusAttribute = attributes.filter(
                                        (attribute) => {
                                            const { attributeType, parentAttributeId, endDateUtc } =
                                                attribute;
                                            return (
                                                attributeType ===
                                                    AttributeTypeEnum.TASK_STATUS.name &&
                                                parentAttributeId ===
                                                    globalAttributes.taskStatusGlobal.pending &&
                                                !endDateUtc
                                            );
                                        }
                                    );
                                    if (
                                        entityId === UNPERSISTED_ENTITY_ID &&
                                        pendingTaskStatusAttribute.length > 0 &&
                                        !statusAttrId
                                    ) {
                                        form.set('statusAttrId', pendingTaskStatusAttribute[0].id);
                                    }
                                }}
                                disabled={isDisabled}
                            />
                            <FlexRow>
                                <ArbiterMFTRoleSelect
                                    path="assigneeRoleId"
                                    length="lg"
                                    roleTypes={[NORMAL.name, USER.name]}
                                    disabled={isDisabled}
                                />
                                <FeatureFlagged flag="RMS_TASK_ENHANCEMENTS_ENABLED">
                                    <AssignToMyselfButton
                                        isTextTransformNone
                                        variant="outline"
                                        testId={testIds.ASSIGN_TO_ME_BUTTON}
                                        onClick={onAssignToMyself}
                                        disabled={isDisabled}
                                    >
                                        {strings.assignMyself}
                                    </AssignToMyselfButton>
                                </FeatureFlagged>
                            </FlexRow>
                            <ArbiterMFTDatePicker
                                path="dueDateUtc"
                                disabled={isDisabled}
                                includeTime
                            />
                            <ArbiterMFTTextArea
                                path="description"
                                rows={3}
                                disabled={isTitleDescriptionDisabled || isDisabled}
                            />
                        </Row>
                        <FeatureFlagged
                            flag="RMS_TASK_ENTITY_LINKS_ENABLED"
                            fallback={
                                <InlineAttachmentsUploader
                                    withAttachmentsSidePanel={true}
                                    entityId={entityId}
                                    entityType={EntityTypeEnum.TASK.name}
                                    linkType={LinkTypesEnum.TASK_ATTACHMENT}
                                    attachmentLinkType={LinkTypesEnum.TASK_ATTACHMENT}
                                    disabled={isDisabled}
                                />
                            }
                        >
                            <SidePanelSection title={strings.attachments.toUpperCase()}>
                                <InlineAttachmentsUploader
                                    withAttachmentsSidePanel={true}
                                    entityId={entityId}
                                    entityType={EntityTypeEnum.TASK.name}
                                    linkType={LinkTypesEnum.TASK_ATTACHMENT}
                                    attachmentLinkType={LinkTypesEnum.TASK_ATTACHMENT}
                                    disabled={isDisabled}
                                />
                            </SidePanelSection>
                        </FeatureFlagged>
                    </>
                );
            }}
        />
    );
};

export default TaskForm;

import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { normalizeTimeString } from '~/client-common/core/dates/utils/dateHelpers';
import { timeFormat, timePlaceholder } from '../../../../legacy-redux/configs/formsConfig';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../core/testIds';
import Text from './Text';
import FormElement from './FormElement';

const { connectRRFInput } = reactReduxFormHelpers;

const resolveDateTimeStrings = (_timeString) => {
    const timeString = normalizeTimeString(_timeString);
    if (!_timeString) {
        return '';
    }
    const timeMoment = moment(timeString, timeFormat);
    return timeMoment.format(timeFormat);
};

// This component expects a value of the form: "10:00". It is an uncontrolled component until
// the blur when it gets converted to moment for autocomplete and then passed up
// as a time string
class TimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeTextValue: this.props.value,
        };
    }

    // we don't worry about updating the backing value until they blur away!
    onTimeTextChange(val) {
        this.setState({
            timeTextValue: val,
        });
    }

    onTimeBlur() {
        // this state means we should handle our
        // on change callback, and assume the user is
        // done filling out the date field for now
        const time = resolveDateTimeStrings(this.state.timeTextValue);
        this.setState({
            timeTextValue: time,
        });
        this.props.onChange(time);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.state = {
                timeTextValue: nextProps.value,
            };
        }
    }

    render() {
        return (
            <FormElement testId={this.props.testId} {...this.props}>
                <Text
                    error={this.props.error}
                    touched={this.props.touched}
                    placeholder={timePlaceholder}
                    value={this.state.timeTextValue}
                    onBlur={this.onTimeBlur.bind(this)}
                    onChange={this.onTimeTextChange.bind(this)}
                    textInputOnly={true}
                    disabled={this.props.disabled}
                    testId={testIds.DATE_PICKER_TIME_INPUT}
                />
            </FormElement>
        );
    }
}

TimePicker.propTypes = {
    value: PropTypes.string,
    touched: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

TimePicker.defaultProps = {
    onFocus: _.noop,
    onBlur: _.noop,
};

TimePicker.displayName = 'TimePicker';

export const RRFTimePicker = connectRRFInput(TimePicker);

import { map } from 'lodash';
import { storeTasks } from '~/client-common/core/domain/tasks/state/data';
import caseResource from '../../../core/resources/caseResource';
import { updateOrphanContents } from '../../../core/state/data/orphanedContents';

const CREATE_TASK_START = 'task-list/CREATE_TASK_START';
const CREATE_TASK_SUCCESS = 'task-list/CREATE_TASK_SUCCESS';
const CREATE_TASK_FAILURE = 'task-list/CREATE_TASK_FAILURE';
const UPDATE_TASK_START = 'task-list/UPDATE_TASK_START';
const UPDATE_TASK_SUCCESS = 'task-list/UPDATE_TASK_SUCCESS';
const UPDATE_TASK_FAILURE = 'task-list/UPDATE_TASK_FAILURE';
const DELETE_TASK_START = 'task-list/DELETE_TASK_START';
const DELETE_TASK_SUCCESS = 'task-list/DELETE_TASK_SUCCESS';
const DELETE_TASK_FAILURE = 'task-list/DELETE_TASK_FAILURE';

function createTaskStart() {
    return {
        type: CREATE_TASK_START,
    };
}

function createTaskSuccess(tasks) {
    return {
        type: CREATE_TASK_SUCCESS,
        payload: tasks,
    };
}

function createTaskFailure(message) {
    return {
        type: CREATE_TASK_FAILURE,
        payload: message,
    };
}

function updateTaskStart() {
    return {
        type: UPDATE_TASK_START,
    };
}

function updateTaskSuccess(tasks) {
    return {
        type: UPDATE_TASK_SUCCESS,
        payload: tasks,
    };
}

function updateTaskFailure(message) {
    return {
        type: UPDATE_TASK_FAILURE,
        payload: message,
    };
}

function deleteTaskStart() {
    return {
        type: DELETE_TASK_START,
    };
}

function deleteTaskSuccess(tasks) {
    return {
        type: DELETE_TASK_SUCCESS,
        payload: tasks,
    };
}

function deleteTaskFailure(message) {
    return {
        type: DELETE_TASK_FAILURE,
        payload: message,
    };
}

export function createTask(caseId, task) {
    return (dispatch) => {
        dispatch(createTaskStart());
        return caseResource
            .createTaskForCase(caseId, task)
            .then((tasks) => {
                const caseTaskViews = map(tasks, (task) => ({ ...task, caseId }));
                dispatch(createTaskSuccess(caseTaskViews));
                dispatch(storeTasks(caseTaskViews));
                dispatch(updateOrphanContents());
                return tasks;
            })
            .catch((err) => {
                dispatch(createTaskFailure(err.message));
                throw err;
            });
    };
}

export function updateTask(caseId, task) {
    return (dispatch) => {
        dispatch(updateTaskStart());
        return caseResource
            .updateTaskForCase(caseId, task.id, task)
            .then((tasks) => {
                const caseTaskViews = map(tasks, (task) => ({ ...task, caseId }));
                dispatch(updateTaskSuccess(caseTaskViews));
                dispatch(storeTasks(caseTaskViews));
                dispatch(updateOrphanContents());
                return tasks;
            })
            .catch((err) => {
                dispatch(updateTaskFailure(err.message));
                throw err;
            });
    };
}

export function deleteTask(caseId, taskId) {
    return (dispatch) => {
        dispatch(deleteTaskStart());
        return caseResource
            .deleteTaskForCase(caseId, taskId)
            .then(() => {
                dispatch(deleteTaskSuccess());
                return taskId;
            })
            .catch((err) => {
                dispatch(deleteTaskFailure(err.message));
                throw err;
            });
    };
}

const SET_CURRENT_PARENT_USAGE_LOG = 'usage-logs/SET_CURRENT_PARENT_USAGE_LOG';

/**
 * Set the current parent values that will be used populate later
 * usage log's parentAction, parentPrimaryEntityId, etc values
 * on UsageLogCreation object (https://github.com/mark43/mark43/blob/develop/usage-logs-client/src/main/java/com/mark43/usagelogs/client/model/UsageLogCreation.java)
 * @param {Object} parentUsageLog
 */
export function setCurrentParentUsageLog(parentUsageLog) {
    return {
        type: SET_CURRENT_PARENT_USAGE_LOG,
        payload: parentUsageLog,
    };
}

export const currentParentUsageLogSelector = (state) => state.ui.usageLogs.currentParentUsageLog;

export default function usageLogsUiReducer(state = {}, action) {
    switch (action.type) {
        case SET_CURRENT_PARENT_USAGE_LOG:
            return {
                currentParentUsageLog: action.payload,
            };
        default:
            return state;
    }
}

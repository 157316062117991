import { EntityTypeEnum } from '@mark43/rms-api';
import {
    chain,
    compact,
    concat,
    find,
    first,
    get,
    includes,
    map,
    orderBy,
    partition,
} from 'lodash';

import { createSelector } from 'reselect';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import { nameReportLinksWhereSelector } from '../../../name-report-links/state/data';
import { useOfForceSubjectsByReportIdSelector } from '../data';
import { getViewModelProperties } from '../../../../../helpers/viewModelHelpers';

import { personProfileViewModelByIdSelector } from '../../../person-profiles/state/ui';
import { reportCardTitleByReportIdAndCardIdSelector } from '../../../report-definitions/state/data';
import reportCardEnum from '../../../../enums/universal/reportCardEnum';

/**
 * Sorts `UseOfForceSubject`s by:
 * - Split all `UseOfForceSubject`s into two sets: models that have a subject and models that do not.
 * - Prioritize models that have subjects first.  Sort these by their
 *   `NameReportLink#linkTypeSequenceNumber` and break ties (there shouldn't be any...) by `contextId`.
 * - Concatenate models without subjects, sorted by `UseOfForceSubject#id`.
 */
const sortUseOfForceSubjects = ({ useOfForceSubjects = [], nameReportLinks = [] }) => {
    const sortedNameReportLinkContextIds = chain(nameReportLinks)
        .orderBy(['linkTypeSequenceNumber', 'contextId'])
        .map('contextId')
        .value();
    const [
        useOfForceSubjectsWithSubject,
        useOfForceSubjectsWithoutSubject,
    ] = partition(useOfForceSubjects, ({ id }) => includes(sortedNameReportLinkContextIds, id));

    // `compact` here, because we're using `NameReportLink`s from state as the source of truth & *not* the
    // `UseOfForceSubject`s -- because we do not remove deleted `NameReportLink`s from state, we have to
    // `compact` out any NRLs that exist in state from deleted `UseOfForceSubject` cards.
    const sortedUseOfForceSubjectsWithSubject = compact(
        map(sortedNameReportLinkContextIds, (sortedNameReportLinkContextId) =>
            find(useOfForceSubjectsWithSubject, { id: sortedNameReportLinkContextId })
        )
    );
    const sortedUseOfForceSubjectsWithoutSubject = orderBy(useOfForceSubjectsWithoutSubject, [
        'id',
    ]);
    return concat(sortedUseOfForceSubjectsWithSubject, sortedUseOfForceSubjectsWithoutSubject);
};

const buildUseOfForceSubjectTitle = ({
    nameReportLink = {},
    personProfileViewModelById,
    title,
}) => {
    const nameId = get(nameReportLink, 'nameId');
    const personProfileViewModel = personProfileViewModelById(nameId);
    const { fullName } = getViewModelProperties(personProfileViewModel);
    return joinTruthyValues([title, fullName], ' - ');
};

export const sortedUseOfForceSubjectsForReportIdSelector = createSelector(
    nameReportLinksWhereSelector,
    useOfForceSubjectsByReportIdSelector,
    (nameReportLinksWhere, useOfForceSubjectsByReportId) => (reportId) => {
        const useOfForceSubjects = useOfForceSubjectsByReportId(reportId);
        const useOfForceSubjectNameReportLinks = nameReportLinksWhere({
            reportId,
            contextType: EntityTypeEnum.USE_OF_FORCE_SUBJECT.name,
        });
        return sortUseOfForceSubjects({
            useOfForceSubjects,
            nameReportLinks: useOfForceSubjectNameReportLinks,
        });
    }
);

export const formatUseOfForceSubjectTitleByUseOfForceSubjectIdSelector = createSelector(
    nameReportLinksWhereSelector,
    personProfileViewModelByIdSelector,
    reportCardTitleByReportIdAndCardIdSelector,
    (nameReportLinksWhere, personProfileViewModelById, reportCardTitleByReportIdAndCardId) => (
        useOfForceSubjectId,
        reportId
    ) => {
        const nameReportLink = first(
            nameReportLinksWhere({
                contextId: useOfForceSubjectId,
                contextType: EntityTypeEnum.USE_OF_FORCE_SUBJECT.name,
            })
        );
        const title = reportCardTitleByReportIdAndCardId(
            reportId,
            reportCardEnum.USE_OF_FORCE_SUBJECT.id
        );
        return buildUseOfForceSubjectTitle({ nameReportLink, personProfileViewModelById, title });
    }
);

export const sortedUseOfForceSubjectTitlesForReportIdSelector = createSelector(
    sortedUseOfForceSubjectsForReportIdSelector,
    formatUseOfForceSubjectTitleByUseOfForceSubjectIdSelector,
    (sortedUseOfForceSubjectsForReportId, formatUseOfForceSubjectTitleByUseOfForceSubjectId) => (
        reportId
    ) => {
        const sortedUseOfForceSubjects = sortedUseOfForceSubjectsForReportId(reportId);
        return map(sortedUseOfForceSubjects, ({ id }) => {
            return {
                useOfForceSubjectId: id,
                title: formatUseOfForceSubjectTitleByUseOfForceSubjectId(id, reportId),
            };
        });
    }
);

import { UiConfigurationMetadataMap, createMFTDoEffectValue } from 'dragon-react';

function formatMessageWithErrorEnhancements(label: string, selector: string, message: string) {
    // see interpolateMessage() for how this syntax works
    return `{{${label}|${selector}}} - ${message}`;
}

function formatMessageWithoutErrorEnhancements(label: string, message: string) {
    return `${label} - ${message}`;
}

/**
 * Compute the final list of error messages for markformythree form state, based on the `errors` inside `doValue`.
 */
export function reconcileFormErrors(
    doValue: ReturnType<typeof createMFTDoEffectValue>,
    uiConfigurationMetadataMap: UiConfigurationMetadataMap,
    errorEnhancementsEnabled: boolean
): string[] {
    const formErrors: string[] = [];

    for (const [path, { errors }] of Object.entries(doValue)) {
        if (errors) {
            // the id of the field configuration is at the end of the path
            const uiConfigurationId = path.split('.').pop();
            if (uiConfigurationId) {
                const label = uiConfigurationMetadataMap[uiConfigurationId]?.configuration.ui.label;
                if (label) {
                    // see scrollToFieldOrSectionWithinCard()
                    const selector = `[data-path="DRAGON${path}"]`;
                    formErrors.push(
                        ...Object.values(errors).map((message) => {
                            return errorEnhancementsEnabled
                                ? formatMessageWithErrorEnhancements(label, selector, message)
                                : formatMessageWithoutErrorEnhancements(label, message);
                        })
                    );
                }
            }
        }
    }

    return formErrors;
}

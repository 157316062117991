import { UcrSummaryOffensePartEnum } from '@mark43/rms-api';

import React from 'react';
import { getDropdownOptionsFromEnum } from '~/client-common/helpers/dropdownOptionHelpers';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const ucrGroupOptions = getDropdownOptionsFromEnum(UcrSummaryOffensePartEnum);

/**
 * Select dropdown containing all UCR groups.
 * @param {Object} props Same props as `Select`.
 */
function UcrGroupSelect({ ...otherProps }) {
    return <Select options={ucrGroupOptions} {...otherProps} />;
}

export const RRFUcrGroupSelect = connectRRFInput(UcrGroupSelect);

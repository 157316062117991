import { MiniUserView, UserProfile, ElasticUser } from '@mark43/rms-api';
import { joinTruthyValues, getNameInitial } from '../../../../helpers/stringHelpers';

/**
 * @param [preferBadge=true] true: take badge if it's there. false: take cadId if it's there
 *   name to its initial only.
 */
export function getBadgeOrCadId(badge?: string, cadId?: string, preferBadge = true) {
    if (preferBadge) {
        return !!badge ? badge : cadId;
    } else {
        return !!cadId ? cadId : badge;
    }
}

/**
 * @param  [firstNameAsInitial=false] Whether to shorten the first
 *   name to its initial only.
 */
export function formatName(
    { first, last, sfx }: Partial<MiniUserView> = {},
    firstNameAsInitial = false
) {
    return joinTruthyValues(
        [firstNameAsInitial && first ? getNameInitial(first, true) : first, last, sfx],
        ' '
    );
}

/**
 * @param [firstNameAsInitial=false] Whether to shorten the first
 *   name to its initial only.
 */
export function formatFullName(
    { first, last, sfx, badge, cadId }: Partial<MiniUserView> = {},
    firstNameAsInitial = false,
    useCadIdIfNoBadge = false
) {
    return joinTruthyValues(
        [
            formatName({ first, last, sfx }, firstNameAsInitial),
            formatIdNumber({ badge, cadId }, useCadIdIfNoBadge),
        ],
        ' '
    );
}

export function formatNameShortHand({ last, badge }: Partial<MiniUserView> = {}) {
    if (!last) {
        return '';
    }
    return joinTruthyValues([last, badge ? `#${badge}` : ''], ' ');
}

export function formatIdNumber(
    { badge, cadId }: Partial<MiniUserView> = {},
    useCadIdIfNoBadge = false
) {
    const idNumber = useCadIdIfNoBadge ? getBadgeOrCadId(badge, cadId) : badge;
    return idNumber ? `#${idNumber}` : '';
}

export function formatInitialsAndIdNumber({
    first,
    last,
    badge,
    cadId,
}: Partial<MiniUserView> = {}) {
    if (!last) {
        return '';
    }
    return joinTruthyValues(
        [getNameInitial(first), getNameInitial(last), '-', getBadgeOrCadId(badge, cadId)],
        ''
    );
}

export function allMiniUserFormats(miniUser: MiniUserView) {
    return {
        fullName: formatFullName(miniUser),
        fullNameWithoutBadge: formatName(miniUser),
        fullNameWithFirstInitial: formatFullName(miniUser, true),
        lastNameAndBadge: formatNameShortHand(miniUser),
        fullNameWithFirstInitialAndIdNumber: formatFullName(miniUser, true, true),
        initialsAndIdNumber: formatInitialsAndIdNumber(miniUser),
        idNumber: formatIdNumber(miniUser),
    };
}

export const allMiniUserFormatsEnum = {
    FULL_NAME: (miniUser: MiniUserView) => formatFullName(miniUser),
    FULL_NAME_WITHOUT_BADGE: (miniUser: MiniUserView) => formatName(miniUser),
    FULL_NAME_WITH_FIRST_INITIAL: (miniUser: MiniUserView) => formatFullName(miniUser, true),
    LAST_NAME_AND_BADGE: (miniUser: MiniUserView) => formatNameShortHand(miniUser),
    FULL_NAME_WITH_FIRST_INITIAL_AND_ID_NUMBER: (miniUser: MiniUserView) =>
        formatFullName(miniUser, true, true),
    INITIALS_AND_ID_NUMBER: (miniUser: MiniUserView) => formatInitialsAndIdNumber(miniUser),
    ID_NUMBER: (miniUser: MiniUserView) => formatIdNumber(miniUser),
} as const;

/**
 * This function creates miniUsers from userProfiles
 * so we can use existing client-common helpers that use miniUsers
 */
export function userProfileToMiniUser(userProfile: UserProfile | ElasticUser): MiniUserView {
    return {
        id: 'id' in userProfile ? userProfile.id : userProfile.userId,
        roleId: userProfile.userRoleId,
        first: userProfile.firstName,
        last: userProfile.lastName,
        sfx: userProfile.suffix,
        badge: userProfile.badgeNumber,
        cadId: userProfile.externalCadId,
        departmentAgencyId: userProfile.departmentAgencyId,
    };
}

import { createSelector } from 'reselect';
import moment from 'moment';
import _ from 'lodash';

/**
 * data related to commands
 */
const commandSelector = (state) => ({
    runningCommands: state.data.armada.runningCommands,
    pendingCommands: state.data.armada.pendingCommands,
    failedCommands: state.data.armada.failedCommands,
    currentCommands: state.data.armada.currentCommands,
    commandHistory: state.data.armada.commandHistory,
    commandMessage: state.ui.armada.commandMessage,
});

/**
 * data related to schedules
 */
const schedulesSelector = (state) => ({
    schedules: state.data.armada.allSchedules,
    history: state.data.armada.scheduleHistory,
    scheduleMessage: state.ui.armada.scheduleMessage,
});

/**
 * ids for selected object in table
 */
const selectedFailedId = (state) => state.ui.armada.selectedFailedCommandId;
const selectedScheduleId = (state) => state.ui.armada.selectedScheduleId;

/**
 * actual failed command object for the selected id
 */
const selectedFailedCommandSelector = createSelector(
    [selectedFailedId, commandSelector],
    (selectedId, commands) => ({
        selectedFailedCommand: selectedId
            ? _.find(commands.failedCommands, (command) => command.id === selectedId)
            : null,
    })
);

/**
 * actual schedule obj for selected schedule id
 */
const selectedScheduleSelector = createSelector(
    [selectedScheduleId, schedulesSelector],
    (selectedId, schedules) => {
        const blankForm = {
            id: '',
            commandName: 'TEST_COMMAND',
            frequencyType: 'ONCE',
            frequencyValue: '',
            startDateUtc: moment().toISOString(),
            enabled: false,
            serializedInput: '',
            isNew: true,
        };

        let selectedSchedule = null;
        if (selectedId > 0) {
            selectedSchedule = _.find(
                schedules.schedules,
                (schedule) => schedule.id === selectedId
            );
        } else if (selectedId < 0) {
            selectedSchedule = blankForm;
        }
        return {
            selectedSchedule,
        };
    }
);

/**
 * this is either CREATE or UPDATE
 */
const schedulesActionSelector = (state) => ({
    scheduleActionType: state.ui.armada.scheduleActionType,
});

// this is for the commands page
export const armadaCommandsSelector = createSelector(
    [commandSelector, selectedFailedCommandSelector],
    (commands, selected) => ({
        commands,
        commandMessage: commands.commandMessage,
        selectedFailedCommand: selected.selectedFailedCommand,
    })
);

// this is for the schedules page
export const armadaSchedulesSelector = createSelector(
    [schedulesSelector, selectedScheduleSelector, schedulesActionSelector],
    (schedules, selected, action) => ({
        schedules: schedules.schedules,
        history: schedules.history,
        scheduleMessage: schedules.scheduleMessage,
        selectedSchedule: selected.selectedSchedule,
        scheduleActionType: action.scheduleActionType,
    })
);

// this is for the armada actions
export const getSelectedFailedCommandSelector = (state, id) => {
    return _.find(state.data.armada.failedCommands, (command) => command.id === id);
};

export const getSelectedScheduleSelector = (state, id) => {
    return _.find(state.data.armada.allSchedules, (schedule) => schedule.id === id);
};

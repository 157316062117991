import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';

import { compact, flatMap, map } from 'lodash';
import { NEXUS_STATE_PROP as OFFENSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offenses/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-codes/state/data';
import { NEXUS_STATE_PROP as REPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/reports/state/data';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/location-entity-links/state/data';

const storeChargeOffenseView = ({ chargeOffenseView, action }) => (dispatch) => {
    // eslint-disable-next-line no-restricted-syntax
    dispatch(storeChargeOffenseViews({ chargeOffenseViews: [chargeOffenseView], action }));
};

export default storeChargeOffenseView;

export const storeChargeOffenseViews = ({ chargeOffenseViews = [], action = { type: '' } }) => (
    dispatch,
    getState,
    { nexus: { withEntityItems, withRemove } }
) => {
    const offenses = flatMap(chargeOffenseViews, 'offense');
    const reports = flatMap(chargeOffenseViews, 'report');
    const reportShortTitles = flatMap(chargeOffenseViews, 'reportShortTitle');
    const offenseCodes = compact(flatMap(chargeOffenseViews, 'offenseCode'));
    const offenseLocationEntityLinks = compact(
        flatMap(chargeOffenseViews, 'offenseLocationEntityLink')
    );

    const predicateLocationEntityLinksToRemoveBy = map(offenses, ({ id }) => ({
        linkType: LinkTypesEnum.OFFENSE_LOCATION,
        entityType: EntityTypeEnum.OFFENSE.name,
        entityId: id,
    }));

    dispatch(
        withEntityItems(
            {
                [OFFENSES_NEXUS_STATE_PROP]: offenses,
                [REPORTS_NEXUS_STATE_PROP]: reports,
                [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: reportShortTitles,
                [OFFENSE_CODES_NEXUS_STATE_PROP]: offenseCodes,
            },
            withRemove(
                LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
                predicateLocationEntityLinksToRemoveBy,
                withEntityItems(
                    {
                        [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: offenseLocationEntityLinks,
                    },
                    action
                )
            )
        )
    );
};

import { DepartmentStatusEnum } from '@mark43/rms-api';
import React from 'react';

import { omitBy } from 'lodash';
import { getDropdownOptionsFromEnum } from '~/client-common/helpers/dropdownOptionHelpers';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

const departmentStatusOptions = getDropdownOptionsFromEnum(
    omitBy(
        DepartmentStatusEnum,
        ({ name }) =>
            name === DepartmentStatusEnum.RESETTING.name ||
            name === DepartmentStatusEnum.DEPARTMENT_SETUP_FAILURE.name
    )
);

/**
 * Select dropdown for Mark43 internal concept of department status
 * @param {Object} props
 * @param {string} [props.label] Set this prop to use a custom label different
 *   from the default label.
 */
export default function DepartmentStatusSelect(props) {
    return <Select clearable={false} options={departmentStatusOptions} {...props} />;
}

export const RRFDepartmentStatusSelect = connectRRFInput(DepartmentStatusSelect);

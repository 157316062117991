import { MentionCategoryEnum, ProductModuleEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { BaseMention } from './types';

const strings = componentStrings.core.Editor.plugins.mentionsForBriefing;

export const LIST_ITEMS_LIMIT = 9;
export const SEARCH_CHARS_LIMIT = 3;

export const DATA_MENTION_ID = 'data-id';
export const DATA_MENTION_HREF = 'data-href';
export const BASE_ELEMENT = 'span';
export const BASE_ELEMENT_CLASS = 'briefing-mention';
export const CONTENT_EDITABLE = 'contenteditable';

export const INITIAL_CATEGORY = 'CATEGORY';
export const BACK_OPTION = 'Back';

export const extraMentions: BaseMention[] = [
    {
        id: BACK_OPTION,
        name: strings.back,
        text: strings.back,
        category: INITIAL_CATEGORY,
        meta: { reset: true },
    },
];

export const MentionCategories = [
    MentionCategoryEnum.PERSON.name,
    MentionCategoryEnum.VEHICLE.name,
    MentionCategoryEnum.ORGANIZATION.name,
    MentionCategoryEnum.PROPERTY.name,
    ProductModuleEnum.REPORTS.name,
    ProductModuleEnum.CASES.name,
    ProductModuleEnum.WARRANTS.name,
] as const;

import { isFunction, noop } from 'lodash';
import React, { ReactNode, useCallback, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';

import { useOnClickOutside } from '../../../legacy-redux/helpers/reactHelpers';
import { createRecordsHeaderWithTooltipContainer } from '../../records/core/components/header/RecordsHeaderButton';
import Icon, { iconTypes } from './Icon';

const StyledDropdownMenuOptions = styled.div`
    position: absolute;
    top: 35px;
    right: 0;
    padding: 6px 0;
    border-radius: 4px;
    box-shadow: 0 1px 15px 1px ${(props) => props.theme.colors.shadow};
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    z-index: 60;
    max-height: 70vh;
    overflow-y: auto;

    .dropdown-menu-option {
        display: block;
        padding: 4px 12px;
        width: 100%;
        color: ${(props) => props.theme.colors.darkGrey};
        font-weight: ${(props) => props.theme.fontWeights.semiBold};
        font-size: var(--arc-fontSizes-md);
        cursor: pointer;

        &:hover {
            background-color: ${(props) => props.theme.colors.brightBlue};
            color: ${(props) => props.theme.colors.white};
        }

        &.disabled {
            color: ${(props) => props.theme.colors.lightGrey};

            &:hover {
                background-color: inherit;
                color: ${(props) => props.theme.colors.lightGrey};
                cursor: text;
            }
        }
    }

    &.dropup {
        bottom: 80%;
        top: auto;
    }

    &.dropRight {
        left: 0;
    }
`;

const DropdownMenuComponent = styled.div`
    position: relative;
    display: inline-block;

    &,
    * {
        box-sizing: border-box;
    }

    &.active {
        .dropdown-menu-button {
            background-color: ${(props) => props.theme.colors.brightBlue};
            color: var(--arc-colors-brand-content);
            fill: var(--arc-colors-brand-content);
            border-color: var(--arc-colors-border-default);
        }
    }

    &.disabled {
        .dropdown-menu-button {
            border-color: ${(props) => props.theme.colors.extraLightGrey};
            background-color: ${(props) => props.theme.colors.extraLightGrey};
            color: ${(props) => props.theme.colors.extraLightGrey};
            cursor: default;

            i {
                color: ${(props) => props.theme.colors.extraLightGrey};
            }
        }
    }
`;

interface DropdownMenuButtonProps {
    disabled?: boolean;
    onOpen?: (...args: unknown[]) => void;
    onClose?: (...args: unknown[]) => void;
    noOptionDropdownClose?: boolean;
    className?: string;
    noTabIndex?: unknown;
    active: boolean;
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropdownMenuButton = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<DropdownMenuButtonProps>
>(
    (
        {
            disabled,
            children,
            active,
            setActive,
            onOpen = () => {},
            onClose = () => {},
            noOptionDropdownClose,
            className,
            noTabIndex,
        },
        forwardedRef
    ) => {
        // if there is no custom button class given we use the default
        const defaultRef = useRef<HTMLDivElement>(null);
        const ref = forwardedRef || defaultRef;

        useOnClickOutside(ref, (event: MouseEvent) => {
            if (noOptionDropdownClose) {
                let currentElement = event.target as HTMLElement | null;
                while (currentElement) {
                    const { className } = currentElement;
                    if (
                        className &&
                        typeof className === 'string' &&
                        // users might have clicked on another button that is
                        // within an element with a `dropdown-menu` class on it.
                        // Only stop if this is actually our element
                        className.indexOf('dropdown-menu') > -1 &&
                        typeof ref !== 'function' &&
                        ref?.current?.parentElement?.contains(currentElement)
                    ) {
                        return;
                    }
                    currentElement = currentElement.parentElement;
                }
            }
            setActive(false);
            if (onClose) {
                onClose();
            }
        });
        const onClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
            (event) => {
                if (event.type === 'mousedown' && event.button !== 0) {
                    return; // not left click
                }

                // toggle the menu when left clicking the menu button
                event.stopPropagation();
                event.preventDefault();
                setActive(!active);
                if (active && onClose) {
                    onClose();
                } else if (!active) {
                    onOpen();
                }
            },
            [active, setActive, onOpen, onClose]
        );
        const handleKeyPress = useCallback(
            (e) => {
                if (e.keyCode === keyCodeEnum.ENTER) {
                    onClick(e);
                } else if (e.keyCode === keyCodeEnum.DOWN_ARROW && !active) {
                    setActive(true);
                    onOpen();
                }
            },
            [onOpen, active, setActive, onClick]
        );
        return (
            <div
                className={className}
                ref={ref}
                {...(noTabIndex ? {} : { tabIndex: 0 })}
                onKeyDown={handleKeyPress}
                {...(!disabled && { onClick })}
            >
                {children}
            </div>
        );
    }
);

const StyledDropdownMenuButton = styled(DropdownMenuButton)`
    border: 1px solid var(--arc-colors-border-default);
    border-radius: 4px;
    fill: var(--arc-colors-brand-default);
    color: var(--arc-colors-brand-default);
    cursor: pointer;
    min-width: 38px;
    font-size: var(--arc-fontSizes-sm);
    text-transform: uppercase;

    &:hover {
        border-color: var(--arc-colors-brand-default);
    }
    float: none;
    margin: 0;
    padding: 0 7px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    letter-spacing: 0.05em;
    user-select: none;
`;

export interface DropdownMenuProps extends Omit<DropdownMenuButtonProps, 'active' | 'setActive'> {
    buttonContent?: ReactNode;
    children: ReactNode | ((closeMenu: () => void) => ReactNode);
    dropup?: boolean;
    dropRight?: boolean;
    buttonDisabled?: boolean;
    testId?: string;
    width?: number;
    minWidth?: number;
    tooltip?: string | React.FC;
}
/*
 * Dropdown menu. The provided children represent menu options. Every child
 * must have className='dropdown-menu-option' applied to either it or some
 * component within it, whichever is the link to be clicked on. It is important to
 * put this block class on the correct component so that clicking anywhere within
 * the block (not only on the text itself) actually triggers its action.
 * @param {boolean} [disabled=false] Whether the menu is disabled, which means
 *   it's greyed out and can't be clicked.
 * @param {boolean} [buttonDisabled=false] Whether the button is disabled, which
 *  means that it cannot be clicked, but won't be greyed out.
 * @param {number}  [width=184]      Optional width for the options.
 * @param {*}       [buttonContent]  Optional custom content to place inside the
 *   button. defaults to hamburger icon.
 */
const DropdownMenu = React.forwardRef<HTMLElement, DropdownMenuProps>(
    (
        {
            disabled = false,
            buttonContent = <Icon type={iconTypes.MORE_OPTIONS} color="cobaltBlue" size={16} />,
            className,
            children,
            dropup = false,
            dropRight = false,
            buttonDisabled = false,
            onOpen = noop,
            onClose,
            noOptionDropdownClose,
            testId,
            width, // style will be handled by min-Width if width not provided
            minWidth = 160, // 160px
            tooltip,
            ...otherProps
        },
        ref
    ) => {
        const [active, setActive] = useState(false);
        const TooltipIfAny = useMemo(
            () =>
                tooltip
                    ? typeof tooltip === 'string'
                        ? createRecordsHeaderWithTooltipContainer({ overlay: tooltip })
                        : tooltip
                    : React.Fragment,
            [tooltip]
        );
        return (
            <DropdownMenuComponent
                className={classNames(
                    'dropdown-menu',
                    { active, disabled },
                    className,
                    dropup,
                    dropRight
                )}
                data-test-id={testId}
                {...otherProps}
            >
                <TooltipIfAny>
                    <StyledDropdownMenuButton
                        disabled={disabled || buttonDisabled}
                        className="dropdown-menu-button"
                        active={active}
                        setActive={setActive}
                        noOptionDropdownClose={noOptionDropdownClose}
                        onOpen={onOpen}
                        onClose={onClose}
                        ref={ref as React.Ref<HTMLDivElement>} // a temp solution
                        data-test-id={testId}
                    >
                        {buttonContent}
                    </StyledDropdownMenuButton>
                </TooltipIfAny>

                <StyledDropdownMenuOptions
                    className={classNames('dropdown-menu-options-container', {
                        dropup,
                        hidden: !active,
                        dropRight,
                    })}
                    style={{ width, minWidth }}
                >
                    {isFunction(children) ? children(() => setActive(false)) : children}
                </StyledDropdownMenuOptions>
            </DropdownMenuComponent>
        );
    }
);

export default DropdownMenu;

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/basket';

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Basket Resource',
        methods: {
            getBasket() {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: BASE_PATH,
                    hideLoadingBar: true,
                });
            },
            addItemsToBasket(masterItemIds) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: `${BASE_PATH}/bulk`,
                    data: masterItemIds,
                    hideLoadingBar: true,
                });
            },
            removeItemFromBasket(masterItemId) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'DELETE',
                    url: `${BASE_PATH}/${masterItemId}`,
                    hideLoadingBar: true,
                });
            },
            clearBasket() {
                return req({
                    baseUrl: BASE_URL,
                    method: 'DELETE',
                    url: `${BASE_PATH}`,
                    hideLoadingBar: true,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import sortBy from 'lodash/sortBy';
import { Caution, ElasticCaution } from '@mark43/rms-api';

import { isAfter, isBefore } from '~/client-common/core/dates/utils/dateHelpers';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatAttributeWithOther } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

import {
    CautionComputedData,
    CautionEntityTypes,
    cautionFormFields,
    DEFAULT_ARC_PRIORITY,
    entityTypeToCautionAttributeTypes,
    priorityAttrIdToArcPriority,
} from '../configuration';
import { ArcPriorityEnum } from '../../components/tags/helpers';

type EnhancedCaution<T extends Caution | ElasticCaution> = {
    caution: T;
} & CautionComputedData;

interface EnhancedCautions<T extends Caution | ElasticCaution> {
    inactiveCautionsCounter: number;
    enhancedCautions: EnhancedCaution<T>[];
}

type FormatAttributeById = ReturnType<typeof formatAttributeByIdSelector>;
type GetParentAttributeById = ReturnType<typeof parentAttributeIdByAttributeIdSelector>;

const isMappedPriorityAttrId = (
    priorityAttrId: number | undefined
): priorityAttrId is keyof typeof priorityAttrIdToArcPriority => {
    return !!(priorityAttrId && priorityAttrId in priorityAttrIdToArcPriority);
};

const getArcPriorityByPriorityAttrId = (priorityAttrId: number | undefined) => {
    if (isMappedPriorityAttrId(priorityAttrId)) {
        return priorityAttrIdToArcPriority[priorityAttrId];
    }
    return DEFAULT_ARC_PRIORITY;
};

const isElasticCaution = (caution: Caution | ElasticCaution): caution is ElasticCaution => {
    return 'cautionAttrDetail' in caution;
};

const isCautionInactive = (caution: Caution | ElasticCaution, evaluateByCreationDate?: boolean) => {
    const baseDate =
        evaluateByCreationDate && 'createdDateUtc' in caution ? caution.createdDateUtc : new Date();

    const isFuture = !!caution.dateEffectiveFrom && isAfter(baseDate, caution.dateEffectiveFrom);
    const isPast = !!caution.dateEffectiveTo && isBefore(baseDate, caution.dateEffectiveTo);

    return isFuture || isPast;
};

function enhanceCaution(
    caution: Caution,
    formatAttributeById: FormatAttributeById,
    parentAttributeIdByAttributeId: GetParentAttributeById
): EnhancedCaution<Caution>;
function enhanceCaution(
    caution: ElasticCaution,
    formatAttributeById: FormatAttributeById,
    parentAttributeIdByAttributeId: GetParentAttributeById
): EnhancedCaution<ElasticCaution>;
function enhanceCaution(
    caution: Caution | ElasticCaution,
    formatAttributeById: FormatAttributeById,
    parentAttributeIdByAttributeId: GetParentAttributeById
): EnhancedCaution<Caution | ElasticCaution>;
function enhanceCaution(
    caution: Caution | ElasticCaution,
    formatAttributeById: FormatAttributeById,
    parentAttributeIdByAttributeId: GetParentAttributeById
) {
    const cautionAttrId = isElasticCaution(caution)
        ? caution.cautionAttrDetail.id
        : caution.cautionAttrId;
    const cautionDisplayValue = isElasticCaution(caution)
        ? caution.cautionAttrDetail.displayValue
        : formatAttributeById(caution.cautionAttrId);

    const label = formatAttributeWithOther(cautionDisplayValue, caution.cautionOther);

    const categoryAttrId = parentAttributeIdByAttributeId(cautionAttrId);
    const priorityAttrId = parentAttributeIdByAttributeId(categoryAttrId ?? 0);
    const priority = getArcPriorityByPriorityAttrId(priorityAttrId);

    return {
        caution,
        label,
        priority,
    };
}

interface BaseInput {
    evaluateByCreationDates?: boolean;
    includeInactive?: boolean;
    includePriority1Only?: boolean;
    formatAttributeById: FormatAttributeById;
    parentAttributeIdByAttributeId: GetParentAttributeById;
}
interface CautionsInput extends BaseInput {
    cautions: Caution[];
}
interface ElasticCautionsInput extends BaseInput {
    cautions: ElasticCaution[];
}
interface CombinedCautionsInput extends BaseInput {
    cautions: Caution[] | ElasticCaution[];
}

export function prepareEnhancedCautions(input: CautionsInput): EnhancedCautions<Caution>;
export function prepareEnhancedCautions(
    input: ElasticCautionsInput
): EnhancedCautions<ElasticCaution>;
export function prepareEnhancedCautions(
    input: CombinedCautionsInput
): EnhancedCautions<Caution | ElasticCaution>;
export function prepareEnhancedCautions({
    cautions,
    evaluateByCreationDates,
    includeInactive,
    includePriority1Only,
    formatAttributeById,
    parentAttributeIdByAttributeId,
}: CautionsInput | ElasticCautionsInput | CombinedCautionsInput) {
    const enhancedCautions = cautions.map((caution) =>
        enhanceCaution(caution, formatAttributeById, parentAttributeIdByAttributeId)
    );

    const includedCautions = enhancedCautions.filter(({ priority }) =>
        includePriority1Only ? priority === ArcPriorityEnum.PriorityOne : true
    );

    let inactiveCautionsCounter = 0;
    const activeCautions = includedCautions.filter(({ caution }) => {
        if (
            isCautionInactive(caution, evaluateByCreationDates)
        ) {
            inactiveCautionsCounter++;
            return !!includeInactive;
        }
        return true;
    });

    return {
        enhancedCautions: sortBy(activeCautions, ['priority', 'label']),
        inactiveCautionsCounter,
    };
}

export const sortCustomAndEnhancedCautions = ({
    customCautions,
    enhancedCautions,
}: {
    customCautions: CautionComputedData[];
    enhancedCautions: EnhancedCaution<Caution | ElasticCaution>[];
}) => {
    return customCautions?.length
        ? sortBy([...enhancedCautions, ...customCautions], ['priority', 'label'])
        : enhancedCautions;
};

export const convertCautionsFromFormModel = (
    cautions: Pick<Caution, typeof cautionFormFields[number]>[],
    entityType: CautionEntityTypes,
    entityId: number
) =>
    cautions
        .filter(({ cautionAttrId }) => cautionAttrId)
        .map((cautionFormData) => ({
            ...cautionFormData,
            entityType,
            entityId,
            attributeType: entityTypeToCautionAttributeTypes[entityType].valueAttrType,
        }));

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Country Resource',
        methods: {
            searchForCountries({ q, from, size }) {
                return req({
                    method: 'GET',
                    url: 'master/locations/countries',
                    params: {
                        q,
                        from,
                        size,
                    },
                });
            },
        },
    });
}
const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import { AttributeTypeEnum } from '@mark43/rms-api';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { personProfileViewModelByIdSelector } from '~/client-common/core/domain/person-profiles/state/ui';
import { PersonLabels } from '../../../../../core/components/tags/PersonLabels';
import { PersonEnhancedCautions } from '../../../../../core/cautions/components/PersonEnhancedCautions';

type PropsT = {
    personId: number;
};

export const EFilePersonLabels = ({ personId }: PropsT) => {
    const personProfileViewModelById = useSelector(personProfileViewModelByIdSelector);

    const personProfileViewModel = personProfileViewModelById(personId);
    const viewModelProperties = getViewModelProperties(personProfileViewModel);

    if (!personProfileViewModel) {
        return null;
    }
    return (
        <FeatureFlagged
            flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED"
            fallback={
                <PersonLabels
                    nameAttributes={filter(viewModelProperties.nameAttributes, {
                        attributeType: AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
                    })}
                    isJuvenile={personProfileViewModel.isJuvenile}
                    isVulnerable={personProfileViewModel.isVulnerable}
                    dateVulnerableTo={personProfileViewModel.dateVulnerableTo}
                    isSuspectedGangMember={personProfileViewModel.isAllegedGangMember}
                    size="sm"
                />
            }
        >
            <PersonEnhancedCautions
                cautions={viewModelProperties.cautions}
                isJuvenile={personProfileViewModel.isJuvenile}
                isVulnerable={personProfileViewModel.isVulnerable}
                dateVulnerableTo={personProfileViewModel.dateVulnerableTo}
                isSuspectedGangMember={personProfileViewModel.isAllegedGangMember}
                size="sm"
            />
        </FeatureFlagged>
    );
};

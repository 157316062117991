import React from 'react';
import { noop, trim, isEmpty, some } from 'lodash';
import { SavedSearch } from '@mark43/rms-api';
import { Form, formEvents, lifecycleOptions } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { savedSearchRenameFormConfiguration } from '../forms/savedSearchRenameForm';
import testIds from '../../../../core/testIds';
import { MFTText } from '../../../core/forms/components/Text';

const strings = componentStrings.search.savedSearch.SavedSearchRenameForm;

type SavedSearchRenameFormEffectMap = {
    name: {
        valid?: boolean;
        errors?: { [index: string]: string };
    };
};

const SavedSearchRenameForm: React.FC<{
    initialValues: { name: string };
    onSavedSearchRename: (name: string) => void;
    savedSearchId: number;
    // TODO: type these objects as SavedSearchViewModel[]
    savedSearches: SavedSearch[];
}> = ({ initialValues, onSavedSearchRename, savedSearchId, savedSearches }) => {
    return (
        <Form
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name="SavedSearchRenameForm"
            validationEvents={[
                { eventType: formEvents.FORM_SUBMIT },
                { eventType: formEvents.INPUT_BLUR },
            ]}
            configuration={savedSearchRenameFormConfiguration}
            onValidate={({ formState }) => {
                const { name } = formState.model || {};
                const formErrors: string[] = [];
                let effectMap: SavedSearchRenameFormEffectMap = {
                    name: { valid: true },
                };

                if (!name || !trim(name)) {
                    formErrors.push(strings.requiredError);
                    effectMap = {
                        ...effectMap,
                        name: {
                            errors: {
                                name: strings.requiredError,
                            },
                        },
                    };
                } else {
                    const uniqueNameError = some(savedSearches, (savedSearch) => {
                        return (
                            savedSearch.id !== savedSearchId &&
                            trim(savedSearch.name).toLowerCase() === trim(name).toLowerCase()
                        );
                    });

                    if (uniqueNameError) {
                        formErrors.push(strings.uniqueNameError);
                        effectMap = {
                            ...effectMap,
                            name: {
                                errors: {
                                    name: strings.uniqueNameError,
                                },
                            },
                        };
                    }
                }

                return {
                    success: isEmpty(formErrors),
                    formErrors,
                    do: effectMap,
                };
            }}
            initialState={initialValues}
            render={(form) => {
                const onSubmit = () => {
                    form.submit()
                        .then(({ form }) => {
                            const value = form.get('name') as string;
                            onSavedSearchRename(trim(value));
                        })
                        .catch(noop);
                };

                const onPressEnter = (
                    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                    event.currentTarget.blur();
                    onSubmit();
                };

                return (
                    <div data-test-id={testIds.SAVED_SEARCH_RENAME_FORM}>
                        <MFTText
                            width={350}
                            autoFocus={true}
                            path="name"
                            maxLength={128}
                            onPressEnter={(event) => onPressEnter(event)}
                            onBlur={onSubmit}
                        />
                    </div>
                );
            }}
        />
    );
};

export default SavedSearchRenameForm;

import _ from 'lodash';
import * as fields from '../core/enums/universal/fields';
import { joinTruthyValues } from './stringHelpers';

const subdivisionFields = [
    fields.LOCATION_ENTITY_LINK_SUBDIVISION_1_ATTR_ID,
    fields.LOCATION_ENTITY_LINK_SUBDIVISION_2_ATTR_ID,
    fields.LOCATION_ENTITY_LINK_SUBDIVISION_3_ATTR_ID,
    fields.LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID,
    fields.LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID,
];

export const subdivisionFieldKeyNames = [
    'subdivision1AttrId',
    'subdivision2AttrId',
    'subdivision3AttrId',
    'subdivision4AttrId',
    'subdivision5AttrId',
];

/**
 * Build the combined subdivision field label, based on this rule:
 * - slash-join the field labels for all "used" subdivisions, sorted in order
 * - subdivision is "used" if field display name != client name
 *
 * Example from CCPD:
 * - depth 1 label = "Distrct"
 * - depth 2 label = "Sector"
 * - depth 3 label = "Grid"
 * - depth 4 label = "LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID"
 * - depth 5 label = "LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID"
 * --> combined subdivisions label = "Distrct / Sector / Grid"
 *
 * So we have 2 DIFFERENT RULES for what it means to be a "used" subdivisions:
 * 1. subdivision is "used" if field display name != client name
 * 2. subdivision is "used" if department has any attribute values for type
 *    (see `getDepartmentSubdivisionUsages` in
 *     {@link client/src/scripts/helpers/subdivisionHelpers.js})
 *
 * Both of these rules must hold for subdivisions to function correctly.
 *
 * @param {Function} formatFieldByName mapper from `field.js` field name to
 *   the display label for that field.
 * @return {string}
 */
export function formatCombinedSubdivisionsLabel(formatFieldByName, joinWith = ' / ') {
    return _(subdivisionFields).map(formatFieldByName).difference(subdivisionFields).join(joinWith);
}

export function formatSubdivsionAttributesForLocationEntityLink(
    locationEntityLink,
    formatAttributeById
) {
    const {
        subdivision1AttrId,
        subdivision2AttrId,
        subdivision3AttrId,
        subdivision4AttrId,
        subdivision5AttrId,
    } = locationEntityLink;
    return joinTruthyValues(
        [
            formatAttributeById(subdivision1AttrId),
            formatAttributeById(subdivision2AttrId),
            formatAttributeById(subdivision3AttrId),
            formatAttributeById(subdivision4AttrId),
            formatAttributeById(subdivision5AttrId),
        ],
        ' / '
    );
}

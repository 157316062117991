import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';

import { lifecycleOptions } from 'markformythree';
import { initialState, formConfiguration } from '../../state/forms/requestDisposition';
import Row from '../../../../core/components/Row';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';

const RequestDispositionForm = () => (
    <ArbiterForm
        context={RefContextEnum.FORM_EVIDENCE_DISPOSITION_REQUEST.name}
        configuration={formConfiguration}
        initialState={initialState}
        name={RefContextEnum.FORM_EVIDENCE_DISPOSITION_REQUEST.name}
        lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
        render={() => (
            <Row>
                <ArbiterMFTTextArea path="comment" rows={6} />
            </Row>
        )}
    />
);

export default RequestDispositionForm;

import { filesize } from 'filesize';
import { sumBy, filter, includes } from 'lodash';

import { Attachment, FileCategoryEnum, FileCategoryEnumType } from '@mark43/rms-api';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { iconTypes } from '../../../core/components/Icon';

export const fileIconTypeForFileCategory = (fileCategory: FileCategoryEnumType) => {
    if (fileCategory === FileCategoryEnum.IMAGE.name) {
        return iconTypes.CAMERA;
    } else if (fileCategory === FileCategoryEnum.VIDEO.name) {
        return iconTypes.VIDEO_FILE;
    } else if (fileCategory === FileCategoryEnum.AUDIO.name) {
        return iconTypes.AUDIO_FILE;
    } else if (fileCategory === FileCategoryEnum.PDF.name) {
        return iconTypes.PDF_DOCUMENT;
    } else {
        return iconTypes.DOCUMENT;
    }
};

export const arcIconForFileCategory = (fileCategory?: FileCategoryEnumType) => {
    if (fileCategory === FileCategoryEnum.IMAGE.name) {
        return 'Camera';
    } else if (fileCategory === FileCategoryEnum.VIDEO.name) {
        return 'VideoFile';
    } else if (fileCategory === FileCategoryEnum.AUDIO.name) {
        return 'AudioFile';
    } else if (fileCategory === FileCategoryEnum.PDF.name) {
        return 'PDFDocument';
    } else {
        return 'Document';
    }
};

// Prefer DownloadLink in files/components,  use this only if there is no other option
export const downloadSelectFile = (filePath: string, fileName: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', filePath);
    element.setAttribute('download', fileName);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

const fileTypesToOpenDirectly = ['png', 'gif', 'jpg', 'jpeg', 'pdf'];
export const fileTypeShouldBeDownloadOnly = (fileName: string) => {
    const fileNameSegments = fileName?.split('.') ?? [];
    return !fileTypesToOpenDirectly.includes(fileNameSegments[fileNameSegments.length - 1]);
};

export function computeTotalFileSize(
    attachments: Attachment[],
    acceptedFileTypes?: string[]
): number {
    if (acceptedFileTypes) {
        const filteredAttachments = filter(attachments, (attachment) =>
            includes(acceptedFileTypes, attachment.image?.originalFile?.fileType)
        );
        return sumBy(
            filteredAttachments,
            (attachment) => getAttachmentFile(attachment)?.contentLength || 0
        );
    }
    return sumBy(attachments, (attachment) => getAttachmentFile(attachment)?.contentLength || 0);
}

export function formatFileSize(bytes: number): string {
    return filesize(bytes, { round: 1 });
}

export function convertBytesToGigaBytes(bytes: number): number {
    return bytes / Math.pow(1024, 3);
}

import { ResolvedEntityResult } from './dragon-select-state-reducer';
import { DragonOption } from './use-dragon-select-base';

export function convertObjectsToDragonOptions(
    displayPropertyId: number,
    valuePropertyId: number,
    optionData?: ResolvedEntityResult
): DragonOption[] {
    return (
        optionData?.map((resolvedConfiguredEntityInstance) => {
            const display = resolvedConfiguredEntityInstance[displayPropertyId];
            if (typeof display !== 'string' && typeof display !== 'number') {
                throw new Error('Invalid display value provided');
            }
            const value = resolvedConfiguredEntityInstance[valuePropertyId];
            if (typeof value !== 'string' && typeof value !== 'number') {
                throw new Error('Invalid value provided');
            }
            return {
                display: display.toString(),
                // Note: we are normalizing the value here because for now we expect the value to be a bigint.
                value: parseInt(value.toString(), 10),
            };
        }) ?? []
    );
}

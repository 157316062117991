import { EntityTypeEnum, ExportOptionTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { compose, withHandlers } from 'recompose';
import styled from 'styled-components';
import { cssVar } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { prettify } from '~/client-common/helpers/stringHelpers';

import {
    EXPORT_RELEASE_RELEASED_TO_ATTR_ID,
    EXPORT_RELEASE_ADDITIONAL_INFORMATION,
} from '~/client-common/core/enums/universal/fields';
import { fileByIdSelector } from '~/client-common/core/domain/files/state/data';
import { exportReleaseEntitiesSelector } from '~/client-common/core/domain/export-release-entities/state/data';
import { exportedAttachmentsByReleaseIdSelector } from '~/client-common/core/domain/exported-attachments/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { FormattedDate } from '~/client-common/core/dates/components';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { BodyMediumText } from '../../../components/typography';
import Row from '../../../components/Row';
import {
    HistoryWrapper,
    HistoryIconWrapper,
    HistoryContent,
    HistoryIcon,
} from '../../../histories/components';
import { Ability, OnlyWithAbility, abilitiesEnum } from '../../../abilities';
import Icon, { iconTypes, iconSizes } from '../../../components/Icon';
import OldIcon, {
    iconTypes as oldIconTypes,
} from '../../../../../legacy-redux/components/core/Icon';
import _SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import _SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import { DownloadLink } from '../../../../attachments/files/components';
import InlineAttachmentsUploader from '../../../../attachments/core/components/InlineAttachmentsUploader';

import { saveInlineAttachments } from '../../../../attachments/core/state/ui/inlineAttachmentsUploader';
import { openBox } from '../../../../../legacy-redux/actions/boxActions';
import testIds from '../../../../../core/testIds';
import createReleaseForm from '../state/forms/createReleaseForm';
import SealedStamp from '../../../components/SealedStamp';
import InlineFileRow from '../../../../attachments/core/components/InlineFileRow';

const strings = componentStrings.exports.releaseTracking.components.ReleaseHistoryBlock;
const optionStrings = componentStrings.exports.options;

export const ReleaseTrackingWrapper = styled(HistoryWrapper)`
    width: auto;
`;

export const ReleaseHistory = styled(HistoryContent)`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-sm);
    width: auto;
`;

export const ReleaseTrackingIconWrapper = styled(HistoryIconWrapper)`
    left: -24px;
    width: 45px;
    height: 40px;
    padding: 3px;
`;

export const ReleaseHistoryContent = styled(Row)`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const SummaryList = styled(_SummaryList)`
    font-size: var(--arc-fontSizes-sm);
`;

export const SummaryRow = styled(_SummaryRow)`
    /* fighting with specificity issues from non-styled-components css */
    && {
        margin-bottom: 13px;
    }
`;

export const Header = styled.div`
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
`;

export const ToggleButton = styled(Text)`
    color: ${cssVar('arc.colors.brand.default')};
    font-size: ${cssVar('arc.fontSizes.xs')};
    text-decoration: none;
    border: none;
    padding: 0;
    background-color: transparent;

    &:hover {
        text-decoration: underline;
    }
`;

export const CheckIcon = styled(Icon)`
    margin-right: 8px;
`;

export const ExportReleaseEntityRow = styled.div`
    margin-bottom: 8px;
`;

export const SealedStampWrapper = styled.div`
    margin-bottom: 20px;
`;

const mapDispatchToProps = (dispatch) => ({
    saveAttachments: (config) => {
        dispatch(
            saveInlineAttachments({
                entityType: config.entityType,
                entityId: config.entityId,
                linkType: config.linkType,
            })
        );
    },
    editRelease: (exportRelease) => {
        dispatch(createReleaseForm.actionCreators.change(exportRelease));
        dispatch(openBox({ name: boxEnum.EDIT_EXPORT_RELEASE_MODAL }));
    },
});

export default compose(
    connect(
        createStructuredSelector({
            fileById: fileByIdSelector,
            exportReleaseEntities: exportReleaseEntitiesSelector,
            exportedAttachmentsByReleaseId: exportedAttachmentsByReleaseIdSelector,
        }),
        mapDispatchToProps
    ),
    withHandlers({
        onEdit: ({ exportReleaseViewModel, editRelease }) => () => {
            return editRelease(exportReleaseViewModel);
        },
        onFileUploadFinish({ exportReleaseViewModel, saveAttachments }) {
            return () =>
                saveAttachments({
                    entityId: exportReleaseViewModel.id,
                    entityType: EntityTypeEnum.EXPORT_RELEASE.name,
                    linkType: LinkTypesEnum.EXPORT_RELEASE_ATTACHMENT,
                });
        },
    })
)(function ReleaseHistoryBlock({
    exportReleaseViewModel,
    fileById,
    exportReleaseEntities,
    exportedAttachmentsByReleaseId,
    onFileUploadFinish,
    onEdit,
    showDetails,
    toggleOpen,
}) {
    const file = fileById(exportReleaseViewModel.fileId);
    const { releaseType, releasedByUserId, releasedTo } = getViewModelProperties(
        exportReleaseViewModel
    );
    const exportedReleaseEntities = exportReleaseEntities[exportReleaseViewModel.id] || [];

    const exportOptionTypesToComponentStringKey = {
        [ExportOptionTypeEnum.INCLUDE_NAME_ADDENDUM.name]: 'includeNameAddendums',
        [ExportOptionTypeEnum.INCLUDE_HISTORY_EVENTS.name]: 'includeHistoryEvents',
        [ExportOptionTypeEnum.INCLUDE_WARRANT_ACTIVITY.name]: 'includeWarrantActivities',
        [ExportOptionTypeEnum.INCLUDE_CONFIDENTIAL_INFORMATION.name]:
            'includeConfidentialInformation',
        [ExportOptionTypeEnum.ONLY_INCLUDE_FIELDS_WITH_DATA.name]: 'onlyIncludeFieldsWithData',
    };
    const exportedAttachmentRows = exportedAttachmentsByReleaseId(exportReleaseViewModel.id)?.map(attachment => {
        const originalFile = getAttachmentFile(attachment);
        return (
            <InlineFileRow
                url={originalFile.fileWebServerPath}
                key={`${exportReleaseViewModel.id}_${attachment.id}`}
                fileName={originalFile.originalFileName}
                createdDateUtc={attachment.createdDateUtc}
                createdBy={attachment.createdBy}
                fileCategory={originalFile.fileCategory}
                disabled={true}
                hideDetails={true}
            />
        );
    });
    return (
        <ReleaseTrackingWrapper data-test-id={testIds.EXPORTS_RELEASE_HISTORY_BLOCK}>
            <ReleaseTrackingIconWrapper>
                <HistoryIcon type={oldIconTypes.EXPORT} size="22px" />
            </ReleaseTrackingIconWrapper>
            <ReleaseHistory>
                <Header>
                    <div>
                        <BodyMediumText fontWeight="semibold">{releaseType}</BodyMediumText>
                        <div>
                            <FormattedDate
                                date={exportReleaseViewModel.releasedDateUtc}
                                format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                            >
                                {(date) => <div>{date}</div>}
                            </FormattedDate>
                            <SummaryList labelWidth={100} contentWidth="100%">
                                <SummaryRow label={strings.exportedBy}>
                                    {releasedByUserId?.fullName}
                                </SummaryRow>
                                <SummaryRow fieldName={EXPORT_RELEASE_RELEASED_TO_ATTR_ID}>
                                    {releasedTo}
                                </SummaryRow>
                            </SummaryList>
                        </div>
                    </div>
                    <div>
                        <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_EXPORT_RELEASE_TRACKING}>
                            <Button
                                className={buttonTypes.ICON_LINK}
                                iconLeft={iconTypes.EDIT}
                                onClick={onEdit}
                                testId={testIds.EDIT_BUTTON}
                            >
                                {strings.edit}
                            </Button>
                        </OnlyWithAbility>
                    </div>
                </Header>
                {showDetails && (
                    <ReleaseHistoryContent>
                        <SummaryList labelWidth={110} contentWidth="100%">
                            <SummaryRow fieldName={EXPORT_RELEASE_ADDITIONAL_INFORMATION}>
                                {exportReleaseViewModel.additionalInformation}
                            </SummaryRow>
                            <SummaryRow label={strings.content}>
                                {exportedReleaseEntities.map((exportReleaseEntity) => (
                                    <ExportReleaseEntityRow key={exportReleaseEntity.id}>
                                        {exportReleaseEntity.templateTitle}
                                    </ExportReleaseEntityRow>
                                ))}
                                {exportedAttachmentRows}
                            </SummaryRow>
                            <FeatureFlagged flag="RMS_OFFICER_REPORT_SNAPSHOT_ENABLED">
                                <SummaryRow label={strings.version}>
                                    {strings.reportVersions[
                                        exportReleaseViewModel.exportReportVersion
                                    ] || prettify(exportReleaseViewModel.exportReportVersion)}
                                </SummaryRow>
                            </FeatureFlagged>
                            <SummaryRow label={strings.details}>
                                {exportReleaseViewModel.exportOptionTypes.map((option) => (
                                    <div key={option}>
                                        <CheckIcon
                                            type={iconTypes.CHECK}
                                            size={iconSizes.SMALL}
                                            color="mediumLightGrey"
                                        />
                                        {
                                            optionStrings[
                                                exportOptionTypesToComponentStringKey[option]
                                            ]
                                        }
                                    </div>
                                ))}
                            </SummaryRow>
                            <Ability ability={abilitiesEnum.CORE.EDIT_EXPORT_RELEASE_ATTACHMENTS}>
                                {({ userHasAbility }) => (
                                    <SummaryRow label={strings.attachments} labelTooltip={strings.attachmentsTooltip}>
                                        <InlineAttachmentsUploader
                                            entityId={exportReleaseViewModel.id}
                                            entityType={EntityTypeEnum.EXPORT_RELEASE.name}
                                            linkType={LinkTypesEnum.EXPORT_RELEASE_ATTACHMENT}
                                            disabled={!userHasAbility}
                                            onFileUploadFinish={onFileUploadFinish}
                                        />
                                    </SummaryRow>
                                )}
                            </Ability>
                        </SummaryList>
                        {exportReleaseViewModel.isSealed ? (
                            <SealedStampWrapper>
                                <SealedStamp />
                            </SealedStampWrapper>
                        ) : (
                            <OnlyWithAbility
                                has={abilitiesEnum.CORE.DOWNLOAD_PREVIOUS_EXPORT_RELEASES}
                            >
                                <DownloadLink href={file?.fileWebServerPath}>
                                    <Button
                                        className={buttonTypes.SECONDARY}
                                        iconLeft={<OldIcon type={oldIconTypes.EXPORT} />}
                                        testId={testIds.EXPORTS_DOWNLOAD_RELEASE_BUTTON}
                                    >
                                        {strings.downloadRelease}
                                    </Button>
                                </DownloadLink>
                            </OnlyWithAbility>
                        )}
                    </ReleaseHistoryContent>
                )}
                <ToggleButton
                    as="button"
                    data-test-id={testIds.EXPORTS_RELEASE_HISTORY_BLOCK_TOGGLE}
                    onClick={toggleOpen}
                >
                    {showDetails ? strings.hideDetails : strings.showDetails}
                </ToggleButton>
            </ReleaseHistory>
        </ReleaseTrackingWrapper>
    );
});

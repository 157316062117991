import { flowRight, orderBy, slice, map, get, filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import {
    DISPLAY_ONLY_ITEMS_PENDING_DISPOSITION_REVIEW_LABEL,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { custodialPropertySummaryReportDefinitionSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { FormattedDate } from '~/client-common/core/dates/components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';

import { Button } from '../../core/components/Button';
import Link from '../../core/components/links/Link';
import { ITEMS_PENDING_REVIEW_INTERVAL, MAX_ITEMS_PENDING_REVIEW } from '../configuration';
import personalDashboardResource from '../resources/personalDashboardResource';
import { formatResponsiblePartySelector } from '../state/ui';
import { NoDataBlock, ShowMoreButton, DashboardCard } from './DashboardSection';
import { DashboardTable, DashboardTd, DashboardTh, DashboardTr } from './DashboardTable';
import { DashboardRightCardsLoader } from './DashboardLoaders';

const strings = componentStrings.personalDashboard.ItemsPendingReview;

const columnStyles = {
    ren: { minWidth: '8rem' },
    party: { minWidth: '12rem', flex: '3' },
    date: { minWidth: '10rem' },
    count: { minWidth: '4.5rem', maxWidth: '4.5rem' },
};

const HeaderRow = withFields([REPORT_REPORTING_EVENT_NUMBER])(function HeaderRow({
    fieldDisplayNames,
}) {
    return (
        <DashboardTr>
            <DashboardTh style={columnStyles.ren}>
                {fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER}
            </DashboardTh>
            <DashboardTh style={columnStyles.party}>{strings.responsibleParty}</DashboardTh>
            <DashboardTh style={columnStyles.date}>{strings.createdDateUtc}</DashboardTh>
            <DashboardTh style={columnStyles.count}>{strings.itemCount}</DashboardTh>
        </DashboardTr>
    );
});

function renderRow(item, index) {
    const { view } = item;
    const { elasticReport } = view;

    return (
        <DashboardTr isInteractive key={index} as={Link} to={`/reports/${elasticReport.id}`}>
            <DashboardTd style={{ ...columnStyles.ren, color: 'var(--arc-colors-brand-default)' }}>
                {elasticReport.reportingEventNumber}
            </DashboardTd>
            <DashboardTd style={columnStyles.party}>{view.displayName}</DashboardTd>
            <DashboardTd style={columnStyles.date}>
                <FormattedDate
                    date={elasticReport.eventStartUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                />
            </DashboardTd>
            <DashboardTd style={columnStyles.count}>{view.itemCount}</DashboardTd>
        </DashboardTr>
    );
}

const itemsPendingReviewContentMapStateToProps = createStructuredSelector({
    formatResponsibleParty: formatResponsiblePartySelector,
    custodialPropertySummaryReportDefinition: custodialPropertySummaryReportDefinitionSelector,
    applicationSettings: applicationSettingsSelector,
});

const ItemsPendingReview = flowRight(
    withRouter,
    connect(itemsPendingReviewContentMapStateToProps)
)(_ItemsPendingReviewContent);

function _ItemsPendingReviewContent({
    formatResponsibleParty,
    custodialPropertySummaryReportDefinition,
    router,
    dragHandleProps,
    isDragging,
    hideLoadingBar = true,
    applicationSettings,
}) {
    const [custodialReportViews, setCustodialReportViews] = useState([]);
    const [reportsToShow, setReportsToShow] = useState(ITEMS_PENDING_REVIEW_INTERVAL);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const investigationEnhancementsEnabled =
        applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE;
    const itemsPendingDispositionReviewDisplayName = useFields(
        DISPLAY_ONLY_ITEMS_PENDING_DISPOSITION_REVIEW_LABEL
    )[DISPLAY_ONLY_ITEMS_PENDING_DISPOSITION_REVIEW_LABEL];

    useEffect(() => {
        personalDashboardResource
            .getCustodialReports({ hideLoadingBar })
            .then((reportViews) => {
                const views = orderBy(reportViews, 'elasticReport.eventStartUtc', 'desc');
                setCustodialReportViews(views);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    }, [hideLoadingBar]);

    const showMoreReports = () => {
        if (reportsToShow >= MAX_ITEMS_PENDING_REVIEW) {
            router.push('/reports');
            return;
        }
        setReportsToShow(reportsToShow + ITEMS_PENDING_REVIEW_INTERVAL);
    };

    const displayedViews = slice(custodialReportViews, 0, reportsToShow);

    const isPrimaryReportingOfficer = ({
        isAssignedInCase,
        assigneeRoleId,
        responsibleOfficers,
        assignedPersonnelUnitAttrId,
    }) => {
        if (isAssignedInCase) {
            if (!assigneeRoleId && !assignedPersonnelUnitAttrId) {
                return true;
            }
        } else if (responsibleOfficers) {
            return true;
        }
        return false;
    };

    const data = (displayedViews) =>
        map(displayedViews, (view) => {
            return {
                view: {
                    ...view,
                    displayName: formatResponsibleParty(view),
                },
            };
        });

    const getPrimaryReportingOfficerDisplayViews = () =>
        filter(displayedViews, (view) => {
            return isPrimaryReportingOfficer(view);
        });

    const filteredData = investigationEnhancementsEnabled
        ? data(getPrimaryReportingOfficerDisplayViews())
        : data(displayedViews);

    let content = null;

    if (loading) {
        content = <DashboardRightCardsLoader />;
    } else if (error) {
        content = <NoDataBlock>{strings.loadError}</NoDataBlock>;
    } else if (custodialReportViews.length === 0) {
        content = <NoDataBlock>{strings.noResults}</NoDataBlock>;
    } else {
        content = (
            <>
                <DashboardTable
                    as="div"
                    data={filteredData}
                    renderHeader={() => <HeaderRow />}
                    renderRow={renderRow}
                />
                {displayedViews.length < custodialReportViews.length && (
                    <ShowMoreButton isSticky onClick={showMoreReports}>
                        {strings.showMore}
                    </ShowMoreButton>
                )}
            </>
        );
    }

    return (
        <DashboardCard
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
            actions={
                <Button isTextTransformNone variant="ghost" asLink to="/evidence/dashboard">
                    {strings.viewAll}
                </Button>
            }
            title={itemsPendingDispositionReviewDisplayName}
            helpText={strings.helpText(get(custodialPropertySummaryReportDefinition, 'name'))}
        >
            {content}
        </DashboardCard>
    );
}

export default ItemsPendingReview;

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatAddressLinesForLocationBundle } from '~/client-common/core/domain/locations/utils/locationHelpers';
import { filterArrayForInput } from '~/client-common/helpers/filterHelpers';

/**
 * Filters a list of person profile view models by a filter value
 * @param {string} filterValue The value to filter on
 * @param {PersonProfileViewModel[]} The person profile view models to retunr
 *
 * @return {PersonProfileViewModel[]}
 */
export function filterPersonProfileViewModelsByFilter(filterValue, personProfileViewModels) {
    return filterArrayForInput({
        filterValue,
        models: personProfileViewModels,
        getFilterValueFromModel: (personProfileViewModel) =>
            getViewModelProperties(personProfileViewModel).fullName,
    });
}

/**
 * Filters a list of location bundles by a filter value
 * @param {string} filterValue The value to filter on
 * @param {LocationBundle[]} The location bundles to retunr
 *
 * @return {LocationBundle[]}
 */
export function filterLocationBundlesByFilter(filterValue, locationBundles, isSubPremiseSupportEnabled) {
    return filterArrayForInput({
        filterValue,
        models: locationBundles,
        getFilterValueFromModel: (locationBundle) =>
            formatAddressLinesForLocationBundle({ locationBundle, isSubPremiseSupportEnabled }).join(' '),
    });
}

import { CourtOrder } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'courtOrders';
const courtOrdersModule = createGroupedModule<CourtOrder>({
    type: NEXUS_STATE_PROP,
    key: 'reportId',
});

// SELECTORS

export const courtOrderSelector = courtOrdersModule.selectors.entitiesSelector;
export const courtOrdersByReportIdSelector = courtOrdersModule.selectors.entitiesByKeySelector;

// REDUCER
export default courtOrdersModule.reducerConfig;

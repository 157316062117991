import React from 'react';
import { get, reject, every, map } from 'lodash';

import valueEmptyForObjects from '../utils/valueEmptyForObjects';
import FlexContainer from './core/FlexContainer';
import PropertyRow from './PropertyRow';
import MergeNoticeRow from './MergeNoticeRow';

const GenericPropertyList = ({
    onValueChange,
    selectedValues = {},
    fieldDisplayNames,
    formatAttributeById,
    propertyConfiguration,
    models,
    similarityVector,
    applicationSettings,
}) => {
    // TODO if this is too slow inline, move it out and compute it
    // when component is constructed
    const mergeableFields = reject(
        propertyConfiguration,
        ({ prop, type, relatedFields, featureFlag }) => {
            if (featureFlag && !applicationSettings[featureFlag]) {
                return true;
            }
            const isEmpty = valueEmptyForObjects({ prop, objects: models, fieldType: type });
            if (!relatedFields) {
                return isEmpty;
            }
            return (
                isEmpty &&
                every(relatedFields, ({ prop, type }) =>
                    valueEmptyForObjects({ prop, objects: models, fieldType: type })
                )
            );
        }
    );

    return (
        <FlexContainer
            flexDirection="column"
            backgroundColor="white"
            flexGrow={1}
            flexShrink={1}
            flexBasis="100%"
            justify="flex-start"
        >
            <MergeNoticeRow />
            {map(mergeableFields, (field, i) => (
                <PropertyRow
                    key={field.prop}
                    onValueChange={onValueChange}
                    selectedValue={selectedValues[field.prop]}
                    fieldDisplayNames={fieldDisplayNames}
                    fieldConfig={field}
                    formatAttributeById={formatAttributeById}
                    models={models}
                    last={i === mergeableFields.length - 1}
                    similarity={get(similarityVector, field.prop)}
                    even={similarityVector ? undefined : i % 2 === 0}
                />
            ))}
        </FlexContainer>
    );
};

export default GenericPropertyList;

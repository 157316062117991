import { AttributeTypeEnum } from '@mark43/rms-api';
import { isEmpty } from 'lodash';
import React from 'react';

import { lifecycleOptions, formEvents } from 'markformythree';

import {
    formName,
    getFormConfiguration,
} from '../../../state/forms/arrest-charges-side-panel/legacyOffenseForm';
import formsRegistry from '../../../../../../core/formsRegistry';
import ArbiterForm from '../../../../../core/markformythree-arbiter/ArbiterForm';
import Row from '../../../../../core/components/Row';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';

export const unregisterLegacyOffenseScreenForm = () => formsRegistry.unregister(formName);

export const onSaveSidePanel = () => {
    const form = formsRegistry.get(formName);
    const { formErrors, success } = form.validate({ eventType: formEvents.FORM_SUBMIT });

    if (isEmpty(formErrors) && success) {
        const { legacyCharge, legacyEventNumber, legacyChargeSourceAttrId } = form.get();
        unregisterLegacyOffenseScreenForm();
        return {
            success,
            legacyCharge,
            legacyEventNumber,
            legacyChargeSourceAttrId,
        };
    }
    return {
        success,
        formErrors,
    };
};

export const onCancelSidePanel = unregisterLegacyOffenseScreenForm;

const LegacyOffenseScreen = ({ screenState: { editLegacyOffensePrefill } }) => {
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={formName}
            context={formName}
            initialState={editLegacyOffensePrefill}
            configuration={getFormConfiguration()}
            render={() => {
                return (
                    <>
                        <Row>
                            <ArbiterMFTText path="legacyCharge" length="lg" autoFocus={true} />
                        </Row>
                        <Row>
                            <ArbiterMFTText path="legacyEventNumber" length="lg" />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="legacyChargeSourceAttrId"
                                attributeType={AttributeTypeEnum.LEGACY_CHARGE_SOURCE.name}
                                length="lg"
                            />
                        </Row>
                    </>
                );
            }}
        />
    );
};

export default LegacyOffenseScreen;

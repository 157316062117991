import { createSelector } from 'reselect';
import { map } from 'lodash';

import { buildViewModel } from '../../../../../helpers/viewModelHelpers';

export function createAttachmentsResultsSelector(baseSelector) {
    return createSelector(baseSelector, (currentResults) =>
        map(
            currentResults,
            buildViewModel({
                recursive: false,
                mappers: [],
                helpers: {},
            })
        )
    );
}

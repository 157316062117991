import { useSelector } from 'react-redux';
import { itemEvidenceStateByMasterItemIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import type { ManageEvidenceDashboardResults } from '../types';

export const useIsHighValue = ({
    masterItemId,
    isHighValue,
    isInHighValueContainer,
}: Pick<
    ManageEvidenceDashboardResults,
    'masterItemId' | 'isHighValue' | 'isInHighValueContainer'
>) => {
    const itemEvidenceStateByMasterItemId = useSelector(itemEvidenceStateByMasterItemIdSelector);
    const itemEvidenceState = itemEvidenceStateByMasterItemId(masterItemId);

    /** These values can exist in two places, from the view model
    OR from the item evidence state and they are not always the same.
    Maybe future opportunity to combine the source of truth. */
    const itemIsHighValue =
        itemEvidenceState?.isHighValue ||
        itemEvidenceState?.isInHighValueContainer ||
        isHighValue ||
        isInHighValueContainer;

    return !!itemIsHighValue;
};

// NOTE This file needs to be refactored to use client-common/
import { createSelector } from 'reselect';
import {
    storeDispatchAreas,
    dispatchAreasSelector,
} from '~/client-common/core/domain/dispatch-areas/state/data';
import { agencyProfileOptionsWhereSelector } from '~/client-common/core/domain/agency-profiles/state/ui';

import dispatchAreasResource from '../../resources/dispatchAreasResource';

export const LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_START =
    'dispatch_areas/LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_START';
export const LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_SUCCESS =
    'dispatch_areas/LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_SUCCESS';
export const LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_FAILURE =
    'dispatch_areas/LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_FAILURE';
export const UPSERT_DISPATCH_AREAS_START = 'dispatch_areas/UPSERT_DISPATCH_AREAS_START';
export const UPSERT_DISPATCH_AREAS_SUCCESS = 'dispatch_areas/UPSERT_DISPATCH_AREAS_SUCCESS';
export const UPSERT_DISPATCH_AREAS_FAILURE = 'dispatch_areas/UPSERT_DISPATCH_AREAS_FAILURE';

function loadDispatchAreasAndRadioChannelsStart() {
    return {
        type: LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_START,
    };
}

function loadDispatchAreasAndRadioChannelsSuccess({ radioChannels }) {
    return {
        type: LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_SUCCESS,
        payload: { radioChannels },
    };
}

function loadDispatchAreasFailure(err) {
    return {
        type: LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_FAILURE,
        payload: err,
    };
}

function upsertDispatchAreasStart() {
    return {
        type: UPSERT_DISPATCH_AREAS_START,
    };
}

function upsertDispatchAreasSuccess() {
    return {
        type: UPSERT_DISPATCH_AREAS_SUCCESS,
    };
}

function upsertDispatchAreasFailure(err) {
    return {
        type: UPSERT_DISPATCH_AREAS_FAILURE,
        payload: err,
    };
}

export function loadDispatchAreasAdmin(deptId) {
    return function (dispatch) {
        dispatch(loadDispatchAreasAndRadioChannelsStart());
        return Promise.all([
            dispatchAreasResource.getDispatchAreas(),
            dispatchAreasResource.getRadioChannelsForDept(deptId),
        ])
            .then(([dispatchAreas, radioChannels]) => {
                dispatch(storeDispatchAreas(dispatchAreas));
                dispatch(loadDispatchAreasAndRadioChannelsSuccess({ radioChannels }));
            })
            .catch((err) => {
                dispatch(loadDispatchAreasFailure(err));
            });
    };
}

export function upsertDispatchArea(upsertRequest) {
    return function (dispatch) {
        dispatch(upsertDispatchAreasStart());
        return dispatchAreasResource
            .upsertDispatchArea(upsertRequest)
            .then((result) => {
                dispatch(storeDispatchAreas(result));
                dispatch(upsertDispatchAreasSuccess());
                return result;
            })
            .catch((err) => {
                dispatch(upsertDispatchAreasFailure(err));
            });
    };
}

export const agencyOptionsByAgencyTypeGlobalAttrIdSelector = createSelector(
    agencyProfileOptionsWhereSelector,
    (agencyProfileOptionsWhere) => (agencyTypeGlobalAttrId) =>
        agencyProfileOptionsWhere(
            ({ agencyTypeGlobalAttrId: testAgencyTypeGlobalAttrId }) =>
                testAgencyTypeGlobalAttrId === agencyTypeGlobalAttrId
        )
);

export const existingDispatchAreasSelector = createSelector(
    dispatchAreasSelector,
    (dispatchAreas) => {
        return Object.values(dispatchAreas || {}).reduce((set, dArea) => {
            set.add(dArea.name);

            return set;
        }, new Set());
    }
);

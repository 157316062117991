import { get, isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { currentUserDepartmentProfileSelector } from '../../../../core/current-user/state/ui';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    formName,
    getChargesForm,
    createChargesForm,
    buildChargesCardFormModel,
} from '../../state/forms/chargesForm';
import { registerForm } from '../../state/ui';
import withCard from '../../utils/withCard';
import chargesCard from '../../state/ui/chargesCard';
import testIds from '../../../../../core/testIds';
import { registerCard } from '../../utils/cardsRegistry';
import Card from '../../../../../legacy-redux/components/core/Card';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import ChargesCardForm from './ChargesCardForm';
import ChargesCardSummary from './ChargesCardSummary';

class ChargesCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            buildChargesCardFormModel,
            reportId,
            index,
        } = this.props;

        const form = createChargesForm({
            initialState: buildChargesCardFormModel({ reportId }),
            arbiter,
            formatFieldByName,
        });

        registerForm({ form, index });
        this.unregisterCard = registerCard({
            cardModule: chargesCard,
            onSave: this.onSave,
            index: this.props.index,
        });
    }

    componentWillUnmount() {
        this.unregisterCard();
        formsRegistry.unregister(formName, this.props.index);
    }

    onEdit = () => {
        const { editCallback, onEdit, index } = this.props;
        editCallback(() => onEdit({ index }));
    };

    onSaveProgress = () => {
        const form = getChargesForm(this.props.index);
        return this.props.onSaveProgress(form, { index: this.props.index });
    };

    onSave = () => {
        const form = getChargesForm(this.props.index);
        return this.props.onSave(form, { index: this.props.index });
    };

    render() {
        const {
            card = {},
            reportId,
            index,
            arrestForReportId,
            chargesWhere,
            currentUserDepartmentProfile,
            currentReportCardUITitleByType,
        } = this.props;
        const {
            anchor,
            errorMessages,
            summaryMode: cardSummaryMode,
            canEditReportCardStatus,
            saving,
            anchorForIndex,
        } = card;
        const arrest = arrestForReportId(reportId) || {};
        const { id: arrestId } = arrest;
        const chargesForArrest = chargesWhere({ arrestId });

        const showNibrsCode =
            checkIfDepartmentIsNibrs(currentUserDepartmentProfile) && !isEmpty(chargesForArrest);
        const cardTitle = currentReportCardUITitleByType(reportCardEnum.CHARGES.id);

        return (
            <Card
                className={anchor}
                anchor={anchorForIndex(this.props.index)}
                title={cardTitle}
                testId={testIds.CHARGES_CARD}
                renderContent={(summaryMode) => {
                    return (
                        <>
                            {summaryMode ? (
                                <ChargesCardSummary
                                    arrest={arrest}
                                    showNibrsCode={showNibrsCode}
                                    index={index}
                                />
                            ) : (
                                <ChargesCardForm
                                    arrest={arrest}
                                    showNibrsCode={showNibrsCode}
                                    index={index}
                                />
                            )}
                        </>
                    );
                }}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={cardSummaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                saving={saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class ChargesCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => <ChargesCard ref={this.setRef} {...this.props} arbiter={arbiter} />}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    arrestForReportId: arrestForReportIdSelector,
    chargesWhere: chargesWhereSelector,
    currentUserDepartmentProfile: currentUserDepartmentProfileSelector,
    formatFieldByName: formatFieldByNameSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = {
    buildChargesCardFormModel,
};

export default compose(
    withCard(chargesCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(ChargesCardWrapper);

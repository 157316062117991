import React from 'react';

import { useSelector } from 'react-redux';
import { AttributeTypeEnum } from '@mark43/rms-api';
import { map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

import { itemAttributesWhereSelector } from '~/client-common/core/domain/item-attributes/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import EntityProfileSectionNoData from '../../core/components/EntityProfileSectionNoData';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
} from '../../core/components/EntityProfileSection';

const strings = componentStrings.entityProfiles.vehicle.AppearanceDetails;

function AppearanceDetails({ vehicleViewModel }) {
    const display = getViewModelProperties(vehicleViewModel);

    const itemAttributesWhere = useSelector(itemAttributesWhereSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const vehicleAdditionalSecondaryColors = itemAttributesWhere({
        itemProfileId: vehicleViewModel.id,
        attributeType: AttributeTypeEnum.ITEM_COLOR.name,
    });
    const additionalSecondaryColors = map(vehicleAdditionalSecondaryColors, ({ attributeId }) =>
        formatAttributeById(attributeId)
    ).join(', ');
    const joinedPrimaryAndSecondaryColor = joinTruthyValues(
        [display.primaryColorAttrId, display.secondaryColorAttrId, additionalSecondaryColors],
        ' & '
    );

    return (
        <EntityProfileSummaryColumn className="one-column">
            <EntityProfileDetailSummaryList>
                <SummaryRow label={strings.labels.makeModel}>{display.vehicleMakeModel}</SummaryRow>
                <SummaryRow label={strings.labels.yearOfManufacture}>
                    {vehicleViewModel.yearOfManufacture}
                </SummaryRow>
                <SummaryRow label={strings.labels.color}>
                    {joinedPrimaryAndSecondaryColor}
                </SummaryRow>
                <SummaryRow label={strings.labels.category}>
                    {display.itemCategoryAttrId}
                </SummaryRow>
                <SummaryRow label={strings.labels.bodyStyle}>{display.bodyStyle}</SummaryRow>
                <SummaryRow label={strings.labels.description}>
                    {vehicleViewModel.description}
                </SummaryRow>
            </EntityProfileDetailSummaryList>
        </EntityProfileSummaryColumn>
    );
}

const AppearanceDetailsBranch = renderOnlyIf(({ vehicleViewModel }) => {
    const display = getViewModelProperties(vehicleViewModel);
    return (
        vehicleViewModel.tag ||
        vehicleViewModel.vinNumber ||
        display.registrationState ||
        vehicleViewModel.registrationYear ||
        vehicleViewModel.registrationType ||
        vehicleViewModel.insuranceProviderName ||
        vehicleViewModel.insurancePolicyNumber ||
        (display.identifiers && display.identifiers.length)
    );
}, EntityProfileSectionNoData)(AppearanceDetails);

export default function AppearanceDetailsWrapper(props) {
    return (
        <EntityProfileSection title={strings.title}>
            <AppearanceDetailsBranch {...props} />
        </EntityProfileSection>
    );
}

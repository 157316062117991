import { OperationTypeEnum, EntityTypeEnum, AttributeTypeEnum } from '@mark43/rms-api';
import { get, omit, map, compact, uniq, chain, pick, filter } from 'lodash';
import { createFormConfiguration, createNItems, createFieldset } from 'markformythree';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { externalReportStatusConfiguration } from '../../../../core/external-report-statuses/state/form/externalReportStatusForm';
import { offenseCaseStatusesConfiguration } from '../../../../core/offense-case-statuses/state/form/offenseCaseStatusesForm';
import { convertFromFormModelToCaseRequest } from './reasonForRelationForm';

const convertCaseAttributeFormModelToDataModel = ({
    attributes,
    caseId,
    departmentId,
    attributeTypeName,
    attributeIds,
    description,
}) => {
    return assignDescriptionToReportAttributes(
        map(attributeIds, (attributeId) => {
            return {
                caseId,
                departmentId,
                attributeType: attributeTypeName,
                attributeId,
            };
        }),
        attributes,
        description
    );
};

const reportCaseStatusesConfiguration = createNItems({
    fields: {
        caseStatusAttrId: {
            fieldName: fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
        },
        closedByDivisionAttrId: {
            fieldName: fields.REPORT_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
        },
        closedByUnitAttrId: {
            fieldName: fields.REPORT_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID,
        },
        statusDateUtc: {
            fieldName: fields.REPORT_CASE_STATUS_STATUS_DATE_UTC,
        },
        isCanceled: {
            fieldName: fields.REPORT_CASE_STATUS_IS_CANCELED,
        },
        canceledById: {
            fieldName: fields.REPORT_CASE_STATUS_CANCELED_BY_ID,
        },
    },
});

const caseStatusConfiguration = createFieldset({
    fieldName: fields.CASE_STATUS_STATUS_ATTR_ID,
    fields: {
        statusAttrId: {
            fieldName: fields.CASE_STATUS_STATUS_ATTR_ID,
        },
        closedByDivisionAttrId: {
            fieldName: fields.CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
        },
        closedByUnitAttrId: {
            fieldName: fields.CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID,
        },
        statusDateUtc: {
            fieldName: fields.CASE_STATUS_STATUS_DATE_UTC,
        },
    },
});

export const formConfiguration = createFormConfiguration({
    id: {},
    assignedDateUtc: {
        fieldName: fields.CASE_ASSIGNED_DATE_UTC,
    },
    assignedPersonnelUnitAttrId: {
        fieldName: fields.CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID,
    },
    assignee: {
        fieldName: fields.DISPLAY_CASE_ASSIGNEE,
    },
    caseDefinitionId: {
        fieldName: fields.CASE_CASE_DEFINITION_ID,
    },
    dueDateUtc: {
        fieldName: fields.CASE_DUE_DATE_UTC,
    },
    title: {
        fieldName: fields.CASE_TITLE,
    },
    isTargetProfile: {
        fieldName: fields.CASE_IS_TARGET_PROFILE,
    },
    targetProfileNarrative: {
        fieldName: fields.CASE_TARGET_PROFILE_NARRATIVE,
    },
    targetProfileCategoryAttrIds: {
        fieldName: fields.CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID,
    },
    targetProfileReviewDateUtc: {
        fieldName: fields.CASE_TARGET_PROFILE_REVIEW_DATE_UTC,
    },
    targetProfilePriorityAttrId: {
        fieldName: fields.CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_PRIORITY_ATTRIBUTE_ID,
    },
    targetProfileAreaAttrIds: {
        fieldName: fields.CASE_ATTRIBUTE_ATTRIBUTE_ID,
    },
    assistingInvestigators: {
        fieldName: fields.DISPLAY_CASE_ASSISTING_INVESTIGATORS,
    },
    supervisors: {
        fieldName: fields.DISPLAY_CASE_SUPERVISORS,
    },
    caseStatus: caseStatusConfiguration,
    externalReportStatus: externalReportStatusConfiguration,
    reportCaseStatuses: reportCaseStatusesConfiguration,
    offenseCaseStatuses: offenseCaseStatusesConfiguration,
});

export function convertCreateManageCaseFromFormModel(
    createManageCaseForm = {},
    {
        reasonForRelationFormModel = {},
        reportingEventNumbers,
        recordWithoutRenReportIds,
        assigneeCaseRoleLinkId,
        assigneeRoleId,
        caseStatusIsClosed,
        defaultCanceledCaseStatusAttribute,
        reportStatusesAreClosed,
        otherPersonnelEntityPermissions = [],
        attributes = {},
        individualReportSelectionEnabled,
    } = {}
) {
    const {
        id: caseId,
        departmentId,
        isTargetProfile,
        targetProfileCategoryAttrIds,
        targetProfilePriorityAttrId,
        targetProfileAreaAttrIds,
    } = createManageCaseForm;
    const isAssignedToDetective = !!get(createManageCaseForm, 'assignee');

    // Server handles updating assignedDateUtc if assignedPersonnelUnitAttrId
    // changes. Server should ALSO handle updating when assignee changes,
    // but that won't be doable until we flatten assignee onto Case,
    // and stop using CaseRoleLink, so we handle it client side.
    const assigneeChanged = assigneeRoleId !== get(createManageCaseForm, 'assignee');
    const assignedDateUtc = assigneeChanged ? nowUtc() : createManageCaseForm.assignedDateUtc;
    const c = {
        ...getCaseFormFields(createManageCaseForm),
        assignedDateUtc,
    };

    const assignee = isAssignedToDetective
        ? {
              caseId,
              id: assigneeCaseRoleLinkId,
              roleId: createManageCaseForm.assignee,
              caseRoleAttrId: globalAttributes.caseRoleGlobal.assignee,
              entityPermission: OperationTypeEnum.MANAGE.name,
          }
        : null;

    const assistingInvestigators = map(uniq(createManageCaseForm.assistingInvestigators), (ai) => ({
        roleId: ai,
        caseId,
        caseRoleAttrId: globalAttributes.caseRoleGlobal.assistingInvestigator,
        entityPermission: OperationTypeEnum.DELETE.name,
    }));

    const supervisors = map(uniq(createManageCaseForm.supervisors), (supervisorRoleId) => ({
        roleId: supervisorRoleId,
        caseId,
        caseRoleAttrId: globalAttributes.caseRoleGlobal.supervisor,
        entityPermission: OperationTypeEnum.MANAGE.name,
    }));

    const caseRoleLinks = compact([...supervisors, ...assistingInvestigators, assignee]);
    const entityPermissions = [
        ...otherPersonnelEntityPermissions,
        // add entity permissions for assignees and supervisors
        ...chain(caseRoleLinks)
            .map(({ roleId, entityPermission }) => ({
                entityId: caseId,
                entityType: EntityTypeEnum.CASE.name,
                roleId,
                operationType: entityPermission,
            }))
            .compact()
            .value(),
    ];

    const caseStatus = createManageCaseForm.caseStatus
        ? {
              id: createManageCaseForm.caseStatus.id,
              caseId,
              statusAttrId: createManageCaseForm.caseStatus.statusAttrId,
              closedByDivisionAttrId: caseStatusIsClosed
                  ? createManageCaseForm.caseStatus.closedByDivisionAttrId
                  : null,
              closedByUnitAttrId: caseStatusIsClosed
                  ? createManageCaseForm.caseStatus.closedByUnitAttrId
                  : null,
              statusDateUtc: caseStatusIsClosed
                  ? createManageCaseForm.caseStatus.statusDateUtc
                  : null,
          }
        : null;

    const reportCaseStatuses = map(createManageCaseForm.reportCaseStatuses, (reportCaseStatus) => {
        const { isCanceled, ...trimmedCaseStatus } = reportCaseStatus;
        const isClosed = reportStatusesAreClosed[reportCaseStatus.id];
        if (!defaultCanceledCaseStatusAttribute && isCanceled) {
            throw new Error(componentStrings.reports.ReportCaseStatusesForm.noCancelAttributeError);
        }
        return {
            ...trimmedCaseStatus,
            caseStatusAttrId: isCanceled
                ? defaultCanceledCaseStatusAttribute
                : reportCaseStatus.caseStatusAttrId,
            closedByDivisionAttrId: isClosed ? reportCaseStatus.closedByDivisionAttrId : null,
            closedByUnitAttrId: isClosed ? reportCaseStatus.closedByUnitAttrId : null,
            statusDateUtc: isClosed ? reportCaseStatus.statusDateUtc : null,
        };
    });

    const recordWithoutRenCaseReportLinks = recordWithoutRenReportIds.map(
        (linkedRecordWithoutRENId) => ({
            linkedRecordWithoutRENId,
        })
    );

    const reportRensCaseReportLinks = map(reportingEventNumbers, (linkedReportREN) => ({
        linkedReportREN,
    }));

    const {
        caseReportLinkCreationFromCaseRequests: caseReportLinks,
    } = convertFromFormModelToCaseRequest(
        reasonForRelationFormModel,
        caseId,
        individualReportSelectionEnabled
    );

    // Case report Link will always populate even if the RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE is off
    // in order to make a case-report link for if the FF is later turned on or switched between on/off
    const caseReportLinkCreationFromCaseRequests =
        caseReportLinks.length > 0
            ? caseReportLinks
            : [...recordWithoutRenCaseReportLinks, ...reportRensCaseReportLinks];

    const targetProfileCategoryAttributes = convertCaseAttributeFormModelToDataModel({
        attributes,
        caseId,
        departmentId,
        attributeTypeName: AttributeTypeEnum.TARGET_PROFILE_CATEGORY.name,
        attributeIds: targetProfileCategoryAttrIds,
    });

    const targetProfilePriorityAttributes = convertCaseAttributeFormModelToDataModel({
        attributes,
        caseId,
        departmentId,
        attributeTypeName: AttributeTypeEnum.TARGET_PROFILE_PRIORITY.name,
        attributeIds: [targetProfilePriorityAttrId],
    });

    const targetProfileAreaAttributes = Object.values(pick(attributes, targetProfileAreaAttrIds));

    const getAttributesFromIdsByAttributeType = (attributeType) => {
        const attributeIds = map(filter(targetProfileAreaAttributes, { attributeType }), 'id');

        return convertCaseAttributeFormModelToDataModel({
            attributes,
            caseId,
            departmentId,
            attributeTypeName: attributeType,
            attributeIds,
        });
    };

    const targetProfileAreaAttributesConverted = [
        ...getAttributesFromIdsByAttributeType(AttributeTypeEnum.SUBDIVISION_DEPTH_1.name),
        ...getAttributesFromIdsByAttributeType(AttributeTypeEnum.SUBDIVISION_DEPTH_2.name),
        ...getAttributesFromIdsByAttributeType(AttributeTypeEnum.SUBDIVISION_DEPTH_3.name),
        ...getAttributesFromIdsByAttributeType(AttributeTypeEnum.SUBDIVISION_DEPTH_4.name),
        ...getAttributesFromIdsByAttributeType(AttributeTypeEnum.SUBDIVISION_DEPTH_5.name),
    ];

    return {
        c,
        caseRoleLinks,
        entityPermissions,
        caseStatus,
        reportCaseStatuses,
        caseReportLinkCreationFromCaseRequests,
        caseAttributes: isTargetProfile
            ? [
                  ...targetProfileCategoryAttributes,
                  ...targetProfilePriorityAttributes,
                  ...targetProfileAreaAttributesConverted,
              ]
            : [],
    };
}

export function getCaseFormFields(createManageCaseForm = {}) {
    return omit(createManageCaseForm, [
        'assignee',
        'supervisors',
        'caseStatus',
        'externalCaseStatus',
        'reportCaseStatuses',
        'offenseCaseStatuses',
        'assistingInvestigators',
        'targetProfileCategoryAttrIds',
        'targetProfilePriorityAttrId',
        'targetProfileAreaAttrIds',
    ]);
}

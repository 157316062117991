import { sortBy } from 'lodash';
import { NameReportLink } from '@mark43/rms-api';

const sortNameReportLinks = ({
    nameReportLinks,
}: {
    nameReportLinks: NameReportLink[];
}): NameReportLink[] => {
    return sortBy(nameReportLinks, ['linkTypeSequenceNumber', 'rmsEventId']);
};

export default sortNameReportLinks;

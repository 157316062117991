import { Dispatch } from 'redux';
import { RotationTypeSuspensionBundle } from '@mark43/cad-api';

import { AddOrEditSuspensionFormFields } from '../types';
import { RmsAction } from '../../../../../core/typings/redux';
import cadServiceProviderResource from '../../resources/cadServiceProviderResource';

export const SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_START =
    'cad-service-rotation-providers/SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_START';
export const SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_SUCCESS =
    'cad-service-rotation-providers/SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_SUCCESS';
export const SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_FAILURE =
    'cad-service-rotation-providers/SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_FAILURE';
export const SHOW_SUSPENSION_REQUEST_ERROR_MESSAGE =
    'cad-service-rotation-providers/SHOW_SUSPENSION_REQUEST_ERROR_MESSAGE';
export const CLEAR_SUSPENSION_ERROR_MESSAGES =
    'cad-service-rotation-providers/CLEAR_SUSPENSION_ERROR_MESSAGES';
export const FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_START =
    'cad-service-rotation-providers/FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_START';
export const FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_SUCCESS =
    'cad-service-rotation-providers/FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_SUCCESS';
export const FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_FAILURE =
    'cad-service-rotation-providers/FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_FAILURE';

export function fetchCadServiceProviderSuspensionsHistorySuccess(
    history: RotationTypeSuspensionBundle[]
) {
    return {
        type: FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_SUCCESS,
        payload: history,
    };
}

export function fetchCadServiceProviderSuspensionsHistoryFailure() {
    return {
        type: FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_FAILURE,
    };
}

export function fetchCadServiceProviderSuspensionsHistoryStart() {
    return {
        type: FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_START,
    };
}

export function saveCadServiceProviderSuspensionStart() {
    return {
        type: SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_START,
    };
}

export function saveCadServiceProviderSuspensionSuccess() {
    return {
        type: SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_SUCCESS,
    };
}

export function saveCadServiceProviderSuspensionFailure(errorMessage: string) {
    return {
        type: SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

export function showSuspensionRequestErrorMessage(errorMessage: string) {
    return {
        type: SHOW_SUSPENSION_REQUEST_ERROR_MESSAGE,
        payload: errorMessage,
    };
}

export function clearSuspensionErrorMessages() {
    return {
        type: CLEAR_SUSPENSION_ERROR_MESSAGES,
    };
}

export function sendCadServiceProviderSuspensionRequest(
    resourceMethodName: string,
    serviceProviderId: number,
    suspension: Partial<AddOrEditSuspensionFormFields>,
    handleSuccess?: (response: RotationTypeSuspensionBundle) => void,
    handleFailure?: (error: Error) => void
): RmsAction<Promise<void>> {
    return function () {
        return cadServiceProviderResource[
            resourceMethodName as keyof typeof cadServiceProviderResource
        ](suspension, serviceProviderId)
            .then((newSuspensionsInfo) => {
                handleSuccess?.(newSuspensionsInfo as RotationTypeSuspensionBundle);
            })
            .catch((error) => {
                handleFailure?.(error);
            });
    };
}

export function getCadServiceProviderSuspensionsHistory(serviceProviderId: number) {
    return function (dispatch: Dispatch) {
        dispatch(fetchCadServiceProviderSuspensionsHistoryStart());

        return cadServiceProviderResource
            .getCadServiceProviderSuspensionsHistory(serviceProviderId)
            .then((response) =>
                dispatch(
                    fetchCadServiceProviderSuspensionsHistorySuccess(
                        response as RotationTypeSuspensionBundle[]
                    )
                )
            )
            .catch(() => dispatch(fetchCadServiceProviderSuspensionsHistoryFailure()));
    };
}

import { DefaultFolderView, EntityTypeEnumType } from '@mark43/rms-api';
import _, { isEmpty } from 'lodash';

type DeepPartial<DefaultFolderView> = {
    [P in keyof DefaultFolderView]?: DeepPartial<DefaultFolderView[P]>;
};
interface DefaultFoldersFormModel {
    name: string;
    defaultSubFolders?: DefaultFoldersFormModel[];
    id?: number;
}

export function convertToDefaultFolderView({
    formModel,
    entityTypeId,
    ownerTypeId,
    ownerId,
}: {
    formModel: DefaultFoldersFormModel[] | undefined;
    entityTypeId: EntityTypeEnumType;
    ownerTypeId: EntityTypeEnumType;
    ownerId: number;
}): DeepPartial<DefaultFolderView>[] {
    if (isEmpty(formModel)) {
        return [];
    }

    return _(formModel)
        .map((folder) => {
            const { defaultSubFolders, ...rest } = folder;
            const defaultFolderView: DeepPartial<DefaultFolderView> = {
                folder: {
                    ...rest,
                    entityTypeId,
                    ownerTypeId,
                    ownerId,
                },
                subFolderViews: convertToDefaultFolderView({
                    formModel: defaultSubFolders,
                    entityTypeId,
                    ownerTypeId,
                    ownerId,
                }),
            };

            return defaultFolderView;
        })
        .value();
}

export function convertFromDefaultFolderView({
    defaultFolderViews,
    entityTypeId,
}: {
    defaultFolderViews: DefaultFolderView[];
    entityTypeId: EntityTypeEnumType;
}): DefaultFoldersFormModel[] {
    if (isEmpty(defaultFolderViews)) {
        return [];
    }

    return _(defaultFolderViews)
        .filter(({ folder }) => folder.entityTypeId === entityTypeId)
        .map((defaultFolderView) => {
            const { name, id } = defaultFolderView.folder;

            return {
                name,
                id,
                defaultSubFolders: convertFromDefaultFolderView({
                    defaultFolderViews: defaultFolderView.subFolderViews,
                    entityTypeId,
                }),
            };
        })
        .sortBy([(folder) => folder.name.toLowerCase()])
        .value();
}

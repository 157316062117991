import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../../core/testIds';
import _Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import RecordsHeaderButton from './RecordsHeaderButton';

const strings = componentStrings.core.header.RecordsHeaderBookingButton;

const Button = styled(_Button)`
    margin: 0;
    width: 38px;
    height: 30px;
    box-sizing: border-box;
    background-color: var(--arc-colors-selected-accent);

    .mark43-icon::before {
        float: none;
    }
`;

function RecordsHeaderBookingButton({ className, onClick }) {
    return (
        <RecordsHeaderButton overlay={strings.tooltip}>
            <Button
                className={classNames(buttonTypes.ICON, className)}
                iconLeft={<Icon size={18} color="cobaltBlue" type={iconTypes.BOOKING} />}
                onClick={onClick}
                testId={testIds.REPORT_HEADER_BOOKING_BUTTON}
            />
        </RecordsHeaderButton>
    );
}

// Button for making the booking button open on arrest reports
// will open the arrests side panel
export default RecordsHeaderBookingButton;

import { ReportUcr } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'reportUcrs';

const reportUcrsModule = createGroupedModule<ReportUcr>({
    type: NEXUS_STATE_PROP,
    key: 'reportId',
});

// ACTIONS
export const storeReportUcrs = reportUcrsModule.actionCreators.storeEntities;

// SELECTORS

export const reportUcrsByReportIdSelector = reportUcrsModule.selectors.entitiesByKeySelector;

// REDUCER
export default reportUcrsModule.reducerConfig;

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'reports';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Tow Vehicle Resource',
        methods: {
            updateTowVehicle(towVehicle) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${towVehicle.reportId}/tow_vehicle`,
                    data: towVehicle,
                });
            },
        },
    });
}

/**
 * Resources for TowVehicles. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

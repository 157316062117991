import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { first } from 'lodash';
import { Text } from 'arc';
import { createStructuredSelector } from 'reselect';

import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { organizationLocationsByProfileIdSelector } from '~/client-common/core/domain/organization-profiles/state/ui';
import { buildOrgProfileLink } from '~/client-common/core/domain/organization-profiles/utils/organizationProfilesHelper';
import componentStrings from '~/client-common/core/strings/componentStrings';

import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { iconTypes } from '../../../../core/components/Icon';
import EntitySummaryHeader from './EntitySummaryHeader';
import { LocationTitleSummary } from './locations/LocationTitleSummary';

const strings = componentStrings.summaries.OrganizationProfileTitleSummary;

const LocationContainer = styled.div`
    margin-top: 12px;
`;

const UnconnectedOrganizationProfileTitleSummary = ({
    collapsed,
    imageUrl,
    linkTypeLetterNumber,
    organizationProfileViewModel,
    organizationLocationsByProfileId,
}) => {
    const { organizationTypeAttrId } = getViewModelProperties(organizationProfileViewModel);
    const mostRecentPhysicalAddress = first(
        organizationLocationsByProfileId(organizationProfileViewModel.id)
    );

    return (
        <EntitySummaryHeader
            imageUrl={imageUrl}
            iconType={iconTypes.ORGANIZATION}
            preTitle={linkTypeLetterNumber}
            name={organizationProfileViewModel.name}
            linkTo={buildOrgProfileLink(organizationProfileViewModel)}
        >
            {!!collapsed && (
                <>
                    <SummaryList>
                        <SummaryRow
                            fieldName={fields.ORGANIZATION_PROFILE_ORGANIZATION_TYPE_ATTR_ID}
                        >
                            {organizationTypeAttrId}
                        </SummaryRow>
                    </SummaryList>
                    {!!mostRecentPhysicalAddress && (
                        <LocationContainer>
                            <Text color="tertiary">{strings.physicalAddress}:</Text>
                            <LocationTitleSummary
                                locationBundle={mostRecentPhysicalAddress}
                                boldAddress={true}
                                showCountry={false}
                            />
                        </LocationContainer>
                    )}
                </>
            )}
        </EntitySummaryHeader>
    );
};

export const OrganizationProfileTitleSummary = connect(
    createStructuredSelector({
        organizationLocationsByProfileId: organizationLocationsByProfileIdSelector,
    })
)(UnconnectedOrganizationProfileTitleSummary);

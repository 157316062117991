import React from 'react';

interface MaterialAttachmentsContextT {
    selectedMaterialIds: number[];
    setSelectedMaterialIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const EFileMaterialAttachmentsContext = React.createContext<
    MaterialAttachmentsContextT | undefined
>(undefined);

export const EFileMaterialAttachmentsProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [selectedMaterialIds, setSelectedMaterialIds] = React.useState<number[]>([]);
    return (
        <EFileMaterialAttachmentsContext.Provider
            value={{
                selectedMaterialIds,
                setSelectedMaterialIds,
            }}
        >
            {children}
        </EFileMaterialAttachmentsContext.Provider>
    );
};

/**
 * The state of material attachments currently selected by the user.
 */
export function useMaterialAttachments(): MaterialAttachmentsContextT {
    const context = React.useContext(EFileMaterialAttachmentsContext);
    if (context === undefined) {
        throw new Error('Must be inside EFileMaterialAttachmentsProvider');
    }
    return context;
}

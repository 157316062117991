import PropTypes from 'prop-types';
import { Icon as ArcIcon, cssVar } from 'arc';
import React from 'react';
import styled from 'styled-components';
import { Icon, iconTypes } from 'components-mark43';
import { compose, withHandlers, pure } from 'recompose';

const KINGFISHER = '#54a3ff';
const KINGFISHER_OPACITY_50 = 'rgba(84, 163, 255, 0.5)';
const WHITE = '#ffffff';

const ZoomControlsWrapper = styled.div`
    width: 37px;
    right: 12px;
    z-index: 1; /* needed to get above map */
    ${({ zoomControlPosition }) => {
        switch (zoomControlPosition) {
            case 'middle':
                return `
                    top: 50%;
                    transform: translateY(-50%);`;
            case 'top':
            default:
                return `top: 19px;`;
        }
    }}

    ${({ isArcButtonStyle }) =>
        isArcButtonStyle &&
        `
        & div:first-child {
            border-radius: 3px 3px 0px 0px;
        }

        & div:last-child {
            border-radius: 0px 0px 3px 3px;
        }
    `}

    position: absolute;
`;

const ButtonColors = styled.div`
    border-color: ${KINGFISHER};
    background-color: ${({ isButtonActive }) => (isButtonActive ? KINGFISHER : '#063871')};
    color: white;
`;

const ControlButton = styled(ButtonColors)`
    float: left;
    clear: both;
    text-align: center;
    cursor: pointer;
    margin-bottom: ${({ compactZoomControls }) => (compactZoomControls ? '5px' : '0')};
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ isArcButtonStyle }) =>
        isArcButtonStyle
            ? `
                    width: 23px;
                    height:24px;
                    background-color: ${cssVar('arc.colors.surface.background')};
                    border: 1px solid ${cssVar('arc.colors.brand.default')};
                `
            : `
                width: 37px;
                height: 37px;
                border-width: 2px;
                border-style: solid;
                border-radius: 3px;
                `}

    &:hover {
        background-color: ${KINGFISHER_OPACITY_50};
    }
`;

const ButtonIcon = styled(Icon)`
    margin-top: -2px;
`;

const SimpleZoomControls = (
    {
        compactZoomControls = false,
        zoomIn,
        zoomOut,
        zoomControlPosition,
        zoomButtonStyle,
    } /* : PropsT */
) => {
    const isArcButtonStyle = zoomButtonStyle === 'arc';
    return (
        <ZoomControlsWrapper
            zoomControlPosition={zoomControlPosition}
            isArcButtonStyle={isArcButtonStyle}
        >
            <ControlButton
                compactZoomControls={compactZoomControls}
                onClick={zoomIn}
                isArcButtonStyle={isArcButtonStyle}
            >
                {isArcButtonStyle ? (
                    <ArcIcon icon="Add" color={cssVar('arc.colors.brand.default')} />
                ) : (
                    <ButtonIcon type={iconTypes.ADD} color={WHITE} />
                )}
            </ControlButton>
            <ControlButton onClick={zoomOut} isArcButtonStyle={isArcButtonStyle}>
                {isArcButtonStyle ? (
                    <ArcIcon icon="Close" color={cssVar('arc.colors.brand.default')} />
                ) : (
                    <ButtonIcon type={iconTypes.CLOSE} color={WHITE} />
                )}
            </ControlButton>
        </ZoomControlsWrapper>
    );
};

SimpleZoomControls.propTypes = {
    children: PropTypes.any,
    connectionStatus: PropTypes.any,
};

export default compose(withHandlers({}), pure)(SimpleZoomControls);

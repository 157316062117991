import _, { isArray, map, keyBy, get, size, sortBy, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    compose,
    setDisplayName,
    withState,
    withHandlers,
    defaultProps,
    withPropsOnChange,
} from 'recompose';

import { vehicleModelOptionsSelector } from '~/client-common/core/domain/vehicle-models/state/ui';
import {
    loadVehicleModelDetails,
    searchForVehicleModels,
    storeVehicleModels,
    vehicleTypeCodeIdForItemCategoryAttrIdSelector,
} from '~/client-common/core/domain/vehicle-models/state/data';
import { buildVehicleModelRelated } from '~/client-common/core/domain/vehicles/utils/vehicleHelpers';

import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import AsyncSelect from './AsyncSelect';

const { connectRRFInput } = reactReduxFormHelpers;

const otherOption = { display: 'Other', value: -1 };
export const initialVehicleModelRelated = {
    itemCategoryAttrIds: [],
    vehicleModelYears: [],
    bodyStyleAttrIds: [],
    vehicleMake: undefined,
};

const mapStateToProps = createStructuredSelector({
    vehicleModelOptionsFromState: vehicleModelOptionsSelector,
    vehicleTypeCodeIdForItemCategoryAttrId: vehicleTypeCodeIdForItemCategoryAttrIdSelector,
});
const mapDispatchToProps = { loadVehicleModelDetails, searchForVehicleModels, storeVehicleModels };

const VehicleModelSelect = compose(
    setDisplayName('VehicleModelSelect'),
    connect(mapStateToProps, mapDispatchToProps),
    defaultProps({ showSelectedGroup: false }),
    withState('vehicleMakeModels', 'setVehicleMakeModels', { vehicleMakes: {}, vehicleModels: {} }),
    withPropsOnChange(
        ['value', 'vehicleMakeId', 'vehicleMakeModels', 'vehicleModelOptionsFromState'],
        ({
            includeOtherOption,
            value,
            vehicleMakeId,
            vehicleMakeModels,
            vehicleModelOptionsFromState,
        }) => {
            const { vehicleMakes, vehicleModels } = vehicleMakeModels;
            const grouped = !vehicleMakeId && size(vehicleModels) > 0;

            let vehicleModelOptions = map(vehicleModels, ({ id, modelName, vehicleMakeId }) => ({
                display: modelName,
                value: id,
                group: grouped ? get(vehicleMakes, `[${vehicleMakeId}].makeName`) : undefined,
            }));

            if (isArray(value)) {
                vehicleModelOptions.push(...vehicleModelOptionsFromState(value));
            } else if (!!value && !vehicleModels[value]) {
                vehicleModelOptions.push(...vehicleModelOptionsFromState([value]));
            }

            if (!grouped) {
                // if make is not selected (or if this select is grouped for any other reason), we
                // don't need to sort because a grouped dropdown sorts its options automatically
                vehicleModelOptions = sortBy(vehicleModelOptions, 'display');
            }

            if (includeOtherOption) {
                vehicleModelOptions.push(otherOption);
            }

            return {
                options: uniqBy(vehicleModelOptions, 'value'),
                grouped,
                sortOnRelevance: !grouped,
            };
        }
    ),
    withPropsOnChange(
        ['itemCategoryAttrId', 'vehicleTypeCodeIdForItemCategoryAttrId'],
        ({ itemCategoryAttrId, vehicleTypeCodeIdForItemCategoryAttrId }) => ({
            vehicleTypeCodeId: vehicleTypeCodeIdForItemCategoryAttrId(itemCategoryAttrId),
        })
    ),
    withHandlers({
        asyncAction({
            searchForVehicleModels,
            vehicleTypeCodeId,
            vehicleBodyStyleCodeId,
            yearOfManufacture,
            setVehicleMakeModels,
            vehicleMakeId,
        }) {
            return (q) => {
                if (!vehicleMakeId && get(q, 'length') === 1) {
                    // Do not allow a 1-character search unless Make is selected, because there'd be
                    // too many irrelevant results. The 1-character search is meant for '3' when
                    // Make is 'Mazda', for example.
                    return;
                }

                return searchForVehicleModels({
                    q,
                    vehicleMakeId,
                    vehicleTypeCodeId,
                    vehicleBodyStyleCodeId,
                    yearOfManufacture,
                    from: 0,
                    size: 100,
                }).then(({ vehicleMakes, vehicleModels }) => {
                    setVehicleMakeModels({
                        vehicleMakes: keyBy(vehicleMakes, 'id'),
                        vehicleModels: keyBy(vehicleModels, 'id'),
                    });
                });
            };
        },
        onChange({
            loadVehicleModelDetails,
            onChange,
            onChangeWithVehicleModelRelated,
            storeVehicleModels,
            vehicleMakeModels,
        }) {
            return (value) => {
                if (!!value && value !== otherOption.value) {
                    const { vehicleMakes, vehicleModels } = vehicleMakeModels;

                    if (!!onChangeWithVehicleModelRelated) {
                        loadVehicleModelDetails([value]).then((result) => {
                            // custom handler for interactions between Model and related inputs, when
                            // they are single-value and not multiselects
                            const vehicleModel = vehicleModels[value];
                            const vehicleMake = vehicleMakes[vehicleModel.vehicleMakeId];
                            const vehicleModelRelated = buildVehicleModelRelated({
                                vehicleMake,
                                ...result,
                            });

                            onChangeWithVehicleModelRelated(vehicleModelRelated);
                        });
                    }

                    // store the selected model(s) into state for the values to persist - essential
                    // when this is a multiselect
                    const ids = isArray(value) ? value : [value];
                    const modelsToPersist = _(ids)
                        .map((id) => vehicleModels[id])
                        .compact()
                        .value();
                    if (modelsToPersist.length > 0) {
                        storeVehicleModels(modelsToPersist);
                    }
                }

                return onChange(value);
            };
        },
    })
)(AsyncSelect);

export default VehicleModelSelect;
export const RRFVehicleModelSelect = connectRRFInput(VehicleModelSelect);
export const ArbiterMFTVehicleModelSelect = arbiterMFTInput(VehicleModelSelect);

import { IconProps, BannerProps } from 'arc';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

type AlertAttributes = typeof globalAttributes['bulletinTypeGlobal'][keyof typeof globalAttributes['bulletinTypeGlobal']];

const alertTypes = globalAttributes.bulletinTypeGlobal;

export function getTypeColorAndIconType(id: AlertAttributes) {
    let status: BannerProps['status'] | null = null;
    let icon: IconProps['icon'] | null = null;

    switch (id) {
        case alertTypes.alert:
            status = 'error';
            icon = 'Alert';
            break;
        case alertTypes.bolo:
            status = 'error';
            icon = 'Bolo';
            break;
        case alertTypes.systemUpdates:
            status = 'info';
            icon = 'Information';
            break;
        case alertTypes.admin:
        default:
            status = 'neutral';
            icon = 'AdminUpdates';
    }
    return {
        status,
        icon,
    };
}

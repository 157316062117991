import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { compose, withPropsOnChange } from 'recompose';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { agencyProfilesWhereSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import FormSection from '../../../core/forms/components/FormSection';
import { PopoutLink } from '../../../core/components/links/Link';

import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import HelpText from '../../../../legacy-redux/components/core/HelpText';
import {
    reportModuleSearch,
    submitReportModuleSearch,
    resetReportModuleDashboard,
} from '../state/ui/reportModuleSearch';
import advancedSearchReports from '../../../search/reports/state/ui';
import { saveFormDataToLocalStorage } from '../../../core/local-storage';

import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import ReportDetailsFieldset from './ReportDetailsFieldset';
import PersonnelFieldset from './PersonnelFieldset';
import DateTimeFieldset from './DateTimeFieldset';

const strings = componentStrings.reports.dashboard;

const ReportsSearchFormContainer = styled(ReactReduxForm)`
    background: ${(props) => props.theme.colors.white};
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const ReportsFormSection = styled(FormSection)`
    && {
        padding-top: 22px;
        padding-bottom: 22px;
    }
`;

const ClearFiltersButton = styled(Button)`
    margin-left: 15px;
    color: ${(props) => props.theme.colors.highContrastGrey};
    fill: ${(props) => props.theme.colors.highContrastGrey};
    display: inline-block;
    padding: 6px 0;
`;

const HelpTextContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-bottom: 4px;
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--arc-space-3);
`;

const SearchButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
`;

function ReportModuleSearchForm({
    query,
    onSubmit,
    onReset,
    onGoToAdvancedSearch,
    hasSingleAgencyProfile,
}) {
    return (
        <ReportsSearchFormContainer {...reportModuleSearch.form}>
            <ReportsFormSection>
                <DateTimeFieldset onSubmit={onSubmit} />
                <PersonnelFieldset onSubmit={onSubmit} />
                <ReportDetailsFieldset
                    hideAgencySelect={hasSingleAgencyProfile}
                    onSubmit={onSubmit}
                />
                <BottomRow>
                    <SearchButtons>
                        <ClearFiltersButton
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.CLOSE_X} size={12} />}
                            onClick={onReset}
                        >
                            {strings.SearchFooter.clear}
                        </ClearFiltersButton>
                    </SearchButtons>
                    <div>
                        <Button
                            className={buttonTypes.ICON_LINK}
                            onClick={() => onGoToAdvancedSearch(query)}
                        >
                            <PopoutLink to={'/search/reports'}>
                                {strings.SearchFooter.advancedSearch}
                            </PopoutLink>
                        </Button>
                        <HelpTextContainer>
                            <HelpText content={strings.SearchFooter.helpText} />
                        </HelpTextContainer>
                    </div>
                </BottomRow>
            </ReportsFormSection>
        </ReportsSearchFormContainer>
    );
}

const mapStateToProps = createStructuredSelector({
    query: reportModuleSearch.selectors.currentQuerySelector,
    agencyProfilesWhere: agencyProfilesWhereSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => {
        dispatch(submitReportModuleSearch());
    },
    onReset: () => {
        dispatch(resetReportModuleDashboard());
        dispatch(reportModuleSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(reportModuleSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
    onGoToAdvancedSearch: (query) => {
        dispatch(advancedSearchReports.form.actionCreators.change(query.elasticQuery));
        dispatch(saveFormDataToLocalStorage(advancedSearchReports.form));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['agencyProfilesWhere'],
        ({ currentUserDepartmentId, agencyProfilesWhere }) => ({
            hasSingleAgencyProfile:
                agencyProfilesWhere({
                    departmentId: currentUserDepartmentId,
                }).length === 1,
        })
    )
)(ReportModuleSearchForm);

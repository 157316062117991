import React, { MouseEventHandler } from 'react';
import { IconButton, Tooltip } from 'arc';
import { Attachment } from '@mark43/rms-api';

import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import { getEmailButtonState } from '../../../core/emails/helpers';
import { computeTotalFileSize } from '../../files/helpers';
import { DownloadLink } from '../../files/components';

const strings = componentStrings.attachments.actions;

const ActionTooltip = styled(Tooltip)`
    z-index: 2100;
`;

const isAttachment = (item: unknown): item is Attachment =>
    Boolean(typeof item === 'object' && item && 'attachmentId' in item);

export const AttachmentDownloadButton = ({
    href,
    onDownload,
}: {
    href: string;
    onDownload?: MouseEventHandler<HTMLButtonElement>;
}) => {
    return (
        <DownloadLink href={href}>
            <ActionTooltip content={strings.download}>
                <IconButton
                    aria-label={strings.download}
                    variant="ghost"
                    icon="Export"
                    onClick={onDownload}
                />
            </ActionTooltip>
        </DownloadLink>
    );
};

type AttachmentEmailButtonProps =
    | {
          attachment: Attachment;
          contentLength?: never;
      }
    | {
          attachment?: never;
          contentLength: number;
      };

export const AttachmentEmailButton = ({
    attachment,
    contentLength = 0,
    onEmail,
}: AttachmentEmailButtonProps & {
    onEmail?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const size = isAttachment(attachment) ? computeTotalFileSize([attachment]) : contentLength;
    const emailButtonState = getEmailButtonState(1, true, size);

    return (
        <ActionTooltip content={emailButtonState?.tooltip ?? strings.email}>
            <IconButton
                aria-label={strings.email}
                variant="ghost"
                icon="Email"
                onClick={onEmail}
                data-test-id={testIds.EMAIL_BUTTON}
                disabled={emailButtonState.isDisabled}
            />
        </ActionTooltip>
    );
};

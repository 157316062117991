import { EntityTypeEnum } from '@mark43/rms-api';
import classNames from 'classnames';
import _, { last, flowRight, size } from 'lodash';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Flex } from 'arc';
import { entityTypeParamToEnum } from '~/client-common/helpers/entityProfileHelpers';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';

import { chainOfCustodyViewModelsForMasterItemIdSelector } from '~/client-common/core/domain/chain-of-custodies/state/ui';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { renderOnlyIf } from '../../../../legacy-redux/helpers/reactHelpers';
import {
    currentUserDepartmentIdSelector,
    currentUserHasAbilitySelector,
} from '../../../core/current-user/state/ui';
import { EntityProfilesErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import { entityProfileModuleMap } from '../..';
import { currentEntityProfileIdSelector } from '../state/ui/selectors';
import { BaseScrollableUnderSubheader } from '../../../core/components/ScrollableUnderSubheader';
import { getEntityDataFromRouteParams } from '../helpers/routeHelpers';
import { getEntityProfileSearchQuery } from '../helpers/searchHelpers';

import {
    entityProfileIsLoadingSelector,
    currentEntityProfileItemTypeSelector,
    currentEntityProfileMasterProfileIdSelector,
    currentEntityProfileOwnerContextSelector,
    currentEntityProfileOwnerTitleSelector,
    currentEntityProfileDepartmentIdSelector,
    currentEntityProfileIsMasterProfileSelector,
} from '../state/ui';

import EntityProfileSidebarLink from './sidebar/EntityProfileSidebarLink';
import EntityProfileContextInfoBar from './EntityProfileContextInfoBar';
import EntityProfileSidebar from './sidebar/EntityProfileSidebar';
import {
    withActiveTargetCases,
    useActiveTargetCases,
} from './EntityProfileActiveTarget/ActiveTargetCasesContext';

const { EVIDENCE } = abilitiesEnum;
const { ITEM_PROFILE } = EntityTypeEnum;

const EntityPageContent = styled(Flex)`
    width: 100%;
    justify-content: center;
    overflow-y: auto;
    position: sticky;
    top: 0;
    max-width: ${(props) => props.theme.widths.container}px;
    background: var(--arc-colors-surface-background);
    border-color: var(--arc-colors-border-default);
    border-left-width: 1px;
    border-right-width: 1px;
`;

const RealHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 101;
    padding: 0 20px;
`;

function EntityProfile({
    // components
    headerComponent,
    contentComponent,

    // have to get `section` here because the section param is only naturally
    // passed to the child component
    params: { section: sectionProp },

    // router props
    routeParams: { entityType, entityId },
    location,

    // other
    currentEntityProfileItemType,
    currentEntityProfileMasterProfileId,
    currentEntityProfileOwnerContext,
    currentEntityProfileOwnerTitle,
    canViewItemHistory,
    canViewStaffRemarks,
    checkedInEvidence,
    currentUserDepartmentId,
    currentEntityProfileDepartmentId,
    currentEntityProfileIsMasterProfile,
    departmentNameFromConsortiumLinksByDepartmentId,
    isProductModuleActive,
}) {
    const applicationSettings = useSelector(applicationSettingsSelector);
    // this is just a temporary fix - if section param is not defined use the location and grab the last param
    // specifically for reports section
    const section = sectionProp || last(location.pathname.split('/'));
    const profileSections = entityProfileModuleMap[entityType].sections;
    const externalDepartmentName =
        currentUserDepartmentId !== currentEntityProfileDepartmentId
            ? departmentNameFromConsortiumLinksByDepartmentId(currentEntityProfileDepartmentId)
            : undefined;

    // This is a temporary implementation as we do not have view-permissions property in the profileSections object
    // There are restrictions as to whom/what can view remarks
    const sidebarLinks = _.chain(profileSections)
        .omitBy(
            (value, key) =>
                entityTypeParamToEnum[entityType] === ITEM_PROFILE.name &&
                ((key === 'remarks' &&
                    (!canViewStaffRemarks ||
                        !currentEntityProfileIsMasterProfile ||
                        !checkedInEvidence)) ||
                    (key === 'history' && !canViewItemHistory))
        )
        .map((sectionConfig, profileSection) => {
            if (
                sectionConfig.productModule &&
                !isProductModuleActive(sectionConfig.productModule)
            ) {
                return;
            }

            if (sectionConfig.featureFlag && !applicationSettings[sectionConfig.featureFlag]) {
                return;
            }

            return (
                <EntityProfileSidebarLink
                    itemType={currentEntityProfileItemType}
                    active={profileSection === section}
                    profileSection={profileSection}
                    key={profileSection}
                    testId={sectionConfig.testId}
                />
            );
        })
        .compact()
        .value();

    const finalHeaderComponent = externalDepartmentName
        ? React.cloneElement(headerComponent, { ownerDepartmentName: externalDepartmentName })
        : headerComponent;

    const finalContentComponent =
        section === 'history'
            ? React.cloneElement(contentComponent, {
                  showFilter: profileSections[section]?.showFilter,
                  historyType: profileSections[section]?.historyType,
                  customFilter: profileSections[section]?.customFilter,
              })
            : contentComponent;

    const { callResource, resetState } = useActiveTargetCases();

    useEffect(() => {
        const shouldFetchActiveTargetProfileCases =
            entityType !== 'property' && applicationSettings.RMS_TARGET_PROFILES_ENABLED;

        if (shouldFetchActiveTargetProfileCases) {
            const entityData = getEntityDataFromRouteParams({ entityId, entityType });
            callResource(
                getEntityProfileSearchQuery(
                    entityData.entityId,
                    entityData.entityType,
                    currentEntityProfileIsMasterProfile
                )
            );
        }
    }, [
        callResource,
        entityId,
        entityType,
        applicationSettings.RMS_TARGET_PROFILES_ENABLED,
        currentEntityProfileIsMasterProfile,
    ]);

    useEffect(() => {
        return resetState;
    }, [resetState]);

    return (
        <EntityProfilesErrorBoundary>
            <BaseScrollableUnderSubheader
                className={classNames('entity-profile', {
                    contexted: !!currentEntityProfileOwnerTitle,
                })}
            >
                <EntityPageContent>
                    {currentEntityProfileOwnerTitle && (
                        <EntityProfileContextInfoBar
                            masterProfileId={currentEntityProfileMasterProfileId}
                            currentRouteParams={{ entityType, entityId }}
                            ownerContext={currentEntityProfileOwnerContext}
                            ownerTitle={currentEntityProfileOwnerTitle}
                            ownerDepartmentName={externalDepartmentName}
                        />
                    )}
                    <EntityProfileSidebar entityType={entityType} entityId={entityId}>
                        {sidebarLinks}
                    </EntityProfileSidebar>
                    <div className="entity-profile-content">
                        <RealHeader>{finalHeaderComponent}</RealHeader>
                        <div className="entity-profile-active-section">
                            {
                                // This is added here so the content will start at the
                                // appropriate place under the fixed real header, without
                                // watching for the height of the header and dynamically
                                // setting the `top` property of this section.
                                // Inspired by https://stackoverflow.com/a/6414716
                            }
                            {finalContentComponent}
                        </div>
                    </div>
                </EntityPageContent>
            </BaseScrollableUnderSubheader>
        </EntityProfilesErrorBoundary>
    );
}

const mapStateToProps = createStructuredSelector({
    chainOfCustodyViewModelsForMasterItemId: chainOfCustodyViewModelsForMasterItemIdSelector,
    currentEntityProfileId: currentEntityProfileIdSelector,
    currentEntityProfileDepartmentId: currentEntityProfileDepartmentIdSelector,
    currentEntityProfileIsMasterProfile: currentEntityProfileIsMasterProfileSelector,
    currentEntityProfileItemType: currentEntityProfileItemTypeSelector,
    currentEntityProfileMasterProfileId: currentEntityProfileMasterProfileIdSelector,
    currentEntityProfileOwnerContext: currentEntityProfileOwnerContextSelector,
    currentEntityProfileOwnerTitle: currentEntityProfileOwnerTitleSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    departmentNameFromConsortiumLinksByDepartmentId:
        departmentNameFromConsortiumLinksByDepartmentIdSelector,
    isLoading: entityProfileIsLoadingSelector,
    isProductModuleActive: isProductModuleActiveSelector,
});

export default flowRight(
    withRouter,
    connect(mapStateToProps, null),
    renderOnlyIf(({ isLoading }) => !isLoading),
    withPropsOnChange(
        [
            'chainOfCustodyViewModelsForMasterItemId',
            'currentEntityProfileId',
            'currentUserHasAbility',
        ],
        ({
            chainOfCustodyViewModelsForMasterItemId,
            currentEntityProfileId,
            currentUserHasAbility,
            routeParams: { entityType },
        }) => ({
            canViewStaffRemarks: currentUserHasAbility(EVIDENCE.VIEW_STAFF_REMARKS),
            canViewItemHistory: currentUserHasAbility(EVIDENCE.VIEW_EVIDENCE_ITEM_HISTORY),
            checkedInEvidence:
                entityTypeParamToEnum[entityType] === ITEM_PROFILE.name &&
                size(chainOfCustodyViewModelsForMasterItemId(currentEntityProfileId)) > 0,
        })
    ),
    withActiveTargetCases
)(EntityProfile);

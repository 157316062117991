import {
    storeShortcutKeys,
    deleteShortcutKey,
} from '~/client-common/core/domain/shortcut-keys/state/data';

import shortcutKeysResource from '../../resources/shortcutKeysResource';

import { departmentProfileSelector } from '../../../../../legacy-redux/selectors/departmentProfileSelectors';

export const LOAD_SHORTCUT_KEYS_START = 'shortcutKeys/LOAD_SHORTCUT_KEYS_START';
export const LOAD_SHORTCUT_KEYS_SUCCESS = 'shortcutKeys/LOAD_SHORTCUT_KEYS_SUCCESS';
export const LOAD_SHORTCUT_KEYS_FAILURE = 'shortcutKeys/LOAD_SHORTCUT_KEYS_FAILURE';

export const SAVE_SHORTCUT_KEY_FORM_START = 'shortcutKey/SAVE_SHORTCUT_KEY_FORM_START';
export const SAVE_SHORTCUT_KEY_FORM_SUCCESS = 'shortcutKey/SAVE_SHORTCUT_KEY_FORM_SUCCESS';
export const SAVE_SHORTCUT_KEY_FORM_FAILURE = 'shortcutKey/SAVE_SHORTCUT_KEY_FORM_FAILURE';

const REMOVE_SHORTCUT_KEY_START = 'shortcutKey/REMOVE_SHORTCUT_KEY_START';
const REMOVE_SHORTCUT_KEY_SUCCESS = 'shortcutKey/REMOVE_SHORTCUT_KEY_SUCCESS';
export const REMOVE_SHORTCUT_KEY_FAILURE = 'shortcutKey/REMOVE_SHORTCUT_KEY_FAILURE';

function saveShortcutKeyFormStart() {
    return {
        type: SAVE_SHORTCUT_KEY_FORM_START,
    };
}

function saveShortcutKeyFormSuccess() {
    return {
        type: SAVE_SHORTCUT_KEY_FORM_SUCCESS,
    };
}

function saveShortcutKeyFormFailure(err) {
    return {
        type: SAVE_SHORTCUT_KEY_FORM_FAILURE,
        payload: err,
    };
}

function removeShortcutKeyStart() {
    return {
        type: REMOVE_SHORTCUT_KEY_START,
    };
}

function removeShortcutKeySuccess() {
    return {
        type: REMOVE_SHORTCUT_KEY_SUCCESS,
    };
}

function removeShortcutKeyFailure(err) {
    return {
        type: REMOVE_SHORTCUT_KEY_FAILURE,
        payload: err,
    };
}

export function saveShortcutKeyForm(model) {
    return (dispatch, getState) => {
        dispatch(saveShortcutKeyFormStart());
        const state = getState();
        const toSave = {
            ...model.shortcutKey,
            departmentId: departmentProfileSelector(state).departmentId,
        };

        return shortcutKeysResource
            .upsertShortcutKeys([toSave])
            .then((resp) => {
                dispatch(storeShortcutKeys(resp));
                dispatch(saveShortcutKeyFormSuccess());
                return toSave;
            })
            .catch((err) => {
                let friendlyError;

                // Hacky way of figuring out what kind of error we had
                if (err.message.toLowerCase().match(/duplicate/)) {
                    friendlyError = {
                        message: 'Shortcut Key Combination must be unique.',
                    };
                } else if (err.message.toLowerCase().match(/network error/)) {
                    friendlyError = {
                        message: 'Unable to save Shortcut Key.',
                    };
                }

                dispatch(saveShortcutKeyFormFailure(friendlyError || err));
            });
    };
}

function loadShortcutKeysStart() {
    return {
        type: LOAD_SHORTCUT_KEYS_START,
    };
}

function loadShortcutKeysSuccess(shortcutKeys) {
    return {
        type: LOAD_SHORTCUT_KEYS_SUCCESS,
        payload: shortcutKeys,
    };
}

function loadShortcutKeysFailure(err) {
    return {
        type: LOAD_SHORTCUT_KEYS_FAILURE,
        payload: err,
    };
}

export function removeShortcutKey(id) {
    return (dispatch) => {
        dispatch(removeShortcutKeyStart());
        return shortcutKeysResource
            .removeShortcutKey(id)
            .then(() => {
                dispatch(deleteShortcutKey(parseInt(id)));
                dispatch(removeShortcutKeySuccess());
            })
            .catch((err) => {
                dispatch(removeShortcutKeyFailure(err));
            });
    };
}

export function loadShortcutKeysAdmin() {
    return function (dispatch) {
        dispatch(loadShortcutKeysStart());

        const inner = () => {
            return shortcutKeysResource
                .getShortcutKeys()
                .then((resp) => {
                    dispatch(storeShortcutKeys(resp));
                    dispatch(loadShortcutKeysSuccess(resp));
                })
                .catch((err) => {
                    dispatch(loadShortcutKeysFailure(err));
                });
        };

        return inner();
    };
}

export function createNewShortcutKey(id = -1) {
    return {
        id,
    };
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { noop } from 'lodash';
import classNames from 'classnames';
import { canFindOnly, canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import Icon, { iconTypes } from '../../../../modules/core/components/Icon';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
} from '../../../../modules/core/current-user/state/ui';
import testIds from '../../../../core/testIds';

const strings = componentStrings.core.Table;

export const TableRow = styled.tr`
    ${(props) =>
        props.isNewResult
            ? `
        margin-left: -2px;
        border-left: 2px solid var(--arc-colors-positive-default);
    `
            : ''};
    border-top: ${(props) => (props.warning ? `6px solid ${props.theme.colors.red}` : 'none')};
    background-color: ${(props) =>
        props.warning ? 'var(--arc-colors-negative-accent)' : 'inherit'};
`;

const RestrictedAccessTableRow = styled(TableRow)`
    background-color: ${(props) =>
        props.warning ? 'var(--arc-colors-negative-accent)' : props.theme.colors.extraLightGrey};
    cursor: initial;
`;

const RestrictedAccessWarningRow = styled.td`
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-image: url(../images/stripe_pattern.png);
    background-repeat: repeat;
    display: block;
    margin-top: ${(props) => props.marginTop || 0};
    margin-left: ${(props) => props.marginLeft || 0};
`;

export const RestrictedAccessWarning = styled.span`
    font-size: var(--arc-fontSizes-md);
    font-style: italic;
    text-transform: uppercase;
`;

const RestrictedAccessSubtext = styled.span`
    padding-left: 20px;
    font-size: var(--arc-fontSizes-sm);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    line-height: 15px;
`;

const LockIcon = styled(Icon)`
    padding-right: 8px;
`;

const mapStateToProps = createStructuredSelector({
    currentUserHasAbility: currentUserHasAbilitySelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
});

function RestrictedAccessRow({
    currentUserDepartmentId,
    currentUserHasAbility,
    row,
    children,
    ...otherProps
}) {
    const permissionSet = row.permissionSet;
    let subtext = canFindOnly(permissionSet) ? strings.canFindOnly : undefined;
    subtext = row.isSealed ? strings.sealedRecord : subtext;

    const hasCourtOrderAbility = currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_COURT_ORDERS);
    const rowIsClickable =
        row.isSealed && currentUserDepartmentId === row.departmentId && hasCourtOrderAbility;
    const onClickHandler = rowIsClickable ? otherProps.onClick : noop;

    const className = classNames('cobalt-table-row', {
        clickable: rowIsClickable,
    });

    return (
        <RestrictedAccessTableRow
            {...otherProps}
            onClick={onClickHandler}
            className={className}
            isNewResult={row.isNewResult}
        >
            <RestrictedAccessWarningRow>
                <RestrictedAccessWarning>{strings.restrictedAccess}</RestrictedAccessWarning>
                {subtext && (
                    <RestrictedAccessSubtext>
                        {row.isSealed && <LockIcon size={18} type={iconTypes.PERMISSIONS} />}
                        {subtext}
                    </RestrictedAccessSubtext>
                )}
            </RestrictedAccessWarningRow>
            {children}
        </RestrictedAccessTableRow>
    );
}

const ConnectedRestrictedAccessRow = connect(mapStateToProps)(RestrictedAccessRow);

export const checkRowIsRestricted = (row) => !canRead(row.permissionSet) || canFindOnly(row.permissionSet);

// This row is used for elastic results
// If the permissionSet array does not have 'READ' permission it will
// display "Restriced Access" and disable that row
// If a 'warning' prop is passed in it will change the background and border
export default function ElasticResultRow({ row, children, ...otherProps }) {
    const isRestricted = checkRowIsRestricted(row);
    return !isRestricted ? (
        <TableRow
            {...otherProps}
            isNewResult={row.isNewResult}
            data-test-id={testIds.TABLE_ROW}
        >
            {children}
        </TableRow>
    ) : (
        <ConnectedRestrictedAccessRow row={row} {...otherProps}>
            {children}
        </ConnectedRestrictedAccessRow>
    );
}

import { InsightsResource } from '@mark43/rms-api';
import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';

export default createResource({
    name: 'Insights Resource',
    methods: {
        getBaseEmbedUrl() {
            return req<InsightsResource.GetBaseEmbedUrl>({
                method: 'GET',
                url: `insights/url`,
            });
        },
        createGoodDataJWT() {
            return req<InsightsResource.CreateJwt>({
                method: 'POST',
                url: `insights/jwt`,
            });
        },
        getAllDashboards() {
            return req<InsightsResource.GetAllDashboards>({
                method: 'GET',
                url: `insights/dashboards`,
            });
        },
    },
});

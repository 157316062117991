import { RotationServiceBundle, RotationTypeSuspensionBundle } from '@mark43/cad-api';

import { RmsAction } from '../../../../../core/typings/redux';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';

import {
    AddServiceSuspensionFormAction,
    SuspensionFormActions,
    SuspensionsHistoryRequestStatuses,
} from '../types';
import {
    SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_START,
    SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_FAILURE,
    SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_SUCCESS,
    FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_START,
    FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_SUCCESS,
    FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_FAILURE,
    SHOW_SUSPENSION_REQUEST_ERROR_MESSAGE,
    CLEAR_SUSPENSION_ERROR_MESSAGES,
    sendCadServiceProviderSuspensionRequest,
    saveCadServiceProviderSuspensionStart,
    saveCadServiceProviderSuspensionSuccess,
    saveCadServiceProviderSuspensionFailure,
} from '../data/suspensions';
import {
    ADD_OR_EDIT_SUSPENSION_FORM_NAME,
    convertFromFormModel,
} from '../forms/AddOrEditSuspensionForm';
import { updateCadServiceProvider } from '.';

export const initialFormUi = {
    submittingForm: false,
    error: null,
    history: {
        status: SuspensionsHistoryRequestStatuses.SUCCESS,
        data: [],
    },
};

export function uiSelector(state: RootState) {
    return state.ui.cadServiceProviderAddOrEditSuspensionForm;
}

export function resetAddOrEditSuspensionForm(): RmsAction<void> {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(ADD_OR_EDIT_SUSPENSION_FORM_NAME);

        if (form) {
            form.resetUi();
            form.resetModel();
        }
    };
}

export function submitAddOrEditSuspensionForm({
    serviceProvider,
    resetForm,
    formAction,
    editSuspensionId,
}: {
    serviceProvider: RotationServiceBundle;
    resetForm: () => void;
    formAction: SuspensionFormActions;
    editSuspensionId?: number;
}): RmsAction<Promise<void>> {
    return function (dispatch, getState, { formsRegistry }) {
        const form = formsRegistry.get(ADD_OR_EDIT_SUSPENSION_FORM_NAME);

        if (!form) {
            return Promise.resolve();
        }

        return form.submit().then(() => {
            const cadServiceProviderSuspension = {
                ...convertFromFormModel(form.getState().model),
                ...(formAction === SuspensionFormActions.EDIT && editSuspensionId
                    ? { rotationSuspensionId: editSuspensionId }
                    : {}),
            };

            dispatch(saveCadServiceProviderSuspensionStart());

            return dispatch(
                sendCadServiceProviderSuspensionRequest(
                    formAction === SuspensionFormActions.ADD
                        ? 'createCadServiceProviderSuspension'
                        : 'updateCadServiceProviderSuspension',
                    serviceProvider?.id as number,
                    cadServiceProviderSuspension,
                    (newSuspensionsInfo: RotationTypeSuspensionBundle) => {
                        resetForm();
                        dispatch(
                            updateCadServiceProvider({
                                ...serviceProvider,
                                suspensions: newSuspensionsInfo,
                            })
                        );
                        dispatch(saveCadServiceProviderSuspensionSuccess());
                    },
                    (error) => {
                        dispatch(saveCadServiceProviderSuspensionFailure(error.message));
                        throw error;
                    }
                )
            );
        });
    };
}

export default function cadServiceProviderAddOrEditSuspensionFormUiReducer(
    state = initialFormUi,
    action: AddServiceSuspensionFormAction
) {
    switch (action.type) {
        case SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_START:
            return {
                ...state,
                submittingForm: true,
            };
        case SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case SAVE_CAD_SERVICE_PROVIDER_SUSPENSION_FAILURE:
            return {
                ...state,
                submittingForm: false,
            };
        case FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_START:
            return {
                ...state,
                history: {
                    ...initialFormUi.history,
                    status: SuspensionsHistoryRequestStatuses.LOADING,
                },
            };
        case FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_SUCCESS:
            return {
                ...state,
                history: {
                    ...state.history,
                    status: SuspensionsHistoryRequestStatuses.SUCCESS,
                    data: action.payload,
                },
            };
        case FETCH_CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_FAILURE:
            return {
                ...state,
                history: {
                    ...state.history,
                    status: SuspensionsHistoryRequestStatuses.FAILURE,
                },
            };
        case SHOW_SUSPENSION_REQUEST_ERROR_MESSAGE:
            return {
                ...state,
                error: action.payload,
            };
        case CLEAR_SUSPENSION_ERROR_MESSAGES:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

import React from 'react';
import { map } from 'lodash';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import StyledLocationCell from '../../../../../legacy-redux/components/core/tables/elasticLocationTable/StyledLocationCell';

export default function WarrantLocationCell(elasticWarrant) {
    const viewModelProps = getViewModelProperties(elasticWarrant);
    const locations = viewModelProps.locations;

    return (
        <div>
            {map(locations, (location, index) => (
                <StyledLocationCell key={index} locationViewModel={location} />
            ))}
        </div>
    );
}

import { reject } from 'lodash';

type Mark43EnumValue = { value: number; name: string };
export type Mark43Enum = Record<string, Mark43EnumValue>;

/**
 * For every Mark43 enum which has an unknown value, its `name` string is always 'UNKNOWN'.
 * Its `value` is -1 for a number, and 'Unknown' for a string.
 */
function isUnknownEnumValue(enumValue?: Mark43EnumValue): boolean {
    return !enumValue || (enumValue.value === -1 && enumValue.name === 'UNKNOWN');
}

export function rejectUnknownEnumValue(mark43Enum: Mark43Enum): Mark43EnumValue[] {
    return reject(mark43Enum, isUnknownEnumValue);
}

export const convertMark43EnumToAdminListViewModels = <
    T extends Record<string, { value: number; displayName: string }>
>(
    mark43Enum: T,
    selectedValue?: number
): { key: number; selected: boolean; title: string }[] => {
    return Object.values(mark43Enum).map((enumValue) => {
        return {
            key: enumValue.value,
            selected: enumValue.value === selectedValue,
            title: enumValue.displayName,
        };
    });
};

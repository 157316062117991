import styled from 'styled-components';

/**
 * Styled text for disposition history.
 */
export default styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-style: italic;

    ::first-letter {
        text-transform: capitalize;
    }
`;

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { mustBeInt } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

export default createFormModule({
    formName: formClientEnum.SUPPORT_ID_GENERATOR,
    fieldViewModels: buildFlatFormFieldViewModels([
        {
            key: 'idFormatType',
        },
        {
            key: 'sequenceIncrement',
            validators: {
                mustBeIntError: mustBeInt,
            },
        },
        {
            key: 'id',
        },
        {
            key: 'agencyId',
        },
        {
            key: 'entityType',
        },
        {
            key: 'extraKey',
        },
    ]),
});

import React, { useCallback } from 'react';
import * as yup from 'yup';
import { map, compact, isEmpty, filter } from 'lodash';

import {
    createField,
    createFormConfiguration,
    createNItems,
    Form,
    lifecycleOptions,
    formEvents,
} from 'markformythree';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import validationStrings from '~/client-common/core/strings/validationStrings';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../core/validation/yupMftValidation';
import ClipsReportCardSection from '../../components/ClipsReportCardSection';
import { ClipsCurrentReportData } from '../ui';

const clipsReportCardsStrings = componentStrings.clips.ClipsReportCards;
const { entryNameLabel } = componentStrings.clips.ClipsReportCardItems;

type ClipsFormEntry = {
    id: number;
    entryName: string;
    isSelected: boolean;
};

export type ClipsFormData = {
    vehicles: ClipsFormEntry[];
    property: ClipsFormEntry[];
    people: ClipsFormEntry[];
};

const checkForUniqueValue = (clipsFormEntries: ClipsFormEntry[], value: string) => {
    return (
        filter(clipsFormEntries, {
            entryName: value,
            isSelected: true,
        }).length < 2
    );
};

const validationSchema = ({ model }: { model: ClipsFormData }) => {
    const entryNames = [...model.people, ...model.property, ...model.vehicles];
    return yup.object().shape({
        people: yup.array().of(
            yup.object().shape({
                isSelected: yup.boolean(),
                entryName: yup.string().when('isSelected', {
                    is: true,
                    then: yup
                        .string()
                        .test(
                            'uniqueEntryName',
                            validationStrings.panel.fieldUnique(entryNameLabel),
                            (value: string | null | undefined) => {
                                return checkForUniqueValue(entryNames, value || '');
                            }
                        )
                        .required(validationStrings.field.required),
                }),
            })
        ),
        vehicles: yup.array().of(
            yup.object().shape({
                isSelected: yup.boolean(),
                entryName: yup.string().when('isSelected', {
                    is: true,
                    then: yup
                        .string()
                        .test(
                            'uniqueEntryName',
                            validationStrings.panel.fieldUnique(entryNameLabel),
                            (value: string | null | undefined) => {
                                return checkForUniqueValue(entryNames, value || '');
                            }
                        )
                        .required(validationStrings.field.required),
                }),
            })
        ),
        property: yup.array().of(
            yup.object().shape({
                isSelected: yup.boolean(),
                entryName: yup.string().when('isSelected', {
                    is: true,
                    then: yup
                        .string()
                        .test(
                            'uniqueEntryName',
                            validationStrings.panel.fieldUnique(entryNameLabel),
                            (value: string | null | undefined) => {
                                return checkForUniqueValue(entryNames, value || '');
                            }
                        )
                        .required(validationStrings.field.required),
                }),
            })
        ),
    });
};

const ClipsReportPrefillFormConfiguration = createFormConfiguration({
    people: createNItems({
        fields: {
            id: createField<number>({}),
            isSelected: createField<boolean>({}),
            entryName: createField<string>({}),
        },
    }),
    vehicles: createNItems({
        fields: {
            id: createField<number>({}),
            isSelected: createField<boolean>({}),
            entryName: createField<string>({}),
        },
    }),
    property: createNItems({
        fields: {
            id: createField<number>({}),
            isSelected: createField<boolean>({}),
            entryName: createField<string>({}),
        },
    }),
});

const ClipsReportForm = ({
    initialState,
    reportEntities,
    entityId,
}: {
    initialState: ClipsFormData;
    reportEntities: ClipsCurrentReportData;
    entityId: number;
}) => {
    // here we check if the entities exist, then add their section's title to an array
    const personProfilesExist = !isEmpty(reportEntities.personProfileEntities);
    const vehiclesExist = !isEmpty(reportEntities.itemSummaryEntities.vehicleSummaries);
    const propertyItemsExist = !isEmpty(reportEntities.itemSummaryEntities.itemSummaries);
    const cardSectionTitles = compact([
        personProfilesExist && clipsReportCardsStrings.peopleTitle,
        vehiclesExist && clipsReportCardsStrings.vehiclesTitle,
        propertyItemsExist && clipsReportCardsStrings.propertyTitle,
    ]);
    const handleValidation = useCallback(({ formState, eventType }) => {
        const $form = formState.ui.$form;
        return validationSchema(formState)
            .validate(formState.model, { abortEarly: false })
            .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
            .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
    }, []);
    return (
        <Form
            configuration={ClipsReportPrefillFormConfiguration}
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={formClientEnum.CLIPS_REPORT_PREFILL_FORM}
            onValidate={handleValidation}
            validationEvents={[
                { eventType: formEvents.FORM_SUBMIT },
                { eventType: formEvents.INPUT_BLUR },
                { eventType: formEvents.INPUT_CHANGE },
            ]}
            initialState={initialState}
            render={() =>
                map(cardSectionTitles, (sectionTitle: string, index: number) => (
                    <ClipsReportCardSection
                        key={`clips-report-card-section-${index}`}
                        sectionTitle={sectionTitle}
                        entityId={entityId}
                        reportEntities={reportEntities}
                    />
                ))
            }
        />
    );
};

export default ClipsReportForm;

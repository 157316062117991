import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { findAdditionsAndSubtractions } from '~/client-common/helpers/logicHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import SidePanel from '../../core/SidePanel';
import CheckboxList from '../../../../modules/core/forms/components/checkboxes/CheckboxList';
import { cancelUserProfileRolesPanel } from '../../../actions/rolesActions';
import {
    userProfileAdminRolesSelector,
    userProfileAdminProfileSelector,
} from '../../../selectors/userProfileAdminSelectors';

const strings = componentStrings.admin.userProfile.roles;

const context = {
    name: boxEnum.USER_PROFILE_ADMIN_ROLES_SIDE_PANEL,
};

class UserProfileAdminRolesForm extends React.Component {
    constructor(props, ...args) {
        super(props, ...args);
        this.state = {
            roleIds: props.roles.userRoles,
        };

        this.updateRoles = this.updateRoles.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    updateRoles(roleIds) {
        this.setState({ roleIds });
    }

    handleSave() {
        const { props, state } = this;
        const [roleIdsToCreate, roleIdsToDelete] = findAdditionsAndSubtractions(
            props.roles.userRoles,
            state.roleIds
        );
        props.onSubmit(props.profile.userId, roleIdsToCreate, roleIdsToDelete);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.roles !== this.props.roles) {
            this.setState({
                roleIds: nextProps.roles.userRoles,
            });
        }
    }

    render() {
        const { props } = this;
        return (
            <SidePanel
                title={strings.heading}
                noPadding={true}
                onSave={this.handleSave}
                onCancel={props.cancelUserProfileRolesPanel}
                context={context}
            >
                <CheckboxList
                    value={props.roles.userRoles}
                    placeholderText={strings.searchPlaceholder}
                    options={props.roles.roleOptions}
                    onChange={this.updateRoles}
                />
            </SidePanel>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    roles: userProfileAdminRolesSelector,
    profile: userProfileAdminProfileSelector,
});

export default compose(
    connect(mapStateToProps),
    withProps(({ dispatch }) => ({
        cancelUserProfileRolesPanel: () => dispatch(cancelUserProfileRolesPanel(context)),
    }))
)(UserProfileAdminRolesForm);

import { NameIdentifier } from '@mark43/rms-api';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import {
    ViewModel,
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { formatAttributeWithOther } from '../../../attributes/utils/attributesHelpers';

import { nameIdentifiersByNameIdSelector } from '../data';

type NameIdentifierViewModelProperties = {
    description: string;
    identifier: string;
    nameIdentifierTypeAttrId: string;
    nameIdentifierTypeOther?: string;
};

export type NameIdentifierViewModel = ViewModel<NameIdentifier, NameIdentifierViewModelProperties>;

export const nameIdentifierViewModelsByNameIdSelector = createSelector(
    nameIdentifiersByNameIdSelector,
    formatAttributeByIdSelector,
    (nameIdentifiersByNameId, formatAttributeById) => (nameId: number) => {
        const viewModelBuilder = buildViewModel<NameIdentifier, NameIdentifierViewModelProperties>({
            mappers: [
                allSingleAttributeValuesMapper,
                ({ nameIdentifierTypeAttrId, nameIdentifierTypeOther }) => ({
                    nameIdentifierType: formatAttributeWithOther(
                        formatAttributeById(nameIdentifierTypeAttrId),
                        nameIdentifierTypeOther
                    ),
                }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return map(nameIdentifiersByNameId(nameId), viewModelBuilder);
    }
);

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { RmsAction } from '../../../../../core/typings/redux';
import { ReasonForRelationSidePanelCustomProperties } from '../../components/ReasonForRelationSidePanel';
import { IndividualReportSelectionSidePanelCustomProperties } from '../../components/IndividualReportSelectionSidePanel';

export const openReasonForRelationSidePanel = (
    customProperties: Partial<ReasonForRelationSidePanelCustomProperties> = {}
): RmsAction<void> => (dispatch, getState, { overlayStore }) => {
    overlayStore.open(overlayIdEnum.REASON_FOR_RELATION_SIDE_PANEL, customProperties);
};

export const openIndividualReportSelectionSidePanel = (
    customProperties: Partial<IndividualReportSelectionSidePanelCustomProperties> = {}
): RmsAction<void> => (dispatch, getState, { overlayStore }) => {
    overlayStore.open(overlayIdEnum.INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL, customProperties);
};

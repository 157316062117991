// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Exports Resource',
        methods: {
            getPrintPackets(context, idMap) {
                return req({
                    method: 'POST',
                    url: `print/${context}`,
                    data: {
                        idMap,
                    },
                });
            },
            print(data, includeInternal) {
                return req({
                    method: 'POST',
                    url: 'print',
                    data,
                    params: {
                        include_internal: includeInternal,
                    },
                });
            },
            pollForExports() {
                return req({
                    method: 'GET',
                    url: 'print/exports',
                });
            },
            pollForInternalExports() {
                return req({
                    method: 'GET',
                    url: 'print/exports/internal',
                });
            },
            pollForExportIds(idList) {
                return req({
                    method: 'POST',
                    url: 'print/exports/ids',
                    data: idList,
                });
            },
            clearExports(idList) {
                return req({
                    method: 'DELETE',
                    // TODO we should be writing/using generic helper for
                    // building query strings out of data
                    url: `print/exports?ids=${idList.join('&ids=')}`,
                });
            },
            bulkExportFiles(fileIds, title) {
                return req({
                    method: 'POST',
                    url: 'export/files',
                    data: fileIds,
                    params: { title },
                });
            },
            generateExportHtmlPreview(workOrder) {
                return req({
                    method: 'POST',
                    url: 'print/preview',
                    data: workOrder,
                });
            },
            getMostRecentUserExportHtmlPreview() {
                return req({
                    method: 'GET',
                    url: 'print/preview/current',
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

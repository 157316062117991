import { get } from 'lodash';
import { ComplianceGroupEnum, OffenseCode, UkOffenseCodeExtension } from '@mark43/rms-api';
import { offenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { ukOffenseCodeExtensionByOffenseCodeIdSelector } from '~/client-common/core/domain/uk-offense-code-extensions/state/data';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { logError, logWarning } from '../../../core/logging';

const warningPrefix = 'Rule Field Getter getOffenseCodePropertyValue:';

type SupportedEntity = OffenseCode & Partial<UkOffenseCodeExtension>;
type GetOffenseCodePropertyValueProps = {
    /**
     * The property from the entity that we want to evaluate
     */
    propertyName: string;
};

const getEntityContainsWantedData = (
    entity: SupportedEntity,
    args: GetOffenseCodePropertyValueProps
) => {
    const entityValue = get(entity, args.propertyName);

    if (isUndefinedOrNull(entityValue)) {
        return;
    }

    // Currently, comparisons will be limited by this set of primitives
    switch (typeof entityValue) {
        case 'boolean': {
            return entityValue ? 'true' : 'false';
        }
        case 'string': {
            return entityValue;
        }
        case 'number': {
            return entityValue.toString();
        }
        default: {
            // It's not expected that we get into this branch.
            // If we do, allow the rule to pass, and also log an error to sentry
            // to further investigate why
            logError(
                'An invalid data-type was returned from the "getOffenseCodePropertyValue" RFG',
                {
                    extras: {
                        dataType: typeof entityValue,
                    },
                }
            );
            return;
        }
    }
};

export const getOffenseCodePropertyValue = (getState: () => RootState) => (
    offenseCodeId: number,
    args: GetOffenseCodePropertyValueProps
) => {
    if (isUndefinedOrNull(args.propertyName)) {
        logWarning(`${warningPrefix} missing rule condition argument for propertyName`);
        return;
    }

    const state = getState();
    const offenseCode = offenseCodeByIdSelector(state)(offenseCodeId);

    if (isUndefinedOrNull(offenseCode)) {
        return;
    }

    const departmentProfile = currentUserDepartmentProfileSelector(state);
    if (departmentProfile?.complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name) {
        const offenseCodeExtension = ukOffenseCodeExtensionByOffenseCodeIdSelector(state)(
            offenseCode.id
        );

        return getEntityContainsWantedData({ ...offenseCodeExtension, ...offenseCode }, args);
    }

    return getEntityContainsWantedData(offenseCode, args);
};

import { ReportDefinitionTitleFormat, ReportTitleFormatEnum } from '@mark43/rms-api';
import { map } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const NEXUS_STATE_PROP = 'reportDefinitionTitleFormats';

const reportDefinitionTitleFormatsModule = createNormalizedModule<ReportDefinitionTitleFormat>({
    type: NEXUS_STATE_PROP,
});

export const reportDefinitionTitleFormatsWhereSelector =
    reportDefinitionTitleFormatsModule.selectors.entitiesWhereSelector;

export const convertReportDefinitionTitleFormatToOptions = (
    reportDefinitionTitleFormats: ReportDefinitionTitleFormat[],
    reportDefinitionName = ''
) => {
    const options = map(reportDefinitionTitleFormats, (reportDefinitionTitleFormat) => ({
        display: `${reportDefinitionName}: ${
            ReportTitleFormatEnum[reportDefinitionTitleFormat.reportTitleFormat].displayName
        } `,
        value: reportDefinitionTitleFormat.reportTitleFormat,
    }));
    if (options.length) {
        return options;
    }
    return [
        {
            display: `${reportDefinitionName}: ${ReportTitleFormatEnum.DEFAULT_REPORT_TYPE_NAME.displayName}`,
            value: ReportTitleFormatEnum.DEFAULT_REPORT_TYPE_NAME.name,
        },
        {
            display: `${reportDefinitionName}: ${ReportTitleFormatEnum.RECORD_ID.displayName}`,
            value: ReportTitleFormatEnum.RECORD_ID.name,
        },
        {
            display: `${reportDefinitionName}: ${ReportTitleFormatEnum.SUPPLEMENT_DESCRIPTION.displayName}`,
            value: ReportTitleFormatEnum.SUPPLEMENT_DESCRIPTION.name,
        },
    ];
};

export default reportDefinitionTitleFormatsModule.reducerConfig;

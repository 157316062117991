import { filter, get, sortBy } from 'lodash';

import { isValueDisplayable } from './logicHelpers';

export const sortElasticOffenseViewModels = (elasticOffenseViewModels) => {
    return sortBy(filter(elasticOffenseViewModels, 'isOffense'), 'sequenceNumber');
};

export const formatElasticOffenseViewModelOffenseCode = (elasticOffenseViewModel) => {
    const { sequenceNumber, offenseCodeDetail } = elasticOffenseViewModel;
    const offenseCodeDisplayValue = get(offenseCodeDetail, 'displayValue');
    return isValueDisplayable(offenseCodeDisplayValue)
        ? `${sequenceNumber} - ${offenseCodeDisplayValue}`
        : `${sequenceNumber}`;
};

import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import createElement from '../../../core/utils/recompose.createElement';

import OrganizationCell from '../../../../legacy-redux/components/core/tables/elasticOrganizationTable/OrganizationCell';
import EntityProfileLinkIndicator from './EntityProfileLinkIndicator';

export default function EntityProfileOrganizationSummary({ elasticOrganization }) {
    // use of `createElement` is due to the fact that all `Symbol` properties
    // of an object are lost when it's "copied" (via spread syntax). This is an intended
    // effect of spread syntax / for in
    return (
        <div className="entity-profile-summary-sibling">
            {createElement(OrganizationCell, { ...elasticOrganization })}
            <EntityProfileLinkIndicator
                className="padded"
                linkTypes={getViewModelProperties(elasticOrganization).linkTypes}
            />
        </div>
    );
}

import React, { createContext, useContext, useCallback, useMemo } from 'react';
import { Widget, CrashDiagramPosition, WidgetAction } from '../types';
import { useFetchSVGAsset } from '../hooks';
import { DEFAULT_BACKGROUND } from '../config';
import { convertDiagramSvgStringToCrashDiagramState } from '../serializer';
import {
    addWidgetToDiagram,
    removeWidgetFromDiagram,
    moveWidgetOnDiagram,
    setSelectedWidget,
    clearSelectedWidget,
    clearDiagram,
    resetDiagram,
    setDiagramBackgroundImage,
    updateWidgetValue,
    duplicateWidgetOnDiagram,
    createGhostWidgetsOnDiagram,
    applyWidgetActionOnDiagram,
    setCrashDiagramState,
    setWidgetOpacityOnDiagram,
} from './reducer/diagram/actions';
import { RootCrashDiagramActions } from './reducer';
import { CrashDiagramState } from './reducer/diagram';

type SetWidgetOpacityArgs = {
    widgetId: string;
    opacity: number;
};

type TransformWidgetArgs = {
    widgetId: string;
    position: CrashDiagramPosition;
};

type AddWidgetArgs = {
    widget: Widget;
};

type DeleteWidgetArgs = {
    widgetId: string;
};

type GhostWidgetArgs = {
    widgetId: string;
    numberOfDuplicates: number;
};

export type ApplyWidgetActionArgs = {
    widgetId: string;
    action: WidgetAction;
};

type UpdateWidgetValueArgs = {
    widgetId: string;
    value: Widget['value'];
    commit: boolean;
};

type CrashDiagramContextType = {
    widgets: Widget[];
    selectedWidget?: Widget;
    backgroundImage?: string;
    addWidget: (args: AddWidgetArgs) => void;
    clear: () => void;
    reset: () => void;
    deleteWidget: (args: DeleteWidgetArgs) => void;
    duplicateWidget: (args: DeleteWidgetArgs) => void;
    addGhostWidgets: (args: GhostWidgetArgs) => void;
    applyWidgetAction: (args: ApplyWidgetActionArgs) => void;
    transformWidget: (args: TransformWidgetArgs) => void;
    setWidgetOpacity: (args: SetWidgetOpacityArgs) => void;
    selectWidget: (widget: Widget) => void;
    clearSelection: () => void;
    setBackground: (backgroundImage: string) => void;
    updateValue: (args: UpdateWidgetValueArgs) => void;
    setCrashDiagramFromSvg: (svgString: string) => void;
};

const CrashDiagramContext = createContext<CrashDiagramContextType | undefined>(undefined);

export function useCrashDiagram(): CrashDiagramContextType {
    const context = useContext(CrashDiagramContext);

    if (context === undefined) {
        throw new Error('Crash Diagram Context must be inside a CrashDiagramProvider with a value');
    }
    return context;
}

type CrashDiagramProviderProps = {
    state: CrashDiagramState;
    dispatch: React.Dispatch<RootCrashDiagramActions>;
};

export const CrashDiagramProvider: React.FC<CrashDiagramProviderProps> = ({
    state,
    dispatch,
    children,
}) => {
    const addWidget = useCallback(
        ({ widget }: AddWidgetArgs) => {
            dispatch(addWidgetToDiagram(widget));
        },
        [dispatch]
    );

    const clear = useCallback(() => {
        dispatch(clearDiagram());
    }, [dispatch]);

    const reset = useCallback(() => {
        dispatch(resetDiagram());
    }, [dispatch]);

    const duplicateWidget = useCallback(
        ({ widgetId }: DeleteWidgetArgs) => {
            dispatch(duplicateWidgetOnDiagram(widgetId));
        },
        [dispatch]
    );

    const addGhostWidgets = useCallback(
        ({ widgetId, numberOfDuplicates }: GhostWidgetArgs) => {
            dispatch(createGhostWidgetsOnDiagram(widgetId, numberOfDuplicates));
        },
        [dispatch]
    );

    const applyWidgetAction = useCallback(
        ({ widgetId, action }: ApplyWidgetActionArgs) => {
            dispatch(applyWidgetActionOnDiagram(widgetId, action));
        },
        [dispatch]
    );

    const setWidgetOpacity = useCallback(
        ({ widgetId, opacity }: SetWidgetOpacityArgs) => {
            dispatch(setWidgetOpacityOnDiagram(widgetId, opacity));
        },
        [dispatch]
    );

    const deleteWidget = useCallback(
        ({ widgetId }: DeleteWidgetArgs) => {
            dispatch(removeWidgetFromDiagram(widgetId));
        },
        [dispatch]
    );

    const transformWidget = useCallback(
        ({ widgetId, position }: TransformWidgetArgs) => {
            dispatch(moveWidgetOnDiagram(widgetId, position));
        },
        [dispatch]
    );

    const clearSelection = useCallback(() => {
        if (!state.selectedWidget) {
            return;
        }
        dispatch(clearSelectedWidget());
    }, [dispatch, state.selectedWidget]);

    const selectWidget = useCallback(
        (widget: Widget) => {
            clearSelection();
            dispatch(setSelectedWidget(widget));
        },
        [dispatch, clearSelection]
    );

    const setBackground = useCallback(
        (image: string) => {
            dispatch(setDiagramBackgroundImage(image));
        },
        [dispatch]
    );

    const updateValue = useCallback(
        ({ widgetId, value, commit }: UpdateWidgetValueArgs) => {
            dispatch(updateWidgetValue(widgetId, value, commit));
        },
        [dispatch]
    );

    useFetchSVGAsset({
        value: !state.backgroundImage ? DEFAULT_BACKGROUND : undefined,
        assetKey: !state.backgroundImage ? DEFAULT_BACKGROUND : undefined,
        onSuccess: setBackground,
    });

    const setCrashDiagramFromSvg = useCallback(
        (svgString: string) => {
            dispatch(setCrashDiagramState(convertDiagramSvgStringToCrashDiagramState(svgString)));
        },
        [dispatch]
    );

    const value = useMemo(() => {
        return {
            clear,
            reset,
            addWidget,
            duplicateWidget,
            addGhostWidgets,
            applyWidgetAction,
            deleteWidget,
            transformWidget,
            selectWidget,
            clearSelection,
            setBackground,
            setCrashDiagramFromSvg,
            updateValue,
            setWidgetOpacity,
            widgets: state.widgets,
            selectedWidget: state.selectedWidget,
            backgroundImage: state.backgroundImage,
        };
    }, [
        addWidget,
        clear,
        reset,
        duplicateWidget,
        addGhostWidgets,
        applyWidgetAction,
        deleteWidget,
        transformWidget,
        selectWidget,
        clearSelection,
        setBackground,
        updateValue,
        setCrashDiagramFromSvg,
        setWidgetOpacity,
        state.widgets,
        state.selectedWidget,
        state.backgroundImage,
    ]);

    return <CrashDiagramContext.Provider value={value}>{children}</CrashDiagramContext.Provider>;
};

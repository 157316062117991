import { createSelector } from 'reselect';
import { get } from 'lodash';
import { itemEvidenceStateByChainOfCustodyIdSelector } from '../data';
import { formatDispositionStatus } from '../../utils/formatDisposition';
/**
 * Given the chainOfCustodyId, find and format the disposition status for item
 * @param  {number} chainOfCustodyId
 * @return {string}
 */
export const formatDispositionStatusByChainOfCustodyIdSelector = createSelector(
    itemEvidenceStateByChainOfCustodyIdSelector,
    (itemEvidenceStateByChainOfCustodyId) => (chainOfCustodyId) => {
        const dispositionStatus = get(
            itemEvidenceStateByChainOfCustodyId(chainOfCustodyId),
            'dispositionStatus'
        );

        return formatDispositionStatus(dispositionStatus);
    }
);

import PropTypes from 'prop-types';
import { simpleControl } from 'markformythree';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName, setPropTypes } from 'recompose';

import { filterLinkTypes, filterHiddenLinkTypes } from '~/client-common/helpers/linkTypesHelpers';
import { hiddenDepartmentLinkTypeIdsSelector } from '~/client-common/core/domain/hidden-department-link-types/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { linkTypesSelector } from '../../../../../legacy-redux/selectors/linkTypesSelectors';
import Select from './Select';

const LinkTypeSelect = compose(
    setDisplayName('LinkTypeSelect'),
    setPropTypes({
        entityTypePairs: PropTypes.arrayOf(PropTypes.array).isRequired,
        filterOptions: PropTypes.func,
        mapLinkTypesToOptions: PropTypes.func.isRequired,
    }),
    connect(
        createStructuredSelector({
            linkTypes: linkTypesSelector,
            hiddenDeptLinkTypeIds: hiddenDepartmentLinkTypeIdsSelector,
            applicationSettings: applicationSettingsSelector,
        })
    )
)(function LinkTypeSelect({
    entityTypePairs = [],
    hideLinkTypes,
    hiddenDeptLinkTypeIds,
    filterOptions,
    linkTypes,
    mapLinkTypesToOptions,
    applicationSettings,
    value,
    ...otherProps
}) {
    const configurableRelationshipsEnabled =
        applicationSettings.RMS_CONFIGURABLE_RELATIONSHIPS_ENABLED;
    const displayedLinkTypes =
        configurableRelationshipsEnabled && hideLinkTypes
            ? filterHiddenLinkTypes(linkTypes, hiddenDeptLinkTypeIds, value)
            : linkTypes;
    const filteredLinkTypes = filterLinkTypes(displayedLinkTypes, entityTypePairs);
    const options = mapLinkTypesToOptions(filteredLinkTypes);

    return (
        <Select
            options={filterOptions ? filterOptions(options) : options}
            value={value}
            {...otherProps}
        />
    );
});

/**
 * Select dropdown containing link types.
 * @param {Object}   props Other props get spread into the parent `Select`.
 * @param {Array[]}  [props.entityTypePairs] If provided, only link types
 *   between these pairs of entity types appear as options. Each element of the
 *   array must be an array of 2 enum values. Within each pair, order does not
 *   matter, and 1 of the 2 values may be `undefined` since there exist link
 *   types with only one entity type.
 * @param {function} [props.filterOptions] If provided, this function is called
 *   on the original array of options and its return value becomes the new
 *   options.
 * @param {function} props.mapLinkTypesToOptions If provided, this function
 *   is used to transform the filtered link types to options
 */
export default LinkTypeSelect;

export const MFTLinkTypeSelect = simpleControl(LinkTypeSelect);

import { OffenseAttribute } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import getOffensesResource from '../../../offenses/resources/offensesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'offenseAttributes';

const offenseAttributeModule = createLinkModule<OffenseAttribute>({
    type: NEXUS_STATE_PROP,
    keys: ['attributeId', 'offenseId'],
});

// ACTIONS

const replaceOffenseAttributesWhere = offenseAttributeModule.actionCreators.replaceLinksWhere;

// SELECTORS
export const offenseAttributesSelector = offenseAttributeModule.selectors.linksSelector;

// REDUCER
export default offenseAttributeModule.reducerConfig;

export function setOffenseAttributes({
    offenseId,
    attributes,
}: {
    offenseId: number;
    attributes: OffenseAttribute[];
}): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        // no success/failure dispatch here because this function is not meant to be used
        // on its own and is only a crutch until we move to bundle-oriented endpoints
        // which won't require us to store all the things using separate endpoints
        return getOffensesResource()
            .setAttributes({ offenseId, attributes })
            .then(
                (attributes: OffenseAttribute[]) =>
                    dispatch(replaceOffenseAttributesWhere({ offenseId }, attributes)) && attributes
            );
        // not catching here because that's the consumer's responsibility
    };
}

import _ from 'lodash';
import { createFormConfiguration } from 'markformythree';
import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';

export const formConfiguration = createFormConfiguration({
    query: {},
    dateType: {},
    dateRange: {},
    daysUntilTasksDue: {},
    isOverdue: {},
    title: {},
    // this 'Untransformed' suffix is to prevent the statusAttrIds field from being converted to an
    // AttrDetail via transformElasticQueryBeforeSearch and
    // recursivelyConvertIdsToDisplayValueObjects which are for elastic queries, whereas this tasks
    // search is not elastic and does not support AttrDetail
    statusAttrIdsUntransformed: {},
    creatorUserIds: {},
    assigneeRoleIds: {},
    priorityAttrIdsUntransformed: {},
    typeAttrIdsUntransformed: {},
    sourceNumbers: {},
});

export const initialState = {
    query: '',
    dateType: null,
    dateRange: null,
    daysUntilTasksDue: null,
    isOverdue: null,
    title: '',
    statusAttrIdsUntransformed: [],
    creatorUserIds: [],
    assigneeRoleIds: [],
    priorityAttrIdsUntransformed: [],
    typeAttrIdsUntransformed: [],
    sourceNumbers: [],
};

const dateTypeToDateRangeQueryKey = {
    [dateTypeEnum.CREATED]: 'createdDateRangeQuery',
    [dateTypeEnum.MODIFIED]: 'updatedDateRangeQuery',
    [dateTypeEnum.DUE]: 'dueDateQuery',
    [dateTypeEnum.COMPLETED]: 'statusDateRangeQuery',
};

/**
 * Convert a TaskSqlQuery into a form model for the Tasks Dashboard search form.
 * @param  {Object} taskSqlQuery
 * @param  {Object} formModel
 * @return {Object}
 */
export function convertToFormModel(taskSqlQuery = {}) {
    const dateRangeQueryKey = _.find(
        _.keys(taskSqlQuery),
        (key) =>
            _.includes(_.values(dateTypeToDateRangeQueryKey), key) && !_.isEmpty(taskSqlQuery[key])
    );

    return {
        query: taskSqlQuery.query,
        dateType: _.invert(dateTypeToDateRangeQueryKey)[dateRangeQueryKey] || dateTypeEnum.CREATED,
        dateRange: taskSqlQuery[dateRangeQueryKey],
        daysUntilTasksDue: taskSqlQuery.daysUntilTasksDue,
        isOverdue: taskSqlQuery.isOverdue,
        title: taskSqlQuery.title,
        statusAttrIdsUntransformed: taskSqlQuery.statusAttrIds,
        creatorUserIds: taskSqlQuery.creatorUserIds,
        assigneeRoleIds: taskSqlQuery.assigneeRoleIds,
        priorityAttrIdsUntransformed: taskSqlQuery.priorityAttrIds,
        typeAttrIdsUntransformed: taskSqlQuery.typeAttrIds,
        sourceNumbers: taskSqlQuery.sourceNumbers && taskSqlQuery.sourceNumbers.join(', '),
    };
}

/**
 * Convert the form model for the Tasks Dashboard search form into a TaskSqlQuery.
 * @param  {Object} formModel
 * @return {Object}
 */
export function convertFromFormModel(formModel = {}) {
    const {
        query,
        dateType,
        dateRange,
        daysUntilTasksDue,
        isOverdue,
        title,
        statusAttrIdsUntransformed,
        creatorUserIds,
        assigneeRoleIds,
        priorityAttrIdsUntransformed,
        typeAttrIdsUntransformed,
        sourceNumbers,
        owners,
        entityId,
        entityType,
    } = formModel;

    const isDateRangeQueryPresent = dateType && !_.isEmpty(dateRange);
    const dateRangeQuery = isDateRangeQueryPresent ? { [dateTypeToDateRangeQueryKey[dateType]]: dateRange } : {};

    const isOverdueValue = isOverdue === true || isOverdue === false ? isOverdue : undefined;

    const parsedDaysUntilDue = parseInt(daysUntilTasksDue);
    const daysUntilTasksDueValue = Number.isFinite(parsedDaysUntilDue) ? parsedDaysUntilDue : undefined;

    let sourceNumbersArr;
    if (sourceNumbers && sourceNumbers.length > 0) {
        sourceNumbersArr = _.map(sourceNumbers.split(','), (sourceNumber) => {
            return sourceNumber.trim();
        });
    }

    return {
        query,
        ...dateRangeQuery,
        daysUntilTasksDue: daysUntilTasksDueValue,
        isOverdue: isOverdueValue,
        title,
        statusAttrIds: statusAttrIdsUntransformed,
        creatorUserIds,
        assigneeRoleIds,
        priorityAttrIds: priorityAttrIdsUntransformed,
        typeAttrIds: typeAttrIdsUntransformed,
        sourceNumbers: sourceNumbersArr,
        owners,
        entityId,
        entityType,
    };
}

import { createSelector } from 'reselect';
import { UseOfForceSubject } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'useOfForceSubjects';
const useOfForceSubjectsModule = createNormalizedModule<UseOfForceSubject>({
    type: NEXUS_STATE_PROP,
});

export const deleteUseOfForceSubject = useOfForceSubjectsModule.actionCreators.deleteEntity;

// SELECTORS

export const useOfForceSubjectsWhereSelector =
    useOfForceSubjectsModule.selectors.entitiesWhereSelector;
export const useOfForceSubjectsByReportIdSelector = createSelector(
    useOfForceSubjectsWhereSelector,
    (useOfForceSubjectsWhere) => (reportId: number) => useOfForceSubjectsWhere({ reportId })
);

// REDUCER
export default useOfForceSubjectsModule.reducerConfig;

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { replaceRelationshipsCardBundle } from '~/client-common/core/domain/reports/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import createCard from '../../utils/createCard';
import { buildRelationshipsFormModel, convertFromFormModel } from '../forms/relationshipsForm';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { relationshipsDataSelector } from './relationships';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.RELATIONSHIPS.name,
    baseSelector: (state) => state.ui.report.relationshipsCard,
    anchor: 'relationships-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const applicationSettings = applicationSettingsSelector(state);

                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const { model } = form.getState();
                                const relationshipsData = relationshipsDataSelector(state);
                                const reportId = currentReportIdSelector(state);
                                return [
                                    dispatch(
                                        replaceRelationshipsCardBundle(
                                            reportId,
                                            convertFromFormModel(
                                                model,
                                                relationshipsData,
                                                applicationSettings.RMS_RELATIONSHIP_PREFILL_ENABLED
                                            )
                                        )
                                    ),
                                ];
                            },
                            onSavingSuccess: () => {
                                // Also resync state to the form
                                form.set('', dispatch(buildRelationshipsFormModel()));
                            },
                        })
                    );
                }
            };
        },
    },
};

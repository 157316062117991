import React from 'react';
import styled from 'styled-components';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    REPORT_RECORD_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import testIds from '../../../../core/testIds';

import { PopoutLink, ExternalLink } from '../../../core/components/links/Link';

// need both max-width and min-width because
// for some reason just max-width doesn't word-wrap correctly
const CaseAssociatedRecordCell = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    max-width: ${(props) => props.width}px;
    min-width: ${(props) => props.width}px;
`;

const SourceReportGrid = styled.div`
    display: inline-grid;
`;

const RenRecordIdText = styled.span`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const ReasonForAssociationText = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.mediumGrey};
`;

const CaseAssociatedRecordRow = styled.div`
    font-size: var(--arc-fontSizes-md);
    min-height: 45px;
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};

    &,
    * {
        box-sizing: border-box;
        word-wrap: break-word;
    }
`;

function _CaseAssociatedRecordRow({
    associatedRecord,
    tableWidths,
    formatFieldByName,
}) {
    const {
        associatedRecordTitle,
        reasonForAssociation,
        sourceReportTitle,
        associatedRecordUrl,
        sourceReportUrl,
        isExternalLink,
        reportingEventNumber,
        recordNumber,
    } = associatedRecord;
    const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
    const recordIdDisplayName = formatFieldByName(REPORT_RECORD_NUMBER);

    return (
        <CaseAssociatedRecordRow>
            <CaseAssociatedRecordCell
                width={tableWidths.associatedRecordTitle}
                data-test-id={testIds.CASE_ASSOCIATED_RECORD_TITLE}
            >
                {associatedRecordUrl ? (
                    isExternalLink ? (
                        <ExternalLink
                            to={associatedRecordUrl}
                            openInNewTab={true}
                        >
                            {associatedRecordTitle}
                        </ExternalLink>
                    ) : (
                        <PopoutLink to={associatedRecordUrl}>
                            {associatedRecordTitle}
                        </PopoutLink>
                    )
                ) : (
                    <span>{associatedRecordTitle}</span>
                )}
            </CaseAssociatedRecordCell>
            <CaseAssociatedRecordCell width={tableWidths.reasonForAssociation}>
                <ReasonForAssociationText>
                    {reasonForAssociation}
                </ReasonForAssociationText>
            </CaseAssociatedRecordCell>
            <CaseAssociatedRecordCell width={tableWidths.sourceReportTitle}>
                <SourceReportGrid>
                    {sourceReportUrl ? (
                        <PopoutLink to={sourceReportUrl}>
                            {sourceReportTitle}
                        </PopoutLink>
                    ) : (
                        <span>{sourceReportTitle}</span>
                    )}
                    <RenRecordIdText>
                        {reportingEventNumber
                            ? `${renDisplayName} ${reportingEventNumber}`
                            : `${recordIdDisplayName} ${recordNumber}`}
                    </RenRecordIdText>
                </SourceReportGrid>
            </CaseAssociatedRecordCell>
        </CaseAssociatedRecordRow>
    );
}

export default _CaseAssociatedRecordRow;

import _, { compact, includes, intersection } from 'lodash';
import { AttributeView, OffenseCode } from '@mark43/rms-api';

import { OFFENSE_TYPE_OFFENSE_CODE_FLAGS, INCIDENT_TYPE_OFFENSE_CODE_FLAGS } from '../constants';

const MPD_FEDERAL_STATUTE_CODE_SET = 'USC';

/**
 * The LOCAL and FEDERAL values are used only by Washington DC MPD.
 */
export const StatuteCodeSetFilterEnum = {
    LOCAL_OFFENSE: 'LOCAL_OFFENSE',
    FEDERAL_OFFENSE: 'FEDERAL_OFFENSE',
    INCIDENT: 'INCIDENT',
} as const;

// mpdc, sandbox, and *-training is just for internal testing
const defaultLocalDepartments = [
    'dcpolice',
    'dc-partneragencies',
    'dc-partneragencies-training',
    'mpdc',
    'mpd-sandbox',
    'mpd-training',
    'usss',
    'usss-training',
];

/**
 * Return a more restricted array of flags for the given filter.
 */
export function applyStatuteCodeSetFilterToFlags(
    filter: keyof typeof StatuteCodeSetFilterEnum,
    defaultFlags: string[]
): readonly string[] {
    switch (filter) {
        case StatuteCodeSetFilterEnum.LOCAL_OFFENSE:
        case StatuteCodeSetFilterEnum.FEDERAL_OFFENSE:
            return OFFENSE_TYPE_OFFENSE_CODE_FLAGS;
        case StatuteCodeSetFilterEnum.INCIDENT:
            return INCIDENT_TYPE_OFFENSE_CODE_FLAGS;
        default:
            return defaultFlags;
    }
}

/**
 * This logic is currently specific to Washington DC MPD since among all agencies, they have unique
 *   requirements for distinguishing offense codes between local and federal. This function may be
 *   rewritten in a generic way under a more powerful rule system.
 */
export function convertStatuteCodeSetFilterToAttrIds(
    filter: keyof typeof StatuteCodeSetFilterEnum,
    statuteCodeSetAttributes: AttributeView[]
): number[] {
    switch (filter) {
        case StatuteCodeSetFilterEnum.LOCAL_OFFENSE:
            return _(statuteCodeSetAttributes)
                .reject({ val: MPD_FEDERAL_STATUTE_CODE_SET })
                .map('id')
                .value();
        case StatuteCodeSetFilterEnum.FEDERAL_OFFENSE:
            return _(statuteCodeSetAttributes)
                .filter({ val: MPD_FEDERAL_STATUTE_CODE_SET })
                .map('id')
                .value();
        case StatuteCodeSetFilterEnum.INCIDENT:
        default:
            return [];
    }
}

export function convertOffenseCodeToStatuteCodeSetFilter(
    offenseCode: OffenseCode | undefined,
    statuteCodeSetAttributes: AttributeView[],
    departmentSubDomain: string,
    isStaticallyHidden: boolean
): keyof typeof StatuteCodeSetFilterEnum | undefined {
    if (isStaticallyHidden) {
        return undefined;
    } else if (!offenseCode) {
        if (includes(defaultLocalDepartments, departmentSubDomain)) {
            return StatuteCodeSetFilterEnum.LOCAL_OFFENSE;
        }
        return undefined;
    } else if (offenseCode.isIncidentType) {
        return StatuteCodeSetFilterEnum.INCIDENT;
    } else if (
        offenseCodeIsInStatuteCodeSetFilter(
            offenseCode,
            StatuteCodeSetFilterEnum.LOCAL_OFFENSE,
            statuteCodeSetAttributes
        )
    ) {
        return StatuteCodeSetFilterEnum.LOCAL_OFFENSE;
    } else if (
        offenseCodeIsInStatuteCodeSetFilter(
            offenseCode,
            StatuteCodeSetFilterEnum.FEDERAL_OFFENSE,
            statuteCodeSetAttributes
        )
    ) {
        return StatuteCodeSetFilterEnum.FEDERAL_OFFENSE;
    } else {
        return undefined;
    }
}

/**
 * Whether a single offense code belongs within the given filter.
 */
export function offenseCodeIsInStatuteCodeSetFilter(
    offenseCode: OffenseCode,
    filter: keyof typeof StatuteCodeSetFilterEnum,
    statuteCodeSetAttributes: AttributeView[]
): boolean {
    const { statuteCodeSetAttrId, statuteCodeSet2AttrId, statuteCodeSet3AttrId } = offenseCode;
    const attrIds = convertStatuteCodeSetFilterToAttrIds(filter, statuteCodeSetAttributes);

    return (
        intersection(
            compact([statuteCodeSetAttrId, statuteCodeSet2AttrId, statuteCodeSet3AttrId]),
            attrIds
        ).length > 0
    );
}

import { filter, includes, map, some } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';

import { chargesSelector } from '~/client-common/core/domain/charges/state/data';
import { offensesSelector } from '~/client-common/core/domain/offenses/state/data';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { isOffenseReportSelector } from '~/client-common/core/domain/report-definitions/state/data';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import {
    approvalStatusSelector,
    linkedOmsReportSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';

import { RmsDispatch } from '../../../../../core/typings/redux';
import Modal, { OverrideModalPropsFunction } from '../../../../core/overlays/components/Modal';
import { useOverlayStore } from '../../../../core/overlays/hooks/useOverlayStore';
import reportsResource from '../../resources/reportsResource';
import { removeEmbeddedReport } from '../../state/ui';
import {
    offenseReportDefinitionForCurrentDepartmentSelector,
    offenseModifyingSupplementReportDefinitionForCurrentDepartmentSelector,
} from '../../../../core/report-definitions/state/ui';

type DeleteReportModalCustomProps = {
    isPurge: boolean;
    reportId: number;
};
type DeleteReportOverlayIds = keyof Pick<
    typeof overlayIdEnum,
    'DELETE_REPORT_FOR_REPORT_HEADER_MODAL' | 'DELETE_REPORT_FOR_EMBEDDED_REPORT_MODAL'
>;
type DeleteReportModalProps = {
    closeFocusRef?: React.RefObject<HTMLElement>;
    overlayId: DeleteReportOverlayIds;
} & WithRouterProps;
type UseOpenDeleteReportModalProps = {
    overlayId: DeleteReportOverlayIds;
    reportId: number;
};

const strings = componentStrings.reports.core.header.DeleteReportModal;

const BoldText = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

type LinkedChargeData = { offenseNames: string[]; chargeCount: number };

function useGetLinkedChargeData(): (reportId: number) => LinkedChargeData | undefined {
    const isOffenseReport = useSelector(isOffenseReportSelector);
    const offenses = useSelector(offensesSelector);
    const offenseCodes = useSelector(offenseCodesSelector);
    const charges = useSelector(chargesSelector);

    return (reportId: number) => {
        if (!isOffenseReport(reportId)) {
            return undefined;
        }

        const offensesWithLinkedCharges = filter(
            offenses,
            (offense) => offense.reportId === reportId && some(charges, { offenseId: offense.id })
        );
        if (offensesWithLinkedCharges.length === 0) {
            return undefined;
        }

        const offenseIds = map(offensesWithLinkedCharges, 'id');
        return {
            offenseNames: map(offensesWithLinkedCharges, (offense) =>
                offense.offenseCodeId
                    ? offenseCodes[offense.offenseCodeId]?.displayName
                    : `${offense.offenseOrder}`
            ),
            chargeCount: filter(charges, (charge) => includes(offenseIds, charge.offenseId)).length,
        };
    };
}

function computeMessage({
    isPurge,
    linkedChargeData,
    isApprovedOffenseReportWithLinkedOms,
    omsReportTypeDisplayName,
    offenseReportTypeDisplayName,
    offenseDisplayName,
}: {
    isPurge: boolean;
    linkedChargeData?: LinkedChargeData;
    isApprovedOffenseReportWithLinkedOms: boolean;
    omsReportTypeDisplayName?: string;
    offenseReportTypeDisplayName?: string;
    offenseDisplayName: string;
}) {
    if (linkedChargeData) {
        return [
            strings.unableToDeleteDueToLinkedCharges({
                ...linkedChargeData,
                offenseDisplayName,
            }),
        ];
    }

    if (isApprovedOffenseReportWithLinkedOms) {
        return [
            strings.deletingOffenseReportWillDeleteAllOMS(
                offenseReportTypeDisplayName,
                omsReportTypeDisplayName
            ),
        ];
    }

    if (isPurge) {
        return [strings.approvedMessage, strings.approvedSubMessage];
    }

    return [strings.unapprovedMessage];
}

export function useOpenDeleteReportModal(props: UseOpenDeleteReportModalProps) {
    const { overlayId, reportId } = props;

    const overlayStore = useOverlayStore<DeleteReportModalCustomProps>();
    const open = useCallback((isPurge) => overlayStore.open(overlayId, { reportId, isPurge }), [
        overlayId,
        overlayStore,
        reportId,
    ]);

    return open;
}

const DeleteReportModal: React.FC<DeleteReportModalProps> = (props) => {
    const { closeFocusRef, overlayId, router } = props;

    const dispatch = useDispatch<RmsDispatch>();
    const offenseDisplayName = useOffenseFieldName();
    const isOffenseReport = useSelector(isOffenseReportSelector);
    const approvalStatus = useSelector(approvalStatusSelector);
    const offenseReportDefinition = useSelector(
        offenseReportDefinitionForCurrentDepartmentSelector
    );
    const getLinkedChargeData = useGetLinkedChargeData();
    const linkedOms = useSelector(linkedOmsReportSelector);
    const omsReportDefinition = useSelector(
        offenseModifyingSupplementReportDefinitionForCurrentDepartmentSelector
    );
    const overlayStore = useOverlayStore<DeleteReportModalCustomProps>();

    const onSaveHandler = useCallback(() => {
        const customProps = overlayStore.getStateForId(overlayId).customProperties;
        const deleteReportResource = customProps.isPurge
            ? reportsResource.purgeReport
            : reportsResource.deleteReport;

        return deleteReportResource(customProps.reportId).then(() => {
            if (overlayId === overlayIdEnum.DELETE_REPORT_FOR_REPORT_HEADER_MODAL) {
                router.push('/reports');
            } else if (overlayId === overlayIdEnum.DELETE_REPORT_FOR_EMBEDDED_REPORT_MODAL) {
                dispatch(removeEmbeddedReport(customProps.reportId));
            }
        });
    }, [dispatch, overlayId, overlayStore, router]);

    const overrideModalProps: OverrideModalPropsFunction<DeleteReportModalCustomProps> = (
        overlayBaseProps
    ) => {
        const { overlayState } = overlayBaseProps;
        const { customProperties, errors } = overlayState;
        const { reportId } = customProperties;
        const isApprovedOffenseReportWithLinkedOms =
            approvalStatus === approvalStatusClientEnum.COMPLETED &&
            isOffenseReport(reportId) &&
            linkedOms.length > 0;
        const offenseReportTypeDisplayName = offenseReportDefinition?.name.includes('Report')
            ? offenseReportDefinition.name
            : offenseReportDefinition?.name.concat(' Report');
        const linkedChargeData = getLinkedChargeData(reportId);
        const [message, subMessage] = computeMessage({
            isPurge: customProperties.isPurge,
            linkedChargeData,
            isApprovedOffenseReportWithLinkedOms,
            omsReportTypeDisplayName: omsReportDefinition?.name,
            offenseReportTypeDisplayName,
            offenseDisplayName,
        });

        const children = (
            <>
                {message}
                {subMessage && <BoldText>{subMessage}</BoldText>}
            </>
        );

        const saveDisabled = !!linkedChargeData || errors.length > 0;

        return { children, saveDisabled };
    };

    return (
        <Modal<DeleteReportModalCustomProps>
            cancelFocusRef={closeFocusRef}
            defaultErrorMessage={strings.errorMessage}
            id={overlayId}
            okText={strings.okText}
            onSave={onSaveHandler}
            overrideModalProps={overrideModalProps}
            saveFocusRef={closeFocusRef}
            title={strings.title}
        />
    );
};

export default withRouter(DeleteReportModal);

import { EntityTypeEnum, LinkTypesEnum, ComplianceGroupEnum } from '@mark43/rms-api';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { some, map } from 'lodash';
import styled from 'styled-components';
import { Menu, MenuTrigger, MenuContent, MenuItem, MenuListGroup } from 'arc';
import { createStructuredSelector } from 'reselect';
import { Observer } from 'markformythree';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import { locationEntityLinksSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { unknownLocationIdSelector } from '~/client-common/core/constants/state/constants';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_LOCATION_ID,
    DISPLAY_ONLY_PERSON_PROFILE_NO_FIXED_ADDRESS_LABEL,
    LOCATION_ENTITY_LINK_LINK_TYPE_WORKS_AT_LINK_TYPE,
    DISPLAY_ONLY_ADD_OTHER_PERSON_LOCATION_LABEL,
} from '~/client-common/core/enums/universal/fields';
import {
    otherPersonLocationTypes,
    getOtherLocationTypesLabelMap,
} from '~/client-common/helpers/linkTypesHelpers';
import { DashedDivider } from '../../../../legacy-redux/components/core/Divider';
import { MFTNItems } from '../../forms/components/NItems';

import testIds from '../../../../core/testIds';

import { LocationSidePanel } from '../../locations/components/LocationSidePanel';
import { renderAddButton } from '../../names/components/nameFormNItemsRenderers';
import {
    convertFormLocationEntityLinkToLocationBundle,
    addLocationToFormFactory,
} from '../../names/util/nameFormHelpers';
import WithComplianceGroup from '../../components/WithComplianceGroup';
import { FormSection } from './FormSection';

import { NItemsRow } from './NItemsRow';
import { renderLocationNItems, renderHomeAddressLocationNItems } from './nameFormNItemsRenderers';

const HOME_ADDRESSES_PATH = 'homeAddresses';
const WORK_ADDRESSES_PATH = 'workAddresses';
const OTHER_ADDRESSES_PATH = 'otherAddresses';

const HomeAddressButtonWrapper = styled.div`
    display: flex;
    width: 100%;
`;

function renderLocationAddButton({ overlayBase: { open }, setCloseFocusRefs }, addText) {
    return renderAddButton({
        addText,
        addItem: open,
        testId: testIds.LOCATION_SUMMARY_VIEW_ADD_LOCATION,
        setRef: setCloseFocusRefs,
    });
}

export const PersonProfileFormAddressFields = connect(
    createStructuredSelector({
        applicationSettings: applicationSettingsSelector,
        locations: locationsSelector,
        locationEntityLinks: locationEntityLinksSelector,
        unknownLocationId: unknownLocationIdSelector,
        formatFieldByName: formatFieldByNameSelector,
    })
)(
    class PersonProfileFormAddressFields extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                otherLinkType: undefined,
            };
        }

        handleHomeLocationSaveSuccess = addLocationToFormFactory(
            this.props.form,
            HOME_ADDRESSES_PATH
        );

        handleWorkLocationSaveSuccess = addLocationToFormFactory(
            this.props.form,
            WORK_ADDRESSES_PATH
        );

        handleOtherLocationSaveSuccess = addLocationToFormFactory(
            this.props.form,
            OTHER_ADDRESSES_PATH,
            true
        );

        handleOtherLocationSelect = (linkType, open) => {
            this.setState({
                otherLinkType: linkType,
            });
            open();
        };

        handleHomeNoFixedLocation = () =>
            this.props.form.push(HOME_ADDRESSES_PATH, {
                locationId: this.props.unknownLocationId,
                isUnknownLocation: true,
            });

        convertFormLocationEntityLinkToLocationBundleWithLinkType = (
            formLocationEntityLink,
            linkType
        ) =>
            convertFormLocationEntityLinkToLocationBundle({
                formLocationEntityLink,
                stateData: {
                    locations: this.props.locations,
                    locationEntityLinks: this.props.locationEntityLinks,
                    unknownLocationId: this.props.unknownLocationId,
                },
                additionalData: {
                    entityId: this.props.entityId,
                    entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    linkType,
                },
                returnUnknownLocationId: true,
            });

        render() {
            const otherLocationTypesLabelMap = getOtherLocationTypesLabelMap(
                this.props.formatFieldByName
            );

            const locationSidePanelProps = {
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
                entityId: this.props.entityId,
                id: 0,
                temp: true,
            };
            const homeLocationSidePanelProps = {
                ...locationSidePanelProps,
                linkType: LinkTypesEnum.LIVES_AT,
                onSaveSuccess: (location, entityLink) => {
                    const prefilledData = this.props.applicationSettings
                        .RMS_POLE_DATA_CAPTURE_ENABLED
                        ? {
                              startDateUtc: moment(new Date()).utc().toISOString(),
                          }
                        : undefined;
                    this.handleHomeLocationSaveSuccess(location, entityLink, prefilledData);
                },
            };
            const workLocationSidePanelProps = {
                ...locationSidePanelProps,
                linkType: LinkTypesEnum.WORKS_AT,
                onSaveSuccess: (location, entityLink) => {
                    const prefilledData = this.props.applicationSettings
                        .RMS_POLE_DATA_CAPTURE_ENABLED
                        ? {
                              startDateUtc: moment(new Date()).utc().toISOString(),
                          }
                        : undefined;
                    this.handleWorkLocationSaveSuccess(location, entityLink, prefilledData);
                },
            };
            const getOtherLocationSidePanelProps = () => {
                return {
                    ...locationSidePanelProps,
                    linkType: this.state.otherLinkType,
                    onSaveSuccess: (location, entityLink) => {
                        const prefilledData = this.props.applicationSettings
                            .RMS_POLE_DATA_CAPTURE_ENABLED
                            ? {
                                  startDateUtc: moment(new Date()).utc().toISOString(),
                              }
                            : undefined;
                        this.handleOtherLocationSaveSuccess(location, entityLink, prefilledData);
                    },
                };
            };

            return (
                <>
                    <div
                        className="person-side-panel-home-location"
                        data-test-field-name={LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_LOCATION_ID}
                    >
                        <MFTNItems
                            path={HOME_ADDRESSES_PATH}
                            renderRowContainer={({ itemElement, index, item }) => (
                                <NItemsRow
                                    key={index}
                                    index={index}
                                    item={item}
                                    children={itemElement}
                                />
                            )}
                            addItemOnEmpty={false}
                            renderAddButton={undefined}
                            renderRemoveButton={undefined}
                            render={({ item, removeItem, index }) => (
                                <FormSection
                                    children={renderHomeAddressLocationNItems({
                                        locationBundleWithUnknownLocationId: this.convertFormLocationEntityLinkToLocationBundleWithLinkType(
                                            item,
                                            LinkTypesEnum.LIVES_AT
                                        ),
                                        formPath: `${HOME_ADDRESSES_PATH}[${index}]`,
                                        locationSummaryTitle: this.props.formatFieldByName(
                                            LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_LOCATION_ID
                                        ),
                                    })}
                                    removeItem={removeItem}
                                />
                            )}
                            minimumNumberOfItems={0}
                        />
                        <LocationSidePanel
                            overlayId={
                                overlayIdEnum.LOCATION_OVERLAY_PERSON_SIDE_PANEL_HOME_ADDRESS
                            }
                            renderButton={(props) => (
                                <HomeAddressButtonWrapper>
                                    {renderLocationAddButton(
                                        props,
                                        this.props.formatFieldByName(
                                            LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_LOCATION_ID
                                        )
                                    )}
                                    <Observer
                                        path={HOME_ADDRESSES_PATH}
                                        subscriptions={{
                                            addedLocations: HOME_ADDRESSES_PATH,
                                        }}
                                        render={({ addedLocations }) => {
                                            if (
                                                some(
                                                    addedLocations,
                                                    (location) => (location || {}).isUnknownLocation
                                                )
                                            ) {
                                                return null;
                                            }
                                            return renderAddButton({
                                                addText: this.props.formatFieldByName(
                                                    DISPLAY_ONLY_PERSON_PROFILE_NO_FIXED_ADDRESS_LABEL
                                                ),
                                                addItem: this.handleHomeNoFixedLocation,
                                            });
                                        }}
                                    />
                                </HomeAddressButtonWrapper>
                            )}
                            {...homeLocationSidePanelProps}
                        />
                    </div>
                    <DashedDivider />
                    <div
                        className="person-side-panel-work-location"
                        data-test-field-name={LOCATION_ENTITY_LINK_LINK_TYPE_WORKS_AT_LINK_TYPE}
                    >
                        <MFTNItems
                            path={WORK_ADDRESSES_PATH}
                            addItemOnEmpty={false}
                            renderRowContainer={({ itemElement, index, item }) => (
                                <NItemsRow
                                    key={index}
                                    index={index}
                                    item={item}
                                    children={itemElement}
                                />
                            )}
                            renderAddButton={undefined}
                            renderRemoveButton={undefined}
                            render={({ item, removeItem, index }) => (
                                <FormSection
                                    children={renderLocationNItems({
                                        locationBundleWithUnknownLocationId: this.convertFormLocationEntityLinkToLocationBundleWithLinkType(
                                            item,
                                            LinkTypesEnum.WORKS_AT
                                        ),
                                        formPath: `${WORK_ADDRESSES_PATH}[${index}]`,
                                        locationSummaryTitle: this.props.formatFieldByName(
                                            LOCATION_ENTITY_LINK_LINK_TYPE_WORKS_AT_LINK_TYPE
                                        ),
                                    })}
                                    removeItem={removeItem}
                                />
                            )}
                            minimumNumberOfItems={0}
                        />
                        <LocationSidePanel
                            overlayId={
                                overlayIdEnum.LOCATION_OVERLAY_PERSON_SIDE_PANEL_WORK_ADDRESS
                            }
                            renderButton={(props) =>
                                renderLocationAddButton(
                                    props,
                                    this.props.formatFieldByName(
                                        LOCATION_ENTITY_LINK_LINK_TYPE_WORKS_AT_LINK_TYPE
                                    )
                                )
                            }
                            {...workLocationSidePanelProps}
                        />
                    </div>

                    <WithComplianceGroup complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}>
                        <DashedDivider />
                        <div
                            className="person-side-panel-work-location"
                            data-test-field-name={DISPLAY_ONLY_ADD_OTHER_PERSON_LOCATION_LABEL}
                        >
                            <MFTNItems
                                path={OTHER_ADDRESSES_PATH}
                                addItemOnEmpty={false}
                                renderRowContainer={({ itemElement, index, item }) => (
                                    <NItemsRow
                                        key={index}
                                        index={index}
                                        item={item}
                                        children={itemElement}
                                    />
                                )}
                                renderAddButton={undefined}
                                renderRemoveButton={undefined}
                                render={({ item, removeItem, index }) => (
                                    <FormSection
                                        children={renderLocationNItems({
                                            locationBundleWithUnknownLocationId: this.convertFormLocationEntityLinkToLocationBundleWithLinkType(
                                                item,
                                                item.linkType
                                            ),
                                            renderDescription: true,
                                            formPath: `${OTHER_ADDRESSES_PATH}[${index}]`,
                                            locationSummaryTitle:
                                                otherLocationTypesLabelMap[item.linkType],
                                        })}
                                        removeItem={removeItem}
                                    />
                                )}
                                minimumNumberOfItems={0}
                            />
                            <LocationSidePanel
                                overlayId={
                                    overlayIdEnum.LOCATION_OVERLAY_PERSON_SIDE_PANEL_OTHER_ADDRESS
                                }
                                renderButton={(props) => {
                                    return (
                                        <Menu>
                                            <MenuTrigger>
                                                {renderAddButton({
                                                    addText: this.props.formatFieldByName(
                                                        DISPLAY_ONLY_ADD_OTHER_PERSON_LOCATION_LABEL
                                                    ),
                                                    addItem: () => {},
                                                    testId:
                                                        testIds.LOCATION_SUMMARY_VIEW_ADD_LOCATION,
                                                    setRef: this.props.setCloseFocusRefs,
                                                })}
                                            </MenuTrigger>
                                            <MenuContent>
                                                <MenuListGroup title={'Location Types'}>
                                                    {map(otherPersonLocationTypes, (type) => (
                                                        <MenuItem
                                                            key={type}
                                                            onSelect={() =>
                                                                this.handleOtherLocationSelect(
                                                                    type,
                                                                    props.overlayBase.open
                                                                )
                                                            }
                                                        >
                                                            {otherLocationTypesLabelMap[type]}
                                                        </MenuItem>
                                                    ))}
                                                </MenuListGroup>
                                            </MenuContent>
                                        </Menu>
                                    );
                                }}
                                {...getOtherLocationSidePanelProps()}
                            />
                        </div>
                    </WithComplianceGroup>
                </>
            );
        }
    }
);

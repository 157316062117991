import React from 'react';
import styled from 'styled-components';
import { map, filter } from 'lodash';
import { Text as ArcText, cssVar, mediaQueries } from 'arc';
import { responsiveStack } from '../../../../core/styles/mixins';

type ColumnConfig = {
    label: string;
    value: React.ReactNode;
};

type TwoColumnSummaryProps = {
    leftColumnConfig: ColumnConfig[];
    rightColumnConfig: ColumnConfig[];
};

type ColumnContainerProps = {
    includeMargin?: boolean;
};

const TwoColumnSummaryContainer = styled.div`
    ${responsiveStack()};
    justify-content: space-between;
    margin-top: 10px;

    @media (min-width: ${mediaQueries.md}) {
        gap: var(--arc-space-3);
    }
`;

const ColumnContainer = styled.div<ColumnContainerProps>`
    display: flex;
    flex-flow: column;
    flex: 1;
    flex-basis: auto;

    @media (min-width: ${mediaQueries.md}) {
        width: 48%;
    }
`;

const Cell = styled(ArcText)`
    font-size: ${cssVar('arc.fontSizes.sm')};
    overflow-wrap: break-word;
    line-height: 1.5;
`;

const Label = styled.span`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const Value = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

const Column: React.FC<{
    columnConfig: ColumnConfig[];
    includeMargin?: boolean;
}> = ({ columnConfig, includeMargin }) => {
    const rows = map(columnConfig, (fieldConfig, idx) => {
        const { value, label } = fieldConfig;
        return (
            <Cell key={idx}>
                <Label>{label}: </Label>
                <Value>{value}</Value>
            </Cell>
        );
    });
    return <ColumnContainer includeMargin={includeMargin}>{rows}</ColumnContainer>;
};

export const TwoColumnSummary: React.FC<TwoColumnSummaryProps> = ({
    leftColumnConfig,
    rightColumnConfig,
}) => {
    // Filter the configs
    const filteredLeftColumnConfig = filter(leftColumnConfig, 'value');
    const filteredRightColumnConfig = filter(rightColumnConfig, 'value');

    const leftColumn = filteredLeftColumnConfig.length ? (
        <Column columnConfig={filteredLeftColumnConfig} />
    ) : null;

    const rightColumn = filteredRightColumnConfig.length ? (
        <Column includeMargin={!!leftColumn} columnConfig={filteredRightColumnConfig} />
    ) : null;

    return leftColumn || rightColumn ? (
        <TwoColumnSummaryContainer>
            {leftColumn}
            {rightColumn}
        </TwoColumnSummaryContainer>
    ) : null;
};

import { map, partition, orderBy, find } from 'lodash';

import getAssociatedRecordsResource from '~/client-common/core/domain/reports/resources/associatedRecordsResource';
import formsRegistry from '../../../../../core/formsRegistry';

export const FORM_NAME = 'ASSOCIATED_RECORDS';

function combineLinks(associatedRecords = [], prevAssociatedRecords = []) {
    return [...associatedRecords, ...prevAssociatedRecords];
}

export function addExternalAssociatedRecord(sourceId, reportId, options = {}) {
    const { url } = options;

    const form = formsRegistry.get(FORM_NAME);
    const currentRecords = form.get('associatedRecords');

    const newReportExternalLink = {
        sourceId,
        reportId,
        url,
        isExternal: true,
    };
    if (currentRecords) {
        form.set('associatedRecords', [newReportExternalLink, ...currentRecords]);
    } else {
        form.set('associatedRecords', [newReportExternalLink]);
    }
}

export function addInternalAssociatedRecord(elasticReport, reportId, departmentId) {
    const form = formsRegistry.get(FORM_NAME);
    const currentRecords = form.get('associatedRecords');
    const prevRecords = form.get('prevAssociatedRecords');
    // if the report already has a link we don't want to add another one
    if (
        find(
            combineLinks(currentRecords, prevRecords),
            ({ fromReportId, toReportId }) =>
                fromReportId === elasticReport.id || toReportId === elasticReport.id
        )
    ) {
        return;
    }
    const newReportInternalLink = {
        fromReportId: reportId,
        toReportId: elasticReport.id,
        departmentId,
        toDepartmentId: elasticReport.departmentId,
        isExternal: false,
    };
    if (currentRecords) {
        form.set('associatedRecords', [newReportInternalLink, ...currentRecords]);
    } else {
        form.set('associatedRecords', [newReportInternalLink]);
    }
}

function convertToFormModel(associatedRecords) {
    const externalLinks = map(associatedRecords.reportExternalLinks, (externalLink) => {
        return {
            isExternal: true,
            ...externalLink,
        };
    });
    const reportLinks = map(associatedRecords.reportReportLinks, (reportReportLink) => {
        return {
            isExternal: false,
            ...reportReportLink,
        };
    });
    const allLinks = [...externalLinks, ...reportLinks];
    return orderBy(allLinks, ['createdDateUtc'], ['desc']);
}

function convertFromFormModel(formModel) {
    const associatedRecords = formModel.associatedRecords;
    const prevAssociatedRecords = formModel.prevAssociatedRecords;
    const allLinks = combineLinks(associatedRecords, prevAssociatedRecords);
    const [reportExternalLinks, reportReportLinks] = partition(allLinks, {
        isExternal: true,
    });
    return {
        reportExternalLinks,
        reportReportLinks,
    };
}

export function fillPrevAssociatedRecords(reportReportLinks, reportExternalLinks) {
    formsRegistry.maybeDeferredOperation(FORM_NAME, 0, (form) => {
        const formModel = convertToFormModel({
            reportReportLinks,
            reportExternalLinks,
        });
        form.set('prevAssociatedRecords', formModel);
    });
}

export function submitForm(reportId) {
    const form = formsRegistry.get(FORM_NAME);
    return form.submit().then((result) => {
        const associatedRecords = convertFromFormModel(result.form.getState().model);
        return getAssociatedRecordsResource().setAssociatedRecordsForReport(
            reportId,
            associatedRecords
        );
    });
}

export function resetFormState() {
    const form = formsRegistry.get(FORM_NAME);
    form.resetModel();
}

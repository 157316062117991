import { RefContextEnum } from '@mark43/rms-api';
import { get, sortBy } from 'lodash';

// `title` is a required prop on admin list items -- a warning
// will be thrown if it is undefined.  Here, we default to blank
// title because if you reload the page while on a field configuration,
// while the page is loading, the component may try to render
// before we have field configurations or field details in state.
// Thus, default to a blank title to prevent the console warning,
// and things will clear themselves up when the data finally
// makes it into state & the component re-renders.
export function buildFieldConfigurationTitle(fieldDetail = {}, fieldConfiguration = {}) {
    return fieldConfiguration.displayName || fieldDetail.fieldName || '';
}

export function sortFieldConfigurationContextsByContextName(fieldConfigurationContexts = []) {
    return sortBy(fieldConfigurationContexts, (fieldConfigurationContext) =>
        get(RefContextEnum, `${fieldConfigurationContext.context}.displayName`)
    );
}

export const dateValueNowStringKey = 'NOW';

import { compact, concat, find, isArray, reduce, some, split, startsWith, uniq } from 'lodash';

import { EntityTypeEnum, LinkTypesEnum, LinkTypesEnumType, RefContextEnum } from '@mark43/rms-api';

import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { mountedOverlayBases } from '../../../core/overlayManager';
import { useOverlayStore } from '../overlays/hooks/useOverlayStore';
import { currentReportIdSelector } from '../../../legacy-redux/selectors/reportSelectors';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { useFormGetter } from '../forms/hooks/useFormGetter';
import { getFormContextForLinkType } from '../persons/utils/getFormContextForLinkType';
import { FieldDescriptorType } from './helpers/types';

const validValueTypes = {
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER',
    NULL: 'NULL',
};
const strictComparison = (a: boolean | number | null, b: boolean | number | null) => a === b;

/**
 * This RFG is for checking person profile fields against offense fields,
 * for example if you wanted to know if the current person profile form
 * was regarding a suspect in a domestic violence incident you would pass
 * in a linkType of "SUSPECT_IN_OFFENSE", a path of 'offense.isDomesticViolence',
 * a value of 'true', and a valueType of 'BOOLEAN'
 *
 * @param getState      () => RootState
 * @param linkType      the nameReportLink linkType
 * @param path          the property path on an offense you want to check
 *                      look at offense model, likely starts with 'offense.' 'links.' or 'offenseAttributes.'
 * @param value         a string representing the value of the path you want for this to return true
 * @param valueType     the type of value ('BOOLEAN', 'NUMBER', 'NULL')
 * @returns {boolean}
 */
type GetPersonProfileInOffenseWhereProps = {
    linkType: keyof typeof LinkTypesEnum;
    path: string;
    value: string;
    valueType: keyof typeof validValueTypes;
};

export const getPersonProfileInOffenseWhere = (getState: () => RootState) => (
    _fieldDescriptor: FieldDescriptorType,
    { linkType, path, value, valueType }: GetPersonProfileInOffenseWhereProps
) => {
    const { NUMBER, BOOLEAN, NULL } = validValueTypes;
    const overlayStore = useOverlayStore();
    const castValue =
        valueType === NUMBER
            ? Number(value)
            : valueType === BOOLEAN
            ? value.trim().toLowerCase() === 'true'
            : null;
    if (castValue === null && valueType !== NULL) {
        throw new Error(`Invalid value type specified for rule ${valueType}`);
    }
    const linkTypeId: LinkTypesEnumType = LinkTypesEnum[linkType];
    if (isUndefinedOrNull(linkTypeId)) {
        return false;
    }

    const state = getState();

    const currentReportId = currentReportIdSelector(state);
    if (isUndefinedOrNull(currentReportId)) {
        return false;
    }
    const personProfileFormContext = getFormContextForLinkType(linkTypeId);

    const { getForm } = useFormGetter();
    const personProfileForm = getForm(personProfileFormContext);
    if (isUndefinedOrNull(personProfileForm)) {
        return false;
    }
    const personProfileId = personProfileForm.get('id') as number;
    const linkTypeNameReportLinks = nameReportLinksWhereSelector(state)({
        nameId: personProfileId,
        linkType: linkTypeId,
        reportId: currentReportId,
        contextType: EntityTypeEnum.OFFENSE.name,
        entityType: EntityTypeEnum.PERSON_PROFILE.name,
    });

    // a nameReportLink does not exist until a person profile form is first saved
    // so we check the offense for the open overlay
    // overlayId is of the form 'overlayIdEnumType'.'contextId'.'linkType'.'position'
    const mountedOffensePersonOverlays = reduce(
        mountedOverlayBases,
        (acc: string[], value, key) => {
            if (startsWith(key, overlayIdEnum.PERSON_OVERLAY_OFFENSE_CARD)) {
                acc.push(key);
            }
            return acc;
        },
        []
    );
    const currentOverlayId = find(mountedOffensePersonOverlays, (mopo) => {
        return overlayStore.getStateForId(mopo)?.isOpen;
    });

    const currentOverlayContext = !!currentOverlayId
        ? parseInt(split(currentOverlayId, '.')[1])
        : null;

    const contexts = compact(
        uniq(
            concat(
                reduce(
                    linkTypeNameReportLinks,
                    (result: number[], nrl) => {
                        result.push(nrl.contextId);
                        return result;
                    },
                    []
                ),
                currentOverlayContext
            )
        )
    );

    const comparator = castValue === null ? isUndefinedOrNull : strictComparison;

    const result = some(contexts, (context) => {
        const form = getForm(RefContextEnum.FORM_OFFENSE.name, context);
        const targetField = form ? (form.get(path) as typeof castValue | typeof castValue[]) : null;
        if (targetField && isArray(targetField)) {
            return some(targetField, (tf) => comparator(tf, castValue));
        }
        return comparator(targetField, castValue);
    });

    return result;
};

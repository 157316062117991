// should be in ./itemProfiles but entityManager in data/itemProfiles
// creates dependency loop
import { createSelector } from 'reselect';
import {
    attributeIsOtherSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

const { proofOfOwnershipGlobal } = globalAttributes;

/**
 * Display logic for proofOfOwnershipOther field.
 * @param  {number} proofOfOwnershipAttrId
 * @return {boolean}
 */
export const showOtherForProofOfOwnershipAttrIdSelector = createSelector(
    attributeIsOtherSelector,
    parentAttributeIdByAttributeIdSelector,
    (attributeIsOther, parentAttributeIdByAttributeId) => (proofOfOwnershipAttrId) => {
        const isOther = attributeIsOther(proofOfOwnershipAttrId);
        const isDriversLicense =
            parentAttributeIdByAttributeId(proofOfOwnershipAttrId) ===
            proofOfOwnershipGlobal.driversLicense;
        return isDriversLicense || isOther;
    }
);

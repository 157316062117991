import { compact, get, map, uniqBy } from 'lodash';

/**
 * Combine the given ids and codes into only ids. This method is for backwards compatibility
 *   since we added `id` to NibrsOffenseCode.
 */
export default function combineNibrsOffenseCodeIds({ ids = [], codes = [], codeByCode = {} }) {
    return uniqBy([
        ...ids,
        // convert deprecated field of code strings
        ...compact(map(codes, (code) => get(codeByCode[code], 'id'))),
    ]);
}

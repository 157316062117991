import recursivelyConvertIdsToDisplayValueObjects from './recursivelyConvertIdsToDisplayValueObjects';

const OFFENSECODE_KEY_REGEX = /offenseCodeIds?$/;
const ID_KEY_REGEX = /Ids?$/;

/**
 * Recursively traverses an object, looking for keys that match `offenseCodeId(s)`,
 * transforms their values into display value objects with a shape
 * of `{ displayValues : [<string>] }` and stores the result on a new key,
 * replacing the `Id(s)` suffix with `Detail`. The original keys will be removed.
 *
 * For params see return value of `recursivelyConvertIdsToDisplayValueObjects`
 */
export default recursivelyConvertIdsToDisplayValueObjects({
    sourceKeyRegex: OFFENSECODE_KEY_REGEX,
    targetKeyRegex: ID_KEY_REGEX,
});

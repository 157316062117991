import { DispatchAreaStatusEnum } from '@mark43/cad-api';
import _, { get, parseInt } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { normalize } from '~/client-common/helpers/dataHelpers';
import { getAdminListStatusFromStartEnd } from '~/client-common/core/dates/utils/dateHelpers';
import { cadUnitsSelector } from '~/client-common/core/domain/cad-units/state/data';
import { dispatchAreasSelector } from '~/client-common/core/domain/dispatch-areas/state/data';
import { stationsSelector } from '~/client-common/core/domain/stations/state/data';
import { agencyProfileByIdSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import {
    processHistoryEvent,
    processChangeSet,
} from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { storeUserAttributes } from '~/client-common/core/domain/user-attributes/state/data';
import { storeUserProfiles } from '~/client-common/core/domain/user-profiles/state/data';
import { currentDepartmentDateFormatsSelector } from '~/client-common/core/domain/current-user/state/ui';
import cadUnitsAdminForm from '../forms/cadUnitsAdminForm';
import {
    LOAD_CAD_UNITS_START,
    LOAD_CAD_UNITS_SUCCESS,
    LOAD_CAD_UNITS_FAILURE,
    SAVE_CAD_UNIT_START,
    SAVE_CAD_UNIT_SUCCESS,
    SAVE_CAD_UNIT_FAILURE,
    FETCH_CAD_UNIT_HISTORY_SUCCESS,
    FETCH_ALL_CAD_UNITS_HISTORY_SUCCESS,
} from '../data';
import authActionTypes from '../../../../../legacy-redux/actions/types/authActionTypes';
import { formatUserByIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';
import routesConfig from '../../../../../routing/routesConfig';
import { routeNameSelector } from '../../../../../routing/routerModule';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import cadUnitResource from '../../resources/cadUnitResource';
import userProfileAdminResource from '../../../../../legacy-redux/resources/userProfileAdminResource';

const { BOOTSTRAP_SUCCESS } = authActionTypes;
const SELECT_CAD_UNIT_START = 'cad-units/SELECT_CAD_UNIT_START';
const SELECT_CAD_UNIT_SUCCESS = 'cad-units/SELECT_CAD_UNIT_SUCCESS';
const SELECT_CAD_UNIT_FAILURE = 'cad-units/SELECT_CAD_UNIT_FAILURE';
const OPEN_NEW_CAD_UNIT_FORM = 'cad-units/OPEN_NEW_CAD_UNIT_FORM';
const SELECT_CAD_AGENCY_TYPE = 'cad-units/SELECT_CAD_AGENCY_TYPE';

function selectCadUnitStart() {
    return {
        type: SELECT_CAD_UNIT_START,
    };
}

function selectCadUnitSuccess(id) {
    return {
        type: SELECT_CAD_UNIT_SUCCESS,
        payload: id,
    };
}

function selectCadUnitFailure() {
    return {
        type: SELECT_CAD_UNIT_FAILURE,
    };
}

export function selectCadAgencyType(id) {
    return {
        type: SELECT_CAD_AGENCY_TYPE,
        payload: id,
    };
}

export function updateAgency(agencyId) {
    return (dispatch, getState) => {
        const state = getState();
        const agency = agencyProfileByIdSelector(state)(agencyId);
        const agencyTypeGlobalAttrId = parentAttributeIdByAttributeIdSelector(state)(
            agency.agencyTypeAttrId
        );
        dispatch(
            cadUnitsAdminForm.actionCreators.changePath(
                'cadUnit.agencyTypeGlobalAttrId',
                agencyTypeGlobalAttrId
            )
        );
        if (agencyTypeGlobalAttrId === globalAttributes.agencyTypeGlobal.police) {
            dispatch(cadUnitsAdminForm.actionCreators.changePath('cadUnit.stationId', null));
        }
    };
}

export function selectCadUnit(id, refetch = false) {
    return (dispatch, getState) => {
        dispatch(selectCadUnitStart());
        const state = getState();
        const cadUnit = cadUnitsSelector(state)[id];
        const changeCadUnit = (cadUnit) => {
            dispatch(cadUnitsAdminForm.actionCreators.change({ cadUnit }));
            dispatch(selectCadUnitSuccess(id));
        };

        if (!cadUnit || refetch) {
            Promise.all([cadUnitResource.getCadUnit(id), cadUnitResource.getDefaultMembers(id)])
                .then(([cadUnit, defaultMembers]) => {
                    changeCadUnit({
                        ...cadUnit,
                        defaultMembers,
                        hydrated: true,
                    });
                })
                .catch((err) => {
                    return dispatch(selectCadUnitFailure(err.message));
                });
        } else if (_.get(cadUnit, 'hydrated')) {
            changeCadUnit(cadUnit);
        } else {
            cadUnitResource
                .getDefaultMembers(id)
                .then((defaultMembers = []) => {
                    changeCadUnit({
                        ...cadUnit,
                        defaultMembers,
                        hydrated: true,
                    });
                })
                .catch((err) => {
                    return dispatch(selectCadUnitFailure(err.message));
                });
        }
    };
}

export function getFullUserProfile(userId) {
    return (dispatch) => {
        Promise.all([
            userProfileAdminResource.getFullProfile(userId),
            cadUnitResource.getDefaultUnits(userId),
        ]).then(([{ profile, userAttributes }, defaultUnits]) => {
            dispatch(
                storeUserProfiles({
                    ...profile,
                    userAttributes,
                    defaultUnits,
                    hydrated: true,
                })
            );
            dispatch(storeUserAttributes(userAttributes));
        });
    };
}

export function openNewCadUnitForm() {
    return {
        type: OPEN_NEW_CAD_UNIT_FORM,
    };
}

const initialUiState = {
    selectedCadAgencyTypeId: globalAttributes.agencyTypeGlobal.police,
    selectedCadUnitId: null,
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    cadUnitLoadError: null,
    agencies: {},
    histories: {},
    allHistories: [], // this is a stop-gap solution. Normally we would normalize all of these by unit id or
    // but there is no consistent way to discern the unit id in history objects.
};

export default function cadUnitsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case BOOTSTRAP_SUCCESS:
            return {
                ...state,
                agencies: normalize(action.payload.userData.departmentAgencies),
            };
        case FETCH_CAD_UNIT_HISTORY_SUCCESS:
            return {
                ...state,
                histories: {
                    ...state.histories,
                    [action.payload.unitId]: action.payload.histories,
                },
            };
        case FETCH_ALL_CAD_UNITS_HISTORY_SUCCESS:
            return {
                ...state,
                allHistories: action.payload.histories,
            };
        case LOAD_CAD_UNITS_START:
            return {
                ...state,
                loadingList: true,
                selectedCadUnitId: null,
                listError: null,
            };
        case LOAD_CAD_UNITS_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_CAD_UNITS_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case SAVE_CAD_UNIT_START:
            return {
                ...state,
                submittingForm: true,
            };
        case SAVE_CAD_UNIT_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case SAVE_CAD_UNIT_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_CAD_UNIT_SUCCESS:
            return {
                ...state,
                cadUnitLoadError: null,
                selectedCadUnitId: parseInt(action.payload),
            };
        case SELECT_CAD_UNIT_FAILURE:
            return {
                ...state,
                cadUnitLoadError: action.payload,
            };
        case SELECT_CAD_AGENCY_TYPE:
            return {
                ...state,
                selectedCadAgencyTypeId: action.payload,
            };
        case OPEN_NEW_CAD_UNIT_FORM:
            return {
                ...state,
                formError: null,
                cadUnitLoadError: null,
                selectedCadUnitId: null,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.cadUnitsAdmin;
const departmentAgenciesSelector = createSelector(uiSelector, (ui) => ui.agencies);

export const cadUnitViewModelByIdSelector = createSelector(cadUnitsSelector, (cadUnits) => (id) =>
    cadUnits[id]
        ? {
              ...cadUnits[id],
              status: getAdminListStatusFromStartEnd(
                  cadUnits[id].activeDateUtc,
                  cadUnits[id].expirationDateUtc
              ),
          }
        : undefined
);

// list of cad units
export const unitsListItemsSelector = createSelector(
    cadUnitsSelector,
    formatAttributeByIdSelector,
    uiSelector,
    cadUnitViewModelByIdSelector,
    routeNameSelector,
    (cadUnits, formatAttributeById, ui, cadUnitViewModelById, routeName) => {
        return _(cadUnits)
            .map((unit) => {
                const historyView =
                    routeName === routesConfig.ADMIN_CAD_UNITS_HISTORY.name ||
                    routeName === routesConfig.ADMIN_CAD_ALL_UNITS_HISTORY.name;

                const path = `/admin/cad/units/${unit.id}${historyView ? '/history' : ''}`;

                return {
                    path,
                    title: unit.callSign,
                    subtitle: formatAttributeById(unit?.unitTypeAttrId),
                    key: unit.id,
                    status: get(cadUnitViewModelById(unit.id), 'status'),
                    selected: unit.id === ui.selectedCadUnitId,
                    agencyTypeAttrId: unit.agencyTypeGlobalAttrId,
                };
            })
            .sortBy('title')
            .value();
    }
);

export const historiesForCadUnitSelector = createSelector(
    uiSelector,
    formatAttributeByIdSelector,
    formatUserByIdSelector,
    getAttributeByIdSelector,
    currentDepartmentDateFormatsSelector,
    (ui, formatAttributeById, userById, getAttributeById, dateTimeFormats) => (id) => {
        const histories = id ? ui.histories[id] : ui.allHistories;

        const displayDate = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDate);
            }
            return '';
        };

        const displayDateTime = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDateTime);
            }
            return '';
        };

        const args = {
            formatAttributeById,
            userById,
            displayDate,
            displayDateTime,
            attributeById: getAttributeById,
        };

        return _.map(_.sortBy(histories, 'timestampUtc'), (history) => {
            const processed = processHistoryEvent(history, args);
            const newObj = {
                ...processed,
                user: userById(processed.changedBy),
                changes: _.map(processed.changeSet, (changeSet) =>
                    processChangeSet(changeSet, args, processed)
                ),
            };

            return newObj;
        }).reverse();
    }
);

export const departmentAgenciesOptionsSelector = createSelector(
    departmentAgenciesSelector,
    (departmentAgencies) => {
        return _.map(departmentAgencies, (departmentAgency) => {
            return {
                display: departmentAgency.agencyName,
                value: departmentAgency.id,
            };
        });
    }
);

export const hasImportExportUnitPermissions = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.CAD.UNIT_IMPORT)
);

export const dispatchAreaOptionsSelector = createSelector(
    dispatchAreasSelector,
    (dispatchAreas) => {
        return _(dispatchAreas)
            .filter(({ status }) => status === DispatchAreaStatusEnum.ACTIVE.name)
            .map(({ id, name }) => {
                return {
                    value: id,
                    display: name,
                };
            })
            .value();
    }
);

export const stationOptionsSelector = createSelector(stationsSelector, (stationViews) => {
    return _(stationViews)
        .filter(({ station }) => station.isActive)
        .map(({ station }) => {
            return {
                value: station.id,
                display: station.name,
                agencyTypeGlobalAttrId: station.agencyTypeGlobalAttrId,
            };
        })
        .value();
});

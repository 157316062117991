import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { chain, find, first, some } from 'lodash';
import { Text, TextProps, cssVar } from 'arc';
import {
    ElasticCase,
    ElasticOrganization,
    ElasticPerson,
    ElasticProperty,
    ElasticReport,
    ElasticSearchTypeEnum,
    ElasticVehicle,
    LinkTypesEnum,
} from '@mark43/rms-api';

import { FormattedDate } from '~/client-common/core/dates/components';
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatCaseDefinitionByIdSelector } from '~/client-common/core/domain/case-definitions/state/data/';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { formatTitleForElasticVehicleSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import * as fields from '~/client-common/core/enums/universal/fields';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { BodyMediumText } from '../../../core/components/typography';
import { useSubPremiseFormatters } from '../../../core/locations/hooks/useSubPremiseFormatters';
import { formatTitleForPropertyRecord } from '../../../../legacy-redux/helpers/propertyRecordHelpers';
import Icon, { iconTypes } from '../../../core/components/Icon';
import Link from '../../../core/components/links/Link';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import ApprovalStatusIcon, {
    CaseApprovalStatusIcon,
} from '../../../records/core/components/ApprovalStatusIcon';
import {
    getCaseTitle,
    getCaseAssignedTo,
    getCaseStatus,
} from '../../../records/core/helpers/caseStringFormatters';
import { SearchResultWithType } from '../../../search/quick-search/helpers/selectedResultHelpers';
import getLinkUrl from '../../../search/quick-search/helpers/getLinkUrl';

export const FlexWrapper = styled.div`
    display: flex;
    column-gap: 10px;
    margin: 20px 0;

    // override @arc/core font-size: var(--arc-fontSizes-lg)
    font-size: unset;
`;
export const FlexItem = styled.span`
    display: flex;
    flex-direction: column;
    flex: 1;

    // baseline font properties
    font-size: var(--arc-fontSizes-md);
    line-height: 17px;
`;
export const FlexBox = styled.span`
    display: flex;
`;
const HalfWidthFieldDetail = styled(Text)`
    // 10px matches column-gap in FieldDetailsWrapper
    flex-basis: calc(50% - 10px);
    display: inline;
    font-size: ${cssVar('arc.fontSizes.sm')};
    color: ${cssVar('arc.colors.text.tertiary')};
`;
const FieldLabel = styled(Text)`
    display: inline;
    font-size: ${cssVar('arc.fontSizes.sm')};
`;
const FieldDetailsWrapper = styled.span`
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    margin-top: ${cssVar('arc.space.1')};
`;
const RightAlignFlexItem = styled.span`
    margin-left: auto;
`;

const ItemTitle = (props: TextProps) => {
    return <Text as="span" variant="headingSm" {...props} />;
};

const ItemDetails = styled(Text)`
    display: inline;
    font-size: ${cssVar('arc.fontSizes.sm')};
    color: ${cssVar('arc.colors.text.tertiary')};
    font-style: italic;
`;

const ExternalDepartmentName: React.FC<{ departmentId: number }> = (props) => {
    const { departmentId } = props;

    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const departmentNameFromConsortiumLinksByDepartmentId = useSelector(
        departmentNameFromConsortiumLinksByDepartmentIdSelector
    );

    if (departmentId === currentUserDepartmentId) {
        return null;
    }

    const externalDepartmentName = departmentNameFromConsortiumLinksByDepartmentId(departmentId);

    return <BodyMediumText color="tertiary">{externalDepartmentName}</BodyMediumText>;
};

const FieldDetail: React.FC<{
    className?: string;
    fieldName?: keyof typeof fields;
    detail?: React.ReactNode;
}> = (props) => {
    const { className, fieldName, detail } = props;

    const formatFieldByName = useSelector(formatFieldByNameSelector);

    if (!detail) {
        return null;
    }

    return (
        <HalfWidthFieldDetail className={className}>
            {fieldName && <FieldLabel>{formatFieldByName(fieldName)}: </FieldLabel>}
            {detail}
        </HalfWidthFieldDetail>
    );
};

const LinkedEntitySummaryCase: React.FC<{
    item: ElasticCase;
    linkUrl: string;
}> = (props) => {
    const { item, linkUrl } = props;

    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();

    const formatCaseDefinitionById = useSelector(formatCaseDefinitionByIdSelector);
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const allRoleFormatsByRoleId = useSelector(allRoleFormatsByRoleIdSelector);

    const title =
        item.shortTitle ||
        getCaseTitle({
            localId: item.caseNumber || item.localId,
            caseDefinition: formatCaseDefinitionById(item.caseDefinitionId),
            caseFieldName,
        });

    const isExternal = currentUserDepartmentId !== item.departmentId;
    const getRoleName = (roleId?: number) => {
        if (roleId) {
            const allRoleFormats = allRoleFormatsByRoleId(roleId);
            return 'fullNameWithFirstInitialAndIdNumber' in allRoleFormats
                ? allRoleFormats.fullNameWithFirstInitialAndIdNumber
                : undefined;
        }
        return;
    };
    const caseAssignedTo = getCaseAssignedTo({
        assigneeRole: getRoleName(item.assigneeRoleId),
        assignedPersonnelUnit: formatAttributeById(item.assignedPersonnelUnitAttrId),
    });
    const caseStatus = getCaseStatus({
        caseStatus: formatAttributeById(item.currentStatusAttrId),
    });

    return (
        <FlexWrapper>
            <CaseApprovalStatusIcon approvalStatus={item.approvalStatus} />
            <FlexItem>
                <FlexBox>
                    <span>
                        <ItemTitle>
                            <Link openInNewTab={true} to={linkUrl}>
                                {title}{' '}
                            </Link>
                        </ItemTitle>
                        <ExternalDepartmentName departmentId={item.departmentId} />
                    </span>
                    <RightAlignFlexItem>
                        <ItemDetails>
                            <FormattedDate
                                date={item.createdDateUtc}
                                format={FormattedDate.FORMATS.SHORT_DATE}
                            />
                        </ItemDetails>
                    </RightAlignFlexItem>
                </FlexBox>
                <FieldDetailsWrapper>
                    {!isExternal && <FieldDetail detail={caseAssignedTo} />}
                    <FieldDetail detail={caseStatus} />
                </FieldDetailsWrapper>
            </FlexItem>
        </FlexWrapper>
    );
};

const LinkedEntitySummaryOrganization: React.FC<{
    item: ElasticOrganization;
    linkUrl: string;
}> = (props) => {
    const { item, linkUrl } = props;

    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const formatAttributeWithOther = useSelector(formatAttributeWithOtherSelector);
    const { buildElasticLocationLines } = useSubPremiseFormatters();

    const organizationType = formatAttributeById(item.organizationTypeAttrId);
    const industry = formatAttributeWithOther({
        attributeId: item.industryAttrId,
        other: item.industryOther,
    });
    const location = chain(item.involvedLocations)
        .filter(
            ({ type }) =>
                type === LinkTypesEnum.BUSINESS_ADDRESS || type === LinkTypesEnum.BUSINESS_LOCATION
        )
        .sortBy('type')
        .first()
        .value();

    return (
        <FlexWrapper>
            <Icon type={iconTypes.ORGANIZATION} color="mediumLightGrey" size={20} />
            <FlexItem>
                <span>
                    <ItemTitle>
                        <Link openInNewTab={true} to={linkUrl}>
                            {item.name}{' '}
                        </Link>
                    </ItemTitle>
                    <ExternalDepartmentName departmentId={item.departmentId} />
                </span>
                <FieldDetailsWrapper>
                    <FieldDetail detail={joinTruthyValues([organizationType, industry], ' / ')} />
                    <FieldDetail detail={first(item.phoneNumbers)} />
                </FieldDetailsWrapper>
                {location && (
                    <BodyMediumText color="tertiary">
                        {buildElasticLocationLines(location).join(', ')}
                    </BodyMediumText>
                )}
            </FlexItem>
        </FlexWrapper>
    );
};
const LinkedEntitySummaryPerson: React.FC<{
    item: ElasticPerson;
    linkUrl: string;
}> = (props) => {
    const { item, linkUrl } = props;

    return (
        <FlexWrapper>
            <Icon type={iconTypes.PERSON} color="mediumLightGrey" size={20} />
            <FlexItem>
                <span>
                    <ItemTitle>
                        <Link openInNewTab={true} to={linkUrl}>
                            {formatFullName(item)}{' '}
                        </Link>
                    </ItemTitle>
                    {!!item.nicknames.length && (
                        <ItemDetails>{`aka: ${item.nicknames.join(', ')} `}</ItemDetails>
                    )}
                    <ExternalDepartmentName departmentId={item.departmentId} />
                </span>
                <FieldDetailsWrapper>
                    <FieldDetail
                        fieldName={fields.PERSON_PROFILE_DATE_OF_BIRTH}
                        detail={
                            !!item.dateOfBirth && (
                                <FormattedDate
                                    date={item.dateOfBirth}
                                    format={FormattedDate.FORMATS.SHORT_DATE}
                                />
                            )
                        }
                    />
                    <FieldDetail fieldName={fields.PERSON_PROFILE_SSN} detail={item.ssn} />
                    <FieldDetail fieldName={fields.PERSON_PROFILE_FBI_UCN} detail={item.fbiUcn} />
                    <FieldDetail
                        fieldName={fields.PERSON_PROFILE_STATE_ID_NUMBER}
                        detail={item.stateIdNumber}
                    />
                    <FieldDetail
                        fieldName={fields.PERSON_PROFILE_DL_NUMBER}
                        detail={item.dlNumber}
                    />
                </FieldDetailsWrapper>
            </FlexItem>
        </FlexWrapper>
    );
};
const LinkedEntitySummaryProperty: React.FC<{
    item: ElasticProperty;
    linkUrl: string;
}> = (props) => {
    const { item, linkUrl } = props;

    const formatAttributeById = useSelector(formatAttributeByIdSelector);

    const isFirearm = item.itemTypeAttrId === globalAttributes.itemType.firearm;
    // we have to pass in a custom object because we are not using view
    // models and the helper was originally designed to be used with
    // view models
    const title = formatTitleForPropertyRecord(
        {
            itemCategoryAttrId: formatAttributeById(item.itemCategoryAttrId),
            description: item.description,
            firearmMakeAttrId: formatAttributeById(item.firearmMakeAttrId),
            atfManufacturer: item.property.atfManufacturerName,
        },
        true
    );

    return (
        <FlexWrapper>
            <Icon
                type={isFirearm ? iconTypes.FIREARM : iconTypes.PROPERTY}
                color="mediumLightGrey"
                size={20}
            />
            <FlexItem>
                <span>
                    <ItemTitle>
                        <Link openInNewTab={true} to={linkUrl}>
                            {title}{' '}
                        </Link>
                    </ItemTitle>
                    <ExternalDepartmentName departmentId={item.departmentId} />
                </span>
                <FieldDetailsWrapper>
                    <FieldDetail
                        fieldName={fields.ITEM_PROFILE_SERIAL_NUMBER}
                        detail={item.serialNumber}
                    />
                </FieldDetailsWrapper>
            </FlexItem>
        </FlexWrapper>
    );
};
const LinkedEntitySummaryReport: React.FC<{
    item: ElasticReport;
    linkUrl: string;
}> = (props) => {
    const { item, linkUrl } = props;

    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const isVacated = some(item.reportDefinition.charges, 'isVacated');
    const renAndTitle = joinTruthyValues([item.ren, item.shortTitle], ' ');
    const reportType = !item.shortTitle ? item.reportDefinition.reportDefinitionName : undefined;
    const location = find(item.involvedElasticLocations, {
        type: LinkTypesEnum.LOCATION_OF_EVENT,
    });
    const { buildElasticLocationLines } = useSubPremiseFormatters();

    return (
        <FlexWrapper>
            <ApprovalStatusIcon
                approvalStatus={item.clientApprovalStatus}
                isSealed={item.isSealed}
                isPartiallySealed={item.isPartiallySealed}
                isVacated={isVacated}
            />
            <FlexItem>
                <span>
                    <ItemTitle>
                        <Link openInNewTab={true} to={linkUrl}>
                            <>
                                {item.ren &&
                                    formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER)}
                                {renAndTitle}
                            </>
                            <div>{reportType} </div>
                        </Link>
                    </ItemTitle>
                    <ExternalDepartmentName departmentId={item.departmentId} />
                </span>
                {location && (
                    <ItemDetails>{buildElasticLocationLines(location).join(', ')}</ItemDetails>
                )}
            </FlexItem>
            <BodyMediumText color="tertiary">
                <FormattedDate
                    date={item.eventStartUtc}
                    format={FormattedDate.FORMATS.SHORT_DATE}
                />
            </BodyMediumText>
        </FlexWrapper>
    );
};
const LinkedEntitySummaryVehicle: React.FC<{
    item: ElasticVehicle;
    linkUrl: string;
}> = (props) => {
    const { item, linkUrl } = props;

    const formatTitleForElasticVehicle = useSelector(formatTitleForElasticVehicleSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);

    const title = formatTitleForElasticVehicle(item, {
        omitProps: 'itemCategory',
    });
    const hasDetails = item.primaryColorAttrId || item.bodyStyleAttrId;
    const details = joinTruthyValues(
        [
            formatAttributeById(item.primaryColorAttrId),
            item.bodyStyleOther || formatAttributeById(item.bodyStyleAttrId),
        ],
        ' '
    );
    const registrationState = item.registrationStateAttrId
        ? `(${formatAttributeById(item.registrationStateAttrId)})`
        : '';
    const tag = joinTruthyValues([item.tag, registrationState], ' ');

    return (
        <FlexWrapper>
            <Icon type={iconTypes.VEHICLE} color="mediumLightGrey" size={20} />
            <FlexItem>
                <FlexBox>
                    <span>
                        <Link openInNewTab={true} to={linkUrl}>
                            <ItemTitle>{title} </ItemTitle>
                        </Link>
                        {hasDetails && <ItemDetails>{details} </ItemDetails>}
                        <ExternalDepartmentName departmentId={item.departmentId} />
                    </span>
                </FlexBox>
                <FieldDetailsWrapper>
                    <FieldDetail fieldName={fields.VEHICLE_TAG} detail={tag} />
                    <FieldDetail fieldName={fields.VEHICLE_VIN_NUMBER} detail={item.vinNumber} />
                </FieldDetailsWrapper>
            </FlexItem>
        </FlexWrapper>
    );
};

const LinkedEntitySummary: React.FC<{ entity: SearchResultWithType }> = (props) => {
    const { entity } = props;
    const linkUrl = getLinkUrl({ itemType: entity.type, item: entity.item });

    switch (entity.type) {
        case ElasticSearchTypeEnum.CASE.name:
            return <LinkedEntitySummaryCase item={entity.item} linkUrl={linkUrl} />;
        case ElasticSearchTypeEnum.ORGANIZATION.name:
            return <LinkedEntitySummaryOrganization item={entity.item} linkUrl={linkUrl} />;
        case ElasticSearchTypeEnum.PERSON.name:
            return <LinkedEntitySummaryPerson item={entity.item} linkUrl={linkUrl} />;
        case ElasticSearchTypeEnum.REPORT.name:
            return <LinkedEntitySummaryReport item={entity.item} linkUrl={linkUrl} />;
        case ElasticSearchTypeEnum.PROPERTY.name:
            return <LinkedEntitySummaryProperty item={entity.item} linkUrl={linkUrl} />;
        case ElasticSearchTypeEnum.VEHICLE.name:
            return <LinkedEntitySummaryVehicle item={entity.item} linkUrl={linkUrl} />;
        // CAD_TICKET and WARRANT are currently not linkable
        case ElasticSearchTypeEnum.CAD_TICKET.name:
        case ElasticSearchTypeEnum.WARRANT.name:
        default:
            return null;
    }
};

/**
 * This component displays a single TaskEntityLink, optionally allowing the user to edit/remove it.
 */
export default LinkedEntitySummary;

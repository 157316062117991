import { useEffect } from 'react';
import { keyBy, isEqual } from 'lodash';
import { DragonFormsResource } from '@mark43/rms-api';
import type BluebirdPromise from 'bluebird';
import { formEvents, MFTFormConfiguration, _Form } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { getInlineFormReferenceFormState } from '../utils/get-inline-form-reference-form-state';
import { getCoreModelInstanceId } from '../utils/dragon-instance-ids';
import { getInlineFormValues } from '../utils/get-inline-form-values';
import { getConfiguredFormPath } from '../utils/get-configured-form-path';
import { isDragonInlineFormReferenceState } from '../utils/is-dragon-inline-form-reference-state';
import { DragonCurrentFormValue } from '../rms-types';
import { useMFTFormContext } from '../context/mft-form';

export function useSyncInlineFormValuesForLinks<T>({
    form,
    linkEntities,
    currentFormNestingPath,
    fullyQualifiedPath,
    uiConfigurationId,
    configuredFormId,
    getLinkId,
    setIsLoading,
}: {
    form: ReturnType<typeof useMFTFormContext>;
    linkEntities: T[];
    uiConfigurationId: string;
    configuredFormId: number;
    currentFormNestingPath: DragonCurrentFormValue[];
    fullyQualifiedPath: string;
    getLinkId: (link: T) => number;
    setIsLoading: (value: boolean) => void;
}): void {
    useEffect(() => {
        const formValueBeforeRequest = getInlineFormReferenceFormState(form, fullyQualifiedPath);
        const indexedLinkEntities = keyBy(linkEntities, getLinkId);
        const nextDragonFormState = formValueBeforeRequest.filter(
            (value) => !!indexedLinkEntities[getCoreModelInstanceId(value)]
        );
        const dragonFormStateByNameReportLinkId = keyBy(
            nextDragonFormState,
            getCoreModelInstanceId
        );
        const linkEntitiesToFetchDragonFormStateFor = linkEntities.filter(
            (link) => !dragonFormStateByNameReportLinkId[getLinkId(link)]
        );

        let isStale = false;
        let requestPromise: BluebirdPromise<
            NonNullable<DragonFormsResource.GetInlineFormReferenceInstances['returns']['data']>
        >;

        async function run(form: _Form<MFTFormConfiguration>): Promise<void> {
            try {
                let inlineFormValueViews: DragonFormsResource.GetInlineFormReferenceInstances['returns']['data'];

                if (linkEntitiesToFetchDragonFormStateFor.length) {
                    setIsLoading(true);
                    requestPromise = getInlineFormValues({
                        instanceIds: linkEntitiesToFetchDragonFormStateFor
                            .map(getLinkId)
                            .filter((x) => !isUndefinedOrNull(x)),
                        parentReferencingUiConfigurationId: uiConfigurationId,
                        parentFormPathInstance: getConfiguredFormPath(currentFormNestingPath),
                        configuredFormId,
                    });
                    inlineFormValueViews = await requestPromise;
                } else {
                    inlineFormValueViews = [];
                }

                if (isStale) {
                    return;
                }

                // We fetch the latest form state again to ensure that we are not accidentally overwriting
                // changes the user has since made.
                const formValueAfterRequest = getInlineFormReferenceFormState(
                    form,
                    fullyQualifiedPath
                );
                const previousInstanceIds = formValueAfterRequest.map(getCoreModelInstanceId);
                const nextDragonFormState = formValueAfterRequest.filter(
                    (value) => !!indexedLinkEntities[getCoreModelInstanceId(value)]
                );
                const nextDragonFormValueStateWithNewLinkState = [
                    ...nextDragonFormState,
                    ...inlineFormValueViews
                        .map((inlineFormValueView) => inlineFormValueView.values)
                        .filter(isDragonInlineFormReferenceState),
                ];
                const nextInstanceIds = nextDragonFormValueStateWithNewLinkState.map(
                    getCoreModelInstanceId
                );

                // Whenever a name report link is added or removed, we will need to adjust the dragon state.
                // If this isn't done we are running the risk of persisting incorrect data for links
                if (!isEqual(previousInstanceIds, nextInstanceIds)) {
                    form.set(fullyQualifiedPath, nextDragonFormValueStateWithNewLinkState);
                    // We have to manually trigger our validation event here because `form.set` will trigger an `INPUT_CHANGE`
                    // event instead of the `N_ITEM_ADDED`/`N_ITEM_REMOVED` events to which we have tied out specific nitems validation logic
                    // in `onValidate`. While this triggers two validation runs it shouldn't cause issues. Another solution, which we cannot
                    // implement right now, would be to check the validated field's form configuration to see if the item is an nitems or a name
                    // report link and run out special logic based on that. That solution does not work right now because MFT passes an incorrect
                    // path to `onValidate` when setting an array value. Instead of the path passed to `set` it seems to be passing a path of
                    // one of then values contained within the value to be set. Because of this the below solution is out best bet for now.
                    form.validate({
                        eventType:
                            nextInstanceIds.length < previousInstanceIds.length
                                ? formEvents.N_ITEM_REMOVED
                                : formEvents.N_ITEM_ADDED,
                        path: fullyQualifiedPath,
                    });
                }
            } catch (e) {
                // Intentional no-op
            } finally {
                setIsLoading(false);
            }
        }

        run(form);

        return () => {
            setIsLoading(false);
            isStale = true;
            if (requestPromise?.isCancellable()) {
                requestPromise.cancel();
            }
        };
    }, [
        linkEntities,
        currentFormNestingPath,
        fullyQualifiedPath,
        form,
        uiConfigurationId,
        configuredFormId,
        getLinkId,
        setIsLoading,
    ]);
}

import React from 'react';
import { map, size } from 'lodash';
import { useSelector } from 'react-redux';

import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { booleanToYesNo, joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import EntityProfileSectionNoData from '../../core/components/EntityProfileSectionNoData';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import EntityProfileSection, {
    EntityProfileDetailSummaryList,
    EntityProfileSubdetailSummaryList,
} from '../../core/components/EntityProfileSection';
import {
    convertPhoneNumbersToPersonComponentArray,
    convertEmailAddressesToPersonComponentArray,
} from '../../core/helpers/contactHelpers';

const strings = componentStrings.entityProfiles.person.ContactDetails;

function EmergencyContactDetails({ emergencyContact, isPoleEnabled = false }) {
    const {
        emergencyContactName,
        emergencyContactLinkType,
        emergencyContactAddress,
        emergencyContactPhoneNumber,
        isGuardian,
        dateEffectiveFrom,
        dateEffectiveTo,
    } = emergencyContact;

    return (
        <SummaryRow fieldName={fields.PERSON_EMERGENCY_CONTACT_N_ITEMS_WRAPPER}>
            <EntityProfileSubdetailSummaryList>
                <div>
                    {emergencyContactName}{' '}
                    <span className="entity-profile-italicized">
                        {emergencyContactLinkType ? `(${emergencyContactLinkType})` : ''}
                    </span>
                </div>
                <SummaryRow
                    fieldName={fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_PHONE_NUMBER}
                >
                    {emergencyContactPhoneNumber}
                </SummaryRow>
                <SummaryRow fieldName={fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_ADDRESS}>
                    {emergencyContactAddress}
                </SummaryRow>
                <SummaryRow fieldName={fields.PERSON_EMERGENCY_CONTACT_IS_GUARDIAN}>
                    {booleanToYesNo(isGuardian)}
                </SummaryRow>
                <SummaryRow
                    fieldName={fields.PERSON_EMERGENCY_CONTACT_DATE_EFFECTIVE_FROM}
                    hidden={!isPoleEnabled}
                >
                    {dateEffectiveFrom}
                </SummaryRow>
                <SummaryRow
                    fieldName={fields.PERSON_EMERGENCY_CONTACT_DATE_EFFECTIVE_TO}
                    hidden={!isPoleEnabled}
                >
                    {dateEffectiveTo}
                </SummaryRow>
            </EntityProfileSubdetailSummaryList>
        </SummaryRow>
    );
}

function ContactDetails({ personDetailsViewModel }) {
    const { nameAttributesDisplay, personEmergencyContacts } = personDetailsViewModel;
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isPoleEnabled = applicationSettings['RMS_POLE_DATA_CAPTURE_ENABLED'];

    const display = getViewModelProperties(personDetailsViewModel);

    const phoneRows = convertPhoneNumbersToPersonComponentArray(
        display.phoneNumbers,
        isPoleEnabled
    );
    const emailRows = convertEmailAddressesToPersonComponentArray(display.emails, isPoleEnabled);
    const ENTITY_PROFILES_SUMMARY_LIST_LABEL_WIDTH = 100;
    const ENTITY_PROFILES_SUMMARY_LIST_CONTENT_WIDTH = '100%';

    return (
        <EntityProfileSection title={strings.title}>
            {!!phoneRows.length && (
                <EntityProfileDetailSummaryList
                    labelWidth={ENTITY_PROFILES_SUMMARY_LIST_LABEL_WIDTH}
                    contentWidth={ENTITY_PROFILES_SUMMARY_LIST_CONTENT_WIDTH}
                >
                    {phoneRows}
                </EntityProfileDetailSummaryList>
            )}
            {!!emailRows.length && (
                <EntityProfileDetailSummaryList
                    labelWidth={ENTITY_PROFILES_SUMMARY_LIST_LABEL_WIDTH}
                    contentWidth={ENTITY_PROFILES_SUMMARY_LIST_CONTENT_WIDTH}
                >
                    {emailRows}
                </EntityProfileDetailSummaryList>
            )}
            {(size(personEmergencyContacts) || nameAttributesDisplay.LANGUAGE) && (
                <EntityProfileDetailSummaryList
                    labelWidth={ENTITY_PROFILES_SUMMARY_LIST_LABEL_WIDTH}
                    contentWidth={ENTITY_PROFILES_SUMMARY_LIST_CONTENT_WIDTH}
                >
                    {map(personEmergencyContacts, (contact) => (
                        <EmergencyContactDetails
                            key={contact.id}
                            emergencyContact={contact}
                            isPoleEnabled={isPoleEnabled}
                        />
                    ))}
                    <SummaryRow label={strings.labels.needsInterpreter}>
                        {display.needsInterpreter}
                    </SummaryRow>
                    <SummaryRow label={strings.labels.languagesSpoken}>
                        {joinTruthyValues(nameAttributesDisplay.LANGUAGE)}
                    </SummaryRow>
                </EntityProfileDetailSummaryList>
            )}
        </EntityProfileSection>
    );
}

const RenderNoInformation = () => (
    <EntityProfileSection title={strings.title}>
        <EntityProfileSectionNoData />
    </EntityProfileSection>
);

export default renderOnlyIf(({ personDetailsViewModel }) => {
    const { nameAttributesDisplay } = personDetailsViewModel;

    const display = getViewModelProperties(personDetailsViewModel);

    return (
        (display.phoneNumbers && display.phoneNumbers.length) ||
        (display.emails && display.emails.length) ||
        (display.personEmergencyContacts && display.personEmergencyContacts.length) ||
        nameAttributesDisplay.LANGUAGE
    );
}, RenderNoInformation)(ContactDetails);

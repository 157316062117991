import React from 'react';
import styled from 'styled-components';
import pluralize from 'pluralize';

import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_JUVENILE_LABEL } from '~/client-common/core/enums/universal/fields';

import { BodyMediumText } from '../../../core/components/typography';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import testIds from '../../../../core/testIds';

const strings = componentStrings.search.AdvancedSearchFormFooter;

const CancelButton = styled(Button)`
    color: ${(props) => props.theme.colors.mediumGrey};
    margin-top: 6px;
`;

const LabelNote = styled.span`
    color: ${(props) => props.theme.colors.lightGrey};
`;

const Checkbox = styled(RRFCheckbox)`
    margin: 4px 0 0 10px;
`;

// repeat to override in dark mode. fieldset has `.dark fieldset` selector specificity
const JuvenilePersonsFilters = styled(RRFFieldset)`
    && {
        display: inline;
        width: unset;
    }
`;

/**
 * Footer for an advanced search form. Contains search button, clear button, and
 *   optional fuzzy matching checkbox.
 * @param {Object}   props
 * @param {string}   props.searchText
 * @param {boolean}  [props.searchDisabled=false]
 * @param {function} props.onSubmit
 * @param {function} props.onReset
 * @param {boolean}  props.includeFuzzyMatchingCheckbox
 * @param {boolean}  [props.fuzzyMatchingEnabled]
 * @param {boolean}  [props.includePersonsFilterCheckboxes] additional checkboxes for persons search
 */
export default function AdvancedSearchFormFooter({
    searchText,
    searchDisabled = false,
    onSubmit,
    onReset,
    includeFuzzyMatchingCheckbox,
    fuzzyMatchingEnabled,
    includePersonsFilterCheckboxes = false,
}) {
    const juvenileDisplayName = pluralize(
        useFields(DISPLAY_ONLY_JUVENILE_LABEL)[DISPLAY_ONLY_JUVENILE_LABEL]
    );
    return (
        <div>
            <Button
                className={buttonTypes.PRIMARY}
                onClick={onSubmit}
                disabled={searchDisabled}
                testId={testIds.SUBMIT_BUTTON}
            >
                {searchText}
            </Button>
            {includeFuzzyMatchingCheckbox && (
                <Checkbox
                    path="fuzzyMatchingEnabled"
                    label={
                        <BodyMediumText isItalic>
                            {strings.labels.fuzzyMatchingEnabled}
                            {fuzzyMatchingEnabled && (
                                <LabelNote> {strings.labels.fuzzyMatchingEnabledNote}</LabelNote>
                            )}
                        </BodyMediumText>
                    }
                    helpText={strings.helpText.fuzzyMatchingEnabled}
                />
            )}
            {includePersonsFilterCheckboxes && (
                <JuvenilePersonsFilters path="juvenilePersonsFilters">
                    <Checkbox
                        path="showAdults"
                        label={strings.labels.showAdults}
                        helpText={strings.helpText.showAdults}
                        testId={testIds.ADVANCED_SEARCH_PERSONS_SHOW_ADULTS_CHECKBOX}
                    />
                    <Checkbox
                        path="showJuveniles"
                        label={strings.labels.showJuveniles(juvenileDisplayName)}
                        helpText={strings.helpText.showJuveniles(juvenileDisplayName.toLowerCase())}
                        testId={testIds.ADVANCED_SEARCH_PERSONS_SHOW_JUVENILES_CHECKBOX}
                    />
                </JuvenilePersonsFilters>
            )}
            <CancelButton
                className={buttonTypes.ICON_LINK}
                iconLeft={<Icon color="mediumGrey" size={12} type={iconTypes.CLOSE_X} />}
                onClick={onReset}
                float="right"
                testId={testIds.ADVANCED_SEARCH_CLEAR_BUTTON}
            >
                {strings.clear}
            </CancelButton>
        </div>
    );
}

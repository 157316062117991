import {
    sortBy,
    compact,
    includes,
    find,
    uniq,
    filter,
    flatMap,
    map,
    values,
    flatten,
} from 'lodash';

import { createSelector } from 'reselect';
import { MentionCategoryEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { itemProfileIsVehicle } from '~/client-common/core/domain/item-profiles/utils/itemProfileHelpers';
import { latestCadTicket } from '~/client-common/core/domain/cad-tickets/utils/cadTicketsHelpers';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { assistingOfficersForReportIdSelector } from '~/client-common/core/domain/assisting-officers/state/data';
import { primaryResponderFormatter } from '~/client-common/core/domain/cad-tickets/state/ui';
import { offensesByReportIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';
import { personProfilesSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { organizationProfilesSelector } from '~/client-common/core/domain/organization-profiles/state/data';
import { nameItemLinksWhereSelector } from '~/client-common/core/domain/name-item-links/state/data';
import { formatNameItemAssociationAttrByNameItemLinkIdSelector } from '~/client-common/core/domain/name-item-links/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import {
    formatNameReportLinkTypeId,
    formatLocationLinkTypeId,
} from '~/client-common/helpers/linkTypesHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';

import { formatAddressForLocationBundle } from '~/client-common/core/domain/locations/utils/locationHelpers';
import { narrativeGuidesSelector } from '~/client-common/core/domain/narrative-guides/state/data';
import { cadTicketsWhereSelector } from '~/client-common/core/domain/cad-tickets/state/data';
import { propertyTitleForItemProfileSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { locationEntityLinksByLocationIdForReportIdSelector } from '~/client-common/core/domain/location-entity-links/state/ui';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { currentUserIdSelector } from '../../../current-user/state/ui';
import { mentionsConfigurationsSelector } from '../../../../admin/mentions-configuration/state/data';

const strings = componentStrings.reports.core.NarrativeVariables;

const buildTitleForOffenseSelector = createSelector(
    offenseCodesSelector,
    formatFieldByNameSelector,
    (offenseCodes, formatFieldByName) => (offense) => {
        const { isIncidentType } = offenseCodes[offense.offenseCodeId] || {};
        const { offenseOrder } = offense;
        return `${isIncidentType ? 'Incident' : formatFieldByName(DISPLAY_ONLY_OFFENSE)}${
            offenseOrder ? ` #${offenseOrder}` : ''
        }`;
    }
);

const locationMentionDataForReportIdSelector = createSelector(
    locationEntityLinksByLocationIdForReportIdSelector,
    offensesByReportIdSelector,
    locationsSelector,
    buildTitleForOffenseSelector,
    applicationSettingsSelector,
    (
        locationEntityLinksByLocationIdForReportId,
        offensesByReportId,
        locations,
        buildTitleForOffense,
        applicationSettings
    ) => (reportId) => {
        const offenses = offensesByReportId(reportId);

        // Ignore `no fixed` locations
        const locationEntityLinksByLocationId = locationEntityLinksByLocationIdForReportId(
            reportId
        );

        return map(flatten(values(locationEntityLinksByLocationId)), (locationEntityLink) => ({
            category: MentionCategoryEnum.LOCATION.name,
            additionalSearchTerms: [],
            display: {
                content: formatAddressForLocationBundle({
                    locationBundle: {
                        location: locations[locationEntityLink.locationId],
                        locationEntityLink,
                    },
                    isSubPremiseSupportEnabled: applicationSettings.SUBPREMISE_SUPPORT_ENABLED,
                }),
                header:
                    locationEntityLink.linkType === LinkTypesEnum.OFFENSE_LOCATION
                        ? // TODO: Not ideal that we have branching logic
                          // here, but this was easiest to achieve
                          `${buildTitleForOffense(
                              find(offenses, { id: locationEntityLink.entityId }) || {}
                          )} Location`
                        : formatLocationLinkTypeId(locationEntityLink.linkType),
            },
        }));
    }
);

export const mentionNarrativeDataSelector = createSelector(
    // This should get us the event start/end date
    currentReportSelector,
    // This should get us offense start/end dates
    offensesByReportIdSelector,

    // All the properties
    itemProfilesInReportSelector,
    // name report links
    nameReportLinksWhereSelector,
    personProfilesSelector,
    organizationProfilesSelector,
    arrestForReportIdSelector,
    eventDetailByReportIdSelector,
    formatFieldByNameSelector,
    narrativeGuidesSelector,
    nameItemLinksWhereSelector,
    formatNameItemAssociationAttrByNameItemLinkIdSelector,
    cadTicketsWhereSelector,
    formatMiniUserByIdSelector,
    assistingOfficersForReportIdSelector,
    propertyTitleForItemProfileSelector,
    buildTitleForOffenseSelector,
    locationMentionDataForReportIdSelector,
    currentUserIdSelector,
    mentionsConfigurationsSelector,
    currentDepartmentDateFormatterSelector,
    (
        currentReport,
        offensesByReportId,
        itemProfilesInReport,
        nameReportLinksWhere,
        personProfiles,
        organizationProfiles,
        arrestForReportId,
        eventDetailByReportId,
        formatFieldByName,
        narrativeGuides,
        nameItemLinksWhere,
        formatNameItemAssociationAttrByNameItemLinkId,
        cadTicketsWhere,
        formatMiniUserById,
        assistingOfficersForReportId,
        propertyTitleForItemProfile,
        buildTitleForOffense,
        locationMentionDataForReportId,
        currentUserId,
        mentionsConfigurations,
        dateTimeFormatter
    ) => (reportId) => {
        if (!reportId) {
            return [];
        }

        const { unitsAndMembers, firstUnitDispatchDateUtc, firstUnitArrivalDateUtc, callDateUtc } =
            latestCadTicket(
                cadTicketsWhere({
                    reportingEventNumber: currentReport.reportingEventNumber,
                })
            ) || {};

        const assistingOfficers = assistingOfficersForReportId(reportId);

        const offenses = offensesByReportId(reportId);
        const eventDetail = eventDetailByReportId(reportId);
        const itemProfiles = itemProfilesInReport(reportId);
        const itemProfileIds = map(itemProfiles, 'id');

        // TODO: Refactor from client-common/core/domain/reports/state/ui/names.js
        const nameItemLinks = nameItemLinksWhere(({ itemProfileId }) =>
            includes(itemProfileIds, itemProfileId)
        );

        const arrest = arrestForReportId(reportId) || {};
        const { defendantId } = arrest;
        const defendant = personProfiles[defendantId];

        const nameReportLinks = nameReportLinksWhere({ reportId });

        const locationMentionData = locationMentionDataForReportId(reportId);
        const mentionData = filter(
            [
                // Always show today's date as an option!
                {
                    category: MentionCategoryEnum.DATE.name,
                    display: {
                        content: dateTimeFormatter.formatDate(new Date()),
                        header: strings.today,
                    },
                },
                ...(firstUnitDispatchDateUtc
                    ? [
                          {
                              category: MentionCategoryEnum.DATE.name,
                              additionalSearchTerms: [],
                              display: {
                                  content: dateTimeFormatter.formatDateTime(
                                      firstUnitDispatchDateUtc
                                  ),
                                  header: strings.firstUnitDispatchDateHeader,
                              },
                          },
                      ]
                    : []),
                ...(firstUnitArrivalDateUtc
                    ? [
                          {
                              category: MentionCategoryEnum.DATE.name,
                              additionalSearchTerms: [],
                              display: {
                                  content: dateTimeFormatter.formatDateTime(
                                      firstUnitArrivalDateUtc
                                  ),
                                  header: strings.firstUnitArrivalDateHeader,
                              },
                          },
                      ]
                    : []),
                ...(callDateUtc
                    ? [
                          {
                              category: MentionCategoryEnum.DATE.name,
                              additionalSearchTerms: [],
                              display: {
                                  content: dateTimeFormatter.formatDateTime(callDateUtc),
                                  header: strings.callDateHeader,
                              },
                          },
                      ]
                    : []),
                ...(currentUserId
                    ? [
                          {
                              category: MentionCategoryEnum.OFFICER.name,
                              additionalSearchTerms: [],
                              display: {
                                  content: formatMiniUserById(currentUserId),
                                  header: strings.officer,
                              },
                          },
                      ]
                    : []),
                ...map(unitsAndMembers, (unitAndMember) => ({
                    category: MentionCategoryEnum.OFFICER.name,
                    additionalSearchTerms: [],
                    display: {
                        content: primaryResponderFormatter(unitAndMember, formatMiniUserById),
                        header: strings.officer,
                    },
                })),
                ...map(assistingOfficers, (assistingOfficer) => ({
                    category: MentionCategoryEnum.OFFICER.name,
                    additionalSearchTerms: [],
                    display: {
                        content: formatMiniUserById(assistingOfficer.officerId),
                        header: strings.assistingOfficer,
                    },
                })),
                ...(eventDetail
                    ? [
                          {
                              category: MentionCategoryEnum.DATE.name,
                              additionalSearchTerms: [],
                              display: {
                                  content: dateTimeFormatter.formatDateTime(
                                      eventDetail.eventStartUtc
                                  ),
                                  header: strings.eventStartDate,
                              },
                          },
                          {
                              category: MentionCategoryEnum.DATE.name,
                              additionalSearchTerms: [],
                              display: {
                                  content: dateTimeFormatter.formatDateTime(
                                      eventDetail.eventEndUtc
                                  ),
                                  header: strings.eventEndDate,
                              },
                          },
                      ]
                    : []),
                ...flatMap(offenses, (offense) => [
                    {
                        category: MentionCategoryEnum.DATE.name,
                        additionalSearchTerms: [],
                        display: {
                            content: dateTimeFormatter.formatDateTime(offense.offenseDateUtc),
                            header: strings.offenseStartDate(buildTitleForOffense(offense)),
                        },
                    },
                    {
                        category: MentionCategoryEnum.DATE.name,
                        additionalSearchTerms: [],
                        display: {
                            content: dateTimeFormatter.formatDateTime(offense.offenseEndDateUtc),
                            header: strings.offenseEndDate(buildTitleForOffense(offense)),
                        },
                    },
                ]),
                ...(defendant
                    ? [
                          {
                              category: MentionCategoryEnum.PERSON.name,
                              additionalSearchTerms: compact([defendant.middleName]),
                              display: {
                                  content: formatFullName(defendant),
                                  header: formatNameReportLinkTypeId(
                                      LinkTypesEnum.DEFENDANT_IN_ARREST,
                                      formatFieldByName
                                  ),
                              },
                          },
                      ]
                    : []),
                ...map(nameReportLinks, (nameReportLink) => {
                    const { nameId, linkType, entityType, contextType, contextId } = nameReportLink;
                    const relatedOffense =
                        contextType === EntityTypeEnum.OFFENSE.name
                            ? find(offenses, { id: contextId })
                            : undefined;

                    const header = `${
                        relatedOffense ? `${buildTitleForOffense(relatedOffense)} ` : ''
                    }${formatNameReportLinkTypeId(linkType, formatFieldByName)}`;

                    if (entityType === EntityTypeEnum.PERSON_PROFILE.name) {
                        const personProfile = personProfiles[nameId] || {};
                        return {
                            category: MentionCategoryEnum.PERSON.name,
                            additionalSearchTerms: compact([personProfile.middleName]),
                            display: {
                                content: formatFullName(personProfile),
                                header,
                            },
                        };
                    } else {
                        const organizationProfile = organizationProfiles[nameId] || {};
                        return {
                            category: MentionCategoryEnum.ORGANIZATION.name,
                            additionalSearchTerms: [],
                            display: {
                                content: organizationProfile.name,
                                header,
                            },
                        };
                    }
                }),
                ...map(nameItemLinks, (nameItemLink) => {
                    const { nameId, entityType, id } = nameItemLink;
                    const header = formatNameItemAssociationAttrByNameItemLinkId(id);
                    if (entityType === EntityTypeEnum.PERSON_PROFILE.name) {
                        const personProfile = personProfiles[nameId] || {};
                        return {
                            category: MentionCategoryEnum.PERSON.name,
                            additionalSearchTerms: compact([personProfile.middleName]),
                            display: {
                                content: formatFullName(personProfile),
                                header,
                            },
                        };
                    } else {
                        const organizationProfile = organizationProfiles[nameId] || {};
                        return {
                            category: MentionCategoryEnum.ORGANIZATION.name,
                            additionalSearchTerms: [],
                            display: {
                                content: organizationProfile.name,
                                header,
                            },
                        };
                    }
                }),
                ...locationMentionData,
                ...map(itemProfiles, (itemProfile) => {
                    const isVehicle = itemProfileIsVehicle(itemProfile);

                    return {
                        category: isVehicle
                            ? MentionCategoryEnum.VEHICLE.name
                            : MentionCategoryEnum.PROPERTY.name,
                        additionalSearchTerms: [],
                        display: {
                            content: propertyTitleForItemProfile(itemProfile),
                            header: isVehicle ? strings.vehicle : strings.property,
                        },
                    };
                }),
                ...map(narrativeGuides, (narrativeGuide) => ({
                    category: MentionCategoryEnum.GUIDE.name,
                    display: {
                        content: narrativeGuide.name,
                        contentToInsert: narrativeGuide.content,
                        header: strings.guide,
                    },
                })),
            ],
            // Only keep them if they have relevant content and the mention category is enabled
            (mentionData) =>
                !!(
                    mentionData.display.content &&
                    ((mentionsConfigurations[mentionData.category] &&
                        mentionsConfigurations[mentionData.category].isEnabled) ||
                        mentionData.category === MentionCategoryEnum.GUIDE.name)
                )
        );

        // Get all the unique categories present
        const categories = sortBy(uniq(map(mentionData, 'category')));

        return [
            ...mentionData,
            ...map(categories, (category) => ({
                category: MentionCategoryEnum.CATEGORY.name,
                display: {
                    content: `@${category}`,
                    header: '',
                },
            })),
        ];
    }
);

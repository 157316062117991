import { TrafficCrash } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { first } from 'lodash';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'trafficCrashes';
const trafficCrashModule = createNormalizedModule<TrafficCrash>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS
export const trafficCrashesSelector = trafficCrashModule.selectors.entitiesSelector;
const trafficCrashesWhereSelector = trafficCrashModule.selectors.entitiesWhereSelector;

export const trafficCrashByReportIdSelector = createSelector(
    trafficCrashesWhereSelector,
    (trafficCrashesWhere) => (reportId: number) => first(trafficCrashesWhere({ reportId }))
);

// REDUCER
export default trafficCrashModule.reducerConfig;

import { AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import { get, omit, filter, map } from 'lodash';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { saveUseOfForceSubject } from '~/client-common/core/domain/reports/state/ui';
import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import { convertNameAttributesFormDataToDataState } from '../../../../../legacy-redux/helpers/nameAttributesHelpers';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { refreshUseOfForceSubjectForm } from '../forms/useOfForceSubjectForm';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const nameAttributeModelFields = [
    'subjectActionsAttrIds',
    'subjectActionsDescription',
    'subjectDetailsAttrIds',
    'impairmentTypeAttrIds',
    'subjectDetailsDescription',
    'officerForceTowardsSubjectAttrIds',
    'officerForceTowardsSubjectDescription',
    'officerForceTowardsSubjectLocationAttrIds',
    'officerForceTowardsSubjectLocationDescription',
    'subjectInjurySeverityAttrId',
    'subjectInjurySeverityDescription',
    'subjectInjuryTypeAttrIds',
    'subjectInjuryTypeDescription',
    'medicalAidReceivedAttrId',
    'medicalAidReceivedDescription',
    'subjectForceTowardsOfficerAttrIds',
    'subjectForceTowardsOfficerDescription',
    'subjectForceTowardsOfficerLocationAttrIds',
    'subjectForceTowardsOfficerLocationDescription',
    'officerInjurySeverityAttrId',
    'officerInjurySeverityDescription',
    'officerInjuryTypeAttrIds',
    'officerInjuryTypeDescription',
    'subject',
];

const baseCard = createCard({
    name: reportCardEnum.USE_OF_FORCE_SUBJECT.name,
    baseSelector: (state) => state.ui.report.useOfForceSubjectCard,
    anchor: 'use-of-force-subject-card',
});

const fillRequiredDeEscalationProps = (useOfForceSubject, useOfForceSubjectDeEscalations) => {
    const { reportId, id: subjectId } = useOfForceSubject;

    return map(useOfForceSubjectDeEscalations, (obj) => {
        return {
            ...obj,
            reportId,
            subjectId,
        };
    });
};

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit(options = {}) {
            const { index } = options;
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        editAll() {
            return (dispatch) => dispatch(baseCard.actionCreators.transitionAllCardsToEditMode());
        },
        save(form, options) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const cardIndex = { index: options.index };
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state, cardIndex);
                const report = currentReportSelector(state);
                const reportId = report.id;
                const departmentId = currentUserDepartmentIdSelector(state);
                let useOfForceSubject;
                let subjectPersonProfileId;
                let useOfForceSubjectDeEscalations;
                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                            options: cardIndex,
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            options,
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const { model } = form.getState();
                                subjectPersonProfileId = get(model, 'subject');

                                const attributeIsOther = (attributeId) =>
                                    attributeIsOtherSelector(state)(attributeId);

                                const nameAttributes = !isUndefinedOrNull(subjectPersonProfileId)
                                    ? filter(
                                          [
                                              ...convertNameAttributesFormDataToDataState(
                                                  model.subjectActionsAttrIds,
                                                  subjectPersonProfileId,
                                                  EntityTypeEnum.PERSON_PROFILE.name,
                                                  AttributeTypeEnum.USE_OF_FORCE_SUBJECT_ACTIONS
                                                      .name,
                                                  model.subjectActionsDescription,
                                                  attributeIsOther,
                                                  departmentId
                                              ),
                                              ...convertNameAttributesFormDataToDataState(
                                                  model.subjectDetailsAttrIds,
                                                  subjectPersonProfileId,
                                                  EntityTypeEnum.PERSON_PROFILE.name,
                                                  AttributeTypeEnum.USE_OF_FORCE_SUBJECT_DETAILS
                                                      .name,
                                                  model.subjectDetailsDescription,
                                                  attributeIsOther,
                                                  departmentId
                                              ),
                                              ...convertNameAttributesFormDataToDataState(
                                                  model.impairmentTypeAttrIds,
                                                  subjectPersonProfileId,
                                                  EntityTypeEnum.PERSON_PROFILE.name,
                                                  AttributeTypeEnum.USE_OF_FORCE_SUBJECT_IMPAIRMENTS
                                                      .name,
                                                  '',
                                                  attributeIsOther,
                                                  departmentId
                                              ),
                                              ...convertNameAttributesFormDataToDataState(
                                                  model.officerForceTowardsSubjectAttrIds,
                                                  subjectPersonProfileId,
                                                  EntityTypeEnum.PERSON_PROFILE.name,
                                                  AttributeTypeEnum
                                                      .USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT
                                                      .name,
                                                  model.officerForceTowardsSubjectDescription,
                                                  attributeIsOther,
                                                  departmentId
                                              ),
                                              ...convertNameAttributesFormDataToDataState(
                                                  model.officerForceTowardsSubjectLocationAttrIds,
                                                  subjectPersonProfileId,
                                                  EntityTypeEnum.PERSON_PROFILE.name,
                                                  AttributeTypeEnum
                                                      .USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_LOCATION
                                                      .name,
                                                  model.officerForceTowardsSubjectLocationDescription,
                                                  attributeIsOther,
                                                  departmentId
                                              ),
                                              ...(model.subjectInjurySeverityAttrId
                                                  ? convertNameAttributesFormDataToDataState(
                                                        [model.subjectInjurySeverityAttrId],
                                                        subjectPersonProfileId,
                                                        EntityTypeEnum.PERSON_PROFILE.name,
                                                        AttributeTypeEnum
                                                            .USE_OF_FORCE_SUBJECT_INJURY_SEVERITY
                                                            .name,
                                                        model.subjectInjurySeverityDescription,
                                                        attributeIsOther,
                                                        departmentId
                                                    )
                                                  : []),
                                              ...convertNameAttributesFormDataToDataState(
                                                  model.subjectInjuryTypeAttrIds,
                                                  subjectPersonProfileId,
                                                  EntityTypeEnum.PERSON_PROFILE.name,
                                                  AttributeTypeEnum.USE_OF_FORCE_SUBJECT_INJURY_TYPE
                                                      .name,
                                                  model.subjectInjuryTypeDescription,
                                                  attributeIsOther,
                                                  departmentId
                                              ),
                                              ...(model.medicalAidReceivedAttrId
                                                  ? convertNameAttributesFormDataToDataState(
                                                        [model.medicalAidReceivedAttrId],
                                                        subjectPersonProfileId,
                                                        EntityTypeEnum.PERSON_PROFILE.name,
                                                        AttributeTypeEnum
                                                            .USE_OF_FORCE_MEDICAL_AID_RECEIVED.name,
                                                        model.medicalAidReceivedDescription,
                                                        attributeIsOther,
                                                        departmentId
                                                    )
                                                  : []),
                                              ...convertNameAttributesFormDataToDataState(
                                                  model.subjectForceTowardsOfficerAttrIds,
                                                  subjectPersonProfileId,
                                                  EntityTypeEnum.PERSON_PROFILE.name,
                                                  AttributeTypeEnum
                                                      .USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER
                                                      .name,
                                                  model.subjectForceTowardsOfficerDescription,
                                                  attributeIsOther,
                                                  departmentId
                                              ),
                                              ...convertNameAttributesFormDataToDataState(
                                                  model.subjectForceTowardsOfficerLocationAttrIds,
                                                  subjectPersonProfileId,
                                                  EntityTypeEnum.PERSON_PROFILE.name,
                                                  AttributeTypeEnum
                                                      .USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_LOCATION
                                                      .name,
                                                  model.subjectForceTowardsOfficerLocationDescription,
                                                  attributeIsOther,
                                                  departmentId
                                              ),
                                              ...(model.officerInjurySeverityAttrId
                                                  ? convertNameAttributesFormDataToDataState(
                                                        [model.officerInjurySeverityAttrId],
                                                        subjectPersonProfileId,
                                                        EntityTypeEnum.PERSON_PROFILE.name,
                                                        AttributeTypeEnum
                                                            .USE_OF_FORCE_OFFICER_INJURY_SEVERITY
                                                            .name,
                                                        model.officerInjurySeverityDescription,
                                                        attributeIsOther,
                                                        departmentId
                                                    )
                                                  : []),
                                              ...convertNameAttributesFormDataToDataState(
                                                  model.officerInjuryTypeAttrIds,
                                                  subjectPersonProfileId,
                                                  EntityTypeEnum.PERSON_PROFILE.name,
                                                  AttributeTypeEnum.USE_OF_FORCE_OFFICER_INJURY_TYPE
                                                      .name,
                                                  model.officerInjuryTypeDescription,
                                                  attributeIsOther,
                                                  departmentId
                                              ),
                                          ],
                                          (nameAttribute) => !!nameAttribute.attributeId
                                      )
                                    : [];

                                useOfForceSubject = omit(model, nameAttributeModelFields);

                                useOfForceSubjectDeEscalations = fillRequiredDeEscalationProps(
                                    useOfForceSubject,
                                    get(model, 'useOfForceSubjectDeEscalations')
                                );

                                return [
                                    dispatch(
                                        saveUseOfForceSubject(
                                            reportId,
                                            useOfForceSubject,
                                            nameAttributes,
                                            subjectPersonProfileId,
                                            useOfForceSubjectDeEscalations
                                        )
                                    ),
                                ];
                            },
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                const formModel = dispatch(
                                    refreshUseOfForceSubjectForm({
                                        useOfForceSubject,
                                        subjectPersonProfileId,
                                        useOfForceSubjectDeEscalations,
                                    })
                                );
                                form.set('', formModel);
                            },
                        })
                    );
                }
            };
        },
    },
};

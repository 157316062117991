import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/item';

export default createResource({
    name: 'Evidence Item Split Resource',
    methods: {
        /**
         * Submit Evidence Item Split
         * @return {Promise<Object[]>}
         */
        splitEvidenceItem(itemId, items) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/${itemId}/split`,
                data: items,
            });
        },
    },
});

import { createSelector } from 'reselect';
import { mapValues } from 'lodash';
import { EntityTypeEnum } from '@mark43/rms-api';
import { cautionsByEntitySelector } from '~/client-common/core/domain/cautions/state/data';

import { formatAttributeWithOther } from '../../../attributes/utils/attributesHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
} from '../../../../../helpers/viewModelHelpers';
import {
    formatVehicleMake,
    formatVehicleModel,
    formatVehicleMakeModel,
    formatTitleForVehicle,
    vehicleMakeModelDisplayProperties,
} from '../../utils/vehicleHelpers';

import { vehiclesSelector } from '../data';
import {
    formatAttributeByIdSelector,
    formatAttributeAbbrevByIdSelector,
    attributeIsOtherSelector,
} from '../../../attributes/state/data';
import { formatVehicleMakeByIdSelector } from '../../../vehicle-makes/state/ui';
import { formatVehicleModelByIdSelector } from '../../../vehicle-models/state/ui';
import { itemIdentifierViewModelsByItemIdSelector } from '../../../item-identifiers/state/ui';

const buildVehicleViewModel = ({
    vehicle,
    formatAttributeById,
    formatAttributeAbbrevById,
    formatVehicleMakeById,
    formatVehicleModelById,
    itemIdentifierViewModelsByItemId,
    cautionsByEntity,
}) => {
    const makeViewModel = buildViewModel({
        recursive: true,
        mappers: [
            allSingleAttributeValuesMapper,
            boolToDisplayMapper,
            ({ registrationStateAttrId }) => ({
                registrationStateAbbrev: formatAttributeAbbrevById(registrationStateAttrId),
                registrationState: joinTruthyValues(
                    [
                        formatAttributeAbbrevById(registrationStateAttrId),
                        formatAttributeById(registrationStateAttrId),
                    ],
                    ' - '
                ),
            }),
            ({ vehicleMakeId, itemMake, vehicleModelId, itemModel }) => {
                const { vehicleMake, vehicleModel } = vehicleMakeModelDisplayProperties({
                    vehicleMakeName: formatVehicleMakeById(vehicleMakeId),
                    vehicleModelName: formatVehicleModelById(vehicleModelId),
                });

                return {
                    vehicleMakeModel: formatVehicleMakeModel({
                        vehicleMakeOther: itemMake,
                        vehicleModelOther: itemModel,
                        vehicleMake,
                        vehicleModel,
                    }),
                    vehicleMake: formatVehicleMake({
                        vehicleMake,
                        vehicleMakeOther: itemMake,
                    }),
                    vehicleModel: formatVehicleModel({
                        vehicleModel,
                        vehicleModelOther: itemModel,
                    }),
                };
            },
            ({ bodyStyleAttrId, bodyStyleOther }) => ({
                bodyStyle: formatAttributeWithOther(
                    formatAttributeById(bodyStyleAttrId),
                    bodyStyleOther
                ),
            }),
            ({ id }) => ({
                identifiers: itemIdentifierViewModelsByItemId(id),
            }),
            ({ id }) => ({
                cautions: cautionsByEntity(EntityTypeEnum.VEHICLE.name, id),
            }),
        ],
        helpers: {
            formatAttributeById,
        },
    });

    return makeViewModel(vehicle);
};

const vehicleViewModelsSelector = createSelector(
    vehiclesSelector,
    formatAttributeByIdSelector,
    formatAttributeAbbrevByIdSelector,
    formatVehicleMakeByIdSelector,
    formatVehicleModelByIdSelector,
    itemIdentifierViewModelsByItemIdSelector,
    cautionsByEntitySelector,
    (
        vehicles,
        formatAttributeById,
        formatAttributeAbbrevById,
        formatVehicleMakeById,
        formatVehicleModelById,
        itemIdentifierViewModelsByItemId,
        cautionsByEntity
    ) => {
        return mapValues(vehicles, (vehicle) =>
            buildVehicleViewModel({
                vehicle,
                formatAttributeById,
                formatAttributeAbbrevById,
                formatVehicleMakeById,
                formatVehicleModelById,
                itemIdentifierViewModelsByItemId,
                cautionsByEntity,
            })
        );
    }
);
export const vehicleViewModelByIdSelector = createSelector(
    vehicleViewModelsSelector,
    (vehicleViewModels) => (id) => vehicleViewModels[id]
);

// uses CAD specific item links

/**
 * @param  {Object} [vehicle] Vehicle, not ElasticVehicle.
 * @return {string}
 */
export const formatTitleForVehicleSelector = createSelector(
    formatAttributeByIdSelector,
    attributeIsOtherSelector,
    formatVehicleMakeByIdSelector,
    formatVehicleModelByIdSelector,
    (formatAttributeById, attributeIsOther, formatVehicleMakeById, formatVehicleModelById) => (
        vehicle = {}
    ) => {
        const vehicleMakeModelDisplayProps = vehicleMakeModelDisplayProperties({
            vehicleMakeIsOther: attributeIsOther(vehicle.vehicleMakeAttrId),
            vehicleMakeName: formatVehicleMakeById(vehicle.vehicleMakeId),
            vehicleModelIsOther: attributeIsOther(vehicle.vehicleModelAttrId),
            vehicleModelName: formatVehicleModelById(vehicle.vehicleModelId),
        });

        return formatTitleForVehicle({
            description: vehicle.description,
            itemCategory: formatAttributeById(vehicle.itemCategoryAttrId),
            vehicleMakeOther: vehicle.itemMake,
            vehicleModelOther: vehicle.itemModel,
            yearOfManufacture: vehicle.yearOfManufacture,
            ...vehicleMakeModelDisplayProps,
        });
    }
);

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import Modal from '../../../../legacy-redux/components/core/Modal';
import {
    closeDeleteConfirmationModal,
    blacksmithIntegrationToDeleteSelector,
    deleteBlacksmithIntegration,
} from '../state/ui';

const ModalBodyText = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-lg);
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.darkGrey};
`;

const context = {
    name: boxEnum.BLACKSMITH_INTEGRATION_DELETION_MODAL,
};

function BlacksmithIntegrationDeleteModal({
    // closeDeleteConfirmationModal,
    blacksmithIntegration,
    deleteBlacksmithIntegration,
}) {
    const displayName = blacksmithIntegration ? blacksmithIntegration.displayName : '';
    return (
        <Modal
            okText={'Delete Integration'}
            context={context}
            title={'Delete Integration'}
            onSave={deleteBlacksmithIntegration}
        >
            <ModalBodyText>
                {`This integration (${displayName}) will be completely removed from the system. This action is permanent and cannot be reversed. Are you sure?`}
            </ModalBodyText>
        </Modal>
    );
}

export default connect(
    (state) => {
        return {
            blacksmithIntegration: blacksmithIntegrationToDeleteSelector(state),
        };
    },
    {
        closeDeleteConfirmationModal,
        deleteBlacksmithIntegration,
    }
)(BlacksmithIntegrationDeleteModal);

import React from 'react';
import { TaskView } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';

export const TaskAssigneeCell = ({ assigneeRoleId }: Pick<TaskView, 'assigneeRoleId'>) => {
    const allRoleFormatsByRoleId = useSelector(allRoleFormatsByRoleIdSelector);

    if (!assigneeRoleId) {
        return null;
    }

    const assignee = allRoleFormatsByRoleId(assigneeRoleId);
    return <>{assignee.default}</>;
};

import { DeliveryMethodEnum } from '@mark43/notifications-api';
import { createSelector } from 'reselect';
import { map } from 'lodash';

import Promise from 'bluebird';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { notificationCategoryConfigsSelector } from '~/client-common/core/domain/user-notifications/state/ui';
import {
    loadOptOuts,
    saveUserOptOuts,
    saveCurrentUserOptOuts,
    userOptOutsSelector,
} from '~/client-common/core/domain/user-opt-outs/state/data';
import { loadNotificationTypes } from '~/client-common/core/domain/notification-types/state/data';

import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';

import notificationSettingsForm, { convertFromFormModel } from '../forms/notificationSettingsForm';
import {
    openBox,
    closeBox,
    saveBoxSuccess,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import { boxUiSelector } from '../../../../../legacy-redux/selectors/boxSelectors';
import { currentUserIdSelector } from '../../../../core/current-user/state/ui';
import { settingOptedOut } from '../../utils/notificationSettingsHelpers';

const context = { name: boxEnum.NOTIFICATION_SETTINGS_SIDE_PANEL };
const { WEB_APP, EMAIL } = DeliveryMethodEnum;

// ACTION TYPES
const OPEN_NOTIFICATION_SETTINGS_FORM_START = 'user-opt-outs/OPEN_NOTIFICATION_SETTINGS_FORM_START';
const OPEN_NOTIFICATION_SETTINGS_FORM_SUCCESS =
    'user-opt-outs/OPEN_NOTIFICATION_SETTINGS_FORM_SUCCESS';
const OPEN_NOTIFICATION_SETTINGS_FORM_FAILURE =
    'user-opt-outs/OPEN_NOTIFICATION_SETTINGS_FORM_FAILURE';

// ACTIONS
function openNotificationSettingsFormStart(userId) {
    return {
        type: OPEN_NOTIFICATION_SETTINGS_FORM_START,
        payload: userId,
    };
}

function openNotificationSettingsFormSuccess() {
    return {
        type: OPEN_NOTIFICATION_SETTINGS_FORM_SUCCESS,
    };
}

function openNotificationSettingsFormFailure(message) {
    return {
        type: OPEN_NOTIFICATION_SETTINGS_FORM_FAILURE,
        payload: message,
    };
}

/**
 * Open the notification settings side panel.
 * @param  {number} [userId] If not provided, the settings will be for the
 *   current user.
 */
export function openNotificationSettingsForm(userId) {
    return function (dispatch, getState) {
        const state = getState();
        const currentUserId = currentUserIdSelector(state);

        if (!userId) {
            userId = currentUserId;
        }

        dispatch(openNotificationSettingsFormStart(userId));
        dispatch(openBox(context));
        dispatch(notificationSettingsForm.actionCreators.reset());

        return Promise.all([
            dispatch(loadOptOuts(userId, currentUserId)),
            dispatch(loadNotificationTypes()),
        ])
            .spread((optOuts) => {
                dispatch(openNotificationSettingsFormSuccess());
                // get state again, because it's been updated
                const notificationCategoryConfigs = notificationCategoryConfigsSelector(getState());
                dispatch(
                    notificationSettingsForm.actionCreators.change(
                        optOuts,
                        notificationCategoryConfigs
                    )
                );
            })
            .catch((error) => {
                dispatch(openNotificationSettingsFormFailure(error.message));
            });
    };
}

export function closeNotificationSettingsSidePanel() {
    return function (dispatch) {
        dispatch(closeBox(context));
        dispatch(notificationSettingsForm.actionCreators.reset());
    };
}

export function submitNotificationSettingsForm() {
    return function (dispatch, getState) {
        const state = getState();
        const notificationCategoryConfigs = notificationCategoryConfigsSelector(state);

        dispatch(
            notificationSettingsForm.actionCreators.submit((formModel) => {
                const userId = notificationSettingsUserIdSelector(state);
                const currentUserId = currentUserIdSelector(state);
                dispatch((dispatch) => {
                    const convertedFormModel = convertFromFormModel(
                        formModel,
                        userId,
                        notificationCategoryConfigs
                    );
                    return userId === currentUserId
                        ? dispatch(saveCurrentUserOptOuts(userId, convertedFormModel))
                        : dispatch(saveUserOptOuts(userId, convertedFormModel));
                })
                    .then(() => {
                        dispatch(saveBoxSuccess(context));
                    })
                    .catch((error) => {
                        dispatch(saveBoxFailure(context, error.message));
                    });
            })
        );
    };
}

// SELECTORS
const notificationSettingsUiSelector = (state) => state.ui.notifications.settings;

const notificationSettingsUserIdSelector = createSelector(
    notificationSettingsUiSelector,
    ({ userId }) => userId
);

export const notificationSettingsIsLoadingSelector = createSelector(
    notificationSettingsUiSelector,
    ({ loadingOptOuts }) => loadingOptOuts
);

export const notificationSettingsLoadingErrorMessageSelector = createSelector(
    notificationSettingsUiSelector,
    ({ loadingOptOutsErrorMessage }) => loadingOptOutsErrorMessage
);

export const notificationSettingsSummaryViewModelSelector = createSelector(
    userOptOutsSelector,
    notificationCategoryConfigsSelector,
    (optOuts, notificationCategoryConfigs) => (userId) =>
        optOuts[userId] &&
        map(notificationCategoryConfigs, ({ display, notificationTypes }) => ({
            display,
            notificationTypes: map(
                notificationTypes,
                ({ display, name, webDelivery, emailDelivery }) => ({
                    display,
                    name,
                    WEB_APP: webDelivery
                        ? booleanToYesNo(
                              !settingOptedOut(name, optOuts[userId].optOuts, WEB_APP.name)
                          )
                        : 'N/A',
                    EMAIL: emailDelivery
                        ? booleanToYesNo(
                              !settingOptedOut(name, optOuts[userId].optOuts, EMAIL.name)
                          )
                        : 'N/A',
                })
            ),
        }))
);

export const notificationSettingsPanelSelector = createSelector(
    boxUiSelector,
    (ui) => ui.NOTIFICATION_SETTINGS_SIDE_PANEL
);

// REDUCER
export default function notificationSettingsUiReducer(
    state = {
        loadingOptOuts: false,
        loadingOptOutsErrorMessage: null,
        userId: null,
    },
    action
) {
    switch (action.type) {
        case OPEN_NOTIFICATION_SETTINGS_FORM_START:
            return {
                ...state,
                loadingOptOuts: true,
                loadingOptOutsErrorMessage: null,
                userId: action.payload,
            };
        case OPEN_NOTIFICATION_SETTINGS_FORM_SUCCESS:
            return {
                ...state,
                loadingOptOuts: false,
                loadingOptOutsErrorMessage: null,
            };
        case OPEN_NOTIFICATION_SETTINGS_FORM_FAILURE:
            return {
                ...state,
                loadingOptOuts: false,
                loadingOptOutsErrorMessage: action.payload,
                userId: null,
            };
        default:
            return state;
    }
}

import { EntityTypeEnum } from '@mark43/rms-api';
import globalAttributes from '../core/legacy-constants/globalAttributes';

const { PERSON_PROFILE, ORGANIZATION_PROFILE, ITEM_PROFILE } = EntityTypeEnum;
const {
    itemType: { vehicle, firearm },
} = globalAttributes;

/**
 * function that returns default profile image url for various
 * entity types and item types.
 */
export function defaultProfileImagePath(
    entityType: keyof typeof EntityTypeEnum,
    itemTypeAttrId: number
) {
    switch (entityType) {
        case PERSON_PROFILE.name:
            return 'images/entity-profiles/persons.png';
        case ORGANIZATION_PROFILE.name:
            return 'images/entity-profiles/organizations.png';
        case ITEM_PROFILE.name:
            switch (itemTypeAttrId) {
                case vehicle:
                    return 'images/entity-profiles/vehicles.png';
                case firearm:
                    return 'images/entity-profiles/firearms.png';
                default:
                    return 'images/entity-profiles/property.png';
            }
        default:
            return;
    }
}

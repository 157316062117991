import React from 'react';
import { NavMenuToggle as _NavMenuToggle } from 'arc';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { itemQueueCountSelector } from '../../../evidence/item-queue/state/ui';
import { notificationsUnreadCountSelector } from '../../../notifications/navigation-link/state/ui';

const indicatorStyles = `
        content: '';
        height: 6px;
        width: 6px;
        background-color: var(--arc-colors-negative-default);
        border-radius: var(--arc-radii-full);
        position: absolute;
        right: 5px;
        top: 8px;
        z-index: 1;
        box-sizing: content-box;`;

// Rectangle with a portion cut out at the top right to give a gap between the indicator dot and the menu lines
const clipPath = `M13 0H0V20H20V9.58396C19.3875 9.85155 18.7111 10 18 10C15.2386 10 13 7.76142 13 5V0Z`;

const StyledNavMenuToggle = styled(_NavMenuToggle)<{ $hasIndicator: boolean }>`
    ${(props) =>
        props.$hasIndicator &&
        `
        &:before {
            ${indicatorStyles}
        }
        svg {
            clip-path: path('${clipPath}');
        }
        `}
`;

export const NavMenuToggle = () => {
    const hasItems = useSelector(itemQueueCountSelector) > 0;
    const hasNotifications = useSelector(notificationsUnreadCountSelector) > 0;
    const showIndicator = hasItems || hasNotifications;

    return <StyledNavMenuToggle $hasIndicator={showIndicator} />;
};

import { LocationSourceEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import { keyBy, join, filter, uniq } from 'lodash';
import { _Form } from 'markformythree';
import keyMirror from 'keymirror';
import {
    NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP,
    saveLocation,
} from '~/client-common/core/domain/locations/state/data';
import getLocationsResource from '~/client-common/core/domain/locations/resources/locationsResource';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/location-entity-links/state/data';
import {
    locationHasSubdivision,
    getPlaceName,
    getAgencyIdForLocationEntityLink,
} from '~/client-common/core/domain/locations/utils/locationHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';

import { createLocationSidePanelFormConfiguration } from '../forms/locationSidePanelForm';

import { createOverlayCustomProperties } from '../../../utils/createOverlayCustomProperties';
import createArbiterMFTValidationHandler from '../../../markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../markformythree-arbiter/mftArbiterValidationEvents';
import { getErrorMessagesFromErrors } from '../../../../reports/core/helpers/validationHelpers';
import { currentUserDepartmentAgencyIdSelector } from '../../../current-user/state/ui';
import { convertToLocationDataModel } from '../../utils/locationSidePanelFormHelpers';

const LOCATION_SIDE_PANEL_SAVE_SUCCESS = 'location-side-panel/SAVE_SUCCESS';

export const SCREENS = keyMirror({
    SEARCH_FORM: null,
    PROFILE_EDIT: null,
    INVOLVED_LOCATIONS: null,
    /**
     * This is a new screen that will basically replace the `INVOLVED_LOCATIONS`
     * and `SEARCH_FORM` screens
     *
     * It should only be used when the feature flag is enabled. What feature flag should we use?
     *
     * Note that for this new feautre, the header and screen will be combined for simplicity
     */
    LOCATION_MAP_SEARCH: null,
});

function locationSidePanelSaveSuccess(location) {
    return {
        type: LOCATION_SIDE_PANEL_SAVE_SUCCESS,
        payload: location,
    };
}

export function saveLocationProfile({
    context,
    omitLocationEntityLinks,
    saveCoordinates,
    onSuccess,
    onError,
    overlayId,
}) {
    return (dispatch, getState, { formsRegistry, nexus, overlayStore }) => {
        const setSavingToFalse = () =>
            overlayStore.setCustomProperties(
                overlayId,
                createOverlayCustomProperties({ isSaving: false })
            );
        const handleError = (err) => {
            setSavingToFalse();
            onError(err);
        };

        return formsRegistry
            .get(context)
            .submit()
            .then(({ form }) => {
                overlayStore.setCustomProperties(
                    overlayId,
                    createOverlayCustomProperties({ isSaving: true })
                );
                const { model } = form.getState();
                dispatch(
                    saveLocation({
                        location: convertToLocationDataModel(model),
                        omitLocationEntityLinks,
                        saveCoordinates,
                    })
                )
                    // This returns a `locationView`
                    .then((location) => {
                        // We don't have to remove existing links here
                        // because we currently have no way via the UI to
                        // edit existing links (only remove)
                        const successAction = nexus.withEntityItems(
                            {
                                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: location.entityLinks,
                                [LOCATIONS_NEXUS_STATE_PROP]: [location],
                            },
                            locationSidePanelSaveSuccess(location)
                        );
                        dispatch(successAction);
                        setSavingToFalse();
                        if (onSuccess) {
                            onSuccess({
                                location,
                                modelEntityLink: {
                                    ...model.entityLink,
                                    // We need to set a location id
                                    // in case this was a newly
                                    // created location
                                    locationId: location.id,
                                },
                            });
                        }
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    };
}

export function quickAddLocation({
    location,
    entityType,
    entityId,
    onSuccess,
    linkType,
    formContext,
    arbiterInstance,
    onComplete,
    locationOverlayId,
}) {
    return (dispatch, getState, { nexus, formsRegistry, overlayStore }) => {
        const state = getState();
        const formatFieldByName = formatFieldByNameSelector(state);
        const formConfiguration = createLocationSidePanelFormConfiguration();
        const resolvedPlaceName = getPlaceName(location);
        const shouldClassify = !locationHasSubdivision(location);
        const applicationSettings = applicationSettingsSelector(state);
        const multiagencySubdivisionsEnabled =
            applicationSettings.MULTI_AGENCY_SUBDIVISIONS_ENABLED;
        const rmsLocationMapSearchEnabled =
            applicationSettings.LOCATIONS_LOCATION_SELECTION_ON_MAP_ENABLED;
        const currentUserDepartmentAgencyId = currentUserDepartmentAgencyIdSelector(state);
        const agencyId = getAgencyIdForLocationEntityLink({
            multiagencySubdivisionsEnabled,
            location,
            currentUserDepartmentAgencyId,
        });

        dispatch(
            payEsriAndClassifyLocation({
                multiagencySubdivisionsEnabled,
                agencyId,
                source: LocationSourceEnum.MARK43.name,
                location,
                id: location.id,
                shouldClassify,
                onSuccess: (location) => {
                    location.entityLinks[0].placeName = resolvedPlaceName;
                    const {
                        propertyTypeAttrId,
                        typeAttrId,
                        countyCodeAttrId,
                        subdivision1AttrId,
                        subdivision2AttrId,
                        subdivision3AttrId,
                        subdivision4AttrId,
                        subdivision5AttrId,
                        placeName,
                        subPremise,
                        agencyId,
                        resolverSource,
                        subPremise1Name,
                        subPremise1Value,
                        subPremise2Name,
                        subPremise2Value,
                        subPremise3Name,
                        subPremise3Value,
                        subPremise4Name,
                        subPremise4Value,
                        subPremise5Name,
                        subPremise5Value,
                    } = location.entityLinks[0];
                    const formModel = {
                        ...location,
                        entityLink: {
                            entityId,
                            entityType,
                            linkType,
                            propertyTypeAttrId,
                            typeAttrId,
                            countyCodeAttrId,
                            placeName,
                            subPremise,
                            subdivision1AttrId,
                            subdivision2AttrId,
                            subdivision3AttrId,
                            subdivision4AttrId,
                            subdivision5AttrId,
                            agencyId,
                            resolverSource,
                            subPremise1Name,
                            subPremise1Value,
                            subPremise2Name,
                            subPremise2Value,
                            subPremise3Name,
                            subPremise3Value,
                            subPremise4Name,
                            subPremise4Value,
                            subPremise5Name,
                            subPremise5Value,
                        },
                    };

                    const omitLocationEntityLinks = !entityId || entityId < 0;

                    formsRegistry.register(
                        new _Form({
                            name: formContext,
                            configuration: formConfiguration,
                            onValidate: createArbiterMFTValidationHandler(
                                arbiterInstance,
                                formContext,
                                formatFieldByName
                            ),
                            validationEvents: mftArbiterValidationEvents,
                            initialState: formModel,
                        })
                    );
                    const form = formsRegistry.get(formContext);

                    return form
                        .submit()
                        .then(() =>
                            dispatch(
                                saveLocation({
                                    location: convertToLocationDataModel(formModel),
                                    omitLocationEntityLinks,
                                })
                            )
                                // This returns a `locationView`
                                .then((location) => {
                                    // We don't have to remove existing links here
                                    // because we currently have no way via the UI to
                                    // edit existing links (only remove)
                                    const successAction = nexus.withEntityItems(
                                        {
                                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]:
                                                location.entityLinks,
                                            [LOCATIONS_NEXUS_STATE_PROP]: [location],
                                        },
                                        locationSidePanelSaveSuccess(location)
                                    );
                                    dispatch(successAction);
                                    if (onSuccess) {
                                        onSuccess({
                                            location,
                                            modelEntityLink: {
                                                ...formModel.entityLink,
                                                // We need to set a location id
                                                // in case this was a newly
                                                // created location
                                                locationId: location.id,
                                            },
                                        });
                                    }
                                })
                                .finally(() => {
                                    formsRegistry.unregister(form);
                                })
                        )
                        .catch((error) => {
                            const errorMessages = getErrorMessagesFromErrors(error);
                            const screenState = {
                                errorMessages,
                                initialFormState: formModel,
                                currentLocation: location,
                            };
                            openLocationSidepanelEditScreen({
                                overlayStore,
                                locationOverlayId,
                                entityId,
                                screenState,
                                rmsLocationMapSearchEnabled,
                            });
                        })
                        .finally(() => {
                            if (onComplete) {
                                onComplete({ location });
                            }
                        });
                },
                onError: (error) => {
                    const screenState = {
                        errorMessages: [error.message],
                        initialFormState: location,
                        currentLocation: location,
                    };
                    openLocationSidepanelEditScreen({
                        overlayStore,
                        locationOverlayId,
                        entityId,
                        screenState,
                        rmsLocationMapSearchEnabled,
                    });
                    if (onComplete) {
                        onComplete({ location });
                    }
                },
            })
        );
    };
}

function openLocationSidepanelEditScreen({
    overlayStore,
    locationOverlayId,
    entityId,
    screenState,
    rmsLocationMapSearchEnabled,
}) {
    overlayStore.open(locationOverlayId, {
        entityId,
        screenStack: [
            {
                screen: rmsLocationMapSearchEnabled
                    ? SCREENS.LOCATION_MAP_SEARCH
                    : SCREENS.INVOLVED_LOCATIONS,
            },
            {
                screen: SCREENS.PROFILE_EDIT,
                screenState,
            },
        ],
    });
}

function getAddressKey({ placeName, streetAddress, locality, adminArea1, postalCode, country }) {
    return join([placeName, streetAddress, locality, adminArea1, postalCode, country], '~');
}

/**
 *
 * @typedef Props
 * @prop {string} query
 * @prop {(location: Resources.ConsolidatedLocationView[]) => void} [onSuccess]
 * @prop {function} [onError]
 *
 * @param {Props} props
 *
 */
export function submitSearchForm({ query, onSuccess, onError }) {
    const locationsResource = getLocationsResource();
    let streetCenterlines;
    let locations;
    const errors = [];

    /**
     * Generate unique string message array of error messages from error array
     */
    function uniqueErrorMessages(errs) {
        return uniq(errs.map((e) => e.message));
    }

    function sortAndStoreLocations(locationResults = [], streetCenterlinesResults = []) {
        const centerLineResultByAddress = keyBy(streetCenterlinesResults, (sr) =>
            getAddressKey(sr)
        );
        const googleToShow = filter(
            locationResults,
            (lr) => !centerLineResultByAddress[getAddressKey(lr)]
        );
        const searchResults = streetCenterlinesResults.concat(googleToShow);
        onSuccess(searchResults);

        // call on error if either of the two calls fail
        if (errors.length) {
            onError(uniqueErrorMessages(errors));
        }
    }

    function collectAndPropagateError(error) {
        errors.push(error);
        onError(uniqueErrorMessages(errors));
    }

    /**
     * Generic handler factory for promise inspection. Receives
     * either locationResults or streetCenterlines as argument
     * to determine which value to fill in with the inspection result.
     */
    function handleInspectionResult(isStreetCenterLine) {
        return (inspection) => {
            if (inspection.isFulfilled()) {
                const value = inspection.value();
                // conditionally assign values to our top level variables
                // so they are available across all promise resolutions
                const centerLines = isStreetCenterLine
                    ? (streetCenterlines = value)
                    : streetCenterlines;
                const locationResults = isStreetCenterLine ? locations : (locations = value);
                return sortAndStoreLocations(locationResults, centerLines);
            } else {
                collectAndPropagateError(inspection.reason());
            }
        };
    }

    // use promise reflection so this promise collection never fails
    // and we get the proper loading indicators for the full duration
    // of all ongoing requests
    return Promise.all([
        Promise.resolve(locationsResource.resolveAllLocations(query))
            .reflect()
            .then(handleInspectionResult(false)),
        Promise.resolve([]),
    ]);
}

const updateLocationSidePanelState = (locationView) => {
    return withEntityItems(
        {
            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: locationView.entityLinks,
            [LOCATIONS_NEXUS_STATE_PROP]: [locationView],
        },
        {
            type: 'location-side-panel/GET_AND_CLASSIFY_LOCATION_BY_SOURCE_SUCCESS',
        }
    );
};

export function payEsriAndClassifyLocation({
    multiagencySubdivisionsEnabled,
    agencyId,
    source,
    id,
    shouldClassify,
    onSuccess,
    onError,
    location: originalLocation,
    storeLocallyAfterClassify = true,
}) {
    return (dispatch) => {
        const locationsResource = getLocationsResource();

        if (source === LocationSourceEnum.ESRI.name) {
            // This is how we pay ESRI
            // response data from this endpoint is unreliable, so don't use it
            locationsResource.fetchLocationBySource({ source, id });
        }

        if (!shouldClassify) {
            dispatch(updateLocationSidePanelState(originalLocation));
            return onSuccess(originalLocation);
        }

        if (source === LocationSourceEnum.POSTCODER.name) {
            return locationsResource
                .retrieveAndClassifyPostcoder(originalLocation)
                .then((clv) => {
                    const locationToReturn = {
                        ...clv,
                        entityLinks: [
                            {
                                ...clv.entityLinks[0],
                                placeName: originalLocation.placeName,
                            },
                        ],
                    };
                    return { location: locationToReturn };
                })
                .catch(() => {
                    // If classification failed, we still want to display the side panel in edit mode.
                    // The subdivision fields just won't be pre-filled
                    return {
                        location: originalLocation,
                        error: { message: 'Postcoder retrieve or classification failed' },
                    };
                })
                .then(({ location, error }) => {
                    if (storeLocallyAfterClassify) {
                        dispatch(updateLocationSidePanelState(location));
                    }
                    return onSuccess(location, error);
                })
                .catch(onError);
        }

        const classifyMethod =
            multiagencySubdivisionsEnabled && agencyId
                ? locationsResource.multiagencyClassify
                : locationsResource.classify;

        return classifyMethod(originalLocation, agencyId)
            .then((entityLink) => {
                const locationWithEntityLink = {
                    ...originalLocation,
                    entityLinks: [
                        {
                            ...entityLink,
                            placeName: originalLocation.placeName,
                        },
                    ],
                };

                return { location: locationWithEntityLink };
            })
            .catch(() => {
                // If classification failed, we still want to display the side panel in edit mode.
                // The subdivision fields just won't be pre-filled
                return { location: originalLocation, error: { message: 'Classification failed' } };
            })
            .then(({ location, error }) => {
                if (storeLocallyAfterClassify) {
                    dispatch(updateLocationSidePanelState(location));
                }
                return onSuccess(location, error);
            })
            .catch(onError);
    };
}

export function classifyLocation({ location, onSuccess, onError }) {
    return () => {
        const locationsResource = getLocationsResource();
        locationsResource.classify(location).then(onSuccess).catch(onError);
    };
}

export function retrieveAndClassifyPostcoder({ location, onSuccess, onError }) {
    return () => {
        const locationsResource = getLocationsResource();
        locationsResource.retrieveAndClassifyPostcoder(location).then(onSuccess).catch(onError);
    };
}

export function multiagencyClassify({ location, agencyId, onSuccess, onError }) {
    return () => {
        const locationsResource = getLocationsResource();
        locationsResource.multiagencyClassify(location, agencyId).then(onSuccess).catch(onError);
    };
}

type CollapsibleAction = {
    type: string;
    payload: {
        key: string;
    };
};

export const toggleIsOpenByKey = (key: string) => {
    return {
        type: 'TOGGLE_BY_KEY',
        payload: {
            key,
        },
    };
};

export function collapsibleStateReducer(state: Record<string, boolean>, action: CollapsibleAction) {
    return {
        ...state,
        [action.payload.key]: !state[action.payload.key],
    };
}
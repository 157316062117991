import React, { useState, useCallback } from 'react';
import { FormControl, Input, DataList as _DataList, DataItem } from 'arc';
import styled from 'styled-components';
import { map } from 'lodash';
import { dateTimeFormats, formatISODate } from '~/client-common/core/dates/utils/dateHelpers';
import {
    MAD_LIBS_FIELD_SPLITTER_REG_EXP,
    extractFieldsFromTemplate,
    sanitizeParameterForDisplay,
    isHandlebarHelper,
    fieldTypes,
    getFieldType,
    Field,
    formatFieldLabel,
    FieldTypesT,
    sanitizeParameterKey,
} from '../../../utils/madLibsConfig';
import DatePicker from '../../../../../core/forms/components/DatePicker';

export type ParamMapT = { [key: string]: string };

type PropsT = {
    isReadOnly: boolean;
    templateString: string;
    initialParameters: ParamMapT;
    onParameterMapChange?: (parameterMap: ParamMapT) => void;
};

const FormControlWrapper = styled.div`
    padding: 0 0 10px 0;
`;

const MadLibsWrapper = styled.div`
    padding-top: 10px;
    min-height: 75px;
`;

const MadLibsTemplateWrapper = styled.div`
    border: 1px solid var(--arc-colors-border-default);
    border-radius: var(--arc-space-2);
    padding: 10px;
    background-color: var(--arc-colors-brand-accent);
    font-size: var(--arc-fontSizes-md);
`;

const MadLibsFieldsWrapper = styled.div`
    padding-top: 10px;
`;

const StyledHighlightedText = styled.div`
    display: inline;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: var(--arc-fontSizes-lg);
    color: var(--arc-colors-selected-default);
`;

const DataList = styled(_DataList)`
    margin-left: 5px;
`;

const FieldValueDisplay: React.FC<{ fieldType: FieldTypesT; value: string }> = ({
    fieldType,
    value,
}) => {
    const isDate = fieldType === fieldTypes.date || fieldType === fieldTypes.dateTime;

    return (
        <span>
            {isDate
                ? formatISODate(
                      value,
                      fieldType === fieldTypes.dateTime
                          ? dateTimeFormats.summaryDateTime
                          : dateTimeFormats.formDate
                  )
                : value}
        </span>
    );
};

const HighlightedText: React.FC<{ fieldType: FieldTypesT; value: string }> = ({
    fieldType,
    value,
}) => {
    return (
        <StyledHighlightedText>
            <FieldValueDisplay fieldType={fieldType} value={value} />
        </StyledHighlightedText>
    );
};

const MadLibs: React.FC<PropsT> = ({
    isReadOnly,
    templateString,
    onParameterMapChange,
    initialParameters,
}) => {
    const [enteredFieldData, setEnteredFieldData] = useState<ParamMapT>(initialParameters);
    const templateFields = extractFieldsFromTemplate(templateString);
    const words = templateString.split(MAD_LIBS_FIELD_SPLITTER_REG_EXP).filter((char) => !!char);

    const result = map(words, (word, index) => {
        if (isHandlebarHelper(word)) {
            const parameterKey = sanitizeParameterKey(word);
            const fieldType = getFieldType(parameterKey);

            const isHandlebarParameter = !enteredFieldData[parameterKey];

            const text = !!enteredFieldData[parameterKey]
                ? enteredFieldData[parameterKey]
                : sanitizeParameterForDisplay(word);

            return (
                <HighlightedText
                    fieldType={isHandlebarParameter ? fieldTypes.string : fieldType}
                    key={index}
                    value={text}
                />
            );
        }

        return word;
    });

    const onDataEntryChange = useCallback(
        (value: string, field: Field) => {
            const newParamaterMap = {
                ...enteredFieldData,
                [field.label]: !!value ? value : '',
            };

            setEnteredFieldData(newParamaterMap);
            if (onParameterMapChange) {
                onParameterMapChange(newParamaterMap);
            }
        },
        [enteredFieldData, onParameterMapChange]
    );

    return (
        <MadLibsWrapper>
            <MadLibsTemplateWrapper>{result}</MadLibsTemplateWrapper>
            <MadLibsFieldsWrapper>
                {isReadOnly ? (
                    <DataList>
                        {map(templateFields, (field) => {
                            const formattedLabel = formatFieldLabel(field.label);
                            const fieldType = getFieldType(field.label);

                            if (!enteredFieldData[field.label]) {
                                return null;
                            }

                            return (
                                <DataItem key={field.id} dataKey={formattedLabel}>
                                    <FieldValueDisplay
                                        fieldType={fieldType}
                                        value={enteredFieldData[field.label]}
                                    />
                                </DataItem>
                            );
                        })}
                    </DataList>
                ) : (
                    map(templateFields, (field) => {
                        const formattedLabel = formatFieldLabel(field.label);

                        const fieldType = getFieldType(field.label);

                        switch (fieldType) {
                            case fieldTypes.date:
                            case fieldTypes.dateTime:
                                return (
                                    <FormControlWrapper key={field.id}>
                                        <FormControl label={formattedLabel}>
                                            <DatePicker
                                                value={enteredFieldData[field.label]}
                                                onChange={(value: string) => {
                                                    onDataEntryChange(value, field);
                                                }}
                                                includeTime={fieldType === fieldTypes.dateTime}
                                            />
                                        </FormControl>
                                    </FormControlWrapper>
                                );
                            default:
                                return (
                                    <FormControlWrapper key={field.id}>
                                        <FormControl label={formattedLabel}>
                                            <Input
                                                value={enteredFieldData[field.label]}
                                                size="md"
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value;
                                                    onDataEntryChange(value, field);
                                                }}
                                            />
                                        </FormControl>
                                    </FormControlWrapper>
                                );
                        }
                    })
                )}
            </MadLibsFieldsWrapper>
        </MadLibsWrapper>
    );
};

export default MadLibs;

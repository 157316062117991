import { isFunction, noop } from 'lodash';
import { _Form } from 'markformythree';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import createArbiterMFTValidationHandler from '../../../markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../markformythree-arbiter/mftArbiterValidationEvents';
import { getErrorMessagesFromErrors } from '../../../../reports/core/helpers/validationHelpers';
import { handleNameProfileFormError } from '../../util/nameSaveFormHelpers';

export function quickAddName({
    formModel,
    entityId,
    onComplete,
    arbiterInstance,
    nameOverlayId,
    formContext,
    formConfiguration,
    onSubmitSuccess,
    genericSaveError,
    getScreenStackWithErrorMessages,
}) {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const formatFieldByName = formatFieldByNameSelector(state);

        dependencies.formsRegistry.register(
            new _Form({
                name: formContext,
                configuration: formConfiguration,
                onValidate: createArbiterMFTValidationHandler(
                    arbiterInstance,
                    formContext,
                    formatFieldByName
                ),
                validationEvents: mftArbiterValidationEvents,
                initialState: formModel,
            })
        );
        const form = dependencies.formsRegistry.get(formContext);
        return form
            .submit()
            .then(() => {
                if (isFunction(onSubmitSuccess)) {
                    return onSubmitSuccess().finally(() => {
                        if (onComplete) {
                            onComplete();
                        }
                        dependencies.formsRegistry.unregister(form);
                    });
                }
            })
            .catch((error) => {
                const errorMessages = getErrorMessagesFromErrors(error, genericSaveError);
                dependencies.overlayStore.open(nameOverlayId, {
                    entityId,
                    screenStack: getScreenStackWithErrorMessages(errorMessages),
                });

                if (onComplete) {
                    onComplete();
                }
                handleNameProfileFormError({
                    error,
                    appendPanelErrorMessageIfNotExists: noop,
                    overlayStore: dependencies.overlayStore,
                    nameOverlayId,
                });
            });
    };
}

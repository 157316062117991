import { get } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredNumber } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const cadDesktopConfigsAdminFormFieldViewModels = {
    cadDesktopConfig: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadDesktopConfig',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'gpsPingerSettings.baudRate',
            },
            {
                key: 'gpsPingerSettings.comPort',
            },
            {
                key: 'gpsPingerSettings.readerType',
            },
            {
                key: 'isGpsInstalled',
                validators: {},
            },
            {
                key: 'authenticationToken',
            },
            {
                key: 'guid',
            },
        ]),
    },
};

function convertToFormModel({ cadDesktopConfig = {} }) {
    return {
        cadDesktopConfig: {
            ...cadDesktopConfig,
        },
    };
}

function convertFromFormModel({ cadDesktopConfig = {} }) {
    return { cadDesktopConfig };
}

/**
 * Module of the cad desktop configs admin form.
 * @type {Object}
 */
const formModule = createFormModule({
    formName: formClientEnum.CAD_DESKTOP_CONFIGS_ADMIN,
    fieldViewModels: cadDesktopConfigsAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

/**
 * Module of the cad rotation list admin form.
 * @type {Object}
 */
export default formModule;

export function validate() {
    const setValidity = formModule.actionCreators.setValidity;
    return (dispatch, getState) => {
        dispatch(formModule.actionCreators.validate());
        const state = getState();
        const formModel = formModule.selectors.formModelSelector(state);
        let isValid = formModule.selectors.formIsValidSelector(getState());
        const isGpsInstalled = get(formModel, 'cadDesktopConfig.isGpsInstalled');
        if (isGpsInstalled) {
            const validateHasField = (field) => {
                const fieldValid = get(formModel, field);
                if (!fieldValid) {
                    isValid = false;
                }
                dispatch(
                    setValidity(field, {
                        requiredError: fieldValid,
                    })
                );
            };

            const validateIsNumber = (field) => {
                const fieldValid = get(formModel, field);
                let isNumber = true;
                if (!fieldValid) {
                    isValid = false;
                } else {
                    isNumber = requiredNumber(fieldValid);
                    if (!isNumber) {
                        isValid = false;
                    }
                }
                dispatch(
                    setValidity(field, {
                        mustBeIntError: isNumber,
                    })
                );
            };

            const readerType = get(formModel, 'cadDesktopConfig.gpsPingerSettings.readerType');
            const isSerialSelected = !readerType || readerType === 'Serial';

            if (isSerialSelected) {
                validateHasField('cadDesktopConfig.gpsPingerSettings.baudRate');
                validateIsNumber('cadDesktopConfig.gpsPingerSettings.baudRate');
                validateHasField('cadDesktopConfig.gpsPingerSettings.comPort');
            }
        }

        return !isValid;
    };
}

import React, { Key, useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Box, FilterMenuRenderProps, Input } from 'arc';

export const InputFilter: React.FC<
    FilterMenuRenderProps & { filterId: Key; placeholder: string; debounced?: boolean }
> = ({ appliedOptions, setAppliedFilters, filterId, placeholder, debounced }) => {
    const [query, setQuery] = useState(() =>
        appliedOptions.length ? appliedOptions[0].value : ''
    );

    const handleChange = useCallback(
        (value: string) => {
            setAppliedFilters({
                id: filterId,
                label: value,
                value,
            });
        },
        [filterId, setAppliedFilters]
    );

    const handleDebouncedChange = useMemo(() => {
        return debounce(handleChange, 700);
    }, [handleChange]);

    const onChange = debounced ? handleDebouncedChange : handleChange;

    return (
        <Box p={2}>
            <Input
                placeholder={placeholder}
                size="md"
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </Box>
    );
};

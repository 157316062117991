import { get, map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { courtCaseViewModelsWhereSelector } from '~/client-common/core/domain/court-cases/state/ui';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';

const mapStateToProps = createStructuredSelector({
    courtCaseViewModelsWhere: courtCaseViewModelsWhereSelector,
});

const CourtCaseCardSummary = connect(mapStateToProps)(function _CourtCaseCardSummary({
    courtCaseViewModelsWhere,
    reportId,
}) {
    const courtCaseViewModels = courtCaseViewModelsWhere({ reportId });
    const courtCaseSections = map(courtCaseViewModels, (courtCaseViewModel) => {
        const courtCaseViewModelProperties = getViewModelProperties(courtCaseViewModel);
        return (
            <CardSection key={courtCaseViewModel.id}>
                <SummaryRowDate
                    fieldName={fields.COURT_CASE_COURT_DATE_UTC}
                    date={get(courtCaseViewModel, 'courtDateUtc')}
                />
                <SummaryRow fieldName={fields.COURT_CASE_COURT_TYPE_ATTR_ID}>
                    {get(courtCaseViewModelProperties, 'courtTypeAttrId')}
                </SummaryRow>
                <SummaryRow fieldName={fields.COURT_CASE_COURT_NAME}>
                    {get(courtCaseViewModel, 'courtName')}
                </SummaryRow>
                <SummaryRow fieldName={fields.COURT_CASE_JUDGE_NAME}>
                    {get(courtCaseViewModel, 'judgeName')}
                </SummaryRow>
                <SummaryRow fieldName={fields.COURT_CASE_BAIL_AMOUNT}>
                    {get(courtCaseViewModel, 'bailAmount')}
                </SummaryRow>
                <SummaryRow fieldName={fields.COURT_CASE_PLACE_DETAINED_AT_ATTR_ID}>
                    {get(courtCaseViewModelProperties, 'placeDetainedAtDisplay')}
                </SummaryRow>
                <SummaryRow fieldName={fields.COURT_CASE_COURT_ROOM_NUMBER}>
                    {get(courtCaseViewModel, 'courtRoomNumber')}
                </SummaryRow>
            </CardSection>
        );
    });

    return (
        <div>
            <SummaryList labelWidth={160} contentWidth={380}>
                {courtCaseSections}
            </SummaryList>
        </div>
    );
});

export default CourtCaseCardSummary;

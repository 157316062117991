import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const shortcutKeyAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'shortcutKey',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'shortcutCommand',
            },
            {
                key: 'shortcutContext',
            },
            {
                key: 'shortcutKeyCombination',
            },
        ]),
    },
]);

function convertFromFormModel({ shortcutKey }) {
    return { shortcutKey };
}

function convertToFormModel({ shortcutKey }) {
    return {
        shortcutKey,
    };
}

export default createFormModule({
    formName: formClientEnum.SHORTCUT_KEYS,
    fieldViewModels: shortcutKeyAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

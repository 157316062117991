import actionTypes from '../actions/types/searchActionTypes';

export function searchResultsUiReducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.SEARCH_ENTITIES_SUCCESS:
            const { entityType, entities } = action.payload;
            return {
                ...state,
                [entityType]: entities,
            };
        default:
            return state;
    }
}

import { UserAttribute, UserPhone, UserProfileView, UserAccountResource } from '@mark43/rms-api';

import { createResource } from '../../../lib/resources/Resource';
import { req } from '../../../lib/ajax';

export default createResource({
    name: 'User Account Resource',
    methods: {
        addUserPhones(data: UserPhone[]) {
            return req<UserAccountResource.AddUserPhones>({
                data,
                method: 'POST',
                url: 'user_account/phones',
            });
        },
        setUserAttributes(data: UserAttribute[]) {
            return req<UserAccountResource.SetUserAttributes>({
                data,
                method: 'PUT',
                url: 'user_account/attributes',
            });
        },
        updateUserProfile(data: UserProfileView) {
            return req<UserAccountResource.UpdateUserProfile>({
                data,
                method: 'PUT',
                url: 'user_account',
            });
        },
    },
});

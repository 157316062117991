import factory from './actionTypesFactory';

export default factory('roles', [
    'SAVE_USER_ROLE_START',
    'STORE_USER_ROLES',
    'DELETE_USER_ROLES',
    'SAVE_USER_ROLES_START',
    'SAVE_USER_ROLES_SUCCESS',
    'LOAD_USER_ROLES_SUCCESS',
    'ADD_USER_ROLES_SUCCESS',
    'DELETE_USER_ROLES_SUCCESS',
    'SAVE_USER_ROLES_FAILURE',
    'SAVE_USER_ROLE_SUCCESS',
    'SAVE_USER_ROLE_FAILURE',
]);

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';

import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import {
    Link,
    ApprovalStatusIcon,
    Subheader,
    LinkedRecordContainer,
    LinkedRecordBodyContainer,
    DisplayDate,
} from './LinkedRecordComponents';

const StyledLinkedRecordContainer = styled(LinkedRecordContainer)`
    display: inline-flex;
    word-break: break-word;
`;

function LinkedReport({
    currentUserHasAbility,
    reportShortTitleViewModel,
    includeApprovalStatusIcon = true,
}) {
    const {
        createdDateUtc,
        reportId,
        shortTitle,
        isSealed,
        isPartiallySealed,
        isVacated,
        clientApprovalStatus,
    } = reportShortTitleViewModel;
    const canViewCourtOrders = currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_COURT_ORDERS);
    const { currentSubmissionOwnerId, canRead } = getViewModelProperties(reportShortTitleViewModel);
    const canViewReport = canRead || (isSealed && canViewCourtOrders);

    return (
        <StyledLinkedRecordContainer>
            {includeApprovalStatusIcon && (
                <ApprovalStatusIcon
                    approvalStatus={clientApprovalStatus}
                    isSealed={isSealed}
                    isPartiallySealed={isPartiallySealed}
                    isVacated={isVacated}
                />
            )}
            <LinkedRecordBodyContainer>
                <Link
                    to={`/reports/${reportId}`}
                    disabled={!canViewReport}
                    testDisplay={String(shortTitle).toLowerCase()}
                >
                    {shortTitle}
                </Link>
                {createdDateUtc ? (
                    <Subheader>
                        Owned by {currentSubmissionOwnerId?.fullNameWithFirstInitialAndIdNumber},
                        created&nbsp;
                        <DisplayDate>
                            <FormattedDate
                                date={createdDateUtc}
                                format={FormattedDate.FORMATS.SUMMARY_DATE}
                            />
                        </DisplayDate>
                    </Subheader>
                ) : (
                    <Subheader>
                        Owned by {currentSubmissionOwnerId?.fullNameWithFirstInitialAndIdNumber}
                    </Subheader>
                )}
            </LinkedRecordBodyContainer>
        </StyledLinkedRecordContainer>
    );
}

export default connect(
    createStructuredSelector({
        currentUserHasAbility: currentUserHasAbilitySelector,
    })
)(LinkedReport);

import { createSelector } from 'reselect';
import { filter, mapValues } from 'lodash';
import {
    buildViewModel,
    buildAllMiniUserFormatsMapper,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { exportReleasesSelector } from '../data';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { formatAttributeWithOther } from '../../../attributes/utils/attributesHelpers';

const exportReleaseViewModelsSelector = createSelector(
    exportReleasesSelector,
    allMiniUserFormatsByIdSelector,
    formatAttributeByIdSelector,
    (exportReleases, allMiniUserFormatsById, formatAttributeById) => {
        const viewModel = buildViewModel({
            mappers: [
                buildAllMiniUserFormatsMapper(),
                allSingleAttributeValuesMapper,
                ({ releaseTypeAttrId, releaseTypeOther }) => ({
                    releaseType: formatAttributeWithOther(
                        formatAttributeById(releaseTypeAttrId),
                        releaseTypeOther
                    ),
                }),
                ({ releasedToAttrId, releasedToOther }) => ({
                    releasedTo: formatAttributeWithOther(
                        formatAttributeById(releasedToAttrId),
                        releasedToOther
                    ),
                }),
            ],
            helpers: {
                allMiniUserFormatsById,
                formatAttributeById,
            },
        });
        return mapValues(exportReleases, viewModel);
    }
);

export const exportReleaseViewModelsWhereSelector = createSelector(
    exportReleaseViewModelsSelector,
    (exportReleaseViewModels) => (predicate) => filter(exportReleaseViewModels, predicate)
);

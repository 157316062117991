import { useCallback, useState } from 'react';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { EFileSearchRequest, EFileQueryT, EFileSearchResultT } from '../types';

type PaginationPropsT<TQuery extends EFileQueryT, TResult extends EFileSearchResultT> = {
    eFileId?: number;
    total: number;
    initialSearchQuery: EFileSearchRequest<TQuery>;
    pageSize: number;
    resourceCall: (
        searchQuery: EFileSearchRequest<TQuery>,
        eFileId?: number
    ) => Promise<TResult> | Promise<undefined>;
    onSuccess: (searchResult?: TResult) => void;
    onError: () => void;
};

export function usePagination<TQuery extends EFileQueryT, TResult extends EFileSearchResultT>({
    eFileId,
    initialSearchQuery,
    total,
    pageSize,
    resourceCall,
    onSuccess,
    onError,
}: PaginationPropsT<TQuery, TResult>) {
    const [page, setPage] = useState<number>(1);
    const [from, setFrom] = useState<number>(0);
    const [to, setTo] = useState<number>(pageSize);

    const onPaginationClickResource = useCallback(
        async (currentPage: number, nextPage: number) => {
            if (nextPage < 1) {
                nextPage = 1;
            }

            const from = total === 0 ? 0 : pageSize * (nextPage - 1);
            const result = await resourceCall(
                {
                    ...initialSearchQuery,
                    from,
                },
                eFileId
            );
            setPage(nextPage);
            setFrom(from);
            setTo(Math.min(from + pageSize, result?.totalCount || total));
            return result;
        },
        [eFileId, initialSearchQuery, pageSize, resourceCall, total]
    );

    const { callResource: onPaginationClick, loading } = useResourceDeferred(
        onPaginationClickResource,
        onSuccess,
        onError
    );

    const reloadCurrentPage = useCallback(() => {
        return onPaginationClick(page, page);
    }, [onPaginationClick, page]);

    return {
        loading,
        page,
        from,
        to,
        onPaginationClick,
        reloadCurrentPage,
    };
}

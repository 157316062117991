import React from 'react';
import { useSelector } from 'react-redux';

import { ComplianceGroupEnumType } from '@mark43/rms-api';

import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';

const WithComplianceGroup: React.FC<{
    complianceGroup: ComplianceGroupEnumType;
    fallback?: React.ReactNode;
}> = ({ complianceGroup, fallback, children }) => {
    const departmentProfile = useSelector(currentUserDepartmentProfileSelector);
    if (departmentProfile?.complianceGroup === complianceGroup) {
        return <>{children}</>;
    } else {
        return <>{fallback}</>;
    }
};

/**
 * Show either `children` or `fallback` depending on whether the department profile has the given `complianceGroup`.
 */
export default WithComplianceGroup;

import React from 'react';

// taken from https://github.com/acdlite/recompose/blob/v0.19.0/src/packages/recompose/createElement.js
// this method was removed during recompose versions

const isClassComponent = (Component) =>
    Boolean(
        Component && Component.prototype && typeof Component.prototype.isReactComponent === 'object'
    );
const isReferentiallyTransparentFunctionComponent = (Component) =>
    Boolean(
        typeof Component === 'function' &&
            !isClassComponent(Component) &&
            !Component.defaultProps &&
            !Component.contextTypes &&
            !Component.propTypes
    );

const _createElement = (hasKey, isReferentiallyTransparent, Component, props, children) => {
    if (!hasKey && isReferentiallyTransparent) {
        const component = Component;
        if (children) {
            return component({ ...props, children });
        }
        return component(props);
    }

    if (children) {
        return <Component {...props}>{children}</Component>;
    }

    return <Component {...props} />;
};

const createElement = (Component, props, children) => {
    const isReferentiallyTransparent = isReferentiallyTransparentFunctionComponent(Component);
    /* eslint-disable */
    const hasKey = props && props.hasOwnProperty('key');
    /* eslint-enable */
    return _createElement(hasKey, isReferentiallyTransparent, Component, props, children);
};

export default createElement;

import { SearchableConfiguredPropertyOptionView } from '@mark43/rms-api';

export function createKeyForSearchableConfiguredPropertyOptionView<
    T extends Pick<
        SearchableConfiguredPropertyOptionView,
        'configuredEntityKeyName' | 'configuredPropertyKeyName'
    >,
>({ configuredEntityKeyName, configuredPropertyKeyName }: T): string {
    return `${configuredEntityKeyName}~${configuredPropertyKeyName}`;
}

import { useEffect } from 'react';

/** Use this hook on a page or route to make it display responsive on mobile devices.
 * It will add the responsive viewport and then reset it back when leaving that page.
 * Eventually all of the RMS should have this responsive viewport but for now this allows us
 * to incrementally convert pages to be responsive.
 */
export const useViewport = () => {
    useEffect(() => {
        const viewportMeta = document.querySelector('meta[id="responsiveMeta"]');
        const viewportMetaContent = viewportMeta?.getAttribute('content');

        // Only set maximum-scale on iOS devices.
        // https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming#which-approach-is-best-it-depends
        if (
            navigator.userAgent.indexOf('iPhone') > -1 ||
            navigator.userAgent.indexOf('iPad') > -1
        ) {
            viewportMeta?.setAttribute(
                'content',
                'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1'
            );
        } else {
            viewportMeta?.setAttribute(
                'content',
                'width=device-width, initial-scale=1, minimum-scale=1'
            );
        }

        // Reset the meta tag when going to another page.
        return () => {
            if (viewportMetaContent) {
                viewportMeta?.setAttribute('content', viewportMetaContent);
            }
        };
    }, []);
};

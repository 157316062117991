import { difference, flatMap, map, some, sortBy, toArray } from 'lodash';

import type { ModuleShape } from '~/client-common/core/utils/createNormalizedModule';

import type { ReportModuleView } from '../state/data/reportModules';

const DEFAULT_REPORT_MODULE_ID = 1 as const;
const DEFAULT_REPORT_MODULE_NAME = 'Reports' as const;

function buildDefaultReportModule(
    reportDefinitionIds: number[],
    moduleOrder: number
): ReportModuleView {
    return {
        id: DEFAULT_REPORT_MODULE_ID,
        name: DEFAULT_REPORT_MODULE_NAME,
        url: 'reports',
        icon: 'Report',
        moduleOrder,
        isDefault: true,
        reportDefinitionIds,
    };
}

function parseDefaultReportDefinitionIds(
    reportModules: ReportModuleView[],
    allReportDefinitionIds: number[]
) {
    const idsInModules = flatMap(reportModules, 'reportDefinitionIds');
    return difference(allReportDefinitionIds, idsInModules);
}

function findFirstMissingNumber(numbers: number[]) {
    // moduleOrder starts at 1, not 0
    let i = 1;
    while (true) {
        if (!numbers.includes(i)) {
            return i;
        } else {
            i++;
            continue;
        }
    }
}

export function sortReportModules(
    reportModuleViews: ReportModuleView[] | ModuleShape<ReportModuleView>
): ReportModuleView[] {
    return sortBy(toArray(reportModuleViews), 'moduleOrder');
}

/**
 * If a default report module isn't included in the given array, then include it.
 * The default module is derived from the other modules and not saved to the database, see ReportModule.java.
 * Its report types (reportDefinitionIds) are the ones that don't belong to any of the other modules.
 * It's ordered (moduleOrder) using the first gap in the numbers of the other modules.
 */
export function maybeAppendDefaultReportModule(
    reportModules: ReportModuleView[],
    allReportDefinitionIds: number[]
) {
    const modules = reportModules.filter(
        ({ reportDefinitionIds }) => reportDefinitionIds.length > 0
    );
    if (!some(modules, 'isDefault')) {
        const order = findFirstMissingNumber(map(reportModules, 'moduleOrder'));
        const defaultIds = parseDefaultReportDefinitionIds(modules, allReportDefinitionIds);
        if (defaultIds.length > 0) {
            const defaultModule = buildDefaultReportModule(defaultIds, order);
            modules.push(defaultModule);
        }
    }
    return sortReportModules(modules);
}

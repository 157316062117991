import {
    EntityTypeEnum,
    ProductModuleEnum,
    PreferencePermissionEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';
import { createSelector } from 'reselect';
import _, { get, map, compact } from 'lodash';

import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { storeAgencyProfiles } from '~/client-common/core/domain/agency-profiles/state/data';
import { storePreferenceLinks } from '~/client-common/core/domain/preference-links/state/data';
import {
    consortiumDepartmentLinksAvailableSelector,
    consortiumDepartmentLinksOptionsWhereSelector,
} from '~/client-common/core/domain/consortium-link-view/state/ui';
import { saveAttachmentsForEntities } from '~/client-common/core/domain/attachments/state/data';
import { currentUserDepartmentProfileSelector } from '../../../../core/current-user/state/ui';
import cadPreferenceResource from '../../../preferences/resources/cadPreferenceResource';
import automaticRenGenerationResource from '../../resources/automaticRenGenerationResource';
import agencyProfileResource from '../../resources/agencyProfileResource';
import { hideCadSection } from '../ui';

export const LOAD_AGENCY_PROFILES_START = 'agency-profiles/LOAD_AGENCY_PROFILES_START';
export const LOAD_AGENCY_PROFILES_SUCCESS = 'agency-profiles/LOAD_AGENCY_PROFILES_SUCCESS';
export const LOAD_AGENCY_PROFILES_FAILURE = 'agency-profiles/LOAD_AGENCY_PROFILES_FAILURE';
export const SAVE_AGENCY_PROFILE_START = 'agency-profiles/SAVE_AGENCY_PROFILE_START';
export const SAVE_AGENCY_PROFILE_SUCCESS = 'agency-profiles/SAVE_AGENCY_PROFILE_SUCCESS';
export const SAVE_AGENCY_PROFILE_FAILURE = 'agency-profiles/SAVE_AGENCY_PROFILE_FAILURE';

export const isCadDepartmentWithinConsortiumSelector = createSelector(
    consortiumDepartmentLinksAvailableSelector,
    currentUserDepartmentProfileSelector,
    (consortiumDepartmentLinksAvailable, departmentProfile) => {
        return (
            consortiumDepartmentLinksAvailable &&
            get(departmentProfile, 'department.cadDepartmentId') ===
                departmentProfile.departmentId &&
            departmentProfile.departmentId > 0
        );
    }
);

const departmentIdsForAgencyProfilesSelector = createSelector(
    consortiumDepartmentLinksOptionsWhereSelector,
    currentUserDepartmentProfileSelector,
    (consortiumDepartmentLinksWhere, departmentProfile) => {
        const { departmentId } = departmentProfile;
        if (_.isEmpty(consortiumDepartmentLinksWhere())) {
            return [departmentId];
        }

        return _.map(consortiumDepartmentLinksWhere(), 'value');
    }
);

function loadAgencyProfileStart() {
    return {
        type: LOAD_AGENCY_PROFILES_START,
    };
}

function loadAgencyProfileSuccess() {
    return {
        type: LOAD_AGENCY_PROFILES_SUCCESS,
    };
}

function loadAgencyProfileFailure(errMsg) {
    return {
        type: LOAD_AGENCY_PROFILES_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function loadAgencyProfiles() {
    return function (dispatch, getState) {
        dispatch(loadAgencyProfileStart());
        const departmentIds = departmentIdsForAgencyProfilesSelector(getState());
        return agencyProfileResource
            .getDepartmentAgencyProfiles(departmentIds)
            .then((agencies) => {
                dispatch(storeAgencyProfiles(agencies));
                dispatch(loadAgencyProfileSuccess());
                return agencies;
            })
            .catch((err) => dispatch(loadAgencyProfileFailure(err.message)));
    };
}

function saveAgencyProfileStart() {
    return {
        type: SAVE_AGENCY_PROFILE_START,
    };
}

function saveAgencyProfileSuccess(agencyProfile) {
    return {
        type: SAVE_AGENCY_PROFILE_SUCCESS,
        payload: agencyProfile,
    };
}

function saveAgencyProfileFailure(errMsg) {
    return {
        type: SAVE_AGENCY_PROFILE_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveAgencyProfileLogo(agencyProfile, id) {
    return (dispatch) => {
        // This awkward double field is required to know on a redux form if we have removed the
        // existing agency logo, or just left it untouched.
        if (!agencyProfile?.agencyProfileLogoId && !agencyProfile?.removedAgencyProfileLogoId) {
            return;
        }
        const newAgencyProfilePhotoAttachment = agencyProfile.agencyProfileLogoId
            ? {
                  entityId: id,
                  attachmentId: agencyProfile.agencyProfileLogoId,
                  attachmentType: EntityTypeEnum.FILE.name,
                  entityType: EntityTypeEnum.AGENCY.name,
                  linkType: LinkTypesEnum.AGENCY_PROFILE_LOGO,
              }
            : undefined;

        const attachment = compact([newAgencyProfilePhotoAttachment]);
        // save Attachments and replace existing attachments (last arg)
        return dispatch(
            saveAttachmentsForEntities({
                entityType: EntityTypeEnum.AGENCY.name,
                entityIds: [id],
                attachments: attachment,
                removeOthers: true,
                linkTypes: [LinkTypesEnum.AGENCY_PROFILE_LOGO],
            })
        ).catch((err) => dispatch(saveAgencyProfileFailure(err.message)));
    };
}

export function saveAgencyProfile(formModel) {
    return function (dispatch, getState) {
        dispatch(saveAgencyProfileStart());
        const { agencyProfile, agencyPreferences, multiRenGenSettings } = formModel;
        const { id, agencyTypeAttrId, rmsDepartmentId, rmsAgencyId } = agencyProfile;
        const state = getState();

        const isNewAgency = !id;
        const method = isNewAgency ? 'createAgencyProfile' : 'updateAgencyProfile';
        const hasCadModule = isProductModuleActiveSelector(state)(ProductModuleEnum.CAD.name);
        const isCadDepartmentWithinConsortium = isCadDepartmentWithinConsortiumSelector(state);

        if (!agencyTypeAttrId) {
            return dispatch(saveAgencyProfileFailure('Agency Type is required'));
        }

        if (isCadDepartmentWithinConsortium && rmsDepartmentId && !rmsAgencyId) {
            return dispatch(saveAgencyProfileFailure('Agency is required'));
        }

        return agencyProfileResource[method](agencyProfile)
            .then((agency) => {
                dispatch(storeAgencyProfiles([agency]));

                if (
                    hasCadModule &&
                    !hideCadSection({ agencyProfile, agencyPreferences, multiRenGenSettings })
                ) {
                    return dispatch(
                        saveAgencyCadConfiguration(
                            agency.id,
                            agencyPreferences,
                            multiRenGenSettings
                        )
                    ).then((response) => {
                        const updatedAgencyPreferences = response[0];
                        dispatch(storePreferenceLinks(updatedAgencyPreferences));
                        dispatch(saveAgencyProfileSuccess(agency));
                        dispatch(loadAgencyProfiles());
                        return agency;
                    });
                } else {
                    dispatch(saveAgencyProfileSuccess(agency));
                    dispatch(loadAgencyProfiles());
                    return agency;
                }
            })
            .catch((err) => dispatch(saveAgencyProfileFailure(err.message)));
    };
}

function saveAgencyCadConfiguration(agencyId, agencyPreferences, multiRenGenSettings) {
    return function (dispatch) {
        const saveAgencyPreferencesPromise = dispatch(
            saveAgencyPreferences(agencyId, agencyPreferences)
        );
        const autoRenGenPreferences = dispatch(
            saveAgencyAutoRenGenPreferences(agencyId, multiRenGenSettings)
        );

        return Promise.all([saveAgencyPreferencesPromise, autoRenGenPreferences]);
    };
}

function saveAgencyPreferences(agencyId, agencyPreferences) {
    return function () {
        const {
            dispositionRequired,
            eventBasedMultiRenGen,
            unitBasedMultiRenGen,
        } = agencyPreferences;

        const agencyLevelDispositionRequiredPreference = {
            ...dispositionRequired,
            entityId: agencyId,
            entityType: EntityTypeEnum.AGENCY.name,
            preferencePermission: PreferencePermissionEnum.AGENCY.name,
        };

        const agencyLevelEventBasedMultiRenGenPreference = {
            ...eventBasedMultiRenGen,
            entityId: agencyId,
            entityType: EntityTypeEnum.AGENCY.name,
            preferencePermission: PreferencePermissionEnum.AGENCY.name,
        };

        const agencyLevelUnitBasedMultiRenGenPreference = {
            ...unitBasedMultiRenGen,
            entityId: agencyId,
            entityType: EntityTypeEnum.AGENCY.name,
            preferencePermission: PreferencePermissionEnum.AGENCY.name,
        };

        const dispositionRequiredPreferenceSave = cadPreferenceResource.upsertDepartmentPreferenceLink(
            agencyLevelDispositionRequiredPreference
        );

        const eventBasedMultiRenGenPreferenceSave = cadPreferenceResource.upsertDepartmentPreferenceLink(
            agencyLevelEventBasedMultiRenGenPreference
        );

        const unitBasedMultiRenGenPreferenceSave = cadPreferenceResource.upsertDepartmentPreferenceLink(
            agencyLevelUnitBasedMultiRenGenPreference
        );

        return Promise.all([
            dispositionRequiredPreferenceSave,
            eventBasedMultiRenGenPreferenceSave,
            unitBasedMultiRenGenPreferenceSave,
        ]);
    };
}

function saveAgencyAutoRenGenPreferences(agencyId, multiRenGenSettings) {
    return function () {
        const { callForServiceIds, unitStatusIds } = multiRenGenSettings;

        const preferences = [
            ...map(callForServiceIds, (id) => {
                return {
                    agencyId,
                    entityId: id,
                    entityType: EntityTypeEnum.CALL_FOR_SERVICE.name,
                };
            }),
            ...map(unitStatusIds, (id) => {
                return {
                    agencyId,
                    entityId: id,
                    entityType: EntityTypeEnum.UNIT_STATE.name,
                };
            }),
        ];

        return automaticRenGenerationResource.updatePreferences(agencyId, preferences);
    };
}

import { EntityTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import {
    idFormatConfigurationByEntityTypeSelector,
    idFormatConfigurationByIdAndEntityTypeSelector,
} from '../data';
import recordSequenceTypeEnum from '../../../../enums/client/recordSequenceTypeEnum';

export const recordSequenceTypeToIdFormatConfigurationSelector = createSelector(
    idFormatConfigurationByEntityTypeSelector,
    idFormatConfigurationByIdAndEntityTypeSelector,
    (idFormatConfigurationByEntityType, idFormatConfigurationByIdAndEntityType) => (
        recordSequenceType,
        idFormatConfigurationId
    ) => {
        switch (recordSequenceType) {
            case recordSequenceTypeEnum.REN:
                return idFormatConfigurationByEntityType(
                    EntityTypeEnum.REPORTING_EVENT_NUMBER.name
                );
            case recordSequenceTypeEnum.AUTO_GENERATED:
            case recordSequenceTypeEnum.FORMATTED_FREE_TEXT:
                return idFormatConfigurationByIdAndEntityType(
                    idFormatConfigurationId,
                    EntityTypeEnum.REPORT.name
                );
            case recordSequenceTypeEnum.CAD_EVENT:
                return idFormatConfigurationByEntityType(EntityTypeEnum.CAD_AGENCY_EVENT.name);
            case recordSequenceTypeEnum.FREE_TEXT:
            default:
                return null;
        }
    }
);

export const idFormatConfigurationToRecordSequenceType = (config, isAutogenerated) => {
    const entityType = get(config, 'entityType');
    switch (entityType) {
        case EntityTypeEnum.REPORTING_EVENT_NUMBER.name:
            return recordSequenceTypeEnum.REN;
        case EntityTypeEnum.REPORT.name:
            return isAutogenerated
                ? recordSequenceTypeEnum.AUTO_GENERATED
                : recordSequenceTypeEnum.FORMATTED_FREE_TEXT;
        case EntityTypeEnum.CAD_AGENCY_EVENT.name:
            return recordSequenceTypeEnum.CAD_EVENT;
        default:
            return recordSequenceTypeEnum.FREE_TEXT;
    }
};

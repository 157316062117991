import React from 'react';

import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    NAME_IDENTIFIER_IDENTIFIER,
    NAME_IDENTIFIER_NAME_IDENTIFIER_TYPE_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

import {
    renderIdentifiersNItems,
    renderAddButton,
} from '../../names/components/nameFormNItemsRenderers';

import { MFTNItems } from '../../forms/components/NItems';

export function PersonProfileFormIdentifierFields() {
    const nameIdentifierLabel = useFields(NAME_IDENTIFIER_IDENTIFIER)[NAME_IDENTIFIER_IDENTIFIER];
    return (
        <div
            className="person-org-side-panel-identifiers"
            data-test-field-name={NAME_IDENTIFIER_NAME_IDENTIFIER_TYPE_ATTR_ID}
        >
            <MFTNItems
                path="identifiers"
                addItemOnEmpty={false}
                addText={nameIdentifierLabel}
                hideAddButtonOnEmptyItem={true}
                childFieldKeys={['nameIdentifierTypeAttrId', 'identifier']}
                render={renderIdentifiersNItems}
                renderAddButton={renderAddButton}
                renderRemoveButton={undefined}
            />
        </div>
    );
}

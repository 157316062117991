import React from 'react';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { closeTaskDetailsSidePanel, submitTaskDetailsForm } from '../state/ui';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import TaskDetailsForm from './TaskDetailsForm';

const strings = componentStrings.cases.taskList.TaskDetailsSidePanel;

const context = {
    name: boxEnum.TASK_DETAILS_SIDE_PANEL,
};

function TaskDetailsSidePanel({ onSubmit, close }) {
    return (
        <SidePanel
            title={strings.title}
            onSave={onSubmit}
            onCancel={close}
            context={context}
            width={400}
        >
            <TaskDetailsForm />
        </SidePanel>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => dispatch(submitTaskDetailsForm()),
    close: () => dispatch(closeTaskDetailsSidePanel()),
});

/**
 * Side panel form for creating/editing a task.
 */
export default connect(undefined, mapDispatchToProps)(TaskDetailsSidePanel);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cssVar, Text } from 'arc';
import Icon, { iconTypes, iconSizes } from '../../../../core/components/Icon';
import { LocationTitleSummary } from './locations/LocationTitleSummary';
import { LocationEntityLinkSummary } from './locations/LocationEntityLinkSummary';

const Title = styled(Text)`
    display: flex;
    line-height: ${cssVar('arc.lineHeights.none')};
    margin-bottom: 3px;
    font-weight: ${cssVar('arc.fontWeights.semibold')};
`;

const TitleNodeMarginWrapper = styled.div`
    margin-left: 3px;
`;

const Container = styled.div`
    font-size: var(--arc-fontSizes-sm);
    margin-bottom: 10px;
`;

/**
 * Summary view for a location bundle, with location and location entity link information
 */
function LocationSummary({
    locationBundle,
    className = '',
    showMap = false,
    title = '',
    labelWidth = 150,
    contentWidth = 240,
    locationEntityLinkSummaryVariant = undefined,
}) {
    const { locationEntityLink } = locationBundle;

    return (
        <Container className={className}>
            {title && (
                <Title>
                    <Icon size={iconSizes.SMALL} type={iconTypes.LOCATION} />
                    <TitleNodeMarginWrapper>{title}</TitleNodeMarginWrapper>
                </Title>
            )}
            <LocationTitleSummary locationBundle={locationBundle} showMap={showMap} />
            {
                <LocationEntityLinkSummary
                    locationEntityLink={locationEntityLink}
                    labelWidth={labelWidth}
                    contentWidth={contentWidth}
                    variant={locationEntityLinkSummaryVariant}
                />
            }
        </Container>
    );
}

LocationSummary.propTypes = {
    locationBundle: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default LocationSummary;

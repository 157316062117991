import { keys, map, sortBy } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';

import { formatReportDefinition } from '~/client-common/helpers/reportDefinitionsHelpers';
import getReportingEventsResource from '~/client-common/core/domain/reporting-events/resources/reportingEventsResource';
import {
    formatReportDefinitionByIdSelector,
    reportDefinitionByIdSelector,
} from '~/client-common/core/domain/report-definitions/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { currentCaseIdSelector } from '../../../../cases/core/state/ui';
import caseResource from '../../../../cases/core/resources/caseResource';
import { overlayStore } from '../../../../../core/overlayManager';
import _AsyncDropdownMenu from '../../../../core/components/AsyncDropdownMenu';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import _DropdownMenu from '../../../../core/components/DropdownMenu';
import { linkNewReport } from '../../state/ui/reportLinking';
import { openCreateReportModalFromLinkedReport } from '../../../../reports/core/state/ui/createReportModal';
import { openRenChangeModal } from '../../../../../legacy-redux/actions/renChangeActions';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.core.header.RecordsHeaderLinkReportMenu;

const ReportHeaderLinkingMenuLink = withRouter(
    ({
        rensForReportDefinitionLink,
        onCloseRef,
        reportDefinition,
        reportingEventNumber,
        closeMenu,
        router,
    }) => {
        const dispatch = useDispatch();
        const currentCaseId = useSelector(currentCaseIdSelector);
        const formatReportDefinitionById = useSelector(formatReportDefinitionByIdSelector);
        const applicationSettings = useSelector(applicationSettingsSelector);

        const displayName = formatReportDefinitionById(reportDefinition.id);

        const onClick = useCallback(() => {
            if (currentCaseId && applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE) {
                overlayStore.open(overlayIdEnum.CREATE_NEW_REPORT_FROM_MULTI_REN_CASE_MODAL, {
                    rensForReportDefinitionLink,
                    reportDefinition,
                });
            } else if (applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED) {
                dispatch(
                    openCreateReportModalFromLinkedReport({
                        reportDefinition,
                        reportingEventNumber,
                        router,
                        onCloseRef,
                    })
                );
            } else {
                dispatch(linkNewReport(reportingEventNumber, reportDefinition.id)).then(
                    (report) => {
                        router.push(`/reports/${report.id}`);
                    }
                );
            }
        }, [
            applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE,
            applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED,
            currentCaseId,
            dispatch,
            onCloseRef,
            rensForReportDefinitionLink,
            reportDefinition,
            reportingEventNumber,
            router,
        ]);

        const handleKeyDown = useCallback(
            (e) => {
                if (e.keyCode === keyCodeEnum.ENTER) {
                    onClick(e);
                    closeMenu();
                }
            },
            [closeMenu, onClick]
        );

        return (
            <div
                className="dropdown-menu-option"
                onClick={onClick}
                onKeyDown={handleKeyDown}
                data-test-field-name={`REPORT_HEADER_LINKING_MENU_DROPDOWN_OPTION_${displayName?.toUpperCase()}`}
                tabIndex={0}
            >
                {displayName}
            </div>
        );
    }
);

const DescriptionText = styled.div`
    padding: 4px 12px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-md);
    font-style: italic;
`;

const AsyncDropdownMenu = styled(_AsyncDropdownMenu)`
    margin: 0;

    .dropdown-menu-button {
        background-color: ${(props) => props.theme.colors.extraLightGrey};
    }
`;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
`;

const DropdownMenu = styled(_DropdownMenu)`
    margin: 0;
`;

function RecordsHeaderLinkReportMenu({ className, reportingEventNumber, caseId }) {
    const dispatch = useDispatch();

    const applicationSettings = useSelector(applicationSettingsSelector);
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const reportDefinitionById = useSelector(reportDefinitionByIdSelector);

    const onClickLinkToRen = React.useCallback(() => {
        dispatch(openRenChangeModal());
    }, [dispatch]);

    const menuButtonRef = useRef(null);
    const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
    const caseEnhancementsEnabled = applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE;
    const tooltip = reportingEventNumber ? strings.tooltip : strings.linkToRen(renDisplayName);

    const showReportDefinitions = caseEnhancementsEnabled
        ? !!caseId || !!reportingEventNumber
        : !!reportingEventNumber;

    return (
        <>
            {showReportDefinitions ? (
                <AsyncDropdownMenu
                    testId={testIds.LINKED_REPORTS_DROPDOWN}
                    buttonContent={
                        <DropdownMenuButtonContainer
                            data-test-id={testIds.REPORT_HEADER_LINKED_REPORTS_BUTTON}
                        >
                            <Icon size={16} type={iconTypes.LINK} />
                            <Icon size={16} type={iconTypes.TRIANGLE_DOWN} />
                        </DropdownMenuButtonContainer>
                    }
                    className={className}
                    descriptionText={
                        <FeatureFlagged
                            flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE"
                            fallback={
                                <DescriptionText>
                                    {strings.create(renDisplayName, reportingEventNumber)}
                                </DescriptionText>
                            }
                        />
                    }
                    fetchOptions={() => {
                        if (caseId && caseEnhancementsEnabled) {
                            return caseResource
                                .getAcceptableReportsForCase(caseId)
                                .then(({ rensByAcceptableReportDefinitionId }) => {
                                    const reportDefinitions = map(
                                        keys(rensByAcceptableReportDefinitionId),
                                        (reportDefinitionId) =>
                                            reportDefinitionById(reportDefinitionId)
                                    );
                                    return {
                                        reportDefinitions,
                                        rensByAcceptableReportDefinitionId,
                                    };
                                });
                        }

                        const reportingEventsResource = getReportingEventsResource();
                        return reportingEventsResource
                            .getAcceptableReportsForRen(reportingEventNumber)
                            .then((reportDefinitions) => ({ reportDefinitions }));
                    }}
                    ref={menuButtonRef}
                    mapResultOptions={(
                        { reportDefinitions, rensByAcceptableReportDefinitionId },
                        closeMenu
                    ) => {
                        return map(
                            sortBy(reportDefinitions, formatReportDefinition),
                            (reportDefinition) => {
                                return (
                                    <ReportHeaderLinkingMenuLink
                                        onCloseRef={menuButtonRef}
                                        key={reportDefinition.id}
                                        reportDefinition={reportDefinition}
                                        reportingEventNumber={reportingEventNumber}
                                        closeMenu={closeMenu}
                                        rensForReportDefinitionLink={
                                            rensByAcceptableReportDefinitionId?.[
                                                reportDefinition.id
                                            ]
                                        }
                                    />
                                );
                            }
                        );
                    }}
                    tooltip={tooltip}
                />
            ) : (
                <DropdownMenu
                    buttonContent={
                        <DropdownMenuButtonContainer
                            data-test-id={testIds.REPORT_HEADER_LINKED_REPORTS_BUTTON}
                        >
                            <Icon size={16} type={iconTypes.LINK} />
                            <Icon size={16} type={iconTypes.TRIANGLE_DOWN} />
                        </DropdownMenuButtonContainer>
                    }
                    className={className}
                    width={250}
                    testId={testIds.LINKED_REPORTS_DROPDOWN}
                    ref={menuButtonRef}
                    tooltip={tooltip}
                >
                    {(closeMenu) => (
                        <>
                            <DescriptionText>
                                {strings.notLinkedToRen(renDisplayName)}
                            </DescriptionText>
                            <div
                                className="dropdown-menu-option"
                                onClick={onClickLinkToRen}
                                onKeyDown={(e) => {
                                    if (e.keyCode === keyCodeEnum.ENTER) {
                                        onClickLinkToRen();
                                        closeMenu();
                                    }
                                }}
                                data-test-id={testIds.CHANGE_REN}
                            >
                                {strings.linkToRen(renDisplayName)}
                            </div>
                        </>
                    )}
                </DropdownMenu>
            )}
        </>
    );
}

/**
 * Dropdown menu in the report header for linking a new report to the current
 * report. This is rendered only if the current user has the relevant
 * permissions and there are available report types to be linked.
 */
export default RecordsHeaderLinkReportMenu;

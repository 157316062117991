import { ReportAttribute, AttributeTypeEnumType } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import getReportAttributesResource from '../../resources/reportAttributesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'reportAttributes';

const reportAttributeModule = createLinkModule<ReportAttribute>({
    type: NEXUS_STATE_PROP,
    keys: ['attributeId', 'reportId'],
});

const UPDATE_REPORT_ATTRIBUTES_START = 'report-attributes/UPDATE_REPORT_ATTRIBUTES_START';
const UPDATE_REPORT_ATTRIBUTES_SUCCESS = 'report-attributes/UPDATE_REPORT_ATTRIBUTES_SUCCESS';
const UPDATE_REPORT_ATTRIBUTES_FAILURE = 'report-attributes/UPDATE_REPORT_ATTRIBUTES_FAILURE';

function updateReportAttributesStart(
    reportId: number,
    reportAttributes: Partial<ReportAttribute>[]
) {
    return {
        type: UPDATE_REPORT_ATTRIBUTES_START,
        payload: {
            reportId,
            reportAttributes,
        },
    };
}

function updateReportAttributesSuccess(reportId: number, reportAttributes: ReportAttribute[]) {
    return {
        type: UPDATE_REPORT_ATTRIBUTES_SUCCESS,
        payload: {
            reportId,
            reportAttributes,
        },
    };
}

function updateReportAttributesFailure(message: string) {
    return {
        type: UPDATE_REPORT_ATTRIBUTES_FAILURE,
        payload: message,
    };
}

export function updateReportAttributes(
    reportId: number,
    reportAttributes: Partial<ReportAttribute>[],
    attributeType: AttributeTypeEnumType
): ClientCommonAction<Promise<ReportAttribute[]>> {
    const resource = getReportAttributesResource();
    return (dispatch) => {
        dispatch(updateReportAttributesStart(reportId, reportAttributes));
        return resource
            .updateReportAttributes(reportId, reportAttributes, attributeType)
            .then((reportAttributes: ReportAttribute[]) => {
                // when putting the received reportAttributes in to state, we need to know
                // whether or not our original call was constrained by a specific attribute type
                // this will inform whether we replace all attributes for a given reportId
                // or only those of a given type
                if (attributeType) {
                    dispatch(
                        replaceReportAttributesWhere({ reportId, attributeType }, reportAttributes)
                    );
                } else {
                    dispatch(replaceReportAttributesWhere({ reportId }, reportAttributes));
                }
                dispatch(updateReportAttributesSuccess(reportId, reportAttributes));
                return reportAttributes;
            })
            .catch((err: Error) => {
                dispatch(updateReportAttributesFailure(err.message));
                throw err;
            });
    };
}

// ACTIONS

const replaceReportAttributesWhere = reportAttributeModule.actionCreators.replaceLinksWhere;

// SELECTORS
export const reportAttributesSelector = reportAttributeModule.selectors.linksSelector;
export const reportAttributesWhereSelector = reportAttributeModule.selectors.linksWhereSelector;

// REDUCER
export default reportAttributeModule.reducerConfig;

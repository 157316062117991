import { createSelector } from 'reselect';
import { mapValues, filter, first, values, map, sortBy } from 'lodash';
import { TrafficCrashVehicle, AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';

import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
    ViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { trafficCrashVehiclesSelector } from '../data';
import { itemAttributesWhereSelector } from '../../../item-attributes/state/data';
import { entityOrderedAttributesWhereSelector } from '../../../entity-ordered-attributes/state/data';
import { ModuleShape } from '../../../../utils/createNormalizedModule';

import { formatTitleForVehicle } from '../../../vehicles/utils/vehicleHelpers';
import { formatVehicleMakeByIdSelector } from '../../../vehicle-makes/state/ui';
import { formatVehicleModelByIdSelector } from '../../../vehicle-models/state/ui';

import { formatAttributeByIdSelector } from '../../../attributes/state/data';

type TrafficCrashVehicleViewModelProps = {
    trafficControlsType?: string;
    trafficControlsInoperativeMissing?: string;
    locationOfDamagedAreas?: string;
    sequenceOfEvents?: string;
    vehicleConfigSpecialSizing?: string;
    automatedDrivingSystemsLevel?: string;
    automatedDrivingSystemsEngaged?: string;
    unitTypeAttrId?: string;
    bodyTypeCategoryAttrId?: string;
    numTrailingUnitsAttrId?: string;
    vehicleSizeAttrId?: string;
    hasHmPlacardAttrId?: string;
    specialFunctionAttrId?: string;
    emergencyMotorVehicleUseAttrId?: string;
    directionOfTravelBeforeCrashAttrId?: string;
    trafficwayTravelDirectionsAttrId?: string;
    trafficwayDividedAttrId?: string;
    trafficwayBarrierTypeAttrId?: string;
    trafficwayHovHotLanesAttrId?: string;
    crashRelatedToHovHotLanesAttrId?: string;
    roadwayHorizontalAlignmentAttrId?: string;
    roadwayGradeAttrId?: string;
    maneuverAttrId?: string;
    initialPointOfContactAttrId?: string;
    resultingExtentOfDamageAttrId?: string;
    mostHarmfulEventAttrId?: string;
    hitAndRunAttrId?: string;
    towedDueToDisablingDamageAttrId?: string;
    contributingCircumstancesAttrId?: string;
    motorCarrierIdentificationTypeAttrId?: string;
    motorCarrierIdentificationCarrierTypeAttrId?: string;
    vehicleConfigurationAttrId?: string;
    vehicleConfigurationPermittedAttrId?: string;
    cargoBodyTypeAttrId?: string;
    hazardousMaterialsReleasedAttrId?: string;
    motorVehicleAutomatedDrivingSystemsAttrId?: string;
    hazardousMaterialsInvolved?: string;
    hazardousMaterialsClassAttrId?: string;
    firstTrailerTitle?: string;
    secondTrailerTitle?: string;
    thirdTrailerTitle?: string;
};

export type TrafficCrashVehicleViewModel = ViewModel<
    TrafficCrashVehicle,
    TrafficCrashVehicleViewModelProps
>;

const formatTrailerTitle = ({
    formatVehicleMakeById,
    formatVehicleModelById,
    makeId,
    modelId,
    vehicleMakeOther,
    vehicleModelOther,
    yearOfManufacture,
}: {
    formatVehicleMakeById: (id: number) => string;
    formatVehicleModelById: (id: number) => string;
    makeId?: number;
    modelId?: number;
    vehicleMakeOther?: string;
    vehicleModelOther?: string;
    yearOfManufacture?: number;
}) => {
    const vehicleMake = !!makeId ? formatVehicleMakeById(makeId) : undefined;
    const vehicleModel = !!modelId ? formatVehicleModelById(modelId) : undefined;

    // @ts-expect-error convert vehicleHelpers to typescript
    return formatTitleForVehicle({
        vehicleMake,
        vehicleMakeIsOther: !vehicleMake,
        vehicleMakeOther,
        vehicleModel,
        vehicleModelIsOther: !vehicleModel,
        vehicleModelOther,
        yearOfManufacture,
    });
};

const trafficCrashVehicleViewModelsSelector = createSelector(
    trafficCrashVehiclesSelector,
    formatAttributeByIdSelector,
    itemAttributesWhereSelector,
    entityOrderedAttributesWhereSelector,
    formatVehicleMakeByIdSelector,
    formatVehicleModelByIdSelector,
    (
        trafficCrashVehicles,
        formatAttributeById,
        itemAttributesWhere,
        entityOrderedAttributesWhere,
        formatVehicleMake,
        formatVehicleModel
    ): ModuleShape<TrafficCrashVehicleViewModel> => {
        const formatVehicleMakeById = formatVehicleMake as (id: number) => string;
        const formatVehicleModelById = formatVehicleModel as (id: number) => string;
        const viewModel = buildViewModel<TrafficCrashVehicle, TrafficCrashVehicleViewModelProps>({
            mappers: [
                allSingleAttributeValuesMapper,
                boolToDisplayMapper,
                ({ vehicleId }) => {
                    const itemAttributes = itemAttributesWhere({ itemProfileId: vehicleId });
                    const entityOrderedAttributes = entityOrderedAttributesWhere({
                        entityId: vehicleId,
                        entityType: EntityTypeEnum.VEHICLE.name,
                    });
                    const trafficControlsType = formatAttributeById(
                        map(
                            filter(
                                itemAttributes,
                                (pa) =>
                                    pa.attributeType ===
                                    AttributeTypeEnum.QC_TRAFFIC_CONTROLS_TYPE.name
                            ),
                            'attributeId'
                        )
                    );
                    const trafficControlsInoperativeMissing = formatAttributeById(
                        map(
                            filter(
                                itemAttributes,
                                (pa) =>
                                    pa.attributeType ===
                                    AttributeTypeEnum.QC_TRAFFIC_CONTROLS_INOPERATIVE_OR_MISSING
                                        .name
                            ),
                            'attributeId'
                        )
                    );
                    const locationOfDamagedAreas = formatAttributeById(
                        map(
                            filter(
                                itemAttributes,
                                (pa) =>
                                    pa.attributeType ===
                                    AttributeTypeEnum.QC_LOCATION_OF_DAMAGED_AREAS.name
                            ),
                            'attributeId'
                        )
                    );
                    const sequenceOfEvents = formatAttributeById(
                        map(
                            sortBy(
                                filter(entityOrderedAttributes, {
                                    attributeType: AttributeTypeEnum.QC_SEQUENCE_OF_EVENTS.name,
                                }),
                                'sequenceOrder'
                            ),
                            'attributeId'
                        ),
                        false
                    );
                    const vehicleConfigSpecialSizing = formatAttributeById(
                        map(
                            filter(
                                itemAttributes,
                                (pa) =>
                                    pa.attributeType ===
                                    AttributeTypeEnum.QC_VEHICLE_CONFIGURATION_SPECIAL_SIZING.name
                            ),
                            'attributeId'
                        )
                    );
                    const automatedDrivingSystemsLevel = formatAttributeById(
                        map(
                            filter(
                                itemAttributes,
                                (pa) =>
                                    pa.attributeType ===
                                    AttributeTypeEnum
                                        .QC_MOTOR_VEHICLE_AUTOMATED_DRIVING_SYSTEMS_LEVEL.name
                            ),
                            'attributeId'
                        )
                    );
                    const automatedDrivingSystemsEngaged = formatAttributeById(
                        map(
                            filter(
                                itemAttributes,
                                (pa) =>
                                    pa.attributeType ===
                                    AttributeTypeEnum
                                        .QC_MOTOR_VEHICLE_AUTOMATED_DRIVING_SYSTEMS_ENGAGED.name
                            ),
                            'attributeId'
                        )
                    );
                    return {
                        trafficControlsType,
                        trafficControlsInoperativeMissing,
                        locationOfDamagedAreas,
                        sequenceOfEvents,
                        vehicleConfigSpecialSizing,
                        automatedDrivingSystemsLevel,
                        automatedDrivingSystemsEngaged,
                    };
                },
                ({
                    makeIdFirstTrailer,
                    modelIdFirstTrailer,
                    makeOtherFirstTrailer,
                    modelOtherFirstTrailer,
                    modelYearFirstTrailer,
                }) => {
                    const firstTrailerTitle = formatTrailerTitle({
                        formatVehicleMakeById,
                        formatVehicleModelById,
                        makeId: makeIdFirstTrailer,
                        modelId: modelIdFirstTrailer,
                        vehicleMakeOther: makeOtherFirstTrailer,
                        vehicleModelOther: modelOtherFirstTrailer,
                        yearOfManufacture: modelYearFirstTrailer,
                    });
                    return {
                        firstTrailerTitle,
                    };
                },
                ({
                    makeIdSecondTrailer,
                    modelIdSecondTrailer,
                    makeOtherSecondTrailer,
                    modelOtherSecondTrailer,
                    modelYearSecondTrailer,
                }) => {
                    const secondTrailerTitle = formatTrailerTitle({
                        formatVehicleMakeById,
                        formatVehicleModelById,
                        makeId: makeIdSecondTrailer,
                        modelId: modelIdSecondTrailer,
                        vehicleMakeOther: makeOtherSecondTrailer,
                        vehicleModelOther: modelOtherSecondTrailer,
                        yearOfManufacture: modelYearSecondTrailer,
                    });
                    return {
                        secondTrailerTitle,
                    };
                },
                ({
                    makeIdThirdTrailer,
                    modelIdThirdTrailer,
                    makeOtherThirdTrailer,
                    modelOtherThirdTrailer,
                    modelYearThirdTrailer,
                }) => {
                    const thirdTrailerTitle = formatTrailerTitle({
                        formatVehicleMakeById,
                        formatVehicleModelById,
                        makeId: makeIdThirdTrailer,
                        modelId: modelIdThirdTrailer,
                        vehicleMakeOther: makeOtherThirdTrailer,
                        vehicleModelOther: modelOtherThirdTrailer,
                        yearOfManufacture: modelYearThirdTrailer,
                    });
                    return {
                        thirdTrailerTitle,
                    };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(trafficCrashVehicles, viewModel);
    }
);

export const trafficCrashVehicleViewModelByVehicleIdSelector = createSelector(
    trafficCrashVehicleViewModelsSelector,
    (trafficCrashVehicleViewModels) => (vehicleId: number) =>
        first(
            filter(
                values(trafficCrashVehicleViewModels),
                (viewModel) => viewModel.vehicleId === vehicleId
            )
        )
);

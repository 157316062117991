import React from 'react';
import { simpleControl } from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const { options } = componentStrings.forms.select.BooleanSelect;
const booleanOptions = [
    { value: true, display: options.true },
    { value: false, display: options.false },
];

const invertedBooleanOptions = [
    { value: false, display: options.true },
    { value: true, display: options.false },
];

/**
 * `Select` dropdown for a boolean value with "Yes" and "No" as options. The
 *   selected option can be blank/cleared for the field value to be `undefined`
 *   to represent neither Yes nor No.
 * @param {Object} props Props get spread into `<Select>`.
 * @param {Object} props.invertValues Whether or not to use inverted values
 */
export default function BooleanSelect({ invertValues, ...props }) {
    return <Select options={invertValues ? invertedBooleanOptions : booleanOptions} {...props} />;
}

BooleanSelect.displayName = 'BooleanSelect';

export const RRFBooleanSelect = connectRRFInput(BooleanSelect);
export const MFTBooleanSelect = simpleControl(BooleanSelect);
export const ArbiterMFTBooleanSelect = arbiterMFTInput(BooleanSelect);

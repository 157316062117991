import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import _DropdownMenu from '../../../core/components/DropdownMenu';
import Icon, { iconTypes } from '../../../core/components/Icon';
import testIds from '../../../../core/testIds';
import { abilitiesEnum, OnlyWithAbility } from '../../../core/abilities';

const Container = styled.div`
    width: 38px;
`;

const DropdownMenu = styled(_DropdownMenu)`
    position: absolute;

    & .dropdown-menu-button {
        background-color: ${(props) => props.theme.colors.white};
    }
`;

const strings = componentStrings.search.savedSearch.SavedSearchMoreOptionsMenu;

const SavedSearchMoreOptionsMenu = ({
    className,
    onCopy,
    onEdit,
    onShare,
    onDelete,
    onSubscribe,
    onFavorite,
    savedSearch = {},
}) => {
    const { isSubscribed, isShared, canEdit, isFavorite } = savedSearch;

    return (
        <Container className={className}>
            <DropdownMenu
                buttonContent={<Icon color="cobaltBlue" size={16} type={iconTypes.MORE_ACTIONS} />}
                testId={testIds.SAVED_SEARCH_MORE_OPTIONS_MENU}
            >
                <FeatureFlagged flag="RMS_SUBSCRIPTIONS_ENABLED">
                    {onSubscribe && !isShared && (
                        <div
                            className="dropdown-menu-option"
                            data-test-id={testIds.SAVED_SEARCH_SUBSCRIBE_OPTION}
                            onClick={onSubscribe}
                            tabIndex={0}
                        >
                            {isSubscribed ? strings.unsubscribe : strings.subscribe}
                        </div>
                    )}
                </FeatureFlagged>
                <FeatureFlagged flag="RMS_SAVED_SEARCH_SHARING_ENABLED">
                    <>
                        <div
                            className="dropdown-menu-option"
                            data-test-id={testIds.SAVED_SEARCH_FAVORITE_OPTION}
                            onClick={onFavorite}
                            tabIndex={0}
                        >
                            {isFavorite ? strings.unfavorite : strings.favorite}
                        </div>
                        {canEdit && (
                            <OnlyWithAbility has={abilitiesEnum.ADMIN.SAVED_SEARCH_SHARING}>
                                <div
                                    className="dropdown-menu-option"
                                    data-test-id={testIds.SAVED_SEARCH_SHARE_OPTION}
                                    onClick={onShare}
                                    tabIndex={0}
                                >
                                    {strings.share}
                                </div>
                            </OnlyWithAbility>
                        )}
                        <div
                            className="dropdown-menu-option"
                            data-test-id={testIds.SAVED_SEARCH_COPY_OPTION}
                            onClick={onCopy}
                            tabIndex={0}
                        >
                            {strings.copy}
                        </div>
                    </>
                </FeatureFlagged>
                {canEdit && (
                    <>
                        <div
                            className="dropdown-menu-option"
                            data-test-id={testIds.SAVED_SEARCH_EDIT_OPTION}
                            onClick={onEdit}
                            tabIndex={0}
                        >
                            {strings.rename}
                        </div>
                        <div
                            className="dropdown-menu-option"
                            data-test-id={testIds.SAVED_SEARCH_DELETE_OPTION}
                            onClick={onDelete}
                            tabIndex={0}
                        >
                            {strings.delete}
                        </div>
                    </>
                )}
            </DropdownMenu>
        </Container>
    );
};

export default SavedSearchMoreOptionsMenu;

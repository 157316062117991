import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IOverlayBaseProps, IOverlayBaseRenderProps } from 'overlay-manager/lib/OverlayBase';
import { OverlayBase } from '../../../../core/overlayManager';
import { getErrorMessagesFromErrors } from '../../../reports/core/helpers/validationHelpers';
import errorToMessage from '../../errors/utils/errorToMessage';
import { ModalCancelError } from './ModalCancelError';
import ModalBase, { ModalBaseProps } from './ModalBase';

export type OverrideModalPropsFunction<T extends Record<string, unknown>> = (
    overlayBaseProps: IOverlayBaseRenderProps<T>
) => Partial<ModalBaseProps & { children: React.ReactNode }>;

type OverlayBaseProps<T extends Record<string, unknown>> = Pick<
    IOverlayBaseProps<T>,
    'autoClose' | 'getInitialCustomPropertyState' | 'id'
>;

export interface ModalProps<T extends Record<string, unknown> = Record<string, unknown>>
    extends OverlayBaseProps<T>,
        Omit<ModalBaseProps, 'isOpen'> {
    buttonElement?: React.ReactNode;
    cancelFocusRef?: React.RefObject<HTMLElement>;
    children?: React.ReactNode;
    onSave?: (customProperties: T) => void | Promise<unknown>;
    defaultErrorMessage?: string;
    overrideModalProps?: Partial<ModalBaseProps> | OverrideModalPropsFunction<T>;
    saveFocusRef?: React.RefObject<HTMLElement>;
    closeOnSave?: boolean;
}
function useGuardedIsSavingState(initialState: boolean) {
    const [state, unsafeSetState] = useState(initialState);
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);
    const setState = useCallback<typeof unsafeSetState>((value) => {
        if (isMounted.current) {
            unsafeSetState(value);
        }
    }, []);

    return [state, setState] as const;
}

/**
 * Modal managed by overlay-manager. Please pair with OverlayButton.
 *   Use overrideModalProps function if you need access to overlayBaseProps.
 *   Will return focus to open button by default,
 *   use cancelFocusRef/saveFocusRef to focus elsewhere.
 */
function Modal<T extends Record<string, unknown>>(props: ModalProps<T>): React.ReactElement | null {
    const {
        autoClose,
        closeOnSave = true,
        buttonElement,
        cancelFocusRef,
        getInitialCustomPropertyState,
        onSave,
        defaultErrorMessage,
        id,
        overrideModalProps,
        saveFocusRef,
        testId,
        onCancel,
        ...modalProps
    } = props;

    const [isSaving, setIsSaving] = useGuardedIsSavingState(false);

    return (
        <OverlayBase<T>
            autoClose={autoClose}
            getInitialCustomPropertyState={getInitialCustomPropertyState}
            id={id}
        >
            {(overlayBaseProps) => {
                const { close: closeOverlay, overlayState } = overlayBaseProps;
                const { customProperties, errors, isLoading, isOpen } = overlayState;
                // IOverlayBaseProps is improperly typed
                const setError = overlayBaseProps.setError as (error: string | string[]) => void;

                function close(closeFocusRef?: React.RefObject<HTMLElement>) {
                    closeOverlay();

                    const refToFocus = closeFocusRef?.current;
                    if (refToFocus) {
                        refToFocus.focus();
                    }
                    // if custom logic is in the onCancel prop,
                    // we need to run it on close.
                    if (onCancel) {
                        onCancel();
                    }
                }

                function createSaveModal() {
                    if (!onSave) {
                        return () => close(saveFocusRef);
                    }

                    return () => {
                        try {
                            setIsSaving(true);
                            const possiblePromise = onSave(customProperties);

                            if (!possiblePromise || !possiblePromise.then) {
                                setIsSaving(false);
                                if (closeOnSave) {
                                    close(saveFocusRef);
                                }
                            } else {
                                possiblePromise
                                    .then(() => {
                                        setIsSaving(false);
                                        close(saveFocusRef);
                                    })
                                    .catch((error) => {
                                        setIsSaving(false);
                                        if (!(error instanceof ModalCancelError)) {
                                            setError(
                                                getErrorMessagesFromErrors(
                                                    error,
                                                    defaultErrorMessage
                                                )
                                            );
                                        }
                                    });
                            }
                        } catch (error) {
                            setIsSaving(false);
                            setError(errorToMessage(error));
                        }
                    };
                }

                // escape hatch to consume overlayBaseProps and override any ModalBase props
                let moreModalProps = overrideModalProps;
                if (typeof overrideModalProps === 'function') {
                    moreModalProps = overrideModalProps(overlayBaseProps);
                }

                return (
                    <>
                        {buttonElement}
                        {isOpen && (
                            <ModalBase
                                {...modalProps}
                                saving={isSaving}
                                errorMessages={errors}
                                isOpen={isOpen}
                                loading={isLoading}
                                onCancel={() => close(cancelFocusRef)}
                                onRequestClose={() => close(cancelFocusRef)}
                                saveModal={createSaveModal()}
                                testId={testId || id}
                                shouldReturnFocusAfterClose={!cancelFocusRef && !saveFocusRef}
                                {...moreModalProps}
                            />
                        )}
                    </>
                );
            }}
        </OverlayBase>
    );
}

export default Modal;

import React from 'react';
import { InlineBanner } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import Modal from '../../../core/overlays/components/Modal';

const errorOverlayId = overlayIdEnum.ERROR_MANUAL_LINEUP_MODAL;
const strings = componentStrings.cases.casePhotoLineups.ComposeLineup;

// If the upload files exceed the max slots of 12 show this error modal
const ExceedManualErrorModal: React.FC = () => {
    return (
        <Modal
            id={errorOverlayId}
            title={strings.uploadImages}
            okText={strings.understood}
            cancelText=""
        >
            <InlineBanner status="error" title="" description={strings.exceedError} />
        </Modal>
    );
};

export default ExceedManualErrorModal;

import { RefContextEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import { get, map, compact, reduce, noop, size } from 'lodash';
import { consortiumDepartmentLinksWhereSelector } from '~/client-common/core/domain/consortium-link-view/state/data';
import { consortiumProfilesSelector } from '~/client-common/core/domain/consortium-profiles/state/data';
import { departmentProfilesSelector } from '~/client-common/core/domain/department-profiles/state/data';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    LOAD_CONSORTIUM_DETAILS_START,
    LOAD_CONSORTIUM_DETAILS_SUCCESS,
    LOAD_CONSORTIUM_DETAILS_FAILURE,
    UPSERT_CONSORTIUM_START,
    UPSERT_CONSORTIUM_SUCCESS,
    UPSERT_CONSORTIUM_FAILURE,
    DELETE_CONSORTIUM_START,
    DELETE_CONSORTIUM_SUCCESS,
    DELETE_CONSORTIUM_FAILURE,
    ADD_DEPARTMENT_TO_CONSORTIUM_START,
    ADD_DEPARTMENT_TO_CONSORTIUM_SUCCESS,
    ADD_DEPARTMENT_TO_CONSORTIUM_FAILURE,
    GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_FAILURE,
    GRANT_PERMISSIONS_START,
    GRANT_PERMISSIONS_SUCCESS,
    GRANT_PERMISSIONS_FAILURE,
    GET_PERMISSION_STATUS_START,
    GET_PERMISSION_STATUS_SUCCESS,
    GET_PERMISSION_STATUS_FAILURE,
    upsertConsortium,
    deleteConsortium,
    addDepartmentToConsortium,
    removeDepartmentFromConsortium,
    grantPermissions,
    getPermissionStatus,
} from '../data';
import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import consortiumAdminAddDepartmentForm from '../forms/consortiumAdminAddDepartmentForm';
import consortiumAdminEditDepartmentForm, {
    convertFromFormModel as convertConsortiumAdminEditDepartmentFormFromFormModel,
} from '../forms/consortiumAdminEditDepartmentForm';
import { currentUserConsortiumIdSelector } from '../../../../core/current-user/state/ui';
import {
    saveBoxFailure,
    saveBoxSuccess,
    openBox,
} from '../../../../../legacy-redux/actions/boxActions';

const initialUiState = {
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,

    grantPermissionStatusError: null,
    grantPermissionStatus: null,
    loadingGrantPermissionStatus: false,
};

export const CONSORTIUM_ADMIN_REMOVE_DEPARTMENT_MODAL_CONTEXT = {
    name: boxEnum.CONSORTIUM_ADMIN_REMOVE_DEPARTMENT_MODAL_CONTEXT,
};

export const CONSORTIUM_ADMIN_ADD_DEPARTMENT_MODAL_CONTEXT = {
    name: boxEnum.CONSORTIUM_ADMIN_ADD_DEPARTMENT_MODAL_CONTEXT,
};

export default makeResettable(
    ENTER_NEW_ROUTE,
    function consortiumAdminUiReducer(state = initialUiState, action) {
        switch (action.type) {
            case LOAD_CONSORTIUM_DETAILS_START:
                return {
                    ...state,
                    loadingList: true,
                    listError: null,
                };
            case LOAD_CONSORTIUM_DETAILS_SUCCESS:
                return {
                    ...state,
                    loadingList: false,
                    listError: null,
                };
            case LOAD_CONSORTIUM_DETAILS_FAILURE:
                return {
                    ...state,
                    loadingList: false,
                    listError: action.payload,
                };

            case GRANT_PERMISSIONS_START:
            case ADD_DEPARTMENT_TO_CONSORTIUM_START:
            case DELETE_CONSORTIUM_START:
            case UPSERT_CONSORTIUM_START:
                return {
                    ...state,
                    submittingForm: true,
                    formError: null,
                };
            case GET_DEPARTMENT_PROFILES_NOT_IN_CONSORTIUM_FAILURE:
            case GRANT_PERMISSIONS_FAILURE:
            case ADD_DEPARTMENT_TO_CONSORTIUM_FAILURE:
            case DELETE_CONSORTIUM_FAILURE:
            case UPSERT_CONSORTIUM_FAILURE:
                return {
                    ...state,
                    submittingForm: false,
                    formError: action.payload,
                };

            case GRANT_PERMISSIONS_SUCCESS:
            case ADD_DEPARTMENT_TO_CONSORTIUM_SUCCESS:
            case DELETE_CONSORTIUM_SUCCESS:
            case UPSERT_CONSORTIUM_SUCCESS:
                return {
                    ...state,
                    submittingForm: false,
                    formError: null,
                };

            case GET_PERMISSION_STATUS_START:
                return {
                    ...state,
                    grantPermissionStatusError: null,
                    grantPermissionStatus: null,
                    loadingGrantPermissionStatus: true,
                };

            case GET_PERMISSION_STATUS_SUCCESS:
                return {
                    ...state,
                    grantPermissionStatusError: null,
                    grantPermissionStatus: action.payload,
                    loadingGrantPermissionStatus: false,
                };

            case GET_PERMISSION_STATUS_FAILURE:
                return {
                    ...state,
                    grantPermissionStatusError: action.payload,
                    grantPermissionStatus: null,
                    loadingGrantPermissionStatus: false,
                };

            default:
                return state;
        }
    }
);

export const uiSelector = (state) => get(state, 'ui.consortiumAdmin');

export const currentConsortiumDepartmentListItems = createSelector(
    currentUserConsortiumIdSelector,
    consortiumDepartmentLinksWhereSelector,
    (currentUserConsortiumId, consortiumDepartmentLinksWhere) => (selectedDepartmentId) =>
        map(
            consortiumDepartmentLinksWhere({ consortiumId: currentUserConsortiumId }),
            (consortiumDepartmentLink) => ({
                path: `/admin/consortium/${consortiumDepartmentLink.consortiumId}/department/${consortiumDepartmentLink.deptId}`,
                title: consortiumDepartmentLink.deptDisplayName,
                key: consortiumDepartmentLink.deptId,
                selected: consortiumDepartmentLink.deptId === selectedDepartmentId,
            })
        )
);

export const consortiumProfileForCurrentUserSelector = createSelector(
    consortiumProfilesSelector,
    currentUserConsortiumIdSelector,
    (consortiumProfiles, currentUserConsortiumId) => consortiumProfiles[currentUserConsortiumId]
);

export function submitConsortiumAdminForm(consortiumId, router) {
    const isCreate = !consortiumId;
    return (dispatch, getState, { formsRegistry }) =>
        formsRegistry
            .get(RefContextEnum.FORM_CONSORTIUM_PROFILE.name)
            .submit()
            .then((result) => {
                const { model } = result.form.getState();
                return dispatch(
                    upsertConsortium({
                        ...model,
                        id: consortiumId,
                    })
                );
            })
            .then(
                (consortiumProfile) =>
                    isCreate && router.push(`/admin/consortium/${get(consortiumProfile, 'id')}`)
            )
            .catch(noop);
}

export function removeConsortium(consortiumId, router) {
    return (dispatch) =>
        dispatch(deleteConsortium(consortiumId))
            .then(() => router.push('/admin/consortium'))
            .catch(noop);
}

export function submitConsortiumAdminAddDepartmentForm(consortiumId) {
    return (dispatch, getState) =>
        consortiumAdminAddDepartmentForm.submit().then((result) => {
            const { model } = result.form.getState();
            const { departmentId } = model;
            const departmentStatusesForConsortium = uniqueDepartmentStatusesForConsortiumByConsortiumIdSelector(
                getState()
            )(consortiumId);
            const currentDepartmentStatus = get(
                departmentProfilesSelector(getState())[departmentId],
                'department.departmentStatus'
            );
            const statusIsDifferent = !departmentStatusesForConsortium[currentDepartmentStatus];

            return statusIsDifferent || size(departmentStatusesForConsortium) > 1
                ? dispatch(
                      openBox(CONSORTIUM_ADMIN_ADD_DEPARTMENT_MODAL_CONTEXT, {
                          addDepartmentToConsortiumData: {
                              consortiumId,
                              departmentId,
                          },
                      })
                  )
                : dispatch(addDepartmentToConsortium(consortiumId, departmentId)).catch(noop);
        });
}

export function addDepartmentViaModal(consortiumId, departmentId) {
    return (dispatch) =>
        dispatch(addDepartmentToConsortium(consortiumId, departmentId))
            .then(() => dispatch(saveBoxSuccess(CONSORTIUM_ADMIN_ADD_DEPARTMENT_MODAL_CONTEXT)))
            .catch((e) =>
                dispatch(saveBoxFailure(CONSORTIUM_ADMIN_ADD_DEPARTMENT_MODAL_CONTEXT, e.message))
            );
}

export function removeDepartmentFromConsortiumViaModal(consortiumId, departmentId, router) {
    return (dispatch) =>
        dispatch(removeDepartmentFromConsortium(consortiumId, departmentId))
            .then(() => {
                router.push('/admin/consortium/');
                dispatch(saveBoxSuccess(CONSORTIUM_ADMIN_REMOVE_DEPARTMENT_MODAL_CONTEXT));
            })
            .catch((e) => {
                dispatch(
                    saveBoxFailure(CONSORTIUM_ADMIN_REMOVE_DEPARTMENT_MODAL_CONTEXT, e.message)
                );
            });
}

const uniquePropertyForConsortiumByConsortiumIdByPropertySelector = createSelector(
    consortiumDepartmentLinksWhereSelector,
    departmentProfilesSelector,
    (consortiumDepartmentLinksWhere, departmentProfiles) => (consortiumId) => (property) =>
        reduce(
            compact(
                map(consortiumDepartmentLinksWhere({ consortiumId }), (consortiumDepartmentLink) =>
                    get(departmentProfiles[consortiumDepartmentLink.deptId], property)
                )
            ),
            (acc, propertyValue) => {
                acc[propertyValue] = true;
                return acc;
            },
            {}
        )
);

export const uniqueTimezonesForConsortiumByConsortiumIdSelector = createSelector(
    uniquePropertyForConsortiumByConsortiumIdByPropertySelector,
    (uniquePropertyForConsortiumByConsortiumIdByProperty) => (consortiumId) =>
        uniquePropertyForConsortiumByConsortiumIdByProperty(consortiumId)('timeZone')
);

const uniqueDepartmentStatusesForConsortiumByConsortiumIdSelector = createSelector(
    uniquePropertyForConsortiumByConsortiumIdByPropertySelector,
    (uniquePropertyForConsortiumByConsortiumIdByProperty) => (consortiumId) =>
        uniquePropertyForConsortiumByConsortiumIdByProperty(consortiumId)(
            'department.departmentStatus'
        )
);

export function submitConsortiumAdminEditDepartmentForm() {
    return (dispatch) =>
        consortiumAdminEditDepartmentForm
            .submit()
            .then((result) =>
                dispatch(
                    grantPermissions(
                        convertConsortiumAdminEditDepartmentFormFromFormModel(
                            result.form.getState().model
                        )
                    )
                )
            )
            .catch(noop);
}

export function refreshPermissionStatus(departmentId) {
    return (dispatch) => dispatch(getPermissionStatus(departmentId)).catch(noop);
}

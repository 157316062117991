import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'preferences';

export default createResource({
    name: 'Cad Preference Resource',
    methods: {
        getDepartmentPreferencesAdmin() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/admin/department`,
            });
        },
        upsertDepartmentPreferenceLink(preferenceLink) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/link/department`,
                data: preferenceLink,
            });
        },
        upsertDepartmentPreference(departmentPreference) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/department`,
                data: departmentPreference,
            });
        },
    },
});

import { get } from 'lodash';

import {
    custodialResponsibleOfficersSelector,
    updateCustodialResponsibleOfficer,
} from '~/client-common/core/domain/custodial-responsible-officers/state/data';
import validationStrings from '~/client-common/core/strings/validationStrings';

import createCard from '../../../core/utils/createCard';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import responsibleOfficerForm from '../forms/responsibleOfficerForm';
import { responsibleOfficerSelector } from '.';

const baseCard = createCard({
    name: 'PERSONNEL',
    baseSelector: (state) => state.ui.report.personnelCard,
    anchor: 'personnel-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch, getState) => {
                const state = getState();
                const custodialResponsibleOfficerId = get(responsibleOfficerSelector(state), 'id');
                const custodialResponsibleOfficerModel = custodialResponsibleOfficersSelector(
                    state
                )[custodialResponsibleOfficerId];
                dispatch(
                    responsibleOfficerForm.actionCreators.change(custodialResponsibleOfficerModel)
                );
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save() {
            return (dispatch, getState) => {
                return dispatch(
                    responsibleOfficerForm.actionCreators.submit((formModel) => {
                        const state = getState();
                        const currentCustodialResponsibleOfficerId = get(
                            responsibleOfficerSelector(state),
                            'id'
                        );
                        const reportId = currentReportIdSelector(state);
                        const updatedCustodialResponsibleOfficer = responsibleOfficerForm.convertFromFormModel(
                            reportId,
                            currentCustodialResponsibleOfficerId,
                            formModel
                        );
                        dispatch(baseCard.actionCreators.savingStart());
                        return dispatch(
                            updateCustodialResponsibleOfficer(updatedCustodialResponsibleOfficer)
                        ).then(() => {
                            dispatch(baseCard.actionCreators.savingSuccess());
                        });
                    })
                ).catch((error) => {
                    let errorMessage = [validationStrings.panel.generic];

                    // remote errors
                    if (error && error.message) {
                        errorMessage.push(error.message);
                    } else if (Array.isArray(error)) {
                        errorMessage = errorMessage.concat(error);
                    }

                    dispatch(baseCard.actionCreators.savingFailure(errorMessage));
                });
            };
        },
    },
    selectors: {
        ...baseCard.selectors,
    },
};

import { RefContextEnum } from '@mark43/rms-api';

import baseCourtOrderFormFieldViewModels from '../../../core/state/forms/baseCourtOrderFormFieldViewModels';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const fieldViewModels = {
    ...buildFlatFormFieldViewModels([{ key: 'chargeIdsToSeal' }]),
    ...baseCourtOrderFormFieldViewModels,
};

export default createFormModule({
    context: RefContextEnum.FORM_COURT_ORDER_EDIT.name,
    fieldViewModels,
});

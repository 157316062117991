import { useContext } from 'react';
import { EFileContext } from '../contexts/eFileContext';
import { EFileContextT } from '../types';

export function useEFileContext(): EFileContextT {
    const context = useContext(EFileContext);

    if (context === undefined) {
        throw new Error('EFile Context must be inside a EFileContextProvider with a value');
    }
    return {
        getters: context.getters,
        actions: context.actions,
        handlers: context.handlers,
    };
}

import React from 'react';
import styled from 'styled-components';
import { Caution as ArcCaution, cssVar } from 'arc';
import { Caution, ElasticCaution } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DESCRIPTION,
    CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_PROVENANCE,
} from '~/client-common/core/enums/universal/fields';

import testIds from '../../../../core/testIds';
import { useDateTimeFormatter } from '../../current-user/hooks/dateTimeFormats';
import { ConditionalTooltip } from '../../components/tooltip';
import { EmDash } from '../../components/HtmlEntities';
import { CautionComputedData } from '../configuration';

const labels = componentStrings.core.cautions.tooltip;

type EnhancedCautionProps = CautionComputedData & {
    caution?: Caution | ElasticCaution;
    showTooltip?: boolean;
    className?: string;
};

const TooltipContentLayout = styled.div`
    padding: ${cssVar('arc.space.2')};
    display: grid;
    grid-template-columns: minmax(80px, 30%) auto;
    gap: ${cssVar('arc.space.2')};
`;

const EnhancedCautionTooltipContent: React.FC<{
    caution: Caution | ElasticCaution;
}> = ({ caution: { dateEffectiveFrom, dateEffectiveTo, description, provenance } }) => {
    const dateFormatter = useDateTimeFormatter();
    const {
        CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DESCRIPTION: descriptionLabel,
        CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_PROVENANCE: provenanceLabel,
    } = useFields([
        CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DESCRIPTION,
        CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_PROVENANCE,
    ]);

    return (
        <TooltipContentLayout data-test-id={testIds.ENHANCED_CAUTION_TOOLTIP}>
            <span>{`${labels.effective}:`}</span>
            <span>
                {dateEffectiveFrom || dateEffectiveTo ? (
                    labels.effectiveDates(
                        dateFormatter.formatDate(dateEffectiveFrom),
                        dateFormatter.formatDate(dateEffectiveTo)
                    )
                ) : (
                    <EmDash />
                )}
            </span>
            <span>{`${descriptionLabel}:`}</span>
            <span>{description ?? <EmDash />}</span>
            <span>{`${provenanceLabel}:`}</span>
            <span>{provenance ?? <EmDash />}</span>
        </TooltipContentLayout>
    );
};

export const EnhancedCaution: React.FC<EnhancedCautionProps> = ({
    label,
    priority,
    caution,
    showTooltip = false,
    className,
}) => {
    const showCautionTooltip = showTooltip && !!caution;
    const tooltipContent = caution ? <EnhancedCautionTooltipContent caution={caution} /> : null;

    return (
        <ConditionalTooltip
            condition={showCautionTooltip}
            content={tooltipContent}
            maxWidth="280px"
        >
            <ArcCaution
                priority={priority}
                className={className}
                data-test-id={testIds.ENHANCED_CAUTION}
            >
                {label}
            </ArcCaution>
        </ConditionalTooltip>
    );
};

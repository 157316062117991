import { AttributeTypeEnum, ComplianceGroupEnum, RefContextEnum } from '@mark43/rms-api';
import _, { filter, get, noop } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { Fieldset as MFTFieldset, Observer } from 'markformythree';
import { Box } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { applyStatuteCodeSetFilterToFlags } from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';
import { saveReportComment } from '~/client-common/core/domain/report-comments/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_CHILD_LABEL } from '~/client-common/core/enums/universal/fields';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import { ArbiterMFTYesNoUnknownButtonRadio } from '../../../../core/forms/components/button-radios/YesNoUnknownButtonRadio';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import { ArbiterMFTCheckbox } from '../../../../core/forms/components/checkboxes/Checkbox';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTOffenseCodeSelect } from '../../../../core/forms/components/selects/OffenseCodeSelect';
import { ArbiterMFTItemInvolvementTypeSelect } from '../../../../core/forms/components/selects/ItemInvolvementTypeSelect';
import { ArbiterMFTNibrsOffenseCodeIdSelect } from '../../../../core/forms/components/selects/NibrsCodeSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTBooleanButtonRadio } from '../../../../core/forms/components/button-radios/BooleanButtonRadio';
import { MFTNItems } from '../../../../core/forms/components/NItems';
import { CardSection, CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import WithComplianceGroup from '../../../../core/components/WithComplianceGroup';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import {
    currentUserDepartmentProfileSelector,
    currentUserHasAbilitySelector,
} from '../../../../core/current-user/state/ui';
import withItemCategoryFilterOptions from '../items/fieldsets/withItemCategoryFilterOptions';
import {
    autofillDateTypeEnum,
    MFTAutofillDateButton,
} from '../../../../core/components/AutofillDateButton';
import { openOffenseCodeChangeReasonModal } from '../../state/ui';
import { ArbiterMFTStatuteCodeSetRadio } from '../StatuteCodeSetRadio';
import { ArbiterMFTFilteredNibrsOffenseCodeIdSelect } from '../../../../core/forms/components/selects/FilteredNibrsCodeSelect';
import { ArbiterMFTAttributeMultiSelectButtonRadio } from '../../../../core/forms/components/button-radios/AttributeMultiSelectButtonRadio';
import { latestReportStatusHistoryForCurrentReport } from '../../../../../legacy-redux/selectors/reportSelectors';
import { Tooltip } from '../../../../core/components/tooltip';
import { UkOffenseCodeCategorySelect } from '../../../../core/forms/components/selects/UkOffenseCodeCategorySelect';
import { FormConfigurationRenderer } from '../../../../core/markformythree-arbiter/dynamic-fields/components/FormConfigurationRenderer';
import {
    CUSTOM_PROPERTIES_KEY_NAME,
    TNIBRS_PROPERTY_NAME,
} from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';

const strings = componentStrings.reports.core.OffenseCard;

const actionAtSceneAttrName = AttributeTypeEnum.ACTION_AT_SCENE.name;
const biasAttrName = AttributeTypeEnum.BIAS_MOTIVATION.name;
const aggAssaultAttrName = AttributeTypeEnum.AGGRAVATED_ASSAULT_CIRCUMSTANCE.name;
const abuseTypeAttrName = AttributeTypeEnum.ABUSE_TYPE.name;
const policeActionAttrName = AttributeTypeEnum.POLICE_ACTION.name;
const reasonForNoArrestAttrName = AttributeTypeEnum.REASON_FOR_NO_ARREST.name;
const weaponForceAttrName = AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name;
const previousComplaintsAttrName = AttributeTypeEnum.PREVIOUS_COMPLAINTS.name;
const protectionOrderStatusAttrName = AttributeTypeEnum.PROTECTION_ORDER_STATUS.name;
const protectionOrderTypeAttrName = AttributeTypeEnum.PROTECTION_ORDER_TYPE.name;
const priorCourtOrdersAttrName = AttributeTypeEnum.PRIOR_COURT_ORDERS.name;
const justifiableHomicideAttrName = AttributeTypeEnum.JUSTIFIABLE_HOMICIDE_CIRCUMSTANCE.name;
const negligentManslaughterAttrName = AttributeTypeEnum.NEGLIGENT_MANSLAUGHTER_CIRCUMSTANCE.name;
const homicideAttrName = AttributeTypeEnum.HOMICIDE_CIRCUMSTANCE.name;
const criminalActivityAttrName = AttributeTypeEnum.CRIMINAL_ACTIVITY_CATEGORY.name;
const animalCrueltyAttrName = AttributeTypeEnum.ANIMAL_CRUELTY_CATEGORY.name;
const gangInfoAttrName = AttributeTypeEnum.GANG_INFORMATION.name;
const moAttrName = AttributeTypeEnum.MODUS_OPERANDI.name;
const contributingFactorsAttrName = AttributeTypeEnum.OFFENDER_CONTRIBUTING_FACTORS.name;
const routeOfApproachAttrName = AttributeTypeEnum.ROUTE_OF_APPROACH.name;
const typeOfSearchConductedByOffenderAttrName =
    AttributeTypeEnum.TYPE_OF_SEARCH_CONDUCTED_BY_OFFENDER.name;
const implementWeaponInvolvedAttrName = AttributeTypeEnum.IMPLEMENT_WEAPON_INVOLVED.name;
const secSystemAttrName = AttributeTypeEnum.SECURITY_SYSTEM.name;
const offenseStatAttrName = AttributeTypeEnum.OFFENSE_STATISTIC.name;
const itemCategoryName = AttributeTypeEnum.ITEM_CATEGORY.name;
const hateCrimeOffensiveActAttrName = AttributeTypeEnum.HATE_CRIME_OFFENSIVE_ACT.name;
const entryPointAttrName = AttributeTypeEnum.ENTRY_POINT.name;
const biasMotivationAttrName = AttributeTypeEnum.NJ_BIAS_DESCRIPTION.name;
const vehicleEntryPointAttrName = AttributeTypeEnum.VEHICLE_ENTRY_POINT.name;
const directionOfEntry1AttrName = AttributeTypeEnum.DIRECTION_OF_ENTRY_1.name;
const directionOfEntry2AttrName = AttributeTypeEnum.DIRECTION_OF_ENTRY_2.name;
const larcenyTypeAttrName = AttributeTypeEnum.LARCENY_TYPE.name;
const internetInvolvementTypeAttrName = AttributeTypeEnum.INTERNET_INVOLVEMENT_TYPE.name;
const suspectedMetalTheftAttrName = AttributeTypeEnum.SUSPECTED_METAL_THEFT.name;
const meansOfApproachAttrName = AttributeTypeEnum.MEANS_OF_APPROACH.name;
const methodOfEntryExitAttrName = AttributeTypeEnum.METHOD_OF_ENTRY_EXIT.name;
const additionalOtherInfoAttrName = AttributeTypeEnum.ADDITIONAL_OTHER_INFORMATION.name;
const vehicleMoAttrName = AttributeTypeEnum.VEHICLE_MO.name;
const honorBasedAbuseTypeAttrName = AttributeTypeEnum.HONOR_BASED_ABUSE_TYPE.name;
const pointOfEntryExitAttrName = AttributeTypeEnum.POINT_OF_ENTRY_EXIT.name;
const distractionFraudActivityAttrName = AttributeTypeEnum.DISTRACTION_FRAUD_ACTIVITY.name;
const safeguardingMoAttrName = AttributeTypeEnum.SAFEGUARDING_MO.name;
const drugInvolvementAttrName = AttributeTypeEnum.DRUG_INVOLVEMENT.name;
const offenseSuspectedAlcoholUseAttrName =
    AttributeTypeEnum.EXTENDED_OFFENSE_SUSPECTED_ALCOHOL_USE.name;
const otherMoAttrName = AttributeTypeEnum.OTHER_MODUS_OPERANDI.name;

const AttributeRadioSelectFieldset = ({
    path,
    attributeType,
    multiple,
    valueAsArray,
    isRequired,
    onChange = noop,
}) => (
    <MFTFieldset path={path}>
        <Row>
            <ArbiterMFTAttributeMultiSelectButtonRadio
                path="attributeIds"
                attributeType={attributeType}
                valueAsArray={valueAsArray}
                multiple={multiple}
                onChange={onChange}
                isRequired={isRequired}
            />
        </Row>
    </MFTFieldset>
);

const AttributeSelectFieldset = ({ path, attributeType, grouped, onChange = noop }) => (
    <MFTFieldset path={path}>
        <Row>
            <ArbiterMFTAttributeSelect
                path="attributeIds"
                attributeType={attributeType}
                multiple={true}
                grouped={grouped}
                onChange={onChange}
            />
            <IndentedFields>
                <Row>
                    <ArbiterMFTText path="description" length="lg" />
                </Row>
            </IndentedFields>
        </Row>
    </MFTFieldset>
);

const FullOffenseForm = ({
    onOffenseCompletedChange,
    dispatch,
    autoFocus,
    form,
    setNibrsAllowedProperty = noop,
    setItemInvolvement = noop,
    nibrsOffenseCodes,
    hasNibrsCodingAbility,
    isNibrsDept,
    itemCategoryFilterOptions,
    offenseCodeFlags = [],
    formIndex,
    reportId,
    onWeaponForceChange,
    nibrsAllowedProperty,
    ukOffenseCodeCategoryExternalIsLoading,
    initialState,
    onSave,
}) => {
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const complianceGroup = useSelector(currentUserDepartmentProfileSelector)?.complianceGroup;
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const latestHistory = useSelector(latestReportStatusHistoryForCurrentReport);

    const offenseCompletedOptions = [
        {
            value: true,
            display: strings.wasCompleted.trueDisplay,
        },
        {
            value: false,
            display: strings.wasCompleted.falseDisplay,
        },
    ];
    const arsonBuildingInhabitedOptions = [
        {
            value: true,
            display: strings.arsonBuildingInhabited.trueDisplay,
        },
        {
            value: false,
            display: strings.arsonBuildingInhabited.falseDisplay,
        },
    ];

    const handleNibrsOffenseCodeIdChange = (value) => {
        form.set('offense.nibrsOffenseCodeId', value);
        const nibrsCodeCode = get(nibrsOffenseCodes[value], 'code');
        form.set('offense.nibrsCodeCode', nibrsCodeCode);
        setNibrsAllowedProperty();
    };

    const handleItemInvolvementChange = (value) => {
        form.set('offense.itemInvolvementType', value);
        setNibrsAllowedProperty();
        setItemInvolvement(value);
    };

    const handleHomeOfficeClassificationChange = () => {
        if (complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name && !!latestHistory) {
            dispatch(
                openOffenseCodeChangeReasonModal({
                    callback: (reasonForOffenseCodeChange) => {
                        dispatch(saveReportComment(reportId, reasonForOffenseCodeChange));
                        onSave();
                    },
                    cancel: () => {
                        const previousCategoryCodeId = _.get(
                            initialState,
                            'offenseAttributes.UK_OFFENSE_CODE_CATEGORY.attributeIds'
                        );
                        form.set('offense.offenseCodeId', initialState.offense.offenseCodeId);
                        form.set(
                            'offenseAttributes.UK_OFFENSE_CODE_CATEGORY.attributeIds',
                            previousCategoryCodeId
                        );
                    },
                })
            );
        }
    };

    const hasAbilityToEditOffenseCode = currentUserHasAbility(
        abilitiesEnum.REPORTING.EDIT_HOME_OFFICE_CLASSIFICATION_CODE
    );

    const disabledOffenseCode =
        complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name &&
        !!latestHistory &&
        !hasAbilityToEditOffenseCode;

    const removeOtherOptionFilter = (options) => {
        return filter(options, (option) => !option?.other);
    };

    const offenseCodeIdField = ({ statuteCodeSetFilter }) => (
        <ArbiterMFTOffenseCodeSelect
            path="offenseCodeId"
            clearable={false}
            flags={applyStatuteCodeSetFilterToFlags(statuteCodeSetFilter, offenseCodeFlags)}
            statuteCodeSetFilter={statuteCodeSetFilter}
            autoFocus={autoFocus}
            disabled={disabledOffenseCode}
        />
    );

    const disabledOffenseCodeField = ({ statuteCodeSetFilter }) => (
        <Tooltip side="top" content={strings.offenseCodeChangeWorkflow.errorMessage}>
            <Box gridColumn="span 9">
                <ArbiterMFTOffenseCodeSelect
                    path="offenseCodeId"
                    clearable={false}
                    flags={applyStatuteCodeSetFilterToFlags(statuteCodeSetFilter, offenseCodeFlags)}
                    statuteCodeSetFilter={statuteCodeSetFilter}
                    autoFocus={autoFocus}
                    disabled={disabledOffenseCode}
                />
            </Box>
        </Tooltip>
    );

    const disabledOffenseCodeCategory = ({ offenseCodeId }) => (
        <Tooltip side="top" content={strings.offenseCodeChangeWorkflow.errorMessage}>
            <Box gridColumn="span 9">
                <UkOffenseCodeCategorySelect
                    externalIsLoading={ukOffenseCodeCategoryExternalIsLoading}
                    offenseCodeId={offenseCodeId}
                    path="UK_OFFENSE_CODE_CATEGORY.attributeIds"
                    disabled={disabledOffenseCode}
                />
            </Box>
        </Tooltip>
    );

    return (
        <CardSection>
            <Row>
                <ArbiterMFTStatuteCodeSetRadio
                    form={form}
                    offenseCodeIdPath="offense.offenseCodeId"
                />
            </Row>
            <MFTFieldset
                path="offense"
                render={() => (
                    <div>
                        <Row>
                            {!disabledOffenseCode ? (
                                <Observer
                                    subscriptions={{
                                        statuteCodeSetFilter: 'statuteCodeSetFilter',
                                    }}
                                    render={offenseCodeIdField}
                                />
                            ) : (
                                <Observer
                                    subscriptions={{
                                        statuteCodeSetFilter: 'statuteCodeSetFilter',
                                    }}
                                    render={disabledOffenseCodeField}
                                />
                            )}
                        </Row>
                        {isNibrsDept ? (
                            <Row>
                                <Observer
                                    subscriptions={{
                                        offenseCodeId: 'offense.offenseCodeId',
                                    }}
                                    render={({ offenseCodeId }) => {
                                        return hasNibrsCodingAbility ? (
                                            <ArbiterMFTNibrsOffenseCodeIdSelect
                                                path="nibrsOffenseCodeId"
                                                clearable={false}
                                                onChange={handleNibrsOffenseCodeIdChange}
                                            />
                                        ) : (
                                            <ArbiterMFTFilteredNibrsOffenseCodeIdSelect
                                                path="nibrsOffenseCodeId"
                                                offenseCodeId={offenseCodeId}
                                                clearable={false}
                                                onChange={handleNibrsOffenseCodeIdChange}
                                            />
                                        );
                                    }}
                                />
                            </Row>
                        ) : undefined}
                    </div>
                )}
            />
            <MFTFieldset
                path="offenseAttributes"
                render={() => (
                    <Row>
                        {!disabledOffenseCode ? (
                            <Observer
                                subscriptions={{ offenseCodeId: 'offense.offenseCodeId' }}
                                render={({ offenseCodeId }) => (
                                    <UkOffenseCodeCategorySelect
                                        externalIsLoading={ukOffenseCodeCategoryExternalIsLoading}
                                        offenseCodeId={offenseCodeId}
                                        onChange={handleHomeOfficeClassificationChange}
                                        path="UK_OFFENSE_CODE_CATEGORY.attributeIds"
                                    />
                                )}
                            />
                        ) : (
                            <Observer
                                subscriptions={{ offenseCodeId: 'offense.offenseCodeId' }}
                                render={disabledOffenseCodeCategory}
                            />
                        )}
                    </Row>
                )}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTText path="nfibNumber" length="lg" />
                        </Row>
                    </IndentedFields>
                )}
            />
            <MFTFieldset
                path="links"
                render={() => (
                    <Row>
                        <ArbiterMFTOffenseCodeSelect path="subCrimeIds" multiple={true} />
                    </Row>
                )}
            />
            <MFTFieldset
                path="offenseAttributes"
                render={() => (
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="LARCENY_TYPE.attributeIds"
                                attributeType={larcenyTypeAttrName}
                                multiple={false}
                                length="lg"
                            />
                        </Row>
                    </IndentedFields>
                )}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <div>
                        <Row>
                            <ArbiterMFTBooleanSelect
                                path="isOffenseDateUnknown"
                                clearable={false}
                                invertValues={true}
                                length="md"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTDatePicker path="offenseDateUtc" includeTime={true} />
                            <ArbiterMFTDatePicker path="offenseEndDateUtc" includeTime={true} />
                            <MFTAutofillDateButton
                                onClick={(_e, { eventStartUtc, eventEndUtc }) => {
                                    form.set('offense.offenseDateUtc', eventStartUtc);
                                    form.set('offense.offenseEndDateUtc', eventEndUtc);
                                }}
                                autofillDateType={autofillDateTypeEnum.START_END_DATE_TIME}
                                startDatePath="offenseDateUtc"
                                endDatePath="offenseEndDateUtc"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTDatePicker
                                path="crimeReportedOffenseDateUtc"
                                includeTime={true}
                            />
                            <ArbiterMFTDatePicker
                                path="crimeReportedOffenseEndDateUtc"
                                includeTime={true}
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="includesCargoTheft" length="md" />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio path="wasCarjackingInvolved" />
                        </Row>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTBooleanButtonRadio path="wasVehicleBumpedRobbed" />
                            </Row>
                            <Row>
                                <ArbiterMFTBooleanButtonRadio path="wasWitnessPresent" />
                            </Row>
                            <Row>
                                <ArbiterMFTBooleanButtonRadio path="wasShootingInvolved" />
                            </Row>
                        </IndentedFields>
                        <Row>
                            <ArbiterMFTBooleanSelect
                                path="wasCompleted"
                                options={offenseCompletedOptions}
                                length="md"
                                onChange={onOffenseCompletedChange}
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTItemInvolvementTypeSelect
                                path="itemInvolvementType"
                                allowedPropertyLossNibrsCodes={get(
                                    nibrsAllowedProperty,
                                    'allowedPropertyLossNibrsCodes'
                                )}
                                onChange={handleItemInvolvementChange}
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="isSuspectedHateCrime" length="md" />
                        </Row>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    path="biasDescriptionAttrId"
                                    attributeType={biasMotivationAttrName}
                                    length="md"
                                />
                            </Row>
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTText path="biasDescriptionOther" length="md" />
                                </Row>
                            </IndentedFields>
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    path={'hateCrimeActAttrId'}
                                    attributeType={hateCrimeOffensiveActAttrName}
                                    length="md"
                                />
                            </Row>
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTText path="hateCrimeActOther" length="md" />
                                </Row>
                            </IndentedFields>
                            <Row>
                                <ArbiterMFTYesNoUnknownButtonRadio path="evidenceAvailableAttrId" />
                            </Row>
                            <Row>
                                <ArbiterMFTYesNoUnknownButtonRadio path="objectsSymbolsPresentAttrId" />
                            </Row>
                            <Row>
                                <ArbiterMFTText path="numberOfWeaponsInvolved" length="md" />
                            </Row>
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    path="policeActionAttrId"
                                    attributeType={AttributeTypeEnum.POLICE_ACTION.name}
                                    length="md"
                                    filterOptions={removeOtherOptionFilter}
                                />
                            </Row>
                        </IndentedFields>
                    </div>
                )}
            />
            <MFTFieldset
                path="offenseAttributes"
                render={() => (
                    <div>
                        <IndentedFields>
                            <Row>
                                <AttributeSelectFieldset
                                    path={biasAttrName}
                                    attributeType={biasAttrName}
                                    grouped={true}
                                />
                            </Row>
                        </IndentedFields>
                        <Row>
                            <AttributeSelectFieldset
                                path={aggAssaultAttrName}
                                attributeType={aggAssaultAttrName}
                            />
                        </Row>
                        <WithComplianceGroup
                            complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                            fallback={
                                <Row>
                                    <AttributeSelectFieldset
                                        path={actionAtSceneAttrName}
                                        attributeType={actionAtSceneAttrName}
                                    />
                                </Row>
                            }
                        />
                    </div>
                )}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <>
                        <Row>
                            <ArbiterMFTBooleanSelect path="isDomesticViolence" length="md" />
                        </Row>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTBooleanSelect path="hasProtectionOrder" length="md" />
                            </Row>
                        </IndentedFields>
                        <IndentedFields>
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="protectionOrderStatusAttrId"
                                        attributeType={protectionOrderStatusAttrName}
                                        length="md"
                                    />
                                </Row>
                                <Row>
                                    <IndentedFields>
                                        <ArbiterMFTText
                                            path="protectionOrderStatusOther"
                                            length="md"
                                        />
                                    </IndentedFields>
                                </Row>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="protectionOrderTypeAttrId"
                                        attributeType={protectionOrderTypeAttrName}
                                        length="md"
                                    />
                                </Row>
                                <Row>
                                    <IndentedFields>
                                        <ArbiterMFTText
                                            path="protectionOrderTypeOther"
                                            length="md"
                                        />
                                    </IndentedFields>
                                </Row>
                                <Row>
                                    <ArbiterMFTText
                                        path="protectionOrderIssuingCourt"
                                        length="md"
                                    />
                                </Row>
                                <Row>
                                    <ArbiterMFTText
                                        path="protectionOrderDocketNumber"
                                        length="md"
                                    />
                                </Row>
                            </IndentedFields>
                        </IndentedFields>
                    </>
                )}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTBooleanSelect path="childrenPresent" length="md" />
                            <IndentedFields>
                                <VisibilityObserver
                                    path="offense.offenseInvolvedChildren"
                                    formName={RefContextEnum.FORM_OFFENSE.name}
                                    formIndex={formIndex}
                                    render={({ hidden }) =>
                                        !hidden ? (
                                            <MFTNItems
                                                path="offenseInvolvedChildren"
                                                addItemOnEmpty={true}
                                                addText={formatFieldByName(
                                                    DISPLAY_ONLY_CHILD_LABEL
                                                )}
                                                hideAddButtonOnEmptyItem={true}
                                                childFieldKeys={['childName', 'childAge']}
                                                autoFocusFirstInputOnAdd={true}
                                                render={() => (setFirstInputRef) => (
                                                    <Row>
                                                        <ArbiterMFTText
                                                            path="childName"
                                                            length="md"
                                                            setRef={setFirstInputRef}
                                                        />
                                                        <ArbiterMFTText
                                                            path="childAge"
                                                            length="sm"
                                                            maxLength={3}
                                                        />
                                                    </Row>
                                                )}
                                            />
                                        ) : null
                                    }
                                />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="childrenInvolved" length="md" />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="previousComplaintsAttrId"
                                attributeType={previousComplaintsAttrName}
                            />
                        </Row>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTText
                                    path="previousComplaintsOtherDescription"
                                    length="lg"
                                />
                            </Row>
                        </IndentedFields>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="priorCourtOrdersAttrId"
                                attributeType={priorCourtOrdersAttrName}
                            />
                        </Row>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTText
                                    path="priorCourtOrdersOtherDescription"
                                    length="lg"
                                />
                            </Row>
                        </IndentedFields>
                    </IndentedFields>
                )}
            />
            <MFTFieldset
                path="offenseAttributes"
                render={() => (
                    <IndentedFields>
                        <AttributeSelectFieldset
                            path={abuseTypeAttrName}
                            attributeType={abuseTypeAttrName}
                        />
                        <AttributeSelectFieldset
                            path={policeActionAttrName}
                            attributeType={policeActionAttrName}
                        />
                    </IndentedFields>
                )}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <IndentedFields>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    path="reasonForNoArrestAttrId"
                                    attributeType={reasonForNoArrestAttrName}
                                />
                            </Row>
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTText
                                        path="reasonForNoArrestOtherDescription"
                                        length="lg"
                                    />
                                </Row>
                            </IndentedFields>
                        </IndentedFields>
                    </IndentedFields>
                )}
            />
            <WithComplianceGroup
                complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                fallback={
                    <MFTFieldset
                        path="offenseAttributes"
                        render={() => (
                            <div>
                                <AttributeSelectFieldset
                                    grouped={
                                        complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name
                                    }
                                    path={weaponForceAttrName}
                                    attributeType={weaponForceAttrName}
                                    onChange={onWeaponForceChange}
                                />
                                <AttributeSelectFieldset
                                    grouped={true}
                                    path={implementWeaponInvolvedAttrName}
                                    attributeType={implementWeaponInvolvedAttrName}
                                />
                                <AttributeSelectFieldset
                                    path={meansOfApproachAttrName}
                                    attributeType={meansOfApproachAttrName}
                                />
                                <AttributeSelectFieldset
                                    path={methodOfEntryExitAttrName}
                                    attributeType={methodOfEntryExitAttrName}
                                />
                                <AttributeSelectFieldset
                                    path={pointOfEntryExitAttrName}
                                    attributeType={pointOfEntryExitAttrName}
                                />
                            </div>
                        )}
                    />
                }
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <div>
                        <Row>
                            <ArbiterMFTBooleanSelect
                                path="burgWasMethodOfEntryForced"
                                length="md"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="entryPointAttrId"
                                attributeType={entryPointAttrName}
                                length="md"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="vehicleEntryPointAttrId"
                                attributeType={vehicleEntryPointAttrName}
                                length="md"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="directionOfEntry1AttrId"
                                attributeType={directionOfEntry1AttrName}
                                length="md"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="directionOfEntry2AttrId"
                                attributeType={directionOfEntry2AttrName}
                                length="md"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect
                                path="arsonBuildingInhabited"
                                options={arsonBuildingInhabitedOptions}
                                length="md"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="justifiableHomicideAttrId"
                                attributeType={justifiableHomicideAttrName}
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTCheckbox path="wasCriminalKilledByOfficer" />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="negligentManslaughterAttrId"
                                attributeType={negligentManslaughterAttrName}
                            />
                        </Row>
                    </div>
                )}
            />
            <MFTFieldset
                path="offenseAttributes"
                render={() => (
                    <div>
                        <AttributeSelectFieldset
                            path={homicideAttrName}
                            attributeType={homicideAttrName}
                        />
                        <AttributeSelectFieldset
                            path={criminalActivityAttrName}
                            attributeType={criminalActivityAttrName}
                        />
                        <AttributeSelectFieldset
                            path={animalCrueltyAttrName}
                            attributeType={animalCrueltyAttrName}
                        />
                        <AttributeSelectFieldset
                            path={gangInfoAttrName}
                            attributeType={gangInfoAttrName}
                        />
                    </div>
                )}
            />

            <MFTFieldset
                path="offense"
                render={() => (
                    <div>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio path="hasStreetGang" />
                        </Row>
                    </div>
                )}
            />
            <FormConfigurationRenderer
                form={form}
                absoluteFormPath={`offense.${CUSTOM_PROPERTIES_KEY_NAME}`}
                mode="FORM"
                __fieldsToInclude={[
                    TNIBRS_PROPERTY_NAME.GANG_NAME_1,
                    TNIBRS_PROPERTY_NAME.TYPE_OF_GANG_1_ATTR_ID,
                    TNIBRS_PROPERTY_NAME.GANG_NAME_2,
                    TNIBRS_PROPERTY_NAME.TYPE_OF_GANG_2_ATTR_ID,
                    TNIBRS_PROPERTY_NAME.IS_USE_OF_FORCE,
                    TNIBRS_PROPERTY_NAME.USE_OF_FORCE_NUMBER,
                    TNIBRS_PROPERTY_NAME.IS_HOME_INVASION,
                    TNIBRS_PROPERTY_NAME.IS_IDENTITY_THEFT,
                    TNIBRS_PROPERTY_NAME.IS_CYBERCRIME,
                ]}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <div>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio path="hasOtherOrganizedGroup" />
                        </Row>
                        <Row>
                            <ArbiterMFTYesNoUnknownButtonRadio path="suspectedInternetCyberInvolvementAttrId" />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio
                                path="suspectedComputerUse"
                                deselectable={false}
                            />
                        </Row>
                    </div>
                )}
            />
            <WithComplianceGroup
                complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                fallback={
                    <MFTFieldset
                        path="offenseAttributes"
                        render={() => (
                            <div>
                                <IndentedFields>
                                    <AttributeSelectFieldset
                                        path={internetInvolvementTypeAttrName}
                                        attributeType={internetInvolvementTypeAttrName}
                                        grouped={true}
                                    />
                                </IndentedFields>
                                <ArbiterMFTAttributeSelect
                                    path="DRUG_INVOLVEMENT.attributeIds"
                                    attributeType={drugInvolvementAttrName}
                                    multiple={false}
                                    length="md"
                                />
                            </div>
                        )}
                    />
                }
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <div>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio path="isSuspectedChildSexualAbuse" />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio path="isSuspectedChildSexualExploitation" />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio path="isSuspectedCorrosiveBasedOffense" />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio path="isHonourBasedOffence" />
                        </Row>
                    </div>
                )}
            />
            <MFTFieldset
                path="offenseAttributes"
                render={() => (
                    <div>
                        <IndentedFields>
                            <AttributeSelectFieldset
                                path={honorBasedAbuseTypeAttrName}
                                attributeType={honorBasedAbuseTypeAttrName}
                            />
                        </IndentedFields>
                    </div>
                )}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <div>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio path="isSuspectedOffenseAgainstWomenOrGirls" />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio path="isSuspectedModernSlavery" />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanButtonRadio
                                path="suspectedAlcoholUse"
                                deselectable={false}
                            />
                        </Row>
                    </div>
                )}
            />
            <MFTFieldset
                path="offenseAttributes"
                render={() => (
                    <Row>
                        <AttributeSelectFieldset
                            path={offenseSuspectedAlcoholUseAttrName}
                            attributeType={offenseSuspectedAlcoholUseAttrName}
                        />
                    </Row>
                )}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <Row>
                        <ArbiterMFTBooleanButtonRadio
                            path="suspectedDrugUse"
                            deselectable={false}
                        />
                    </Row>
                )}
            />
            <FormConfigurationRenderer
                form={form}
                absoluteFormPath={`offense.${CUSTOM_PROPERTIES_KEY_NAME}`}
                mode="FORM"
                __fieldsToInclude={[
                    TNIBRS_PROPERTY_NAME.IS_DRUG_RELATED,
                    TNIBRS_PROPERTY_NAME.DRUG_TYPE_ATTR_ID,
                ]}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTBooleanButtonRadio path="suspectedMarijuanaUse" />
                            </Row>
                        </IndentedFields>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                attributeType={itemCategoryName}
                                filterOptions={itemCategoryFilterOptions}
                                path="suspectedDrugTypeAttrId"
                            />
                        </Row>
                    </>
                )}
            />
            <FormConfigurationRenderer
                form={form}
                absoluteFormPath={`offense.${CUSTOM_PROPERTIES_KEY_NAME}`}
                mode="FORM"
                __fieldsToInclude={[TNIBRS_PROPERTY_NAME.ORIGIN_OF_DRUG_ATTR_ID]}
            />
            <FormConfigurationRenderer
                form={form}
                absoluteFormPath={`offense.${CUSTOM_PROPERTIES_KEY_NAME}`}
                mode="FORM"
                __fieldsToInclude={[TNIBRS_PROPERTY_NAME.DRUG_PRECURSORS_ATTR_ID]}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <Row>
                        <ArbiterMFTBooleanButtonRadio
                            path="suspectedGamingActivity"
                            deselectable={false}
                        />
                    </Row>
                )}
            />
            <MFTFieldset
                path="offenseAttributes"
                render={() => (
                    <div>
                        <WithComplianceGroup
                            complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                            fallback={
                                <>
                                    <AttributeSelectFieldset
                                        path={moAttrName}
                                        attributeType={moAttrName}
                                        grouped={true}
                                    />
                                    <AttributeSelectFieldset
                                        path={contributingFactorsAttrName}
                                        attributeType={contributingFactorsAttrName}
                                        grouped={true}
                                    />
                                    <AttributeSelectFieldset
                                        path={routeOfApproachAttrName}
                                        attributeType={routeOfApproachAttrName}
                                    />
                                    <AttributeSelectFieldset
                                        path={typeOfSearchConductedByOffenderAttrName}
                                        attributeType={typeOfSearchConductedByOffenderAttrName}
                                    />
                                    <AttributeSelectFieldset
                                        path={distractionFraudActivityAttrName}
                                        attributeType={distractionFraudActivityAttrName}
                                    />
                                </>
                            }
                        />
                        <AttributeSelectFieldset
                            path={secSystemAttrName}
                            attributeType={secSystemAttrName}
                            grouped={true}
                        />

                        <MFTFieldset
                            path={offenseStatAttrName}
                            render={() => (
                                <div>
                                    <Row>
                                        <ArbiterMFTAttributeCheckboxes
                                            path="attributeIds"
                                            attributeType={offenseStatAttrName}
                                            columns={2}
                                            columnWidth="48%"
                                            gutterWidth="4%"
                                        />
                                    </Row>
                                    <Row>
                                        <IndentedFields>
                                            <ArbiterMFTText path="description" length="lg" />
                                        </IndentedFields>
                                    </Row>
                                </div>
                            )}
                        />
                    </div>
                )}
            />
            <MFTFieldset
                path="offense"
                render={() => (
                    <Row>
                        <ArbiterMFTBooleanButtonRadio
                            path="isAntiReproductiveRightsCrime"
                            length="md"
                        />
                    </Row>
                )}
            />
            <MFTFieldset
                path="offenseAttributes"
                render={() => (
                    <div>
                        <AttributeRadioSelectFieldset
                            path={suspectedMetalTheftAttrName}
                            attributeType={suspectedMetalTheftAttrName}
                            valueAsArray={true}
                            multiple={true}
                            isRequired={false}
                        />
                        <AttributeSelectFieldset
                            path={additionalOtherInfoAttrName}
                            attributeType={additionalOtherInfoAttrName}
                        />
                        <WithComplianceGroup
                            complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                            fallback={
                                <>
                                    <AttributeSelectFieldset
                                        path={vehicleMoAttrName}
                                        attributeType={vehicleMoAttrName}
                                    />
                                    <AttributeSelectFieldset
                                        path={safeguardingMoAttrName}
                                        attributeType={safeguardingMoAttrName}
                                    />
                                    <AttributeSelectFieldset
                                        path={otherMoAttrName}
                                        attributeType={otherMoAttrName}
                                        grouped={true}
                                    />
                                </>
                            }
                        />
                    </div>
                )}
            />
            <WithComplianceGroup complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}>
                <CardSubsection title={strings.moKeywords}>
                    <OffenseFormMOKeywordsFieldset onWeaponForceChange={onWeaponForceChange} />
                </CardSubsection>
            </WithComplianceGroup>
        </CardSection>
    );
};

const OffenseFormMOKeywordsFieldset = ({ onWeaponForceChange }) => (
    <MFTFieldset
        path="offenseAttributes"
        render={() => (
            <div>
                <AttributeSelectFieldset
                    path={actionAtSceneAttrName}
                    attributeType={actionAtSceneAttrName}
                />
                <AttributeSelectFieldset
                    path={contributingFactorsAttrName}
                    attributeType={contributingFactorsAttrName}
                    grouped={true}
                />
                <AttributeSelectFieldset
                    path={internetInvolvementTypeAttrName}
                    attributeType={internetInvolvementTypeAttrName}
                    grouped={true}
                />
                <AttributeSelectFieldset
                    path={distractionFraudActivityAttrName}
                    attributeType={distractionFraudActivityAttrName}
                />
                <ArbiterMFTAttributeSelect
                    path="DRUG_INVOLVEMENT.attributeIds"
                    attributeType={drugInvolvementAttrName}
                    multiple={false}
                    length="md"
                />
                <AttributeSelectFieldset
                    path={otherMoAttrName}
                    attributeType={otherMoAttrName}
                    grouped={true}
                />
                <AttributeSelectFieldset
                    path={implementWeaponInvolvedAttrName}
                    attributeType={implementWeaponInvolvedAttrName}
                    grouped={true}
                />
                <AttributeSelectFieldset
                    path={meansOfApproachAttrName}
                    attributeType={meansOfApproachAttrName}
                />
                <AttributeSelectFieldset
                    path={methodOfEntryExitAttrName}
                    attributeType={methodOfEntryExitAttrName}
                />
                <AttributeSelectFieldset
                    path={pointOfEntryExitAttrName}
                    attributeType={pointOfEntryExitAttrName}
                />
                <AttributeSelectFieldset
                    path={routeOfApproachAttrName}
                    attributeType={routeOfApproachAttrName}
                />
                <AttributeSelectFieldset
                    path={safeguardingMoAttrName}
                    attributeType={safeguardingMoAttrName}
                />
                <AttributeSelectFieldset
                    path={moAttrName}
                    attributeType={moAttrName}
                    grouped={true}
                />
                <AttributeSelectFieldset
                    path={typeOfSearchConductedByOffenderAttrName}
                    attributeType={typeOfSearchConductedByOffenderAttrName}
                />
                <AttributeSelectFieldset
                    path={vehicleMoAttrName}
                    attributeType={vehicleMoAttrName}
                />
                <AttributeSelectFieldset
                    path={weaponForceAttrName}
                    attributeType={weaponForceAttrName}
                    onChange={onWeaponForceChange}
                    grouped={true}
                />
            </div>
        )}
    />
);

export default withItemCategoryFilterOptions(globalAttributes.itemType.drugs)(FullOffenseForm);

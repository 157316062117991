import { isEmpty, unionBy } from 'lodash';
import { UserOptOut, ProductModuleEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import { ClientCommonAction } from '../../../../../redux/types';
import { RootState } from '../../../../../redux/state';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getUserOptOutsResource from '../../resources/notificationPreferencesResource';
import { userHasAbilitySelector } from '../../../abilities/state/data';
import { isProductModuleActiveSelector } from '../../../product-modules/state/data';
import abilitiesEnum from '../../../../../enums/universal/abilitiesEnum';

interface ClientUserOptOut {
    id: number;
    optOuts: UserOptOut[];
}

const userOptOutsModule = createNormalizedModule<ClientUserOptOut>({
    type: 'userOptOuts',
});

// SELECTORS
export const userOptOutsSelector = userOptOutsModule.selectors.entitiesSelector;
const userOptOutsByIdSelector = userOptOutsModule.selectors.entityByIdSelector;
// ACTIONS
// use replace instead of store, because server will create new opt-outs
// on save, instead of re-using existing ones.
const replaceUserOptOutsWhere = userOptOutsModule.actionCreators.replaceEntitiesWhere;

export const notificationsSettingsVisibilitySelector = (state: RootState) => (
    userId: number,
    currentUserId: number
) => {
    const isProductModuleActive = isProductModuleActiveSelector(state);
    if (!isProductModuleActive(ProductModuleEnum.NOTIFICATIONS.name)) {
        return false;
    }

    const userHasAbility = userHasAbilitySelector(state);
    const canLoadSelfOptOuts =
        userHasAbility(currentUserId, abilitiesEnum.NOTIFICATIONS.CORE) && userId === currentUserId;
    const canLoadOtherUsersOptOuts = userHasAbility(
        currentUserId,
        abilitiesEnum.NOTIFICATIONS.ADMIN
    );

    return canLoadSelfOptOuts || canLoadOtherUsersOptOuts;
};

/**
 * This method checks permissions resolves the correct loadOptOuts method
 * @param userId The user to load his/her opt outs
 * @param currentUserId The acting user
 * @returns cached opt-outs or response from the server
 */
export function loadOptOuts(
    userId: number,
    currentUserId: number
): ClientCommonAction<Promise<UserOptOut[]>> {
    // @ts-expect-error Determine if Promise.method is needed and if not, remove it
    return Promise.method((dispatch, getState) => {
        const state = getState();
        const hasPermission = notificationsSettingsVisibilitySelector(state)(userId, currentUserId);
        if (!hasPermission) {
            return [];
        }
        const userOptOutsResource = getUserOptOutsResource();
        const cachedOptOuts = userOptOutsByIdSelector(state)(userId);
        // return user opt outs from state if they exist
        // to prevent extra api calls for opt outs
        // edge case: user has NO opt outs, API call will still happen
        if (!isEmpty(cachedOptOuts) && !!cachedOptOuts) {
            return cachedOptOuts.optOuts;
        }
        const resourceMethod =
            userId === currentUserId
                ? userOptOutsResource.getCurrentUserOptOuts
                : userOptOutsResource.getUserOptOuts;

        return resourceMethod(userId).then((optOuts: UserOptOut[]) => {
            // replace all opt outs
            dispatch(replaceUserOptOutsWhere({ id: userId }, { id: userId, optOuts }));
            return optOuts;
        });
    });
}

export function saveUserOptOuts(
    userId: number,
    optOuts: UserOptOut[]
): ClientCommonAction<Promise<UserOptOut[]>> {
    return (dispatch) => {
        const userOptOutsResource = getUserOptOutsResource();

        return userOptOutsResource
            .saveUserOptOuts(userId, optOuts)
            .then((optOuts: UserOptOut[]) => {
                // replace all opt outs
                dispatch(replaceUserOptOutsWhere({ id: userId }, { id: userId, optOuts }));
                return optOuts;
            });
    };
}

export function saveCurrentUserOptOuts(
    userId: number,
    optOuts: UserOptOut[]
): ClientCommonAction<Promise<UserOptOut[]>> {
    return (dispatch) => {
        const userOptOutsResource = getUserOptOutsResource();

        return userOptOutsResource.saveCurrentUserOptOuts(optOuts).then((optOuts: UserOptOut[]) => {
            // replace all opt outs
            dispatch(replaceUserOptOutsWhere({ id: userId }, { id: userId, optOuts }));
            return optOuts;
        });
    };
}

export function saveCurrentUserOptOut(optOut: UserOptOut): ClientCommonAction<Promise<UserOptOut>> {
    return (dispatch, getState) => {
        const userOptOutsResource = getUserOptOutsResource();

        return userOptOutsResource.saveCurrentUserOptOut(optOut).then((optOut: UserOptOut) => {
            const { userId } = optOut;
            const optOutsForCurrentUser = userOptOutsByIdSelector(getState())(userId as number);

            if (!optOutsForCurrentUser) {
                // loadOptOuts has a simple cache check, storing this single opt out would
                // potentially leave client state out of sync. therefore force reload of opt outs.
                dispatch(loadOptOuts(userId as number, userId as number));
            } else {
                const optOuts = unionBy([optOutsForCurrentUser.optOuts, [optOut]], 'id');

                // @ts-expect-error incorrect type for optOuts. Need to fix this and re-test functionality
                dispatch(replaceUserOptOutsWhere({ id: userId }, { id: userId, optOuts }));
            }

            return optOut;
        });
    };
}

// REDUCER
export default userOptOutsModule.reducerConfig;

import { Store } from 'redux';
import { ElasticOrganization } from '@mark43/rms-api';
import { iconPaths } from '@arc/icon';
import { buildElasticLocationLines } from '~/client-common/core/domain/elastic-involved-locations/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { RootState } from '../../../../../../../legacy-redux/reducers/rootReducer';
import { FormatMentionItemMapper, Mention } from '../types';
import { getDefaultAvatarIcon, getMentionEntityLinkUrl } from '../utils';

const prepareMentionedItem = (item: ElasticOrganization, store: Store<RootState>): Mention => {
    const applicationSettings = applicationSettingsSelector(store.getState());
    const isSubPremiseSupportEnabled = !!applicationSettings.SUBPREMISE_SUPPORT_ENABLED;

    const name = item.name ?? 'unknown';

    const descriptions = item.involvedLocations.length
        ? buildElasticLocationLines(item.involvedLocations[0], { isSubPremiseSupportEnabled })
        : undefined;

    return {
        id: item.id.toString(),
        name,
        text: name,
        descriptions,
        category: 'ORGANIZATION',
        photo: item.primaryProfilePhotoPath,
        defaultIcon: getDefaultAvatarIcon(iconPaths.Organization),
        url: getMentionEntityLinkUrl({ itemType: 'ORGANIZATION', itemId: item.id }),
    };
};

const formatMapper: FormatMentionItemMapper<ElasticOrganization> = {
    resourceMethod: 'searchOrganizations',
    prepareMentionedItem,
};

export default formatMapper;

import { get, has, set, mapValues } from 'lodash';
import {
    Attachment,
    Mark43File,
    Image,
    FileCategoryEnum,
    EntityTypeEnum,
    CaseNote,
    Task,
    ElasticReport,
} from '@mark43/rms-api';

import componentStrings from '../../../strings/componentStrings';
import { DateTimeFormatter } from '../../../dates/utils/dateHelpers';
import { buildViewModel } from '../../../../helpers/viewModelHelpers';
import { formatMiniUserByIdSelector } from '../../mini-users/state/data';
import { attachmentToFileGrid, AttachmentViewModel } from '../state/ui';

const strings = componentStrings.cases.caseAttachments.caseAttachmentRowViewConfig;

export const getAttachmentFile = (
    attachment:
        | Attachment
        | ReturnType<typeof attachmentToFileGrid>
        | (ReturnType<typeof attachmentToFileGrid> & { isFolder: false; type: string })
): Partial<Mark43File> =>
    get(attachment, 'file') ||
    get(attachment, 'image.originalFile') ||
    get(attachment, 'video.originalFile') ||
    {};

export const setAttachmentFile = (attachment: Attachment, file?: Mark43File) => {
    if (!file) {
        return attachment;
    }
    if (has(attachment, 'file')) {
        set(attachment, 'file', file);
    } else if (has(attachment, 'image.originalFile')) {
        set(attachment, 'image.originalFile', file);
    } else if (has(attachment, 'video.originalFile')) {
        set(attachment, 'video.originalFile', file);
    }
    return attachment;
};

export const getThumbnailPath = (attachment: Attachment, small?: boolean): string => {
    const file = getAttachmentFile(attachment);
    let thumbnailPath = '';
    if (attachment.attachmentType === EntityTypeEnum.VIDEO.name) {
        thumbnailPath = get(attachment, 'video.previewFrameWebPaths[0]');
    } else if (attachment.attachmentType === EntityTypeEnum.IMAGE.name) {
        if (small) {
            thumbnailPath = get(attachment, 'image.thumbnailSmallFile.fileWebServerPath');
        } else if (file.fileCategory === FileCategoryEnum.PDF.name) {
            // Some PDFs have thumbnails.
            // `originalFile` is the PDF, so we don't use that here.
            thumbnailPath =
                get(attachment, 'image.thumbnailLargeFile.fileWebServerPath') ||
                get(attachment, 'image.thumbnailMediumFile.fileWebServerPath');
        } else {
            thumbnailPath =
                get(attachment, 'image.thumbnailLargeFile.fileWebServerPath') ||
                get(attachment, 'image.thumbnailMediumFile.fileWebServerPath') ||
                get(attachment, 'image.originalFile.fileWebServerPath');
        }
    }

    return thumbnailPath;
};

export const convertImageToImageGalleryViewModel = (
    image: Image,
    formatUserById: (id: number) => string,
    description: string,
    dateTimeFormatter: DateTimeFormatter
) => {
    return {
        attachmentType: EntityTypeEnum.IMAGE.name,
        url: get(image, 'originalFile.fileWebServerPath'),
        thumbnail: get(image, 'thumbnailLargeFile.fileWebServerPath'),
        description,
        uploadedBy: formatUserById(get(image, 'createdBy')),
        uploadedDate: dateTimeFormatter.formatDate(get(image, 'createdDateUtc')),
    };
};

export const convertAttachmentToImageGalleryViewModel = (
    attachment: Attachment,
    formatUserById: (id: number) => string,
    description: string,
    dateTimeFormatter: DateTimeFormatter
) => {
    return {
        id: attachment.id,
        attachmentType:
            get(attachment, 'attachmentType') ||
            ((get(attachment, 'originalFile.fileCategory') ||
                get(attachment, 'file.fileCategory')) === 'IMAGE'
                ? EntityTypeEnum.IMAGE.name
                : EntityTypeEnum.FILE.name),
        fileCategory:
            get(attachment, 'originalFile.fileCategory') || get(attachment, 'file.fileCategory'),
        url:
            get(attachment, 'originalFile.fileWebServerPath') ||
            get(attachment, 'file.fileWebServerPath'),
        thumbnail: get(attachment, 'thumbnailLargeFile.fileWebServerPath'),
        description,
        uploadedBy: formatUserById(get(attachment, 'createdBy')),
        uploadedDate: dateTimeFormatter.formatDate(get(attachment, 'createdDateUtc')),
    };
};

export const convertAttachmentsToAttachmentViewModel = (
    attachments: Attachment[],
    formatMiniUserById: ReturnType<typeof formatMiniUserByIdSelector>
) => {
    const makeViewModel = buildViewModel<Attachment>({
        recursive: true,
        mappers: [
            ({ createdBy }) => {
                return {
                    createdBy: formatMiniUserById(createdBy, { firstNameAsInitial: true }),
                };
            },
            (attachment) => {
                const file = getAttachmentFile(attachment);
                return {
                    originalFile: file,
                    originalFileName: file.originalFileName,
                    path: file.fileWebServerPath,
                    thumbnailPath: getThumbnailPath(attachment),
                    thumbnailMediumPath: attachment.image
                        ? get(attachment.image, 'thumbnailMediumFile.fileWebServerPath')
                        : '',
                    // Under NO circumstance should this be used on a web app
                    // It is solely for printing where absolute paths are required
                    absolutePath: file.fileAppServerPath,
                };
            },
        ],
    });
    return mapValues(attachments, makeViewModel);
};

export const translateToCaseAttachment = (
    attachmentViewModel: AttachmentViewModel,
    caseTitle: string,
    caseFieldName: string
) => {
    return {
        ...attachmentViewModel,
        description: strings.caseAttachmentDescription(caseFieldName),
        source: caseFieldName,
        sourceDescription: caseTitle,
        type: attachmentViewModel.attachmentType,
    };
};

export const translateToCaseNoteAttachment = (
    attachmentViewModel: AttachmentViewModel,
    caseId: number,
    caseNote: CaseNote,
    caseFieldName: string
) => {
    return {
        ...attachmentViewModel,
        caseId,
        description: strings.caseNoteAttachmentDescription(caseNote?.title, caseFieldName),
        source: strings.caseNoteAttachmentSource(caseFieldName),
        sourceDescription: caseNote?.title,
        linkTo: strings.caseNoteAttachmentLink(caseId, caseNote?.id),
        type: attachmentViewModel.attachmentType,
    };
};

export const translateToCaseTaskAttachment = (
    attachmentViewModel: AttachmentViewModel,
    caseId: number,
    task: Task,
    caseFieldName: string
) => {
    return {
        ...attachmentViewModel,
        caseId,
        description: strings.caseTaskAttachmentDescription(task?.description, caseFieldName),
        source: strings.caseTaskAttachmentSource(caseFieldName),
        sourceDescription: task?.description,
        linkTo: strings.caseTaskAttachmentLink(caseId),
        type: attachmentViewModel.attachmentType,
    };
};

export const translateToCaseReportAttachment = (
    attachmentViewModel: AttachmentViewModel,
    caseId: number,
    elasticReport: ElasticReport
) => {
    return {
        ...attachmentViewModel,
        caseId,
        description: strings.caseReportAttachmentDescription(elasticReport?.shortTitle),
        source: strings.caseReportAttachmentSource,
        sourceDescription: elasticReport?.shortTitle,
        linkTo: strings.caseReportAttachmentLink(elasticReport?.id),
        type: attachmentViewModel.attachmentType,
    };
};

export const translateToCaseWarrantAttachment = (
    attachmentViewModel: AttachmentViewModel,
    caseId: number,
    warrantId: number,
    warrantTitle: string
) => {
    return {
        ...attachmentViewModel,
        caseId,
        description: strings.caseWarrantAttachmentDescription(warrantTitle),
        source: strings.caseWarrantAttachmentSource,
        sourceDescription: warrantTitle,
        linkTo: strings.caseWarrantAttachmentLink(warrantId),
        type: attachmentViewModel.attachmentType,
    };
};

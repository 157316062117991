import { DepartmentLocalTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const departmentSetupFormFieldViewModels = {
    departmentDetails: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'departmentDetails',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'displayName',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'countryCode',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'timeZone',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'nibrsRegionalGroup',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'departmentType',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'departmentStatus',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'departmentSubDomain',
                validators: {
                    requiredError: requiredString,
                },
            },
        ]),
    },
    locationBias: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'locationBias',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'centerLat',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'centerLng',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'southwestLat',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'southwestLng',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'northeastLat',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'northeastLng',
                validators: {
                    requiredError: requiredString,
                },
            },
        ]),
    },
    regionalPreferences: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'regionalPreferences',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'heightMeasurementSystem',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'weightMeasurementSystem',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'distanceMeasurementSystem',
                validators: {
                    requiredError: requiredString,
                },
            },
        ]),
    },
};

function convertDepartmentSetupFormModelToDepartmentCreationRequest(formModel = {}) {
    const { departmentDetails, locationBias, regionalPreferences } = formModel;

    const {
        displayName,
        countryCode,
        timeZone,
        nibrsRegionalGroup,
        departmentType,
        departmentStatus,
        departmentSubDomain,
    } = departmentDetails;

    const {
        centerLat,
        centerLng,
        southwestLat,
        southwestLng,
        northeastLat,
        northeastLng,
    } = locationBias;

    const {
        heightMeasurementSystem,
        weightMeasurementSystem,
        distanceMeasurementSystem,
    } = regionalPreferences;

    const agencies = [
        {
            agencyCode: departmentSubDomain, // eg, CCPD
            agencyName: displayName, // eg, Camden County Police Department
        },
    ];

    const department = {
        subDomain: departmentSubDomain,
        departmentStatus,
    };

    const locationBiasWithCountry = {
        countryCode,
        centerLat,
        centerLng,
        southwestLat,
        southwestLng,
        northeastLat,
        northeastLng,
    };

    const departmentProfile = {
        localType: DepartmentLocalTypeEnum.NONE.name,
        timeZone,
        nibrsRegionalGroup,
        displayName,
        locationBias: locationBiasWithCountry,
        heightMeasurementSystem,
        weightMeasurementSystem,
        distanceMeasurementSystem,
    };

    // if no profiles are sent up, server will auto-create 1 per agency,
    // which is fine for now!
    const agencyProfiles = [];

    const cloneFromDepartmentId = departmentType;

    return {
        department,
        agencies,
        departmentProfile,
        agencyProfiles,
        cloneFromDepartmentId,
    };
}

/**
 * Module of the Department Setup
 */
const departmentSetupForm = createFormModule({
    formName: formClientEnum.DEPARTMENT_SETUP,
    fieldViewModels: departmentSetupFormFieldViewModels,
    convertFromFormModel: convertDepartmentSetupFormModelToDepartmentCreationRequest,
});

export const isDepartmentProfileFormFilledSelector = createSelector(
    departmentSetupForm.selectors.formPathIsValidSelector,
    applicationSettingsSelector,
    (formPathIsValid, applicationSettings) => {
        return (
            formPathIsValid('departmentDetails.displayName') &&
            formPathIsValid('departmentDetails.countryCode') &&
            // If ff is on then timeZone is moved to the regional preferences page
            // and is validated when the department is created
            (applicationSettings.RMS_I18N_ENABLED ||
                formPathIsValid('departmentDetails.timeZone')) &&
            formPathIsValid('departmentDetails.nibrsRegionalGroup') &&
            formPathIsValid('departmentDetails.departmentType') &&
            formPathIsValid('departmentDetails.departmentStatus') &&
            formPathIsValid('departmentDetails.departmentSubDomain')
        );
    }
);

export const isLocationFormFilledSelector = createSelector(
    departmentSetupForm.selectors.formPathIsValidSelector,
    (formPathIsValid) => {
        return (
            formPathIsValid('locationBias.centerLat') &&
            formPathIsValid('locationBias.southwestLat') &&
            formPathIsValid('locationBias.southwestLng') &&
            formPathIsValid('locationBias.northeastLat') &&
            formPathIsValid('locationBias.northeastLng')
        );
    }
);

export default departmentSetupForm;

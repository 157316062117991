import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';

const { ATTRIBUTE } = fieldTypeClientEnum;

export const subdivisionAttrFieldsForViewModels = [
    {
        key: 'subdivision1AttrIds',
        type: ATTRIBUTE,
        fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_1_ATTR_ID,
        fieldNameForFilterLabel: fields.LOCATION_ENTITY_LINK_SUBDIVISION_1_ATTR_ID,
    },
    {
        key: 'subdivision2AttrIds',
        type: ATTRIBUTE,
        fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_2_ATTR_ID,
        fieldNameForFilterLabel: fields.LOCATION_ENTITY_LINK_SUBDIVISION_2_ATTR_ID,
    },
    {
        key: 'subdivision3AttrIds',
        type: ATTRIBUTE,
        fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_3_ATTR_ID,
        fieldNameForFilterLabel: fields.LOCATION_ENTITY_LINK_SUBDIVISION_3_ATTR_ID,
    },
    {
        key: 'subdivision4AttrIds',
        type: ATTRIBUTE,
        fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID,
        fieldNameForFilterLabel: fields.LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID,
    },
    {
        key: 'subdivision5AttrIds',
        type: ATTRIBUTE,
        fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID,
        fieldNameForFilterLabel: fields.LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID,
    },
];

import { EntityTypeEnum, EntityTypeEnumType } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import _Checkbox from '../../../core/forms/components/checkboxes/Checkbox';
import {
    canCurrentUserEditCurrentCaseSelector,
    canModifyCaseNoteForCurrentCaseInFolderSelector,
} from '../state/ui';

const Checkbox = styled(_Checkbox)`
    margin: 0;
    z-index: 1;
`;

interface CaseRowCheckboxProps {
    value: boolean;
    entityType: EntityTypeEnumType;
    folderId?: number;
    caseNoteId?: number;
    onChange: () => void;
}

const CaseRowCheckbox: React.FC<CaseRowCheckboxProps> = ({
    entityType,
    caseNoteId,
    folderId,
    ...otherprops
}) => {
    const canCurrentUserEditCurrentCase = useSelector(canCurrentUserEditCurrentCaseSelector);
    const canModifyCaseNoteForCurrentCaseInFolder = useSelector(
        canModifyCaseNoteForCurrentCaseInFolderSelector
    );

    let disabled;
    if (entityType === EntityTypeEnum.CASE_NOTE.name && caseNoteId) {
        disabled = !canModifyCaseNoteForCurrentCaseInFolder(caseNoteId, folderId);
    } else if (entityType === EntityTypeEnum.ATTACHMENT.name) {
        disabled = false;
    } else {
        disabled = !canCurrentUserEditCurrentCase;
    }

    return <Checkbox disabled={disabled} {...otherprops} />;
};

export default CaseRowCheckbox;

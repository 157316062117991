import { get } from 'lodash';
import { CodeTypeSourceEnum, DepartmentProfile, RegionalGroupEnum } from '@mark43/rms-api';

export const checkIfDepartmentIsNibrs = (deptProfile?: DepartmentProfile): boolean => {
    if (deptProfile?.nibrsRegionalGroup) {
        const regionalGroup = RegionalGroupEnum[deptProfile.nibrsRegionalGroup];
        if (regionalGroup && regionalGroup.name !== RegionalGroupEnum.NOT_APPLICABLE.name) {
            if (
                regionalGroup.nibrsSource === CodeTypeSourceEnum.MD_NIBRS.name ||
                regionalGroup.nibrsSource === CodeTypeSourceEnum.NJ_NIBRS.name
            ) {
                /**
                 * To allow agencies to report both NIBRS and UCR, we give their regional
                 * group both a ucrSource and a nibrsSource. RMS-11789, REB-1705
                 */
                return true;
            }
            return (
                // From mark43-resources, the property regionalGroup.ucrSource either does not exist or has a value,
                // meaning it is never null or undefined. So the second check here is technically unnecessary, and is
                // included for safety in case the enum changes to allow null/undefined values for ucrSource.
                (!('ucrSource' in regionalGroup) ||
                    ('ucrSource' in regionalGroup && !regionalGroup.ucrSource)) &&
                !!regionalGroup.nibrsSource
            );
        }
    }
    return false;
};

const defaultDisplayFormatter = (departmentProfile?: DepartmentProfile) =>
    get(departmentProfile, 'displayName');

export const createDepartmentProfileToOptionMapper = (
    displayFormatter: (
        departmentProfile: DepartmentProfile
    ) => string | undefined = defaultDisplayFormatter
) => (departmentProfile: DepartmentProfile) => ({
    value: departmentProfile.departmentId,
    display: displayFormatter(departmentProfile),
});

import { EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get, first } from 'lodash';
import { lifecycleOptions } from 'markformythree';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { useOfForcesWhereSelector } from '~/client-common/core/domain/use-of-forces/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import testIds from '../../../../../core/testIds';
import formsRegistry from '../../../../../core/formsRegistry';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import { createUseOfForceForm, refreshUseOfForceForm } from '../../state/forms/useOfForceForm';
import { registerForm } from '../../state/ui';
import useOfForceCard from '../../state/ui/useOfForceCard';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import withCard from '../../utils/withCard';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { registerCard } from '../../utils/cardsRegistry';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import UseOfForceCardForm from './UseOfForceCardForm';
import UseOfForceCardSummary from './UseOfForceCardSummary';

function getForm() {
    return (dispatch, getState, { formsRegistry }) => {
        return formsRegistry.get(RefContextEnum.FORM_USE_OF_FORCE.name);
    };
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const getUseOfForce = ({ useOfForcesWhere, reportId }) => first(useOfForcesWhere({ reportId }));
const getLocationDescriptionPath = () => 'useOfForceLocation.description';
const getLocationPositionAttrIdPath = () => 'useOfForceLocation.positionAttrId';

class UseOfForceCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const useOfForce = getUseOfForce({
            useOfForcesWhere: this.props.useOfForcesWhere,
            reportId: this.props.currentReportId,
        });

        this.state = {
            selectedUserProfileId: get(useOfForce, 'userProfileId'),
        };
        const initialFormState = this.props.refreshUseOfForceForm({
            reportId: this.props.currentReportId,
        });
        const form = createUseOfForceForm({
            formatFieldByName: this.props.formatFieldByName,
            arbiter: this.props.arbiter,
            initialState: initialFormState,
        });
        registerForm({ form });

        registerCard({
            cardModule: useOfForceCard,
            onSave: this.onSave,
        });
    }

    getSelectedUserProfileId = () => this.state.selectedUserProfileId;
    setSelectedUserProfileId = (selectedUserProfileId) => this.setState({ selectedUserProfileId });

    getSelectedSupervisorProfileId = () => this.state.selectedSupervisorProfileId;
    setSelectedSupervisorProfileId = (selectedSupervisorProfileId) =>
        this.setState({ selectedSupervisorProfileId });

    getForm = () => {
        return this.props.getForm();
    };

    onEdit = () => this.props.editCallback(() => this.props.onEdit());

    onSaveProgress = ({ customEventType, changeToSummaryModeAfterSubmission } = {}) =>
        this.props.onSaveProgress(formsRegistry.get(RefContextEnum.FORM_USE_OF_FORCE.name), {
            customEventType,
            changeToSummaryModeAfterSubmission,
        });

    handleLocationAdd = (location, modelEntityLink) => {
        this.getForm().set('useOfForceLocation', modelEntityLink);
    };

    handleLocationRemove = () => {
        const form = this.getForm();
        form.set('useOfForceLocation', undefined);
    };

    onSave = () => {
        return this.props.onSave(formsRegistry.get(RefContextEnum.FORM_USE_OF_FORCE.name));
    };

    render() {
        const {
            card = {},
            currentReportId,
            useOfForcesWhere,
            currentReportCardUITitleByType,
        } = this.props;
        const useOfForce = getUseOfForce({
            useOfForcesWhere,
            reportId: currentReportId,
        });
        const cardTitle = currentReportCardUITitleByType(reportCardEnum.USE_OF_FORCE.id);

        return (
            <Card
                testId={testIds.USE_OF_FORCE_CARD}
                className={card.anchor}
                anchor={card.anchor}
                title={cardTitle}
                renderContent={(summaryMode) => {
                    return (
                        <div>
                            {summaryMode ? (
                                <UseOfForceCardSummary
                                    reportId={currentReportId}
                                    useOfForce={useOfForce}
                                />
                            ) : (
                                <UseOfForceCardForm
                                    reportId={currentReportId}
                                    getSelectedUserProfileId={this.getSelectedUserProfileId}
                                    setSelectedUserProfileId={this.setSelectedUserProfileId}
                                    getSelectedSupervisorProfileId={
                                        this.getSelectedSupervisorProfileId
                                    }
                                    setSelectedSupervisorProfileId={
                                        this.setSelectedSupervisorProfileId
                                    }
                                />
                            )}
                            <ArbiterForm
                                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                                name={RefContextEnum.FORM_USE_OF_FORCE.name}
                                context={RefContextEnum.FORM_USE_OF_FORCE.name}
                                render={() => (
                                    <VisibilityObserver
                                        path="useOfForceLocation.locationId"
                                        formName={RefContextEnum.FORM_USE_OF_FORCE.name}
                                        render={({ hidden }) =>
                                            !hidden ? (
                                                <CardSection
                                                    fieldName={
                                                        fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_USE_OF_FORCE_LOCATION_ID
                                                    }
                                                >
                                                    <LocationSummaryViewWrapperWithFormFields
                                                        summaryMode={card.summaryMode}
                                                        onLocationAdd={this.handleLocationAdd}
                                                        onLocationRemove={this.handleLocationRemove}
                                                        entityType={
                                                            EntityTypeEnum.USE_OF_FORCE.name
                                                        }
                                                        entityId={useOfForce.id}
                                                        linkType={
                                                            LinkTypesEnum.LOCATION_OF_USE_OF_FORCE
                                                        }
                                                        locationDescriptionPath={
                                                            getLocationDescriptionPath
                                                        }
                                                        locationPositionAttrIdPath={
                                                            getLocationPositionAttrIdPath
                                                        }
                                                    />
                                                </CardSection>
                                            ) : null
                                        }
                                    />
                                )}
                            />
                        </div>
                    );
                }}
                onEdit={this.onEdit}
                errors={card.errorMessages}
                summaryMode={card.summaryMode}
                canEdit={get(card.canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(card.canEditReportCardStatus, 'errorMessage')}
                saving={card.saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class UseOfForceCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={RefContextEnum.FORM_USE_OF_FORCE.name}>
                {(arbiter) => (
                    <UseOfForceCard ref={this.setRef} {...this.props} arbiter={arbiter} />
                )}
            </RMSArbiterProvider>
        );
    }
}

export default compose(
    withCard(useOfForceCard),
    connect(
        createStructuredSelector({
            useOfForcesWhere: useOfForcesWhereSelector,
            formatFieldByName: formatFieldByNameSelector,
            currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
        }),
        { refreshUseOfForceForm, getForm },
        null,
        { forwardRef: true }
    )
)(UseOfForceCardWrapper);

import React from 'react';
import styled from 'styled-components';

import _Button from '../../../legacy-redux/components/core/Button';
import { InputLoading as _InputLoading } from '../../../legacy-redux/components/core/Loading';

const InputLoading = styled(_InputLoading)`
    && {
        right: 5px;
    }
`;

const Button = styled(_Button)`
    position: relative;
`;

const ButtonWithLoadingIndicator = ({ children, loading, disabled, ...props }) => (
    <Button disabled={loading || disabled} {...props}>
        {children}
        {loading && <InputLoading />}
    </Button>
);

export default ButtonWithLoadingIndicator;

import { LocationEntityLink, EntityTypeEnumType } from '@mark43/rms-api';
import { reject, filter, includes } from 'lodash';
import createLinkModule from '../../../../utils/createLinkModule';

import getLocationsResource from '../../../locations/resources/locationsResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'locationEntityLinks';
export const LOCATION_ENTITY_LINK_MODEL_KEYS = ['locationId', 'entityType', 'entityId', 'linkType'];

const locationEntityLinksModule = createLinkModule<LocationEntityLink>({
    type: NEXUS_STATE_PROP,
    keys: LOCATION_ENTITY_LINK_MODEL_KEYS,
});

// ACTIONS

export const replaceLocationEntityLinksWhere =
    locationEntityLinksModule.actionCreators.replaceLinksWhere;

function replaceLocationEntityLinksForEntityStart() {
    return {
        type: 'location-entity-links/REPLACE_LOCATION_ENTITY_LINKS_FOR_ENTITY_START',
    };
}
function replaceLocationEntityLinksForEntitySuccess() {
    return {
        type: 'location-entity-links/REPLACE_LOCATION_ENTITY_LINKS_FOR_ENTITY_SUCCESS',
    };
}
function replaceLocationEntityLinksForEntityFailure(errorMessage: string) {
    return {
        type: 'location-entity-links/REPLACE_LOCATION_ENTITY_LINKS_FOR_ENTITY_FAILURE',
        payload: errorMessage,
        error: true,
    };
}

/**
 * Update the location entity links of an entity.
 */
export function replaceLocationLinksForEntity(
    entityType: EntityTypeEnumType,
    entityId: number,
    locationEntityLinks: LocationEntityLink[]
): ClientCommonAction<Promise<LocationEntityLink[]>> {
    return function (dispatch, getState, { nexus: { withEntityItems, withRemove } }) {
        dispatch(replaceLocationEntityLinksForEntityStart());

        return getLocationsResource()
            .replaceLocationLinksForEntity(entityType, entityId, locationEntityLinks)
            .then((replacedLocationEntityLinks: LocationEntityLink[]) => {
                // First remove all the locations for the given
                // `entityType` and `entityId`,
                // and then set then set back the desired `locationEntityLinks`
                dispatch(
                    withRemove(
                        NEXUS_STATE_PROP,
                        { entityType, entityId },
                        withEntityItems(
                            {
                                [NEXUS_STATE_PROP]: replacedLocationEntityLinks,
                            },
                            replaceLocationEntityLinksForEntitySuccess()
                        )
                    )
                );
                return replacedLocationEntityLinks;
            })
            .catch((err: Error) => {
                dispatch(replaceLocationEntityLinksForEntityFailure(err.message));
                throw err;
            });
    };
}

function replaceLocationEntityLinksForEntityAndLinkTypesStart() {
    return {
        type: 'location-entity-links/REPLACE_LOCATION_ENTITY_LINKS_FOR_ENTITY_AND_LINK_TYPES_START',
    };
}

function replaceLocationEntityLinksForEntityAndLinkTypesSuccess() {
    return {
        type:
            'location-entity-links/REPLACE_LOCATION_ENTITY_LINKS_FOR_ENTITY_AND_LINK_TYPES_SUCCESS',
    };
}

function replaceLocationEntityLinksForEntityAndLinkTypesFailure(errorMessage: string) {
    return {
        type:
            'location-entity-links/REPLACE_LOCATION_ENTITY_LINKS_FOR_ENTITY_AND_LINK_TYPES_FAILURE',
        payload: errorMessage,
        error: true,
    };
}

/**
 * Replace the location entity links for an entity, for a given set of link types.
 */
export function replaceLocationEntityLinksForEntityAndLinkTypes({
    entityType,
    entityId,
    linkTypes,
    locationEntityLinks,
}: {
    entityType: EntityTypeEnumType;
    entityId: number;
    linkTypes: number[];
    locationEntityLinks: LocationEntityLink[];
}): ClientCommonAction<Promise<LocationEntityLink[]>> {
    return function (dispatch, getState, { nexus: { withEntityItems, withRemove } }) {
        dispatch(replaceLocationEntityLinksForEntityAndLinkTypesStart());

        return getLocationsResource()
            .replaceLocationEntityLinksForEntityAndLinkTypes(
                entityType,
                entityId,
                linkTypes,
                locationEntityLinks
            )
            .then((replacedLocationEntityLinks: LocationEntityLink[]) => {
                // replace ==> remove then insert.
                dispatch(
                    withRemove(
                        NEXUS_STATE_PROP,
                        (locationEntityLink: LocationEntityLink) =>
                            locationEntityLink.entityType === entityType &&
                            locationEntityLink.entityId === entityId &&
                            includes(linkTypes, locationEntityLink.linkType),
                        withEntityItems(
                            {
                                [NEXUS_STATE_PROP]: replacedLocationEntityLinks,
                            },
                            replaceLocationEntityLinksForEntityAndLinkTypesSuccess()
                        )
                    )
                );
                return replacedLocationEntityLinks;
            })
            .catch((err: Error) => {
                dispatch(replaceLocationEntityLinksForEntityAndLinkTypesFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const locationEntityLinksSelector = locationEntityLinksModule.selectors.linksSelector;
export const locationEntityLinksWhereSelector =
    locationEntityLinksModule.selectors.linksWhereSelector;

/**
 * Removes the given `locationEntitylink` from the associated `entityType` and `entityId`
 *
 * NOTE: If a user has added two of the same location to the same `entityType`/`entityId`/`linkType`
 * this will not work. But this should not be a use case
 * as we shouldn't be allowed to add two of the same location
 *
 * @param  {LocationEntityLink} locationEntityLinkToRemove
 * @param  {Function} onSuccess
 * @param  {Function} onError
 */
export function removeLocationEntityLink({
    locationEntityLinkToRemove,
    onSuccess,
    onError,
}: {
    locationEntityLinkToRemove: LocationEntityLink;
    onSuccess: (...args: unknown[]) => void;
    onError: (...args: unknown[]) => void;
}): ClientCommonAction<Promise<void>> {
    const { entityId, entityType, linkType, locationId } = locationEntityLinkToRemove;
    return (dispatch, getState) => {
        // Secondly, filter out the `locationEntityLinks`
        // with the same `locationId` and `linkType`
        // as the supplied `locationEntityLink`
        const locationEntityLinksToKeep = reject(
            // First, grab all the `locationEntityLinks` for this entity
            filter(locationEntityLinksSelector(getState()), {
                entityId,
                entityType,
            }),
            {
                linkType,
                locationId,
            }
        );
        return dispatch(
            replaceLocationLinksForEntity(entityType, entityId, locationEntityLinksToKeep)
        )
            .then((remainingLocationEntityLinks) =>
                onSuccess(locationEntityLinkToRemove, remainingLocationEntityLinks)
            )
            .catch(onError);
    };
}

// REDUCER
export default locationEntityLinksModule.reducerConfig;

import { EntityTypeEnum, PrintingTemplateTypeEnum } from '@mark43/rms-api';
import { createField, createFormConfiguration, formEvents } from 'markformythree';
import * as yup from 'yup';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../../core/validation/yupMftValidation';

const strings = componentStrings.validation;

export const FILLABLE_PDF_TEMPLATES_ADMIN_FORM_NAME = 'FILLABLE_PDF_TEMPLATES_ADMIN_FORM';

export function convertToFormModel(fillablePdfTemplateView) {
    const { title, linkedEntityId: reportDefinitionId, file, id } = fillablePdfTemplateView;

    return {
        title,
        id,
        reportDefinitionId,
        file,
    };
}

export function convertFromFormModel(formModel) {
    const { title, reportDefinitionId, file, id } = formModel;
    return {
        id,
        isEnabled: true,
        title,
        linkedEntityType: EntityTypeEnum.REPORT_DEFINITION.name,
        linkedEntityId: reportDefinitionId,
        file,
        printingTemplateType: PrintingTemplateTypeEnum.FILLABLE_PDF.name,
    };
}

export const mftFormConfiguration = createFormConfiguration({
    id: createField({}),
    title: createField({}),
    reportDefinitionId: createField({}),
    file: createField({}),
});

const validationSchema = yup.object().shape({
    title: yup.string().required(strings.requiredError).nullable(true),
    reportDefinitionId: yup.string().required(strings.requiredError).nullable(true),
    file: yup.object().required(strings.requiredError).nullable(true),
});

export const onValidate = ({ formState, eventType }) =>
    validationSchema
        .validate(formState.model, { abortEarly: false })
        .then((validationResult) =>
            convertYupSuccessShapeToMFTSuccessShape(validationResult, formState.ui.$form)
        )
        .catch((validationErrors) =>
            convertYupErrorShapeToMFTErrorShape(validationErrors, formState.ui.$form, eventType)
        );

export const validationEvents = [
    {
        eventType: formEvents.FORM_SUBMIT,
    },
    {
        eventType: formEvents.INPUT_CHANGE,
    },
];

export const initialState = {
    id: undefined,
    title: undefined,
    reportDefinitionId: undefined,
    file: undefined,
};

import React from 'react';
import styled from 'styled-components';

import { HStack, Spacer } from '@arc/layout';
import { Text } from '@arc/typography';
import { AttributeTypeEnum, ElasticPersonQuery } from '@mark43/rms-api';
import { Form, lifecycleOptions } from 'markformythree';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { Button as _Button } from '../../../core/components/Button';
import Row from '../../../core/components/Row';
import {
    MFTAgeRangeSlider,
    MFTHeightRangeSlider,
    MFTWeightRangeSlider,
} from '../../../core/forms/components/MFTSlider';
import { MFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import {
    PhotoLineupFilterFormConfiguration,
    PhotoLineupFilterMFTForm,
    convertToFormModel,
    formConfiguration,
} from '../state/forms/photoLineupFilterForm';
import testIds from '../../../../core/testIds';

const strings = componentStrings.cases.casePhotoLineups.PhotoLineupFilterForm;

function Fields() {
    // get the configurable labels directly here because the form is not defined in the database (hence there are no
    // FieldConfigurations for this form, and these fields are linked to other Contexts) and not hooked up to Arbiter
    // (hence no automatic labelling from the input components)
    const fieldDisplayNames = useFields([
        fields.PERSON_PROFILE_SEX_ATTR_ID,
        fields.PERSON_PROFILE_RACE_ATTR_ID,
        fields.PERSON_PROFILE_ETHNICITY_ATTR_ID,
        fields.PERSON_PROFILE_BUILD_ATTR_ID,
        fields.PERSON_PROFILE_EYE_COLOR_ATTR_ID,
        fields.PERSON_PROFILE_HAIR_COLOR_ATTR_ID,
        fields.PERSON_PROFILE_HAIR_STYLE_ATTR_ID,
        fields.PERSON_PROFILE_FACIAL_HAIR_TYPE_ATTR_ID,
        fields.DISPLAY_ATTRIBUTE_PHYSICAL_CHARACTERISTIC,
        fields.DISPLAY_ONLY_AGE_RANGE,
        fields.DISPLAY_ONLY_SHOW_WEIGHT_RANGE,
        fields.DISPLAY_ONLY_SHOW_HEIGHT_RANGE,
        fields.IDENTIFYING_MARK_IDENTIFYING_MARK_TYPE_ATTR_ID,
        fields.IDENTIFYING_MARK_BODY_PART_ATTR_ID,
    ]);

    return (
        <>
            <Text variant="headingXs">{strings.physicalCharacteristics}</Text>
            <Spacer h="3" />
            <Row>
                <MFTAttributeSelect
                    label={fieldDisplayNames.PERSON_PROFILE_SEX_ATTR_ID}
                    fieldName={fields.PERSON_PROFILE_SEX_ATTR_ID}
                    attributeType={AttributeTypeEnum.SEX.name}
                    path="sexAttrId"
                    includeExpired={true}
                    width={205}
                />
                <MFTAttributeSelect
                    label={fieldDisplayNames.PERSON_PROFILE_RACE_ATTR_ID}
                    fieldName={fields.PERSON_PROFILE_RACE_ATTR_ID}
                    attributeType={AttributeTypeEnum.RACE.name}
                    path="raceAttrId"
                    includeExpired={true}
                    width={205}
                />
                <MFTAttributeSelect
                    label={fieldDisplayNames.PERSON_PROFILE_ETHNICITY_ATTR_ID}
                    fieldName={fields.PERSON_PROFILE_ETHNICITY_ATTR_ID}
                    attributeType={AttributeTypeEnum.ETHNICITY.name}
                    path="ethnicityAttrId"
                    includeExpired={true}
                    width={205}
                />
            </Row>
            <Row>
                <MFTAttributeSelect
                    label={fieldDisplayNames.PERSON_PROFILE_BUILD_ATTR_ID}
                    fieldName={fields.PERSON_PROFILE_BUILD_ATTR_ID}
                    attributeType={AttributeTypeEnum.BUILD.name}
                    path="buildAttrId"
                    includeExpired={true}
                    width={205}
                />
                <MFTAttributeSelect
                    label={fieldDisplayNames.PERSON_PROFILE_EYE_COLOR_ATTR_ID}
                    fieldName={fields.PERSON_PROFILE_EYE_COLOR_ATTR_ID}
                    attributeType={AttributeTypeEnum.EYE_COLOR.name}
                    path="eyeColorAttrId"
                    includeExpired={true}
                    width={205}
                />
                <MFTAttributeSelect
                    label={fieldDisplayNames.PERSON_PROFILE_HAIR_COLOR_ATTR_ID}
                    fieldName={fields.PERSON_PROFILE_HAIR_COLOR_ATTR_ID}
                    attributeType={AttributeTypeEnum.HAIR_COLOR.name}
                    path="hairColorAttrId"
                    includeExpired={true}
                    width={205}
                />
            </Row>
            <Row>
                <MFTAttributeSelect
                    label={fieldDisplayNames.PERSON_PROFILE_HAIR_STYLE_ATTR_ID}
                    fieldName={fields.PERSON_PROFILE_HAIR_STYLE_ATTR_ID}
                    attributeType={AttributeTypeEnum.HAIR_STYLE.name}
                    path="hairStyleAttrId"
                    includeExpired={true}
                    width={205}
                />
                <MFTAttributeSelect
                    label={fieldDisplayNames.PERSON_PROFILE_FACIAL_HAIR_TYPE_ATTR_ID}
                    fieldName={fields.PERSON_PROFILE_FACIAL_HAIR_TYPE_ATTR_ID}
                    attributeType={AttributeTypeEnum.FACIAL_HAIR_TYPE.name}
                    path="facialHairTypeAttrId"
                    includeExpired={true}
                    width={205}
                />
                <MFTAttributeSelect
                    label={fieldDisplayNames.DISPLAY_ATTRIBUTE_PHYSICAL_CHARACTERISTIC}
                    fieldName={fields.DISPLAY_ATTRIBUTE_PHYSICAL_CHARACTERISTIC}
                    attributeType={AttributeTypeEnum.PHYSICAL_CHARACTERISTIC.name}
                    path="physicalCharacteristicAttrIds"
                    multiple={true}
                    grouped={true}
                    includeExpired={true}
                    width={205}
                />
            </Row>
            <Row>
                <MFTAgeRangeSlider
                    label={fieldDisplayNames.DISPLAY_ONLY_AGE_RANGE}
                    fieldName={fields.DISPLAY_ONLY_AGE_RANGE}
                    path="ageRange"
                    width={205}
                />
                <MFTWeightRangeSlider
                    label={fieldDisplayNames.DISPLAY_ONLY_SHOW_WEIGHT_RANGE}
                    fieldName={fields.DISPLAY_ONLY_SHOW_WEIGHT_RANGE}
                    path="weightRange"
                    width={205}
                />
                <MFTHeightRangeSlider
                    label={fieldDisplayNames.DISPLAY_ONLY_SHOW_HEIGHT_RANGE}
                    fieldName={fields.DISPLAY_ONLY_SHOW_HEIGHT_RANGE}
                    path="heightRange"
                    width={205}
                />
            </Row>
            <Text variant="headingXs">{strings.identifyingMarks}</Text>
            <Spacer h="3" />
            <Row>
                <MFTAttributeSelect
                    label={fieldDisplayNames.IDENTIFYING_MARK_IDENTIFYING_MARK_TYPE_ATTR_ID}
                    fieldName={fields.IDENTIFYING_MARK_IDENTIFYING_MARK_TYPE_ATTR_ID}
                    attributeType={AttributeTypeEnum.IDENTIFYING_MARK_TYPE.name}
                    path="identifyingMarkTypeAttrId"
                    width={205}
                />
                <MFTAttributeSelect
                    label={fieldDisplayNames.IDENTIFYING_MARK_BODY_PART_ATTR_ID}
                    fieldName={fields.IDENTIFYING_MARK_BODY_PART_ATTR_ID}
                    attributeType={AttributeTypeEnum.BODY_PART.name}
                    path="bodyPartAttrId"
                    width={205}
                />
            </Row>
        </>
    );
}

const Button = styled(_Button)`
    text-transform: none;
`;

function Buttons({
    form,
    onSubmit,
}: {
    form: PhotoLineupFilterMFTForm;
    onSubmit: () => void;
}): JSX.Element {
    return (
        <HStack>
            <Button
                variant="solid"
                onClick={onSubmit}
                testId={testIds.PHOTO_LINEUP_FILTER_FORM_APPLY_FILTERS}
            >
                {strings.applyFilters}
            </Button>
            <Button
                leftIcon="CloseX"
                variant="ghost"
                onClick={() => {
                    // calling form.resetModel() is not equivalent because the form's `initialState` is non-empty
                    form.set('', undefined);
                }}
                testId={testIds.PHOTO_LINEUP_FILTER_FORM_CLEAR_FILTERS}
            >
                {strings.clearFilters}
            </Button>
        </HStack>
    );
}

/**
 * This form does not search for photo lineups, it searches for photos using person fields.
 */
const PhotoLineupFilterForm = ({
    initialSearchFilters,
    onSubmit,
}: {
    initialSearchFilters?: ElasticPersonQuery;
    onSubmit: () => void;
}): JSX.Element => {
    return (
        <Form<PhotoLineupFilterFormConfiguration>
            name={formClientEnum.PHOTO_LINEUP_FILTER_FORM}
            /**
             * 1. Form state has to persist when the user show/hides this form in the Compose Lineup page.
             *    This is accomplished by REGISTER_AND_RETAIN.
             * 2. Form state has to reset when the user navigates between different Photo Lineups
             *    This is accomplished by the parent component, SelectionAreaContainer.
             */
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            validationEvents={[]}
            configuration={formConfiguration}
            initialState={
                initialSearchFilters ? convertToFormModel(initialSearchFilters) : undefined
            }
            render={(form) => {
                return (
                    <>
                        <Fields />
                        <Buttons form={form} onSubmit={onSubmit} />
                    </>
                );
            }}
        />
    );
};

export default PhotoLineupFilterForm;

import { PrintingDataTypeEnum, PrintingDataTypeEnumType } from '@mark43/rms-api';

const alwaysIncludeChildPrintablesArray: string[] = [
    PrintingDataTypeEnum.CASE.name,
    PrintingDataTypeEnum.TASK_TYPE.name,
    PrintingDataTypeEnum.REPORT_IDENTIFIER.name,
];

export const alwaysIncludeChildPrintables = (printingDataType: PrintingDataTypeEnumType) => {
    return alwaysIncludeChildPrintablesArray.includes(printingDataType);
};

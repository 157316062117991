import { isEmpty } from 'lodash';
import { formEvents } from 'markformythree';
import Promise from 'bluebird';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { towVehicleCheckInByReportIdSelector } from '~/client-common/core/domain/tow-vehicle-check-ins/state/data';
import { replaceTowVehicleCheckInCardBundle } from '~/client-common/core/domain/reports/state/ui/towVehicleCheckIns';

import {
    getTowVehicleCheckInForm,
    refreshTowVehicleCheckInForm,
    buildTowVehicleCheckInCardBundle,
} from '../../forms/towVehicleCheckInForm';

const TOW_VEHICLE_CHECK_IN_SIDE_PANEL_SCREEN = 'TOW_VEHICLE_CHECK_IN_SIDE_PANEL_SCREEN';

const buildTowVehicleCheckInSidePanelInitialCustomPropertyState = () => {
    return {
        screenStack: [
            {
                screen: TOW_VEHICLE_CHECK_IN_SIDE_PANEL_SCREEN,
                screenState: {
                    isPerformingAsyncAction: false,
                },
            },
        ],
    };
};

const refreshTowVehicleCheckInFormAndUi = ({ reportId }) => (dispatch) => {
    const form = getTowVehicleCheckInForm();
    dispatch(refreshTowVehicleCheckInForm({ reportId }));
    form.resetUi();
};

export const openTowVehicleCheckInSidePanel = ({ reportId }) => (
    dispatch,
    getState,
    { overlayStore: { open } }
) => {
    const state = getState();
    const towVehicleCheckInByReportId = towVehicleCheckInByReportIdSelector(state);
    const towVehicleCheckIn = towVehicleCheckInByReportId(reportId);

    if (!isUndefinedOrNull(towVehicleCheckIn)) {
        dispatch(refreshTowVehicleCheckInFormAndUi({ reportId }));
        const initialCustomPropertyState = buildTowVehicleCheckInSidePanelInitialCustomPropertyState();
        open(overlayIdEnum.TOW_VEHICLE_CHECK_IN_SIDE_PANEL, initialCustomPropertyState);
    }
};

export const saveTowVehicleCheckInSidePanel = ({ reportId }) => (dispatch) => {
    const form = getTowVehicleCheckInForm();
    const { formErrors, success } = form.validate({ eventType: formEvents.FORM_SUBMIT });

    if (isEmpty(formErrors) && success) {
        const towVehicleCheckInCardBundle = buildTowVehicleCheckInCardBundle({
            reportId,
            form,
        });
        return dispatch(
            replaceTowVehicleCheckInCardBundle({
                reportId,
                towVehicleCheckInCardBundle,
            })
        ).then(() => {
            dispatch(refreshTowVehicleCheckInFormAndUi({ reportId }));
        });
    }
    return Promise.reject(formErrors);
};

export const onCloseTowVehicleCheckInSidePanel = ({ reportId }) => (dispatch) => {
    dispatch(refreshTowVehicleCheckInFormAndUi({ reportId }));
};

import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

import RecordPrivacyIcon from '../../../../core/components/RecordPrivacyIcon';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.core.SealedReportCard;

const Border = styled.div`
    position: relative;
    margin-top: 30px;
    border-radius: 4px;
    width: 100%;
    border: 2px solid ${(props) => props.theme.colors.highContrastGrey};
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    box-sizing: border-box;
`;

const SealedText = styled.div`
    position: relative;
    display: inline-block;
    margin-left: 20px;
    line-height: 15px;
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.darkGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export default function SealedReportCard() {
    return (
        <Border data-test-id={testIds.SEALED_REPORT}>
            <RecordPrivacyIcon isSealed={true} />
            <SealedText>{strings.body}</SealedText>
        </Border>
    );
}

import {
    AttributeTypeEnum,
    EntityTypeEnum,
    ProductModuleEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    UsageSourceModuleEnum,
} from '@mark43/rms-api';

import * as Sentry from '@sentry/browser';
import {
    chain,
    compact,
    curry,
    find,
    flatten,
    flowRight,
    get,
    identity,
    isArray,
    map,
    mergeWith,
    parseInt,
    pick,
    some,
    unionBy,
    values,
    without,
} from 'lodash';
import { createSelector } from 'reselect';
// Nexus Properties
import { NEXUS_STATE_PROP as REPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/reports/state/data';
import { NEXUS_STATE_PROP as DETENTIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/detentions/state/data';
import { NEXUS_STATE_PROP as AGENCY_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/agency-profiles/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-codes/state/data';
import { NEXUS_STATE_PROP as ARREST_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/arrest-attributes/state/data';
import { NEXUS_STATE_PROP as ARRESTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/arrests/state/data';
import { NEXUS_STATE_PROP as ASSISTING_OFFICERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/assisting-officers/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { NEXUS_STATE_PROP as BEHAVIORAL_CRISES_NEXUS_STATE_PROP } from '~/client-common/core/domain/behavioral-crises/state/data';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/attachments/state/data';
import { NEXUS_STATE_PROP as CAD_TICKETS_NEXUS_STATE_PROP } from '~/client-common/core/domain/cad-tickets/state/data';
import { NEXUS_STATE_PROP as CASE_DEFINITIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/case-definitions/state/data';
import { NEXUS_STATE_PROP as CASE_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/case-titles/state/data';
import { NEXUS_STATE_PROP as CHARGES_NEXUS_STATE_PROP } from '~/client-common/core/domain/charges/state/data';
import { NEXUS_STATE_PROP as CITATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/citations/state/data';
import { NEXUS_STATE_PROP as CITATION_CHARGES_NEXUS_STATE_PROP } from '~/client-common/core/domain/citation-charges/state/data';
import { NEXUS_STATE_PROP as ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-reporting-event-links/state/data';
import { NEXUS_STATE_PROP as COURT_CASES_NEXUS_STATE_PROP } from '~/client-common/core/domain/court-cases/state/data';
import { NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-report-links/state/data';
import { NEXUS_STATE_PROP as STOP_ANONYMOUS_SUBJECTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/stop-anonymous-subjects/state/data';
import { NEXUS_STATE_PROP as RIPA_OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/ripa-offense-codes/state/data';
import { NEXUS_STATE_PROP as RIPA_SUBJECT_OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/ripa-subject-offense-codes/state/data';
import { NEXUS_STATE_PROP as DOJ_SCHOOLS_NEXUS_STATE_PROP } from '~/client-common/core/domain/doj-schools/state/data';
import { NEXUS_STATE_PROP as ATF_MANUFACTURERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/etrace-atf/state/data';
import { NEXUS_STATE_PROP as STOP_ENTITY_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/stop-entity-attributes/state/data';
import { NEXUS_STATE_PROP as EMPLOYMENT_HISTORIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/employment-histories/state/data';
import { NEXUS_STATE_PROP as FIREARMS_NEXUS_STATE_PROP } from '~/client-common/core/domain/firearms/state/data';
import { NEXUS_STATE_PROP as FIELD_CONTACTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/field-contacts/state/data';
import { NEXUS_STATE_PROP as REPORT_LEGACY_METADATAS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-legacy-metadatas/state/data';
import { NEXUS_STATE_PROP as ID_FORMAT_CONFIGURATION_NEXUS_STATE_PROP } from '~/client-common/core/domain/id-format-configurations/state/data';
import { NEXUS_STATE_PROP as IDENTIFYING_MARKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/identifying-marks/state/data';
import { NEXUS_STATE_PROP as IMAGES_NEXUS_STATE_PROP } from '~/client-common/core/domain/images/state/data';
import { NEXUS_STATE_PROP as IMPOUNDS_NEXUS_STATE_PROP } from '~/client-common/core/domain/impounds/state/data';
import { NEXUS_STATE_PROP as ITEM_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-attributes/state/data';
import { NEXUS_STATE_PROP as ITEM_IDENTIFIERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-identifiers/state/data';
import { NEXUS_STATE_PROP as ITEM_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-profiles/state/data';
import { NEXUS_STATE_PROP as LEGACY_ENTITY_DETAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/legacy-entity-details/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/location-entity-links/state/data';
import { NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/locations/state/data';
import { NEXUS_STATE_PROP as MINI_USERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/mini-users/state/data';
import { NEXUS_STATE_PROP as MISSING_PERSONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/missing-persons/state/data';
import { NEXUS_STATE_PROP as NAME_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-attributes/state/data';
import { NEXUS_STATE_PROP as NAME_EMAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-emails/state/data';
import { NEXUS_STATE_PROP as NAME_IDENTIFIERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-identifiers/state/data';
import { NEXUS_STATE_PROP as NAME_ITEM_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-item-links/state/data';
import { NEXUS_STATE_PROP as NAME_MONIKERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-monikers/state/data';
import { NEXUS_STATE_PROP as NAME_NAME_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-name-links/state/data';
import { NEXUS_STATE_PROP as NAME_PHONES_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-phones/state/data';
import { NEXUS_STATE_PROP as NARRATIVE_AUTOSAVE_NEXUS_STATE_PROP } from '~/client-common/core/domain/narrative-autosave/state/data';
import { NEXUS_STATE_PROP as OFFENSE_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-attributes/state/data';
import { NEXUS_STATE_PROP as OFFENSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offenses/state/data';
import { NEXUS_STATE_PROP as ORGANIZATION_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/organization-profiles/state/data';
import { NEXUS_STATE_PROP as PERSON_EMERGENCY_CONTACTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-emergency-contacts/state/data';
import { NEXUS_STATE_PROP as PASSPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/passports/state/data';
import { NEXUS_STATE_PROP as PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-gang-trackings/state/data';
import { NEXUS_STATE_PROP as PERSON_INJURIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-injuries/state/data';
import { NEXUS_STATE_PROP as PERSON_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-profiles/state/data';
import { NEXUS_STATE_PROP as PERSON_PROBATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-probations/state/data';
import { NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-attributes/state/data';
import { NEXUS_STATE_PROP as REPORT_CASE_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-case-statuses/state/data';
import { NEXUS_STATE_PROP as REPORT_EXTERNAL_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-external-links/state/data';
import { NEXUS_STATE_PROP as REPORT_NOTIFICATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-notifications/state/data';
import { NEXUS_STATE_PROP as REPORT_REPORT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-report-links/state/data';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { NEXUS_STATE_PROP as REPORT_STATUS_HISTORIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-status-histories/state/data';
import { NEXUS_STATE_PROP as REPORT_SUBMISSION_AUTHORS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-submission-authors/state/data';
import { NEXUS_STATE_PROP as REPORT_UCRS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-ucrs/state/data';
import { NEXUS_STATE_PROP as ROLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/roles/state/data';
import { NEXUS_STATE_PROP as SCHOOL_HISTORIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/school-histories/state/data';
import { NEXUS_STATE_PROP as STOPS_NEXUS_STATE_PROP } from '~/client-common/core/domain/stops/state/data';
import { NEXUS_STATE_PROP as SUMMARY_NARRATIVE_NEXUS_STATE_PROP } from '~/client-common/core/domain/summary-narratives/state/data';
import { NEXUS_STATE_PROP as TOW_VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/tow-vehicles/state/data';
import { NEXUS_STATE_PROP as TOW_VEHICLE_CHECK_INS_NEXUS_STATE_PROP } from '~/client-common/core/domain/tow-vehicle-check-ins/state/data';
import { NEXUS_STATE_PROP as TOW_VEHICLE_RELEASES_NEXUS_STATE_PROP } from '~/client-common/core/domain/tow-vehicle-releases/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASHES_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crashes/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASH_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crash-attributes/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASH_PERSONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crash-persons/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASH_PERSON_OFFENSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crash-person-offenses/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASH_VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crash-vehicles/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASH_ENTITY_DETAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crash-entity-details/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASH_ROADWAYS_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crash-roadways/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASH_ROADWAY_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crash-roadway-entity-links/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASH_CUSTOM_FIELDS_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crash-custom-fields/state/data';
import { NEXUS_STATE_PROP as TRAFFIC_CRASH_CUSTOM_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/traffic-crash-custom-attributes/state/data';
import { NEXUS_STATE_PROP as UCR_EVENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/ucr-events/state/data';
import { NEXUS_STATE_PROP as UCR_OFFENSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/ucr-offenses/state/data';
import { NEXUS_STATE_PROP as UCR_PROPERTY_NEXUS_STATE_PROP } from '~/client-common/core/domain/ucr-property/state/data';
import { NEXUS_STATE_PROP as UK_OFFENSE_CODE_EXTENSIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/uk-offense-code-extensions/state/data';
import { NEXUS_STATE_PROP as USE_OF_FORCES_NEXUS_STATE_PROP } from '~/client-common/core/domain/use-of-forces/state/data';
import { NEXUS_STATE_PROP as USE_OF_FORCE_SUBJECTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/use-of-force-subjects/state/data';
import { NEXUS_STATE_PROP as USE_OF_FORCE_SUBJECT_DE_ESCALATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/use-of-force-subject-de-escalations/state/data';
import { NEXUS_STATE_PROP as USER_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/user-profiles/state/data';
import { NEXUS_STATE_PROP as VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicles/state/data';
import { NEXUS_STATE_PROP as CAUTIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/cautions/state/data';
import { NEXUS_STATE_PROP as VEHICLE_MAKES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicle-makes/state/data';
import { NEXUS_STATE_PROP as VEHICLE_MODELS_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicle-models/state/data';
import { NEXUS_STATE_PROP as WARRANT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrant-titles/state/data';
import { NEXUS_STATE_PROP as WARRANTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrants/state/data';
import { NEXUS_STATE_PROP as WARRANT_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrant-statuses/state/data';
import { NEXUS_STATE_PROP as COURT_ORDERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/court-orders/state/data';
import { NEXUS_STATE_PROP as REPORT_COMMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-comments/state/data';
import { NEXUS_STATE_PROP as REPORT_INLINE_COMMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/inline-report-comments/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CHILDREN_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-involved-children/state/data';
import { NEXUS_STATE_PROP as EXTERNAL_REPORT_STATUS_NEXUS_STATE_PROP } from '~/client-common/core/domain/external-report-statuses/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CASE_STATUS_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-case-statuses/state/data';
import { NEXUS_STATE_PROP as ENTITY_ORDERED_ATTRIBUTE_NEXUS_STATE_PROP } from '~/client-common/core/domain/entity-ordered-attributes/state/data';
import { NEXUS_STATE_PROP as USE_OF_FORCE_OTHER_INVOLVED_AGENCIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/other-involved-agencies/state/data';
import { NEXUS_STATE_PROP as EVIDENCE_LOCATION_PERMISSONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/evidence-location-permissions/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NIBRS_CODE_LINKS_PROP } from '~/client-common/core/domain/offense-code-nibrs-code-links/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_HOC_CATEGORY_LINKS_PROP } from '~/client-common/core/domain/offense-code-hoc-category-links/state/data';
import { NEXUS_STATE_PROP as OFFENSE_SUB_CRIME_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-sub-crime-links/state/data';
import { NEXUS_STATE_PROP as CORE_MODEL_CONFIGURATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/core-model-configurations/state/data';
import { NEXUS_STATE_PROP as CRASH_DETAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/crash-details/state/data';
import { NEXUS_STATE_PROP as SECURITY_CLASSIFICATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/security-classifications/state/data';
import { NEXUS_STATE_PROP as CRASH_LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/crash-locations/state/data';

// THIS IS INTENTIONALLY OMITTED FROM dataNexusKeys BELOW, DO NOT ADD IT UNLESS YOU'RE SURE THATS WHAT YOU WANT
import { NEXUS_STATE_PROP as PROPERTY_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/property-statuses/state/data';
// client-common
import { formatLinkedReportDisplayTitle } from '~/client-common/core/domain/reports/utils/reportsHelpers';

import environmentEnum from '~/client-common/core/enums/client/environmentEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import errorCodeEnum from '~/client-common/core/enums/client/errorCodeEnum';
import { storeEventDetails } from '~/client-common/core/domain/event-details/state/data';
import { otherUserId } from '~/client-common/helpers/userHelpers';
import {
    NEXUS_STATE_PROP as REPORT_DEFINITIONS_NEXUS_STATE_PROP,
    reportDefinitionByIdSelector,
    reportDefinitionHasCardSelector,
} from '~/client-common/core/domain/report-definitions/state/data';
import { reportDefinitionHasCustodialPropertySummaryCard } from '~/client-common/helpers/reportDefinitionsHelpers';
import { userProfileToMiniUser } from '~/client-common/core/domain/mini-users/utils/miniUsersHelpers';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { augmentOffenseCodeWithOffenseCodeView } from '~/client-common/helpers/offenseCodesHelpers';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { withRemove } from '~/client-common/core/utils/nexusHelpers';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import {
    startPollingForCurrentViewers,
    stopPollingForCurrentViewers,
} from '~/client-common/core/domain/current-viewers/state/polling';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import getReportHistoryResource from '~/client-common/core/domain/reports/resources/reportHistoryResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data/';

// local
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { createUsageLog } from '../../../../admin/usage-logs/state/data';
import {
    currentReportIdSelector,
    currentReportSelector,
    isReportNewSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import redirectToErrorPage from '../../../../core/utils/redirectToErrorPage';
import errors from '../../../../../lib/errors';
import reportsActionTypes from '../../../../../legacy-redux/actions/types/reportsActionTypes';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { loadEvidenceItemsLite } from '../../../../evidence/core/state/data/evidenceItems';
import {
    clearAllReportUiData,
    fetchReport,
    fetchReportFailure,
    fetchReportRelatedEntitiesSuccess,
    fetchReportSuccess,
    setLastCreatedReportId,
} from '../../../../../legacy-redux/actions/reportsActions';
import { loadAttributesForType } from '../../../../core/attributes/state/ui/loadAttributesForType';
import { loadEvidenceDataForReportId } from '../../../custodial-property-summary/state/ui';
import personnelCard from '../../../custodial-property-summary/state/ui/personnelCard';
import custodialPropertyCard from '../ui/custodialPropertyCard';
import attachmentsCard from '../../../../warrants/warrant/state/cards/attachmentsCard';
import useOfForceCard from '../ui/useOfForceCard';
import citationCard from '../ui/citationCard';
import chargesCard from '../ui/chargesCard';
import bookingCard from '../ui/bookingCard';
import stopCard from '../ui/stopCard';
import fieldContactCard from '../ui/fieldContactCard';
import reportLegacyMetadataCard from '../ui/reportLegacyMetadataCard';
import missingPersonsCard from '../ui/missingPersonsCard';
import communityInfoCard from '../ui/communityInfoCard';
import towVehicleImpoundCard from '../ui/towVehicleImpoundCard';
import useOfForceSubjectCard from '../ui/useOfForceSubjectCard';
import impoundCard from '../ui/impoundCard';
import towVehicleReleaseCard from '../ui/towVehicleReleaseCard';
import towVehicleCheckInCard from '../ui/towVehicleCheckInCard';
import relationshipsCard from '../ui/relationshipsCard';
import sealingResource from '../../../../record-privacy/core/resources/sealingResource';
import { resetCard, initCards, visibleEmbeddedReportIdsSelector } from '../ui';
import arrestCard from '../ui/arrestCard';
import courtCaseCard from '../ui/courtCaseCard';
import propertyCard from '../ui/propertyCard';
import vehicleCard from '../ui/vehicleCard';
import towVehicleCard from '../ui/towVehicleCard';
import behavioralCrisisCard from '../ui/behavioralCrisisCard';
import trafficCrashCard from '../ui/trafficCrashCard';
import involvedProfilesCard from '../ui/involvedProfilesCard';
import supplementInfoCard from '../ui/supplementInfoCard';
import reportStatusCommentsCard from '../ui/reportStatusCommentsCard';
import offenseCards from '../ui/offenseCards';
import summaryNarrativeCard from '../ui/summaryNarrativeCard';
import narrativeCard from '../ui/narrativeCard';
import { unregisterAllCards } from '../../utils/cardsRegistry';
import { clearAllRecentEntities } from '../../../../../legacy-redux/actions/recentEntitiesActions';
import formsRegistry from '../../../../../core/formsRegistry';
import { dragonDataSelector } from '../../../../dragon/dragonRedux';

import { openPrefillModals } from './reportPrefillModals';

export const dataNexusEntityKeys = [
    AGENCY_PROFILES_NEXUS_STATE_PROP,
    ARREST_ATTRIBUTES_NEXUS_STATE_PROP,
    ARRESTS_NEXUS_STATE_PROP,
    ASSISTING_OFFICERS_NEXUS_STATE_PROP,
    ATTACHMENTS_NEXUS_STATE_PROP,
    BEHAVIORAL_CRISES_NEXUS_STATE_PROP,
    CAD_TICKETS_NEXUS_STATE_PROP,
    CHARGES_NEXUS_STATE_PROP,
    CITATIONS_NEXUS_STATE_PROP,
    CITATION_CHARGES_NEXUS_STATE_PROP,
    COURT_CASES_NEXUS_STATE_PROP,
    CRASH_DETAILS_NEXUS_STATE_PROP,
    CRASH_LOCATIONS_NEXUS_STATE_PROP,
    DETENTIONS_NEXUS_STATE_PROP,
    PERSON_EMERGENCY_CONTACTS_NEXUS_STATE_PROP,
    PASSPORTS_NEXUS_STATE_PROP,
    EMPLOYMENT_HISTORIES_NEXUS_STATE_PROP,
    ENTITY_ORDERED_ATTRIBUTE_NEXUS_STATE_PROP,
    FIELD_CONTACTS_NEXUS_STATE_PROP,
    REPORT_LEGACY_METADATAS_NEXUS_STATE_PROP,
    FIREARMS_NEXUS_STATE_PROP,
    ID_FORMAT_CONFIGURATION_NEXUS_STATE_PROP,
    IDENTIFYING_MARKS_NEXUS_STATE_PROP,
    IMAGES_NEXUS_STATE_PROP,
    IMPOUNDS_NEXUS_STATE_PROP,
    ITEM_IDENTIFIERS_NEXUS_STATE_PROP,
    ITEM_PROFILES_NEXUS_STATE_PROP,
    LEGACY_ENTITY_DETAILS_NEXUS_STATE_PROP,
    LOCATIONS_NEXUS_STATE_PROP,
    LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
    MISSING_PERSONS_NEXUS_STATE_PROP,
    NAME_ATTRIBUTES_NEXUS_STATE_PROP,
    NAME_EMAILS_NEXUS_STATE_PROP,
    NAME_IDENTIFIERS_NEXUS_STATE_PROP,
    NAME_ITEM_LINKS_NEXUS_STATE_PROP,
    NAME_MONIKERS_NEXUS_STATE_PROP,
    NAME_NAME_LINKS_NEXUS_STATE_PROP,
    NAME_PHONES_NEXUS_STATE_PROP,
    NAME_REPORT_LINKS_NEXUS_STATE_PROP,
    STOP_ANONYMOUS_SUBJECTS_NEXUS_STATE_PROP,
    RIPA_OFFENSE_CODES_NEXUS_STATE_PROP,
    RIPA_SUBJECT_OFFENSE_CODES_NEXUS_STATE_PROP,
    DOJ_SCHOOLS_NEXUS_STATE_PROP,
    ATF_MANUFACTURERS_NEXUS_STATE_PROP,
    STOP_ENTITY_ATTRIBUTES_NEXUS_STATE_PROP,
    NARRATIVE_AUTOSAVE_NEXUS_STATE_PROP,
    OFFENSE_ATTRIBUTES_NEXUS_STATE_PROP,
    OFFENSE_CHILDREN_NEXUS_STATE_PROP,
    OFFENSE_CASE_STATUS_NEXUS_STATE_PROP,
    EXTERNAL_REPORT_STATUS_NEXUS_STATE_PROP,
    ORGANIZATION_PROFILES_NEXUS_STATE_PROP,
    PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP,
    PERSON_INJURIES_NEXUS_STATE_PROP,
    PERSON_PROFILES_NEXUS_STATE_PROP,
    REPORT_ATTRIBUTES_NEXUS_STATE_PROP,
    REPORT_CASE_STATUSES_NEXUS_STATE_PROP,
    REPORT_DEFINITIONS_NEXUS_STATE_PROP,
    REPORT_EXTERNAL_LINKS_NEXUS_STATE_PROP,
    REPORT_NOTIFICATIONS_NEXUS_STATE_PROP,
    REPORT_REPORT_LINKS_NEXUS_STATE_PROP,
    REPORT_SHORT_TITLES_NEXUS_STATE_PROP,
    REPORT_STATUS_HISTORIES_NEXUS_STATE_PROP,
    REPORT_SUBMISSION_AUTHORS_NEXUS_STATE_PROP,
    PERSON_PROBATIONS_NEXUS_STATE_PROP,
    REPORT_UCRS_NEXUS_STATE_PROP,
    SCHOOL_HISTORIES_NEXUS_STATE_PROP,
    STOPS_NEXUS_STATE_PROP,
    SUMMARY_NARRATIVE_NEXUS_STATE_PROP,
    TOW_VEHICLES_NEXUS_STATE_PROP,
    TOW_VEHICLE_CHECK_INS_NEXUS_STATE_PROP,
    TOW_VEHICLE_RELEASES_NEXUS_STATE_PROP,
    TRAFFIC_CRASHES_NEXUS_STATE_PROP,
    TRAFFIC_CRASH_ATTRIBUTES_NEXUS_STATE_PROP,
    TRAFFIC_CRASH_ENTITY_DETAILS_NEXUS_STATE_PROP,
    TRAFFIC_CRASH_PERSONS_NEXUS_STATE_PROP,
    TRAFFIC_CRASH_PERSON_OFFENSES_NEXUS_STATE_PROP,
    TRAFFIC_CRASH_VEHICLES_NEXUS_STATE_PROP,
    TRAFFIC_CRASH_ROADWAYS_NEXUS_STATE_PROP,
    TRAFFIC_CRASH_ROADWAY_ENTITY_LINKS_NEXUS_STATE_PROP,
    TRAFFIC_CRASH_CUSTOM_FIELDS_NEXUS_STATE_PROP,
    TRAFFIC_CRASH_CUSTOM_ATTRIBUTES_NEXUS_STATE_PROP,
    UCR_EVENTS_NEXUS_STATE_PROP,
    UCR_OFFENSES_NEXUS_STATE_PROP,
    UCR_PROPERTY_NEXUS_STATE_PROP,
    USE_OF_FORCE_SUBJECTS_NEXUS_STATE_PROP,
    USE_OF_FORCE_SUBJECT_DE_ESCALATIONS_NEXUS_STATE_PROP,
    USE_OF_FORCES_NEXUS_STATE_PROP,
    USER_PROFILES_NEXUS_STATE_PROP,
    CAUTIONS_NEXUS_STATE_PROP,
    VEHICLES_NEXUS_STATE_PROP,
    VEHICLE_MAKES_NEXUS_STATE_PROP,
    VEHICLE_MODELS_NEXUS_STATE_PROP,
    WARRANTS_NEXUS_STATE_PROP,
    WARRANT_STATUSES_NEXUS_STATE_PROP,
    COURT_ORDERS_NEXUS_STATE_PROP,
    REPORT_COMMENTS_NEXUS_STATE_PROP,
    ITEM_ATTRIBUTES_NEXUS_STATE_PROP,
    USE_OF_FORCE_OTHER_INVOLVED_AGENCIES_NEXUS_STATE_PROP,
    EVIDENCE_LOCATION_PERMISSONS_NEXUS_STATE_PROP,
    OFFENSES_NEXUS_STATE_PROP,
    ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP,
    OFFENSE_SUB_CRIME_LINKS_NEXUS_STATE_PROP,
    CORE_MODEL_CONFIGURATIONS_NEXUS_STATE_PROP,
    SECURITY_CLASSIFICATIONS_NEXUS_STATE_PROP,
];

const fetchReportAndRelatedEntities = (reportId, hasCourtOrderViewAbility, rmsEventId) => {
    // we catch for either related entity call because the report load should
    // continue even if the call to related entities fails
    if (rmsEventId) {
        return Promise.all([
            getReportHistoryResource().getFullReportSnapshot(reportId, rmsEventId),
            getReportHistoryResource()
                .getReportShortTitleForReportSnapshot(reportId, rmsEventId)
                .catch(identity),
        ]);
    } else {
        return Promise.all([
            getReportResource().getReportView(reportId),
            getReportResource().getReportRelatedEntities(reportId).catch(identity),
            hasCourtOrderViewAbility
                ? sealingResource().loadCourtOrders(reportId).catch(identity)
                : Promise.resolve(),
        ]);
    }
};

const recordUsageLog = ({
    usageCompletion,
    reportId,
    primaryEntityTitle = '',
    primaryEntityDepartmentId,
}) => {
    return (dispatch) =>
        dispatch(
            createUsageLog({
                primaryEntityType: EntityTypeEnum.REPORT.name,
                primaryEntityTitle,
                primaryEntityId: reportId,
                primaryEntityDepartmentId,
                action: UsageActionEnum.VIEWED_REPORT.name,
                completion: usageCompletion,
                sourceModule: UsageSourceModuleEnum.REPORTS.name,
            })
        );
};

const handleFetchReportFailure = (err, params) => {
    return (dispatch) => {
        dispatch(fetchReportFailure(err));
        let errorCode;
        if (err instanceof errors.NotFoundError) {
            // this _likely_ means "insufficient permissions", but we
            // do not differentiate 403/404 b/c "security", so don't
            // recordUsageLog b/c we don't know what happened
        } else if (err instanceof errors.InsufficientPermissionsError) {
            dispatch(
                recordUsageLog({
                    usageCompletion: UsageCompletionEnum.INSUFFICIENT_PERMISSIONS.name,
                    reportId: params.reportId,
                })
            );
            errorCode = errorCodeEnum.FORBIDDEN;
        } else if (err instanceof errors.FeatureFlagDisabledError) {
            dispatch(
                recordUsageLog({
                    usageCompletion: UsageCompletionEnum.FEATURE_FLAGGED_OFF.name,
                    reportId: params.reportId,
                })
            );
            errorCode = errorCodeEnum.FORBIDDEN;
        } else if (err instanceof errors.InternalServerError) {
            dispatch(
                recordUsageLog({
                    usageCompletion: UsageCompletionEnum.SERVER_ERROR.name,
                    reportId: params.reportId,
                })
            );
            errorCode = errorCodeEnum.INTERNAL_SERVER_ERROR;
        } else if (err instanceof errors.UnauthorizedError) {
            dispatch(
                recordUsageLog({
                    usageCompletion: UsageCompletionEnum.UNAUTHORIZED.name,
                    reportId: params.reportId,
                })
            );
            errorCode = errorCodeEnum.UNAUTHORIZED;
        } else {
            dispatch(
                recordUsageLog({
                    usageCompletion: UsageCompletionEnum.SERVER_ERROR.name,
                    reportId: params.reportId,
                })
            );
            errorCode = errorCodeEnum.NOT_FOUND; // default error code
        }

        errorCode = err.code || errorCode;
        dispatch(redirectToErrorPage({ errorCode }));

        const handledErrors = [
            errors.NotFoundError,
            errors.InsufficientPermissionsError,
            errors.FeatureFlagDisabledError,
            errors.InternalServerError,
            errors.UnauthorizedError,
        ];

        if (typeof MARK43_ENV !== 'undefined') {
            if (MARK43_ENV === environmentEnum.DEVELOPER) {
                throw err;
            } else {
                if (!some(handledErrors, (errorType) => err instanceof errorType)) {
                    Sentry.withScope((scope) => {
                        scope.setTag('module', UsageSourceModuleEnum.REPORTS.name);
                        scope.setExtra('params', params);
                        Sentry.captureException(err);
                    });
                }
            }
        }
        dispatch((dispatch, getState, { overlayStore }) => {
            overlayStore.close(overlayIdEnum.REPORT_LOADING_MODAL);
        });
    };
};

const storeSealingResponse = (completeClientReportView, sealingResponse) => {
    return (dispatch, getState, dependencies) => {
        const attachments = get(completeClientReportView, 'attachments') || [];
        const courtOrders =
            get(sealingResponse, 'courtOrders') || get(completeClientReportView, 'courtOrders');
        const courtOrderAttachments = get(sealingResponse, 'courtOrderAttachments') || [];
        const entitiesToStore = {};
        entitiesToStore[COURT_ORDERS_NEXUS_STATE_PROP] = courtOrders;
        entitiesToStore[ATTACHMENTS_NEXUS_STATE_PROP] = unionBy(
            [...attachments, ...courtOrderAttachments],
            'id'
        );
        // hardcoded string as action type here because nothing is listening for this
        // but we have to provide a type in order to dispatch an action.
        // this will be removed as soon as we remove KO/BB from our codebase.
        const action = dependencies.nexus.withEntityItems(entitiesToStore, {
            type: 'LOAD_COURT_ORDERS_SUCCESS',
        });
        dispatch(action);
    };
};

export const loadReportOnEnter = (params, args) => {
    const { dispatch, rmsEventId, getState, reportIsNew } = args;
    const isSnapshot = !!rmsEventId;
    const hasCourtOrderViewAbility = currentUserHasAbilitySelector(getState())(
        abilitiesEnum.REPORTING.VIEW_COURT_ORDERS
    );

    const isPrefillEnabled =
        applicationSettingsSelector(getState()).RMS_EVENT_SUPP_INFO_PREFILL_ENABLED;

    dispatch(fetchReport(params.reportId));
    fetchReportAndRelatedEntities(params.reportId, hasCourtOrderViewAbility, rmsEventId)
        .then(([completeClientReportView, reportRelatedEntities, sealingResponse]) => {
            return dispatch(
                handleFetchReportSuccess(
                    completeClientReportView,
                    reportRelatedEntities,
                    reportIsNew
                )
            ).then((successAction) => {
                const {
                    departmentId,
                    id: reportId,
                    reportDefinitionId,
                } = completeClientReportView.report;
                successAction.payload.isSnapshot = isSnapshot;

                dispatch(startPollingForCurrentViewers(EntityTypeEnum.REPORT.name, reportId));
                const reportShortTitle = find(reportRelatedEntities.reportShortTitles, {
                    reportId,
                });
                const shortTitleWithRen = formatLinkedReportDisplayTitle(reportShortTitle);
                dispatch(
                    recordUsageLog({
                        usageCompletion: UsageCompletionEnum.SUCCEEDED.name,
                        primaryEntityDepartmentId: departmentId,
                        reportId,
                        primaryEntityTitle: shortTitleWithRen,
                    })
                );
                dispatch(successAction);

                if (!isSnapshot && isPrefillEnabled) {
                    dispatch(openPrefillModals(completeClientReportView.report));
                }
                dispatch(resetCard({ module: arrestCard, editMode: reportIsNew }));
                dispatch(
                    resetCard({
                        module: bookingCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: chargesCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: propertyCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: vehicleCard,
                        editMode: reportIsNew,
                    })
                );
                const reportDefinitionHasCard = reportDefinitionHasCardSelector(getState());
                const isOmsReport =
                    reportDefinitionHasCard(
                        reportDefinitionId,
                        reportCardEnum.SUPPLEMENT_OFFENSE.id
                    ) ||
                    reportDefinitionHasCard(
                        reportDefinitionId,
                        reportCardEnum.SUPPLEMENT_INCIDENT.id
                    );
                if (isOmsReport) {
                    dispatch(resetCard({ module: offenseCards, editMode: false }));
                } else {
                    dispatch(
                        resetCard({
                            module: offenseCards,
                            editMode: reportIsNew,
                        })
                    );
                }
                // these should be refactored into a separate method that resets them conditionally
                // see tw-858
                dispatch(
                    resetCard({
                        module: citationCard,
                        editMode: reportIsNew,
                    })
                );
                // there may be multiple attachments cards rendered on this page (in a previously visited route), and we
                // always initialize with only 1 card, hence this bulk reset/init
                dispatch(
                    initCards({
                        cardModule: attachmentsCard,
                        editMode: reportIsNew,
                        options: { indexes: [reportId] },
                    })
                );
                // there may be multiple vehicle cards with streamlined report writing feature
                dispatch(
                    initCards({
                        cardModule: vehicleCard,
                        editMode: reportIsNew,
                        options: { indexes: [reportId] },
                    })
                );
                dispatch(resetCard({ module: stopCard, editMode: reportIsNew }));
                dispatch(
                    resetCard({
                        module: fieldContactCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: reportLegacyMetadataCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: missingPersonsCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: communityInfoCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: impoundCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: relationshipsCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: useOfForceCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: courtCaseCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: towVehicleReleaseCard,
                        editMode: false,
                    })
                );
                dispatch(
                    resetCard({
                        module: towVehicleCheckInCard,
                        editMode: false,
                    })
                );
                dispatch(
                    resetCard({
                        module: towVehicleCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: towVehicleImpoundCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: behavioralCrisisCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: trafficCrashCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: useOfForceSubjectCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: involvedProfilesCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: supplementInfoCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    resetCard({
                        module: reportStatusCommentsCard,
                        editMode: reportIsNew,
                    })
                );
                dispatch(
                    summaryNarrativeCard.actionCreators.createNewCard({
                        index: reportId,
                        summaryMode: !reportIsNew,
                        reportId,
                    })
                );
                dispatch(
                    narrativeCard.actionCreators.createNewCard({
                        index: reportId,
                        summaryMode: !reportIsNew,
                        reportId,
                    })
                );

                dispatch((dispatch, getState, { overlayStore }) => {
                    overlayStore.close(overlayIdEnum.REPORT_LOADING_MODAL);
                });
                dispatch(
                    loadAttributesForType({
                        attributeType: [
                            AttributeTypeEnum.COURT_CODE.name,
                            AttributeTypeEnum.ETHNICITY.name,
                            AttributeTypeEnum.RACE.name,
                            AttributeTypeEnum.SEX.name,
                        ],
                    })
                );
                if (hasCourtOrderViewAbility && sealingResponse) {
                    dispatch(storeSealingResponse(completeClientReportView, sealingResponse));
                }
            });
        })
        .then(() => {
            // force getState because state is stale
            const isCustodial = currentReportIsCustodialSelector(getState());
            if (isCustodial) {
                // AFTER data fetching and state management,
                // ui updates that are dependent on state being hydrated
                dispatch(personnelCard.actionCreators.resetState());
                dispatch(custodialPropertyCard.actionCreators.resetState());
                // the attachments card is handled above, the same way as for non-CPS reports
            }

            if (isCustodial && reportIsNew) {
                dispatch(custodialPropertyCard.actionCreators.transitionToEditMode());
            }
        })
        .catch((err) => {
            dispatch(handleFetchReportFailure(err, params));
        });
};

export function reportOnEnter({ params }) {
    const { dispatch, getState } = this;
    const state = getState();
    const reportIsNew = isReportNewSelector(state)(parseInt(params.reportId));
    dispatch((dispatch, getState, { overlayStore }) => {
        overlayStore.open(overlayIdEnum.REPORT_LOADING_MODAL);
    });
    const args = { dispatch, getState, reportIsNew };
    loadReportOnEnter(params, args);
}

export function reportSnapshotOnEnter({ params, location }) {
    const { dispatch, getState } = this;
    dispatch((dispatch, getState, { overlayStore }) => {
        overlayStore.open(overlayIdEnum.REPORT_LOADING_MODAL);
    });
    const rmsEventId = location.query.rms_event_id;
    if (!rmsEventId) {
        dispatch(handleFetchReportFailure(new errors.NotFoundError(), params));
        return;
    }

    const args = { dispatch, getState, reportIsNew: false, rmsEventId };
    loadReportOnEnter(params, args);
}

export function reportOnLeave() {
    const { dispatch, getState } = this;
    const state = getState();

    const reportId = currentReportIdSelector(state);
    const dragonDataState = dragonDataSelector(state)[reportId]?.form;
    if (dragonDataState && dragonDataState.formValues) {
        const formsRegistryKeys = dragonDataState.formValues.map((formValue) => {
            return formValue.formConfigurationId;
        });

        for (const formsRegistryKey of formsRegistryKeys) {
            // put through string interpolation because this is otherwise a number
            // and causes a runtime error in mft registry code
            formsRegistry.unregister(`${formsRegistryKey}`, 0);
        }
    }

    // Must be done before we start clearing state
    const isReportNew = isReportNewSelector(state);
    const visibleEmbeddedReportIds = visibleEmbeddedReportIdsSelector(state);
    const shouldClearLastCreatedReportId = some(
        [reportId, ...visibleEmbeddedReportIds],
        (reportId) => isReportNew(reportId)
    );
    const currentReportIsCustodial = currentReportIsCustodialSelector(state);
    // unsubscribe from current viewers
    dispatch(
        stopPollingForCurrentViewers(EntityTypeEnum.REPORT.name, currentReportIdSelector(state))
    );

    // evict data from state that doesn't need to be retained
    const withRemoveC = curry(withRemove);
    const nexusKeysToClear = without(
        // REPORTS_NEXUS_STATE_PROP need to be removed but are handled separately
        // because the key name is non-standard (reportsTODO)
        [...dataNexusEntityKeys, REPORTS_NEXUS_STATE_PROP],
        // Select dropdowns for Agency Profiles and Report Definitions need to keep these
        AGENCY_PROFILES_NEXUS_STATE_PROP,
        REPORT_DEFINITIONS_NEXUS_STATE_PROP,
        USER_PROFILES_NEXUS_STATE_PROP
    );
    const withRemovals = flowRight(
        ...map(nexusKeysToClear, (entityKey) => withRemoveC(entityKey, {}))
    );
    const leaveAction = withRemovals({ type: reportsActionTypes.LEAVE_REPORT });

    if (currentReportIsCustodial) {
        dispatch(personnelCard.actionCreators.resetState());
        dispatch(custodialPropertyCard.actionCreators.resetState());
    }
    dispatch(attachmentsCard.actionCreators.resetCardsState());

    dispatch(leaveAction);
    dispatch(clearAllReportUiData());

    if (shouldClearLastCreatedReportId) {
        dispatch(setLastCreatedReportId(null));
    }

    dispatch(clearAllRecentEntities());

    unregisterAllCards();
}

const augmentActionWithPropertyChanges = (action, nexus) => {
    const propertyStatuses = action.payload.propertyStatuses || [];
    // we removed `userIdOther` value when saving propertyStatuses to
    // avoid an error on the server - when necessary, we want to put this value back
    // after a successful remote call so that it does not cause issues in the UI
    const propertyStatusesUpdated = map(propertyStatuses, (propertyStatus) => {
        return !propertyStatus.recoveredByOfficerId && propertyStatus.recoveredByOtherName
            ? {
                  ...propertyStatus,
                  recoveredByOfficerId: otherUserId,
              }
            : propertyStatus;
    });

    const removePropertyStatuses = chain(propertyStatusesUpdated)
        .groupBy('itemProfileId')
        .keys()
        .map(parseInt)
        .value();

    const actionWithAdditions = nexus.withEntityItems(
        { [PROPERTY_STATUSES_NEXUS_STATE_PROP]: propertyStatusesUpdated },
        action
    );
    const actionWithAdditionsAndRemovals = nexus.withRemove(
        PROPERTY_STATUSES_NEXUS_STATE_PROP,
        map(removePropertyStatuses, (itemProfileId) => ({ itemProfileId })),
        actionWithAdditions
    );
    return actionWithAdditionsAndRemovals;
};

const concatMerge = (obj1, obj2) => {
    return mergeWith(obj1, obj2, (objValue, srcValue) => {
        if (isArray(objValue)) {
            return objValue.concat(srcValue);
        }
        // see comment below on why this is done
        return objValue;
    });
};

/**
 * After successfully fetching report data, set data/ui state for the report.
 * @param {Object} completeClientReportView Complete Client Report View
 * @param {Object} reportRelatedEntities Report Related Entities
 */
const handleFetchReportSuccess = (completeClientReportView, reportRelatedEntities, reportIsNew) => {
    return (dispatch, getState, dependencies) => {
        const state = getState();

        const applicationSettings = applicationSettingsSelector(getState());

        const {
            reportShortTitles,
            linkedReportIds,
            caseTitles,
            linkedCaseIds,
            caseDefinitions,
            attributes,
            roles,
            warrantTitles,
            linkedWarrantIds,
        } = reportRelatedEntities;

        const renOffenseReport = get(
            completeClientReportView,
            'reportSupplementView.renOffenseFlatHydratedReport'
        );

        // these are fields that aren't arrays on the hydrated report and must be handled separately
        const reports = compact([
            completeClientReportView.report,
            ...values(completeClientReportView.reportsForOutsideOffenses),
            ...completeClientReportView.outsideArrestReports,
        ]);
        if (renOffenseReport) {
            // the order here is important, this will mutate completeClientReportView, but because how concatMerge is written
            // the original value will be returned for non-array fields
            // and renOffenseReport will not change
            completeClientReportView = concatMerge(completeClientReportView, renOffenseReport);
            reports.push(
                ...compact([
                    renOffenseReport.report,
                    ...values(renOffenseReport.reportsForOutsideOffenses),
                ])
            );
        }

        let baseAction = fetchReportSuccess(completeClientReportView);

        baseAction = dependencies.nexus.withEntityItems(
            {
                // From Complete Client Report View
                // First, naively pick some properties
                ...pick(completeClientReportView, dataNexusEntityKeys),
                [CORE_MODEL_CONFIGURATIONS_NEXUS_STATE_PROP]: values(
                    completeClientReportView.coreModelConfigurations
                ),
                // Then multi agency configurables
                [USER_PROFILES_NEXUS_STATE_PROP]: map(
                    completeClientReportView.userProfiles,
                    userProfileToMiniUser
                ),
                [MINI_USERS_NEXUS_STATE_PROP]: map(
                    completeClientReportView.userProfiles,
                    userProfileToMiniUser
                ),
                [OFFENSE_CODES_NEXUS_STATE_PROP]: map(
                    completeClientReportView.offenseCodes,
                    augmentOffenseCodeWithOffenseCodeView
                ),
                [UK_OFFENSE_CODE_EXTENSIONS_NEXUS_STATE_PROP]:
                    completeClientReportView.ukOffenseCodeExtensions,
                // Then other values that need to be mapped in some way
                [REPORTS_NEXUS_STATE_PROP]: reports,
                [NARRATIVE_AUTOSAVE_NEXUS_STATE_PROP]: compact([
                    completeClientReportView.narrativeAutosave,
                ]),
                [ARRESTS_NEXUS_STATE_PROP]: completeClientReportView.arrests,
                [ITEM_PROFILES_NEXUS_STATE_PROP]: completeClientReportView.items,
                [LOCATIONS_NEXUS_STATE_PROP]: completeClientReportView.locationViews,
                [IMPOUNDS_NEXUS_STATE_PROP]: compact([completeClientReportView.impound]),
                [REPORT_CASE_STATUSES_NEXUS_STATE_PROP]: compact([
                    completeClientReportView.reportCaseStatus,
                ]),
                [REPORT_UCRS_NEXUS_STATE_PROP]: compact([completeClientReportView.reportUcr]),
                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: flatten(
                    map(completeClientReportView.locationViews, 'entityLinks')
                ),
                [OFFENSES_NEXUS_STATE_PROP]: compact(
                    []
                        .concat(completeClientReportView.linkedOffenses)
                        .concat(completeClientReportView.offenses)
                ),
                [FIELD_CONTACTS_NEXUS_STATE_PROP]: compact([completeClientReportView.fieldContact]),
                [MISSING_PERSONS_NEXUS_STATE_PROP]: compact([
                    completeClientReportView.missingPerson,
                ]),
                [USE_OF_FORCES_NEXUS_STATE_PROP]: compact([completeClientReportView.useOfForce]),
                [REPORT_DEFINITIONS_NEXUS_STATE_PROP]: compact([
                    completeClientReportView.reportDefinition,
                    ...completeClientReportView.reportDefinitions,
                ]),
                // From Report Related Entities
                [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: [
                    ...reportShortTitles,
                    ...completeClientReportView.reportShortTitles,
                ],
                [CASE_TITLES_NEXUS_STATE_PROP]: caseTitles,
                [CASE_DEFINITIONS_NEXUS_STATE_PROP]: caseDefinitions,
                [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                    [...attributes, ...completeClientReportView.attributes],
                    convertAttributeToAttributeView
                ),
                [ROLES_NEXUS_STATE_PROP]: roles,
                [WARRANT_TITLES_NEXUS_STATE_PROP]: warrantTitles,
                [REPORT_INLINE_COMMENTS_NEXUS_STATE_PROP]:
                    applicationSettings.RMS_INLINE_NARRATIVE_COMMENTS_ENABLED
                        ? completeClientReportView.inlineReportComments
                        : undefined,
                [OFFENSE_CODES_NIBRS_CODE_LINKS_PROP]: values(
                    flatten(completeClientReportView.offenseCodeNibrsCodeLinks)
                ),
                [OFFENSE_CODES_HOC_CATEGORY_LINKS_PROP]: values(
                    flatten(completeClientReportView.offenseCodeHocCategoryLinks)
                ),
                // New properties from CompleteClientReportView only need to be added here when they need special logic.
                // Otherwise, when the NEXUS_STATE_PROP key is identical to the property name in
                // CompleteClientReportView, simply adding it to the dataNexusEntityKeys array will store it into Nexus
                // state as part of this action.
            },
            baseAction
        );

        // We need to wipe offenses from state before rendering a report
        // to ensure that we don't render data that should not be rendered.
        // A main offender is the OMS report which can be used to create
        // new offenses for a report, where the created offenses are tied to
        // the offense report the OMS report modifies, not the OMS report itself.
        // This leads to an issue where we display newly created offenses
        // when navigating back to the offense report that was supposed to be modified,
        // even though the OMS report has not yet been approved.
        baseAction = dependencies.nexus.withRemove(OFFENSES_NEXUS_STATE_PROP, {}, baseAction);

        baseAction.payload.linkedReportIds = linkedReportIds;
        baseAction.payload.linkedCaseIds = linkedCaseIds;
        baseAction.payload.linkedWarrantIds = linkedWarrantIds;

        // TODO address whether this makes any sense at all as part of tasks related
        // to the consumers here, specifically the charges card
        baseAction.payload.linkedOffenses = completeClientReportView.linkedOffenses;
        baseAction.payload.reportSupplementView =
            completeClientReportView.reportSupplementView || {};
        baseAction.payload.reportIsNew = reportIsNew;

        const currentUserHasAbility = currentUserHasAbilitySelector(state);
        const isProductModuleActive = isProductModuleActiveSelector(state);
        if (completeClientReportView.reportStatusView) {
            const userCanEditReports = currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_GENERAL);
            baseAction.payload.canSubmit =
                completeClientReportView.reportStatusView.canSubmit && userCanEditReports;
            baseAction.payload.canReview =
                completeClientReportView.reportStatusView.canReview && userCanEditReports;
            baseAction.payload.canStaffReview =
                completeClientReportView.reportStatusView.canStaffReview && userCanEditReports;
            baseAction.payload.canReject =
                completeClientReportView.reportStatusView.canReject && userCanEditReports;
            baseAction.payload.canSecondaryReject =
                completeClientReportView.reportStatusView.canSecondaryReject && userCanEditReports;
        }

        // TODO -- cannot move this call to nexus yet because under the hood, this action will
        // pick off the `eventDetail#paramedics` prop and handle storing the nested `paramedics`
        if (completeClientReportView.eventDetail) {
            dispatch(
                storeEventDetails(
                    compact([
                        completeClientReportView.eventDetail,
                        renOffenseReport ? renOffenseReport.eventDetail : undefined,
                    ])
                )
            );
        }

        // other actions
        baseAction = augmentActionWithPropertyChanges(baseAction, dependencies.nexus);

        // cannot use currentReportIsCustodialSelector here because
        // current report ui state has not been updated yet by baseAction
        const isCustodial = reportDefinitionHasCustodialPropertySummaryCard(
            reportDefinitionByIdSelector(state)(completeClientReportView.report.reportDefinitionId)
        );

        if (
            !isCustodial &&
            completeClientReportView.items.length > 0 &&
            isProductModuleActive(ProductModuleEnum.EVIDENCE.name) &&
            currentUserHasAbility(abilitiesEnum.EVIDENCE.VIEW_GENERAL)
        ) {
            // on a non-Custodial Property Summary report, if it has
            // evidence items, first load all evidence data before showing
            // the report
            return dispatch(loadEvidenceItemsLite(map(completeClientReportView.items, 'id'))).then(
                () => {
                    return baseAction;
                }
            );
        }

        if (isCustodial && !reportIsNew) {
            return dispatch(loadEvidenceDataForReportId(completeClientReportView.report.id)).then(
                () => baseAction
            );
        }

        return Promise.resolve(baseAction);
    };
};

/**
 * After successfully fetching a report's related entities, set data/ui state for the report.
 * @param {Object} reportRelatedEntities Report Related Entities
 */
export const relatedReportEntitiesSuccess = (reportRelatedEntities) => {
    return (dispatch, getState, dependencies) => {
        const { reportShortTitles, caseTitles, caseDefinitions, attributes, roles, warrantTitles } =
            reportRelatedEntities;

        let baseAction = fetchReportRelatedEntitiesSuccess(reportRelatedEntities);

        const entitiesToStore = {
            [ATTRIBUTES_NEXUS_STATE_PROP]: map(attributes, convertAttributeToAttributeView),
            [CASE_DEFINITIONS_NEXUS_STATE_PROP]: caseDefinitions,
            [CASE_TITLES_NEXUS_STATE_PROP]: caseTitles,
            [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: reportShortTitles,
            [ROLES_NEXUS_STATE_PROP]: roles,
            [WARRANT_TITLES_NEXUS_STATE_PROP]: warrantTitles,
        };

        const withRemoveAction = dependencies.nexus.withRemoveMultiple(
            {
                [CASE_TITLES_NEXUS_STATE_PROP]: {},
                [WARRANT_TITLES_NEXUS_STATE_PROP]: {},
            },
            baseAction
        );

        baseAction = dependencies.nexus.withEntityItems(entitiesToStore, withRemoveAction);

        dispatch(baseAction);
    };
};

const currentReportIsCustodialSelector = createSelector(
    currentReportSelector,
    reportDefinitionByIdSelector,
    (currentReport, reportDefinitionById) =>
        !!currentReport &&
        reportDefinitionHasCustodialPropertySummaryCard(
            reportDefinitionById(currentReport.reportDefinitionId)
        )
);

import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import Modal from '../../../../legacy-redux/components/core/Modal';

import { createModalSelector } from '../state/ui';

const context = {
    name: boxEnum.CONFIRMATION_MODAL,
};

function ConfirmationModal({
    title,
    message,
    saveCallback,
    cancelCallback,
    messageContainsNewLine = false,
    okText,
}) {
    return (
        <Modal
            data-test-id={testIds.CONFIRMATION_MODAL}
            context={context}
            title={title}
            okText={okText || componentStrings.core.ConfirmationModal.confirm}
            onSave={saveCallback}
            onCancelOnly={cancelCallback || undefined}
        >
            {messageContainsNewLine ? (
                <span style={{ whiteSpace: 'pre-wrap' }}>{message}</span>
            ) : (
                message
            )}
        </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
    title: createModalSelector(context, 'title'),
    message: createModalSelector(context, 'message'),
    saveCallback: createModalSelector(context, 'saveCallback'),
    cancelCallback: createModalSelector(context, 'cancelCallback'),
    messageContainsNewLine: createModalSelector(context, 'messageContainsNewLine'),
});

/**
 * Reusable confirmation modal.
 */
export default connect(mapStateToProps)(ConfirmationModal);

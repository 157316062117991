import errorCodeEnum from '~/client-common/core/enums/client/errorCodeEnum';
import { setGlobalError } from '../../../legacy-redux/actions/globalActions';
import type { ErrorConfig } from '../errors/types';

/**
 * Redirect the user to the error page.
 */
export default function redirectToErrorPage({
    errorCode = errorCodeEnum.NOT_FOUND,
    errorId = undefined,
}: ErrorConfig = {}) {
    return setGlobalError({
        errorCode,
        errorId,
    });
}

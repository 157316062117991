import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { includes, get, map, some } from 'lodash';
import { createStructuredSelector } from 'reselect';

import { impoundByReportIdSelector } from '~/client-common/core/domain/impounds/state/data';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { vehiclesSelector } from '~/client-common/core/domain/vehicles/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import withCard from '../../utils/withCard';
import Card from '../../../../../legacy-redux/components/core/Card';
import impoundCard from '../../state/ui/impoundCard';
import { refreshImpoundForm } from '../../state/ui/impound';
import { registerCard } from '../../utils/cardsRegistry';
import testIds from '../../../../../core/testIds';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import ImpoundCardForm from './ImpoundCardForm';
import ImpoundCardSummary from './ImpoundCardSummary';

const mapStateToProps = createStructuredSelector({
    impoundByReportId: impoundByReportIdSelector,
    itemProfilesInReport: itemProfilesInReportSelector,
    vehicles: vehiclesSelector,
    reportById: reportByIdSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = {
    refreshImpoundForm,
};

class ImpoundCard extends React.Component {
    constructor(...args) {
        super(...args);

        this.onEdit = this.onEdit.bind(this);
        this.onSaveProgress = this.onSaveProgress.bind(this);
        this.onSave = this.onSave.bind(this);

        registerCard({
            cardModule: impoundCard,
            onSave: this.onSave,
        });
    }

    onEdit() {
        this.props.editCallback(() => this.props.onEdit({ reportId: this.props.currentReportId }));
    }

    componentDidMount() {
        this.props.refreshImpoundForm(this.props.currentReportId);
    }

    onSaveProgress() {
        return this.props.onSaveProgress();
    }

    onSave() {
        return this.props.onSave();
    }

    render() {
        const {
            card = {},
            currentReportId,
            impoundByReportId,
            itemProfilesInReport,
            vehicles,
            currentReportCardUITitleByType,
        } = this.props;

        const cardTitle = currentReportCardUITitleByType(reportCardEnum.IMPOUND.id);
        const currentImpoundId = get(impoundByReportId(currentReportId), 'id');
        const vehicleIds = map(vehicles, 'itemId');
        const itemProfileIds = map(itemProfilesInReport(currentReportId), 'id');
        const hasVehicle = some(vehicleIds, (vehicleId) => {
            return includes(itemProfileIds, vehicleId);
        });

        return (
            <Card
                className={card.anchor}
                anchor={card.anchor}
                title={cardTitle}
                testId={testIds.IMPOUND_CARD}
                renderContent={(summaryMode) => {
                    if (summaryMode) {
                        return <ImpoundCardSummary impoundId={currentImpoundId} />;
                    } else {
                        return (
                            <ImpoundCardForm impoundId={currentImpoundId} hasVehicle={hasVehicle} />
                        );
                    }
                }}
                onEdit={this.onEdit}
                errors={card.errorMessages}
                summaryMode={card.summaryMode}
                canEdit={get(card.canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(card.canEditReportCardStatus, 'errorMessage')}
                saving={card.saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

export default compose(
    withCard(impoundCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(ImpoundCard);

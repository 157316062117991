import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { get, map } from 'lodash';
import { createStructuredSelector } from 'reselect';

import { compose, withPropsOnChange } from 'recompose';
import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import {
    warrantViewModelByIdSelector,
    hideVehicleInformationSelector,
} from '~/client-common/core/domain/warrants/state/ui';
import { mostRecentWarrantStatusViewModelByWarrantIdSelector } from '~/client-common/core/domain/warrant-statuses/state/ui';
import { warrantAttributeViewModelsWhereSelector } from '~/client-common/core/domain/warrant-attributes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { CardSection } from '../../../../legacy-redux/components/core/Card';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';

import NameSummaryViewWrapper from '../../../core/components/NameSummaryViewWrapper';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFLocationSummaryViewWrapperWithFormFields } from '../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import warrantDetailsForm from '../state/forms/warrantDetailsForm';

import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../legacy-redux/components/summaries/SummaryRowDate';

import testIds from '../../../../core/testIds';

const { WithFieldState } = reactReduxFormHelpers;
const strings = componentStrings.warrants.warrant.WarrantDetailsCard;

function WarrantDetailsSummary({
    warrantId,
    warrantViewModel,
    warrantStatusViewModel,
    warrantStatistics,
    hideVehicleInformation,
}) {
    const {
        obtainingOfficerUserDisplay,
        isOtherJurisdiction,
        warrantTypeAttrId,
        hasNightService,
        bailAmountDisplay,
        issuingAgencyNameDisplay,
    } = getViewModelProperties(warrantViewModel);

    return (
        <div>
            <SummaryList labelWidth={180} contentWidth={360}>
                <CardSection
                    testId={testIds.WARRANT_SUBJECT_SECTION}
                    fieldName={fields.WARRANT_SUBJECT_PERSON_PROFILE_ID}
                >
                    <NameSummaryViewWrapper
                        nameId={get(warrantViewModel, 'subjectPersonProfileId')}
                        renForRecents={get(warrantViewModel, 'reportingEventNumber')}
                        parentEntityType={EntityTypeEnum.WARRANT.name}
                        parentId={warrantId}
                        linkType={LinkTypesEnum.SUBJECT_OF_WARRANT}
                        summaryMode={true}
                        show={{ people: true }}
                    />
                </CardSection>
                <CardSection
                    title={strings.warrantInformation}
                    testId={testIds.WARRANT_INFORMATION_SECTION}
                >
                    <SummaryRowDate
                        fieldName={fields.WARRANT_WARRANT_ISSUED_DATE_UTC}
                        date={warrantViewModel.warrantIssuedDateUtc}
                        format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                    />
                    <SummaryRowDate
                        fieldName={fields.WARRANT_WARRANT_RECEIVED_DATE_UTC}
                        date={warrantViewModel.warrantReceivedDateUtc}
                        format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                    />
                    <SummaryRow fieldName={fields.WARRANT_OBTAINING_OFFICER_USER_ID}>
                        {obtainingOfficerUserDisplay}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_STATUS_WARRANT_STATUS_ATTR_ID}>
                        {getViewModelProperties(warrantStatusViewModel).warrantStatusAttrId}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_WARRANT_TYPE_ATTR_ID}>
                        {warrantTypeAttrId}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_IS_OTHER_JURISDICTION}>
                        {get(warrantViewModel, 'isOtherJurisdiction') && isOtherJurisdiction}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_BAIL_AMOUNT}>
                        {bailAmountDisplay}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_HAS_NIGHT_SERVICE}>
                        {hasNightService}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_DAYS_TO_SERVE}>
                        {get(warrantViewModel, 'daysToServe')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_ISSUING_AGENCY_ORI}>
                        {get(warrantViewModel, 'issuingAgencyOri')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_ISSUING_AGENCY_NAME_ATTR_ID}>
                        {issuingAgencyNameDisplay}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.WARRANT_ATTRIBUTE_ATTRIBUTE_TYPE_WARRANT_STATISTIC_ATTRIBUTE_ID
                        }
                    >
                        {warrantStatistics}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_DESCRIPTION}>
                        {get(warrantViewModel, 'description')}
                    </SummaryRow>
                </CardSection>
                <CardSection
                    title={strings.courtInformation}
                    testId={testIds.WARRANT_COURT_INFORMATION_SECTION}
                >
                    <SummaryRow fieldName={fields.WARRANT_COURT_CASE_NUMBER}>
                        {get(warrantViewModel, 'courtCaseNumber')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_COURT_NAME}>
                        {get(warrantViewModel, 'courtName')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_COURT_ADDRESS}>
                        {get(warrantViewModel, 'courtAddress')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_COURT_PHONE_NUMBER}>
                        {get(warrantViewModel, 'courtPhoneNumber')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_ISSUING_JUDGE}>
                        {get(warrantViewModel, 'issuingJudge')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_WARRANT_NOTES}>
                        {get(warrantViewModel, 'warrantNotes')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_ISSUING_COURT_ORI}>
                        {get(warrantViewModel, 'issuingCourtOri')}
                    </SummaryRow>
                </CardSection>
                <ReactReduxForm {...warrantDetailsForm}>
                    <WithFieldState path="warrantLocation">
                        {(fieldState) =>
                            fieldState.isHidden ? (
                                false
                            ) : (
                                <CardSection
                                    testId={testIds.WARRANT_LOCATION_SECTION}
                                    fieldName={
                                        fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_WARRANT_LOCATION_ID
                                    }
                                >
                                    <RRFLocationSummaryViewWrapperWithFormFields
                                        entityType={EntityTypeEnum.WARRANT.name}
                                        entityId={warrantId}
                                        linkType={LinkTypesEnum.LOCATION_OF_WARRANT}
                                        summaryMode={true}
                                        hideQuickAdd={true}
                                        locationPositionAttrIdPath={() => 'positionAttrId'}
                                    />
                                </CardSection>
                            )
                        }
                    </WithFieldState>
                </ReactReduxForm>
                {!hideVehicleInformation && (
                    <CardSection
                        title={strings.vehicleInformation}
                        testId={testIds.WARRANT_VEHICLE_INFORMATION_SECTION}
                    >
                        <SummaryRow fieldName={fields.WARRANT_LICENSE_PLATE_STATE}>
                            {get(warrantViewModel, 'licensePlateState')}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_LICENSE_PLATE_NUMBER}>
                            {get(warrantViewModel, 'licensePlateNumber')}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_VEHICLE_MAKE}>
                            {get(warrantViewModel, 'vehicleMake')}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_VEHICLE_MODEL}>
                            {get(warrantViewModel, 'vehicleModel')}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_VEHICLE_YEAR}>
                            {get(warrantViewModel, 'vehicleYear')}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_VEHICLE_COLOR}>
                            {get(warrantViewModel, 'vehicleColor')}
                        </SummaryRow>
                    </CardSection>
                )}
            </SummaryList>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    warrantViewModelById: warrantViewModelByIdSelector,
    warrantAttributeViewModelsWhere: warrantAttributeViewModelsWhereSelector,
    mostRecentWarrantStatusViewModelByWarrantId: mostRecentWarrantStatusViewModelByWarrantIdSelector,
    hideVehicleInformation: hideVehicleInformationSelector,
});

export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        [
            'warrantId',
            'warrantViewModelById',
            'warrantAttributeViewModelsWhere',
            'mostRecentWarrantStatusViewModelByWarrantId',
        ],
        ({
            warrantId,
            warrantViewModelById,
            warrantAttributeViewModelsWhere,
            mostRecentWarrantStatusViewModelByWarrantId,
        }) => {
            const warrantViewModel = warrantViewModelById(warrantId) || {};
            const warrantStatusViewModel = mostRecentWarrantStatusViewModelByWarrantId(warrantId);
            const warrantStatistics = map(
                warrantAttributeViewModelsWhere({
                    warrantId,
                    attributeType: 'WARRANT_STATISTIC',
                }),
                (warrantAttribute) => getViewModelProperties(warrantAttribute).attributeId
            ).join(', ');

            return {
                warrantViewModel,
                warrantStatusViewModel,
                warrantStatistics,
            };
        }
    )
)(WarrantDetailsSummary);

import { isEmpty, map, pick, size, take } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Text } from 'arc';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import {
    sortElasticOffenseViewModels,
    formatElasticOffenseViewModelOffenseCode,
} from '~/client-common/helpers/elasticOffenseHelpers';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import withFields from '~/client-common/core/fields/components/withFields';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import { elasticReportViewModelByIdSelector } from '~/client-common/core/domain/elastic-reports/state/ui';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { BodyMediumText } from '../../../../../core/components/typography';
import testIds from '../../../../../../core/testIds';
import { performPriorOffenseSearchMoreResults } from '../../../state/ui/arrest-charges-side-panel/priorOffenseSearchResults';
import { CustomLink } from '../../../../../core/components/links/Link';
import Pill from '../../../../../core/components/Pill';
import Row from '../../../../../core/components/Row';
import { useSubPremiseFormatters } from '../../../../../core/locations/hooks/useSubPremiseFormatters';

const strings =
    componentStrings.reports.core.ArrestChargesSidePanel.PriorOffenseSearchResultsScreen;

const NoReportsFound = styled.div`
    text-align: center;
    vertical-align: middle;
`;

const PanelContent = styled.div`
    padding-top: 10px;
`;

const ResultPill = styled(Pill)`
    margin: 6px 0;
`;

const GetMoreResults = styled.div`
    border-radius: 5px;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    border: 1px solid ${(props) => props.theme.colors.mediumBlue};
    background-color: ${(props) => props.theme.colors.lightBlue};
    margin-top: 24px;
`;

const GetMoreResultsLinkContainer = styled.div`
    padding: 23px 5px;
    text-align: center;
`;

// As of Nov. 2019, we average ~1.5 offenses per offense report, making 3 a reasonable cutoff.
const OFFENSE_DISPLAY_COUNT_CUTOFF = 3;

const buildResultPillOffenseSummaryDisplay = ({ offensesAndIncidents, offenseDisplayName }) => {
    const sortedOffenses = sortElasticOffenseViewModels(offensesAndIncidents);
    const countSortedOffenses = size(sortedOffenses);
    const countOffensesOverCutoff =
        countSortedOffenses > OFFENSE_DISPLAY_COUNT_CUTOFF
            ? countSortedOffenses - OFFENSE_DISPLAY_COUNT_CUTOFF
            : 0;
    const displayCutoffSortedOffenses = take(sortedOffenses, OFFENSE_DISPLAY_COUNT_CUTOFF);

    const topNOffensesTitleDisplay = strings.topNOffenses(
        offenseDisplayName,
        countSortedOffenses > OFFENSE_DISPLAY_COUNT_CUTOFF
            ? OFFENSE_DISPLAY_COUNT_CUTOFF
            : countSortedOffenses
    );
    const topNOffensesDisplay = map(displayCutoffSortedOffenses, (sortedOffense) => {
        return {
            display: formatElasticOffenseViewModelOffenseCode(sortedOffense),
        };
    });
    const moreOffensesDisplay =
        countOffensesOverCutoff > 0
            ? strings.nMoreOffenses(offenseDisplayName, countOffensesOverCutoff)
            : undefined;

    return {
        topNOffensesTitleDisplay,
        topNOffensesDisplay,
        moreOffensesDisplay,
    };
};

export const onPerformSearchMoreResults = ({ priorOffenseSearch }) => (dispatch) => {
    return dispatch(
        performPriorOffenseSearchMoreResults(
            pick(priorOffenseSearch, ['elasticQuery', 'from', 'size'])
        )
    );
};

const PriorOffenseSearchResultsScreen = compose(
    connect(
        createStructuredSelector({
            elasticReportViewModelById: elasticReportViewModelByIdSelector,
        })
    ),
    withFields([REPORT_REPORTING_EVENT_NUMBER])
)(
    ({
        screenState,
        onSearchResultClickCallback,
        onPerformSearchMoreResultsCallback,
        elasticReportViewModelById,
        fieldDisplayNames,
    }) => {
        const {
            isPerformingAsyncAction,
            priorOffenseSearch: { orderedCurrentResultElasticReportIds, totalCount },
        } = screenState;
        const orderedElasticReportViewModels = map(
            orderedCurrentResultElasticReportIds,
            (orderedCurrentResultElasticReportId) =>
                elasticReportViewModelById(orderedCurrentResultElasticReportId)
        );
        const { buildElasticLocationLines } = useSubPremiseFormatters();
        const offenseDisplayName = useOffenseFieldName();

        return (
            <>
                <PanelContent>
                    {isEmpty(orderedCurrentResultElasticReportIds) ? (
                        <NoReportsFound>{strings.noReportsFound}</NoReportsFound>
                    ) : (
                        <>
                            {map(orderedElasticReportViewModels, (elasticReportViewModel) => {
                                const {
                                    id,
                                    eventStartUtc,
                                    reportDefinition: { offensesAndIncidents },
                                } = elasticReportViewModel;
                                const {
                                    renWithShortTitle,
                                    offenseLocation,
                                    primaryLocation,
                                    respondingOfficerFormats: { fullName },
                                } = getViewModelProperties(elasticReportViewModel);
                                const displayLocation = !isUndefinedOrNull(offenseLocation)
                                    ? offenseLocation
                                    : primaryLocation;
                                const {
                                    topNOffensesTitleDisplay,
                                    topNOffensesDisplay,
                                    moreOffensesDisplay,
                                } = buildResultPillOffenseSummaryDisplay({
                                    offensesAndIncidents,
                                    offenseDisplayName,
                                });

                                return (
                                    <ResultPill
                                        key={id}
                                        hoverable={true}
                                        showChevron={true}
                                        disabled={isPerformingAsyncAction}
                                        onClick={() =>
                                            onSearchResultClickCallback({
                                                priorOffenseSearchSelectedElasticReportViewModel: elasticReportViewModel,
                                            })
                                        }
                                        testId={
                                            testIds.CHARGES_SIDE_PANEL_PRIOR_OFFENSE_SEARCH_RESULTS_SCREEN_OFFENSE_SEARCH_RESULT
                                        }
                                    >
                                        <div>
                                            <Row>
                                                <Text variant="headingXs">
                                                    {`${fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER} ${renWithShortTitle}`}
                                                </Text>
                                            </Row>
                                            {displayLocation && (
                                                <Row>
                                                    <BodyMediumText>
                                                        {map(
                                                            buildElasticLocationLines(
                                                                displayLocation
                                                            ),
                                                            (line) => {
                                                                return <Row key={line}>{line}</Row>;
                                                            }
                                                        )}
                                                    </BodyMediumText>
                                                </Row>
                                            )}
                                            {(eventStartUtc || fullName) && (
                                                <Row>
                                                    <BodyMediumText>
                                                        {eventStartUtc && (
                                                            <FormattedDate
                                                                format={
                                                                    FormattedDate.FORMATS
                                                                        .SUMMARY_DATE_TIME
                                                                }
                                                                date={eventStartUtc}
                                                            />
                                                        )}
                                                        {fullName &&
                                                            eventStartUtc &&
                                                            ` ${strings.respondingOfficerByPrefix} ${fullName}`}
                                                        {fullName &&
                                                            isUndefinedOrNull(eventStartUtc) &&
                                                            `${fullName}`}
                                                    </BodyMediumText>
                                                </Row>
                                            )}
                                            {!isEmpty(topNOffensesDisplay) && (
                                                <>
                                                    <Row />
                                                    <Row>
                                                        <BodyMediumText fontWeight="semibold">
                                                            {topNOffensesTitleDisplay}
                                                        </BodyMediumText>
                                                    </Row>
                                                    <Row>
                                                        <BodyMediumText>
                                                            {map(
                                                                topNOffensesDisplay,
                                                                ({ display }) => {
                                                                    return (
                                                                        <Row key={display}>
                                                                            {display}
                                                                        </Row>
                                                                    );
                                                                }
                                                            )}
                                                        </BodyMediumText>
                                                        <BodyMediumText fontWeight="semibold">
                                                            {moreOffensesDisplay}
                                                        </BodyMediumText>
                                                    </Row>
                                                </>
                                            )}
                                        </div>
                                    </ResultPill>
                                );
                            })}
                            {size(orderedCurrentResultElasticReportIds) < totalCount && (
                                <GetMoreResults>
                                    <GetMoreResultsLinkContainer>
                                        <CustomLink
                                            disabled={isPerformingAsyncAction}
                                            onClick={onPerformSearchMoreResultsCallback}
                                        >
                                            {strings.viewMoreResults}
                                        </CustomLink>
                                    </GetMoreResultsLinkContainer>
                                </GetMoreResults>
                            )}
                        </>
                    )}
                </PanelContent>
            </>
        );
    }
);

export default PriorOffenseSearchResultsScreen;

import { CaseTaskView } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'tasks';

const taskModule = createNormalizedModule<CaseTaskView>({
    type: NEXUS_STATE_PROP,
});

export const storeTasks = taskModule.actionCreators.storeEntities;

export const tasksSelector = taskModule.selectors.entitiesSelector;
export const tasksWhereSelector = taskModule.selectors.entitiesWhereSelector;

export default taskModule.reducerConfig;

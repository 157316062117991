// TODO PRINTING-REFACTOR evalute the "DI" pattern used here

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Report Case Statuses Resource',
        methods: {
            /**
             * Will eventually be used in favor of getCaseStatuses
             * Given a report_id, will return the case_statuses for the report
             *  if RWR is disabled. Otherwise will return statuses for the reporting event
             * @param {number} reportId
             * @return {Promise<Object[]>}
             */
            getCaseStatusesForReportId(reportId) {
                return req({
                    method: 'GET',
                    url: `reports/case_status/report/${reportId}`,
                });
            },

            /**
             * Save case statuses associated with reports.
             * @param  {Object[]} caseStatuses
             * @return {Object[]}
             */
            saveCaseStatuses(reportCaseStatuses) {
                return req({
                    method: 'PUT',
                    url: 'reports/case_status',
                    data: reportCaseStatuses,
                });
            },

            willNotInvestigate(reportId, comments) {
                return req({
                    method: 'POST',
                    url: `report/${reportId}/cases/will_not_investigate`,
                    data: {
                        data: comments,
                    },
                });
            },

            bulkWillNotInvestigate(reportIds, comments) {
                return req({
                    method: 'POST',
                    url: 'batch_operations',
                    data: {
                        operation: 'POST /report/{id}/cases/will_not_investigate',
                        targets: JSON.stringify({
                            targets: reportIds,
                        }),
                        args: JSON.stringify([
                            {
                                data: comments,
                            },
                        ]),
                    },
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import { some } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import notificationTypeEnum from '~/client-common/core/enums/universal/notificationTypeEnum';

/**
 * Using the notification config object, find and return the category object
 * for given index
 * @param  {number} index
 * @return {{display: string, displayIndex: number, settings: Object}}
 *          category object
 */
export function notificationCategoryByIndex(index, config) {
    return config[index];
}

/**
 * Using the notification config object, find and return the setting type object
 * for given indices
 * @param  {number} categoryIndex
 * @param  {number} settingIndex
 * @return {{displayIndex: number, settingEnum: string, display: string}}
            setting type object
 */
export function notificationSettingByIndex(categoryIndex, settingIndex, config) {
    const category = notificationCategoryByIndex(categoryIndex, config);

    return category.notificationTypes[settingIndex];
}

/**
 * For a given setting, check against list of opt outs and return true
 * if setting has been opted out.
 * @param  {string} setting     setting type enum
 * @param  {Object[]} optOuts   all opt outs
 * @param  {string} deliveryMethod delivery method enum
 * @return {boolean}
 */
export function settingOptedOut(setting, optOuts, deliveryMethod) {
    return some(
        optOuts,
        (optOut) => optOut.notificationType === setting && optOut.deliveryMethod === deliveryMethod
    );
}

/**
 * Create a HOC with renderOnlyIf by checking the name of
 * the notification.
 * @param  {React.Component} Component component to wrap
 * @return {React.Component}
 */
export function notificationTypeWithRenderOnlyIf(Component) {
    return compose(
        connect(
            createStructuredSelector({
                applicationSettings: applicationSettingsSelector,
            })
        ),
        renderOnlyIf(({ name, applicationSettings }) => {
            switch (name) {
                // Only show the category if it's enabled
                case notificationTypeEnum.BOLO.name:
                    return applicationSettings.CAD_BOLOS_ENABLED;

                // Don't show the testing categories and WIP categories
                case notificationTypeEnum.TEST_CAD_HIGH.name:
                case notificationTypeEnum.TEST_CAD_MEDIUM.name:
                case notificationTypeEnum.OFFICER_EMERGENCY.name:
                case notificationTypeEnum.INCOMING_ASSIGNMENT.name:
                case notificationTypeEnum.HIGH_PRIORITY_NARRATIVE.name:
                    return false;

                default:
                    return true;
            }
        })
    )(Component);
}

import { map } from 'lodash';
import { UserAssignment } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

import getUserAssignmentsResource from '../../resources/userAssignmentsResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'userAssignments';

const userAssignmentsModule = createNormalizedModule<UserAssignment>({
    type: NEXUS_STATE_PROP,
});

// ACTION TYPES

const SAVE_USER_ASSIGNMENTS_START = 'user-assignments/SAVE_USER_ASSIGNMENTS_START';
const SAVE_USER_ASSIGNMENTS_SUCCESS = 'user-assignments/SAVE_USER_ASSIGNMENTS_SUCCESS';
const SAVE_USER_ASSIGNMENTS_FAILURE = 'user-assignments/SAVE_USER_ASSIGNMENTS_FAILURE';

// ACTIONS

function saveUserAssignmentsStart(userAssignments: UserAssignment[]) {
    return {
        type: SAVE_USER_ASSIGNMENTS_START,
        payload: userAssignments,
    };
}

function saveUserAssignmentsSuccess(userAssignments: UserAssignment[]) {
    return {
        type: SAVE_USER_ASSIGNMENTS_SUCCESS,
        payload: userAssignments,
    };
}

function saveUserAssignmentsFailure(message: string) {
    return {
        type: SAVE_USER_ASSIGNMENTS_FAILURE,
        payload: message,
    };
}

export function saveUserAssignments(
    userProfileId: number,
    departmentId: number,
    userAssignments: UserAssignment[]
): ClientCommonAction<Promise<UserAssignment[]>> {
    return (dispatch) => {
        const resource = getUserAssignmentsResource();
        const mappedUserAssignments = map(userAssignments, (userAssignment) => ({
            ...userAssignment,
            userProfileId,
            departmentId,
        }));
        dispatch(saveUserAssignmentsStart(userAssignments));
        return resource
            .saveUserAssigments(userProfileId, mappedUserAssignments)
            .then((userAssignments: UserAssignment[]) => {
                dispatch(saveUserAssignmentsSuccess(userAssignments));
                dispatch(replaceUserAssignmentsWhere({ userProfileId }, userAssignments));
                return userAssignments;
            })
            .catch((err: Error) => {
                dispatch(saveUserAssignmentsFailure(err.message));
                throw err;
            });
    };
}

export const storeUserAssignments = userAssignmentsModule.actionCreators.storeEntities;
const replaceUserAssignmentsWhere = userAssignmentsModule.actionCreators.replaceEntitiesWhere;

// SELECTORS
export const userAssignmentsSelector = userAssignmentsModule.selectors.entitiesSelector;

export const userAssignmentsWhereSelector = userAssignmentsModule.selectors.entitiesWhereSelector;

// REDUCER
export default userAssignmentsModule.reducerConfig;

import {
    AggregateSearchResult,
    ClientApprovalStatusEnumType,
    ElasticReportQuery,
    ElasticSearchTypeEnum,
} from '@mark43/rms-api';
import { difference, first, get, omit, map } from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';
import { combineReducers } from 'redux';

import { elasticReportResultViewModelsSelector } from '~/client-common/core/domain/elastic-reports/state/ui';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
    globalSequenceNumberLabelSelector,
} from '~/client-common/core/fields/state/config';
import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';

import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import { RmsAction } from '../../../../../core/typings/redux';
import reportsDashboardSearchForm from '../forms/reportsDashboardSearchForm';
import { transformElasticQueryBeforeSearchSelector } from '../../../../search/reports/state/ui';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import { updateActiveColumnKeysBySortKey } from './config';
import { convertReportsDashboardElasticQueryToFilterGroups } from '.';

export { clearReportsDashboardFilter as clearReportModuleFilters } from '.';

export const reportDefinitionIdsInCurrentReportModuleSelector = (state: RootState) =>
    state.ui.reports.module.ui.reportDefinitionIds;
export const reportModuleTabNameToCountSelector = (state: RootState) =>
    state.ui.reports.module.ui.tabNameToCount;

/**
 * This search module is for the Report Module that the user has opened currently.
 * It has many properties that are the same as reportsDashboardSearch in order to handle the search form, search API
 * requests, and search filters the exact same way.
 */
export const reportModuleSearch = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.REPORTS_DASHBOARD.name,
    baseUiSelector: (state: RootState) => state.ui.reports.module.search,
    searchResultToRoutePath: ({ id }: { id: number }) => `/reports/${id}`,
    form: reportsDashboardSearchForm,
    resourceMethod: elasticSearchResource.searchReports,
    elasticQueryToFilterGroups: convertReportModuleElasticQueryToFilterGroups,
    resultsContainerClassName: 'report-module-results-container',
    createResultsViewModelsSelector: elasticReportResultViewModelsSelector,
    defaultTableState: {
        from: 0,
        size: 25,
        sortKey: sortKeyEnum.REPORT_EVENT_START_UTC,
        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
        activeColumnKeys: {
            date: 'eventStartUtc',
            entity: 'reportOwner',
            location: 'locations', // this is "primary location"
            ren: 'reportingEventNumber',
        },
    },
    selectorToBind: createStructuredSelector({
        combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        formatFieldByName: formatFieldByNameSelector,
        globalSequenceNumberLabel: globalSequenceNumberLabelSelector,
        reportDefinitionIdsInCurrentReportModule: reportDefinitionIdsInCurrentReportModuleSelector,
    }),
    transformElasticQueryBeforeSearchSelector,
    updateActiveColumnKeysBySortKey,
});

export const currentReportModuleSearchClientApprovalStatusSelector = createSelector(
    // @ts-expect-error RND-9718 type the search module
    reportModuleSearch.selectors.currentQuerySelector,
    ({ elasticQuery }) => {
        return first(get(elasticQuery, 'clientApprovalStatuses', approvalStatusClientEnum.DRAFT));
    }
);

function elasticQueryIncludesReportDefinitionIds(
    elasticQuery: ElasticReportQuery,
    reportDefinitionIds: number[]
): boolean {
    if (
        !elasticQuery?.reportDefinitions ||
        !reportDefinitionIds ||
        reportDefinitionIds.length === 0
    ) {
        return false;
    }
    const idsInQuery = map(elasticQuery.reportDefinitions, 'reportDefinitionId');
    return difference(reportDefinitionIds, idsInQuery).length === 0;
}

function convertReportModuleElasticQueryToFilterGroups(
    elasticQuery: ElasticReportQuery,
    formatFieldValue: unknown,
    boundSelectors: {
        reportDefinitionIdsInCurrentReportModule: number[];
    }
) {
    if (
        elasticQueryIncludesReportDefinitionIds(
            elasticQuery,
            boundSelectors.reportDefinitionIdsInCurrentReportModule
        )
    ) {
        elasticQuery = {
            ...elasticQuery,
            reportDefinitions: [],
        };
    }
    return convertReportsDashboardElasticQueryToFilterGroups(
        elasticQuery,
        formatFieldValue,
        boundSelectors
    );
}

const SET_REPORT_DEFINITION_IDS = 'report-modules/SET_REPORT_DEFINITION_IDS' as const;
export function setReportDefinitionIds(reportDefinitionIds: number[]) {
    return {
        type: SET_REPORT_DEFINITION_IDS,
        payload: reportDefinitionIds,
    };
}

export const setReportModuleSearchClientApprovalStatus = (
    reportStatus: ClientApprovalStatusEnumType
): RmsAction<void> => (dispatch, getState) => {
    // @ts-expect-error RND-9718 type the search module
    const formModel = reportsDashboardSearchForm.selectors.formModelSelector(getState());
    const formModelWithClientApprovalStatuses = {
        ...formModel,
        reportDetails: {
            ...omit(
                formModel.reportDetails,
                'approvalStatus',
                'secondaryApprovalStatuses',
                'hasSecondaryApprovalStatus'
            ),
            clientApprovalStatuses: [reportStatus],
        },
        isFormModel: true,
    };

    // @ts-expect-error RND-9718 type the search module
    dispatch(reportsDashboardSearchForm.actionCreators.change(formModelWithClientApprovalStatuses));
};

interface FormData {
    reportDetails: { reportDefinitions?: number[] };
}

/**
 * Say a Report Module has 3 report definitions. Every search executed in its search dashboard must filter by these 3
 * report definitions. We don't directly update form state because it makes more sense for the Report Type select
 * (ReportDefinitionSelect) to remain empty in the UI unless the user manually selects them to filter for only 1 or 2 of
 * those 3 report definitions. This is why we add the reportDefinitionIds only after the form is submitted.
 */
function addReportDefinitionIds(formData: FormData, reportDefinitionIds: number[]) {
    if (
        reportDefinitionIds.length === 0 ||
        (formData?.reportDetails?.reportDefinitions &&
            formData.reportDetails.reportDefinitions.length > 0)
    ) {
        return formData;
    }
    return {
        ...formData,
        reportDetails: {
            ...formData.reportDetails,
            // this is the path to reportDetailsFieldsetViewModel in reportsDashboardSearchForm
            reportDefinitions: reportDefinitionIds,
        },
    };
}

export const submitReportModuleSearch = (): RmsAction<void> => (dispatch, getState) => {
    const state = getState();
    const reportDefinitionIds = reportDefinitionIdsInCurrentReportModuleSelector(state);
    // @ts-expect-error RND-9718 type the search module
    dispatch(reportModuleSearch.form.actionCreators.updateShiftTimeRange());
    dispatch(
        // @ts-expect-error RND-9718 type the search module
        reportModuleSearch.form.actionCreators.submit((formData: FormData) => {
            formData = addReportDefinitionIds(formData, reportDefinitionIds);
            dispatch(
                // @ts-expect-error RND-9718 type the search module
                reportModuleSearch.actionCreators.search(
                    {
                        formData,
                        from: 0,
                    },
                    // auto-save is not supported in the Report Module yet
                    { cacheBust: true, showLoadingModal: false, autoSave: false }
                )
            );
            dispatch(searchTabCounts(formData));
        })
    );
};

export const searchInitialReports = (): RmsAction<void> => (dispatch) => {
    // @ts-expect-error RND-9718 type the search module
    dispatch(reportModuleSearch.actionCreators.setIsInitialSearch(true));
    dispatch(
        // @ts-expect-error RND-9718 type the search module
        reportsDashboardSearchForm.actionCreators.change({
            reportDetails: {
                clientApprovalStatuses: [approvalStatusClientEnum.DRAFT],
                reportDefinitions: [],
            },
            isFormModel: true,
            dateTime: {
                dateType: dateTypeEnum.EVENT,
            },
        })
    );
    dispatch(submitReportModuleSearch());
};

export const resetReportModuleDashboard = (): RmsAction<void> => (dispatch, getState) => {
    // keep current clientApprovalStatus, as it determines which results tab we're on
    const state = getState();
    // @ts-expect-error RND-9718 type the search module
    const clientApprovalStatuses = reportsDashboardSearchForm.selectors.formModelByPathSelector(
        state
    )('reportDetails.clientApprovalStatuses');
    dispatch(
        // @ts-expect-error RND-9718 type the search module
        reportsDashboardSearchForm.actionCreators.change({
            reportDetails: {
                clientApprovalStatuses,
                reportDefinitions: [],
            },
            isFormModel: true,
            dateTime: {
                dateType: dateTypeEnum.EVENT,
            },
        })
    );
    dispatch(submitReportModuleSearch());
};

const UPDATE_TAB_COUNTS = 'REPORT_MODULE_UPDATE_TAB_COUNTS' as const;

type TabCountToName = Record<string, number>;

const searchTabCounts = (formData: unknown): RmsAction<void> => (dispatch, getState) => {
    const elasticQuery = {
        // @ts-expect-error RND-9718 type the search module
        ...omit(reportModuleSearch.form.convertFromFormModel(formData), 'clientApprovalStatuses'),
    };

    const transformedQuery = transformElasticQueryBeforeSearchSelector(getState())(elasticQuery);

    elasticSearchResource
        .searchReportsAggregate(transformedQuery, 'clientApprovalStatus')
        // @ts-expect-error RND-9718 type the search resource
        .then((searchResults: AggregateSearchResult) => {
            dispatch({
                type: UPDATE_TAB_COUNTS,
                payload: searchResults.buckets,
            });
        });
};

// since there is only 1 route for each Report Module, always clear reportDefinitionIds when the route changes
const uiReducer = makeResettable(
    ENTER_NEW_ROUTE,
    (
        state: {
            reportDefinitionIds: number[];
            tabNameToCount: TabCountToName;
        } = {
            reportDefinitionIds: [],
            tabNameToCount: {},
        },
        action:
            | {
                  type: typeof UPDATE_TAB_COUNTS;
                  payload: TabCountToName;
              }
            | ReturnType<typeof setReportDefinitionIds>
    ) => {
        switch (action.type) {
            case UPDATE_TAB_COUNTS:
                return {
                    ...state,
                    tabNameToCount: action.payload,
                };
            case SET_REPORT_DEFINITION_IDS:
                return {
                    ...state,
                    reportDefinitionIds: action.payload,
                };
            default:
                return state;
        }
    }
);

export default combineReducers({
    // @ts-expect-error RND-9718 type the search resource
    search: makeResettable(ENTER_NEW_ROUTE, reportModuleSearch.reducers.uiReducer),
    ui: uiReducer,
});

import _, { get, isArray, keyBy, map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName, withState, withHandlers, withPropsOnChange } from 'recompose';

import { vehicleMakeOptionsSelector } from '~/client-common/core/domain/vehicle-makes/state/ui';
import {
    searchForVehicleMakes,
    storeVehicleMakes,
} from '~/client-common/core/domain/vehicle-makes/state/data';
import { vehicleTypeCodeIdForItemCategoryAttrIdSelector } from '~/client-common/core/domain/vehicle-models/state/data';

import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import AsyncSelect from './AsyncSelect';

const { connectRRFInput } = reactReduxFormHelpers;

const otherOption = { display: 'Other', value: -1 };

const mapStateToProps = createStructuredSelector({
    vehicleMakeOptionsFromState: vehicleMakeOptionsSelector,
    vehicleTypeCodeIdForItemCategoryAttrId: vehicleTypeCodeIdForItemCategoryAttrIdSelector,
});
const mapDispatchToProps = { searchForVehicleMakes, storeVehicleMakes };

const VehicleMakeSelect = compose(
    setDisplayName('VehicleMakeSelect'),
    connect(mapStateToProps, mapDispatchToProps),
    withState('vehicleMakes', 'setVehicleMakes', {}),
    withPropsOnChange(
        ['value', 'vehicleMake', 'vehicleMakes', 'vehicleMakeOptionsFromState'],
        ({ includeOtherOption, value, vehicleMake, vehicleMakes, vehicleMakeOptionsFromState }) => {
            let vehicleMakeOptions = map(vehicleMakes, ({ id, makeName }) => ({
                display: makeName,
                value: id,
            }));

            if (isArray(value)) {
                vehicleMakeOptions.push(...vehicleMakeOptionsFromState(value));
            } else if (!!value && !vehicleMakes[value]) {
                vehicleMakeOptions.push(...vehicleMakeOptionsFromState([value]));
            }

            if (!!vehicleMake && !vehicleMakes[vehicleMake.id]) {
                vehicleMakeOptions.push({ display: vehicleMake.makeName, value: vehicleMake.id });
            }

            vehicleMakeOptions = _(vehicleMakeOptions).uniqBy('value').sortBy('display').value();

            if (includeOtherOption) {
                // the Other option always appears last
                vehicleMakeOptions.push(otherOption);
            }

            return { options: vehicleMakeOptions, sortOnRelevance: true };
        }
    ),
    withPropsOnChange(
        ['itemCategoryAttrId', 'vehicleTypeCodeIdForItemCategoryAttrId'],
        ({ itemCategoryAttrId, vehicleTypeCodeIdForItemCategoryAttrId }) => ({
            vehicleTypeCodeId: vehicleTypeCodeIdForItemCategoryAttrId(itemCategoryAttrId),
        })
    ),
    withHandlers({
        asyncAction({
            searchForVehicleMakes,
            vehicleTypeCodeId,
            vehicleBodyStyleCodeId,
            yearOfManufacture,
            setVehicleMakes,
        }) {
            return (q) => {
                if (get(q, 'length') === 1) {
                    // do not allow a 1-character search because there'd be too many irrelevant
                    // results
                    return;
                }

                return searchForVehicleMakes({
                    q,
                    vehicleTypeCodeId,
                    vehicleBodyStyleCodeId,
                    yearOfManufacture,
                    from: 0,
                    size: 100,
                }).then((vehicleMakes) => setVehicleMakes(keyBy(vehicleMakes, 'id')));
            };
        },
        onChange({ onChange, vehicleMakes, storeVehicleMakes }) {
            return (value) => {
                if (!!value && value !== otherOption.value) {
                    // store the selected make(s) into state for the values to persist - essential
                    // when this is a multiselect
                    const ids = isArray(value) ? value : [value];
                    const makesToPersist = _(ids)
                        .map((id) => vehicleMakes[id])
                        .compact()
                        .value();
                    if (makesToPersist.length > 0) {
                        storeVehicleMakes(makesToPersist);
                    }
                }

                return onChange(value);
            };
        },
    })
)(AsyncSelect);

export default VehicleMakeSelect;
export const RRFVehicleMakeSelect = connectRRFInput(VehicleMakeSelect);
export const ArbiterMFTVehicleMakeSelect = arbiterMFTInput(VehicleMakeSelect);

import { forEach, map } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ElasticReport } from '@mark43/rms-api';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { BodyMediumText } from '../../../../core/components/typography';
import { storeLinkedReportIds } from '../../../../../legacy-redux/actions/reportsActions';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import reportsResource from '../../resources/reportsResource';
import { RmsDispatch } from '../../../../../core/typings/redux';
import Modal from '../../../../core/overlays/components/Modal';
import ModalBase from '../../../../core/overlays/components/ModalBase';
import { PopoutLink } from '../../../../core/components/links/Link';
import { deleteEmbeddedReport, embeddedReportsUiSelector } from '../../state/ui/embeddedReports';

const strings = componentStrings.reports.core.AddArrestReportButton;

type OneLinkedArrestModalProps = {
    defendantId: number;
    defendantName?: string;
    report: Pick<ElasticReport, 'id' | 'shortTitle'>;
    arrestReportDefinitionName: string;
};

// this state is about which modal we're displaying at any given time, and the props needed for that modal
export type AddArrestReportModalProps =
    | {
          id: 'ERROR';
          errorMessage: string;
      }
    | ({
          id: 'ONE_LINKED_ARREST';
      } & OneLinkedArrestModalProps)
    | {
          id: 'MULTIPLE_LINKED_ARRESTS';
          defendantName?: string;
          reports: Pick<ElasticReport, 'id' | 'shortTitle'>[];
          arrestReportDefinitionName: string;
      }
    | undefined;

const OneLinkedArrestModal: React.FC<{
    modalProps: OneLinkedArrestModalProps;
}> = ({ modalProps }) => {
    const dispatch: RmsDispatch = useDispatch();
    const currentReportId = useSelector(currentReportIdSelector);
    const embeddedReportsUi = useSelector(embeddedReportsUiSelector);
    const onSave = React.useCallback(() => {
        if (currentReportId) {
            // reload linked reports in case Redux state is stale
            return reportsResource
                .getReportRelatedEntities(currentReportId)
                .then((relatedEntities) => {
                    // Reset the UI state of any report that was previously hidden, since the user may be adding a
                    // suspect that matches that report's defendant, and resetting this state will trigger that embedded
                    // report to be loaded. At the same time, we don't reset the UI state of reports that are already
                    // embedded.
                    forEach(relatedEntities.reportShortTitles, ({ reportId }) => {
                        const ui = embeddedReportsUi[reportId];
                        if (ui && ui.loading === false && ui.loadedAndVisible === false) {
                            dispatch(deleteEmbeddedReport(reportId));
                        }
                    });
                    dispatch((dispatch, getState, dependencies) =>
                        dispatch(
                            dependencies.nexus.withEntityItems(
                                {
                                    [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]:
                                        relatedEntities.reportShortTitles,
                                },
                                { type: 'STORE_REPORT_SHORT_TITLES' }
                            )
                        )
                    );
                    dispatch(storeLinkedReportIds(relatedEntities.linkedReportIds));
                    return;
                });
        }
        return; // currentReportId must exist for this to be rendered
    }, [currentReportId, embeddedReportsUi, dispatch]);

    return (
        <Modal
            id="ONE_LINKED_ARREST"
            title={strings.addArrestReport(modalProps.arrestReportDefinitionName)}
            onSave={onSave}
            okText={strings.addReport}
        >
            {strings.singleArrestReportMessage1(modalProps.arrestReportDefinitionName)}{' '}
            <BodyMediumText fontWeight="semibold">{modalProps.defendantName}</BodyMediumText>{' '}
            {strings.singleArrestReportMessage2}
            <br />
            <br />
            <BodyMediumText fontWeight="semibold">{modalProps.report.shortTitle}</BodyMediumText>
        </Modal>
    );
};

const AddArrestReportModal: React.FC<{
    modalProps: AddArrestReportModalProps;
    setModalProps: React.Dispatch<React.SetStateAction<AddArrestReportModalProps>>;
}> = ({ modalProps, setModalProps }) => {
    const closeModal = React.useCallback(() => {
        setModalProps(undefined);
    }, [setModalProps]);
    if (!modalProps) {
        return null;
    }

    switch (modalProps.id) {
        case 'ERROR':
            return (
                <ModalBase
                    isOpen={true}
                    title={strings.error}
                    errorHeader={true}
                    okText=""
                    cancelText={strings.close}
                    onCancel={closeModal}
                >
                    {modalProps.errorMessage}
                </ModalBase>
            );
        case 'ONE_LINKED_ARREST':
            // Out of the 3 modals, only this one is rendered with an id for convenience because it has save
            // functionality. The other 2 modals only display messages and have no actions.
            return <OneLinkedArrestModal modalProps={modalProps} />;
        case 'MULTIPLE_LINKED_ARRESTS':
            return (
                <ModalBase
                    isOpen={true}
                    title={strings.multipleArrestReports(modalProps.arrestReportDefinitionName)}
                    errorHeader={true}
                    okText=""
                    cancelText={strings.close}
                    onCancel={closeModal}
                >
                    {strings.multipleArrestReportsMessage1({
                        reportCount: modalProps.reports.length,
                        arrestReportDefinitionName: modalProps.arrestReportDefinitionName,
                    })}{' '}
                    <BodyMediumText fontWeight="semibold">
                        {modalProps.defendantName}
                    </BodyMediumText>{' '}
                    {strings.multipleArrestReportsMessage2(modalProps.arrestReportDefinitionName)}
                    {map(modalProps.reports, ({ id, shortTitle }) => (
                        <React.Fragment key={id}>
                            <br />
                            <br />
                            <PopoutLink to={`/reports/${id}`}>{shortTitle}</PopoutLink>
                        </React.Fragment>
                    ))}
                </ModalBase>
            );
        default:
            return null;
    }
};

/**
 * Render any of 3 modals at a given time (or render nothing).
 */
export default AddArrestReportModal;

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Booking Resource',
        methods: {
            /**
             * Only update booking fields https://github.com/mark43/mark43/blob/11fa4d8b3966a2218a202357fa2de510e6f1e851/cobalt-rms/reporting/reporting-services/src/main/java/mark43/reporting/data/arrest/ArrestRepository.java#L64-L71
             * @param  {Object} arrestView shape of ArrestView.java
             */
            updateArrestBookingInfo(arrestView) {
                return req({
                    method: 'PUT',
                    url: `reporting/booking/${arrestView.arrest.id}`,
                    data: arrestView,
                });
            },
        },
    });
}

const resourceGetter = () => resource;

/**
 * Resources for Booking Arrests. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
export default resourceGetter;

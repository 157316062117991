export const AnalyticsPropertyEnum = {
    SIDE_PANEL: 'Side Panel',
    CARD: 'Card',
    CARD_SECTION: 'Card Section',
    EXPORT_TYPE: 'Export type',
    BUTTON: 'Button',
    LINK: 'Link',
    MODAL: 'Modal',
    INTERACTION: 'Interaction',
    KEYBOARD_SHORTCUT: 'Keyboard shortcut',
    REPORT_DEFINITION: 'Report definition',
    REPORT_SESSION_ID: 'Report session id',
    REPORT_COUNT: 'Number of reports',
    TABS: 'Tabs',
    TAB_INDEX: 'Tab index',
    COLLAPSIBLE_SECTION: 'Collapsible section',
    COLLAPSIBLE_SECTION_ACTION: 'Collapsible section action',

    // Category for which mention was selected
    MENTION_CATEGORY: 'Mention category',

    // Length of query when mention was selected
    MENTION_CHARACTER_LENGTH: 'Mention character length',

    // Whether or not mention was selected
    // from searching, or selecting a category
    MENTION_CHOSEN_FROM: 'Mention chosen from',

    // List of the queries used to conduct photo line up filtering
    PHOTO_LINEUP_FILTER_SECTION: 'Photo lineup filter section',
    PHOTO_LINEUP_FILTER_QUERY: 'Photo lineup filter query',

    // Redaction properties
    REDACTION_SEARCH_TAGS: 'Redaction search tags',
    REDACTION_FIELDS: 'Redaction fields',

    TOTAL_ITEM_COUNT: 'Number of total items',
    SELECTABLE_ITEM_COUNT: 'Number of selectable items',
    SELECTED_ITEM_COUNT: 'Number of selected items',

    // CLIPS card sections for reports
    CLIPS: 'CLIPS report section',
} as const;

export type AnalyticsPropertyEnumType = typeof AnalyticsPropertyEnum[keyof typeof AnalyticsPropertyEnum];

// These interactions could also
// be key combination strings (`ctrl+s` for instance`)
export const analyticsInteractionEnum = {
    ENTER: 'Enter key',
    CLICK: 'Click',
    KEYBOARD: 'Keyboard',
};

export const analyticsCollapsibleSectionActionEnum = {
    OPEN: 'Open',
    CLOSE: 'Close',
};

// @ts-nocheck client-common to client migration
import _, {
    isArray,
    isBoolean,
    isObject,
    endsWith,
    map,
    mapValues,
    reduce,
    includes,
    isEmpty,
} from 'lodash';

let viewModelProperties: 'display' | symbol; // eslint-disable-line import/no-mutable-exports
export const symbol = Symbol('VIEW_MODEL_PROP');
/* global IS_PRINTING */
if (IS_PRINTING) {
    viewModelProperties = 'display';
} else {
    viewModelProperties = symbol;
}

type DisplayShape<T = unknown> = {
    display: T;
};

type SymbolShape<T = unknown> = {
    [symbol]: T;
};

export type ViewModel<
    // see `buildViewModel` for explanation of this eslint exception
    // eslint-disable-next-line @typescript-eslint/ban-types
    Base extends object,
    // eslint-disable-next-line @typescript-eslint/ban-types
    Props extends object
> = Base & (DisplayShape<Props> | SymbolShape<Props>);

export function getViewModelProperties<T extends DisplayShape | SymbolShape>(
    object: T
): T extends DisplayShape ? T['display'] : T extends SymbolShape ? T[typeof symbol] : never {
    return object && object[viewModelProperties] ? object[viewModelProperties] : {};
}

export function assignViewModelProperties<T extends Partial<DisplayShape | SymbolShape>>(
    object: T,
    newProperties: Record<string, unknown> = {}
) {
    return !isEmpty(newProperties)
        ? {
              ...object,
              [viewModelProperties]: {
                  ...object[viewModelProperties],
                  ...newProperties,
              },
          }
        : object;
}

/**
 * This method returns a function (object processor) which can be used to build
 *   view models. The configuration object which is passed determines the
 *   behaviour of the object processor.
 *
 *  In order to get a fully-typed output, the original model must be passed in as the first generic
 *  And the computed view model properties inputted as the second generic. There is near-zero type
 *  inference in this function due to the difficulty.
 * @param      [config.recursive=false] Determines whether or not the returned
 *   object processor should recurse on nested objects or arrays of objects
 * @param   [config.mappers]         An array of 'mapper' functions. Mapper
 *   functions are passed the object to process as well as all helpers provided
 *   in `config.helpers`. Any value returned from a mapper is spread in to the
 *   view model properties object of a source object.
 * @param       [config.helpers]         An object of helper functions.
 *   Helper functions are provided to each mapper to help format data.
 * @return    A function which accepts an object and uses bound
 *   mappers/helpers to assign view model properties to said object.
 */
export function buildViewModel<
    // Ideally we would be using `Record<string, unknown>` here instead of `object`. This is sadly not possible right now
    // as all our generated types use `interface`s instead of `type`s. This leads to them not having an index signature,
    // preventing them to be passed to a generic extending `Record<string, unknown>.
    // See https://github.com/microsoft/TypeScript/issues/15300
    // eslint-disable-next-line @typescript-eslint/ban-types
    T extends object,
    P = unknown
>(config: {
    recursive?: boolean;
    mappers?: ((obj: T, helpers: unknown) => Record<string, unknown>)[];
    helpers?: {
        [s: string]: unknown;
    };
}): (obj: T) => (T & DisplayShape<P>) | (T & SymbolShape<P>) {
    return (obj: T) => {
        return reduce<T>(
            config.mappers,
            (object, mapper) => assignViewModelProperties(object, mapper(object, config.helpers)),
            {
                ...obj,
                ...(config.recursive
                    ? (_(obj)
                          // recurse on either an array of objects or a single object
                          .pickBy(
                              (val) =>
                                  (isArray(val) && isObject(val[0])) ||
                                  (!isArray(val) && isObject(val))
                          )
                          .thru((nestedObject) =>
                              mapValues(nestedObject, (nestedMember) =>
                                  isArray(nestedMember)
                                      ? map(nestedMember, (member) =>
                                            buildViewModel(config)(member)
                                        )
                                      : buildViewModel(config)((nestedMember as unknown) as T)
                              )
                          )
                          .value() as T)
                    : {}),
            }
        ) as (T & DisplayShape<P>) | (T & SymbolShape<P>);
    };
}

export function allAttributeIdListsMapper(obj, helpers) {
    return _(obj)
        .pickBy((val, key) => endsWith(key, 'AttrIds'))
        .mapValues((val) => helpers.formatAttributeById(val))
        .value();
}

export function allSingleAttributeValuesMapper(obj, helpers) {
    return _(obj)
        .pickBy((val, key) => endsWith(key, 'AttrId'))
        .mapValues((val) => helpers.formatAttributeById(val))
        .value();
}

export function subdivisionAttrIdsMapper(obj, helpers) {
    return _(obj)
        .pick('subdivisionAttrIds')
        .mapValues((val) => helpers.formatSubdivisionAttrIds(val, true, ' / '))
        .value();
}

export function allOfficerIdListsMapper(obj, helpers) {
    return _(obj)
        .pickBy((val, key) => endsWith(key, 'OfficerIds'))
        .mapValues((ids) => map(ids, helpers.formatMiniUserShortHandById))
        .value();
}

export function allSingleOfficerIdListsMapper(obj, helpers) {
    return _(obj)
        .pickBy((val, key) => endsWith(key, 'OfficerId'))
        .mapValues((val) => helpers.formatUserProfileById(val))
        .value();
}

export function buildJoinValuesMapper(property, joinWith = ', ') {
    return (obj) =>
        _(obj)
            .pick(property)
            .mapValues((values) => values.join(joinWith))
            .value();
}

export function boolToDisplayMapper(obj) {
    return _(obj)
        .pickBy((val) => isBoolean(val))
        .mapValues((val) => (val ? 'Yes' : 'No'))
        .value();
}

export function boolToCustomTrueFalseDisplay({ propName, propVal, trueDisplay, falseDisplay }) {
    if (propVal) {
        return {
            [propName]: trueDisplay,
        };
    }
    return {
        [propName]: propVal === false ? falseDisplay : undefined,
    };
}

/**
 *  For formats of output, see allMiniUserFormats in miniUsersHelpers
 */
export function buildAllMiniUserFormatsMapper(...otherUserFields) {
    return (obj, helpers) =>
        _(obj)
            .pickBy(
                (val, key) =>
                    endsWith(key, 'officerId') ||
                    endsWith(key, 'OfficerId') ||
                    endsWith(key, 'UserId') ||
                    endsWith(key, 'userId') ||
                    endsWith(key, 'CreatedBy') ||
                    endsWith(key, 'createdBy') ||
                    endsWith(key, 'UpdatedBy') ||
                    endsWith(key, 'updatedBy') ||
                    includes(otherUserFields, key)
            )
            .mapValues((value) => {
                // when we move miniUserHelpers to client-common we import it directly to this file
                // similar to how we import dateHelpers
                return helpers.allMiniUserFormatsById(value);
            })
            .value();
}

export function buildFormatRolesMapper(fieldMap) {
    return (obj, helpers) =>
        mapValues(fieldMap, (oldKey) => helpers.allRoleFormatsByRoleId(obj[oldKey]));
}

export function buildFormatRolesArrayMapper(fieldMap) {
    return (obj, helpers) =>
        mapValues(fieldMap, (oldKey) => map(obj[oldKey], helpers.allRoleFormatsByRoleId));
}

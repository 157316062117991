import nameNameLinksActionTypes from '../actions/types/nameNameLinksActionTypes';

export function nameNameLinksDataReducer(state = [], action) {
    switch (action.type) {
        case nameNameLinksActionTypes.SET_NAME_NAME_LINKS:
            return action.payload;
        default:
            return state;
    }
}

import { RefContextEnum } from '@mark43/rms-api';
import { _Form } from 'markformythree';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { RmsAction } from '../../../../../core/typings/redux';
import { createRMSArbiterInstance } from '../../../../core/arbiter';
import { ArbiterInstance } from '../../../../core/markformythree-arbiter/ArbiterForm';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import { caseLinkedProfilesForm, CaseLinkedProfilesFormModel } from '../forms/caseLinkedProfilesForm';
import { setFormModelAndValidate } from './caseLinkedProfilesSidePanel';

const formContext = RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name;
const formInstance = 'CASE_LINKED_PROFILES_ERRORS_BANNER';

export const validateCaseProfileLinks = (
    formModel: CaseLinkedProfilesFormModel,
    setErrors: (errors: string[]) => void
): RmsAction<void> => (
    dispatch,
    getState,
    { formsRegistry }
) => {
    const state = getState();
    const formatFieldByName = formatFieldByNameSelector(state);

    const arbiterInstance: ArbiterInstance = createRMSArbiterInstance(
        getState,
        formContext
    );

    const existingForm = formsRegistry.get(formContext, formInstance);

    if (!existingForm) {
        const form = new _Form({
            name: caseLinkedProfilesForm.name,
            initialState: formModel,
            configuration: caseLinkedProfilesForm.configuration,
            onValidate: createArbiterMFTValidationHandler(
                arbiterInstance,
                formContext,
                formatFieldByName
            ),
            validationEvents: mftArbiterValidationEvents,
        });
        // @ts-expect-error `form` is not assignable to `_Form<MFTFormConfiguration>`
        formsRegistry.register(form, formInstance);
    }

    formsRegistry.maybeDeferredOperation(
        formContext,
        formInstance,
        setFormModelAndValidate(formModel, setErrors)
    );
};

import { LookerDashboard } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const lookerDashboardsModule = createNormalizedModule<LookerDashboard>({
    type: 'lookerDashboards',
    key: 'lookerId',
});

// ACTIONS

// SELECTORS

export const lookerDashboardsWhereSelector = lookerDashboardsModule.selectors.entitiesWhereSelector;
export const lookerDashboardsSelector = lookerDashboardsModule.selectors.entitiesSelector;

// NEXUS PROP
export const NEXUS_STATE_PROP = 'lookerDashboards';

// REDUCERS
export default lookerDashboardsModule.reducerConfig;

import { routerReducer as baseRouterReducer } from 'react-router-redux';
import { createSelector } from 'reselect';

// /////////////
// CONSTANTS //
// /////////////

// Action Types
export const ENTER_NEW_ROUTE = 'ENTER_NEW_ROUTE';

// Constants used by reducer
const ROUTE_NAME = 'ROUTE_NAME';
export const NO_ROUTE_NAME = 'NO_ROUTE_NAME';

// ///////////
// ACTIONS //
// ///////////

/**
 * This action is responsible for augmenting our router state with extra
 *   parameters used in Cobalt's context feature. It will be consumed by
 *   the routerReducer.
 * @param  {string}      route.name  The name of the route. Defined in routesConfig
 * @return {Redux.Action}            A redux action object which will be used
 *   to populate state with information about the route the user is currently
 *   viewing
 */
export function enterNewRoute({ name, params }) {
    return {
        type: ENTER_NEW_ROUTE,
        payload: {
            name,
            params,
        },
    };
}

// /////////////
// SELECTORS //
// /////////////

export const routerSelector = (state) => state.routing;

export const routeNameSelector = createSelector(routerSelector, ({ ROUTE_NAME }) => ROUTE_NAME);

export const routerLocationSelector = createSelector(
    routerSelector,
    ({ locationBeforeTransitions: location }) => location
);

// //////////
// ROUTER //
// //////////

/**
 * Cobalt's router reducer. Router state available at store.state.routing. This
 *   state holds information about the current route, primarily for so that it
 *   can be consumed to implement Cobalt's context behaviours.
 * @param  {string}      state.ROUTE_NAME                The route name. Route
 *   names are effectively an enum with one value for each page in cobalt. Route
 *   names are defined in routesConfig.
 * @param  {Object}      state.params                    The current router
 *   params. For instance, when a user visits `/reports/1234` which matches
 *   our route `/reports/:reportId` this object will take the shape
 *   {reportId: 1234}
 * @param  {Location}    state.locationBeforeTransitions The location object
 *   which pertains to the route the user is currently viewing.
 * @return {Object} The current router state.
 */
export default function routerReducer(
    state = {
        [ROUTE_NAME]: NO_ROUTE_NAME,
        params: {},
    },
    action
) {
    const baseRouterState = baseRouterReducer(state, action);
    // if we meet these conditions we know state has not changed, so return
    // the same object reference
    if (state === baseRouterState && action.type !== ENTER_NEW_ROUTE) {
        return state;
    }
    switch (action.type) {
        case ENTER_NEW_ROUTE:
            // in this case baseRouterState has not change but we have
            // a new ROUTE_NAME
            return {
                ...state,
                [ROUTE_NAME]: action.payload.name,
                params: action.payload.params,
            };
        default:
            // in this case the routerReducer provided by react-router-redux
            // has returned a new state, and so we apply those new values
            // on top of our existing state and return the new object
            return {
                ...state,
                ...baseRouterState,
            };
    }
}

import React from 'react';
import createElement from '../../../core/utils/recompose.createElement';

import Link from '../../../core/components/links/Link';

import ImageCell from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/ImageCell';
import PropertyCell from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/PropertyCell';
import IdentifiersCell from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/IdentifiersCell';
import OwnerCell from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/OwnerCell';
import CaseLinkedProfile from './CaseLinkedProfile';
import CaseSourceIndicator from './CaseSourceIndicator';

export default function CaseLinkedProperty({ property }) {
    // use of `createElement` is due to the fact that all `Symbol` properties
    // of an object are lost when it's "copied" (via spread syntax). This is an intended
    // effect of spread syntax / for in
    return (
        <Link to={`/profiles/property/${property.id}`} target="_blank">
            <CaseLinkedProfile>
                <div className="case-related-entity-cell">
                    {createElement(ImageCell, { ...property })}
                </div>
                <div className="case-related-entity-cell related-entity-property-cell">
                    {createElement(PropertyCell, { ...property })}
                    <CaseSourceIndicator sources={property.sources} />
                </div>
                <div className="case-related-entity-cell related-entity-property-identifiers-cell">
                    {createElement(IdentifiersCell, { ...property })}
                </div>
                <div className="case-related-entity-cell related-entity-property-owner-cell">
                    {createElement(OwnerCell, { ...property })}
                </div>
            </CaseLinkedProfile>
        </Link>
    );
}

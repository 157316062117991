import React from 'react';
import styled from 'styled-components';
import BaseHighlighter from 'react-highlight-words';
import { omit } from 'lodash';

/**
 * @param {Object} props
 */
function Highlighter({ className, ...otherProps }) {
    const props = omit(otherProps, ['highlightColor', 'highlightBackgroundColor']);

    return <BaseHighlighter highlightClassName={className} {...props} />;
}

/**
 * @see https://github.com/bvaughn/react-highlight-words
 */
export default styled(Highlighter)`
    color: ${(props) => props.highlightColor || 'inherit'};
    background-color: ${(props) =>
        props.highlightBackgroundColor || 'var(--arc-colors-selected-accent)'};
`;

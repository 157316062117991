import React from 'react';
import PropTypes from 'prop-types';
import { Motion, spring, presets } from 'react-motion';
import styled from 'styled-components';

import testIds from '../../../core/testIds';

function LegacyLoadingBar({ visible }) {
    return (
        <Motion
            defaultStyle={{ height: 0 }}
            style={{ height: spring(visible ? 12 : 0, presets.gentle) }}
        >
            {({ height }) => (
                <div
                    className="loading-bar"
                    style={{ height }}
                    data-test-id={testIds.LOADING_BAR}
                />
            )}
        </Motion>
    );
}

LegacyLoadingBar.propTypes = {
    visible: PropTypes.bool,
};

/**
 * The global loading bar which always appears under the header.
 */
export default LegacyLoadingBar;

const Bar = styled.div`
    background-image: url(../images/loading/loadingBar-Blue-Faster-v2.gif);
    background-size: cover;
    height: 0;
    width: 100%;
    font-size: var(--arc-fontSizes-sm);
    ${({ maxHeight }) => (maxHeight ? `line-height: ${maxHeight}px` : '')};
    text-align: center;
    color: ${(props) => props.theme.colors.white};
    text-shadow: 0 1px 1px ${(props) => props.theme.colors.navyBlue};
    overflow: hidden;
`;

/**
 * A loading bar which may be positioned anywhere on the page, unlike the global LegacyLoadingBar.
 *
 * When `children` is provided as a string, the text gets rendered in the middle of the loading bar.
 */
export function LoadingBar({
    visible,
    maxHeight = 12,
    className = undefined,
    children = undefined,
    style = undefined,
}) {
    return (
        <Motion
            defaultStyle={{ height: 0 }}
            style={{ height: spring(visible ? maxHeight : 0, presets.gentle) }}
        >
            {({ height }) => (
                <Bar
                    className={className}
                    style={{ height, ...style }}
                    data-test-id={testIds.LOADING_BAR}
                >
                    {visible && children}
                </Bar>
            )}
        </Motion>
    );
}

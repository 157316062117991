import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { InlineBanner, cssVar, mediaQueries } from 'arc';
import { EntityTypeEnumType } from '@mark43/rms-api';

import { securityClassificationViewModelsByOwnerSelector } from '~/client-common/core/domain/security-classifications/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

const BannerContainer = styled.div`
    width: 100%;

    & > * {
        max-width: ${(props) => `${props.theme.widths.container}px`};
        margin: ${cssVar('arc.space.6')} auto 0;
    }

    @media (min-width: ${mediaQueries.lg}) {
        padding: 0 ${cssVar('arc.space.3')};
    }
`;

export const SecurityClassificationBanner = ({
    ownerType,
    ownerId,
    className,
}: {
    ownerType: EntityTypeEnumType;
    ownerId: number;
    className?: string;
}): JSX.Element | null => {
    const securityClassificationsViewModels = useSelector(
        securityClassificationViewModelsByOwnerSelector
    )({ ownerType, ownerId });

    if (securityClassificationsViewModels.length === 0) {
        return null;
    }

    return (
        <BannerContainer>
            {securityClassificationsViewModels.map((viewModel) => {
                const { securityClassificationAttrId } = getViewModelProperties(viewModel);

                return (
                    <InlineBanner
                        key={viewModel.id}
                        className={className}
                        status="attention"
                        title={securityClassificationAttrId}
                        description={viewModel.handlingInstructions}
                    />
                );
            })}
        </BannerContainer>
    );
};

import { EvidenceReportView } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'evidenceReports';

const evidenceReportsModule = createNormalizedModule<EvidenceReportView>({
    type: NEXUS_STATE_PROP,
    key: 'reportId',
});

// SELECTORS
export const evidenceReportsSelector = evidenceReportsModule.selectors.entitiesSelector;
export const evidenceReportByIdSelector = evidenceReportsModule.selectors.entityByIdSelector;

// REDUCER
export default evidenceReportsModule.reducerConfig;

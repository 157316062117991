import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Row from '../../../../../core/components/Row';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import ChargeTitle from '../ChargeTitle';
import {
    getBookingSidePanelForm,
    keysToResetOnDispositionAttributeChange,
} from '../../../state/forms/bookingSidePanelForm';
import { prefillChargeFormData } from '../../../state/forms/bookingForm';
import { LabelExpander as _LabelExpander } from '../../../../../core/components/ExpandableFormLabel';
import { dateTimePickerTotalWidth } from '../../../../../../legacy-redux/configs/formsConfig';

const strings = componentStrings.reports.core.BookingSidePanel;

const RowWithMarginBottom = styled(Row)`
    margin-bottom: 12px;
`;

const IndentedRow = styled(Row)`
    margin-left: 12px;
`;

const LabelExpander = styled(_LabelExpander)`
    float: right;
    right: 164px;
    top: -8px;
`;

const ChargesNItems = ({ item, index }) => {
    const showApplyToAllButton = index === 0;
    return (
        <div>
            <RowWithMarginBottom>
                <ChargeTitle charge={item} />
            </RowWithMarginBottom>
            <IndentedRow>
                <ArbiterMFTAttributeSelect
                    path="dispositionAttrId"
                    onChange={() => {
                        const form = getBookingSidePanelForm();
                        if (form) {
                            form.transaction(() => {
                                map(keysToResetOnDispositionAttributeChange, (key) => {
                                    form.set(`charges[${index}].${key}`, undefined);
                                });
                            });
                        }
                    }}
                    attributeType={AttributeTypeEnum.ARREST_DISPOSITION.name}
                    width="45%"
                />
                <ArbiterMFTDatePicker
                    path="dispositionDateUtc"
                    includeTime={true}
                    width={dateTimePickerTotalWidth}
                />
                {showApplyToAllButton && (
                    <LabelExpander
                        position="right"
                        onClick={() => {
                            const form = getBookingSidePanelForm();
                            if (form) {
                                const charges = form.get('charges');
                                const currentCharge = charges[index];

                                return prefillChargeFormData({
                                    form,
                                    prefillFromChargeId: currentCharge.id,
                                });
                            }
                        }}
                    >
                        {strings.applyToAll}
                    </LabelExpander>
                )}
            </IndentedRow>
            <IndentedRow>
                <ArbiterMFTAttributeSelect
                    path="juvenileDispositionAttrId"
                    attributeType={AttributeTypeEnum.JUVENILE_DISPOSITION.name}
                    width="45%"
                />

                <ArbiterMFTDatePicker
                    path="juvenileDispositionDateUtc"
                    includeTime={true}
                    width={dateTimePickerTotalWidth}
                />
            </IndentedRow>
            <IndentedRow>
                <ArbiterMFTText path="collateralBondAmountPaid" width="45%" />
                <ArbiterMFTText path="collateralBondReceiptNum" width="45%" />
            </IndentedRow>
        </div>
    );
};

export default ChargesNItems;

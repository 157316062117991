import { OperationTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import _, { map, includes, get, head } from 'lodash';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { itemProfileViewModelByIdSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import {
    POLL_IMPORT_EVENT_START,
    POLL_IMPORT_EVENT_FAILURE,
} from '~/client-common/core/domain/import-events/state/data';
import { assistingOfficersSelector } from '~/client-common/core/domain/assisting-officers/state/data';
import { custodialResponsibleOfficersWhereSelector } from '~/client-common/core/domain/custodial-responsible-officers/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';

import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import {
    currentReportIdSelector,
    currentReportSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { formatUserByIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';
import { loadCustodialReportBundle } from '../../../../evidence/core/state/data';

export * from './propertyStatus';
export * from './disposition';
export * from './evidenceDocument';

const SELECT_ITEM_PROFILES = 'custodial-property-card/SELECT_ITEM_PROFILES';
const LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_START =
    'custodial-property-card/LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_START';
const LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_SUCCESS =
    'custodial-property-card/LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_SUCCESS';
const LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_FAILURE =
    'custodial-property-card/LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_FAILURE';

function loadEvidenceDataForCurrentReportStart() {
    return {
        type: LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_START,
    };
}
function loadEvidenceDataForCurrentReportSuccess(custodialReportBundle) {
    return {
        type: LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_SUCCESS,
        payload: custodialReportBundle,
    };
}
function loadEvidenceDataForCurrentReportFailure(errorMessage) {
    return {
        type: LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Loading Evidence data for the specified Custodial Property Summary reportId
 *   This is a separate action creator because it's data outside of the RMS.
 * TODO: move this and related redux actions to /state/data
 * @param  {number}  reportId
 * @return {RmsAction<Promise<void>>}
 */
export function loadEvidenceDataForReportId(reportId) {
    return (dispatch) => {
        dispatch(loadEvidenceDataForCurrentReportStart());

        return dispatch(loadCustodialReportBundle(reportId))
            .then(
                ({
                    assignedInvestigatorUserId,
                    assignedPersonnelUnitAttrId,
                    assignedSupervisorUserIds,
                    assistingOfficers,
                    responsibleOfficer,
                }) => {
                    dispatch(
                        loadEvidenceDataForCurrentReportSuccess({
                            assignedInvestigatorUserId,
                            assignedPersonnelUnitAttrId,
                            assignedSupervisorUserIds,
                            assistingOfficers,
                            responsibleOfficer,
                        })
                    );
                }
            )
            .catch((err) => {
                dispatch(loadEvidenceDataForCurrentReportFailure(err.message));
                throw err;
            });
    };
}

/**
 * Legacy Custodial Report evidence data loader
 * @return {RmsAction<Promise<void>>}
 */
export function loadEvidenceDataForCurrentReport() {
    return (dispatch, getState) => {
        const currentReportId = currentReportIdSelector(getState());

        dispatch(loadEvidenceDataForReportId(currentReportId));
    };
}

/**
 * Select the given item profile ids in the custodial report. This selection is
 *   used for the custodial property actions such as "request checkout" and "add
 *   to queue", which means the items have to have chains of custody. Note there
 *   is no "unselect" action, so you have to pass in the entire selection each
 *   time.
 */
export function selectItemProfiles(ids) {
    return {
        type: SELECT_ITEM_PROFILES,
        payload: ids,
    };
}

/**
 * Whether the current report (doesn't have to be custodial) has at least 1 item
 *   profile that has at least 1 property status.
 * @type {boolean}
 */
export const currentReportHasItemProfilesSelector = createSelector(
    currentReportIdSelector,
    itemProfilesInReportSelector,
    (currentReportId, itemProfilesInReport) => itemProfilesInReport(currentReportId).length > 0
);

// manually check permissions
// can not use canEditReportCardStatusSelector because ui report is not synced on report load
export const canEditCustodialReportSelector = createSelector(
    currentReportSelector,
    (currentReport) => includes(currentReport.permissionSet, OperationTypeEnum.WRITE.name)
);

const involvedPersonnelIdsSelector = createSelector(
    assistingOfficersSelector,
    currentReportIdSelector,
    (assistingOfficers, currentReportId) =>
        _(assistingOfficers)
            .filter((assistingOfficer) => assistingOfficer.reportId === currentReportId)
            .map('officerId')
            .value()
);

export const responsibleOfficerSelector = createSelector(
    custodialResponsibleOfficersWhereSelector,
    currentReportIdSelector,
    (custodialResponsibleOfficersWhere, currentReportId) =>
        head(custodialResponsibleOfficersWhere({ reportId: currentReportId }))
);

const custodialPropertyUiSelector = (state) => state.ui.report.custodialProperty;

export const custodialReportBundleSelector = createSelector(
    custodialPropertyUiSelector,
    (custodialPropertyUi) => custodialPropertyUi.currentCustodialReportBundle
);

export const personnelCardViewModelSelector = createSelector(
    involvedPersonnelIdsSelector,
    responsibleOfficerSelector,
    formatUserByIdSelector,
    custodialReportBundleSelector,
    formatAttributeByIdSelector,
    (
        involvedPersonnelIds,
        responsibleOfficer,
        formatUserById,
        custodialReportBundle,
        formatAttributeById
    ) => ({
        assignedInvestigator: formatUserById(custodialReportBundle.assignedInvestigatorUserId),
        assignedUnit: formatAttributeById(custodialReportBundle.assignedPersonnelUnitAttrId),
        assignedSupervisors: map(custodialReportBundle.assignedSupervisorUserIds, (id) => ({
            id,
            summary: formatUserById(id),
        })),
        involvedPersonnel: map(involvedPersonnelIds, (id) => ({
            id,
            summary: formatUserById(id),
        })),
        responsibleOfficer: formatUserById(get(responsibleOfficer, 'officerId')),
    })
);

/**
 * Whether evidence data is currently being loaded for the item profiles in the
 *   custodial property summary report.
 * @type {boolean}
 */
export const loadingEvidenceDataSelector = createSelector(
    custodialPropertyUiSelector,
    ({ loadingEvidenceData }) => loadingEvidenceData
);

/**
 * @type {string}
 */
export const loadingEvidenceDataErrorMessageSelector = createSelector(
    custodialPropertyUiSelector,
    ({ loadingEvidenceDataErrorMessage }) => loadingEvidenceDataErrorMessage
);

/**
 * Ids of the item profiles that are currently selected using checkboxes.
 * @type {() => number[]}
 */
export const selectedItemProfileIdsSelector = createSelector(
    custodialPropertyUiSelector,
    ({ selectedItemProfileIds }) => selectedItemProfileIds
);

/**
 * Converting all of the selected item profiles to their corresponding
 * master item ids
 */
export const selectedMasterItemIdsSelector = createSelector(
    selectedItemProfileIdsSelector,
    itemProfileViewModelByIdSelector,
    (selectedItemProfileIds, itemProfileViewModelById) => {
        return map(selectedItemProfileIds, (id) => itemProfileViewModelById(id).masterItemId);
    }
);

export const custodialPropertyUiReducer = makeResettable(
    ENTER_NEW_ROUTE,
    function custodialPropertyUiReducer(
        state = {
            loadingEvidenceData: false,
            loadingEvidenceDataErrorMessage: null,
            selectedItemProfileIds: [],
            currentCustodialReportBundle: {},
        },
        action
    ) {
        switch (action.type) {
            case LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_START:
            case POLL_IMPORT_EVENT_START:
                return {
                    ...state,
                    loadingEvidenceData: true,
                    loadingEvidenceDataErrorMessage: null,
                };
            case LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_SUCCESS:
                // POLL_IMPORT_EVENT_SUCCESS is not here because it is always
                // followed by LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_SUCCESS
                return {
                    ...state,
                    loadingEvidenceData: false,
                    loadingEvidenceDataErrorMessage: null,
                    currentCustodialReportBundle: action.payload,
                };
            case LOAD_EVIDENCE_DATA_FOR_CURRENT_REPORT_FAILURE:
            case POLL_IMPORT_EVENT_FAILURE:
                return {
                    ...state,
                    loadingEvidenceData: false,
                    loadingEvidenceDataErrorMessage: action.payload,
                };
            case SELECT_ITEM_PROFILES:
                return {
                    ...state,
                    selectedItemProfileIds: action.payload,
                };
            default:
                return state;
        }
    }
);

import styled from 'styled-components';
import { TableRow, TableColumn, TableCell } from 'components-mark43';

export const DashboardTableColumn = TableColumn;
export const EFileTableHeaderRow = TableRow;

export const EFileTableHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

export const EFileTableHeaderCell = styled(TableCell)`
    padding: 8px 10px;
    font-size: var(--arc-fontSizes-md);
    font-weight: bold;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.cobaltBlue};
`;

export const EFileTableHeader = styled.div<{ width: number }>`
    line-height: 35px;
    padding: 0;
    font: ${(props) =>
        `${props.theme.fontWeights.semiBold} var(--arc-fontSizes-sm) ${props.theme.fontFamilies.proximaNova}`};
    color: ${(props) => props.theme.colors.mediumGrey};
    width: ${(props) => props.width}px;
`;

export const EFileCheckboxTableHeader = styled.div`
    margin: 0 10px;
    display: flex;
`;

export const EFileNameTableHeaderCell = styled(EFileTableHeaderCell)`
    display: flex;
    justify-content: center;
`;

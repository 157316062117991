import React, { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import { logError } from '../../../../core/logging';

interface State {
    lookerIframeLoaded: boolean;
    lookerEventSubscriptions: { [eventType: string]: ((eventData: LookerEventData) => void)[] };
}

interface Action {
    type: 'SET_LOOKER_IFRAME_LOADED' | 'ADD_LOOKER_EVENT_CALLBACK' | 'REMOVE_LOOKER_EVENT_CALLBACK';
    loaded?: boolean;
    eventType?: string;
    callback?: (eventData: LookerEventData) => void;
}

export interface LookerEventData {
    type: string;
    dashboard?: {
        id?: string;
    };
    page?: {
        type?: string;
    };
}

export const AnalysisContext = createContext<{
    setLookerIframeLoaded: (loaded: boolean) => void;
    addLookerEventCallback: (
        eventType: string,
        callback: (eventData: LookerEventData) => void
    ) => void;
    removeLookerEventCallback: (
        eventType: string,
        callback: (eventData: LookerEventData) => void
    ) => void;
} | null>(null);

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_LOOKER_IFRAME_LOADED':
            return { ...state, lookerIframeLoaded: action.loaded ?? false };
        case 'ADD_LOOKER_EVENT_CALLBACK':
            if (!action.eventType || !action.callback) {
                return state;
            }
            return {
                ...state,
                lookerEventSubscriptions: {
                    ...state.lookerEventSubscriptions,
                    [action.eventType]: [
                        ...(state.lookerEventSubscriptions[action.eventType] || []),
                        action.callback,
                    ],
                },
            };
        case 'REMOVE_LOOKER_EVENT_CALLBACK':
            if (!action.eventType || !action.callback) {
                return state;
            }
            return {
                ...state,
                lookerEventSubscriptions: {
                    ...state.lookerEventSubscriptions,
                    [action.eventType]: state.lookerEventSubscriptions[action.eventType].filter(
                        (cb) => cb !== action.callback
                    ),
                },
            };
        default:
            return state;
    }
}

const setLookerIframeLoaded = (loaded: boolean): Action => ({
    type: 'SET_LOOKER_IFRAME_LOADED',
    loaded,
});

const addLookerEventCallback = (
    eventType: string,
    callback: (eventData: LookerEventData) => void
): Action => ({
    type: 'ADD_LOOKER_EVENT_CALLBACK',
    eventType,
    callback,
});

const removeLookerEventCallback = (
    eventType: string,
    callback: (eventData: LookerEventData) => void
): Action => ({
    type: 'REMOVE_LOOKER_EVENT_CALLBACK',
    eventType,
    callback,
});

function useActions(dispatch: React.Dispatch<Action>) {
    const handleSetLookerIframeLoaded = useCallback(
        (loaded: boolean) => {
            dispatch(setLookerIframeLoaded(loaded));
        },
        [dispatch]
    );

    const handleAddLookerEventCallback = useCallback(
        (eventType: string, callback: (eventData: LookerEventData) => void) => {
            dispatch(addLookerEventCallback(eventType, callback));
        },
        [dispatch]
    );

    const handleRemoveLookerEventCallback = useCallback(
        (eventType: string, callback: (eventData: LookerEventData) => void) => {
            dispatch(removeLookerEventCallback(eventType, callback));
        },
        [dispatch]
    );

    return {
        setLookerIframeLoaded: handleSetLookerIframeLoaded,
        addLookerEventCallback: handleAddLookerEventCallback,
        removeLookerEventCallback: handleRemoveLookerEventCallback,
    };
}

export const AnalysisContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
        lookerIframeLoaded: false,
        lookerEventSubscriptions: {},
    });
    const actions = useActions(dispatch);

    useEffect(() => {
        if (!state.lookerIframeLoaded) {
            return;
        }

        const handleMessage = (event: MessageEvent) => {
            const lookerIframe = document.getElementsByClassName(
                'analysis-dashboard-frame'
            )[0] as HTMLIFrameElement;

            if (lookerIframe?.contentWindow && event.source === lookerIframe.contentWindow) {
                let lookerEventData: LookerEventData;
                try {
                    lookerEventData = JSON.parse(event.data);
                } catch (error) {
                    logError(`Failed to parse looker event data: ${error}`);
                    return;
                }
                state.lookerEventSubscriptions[lookerEventData.type]?.forEach((callback) =>
                    callback(lookerEventData)
                );
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [state.lookerIframeLoaded, state.lookerEventSubscriptions, actions]);

    const contextValue = useMemo(
        () => ({
            ...actions,
        }),
        [actions]
    );

    return <AnalysisContext.Provider value={contextValue}>{children}</AnalysisContext.Provider>;
};

import keyMirror from 'keymirror';

export default keyMirror({
    EVENT: null,
    ASSIGNED: null,
    MODIFIED: null,
    CREATED: null,
    DUE: null,
    COMPLETED: null,
});

import { LinkTypesEnum, ComplianceGroupEnum } from '@mark43/rms-api';

export const DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_COMPLIANCE_GROUP = {
    [ComplianceGroupEnum.UNITED_KINGDOM.name]: [
        LinkTypesEnum.STEP_GRANDPARENT_OF,
        LinkTypesEnum.ADOPTED_GRANDPARENT_OF,

        LinkTypesEnum.GRANDCHILD_OF,
        LinkTypesEnum.HETEROSEXUAL_PARTNER_OF,
        LinkTypesEnum.NON_HETEROSEXUAL_PARTNER_OF,
        LinkTypesEnum.PARTNERSHIP_NOT_KNOWN,
        LinkTypesEnum.HETEROSEXUAL_EX_PARTNER_OF,
        LinkTypesEnum.NON_HETEROSEXUAL_EX_PARTNER_OF,
        LinkTypesEnum.EX_PARTNER_PARTNERSHIP_NOT_KNOWN,
        LinkTypesEnum.STEP_PARENT_OF,
        LinkTypesEnum.ADOPTED_PARENT_OF,
        LinkTypesEnum.CHILD_OF,

        LinkTypesEnum.ADOPTED_BROTHER_OF,
        LinkTypesEnum.HALF_BROTHER_OF,
        LinkTypesEnum.STEP_SIBLING_OF,
        LinkTypesEnum.SIBLING_OF,

        LinkTypesEnum.ADOPTED_SISTER_OF,
        LinkTypesEnum.HALF_SISTER_OF,

        LinkTypesEnum.RELATIVE_OF,

        LinkTypesEnum.OTHER_SEXUAL_RELATIONSHIP,
    ],
} as const;

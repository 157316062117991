import { EntityTypeEnum } from '@mark43/rms-api';
import getOrganizationProfileResource from '~/client-common/core/domain/organization-profiles/resources/organizationProfileResource';
import { NEXUS_STATE_PROP as SOCIETY_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/society-profiles/state/data';

export const LOAD_SOCIETY_PROFILES_START = 'society-profiles/LOAD_SOCIETY_PROFILES_START';
export const LOAD_SOCIETY_PROFILES_SUCCESS = 'society-profiles/LOAD_SOCIETY_PROFILES_SUCCESS';
export const LOAD_SOCIETY_PROFILES_FAILURE = 'society-profiles/LOAD_SOCIETY_PROFILES_FAILURE';
export const SAVE_SOCIETY_PROFILE_START = 'society-profiles/SAVE_SOCIETY_PROFILE_START';
export const SAVE_SOCIETY_PROFILE_SUCCESS = 'society-profiles/SAVE_SOCIETY_PROFILE_SUCCESS';
export const SAVE_SOCIETY_PROFILE_FAILURE = 'society-profiles/SAVE_SOCIETY_PROFILE_FAILURE';
export const REMOVE_SOCIETY_PROFILE_START = 'society-profiles/REMOVE_SOCIETY_PROFILE_START';
export const REMOVE_SOCIETY_PROFILE_SUCCESS = 'society-profiles/REMOVE_SOCIETY_PROFILE_SUCCESS';
export const REMOVE_SOCIETY_PROFILE_FAILURE = 'society-profiles/REMOVE_SOCIETY_PROFILE_FAILURE';

function loadSocietyProfileStart() {
    return {
        type: LOAD_SOCIETY_PROFILES_START,
    };
}

function loadSocietyProfileSuccess() {
    return {
        type: LOAD_SOCIETY_PROFILES_SUCCESS,
    };
}

function loadSocietyProfileFailure(errMsg) {
    return {
        type: LOAD_SOCIETY_PROFILES_FAILURE,
        payload: errMsg,
    };
}

export function loadSocietyProfiles() {
    return function (dispatch, getState, dependencies) {
        dispatch(loadSocietyProfileStart());
        return getOrganizationProfileResource()
            .getSocietyProfiles()
            .then((societyProfiles) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [SOCIETY_PROFILES_NEXUS_STATE_PROP]: societyProfiles,
                        },
                        loadSocietyProfileSuccess()
                    )
                );
                return societyProfiles;
            })
            .catch((err) => dispatch(loadSocietyProfileFailure(err.message)));
    };
}

function saveSocietyProfileStart() {
    return {
        type: SAVE_SOCIETY_PROFILE_START,
    };
}

function saveSocietyProfileSuccess(societyProfile) {
    return {
        type: SAVE_SOCIETY_PROFILE_SUCCESS,
        payload: societyProfile,
    };
}

function saveSocietyProfileFailure(errMsg) {
    return {
        type: SAVE_SOCIETY_PROFILE_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function removeSocietyProfileStart() {
    return {
        type: REMOVE_SOCIETY_PROFILE_START,
    };
}

function removeSocietyProfileSuccess(societyProfile) {
    return {
        type: REMOVE_SOCIETY_PROFILE_SUCCESS,
        payload: societyProfile,
    };
}

function removeSocietyProfileFailure(errMsg) {
    return {
        type: REMOVE_SOCIETY_PROFILE_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveSocietyProfile(societyProfile) {
    return function (dispatch, getState, dependencies) {
        dispatch(saveSocietyProfileStart());
        const isNewProfile = !societyProfile.id;
        const resource = getOrganizationProfileResource();
        const promise = isNewProfile
            ? resource.createOrganizationProfile({
                  ...societyProfile,
                  isSociety: true,
                  ownerType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
              })
            : resource.updateOrganizationProfile({
                  profile: societyProfile,
                  isMaster: true,
              });
        return promise
            .then((societyProfile) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [SOCIETY_PROFILES_NEXUS_STATE_PROP]: [societyProfile],
                        },
                        saveSocietyProfileSuccess(societyProfile)
                    )
                );
                return societyProfile;
            })
            .catch((err) => dispatch(saveSocietyProfileFailure(err.message)));
    };
}

/**
 * Society profiles are not really deleted. Deletion is more of a demotion
 * from `isSociety:true` to `isSociety:false`.
 * @param {object} societyProfile
 */
export function removeSocietyProfile(societyProfile) {
    return function (dispatch, getState, dependencies) {
        dispatch(removeSocietyProfileStart());
        const resource = getOrganizationProfileResource();
        return resource
            .updateOrganizationProfile({
                profile: {
                    ...societyProfile,
                    isSociety: false,
                },
                isMaster: true,
            })
            .then((societyProfile) => {
                dispatch(
                    dependencies.nexus.withRemove(
                        SOCIETY_PROFILES_NEXUS_STATE_PROP,
                        { id: societyProfile.id },
                        removeSocietyProfileSuccess(societyProfile)
                    )
                );
                return societyProfile;
            })
            .catch((err) => dispatch(removeSocietyProfileFailure(err.message)));
    };
}

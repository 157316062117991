import { findIndex, get, keyBy, sortBy, map, trim } from 'lodash';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { sortRuleConfigurationContextsByContextName } from '../../utils/rulesAdminHelpers';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { FIELDSET, N_ITEMS_FIELDSET } = fieldTypeClientEnum;
const rulesAdminFormFieldViewModels = {
    rule: {
        type: FIELDSET,
        key: 'rule',
        fields: buildFlatFormFieldViewModels([
            'canDisableInAnyContext',
            'displayName',
            'name',
            'ruleType',
        ]),
    },
    ruleConfigurationContexts: {
        type: N_ITEMS_FIELDSET,
        key: 'ruleConfigurationContexts',
        fields: buildFlatFormFieldViewModels(['context', 'isDisabled', 'ruleFailureDisplayText']),
    },
    ruleActionedFields: {
        type: N_ITEMS_FIELDSET,
        key: 'ruleActionedFields',
        fields: buildFlatFormFieldViewModels(['fieldConfigurationId', 'ruleFailureDisplayText']),
    },
    ruleConditions: {
        type: N_ITEMS_FIELDSET,
        key: 'ruleConditions',
        fields: buildFlatFormFieldViewModels([
            'fieldConfigurationId',
            'ruleComparisonOperator',
            'tokenOrder',
            'fieldConfiguration2Id',
        ]),
    },
    ruleConditionArgs: {
        type: N_ITEMS_FIELDSET,
        key: 'ruleConditionArgs',
        fields: buildFlatFormFieldViewModels([
            'argName',
            'argType',
            'getterNum',
            'ruleConditionId',
            'ruleId',
            'value',
        ]),
    },
};

function convertFromFormModel({
    rule = {},
    ruleConfigurationContexts = {},
    ruleActionedFields = {},
    ruleConditions = {},
    ruleConditionArgs = {},
}) {
    const trimmedContexts = map(ruleConfigurationContexts, (context) => {
        return {
            ...context,
            ruleFailureDisplayText: trim(context.ruleFailureDisplayText),
        };
    });
    return {
        rule,
        ruleConfigurationContexts: trimmedContexts,
        ruleActionedFields,
        ruleConditions,
        ruleConditionArgs,
    };
}

function convertToFormModel({
    fieldDetails = {},
    fieldConfigurations = {},
    rule = {},
    ruleConfigurationContexts = {},
    ruleActionedFields = {},
    ruleConditions = {},
    ruleConditionArgs = {},
}) {
    const fieldDetailsById = keyBy(fieldDetails, 'id');

    const sortedRuleConfigurationContexts = sortRuleConfigurationContextsByContextName(
        ruleConfigurationContexts
    );

    const sortedFieldConfigurations = sortBy(fieldConfigurations, (fieldConfiguration) => {
        if (!isUndefinedOrNull(fieldConfiguration.displayName)) {
            return fieldConfiguration.displayName;
        } else {
            return get(fieldDetailsById, `${fieldConfiguration.fieldDetailId}.fieldName`) || '';
        }
    });

    const sortedRuleActionedFields = sortBy(ruleActionedFields, (ruleActionedField) => {
        const index = findIndex(
            sortedFieldConfigurations,
            (sortedFieldConfiguration) =>
                sortedFieldConfiguration.id === ruleActionedField.fieldConfigurationId
        );
        return index;
    });
    return {
        rule,
        ruleConfigurationContexts: sortedRuleConfigurationContexts,
        ruleActionedFields: sortedRuleActionedFields,
        ruleConditions,
        ruleConditionArgs,
    };
}

export default createFormModule({
    formName: formClientEnum.RULES_ADMIN,
    fieldViewModels: rulesAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

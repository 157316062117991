import { EntityTypeEnum } from '@mark43/rms-api';

import { find, map, compact } from 'lodash';
import { convertFileUploadResponseToAttachmentLink } from '~/client-common/core/domain/attachments/state/ui';
import { createOverlayCustomProperties } from '../../utils/createOverlayCustomProperties';

export function handleNameProfileFormError({
    error,
    appendPanelErrorMessageIfNotExists,
    overlayStore,
    overlayId,
}) {
    let errors = error.message;
    if (error.validationResult) {
        errors = error.validationResult.rawErrors;
    }
    appendPanelErrorMessageIfNotExists(errors);
    overlayStore.setCustomProperties(overlayId, createOverlayCustomProperties({ isSaving: false }));
}

export function augmentHydratedNameWithAttachmentsAndReportLinks({
    hydratedName,
    attachments,
    entityType,
    entityId,
    linkType,
    ownerId,
    contextId,
    contextType,
    isEditingMasterProfile,
}) {
    return {
        ...hydratedName,
        attachments: compact(
            (hydratedName.attachments || []).concat(
                map(attachments, (attachment) =>
                    convertFileUploadResponseToAttachmentLink(attachment, {
                        entityId,
                        entityType,
                        linkType: attachment.linkType,
                    })
                )
            )
        ),
        // when editing master profiles we do not want to send any report links
        // because a master profile is not linked to any reports
        reportLinks: isEditingMasterProfile
            ? undefined
            : [
                  {
                      entityType,
                      nameId: entityId,
                      linkType,
                      reportId: ownerId,
                      contextId,
                      contextType,
                  },
              ],
    };
}

export function shouldUseStubLink({ contextType, contextId }) {
    // If we haven't supplied a contextType and a contextId,
    // then generate a stub link
    return (!contextType && !contextId) || contextType === EntityTypeEnum.CASE.name;
}

export function createOnAddSuccessNameReportLink({
    contextId,
    contextType,
    linkType,
    ownerId,
    hydratedName,
    entityType,
}) {
    // consumers of the person side panel need to receive the newly created
    // name report link back for further usages
    //
    // Even though a defendant is not added using a name report link
    // we need to send a fake name report link to consumers
    // since it is infeasible to change the interface to
    // send the whole person profile at this point in time.
    const newNameReportLink = shouldUseStubLink({ contextType, contextId })
        ? {
              nameId:
                  hydratedName[
                      entityType === EntityTypeEnum.PERSON_PROFILE.name
                          ? 'personProfiles'
                          : 'organizationProfiles'
                  ][0].id,
              entityType,
              linkType,
          }
        : find(hydratedName.reportLinks, {
              entityType,
              linkType,
              reportId: ownerId,
              contextId,
              contextType,
          });

    return newNameReportLink;
}

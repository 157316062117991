import { NameIdentifier } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'nameIdentifiers';
const nameIdentifiersModule = createGroupedModule<NameIdentifier>({
    type: NEXUS_STATE_PROP,
    key: 'nameId',
});

// SELECTORS
export const nameIdentifiersSelector = nameIdentifiersModule.selectors.entitiesSelector;
export const nameIdentifiersByNameIdSelector =
    nameIdentifiersModule.selectors.entitiesByKeySelector;

// REDUCER
export default nameIdentifiersModule.reducerConfig;

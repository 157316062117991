import React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withHandlers } from 'recompose';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { createModalSelector } from '../../../core/box/state/ui';
import { commitAbilityChange } from '../state/ui';
import ModalBodyText from './ModalBodyText';
import ModalAbilityText from './ModalAbilityText';

const strings = componentStrings.admin.roles.ToggleAbilityModal;

const context = {
    name: boxEnum.TOGGLE_ABILITY_MODAL,
};

function ToggleAbilityModal({ title, body, confirmation, abilities, commitAbilityChange }) {
    return (
        <Modal context={context} title={title} onSave={commitAbilityChange}>
            <ModalBodyText>{body}</ModalBodyText>
            <ModalAbilityText>
                {map(abilities, (ability) => (
                    <div key={ability.id}>
                        {getViewModelProperties(ability).moduleName} - {ability.name}
                    </div>
                ))}
            </ModalAbilityText>
            <ModalBodyText>{confirmation}</ModalBodyText>
        </Modal>
    );
}

const abilityIdSelector = createModalSelector(context, 'abilityId');
const abilitiesSelector = createModalSelector(context, 'abilities');
const newValSelector = createModalSelector(context, 'newVal');

const mapStateToProps = (state) => {
    const abilityId = abilityIdSelector(state);
    const abilities = abilitiesSelector(state) || [];
    const newVal = newValSelector(state);
    const contextedStrings = newVal ? strings.enable : strings.disable;
    let title;
    let body;
    let confirmation;
    if (abilities.length === 1) {
        title = contextedStrings.title.single;
        body = contextedStrings.body.single;
        confirmation = contextedStrings.confirmation.single;
    } else {
        title = contextedStrings.title.multiple;
        body = contextedStrings.body.multiple;
        confirmation = contextedStrings.confirmation.multiple;
    }
    return {
        abilityId,
        title,
        body,
        confirmation,
        abilities,
        newVal,
    };
};

const mapDispatchToProps = (dispatch) => ({
    commitAbilityChange(abilityIds, newVal) {
        dispatch(commitAbilityChange(abilityIds, newVal));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        commitAbilityChange({ abilityId, abilities, commitAbilityChange, newVal }) {
            return () => {
                commitAbilityChange([abilityId, ...map(abilities, 'id')], newVal);
            };
        },
    })
)(ToggleAbilityModal);

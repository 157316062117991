import { WarrantDexSubmissionHistory } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const NEXUS_STATE_PROP = 'warrantDexSubmissionHistories';

const warrantDexSubmissionHistoriesModule = createNormalizedModule<WarrantDexSubmissionHistory>({
    type: NEXUS_STATE_PROP,
    key: 'rmsEventId',
});

// SELECTORS
export const warrantDexSubmissionHistoriesWhereSelector =
    warrantDexSubmissionHistoriesModule.selectors.entitiesWhereSelector;

// REDUCER
export default warrantDexSubmissionHistoriesModule.reducerConfig;

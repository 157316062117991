import { OperationTypeEnum, RoleTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import _, { omit, filter, get, map, some, every, includes } from 'lodash';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import caseDefaultTaskAssigneeTypeEnum from '~/client-common/core/enums/client/caseDefaultTaskAssigneeTypeEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { parsePeriod } from '~/client-common/core/dates/utils/dateRangeHelpers';
import { convertFromDefaultFolderView } from '~/client-common/core/domain/default-folders/utils/defaultFoldersHelpers';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import {
    requiredString,
    mustBeInt,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const { NORMAL, DEPARTMENT, SUPPORT } = RoleTypeEnum;

const caseDefinitionsAdminFormFieldViewModels = {
    caseDefinition: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'caseDefinition',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'name',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'nameAbbreviation',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'startDateUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            'endDateUtc',
            {
                key: 'caseDuration',
                validators: {
                    mustBeIntError: mustBeInt,
                },
            },
            'defaultCaseStatusAttrId',
            'defaultPersonnelUnitAttrId',
            'defaultAssigneeRoleId',
        ]),
    },
    defaultSupervisors: {
        type: fieldTypeClientEnum.N_FIELDSETS,
        key: 'defaultSupervisors',
        fields: buildFlatFormFieldViewModels(['id', 'roleId']),
    },
    defaultPermissions: {
        type: fieldTypeClientEnum.N_FIELDSETS,
        key: 'defaultPermissions',
        fields: buildFlatFormFieldViewModels(['id', 'roleId', 'operationType']),
    },
    defaultDepartmentPermissions: {
        type: fieldTypeClientEnum.N_FIELDSETS,
        key: 'defaultDepartmentPermissions',
        fields: buildFlatFormFieldViewModels(['id', 'roleId', 'operationType']),
    },
    defaultTaskList: {
        type: fieldTypeClientEnum.N_FIELDSETS,
        key: 'defaultTaskList',
        fields: buildFlatFormFieldViewModels([
            'id',
            'description',
            {
                key: 'dueDateInterval',
                validators: {
                    mustBeIntError: mustBeInt,
                },
            },
            'assigneeRoleId',
            'assigneeType',
            'title',
            'typeAttrId',
        ]),
    },
    defaultAttachmentFolders: {
        type: fieldTypeClientEnum.N_FIELDSETS,
        key: 'defaultAttachmentFolders',
        fields: buildFlatFormFieldViewModels(['id', 'name', 'defaultSubFolders']),
    },
    defaultNotesFolders: {
        type: fieldTypeClientEnum.N_FIELDSETS,
        key: 'defaultNotesFolders',
        fields: buildFlatFormFieldViewModels(['id', 'name', 'defaultSubFolders']),
    },
};

function convertToFormModel({
    caseDefinition = {},
    caseDefaultRoleLinks = [],
    defaultPermissions = [],
    defaultTaskList = [],
    roleById,
    currentDepartmentId,
    defaultFolders = [],
}) {
    const isExternalRole = (roleId) => {
        const role = roleById(roleId);
        // if the role doesn't exists we assume it is a user role,
        // which we do not support for external departments
        return role && role.departmentId !== currentDepartmentId;
    };
    const defaultDepartmentPermissions = filter(caseDefaultRoleLinks, (caseDefaultRoleLink) =>
        isExternalRole(caseDefaultRoleLink.roleId)
    );
    return {
        caseDefinition: {
            ...caseDefinition,
            // this should always be days
            caseDuration: caseDefinition.defaultDueDateInterval
                ? parsePeriod(caseDefinition.defaultDueDateInterval).amount
                : '',
            defaultAssigneeRoleId: get(
                _(caseDefaultRoleLinks)
                    .filter(
                        (caseDefaultRoleLink) =>
                            caseDefaultRoleLink.caseRoleAttrId ===
                            globalAttributes.caseRoleGlobal.assignee
                    )
                    .head(),
                'roleId'
            ),
        },
        defaultSupervisors: filter(
            caseDefaultRoleLinks,
            (caseDefaultRoleLink) =>
                caseDefaultRoleLink.caseRoleAttrId === globalAttributes.caseRoleGlobal.supervisor
        ),
        defaultPermissions: [
            ...filter(
                caseDefaultRoleLinks,
                (caseDefaultRoleLink) =>
                    caseDefaultRoleLink.caseRoleAttrId ===
                        globalAttributes.caseRoleGlobal.otherPersonnel &&
                    // external agencies are also assigned the "other personnel" attribute id
                    // but are shown in a different section. Because of this they need
                    // to be filtered out
                    !isExternalRole(caseDefaultRoleLink.roleId)
            ),
            ...defaultPermissions,
        ],
        originalDefaultPermissions: [
            ...filter(
                caseDefaultRoleLinks,
                (caseDefaultRoleLink) =>
                    caseDefaultRoleLink.caseRoleAttrId ===
                        globalAttributes.caseRoleGlobal.otherPersonnel &&
                    // external agencies are also assigned the "other personnel" attribute id
                    // but are shown in a different section. Because of this they need
                    // to be filtered out
                    !isExternalRole(caseDefaultRoleLink.roleId)
            ),
            ...defaultPermissions,
        ],
        defaultDepartmentPermissions,
        defaultTaskList: map(defaultTaskList, (task) => {
            return {
                ...task,
                assigneeType: task.assigneeRoleId
                    ? caseDefaultTaskAssigneeTypeEnum.ROLE
                    : caseDefaultTaskAssigneeTypeEnum.ASSIGNED_INVESTIGATOR,
                dueDateInterval: task.dueDateInterval
                    ? parsePeriod(task.dueDateInterval).amount
                    : '',
            };
        }),
        roleById,
        defaultAttachmentFolders: convertFromDefaultFolderView({
            defaultFolderViews: defaultFolders,
            entityTypeId: EntityTypeEnum.ATTACHMENT.name,
        }),
        defaultNotesFolders: convertFromDefaultFolderView({
            defaultFolderViews: defaultFolders,
            entityTypeId: EntityTypeEnum.CASE_NOTE.name,
        }),
    };
}

function convertFromFormModel({
    caseDefinition = {},
    defaultSupervisors = [],
    defaultPermissions = [],
    defaultDepartmentPermissions = [],
    defaultTaskList = [],
    defaultAttachmentFolders = [],
    defaultNotesFolders = [],
}) {
    const defaultDueDateInterval = caseDefinition.caseDuration
        ? `P${caseDefinition.caseDuration}D`
        : null;
    const defaultSupervisorsCaseDefaultRoleLinks = _(defaultSupervisors)
        .map((defaultSupervisor) => ({
            id: defaultSupervisor.id,
            caseDefinitionId: caseDefinition.id,
            caseRoleAttrId: globalAttributes.caseRoleGlobal.supervisor,
            operationType: OperationTypeEnum.MANAGE.name,
            roleId: defaultSupervisor.roleId,
        }))
        .filter(({ roleId, operationType }) => roleId && operationType)
        .value();

    const mapPermissions = (defaultPermission) => ({
        id: defaultPermission.id || null,
        caseDefinitionId: caseDefinition.id,
        caseRoleAttrId: globalAttributes.caseRoleGlobal.otherPersonnel,
        operationType: defaultPermission.operationType,
        roleId: defaultPermission.roleId,
    });
    const filterPermissions = ({ roleId, operationType }) => roleId && operationType;

    const defaultPermissionCaseDefaultRoleLinks = _(defaultPermissions)
        .map(mapPermissions)
        .filter(filterPermissions)
        .value();
    const defaultDepartmentPermissionCaseDefaultRoleLinks = _(defaultDepartmentPermissions)
        .map(mapPermissions)
        .filter(filterPermissions)
        .value();

    const defaultUserAssigned = caseDefinition.defaultAssigneeRoleId
        ? [
              {
                  caseDefinitionId: caseDefinition.id,
                  caseRoleAttrId: globalAttributes.caseRoleGlobal.assignee,
                  operationType: OperationTypeEnum.MANAGE.name,
                  roleId: caseDefinition.defaultAssigneeRoleId,
              },
          ]
        : [];

    return {
        caseDefinition: {
            ...omit(caseDefinition, 'caseDuration'),
            // this should always be days (or null)
            defaultDueDateInterval,
        },
        caseDefaultRoleLinks: [
            ...defaultUserAssigned,
            ...defaultSupervisorsCaseDefaultRoleLinks,
            ...defaultPermissionCaseDefaultRoleLinks,
            ...defaultDepartmentPermissionCaseDefaultRoleLinks,
        ],
        defaultTaskList: _(defaultTaskList)
            .map((task) => {
                const assigneeRoleId =
                    task.assigneeType === caseDefaultTaskAssigneeTypeEnum.ROLE
                        ? task.assigneeRoleId
                        : undefined;

                return {
                    ...omit(task, 'assigneeType'),
                    assigneeRoleId,
                    dueDateInterval: task.dueDateInterval ? `P${task.dueDateInterval}D` : null,
                };
            })
            .value(),
        defaultAttachmentFolders,
        defaultNotesFolders,
    };
}

/**
 * Module of the case definitions admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.CASE_DEFINITIONS_ADMIN,
    fieldViewModels: caseDefinitionsAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
    formValidators: {
        accessLevelIsRequired: ({ defaultPermissions, defaultDepartmentPermissions }) =>
            every(defaultPermissions, ({ operationType }) => operationType) &&
            every(defaultDepartmentPermissions, ({ operationType }) => operationType),
        userRoleIsRequired: ({ defaultPermissions, defaultDepartmentPermissions }) =>
            every(defaultPermissions, ({ roleId }) => roleId) &&
            every(defaultDepartmentPermissions, ({ roleId }) => roleId),
        atLeastOneRoleMustHaveCanManage: ({ defaultPermissions, roleById }) => {
            const roleTypes = [NORMAL.name, DEPARTMENT.name, SUPPORT.name];
            // at least one internal role must have MANAGE permissions
            return some(defaultPermissions, ({ roleId, operationType }) => {
                const role = roleById(roleId);
                const roleType = get(role, 'roleType');
                return (
                    includes(roleTypes, roleType) && operationType === OperationTypeEnum.MANAGE.name
                );
            });
        },
        caseDefaultTaskTitlesRequired: ({ defaultTaskList }) => every(defaultTaskList, 'title'),
    },
});

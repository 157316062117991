import React from 'react';
import { map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { CardSection } from '../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';

const strings = componentStrings.entityProfiles.LegacyEntityDetails;

/**
 * This component contains a CardSection title
 */
export default function LegacyEntityDetails({ legacyEntityDetails = [] }) {
    if (!legacyEntityDetails.length) {
        return null;
    }
    const detailRows = map(legacyEntityDetails, (detail, i) => {
        const { detailKey, detailValue } = detail;
        return (
            <SummaryRow key={i} label={detailKey}>
                {detailValue}
            </SummaryRow>
        );
    });
    return (
        <CardSection title={strings.title}>
            <SummaryList labelWidth={150} contentWidth={300}>
                {detailRows}
            </SummaryList>
        </CardSection>
    );
}

import { ElasticPhotoLineup } from '@mark43/rms-api';

/**
 * Get the image path (URL) of an ElasticPhotoLineup.
 * It's possible for a thumbnail to not have been generated, so we use the original image as a fallback.
 * If the URL of the original image does not exist for some reason (which is unlikely), return undefined.
 */
function getImagePathFromElasticPhotoLineup({
    mugshotPhoto,
}: ElasticPhotoLineup): string | undefined {
    return mugshotPhoto.largeThumbnailPath || mugshotPhoto.originalPath;
}

export interface DisplayedPhoto {
    id: number;
    path: string;
    masterPersonId: number;
}

/**
 * Given an array of elasticPhotoLineups,
 * 1. Filter out any objects without an image path, because those can't be shown in the UI,
 * 2. Filter out any objects corresponding to the person of interest, i.e. same masterPersonId, and
 * 3. Return an array of flat objects with fewer properties
 */
export default function parseElasticPhotoLineups(
    elasticPhotoLineups: ElasticPhotoLineup[],
    personOfInterestMasterPersonId: number
): DisplayedPhoto[] {
    return elasticPhotoLineups.reduce<DisplayedPhoto[]>((acc, elasticPhotoLineup) => {
        const path = getImagePathFromElasticPhotoLineup(elasticPhotoLineup);
        if (!path) {
            return acc;
        }
        if (elasticPhotoLineup.masterPersonId === personOfInterestMasterPersonId) {
            return acc;
        }
        return [
            ...acc,
            {
                id: elasticPhotoLineup.mugshotPhoto.id,
                path,
                masterPersonId: elasticPhotoLineup.masterPersonId,
            },
        ];
    }, []);
}

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { mediaQueries } from 'arc';
import { trafficCrashByReportIdSelector } from '~/client-common/core/domain/traffic-crashes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import testIds from '../../../../core/testIds';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import {
    currentReportSelector,
    reportStatusViewSelector,
    isQuickCrashReportSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { useIsE2ETestEnvironment } from '../../../core/context/E2ETestingContext';
import { responsiveStack } from '../../../core/styles/mixins';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import theme from '../../../core/styles/theme';
import { redirectToQuickCrashUrl } from '../utils';
import QuickCrashLogo from './QuickCrashLogo';
import QuickCrashName from './QuickCrashName';

const strings = componentStrings.quickCrashLinkCard;

const CardComponent = styled.div<{ centerContent?: boolean }>`
    ${responsiveStack()};
    gap: var(--arc-space-3);
    justify-content: ${(props) => (props.centerContent ? 'center' : 'space-between')};
    align-items: center;
    padding: var(--arc-space-4);
    margin-top: 24px;
    overflow: visible;
    background-image: linear-gradient(162deg, #443f51 0%, #191919 100%);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    background-clip: padding-box;

    @media (min-width: ${mediaQueries.md}) {
        padding: var(--arc-space-4) var(--arc-space-6);
    }
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const LinkButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--arc-fontSizes-md);
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.white};
    border-radius: 4px;
    padding: 6px;
    path {
        fill: ${(props) => props.theme.colors.white};
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    svg {
        height: 40px;
        margin-right: 16px;
    }
`;

const NameContainer = styled.div`
    svg {
        width: 150px;
    }
`;

const CreatedWithMessage = styled.div`
    color: ${(props) => props.theme.colors.white};
    font-size: var(--arc-fontSizes-sm);
    margin-bottom: 5px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const QuickCrashLinkCard = () => {
    const currentReport = useSelector(currentReportSelector);
    const currentReportId = currentReport?.id;
    const trafficCrashByReportId = useSelector(trafficCrashByReportIdSelector);
    const reportStatusView = useSelector(reportStatusViewSelector);
    const userHasViewTrafficCrashAbility = useSelector(currentUserHasAbilitySelector)(
        abilitiesEnum.REPORTING.VIEW_TRAFFIC_CRASH_REPORTS
    );
    const isQuickCrashReport = useSelector(isQuickCrashReportSelector);
    const canEditTrafficCrash = !!reportStatusView && reportStatusView.canEditTrafficCrash;

    const trafficCrash = !!currentReportId ? trafficCrashByReportId(currentReportId) : undefined;
    const quickCrashUrl = trafficCrash?.quickCrashUrl;
    const isE2EContext = useIsE2ETestEnvironment();
    const openInNewWindow = !isE2EContext;

    const redirectToQuickCrashReport = useCallback(() => {
        if (!!quickCrashUrl) {
            redirectToQuickCrashUrl({ quickCrashUrl, openInNewWindow });
        }
    }, [openInNewWindow, quickCrashUrl]);

    if (!isQuickCrashReport) {
        return null;
    }

    const buttonIcon = canEditTrafficCrash ? <Icon size={14} type={iconTypes.EDIT} /> : undefined;
    const buttonText = canEditTrafficCrash ? strings.editReport : strings.viewReport;
    const showEditButton = userHasViewTrafficCrashAbility && !!quickCrashUrl;

    return (
        <CardComponent centerContent={!showEditButton}>
            <MessageContainer>
                <LogoContainer>
                    <QuickCrashLogo />
                </LogoContainer>
                <TextContainer>
                    <CreatedWithMessage theme={theme}>
                        {strings.crashReportCreatedWith}
                    </CreatedWithMessage>
                    <NameContainer>
                        <QuickCrashName />
                    </NameContainer>
                </TextContainer>
            </MessageContainer>
            {showEditButton && (
                <LinkButton
                    onClick={redirectToQuickCrashReport}
                    className={buttonTypes.ICON_LINK}
                    iconLeft={buttonIcon}
                    theme={theme}
                    testId={testIds.QUICKCRASH_EDIT_REPORT}
                >
                    {buttonText}
                </LinkButton>
            )}
        </CardComponent>
    );
};

export default QuickCrashLinkCard;

import { IdentifyingMark, Image } from '@mark43/rms-api';
import { get, map, mapValues } from 'lodash';
import { createSelector } from 'reselect';
import { identifyingMarksByPersonProfileIdSelector, identifyingMarksSelector } from '../data';
import { imageViewModelsSelector } from '../../../images/state/ui';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { dateTimeFormats, formatISODate } from '../../../../dates/utils/dateHelpers';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import {
    ViewModel,
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';

type IdentifyingMarkViewModelProperties = {
    bodyPartAttrId: string;
    description: string;
    identifyingMarkTypeAttrId: string;
    image: Image;
};

export type IdentifyingMarkViewModel = ViewModel<
    IdentifyingMark,
    IdentifyingMarkViewModelProperties
>;

const identifyingMarkViewModelsSelector = createSelector(
    identifyingMarksSelector,
    imageViewModelsSelector,
    formatAttributeByIdSelector,
    (identifyingMarks, imageViewModels, formatAttributeById) => {
        const viewModel = buildViewModel<IdentifyingMark, IdentifyingMarkViewModelProperties>({
            recursive: false,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ imageId }) => ({ image: imageId ? imageViewModels[imageId] : undefined }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(identifyingMarks, (identifyingMark) => map(identifyingMark, viewModel));
    }
);

export const identifyingMarkViewModelsByPersonProfileIdSelector = createSelector(
    identifyingMarkViewModelsSelector,
    (identifyingMarkViewModels) => (personProfileId: number) =>
        identifyingMarkViewModels[personProfileId]
);

export const identifyingMarksDisplayAndImagesByNameIdSelector = createSelector(
    identifyingMarksByPersonProfileIdSelector,
    imageViewModelsSelector,
    formatAttributeByIdSelector,
    formatMiniUserByIdSelector,
    (identifyingMarksByNameId, images, formatAttributeById, formatMiniUserById) => (
        nameId: number
    ) => {
        const identifyingMarks = identifyingMarksByNameId(nameId);
        return map(identifyingMarks, (im: IdentifyingMark) => {
            const description = joinTruthyValues([
                im.description,
                formatAttributeById(im.bodyPartAttrId),
                formatAttributeById(im.identifyingMarkTypeAttrId),
            ]);

            const image = im.imageId ? images[im.imageId] : undefined;
            const imageGalleryModel = image
                ? {
                      url: get(image, 'originalFile.fileWebServerPath'),
                      thumbnail: get(image, 'thumbnailLargeFile.fileWebServerPath'),
                      description,
                      uploadedBy: formatMiniUserById(get(image, 'createdBy')),
                      uploadedDate: formatISODate(
                          get(image, 'createdDateUtc'),
                          dateTimeFormats.formDate
                      ),
                  }
                : null;

            return { description, image: imageGalleryModel };
        });
    }
);

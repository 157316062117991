import { map } from 'lodash';
import { createSelector } from 'reselect';

// helpers
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';

// selectors
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '../../../attributes/state/data';
import { courtCasesWhereSelector } from '../data';

const buildCourtCaseViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
    (formatAttributeById, formatAttributeWithOther) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ placeDetainedAtAttrId, placeDetainedAtOther }) => {
                    return {
                        placeDetainedAtDisplay: formatAttributeWithOther({
                            attributeId: placeDetainedAtAttrId,
                            other: placeDetainedAtOther,
                        }),
                    };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return viewModel;
    }
);

export const courtCaseViewModelsWhereSelector = createSelector(
    courtCasesWhereSelector,
    buildCourtCaseViewModelSelector,
    (courtCasesWhere, buildCourtCaseViewModel) => (predicate) => {
        return map(courtCasesWhere(predicate), buildCourtCaseViewModel);
    }
);

import { AgencyProfile } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'agencyProfiles';

const agencyProfilesModule = createNormalizedModule<AgencyProfile>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeAgencyProfiles = agencyProfilesModule.actionCreators.storeEntities;

// SELECTORS
export const agencyProfilesSelector = agencyProfilesModule.selectors.entitiesSelector;
export const agencyProfilesWhereSelector = agencyProfilesModule.selectors.entitiesWhereSelector;
export const agencyProfileByIdSelector = agencyProfilesModule.selectors.entityByIdSelector;

// REDUCER
export default agencyProfilesModule.reducerConfig;

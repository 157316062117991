// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Report Notification Resource',
        methods: {
            setReportNotifications(reportId, reportNotifications) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/notifications`,
                    data: reportNotifications,
                });
            },
        },
    });
}

/**
 * Resources for Report Notifications. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

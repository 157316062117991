import nameAttributesActionTypes from './types/nameAttributesActionTypes';

/**
 * Set the given name-attribute links in the data state (all previously stored
 *   links are removed from state). There is no `addNameAttributes` action
 *   because they don't have ids and so they are stored in an array, not a keyed
 *   object. As of 2016-05-05, this data state is used only to store medical
 *   statistics of involved names in the current report, and does not always
 *   include the report's other nameAttributes.
 * @param  {Object[]} nameAttributes
 * @return {Object}
 */
export function setNameAttributes(nameAttributes) {
    return {
        type: nameAttributesActionTypes.SET_NAME_ATTRIBUTES,
        payload: nameAttributes,
    };
}

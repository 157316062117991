import {
    EntityTypeEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    UsageSourceModuleEnum,
} from '@mark43/rms-api';

import { executeSavedSearchFromQueryParam } from '../../search/core/state/ui';
import { createUsageLog } from '../../admin/usage-logs/state/data';
import { tasksDashboardSearch, resetFilterForm, searchInitialTasks } from './state/ui';

export function onTasksEnter(nextState, replace) {
    const dispatch = this.dispatch;

    if (!tasksDashboardSearch.selectors.successfulSearchHasBeenPerformedSelector(this.getState())) {
        dispatch(resetFilterForm());
    }

    dispatch(
        createUsageLog({
            primaryEntityType: EntityTypeEnum.TASK.name,
            action: UsageActionEnum.VIEWED_TASKS_DASHBOARD.name,
            completion: UsageCompletionEnum.SUCCEEDED.name,
            sourceModule: UsageSourceModuleEnum.TASKS.name,
        })
    );

    dispatch(
        executeSavedSearchFromQueryParam({
            searchModule: tasksDashboardSearch,
            nextState,
            replace,
        })
    ).then((executed) => {
        if (!executed) {
            this.dispatch(searchInitialTasks());
        }
    });
}

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
    Card,
    CardProps,
    CardBody,
    CardHeader,
    CardHeaderTitle,
    Flex,
    SimpleGridProps,
    SimpleGrid,
    Center,
} from 'arc';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import Icon, { iconTypes, iconSizes } from '../../core/components/Icon';
import HelpText from '../../../legacy-redux/components/core/HelpText';
import { Button, ButtonProps } from '../../core/components/Button';
import NoDataBlockBase, { NoDataBlockProps } from '../../core/components/NoDataBlock';

/** Used for empty states on dashboard cards. */
export function NoDataBlock(props: NoDataBlockProps) {
    return (
        <Center minHeight="6rem">
            <NoDataBlockBase {...props} />
        </Center>
    );
}

const StyledButton = styled(Button)<{ $isSticky?: boolean }>`
    ${(props) =>
        props.$isSticky &&
        `
        margin-top: var(--arc-space-2);
        position: sticky;
        left: 0;
        `}
`;
/** Used for user actions to load more content inside dashboard cards */
export const ShowMoreButton = ({ isSticky, ...props }: ButtonProps & { isSticky?: boolean }) => (
    <StyledButton isTextTransformNone isFullWidth $isSticky={isSticky} variant="ghost" {...props} />
);

const StyledCard = styled(Card)<{ $minHeight: number; $isDragging: boolean }>`
    transition-property: var(--arc-transition-property-common);
    transition-duration: var(--arc-transition-duration-fast);
    transition-timing-function: var(--arc-transition-easing-ease-in-out);
    ${(props) => props.$minHeight && `min-height: ${props.$minHeight}px`};
    ${(props) => props.$isDragging && `box-shadow: var(--arc-shadows-dark-lg);`};
    overflow: hidden;
`;

const StyledCardHeader = styled(CardHeader)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledCardBody = styled(CardBody)<{
    $minHeight: React.CSSProperties['minHeight'];
    $maxHeight: React.CSSProperties['maxHeight'];
}>`
    overflow: auto;
    max-height: ${(props) => props.$maxHeight ?? '22rem'};

    ${(props) =>
        props.$minHeight &&
        `
        min-height: ${props.$minHeight};
        `};
    ${(props) =>
        props.$padding &&
        `
        padding: ${props.$padding};
        `};
`;

type DraggableCardProps = {
    dragHandleProps: DraggableProvidedDragHandleProps;
    isDragging: boolean;
};

type DragHandleProps = DraggableCardProps['dragHandleProps'] & {
    $isDragging: boolean;
};

const DragHandle = styled((props: DragHandleProps) => {
    return (
        <div {...props}>
            <Icon type={iconTypes.DRAG_HANDLE} size={iconSizes.SMALL} />
        </div>
    );
})`
    svg {
        fill: var(--arc-colors-icon-default);
    }

    &:hover {
        svg {
            fill: var(--arc-colors-text-secondary);
        }
    }

    ${(props) =>
        props.$isDragging &&
        `svg {
        fill: var(--arc-colors-selected-default)
    }`}
`;

export const DashboardCardContentGrid = ({ children, ...props }: SimpleGridProps) => {
    return (
        <SimpleGrid gridGap="var(--arc-space-2)" columns={{ base: 1, md: 2 }} {...props}>
            {children}
        </SimpleGrid>
    );
};

export type DashboardCardProps = Omit<CardProps, 'title'> &
    DraggableCardProps & {
        testId?: string;
        actions?: React.ReactNode;
        title: React.ReactNode | string;
        helpText?: string;
        minHeight?: React.CSSProperties['minHeight'];
        maxHeight?: React.CSSProperties['maxHeight'];
        padding?: React.CSSProperties['padding'];
    };

/** Draggable and scrollable card used for the primary dashboard sections. */
export const DashboardCard = forwardRef<HTMLDivElement, DashboardCardProps>(
    (
        {
            dragHandleProps,
            children,
            actions,
            title,
            helpText,
            minHeight,
            maxHeight,
            padding,
            isDragging,
            testId,
            ...props
        },
        forwardedRef
    ) => {
        return (
            <StyledCard
                data-test-id={testId}
                $isDragging={isDragging}
                ref={forwardedRef}
                {...props}
            >
                <StyledCardHeader>
                    <Flex gap="var(--arc-space-1)" align="center" flexGrow={1} {...dragHandleProps}>
                        <DragHandle {...dragHandleProps} $isDragging={isDragging} />
                        {typeof title === 'string' ? (
                            <CardHeaderTitle>{title}</CardHeaderTitle>
                        ) : (
                            title
                        )}
                        {helpText && <HelpText content={helpText} />}
                    </Flex>
                    {actions}
                </StyledCardHeader>
                <StyledCardBody $minHeight={minHeight} $maxHeight={maxHeight} $padding={padding}>
                    {children}
                </StyledCardBody>
            </StyledCard>
        );
    }
);

/** A more subtle dashboard card used for the side bar sections */
export const DashboardSideBarCard = styled(DashboardCard)`
    background-color: var(--arc-colors-surface-canvas);
    border: solid 1px var(--arc-colors-border-default);
    box-shadow: none;

    ${(props) =>
        props.isDragging &&
        `
        border: solid 1px var(--arc-colors-border-default);
        box-shadow: var(--arc-shadows-lg);

    `};
`;

import { ElasticSearchTypeEnum, EntityTypeEnum, ProductModuleEnum } from '@mark43/rms-api';
import React from 'react';
import { compact, filter } from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import pluralize from 'pluralize';

import { onlyUpdateForKeys } from 'recompose';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_CAD_LABEL,
    DISPLAY_ONLY_ORGANIZATION_LABEL,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import testIds from '../../../../core/testIds';
import { abilitiesEnum } from '../../../core/abilities';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Link from '../../../core/components/links/Link';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import {
    alwaysIncludeLinks,
    elasticSearchTypeEnumToAdvancedSearchPath,
    quickSearchAdvancedSearchLinks,
} from '../config';
import zIndexes from '../../../core/styles/zIndexes';

const { quickSearch: quickSearchStrings } = componentStrings;

const SearchIcon = styled(Icon)`
    display: inline-block;
    vertical-align: top;
`;

const searchButtonStyles = {
    margin: 0,
    // Added to keep it from wrapping in current design.
    // This design will eventually be replaced when the nav is replaced with the new main nav.
    fontSize: '13px',
};

const AdvancedSearchTypesMenuWrapper = styled.div`
    position: relative;
    float: left;
`;

const MenuLink = styled(Link)`
    display: block;
    color: ${(props) => props.theme.colors.darkGrey};
    font-weight: 600;
    font-size: var(--arc-fontSizes-md);
    padding: 5px 10px;
    text-transform: uppercase;

    &:hover {
        background: var(--arc-colors-interactive-hover);
    }
`;

const headerStrings = quickSearchStrings.core.elasticSearchTypeHeader;

const MenuWrapper = styled.div`
    background: ${(props) => props.theme.colors.white};
    border-radius: 3px;
    width: 100%;
    position: absolute;
    top: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    z-index: ${zIndexes.menu};
`;

const AdvancedSearchTypesMenu = ({
    onMenuItemClick,
    expanded,
    onButtonClick,
    resultTypes,
    style,
}) => {
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const isProductModuleActive = useSelector(isProductModuleActiveSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const shouldShowCourtOrderSearchType = (searchType) => {
        return (
            searchType === ElasticSearchTypeEnum.COURT_ORDERS.name &&
            currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_COURT_ORDERS)
        );
    };

    const shouldShowBookings = (searchType) => {
        return (
            searchType === EntityTypeEnum.BOOKING.name &&
            isProductModuleActive(ProductModuleEnum.JMS.name) &&
            applicationSettings.RMS_JMS_BOOKINGS_ENABLED === true
        );
    };

    const cadDisplayName = useFields(DISPLAY_ONLY_CAD_LABEL)[DISPLAY_ONLY_CAD_LABEL];
    const organizationFieldName = pluralize(
        useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[DISPLAY_ONLY_ORGANIZATION_LABEL]
    );

    const getLabel = (type) => {
        const label = headerStrings[type];
        if (type === ElasticSearchTypeEnum.CAD_TICKET.name) {
            return label(cadDisplayName);
        }
        if (type === ElasticSearchTypeEnum.ORGANIZATION.name) {
            return label(organizationFieldName);
        }
        return label;
    };

    return (
        <AdvancedSearchTypesMenuWrapper style={style}>
            <Button
                className={buttonTypes.PRIMARY}
                iconRight={<SearchIcon type={iconTypes.TRIANGLEDOWN} fontSize="25px" width={14} />}
                style={searchButtonStyles}
                onClick={onButtonClick}
                testId={testIds.QUICK_SEARCH_ADVANCED_SEARCH_TYPES_BUTTON}
            >
                {quickSearchStrings.generic.advancedSearch}
            </Button>
            {expanded && (
                <MenuWrapper data-test-id={testIds.QUICK_SEARCH_ADVANCED_SEARCH_TYPES_MENU_WRAPPER}>
                    {compact(
                        filter(
                            quickSearchAdvancedSearchLinks,
                            (link) =>
                                resultTypes.includes(link) ||
                                alwaysIncludeLinks.includes(link) ||
                                shouldShowCourtOrderSearchType(link) ||
                                shouldShowBookings(link)
                        ).map((type) => {
                            const label = getLabel(type);
                            if (!label) {
                                return;
                            }
                            const segment = elasticSearchTypeEnumToAdvancedSearchPath[type];
                            return (
                                <MenuLink
                                    key={type}
                                    to={`/search/${segment}`}
                                    onClick={onMenuItemClick}
                                    testId={testIds.QUICK_SEARCH_ADVANCED_SEARCH_TYPES_OPTION}
                                    testType={type}
                                >
                                    {label}
                                </MenuLink>
                            );
                        })
                    )}
                </MenuWrapper>
            )}
        </AdvancedSearchTypesMenuWrapper>
    );
};

export default onlyUpdateForKeys(['expanded'])(AdvancedSearchTypesMenu);

import { DepartmentProfile } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'departmentProfiles';

const departmentProfilesModule = createNormalizedModule<DepartmentProfile>({
    type: NEXUS_STATE_PROP,
    key: 'departmentId',
});

// SELECTORS
export const departmentProfilesSelector = departmentProfilesModule.selectors.entitiesSelector;
export const departmentProfileByIdSelector = departmentProfilesModule.selectors.entityByIdSelector;
export const departmentProfilesWhereSelector =
    departmentProfilesModule.selectors.entitiesWhereSelector;

// REDUCER
export default departmentProfilesModule.reducerConfig;

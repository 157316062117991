import { Rule } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'rules';

const rulesModule = createNormalizedModule<Rule>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const rulesSelector = rulesModule.selectors.entitiesSelector;
export const ruleByIdSelector = rulesModule.selectors.entityByIdSelector;

// REDUCER
export default rulesModule.reducerConfig;

import { createSelector } from 'reselect';
import { EntityTypeEnumType, SecurityClassification } from '@mark43/rms-api';

import createNormalizedModule from '~/client-common/core/utils/createNormalizedModule';
import { ClientCommonAction } from '~/client-common/redux/types';
import { SecurityClassificationOwner } from '../../../../../../modules/security-classification/types';

export const NEXUS_STATE_PROP = 'securityClassifications';

const securityClassificationsModule = createNormalizedModule<SecurityClassification>({
    type: NEXUS_STATE_PROP,
});

export const securityClassificationsSelector =
    securityClassificationsModule.selectors.entitiesSelector;
const securityClassificationsWhereSelector =
    securityClassificationsModule.selectors.entitiesWhereSelector;

export const securityClassificationsByOwnerSelector = createSelector(
    securityClassificationsWhereSelector,
    (securityClassificationsWhere) => (ownerType: EntityTypeEnumType, ownerId: number) =>
        securityClassificationsWhere({ ownerType, ownerId })
);

export const withSecurityClassification = (
    ownerProps: SecurityClassificationOwner,
    securityClassification: SecurityClassification
): ClientCommonAction<void> => (dispatch, getState, { nexus }) => {
    dispatch(
        nexus.withRemove(
            NEXUS_STATE_PROP,
            ownerProps,
            nexus.withEntityItems(
                {
                    [NEXUS_STATE_PROP]: [securityClassification],
                },
                { type: `${NEXUS_STATE_PROP}/REPLACE_SECURITY_CLASSIFICATION` }
            )
        )
    );
};

export default securityClassificationsModule.reducerConfig;

import { MentionsconfigResource, NarrativeMentionConfiguration } from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/rms/api';

export default createResource({
    name: 'Narrative Mentions Configuration Admin Resource',
    methods: {
        /**
         * Get a list of all mentions configurations for the user's department
         */
        getMentionsConfigurationSettings() {
            return req<MentionsconfigResource.GetMentionsConfigurationSettings>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: 'mentions/config',
            });
        },
        /**
         * Update status of one mention type
         */
        setMentionsConfigurationSetting(mentionConfiguration: NarrativeMentionConfiguration) {
            return req<MentionsconfigResource.SetMentionsConfigurationSetting>({
                method: 'PUT',
                baseUrl: BASE_URL,
                url: 'mentions/config',
                data: mentionConfiguration,
            });
        },
    },
});

import { RefContextEnum } from '@mark43/rms-api';
import { get, map, pick } from 'lodash';

import { createFormConfiguration, createNItems, createFieldset, _Form } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';

import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { courtCasesByReportIdSelector } from '~/client-common/core/domain/court-cases/state/data';
import sortCourtCases from '~/client-common/core/domain/court-cases/utils/sortCourtCases';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import {
    getArrestForm,
    ARREST_FORM_REPORT_ID_PATH,
    ARREST_FORM_ARREST_DATE_UTC_PATH,
} from './arrestForm';

export const formName = RefContextEnum.FORM_COURT_CASE.name;
export const getCourtCaseForm = (index) => formsRegistry.get(formName, index);

export const createCourtCaseForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            courtCases: createNItems({
                fields: {
                    id: {},
                    courtDateUtc: {
                        fieldName: fields.COURT_CASE_COURT_DATE_UTC,
                    },
                    courtTypeAttrId: {
                        fieldName: fields.COURT_CASE_COURT_TYPE_ATTR_ID,
                    },
                    courtName: {
                        fieldName: fields.COURT_CASE_COURT_NAME,
                    },
                    judgeName: {
                        fieldName: fields.COURT_CASE_JUDGE_NAME,
                    },
                    bailAmount: {
                        fieldName: fields.COURT_CASE_BAIL_AMOUNT,
                    },
                    placeDetainedAtAttrId: {
                        fieldName: fields.COURT_CASE_PLACE_DETAINED_AT_ATTR_ID,
                    },
                    placeDetainedAtOther: {
                        fieldName: fields.COURT_CASE_PLACE_DETAINED_AT_OTHER,
                    },
                    courtRoomNumber: {
                        fieldName: fields.COURT_CASE_COURT_ROOM_NUMBER,
                    },
                },
            }),
            arrest: createFieldset({
                fields: {
                    id: {},
                    // The following fields don't show up in the booking card UI.
                    // These fields are needed for hide/show and validation.
                    arrestDateUtc: {
                        fieldName: fields.ARREST_ARREST_DATE_UTC,
                    },
                },
            }),
        }),
    });
};

export const buildCourtCaseCardFormModel = ({ reportId, formIndex }) => (dispatch, getState) => {
    const state = getState();

    const courtCases = courtCasesByReportIdSelector(state)(reportId);
    const convertedCourtCases = map(sortCourtCases({ courtCases }), (courtCase) => {
        return pick(courtCase, [
            'id',
            'courtDateUtc',
            'courtTypeAttrId',
            'courtName',
            'judgeName',
            'bailAmount',
            'placeDetainedAtAttrId',
            'placeDetainedAtOther',
            'courtRoomNumber',
        ]);
    });

    // Court Case card can be added to any report type.
    const arrest = arrestForReportIdSelector(state)(reportId) || {};
    const arrestForm = getArrestForm(formIndex);
    const arrestFormModel = arrestForm ? arrestForm.get() : undefined;
    const { id: arrestId, arrestDateUtc: arrestDataStateArrestDateUtc } = arrest;
    // Paths are `.` paths, so must use `get` calls here.
    const arrestFormStateReportId = get(arrestFormModel, ARREST_FORM_REPORT_ID_PATH);
    const arrestFormStateArrestDateUtc = get(arrestFormModel, ARREST_FORM_ARREST_DATE_UTC_PATH);
    let arrestDateUtcForCourtCaseForm;

    // Only populate nested `arrest` data in there is an `arrest` for our current `reportId`.
    if (!isUndefinedOrNull(arrestId)) {
        // If the arrest form state is currently populated with an arrest for our current
        // `reportId`, then use the form model as the source of truth.  Otherwise, use
        // data state as the source of truth.
        arrestDateUtcForCourtCaseForm =
            arrestFormStateReportId === reportId
                ? arrestFormStateArrestDateUtc
                : arrestDataStateArrestDateUtc;
    }

    return {
        courtCases: convertedCourtCases,
        arrest: {
            id: arrestId,
            arrestDateUtc: arrestDateUtcForCourtCaseForm,
        },
    };
};

export const buildCourtCaseCardBundle = ({ reportId, form }) => {
    const {
        model: { courtCases },
    } = form.getState();
    const courtCasesForBundle = map(courtCases, (courtCase) => {
        const {
            id,
            courtDateUtc,
            courtTypeAttrId,
            courtName,
            judgeName,
            bailAmount,
            placeDetainedAtAttrId,
            placeDetainedAtOther,
            courtRoomNumber,
        } = courtCase;

        return {
            id,
            reportId,
            courtDateUtc,
            courtTypeAttrId,
            courtName,
            judgeName,
            bailAmount,
            placeDetainedAtAttrId,
            placeDetainedAtOther,
            courtRoomNumber,
        };
    });

    return {
        courtCases: courtCasesForBundle,
    };
};

export const refreshCourtCaseForm = ({ reportId, index }) => (dispatch) => {
    const form = getCourtCaseForm(index);
    if (!isUndefinedOrNull(form)) {
        const formModel = dispatch(buildCourtCaseCardFormModel({ reportId }));
        form.set('', formModel);
    }
};

export const refreshCourtCaseCardDependentArrestDateUtcField = ({ arrestDateUtc, formIndex }) => {
    const form = getCourtCaseForm(formIndex);
    if (!isUndefinedOrNull(form)) {
        form.set('arrest.arrestDateUtc', arrestDateUtc || undefined);
    }
};

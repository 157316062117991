import { UnitStateTransition } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const cadUnitStatusTransitionsModule = createNormalizedModule<UnitStateTransition>({
    type: 'cadUnitStatusTransitions',
});

export default cadUnitStatusTransitionsModule.reducerConfig;

export const storeCadUnitStatusTransitions =
    cadUnitStatusTransitionsModule.actionCreators.storeEntities;

export const cadUnitStatusTransitionsSelector =
    cadUnitStatusTransitionsModule.selectors.entitiesSelector;

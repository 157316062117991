import { createSelector } from 'reselect';
import { DuplicateEventSearchConfig } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getDuplicateEventSearchConfigResource from '../../resources/duplicateEventSearchConfigResource';
import { ClientCommonAction } from '../../../../../redux/types';

const duplicateEventSearchConfigsModule = createNormalizedModule<DuplicateEventSearchConfig>({
    type: 'duplicateEventSearchConfigs',
});

export default duplicateEventSearchConfigsModule.reducerConfig;

const storeDuplicateEventSearchConfigs =
    duplicateEventSearchConfigsModule.actionCreators.storeEntities;

const deleteDuplicateEventSearchConfigs = () =>
    duplicateEventSearchConfigsModule.actionCreators.deleteEntitiesWhere(() => true);

export const duplicateEventSearchConfigSelector = createSelector(
    duplicateEventSearchConfigsModule.selectors.entitiesSelector,
    (configs) => Object.values(configs)[0] || {}
);

const LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_START =
    'dupe-events/LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_START';
export const LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS =
    'dupe-events/LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS';
export const LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE =
    'dupe-events/LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE';
export const UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_START =
    'dupe-events/UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_START';
export const UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS =
    'dupe-events/UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS';
export const UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE =
    'dupe-events/UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE';

function loadDuplicateEventSearchConfigStart() {
    return {
        type: LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_START,
    };
}

function loadDuplicateEventSearchConfigSuccess(
    duplicateEventSearchConfig: DuplicateEventSearchConfig
) {
    return {
        type: LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS,
        payload: duplicateEventSearchConfig,
    };
}

function loadDuplicateEventSearchConfigFailure(err: Error) {
    return {
        type: LOAD_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE,
        payload: err,
    };
}

function upsertDuplicateEventSearchConfigStart() {
    return {
        type: UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_START,
    };
}

function upsertDuplicateEventSearchConfigSuccess() {
    return {
        type: UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_SUCCESS,
    };
}

export function upsertDuplicateEventSearchConfigFailure(err: Error) {
    return {
        type: UPSERT_DUPLICATE_EVENT_SEARCH_CONFIG_FAILURE,
        payload: err,
    };
}

export function loadDuplicateEventSearchConfig(): ClientCommonAction<
    Promise<DuplicateEventSearchConfig>
> {
    return function (dispatch) {
        dispatch(loadDuplicateEventSearchConfigStart());
        return getDuplicateEventSearchConfigResource()
            .getDuplicateEventSearchConfig()
            .then((duplicateEventSearchConfig: DuplicateEventSearchConfig) => {
                dispatch(deleteDuplicateEventSearchConfigs());
                dispatch(storeDuplicateEventSearchConfigs([duplicateEventSearchConfig]));
                dispatch(loadDuplicateEventSearchConfigSuccess(duplicateEventSearchConfig));
                return duplicateEventSearchConfig;
            })
            .catch((err: Error) => {
                dispatch(loadDuplicateEventSearchConfigFailure(err));
            });
    };
}

export function upsertDuplicateEventSearchConfig(
    duplicateEventSearchConfig: DuplicateEventSearchConfig
): ClientCommonAction<Promise<DuplicateEventSearchConfig>> {
    return function (dispatch) {
        dispatch(upsertDuplicateEventSearchConfigStart());
        return getDuplicateEventSearchConfigResource()
            .upsertDuplicateEventSearchConfig(duplicateEventSearchConfig)
            .then((result: DuplicateEventSearchConfig) => {
                dispatch(deleteDuplicateEventSearchConfigs());
                dispatch(storeDuplicateEventSearchConfigs([result]));
                dispatch(upsertDuplicateEventSearchConfigSuccess());
                return result;
            })
            .catch((err: Error) => {
                dispatch(upsertDuplicateEventSearchConfigFailure(err));
            });
    };
}

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

const BASE_URL = '/evidence/api';
const BASE_PATH = 'bootstrap';

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Bootstrap Resource',
        methods: {
            /**
             * Load all data needed by the evidence app to initialize state. This is
             *   meant to be called on page load and only when the evidence feature
             *   flag is enabled.
             */
            bootstrap() {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: BASE_PATH,
                });
            },
        },
    });
}
const resourceGetter = () => resource;
export default resourceGetter;

// this file can be merged into index file when it's converted into typescript
import { findKey } from 'lodash';
import sortKeyEnum, { SortKey } from '~/client-common/core/enums/universal/sortKeyEnum';

type ActiveColumnKeys = {
    date: 'eventStartUtc' | 'updatedDateUtc' | 'reportCreationDateUtc';
    entity: 'reportOwner' | 'reviewers' | 'authors' | 'arrestingOfficer' | 'persons';
    location:
        | 'locations'
        | 'offenseLocation'
        | 'arrestLocation'
        | 'eventLocation'
        | 'agency'
        | 'units';
    ren: 'reportingEventNumber';
};

type SortableGroupKey = 'date' | 'ren';

export const sortableColumnKeys: Record<
    ActiveColumnKeys[SortableGroupKey],
    { groupKey: SortableGroupKey; sortKey: SortKey }
> = {
    eventStartUtc: {
        groupKey: 'date',
        sortKey: sortKeyEnum.REPORT_EVENT_START_UTC,
    },
    updatedDateUtc: {
        groupKey: 'date',
        sortKey: sortKeyEnum.REPORT_UPDATED_DATE_UTC,
    },
    reportCreationDateUtc: {
        groupKey: 'date',
        sortKey: sortKeyEnum.REPORT_REPORT_CREATION_DATE_UTC,
    },
    reportingEventNumber: {
        groupKey: 'ren',
        sortKey: sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER,
    },
};

export function updateActiveColumnKeysBySortKey(
    activeColumnKeys: ActiveColumnKeys,
    sortKey: SortKey
) {
    const activeColumnKey = findKey(sortableColumnKeys, { sortKey });
    if (activeColumnKey) {
        return {
            ...activeColumnKeys,
            [sortableColumnKeys[activeColumnKey as keyof typeof sortableColumnKeys]
                .groupKey]: activeColumnKey,
        };
    }

    return activeColumnKeys;
}

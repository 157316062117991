import { OperationTypeEnum } from '@mark43/rms-api';
import { mapValues } from 'lodash';

import { createSelector } from 'reselect';

import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { caseTitlesSelector } from '../data';
import { allRoleFormatsByRoleIdSelector } from '../../../roles/state/data';

import { formatCaseDefinitionByIdSelector } from '../../../case-definitions/state/data';

export const caseTitleViewModelsSelector = createSelector(
    caseTitlesSelector,
    formatAttributeByIdSelector,
    formatCaseDefinitionByIdSelector,
    allRoleFormatsByRoleIdSelector,
    (caseTitles, formatAttributeById, formatCaseDefinitionById, allRoleFormatsByRoleId) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ caseDefinitionId }) => ({
                    caseDefinitionId: formatCaseDefinitionById(caseDefinitionId),
                }),
                ({ maxOpType }) => ({
                    canRead: maxOpType !== OperationTypeEnum.SEARCH.name,
                }),
                ({ assigneeRoleId }) => ({
                    assigneeNameFormats: assigneeRoleId && allRoleFormatsByRoleId(assigneeRoleId),
                }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(caseTitles, viewModel);
    }
);

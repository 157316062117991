import { configure } from 'react-hotkeys';
import { reduce, split, flatMap, values, isArray } from 'lodash';
import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';

const formatHotkey = (hotkey) => {
    return split(hotkey, '+');
};

const keysToListenTo = reduce(
    flatMap(values(hotkeysActionEnum), ({ keyBinding }) => {
        if (isArray(keyBinding)) {
            return flatMap(keyBinding, formatHotkey);
        } else {
            return formatHotkey(keyBinding);
        }
    }),
    (acc, hotkey) => {
        acc[hotkey] = true;
        return acc;
    },
    {}
);

/**
 * Function to handle setting configuration options for the react-hotkeys library.
 * See https://www.npmjs.com/package/react-hotkeys#configuration for options
 */
export function initReactHotKeys() {
    configure({
        /**
         * Override the default here
         * Previously, this function ignored all `input`, `select`, `textarea`
         * and `contenteditable` elements
         *
         * Because we actually want all those elements to work with hotkeys,
         * there's no point ignoring them.
         *
         * Additionally, we only want to respond to keys that
         * we know are associated with hotkeys.
         *
         * Return false if we want the event to be propagated to hotkeys
         *
         */
        ignoreEventsCondition: (event) => {
            const { key } = event;
            // If its a keyboard event, only listen
            // to some keys
            if (key && keysToListenTo[key]) {
                return false;
            }
            return true;
        },
    });
}

export const findClosestHotKeysWrapper = (target) =>
    target && target.closest('.mark43-hotkeys-wrapper');

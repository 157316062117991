import { Inventory, InventoryDashboardView, SearchQuery } from '@mark43/evidence-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { storeStorageLocations } from '../../../storage-locations/state/data';
import getInventoriesResource from '../../resources/inventoriesResource';
import { ClientCommonAction } from '../../../../../redux/types';

const inventoriesModule = createNormalizedModule<Inventory>({
    type: 'inventories',
});

// ACTION TYPES
const SEARCH_INVENTORIES_START = 'inventories/SEARCH_INVENTORIES_START';
const SEARCH_INVENTORIES_SUCCESS = 'inventories/SEARCH_INVENTORIES_SUCCESS';
const SEARCH_INVENTORIES_FAILURE = 'inventories/SEARCH_INVENTORIES_FAILURE';

const searchInventoriesStart = () => ({
    type: SEARCH_INVENTORIES_START,
});

const searchInventoriesSuccess = () => ({
    type: SEARCH_INVENTORIES_SUCCESS,
});

const searchInventoriesFailure = (errorMessage: string) => ({
    type: SEARCH_INVENTORIES_FAILURE,
    payload: errorMessage,
});

// ACTIONS
const storeInventories = inventoriesModule.actionCreators.storeEntities;

/**
 * Search inventories. If no elastic query is provided, get all inventories up
 *   to a default limit.
 */
export const searchInventories = (
    elasticQuery: SearchQuery
): ClientCommonAction<Promise<InventoryDashboardView>> => (dispatch) => {
    const inventoriesResource = getInventoriesResource();

    dispatch(searchInventoriesStart());
    return inventoriesResource
        .searchInventories(elasticQuery)
        .then((response: InventoryDashboardView) => {
            dispatch(searchInventoriesSuccess());
            dispatch(storeInventories(response.inventories));
            dispatch(storeStorageLocations(response.storageLocations));
            return response;
        })
        .catch((err: Error) => {
            dispatch(searchInventoriesFailure(err.message));
            throw err;
        });
};

// SELECTORS
export const inventoriesSelector = inventoriesModule.selectors.entitiesSelector;

// REDUCER
export default inventoriesModule.reducerConfig;

import { FormEnum } from '@mark43/rms-api';
import { LOCATION_ALIAS_ALIAS } from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../core/forms';
import { buildFlatFormFieldViewModels } from '../../legacy-redux/helpers/formHelpers';

/**
 * Module of the location aliases admin form.
 * @type {Object}
 */
const locationAliasesAdminForm = createFormModule({
    formName: FormEnum.LOCATION_ALIAS_ADMIN.name,
    fieldViewModels: buildFlatFormFieldViewModels([
        'id',
        'locationId',
        {
            key: 'alias',
            fieldName: LOCATION_ALIAS_ALIAS,
        },
    ]),
});

export default locationAliasesAdminForm;

import React from 'react';
import { map } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';

const strings = componentStrings.entityProfiles.person.ContactDetails.labels;

/**
 * Converts phone numbers to an array of `SummaryRow`s.
 *
 * NOTE This is a helper and not a component as React currently does not
 * allow returning of fragments
 *
 * FIXME when React 16 is released
 *
 * @param  {array} phoneNumbers An array of phone numbers
 * @return {array}              An array of `SummaryRow`s
 */
export const convertPhoneNumbersToComponentArray = (phoneNumbers, isPoleEnabled = false) =>
    map(phoneNumbers, (phone) => (
        <SummaryRow label={phone.phoneType}>
            {phone.displayNumber}
            <SummaryList key={phone.id}>
                <SummaryRow label={strings.dateEffectiveFrom} hidden={!isPoleEnabled}>
                    {phone.dateEffectiveFrom}
                </SummaryRow>
                <SummaryRow label={strings.dateEffectiveTo} hidden={!isPoleEnabled}>
                    {phone.dateEffectiveTo}
                </SummaryRow>
            </SummaryList>
        </SummaryRow>
    ));

/**
 * Converts phone numbers to an array of `SummaryRow`s.
 *
 * NOTE This is a helper and not a component as React currently does not
 * allow returning of fragments
 *
 * FIXME when React 16 is released
 *
 * @param  {array} phoneNumbers An array of phone numbers
 * @return {array}              An array of `SummaryRow`s
 */
export const convertPhoneNumbersToPersonComponentArray = (phoneNumbers, isPoleEnabled = false) =>
    map(phoneNumbers, (phone) => (
        <SummaryRow key={phone.id} label={phone.phoneType}>
            <div>
                {`${phone.displayNumber} ${
                    phone.isSafeContact || phone.isPrimary
                        ? `(${joinTruthyValues([
                              phone.isPrimary && strings.primary,
                              phone.isSafeContact && strings.safe,
                          ])})`
                        : ''
                }`}
            </div>
            <SummaryList key={phone.id}>
                <SummaryRow label={strings.dateEffectiveFrom} hidden={!isPoleEnabled}>
                    {phone.dateEffectiveFrom}
                </SummaryRow>
                <SummaryRow label={strings.dateEffectiveTo} hidden={!isPoleEnabled}>
                    {phone.dateEffectiveTo}
                </SummaryRow>
                <SummaryRow label={strings.description}>{phone.provenance}</SummaryRow>
                <SummaryRow label={strings.details}>{phone.safeContactDetails}</SummaryRow>
            </SummaryList>
        </SummaryRow>
    ));

/**
 * Converts emails to an array of `SummaryRow`s.
 *
 * NOTE This is a helper and not a component as React currently does not
 * allow returning of fragments
 *
 * FIXME when React 16 is released
 *
 * @param  {array} emails An array of emails
 * @return {array}        An array of `SummaryRow`s
 */
export const convertEmailAddressesToComponentArray = (emails, isPoleEnabled = false) =>
    map(emails, (email) => (
        <SummaryRow key={email.id} label={email.emailType}>
            {email.emailAddress}
            <SummaryList key={email.id}>
                <SummaryRow label={strings.dateEffectiveFrom} hidden={!isPoleEnabled}>
                    {email.dateEffectiveFrom}
                </SummaryRow>
                <SummaryRow label={strings.dateEffectiveTo} hidden={!isPoleEnabled}>
                    {email.dateEffectiveTo}
                </SummaryRow>
            </SummaryList>
        </SummaryRow>
    ));

/**
 * Converts emails to an array of `SummaryRow`s.
 *
 * NOTE This is a helper and not a component as React currently does not
 * allow returning of fragments
 *
 * FIXME when React 16 is released
 *
 * @param  {array} emails An array of emails
 * @return {array}        An array of `SummaryRow`s
 */
export const convertEmailAddressesToPersonComponentArray = (emails, isPoleEnabled = false) =>
    map(emails, (email) => (
        <SummaryRow key={email.id} label={email.emailType}>
            <div>
                {`${email.emailAddress} ${
                    email.isSafeContact || email.isPrimary
                        ? `(${joinTruthyValues([
                              email.isPrimary && strings.primary,
                              email.isSafeContact && strings.safe,
                          ])})`
                        : ''
                }`}
            </div>
            <SummaryList key={email.id}>
                <SummaryRow label={strings.dateEffectiveFrom} hidden={!isPoleEnabled}>
                    {email.dateEffectiveFrom}
                </SummaryRow>
                <SummaryRow label={strings.dateEffectiveTo} hidden={!isPoleEnabled}>
                    {email.dateEffectiveTo}
                </SummaryRow>
                <SummaryRow label={strings.description}>{email.provenance}</SummaryRow>
                <SummaryRow label={strings.details}>{email.safeContactDetails}</SummaryRow>
            </SummaryList>
        </SummaryRow>
    ));

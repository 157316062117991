import { includes, isFunction, keys, map, omit, pick } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import keyMirror from 'keymirror';
import invariant from 'invariant';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayStateTypeEnum from '~/client-common/core/enums/client/overlayStateTypeEnum';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { hydratedChargesForChargesWhereSelector } from '~/client-common/core/domain/charges/state/ui';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import { getErrorMessagesFromErrors } from '../../helpers/validationHelpers';
import testIds from '../../../../../core/testIds';
import { PortalSidePanel } from '../../../../../legacy-redux/components/core/SidePanel';
import { OverlayBaseHelper } from '../../../../core/components/OverlayBaseHelper';
import BackBanner from '../../../../core/components/SidePanel/BackBanner';

import {
    setOffenseOnCharge,
    getLegacyOffenseForCharge,
    setLegacyOffenseOnCharge,
    getWarrantIdForCharge,
    setWarrantOnCharge,
} from '../../state/forms/arrest-charges-side-panel/nChargesForm';
import { getChargeEntityQuickAddView } from '../../state/ui/arrest-charges-side-panel/nCharges';

import FindWarrantSearchScreen, {
    unregisterFindWarrantSearchScreenForm,
    onPerformSearch as findWarrantSearch,
    onCancelSidePanel as findWarrantSearchScreenOnCancelSidePanel,
} from './screens/FindWarrantSearchScreen';
import FindWarrantSearchResultsScreen, {
    onPerformSearchMoreResults as findWarrantSearchMoreResults,
    onSelectWarrant as findWarrantSearchOnSelectWarrantOnSelectOffense,
} from './screens/FindWarrantSearchResultsScreen';
import LegacyOffenseScreen, {
    unregisterLegacyOffenseScreenForm,
    onSaveSidePanel as legacyOffenseScreenOnSaveSidePanel,
    onCancelSidePanel as legacyOffenseScreenOnCancelSidePanel,
} from './screens/LegacyOffenseScreen';
import NChargesScreen, {
    unregisterNChargesScreenForm,
    onSaveSidePanel as nChargesScreenOnSaveSidePanel,
    onCancelSidePanel as nChargesScreenOnCancelSidePanel,
} from './screens/NChargesScreen';
import PriorOffenseSearchScreen, {
    unregisterPriorOffenseSearchScreenForm,
    onPerformSearch as priorOffenseSearch,
    onCancelSidePanel as priorOffenseSearchCancelSidePanel,
} from './screens/PriorOffenseSearchScreen';
import PriorOffenseSearchResultsScreen, {
    onPerformSearchMoreResults as priorOffenseSearchMoreResults,
} from './screens/PriorOffenseSearchResultsScreen';
import PriorOffenseSearchOffenseSelectorScreen, {
    unregisterPriorOffenseSearchOffenseSelectorScreenForm,
    onSelectOffense as priorOffenseSearchOnSelectOffense,
    onCancelSidePanel as priorOffenseSearchOffenseSelectorCancelSidePanel,
} from './screens/PriorOffenseSearchOffenseSelectorScreen';
import StubOffenseScreen, {
    unregisterStubOffenseScreenForm,
    onSaveSidePanel as stubOffenseScreenOnSaveSidePanel,
    onCancelSidePanel as stubOffenseScreenOnCancelSidePanel,
} from './screens/StubOffenseScreen';
import StubWarrantScreen, {
    unregisterStubWarrantScreenForm,
    onSaveSidePanel as stubWarrantScreenOnSaveSidePanel,
    onCancelSidePanel as stubWarrantScreenOnCancelSidePanel,
} from './screens/StubWarrantScreen';

const strings = componentStrings.reports.core.ArrestChargesSidePanel;

const BackBannerBottomPadding = styled(BackBanner)`
    padding: 0 0 20px 0;
`;

const unregisterAllSidePanelForms = () => {
    unregisterNChargesScreenForm();
    unregisterPriorOffenseSearchScreenForm();
    unregisterPriorOffenseSearchOffenseSelectorScreenForm();
    unregisterStubOffenseScreenForm();
    unregisterLegacyOffenseScreenForm();
    unregisterFindWarrantSearchScreenForm();
    unregisterStubWarrantScreenForm();
};

const ARREST_CHARGES_SIDE_PANEL_SCREENS = keyMirror({
    FIND_WARRANT_SEARCH: null,
    FIND_WARRANT_SEARCH_RESULTS: null,
    LEGACY_OFFENSE: null,
    N_CHARGES: null,
    PRIOR_OFFENSE_SEARCH: null,
    PRIOR_OFFENSE_SEARCH_OFFENSE_SELECTOR: null,
    PRIOR_OFFENSE_SEARCH_RESULTS: null,
    STUB_OFFENSE: null,
    STUB_WARRANT: null,
});

const sidePanelScreensInitialState = {
    isInitialLoad: false,
    initialHydratedCharges: [],
    offenseOrderOfEditingCharge: null,
    isPerformingAsyncAction: false,
    editLegacyOffensePrefill: {},
    editStubWarrantIdPrefill: null,
    priorOffenseSearch: null,
    findWarrantSearch: null,
};

const buildPriorOffenseSearchState = ({
    existingSearchElasticReportIds = [],
    priorOffenseElasticReportsSearchResult,
}) => {
    const {
        elasticReportsSearchResult: {
            query: { elasticQuery },
            items,
            totalCount,
        },
        from,
        size,
    } = priorOffenseElasticReportsSearchResult;
    return {
        orderedCurrentResultElasticReportIds: []
            .concat(existingSearchElasticReportIds)
            .concat(map(items, 'id')),
        elasticQuery,
        from,
        size,
        totalCount,
        priorOffenseSearchSelectedElasticReportViewModel: null,
    };
};

const buildFindWarrantSearchState = ({
    existingSearchElasticWarrantIds = [],
    findWarrantElasticWarrantsSearchResult,
}) => {
    const {
        elasticWarrantsSearchResult: {
            query: { elasticQuery },
            items,
            totalCount,
        },
        from,
        size,
    } = findWarrantElasticWarrantsSearchResult;
    return {
        orderedCurrentResultElasticWarrantIds: []
            .concat(existingSearchElasticWarrantIds)
            .concat(map(items, 'id')),
        elasticQuery,
        from,
        size,
        totalCount,
    };
};

const getCurrentScreenConfiguration =
    ({
        offenseDisplayName,
        screenManagerApi,
        setSidePanelError,
        saveSidePanel,
        cancelSidePanel,
        reportId,
        arrestId,
        formIndex,
    }) =>
    (dispatch) => {
        const { getCurrentScreen, goToNextScreen, goToPreviousScreen, setCurrentScreenState } =
            screenManagerApi;
        const { screen, screenState } = getCurrentScreen();
        invariant(
            includes(keys(ARREST_CHARGES_SIDE_PANEL_SCREENS), screen),
            `Unexpectedly no implementation for Arrest Charges Side Panel screen: ${screen}.`
        );

        const resetErrorsAndGoToPreviousScreen = () => {
            setSidePanelError([]);
            goToPreviousScreen();
        };

        const resetErrorsAndGoToNextScreen = (screenName, screenStateObj = {}) => {
            setSidePanelError([]);
            goToNextScreen(screenName, screenStateObj);
        };

        if (screen === ARREST_CHARGES_SIDE_PANEL_SCREENS.FIND_WARRANT_SEARCH) {
            return {
                title: strings.findWarrantScreensTitle,
                onCancelSidePanel: () => {
                    findWarrantSearchScreenOnCancelSidePanel();
                    resetErrorsAndGoToPreviousScreen();
                },
                renderBodyContent: (
                    <FindWarrantSearchScreen
                        screenState={screenState}
                        onPerformSearchCallback={({ searchType }) => {
                            setCurrentScreenState({ isPerformingAsyncAction: true });
                            dispatch(findWarrantSearch({ searchType }))
                                .then((findWarrantElasticWarrantsSearchResult) => {
                                    const {
                                        screenState: { offenseOrderOfEditingCharge },
                                    } = getCurrentScreen();
                                    const findWarrantSearchState = buildFindWarrantSearchState({
                                        existingSearchElasticWarrantIds: [],
                                        findWarrantElasticWarrantsSearchResult,
                                    });

                                    setCurrentScreenState({ isPerformingAsyncAction: false });
                                    resetErrorsAndGoToNextScreen(
                                        ARREST_CHARGES_SIDE_PANEL_SCREENS.FIND_WARRANT_SEARCH_RESULTS,
                                        {
                                            offenseOrderOfEditingCharge,
                                            findWarrantSearch: findWarrantSearchState,
                                        }
                                    );
                                })
                                .catch((err) => {
                                    setCurrentScreenState({ isPerformingAsyncAction: false });
                                    setSidePanelError(getErrorMessagesFromErrors(err));
                                });
                        }}
                    />
                ),
            };
        } else if (screen === ARREST_CHARGES_SIDE_PANEL_SCREENS.FIND_WARRANT_SEARCH_RESULTS) {
            const { isPerformingAsyncAction } = screenState;
            const onCancelSidePanel = resetErrorsAndGoToPreviousScreen;
            return {
                title: strings.findWarrantScreensTitle,
                onCancelSidePanel,
                renderBodyHeaderContent: (
                    <BackBannerBottomPadding
                        disabled={isPerformingAsyncAction}
                        onClickBack={onCancelSidePanel}
                    />
                ),
                renderBodyContent: (
                    <FindWarrantSearchResultsScreen
                        screenState={screenState}
                        onSearchResultClickCallback={({ warrantId }) => {
                            setCurrentScreenState({ isPerformingAsyncAction: true });
                            dispatch(findWarrantSearchOnSelectWarrantOnSelectOffense({ warrantId }))
                                .then((selectedWarrant) => {
                                    const { id } = selectedWarrant;
                                    const {
                                        screenState: { offenseOrderOfEditingCharge },
                                    } = getCurrentScreen();

                                    setWarrantOnCharge({
                                        chargeOffenseOrder: offenseOrderOfEditingCharge,
                                        warrantId: id,
                                    });
                                    resetErrorsAndGoToNextScreen(
                                        ARREST_CHARGES_SIDE_PANEL_SCREENS.N_CHARGES,
                                        {
                                            ...pick(screenState, 'initialHydratedCharges'),
                                            ...omit(
                                                sidePanelScreensInitialState,
                                                'initialHydratedCharges'
                                            ),
                                        }
                                    );
                                })
                                .catch((err) => {
                                    setCurrentScreenState({ isPerformingAsyncAction: false });
                                    setSidePanelError(getErrorMessagesFromErrors(err));
                                });
                        }}
                        onPerformSearchMoreResultsCallback={() => {
                            setCurrentScreenState({ isPerformingAsyncAction: true });
                            const {
                                screenState: { findWarrantSearch },
                            } = getCurrentScreen();

                            dispatch(findWarrantSearchMoreResults({ findWarrantSearch }))
                                .then((findWarrantElasticWarrantsSearchResult) => {
                                    setCurrentScreenState({
                                        isPerformingAsyncAction: false,
                                        findWarrantSearch: buildFindWarrantSearchState({
                                            existingSearchElasticWarrantIds:
                                                findWarrantSearch.orderedCurrentResultElasticWarrantIds,
                                            findWarrantElasticWarrantsSearchResult,
                                        }),
                                    });
                                })
                                .catch((err) => {
                                    setCurrentScreenState({ isPerformingAsyncAction: false });
                                    setSidePanelError(getErrorMessagesFromErrors(err));
                                });
                        }}
                    />
                ),
            };
        } else if (screen === ARREST_CHARGES_SIDE_PANEL_SCREENS.LEGACY_OFFENSE) {
            return {
                title: strings.legacyOffenseScreenTitle(offenseDisplayName),
                onSaveSidePanel: () => {
                    const legacyOffenseScreenSaveResult = legacyOffenseScreenOnSaveSidePanel();
                    const {
                        success,
                        formErrors,
                        legacyCharge,
                        legacyEventNumber,
                        legacyChargeSourceAttrId,
                    } = legacyOffenseScreenSaveResult;

                    if (success) {
                        const {
                            screenState: { offenseOrderOfEditingCharge },
                        } = getCurrentScreen();
                        setLegacyOffenseOnCharge({
                            chargeOffenseOrder: offenseOrderOfEditingCharge,
                            legacyCharge,
                            legacyEventNumber,
                            legacyChargeSourceAttrId,
                        });
                        resetErrorsAndGoToNextScreen(ARREST_CHARGES_SIDE_PANEL_SCREENS.N_CHARGES, {
                            ...pick(screenState, 'initialHydratedCharges'),
                            ...omit(sidePanelScreensInitialState, 'initialHydratedCharges'),
                        });
                    } else {
                        setSidePanelError(getErrorMessagesFromErrors(formErrors));
                    }
                },
                onCancelSidePanel: () => {
                    legacyOffenseScreenOnCancelSidePanel();
                    resetErrorsAndGoToPreviousScreen();
                },
                renderBodyContent: <LegacyOffenseScreen screenState={screenState} />,
            };
        } else if (screen === ARREST_CHARGES_SIDE_PANEL_SCREENS.N_CHARGES) {
            return {
                title: strings.sidePanelTitle,
                onSaveSidePanel: () => {
                    dispatch(nChargesScreenOnSaveSidePanel({ reportId, arrestId, formIndex }))
                        .then(() => {
                            setSidePanelError([]);
                            saveSidePanel();
                        })
                        .catch((err) => setSidePanelError(getErrorMessagesFromErrors(err)));
                },
                onCancelSidePanel: () => {
                    nChargesScreenOnCancelSidePanel();
                    setSidePanelError([]);
                    cancelSidePanel();
                },
                renderBodyContent: (
                    <NChargesScreen
                        screenState={screenState}
                        reportId={reportId}
                        arrestId={arrestId}
                        onOrderChangeCallback={() => setSidePanelError([])}
                        onAddStubOffenseCallback={({ offenseOrder }) => {
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.STUB_OFFENSE,
                                {
                                    offenseOrderOfEditingCharge: offenseOrder,
                                }
                            );
                        }}
                        onAddLegacyOffenseCallback={({ offenseOrder }) => {
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.LEGACY_OFFENSE,
                                {
                                    offenseOrderOfEditingCharge: offenseOrder,
                                }
                            );
                        }}
                        onEditLegacyOffenseCallback={({ offenseOrder }) => {
                            const legacyOffense = getLegacyOffenseForCharge({
                                chargeOffenseOrder: offenseOrder,
                            });
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.LEGACY_OFFENSE,
                                {
                                    offenseOrderOfEditingCharge: offenseOrder,
                                    editLegacyOffensePrefill: legacyOffense,
                                }
                            );
                        }}
                        onPriorOffenseSearchCallback={({ offenseOrder }) => {
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.PRIOR_OFFENSE_SEARCH,
                                {
                                    offenseOrderOfEditingCharge: offenseOrder,
                                }
                            );
                        }}
                        onAddStubWarrantCallback={({
                            offenseOrder,
                            isOtherJurisdictionPrefill,
                        }) => {
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.STUB_WARRANT,
                                {
                                    offenseOrderOfEditingCharge: offenseOrder,
                                    isOtherJurisdictionPrefill,
                                }
                            );
                        }}
                        onEditStubWarrantCallback={({
                            offenseOrder,
                            isOtherJurisdictionPrefill,
                        }) => {
                            const warrantId = getWarrantIdForCharge({
                                chargeOffenseOrder: offenseOrder,
                            });
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.STUB_WARRANT,
                                {
                                    offenseOrderOfEditingCharge: offenseOrder,
                                    editStubWarrantIdPrefill: warrantId,
                                    isOtherJurisdictionPrefill,
                                }
                            );
                        }}
                        onFindWarrantSearchCallback={({ offenseOrder }) => {
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.FIND_WARRANT_SEARCH,
                                {
                                    offenseOrderOfEditingCharge: offenseOrder,
                                }
                            );
                        }}
                    />
                ),
                onRest: () => {
                    const { isInitialLoad } = screenState;
                    if (isInitialLoad) {
                        setCurrentScreenState({ isPerformingAsyncAction: true });
                        dispatch(getChargeEntityQuickAddView({ arrestId }))
                            // On success or failure, we want to proceed to the
                            // landing screen.  If this call fails, then at worst, we may not
                            // have the complete set of quick add offenses/warrants to quick
                            // link, which is not the end of the world.
                            .finally(() => {
                                setCurrentScreenState({
                                    isInitialLoad: false,
                                    isPerformingAsyncAction: false,
                                });
                            });
                    }
                },
            };
        } else if (screen === ARREST_CHARGES_SIDE_PANEL_SCREENS.PRIOR_OFFENSE_SEARCH) {
            return {
                title: strings.priorOffenseSearchScreensTitle(offenseDisplayName),
                onCancelSidePanel: () => {
                    priorOffenseSearchCancelSidePanel();
                    resetErrorsAndGoToPreviousScreen();
                },
                renderBodyContent: (
                    <PriorOffenseSearchScreen
                        screenState={screenState}
                        onPerformSearchCallback={({ searchType }) => {
                            setCurrentScreenState({ isPerformingAsyncAction: true });
                            dispatch(priorOffenseSearch({ searchType }))
                                .then((priorOffenseElasticReportsSearchResult) => {
                                    const {
                                        screenState: { offenseOrderOfEditingCharge },
                                    } = getCurrentScreen();
                                    const priorOffenseSearchState = buildPriorOffenseSearchState({
                                        existingSearchElasticReportIds: [],
                                        priorOffenseElasticReportsSearchResult,
                                    });

                                    setCurrentScreenState({ isPerformingAsyncAction: false });
                                    resetErrorsAndGoToNextScreen(
                                        ARREST_CHARGES_SIDE_PANEL_SCREENS.PRIOR_OFFENSE_SEARCH_RESULTS,
                                        {
                                            offenseOrderOfEditingCharge,
                                            priorOffenseSearch: priorOffenseSearchState,
                                        }
                                    );
                                })
                                .catch((err) => {
                                    setCurrentScreenState({ isPerformingAsyncAction: false });
                                    setSidePanelError(getErrorMessagesFromErrors(err));
                                });
                        }}
                    />
                ),
            };
        } else if (
            screen === ARREST_CHARGES_SIDE_PANEL_SCREENS.PRIOR_OFFENSE_SEARCH_OFFENSE_SELECTOR
        ) {
            const { isPerformingAsyncAction } = screenState;
            const onCancelSidePanel = () => {
                priorOffenseSearchOffenseSelectorCancelSidePanel();
                resetErrorsAndGoToPreviousScreen();
            };
            return {
                title: strings.priorOffenseSearchScreensTitle(offenseDisplayName),
                onCancelSidePanel,
                renderBodyHeaderContent: (
                    <BackBannerBottomPadding
                        disabled={isPerformingAsyncAction}
                        onClickBack={onCancelSidePanel}
                    />
                ),
                renderBodyContent: (
                    <PriorOffenseSearchOffenseSelectorScreen
                        screenState={screenState}
                        onSelectOffenseCallback={({ offenseId }) => {
                            setCurrentScreenState({ isPerformingAsyncAction: true });
                            dispatch(priorOffenseSearchOnSelectOffense({ offenseId }))
                                .then((selectedOffense) => {
                                    setCurrentScreenState({ isPerformingAsyncAction: false });
                                    const { screenState } = getCurrentScreen();
                                    const { offenseOrderOfEditingCharge } = screenState;
                                    const { id } = selectedOffense;

                                    dispatch(
                                        setOffenseOnCharge({
                                            chargeOffenseOrder: offenseOrderOfEditingCharge,
                                            offenseId: id,
                                        })
                                    );
                                    resetErrorsAndGoToNextScreen(
                                        ARREST_CHARGES_SIDE_PANEL_SCREENS.N_CHARGES,
                                        {
                                            ...pick(screenState, 'initialHydratedCharges'),
                                            ...omit(
                                                sidePanelScreensInitialState,
                                                'initialHydratedCharges'
                                            ),
                                        }
                                    );
                                })
                                .catch((err) => {
                                    setCurrentScreenState({ isPerformingAsyncAction: false });
                                    setSidePanelError(getErrorMessagesFromErrors(err));
                                });
                        }}
                    />
                ),
            };
        } else if (screen === ARREST_CHARGES_SIDE_PANEL_SCREENS.PRIOR_OFFENSE_SEARCH_RESULTS) {
            const { isPerformingAsyncAction } = screenState;
            const onCancelSidePanel = resetErrorsAndGoToPreviousScreen;
            return {
                title: strings.priorOffenseSearchScreensTitle(offenseDisplayName),
                onCancelSidePanel,
                renderBodyHeaderContent: (
                    <BackBannerBottomPadding
                        disabled={isPerformingAsyncAction}
                        onClickBack={onCancelSidePanel}
                    />
                ),
                renderBodyContent: (
                    <PriorOffenseSearchResultsScreen
                        screenState={screenState}
                        onSearchResultClickCallback={({
                            priorOffenseSearchSelectedElasticReportViewModel,
                        }) => {
                            const {
                                screenState: { offenseOrderOfEditingCharge, priorOffenseSearch },
                            } = getCurrentScreen();
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.PRIOR_OFFENSE_SEARCH_OFFENSE_SELECTOR,
                                {
                                    offenseOrderOfEditingCharge,
                                    priorOffenseSearch: {
                                        ...priorOffenseSearch,
                                        priorOffenseSearchSelectedElasticReportViewModel,
                                    },
                                }
                            );
                        }}
                        onPerformSearchMoreResultsCallback={() => {
                            setCurrentScreenState({ isPerformingAsyncAction: true });
                            const {
                                screenState: { priorOffenseSearch },
                            } = getCurrentScreen();

                            dispatch(priorOffenseSearchMoreResults({ priorOffenseSearch }))
                                .then((priorOffenseElasticReportsSearchResult) => {
                                    setCurrentScreenState({
                                        isPerformingAsyncAction: false,
                                        priorOffenseSearch: buildPriorOffenseSearchState({
                                            existingSearchElasticReportIds:
                                                priorOffenseSearch.orderedCurrentResultElasticReportIds,
                                            priorOffenseElasticReportsSearchResult,
                                        }),
                                    });
                                })
                                .catch((err) => {
                                    setCurrentScreenState({ isPerformingAsyncAction: false });
                                    setSidePanelError(getErrorMessagesFromErrors(err));
                                });
                        }}
                    />
                ),
            };
        } else if (screen === ARREST_CHARGES_SIDE_PANEL_SCREENS.STUB_OFFENSE) {
            return {
                title: strings.stubOffenseScreenTitle(offenseDisplayName),
                onSaveSidePanel: () => {
                    setCurrentScreenState({ isPerformingAsyncAction: true });
                    dispatch(stubOffenseScreenOnSaveSidePanel())
                        .then((createdStubOffense) => {
                            setCurrentScreenState({ isPerformingAsyncAction: false });
                            const { screenState } = getCurrentScreen();
                            const { offenseOrderOfEditingCharge } = screenState;
                            const { id } = createdStubOffense;

                            dispatch(
                                setOffenseOnCharge({
                                    chargeOffenseOrder: offenseOrderOfEditingCharge,
                                    offenseId: id,
                                })
                            );
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.N_CHARGES,
                                {
                                    ...pick(screenState, 'initialHydratedCharges'),
                                    ...omit(sidePanelScreensInitialState, 'initialHydratedCharges'),
                                }
                            );
                        })
                        .catch((err) => {
                            setCurrentScreenState({ isPerformingAsyncAction: false });
                            setSidePanelError(getErrorMessagesFromErrors(err));
                        });
                },
                onCancelSidePanel: () => {
                    stubOffenseScreenOnCancelSidePanel();
                    resetErrorsAndGoToPreviousScreen();
                },
                renderBodyContent: <StubOffenseScreen screenState={screenState} />,
            };
        } else if (screen === ARREST_CHARGES_SIDE_PANEL_SCREENS.STUB_WARRANT) {
            return {
                title: strings.stubWarrantScreenTitle,
                onSaveSidePanel: () => {
                    const {
                        screenState: { editStubWarrantIdPrefill },
                    } = getCurrentScreen();
                    setCurrentScreenState({ isPerformingAsyncAction: true });
                    dispatch(
                        stubWarrantScreenOnSaveSidePanel({
                            isEditingStubWarrant: !isUndefinedOrNull(editStubWarrantIdPrefill),
                        })
                    )
                        .then((stubWarrant) => {
                            setCurrentScreenState({ isPerformingAsyncAction: false });
                            const { screenState } = getCurrentScreen();
                            const { offenseOrderOfEditingCharge } = screenState;
                            const { id } = stubWarrant;

                            setWarrantOnCharge({
                                chargeOffenseOrder: offenseOrderOfEditingCharge,
                                warrantId: id,
                            });
                            resetErrorsAndGoToNextScreen(
                                ARREST_CHARGES_SIDE_PANEL_SCREENS.N_CHARGES,
                                {
                                    ...pick(screenState, 'initialHydratedCharges'),
                                    ...omit(sidePanelScreensInitialState, 'initialHydratedCharges'),
                                }
                            );
                        })
                        .catch((err) => {
                            setCurrentScreenState({ isPerformingAsyncAction: false });
                            setSidePanelError(getErrorMessagesFromErrors(err));
                        });
                },
                onCancelSidePanel: () => {
                    stubWarrantScreenOnCancelSidePanel();
                    resetErrorsAndGoToPreviousScreen();
                },
                renderBodyContent: <StubWarrantScreen screenState={screenState} />,
            };
        }
    };

const ArrestChargesSidePanel = connect(
    createStructuredSelector({
        arrestForReportId: arrestForReportIdSelector,
        hydratedChargesForChargesWhere: hydratedChargesForChargesWhereSelector,
    }),
    { getCurrentScreenConfiguration }
)(function _ArrestChargesSidePanel({
    overlayId,
    reportId,
    formIndex,
    renderButton,
    arrestForReportId,
    hydratedChargesForChargesWhere,
    getCurrentScreenConfiguration,
}) {
    // User can navigate away from the Arrest Charges Side Panel by clicking a link
    // outside of the report page, such as a link in the main app header.
    // These unclean exits from the side panel will cause the side panel's form states
    // to linger, so, when we unmount the side panel, let's clean them up.
    // Simulate `componentWillUnmount`
    useEffect(() => {
        return unregisterAllSidePanelForms;
    }, []);

    const offenseDisplayName = useOffenseFieldName();

    const arrest = arrestForReportId(reportId);
    // If we're not on an Arrest Report, render nothing.
    return isUndefinedOrNull(arrest) ? null : (
        <OverlayBaseHelper
            id={overlayId}
            overlayStateType={overlayStateTypeEnum.CHARGES_OVERLAY}
            getInitialCustomPropertyState={() => {
                const hydratedCharges = hydratedChargesForChargesWhere({ arrestId: arrest.id });
                return buildInitialCustomPropertyState({ hydratedCharges });
            }}
            renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => {
                if (isFunction(renderButton)) {
                    return renderButton({ open, setCloseFocusRefs });
                }
                return null;
            }}
        >
            {({
                overlayBase: { setError, ...overlayProps },
                screenManagerApi,
                savePanel,
                closePanel,
                ...renderProps
            }) => {
                const {
                    screenState: { isPerformingAsyncAction },
                } = screenManagerApi.getCurrentScreen();
                const {
                    overlayState: { errors },
                } = overlayProps;
                const {
                    title,
                    onSaveSidePanel,
                    onCancelSidePanel,
                    renderBodyHeaderContent = null,
                    renderBodyContent,
                    onRest,
                } = getCurrentScreenConfiguration({
                    offenseDisplayName,
                    screenManagerApi,
                    setSidePanelError: setError,
                    saveSidePanel: savePanel,
                    cancelSidePanel: closePanel,
                    reportId,
                    arrestId: arrest.id,
                    formIndex,
                });
                const saveText = isFunction(onSaveSidePanel)
                    ? strings.sidePanelSaveButtonText
                    : null;

                return (
                    <PortalSidePanel
                        testId={testIds.CHARGES_SIDE_PANEL}
                        errorMessages={errors}
                        // always true -- we always want panel level error messages to show.
                        userHasAttemptedSave={true}
                        title={title}
                        saveText={saveText}
                        savePanel={onSaveSidePanel}
                        closePanel={onCancelSidePanel}
                        saveDisabled={isPerformingAsyncAction}
                        cancelDisabled={isPerformingAsyncAction}
                        onRest={onRest}
                        {...renderProps}
                        {...overlayProps}
                    >
                        {renderBodyHeaderContent}
                        {renderBodyContent}
                    </PortalSidePanel>
                );
            }}
        </OverlayBaseHelper>
    );
});

export const buildInitialCustomPropertyState = ({ hydratedCharges }) => {
    return {
        screenStack: [
            {
                screen: ARREST_CHARGES_SIDE_PANEL_SCREENS.N_CHARGES,
                screenState: {
                    ...sidePanelScreensInitialState,
                    isInitialLoad: true,
                    isPerformingAsyncAction: true,
                    initialHydratedCharges: hydratedCharges,
                },
            },
        ],
    };
};

export default ArrestChargesSidePanel;

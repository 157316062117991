import React from 'react';
import { EntityTypeEnum } from '@mark43/rms-api';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTruthyValuesByKey } from '~/client-common/helpers/logicHelpers';
import { nameCaseLinksWhereSelector } from '~/client-common/core/domain/name-case-links/state/data';

import OrganizationCell from '../../../../legacy-redux/components/core/tables/elasticOrganizationTable/OrganizationCell';
import IdentifiersCell from '../../../../legacy-redux/components/core/tables/elasticOrganizationTable/IdentifiersCell';
import LocationsCell from '../../../../legacy-redux/components/core/tables/LocationsCell';

import Link from '../../../core/components/links/Link';
import createElement from '../../../core/utils/recompose.createElement';
import { currentCaseIdSelector } from '../../core/state/ui';
import CaseLinkIndicator from './CaseLinkIndicator';
import CaseLinkedProfile from './CaseLinkedProfile';
import CaseSourceIndicator from './CaseSourceIndicator';
import CaseTargetLevelIndicator from './CaseTargetLevelIndicator';

function CaseLinkedOrganization({
    organization,
    formattedInvolvement,
    nameCaseLinksWhere,
    currentCaseId,
}) {
    const predicate = {
        caseId: currentCaseId,
        nameId: organization.id,
        entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
    };

    const nameCaseLinks = nameCaseLinksWhere(predicate);
    const targetProfileLevelAttrIds = getTruthyValuesByKey(nameCaseLinks, 'targetProfileLevelAttrId');

    // use of `createElement` is due to the fact that all `Symbol` properties
    // of an object are lost when it's "copied" (via spread syntax). This is an intended
    // effect of spread syntax / for in
    return (
        <Link to={`/profiles/organizations/${organization.id}`} target="_blank">
            <CaseLinkedProfile>
                <div className="case-related-entity-cell related-entity-organization-cell">
                    {createElement(OrganizationCell, { ...organization })}
                    <CaseLinkIndicator formattedInvolvement={formattedInvolvement} />
                    <CaseSourceIndicator sources={organization.sources} />
                    <CaseTargetLevelIndicator attributeIds={targetProfileLevelAttrIds} />
                </div>
                <div className="case-related-entity-cell related-entity-organization-identifiers-cell">
                    {createElement(IdentifiersCell, { ...organization })}
                </div>
                <div className="case-related-entity-cell related-entity-locations-cell organization-locations">
                    {createElement(LocationsCell, { ...organization })}
                </div>
            </CaseLinkedProfile>
        </Link>
    );
}

const mapStateToProps = createStructuredSelector({
    nameCaseLinksWhere: nameCaseLinksWhereSelector,
    currentCaseId: currentCaseIdSelector,
});

export default connect(mapStateToProps)(CaseLinkedOrganization);

export const BRIEFINGS_ROUTES = {
    root: {
        path: 'briefings',
        url: () => BRIEFINGS_ROUTES.root.path,
    },
    item: {
        path: 'briefings/:briefingId',
        url: (briefingId: number, params?: string) =>
            `${BRIEFINGS_ROUTES.root.path}/${briefingId}${params ? `?${params}` : ''}`,
    },
};

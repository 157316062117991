// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'admin/abilities';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Abilities Resource',
        methods: {
            loadAbilities() {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;

/**
 * Resources for Abilities. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
export default resourceGetter;

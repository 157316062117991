import { reduce, forEach, includes } from 'lodash';

import { Attachment, Printable, EntityTypeEnum } from '@mark43/rms-api';
import { ModuleShape } from '~/client-common/core/utils/createNormalizedModule';
import { LinkedModuleShape } from '~/client-common/core/utils/createLinkModule';
import { Folder } from '~/client-common/core/domain/folders/state/data';

import getCaseIdsInPackets from './getCaseIdsInPackets';
import type { PacketOption } from './exportHelpers';
import getFolderIdsInPackets from './getFolderIdsInPackets';

/**
 * Creates an object which contains lists of attachments by matching `entityId`
 * indexed by `entityId` for all `entities`.
 */
export function groupAttachmentsByEntityId({
    entities,
    attachments,
}: {
    entities: PacketOption[];
    attachments: Attachment[];
}): Record<number, Attachment[]> {
    const attachmentsByEntity: Record<number, Attachment[]> = {};
    const validEntityIds = reduce<PacketOption, Record<number, true>>(
        entities,
        (acc, entity) => {
            if (entity.entityId) {
                acc[entity.entityId] = true;
            }
            return acc;
        },
        {}
    );

    for (const caseId of getCaseIdsInPackets(entities)) {
        validEntityIds[caseId] = true;
    }

    forEach(attachments, (attachment) => {
        if (!validEntityIds[attachment.entityId]) {
            return;
        }
        const arr =
            attachmentsByEntity[attachment.entityId] ||
            (attachmentsByEntity[attachment.entityId] = []);
        arr.push(attachment);
    });

    return attachmentsByEntity;
}

/**
 * The purpose of this type is to make a Folder behave like an Attachment in the export page. It's basically a shim
 * because a folder (which contains attachments) may be selected in the export attachments side panel, in the same way
 * as a normal attachment.
 */
export type ExportableFolder = Folder & {
    /**
     * The ownerId of the folder, such as a caseId.
     */
    entityId: number;
    /**
     * This value is always the folderId.
     */
    attachmentId: number;
};

/**
 * Use this instead of the base helper function `groupAttachmentsByEntityId` when the export feature involves attachment
 * folders.
 */
export function groupAttachmentsByEntityIdWithFolders({
    entities,
    attachments,
    attachmentFolderPrintables,
    folders,
}: {
    entities: PacketOption[];
    attachments: Attachment[];
    attachmentFolderPrintables: LinkedModuleShape<Printable>;
    folders: ModuleShape<Folder>;
}): Record<number, (Attachment | ExportableFolder)[]> {
    const caseIds = getCaseIdsInPackets(entities);
    const folderIds = getFolderIdsInPackets(entities);
    const attachmentsByEntityId = groupAttachmentsByEntityId({ entities, attachments });

    return reduce<Printable, Record<number, (Attachment | ExportableFolder)[]>>(
        attachmentFolderPrintables,
        (acc, printable) => {
            const folderId = printable.entityId;
            const folder = folderId ? folders[folderId] : undefined;

            if (folder?.folder && folderId) {
                const ownerId = folder.folder.ownerId;

                // note folder printables do not contain case id so first condition accounts for that
                // second condition accounts for the relevant attachment folders
                if (
                    includes(folderIds, folderId) ||
                    (includes(caseIds, ownerId) &&
                        folder.folder.entityTypeId === EntityTypeEnum.ATTACHMENT.name)
                ) {
                    // This logic is similar to `groupAttachmentsByEntityId`. The difference is we are adding
                    // to the array a different type of object which is not an Attachment. When using these
                    // arrays, you will find the folders by checking whether the `folder` property exists.
                    const key =
                        folder.folder.entityTypeId === EntityTypeEnum.CASE_NOTE.name
                            ? folderId
                            : ownerId;

                    const exportableFolder: ExportableFolder = {
                        ...folder,
                        entityId: key,
                        attachmentId: folderId,
                    };

                    return {
                        ...acc,
                        [key]: [...(acc[key] || []), exportableFolder],
                    };
                }
            }
            return acc;
        },
        attachmentsByEntityId
    );
}

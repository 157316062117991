import _ from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import boxActionTypes from '../actions/types/boxActionTypes';
import { ENTER_NEW_ROUTE } from '../../routing/routerModule';

const {
    OPEN_BOX,
    CLOSE_BOX,
    SAVE_BOX_START,
    SAVE_BOX_HALT,
    SAVE_BOX_SUCCESS,
    SAVE_BOX_FAILURE,
    STORE_BOX_ERROR_MESSAGES,
    LOAD_BOX_START,
    STORE_PAYLOAD,
} = boxActionTypes;

const initialPanelState = {
    saving: false,
    userHasAttemptedSave: false,
    isOpen: false,
    errorMessages: [],
    zIndexOverride: null,
};
// initialUiState should have one key per panel
// Here we set up the initial state for each panel
// Actions that modify panel state must specify which panel they apply to using action.meta
const initialBoxUiState = _.mapValues(boxEnum, () => ({
    ...initialPanelState,
}));

export function boxUiReducer(state = initialBoxUiState, action) {
    switch (action.type) {
        case LOAD_BOX_START:
            return {
                ...state,
                [action.meta.name]: {
                    ...state[action.meta.name],
                    loading: true,
                },
            };
        case SAVE_BOX_START:
            return {
                ...state,
                [action.meta.name]: {
                    ...state[action.meta.name],
                    saving: true,
                    userHasAttemptedSave: true,
                },
            };
        case SAVE_BOX_HALT:
            return {
                ...state,
                [action.meta.name]: {
                    ...state[action.meta.name],
                    saving: false,
                    loading: false,
                },
            };
        case SAVE_BOX_SUCCESS:
            return {
                ...state,
                [action.meta.name]: {
                    ...state[action.meta.name],
                    saving: false,
                    loading: false,
                    isOpen: false,
                },
            };
        case SAVE_BOX_FAILURE:
            return {
                ...state,
                [action.meta.name]: {
                    ...state[action.meta.name],
                    saving: false,
                    loading: false,
                    errorMessages: action.payload,
                },
            };
        case OPEN_BOX:
            return {
                ...state,
                [action.meta.name]: {
                    ...state[action.meta.name],
                    isOpen: true,
                    userHasAttemptedSave: false,
                    errorMessages: [],
                    saving: false,
                    loading: false,
                    ...action.payload,
                },
            };
        case CLOSE_BOX:
            return {
                ...state,
                // closing box should reset the box state
                [action.meta.name]: initialPanelState,
            };
        case ENTER_NEW_ROUTE:
            return _.mapValues(state, (box, name) => {
                // special exception: don't close the confirmation bar across
                // route changes because it can be opened right before a route
                // change, and it closes itself on a timer anyway
                return name === boxEnum.CONFIRMATION_BAR
                    ? box
                    : {
                          ...box,
                          isOpen: false,
                      };
            });
        case STORE_BOX_ERROR_MESSAGES:
            return {
                ...state,
                [action.meta.name]: {
                    ...state[action.meta.name],
                    errorMessages: action.payload,
                    saving: !!action.payload.length ? false : state[action.meta.name].saving,
                },
            };
        case STORE_PAYLOAD:
            return {
                ...state,
                [action.meta.name]: {
                    ...state[action.meta.name],
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}

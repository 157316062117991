import React, { useCallback, useEffect } from 'react';
import { RefContextEnum, EntityTypeEnum, ComplianceGroupEnum } from '@mark43/rms-api';
import _, { concat, find, get, head, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Observer, lifecycleOptions } from 'markformythree';

import {
    offenseCodesSelector,
    offenseCodeByIdSelector,
} from '~/client-common/core/domain/offense-codes/state/data';
import { applyStatuteCodeSetFilterToFlags } from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';
import { nibrsOffenseCodesSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import {
    OFFENSE_TYPE_OFFENSE_CODE_FLAGS,
    INCIDENT_TYPE_OFFENSE_CODE_FLAGS,
} from '~/client-common/core/domain/offense-codes/constants';
import { isNibrsAssault } from '~/client-common/core/domain/offense-codes/utils/offenseCodesHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { reportRenByIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { saveReportComment } from '~/client-common/core/domain/report-comments/state/data';
import { nibrsOffenseCodesForOffenseCodeIdForOffenseCodeIdSelector } from '~/client-common/core/domain/offense-code-nibrs-code-links/state/data';
import { hocCategoryAttrIdsForOffenseCodeIdSelector } from '~/client-common/core/domain/offense-code-hoc-category-links/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import { ERROR_CODES } from '../../../../../lib/errors';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentProfileSelector,
} from '../../../../core/current-user/state/ui';
import Row from '../../../../core/components/Row';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTOffenseCodeSelect } from '../../../../core/forms/components/selects/OffenseCodeSelect';
import {
    actuallyDeleteOffense,
    addNewClientSideOffense,
    openOffenseCodeChangeReasonModal,
} from '../../state/ui/offense';
import incidentCards from '../../state/ui/incidentCards';
import { ArbiterMFTStatuteCodeSetRadio } from '../StatuteCodeSetRadio';
import { requestRecentPersonsOrOrganizationsForAllReports } from '../../../../../legacy-redux/actions/recentEntitiesActions';
import { activeOffenseCodesSelector } from '../../../../../legacy-redux/selectors/offenseCodesSelectors';
import { latestReportStatusHistoryForCurrentReport } from '../../../../../legacy-redux/selectors/reportSelectors';
import { checkIfOffenseDateIsWithinOffenseCodeDateRange } from '../../utils/offenseIncidentHelpers';
import FullOffenseForm from './FullOffenseForm';

const getFirstIfOnlyOneElseFallback = (array, fallback = undefined) =>
    size(array) === 1 ? head(array) : fallback;

const offenseCodeFlags = concat(
    [],
    OFFENSE_TYPE_OFFENSE_CODE_FLAGS,
    INCIDENT_TYPE_OFFENSE_CODE_FLAGS
);

const strings = componentStrings.reports.core.OffenseCard;

const OffenseCardForm = ({
    autoFocus,
    getForm,
    index,
    isClientSideStub,
    isSaving,
    nibrsAllowedProperty,
    offenseId,
    setErrorMessages,
    onStubOffenseCodeChange,
    onFullOffenseCodeChange,
    reportId,
    setItemInvolvement,
    setNibrsAllowedProperty,
    callGetValidOffenseCodeByNameAndDate,
    isLoadingValidOffenseCodeByNameAndDate,
    initialState,
    onSave,
}) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const currentUserDepartmentProfile = useSelector(currentUserDepartmentProfileSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const nibrsOffenseCodes = useSelector(nibrsOffenseCodesSelector);
    const nibrsOffenseCodeForOffenseCodeId = useSelector(
        nibrsOffenseCodesForOffenseCodeIdForOffenseCodeIdSelector
    );
    const activeOffenseCodes = useSelector(activeOffenseCodesSelector)([]);
    const getActiveOffenseCodeByDisplayName = useCallback(
        (dn) => {
            return find(activeOffenseCodes, ({ displayName }) => displayName === dn);
        },
        [activeOffenseCodes]
    );
    const offenseDisplayName = useOffenseFieldName();
    const offenseCodes = useSelector(offenseCodesSelector);
    const offenseCodeById = useSelector(offenseCodeByIdSelector);
    const reportRenById = useSelector(reportRenByIdSelector);
    const hocCategoryAttrIdsForOffenseCodeId = useSelector(
        hocCategoryAttrIdsForOffenseCodeIdSelector
    );
    const latestHistory = useSelector(latestReportStatusHistoryForCurrentReport);

    const complianceGroup = currentUserDepartmentProfile?.complianceGroup;
    const dispatch = useDispatch();

    const onError = useCallback(
        (offenseId) => (err) => {
            const errorMessage =
                err.code === ERROR_CODES.UNPROCESSABLE_ENTITY
                    ? strings.offenseIsAssociatedWithAnArrest(offenseDisplayName)
                    : strings.saveGenericError;
            setErrorMessages([errorMessage], offenseId);
        },
        [offenseDisplayName, setErrorMessages]
    );

    const deleteOffense = useCallback(
        ({ isOffenseTypeChange }) =>
            dispatch(
                actuallyDeleteOffense({
                    offenseId,
                    reportId,
                    isIncident: false,
                    isOffenseTypeChange,
                })
            ),
        [dispatch, offenseId, reportId]
    );
    const addNewIncident = useCallback(
        (offenseCodeId) =>
            dispatch(
                addNewClientSideOffense({
                    reportId,
                    cardModule: incidentCards,
                    isIncident: true,
                    offenseCodeId,
                })
            ),
        [dispatch, reportId]
    );
    const refetchRecentNames = useCallback(
        (reportingEventNumber) => {
            dispatch(
                requestRecentPersonsOrOrganizationsForAllReports({
                    renForRecents: reportingEventNumber,
                    ownerType: EntityTypeEnum.REPORT.name,
                    isOrg: false,
                })
            );
            dispatch(
                requestRecentPersonsOrOrganizationsForAllReports({
                    renForRecents: reportingEventNumber,
                    ownerType: EntityTypeEnum.REPORT.name,
                    isOrg: true,
                })
            );
        },
        [dispatch]
    );

    const isNibrsDept = checkIfDepartmentIsNibrs(currentUserDepartmentProfile);
    const hasNibrsCodingAbility = currentUserHasAbility(abilitiesEnum.REPORTING.NIBRS_CODING);

    const handleOffenseCompletedChange = useCallback(
        (value) => {
            getForm().set('offense.wasCompleted', value);
            setNibrsAllowedProperty();
        },
        [getForm, setNibrsAllowedProperty]
    );

    const handleWeaponForceChange = (value) => {
        getForm().set('offenseAttributes.WEAPON_OR_FORCE_INVOLVED.attributeIds', value);
        setNibrsAllowedProperty();
    };

    const hasAbilityToEditOffenseCode = currentUserHasAbility(
        abilitiesEnum.REPORTING.EDIT_HOME_OFFICE_CLASSIFICATION_CODE
    );

    // it seems like MFT cannot currently handle multiple `onChange` handers
    // so we need to manually set the value in our form before triggering the submit
    // to ensure that the offense code value exists.
    const handleOffenseCodeChange = useCallback(
        ({ offenseCodeId, noReasonNeeded }) => {
            const form = getForm();
            form.set('offense.offenseCodeId', offenseCodeId);

            const nibrsCodes = nibrsOffenseCodeForOffenseCodeId(offenseCodeId) || {};
            const nibrsCode = getFirstIfOnlyOneElseFallback(nibrsCodes, {});

            if (complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name) {
                if (latestHistory && !noReasonNeeded && !hasAbilityToEditOffenseCode) {
                    return;
                }
                const setOrClearUkOffenseCodeCategory = (offenseCodeIdToSet) => {
                    const hocCategories = hocCategoryAttrIdsForOffenseCodeId(offenseCodeIdToSet);
                    const hocCategory = getFirstIfOnlyOneElseFallback(hocCategories, undefined);
                    form.set(
                        'offenseAttributes.UK_OFFENSE_CODE_CATEGORY.attributeIds',
                        hocCategory
                    );
                    return hocCategories.length;
                };
                let categoryCountForOffenseCode = 0;
                const offenseDateUtc = form.get('offense.offenseDateUtc');
                const candidateOffenseCode = offenseCodeById(offenseCodeId);
                const activeOffenseCode = getActiveOffenseCodeByDisplayName(
                    candidateOffenseCode.displayName
                );
                if (!candidateOffenseCode || !offenseDateUtc) {
                    categoryCountForOffenseCode = setOrClearUkOffenseCodeCategory(offenseCodeId);
                } else if (
                    checkIfOffenseDateIsWithinOffenseCodeDateRange({
                        startDateUtc: candidateOffenseCode.startDateUtc,
                        endDateUtc: candidateOffenseCode.endDateUtc,
                        offenseDateUtc,
                    })
                ) {
                    categoryCountForOffenseCode = setOrClearUkOffenseCodeCategory(offenseCodeId);
                } else if (
                    activeOffenseCode &&
                    (!offenseDateUtc ||
                        checkIfOffenseDateIsWithinOffenseCodeDateRange({
                            startDateUtc: activeOffenseCode.startDateUtc,
                            endDateUtc: activeOffenseCode.endDateUtc,
                            offenseDateUtc,
                        }))
                ) {
                    form.set('offense.offenseCodeId', activeOffenseCode.id);
                    categoryCountForOffenseCode = setOrClearUkOffenseCodeCategory(
                        activeOffenseCode.id
                    );
                } else {
                    callGetValidOffenseCodeByNameAndDate({
                        dateTime: offenseDateUtc,
                        displayName: candidateOffenseCode.displayName,
                    });
                }
                if (latestHistory && !noReasonNeeded && categoryCountForOffenseCode === 1) {
                    dispatch(
                        openOffenseCodeChangeReasonModal({
                            callback: (reasonForOffenseCodeChange) => {
                                dispatch(saveReportComment(reportId, reasonForOffenseCodeChange));
                                onSave();
                            },
                            cancel: () => {
                                const previousCategoryCodeId = _.get(
                                    initialState,
                                    'offenseAttributes.UK_OFFENSE_CODE_CATEGORY.attributeIds'
                                );
                                form.set(
                                    'offense.offenseCodeId',
                                    initialState.offense.offenseCodeId
                                );
                                form.set(
                                    'offenseAttributes.UK_OFFENSE_CODE_CATEGORY.attributeIds',
                                    previousCategoryCodeId
                                );
                            },
                        })
                    );
                }
            } else {
                // Anytime the offense code changes, we always want to update the nibrs fields
                form.set('offense.nibrsOffenseCodeId', nibrsCode.id);
                form.set('offense.nibrsCodeCode', nibrsCode.code);
            }

            // ensure that we actually have a value, just clicking in and out of
            // the field should not trigger the outside change handler
            if (!offenseCodeId) {
                return;
            }

            // reset the validation UI
            form.resetUi('offense.wasCompleted');

            // AFTER setting nibrsCodeCode
            setNibrsAllowedProperty();
            if (isNibrsAssault(nibrsCode.code)) {
                handleOffenseCompletedChange(true);
            }
            if (applicationSettings.RMS_INCLUDES_CARGO_THEFT_DEFAULT_NO_ENABLED) {
                form.set('offense.includesCargoTheft', false);
            }

            // handle the user switching from an offense to an incident
            const newOffenseCode = get(offenseCodes, offenseCodeId);
            form.set('offense.offenseCodeCode', newOffenseCode?.code);
            if (newOffenseCode && newOffenseCode.isIncidentType) {
                deleteOffense({ isOffenseTypeChange: true })
                    .then(() => {
                        addNewIncident(offenseCodeId);
                        refetchRecentNames(reportRenById(reportId));
                    })
                    .catch((err) => onError(err));
            }

            // Additional callback to be fired
            if (isClientSideStub && onStubOffenseCodeChange) {
                onStubOffenseCodeChange(offenseCodeId);
            } else if (!isClientSideStub && onFullOffenseCodeChange) {
                onFullOffenseCodeChange(offenseCodeId);
            }
        },
        [
            addNewIncident,
            applicationSettings.RMS_INCLUDES_CARGO_THEFT_DEFAULT_NO_ENABLED,
            callGetValidOffenseCodeByNameAndDate,
            complianceGroup,
            deleteOffense,
            getActiveOffenseCodeByDisplayName,
            getForm,
            handleOffenseCompletedChange,
            hocCategoryAttrIdsForOffenseCodeId,
            isClientSideStub,
            nibrsOffenseCodeForOffenseCodeId,
            offenseCodeById,
            offenseCodes,
            onError,
            onFullOffenseCodeChange,
            onStubOffenseCodeChange,
            refetchRecentNames,
            reportId,
            reportRenById,
            setNibrsAllowedProperty,
            dispatch,
            latestHistory,
            initialState,
            onSave,
            hasAbilityToEditOffenseCode,
        ]
    );

    const handleOffenseDateUtcChange = useCallback(
        ({ offenseDateUtc }) => {
            if (complianceGroup !== ComplianceGroupEnum.UNITED_KINGDOM.name) {
                return;
            }
            const form = getForm();
            const currentOffenseCodeId = form.get('offense.offenseCodeId');
            if (!currentOffenseCodeId) {
                return;
            }
            const currentOffenseCode = offenseCodeById(currentOffenseCodeId);
            if (!currentOffenseCode) {
                return;
            }
            const activeOffenseCode = getActiveOffenseCodeByDisplayName(
                currentOffenseCode.displayName
            );
            const isCurrentOffenseCodeActive = currentOffenseCodeId === activeOffenseCode?.id;
            switch (true) {
                case !offenseDateUtc && isCurrentOffenseCodeActive:
                    return;
                case !offenseDateUtc && !!activeOffenseCode:
                    return form.set('offense.offenseCodeId', activeOffenseCode.id);
                case !offenseDateUtc:
                    return handleOffenseCodeChange({ offenseCodeId: currentOffenseCodeId });
                case checkIfOffenseDateIsWithinOffenseCodeDateRange({
                    offenseDateUtc,
                    startDateUtc: currentOffenseCode.startDateUtc,
                    endDateUtc: currentOffenseCode.endDateUtc,
                }):
                    return;
                default:
                    return handleOffenseCodeChange({ offenseCodeId: currentOffenseCodeId });
            }
        },
        [
            complianceGroup,
            getActiveOffenseCodeByDisplayName,
            getForm,
            handleOffenseCodeChange,
            offenseCodeById,
        ]
    );

    useEffect(() => {
        const form = getForm();
        // This handles offense reports created via the charges sidepanel
        // and it handles setting the value in FORM_OFFENSE when this field
        // changes from FORM_STUB_OFFENSE into FORM_OFFENSE
        if (complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name) {
            if (!form.get('offenseAttributes.UK_OFFENSE_CODE_CATEGORY.attributeIds')) {
                handleOffenseCodeChange({
                    offenseCodeId: form.get('offense.offenseCodeId'),
                    noReasonNeeded: true,
                });
            }
        } else if (!form.get('offense.nibrsCodeCode')) {
            handleOffenseCodeChange({
                offenseCodeId: form.get('offense.offenseCodeId'),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const form = getForm();

        const offenseCodeIdObserveDisposer = form.observe({
            subscriptions: {
                offenseCodeId: 'offense.offenseCodeId',
            },
            callback: ({ offenseCodeId }) => {
                return handleOffenseCodeChange({ offenseCodeId });
            },
        });

        const offenseDateUtcObserveDisposer = form.observe({
            subscriptions: {
                offenseDateUtc: 'offense.offenseDateUtc',
            },
            callback: ({ offenseDateUtc }) => handleOffenseDateUtcChange({ offenseDateUtc }),
        });

        return () => {
            offenseCodeIdObserveDisposer();
            offenseDateUtcObserveDisposer();
        };
    }, [getForm, handleOffenseCodeChange, handleOffenseDateUtcChange]);

    return (
        <Form
            name={RefContextEnum.FORM_OFFENSE.name}
            index={index}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            render={(form) =>
                isClientSideStub ? (
                    <CardSection>
                        <Row>
                            <ArbiterMFTStatuteCodeSetRadio
                                form={getForm()}
                                offenseCodeIdPath="offense.offenseCodeId"
                            />
                        </Row>
                        <Row>
                            <Observer
                                subscriptions={{
                                    statuteCodeSetFilter: 'statuteCodeSetFilter',
                                }}
                                render={({ statuteCodeSetFilter }) => (
                                    <ArbiterMFTOffenseCodeSelect
                                        path="offense.offenseCodeId"
                                        clearable={false}
                                        flags={applyStatuteCodeSetFilterToFlags(
                                            statuteCodeSetFilter,
                                            offenseCodeFlags
                                        )}
                                        statuteCodeSetFilter={statuteCodeSetFilter}
                                        disabled={isSaving}
                                        autoFocus={true}
                                    />
                                )}
                            />
                        </Row>
                    </CardSection>
                ) : (
                    <FullOffenseForm
                        form={form}
                        formIndex={index}
                        isNibrsDept={isNibrsDept}
                        hasNibrsCodingAbility={hasNibrsCodingAbility}
                        setNibrsAllowedProperty={setNibrsAllowedProperty}
                        nibrsAllowedProperty={nibrsAllowedProperty}
                        setItemInvolvement={setItemInvolvement}
                        onOffenseCompletedChange={handleOffenseCompletedChange}
                        onWeaponForceChange={handleWeaponForceChange}
                        offenseCodeFlags={offenseCodeFlags}
                        nibrsOffenseCodes={nibrsOffenseCodes}
                        autoFocus={autoFocus}
                        reportId={reportId}
                        onSave={onSave}
                        initialState={initialState}
                        ukOffenseCodeCategoryExternalIsLoading={
                            isLoadingValidOffenseCodeByNameAndDate
                        }
                    />
                )
            }
        />
    );
};

export default OffenseCardForm;

import React from 'react';
import styled from 'styled-components';
import { Text, cssVar } from 'arc';
import Pill from './Pill';
import Icon, { iconTypes, iconSizes } from './Icon';

const PillText = styled(Text)`
    color: ${(props) => props.theme.colors.cobaltBlue};
    margin-left: 10px;
    line-height: ${cssVar('arc.lineHeights.none')};
`;

const AddPill: React.FC<{
    onClick?: () => void;
    className?: string;
    testId?: string;
    disabled?: boolean;
}> = ({ onClick, children, className, testId, disabled }) => (
    <Pill
        className={className}
        justifyContent="normal"
        hoverable={true}
        onClick={onClick}
        testId={testId}
        tabIndex={0}
        disabled={disabled}
    >
        <Icon color="cobaltBlue" type={iconTypes.ADD} size={iconSizes.MEDIUM} />
        <PillText>{children}</PillText>
    </Pill>
);

export default AddPill;

import { AttributeTypeEnum } from '@mark43/rms-api';
import { filter, get, head, includes } from 'lodash';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Fieldset as MFTFieldset, Observer } from 'markformythree';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import {
    attributeIsOtherSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { vehicleModelsSelector } from '~/client-common/core/domain/vehicle-models/state/data';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import FormRow from '../../../../../core/forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTYearSelect } from '../../../../../core/forms/components/selects/YearSelect';

import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTVehicleMakeSelect } from '../../../../../core/forms/components/selects/VehicleMakeSelect';
import {
    ArbiterMFTVehicleModelSelect,
    initialVehicleModelRelated,
} from '../../../../../core/forms/components/selects/VehicleModelSelect';

import {
    itemSidePanelVehicleModelRelatedSelector,
    storeItemSidePanelVehicleModelRelated,
} from '../../../state/ui';
import withItemCategoryFilterOptions from './withItemCategoryFilterOptions';

const OTHER_OPTION_VALUE = -1;

const vehicleFieldsetStrings = componentStrings.search.fieldsets.VehicleFieldset;

const BaseVehicleDescriptionFieldset = ({
    form,
    helpTextCollisionBoundary,
    itemCategoryFilterOptions,
}) => {
    const attributeIsOther = useSelector(attributeIsOtherSelector);
    const vehicleModelsFromState = useSelector(vehicleModelsSelector);
    const vehicleModelRelated = useSelector(itemSidePanelVehicleModelRelatedSelector);
    const parentAttributeIdByAttributeId = useSelector(parentAttributeIdByAttributeIdSelector);
    const dispatch = useDispatch();

    const { bodyStyleAttrIds, itemCategoryAttrIds, vehicleMake } = vehicleModelRelated;

    const modelRelatedBodyStylesIsEmpty = !bodyStyleAttrIds || bodyStyleAttrIds.length < 1;
    const modelRelatedItemCategoryIsEmpty = !itemCategoryAttrIds || itemCategoryAttrIds.length < 1;

    const bodyStyleFilterOptions = useCallback(
        (options) => {
            if (modelRelatedBodyStylesIsEmpty) {
                const bodyStyleAttrId = form.get('vehicle.bodyStyleAttrId');
                const itemCategoryAttrId = form.get('vehicle.itemCategoryAttrId');

                return filter(
                    options,
                    ({ value }) =>
                        parentAttributeIdByAttributeId(value) === itemCategoryAttrId ||
                        // show current value no matter what
                        value === bodyStyleAttrId
                );
            }

            return filter(
                options,
                ({ value }) => includes(bodyStyleAttrIds, value) || attributeIsOther(value)
            );
        },
        [
            attributeIsOther,
            bodyStyleAttrIds,
            parentAttributeIdByAttributeId,
            modelRelatedBodyStylesIsEmpty,
            form,
        ]
    );

    const handleVehicleModelChange = useCallback(
        (value) => {
            if (!value) {
                form.transaction(() => {
                    form.set('vehicle.vehicleModelHasBodyStyleOptions', false);
                    form.set('vehicle.bodyStyleAttrId', undefined);
                    form.set('vehicle.bodyStyleOther', undefined);
                });

                // vehicleMakeSelect requires vehicleMake to display the persisted Make
                dispatch(
                    // eslint-disable-next-line no-restricted-syntax
                    storeItemSidePanelVehicleModelRelated({
                        ...initialVehicleModelRelated,
                        vehicleMake,
                    })
                );
            }
        },
        [dispatch, form, vehicleMake]
    );

    const handleVehicleModelChangeWithModelRelated = useCallback(
        (vehicleModelRelated) => {
            const itemCategoryAttrId = form.get('vehicle.itemCategoryAttrId');
            const vehicleMakeId = form.get('vehicle.vehicleMakeId');

            form.transaction(() => {
                if (!vehicleMakeId && !!vehicleMake) {
                    form.set('vehicle.vehicleMakeId', vehicleMake.id);
                }

                if (itemCategoryAttrIds.length === 1) {
                    form.set('vehicle.itemCategoryAttrId', head(itemCategoryAttrIds));
                } else if (
                    itemCategoryAttrIds.length > 0 &&
                    !includes(itemCategoryAttrIds, itemCategoryAttrId)
                ) {
                    form.set('vehicle.itemCategoryAttrId', undefined);
                }

                form.set('vehicle.vehicleModelHasBodyStyleOptions', bodyStyleAttrIds.length > 0);
                form.set('vehicle.bodyStyleAttrId', undefined);
                form.set('vehicle.bodyStyleOther', undefined);
            });

            // eslint-disable-next-line no-restricted-syntax
            dispatch(storeItemSidePanelVehicleModelRelated(vehicleModelRelated));
        },
        [dispatch, form, vehicleMake, bodyStyleAttrIds, itemCategoryAttrIds]
    );

    const itemCategoryFilterOptionsOverride = useCallback(
        (options) => {
            if (modelRelatedItemCategoryIsEmpty) {
                return itemCategoryFilterOptions(options);
            }
            return filter(options, ({ value }) => includes(itemCategoryAttrIds, value));
        },
        [itemCategoryAttrIds, modelRelatedItemCategoryIsEmpty, itemCategoryFilterOptions]
    );

    return (
        <SidePanelSection title={vehicleFieldsetStrings.sections.vehicleDescription}>
            <MFTFieldset path="vehicle">
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="itemCategoryAttrId"
                    attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
                    filterOptions={itemCategoryFilterOptionsOverride}
                    length="lg"
                />
                <FormRow>
                    <Observer
                        subscriptions={{
                            itemCategoryAttrId: 'vehicle.itemCategoryAttrId',
                        }}
                        render={({ itemCategoryAttrId }) => (
                            <ArbiterMFTVehicleMakeSelect
                                path="vehicleMakeId"
                                length="md"
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                includeOtherOption={true}
                                itemCategoryAttrId={itemCategoryAttrId}
                                vehicleMake={vehicleModelRelated.vehicleMake}
                                onChange={(vehicleMakeId) => {
                                    const vehicleModelId = form.get('vehicle.vehicleModelId');
                                    if (
                                        vehicleMakeId === OTHER_OPTION_VALUE &&
                                        vehicleModelId !== OTHER_OPTION_VALUE
                                    ) {
                                        form.transaction(() => {
                                            // when Make becomes Other, Model must become Other too because every
                                            // Model (which is not Other) is linked to a Make
                                            form.resetUi('vehicle.vehicleModelId');
                                            form.set('vehicle.vehicleModelId', OTHER_OPTION_VALUE);
                                        });
                                    } else if (
                                        !!vehicleModelId &&
                                        (!vehicleMakeId ||
                                            vehicleMakeId !==
                                                get(
                                                    vehicleModelsFromState[vehicleModelId],
                                                    'vehicleMakeId'
                                                ))
                                    ) {
                                        form.transaction(() => {
                                            // Reset Vehicle Model when this newly selected Make is no longer linked to
                                            // the Model. This includes when no Make is selected.
                                            form.resetUi('vehicle.vehicleModelId');
                                            form.set('vehicle.vehicleModelId', undefined);
                                        });
                                    }

                                    dispatch(
                                        // eslint-disable-next-line no-restricted-syntax
                                        storeItemSidePanelVehicleModelRelated(
                                            initialVehicleModelRelated
                                        )
                                    );
                                }}
                            />
                        )}
                    />
                    <Observer
                        subscriptions={{
                            itemCategoryAttrId: 'vehicle.itemCategoryAttrId',
                            vehicleMakeId: 'vehicle.vehicleMakeId',
                        }}
                        render={({ itemCategoryAttrId, vehicleMakeId }) => (
                            <ArbiterMFTVehicleModelSelect
                                path="vehicleModelId"
                                length="md"
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                includeOtherOption={true}
                                itemCategoryAttrId={itemCategoryAttrId}
                                vehicleMakeId={vehicleMakeId}
                                onChange={handleVehicleModelChange}
                                onChangeWithVehicleModelRelated={
                                    handleVehicleModelChangeWithModelRelated
                                }
                            />
                        )}
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="itemMake"
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="itemModel"
                        length="md"
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTYearSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="yearOfManufacture"
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="bodyStyleAttrId"
                        attributeType={AttributeTypeEnum.VEHICLE_BODY_STYLE.name}
                        filterOptions={bodyStyleFilterOptions}
                        length="md"
                    />
                </FormRow>
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="bodyStyleOther"
                    length="md"
                />
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="primaryColorAttrId"
                        attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="secondaryColorAttrId"
                        attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                        length="md"
                    />
                </FormRow>
            </MFTFieldset>
            <MFTFieldset path="attributeLinks">
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="vehicleAdditionalSecondaryColorAttrIds"
                        attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                        length="lg"
                        multiple
                    />
                </FormRow>
            </MFTFieldset>
            <MFTFieldset path="vehicle">
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="description"
                />
            </MFTFieldset>
        </SidePanelSection>
    );
};

export const VehicleDescriptionFieldset = withItemCategoryFilterOptions(
    globalAttributes.itemType.vehicle
)(BaseVehicleDescriptionFieldset);

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'rotation';

// Putting these endpoints in a resource separate from locationResource
// since they are only used by the admin page
export default createResource({
    name: 'Cad Rotation List Resource',
    methods: {
        getCadServiceTypes() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service_types/true`,
            });
        },
        getCadRotationsList() {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/lists`,
            });
        },
        getServiceProviders() {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/services`,
            });
        },
        createCadRotationsList(cadRotationsList) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/bundle`,
                data: cadRotationsList,
            });
        },
        createCadRotationsListEntry(entry) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/entries`,
                data: entry,
            });
        },

        loadCadRotationListHistory(cadRotationListId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service_list/${cadRotationListId}/histories`,
            });
        },
    },
});

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { HotKeys, GlobalHotKeys } from 'react-hotkeys';
import { get, mapValues, pickBy, isFunction, isArray, head, includes } from 'lodash';
import styled from 'styled-components';
import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';
import { noFocusOutline } from '../../styles/mixins';
import { routeNameSelector } from '../../../../routing/routerModule';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';

const _HotKeys = React.forwardRef((props, ref) => <HotKeys {...props} innerRef={ref} />);

const HotKeysNoOutline = styled(_HotKeys)`
    ${noFocusOutline}
`;

const ReactHotKeysWrapper = (props) => {
    const { applicationSettings, hotKeysConfig, children, isGlobal, routeName } = props;
    const { track } = useAnalytics();
    const configs = pickBy(hotKeysConfig, (config) => isFunction(get(config, 'handler')));

    /**
     * Memoize keyMap/handlers to ensure performance when enabling allowChanges
     * for the react-hotkeys components -- we only want these to be recomputed
     * when necessary
     */
    const keyMap = React.useMemo(
        () =>
            mapValues(configs, (config, configName) =>
                get(hotkeysActionEnum[configName], 'keyBinding')
            ),
        [configs]
    );

    const handlers = React.useMemo(
        () =>
            mapValues(configs, (config, eventName) => (...args) => {
                const handler = get(config, 'handler');
                const keyBinding = get(config, 'keyBinding');
                const enabledForRoutes = get(config, 'enabledForRoutes');
                const enabledForApplicationSetting = get(config, 'enabledForApplicationSetting');

                const isRouteEnabled = !enabledForRoutes || includes(enabledForRoutes, routeName);
                const isAppSettingEnabled =
                    !enabledForApplicationSetting ||
                    applicationSettings[enabledForApplicationSetting];

                /**
                 * Only execute shortcuts if they're in the allowed list of enabled routes.
                 * If enabledForRoutes isn't specified, assume it's enabled for all routes.
                 */
                if (isRouteEnabled && isAppSettingEnabled) {
                    if (handler) {
                        /**
                         * Always prevent the default event handling
                         */
                        const event = head(args);
                        if (event) {
                            event.preventDefault();
                        }
                        handler(...args);
                    }
                    track({
                        [AnalyticsPropertyEnum.KEYBOARD_SHORTCUT]: eventName,
                        [AnalyticsPropertyEnum.INTERACTION]: isArray(keyBinding)
                            ? head(keyBinding)
                            : keyBinding,
                    });
                }
            }),
        [configs, routeName, track, applicationSettings]
    );

    if (isGlobal) {
        /**
         * Setting allowChanges = true here to allow handlers to recalculate when necessary.
         * See https://github.com/greena13/react-hotkeys#allowing-hotkeys-and-handlers-props-to-change
         * for default behavior about when keyMap/handlers are grabbed by react-hotkeys.
         * We need to allow changes to facilitate handlers being updated when the routeName and other props
         * change
         */
        return (
            <GlobalHotKeys
                keyMap={keyMap}
                handlers={handlers}
                className="mark43-hotkeys-wrapper"
                allowChanges={true}
            />
        );
    }

    return (
        <HotKeysNoOutline
            keyMap={keyMap}
            handlers={handlers}
            children={children}
            className="mark43-hotkeys-wrapper"
        />
    );
};

const mapStateToProps = createStructuredSelector({
    applicationSettings: applicationSettingsSelector,
    routeName: routeNameSelector,
});

export default connect(mapStateToProps)(ReactHotKeysWrapper);

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'shortcut_keys';

export default createResource({
    name: 'Cad Command Line Resource',
    methods: {
        getShortcutKeys() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: BASE_PATH,
            });
        },
        upsertShortcutKeys(shorcutKeys) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: BASE_PATH,
                data: shorcutKeys,
            });
        },
        removeShortcutKey(id) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/delete`,
                data: [id],
            });
        },
    },
});

import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import testIds from '../../../core/testIds';

const SimpleLoadingContainer = styled.div<{ float?: string }>`
    float: ${(props) => props.float || 'left'};
    width: 100%;

    div {
        margin: 50px auto;
    }
`;

export const SimpleLoading: React.FC<{ className?: string; float?: string }> = ({
    className,
    float,
}) => {
    return (
        <SimpleLoadingContainer float={float} className={className}>
            <div className="loading-whitebg" />
        </SimpleLoadingContainer>
    );
};

export const FloatLoadingGray: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <div className={classNames('float-loading', className)} data-test-id={testIds.LOADING_ICON}>
            <div className="loading-whitebg loading-small" />
        </div>
    );
};

const LoadingAnimation = styled.div`
    position: absolute;
    top: 5px;

    && {
        right: 15px;
        height: 20px;
        width: 20px;
    }
`;

/**
 * Small loading spinner to be placed at the right side of inputs (dropdowns,
 *   text inputs).
 */
export const InputLoading: React.FC<{ className?: string }> = ({ className }) => {
    return <LoadingAnimation className={classNames('loading-whitebg', className)} />;
};

import Promise from 'bluebird';

import { normalize } from '~/client-common/helpers/dataHelpers';
import stringsConfig from '~/client-common/core/strings';
import { Mark43Error } from '../../lib/errors';

import shapefilesAdminResource from '../resources/shapefilesAdminResource';
import { shapefileByIdSelector } from '../selectors/shapefilesAdminSelectors';
import shapefilesActionTypes from './types/shapefilesAdminActionTypes';

const strings = stringsConfig.components.admin.shapefiles;

function pageLoadStart() {
    return {
        type: shapefilesActionTypes.PAGE_LOAD_START,
    };
}

function pageLoadSuccess(shapefiles) {
    return {
        type: shapefilesActionTypes.PAGE_LOAD_SUCCESS,
        payload: normalize(shapefiles),
    };
}

function pageLoadError(err) {
    return {
        type: shapefilesActionTypes.PAGE_LOAD_ERROR,
        error: true,
        payload: err,
    };
}

export function pageLoad() {
    return function (dispatch) {
        dispatch(pageLoadStart());
        shapefilesAdminResource
            .getShapefiles()
            .then((shapefiles) => {
                dispatch(pageLoadSuccess(shapefiles));
            })
            .catch(() => {
                dispatch(pageLoadError());
            });
    };
}

function selectShapefileStart(id) {
    return {
        type: shapefilesActionTypes.SELECT_SHAPEFILE_START,
        payload: id,
    };
}

function selectShapefileSuccess(shapefile) {
    return {
        type: shapefilesActionTypes.SELECT_SHAPEFILE_SUCCESS,
        payload: shapefile,
    };
}

function selectShapefileError(err) {
    return {
        type: shapefilesActionTypes.SELECT_SHAPEFILE_FAILURE,
        error: true,
        payload: err,
    };
}

export function selectShapefile(id) {
    return function (dispatch, getState) {
        dispatch(selectShapefileStart(id));
        const state = getState();
        const shapefile = shapefileByIdSelector(state)(id);

        if (!shapefile) {
            const err = new Mark43Error(strings.selectShapefileError);
            return Promise.resolve(dispatch(selectShapefileError(err)));
        }

        return shapefilesAdminResource
            .getGeojsonFile(shapefile)
            .then((file) => {
                const newShapefile = {
                    ...shapefile,
                    geojsonFilePath: file.fileWebServerPath,
                };
                dispatch(selectShapefileSuccess(newShapefile));
            })
            .catch(() => {
                dispatch(pageLoadError());
            });
    };
}

function saveShapefileStart(shapefile) {
    return {
        type: shapefilesActionTypes.SAVE_SHAPEFILE_START,
        payload: shapefile,
    };
}

function saveShapefileSuccess(shapefile) {
    return {
        type: shapefilesActionTypes.SAVE_SHAPEFILE_SUCCESS,
        payload: shapefile,
    };
}

function saveShapefileError(err) {
    return {
        type: shapefilesActionTypes.SAVE_SHAPEFILE_FAILURE,
        error: true,
        payload: err,
    };
}

export function saveShapefile(shapefile) {
    return function (dispatch) {
        dispatch(saveShapefileStart(shapefile));

        return shapefilesAdminResource
            .updateShapefile(shapefile)
            .then((shapefile) => dispatch(saveShapefileSuccess(shapefile)))
            .catch((err) => dispatch(saveShapefileError(err)));
    };
}

function deleteShapefileStart(shapefile) {
    return {
        type: shapefilesActionTypes.DELETE_SHAPEFILE_START,
        payload: shapefile,
    };
}

function deleteShapefileSuccess(shapefile) {
    return {
        type: shapefilesActionTypes.DELETE_SHAPEFILE_SUCCESS,
        payload: shapefile,
    };
}

function deleteShapefileFailure(err) {
    return {
        type: shapefilesActionTypes.DELETE_SHAPEFILE_FAILURE,
        error: true,
        payload: err,
    };
}

export function deleteShapefile(shapefile) {
    return function (dispatch) {
        dispatch(deleteShapefileStart(shapefile));

        return shapefilesAdminResource
            .deleteShapefile(shapefile)
            .then(() => dispatch(deleteShapefileSuccess(shapefile)))
            .catch((err) => dispatch(deleteShapefileFailure(err)));
    };
}

function getGeojsonFileStart(shapefile) {
    return {
        type: shapefilesActionTypes.DOWNLOAD_SHAPEFILE_START,
        payload: shapefile,
    };
}

function getGeojsonFileSuccess(shapefile) {
    return {
        type: shapefilesActionTypes.DOWNLOAD_SHAPEFILE_SUCCESS,
        payload: shapefile,
    };
}

function getGeojsonFileFailure(err) {
    return {
        type: shapefilesActionTypes.DOWNLOAD_SHAPEFILE_FAILURE,
        error: true,
        payload: err,
    };
}

export function getGeojsonFile(shapefile) {
    return function (dispatch) {
        dispatch(getGeojsonFileStart(shapefile));

        return shapefilesAdminResource
            .getGeojsonFile(shapefile)
            .then((file) => {
                window.open(file.fileWebServerPath, '_blank');
                dispatch(getGeojsonFileSuccess(shapefile));
            })
            .catch((err) => dispatch(getGeojsonFileFailure(err)));
    };
}

export function cancelShapefileActions() {
    return {
        type: shapefilesActionTypes.CANCEL_SHAPEFILE_ACTIONS,
    };
}

function createShapefileStart(fileId) {
    return {
        type: shapefilesActionTypes.CREATE_SHAPEFILE_START,
        payload: fileId,
    };
}

function createShapefileSuccess(shapefile) {
    return {
        type: shapefilesActionTypes.CREATE_SHAPEFILE_SUCCESS,
        payload: shapefile,
    };
}

function createShapefileError(err) {
    return {
        type: shapefilesActionTypes.CREATE_SHAPEFILE_FAILURE,
        error: true,
        payload: err,
    };
}

export function createShapefile(fileResponse) {
    return function (dispatch) {
        const fileId = fileResponse[0].file.id;
        dispatch(createShapefileStart(fileId));

        return shapefilesAdminResource
            .createShapefile(fileId)
            .then((shapefile) => {
                dispatch(createShapefileSuccess(shapefile));
                dispatch(selectShapefile(shapefile.id));
            })
            .catch((err) => dispatch(createShapefileError(err)));
    };
}

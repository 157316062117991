import { CoreModelEnum, ValueTypeEnum } from '@mark43/rms-api';
import type { searchableConfiguredPropertyOptionViewByConfiguredEntityKeyNameSelector } from '~/client-common/core/domain/dragon-rms-integration/state/data/searchable-configured-property-option-views';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import {
    protectedEntityOptionDisplayViewByIdSelector,
    protectedEntityOptionDisplayViewWhereSelector,
} from '~/client-common/core/domain/dragon-rms-integration/state/data/protected-option-display-views';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import { formatRangeFieldValue, formatSimpleRange } from '~/client-common/helpers/rangeHelpers';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { ReportConfiguredEntityProperty } from '../../components/dragon/types';
import { parseConfiguredEntityPropertyOptionValue } from './optionValue';
import { isNumericRangeQuery } from './isNumericRangeQuery';

const { RANGE, USER } = fieldTypeClientEnum;
const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;

export function formatDragonReportFieldsFilterGroups(
    reportConfiguredEntityProperties: Required<ReportConfiguredEntityProperty>[] | undefined,
    selectors: {
        searchableConfiguredPropertyOptionViewByConfiguredEntityKeyName: ReturnType<
            typeof searchableConfiguredPropertyOptionViewByConfiguredEntityKeyNameSelector
        >;
        protectedEntityOptionDisplayViewById: ReturnType<
            typeof protectedEntityOptionDisplayViewByIdSelector
        >;
        protectedEntityOptionDisplayViewWhere: ReturnType<
            typeof protectedEntityOptionDisplayViewWhereSelector
        >;
        dateTimeFormatter: ReturnType<typeof currentDepartmentDateFormatterSelector>;
        formatFieldValue: (input: unknown, config: unknown) => string;
    }
): string {
    return joinTruthyValues(
        (reportConfiguredEntityProperties ?? []).map(
            ({ configuredEntityPropertyDescriptor, configuredEntityPropertyInputValue }) => {
                const { configuredEntityKeyName, configuredPropertyKeyName, valueType } =
                    parseConfiguredEntityPropertyOptionValue(configuredEntityPropertyDescriptor);

                const propertyOptions =
                    selectors.searchableConfiguredPropertyOptionViewByConfiguredEntityKeyName(
                        configuredEntityKeyName
                    );
                const propertyOptionView = propertyOptions.find((x) => {
                    return x.configuredPropertyKeyName === configuredPropertyKeyName;
                });

                if (!propertyOptionView) {
                    return undefined;
                }

                let valueDisplay: string | undefined = '';

                if (valueType === ValueTypeEnum.DATETIME.name) {
                    valueDisplay = configuredEntityPropertyInputValue
                        ? formatRangeFieldValue(
                              configuredEntityPropertyInputValue,
                              [
                                  {
                                      key: 'withinLastPeriod',
                                      type: RANGE,
                                      rangeKey: DATE_TIME_RANGE,
                                      rangeType: WITHIN_LAST_PERIOD,
                                  },
                                  {
                                      key: 'toDatePeriod',
                                      type: RANGE,
                                      rangeKey: DATE_TIME_RANGE,
                                      rangeType: TO_DATE_PERIOD,
                                  },
                                  {
                                      key: 'startDateUtc',
                                      type: RANGE,
                                      rangeKey: DATE_TIME_RANGE,
                                      rangeType: RANGE_START,
                                  },
                                  {
                                      key: 'endDateUtc',
                                      type: RANGE,
                                      rangeKey: DATE_TIME_RANGE,
                                      rangeType: RANGE_END,
                                  },
                              ],
                              selectors.dateTimeFormatter
                          )
                        : '';
                } else if (
                    valueType === ValueTypeEnum.DECIMAL.name ||
                    valueType === ValueTypeEnum.BIGINT.name
                ) {
                    valueDisplay = isNumericRangeQuery(configuredEntityPropertyInputValue)
                        ? formatSimpleRange({
                              // we rely on the fact that `NaN` is not truthy, which will exclude
                              // either start or end from formatting if no valid value is provided
                              rangeEnd: Number(configuredEntityPropertyInputValue.max),
                              rangeStart: Number(configuredEntityPropertyInputValue.min),
                          })
                        : String(configuredEntityPropertyInputValue);
                } else if (
                    (valueType === ValueTypeEnum.ID.name &&
                        typeof configuredEntityPropertyInputValue === 'string') ||
                    typeof configuredEntityPropertyInputValue === 'number'
                ) {
                    valueDisplay =
                        propertyOptionView.associatedForeignKeyCoreModelKeyName ===
                        CoreModelEnum.USER_PROFILE.keyName
                            ? selectors.formatFieldValue(configuredEntityPropertyInputValue, {
                                  type: USER,
                              })
                            : selectors.protectedEntityOptionDisplayViewById(
                                  configuredEntityPropertyInputValue
                              )?.displayValue;
                } else if (valueType === ValueTypeEnum.BOOLEAN.name) {
                    valueDisplay = selectors.protectedEntityOptionDisplayViewWhere({
                        value: String(configuredEntityPropertyInputValue),
                    })?.[0]?.displayValue;
                } else if (configuredEntityPropertyInputValue) {
                    valueDisplay = String(configuredEntityPropertyInputValue);
                }

                return joinTruthyValues([propertyOptionView.display, valueDisplay], ': ');
            }
        ),
        ', '
    );
}

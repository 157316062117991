import React from 'react';
import styled from 'styled-components';
import { Flex, isOverflowHorizontal, Text as ArcText } from 'arc';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { ConditionalTooltip } from '../../../../../../core/components/tooltip';
import { truncateText } from '../../../../../../core/styles/mixins';

// Types
import type { ManageEvidenceViewModel } from '../../../../types';

const TruncatedLocation = styled(ArcText)`
    ${truncateText}
`;

const EvidenceLocationCell = ({ evidenceItem }: { evidenceItem: ManageEvidenceViewModel }) => {
    const {
        eventType,
        storageLocationNames,
        facility,
        receivedByEntityName,
    } = getViewModelProperties(evidenceItem);

    const titleRef = React.useRef(null);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const location =
        storageLocationNames ||
        facility ||
        (evidenceItem.currentChainEvent &&
            evidenceItem.currentChainEvent[0] &&
            evidenceItem.currentChainEvent[0].receivedByName) ||
        receivedByEntityName;

    return (
        <Flex flexDirection="column" maxWidth="400px" minWidth="200px">
            <ArcText fontWeight="semibold">{eventType}</ArcText>
            <ConditionalTooltip condition={showTooltip} content={location}>
                <TruncatedLocation
                    ref={titleRef}
                    onMouseLeave={() => setShowTooltip(false)}
                    onMouseEnter={() =>
                        titleRef?.current && setShowTooltip(isOverflowHorizontal(titleRef.current))
                    }
                >
                    {location}
                </TruncatedLocation>
            </ConditionalTooltip>
        </Flex>
    );
};

export { EvidenceLocationCell };

import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { pure } from 'recompose';
import { EntityTypeEnum } from '@mark43/rms-api';

import { useSelector } from 'react-redux';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { formatTitleForVehicleSelector } from '~/client-common/core/domain/vehicles/state/ui';

import Link from '../../core/components/links/Link';
import Icon, { iconTypes } from '../../../legacy-redux/components/core/Icon';
import MergeEntitiesText from './core/MergeEntitiesText';
import Column from './core/Column';
import HeaderColumn from './HeaderColumn';
import FlexContainer from './core/FlexContainer';

const { PERSON_PROFILE, ORGANIZATION_PROFILE, VEHICLE } = EntityTypeEnum;

const ProfileHeaderRowContainer = styled.div`
    background: var(--arc-colors-surface-accent);
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
`;

const ProfileHeaderRow = ({ models, entityType }) => {
    const formatTitleForVehicle = useSelector(formatTitleForVehicleSelector);
    return (
        <ProfileHeaderRowContainer>
            <FlexContainer overflowX="visible" overflowY="visible">
                {map(models, (model, i) => {
                    let link = '';
                    let nameInHeader = '';
                    switch (entityType) {
                        case PERSON_PROFILE.name:
                            link = `/profiles/persons/${model.id}`;
                            nameInHeader = formatFullName(model);
                            break;
                        case ORGANIZATION_PROFILE.name:
                            link = `/profiles/organizations/${model.id}`;
                            nameInHeader = model.name;
                            break;
                        case VEHICLE.name:
                            link = `/profiles/vehicles/${model.id}`;
                            nameInHeader = formatTitleForVehicle(model);
                            break;
                        default:
                            throw new Error(
                                'Unsupported merging type, not of type Persons, Organizations, or Vehicles'
                            );
                    }

                    return (
                        <HeaderColumn
                            key={model.id}
                            direction="column"
                            last={i === models.length - 1}
                        >
                            <Link to={link} openInNewTab={true}>
                                <MergeEntitiesText
                                    fontWeight="bold"
                                    color="var(--arc-colors-brand-default)"
                                >
                                    {nameInHeader} <Icon className={iconTypes.POPOUT} width="1em" />
                                </MergeEntitiesText>
                            </Link>
                            <FlexContainer
                                flexDirection="row"
                                flexBasis="auto"
                                width="auto"
                                justify="flex-start"
                                overflowY="hidden"
                                overflowX="hidden"
                            >
                                <Column width="30%" padding="10px 0 0">
                                    <MergeEntitiesText color="var(--arc-colors-text-tertiary)">
                                        {componentStrings.mergeEntities.generic.lastModified}{' '}
                                    </MergeEntitiesText>
                                </Column>
                                <Column width="70%" padding="10px 0 0">
                                    <MergeEntitiesText color="var(--arc-colors-text-tertiary)">
                                        <FormattedDate
                                            format={FormattedDate.FORMATS.FORM_DATE}
                                            date={model.updatedDateUtc}
                                        />
                                    </MergeEntitiesText>
                                </Column>
                            </FlexContainer>
                        </HeaderColumn>
                    );
                })}
            </FlexContainer>
        </ProfileHeaderRowContainer>
    );
};

export default pure(ProfileHeaderRow);

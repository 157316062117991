import {
    EntityTypeEnum,
    ProductModuleEnum,
    AttributeTypeEnum,
    LinkTypesEnum,
    RefContextEnum,
} from '@mark43/rms-api';
import { map, chain, filter, pick, reject, size, find, get, flow, includes, mapKeys } from 'lodash';
import Promise from 'bluebird';
import { getAttributesByIdsSelector } from '~/client-common/core/domain/attributes/state/data';
import { replaceLocationEntityLinksForEntityAndLinkTypes } from '~/client-common/core/domain/location-entity-links/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import { isStorageLocationEditableSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { elasticStorageLocationsSelector } from '~/client-common/core/domain/elastic-storage-locations/state/data';
import { firearmsSelector } from '~/client-common/core/domain/firearms/state/data';
import {
    deleteItemFacilityLinks,
    itemFacilityLinksSelector,
    replaceItemFacilityLinks,
    upsertItemFacilityLinks,
} from '~/client-common/core/domain/item-facility-links/state/data';
import { itemIdentifiersByItemIdSelector } from '~/client-common/core/domain/item-identifiers/state/data';
import {
    itemProfilesSelector,
    hydratedItemByIdSelector,
    saveHydratedItem,
    loadHydratedItem,
} from '~/client-common/core/domain/item-profiles/state/data';
import { propertyStatusesByItemProfileIdSelector } from '~/client-common/core/domain/property-statuses/state/data';
import { offensesByReportIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { vehiclesSelector } from '~/client-common/core/domain/vehicles/state/data';
import { sortPropertyStatuses } from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import { sanitizeModelFromOtherDepartmentDeep } from '~/client-common/helpers/multiAgencyHelpers';
import { loadVehicleModelDetails } from '~/client-common/core/domain/vehicle-models/state/data';
import { buildVehicleModelRelated } from '~/client-common/core/domain/vehicles/utils/vehicleHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getEarliestDate } from '~/client-common/core/dates/utils/dateHelpers';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import { loadAttributesForType } from '../../../../core/attributes/state/ui/loadAttributesForType';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    openBox,
    closeBox,
    saveBoxFailure,
    storePayloadToBox,
} from '../../../../../legacy-redux/actions/boxActions';

import { createModalSelector } from '../../../../core/box/state/ui';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import {
    getHydratedItemForm,
    convertToFormModel,
    convertFromFormModelToHydratedItem,
    convertFromFormModelToItemFacilityLinks,
    convertHydratedItemToDuplicate,
    convertFromFormModelToAllLocationEntityLinks,
} from '../forms/hydratedItemForm';

import { Mark43Error } from '../../../../../lib/errors';
import { initialVehicleModelRelated } from '../../../../core/forms/components/selects/VehicleModelSelect';
import { logWarning } from '../../../../../core/logging';
import { getItemTypeForItemEvent } from '../../../../dragon/events/get-item-type-for-item-event';
/**
 * `LinkTypes` that are valid for the `LocationEntityLinks`s in an Hydrated Item data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const HYDRATED_ITEM_LOCATION_LINK_TYPES = [
    LinkTypesEnum.PROPERTY_RECOVERED_LOCATION,
    LinkTypesEnum.VEHICLE_ABANDONED_LOCATION,
    LinkTypesEnum.VEHICLE_BURNED_LOCATION,
    LinkTypesEnum.VEHICLE_REGISTERED_LOCATION,
    LinkTypesEnum.VEHICLE_SEEN_LOCATION,
    LinkTypesEnum.VEHICLE_STOLEN_FROM_LOCATION,
];

const context = { name: boxEnum.ITEM_SIDE_PANEL };
const { CREATE, EDIT, DUPLICATE } = itemSidePanelOperationEnum;
const strings = componentStrings.reports.core.ItemSidePanel;

/**
 * Open a side panel to add, edit, or duplicate a single property or vehicle. For edit or duplicate,
 *   fill in the form.
 * @param {number}  [options.itemProfileId] Required when operation is EDIT or DUPLICATE.
 * @param {boolean} options.isInPoliceCustodyDisabled
 * @param {boolean} options.isStorageLocationIdDisabled
 * @param {boolean} [options.itemTypeAttrId] Required when operation is CREATE.
 * @param {boolean} options.isVehicle
 * @param {string}  options.operation       Value from itemSidePanelOperationEnum.
 * @param {number}  options.ownerId
 * @param {string}  options.ownerType
 * @param {object}  options.defaultValues
 */
export const openItemSidePanel = ({
    itemProfileId,
    isInPoliceCustodyDisabled = false,
    isStorageLocationIdDisabled = false,
    itemTypeAttrId,
    isVehicle,
    operation,
    ownerId,
    ownerType,
    reportingEventNumber,
    isFormHidden,
    isAutosearch = false,
    query,
    defaultValues,
    dexData,
}) => (dispatch, getState) => {
    if (isVehicle) {
        // the Body Style dropdown is hidden whenever there are no Body Style options
        // there are two sources for Body Style options.
        // 1. attributes stored in state, so we load them first in order for that logic to work
        // 2. vehicle make model - body style attributes linked to vehicle make through code links.
        //     presence of those options is stored in vehicleModelHasBodyStyleOptions
        dispatch(
            loadAttributesForType({
                attributeType: [AttributeTypeEnum.VEHICLE_BODY_STYLE.name],
            })
        );
    }

    dispatch(
        loadAttributesForType({
            attributeType: [
                AttributeTypeEnum.NAME_ITEM_ASSOCIATION.name,
                AttributeTypeEnum.PROOF_OF_OWNERSHIP.name,
            ],
        })
    );

    const state = getState();
    const currentReport = currentReportSelector(state);
    const offenses = offensesByReportIdSelector(state)(currentReport?.id);
    let offenseDate = offenses
        ? getEarliestDate(
              offenses
                  .map((o) => o.offenseDateUtc)
                  .filter((date) => date !== undefined && date !== null)
          )
        : undefined;

    if (!offenseDate) {
        const eventDetails = eventDetailByReportIdSelector(state)(currentReport?.id);
        const eventStartDate = eventDetails ? eventDetails.eventStartUtc : undefined;
        offenseDate =
            eventStartDate !== undefined && eventStartDate < currentReport?.createdDateUtc
                ? eventStartDate
                : currentReport?.createdDateUtc;
    }

    if (itemProfileId && (operation === EDIT || operation === DUPLICATE)) {
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_HYDRATED_ITEM.name,
            undefined,
            (form) => {
                const state = getState();
                let hydratedItem = hydratedItemByIdSelector(state)(itemProfileId);
                if (operation === DUPLICATE) {
                    hydratedItem = convertHydratedItemToDuplicate(hydratedItem);
                }
                const itemFacilityLinks = filter(itemFacilityLinksSelector(state), {
                    itemProfileId,
                });
                const formModel = convertToFormModel(hydratedItem, itemFacilityLinks, operation);
                form.set('', formModel);
                form.set('offenseDateUtc', offenseDate);

                if (!reportingEventNumber) {
                    form.set('isInPoliceCustody', false);
                }

                const vehicleModelId = get(hydratedItem, 'vehicles[0].vehicleModelId');
                if (isVehicle && vehicleModelId) {
                    dispatch(loadVehicleModelDetails([vehicleModelId])).then((result) => {
                        const vehicleModelRelated = buildVehicleModelRelated(result);

                        form.set(
                            'vehicle.vehicleModelHasBodyStyleOptions',
                            vehicleModelRelated.bodyStyleAttrIds.length > 0
                        );

                        dispatch(storeItemSidePanelVehicleModelRelated(vehicleModelRelated));
                    });
                }
            }
        );
    } else if (operation === CREATE) {
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_HYDRATED_ITEM.name,
            undefined,
            (form) => {
                form.resetModel();
                form.set('offenseDateUtc', offenseDate);
                form.set('itemTypeAttrId', itemTypeAttrId);
                if (!reportingEventNumber) {
                    form.set('isInPoliceCustody', false);
                }

                form.set('vehicle.vehicleModelHasBodyStyleOptions', false);
            }
        );
    }

    if (defaultValues) {
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_HYDRATED_ITEM.name,
            undefined,
            (form) => {
                Object.entries(defaultValues).forEach(([key, value]) => {
                    form.set(key, value);
                });
            }
        );
    }

    dispatch(
        openBox(context, {
            itemProfileId,
            isInPoliceCustodyDisabled,
            isStorageLocationIdDisabled,
            isVehicle,
            operation,
            ownerId,
            ownerType,
            masterItemId: undefined,
            reportingEventNumber,
            vehicleModelRelated: initialVehicleModelRelated,
            isFormHidden,
            isAutosearch,
            query,
            dexData,
        })
    );
};

/**
 * Close side to panel
 */
export const closeItemSidePanel = () => (dispatch) => dispatch(closeBox(context));

export function storeItemSidePanelMasterItemId(masterItemId) {
    return storePayloadToBox(context, { masterItemId });
}

export function storeItemSidePanelVehicleModelRelated(vehicleModelRelated) {
    return storePayloadToBox(context, { vehicleModelRelated });
}

export function storeItemSidePanelIsFormHidden(isFormHidden) {
    return storePayloadToBox(context, { isFormHidden });
}

export function storeItemSidePanelIsAutosearch(isAutosearch) {
    return storePayloadToBox(context, { isAutosearch });
}

export const itemSidePanelQuerySelector = createModalSelector(context, 'query');

export const itemSidePanelItemProfileIdSelector = createModalSelector(context, 'itemProfileId');
export const itemSidePanelIsInPoliceCustodyDisabledSelector = createModalSelector(
    context,
    'isInPoliceCustodyDisabled'
);
export const itemSidePanelIsStorageLocationIdDisabledSelector = createModalSelector(
    context,
    'isStorageLocationIdDisabled'
);
export const itemSidePanelIsVehicleSelector = createModalSelector(context, 'isVehicle');
export const itemSidePanelOperationSelector = createModalSelector(context, 'operation');
export const itemSidePanelOwnerIdSelector = createModalSelector(context, 'ownerId');
export const itemSidePanelOwnerTypeSelector = createModalSelector(context, 'ownerType');
export const itemSidePanelReportingEventNumberSelector = createModalSelector(
    context,
    'reportingEventNumber'
);
export const itemSidePanelIsFormHiddenSelector = createModalSelector(context, 'isFormHidden');
export const itemSidePanelIsAutosearchSelector = createModalSelector(context, 'isAutosearch');
// not related to itemSidePanelItemProfileId.  masterItemId only populated by inline entity search.
const itemSidePanelMasterItemIdSelector = createModalSelector(context, 'masterItemId');
export const itemSidePanelVehicleModelRelatedSelector = createModalSelector(
    context,
    'vehicleModelRelated'
);
export const itemSidePanelDexDataSelector = createModalSelector(context, 'dexData');
const SAVE_HYDRATED_ITEM_ERROR_MESSAGE = 'Failed to save item.';

export function validateHydratedItemForm() {
    const form = getHydratedItemForm();

    return function (dispatch) {
        return form.submit().catch((result) => {
            dispatch(saveBoxFailure(context, get(result, 'validationResult.rawErrors')));
        });
    };
}
/**
 * Submit the Item Side Panel to create or update a `RmsHydratedItem`.
 *
 * Conditionally upsert or delete `ItemFacilityLink`s if the item is in police custody and the
 *   Evidence module is enabled.
 * @return {Promise}
 */
export function submitHydratedItemForm() {
    const form = getHydratedItemForm();

    return function (dispatch, getState, dependencies) {
        return form
            .submit()
            .then(({ form }) => {
                let state = getState();

                const formModel = form.getState().model;
                const { itemTypeAttrId, attributeLinks } = formModel;
                const applicationSettings = applicationSettingsSelector(state);
                const getAttributesByIds = getAttributesByIdsSelector(state);
                const operation = itemSidePanelOperationSelector(state);
                const itemProfileId =
                    operation === EDIT ? itemSidePanelItemProfileIdSelector(state) : undefined;
                const itemProfile = itemProfilesSelector(state)[itemProfileId];

                // use masterItemId from existing profile (edit)
                // or if inline entity search get itemSidePanelMasterItemId (create from existing)
                // otherwise undefined (create new or duplicate)
                const masterItemId =
                    get(itemProfile, 'masterItemId') || itemSidePanelMasterItemIdSelector(state);

                const ownerId = itemSidePanelOwnerIdSelector(state);

                const firearm =
                    itemTypeAttrId === globalAttributes.itemType.firearm
                        ? firearmsSelector(state)[itemProfileId]
                        : undefined;

                const hydratedItem = convertFromFormModelToHydratedItem(formModel, {
                    itemProfileId,
                    masterItemId,
                    ownerType: itemSidePanelOwnerTypeSelector(state),
                    ownerId,
                    itemProfile,
                    firearm,
                    vehicle:
                        itemTypeAttrId === globalAttributes.itemType.vehicle
                            ? vehiclesSelector(state)[itemProfileId]
                            : undefined,
                    itemIdentifiers: itemIdentifiersByItemIdSelector(state)(itemProfileId),
                    locationEntityLink: undefined, // TODO KRA-2238
                    propertyStatuses: propertyStatusesByItemProfileIdSelector(state)(itemProfileId),
                    vehicleCautionAttributes: applicationSettings.RMS_VEHICLE_CAUTIONS_ENABLED
                        ? getAttributesByIds(attributeLinks.vehicleCautionAttrIds)
                        : [],
                });

                return dispatch(saveHydratedItem(hydratedItem))
                    .catch((err) => {
                        throw new Mark43Error(`${SAVE_HYDRATED_ITEM_ERROR_MESSAGE} ${err.message}`);
                    })
                    .then((hydratedItem) => {
                        const newItemProfileId = get(hydratedItem, 'item.id');
                        dispatch(
                            replaceLocationEntityLinksForEntityAndLinkTypes({
                                entityType: EntityTypeEnum.ITEM_PROFILE.name,
                                entityId: newItemProfileId,
                                linkTypes: HYDRATED_ITEM_LOCATION_LINK_TYPES,
                                // We need to merge the newItemProfileId
                                // because up until now, we didn't have it
                                locationEntityLinks: map(
                                    convertFromFormModelToAllLocationEntityLinks(formModel),
                                    (locationEntityLink) => ({
                                        ...locationEntityLink,
                                        entityId: newItemProfileId,
                                    })
                                ),
                            })
                        )
                            .then(() => hydratedItem)
                            .catch(() => {
                                throw new Mark43Error('Failed to save recovered location');
                            });

                        return hydratedItem;
                    })
                    .then((hydratedItem) => {
                        dependencies.dragonEvents.publish({
                            type: 'ITEMS_MODIFIED',
                            payload: {
                                itemType: getItemTypeForItemEvent(itemTypeAttrId),
                                itemIds: [get(hydratedItem, 'item.id')],
                            },
                        });
                        return hydratedItem;
                    })
                    .then((hydratedItem) => {
                        // make any Evidence API requests
                        state = getState();

                        if (
                            !isProductModuleActiveSelector(state)(
                                ProductModuleEnum.EVIDENCE.name
                            ) ||
                            !currentUserHasAbilitySelector(state)(
                                abilitiesEnum.EVIDENCE.EDIT_REPORTING
                            )
                        ) {
                            return;
                        }

                        const newItemProfileId = get(hydratedItem, 'item.id');
                        const newMasterItemId = get(hydratedItem, 'item.masterItemId');

                        if (!isStorageLocationEditableSelector(state)(newMasterItemId)) {
                            return;
                        }

                        const existingItemFacilityLinks = filter(itemFacilityLinksSelector(state), {
                            itemProfileId: newItemProfileId,
                        });

                        if (
                            !get(formModel, 'isInPoliceCustody') &&
                            size(existingItemFacilityLinks)
                        ) {
                            return dispatch(
                                deleteItemFacilityLinks(existingItemFacilityLinks)
                            ).catch((err) => {
                                throw new Mark43Error(
                                    `${strings.deleteItemFacilityLinkErrorMessage} ${err.message}`
                                );
                            });
                        }

                        return chain(state)
                            .thru(elasticStorageLocationsSelector)
                            .get(`${get(formModel.custodyStatus, 'storageLocationId')}.facilityId`)
                            .thru((facilityId) =>
                                convertFromFormModelToItemFacilityLinks(formModel, {
                                    facilityId,
                                    itemFacilityLinks: existingItemFacilityLinks,
                                    itemProfileId: newItemProfileId,
                                    propertyStatusIds: chain(hydratedItem)
                                        .get('propertyStatuses')
                                        .thru(sortPropertyStatuses)
                                        .map('id')
                                        .value(),
                                })
                            )
                            .thru((itemFacilityLinks) => {
                                if (
                                    existingItemFacilityLinks.length > 0 &&
                                    itemFacilityLinks.length === 0
                                ) {
                                    return dispatch(
                                        deleteItemFacilityLinks(existingItemFacilityLinks)
                                    ).catch((err) => {
                                        throw new Mark43Error(
                                            `${strings.deleteItemFacilityLinkErrorMessage} ${err.message}`
                                        );
                                    });
                                } else if (
                                    existingItemFacilityLinks.length > 0 &&
                                    itemFacilityLinks.length > 0
                                ) {
                                    return dispatch(
                                        replaceItemFacilityLinks(itemFacilityLinks)
                                    ).catch((err) => {
                                        throw new Mark43Error(
                                            `${strings.saveItemFacilityLinkErrorMessage} ${err.message}`
                                        );
                                    });
                                } else if (
                                    existingItemFacilityLinks.length === 0 &&
                                    itemFacilityLinks.length > 0
                                ) {
                                    return dispatch(
                                        upsertItemFacilityLinks(itemFacilityLinks)
                                    ).catch((err) => {
                                        throw new Mark43Error(
                                            `${strings.saveItemFacilityLinkErrorMessage} ${err.message}`
                                        );
                                    });
                                } else {
                                    return Promise.resolve();
                                }
                            })
                            .value();
                    })
                    .then(() => {
                        dispatch(closeItemSidePanel());
                    })
                    .catch((err) => {
                        const errorMessage = err.message || err;
                        if (
                            includes(errorMessage, SAVE_HYDRATED_ITEM_ERROR_MESSAGE) ||
                            operation === EDIT
                        ) {
                            dispatch(saveBoxFailure(context, errorMessage));
                        } else {
                            // This is an error that happens after the API request to
                            // create/duplicate a hydrated item has succeeded, and a subsequent API
                            // request to create the locationEntityLink or itemFacilityLink has
                            // failed. We cannot keep the ItemSidePanel open because the new
                            // hydrated item has already been created, and clicking the save button
                            // again would create/duplicate yet another copy of that item.
                            dispatch(closeItemSidePanel());
                            // TODO is to show the error in the ManageItemsSidePanel instead, since
                            // that side panel remains open after the ItemSidePanel is closed.
                            logWarning(
                                'Intentionally closing Item Side Panel without showing server error',
                                {
                                    operation,
                                    itemTypeAttrId,
                                    ownerId,
                                    masterItemId,
                                    itemProfileId,
                                    errorMessage,
                                }
                            );
                        }
                    });
            })
            .catch((result) => {
                dispatch(saveBoxFailure(context, get(result, 'validationResult.rawErrors')));
            });
    };
}

/**
 * Load hydrated item from selected EntitySearch result and set hydratedItemForm.
 *   Report level and item identifier fields will persist in form.
 *   Item model details (including some fields in shared details) will be overwritten
 *   with data from hydrated item.
 *   NameItemLinks will be combined (form and hydrated item) and de-duped.
 * @param {number}   itemProfileId
 * @param {boolean}   itemInCurrentUserDepartment
 * @param {import('@mark43/rms-api').Vehicle}   dataToOverride
 */
export function setFormWithSearchResult(
    itemProfileId,
    itemInCurrentUserDepartment,
    dataToOverride = {}
) {
    return (dispatch) =>
        dispatch(loadHydratedItem(itemProfileId))
            .then((hydratedItem) => {
                const vehicleModelId = get(hydratedItem, 'vehicles[0].vehicleModelId');
                if (!vehicleModelId) {
                    return { hydratedItem };
                } else {
                    return dispatch(loadVehicleModelDetails([vehicleModelId])).then(
                        (vehicleModelDetails) => {
                            const vehicleModelRelated = buildVehicleModelRelated(
                                vehicleModelDetails
                            );
                            dispatch(storeItemSidePanelVehicleModelRelated(vehicleModelRelated));

                            return { hydratedItem, vehicleModelRelated };
                        }
                    );
                }
            })
            .then(({ hydratedItem, vehicleModelRelated }) => {
                setFormWithItem({
                    hydratedItem,
                    vehicleModelRelated,
                    itemInCurrentUserDepartment,
                    dataToOverride,
                });
            });
}

export function setFormWithCadVehicleSuggestion({ hydratedItem, itemInCurrentUserDepartment }) {
    const vehicleModelId = get(hydratedItem, 'vehicles[0].vehicleModelId');
    hydratedItem.vehicles[0].sourceCadVehicleProfileId = get(hydratedItem, 'vehicles[0].id');
    hydratedItem.items = hydratedItem.vehicles;
    if (!vehicleModelId) {
        setFormWithItem({ hydratedItem, itemInCurrentUserDepartment });
    } else {
        return (dispatch) => {
            dispatch(loadVehicleModelDetails([vehicleModelId])).then((vehicleModelDetails) => {
                const vehicleModelRelated = buildVehicleModelRelated(vehicleModelDetails);
                dispatch(storeItemSidePanelVehicleModelRelated(vehicleModelRelated));
                setFormWithItem({ hydratedItem, vehicleModelRelated, itemInCurrentUserDepartment });
            });
        };
    }
}

function setFormWithItem({
    hydratedItem,
    vehicleModelRelated,
    itemInCurrentUserDepartment,
    dataToOverride,
}) {
    const form = getHydratedItemForm();
    const formModel = form.getState().model;
    const hydratedItemAsFormModel = flow(convertToFormModel, (model) =>
        itemInCurrentUserDepartment ? model : sanitizeModelFromOtherDepartmentDeep(model)
    )(hydratedItem);

    const formNameItemLinks = get(formModel, 'nameItemLinks', []);

    /*
        hydratedItemAsFormModel.nameItemLinks: These are links between the
        master item profile and master name profile. Why can we submit these as is?
        Because hydrated Item endpoint will generate the contexted name profile and
        create NEW link to the newly generated contexted item profile.
    */
    const uniqueNameItemLinks = reject(hydratedItemAsFormModel.nameItemLinks, (nameItemLink) =>
        find(formNameItemLinks, nameItemLink)
    );

    const itemType = get(hydratedItem, 'items.[0].itemTypeAttrId');
    let itemTypeFieldset;
    switch (itemType) {
        case globalAttributes.itemType.vehicle:
            itemTypeFieldset = 'vehicle';
            hydratedItemAsFormModel.vehicle = {
                ...hydratedItemAsFormModel.vehicle,
                ...(dataToOverride?.vehicle || {}),
            };

            hydratedItemAsFormModel.vehicle.vehicleModelHasBodyStyleOptions =
                get(vehicleModelRelated, 'bodyStyleAttrIds.length') > 0;
            break;
        case globalAttributes.itemType.firearm:
            itemTypeFieldset = 'firearm';
            break;
        default:
            itemTypeFieldset = 'otherItem';
    }

    form.set('', {
        ...formModel,
        ...pick(hydratedItemAsFormModel, itemTypeFieldset),
        attributeLinks: {
            ...hydratedItemAsFormModel.attributeLinks,
        },
        cautions: {
            ...hydratedItemAsFormModel.cautions,
        },
        sharedItemDetails: {
            ...hydratedItemAsFormModel.sharedItemDetails,
            itemIdentifiers: get(formModel, 'sharedItemDetails.itemIdentifiers', []),
        },
        nameItemLinks: itemInCurrentUserDepartment
            ? [...uniqueNameItemLinks, ...formNameItemLinks]
            : formNameItemLinks,
    });
}

export const generateSidePanelFormDataFromRmsVehicle = (vehicle) => {
    const vehicleFieldsetData = pick(vehicle, [
        'id',
        'registrationYear',
        'yearOfManufacture',
        'tag',
        'vinNumber',
        'registrationType',
        'registrationStateAttrId',
        'vehicleMakeId',
        'vehicleModelId',
        'bodyStyleAttrId',
        'primaryColorAttrId',
    ]);
    const sharedItemDetailsFeidlsetData = pick(vehicle, ['ncicNumber']);

    const vehicleFieldset = mapKeys(vehicleFieldsetData, (_value, key) => `vehicle.${key}`);
    const sharedItemDetailsFeidlset = mapKeys(
        sharedItemDetailsFeidlsetData,
        (_value, key) => `sharedItemDetails.${key}`
    );

    return {
        ...vehicleFieldset,
        ...sharedItemDetailsFeidlset,
    };
};

import { ExternalReportStatus } from '@mark43/rms-api';
import { createFieldset, createField, InferFormDataShape } from 'markformythree';
import { pick, keys } from 'lodash';

import {
    EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID,
    EXTERNAL_REPORT_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
    EXTERNAL_REPORT_STATUS_CLOSED_BY_UNIT_ATTR_ID,
    EXTERNAL_REPORT_STATUS_STATUS_DATE_UTC,
} from '~/client-common/core/enums/universal/fields';

export const externalReportStatusConfiguration = createFieldset({
    fieldName: EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID,
    fields: {
        // These fields are not used for the form -- they are
        // just used in the `convertToFormModel` method
        id: createField<number>({}),
        reportingEventId: createField<number>({}),
        externalReportStatusAttrId: createField<number>({
            fieldName: EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID,
        }),
        closedByDivisionAttrId: createField<number>({
            fieldName: EXTERNAL_REPORT_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
        }),
        closedByUnitAttrId: createField<number>({
            fieldName: EXTERNAL_REPORT_STATUS_CLOSED_BY_UNIT_ATTR_ID,
        }),
        statusDateUtc: createField<string>({
            fieldName: EXTERNAL_REPORT_STATUS_STATUS_DATE_UTC,
        }),
    },
});

export const convertToExternalReportStatusFormModel = (
    externalReportStatus: ExternalReportStatus
) => {
    return pick(externalReportStatus, keys(externalReportStatusConfiguration.fields));
};

export const convertFromExternalReportStatusFormModel = (
    formExternalReportStatus: InferFormDataShape<typeof externalReportStatusConfiguration>,
    dataExternalReportStatus?: ExternalReportStatus
) => {
    return {
        ...(dataExternalReportStatus || {}),
        ...pick(formExternalReportStatus, keys(externalReportStatusConfiguration.fields)),
    };
};

import React, { FC, useContext } from 'react';
import { OperationTypeEnum } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { includes } from 'lodash';
import { currentUserIdSelector } from '../../../../../core/current-user/state/ui';
import { canEditTaskSelector, canDeleteTaskSelector } from '../../../state/ui';
import TaskPopOverMenu, { TaskPopOverMenuProps } from '../../TaskPopOverMenu';
import { TasksTableContext } from '../TasksTableContext';

type TaskActionsMenuProps = Pick<TaskPopOverMenuProps, 'task'>;

export const TaskActionsMenu: FC<TaskActionsMenuProps> = ({ task }) => {
    const { refreshTasks } = useContext(TasksTableContext);
    const currentUserId = useSelector(currentUserIdSelector);
    const hasEditAbility = useSelector(canEditTaskSelector);
    const hasDeleteAbility = useSelector(canDeleteTaskSelector);
    const canDelete =
        hasDeleteAbility ||
        (hasEditAbility &&
            includes(task.permissionSet, OperationTypeEnum.DELETE.name) &&
            task.createdBy === currentUserId);

    return <TaskPopOverMenu task={task} canDelete={canDelete} onDeleteTask={refreshTasks} />;
};

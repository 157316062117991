/* eslint-disable prefer-template */
import { LocationTypeEnum } from '@mark43/rms-api';
import React from 'react';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { forEach, find, compact } from 'lodash';
import { Text } from 'arc';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatLatLong } from '~/client-common/core/domain/locations/utils/locationHelpers';
import { formatStaticMapUrl } from '~/client-common/core/domain/elastic-involved-locations/state/ui';
import { formatSubPremise } from '~/client-common/core/domain/locations/utils/subPremiseHelpers';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { Map } from '~/client-common/core/maps';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import stringsConfig from '~/client-common/core/strings';
import { getDisplaySource } from '~/client-common/core/domain/locations/utils/locationSourceHelpers';
import countries from '../../configs/countries';
import { currentUserDepartmentProfileSelector } from '../../../modules/core/current-user/state/ui';
import VerifiedTag from '../../../modules/core/components/tags/VerifiedTag';
import Icon, { iconTypes } from '../core/Icon';
import SummaryList from './SummaryList';
import SummaryRow from './SummaryRow';

const strings = stringsConfig.components.summaries.LocationSummary;

/**
 * Summary view for a location by itself without an entity link - without
 *   subpremise or classifications. Unlike the legacy location summary view, the
 *   location's static map and lat/long coordinates are included. Prefer
 *   modules/records/core/components/summaries/LocationSummary over this.
 */

const combineParts = function () {
    let result = '';
    let isSeparator = false;
    let partWasPresent = false;
    forEach(arguments, (arg) => {
        if (!isSeparator && arg) {
            partWasPresent = true;
            result += arg;
        } else if (!isSeparator && !arg) {
            partWasPresent = false;
        } else {
            if (partWasPresent) {
                result += arg;
            }
        }
        isSeparator = !isSeparator;
    });
    return result;
};

const countryIfNotLocal = function (location, currentUserCountryCode) {
    let result = '';
    if (location.country !== currentUserCountryCode) {
        const country = find(countries, (c) => {
            return c.value === location.country;
        });
        if (country) {
            result = country.display;
        }
    }
    return result;
};

const getCrossStreetsDisplay = function (location) {
    if (location.crossStreet1 && location.crossStreet2) {
        return combineParts('B/T ' + location.crossStreet1, ' & ', location.crossStreet2);
    } else if (location.crossStreet1 || location.crossStreet2) {
        return combineParts('at ' + (location.crossStreet1 || location.crossStreet2));
    } else {
        return '';
    }
};

const formatAddressFromJson = function ({
    location,
    currentUserCountryCode = '',
    isSubPremiseSupportEnabled,
}) {
    const result = {
        placeName: '',
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        areCoordinatesVerified: location.areCoordinatesVerified,
        bold2: false,
        oneLiner: '',
        subPremise: '',
        source: '',
    };
    const locationEntityLink = location?.entityLinks?.[0] || {};
    const deprecatedSubPremise = location.subPremise || locationEntityLink.subPremise;
    result.placeName = location.placeName || locationEntityLink.placeName;

    if (isSubPremiseSupportEnabled) {
        result.subPremise =
            formatSubPremise(location) ||
            formatSubPremise(locationEntityLink) ||
            deprecatedSubPremise;
    } else {
        result.subPremise = deprecatedSubPremise;
    }
    result.source = isSubPremiseSupportEnabled
        ? getDisplaySource(location.resolverSource) ||
          getDisplaySource(locationEntityLink.resolverSource)
        : location.areCoordinatesVerified
        ? strings.verifiedAddress
        : '';

    switch (location.type) {
        case LocationTypeEnum.ADDRESS.name:
        case LocationTypeEnum.POINT.name:
            result.line1 = location.streetAddress;
            result.line2 = getCrossStreetsDisplay(location);
            result.line3 += combineParts(
                location.locality,
                ', ',
                location.adminArea1,
                ' ',
                location.postalCode
            );
            result.line4 += countryIfNotLocal(location, currentUserCountryCode);
            result.oneLiner = compact([
                result.placeName,
                result.line1,
                result.line2,
                result.line3,
                result.line4,
            ]).join(' | ');
            break;
        case LocationTypeEnum.INTERSECTION.name:
            result.line1 += combineParts(location.crossStreet1, ' &');
            result.line2 += location.crossStreet2;
            result.line3 += combineParts(
                location.locality,
                ', ',
                location.adminArea1,
                ' ',
                location.postalCode
            );
            result.line4 += countryIfNotLocal(location);
            result.bold2 = true;
            result.oneLiner = compact([
                result.placeName,
                result.line1 + ' ' + result.line2,
                result.line3,
                result.line4,
            ]).join(' | ');
            break;
        case LocationTypeEnum.RANGE.name:
            result.line1 = combineParts(location.rangeName, ' ', location.streetAddress);
            result.line2 = getCrossStreetsDisplay(location);
            result.line3 += combineParts(
                location.locality,
                ', ',
                location.adminArea1,
                ' ',
                location.postalCode
            );
            result.line4 += countryIfNotLocal(location);
            result.bold2 = true;
            result.oneLiner = compact([
                result.placeName,
                result.line1,
                result.line2,
                result.line3,
                result.line4,
            ]).join(' | ');
            break;
        default:
            break;
    }
    return result;
};

function LocationSummary({
    location,
    style,
    hideMap = false,
    currentUserDepartmentProfile,
    applicationSettings,
    showAliases = true,
}) {
    const countryCode = currentUserDepartmentProfile.locationBias.countryCode;
    const isSubPremiseSupportEnabled = applicationSettings.SUBPREMISE_SUPPORT_ENABLED;

    const entityLinks = useSelector(locationEntityLinksWhereSelector)({
        locationId: location.id,
    });

    if (location.id && !location.entityLinks?.length && !!entityLinks.length) {
        location.entityLinks = entityLinks;
    }

    const address = formatAddressFromJson({
        location,
        currentUserCountryCode: countryCode,
        isSubPremiseSupportEnabled,
    });

    const { source, placeName, line1, line2, line3, line4, subPremise, bold2 } = address;
    const locationSource = location.areCoordinatesVerified ? (
        <VerifiedTag content={source} />
    ) : (
        <Text variant="bodyMd" color="positive" fontWeight="semibold">
            {source}
        </Text>
    );

    let staticMap = null;
    const hasLatLng =
        location &&
        typeof location?.latitude === 'number' &&
        typeof location?.longitude === 'number';
    if (applicationSettings.ESRI_MAPS_ENABLED && hasLatLng) {
        staticMap = (
            <Map
                defaultCenter={{ lat: location.latitude, lng: location.longitude }}
                selectedLocation={location}
                width={370}
                height={160}
            />
        );
    } else {
        const mapSize = '370x160'; // must match css rule for `.location-map`
        const staticMapUrl = formatStaticMapUrl(location, mapSize);
        staticMap = staticMapUrl && (
            <div className="location-map">
                <img src={staticMapUrl} />
            </div>
        );
    }

    const latLong = formatLatLong(location);

    return (
        <div className="entity-summary-header" style={style}>
            <div className="entity-summary-icon-wrapper">
                <Icon type={iconTypes.LOCATION} />
            </div>
            <div className="entity-summary-header-content location-summary-header">
                {showAliases && (
                    <FeatureFlagged
                        flag="SUBPREMISE_SUPPORT_ENABLED"
                        fallback={<div className="location-row bold">{placeName}</div>}
                    >
                        <div className="location-row bold">
                            {placeName} {locationSource}
                        </div>
                    </FeatureFlagged>
                )}
                <div className="location-row bold">
                    {line1} {!showAliases && locationSource}
                </div>
                <div className="location-row bold">{subPremise}</div>
                <div
                    className={classNames('location-row', {
                        bold: bold2,
                    })}
                >
                    {line2}
                </div>
                <div className="location-row">{line3}</div>
                <div className="location-row">{line4}</div>
                {!hideMap && staticMap}
                {!!latLong && (
                    <div className="location-row lat-long-row">
                        <SummaryList labelWidth={75} contentWidth={150}>
                            <SummaryRow label={strings.latLong}>{latLong}</SummaryRow>
                        </SummaryList>
                    </div>
                )}
            </div>
        </div>
    );
}

export default connect(
    createStructuredSelector({
        currentUserDepartmentProfile: currentUserDepartmentProfileSelector,
        applicationSettings: applicationSettingsSelector,
    })
)(LocationSummary);

import React from 'react';
import classNames from 'classnames';
import Checkbox from '../../../../modules/core/forms/components/checkboxes/Checkbox';

export default function TableCheckboxCell({ onChange, value, disabled, className }) {
    return (
        <td className={classNames('cobalt-table-cell cobalt-select-cell', className)}>
            {<Checkbox disabled={disabled} value={value} onChange={onChange} />}
        </td>
    );
}

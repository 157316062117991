import React from 'react';
import { Fieldset as MFTFieldset } from 'markformythree';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import Divider from '../../../../../../legacy-redux/components/core/Divider';
import Row from '../../../../../core/components/Row';
import { dateTimePickerTotalWidth } from '../../../../../../legacy-redux/configs/formsConfig';

const LockupDetails = () => (
    <div>
        <Divider />
        <Row>
            <MFTFieldset path="arrest">
                <Row>
                    <ArbiterMFTText path="lockupNumber" width="45%" />
                    <ArbiterMFTText path="afisNumber" width="50%" />
                </Row>
                <Row>
                    <ArbiterMFTText path="lockupLocation" width="45%" />
                    <ArbiterMFTDatePicker
                        path="lockupDateUtc"
                        includeTime={true}
                        width={dateTimePickerTotalWidth}
                    />
                </Row>
            </MFTFieldset>
        </Row>
    </div>
);

export default LockupDetails;

import React from 'react';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { reportDefinitionByIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { reportDefinitionHasOffenseCard } from '~/client-common/helpers/reportDefinitionsHelpers';
import { DISPLAY_ONLY_REPORT_TYPE_LABEL } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { RRFReportDefinitionSelect } from '../../../core/forms/components/selects/ReportDefinitionSelect';
import { RRFNibrsOffenseCodeIdSelect } from '../../../core/forms/components/selects/NibrsCodeSelect';
import { RRFOffenseGroupSelect } from '../../../core/forms/components/selects/OffenseGroupSelect';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../core/testIds';

const { withRRFActions } = reactReduxFormHelpers;

/**
 * An arbitrary number of report definition rows. Some form rows conditionally
 *   appear depending on the selected report definition.
 */
const ReportDefinitionsNItems = compose(
    connect(
        createStructuredSelector({
            reportDefinitionById: reportDefinitionByIdSelector,
        })
    ),
    withRRFActions
)(function ReportDefinitionsNItems({ reportDefinitionById, formActions }) {
    const displayFieldNames = useFields(DISPLAY_ONLY_REPORT_TYPE_LABEL);

    return (
        <RRFNItems
            path="reportDefinitions"
            addText={displayFieldNames.DISPLAY_ONLY_REPORT_TYPE_LABEL}
            addItemOnDirty={false}
            hideAddButtonOnEmptyItem={true}
        >
            {(item, index, deleteButton) => {
                const reportDefinitionIdRow = (
                    <Row>
                        <RRFReportDefinitionSelect
                            width={260}
                            path="reportDefinitionId"
                            onChange={(value) => {
                                if (value !== item.reportDefinitionId) {
                                    // when the report type is changed, clear all
                                    // other field values in this item
                                    formActions.change(`reportDefinitions[${index}]`, {
                                        reportDefinitionId: value,
                                    });
                                }
                            }}
                        />
                        {deleteButton}
                    </Row>
                );

                const reportDefinition = reportDefinitionById(item.reportDefinitionId);

                const offenseAndIncidentRows = reportDefinitionHasOffenseCard(reportDefinition) && (
                    <Row>
                        <RRFNibrsOffenseCodeIdSelect
                            multiple={true}
                            width={260}
                            path="nibrsCodeIds"
                        />
                        <RRFOffenseGroupSelect multiple={true} width={180} />
                    </Row>
                );

                return (
                    <div>
                        {reportDefinitionIdRow}
                        {offenseAndIncidentRows}
                    </div>
                );
            }}
        </RRFNItems>
    );
});

/**
 * Fieldset for report type in the Unassigned Reports search form.
 */
export default pure(function UnassignedReportsSearchReportDefinitionFieldset() {
    const reportTypeLabel = useFields(DISPLAY_ONLY_REPORT_TYPE_LABEL)[
        DISPLAY_ONLY_REPORT_TYPE_LABEL
    ];
    return (
        <div data-test-id={testIds.CASE_DASHBOARD_REPORT_TYPE_FILTER}>
            <RRFFieldset title={reportTypeLabel} highlightOnFocus={false}>
                <ReportDefinitionsNItems />
            </RRFFieldset>
        </div>
    );
});

import React from 'react';

import { EFileSummaryCard } from './EFileSummaryCard';

export const EFileSummaryPage = () => {
    return (
        <div>
            <EFileSummaryCard />
        </div>
    );
};

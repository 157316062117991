// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Organization Profile Resource',
        methods: {
            getContextedOrMasterOrganizationProfile({
                masterOrContextedProfileId,
                ownerType,
                ownerId,
            }) {
                return req({
                    method: 'GET',
                    url: `organization/profile/${masterOrContextedProfileId}/owner/${ownerType}/${ownerId}`,
                });
            },
            createOrganizationProfile(organizationProfile) {
                return req({
                    method: 'POST',
                    url: 'organization/profile',
                    data: organizationProfile,
                });
            },
            upsertHydratedOrganization({ hydratedOrganization, removeLocationsIfEmpty }) {
                return req({
                    method: 'POST',
                    url: 'organization/hydrated',
                    data: hydratedOrganization,
                    params: { remove_locations_if_empty: removeLocationsIfEmpty },
                });
            },
            getOrganizationProfile(profileId) {
                return req({
                    method: 'GET',
                    url: `organization/profile/${profileId}`,
                });
            },
            updateOrganizationProfile({ profile, isMaster }) {
                return req({
                    method: 'PUT',
                    url: `organization/profile/${profile.id}`,
                    data: profile,
                    params: { is_master: isMaster },
                });
            },
            getSocietyProfiles() {
                return req({
                    method: 'GET',
                    url: 'organization/profile',
                    params: { is_society: true },
                });
            },
            mergeOrganizationProfiles({ mergeProfile, idsToMerge }) {
                return req({
                    method: 'POST',
                    url: 'organization/profile/merge',
                    data: {
                        mergeProfile,
                        idsToMerge,
                    },
                });
            },
            addOrganizationLink(masterProfileId, entityType, ownerId) {
                return req({
                    method: 'PUT',
                    url: `organization/profile/${masterProfileId}/owner/${entityType}/${ownerId}`,
                });
            },
        },
    });
}

/**
 * Resource for organization profiles. This is temporarily a function to avoid
 *   dependency problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

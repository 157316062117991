import { normalize } from '~/client-common/helpers/dataHelpers';
import Resource from '../resources/departmentAdminSecurityResource';
import {
    getDepartmentIPById,
    getCurrentDepartmentIPId,
    departmentIPsAdminUISelector,
} from '../selectors/departmentIPsAdminSelectors';
import { userDepartmentIdSelector } from '../selectors/userSelectors';
import securityAdminActionTypes from './types/securityAdminActionTypes';

const {
    LOAD_IPS_START,
    LOAD_IPS_SUCCESS,
    LOAD_IPS_FAILURE,
    SELECT_IP,
    CREATE_IP,
    SAVE_IP_START,
    SAVE_IP_SUCCESS,
    SAVE_IP_FAILURE,
    DELETE_IP_START,
    DELETE_IP_SUCCESS,
    DELETE_IP_FAILURE,
} = securityAdminActionTypes;

function loadIPsStart() {
    return {
        type: LOAD_IPS_START,
    };
}

function loadIPsSuccess(ips) {
    return {
        type: LOAD_IPS_SUCCESS,
        payload: {
            ips: normalize(ips),
        },
    };
}

function loadIPsFailure() {
    return {
        type: LOAD_IPS_FAILURE,
    };
}

export function pageLoad() {
    return function (dispatch) {
        dispatch(loadIPsStart());
        Resource.getAllDepartmentIPs()
            .then((ips) => {
                dispatch(loadIPsSuccess(ips));
            })
            .catch(() => {
                dispatch(loadIPsFailure());
            });
    };
}

function rawSelectIP(ip) {
    return {
        type: SELECT_IP,
        payload: ip,
    };
}

export function selectIP(id) {
    return function (dispatch, getState) {
        const ip = getDepartmentIPById(getState(), id);

        dispatch(rawSelectIP(ip));
    };
}

export function createIP() {
    return {
        type: CREATE_IP,
    };
}

function saveIPStart(ip) {
    return {
        type: SAVE_IP_START,
        payload: ip,
    };
}

function saveIPSuccess(ip) {
    return {
        type: SAVE_IP_SUCCESS,
        payload: ip,
    };
}

function saveIPFailure(err) {
    return {
        type: SAVE_IP_FAILURE,
        error: true,
        payload: err,
    };
}

export function saveIP(formData) {
    return function (dispatch, getState) {
        const state = getState();
        const ui = departmentIPsAdminUISelector(state);

        if (ui.saving) {
            return; // Don't double save!
        }

        const id = getCurrentDepartmentIPId(state);

        // Combine the existing object with everything that changed in the form
        // Note: We also set the department id (It could be a new code, but doesn't hurt on an old code)
        const departmentId = userDepartmentIdSelector(state);
        const currentIP = getDepartmentIPById(state, id);

        const newIP = {
            ...currentIP,
            ...formData,
            departmentId,
        };

        dispatch(saveIPStart(newIP));

        Resource.upsertDepartmentIP(newIP)
            .then((response) => dispatch(saveIPSuccess(response)))
            .catch((err) => dispatch(saveIPFailure(err)));
    };
}

function deleteIPStart(id) {
    return {
        type: DELETE_IP_START,
        payload: id,
    };
}

function deleteIPSuccess(id) {
    return {
        type: DELETE_IP_SUCCESS,
        payload: id,
    };
}

function deleteIPFailure(err) {
    return {
        type: DELETE_IP_FAILURE,
        error: true,
        payload: err,
    };
}

export function deleteIP(ip) {
    return function (dispatch) {
        const id = ip.id;

        dispatch(deleteIPStart(id));

        Resource.deleteDepartmentIP(id)
            .then(() => dispatch(deleteIPSuccess(id)))
            .catch((err) => dispatch(deleteIPFailure(err)));
    };
}

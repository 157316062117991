import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { reportShortTitleViewModelByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { elasticReportByIdSelector } from '~/client-common/core/domain/elastic-reports/state/data';
import {
    computeOtherReportId,
    computeCanReadReportReportLink,
} from '~/client-common/core/domain/report-report-links/utils';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import { RemoveButton as _RemoveButton } from '../../../core/forms/components/NItems';
import Row from '../../../core/components/Row';
import IndentedFields from '../../../core/components/IndentedFields';

import RestrictedAccessNotice from '../../../core/components/RestrictedAccessNotice';
import ReportReportLinkTitle from './ReportReportLinkTitle';

export const ItemWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.mediumBlue};
    border-style: solid;
    border-radius: 4px;
    float: left;
    margin-top: 10px;
    position: relative;
    width: 100%;
    font-size: var(--arc-fontSizes-sm);

    &:hover {
        border-color: ${(props) => props.theme.colors.cobaltBlue};
    }
`;

export const RemoveButton = styled(_RemoveButton)`
    position: absolute;
    margin-top: 0;
    top: 9px;
    right: 2px;
    display: none;

    ${/* sc-selector */ ItemWrapper}:hover & {
        display: block;
    }
`;

const ReportReportLinkTitleContainer = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.bold};
    padding-bottom: 5px;
`;

const FormContainer = styled.div`
    float: left;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 30px;
    padding-bottom: 12px;
`;

function ReportExternalLinkForm() {
    return (
        <FormContainer>
            <Row>
                <ArbiterMFTText path="sourceId" width={'100%'} />
            </Row>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="sourceSystemAttrId"
                    attributeType={AttributeTypeEnum.EXTERNAL_RECORD_SOURCE.name}
                    width={'100%'}
                />
            </Row>
            <IndentedFields>
                <Row>
                    <ArbiterMFTText path="sourceSystemOther" width={'100%'} />
                </Row>
            </IndentedFields>
            <Row>
                <ArbiterMFTText path="url" width={'100%'} />
            </Row>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="reasonForAssociationAttrId"
                    attributeType={AttributeTypeEnum.REASON_FOR_ASSOCIATION.name}
                    width={'100%'}
                />
            </Row>
            <IndentedFields>
                <Row>
                    <ArbiterMFTText path="reasonForAssociationOther" width={'100%'} />
                </Row>
            </IndentedFields>
        </FormContainer>
    );
}

function ReportInternalLinkForm({
    item,
    reportShortTitleViewModelByReportId,
    elasticReportById,
    currentReportId,
}) {
    const canRead = computeCanReadReportReportLink({
        reportId: computeOtherReportId({
            currentReportId,
            fromReportId: item.fromReportId,
            toReportId: item.toReportId,
        }),
        reportShortTitleViewModelByReportId,
        elasticReportById,
    });

    return (
        <div>
            {!canRead && <RestrictedAccessNotice />}
            <FormContainer>
                <ReportReportLinkTitleContainer>
                    <ReportReportLinkTitle reportLink={item} currentReportId={currentReportId} />
                </ReportReportLinkTitleContainer>
                <Row>
                    <ArbiterMFTAttributeSelect
                        path="reasonForAssociationAttrId"
                        attributeType={AttributeTypeEnum.REASON_FOR_ASSOCIATION.name}
                        width={'100%'}
                    />
                </Row>
                <IndentedFields>
                    <Row>
                        <ArbiterMFTText path="reasonForAssociationOther" width={'100%'} />
                    </Row>
                </IndentedFields>
            </FormContainer>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    reportShortTitleViewModelByReportId: reportShortTitleViewModelByReportIdSelector,
    elasticReportById: elasticReportByIdSelector,
});

const ConnectedReportInternalLinkForm = connect(mapStateToProps)(ReportInternalLinkForm);

function AssociatedRecordsFormItem({ form, currentReportId }) {
    const { item } = form;
    if (item.isExternal) {
        return <ReportExternalLinkForm />;
    }
    return <ConnectedReportInternalLinkForm item={item} currentReportId={currentReportId} />;
}

export default AssociatedRecordsFormItem;

// This file is a temporary solution used only for patch string replacement for RMS-15751

const isNamedAsEvidenceModule = (moduleName: string) =>
    moduleName.toLowerCase().trim() === 'evidence';

export const replaceTextIfEvidenceModuleNameIsNotEvidence = (
    text: string | undefined | number, // number type comes from value group from mapAttributesToOptions
    replacementString: string
) => {
    // only replace the text if it's a valid string
    // and the module name is not 'evidence'
    if (text && typeof text !== 'number' && !isNamedAsEvidenceModule(replacementString)) {
        return text.replace(/evidence/gi, replacementString);
    }

    return text?.toString();
};

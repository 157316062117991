import React from 'react';
import { map } from 'lodash';
import classNames from 'classnames';
import styled from 'styled-components';

import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import testIds from '../../../core/testIds';
import { useOnClickOutside } from '../../../legacy-redux/helpers/reactHelpers';
import { onClickToOnEnter } from '../utils/eventHelpers';
import zIndexes from '../styles/zIndexes';
import _Icon, { iconTypes } from './Icon';

const PopoutMenuContainer = styled.div`
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    background-color: ${(props) => props.theme.colors.white};
    position: absolute;
    top: ${(props) => (props.top ? props.top : 'auto')};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
    padding: 8px 0;
    /* the column parent has a 10px padding, this left: 0 value makes the dropdown appear directly below its parent */
    left: ${(props) => (props.left ? props.left : 0)};
    ${(props) => (props.right ? `right: ${props.right};` : '')};
    z-index: ${zIndexes.menu};
    width: ${(props) => (props.width ? `${props.width}px` : 'auto')};

    .column-popout-sort-options {
        border-top: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    }

    .column-popout-column-option,
    .column-popout-sort-option {
        font-family: ${(props) => props.theme.fontFamilies.proximaNova};
        font-weight: ${(props) => props.theme.fontWeights.semiBold};
        color: ${(props) => props.theme.colors.text};
        padding: 10px 18px;
        line-height: 20px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
            background-color: var(--arc-colors-selected-default);
            color: var(--arc-colors-selected-content);
        }

        &.disabled {
            color: ${(props) => props.theme.colors.disabled};
            cursor: default;

            &:hover {
                background-color: ${(props) => props.theme.colors.white};
            }
        }
    }
`;

const Icon = styled(_Icon)`
    vertical-align: middle;
    margin-top: -4px;
    margin-left: 4px;
`;

const PopoutMenu = function PopoutMenu({
    options,
    sortOptions,
    activeOptionIndex,
    onOptionClick: handleOptionClick,
    width,
    top,
    left,
    right,
    marginBottom,
    onClickAway,
}) {
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => {
        onClickAway();
    });
    return (
        <PopoutMenuContainer
            ref={ref}
            width={width}
            top={top}
            left={left}
            right={right}
            marginBottom={marginBottom}
        >
            {/* Options for other properties to display in this column */}
            <div
                className="column-popout-column-options"
                data-test-id={testIds.TABLE_COLUMN_POPOUT}
            >
                {map(options, (option, i) => {
                    const handleClick = () => {
                        if (!(i === activeOptionIndex)) {
                            handleOptionClick(option.props);
                        }
                    };
                    return (
                        <div
                            tabIndex="0"
                            key={option.props.value}
                            className={classNames('column-popout-column-option', {
                                disabled: i === activeOptionIndex,
                            })}
                            onKeyDown={onClickToOnEnter(handleClick)}
                            onClick={handleClick}
                            data-test-id={testIds.TABLE_COLUMN_OPTION}
                        >
                            {option.props.display}
                        </div>
                    );
                })}
            </div>
            {/* Options for how to sort on this column, if available */}
            {sortOptions && activeOptionIndex > -1 ? (
                <div className="column-popout-sort-options" data-test-id={testIds.SORT_OPTIONS}>
                    {map(sortOptions, (sortOption) => (
                        <PopoutSortOption key={sortOption.sortType} {...sortOption} />
                    ))}
                </div>
            ) : undefined}
        </PopoutMenuContainer>
    );
};

export const mapSortTypeToIcon = (color) => {
    return {
        [sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT]: (
            <Icon size={12} color={color} type={iconTypes.ARROW_DOWN} />
        ),
        [sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT]: (
            <Icon size={12} color={color} type={iconTypes.ARROW_UP} />
        ),
        [sortTypeEnum.ALPHABETICAL_Z_TO_A]: (
            <Icon size={12} color={color} type={iconTypes.ARROW_DOWN} />
        ),
        [sortTypeEnum.ALPHABETICAL_A_TO_Z]: (
            <Icon size={12} color={color} type={iconTypes.ARROW_UP} />
        ),
        [sortTypeEnum.AGE_OLDEST_TO_YOUNGEST]: (
            <Icon size={12} color={color} type={iconTypes.ARROW_DOWN} />
        ),
        [sortTypeEnum.AGE_YOUNGEST_TO_OLDEST]: (
            <Icon size={12} color={color} type={iconTypes.ARROW_UP} />
        ),
    };
};

const subjectSorts = {
    LAST_NAME_ASCENDING: 'Last Name Z to A',
    LAST_NAME_DESCENDING: 'Last Name A to Z',
    FIRST_NAME_ASCENDING: 'First Name Z to A',
    FIRST_NAME_DESCENDING: 'First Name A to Z',
};

export function mapToSortType(sortOption) {
    if (
        sortOption === subjectSorts.LAST_NAME_DESCENDING ||
        sortOption === subjectSorts.FIRST_NAME_DESCENDING
    ) {
        return sortTypeEnum.ALPHABETICAL_A_TO_Z;
    } else if (
        sortOption === subjectSorts.LAST_NAME_ASCENDING ||
        sortOption === subjectSorts.FIRST_NAME_ASCENDING
    ) {
        return sortTypeEnum.ALPHABETICAL_Z_TO_A;
    } else {
        return;
    }
}

/**
 * An option that may appear depending on the configuration of an active
 * RelatedRecordsSortHeader. Indicates a Table's active sort type, and
 * provides the user with an interface to change the sort type.
 * @param  {string}   props.display     The text that displays for this
 *   PopoutSortOption, i.e. "A - Z" or "Newest to Oldest"
 * @param  {string}   props.sortType    The sort type that this PopoutSortOption
 *   represents.
 * @param  {boolean}  props.isActive    Indicates whether or not the sort type that
 *   this option represents is the parent headers's active sort type.
 * @param  {function} props.handleClick Callback called when the PopoutSortOption
 *   is clicked.
 */
function PopoutSortOption({ display, sortType, isActive: disabled, handleClick: onClick }) {
    const sortTypeToMap = mapToSortType(sortType) ? mapToSortType(sortType) : sortType;
    const sortIcon = disabled
        ? mapSortTypeToIcon('lightGrey')[sortTypeToMap]
        : mapSortTypeToIcon('darkGrey')[sortTypeToMap];

    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };
    return (
        <div
            tabIndex="0"
            onKeyDown={onClickToOnEnter(handleClick)}
            onClick={handleClick}
            className={classNames('column-popout-sort-option', {
                disabled,
            })}
        >
            <span>
                {display}
                {sortIcon}
            </span>
        </div>
    );
}

export default PopoutMenu;

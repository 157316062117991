import React from 'react';
import { filter, includes } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';

import { codeOptionsByCodeTypeCategorySelector } from '~/client-common/core/domain/codes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const strings = componentStrings.forms.select.CodeSelect;

const CodeSelect = compose(
    connect(
        createStructuredSelector({
            codeOptionsByCodeTypeCategory: codeOptionsByCodeTypeCategorySelector,
        })
    ),
    withPropsOnChange(
        ['codeSource', 'codeTypeCategory', 'codeOptionsByCodeTypeCategory', 'ignoreCodes'],
        ({ codeSource, codeTypeCategory, ignoreCodes, codeOptionsByCodeTypeCategory }) => {
            let codes = codeOptionsByCodeTypeCategory(codeSource)[codeTypeCategory];
            if (ignoreCodes) {
                codes = filter(codes, (code) => {
                    return !includes(ignoreCodes, code.code);
                });
            }

            return {
                codes,
            };
        }
    )
)(function CodeSelect({ codes, ...otherProps }) {
    return <Select label={otherProps.label || strings.label} options={codes} {...otherProps} />;
});

/**
 * Select dropdown containing all UCR summary offense codes as well as a "No UCR
 *   Code" option (modeled by `hasCode = false`).
 * @param {Object} props Same props as `Select`.
 */
export default CodeSelect;

export const RRFCodeSelect = connectRRFInput(CodeSelect);
export const ArbiterMFTCodeSelect = arbiterMFTInput(CodeSelect);

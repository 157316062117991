import React from 'react';
import { Control } from 'markformythree';
import { Field } from '~/client-common/core/fields/state/config';
import { AgeRangeSlider, HeightRangeSlider, WeightRangeSlider } from './Slider';

type RangeValue = [number | undefined, number | undefined];

/**
 * Original props on <Slider isRange={true} />
 */
interface BaseRangeSliderProps {
    label?: string;
    fieldName?: Field;
    value?: RangeValue;
    fields: {
        rangeStart: {
            value?: number;
            onChange: (value: number) => void;
        };
        rangeEnd: {
            value?: number;
            onChange: (value: number) => void;
        };
    };
    onChange?: ({ rangeStart, rangeEnd }: { rangeStart: number; rangeEnd: number }) => void;
    onFocus?: (value?: RangeValue) => void;
    onBlur?: (value?: RangeValue) => void;
    width?: number | string;
}

/**
 * Props on each component created by createMFTRangeSlider()
 */
interface MFTRangeSliderProps {
    path: string;
    label?: string;
    fieldName?: Field;
    onChange?: (value?: RangeValue) => void;
    onFocus?: (value?: RangeValue) => void;
    onBlur?: (value?: RangeValue) => void;
    width?: number | string;
}

/**
 * Connect the given Slider component to markformythree form state.
 */
function createMFTRangeSlider(Slider: React.ComponentType<BaseRangeSliderProps>) {
    return function (props: MFTRangeSliderProps): JSX.Element {
        return (
            <Control<RangeValue>
                path={props.path}
                // pass through custom event handlers to the Slider below
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onChange={props.onChange}
                render={({ onFocus, onBlur, onChange, model }) => {
                    const [rangeStart, rangeEnd] = model || [];

                    const fields = {
                        rangeStart: {
                            value: rangeStart,
                            onChange: (value: number) => {
                                onChange([value, rangeEnd]);
                            },
                        },
                        rangeEnd: {
                            value: rangeEnd,
                            onChange: (value: number) => {
                                onChange([rangeStart, value]);
                            },
                        },
                    };

                    return (
                        <Slider
                            label={props.label}
                            fieldName={props.fieldName}
                            value={[rangeStart, rangeEnd]}
                            fields={fields}
                            onChange={({ rangeStart, rangeEnd }) => {
                                onChange([rangeStart, rangeEnd]);
                            }}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            width={props.width}
                        />
                    );
                }}
            />
        );
    };
}

export const MFTAgeRangeSlider = createMFTRangeSlider(AgeRangeSlider);
export const MFTHeightRangeSlider = createMFTRangeSlider(HeightRangeSlider);
export const MFTWeightRangeSlider = createMFTRangeSlider(WeightRangeSlider);

import httpHeaderEnum from '~/client-common/core/enums/client/httpHeaderEnum';
import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'User Resource',
    methods: {
        /* eslint-disable-next-line @typescript-eslint/no-inferrable-types */
        getUserProfile(override401Handler: boolean = false) {
            return req({
                method: 'GET',
                url: 'user/current/profile',
                override401Handler,
                headers: {
                    [httpHeaderEnum.X_ENABLE_ETAG_CACHE]: true,
                },
            });
        },
    },
});

import React from 'react';
import { Motion, spring } from 'react-motion';
import { profileHeaderSpringParameters } from '../../configuration';

export default function HeaderMasterName({ children, collapsed }) {
    return (
        <Motion
            style={{
                fontSize: spring(collapsed ? 20 : 30, profileHeaderSpringParameters),
            }}
        >
            {({ fontSize }) => (
                <div
                    className="master-name"
                    style={{
                        fontSize,
                    }}
                >
                    {children}
                </div>
            )}
        </Motion>
    );
}

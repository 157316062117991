import React from 'react';
import styled from 'styled-components';
import { compose, withHandlers } from 'recompose';
import classNames from 'classnames';
import { Banner as ArcBanner, Flex, Icon, createBannerCssVariables } from 'arc';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { acknowledgeUserNotification } from '~/client-common/core/domain/user-notifications/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { convertAttachmentViewModelsForLightboxGallery } from '~/client-common/core/domain/attachments/state/ui';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { reverseBanners } from '../../actions/alertsActions';
import { openImageGalleryLightbox } from '../../actions/imageGalleryLightboxActions';
import { bannerForDisplaySelector } from '../../selectors/alertsSelectors';
import NotificationAlertCreator from '../../../modules/notifications/core/components/NotificationAlertCreator';
import testIds from '../../../core/testIds';
import { useDateTimeFormatter } from '../../../modules/core/current-user/hooks/dateTimeFormats';
import { getTypeColorAndIconType } from './utils/getTypeColorAndIconType';

const strings = componentStrings.alerts.Banner;

const StyledBannerIcon = styled.div`
    ${(props) => createBannerCssVariables(props.status)}
    background-color: var(--arc-banner-default-color);
    color: var(--arc-banner-content-color);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledArcBanner = styled(ArcBanner)`
    flex: 1;
    position: relative;
`;

const BannerWrapper = styled(Flex)`
    z-index: 200;
    width: 100%;
`;

export const BannerIcon = connect(
    createStructuredSelector({
        parentAttributeIdByAttributeId: parentAttributeIdByAttributeIdSelector,
    })
)(({ bannerTypeAttrId, parentAttributeIdByAttributeId, iconSize = 'md', ...props }) => {
    const { icon, status } = getTypeColorAndIconType(
        parentAttributeIdByAttributeId(bannerTypeAttrId)
    );
    return (
        <StyledBannerIcon status={status} {...props}>
            <Icon color="inherit" icon={icon} size={iconSize} />
        </StyledBannerIcon>
    );
});

const BannerBase = (props) => {
    const {
        banner: { bannerTypeAttrId, nBanners, bulletin },
        staticDisplay,
        handleAcknowledge,
        router,
        parentAttributeIdByAttributeId,
        onClick,
    } = props;
    const bannerTypeParentAttributeId = parentAttributeIdByAttributeId(bannerTypeAttrId);

    const dateTimeFormatter = useDateTimeFormatter();

    const { status, icon } = getTypeColorAndIconType(bannerTypeParentAttributeId);

    const actionButtons = [];

    const attachmentsCount = _.size(bulletin.attachments);
    if (attachmentsCount > 0) {
        actionButtons.push({
            children: strings.attachmentsN(attachmentsCount),
            key: 'button-attachments',
            variant: 'ghost',
            leftIcon: 'Document',
            onClick,
            className: 'banner-attachments-wrapper',
            'data-test-id': testIds.ALERT_BANNER_ATTACHMENT,
        });
    }

    if (!staticDisplay) {
        actionButtons.push({
            key: 'button-acknowledge',
            children: strings.acknowledge,
            variant: 'solid',
            leftIcon: 'Check',
            onClick: handleAcknowledge,
            className: 'acknowledge-wrapper',
            'data-test-id': testIds.ALERT_BANNER_ACKNOWLEDGE,
        });

        const showMore = nBanners > 1;
        if (showMore) {
            actionButtons.push({
                as: 'div',
                key: 'button-show-more',
                children: strings.more(nBanners - 1),
                onClick: () => router.push('/notifications/alerts'),
                style: { cursor: 'pointer' },
                variant: 'outline',
                className: 'show-more',
                'data-test-id': testIds.ALERT_BANNER_SHOW_MORE,
                target: '_self',
            });
        }
    }

    return (
        <BannerWrapper className={classNames('banner-wrapper')} data-test-id={testIds.ALERT_BANNER}>
            <StyledArcBanner
                icon={icon}
                status={status}
                actions={actionButtons}
                description={
                    <>
                        {bulletin.text}
                        {' — '}
                        {strings.info(dateTimeFormatter.formatTableDateTime(bulletin.startTimeUtc))}
                        <NotificationAlertCreator
                            alertCreatorFullNameWithBadge={bulletin.alertCreatorFullNameWithBadge}
                            alertCreatorId={bulletin.alertCreatorId}
                            alertCreatorUserProfile={bulletin.alertCreatorUserProfile}
                        />
                    </>
                }
            />
        </BannerWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    banner: bannerForDisplaySelector,
    parentAttributeIdByAttributeId: parentAttributeIdByAttributeIdSelector,
    dateTimeFormatter: currentDepartmentDateFormatterSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openImageGalleryLightbox: (bannerAttachments, index) =>
        dispatch(openImageGalleryLightbox(bannerAttachments, index)),
    onReverse: () => dispatch(reverseBanners()),
    acknowledgeUserNotification: (userNotification) =>
        dispatch(acknowledgeUserNotification(userNotification)),
});

export const Banner = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        handleAcknowledge: ({
            banner: {
                bulletin: { userNotification },
            },
            acknowledgeUserNotification,
        }) => () => acknowledgeUserNotification(userNotification),
        onClick: ({
            openImageGalleryLightbox,
            banner: {
                bulletin: { attachments },
            },
            dateTimeFormatter,
        }) => () => {
            openImageGalleryLightbox(
                convertAttachmentViewModelsForLightboxGallery(attachments, dateTimeFormatter)
            );
        },
    }),
    withRouter
)(BannerBase);

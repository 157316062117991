import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { uniqWith, isEqual } from 'lodash';
import { atfManufacturersSelector } from '~/client-common/core/domain/etrace-atf/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { getDropdownOptionsFromStrings } from '~/client-common/helpers/dropdownOptionHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import AsyncSelect from '../../../../../core/forms/components/selects/AsyncSelect';
import { LegacySelectOption } from '../../../../../core/forms/helpers/selectHelpers';
import atfManufacturerResource from '../../../resources/atfManufacturerResource';
import { arbiterMFTInput } from '../../../../../core/arbiter';
import { SelectProps } from '../../../../../core/forms/components/selects/Select';
import { logError } from '../../../../../../core/logging';

const atfManufacturerMustBeSelectedErrorMessage = componentStrings.reports.core.ItemSidePanel.atfManufacturerMustBeSelectedErrorMessage;

type PropsT = {
    atfManufacturerId: number;
    atfWeaponModel: string;
} & SelectProps<LegacySelectOption, false> ;

const AtfCaliberSelect: React.FC<PropsT> = ({ atfManufacturerId, atfWeaponModel, ...props }) => {
    const [atfCaliberOptions, setAtfCaliberOptions] = useState<
        LegacySelectOption[]
    >([]);

    const atfManufacturers = useSelector(atfManufacturersSelector);
    const selectedAtfManufacturer = atfManufacturers[atfManufacturerId];

    const searchForAtfCalibersSuccess = useCallback(
        (atfCalibers: string[]) => {
            const options = getDropdownOptionsFromStrings(atfCalibers);
            const selectedOptionArray = props.value ? getDropdownOptionsFromStrings([props.value.toString()]) : [];
            setAtfCaliberOptions(uniqWith(options.concat(selectedOptionArray), isEqual));
    }, [props.value]);

    const searchForAtfCalibers = useCallback((query?: string) => {
        const {code, countryAbbreviation} = selectedAtfManufacturer;
        return atfManufacturerResource.searchForAtfCalibers({
            query: {
                atfManufacturerCode: code,
                countryAbbreviation,
                aftModel: atfWeaponModel,
                atfCaliber: query,
            },
            from: 0,
            size: 20,
            sorts: [],
        });
    }, [atfWeaponModel, selectedAtfManufacturer]);

    const { callResource: fetchAtfCalibers } = useResourceDeferred(
        searchForAtfCalibers,
        searchForAtfCalibersSuccess
    );

    const fetchAtfCalibersWhenAtfManufacturerSelected = useCallback((query?: string) => {
        if (!selectedAtfManufacturer) {
            logError(atfManufacturerMustBeSelectedErrorMessage);
            return Promise.resolve();
        }
        return fetchAtfCalibers(query);
    }, [fetchAtfCalibers, selectedAtfManufacturer]);

    useEffect(() => {
        if (!props.value) {
            fetchAtfCalibersWhenAtfManufacturerSelected(undefined);
        }
    }, [fetchAtfCalibersWhenAtfManufacturerSelected, props.value]);

    useEffect(() => {
        const selectedOptionArray = props.value ? getDropdownOptionsFromStrings([props.value.toString()]) : [];
        setAtfCaliberOptions((prevOptions) => uniqWith(prevOptions.concat(selectedOptionArray), isEqual));
    }, [props.value]);

    return <AsyncSelect asyncAction={fetchAtfCalibersWhenAtfManufacturerSelected} options={atfCaliberOptions} {...props} />;
};

export const ArbiterAtfCaliberSelect = arbiterMFTInput(AtfCaliberSelect);

import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BriefingStateEnum, BriefingStateEnumType } from '@mark43/rms-api';
import { useToast } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { withBriefing } from '~/client-common/core/domain/briefings/state/data';

import errorToMessage from '../../core/errors/utils/errorToMessage';

import { AUTOSAVE_SECONDS_INTERVAL } from '../configuration';
import { getEditorContentByBriefingId } from '../utils';
import briefingResource from '../resources/briefingResource';

const strings = componentStrings.briefings.core;

const useBriefingAutosave = (
    id: number,
    state: BriefingStateEnumType,
    body: string | undefined,
    canSave: boolean
) => {
    const dispatch = useDispatch();
    const toast = useToast();

    const intervalRef = useRef<number>();

    useEffect(() => {
        if (canSave && state === BriefingStateEnum.DRAFT.name && !intervalRef.current) {
            intervalRef.current = setInterval(async () => {
                try {
                    const savedBody = body ?? '';
                    const bodyToSave = getEditorContentByBriefingId(id);
                    if (bodyToSave !== savedBody) {
                        const response = await briefingResource.updateBriefingBody(id, {
                            body: bodyToSave,
                        });
                        dispatch(withBriefing(response));
                    }
                } catch (e) {
                    toast({
                        status: 'error',
                        description: errorToMessage(e, strings.autosaveFailToast),
                    });
                }
            }, AUTOSAVE_SECONDS_INTERVAL * 1000);
        }
        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = undefined;
        };
    }, [id, state, body, canSave, toast, dispatch]);
};

export default useBriefingAutosave;

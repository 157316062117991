// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
import { map } from 'lodash';

const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/import_event';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Import Event Resource',
        methods: {
            /**
             * Get the status of an import event.
             * @param  {number} reportId
             * @return {Promise<CommandStatusEnumType>}
             */
            getImportEventStatus(reportId) {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/record/report/${reportId}`,
                }).then(({ currentStatus }) => currentStatus);
            },
            /**
             * Trigger the import event to run for the given itemProfileIds from the source report to
             *   the target report.
             * @param  {number}   sourceReportId
             * @param  {number}   targetReportId
             * @param  {number[]} itemProfileIds
             * @return {Promise<Object[]>} RmsHydratedItem
             */
            runImportEvent({ sourceReportId, targetReportId, itemProfileIds }) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/item/import`,
                    data: {
                        sourceReportId,
                        targetReportId,
                        sourceItems: map(itemProfileIds, (sourceItemId) => ({ sourceItemId })),
                    },
                });
            },
            importItemsToReport({ reportId, itemProfileIds, hideLoadingBar }) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/item/add`,
                    data: { reportId, itemIds: itemProfileIds },
                    hideLoadingBar,
                });
            },
        },
    });
}

/**
 * Resource for evidence import events.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

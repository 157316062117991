import { Widget } from '../../../types';

export type CrashDiagramState = {
    widgets: Widget[];
    selectedWidget?: Widget;
    backgroundImage?: string;
};

export const initialState: CrashDiagramState = {
    widgets: [],
    selectedWidget: undefined,
    backgroundImage: undefined,
};

import { CaseProfileLink, RefContextEnum, EntityTypeEnum } from '@mark43/rms-api';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { updateCaseProfileLinks } from '~/client-common/core/domain/case-profile-links/state/data';

import { getErrorMessagesFromErrors } from '../../../../reports/core/helpers/validationHelpers';
import { RmsAction } from '../../../../../core/typings/redux';
import { caseOnEnter } from '../../..';
import { currentCaseIdSelector, currentCaseViewSelector } from '../../../core/state/ui';
import {
    CaseLinkedProfilesFormType,
    CaseLinkedProfilesFormModel,
    convertFromFormModel,
    ItemCaseLinkFormModel,
    NameCaseLinkFormModel,
    NameLinksFormPath,
} from '../forms/caseLinkedProfilesForm';
import { caseLinkedProfilesFormViewModelSelector, extendCaseLinkWithCaseProps } from '.';

const initializeCaseProfilesForm =
    (): RmsAction<void> =>
    (dispatch, getState, { formsRegistry, overlayStore }) => {
        const state = getState();
        const formModel = caseLinkedProfilesFormViewModelSelector(state);

        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name,
            undefined,
            setFormModelAndValidate(formModel, (errors: string[]) => {
                overlayStore.setError(overlayIdEnum.CASE_LINKED_PROFILES_SIDE_PANEL, errors);
            })
        );
    };

export const setFormModelAndValidate = (
    formModel: CaseLinkedProfilesFormModel,
    setErrors: (err: string[]) => void
): (form: CaseLinkedProfilesFormType) => Promise<void> => async (form) => {
    let errors: string[] = [];
    form.set('', formModel);
    try {
        await form.submit();
    } catch (e) {
        errors = getErrorMessagesFromErrors(e);
    }
    setErrors(errors);
};

export const addNameCaseLink = (
    path: NameLinksFormPath,
    link: NameCaseLinkFormModel
): RmsAction<void> => (
    dispatch,
    getState,
    { formsRegistry }
) => {
    const state = getState();
    const caseView = currentCaseViewSelector(state);

    formsRegistry.maybeDeferredOperation(RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name, undefined, (form) => {
        const items = convertFromFormModel(form.getState().model)[path];
        const index = items.findIndex((item) => item.nameId === link.nameId);

        if (index === -1) {
            form.push(path, extendCaseLinkWithCaseProps(link, caseView));
        }
    });
};

export const removeNameCaseLink = (
    path: NameLinksFormPath,
    link: NameCaseLinkFormModel
): RmsAction<void> => (
    dispatch,
    getState,
    { formsRegistry }
) => {
    formsRegistry.maybeDeferredOperation(RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name, undefined, (form) => {
        const items = convertFromFormModel(form.getState().model)[path];
        const index = items.findIndex((item) => item.nameId === link.nameId);

        if (index !== -1) {
            form.remove(path, index);
        }
    }); 
};

export const addVehicleCaseLink = (link: Pick<ItemCaseLinkFormModel, 'itemId'>): RmsAction<void> => (
    dispatch,
    getState,
    { formsRegistry }
) => {
    const state = getState();
    const caseView = currentCaseViewSelector(state);

    formsRegistry.maybeDeferredOperation(RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name, undefined, (form) => {
        const key = 'vehicleItemCaseLinks';
        const items = convertFromFormModel(form.getState().model)[key];
        const index = items.findIndex((item) => item.itemId === link.itemId);

        if (index === -1) {
            form.push('vehicleItemCaseLinks', extendCaseLinkWithCaseProps(link, caseView));
        }
    });
};

export const removeVehicleCaseLink = (link: Pick<ItemCaseLinkFormModel, 'itemId'>): RmsAction<void> => (
    dispatch,
    getState,
    { formsRegistry }
) => {
    formsRegistry.maybeDeferredOperation(RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name, undefined, (form) => {
        const key = 'vehicleItemCaseLinks';
        const items = convertFromFormModel(form.getState().model)[key];
        const index = items.findIndex((item) => item.itemId === link.itemId);

        if (index !== -1) {
            form.remove(key, index);
        }
    });
};

const convertLinkToProfileLink = (link: NameCaseLinkFormModel | ItemCaseLinkFormModel) => {
    const result: Partial<CaseProfileLink> = {
        entityType: link.entityType,
        targetProfileLevelAttrId: link.targetProfileLevelAttrId,
    };

    if ('itemId' in link) {
        result.profileId = link.itemId;
        result.entityType = EntityTypeEnum.ITEM_PROFILE.name;
    } else if ('nameId' in link) {
        result.profileId = link.nameId;
        result.involvementTypeAttrId = link.involvementTypeAttrId;
    }

    return result as CaseProfileLink;
};

export const saveCaseLinks = (): RmsAction<Promise<void>> => async (
    dispatch,
    getState,
    { formsRegistry, overlayStore }
) => {
    const state = getState();
    const caseId = currentCaseIdSelector(state as never);
    const form = formsRegistry.get(RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name);

    if (!form) {
        return;
    }

    try {
        await form.submit();

        const {
            personNameCaseLinks,
            personNameCaseLinksFromReport,
            orgNameCaseLinks,
            orgNameCaseLinksFromReport,
            vehicleItemCaseLinks,
        } = convertFromFormModel(form.getState().model);

        const caseProfileLinks = [
            ...personNameCaseLinks,
            ...personNameCaseLinksFromReport,
            ...orgNameCaseLinks,
            ...orgNameCaseLinksFromReport,
            ...vehicleItemCaseLinks,
        ].map(convertLinkToProfileLink);

        await dispatch(updateCaseProfileLinks(caseId, caseProfileLinks, () => {
            caseOnEnter.call(
                { dispatch, getState },
                {
                    params: {
                        caseId,
                    },
                }
            );
        }));

        overlayStore.close(overlayIdEnum.CASE_LINKED_PROFILES_SIDE_PANEL);
    } catch (e) {
        overlayStore.setError(
            overlayIdEnum.CASE_LINKED_PROFILES_SIDE_PANEL,
            getErrorMessagesFromErrors(e)
        );
    }
};

export const openCaseProfilesSidePanel = (): RmsAction<void> => (
    dispatch,
    getState,
    { overlayStore }
) => {
    dispatch(initializeCaseProfilesForm());
    overlayStore.open(overlayIdEnum.CASE_LINKED_PROFILES_SIDE_PANEL);
};

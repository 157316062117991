import { DispatchAreaStatusEnum } from '@mark43/cad-api';
import { createSelector } from 'reselect';
import { chain } from 'lodash';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import {
    dispatchAreasSelector,
    dispatchAreaByIdSelector,
} from '~/client-common/core/domain/dispatch-areas/state/data';
import { normalize } from '~/client-common/helpers/dataHelpers';
import { prettify } from '~/client-common/helpers/stringHelpers';
import {
    LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_START,
    LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_SUCCESS,
    LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_FAILURE,
    UPSERT_DISPATCH_AREAS_START,
    UPSERT_DISPATCH_AREAS_SUCCESS,
    UPSERT_DISPATCH_AREAS_FAILURE,
} from '../data';

const SELECT_DISPATCH_AREA_START = 'dispatch_areas/SELECT_DISPATCH_AREA_START';
const SELECT_DISPATCH_AREA_SUCCESS = 'dispatch_areas/SELECT_DISPATCH_AREA_SUCCESS';
const SELECT_DISPATCH_AREA_FAILURE = 'dispatch_areas/SELECT_DISPATCH_AREA_FAILURE';
const RESET_DISPATCH_AREA_ADMIN_PAGE = 'dispatch_areas/RESET_DISPATCH_AREA_ADMIN_PAGE';
const OPEN_NEW_DISPATCH_AREAS_FORM = 'dispatch_areas/OPEN_NEW_DISPATCH_AREAS_FORM';

function selectDispatchAreaStart() {
    return {
        type: SELECT_DISPATCH_AREA_START,
    };
}

function selectDispatchAreaSuccess(dispatchAreaId) {
    return {
        type: SELECT_DISPATCH_AREA_SUCCESS,
        payload: dispatchAreaId,
    };
}

function selectDispatchAreaFailure(err) {
    return {
        type: SELECT_DISPATCH_AREA_FAILURE,
        payload: err,
    };
}

export function resetDispatchAreaAdminPage() {
    return {
        type: RESET_DISPATCH_AREA_ADMIN_PAGE,
    };
}

export function openNewDispatchAreasForm() {
    return {
        type: OPEN_NEW_DISPATCH_AREAS_FORM,
    };
}

const initialUiState = {
    loadError: null,
    error: null,
    submittingForm: false,
    selectedDispatchAreaId: null,
};

function dispatchAreasAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_START:
        case SELECT_DISPATCH_AREA_START:
            return {
                ...state,
                loadError: null,
                submittingForm: false,
                selectedDispatchAreaId: null,
                error: null,
            };

        // Load dispatch areas and radio channels starts together on component load.
        // dispatch areas are saved in dataNexus via storeDispatchAreas on success. radio channels are saved in ui
        case LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_SUCCESS:
            return {
                ...state,
                loadError: null,
                radioChannels: normalize(action.payload.radioChannels),
            };
        case LOAD_DISPATCH_AREAS_AND_RADIO_CHANNELS_FAILURE:
            return {
                ...state,
                submittingForm: false,
                loadError: action.payload,
            };
        case SELECT_DISPATCH_AREA_SUCCESS:
            return {
                ...state,
                loadError: null,
                submittingForm: false,
                selectedDispatchAreaId: action.payload,
                errors: null,
            };

        case SELECT_DISPATCH_AREA_FAILURE:
        case UPSERT_DISPATCH_AREAS_FAILURE:
            return {
                ...state,
                submittingForm: false,
                error: action.payload,
            };
        case UPSERT_DISPATCH_AREAS_START:
            return {
                ...state,
                submittingForm: true,
                error: null,
            };
        case UPSERT_DISPATCH_AREAS_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                error: null,
            };
        case OPEN_NEW_DISPATCH_AREAS_FORM:
            return {
                ...state,
                submittingForm: false,
                selectedDispatchAreaId: null,
                error: null,
            };
        default:
            return state;
    }
}

export function selectDispatchArea(dispatchAreaId) {
    return (dispatch, getState) => {
        dispatch(selectDispatchAreaStart());
        const state = getState();
        const id = parseInt(dispatchAreaId);
        const dispatchArea = dispatchAreaByIdSelector(state)(id);

        if (dispatchArea) {
            dispatch(selectDispatchAreaSuccess(dispatchArea.id));
        } else {
            dispatch(selectDispatchAreaFailure(`Unable to select dispatch area ${dispatchAreaId}`));
        }
    };
}

export const uiSelector = (state) => state.ui.dispatchAreasAdmin;

export const loadErrorSelector = createSelector(uiSelector, (ui) => ui.loadError);

export const dispatchAreasAdminUiListItemsSelector = createSelector(
    uiSelector,
    dispatchAreasSelector,
    (dispatchAreasAdmin, dispatchAreas) => {
        return chain(dispatchAreas)
            .map((area) => {
                const dispatchAreaStatus = DispatchAreaStatusEnum[area.status];
                return {
                    key: area.id,
                    title: `${area.name}`,
                    subtitle: prettify(dispatchAreaStatus.name),
                    path: `/admin/cad/dispatch-areas/${area.id}`,
                    selected: dispatchAreasAdmin.selectedDispatchAreaId === area.id,
                };
            })
            .sortBy('title')
            .sortBy((listItem) => (listItem.subtitle ? listItem.subtitle : null))
            .value();
    }
);

export const selectedDispatchAreaIdSelector = createSelector(
    uiSelector,
    (ui) => ui.selectedDispatchAreaId
);

export const selectedDispatchAreaSelector = createSelector(
    uiSelector,
    dispatchAreaByIdSelector,
    (ui, dispatchAreasById) => dispatchAreasById(ui.selectedDispatchAreaId)
);

export default makeResettable(RESET_DISPATCH_AREA_ADMIN_PAGE, dispatchAreasAdminUiReducer);

import { DispositionApprovalLevelEnum } from '@mark43/evidence-api';
import _ from 'lodash';

export const dispositionApprovalLevelPriority = {
    [DispositionApprovalLevelEnum.SECONDARY.name]: 1,
    [DispositionApprovalLevelEnum.PRIMARY.name]: 2,
    [DispositionApprovalLevelEnum.NONE_RELEASE.name]: 3,
    [DispositionApprovalLevelEnum.NONE_DISPOSITION.name]: 4,
};

export const highestPriorityDispositionApprovalLevel = (dispositionApprovalLevels) => {
    return _(dispositionApprovalLevels)
        .filter(
            (dispositionApprovalLevel) => dispositionApprovalLevelPriority[dispositionApprovalLevel]
        )
        .reduce((currentHighestPriorityDispositionLevel, dispositionApprovalLevel) => {
            const previousPriority =
                dispositionApprovalLevelPriority[currentHighestPriorityDispositionLevel];
            const currentPriority = dispositionApprovalLevelPriority[dispositionApprovalLevel];

            if (currentPriority <= previousPriority) {
                return dispositionApprovalLevel;
            }
            return currentHighestPriorityDispositionLevel;
        });
};

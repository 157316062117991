import { size, compact, uniq, uniqBy, map, first } from 'lodash';

export function getCountDistinct(value) {
    if (typeof value === 'undefined' || value === null) {
        return 0;
    }
    if (first(compact(map(value, 'castedValue'))) instanceof Date) {
        return size(uniqBy(compact(map(value, 'castedValue')), (x) => x.toString()));
    } else {
        return size(compact(uniq(map(value, 'castedValue'))));
    }
}

import { every, includes, isEqual, map, xorWith } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ItemProfile } from '@mark43/rms-api';
import { Avatar, Checkbox, InlineBanner, Tile, VStack } from 'arc';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { useResource } from '~/client-common/core/hooks/useResource';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import FormattedAttribute from '~/client-common/core/domain/attributes/components/FormattedAttribute';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatTitleForVehicle } from '~/client-common/core/domain/vehicles/utils/vehicleHelpers';
import { propertyTitleForItemProfileSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { arcIconForItemTypeAttrId } from '../../../../../core/item-profiles/utils/itemIcons';
import { eFileResource } from '../../../../resources';
import { SkeletonList } from '../../../../../core/components/Skeleton';
import NoDataBlock from '../../../../../core/components/NoDataBlock';

const strings = componentStrings.eFiles.core.sidePanel;

interface ImportableItemsContextT {
    // contexted ids, not master ids
    selectedItemProfileIds: number[];
    setSelectedItemProfileIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const ImportableItemsContext = React.createContext<ImportableItemsContextT | undefined>(undefined);

/**
 * Wrap this Provider component around the feature that needs to import E-File item profiles.
 */
export const ImportableItemsProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [selectedItemProfileIds, setSelectedItemProfileIds] = React.useState<number[]>([]);
    return (
        <ImportableItemsContext.Provider
            value={{
                selectedItemProfileIds,
                setSelectedItemProfileIds,
            }}
        >
            {children}
        </ImportableItemsContext.Provider>
    );
};

/**
 * The state of item profiles currently selected for import by the user.
 * This state has no ties to the other state in `EFileContext`.
 */
export function useImportableItems(): ImportableItemsContextT {
    const context = React.useContext(ImportableItemsContext);
    if (context === undefined) {
        throw new Error('Must be inside ImportableItemsProvider');
    }
    return context;
}

export const ImportableItemsContent = ({ eFileId }: { eFileId: number }): JSX.Element => {
    const [itemProfiles, setItemProfiles] = React.useState<ItemProfile[]>([]);

    const resource = React.useCallback(() => {
        return eFileResource.getItemsForImport(eFileId);
    }, [eFileId]);

    const { isLoading, errorMessage } = useResource(resource, setItemProfiles);

    if (errorMessage) {
        return <InlineBanner status="error">{errorMessage}</InlineBanner>;
    } else if (isLoading) {
        return <SkeletonList />;
    }
    return (
        <>
            <ItemProfilesSection itemProfiles={itemProfiles} />
        </>
    );
};

const ItemProfileTile = styled(Tile)`
    margin-top: var(--arc-space-3);
`;

const ItemProfileRow = ({
    itemProfile,
    title,
    isSelected,
    setSelectedItemProfileIds,
}: {
    itemProfile: ItemProfile;
    title: string;
    isSelected: boolean;
    setSelectedItemProfileIds: React.Dispatch<React.SetStateAction<number[]>>;
}): JSX.Element => {
    return (
        <ItemProfileTile
            title={title}
            description={
                <ConnectedFormattedAttribute
                    attributeId={itemProfile.itemTypeAttrId}
                    format={FormattedAttribute.FORMATS.ITEM_TYPE}
                />
            }
            media={
                // RMS-17994 set `src` to the optional thumbnail after it's hydrated in EFileItemSearchView
                <Avatar icon={arcIconForItemTypeAttrId(itemProfile.itemTypeAttrId)} size="md" />
            }
            onClick={() => {
                setSelectedItemProfileIds((ids) => xorWith(ids, [itemProfile.id], isEqual));
            }}
            isMultiple={true}
            isSelected={isSelected}
        />
    );
};

const ItemProfileRowMemo = React.memo(ItemProfileRow);

const ItemProfilesSection = ({ itemProfiles }: { itemProfiles: ItemProfile[] }): JSX.Element => {
    const { selectedItemProfileIds, setSelectedItemProfileIds } = useImportableItems();

    const onToggleAll = React.useCallback(
        (event) => {
            setSelectedItemProfileIds(event.target.checked ? map(itemProfiles, 'id') : []);
        },
        [itemProfiles, setSelectedItemProfileIds]
    );

    const propertyTitleForItemProfile: (itemProfile: ItemProfile) => string = useSelector(
        propertyTitleForItemProfileSelector
    );
    const formatAttributeById = useSelector(formatAttributeByIdSelector);

    const isChecked =
        itemProfiles.length > 0 &&
        every(itemProfiles, (itemProfile) => includes(selectedItemProfileIds, itemProfile.id));

    return (
        <VStack align="left" height="auto">
            <Checkbox
                isDisabled={itemProfiles.length === 0}
                isChecked={isChecked}
                onChange={onToggleAll}
            >
                {strings.selectAll}
            </Checkbox>
            {itemProfiles.length > 0 ? (
                map(itemProfiles, (itemProfile) => {
                    // RMS-17994 remove this ternary after Vehicles get hydrated into EFileItemSearchView
                    const title =
                        itemProfile.itemTypeAttrId === globalAttributes.itemType.vehicle
                            ? // @ts-expect-error client-common to client RND-7529
                              formatTitleForVehicle({
                                  description: itemProfile.description,
                                  itemCategory: formatAttributeById(itemProfile.itemCategoryAttrId),
                                  vehicleMake: itemProfile.itemMake,
                                  vehicleModel: itemProfile.itemModel,
                              })
                            : propertyTitleForItemProfile(itemProfile);
                    return (
                        <ItemProfileRowMemo
                            key={itemProfile.id}
                            itemProfile={itemProfile}
                            title={title}
                            isSelected={includes(selectedItemProfileIds, itemProfile.id)}
                            setSelectedItemProfileIds={setSelectedItemProfileIds}
                        />
                    );
                })
            ) : (
                <NoDataBlock>{strings.noResultsFound}</NoDataBlock>
            )}
        </VStack>
    );
};

import componentStrings from '~/client-common/core/strings/componentStrings';
import { isAfter } from '~/client-common/core/dates/utils/dateHelpers';

import { ArcPriorityEnum } from '../../components/tags/helpers';
import {
    CautionComputedData,
    PersonCustomCautionsData,
    VehicleCustomCautionsData,
} from '../configuration';

const { PersonLabels, VehicleLabels } = componentStrings.core;

export const preparePersonCustomCautions = ({
    isActiveTarget,
    isJuvenile,
    isSuspectedGangMember,
    isVulnerable,
    dateVulnerableTo,
    hasPotentialActiveWarrant,
}: PersonCustomCautionsData) => {
    const customCautions: CautionComputedData[] = [];

    const isDateVulnerableToValid = dateVulnerableTo ? isAfter(new Date(), dateVulnerableTo) : true;

    if (isActiveTarget) {
        customCautions.push({
            label: PersonLabels.activeTarget,
            priority: ArcPriorityEnum.PriorityOne,
        });
    }

    if (isJuvenile) {
        customCautions.push({
            label: PersonLabels.juvenile,
            priority: ArcPriorityEnum.PriorityOne,
        });
    }

    if (isSuspectedGangMember) {
        customCautions.push({
            label: PersonLabels.suspectedGangMember,
            priority: ArcPriorityEnum.PriorityTwo,
        });
    }

    if (isVulnerable && isDateVulnerableToValid) {
        customCautions.push({
            label: isJuvenile ? PersonLabels.vulnerableChild : PersonLabels.vulnerableAdult,
            priority: ArcPriorityEnum.PriorityOne,
        });
    }

    if (hasPotentialActiveWarrant) {
        customCautions.push({
            label: PersonLabels.potentialActiveWarrant,
            priority: ArcPriorityEnum.PriorityOne,
        });
    }

    return customCautions;
};

export const prepareVehicleCustomCautions = ({
    isActiveTarget,
    isStolen,
}: VehicleCustomCautionsData) => {
    const customCautions: CautionComputedData[] = [];

    if (isActiveTarget) {
        customCautions.push({
            label: VehicleLabels.isActiveTarget,
            priority: ArcPriorityEnum.PriorityOne,
        });
    }

    if (isStolen) {
        customCautions.push({
            label: VehicleLabels.isStolen,
            priority: ArcPriorityEnum.PriorityOne,
        });
    }

    return customCautions;
};

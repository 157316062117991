import * as yup from 'yup';
import {
    createField,
    InferFormDataShape,
    createFormConfiguration,
    formEvents,
} from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';
import stringsConfig from '~/client-common/core/strings';
import { AddOrEditSuspensionFormFields } from '../types';

const { requiredError } = componentStrings.validation;
const {
    endDateAfterStartOneValidationErrorMessage,
} = stringsConfig.components.admin.cadServiceProviders.cadServiceProvidersAdminForm.labels.suspensions;

type FormModel = InferFormDataShape<typeof formConfiguration>;

export const ADD_OR_EDIT_SUSPENSION_FORM_NAME = 'ADD_OR_EDIT_SUSPENSION_FORM';

export const formConfiguration = createFormConfiguration({
    suspensionStartDateUtc: createField<string>({}),
    suspensionEndDateUtc: createField<string>({}),
    rotationTypeIdsToSuspend: createField<number[]>({}),
    suspensionReason: createField<string>({}),
    isIndefinitely: createField<boolean>({}),
});

export function convertFromFormModel(formModel: FormModel): Partial<AddOrEditSuspensionFormFields> {
    const { isIndefinitely = false, suspensionEndDateUtc, ...remainingFormFields } = formModel;

    return {
        ...remainingFormFields,
        ...(isIndefinitely ? {} : { suspensionEndDateUtc }),
    };
}

export const validationSchema = yup.object().shape({
    rotationTypeIdsToSuspend: yup.array().of(yup.number()).required(requiredError),
    suspensionReason: yup.string().required(requiredError),
    isIndefinitely: yup.boolean(),
    suspensionStartDateUtc: yup.string().required(requiredError),
    suspensionEndDateUtc: yup
        .string()
        .when('$isIndefinitely', (isIndefinitely, schema) =>
            isIndefinitely ? schema : schema.required(requiredError)
        )
        .when('$isEndDateAfterStartOne', (isEndDateAfterStartOne, schema) =>
            isEndDateAfterStartOne
                ? schema
                : schema.matches(/''/, endDateAfterStartOneValidationErrorMessage)
        ),
});

export const validationEvents = [{ eventType: formEvents.FORM_SUBMIT }];

export const initialFormState = {
    suspensionStartDateUtc: undefined,
    suspensionEndDateUtc: undefined,
    rotationTypeIdsToSuspend: undefined,
    suspensionReason: undefined,
    isIndefinitely: undefined,
};

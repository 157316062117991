import { isFunction } from 'lodash';
import React from 'react';
import { OverlayBase } from '../../../core/overlayManager';
import { createOverlayCustomProperties } from '../utils/createOverlayCustomProperties';
import { ScreenManager } from './SidePanel/ScreenManager';

/**
 * Manages setup of initial overlay state based on passed props
 * and provides the consumer with the screen manager api
 */
export class OverlayStateManager extends React.Component {
    /**
     * Wrapper around `props.getInitialCustomPropertyState` so
     * that we can centralize the usage of `createOverlayCustomProperties`
     */
    getInitialCustomPropertyState = () => {
        const customProperties = this.props.getInitialCustomPropertyState
            ? this.props.getInitialCustomPropertyState()
            : {};
        return this.props.overlayStateType
            ? createOverlayCustomProperties(customProperties, this.props.overlayStateType, {
                  setDefaults: true,
              })
            : customProperties;
    };

    setSaveRef = (ref) => ref && (this.saveRef = ref);

    focusSaveRef = () => this.focusCloseRef(this.props.saveRef || this.saveRef);

    setCancelFocusRef = (ref) => (this.cancelRef = ref);

    focusCancelRef = () => this.focusCloseRef(this.cancelRef);

    setCloseFocusRefs = (ref) => {
        this.setCancelFocusRef(ref);
        this.setSaveRef(ref);
    };

    focusCloseRef = (ref) => {
        const refToFocus = ref && ref.current ? ref.current : ref;
        if (refToFocus) {
            if (refToFocus.button && isFunction(refToFocus.button.focus)) {
                // Remove this when ARC_RELEASE_CYCLE_THREE_COMPONENTS is globally enabled
                refToFocus.button.focus();
            } else if (isFunction(refToFocus.focus)) {
                refToFocus.focus();
            }
        }
    };

    render() {
        const { overlayStateType, id, children, ...rest } = this.props;

        return (
            <OverlayBase id={id} getInitialCustomPropertyState={this.getInitialCustomPropertyState}>
                {(overlayBaseProps) => (
                    <ScreenManager
                        overlayState={overlayBaseProps.overlayState}
                        overlayId={id}
                        overlayStateType={overlayStateType}
                        overlayStore={overlayBaseProps.overlayStore}
                    >
                        {(screenManagerApi) =>
                            children({
                                ...rest,
                                overlayBase: overlayBaseProps,
                                screenManagerApi,
                                setCancelFocusRef: this.setCancelFocusRef,
                                setCloseFocusRefs: this.setCloseFocusRefs,
                                closePanel: () => {
                                    overlayBaseProps.close();
                                    this.focusCancelRef();
                                },
                                savePanel: () => {
                                    overlayBaseProps.close();
                                    this.focusSaveRef();
                                },
                            })
                        }
                    </ScreenManager>
                )}
            </OverlayBase>
        );
    }
}

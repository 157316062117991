import { PrintingTemplate } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { groupBy } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'printingTemplates';

const printingTemplatesModule = createNormalizedModule<PrintingTemplate>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storePrintingTemplates = printingTemplatesModule.actionCreators.storeEntities;
export const deletePrintingTemplatesWhere =
    printingTemplatesModule.actionCreators.deleteEntitiesWhere;

// SELECTORS
export const printingTemplatesSelector = printingTemplatesModule.selectors.entitiesSelector;
export const printingTemplatesByPrintingDataTypeSelector = createSelector(
    printingTemplatesSelector,
    (printingTemplates) => groupBy(printingTemplates, 'printingDataType')
);

// REDUCER
export default printingTemplatesModule.reducerConfig;

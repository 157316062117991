import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import _Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import { canEditReportCardStatusSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { resolveInlineComment } from '../../state/data/inlineComments';

const resolveInlineCommentStrings = componentStrings.reports.core.InlineCommentsResolveButton;

const Button = styled(_Button)`
    height: 25px;
    min-width: 76px;
    color: ${(props) => props.theme.colors.cobaltBlue};
    background-color: ${(props) => props.theme.colors.white};
    text-transform: capitalize;
    font-weight: 600;
    size: 13px;
    box-sizing: border-box;
    line-height: 12px;
    padding: 6px 10px;
    margin: 0;
`;

type ResolveButtonPropsT = {
    commentId: number;
    reportId: number;
};

export const InlineCommentsResolveButton: React.FC<ResolveButtonPropsT> = ({
    commentId,
    reportId,
}) => {
    const dispatch = useDispatch();

    const canEditReportCardStatus = useSelector(canEditReportCardStatusSelector);
    if (!canEditReportCardStatus || !canEditReportCardStatus.canEditReportCard) {
        return null;
    }

    const handleResolveClick = () => {
        dispatch(resolveInlineComment(reportId, commentId, true));
    };

    return (
        <Button className={buttonTypes.SECONDARY} onClick={handleResolveClick}>
            {resolveInlineCommentStrings.label}
        </Button>
    );
};

import { _Form, createFormConfiguration, formEvents } from 'markformythree';
import * as yup from 'yup';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../../core/validation/yupMftValidation';

export const STATION_FORM = 'stationForm';
const strings = componentStrings.validation;

const validationSchema = yup.object().shape({
    name: yup.string().max(5, strings.stationNameLengthError).required(strings.required),
    dispatchAreaId: yup
        .number()
        .transform((value) => (!value ? 0 : value))
        .test('dispatchAreaVal', strings.required, (value) => !!value),
    agencyTypeGlobalAttrId: yup
        .number()
        .transform((value) => (!value ? 0 : value))
        .test('agencyTypeVal', strings.required, (value) => !!value),
});

const stationForm = new _Form({
    name: STATION_FORM,
    configuration: createFormConfiguration({
        id: {},
        name: {},
        dispatchAreaId: {},
        isActive: {},
        agencyTypeGlobalAttrId: {},
    }),
    onValidate: ({ formState, eventType }) =>
        validationSchema
            .validate(formState.model, { abortEarly: false })
            .then((validationResult) => {
                return convertYupSuccessShapeToMFTSuccessShape(
                    validationResult,
                    formState.ui.$form
                );
            })
            .catch((validationErrors) => {
                return convertYupErrorShapeToMFTErrorShape(
                    validationErrors,
                    formState.ui.$form,
                    eventType
                );
            }),
    validationEvents: [
        {
            eventType: formEvents.FORM_SUBMIT,
        },
        {
            eventType: formEvents.INPUT_CHANGE,
        },
        {
            eventType: formEvents.INPUT_BLUR,
        },
    ],
});

formsRegistry.register(stationForm);

export default stationForm;

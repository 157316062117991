import React, { Component, useCallback, useContext } from 'react';
import { _Form } from 'markformythree';
import { withRouter } from 'react-router';
import { get, map } from 'lodash';
import { ElasticSearchTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import {
    FederatedSearchClient,
    FederatedSearchExternalWriteDataContext,
    QueryableEntityType,
} from 'mark43-federated-search';
import { Box, useToast } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatNameReportLinkTypeIdSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import { personProfileByIdSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    isUnknown,
    formatShortName,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import overlayStateTypeEnum from '~/client-common/core/enums/client/overlayStateTypeEnum';
import { offensesSelector } from '~/client-common/core/domain/offenses/state/data';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { queryParamDefaults } from '~/client-common/configs/advancedSearchConfig';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import OverlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { overlayStore } from '../../../../core/overlayManager';
import formsRegistry from '../../../../core/formsRegistry';
import elasticSearchResource from '../../../../legacy-redux/resources/elasticSearchResource';
import { BodyMediumText } from '../../components/typography';
import testIds from '../../../../core/testIds';
import { OverlayBaseHelper } from '../../components/OverlayBaseHelper';
import BackBanner from '../../components/SidePanel/BackBanner';
import { SidePanelSearchResults } from '../../components/SidePanel/SidePanelSearchResults';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import {
    personSearchToAddSearch,
    unregisterForm as unregisterPersonSearchToAddForm,
} from '../../../search/search-to-add/state/ui';
import { requestRecentPersonsOrOrganizations } from '../../../../legacy-redux/actions/recentEntitiesActions';
import _Button from '../../../../legacy-redux/components/core/Button';
import { isProcessingUploadingAttachmentsSelector } from '../../../attachments/core/state/ui/sharedAttachmentsSelectors';
import { InfoMessage } from '../../components/InfoMessage';
import { NAME_SIDE_PANEL_SCROLL_OFFSET } from '../../names/config';
import { savePersonProfileForm, SCREENS } from '../state/data';
import { KNOWN_PERSON, UNKNOWN_PERSON } from '../config/personProfileFormPersonTypes';
import { getCollapsibleSectionConfigForLinkType } from '../utils/getCollapsibleSectionConfigForLinkType';
import { PERSON_PROFILE_FORM_FIRST_NAME } from '../config/personProfileFieldIds';
import { PersonSidePanelContextProvider, usePersonSidePanelContext } from '../context';
import { focusFieldById } from '../utils/focusFieldById';
import _ElasticPersonPill from './ElasticPersonPill';
import { PersonProfileForm } from './PersonProfileForm';
import { PersonInvolvmentTypeSelectionForm } from './PersonInvolvementTypeSelectionForm';
import { PersonSearchToAddForm } from './PersonSearchToAddForm';
import PersonSearchToAdd from './PersonSearchToAdd';
import { OverrideProfileModal } from './OverrideProfileModal';

const strings = componentStrings.core.PersonSidePanel;

const Button = styled(_Button)`
    margin: 0;
`;

const mapStateToProps = createStructuredSelector({
    formatNameReportLinkTypeId: formatNameReportLinkTypeIdSelector,
    personProfileById: personProfileByIdSelector,
    isProcessingUploadingAttachments: isProcessingUploadingAttachmentsSelector,
    offenses: offensesSelector,
    applicationSettings: applicationSettingsSelector,
});

const ElasticPersonPill = styled(_ElasticPersonPill)`
    margin-bottom: 10px;
`;

const ScreenContainer = styled.div`
    padding: 20px;
`;

const SearchResultCount = styled.div`
    white-space: nowrap;
    line-height: 1;
`;

const PersonSidePanelWithoutOverlayBase = compose(
    withRouter,
    connect(mapStateToProps, {
        savePersonProfileForm,
        personSearchToAddSearch,
        requestRecentPersonsOrOrganizations,
        unregisterPersonSearchToAddForm,
    })
)(
    class PersonSidePanelWithoutOverlayBaseUnconnected extends Component {
        state = {
            // sadly we have to lift this state up this high in the tree because our form
            // submission gets triggered here and we need to be able to toggle sections when submitting
            formSectionState: getCollapsibleSectionConfigForLinkType(this.props.linkType),
        };

        componentDidMount = () => {
            const { initialSearch } = this.props;
            const dexPerson = this.getDexPerson();
            if (initialSearch && dexPerson) {
                const existedPersonSearchForm = formsRegistry.get(
                    formClientEnum.PERSON_SEARCH_TO_ADD_FORM
                );

                const quickSearchQuery =
                    dexPerson.name || joinTruthyValues([dexPerson.firstName, dexPerson.lastName]);
                if (existedPersonSearchForm) {
                    existedPersonSearchForm.set('quickSearchQuery', quickSearchQuery);
                    existedPersonSearchForm.set(
                        'dateOfBirthQuickSearchQuery',
                        dexPerson.dateOfBirth
                    );
                } else {
                    const form = new _Form({
                        name: formClientEnum.PERSON_SEARCH_TO_ADD_FORM,
                        initialState: {
                            quickSearchQuery,
                            dateOfBirthQuickSearchQuery: dexPerson.dateOfBirth,
                        },
                    });

                    formsRegistry.register(form);
                }
                this.executePersonSearchToAddSearch();
            }
        };

        openOverridePersonModal = ({ selectedId, dexPerson }) => {
            overlayStore.open(OverlayIdEnum.OVERRIDE_PROFILE_MODAL, {
                selectedId,
                dexPerson,
            });
        };

        updateFormSectionState = (newSectionState) =>
            this.setState({
                formSectionState: {
                    ...this.state.formSectionState,
                    ...newSectionState,
                },
            });

        onOverridePerson = ({ selectedId, dexPerson }) => {
            this.handleClickSearchResult(selectedId, dexPerson);
        };

        onCancelOverride = ({ selectedId }) => {
            this.handleClickSearchResult(selectedId);
        };

        handleClickSearchResult = (selectedId, dexPerson) =>
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_EDIT, {
                selectedId,
                dexPerson,
            });

        handleClickNewProfileFromCadProfile = () => {
            this.props.overlayBase.overlayState.customProperties.cadProfileOwnerType =
                EntityTypeEnum.CAD_AGENCY_EVENT.name;
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_EDIT, {
                selectedId: this.getCadProfileId(),
            });
        };

        handleClickAddNew = (dexPerson) =>
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_ADD_NEW, {
                selectedId: undefined,
                dexPerson,
            });

        executePersonSearchToAddSearch = ({ isViewMore, isIdentify, cadProfileId } = {}) => {
            this.props.overlayBase.setLoading(true);
            this.props.personSearchToAddSearch({
                from: isViewMore
                    ? this.props.screenManagerApi.getCurrentScreen().screenState.searchResultIds
                          .length
                    : undefined,
                onError: (err) => {
                    this.props.overlayBase.setLoading(false);
                    this.props.screenManagerApi.setCurrentScreenState({
                        errorMessages: [err.message],
                    });
                },
                onSuccess: (results) => {
                    this.props.overlayBase.setLoading(false);
                    this.props.screenManagerApi.setCurrentScreenState((state) => ({
                        errorMessages: [],
                        ...(isViewMore
                            ? {
                                  searchResultIds: [
                                      ...state.searchResultIds,
                                      ...map(get(results, 'items'), 'id'),
                                  ],
                              }
                            : {}),
                    }));
                    if (!isViewMore) {
                        const knownResultScreen = cadProfileId
                            ? SCREENS.CAD_PROFILE_SEARCH_RESULTS
                            : SCREENS.SEARCH_RESULTS;
                        const nextScreen = isIdentify
                            ? SCREENS.IDENTIFY_UNKNOWN_SEARCH_RESULTS
                            : knownResultScreen;
                        this.props.screenManagerApi.goToNextScreen(nextScreen, {
                            searchResultIds: map(get(results, 'items'), 'id'),
                            totalSearchResultCount: get(results, 'totalCount'),
                            idToIdentify: isIdentify ? this.getIdToIdentify() : null,
                            dexPerson: this.getDexPerson(),
                        });
                    }
                },
            });
        };

        handleClickAddUnknown = () =>
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_ADD_UNKNOWN);
        handleClickIdentify = (idToIdentify) =>
            this.props.screenManagerApi.goToNextScreen(SCREENS.IDENTIFY_UNKNOWN_SEARCH, {
                idToIdentify,
            });

        handleClickSearch = () => this.executePersonSearchToAddSearch();
        handleClickCadProfileSearch = () =>
            this.executePersonSearchToAddSearch({ cadProfileId: this.getCadProfileId() });
        handleClickIdentifySearch = () => this.executePersonSearchToAddSearch({ isIdentify: true });
        handleClickViewMore = () => this.executePersonSearchToAddSearch({ isViewMore: true });
        handleClickIdentifyNewPerson = () =>
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_IDENTIFY_NEW, {
                idToIdentify: this.getIdToIdentify(),
            });
        handleClickIdentifyExistingPerson = (personId) =>
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_IDENTIFY_EXISTING, {
                idToIdentify: this.getIdToIdentify(),
                selectedId: personId,
            });

        handleClickPersonInvolvementSubjectType = () => {
            const selectedId = this.getSelectedId();

            if (selectedId) {
                this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_EDIT, {
                    selectedId,
                });
            } else {
                this.props.screenManagerApi.goToNextScreen(SCREENS.SEARCH_FORM);
            }
        };

        handleSavePersonProfileForm = () =>
            this.props.savePersonProfileForm({
                appendPanelErrorMessageIfNotExists: this.props.screenManagerApi
                    .appendPanelErrorMessageToCurrentScreenIfNotExists,
                resetPanelErrorMessages: this.props.screenManagerApi
                    .resetPanelErrorMessagesForCurrentScreen,
                overlayId: this.props.overlayId,
                isEditingMasterProfile: this.getIsEditingMasterProfileId(),
                // If we're directly editing a person profile for a given id, then
                // we are passed in their `entityId`.
                isEditingExistingPerson: !!this.getEntityId(),
                updateFormSectionState: this.updateFormSectionState,
                linkType: this.props.linkType,
                formConfigurationLinkType: this.props.formConfigurationLinkType,
                onAddSuccess: this.props.onAddSuccess,
                contextId: this.props.contextId,
                contextType: this.props.contextType,
                ownerId: this.getOwnerId(),
                ownerType: this.props.ownerType,
                entityId: this.getEntityId(),
                savePanel: (id) => {
                    this.props.savePanel();
                    if (this.props.resetDexResult) {
                        this.props.resetDexResult();
                    }
                    if (this.getRedirectAfterSave()) {
                        this.props.toast({ description: strings.savedSuccessfully });
                        this.props.router.push(`/profiles/persons/${id}`);
                    }
                },
                selectedSubjectTypeAttrId: this.props.selectedSubjectTypeAttrId,
                cadProfileId: this.getCadProfileId(),
                refreshRecentPersons: this.requestRecentPersons,
            });

        // Even though we may know from the form that this is
        // unknown (b/c its missing first/last name),
        // lets explicitly define a different action that
        // gets dispatched here to handle unknowns
        handleIdentifyUnknownPersonProfileForm = () =>
            this.props.savePersonProfileForm({
                appendPanelErrorMessageIfNotExists: this.props.screenManagerApi
                    .appendPanelErrorMessageToCurrentScreenIfNotExists,
                resetPanelErrorMessages: this.props.screenManagerApi
                    .resetPanelErrorMessagesForCurrentScreen,
                overlayId: this.props.overlayId,
                idToIdentify: this.getIdToIdentify(),
                updateFormSectionState: this.updateFormSectionState,
                linkType: this.props.linkType,
                formConfigurationLinkType: this.props.formConfigurationLinkType,
                onAddSuccess: this.props.onAddSuccess,
                contextId: this.props.contextId,
                contextType: this.props.contextType,
                ownerId: this.getOwnerId(),
                ownerType: this.props.ownerType,
                entityId: this.getEntityId(),
                savePanel: this.props.savePanel,
                cadProfileId: this.getCadProfileId(),
                refreshRecentPersons: this.props.onAddSuccess
                    ? this.requestRecentPersons
                    : undefined,
                resetDexResult: this.props.resetDexResult,
            });

        // If we have a value in state, use it
        // If not and we have a value passed in as a prop, use that instead.
        getSelectedId = () =>
            this.props.screenManagerApi.getCurrentScreen().screenState.selectedId ||
            this.props.entityId;

        handleClickNotepadEntity = ({ id: selectedId } = {}) =>
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_EDIT, {
                selectedId,
            });

        getIdToIdentify = () =>
            this.props.screenManagerApi.getCurrentScreen().screenState.idToIdentify;

        getCadProfileId = () => this.props.overlayBase.overlayState.customProperties.cadProfileId;

        getOwnerId = () =>
            this.props.overlayBase.overlayState.customProperties.ownerId || this.props.ownerId;

        getDexPerson = () =>
            this.props.overlayBase.overlayState.customProperties.dexPerson ||
            this.props.screenManagerApi.getCurrentScreen().screenState.dexPerson;

        getEntityId = () =>
            this.props.overlayBase.overlayState.customProperties.entityId || this.props.entityId;

        getIsEditingMasterProfileId = () =>
            this.props.overlayBase.overlayState.customProperties.isEditingMasterProfile ||
            this.props.isEditingMasterProfile;

        getRedirectAfterSave = () =>
            this.props.overlayBase.overlayState.customProperties.redirectAfterSave;

        getOnCloseAction = () => this.props.overlayBase.overlayState.customProperties.onCloseAction;

        renderPersonProfileForm({
            ownerType,
            ownerId,
            linkType,
            formConfigurationLinkType,
            idToEdit,
            personType,
            focusOnMount,
            formSectionState,
            dexPerson,
            cadProfileId,
        }) {
            return (
                <PersonProfileForm
                    nibrsOffenseCode={this.props.nibrsOffenseCode}
                    ownerType={ownerType}
                    ownerId={ownerId}
                    linkType={linkType}
                    appendPanelErrorMessageIfNotExists={
                        this.props.screenManagerApi
                            .appendPanelErrorMessageToCurrentScreenIfNotExists
                    }
                    removePanelErrorMessage={
                        this.props.screenManagerApi.removePanelErrorMessageForCurrentScreen
                    }
                    setSaveButtonDisabled={
                        this.props.screenManagerApi.setSaveButtonDisabledForCurrentScreen
                    }
                    updateFormSectionState={this.updateFormSectionState}
                    formSectionState={formSectionState || this.state.formSectionState}
                    id={idToEdit}
                    personType={personType}
                    focusFieldOnMount={focusOnMount}
                    isEditingMasterProfile={this.props.isEditingMasterProfile}
                    formConfigurationLinkType={formConfigurationLinkType}
                    dexPerson={dexPerson}
                    cadProfileId={cadProfileId}
                />
            );
        }

        searchPersons = async (searchString) => {
            const response = await elasticSearchResource.searchAll({
                size: queryParamDefaults.SIZE,
                query: searchString,
                searchTypes: [ElasticSearchTypeEnum.PERSON.name],
            });
            return response.persons;
        };

        addToProfile = ({ profileId, result }) => {
            if (profileId) {
                const dexPerson = result.PERSON[0];
                this.openOverridePersonModal({ selectedId: profileId, dexPerson });
            } else {
                const dexPerson = result.PERSON[0];
                this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_ADD_NEW, {
                    selectedId: undefined,
                    dexPerson,
                });
            }
        };

        renderSearchResults({
            isLoading,
            searchResultIds,
            totalSearchResultCount,
            onClickAddNew,
            onPersonPillClick,
        }) {
            return (
                <SidePanelSearchResults
                    onClickAddNew={onClickAddNew}
                    onClickViewMore={this.handleClickViewMore}
                    loading={isLoading}
                    searchResultIds={searchResultIds}
                    renderSearchResult={(personId) => (
                        <ElasticPersonPill
                            key={personId}
                            id={personId}
                            onClick={() => onPersonPillClick(personId)}
                        />
                    )}
                    showViewMore={
                        !!searchResultIds.length && searchResultIds.length < totalSearchResultCount
                    }
                    noResultsString={strings.SidePanelSearchResults.noResults}
                    addNewString={strings.SidePanelSearchResults.addNew}
                    viewMoreString={strings.SidePanelSearchResults.viewMore}
                    addNewButtonTestId={testIds.NAME_SIDE_PANEL_ADD_NEW_NAME}
                />
            );
        }

        renderPersonProfileInvolvementScreen() {
            const personEntityId = this.getSelectedId();
            return (
                <PersonInvolvmentTypeSelectionForm
                    onNavigate={this.handleClickPersonInvolvementSubjectType}
                    personEntityId={personEntityId}
                />
            );
        }

        renderInfoMessage({ personId }) {
            return (
                <InfoMessage>
                    <BodyMediumText>
                        {strings.identifyUnknown(
                            formatShortName(this.props.personProfileById(personId))
                        )}
                    </BodyMediumText>
                </InfoMessage>
            );
        }

        onClickSearchFromDex = () => {
            this.props.screenManagerApi.goToNextScreen(SCREENS.DEX_SIDE_PANEL);
        };

        getCurrentScreenConfiguration() {
            const {
                screen,
                screenState: {
                    errorMessages = [],
                    saveDisabled,
                    searchResultIds,
                    totalSearchResultCount,
                },
                formSectionState,
            } = this.props.screenManagerApi.getCurrentScreen();
            const {
                overlayBase: { isAtBottomOfStack, overlayState },
                personProfileById,
                formatNameReportLinkTypeId,
                ownerType,
                ownerId,
                renForRecents,
                formConfigurationLinkType,
                selectedSubjectTypeAttrId,
                linkType,
                contextId,
                hideRecentEntities,
                closePanel,
                applicationSettings,
            } = this.props;
            const { isLoading, customProperties } = overlayState;

            const baseBannerProps = {
                onClickBack: this.props.screenManagerApi.goToPreviousScreen,
            };

            const basePortalProps = {
                isAtBottomOfStack,
                noPadding: true,
                closePanel: () => {
                    closePanel();
                    this.getOnCloseAction()?.();
                },
                errorMessages,
                userHasAttemptedSave: !!errorMessages.length,
                title: strings.title(formatNameReportLinkTypeId(linkType)),
                saveDisabled,
                saving: overlayState.customProperties.isSaving,
            };

            switch (screen) {
                case SCREENS.SELECT_PERSON_INVOLVEMENT_TYPE:
                    return {
                        bannerProps: baseBannerProps,
                        portalProps: {
                            ...basePortalProps,
                            saveText: null,
                        },
                        component: this.renderPersonProfileInvolvementScreen(),
                    };
                case SCREENS.SEARCH_FORM:
                    return {
                        bannerProps: baseBannerProps,
                        portalProps: {
                            ...basePortalProps,
                            onRest: hideRecentEntities ? null : this.requestRecentPersons,
                            saveText: null,
                            action: applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED
                                ? this.handleClickAddNew
                                : undefined,
                            actionText: applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED
                                ? strings.createNewProfile
                                : '',
                        },
                        component: (
                            <PersonSearchToAdd
                                onNotepadEntityClick={this.handleClickNotepadEntity}
                                onClickIdentify={this.handleClickIdentify}
                                onClickAddUnknown={this.handleClickAddUnknown}
                                onClickSearch={this.handleClickSearch}
                                loading={isLoading}
                                onClickRecentEntity={this.handleClickSearchResult}
                                ownerType={ownerType}
                                ownerId={ownerId}
                                contextId={contextId}
                                renForRecents={renForRecents}
                                linkType={linkType}
                                hideRecentEntities={hideRecentEntities}
                                onClickSearchFromDex={this.onClickSearchFromDex}
                                searchFromDexButtonTestId={testIds.SEARCH_FROM_DEX}
                                searchFromDexString={strings.addFromDexQuery}
                            />
                        ),
                    };
                case SCREENS.SEARCH_RESULTS:
                    return {
                        bannerProps: {
                            ...baseBannerProps,
                            rightContent: searchResultIds.length ? (
                                <FeatureFlagged
                                    flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                                    fallback={
                                        <SearchResultCount>
                                            <BodyMediumText fontWeight="semibold">
                                                1-{searchResultIds.length}
                                                <BodyMediumText as="span">
                                                    {' '}
                                                    {strings.of}{' '}
                                                </BodyMediumText>
                                                {totalSearchResultCount}
                                            </BodyMediumText>
                                        </SearchResultCount>
                                    }
                                />
                            ) : undefined,
                        },
                        portalProps: {
                            ...basePortalProps,
                            saveText: null,
                            action: applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED
                                ? this.handleClickAddNew
                                : undefined,
                            actionText: applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED
                                ? strings.createNewProfile
                                : '',
                        },
                        component: (
                            <>
                                <FeatureFlagged flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED">
                                    <PersonSearchToAdd
                                        onNotepadEntityClick={this.handleClickNotepadEntity}
                                        onClickIdentify={this.handleClickIdentify}
                                        onClickAddUnknown={this.handleClickAddUnknown}
                                        onClickSearch={this.handleClickSearch}
                                        loading={isLoading}
                                        onClickRecentEntity={this.handleClickSearchResult}
                                        ownerType={ownerType}
                                        ownerId={ownerId}
                                        contextId={contextId}
                                        renForRecents={renForRecents}
                                        linkType={linkType}
                                        hideRecentEntities={hideRecentEntities}
                                    />
                                </FeatureFlagged>
                                {this.renderSearchResults({
                                    onClickAddNew: () => {
                                        this.handleClickAddNew(this.getDexPerson());
                                    },
                                    onPersonPillClick: (selectedId) => {
                                        const dexPerson = this.getDexPerson();
                                        if (dexPerson) {
                                            this.openOverridePersonModal({ selectedId, dexPerson });
                                        } else {
                                            this.handleClickSearchResult(selectedId);
                                        }
                                    },
                                    isLoading,
                                    searchResultIds,
                                    totalSearchResultCount,
                                })}
                            </>
                        ),
                    };
                case SCREENS.PROFILE_ADD_NEW:
                case SCREENS.PROFILE_ADD_UNKNOWN: {
                    const personType =
                        screen === SCREENS.PROFILE_ADD_NEW ? KNOWN_PERSON : UNKNOWN_PERSON;
                    return {
                        bannerProps: baseBannerProps,
                        portalProps: {
                            ...basePortalProps,
                            savePanel: this.handleSavePersonProfileForm,
                            scrollOffset: NAME_SIDE_PANEL_SCROLL_OFFSET,
                        },
                        component: this.renderPersonProfileForm({
                            ownerType,
                            ownerId: this.getOwnerId(),
                            linkType,
                            formConfigurationLinkType,
                            selectedSubjectTypeAttrId,
                            personType,
                            focusOnMount: PERSON_PROFILE_FORM_FIRST_NAME,
                            dexPerson: this.getDexPerson(),
                        }),
                    };
                }
                case SCREENS.DEX_SIDE_PANEL:
                    return {
                        bannerProps: {
                            ...baseBannerProps,
                            backButtonText: strings.backToSearchPerson,
                        },
                        portalProps: {
                            ...basePortalProps,
                            saveText: null,
                        },
                        component: (
                            // Federated Search should be inserted in a fixed size container to be able to display
                            // pagination container stick to the botton
                            // calcualte height panelHeader, backButton, sodePanelPadding, panelFooter
                            // backButton visibility depends on RMS_CAD_DATA_ENTITY_PREFILL_ENABLED
                            <Box
                                sx={{
                                    height: `calc(100vh - 41px - ${
                                        applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED
                                            ? 0
                                            : 65
                                    }px - 40px - 54px)`,
                                }}
                            >
                                <FederatedSearchClient
                                    buttonsConfiguration={{
                                        profile: {
                                            addToProfile: this.addToProfile,
                                            searchProfiles: this.searchPersons,
                                            buttonTitle: strings.addToReport,
                                            entityTypes: [QueryableEntityType.PERSON],
                                        },
                                    }}
                                />
                            </Box>
                        ),
                    };
                case SCREENS.CAD_PROFILE_SEARCH:
                    return {
                        portalProps: {
                            ...basePortalProps,
                            onRest: hideRecentEntities ? null : this.requestRecentPersons,
                            saveText: null,
                            action: this.handleClickNewProfileFromCadProfile,
                            actionText: strings.createNewProfile,
                        },
                        component: (
                            <PersonSearchToAdd
                                onNotepadEntityClick={this.handleClickNotepadEntity}
                                onClickIdentify={this.handleClickIdentify}
                                onClickAddUnknown={this.handleClickAddUnknown}
                                onClickSearch={this.handleClickCadProfileSearch}
                                loading={isLoading}
                                onClickRecentEntity={this.handleClickSearchResult}
                                ownerType={ownerType}
                                ownerId={ownerId}
                                contextId={contextId}
                                renForRecents={renForRecents}
                                linkType={linkType}
                                hideRecentEntities={hideRecentEntities}
                            />
                        ),
                    };
                case SCREENS.CAD_PROFILE_SEARCH_RESULTS:
                    return {
                        portalProps: {
                            ...basePortalProps,
                            saveText: null,
                            action: this.handleClickNewProfileFromCadProfile,
                            actionText: strings.createNewProfile,
                        },
                        component: (
                            <>
                                <PersonSearchToAdd
                                    onNotepadEntityClick={this.handleClickNotepadEntity}
                                    onClickIdentify={this.handleClickIdentify}
                                    onClickAddUnknown={this.handleClickAddUnknown}
                                    onClickSearch={this.handleClickIdentifyCadProfileSearch}
                                    loading={isLoading}
                                    onClickRecentEntity={this.handleClickSearchResult}
                                    ownerType={ownerType}
                                    ownerId={ownerId}
                                    contextId={contextId}
                                    renForRecents={renForRecents}
                                    linkType={linkType}
                                    hideRecentEntities={hideRecentEntities}
                                />
                                {this.renderSearchResults({
                                    onClickAddNew: this.handleClickAddNew,
                                    onPersonPillClick: this.handleClickSearchResult,
                                    isLoading,
                                    searchResultIds,
                                    totalSearchResultCount,
                                })}
                            </>
                        ),
                    };
                case SCREENS.PROFILE_IDENTIFY_NEW:
                case SCREENS.PROFILE_IDENTIFY_EXISTING: {
                    const idToEdit =
                        screen === SCREENS.PROFILE_IDENTIFY_NEW
                            ? this.getIdToIdentify()
                            : this.getSelectedId();
                    return {
                        bannerProps: baseBannerProps,
                        portalProps: {
                            ...basePortalProps,
                            savePanel: this.handleIdentifyUnknownPersonProfileForm,
                        },
                        component: (
                            <>
                                {this.renderInfoMessage({ personId: idToEdit })}
                                {this.renderPersonProfileForm({
                                    ownerType,
                                    ownerId,
                                    linkType,
                                    formConfigurationLinkType,
                                    selectedSubjectTypeAttrId,
                                    idToEdit,
                                    personType: KNOWN_PERSON,
                                    focusOnMount: PERSON_PROFILE_FORM_FIRST_NAME,
                                })}
                            </>
                        ),
                    };
                }
                case SCREENS.PROFILE_EDIT: {
                    const idToEdit = this.getSelectedId();
                    return {
                        bannerProps: baseBannerProps,
                        portalProps: {
                            ...basePortalProps,
                            // we have to focus the element after the side panel animation
                            // because else it will get cancelled by the browser trying to instantly
                            // move the focused element into view.
                            // This is a fairly hacky solution, but we cannot rely on `didMount`
                            // in the actual form component or use `autoFocus` for the text input
                            // because of the above issue.
                            onRest: () =>
                                focusFieldById(
                                    this.props.elementToFocusId ?? PERSON_PROFILE_FORM_FIRST_NAME
                                ),
                            savePanel: this.handleSavePersonProfileForm,
                            scrollOffset: NAME_SIDE_PANEL_SCROLL_OFFSET,
                        },
                        component: this.renderPersonProfileForm({
                            ownerType: customProperties?.cadProfileOwnerType ?? ownerType,
                            ownerId: this.getOwnerId(),
                            formConfigurationLinkType,
                            selectedSubjectTypeAttrId,
                            linkType,
                            idToEdit,
                            // we have to use `onRest` and this prop because sometimes the edit
                            // screen gets opened right away and sometimes it is a nested screen
                            focusOnMount:
                                this.props.elementToFocusId ?? PERSON_PROFILE_FORM_FIRST_NAME,
                            personType: isUnknown(personProfileById(idToEdit))
                                ? UNKNOWN_PERSON
                                : KNOWN_PERSON,
                            formSectionState,
                            dexPerson: this.props.screenManagerApi.getCurrentScreen().screenState
                                .dexPerson,
                            cadProfileId: this.getCadProfileId(),
                        }),
                    };
                }
                case SCREENS.IDENTIFY_UNKNOWN_SEARCH: {
                    const idToIdentify = this.getIdToIdentify();
                    return {
                        bannerProps: baseBannerProps,
                        portalProps: {
                            ...basePortalProps,
                            saveText: null,
                        },
                        component: (
                            <>
                                {this.renderInfoMessage({
                                    personId: idToIdentify,
                                })}
                                <PersonSearchToAddForm
                                    onClickSearch={this.handleClickIdentifySearch}
                                    loading={isLoading}
                                />
                            </>
                        ),
                    };
                }
                case SCREENS.IDENTIFY_UNKNOWN_SEARCH_RESULTS:
                    return {
                        bannerProps: {
                            ...baseBannerProps,
                            rightContent: (
                                <FeatureFlagged
                                    flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                                    fallback={
                                        <Button
                                            onClick={this.handleClickIdentifyNewPerson}
                                            testId={testIds.PERSON_PROFILE_CREATE_NEW_BUTTON}
                                        >
                                            {strings.identifyNewUnknown}
                                        </Button>
                                    }
                                />
                            ),
                        },
                        portalProps: {
                            ...basePortalProps,
                            saveText: null,
                        },
                        component: this.renderSearchResults({
                            onPersonPillClick: this.handleClickIdentifyExistingPerson,
                            isLoading,
                            searchResultIds,
                            totalSearchResultCount,
                        }),
                    };
                default:
                    return {
                        bannerProps: baseBannerProps,
                        portalProps: {
                            ...basePortalProps,
                            savePanel: this.handleSavePersonProfileForm,
                        },
                        component: null,
                    };
            }
        }

        requestRecentPersons = () => {
            const {
                requestRecentPersonsOrOrganizations,
                renForRecents,
                ownerType,
                ownerId,
            } = this.props;

            return requestRecentPersonsOrOrganizations({
                renForRecents,
                ownerType,
                ownerId,
                isOrg: false,
            });
        };

        renderBackBanner(bannerProps) {
            return this.props.screenManagerApi.getScreenStackSize() > 1 ? (
                <BackBanner {...bannerProps} />
            ) : null;
        }

        componentWillUnmount() {
            this.props.unregisterPersonSearchToAddForm(formClientEnum.PERSON_SEARCH_TO_ADD_FORM);
        }

        render() {
            const { screen } = this.props.screenManagerApi.getCurrentScreen();

            if (
                this.props.overlayBase.overlayState.customProperties.autoSearch === true &&
                screen === SCREENS.CAD_PROFILE_SEARCH &&
                !this.props.overlayBase.overlayState.isLoading
            ) {
                this.handleClickCadProfileSearch();
                this.props.overlayBase.overlayState.customProperties.autoSearch = false;
            }

            const { component, bannerProps, portalProps } = this.getCurrentScreenConfiguration();
            return (
                <PortalSidePanel
                    {...portalProps}
                    saveDisabled={this.props.isProcessingUploadingAttachments}
                    testId={testIds.PERSON_SIDE_PANEL}
                >
                    <FeatureFlagged
                        flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                        fallback={this.renderBackBanner(bannerProps)}
                    />
                    <ScreenContainer>{component}</ScreenContainer>
                    <OverrideProfileModal
                        onOverride={this.onOverridePerson}
                        onKeepExisting={this.onCancelOverride}
                    />
                </PortalSidePanel>
            );
        }
    }
);

const initialState = ({
    entityId,
    linkType,
    isPersonInvolvementTypeEnabled,
    dexPerson,
    initialSearch,
    cadDataEntityPrefillEnabled,
}) => {
    if (dexPerson) {
        const screen = initialSearch
            ? cadDataEntityPrefillEnabled
                ? SCREENS.SEARCH_RESULTS
                : SCREENS.SEARCH_FORM
            : SCREENS.PROFILE_ADD_NEW;
        return {
            screenStack: [
                {
                    screen,
                    screenState: {
                        dexPerson,
                    },
                },
            ],
        };
    }
    const editPersonProfileScreen =
        isPersonInvolvementTypeEnabled && linkType === LinkTypesEnum.INVOLVED_PERSON_IN_REPORT
            ? SCREENS.SELECT_PERSON_INVOLVEMENT_TYPE
            : SCREENS.PROFILE_EDIT;

    const newPersonProfileScreen =
        isPersonInvolvementTypeEnabled && linkType === LinkTypesEnum.INVOLVED_PERSON_IN_REPORT
            ? SCREENS.SELECT_PERSON_INVOLVEMENT_TYPE
            : SCREENS.SEARCH_FORM;

    return {
        screenStack: [
            {
                screen: entityId ? editPersonProfileScreen : newPersonProfileScreen,
            },
        ],
    };
};

const BasePersonSidePanelWithoutOverlayBase = (props) => {
    const { selectedLinkType, subjectTypeAttrId } = usePersonSidePanelContext();
    const applicationSettings = useSelector(applicationSettingsSelector);

    const formConfigurationLinkType =
        applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED &&
        props.linkType === LinkTypesEnum.INVOLVED_PERSON_IN_REPORT
            ? selectedLinkType
            : props.linkType;

    const selectedSubjectTypeAttrId =
        applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED &&
        props.linkType === LinkTypesEnum.INVOLVED_PERSON_IN_REPORT
            ? subjectTypeAttrId
            : undefined;

    return (
        <PersonSidePanelWithoutOverlayBase
            {...props}
            formConfigurationLinkType={formConfigurationLinkType}
            selectedSubjectTypeAttrId={selectedSubjectTypeAttrId}
        />
    );
};

/**
 * @param {Number}          contextId           The entity we are linking this person to
 * @param {String}          contextType         The type of entity we are linking this person to
 * @param {Number}          [entityId]          The id of the entity to edit. If not provided, show the search to add view
 * @param {String}          linkType            The `linkTypesEnum` to link this person with
 * @param {Function}        [onAddSuccess]      Callback to get executed after a person is saved
 * @param {Number|String}   [renForRecents]     REN of the report we'd like to fetch involved entities for
 * @param {String}          ownerType           Type of the owning entity (either `report` or `warrant`)
 * @param {Number}          ownerId             Id of the owning entity
 * @param {Number}          overlayId
 * @param {String}          elementToFocusId    HTMLElement id to focus and scroll on mount. Implemented only for SCREENS.PROFILE_EDIT
 * @param {Number}          renderButton
 * @param {Record<string, unknown>} dexPerson initial person profile form dex
 */
export const PersonSidePanel = ({ renderButton, saveRef, dexPerson, ...props }) => {
    const { overlayId, entityId, linkType, initialSearch } = props;

    const { resetResultForConsumerApp: resetDexResult } =
        useContext(FederatedSearchExternalWriteDataContext) || {};
    const toast = useToast();

    const applicationSettings = useSelector(applicationSettingsSelector);
    const getInitialCustomPropertyState = useCallback(
        () =>
            initialState({
                entityId,
                linkType,
                isPersonInvolvementTypeEnabled:
                    applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED,
                dexPerson,
                initialSearch,
                cadDataEntityPrefillEnabled:
                    applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED,
            }),
        [
            entityId,
            linkType,
            applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED,
            dexPerson,
            initialSearch,
            applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED,
        ]
    );
    return (
        <OverlayBaseHelper
            id={overlayId}
            overlayStateType={overlayStateTypeEnum.PERSON_OVERLAY}
            getInitialCustomPropertyState={getInitialCustomPropertyState}
            renderButton={renderButton}
            saveRef={saveRef}
        >
            {(renderProps) => (
                <PersonSidePanelContextProvider>
                    <BasePersonSidePanelWithoutOverlayBase
                        {...props}
                        {...renderProps}
                        // dexPerson={dexPerson}
                        resetDexResult={resetDexResult}
                        toast={toast}
                    />
                </PersonSidePanelContextProvider>
            )}
        </OverlayBaseHelper>
    );
};

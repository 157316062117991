import React, { createContext, useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { SubmissionMessageView, EntityTypeEnumType } from '@mark43/rms-api';
import { useResource } from '~/client-common/core/hooks/useResource';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { SubmissionMessagesContextT } from '../../types';
import submissionMessageResource from '../../resources/submissionMessageResource';
import { useAbilitySelector } from '../../../core/current-user/hooks/useAbilitySelector';
import { submissionsMessagesReducer, initialState, setSubmissionMessage } from './reducer';

export const SubmissionMessagesContext = createContext<SubmissionMessagesContextT | undefined>(
    undefined
);

export const SubmissionMessagesProvider: React.FC<{
    entityId: number;
    entityType: EntityTypeEnumType;
    children: React.ReactNode;
}> = ({ entityId, entityType, children }) => {
    const [state, dispatch] = useReducer(submissionsMessagesReducer, initialState);

    const canViewSubmissionMessages = useAbilitySelector(
        abilitiesEnum.CORE.VIEW_SUBMISSIONS_MESSAGES
    );

    const applicationSettings = useSelector(applicationSettingsSelector);
    const setSubmissionViews = useCallback(
        (submissionMessageViews: SubmissionMessageView[]) => {
            dispatch(setSubmissionMessage(submissionMessageViews));
        },
        [dispatch]
    );

    const fetchSubmissionMessagesForEntity = useCallback(() => {
        if (!applicationSettings.RMS_GENERIC_EXTERNAL_SUBMISSIONS_ENABLED) {
            return new Promise((resolve) => resolve([]));
        }

        // Note: For Reports, entityId can be passed in as null despite the type safety. When that happens
        // we shouldn't try to make an API request, since it will not return anything anyways.
        if (entityId && entityType && canViewSubmissionMessages) {
            return submissionMessageResource.getSubmissinMessagesForEntity(entityId, entityType);
        }
        return new Promise((resolve) => resolve([]));
    }, [applicationSettings, canViewSubmissionMessages, entityId, entityType]);

    const onFetchSubmissionMessagesForEntitySuccess = useCallback(
        (submissionMessageViews) => {
            setSubmissionViews(submissionMessageViews);
        },
        [setSubmissionViews]
    );

    useResource(fetchSubmissionMessagesForEntity, onFetchSubmissionMessagesForEntitySuccess);

    return (
        <SubmissionMessagesContext.Provider
            value={{
                state,
                actions: {
                    setSubmissionViews,
                },
            }}
        >
            {children}
        </SubmissionMessagesContext.Provider>
    );
};

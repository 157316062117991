import React from 'react';
import { useSelector } from 'react-redux';
import { _Form, Fieldset as MFTFieldset } from 'markformythree';
import { Menu, MenuTrigger, MenuContent, MenuItem, MenuListGroup } from 'arc';
import {
    EntityTypeEnum,
    LinkTypesEnumType,
    LocationView,
    LocationEntityLink,
    AttributeTypeEnum,
} from '@mark43/rms-api';
import { get, head, map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { Field } from '~/client-common/core/fields/state/config';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_ITEM_LOCATIONS_ADD_BUTTON,
    DISPLAY_ONLY_VEHICLE_ABANDONED_LOCATION,
    DISPLAY_ONLY_VEHICLE_BURNED_LOCATION,
    DISPLAY_ONLY_VEHICLE_REGISTRATION_LOCATION,
    DISPLAY_ONLY_VEHICLE_SEEN_LOCATION,
    DISPLAY_ONLY_VEHICLE_STOLEN_FROM_LOCATION,
} from '~/client-common/core/enums/universal/fields';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { locationBundlesForUnsavedLocationEntityLinksSelector } from '~/client-common/core/domain/locations/state/ui';
import {
    ItemLocationType,
    vehicleLocationsTypes,
    fieldByItemLocationType,
} from '~/client-common/helpers/itemLocationLinksHelper';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import { AddButton, MFTNItems } from '../../../../../core/forms/components/NItems';
import { LocationSidePanel } from '../../../../../core/locations/components/LocationSidePanel';
import testIds from '../../../../../../core/testIds';
import { HydratedItemFormConfiguration } from '../../../state/forms/hydratedItemFormConfiguration';
import { LocationSummaryView } from '../../../../../records/core/components/summaries/locations/LocationSummaryView';
import { locationEntityLinkSummaryVariants } from '../../../../../records/core/components/summaries/locations/LocationEntityLinkSummary';
import Row from '../../../../../core/components/Row';
import { ArbiterMFTTextArea } from '../../../../../core/forms/components/TextArea';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';

type LocationFieldsetProps = {
    itemProfileId: number;
    itemType: typeof globalAttributes.itemType[keyof typeof globalAttributes.itemType];
    form: _Form<HydratedItemFormConfiguration>;
};

const strings = componentStrings.search.fieldsets.ItemLocationFieldset;

const LocationsOptions: React.FC<{
    onSelect: (linkType: LinkTypesEnumType) => void;
    fieldDisplayNames: Record<Field, string>;
    locationTypes: ItemLocationType[];
}> = ({ onSelect, fieldDisplayNames, locationTypes }) => {
    return (
        <>
            {map(locationTypes, ({ type, field }) => (
                <MenuItem key={type} onSelect={() => onSelect(type)}>
                    {get(fieldDisplayNames, field)}
                </MenuItem>
            ))}
        </>
    );
};

const LocationsRenderWrapper: React.FC<{
    fieldDisplayNames: Record<Field, string>;
}> = ({ fieldDisplayNames }) => {
    const locationBundlesForUnsavedLocationEntityLinks = useSelector(
        locationBundlesForUnsavedLocationEntityLinksSelector
    );
    return (
        <MFTNItems
            path={'otherLocations'}
            addItemOnEmpty={false}
            renderAddButton={undefined}
            renderRemoveButton={undefined}
            render={({
                item,
                removeItem,
                index,
            }: {
                item: LocationEntityLink;
                removeItem: () => void;
                index: number;
            }) => {
                const field = fieldByItemLocationType[item.linkType];
                return (
                    <LocationSummaryView
                        saveRef={undefined}
                        onRemove={removeItem}
                        key={index}
                        customTitle={fieldDisplayNames[field]}
                        locationBundle={head(locationBundlesForUnsavedLocationEntityLinks([item]))}
                        index={index}
                        summaryMode={false}
                        locationEntityLinkSummaryVariant={
                            locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION_AND_START_DATE_UTC
                        }
                        hideQuickAdd={true}
                        hideCountry={false}
                        additionalLocationContent={() => (
                            <>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="positionAttrId"
                                        attributeType={AttributeTypeEnum.LOCATION_POSITION.name}
                                        length="lg"
                                    />
                                </Row>
                                <Row>
                                    <ArbiterMFTTextArea path="description" />
                                </Row>
                            </>
                        )}
                    />
                );
            }}
        />
    );
};

const LocationSelection: React.FC<{
    itemProfileId: number;
    form: _Form<HydratedItemFormConfiguration>;
    fieldDisplayNames: Record<Field, string>;
    locationTypes: ItemLocationType[];
}> = ({ itemProfileId, form, fieldDisplayNames, locationTypes }) => {
    const locationSidePanelProps = {
        entityType: EntityTypeEnum.ITEM_PROFILE.name,
        entityId: itemProfileId,
        id: 0,
        temp: true,
        saveRef: undefined,
        onSaveSuccess: (_: LocationView, entityLink: LocationEntityLink) => {
            form.push('itemLocations.otherLocations', entityLink);
        },
    };
    return (
        <LocationSidePanel
            overlayId={overlayIdEnum.LOCATION_OVERLAY_ITEM_LOCATIONS}
            renderButton={({
                overlayBase: { open, setCustomProperties },
                setCloseFocusRefs,
            }: {
                overlayBase: {
                    open: () => void;
                    setCustomProperties: (data: object) => void;
                };
                setCloseFocusRefs: React.Ref<HTMLButtonElement>;
            }) => {
                const onSelect = (linkType: LinkTypesEnumType) => {
                    open();
                    setCustomProperties({ linkType });
                };
                return (
                    <Menu>
                        <MenuTrigger>
                            <AddButton
                                setRef={setCloseFocusRefs}
                                testId={testIds.ITEM_SIDE_PANEL_ADD_LOCATIONS_BUTTON}
                            >
                                {fieldDisplayNames.DISPLAY_ONLY_ITEM_LOCATIONS_ADD_BUTTON}
                            </AddButton>
                        </MenuTrigger>
                        <MenuContent>
                            <MenuListGroup title={strings.menuTitle}>
                                <LocationsOptions
                                    onSelect={onSelect}
                                    fieldDisplayNames={fieldDisplayNames}
                                    locationTypes={locationTypes}
                                />
                            </MenuListGroup>
                        </MenuContent>
                    </Menu>
                );
            }}
            {...locationSidePanelProps}
        />
    );
};

export const ItemLocationFieldset: React.FC<LocationFieldsetProps> = ({
    itemProfileId,
    itemType,
    form,
}) => {
    const fieldDisplayNames = useFields([
        DISPLAY_ONLY_VEHICLE_ABANDONED_LOCATION,
        DISPLAY_ONLY_VEHICLE_BURNED_LOCATION,
        DISPLAY_ONLY_VEHICLE_REGISTRATION_LOCATION,
        DISPLAY_ONLY_VEHICLE_SEEN_LOCATION,
        DISPLAY_ONLY_VEHICLE_STOLEN_FROM_LOCATION,
        DISPLAY_ONLY_ITEM_LOCATIONS_ADD_BUTTON,
    ]);

    let locationTypes;

    if (itemType === globalAttributes.itemType.vehicle) {
        locationTypes = vehicleLocationsTypes;
    }

    return (
        <>
            {locationTypes && (
                <SidePanelSection title={strings.title}>
                    <MFTFieldset path="itemLocations">
                        <LocationsRenderWrapper fieldDisplayNames={fieldDisplayNames} />
                        <LocationSelection
                            itemProfileId={itemProfileId}
                            form={form}
                            fieldDisplayNames={fieldDisplayNames}
                            locationTypes={locationTypes}
                        />
                    </MFTFieldset>
                </SidePanelSection>
            )}
        </>
    );
};

import { StreetAlias, StreetCenterlineView } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getStreetSearchAliasResource from '../../resources/streetSearchAliasResource';
import getStreetCenterlineResource from '../../../street-centerline/resources/streetCenterlineResource';
import { storeStreets } from '../../../streets/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

const streetAliasesModule = createNormalizedModule<StreetAlias>({
    type: 'streetAliases',
});

const storeStreetAliases = streetAliasesModule.actionCreators.storeEntities;

const deleteStreetAliasesWhere = streetAliasesModule.actionCreators.deleteEntitiesWhere;

export const streetAliasesSelector = streetAliasesModule.selectors.entitiesSelector;

export default streetAliasesModule.reducerConfig;

export const LOAD_STREET_ALIASES_START = 'street-aliases/LOAD_STREET_ALIASES_START';
export const LOAD_STREET_ALIASES_SUCCESS = 'street-aliases/LOAD_STREET_ALIASES_SUCCESS';
export const LOAD_STREET_ALIASES_FAILURE = 'street-aliases/LOAD_STREET_ALIASES_FAILURE';
export const SAVE_STREET_ALIAS_START = 'street-aliases/SAVE_STREET_ALIAS_START';
export const SAVE_STREET_ALIAS_SUCCESS = 'street-aliases/SAVE_STREET_ALIAS_SUCCESS';
export const SAVE_STREET_ALIAS_FAILURE = 'street-aliases/SAVE_STREET_ALIAS_FAILURE';
export const DELETE_STREET_ALIAS_START = 'street-aliases/DELETE_STREET_ALIAS_START';
export const DELETE_STREET_ALIAS_SUCCESS = 'street-aliases/DELETE_STREET_ALIAS_SUCCESS';
export const DELETE_STREET_ALIAS_FAILURE = 'street-aliases/DELETE_STREET_ALIAS_FAILURE';

function loadStreetAliasesStart() {
    return {
        type: LOAD_STREET_ALIASES_START,
    };
}

function loadStreetAliasesSuccess() {
    return {
        type: LOAD_STREET_ALIASES_SUCCESS,
    };
}

function loadStreetAliasesFailure(errMsg: string) {
    return {
        type: LOAD_STREET_ALIASES_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function saveStreetAliasStart() {
    return {
        type: SAVE_STREET_ALIAS_START,
    };
}

export function saveStreetAliasFailure(errMessage: string) {
    return {
        type: SAVE_STREET_ALIAS_FAILURE,
        payload: errMessage,
    };
}

function saveStreetAliasSuccess(aliases: StreetAlias[]) {
    return {
        type: SAVE_STREET_ALIAS_SUCCESS,
        payload: aliases,
    };
}

function deleteStreetAliasStart() {
    return {
        type: DELETE_STREET_ALIAS_START,
    };
}

function deleteStreetAliasSuccess() {
    return {
        type: DELETE_STREET_ALIAS_SUCCESS,
    };
}

function deleteStreetAliasFailure(errMessage: string) {
    return {
        type: DELETE_STREET_ALIAS_FAILURE,
        payload: errMessage,
    };
}

export function loadStreetAliases(): ClientCommonAction<Promise<StreetCenterlineView>> {
    return function (dispatch) {
        dispatch(loadStreetAliasesStart());
        return getStreetCenterlineResource()
            .getStreetCenterlineView()
            .then(({ streets, streetAliases }: StreetCenterlineView) => {
                dispatch(storeStreets(streets));
                dispatch(storeStreetAliases(streetAliases));

                return dispatch(loadStreetAliasesSuccess());
            })
            .catch((err: Error) => dispatch(loadStreetAliasesFailure(err.message)));
    };
}

export function saveStreetAlias(streetAliasModel: {
    streetId: number;
    aliases: StreetAlias[];
}): ClientCommonAction<Promise<void>> {
    return function (dispatch) {
        dispatch(saveStreetAliasStart());
        return getStreetSearchAliasResource()
            .upsertAliasesForStreet(streetAliasModel.streetId, streetAliasModel.aliases)
            .then((aliases: StreetAlias[]) => {
                dispatch(deleteStreetAliasesWhere({ streetId: streetAliasModel.streetId }));
                dispatch(storeStreetAliases(aliases));

                return dispatch(saveStreetAliasSuccess(aliases));
            })
            .catch((err: Error) => dispatch(saveStreetAliasFailure(err.message)));
    };
}

export function deleteStreetAlias(streetAliasId: number): ClientCommonAction<Promise<void>> {
    return function (dispatch) {
        dispatch(deleteStreetAliasStart());
        return getStreetSearchAliasResource()
            .deleteStreetSearchAlias(streetAliasId)
            .then(() => {
                dispatch(deleteStreetAliasesWhere({ id: streetAliasId }));
                return dispatch(deleteStreetAliasSuccess());
            })
            .catch((err: Error) => dispatch(deleteStreetAliasFailure(err.message)));
    };
}

import { CadTicket } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'cadTickets';

const cadTickets = createNormalizedModule<CadTicket>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS

export const cadTicketsWhereSelector = cadTickets.selectors.entitiesWhereSelector;

// REDUCER
export default cadTickets.reducerConfig;

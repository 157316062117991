import { ItemEvidenceState } from '@mark43/evidence-api';
import { find } from 'lodash';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'itemEvidenceStates';

const itemEvidenceStatesModule = createNormalizedModule<ItemEvidenceState>({
    type: NEXUS_STATE_PROP,
    key: 'chainOfCustodyId',
});

// ACTIONS
export const storeItemEvidenceStates = itemEvidenceStatesModule.actionCreators.storeEntities;

// SELECTORS
const itemEvidenceStatesSelector = itemEvidenceStatesModule.selectors.entitiesSelector;

export const itemEvidenceStateByChainOfCustodyIdSelector = createSelector(
    itemEvidenceStatesSelector,
    (itemEvidenceStates) => (chainOfCustodyId: number) => itemEvidenceStates[chainOfCustodyId]
);

export const itemEvidenceStateByMasterItemIdSelector = createSelector(
    itemEvidenceStatesSelector,
    (itemEvidenceStates) => (masterItemId: number) => find(itemEvidenceStates, { masterItemId })
);

// REDUCER
/**
 * ItemEvidenceState models, one for each chain of custody. If an evidence item
 *   has multiple chains of custody, then it has multiple itemEvidenceStates.
 *   This model has the following properties:
 *
 * canDisposition
 * canManualRequestDisposition
 * canPrimaryReview
 * canRelease
 * canSecondaryReviewForDisposition
 * canSecondaryReviewForRelease
 * chainOfCustodyId
 * dispositionApprovalLevel
 * dispositionStatus
 * expirationDateUtc
 * isPrimaryReviewer
 * isSecondaryReviewer
 * masterItemId
 * validNextCategories
 */
export default itemEvidenceStatesModule.reducerConfig;

import {
    StreetSegmentParityEnum,
    StreetSegmentSideEnum,
    DispatchAreaStatusEnum,
} from '@mark43/cad-api';
import { createSelector } from 'reselect';
import _, { get, every, isNaN } from 'lodash';
import {
    LOAD_STREET_SEGMENTS_START,
    LOAD_STREET_SEGMENTS_SUCCESS,
    LOAD_STREET_SEGMENTS_FAILURE,
    SAVE_STREET_SEGMENT_SUCCESS,
    SAVE_STREET_SEGMENT_START,
    SAVE_STREET_SEGMENT_FAILURE,
    DELETE_STREET_SEGMENT_START,
    DELETE_STREET_SEGMENT_SUCCESS,
    DELETE_STREET_SEGMENT_FAILURE,
    streetSegmentsSelector,
} from '~/client-common/core/domain/street-segments/state/data';

import { streetByIdSelector } from '~/client-common/core/domain/streets/state/data';
import { dispatchAreasSelector } from '~/client-common/core/domain/dispatch-areas/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { getPolygonCenter } from '../../../../core/maps/utils/mapHelpers';
import streetSegmentsForm from '../forms/streetSegmentsForm';

const OPEN_NEW_STREET_SEGMENTS_FORM = 'street-segments/OPEN_NEW_STREET_SEGMENTS_FORM';
const SELECT_STREET_SEGMENT = 'street-segments/SELECT_STREET_SEGMENT';
const CLOSE_STREET_SEGMENT_FORM = 'street-segments/CLOSE_STREET_SEGMENT_FORM';

export function openNewStreetSegmentsForm() {
    return {
        type: OPEN_NEW_STREET_SEGMENTS_FORM,
    };
}

export function selectStreetSegment(streetSegment) {
    return {
        type: SELECT_STREET_SEGMENT,
        payload: streetSegment,
    };
}

export function closeStreetSegmentForm() {
    return {
        type: CLOSE_STREET_SEGMENT_FORM,
    };
}

const initialUiState = {
    loadingList: false,
    listError: null,
    selectedStreetSegmentId: null,
    saving: false,
    savingError: null,
};

export default function streetSegmentsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_STREET_SEGMENTS_START:
            return {
                ...state,
                loadingList: true,
                listError: null,
                selectedStreetSegmentId: null,
            };
        case LOAD_STREET_SEGMENTS_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_STREET_SEGMENTS_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case DELETE_STREET_SEGMENT_SUCCESS:
            return {
                ...state,
                selectedStreetSegmentId: null,
                saving: false,
            };
        case SAVE_STREET_SEGMENT_SUCCESS:
            return {
                ...state,
                saving: false,
                savingError: null,
            };
        case SAVE_STREET_SEGMENT_FAILURE:
        case DELETE_STREET_SEGMENT_FAILURE:
            return {
                ...state,
                saving: false,
                savingError: action.payload ? action.payload : null,
            };
        case OPEN_NEW_STREET_SEGMENTS_FORM:
            return {
                ...state,
                selectedStreetSegmentId: null,
            };
        case SELECT_STREET_SEGMENT:
            return {
                ...state,
                selectedStreetSegmentId: action.payload ? action.payload : null,
                savingError: null,
            };
        case SAVE_STREET_SEGMENT_START:
        case DELETE_STREET_SEGMENT_START:
            return {
                ...state,
                saving: true,
            };
        case CLOSE_STREET_SEGMENT_FORM:
            return {
                ...state,
                selectedStreetSegmentId: false,
                savingError: null,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.streetSegmentsAdmin;

const crossStreetText = (startStreet, endStreet) => {
    if (startStreet && endStreet) {
        return `b/t ${startStreet.name} & ${endStreet.name}`;
    }

    if (startStreet || endStreet) {
        return `at ${(startStreet || endStreet).name}`;
    }

    return '';
};

export const streetSegmentsListSelector = createSelector(
    uiSelector,
    streetSegmentsSelector,
    streetByIdSelector,
    ({ selectedStreetSegmentId, loadingList }, streetSegments, streetById) => {
        return loadingList
            ? []
            : _(streetSegments)
                  .map((streetSegment) => {
                      const street = streetById(streetSegment.streetId);
                      const startStreet = streetById(streetSegment.startCrossStreetId);
                      const endStreet = streetById(streetSegment.endCrossStreetId);

                      const subtitleParts = [
                          `${streetSegment.startRange} - ${streetSegment.endRange}`,
                          crossStreetText(startStreet, endStreet),
                          streetSegment.streetSegmentParity
                              ? `(${streetSegment.streetSegmentParity})`
                              : null,
                      ];

                      return {
                          ...streetSegment,
                          title: get(street, 'name', 'N/A'),
                          key: get(streetSegment, 'id'),
                          selected: streetSegment.id === selectedStreetSegmentId,
                          subtitle: _.compact(subtitleParts).join(' '),
                      };
                  })
                  .sortBy(['title', 'startRange'])
                  .value();
    }
);

export const streetSegmentMapDataSelector = createSelector(
    streetSegmentsForm.selectors.formModelSelector,
    (model) => {
        const streetSegment = _.get(model, 'streetSegment');
        if (!streetSegment) {
            return {};
        }

        const { startLatitude, startLongitude, endLatitude, endLongitude } = streetSegment;

        const polyline = [
            { lat: parseFloat(startLatitude), lng: parseFloat(startLongitude) },
            { lat: parseFloat(endLatitude), lng: parseFloat(endLongitude) },
        ];

        if (!every(polyline, ({ lat, lng }) => !isNaN(lat) && !isNaN(lng))) {
            return {};
        }

        const center = getPolygonCenter(polyline);

        return {
            center,
            polyline,
        };
    }
);

export const selectedStreetSegmentSelector = createSelector(
    streetSegmentsForm.selectors.formModelSelector,
    (model) => _.get(model, 'streetSegment')
);

export const streetSegmentParityOptions = _.map(StreetSegmentParityEnum, (val) => {
    return {
        display: val.name,
        value: val.name,
    };
});

export const streetSegmentSideOptions = _.map(StreetSegmentSideEnum, (val) => {
    return {
        display: val.name,
        value: val.name,
    };
});

export const policeDispatchAreaOptionsSelector = createSelector(
    dispatchAreasSelector,
    (dispatchAreas) =>
        dispatchAreaOptionsForAgencyType(dispatchAreas, globalAttributes.agencyTypeGlobal.police)
);

export const fireDispatchAreaOptionsSelector = createSelector(
    dispatchAreasSelector,
    (dispatchAreas) =>
        dispatchAreaOptionsForAgencyType(dispatchAreas, globalAttributes.agencyTypeGlobal.fire)
);

export const medicalDispatchAreaOptionsSelector = createSelector(
    dispatchAreasSelector,
    (dispatchAreas) =>
        dispatchAreaOptionsForAgencyType(dispatchAreas, globalAttributes.agencyTypeGlobal.medical)
);

function dispatchAreaOptionsForAgencyType(dispatchAreas, agencyType) {
    return _(dispatchAreas)
        .filter(
            ({ status, agencyTypeGlobalAttrId }) =>
                status === DispatchAreaStatusEnum.ACTIVE.name &&
                agencyTypeGlobalAttrId === agencyType
        )
        .map(({ id, name }) => {
            return {
                value: id,
                display: name,
            };
        })
        .value();
}

/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import { forEach } from 'lodash';

const NODE_ENV = process.env.NODE_ENV;

export const logWarning = (
    msg: string,
    extras?: { [index: string]: unknown; [index: number]: unknown }
) => {
    // log warning to sentry
    Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Warning);
        forEach(extras, (val, key) => {
            scope.setExtra(key, val);
        });
        Sentry.captureMessage(msg);
    });
    if (NODE_ENV !== 'production') {
        console.warn(msg, extras);
    }
};

export const logError = (
    msg: string,
    extras?: { [index: string]: unknown; [index: number]: unknown }
) => {
    // log error to sentry
    Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Error);
        forEach(extras, (val, key) => {
            scope.setExtra(key, val);
        });
        Sentry.captureMessage(msg);
    });
    if (NODE_ENV !== 'production') {
        console.error(msg, extras);
    }
};

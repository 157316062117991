import { Attribute, WarrantActivity, WarrantStatus } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getWarrantActivityResource from '../../resources/warrantActivityResource';
import { ClientCommonAction } from '../../../../../redux/types';

const NEXUS_STATE_PROP = 'warrantActivities';

const warrantActivitiesModule = createNormalizedModule<WarrantActivity>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// ACTIONS
const storeWarrantActivities = warrantActivitiesModule.actionCreators.storeEntities;
const replaceWarrantActivitiesWhere = warrantActivitiesModule.actionCreators.replaceEntitiesWhere;
const deleteWarrantActivitiesWhere = warrantActivitiesModule.actionCreators.deleteEntitiesWhere;

// SELECTORS

export const warrantActivitiesWhereSelector =
    warrantActivitiesModule.selectors.entitiesWhereSelector;

type WarrantActivityBundle = {
    warrantActivity: WarrantActivity;
    warrantStatus?: WarrantStatus;
    warrantStatusAttribute?: Attribute;
};

/**
 * Create an existing activity and update the warrant status
 */
export function createWarrantActivity(
    warrantActivity: WarrantActivity
): ClientCommonAction<Promise<WarrantActivityBundle>> {
    const warrantActivityResource = getWarrantActivityResource();
    return (dispatch) => {
        return warrantActivityResource
            .createWarrantActivity(warrantActivity)
            .then((warrantActivityBundle: WarrantActivityBundle) => {
                dispatch(storeWarrantActivities(warrantActivityBundle.warrantActivity));
                return warrantActivityBundle;
            });
    };
}

/**
 * Update an existing activity
 */
export function updateWarrantActivity(
    warrantActivity: WarrantActivity
): ClientCommonAction<Promise<WarrantActivity>> {
    const warrantActivityResource = getWarrantActivityResource();
    return (dispatch) => {
        return warrantActivityResource
            .updateWarrantActivity(warrantActivity)
            .then((updatedWarrantActivity: WarrantActivity) => {
                dispatch(
                    replaceWarrantActivitiesWhere(
                        { id: updatedWarrantActivity.id },
                        updatedWarrantActivity
                    )
                );
                return updatedWarrantActivity;
            });
    };
}

/**
 * Delete a warrant
 */
export function deleteWarrantActivity(activityId: number): ClientCommonAction<Promise<void>> {
    const warrantActivityResource = getWarrantActivityResource();
    return (dispatch) => {
        return warrantActivityResource
            .deleteWarrantActivity(activityId)
            .then((success: boolean) => {
                if (success) {
                    dispatch(deleteWarrantActivitiesWhere({ id: activityId }));
                } else {
                    throw new Error('Failed to remove warrant activity');
                }
            });
    };
}

// REDUCER
export default warrantActivitiesModule.reducerConfig;

import { ArrestAttribute } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'arrestAttributes';

const arrestAttributeModule = createLinkModule<ArrestAttribute>({
    type: NEXUS_STATE_PROP,
    keys: ['attributeId', 'arrestId'],
});

// ACTIONS

// SELECTORS
export const arrestAttributesWhereSelector = arrestAttributeModule.selectors.linksWhereSelector;

// REDUCER
export default arrestAttributeModule.reducerConfig;

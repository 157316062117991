import type { Editor, EditorEvent } from 'tinymce';

const isLink = (node: Element): node is HTMLAnchorElement =>
    node.nodeName.toLowerCase() === 'a' && 'href' in node;

export const setupLinkPlugin = (editor: Editor) => {
    const handleOpenLink = (event: EditorEvent<MouseEvent>) => {
        if (!isLink(event.target)) {
            return;
        }

        const { href, target } = event.target;
        if (!href) {
            return;
        }

        window.open(href, target);
    };

    editor.on('SwitchMode', ({ mode }) => {
        if (mode === 'design') {
            editor.off('click', handleOpenLink);
            return;
        }

        // to have only one handler first we clean all previous handles
        editor.off('click', handleOpenLink);
        editor.on('click', handleOpenLink);
    });

    editor.on('remove', () => {
        editor.off('click', handleOpenLink);
    });
};

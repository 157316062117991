import { FormEnum } from '@mark43/rms-api';

import { omit } from 'lodash';
import {
    EXPORT_RELEASE_RELEASED_TO_ATTR_ID,
    EXPORT_RELEASE_RELEASED_TO_OTHER,
    EXPORT_RELEASE_ADDITIONAL_INFORMATION,
} from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { buildFlatFormFieldViewModels } from '../../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../forms';

const { ATTRIBUTE } = fieldTypeClientEnum;

const createReleaseFormFieldViewModels = buildFlatFormFieldViewModels([
    'id',
    'fileId',
    {
        type: ATTRIBUTE,
        key: 'releasedToAttrId',
        fieldName: EXPORT_RELEASE_RELEASED_TO_ATTR_ID,
    },
    {
        key: 'releasedToOther',
        fieldName: EXPORT_RELEASE_RELEASED_TO_OTHER,
    },
    {
        key: 'additionalInformation',
        fieldName: EXPORT_RELEASE_ADDITIONAL_INFORMATION,
    },
]);

function convertFromFormModel(formModel, attributeIsOther) {
    if (!attributeIsOther(formModel.releasedToAttrId)) {
        return omit(formModel, 'releasedToOther');
    }
    return formModel;
}

const createReleaseForm = createFormModule({
    formName: FormEnum.CREATE_EXPORT_RELEASE.name,
    fieldViewModels: createReleaseFormFieldViewModels,
    convertFromFormModel,
});

/**
 * Module of the create release form
 * @type {Object}
 */
export default createReleaseForm;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import stringsConfig from '~/client-common/core/strings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import Modal from '../core/Modal';
import { closeBox, saveBoxHalt } from '../../actions/boxActions';
import {
    entityPermissionsUiSelector,
    entityPermissionsFormSelector,
} from '../../selectors/entityPermissionsSelectors';
import { createModalSelector } from '../../../modules/core/box/state/ui';
import EntityPermissionsForm from './EntityPermissionsForm';

const strings = stringsConfig.components.security.EntityPermissionsModal;

const context = {
    name: boxEnum.ENTITY_PERMISSIONS_MODAL,
};

/**
 * @param  {Boolean}    [options.canManagePermissions]      Optional param that is relevant
 *      for `REPORT`s. BE-computed `canManagePermissions` boolean on the `ReportStatusView` object.
 */
function EntityPermissionsModal({
    ui,
    form,
    canManagePermissions,
    readOnlyTitleAndRoleIds,
    saveBoxHalt,
    closeBox,
    infoMessage,
}) {
    const handleSubmit = () => {
        const result = ui.handleSubmit();
        if (!result) {
            // on field validation error, get out of the `saving` state; this
            // functionality should be included in `Modal` and not here
            saveBoxHalt();
        }
        return result;
    };

    return (
        <Modal
            context={context}
            title={strings.title}
            onClose={closeBox}
            onSave={ui.editable ? handleSubmit : closeBox}
            error={form._error}
            cancelText={!ui.editable ? '' : undefined}
        >
            <EntityPermissionsForm
                canManagePermissions={canManagePermissions}
                readOnlyTitleAndRoleIds={readOnlyTitleAndRoleIds}
                infoMessage={infoMessage}
            />
        </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
    ui: entityPermissionsUiSelector,
    form: entityPermissionsFormSelector,
    canManagePermissions: createModalSelector(context, 'canManagePermissions'),
    readOnlyTitleAndRoleIds: createModalSelector(context, 'readOnlyTitleAndRoleIds'),
    infoMessage: createModalSelector(context, 'infoMessage'),
});

const mapDispatchToProps = (dispatch) => ({
    saveBoxHalt: () => dispatch(saveBoxHalt(context)),
    closeBox: () => dispatch(closeBox(context)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityPermissionsModal);

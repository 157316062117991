import * as React from 'react';
import { Flex, Box } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { RRFNumberInput } from '../../../../core/forms/components/NumberInput';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';

import { isNumericRangeQuery } from '../../utils/dragon/isNumericRangeQuery';
import { Switch } from './common';
import { NumericRangeQuery, RRFFormActions } from './types';

const strings = componentStrings.search.AdvancedSearchReportsForm.dragon.inputFields.number;

function NumericRangeInput({
    path,
    mode,
}: {
    path: string;
    mode: 'NUMBER' | 'INTEGER';
}): JSX.Element {
    return (
        <>
            <Box flexBasis="50%">
                <RRFNumberInput label={strings.minLabel} path={`${path}.min`} mode={mode} />
            </Box>
            <Flex alignItems="center" justifyContent="center" flexBasis="10%">
                {'\u2013'}
            </Flex>
            <Box flexBasis="50%">
                <RRFNumberInput label={strings.maxLabel} path={`${path}.max`} mode={mode} />
            </Box>
        </>
    );
}

export const NumericInput = reactReduxFormHelpers.connectRRFFieldState(
    reactReduxFormHelpers.withRRFActions(function NumbericInput({
        path,
        mode,
        formActions,
        fieldModel,
    }: {
        path: string;
        mode: 'NUMBER' | 'INTEGER';
        fieldModel?: string | NumericRangeQuery;
    } & RRFFormActions): JSX.Element {
        const [isRange, setIsRange] = React.useState(isNumericRangeQuery(fieldModel));
        return (
            <Flex basis="50%" direction="column" alignItems="flex-start">
                <Flex width="100%">
                    {isRange ? (
                        <NumericRangeInput path={path} mode={mode} />
                    ) : (
                        <RRFNumberInput label={strings.exactValueLabel} path={path} mode={mode} />
                    )}
                </Flex>
                <Switch
                    isChecked={isRange}
                    onChange={() => {
                        formActions.change(path, undefined);
                        setIsRange((isRange) => !isRange);
                    }}
                >
                    {strings.rangeLabel}
                </Switch>
            </Flex>
        );
    })
);

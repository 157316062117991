import React from 'react';
import withFields from '~/client-common/core/fields/components/withFields';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import ElasticCell from '../ElasticCell';
import Link from '../../../../../modules/core/components/links/Link';

export default withFields([REPORT_REPORTING_EVENT_NUMBER])(function ReportingEventNumberCell({
    fieldDisplayNames,
    handleRenLinkClick = (e) => e.stopPropagation(),
    ...elasticReport
}) {
    // This cell shows both the Record ID and the REN.
    // Record ID should be clickable and REN should not clickable in this column, UNLESS the RECORD ID = REN (e.g., offense report),
    // in which case ONLY show the REN and make the REN clickable (will open to the specific report)
    const renAndRecordNumberAreDifferent =
        elasticReport.recordNumber !== elasticReport.reportingEventNumber;

    return (
        <div className="elastic-report-reporting-event-number">
            <ElasticCell row={elasticReport}>
                {(isClickable) => (
                    <FeatureFlagged
                        flag="RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED"
                        fallback={
                            <Link
                                disabled={!isClickable}
                                disabledColor="darkGrey"
                                hoverOnUnderline={true}
                                to={`reports/${elasticReport.id}`}
                                onClick={handleRenLinkClick}
                            >
                                {elasticReport.reportingEventNumber}
                            </Link>
                        }
                    >
                        <>
                            {elasticReport.reportingEventNumber && (
                                <div>
                                    {fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER}{' '}
                                    <Link
                                        disabled={
                                            (elasticReport.recordNumber &&
                                                renAndRecordNumberAreDifferent) ||
                                            !isClickable
                                        }
                                        hoverOnUnderline={true}
                                        disabledColor="darkGrey"
                                        onClick={handleRenLinkClick}
                                        to={`reports/${elasticReport.id}`}
                                    >
                                        {elasticReport.isLegacy ? (
                                            <span className="legacy-search-result">{`${elasticReport.reportingEventNumber}*`}</span>
                                        ) : (
                                            elasticReport.reportingEventNumber
                                        )}
                                    </Link>
                                </div>
                            )}
                            {elasticReport.recordNumber && renAndRecordNumberAreDifferent && (
                                <div>
                                    ID{' '}
                                    <Link
                                        disabled={!isClickable}
                                        hoverOnUnderline={true}
                                        disabledColor="darkGrey"
                                        onClick={handleRenLinkClick}
                                        to={`reports/${elasticReport.id}`}
                                    >
                                        {elasticReport.recordNumber}
                                    </Link>
                                </div>
                            )}
                        </>
                    </FeatureFlagged>
                )}
            </ElasticCell>
        </div>
    );
});

import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';

import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';

import withFields from '~/client-common/core/fields/components/withFields';
import { ITEM_PROFILE_SERIAL_NUMBER } from '~/client-common/core/enums/universal/fields';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import { formatTitleForPropertyRecord } from '../../../../../legacy-redux/helpers/propertyRecordHelpers';
import QuickSearchResultSectionItemTitle from '../QuickSearchResultSectionItemTitle';
import Highlighter from '../QuickSearchHighlighter';
import FieldDisplay from '../layout/FieldDisplay';
import ExternalDepartmentName from '../ExternalDepartmentName';

const Wrapper = styled.div`
    position: relative;
`;

const ProfilePhoto = styled(ImgWithExifOrientation)`
    width: 65px;
    height: 65px;
`;

const ProfilePhotoContainer = styled.div`
    background: ${(props) => props.theme.colors.lightGrey};
    width: 65px;
    height: 65px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const PropertyItemInfoContainer = styled.div`
    vertical-align: top;
    width: calc(100% - 75px);
    margin-left: 10px;
    padding: 10px 0;
    display: inline-block;
`;

const QuickSearchResultSectionPropertyItem = ({
    query,
    item,
    formatAttributeById,
    theme,
    externalDepartmentName,
    fieldDisplayNames,
    testId,
    testType,
}) => {
    const {
        itemTypeAttrId,
        itemCategoryAttrId,
        firearmMakeAttrId,
        primaryProfilePhotoPath,
        atfManufacturerName,
    } = item;

    const searchWords = [query];
    // we have to pass in a custom object because we are not using view
    // models and the helper was originally designed to be used with
    // view models
    const title = formatTitleForPropertyRecord(
        {
            itemCategoryAttrId: formatAttributeById(itemCategoryAttrId),
            description: item.description,
            firearmMakeAttrId: formatAttributeById(firearmMakeAttrId),
            atfManufacturer: atfManufacturerName,
        },
        true
    );

    const isFirearm = itemTypeAttrId === globalAttributes.itemType.firearm;

    return (
        <Wrapper data-test-id={testId} data-test-type={testType}>
            <ProfilePhotoContainer>
                {item.primaryProfilePhotoPath ? (
                    <ProfilePhoto src={primaryProfilePhotoPath} />
                ) : (
                    <Icon
                        type={isFirearm ? iconTypes.FIREARM : iconTypes.PROPERTY}
                        color="mediumLightGrey"
                        size={40}
                    />
                )}
            </ProfilePhotoContainer>
            <PropertyItemInfoContainer>
                <QuickSearchResultSectionItemTitle width="100%">
                    <Highlighter searchWords={searchWords} textToHighlight={title} />{' '}
                    <ExternalDepartmentName theme={theme} departmentName={externalDepartmentName} />
                </QuickSearchResultSectionItemTitle>
                {item.serialNumber && (
                    <FieldDisplay
                        fieldDisplayNames={fieldDisplayNames}
                        field={ITEM_PROFILE_SERIAL_NUMBER}
                        theme={theme}
                    >
                        <Highlighter
                            searchWords={searchWords}
                            textToHighlight={item.serialNumber}
                        />
                    </FieldDisplay>
                )}
            </PropertyItemInfoContainer>
        </Wrapper>
    );
};

export default connect((state) => ({
    formatAttributeById: formatAttributeByIdSelector(state),
}))(withFields([ITEM_PROFILE_SERIAL_NUMBER])(withTheme(QuickSearchResultSectionPropertyItem)));

import { EntityTypeEnum, ProductModuleEnum } from '@mark43/rms-api';
import { isUndefined, reject } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';
import classNames from 'classnames';

import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_PERSON_AGE_RANGE_AT_REPORT_LABEL } from '~/client-common/core/enums/universal/fields';
import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFAdvancedSearchLinkTypeSelect } from '../../../core/forms/components/selects/AdvancedSearchLinkTypeSelect';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFAgeRangeSlider } from '../../../core/forms/components/Slider';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../core/testIds';
import SearchCapabilityIcon, { SEARCH_CAPABILITY_TYPE_ENUM } from './SearchCapabilityIcon';

const { withRRFActions } = reactReduxFormHelpers;

const Checkbox = styled(RRFCheckbox)`
    margin-top: 23px;
`;
const IconWrapper = styled.span`
    margin-left: 10px;
`;

const StyledRowWithAgeSlider = styled(Row)`
    display: flex;
    flex-wrap: wrap;
`;

function PersonDetailsFieldsetRow1({ fuzzyMatchingEnabled, expandCollapseButton, includeIsDead }) {
    return (
        <Row>
            <RRFText
                width={257}
                className={classNames({
                    'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                })}
                extraLabelContent={
                    <IconWrapper>
                        <SearchCapabilityIcon variant={SEARCH_CAPABILITY_TYPE_ENUM.BOOLEAN.name} />
                    </IconWrapper>
                }
                labelClassName=""
                path="firstName"
            />
            <RRFText
                width={257}
                className={classNames({
                    'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                })}
                extraLabelContent={
                    <IconWrapper>
                        <SearchCapabilityIcon variant={SEARCH_CAPABILITY_TYPE_ENUM.BOOLEAN.name} />
                    </IconWrapper>
                }
                labelClassName=""
                path="lastName"
            />
            <div data-test-id={testIds.ADVANCED_SEARCH_PERSON_PROFILE_SEX}>
                <RRFAttributeSelect
                    attributeType="SEX"
                    includeExpired={true}
                    width={158}
                    path="sexAttrId"
                />
            </div>
            {includeIsDead && <Checkbox path="isDead" />}
            {expandCollapseButton}
        </Row>
    );
}

const PersonDetailsFieldsetRow2 = withRRFActions(
    ({ fuzzyMatchingEnabled, formActions, includeHasPotentialActiveWarrant }) => {
        const onDateChangeResetAgeRangeSlider = (value) => {
            // when a date of birth is selected, clear the age range
            // fields
            if (value) {
                formActions.reset('ageRangeStart');
                formActions.reset('ageRangeEnd');
            }
        };
        const fieldDisplayNames = useFields([DISPLAY_ONLY_PERSON_AGE_RANGE_AT_REPORT_LABEL]);
        return (
            <StyledRowWithAgeSlider>
                <div data-test-id={testIds.ADVANCED_SEARCH_PERSON_PROFILE_NICKNAME}>
                    <RRFText
                        width={130}
                        className={classNames({
                            'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                        })}
                        path="nickname"
                    />
                </div>
                <div data-test-id={testIds.ADVANCED_SEARCH_PERSON_PROFILE_DOB}>
                    <RRFDatePicker
                        path="dateOfBirth"
                        variant={RRFDatePicker.variants.LOCAL_DATE}
                        onChange={onDateChangeResetAgeRangeSlider}
                    />
                </div>
                <RRFDatePicker
                    path="dateOfBirthOther"
                    variant={RRFDatePicker.variants.LOCAL_DATE}
                    onChange={onDateChangeResetAgeRangeSlider}
                />
                <RRFAgeRangeSlider
                    label={fieldDisplayNames.DISPLAY_ONLY_PERSON_AGE_RANGE_AT_REPORT_LABEL}
                    onChange={({ rangeStart, rangeEnd }) => {
                        // when an age range is selected, clear the date of birth / date of birth other
                        // field
                        if (!isUndefined(rangeStart) || !isUndefined(rangeEnd)) {
                            formActions.reset('dateOfBirth');
                            formActions.reset('dateOfBirthOther');
                        }
                    }}
                />
                {includeHasPotentialActiveWarrant && (
                    <ProductModuled productModule={ProductModuleEnum.WARRANTS.name}>
                        <Checkbox
                            path="hasPotentialActiveWarrant"
                            onChange={(value) => {
                                // when active warrant checkbox is unchecked, reset the form value
                                if (!value) {
                                    formActions.reset('hasPotentialActiveWarrant');
                                }
                            }}
                        />
                    </ProductModuled>
                )}
            </StyledRowWithAgeSlider>
        );
    }
);

function PersonDetailsFieldsetRow3({
    includeInvolvement,
    includeWasFrisked,
    includeHasCriminalStreetGangCriteria,
}) {
    if (!includeInvolvement && !includeWasFrisked && !includeHasCriminalStreetGangCriteria) {
        return <div />;
    }

    return (
        <Row>
            {includeInvolvement && (
                <RRFAdvancedSearchLinkTypeSelect
                    entityTypePairs={[
                        [EntityTypeEnum.PERSON_PROFILE.name, EntityTypeEnum.REPORT.name],
                        [EntityTypeEnum.PERSON_PROFILE.name, EntityTypeEnum.ARREST.name],
                        [EntityTypeEnum.REPORT.name],
                    ]}
                    filterOptions={(options) =>
                        reject(options, ({ display }) => {
                            // exclude traffic crash-related link types; this is a hard
                            // coded check due to the problem described in
                            // https://github.com/mark43/mark43/pull/5503
                            return display === 'Non Motorist' || display === 'Person In Vehicle';
                        })
                    }
                    width={248}
                    path="involvement"
                />
            )}
            {includeWasFrisked && <RRFBooleanSelect width={158} path="wasFrisked" />}
            {includeHasCriminalStreetGangCriteria && (
                <FeatureFlagged flag="RMS_GANG_TRACKING_ENABLED">
                    <RRFBooleanSelect width={270} path="isAllegedGangMember" />
                </FeatureFlagged>
            )}
        </Row>
    );
}

/**
 * Fieldset for a person's details. Some of the fields are optional for use in
 *   different forms: `isDead`, `involvement`, `wasFrisked`.
 * @param {Object}  props
 * @param {boolean} [props.collapsed=false]
 * @param {boolean} [props.fuzzyMatchingEnabled]
 * @param {boolean} [props.includeIsDead=false]
 * @param {boolean} [props.includeInvolvement=false]
 * @param {boolean} [props.includeWasFrisked=false]
 * @param {String}  [props.ageRangeLabel]
 * @param {ReactElement} [props.expandCollapseButton]
 */
export default pure(function PersonDetailsFieldset({
    collapsed = false,
    fuzzyMatchingEnabled,
    expandCollapseButton,
    includeIsDead = false,
    includeInvolvement = false,
    includeWasFrisked = false,
    includeHasPotentialActiveWarrant = false,
    includeHasCriminalStreetGangCriteria = false,
    ...otherProps
}) {
    return (
        <RRFFieldset path="personDetails" {...otherProps}>
            <PersonDetailsFieldsetRow1
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                expandCollapseButton={expandCollapseButton}
                includeIsDead={includeIsDead}
            />
            {!collapsed && (
                <PersonDetailsFieldsetRow2
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    includeHasPotentialActiveWarrant={includeHasPotentialActiveWarrant}
                />
            )}
            {!collapsed && (
                <PersonDetailsFieldsetRow3
                    includeInvolvement={includeInvolvement}
                    includeWasFrisked={includeWasFrisked}
                    includeHasCriminalStreetGangCriteria={includeHasCriminalStreetGangCriteria}
                />
            )}
        </RRFFieldset>
    );
});

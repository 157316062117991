import { storeFutureEntityPermissions } from '~/client-common/core/domain/future-entity-permissions/state/data';

import entityPermissionsResource from '../../../../../legacy-redux/resources/entityPermissionsResource';

const LOAD_WARRANT_PERMISSIONS_START = 'warrant-configuration/LOAD_WARRANT_PERMISSIONS_START';
const LOAD_WARRANT_PERMISSIONS_SUCCESS = 'warrant-configuration/LOAD_WARRANT_PERMISSIONS_SUCCESS';
const LOAD_WARRANT_PERMISSIONS_FAILURE = 'warrant-configuration/LOAD_WARRANT_PERMISSIONS_FAILURE';
export const UPDATE_WARRANT_PERMISSIONS_START =
    'warrant-configuration/UPDATE_WARRANT_PERMISSIONS_START';
export const UPDATE_WARRANT_PERMISSIONS_SUCCESS =
    'warrant-configuration/UPDATE_WARRANT_PERMISSIONS_SUCCESS';
export const UPDATE_WARRANT_PERMISSIONS_FAILURE =
    'warrant-configuration/UPDATE_WARRANT_PERMISSIONS_FAILURE';

function loadWarrantPermissionsStart() {
    return {
        type: LOAD_WARRANT_PERMISSIONS_START,
    };
}

function loadWarrantPermissionsSuccess(permissions) {
    return {
        type: LOAD_WARRANT_PERMISSIONS_SUCCESS,
        payload: permissions,
    };
}

function loadWarrantPermissionsFailure(errMsg) {
    return {
        type: LOAD_WARRANT_PERMISSIONS_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function loadWarrantPermissions() {
    return function (dispatch) {
        dispatch(loadWarrantPermissionsStart());

        return entityPermissionsResource
            .getWarrantDefaultPermissions()
            .then((permissions) => {
                dispatch(storeFutureEntityPermissions(permissions));
                dispatch(loadWarrantPermissionsSuccess());
                return permissions;
            })
            .catch((err) => dispatch(loadWarrantPermissionsFailure(err.message)));
    };
}

function updateWarrantPermissionsStart() {
    return {
        type: UPDATE_WARRANT_PERMISSIONS_START,
    };
}

function updateWarrantPermissionsSuccess(permissions) {
    return {
        type: UPDATE_WARRANT_PERMISSIONS_SUCCESS,
        payload: permissions,
    };
}

function updateWarrantPermissionsFailure(errMsg) {
    return {
        type: UPDATE_WARRANT_PERMISSIONS_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function updateWarrantPermissions(formData) {
    return function (dispatch) {
        const { permissions } = formData;
        dispatch(updateWarrantPermissionsStart());

        return entityPermissionsResource
            .updateWarrantDefaultPermissions(permissions)
            .then((permissions) => {
                dispatch(storeFutureEntityPermissions(permissions));
                dispatch(updateWarrantPermissionsSuccess(permissions));
                return permissions;
            })
            .catch((err) => dispatch(updateWarrantPermissionsFailure(err.message)));
    };
}

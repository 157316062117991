import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';

import _, { size, get, filter } from 'lodash';
import {
    reportDefinitionHasCardSelector,
    reportDefinitionByIdSelector,
} from '~/client-common/core/domain/report-definitions/state/data';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import {
    hydratedEventDetailByReportIdSelector,
    hydratedSupplementInfoByReportIdSelector,
} from '~/client-common/core/domain/reports/state/ui';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import {
    objectOnlyHasMetadata,
    reportVersionableBaseFieldKeys,
} from '~/client-common/helpers/dataHelpers';
import {
    reportDefinitionHasEventInfoCard,
    reportDefinitionHasSupplementInfoCard,
} from '~/client-common/helpers/reportDefinitionsHelpers';
import { reportShortTitleViewModelsByRenSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    setEventInfoCardPrefill,
    setSupplementInfoCardPrefill,
} from '../../../../../legacy-redux/actions/reportsActions';
import {
    currentUserDepartmentIdSelector,
    currentUserIdSelector,
} from '../../../../core/current-user/state/ui';
import {
    reportOwnerIdSelector,
    canEditReportCardStatusSelector,
    currentReportCardUITitleByTypeSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';

/**
 * Determine if the Event Info Card is prefillable.  Expects that the `report` passed in
 * has an `Event Info` card.
 * Logic:
 *  - Legacy Reports are not prefillable.
 *  - If current report has _any_ Event Info card data, then it is not prefillable.
 * @return {boolean}    Whether or not the current report's Event Info Card can be prefilled.
 */
const isCurrentReportEventInfoCardPrefillable = (report, state) => {
    if (report.isLegacyReport) {
        return false;
    }

    const canEditReportCard = get(canEditReportCardStatusSelector(state), 'canEditReportCard');
    if (!canEditReportCard) {
        return false;
    }

    const currentReportId = report.id;
    const hydratedEventDetail = hydratedEventDetailByReportIdSelector(state)(currentReportId);

    // Omit standard object properties because the BE may create an "empty" `EventDetail` object
    // by default for reports.  In this case, reports with an Event Info card will have an
    // `EventDetail` object.
    const hasEventDetailData = !objectOnlyHasMetadata(
        get(hydratedEventDetail, 'eventDetail'),
        reportVersionableBaseFieldKeys
    );

    const hasHydratedEventDetailData = _(hydratedEventDetail)
        .omit(['eventDetail', 'report', 'cadTicket'])
        .some((value) => {
            return size(value) > 0;
        });

    const hasReportTakenLocationLinks =
        size(
            locationEntityLinksWhereSelector(state)({
                entityId: currentReportId,
                entityType: EntityTypeEnum.REPORT.name,
                linkType: LinkTypesEnum.LOCATION_OF_EVENT,
            })
        ) > 0;

    const hasReportingPartyLinks =
        size(
            nameReportLinksWhereSelector(state)({
                reportId: currentReportId,
                linkType: LinkTypesEnum.REPORTING_PARTY_IN_REPORT,
            })
        ) > 0;

    return !(
        hasEventDetailData ||
        hasHydratedEventDetailData ||
        hasReportTakenLocationLinks ||
        hasReportingPartyLinks
    );
};

/**
 * Determine if the Supplement Info Card is prefillable.  Expects that the `currentReport` passed in
 * has a `Supplement Info` card.
 * Logic:
 *  - Legacy Reports are not prefillable.
 *  - If current report has _any_ Supplement Info card data, then it is not prefillable.
 * @return {boolean}    Whether or not the current report's Supplement Info Card can be prefilled.
 */
const isCurrentReportSupplementInfoCardPrefillable = function (currentReport, state) {
    const { id: reportId, isLegacyReport } = currentReport;

    if (isLegacyReport) {
        return false;
    }

    const canEditReportCard = get(canEditReportCardStatusSelector(state), 'canEditReportCard');
    if (!canEditReportCard) {
        return false;
    }

    const { report, reportAttributes, eventDetail } = hydratedSupplementInfoByReportIdSelector(
        state
    )(reportId);

    // Omit standard object properties because the BE may create an "empty" `EventDetail` object
    // by default for reports.  In this case, reports with an Event Info card will have an
    // `EventDetail` object.
    const hasEventDetailData = !objectOnlyHasMetadata(eventDetail, reportVersionableBaseFieldKeys);
    const hasReportDescription = size(get(report, 'description')) > 0;
    const hasReportAttributes = size(reportAttributes) > 0;

    return !(hasEventDetailData || hasReportDescription || hasReportAttributes);
};

/**
 * Sort `ReportShortTitle` view models by their most recently created date, in descending order.
 */
const findLinkedReportShortTitleToPrefillFrom = (reportShortTitleViewModels, predicate, state) => {
    const reportDefinitionById = reportDefinitionByIdSelector(state);
    return _(reportShortTitleViewModels)
        .filter((reportShortTitleViewModel) => {
            const reportDefinition = reportDefinitionById(
                reportShortTitleViewModel.reportDefinitionId
            );
            return predicate(reportDefinition);
        })
        .sortBy('createdDateUtc')
        .last();
};

/**
 * Function to get the report id to prefill from.  To resolve linked report ids, we use a `reportShortTitle` look up.
 * Logic:
 *      1) Find target set of possible reports to prefill from.
 *      2) Attempt to find a report with an Event Information or a Supplement Information card,
 *         this will be the report we are prefilling from.
 * @param {number}   currentReportId    Id of the `Report` object the user is currently viewing.
 * @return  number|undefined    Returns the report id to prefill from or undefined if no prefill
 *                              candidate is found.
 */
const findSupplementInfoCardPrefillFromReportId = function (
    currentReportId,
    currentReportREN,
    state
) {
    const filteredLinkedReportShortTitleViewModels = filterLinkedReportShortTitleViewModelsForPrefill(
        currentReportId,
        currentReportREN,
        state
    );
    const linkedReportShortTitleToPrefillFrom = findLinkedReportShortTitleToPrefillFrom(
        filteredLinkedReportShortTitleViewModels,
        (reportDefinition) => {
            return (
                reportDefinitionHasEventInfoCard(reportDefinition) ||
                reportDefinitionHasSupplementInfoCard(reportDefinition)
            );
        },
        state
    );
    return _.get(linkedReportShortTitleToPrefillFrom, 'reportId');
};

/**
 * For all reports under the REN, filter out all reports that...:
 *  a) are not within the current department id as the report we're landing on
 *  b) the user has at least Can View (canRead) access to
 *  c) that do not have either an Event Information or Supplement Information card.
 */
const filterLinkedReportShortTitleViewModelsForPrefill = function (
    currentReportId,
    currentReportREN,
    state
) {
    const linkedReportShortTitleViewModels = reportShortTitleViewModelsByRenSelector(state)(
        currentReportREN
    );
    const reportDefinitionById = reportDefinitionByIdSelector(state);
    const currentUserDepartmentId = currentUserDepartmentIdSelector(state);

    return filter(linkedReportShortTitleViewModels, (linkedReportShortTitleViewModel) => {
        const isNotCurrentReport = linkedReportShortTitleViewModel.reportId !== currentReportId;
        const isInCurrentDepartment =
            linkedReportShortTitleViewModel.departmentId === currentUserDepartmentId;
        const linkedReportDefinition = reportDefinitionById(
            linkedReportShortTitleViewModel.reportDefinitionId
        );
        const canRead = get(getViewModelProperties(linkedReportShortTitleViewModel), 'canRead');
        const hasEventInfoCard = reportDefinitionHasEventInfoCard(linkedReportDefinition);
        const hasSupplementInfoCard = reportDefinitionHasSupplementInfoCard(linkedReportDefinition);

        return (
            isNotCurrentReport &&
            isInCurrentDepartment &&
            canRead &&
            (hasEventInfoCard || hasSupplementInfoCard)
        );
    });
};

const findEventInfoCardPrefillFromReportId = function (reportId, reportREN, state) {
    const filteredLinkedReportShortTitleViewModels = filterLinkedReportShortTitleViewModelsForPrefill(
        reportId,
        reportREN,
        state
    );
    let linkedReportShortTitleToPrefillFrom = findLinkedReportShortTitleToPrefillFrom(
        filteredLinkedReportShortTitleViewModels,
        reportDefinitionHasEventInfoCard,
        state
    );

    if (isUndefinedOrNull(linkedReportShortTitleToPrefillFrom)) {
        linkedReportShortTitleToPrefillFrom = findLinkedReportShortTitleToPrefillFrom(
            filteredLinkedReportShortTitleViewModels,
            reportDefinitionHasSupplementInfoCard,
            state
        );
    }

    return get(linkedReportShortTitleToPrefillFrom, 'reportId');
};

export const openPrefillModals = (report) => {
    return (dispatch, getState) => {
        const { reportDefinitionId } = report;

        const state = getState();
        const reportDefinitionHasCard = reportDefinitionHasCardSelector(state);
        const currentReportCardUITitleByType = currentReportCardUITitleByTypeSelector(state);

        const currentReportHasEventInfoCard = reportDefinitionHasCard(
            reportDefinitionId,
            reportCardEnum.EVENT_INFO.id
        );
        const currentReportHasSupplementInfoCard = reportDefinitionHasCard(
            reportDefinitionId,
            reportCardEnum.SUPPLEMENT_INFO.id
        );
        const currentUserIsReportOwner =
            reportOwnerIdSelector(state) === currentUserIdSelector(state);
        let currentReportInfoCardIsPrefillable = false;
        let prefillFromReportId;
        let cardName = '';
        if (currentReportHasEventInfoCard) {
            currentReportInfoCardIsPrefillable = isCurrentReportEventInfoCardPrefillable(
                report,
                state
            );
            prefillFromReportId = findEventInfoCardPrefillFromReportId(
                report.id,
                report.reportingEventNumber,
                state
            );
            cardName = currentReportCardUITitleByType(reportCardEnum.EVENT_INFO.id);
        } else if (currentReportHasSupplementInfoCard) {
            currentReportInfoCardIsPrefillable = isCurrentReportSupplementInfoCardPrefillable(
                report,
                state
            );
            prefillFromReportId = findSupplementInfoCardPrefillFromReportId(
                report.id,
                report.reportingEventNumber,
                state
            );
            cardName = currentReportCardUITitleByType(reportCardEnum.SUPPLEMENT_INFO.id);
        }

        if (
            currentUserIsReportOwner &&
            currentReportInfoCardIsPrefillable &&
            !isUndefinedOrNull(prefillFromReportId)
        ) {
            dispatch((dispatch, getState, { overlayStore }) => {
                overlayStore.open(overlayIdEnum.REPORT_PREFILL_MODAL, {
                    cardName,
                    prefillFromReportId,
                    reportId: report.id,
                    isEventInfoCardPrefill: currentReportHasEventInfoCard,
                });
            });
        }
    };
};

export const submitPrefillModal = (reportId, prefillFromReportId, isEventInfoCardPrefill) => {
    return (dispatch) => {
        const setError = () => {
            dispatch((dispatch, getState, { overlayStore }) => {
                overlayStore.setError(
                    overlayIdEnum.REPORT_PREFILL_MODAL,
                    componentStrings.reports.core.ReportPrefillModal.message.error
                );
            });
        };

        if (isEventInfoCardPrefill) {
            return getReportResource()
                .getEventDetailBundleForPrefill(reportId, prefillFromReportId)
                .then((eventDetailBundle) => {
                    if (eventDetailBundle) {
                        // NOTE: Order matters here -- we need to set the prefill info
                        // in redux state before closing the prefill modal
                        dispatch(
                            setEventInfoCardPrefill({
                                prefillModalSaveSelected: true,
                                eventDetailBundle,
                            })
                        );
                        dispatch((dispatch, getState, { overlayStore }) => {
                            overlayStore.close(overlayIdEnum.REPORT_PREFILL_MODAL);
                        });
                    } else {
                        setError();
                    }
                })
                .catch(() => {
                    setError();
                });
        } else {
            return getReportResource()
                .getSupplementCardPrefillBundle(reportId, prefillFromReportId)
                .then((supplementCardPrefillBundle) => {
                    if (supplementCardPrefillBundle) {
                        // NOTE: Order matters here -- we need to set the prefill info
                        // in redux state before closing the prefill modal
                        dispatch(
                            setSupplementInfoCardPrefill({
                                prefillModalSaveSelected: true,
                                supplementCardPrefillBundle,
                            })
                        );
                        dispatch((dispatch, getState, { overlayStore }) => {
                            overlayStore.close(overlayIdEnum.REPORT_PREFILL_MODAL);
                        });
                    } else {
                        setError();
                    }
                })
                .catch(() => {
                    setError();
                });
        }
    };
};

import { FieldTypeEnum } from '@mark43/rms-api';
import React from 'react';
import invariant from 'invariant';
import _, {
    some,
    map,
    forEach,
    get,
    filter,
    merge,
    compact,
    has,
    values,
    reduce,
    keys,
    omitBy,
    flowRight,
    partition,
    startsWith,
    times,
} from 'lodash';
import { ReactReduxContext } from 'react-redux';
import createArbiter, { ruleTypes } from 'arbiter-mark43';
import { isNItems, isFieldset, simpleControl } from 'markformythree';
import PropTypes from 'prop-types';
import {
    fieldDetailsSelector,
    fieldDetailsByFieldNameSelector,
} from '~/client-common/core/domain/field-details/state/data';
import {
    fieldConfigurationsSelector,
    fieldConfigurationsByFieldDetailIdSelector,
} from '~/client-common/core/domain/field-configurations/state/data';
import {
    fieldConfigurationContextsSelector,
    fieldConfigurationContextsByFieldConfigurationIdAndContextSelector,
    fieldConfigurationContextsByFieldConfigurationIdSelector,
} from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { rulesSelector } from '~/client-common/core/domain/rules/state/data';
import { ruleConfigurationContextsSelector } from '~/client-common/core/domain/rule-configuration-contexts/state/data';
import { ruleConditionsSelector } from '~/client-common/core/domain/rule-conditions/state/data';
import { ruleActionedFieldsSelector } from '~/client-common/core/domain/rule-actioned-fields/state/data';
import featureFlagged from '~/client-common/core/domain/settings/utils/featureFlagged';
import { interpolateErrorMessage } from '~/client-common/core/arbiter-utils/templateRunner';
import logBrokenFieldEffectComputation from '~/client-common/core/arbiter-utils/logBrokenFieldEffectComputation';
import { ruleConditionArgsSelector } from '~/client-common/core/domain/rule-condition-args/state/data';

import { useStaticFieldRequired } from '../forms/hooks/useStaticFieldRequired';

import { buildRuleFieldGetterImplementations } from './buildRuleFieldGetterImplementations';

export function buildDataGetters(getState, context) {
    return {
        fieldDetails() {
            return fieldDetailsSelector(getState());
        },
        fieldDetailsByFieldName() {
            return fieldDetailsByFieldNameSelector(getState());
        },
        fieldConfigurations() {
            return fieldConfigurationsSelector(getState());
        },
        fieldConfigurationsByFieldDetailId() {
            return fieldConfigurationsByFieldDetailIdSelector(getState());
        },
        fieldConfigurationContexts() {
            const allFieldConfigurationContexts = fieldConfigurationContextsSelector(getState());
            return context
                ? filter(allFieldConfigurationContexts, { context })
                : allFieldConfigurationContexts;
        },
        fieldConfigurationContextsByFieldConfigurationId() {
            return fieldConfigurationContextsByFieldConfigurationIdSelector(getState());
        },
        fieldConfigurationContextsByFieldConfigurationIdAndContext() {
            return fieldConfigurationContextsByFieldConfigurationIdAndContextSelector(getState());
        },
        rules() {
            return rulesSelector(getState());
        },
        ruleConfigurationContexts() {
            const allRuleConfigurationContexts = ruleConfigurationContextsSelector(getState());
            return context
                ? filter(allRuleConfigurationContexts, { context })
                : allRuleConfigurationContexts;
        },
        ruleConditions() {
            return ruleConditionsSelector(getState());
        },
        ruleConditionArgs() {
            return ruleConditionArgsSelector(getState());
        },
        ruleActionedFields() {
            return ruleActionedFieldsSelector(getState());
        },
    };
}

let arbiter;

/**
 * @param {Object} store     The RMS' global redux store
 * @param {string} [context] An optional property which scopes data getters to a given context.
 *                             This would result in a minor performance improvement when validation runs.
 * @param {boolean} [pure]   If `true`, returns an instance of arbiter. Otherwise, replaces the RMS' global
 *                             instance of arbiter with the created instance.
 */
export function buildArbiter(store, context, pure = false) {
    const getState = store.getState.bind(store);
    if (pure) {
        return createArbiter({
            ruleFieldGetterImplementations: buildRuleFieldGetterImplementations(getState),
            dataGetters: buildDataGetters(getState, context),
        });
    } else {
        arbiter = createArbiter({
            ruleFieldGetterImplementations: buildRuleFieldGetterImplementations(getState),
            dataGetters: buildDataGetters(getState, context),
        });
    }
}

export const createRMSArbiterInstance = (getState, context) => {
    const ruleFieldGetterImplementations = buildRuleFieldGetterImplementations(getState);
    const dataGetters = buildDataGetters(getState, context);
    const newArbiter = createArbiter({ ruleFieldGetterImplementations, dataGetters });

    return { ...newArbiter, dataGetters };
};

export function getArbiter() {
    return arbiter;
}

// TODO move to arbiter
class ArbiterProvider extends React.Component {
    constructor(props) {
        super();
        this.arbiter = createArbiter({
            ruleFieldGetterImplementations: props.ruleFieldGetterImplementations,
            dataGetters: props.dataGetters,
        });
    }
    getChildContext() {
        return {
            runRules: this.arbiter.runRules,
            dataGetters: this.props.dataGetters,
            context: this.props.context,
        };
    }
    render() {
        return this.props.children({
            runRules: this.arbiter.runRules,
            dataGetters: this.props.dataGetters,
        });
    }
}
ArbiterProvider.childContextTypes = {
    runRules: PropTypes.func,
    dataGetters: PropTypes.object,
    context: PropTypes.string,
};

export class RMSArbiterProvider extends React.Component {
    render() {
        return (
            <ReactReduxContext.Consumer>
                {({ store }) => {
                    return (
                        <ArbiterProvider
                            ruleFieldGetterImplementations={buildRuleFieldGetterImplementations(
                                store.getState.bind(store)
                            )}
                            dataGetters={buildDataGetters(
                                store.getState.bind(store),
                                this.props.context
                            )}
                            {...this.props}
                        />
                    );
                }}
            </ReactReduxContext.Consumer>
        );
    }
}

export const withStore = () => (Component) => {
    class WithStore extends React.Component {
        render() {
            return (
                <ReactReduxContext.Consumer>
                    {({ store }) => {
                        return (
                            <Component {...this.props} store={store} ref={this.props.innerRef} />
                        );
                    }}
                </ReactReduxContext.Consumer>
            );
        }
    }
    WithStore.contextTypes = {
        store: PropTypes.object,
    };
    const WithStoreForwardedRef = React.forwardRef((props, ref) => (
        <WithStore {...props} innerRef={ref} />
    ));
    WithStoreForwardedRef.__forwardsRef = true;
    return WithStoreForwardedRef;
};

export class ArbiterField extends React.Component {
    render() {
        invariant(
            this.context.dataGetters,
            '`dataGetters` not available on context. Make sure <ArbiterField /> is rendered under an <ArbiterProvider />'
        );

        const fieldName = this.props.fieldName;
        const fieldDetail = this.context.dataGetters.fieldDetailsByFieldName()[fieldName];
        invariant(fieldDetail, `fieldDetail is missing for field: ${fieldName}`);
        const fieldConfiguration =
            this.context.dataGetters.fieldConfigurationsByFieldDetailId()[fieldDetail.id];
        const fieldConfigurationContext = this.context.context
            ? this.context.dataGetters.fieldConfigurationContextsByFieldConfigurationIdAndContext()[
                  `${fieldConfiguration.id}~${this.context.context}`
              ]
            : // fallback for cases where we do not have a context.
              // TODO figure out if these cases are valid or bugs. One case is `WarrantActivityMappingForm`
              this.context.dataGetters.fieldConfigurationContextsByFieldConfigurationId()[
                  fieldConfiguration.id
              ];
        return this.props.children({
            fieldName,
            fieldDetail,
            fieldConfiguration,
            fieldConfigurationContext,
        });
    }
}
ArbiterField.contextTypes = {
    dataGetters: PropTypes.object,
    context: PropTypes.string,
};

export function arbiterInput(Component) {
    const ArbiterInput = class extends React.Component {
        static contextTypes = {
            showLabelForFirstItemOnly: PropTypes.bool,
            nItemIndex: PropTypes.number,
        };
        render() {
            const { fieldName, ...otherProps } = this.props;
            return (
                <ArbiterField fieldName={fieldName}>
                    {({ fieldConfiguration, fieldConfigurationContext }) =>
                        !fieldConfigurationContext.isStaticallyHidden ? (
                            <Component
                                label={
                                    this.context.showLabelForFirstItemOnly &&
                                    this.context.nItemIndex !== 0
                                        ? ''
                                        : fieldConfiguration.displayName
                                }
                                placeholder={fieldConfigurationContext.placeholderText}
                                helpText={fieldConfigurationContext.helpText}
                                fieldName={fieldName}
                                {...otherProps}
                            />
                        ) : null
                    }
                </ArbiterField>
            );
        }
    };

    ArbiterInput.displayName = `ArbiterInput(${Component.displayName || 'Component'})`;
    return ArbiterInput;
}

export function getFieldName(Component) {
    return function MapMFTFieldName({ getConfigurationForPath, ...otherProps }) {
        const fieldName = getConfigurationForPath(otherProps.path).fieldName;
        invariant(typeof fieldName === 'string', `fieldName missing for path: ${otherProps.path}`);
        return <Component fieldName={fieldName} {...otherProps} />;
    };
}

// map the "fieldName" prop (which correlates to a FieldDetail object) to a "testId" prop
// so that it can be easily accessed with any DOM API (ultimately at "data-test-id")
export function fieldNameToTestId(Component) {
    return function FieldNameToTestId({ fieldName, ...otherProps }) {
        return <Component testId={fieldName} fieldName={fieldName} {...otherProps} />;
    };
}

function staticRequired(Component) {
    const UseStaticFieldRequiredHookComponent = ({ context, fieldConfigurationId, ...props }) => {
        const isStaticRequired = useStaticFieldRequired({
            disabled: props.disabled,
            value: props.value,
            fieldConfigurationId,
            context,
        });

        return <Component {...props} isRequired={isStaticRequired} />;
    };

    function StaticRequired(props, context) {
        return (
            <ArbiterField fieldName={props.fieldName}>
                {({ fieldConfiguration }) => (
                    <UseStaticFieldRequiredHookComponent
                        fieldConfigurationId={fieldConfiguration?.id}
                        context={context?.context}
                        {...props}
                    />
                )}
            </ArbiterField>
        );
    }

    StaticRequired.contextTypes = { context: PropTypes.string };

    return featureFlagged(
        'RMS_REPORT_WRITING_SHOW_REQUIRED_FIELDS_ENABLED',
        Component
    )(StaticRequired);
}

export const arbiterMFTInput = flowRight(
    simpleControl,
    getFieldName,
    fieldNameToTestId,
    staticRequired,
    arbiterInput
);

/**
 * Get all the "real" paths that currently exist based on the formModel, including all NItems indexes.
 *   nItemsParents are FieldDescriptors.
 *
 * Example with 1 level of NItems, the typical case:
 *   foo[0].fieldPath
 *   foo[1].fieldPath
 *   foo[2].fieldPath
 *
 * Example with 3 levels of NItems, where we explode paths from the top down:
 *   foo[0].bar[0].baz[0].fieldPath
 *   foo[0].bar[0].baz[1].fieldPath
 *   ...
 *   foo[1].bar[2].baz[3].fieldPath
 *
 * This function does not support nested fieldsets.
 *
 * The returned array of parentPaths goes from bottom up, e.g.
 *   foo[1].bar[2].baz
 *   foo[1].bar
 *   foo
 */
function getNestedNItems({ formModel, nItemsParents = [], fieldPath, prefix = '', parentPaths }) {
    const [firstParent, ...remainingParents] = nItemsParents;
    const pathWithoutIndex = `${prefix ? `${prefix}.` : ''}${get(firstParent, 'path')}`;
    const count = get(formModel, pathWithoutIndex, []).length;
    const nItemsPaths = times(count, (n) => `${pathWithoutIndex}[${n}]`);
    if (nItemsParents.length === 1) {
        return _(nItemsPaths)
            .map((pathWithIndex) => ({
                path: `${pathWithIndex}.${fieldPath}`,
                parentPaths: [pathWithoutIndex, ...(parentPaths || [])],
            }))
            .compact()
            .value();
    } else {
        return reduce(
            nItemsPaths,
            (acc, pathWithIndex) => [
                ...acc,
                ...getNestedNItems({
                    formModel,
                    nItemsParents: remainingParents,
                    fieldPath,
                    prefix: pathWithIndex,
                    parentPaths: [pathWithoutIndex, ...(parentPaths || [])],
                }),
            ],
            []
        );
    }
}

/**
 * Given a `fieldConfiguration` tree,
 * recursively traverse all the fields and nested `fieldset` fields (ignoring all `NItems`)
 * and pull out all the `fieldNames` and their respective relative `paths`
 */
const recursivelyCollectFields = (tree) => {
    function process(tree, fields, basePath) {
        for (const key of keys(tree.fields)) {
            const { fieldName } = tree.fields[key];
            const combinedPath = basePath ? `${basePath}.${key}` : key;
            if (fieldName) {
                fields.push({
                    fieldName,
                    path: combinedPath,
                });
            }
            // This is purposely not an `else if` because a `fieldset`
            // can also have its own `fieldName` (which we want to track),
            // as well as the `fields` within the `fieldset`
            if (isFieldset(tree.fields[key])) {
                process(tree.fields[key], fields, combinedPath);
            }
        }

        return fields;
    }

    return process(tree, [], '');
};

/**
 * Convert MFT data to Arbiter data. Supports only NItems fields that are nested 3 levels, e.g. level1[n].level2[n].level3
 */
export function formDataToArbiterDataMFT(formModel, formConfiguration, arbiterData = {}) {
    let globalMaxNItemId = 0;
    const process = (tree, basePath = '', nItemsParents, hasParentNItems) => {
        forEach(tree, (subtree, fieldPath) => {
            const combinedPath = basePath ? `${basePath}.${fieldPath}` : fieldPath;
            // if we're in an nitems subtree, we have to accommodate arrays
            const fieldDescriptor = {
                value: get(formModel, combinedPath),
                meta: {
                    path: combinedPath,
                },
            };

            // This path is only hit when we're creating an arbiterData entry
            // for a field that is in an nitems that doesn't actually have any members
            if (
                subtree.fieldName &&
                hasParentNItems &&
                !arbiterData[subtree.fieldName].__hadNItemsMembers
            ) {
                arbiterData[subtree.fieldName] = [];
                // otherwise, we can just add a single value
            } else if (subtree.fieldName && !hasParentNItems) {
                arbiterData[subtree.fieldName] = fieldDescriptor;
            }

            if (isNItems(subtree)) {
                const fields = recursivelyCollectFields(subtree);

                forEach(fields, ({ fieldName }) => {
                    if (!arbiterData[fieldName]) {
                        arbiterData[fieldName] = [];
                    }
                });

                const nestedNItems = nItemsParents
                    ? getNestedNItems({
                          formModel,
                          nItemsParents,
                          fieldPath,
                      })
                    : [{ path: combinedPath, parentPaths: [] }];
                forEach(nestedNItems, ({ path: basePath }) => {
                    const members = get(formModel, basePath, []);
                    forEach(members, (member, index) => {
                        ++globalMaxNItemId;
                        forEach(fields, ({ fieldName, path }) => {
                            const fullPath = `${basePath}[${index}].${path}`;
                            const value = get(formModel, fullPath);
                            const fieldDescriptor = {
                                value,
                                castedValue: undefined,
                                nItemInfo: {
                                    id: globalMaxNItemId,
                                    index,
                                    // this function `formDataToArbiterDataMFT` by itself supports nested NItems without
                                    // needing `parents`, but it may still be useful to later populate `parents` for
                                    // other functionality, by grabbing the fieldDescriptors from arbiterData:
                                    //     _(parentPaths) // from nestedNItems
                                    //         .map((parentPath) =>
                                    //             find(_.flatMap(arbiterData), { meta: { path: parentPath } })
                                    //         )
                                    //         .compact()
                                    //         .value();
                                    parents: [],
                                },
                                meta: {
                                    path: fullPath,
                                },
                            };
                            if (Array.isArray(arbiterData[fieldName])) {
                                arbiterData[fieldName].push(fieldDescriptor);
                            } else {
                                arbiterData[fieldName] = [fieldDescriptor];
                            }
                            arbiterData[fieldName].__hadNItemsMembers = true;
                        });
                    });
                });
            }

            if (subtree.fields) {
                const hasNItemsUpstream = hasParentNItems || isNItems(subtree);
                const parents = hasNItemsUpstream
                    ? [...(nItemsParents || []), { path: fieldPath }]
                    : undefined;
                process(subtree.fields, combinedPath, parents, hasNItemsUpstream);
            }
        });
    };
    process(formConfiguration);
    return arbiterData;
}

function pushOrSet(map, key, val) {
    if (map[key]) {
        map[key].push(val);
    } else {
        map[key] = [val];
    }
}

const DEFAULT_EFFECTS_CONFIGURATION = {
    skipTypes: {
        [ruleTypes.VALIDATION]: undefined,
        [ruleTypes.HIDE]: undefined,
        [ruleTypes.FIELD_CONSTRAINT]: undefined,
        [ruleTypes.CONDITIONAL]: true,
    },
    clearHiddenFields: false,
    forceFieldLevelEffects: false,
};

const CHECKED_VALIDATION = Symbol('CHECKED_VALIDATION');

export const hasErrors = (computedFieldEffect) => {
    if (computedFieldEffect && computedFieldEffect.errors) {
        return values(computedFieldEffect.errors).length > 0;
    }

    return false;
};

function fieldPathIsNItems(fieldPath, formUi) {
    const pathSegments = fieldPath.match(/[^\]\[.]+/g);
    if (pathSegments) {
        const uiStateAtPath = pathSegments.reduce((obj, key) => obj?.[key], formUi);
        if (uiStateAtPath) {
            return !!uiStateAtPath[0];
        }
    }
    return false;
}

export function rulesResultsToMFTValidationResult(
    dataGetters,
    rulesResults,
    formUi,
    effectsConfiguration = {},
    formatFieldByName
) {
    const transformedEffectsConfiguration = {
        ...effectsConfiguration,
        skipTypes: _(get(effectsConfiguration, 'skipTypes'))
            .mapKeys()
            .mapValues(() => true)
            .value(),
    };
    const finalEffectsConfiguration = merge(
        {},
        DEFAULT_EFFECTS_CONFIGURATION,
        transformedEffectsConfiguration
    );
    const effectMap = {};
    // Safe to get state up here -- we use state only when building rule
    // effects, not when `dispatch`ing changes to reduce effects.
    const fieldDetailsById = dataGetters.fieldDetails();
    const fieldConfigurationsById = dataGetters.fieldConfigurations();
    const fieldConfigurationContexts = dataGetters.fieldConfigurationContexts();
    const fieldDetailById = (id) => fieldDetailsById[id];
    const fieldConfigurationById = (id) => fieldConfigurationsById[id];
    const formHasBeenSubmitted = get(formUi, '$form.submitted');
    const formFieldIsTouched = (path) => {
        const fieldUi = get(formUi, path, {});
        if (fieldUi.$) {
            return fieldUi.$.touched;
        }
        return fieldUi.touched;
    };
    const forceFieldLevelEffects = get(finalEffectsConfiguration, 'forceFieldLevelEffects');
    let success = true;

    const filteredArbiterRuleResults = filterStaticallyHiddenFieldsFromArbiterRuleResults(
        rulesResults,
        fieldDetailById,
        fieldConfigurationById,
        fieldConfigurationContexts
    );

    const [hiddenRulesResults, otherRulesResults] = partition(
        filteredArbiterRuleResults,
        (ruleResult) => ruleResult.ruleView.rule.ruleType === ruleTypes.HIDE
    );

    forEach(hiddenRulesResults, (ruleResult) => {
        const ruleType = ruleResult.ruleView.rule.ruleType;
        // if we should skip this ruleType, just quit early
        if (finalEffectsConfiguration.skipTypes[ruleType]) {
            return;
        }

        const ruleActionedFieldDescriptors = ruleResult.ruleActionedFieldDescriptors;

        forEach(ruleActionedFieldDescriptors, (ruleActionedFieldDescriptor) => {
            try {
                const fieldPath = ruleActionedFieldDescriptor.meta.path;
                // include special meta property to handle an NItems edge case in reduceEffects
                const isNItemArray =
                    fieldPathIsNItems(fieldPath, formUi) && ruleActionedFieldDescriptor.nItemInfo;

                pushOrSet(effectMap, fieldPath, {
                    hidden: ruleResult.success,
                    ...(isNItemArray ? { __isNItems: true } : {}),
                });
            } catch (e) {
                logBrokenFieldEffectComputation(ruleResult);
            }
        });
    });

    const computedFieldEffectsHiddenOnly = reduceEffects(effectMap, finalEffectsConfiguration);

    const arbiterValidationRuleResultsWithoutHiddenFields =
        filterRulesWithHiddenFieldsFromValidationComputation(
            otherRulesResults,
            computedFieldEffectsHiddenOnly
        );

    const rawPanelErrors = [];

    // field-level effects first
    forEach(arbiterValidationRuleResultsWithoutHiddenFields, (ruleResult) => {
        const ruleType = ruleResult.ruleView.rule.ruleType;
        // if we should skip this ruleType, just quit early
        if (finalEffectsConfiguration.skipTypes[ruleType]) {
            return;
        }

        const ruleActionedFieldDescriptors = ruleResult.ruleActionedFieldDescriptors;

        try {
            if (ruleType === ruleTypes.VALIDATION) {
                if (!ruleResult.success) {
                    success = false;
                    const ruleFailureDisplayText =
                        ruleResult.ruleView.ruleConfigurationContext.ruleFailureDisplayText;
                    if (ruleFailureDisplayText) {
                        rawPanelErrors.push(ruleFailureDisplayText);
                    }
                }

                // omit producing field-level effects if:
                // 1) The form has never attempted to have been saved
                // 2) All fields involved with the rule have not yet
                // been touched
                // Note: if a field is involved in multiple rules, where
                // at least 1 rule fails this step, then the field will
                // have effects produced for it.
                if (forceFieldLevelEffects !== true && !formHasBeenSubmitted) {
                    const ruleHasTouchedField = some(
                        ruleActionedFieldDescriptors,
                        (ruleActionedFieldDescriptor) => {
                            const fieldPath = ruleActionedFieldDescriptor.meta.path;
                            const isTouched = formFieldIsTouched(fieldPath);
                            return isTouched;
                        }
                    );
                    if (!ruleHasTouchedField) {
                        // omit producing a field-level effect
                        return;
                    }
                }

                // otherwise, produce effects for all fields involved in this rule.
                forEach(ruleActionedFieldDescriptors, (ruleActionedFieldDescriptor) => {
                    const fieldPath = ruleActionedFieldDescriptor.meta.path;
                    // we negate ruleResult.success because ultimately we reduce this
                    // result to produce ERRORS, so a success would mean that a need
                    // for an error is false
                    pushOrSet(
                        effectMap,
                        fieldPath,
                        ruleResult.success
                            ? CHECKED_VALIDATION
                            : ruleActionedFieldDescriptor.ruleFailureDisplayText
                              ? // if theres a custom field message, use it
                                {
                                    _error: interpolateErrorMessage(
                                        ruleActionedFieldDescriptor.ruleFailureDisplayText,
                                        formatFieldByName
                                    ),
                                }
                              : // otherwise just use `true`
                                { _error: true }
                    );
                });
            } else if (ruleType === ruleTypes.FIELD_CONSTRAINT) {
                if (!ruleResult.success) {
                    success = false;
                }
                forEach(ruleActionedFieldDescriptors, (ruleActionedFieldDescriptor) => {
                    const fieldPath = ruleActionedFieldDescriptor.meta.path;
                    pushOrSet(
                        effectMap,
                        fieldPath,
                        ruleResult.success
                            ? CHECKED_VALIDATION
                            : {
                                  _error: interpolateErrorMessage(
                                      ruleActionedFieldDescriptor.ruleFailureDisplayText,
                                      formatFieldByName
                                  ),
                              }
                    );
                });
            } else {
                throw new Error('Rule Result has an unhandled ruleType: ', ruleType);
            }
        } catch (e) {
            logBrokenFieldEffectComputation(ruleResult);
        }
    });

    const computedFieldEffects = reduceEffects(
        effectMap,
        finalEffectsConfiguration,
        computedFieldEffectsHiddenOnly
    );

    const panelErrors = map(rawPanelErrors, (rawPanelError) =>
        interpolateErrorMessage(rawPanelError, formatFieldByName)
    );

    return {
        success,
        formErrors: panelErrors,
        // adding rawErrors to support custom interpolation
        // eg. linked errors in sticky headers
        rawErrors: rawPanelErrors,
        do: computedFieldEffects,
    };
}

function reduceEffects(effectMap, effectsConfiguration, computedFieldEffects = {}) {
    forEach(effectMap, (results, fieldPath) => {
        let checkedValidation = false;
        let computedFieldEffect = reduce(
            results,
            (acc, result) => {
                if (result === CHECKED_VALIDATION) {
                    checkedValidation = true;
                    return acc;
                    // if we already have an object and encounter another, we simply
                    // combine the two, as this indicates that we have multiple effects
                    // and we want to be able to express them all
                } else if (typeof acc === 'object' && typeof result === 'object') {
                    if (acc.errors && result._error) {
                        return {
                            ...acc,
                            touched: true,
                            errors: {
                                ...acc.errors,
                                [result._error]: result._error,
                            },
                        };
                    }
                    if (!acc.errors && result._error) {
                        return {
                            ...acc,
                            touched: true,
                            errors: {
                                [result._error]: result._error,
                            },
                        };
                    }
                    return {
                        ...acc,
                        ...result,
                        // If there are multiple `hidden` effects, then
                        // a `true` value should always win out
                        ...(acc.hidden || result.hidden
                            ? {
                                  hidden: true,
                              }
                            : {}),
                    };
                    // if we have an object and encounter a non object, we know to only keep the object,
                    // as the boolean describes nothing of use
                } else if (typeof acc === 'object' && typeof result !== 'object') {
                    return acc;
                    // if we have a non object (which is the same as a non effect) and encounter
                    // an object, we take the object
                } else if (typeof acc !== 'object' && typeof result === 'object') {
                    if (result._error) {
                        return {
                            touched: true,
                            errors: {
                                [result._error]: result._error,
                            },
                        };
                    }
                    return { ...result };
                } else if (typeof acc === 'boolean' && typeof result === 'boolean') {
                    return acc;
                } else {
                    throw new Error(
                        'Unexpected case reached when reducing validation rule results'
                    );
                }
            },
            {}
        );
        // If the field is hidden AND has some other error, we know that we have a conflict - because the user
        // cannot see the field but some error on this field will prevent a form from being submitted.
        // If we hit this case, we want to hide the field, but strip validation errors, per acceptance criteria
        // established in https://mark43.atlassian.net/browse/RMS-5474
        if (computedFieldEffect.hidden === true && hasErrors(computedFieldEffect)) {
            computedFieldEffect = {
                hidden: true,
            };
        }
        if (effectsConfiguration.clearHiddenFields && computedFieldEffect.hidden) {
            computedFieldEffect = {
                ...computedFieldEffect,
                value: undefined,
            };
        }
        if (checkedValidation && _.keys(computedFieldEffect.errors).length === 0) {
            computedFieldEffect.valid = true;
        }
        // As a special case, NItems (particularly nested NItems) cannot have an undefined value as an effect,
        // and their casted value should always be undefined anyway
        if (computedFieldEffect.__isNItems) {
            delete computedFieldEffect.value;
        }
        delete computedFieldEffect.__isNItems; // cleanup
        computedFieldEffects[fieldPath] = computedFieldEffect;
    });

    return computedFieldEffects;
}

function filterRulesWithHiddenFieldsFromValidationComputation(rulesResults, computedFieldEffects) {
    const fieldEffectsWithHidden = omitBy(computedFieldEffects, (effect) => {
        return effect.hidden !== true;
    });
    const fieldEffectsWithHiddenKeys = keys(fieldEffectsWithHidden);

    // if any of the read fields for this rule match the fieldEffects with
    // hidden keys, them we keep them

    return filter(rulesResults, (ruleResult) => {
        // check if this field or any parent/ancestor fields are hidden
        let hasHiddenField;
        const readFieldPaths = map(ruleResult.ruleReadFieldDescriptors, 'meta.path');

        // If there are no `readFieldPaths`
        // then don't bother iterating over `fieldEffectsWithHiddenKeys`
        if (readFieldPaths.length) {
            forEach(fieldEffectsWithHiddenKeys, (hiddenFieldPath) => {
                if (
                    some(readFieldPaths, (fieldPath) => {
                        return (
                            fieldPath === hiddenFieldPath ||
                            startsWith(fieldPath, `${hiddenFieldPath}.`) ||
                            startsWith(fieldPath, `${hiddenFieldPath}[`)
                        );
                    })
                ) {
                    hasHiddenField = true;
                }
            });
        }

        if (hasHiddenField) {
            return false;
        } else {
            return true;
        }
    });
}

/**
 * Filter out `VALIDATION` rules from Arbiter Rule Results if the `VALIDATION` rule contains *any*
 * non-`INTERNAL_DATA` fields that are statically hidden.
 *
 * Also filter out any `FIELD_CONSTRAINT` rules if the field is statically hidden.
 */
function filterStaticallyHiddenFieldsFromArbiterRuleResults(
    arbiterRuleResults,
    fieldDetailById,
    fieldConfigurationById,
    fieldConfigurationContexts
) {
    const nonInternalDataStaticallyHiddenFields = _(fieldConfigurationContexts)
        .filter((fieldConfigurationContext) => {
            if (fieldConfigurationContext.isStaticallyHidden !== true) {
                return false;
            }

            // Field is statically hidden. Return `true` if the field is not an `INTERNAL_DATA` field.
            const fieldConfiguration = fieldConfigurationById(
                fieldConfigurationContext.fieldConfigurationId
            );
            const fieldDetail = fieldDetailById(get(fieldConfiguration, 'fieldDetailId')) || {};
            return fieldDetail.fieldType !== FieldTypeEnum.INTERNAL_DATA.name;
        })
        .keyBy('fieldConfigurationId')
        .value();

    const filteredArbiterRuleResults = filter(arbiterRuleResults, (arbiterRuleResult) => {
        const ruleType = get(arbiterRuleResult, 'ruleView.rule.ruleType');

        switch (ruleType) {
            case ruleTypes.VALIDATION:
                const allRuleConditions = get(arbiterRuleResult, 'ruleView.ruleConditions');
                // grab all distinct field configuration ids, for all rule conditions of the rule
                const allRuleConditionsFieldConfigurationIds = _(allRuleConditions)
                    .flatMap((ruleCondition) => {
                        const fieldConfigurationIds = [
                            ruleCondition.fieldConfigurationId,
                            ruleCondition.fieldConfiguration2Id,
                        ];
                        return compact(fieldConfigurationIds);
                    })
                    .uniq()
                    .value();

                const ruleHasStaticallyHiddenFields = some(
                    allRuleConditionsFieldConfigurationIds,
                    (ruleConditionFieldConfigurationId) =>
                        has(
                            nonInternalDataStaticallyHiddenFields,
                            ruleConditionFieldConfigurationId
                        )
                );

                return !ruleHasStaticallyHiddenFields;

            case ruleTypes.FIELD_CONSTRAINT:
                return !get(
                    arbiterRuleResult,
                    'fieldView.fieldConfigurationContext.isStaticallyHidden'
                );

            default:
                return true;
        }
    });

    return filteredArbiterRuleResults;
}

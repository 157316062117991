import React from 'react';
import { connect } from 'react-redux';
import { Form, lifecycleOptions } from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { MFTAssociatedDepartmentsSelect } from '../../forms/components/selects/AssociatedDepartmentsSelect';
import { ACCOUNT_SWITCHER_MODAL_CONTEXT, FORM_NAME, submitAccountSwitcherForm } from '../state/ui';

const strings = componentStrings.cobalt.navigation.core.AccountSwitcherModal;

const mapDispatchToProps = { submitAccountSwitcherForm };

function AccountSwitcherModal({ submitAccountSwitcherForm }) {
    return (
        <Modal
            context={ACCOUNT_SWITCHER_MODAL_CONTEXT}
            title={strings.modalTitle}
            shouldCloseOnOverlayClick={true}
            okText={strings.modalOk}
            contentStyle={{ minHeight: 300 }}
            onSave={submitAccountSwitcherForm}
        >
            <Form
                name={FORM_NAME}
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                render={() => (
                    <MFTAssociatedDepartmentsSelect
                        label={strings.selectLabel}
                        width="400px"
                        path="departmentId"
                    />
                )}
            />
        </Modal>
    );
}

export default connect(null, mapDispatchToProps)(AccountSwitcherModal);

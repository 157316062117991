import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { map, size } from 'lodash';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { ButtonGroup } from 'arc';

import styled from 'styled-components';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import {
    formatPhones,
    formatEmails,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    organizationProfileViewModelByIdSelector,
    organizationLocationsByProfileIdSelector,
    organizationAddressesByProfileIdSelector,
} from '~/client-common/core/domain/organization-profiles/state/ui';
import { sortedAugmentedAttachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';

import { FileGrid } from '../../../../../legacy-redux/components/core/Files';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { SummaryExpandCollapseButton } from './buttons/SummaryExpandCollapseButton';
import { OrganizationProfileTitleSummary } from './OrganizationProfileTitleSummary';
import LocationSummary from './LocationSummary';
import LegacyEntityDetailSummaryRow from './LegacyEntityDetailSummaryRow';
import EntitySummary from './EntitySummary';

const strings = componentStrings.summaries.OrganizationProfileSummary;

const WrapperList = styled(SummaryList)`
    padding-bottom: 10px;
`;

const MailingAddresses = connect(
    createStructuredSelector({
        organizationAddressesByProfileId: organizationAddressesByProfileIdSelector,
    })
)(({ organizationProfileId, organizationAddressesByProfileId }) => (
    <SummaryRow label={strings.mailingAddresses}>
        {map(organizationAddressesByProfileId(organizationProfileId), (orgAddress) => {
            return <LocationSummary key={orgAddress.location.id} locationBundle={orgAddress} />;
        })}
    </SummaryRow>
));

const PhysicalAddresses = connect(
    createStructuredSelector({
        organizationLocationsByProfileId: organizationLocationsByProfileIdSelector,
    })
)(({ organizationProfileId, organizationLocationsByProfileId }) => (
    <SummaryRow label={strings.physicalAddresses}>
        {map(organizationLocationsByProfileId(organizationProfileId), (orgLocation) => {
            return <LocationSummary key={orgLocation.location.id} locationBundle={orgLocation} />;
        })}
    </SummaryRow>
));

const mapStateToProps = createStructuredSelector({
    organizationProfileViewModelById: organizationProfileViewModelByIdSelector,
    sortedAugmentedAttachmentViewModelsWhere: sortedAugmentedAttachmentViewModelsWhereSelector,
    applicationSettings: applicationSettingsSelector,
});

const OrganizationProfileSummary = compose(
    connect(mapStateToProps),
    withPropsOnChange(
        [
            'organizationProfileViewModelById',
            'sortedAugmentedAttachmentViewModelsWhere',
            'identifyingMarksDisplayAndImagesByNameId',
        ],
        ({
            organizationProfileId,
            organizationProfileViewModelById,
            sortedAugmentedAttachmentViewModelsWhere,
        }) => {
            const organizationProfileViewModel = organizationProfileViewModelById(
                organizationProfileId
            );

            return {
                organizationProfileViewModel,
                attachmentViewModels: sortedAugmentedAttachmentViewModelsWhere({
                    entityId: organizationProfileId,
                    entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                    linkType: LinkTypesEnum.ORGANIZATION_PROFILE_ATTACHMENT,
                }),
            };
        }
    ),
    renderOnlyIf(({ organizationProfileViewModel }) => !!organizationProfileViewModel)
)(function OrganizationProfileSummary({
    organizationProfileViewModel,
    linkTypeLetterNumber,
    attachmentViewModels,
    additionalItem,
    organizationProfileId,
    renderActionButtons,
    showProfileThumbnail = false,
    showExpandCollapseButton = true,
}) {
    const viewModelProperties = getViewModelProperties(organizationProfileViewModel);

    return (
        <EntitySummary
            additionalItem={additionalItem}
            collapsible={true}
            renderTitle={({ collapsed }) => {
                return (
                    <OrganizationProfileTitleSummary
                        imageUrl={
                            showProfileThumbnail
                                ? viewModelProperties.primaryProfilePhoto?.thumbnailSmallFile
                                      ?.fileWebServerPath
                                : ''
                        }
                        collapsed={collapsed}
                        organizationProfileViewModel={organizationProfileViewModel}
                        linkTypeLetterNumber={linkTypeLetterNumber}
                    />
                );
            }}
            renderActionButtons={({ setCollapsed, collapsed }) => (
                <ButtonGroup>
                    {showExpandCollapseButton && (
                        <SummaryExpandCollapseButton
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    )}
                    {renderActionButtons &&
                        renderActionButtons({
                            isSociety: organizationProfileViewModel.isSociety,
                        })}
                </ButtonGroup>
            )}
        >
            <WrapperList labelWidth={100} contentWidth={400}>
                <SummaryRow fieldName={fields.ORGANIZATION_PROFILE_ORGANIZATION_TYPE_ATTR_ID}>
                    {viewModelProperties.organizationTypeAttrId}
                </SummaryRow>
                <SummaryRow fieldName={fields.ORGANIZATION_PROFILE_INDUSTRY_ATTR_ID}>
                    {viewModelProperties.industry}
                </SummaryRow>
                <MailingAddresses organizationProfileId={organizationProfileId} />
                <PhysicalAddresses organizationProfileId={organizationProfileId} />
                <SummaryRow label={strings.phones}>
                    {formatPhones(viewModelProperties.phoneNumbers)}
                </SummaryRow>
                <SummaryRow label={strings.emails}>
                    {formatEmails(viewModelProperties.emails)}
                </SummaryRow>
                <SummaryRow label={strings.id}>
                    {size(viewModelProperties.identifiers) > 0 && (
                        <SummaryList labelWidth={120} contentWidth={258}>
                            {map(viewModelProperties.identifiers, (nameIdentifierViewModel) => (
                                <SummaryRow
                                    key={nameIdentifierViewModel.id}
                                    label={
                                        getViewModelProperties(nameIdentifierViewModel)
                                            .nameIdentifierTypeAttrId
                                    }
                                >
                                    {nameIdentifierViewModel.identifier}
                                </SummaryRow>
                            ))}
                        </SummaryList>
                    )}
                </SummaryRow>
                <SummaryRow label={strings.attachments}>
                    {attachmentViewModels.length > 0 && (
                        <FileGrid fileLinks={attachmentViewModels} numOfColumns={1} />
                    )}
                </SummaryRow>
                <LegacyEntityDetailSummaryRow
                    legacyEntityDetails={viewModelProperties.legacyEntityDetails}
                />
            </WrapperList>
        </EntitySummary>
    );
});

export default OrganizationProfileSummary;

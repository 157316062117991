import React from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipProps } from '../../../../core/components/tooltip';

const InlineBlock = styled.div`
    display: inline-block;
    vertical-align: top;
`;

interface RecordsHeaderWithTooltipProps {
    overlay: TooltipProps['content'];
    // It's not compact enough to be used as a default component for example in DropdownMenu with current design.
    // Future improvement:
    // We're not supposed to bind a variable testId to a generic component,
    // For integration testing, can either use it's ancestor component to identify it,
    // or bind the testId onto the children component.
    // If you really need a testId, a fixed testId will be enough to test itself.
    testId?: string; // not recommend to use
    children: React.ReactNode;
}
const RecordsHeaderWithTooltip = ({
    children,
    overlay,
    testId,
}: RecordsHeaderWithTooltipProps): JSX.Element => {
    return (
        <InlineBlock>
            <Tooltip
                side="top"
                content={overlay}
                collisionBoundary={document.querySelector('.override-arc-styles')}
            >
                <InlineBlock data-test-id={testId}>{children}</InlineBlock>
            </Tooltip>
        </InlineBlock>
    );
};

export function createRecordsHeaderWithTooltipContainer(
    args: Omit<RecordsHeaderWithTooltipProps, 'children'>
) {
    return ({ children }: Pick<RecordsHeaderWithTooltipProps, 'children'>) => (
        <RecordsHeaderWithTooltip {...args} children={children} />
    );
}

export default RecordsHeaderWithTooltip;

import { ProductModuleEnum, ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { BOOKING_SEARCH_TYPE } from '../../bookings/constants';

export const QUICK_SEARCH_QUERY_SIZE = 5;
export const MIN_QUERY_LENGTH = 3;

export const alwaysIncludeLinks = [
    ElasticSearchTypeEnum.ATTACHMENTS.name,
    ElasticSearchTypeEnum.VEHICLE.name,
    ElasticSearchTypeEnum.PROPERTY.name,
] as const;

export const elasticSearchTypeEnumToAdvancedSearchPath = {
    [ElasticSearchTypeEnum.ATTACHMENTS.name]: 'attachments',
    [ElasticSearchTypeEnum.CAD_TICKET.name]: 'cad',
    [ElasticSearchTypeEnum.COURT_ORDERS.name]: 'court-orders',
    [ElasticSearchTypeEnum.ORGANIZATION.name]: 'organizations',
    [ElasticSearchTypeEnum.PERSON.name]: 'persons',
    [ElasticSearchTypeEnum.PROPERTY.name]: 'property',
    [ElasticSearchTypeEnum.REPORT.name]: 'reports',
    [ElasticSearchTypeEnum.VEHICLE.name]: 'vehicles',
    [EntityTypeEnum.BOOKING.name]: 'bookings',
} as const;

/**
 * List of Advanced Search Links displayed in Quick Search.
 *   Stored as array to preserve order.
 */
export const quickSearchAdvancedSearchLinks = [
    ElasticSearchTypeEnum.CAD_TICKET.name,
    ElasticSearchTypeEnum.REPORT.name,
    EntityTypeEnum.BOOKING.name,
    ElasticSearchTypeEnum.PERSON.name,
    ElasticSearchTypeEnum.ORGANIZATION.name,
    ElasticSearchTypeEnum.VEHICLE.name,
    ElasticSearchTypeEnum.PROPERTY.name,
    ElasticSearchTypeEnum.ATTACHMENTS.name,
    ElasticSearchTypeEnum.COURT_ORDERS.name,
] as const;

export const quickSearchResultTypeToPropMap = {
    [ElasticSearchTypeEnum.REPORT.name]: 'reports',
    [ElasticSearchTypeEnum.PERSON.name]: 'persons',
    [ElasticSearchTypeEnum.CAD_TICKET.name]: 'cadTickets',
    [ElasticSearchTypeEnum.ORGANIZATION.name]: 'organizations',
    [ElasticSearchTypeEnum.VEHICLE.name]: 'vehicles',
    [ElasticSearchTypeEnum.PROPERTY.name]: 'property',
    [ElasticSearchTypeEnum.WARRANT.name]: 'warrants',
    [ElasticSearchTypeEnum.CASE.name]: 'cases',
    [EntityTypeEnum.BOOKING.name]: 'bookings',
} as const;

export type QuickSearchResultSearchTypes = keyof typeof quickSearchResultTypeToPropMap;

// Quick Search search types and allowed config options
export const quickSearchSearchTypes = [
    { type: ElasticSearchTypeEnum.CAD_TICKET.name },
    {
        type: ElasticSearchTypeEnum.REPORT.name,
        ability: abilitiesEnum.REPORTING.VIEW_GENERAL,
        productModule: ProductModuleEnum.REPORTS.name,
    },
    BOOKING_SEARCH_TYPE,
    {
        type: ElasticSearchTypeEnum.CASE.name,
        ability: abilitiesEnum.CASES.VIEW_GENERAL,
        productModule: ProductModuleEnum.CASES.name,
    },
    {
        type: ElasticSearchTypeEnum.PERSON.name,
        ability: abilitiesEnum.CORE.VIEW_GENERAL,
    },
    {
        type: ElasticSearchTypeEnum.ORGANIZATION.name,
        ability: abilitiesEnum.CORE.VIEW_GENERAL,
    },
    {
        type: ElasticSearchTypeEnum.VEHICLE.name,
        ability: abilitiesEnum.CORE.VIEW_GENERAL,
    },
    {
        type: ElasticSearchTypeEnum.PROPERTY.name,
        ability: abilitiesEnum.CORE.VIEW_GENERAL,
    },
    {
        type: ElasticSearchTypeEnum.WARRANT.name,
        ability: abilitiesEnum.WARRANTS.VIEW_GENERAL,
        productModule: ProductModuleEnum.WARRANTS.name,
    },
] as const;

import { identity } from 'lodash';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';

import { WARRANT_WARRANT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../../../../core/forms';
import {
    filterFormData,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';

const { RANGE, ATTRIBUTE, USER } = fieldTypeClientEnum;
const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END } = rangeFieldTypeEnum;
const strings = componentStrings.warrants.dashboard.searchForm.labels;

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'warrantNumber',
        fieldName: WARRANT_WARRANT_NUMBER,
    },
    {
        key: 'warrantIssuedStartDateUtc',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: RANGE_START,
    },
    {
        key: 'warrantIssuedEndDateUtc',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: RANGE_END,
    },
    {
        key: 'warrantTypeAttrIds',
        label: strings.warrantTypeAttrIds,
        type: ATTRIBUTE,
    },
    {
        key: 'obtainingOfficerIds',
        label: strings.obtainingOfficerIds,
        type: USER,
    },
]);

/**
 * Convert the given form model state into a search query model which can be
 *   sent to the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
function convertConnectWarrantsSidePanelFormModelToElasticQuery(formModel = {}) {
    return filterFormData(
        {
            quickSearchQuery: formModel.warrantNumber,
            startDateUtc: formModel.warrantIssuedStartDateUtc,
            endDateUtc: formModel.warrantIssuedEndDateUtc,
            warrantTypeAttrIds: formModel.warrantTypeAttrIds,
            obtainingOfficerIds: formModel.obtainingOfficerIds,
        },
        fieldViewModels
    );
}

const connectWarrantsSidePanelForm = createFormModule({
    formName: formClientEnum.CONNECT_WARRANTS_SIDE_PANEL,
    fieldViewModels,
    convertToFormModel: identity,
    convertFromFormModel: convertConnectWarrantsSidePanelFormModelToElasticQuery,
});

export default connectWarrantsSidePanelForm;

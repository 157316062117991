let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Report Card Bundle Resource',
        methods: {
            upsertArrestReportCard(reportId, arrestCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/arrest`,
                    data: arrestCardBundle,
                });
            },
            upsertBehavioralCrisisCard(reportId, behavioralCrisisCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/behavioral_crisis`,
                    data: behavioralCrisisCardBundle,
                });
            },
            upsertSupplementCard(reportId, supplementCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/supplement`,
                    data: supplementCardBundle,
                });
            },
            upsertStopCard(reportId, stopCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/stop`,
                    data: stopCardBundle,
                });
            },
            upsertFieldContactCard(reportId, fieldContactCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/field_contact`,
                    data: fieldContactCardBundle,
                });
            },
            upsertCommunityInfoCard(reportId, communityInfoCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/community_info`,
                    data: communityInfoCardBundle,
                });
            },
            upsertUseOfForceCard(reportId, useOfForceCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/use_of_force`,
                    data: useOfForceCardBundle,
                });
            },
            upsertUseOfForceSubjectCard(reportId, useOfForceSubjectCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/use_of_force_subject`,
                    data: useOfForceSubjectCardBundle,
                });
            },
            upsertMissingPersonsCard(reportId, missingPersonsCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/missing_persons`,
                    data: missingPersonsCardBundle,
                });
            },
            upsertBookingCard(reportId, bookingCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/booking`,
                    data: bookingCardBundle,
                });
            },
            upsertRelationshipsCard(reportId, relationshipsCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/relationships`,
                    data: relationshipsCardBundle,
                });
            },
            upsertCitationCard(reportId, citationCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/citation`,
                    data: citationCardBundle,
                });
            },
            upsertTrafficCrashCard(reportId, trafficCrashCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/traffic_crash`,
                    data: trafficCrashCardBundle,
                });
            },
            upsertCourtCaseCard(reportId, courtCaseCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/court_case`,
                    data: courtCaseCardBundle,
                });
            },
            upsertChargesCard(reportId, chargesCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/charges`,
                    data: chargesCardBundle,
                });
            },
            upsertTowVehicleReleaseCard(reportId, towVehicleReleaseCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/tow_vehicle_release`,
                    data: towVehicleReleaseCardBundle,
                });
            },
            upsertTowVehicleCheckInCard(reportId, towVehicleCheckInCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/tow_vehicle_check_in`,
                    data: towVehicleCheckInCardBundle,
                });
            },
            upsertSummaryNarrativeCard(reportId, summaryNarrativeCardBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/summary_narrative`,
                    data: summaryNarrativeCardBundle,
                });
            },
        },
    });
}

/**
 * Resources for Report Card Bundle objects. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

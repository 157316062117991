import { filter } from 'lodash';
import moment from 'moment';

import {
    calculateAge,
    formatAgeRangeNumbersOnly,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';

export const createDelimitedString = (
    fields: (string | number | undefined)[],
    delimiter = ' | '
) => {
    const filteredFields = filter(fields, (field) => !!field);
    return filteredFields.join(delimiter);
};

export const formatDob = (
    dob: string | undefined,
    dobRangeStart: string | undefined,
    dobRangeEnd: string | undefined
): string => {
    if (!!dob) {
        return moment(dob).format('L');
    }
    const dobDisplay = [];
    if (dobRangeStart) {
        dobDisplay.push(formatDob(dobRangeStart, undefined, undefined));
    }
    if (dobRangeEnd) {
        dobDisplay.push(formatDob(dobRangeEnd, undefined, undefined));
    }
    return dobDisplay.join(' - ');
};

export const formatAge = (
    dateOfReference: string,
    dateOfBirth: string | undefined,
    dateOfBirthRangeStart: string | undefined,
    dateOfBirthRangeEnd: string | undefined
): string => {
    if (dateOfBirth) {
        const age = calculateAge(dateOfReference, dateOfBirth);
        if (age === null || moment(dateOfBirth).isAfter(dateOfReference)) {
            return '';
        }
        return ` (${age})`;
    }
    if (dateOfBirthRangeStart || dateOfBirthRangeEnd) {
        return ` (${formatAgeRangeNumbersOnly({
            dateOfReference,
            dateOfBirthRangeStart,
            dateOfBirthRangeEnd,
        })})`;
    }
    return '';
};

import { chain, get, map, omit, pick, trim } from 'lodash';
import { createSelector } from 'reselect';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    allSubdivisionAttrIdNames,
    includeLocationSubdivisions,
    getSubdivisionsFromLocation,
    getElasticQuerySubdivisionAttrIds,
} from '~/client-common/core/domain/locations/utils/subdivisionHelpers';
import { DISPLAY_ONLY_CAD_LABEL } from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../../../../core/forms';
import {
    formDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import {
    cadTicketFieldsetViewModel,
    locationsFieldsetViewModel,
} from '../../../../../legacy-redux/configs/fieldsetsConfig';
import recursivelyConvertElasticLocationQueries from '../../../core/utils/recursivelyConvertElasticLocationQueries';

const advancedSearchCadTicketsFormFieldViewModels = {
    ...buildFlatFormFieldViewModels([
        // fields in the form that are not nested
        'fuzzyMatchingEnabled',
    ]),
    cadTicket: cadTicketFieldsetViewModel,
    locations: locationsFieldsetViewModel,
};

/**
 * Convert the given search query model to form state. While the query is flat,
 *   the form state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertAdvancedSearchCadTicketsElasticQueryToFormModel(
    elasticQuery,
    state,
    boundSelectors
) {
    const CAD_MULTI_REN_GENERATION_ENABLED = get(
        state ? applicationSettingsSelector(state) : get(boundSelectors, 'applicationSettings'),
        'CAD_MULTI_REN_GENERATION_ENABLED'
    );

    const renFormModel = !CAD_MULTI_REN_GENERATION_ENABLED
        ? {
              reportingEventNumbers: chain(elasticQuery)
                  .get('reportingEventNumbers')
                  .map((reportingEventNumber) => ({ reportingEventNumber }))
                  .value(),
          }
        : {
              rens: chain(elasticQuery)
                  .get('rens')
                  .map((ren) => ({ ren }))
                  // To support backward compatibility
                  .concat(
                      chain(elasticQuery)
                          .get('reportingEventNumbers')
                          .map((ren) => ({ ren }))
                          .value()
                  )
                  .value(),
          };

    const locationTransformedElasticQuery = recursivelyConvertElasticLocationQueries(elasticQuery, {
        legacyQueryProp: 'involvedLocations',
        newQueryProp: 'locations',
    });

    const subdivisions = getElasticQuerySubdivisionAttrIds(
        locationTransformedElasticQuery,
        'locations'
    );

    const result = buildFormModel(
        {
            fuzzyMatchingEnabled: get(elasticQuery, 'fuzzyMatchingEnabled'),
            cadTicket: {
                ...pick(elasticQuery, map(cadTicketFieldsetViewModel.fields, 'key')),
                ...renFormModel,
                agencyEventNumbers: chain(elasticQuery)
                    .get('agencyEventNumbers')
                    .map((agencyEventNumber) => ({ agencyEventNumber }))
                    .value(),
                primaryUnitCallSigns: get(elasticQuery, 'primaryUnitCallSigns')?.join(','),
                involvedUnitCallSigns: get(elasticQuery, 'involvedUnitCallSigns[0]'),
                ...subdivisions, // move from `locations`
                callerPhoneNumber: get(elasticQuery, 'callerPhoneNumber[0]'),
            },
            locations: chain(locationTransformedElasticQuery)
                .get('locations')
                // move the subdivisons-only location to `cadTicket`
                .reject(includeLocationSubdivisions)
                .value(),
        },
        advancedSearchCadTicketsFormFieldViewModels
    );

    return result;
}

/**
 * Flatten the given form model state into a search query model which can be sent to
 *   the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertAdvancedSearchCadTicketsFormModelToElasticQuery(formModel = {}) {
    return filterFormData({
        fuzzyMatchingEnabled: formModel.fuzzyMatchingEnabled,
        ...omit(formModel.cadTicket, allSubdivisionAttrIdNames),
        agencyEventNumbers: chain(formModel.cadTicket)
            .get('agencyEventNumbers')
            .map(({ agencyEventNumber }) => agencyEventNumber)
            .value(),
        // When removing CAD_MULTI_REN_GENERATION_ENABLED FF, delete reportingEventNumbers
        reportingEventNumbers: chain(formModel.cadTicket)
            .get('reportingEventNumbers')
            .map(({ reportingEventNumber }) => reportingEventNumber)
            .value(),
        rens: chain(formModel.cadTicket)
            .get('rens')
            .map(({ ren }) => ren)
            .value(),
        primaryUnitCallSigns: chain(formModel.cadTicket)
            .get('primaryUnitCallSigns')
            .split(',')
            .map((sign) => trim(sign).toUpperCase())
            .value(),
        involvedUnitCallSigns: chain(formModel.cadTicket)
            .get('involvedUnitCallSigns')
            .split(',')
            .map((sign) => trim(sign).toUpperCase())
            .value(),
        callerPhoneNumber: [get(formModel.cadTicket, 'callerPhoneNumber')],
        locations: [
            ...(formModel.locations || []),
            ...(includeLocationSubdivisions(formModel.cadTicket)
                ? [getSubdivisionsFromLocation(formModel.cadTicket)]
                : []),
        ],
    });
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
export function convertAdvancedSearchCadTicketsFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    const combinedSubdivisionsLabel = boundSelectors.combinedSubdivisionsLabel;
    const formatFieldByName = boundSelectors.formatFieldByName;
    const cadDisplayName = formatFieldByName(DISPLAY_ONLY_CAD_LABEL);

    // mutating deeply nested structures is not pretty... something like
    // https://github.com/kolodny/immutability-helper might be nice here
    const fieldViewModels = {
        ...advancedSearchCadTicketsFormFieldViewModels,
        cadTicket: {
            ...advancedSearchCadTicketsFormFieldViewModels.cadTicket,
            title: advancedSearchCadTicketsFormFieldViewModels.cadTicket.title(cadDisplayName),
            fields: {
                ...advancedSearchCadTicketsFormFieldViewModels.cadTicket.fields,
                subdivisionAttrIds: {
                    ...advancedSearchCadTicketsFormFieldViewModels.cadTicket.fields
                        .subdivisionAttrIds,
                    label: combinedSubdivisionsLabel,
                },
                agencyEventNumbers: {
                    ...advancedSearchCadTicketsFormFieldViewModels.cadTicket.fields
                        .agencyEventNumbers,
                    filterLabel: (index) =>
                        advancedSearchCadTicketsFormFieldViewModels.cadTicket.fields.agencyEventNumbers.filterLabel(
                            cadDisplayName,
                            index
                        ),
                    fields: {
                        ...advancedSearchCadTicketsFormFieldViewModels.cadTicket.fields
                            .agencyEventNumbers.fields,
                        agencyEventNumber: {
                            ...advancedSearchCadTicketsFormFieldViewModels.cadTicket.fields
                                .agencyEventNumbers.fields.agencyEventNumber,
                            label: advancedSearchCadTicketsFormFieldViewModels.cadTicket.fields.agencyEventNumbers.fields.agencyEventNumber.label(
                                cadDisplayName
                            ),
                        },
                    },
                },
            },
        },
    };

    return convertFormModelToFilterGroups(
        formModel,
        fieldViewModels,
        formatFieldValue,
        formatFieldByName
    );
}

const advancedSearchCadTicketsForm = createFormModule({
    formName: formClientEnum.ADVANCED_SEARCH_CAD_TICKETS,
    fieldViewModels: advancedSearchCadTicketsFormFieldViewModels,
    convertToFormModel: convertAdvancedSearchCadTicketsElasticQueryToFormModel,
    convertFromFormModel: convertAdvancedSearchCadTicketsFormModelToElasticQuery,
});

advancedSearchCadTicketsForm.selectors.formIsEmptySelector = createSelector(
    advancedSearchCadTicketsForm.selectors.formModelSelector,
    (formModel) => formDataIsEmpty(omit(formModel, 'fuzzyMatchingEnabled'))
);

/**
 * Module of the advanced search CAD tickets form.
 * @type {Object}
 */
export default advancedSearchCadTicketsForm;

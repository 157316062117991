import { Court, CourtCreationRequest, CourtUpdateRequest } from '@mark43/rms-api';
import {
    createField,
    createFormConfiguration,
    formEvents,
    Form,
    InferFormDataShape,
} from 'markformythree';
import * as yup from 'yup';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../../core/validation/yupMftValidation';

const strings = componentStrings.validation;

export const COURTS_ADMIN_FORM = 'COURTS_ADMIN_FORM';

type FormModel = InferFormDataShape<typeof mftFormConfiguration>;

export const mftFormConfiguration = createFormConfiguration({
    id: createField<number>({}),
    courtName: createField<string>({}),
    courtIdentifier: createField<string>({}),
    isDeactivated: createField<boolean>({}),
});

export function convertToFormModel(court: Court): FormModel {
    const { courtIdentifier, courtName, id, isDeactivated } = court;

    return {
        courtIdentifier,
        courtName,
        id,
        isDeactivated,
    };
}

export function convertFromFormModel(
    formModel: FormModel
): Partial<CourtCreationRequest> | Partial<CourtUpdateRequest> {
    const { courtIdentifier, courtName, id, isDeactivated } = formModel;
    return {
        courtIdentifier,
        courtName,
        id,
        isDeactivated: !!isDeactivated,
    };
}

const validationSchema = yup.object().shape({
    courtName: yup.string().required(strings.requiredError),
    courtIdentifier: yup.string().required(strings.requiredError),
});

export const onValidate: Form['props']['onValidate'] = ({ formState, eventType }) =>
    validationSchema
        .validate(formState.model, { abortEarly: false })
        .then((validationResult) =>
            convertYupSuccessShapeToMFTSuccessShape(validationResult, formState.ui.$form)
        )
        .catch((validationErrors) =>
            convertYupErrorShapeToMFTErrorShape(validationErrors, formState.ui.$form, eventType)
        );

export const validationEvents = [
    {
        eventType: formEvents.FORM_SUBMIT,
    },
    {
        eventType: formEvents.INPUT_BLUR,
    },
    {
        eventType: formEvents.INPUT_CHANGE,
    },
];

export const initialState = {
    id: undefined,
    courtName: undefined,
    courtIdentifier: undefined,
    isDeactivated: undefined,
};

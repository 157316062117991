import { createSelector } from 'reselect';
import { mapValues } from 'lodash';
import { tasksSelector } from '../data';
import { allRoleFormatsByRoleIdSelector } from '../../../roles/state/data';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '../../../attributes/state/data';
import { isDateInFuture } from '../../../../dates/utils/dateHelpers';
import globalAttributes from '../../../../legacy-constants/globalAttributes';

import {
    buildViewModel,
    buildFormatRolesMapper,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { applicationSettingsSelector } from '../../../settings/state/data';
import { taskViewsSelector } from '../../../taskViews/state/data';

export const taskViewModelsSelector = createSelector(
    tasksSelector,
    taskViewsSelector,
    allRoleFormatsByRoleIdSelector,
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
    applicationSettingsSelector,
    (
        tasks,
        taskViews,
        allRoleFormatsByRoleId,
        formatAttributeById,
        parentAttributeIdByAttributeId,
        applicationSettings
    ) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                buildFormatRolesMapper({
                    assignee: 'assigneeRoleId',
                }),
                ({ dueDateUtc, statusAttrId }) => ({
                    isOverdue:
                        !!dueDateUtc &&
                        !isDateInFuture(dueDateUtc) &&
                        parentAttributeIdByAttributeId(statusAttrId) !==
                            globalAttributes.taskStatusGlobal.completed,
                }),
                ({ statusAttrId }) => ({
                    statusParentAttrId: parentAttributeIdByAttributeId(statusAttrId),
                    isCompleted:
                        parentAttributeIdByAttributeId(statusAttrId) ===
                        globalAttributes.taskStatusGlobal.completed,
                    isPending:
                        parentAttributeIdByAttributeId(statusAttrId) ===
                        globalAttributes.taskStatusGlobal.pending,
                }),
            ],
            helpers: {
                allRoleFormatsByRoleId,
                formatAttributeById,
            },
        });

        if (applicationSettings.RMS_TASK_AND_REQUEST_TRACKING_ENABLED) {
            return mapValues(taskViews, viewModel);
        }

        return mapValues(tasks, viewModel);
    }
);

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const streetAliasFormFieldViewModels = {
    streetAlias: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'streetAlias',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'streetId',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'aliases[]',
                validators: {
                    requiredError: requiredString,
                },
            },
        ]),
    },
};

function convertToFormModel(streetAlias) {
    return {
        streetAlias: {
            ...streetAlias,
        },
    };
}

function convertFromFormModel(formModel) {
    return {
        ...formModel.streetAlias,
    };
}

export default createFormModule({
    formName: formClientEnum.STREET_ALIASES_ADMIN,
    fieldViewModels: streetAliasFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import { ButtonProps as ArcButtonProps } from 'arc';
import { buttonTypes } from '../overlays/components/OverlayButton';

export type InteractionType =
    | React.MouseEvent<HTMLButtonElement>
    | React.MouseEvent<HTMLDivElement>
    | React.KeyboardEvent<HTMLDivElement>;

export const convertArcButtonVariantToLegacyClass = (variant: ArcButtonProps['variant']) => {
    switch (variant) {
        case 'solid':
            return buttonTypes.PRIMARY;

        case 'outline':
        case 'ghost':
        default:
            return buttonTypes.SECONDARY;
    }
};

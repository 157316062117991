import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { formDataIsEmpty } from '~/client-common/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

// Imported in /client/src/scripts/modules/admin/roles/state/forms/editRoleNameForm.js
export const DEFAULT_SESSION_LENGTH_MINUTES = 240;

const newRoleFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'name',
        validators: {
            requiredError: requiredString,
        },
    },
    {
        key: 'sessionTimeoutLength',
        fields: buildFlatFormFieldViewModels([{ key: 'hours' }, { key: 'mins' }]),
    },
]);

function convertFromFormModel(applicationSettings, formData, extraData) {
    let totalMins;
    // if formData.sessionTimeoutLength is null the field has not been modified on the frontend
    //      set value to default value, rather than throwing an error.
    if (formData.sessionTimeoutLength) {
        const parsedHours = parseInt(
            formDataIsEmpty(formData.sessionTimeoutLength.hours)
                ? 0
                : formData.sessionTimeoutLength.hours
        );
        const parsedMins = parseInt(
            formDataIsEmpty(formData.sessionTimeoutLength.mins)
                ? 0
                : formData.sessionTimeoutLength.mins
        );

        if (!isNaN(parsedHours) && !isNaN(parsedMins)) {
            totalMins = parsedHours * 60 + parsedMins;
        } else {
            totalMins = 0;
        }
    } else {
        //  totalMins is set to the default to ensure a sane value is set if nothing is entered by the user.
        totalMins = DEFAULT_SESSION_LENGTH_MINUTES;
    }

    return {
        name: formData.name,
        sessionLengthMinutes: totalMins,
        ...extraData,
    };
}

function convertToFormModel(role) {
    const parsedHours = parseInt(DEFAULT_SESSION_LENGTH_MINUTES / 60);
    const parsedMins = parseInt(DEFAULT_SESSION_LENGTH_MINUTES % 60);
    return {
        ...role,
        sessionTimeoutLength: {
            hours: parsedHours,
            mins: parsedMins,
        },
    };
}

export default createFormModule({
    formName: formClientEnum.NEW_ROLE_ADMIN,
    fieldViewModels: newRoleFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import React from 'react';
import classNames from 'classnames';
import LinkedReports from '../../../modules/records/core/components/sidebar/LinkedReports';

export default function LinkedReportsSection({
    className,
    isCollapsible,
    title,
    reportShortTitleViewModels,
    noReportsMessage,
}) {
    return (
        <div className={classNames(className, 'linked-reports-section')}>
            <div className="linked-reports-section-title">{title}</div>
            <div className="linked-reports-section-members">
                <LinkedReports
                    isCollapsible={isCollapsible}
                    reportShortTitleViewModels={reportShortTitleViewModels}
                    noReportsMessage={noReportsMessage}
                />
            </div>
        </div>
    );
}

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/print/templates';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Printing Templates Resource',
        methods: {
            /**
             * Get all evidence printing templates in the department.
             * @return {Promise<Object[]>}
             */
            getEvidencePrintingTemplates() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                });
            },

            /**
             * Create or update an evidence printing template.
             * @param  {Object}   evidencePrintingTemplate
             * @param  {Object}   evidencePrintingTemplate.template
             * @param  {Object[]} evidencePrintingTemplate.files
             * @return {Promise<Object>}
             */
            upsertEvidencePrintingTemplate(evidencePrintingTemplate) {
                return req({
                    method: 'PUT',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                    data: evidencePrintingTemplate,
                });
            },

            /**
             * Delete an evidence document.
             * @param  {number} evidencePrintingTemplateId
             * @return {Promise<Object>}
             */
            deleteEvidencePrintingTemplate(evidencePrintingTemplateId) {
                return req({
                    method: 'DELETE',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/${evidencePrintingTemplateId}`,
                });
            },
        },
    });
}

/**
 * Resource for evidence printing templates. See the tech doc for details.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 * @see
 *   https://docs.google.com/document/d/10R17mR9s_BnxBlTh804LA6LC3I7itAq1okQPR7f3OHI/
 */
const resourceGetter = () => resource;
export default resourceGetter;

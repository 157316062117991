import React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { propertyTitleForItemProfileSelector } from '../state/ui';

/**
 * Use this component to format any item profile title.
 *   Mirrors logic in formatTitleForPropertyRecord. If dealing with raw item profile models,
 *   try to use this component. If dealing with item profile view models,
 *   can use formatTitleForPropertyRecord.
 *   Using formatAttributeById instead of FormattedAttribute because of complexity around
 *   conditional string manipulation.
 *   Server mirrors logic to fill the ucr property's additional notes.
 *   mark43/cobalt-rms/reporting/reporting-services/src/main/java/mark43/reporting/services/ucr/UcrPrefillService.java.
 * @param  {Object} props.itemProfile  instance of item profile model
 * @param  {String} props.className    (Optional) className for resulting span.
 */
const PropertyTitle = compose(
    connect(
        createStructuredSelector({
            propertyTitleForItemProfile: propertyTitleForItemProfileSelector,
        })
    ),
    withPropsOnChange(
        ['itemProfile', 'propertyTitleForItemProfile'],
        ({ itemProfile, propertyTitleForItemProfile }) => {
            return { propertyTitle: propertyTitleForItemProfile(itemProfile) };
        }
    )
)(function PropertyTitle({ propertyTitle, className }) {
    return <span className={className}>{propertyTitle}</span>;
});

export default PropertyTitle;

import React, { FC } from 'react';
import { Icon } from 'arc';
import { ApprovalStatusForCaseEnum, ApprovalStatusForCaseEnumType } from '@mark43/rms-api';

const iconMap = {
    [ApprovalStatusForCaseEnum.APPROVED.name]: (
        <Icon size="md" color="positive.default" icon="SupervisorApproved" />
    ),
    [ApprovalStatusForCaseEnum.DRAFT.name]: <Icon size="md" color="icon.default" icon="Draft" />,
    [ApprovalStatusForCaseEnum.SUBMITTED.name]: (
        <Icon size="md" color="warning.default" icon="Pending" />
    ),
    [ApprovalStatusForCaseEnum.REJECTED.name]: (
        <Icon size="md" color="negative.default" icon="Rejected" />
    ),
};

type ApprovalStatusCellProps = {
    approvalStatus?: ApprovalStatusForCaseEnumType;
};

export const ApprovalStatusCell: FC<ApprovalStatusCellProps> = ({
    approvalStatus = ApprovalStatusForCaseEnum.DRAFT.name,
}) => {
    return <>{iconMap[approvalStatus]}</>;
};

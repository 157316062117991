import { IdFormatConfiguration, EntityTypeEnumType } from '@mark43/rms-api';
import { find, filter } from 'lodash';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import createIdFormatConfigurationResource from '../../resources/idFormatConfigurationResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'idFormatConfigurations';

const idFormatConfigurationModule = createNormalizedModule<IdFormatConfiguration>({
    type: NEXUS_STATE_PROP,
});

export const REMOVE_ID_FORMAT_CONFIGURATION_START =
    'id-format-configuration/REMOVE_ID_FORMAT_CONFIGURATION_START';
export const REMOVE_ID_FORMAT_CONFIGURATION_SUCCESS =
    'id-format-configuration/REMOVE_ID_FORMAT_CONFIGURATION_SUCCESS';
export const REMOVE_ID_FORMAT_CONFIGURATION_FAILURE =
    'id-format-configuration/REMOVE_ID_FORMAT_CONFIGURATION_FAILURE';

export const removeIdFormatConfigurationStart = () =>
    ({
        type: REMOVE_ID_FORMAT_CONFIGURATION_START,
    } as const);

export const removeIdFormatConfigurationSuccess = (idFormatConfigurationId: number) =>
    ({
        type: REMOVE_ID_FORMAT_CONFIGURATION_SUCCESS,
        payload: idFormatConfigurationId,
    } as const);

export const removeIdFormatConfigurationFailure = (error: { message: string }) =>
    ({
        type: REMOVE_ID_FORMAT_CONFIGURATION_FAILURE,
        payload: error.message,
    } as const);

export const idFormatConfigurationSelector = idFormatConfigurationModule.selectors.entitiesSelector;
export const idFormatConfigurationByIdSelector =
    idFormatConfigurationModule.selectors.entityByIdSelector;
export const idFormatConfigurationByEntityTypeSelector = createSelector(
    idFormatConfigurationSelector,
    (idFormatConfigurations) => (
        entityType: EntityTypeEnumType,
        agencyId: number | undefined = undefined
    ) => {
        if (!agencyId) {
            return find(idFormatConfigurations, { entityType });
        }

        return find(idFormatConfigurations, { entityType, agencyId });
    }
);

export const idFormatConfigurationsForEntityTypeSelector = createSelector(
    idFormatConfigurationSelector,
    (idFormatConfigurations) => (entityType: EntityTypeEnumType) =>
        filter(idFormatConfigurations, { entityType })
);

export const idFormatConfigurationByIdAndEntityTypeSelector = createSelector(
    idFormatConfigurationSelector,
    (idFormatConfigurations) => (id: number, entityType: EntityTypeEnumType) =>
        find(idFormatConfigurations, { id, entityType })
);

export const removeIdFormatConfigurationById = (
    idFormatConfigurationId: number
): ClientCommonAction<Promise<void>> => {
    const idFormatConfigurationResource = createIdFormatConfigurationResource();
    return (dispatch, getState, { nexus }) => {
        dispatch(removeIdFormatConfigurationStart());
        return idFormatConfigurationResource
            .removeIdFormatConfigurationById(idFormatConfigurationId)
            .then(() => {
                dispatch(
                    nexus.withRemove(
                        NEXUS_STATE_PROP,
                        {
                            id: idFormatConfigurationId,
                        },
                        removeIdFormatConfigurationSuccess(idFormatConfigurationId)
                    )
                );
            })
            .catch((err: Error) => {
                dispatch(removeIdFormatConfigurationFailure(err));
                throw err;
            });
    };
};

export default idFormatConfigurationModule.reducerConfig;

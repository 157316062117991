import React from 'react';
import styled from 'styled-components';
import { cssVar } from 'arc';
import { EntityTypeEnum } from '@mark43/rms-api';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get, map } from 'lodash';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import { DISPLAY_CASE_ASSIGNEE } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';

import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { canWrite } from '~/client-common/core/domain/entity-permissions/state/ui';
import { useAbilitySelector } from '../../../core/current-user/hooks/useAbilitySelector';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import CaseSection from '../../core/components/CaseSection';
import {
    currentUserCasePermissionsSelector,
    reportsResultsViewModelsSelector,
    supervisorCaseRoleLinksSelector,
    assigneeCaseRoleLinkSelector,
    assistingInvestigatorCaseRoleLinksSelector,
    currentCaseNotesSelector,
} from '../../core/state/ui';
import { sortedCurrentCaseTaskViewModelsSelector } from '../../task-list/state/ui';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import testIds from '../../../../core/testIds';
import { SecurityClassificationBanner as _SecurityClassificationBanner } from '../../../reports/core/components/security-classifications/SecurityClassificationBanner';
import _EnhancedCaseReports from './EnhancedCaseReports';
import _CaseTargetProfileSection from './CaseTargetProfileSection';
import _CaseTasks from './CaseTasks';
import CaseNotes from './CaseNotes';
import ReasonForRelationModal from './ReasonForRelationModal';

const caseDetailsSummaryStrings = componentStrings.cases.core.CaseDetailsSummary;

const CaseSummaryDetails = styled.div`
    display: flex;
    padding-bottom: 20px;
`;

const FirstSummaryListColumn = styled(SummaryList)`
    margin-right: 50px;
`;

const WrappingSummaryRow = styled(SummaryRow)`
    > .summary-row-content {
        word-break: break-word;
    }
`;

const EnhancedCaseReports = styled(_EnhancedCaseReports)`
    margin-bottom: 20px;
`;

const CaseTargetProfileSection = styled(_CaseTargetProfileSection)`
    margin-bottom: 20px;
`;

const CaseTasks = styled(_CaseTasks)`
    margin-bottom: 20px;
`;

const SecurityClassificationBanner = styled(_SecurityClassificationBanner)`
    margin: 0 0 ${cssVar('arc.space.4')};
`;

const CaseDetails = function CaseDetails({
    caseApprovalStatus,
    caseStatusViewModel,
    caseViewModel,
    caseReports,
    sortedTaskViewModels,
    caseNotes,
    supervisorCaseRoleLinks,
    assigneeCaseRoleLink,
    assistingInvestigatorCaseRoleLinks,
    fieldDisplayNames,
}) {
    const canCaseEditGeneral = useAbilitySelector(abilitiesEnum.CASES.EDIT_GENERAL);
    const caseIsEditable = caseViewModel
        ? canWrite(caseViewModel.permissionSet) && canCaseEditGeneral
        : false;
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const { caseDefinitionId, assignedPersonnelUnitAttrId } = getViewModelProperties(caseViewModel);
    const displayCaseStatus = getViewModelProperties(caseStatusViewModel);
    const approvalStatus =
        componentStrings.forms.select.ApprovalStatusForCaseSelect.options[
            caseApprovalStatus.status
        ];
    const { names: assigneeNameFormats } = getViewModelProperties(assigneeCaseRoleLink);
    const assigneeName = get(assigneeNameFormats, 'fullNameWithFirstInitialAndIdNumber');
    const assistingInvestigators = map(
        assistingInvestigatorCaseRoleLinks,
        (assistingInvestigatorCaseRoleLink) => {
            const { names: assistingInvestigatorNameFormats } = getViewModelProperties(
                assistingInvestigatorCaseRoleLink
            );
            return get(assistingInvestigatorNameFormats, 'fullNameWithFirstInitialAndIdNumber');
        }
    ).join(', ');
    const supervisors = map(supervisorCaseRoleLinks, (supervisorCaseRoleLink) => {
        const { names: supervisorNameFormats } = getViewModelProperties(supervisorCaseRoleLink);
        return get(supervisorNameFormats, 'fullNameWithFirstInitialAndIdNumber');
    }).join(', ');

    return (
        <>
            <SecurityClassificationBanner
                ownerType={EntityTypeEnum.CASE.name}
                ownerId={caseViewModel.id}
            />
            <CaseSection title={caseDetailsSummaryStrings.title(caseDisplayName)}>
                <>
                    <CaseSummaryDetails data-test-id={testIds.CASE_DETAILS_SECTION}>
                        <FirstSummaryListColumn labelWidth={150}>
                            <WrappingSummaryRow
                                label={caseDetailsSummaryStrings.caseType(caseDisplayName)}
                            >
                                {caseDefinitionId}
                            </WrappingSummaryRow>
                            <WrappingSummaryRow
                                label={caseDetailsSummaryStrings.caseStatus(caseDisplayName)}
                            >
                                {displayCaseStatus.statusAttrId}
                            </WrappingSummaryRow>
                            {caseViewModel.dueDateUtc && (
                                <WrappingSummaryRow label={caseDetailsSummaryStrings.dueDate}>
                                    <FormattedDate
                                        format={FormattedDate.FORMATS.FORM_DATE}
                                        date={caseViewModel.dueDateUtc}
                                    />
                                </WrappingSummaryRow>
                            )}
                            <WrappingSummaryRow label={caseDetailsSummaryStrings.approvalStatus}>
                                {approvalStatus}
                            </WrappingSummaryRow>
                        </FirstSummaryListColumn>
                        <SummaryList labelWidth={150}>
                            <WrappingSummaryRow label={fieldDisplayNames.DISPLAY_CASE_ASSIGNEE}>
                                {assigneeName}
                            </WrappingSummaryRow>
                            <WrappingSummaryRow
                                label={caseDetailsSummaryStrings.assistingInvestigators}
                            >
                                {assistingInvestigators}
                            </WrappingSummaryRow>
                            <WrappingSummaryRow
                                label={caseDetailsSummaryStrings.assignedPersonnelUnit}
                            >
                                {assignedPersonnelUnitAttrId}
                            </WrappingSummaryRow>
                            <WrappingSummaryRow label={caseDetailsSummaryStrings.supervisors}>
                                {supervisors}
                            </WrappingSummaryRow>
                        </SummaryList>
                    </CaseSummaryDetails>
                    <FeatureFlagged flag="RMS_TARGET_PROFILES_ENABLED">
                        {caseViewModel.isTargetProfile && (
                            <CaseTargetProfileSection caseViewModel={caseViewModel} />
                        )}
                    </FeatureFlagged>
                    <EnhancedCaseReports
                        caseReports={caseReports}
                        caseIsEditable={caseIsEditable}
                    />
                    <OnlyWithAbility has={abilitiesEnum.CASES.VIEW_CASE_TASKS}>
                        <CaseTasks sortedTaskViewModels={sortedTaskViewModels} />
                    </OnlyWithAbility>
                    <OnlyWithAbility has={abilitiesEnum.CASES.VIEW_NOTES}>
                        <CaseNotes caseNotes={caseNotes} />
                    </OnlyWithAbility>
                </>
                <FeatureFlagged flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE">
                    <ReasonForRelationModal caseId={caseViewModel.id} />
                </FeatureFlagged>
            </CaseSection>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    caseReports: reportsResultsViewModelsSelector,
    userCasePermissions: currentUserCasePermissionsSelector,
    sortedTaskViewModels: sortedCurrentCaseTaskViewModelsSelector,
    caseNotes: currentCaseNotesSelector,
    supervisorCaseRoleLinks: supervisorCaseRoleLinksSelector,
    assigneeCaseRoleLink: assigneeCaseRoleLinkSelector,
    assistingInvestigatorCaseRoleLinks: assistingInvestigatorCaseRoleLinksSelector,
    applicationSettings: applicationSettingsSelector,
});

export default compose(
    connect(mapStateToProps, null),
    withFields([DISPLAY_CASE_ASSIGNEE])
)(CaseDetails);

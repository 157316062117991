import React, { Component } from 'react';
import { Form, createFormConfiguration, lifecycleOptions } from 'markformythree';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { MFTAsyncText } from '../../forms/components/AsyncText';
import { submitSearchForm } from '../state/ui';

const strings = componentStrings.core.LocationSidePanel;

export const LOCATION_SEARCH_FORM = 'LOCATION_SEARCH_FORM';

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const FormWrapper = styled.div`
    flex: 1;
    width: 100%;
`;

const Input = styled(MFTAsyncText)`
    width: 100%;

    && {
        float: none;
    }
`;

class LocationSearchInput extends Component {
    constructor(...args) {
        super(...args);
        this.formConfiguration = createFormConfiguration({
            searchQuery: {},
        });
    }

    onInput = (value) => {
        const { onSearch, storeResults, setErrors, onInputOverride } = this.props;
        if (onInputOverride) {
            return onInputOverride(value);
        }

        if (onSearch) {
            onSearch();
        }

        storeResults([]);

        if (!value || value.length < 4) {
            return;
        }

        const onSuccess = (results) => {
            storeResults(results);
            setErrors([]);
        };

        // always wipe results when typing
        setErrors([]);

        return submitSearchForm({
            query: value,
            onSuccess,
            onError: setErrors,
        });
    };

    renderInput = () => (
        <Input
            setRef={this.props.setRef}
            onClick={this.props.onClick}
            path="searchQuery"
            onFocus={this.props.onFocus}
            placeholder={strings.searchPlaceholder}
            asyncAction={this.onInput}
            typeaheadThrottle={300}
            // needs to match our legacy code's field name
            // can be updated once the legacy code has been removed
            // and we can consolidate E2E tests
            fieldName="GENERIC_SEARCH_FIELD"
            className="location-side-panel-search-input"
            autoCursorDisabled={true}
        />
    );

    render() {
        return (
            <FormContainer>
                <FormWrapper>
                    <Form
                        lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                        name={LOCATION_SEARCH_FORM}
                        configuration={this.formConfiguration}
                        render={this.renderInput}
                    />
                </FormWrapper>
                {this.props.afterFormContent}
            </FormContainer>
        );
    }
}

export default LocationSearchInput;

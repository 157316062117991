export default {
    reactSidepanelContainer: 2005,
    reactSidepanelContainerOverride: 2015,

    /**
     * reactModalContainer is not currently set or used because our modal component
     * uses "portal" which causes our modals to render outside of the container
     * that they are declared in. This makes the z-index value of `Modals.js` meaningless
     * (if one were supplied). This key/undefined value/comment are nonetheless here
     * so as to leave a record of our reasoning.
     */
    reactModalContainer: undefined,
    /**
     * this z-index matches the z-index defined in sass for `.mark43-modal-overlay`.
     * This is important so that the stacking of side panels and modals using overlay manager
     * properly works with legacy modals
     */
    reactPortalSidePanels: 2025,
    reactPageContainer: 200,
    loadingBar: 200,
    quickSearch: 20,
    leftNavigation: 2100,
    /**
     * this z-index matches the z-index defined in sass for `.mark43-react-card-header`.
     * It is this low because cards are rendered within a container which itself contains
     * a z-index of `101` as of time of writing. This z-index is only here to ensure
     * sticky headers are on top of other elements. There are no known positioned
     * elements right now that would inferere with this.
     */
    reactCardHeader: 10,

    // Date picker
    calendar: 50,
    // for all popout menus
    menu: 50,
    // Sticky header
    stickyHeader: 49,
    actionBar: 50,
    narrativeInlineComment: 48,
};

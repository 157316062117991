// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

const BASE_URL = '/notifications/api';
const BASE_PATH = 'notifications/alerts';

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Notification Alert Schedules',
        methods: {
            /**
             * Get Alert Schedules.
             * @return {Promise<Object[]>}
             */
            getAlertSchedules() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                });
            },
            /**
             * Create new Alert Schedule.
             * @param {Object} alertSchedule
             * @return {Promise<Object>}
             */
            createAlertSchedule(alertSchedule) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                    data: alertSchedule,
                });
            },
            /**
             * Update single Alert Schedule.
             * @param {Object} alertSchedule
             * @return {Promise<Object>}
             */
            updateAlertSchedule(alertSchedule) {
                return req({
                    method: 'PUT',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/${alertSchedule.alertScheduleId}`,
                    data: alertSchedule,
                });
            },
            /**
             * Delete Single Alert Schedule.
             * @param {number} alertScheduleId
             * @return {Promise<Object>}
             */
            deleteAlertSchedule(alertScheduleId) {
                return req({
                    method: 'DELETE',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/${alertScheduleId}`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
/**
 * Resources for alert schedules. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
export default resourceGetter;

import { LinkTypesEnum } from '@mark43/rms-api';
import type { LinkTypesEnumType } from '@mark43/rms-api';

/*
 * Dragon query configurations store link types by their id, but our component requires
 * the string name. We use this reverse lookup enum to find the correct name for a given link type id
 */
const reverseLookupLinkTypeEnum = (Object.entries(LinkTypesEnum) as [
    keyof typeof LinkTypesEnum,
    LinkTypesEnumType
][]).reduce<Record<number, keyof typeof LinkTypesEnum>>((acc, [key, value]) => {
    acc[value] = key;
    return acc;
}, {});

export function assertValidLinkTypeEnumValue(value: number): asserts value is LinkTypesEnumType {
    if (!reverseLookupLinkTypeEnum[value]) {
        throw new Error(`${value} is not a valid link type id.`);
    }
}

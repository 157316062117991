import { createSelector } from 'reselect';
import {
    createThumbnailViewModels,
    createCurrentImageViewModels,
} from './imageGallerySelectorHelpers';

const imageGalleryUiSelector = (state) => state.ui.imageGallery;
export const imageGalleryViewModelsSelector = createSelector(imageGalleryUiSelector, (ui) => {
    return {
        thumbnails: createThumbnailViewModels(ui.images, ui.currentIndex),
        currentImages: createCurrentImageViewModels(ui.images, ui.currentIndex),
    };
});

export const imageGalleryCurrentIndexSelector = createSelector(
    imageGalleryUiSelector,
    ({ currentIndex }) => currentIndex
);

export const imageGalleryEmailsEnabledSelector = createSelector(
    imageGalleryUiSelector,
    ({ emailsEnabled }) => emailsEnabled
);

import { _Form } from 'markformythree';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';

import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import { RmsDispatch } from '../../../../../core/typings/redux';
import createCard from '../../utils/createCard';
import {
    convertFromFormModel,
    buildCrashEventInfoFormModel,
    CrashEventInfoFormConfiguration,
} from '../forms/crashEventInfoForm';
import { updateCrashEventInfo } from './crashEventInfo';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    anchor: 'crash-event-info-card',
    baseSelector: (state: RootState) => state.ui.report.crashEventInfoCard,
    name: reportCardEnum.CRASH_EVENT_INFO.name,
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit: () => (dispatch: RmsDispatch) => {
            dispatch(buildCrashEventInfoFormModel());
            dispatch(baseCard.actionCreators.transitionToEditMode());
        },
        save:
            (form: _Form<CrashEventInfoFormConfiguration>) =>
            (dispatch: RmsDispatch, getState: () => RootState) => {
                form.resetUi();
                const state = getState();
                // @ts-expect-error createCard not typed
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);

                if (isInSummaryMode) {
                    return dispatch(validateCard({ card: baseCard, formComponent: form }));
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const { model } = form.getState();
                                const reportId = currentReportIdSelector(state);
                                if (!reportId) {
                                    return;
                                }
                                const attributes = attributesSelector(state);
                                const result = convertFromFormModel(
                                    // @ts-expect-error customProperties isn't being correctly inferred by
                                    // markformythree's createFormConfiguration
                                    model,
                                    reportId,
                                    attributes
                                );
                                return [dispatch(updateCrashEventInfo(result, reportId))];
                            },
                            onSavingSuccess: () => {
                                dispatch(buildCrashEventInfoFormModel());
                            },
                        })
                    );
                }
            },
    },
};

import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

const strings = componentStrings.search.AdvancedSearchPropertyResults.IdentifiersCell.labels;

export default function IdentifiersCell(elasticProperty) {
    const { serialNumber } = elasticProperty;

    const { itemCategoryAttrId } = getViewModelProperties(elasticProperty);

    return (
        <div className="elastic-property-identifiers">
            {itemCategoryAttrId && (
                <div className="info-group">
                    <div className="info-label">{strings.category}</div>
                    <div className="info-text">{itemCategoryAttrId}</div>
                </div>
            )}
            {serialNumber && (
                <div className="info-group">
                    <div className="info-label">{strings.serialNumber}</div>
                    <div className="info-text">{serialNumber}</div>
                </div>
            )}
        </div>
    );
}

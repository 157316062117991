import React from 'react';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { createModalSelector } from '../../../core/box/state/ui';
import { submitEditCaseRenModal } from '../state/ui';
import Modal from '../../../../legacy-redux/components/core/Modal';
import EditCaseRenForm from './EditCaseRenForm';

const context = { name: boxEnum.EDIT_CASE_REN_MODAL };
const strings = componentStrings.cases.core.EditCaseRenModal;

function EditCaseRenModal({
    submitEditCaseRen,
    fieldDisplayNames,
    existingCaseIds = [],
    existingReportIds = [],
    isCaseRenValid,
}) {
    return (
        <Modal
            context={context}
            title={strings.title(fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER)}
            onSave={submitEditCaseRen}
            okText={strings.okText}
            saveDisabled={!isCaseRenValid}
        >
            <EditCaseRenForm
                existingCaseIds={existingCaseIds}
                existingReportIds={existingReportIds}
            />
        </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
    existingCaseIds: createModalSelector(context, 'existingCaseIds'),
    existingReportIds: createModalSelector(context, 'existingReportIds'),
    isCaseRenValid: createModalSelector(context, 'isCaseRenValid'),
});

const mapDispatchToProps = (dispatch) => ({
    submitEditCaseRen: () => dispatch(submitEditCaseRenModal()),
});

/**
 * Modal for changing the Case REN on an existing case.
 */
export default flowRight(
    connect(mapStateToProps, mapDispatchToProps),
    withFields([REPORT_REPORTING_EVENT_NUMBER])
)(EditCaseRenModal);

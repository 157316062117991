import { EntityTypeEnum } from '@mark43/rms-api';

import _, { get, map, omit, pick, flatten, first, difference } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import {
    DISPLAY_ONLY_CASE_SEARCH_REPORT_CREATED_DATE_TIME_RANGE_LABEL,
    DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL,
    DISPLAY_ONLY_WILL_NOT_INVESTIGATE_LABEL,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { nibrsOffenseCodeByCodeSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import combineNibrsOffenseCodeIds from '~/client-common/core/domain/nibrs-offense-codes/utils/combineNibrsOffenseCodeIds';
import {
    includeLocationSubdivisions,
    getSubdivisionsFromLocation,
    getElasticQuerySubdivisionAttrIds,
} from '~/client-common/core/domain/locations/utils/subdivisionHelpers';

import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import { filterFormData, buildFormModel } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';
import {
    parseAdditionalFieldsFromReportDefinitions,
    parseHasOffenseCaseStatusFromReportDefinitions,
    parseOffenseCaseStatusesFromReportDefinitions,
} from '../../../../core/elastic-search/util/parseOffenseCaseStatusFromReportDefinitions';
import unassignedReportsSearchReportDefinitionFieldsetViewModel from './unassignedReportsSearchReportDefinitionFieldset';
import unassignedReportsSearchReportDetailsFieldsetViewModel from './unassignedReportsSearchReportDetailsFieldset';
import unassignedReportsSearchPersonnelFieldsetViewModel from './unassignedReportsSearchPersonnelFieldset';

const strings = componentStrings.cases.unassignedReports.UnassignedReports.savedSearch;

const { DEPARTMENT } = fieldTypeClientEnum;

const unassignedReportsSearchFormFieldViewModels = {
    personnel: unassignedReportsSearchPersonnelFieldsetViewModel,
    reportDetails: unassignedReportsSearchReportDetailsFieldsetViewModel,
    reportDefinitions: unassignedReportsSearchReportDefinitionFieldsetViewModel,
    departmentIds: {
        key: 'departmentIds',
        type: DEPARTMENT,
    },
};

/**
 * Convert the given search query model to form state. While the query is flat,
 *   the form state has a nested structure. The elastic query shape for
 *   unassigned reports is the same as for advanced search reports.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertUnassignedReportsSearchElasticQueryToFormModel(
    elasticQuery,
    state,
    boundSelectors
) {
    const reportDefinitions = get(elasticQuery, 'reportDefinitions', []);
    const hasRoutingLabel = get(elasticQuery, 'hasRoutingLabel');
    const subdivisions = getElasticQuerySubdivisionAttrIds(elasticQuery, 'involvedLocations');
    const hasOffenseCaseStatusVal = parseHasOffenseCaseStatusFromReportDefinitions(elasticQuery);

    return buildFormModel(
        {
            personnel: {
                ...pick(
                    elasticQuery,
                    map(unassignedReportsSearchPersonnelFieldsetViewModel.fields, 'key')
                ),
                involvedOfficerIds: flatten(
                    map(get(elasticQuery, 'involvedOfficers'), 'officerIds')
                ),
            },
            reportDetails: {
                ...pick(
                    elasticQuery,
                    map(unassignedReportsSearchReportDetailsFieldsetViewModel.fields, 'key')
                ),
                ...subdivisions,
                hasRoutingLabel: [hasRoutingLabel],
                offenseCaseStatusAttrId: parseOffenseCaseStatusesFromReportDefinitions(
                    elasticQuery
                ),
                ...(hasOffenseCaseStatusVal !== undefined
                    ? {
                          hasOffenseCaseStatus: hasOffenseCaseStatusVal.toString(),
                      }
                    : {}),
                ...parseAdditionalFieldsFromReportDefinitions(elasticQuery),
            },
            reportDefinitions: map(reportDefinitions, (reportDefinition) => {
                const offenseAndIncident = get(reportDefinition, 'offensesAndIncidents[0]', {});
                const nibrsCodeIds = combineNibrsOffenseCodeIds({
                    ids: offenseAndIncident.nibrsCodeIds,
                    codes: offenseAndIncident.nibrsCodes,
                    codeByCode: state
                        ? nibrsOffenseCodeByCodeSelector(state)
                        : get(boundSelectors, 'nibrsOffenseCodeByCode'),
                });

                return {
                    reportDefinitionId: reportDefinition.reportDefinitionId,
                    nibrsCodeIds,
                    nibrsGroups: offenseAndIncident.nibrsGroups,
                    ucrGroups: offenseAndIncident.ucrGroups,
                };
            }),
            departmentIds: get(elasticQuery, 'departmentIds'),
        },
        unassignedReportsSearchFormFieldViewModels
    );
}

const convertStringToBoolean = (value) => {
    if (value === 'false') {
        return false;
    }
    if (value === 'true') {
        return true;
    }
    return undefined;
};

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching. The elastic query shape for unassigned
 *   reports is the same as for advanced search reports.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertUnassignedReportsSearchFormModelToElasticQuery(
    formModel = {},
    { recordsWithoutRenEnabled = false } = {}
) {
    const involvedOfficerIds = get(formModel.personnel, 'involvedOfficerIds') || [];
    const hasRoutingLabel = first(get(formModel, 'reportDetails.hasRoutingLabel'));
    const offenseCaseStatusAttrId = get(formModel, 'reportDetails.offenseCaseStatusAttrId');
    const hasOffenseCaseStatusVal = get(formModel, 'reportDetails.hasOffenseCaseStatus');
    const hasArrest = get(formModel, 'reportDetails.hasArrest');
    const offenseCodeIds = get(formModel.reportDetails, 'offenseCodeIds') || [];
    const offenseClassificationAttrIds =
        get(formModel.reportDetails, 'offenseClassificationAttrIds') || [];
    const isSuspectedHateCrime = get(formModel, 'reportDetails.isSuspectedHateCrime');
    const isDomesticViolence = get(formModel, 'reportDetails.isDomesticViolence');

    const reportDefinitions = formModel.reportDefinitions || [{}];

    const filter = filterFormData(
        {
            hasRoutingLabel,
            isAssignedInCase: false,
            reportSequenceNumbers: !!recordsWithoutRenEnabled
                ? [{ entityType: EntityTypeEnum.REPORTING_EVENT_NUMBER.name }]
                : [],
            ...omit(formModel.personnel, 'involvedOfficerIds'),
            ...omit(formModel.reportDetails, [
                'subdivisionAttrIds',
                'hasRoutingLabel',
                'offenseCaseStatusAttrId',
                'hasLinkedArrests',
                'isDomesticViolence',
                'isSuspectedHateCrime',
                'offenseCodeIds',
                'offenseClassificationAttrIds',
                'hasOffenseCaseStatus',
            ]),
            involvedOfficers:
                involvedOfficerIds.length > 0 ? [{ officerIds: involvedOfficerIds }] : [],
            involvedLocations: [
                ...(includeLocationSubdivisions(formModel.reportDetails)
                    ? [getSubdivisionsFromLocation(formModel.reportDetails)]
                    : []),
            ],
            reportDefinitions: _(reportDefinitions)
                .map((reportDefinition) => {
                    const nibrsCodes = reportDefinition.nibrsCodes;
                    const nibrsCodeIds = reportDefinition.nibrsCodeIds;
                    const nibrsGroups = reportDefinition.nibrsGroups;
                    const ucrGroups = reportDefinition.ucrGroups;

                    return {
                        reportDefinitionId: reportDefinition.reportDefinitionId,
                        hasLinkedArrests: hasArrest,
                        offensesAndIncidents:
                            nibrsCodes || nibrsCodeIds || nibrsGroups
                                ? [
                                      {
                                          nibrsCodes,
                                          nibrsCodeIds,
                                          nibrsGroups,
                                          ucrGroups,
                                          isDomesticViolence,
                                          isSuspectedHateCrime,
                                          offenseCodeIds,
                                          offenseCaseStatusAttrId,
                                          offenseClassificationAttrIds,
                                          hasOffenseCaseStatus: convertStringToBoolean(
                                              hasOffenseCaseStatusVal
                                          ),
                                      },
                                  ]
                                : [
                                      {
                                          isDomesticViolence,
                                          isSuspectedHateCrime,
                                          offenseCodeIds,
                                          offenseCaseStatusAttrId,
                                          offenseClassificationAttrIds,
                                          hasOffenseCaseStatus: convertStringToBoolean(
                                              hasOffenseCaseStatusVal
                                          ),
                                      },
                                  ],
                    };
                })
                .compact()
                .value(),
            departmentIds: formModel.departmentIds,
        },
        unassignedReportsSearchFormFieldViewModels
    );

    // JUN-2539 - remove selected search statuses from excluded filter
    if (!!filter.excludedCaseStatusAttrIds && !!filter.caseStatusAttrIds) {
        filter.excludedCaseStatusAttrIds = difference(
            filter.excludedCaseStatusAttrIds,
            filter.caseStatusAttrIds
        );
    }

    return filter;
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
export function convertUnassignedReportsSearchFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    const formatFieldByName = boundSelectors.formatFieldByName;
    const combinedSubdivisionsLabel = boundSelectors.combinedSubdivisionsLabel;
    const willNotInvestigateLabel = boundSelectors.formatFieldByName(
        DISPLAY_ONLY_WILL_NOT_INVESTIGATE_LABEL
    );
    const fieldViewModels = {
        ...unassignedReportsSearchFormFieldViewModels,
        reportDetails: {
            ...unassignedReportsSearchFormFieldViewModels.reportDetails,
            fields: {
                ...unassignedReportsSearchFormFieldViewModels.reportDetails.fields,
                withinLastPeriod: {
                    ...unassignedReportsSearchFormFieldViewModels.reportDetails.fields
                        .withinLastPeriod,
                    label: formatFieldByName(DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL),
                },
                subdivisionAttrIds: {
                    ...unassignedReportsSearchFormFieldViewModels.reportDetails.fields
                        .subdivisionAttrIds,
                    label: combinedSubdivisionsLabel,
                },
                createdDateRangeQuery: {
                    ...unassignedReportsSearchFormFieldViewModels.reportDetails.fields
                        .createdDateRangeQuery,
                    fields: {
                        ...unassignedReportsSearchFormFieldViewModels.reportDetails.fields
                            .createdDateRangeQuery.fields,
                        withinLastPeriod: {
                            ...unassignedReportsSearchFormFieldViewModels.reportDetails.fields
                                .createdDateRangeQuery.fields.withinLastPeriod,
                            label: formatFieldByName(
                                DISPLAY_ONLY_CASE_SEARCH_REPORT_CREATED_DATE_TIME_RANGE_LABEL
                            ),
                        },
                    },
                },
            },
        },
    };

    const hasCaseStatus = get(formModel, 'reportDetails.hasCaseStatus');
    const hasOffenseCaseStatus = convertStringToBoolean(
        get(formModel, 'reportDetails.hasOffenseCaseStatus')
    );

    const display =
        hasCaseStatus === true ? strings.willNotInvestigate(willNotInvestigateLabel) : strings.new;

    return [
        // "New" and "Will Not Investigate" are top level ideas in this search ui
        // (represented by tabs as opposed to a nested form field) as such
        // we lift them in serialized displays (for now just saved search) to reflect this
        {
            label: strings.tabLabel,
            display,
        },
        ...convertFormModelToFilterGroups(
            {
                ...formModel,
                reportDetails: {
                    ...omit(formModel.reportDetails, [
                        'hasCaseStatus',
                        'hasSecondaryApprovalStatus',
                        'excludedCaseStatusAttrIds',
                    ]),
                    ...(hasOffenseCaseStatus !== undefined
                        ? {
                              hasOffenseCaseStatus,
                          }
                        : {}),
                },
            },
            fieldViewModels,
            formatFieldValue,
            formatFieldByName
        ),
    ];
}

const unassignedReportsSearchForm = createFormModule({
    formName: formClientEnum.UNASSIGNED_REPORTS_SEARCH,
    fieldViewModels: unassignedReportsSearchFormFieldViewModels,
    convertToFormModel: convertUnassignedReportsSearchElasticQueryToFormModel,
    convertFromFormModel: convertUnassignedReportsSearchFormModelToElasticQuery,
});

/**
 * Module of the unassigned reports search/filter form.
 * @type {Object}
 */
export default unassignedReportsSearchForm;

import { compose, lifecycle } from 'recompose';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';
import styled, { withTheme } from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { isProcessingUploadingAttachmentsSelector } from '../../../attachments/core/state/ui/sharedAttachmentsSelectors';
import Subheader from '../../../core/components/Subheader';
import UnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import _Column from '../../../core/components/Column';
import Page from '../../../core/components/Page';
import _FixedFooter from '../../../core/components/FixedFooter';
import { loadItemQueue } from '../../core/state/data';

import {
    resetChainEventsForm,
    resetItemQueue,
    loadItemReportLevelStaffRemarks,
    itemReportLevelStaffRemarksToLoadSelector,
    submitCreateChainEventsForm,
    createChainEventsPanelIsSubmittingSelector,
    itemQueueCountSelector,
    subsequentChainEventTypeIdsSelector,
} from '../state/ui';

import { CREATE_CHAIN_EVENTS_PANEL_WIDTH } from '../configuration';
import CreateChainEventsForm from './CreateChainEventsForm';
import ItemQueueTable from './ItemQueueTable';

const strings = componentStrings.evidence.itemQueue.ItemQueue;

// make the columns of white backgrounds scrollable and take up the full screen
// height
const ItemQueueTableColumn = styled(_Column)`
    height: 100%;
    overflow-y: auto;
    flex: 1;
`;
const CreateChainEventsPanelColumn = styled(_Column)`
    border-right: 1px solid var(--arc-colors-border-default);
    height: 100%;
`;

// scrollable area above fixed footer
const CreateChainEventsPanel = styled.div`
    position: absolute;
    top: 0;
    bottom: 55px;
    padding: 20px;
    width: ${CREATE_CHAIN_EVENTS_PANEL_WIDTH - 2}px;
    overflow-y: auto;
    background-color: ${(props) => props.theme.colors.white};
`;

// match table header style in item queue table
const CreateChainEventsPanelTitle = styled.div`
    margin-bottom: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.regular};
    text-transform: uppercase;
`;

// subtract border
const FixedFooter = styled(_FixedFooter)`
    width: ${CREATE_CHAIN_EVENTS_PANEL_WIDTH - 2}px;
`;

function _ItemQueue({
    itemQueueCount = 0,
    createChainEventsPanelIsSubmitting,
    subsequentChainEventTypeIds,
    submitCreateChainEventsForm,
    resetItemQueue,
    className,
    isProcessingUploadingAttachments,
}) {
    return (
        <Page className={`evidence ${className}`}>
            <Subheader title={strings.title(itemQueueCount)} />
            <UnderSubheader style={{ height: '100%', display: 'flex', padding: 0 }}>
                <CreateChainEventsPanelColumn
                    width={CREATE_CHAIN_EVENTS_PANEL_WIDTH}
                    marginRight={0}
                >
                    <CreateChainEventsPanel>
                        <CreateChainEventsPanelTitle>
                            {strings.createChainEventsPanelTitle}
                        </CreateChainEventsPanelTitle>
                        <CreateChainEventsForm />
                    </CreateChainEventsPanel>
                    <FixedFooter
                        onSubmit={submitCreateChainEventsForm}
                        onCancel={resetItemQueue}
                        submitText={strings.submitCreateChainEventsPanel}
                        cancelText={strings.clear}
                        submitDisabled={subsequentChainEventTypeIds.length === 0}
                        disabled={
                            itemQueueCount === 0 ||
                            createChainEventsPanelIsSubmitting ||
                            isProcessingUploadingAttachments
                        }
                    />
                </CreateChainEventsPanelColumn>
                <ItemQueueTableColumn marginRight={0}>
                    <ItemQueueTable />
                </ItemQueueTableColumn>
            </UnderSubheader>
        </Page>
    );
}

const ItemQueue = styled(_ItemQueue)`
    &,
    * {
        box-sizing: border-box;
    }
`;

const mapStateToProps = createStructuredSelector({
    createChainEventsPanelIsSubmitting: createChainEventsPanelIsSubmittingSelector,
    itemQueueCount: itemQueueCountSelector,
    subsequentChainEventTypeIds: subsequentChainEventTypeIdsSelector,
    itemReportLevelStaffRemarksToLoad: itemReportLevelStaffRemarksToLoadSelector,
    isProcessingUploadingAttachments: isProcessingUploadingAttachmentsSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    resetChainEventsForm: () => dispatch(resetChainEventsForm()),
    resetItemQueue: () => dispatch(resetItemQueue()),
    loadItemQueue: () => dispatch(loadItemQueue()),
    loadItemReportLevelStaffRemarks: () => dispatch(loadItemReportLevelStaffRemarks()),
    submitCreateChainEventsForm: () => dispatch(submitCreateChainEventsForm(router)),
});

/**
 * Route component for the item queue.
 */
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
        componentDidMount() {
            this.props.resetChainEventsForm();
            this.props.loadItemQueue();
            this.props.loadItemReportLevelStaffRemarks();
        },
        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.itemReportLevelStaffRemarksToLoad.length) {
                this.props.loadItemReportLevelStaffRemarks();
            }
        },
    }),
    withTheme
)(ItemQueue);

import { RoleTypeEnum } from '@mark43/rms-api';

import { map } from 'lodash';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { NEXUS_STATE_PROP as ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-attribute-details/state/data';
import { NEXUS_STATE_PROP as ELASTIC_STORAGE_LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-storage-locations/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-codes/state/data';
import { NEXUS_STATE_PROP as ROLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/roles/state/data';
import { NEXUS_STATE_PROP as USER_SEARCH_RESULTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/user-search-results/state/data';
import { NEXUS_STATE_PROP as VEHICLE_MAKES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicle-makes/state/data';
import { NEXUS_STATE_PROP as VEHICLE_MODELS_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicle-models/state/data';
import { NEXUS_STATE_PROP as FIREARM_ATF_MANUFACTURERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/etrace-atf/state/data';
import {
    convertAttributeToAttributeView,
    convertAttributeToElasticAttributeDetail,
} from '~/client-common/core/domain/attributes/utils/attributesHelpers';

const storeRmsSavedSearchesView = ({
    rmsSavedSearchesView,
    action = { type: 'STORE_RMS_SAVED_SEARCHES_VIEW' },
}) => (dispatch, getState, { nexus }) => {
    const { withEntityItems } = nexus;
    const {
        attributes,
        offenseCodes,
        users,
        storageLocations,
        vehicleMakes,
        vehicleModels,
        atfManufacturers,
    } = rmsSavedSearchesView;

    dispatch(
        withEntityItems(
            {
                [ATTRIBUTES_NEXUS_STATE_PROP]: map(attributes, convertAttributeToAttributeView),
                [ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP]: map(attributes, (attribute) =>
                    convertAttributeToElasticAttributeDetail(attribute)
                ),
                [OFFENSE_CODES_NEXUS_STATE_PROP]: offenseCodes,
                [USER_SEARCH_RESULTS_NEXUS_STATE_PROP]: users,
                [ROLES_NEXUS_STATE_PROP]: map(users, (user) => ({
                    id: user.userRoleId,
                    roleType: RoleTypeEnum.USER.name,
                    userId: user.id,
                    departmentId: user.departmentId,
                })),
                [ELASTIC_STORAGE_LOCATIONS_NEXUS_STATE_PROP]: storageLocations,
                [VEHICLE_MAKES_NEXUS_STATE_PROP]: vehicleMakes,
                [VEHICLE_MODELS_NEXUS_STATE_PROP]: vehicleModels,
                [FIREARM_ATF_MANUFACTURERS_NEXUS_STATE_PROP]: atfManufacturers,
            },
            action
        )
    );
};

export default storeRmsSavedSearchesView;

import React from 'react';
import styled from 'styled-components';

import _Checkbox from '../../../core/forms/components/checkboxes/Checkbox';

const FieldRowContainer = styled.div`
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
`;

const Checkbox = styled(_Checkbox)`
    margin-bottom: 0;
`;

export const FieldRow = React.memo(function FieldRow({
    option,
    entityId,
    onFieldToggle,
    isSelected,
}) {
    return (
        <FieldRowContainer>
            <Checkbox
                value={isSelected}
                onChange={() => onFieldToggle({ entityId, option }, !isSelected)}
                label={option.display}
            />
        </FieldRowContainer>
    );
});

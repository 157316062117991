export const genericAdvancedSearchErrorReadOnlyMessage = 'A server error occurred.';
export const genericAdvancedSearchErrorActionTextMessage = 'Your results were not loaded.';
export const requestTimeoutAdvancedSearchErrorReadOnlyMessage =
    'The search request timed out. Your search may have been too broad.';
export const requestTimeoutAdvancedSearchErrorActionTextMessage =
    'Try narrowing your search and search again.';

/**
 * Error messages used by Saved Search components.
 */
export const savedSearchErrorMessages = {
    onGetSavedSearchesErrorMessage:
        'A server request error occurred.  We were unable to load your saved searches.',
    noSavedSearchesErrorMessage: 'No saved searches found.',
    onExecuteSavedSearchErrorMessage:
        'A server request error occurred.  Your saved search was not executed.',
    onDeleteSavedSearchErrorMessage:
        'A server request error occurred.  Your search was not deleted.',
    onSaveSavedSearchErrorMessage: 'A server request error occurred.  Your search was not saved.',
    onRenameSavedSearchErrorMessage: 'A server request error occurred.  Your edit was not saved.',
    onUpdateSavedSearchErrorMessage: 'A server request error occurred. Your update was not saved.',
} as const;

// Default search request `from` and `size` parameters.
export const queryParamDefaults = {
    FROM: 0,
    SIZE: 25,
} as const;

// Default search request `from` and `size` for modules of on the
// advanced search 'ALL' tab
export const allQueryParamDefaults = {
    FROM: 0,
    SIZE: 5,
} as const;

/**
 * Option values for how many search results to show per page.
 */
export const searchSizes = [25, 50, 75, 100] as const;
export type searchSizes = typeof searchSizes[number];

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Street Centerline Resource',
        methods: {
            getStreetCenterlineView() {
                return req({
                    method: 'GET',
                    url: 'master/locations/street_centerline',
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import { map, get } from 'lodash';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { otherUserId } from '~/client-common/helpers/userHelpers';

export const convertChargeWarrantFromFormModel = ({ warrant }) =>
    isUndefinedOrNull(get(warrant, 'obtainingOfficerOther'))
        ? warrant
        : { ...warrant, obtainingOfficerUserId: undefined };

export const convertChargeWarrantToFormModel = ({ warrant }) =>
    isUndefinedOrNull(get(warrant, 'obtainingOfficerOther'))
        ? warrant
        : { ...warrant, obtainingOfficerUserId: otherUserId };

export const convertChargeWarrantViewsToFormModelList = (chargeWarrantViews) =>
    map(chargeWarrantViews, (chargeWarrantView) => {
        const warrant = convertChargeWarrantToFormModel(chargeWarrantView);
        return {
            ...chargeWarrantView,
            warrant,
        };
    });

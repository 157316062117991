import { ConfiguredFormQueryConfigurationView } from '@mark43/rms-api';
import { useDragonConfiguredPropertyAliasContext } from '../context/dragon-configured-property-alias';
import { assertNotNullOrUndefined } from '../../core/utils/assert-not-null-or-undefined';

function resolveQueryConfigurations(
    queryConfigurations: ConfiguredFormQueryConfigurationView[],
    aliasContext: Record<string, string | number | undefined>
): Record<string, string | number> {
    return queryConfigurations.reduce<Record<string, string | number>>(
        (acc, queryConfiguration) => {
            if (queryConfiguration.queryValueStrategy === 'STATIC') {
                assertNotNullOrUndefined(
                    queryConfiguration.staticValue,
                    'Unexpectedly did not find static value for STATIC query configuration'
                );
                acc[queryConfiguration.configuredEntityPropertyName] =
                    queryConfiguration.staticValue;
            } else {
                assertNotNullOrUndefined(
                    queryConfiguration.lookupConfiguredFormConfiguredEntityPropertyAlias,
                    'Unexpectedly did not find lookup alias value for LOOKUP query configuration'
                );
                const aliasValue =
                    aliasContext[
                        queryConfiguration.lookupConfiguredFormConfiguredEntityPropertyAlias
                    ];
                assertNotNullOrUndefined(
                    aliasValue,
                    `Unexpectedly could not resolve value for alias "${queryConfiguration.lookupConfiguredFormConfiguredEntityPropertyAlias}"`
                );
                acc[queryConfiguration.configuredEntityPropertyName] = aliasValue;
            }
            return acc;
        },
        {}
    );
}

/**
 * Query configuration values need to be resolved in the context of the tree they're in.
 * Static configurations are easy to resolve, but lookups must ensure that they find their closest defined
 * alias and use the value it resolves to. This hook will return a map of resolved values, ensuring that values
 * are at least defined. Validating that those values have the right types is left to the consumer within the passed-in `resolvers`.
 */
export function useResolvedQueryConfigurationValues<
    T extends Record<
        string,
        (queryConfigurationValues: Record<string, string | number | undefined>) => unknown
    >
>(
    configuredFormQueryConfigurationViews: ConfiguredFormQueryConfigurationView[],
    resolvers: T
): { [P in keyof T]: ReturnType<T[P]> } {
    const aliasContext = useDragonConfiguredPropertyAliasContext();
    const resolvedQueryConfigurationValues = resolveQueryConfigurations(
        configuredFormQueryConfigurationViews,
        aliasContext
    );

    return (Object.keys(resolvers) as (keyof T)[]).reduce<{ [index: string]: unknown }>(
        (acc, key) => {
            acc[key as string] = resolvers[key](resolvedQueryConfigurationValues) as ReturnType<
                T[typeof key]
            >;
            return acc;
        },
        {}
    ) as { [P in keyof T]: ReturnType<T[P]> };
}

import { createSelector } from 'reselect';
import _ from 'lodash';

import {
    cadUnitStatusesSelector,
    cadUnitStatusByIdSelector,
} from '~/client-common/core/domain/cad-unit-statuses/state/data';
import { cadUnitStatusTransitionsSelector } from '~/client-common/core/domain/cad-unit-status-transitions/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';

import {
    LOAD_CAD_UNIT_STATUSES_START,
    LOAD_CAD_UNIT_STATUSES_SUCCESS,
    LOAD_CAD_UNIT_STATUSES_FAILURE,
    SAVE_CAD_UNIT_STATUS_TRANSITIONS_START,
    SAVE_CAD_UNIT_STATUS_TRANSITIONS_FAILURE,
    SAVE_CAD_UNIT_STATUS_TRANSITIONS_SUCCESS,
} from '../data';

const SELECT_UNIT_STATUS = 'cad-unit-status-transitions/SELECT_UNIT_STATUS';

export function selectUnitStatus(unitStatusId) {
    return {
        type: SELECT_UNIT_STATUS,
        payload: unitStatusId,
    };
}

const initialUiState = {
    listLoading: false,
    listError: null,
    selectedUnitStatusId: null,
    saving: false,
    savingError: null,
};

export default function cadUnitStatusTransitionsUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_CAD_UNIT_STATUSES_START:
            return {
                ...state,
                listLoading: true,
            };
        case LOAD_CAD_UNIT_STATUSES_SUCCESS:
            return {
                ...state,
                listLoading: false,
            };
        case LOAD_CAD_UNIT_STATUSES_FAILURE:
            return {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
        case SELECT_UNIT_STATUS:
            return {
                ...state,
                selectedUnitStatusId: action.payload,
                savingError: null,
            };
        case SAVE_CAD_UNIT_STATUS_TRANSITIONS_START:
            return {
                ...state,
                saving: true,
            };
        case SAVE_CAD_UNIT_STATUS_TRANSITIONS_FAILURE:
            return {
                ...state,
                saving: false,
                savingError: action.payload,
            };
        case SAVE_CAD_UNIT_STATUS_TRANSITIONS_SUCCESS:
            return {
                ...state,
                saving: false,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.cadUnitStatusTransitions;
export const selectedUnitStatusSelector = createSelector(
    uiSelector,
    cadUnitStatusByIdSelector,
    ({ selectedUnitStatusId }, cadUnitStatusById) =>
        selectedUnitStatusId && cadUnitStatusById(selectedUnitStatusId)
);

export const unitStatusesListItemsSelector = createSelector(
    uiSelector,
    cadUnitStatusesSelector,
    (ui, cadUnitStatuses) => {
        return _(cadUnitStatuses)
            .map((unitStatus) => {
                const path = `/support/cad-unit-status-transitions/${unitStatus.id}`;
                return {
                    path,
                    title: unitStatus.displayValue,
                    key: unitStatus.id,
                    selected: unitStatus.id === ui.selectedUnitStatusId,
                };
            })
            .sortBy('title')
            .value();
    }
);

export const unitStatusTransitionsFromIdSelector = createSelector(
    cadUnitStatusTransitionsSelector,
    cadUnitStatusesSelector,
    cadUnitStatusByIdSelector,
    (transitions, unitStatuses, unitStatusById) => (fromId) => {
        const existingTransitions = _.pickBy(
            transitions,
            ({ fromUnitStateId }) => fromUnitStateId === fromId
        );
        const existingTransitionsByToId = _.keyBy(
            existingTransitions,
            ({ toUnitStateId }) => toUnitStateId
        );
        const statusesWithoutTransitions = _.filter(
            unitStatuses,
            ({ id }) => !existingTransitionsByToId[id]
        );
        const emptyTransitionPlaceholders = _.map(statusesWithoutTransitions, ({ id }) => ({
            fromUnitStateId: fromId,
            toUnitStateId: id,
            transitionPermissionSet: [],
        }));
        const allTransitions = [..._.map(existingTransitions), ...emptyTransitionPlaceholders];
        const [eventRelatedTransitions, nonEventRelatedTransitions] = _.partition(
            allTransitions,
            (transition) => unitStatusById(transition.toUnitStateId).isEventRelated
        );

        // sort by unit state group, then by value
        const sortIteratee = (transition) =>
            `${unitStatusById(transition.toUnitStateId).unitStateGroup}^${
                unitStatusById(transition.toUnitStateId).displayValue
            }`;
        return {
            eventRelatedTransitions: _.sortBy(eventRelatedTransitions, sortIteratee),
            nonEventRelatedTransitions: _.sortBy(nonEventRelatedTransitions, sortIteratee),
        };
    }
);

export const hasImportExportUnitPermissions = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.CAD.UNIT_IMPORT)
);

import { useEffect, useState, useCallback } from 'react';
import Circle from '@arcgis/core/geometry/Circle';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { FeatureLayerT, MapViewT, PolygonT, WebMapT } from '../../types';
import { spatialReference, useStaticLayerControl } from './staticFeatureLayerHelpers';

type PolygonFeatureLayerProps = {
    polygons?: PolygonT[];
    webmap?: WebMapT;
    mapView?: MapViewT;
    zoomToPolygons?: boolean;
};

const createPolygonGraphic = (polygon: PolygonT) => {
    const { type, rings, center, radius, radiusUnit } = polygon;
    let geometry;

    if (type === 'CIRCLE') {
        geometry = new Circle({
            // @ts-expect-error - number[] is supported, according to the docs.
            center,
            radius: radius || 10000,
            radiusUnit: (radiusUnit?.toLowerCase() as 'meters' | 'miles' | undefined) || 'meters',
        });
    } else if (type === 'POLYGON' && rings) {
        geometry = {
            // @ts-expect-error esri types seem to be wrong https://developers.arcgis.com/javascript/latest/api-reference/esri-Graphic.html#geometry
            type: 'polygon',
            rings,
        };
    }

    return new Graphic({
        attributes: {
            type: polygon.type,
        },
        geometry,
    });
};

export const useStaticPolygonFeatureLayer = ({
    polygons = [],
    webmap,
    mapView,
    zoomToPolygons,
}: PolygonFeatureLayerProps) => {
    const [polygonLayer, setPolygonLayer] = useState<FeatureLayerT>();

    const createPolygonGraphics = useCallback((polygons: PolygonT[]) => {
        return polygons.map((polygon: PolygonT) => {
            return createPolygonGraphic(polygon);
        });
    }, []);

    // handles initializing empty feature layer
    useEffect(() => {
        if (webmap) {
            const polygonFeatureLayer = getPolygonLayer();
            setPolygonLayer(polygonFeatureLayer);
            webmap.layers.add(polygonFeatureLayer);
        }
    }, [webmap]);

    useStaticLayerControl({
        layer: polygonLayer,
        currItems: polygons,
        createGraphics: createPolygonGraphics,
        replaceExisting: true,
        zoomToGraphics: zoomToPolygons,
        mapView,
    });
};

const polygonIconRenderer = {
    type: 'unique-value',
    field: 'type',
    defaultSymbol: {
        // used when item field dont match anything defined in uniqueValueInfos
        type: 'simple-fill',
        color: [0, 71, 171, 0.3],
        outline: {
            color: [255, 255, 255],
            width: 1,
        },
    },
    uniqueValueInfos: [
        {
            value: 'CIRCLE',
            symbol: {
                type: 'simple-fill',
                color: [255, 0, 0, 0.3],
                outline: {
                    color: [255, 255, 255],
                    width: 1,
                },
            },
        },
    ],
};

const getPolygonLayer = () => {
    const polygonFeatureLayer = new FeatureLayer({
        title: 'Polygons',
        source: [], // initialize with an empty collection
        renderer: polygonIconRenderer as __esri.RendererProperties,
        objectIdField: 'objectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
            {
                name: 'type',
                alias: 'type',
                type: 'string',
            },
        ],
        outFields: ['*'],
        geometryType: 'polygon',
        spatialReference,
    });

    return polygonFeatureLayer;
};

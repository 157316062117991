import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';

import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { FIELDSET } = fieldTypeClientEnum;

export default buildFlatFormFieldViewModels([
    {
        key: 'reportId',
    },
    {
        key: 'courtOrder',
        type: FIELDSET,
        fields: buildFlatFormFieldViewModels([
            {
                key: 'statuteCodeAttrId',
                fieldName: fields.COURT_ORDER_STATUTE_CODE_ATTR_ID,
            },
            {
                key: 'courtCaseNumber',
                fieldName: fields.COURT_ORDER_COURT_CASE_NUMBER,
            },
            {
                key: 'courtCaseStatute',
                fieldName: fields.COURT_ORDER_COURT_CASE_STATUTE,
            },
            {
                key: 'courtCaseDateUtc',
                fieldName: fields.COURT_ORDER_COURT_CASE_DATE_UTC,
            },
            {
                key: 'dateToPurgeUtc',
                fieldName: fields.COURT_ORDER_DATE_TO_PURGE_UTC,
            },
            {
                key: 'involvedPersonFullName',
                fieldName: fields.COURT_ORDER_INVOLVED_PERSON_FULL_NAME,
            },
            {
                key: 'isJuvenile',
                fieldName: fields.COURT_ORDER_IS_JUVENILE,
            },
            {
                key: 'remarks',
                fieldName: fields.COURT_ORDER_REMARKS,
            },
            {
                key: 'courtCodeAttrId',
                fieldName: fields.COURT_ORDER_COURT_CODE_ATTR_ID,
            },
            {
                key: 'stateIdNumber',
                fieldName: fields.COURT_ORDER_STATE_ID_NUMBER,
            },
        ]),
    },
    {
        key: 'isSealNarrative',
        fieldName: fields.REPORT_IS_NARRATIVE_SEALED,
    },
    {
        key: 'arrestIdNumber',
        fieldName: fields.DISPLAY_ONLY_ARREST_ID_NUMBER,
    },
    {
        key: 'userToContactId',
        fieldName: fields.REPORT_SEALED_NARRATIVE_CONTACT_USER_ID,
    },
    {
        key: 'isSealReport',
    },
    {
        key: 'courtOrderFileIds',
        fieldName: fields.ATTACHMENT_ATTACHMENT_ID,
    },
]);

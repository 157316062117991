import errorCodeEnum from '../core/enums/client/errorCodeEnum';

export function formatErrorCode(errorCode: number, useSpecificMessages: boolean) {
    if (!useSpecificMessages) {
        return {
            title: 'Something Went Wrong',
            body:
                'This page may not exist, there was a problem, or you may not have permission to view its contents.',
        };
    }

    switch (errorCode) {
        case errorCodeEnum.UNAUTHORIZED:
            return {
                title: 'Error 401: Unauthorized',
                body: 'You must log in before accessing this page.',
            };
        case errorCodeEnum.FORBIDDEN:
            return {
                title: 'Error 403: Access Denied',
                body: 'You do not have permission to access this page.',
            };
        case errorCodeEnum.INTERNAL_SERVER_ERROR:
            return {
                title: 'Error 500: Something Went Wrong',
                body: 'This page could not be loaded. Try refreshing the page.',
            };
        case errorCodeEnum.NOT_FOUND:
        default:
            return {
                title: 'Error 404: Page Not Found',
                body:
                    'This page could not be found. Double check your web address before trying again.',
            };
    }
}

import { mergeWith } from 'lodash';
import { createMFTDoEffectValue } from 'dragon-react';
import { Registry } from 'markformythree';
import { ConcreteConfiguredRuleEffect } from 'dragon-react/dist/rules/process-rule-effect-descriptors';
import { PatchedFormDoValue } from 'dragon-react/dist/rules/create-mft-do-effect-value';

export function reconcileDoValues(
    formGetter: (formName: string) => undefined | ReturnType<Registry['get']>,
    ruleExecutorResults: (
        | {
              doEffectValue: ReturnType<typeof createMFTDoEffectValue>;
              concreteRuleEffectDescriptors: ConcreteConfiguredRuleEffect[];
          }
        | undefined
    )[],
    {
        touchAllFields,
        clearErrorsForHiddenFields,
    }: { touchAllFields: boolean; clearErrorsForHiddenFields: boolean } = {
        clearErrorsForHiddenFields: false,
        touchAllFields: false,
    }
) {
    return ruleExecutorResults.reduce<{
        success: boolean;
        doValue: ReturnType<typeof createMFTDoEffectValue>;
    }>(
        (acc, ruleExecutorResult) => {
            if (!ruleExecutorResult) {
                return acc;
            }

            mergeWith(
                acc.doValue,
                ruleExecutorResult.doEffectValue,
                (
                    a: PatchedFormDoValue[string] | undefined,
                    b: PatchedFormDoValue[string] | undefined
                ): PatchedFormDoValue[string] => {
                    const merged = { ...a, ...b };
                    // if any effect marks a field as hidden, retain the existing hidden status
                    if (a?.hidden) {
                        merged.hidden = true;
                    }
                    // if any effect marks a field as failed, retain the existing failure status
                    if (a?.valid === false) {
                        merged.valid = false;
                        merged.errors = a.errors;
                    }
                    return merged;
                }
            );

            for (const {
                targetPathDescriptor,
            } of ruleExecutorResult.concreteRuleEffectDescriptors) {
                const { materializedPath, formName } = targetPathDescriptor;
                // we have to ensure that we look up the do value using the mft array access syntax (a[0]) versus
                // the dragon-internal syntax (a.[0]).
                // Long term `dragon-react` should expose a path-format conversion function
                const materializedPathWithMFTArrayAccess = materializedPath.replace(
                    /\.(\[.*?\])/g,
                    '$1'
                );
                // A do value should exist for every effect descriptor, as it was created during main rule evaluation
                const doValueForPath = acc.doValue[materializedPathWithMFTArrayAccess];
                if (clearErrorsForHiddenFields) {
                    // check if field or ancestors are hidden and if so, mark field as valid
                    // regardless of rule effect result
                    const form = formGetter(formName);
                    // splitting and subsequently joining again might not be the most performant, but we can optimize this later
                    const splitPathSegments = materializedPathWithMFTArrayAccess.split('.');
                    let fieldOrAncestorIsHidden = false;
                    while (splitPathSegments.length) {
                        let pathToCheck = splitPathSegments.join('.');
                        // make sure to remove array indexes
                        if (pathToCheck.endsWith(']')) {
                            pathToCheck = pathToCheck.substring(0, pathToCheck.lastIndexOf('['));
                        }
                        const ui = form?.getUi(pathToCheck);
                        if (ui?.hidden) {
                            fieldOrAncestorIsHidden = true;
                            break;
                        }

                        splitPathSegments.pop();
                    }

                    if (fieldOrAncestorIsHidden) {
                        doValueForPath.valid = true;
                        doValueForPath.errors = undefined;
                    }
                }

                if (touchAllFields) {
                    doValueForPath.touched = true;
                }
            }

            // determine whether the validation was a failure or success based on whether any of the
            // targeted fields are marked as invalid
            if (acc.success && ruleExecutorResult.doEffectValue) {
                // TODO move this into the above loop?
                for (const fieldDoValueKey of Object.keys(ruleExecutorResult.doEffectValue)) {
                    if (acc.doValue[fieldDoValueKey].valid === false) {
                        acc.success = false;
                        break;
                    }
                }
            }
            return acc;
        },
        { success: true, doValue: {} }
    );
}

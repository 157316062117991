import styled from 'styled-components';
import _Button from '../../../../../../legacy-redux/components/core/Button';

const Button = styled(_Button)`
    && {
        span {
            padding: 0;
        }
    }
`;

export const AddButton = styled(Button)`
    display: flex;
    margin: 0;
    padding: 0 15px;
    min-height: 28px;

    span {
        align-items: center;
        display: flex;
        margin: 0;
        padding: 0;
    }
`;

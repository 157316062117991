import { chain } from 'lodash';
import { createSelector } from 'reselect';
import { RotationServiceTypeBundle } from '@mark43/cad-api';
import createNormalizedModule, { ModuleShape } from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'cadServiceTypes';

const cadServiceTypesModule = createNormalizedModule<RotationServiceTypeBundle>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeCadServiceTypes = cadServiceTypesModule.actionCreators.storeEntities;

/**
 * Format the given cad unit as a dropdown/checkbox option.
 */
function mapCadServiceTypeToOption({ id, name }: RotationServiceTypeBundle) {
    return {
        value: id,
        display: name,
    };
}

/**
 * Format the given cad units as dropdown/checkbox options, sorted by
 *   display string.
 */
function mapCadServiceTypesToOptions(cadServiceTypes: ModuleShape<RotationServiceTypeBundle>) {
    return chain(cadServiceTypes).map(mapCadServiceTypeToOption).sortBy('display').value();
}

// SELECTORS
export const cadServiceTypesSelector = cadServiceTypesModule.selectors.entitiesSelector;
export const cadServiceTypesByIdSelector = cadServiceTypesModule.selectors.entityByIdSelector;

export const cadServiceTypeOptionsSelector = createSelector(
    cadServiceTypesSelector,
    mapCadServiceTypesToOptions
);

// REDUCER
export default cadServiceTypesModule.reducerConfig;

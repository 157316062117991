import { isNumber, noop, isString } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, defaultProps, getContext, withHandlers } from 'recompose';
import classNames from 'classnames';
import { simpleControl } from 'markformythree';
import { Checkbox as ArcCheckbox } from 'arc';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import { arbiterMFTInput } from '../../../arbiter';
import Icon, { iconTypes } from '../../../../../legacy-redux/components/core/Icon';
import HelpText, { HelpTextProps } from '../../../../../legacy-redux/components/core/HelpText';
import FieldLink from '../../../components/links/FieldLink';
import { inputFocus } from '../../../styles/mixins';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../../core/testIds';

const { connectRRFInput } = reactReduxFormHelpers;

export const INDETERMINATE = 'INDETERMINATE';

/**
 * true for checked
 * false for unchecked
 * 'INDETERMINATE' for a horizontal line to indicate that children checkboxes are partial checked.
 */
type CheckboxValue = boolean | string | undefined;

type CheckboxProps = {
    label?: React.ReactNode | string;
    /** Optional note to display after the label, e.g. '(expired)'. */
    noteDisplay?: string;
    /** Do not use in conjunction with `width`, because the help text icon will be pushed down to below the checkbox. */
    helpText?: string;
    helpTextCollisionBoundary?: HelpTextProps['collisionBoundary'];
    helpTextPlacement?: HelpTextProps['side'];
    className?: string;
    width?: string | number;
    disabled?: boolean | undefined;
    value: CheckboxValue;
    fieldName?: string | undefined;
    // Only supports `right` and `top` right now
    labelPosition?: 'right' | 'top';
    testId?: string;
    attrIsOther?: boolean;
    onChange?: (value: CheckboxValue) => void;
    onInputChange?: (value: CheckboxValue) => void;
    onFocus?: (value: CheckboxValue) => void;
    onInputFocus?: (value: CheckboxValue) => void;
    onBlur?: (value: CheckboxValue) => void;
    onInputBlur?: (value: CheckboxValue) => void;
    customStyles?: { [key: string]: string | number };
};

export const CheckboxLabel = styled.label<{ disabled: boolean | undefined }>`
    float: left;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};

    i {
        margin-right: 10px;
    }
`;

export const CheckboxDescription = styled.span`
    vertical-align: middle;

    .no-label-margin & {
        margin-bottom: 0;
    }
`;

const CheckboxLabelWithLabelOnTop = styled(CheckboxLabel)`
    float: none;
    display: flex;
    flex-direction: column-reverse;

    ${/* sc-selector */ CheckboxDescription} {
        margin-bottom: 2px;
    }
`;

// the CSS selector is VERY specific here and can't be removed because it's used for knockout
export const CheckboxBox = styled(Icon)<CheckboxProps & { checked: boolean }>`
    &&&& {
        ${(props) =>
            props.disabled
                ? `
                cursor: default;
                background-color: ${props.theme.colors.extraLightGrey};
                border: 1px solid ${props.theme.colors.lightGrey} !important;
            `
                : `
                cursor: pointer;
                background-color: ${props.theme.colors.white};
            `};
        border: 1px solid
            ${(props) =>
                props.checked ? props.theme.colors.cobaltBlue : props.theme.colors.lightGrey};

        &:hover {
            border: 1px solid ${(props) => props.theme.colors.cobaltBlue};
        }

        &.focused {
            ${inputFocus};
        }

        &::before {
            /* this generates a consistent box height in firefox/chrome */
            line-height: 100%;
            color: ${(props) =>
                props.disabled
                    ? props.theme.colors.mediumLightGrey
                    : props.theme.colors.cobaltBlue};
            ${(props) =>
                props.value === INDETERMINATE
                    ? `
                width: 14px;
                overflow: hidden;
                margin-left: 3px;
                `
                    : ''};
        }
        overflow: hidden;
    }
`;

const connectCheckbox: (
    Component: React.ComponentType<BaseCheckboxProps>
) => React.ComponentType<CheckboxProps> = compose(
    getContext({
        onInputChange: PropTypes.func,
        onInputFocus: PropTypes.func,
        onInputBlur: PropTypes.func,
    }),
    defaultProps({
        onChange: noop,
        onFocus: noop,
        onBlur: noop,
        onInputChange: noop,
        onInputFocus: noop,
        onInputBlur: noop,
        disabled: false,
    }),
    withHandlers({
        onChange({
            value,
            onChange,
            onInputChange,
            onFocus,
            onInputFocus,
            onBlur,
            onInputBlur,
            disabled,
        }: Required<CheckboxProps>) {
            return () => {
                if (disabled) {
                    return;
                }
                // call the focus and blur handlers immediately since the custom
                // checkbox does not have any real focus state
                onFocus(value);
                onInputFocus(value);

                // "toggle" the value:
                // if unchecked, check
                // if checked, uncheck
                // if indeterminate, uncheck
                const newValue = !value;
                onChange(newValue); // call custom handler
                onInputChange(newValue); // call context handler

                onBlur(newValue);
                onInputBlur(newValue);
            };
        },
    })
);

interface State {
    isFocused: boolean;
}

type BaseCheckboxProps = CheckboxProps & {
    onChange: () => void;
};

class CheckboxComponent extends React.Component<BaseCheckboxProps, State> {
    constructor(props: BaseCheckboxProps) {
        super(props);
        this.state = {
            isFocused: false,
        };
    }

    checkbox: HTMLInputElement | null = null;

    handleClick(event: React.MouseEvent) {
        // The event behavior should be the same for disabled/non-disabled
        // checkboxes
        event.stopPropagation();
        if (!this.props.disabled) {
            this.checkbox?.focus();
        }
        this.props.onChange?.();
    }

    handleKeyPress(event: React.KeyboardEvent) {
        if (this.props.disabled) {
            return;
        }
        // spacebar
        if (event.keyCode === keyCodeEnum.SPACEBAR) {
            event.preventDefault();
            this.props.onChange?.();
        }
    }

    render() {
        const {
            label,
            noteDisplay,
            helpText,
            helpTextCollisionBoundary,
            helpTextPlacement,
            className,
            width,
            disabled,
            value,
            fieldName,
            // Only supports `right` and `top` right now
            labelPosition = 'right',
            testId,
            attrIsOther,
            customStyles,
        } = this.props;

        const indeterminate = value === INDETERMINATE;

        let iconType: '' | typeof iconTypes.COLLAPSE | typeof iconTypes.CHECK;
        if (indeterminate) {
            iconType = iconTypes.COLLAPSE;
        } else if (value) {
            iconType = iconTypes.CHECK;
        } else {
            iconType = '';
        }

        const checkboxWidth = 26;
        const CheckboxLabelComponent =
            labelPosition === 'top' ? CheckboxLabelWithLabelOnTop : CheckboxLabel;
        return (
            <div
                data-test-id={testId}
                data-test-field-name={fieldName}
                className={classNames('mark43-form-field react-checkbox-field', className)}
                style={{ ...customStyles, width }}
            >
                <FeatureFlagged
                    flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
                    fallback={
                        <>
                            <input
                                type="checkbox"
                                checked={!!value}
                                value={value?.toString()}
                                onKeyDown={(event) => this.handleKeyPress(event)}
                                onFocus={() => {
                                    this.setState({ isFocused: true });
                                }}
                                onBlur={() => {
                                    this.setState({ isFocused: false });
                                }}
                                disabled={disabled}
                                ref={(checkbox) => {
                                    this.checkbox = checkbox;
                                }}
                                readOnly={true}
                            />
                            <CheckboxLabelComponent
                                disabled={disabled}
                                className="mark43-form-label"
                                style={isNumber(width) ? { width: width - checkboxWidth } : {}}
                                onClick={(event) => this.handleClick(event)}
                                data-test-id={testIds.CHECKBOX_INPUT}
                                {...(attrIsOther ? { 'data-test-attr-is-other': true } : {})}
                            >
                                <CheckboxBox
                                    value={value}
                                    // @ts-expect-error - best solution since this type prop is required and it doesn't accept an empty string, ts-ignoring for now as this won't be an issue when this component is replaced soon
                                    type={iconType}
                                    disabled={disabled}
                                    checked={!!value}
                                    fontSize="32px"
                                    className={classNames({ focused: this.state.isFocused })}
                                />
                                <CheckboxDescription
                                    data-test-display={
                                        label && isString(label) ? label.toLowerCase?.() : label
                                    }
                                >
                                    {label}
                                    {noteDisplay && (
                                        <span className="react-checkbox-note"> {noteDisplay}</span>
                                    )}
                                </CheckboxDescription>
                            </CheckboxLabelComponent>
                        </>
                    }
                >
                    <ArcCheckbox
                        disabled={disabled}
                        isChecked={!!value}
                        // @ts-expect-error: TODO: fix the error `caption does not exist on type`
                        caption={noteDisplay}
                        onClick={(e) => e.stopPropagation()}
                        onChange={this.props.onChange}
                        labelPosition={labelPosition}
                        indeterminate={indeterminate}
                    >
                        {label}
                    </ArcCheckbox>
                </FeatureFlagged>
                {helpText && (
                    <HelpText
                        content={helpText}
                        side={helpTextPlacement}
                        collisionBoundary={helpTextCollisionBoundary}
                    />
                )}
                {fieldName && (
                    <FieldLink
                        fieldName={fieldName}
                        className="mark43-form-label"
                        style={isNumber(width) ? { width: width - checkboxWidth } : {}}
                    />
                )}
            </div>
        );
    }
}

const Checkbox = connectCheckbox(CheckboxComponent);

export default Checkbox;

// @ts-expect-error client-common to client RND-7529
export const RRFCheckbox = connectRRFInput(Checkbox);
export const MFTCheckbox = simpleControl(Checkbox);
export const ArbiterMFTCheckbox = arbiterMFTInput(Checkbox);

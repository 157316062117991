import { createSelector } from 'reselect';

const FEDERATED_SEARCH_INCREMENT_NOTIFICATIONS_COUNT =
    'FEDERATED_SEARCH_INCREMENT_NOTIFICATIONS_COUNT';
const FEDERATED_SEARCH_RESET_NOTIFICATIONS_COUNT = 'FEDERATED_SEARCH_RESET_NOTIFICATIONS_COUNT';

type FederatedSearchState = {
    notificationsCount: number;
};

type IncrementNotificationCountAction = {
    type: typeof FEDERATED_SEARCH_INCREMENT_NOTIFICATIONS_COUNT;
};

type ResetNotificationsCountAction = {
    type: typeof FEDERATED_SEARCH_RESET_NOTIFICATIONS_COUNT;
};

type Actions = IncrementNotificationCountAction | ResetNotificationsCountAction;

const initialState: FederatedSearchState = {
    notificationsCount: 0,
};

export const FederatedSearchUIReducer = (
    state: FederatedSearchState = initialState,
    action: Actions
): FederatedSearchState => {
    switch (action.type) {
        case FEDERATED_SEARCH_INCREMENT_NOTIFICATIONS_COUNT: {
            return {
                ...state,
                notificationsCount: state.notificationsCount + 1,
            };
        }
        case FEDERATED_SEARCH_RESET_NOTIFICATIONS_COUNT: {
            return {
                ...state,
                notificationsCount: 0,
            };
        }
        default:
            return state;
    }
};

// actions
export const incrementNotificationCount = () => {
    return {
        type: FEDERATED_SEARCH_INCREMENT_NOTIFICATIONS_COUNT,
    };
};

export const resetNotificationCount = () => {
    return {
        type: FEDERATED_SEARCH_RESET_NOTIFICATIONS_COUNT,
    };
};

// selectors
const federatedSearchUISelector = (state: { ui: { federatedSearch: FederatedSearchState } }) => {
    return state.ui.federatedSearch;
};

export const getFederatedSearchNotificationsCount = createSelector(
    federatedSearchUISelector,
    (federatedSearch) => federatedSearch.notificationsCount
);

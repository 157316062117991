import { ReportShortTitle, OperationTypeEnum, Report } from '@mark43/rms-api';
import { mapValues, filter, ObjectIterateeCustom } from 'lodash';
import { createSelector } from 'reselect';

import { formatLinkedReportDisplayTitle } from '../../../reports/utils/reportsHelpers';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';
import { ModuleShape } from '../../../../utils/createNormalizedModule';
import {
    buildViewModel,
    buildAllMiniUserFormatsMapper,
    ViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { reportShortTitlesSelector } from '../data';

type ReportShortTitleViewModelProps = {
    shortTitleWithRen: string;
    canRead: boolean;
};

type ReportShortTitleViewModel = ViewModel<ReportShortTitle, ReportShortTitleViewModelProps>;

export const reportShortTitleViewModelsSelector = createSelector(
    reportShortTitlesSelector,
    allMiniUserFormatsByIdSelector,
    (reportShortTitles, allMiniUserFormatsById) => {
        const viewModel = buildViewModel<ReportShortTitle, ReportShortTitleViewModelProps>({
            recursive: true,
            mappers: [
                buildAllMiniUserFormatsMapper('currentSubmissionOwnerId'),
                (reportShortTitle) => ({
                    shortTitleWithRen: formatLinkedReportDisplayTitle(reportShortTitle),
                }),
                ({ maxOpType }) => {
                    return {
                        // SEARCH is the lowest possible operation type,
                        // so if the max operation type is not null,
                        // and it's anything other than SEARCH, that means user
                        // has READ abilities
                        canRead: !!maxOpType && maxOpType !== OperationTypeEnum.SEARCH.name,
                    };
                },
            ],
            helpers: {
                allMiniUserFormatsById,
            },
        });
        return mapValues(reportShortTitles, viewModel);
    }
);

export const reportShortTitleViewModelByReportIdSelector = createSelector(
    reportShortTitleViewModelsSelector,
    (reportShortTitlesViewModels) => (reportId: number) => reportShortTitlesViewModels[reportId]
);

export const reportShortTitleViewModelsWhereSelector = createSelector(
    reportShortTitleViewModelsSelector,
    (reportShortTitlesViewModels) => (
        predicate: ObjectIterateeCustom<ModuleShape<ReportShortTitleViewModel>, boolean>
    ) => filter(reportShortTitlesViewModels, predicate)
);

export const reportShortTitleViewModelsByRenSelector = createSelector(
    reportShortTitleViewModelsSelector,
    (reportShortTitleViewModels) => (reportingEventNumber: string) =>
        filter(reportShortTitleViewModels, { reportingEventNumber })
);

/**
 * Given a current report, grab all `LinkedReportShortTitleViewModels` that live in state
 */
export const linkedReportShortTitleViewModelsForReportSelector = createSelector(
    reportShortTitleViewModelsSelector,
    (reportShortTitlesViewModels) => (report: Report) =>
        filter(reportShortTitlesViewModels, (reportShortTitlesViewModel) => {
            return (
                reportShortTitlesViewModel.reportingEventNumber === report.reportingEventNumber &&
                reportShortTitlesViewModel.departmentId === report.departmentId &&
                reportShortTitlesViewModel.reportId !== report.id
            );
        })
);

import { RefContextEnum } from '@mark43/rms-api';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import pluralize from 'pluralize';

import { compose, pure, lifecycle, withPropsOnChange } from 'recompose';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { agencyProfilesWhereSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_PERSON_LABEL,
    DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_PROPERTY_LABEL,
    DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_VEHICLE_LABEL,
    DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_LOCATION_LABEL,
    DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_ORGANIZATION_LABEL,
    DISPLAY_ONLY_ORGANIZATION_LABEL,
    EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';

import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import advancedSearchReports from '../state/ui';
import {
    currentUserDepartmentIdSelector,
    currentUserDepartmentAgencyIdSelector,
} from '../../../core/current-user/state/ui';
import { loadFormDataFromLocalStorageIntoFormModule } from '../../../core/local-storage';
import FormSection from '../../../core/forms/components/FormSection';
import AdvancedSearchForm from '../../core/components/AdvancedSearchForm';
import LocationsFieldset from '../../core/components/LocationsFieldset';
import OrganizationsFieldset from '../../core/components/OrganizationsFieldset';
import PropertiesFieldset from '../../core/components/PropertiesFieldset';
import VehiclesFieldset from '../../core/components/VehiclesFieldset';
import { SearchName } from '../../core/components/SearchName';

import PersonnelFieldset from './PersonnelFieldset';
import PersonsFieldset from './PersonsFieldset';
import ReportDetailsFieldset, { ReportDetailsTopFieldset } from './ReportDetailsFieldset';

const strings = componentStrings.search.AdvancedSearchReportsForm;

const fillIfUndefined = (field, value) => (dispatch, getState) => {
    const currentValue = advancedSearchReports.form.selectors.formModelByPathSelector(getState())(
        field
    );

    if (currentValue === undefined) {
        dispatch(advancedSearchReports.form.actionCreators.changePath(field, value));
    }
};

const ReportDetailsSection = pure(function ReportDetailsSection({
    fuzzyMatchingEnabled,
    hideAgencySelect,
}) {
    return (
        <div>
            <FormSection>
                <ReportDetailsTopFieldset
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    highlightOnFocus={false}
                />
            </FormSection>
            <hr />
            <FormSection title={strings.sectionTitles.reportDetails}>
                <ReportDetailsFieldset
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    hideAgencySelect={hideAgencySelect}
                />
            </FormSection>
        </div>
    );
});

const PersonnelSection = pure(function PersonnelSection({
    isPersonnelUnitStaticallyHidden,
    formatFieldByName,
}) {
    // negative margin-top instead of 0 padding-top for title clipping to
    // work with the same bounding rect
    return (
        <FormSection title={strings.sectionTitles.personnel} style={{ marginTop: -31 }}>
            <PersonnelFieldset
                isPersonnelUnitStaticallyHidden={isPersonnelUnitStaticallyHidden}
                formatFieldByName={formatFieldByName}
            />
        </FormSection>
    );
});

const InvolvedPersonsSection = pure(function InvolvedPersonsSection({ fuzzyMatchingEnabled }) {
    const addInvolvedPersonLabel = useFields(
        DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_PERSON_LABEL
    )[DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_PERSON_LABEL];
    return (
        <FormSection title={strings.sectionTitles.involvedPersons}>
            <PersonsFieldset
                path="involvedPersons"
                addText={addInvolvedPersonLabel}
                title={strings.fieldsetTitles.involvedPersons}
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            />
        </FormSection>
    );
});

const InvolvedPropertySection = pure(function InvolvedPropertySection({ fuzzyMatchingEnabled }) {
    const addInvolvedPropertyLabel = useFields(
        DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_PROPERTY_LABEL
    )[DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_PROPERTY_LABEL];
    return (
        <FormSection title={strings.sectionTitles.involvedProperty}>
            <PropertiesFieldset
                path="involvedProperty"
                addText={addInvolvedPropertyLabel}
                title={strings.fieldsetTitles.involvedProperty}
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            />
        </FormSection>
    );
});

const InvolvedVehiclesSection = pure(function InvolvedVehiclesSection({ fuzzyMatchingEnabled }) {
    const addInvolvedVehicleLabel = useFields(
        DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_VEHICLE_LABEL
    )[DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_VEHICLE_LABEL];
    return (
        <FormSection title={strings.sectionTitles.involvedVehicles}>
            <VehiclesFieldset
                path="involvedVehicles"
                addText={addInvolvedVehicleLabel}
                title={strings.fieldsetTitles.involvedVehicles}
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                includePropertyStatusAttrIds={true}
            />
        </FormSection>
    );
});

const InvolvedLocationsSection = pure(function InvolvedLocationsSection({ fuzzyMatchingEnabled }) {
    const addInvolvedLocationLabel = useFields(
        DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_LOCATION_LABEL
    )[DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_LOCATION_LABEL];
    return (
        <FormSection title={strings.sectionTitles.involvedLocations}>
            <LocationsFieldset
                path="involvedLocations"
                addText={addInvolvedLocationLabel}
                title={strings.fieldsetTitles.involvedLocations}
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                includeCrossStreets={true}
                includeType={true}
                includeCategoryAttrIds={true}
                includeGeoQuery={true}
            />
        </FormSection>
    );
});

const InvolvedOrganizationsSection = pure(function InvolvedOrganizationsSection({
    fuzzyMatchingEnabled,
}) {
    const {
        DISPLAY_ONLY_ORGANIZATION_LABEL: organizationFieldName,
        DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_ORGANIZATION_LABEL: addInvolvedOrganizationLabel,
    } = useFields([
        DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_INVOLVED_ORGANIZATION_LABEL,
        DISPLAY_ONLY_ORGANIZATION_LABEL,
    ]);
    const fieldsetTitle = (index) => {
        return strings.fieldsetTitles.involvedOrganizations(organizationFieldName, index);
    };
    return (
        <FormSection
            title={strings.sectionTitles.involvedOrganizations(pluralize(organizationFieldName))}
        >
            <OrganizationsFieldset
                path="involvedOrganizations"
                addText={addInvolvedOrganizationLabel}
                title={fieldsetTitle}
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                includeInvolvement={true}
            />
        </FormSection>
    );
});

function AdvancedSearchReportsForm({
    fuzzyMatchingEnabled,
    formIsEmpty,
    fieldConfigurationContextByContextAndFieldName,
    hasSingleAgencyProfile,
    onSubmit,
    onReset,
    formatFieldByName,
    currentSavedSearch,
}) {
    const personnelUnitEventCardFieldConfigContext = fieldConfigurationContextByContextAndFieldName(
        RefContextEnum.FORM_EVENT_INFO.name,
        EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID
    );
    const isPersonnelUnitStaticallyHidden = !!get(
        personnelUnitEventCardFieldConfigContext,
        'isStaticallyHidden'
    );

    return (
        <AdvancedSearchForm
            {...advancedSearchReports.form}
            includeFuzzyMatchingCheckbox={true}
            fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            onSubmit={onSubmit}
            onReset={onReset}
            formIsEmpty={formIsEmpty}
            searchText={strings.search}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={true} />
            <ReportDetailsSection
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                hideAgencySelect={hasSingleAgencyProfile}
            />
            <PersonnelSection
                isPersonnelUnitStaticallyHidden={isPersonnelUnitStaticallyHidden}
                formatFieldByName={formatFieldByName}
            />
            <hr />
            <InvolvedPersonsSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <hr />
            <InvolvedPropertySection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <hr />
            <InvolvedVehiclesSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <hr />
            <InvolvedLocationsSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <hr />
            <InvolvedOrganizationsSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
        </AdvancedSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    fuzzyMatchingEnabled: advancedSearchReports.form.selectors.buildFormModelSelectorByPath(
        'fuzzyMatchingEnabled'
    ),
    formIsEmpty: advancedSearchReports.form.selectors.formIsEmptySelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    currentUserDepartmentAgencyId: currentUserDepartmentAgencyIdSelector,
    fieldConfigurationContextByContextAndFieldName: fieldConfigurationContextByContextAndFieldNameSelector,
    agencyProfilesWhere: agencyProfilesWhereSelector,
    formatFieldByName: formatFieldByNameSelector,
    currentSavedSearch: advancedSearchReports.selectors.currentSavedSearchSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => {
        dispatch(advancedSearchReports.form.actionCreators.updateShiftTimeRange());
        dispatch(
            advancedSearchReports.form.actionCreators.submit((formData) =>
                dispatch(
                    advancedSearchReports.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                )
            )
        );
    },
    onReset: () => {
        dispatch(advancedSearchReports.form.actionCreators.reset());
        dispatch(advancedSearchReports.actionCreators.resetState());
        dispatch(advancedSearchReports.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(advancedSearchReports.actionCreators.setExecutedSavedSearchToUpdate(null));
        dispatch(fillIfUndefined('reportDetails.dateType', dateTypeEnum.EVENT));
    },
    initializeFormFields: (props) => {
        const data = props.consortiumDepartmentLinksAvailable
            ? { departmentIds: [props.currentUserDepartmentId] }
            : {};
        dispatch(advancedSearchReports.form.actionCreators.change(data));
        dispatch(loadFormDataFromLocalStorageIntoFormModule(advancedSearchReports.form));
        dispatch(fillIfUndefined('reportDetails.dateType', dateTypeEnum.EVENT));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['agencyProfilesWhere'],
        ({ currentUserDepartmentId, agencyProfilesWhere }) => ({
            hasSingleAgencyProfile:
                agencyProfilesWhere({
                    departmentId: currentUserDepartmentId,
                }).length === 1,
        })
    ),
    lifecycle({
        UNSAFE_componentWillMount() {
            // if the form is empty, this means that we didn't execute a saved search.
            // In this case we have to prefill the agency or department select once
            // on component mount so that user have a sensible default value
            if (this.props.formIsEmpty) {
                this.props.initializeFormFields(this.props);
            }
        },
    })
)(AdvancedSearchReportsForm);

/**
 * Attempts to focus a field by id with built-in retry and backoff
 *
 * @param {string} fieldId The id of the field to focus
 * @param {Object} [options]
 * @param {number} [options.retries] The maximum number of retries
 * @param {number} [options.wait] The amount of time to wait in ms before retrying
 * @param {number} [options.backoff] The backoff factor to apply after each focus attempt
 */
export function focusFieldById(fieldId, { retries = 3, wait = 30, backoff = 1.2 } = {}) {
    const element = document.getElementById(fieldId);
    if (element) {
        element.focus();
    } else if (retries > 0) {
        setTimeout(() => {
            focusFieldById(fieldId, { retries: retries - 1, wait: wait * backoff, backoff });
        }, wait);
    }
}

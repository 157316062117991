import React from 'react';

import withAsyncHandler from '../withAsyncHandler';
import Select from './Select';

/**
 * This wraps the select component in an async system that allows you
 * to pass in an action to perform as a search, and will fire
 * off the search as the user types, and cancel old searches.
 * it assume the options are filled via selectors handled in the background
 * @param {Object} props Same props as `Select` with the addition of `searchAction` to specify the action creator that performs search
 */
function AsyncSelect({ asyncHandler, asyncHandlerIsLoading, sortOnRelevance, ...props }) {
    return (
        <Select
            onQueryChange={asyncHandler}
            loading={asyncHandlerIsLoading}
            sortOnRelevance={sortOnRelevance}
            {...props}
            isAsync={true}
            onClick={() => asyncHandler()}
        />
    );
}

export default withAsyncHandler()(AsyncSelect);

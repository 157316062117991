import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { ATTRIBUTE } = fieldTypeClientEnum;

const itemProfileFieldViewModels = buildFlatFormFieldViewModels([
    { key: 'description', fieldName: fields.ITEM_PROFILE_DESCRIPTION },
    {
        key: 'itemCategoryAttrId',
        type: ATTRIBUTE,
        fieldName: fields.ITEM_PROFILE_ITEM_CATEGORY_ATTR_ID,
    },
    { key: 'biohazard', fieldName: fields.ITEM_PROFILE_IS_BIOHAZARD },
    {
        key: 'primaryColorAttrId',
        type: ATTRIBUTE,
        fieldName: fields.ITEM_PROFILE_PRIMARY_COLOR_ATTR_ID,
    },
    {
        key: 'secondaryColorAttrId',
        type: ATTRIBUTE,
        fieldName: fields.ITEM_PROFILE_SECONDARY_COLOR_ATTR_ID,
    },
    { key: 'size', fieldName: fields.ITEM_PROFILE_SIZE },
    { key: 'serialNumber', fieldName: fields.ITEM_PROFILE_SERIAL_NUMBER },
    {
        key: 'firearmMakeAttrId',
        type: ATTRIBUTE,
        fieldName: fields.FIREARM_FIREARM_MAKE_ATTR_ID,
    },
    {
        key: 'registrationNumber',
        fieldName: fields.FIREARM_REGISTRATION_NUMBER,
    },
    {
        key: 'finishAttrId',
        type: ATTRIBUTE,
        fieldName: fields.FIREARM_FINISH_ATTR_ID,
    },
    {
        key: 'stockAttrId',
        type: ATTRIBUTE,
        fieldName: fields.FIREARM_STOCK_ATTR_ID,
    },
    {
        key: 'gripAttrId',
        type: ATTRIBUTE,
        fieldName: fields.FIREARM_GRIP_ATTR_ID,
    },
    {
        key: 'ncicFirearmCaliberAttrId',
        type: ATTRIBUTE,
        fieldName: fields.FIREARM_NCIC_FIREARM_CALIBER_ATTR_ID,
    },
    { key: 'caliber', fieldName: fields.FIREARM_CALIBER },
    { key: 'barrelLength', fieldName: fields.FIREARM_BARREL_LENGTH },
    { key: 'numberOfShots', fieldName: fields.FIREARM_NUMBER_OF_SHOTS },
    {
        key: 'alterationIndicated',
        fieldName: fields.FIREARM_ALTERATION_INDICATED,
    },
    {
        key: 'vehicleMakeAttrId',
        type: ATTRIBUTE,
        fieldName: fields.VEHICLE_VEHICLE_MAKE_ATTR_ID,
    },
    { key: 'vehicleMakeId', fieldName: fields.VEHICLE_VEHICLE_MAKE_ID },
    {
        key: 'vehicleModelAttrId',
        type: ATTRIBUTE,
        fieldName: fields.VEHICLE_VEHICLE_MODEL_ATTR_ID,
    },
    { key: 'vehicleModelId', fieldName: fields.VEHICLE_VEHICLE_MODEL_ID },
    {
        key: 'yearOfManufacture',
        fieldName: fields.VEHICLE_YEAR_OF_MANUFACTURE,
    },
    {
        key: 'bodyStyleAttrId',
        type: ATTRIBUTE,
        fieldName: fields.VEHICLE_BODY_STYLE_ATTR_ID,
    },
    { key: 'tag', fieldName: fields.VEHICLE_TAG },
    { key: 'vinNumber', fieldName: fields.VEHICLE_VIN_NUMBER },
    {
        key: 'registrationStateAttrId',
        fieldName: fields.VEHICLE_REGISTRATION_STATE_ATTR_ID,
    },
    {
        key: 'registrationYear',
        fieldName: fields.VEHICLE_REGISTRATION_YEAR,
    },
    {
        key: 'registrationType',
        fieldName: fields.VEHICLE_REGISTRATION_TYPE,
    },
    { key: 'mileage', fieldName: fields.VEHICLE_MILEAGE },
    {
        key: 'insuranceProviderName',
        fieldName: fields.VEHICLE_INSURANCE_PROVIDER_NAME,
    },
    {
        key: 'insurancePolicyNumber',
        fieldName: fields.VEHICLE_INSURANCE_POLICY_NUMBER,
    },
    { key: 'itemMake', fieldName: fields.ITEM_PROFILE_ITEM_MAKE },
    { key: 'itemModel', fieldName: fields.ITEM_PROFILE_ITEM_MODEL },
    { key: 'bodyStyleOther', fieldName: fields.VEHICLE_BODY_STYLE_OTHER },
]);

export default itemProfileFieldViewModels;

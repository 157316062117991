import React, { useEffect } from 'react';
import styled from 'styled-components';
import { lifecycleOptions } from 'markformythree';
import { AttributeTypeEnum, EFileView, RefContextEnum, RoleTypeEnum } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { E_FILE_ROLE_LINK_ROLE_ID } from '~/client-common/core/enums/universal/fields';
import testIds from '../../../../core/testIds';
import formsRegistry from '../../../../core/formsRegistry';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import {
    convertToFormModel,
    detailsEFileFormConfiguration,
    InvolvedPersonnelFormConfigurationT,
} from '../state/forms/eFileDetailsForm';
import { currentUserIdSelector } from '../../../core/current-user/state/ui';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import FormGroup from '../../../core/forms/components/FormGroup';
import FormRow from '../../../core/forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { ArbiterMFTRoleSelect } from '../../../core/forms/components/selects/RoleSelect';
import { MFTNItems } from '../../../core/forms/components/NItems';
import { Button } from '../../../core/components/Button';

const strings = componentStrings.eFiles.core.eFileDetailsModal.form;

const CustomRow = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 0;
    gap: var(--arc-space-3);
`;

const StyledInvolvedPersonRow = styled(CustomRow)`
    align-items: flex-end;
`;

const NItemRemoveButtonWrapper = styled.div`
    padding-top: 28px;
    width: 19px;
    flex-shrink: 0;
    align-self: flex-start;
`;

const AddMeButton = styled(Button)`
    margin-top: 23px;
    flex-shrink: 0;
`;

type InvolvedPersonnelRowPropsT = {
    index: number;
    itemElement: React.ReactNode;
    removeButtonElement: React.ReactNode;
};
const InvolvedPersonnelRow = ({
    index,
    itemElement,
    removeButtonElement,
}: InvolvedPersonnelRowPropsT) => (
    <StyledInvolvedPersonRow key={index}>
        {itemElement}
        <NItemRemoveButtonWrapper>{index > 0 && removeButtonElement}</NItemRemoveButtonWrapper>
    </StyledInvolvedPersonRow>
);

type EFileDetailsFormPropsT = {
    eFile?: EFileView;
};

const EFileDetailsForm = ({ eFile }: EFileDetailsFormPropsT) => {
    const currentUserId = useSelector(currentUserIdSelector);
    const involvedPersonnelFieldName = useFields(E_FILE_ROLE_LINK_ROLE_ID)[
        E_FILE_ROLE_LINK_ROLE_ID
    ];

    useEffect(() => {
        if (eFile) {
            formsRegistry.maybeDeferredOperation(
                RefContextEnum.FORM_E_FILE_DETAILS_MODAL.name,
                undefined,
                (form) => {
                    const formModel = convertToFormModel(eFile);
                    form.set('', formModel);
                }
            );
        }
    }, [eFile]);

    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={RefContextEnum.FORM_E_FILE_DETAILS_MODAL.name}
            context={RefContextEnum.FORM_E_FILE_DETAILS_MODAL.name}
            configuration={detailsEFileFormConfiguration}
            render={(form) => (
                <FormGroup>
                    <ArbiterMFTText path="title" length="md" />
                    <ArbiterMFTAttributeSelect
                        path="caseClassificationAttrIds"
                        length="lg"
                        attributeType={AttributeTypeEnum.E_FILE_CLASSIFICATION.name}
                        multiple
                    />
                    <CustomRow>
                        <ArbiterMFTUserSelect length="lg" path="caseOwnerId" />
                        <AddMeButton
                            isTextTransformNone
                            testId={testIds.ASSIGN_TO_ME_BUTTON}
                            variant={'outline'}
                            onClick={() => {
                                form.set('caseOwnerId', currentUserId);
                            }}
                        >
                            {strings.addMe}
                        </AddMeButton>
                    </CustomRow>
                    <ArbiterMFTAttributeSelect
                        path="courtUnitId"
                        length="md"
                        attributeType={AttributeTypeEnum.COURT_UNIT.name}
                    />
                    <>
                        <MFTNItems<InvolvedPersonnelFormConfigurationT>
                            path="involvedPersonnels"
                            addText={strings.addInvolvedPersonnel(involvedPersonnelFieldName)}
                            renderRowContainer={({ itemElement, removeButtonElement, index }) => (
                                <InvolvedPersonnelRow
                                    itemElement={itemElement}
                                    removeButtonElement={removeButtonElement}
                                    index={index}
                                />
                            )}
                            render={() => (
                                <FormRow>
                                    <ArbiterMFTRoleSelect
                                        roleTypes={[RoleTypeEnum.USER.name]}
                                        path="involvedPersonnelId"
                                        length="md"
                                    />
                                    <ArbiterMFTAttributeSelect
                                        path="involvedPersonnelRoleId"
                                        length="md"
                                        attributeType={
                                            AttributeTypeEnum.E_FILE_ROLE_INVOLVEMENT_TYPE.name
                                        }
                                    />
                                </FormRow>
                            )}
                        />
                    </>
                    <ArbiterMFTAttributeSelect
                        path="caseStatusAttrId"
                        length="md"
                        attributeType={AttributeTypeEnum.E_FILE_STATUS.name}
                    />
                </FormGroup>
            )}
        />
    );
};

export default EFileDetailsForm;

/**
 * How many milliseconds to wait between each polling request for the status of
 *   the Import Event within a report. If the report is a Custodial Property
 *   Summary report, this affects how long it takes for the report to be updated
 *   with Evidence data after it is submitted. If the report is another type of
 *   report that is not already linked to a Custodial Property Summary report,
 *   this affects how long it takes for the newly generated Custodial Property
 *   Summary report to show up in the sidebar. The Import Event should happen
 *   within the first couple seconds, which is why the first intervals are
 *   short.
 * @type {number[]}
 */
export const POLL_IMPORT_EVENT_INTERVALS = [500, 1000, 2000, 5000];

/**
 * How many times to try polling the Import Event before giving up.
 * @type {number}
 */
export const POLL_IMPORT_EVENT_MAX_ATTEMPTS = 10;

import React from 'react';
import { Stack } from 'arc';
import { CardField } from '@mark43/rms-api';
import { BookingDetailsInfo } from './BookingDetailsInfo';

type BookingDetailsCardProps = {
    fields: CardField[];
};

export const BookingDetails: React.FC<BookingDetailsCardProps> = ({ fields }) => {
    return (
        <Stack>
            {fields.map((field) => {
                const label = field.label || '';
                const value = field.values.map((item) => item.value).join(',');
                return <BookingDetailsInfo label={label} key={field.key} value={value} />;
            })}
        </Stack>
    );
};

import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Permissions Resource',
    methods: {
        /**
         * Get the permissions for the given entity or entities.
         * @param  {string}          entityType
         * @param  {number|number[]} entityIds
         * @return {Promise<Object[]>} Set of EntityPermission models.
         */
        getPermissions(entityType, entityIds) {
            return req({
                method: 'GET',
                url: 'security/permissions/entity',
                params: {
                    entity_type: entityType,
                    entity_ids: entityIds,
                },
            });
        },

        /**
         * Update the permissions for the given entity, by REPLACING THEM.
         * @param  {string}   entityType
         * @param  {number}   entityId
         * @param  {Object[]} permissions
         * @return {Promise<Object[]>} Set of EntityPermission models.
         */
        updatePermissions(entityType, entityId, permissions) {
            return req({
                method: 'PUT',
                url: 'security/permissions/entity',
                params: {
                    entity_type: entityType,
                    entity_id: entityId,
                },
                data: permissions,
            });
        },

        /**
         * Get the default permissions for warrants
         * @return {Promise<Object[]>} Set of FutureEntityPermission models.
         */
        getWarrantDefaultPermissions() {
            return req({
                method: 'GET',
                url: 'security/permissions/entity/future/warrants',
            });
        },

        /**
         * Update the default permissions for warrants, by REPLACING THEM.
         * @param  {Object[]} permissions
         * @return {Promise<Object[]>} Set of FutureEntityPermission models.
         */
        updateWarrantDefaultPermissions(permissions) {
            return req({
                method: 'PUT',
                url: 'security/permissions/entity/future/warrants',
                data: permissions,
            });
        },

        /**
         * Get the default permissions for a master entity
         * @param  {string}   entityType
         * @return {Promise<Object[]>} Set of FutureEntityPermission models.
         */
        getMasterEntityDefaultPermissions(entityType) {
            return req({
                method: 'GET',
                url: 'security/permissions/entity/future/master',
                params: {
                    entity_type: entityType,
                },
            });
        },

        /**
         * Update the default permissions for a master entity, by REPLACING THEM.
         * @param  {string}   entityType
         * @param  {Object[]} permissions
         * @return {Promise<Object[]>} Set of FutureEntityPermission models.
         */
        updateMasterEntityDefaultPermissions(entityType, permissions) {
            return req({
                method: 'PUT',
                url: 'security/permissions/entity/future/master',
                params: {
                    entity_type: entityType,
                },
                data: permissions,
            });
        },
    },
});

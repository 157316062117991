import { noop } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle } from 'recompose';
import { focusFirstElementOnCard } from './cardHelpers';

export default function withCard(cardObject) {
    return (Component) => {
        const mapStateToProps = () => {
            const structuredSelector = createStructuredSelector({
                saving: cardObject.selectors.savingSelector,
                errorMessages: cardObject.selectors.errorMessagesSelector,
                summaryMode: cardObject.selectors.summaryModeSelector,
                canEditReportCardStatus: cardObject.selectors.canEditReportCardStatusSelector,
                canEditOffenseIncidentReportCardStatus:
                    cardObject.selectors.canEditOffenseIncidentReportCardStatusSelector,
                canEditEventInfoReportCardStatus:
                    cardObject.selectors.canEditEventInfoReportCardStatusSelector,
                canEditSummaryNarrativeReportCardStatus:
                    cardObject.selectors.canEditSummaryNarrativeReportCardStatusSelector,
                anchor: () => cardObject.anchor,
                anchorForIndex: () => cardObject.anchorForIndex,
            });
            return (state, ownProps) => {
                return {
                    card: structuredSelector(state, ownProps),
                };
            };
        };
        const mapDispatchToProps = (dispatch) => ({
            onEdit(onEditProps = {}) {
                const actionToReturn = dispatch(cardObject.actionCreators.edit(onEditProps));
                focusFirstElementOnCard(cardObject.anchor);
                return actionToReturn;
            },
            resetCard(options = {}) {
                dispatch(cardObject.actionCreators.resetState(options));
            },
            onSaveProgress(form, options = {}) {
                return dispatch((dispatch, getState) => {
                    return (
                        dispatch(cardObject.actionCreators.save(form, options))
                            // Specifically no-op here.  The `save` implementation takes care
                            // of side-effects such as actually dispatching panel error messages
                            // that are collected.  On a `Save Progress` click, this is all we
                            // need because this Card is already at the top level.  However,
                            // on a `Package Report` click, this Card is not the parent; the
                            // Ko view (`reportView`) is -- thus, for `Package Report`, we
                            // throw the panel error messages up one more level.  Both types of
                            // saves use the same `save` action because they are same, barring
                            // this difference.
                            // We catch here to swallow bluebird `unhandled rejection` error.
                            .catch(noop)
                            .finally(() => {
                                const indexOptions = { index: options.index };
                                const isInSummaryMode = cardObject.selectors.summaryModeSelector(
                                    getState(),
                                    indexOptions
                                );
                                if (isInSummaryMode) {
                                    cardObject.scrollToNextCard(indexOptions);
                                } else {
                                    cardObject.scrollToTop(indexOptions);
                                }
                            })
                    );
                });
            },
            onSave(form, options = {}) {
                return (
                    dispatch(cardObject.actionCreators.save(form, options))
                        // Do not no-op here; continue throwing errors up to parent Ko view.
                        // No side-effects here for scrolling the card; parent Ko view
                        // runs a save on _all_ cards, then determines which is the highest
                        // card in the DOM to scroll to.
                        .catch((errorMessages) => {
                            throw errorMessages;
                        })
                );
            },
            setReportId: (options) => dispatch(cardObject.actionCreators.setReportId(options)),
        });
        return compose(
            connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }),
            lifecycle({
                componentDidMount() {
                    // initialize card state with this prop which is inconsistently named across the card components
                    const reportId = this.props.reportId || this.props.currentReportId;
                    if (reportId) {
                        this.props.setReportId({ reportId, index: this.props.index });
                    }
                },
                componentWillUnmount() {
                    const index = this.props.index;
                    this.props.resetCard({ index });
                },
            })
        )(Component);
    };
}

const BASE_URL = '/cad/api';
const BASE_PATH = 'dispatch_areas';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Cad Dispatch Areas Resource',
        methods: {
            /**
             * Get all dispatch areas.
             *
             * @return {Promise<DispatchArea[]>}
             */
            getDispatchAreas() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                });
            },

            /**
             * Upserts a dispatch area.
             *
             * @param {DispatchArea}
             * @return {Promise<DispatchArea>}
             */
            upsertDispatchArea(dispatchArea) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                    data: dispatchArea,
                });
            },

            /**
             * Build (shapefile) dispatch areas for agency types.
             *
             * @param {number[]} agencyTypeGlobalAttrIds,
             * @param {DispatchAreas[]} oojDispatchAreas
             * @return {Promise<DispatchArea[]>}
             */
            buildOojDispatchAreas(agencyTypeGlobalAttrIds, oojDispatchAreas) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/build`,
                    data: {
                        agencyTypeGlobalAttrIds,
                        oojDispatchAreas,
                    },
                });
            },
        },
    });
}

/**
 * Resources for Dispatch Areas. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import {
    ElasticReport,
    ElasticPerson,
    LinkTypesEnum,
    ElasticInvolvedPerson,
    PersonProfile,
    Attachment,
} from '@mark43/rms-api';
import {
    map,
    filter,
    flatten,
    chain,
    flattenDeep,
    ObjectIterateeCustom,
    orderBy,
    differenceBy,
} from 'lodash';
import { ModuleShape } from '~/client-common/redux/state';

export const POI_VALUE = -1;

const getMasterProfile = (
    personProfiles: ElasticInvolvedPerson[],
    personProfilesWhere: (
        predicate: ObjectIterateeCustom<ModuleShape<PersonProfile>, unknown>
    ) => PersonProfile[],
    mugshotAttachmentsForPersonId: (personProfileId: number) => Attachment[]
): PersonProfile[] => {
    const masterPersonProfiles: PersonProfile[] = filter(
        flatten(
            map(personProfiles, (personProfile) =>
                personProfilesWhere({
                    masterPersonId: personProfile.person.masterPersonId,
                })
            )
        ),
        (profile) => profile !== undefined
    );

    return filter(
        masterPersonProfiles,
        (profile) => mugshotAttachmentsForPersonId(profile.id).length > 0
    );
};

const getKnownProfiles = (profiles: ElasticInvolvedPerson[]) =>
    chain(profiles)
        .filter((profile) => profile.person && !!profile.person.masterPersonId)
        .uniqBy((profile) => profile.person.masterPersonId)
        .value();

const getUnknownProfiles = (
    profiles: ElasticInvolvedPerson[],
    mugshotAttachmentsForPersonId: (personProfileId: number) => Attachment[]
) =>
    chain(profiles)
        .filter((profile) => !profile.person.masterPersonId)
        .filter((profile) => mugshotAttachmentsForPersonId(profile.person.id).length > 0)
        .uniqBy((profile) => profile.person.id) // dedupe if person is listed as suspect on multiple offenses on same report
        .map((profile) => profile.person)
        .value();

// get all suspect profiles on linked offense reports
export const getSuspectsOnCaseLinkedOffenseReports = (
    casePersons: ElasticPerson[],
    caseReports: ElasticReport[],
    personProfilesWhere: (
        predicate: ObjectIterateeCustom<ModuleShape<PersonProfile>, unknown>
    ) => PersonProfile[],
    mugshotAttachmentsForPersonId: (personProfileId: number) => Attachment[]
): (PersonProfile | ElasticPerson)[] => {
    const suspectProfiles = flattenDeep(
        map(casePersons, (person) =>
            chain(caseReports)
                .map('involvedPersons')
                .flatten()
                .filter(
                    (involvedPerson) =>
                        person.id ===
                            (involvedPerson.person.masterPersonId || involvedPerson.person.id) &&
                        !involvedPerson.person.isJuvenile &&
                        involvedPerson.involvement === LinkTypesEnum.SUSPECT_IN_OFFENSE
                )
                .value()
        )
    );

    const knownSuspectProfiles = getKnownProfiles(suspectProfiles);

    const unknownSuspectProfiles = getUnknownProfiles(
        suspectProfiles,
        mugshotAttachmentsForPersonId
    );

    const knownSuspectPersonProfiles = getMasterProfile(
        knownSuspectProfiles,
        personProfilesWhere,
        mugshotAttachmentsForPersonId
    );

    const allSuspectProfiles = [...knownSuspectPersonProfiles, ...unknownSuspectProfiles];
    return allSuspectProfiles;
};

// get all non-suspect profiles on any linked reports
// if a person is already a suspect, exclude them from the POI dropdown
export const getOtherPersonsOnCaseLinkedReports = (
    casePersons: ElasticPerson[],
    caseReports: ElasticReport[],
    personProfilesWhere: (
        predicate: ObjectIterateeCustom<ModuleShape<PersonProfile>, unknown>
    ) => PersonProfile[],
    mugshotAttachmentsForPersonId: (personProfileId: number) => Attachment[],
    offenseSuspects: (PersonProfile | ElasticPerson)[]
): (PersonProfile | ElasticPerson)[] => {
    const otherProfiles = flattenDeep(
        map(casePersons, (person) =>
            chain(caseReports)
                .map('involvedPersons')
                .flatten()
                .filter(
                    (involvedPerson) =>
                        person.id ===
                            (involvedPerson.person.masterPersonId || involvedPerson.person.id) &&
                        !involvedPerson.person.isJuvenile &&
                        involvedPerson.involvement !== LinkTypesEnum.SUSPECT_IN_OFFENSE
                )
                .value()
        )
    );

    const knownPoiProfiles = getKnownProfiles(otherProfiles);

    const unknownPoiProfiles = getUnknownProfiles(otherProfiles, mugshotAttachmentsForPersonId);

    const knownPoiPersonProfiles = getMasterProfile(
        knownPoiProfiles,
        personProfilesWhere,
        mugshotAttachmentsForPersonId
    );
    const allPoiProfiles = [...knownPoiPersonProfiles, ...unknownPoiProfiles];

    return differenceBy(allPoiProfiles, offenseSuspects, (profile) =>
        profile.masterPersonId ? profile.masterPersonId : profile.id
    );
};

export const getFormOptions = (profiles: (PersonProfile | ElasticPerson)[]) => {
    return orderBy(
        map(profiles, (profile: PersonProfile | ElasticPerson) => {
            return {
                label: `${profile.firstName || 'UNKNOWN'} ${profile.lastName || 'UNKNOWN'}`,
                value: profile.id,
            };
        }),
        'label'
    );
};

export const getMugshots = (
    profileId: number,
    mugshotAttachmentsForPersonId: (personProfileId: number) => Attachment[]
) => {
    return orderBy(mugshotAttachmentsForPersonId(profileId), 'createdDateUtc', 'desc');
};

export const mugShotsExist = (
    profiles: (PersonProfile | ElasticPerson)[],
    mugshotAttachmentsForPersonId: (personProfileId: number) => Attachment[]
) => {
    for (let i = 0; i < profiles.length; i++) {
        const mugshots = mugshotAttachmentsForPersonId(profiles[i].id);
        if (mugshots && mugshots.length > 0) {
            return true;
        }
    }
    return false;
};

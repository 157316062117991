import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { first, noop, orderBy } from 'lodash';
import styled from 'styled-components';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import getExportsResource from '~/client-common/core/domain/exports/resources/exportsResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { RmsDispatch } from '../../../core/typings/redux';
import { OnlyWithAbility } from '../../core/abilities';
import { useFormGetter } from '../../core/forms/hooks/useFormGetter';
import { closeBox, openBox, saveBoxHalt } from '../../../legacy-redux/actions/boxActions';
import { exportDocument } from '../../../legacy-redux/actions/exportsActions';
import complianceExportResource from '../resources/complianceExportResource';
import { buttonTypes } from '../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../legacy-redux/components/core/Icon';
import Modal from '../../../legacy-redux/components/core/Modal';
import reportPrintingResource from '../../admin/report-printing-templates/resources/reportPrintingResource';

import nibrsExportResource from '../resources/nibrsExportResource';
import { useComplianceExportContext } from '../contexts/ComplianceExportContext';
import { uiDisableComplianceExportFormControls } from '../state/ui';
import { createPoller } from '../util/complianceExportTypeHelpers';
import useSubmissionType from '../hooks/useIsExternalSubmission';
import { useGetLastExportedDate } from '../hooks';
import {
    exportNibrsDownloadModalContext,
    exportResubmissionDownloadModalContext,
} from '../configuration';
import { ComplianceExportType, FetchErrorsSuccessType } from '../types';
import { convertFromResubmissionFormModel } from './ComplianceResubmissionForm';
import ComplianceInlineButton from './ComplianceInlineButton';
import ComplianceResubmissionExportModal, {
    useOpenComplianceResubmissionExportModal,
} from './ComplianceResubmissionExportModal';
import ComplianceExportAuthenticationForm from './ComplianceExportAuthenticationForm';

type ComplianceExportDownloadButtonProps = {
    className?: string;
    complianceExportType: ComplianceExportType;
    disabled?: boolean;
    onFetchErrorsSuccess?: FetchErrorsSuccessType;
    isResubmission?: boolean;
};

const ComplianceExportAuthenticationFormWrapper = styled.div`
    margin: 10px 0;
`;

const modalContentStyles = {
    minHeight: 'auto',
    fontSize: '13px',
};

const strings = componentStrings.compliance.ComplianceResubmissionExportDownloadButton;
const buttonStrings = componentStrings.compliance.ComplianceExportDownloadButton;

function useConvertedFormModelGetter(
    segmentGenerationEnabled: boolean,
    currentAgencyId: number | undefined
) {
    const { getForm } = useFormGetter();

    return useCallback(() => {
        const form = getForm(formClientEnum.COMPLIANCE_RESUBMISSION);
        const formModel = form?.getState().model;

        // form is already submitted and validated
        if (!formModel) {
            return;
        }

        return convertFromResubmissionFormModel(
            formModel,
            segmentGenerationEnabled,
            currentAgencyId
        );
    }, [currentAgencyId, getForm, segmentGenerationEnabled]);
}

const ComplianceResubmissionExportDownloadButton: React.FC<ComplianceExportDownloadButtonProps> = (
    props
) => {
    const { complianceExportType, disabled, onFetchErrorsSuccess } = props;
    const { callResource: getLastExportedDate } = useGetLastExportedDate(complianceExportType);
    const { isExternalSubmission, isNibrs } = useSubmissionType(complianceExportType);
    const { currentAgencyId } = useComplianceExportContext();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const { getForm } = useFormGetter();
    const dispatch = useDispatch<RmsDispatch>();
    const convertedFormModelGetter = useConvertedFormModelGetter(
        !!applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED,
        currentAgencyId
    );

    const onGenerateFinalExportSuccessCallback = useCallback(
        (results) => {
            dispatch(uiDisableComplianceExportFormControls(false));
            const mostRecentExport = first(orderBy(results, ['createdDateUtc'], ['desc']));

            const complianceForm = getForm(formClientEnum.COMPLIANCE_RESUBMISSION);
            if (complianceForm) {
                const formModel = complianceForm.get();
                if (formModel && formModel.agencyId) {
                    getLastExportedDate(formModel.agencyId);
                }
            }

            if (isExternalSubmission) {
                const pollForErrorResults = createPoller(
                    getExportsResource().pollForExportIds,
                    isNibrs
                        ? nibrsExportResource.getExportErrors
                        : complianceExportResource.getComplianceErrorSummary
                );
                return pollForErrorResults([mostRecentExport.id]).then((results) => {
                    if (onFetchErrorsSuccess) {
                        onFetchErrorsSuccess(results);
                    }
                });
            }
            return;
        },
        [
            isNibrs,
            isExternalSubmission,
            getForm,
            getLastExportedDate,
            onFetchErrorsSuccess,
            dispatch,
        ]
    );

    const generateFinalExport = useCallback(
        (payload) => {
            let username;
            let password;
            const complianceExportAuthenticationForm = getForm(
                formClientEnum.COMPLIANCE_EXPORT_AUTHENTICATION_FORM
            );
            if (complianceExportAuthenticationForm) {
                const {
                    username: formUsername,
                    password: formPassword,
                } = complianceExportAuthenticationForm.get();
                username = formUsername;
                password = formPassword;
            }

            dispatch(uiDisableComplianceExportFormControls(true));
            if (isNibrs) {
                dispatch(
                    exportDocument({
                        requestData: {
                            ...payload,
                            username,
                            password,
                            resubmission: true,
                        },
                        exportResourceMethod: reportPrintingResource.generateFinalNibrsExport,
                        onSuccess: onGenerateFinalExportSuccessCallback,
                        disableUsageLogs: true,
                    })
                );
            } else {
                dispatch(
                    exportDocument({
                        requestData: {
                            ...payload,
                            complianceExportType: complianceExportType?.name,
                            username,
                            password,
                            resubmission: true,
                        },
                        exportResourceMethod: reportPrintingResource.generateComplianceExportFinal,
                        onSuccess: onGenerateFinalExportSuccessCallback,
                        disableUsageLogs: true,
                    })
                );
            }
        },
        [isNibrs, complianceExportType, dispatch, getForm, onGenerateFinalExportSuccessCallback]
    );

    const confirmResubmissionModalOnSave = useCallback(async () => {
        const payload = convertedFormModelGetter();

        const success = () => {
            dispatch(saveBoxHalt(exportResubmissionDownloadModalContext));
            dispatch(closeBox(exportResubmissionDownloadModalContext));
            dispatch(closeBox(exportNibrsDownloadModalContext));
            generateFinalExport(payload);
        };

        const complianceExportAuthenticationForm = getForm(
            formClientEnum.COMPLIANCE_EXPORT_AUTHENTICATION_FORM
        );

        if (complianceExportAuthenticationForm) {
            try {
                const result = await complianceExportAuthenticationForm.submit();

                if (result && result.validationResult && !result.validationResult.success) {
                    dispatch(saveBoxHalt(exportResubmissionDownloadModalContext));
                    return;
                }
            } catch {
                dispatch(saveBoxHalt(exportResubmissionDownloadModalContext));
                return;
            }
        }

        return (
            nibrsExportResource
                // @ts-expect-error formModel props are all optional, does not match resource payload type
                .checkFinalExportStatus(payload)
                .then(() => success())
        );
    }, [dispatch, convertedFormModelGetter, generateFinalExport, getForm]);

    const openResubmissionExportModal = useOpenComplianceResubmissionExportModal({
        overlayId: overlayIdEnum.COMPLIANCE_RESUBMISSION_EXPORT_MODAL,
        exportHandler: confirmResubmissionModalOnSave,
    });

    const openCredentialsModal = useCallback(() => {
        const formName = formClientEnum.COMPLIANCE_RESUBMISSION;
        const form = getForm(formName);

        form?.submit()
            .then(() => dispatch(openBox(exportNibrsDownloadModalContext)))
            // no validation messages, required errors are displayed inline on inputs
            .catch(noop);
    }, [dispatch, getForm]);

    const confirmModalOnSave = isExternalSubmission ? openCredentialsModal : confirmResubmissionModalOnSave;

    const exportButtonLabel = isExternalSubmission
        ? buttonStrings.labels.exportAndSubmit
        : buttonStrings.labels.exportButton

    const exportButton = (
        <ComplianceInlineButton
            buttonType={buttonTypes.PRIMARY}
            iconType={iconTypes.EXPORT}
            disabled={disabled}
            onClick={openResubmissionExportModal}
        >
            {exportButtonLabel}
        </ComplianceInlineButton>
    );

    return (
        <OnlyWithAbility has={abilitiesEnum.REPORTING.NIBRS_WORKSPACE}>
            <Modal
                title={strings.modal.title}
                context={exportNibrsDownloadModalContext}
                okText={strings.modal.confirm}
                cancelText={strings.modal.cancel}
                onSave={confirmResubmissionModalOnSave}
                shouldCloseOnOverlayClick={false}
                contentStyle={modalContentStyles}
            >
                <ComplianceExportAuthenticationFormWrapper>
                    <ComplianceExportAuthenticationForm
                        complianceExportTypeDisplayValue={'NIBRS'}
                    />
                </ComplianceExportAuthenticationFormWrapper>
            </Modal>
            {exportButton}
            <ComplianceResubmissionExportModal
                overlayId={overlayIdEnum.COMPLIANCE_RESUBMISSION_EXPORT_MODAL}
                exportHandler={confirmModalOnSave}
            />
        </OnlyWithAbility>
    );
};
export default ComplianceResubmissionExportDownloadButton;

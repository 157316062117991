import { get } from 'lodash';
import departmentProfileAdminActionTypes from '../actions/types/departmentProfileAdminActionTypes';
import { departmentProfileFormFieldList } from '../configs/adminConfig';
import { setFormValues } from '../helpers/formReducerHelpers';

const {
    LOAD_DEPARTMENT_PROFILE_SUCCESS,
    SAVE_DEPARTMENT_PROFILE_SUCCESS,
    RESET_DEPARTMENT_FORM,
    LOAD_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS,
    SAVE_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS,
} = departmentProfileAdminActionTypes;

export function departmentProfileAdminFormReducer(state, action) {
    let formObj = {};
    switch (action.type) {
        case LOAD_DEPARTMENT_PROFILE_SUCCESS:
            formObj = {
                ...action.payload.locationBias,
                ...action.payload,
                departmentStatus: get(action.payload, 'department.departmentStatus'),
                searchResyncsEnabled: get(action.payload, 'department.allowSearchResyncs'),
            };
            return setFormValues(state, formObj, departmentProfileFormFieldList);
        case SAVE_DEPARTMENT_PROFILE_SUCCESS:
            formObj = {
                ...action.payload.locationBias,
                ...action.payload,
                departmentStatus: get(action.payload, 'department.departmentStatus'),
                searchResyncsEnabled: get(action.payload, 'department.allowSearchResyncs'),
            };
            return setFormValues(state, formObj, departmentProfileFormFieldList);
        case RESET_DEPARTMENT_FORM:
            const deptProfile = action.payload;
            formObj = {
                ...deptProfile.locationBias,
                ...deptProfile,
                departmentStatus: get(deptProfile, 'department.departmentStatus'),
                searchResyncsEnabled: get(action.payload, 'department.allowSearchResyncs'),
            };
            return setFormValues(state, formObj, departmentProfileFormFieldList);
        case LOAD_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS:
        case SAVE_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS:
            return { ...state, departmentProfileAttachment: action.payload };
        default:
            return state;
    }
}

/* eslint-disable camelcase */
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/evidence/api';
const BASE_PATH = 'barcodes';

/**
 * Resources for getting evidence barcode labels either in the preview format (a
 *   string in Base64) or a format to send to the printer (a string in ZPL
 *   code). This is not in core/domain/barcodes because labels contain more info
 *   than barcodes, and none of these endpoints return Barcode models.
 *
 * See the tech doc for details.
 * @see https://docs.google.com/document/d/1ulTpI_KMBkRz35uADJMoAqdVeeIXL__hdDKbfFqeaWc/
 */
export default createResource({
    name: 'Evidence Barcode Label Resource',
    methods: {
        upsertLayout(layout) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/labels/layouts`,
                data: layout,
            });
        },
        getLabelPreview(elements) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/labels/layout/preview`,
                data: { elements },
            });
        },

        /**
         * Get a Base64 string for displaying a label preview image to the user
         *   before they print.
         * @param  {string} entityType
         * @param  {number} entityId
         * @return {string}
         */
        getLabelPreviewForEntity(entityType, entityId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/labels/preview`,
                params: {
                    entity_type: entityType,
                    entity_id: entityId,
                },
            });
        },

        /**
         * Get Base64 strings for displaying label preview images to the user
         *   before they print. The order of the returned array is NOT
         *   guaranteed to be the same order as `entityIds`.
         * @param  {string}   entityType
         * @param  {number[]} entityIds
         * @return {string[]}
         */
        getLabelPreviewsForEntities(entityType, entityIds) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/labels/preview/batch`,
                params: {
                    entity_type: entityType,
                    entity_id: entityIds,
                },
            });
        },

        /**
         * Get the ZPL code for a single entity. The result is a string to be
         *   sent to a label printer in a separate API request for printing (see
         *   `printingResource`).
         * @param  {string} entityType
         * @param  {string} entityId
         * @param  {string} dpi
         * @param  {string} printerId
         * @return {Promise<string>}
         */
        getZplCodeForEntity(entityType, entityId, dpi, printerId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/labels/print`,
                params: {
                    entity_type: entityType,
                    entity_id: entityId,
                    dpi,
                    printer_id: printerId,
                },
            });
        },

        /**
         * Get the ZPL code for multiple entities of the same type. The result
         *   is a string to be sent to a label printer in a separate API request
         *   for printing (see `printingResource`).
         * @param  {string} entityType
         * @param  {string} entityIds
         * @param  {string} dpi
         * @param  {string} printerId
         * @return {Promise<string>}
         */
        getZplCodeForEntities(entityType, entityIds, dpi, printerId) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/labels/print/batch`,
                params: {
                    entity_type: entityType,
                    dpi,
                    printer_id: printerId,
                },
                data: entityIds,
            });
        },

        /**
         * Uploads the SVG file of a custom evidence label
         * @param  {CustomLabelCreationRequest} customLabelCreationRequest
         * @return {Promise<LabelLayoutTemplateContents>}
         */
        uploadSvgFile(customLabelCreationRequest) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/labels/custom_label/create`,
                data: customLabelCreationRequest,
            });
        },

        /**
         * Get the custom evidence label that is currently uploaded
         * @return {Promise<LabelLayoutTemplateContents>}
         */
        getCustomLabel() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/labels/custom_label/get`,
            });
        },

        /**
         * Delete the custom evidence label that is currently uploaded
         * @return {Promise<boolean>}
         */
        deleteCustomLabel() {
            return req({
                method: 'DELETE',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/labels/custom_label/delete`,
            });
        },
    },
});

import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

const DEFAULT_PARAMS = {
    q: '',
    from: 0,
    size: 20,
};

export default createResource({
    name: 'Search Resource',
    methods: {
        /**
         * Search for users.
         * @param  {Object} params
         * @param  {string} [params.q]
         * @param  {number} [params.from]
         * @param  {number} [params.size]
         * @param  {string} [params.source]
         * @param  {Object} [data]
         * @return {Promise<import('@mark43/rms-api').SearchResultElasticUser>}
         */
        searchUsers(params, data) {
            return req({
                method: 'POST',
                url: 'elastic_search/users',
                hideLoadingBar: true,
                data: {
                    elasticQuery: {
                        quickSearchQuery: params.q || DEFAULT_PARAMS.q,
                        dutyStatusHistories: [
                            {
                                dateEffectiveStartBefore: data.effectiveDate,
                                dateEffectiveEndAfter: data.effectiveDate,
                            },
                        ],
                    },
                    sorts: [
                        {
                            sortKey: sortKeyEnum.USER_ACTIVE,
                            sortType: sortTypeEnum.ACTIVE_TO_INACTIVE,
                        },
                        {
                            sortKey: sortKeyEnum.ELASTICSEARCH_SCORE,
                            sortType: sortTypeEnum.RELEVANCE_MOST_TO_LEAST,
                        },
                        {
                            sortKey: sortKeyEnum.USER_FULL_NAME,
                            sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                        },
                    ],
                    from: params.from || DEFAULT_PARAMS.from,
                    size: params.size || DEFAULT_PARAMS.size,
                },
            });
        },

        /**
         * Get report search printables
         * @return {Promise<Object[]>}
         */
        getReportSearchExportPrintables() {
            return req({
                method: 'GET',
                url: 'search/export/reports/printables',
            });
        },

        /**
         * Export report search results.
         * @param  {Object} elasticQuery
         * @param  {number[]} layoutIds
         * @return {Promise<Object[]>}
         */
        exportReports(elasticQuery, printingTemplateIds) {
            return req({
                method: 'POST',
                url: 'search/export/reports',
                params: {
                    printing_template_ids: printingTemplateIds,
                },
                data: elasticQuery,
            });
        },

        /**
         * Get warrant search printables
         * @return {Promise<Object[]>}
         */
        getWarrantSearchExportPrintables() {
            return req({
                method: 'GET',
                url: 'search/export/warrants/printables',
            });
        },

        /**
         * Export report search results.
         * @param  {Object} elasticQuery
         * @param  {number[]} layoutIds
         * @return {Promise<Object[]>}
         */
        exportWarrants(elasticQuery, printingTemplateIds) {
            return req({
                method: 'POST',
                url: 'search/export/warrants',
                params: {
                    printing_template_ids: printingTemplateIds,
                },
                data: elasticQuery,
            });
        },

        getPropertySearchExportPrintables() {
            return req({
                method: 'GET',
                url: 'search/export/property/printables',
            });
        },

        exportProperty(elasticQuery, printingTemplateIds) {
            return req({
                method: 'POST',
                url: 'search/export/property',
                params: {
                    printing_template_ids: printingTemplateIds,
                },
                data: elasticQuery,
            });
        },

        exportUsageLogs(elasticQuery, printingTemplateIds) {
            return req({
                method: 'POST',
                url: 'search/export/rms_usage_logs',
                params: { printing_template_ids: printingTemplateIds },
                data: elasticQuery,
            });
        },

        getCourtOrdersSearchExportPrintables() {
            return req({
                method: 'GET',
                url: 'search/export/court-orders/printables',
            });
        },

        exportCourtOrders(elasticQuery, printingTemplateIds) {
            return req({
                method: 'POST',
                url: 'search/export/court-orders',
                params: { printing_template_ids: printingTemplateIds },
                data: elasticQuery,
            });
        },

        getCasesPrintables(caseIds) {
            return req({
                method: 'POST',
                url: `print/case/printables/`,
                data: caseIds,
            });
        },

        updateNotificationSettingsResource(userSearchQuery, userOptIns, userOptOuts) {
            return req({
                method: 'POST',
                url: `notification/update/bulk/begin`,
                data: { userOptOuts, userOptIns, userSearchQuery },
            });
        },
    },
});

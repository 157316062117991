import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { sortFieldConfigurationContextsByContextName } from '../../utils/fieldConfigurationAdminHelpers';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const fieldConfigurationsAdminFormFieldViewModels = {
    // Purposely does not have a `type` property.  None of the fields are
    // inputtable.  So, why is this here?  Because for validation, we always
    // pass around the form model to validators and to help build validation
    // strings.  The `FieldDetail` object is _crucial_; all validation relies on
    // it and a bunch of dynamic messaging depends on it's values.
    // Therefore, we chug around the `fieldDetail` model to pass it along
    // everywhere, even though it is not a "true" "FieldViewModel" since the
    // object is entirely uneditable.
    fieldDetail: {
        key: 'fieldDetail',
        fields: buildFlatFormFieldViewModels([
            { key: 'fieldName' },
            { key: 'className' },
            { key: 'propertyName' },
            { key: 'fieldType' },
            { key: 'fieldTypeMappedId' },
            { key: 'genericFieldType' },
            { key: 'genericFieldTypeMappedId' },
            { key: 'canStaticallyHideInAnyContext' },
            { key: 'logicalMin' },
            { key: 'logicalMax' },
        ]),
    },
    fieldConfiguration: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'fieldConfiguration',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'displayName',
                fieldName: fields.FIELD_CONFIGURATION_DISPLAY_NAME,
            },
            {
                key: 'min',
                fieldName: fields.FIELD_CONFIGURATION_MIN,
            },
            {
                key: 'max',
                fieldName: fields.FIELD_CONFIGURATION_MAX,
            },
        ]),
    },
    fieldConfigurationContexts: {
        type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
        key: 'fieldConfigurationContexts',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'helpText',
                fieldName: fields.FIELD_CONFIGURATION_CONTEXT_HELP_TEXT,
            },
            {
                key: 'placeholderText',
                fieldName: fields.FIELD_CONFIGURATION_CONTEXT_PLACEHOLDER_TEXT,
            },
            {
                key: 'isStaticallyHidden',
                fieldName: fields.FIELD_CONFIGURATION_CONTEXT_IS_STATICALLY_HIDDEN,
            },
        ]),
    },
};

function convertFromFormModel({
    fieldDetail = {},
    fieldConfiguration = {},
    fieldConfigurationContext = {},
}) {
    return { fieldDetail, fieldConfiguration, fieldConfigurationContext };
}

function convertToFormModel({
    fieldDetail = {},
    fieldConfiguration = {},
    fieldConfigurationContexts = {},
}) {
    const sortedFieldConfigurationContexts = sortFieldConfigurationContextsByContextName(
        fieldConfigurationContexts
    );
    return {
        fieldDetail,
        fieldConfiguration,
        fieldConfigurationContexts: sortedFieldConfigurationContexts,
    };
}

export default createFormModule({
    formName: formClientEnum.FIELD_CONFIGURATION_ADMIN,
    fieldViewModels: fieldConfigurationsAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

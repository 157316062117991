import { createSelector } from 'reselect';
import _ from 'lodash';

import {
    buildDisplayStringForStorageLocation,
    buildStorageLocationViewModel,
    buildStorageLocationViewModels,
} from '../../utils/storageLocationHelpers';
import { storageLocationsSelector } from '../data';
import { facilitiesSelector } from '../../../facilities/state/data';

/**
 * All storage view models, keyed by storage location id.
 * @type {Object}
 */
export const storageLocationViewModelsSelector = createSelector(
    storageLocationsSelector,
    facilitiesSelector,
    buildStorageLocationViewModels
);

/**
 * Get a storage location view model by id.
 * @param  {number} [id]
 * @return {Object|undefined}
 */
export const storageLocationViewModelByIdSelector = createSelector(
    storageLocationsSelector,
    facilitiesSelector,
    (storageLocations, facilities) => (id) => {
        const storageLocation = storageLocations[id];
        if (!storageLocation) {
            return;
        }
        return buildStorageLocationViewModel(
            storageLocation,
            buildDisplayStringForStorageLocation(storageLocation, storageLocations, facilities)
        );
    }
);

export const storageLocationHasChildrenSelector = createSelector(
    storageLocationsSelector,
    (storageLocations) => (storageLocationId) => {
        return _.some(storageLocations, ({ parentStorageLocationIds }) => {
            _.includes(parentStorageLocationIds, storageLocationId);
        });
    }
);

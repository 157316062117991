import createNormalizedModule from '../../../../utils/createNormalizedModule';

const exportReleaseTypesModule = createNormalizedModule<unknown>({
    type: 'exportReleaseTypes',
    key: 'id',
});

// ACTIONS
export const storeExportReleaseTypes = exportReleaseTypesModule.actionCreators.storeEntities;

// SELECTORS
export const exportReleaseTypesSelector = exportReleaseTypesModule.selectors.entitiesSelector;
export const exportReleaseTypeByIdSelector = exportReleaseTypesModule.selectors.entityByIdSelector;

// REDUCER
export default exportReleaseTypesModule.reducerConfig;

import React, { PropsWithChildren, useCallback, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { BriefingSimpleView, BriefingView } from '@mark43/rms-api';
import {
    Button,
    ButtonGroup,
    CardHeaderTitle,
    Icon,
    IconButton,
    Menu,
    MenuContent,
    MenuItem,
    MenuTrigger,
    Skeleton,
    SkeletonText,
    Stack,
    VStack,
} from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResource, useResourceDeferred } from '~/client-common/core/hooks/useResource';

import Link from '../../core/components/links/Link';
import BriefingEditor from '../../briefings/components/BriefingEditor';
import briefingsResource from '../../briefings/resources/briefingResource';
import testIds from '../../../core/testIds';

import { BRIEFINGS_ROUTES } from '../../briefings/configuration/routes';
import { DashboardCard, NoDataBlock } from './DashboardSection';

const strings = componentStrings.personalDashboard.MyBriefings;

const Loader = () => (
    <VStack align="top" spacing={3} my={4} px={4}>
        <Stack spacing={3} flex="1">
            <Skeleton height="1rem" />
            <SkeletonText noOfLines={3} />
        </Stack>
        <Stack spacing={3} flex="1">
            <Skeleton width="50%" height="1rem" />
            <Skeleton height="1rem" />
            <SkeletonText noOfLines={2} />
        </Stack>
    </VStack>
);

type DropdownItem = {
    id: number;
    title: string;
};
const Dropdown: React.FC<{
    selectedItem: DropdownItem;
    items: DropdownItem[];
    onSelect: (selected: DropdownItem) => void;
}> = ({ selectedItem, items, onSelect }) => {
    return (
        <Menu>
            <MenuTrigger data-test-id={testIds.BRIEFING_DROPDOWN_MENU_BUTTON} asChild>
                <CardHeaderTitle style={{ cursor: 'pointer' }}>
                    {selectedItem.title}
                    <IconButton
                        aria-label={selectedItem.title}
                        icon="Open"
                        size="sm"
                        variant="ghost"
                    />
                </CardHeaderTitle>
            </MenuTrigger>
            <MenuContent data-test-id={testIds.BRIEFING_DROPDOWN_MENU_CONTENT}>
                {items.map((item) => (
                    <MenuItem
                        key={item.id}
                        leadingVisual={
                            item.id === selectedItem.id ? (
                                <Icon icon="Check" size="sm" color="brand.default" />
                            ) : undefined
                        }
                        onSelect={() => onSelect(item)}
                        data-test-id={testIds.BRIEFING_DROPDOWN_MENU_ITEM}
                    >
                        {item.title}
                    </MenuItem>
                ))}
            </MenuContent>
        </Menu>
    );
};

const MyBriefingsContent: React.FC<
    PropsWithChildren<{
        isLoading: boolean;
        error: string;
    }>
> = ({ isLoading, error, children }) => {
    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <NoDataBlock>{error}</NoDataBlock>;
    }

    if (!children) {
        return <NoDataBlock>{strings.noResults}</NoDataBlock>;
    }

    return <>{children}</>;
};

const convertToDropdownItem = <TItem extends { id: number; title: string }>({
    id,
    title,
}: TItem): DropdownItem => ({
    id,
    title,
});

const useSelectBriefing = (onSuccess: (data: BriefingView) => void) => {
    const fetchBriefing = useCallback((selectedItem: DropdownItem) => {
        return briefingsResource.getBriefing(selectedItem.id, true);
    }, []);

    return useResourceDeferred(fetchBriefing, onSuccess);
};

export const MyBriefings: React.FC<{
    dragHandleProps: DraggableProvidedDragHandleProps;
    isDragging: boolean;
}> = ({ dragHandleProps, isDragging }) => {
    const [myBriefings, setMyBriefings] = useState<BriefingSimpleView[]>([]);
    const [briefing, setBriefing] = useState<BriefingView>();

    const fetchBriefing = useCallback(
        () =>
            briefingsResource.getMyBriefings().then((briefingsSearchView) => {
                const { briefings } = briefingsSearchView;
                if (!briefings.length) {
                    throw new Error(strings.noResults);
                }
                setMyBriefings(briefings);
                return briefingsResource.getBriefing(briefings[0].id, true);
            }),
        []
    );

    const fetchBriefingSuccess = useCallback((data: BriefingView) => {
        setBriefing(data);
    }, []);

    const { isLoading: isLoadingBriefing, errorMessage: errorFetchBriefing } = useResource(
        fetchBriefing,
        fetchBriefingSuccess
    );
    const {
        loading: { isLoading: isLoadingSelectBriefing, errorMessage: errorSelectBriefing },
        callResource: handleDropdownSelect,
    } = useSelectBriefing(fetchBriefingSuccess);

    return (
        <DashboardCard
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
            title={
                briefing ? (
                    <Dropdown
                        selectedItem={convertToDropdownItem(briefing)}
                        items={myBriefings.map(convertToDropdownItem)}
                        onSelect={handleDropdownSelect}
                    />
                ) : (
                    strings.title
                )
            }
            actions={
                <ButtonGroup>
                    {briefing ? (
                        <Link
                            to={BRIEFINGS_ROUTES.item.url(briefing.id)}
                            testId={testIds.BRIEFING_OPEN_BRIEFING_BUTTON}
                        >
                            <Button variant="outline">{strings.openBriefing}</Button>
                        </Link>
                    ) : null}
                    <Link
                        to={BRIEFINGS_ROUTES.root.url()}
                        testId={testIds.BRIEFING_VIEW_ALL_BUTTON}
                    >
                        <Button variant="ghost">{strings.viewAll}</Button>
                    </Link>
                </ButtonGroup>
            }
            testId={testIds.BRIEFING_DASHBOARD_CARD}
            padding={briefing ? '0px' : undefined}
        >
            <MyBriefingsContent
                isLoading={(isLoadingBriefing && !briefing) || isLoadingSelectBriefing}
                error={errorFetchBriefing ?? errorSelectBriefing}
            >
                {briefing ? (
                    <BriefingEditor
                        id={briefing.id}
                        body={briefing.body}
                        mode="view"
                        height="285px"
                    />
                ) : null}
            </MyBriefingsContent>
        </DashboardCard>
    );
};

import React from 'react';
import { compose, setDisplayName } from 'recompose';
import { getDropdownOptionsFromObject } from '~/client-common/helpers/dropdownOptionHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

const { label, options } = componentStrings.forms.select.ApprovalStatusForCaseSelect;
const approvalStatusForCaseOptions = getDropdownOptionsFromObject(options);

/**
 * Dropdown for approval statuses of cases
 * @param {Object} props
 */
function ApprovalStatusForCaseSelect(props) {
    return (
        <Select label={props.label || label} options={approvalStatusForCaseOptions} {...props} />
    );
}

export const RRFApprovalStatusForCaseSelect = compose(
    setDisplayName('RRFApprovalStatusForCaseSelect'),
    connectRRFInput
)(ApprovalStatusForCaseSelect);

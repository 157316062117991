import { combineReducers } from 'redux';

import labelPrintingUiReducer from './label-printing/state/ui';
import evidenceDashboardUiReducer from './dashboard/state/ui';
import inventoriesUiReducer from './inventories/state/ui';
import inventoryUiReducer from './inventory/state/ui';
import itemQueueUiReducer from './item-queue/state/ui';
import itemSplitUiReducer from './item-split/state/ui';
import cpsCardUiReducer from './cps-card/state/ui';

export default combineReducers({
    labelPrinting: labelPrintingUiReducer,
    dashboard: evidenceDashboardUiReducer,
    inventories: inventoriesUiReducer,
    inventory: inventoryUiReducer,
    itemQueue: itemQueueUiReducer,
    itemSplit: itemSplitUiReducer,
    cpsCard: cpsCardUiReducer,
});

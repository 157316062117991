import {
    CodeTypeSourceEnum,
    AttributeTypeEnum,
    CodeTypeCategoryEnum,
    RefContextEnum,
} from '@mark43/rms-api';
import React from 'react';
import { flowRight, slice, forEach, sortBy } from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createFormConfiguration, lifecycleOptions } from 'markformythree';

import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { warrantDexSubmissionHistoriesWhereSelector } from '~/client-common/core/domain/warrant-dex-submission-histories/state/data';
import withFields from '~/client-common/core/fields/components/withFields';
import { CardSection } from '../../../../legacy-redux/components/core/Card';
import Row from '../../../core/components/Row';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import Text, { ArbiterMFTText } from '../../../core/forms/components/Text';
import { ArbiterMFTSelect } from '../../../core/forms/components/selects/Select';
import { ArbiterMFTUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { ArbiterMFTCodeSelect } from '../../../core/forms/components/selects/CodeSelect';
import { ArbiterMFTDatePicker } from '../../../core/forms/components/DatePicker';
import IndentedFields from '../../../core/components/IndentedFields';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';

import { currentUserIdSelector } from '../../../core/current-user/state/ui';

import testIds from '../../../../core/testIds';
import { purposeCodes } from '../config';

const strings = componentStrings.warrants.warrant.WarrantDexSubmissionCard;

const MyselfButton = styled(Button)`
    margin-top: 19px;
`;

export default flowRight(
    connect(
        createStructuredSelector({
            currentUserId: currentUserIdSelector,
            warrantDexSubmissionHistoriesWhere: warrantDexSubmissionHistoriesWhereSelector,
        })
    ),
    withFields([
        fields.WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER,
        fields.WARRANT_DEX_SUBMISSION_NCIC_NUMBER,
    ])
)(function WarrantDexSubmissionForm({
    currentUserId,
    warrantId,
    warrantDexSubmissionHistoriesWhere,
    fieldDisplayNames,
}) {
    const warrantDexSubmissionHistories = sortBy(
        warrantDexSubmissionHistoriesWhere({ warrantId }),
        'historyCreatedDateUtc'
    );

    let previousNCICNumber;
    let previousRegionalMessageSwitchNumber;
    let historyIndex = 1;
    let historyRows = [];

    forEach(warrantDexSubmissionHistories, ({ ncicNumber, regionalMessageSwitchNumber }) => {
        if (
            ncicNumber !== previousNCICNumber ||
            regionalMessageSwitchNumber !== previousRegionalMessageSwitchNumber
        ) {
            const currentIndex = historyIndex++;
            const row = (
                <Row key={currentIndex}>
                    <Text
                        label={`${fieldDisplayNames.WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER} ${currentIndex}`}
                        width={240}
                        textInputOnly={false}
                        disabled={true}
                        placeholder={regionalMessageSwitchNumber}
                    />
                    <Text
                        label={`${fieldDisplayNames.WARRANT_DEX_SUBMISSION_NCIC_NUMBER} ${currentIndex}`}
                        width={240}
                        textInputOnly={false}
                        disabled={true}
                        placeholder={ncicNumber}
                    />
                </Row>
            );
            previousNCICNumber = ncicNumber;
            previousRegionalMessageSwitchNumber = regionalMessageSwitchNumber;
            historyRows.push(row);
        }
    });

    if (historyRows.length > 0) {
        // get rid of the last entry because it will always be the same as the current values
        historyRows = slice(historyRows, 0, historyRows.length - 1);
    }

    const hasExternalDbNumberHistory = historyRows.length > 0;

    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={RefContextEnum.FORM_WARRANT_DEX_SUBMISSION.name}
            context={RefContextEnum.FORM_WARRANT_DEX_SUBMISSION.name}
            configuration={createFormConfiguration({
                id: {},
                internalWarrantStatusAttrId: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_INTERNAL_WARRANT_STATUS_ATTR_ID,
                },
                internalWarrantStatusOther: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_INTERNAL_WARRANT_STATUS_OTHER,
                },
                originatingAgencyCaseNumber: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_ORIGINATING_AGENCY_CASE_NUMBER,
                },
                dexWarrantTypeAttrId: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_DEX_WARRANT_TYPE_ATTR_ID,
                },
                dexWarrantTypeOther: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_DEX_WARRANT_TYPE_OTHER,
                },
                ncicOffenseCodeId: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_NCIC_OFFENSE_CODE_ID,
                },
                ncicOffenseCodeDescription: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_NCIC_OFFENSE_CODE_DESCRIPTION,
                },
                originalNcicOffenseCodeId: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_ORIGINAL_NCIC_OFFENSE_CODE_ID,
                },
                felonyExtraditionLimitationAttrId: {
                    fieldName:
                        fields.WARRANT_DEX_SUBMISSION_ATTRIBUTE_TYPE_WARRANT_FELONY_EXTRADITION_EXTRADITION_LIMITATION_ATTR_ID,
                },
                misdemeanorExtraditionLimitationAttrId: {
                    fieldName:
                        fields.WARRANT_DEX_SUBMISSION_ATTRIBUTE_TYPE_WARRANT_MISDEMEANOR_EXTRADITION_EXTRADITION_LIMITATION_ATTR_ID,
                },
                warrantEntryLevelCodeAttrId: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_ATTR_ID,
                },
                warrantEntryLevelCodeOther: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_OTHER,
                },
                enteredBy: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_ENTERED_BY,
                },
                enteredDateUtc: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_ENTERED_DATE_UTC,
                },
                regionalMessageSwitchNumber: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER,
                },
                ncicNumber: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_NCIC_NUMBER,
                },
                purposeCode: {
                    fieldName: fields.WARRANT_DEX_SUBMISSION_PURPOSE_CODE,
                },
            })}
            render={({ set, resetUi }) => (
                <div>
                    <CardSection>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="internalWarrantStatusAttrId"
                                width={240}
                                attributeType="INTERNAL_WARRANT_STATUS"
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="internalWarrantStatusOther" width={240} />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTText path="originatingAgencyCaseNumber" width={240} />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="dexWarrantTypeAttrId"
                                width={240}
                                attributeType={AttributeTypeEnum.DEX_WARRANT_TYPE.name}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="dexWarrantTypeOther" width={240} />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTCodeSelect
                                path="ncicOffenseCodeId"
                                codeSource={CodeTypeSourceEnum.NCIC.name}
                                codeTypeCategory={CodeTypeCategoryEnum.NCIC_OFFENSE_CODE.name}
                                width={240}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="ncicOffenseCodeDescription" width={240} />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTCodeSelect
                                    path="originalNcicOffenseCodeId"
                                    codeSource={CodeTypeSourceEnum.NCIC.name}
                                    codeTypeCategory={CodeTypeCategoryEnum.NCIC_OFFENSE_CODE.name}
                                    width={240}
                                />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="felonyExtraditionLimitationAttrId"
                                width={240}
                                attributeType={AttributeTypeEnum.WARRANT_FELONY_EXTRADITION.name}
                            />
                            <ArbiterMFTAttributeSelect
                                path="misdemeanorExtraditionLimitationAttrId"
                                width={240}
                                attributeType={
                                    AttributeTypeEnum.WARRANT_MISDEMEANOR_EXTRADITION.name
                                }
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="warrantEntryLevelCodeAttrId"
                                width={240}
                                attributeType={AttributeTypeEnum.WARRANT_ENTRY_LEVEL_CODE.name}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="warrantEntryLevelCodeOther" width={240} />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTUserSelect
                                path="enteredBy"
                                includeOther={false}
                                width={240}
                            />
                            <MyselfButton
                                className={buttonTypes.SECONDARY}
                                onClick={() => {
                                    set('enteredBy', currentUserId);
                                    resetUi('enteredBy');
                                }}
                            >
                                {strings.myself}
                            </MyselfButton>
                        </Row>
                        <Row>
                            <ArbiterMFTDatePicker path="enteredDateUtc" width={120} />
                        </Row>
                        <Row>
                            <ArbiterMFTSelect
                                path="purposeCode"
                                width={240}
                                options={purposeCodes}
                            />
                        </Row>
                    </CardSection>
                    <CardSection
                        title={strings.externalDatabaseIdNumber}
                        testId={testIds.WARRANT_EXTERNAL_DATABASE_SECTION}
                    >
                        {historyRows}
                        <Row>
                            <ArbiterMFTText
                                label={
                                    hasExternalDbNumberHistory
                                        ? `${
                                              fieldDisplayNames.WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER
                                          } ${historyRows.length + 1} (ACTIVE)`
                                        : fieldDisplayNames.WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER
                                }
                                path="regionalMessageSwitchNumber"
                                width={240}
                            />
                            <ArbiterMFTText
                                label={
                                    hasExternalDbNumberHistory
                                        ? `${
                                              fieldDisplayNames.WARRANT_DEX_SUBMISSION_NCIC_NUMBER
                                          } ${historyRows.length + 1} (ACTIVE)`
                                        : fieldDisplayNames.WARRANT_DEX_SUBMISSION_NCIC_NUMBER
                                }
                                path="ncicNumber"
                                width={240}
                            />
                        </Row>
                    </CardSection>
                </div>
            )}
        />
    );
});

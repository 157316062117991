import { compact, includes, filter, find, first, get, last, map, size, some } from 'lodash';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import styled from 'styled-components';

import { unapprovedApprovalStatuses } from '~/client-common/helpers/approvalStatusHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { reportShortTitleViewModelsByRenSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { getReportSnapshotRoute } from '../../../../core/histories/helpers/historyHelpers';
import {
    mostRecentReportSupplementHistoryForReport,
    reportSupplementHistoryIsAfter,
    reportSupplementHistoryIsBefore,
    sortedMostRecentReportSupplementHistoriesByReportId,
    sortedReportSupplementHistories,
} from '../../../../../legacy-redux/helpers/reportSupplementHelpers';
import { renReportSupplementHistoriesSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    offenseReportDefinitionForCurrentDepartmentSelector,
    offenseModifyingSupplementReportDefinitionForCurrentDepartmentSelector,
} from '../../../../core/report-definitions/state/ui';
import {
    Link,
    LinkedRecordContainer,
} from '../../../../records/core/components/sidebar/LinkedRecordComponents';
import LinkedReport from '../../../../records/core/components/sidebar/LinkedReport';
import ReportInfoMessage from '../info-message/ReportInfoMessage';

const strings = componentStrings.reports.core.ReportSupplementInfoMessage;

const PaddedDiv = styled.div`
    padding: 5px;
`;

const SectionTitle = styled.div`
    font-weight: bold;
`;

const offenseSnapshotLink = ({ reportId, rmsEventId, offenseReportDefinitionName }) => (
    <LinkedRecordContainer>
        <Link to={getReportSnapshotRoute({ reportId, rmsEventId })}>
            {strings.offenseReportSnapshotLinkText(offenseReportDefinitionName)}
        </Link>
    </LinkedRecordContainer>
);

const linkedReportRecords = ({
    reportShortTitleViewModels,
    reportSupplementHistories,
    mostRecentOMSReportIsUnapproved,
    mostRecentReportSupplementHistory,
}) =>
    map(reportSupplementHistories, (reportSupplementHistory) => {
        const reportShortTitleViewModel = find(reportShortTitleViewModels, {
            reportId: reportSupplementHistory.reportId,
        });
        const includeApprovalStatusIcon =
            mostRecentOMSReportIsUnapproved &&
            reportSupplementHistory.reportId === mostRecentReportSupplementHistory.reportId;

        return (
            <LinkedReport
                key={reportShortTitleViewModel.reportId}
                reportShortTitleViewModel={reportShortTitleViewModel}
                includeApprovalStatusIcon={includeApprovalStatusIcon}
            />
        );
    });

const isMostRecentOMSReportUnapproved = ({
    reportShortTitleViewModels,
    mostRecentReportSupplementHistory,
}) => {
    const mostRecentOMSReportShortTitleViewModel = find(reportShortTitleViewModels, {
        reportId: mostRecentReportSupplementHistory.reportId,
    });
    const mostRecentOMSReportApprovalStatuses = compact([
        mostRecentOMSReportShortTitleViewModel.approvalStatus,
        mostRecentOMSReportShortTitleViewModel.secondaryApprovalStatus,
    ]);
    return some(mostRecentOMSReportApprovalStatuses, (mostRecentOMSReportApprovalStatus) =>
        includes(unapprovedApprovalStatuses, mostRecentOMSReportApprovalStatus)
    );
};

const buildReportModifiesSection = ({
    renReportSupplementHistories,
    reportShortTitleViewModels,
    currentReport,
    offenseReportDefinition,
    omsReportDefinition,
}) => {
    if (
        currentReport.reportDefinitionId === omsReportDefinition.id &&
        size(renReportSupplementHistories) > 1
    ) {
        const sortedRenReportSupplementHistories = sortedReportSupplementHistories(
            renReportSupplementHistories
        );
        const originalOffenseReportRmsEventId = get(
            first(sortedRenReportSupplementHistories),
            'rmsEventId'
        );
        const offenseReportId = get(
            first(sortedRenReportSupplementHistories),
            'supplementingReportId'
        );
        const sortedMostRecentReportSupplementHistories = sortedMostRecentReportSupplementHistoriesByReportId(
            {
                reportSupplementHistories: renReportSupplementHistories,
            }
        );
        const mostRecentReportSupplementHistory = last(sortedMostRecentReportSupplementHistories);
        const mostRecentOMSReportIsUnapproved = isMostRecentOMSReportUnapproved({
            reportShortTitleViewModels,
            mostRecentReportSupplementHistory,
        });
        const currentOMSReportShortTitleViewModel = find(reportShortTitleViewModels, {
            reportId: currentReport.id,
        });
        const currentOMSReportSupplementHistory = find(sortedMostRecentReportSupplementHistories, {
            reportId: currentReport.id,
        });
        const reportSupplementHistoriesBeforeCurrent = filter(
            sortedMostRecentReportSupplementHistories,
            (sortedMostRecentReportSupplementHistory) =>
                reportSupplementHistoryIsBefore({
                    baseReportSupplementHistory: currentOMSReportSupplementHistory,
                    reportSupplementHistoryToCheck: sortedMostRecentReportSupplementHistory,
                })
        );

        return (
            <PaddedDiv>
                <SectionTitle>
                    {strings.offenseModifyingSupplementReportModifiesTitle(
                        currentOMSReportShortTitleViewModel.shortTitle
                    )}
                </SectionTitle>
                {offenseSnapshotLink({
                    reportId: offenseReportId,
                    rmsEventId: originalOffenseReportRmsEventId,
                    offenseReportDefinitionName: offenseReportDefinition.name,
                })}
                {size(reportSupplementHistoriesBeforeCurrent) > 0 &&
                    linkedReportRecords({
                        reportShortTitleViewModels,
                        reportSupplementHistories: reportSupplementHistoriesBeforeCurrent,
                        mostRecentOMSReportIsUnapproved,
                        mostRecentReportSupplementHistory,
                    })}
            </PaddedDiv>
        );
    }
};

const buildReportModifiedBySection = ({
    renReportSupplementHistories,
    reportShortTitleViewModels,
    currentReport,
    offenseReportDefinition,
    omsReportDefinition,
}) => {
    const sortedRenReportSupplementHistories = sortedReportSupplementHistories(
        renReportSupplementHistories
    );
    const sortedMostRecentReportSupplementHistories = sortedMostRecentReportSupplementHistoriesByReportId(
        {
            reportSupplementHistories: renReportSupplementHistories,
        }
    );
    const mostRecentReportSupplementHistory = last(sortedMostRecentReportSupplementHistories);
    const mostRecentOMSReportIsUnapproved = isMostRecentOMSReportUnapproved({
        reportShortTitleViewModels,
        mostRecentReportSupplementHistory,
    });

    if (currentReport.reportDefinitionId === offenseReportDefinition.id) {
        const showOriginalOffenseReportSnapshotLink =
            size(sortedMostRecentReportSupplementHistories) > 1 || !mostRecentOMSReportIsUnapproved;
        const originalOffenseReportRmsEventId = get(
            first(sortedRenReportSupplementHistories),
            'rmsEventId'
        );

        return (
            <PaddedDiv>
                <SectionTitle>
                    {strings.offenseReportModifiedByTitle(offenseReportDefinition.name)}
                </SectionTitle>
                {showOriginalOffenseReportSnapshotLink &&
                    offenseSnapshotLink({
                        reportId: currentReport.id,
                        rmsEventId: originalOffenseReportRmsEventId,
                        offenseReportDefinitionName: offenseReportDefinition.name,
                    })}
                {linkedReportRecords({
                    reportShortTitleViewModels,
                    reportSupplementHistories: sortedMostRecentReportSupplementHistories,
                    mostRecentOMSReportIsUnapproved,
                    mostRecentReportSupplementHistory,
                })}
            </PaddedDiv>
        );
    } else if (
        currentReport.reportDefinitionId === omsReportDefinition.id &&
        currentReport.id !== mostRecentReportSupplementHistory.reportId
    ) {
        const currentOMSReportShortTitleViewModel = find(reportShortTitleViewModels, {
            reportId: currentReport.id,
        });
        const currentOMSReportSupplementHistory = find(sortedMostRecentReportSupplementHistories, {
            reportId: currentReport.id,
        });
        const reportSupplementHistoriesAfterCurrent = filter(
            sortedMostRecentReportSupplementHistories,
            (sortedMostRecentReportSupplementHistory) =>
                reportSupplementHistoryIsAfter({
                    baseReportSupplementHistory: currentOMSReportSupplementHistory,
                    reportSupplementHistoryToCheck: sortedMostRecentReportSupplementHistory,
                })
        );

        return (
            size(reportSupplementHistoriesAfterCurrent) > 0 && (
                <PaddedDiv>
                    <SectionTitle>
                        {strings.offenseModifyingSupplementReportModifiedByTitle(
                            currentOMSReportShortTitleViewModel.shortTitle
                        )}
                    </SectionTitle>
                    {linkedReportRecords({
                        reportShortTitleViewModels,
                        reportSupplementHistories: reportSupplementHistoriesAfterCurrent,
                        mostRecentOMSReportIsUnapproved,
                        mostRecentReportSupplementHistory,
                    })}
                </PaddedDiv>
            )
        );
    }
};

const buildCombinedOffenseSection = ({
    renReportSupplementHistories,
    reportShortTitleViewModels,
    currentReport,
    offenseReportDefinition,
    omsReportDefinition,
}) => {
    if (currentReport.reportDefinitionId === omsReportDefinition.id) {
        const omsReportSupplementHistory = mostRecentReportSupplementHistoryForReport({
            reportSupplementHistories: renReportSupplementHistories,
            reportId: currentReport.id,
        });
        const offenseReportShortTitleViewModel = find(reportShortTitleViewModels, {
            reportId: get(omsReportSupplementHistory, 'supplementingReportId'),
        });
        return (
            offenseReportShortTitleViewModel && (
                <PaddedDiv>
                    <SectionTitle>
                        {strings.viewCombinedOffenseRecordTitle(offenseReportDefinition.name)}
                    </SectionTitle>
                    <LinkedReport
                        reportShortTitleViewModel={offenseReportShortTitleViewModel}
                        includeApprovalStatusIcon={false}
                    />
                </PaddedDiv>
            )
        );
    }
};

const mapStateToProps = createStructuredSelector({
    renReportSupplementHistories: renReportSupplementHistoriesSelector,
    offenseReportDefinitionForCurrentDepartment: offenseReportDefinitionForCurrentDepartmentSelector,
    offenseModifyingSupplementReportDefinitionForCurrentDepartment: offenseModifyingSupplementReportDefinitionForCurrentDepartmentSelector,
    reportById: reportByIdSelector,
    reportShortTitleViewModelsByRen: reportShortTitleViewModelsByRenSelector,
});

const ReportSupplementInfoMessage = compose(
    connect(mapStateToProps),
    mapProps(
        ({
            reportId,
            isSnapshot,
            renReportSupplementHistories,
            offenseReportDefinitionForCurrentDepartment,
            offenseModifyingSupplementReportDefinitionForCurrentDepartment,
            reportById,
            reportShortTitleViewModelsByRen,
        }) => {
            const currentReport = reportById(reportId) || {};
            const reportShortTitleViewModels = reportShortTitleViewModelsByRen(
                currentReport.reportingEventNumber
            );
            return {
                renReportSupplementHistories,
                reportShortTitleViewModels,
                currentReport,
                offenseReportDefinition: offenseReportDefinitionForCurrentDepartment,
                omsReportDefinition: offenseModifyingSupplementReportDefinitionForCurrentDepartment,
                isSnapshot,
            };
        }
    ),
    renderOnlyIf(
        ({
            renReportSupplementHistories,
            reportShortTitleViewModels,
            currentReport,
            offenseReportDefinition,
            omsReportDefinition,
            isSnapshot,
        }) =>
            !isSnapshot &&
            (currentReport.reportDefinitionId === offenseReportDefinition.id ||
                currentReport.reportDefinitionId === omsReportDefinition.id) &&
            size(renReportSupplementHistories) > 0 &&
            size(reportShortTitleViewModels) > 0
    )
)(function _ReportSupplementInfoMessage({
    renReportSupplementHistories,
    reportShortTitleViewModels,
    currentReport,
    offenseReportDefinition,
    omsReportDefinition,
}) {
    return (
        <ReportInfoMessage>
            {buildReportModifiesSection({
                renReportSupplementHistories,
                reportShortTitleViewModels,
                currentReport,
                offenseReportDefinition,
                omsReportDefinition,
            })}
            {buildReportModifiedBySection({
                renReportSupplementHistories,
                reportShortTitleViewModels,
                currentReport,
                offenseReportDefinition,
                omsReportDefinition,
            })}
            {buildCombinedOffenseSection({
                renReportSupplementHistories,
                reportShortTitleViewModels,
                currentReport,
                offenseReportDefinition,
                omsReportDefinition,
            })}
        </ReportInfoMessage>
    );
});

export default ReportSupplementInfoMessage;

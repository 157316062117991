import { Passport } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'passports';

const passportsModule = createGroupedModule<Passport>({
    type: NEXUS_STATE_PROP,
    key: 'personProfileId',
});

// SELECTORS
export const passportsSelector = passportsModule.selectors.entitiesSelector;
export const passportsByPersonProfileIdSelector = passportsModule.selectors.entitiesByKeySelector;

// REDUCER
export default passportsModule.reducerConfig;

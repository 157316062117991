import _, { isArray, map, values } from 'lodash';

import { EntityTypeEnum } from '@mark43/rms-api';
import fieldTypeClientEnum from '../core/enums/client/fieldTypeClientEnum';
import fieldStrings from '../configs/fieldStrings';

import componentStrings from '../core/strings/componentStrings';
import accountStatusEnum from '../core/enums/client/accountStatusEnum';
import { formatDispositionStatus } from '../core/domain/item-evidence-states/utils/formatDisposition';
import { dateTimeDefaultFormatter } from '../core/dates/utils/dateHelpers';
import { DISPLAY_ONLY_OFFENSE } from '../core/enums/universal/fields';
import { formatClientApprovalStatuses } from './approvalStatusHelpers';
import { joinTruthyValues, prettify } from './stringHelpers';

const {
    ACCOUNT_STATUS,
    ABILITY,
    ATTRIBUTE,
    CALL_FOR_SERVICE,
    CASE_DEFINITION,
    CASE_STATUS,
    CHAIN_EVENT_TYPE, // evidence
    CLIENT_APPROVAL_STATUS,
    CUSTOM_REPORT_CLASSIFICATION,
    DATE,
    DATE_TIME,
    DEPARTMENT,
    DISPATCH_AREA,
    DISPOSITION_STATUS, // evidence
    ENTITY_TYPE,
    FACILITY, // evidence
    FIELDSET,
    LABEL,
    LINK_TYPE,
    N_FIELDSETS,
    N_ITEMS_FIELDSET,
    NIBRS_CODE,
    NIBRS_CODE_ID,
    OFFENSE_CASE_STATUS,
    OFFENSE_CODE,
    OFFICER_INVOLVEMENT,
    RANGE,
    RADIO_CHANNEL,
    REPORT_DEFINITION,
    ROLE,
    STORAGE_LOCATION, // evidence
    SUBDIVISION,
    UCR_CODE,
    USER,
    UNIT,
    UNIT_STATE,
    VEHICLE_MAKE,
    VEHICLE_MODEL,
    AGENCY,
    STATION,
    GANG_NAME,
    INVOLVEMENT_STATUS,
    ATF_MANUFACTURER,
} = fieldTypeClientEnum;

const selectStrings = componentStrings.forms.select;
const accountStatusStrings = componentStrings.admin.userProfile.fieldValues.accountStatus;

// Helper to select a display string given accountStatus enum.
const accountStatusDisplayFromValue = (value) => {
    switch (value) {
        case accountStatusEnum.ACTIVE:
            return accountStatusStrings.active;
        case accountStatusEnum.INACTIVE:
            return accountStatusStrings.inactive;
        default:
            return undefined;
    }
};

/**
 * Return whether the given form data, which may be a single field or multiple
 *   fields in an object (with no `value` properties) is empty. This function
 *   covers the general cases including an N item and you may need to use a
 *   custom function for a complicated data shape like a nested object, or call
 *   this function on a deeper level within your nested form data object.
 * @param  {Object|Object[]} field
 * @return {boolean}
 */
export function formDataIsEmpty(formData) {
    return (
        _.isUndefined(formData) ||
        _.isNull(formData) ||
        formData === '' ||
        (_.isArray(formData) && (formData.length === 0 || nItemFormDataIsEmpty(formData))) ||
        (_.isObject(formData) && _.every(formData, formDataIsEmpty))
    );
}

/**
 * Return whether the given form data representing an N item is empty. This
 *   function covers the general case and you may need to use a custom function
 *   for complicated data shapes.
 * @param  {Object}  formData
 * @return {boolean}
 */
export function nItemFormDataIsEmpty(formData) {
    return _.every(formData, formDataIsEmpty);
}

/**
 * Compute a display string for a field's value.
 * @param  {Object} fieldViewModel
 * @param  {Object} format Display string formatting function that come from
 *   selectors, which are needed when the field value is an id of an object
 *   stored in state.
 * @return {string}
 */
export function formatFieldValue(
    { value, type, grouped } = {},
    {
        formatDispatchAreaById = _.identity,
        formatRadioChannelById = _.identity,
        formatAbilityById = _.identity,
        formatAttributeById = _.identity,
        formatCallForServiceById = _.identity,
        formatCaseDefinitionById = _.identity,
        formatChainEventTypeById = _.identity, // evidence
        formatElasticStorageLocationById = _.identity, // evidence
        formatFacilityById = _.identity, // evidence
        formatFieldByName = _.identity,
        formatLinkTypeById = _.identity,
        formatNibrsCodeByCode = _.identity,
        formatNibrsOffenseCodeById = _.identity,
        formatOffenseCodeById = _.identity,
        formatReportDefinitionById = _.identity,
        formatRoleNameByRoleId = _.identity,
        formatUcrCodeByCode = _.identity,
        formatUserById = _.identity,
        formatUnitById = _.identity,
        formatVehicleMakeById = _.identity,
        formatVehicleModelById = _.identity,
        formatAgencyProfileById = _.identity,
        formatDepartmentById = _.identity,
        stationNamesById = _.identity,
        formatSubdivisionsByIds = _.identity,
        dateTimeFormatter = dateTimeDefaultFormatter,
    } = {}
) {
    // special field types
    switch (type) {
        case ACCOUNT_STATUS:
            return accountStatusDisplayFromValue(value);
        case CLIENT_APPROVAL_STATUS:
            return formatClientApprovalStatuses(value);
        case DISPATCH_AREA:
            return formatDispatchAreaById(value);
        case RADIO_CHANNEL:
            return formatRadioChannelById(value);
        case ATTRIBUTE:
            return formatAttributeById(value, true, grouped);
        case STATION:
            return stationNamesById(value);
        case CALL_FOR_SERVICE:
            return formatCallForServiceById(value);
        case CASE_DEFINITION:
            return formatCaseDefinitionById(value);
        case CASE_STATUS:
            return value === false || value === 'false'
                ? selectStrings.CaseStatusSelect.options.hasCaseStatus.false
                : formatAttributeById(value);
        case CHAIN_EVENT_TYPE: // evidence
            return formatChainEventTypeById(value);
        case CUSTOM_REPORT_CLASSIFICATION:
            const offenseDisplayName = formatFieldByName
                ? formatFieldByName(DISPLAY_ONLY_OFFENSE)
                : 'Offense';
            return _.includes(value, 'false')
                ? selectStrings.CustomReportClassificationAttrSelect.options.hasCustomReportClassificationAttrId.false(
                      offenseDisplayName
                  )
                : _.includes(value, 'true')
                ? selectStrings.CustomReportClassificationAttrSelect.options.hasCustomReportClassificationAttrId.true(
                      offenseDisplayName
                  )
                : formatAttributeById(value);
        case DATE:
            return dateTimeFormatter.formatDate(value);
        case DATE_TIME:
            return dateTimeFormatter.formatDateTime(value);
        case DISPOSITION_STATUS: // evidence
            return formatDispositionStatus(value);
        case ENTITY_TYPE:
            return _.map(value, (val) => {
                switch (val) {
                    case EntityTypeEnum.PERSON_PROFILE.name:
                        return 'Person';
                    case EntityTypeEnum.ITEM_PROFILE.name:
                        return 'Item';
                    default:
                        return prettify(val);
                }
            }).join(', ');
        case FACILITY: // evidence
            return formatFacilityById(value);
        case INVOLVEMENT_STATUS:
            return prettify(value);
        case LABEL:
            return _.includes(value, false) || value === false
                ? selectStrings.LabelsWithNoneAttrSelect.options.hasNoLabels
                : '';
        case LINK_TYPE:
            return formatLinkTypeById(value);
        case NIBRS_CODE:
            return formatNibrsCodeByCode(value);
        case NIBRS_CODE_ID:
            return formatNibrsOffenseCodeById(value);
        case OFFENSE_CASE_STATUS: {
            const offenseDisplayName = formatFieldByName
                ? formatFieldByName(DISPLAY_ONLY_OFFENSE)
                : 'Offense';
            return _.includes(value, 'false')
                ? selectStrings.OffenseCaseStatusAttrSelect.options.hasOffenseCaseStatus.false(
                      offenseDisplayName
                  )
                : _.includes(value, 'true')
                ? selectStrings.OffenseCaseStatusAttrSelect.options.hasOffenseCaseStatus.true(
                      offenseDisplayName
                  )
                : formatAttributeById(value);
        }
        case OFFENSE_CODE:
            return formatOffenseCodeById({ id: value, includeCode: false });
        case OFFICER_INVOLVEMENT:
            return selectStrings.OfficerInvolvementSelect.options[value];
        case REPORT_DEFINITION:
            return formatReportDefinitionById(value);
        case ROLE:
            return formatRoleNameByRoleId(value);
        case STORAGE_LOCATION: // evidence
            return formatElasticStorageLocationById(value);
        case SUBDIVISION:
            return formatSubdivisionsByIds(value, true);
        case UCR_CODE:
            return _.includes(value, 'false')
                ? selectStrings.UcrCodeSelect.options.hasUcrCode.false
                : _.includes(value, 'true')
                ? selectStrings.UcrCodeSelect.options.hasUcrCode.true
                : formatUcrCodeByCode(value);
        case USER:
            return formatUserById(value);
        case VEHICLE_MAKE:
            return formatVehicleMakeById(value);
        case VEHICLE_MODEL:
            return formatVehicleModelById(value);
        case AGENCY:
            return formatAgencyProfileById(value);
        case DEPARTMENT:
            return formatDepartmentById(value);
        case UNIT:
            return formatUnitById(value);
        case ABILITY:
            return formatAbilityById(value);
        case GANG_NAME:
            return selectStrings.GangNameSelect.anyGang;
        default:
            break;
    }

    // basic field types
    if (_.isString(value)) {
        return value;
    } else if (_.isNumber(value)) {
        return value.toString();
    } else if (_.isBoolean(value)) {
        return value ? 'Yes' : 'No';
    } else if (_.isArray(value)) {
        return value.join(', ');
    }
}

/**
 * Recursively filter for non-empty form data in the given object or array; all
 *   empty data gets removed in the resulting object or array.
 * @param  {Object|Object[]} formData
 * @return {Object|Object[]} Filtered form data.
 */
export function filterFormData(formData) {
    // must use different lodash methods depending on whether the data is an
    // object or array
    // NOTE: omit has undergone breaking changes in 4.17.2. The correct function
    // in 4.17.2 is omitBy which does not exist in 3.10.1. Use omitBy if available,
    // otherwise fallback to omit.
    const [mapCollection, rejectCollection] = _.isArray(formData)
        ? [_.map, _.reject]
        : [_.mapValues, _.omitBy || _.omit];

    return rejectCollection(
        mapCollection(formData, (data) => {
            if (_.isObject(data)) {
                // recursive case: array field, N items, or nested object fields
                return filterFormData(data);
            } else {
                // flat non-array field
                return data;
            }
        }),
        formDataIsEmpty
    );
}

/**
 * Build form model state from data and field view models.
 * @param  {Object|Object[]} [data]
 * @param  {Object}          [fieldViewModels] May be nested.
 * @return {Object|Object[]}
 */
export function buildFormModel(data = {}, fieldViewModels) {
    const mapCollection = _.isArray(data) ? _.map : _.mapValues;

    return filterFormData(
        mapCollection(fieldViewModels, (fieldViewModel = {}, index) => {
            const { type, key, fields } = fieldViewModel;

            if (type === N_FIELDSETS || type === N_ITEMS_FIELDSET) {
                // array
                return _.map(data[index], (item) => buildFormModel(item, fields));
            } else if (type === FIELDSET) {
                // nested fields with a parent structure (fieldset), where the field
                // view models are one level deeper
                return buildFormModel(data[index], fields);
            } else if (key) {
                // single field
                return data[index];
            } else {
                // nested fields without parent structure
                return buildFormModel(data[index], fieldViewModel);
            }
        })
    );
}

/**
 * Build form field objects with useful properties such as labels. No nested
 *   fields allowed.
 * @param  {Array} fieldsOrKeys Each element may be a string (a field key) or an
 *   object with `key` being the only required property.
 * @return {Object} Object with field keys.
 */
export function buildFlatFormFieldViewModels(fieldsOrKeys) {
    return _(fieldsOrKeys)
        .map((fieldOrKey) => {
            if (_.isString(fieldOrKey)) {
                // only the key is provided
                const key = fieldOrKey;
                return {
                    key,
                    label: fieldStrings[key],
                };
            } else {
                const field = fieldOrKey;

                // compute the field's label
                let label;
                if (_.isString(field.label) || _.isFunction(field.label)) {
                    label = field.label;
                } else if (field.fieldName && !field.label) {
                    label = undefined;
                } else if (field.type === RANGE) {
                    label = fieldStrings.ranges[field.rangeKey];
                } else {
                    label = fieldStrings[field.key];
                }

                return {
                    ...field,
                    label,
                };
            }
        })
        .mapKeys('key')
        .value();
}

/**
 * Compute a display string for a field's value.
 * @param  {*}      [value]
 * @param  {Object} fieldViewModel
 * @param  {Object} format Display string formatting function that come from
 *   selectors, which are needed when the field value is an id of an object
 *   stored in state.
 * @return {string}
 */
export function formatFieldValueRRF(
    value,
    { type, grouped } = {},
    {
        formatDispatchAreaById = _.identity,
        formatRadioChannelById = _.identity,
        formatAbilityById = _.identity,
        formatAttributeById = _.identity,
        formatCallForServiceById = _.identity,
        formatCaseDefinitionById = _.identity,
        formatChainEventTypeById = _.identity, // evidence
        formatElasticStorageLocationById = _.identity, // evidence
        formatFacilityById = _.identity, // evidence
        formatFieldByName = _.identity,
        formatLinkTypeById = _.identity,
        formatNibrsCodeByCode = _.identity,
        formatNibrsOffenseCodeById = _.identity,
        formatOffenseCodeById = _.identity,
        formatReportDefinitionById = _.identity,
        formatRoleNameByRoleId = _.identity,
        formatUcrCodeByCode = _.identity,
        formatUserById = _.identity,
        formatUnitById = _.identity,
        formatUnitStateById = _.identity,
        formatVehicleMakeById = _.identity,
        formatVehicleModelById = _.identity,
        formatAgencyProfileById = _.identity,
        formatDepartmentById = _.identity,
        stationNamesById = _.identity,
        formatSubdivisionsByIds = _.identity,
        dateTimeFormatter = dateTimeDefaultFormatter,
        formatAtfManufacturerById = _.identity,
    } = {}
) {
    // special field types
    switch (type) {
        case ACCOUNT_STATUS:
            return accountStatusDisplayFromValue(value);
        case CLIENT_APPROVAL_STATUS:
            return formatClientApprovalStatuses(value);
        case DISPATCH_AREA:
            return formatDispatchAreaById(value);
        case RADIO_CHANNEL:
            return formatRadioChannelById(value);
        case STATION:
            return stationNamesById(value);
        case ATTRIBUTE:
            return formatAttributeById(value, true, grouped);
        case CALL_FOR_SERVICE:
            return formatCallForServiceById(value);
        case CASE_DEFINITION:
            return formatCaseDefinitionById(value);
        case CASE_STATUS:
            return value === false || value === 'false'
                ? selectStrings.CaseStatusSelect.options.hasCaseStatus.false
                : formatAttributeById(value);
        case CHAIN_EVENT_TYPE: // evidence
            return formatChainEventTypeById(value);
        case CUSTOM_REPORT_CLASSIFICATION: {
            const offenseDisplayName = formatFieldByName
                ? formatFieldByName(DISPLAY_ONLY_OFFENSE)
                : 'Offense';
            return _.includes(value, 'false')
                ? selectStrings.CustomReportClassificationAttrSelect.options.hasCustomReportClassificationAttrId.false(
                      offenseDisplayName
                  )
                : _.includes(value, 'true')
                ? selectStrings.CustomReportClassificationAttrSelect.options.hasCustomReportClassificationAttrId.true(
                      offenseDisplayName
                  )
                : formatAttributeById(value);
        }
        case DATE:
            return dateTimeFormatter.formatDate(value);
        case DATE_TIME:
            return dateTimeFormatter.formatDateTime(value);
        case DISPOSITION_STATUS: // evidence
            return formatDispositionStatus(value);
        case ENTITY_TYPE:
            return _.map(value, (val) => {
                switch (val) {
                    case EntityTypeEnum.PERSON_PROFILE.name:
                        return 'Person';
                    case EntityTypeEnum.ITEM_PROFILE.name:
                        return 'Item';
                    default:
                        return prettify(val);
                }
            }).join(', ');
        case FACILITY: // evidence
            return formatFacilityById(value);
        case INVOLVEMENT_STATUS:
            return prettify(value);
        case LABEL:
            return _.includes(value, false) || value === false
                ? selectStrings.LabelsWithNoneAttrSelect.options.hasNoLabels
                : '';
        case LINK_TYPE:
            return formatLinkTypeById(value);
        case NIBRS_CODE:
            return formatNibrsCodeByCode(value);
        case NIBRS_CODE_ID:
            return formatNibrsOffenseCodeById(value);
        case OFFENSE_CASE_STATUS: {
            const offenseDisplayName = formatFieldByName
                ? formatFieldByName(DISPLAY_ONLY_OFFENSE)
                : 'Offense';
            return _.includes(value, 'false')
                ? selectStrings.OffenseCaseStatusAttrSelect.options.hasOffenseCaseStatus.false(
                      offenseDisplayName
                  )
                : _.includes(value, 'true')
                ? selectStrings.OffenseCaseStatusAttrSelect.options.hasOffenseCaseStatus.true(
                      offenseDisplayName
                  )
                : formatAttributeById(value);
        }
        case OFFENSE_CODE:
            return formatOffenseCodeById({ id: value, includeCode: false });
        case OFFICER_INVOLVEMENT:
            return selectStrings.OfficerInvolvementSelect.options[value];
        case REPORT_DEFINITION:
            return formatReportDefinitionById(value);
        case ROLE:
            return formatRoleNameByRoleId(value);
        case STORAGE_LOCATION: // evidence
            return formatElasticStorageLocationById(value);
        case SUBDIVISION:
            return formatSubdivisionsByIds(value, true);
        case UCR_CODE:
            return _.includes(value, 'false')
                ? selectStrings.UcrCodeSelect.options.hasUcrCode.false
                : _.includes(value, 'true')
                ? selectStrings.UcrCodeSelect.options.hasUcrCode.true
                : formatUcrCodeByCode(value);
        case USER:
            return formatUserById(value);
        case UNIT:
            return formatUnitById(value);
        case UNIT_STATE:
            return formatUnitStateById(value);
        case VEHICLE_MAKE:
            return formatVehicleMakeById(value);
        case ATF_MANUFACTURER:
            return formatAtfManufacturerById(value);
        case VEHICLE_MODEL:
            return formatVehicleModelById(value);
        case AGENCY:
            return formatAgencyProfileById(value);
        case DEPARTMENT:
            return formatDepartmentById(value);
        case ABILITY:
            return formatAbilityById(value);
        case GANG_NAME:
            return selectStrings.GangNameSelect.anyGang;
        default:
            break;
    }

    // basic field types
    if (_.isString(value)) {
        return value;
    } else if (_.isNumber(value)) {
        return value.toString();
    } else if (_.isBoolean(value)) {
        return value ? 'Yes' : 'No';
    } else if (_.isArray(value)) {
        return value.join(', ');
    }
}

export function formatNItemFieldValues(
    fields,
    fieldViewModels = {},
    formatFieldValue,
    formatFieldByName
) {
    return _(fields)
        .map((field, key) => {
            const fieldConfig = fieldViewModels[key];
            const fieldNameForFilterLabel = _.get(fieldConfig, 'fieldNameForFilterLabel');
            if (field === undefined || field === null) {
                return undefined;
            }

            return joinTruthyValues(
                [
                    formatFieldByName && fieldNameForFilterLabel
                        ? formatFieldByName(fieldNameForFilterLabel)
                        : _.get(fieldConfig, 'label'),
                    formatFieldValue(field, fieldConfig),
                ],
                ': '
            );
        })
        .compact()
        .join(', ');
}

const removeHiddenValues = (model, ui) => {
    const newModel = { ...model };
    for (const key in model) {
        let isFieldHidden;
        if (ui[key]['$']) {
            isFieldHidden = ui[key]['$'].hidden;
        } else {
            isFieldHidden = ui[key].hidden;
        }

        if (isFieldHidden) {
            newModel[key] = isArray(newModel[key]) ? [] : undefined;
        }
    }
    return newModel;
};

/**
 * Filter hidden value in Form Model of NItems
 * @param  {Array} formModel
 * @param  {Object} formUI
 * @return {Array}
 */
export const filterNitemsHiddenValues = (modelValues, uiValues) => {
    return map(modelValues, (modelValue, index) => {
        return removeHiddenValues(modelValue, values(uiValues)[index]);
    });
};

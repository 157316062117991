import React, { useRef } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { ReportApprovalStatusResponse, ReportStatusView } from '@mark43/rms-api';
import withFields from '~/client-common/core/fields/components/withFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import { DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW } from '~/client-common/core/enums/universal/fields';
import { Field } from '~/client-common/core/fields/state/config';
import { Button as ArcButton, ButtonGroup } from '../../../../core/components/Button';
import testIds from '../../../../../core/testIds';
import { useFocusOnUnmountCallback } from '../../../../core/utils/eventHelpers';
import { approveReport } from '../../state/ui/submissions';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';
import CardWithApprovalStatusIcon from './CardWithApprovalStatusIcon';
import SubmissionErrorModal from './SubmissionErrorModal';

const strings = componentStrings.reports.core.ReportStatusCommentsCard;

interface SubmittedCardOuterPropsT {
    reportStatusView: ReportStatusView;
    className?: string;
    rejectReport: (callback: () => void) => void;
    disabled?: boolean;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
}
interface SubmittedCardInnerPropsT extends SubmittedCardOuterPropsT {
    approveReport: () => Promise<ReportApprovalStatusResponse>;
    fieldDisplayNames: Record<Field, string>;
}

/**
 * Approval status card in the "submitted" state. This is a yellow card that is await approval
 */
function SubmittedCard({
    reportStatusView,
    className,
    approveReport,
    rejectReport,
    disabled,
    reportSealingInfo,
    fieldDisplayNames,
}: SubmittedCardInnerPropsT) {
    const approveRef = useRef(null);
    const rejectReportCallback = useFocusOnUnmountCallback(rejectReport, approveRef);
    return (
        <CardWithApprovalStatusIcon
            testId={testIds.REPORT_STATUS_COMMENTS_SUBMITTED_CARD}
            approvalStatus={approvalStatusClientEnum.SUBMITTED}
            reportSealingInfo={reportSealingInfo}
            title={fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW}
            className={className}
            customSummaryModeColor={'lightYellow'}
            customSummaryModeBorderColor={'brightYellow'}
            latestHistoryText={strings.submitted.by}
        >
            <p>
                {map(reportStatusView.reviewableErrors, (error, i) => {
                    return <div key={i}>{error}</div>;
                })}
            </p>
            {(reportStatusView.canReview || reportStatusView.canReject) && !disabled && (
                <ButtonGroup style={{ marginTop: 'var(--arc-space-4)' }}>
                    <ArcButton
                        isTextTransformNone
                        leadingVisual="Check"
                        variant="solid"
                        testId={testIds.REPORT_STATUS_COMMENTS_SUBMITTED_CARD_APPROVE_REPORT_BUTTON}
                        isDisabled={!reportStatusView.canReview}
                        onClick={approveReport}
                        ref={approveRef}
                    >
                        {strings.core.approve}
                    </ArcButton>
                    <SubmissionErrorModal />
                    <ArcButton
                        leadingVisual="CloseX"
                        isTextTransformNone
                        testId={testIds.REPORT_STATUS_COMMENTS_SUBMITTED_CARD_REJECT_REPORT_BUTTON}
                        isDisabled={!reportStatusView.canReject}
                        variant="outline"
                        onClick={rejectReportCallback}
                    >
                        {strings.core.reject}
                    </ArcButton>
                </ButtonGroup>
            )}
        </CardWithApprovalStatusIcon>
    );
}

export default compose<SubmittedCardInnerPropsT, SubmittedCardOuterPropsT>(
    withFields([DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW]),
    connect(null, {
        approveReport,
    })
)(SubmittedCard);

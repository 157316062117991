import { SystemRuleEnum } from '@mark43/rms-api';
import _ from 'lodash';
import { statusIsClosedSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import addRuleId from '../../../../../legacy-redux/validation/helpers/addRuleId';

export const reportCaseStatusClosedByDivisionRequiredIfStatusClosed = addRuleId(
    SystemRuleEnum.REPORT_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID_REQUIRED_IF_STATUS_CLOSED.name,
    (formData, value, state) => {
        const { reportCaseStatuses } = formData;
        return _(reportCaseStatuses)
            .compact()
            .every((status) => {
                const isClosed = statusIsClosedSelector(state)(status.caseStatusAttrId);
                return !isClosed || status.closedByDivisionAttrId;
            });
    }
);

export const reportCaseStatusClosedByUnitRequiredIfStatusClosed = addRuleId(
    SystemRuleEnum.REPORT_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID_REQUIRED_IF_STATUS_CLOSED.name,
    (formData, value, state) => {
        const { reportCaseStatuses } = formData;
        return _(reportCaseStatuses)
            .compact()
            .every((status) => {
                const isClosed = statusIsClosedSelector(state)(status.caseStatusAttrId);
                return !isClosed || status.closedByUnitAttrId;
            });
    }
);

export const reportCaseStatusStatusDateRequiredIfStatusClosed = addRuleId(
    SystemRuleEnum.REPORT_CASE_STATUS_STATUS_DATE_UTC_REQUIRED_IF_STATUS_CLOSED.name,
    (formData, value, state) => {
        const { reportCaseStatuses } = formData;
        return _(reportCaseStatuses)
            .compact()
            .every((status) => {
                const isClosed = statusIsClosedSelector(state)(status.caseStatusAttrId);
                return !isClosed || status.statusDateUtc;
            });
    }
);

import { AttributeTypeEnum } from '@mark43/rms-api';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Fieldset, Observer, Fields } from 'markformythree';
import { compose } from 'recompose';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { proofOfOwnershipOtherLabelSelector } from '~/client-common/core/fields/state/config/';
import pillLayoutEnum from '~/client-common/core/enums/client/pillLayoutEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import Row from '../../../../../core/components/Row';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import testIds from '../../../../../../core/testIds';
import NameSummaryViewWrapper from '../../../../../core/components/NameSummaryViewWrapper';
import { DefaultDateEffectiveFromToCurrent } from '../../../../../core/names/util/nameFormHelpers';

const strings = componentStrings.reports.core.AssociatedNamesFieldset;
const namesToShow = { people: true, organizations: true };

/**
 * @param  {Object[]} props.nameItemLinks new items have no item profile id, therefore
 *                                        links are stubbed from the form model
 * @param  {Number} props.ownerType This should always be `REPORT` as of 5/9/2019
 * @param  {Number} props.ownerId This is the ownerType's id
 * @param  {Number|String} props.reportingEventNumber The owner's reportingEventNumber
 */
function AssociatedNamesFieldset({
    form,
    proofOfOwnershipOtherLabel,
    nameItemLinks,
    ownerType,
    ownerId,
    reportingEventNumber,
}) {
    const onAddSuccess = useCallback(
        ({ nameId, entityType }) => form.push('nameItemLinks', { nameId, entityType }),
        [form]
    );
    const onDelete = useCallback((link, index) => form.remove('nameItemLinks', index), [form]);
    const renderAssociatedFieldset = useCallback(
        ({ nameLink, index }) => {
            return (
                <div key={index}>
                    <Fieldset path={`nameItemLinks[${index}]`}>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="nameItemAssociationAttrId"
                                attributeType={AttributeTypeEnum.NAME_ITEM_ASSOCIATION.name}
                                length="md"
                            />
                            <ArbiterMFTText length="md" path="nameItemAssociationOther" />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="proofOfOwnershipAttrId"
                                attributeType={AttributeTypeEnum.PROOF_OF_OWNERSHIP.name}
                                length="md"
                            />
                            <ArbiterMFTText
                                label={proofOfOwnershipOtherLabel(nameLink.proofOfOwnershipAttrId)}
                                length="md"
                                path="proofOfOwnershipOther"
                            />
                        </Row>
                        <Row>
                            <Observer
                                subscriptions={{
                                    id: [`nameItemLinks[${index}].id`, Fields.MODEL],
                                    dateNonPrefillConditions: [
                                        `nameItemLinks[${index}].dateEffectiveFrom`,
                                        [Fields.DIRTY],
                                    ],
                                }}
                                render={({ id, dateNonPrefillConditions }) => {
                                    return (
                                        <DefaultDateEffectiveFromToCurrent
                                            entityId={id}
                                            form={form}
                                            NItemPath={`nameItemLinks[${index}]`}
                                            dateNonPrefillConditions={dateNonPrefillConditions}
                                        />
                                    );
                                }}
                            />
                            <ArbiterMFTDatePicker
                                path="dateEffectiveTo"
                                variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                            />
                        </Row>
                    </Fieldset>
                </div>
            );
        },
        [proofOfOwnershipOtherLabel, form]
    );

    const organizationLabel = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];

    return (
        <SidePanelSection
            title={strings.addItem(organizationLabel)}
            testId={testIds.ITEM_SIDE_PANEL_ASSOCIATED_NAMES_SECTION}
        >
            <NameSummaryViewWrapper
                renForRecents={reportingEventNumber}
                personOverlayIdPrefix={overlayIdEnum.ITEM_SIDE_PANEL_ASSOCIATED_PERSONS}
                organizationOverlayIdPrefix={overlayIdEnum.ITEM_SIDE_PANEL_ASSOCIATED_ORGANIZATIONS}
                addNameButtonText={strings.addAssociatedPerson}
                // owner values are the report, passing down for quick add.
                // ignored for actual parent link because parent should be item.
                parentEntityType={ownerType}
                parentId={ownerId}
                show={namesToShow}
                showExpandCollapseButton={false}
                onAddSuccess={onAddSuccess}
                renderAdditionalItem={renderAssociatedFieldset}
                stubbedNameItemLinks={nameItemLinks}
                onDelete={onDelete}
                quickAddLayout={pillLayoutEnum.FULL_WIDTH}
            />
        </SidePanelSection>
    );
}

export default compose(
    connect(
        createStructuredSelector({ proofOfOwnershipOtherLabel: proofOfOwnershipOtherLabelSelector })
    )
)(AssociatedNamesFieldset);

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { map, compact } from 'lodash';

import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data/';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import { clearfix } from '../../../../core/styles/mixins';
import Link from '../../../../core/components/links/Link';
import ButtonCopyToClipboard from '../../../../core/components/ButtonCopyToClipboard';

const strings = componentStrings.core.header.RecordsHeader;

const Subtitle = styled.div`
    display: flex;
    grid-gap: 0 var(--arc-space-2);
    flex-wrap: wrap;
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.darkGrey};
`;

const RecordsSubtitleKey = styled.span`
    text-transform: uppercase;
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    margin-right: 8px;
`;

const RecordSubtitleKeyValueContainer = styled.span`
    display: flex;
`;

export function RecordsSubtitleKeyValue({ name, value, link, testId, canCopyToClipboard = false }) {
    if (!value) {
        return null;
    }
    return (
        <RecordSubtitleKeyValueContainer>
            <RecordsSubtitleKey>{name}</RecordsSubtitleKey>
            {link ? (
                <Link to={link} hoverOnUnderline={true}>
                    {value}
                </Link>
            ) : (
                <span data-test-id={testId}>{value}</span>
            )}
            {canCopyToClipboard && <ButtonCopyToClipboard message={name} dataToCopy={value} />}
        </RecordSubtitleKeyValueContainer>
    );
}

const AgencyInfo = ({
    departmentId,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}) => {
    const isCrossAgency = departmentId !== currentUserDepartmentId;
    if (!isCrossAgency) {
        return null;
    }

    return (
        <RecordsSubtitleKeyValue
            name={strings.agency}
            value={departmentNameFromConsortiumLinksByDepartmentId(departmentId)}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
    applicationSettings: applicationSettingsSelector,
});

function RecordsHeader({
    subtitleParts = [],
    departmentId,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}) {
    const subtitleKeyValues = compact(
        map(subtitleParts, ({ name, value, link, testId, canCopyToClipboard }) => (
            <RecordsSubtitleKeyValue
                key={`${name}~${value}`}
                name={name}
                value={value}
                link={link}
                testId={testId}
                canCopyToClipboard={canCopyToClipboard}
            />
        ))
    );

    return (
        <Subtitle>
            {subtitleKeyValues}
            <AgencyInfo
                departmentId={departmentId}
                currentUserDepartmentId={currentUserDepartmentId}
                departmentNameFromConsortiumLinksByDepartmentId={
                    departmentNameFromConsortiumLinksByDepartmentId
                }
            />
        </Subtitle>
    );
}

/**
 * Header for all "records", which includes reports, warrants, and cases.
 *
 * Children are rendered on the far right, and are typically a handful of
 *   `RecordsHeaderButton`s followed by one `RecordsHeaderHamburgerMenu`.
 */
export default styled(connect(mapStateToProps)(RecordsHeader))`
    ${clearfix};
`;

import actionTypes from '../actions/types/recentEntitiesActionTypes';

/**
 * The shape of this state is recent entities keyed by parent entity id (for a
 *   report, this is the reporting event id and not the report id since it
 *   shares recent entities with all other reports in the event) and then by
 *   entity type (such as PERSON_PROFILE).
 */
export function recentEntitiesDataReducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.REQUEST_RECENT_ENTITIES_SUCCESS:
            const { renForRecents, recentEntityType, recentEntities } = action.payload;

            return {
                ...state,
                [renForRecents]: {
                    ...state[renForRecents],
                    [recentEntityType]: recentEntities,
                },
            };
        default:
            return state;
    }
}

import {
    ReportDefinition,
    ReportStatusHistory,
    ClientApprovalStatusEnum,
    ApprovalStatusEnum,
} from '@mark43/rms-api';
import { chain, find, findLastIndex, slice, sortBy } from 'lodash';
import reportApprovalLevelClientEnum from '../core/enums/client/reportApprovalLevelClientEnum';

export function convertReportApprovalLevelToReportDefinitionFields(
    reportApprovalLevel: keyof typeof reportApprovalLevelClientEnum
) {
    switch (reportApprovalLevel) {
        case reportApprovalLevelClientEnum.NONE:
            return {
                terminalApprovalStatus: ApprovalStatusEnum.SUBMITTED.name,
                isSecondaryApprovalRequired: false,
            };
        case reportApprovalLevelClientEnum.ONE:
            return {
                terminalApprovalStatus: ApprovalStatusEnum.APPROVED.name,
                isSecondaryApprovalRequired: false,
            };
        case reportApprovalLevelClientEnum.TWO:
            return {
                terminalApprovalStatus: ApprovalStatusEnum.APPROVED.name,
                isSecondaryApprovalRequired: true,
            };
        default:
            return {};
    }
}

export function convertReportDefinitionFieldsToReportApprovalLevelClientEnum(
    reportDefinition: ReportDefinition
) {
    const { terminalApprovalStatus, isSecondaryApprovalRequired } = reportDefinition;

    if (
        terminalApprovalStatus === ApprovalStatusEnum.SUBMITTED.name &&
        !isSecondaryApprovalRequired
    ) {
        return reportApprovalLevelClientEnum.NONE;
    }

    // ReportDefinition.terminalApprovalStatus is expected to always be either SUBMITTED or
    // APPROVED through being saved in the report types admin page. The reason we don't check for
    // terminalApprovalStatus === APPROVED here is because the database can still store other
    // values for this field, such as APPROVED2 or COMPLETED, and the only distinguishing factor
    // between ONE and TWO is the boolean isSecondaryApprovalRequired.
    return !isSecondaryApprovalRequired
        ? reportApprovalLevelClientEnum.ONE
        : reportApprovalLevelClientEnum.TWO;
}

/*
    This method will return the supervisor approval's ReportStatusHistory event
    from the array of all report status history events.

    Report has 2 levels of approval:
        - We will retrieve the first Report Status History that has the clientApprovalStatus
          set to PENDING_SECONDARY_REVIEW. There could be a case where the Records Admin
          staff can put this into this state and when that happens we want to make sure that
          we always retrieve the first person who put it in this state since that will always be
          the supervisor. This is why we can't run a `reverse()` since that would return the last
          person who placed it in PENDING_SECONDARY_REVIEW. We want to always return the first one.

    Report has 1 level of approval:
        - We retrive the latest report status history record that put this in the COMPLETED state.
          Since this report can be placed into draft again, by retrieving the latest report status
          history we guarantee that we always get the most recent supervisor that approved this report.

    Report has 0 levels of approval:
        - We return undefined since the report did not require any levels of approval.
*/
export function deriveSupervisorApprovalStatusFromReportStatusHistories(
    reportApprovalLevel: keyof typeof reportApprovalLevelClientEnum,
    reportStatusHistories: ReportStatusHistory[]
): ReportStatusHistory | undefined {
    const sortedReportStatusHistories = sortBy(reportStatusHistories, ['updatedDateUtc'], ['desc']);

    switch (reportApprovalLevel) {
        case reportApprovalLevelClientEnum.TWO:
            const draftReportHistoryRecord = find(sortedReportStatusHistories, {
                clientApprovalStatus: ClientApprovalStatusEnum.DRAFT.name,
            });

            if (draftReportHistoryRecord) {
                // Get the index of the last DRAFT record
                const lastDraftReportStatusHistoryIndex = findLastIndex(
                    sortedReportStatusHistories,
                    {
                        clientApprovalStatus: ClientApprovalStatusEnum.DRAFT.name,
                    }
                );

                const splicedReportStatusHistories = slice(
                    sortedReportStatusHistories,
                    lastDraftReportStatusHistoryIndex
                );

                return chain(splicedReportStatusHistories)
                    .filter(({ clientApprovalStatus }) => {
                        return (
                            clientApprovalStatus ===
                            ClientApprovalStatusEnum.PENDING_SECONDARY_REVIEW.name
                        );
                    })
                    .sortBy('updatedDateUtc')
                    .value()[0];
            }

            return chain(sortedReportStatusHistories)
                .filter(({ clientApprovalStatus }) => {
                    return (
                        clientApprovalStatus ===
                        ClientApprovalStatusEnum.PENDING_SECONDARY_REVIEW.name
                    );
                })
                .sortBy('updatedDateUtc')
                .value()[0];

        case reportApprovalLevelClientEnum.ONE:
            return chain(sortedReportStatusHistories)
                .filter(({ clientApprovalStatus }) => {
                    return clientApprovalStatus === ClientApprovalStatusEnum.COMPLETED.name;
                })
                .sortBy('updatedDateUtc')
                .value()
                .reverse()[0];

        default:
            return undefined;
    }
}

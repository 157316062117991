import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED } from '~/client-common/core/enums/universal/fields';
import Modal from '../../../../../legacy-redux/components/core/Modal';
import Row from '../../../../core/components/Row';
import ReactReduxForm from '../../../../core/forms/components/ReactReduxForm';
import { RRFTextArea } from '../../../../core/forms/components/TextArea';
import { closeBox, saveBoxHalt } from '../../../../../legacy-redux/actions/boxActions';
import reportRejectionForm from '../../state/forms/reportRejectionForm';
import { createModalSelector } from '../../../../core/box/state/ui';
import { RmsDispatch } from '../../../../../core/typings/redux';

const context = {
    name: boxEnum.REPORT_REJECTION_MODAL,
};

const strings = componentStrings.reports.core.ReportRejectionModal;

const ReportRejectionText = styled.div`
    padding-bottom: 15px;
`;
interface ReportRejectionModalPropsT {
    closeBox: () => void;
    submitForm: (callback: () => void) => void;
}

function ReportRejectionModal({ closeBox, submitForm }: ReportRejectionModalPropsT) {
    const fieldDisplayNames = useFields([DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED]);
    const callback: () => void = useSelector(createModalSelector(context, 'callback'));
    return (
        <Modal
            context={context}
            title={strings.title}
            onClose={closeBox}
            okText={strings.okText}
            onSave={() => submitForm(callback)}
        >
            <ReactReduxForm {...reportRejectionForm}>
                <Row>
                    <ReportRejectionText>
                        {strings.warning(
                            fieldDisplayNames?.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED
                        )}
                    </ReportRejectionText>
                </Row>
                <Row>
                    <RRFTextArea
                        label={strings.comments}
                        rows={5}
                        path="reasonForRejection"
                        resizable={false}
                        placeholder={strings.placeholder}
                    />
                </Row>
            </ReactReduxForm>
        </Modal>
    );
}

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    closeBox: () => {
        // @ts-expect-error client-common to client RND-7529
        dispatch(reportRejectionForm.actionCreators.reset());
        dispatch(closeBox(context));
    },
    submitForm: (callback: (reasonForRejection: string) => void) => {
        dispatch(
            // @ts-expect-error client-common to client RND-7529
            reportRejectionForm.actionCreators.submit(
                (formModel: { reasonForRejection: string }) => {
                    callback(formModel.reasonForRejection);
                    // @ts-expect-error client-common to client RND-7529
                    dispatch(reportRejectionForm.actionCreators.reset());
                    dispatch(closeBox(context));
                }
            )
        ).catch(() => {
            dispatch(saveBoxHalt(context));
        });
    },
});
export default compose<ReportRejectionModalPropsT, ReportRejectionModalPropsT>(
    connect(null, mapDispatchToProps)
)(ReportRejectionModal);

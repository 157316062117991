import React from 'react';
import { Draggable, DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import styled from 'styled-components';
import { Text as ArcText, cssVar } from 'arc';
import { isBoolean, filter, mapValues, keyBy, size, map, every } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import Checkbox, { INDETERMINATE } from '../../../core/forms/components/checkboxes/Checkbox';
import Icon, { iconSizes, iconTypes } from '../../../core/components/Icon';
import { reOrder } from '../../../core/drag-and-drop/utils/reOrder';
import { PacketOption } from '../../../exports/core/utils/exportHelpers';
import { onClickToOnEnter } from '../../../core/utils/eventHelpers';
import { ExpandCollapseState } from './ReportFormExportsOptions';
import ReportExportComponent from './ReportExportComponent';

const strings = componentStrings.core.ReportExportsCheckboxTree;

const ExpandCollapseAll = styled(ArcText)`
    display: inline;
    font-size: ${cssVar('arc.fontSizes.xs')};
    color: ${cssVar('arc.colors.brand.default')};
    cursor: pointer;
`;

const SelectAllWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding-top: 15px;
`;

const ExpandCollapseAllWrapper = styled.div`
    margin-right: 10px;
`;

const DragHandleWrapper = styled.div`
    position: relative;
`;

const DragHandle = styled.div`
    top: 16px;
    left: -15px;
    position: absolute;
`;

export interface Tree {
    value: string;
    children: Tree[];
}

export const ReportExportsCheckboxTree: React.FC<{
    tree: Tree[];
    nodes: PacketOption[];
    onSelectAll: () => void;
    onDeselectAll: () => void;
    options: PacketOption[];
    value: string[];
    onDragEnd: (sortOrder: Tree[]) => void;
    expandCollapseState: ExpandCollapseState;
    setExpandCollapseState: React.Dispatch<React.SetStateAction<ExpandCollapseState>>;
}> = ({
    tree,
    nodes,
    onSelectAll,
    onDeselectAll,
    options,
    value,
    onDragEnd,
    expandCollapseState,
    setExpandCollapseState,
}) => {
    const selectedValues = mapValues(keyBy(value), () => true);
    const allOptionsAreSelected = every(
        filter(options, (option) => {
            return !option.disabled;
        }),
        ({ value: currentValue }) => selectedValues[currentValue]
    );
    const handleClickExpand = () => {
        setExpandCollapseState(mapValues(keyBy(tree, 'value'), () => true));
    };

    const handleClickCollapse = () => {
        setExpandCollapseState(mapValues(keyBy(tree, 'value'), () => false));
    };

    const handleSelectAll = () => {
        handleClickExpand();
        if (onSelectAll) {
            onSelectAll();
        }
    };

    const handleDeselectAll = () => {
        handleClickCollapse();
        if (onDeselectAll) {
            onDeselectAll();
        }
    };

    const handleExpandCollapseClick = (reportId: string) => {
        return (val: boolean) => {
            setExpandCollapseState((state) => ({
                ...state,
                [reportId]: isBoolean(val) ? val : !state[reportId],
            }));
        };
    };

    const handleDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reOrder<Tree>(tree, result.source.index, result.destination.index);

        if (onDragEnd) {
            onDragEnd(items);
        }
    };

    const topLevelNodes = map(tree, (node, index) => {
        return (
            <Draggable key={node.value} draggableId={node.value} index={index}>
                {(provided) => {
                    return (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <DragHandleWrapper>
                                <DragHandle {...provided.dragHandleProps}>
                                    <Icon
                                        type={iconTypes.DRAG_HANDLE}
                                        size={iconSizes.SMALL}
                                        color="mediumLightGrey"
                                    />
                                </DragHandle>
                            </DragHandleWrapper>
                            <ReportExportComponent
                                allNodes={nodes}
                                {...node}
                                testId={testIds.EXPORTS_INCLUDE_IN_EXPORT_CHECKBOX}
                                onExpandCollapseClick={handleExpandCollapseClick(node.value)}
                                isToggleExpanded={expandCollapseState[node.value]}
                            />
                        </div>
                    );
                }}
            </Draggable>
        );
    });

    // https://github.com/atlassian/react-beautiful-dnd/issues/131#issuecomment-411083296
    // This is throwing a warning about: "Droppable: unsupported nested scroll container detected."
    // However, for this usecase, we can safely ignore this error.  Even though
    // there are nested scroll containers, only one is actively scrollable.
    return (
        <>
            <SelectAllWrapper>
                <Checkbox
                    onChange={allOptionsAreSelected ? handleDeselectAll : handleSelectAll}
                    label={
                        <ArcText as="span" color="secondary" fontSize="xs" fontWeight="semibold">
                            {' '}
                            {strings.selectAll}{' '}
                        </ArcText>
                    }
                    value={allOptionsAreSelected ? true : size(value) === 0 ? false : INDETERMINATE}
                />
                <ExpandCollapseAllWrapper>
                    <ExpandCollapseAll
                        tabIndex={0}
                        onClick={handleClickExpand}
                        onKeyDown={onClickToOnEnter(handleClickExpand)}
                    >
                        {strings.expandAll} {'/'}
                    </ExpandCollapseAll>

                    <ExpandCollapseAll
                        tabIndex={0}
                        onClick={handleClickCollapse}
                        onKeyDown={onClickToOnEnter(handleClickCollapse)}
                    >
                        {' '}
                        {strings.collapseAll}
                    </ExpandCollapseAll>
                </ExpandCollapseAllWrapper>
            </SelectAllWrapper>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="reportExportsCheckboxTree">
                    {(provided) => {
                        return (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {topLevelNodes}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </DragDropContext>
        </>
    );
};

import { EntityTypeEnum } from '@mark43/rms-api';
import _, { filter, size } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import withFields from '~/client-common/core/fields/components/withFields';
import { staffRemarksByMasterItemIdSelector as staffRemarksByMasterItemId } from '~/client-common/core/domain/staff-remarks/state/data';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { getReportingEventNumberSelector as getReportingEventNumber } from '~/client-common/core/domain/item-reporting-event-links/state/data/';
import { RemarksAccordion } from '../../../core/components/RemarksAccordion';
import StaffRemarks from '../../core/components/StaffRemarks';
import { renderOnlyIf } from '../../../../legacy-redux/helpers/reactHelpers';

const { REPORT, ITEM_PROFILE } = EntityTypeEnum;
const STRINGS = componentStrings.evidence.itemQueue.StaffRemarksRow;

const StaffRemarksContainer = styled.div`
    margin-top: 30px;

    &:first-child {
        margin-top: 8px;
    }
`;

const Subtitle = styled.div`
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-sm);
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    line-height: 15px;
    margin-bottom: 16px;
`;

const ReportRemarks = compose(withFields([REPORT_REPORTING_EVENT_NUMBER]))(
    ({
        fieldDisplayNames: { REPORT_REPORTING_EVENT_NUMBER },
        reportingEventNumber,
        reportRemarks,
    }) => (
        <div>
            <Subtitle>
                {STRINGS.label.reportRemarks(REPORT_REPORTING_EVENT_NUMBER, reportingEventNumber)}
            </Subtitle>

            <StaffRemarks staffRemarks={reportRemarks} />
        </div>
    )
);

const ItemRemarks = ({ className, itemRemarks }) => (
    <div className={className}>
        <Subtitle>{STRINGS.label.itemRemarks}</Subtitle>

        <StaffRemarks staffRemarks={itemRemarks} />
    </div>
);

const StaffRemarksRow = styled(
    ({
        className,
        itemRemarks,
        reportingEventNumber,
        reportRemarks,
        totalRemarksCount,
        showReportRemarks = size(reportRemarks) > 0,
        showItemRemarks = size(itemRemarks) > 0,
    }) => (
        <div className={className}>
            <RemarksAccordion header={STRINGS.title(totalRemarksCount)}>
                {showReportRemarks && (
                    <StaffRemarksContainer>
                        <ReportRemarks
                            reportingEventNumber={reportingEventNumber}
                            reportRemarks={reportRemarks}
                        />
                    </StaffRemarksContainer>
                )}

                {showItemRemarks && (
                    <StaffRemarksContainer>
                        <ItemRemarks itemRemarks={itemRemarks} />
                    </StaffRemarksContainer>
                )}
            </RemarksAccordion>
        </div>
    )
)`
    width: 100%;
`;

StaffRemarksRow.propTypes = {
    className: PropTypes.string,
    masterItemId: PropTypes.number.isRequired,
    totalRemarksCount: PropTypes.number,
};

StaffRemarksRow.defaultProps = {
    className: '',
    totalRemarksCount: 0,
};

const mapStateToProps = createStructuredSelector({
    getReportingEventNumber,
    staffRemarksByMasterItemId,
});

export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['masterItemId', 'staffRemarksByMasterItemId'],
        ({ masterItemId, staffRemarksByMasterItemId, getReportingEventNumber }) => {
            const staffRemarks = staffRemarksByMasterItemId(masterItemId);
            const itemRemarks = filter(staffRemarks, { entityType: ITEM_PROFILE.name });
            const reportRemarks = filter(staffRemarks, { entityType: REPORT.name });
            const reportId = _.chain(reportRemarks).head().get('entityId').value();

            return {
                itemRemarks,
                reportRemarks,
                reportingEventNumber: reportId ? getReportingEventNumber(reportId) : '',
                totalRemarksCount: size(itemRemarks) + size(reportRemarks),
            };
        }
    ),
    renderOnlyIf(({ totalRemarksCount }) => totalRemarksCount)
)(StaffRemarksRow);

import React from 'react';
import { compose, withState, withHandlers } from 'recompose';

import componentStrings from '~/client-common/core/strings/componentStrings';

import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import { scrollToTop } from '../../../core/components/ScrollableUnderSubheader';
import { NewWarrantButton } from './WarrantsNewButton';

import WarrantsDashboardSearchResults from './WarrantsDashboardSearchResults';

const strings = componentStrings.warrants.dashboard;

const WarrantsDashboard = ({ isSearchFormOpen, onSearchFormToggleClick }) => {
    return (
        <Page className="warrants-dashboard-container">
            <Subheader title={strings.title}>
                <NewWarrantButton />
            </Subheader>
            <WarrantsDashboardSearchResults
                isSearchFormOpen={isSearchFormOpen}
                onSearchFormToggleClick={onSearchFormToggleClick}
            />
        </Page>
    );
};

export default compose(
    withState('isSearchFormOpen', 'setSearchFormOpen', false),
    withHandlers({
        onSearchFormToggleClick({ setSearchFormOpen }) {
            scrollToTop();
            return () => setSearchFormOpen((isOpen) => !isOpen);
        },
    })
)(WarrantsDashboard);

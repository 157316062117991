import _ from 'lodash';

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { ATTRIBUTE, FIELDSET, N_ITEMS_FIELDSET } = fieldTypeClientEnum;
const callForServiceAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'fullCallForService',
        type: FIELDSET,
        fields: buildFlatFormFieldViewModels([
            { key: 'isAgencyCombination' },
            {
                key: 'callForService',
                type: FIELDSET,
                fields: buildFlatFormFieldViewModels([
                    {
                        key: 'code',
                    },
                    {
                        key: 'description',
                    },
                    {
                        key: 'priorityAttrId',
                        type: ATTRIBUTE,
                    },
                    {
                        key: 'riskLevelAttrId',
                        type: ATTRIBUTE,
                    },
                    {
                        key: 'maxTimeUnassignedSeconds',
                    },
                    {
                        key: 'maxTimeUnassignedMinutes',
                    },
                    {
                        key: 'defaultForEventOriginAttrId',
                        type: ATTRIBUTE,
                    },
                    { key: 'isAedEnabled' },
                    { key: 'isSelfInitiateEnabled' },
                    { key: 'activeDateUtc' },
                    { key: 'expirationDateUtc' },
                ]),
            },
            {
                key: 'callForServiceDetails',
                type: N_ITEMS_FIELDSET,
                fields: buildFlatFormFieldViewModels([
                    { key: 'agencyTypeCallForServiceId' },
                    { key: 'agencyTypeGlobalAttrId' },
                    { key: 'callForServiceId' },
                ]),
            },
        ]),
    },
]);

const initialState = {
    fullCallForService: {
        callForService: {},
        callForServiceDetails: [],
    },
};

function convertFromFormModel(formModel) {
    const maxTimeUnassignedMinutes = _.get(
        formModel,
        'fullCallForService.callForService.maxTimeUnassignedMinutes'
    );

    const updatedFormModel = {
        ...formModel,
        fullCallForService: {
            ...formModel.fullCallForService,
            callForService: {
                ...formModel.fullCallForService.callForService,
                maxTimeUnassignedMinutes: undefined,
                maxTimeUnassignedSeconds: maxTimeUnassignedMinutes
                    ? Number(maxTimeUnassignedMinutes) * 60
                    : null,
            },
        },
    };

    return updatedFormModel;
}

export const validateRequiredFields = (
    dispatch,
    callForServiceForm,
    model,
    requiredAgencyTypeCount
) => {
    const validations = [];
    const requiredPaths = [
        ['callForService', 'code'],
        ['callForService', 'description'],
        ['callForService', 'priorityAttrId'],
        ['callForService', 'riskLevelAttrId'],
    ];

    for (let i = 0; i < requiredAgencyTypeCount; i++) {
        requiredPaths.push(['callForServiceDetails', i, 'agencyTypeGlobalAttrId']);

        // skip agencyTypeCallForServiceId if not combined cfs
        if (requiredAgencyTypeCount > 1) {
            requiredPaths.push(['callForServiceDetails', i, 'agencyTypeCallForServiceId']);
        }
    }

    requiredPaths.forEach((pathChunks) => {
        let value;
        let path;

        if (pathChunks.length === 2) {
            const [branch, field] = pathChunks;
            value = model.fullCallForService[branch][field];
            path = `${branch}.${field}`;
        }
        if (pathChunks.length === 3) {
            const [branch, index, field] = pathChunks;
            value = model.fullCallForService[branch][index]?.[field];
            path = `${branch}[${index}].${field}`;
        }

        const isValid = !!value;
        validations.push(isValid);

        dispatch(
            callForServiceForm.actionCreators.setValidity(`fullCallForService.${path}`, {
                requiredError: isValid,
            })
        );
    });

    return validations.every((v) => v === true);
};

export const validateOptionalFields = (dispatch, callForServiceForm, model) => {
    const maxTimeUnassignedMinutes = `${
        _.get(model, 'fullCallForService.callForService.maxTimeUnassignedMinutes') ?? ''
    }`;
    if (maxTimeUnassignedMinutes) {
        const maxTimeUnassignedMinutesNumberVal = Number(maxTimeUnassignedMinutes.trim());

        if (
            Number.isInteger(maxTimeUnassignedMinutesNumberVal) &&
            maxTimeUnassignedMinutesNumberVal > 0
        ) {
            dispatch(
                callForServiceForm.actionCreators.setValidity(
                    'fullCallForService.callForService.maxTimeUnassignedMinutes',
                    {
                        mustBeIntAndGreaterThanZeroError: true,
                    }
                )
            );

            return true;
        } else {
            dispatch(
                callForServiceForm.actionCreators.setValidity(
                    'fullCallForService.callForService.maxTimeUnassignedMinutes',
                    {
                        mustBeIntAndGreaterThanZeroError: false,
                    }
                )
            );

            return false;
        }
    }

    return true;
};

function convertToFormModel({ callForService }) {
    const isNewCallForService = _.get(callForService, 'callForService.id') === -1;
    const maxTimeUnassignedSeconds = _.get(
        callForService,
        'callForService.maxTimeUnassignedSeconds'
    );
    let callForServiceDetails = _.get(callForService, 'callForServiceDetails', []);

    if (isNewCallForService) {
        const newCallForServiceDetail = {
            agencyTypeGlobalAttrId: null,
            callForServiceId: isNewCallForService ? 0 : callForService.callForService.id,
            agencyTypeCallForServiceId: isNewCallForService ? 0 : callForService.callForService.id,
        };

        callForServiceDetails = [newCallForServiceDetail];
    }

    if (maxTimeUnassignedSeconds) {
        _.set(
            callForService,
            'callForService.maxTimeUnassignedMinutes',
            Number(maxTimeUnassignedSeconds) / 60
        );
    }

    const isAgencyCombination = !isNewCallForService && callForServiceDetails.length > 1;

    return {
        fullCallForService: {
            ...callForService,
            callForServiceDetails,
            isAgencyCombination,
            agencyProfileIds: _.map(callForService.callForServiceAgencies, 'agencyProfileId'),
            agencyTypeAttrIds: _.map(callForService.attributes, 'attributeId'),
            callForServiceParentId: _(callForService.callForServiceAgencies)
                .map('callForServiceParentId')
                .compact()
                .head(),
            isGlobal: callForService.attributes && callForService.attributes.length > 0,
        },
    };
}

export default createFormModule({
    formName: formClientEnum.CALL_FOR_SERVICE_ADMIN,
    fieldViewModels: callForServiceAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
    initialState,
});

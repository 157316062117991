import { GangTrackingResource, PersonGangTracking } from '@mark43/rms-api';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Gang Tracking Resource',
    methods: {
        getPersonGangTrackingsBatch(personProfileIds: number[]) {
            return req<GangTrackingResource.GetPersonGangTrackingsBatch>({
                method: 'POST',
                url: 'gang_tracking/persons/batch',
                data: personProfileIds,
            });
        },
        setContextedPersonGangTrackings(personId: number, data: PersonGangTracking[]) {
            return req<GangTrackingResource.SetContextedPersonGangTrackings>({
                method: 'PUT',
                url: `gang_tracking/persons/${personId}/contexted`,
                data,
            });
        },
        setMasterPersonGangTrackings(personId: number, data: PersonGangTracking[]) {
            return req<GangTrackingResource.SetMasterPersonGangTrackings>({
                method: 'PUT',
                url: `gang_tracking/persons/${personId}/master`,
                data,
            });
        },
    },
});

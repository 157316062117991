import { compact, filter, map } from 'lodash';

import { ModuleShape } from '~/client-common/core/utils/createNormalizedModule';

interface Record {
    recordNumber?: string;
    reportingEventNumber?: string;
}

/**
 * Return whether the given object is a record without a REN.
 *
 * As of 2023, the record is always a report.
 * The object may be a Report, a ReportShortTitle, or any object with an optional recordNumber property.
 *
 * An additional condition is that the report's reportDefinition must have
 * renConfiguration !== RenConfigurationEnum.REQUIRED.name.
 */
export default function isRecordWithoutRen(record?: Record): boolean {
    return !!record?.recordNumber && !record?.reportingEventNumber;
}

export function filterRecordWithoutRenIds(
    recordIds: number[],
    records: ModuleShape<Record>
): number[] {
    return filter(recordIds, (id) => isRecordWithoutRen(records[id]));
}

export function filterRecordWithRen(recordIds: number[], records: ModuleShape<Record>): string[] {
    return compact(
        map(recordIds, (id) => {
            const record = records[id];
            if (isRecordWithRen(record)) {
                return record.reportingEventNumber;
            }
            return undefined;
        })
    );
}

/**
 * Return whether the given object is a record with a REN.
 *
 * This function is not the exact opposite of `isRecordWithoutRen`, because it doesn't check that `recordNumber` does
 * not exist. Use this function when it's technically possible for `recordNumber` to not exist for a RWR, such as on an
 * ElasticReport, which may have stale data compared to the Report.
 */
export function isRecordWithRen(record?: Record): boolean {
    return !!record?.reportingEventNumber;
}

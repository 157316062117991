import { get, reduce, keys } from 'lodash';
import invariant from 'invariant';

import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import {
    canEditReportCardStatusSelector,
    canEditOffenseIncidentReportCardStatusSelector,
    canEditEventInfoReportCardStatusSelector,
    canEditSummaryNarrativeReportCardStatusSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import { scrollToCardAtOffsetAndFocusFirstElement } from './cardHelpers';

const EXISTING_CARDS = {};
/**
 * Used to key an individual, single card.
 * Example: Arrest Reports only have a single Arrest Card.
 */
export const SINGLE_CARD_KEY = 'SINGLE';

/**
 * @typedef {object} Card
 * @prop {ActionCreators} actionCreators
 * @prop {Record<string, unknown>} selectors
 * @prop {string} anchor
 * @prop {(unknown, RmsAction) => unknown} reducer
 * @prop {function} anchorForIndex
 * @prop {function} scrollToTop
 * @prop {function} scrollToNextCard
 * @prop {string} name
 */

/**
 * Creates a "cards" state object, offering standard Redux facilities (actions, selectors, etc.)
 * for maintaining the UI state of N cards on a report.
 * @param {Object}      options - Options object containing parameters for creating the card state.
 * @param {string}      options.name - The name of the card(s) key.
 * @param {function}    options.baseSelector - The base selector (where this cards reducer is attached).
 * @param {string}      options.anchor - The value at the `data-anchor` attribute on the card.
 * @returns {Card}
 */
export default function createCard({ name = '', baseSelector, anchor }) {
    invariant(!EXISTING_CARDS[name], `A card(s) object key already exists with name ${name}.`);
    invariant(anchor, `An anchor must be specified when creating a card`);

    EXISTING_CARDS[name] = name;
    const createAnchorWithIndex = (anchor, index) =>
        index === SINGLE_CARD_KEY ? anchor : `${anchor}-${index}`;
    const anchorForIndex = (index = SINGLE_CARD_KEY) => createAnchorWithIndex(anchor, index);
    const actionTypes = buildActionTypes({ name });
    const actionCreators = buildActionCreators({ actionTypes, name });
    const selectors = buildSelectors({ baseSelector });
    const reducer = buildReducer({ actionTypes });

    function scrollToTop(options = {}) {
        const { index } = options;
        const anchor = anchorForIndex(index);
        if (anchor) {
            scrollToCardAtOffsetAndFocusFirstElement({ startingAnchor: anchor, offset: 0 });
        }
    }

    /**
     * Scrolls to the next element that has `data-anchor` defined on it
     * Technically is is not _always_ a card, but right now all elements that
     * can be scrolled to are card-related.
     * @param {*} options
     */
    function scrollToNextCard(options = {}) {
        const { index } = options;
        const anchor = anchorForIndex(index);
        scrollToCardAtOffsetAndFocusFirstElement({ startingAnchor: anchor, offset: 1 });
    }
    return {
        actionCreators,
        selectors,
        anchor,
        reducer,
        anchorForIndex,
        scrollToTop,
        scrollToNextCard,
        name,
    };
}

export function destroyCard(name) {
    delete EXISTING_CARDS[name];
}

function buildEntityActionName(name, index) {
    return `${name}-${index}`;
}

function buildEntitiesActionName(name, indexes = []) {
    return `${name}_IDS_${[].concat(indexes).join('-')}`;
}

function buildActionTypes({ name }) {
    return {
        // single card actions
        createNewCard: ({ index }) => `card/CREATE_NEW_CARD_${buildEntityActionName(name, index)}`,
        savingStart: ({ index }) => `card/${buildEntityActionName(name, index)}_CARD_SAVING_START`,
        savingSuccess: ({ index }) =>
            `card/${buildEntityActionName(name, index)}_CARD_SAVING_SUCCESS`,
        savingFailure: ({ index }) =>
            `card/${buildEntityActionName(name, index)}_CARD_SAVING_FAILURE`,
        setErrorMessages: ({ index }) =>
            `card/${buildEntityActionName(name, index)}_CARD_SET_ERROR_MESSAGES`,
        setSuccessMessages: ({ index }) =>
            `card/${buildEntityActionName(name, index)}_CARD_SET_SUCCESS_MESSAGES`,
        transitionToEditMode: ({ index }) =>
            `card/TRANSITION_${buildEntityActionName(name, index)}_CARD_TO_EDIT_MODE`,
        resetState: ({ index }) => `card/${buildEntityActionName(name, index)}_CARD_RESET_STATE`,
        setReportId: ({ index }) => `card/${buildEntityActionName(name, index)}_SET_REPORT_ID`,
        // bulk cards actions
        initCardsState: ({ indexes }) =>
            `card/INITIALIZE_${buildEntitiesActionName(name, indexes)}_CARDS_STATE`,
        transitionCardsToEditMode: ({ indexes }) =>
            `card/TRANSITION_${buildEntitiesActionName(name, indexes)}_CARDS_TO_EDIT_MODE`,
        transitionAllCardsToEditMode: () => `card/TRANSITION_ALL_${name}_CARDS_TO_EDIT_MODE`,
        resetCardsState: `card/${name}_CARDS_RESET_STATE`,
    };
}

const CLONE_CARD_STATE = 'card/CLONE_CARD_STATE';

/**
 * @typedef {object} ActionCreators
 * @prop {function} createNewCard
 * @prop {function} savingStart
 * @prop {function} savingSuccess
 * @prop {function} savingFailure
 * @prop {function} setErrorMessages
 * @prop {function} setSuccessMessages
 * @prop {function} transitionToEditMode
 * @prop {function} resetState
 * @prop {function} setReportId
 * @prop {function} initCardsState
 * @prop {function} cloneCardState
 * @prop {function} transitionCardsToEditMode
 * @prop {function} transitionAllCardsToEditMode
 * @prop {function} resetCardsState
 */
function buildActionCreators({ actionTypes, name }) {
    return {
        // single card actions
        createNewCard(options = {}) {
            const { index, summaryMode, reportId } = options;
            invariant(!!index, 'Must provide a `index` when creating a new card.');
            return {
                type: actionTypes.createNewCard({ index }),
                payload: { index, summaryMode, reportId },
            };
        },
        savingStart(options = {}) {
            const { index = SINGLE_CARD_KEY } = options;
            return { type: actionTypes.savingStart({ index }), payload: { index } };
        },
        savingSuccess(options = {}) {
            const { index = SINGLE_CARD_KEY, summaryMode } = options;
            return { type: actionTypes.savingSuccess({ index }), payload: { index, summaryMode } };
        },
        savingFailure(messages, options = {}) {
            const { index = SINGLE_CARD_KEY } = options;
            if (!Array.isArray(messages)) {
                messages = [messages];
            }
            return {
                type: actionTypes.savingFailure({ index }),
                payload: { messages, index },
            };
        },
        setErrorMessages(messages, options = {}) {
            const { index = SINGLE_CARD_KEY } = options;
            if (!Array.isArray(messages)) {
                messages = [messages];
            }
            return {
                type: actionTypes.setErrorMessages({ index }),
                payload: { messages, index },
            };
        },
        setSuccessMessages(messages, options = {}) {
            const { index = SINGLE_CARD_KEY } = options;
            if (!Array.isArray(messages)) {
                messages = [messages];
            }
            return {
                type: actionTypes.setSuccessMessages({ index }),
                payload: { messages, index },
            };
        },
        transitionToEditMode(options = {}) {
            const { index = SINGLE_CARD_KEY } = options;
            return { type: actionTypes.transitionToEditMode({ index }), payload: { index } };
        },
        resetState(options = {}) {
            const { index = SINGLE_CARD_KEY } = options;
            return { type: actionTypes.resetState({ index }), payload: { index } };
        },
        setReportId(options = {}) {
            const { index = SINGLE_CARD_KEY, reportId } = options;
            return {
                type: actionTypes.setReportId({ index }),
                payload: { reportId, index },
            };
        },
        // bulk cards actions
        initCardsState({ indexes }) {
            return { type: actionTypes.initCardsState({ indexes }), payload: { indexes } };
        },
        cloneCardState({ sourceIndex, targetIndex }) {
            return { type: CLONE_CARD_STATE, payload: { sourceIndex, targetIndex } };
        },
        transitionCardsToEditMode({ indexes }) {
            return {
                type: actionTypes.transitionCardsToEditMode({ indexes }),
                payload: { indexes },
            };
        },
        transitionAllCardsToEditMode() {
            return {
                type: actionTypes.transitionAllCardsToEditMode(name),
            };
        },
        resetCardsState() {
            return { type: actionTypes.resetCardsState };
        },
    };
}

function buildSelectors({ baseSelector }) {
    return {
        summaryModeSelector: (state, props = {}) => {
            const cardsByKey = baseSelector(state);
            return get(cardsByKey, `${props.index || SINGLE_CARD_KEY}.summaryMode`);
        },
        savingSelector: (state, props = {}) => {
            const cardsByKey = baseSelector(state);
            return get(cardsByKey, `${props.index || SINGLE_CARD_KEY}.saving`);
        },
        errorMessagesSelector: (state, props = {}) => {
            const cardsByKey = baseSelector(state);
            return get(cardsByKey, `${props.index || SINGLE_CARD_KEY}.errorMessages`);
        },
        successMessagesSelector: (state, props = {}) => {
            const cardsByKey = baseSelector(state);
            return get(cardsByKey, `${props.index || SINGLE_CARD_KEY}.successMessages`);
        },
        reportIdSelector: (state, props = {}) => {
            const cardsByKey = baseSelector(state);
            return get(cardsByKey, `${props.index || SINGLE_CARD_KEY}.reportId`);
        },
        canEditReportCardStatusSelector,
        canEditOffenseIncidentReportCardStatusSelector,
        canEditEventInfoReportCardStatusSelector,
        canEditSummaryNarrativeReportCardStatusSelector,
    };
}

const initialCardState = {
    summaryMode: true,
    saving: false,
    errorMessages: [],
    successMessages: [],
    reportId: undefined,
};

function buildReducer({ actionTypes }) {
    return makeResettable(actionTypes.resetCardsState, function cardReducer(state = {}, action) {
        const index = get(action, 'payload.index'); // single card
        const indexes = get(action, 'payload.indexes'); // bulk cards

        switch (action.type) {
            // single card actions
            case actionTypes.createNewCard({ index }):
                return {
                    ...state,
                    [index]: {
                        ...initialCardState,
                        summaryMode: !!action.payload.summaryMode,
                        reportId: action.payload.reportId,
                    },
                };
            case actionTypes.savingStart({ index }):
                return {
                    ...state,
                    [index]: {
                        ...state[index],
                        saving: true,
                        errorMessages: [],
                        successMessages: [],
                    },
                };
            case actionTypes.savingSuccess({ index }):
                return {
                    ...state,
                    [index]: {
                        ...state[index],
                        saving: false,
                        errorMessages: [],
                        successMessages: action.payload.messages,
                        summaryMode:
                            action.payload.summaryMode !== undefined
                                ? !!action.payload.summaryMode
                                : true,
                    },
                };
            case actionTypes.savingFailure({ index }):
                return {
                    ...state,
                    [index]: {
                        ...state[index],
                        saving: false,
                        errorMessages: action.payload.messages,
                        successMessages: [],
                    },
                };
            case actionTypes.setErrorMessages({ index }):
                return {
                    ...state,
                    [index]: {
                        ...state[index],
                        errorMessages: action.payload.messages,
                    },
                };
            case actionTypes.setSuccessMessages({ index }):
                return {
                    ...state,
                    [index]: {
                        ...state[index],
                        successMessages: action.payload.messages,
                    },
                };
            case actionTypes.transitionToEditMode({ index }):
                return {
                    ...state,
                    [index]: {
                        ...state[index],
                        summaryMode: false,
                    },
                };
            case actionTypes.resetState({ index }):
                return {
                    ...state,
                    [index]: initialCardState,
                };
            case actionTypes.setReportId({ index }):
                return {
                    ...state,
                    [index]: {
                        ...state[index],
                        reportId: action.payload.reportId,
                    },
                };
            // bulk cards actions
            case actionTypes.initCardsState({ indexes }):
                return {
                    ...state,
                    ...reduce(
                        indexes,
                        (accResult, index) => ({ ...accResult, [index]: initialCardState }),
                        {}
                    ),
                };
            case actionTypes.transitionCardsToEditMode({ indexes }):
                return {
                    ...state,
                    ...reduce(
                        indexes,
                        (accResult, index) => ({
                            ...accResult,
                            [index]: { ...state[index], summaryMode: false },
                        }),
                        {}
                    ),
                };
            case actionTypes.transitionAllCardsToEditMode():
                return {
                    ...state,
                    ...reduce(
                        keys(state),
                        (accResult, index) => ({
                            ...accResult,
                            [index]: { ...state[index], summaryMode: false },
                        }),
                        {}
                    ),
                };
            case CLONE_CARD_STATE:
                return {
                    ...state,
                    [action.payload.targetIndex]: { ...state[action.payload.sourceIndex] },
                };
            default:
                return state;
        }
    });
}

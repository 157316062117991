import React from 'react';
import Link from '../../../../core/components/links/Link';
import { ApprovalStatusIcon } from './LinkedRecordComponents';

export default function LinkedReportLink({
    className,
    reportId,
    reportSummary,
    reportSealingInfo,
    clientApprovalStatus,
}) {
    return (
        <Link className={className} to={`/reports/${reportId}`}>
            <ApprovalStatusIcon approvalStatus={clientApprovalStatus} {...reportSealingInfo} />
            {reportSummary}
        </Link>
    );
}

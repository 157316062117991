import * as yup from 'yup';

import {
    InferFormDataShape,
    createField,
    createFormConfiguration,
    createNItems,
} from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';

export const manualLineupFormConfiguration = createFormConfiguration({
    manualLineupRow: createNItems({
        fields: {
            firstName: createField<string>({}),
            lastName: createField<string>({}),
            imageId: createField<number>({}),
            imageURL: createField<string>({}),
        },
    }),
});

export type ManualLineupFormConfiguration = typeof manualLineupFormConfiguration;
export type ManualLineupRowDataShape =
    | InferFormDataShape<typeof manualLineupFormConfiguration['manualLineupRow']>
    | undefined;
export type ManualNItemsDataShape = InferFormDataShape<
    typeof manualLineupFormConfiguration['manualLineupRow']
>[0];

const validationStrings = componentStrings.validation;

export const validationSchema = yup.object().shape({
    manualLineupRow: yup.array().of(
        yup.object().shape({
            firstName: yup.string().required(validationStrings.requiredError),
            lastName: yup.string().required(validationStrings.requiredError),
            imageId: yup.number().nullable(),
        })
    ),
});

import { printingTemplatesSelector } from '~/client-common/core/domain/printing-templates/state/data';
import factory from '../../../../../legacy-redux/actions/types/actionTypesFactory';
import printingTemplatesAdminForm from '../forms/printingTemplatesAdminForm';

export const actionTypes = factory('printing', [
    'SELECT_PRINTING_TEMPLATE_START',
    'SELECT_PRINTING_TEMPLATE_SUCCESS',
    'SELECT_PRINTING_TEMPLATE_FAILURE',
    'UPLOAD_FILE_START',
    'UPLOAD_FILE_SUCCESS',
    'UPLOAD_FILE_FAILURE',
    'OPEN_NEW_PRINTING_TEMPLATE_FORM',
    'CLEAR_PRINTING_TEMPLATE_ADMIN',
]);

function selectPrintingTemplateStart() {
    return {
        type: actionTypes.SELECT_PRINTING_TEMPLATE_START,
    };
}

function selectPrintingTemplateSuccess(printingTemplateId) {
    return {
        type: actionTypes.SELECT_PRINTING_TEMPLATE_SUCCESS,
        payload: printingTemplateId,
    };
}

function selectPrintingTemplateFailure(err) {
    return {
        type: actionTypes.SELECT_PRINTING_TEMPLATE_FAILURE,
        payload: err,
    };
}

export function selectPrintingTemplate(printingTemplateId) {
    return (dispatch, getState) => {
        dispatch(selectPrintingTemplateStart());
        const state = getState();
        const printingTemplate = printingTemplatesSelector(state)[printingTemplateId];
        if (printingTemplate) {
            dispatch(printingTemplatesAdminForm.actionCreators.change(printingTemplate));
            dispatch(selectPrintingTemplateSuccess(printingTemplateId));
        } else {
            dispatch(selectPrintingTemplateFailure('Failed to get printing template'));
        }
    };
}

export function uploadFileStart() {
    return {
        type: actionTypes.UPLOAD_FILE_START,
    };
}

export function uploadFileSuccess(file) {
    return {
        type: actionTypes.UPLOAD_FILE_SUCCESS,
        payload: file,
    };
}

export function uploadFileFailure(err) {
    return {
        type: actionTypes.UPLOAD_FILE_FAILURE,
        payload: err,
    };
}

export function openNewPrintingTemplateForm() {
    return {
        type: actionTypes.OPEN_NEW_PRINTING_TEMPLATE_FORM,
    };
}

function clearPrintingTemplateAdminForm() {
    return {
        type: actionTypes.CLEAR_PRINTING_TEMPLATE_ADMIN,
    };
}

export function clearPrintingTemplateAdmin() {
    return (dispatch) => {
        dispatch(printingTemplatesAdminForm.actionCreators.reset());
        return dispatch(clearPrintingTemplateAdminForm());
    };
}

import React from 'react';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import CaseProfileIndicator from './CaseProfileIndicator';

export default function CaseSourceIndicator({ sources }: { sources: string[] }) {
    return (
        <CaseProfileIndicator title={componentStrings.cases.sourceIndicator.title}>
            {joinTruthyValues(sources)}
        </CaseProfileIndicator>
    );
}

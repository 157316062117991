import { joinTruthyValues } from '../../../../helpers/stringHelpers';

/**
 * @param  {string}  reportingEventNumber
 * @param  {number}  sequenceNumber
 * @param  {boolean} [options.bothRequired=false] Whether both REN and Sequence Number are required
 *   to build the formatted string. If true, the formatted string will be empty when either are
 *   empty. If false, the formatted string will just be the REN when there is no Sequence Number.
 * @return {[type]}
 */
export default function formatRenSequenceNumber(
    reportingEventNumber,
    sequenceNumber,
    { bothRequired = false } = {}
) {
    if (bothRequired) {
        return reportingEventNumber && sequenceNumber
            ? `${reportingEventNumber}-${sequenceNumber}`
            : '';
    } else {
        return joinTruthyValues([reportingEventNumber, sequenceNumber], '-');
    }
}

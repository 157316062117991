import { LocationEntityLinkUiOptions } from 'dragon-react';
import { isEqual } from 'lodash';
import { LocationEntityLink as LocationEntityLinkT } from '@mark43/rms-api';
import { Fieldset } from 'markformythree';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { useMemoEquals } from '../../../hooks/use-memo-equals';
import { useResolvedLocationEntityLinkQueryConfigurationValues } from '../../../hooks/use-resolved-location-entity-link-query-configuration-values';
import {
    DragonInlineFormReferenceState,
    RMSDragonConfigurationExtensions,
} from '../../../rms-types';
import { assertNotNullOrUndefined } from '../../../../core/utils/assert-not-null-or-undefined';

import { useMFTFormContext } from '../../../context/mft-form';
import { useDragonCurrentFormNestingPathContext } from '../../../context/dragon-current-form';
import { getConfiguredFormPath } from '../../../utils/get-configured-form-path';
import { InlineFormReferenceSummaryWrapper } from '../inline-form-reference-summary-wrapper';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { syncCoreInstancesAction } from '../state';
import { getCoreModelInstanceId } from '../../../utils/dragon-instance-ids';
import { getDataAttributes } from '../utils/get-data-attributes';
import { deleteInlineFormReference } from '../../../state';
import { useSummaryValueForPath } from '../../../hooks/use-summary-value-for-path';
import { LocationSummaryViewWrapper } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapper';
import { useSyncInlineFormValuesForLinks } from '../../../hooks/use-sync-inline-form-values-for-links';
import { InlineFormReferenceListEntry, RMSDragonFieldsetHelper } from './shared';

type LocationEntityLinkUiOptionsWithExtensions = LocationEntityLinkUiOptions<RMSDragonConfigurationExtensions>;

export function LocationEntityLinkSummary(
    props: LocationEntityLinkUiOptionsWithExtensions
): JSX.Element {
    const { props: dragonProps, configuration, renderChildren, fullyQualifiedPath } = props;
    const value = useSummaryValueForPath(fullyQualifiedPath);
    if (!Array.isArray(value)) {
        throw new Error(`Unexpectedly did not find array value for path ${fullyQualifiedPath}`);
    }

    const {
        entityId,
        linkType,
        entityType,
    } = useResolvedLocationEntityLinkQueryConfigurationValues(
        configuration.configuredFormQueryConfigurationViews
    );

    return (
        <RMSDragonFieldsetHelper
            key={dragonProps.key}
            label={dragonProps.label}
            {...getDataAttributes({
                label: configuration.ui.label,
                fullyQualifiedPath,
            })}
            render={() => (
                <LocationSummaryViewWrapper
                    key={dragonProps.key}
                    // Summary mode is always `true` because we use dedicated components for summary and form modes in dragon
                    summaryMode={true}
                    entityType={entityType}
                    entityId={entityId}
                    linkType={linkType}
                    allowMultiple={true}
                    additionalLocationContent={(
                        locationBundle: { locationEntityLink?: LocationEntityLinkT } = {}
                    ) => {
                        const { locationEntityLink } = locationBundle;
                        if (!locationEntityLink) {
                            return null;
                        }
                        assertNotNullOrUndefined(
                            locationEntityLink.configuredEntityReferenceId,
                            'Unexpectedly did not find `configuredEntityReferenceId` for location entity link'
                        );
                        // We have to match the index of the NRL to the correct index in our form state, in case the NRL order is different
                        // than what we have in form state.
                        const index = (value as DragonInlineFormReferenceState[]).findIndex(
                            (state) =>
                                getCoreModelInstanceId(state) ===
                                locationEntityLink.configuredEntityReferenceId
                        );

                        if (index === -1) {
                            // TODO alert here?
                            return null;
                        }

                        return (
                            <InlineFormReferenceSummaryWrapper
                                options={{
                                    ...props,
                                    fullyQualifiedPath: `${fullyQualifiedPath}[${index}]`,
                                }}
                            >
                                {renderChildren({
                                    index,
                                    key: locationEntityLink.configuredEntityReferenceId,
                                })}
                            </InlineFormReferenceSummaryWrapper>
                        );
                    }}
                />
            )}
        />
    );
}

function compareLocationEntityLinksByConfiguredEntityReferenceId(
    oldLinks: LocationEntityLinkT[] | undefined,
    newLinks: LocationEntityLinkT[]
): boolean {
    return isEqual(
        oldLinks?.map((link) => link.configuredEntityReferenceId),
        newLinks.map((link) => link.configuredEntityReferenceId)
    );
}

function getLinkId(link: LocationEntityLinkT): number {
    const { configuredEntityReferenceId } = link;
    assertNotNullOrUndefined(
        configuredEntityReferenceId,
        'Unexpectedly did not find `configuredEntityReferenceId` for location entity link'
    );
    return configuredEntityReferenceId;
}

export function LocationEntityLink(props: LocationEntityLinkUiOptionsWithExtensions): JSX.Element {
    const { props: dragonProps, fullyQualifiedPath, configuration } = props;
    const [isFetchingInlineFormValues, setIsFetchingInlineFormValues] = React.useState(false);
    const form = useMFTFormContext();
    assertNotNullOrUndefined(form, 'Unexpectedly did not find form');
    const currentFormNestingPath = useDragonCurrentFormNestingPathContext();
    const {
        entityId,
        entityType,
        linkType,
    } = useResolvedLocationEntityLinkQueryConfigurationValues(
        configuration.configuredFormQueryConfigurationViews
    );

    const dispatch: RmsDispatch = useDispatch();
    // The RMS is in charge of rendering name report links via `NameSummaryViewWrapper`.
    // We pull out the current state manually so we can use it to detect changes to the set of links for a given link configuration.
    const locationEntityLinksWhere = useSelector(locationEntityLinksWhereSelector);
    const locationEntityLinks = locationEntityLinksWhere({
        entityType,
        entityId,
        linkType,
    });

    // The name report links array is not stable by virtue of being a filtered list, so we need
    // ensure equality by comparing the ids of all links. The reason for this is that we need this
    // as input to our `useEffect` below, which would execute too often without a stable reference.
    const stableLocationEntityLinks = useMemoEquals(
        locationEntityLinks,
        compareLocationEntityLinksByConfiguredEntityReferenceId
    );
    useSyncInlineFormValuesForLinks({
        configuredFormId: configuration.formConfigurationId,
        currentFormNestingPath,
        form,
        fullyQualifiedPath,
        linkEntities: stableLocationEntityLinks,
        getLinkId,
        uiConfigurationId: configuration.id,
        setIsLoading: setIsFetchingInlineFormValues,
    });

    return (
        <RMSDragonFieldsetHelper
            key={dragonProps.key}
            label={dragonProps.label}
            render={() => (
                // Nitems handle the path nesting internally, but we have to ensure that we add the current path as a fieldset, else
                // our form state nesting will be off
                <Fieldset key={dragonProps.key} path={`[${dragonProps.path}]`}>
                    <LocationSummaryViewWrapper
                        key={dragonProps.key}
                        // Summary mode is always `false` because we use dedicated components for summary and form modes in dragon
                        summaryMode={false}
                        entityType={entityType}
                        entityId={entityId}
                        linkType={linkType}
                        allowMultiple={true}
                        onLocationRemove={(locationEntityLink: LocationEntityLinkT) => {
                            if (!locationEntityLink.configuredEntityReferenceId) {
                                return;
                            }
                            return deleteInlineFormReference({
                                instanceIds: [locationEntityLink.configuredEntityReferenceId],
                                parentReferencingUiConfigurationId: configuration.id,
                                parentFormPathInstance: getConfiguredFormPath(
                                    currentFormNestingPath
                                ),
                                configuredFormId: configuration.formConfigurationId,
                            })
                                .then((deletionResponse) => {
                                    if (deletionResponse) {
                                        dispatch(
                                            syncCoreInstancesAction(
                                                deletionResponse.deletedCoreInstances,
                                                deletionResponse.updatedCoreInstances
                                            )
                                        );
                                    }
                                })
                                .catch(() => {
                                    // noop for failed deletes. This causes dangling data but we
                                    // can't do anything on the frontend about it
                                });
                        }}
                        additionalLocationContent={(
                            locationBundle: { locationEntityLink?: LocationEntityLinkT } = {}
                        ) => {
                            const { locationEntityLink } = locationBundle;
                            if (!locationEntityLink) {
                                return null;
                            }
                            const { configuredEntityReferenceId } = locationEntityLink;
                            assertNotNullOrUndefined(
                                configuredEntityReferenceId,
                                'Unexpectedly did not find `configuredEntityReferenceId` for location entity link'
                            );
                            return (
                                <InlineFormReferenceListEntry
                                    key={configuredEntityReferenceId}
                                    entityId={configuredEntityReferenceId}
                                    form={form}
                                    isLoading={isFetchingInlineFormValues}
                                    setIsLoading={setIsFetchingInlineFormValues}
                                    options={props}
                                    currentFormNestingPath={currentFormNestingPath}
                                />
                            );
                        }}
                    />
                </Fieldset>
            )}
        />
    );
}

import type { Editor } from 'tinymce';

import {
    hideCommentSidebar,
    showCommentSidebar,
    getCurrentSelection,
    selectionIsComment,
    selectionIsNotEmpty,
} from './tinyEditor';

/**
 * Create a handler function that runs every time the user clicks or selects text inside the TinyMCE editor.
 *
 * It shows the TinyMCE comment sidebar when the user selects non-highlighted text (which was not commented on), in
 * order to allow them to write a new comment.
 *
 * It doesn't show the sidebar in any other situation, such as when the user selects highlighted text.
 */
export default function createSelectionHandler({
    editor,
    getSummaryMode,
    getCanAddInlineComments,
}: {
    editor: Editor;
    getSummaryMode: () => boolean;
    getCanAddInlineComments: () => boolean;
}): () => void {
    return function () {
        const selection = getCurrentSelection(editor);

        if (
            getSummaryMode() &&
            getCanAddInlineComments() &&
            !selectionIsComment(selection) &&
            selectionIsNotEmpty(selection)
        ) {
            showCommentSidebar(editor);
        } else {
            hideCommentSidebar(editor);
        }
    };
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterMenuList, FilterMenuRenderProps, FilterSearchInput } from 'arc';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { userOptionsSelector } from '../../../../legacy-redux/selectors/userSelectors';
import { searchUsers } from '../../../../legacy-redux/actions/userActions';

export const convertUserToAppliedOption = (item: { userId: number; label: string }) => {
    return {
        id: item.userId,
        label: item.label,
        value: item.userId.toString(),
    };
};

export const UserSelectFilter: React.FC<FilterMenuRenderProps> = ({
    appliedOptions,
    setAppliedFilters,
    selectionType,
}) => {
    const dispatch = useDispatch();
    const usersOptions = useSelector(userOptionsSelector);
    const [query, setQuery] = useState('');

    useEffect(() => {
        dispatch(searchUsers(query, { effectiveDate: nowUtc() }));
    }, [dispatch, query]);

    const users = usersOptions([], { includeOther: false, effectiveDate: nowUtc() });

    const filterListOptions = users.map((item) => {
        return {
            id: item.value,
            label: String(item.display),
            value: String(item.value),
        };
    });

    return (
        <>
            <FilterSearchInput onChange={(e) => setQuery(e.target.value)} />
            <FilterMenuList
                query={query}
                options={filterListOptions}
                onSelect={setAppliedFilters}
                appliedOptions={appliedOptions}
                selectionType={selectionType}
            />
        </>
    );
};

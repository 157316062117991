import * as React from 'react';
import { FormReferenceUiOptions, FormReferenceSummaryUiOptions } from 'dragon-react';
import { DragonCurrentFormContextProvider } from '../../context/dragon-current-form';
import { useReferencedFormInstanceId } from '../../hooks/use-referenced-form-instance-id';
import { DragonConfiguredPropertyAliasContextProvider } from '../../context/dragon-configured-property-alias';
import { useDragonFormValuesContext } from '../../context/dragon-form-values';
import { RMSDragonConfigurationExtensions } from '../../rms-types';
import { resolveAliasValues } from '../../utils/resolve-alias-values';
import { formReferenceSummary } from './renderers/summary';
import { MFTFormWrapper } from './mft-form-wrapper';

export function FormReferenceWrapper({
    options,
}: {
    options:
        | FormReferenceUiOptions<RMSDragonConfigurationExtensions>
        | FormReferenceSummaryUiOptions<RMSDragonConfigurationExtensions>;
}): JSX.Element | null {
    const referencedFormInstanceId = useReferencedFormInstanceId(options.fullyQualifiedPath);
    const formValuesMap = useDragonFormValuesContext();

    // we bail if we cannot find an instance id to prevent old reports from crashing
    if (!referencedFormInstanceId) {
        return null;
    }

    const formValueKey = `${options.configuration.formConfigurationId}~${referencedFormInstanceId}`;
    const formValue = formValuesMap[formValueKey];
    if (!formValue) {
        throw new Error(`Unexpectedly did not find a form value for key "${formValueKey}"`);
    }

    // dragon forms are composed together using query configurations. These configurations
    // can look up ancestor values via aliases, crossing multiple forms in the process.
    // We have to replicate the same alias resolving tree that the backend uses
    // when it is loading data, in order to ensure that values are consistent when
    // being read for consumption by components, e.g. `NameSummaryViewWrapper`.
    const { configuredFormPropertyAliasViews } = options.form;
    const configuredEntityPropertyValues = formValue.__meta?.configuredEntityPropertyValues;

    return (
        <DragonConfiguredPropertyAliasContextProvider
            value={resolveAliasValues({
                configuredEntityPropertyValues,
                configuredFormPropertyAliasViews,
            })}
        >
            <DragonCurrentFormContextProvider
                instanceId={referencedFormInstanceId}
                configuredFormId={options.configuration.formConfigurationId}
                referencingUiConfigurationId={options.configuration.id}
            >
                {options.mode === 'FORM' ? (
                    <MFTFormWrapper {...options} instanceId={referencedFormInstanceId} />
                ) : (
                    // given how `DragonCard` renders its summary mode, we will most likely
                    // never hit this case in the RMS with the latest `dragon-react` changes.
                    // `dragon-react@v6` will not explicitly re-render the current form reference again in summary
                    // mode but only render its children to prevent an issue with tracking the currently
                    // active form context
                    formReferenceSummary(options)
                )}
            </DragonCurrentFormContextProvider>
        </DragonConfiguredPropertyAliasContextProvider>
    );
}

import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { VStack, HStack, Avatar, Text as ArcText, Skeleton } from 'arc';

import { EntityTypeEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { Button } from '../../../../core/components/Button';

const strings = componentStrings.reports.EntityPrefillPopover;

const Text = styled(ArcText)`
    margin: 0;
    margin-top: 0.3em;
`;

const Padding = styled.div`
    padding-left: 12px;
    padding-right: 12px;
`;

const Container = styled(HStack)`
    cursor: pointer;
`;

const SkeletonContainer = styled(HStack)`
    margin-top: 5px;
`;

const NUMBER_OF_DISPLAYED_RESULTS = 3;

const SuggestedProfiles: React.FC<{
    suggestedProfiles: SuggestionListItemProps[] | undefined;
    onClick: (id: number) => void;
}> = ({ suggestedProfiles, onClick }) => {
    return (
        <VStack alignItems="start" padding={3} paddingTop={0}>
            <Text fontSize="xs" color="placeholder" fontWeight="bold">
                {strings.suggestedProfiles(suggestedProfiles?.length || 0)}
            </Text>
            {map(suggestedProfiles, (profile) => (
                <SuggestionListItem
                    key={profile.id}
                    avatarImgPath={profile.avatarImgPath}
                    entityType={profile.entityType}
                    name={profile.name}
                    nameDescriptor={profile.nameDescriptor}
                    infoText={profile.infoText}
                    infoText2={profile.infoText2}
                    id={profile.id}
                    onClick={onClick}
                />
            ))}
        </VStack>
    );
};

const RmsResults: React.FC<{
    rmsResults: SuggestionListItemProps[] | undefined;
    onClick: (id: number) => void;
    onViewAllClick: () => void;
    isLoading: boolean;
}> = ({ rmsResults, onClick, onViewAllClick, isLoading }) => {
    return (
        <VStack alignItems="start" padding={0} style={{ marginTop: 0 }}>
            <Padding>
                <Text fontSize="xs" color="placeholder" fontWeight="bold">
                    {strings.rmsResults(rmsResults?.length || 0)}
                </Text>
                {isLoading ? (
                    <>
                        <ItemSkeleton />
                        <ItemSkeleton />
                        <ItemSkeleton />
                    </>
                ) : (
                    map(rmsResults?.slice(0, NUMBER_OF_DISPLAYED_RESULTS), (profile) => (
                        <SuggestionListItem
                            key={profile.id}
                            avatarImgPath={profile.avatarImgPath}
                            entityType={profile.entityType}
                            name={profile.name}
                            nameDescriptor={profile.nameDescriptor}
                            infoText={profile.infoText}
                            infoText2={profile.infoText2}
                            id={profile.id}
                            onClick={onClick}
                        />
                    ))
                )}
            </Padding>
            <Button
                variant="ghost"
                style={{ textTransform: 'none', marginBottom: 8, marginTop: 8 }}
                onClick={onViewAllClick}
                disabled={!rmsResults?.length}
            >
                {strings.viewAllResults}
            </Button>
        </VStack>
    );
};

const ItemSkeleton: React.FC = () => {
    return (
        <SkeletonContainer>
            <Skeleton boxSize="3rem" />
            <VStack alignItems="start">
                <Skeleton height="1rem" width="150px" />
                <Skeleton height="1rem" width="250px" />
            </VStack>
        </SkeletonContainer>
    );
};

type SuggestionSearchListProps = {
    suggestedProfiles?: SuggestionListItemProps[];
    rmsResults?: SuggestionListItemProps[] | undefined;
    onRmsResultClick: (id: number) => void;
    onSuggestionClick: (id: number) => void;
    onViewAllClick: () => void;
    isLoading: boolean;
};

export type SuggestionListItemProps = {
    avatarImgPath: string;
    entityType: typeof EntityTypeEnum.VEHICLE.name | typeof EntityTypeEnum.PERSON_PROFILE.name;
    name: string;
    nameDescriptor?: string;
    infoText?: string;
    infoText2?: string;
    id: number;
    onClick?: (id: number) => void;
};

const SuggestionListItem: React.FC<SuggestionListItemProps> = ({
    avatarImgPath,
    entityType,
    name,
    nameDescriptor,
    infoText,
    infoText2,
    id,
    onClick,
}) => {
    const iconName =
        entityType === EntityTypeEnum.VEHICLE.name
            ? 'Vehicle'
            : entityType === EntityTypeEnum.PERSON_PROFILE.name
            ? 'Person'
            : undefined;
    return (
        <Container onClick={() => onClick?.(id)}>
            <Avatar icon={iconName} src={avatarImgPath} />
            <VStack alignItems="start">
                <Text variant="headingXs" color="primary" lineHeight="shorter">
                    {name}
                    {nameDescriptor && (
                        <ArcText as="span" isItalic color="tertiary" fontWeight="normal">
                            {` ${nameDescriptor}`}
                        </ArcText>
                    )}
                </Text>
                {infoText && (
                    <Text
                        variant="bodyMd"
                        color="secondary"
                        lineHeight="shorter"
                        style={{ marginTop: '0.3em' }}
                    >
                        {infoText}
                    </Text>
                )}
                {infoText2 && (
                    <Text
                        variant="bodyMd"
                        color="secondary"
                        lineHeight="shorter"
                        style={{ marginTop: '0.3em' }}
                    >
                        {infoText2}
                    </Text>
                )}
            </VStack>
        </Container>
    );
};

export const SuggestionSearchList: React.FC<SuggestionSearchListProps> = ({
    suggestedProfiles,
    rmsResults,
    onRmsResultClick,
    onSuggestionClick,
    onViewAllClick,
    isLoading,
}) => {
    return (
        <VStack marginLeft={2} w={359} alignItems="start" overflow="auto" maxHeight="350px">
            <SuggestedProfiles suggestedProfiles={suggestedProfiles} onClick={onSuggestionClick} />
            {(!!rmsResults || isLoading) && (
                <RmsResults
                    rmsResults={rmsResults}
                    onClick={onRmsResultClick}
                    onViewAllClick={onViewAllClick}
                    isLoading={isLoading}
                />
            )}
        </VStack>
    );
};

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { EntityTypeEnum } from '@mark43/rms-api';
import { Link } from 'react-router';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { FormattedDate } from '~/client-common/core/dates/components';
import { getMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import _Icon, { iconTypes } from '../../../core/components/Icon';
import { getCaseFolderPath } from '../../../cases/core/utils/getCaseFolderPath';
import { HandleRowSelectType, SearchFilterContext } from '../../../cases/core/contexts';
import { FolderRowView } from '../../../cases/case-attachments/types';
import testIds from '../../../../core/testIds';
import {
    AttachmentGridItemContainer,
    AttachmentGridItemContainerInlineBlock,
    AttachmentGridItemFooter,
    AttachmentGridItemSubtitle,
    AttachmentGridItemTitle,
    GridStyledCheckbox,
    HoverContainer,
} from './AttachmentGridItem';

const Icon = styled(_Icon)`
    position: absolute;
    display: flex;
    align-self: center;
    margin-bottom: 2em;
`;

const AttachmentFolderGridItem = ({
    folder,
    handleGridSelect,
    isGridSelected,
}: {
    folder: FolderRowView;
    handleGridSelect?: HandleRowSelectType;
    isGridSelected: boolean;
}) => {
    const { id, name, createdDateUtc, ownerId, isEditable, entityTypeId, updatedBy } = folder;
    const getMiniUserById = useSelector(getMiniUserByIdSelector);
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    const { filter } = useContext(SearchFilterContext);

    const user = getMiniUserById(updatedBy);
    const folderPath = getCaseFolderPath({
        caseId: ownerId,
        entityType: entityTypeId,
        folderId: id,
    });
    return (
        <AttachmentGridItemContainerInlineBlock>
            <AttachmentGridItemContainer data-test-id={testIds.ATTACHMENT_GRID_ITEM}>
                <Icon type={iconTypes.CASE} size={80} color="cobaltBlue" />
                <HoverContainer>
                    <AttachmentGridItemTitle>{caseDisplayName}</AttachmentGridItemTitle>
                    <AttachmentGridItemSubtitle>
                        <FormattedDate
                            date={createdDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                        />
                        <div>by {user.firstInitialAndLastName}</div>
                    </AttachmentGridItemSubtitle>
                </HoverContainer>
                {!filter && (
                    <GridStyledCheckbox
                        value={isGridSelected}
                        onChange={() => {
                            if (handleGridSelect) {
                                handleGridSelect(
                                    id,
                                    isGridSelected,
                                    true,
                                    isEditable,
                                    entityTypeId
                                );
                            }
                        }}
                        entityType={EntityTypeEnum.ATTACHMENT.name}
                    />
                )}
                <Link to={folderPath}>
                    <AttachmentGridItemFooter>{name}</AttachmentGridItemFooter>
                </Link>
            </AttachmentGridItemContainer>
        </AttachmentGridItemContainerInlineBlock>
    );
};

export default AttachmentFolderGridItem;

import _ from 'lodash';
import { createSelector } from 'reselect';

import {
    formatNameNameLink,
    filterNameNameLinks,
} from '~/client-common/helpers/nameNameLinksHelpers';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { linkTypesSelector } from './linkTypesSelectors';

export const nameNameLinksDataSelector = (state) => state.data.nameNameLinks;

export const formatNameNameLinkSelector = createSelector(
    linkTypesSelector,
    (linkTypes) => (nameNameLink, nameId) =>
        formatNameNameLink(nameNameLink, linkTypes[nameNameLink.linkTypeId], nameId)
);

export const formatNameNameLinkWithEffectiveDatesSelector = createSelector(
    formatNameNameLinkSelector,
    currentDepartmentDateFormatterSelector,
    (formatNameNameLink, dateTimeFormatter) => (nameNameLink, nameId) => {
        const link = formatNameNameLink(nameNameLink, nameId);
        const dateFrom = dateTimeFormatter.formatDate(nameNameLink.dateEffectiveFrom);
        const dateTo = dateTimeFormatter.formatDate(nameNameLink.dateEffectiveTo);

        if (!dateFrom && !dateTo) {
            return link;
        }

        return `${link}, ${dateFrom} - ${dateTo}`;
    }
);

/**
 * Format relationships as display strings from a person/org to other
 *   persons/orgs. The result is grouped by the other person/org.
 * @param  {Object[]} nameNameLinks   Relationships. This array is allowed to
 *   include more relationships than just the ones between the given
 *   persons/orgs.
 * @param  {number}   nameId          Id of the person/org to relate to
 *   `otherViewModels`.
 * @param  {Object[]} otherViewModels View models of other persons/orgs.
 * @return {Object[]}
 */
export const formatNameNameLinksSelector = createSelector(
    formatNameNameLinkSelector,
    currentDepartmentDateFormatterSelector,
    (formatNameNameLink, dateTimeFormatter) => (nameNameLinks, nameId, otherViewModels) =>
        _(otherViewModels)
            .flatMap(({ nameId: otherNameId, display: { label } }) => {
                const filteredNameNameLinks = filterNameNameLinks(
                    nameNameLinks,
                    nameId,
                    otherNameId
                );

                // this means no relationship has been specified
                if (filteredNameNameLinks.length === 0) {
                    return;
                }
                return _(filteredNameNameLinks)
                    .map((nameNameLink) => ({
                        label: formatNameNameLink(nameNameLink, nameId),
                        content: label,
                        dateEffectiveFrom: dateTimeFormatter.formatDate(
                            nameNameLink.dateEffectiveFrom
                        ),
                        dateEffectiveTo: dateTimeFormatter.formatDate(nameNameLink.dateEffectiveTo),
                    }))
                    .value();
            })
            .filter()
            .value()
);

import { currentUserHasAbilitySelector } from '../current-user/state/ui';
import redirectInsufficientPermissions from './redirectInsufficientPermissions';

/**
 * Check whether the current user has the required ability.
 * If they don't, then redirect them to the "you do not have
 * permissions" page as a side effect. Use this function in a React Router
 * `onEnter` hook.
 * @param  {string}   ability       Value from `client-common/enums/universal/abilitiesEnum`.
 * @param  {function} replace       Argument from `onEnter`.
 * @return {boolean}  Whether the user has permission.
 */
export default function checkPagePermissions(ability) {
    return (dispatch, getState) => {
        const canViewPage = currentUserHasAbilitySelector(getState())(ability);

        if (canViewPage) {
            return true;
        } else {
            dispatch(redirectInsufficientPermissions());
            return false; // just so the other method stops
        }
    };
}

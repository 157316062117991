import { PersonProfileDriversLicenseDetails } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { filter } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'driversLicenseDetails';
const personDriverLicenseDetailsModule = createNormalizedModule<PersonProfileDriversLicenseDetails>(
    {
        type: NEXUS_STATE_PROP,
    }
);

// SELECTORS
const personDriversLicenseDetailsSelector =
    personDriverLicenseDetailsModule.selectors.entitiesSelector;

export const personDriverLicenseDetailsByPersonProfileIdSelector = createSelector(
    personDriversLicenseDetailsSelector,
    (personDriversLicenseDetails) => (personProfileId: number) =>
        filter(personDriversLicenseDetails, { personProfileId })
);

// REDUCER
export default personDriverLicenseDetailsModule.reducerConfig;

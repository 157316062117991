import { filter, find, first, map, size } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { SelectOption as Country } from '@mark43/rms-api';
import { _Form, MFTFormConfiguration, Observer } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import type { ModuleShape } from '~/client-common/redux/state';
import { countriesSelector } from '~/client-common/core/domain/countries/state/data';
import { countriesUiSelector, loadCountries } from '~/client-common/core/domain/countries/state/ui';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

import { departmentProfileSelector } from '../../../../legacy-redux/selectors/departmentProfileSelectors';
import testIds from '../../../../core/testIds';
import { arbiterMFTInput } from '../../arbiter';
import type { SelectOption } from '../helpers/selectHelpers';
import Select from './selects/Select';
import Text, { Props as TextProps } from './Text';
import FormRow from './FormRow';

/**
 * Return a unique identifier for a country dialing code, e.g. "44~GB".
 *
 * `country.additionalData` is the dialing code, e.g. 44.
 *
 * `country.value` is the ISO 3166 Alpha-2 code, e.g. "GB". It is used as a unique identifier for each select dropdown
 * option because some countries have the same dialing code, and used to display the currently selected dialing code.
 */
function hashCountryCode(country: Country) {
    return `${country.additionalData}~${country.value}`;
}

/**
 * Country (SelectOption) comes from CountryCode.java from the neovisionaries library, which follows the ISO 3166
 * standard. https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 *
 * Use the return value as select dropdown options.
 */
function buildCountryCodeOptions(countries: ModuleShape<Country>): SelectOption[] {
    return map(countries, (country) => {
        const [, alpha2, name] = /^(.+) - (.+)$/.exec(country.display) || [];
        return {
            value: hashCountryCode(country),
            // Example: "US (+1)"
            // Special case: "UK (+44)" because UK customers prefer "UK" over "GB"
            label: `${alpha2 === 'GB' ? 'UK' : alpha2} (+${country.additionalData})`,
            // Example: "United Kingdom"
            subtitle: name,
        };
    });
}

/**
 * Use the return value as the `displayNumber` in API requests.
 */
function combineDisplayNumber(
    hashedCountryCode?: string,
    baseDisplayNumber?: string
): string | undefined {
    if (!baseDisplayNumber) {
        return;
    }
    const countryCode = hashedCountryCode ? `+${hashedCountryCode.split('~')[0]}` : '';
    return joinTruthyValues([countryCode, baseDisplayNumber], ' ');
}

/**
 * Shared dialing codes and their corresponding default country code
 */
const defaultDialingCodes = new Map<string, string>([
    ['1', 'US'],
    ['7', 'RU'],
    ['39', 'IT'],
    ['44', 'GB'],
    ['47', 'NO'],
    ['61', 'AU'],
    ['64', 'NZ'],
    ['212', 'MA'],
    ['262', 'YT'],
    ['358', 'FI'],
    ['500', 'FK'],
    ['590', 'MF'],
    ['599', 'CW'],
    ['672', 'NF'],
]);

/**
 * Determine the initial country code.
 * 1. For an existing phone number, use the code that comes with it (`initialCountryCode`).
 *    a. If the code matches multiple countries including the department profile's country (`defaultAlpha2`),
 *       then pick `defaultAlpha2`.
 *    b. Otherwise, get the matching country or the default value from defaultDialingCodes.
 * 2. For a new phone number, use the department profile's country (`defaultAlpha2`).
 */
function getInitialCountry({
    initialCountryCode,
    defaultAlpha2,
    countries,
}: {
    initialCountryCode?: string;
    defaultAlpha2: string;
    countries: ModuleShape<Country>;
}): Country | undefined {
    const defaultCountry = find(countries, {
        value: defaultAlpha2,
    });

    if (initialCountryCode) {
        const matches = filter(countries, { additionalData: initialCountryCode });

        if (defaultCountry && map(matches, 'value').includes(defaultCountry.value)) {
            return defaultCountry;
        } else {
            const defaultCountryCode = defaultDialingCodes.get(initialCountryCode);
            return defaultCountryCode
                ? first(filter(countries, { value: defaultCountryCode }))
                : first(filter(countries, { additionalData: initialCountryCode }));
        }
    } else {
        return defaultCountry;
    }
}

interface PhoneNumberInputProps
    extends Pick<
        TextProps,
        | 'fieldName'
        | 'label'
        | 'helpText'
        | 'placeholder'
        | 'error'
        | 'touched'
        | 'onChange'
        | 'onFocus'
        | 'onBlur'
    > {
    baseDisplayNumber?: string;
    hashedCountryCode?: string;
    initialCountryCode?: string;
    onChangeBaseDisplayNumber: (value?: string) => void;
    onChangeHashedCountryCode: (value?: string) => void;
    onChangeInitialCountryCode: (value?: string) => void;
    onChangeDisplayNumber: (value?: string) => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
    fieldName,
    label,
    helpText,
    placeholder,
    error,
    touched,
    baseDisplayNumber,
    hashedCountryCode,
    initialCountryCode,
    onChangeBaseDisplayNumber,
    onChangeHashedCountryCode,
    onChangeInitialCountryCode,
    onChangeDisplayNumber,
    onFocus,
    onBlur,
}) => {
    const dispatch = useDispatch();
    const countries = useSelector(countriesSelector);
    const countriesUi = useSelector(countriesUiSelector);
    const departmentProfile = useSelector(departmentProfileSelector);
    const fieldDisplayNames = useFields(fields.LOCATION_BIAS_COUNTRY_CODE);

    // make an API request to get the dropdown options
    React.useEffect(() => {
        if (size(countries) === 0 && !countriesUi?.loading) {
            dispatch(loadCountries());
        }
    }, [countries, countriesUi, dispatch]);

    React.useEffect(() => {
        if (size(countries) === 0 || hashedCountryCode) {
            return;
        }

        const initialCountry = getInitialCountry({
            initialCountryCode,
            defaultAlpha2: departmentProfile.locationBias.countryCode,
            countries,
        });

        if (initialCountry) {
            onChangeHashedCountryCode(hashCountryCode(initialCountry));
            onChangeInitialCountryCode(undefined);
        }
    }, [
        hashedCountryCode,
        initialCountryCode,
        onChangeHashedCountryCode,
        onChangeInitialCountryCode,
        countries,
        departmentProfile,
        dispatch,
    ]);

    return (
        <FormRow>
            <Select
                label={fieldDisplayNames.LOCATION_BIAS_COUNTRY_CODE}
                options={buildCountryCodeOptions(countries)}
                value={hashedCountryCode}
                onChange={(value) => {
                    if (typeof value === 'string') {
                        onChangeHashedCountryCode(value);
                        onChangeDisplayNumber(combineDisplayNumber(value, baseDisplayNumber));
                    }
                }}
                clearable={false}
                length="md"
                testId={testIds.PHONE_NUMBER_COUNTRY_CODE_SELECT}
            />
            <Text
                fieldName={fieldName}
                label={label}
                helpText={helpText}
                placeholder={placeholder}
                error={error}
                touched={touched}
                value={baseDisplayNumber}
                onChange={(value) => {
                    if (typeof value === 'string') {
                        onChangeBaseDisplayNumber(value);
                        onChangeDisplayNumber(combineDisplayNumber(hashedCountryCode, value));
                    }
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                length="md"
            />
        </FormRow>
    );
};

export default PhoneNumberInput;

interface ArbiterMFTPhoneNumberInputProps {
    form: _Form<MFTFormConfiguration>;
    path: string;
}

/**
 * This phone number input comprises 1 database-backed field, typically named `displayNumber`, and 3 internal fields:
 *
 * 1. `baseDisplayNumber` represents the phone number without the country code, which the user types into a text input.
 * 2. `hashedCountryCode` represents the currently selected country code, which the user selects in a dropdown. The
 *    dropdown options are loaded from the server, see the related helper functions for details.
 * 3. `initialCountryCode` is used to initialize the value of `hashedCountryCode` for an existing phone number, in order
 *    to keep all the conversion logic inside this component. You can remove this field and move the initialization to
 *    `convertToFormModel()`, but then you'd have to call `loadCountries()` elsewhere.
 *
 * The values of 1 and 2 combine to produce the full `displayNumber`. It is the only value that needs to be saved in
 * the API request. Validation runs on this field, not on the internal fields, meaning that a length constraint applies
 * to the concatenation of the 2 strings.
 */
export const ArbiterMFTPhoneNumberInput: React.FC<ArbiterMFTPhoneNumberInputProps> = arbiterMFTInput(
    (props: PhoneNumberInputProps & ArbiterMFTPhoneNumberInputProps) => {
        const { form, path: displayNumberPath } = props;

        // the 3 fields must exist in the form configuration using `createPhoneNumberFields()`
        const pattern = /[^.]+$/;
        const baseDisplayNumberPath = displayNumberPath.replace(pattern, 'baseDisplayNumber');
        const hashedCountryCodePath = displayNumberPath.replace(pattern, 'hashedCountryCode');
        const initialCountryCodePath = displayNumberPath.replace(pattern, 'initialCountryCode');

        return (
            <Observer<
                {
                    baseDisplayNumber?: string;
                    hashedCountryCode?: string;
                    initialCountryCode?: string;
                },
                MFTFormConfiguration
            >
                subscriptions={{
                    baseDisplayNumber: baseDisplayNumberPath,
                    hashedCountryCode: hashedCountryCodePath,
                    initialCountryCode: initialCountryCodePath,
                }}
                render={({ baseDisplayNumber, hashedCountryCode, initialCountryCode }) => {
                    return (
                        <PhoneNumberInput
                            {...props}
                            baseDisplayNumber={baseDisplayNumber}
                            hashedCountryCode={hashedCountryCode}
                            initialCountryCode={initialCountryCode}
                            onChangeBaseDisplayNumber={(value) =>
                                form.set(baseDisplayNumberPath, value)
                            }
                            onChangeHashedCountryCode={(value) =>
                                form.set(hashedCountryCodePath, value)
                            }
                            onChangeInitialCountryCode={(value) =>
                                form.set(initialCountryCodePath, value)
                            }
                            onChangeDisplayNumber={(value) => {
                                if (props.onChange) {
                                    props.onChange(value);
                                }
                            }}
                        />
                    );
                }}
            />
        );
    }
);

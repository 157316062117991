import { LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _, { map, first, get, find, size, tail } from 'lodash';
import { formatLocationLinkTypeId } from '~/client-common/helpers/linkTypesHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrIdSelector } from '~/client-common/core/domain/name-item-links/state/data/';

import { useSubPremiseFormatters } from '../../../../../modules/core/locations/hooks/useSubPremiseFormatters';

const strings = componentStrings.search.AdvancedSearchPropertyResults.OwnerCell;

function nameIsOwnerCreator(parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId) {
    return (elasticPropertyName) =>
        nameIsOwner(
            elasticPropertyName,
            parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId
        );
}

function nameIsOwner(
    elasticPropertyName,
    parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId
) {
    return (
        // Although we don't need to support backwards compatibility here
        // (linkType), let's continue using this function to keep things
        // unified
        parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId({
            nameItemAssociationAttrId: get(
                elasticPropertyName.nameItemLink,
                'nameItemAssociationAttrDetail.id'
            ),
            linkType: elasticPropertyName.nameItemLink.linkType,
        }) === globalAttributes.nameItemAssociationGlobal.owner
    );
}

// Most recent person to be an owner of this property if available,
// otherwise most recent organization to be an owner of this property
// otherwise nothing
function getOwner({ propertyPersons: persons, propertyOrganizations: organizations }, nameIsOwner) {
    return first([
        ..._(persons)
            .filter(nameIsOwner)
            .orderBy('nameItemLink.updatedDateUtc', 'desc')
            .map('person')
            .value(),
        ..._(organizations)
            .filter(nameIsOwner)
            .orderBy('nameItemLink.updatedDateUtc', 'desc')
            .map('organization')
            .value(),
    ]);
}

// Use Home Address if available, Otherwise use work address, otherwise
// use first address
function getRelevantLocation(locations) {
    return (
        // if there is no home or work address, use any address
        find(locations, ({ type }) => type === LinkTypesEnum.LIVES_AT) ||
        find(locations, ({ type }) => type === LinkTypesEnum.WORKS_AT) ||
        first(locations)
    );
}

export default compose(
    // Note - Object spreading causes it to lose its symbol
    // Reference: https://github.com/mark43/mark43/blob/develop/client/src/scripts/legacy-redux/components/core/tables/elasticPersonTable/BiographicalInfoCell.js#L31
    mapProps((ownerProps) => ({ elasticProperty: ownerProps })),
    connect(
        createStructuredSelector({
            parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId: parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrIdSelector,
        })
    )
)(function OwnerCell({
    parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId,
    elasticProperty,
}) {
    const nameIsOwner = nameIsOwnerCreator(
        parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId
    );
    const owner = getOwner(elasticProperty, nameIsOwner);
    const elasticLocation = getRelevantLocation(get(owner, 'involvedLocations', []));
    const phoneNumber = first(get(owner, 'phoneNumbers', []));
    const moreOwnersCount = size(
        tail([
            ..._(elasticProperty.propertyPersons).filter(nameIsOwner).value(),
            ..._(elasticProperty.propertyOrganizations).filter(nameIsOwner).value(),
        ])
    );

    const { buildElasticLocationLines } = useSubPremiseFormatters();

    return (
        <div className="elastic-property-owner elastic-locations-cell">
            {owner && (
                <div className="elastic-property-owner-name info-group">
                    <div className="info-label">{strings.ownerNameLabel}</div>
                    {/**
                     * use owner.name for an organization's name, otherwise get the fullName
                     * for a person
                     */}
                    <div className="info-text">{owner.name || formatFullName(owner)}</div>
                </div>
            )}
            {elasticLocation && (
                <div className="elastic-location">
                    <div className="elastic-location-type">
                        {`${formatLocationLinkTypeId(elasticLocation.type)}`}:
                    </div>
                    <div className="elastic-location-info">
                        {map(buildElasticLocationLines(elasticLocation), (line) => (
                            <div key={line}>{line}</div>
                        ))}
                    </div>
                </div>
            )}
            {phoneNumber && (
                <div className="elastic-property-owner-phone-number info-group">
                    <div className="info-label">{strings.phoneNumberLabel}</div>
                    <div className="info-text">{phoneNumber}</div>
                </div>
            )}
            {moreOwnersCount > 0 && (
                <div className="elastic-row-show-more info-group">
                    <div className="info-label" />
                    <div className="show-more-text">{`+ ${moreOwnersCount} more`}</div>
                </div>
            )}
        </div>
    );
});

import React from 'react';
import PropTypes from 'prop-types';
import _, { partial, noop } from 'lodash';
import classNames from 'classnames';
import testIds from '../../../core/testIds';

// helper to support and older API (where Tab accepts a prop `key`) and a
// new API (using `tabKey` instead of `key`) simultaneously
function tabKeyOrKey(tab) {
    return tab.props.tabKey || tab.key;
}

/**
 * A collection of tabs, only one of which is visible at any given time.
 * @param {string}   [props.selectedTab] `key` of the currently selected tab.
 * @param {function} [props.onSelectTab] Receives the `key` of a tab as an
 *   argument. This handler should update the `selectedTab` prop.
 * @param {string}   [props.className='mark43-nav-tabs']
 * @param {Object[]} props.children `Tab` components.
 */
export default class Tabs extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.selectedTab && this.props.children.length > 0) {
            this.state = {
                selectedTab: this.props.children[0].key,
            };
        } else {
            this.state = {};
        }
    }

    defaultOnSelectTab(key) {
        // no need to re-render if the user is reselecting the already selected
        // tab
        if (this.state.selectedTab !== key) {
            this.setState({
                selectedTab: key,
            });
        }
    }

    render() {
        const { className, children, lazyRenderTabs, renderTabLink } = this.props;

        // filter out any false/undefined children
        const filteredChildren = _.filter(children);
        const tabFromStateOrProps = this.props.selectedTab || this.state.selectedTab;
        // check if tab exists. if it does not, default to first tab
        const childTabKeys = React.Children.map(filteredChildren, (child) => tabKeyOrKey(child));
        const tabExists = _.includes(childTabKeys, tabFromStateOrProps);
        const selectedTab = tabExists ? tabFromStateOrProps : _.head(childTabKeys);
        const onSelectTab = this.props.onSelectTab || this.defaultOnSelectTab.bind(this);

        const links = _(filteredChildren)
            .map((tab) => {
                const onClick = tab.props.disabled ? noop : partial(onSelectTab, tabKeyOrKey(tab));

                return renderTabLink ? (
                    renderTabLink({
                        ...tab.props,
                        testId: testIds.TAB_LINK,
                        key: tabKeyOrKey(tab),
                        onClick,
                        className: classNames('mark43-tab-link', {
                            selected: !tab.props.disabled && tabKeyOrKey(tab) === selectedTab,
                            disabled: !!tab.props.disabled,
                        }),
                        selected: !tab.props.disabled && tabKeyOrKey(tab) === selectedTab,
                        disabled: !!tab.props.disabled,
                    })
                ) : (
                    <div
                        data-test-id={testIds.TAB_LINK}
                        key={tabKeyOrKey(tab)}
                        onClick={onClick}
                        className={classNames('mark43-tab-link', {
                            selected: !tab.props.disabled && tabKeyOrKey(tab) === selectedTab,
                            disabled: !!tab.props.disabled,
                        })}
                    >
                        {tab.props.title}
                    </div>
                );
            })
            .value();

        return (
            <div className={classNames('mark43-tabs', className)}>
                <div className="mark43-tab-links">{links}</div>
                {_(filteredChildren)
                    .map((tab) =>
                        React.cloneElement(tab, {
                            key: tabKeyOrKey(tab),
                            tabKey: tabKeyOrKey(tab),
                            selected: tabKeyOrKey(tab) === selectedTab,
                        })
                    )
                    // if lazyRenderTabs is true then we _only_ want to render
                    // the selected tab
                    .filter((tab) => (lazyRenderTabs ? tab.props.selected : true))
                    .value()}
            </div>
        );
    }
}

Tabs.propTypes = {
    selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelectTab: PropTypes.func,
    className: PropTypes.string,
    lazyRenderTabs: PropTypes.bool,
};

Tabs.defaultProps = {
    className: 'mark43-nav-tabs',
    lazyRenderTabs: false,
};

/**
 * Child of a `Tabs` component. Must contain a `key`. A tab comprises a link and
 *   a content section.
 *
 * @typedef Props
 * @prop {boolean} [selected] Whether this tab is currently
 *   selected. This prop is automatically generated by the parent `Tabs`.
 * @prop {ReactNode} [children]
 *
 * @param {Props} props
 *
 */
export function Tab({ selected, children, className }) {
    return (
        <div
            className={classNames('mark43-tab-content', { selected }, className)}
            data-test-id={testIds.TAB_CONTENT}
        >
            {children}
        </div>
    );
}

Tab.propTypes = {
    title: PropTypes.oneOfType([
        // displayed in the tab link
        PropTypes.string,
        PropTypes.element,
    ]).isRequired,
    tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
};

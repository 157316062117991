type FormDisplayEntry = { instanceId: number; mode: 'FORM' | 'SUMMARY' };
type FormDisplayStateTracker = Map<string | number, FormDisplayEntry[]>;

/**
 * This tracker is a very crude way of having global access to the current display state of a dragon card without us having
 * to lift state up from `DragonCard` and cause more re-renders when a card goes into edit or summary mode.
 *
 * If we encounter any extreme issues with this approach or we simple want to move away from a singleton that lives outside
 * of the component tree, we can refactor this into a context-based approach and make card summary state an observable.
 */
const trackerSingleton: FormDisplayStateTracker = new Map();

function getTrackingArray(configuredFormId: string | number): NonNullable<FormDisplayEntry[]> {
    const arr = trackerSingleton.get(configuredFormId.toString());
    if (!arr) {
        const displayEntries: FormDisplayEntry[] = [];
        trackerSingleton.set(configuredFormId.toString(), displayEntries);
        return displayEntries;
    }

    return arr;
}

export function setDisplayStateForConfiguredFormInstance({
    configuredFormId,
    instanceId,
    mode,
}: {
    configuredFormId: string | number;
    instanceId: number;
    mode: 'FORM' | 'SUMMARY';
}): void {
    const arr = getTrackingArray(configuredFormId).filter((x) => x.instanceId !== instanceId);
    arr.push({ instanceId, mode });
    trackerSingleton.set(configuredFormId.toString(), arr);
}

export function removeDisplayStateForConfiguredFormInstance({
    configuredFormId,
    instanceId,
}: {
    configuredFormId: string | number;
    instanceId: number;
}): void {
    const arr = getTrackingArray(configuredFormId).filter((x) => x.instanceId !== instanceId);
    trackerSingleton.set(configuredFormId.toString(), arr);
    if (!arr.length) {
        trackerSingleton.delete(configuredFormId);
    }
}

export function getDisplayStatesForConfiguredFormId(
    configuredFormId: string | number
): readonly FormDisplayEntry[] | undefined {
    return trackerSingleton.get(configuredFormId.toString());
}

import { entityTypeParamToEnum } from '~/client-common/helpers/entityProfileHelpers';

export type ProfileRouteParams = {
    entityId: string;
    entityType: keyof typeof entityTypeParamToEnum;
};

export const getEntityDataFromRouteParams = ({ entityId, entityType }: ProfileRouteParams) => ({
    entityId: Number(entityId),
    entityType: entityTypeParamToEnum[entityType],
});

import { NameCaseLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'nameCaseLinks';

const nameCaseLinksModule = createLinkModule<NameCaseLink>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// SELECTORS
export const nameCaseLinksWhereSelector = nameCaseLinksModule.selectors.linksWhereSelector;

// REDUCER
export default nameCaseLinksModule.reducerConfig;

import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ElasticReport } from '@mark43/rms-api';

import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { getViewModelProperties, ViewModel } from '~/client-common/helpers/viewModelHelpers';
import { reportDefinitionRestrictViewReportOwnersSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { canManage } from '~/client-common/core/domain/entity-permissions/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import Link from '../../../../../modules/core/components/links/Link';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';

import ElasticCell from '../ElasticCell';
import { RestrictVisibilityWithDash } from '../../../../../modules/core/components/RestrictVisibilityWithDash';

const AgencyNameContainer = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

type InfoCellProps = {
    elasticReportViewModel: ViewModel<ElasticReport, { currentSubmissionOwnerId?: string }>;
    currentUserDepartmentId: ReturnType<typeof currentUserDepartmentIdSelector>;
    departmentNameFromConsortiumLinksByDepartmentId: ReturnType<
        typeof departmentNameFromConsortiumLinksByDepartmentIdSelector
    >;
};

function InfoCell({
    elasticReportViewModel,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}: InfoCellProps) {
    const viewModelProps = getViewModelProperties(elasticReportViewModel);
    const isExternalAgency = elasticReportViewModel.departmentId !== currentUserDepartmentId;
    const departmentDisplayTag = isExternalAgency
        ? ` (${departmentNameFromConsortiumLinksByDepartmentId(
              elasticReportViewModel.departmentId
          )})`
        : undefined;
    const ren = elasticReportViewModel.reportingEventNumber;
    const { restrictViewReportOwners } = elasticReportViewModel.reportDefinition;

    return (
        <ElasticCell row={elasticReportViewModel}>
            {(isClickable: boolean) => (
                <div className="elastic-report-info">
                    <Link
                        disabled={!isClickable}
                        hoverOnUnderline={true}
                        disabledColor="darkGrey"
                        onClick={(event: MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
                        to={`reports/${elasticReportViewModel.id}`}
                    >
                        {ren &&
                            (elasticReportViewModel.isLegacy ? (
                                <div className="legacy-search-result">{`${ren}*`}</div>
                            ) : (
                                <div>{ren}</div>
                            ))}
                    </Link>
                    {departmentDisplayTag && (
                        <AgencyNameContainer>{departmentDisplayTag}</AgencyNameContainer>
                    )}
                    <div>
                        <FeatureFlagged
                            flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                            fallback={viewModelProps.currentSubmissionOwnerId}
                        >
                            <RestrictVisibilityWithDash
                                restricted={
                                    restrictViewReportOwners &&
                                    !canManage(elasticReportViewModel.permissionSet)
                                }
                            >
                                {viewModelProps.currentSubmissionOwnerId}
                            </RestrictVisibilityWithDash>
                        </FeatureFlagged>
                    </div>
                </div>
            )}
        </ElasticCell>
    );
}

export default compose<InfoCellProps, { columnKey: 'info' }>(
    mapProps((ownerProps) => ({ elasticReportViewModel: ownerProps })),
    connect(
        createStructuredSelector({
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
            reportDefinitionRestrictViewReportOwners: reportDefinitionRestrictViewReportOwnersSelector,
        })
    )
)(InfoCell);

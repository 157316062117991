import React from 'react';
import { withTheme } from 'styled-components';
import Highlighter from '../../../core/components/Highlighter';

const QuickSearchHighlighter = ({ textToHighlight, searchWords }) => (
    <Highlighter
        highlightColor="var(--arc-colors-selected-content)"
        highlightBackgroundColor="var(--arc-colors-selected-default)"
        searchWords={searchWords}
        textToHighlight={textToHighlight}
    />
);

export default withTheme(QuickSearchHighlighter);

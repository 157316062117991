import _, { get } from 'lodash';

/**
 * @param  {Object|undefined} injuryType `undefined` when not set for an injury.
 * @param  {Object|undefined} bodyPart   `undefined` when not set for an injury.
 * @param  {string}           description
 * @return {string} Display string.
 */
export function formatPersonInjury(injuryType, bodyPart, description) {
    return _([get(injuryType, 'val'), get(bodyPart, 'val'), description])
        .compact()
        .join(', ');
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import invariant from 'invariant';
import { createSelector } from 'reselect';
import { filter, isArray, ObjectIterateeCustom, get } from 'lodash';

import { createWithEntityItems } from 'nexus-mark43';
import { createWithRemove, normalizedModuleCompatStrategy } from 'probes-mark43';
import type { ModuleShape, RootState } from '../../redux/state';

const withRemove = createWithRemove({ key: 'config' });
const withEntityItems = createWithEntityItems({ key: 'items' });

export type { ModuleShape };

// a cache of the names of existing modules, used to ensure that no duplicates
// are created
const existingModules: { [index: string]: boolean } = {};

/**
 * creates a module for entities that should be normalized and returns actions, selectors and a reducer
 * @param   options.type    actionType, often the snake case of the entity type, eg, VEHICLES
 * @param   options.key     The key to normalize on, defaults to id
 * @return                 action creators and data reducer
 */
export default function createNormalizedModule<T = unknown>({
    type,
    key = 'id',
}: {
    type: string;
    key?: string;
}) {
    invariant(
        !existingModules[type],
        `A normalized module for the specified 'type' ${type} already exists`
    );

    // we have to wrap the base selector because nexus introduces a new
    // nesting layer and wrapping here instead of the module level
    // allows us to hide this fact from the consuming modules for now
    // The downside to this is that modules cannot be grouped by anything else
    // than then string "global" for now. This is a limitation due to how
    // we have to roll this feature out, because everything else will
    // require more refactoring of our selectors to support safe access
    // of nested data. Right now Nexus does not create a dummy top level
    // object for each entity. This could be done but the second level
    // cannot be stubbed because it depends on the grouping strategy.
    const defaultEmpty = {};
    const safeBaseSelector = createSelector(
        (state: RootState) =>
            // @ts-expect-error TODO: Change `type` from string to a union of the strings defined in RootState
            state.dataNexus[type] as { global: ModuleShape<T> },
        (value) => get(value, 'global', defaultEmpty)
    );
    const actionTypes = createActionTypes(type);
    const actionCreators = createActionCreators<T>(key, type, actionTypes);
    const selectors = createSelectors<T>(safeBaseSelector);
    const normalizedMerge = normalizedModuleCompatStrategy({
        key,
        metaKey: 'meta',
        tagsKey: 'tags',
    });
    existingModules[type] = true;

    return {
        actionCreators,
        selectors,
        reducerConfig: {
            [type]: {
                indexStrategy: normalizedMerge.indexStrategy,
                mergeStrategy: normalizedMerge,
            },
        },
        actionTypes,
        withEntityItems,
        withRemove,
    };
}

function createActionTypes(type: string) {
    return {
        storeEntities: `core/STORE_${type}S`,
        replaceEntitiesWhere: `core/REPLACE_${type}S_WHERE`,
        deleteEntity: `core/DELETE_${type}`,
        deleteEntitiesWhere: `core/DELETE_${type}S_WHERE`,
    };
}

function createActionCreators<T>(
    key: string,
    type: string,
    actionTypes: ReturnType<typeof createActionTypes>
) {
    return {
        storeEntities: (data: T | T[] = []) =>
            withEntityItems(
                {
                    [type]: isArray(data) ? data : [data],
                },
                { type: actionTypes.storeEntities }
            ),
        replaceEntitiesWhere: (
            predicate: ObjectIterateeCustom<ModuleShape<T>, any>,
            newEntities: T | T[] = []
        ) =>
            withEntityItems(
                {
                    [type]: isArray(newEntities) ? newEntities : [newEntities],
                },
                withRemove(type, predicate, { type: actionTypes.replaceEntitiesWhere })
            ),
        deleteEntity: (entityKey: string | number) =>
            withRemove(type, { [key]: entityKey }, { type: actionTypes.deleteEntity }),
        deleteEntitiesWhere: (predicate: ObjectIterateeCustom<ModuleShape<T>, any>) =>
            withRemove(type, predicate, { type: actionTypes.deleteEntitiesWhere }),
    };
}

function createSelectors<T>(baseSelector: (state: RootState) => ModuleShape<T>) {
    return {
        entitiesSelector: baseSelector,
        entityByIdSelector: createSelector(baseSelector, (entities) => (key?: string | number) =>
            entities[key as string | number] as T | undefined
        ),
        entitiesWhereSelector: createSelector(
            baseSelector,
            (entities) => (predicate: ObjectIterateeCustom<ModuleShape<T>, any>) =>
                filter(entities, predicate)
        ),
    };
}

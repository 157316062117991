import { FieldTypeEnum } from '@mark43/rms-api';
import {
    ITEM_PROFILE_BIOHAZARD_DESCRIPTION,
    ITEM_PROFILE_IS_BIOHAZARD,
    ITEM_PROFILE_ITEM_TYPE_VEHICLE_DESCRIPTION,
    ITEM_PROFILE_ITEM_TYPE_VEHICLE_ITEM_CATEGORY_ATTR_ID,
    ITEM_PROFILE_ITEM_TYPE_VEHICLE_PRIMARY_COLOR_ATTR_ID,
    ITEM_PROFILE_ITEM_TYPE_VEHICLE_SECONDARY_COLOR_ATTR_ID,
    VEHICLE_BODY_STYLE_ATTR_ID,
    VEHICLE_BODY_STYLE_OTHER,
    VEHICLE_INSURANCE_POLICY_NUMBER,
    VEHICLE_INSURANCE_PROVIDER_NAME,
    VEHICLE_REGISTRATION_STATE_ATTR_ID,
    VEHICLE_REGISTRATION_TYPE,
    VEHICLE_REGISTRATION_YEAR,
    VEHICLE_TAG,
    VEHICLE_VEHICLE_MAKE_ATTR_ID,
    VEHICLE_VEHICLE_MAKE_ID,
    VEHICLE_VEHICLE_MODEL_ATTR_ID,
    VEHICLE_VEHICLE_MODEL_ID,
    VEHICLE_VIN_NUMBER,
    VEHICLE_YEAR_OF_MANUFACTURE,
    DISPLAY_MERGE_ENTITIES_MAKE_MODEL_GROUPING_VEHICLES,
    ITEM_PROFILE_ITEM_MAKE,
    ITEM_PROFILE_ITEM_MODEL,
} from '~/client-common/core/enums/universal/fields';

const { STRING, ATTRIBUTE, BOOLEAN, INTEGER, ID } = FieldTypeEnum;

/**
 * This array describes all mergeable properties of the person profile model.
 * Additionally the order of these keys defines the order in which
 * fields are going to be rendered on the merge entities dashboard.
 */
const vehicleProfileMergeableFields = [
    {
        prop: 'vinNumber',
        field: VEHICLE_VIN_NUMBER,
        type: STRING,
    },
    {
        prop: 'description',
        field: ITEM_PROFILE_ITEM_TYPE_VEHICLE_DESCRIPTION,
        type: STRING,
    },
    {
        prop: 'tag',
        field: VEHICLE_TAG,
        type: STRING,
    },
    {
        prop: 'yearOfManufacture',
        field: VEHICLE_YEAR_OF_MANUFACTURE,
        type: INTEGER,
    },
    {
        prop: 'registrationStateAttrId',
        field: VEHICLE_REGISTRATION_STATE_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'registrationType',
        field: VEHICLE_REGISTRATION_TYPE,
        type: STRING,
    },
    {
        prop: 'registrationYear',
        field: VEHICLE_REGISTRATION_YEAR,
        type: INTEGER,
    },
    {
        prop: 'itemCategoryAttrId',
        field: ITEM_PROFILE_ITEM_TYPE_VEHICLE_ITEM_CATEGORY_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: DISPLAY_MERGE_ENTITIES_MAKE_MODEL_GROUPING_VEHICLES,
        field: DISPLAY_MERGE_ENTITIES_MAKE_MODEL_GROUPING_VEHICLES,
        relatedFields: [
            {
                prop: 'vehicleMakeAttrId',
                field: VEHICLE_VEHICLE_MAKE_ATTR_ID,
                type: ATTRIBUTE,
            },
            {
                prop: 'vehicleMakeId',
                field: VEHICLE_VEHICLE_MAKE_ID,
                type: ID,
            },
            {
                prop: 'itemMake',
                field: ITEM_PROFILE_ITEM_MAKE,
                type: STRING,
            },
            {
                prop: 'vehicleModelAttrId',
                field: VEHICLE_VEHICLE_MODEL_ATTR_ID,
                type: ATTRIBUTE,
            },
            {
                prop: 'vehicleModelId',
                field: VEHICLE_VEHICLE_MODEL_ID,
                type: ID,
            },
            {
                prop: 'itemModel',
                field: ITEM_PROFILE_ITEM_MODEL,
                type: STRING,
            },
        ],
    },
    {
        prop: 'bodyStyleAttrId',
        field: VEHICLE_BODY_STYLE_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'bodyStyleOther',
        field: VEHICLE_BODY_STYLE_OTHER,
        type: STRING,
    },
    {
        prop: 'primaryColorAttrId',
        field: ITEM_PROFILE_ITEM_TYPE_VEHICLE_PRIMARY_COLOR_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'secondaryColorAttrId',
        field: ITEM_PROFILE_ITEM_TYPE_VEHICLE_SECONDARY_COLOR_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'isBiohazard',
        field: ITEM_PROFILE_IS_BIOHAZARD,
        type: BOOLEAN,
    },
    {
        prop: 'biohazardDescription',
        field: ITEM_PROFILE_BIOHAZARD_DESCRIPTION,
        type: STRING,
    },
    {
        prop: 'insuranceProviderName',
        field: VEHICLE_INSURANCE_PROVIDER_NAME,
        type: STRING,
    },
    {
        prop: 'insurancePolicyNumber',
        field: VEHICLE_INSURANCE_POLICY_NUMBER,
        type: STRING,
    },
];

export default vehicleProfileMergeableFields;

import { RefContextEnum, ReportComment } from '@mark43/rms-api';
import React, { useRef, useEffect } from 'react';
import { trim } from 'lodash';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { createField, createFormConfiguration, lifecycleOptions, Observer } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { REPORT_COMMENT_COMMENT } from '~/client-common/core/enums/universal/fields';
import { saveReportComment } from '~/client-common/core/domain/report-comments/state/data';
import { Button as ArcButton } from '../../../../core/components/Button';

import Row from '../../../../core/components/Row';

import { ArbiterMFTTextArea as _ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import testIds from '../../../../../core/testIds';
import { RmsDispatch } from '../../../../../core/typings/redux';

interface ReportCommentFormPropsT {
    reportId: number;
    submitReportCommentForm: (reportId: number, comment: string) => Promise<ReportComment>;
}

const strings = componentStrings.reports.core.ReportCommentForm;

const ArbiterMFTTextArea = styled(_ArbiterMFTTextArea)`
    margin-top: 0 !important;
`;

const ReportCommentForm = ({ reportId, submitReportCommentForm }: ReportCommentFormPropsT) => {
    const commentRef = useRef<HTMLTextAreaElement | null>(null);

    const { getForm } = useFormGetter();

    useEffect(() => {
        const form = getForm(RefContextEnum.FORM_REPORT_COMMENT.name);
        if (form) {
            const formModel = form.get();
            const { comments } = formModel;

            if (!!comments) {
                form.resetModel();
            }
        }
    }, [getForm]);

    return (
        <ArbiterForm
            name={RefContextEnum.FORM_REPORT_COMMENT.name}
            context={RefContextEnum.FORM_REPORT_COMMENT.name}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            configuration={createFormConfiguration({
                comments: createField<string>({
                    fieldName: REPORT_COMMENT_COMMENT,
                }),
            })}
            render={(form) => (
                <Row>
                    <ArbiterMFTTextArea
                        path="comments"
                        rows={2}
                        resizable={false}
                        autoResize={true}
                        label={undefined}
                        setRef={(ref: HTMLTextAreaElement) => (commentRef.current = ref)}
                    />
                    <Observer
                        subscriptions={{ comments: 'comments' }}
                        render={({ comments }: { comments: string }) => (
                            <ArcButton
                                variant="solid"
                                isTextTransformNone
                                // TODO: Remove this once we
                                // have proper layout components;
                                // we shouldn't need to apply a margin on a button
                                style={{ marginBottom: '20px' }}
                                isDisabled={!trim(comments)}
                                onClick={() =>
                                    form
                                        .submit()
                                        .then(() =>
                                            submitReportCommentForm(reportId, trim(comments))
                                        )
                                        .then(() => form.resetModel())
                                        .then(() => commentRef?.current?.focus())
                                }
                                testId={testIds.REPORT_STATUS_COMMENTS_CARD_COMMENT_BUTTON}
                            >
                                {strings.submit}
                            </ArcButton>
                        )}
                    />
                </Row>
            )}
        />
    );
};

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    submitReportCommentForm: (reportId: number, comment: string) =>
        dispatch(saveReportComment(reportId, comment)),
});

export default connect(null, mapDispatchToProps)(ReportCommentForm);

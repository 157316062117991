import { OffenseCaseStatus } from '@mark43/rms-api';
import { values } from 'lodash';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'offenseCaseStatuses';

const offenseCaseStatusesModule = createNormalizedModule<OffenseCaseStatus>({
    type: NEXUS_STATE_PROP,
    // offenseId is guaranteed unique
    key: 'offenseId',
});

// SELECTORS
export const offenseCaseStatusByOffenseIdSelector =
    offenseCaseStatusesModule.selectors.entitiesSelector;

export const offenseCaseStatusesSelector = createSelector(
    offenseCaseStatusByOffenseIdSelector,
    (offenseCaseStatuses) => values(offenseCaseStatuses)
);

// REDUCER
export default offenseCaseStatusesModule.reducerConfig;

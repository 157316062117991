import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';

import { ComplianceResubmissionStatusView } from '@mark43/rms-api';
import {
    Form,
    createField,
    createFormConfiguration,
    lifecycleOptions,
    InferFormDataShape,
    formEvents,
} from 'markformythree';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import testIds from '../../../core/testIds';
import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import Row from '../../core/components/Row';
import { currentUserDepartmentIdSelector } from '../../core/current-user/state/ui';
import { MFTCheckbox } from '../../core/forms/components/checkboxes/Checkbox';
import { MFTAgencyProfileWithOriSelect } from '../../core/forms/components/selects/AgencyProfileSelect';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../core/validation/yupMftValidation';

import { useGetLastExportedDate } from '../hooks';
import { ComplianceExportType } from '../types';

const GenerateButton = styled(Button)`
    margin-top: 19px;
`;

type ComplianceResubmissionFormProps = {
    complianceExportType: ComplianceExportType;
    disabled: boolean;
    forceUpdate?: () => void;
    isNibrs: boolean;
    isLoading: boolean;
    reports?: ComplianceResubmissionStatusView[];
    updateFormNotMatchingErrorsState: () => void;
    onGenerateReportsAndErrors: () => void;
};

const labels = componentStrings.compliance.ComplianceResubmissionForm.labels;
const validationStrings = componentStrings.validation;

export const complianceResubmissionFormConfiguration = createFormConfiguration({
    agencyId: createField<number>({}),
    exportZeroReportSegment: createField<boolean>({}),
});

type FormComplianceResubmissionFormConfiguration = typeof complianceResubmissionFormConfiguration;
type ComplianceResubmissionFormModel = InferFormDataShape<FormComplianceResubmissionFormConfiguration>;

const ExportZeroReportSegmentCheckbox = styled(MFTCheckbox)`
    margin-left: 530px;
`;

const TestIdWrapper = styled.div`
    float: left;
`;

const validationSchema = yup.object().shape({
    agencyId: yup.number().required(validationStrings.requiredError),
    exportZeroReportSegment: yup.boolean(),
});

export function convertFromResubmissionFormModel(
    formModel: ComplianceResubmissionFormModel,
    segmentGenerationEnabled: boolean,
    currentAgencyId: number | undefined
) {
    const { exportZeroReportSegment } = formModel;

    return {
        agencyId: segmentGenerationEnabled ? currentAgencyId : formModel.agencyId,
        exportZeroReportSegment,
        // NibrsExportQuery type requires totalUpdatedReportSize
        // but the client payload value is not used, instead the server sets it based on query
        totalUpdatedReportSize: 0,
        resubmission: true,
    };
}

const ComplianceResubmissionForm: React.FC<ComplianceResubmissionFormProps> = (props) => {
    const {
        isLoading,
        complianceExportType,
        isNibrs,
        onGenerateReportsAndErrors,
        updateFormNotMatchingErrorsState,
    } = props;

    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const agencyProfileSelectPredicate = { departmentId: currentUserDepartmentId };

    const offenseDisplayName = useOffenseFieldName();

    const { callResource, loading } = useGetLastExportedDate(complianceExportType);

    const disabled = props.disabled || loading.isLoading;

    const handleAgencyChange = useCallback(
        (agencyId) => {
            updateFormNotMatchingErrorsState();
            callResource(agencyId);
        },
        [callResource, updateFormNotMatchingErrorsState]
    );

    const handleValidation = useCallback(({ formState, eventType }) => {
        const $form = formState.ui.$form;
        return validationSchema
            .validate(formState.model, { abortEarly: false })
            .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
            .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
    }, []);

    return (
        <Form
            configuration={complianceResubmissionFormConfiguration}
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={formClientEnum.COMPLIANCE_RESUBMISSION}
            onValidate={handleValidation}
            validationEvents={[
                { eventType: formEvents.FORM_SUBMIT },
                { eventType: formEvents.INPUT_BLUR },
                { eventType: formEvents.INPUT_CHANGE },
            ]}
            render={(form) => {
                const model = form.get();
                return (
                    <>
                        <Row>
                            <TestIdWrapper
                                data-test-id={testIds.COMPLIANCE_DASHBOARD_AGENCY_PROFILE_SELECT}
                            >
                                <MFTAgencyProfileWithOriSelect
                                    clearable={false}
                                    disabled={disabled}
                                    label={labels.agencySelect}
                                    onChange={handleAgencyChange}
                                    path="agencyId"
                                    predicate={agencyProfileSelectPredicate}
                                    width={390}
                                />
                            </TestIdWrapper>
                            <GenerateButton
                                testId={testIds.COMPLIANCE_DASHBOARD_GENERATE_REPORTS_BUTTON}
                                disabled={isLoading || !model?.agencyId}
                                onClick={onGenerateReportsAndErrors}
                                className={buttonTypes.PRIMARY}
                            >
                                {labels.generateReportsButton}
                            </GenerateButton>
                        </Row>
                        <FeatureFlagged
                            flag="RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED"
                            fallback={
                                <Row>
                                    {isNibrs && (
                                        <FeatureFlagged flag="NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX_ENABLED">
                                            <ExportZeroReportSegmentCheckbox
                                                disabled={disabled}
                                                testId={
                                                    testIds.COMPLIANCE_DASHBOARD_NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX
                                                }
                                                helpText={labels.exportZeroReportSegmentHelpText(
                                                    offenseDisplayName
                                                )}
                                                label={labels.exportZeroReportSegmentCheckbox}
                                                onChange={updateFormNotMatchingErrorsState}
                                                path="exportZeroReportSegment"
                                            />
                                        </FeatureFlagged>
                                    )}
                                </Row>
                            }
                        />
                    </>
                );
            }}
        />
    );
};

export default ComplianceResubmissionForm;

import { SystemRuleEnum } from '@mark43/rms-api';
import _ from 'lodash';
import addRuleId from '../helpers/addRuleId';
import { setKoModelValidation } from '../helpers/koValidationHelper';
import { checkPhoneLength } from './phoneValidationHelpers';

export const personAgeRangeValidator = addRuleId(
    SystemRuleEnum.DATE_OF_BIRTH_RANGE.name,
    (data, msg) => {
        let isValid = true;

        if (
            data.person.ageRangeMax &&
            data.person.ageRangeMax() &&
            data.person.ageRangeMin &&
            data.person.ageRangeMin() &&
            data.person.ageRangeMax() < data.person.ageRangeMin()
        ) {
            isValid = false;
        }

        setKoModelValidation(data.person.ageRangeMin, isValid, msg);
        setKoModelValidation(data.person.ageRangeMax, isValid, msg);

        return isValid;
    }
);

export const defendantAddressRequired = addRuleId(
    SystemRuleEnum.DEFENDANT_ADDRESS_REQUIRED.name,
    (data) => {
        return data.hasHomeAddress || data.hasWorkAddress;
    }
);

export const defendantRaceRequired = addRuleId(
    SystemRuleEnum.DEFENDANT_RACE_REQUIRED.name,
    (data, msg) => {
        const isValid = !!data.person.raceAttrId();
        setKoModelValidation(data.person.raceAttrId, isValid, msg);
        return isValid;
    }
);

export const defendantDOBRequired = addRuleId(
    SystemRuleEnum.DEFENDANT_DOB_REQUIRED.name,
    (data, msg) => {
        const isValid = !!data.person.dateOfBirth();
        setKoModelValidation(data.person.dateOfBirth, isValid, msg);
        return isValid;
    }
);

export const knownPersonDateOfEmancipationAfterDateOfBirth = addRuleId(
    SystemRuleEnum.KNOWN_PERSON_DATE_OF_EMANCIPATION_MUST_BE_AFTER_DOB.name,
    (data, msg) => {
        let isValid = true;
        if (
            data.person.dateOfBirth &&
            data.person.dateOfBirth() &&
            data.person.dateOfEmancipation &&
            data.person.dateOfEmancipation() &&
            data.person.dateOfEmancipation() < data.person.dateOfBirth()
        ) {
            isValid = false;
        }
        setKoModelValidation(data.person.dateOfEmancipation, isValid, msg);
        return isValid;
    }
);

export const knownPersonSexRequired = addRuleId(
    SystemRuleEnum.KNOWN_PERSON_SEX_REQUIRED.name,
    (data, msg) => {
        const isValid = !!data.person.sexAttrId();
        setKoModelValidation(data.person.sexAttrId, isValid, msg);
        return isValid;
    }
);

export const missingPersonHomeAddress = addRuleId(
    SystemRuleEnum.MISSING_PERSON_HOME_ADDRESS_REQUIRED.name,
    (data) => {
        return !!data.hasHomeAddress;
    }
);

export const missingPersonRaceRequired = addRuleId(
    SystemRuleEnum.MISSING_PERSON_RACE_REQUIRED.name,
    (data, msg) => {
        const isValid = !!data.person.raceAttrId();
        setKoModelValidation(data.person.raceAttrId, isValid, msg);
        return isValid;
    }
);

export const missingPersonAgeRequired = addRuleId(
    SystemRuleEnum.MISSING_PERSON_DOB_OR_EQUIV_REQUIRED.name,
    (data) => {
        return !!(
            data.person.dateOfBirth() ||
            (data.person.ageRangeMax() && data.person.ageRangeMin())
        );
    }
);

export const suspectPersonAgeRequired = addRuleId(
    SystemRuleEnum.OFFENSE_SUSPECT_DOB_OR_EQUIV_REQUIRED.name,
    (data) => {
        return !!(
            data.person.dateOfBirth() ||
            (data.person.ageRangeMax() && data.person.ageRangeMin())
        );
    }
);

export const victimRaceRequired = addRuleId(
    SystemRuleEnum.VICTIM_RACE_REQUIRED.name,
    (data, msg) => {
        const isValid = !!data.person.raceAttrId();
        setKoModelValidation(data.person.raceAttrId, isValid, msg);
        return isValid;
    }
);

export const victimAddressRequired = addRuleId(
    SystemRuleEnum.OFFENSE_VICTIM_ADDRESS_REQUIRED.name,
    (data) => {
        return !!(data.hasHomeAddress || data.hasWorkAddress);
    }
);
export const victimPersonAgeRequired = addRuleId(
    SystemRuleEnum.OFFENSE_VICTIM_DOB_OR_EQUIV_REQUIRED.name,
    (data) => {
        return !!(
            data.person.dateOfBirth() ||
            data.person.ageRangeMax() ||
            data.person.ageRangeMin()
        );
    }
);

export const driverLicenseOtherRequired = addRuleId(
    SystemRuleEnum.PERSON_DRIVER_LICENSE_OTHER_REQUIRED.name,
    (data, msg) => {
        const isValid = data.person.isDlTypeOther() ? !!data.person.dlTypeOther() : true;
        setKoModelValidation(data.person.dlTypeOther, isValid, msg);
        return isValid;
    }
);

// just makes sure they have employer names
// could be made dynamic
export const employerNameRequired = addRuleId(
    SystemRuleEnum.EMPLOYER_NAME_REQUIRED.name,
    (data, msg) => {
        let allAreValid = true;
        _.forEach(data.employmentHistories, (history) => {
            const isValid = !!history.employerName();
            setKoModelValidation(history.employerName, isValid, msg);
            if (!isValid) {
                allAreValid = false;
            }
        });
        return allAreValid;
    }
);

// make sure military histories have military branches
// could be made dynamic
export const militaryBranchRequired = addRuleId(
    SystemRuleEnum.MILITARY_BRANCH_REQUIRED.name,
    (data, msg) => {
        let allAreValid = true;
        _.forEach(data.militaryHistories, (history) => {
            const isValid = !!history.militaryBranchAttrId();
            setKoModelValidation(history.militaryBranchAttrId, isValid, msg);
            if (!isValid) {
                allAreValid = false;
            }
        });
        return allAreValid;
    }
);

// School History phone number validator
export const personSchoolHistoryDisplayNumberSpecialMaxLength = addRuleId(
    SystemRuleEnum.PERSON_SCHOOL_HISTORY_DISPLAY_NUMBER_SPECIAL_MAX_LENGTH.name,
    (data, errMsg) => {
        let allAreValid = true;
        _.forEach(data.schoolHistories, (history) => {
            const isValid = checkPhoneLength(history.displayNumber());
            setKoModelValidation(history.displayNumber, isValid, errMsg);
            if (!isValid) {
                allAreValid = false;
            }
        });
        return allAreValid;
    }
);

export const emailTypeRequiredIfFilled = addRuleId(
    SystemRuleEnum.EMAIL_TYPE_REQUIRED_IF_EMAIL_FILLED.name,
    (data, msg) => {
        let allAreValid = true;
        _.forEach(data.emails, (email) => {
            const isValid = email.emailAddress() ? !!email.emailType() : true;
            setKoModelValidation(email.emailType, isValid, msg);
            if (!isValid) {
                allAreValid = false;
            }
        });
        return allAreValid;
    }
);

// also runs the reverse: if type is filled then identifier is required
export const idTypeRequiredIfFilled = addRuleId(
    SystemRuleEnum.ID_TYPE_REQUIRED_IF_IDENTIFIER_FILLED.name,
    (data, msg) => {
        let allAreValid = true;
        _.forEach(data.identifiers, (identifier) => {
            const isTypeValid = identifier.identifier()
                ? !!identifier.nameIdentifierTypeAttrId()
                : true;
            setKoModelValidation(identifier.nameIdentifierTypeAttrId, isTypeValid, msg);
            const isIdentifierValid = identifier.nameIdentifierTypeAttrId()
                ? !!identifier.identifier()
                : true;
            setKoModelValidation(identifier.identifier, isIdentifierValid, msg);
            if (!isTypeValid || !isIdentifierValid) {
                allAreValid = false;
            }
        });
        return allAreValid;
    }
);

export const phoneSpecialMaxLength = addRuleId(
    SystemRuleEnum.PHONE_SPECIAL_MAX_LENGTH.name,
    (data, msg) => {
        let allAreValid = true;
        _.forEach(data.phones, (phone) => {
            const isValid = checkPhoneLength(phone.displayNumber());
            setKoModelValidation(phone.displayNumber, isValid, msg);
            if (!isValid) {
                allAreValid = false;
            }
        });
        return allAreValid;
    }
);

export const phoneTypeRequiredIfNumberFilled = addRuleId(
    SystemRuleEnum.PHONE_TYPE_REQUIRED_IF_NUMBER_FILLED.name,
    (data, msg) => {
        let allAreValid = true;
        _.forEach(data.phones, (phone) => {
            const isValid = phone.displayNumber() ? !!phone.phoneType() : true;
            setKoModelValidation(phone.phoneType, isValid, msg);
            if (!isValid) {
                allAreValid = false;
            }
        });
        return allAreValid;
    }
);

export const towVehicleOwnerRequiresPhoneNumber = addRuleId(
    SystemRuleEnum.TOW_VEHICLE_OWNER_REQUIRES_PHONE_NUMBER.name,
    (data) => {
        return !!data.phones.length;
    }
);

export const towVehicleOwnerRequiresHomeAddress = addRuleId(
    SystemRuleEnum.TOW_VEHICLE_OWNER_REQUIRES_HOME_ADDRESS.name,
    (data) => {
        return !!data.hasHomeAddress;
    }
);

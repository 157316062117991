import React, { CSSProperties } from 'react';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router';
import styled from 'styled-components';
import { omitProps } from '~/client-common/helpers/reactHelpers';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';
import Icon, { iconTypes } from '../Icon';
import { withAnalytics } from '../../../analytics/components/withAnalytics';
import rmsTheme from '../../styles/theme';

export const PopoutIcon = styled(Icon)`
    padding-left: 5px;
`;

export type LinkProps = {
    openInNewTab?: boolean;
    disabled?: boolean;
    testId?: string;
    testType?: string;
    testDisplay?: string;
    hoverOnUnderline?: boolean;
    disabledColor?: string;
} & Omit<ReactRouterLinkProps, 'onClick'> &
    Omit<React.HTMLAttributes<HTMLSpanElement>, 'onClick'>;

const BaseLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (
        { openInNewTab = false, disabled = false, testId, testType, testDisplay, ...otherProps },
        forwardedRef
    ) => {
        return disabled ? (
            <span {...otherProps} data-test-id={testId} data-test-type={testType} />
        ) : (
            <ReactRouterLink
                {...otherProps}
                // @ts-expect-error it says it's not valid but it is working: https://github.com/quintoandar/react-router/blob/master/docs/API.md#innerref
                innerRef={forwardedRef}
                target={openInNewTab ? '_blank' : '_self'}
                data-test-id={testId}
                data-test-type={testType}
                data-test-display={testDisplay}
            />
        );
    }
);

export const BaseLinkWithAnalytics = withAnalytics({
    analyticsKeyToAdd: AnalyticsPropertyEnum.LINK,
    // @ts-expect-error Type 'string' is not assignable to type 'Ref<HTMLAnchorElement> | undefined'.
})<LinkProps, HTMLAnchorElement>(BaseLink);

const Link = styled(omitProps(['hoverOnUnderline', 'disabledColor'])(BaseLinkWithAnalytics))<{
    disabledColor?: string;
    disabled?: boolean;
    hoverOnUnderline?: boolean;
    color?: string;
    fontWeight?: CSSProperties['fontWeight'];
}>`
    color: ${(props) =>
        props.disabled
            ? props.disabledColor
                ? // @ts-expect-error client-common to client RND-7529
                  props.theme.colors[props.disabledColor]
                : props.theme.colors.disabled
            : props.color || props.theme.colors.cobaltBlue};
    fill: ${(props) =>
        props.disabled
            ? props.disabledColor
                ? // @ts-expect-error client-common to client RND-7529
                  props.theme.colors[props.disabledColor]
                : props.theme.colors.disabled
            : props.color || props.theme.colors.cobaltBlue};
    font-weight: ${(props) => props.fontWeight ?? props.theme.fontWeights.semiBold};
    &&:focus {
        box-shadow: 0 0 0 3px ${(props) => props.theme.colors.cobaltBlue};
        outline: 0;
    }
    ${(props) =>
        props.hoverOnUnderline &&
        !props.disabled &&
        `
        &:hover {
        text-decoration: underline;
    }
    `};
`;

export const PopoutLink: React.FC<LinkProps> = ({ children, disabled = false, ...otherProps }) => {
    return (
        // @ts-expect-error legacy todo. the `as` prop does not get passed through correctly
        <Link {...otherProps} openInNewTab={true} disabled={disabled}>
            {children}
            {!disabled && <PopoutIcon size={11} type={iconTypes.OPEN_NEW_WINDOW} />}
        </Link>
    );
};

type CustomLinkProps = React.HTMLAttributes<HTMLSpanElement> & {
    disabled?: boolean;
    disabledColor?: keyof typeof rmsTheme.colors;
    testId?: string;
};

const StyledCustomLink = styled.span<Partial<Pick<CustomLinkProps, 'disabled' | 'disabledColor'>>>`
    color: ${(props) =>
        props.disabled
            ? props.disabledColor
                ? props.theme.colors[props.disabledColor]
                : props.theme.colors.disabled
            : props.color || props.theme.colors.cobaltBlue};
    cursor: pointer;
    text-decoration: underline;
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const _CustomLink = ({ testId, ...props }: CustomLinkProps) => {
    return <StyledCustomLink data-test-id={testId} {...props} />;
};

// When you want something to look like a link but have a custom onClick
export const CustomLink = withAnalytics({
    analyticsKeyToAdd: AnalyticsPropertyEnum.LINK,
})<CustomLinkProps, HTMLSpanElement>(_CustomLink);

export const ExternalLinkStyles = styled.a<{
    disabled?: boolean;
    disabledColor?: keyof typeof rmsTheme.colors;
}>`
    color: ${(props) =>
        props.disabled
            ? props.disabledColor
                ? props.theme.colors[props.disabledColor]
                : props.theme.colors.disabled
            : props.color || props.theme.colors.cobaltBlue};
    fill: ${(props) =>
        props.disabled
            ? props.disabledColor
                ? props.theme.colors[props.disabledColor]
                : props.theme.colors.disabled
            : props.color || props.theme.colors.cobaltBlue};
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

interface ExterenalLinkProps {
    openInNewTab?: boolean;
    to?: string;
}

// A link that takes in an absolute path, mainly used for external URLS
// ie: to={'https://google/com'}
export const ExternalLink: React.FC<
    ExterenalLinkProps & React.HTMLAttributes<HTMLAnchorElement>
> = ({ children, to, openInNewTab, ...otherProps }) => {
    return (
        <ExternalLinkStyles href={to} target={openInNewTab ? '_blank' : '_self'} {...otherProps}>
            {children}
            {openInNewTab && <PopoutIcon size={11} type={iconTypes.OPEN_NEW_WINDOW} />}
        </ExternalLinkStyles>
    );
};

/**
 * Mark43 wrapper component around React Router's ReactRouterLink
 * This component should be used rather than React Router's link directly
 * @param    className          className
 * @param    color              Color of an enabled link
 * @param    disabledColor      Color of a disabled link
 */
export default Link;

import { createSelector } from 'reselect';
import { LOAD_APPLICATION_SETTINGS_FAILURE, UPSERT_APPLICATION_SETTING_FAILURE } from '../data';

const initialUiState = {
    error: null,
};

export default function applicationSettingAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_APPLICATION_SETTINGS_FAILURE:
        case UPSERT_APPLICATION_SETTING_FAILURE:
            return {
                error: action.payload,
            };
        default:
            return state;
    }
}

const applicationSettingsAdminSelector = (state) => state.ui.applicationSettingsAdmin;

export const applicationSettingsErrorSelector = createSelector(
    applicationSettingsAdminSelector,
    (applicationSettingsAdmin) => applicationSettingsAdmin.error
);

import { map } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    ElasticCobaltSortTypeEnum,
    ElasticCobaltSortTypeEnumType,
    EntityTypeEnumType,
    OperationTypeEnum,
    TaskView,
} from '@mark43/rms-api';
import sortRelatedRecordsEnum, {
    ReportSidebarSortRelatedRecordsEnumType,
} from '~/client-common/core/enums/client/reportSidebarSortRelatedRecordsEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { BodyMediumText } from '../../../../core/components/typography';
import { NoResults } from '../../../../core/components/NoResults';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { InlineBanner } from '../../../../core/components/InlineBanner';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { CustomLink } from '../../../../core/components/links/Link';
import _TaskStatusLabel, {
    TaskStatusInlineBlock,
} from '../../../../tasks/core/components/TaskStatusLabel';
import TaskDateCell from '../../../../tasks/core/components/TaskDateCell';
import { useGetTasksForLinkedEntity } from '../../../../tasks/core/hooks/useGetTasksForLinkedEntity';
import { openTaskSidePanelForTask, TaskOverlayMode } from '../../../../tasks/core/state/ui';
import sortTasks from '../../../../tasks/core/utils/sortTasks';
import SortOptions, { SortOption } from './SortOptions';
import _RecordSidebarSection from './RecordSidebarSection';

const strings = componentStrings.records.core.sidebar.LinkedTasksSection;
const sortOptions = componentStrings.reports.ReportSidebar.sortOptions;

// make sure the section is tall enough for the sort dropdown menu to appear fully
// this visually looks fine as long as no other section is rendered below this section
const RecordSidebarSection = styled(_RecordSidebarSection)`
    min-height: 350px;
`;

const TaskSummaryContainer = styled.div`
    margin-bottom: 8px;
`;

const TaskStatusLabel = styled(_TaskStatusLabel)`
    ${/* sc-selector */ TaskStatusInlineBlock} {
        margin: 3px 0;
        font-size: var(--arc-fontSizes-sm);
    }
`;

const TaskSummary: React.FC<{ task: TaskView }> = ({ task }) => {
    const dispatch = useDispatch<RmsDispatch>();
    const openTaskSidePanel = React.useCallback(() => {
        if (task.permissionSet?.includes(OperationTypeEnum.WRITE.name)) {
            dispatch(openTaskSidePanelForTask(task, TaskOverlayMode.EDIT));
        } else {
            dispatch(openTaskSidePanelForTask(task, TaskOverlayMode.VIEW));
        }
    }, [dispatch, task]);

    const allRoleFormatsByRoleId = useSelector(allRoleFormatsByRoleIdSelector);

    return (
        <TaskSummaryContainer>
            <CustomLink onClick={openTaskSidePanel}>
                <BodyMediumText fontWeight="semibold">{task.title}</BodyMediumText>
            </CustomLink>
            <TaskStatusLabel statusAttrId={task.statusAttrId} />
            {task.dueDateUtc || task.assigneeRoleId ? (
                <SummaryList>
                    {task.dueDateUtc ? (
                        <SummaryRow fieldName={fields.TASK_DUE_DATE_UTC}>
                            <TaskDateCell date={task.dueDateUtc} statusAttrId={task.statusAttrId} />
                        </SummaryRow>
                    ) : undefined}
                    {task.assigneeRoleId ? (
                        <SummaryRow fieldName={fields.TASK_ASSIGNEE_ROLE_ID}>
                            {allRoleFormatsByRoleId(task.assigneeRoleId).default}
                        </SummaryRow>
                    ) : undefined}
                </SummaryList>
            ) : undefined}
        </TaskSummaryContainer>
    );
};

const getActiveDisplay = (value: ReportSidebarSortRelatedRecordsEnumType) => {
    switch (value) {
        case sortRelatedRecordsEnum.STATUS:
            return sortOptions.status.label;
        case sortRelatedRecordsEnum.DUE_DATE:
            return sortOptions.dueDateUtc.label;
        case sortRelatedRecordsEnum.ALPHABETICAL:
            return sortOptions.alphabetical.label;
        case sortRelatedRecordsEnum.CREATED_DATE:
            return sortOptions.createdDateUtc.label;
        default:
            return sortOptions.createdDateUtc.label;
    }
};

const SortPopoutMenu: React.FC<{
    activeValue: ReportSidebarSortRelatedRecordsEnumType;
    setActiveValue: React.Dispatch<React.SetStateAction<ReportSidebarSortRelatedRecordsEnumType>>;
    sortType: ElasticCobaltSortTypeEnumType;
    setSortType: React.Dispatch<React.SetStateAction<ElasticCobaltSortTypeEnumType>>;
}> = ({ activeValue, setActiveValue, sortType, setSortType }) => {
    const activeDisplay = getActiveDisplay(activeValue);

    const handleSortOptionClick = React.useCallback(
        ({ value, sortOptions }) => {
            setSortType(sortOptions[0].sortType);
            setActiveValue(value);
        },
        [setSortType, setActiveValue]
    );
    const handleSortTypeClick = React.useCallback((sortType) => setSortType(sortType), [
        setSortType,
    ]);

    return (
        <SortOptions
            display={activeDisplay}
            activeValue={activeValue}
            activeSortType={sortType}
            onOptionClick={handleSortOptionClick}
            onSortTypeClick={handleSortTypeClick}
        >
            <SortOption
                display={sortOptions.status.label}
                value={sortRelatedRecordsEnum.STATUS}
                columnKey={sortRelatedRecordsEnum.STATUS}
                sortOptions={[
                    {
                        display: sortOptions.status.ascendingSort,
                        sortType: ElasticCobaltSortTypeEnum.ALPHABETICAL_A_TO_Z.name,
                    },
                    {
                        display: sortOptions.status.descendingSort,
                        sortType: ElasticCobaltSortTypeEnum.ALPHABETICAL_Z_TO_A.name,
                    },
                ]}
            />
            <SortOption
                display={sortOptions.dueDateUtc.label}
                value={sortRelatedRecordsEnum.DUE_DATE}
                columnKey={sortRelatedRecordsEnum.DUE_DATE}
                sortOptions={[
                    {
                        display: sortOptions.dueDateUtc.ascendingSort,
                        sortType: ElasticCobaltSortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT.name,
                    },
                    {
                        display: sortOptions.dueDateUtc.descendingSort,
                        sortType: ElasticCobaltSortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT.name,
                    },
                ]}
            />
            <SortOption
                display={sortOptions.alphabetical.label}
                value={sortRelatedRecordsEnum.ALPHABETICAL}
                columnKey={sortRelatedRecordsEnum.ALPHABETICAL}
                sortOptions={[
                    {
                        display: sortOptions.alphabetical.ascendingSort,
                        sortType: ElasticCobaltSortTypeEnum.ALPHABETICAL_A_TO_Z.name,
                    },
                    {
                        display: sortOptions.alphabetical.descendingSort,
                        sortType: ElasticCobaltSortTypeEnum.ALPHABETICAL_Z_TO_A.name,
                    },
                ]}
            />
            <SortOption
                display={sortOptions.createdDateUtc.label}
                value={sortRelatedRecordsEnum.CREATED_DATE}
                columnKey={sortRelatedRecordsEnum.CREATED_DATE}
                sortOptions={[
                    {
                        display: sortOptions.createdDateUtc.ascendingSort,
                        sortType: ElasticCobaltSortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT.name,
                    },
                    {
                        display: sortOptions.createdDateUtc.descendingSort,
                        sortType: ElasticCobaltSortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT.name,
                    },
                ]}
            />
        </SortOptions>
    );
};

/**
 * A sidebar section to display tasks linked to the given entity. It loads its own data and stores it into Redux state.
 */
const LinkedTasksSection: React.FC<{ entityType: EntityTypeEnumType; entityId: number }> = ({
    entityType,
    entityId,
}) => {
    const [activeValue, setActiveValue] = React.useState<ReportSidebarSortRelatedRecordsEnumType>(
        sortRelatedRecordsEnum.CREATED_DATE
    );
    const [sortType, setSortType] = React.useState<ElasticCobaltSortTypeEnumType>(
        ElasticCobaltSortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT.name
    );
    const { isLoading, errorMessage, tasks } = useGetTasksForLinkedEntity(entityId, entityType);
    const parentAttributeIdByAttributeId = useSelector(parentAttributeIdByAttributeIdSelector);

    let content: React.ReactNode;
    let sortPopoutMenu: React.ReactNode;
    if (isLoading) {
        content = <SimpleLoading />;
    } else if (errorMessage) {
        content = <InlineBanner status="error">{errorMessage}</InlineBanner>;
    } else if (!tasks) {
        // this should be unreachable, tasks should always exist when the current state of the resource is neither
        // loading nor error
        content = <InlineBanner status="error">{strings.loadingError}</InlineBanner>;
    } else if (tasks.length === 0) {
        content = <NoResults children={strings.noResults} />;
    } else {
        sortPopoutMenu = (
            <SortPopoutMenu
                activeValue={activeValue}
                setActiveValue={setActiveValue}
                sortType={sortType}
                setSortType={setSortType}
            />
        );
        const sortedTasks = sortTasks(tasks, {
            activeValue,
            sortType,
            parentAttributeIdByAttributeId,
        });
        content = (
            <div>
                {map(sortedTasks, (task) => {
                    return <TaskSummary key={task.id} task={task} />;
                })}
            </div>
        );
    }

    return (
        <RecordSidebarSection title={strings.title} sortPopoutMenu={sortPopoutMenu}>
            {content}
        </RecordSidebarSection>
    );
};

/**
 * Sidebar section to display the tasks linked to a given entity, not to be confused with the section which displays the
 * entities linked to a given task (TaskEntityLinksSection).
 */
export default LinkedTasksSection;

import React from 'react';
import { includes } from 'lodash';
import { OperationTypeEnumType } from '@mark43/rms-api';

/**
 * A component for rendering child components only if there are right permissions
 * Takes in children components and the max operation type
 * Note that permissions cascade, meaning if someone has 'WRITE' perms, they will also have
 * 'READ' AND 'SEARCH' perms
 *
 * Example use:
 * <OnlyWithEntityPermission has={OperationTypeEnum.WRITE.name}>
 *  <Button>Say Stuff!</Button>
 * </OnlyWithEntityPermission>
 */

const OnlyWithEntityPermission: React.FC<{
    permissionSet: OperationTypeEnumType[];
    has: OperationTypeEnumType;
    fallback?: React.ReactType;
}> = ({ permissionSet, has, fallback, children }) => {
    if (!includes(permissionSet, has)) {
        return <>{fallback || <span />}</>;
    }
    return <>{children}</>;
};

export default OnlyWithEntityPermission;

import { ApprovalStatusForCaseEnum, ApprovalStatusForCaseEnumType } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { IconNameT } from 'arc';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { WarrantStatusGlobalAttrIdType } from '~/client-common/core/legacy-constants/types';
import Icon, { iconTypes } from '../../../core/components/Icon';
import RecordPrivacyIcon from '../../../core/components/RecordPrivacyIcon';

export function convertCaseApprovalStatusToIconType(approvalStatus: ApprovalStatusForCaseEnumType) {
    switch (approvalStatus) {
        case ApprovalStatusForCaseEnum.APPROVED.name:
            return iconTypes.SUPERVISOR_APPROVED;
        case ApprovalStatusForCaseEnum.SUBMITTED.name:
            return iconTypes.PENDING;
        case ApprovalStatusForCaseEnum.REJECTED.name:
            return iconTypes.REJECTED;
        case ApprovalStatusForCaseEnum.DRAFT.name:
            return iconTypes.DRAFT;
        default:
            return iconTypes.DRAFT;
    }
}

// This takes in a approvalStatus-CLIENT-Enum value
export function convertReportApprovalStatusToIconType(
    approvalStatus?: keyof typeof approvalStatusClientEnum
) {
    switch (approvalStatus) {
        case approvalStatusClientEnum.STAFF_REVIEWED:
            return iconTypes.RECORDS_APPROVED;
        case approvalStatusClientEnum.APPROVED:
        case approvalStatusClientEnum.COMPLETED:
            return iconTypes.SUPERVISOR_APPROVED;
        case approvalStatusClientEnum.SUBMITTED:
        case approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW:
            return iconTypes.PENDING;
        case approvalStatusClientEnum.REJECTED:
            return iconTypes.REJECTED;
        case approvalStatusClientEnum.DRAFT:
            return iconTypes.DRAFT;
        case approvalStatusClientEnum.PENDING_SECONDARY_REVIEW:
            return iconTypes.PENDING_SECONDARY_REVIEW;
        default:
            return iconTypes.DRAFT;
    }
}

/**
 * This takes an approval status and returns an arc icon name string
 */
export function convertReportApprovalStatusToArcIconName(
    approvalStatus?: keyof typeof approvalStatusClientEnum
): IconNameT {
    switch (approvalStatus) {
        case approvalStatusClientEnum.STAFF_REVIEWED:
            return 'RecordsApproved';
        case approvalStatusClientEnum.APPROVED:
        case approvalStatusClientEnum.COMPLETED:
            return 'SupervisorApproved';
        case approvalStatusClientEnum.SUBMITTED:
        case approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW:
            return 'Pending';
        case approvalStatusClientEnum.REJECTED:
            return 'Rejected';
        case approvalStatusClientEnum.DRAFT:
            return 'Draft';
        case approvalStatusClientEnum.PENDING_SECONDARY_REVIEW:
            return 'PendingSecondaryReview';
        default:
            return 'Draft';
    }
}

// This takes in a warrant status global attribute ID
export function convertWarrantApprovalStatusGlobalAttrIdToIconType(
    warrantStatusGlobalAttrId: WarrantStatusGlobalAttrIdType
) {
    switch (warrantStatusGlobalAttrId) {
        case globalAttributes.warrantStatusGlobal.pending:
            return iconTypes.PENDING;
        case globalAttributes.warrantStatusGlobal.active:
            return iconTypes.WARRANT_ACTIVE;
        case globalAttributes.warrantStatusGlobal.inactive:
            return iconTypes.WARRANT_INACTIVE;
        case globalAttributes.warrantStatusGlobal.stub:
            return iconTypes.STUB;
        default:
            return iconTypes.WARRANT_NO_STATUS;
    }
}

export function convertWarrantIconTypeToThemeColor(
    warrantIconType: ReturnType<typeof convertWarrantApprovalStatusGlobalAttrIdToIconType>
) {
    switch (warrantIconType) {
        case iconTypes.PENDING:
        case iconTypes.WARRANT_NO_STATUS:
            return 'brightYellow';
        case iconTypes.WARRANT_ACTIVE:
            return 'darkGreen';
        case iconTypes.WARRANT_INACTIVE:
        case iconTypes.STUB:
            return 'mediumGrey';
        default:
            return;
    }
}

export function convertApprovalStatusIconTypeToThemeColor(
    approvalStatusIconType:
        | ReturnType<typeof convertCaseApprovalStatusToIconType>
        | ReturnType<typeof convertReportApprovalStatusToIconType>
) {
    switch (approvalStatusIconType) {
        case iconTypes.RECORDS_APPROVED:
        case iconTypes.SUPERVISOR_APPROVED:
        case iconTypes.PENDING_SECONDARY_REVIEW:
            return 'darkGreen';
        case iconTypes.DRAFT:
            return 'mediumGrey';
        case iconTypes.REJECTED:
            return 'red';
        case iconTypes.PENDING:
            return 'brightYellow';
        default:
            return;
    }
}

export function WarrantApprovalStatusIcon({
    warrantStatusGlobalAttrId,
    className,
    size = 20,
}: {
    warrantStatusGlobalAttrId: WarrantStatusGlobalAttrIdType;
    className?: string;
    size?: number;
}) {
    const warrantIconType = convertWarrantApprovalStatusGlobalAttrIdToIconType(
        warrantStatusGlobalAttrId
    );
    const iconColor = convertWarrantIconTypeToThemeColor(warrantIconType);

    if (warrantIconType === iconTypes.PENDING) {
        return (
            <PendingIcon size={size} className={className}>
                <Icon type={warrantIconType} size={size} color={iconColor} />
            </PendingIcon>
        );
    }
    return <Icon className={className} type={warrantIconType} size={size} color={iconColor} />;
}

export function CaseApprovalStatusIcon({
    approvalStatus,
    className,
    size = 20,
}: {
    approvalStatus: ApprovalStatusForCaseEnumType;
    className?: string;
    size?: number;
}) {
    const caseIconType = convertCaseApprovalStatusToIconType(approvalStatus);
    const iconColor = convertApprovalStatusIconTypeToThemeColor(caseIconType);

    if (caseIconType === iconTypes.PENDING) {
        return (
            <PendingIcon size={size} className={className}>
                <Icon type={caseIconType} size={size} color={iconColor} />
            </PendingIcon>
        );
    }
    return <Icon className={className} type={caseIconType} size={size} color={iconColor} />;
}

const PendingIcon = styled.div<{ size: number }>`
    position: relative;
    display: inline-block;
    line-height: 0;

    &::before {
        border-radius: 50%;
        content: '';
        position: absolute;
        width: ${(props) => props.size - 3}px;
        height: ${(props) => props.size - 3}px;
        top: 2px;
        left: 2px;
        background-color: ${(props) => props.theme.colors.navyBlue};
    }

    svg {
        position: relative;
    }
`;

interface ApprovalStatusIconPropsType {
    approvalStatus?: keyof typeof approvalStatusClientEnum;
    isSealed?: boolean;
    isPartiallySealed?: boolean;
    isVacated?: boolean;
    className?: string;
    size?: number;
}

export default function ApprovalStatusIcon({
    approvalStatus,
    isSealed,
    isPartiallySealed,
    isVacated,
    className,
    size = 20,
}: ApprovalStatusIconPropsType) {
    const reportIconType = convertReportApprovalStatusToIconType(approvalStatus);
    const iconColor = convertApprovalStatusIconTypeToThemeColor(reportIconType);

    if (isSealed || isPartiallySealed || isVacated) {
        return <RecordPrivacyIcon className={className} isSealed={isSealed} size={size / 2} />;
    }

    if (reportIconType === iconTypes.PENDING) {
        return (
            <PendingIcon size={size} className={className}>
                <Icon type={reportIconType} size={size} color={iconColor} />
            </PendingIcon>
        );
    }
    return <Icon className={className} type={reportIconType} size={size} color={iconColor} />;
}

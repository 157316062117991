import React, { useState, useCallback, useRef, useEffect } from 'react';
import { exportStageSVG } from 'react-konva-to-svg';
import styled from 'styled-components';
import invariant from 'invariant';
import Konva from 'konva';
import { Mark43File } from '@mark43/rms-api';
import { Flex } from '@arc/layout';
import { cssVar } from 'arc';
import { OverlayIdEnumType } from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useFileUploader } from '../../../../../../attachments/files/hooks/useFileUploader';
import { useOverlayStore } from '../../../../../../core/overlays/hooks/useOverlayStore';
import Modal from '../../../../../../core/overlays/components/Modal';
import { widgetTypes } from '../types';
import { getCrashDiagramModalOverlayId, createWidget } from '../helpers';
import {
    addDataWidgetTypeAttributeToBackgroundImageTag,
    addAdditionalAttributesToSVGWidgetTags,
} from '../serializer';
import {
    CrashDiagramAssetType,
    widgetTypeToDefaultValue,
    SCROLLABLE_ASSETS_BUTTON_CONFIG,
    STAGE_CENTER,
    STAGE_WIDTH_MOBILE,
    STAGE_WIDTH_MEDIUM,
    MODAL_WIDTH_MOBILE,
    MODAL_WIDTH_MEDIUM,
    MODAL_WIDTH_LARGE,
    DEFAULT_BACKGROUND,
    MODAL_CONTENT_WIDTH,
    DEFAULT_WIDGET_POSITION,
    FONT_SIZE,
} from '../config';
import { useFetchSVGAsset } from '../hooks';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { useCrashDiagramHistory } from '../context/CrashDiagramHistoryContext';
import errorToMessage from '../../../../../../core/errors/utils/errorToMessage';
import { logError } from '../../../../../../../core/logging';
import { useScreenBreakpoint } from '../../../../../../core/utils/useScreenBreakpoint';

import { CrashDiagram } from './CrashDiagram';
import { SelectedWidgetMenu } from './SelectedWidgetMenu';
import { TemplateThumbnail } from './TemplateThumbnail';
import { CrashDiagramActionButton } from './CrashDiagramActionButton';

const ModalContentWrapper = styled.div`
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    border-radius: 6px;
    position: relative;
`;

const DiagramCanvasWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: end;
    position: relative;
`;

const SelectedWidgetActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    overflow: auto;
`;

const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    overflow: auto;
`;

const FooterActionButton = styled(CrashDiagramActionButton)`
    margin-right: ${cssVar('arc.space.1')};
`;

type HeaderActionButtonProps = {
    isMobile?: boolean;
};
const HeaderActionButton = styled(CrashDiagramActionButton)<HeaderActionButtonProps>`
    padding: ${({ isMobile }) => (isMobile ? 0 : 'auto')};
`;

const crashDiagramModalStrings = componentStrings.dragon.crashDiagram.CrashDiagramModal;
const crashDiagramModalErrorMessageStrings = crashDiagramModalStrings.errorMessages;

type CrashDiagramModalPropsT = {
    identifier: string;
    overlayId: OverlayIdEnumType;
    onSave: (file: Mark43File) => void;
};

const getCrashDiagramFileName = (identifier: string) => {
    return `crash-diagram-${identifier}.svg`;
};

export const CrashDiagramModal: React.FC<CrashDiagramModalPropsT> = ({
    identifier,
    onSave,
}: CrashDiagramModalPropsT) => {
    const stageRef = useRef<Konva.Stage>(null);
    const overlayStore = useOverlayStore();
    const overlayId = getCrashDiagramModalOverlayId(identifier);
    const { widgets, clear, reset, addWidget, clearSelection, setBackground, backgroundImage } =
        useCrashDiagram();

    const { undo, redo, canPerformRedoAction, canPerformUndoAction } = useCrashDiagramHistory();
    const { isMobile, isLargeScreen } = useScreenBreakpoint();
    const [selectedAssetType, setSelectedAssetType] = useState<CrashDiagramAssetType>();

    const onUploadStart = useCallback(() => {
        overlayStore.setLoading(overlayId, true);
    }, [overlayId, overlayStore]);

    const onUploadComplete = useCallback(() => {
        overlayStore.close(overlayId);
    }, [overlayId, overlayStore]);

    const { callResource: saveCrashDiagram, loading } = useFileUploader({
        onSave,
        onUploadStart,
        onUploadComplete,
    });

    const handleSetAssetType = (assetType?: CrashDiagramAssetType) => {
        setSelectedAssetType((previousAssetType) => {
            return previousAssetType === assetType ? undefined : assetType;
        });
    };

    const handleAfterClose = useCallback(() => {
        setSelectedAssetType(undefined);
        reset();
    }, [reset]);

    const onAddText = useCallback(() => {
        const widgetType = widgetTypes.TEXT;
        const value = widgetTypeToDefaultValue[widgetType] ?? '';
        const widget = createWidget({
            type: widgetType,
            value,
            position: {
                ...DEFAULT_WIDGET_POSITION,
                x: STAGE_CENTER[0],
                y: STAGE_CENTER[1],
                height: FONT_SIZE,
                offsetY: FONT_SIZE / 2,
            },
            opacity: 1,
        });
        addWidget({
            widget,
        });
    }, [addWidget]);

    const handleOnSave = useCallback(async () => {
        const konvaStage = stageRef?.current?.getStage();

        if (!konvaStage || !backgroundImage) {
            return;
        }

        clearSelection();

        const crashDiagramSvgString = await exportStageSVG(konvaStage);

        invariant(
            typeof crashDiagramSvgString === 'string',
            'Failed to export crash diagram as an svg string'
        );

        let modifiedCrashDiagramSvgString = addDataWidgetTypeAttributeToBackgroundImageTag(
            crashDiagramSvgString,
            backgroundImage
        );

        invariant(
            !!modifiedCrashDiagramSvgString,
            'Crash Diagram SVG is missing a background. Invalid Format'
        );

        modifiedCrashDiagramSvgString = addAdditionalAttributesToSVGWidgetTags(
            modifiedCrashDiagramSvgString,
            widgets
        );

        const crashDiagramBlob = new Blob([modifiedCrashDiagramSvgString], {
            type: 'image/svg+xml;charset=utf-8',
        });
        const formData = new window.FormData();
        formData.append('my-file', crashDiagramBlob, getCrashDiagramFileName(identifier));

        try {
            await saveCrashDiagram(formData);
        } catch (err) {
            logError(
                errorToMessage(err, crashDiagramModalErrorMessageStrings.unhandledUploadException),
                {
                    crashDiagramSvgString: modifiedCrashDiagramSvgString,
                }
            );
            throw err;
        }
    }, [identifier, stageRef, clearSelection, saveCrashDiagram, backgroundImage, widgets]);

    const result = useFetchSVGAsset({
        value: DEFAULT_BACKGROUND,
        assetKey: DEFAULT_BACKGROUND,
    });

    useEffect(() => {
        if (!result?.svgDataUrl || !!backgroundImage) {
            return;
        }
        setBackground(result.svgDataUrl);
    }, [backgroundImage, setBackground, result]);

    return (
        <Modal
            contentStyle={{
                minWidth: isMobile
                    ? STAGE_WIDTH_MOBILE
                    : isLargeScreen
                      ? MODAL_CONTENT_WIDTH
                      : STAGE_WIDTH_MEDIUM,
            }}
            id={overlayId}
            okText={crashDiagramModalStrings.okText}
            cancelText={crashDiagramModalStrings.cancelText}
            title={crashDiagramModalStrings.title}
            closeOnSave={false}
            modalStyle={{
                minWidth: isMobile
                    ? MODAL_WIDTH_MOBILE
                    : isLargeScreen
                      ? MODAL_WIDTH_LARGE
                      : MODAL_WIDTH_MEDIUM,
                maxHeight: '100%',
            }}
            onAfterClose={handleAfterClose}
            onSave={handleOnSave}
            saveDisabled={widgets.length === 0 || !backgroundImage}
            errorMessages={loading.errorMessage ? [loading.errorMessage] : undefined}
        >
            <ModalContentWrapper>
                <ActionsContainer>
                    <Flex justifyContent="start">
                        <HeaderActionButton
                            disabled={widgets.length === 0}
                            leadingVisual="CloseX"
                            variant="ghost"
                            onClick={clear}
                            label={crashDiagramModalStrings.clearButtonText}
                            isMobile={isMobile}
                        />
                    </Flex>
                    <SelectedWidgetActionsContainer>
                        <SelectedWidgetMenu />
                    </SelectedWidgetActionsContainer>
                    <Flex justifyContent="end">
                        <HeaderActionButton
                            disabled={!canPerformUndoAction}
                            leadingVisual="Undo"
                            variant="ghost"
                            onClick={undo}
                            label={crashDiagramModalStrings.undoButtonText}
                            isMobile={isMobile}
                        />

                        <HeaderActionButton
                            disabled={!canPerformRedoAction}
                            leadingVisual="Redo"
                            variant="ghost"
                            onClick={redo}
                            label={crashDiagramModalStrings.redoButtonText}
                            isMobile={isMobile}
                        />
                    </Flex>
                </ActionsContainer>
                <DiagramCanvasWrapper>
                    <CrashDiagram
                        assetType={selectedAssetType}
                        stageRef={stageRef}
                        handleSetAssetType={handleSetAssetType}
                    />
                </DiagramCanvasWrapper>
                <FooterContainer>
                    <Flex>
                        <FooterActionButton
                            leadingVisual="Title"
                            variant="outline"
                            colorVariant="black"
                            onClick={onAddText}
                            label={crashDiagramModalStrings.textButtonText}
                        />
                        {SCROLLABLE_ASSETS_BUTTON_CONFIG.map(
                            ({ assetType, leadingVisual, label }) => (
                                <FooterActionButton
                                    key={assetType}
                                    isActive={selectedAssetType === assetType}
                                    leadingVisual={leadingVisual}
                                    variant="outline"
                                    colorVariant="black"
                                    onClick={() => handleSetAssetType(assetType)}
                                    label={label}
                                />
                            )
                        )}
                    </Flex>
                    <TemplateThumbnail onClick={handleSetAssetType} />
                </FooterContainer>
            </ModalContentWrapper>
        </Modal>
    );
};

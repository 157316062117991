import React from 'react';
import { map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
} from './EntityProfileSection';

const strings = componentStrings.entityProfiles.LegacyEntityDetails;

export default function LegacyEntityDetails({ legacyEntityDetails = [] }) {
    if (!legacyEntityDetails.length) {
        return null;
    }

    const detailRows = map(legacyEntityDetails, (detail, i) => {
        const { detailKey, detailValue } = detail;
        return (
            <SummaryRow key={i} label={detailKey}>
                {detailValue}
            </SummaryRow>
        );
    });
    return (
        <EntityProfileSection title={strings.title}>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>{detailRows}</EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}

import _ from 'lodash';
import { setFormValues, clearFormValues } from '../helpers/formReducerHelpers';
import { departmentIPFormFieldList } from '../configs/adminConfig';
import securityAdminActionTypes from '../actions/types/securityAdminActionTypes';

const {
    LOAD_IPS_START,
    LOAD_IPS_SUCCESS,
    LOAD_IPS_FAILURE,
    SELECT_IP,
    CREATE_IP,
    SAVE_IP_SUCCESS,
    SAVE_IP_FAILURE,
    DELETE_IP_START,
    DELETE_IP_SUCCESS,
    DELETE_IP_FAILURE,
} = securityAdminActionTypes;

const initialDataState = {
    departmentIPs: {}, // keyed on ID
};

const initialUiState = {
    selectedDepartmentIPId: null,
    pageLoadError: false,
    departmentIPs: {}, // for storing computed properties not in the data state
    loadingList: false,
    saving: false,
    formError: null,
};

export function departmentIPsAdminDataReducer(state = initialDataState, action) {
    switch (action.type) {
        case LOAD_IPS_START:
            return {
                ...state,
                departmentIPs: [],
            };
        case LOAD_IPS_SUCCESS:
            return {
                ...state,
                departmentIPs: action.payload.ips,
            };
        case SAVE_IP_SUCCESS:
            // We successfully saved the department IP!
            const newIP = action.payload;

            return {
                ...state,
                departmentIPs: {
                    ...state.departmentIPs,
                    [newIP.id]: newIP,
                },
            };
        case SAVE_IP_FAILURE:
            return state;
        case DELETE_IP_SUCCESS:
            return {
                ...state,
                departmentIPs: _.omit(state.departmentIPs, action.payload),
            };
        default:
            return state;
    }
}

export function departmentIPsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_IPS_START:
            return {
                ...state,
                selectedDepartmentIPId: null,
                loadingList: true,
            };
        case LOAD_IPS_SUCCESS:
            // we need to set the status of each code, not tracked on the server
            // as it's just a computed field. Could do this in the selector but
            // it's actually pretty expensive, so better to cache it

            return {
                ...state,
                loadingList: false,
            };
        case LOAD_IPS_FAILURE:
            return {
                ...state,
                pageLoadError: true,
                loadingList: false,
            };
        case SELECT_IP:
            const ip = action.payload;

            return {
                ...state,
                selectedDepartmentIPId: ip ? ip.id : null,
                formError: null,
            };
        case CREATE_IP:
            return {
                ...state,
                selectedDepartmentIPId: -1,
                formError: null,
            };
        case SAVE_IP_SUCCESS:
            const savedIP = action.payload;

            return {
                ...state,
                saving: false,
                formError: null,
                selectedDepartmentIPId: savedIP.id,
                departmentIPs: {
                    ...state.departmentIPs,
                    [savedIP.id]: {
                        ...savedIP,
                    },
                },
            };
        case SAVE_IP_FAILURE:
            return {
                ...state,
                saving: false,
                formError: action.payload.message,
            };
        case DELETE_IP_START:
            return {
                ...state,
                submittingForm: true,
                formError: null,
            };
        case DELETE_IP_SUCCESS:
            return {
                ...state,
                // The IP address has been deleted so it's no longer selected
                submittingForm: false,
                selectedDepartmentIPId: null,
            };
        case DELETE_IP_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload.message,
            };
        default:
            return state;
    }
}

export function departmentIPsAdminFormReducer(state, action) {
    switch (action.type) {
        case SELECT_IP:
            // The user selected one
            if (action.payload) {
                return setFormValues(state, action.payload, departmentIPFormFieldList);
            }

            // Otherwise they chose a new template
            return clearFormValues(state);
        case CREATE_IP:
        case DELETE_IP_SUCCESS:
            return clearFormValues(state);
        default:
            return state;
    }
}

import { ReportingEvent } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'reportingEvents';

const reportingEventsModule = createNormalizedModule<ReportingEvent>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS

export const reportingEventsWhereSelector = reportingEventsModule.selectors.entitiesWhereSelector;

// REDUCER
export default reportingEventsModule.reducerConfig;

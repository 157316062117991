import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFMultiTextInput } from '../../../core/forms/components/RRFMultiTextInput';
import Row from '../../../core/components/Row';

import addNewRoleForm from '../state/forms/addNewRoleForm';
import { CjisComplianceMessage } from './EditRoleNameModal';

const strings = componentStrings.admin.roles.ManageRoles.AddNewRoleForm;

const globalPathConfig = [
    {
        path: 'sessionTimeoutLength.hours',
        suffix: 'H',
        width: 50,
    },
    {
        path: 'sessionTimeoutLength.mins',
        suffix: 'm',
        width: 50,
    },
];

export default function NewRoleForm() {
    return (
        <ReactReduxForm {...addNewRoleForm}>
            <CjisComplianceMessage/>
            <Row>
                <RRFText label={strings.roleName} width={240} path="name" />
            </Row>
            <Row>
                <RRFMultiTextInput
                    label={strings.sessionTimeoutLength}
                    pathConfig={globalPathConfig}
                    width={150}
                    path="sessionTimeoutLength"
                    justifyContent="start"
                />
            </Row>
        </ReactReduxForm>
    );
}

import React from 'react';

import { COLUMN_WIDTH_12 } from '../configuration/constants';
import Column from './core/Column';

const HEADER_COLUMN_PADDING = '20px';

const HeaderColumn = ({
    children,
    borderColor = 'var(--arc-colors-border-default)',
    last,
    ...rest
}) => (
    <Column
        borderRight="1px solid"
        borderLeft={last ? undefined : '1px solid'}
        borderColor={borderColor}
        padding={HEADER_COLUMN_PADDING}
        width={COLUMN_WIDTH_12}
        {...rest}
    >
        {children}
    </Column>
);

export default HeaderColumn;

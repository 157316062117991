import { map, mapValues } from 'lodash';
import { createSelector } from 'reselect';
import { currentDepartmentCurrencyFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    buildAllMiniUserFormatsMapper,
    boolToDisplayMapper,
} from '../../../../../helpers/viewModelHelpers';

import { propertyStatusesSelector, propertyStatusesByItemProfileIdSelector } from '../data';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';
import { offenseByIdSelector } from '../../../offenses/state/data';

/**
 * @return {function} A function that accepts a propertyStatus and returns a view model.
 */
export const buildPropertyStatusViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    allMiniUserFormatsByIdSelector,
    offenseByIdSelector,
    currentDepartmentCurrencyFormatterSelector,
    (formatAttributeById, allMiniUserFormatsById, offenseById, { formatCurrency }) =>
        buildViewModel({
            mappers: [
                allSingleAttributeValuesMapper,
                buildAllMiniUserFormatsMapper('recoveredByOfficerId'),
                boolToDisplayMapper,
                ({ declaredValue, forfeitureValue, offenseId }, { formatCurrency }) => ({
                    declaredValue: declaredValue && formatCurrency(declaredValue),
                    forfeitureValue: forfeitureValue && formatCurrency(forfeitureValue),
                    linkedOffense: offenseById(offenseId),
                }),
            ],
            helpers: {
                formatAttributeById,
                allMiniUserFormatsById,
                formatCurrency,
            },
        })
);

/**
 * @return {Object[]}
 */
const propertyStatusesViewModelsSelector = createSelector(
    propertyStatusesSelector,
    buildPropertyStatusViewModelSelector,
    (propertyStatuses, buildPropertyStatusViewModel) =>
        mapValues(propertyStatuses, buildPropertyStatusViewModel)
);

/**
 * Property status view model for the given id.
 * @param  {number} itemProfileId
 * @return {Object}
 */
export const propertyStatusViewModelByIdSelector = createSelector(
    propertyStatusesViewModelsSelector,
    (propertyStatusesViewModels) => (propertyStatusId) =>
        propertyStatusesViewModels[propertyStatusId]
);

/**
 * Property Status view models for an item profile id
 */
export const propertyStatusViewModelsByItemProfileIdSelector = createSelector(
    propertyStatusesByItemProfileIdSelector,
    propertyStatusViewModelByIdSelector,
    (propertyStatusesByItemProfileId, propertyStatusViewModelById) => (itemProfileId) => {
        return map(propertyStatusesByItemProfileId(itemProfileId), (propertyStatus) => {
            return propertyStatusViewModelById(propertyStatus.id);
        });
    }
);

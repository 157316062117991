import React from 'react';
import invariant from 'invariant';
import { withHandlers } from 'recompose';
import styled from 'styled-components';
import _, {
    find,
    map,
    noop,
    compact,
    without,
    uniq,
    isArray,
    reject,
    keyBy,
    times,
    includes,
    first,
    isNull,
    isUndefined,
    omit,
    groupBy,
    orderBy,
    findIndex,
    isEmpty,
} from 'lodash';
import classNames from 'classnames';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { isElementOfType } from '../../../helpers/reactHelpers';
import createElement from '../../../../modules/core/utils/recompose.createElement';
import { NoResults } from '../../../../modules/core/components/NoResults';
import testIds from '../../../../core/testIds';
import TableCheckboxCell from './TableCheckboxCell';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableColumns from './TableColumns';
import { ResultsGrouping } from './ResultsGrouping';

/**
 * This component for tables where the highlight of the first header is overlapping the checkbox
 */
const StyledTableCheckboxCell = styled(TableCheckboxCell)`
    margin-right: ${(props) => (props.tableCheckboxMargin ? props.tableCheckboxMargin : 'auto')};
    border-top: none;
`;

/**
 * This component display a tr (table row element) that is used by the Table Component
 * @param row
 * @param rest
 */
function TrRowComponent(props) {
    const restOfProps = omit(props, 'row');
    return <tr {...restOfProps} />;
}

/**
 * This component displays results in a table and offers several APIs for
 *   adjusting the look and behavior of said table. Should be used in
 *   conjunction with TableBody, TablyHeader, TableColumns, TableColumn,
 *   OptionsTableColumn and TableCell.
 * @param {object} props
 * @param {Component[]} props.children         Should include both a TableHeader
 *   and a TableBody.
 * @param {Object[]}    [props.data]           The actual data that your table
 *   will render.
 * @param {boolean}     [props.selectableRow]  Whether or not a single row of
 *   your Table is selectable. If true, a column will be added to the left side
 *   of your table where checkboxes will appear.
 * @param {boolean}     [props.selectableRows] Whether or not rows of your Table
 *   are selectable. If true, a column will be added to the left side of your
 *   table where checkboxes will appear. If `selectableRows` is true and `selectableRow`
 *   is false, then the table header will include a select all checkbox
 * @param {boolean}     [props.selectedRows]   The indexes of all currently
 *   selected rows.
 * @param {function}    [props.onSelectRow]    A callback that will fire when a
 *   user attempts to select a row.
 * @param {function}    [props.onRowClick]     A callback that will fire when a
 *   row is clicked. Remember to use `event.stopPropagation()` on click events
 *   on child components to prevent this from firing.
 * @param {string}      props.sortKey          Which key (similar to a column)
 *   of our results data structure that we are sorting on. i.e. 'eventStartUtc'
 * @param {string}      props.sortType         The type of sort that is active.
 * @param {string}      props.noRowsText       Text to display in the table body
 *   when no results are available.
 * @param {boolean}      [props.disableBody]      If true the table body becomes
 *   disabled. Click handlers will stop working and there will be a visual
 *   effect to indicate that the table is disabled.
 * @param {string}      [props.containerClassName] Wl be passed to `classNames`
 *   and the result will be added to the <div> container our <table> belongs to
 * @param {boolean}     [props.simpleStyles]     Applies a class which will result
 *   in a 'simple styles' look for the table.
 * @param {function}     [props.disableRowSelectWhen] Choose which row
 *   checkboxes should be disabled. The row data is passed in as a param
 *   Note, this will have no affect on any selectAll-type actions, those take
 *   place outside of the Table component
 * @param {Component} [props.rowComponent]
 * @param {Object} [props.groupingData] Will group results rows together based on a field
 *  by creating a table division with the provided display message.
 */
function Table({
    children,
    data: rows = [],
    selectableRow = false,
    selectableRows = false,
    selectedRows = [],
    highlightedRows = [],
    onSelectRow = noop,
    onRowClick = noop,
    sortKey,
    sortType,
    noRowsText = componentStrings.core.Table.noResults,
    disableBody = false,
    clippable: { clipConstantOffset, isClippedTop = false, clipAtBottomLimit } = {},
    hasStickyHeader,
    containerClassName,
    simpleStyles = false,
    className = '',
    tableColumnsClassName = '',
    rowComponent = TrRowComponent,
    disableRowSelectWhen,
    onRowEnter,
    onRowLeave,
    tableCheckboxMargin,
    groupingData,
}) {
    invariant(
        isArray(children),
        'Table must be provided at least two top level Children. A TableHeader and a TableBody.'
    );

    const tableHeader = find(children, (child) => isElementOfType(child, TableHeader));
    const tableBody = find(children, (child) => isElementOfType(child, TableBody));
    const indexedCellComponents = keyBy(
        React.Children.toArray(compact(tableBody.props.children)),
        ({ props: { columnKey } }) => columnKey
    );

    invariant(tableHeader, 'Table must be provided a TableHeader element as a top level child.');

    const tableHeaderChildrenArray = isArray(tableHeader.props.children)
        ? tableHeader.props.children
        : [tableHeader.props.children];

    const tableHeaderColumns = find(tableHeaderChildrenArray, (child) =>
        isElementOfType(child, TableColumns)
    );

    const otherTableHeaderChildren = reject(tableHeaderChildrenArray, (child) =>
        isElementOfType(child, TableColumns)
    );

    invariant(
        tableHeaderColumns,
        'TableHeader must be provided a TableColumns element as a top level child.'
    );

    const columns = React.Children.toArray(compact(tableHeaderColumns.props.children));

    let selectableColumnHeaderCell;
    if (selectableRow) {
        selectableColumnHeaderCell = (
            <td
                className="cobalt-table-cell cobalt-select-cell"
                key="selectable-column-header-cell-placeholder"
            />
        );
    } else if (selectableRows) {
        let selectableColumnCheckboxValue = false;
        // using >= because option to select ALL rows in all pages
        if (selectedRows.length >= rows.length && rows.length !== 0) {
            selectableColumnCheckboxValue = true;
        } else if (selectedRows.length < rows.length && selectedRows.length > 0) {
            selectableColumnCheckboxValue = 'INDETERMINATE';
        }

        selectableColumnHeaderCell = (
            <StyledTableCheckboxCell
                tableCheckboxMargin={tableCheckboxMargin}
                key="TableHeaderCheckbox"
                onChange={(requestedState) => {
                    let selectedRows;
                    if (requestedState) {
                        if (disableRowSelectWhen) {
                            // Find all the indexes of the rows that have not been disabled
                            selectedRows = _(rows)
                                .map((row, index) => (disableRowSelectWhen(row) ? -1 : index))
                                .filter((i) => i > -1)
                                .value();
                        } else {
                            selectedRows = times(rows.length);
                        }
                    } else {
                        selectedRows = [];
                    }
                    return onSelectRow(selectedRows);
                }}
                value={selectableColumnCheckboxValue}
            />
        );
    }

    const mappedColumnHeaders = compact([
        selectableColumnHeaderCell,
        ...map(columns, (column, columnIndex) => {
            return React.cloneElement(column, {
                sortType:
                    sortKey === column.props.sortKey
                        ? sortType
                        : isNull(column.props.sortType) || isUndefined(column.props.sortType)
                        ? column.props.sortType
                        : undefined,
                key: column.props.columnKey,
                width:
                    (selectableRow || selectableRows) && columnIndex === 0
                        ? column.props.width - 30
                        : column.props.width,
            });
        }),
    ]);

    let groupedRows = !isEmpty(rows) ? { nonGroupedData: rows } : {};
    if (groupingData) {
        groupedRows = groupBy(rows, groupingData.field);
        // Have to resort here since groupBy will override the current sort.
        groupedRows = orderBy(groupedRows, (groupedItem) => {
            return findIndex(rows, [groupingData.field, groupedItem[0][groupingData.field]]);
        });
    }

    const canSelectRow = (row) => {
        if (disableRowSelectWhen && (selectableRow || selectableRows)) {
            return !disableRowSelectWhen(row);
        }

        return selectableRow || selectableRows;
    };

    let currentIndex = 0;
    const mappedRows = map(groupedRows, (groupedRow) => {
        return (
            <>
                <ResultsGrouping
                    groupingDisplay={
                        groupingData?.displayMessage ? groupingData.displayMessage(groupedRow) : ''
                    }
                    showDivider={!isUndefined(groupingData)}
                    showLoadMore={groupingData?.showLoadMore}
                    showCollapseButton={groupingData?.showCollapseButton}
                    rightElement={groupingData?.rightElement?.(groupedRow)}
                >
                    {map(groupedRow, (row) => {
                        const rowIndex = currentIndex++;
                        return (
                            <TableRow
                                key={rowIndex}
                                selectable={canSelectRow(row)}
                                highlighted={includes(highlightedRows, rowIndex)}
                                row={row}
                                rowIndex={rowIndex}
                                clickable={onRowClick !== noop}
                                onClick={onRowClick}
                                onSelect={(requestedState) => {
                                    if (selectableRow) {
                                        if (first(selectedRows) === rowIndex) {
                                            return onSelectRow();
                                        } else {
                                            return onSelectRow(rowIndex);
                                        }
                                    } else if (selectableRows) {
                                        // checkbox
                                        return requestedState
                                            ? // add the index to the selected rows
                                              onSelectRow(uniq([...selectedRows, rowIndex]))
                                            : // remove the index from the seleted rows
                                              onSelectRow(without(selectedRows, rowIndex));
                                    }
                                }}
                                isSelected={includes(selectedRows, rowIndex)}
                                rowComponent={rowComponent}
                                selectDisabled={
                                    (selectableRow || selectableRows) &&
                                    disableRowSelectWhen &&
                                    disableRowSelectWhen(row)
                                }
                                onRowEnter={onRowEnter}
                                onRowLeave={onRowLeave}
                            >
                                {map(
                                    columns,
                                    (
                                        { props: { activeColumnKey, columnKey, width } },
                                        columnIndex
                                    ) => {
                                        const cell =
                                            indexedCellComponents[activeColumnKey || columnKey];
                                        invariant(
                                            cell,
                                            `No provided CellComponent matches ${
                                                activeColumnKey || columnKey
                                            }`
                                        );

                                        return (
                                            <TableCell
                                                key={`${row?.id || columnIndex}~${columnKey}`}
                                                width={
                                                    // width of column determined by column header.
                                                    // with no checkbox (width: 30px) in header,
                                                    // shrink first column width on data rows.
                                                    canSelectRow(row) && columnIndex === 0
                                                        ? width - 30
                                                        : width
                                                }
                                            >
                                                {createElement(cell.type, {
                                                    ...omit(cell.props, 'columnKey'),
                                                    ...row,
                                                })}
                                            </TableCell>
                                        );
                                    }
                                )}
                            </TableRow>
                        );
                    })}
                </ResultsGrouping>
            </>
        );
    });

    let body;
    if (mappedRows.length > 0) {
        body = (
            <tbody className="cobalt-table-rows" style={{ opacity: disableBody ? 0.25 : 1 }}>
                {isClippedTop && (
                    <tr>
                        <td
                            key="clipped-table-padding"
                            className="clipped-table-padding"
                            colSpan={mappedColumnHeaders.length}
                        />
                    </tr>
                )}
                {mappedRows}
            </tbody>
        );
    } else {
        body = (
            <tbody style={{ opacity: disableBody ? 0.25 : 1 }}>
                <tr>
                    <td colSpan={mappedColumnHeaders.length}>
                        <div className="cobalt-table-no-results">
                            <NoResults marginTop="0">{noRowsText}</NoResults>
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    // disable all table body actions if its in a disabled state
    if (disableBody) {
        onSelectRow = noop;
        onRowClick = noop;
    }

    return (
        <div className={classNames('cobalt-table-container', containerClassName)}>
            <table
                className={classNames('cobalt-table', className, {
                    'simple-styles': simpleStyles,
                })}
                data-test-id={testIds.TABLE}
            >
                <thead
                    className={classNames('cobalt-table-header', {
                        'clipped-top': isClippedTop,
                        'at-bottom-limit': clipAtBottomLimit,
                        sticky: hasStickyHeader,
                    })}
                    style={{
                        top:
                            isClippedTop && !clipAtBottomLimit
                                ? clipConstantOffset
                                : hasStickyHeader
                                ? '0'
                                : 'auto',
                    }}
                >
                    {/* children of thead should be tr for clipable */}
                    <tr className="cobalt-table-above-column-headers">
                        {otherTableHeaderChildren}
                    </tr>
                    <TableColumns className={tableColumnsClassName}>
                        {mappedColumnHeaders}
                    </TableColumns>
                    {!simpleStyles && (
                        <tr className="cobalt-table-shadow-container">
                            <th colSpan={mappedColumnHeaders.length}>
                                <div className="cobalt-table-header-shadow" />
                            </th>
                        </tr>
                    )}
                </thead>
                {body}
            </table>
        </div>
    );
}

/**
 * Represents one full row in a table. Most logic around the contents of a
 *   TableRow instance take place in its parent Table.
 * @param {boolean}     [props.selectable] Whether to include a
 *   TableCheckboxCell as the first cell.
 * @param {boolean}     [props.isSelected]  Whether this row is selected.
 * @param {boolean}     [props.highlighted] Whether this row is highlighted.
 * @param {function}    props.onClick       Called whenever the row is clicked
 * @param {function}    props.onSelect      Called whenever the row's
 *   checkbox is clicked.
 * @param {TableCell[]} props.children      The content of the row (instances of
 *   TableCell).
 */
const TableRow = withHandlers({
    onClick({ row, rowIndex, onClick }) {
        return () => {
            onClick(row, rowIndex);
        };
    },
    onMouseEnter: ({ onRowEnter, row, rowIndex }) => () => onRowEnter && onRowEnter(row, rowIndex),
    onMouseLeave: ({ onRowLeave, row, rowIndex }) => () => onRowLeave && onRowLeave(row, rowIndex),
})(function TableRow({
    selectable,
    isSelected,
    onClick: handleClick,
    onSelect,
    row,
    children,
    highlighted,
    clickable,
    rowComponent: RowComponent,
    selectDisabled = false,
    onMouseEnter,
    onMouseLeave,
}) {
    let selectableCell;
    if (selectable) {
        selectableCell = (
            <TableCheckboxCell onChange={onSelect} value={isSelected} disabled={selectDisabled} />
        );
    }

    return (
        <RowComponent
            className={classNames('cobalt-table-row', {
                highlighted,
                clickable,
            })}
            row={row}
            onClick={handleClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {selectableCell}
            {children}
        </RowComponent>
    );
});

/**
 * The React Component wrapper around <td> for use with Table component
 * @param  {Component[]} [props.children] The content of the TableCell's <td>.
 * @param  {number}      props.width      The width of the TableCell (in pixels).
 */
function TableCell({ children, width }) {
    return (
        <td className="cobalt-table-cell" style={{ width }} data-test-id={testIds.TABLE_CELL}>
            {children}
        </td>
    );
}

export default Table;

import { get } from 'lodash';
import {
    nibrsOffenseCodesSelector,
    nibrsOffenseCodeByCodeSelector,
} from '~/client-common/core/domain/nibrs-offense-codes/state/data';

/**
 * The given nibrsCode may be either an id or a code string. A rule must refer to id if the
 *   relevant code strings are not unique. We should deprecate code string and make all rules refer
 *   to id only.
 */
export const getNibrsCodeCrimeAgainst = (getState) => (nibrsCode) => {
    const state = getState();
    const nibrsOffenseCode =
        nibrsOffenseCodeByCodeSelector(state)[nibrsCode] ||
        nibrsOffenseCodesSelector(state)[nibrsCode];
    return get(nibrsOffenseCode, 'crimeAgainst');
};

import {
    AttributeTypeEnum,
    EntityTypeEnum,
    RefContextEnum,
    LinkTypesEnum,
    RenConfigurationEnum,
} from '@mark43/rms-api';
import { get, head, some, map } from 'lodash';
import { createSelector } from 'reselect';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { reportDefinitionHasTowVehicle } from '~/client-common/helpers/reportDefinitionsHelpers';

import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { reportDefinitionByIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { impoundByReportIdSelector } from '~/client-common/core/domain/impounds/state/data';
import { reportAttributesWhereSelector } from '~/client-common/core/domain/report-attributes/state/data';
import { towVehicleByReportIdSelector } from '~/client-common/core/domain/tow-vehicles/state/data';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import {
    currentReportSelector,
    currentReportIdSelector,
    currentReportCardUITitleByTypeSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';

import towVehicleImpoundForm from '../forms/towVehicleImpoundForm';

const towVehicleReasonForTowStolen = globalAttributes.towVehicleReasonForTowGlobal.stolen;

// Tow Vehicle card
export function refreshTowVehicleForm() {
    return (dispatch, getState, { formsRegistry }) => {
        const state = getState();
        const currentReportId = currentReportIdSelector(state);
        const towVehicle = towVehicleByReportIdSelector(state)(currentReportId);
        const towVehicleReasonForTowReportAttributes = reportAttributesWhereSelector(state)({
            reportId: currentReportId,
            attributeType: AttributeTypeEnum.TOW_VEHICLE_REASON_FOR_TOW.name,
        });
        const towedFromLocation = head(
            locationEntityLinksWhereSelector(state)({
                entityId: towVehicle.id,
                entityType: EntityTypeEnum.RMS_TOW_VEHICLE.name,
                linkType: LinkTypesEnum.TOW_VEHICLE_TOWED_FROM_LOCATION,
            })
        );
        const reportedStolenLocation = head(
            locationEntityLinksWhereSelector(state)({
                entityId: towVehicle.id,
                entityType: EntityTypeEnum.RMS_TOW_VEHICLE.name,
                linkType: LinkTypesEnum.TOW_VEHICLE_REPORTED_STOLEN_LOCATION,
            })
        );
        const outsideRecoveryLocation = head(
            locationEntityLinksWhereSelector(state)({
                entityId: towVehicle.id,
                entityType: EntityTypeEnum.RMS_TOW_VEHICLE.name,
                linkType: LinkTypesEnum.TOW_VEHICLE_RECOVERY_LOCATION,
            })
        );

        formsRegistry.maybeDeferredOperation(RefContextEnum.FORM_TOW_VEHICLE.name, 0, (form) => {
            form.set('', {
                ...towVehicle,
                reasonsForTow: map(towVehicleReasonForTowReportAttributes, 'attributeId'),
                towedFromLocation,
                reportedStolenLocation,
                outsideRecoveryLocation,
            });
        });
    };
}

export const formattedTowVehicleCardTitleSelector = createSelector(
    currentReportSelector,
    reportDefinitionByIdSelector,
    currentReportCardUITitleByTypeSelector,
    (currentReport, reportDefinitionById, currentReportCardUITitleByType) => {
        const reportDefinition =
            reportDefinitionById(get(currentReport, 'reportDefinitionId')) || {};

        if (reportDefinitionHasTowVehicle(reportDefinition)) {
            const cardTitle = currentReportCardUITitleByType(reportCardEnum.TOW_VEHICLE.id);
            const isRenNotRequired =
                get(reportDefinition, 'renConfiguration') !== RenConfigurationEnum.REQUIRED.name;
            const recordNumber = get(currentReport, 'recordNumber');
            const recordNumberDisplay =
                isRenNotRequired && !isUndefinedOrNull(recordNumber) ? ` #${recordNumber}` : '';
            return `${cardTitle}${recordNumberDisplay}`;
        }
        return '';
    }
);

export const reasonForTowIsStolenSelector = createSelector(
    currentReportIdSelector,
    towVehicleByReportIdSelector,
    parentAttributeIdByAttributeIdSelector,
    reportAttributesWhereSelector,
    (
        currentReportId,
        towVehicleByReportId,
        parentAttributeIdByAttributeId,
        reportAttributesWhere
    ) => {
        const towVehicle = towVehicleByReportId(currentReportId);
        if (towVehicle && towVehicle.id) {
            const towVehicleReasonForTowReportAttrs = reportAttributesWhere({
                reportId: currentReportId,
                attributeType: AttributeTypeEnum.TOW_VEHICLE_REASON_FOR_TOW.name,
            });

            return some(
                towVehicleReasonForTowReportAttrs,
                (towVehicleReasonForTowReportAttr) =>
                    parentAttributeIdByAttributeId(towVehicleReasonForTowReportAttr.attributeId) ===
                    towVehicleReasonForTowStolen
            );
        } else {
            return false;
        }
    }
);

// Tow Vehicle Impound card
export function refreshTowVehicleImpoundForm() {
    return (dispatch, getState) => {
        const state = getState();
        const currentReportId = currentReportIdSelector(state);
        const impound = impoundByReportIdSelector(state)(currentReportId);

        dispatch(towVehicleImpoundForm.actionCreators.change(impound));
        dispatch(towVehicleImpoundForm.actionCreators.setValidity(undefined, true));
    };
}

import { ReportDefinitionOrderedCard } from '../../types';

export const setOrderedReportCardLinks = (
    orderedReportCardLinks: ReportDefinitionOrderedCard[]
) => {
    return {
        type: 'SET_ORDERED_REPORT_CARD_LINKS',
        payload: {
            orderedReportCardLinks,
        },
    } as const;
};

export type ReportUiState = {
    orderedReportCardLinks: ReportDefinitionOrderedCard[];
};

type ReportUiStateActions = ReturnType<typeof setOrderedReportCardLinks>;

export const initialState: ReportUiState = {
    orderedReportCardLinks: [],
};

export const reportUiStateReducer = (
    state: ReportUiState,
    action: ReportUiStateActions
): ReportUiState => {
    switch (action.type) {
        case 'SET_ORDERED_REPORT_CARD_LINKS':
            return {
                ...state,
                orderedReportCardLinks: action.payload.orderedReportCardLinks,
            };
        default:
            return state;
    }
};

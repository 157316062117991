import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { relationshipsDataSelector } from '../../state/ui';
import { involvedNameViewModelsForDataSelector } from '../../../../../legacy-redux/selectors/involvedNamesSelectors';
import { buildRelationshipsFormModel } from '../../state/forms/relationshipsForm';
import RelationshipsCard from './RelationshipsCard';

const RelationshipsCardWrapper = ({
    involvedNameViewModelsForData,
    relationshipsData,
    ...props
}) => {
    const involvedNameViewModels = involvedNameViewModelsForData(relationshipsData);
    return (
        !isEmpty(involvedNameViewModels) && (
            <RelationshipsCard {...props} involvedNameViewModels={involvedNameViewModels} />
        )
    );
};

const mapStateToProps = createStructuredSelector({
    involvedNameViewModelsForData: involvedNameViewModelsForDataSelector,
    relationshipsData: relationshipsDataSelector,
});

const mapDispatchToProps = { buildRelationshipsFormModel };

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipsCardWrapper);

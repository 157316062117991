import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

const RestrictedAccessNoticeContainer = styled.div`
    background: ${(props) => props.theme.colors.extraLightGrey};
    background-image: url('/images/stripe_pattern.png');
    background-repeat: repeat;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    font: ${({ theme }) => `13px ${theme.fontFamilies.proximaNova}`};
    font-style: italic;
    color: ${({ theme }) => theme.colors.mediumLightGrey};
    padding: 6px 10px;
    box-sizing: border-box;
    text-transform: uppercase;
`;

const RestrictedAccessNotice = () => (
    <RestrictedAccessNoticeContainer>
        {componentStrings.quickSearch.RestrictedAccessNotice.restricedAccess}
    </RestrictedAccessNoticeContainer>
);

export default RestrictedAccessNotice;

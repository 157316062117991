import _ from 'lodash';
import { createSelector } from 'reselect';

const shapefilesSelector = (state) => state.data.shapefilesAdmin.shapefiles;

export const uiSelector = (state) => state.ui.shapefilesAdmin;

export const shapefileByIdSelector = createSelector(shapefilesSelector, (shapefiles) => (id) =>
    shapefiles[id]
);

export const selectedShapefileSelector = createSelector(uiSelector, (ui) => ui.selectedShapefile);

export const shapefilesListItemsSelector = createSelector(
    shapefilesSelector,
    selectedShapefileSelector,
    (shapefiles, selectedShapefile) =>
        _.sortBy(
            _(shapefiles)
                .map((shapefile) => ({
                    title: shapefile.displayName || shapefile.geojsonFileId,
                    key: shapefile.id,
                    selected: shapefile.id === _.get(selectedShapefile, 'id'),
                }))
                .value(),
            'title'
        )
);

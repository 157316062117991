import _, { get, isArray, map } from 'lodash';
import { createSelector } from 'reselect';

import { vehicleMakesSelector } from '../data';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

const OTHER_OPTION = {
    value: -1,
    display: 'Other',
};

export const formatVehicleMakeByIdSelector = createSelector(
    vehicleMakesSelector,
    (vehicleMakes) => (id) => {
        const ids = isArray(id) ? id : [id];
        return joinTruthyValues(
            map(ids, (id) => {
                if (id === OTHER_OPTION.value) {
                    return OTHER_OPTION.display;
                }
                return get(vehicleMakes[id], 'makeName');
            })
        );
    }
);

export const vehicleMakeOptionsSelector = createSelector(
    vehicleMakesSelector,
    (vehicleMakes) => (vehicleMakeIds) => {
        return _(vehicleMakeIds)
            .map((id) => vehicleMakes[id])
            .compact()
            .map((vehicleMake) => ({
                display: vehicleMake.makeName,
                value: vehicleMake.id,
            }))
            .sortBy('display')
            .value();
    }
);

import { ItemCaseLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'itemCaseLinks';

const itemCaseLinksModule = createLinkModule<ItemCaseLink>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// SELECTORS
export const itemCaseLinksWhereSelector = itemCaseLinksModule.selectors.linksWhereSelector;

// REDUCER
export default itemCaseLinksModule.reducerConfig;

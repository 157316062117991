import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { kickOffJobAsync } from '../state/ui';

const ModalBodyText = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-lg);
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.darkGrey};
`;

const context = {
    name: boxEnum.BLACKSMITH_INTEGRATION_KICK_OFF_CONFIRMATION_MODAL,
};

function BlacksmithIntegationKickOffConfirmationModal({ kickOffJobAsync }) {
    return (
        <Modal
            okText={'Confirm'}
            context={context}
            title={'Confirm Kick Off Job Run'}
            onSave={kickOffJobAsync}
        >
            <ModalBodyText>{'Are you sure you want to kick off a job run?'}</ModalBodyText>
        </Modal>
    );
}

export default connect(null, {
    kickOffJobAsync,
})(BlacksmithIntegationKickOffConfirmationModal);

import { filter, map, noop, parseInt } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Form, Observer, createFormConfiguration, lifecycleOptions } from 'markformythree';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import {
    sortElasticOffenseViewModels,
    formatElasticOffenseViewModelOffenseCode,
} from '~/client-common/helpers/elasticOffenseHelpers';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import formsRegistry from '../../../../../../core/formsRegistry';
import { selectOffense } from '../../../state/ui/arrest-charges-side-panel/priorOffenseSearchOffenseSelector';
import testIds from '../../../../../../core/testIds';
import Button, { buttonTypes } from '../../../../../../legacy-redux/components/core/Button';
import Row from '../../../../../core/components/Row';
import { SubsectionTitleText } from '../../../../../core/components/typography';
import { unregisterPriorOffenseSearchScreenForm } from './PriorOffenseSearchScreen';
import { MFTRadioWithPaddedOptions } from './components/RadioWithPaddedOptions';

const strings =
    componentStrings.reports.core.ArrestChargesSidePanel.PriorOffenseSearchOffenseSelectorScreen;

const RenTitleRow = styled(Row)`
    margin-top: 25px;
    margin-bottom: 20px;
`;

const formName = formClientEnum.ARREST_CHARGES_SIDE_PANEL_PRIOR_OFFENSE_SELECTOR;
export const unregisterPriorOffenseSearchOffenseSelectorScreenForm = () =>
    formsRegistry.unregister(formName);

export const onSelectOffense = ({ offenseId }) => (dispatch) => {
    const form = formsRegistry.get(formName);
    return dispatch(selectOffense({ form, offenseId })).then((selectedOffense) => {
        unregisterPriorOffenseSearchOffenseSelectorScreenForm();
        unregisterPriorOffenseSearchScreenForm();
        return selectedOffense;
    });
};

export const onCancelSidePanel = unregisterPriorOffenseSearchOffenseSelectorScreenForm;

const PriorOffenseSearchOffenseSelectorScreen = withFields([REPORT_REPORTING_EVENT_NUMBER])(
    ({ screenState, onSelectOffenseCallback, fieldDisplayNames }) => {
        const {
            isPerformingAsyncAction,
            priorOffenseSearch: { priorOffenseSearchSelectedElasticReportViewModel },
        } = screenState;
        const {
            reportDefinition: { offensesAndIncidents },
        } = priorOffenseSearchSelectedElasticReportViewModel;
        const { renWithShortTitle } = getViewModelProperties(
            priorOffenseSearchSelectedElasticReportViewModel
        );
        const offenseDisplayName = useOffenseFieldName();

        const offenseRadioButtonOptions = map(
            sortElasticOffenseViewModels(filter(offensesAndIncidents, 'isOffense')),
            (elasticOffenseViewModel) => {
                const { id } = elasticOffenseViewModel;
                return {
                    value: id,
                    label: formatElasticOffenseViewModelOffenseCode(elasticOffenseViewModel),
                };
            }
        );

        return (
            <Form
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                name={formName}
                context={formName}
                configuration={createFormConfiguration({
                    selectedElasticOffenseViewModelId: {},
                })}
                // Specifically no validation for this form
                onValidate={noop}
                render={() => {
                    return (
                        <>
                            <RenTitleRow>
                                <SubsectionTitleText>
                                    {`${fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER} ${renWithShortTitle}`}
                                </SubsectionTitleText>
                            </RenTitleRow>
                            <Row>
                                <MFTRadioWithPaddedOptions
                                    path="selectedElasticOffenseViewModelId"
                                    newlines={true}
                                    orientation="column"
                                    disabled={isPerformingAsyncAction}
                                    options={offenseRadioButtonOptions}
                                />
                            </Row>
                            <Row>
                                <Observer
                                    subscriptions={{
                                        selectedElasticOffenseViewModelId:
                                            'selectedElasticOffenseViewModelId',
                                    }}
                                    render={({ selectedElasticOffenseViewModelId }) => {
                                        return (
                                            <Button
                                                className={buttonTypes.PRIMARY}
                                                disabled={
                                                    isPerformingAsyncAction ||
                                                    isUndefinedOrNull(
                                                        selectedElasticOffenseViewModelId
                                                    )
                                                }
                                                onClick={() =>
                                                    onSelectOffenseCallback({
                                                        offenseId: parseInt(
                                                            selectedElasticOffenseViewModelId
                                                        ),
                                                    })
                                                }
                                                testId={
                                                    testIds.CHARGES_SIDE_PANEL_PRIOR_OFFENSE_SEARCH_SELECTOR_SCREEN_ADD_PRIOR_OFFENSE
                                                }
                                            >
                                                {strings.addOffenseButtonText(offenseDisplayName)}
                                            </Button>
                                        );
                                    }}
                                />
                            </Row>
                        </>
                    );
                }}
            />
        );
    }
);

export default PriorOffenseSearchOffenseSelectorScreen;

import partialRight from 'lodash/partialRight';
import { CountryCodeEnum, CountryCodeEnumType } from '@mark43/rms-api';

export const CURRENCIES = {
    GBP: { countryCode: CountryCodeEnum.GB.name, iso: 'GBP', symbol: '£' },
    USD: { countryCode: CountryCodeEnum.US.name, iso: 'USD', symbol: '$' },
} as const;

type CurrencyIsoCode = keyof typeof CURRENCIES;
type Currency = {
    countryCode: CountryCodeEnumType;
    iso: CurrencyIsoCode;
    symbol: string;
};

const getLocationSpecificCurrency = (countryCode: string | undefined): Currency => {
    return countryCode === CountryCodeEnum.GB.name ? CURRENCIES.GBP : CURRENCIES.USD;
};

const formatNumber = (value: number, currency: Currency) => {
    if (Number.isInteger(value)) {
        return `${currency.symbol}${value}`;
    }

    return `${currency.symbol}${value.toFixed(2)}`;
};

const formatValue = (value: number | string | undefined, currency: Currency) => {
    if (value === undefined) {
        return '';
    }

    if (typeof value === 'string') {
        const parsedValue = parseFloat(value);
        return !isNaN(parsedValue) ? formatNumber(parsedValue, currency) : '';
    }

    return formatNumber(value, currency);
};

export const getLocationSpecificCurrencyFormatter = (
    countryCode: string | undefined
): CurrencyFormatters => {
    const currency = getLocationSpecificCurrency(countryCode);
    return {
        formatCurrency: partialRight(formatValue, currency),
    };
};

export type CurrencyFormatters = {
    formatCurrency(value: number | string | undefined): string;
};

import React, { useCallback, useState } from 'react';
import { partialRight, sortBy } from 'lodash';
import styled from 'styled-components';
import { TableRow as _TableRow } from 'components-mark43';
import { ComplianceResubmissionStatusView } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import _TableResultsSummary from '../../../legacy-redux/components/core/tables/TableResultsSummary';
import _Pagination from '../../../legacy-redux/components/core/Pagination';
import { scrollToElement } from '../../../legacy-redux/helpers/navigationHelpers';
import testIds from '../../../core/testIds';
import RMSTableComposite from '../../core/components/RMSTableComposite';
import { COMPLIANCE_DEFAULT_PAGE_SIZE } from '../configuration';
import { RenderBodyRowT } from '../types';
import { useDateTimeFormatter } from '../../core/current-user/hooks/dateTimeFormats';
import ComplianceReportTitleCell from './table/cells/ComplianceReportTitleCell';
import _TableCell, { TableCellContent } from './table/TableCell';
import TableHeader, { TableHeaderWrapper } from './table/TableHeader';
import TableWrapper from './table/TableWrapper';
import TableHeaderCell from './table/TableHeaderCell';

const labels = componentStrings.compliance.ComplianceResubmissionReportsTable.labels;

const RESULT_REPORT_CONTAINER_CLASS = 'compliance-resubmission-reports-table';
const SCROLL_CONTAINER_CLASS = 'mark43-scrollable-under-subheader';
const COLUMN_REPORT_WIDTH = 380;
const COLUMN_LAST_EXPORTED_DATE_WIDTH = 570;

const TableRow = styled(_TableRow)`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 10px 0;
`;

const TableCell = styled(_TableCell)`
    display: inline-block;
    align-items: unset;
`;

const TableResultsSummary = styled(_TableResultsSummary)`
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
`;

const Pagination = styled(_Pagination)`
    vertical-align: middle;
`;

const PaginationWrapper = styled.div`
    text-align: right;
    padding: 10px 20px 30px;
`;

const renderHeaderRow = () => {
    return (
        <TableHeaderWrapper>
            <TableHeaderCell width={COLUMN_REPORT_WIDTH}>{labels.report}</TableHeaderCell>
            <TableHeader width={COLUMN_LAST_EXPORTED_DATE_WIDTH}>
                {labels.lastExportDate}
            </TableHeader>
        </TableHeaderWrapper>
    );
};

const renderBodyRow: React.FC<RenderBodyRowT<ComplianceResubmissionStatusView>> = (
    { item },
    dateTimeFormatter
) => {
    const { exportDateTimeUtc, reportShortTitle } = item;

    return (
        <TableRow key={reportShortTitle?.reportId}>
            <TableCell width={COLUMN_REPORT_WIDTH}>
                <ComplianceReportTitleCell reportShortTitle={reportShortTitle} />
            </TableCell>
            <TableCell width={COLUMN_LAST_EXPORTED_DATE_WIDTH}>
                <TableCellContent>
                    {dateTimeFormatter.formatDate(exportDateTimeUtc)}
                </TableCellContent>
            </TableCell>
        </TableRow>
    );
};

type ComplianceResubmissionReportsTableProps = {
    reportStatuses?: ComplianceResubmissionStatusView[];
};

function useReportsPagination(reportStatuses: ComplianceResubmissionStatusView[]) {
    const [page, setPage] = useState(1);

    const total = reportStatuses?.length || 0;
    const zeroBasedPage = page - 1;
    const sliceOffset = zeroBasedPage * COMPLIANCE_DEFAULT_PAGE_SIZE;

    // Pagination happens on the FE so let's sort
    // the records to keep the order consistent
    const sortedReportStatuses = sortBy(reportStatuses, 'reportShortTitle.reportId').slice(
        sliceOffset,
        sliceOffset + COMPLIANCE_DEFAULT_PAGE_SIZE
    );

    const handlePaginationClick = useCallback(
        (currentPage, nextPage) => {
            if (nextPage * COMPLIANCE_DEFAULT_PAGE_SIZE > total) {
                setPage(Math.ceil(total / COMPLIANCE_DEFAULT_PAGE_SIZE));
            } else if (nextPage <= 1) {
                setPage(1);
            } else {
                setPage(nextPage);
            }

            // Handling this directly when clicking the pagination since
            // this pagination is client-only and does not need to wait
            // for data from the server
            scrollToElement({
                selector: `.${RESULT_REPORT_CONTAINER_CLASS}`,
                wrapperSelector: `.${SCROLL_CONTAINER_CLASS}`,
            });
        },
        [setPage, total]
    );

    return {
        from: sliceOffset,
        to: sliceOffset + sortedReportStatuses.length,
        total,
        page,
        handlePaginationClick,
        resubmissionRows: sortedReportStatuses,
    };
}

const ComplianceResubmissionReportsTable: React.FC<ComplianceResubmissionReportsTableProps> = ({
    reportStatuses = [],
}) => {
    const { handlePaginationClick, resubmissionRows, to, from, total, page } = useReportsPagination(
        reportStatuses
    );

    const renderBodyRowFunction = partialRight(renderBodyRow, useDateTimeFormatter());

    return (
        <>
            <TableWrapper>
                <RMSTableComposite
                    className={RESULT_REPORT_CONTAINER_CLASS}
                    columns={[]}
                    items={resubmissionRows}
                    includeFilter={false}
                    noDataText={labels.noReports}
                    renderHeaderRow={renderHeaderRow}
                    renderBodyRow={renderBodyRowFunction}
                    testId={testIds.COMPLIANCE_DASHBOARD_REPORT_RESUBMISSIONS_TABLE}
                />
            </TableWrapper>
            {!!total && (
                <PaginationWrapper>
                    <TableResultsSummary
                        from={from}
                        to={to}
                        totalResults={total}
                        className={null}
                    />
                    <Pagination
                        currentPage={page}
                        itemsPerPage={COMPLIANCE_DEFAULT_PAGE_SIZE}
                        itemCount={total}
                        onClick={handlePaginationClick}
                        maxEdgeItems={1}
                        className={null}
                        gapText={null}
                    />
                </PaginationWrapper>
            )}
        </>
    );
};

export default ComplianceResubmissionReportsTable;

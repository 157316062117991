import { identity } from 'lodash';
import { ElasticPersonQuery } from '@mark43/rms-api';
import { InferFormDataShape, createField, createFormConfiguration, _Form } from 'markformythree';

import { recursivelyConvertQueryDetailObjectsToIds } from '../../../../search/core/utils/recursivelyConvertQueryDetailObjectsToIds';

export const formConfiguration = createFormConfiguration({
    sexAttrId: createField<number>({}),
    raceAttrId: createField<number>({}),
    ethnicityAttrId: createField<number>({}),
    buildAttrId: createField<number>({}),
    eyeColorAttrId: createField<number>({}),
    hairColorAttrId: createField<number>({}),
    hairStyleAttrId: createField<number>({}),
    facialHairTypeAttrId: createField<number>({}),
    physicalCharacteristicAttrIds: createField<number[]>({}),
    ageRange: createField<[number, number]>({}),
    weightRange: createField<[number, number]>({}),
    heightRange: createField<[number, number]>({}),
    identifyingMarkTypeAttrId: createField<number>({}),
    bodyPartAttrId: createField<number>({}),
});

export type PhotoLineupFilterFormConfiguration = typeof formConfiguration;
type PhotoLineupFilterFormDataShape = InferFormDataShape<PhotoLineupFilterFormConfiguration>;
export type PhotoLineupFilterMFTForm = _Form<PhotoLineupFilterFormConfiguration>;

function arrayOrUndefined(value?: number): number[] | undefined {
    return value ? [value] : undefined;
}

export function convertFromFormModel(
    formModel: PhotoLineupFilterFormDataShape
): Partial<ElasticPersonQuery> {
    return {
        sexAttrId: formModel.sexAttrId || undefined,
        raceAttrIds: arrayOrUndefined(formModel.raceAttrId),
        ethnicityAttrIds: arrayOrUndefined(formModel.ethnicityAttrId),
        buildAttrIds: arrayOrUndefined(formModel.buildAttrId),
        eyeColorAttrIds: arrayOrUndefined(formModel.eyeColorAttrId),
        hairColorAttrIds: arrayOrUndefined(formModel.hairColorAttrId),
        hairStyleAttrIds: arrayOrUndefined(formModel.hairStyleAttrId),
        facialHairTypeAttrIds: arrayOrUndefined(formModel.facialHairTypeAttrId),
        physicalCharacteristicAttrIds: formModel.physicalCharacteristicAttrIds,
        ageRangeStart: formModel.ageRange?.[0],
        ageRangeEnd: formModel.ageRange?.[1],
        weightRangeMin: formModel.weightRange?.[0],
        weightRangeMax: formModel.weightRange?.[1],
        heightRangeMin: formModel.heightRange?.[0],
        heightRangeMax: formModel.heightRange?.[1],
        identifyingMarks:
            formModel.identifyingMarkTypeAttrId || formModel.bodyPartAttrId
                ? [
                      {
                          identifyingMarkTypeAttrId:
                              formModel.identifyingMarkTypeAttrId || undefined,
                          bodyPartAttrId: formModel.bodyPartAttrId || undefined,
                      },
                  ]
                : undefined,
    };
}

/**
 * The expected type of `value` is `[number]`, coming from `recursivelyConvertQueryDetailObjectsToIds`.
 * When `value` is an array, it's expected to include only 1 number for the fields that are single-select dropdowns.
 */
function getAttrId(value: unknown): number | undefined {
    return Array.isArray(value) && value.length > 0 ? value[0] : undefined;
}

export function convertToFormModel(
    elasticQuery: ElasticPersonQuery
): PhotoLineupFilterFormDataShape {
    const query = recursivelyConvertQueryDetailObjectsToIds(elasticQuery, identity);
    return {
        sexAttrId: getAttrId(query.sexAttrId),
        raceAttrId: getAttrId(query.raceAttrId),
        ethnicityAttrId: getAttrId(query.ethnicityAttrId),
        buildAttrId: getAttrId(query.buildAttrId),
        eyeColorAttrId: getAttrId(query.eyeColorAttrId),
        hairColorAttrId: getAttrId(query.hairColorAttrId),
        hairStyleAttrId: getAttrId(query.hairStyleAttrId),
        facialHairTypeAttrId: getAttrId(query.facialHairTypeAttrId),
        physicalCharacteristicAttrIds: query.physicalCharacteristicAttrIds,
        ageRange: [query.ageRangeStart, query.ageRangeEnd],
        weightRange: [query.weightRangeMin, query.weightRangeMax],
        heightRange: [query.heightRangeMin, query.heightRangeMax],
        identifyingMarkTypeAttrId: getAttrId(query.identifyingMarkTypeAttrId),
        bodyPartAttrId: getAttrId(query.bodyPartAttrId),
    };
}

export const ALL_EVENTS_OPTION = 'ALL_EVENTS';

export enum HistoryType {
    DEFAULT = 'DEFAULT',
    CASE = 'CASE',
    VEHICLE = 'VEHICLE',
}

export enum VehicleHistoryOptionsType {
    ANPR = 'ANPR',
    REGISTRATION_CHANGE = 'REGISTRATION_CHANGE',
    REPORT_ACTIVITY = 'REPORT_ACTIVITY',
    OTHER_ACTIVITY = 'OTHER_ACTIVITY',
}

import _ from 'lodash';

import getReportingEventsResource from '~/client-common/core/domain/reporting-events/resources/reportingEventsResource';
import { storeReportShortTitles } from '~/client-common/core/domain/report-short-titles/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { CAD_TICKET_CAD_AGENCY_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import stringsConfig from '~/client-common/core/strings';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';

import { setReport } from './reportsActions';
import { openBox, closeBox, saveBoxFailure } from './boxActions';
import recordNumberChangeActionTypes from './types/recordNumberChangeActionTypes';

const strings = stringsConfig.components.reports.RecordNumberChangeForm;

const context = {
    name: boxEnum.RECORD_NUMBER_CHANGE_MODAL,
};

export function clearRecordNumberChangeModal() {
    return {
        type: recordNumberChangeActionTypes.CLEAR_RECORD_NUMBER_CHANGE_MODAL,
    };
}

export function openRecordNumberChangeModal(reportDefinition, recordSequenceType) {
    return function (dispatch) {
        dispatch(openBox(context, { reportDefinition, recordSequenceType }));
    };
}

function updateRecordNumberStart() {
    return {
        type: recordNumberChangeActionTypes.UPDATE_RECORD_NUMBER_START,
    };
}

function updateRecordNumberSuccess() {
    return {
        type: recordNumberChangeActionTypes.UPDATE_RECORD_NUMBER_SUCCESS,
    };
}

function updateRecordNumberFailure(errorMessage) {
    return {
        type: recordNumberChangeActionTypes.UPDATE_RECORD_NUMBER_FAILURE,
        payload: errorMessage,
    };
}

export function updateRecordNumber(reportId, newRecordNumber) {
    return function (dispatch) {
        dispatch(updateRecordNumberStart());

        return getReportResource()
            .updateRecordNumber(reportId, newRecordNumber)
            .then((newReport) => {
                dispatch(setReport(newReport));
                dispatch(updateRecordNumberSuccess());
                dispatch(closeBox(context));
                window.location.reload();
            })
            .catch((err) => {
                dispatch(updateRecordNumberFailure(err.message));
                dispatch(saveBoxFailure(context));
            });
    };
}

function validateRecordNumberUpdateStart() {
    return {
        type: recordNumberChangeActionTypes.VALIDATE_RECORD_NUMBER_UPDATE_START,
    };
}

function validateRecordNumberUpdateSuccess(newRecordNumber) {
    return {
        type: recordNumberChangeActionTypes.VALIDATE_RECORD_NUMBER_UPDATE_SUCCESS,
        payload: { newRecordNumber },
    };
}

function validateRecordNumberUpdateFailure(errorMessage) {
    return {
        type: recordNumberChangeActionTypes.VALIDATE_RECORD_NUMBER_UPDATE_FAILURE,
        payload: errorMessage,
    };
}

function enableRecordNumberUpdate(message) {
    return {
        type: recordNumberChangeActionTypes.ENABLE_RECORD_NUMBER_UPDATE,
        payload: message,
    };
}

export function disableRecordNumberUpdate(message) {
    return {
        type: recordNumberChangeActionTypes.DISABLE_RECORD_NUMBER_UPDATE,
        payload: message,
    };
}

export function validateRecordNumberUpdate(reportDefinition, newRecordNumber) {
    return function (dispatch, getState) {
        dispatch(validateRecordNumberUpdateStart());

        const state = getState();
        const allowMultiple = reportDefinition.allowMultiple;
        const recordNumberDisplayName = formatFieldByNameSelector(state)(
            CAD_TICKET_CAD_AGENCY_EVENT_NUMBER
        );
        const reportingEventsResource = getReportingEventsResource();

        return reportingEventsResource
            .getReportingEventInfoByCadAgencyEventNumber(newRecordNumber)
            .then((result) => {
                const reportShortTitles = result.reportShortTitles;
                dispatch(storeReportShortTitles(reportShortTitles));
                dispatch(validateRecordNumberUpdateSuccess(newRecordNumber));

                const hasSameType =
                    _.filter(reportShortTitles, (r) => {
                        return r.reportDefinitionId === reportDefinition.id;
                    }).length > 0;

                if (hasSameType && !allowMultiple) {
                    dispatch(
                        disableRecordNumberUpdate(
                            strings.recordNumberTakenError(recordNumberDisplayName)
                        )
                    );
                } else if (!_.isArray(result.cadTickets) || result.cadTickets.length === 0) {
                    dispatch(
                        disableRecordNumberUpdate(
                            strings.cadTicketNotFoundError(recordNumberDisplayName)
                        )
                    );
                } else {
                    dispatch(
                        enableRecordNumberUpdate(
                            strings.validRecordNumberNotice(recordNumberDisplayName)
                        )
                    );
                }
            })
            .catch((err) => {
                dispatch(validateRecordNumberUpdateFailure(err.message));
                dispatch(saveBoxFailure(context));
            });
    };
}

/**
 * Store the form's submit handler in the ui state, so a parent component can
 *   trigger the form to be submitted. In this case, the containing modal has
 *   the submit button, and the form itself does not. This is a temporary ugly
 *   workaround that can be removed if/when we update redux-form to v4.2.0.
 * @param  {function} handleSubmit
 * @return {Object}
 */
export function storeHandleSubmit(handleSubmit) {
    return {
        type: recordNumberChangeActionTypes.STORE_HANDLE_SUBMIT,
        payload: handleSubmit,
    };
}

import { chain, includes, reject } from 'lodash';
import { ProductModuleEnumType, ProductModuleEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import type { RootState } from '../../../../../redux/state';

const STORE_PRODUCT_MODULES = 'STORE_PRODUCT_MODULES';
const REMOVE_PRODUCT_MODULES_WHERE = 'REMOVE_PRODUCT_MODULES_WHERE';

type State = RootState['data']['productModules'];

const initialState: State = {
    activeProductModules: [],
};

export const storeProductModules = (productModules: ProductModuleEnumType[]) => {
    return {
        type: STORE_PRODUCT_MODULES,
        payload: productModules,
    };
};

export const removeProductModuleWhere = (predicate: ProductModuleEnumType) => ({
    type: REMOVE_PRODUCT_MODULES_WHERE,
    payload: predicate,
});

type Action = ReturnType<typeof storeProductModules> | ReturnType<typeof removeProductModuleWhere>;

const productModulesReducer = (state: State = initialState, action: Action) => {
    switch (action.type) {
        case STORE_PRODUCT_MODULES: {
            return {
                ...state,
                activeProductModules: action.payload,
            };
        }
        case REMOVE_PRODUCT_MODULES_WHERE: {
            return {
                ...state,
                activeProductModules: chain([] as ProductModuleEnumType[])
                    .concat(action.payload)
                    .reduce(
                        (list, item) => reject(list, (_item) => _item === item),
                        state.activeProductModules
                    )
                    .value(),
            };
        }
        default:
            return state;
    }
};
export default productModulesReducer;

const baseSelector = (state: RootState) => state.data.productModules;

export const productModulesSelector = createSelector(baseSelector, (productModuleState: State) => {
    return productModuleState.activeProductModules;
});

export const isProductModuleActiveSelector = createSelector(
    productModulesSelector,
    (activeModules) => (module: ProductModuleEnumType) => {
        return includes(activeModules, module);
    }
);

export const isWarrantModuleActiveSelector = createSelector(
    isProductModuleActiveSelector,
    (isProductModuleActive) => isProductModuleActive(ProductModuleEnum.WARRANTS.name)
);

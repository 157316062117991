import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';

// Redux
import {
    closeBlacksmithIntegrationSidePanel,
    submitBlacksmithIntegrationForm,
    isBlacksmithIntegrationSidePanelEditSelector,
} from '../state/ui';
import { currentUserDepartmentProfileSelector } from '../../../core/current-user/state/ui';

// components
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import BlacksmithIntegrationForm from './BlacksmithIntegrationForm';

const context = {
    name: boxEnum.BLACKSMITH_INTEGRATION_SIDE_PANEL,
};

function BlacksmithIntegrationSidePanel({ currentUserDepartmentProfile, onSubmit, close, isEdit }) {
    return (
        <SidePanel
            title={`${isEdit ? 'Edit' : 'Create'} Integration for ${
                currentUserDepartmentProfile.displayName
            }`}
            onSave={onSubmit}
            onCancel={close}
            context={context}
            width={480}
        >
            <BlacksmithIntegrationForm />
        </SidePanel>
    );
}

export default compose(
    connect((state) => ({
        currentUserDepartmentProfile: currentUserDepartmentProfileSelector(state),
        isEdit: isBlacksmithIntegrationSidePanelEditSelector(state),
    })),
    connect(null, (dispatch, { isEdit }) => ({
        onSubmit: () => dispatch(submitBlacksmithIntegrationForm(isEdit)),
        close: () => dispatch(closeBlacksmithIntegrationSidePanel()),
    }))
)(BlacksmithIntegrationSidePanel);

import React from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { closeBox, saveBoxHalt } from '../../../../../legacy-redux/actions/boxActions';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { RRFTextArea } from '../../../../core/forms/components/TextArea';
import offenseCodeChangeReasonForm from '../../state/forms/offenseCodeChangeReasonForm';
import Row from '../../../../core/components/Row';
import Modal from '../../../../../legacy-redux/components/core/Modal';
import ReactReduxForm from '../../../../core/forms/components/ReactReduxForm';
import { createModalSelector } from '../../../../core/box/state/ui';

const context = {
    name: boxEnum.OFFENSE_CODE_CHANGE_REASON_MODAL,
};

const strings = componentStrings.reports.core.OffenseCodeChangeReasonModal;

const OffenseCodeChangeReasonText = styled.div`
    padding-bottom: 15px;
`;

interface OffenseCodeChangeReasonModalPropsT {
    closeBox: () => void;
    submitForm: (callback: () => void) => void;
}

function OffenseCodeChangeReasonModal({
    closeBox,
    submitForm,
}: OffenseCodeChangeReasonModalPropsT) {
    const offenseDisplayName = useOffenseFieldName();
    const callback: () => void = useSelector(createModalSelector(context, 'callback'));
    const cancel: () => void = useSelector(createModalSelector(context, 'cancel'));
    const onCancel = () => {
        cancel();
        closeBox();
    };
    return (
        <Modal
            context={context}
            title={strings.title}
            onClose={onCancel}
            okText={strings.okText}
            onSave={() => submitForm(callback)}
        >
            <ReactReduxForm {...offenseCodeChangeReasonForm}>
                <Row>
                    <OffenseCodeChangeReasonText>{strings.title}</OffenseCodeChangeReasonText>
                </Row>
                <Row>
                    <RRFTextArea
                        label={strings.message(offenseDisplayName)}
                        rows={5}
                        path="offenseCodeChangeReason"
                        resizable={false}
                        placeholder={strings.placeholder}
                    />
                </Row>
            </ReactReduxForm>
        </Modal>
    );
}

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    closeBox: () => {
        // @ts-expect-error client-common to client RND-7529
        dispatch(offenseCodeChangeReasonForm.actionCreators.reset());
        dispatch(closeBox(context));
    },
    submitForm: (callback: (offenseCodeChangeReason: string) => void) => {
        dispatch(
            // @ts-expect-error client-common to client RND-7529
            offenseCodeChangeReasonForm.actionCreators.submit(
                (formModel: { offenseCodeChangeReason: string }) => {
                    callback(formModel.offenseCodeChangeReason);
                    // @ts-expect-error client-common to client RND-7529
                    dispatch(offenseCodeChangeReasonForm.actionCreators.reset());
                    dispatch(closeBox(context));
                }
            )
        ).catch(() => {
            dispatch(saveBoxHalt(context));
        });
    },
});
export default compose<OffenseCodeChangeReasonModalPropsT, OffenseCodeChangeReasonModalPropsT>(
    connect(null, mapDispatchToProps)
)(OffenseCodeChangeReasonModal);

import React from 'react';
import classNames from 'classnames';
import type { ClassValue } from 'classnames/types';

import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../../core/testIds';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import OverlayButton, { buttonTypes } from '../../../../core/overlays/components/OverlayButton';
import RecordsHeaderButton from './RecordsHeaderButton';

const strings = componentStrings.core.header.RecordsHeaderPermissionsButton;

interface RecordsHeaderPermissionsButtonProps {
    className?: ClassValue;
    overlayId: string;
}

/**
 * Button in the report/warrants header for opening the permissions modal.
 * This button always appears; since the user has read permission,
 * they're able to at least view its permissions.
 */
const RecordsHeaderPermissionsButton: React.FC<RecordsHeaderPermissionsButtonProps> = (props) => {
    const { className, overlayId } = props;

    return (
        <RecordsHeaderButton
            overlay={strings.tooltip}
            testId={testIds.REPORT_HEADER_MANAGE_PERMISSIONS_BUTTON}
        >
            <OverlayButton id={overlayId} className={classNames(buttonTypes.ICON, className)}>
                <Icon size={18} type={iconTypes.PERMISSIONS} />
            </OverlayButton>
        </RecordsHeaderButton>
    );
};

export default RecordsHeaderPermissionsButton;

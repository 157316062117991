import { createSelector } from 'reselect';
import { get, filter, isArray, some, compact, map } from 'lodash';

import { EntityTypeEnum, LinkTypesEnum, LinkTypesEnumType } from '@mark43/rms-api';

import { dateOfBirthToAge } from '~/client-common/core/dates/utils/dateHelpers';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { personProfileByIdSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { offenseByIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { organizationProfileByIdSelector } from '~/client-common/core/domain/organization-profiles/state/data';

import { RootState } from '../../../legacy-redux/reducers/rootReducer';

const validValueTypes = {
    BOOLEAN: 'BOOLEAN',
    STRING: 'STRING',
    NUMBER: 'NUMBER',
    NULL: 'NULL',
};
const strictComparison = (
    a: boolean | string | number | null,
    b: boolean | string | number | null
) => a === b;

const lessThanComparison = (
    a: boolean | string | number | null,
    b: boolean | string | number | null
) => typeof a === 'number' && typeof b === 'number' && a < b;

type GetCountNameReportLinksInOffenseWhereProps = {
    linkType: keyof typeof LinkTypesEnum;
    path: string;
    value: string;
    valueType: keyof typeof validValueTypes;
    comparisonType: 'LESS_THAN' | undefined;
    valueProcessing: 'AGE_FOR_OFFENSE_DATE' | undefined;
};

const nameProfilesSelector = createSelector(
    nameReportLinksWhereSelector,
    personProfileByIdSelector,
    organizationProfileByIdSelector,
    (nameReportLinksWhere, personProfileById, organizationProfileById) => (
        linkTypeId: LinkTypesEnumType
    ) => {
        const personReportLinks = nameReportLinksWhere({
            linkType: linkTypeId,
            entityType: EntityTypeEnum.PERSON_PROFILE.name,
        });
        const organizationReportLinks = nameReportLinksWhere({
            linkType: linkTypeId,
            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
        });

        const nameProfiles = compact([
            ...map(personReportLinks, ({ nameId }) => personProfileById(nameId)),
            ...map(organizationReportLinks, ({ nameId }) => organizationProfileById(nameId)),
        ]);

        return nameProfiles;
    }
);
/**
 * This ruleFieldGetter will look for occurrences of a given linkType which have a certain value on the associated
 * property of a personProfile at the provided path.
 *
 * @param linkType      target type of link for a personProfile in an offense
 * @param path          path to target property of a personProfile
 * @param value         string representing value desired for target property
 * @param valueType     string representing type of target property
 * @param comparisonType type of comparison
 * @param valueProcessing instruction to process value additionally before comparing
 * @returns {number}    count of matching occurrences
 */
export const getCountNameReportLinksInOffenseWhere = (getState: () => RootState) => (
    contextValue: string,
    {
        linkType,
        path,
        value,
        valueType,
        comparisonType,
        valueProcessing,
    }: GetCountNameReportLinksInOffenseWhereProps
) => {
    const { STRING, NUMBER, BOOLEAN, NULL } = validValueTypes;
    const castValue =
        valueType === STRING
            ? value
            : valueType === NUMBER
            ? Number(value)
            : valueType === BOOLEAN
            ? value.trim().toLowerCase() === 'true'
            : null;

    if (
        (castValue === null && valueType !== NULL) ||
        (valueType === NUMBER && typeof castValue !== 'number')
    ) {
        throw new Error(
            `Invalid value type or value specified for rule, value type - ${valueType}, value - ${castValue}`
        );
    }
    const comparator = getComparator(comparisonType, castValue);

    const linkTypeId = LinkTypesEnum[linkType];
    if (isUndefinedOrNull(linkTypeId)) {
        return 0;
    }

    const state = getState();
    const nameProfiles = nameProfilesSelector(state)(linkTypeId);

    const result = filter(nameProfiles, (nameProfile) => {
        const targetField: typeof castValue | typeof castValue[] = get(nameProfile, path);
        if (targetField && isArray(targetField)) {
            return some(targetField, (tf) =>
                comparator(processValue(tf, valueProcessing, state, contextValue), castValue)
            );
        }
        return comparator(
            processValue(targetField, valueProcessing, state, contextValue),
            castValue
        );
    });

    return result.length;
};

const processValue = (
    value: string | number | boolean | null,
    valueProcessing: string | undefined,
    state: RootState,
    id: string
) => {
    switch (valueProcessing) {
        case 'AGE_FOR_OFFENSE_DATE': {
            return (
                typeof value === 'string' &&
                dateOfBirthToAge(value, getDateToCalculateAge(state, id))
            );
        }
        default:
            return value;
    }
};

const getDateToCalculateAge = (state: RootState, id: string) => {
    const offense = offenseByIdSelector(state)(id);
    const currentReportId = offense?.reportId;
    if (currentReportId) {
        const eventDetail = eventDetailByReportIdSelector(state)(currentReportId);
        return offense?.offenseDateUtc || eventDetail?.eventStartUtc || eventDetail?.createdDateUtc;
    }
    return offense?.offenseDateUtc || offense?.createdDateUtc;
};

const getComparator = (
    comparisonType: string | undefined,
    castValue: string | number | boolean | null
) => {
    switch (comparisonType) {
        case 'LESS_THAN':
            return lessThanComparison;
        default:
            return castValue === null ? isUndefinedOrNull : strictComparison;
    }
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';
import styled from 'styled-components';
import { map } from 'lodash';
import { TableRow as _Row, TableCell as Cell } from 'components-mark43';
import ConnectedFormattedPropertyStatusQuantityForItem from '~/client-common/core/domain/property-statuses/components/ConnectedFormattedPropertyStatusQuantityForItem';
import FormattedPropertyStatusDeclaredValue from '~/client-common/core/domain/property-statuses/components/FormattedPropertyStatusDeclaredValue';
import { FormattedPropertyStatusMarijuanaTypeQuantity } from '~/client-common/core/domain/property-statuses/components/FormattedPropertyStatusMarijuanaTypeQuantity';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import FormattedAttribute from '~/client-common/core/domain/attributes/components/FormattedAttribute';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import { formatTitleForVehicleSelector } from '~/client-common/core/domain/vehicles/state/ui';

import { formatTitleForPropertyRecord } from '../../../../../legacy-redux/helpers/propertyRecordHelpers';
import { itemSummaryViewModelsForCurrentReportSelector } from '../../state/ui';

const ItemSummaryList = styled.div`
    clear: both;
`;

const Row = styled(_Row)`
    flex: 1;
    word-break: break-all;
`;

const BodyRow = styled(Row)`
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-sm);
    padding: 10px 0;
`;
const Title = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

function PropertyStatusNameDateValue({ propertyStatus }) {
    const { propertyStatusAttrId, statusDateUtc } = propertyStatus;
    return (
        <div>
            <ConnectedFormattedAttribute attributeId={propertyStatusAttrId} />
            {' - '}
            <FormattedDate date={statusDateUtc} format={FormattedDate.FORMATS.SUMMARY_DATE} />
            <FormattedPropertyStatusDeclaredValue propertyStatus={propertyStatus}>
                {(formattedDeclaredValue) =>
                    !!formattedDeclaredValue ? (
                        <span>
                            {' - '}
                            {formattedDeclaredValue}
                        </span>
                    ) : null
                }
            </FormattedPropertyStatusDeclaredValue>
            <FormattedPropertyStatusMarijuanaTypeQuantity propertyStatus={propertyStatus} />
        </div>
    );
}

const ItemSummaryRow = connect(
    createStructuredSelector({
        formatTitleForVehicle: formatTitleForVehicleSelector,
    })
)(function ItemSummaryRow({ data, isVehicle, formatTitleForVehicle }) {
    const title = isVehicle
        ? formatTitleForVehicle(data)
        : formatTitleForPropertyRecord(data, false);

    return (
        <BodyRow>
            <Row style={{ margin: '5px 0 10px 0' }}>
                <Cell>
                    <Title>{title}</Title>
                    {!isVehicle && (
                        <div>
                            <ConnectedFormattedAttribute
                                attributeId={data.itemTypeAttrId}
                                format={FormattedAttribute.FORMATS.ITEM_TYPE}
                            />
                            <ConnectedFormattedPropertyStatusQuantityForItem
                                itemProfileId={data.id}
                                children={(quantity) =>
                                    !!quantity && (
                                        <span>
                                            {' ('}
                                            {quantity}
                                            {')'}
                                        </span>
                                    )
                                }
                            />
                        </div>
                    )}
                    {map(data.propertyStatus, (propertyStatus) => (
                        <PropertyStatusNameDateValue
                            propertyStatus={propertyStatus}
                            key={propertyStatus.id}
                        />
                    ))}
                </Cell>
            </Row>
        </BodyRow>
    );
});

function ItemSectionsInOffenseSummaryList({ itemProfiles, isVehicle }) {
    return (
        <ItemSummaryList>
            {map(itemProfiles, (itemProfile) => (
                <ItemSummaryRow data={itemProfile} isVehicle={isVehicle} key={itemProfile.id} />
            ))}
        </ItemSummaryList>
    );
}

const mapStateToProps = createStructuredSelector({
    itemSummaryViewModelsForCurrentReport: itemSummaryViewModelsForCurrentReportSelector,
});

export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['itemSummaryViewModelsForCurrentReport', 'offenseId', 'isVehicle'],
        ({ itemSummaryViewModelsForCurrentReport, offenseId, isVehicle }) => ({
            itemProfiles: itemSummaryViewModelsForCurrentReport(isVehicle, offenseId),
        })
    )
)(ItemSectionsInOffenseSummaryList);

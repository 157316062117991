import { storeCadServiceTypes } from '~/client-common/core/domain/cad-service-rotation-types/state/data';
import cadServiceTypeResource from '../../resources/cadServiceTypeResource';
import { departmentProfileSelector } from '../../../../../legacy-redux/selectors/departmentProfileSelectors';

export const LOAD_CAD_SERVICE_TYPES_START =
    'cad-service-rotation-types/LOAD_CAD_SERVICE_TYPES_START';
export const LOAD_CAD_SERVICE_TYPES_SUCCESS =
    'cad-service-rotation-types/LOAD_CAD_SERVICE_TYPES_SUCCESS';
export const LOAD_CAD_SERVICE_TYPES_FAILURE =
    'cad-service-rotation-types/LOAD_CAD_SERVICE_TYPES_FAILURE';
export const SAVE_CAD_SERVICE_TYPE_START = 'cad-service-rotation-types/SAVE_CAD_SERVICE_TYPE_START';
export const SAVE_CAD_SERVICE_TYPE_SUCCESS =
    'cad-service-rotation-types/SAVE_CAD_SERVICE_TYPE_SUCCESS';
export const SAVE_CAD_SERVICE_TYPE_FAILURE =
    'cad-service-rotation-types/SAVE_CAD_SERVICE_TYPE_FAILURE';
const FETCH_CAD_SERVICE_TYPE_HISTORY_START =
    'cad-service-rotation-types/FETCH_CAD_SERVICE_TYPE_HISTORY_START';
export const FETCH_CAD_SERVICE_TYPE_HISTORY_SUCCESS =
    'cad-service-rotation-types/FETCH_CAD_SERVICE_TYPE_HISTORY_SUCCESS';
const FETCH_CAD_SERVICE_TYPE_HISTORY_FAILURE =
    'cad-service-rotation-types/FETCH_CAD_SERVICE_TYPE_HISTORY_FAILURE';
const FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_START =
    'cad-service-rotation-types/FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_START';
export const FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_SUCCESS =
    'cad-service-rotation-types/FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_SUCCESS';
const FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_FAILURE =
    'cad-service-rotation-types/FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_FAILURE';
const SET_CAD_SERVICE_TYPE_SHAPEFILE_ID =
    'cad-service-rotation-types/SET_CAD_SERVICE_TYPE_SHAPEFILE_ID';

function loadCadServiceTypesStart() {
    return {
        type: LOAD_CAD_SERVICE_TYPES_START,
    };
}

function loadCadServiceTypesSuccess(serviceTypes) {
    return {
        type: LOAD_CAD_SERVICE_TYPES_SUCCESS,
        payload: serviceTypes,
    };
}

function loadCadServiceTypesFailure(errMsg) {
    return {
        type: LOAD_CAD_SERVICE_TYPES_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function fetchCadServiceTypeHistoryStart(serviceTypeId) {
    return {
        type: FETCH_CAD_SERVICE_TYPE_HISTORY_START,
        payload: serviceTypeId,
    };
}

function fetchCadServiceTypeHistorySuccess(serviceTypeId, histories) {
    return {
        type: FETCH_CAD_SERVICE_TYPE_HISTORY_SUCCESS,
        payload: { serviceTypeId, histories },
    };
}

function fetchCadServiceTypeHistoryFailure(serviceTypeId, err) {
    return {
        type: FETCH_CAD_SERVICE_TYPE_HISTORY_FAILURE,
        payload: { serviceTypeId, err },
    };
}

function fetchAllCadServiceTypesHistoryStart() {
    return {
        type: FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_START,
    };
}

function fetchAllCadServiceTypesHistorySuccess(histories) {
    return {
        type: FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_SUCCESS,
        payload: { histories },
    };
}

function fetchAllCadServiceTypesHistoryFailure(err) {
    return {
        type: FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_FAILURE,
        payload: { err },
    };
}

export function setCadServiceTypeShapefileId(id) {
    return {
        type: SET_CAD_SERVICE_TYPE_SHAPEFILE_ID,
        payload: { id },
    };
}

export function loadCadServiceTypes() {
    return function (dispatch) {
        dispatch(loadCadServiceTypesStart());

        return cadServiceTypeResource
            .getCadServiceTypes()
            .then((serviceTypes) => {
                dispatch(storeCadServiceTypes(serviceTypes));
                dispatch(loadCadServiceTypesSuccess(serviceTypes));
            })
            .catch((err) => dispatch(loadCadServiceTypesFailure(err.message)));
    };
}

export function loadCadServiceTypeHistoryData(serviceTypeId) {
    return function (dispatch) {
        dispatch(fetchCadServiceTypeHistoryStart(serviceTypeId));
        return cadServiceTypeResource
            .getCadServiceTypeHistory(serviceTypeId)
            .then((histories) => {
                dispatch(fetchCadServiceTypeHistorySuccess(serviceTypeId, histories));
            })
            .catch((err) => {
                dispatch(fetchCadServiceTypeHistoryFailure(serviceTypeId, err));
            });
    };
}

export function loadAllCadServiceTypesHistoryData() {
    return function (dispatch) {
        dispatch(fetchAllCadServiceTypesHistoryStart());
        return cadServiceTypeResource
            .getAllCadServiceTypesHistory()
            .then((histories) => {
                dispatch(fetchAllCadServiceTypesHistorySuccess(histories));
            })
            .catch((err) => {
                dispatch(fetchAllCadServiceTypesHistoryFailure(err));
            });
    };
}

function saveCadServiceTypeStart() {
    return {
        type: SAVE_CAD_SERVICE_TYPE_START,
    };
}

function saveCadServiceTypeSuccess(cadServiceType) {
    return {
        type: SAVE_CAD_SERVICE_TYPE_SUCCESS,
        payload: cadServiceType,
    };
}

function saveCadServiceTypeFailure(errMsg) {
    return {
        type: SAVE_CAD_SERVICE_TYPE_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveCadServiceType({ cadServiceType }) {
    return function (dispatch, getState) {
        dispatch(saveCadServiceTypeStart());

        const method = 'createCadServiceType';

        const { departmentId } = departmentProfileSelector(getState());

        return cadServiceTypeResource[method]({ ...cadServiceType, departmentId })
            .then((serviceType) => {
                dispatch(storeCadServiceTypes([serviceType]));
                return dispatch(saveCadServiceTypeSuccess(serviceType));
            })
            .catch((err) => dispatch(saveCadServiceTypeFailure(err.message)));
    };
}

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';

import { useSelector } from 'react-redux';
import { idFormatConfigurationsForEntityTypeSelector } from '~/client-common/core/domain/id-format-configurations/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { RRFSelect } from '../../../core/forms/components/selects/Select';

const strings = componentStrings.warrants.warrant.WarrantNumberConfigurationSelect;

const WarrantNumberConfigurationSelect: React.FC<{
    path: string;
    width?: number;
}> = ({ path, width }) => {
    const idFormatConfigurationsForEntityType = useSelector(
        idFormatConfigurationsForEntityTypeSelector
    );

    const warrantIdConfigurationOptions = idFormatConfigurationsForEntityType(
        EntityTypeEnum.WARRANT.name
    ).map(({ id, name }) => ({
        value: id,
        display: name || strings.unnamed,
    }));
    return <RRFSelect options={warrantIdConfigurationOptions} path={path} width={width} />;
};

export default WarrantNumberConfigurationSelect;

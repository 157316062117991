import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';

import { ComplianceResubmissionStatusView } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';

import { useFormGetter } from '../../core/forms/hooks/useFormGetter';
import errorToMessage from '../../core/errors/utils/errorToMessage';

import { useComplianceExportContext } from '../contexts/ComplianceExportContext';
import { ErrorSummary, FetchErrorsSuccessType, LoadingState, NibrsErrorCounts } from '../types';
import nibrsExportResource from '../resources/nibrsExportResource';
import useGenerateErrors from './useGenerateErrors';

const errors = componentStrings.compliance.ComplianceResubmissionForm.errors;

type ResubmissionReportsAndErrorsResult = {
    agencyName?: string;
    generateReportsAndErrors: () => void;
    errorSummary?: ErrorSummary;
    errorsLoading: LoadingState;
    nibrsErrorCounts?: NibrsErrorCounts;
    resetErrors: () => void;
    onFetchErrorsSuccess: FetchErrorsSuccessType;
    reports: ComplianceResubmissionStatusView[];
    reportsLoading: LoadingState;
};

export function useGenerateResbumissionReportsAndErrors(): ResubmissionReportsAndErrorsResult {
    const [reports, setReports] = useState<ComplianceResubmissionStatusView[]>([]);
    const [loadingReports, setLoadingReports] = useState(false);
    const [reportsErrorMessage, setReportsErrorMessage] = useState('');
    const { currentAgencyId } = useComplianceExportContext();
    const {
        agencyName,
        callResource: callGenerateErrorsResource,
        errorSummary,
        loading: generateErrorsLoadingState,
        nibrsErrorCounts,
        onFetchErrorsSuccess,
        resetErrors,
    } = useGenerateErrors();
    const { getForm } = useFormGetter();
    const applicationSettings = useSelector(applicationSettingsSelector);

    const generateReportsAndErrors = useCallback(() => {
        const form = getForm(formClientEnum.COMPLIANCE_RESUBMISSION);
        form?.submit()
            .then(async ({ form }) => {
                if (applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED) {
                    form.set('agencyId', currentAgencyId);
                }
                const formModel = form.getState().model;
                const agencyId = applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED
                    ? currentAgencyId
                    : formModel.agencyId;

                callGenerateErrorsResource({
                    agencyId,
                    resubmission: true,
                    // @ts-expect-error `exportDateUtc` is only in front end, double check that this is unused before deleting it
                    exportDateUtc: nowUtc(),
                    // NibrsExportQuery type requires totalUpdatedReportSize
                    // but the client payload value is not used, instead the server sets it based on query
                    totalUpdatedReportSize: 0,
                });

                try {
                    setLoadingReports(true);

                    const reportStatuses = await nibrsExportResource.getAllComplianceResubmission(
                        agencyId || 0
                    );
                    setReports(reportStatuses);
                } catch (error) {
                    setReportsErrorMessage(errorToMessage(error, errors.reportsError));
                } finally {
                    setLoadingReports(false);
                }
            })
            // no validation messages, required errors are displayed inline on inputs
            .catch(noop);
    }, [
        getForm,
        applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED,
        currentAgencyId,
        callGenerateErrorsResource,
    ]);

    return {
        agencyName,

        // click handler
        generateReportsAndErrors,

        // errors
        errorSummary,
        errorsLoading: generateErrorsLoadingState,
        nibrsErrorCounts,
        resetErrors,
        onFetchErrorsSuccess,

        // reports
        reports,
        reportsLoading: {
            isLoading: loadingReports,
            errorMessage: reportsErrorMessage,
        },
    };
}

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';

import { includes, map, compact, filter, parseInt } from 'lodash';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { personProfilesSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { organizationProfilesSelector } from '~/client-common/core/domain/organization-profiles/state/data';

export const offenseVictimProfileGetter = (getState) => (offenseId, entityTypes = undefined) => {
    const state = getState();
    const victimLinks = nameReportLinksWhereSelector(state)({
        contextId: parseInt(offenseId),
        contextType: EntityTypeEnum.OFFENSE.name,
        linkType: LinkTypesEnum.VICTIM_IN_OFFENSE,
    });
    const personProfiles = personProfilesSelector(state);
    const organizationProfiles = organizationProfilesSelector(state);
    return compact(
        map(
            entityTypes
                ? filter(victimLinks, (link) => includes(entityTypes, link.entityType))
                : victimLinks,
            (victimLink) => {
                const { entityType, nameId } = victimLink;
                switch (entityType) {
                    case EntityTypeEnum.ORGANIZATION_PROFILE.name:
                        return organizationProfiles[nameId];
                    case EntityTypeEnum.PERSON_PROFILE.name:
                        return personProfiles[nameId];
                    default:
                        return undefined;
                }
            }
        )
    );
};

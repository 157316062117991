import { EntityTypeEnum } from '@mark43/rms-api';
import { get, concat } from 'lodash';

import { createSelector } from 'reselect';
import { hydratedArrestByReportIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { legacyEntityDetailsWhereSelector } from '~/client-common/core/domain/legacy-entity-details/state/data';

import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';
import getArrestResource from '~/client-common/core/domain/arrests/resources/arrestResource';
import {
    arrestByIdSelector,
    NEXUS_STATE_PROP as ARRESTS_NEXUS_STATE_PROP,
    UPDATE_ARREST_DEFENDANT_ID,
} from '~/client-common/core/domain/arrests/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    getArrestForm,
    ARREST_FORM_ARREST_ID_PATH,
    ARREST_FORM_DEFENDANT_ID_PATH,
} from '../forms/arrestForm';
import arrestCard from './arrestCard';

const strings = componentStrings.reports.core.ArrestCard;

export const hydratedArrestForArrestCardByReportIdSelector = createSelector(
    hydratedArrestByReportIdSelector,
    legacyEntityDetailsWhereSelector,
    itemProfilesInReportSelector,
    (hydratedArrestByReportId, legacyEntityDetailsWhere, itemProfilesByReportId) => (reportId) => {
        const {
            arrest,
            arrestAttributes,
            defendantAttachments,
            warrants,
            defendantRemarks,
        } = hydratedArrestByReportId(reportId);
        return {
            arrest,
            arrestAttributes,
            defendantAttachments,
            warrants,
            legacyEntityDetails: legacyEntityDetailsWhere({
                entityType: EntityTypeEnum.ARREST.name,
                entityId: get(arrest, 'id'),
            }),
            itemProfilesInReport: itemProfilesByReportId(reportId),
            defendantRemarks,
        };
    }
);

export function updateArrestDefendant(arrestId, defendantId, index) {
    return (dispatch, getState, { nexus: { withEntityItems } }) => {
        const arrestForm = getArrestForm(index);
        const resource = getArrestResource();
        return resource
            .updateArrestDefendant(arrestId, defendantId)
            .then((updatedArrest) => {
                // updatedDefendantId will always be same as param, but we pull it anyway so we're always consistent
                const { defendantId: updatedDefendantId } = updatedArrest;
                const currentArrest = arrestByIdSelector(getState())(arrestId);
                const newArrest = {
                    ...currentArrest,
                    defendantId: updatedDefendantId,
                };
                arrestForm.set(ARREST_FORM_DEFENDANT_ID_PATH, defendantId);
                dispatch(
                    withEntityItems(
                        {
                            [ARRESTS_NEXUS_STATE_PROP]: [newArrest],
                        },
                        {
                            type: UPDATE_ARREST_DEFENDANT_ID,
                        }
                    )
                );
                return newArrest;
            })
            .catch(() => {
                const fieldName = formatFieldByNameSelector(getState())(fields.ARREST_DEFENDANT_ID);
                const defendantUpdateError = strings.defendantUpdateErrorMessage(
                    defendantId,
                    fieldName
                );
                const currentCardErrorMessages =
                    arrestCard.selectors.errorMessagesSelector(getState()) || [];
                const allErrorMessages = concat([], currentCardErrorMessages, [
                    defendantUpdateError,
                ]);
                dispatch(arrestCard.actionCreators.setErrorMessages(allErrorMessages));
                arrestCard.scrollToTop();
            });
    };
}

/**
 * Handle desired UI form effects when a new defendant is chosen
 * @param {Number} sidePanelSavedDefendantId  Saved defendant side panel person profile id
 */
export const handleAddDefendantSidePanelSave = (sidePanelSavedDefendantId, index) => {
    return (dispatch) => {
        const formModel = getArrestForm(index).get();
        const defendantId = get(formModel, ARREST_FORM_DEFENDANT_ID_PATH);
        if (sidePanelSavedDefendantId === defendantId) {
            return;
        }

        const arrestId = get(formModel, ARREST_FORM_ARREST_ID_PATH);
        dispatch(updateArrestDefendant(arrestId, sidePanelSavedDefendantId, index));
    };
};

export const handleRemoveDefendant = (index) => {
    return (dispatch) => {
        const formModel = getArrestForm(index).get();
        const arrestId = get(formModel, ARREST_FORM_ARREST_ID_PATH);
        return dispatch(updateArrestDefendant(arrestId, undefined, index));
    };
};

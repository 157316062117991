import { RefContextEnum } from '@mark43/rms-api';
import { get, map, omit } from 'lodash';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import { otherUserId } from '~/client-common/helpers/userHelpers';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';
import { ruleRunnerConfigurationWithTemplateStringErrorsSelector } from '../../../../core/forms/lib/createReportFormModule';

const { ATTRIBUTE, DATE, FIELDSET } = fieldTypeClientEnum;

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'id',
    },
    {
        key: 'warrantNumber',
    },
    {
        key: 'subjectPersonProfileId',
        fieldName: fields.WARRANT_SUBJECT_PERSON_PROFILE_ID,
    },
    {
        type: ATTRIBUTE,
        key: 'warrantStatusAttrId', // note: this value will populate on a WarrantStatus
        fieldName: fields.WARRANT_STATUS_WARRANT_STATUS_ATTR_ID,
    },
    {
        type: ATTRIBUTE,
        key: 'warrantTypeAttrId',
        fieldName: fields.WARRANT_WARRANT_TYPE_ATTR_ID,
    },
    {
        type: DATE,
        key: 'warrantIssuedDateUtc',
        fieldName: fields.WARRANT_WARRANT_ISSUED_DATE_UTC,
    },
    {
        type: DATE,
        key: 'warrantReceivedDateUtc',
        fieldName: fields.WARRANT_WARRANT_RECEIVED_DATE_UTC,
    },
    {
        key: 'obtainingOfficerUserId',
        fieldName: fields.WARRANT_OBTAINING_OFFICER_USER_ID,
    },
    {
        key: 'obtainingOfficerOther',
        fieldName: fields.WARRANT_OBTAINING_OFFICER_OTHER,
    },
    {
        key: 'isOtherJurisdiction',
        fieldName: fields.WARRANT_IS_OTHER_JURISDICTION,
    },
    {
        key: 'issuingAgencyOri',
        fieldName: fields.WARRANT_ISSUING_AGENCY_ORI,
    },
    {
        type: ATTRIBUTE,
        key: 'issuingAgencyNameAttrId',
        fieldName: fields.WARRANT_ISSUING_AGENCY_NAME_ATTR_ID,
    },
    {
        key: 'issuingAgencyNameOther',
        fieldName: fields.WARRANT_ISSUING_AGENCY_NAME_OTHER,
    },
    {
        key: 'hasNightService',
        fieldName: fields.WARRANT_HAS_NIGHT_SERVICE,
    },
    {
        key: 'daysToServe',
        fieldName: fields.WARRANT_DAYS_TO_SERVE,
    },
    {
        key: 'issuingJudge',
        fieldName: fields.WARRANT_ISSUING_JUDGE,
    },
    {
        key: 'bailAmount',
        fieldName: fields.WARRANT_BAIL_AMOUNT,
    },
    {
        key: 'courtCaseNumber',
        fieldName: fields.WARRANT_COURT_CASE_NUMBER,
    },
    {
        type: ATTRIBUTE,
        key: 'warrantStatisticAttrIds', // note: these values will populate attributeId on a WarrantAttribute
        fieldName: fields.WARRANT_ATTRIBUTE_ATTRIBUTE_TYPE_WARRANT_STATISTIC_ATTRIBUTE_ID,
    },
    {
        key: 'warrantNotes',
        fieldName: fields.WARRANT_WARRANT_NOTES,
    },
    {
        key: 'description',
        fieldName: fields.WARRANT_DESCRIPTION,
    },
    {
        key: 'warrantLocation',
        type: FIELDSET,
        fields: buildFlatFormFieldViewModels([
            {
                key: 'locationId',
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_WARRANT_LOCATION_ID,
            },
            {
                key: 'positionAttrId',
                fieldName:
                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_WARRANT_POSITION_ATTR_ID,
            },
            {
                key: 'description',
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_WARRANT_DESCRIPTION,
            },
        ]),
    },
    {
        key: 'courtName',
        fieldName: fields.WARRANT_COURT_NAME,
    },
    {
        key: 'courtAddress',
        fieldName: fields.WARRANT_COURT_ADDRESS,
    },
    {
        key: 'courtPhoneNumber',
        fieldName: fields.WARRANT_COURT_PHONE_NUMBER,
    },
    {
        key: 'issuingCourtOri',
        fieldName: fields.WARRANT_ISSUING_COURT_ORI,
    },
    {
        key: 'hasNoBail',
        fieldName: fields.WARRANT_HAS_NO_BAIL,
    },
    {
        key: 'licensePlateNumber',
        fieldName: fields.WARRANT_LICENSE_PLATE_NUMBER,
    },
    {
        key: 'licensePlateState',
        fieldName: fields.WARRANT_LICENSE_PLATE_STATE,
    },
    {
        key: 'vehicleColor',
        fieldName: fields.WARRANT_VEHICLE_COLOR,
    },
    {
        key: 'vehicleMake',
        fieldName: fields.WARRANT_VEHICLE_MAKE,
    },
    {
        key: 'vehicleModel',
        fieldName: fields.WARRANT_VEHICLE_MODEL,
    },
    {
        key: 'vehicleYear',
        fieldName: fields.WARRANT_VEHICLE_YEAR,
    },
]);

export default createFormModule({
    context: RefContextEnum.FORM_WARRANT_DETAILS.name,
    fieldViewModels,
    ruleRunnerConfigurationSelector: ruleRunnerConfigurationWithTemplateStringErrorsSelector,
    convertToFormModel: (
        warrantModel,
        warrantStatus,
        warrantStatisticAttributes,
        warrantLocation
    ) => {
        const { obtainingOfficerUserId, obtainingOfficerOther, isOtherJurisdiction } = warrantModel;
        return {
            ...warrantModel,
            // if obtainingOfficerOther exists with no obtainingOfficerUserId, then set to "isOther" id
            obtainingOfficerUserId:
                !obtainingOfficerUserId && obtainingOfficerOther
                    ? otherUserId
                    : obtainingOfficerUserId,
            warrantStatusAttrId: warrantStatus.warrantStatusAttrId,
            warrantStatisticAttrIds: map(warrantStatisticAttributes, 'attributeId'),
            isOtherJurisdiction: isOtherJurisdiction || false,
            warrantLocation,
        };
    },
    convertFromFormModel: (formModel) => {
        const {
            warrantStatusAttrId,
            warrantStatisticAttrIds,
            obtainingOfficerUserId,
            obtainingOfficerOther,
            warrantLocation,
        } = formModel;
        const warrantStatus = {
            warrantStatusAttrId,
            warrantId: formModel.id,
        };
        const warrantAttributes = map(warrantStatisticAttrIds, (attributeId) => ({
            attributeId,
            warrantId: formModel.id,
            attributeType: 'WARRANT_STATISTIC',
        }));
        const warrant = {
            ...omit(formModel, ['warrantStatusAttrId, warrantStatisticAttrIds']),
            // if is other officer, than null out obtainingOfficerUserId and fill out obtainingOfficerOther
            // else, null out obtainingOfficerOther and fill obtainingOfficerUserId
            obtainingOfficerUserId:
                obtainingOfficerUserId === otherUserId ? null : obtainingOfficerUserId,
            obtainingOfficerOther:
                obtainingOfficerUserId !== otherUserId ? null : obtainingOfficerOther,
        };
        const locationEntityLink = get(warrantLocation, 'locationId') ? warrantLocation : null;
        return { warrant, warrantStatus, warrantAttributes, locationEntityLink };
    },
});

import React from 'react';
import { useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useEvidenceModuleName } from '~/client-common/core/fields/hooks/useFields';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import EvidenceDashboardSubHeaders from './EvidenceDashboardSubHeaders';

const getModuleName = componentStrings.core.navigation.evidence;

/**
 * Route component for /evidence/.
 */
export default function Evidence({ children }) {
    const evidenceModuleName = useEvidenceModuleName();
    const evidenceTableRefreshEnabled = useSelector(applicationSettingsSelector)
        .RMS_EVIDENCE_DASHBOARD_REFRESH_ENABLED;
    return (
        <Page className="dashboard-subheader-container">
            <Subheader
                title={getModuleName(evidenceModuleName)}
                content={<EvidenceDashboardSubHeaders />}
                hasFlushContent
                fullscreen={evidenceTableRefreshEnabled}
            />
            {children}
        </Page>
    );
}

import { chain } from 'lodash';
import { RefContextEnum } from '@mark43/rms-api';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { replaceChargesCardBundle } from '~/client-common/core/domain/reports/state/ui/charges';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';
import {
    arrestForReportIdSelector,
    updateArrestNibrsCode,
} from '~/client-common/core/domain/arrests/state/data';
import {
    buildChargesCardBundle,
    refreshChargesForm,
    prefillChargesCardDependentArrestNibrsCodeField,
    computeDefaultNibrsOffenseCode,
} from '../forms/chargesForm';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.CHARGES.name,
    baseSelector: (state) => state.ui.report.chargesCard,
    anchor: 'charges-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit({ index }) {
            return (dispatch, getState) => {
                const state = getState();
                const reportId = baseCard.selectors.reportIdSelector(state, { index });
                const arrest = arrestForReportIdSelector(state)(reportId) || {};
                const { id: arrestId, nibrsCode } = arrest;

                if (isUndefinedOrNull(nibrsCode)) {
                    dispatch(
                        prefillChargesCardDependentArrestNibrsCodeField({
                            arrestId,
                            formIndex: index,
                        })
                    );
                }

                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        save(form, options = {}) {
            return (dispatch, getState) => {
                form.resetUi();
                const { index } = options;
                const state = getState();
                const applicationSettings = applicationSettingsSelector(state);
                const formatFieldByName = formatFieldByNameSelector(state);
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state, { index });
                const reportId = baseCard.selectors.reportIdSelector(state, { index });

                // Custom, unique saving logic here specific to the Charges card.
                // If the `NIBRS Code` on the form is `NULL`, then we *always* want
                // to persist down a computed, default `NIBRS Code` value to the DB,
                // even if the card is in Summary Mode.
                // This is because the field should really be "required", but there is
                // some trickiness there, in that editing the field is behind an ability.
                // Therefore, to psuedo-"force" requirement, we will try to always
                // default the value if `NULL`.
                const chargesCardBundle = dispatch(buildChargesCardBundle({ form }));
                const { arrestNibrsOffenseCode } = chargesCardBundle;

                if (applicationSettings.RMS_OFFENSE_CODE_WORDING) {
                    const formModel = form.get();
                    const { MADLIBS } = formModel;

                    const isValid = chain(MADLIBS)
                        .map('isValid')
                        .filter((item) => item !== undefined)
                        .every(Boolean)
                        .value();

                    if (!isValid) {
                        const offenseFieldName = formatFieldByName(DISPLAY_ONLY_OFFENSE);
                        const errorMessage = `Missing fields in the ${offenseFieldName.toLowerCase()} wording template`;

                        return dispatch(
                            baseCard.actionCreators.setErrorMessages(errorMessage, { index })
                        );
                    }
                }

                const additionalFormsToValidate = [
                    {
                        formName: RefContextEnum.FORM_ARREST_CHARGES_N_CHARGES_SIDE_PANEL.name,
                        reportId,
                    },
                ];

                const validateCardAction = validateCard({
                    card: baseCard,
                    formComponent: form,
                    options: {
                        index,
                        additionalFormsToValidate,
                    },
                });

                if (isInSummaryMode) {
                    // If the `NIBRS code` on the form is `NULL`, then persist down a default value.
                    if (isUndefinedOrNull(arrestNibrsOffenseCode)) {
                        const existingArrest = arrestForReportIdSelector(state)(reportId);
                        const { id: arrestId } = existingArrest;
                        const computedDefaultNibrsOffenseCode = dispatch(
                            computeDefaultNibrsOffenseCode({ arrestId })
                        );
                        const arrestForNibrsCodeUpdate = {
                            ...existingArrest,
                            nibrsCode: computedDefaultNibrsOffenseCode,
                        };

                        return dispatch(updateArrestNibrsCode(arrestForNibrsCodeUpdate))
                            .then(() => {
                                // if updating the arrest nibrs code was successful, then
                                // re-sync `formModel` state.
                                dispatch(refreshChargesForm({ reportId, index }));
                            })
                            .finally(() => {
                                // regardless of persisting failure or success, validate the card.
                                return dispatch(validateCardAction);
                            });
                    }
                    return dispatch(validateCardAction);
                } else {
                    // Only submit the card if its valid.
                    return dispatch(validateCardAction).then(() => {
                        return dispatch(
                            submitCard({
                                card: baseCard,
                                formComponent: form,
                                options: {
                                    index,
                                },
                                getPromisesForSubmission: () => {
                                    // If the `NIBRS code` on the form is `NULL`, then persist down a default value.
                                    if (isUndefinedOrNull(arrestNibrsOffenseCode)) {
                                        const existingArrest = arrestForReportIdSelector(state)(
                                            reportId
                                        );
                                        const { id: arrestId } = existingArrest;
                                        const defaultNibrsOffenseCode = dispatch(
                                            computeDefaultNibrsOffenseCode({ arrestId })
                                        );

                                        const chargesCardBundleWithDefaultNibrsOffenseCode = {
                                            ...chargesCardBundle,
                                            arrestNibrsOffenseCode: defaultNibrsOffenseCode,
                                        };

                                        return [
                                            dispatch(
                                                replaceChargesCardBundle({
                                                    reportId,
                                                    chargesCardBundle: chargesCardBundleWithDefaultNibrsOffenseCode,
                                                })
                                            ),
                                        ];
                                    }
                                    return [
                                        dispatch(
                                            replaceChargesCardBundle({
                                                reportId,
                                                chargesCardBundle,
                                            })
                                        ),
                                    ];
                                },
                                onSavingSuccess: () => {
                                    // Always re-sync `formModel` state.
                                    dispatch(refreshChargesForm({ reportId, index }));
                                },
                            })
                        );
                    });
                }
            };
        },
    },
};

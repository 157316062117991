import React from 'react';

import { useSelector } from 'react-redux';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
    EntityProfileSubdetailSummaryList,
} from '../../core/components/EntityProfileSection';
import EntityProfileSectionNoData from '../../core/components/EntityProfileSectionNoData';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';

// helpers
import { convertIdentifiersToComponentArray } from '../../core/helpers/identifierHelpers';
import {
    convertPhoneNumbersToComponentArray,
    convertEmailAddressesToComponentArray,
} from '../../core/helpers/contactHelpers';

const strings = componentStrings.entityProfiles.organization.IdentificationDetails;

const IdentificationDetails = ({ orgProfileDetails }) => {
    const display = getViewModelProperties(orgProfileDetails);
    const identifierRows = convertIdentifiersToComponentArray(
        display.identifiers,
        'nameIdentifierType'
    );
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isPoleEnabled = applicationSettings['RMS_POLE_DATA_CAPTURE_ENABLED'];

    const phoneRows = convertPhoneNumbersToComponentArray(display.phoneNumbers, isPoleEnabled);
    const emailRows = convertEmailAddressesToComponentArray(display.emails, isPoleEnabled);
    return (
        <div>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    <SummaryRow label={strings.labels.type}>
                        {display.organizationTypeAttrId}
                    </SummaryRow>
                    <SummaryRow label={strings.labels.industry}>
                        {display.industryAttrId}
                    </SummaryRow>
                    {!!phoneRows.length && (
                        <SummaryRow label={strings.labels.phone}>
                            <EntityProfileSubdetailSummaryList>
                                {phoneRows}
                            </EntityProfileSubdetailSummaryList>
                        </SummaryRow>
                    )}
                    {!!emailRows.length && (
                        <SummaryRow label={strings.labels.email}>
                            <EntityProfileSubdetailSummaryList>
                                {emailRows}
                            </EntityProfileSubdetailSummaryList>
                        </SummaryRow>
                    )}
                    {identifierRows}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </div>
    );
};

const IdentificationDetailsBranch = renderOnlyIf(({ orgProfileDetails }) => {
    const display = getViewModelProperties(orgProfileDetails);
    return (
        (display.identifiers && display.identifiers.length) ||
        display.organizationTypeAttrId ||
        display.industryAttrId ||
        (display.phoneNumbers && display.phoneNumbers.length) ||
        (display.emails && display.emails.length)
    );
}, EntityProfileSectionNoData)(IdentificationDetails);

export default function IdentificationDetailsWrapper(props) {
    return (
        <EntityProfileSection title={strings.title}>
            <IdentificationDetailsBranch {...props} />
        </EntityProfileSection>
    );
}

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import styled from 'styled-components';

import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';

import { currentReportCardUITitleByTypeSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { sortedOffensesAndIncidentsForReportIdSelector } from '../state/ui';
import { useOffenseURN } from '../hooks/useOffenseURN';
import OffenseIncidentReorderDropdown from './OffenseIncidentReorderDropdown';

const mapStateToProps = createStructuredSelector({
    formatOffenseCodeById: formatOffenseCodeByIdSelector,
    sortedOffensesAndIncidentsForReportId: sortedOffensesAndIncidentsForReportIdSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const TitleContainer = styled.div`
    display: flex;
`;

export const OffenseIncidentTitle = connect(mapStateToProps)(
    ({
        card = {},
        isClientSideStub,
        offense,
        maxOffenseNumber,
        onReorder,
        formatOffenseCodeById,
        buildCardTitle,
        hideOffenseCode,
        sortedOffensesAndIncidentsForReportId,
        offenseReportId,
        cardId,
        currentReportCardUITitleByType,
    }) => {
        const offenseURN = useOffenseURN(offense);
        const formattedOffenseCode = hideOffenseCode
            ? null
            : offenseURN ||
              formatOffenseCodeById({ id: offense.offenseCodeId, includeCode: false });
        const titlePrefix = currentReportCardUITitleByType(cardId);
        const title = buildCardTitle({
            order: offense.offenseOrder,
            formattedOffenseCode,
            titlePrefix,
        });
        const { offenses } = sortedOffensesAndIncidentsForReportId(offenseReportId);

        return isClientSideStub || card.summaryMode ? (
            <span>{title}</span>
        ) : (
            <TitleContainer>
                {onReorder && offenses.length > 1 && (
                    <OffenseIncidentReorderDropdown
                        offense={offense}
                        maxOrder={maxOffenseNumber}
                        onReorder={onReorder}
                    />
                )}
                {title}
            </TitleContainer>
        );
    }
);

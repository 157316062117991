import React, { useCallback } from 'react';
import { Button, useToast, cssVar } from 'arc';
import { Mark43File } from '@mark43/rms-api';
import invariant from 'invariant';
import {
    simpleControl,
    SimpleControlInjectedProps,
    Observer,
    MFTFormConfiguration,
} from 'markformythree';
import styled from 'styled-components';
import { FieldUiOptions } from 'dragon-react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { RMSDragonConfigurationExtensions } from '../../../../../rms-types';
import { getCrashDiagramModalOverlayId } from '../helpers';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { CRASH_DIAGRAM_ASSET_KEY, useAssetCache } from '../context/AssetCacheContext';
import { useOverlayStore } from '../../../../../../core/overlays/hooks/useOverlayStore';
import { Wrapper } from './shared';
import { CrashDiagramModal } from './CrashDiagramModal';
import { CrashDiagramImage } from './CrashDiagramImage';

type BaseCrashDiagramCustomComponentPropsT = Pick<
    FieldUiOptions<RMSDragonConfigurationExtensions>,
    'fullyQualifiedPath'
> &
    SimpleControlInjectedProps<number>;

type DragonCrashDiagramCustomComponentPropsT = {
    identifier: string;
} & BaseCrashDiagramCustomComponentPropsT;

const StyledButton = styled(Button)`
    margin: ${cssVar('arc.space.2')} 0;
    width: 100%;
    height: 60px;
`;

const crashDiagramStrings = componentStrings.dragon.crashDiagram.CrashDiagramFormField;

const CrashDiagramInput: React.FC<DragonCrashDiagramCustomComponentPropsT> = ({
    identifier,
    onChange,
    fullyQualifiedPath,
}) => {
    const toast = useToast();
    const { setCrashDiagramFromSvg } = useCrashDiagram();
    const { getAsset, removeAsset } = useAssetCache();
    const overlayStore = useOverlayStore();
    const overlayId = getCrashDiagramModalOverlayId(identifier);

    const openCrashDiagramModal = useCallback(() => {
        overlayStore.open(overlayId);
    }, [overlayId, overlayStore]);

    const setFormValueAndInvalidateAssetCache = useCallback(
        (fileId?: number) => {
            // Invalidate the cache for the Crash Diagram Image
            removeAsset({ key: CRASH_DIAGRAM_ASSET_KEY, type: 'SVG' });
            removeAsset({ key: CRASH_DIAGRAM_ASSET_KEY, type: 'SVG_DATA_URL' });

            // Set the value in the MFT Form model for this form field.
            onChange(fileId);
        },
        [onChange, removeAsset]
    );

    const onSave = useCallback(
        (file: Mark43File) => {
            toast({
                status: 'default',
                description: crashDiagramStrings.successToastMessage,
                duration: 2500,
            });
            setFormValueAndInvalidateAssetCache(file.id);
        },
        [setFormValueAndInvalidateAssetCache, toast]
    );

    const onEdit = useCallback(() => {
        const crashDiagramSvgString = getAsset({ key: CRASH_DIAGRAM_ASSET_KEY, type: 'SVG' });
        invariant(
            !!crashDiagramSvgString,
            'Failed to get crash diagram svg contents from asset cache'
        );
        setCrashDiagramFromSvg(crashDiagramSvgString);
        openCrashDiagramModal();
    }, [setCrashDiagramFromSvg, openCrashDiagramModal, getAsset]);

    const onDelete = useCallback(() => {
        setFormValueAndInvalidateAssetCache(undefined);
    }, [setFormValueAndInvalidateAssetCache]);

    return (
        <Wrapper>
            <Observer<{ fileId?: number }, MFTFormConfiguration>
                subscriptions={{
                    fileId: fullyQualifiedPath,
                }}
                render={({ fileId }) => {
                    return !fileId ? (
                        <StyledButton
                            leadingVisual="Signature"
                            variant="outline"
                            onClick={openCrashDiagramModal}
                        >
                            {crashDiagramStrings.captureButtonText}
                        </StyledButton>
                    ) : (
                        <CrashDiagramImage
                            fileId={fileId}
                            mode="EDIT"
                            onEdit={onEdit}
                            onDelete={onDelete}
                        />
                    );
                }}
            />
            <CrashDiagramModal overlayId={overlayId} identifier={identifier} onSave={onSave} />
        </Wrapper>
    );
};
export const CrashDiagramFormField = simpleControl(CrashDiagramInput);

import { map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { omitProps } from '~/client-common/helpers/reactHelpers';
import routesConfig from '../../../../routing/routesConfig';
import Link from '../../../core/components/links/Link';
import { routeNameSelector } from '../../../../routing/routerModule';

const strings = componentStrings.notepad;

const pathParamToRouteName = {
    collections: routesConfig.MOBILE_COLLECTIONS.name,
    archive: routesConfig.MOBILE_ARCHIVE.name,
};

// inherit width uses the width of the parent sidebar
const NotepadSidebarContainer = styled.div`
    width: inherit;
    position: fixed;
    padding-top: 20px;
`;

const NotepadSidebarLink = styled(omitProps(['active'])(Link))`
    && {
        background-color: ${(props) => props.active && props.theme.colors.cobaltBlue};
        color: ${(props) =>
            props.active ? props.theme.colors.white : props.theme.colors.cobaltBlue};
    }
    display: block;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    padding: 15px 18px;
    outline: none;

    &:hover {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.cobaltBlue};
    }
`;

function NotepadSidebar({ routeName }) {
    return (
        <NotepadSidebarContainer>
            {map(pathParamToRouteName, (name, pathParam) => (
                <NotepadSidebarLink
                    active={routeName === name}
                    key={pathParam}
                    to={`/mobile/${pathParam}`}
                >
                    {strings.menu[pathParam]}
                </NotepadSidebarLink>
            ))}
        </NotepadSidebarContainer>
    );
}

const mapStateToProps = createStructuredSelector({
    routeName: routeNameSelector,
});

/**
 * Sidebar that appears on all evidence feature routes.
 */
export default connect(mapStateToProps)(NotepadSidebar);

import React from 'react';
import { isOverflowHorizontal } from 'arc';
import { Tooltip } from '../../../core/components/tooltip';

interface PropsT {
    content: React.ReactNode;
}

const TaskTableTooltip: React.FC<PropsT> = (props) => {
    const [visible, setVisible] = React.useState<boolean>(false);

    return (
        <Tooltip side="bottom" align="start" isOpen={visible} content={props.content}>
            <div
                onMouseEnter={(e) => {
                    const currentTarget = e.currentTarget.children[0];
                    if (
                        currentTarget instanceof HTMLElement &&
                        isOverflowHorizontal(currentTarget)
                    ) {
                        return setVisible(true);
                    }
                    return null;
                }}
                onMouseLeave={() => {
                    setVisible(false);
                }}
            >
                {props.children}
            </div>
        </Tooltip>
    );
};

export default TaskTableTooltip;

import {
    AttributeTypeEnum,
    AttributeTypeEnumType,
    CodeTypeCategoryEnum,
    CodeTypeCategoryEnumType,
} from '@mark43/rms-api';

type AttributeTypeToCodeTypeCategoryMap = {
    [key in AttributeTypeEnumType]?: CodeTypeCategoryEnumType | readonly CodeTypeCategoryEnumType[];
};

/**
 * Object where each key is an attribute type and the value is the corresponding NIBRS code type(s).
 * @type {Object}
 */
export const attributeTypeToNibrsCodeTypeCategoryMap: AttributeTypeToCodeTypeCategoryMap = {
    [AttributeTypeEnum.DRUG_MEASUREMENT.name]: CodeTypeCategoryEnum.NIBRS_DRUG_MEASUREMENT.name,
    [AttributeTypeEnum.INJURY_CATEGORY.name]: CodeTypeCategoryEnum.NIBRS_INJURY_CATEGORY.name,
    // there are different code types for different item types, but the codes do not overlap, so
    // this array can be used to find the unique code regardless of item type
    [AttributeTypeEnum.ITEM_CATEGORY.name]: [
        CodeTypeCategoryEnum.NIBRS_DRUG_CATEGORY.name,
        CodeTypeCategoryEnum.NIBRS_PROPERTY_CATEGORY.name,
    ],
    [AttributeTypeEnum.LOCATION_CATEGORY.name]: CodeTypeCategoryEnum.NIBRS_LOCATION_CATEGORY.name,
    [AttributeTypeEnum.PROPERTY_LOSS.name]: CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name,
    [AttributeTypeEnum.SEX.name]: CodeTypeCategoryEnum.NIBRS_SEX.name,
    [AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name]:
        CodeTypeCategoryEnum.NIBRS_TYPE_OF_WEAPON_FORCE_INVOLVED.name,
    [AttributeTypeEnum.ARREST_TYPE.name]: CodeTypeCategoryEnum.NIBRS_ARREST_CATEGORY.name,
    [AttributeTypeEnum.BIAS_MOTIVATION.name]:
        CodeTypeCategoryEnum.NIBRS_INCIDENT_BIAS_MOTIVATION.name,
    [AttributeTypeEnum.HOMICIDE_CIRCUMSTANCE.name]:
        CodeTypeCategoryEnum.NIBRS_AGGRAVATED_ASSAULT_HOMICIDE_FACTORS.name,
    [AttributeTypeEnum.AGGRAVATED_ASSAULT_CIRCUMSTANCE.name]:
        CodeTypeCategoryEnum.NIBRS_AGGRAVATED_ASSAULT_HOMICIDE_FACTORS.name,
    [AttributeTypeEnum.LEOKA_ASSIGNMENT_CATEGORY.name]:
        CodeTypeCategoryEnum.FIRST_RESPONDER_VICTIM_TYPE.name,
    [AttributeTypeEnum.GANG_INFORMATION.name]:
        CodeTypeCategoryEnum.NIBRS_CRIMINAL_ACTIVITY_CATEGORY.name,
    [AttributeTypeEnum.CRIMINAL_ACTIVITY_CATEGORY.name]:
        CodeTypeCategoryEnum.NIBRS_CRIMINAL_ACTIVITY_CATEGORY.name,
    [AttributeTypeEnum.NEGLIGENT_MANSLAUGHTER_CIRCUMSTANCE.name]:
        CodeTypeCategoryEnum.NIBRS_AGGRAVATED_ASSAULT_HOMICIDE_FACTORS.name,
};

/**
 * Object where each key is an attribute type and the value is the corresponding UCR code type(s).
 * @type {Object}
 */
export const attributeTypeToUcrCodeTypeCategoryMap: AttributeTypeToCodeTypeCategoryMap = {
    [AttributeTypeEnum.PROPERTY_LOSS.name]: CodeTypeCategoryEnum.UCR_PROPERTY_STATUS.name,
};

export const attributeTypeToUkCodeTypeCategoryMap: AttributeTypeToCodeTypeCategoryMap = {
    [AttributeTypeEnum.UK_OFFENSE_CODE_CATEGORY.name]:
        CodeTypeCategoryEnum.UK_OFFENSE_CODE_CATEGORY.name,
    [AttributeTypeEnum.SEX.name]: CodeTypeCategoryEnum.UK_SEX.name,
    [AttributeTypeEnum.YES_NO_UNKNOWN.name]: CodeTypeCategoryEnum.YES_NO_UNKNOWN.name,
    [AttributeTypeEnum.DRUG_INVOLVEMENT.name]: CodeTypeCategoryEnum.DRUG_INVOLVEMENT.name,
};

export const attributeTypeToCodeTypeCategoryMap: AttributeTypeToCodeTypeCategoryMap = {
    [AttributeTypeEnum.DEX_WARRANT_TYPE.name]: CodeTypeCategoryEnum.DEX_WARRANT_TYPE.name,
    [AttributeTypeEnum.FIELD_CONTACT_REASON_FOR_STOP.name]:
        CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
    [AttributeTypeEnum.SUBJECT_DATA_REASON_FOR_STOP.name]:
        CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
    [AttributeTypeEnum.RACE.name]: CodeTypeCategoryEnum.STOP_PERCEIVED_RACE.name,
    [AttributeTypeEnum.REASON_FOR_SEARCH.name]: CodeTypeCategoryEnum.STOP_BASIS_FOR_SEARCH.name,
    [AttributeTypeEnum.RESULT_OF_STOP.name]: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
    [AttributeTypeEnum.SUBJECT_DATA_RESULT_OF_STOP.name]:
        CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
    [AttributeTypeEnum.SEX.name]: CodeTypeCategoryEnum.STOP_PERCEIVED_GENDER.name,
    [AttributeTypeEnum.SUBJECT_DATA_GENDER.name]: CodeTypeCategoryEnum.STOP_PERCEIVED_GENDER.name,
    [AttributeTypeEnum.STOP_ACTION_TAKEN.name]: CodeTypeCategoryEnum.STOP_ACTION_TAKEN.name,
    [AttributeTypeEnum.STOP_BASIS_FOR_PROPERTY_SEIZURE.name]:
        CodeTypeCategoryEnum.STOP_BASIS_FOR_PROPERTY_SEIZURE.name,
    [AttributeTypeEnum.STOP_EDUCATION_CODE_SECTION.name]:
        CodeTypeCategoryEnum.STOP_EDUCATION_CODE_SECTION.name,
    [AttributeTypeEnum.STOP_EDUCATION_CODE_SUBDIVISION.name]:
        CodeTypeCategoryEnum.STOP_EDUCATION_CODE_SUBDIVISION.name,
    [AttributeTypeEnum.STOP_SUSPICION_TYPE.name]: CodeTypeCategoryEnum.STOP_SUSPICION_TYPE.name,
    [AttributeTypeEnum.SUBJECT_DATA_PROBABLE_CAUSE.name]:
        CodeTypeCategoryEnum.STOP_SUSPICION_TYPE.name,
    [AttributeTypeEnum.STOP_TYPE_OF_PROPERTY_SEIZED.name]:
        CodeTypeCategoryEnum.STOP_TYPE_OF_PROPERTY_SEIZED.name,
    [AttributeTypeEnum.STOP_TYPE_OF_TRAFFIC_VIOLATION.name]:
        CodeTypeCategoryEnum.STOP_TYPE_OF_TRAFFIC_VIOLATION.name,
    [AttributeTypeEnum.STOP_USER_ASSIGNMENT_TYPE.name]:
        CodeTypeCategoryEnum.STOP_USER_ASSIGNMENT_TYPE.name,
    [AttributeTypeEnum.STOP_DATA_OFFICER_RACE.name]: CodeTypeCategoryEnum.STOP_PERCEIVED_RACE.name,
    [AttributeTypeEnum.STOP_DATA_OFFICER_GENDER.name]:
        CodeTypeCategoryEnum.STOP_PERCEIVED_GENDER.name,
    [AttributeTypeEnum.SUBJECT_DATA_DISABILITY.name]: CodeTypeCategoryEnum.STOP_DISABILITY.name,
    [AttributeTypeEnum.WEAPON_INVOLVED.name]: CodeTypeCategoryEnum.STOP_CONTRABAND_TYPE.name,
    [AttributeTypeEnum.WARRANT_ENTRY_LEVEL_CODE.name]: CodeTypeCategoryEnum.ENTRY_LEVEL_CODE.name,
    [AttributeTypeEnum.USE_OF_FORCE_EXTENDED_BOOLEAN.name]:
        CodeTypeCategoryEnum.USE_OF_FORCE_EXTENDED_BOOLEAN.name,
    [AttributeTypeEnum.USE_OF_FORCE_SUBJECT_IMPAIRMENTS.name]:
        CodeTypeCategoryEnum.USE_OF_FORCE_SUBJECT_IMPAIRMENTS.name,
    [AttributeTypeEnum.USE_OF_FORCE_SUBJECT_DISPOSITION.name]:
        CodeTypeCategoryEnum.USE_OF_FORCE_SUBJECT_DISPOSITION.name,
    [AttributeTypeEnum.USE_OF_FORCE_PENDING_UNKNOWN.name]:
        CodeTypeCategoryEnum.USE_OF_FORCE_PENDING_UNKNOWN.name,
    [AttributeTypeEnum.USE_OF_FORCE_REASON.name]: CodeTypeCategoryEnum.USE_OF_FORCE_REASON.name,
    [AttributeTypeEnum.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT.name]:
        CodeTypeCategoryEnum.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT.name,
    [AttributeTypeEnum.STOP_NON_FORCIBLE_ACTION_TAKEN.name]:
        CodeTypeCategoryEnum.STOP_NON_FORCIBLE_ACTION_TAKEN.name,
    [AttributeTypeEnum.STOP_FORCIBLE_ACTION_TAKEN.name]:
        CodeTypeCategoryEnum.STOP_FORCIBLE_ACTION_TAKEN.name,
    [AttributeTypeEnum.TYPE_OF_STOP.name]: CodeTypeCategoryEnum.STOP_TYPE_OF_STOP.name,
    [AttributeTypeEnum.SUBJECT_DATA_CONTRABAND_OR_EVIDENCE.name]:
        CodeTypeCategoryEnum.STOP_CONTRABAND_TYPE.name,
    [AttributeTypeEnum.SUBJECT_DATA_REASON_GIVEN_TO_STOPPED_PERSON.name]:
        CodeTypeCategoryEnum.STOP_REASON_GIVEN_TO_STOPPED_PERSON.name,
    [AttributeTypeEnum.DETENTION_NON_FILING_TYPE.name]:
        CodeTypeCategoryEnum.CA_EARREST_NON_FILING_TYPE.name,
    [AttributeTypeEnum.DETENTION_NON_FILING_REASON_CODE.name]:
        CodeTypeCategoryEnum.CA_EARREST_NON_FILING_REASON.name,
} as const;

export function getCodeTypeCategory(
    source: 'NIBRS' | 'UCR' | 'UK',
    attributeType: AttributeTypeEnumType
): CodeTypeCategoryEnumType | readonly CodeTypeCategoryEnumType[] | undefined {
    switch (source) {
        case 'NIBRS':
            return attributeTypeToNibrsCodeTypeCategoryMap[attributeType];
        case 'UCR':
            return attributeTypeToUcrCodeTypeCategoryMap[attributeType];
        case 'UK':
            return attributeTypeToUkCodeTypeCategoryMap[attributeType];
        default:
            return attributeTypeToCodeTypeCategoryMap[attributeType];
    }
}

import { createFormConfiguration } from 'markformythree';

import {
    WARRANT_WARRANT_NUMBER,
    WARRANT_WARRANT_TYPE_ATTR_ID,
    WARRANT_OBTAINING_OFFICER_USER_ID,
} from '~/client-common/core/enums/universal/fields';

export const getFormConfiguration = () =>
    createFormConfiguration({
        warrantNumber: {
            fieldName: WARRANT_WARRANT_NUMBER,
        },
        warrantTypeAttrIds: {
            fieldName: WARRANT_WARRANT_TYPE_ATTR_ID,
        },
        obtainingOfficerUserIds: {
            fieldName: WARRANT_OBTAINING_OFFICER_USER_ID,
        },
        // appear in UI, but have hardcoded labels and are not associated with any rules/etc.
        startDateUtc: {},
        endDateUtc: {},
    });

import { CrashDetail, CrashDetailCardBundle, ReportAttribute } from '@mark43/rms-api';
import { VersionableProperties } from '../../../../../types';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { WithCustomProperties } from '../../../dynamic-fields/types';

export type CrashDetailWithCustomProperties = WithCustomProperties<CrashDetail>;

type CrashDetailCardBundleCrashDetailForUpsert = Omit<
    CrashDetailWithCustomProperties,
    VersionableProperties | 'id'
> & {
    id?: number;
};
type CrashDetailCardBundleReportAttributesForUpsert = Omit<
    ReportAttribute,
    VersionableProperties
>[];
export type CrashDetailCardBundleForUpsert = {
    crashDetail: CrashDetailCardBundleCrashDetailForUpsert;
    reportAttributes: CrashDetailCardBundleReportAttributesForUpsert;
};

export const NEXUS_STATE_PROP = 'crashDetails';

const module = createNormalizedModule<CrashDetailWithCustomProperties>({
    type: NEXUS_STATE_PROP,
});

export const crashDetailsWhereSelector = module.selectors.entitiesWhereSelector;

const UPDATE_CRASH_EVENT_INFO_START = 'crash-details/UPDATE_CRASH_EVENT_INFO_START';
const UPDATE_CRASH_EVENT_INFO_SUCCESS = 'crash-details/UPDATE_CRASH_EVENT_INFO_SUCCESS';
const UPDATE_CRASH_EVENT_INFO_FAILURE = 'crash-details/UPDATE_CRASH_EVENT_INFO_FAILURE';

export const updateCrashEventInfoStart = (
    crashDetailCardBundle: CrashDetailCardBundleForUpsert
) => {
    return {
        type: UPDATE_CRASH_EVENT_INFO_START,
        payload: crashDetailCardBundle,
    };
};

export const updateCrashEventInfoSuccess = (crashDetailCardBundle: CrashDetailCardBundle) => {
    return {
        type: UPDATE_CRASH_EVENT_INFO_SUCCESS,
        payload: crashDetailCardBundle,
    };
};

export const updateCrashEventInfoFailure = (message: string) => {
    return {
        type: UPDATE_CRASH_EVENT_INFO_FAILURE,
        payload: message,
    };
};

export default module.reducerConfig;

import { find } from 'lodash';
import { createSelector } from 'reselect';

import {
    reportDefinitionHasOffenseCard,
    reportDefinitionHasIncidentCard,
} from '../../../../../helpers/reportDefinitionsHelpers';
import { reportDefinitionsWhereSelector } from '../data';

const isOffenseReport = ({ reportDefinition }) => {
    return (
        reportDefinitionHasOffenseCard(reportDefinition) ||
        reportDefinitionHasIncidentCard(reportDefinition)
    );
};

export const offenseReportDefinitionForDepartmentSelector = createSelector(
    reportDefinitionsWhereSelector,
    (reportDefinitionsWhere) => ({ departmentId }) => {
        const reportDefinitionsForDepartment = reportDefinitionsWhere({ departmentId });
        return (
            find(reportDefinitionsForDepartment, (reportDefinition) =>
                isOffenseReport({ reportDefinition })
            ) || {}
        );
    }
);

import React, { useCallback } from 'react';
import styled from 'styled-components';
import { get, map, filter } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Fields, Observer, lifecycleOptions } from 'markformythree';
import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { convertStopEntityAttributesForDisplay } from '~/client-common/core/domain/stop-entity-attributes/utils/stopEntityAttributesHelpers';
import withFields from '~/client-common/core/fields/components/withFields';
import * as fields from '~/client-common/core/enums/universal/fields';
import entityTypeObjectEnum from '~/client-common/core/enums/universal/entityTypeObjectEnum';
import { formatAttributeWithOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_WRAPPER,
    DISPLAY_ONLY_STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_WRAPPER,
} from '~/client-common/core/enums/universal/fields';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import testIds from '../../../../../core/testIds';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { MFTNItems } from '../../../../core/forms/components/NItems';
import {
    renderAddButton,
    renderRemoveButton,
} from '../../../../core/names/components/nameFormNItemsRenderers';
import { iconSizes } from '../../../../core/components/Icon';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import IndentedFields from '../../../../core/components/IndentedFields';

import Row from '../../../../core/components/Row';
import SummarySectionDivider from '../../../../../legacy-redux/components/summaries/SummarySectionDivider';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { createNItemsAdder, createNItemsRemover } from '../../utils/nItemsHelpers';
import {
    formName,
    STOP_SUBJECTS_PATH,
    STOP_SUBJECT_SUBJECT_LOCATION_PATH,
    STOP_SUBJECT_SUBJECT_TYPE_ATTR_ID_PATH,
    STOP_SUBJECT_SUBJECT_TYPE_OTHER_PATH,
    STOP_SUBJECT_ADD_ADDITIONAL_SUBJECTS_BUTTON_PATH,
    STOP_OTHER_NAMES_PATH,
    STOP_OTHER_NAME_SUBJECT_TYPE_ATTR_ID_PATH,
    STOP_OTHER_NAME_SUBJECT_TYPE_OTHER_PATH,
    STOP_SUBJECT_WAS_THIS_PERSON_STOPPED_PATH,
    STOP_SUBJECT_STOP_START_UTC_PATH,
    STOP_SUBJECT_STOP_END_UTC_PATH,
    STOP_SUBJECT_WAS_THIS_PERSON_SEARCHED_PATH,
    STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_PATH,
    STOP_SUBJECT_WAS_THIS_PROPERTY_SEARCHED_PATH,
    STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_PATH,
    getStopForm,
    findIndexOfNItemsNameLink,
} from '../../state/forms/stopForm';
import { useDateTimeFormatter } from '../../../../core/current-user/hooks/dateTimeFormats';

const RemoveNItemButtonWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 10px;
`;

const SubjectSummary = connect(
    createStructuredSelector({
        formatAttributeWithOther: formatAttributeWithOtherSelector,
    })
)(function SubjectSummary({
    subjectTypeFieldName,
    subjectTypeAttrId,
    subjectTypeOther,
    formatAttributeWithOther,
    wasFrisked,
    wasSearchPerformed,
    wasThisPersonStopped,
    stopStartUtc,
    stopEndUtc,
    nameId,
    index,
    stopEntityAttributes,
    isOtherName,
}) {
    const dateTimeFormatter = useDateTimeFormatter();

    const personStopEntityAttributesForDisplay = convertStopEntityAttributesForDisplay(
        stopEntityAttributes,
        true
    );

    const propertyStopEntityAttributesForDisplay = convertStopEntityAttributesForDisplay(
        stopEntityAttributes,
        false
    );

    return (
        <>
            <SummaryList labelWidth={100} contentWidth={400}>
                <SummaryRow fieldName={subjectTypeFieldName}>
                    {formatAttributeWithOther({
                        attributeId: subjectTypeAttrId,
                        other: subjectTypeOther,
                    })}
                </SummaryRow>
            </SummaryList>
            {/* MPD: NEAR Act Summary Fields */}
            <SummaryList labelWidth={275} contentWidth={225}>
                <SummaryRow fieldName={fields.NAME_REPORT_LINK_WAS_THIS_PERSON_STOPPED}>
                    {booleanToYesNo(wasThisPersonStopped)}
                </SummaryRow>
                <SummaryRow fieldName={fields.NAME_REPORT_LINK_STOP_START_UTC}>
                    {dateTimeFormatter.formatDateTime(stopStartUtc)}
                </SummaryRow>
                <SummaryRow fieldName={fields.NAME_REPORT_LINK_STOP_END_UTC}>
                    {dateTimeFormatter.formatDateTime(stopEndUtc)}
                </SummaryRow>
                {!isOtherName && (
                    <SubjectLocation index={index} nameId={nameId} summaryMode={true} />
                )}
                <SummaryRow fieldName={fields.NAME_REPORT_LINK_WAS_FRISKED}>
                    {booleanToYesNo(wasFrisked)}
                </SummaryRow>
                {map(personStopEntityAttributesForDisplay, ({ fieldName, attributes }, index) => {
                    return (
                        <>
                            <SummarySectionDivider />
                            <SummaryRow fieldName={fieldName}>
                                {map(attributes, ({ attributeId, description }) => {
                                    return formatAttributeWithOther({
                                        attributeId,
                                        other: description,
                                    });
                                }).join(', ')}
                            </SummaryRow>
                            {index === personStopEntityAttributesForDisplay.length && (
                                <SummarySectionDivider />
                            )}
                        </>
                    );
                })}
                <SummaryRow fieldName={fields.NAME_REPORT_LINK_WAS_SEARCH_PERFORMED}>
                    {booleanToYesNo(wasSearchPerformed)}
                </SummaryRow>
                {map(propertyStopEntityAttributesForDisplay, ({ fieldName, attributes }) => {
                    return (
                        <>
                            <SummarySectionDivider />
                            <SummaryRow fieldName={fieldName}>
                                {map(attributes, ({ attributeId, description }) => {
                                    return formatAttributeWithOther({
                                        attributeId,
                                        other: description,
                                    });
                                }).join(', ')}
                            </SummaryRow>
                        </>
                    );
                })}
            </SummaryList>
        </>
    );
});

function SearchTypeNItems({ isPerson, path }) {
    const {
        DISPLAY_ONLY_STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_WRAPPER: personSearchTypeSubjectLabel,
        DISPLAY_ONLY_STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_WRAPPER: propertySearchTypeSubjectLabel,
    } = useFields([
        DISPLAY_ONLY_STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_WRAPPER,
        DISPLAY_ONLY_STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_WRAPPER,
    ]);
    return (
        <VisibilityObserver
            path={path}
            formName={formName}
            render={({ hidden }) =>
                !hidden && (
                    <MFTNItems
                        formName={formName}
                        path={path}
                        addItemOnEmpty={true}
                        addText={
                            isPerson ? personSearchTypeSubjectLabel : propertySearchTypeSubjectLabel
                        }
                        hideAddButtonOnEmptyItem={false}
                        childFieldKeys={[
                            'typeOfSearchAttrId',
                            'reasonForSearchAttrIds',
                            'reasonForSearchDescription',
                            ...(!isPerson
                                ? ['objectOfSearchAttrIds', 'objectOfSearchDescription']
                                : []),
                            'typeOfPropertySeizedAttrIds',
                            'typeOfPropertySeizedDescription',
                        ]}
                        render={() => (
                            <>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="typeOfSearchAttrId"
                                        attributeType={
                                            isPerson
                                                ? AttributeTypeEnum.TYPE_OF_SEARCH.name
                                                : AttributeTypeEnum.PROPERTY_TYPE_OF_SEARCH.name
                                        }
                                        length="lg"
                                    />
                                </Row>
                                <IndentedFields>
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            path="reasonForSearchAttrIds"
                                            attributeType={
                                                isPerson
                                                    ? AttributeTypeEnum.REASON_FOR_SEARCH.name
                                                    : AttributeTypeEnum.PROPERTY_REASON_FOR_SEARCH
                                                          .name
                                            }
                                            multiple={true}
                                        />
                                    </Row>
                                    <IndentedFields>
                                        <Row>
                                            <ArbiterMFTText
                                                path="reasonForSearchDescription"
                                                length="lg"
                                            />
                                        </Row>
                                    </IndentedFields>
                                    {!isPerson && (
                                        <>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="objectOfSearchAttrIds"
                                                    attributeType={
                                                        AttributeTypeEnum.OBJECT_OF_SEARCH.name
                                                    }
                                                    multiple={true}
                                                />
                                            </Row>
                                            <IndentedFields>
                                                <Row>
                                                    <ArbiterMFTText
                                                        path="objectOfSearchDescription"
                                                        length="lg"
                                                    />
                                                </Row>
                                            </IndentedFields>
                                        </>
                                    )}
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            path="typeOfPropertySeizedAttrIds"
                                            attributeType={
                                                isPerson
                                                    ? AttributeTypeEnum.STOP_TYPE_OF_PROPERTY_SEIZED
                                                          .name
                                                    : AttributeTypeEnum
                                                          .STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED
                                                          .name
                                            }
                                            multiple={true}
                                        />
                                    </Row>
                                    <IndentedFields>
                                        <Row>
                                            <ArbiterMFTText
                                                path="typeOfPropertySeizedDescription"
                                                length="lg"
                                            />
                                        </Row>
                                    </IndentedFields>
                                </IndentedFields>
                            </>
                        )}
                        renderAddButton={(props) =>
                            renderAddButton({
                                ...props,
                                testId: testIds.STOP_SUBJECT_ADD_SEARCH_TYPE,
                            })
                        }
                        renderRemoveButton={(props) => {
                            const { items, index } = props;
                            return (
                                (index > 0 || items.length > 1) && (
                                    <RemoveNItemButtonWrapper>
                                        {renderRemoveButton({
                                            ...props,
                                            iconSize: iconSizes.SMALL,
                                            testId: testIds.STOP_SUBJECT_REMOVE_SEARCH_TYPE,
                                        })}
                                    </RemoveNItemButtonWrapper>
                                )
                            );
                        }}
                    />
                )
            }
        />
    );
}

function SubjectFields({ pathPrefix, index, nameId, form, isOtherName }) {
    const subjectTypeAttrIdPath = `${pathPrefix}[${index}].${STOP_SUBJECT_SUBJECT_TYPE_ATTR_ID_PATH}`;
    const subjectTypeOtherPath = `${pathPrefix}[${index}].${STOP_SUBJECT_SUBJECT_TYPE_OTHER_PATH}`;
    const wasPersonStoppedPath = `${pathPrefix}[${index}].${STOP_SUBJECT_WAS_THIS_PERSON_STOPPED_PATH}`;
    const stopStartUtcPath = `${pathPrefix}[${index}].${STOP_SUBJECT_STOP_START_UTC_PATH}`;
    const stopEndUtcPath = `${pathPrefix}[${index}].${STOP_SUBJECT_STOP_END_UTC_PATH}`;
    const wasThisPersonSearchedPath = `${pathPrefix}[${index}].${STOP_SUBJECT_WAS_THIS_PERSON_SEARCHED_PATH}`;
    const personSearchNItemsPath = `${pathPrefix}[${index}].${STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_PATH}`;
    const wasPropertySearchedPath = `${pathPrefix}[${index}].${STOP_SUBJECT_WAS_THIS_PROPERTY_SEARCHED_PATH}`;
    const propertySearchNItemsPath = `${pathPrefix}[${index}].${STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_PATH}`;

    const onChangeWasPersonStopped = React.useCallback(
        (value) => {
            // when changed to No, clear and hide the 2 boolean fields and 2 nested NItems in order to
            // 1. prevent UI bugs of the NItems appearing when they should not, while the value of the 2 boolean fields remain true
            // 2. not populate stopEntityAttributes in the API request (as opposed to filtering in buildStopCardBundle)
            if (
                !value &&
                (get(form.getUi(personSearchNItemsPath), 'hidden') === false ||
                    get(form.getUi(propertySearchNItemsPath), 'hidden') === false)
            ) {
                form.transaction(() => {
                    // clear the nested NItems
                    form.set(personSearchNItemsPath, []);
                    form.resetUi(personSearchNItemsPath);
                    form.set(propertySearchNItemsPath, []);
                    form.resetUi(propertySearchNItemsPath);

                    // clear the fields that show/hide the nested Items
                    form.set(wasThisPersonSearchedPath, undefined);
                    form.resetUi(wasThisPersonSearchedPath);
                    form.set(wasPropertySearchedPath, undefined);
                    form.resetUi(wasPropertySearchedPath);
                });
            }
        },
        [
            form,
            wasThisPersonSearchedPath,
            personSearchNItemsPath,
            wasPropertySearchedPath,
            propertySearchNItemsPath,
        ]
    );

    return (
        <>
            <Row>
                <ArbiterMFTAttributeSelect
                    path={subjectTypeAttrIdPath}
                    length="lg"
                    attributeType={AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE.name}
                />
            </Row>
            <IndentedFields>
                <Row>
                    <ArbiterMFTText path={subjectTypeOtherPath} length="lg" />
                </Row>
            </IndentedFields>
            {!isOtherName && (
                <>
                    <Row>
                        <ArbiterMFTBooleanSelect
                            path={wasPersonStoppedPath}
                            length="lg"
                            onChange={onChangeWasPersonStopped}
                        />
                    </Row>
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTDatePicker
                                path={stopStartUtcPath}
                                includeTime={true}
                                length="lg"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTDatePicker
                                path={stopEndUtcPath}
                                includeTime={true}
                                length="lg"
                            />
                        </Row>

                        <SubjectLocation index={index} nameId={nameId} summaryMode={false} />

                        <Row>
                            <ArbiterMFTBooleanSelect path={wasThisPersonSearchedPath} length="lg" />
                        </Row>
                        <IndentedFields>
                            <SearchTypeNItems isPerson={true} path={personSearchNItemsPath} />
                        </IndentedFields>
                        <Row>
                            <ArbiterMFTBooleanSelect path={wasPropertySearchedPath} length="lg" />
                        </Row>
                        <IndentedFields>
                            <SearchTypeNItems isPerson={false} path={propertySearchNItemsPath} />
                        </IndentedFields>
                    </IndentedFields>
                </>
            )}
        </>
    );
}

function onAddSubjectSuccess(nameReportLink) {
    return createNItemsAdder({
        getForm: getStopForm,
        path: STOP_SUBJECTS_PATH,
        defaults: {
            [STOP_SUBJECT_SUBJECT_TYPE_ATTR_ID_PATH]: undefined,
            [STOP_SUBJECT_SUBJECT_TYPE_OTHER_PATH]: undefined,
        },
    })(nameReportLink);
}

function onRemoveSubjectSuccess(nameReportLink) {
    const { nameId } = nameReportLink;
    createNItemsRemover({
        getForm: getStopForm,
        path: STOP_SUBJECTS_PATH,
    })({ nameId });
}

function onAddOtherNameSuccess(nameReportLink) {
    return createNItemsAdder({
        getForm: getStopForm,
        path: STOP_OTHER_NAMES_PATH,
        defaults: {
            [STOP_OTHER_NAME_SUBJECT_TYPE_ATTR_ID_PATH]: undefined,
            [STOP_OTHER_NAME_SUBJECT_TYPE_OTHER_PATH]: undefined,
        },
    })(nameReportLink);
}

function onRemoveOtherNameSuccess(nameReportLink) {
    const { nameId } = nameReportLink;
    return createNItemsRemover({
        getForm: getStopForm,
        path: STOP_OTHER_NAMES_PATH,
    })({ nameId });
}

function SubjectLocation(props) {
    const { index, nameId, summaryMode } = props;

    const subjectLocationPath = `${STOP_SUBJECTS_PATH}[${index}].${STOP_SUBJECT_SUBJECT_LOCATION_PATH}`;

    // using n-items but only allow 1
    const onLocationAdd = useCallback(
        (location, modelEntityLink) => getStopForm().set(subjectLocationPath, [modelEntityLink]),
        [subjectLocationPath]
    );
    const onLocationRemove = useCallback(() => getStopForm().set(subjectLocationPath, []), [
        subjectLocationPath,
    ]);
    const getLocationDescriptionPath = useCallback(
        (locationBundle, index) => `${subjectLocationPath}[${index}].description`,
        [subjectLocationPath]
    );
    const getLocationPositionAttrIdPath = useCallback(
        (locationBundle, index) => `${subjectLocationPath}[${index}].positionAttrId`,
        [subjectLocationPath]
    );

    return (
        <VisibilityObserver
            path={subjectLocationPath}
            formName={formName}
            render={({ hidden }) => {
                return (
                    !hidden && (
                        <LocationSummaryViewWrapperWithFormFields
                            allowMultiple={false}
                            entityId={nameId}
                            entityType={EntityTypeEnum.PERSON_PROFILE.name}
                            linkType={LinkTypesEnum.LOCATION_OF_PERSON_STOP}
                            locationDescriptionPath={getLocationDescriptionPath}
                            locationPositionAttrIdPath={getLocationPositionAttrIdPath}
                            onLocationAdd={onLocationAdd}
                            onLocationRemove={onLocationRemove}
                            summaryMode={summaryMode}
                        />
                    )
                );
            }}
        />
    );
}

const Subject = withFields([fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_LINK_TYPE])(
    function Subject({
        summaryMode,
        reportId,
        reportingEventNumber,
        limitToOne,
        fieldDisplayNames,
        stopEntityAttributes,
        form,
    }) {
        return (
            <NameSummaryViewWrapper
                renForRecents={reportingEventNumber}
                summaryMode={summaryMode}
                addNameButtonText={
                    fieldDisplayNames.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_LINK_TYPE
                }
                reportId={reportId}
                contextType={entityTypeObjectEnum.REPORT.name}
                contextId={reportId}
                parentEntityType={entityTypeObjectEnum.REPORT.name}
                parentId={reportId}
                linkType={LinkTypesEnum.SUBJECT_IN_STOP}
                show={{
                    people: true,
                }}
                onAddSuccess={onAddSubjectSuccess}
                onRemoveSuccess={onRemoveSubjectSuccess}
                limitToOne={limitToOne}
                renderAdditionalItem={({ nameLink }) => {
                    const { nameId } = nameLink;
                    const index = findIndexOfNItemsNameLink({
                        path: STOP_SUBJECTS_PATH,
                        nameId,
                    });

                    const subjectStopEntityAttributes = filter(stopEntityAttributes, {
                        entityId: nameId,
                    });

                    // Only render additional fields if the name currently exists on the form.
                    if (index < 0) {
                        return null;
                    }

                    return (
                        <>
                            {summaryMode ? (
                                <SubjectSummary
                                    index={index}
                                    subjectTypeFieldName={
                                        fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_SUBJECT_TYPE_ATTR_ID
                                    }
                                    stopEntityAttributes={subjectStopEntityAttributes}
                                    {...nameLink}
                                />
                            ) : (
                                <SubjectFields
                                    index={index}
                                    pathPrefix={STOP_SUBJECTS_PATH}
                                    nameId={nameId}
                                    form={form}
                                />
                            )}
                        </>
                    );
                }}
            />
        );
    }
);

const OtherName = withFields([fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_STOP_LINK_TYPE])(
    function OtherName({ summaryMode, reportId, reportingEventNumber, fieldDisplayNames }) {
        return (
            <NameSummaryViewWrapper
                renForRecents={reportingEventNumber}
                summaryMode={summaryMode}
                addNameButtonText={
                    fieldDisplayNames.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_STOP_LINK_TYPE
                }
                reportId={reportId}
                contextType={entityTypeObjectEnum.REPORT.name}
                contextId={reportId}
                parentEntityType={entityTypeObjectEnum.REPORT.name}
                parentId={reportId}
                linkType={LinkTypesEnum.OTHER_NAME_IN_STOP}
                show={{
                    people: true,
                }}
                onAddSuccess={onAddOtherNameSuccess}
                onRemoveSuccess={onRemoveOtherNameSuccess}
                renderAdditionalItem={({ nameLink }) => {
                    const { nameId, subjectTypeAttrId, subjectTypeOther } = nameLink;
                    const index = findIndexOfNItemsNameLink({
                        path: STOP_OTHER_NAMES_PATH,
                        nameId,
                    });

                    // Only render additional fields if the name currently exists on the form.
                    if (index < 0) {
                        return null;
                    }

                    return summaryMode ? (
                        <SubjectSummary
                            subjectTypeFieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_STOP_SUBJECT_TYPE_ATTR_ID
                            }
                            subjectTypeAttrId={subjectTypeAttrId}
                            subjectTypeOther={subjectTypeOther}
                            isOtherName={true}
                        />
                    ) : (
                        <SubjectFields
                            pathPrefix={STOP_OTHER_NAMES_PATH}
                            index={index}
                            isOtherName={true}
                        />
                    );
                }}
            />
        );
    }
);

function StopCardSubjectSection({
    summaryMode,
    reportId,
    reportingEventNumber,
    stopEntityAttributes,
}) {
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            render={(form) => (
                <>
                    <VisibilityObserver
                        path={STOP_SUBJECTS_PATH}
                        formName={formName}
                        render={({ hidden }) =>
                            !hidden && (
                                <CardSection
                                    fieldName={
                                        fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_LINK_TYPE
                                    }
                                    testId={testIds.STOP_CARD_SUBJECT_SECTION}
                                >
                                    <Observer
                                        formName={formName}
                                        subscriptions={{
                                            // this section must re-render whenever the subjects change
                                            subjects: [STOP_SUBJECTS_PATH, Fields.MODEL],
                                            // the wrapper field determines whether the whole section is visible
                                            addButtonHidden: [
                                                STOP_SUBJECT_ADD_ADDITIONAL_SUBJECTS_BUTTON_PATH,
                                                Fields.HIDDEN,
                                            ],
                                        }}
                                        render={({ addButtonHidden }) => (
                                            <Subject
                                                summaryMode={summaryMode}
                                                reportId={reportId}
                                                reportingEventNumber={reportingEventNumber}
                                                limitToOne={addButtonHidden}
                                                stopEntityAttributes={stopEntityAttributes}
                                                form={form}
                                            />
                                        )}
                                    />
                                </CardSection>
                            )
                        }
                    />
                    <VisibilityObserver
                        path={STOP_OTHER_NAMES_PATH}
                        formName={formName}
                        render={({ hidden }) =>
                            !hidden && (
                                <CardSection
                                    fieldName={
                                        fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_STOP_LINK_TYPE
                                    }
                                    testId={testIds.STOP_CARD_OTHER_NAMES_SECTION}
                                >
                                    <Observer
                                        formName={formName}
                                        subscriptions={{
                                            // this section must re-render whenever the subjects change
                                            otherNames: [STOP_OTHER_NAMES_PATH, [Fields.MODEL]],
                                        }}
                                        render={() => (
                                            <OtherName
                                                summaryMode={summaryMode}
                                                reportId={reportId}
                                                reportingEventNumber={reportingEventNumber}
                                            />
                                        )}
                                    />
                                </CardSection>
                            )
                        }
                    />
                </>
            )}
        />
    );
}

export default StopCardSubjectSection;

import _, { filter, get } from 'lodash';
import { createSelector } from 'reselect';
import { getViewModelProperties } from '../../../../../helpers/viewModelHelpers';
import { chainEventViewModelsForMasterItemIdSelector } from '../../../chain-events/state/ui';
import { evidenceReportByIdSelector } from '../../../evidence-reports/state/data';

/**
 * Chain of Custody view model for a master item id
 * @param {number} masterItemId
 * return {Object[]}
 */
export const chainOfCustodyViewModelsForMasterItemIdSelector = createSelector(
    chainEventViewModelsForMasterItemIdSelector,
    evidenceReportByIdSelector,
    (chainEventViewModelsForMasterItemId, evidenceReportById) => (masterItemId) => {
        const chainEventViewModels = chainEventViewModelsForMasterItemId(masterItemId);

        return _(chainEventViewModels)
            .uniqBy('chainOfCustodyId')
            .orderBy('eventDateUtc', 'desc')
            .map((chainEvent) => {
                const { chainOfCustodyId } = chainEvent;
                const { reportId: custodialReportId } = getViewModelProperties(chainEvent);

                const reportingEventNumber = get(
                    evidenceReportById(custodialReportId),
                    'reportingEventNumber'
                );
                const chainEvents = filter(chainEventViewModels, { chainOfCustodyId });

                return {
                    chainOfCustodyId,
                    chainEvents,
                    reportingEventNumber,
                    custodialReportId,
                    masterItemId,
                };
            })
            .value();
    }
);

import React, { ComponentType, FC } from 'react';
import { useBookingSearchPermission } from '../hooks';

type InjectProps = {
    canSearchBookings: boolean;
};

export const withBookingSearchPermission = <OwnProps extends InjectProps>(
    Component: ComponentType<OwnProps>
) => {
    const WithBookingSearchPermissionWrapper: FC<Omit<OwnProps, keyof InjectProps>> = (props) => {
        const canSearchBookings = useBookingSearchPermission();

        return <Component {...(props as OwnProps)} canSearchBookings={canSearchBookings} />;
    };

    return WithBookingSearchPermissionWrapper;
};

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';

import { pick } from 'lodash';

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import {
    requiredString,
    requiredInt,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const facilityAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'locationName',
        validators: {
            requiredError: requiredString,
        },
    },
    {
        key: 'locationTypeAttrId',
        type: fieldTypeClientEnum.ATTRIBUTE,
        validators: {
            requiredError: requiredInt,
        },
    },
    {
        key: 'streetAddress',
        validators: {
            requiredError: requiredString,
        },
    },
    'subPremise',
    'locality',
    'adminArea1',
    'postalCode',
    'country',
    // latitude and longitude are not included because they can't be saved on
    // locations as of 2016-10-20
    'phoneNumber',
    'description',
    {
        key: 'expiredDateUtc',
        type: fieldTypeClientEnum.DATE_TIME,
    },
]);

/**
 * @param  {Object} formModel
 * @param  {number} [id] Empty for a new facility.
 * @return {Object}
 */
function convertFacilityFormModelToDataState(formModel, id) {
    return {
        id,
        ...pick(formModel, [
            'locationName',
            'locationTypeAttrId',
            'phoneNumber',
            'description',
            'expiredDateUtc',
        ]),
    };
}

/**
 * This second helper is needed to extract the location fields from the facility
 *   form model. The resulting object is a location data model including the
 *   location entity link between the facility and the location.
 * @param  {Object} formModel
 * @param  {number} [facilityId] Empty for a new facility.
 * @param  {number} [locationId] Empty for a new facility.
 * @return {Object}
 */
export function convertFacilityFormModelToLocationDataState(formModel, facilityId, locationId) {
    return {
        id: locationId,
        ...pick(formModel, ['streetAddress', 'locality', 'adminArea1', 'postalCode', 'country']),
        entityLinks: [
            {
                linkType: LinkTypesEnum.EVIDENCE_FACILITY_LOCATION,
                locationId,
                entityType: EntityTypeEnum.EVIDENCE_FACILITY.name,
                entityId: facilityId,
                subPremise: formModel.subPremise,
            },
        ],
    };
}

const facilityAdminForm = createFormModule({
    formName: formClientEnum.FACILITY_ADMIN,
    fieldViewModels: facilityAdminFormFieldViewModels,
    convertFromFormModel: convertFacilityFormModelToDataState,
});

/**
 * Module of the facility admin form, for creating/editing a single facility.
 * @type {Object}
 */
export default facilityAdminForm;

import { first } from 'lodash';
import { RmsTowVehicle } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getTowVehicleResource from '../../resources/towVehiclesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'towVehicles';

const towVehiclesModule = createNormalizedModule<RmsTowVehicle>({
    type: NEXUS_STATE_PROP,
});

const UPDATE_TOW_VEHICLE_START = 'tow-vehicles/UPDATE_TOW_VEHICLE_START';
const UPDATE_TOW_VEHICLE_SUCCESS = 'tow-vehicles/UPDATE_TOW_VEHICLE_SUCCESS';
const UPDATE_TOW_VEHICLE_FAILURE = 'tow-vehicles/UPDATE_TOW_VEHICLE_FAILURE';

function updateTowVehicleStart(towVehicle: RmsTowVehicle) {
    return {
        type: UPDATE_TOW_VEHICLE_START,
        payload: towVehicle,
    };
}

function updateTowVehicleSuccess(towVehicle: RmsTowVehicle) {
    return {
        type: UPDATE_TOW_VEHICLE_SUCCESS,
        payload: towVehicle,
    };
}

function updateTowVehicleFailure(message: string) {
    return {
        type: UPDATE_TOW_VEHICLE_FAILURE,
        payload: message,
    };
}

export function updateTowVehicle(
    towVehicle: RmsTowVehicle
): ClientCommonAction<Promise<RmsTowVehicle>> {
    return (dispatch) => {
        const resource = getTowVehicleResource();
        dispatch(updateTowVehicleStart(towVehicle));
        return resource
            .updateTowVehicle(towVehicle)
            .then((towVehicle: RmsTowVehicle) => {
                dispatch(storeTowVehicles(towVehicle));
                dispatch(updateTowVehicleSuccess(towVehicle));
                return towVehicle;
            })
            .catch((err: Error) => {
                dispatch(updateTowVehicleFailure(err.message));
                throw err;
            });
    };
}

// ACTIONS
const storeTowVehicles = towVehiclesModule.actionCreators.storeEntities;

// SELECTORS

export const towVehiclesWhereSelector = towVehiclesModule.selectors.entitiesWhereSelector;

// Only 1 `TowVehicle` per report
export const towVehicleByReportIdSelector = createSelector(
    towVehiclesWhereSelector,
    (towVehiclesWhere) => (reportId: number) => first(towVehiclesWhere({ reportId }))
);

// REDUCER
export default towVehiclesModule.reducerConfig;

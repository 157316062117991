import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isFunction } from 'lodash';
import { UseOfForceSubject } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { removeUseOfForceSubject } from '~/client-common/core/domain/reports/state/ui/useOfForceSubjects';
import testIds from '../../../../../core/testIds';
import { RmsDispatch } from '../../../../../core/typings/redux';
import Modal from '../../../../core/overlays/components/Modal';
import useQuickAddCallbacks from '../../hooks/useQuickAddCallbacks';

interface DeleteUseOfForceSubjectModalProps {
    overlayId: string;
    reportId: number;
    useOfForceSubject: UseOfForceSubject;
}

const strings = componentStrings.reports.core.UseOfForceSubjectCard.deleteUseOfForceSubjectModal;

const DeleteUseOfForceSubjectModal: React.FC<DeleteUseOfForceSubjectModalProps> = ({
    reportId,
    useOfForceSubject,
    overlayId,
}) => {
    const { personQuickAddCallback } = useQuickAddCallbacks();
    const dispatch = useDispatch<RmsDispatch>();
    const handleSave = useCallback(
        () =>
            dispatch(removeUseOfForceSubject({ reportId, useOfForceSubject })).then(() => {
                if (isFunction(personQuickAddCallback)) {
                    personQuickAddCallback();
                }
            }),
        [dispatch, personQuickAddCallback, reportId, useOfForceSubject]
    );

    return (
        <Modal
            id={overlayId}
            okText={strings.confirm}
            onSave={handleSave}
            shouldCloseOnOverlayClick={true}
            testId={testIds.DELETE_USE_OF_FORCE_SUBJECT_MODAL}
            title={strings.confirmUseOfForceSubjectRemoval}
        >
            {strings.confirmationText}
        </Modal>
    );
};

export default DeleteUseOfForceSubjectModal;

import React from 'react';
import { ElasticReport, AttributeTypeEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ModuleShape } from '~/client-common/core/utils/createNormalizedModule';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

// Helpers
import { getViewModelProperties, ViewModel } from '~/client-common/helpers/viewModelHelpers';

// Selectors
import { reportShortTitleViewModelsSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { elasticReportViewModelsSelector } from '~/client-common/core/domain/elastic-reports/state/ui';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { caseStatusOptionsSelector } from '../state/ui';

// Components
import { OnlyWithAbility } from '../../../core/abilities';
import { MFTNItems } from '../../../core/forms/components/NItems';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTDatePicker } from '../../../core/forms/components/DatePicker';
import { ArbiterMFTBooleanButtonRadio } from '../../../core/forms/components/button-radios/BooleanButtonRadio';
import { ArbiterMFTUserSelect } from '../../../core/forms/components/selects/UserSelect';

import {
    modalHalfFormWidth,
    modalSmallFormWidth,
} from '../../../../legacy-redux/configs/formsConfig';

// TODO: This is temporary, until we move over
// the elastic-reports/state/ui file to ts
type ElasticReportViewModel = ViewModel<ElasticReport, { renWithShortTitle: string }>;

const ReportStatusHeaderWrapper = styled.div`
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-md);
    margin-bottom: 16px;
    font-family: proxima-nova, sans-serif;
    font-weight: 600;
`;

export const ReportStatusesMFTNItems: React.FC<{
    statusIsClosed: (caseStatusAttrId: number) => boolean;
    // @ts-expect-error CHI-1287
    setReportCaseStatusDefaultStatusDate: (form, index: number) => void;
    // @ts-expect-error CHI-1287
    form;
}> = ({ statusIsClosed, setReportCaseStatusDefaultStatusDate, form }) => {
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const reportShortTitleViewModels = useSelector(reportShortTitleViewModelsSelector);
    const elasticReportsViewModels: ModuleShape<ElasticReportViewModel> = useSelector(
        elasticReportViewModelsSelector
    );
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const renDisplayName = formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER);
    const caseStatusFilter = useSelector(caseStatusOptionsSelector);

    return (
        <MFTNItems
            addItemOnEmpty={false}
            path="reportCaseStatuses"
            renderRemoveButton={undefined}
            renderAddButton={undefined}
            render={({ item, index }) => {
                // during case creation, we have ReportShortTitles
                // @ts-expect-error CHI-1287
                const reportShortTitleViewModel = item && reportShortTitleViewModels[item.reportId];
                // @ts-expect-error CHI-1287
                const { shortTitleWithRen } = getViewModelProperties(reportShortTitleViewModel);

                // for existing cases, we have ElasticReports
                // @ts-expect-error CHI-1287
                const elasticReportsViewModel = item && elasticReportsViewModels[item.reportId];
                // @ts-expect-error CHI-1287
                const { renWithShortTitle } = getViewModelProperties(elasticReportsViewModel);

                // use whichever title we have
                const reportCaseStatusTitle = `${renDisplayName} ${
                    shortTitleWithRen || renWithShortTitle
                }`;
                const userHasEditReportCaseStatusAbility = currentUserHasAbility(
                    abilitiesEnum.REPORTING.EDIT_CASE_STATUS
                );
                const getIsCanceled = (index: number) => {
                    const reportCaseStatus = form.get(`reportCaseStatuses[${index}]`);
                    return reportCaseStatus?.isCanceled ?? false;
                };

                return (
                    <div>
                        <ReportStatusHeaderWrapper>
                            {reportCaseStatusTitle}
                        </ReportStatusHeaderWrapper>
                        <ArbiterMFTAttributeSelect
                            attributeType={AttributeTypeEnum.CASE_STATUS.name}
                            disabled={!userHasEditReportCaseStatusAbility || getIsCanceled(index)}
                            path="caseStatusAttrId"
                            width={modalSmallFormWidth}
                            filterOptions={caseStatusFilter}
                            onChange={(caseStatusAttrId: number) => {
                                if (statusIsClosed(caseStatusAttrId)) {
                                    setReportCaseStatusDefaultStatusDate(form, index);
                                }
                            }}
                        />
                        <OnlyWithAbility has={abilitiesEnum.REPORTING.CANCEL_CASE_STATUS}>
                            <ArbiterMFTBooleanButtonRadio
                                path="isCanceled"
                                onChange={(value: boolean) => {
                                    if (value === true) {
                                        form?.set(
                                            `reportCaseStatuses[${index}].caseStatusAttrId`,
                                            undefined
                                        );
                                    }
                                }}
                            />
                            <ArbiterMFTUserSelect
                                path="canceledById"
                                multiple={false}
                                length="lg"
                                effectiveDate={null}
                            />
                        </OnlyWithAbility>
                        {item && (
                            <div>
                                <ArbiterMFTAttributeSelect
                                    attributeType={AttributeTypeEnum.DIVISION.name}
                                    disabled={!userHasEditReportCaseStatusAbility}
                                    width={modalSmallFormWidth}
                                    path="closedByDivisionAttrId"
                                />
                                <ArbiterMFTAttributeSelect
                                    attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                                    disabled={!userHasEditReportCaseStatusAbility}
                                    width={modalSmallFormWidth}
                                    path="closedByUnitAttrId"
                                />
                                <ArbiterMFTDatePicker
                                    disabled={!userHasEditReportCaseStatusAbility}
                                    includeTime={true}
                                    width={modalHalfFormWidth}
                                    path="statusDateUtc"
                                />
                            </div>
                        )}
                    </div>
                );
            }}
        />
    );
};

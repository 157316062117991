import _, { get, parseInt } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { cadDesktopConfigsSelector } from '~/client-common/core/domain/cad-desktop-configs/state/data';
import {
    processHistoryEvent,
    processChangeSet,
} from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { currentDepartmentDateFormatsSelector } from '~/client-common/core/domain/current-user/state/ui';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { routeNameSelector } from '../../../../../routing/routerModule';
import routesConfig from '../../../../../routing/routesConfig';
import cadDesktopConfigsAdminForm from '../forms/cadDesktopConfigsAdminForm';
import {
    LOAD_CAD_DESKTOP_CONFIGS_START,
    LOAD_CAD_DESKTOP_CONFIGS_SUCCESS,
    LOAD_CAD_DESKTOP_CONFIGS_FAILURE,
    SAVE_CAD_DESKTOP_CONFIG_START,
    SAVE_CAD_DESKTOP_CONFIG_SUCCESS,
    SAVE_CAD_DESKTOP_CONFIG_FAILURE,
    DELETE_CAD_DESKTOP_CONFIG_START,
    DELETE_CAD_DESKTOP_CONFIG_SUCCESS,
    DELETE_CAD_DESKTOP_CONFIG_FAILURE,
    FETCH_CAD_DESKTOP_CONFIG_HISTORY_SUCCESS,
    FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_SUCCESS,
} from '../data';
import authActionTypes from '../../../../../legacy-redux/actions/types/authActionTypes';
import { formatUserByIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';

const { BOOTSTRAP_SUCCESS } = authActionTypes;
const SELECT_CAD_DESKTOP_CONFIG_START = 'cad-desktop-configs/SELECT_CAD_DESKTOP_CONFIG_START';
const SELECT_CAD_DESKTOP_CONFIG_SUCCESS = 'cad-desktop-configs/SELECT_CAD_DESKTOP_CONFIG_SUCCESS';
const SELECT_CAD_DESKTOP_CONFIG_FAILURE = 'cad-desktop-configs/SELECT_CAD_DESKTOP_CONFIG_FAILURE';
const OPEN_NEW_CAD_DESKTOP_CONFIG_FORM = 'cad-desktop-configs/OPEN_NEW_CAD_DESKTOP_CONFIG_FORM';

function selectCadDesktopConfigStart() {
    return {
        type: SELECT_CAD_DESKTOP_CONFIG_START,
    };
}

function selectCadDesktopConfigSuccess(id) {
    return {
        type: SELECT_CAD_DESKTOP_CONFIG_SUCCESS,
        payload: id,
    };
}

function selectCadDesktopConfigFailure() {
    return {
        type: SELECT_CAD_DESKTOP_CONFIG_FAILURE,
    };
}

export function selectCadDesktopConfig(id) {
    return (dispatch, getState) => {
        dispatch(selectCadDesktopConfigStart());
        const state = getState();
        const cadDesktopConfig = cadDesktopConfigsSelector(state)[id];

        if (cadDesktopConfig) {
            dispatch(cadDesktopConfigsAdminForm.actionCreators.change({ cadDesktopConfig }));
            dispatch(selectCadDesktopConfigSuccess(id));
        } else {
            // TODO: remove this crappy interval when migrating the router, this is
            // a workaround for onEnter/onLeave hooks not respecting parent/child relationships
            let attempts = 0;

            const intervalId = window.setInterval(() => {
                const state = getState();
                const cadDesktopConfig = cadDesktopConfigsSelector(state)[id];

                if (cadDesktopConfig) {
                    window.clearInterval(intervalId);
                    dispatch(
                        cadDesktopConfigsAdminForm.actionCreators.change({ cadDesktopConfig })
                    );
                    dispatch(selectCadDesktopConfigSuccess(id));
                }

                attempts++;
                if (attempts > 300) {
                    window.clearInterval(intervalId);

                    return dispatch(
                        selectCadDesktopConfigFailure('Failed to get cad desktop-config.')
                    );
                }
            }, 1000);
        }
    };
}

export function openNewCadDesktopConfigForm() {
    return {
        type: OPEN_NEW_CAD_DESKTOP_CONFIG_FORM,
    };
}

const initialUiState = {
    selectedCadDesktopConfigId: null,
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    cadDesktopConfigLoadError: null,
    histories: {},
    allHistories: [], // this is a stop-gap solution. Normally we would normalize all of these by desktop-config id or
    // but there is no consistent way to discern the desktop-config id in history objects.
};

export default function cadDesktopConfigsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case BOOTSTRAP_SUCCESS:
            return {
                ...state,
            };
        case FETCH_CAD_DESKTOP_CONFIG_HISTORY_SUCCESS:
            return {
                ...state,
                histories: {
                    ...state.histories,
                    [action.payload.desktopConfigId]: action.payload.histories,
                },
            };
        case FETCH_ALL_CAD_DESKTOP_CONFIGS_HISTORY_SUCCESS:
            return {
                ...state,
                allHistories: action.payload.histories,
            };
        case LOAD_CAD_DESKTOP_CONFIGS_START:
            return {
                ...state,
                loadingList: true,
                selectedCadDesktopConfigId: null,
                listError: null,
            };
        case LOAD_CAD_DESKTOP_CONFIGS_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_CAD_DESKTOP_CONFIGS_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case SAVE_CAD_DESKTOP_CONFIG_START:
            return {
                ...state,
                submittingForm: true,
            };
        case SAVE_CAD_DESKTOP_CONFIG_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case SAVE_CAD_DESKTOP_CONFIG_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case DELETE_CAD_DESKTOP_CONFIG_START:
            return {
                ...state,
                submittingForm: true,
            };
        case DELETE_CAD_DESKTOP_CONFIG_SUCCESS:
            return {
                ...state,
                formError: null,
                cadDesktopConfigLoadError: null,
                selectedCadDesktopConfigId: null,
                submittingForm: false,
            };
        case DELETE_CAD_DESKTOP_CONFIG_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_CAD_DESKTOP_CONFIG_SUCCESS:
            return {
                ...state,
                cadDesktopConfigLoadError: null,
                selectedCadDesktopConfigId: parseInt(action.payload),
            };
        case SELECT_CAD_DESKTOP_CONFIG_FAILURE:
            return {
                ...state,
                cadDesktopConfigLoadError: action.payload,
            };
        case OPEN_NEW_CAD_DESKTOP_CONFIG_FORM:
            return {
                ...state,
                formError: null,
                cadDesktopConfigLoadError: null,
                selectedCadDesktopConfigId: null,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.cadDesktopConfigsAdmin;

export const cadDesktopConfigViewModelByIdSelector = createSelector(
    cadDesktopConfigsSelector,
    (cadDesktopConfigs) => (id) =>
        cadDesktopConfigs[id]
            ? {
                  ...cadDesktopConfigs[id],
              }
            : undefined
);

// list of cad desktopConfigs
export const desktopConfigsListItemsSelector = createSelector(
    cadDesktopConfigsSelector,
    uiSelector,
    cadDesktopConfigViewModelByIdSelector,
    routeNameSelector,
    (cadDesktopConfigs, ui, cadDesktopConfigViewModelById, routeName) => {
        return _(cadDesktopConfigs)
            .map((desktopConfig) => {
                const historyView =
                    routeName === routesConfig.ADMIN_CAD_DESKTOP_CONFIGS_HISTORY.name ||
                    routeName === routesConfig.ADMIN_CAD_ALL_DESKTOP_CONFIGS_HISTORY.name;

                const path = `/admin/cad/desktop-configs/${desktopConfig.id}${
                    historyView ? '/history' : ''
                }`;
                return {
                    path,
                    title: `${desktopConfig.machineName ? desktopConfig.machineName : 'N/A'}-(${
                        desktopConfig.guid
                    })`,
                    key: desktopConfig.id,
                    status: get(cadDesktopConfigViewModelById(desktopConfig.id), 'status'),
                    selected: desktopConfig.id === ui.selectedCadDesktopConfigId,
                };
            })
            .sortBy('title')
            .value();
    }
);

export const historiesForCadDesktopConfigSelector = createSelector(
    uiSelector,
    formatAttributeByIdSelector,
    formatUserByIdSelector,
    getAttributeByIdSelector,
    currentDepartmentDateFormatsSelector,
    (ui, formatAttributeById, userById, getAttributeById, dateTimeFormats) => (id) => {
        const histories = id ? ui.histories[id] : ui.allHistories;

        const displayDate = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDate);
            }
            return '';
        };

        const displayDateTime = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDateTime);
            }
            return '';
        };

        const args = {
            formatAttributeById,
            userById,
            displayDate,
            displayDateTime,
            attributeById: getAttributeById,
        };

        return _.map(_.sortBy(histories, 'timestampUtc'), (history) => {
            const processed = processHistoryEvent(history, args);
            const newObj = {
                ...processed,
                user: userById(processed.changedBy),
                changes: _.map(processed.changeSet, (changeSet) =>
                    processChangeSet(changeSet, args, processed)
                ),
            };

            return newObj;
        }).reverse();
    }
);

export const hasImportExportDesktopConfigPermissions = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.CAD.GPS_CONFIG_IMPORT)
);

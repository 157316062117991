import { LocationSourceEnum, LocationSourceEnumType } from '@mark43/rms-api';

// This function convert the resolverSource to a generalized description of where we got the location. ie: Mark43, ESRI
// It's similar to the BE hepler here:
// cobalt-core-client/master-entity-client/src/main/java/com/mark43/core/master/model/locations/LocationSource.java
// We need this function since sometimes the BE can only pass up the resolverSource
// due to the objects we’re passing so FE will have to convert that resolverSource to source.
export function getDisplaySource(resolverSource: LocationSourceEnumType) {
    switch (resolverSource) {
        case LocationSourceEnum.STREET_CENTERLINE.name:
        case LocationSourceEnum.GEO_ALIAS.name:
        case LocationSourceEnum.GEO_ADDRESS_POINTS.name:
            return LocationSourceEnum.MARK43.name;
        case LocationSourceEnum.ESRI.name:
        case LocationSourceEnum.POSTCODER.name:
            return resolverSource;
        case LocationSourceEnum.ESRI_CUSTOM.name:
        case LocationSourceEnum.DC_MAR.name:
        case LocationSourceEnum.FORCED_LOCATION.name:
        case LocationSourceEnum.FORCED_SUB_PREMISES.name:
            return resolverSource.split('_').join(' ');
        case LocationSourceEnum.LAT_LONG.name:
            return 'FORCED LOCATION';
        case LocationSourceEnum.MARK43.name:
        case LocationSourceEnum.LEGACY_LOCATION.name:
        case LocationSourceEnum.DC_CAD.name:
        case LocationSourceEnum.MIGRATION_LOCATION.name:
        case LocationSourceEnum.INTEGRATION_LOCATION.name:
        case LocationSourceEnum.SMARTY_STREETS.name:
        case LocationSourceEnum.GOOGLE.name:
            // these are deprecated sources
            return;
        default:
            return;
    }
}

import React from 'react';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import createElement from '../../../core/utils/recompose.createElement';

import ImageCell from '../../../../legacy-redux/components/core/tables/elasticPersonTable/ImageCell';
import BiographicalInfoCell from '../../../../legacy-redux/components/core/tables/elasticPersonTable/BiographicalInfoCell';
import EntityProfileLinkIndicator from './EntityProfileLinkIndicator';

export default function EntityProfilePersonSummary({ elasticPerson }) {
    // use of `createElement` is due to the fact that all `Symbol` properties
    // of an object are lost when it's "copied" (via spread syntax). This is an intended
    // effect of spread syntax / for in
    return (
        <div>
            <div className="entity-profile-summary-sibling">
                {createElement(ImageCell, { ...elasticPerson })}
            </div>
            <div className="entity-profile-summary-sibling person-cell">
                {createElement(BiographicalInfoCell, { ...elasticPerson })}
                <EntityProfileLinkIndicator
                    linkTypes={getViewModelProperties(elasticPerson).linkTypes}
                />
            </div>
        </div>
    );
}

import { AttributeTypeEnum } from '@mark43/rms-api';
import { camelCase, reduce } from 'lodash';

// strings for attribute types
// TODO we should be using fields.ts instead of these...
// most have entries under DISPLAY_ATTRIBUTE_ and ones that don't should be added
// see https://mark43.atlassian.net/browse/RMS-3298
const attributeTypeStrings = {
    [AttributeTypeEnum.ARREST_TYPE.name]: 'Arrest Type',
    [AttributeTypeEnum.ARREST_TACTICS.name]: 'Tactics Used',
    [AttributeTypeEnum.ARRESTEE_WAS_ARMED_WITH.name]: 'Defendant Armed With',
    [AttributeTypeEnum.ARREST_STATUS.name]: 'Arrest Status',
    [AttributeTypeEnum.BEHAVIORAL_CHARACTERISTIC.name]: 'Behavioral Characteristics',
    [AttributeTypeEnum.BODY_PART.name]: 'Body Part',
    [AttributeTypeEnum.BUILD.name]: 'Build',
    [AttributeTypeEnum.CAD_EVENT_TYPE.name]: 'Call Type',
    [AttributeTypeEnum.CAD_SECONDARY_EVENT_TYPE.name]: 'Call Event Type',
    [AttributeTypeEnum.CASE_STATUS.name]: 'Case Status',
    [AttributeTypeEnum.CLOTHING_TYPE.name]: 'Clothing Type',
    [AttributeTypeEnum.CUSTOM_REPORT_CLASSIFICATION.name]: 'Incident / Offense Classification',
    [AttributeTypeEnum.DL_TYPE.name]: 'Drivers License Type',
    [AttributeTypeEnum.DRUGS_DATA_SOURCE.name]: 'Drugs Data Source',
    [AttributeTypeEnum.ETHNICITY.name]: 'Ethnicity',
    [AttributeTypeEnum.EVENT_STATISTICS.name]: 'Statistics',
    [AttributeTypeEnum.EVIDENCE_FACILITY.name]: 'Location Type',
    [AttributeTypeEnum.EYE_COLOR.name]: 'Eye Color',
    [AttributeTypeEnum.FACIAL_HAIR_TYPE.name]: 'Facial Hair',
    [AttributeTypeEnum.FIREARM_MAKE.name]: 'Firearm Make',
    [AttributeTypeEnum.HAIR_COLOR.name]: 'Hair Color',
    [AttributeTypeEnum.HAIR_STYLE.name]: 'Hair Style',
    [AttributeTypeEnum.IDENTIFYING_MARK_TYPE.name]: 'Type of Mark',
    [AttributeTypeEnum.INDUSTRY.name]: 'Industry',
    [AttributeTypeEnum.INJURY_CATEGORY.name]: 'Injury Type',
    [AttributeTypeEnum.ITEM_CATEGORY.name]: 'Property Category',
    [AttributeTypeEnum.ITEM_COLOR.name]: 'Primary Color',
    [AttributeTypeEnum.ITEM_IDENTIFIER_TYPE.name]: 'Identifier Type',
    [AttributeTypeEnum.ITEM_TYPE.name]: 'Property Type',
    [AttributeTypeEnum.LOCATION_CATEGORY.name]: 'Location Category',
    [AttributeTypeEnum.MISSING_PERSON_TYPE.name]: 'Missing Person Type',
    [AttributeTypeEnum.MISSING_PERSON_STATUS.name]: 'Missing Person Status',
    [AttributeTypeEnum.MODUS_OPERANDI.name]: 'Modus Operandi',
    [AttributeTypeEnum.MOOD.name]: 'Mood',
    [AttributeTypeEnum.NAME_IDENTIFIER_TYPE.name]: 'Identifier Type',
    [AttributeTypeEnum.OFFENSE_STATUS.name]: 'Offense-Level Status',
    [AttributeTypeEnum.OFFICER_ASSIST_TYPE.name]: 'Officer Assist Type',
    [AttributeTypeEnum.ORGANIZATION_TYPE.name]: 'Organization Type',
    [AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name]: 'Caution',
    [AttributeTypeEnum.PERSONNEL_UNIT.name]: 'Unit',
    [AttributeTypeEnum.PHYSICAL_CHARACTERISTIC.name]: 'Physical Characteristics',
    [AttributeTypeEnum.PROPERTY_LOSS.name]: 'Property Status',
    [AttributeTypeEnum.RACE.name]: 'Race',
    [AttributeTypeEnum.ROUTING_LABEL.name]: 'Labels',
    [AttributeTypeEnum.SEX.name]: 'Sex',
    [AttributeTypeEnum.STATE.name]: 'State',
    STORAGE_LOCATION: 'Location Type', // Not sure why this is not a BE Attribute Type
    [AttributeTypeEnum.SUPPLEMENT_TYPE.name]: 'Supplement Type',
    [AttributeTypeEnum.TOW_VEHICLE_REASON_FOR_TOW.name]: 'Reason for Tow',
    [AttributeTypeEnum.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT.name]:
        'Officer Use of Force to Subject',
    [AttributeTypeEnum.USE_OF_FORCE_REASON.name]: 'Type of Incident',
    [AttributeTypeEnum.USE_OF_FORCE_STATISTICS.name]: 'Officer Details',
    [AttributeTypeEnum.USE_OF_FORCE_SUBJECT_ACTIONS.name]: 'Subject Actions',
    [AttributeTypeEnum.USE_OF_FORCE_SUBJECT_DETAILS.name]: 'Subject Details',
    [AttributeTypeEnum.USE_OF_FORCE_SUBJECT_IMPAIRMENTS.name]: 'Subject Impairments',
    [AttributeTypeEnum.VEHICLE_BODY_STYLE.name]: 'Body Style',
    [AttributeTypeEnum.VEHICLE_MAKE.name]: 'Make',
    [AttributeTypeEnum.VEHICLE_MODEL.name]: 'Model',
    [AttributeTypeEnum.VISION.name]: 'Vision',
    [AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name]: 'Weapon/Force Involved',
    [AttributeTypeEnum.LEGACY_SYSTEM.name]: 'Legacy System',
    [AttributeTypeEnum.LEGACY_REPORT_TYPE.name]: 'Legacy Report Type',
    [AttributeTypeEnum.LEGACY_REPORT_SUB_TYPE.name]: 'Legacy Report Sub-Type',
    [AttributeTypeEnum.LEGACY_CLASSIFICATION_CODE.name]: 'Legacy Classification Code(s)',
    [AttributeTypeEnum.LEGACY_MIGRATION_STATISTIC.name]: 'Legacy Migration Statistics',
} as const;

// from the above object, set the same strings for field keys like `moodAttrId`
// and multiselect field keys like `moodAttrIds`
const attrIdStrings = reduce(
    attributeTypeStrings,
    (strings, string, attributeType) => ({
        ...strings,
        [`${camelCase(attributeType)}AttrId`]: string,
        [`${camelCase(attributeType)}AttrIds`]: string,
    }),
    {}
);

const customAttrIdStrings = {
    assistTypeAttrId: attributeTypeStrings.OFFICER_ASSIST_TYPE,
    bodyStyleAttrIds: attributeTypeStrings.VEHICLE_BODY_STYLE,
    callEventTypeAttrIds: attributeTypeStrings.CAD_EVENT_TYPE,
    callTypeAttrIds: attributeTypeStrings.CAD_SECONDARY_EVENT_TYPE,
    eventStatisticAttrIds: attributeTypeStrings.EVENT_STATISTICS,
    involvedOfficerIds: 'Involved Officer(s)',
    personLabelAttrIds: attributeTypeStrings.PERSON_LABEL_ATTRIBUTES,
    primaryColorAttrIds: attributeTypeStrings.ITEM_COLOR,
    primaryOfficerIds: 'Primary Officer(s)',
    propertyStatusAttrIds: attributeTypeStrings.PROPERTY_LOSS,
    subdivisionAttrIds: '', // always use selector to get field label!
} as const;

// strings that represent ranges with multiple fields
const rangeStrings = {
    AGE_RANGE: 'Age Range',
    DATE_RANGE: 'Date Range',
    DATE_TIME_RANGE: 'Date/Time Range',
    TIME_RANGE: 'Time Range',
    HEIGHT_RANGE: 'Height Range',
    VEHICLE_YEAR_RANGE: 'Vehicle Year',
    WEIGHT_RANGE: 'Weight Range',
    YEARS_OF_SERVICE_RANGE: 'Years of Service Range',
};

// strings for other fields
const otherStrings = {
    fileName: 'File Name',
    fileTextContentsKeywords: 'File Contents',
    linkedEntityTypes: 'Entity Types',
    administrativeAreaLevel1: 'State',
    approvalStatus: 'Approval Status',
    clientApprovalStatus: 'Approval Status',
    secondaryApprovalStatuses: 'Secondary Approval Status',
    hasSecondaryApprovalStatus: 'Has Secondary Approval Status?',
    arrestNumber: 'Arrest Number',
    arrestUcrCodes: 'Arrest UCR Classification',
    authorIds: 'Author(s)',
    reviewerIds: 'Reviewer(s)',
    agencyEventNumber: 'CAD Event #',
    country: 'Country',
    crossStreet1: 'Intersection Street 1',
    crossStreet2: 'Intersection Street 2',
    latitude: 'Latitude',
    longitude: 'Longitude',
    currentSubmissionOwnerIds: 'Report Owner',
    dateOfBirth: 'Date of Birth',
    isDead: 'Deceased',
    description: 'Description',
    dlNumber: 'Drivers License #',
    fbiUcn: 'FBI #',
    firstName: 'First Name',
    fuzzyMatchingEnabled: 'enable fuzzy matching',
    hasCaseStatus: 'Case Status',
    hasReport: 'Has Report',
    hasUcrCode: 'UCR Code',
    hasRoutingLabel: 'Labels',
    hasCustomReportClassificationAttrId: 'Incident / Offense Classification',
    hasOffenseCaseStatus: 'Offense Case Status',
    hasWarrants: 'Has Warrants',
    hasPotentialActiveWarrant: 'Potential Active Warrant(s)',
    identifier: 'Name/ID',
    mniValue: 'MNI #',
    idValue: 'Name/ID',
    involvedUnitCallSigns: 'Involved Unit #(s)',
    involvedUnitIds: 'Involved Unit #(s)',
    isJuvenile: 'Defendant Is Juvenile',
    lastName: 'Last Name',
    locality: 'City',
    narrative: 'Narrative',
    narrativeKeywords: 'Narrative',
    nickname: 'Nickname',
    offenseUcrCodes: 'Offense-Level Classification',
    phoneNumber: 'Phone Number',
    phoneNumbers: 'Phone Number',
    callerPhoneNumber: 'Caller Phone Number',
    priorityAttrIds: 'Priority',
    postalCode: 'Zip',
    primaryUnitCallSigns: 'Primary Unit #(s)',
    primaryUnitIds: 'Primary Unit #(s)',
    requiresReport: 'Requires Report',
    serialNumber: 'Serial #',
    ssn: 'SSN #',
    stateIdNumber: 'State ID',
    streetAddress: 'Street Address',
    subPremise: 'Apt, Unit, Ste',
    ucrCodes: 'UCR Classification',
    vinNumber: 'VIN #',
    tag: 'TAG #',
    yearOfManufacture: 'Year of Manufacture',
    callForServiceIds: 'Call For Service',
    eventOriginAttrIds: 'Call Origin',
    eventQueue: 'Call Type',
    registrationStateAttrIds: 'State',
};

/**
 * Field strings used as form labels or summary labels. This is useful over
 *   `componentStrings` for fields whose labels are unlikely to ever change
 *   across forms, like "First Name". Some form components such as
 *   `AttributeSelect` use these labels by default. There is no singular/plural
 *   distinction; some fields are assumed to be always plural, like "Behavioral
 *   Characteristics".
 * @type {Object}
 */
export default {
    ...attrIdStrings,
    ...customAttrIdStrings,
    ...otherStrings,
    attributeTypes: attributeTypeStrings,
    ranges: rangeStrings,
};

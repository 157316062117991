import _ from 'lodash';
import moment from 'moment';

import { StorageLocationView, Facility, ElasticStorageLocation } from '@mark43/evidence-api';
import { isExpired } from '../../../dates/utils/dateHelpers';
import { ModuleShape } from '../../../utils/createNormalizedModule';

export function formatStorageLocationNames(storageLocationNames: string[] = []) {
    return _.compact(storageLocationNames).join(' > ');
}

export function appendExpiredLabelIfExpired(displayName: string, expiredDateUtc?: string) {
    return moment(expiredDateUtc).isAfter(moment()) || expiredDateUtc === undefined
        ? displayName
        : `${displayName} (expired)`;
}

/**
 * Build a storage location view model.
 */
export function buildStorageLocationViewModel(
    storageLocation: Partial<StorageLocationView> = {},
    fullDisplayPath?: string
) {
    return {
        displayValue: storageLocation.displayValue,
        expiredDateUtc: storageLocation.expiredDateUtc,
        description: storageLocation.description,
        id: storageLocation.id,
        facilityId: storageLocation.facilityId,
        parentStorageLocationIds: storageLocation.parentStorageLocationIds,
        storageLocationType: storageLocation.storageLocationType,
        removedDateUtc: storageLocation.removedDateUtc,
        barcodeValue: storageLocation.barcodeValue,
        fullDisplayPath,
        isHighValue: storageLocation.isHighValue,
        isInHighValueContainer: storageLocation.isInHighValueContainer,
    };
}

export function buildDisplayStringForStorageLocation(
    storageLocation: StorageLocationView,
    storageLocations: ModuleShape<StorageLocationView>,
    facilities: ModuleShape<Facility>
) {
    if (!storageLocation) {
        return;
    }
    const facilityLocationName = _.get(facilities[storageLocation.facilityId], 'locationName');
    // the parentStorageLocationIds do NOT contain the current object
    const displayParts = storageLocation.parentStorageLocationIds
        ? _.map(
              storageLocation.parentStorageLocationIds.concat(storageLocation.id),
              (storageLocationId) => _.get(storageLocations[storageLocationId], 'displayValue')
          )
        : [];
    return formatStorageLocationNames([facilityLocationName, ...displayParts]);
}

/**
 * Build storage view models, keyed by storage location id.
 */
export function buildStorageLocationViewModels(
    storageLocations: ModuleShape<StorageLocationView>,
    facilities: ModuleShape<Facility>
) {
    const values = _(storageLocations)
        .mapValues((storageLocation) => {
            const fullDisplayPath = buildDisplayStringForStorageLocation(
                storageLocation,
                storageLocations,
                facilities
            );
            const viewModel = buildStorageLocationViewModel(storageLocation, fullDisplayPath);
            return viewModel;
        })
        .mapKeys('id')
        .value();
    return values;
}

/**
 * From all the given storage view models, filter for the sub-locations of the
 *   given facility/storage location at the given depth. The results are sorted
 *   by display value.
 *
 * For example, `findStorageLocationChildren(7)` gives the locations directly
 *   under facility 7. `findStorageLocationChildren(7, 4, 123)` gives the
 *   locations directly under location 123 that are depth 4, as in
 *   `subLocation3Id = 123` (storage location 123 must be of depth 3 for this
 *   call to make sense).
 */
export function findStorageLocationChildren(
    storageLocationViewModels: StorageLocationView[],
    facilityId: number,
    depth = 1,
    storageLocationId?: number
) {
    const now = new Date();
    const isRemoved = isExpired;
    const children = _(storageLocationViewModels)
        .filter({ facilityId })
        .filter(({ parentStorageLocationIds }) => {
            // We're finding the children of a storage location
            if (storageLocationId) {
                // the parent storage locations do not contain a facility or the selected storage location
                // the depth is the amount of things that have been highlighted including the current thing being highlighted
                return (
                    parentStorageLocationIds.length === depth - 1 &&
                    _.includes(parentStorageLocationIds, storageLocationId)
                );
                // we're finding the children of a facility
            } else {
                // already filtered by the facility id so all that's needed is
                // finding the top level ones
                return parentStorageLocationIds.length === 0;
            }
        })
        .reject(({ removedDateUtc }) => isRemoved(removedDateUtc, now))
        .sortBy('displayValue')
        .value();
    return children;
}

/**
 * Helper function to generate an ad hoc elastic storage data model from a regular storage location.
 *   The resulting `ElasticStorageLocation` model is not complete, the following properties are
 *   missing: barcodeValue, facilityTypeAttrDetail, storageLocationPermissions
 * @return  elasticStorageLocation data model
 */
export function convertStorageLocationToElastic(
    storageLocations: ModuleShape<StorageLocationView>,
    facilities: ModuleShape<Facility>,
    storageLocationId: number
): Omit<
    ElasticStorageLocation,
    | 'facilityTypeAttrDetail'
    | 'innerHits'
    | 'innerHitFields'
    | 'matchedQueries'
    | 'lastSyncedDateUtc'
    | 'score'
    | 'storageLocationPermissions'
> {
    const storageLocation = storageLocations[storageLocationId];
    const pickedPropsForElasticStorageLocation = _.pick(storageLocation, [
        'departmentId',
        'facilityId',
        'id',
        'rmsEventId',
        'storageLocationType',
        'updatedDateUtc',
    ]);
    const { facilityId, parentStorageLocationIds, displayValue } = storageLocation;
    const parents = _.map(parentStorageLocationIds, (id) =>
        _.get(storageLocations[id], 'displayValue')
    );
    const facility = facilities[facilityId] || {};

    return {
        ...pickedPropsForElasticStorageLocation,
        isFacility: false,
        facilityTypeAttrId: facility.locationTypeAttrId,
        parentLocationNames: [facility.locationName, ...parents, displayValue],
    };
}

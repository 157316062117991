import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatCardNameWithRecordNumberByReportIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { RMSArbiterProvider } from '../../../core/arbiter';
import formsRegistry from '../../../../core/formsRegistry';
import { createNItemsAdder, createNItemsRemover } from '../../core/utils/nItemsHelpers';
import withCard from '../../core/utils/withCard';
import {
    formName,
    TRAFFIC_CRASH_SUBJECTS_PATH,
    TRAFFIC_CRASH_LOCATION_PATH,
    TRAFFIC_CRASH_LOCATION_DESCRIPTION_FULL_PATH,
    TRAFFIC_CRASH_LOCATION_POSITION_ATTR_ID_FULL_PATH,
    getTrafficCrashForm,
    createTrafficCrashForm,
    buildTrafficCrashCardFormModel,
} from '../../core/state/forms/trafficCrashForm';
import { registerForm } from '../../core/state/ui';
import trafficCrashCard from '../../core/state/ui/trafficCrashCard';
import testIds from '../../../../core/testIds';
import { registerCard } from '../../core/utils/cardsRegistry';
import Card, { CardSection } from '../../../../legacy-redux/components/core/Card';
import NameSummaryViewWrapper from '../../../core/components/NameSummaryViewWrapper';
import { LocationSummaryViewWrapperWithFormFields } from '../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import TrafficCrashSummary from './TrafficCrashSummary';
import TrafficCrashPersonSummary from './TrafficCrashPersonSummary';
import TrafficCrashRoadwaySummarySection from './TrafficCrashRoadwaySummarySection';

const strings = componentStrings.reports.core.TrafficCrashCard;

class TrafficCrashCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            buildTrafficCrashCardFormModel,
            currentReportId: reportId,
        } = this.props;

        const form = createTrafficCrashForm({
            initialState: buildTrafficCrashCardFormModel({ reportId }),
            arbiter,
            formatFieldByName,
        });

        registerForm({ form });
        registerCard({
            cardModule: trafficCrashCard,
            onSave: this.onSave,
        });
    }

    componentWillUnmount() {
        formsRegistry.unregister(formName);
    }

    handleTrafficCrashSubjectAdd = (nameReportLink) => {
        return createNItemsAdder({
            getForm: getTrafficCrashForm,
            path: TRAFFIC_CRASH_SUBJECTS_PATH,
        })(nameReportLink);
    };

    handleTrafficCrashSubjectRemove = (nameReportLink) => {
        const { nameId } = nameReportLink;
        return createNItemsRemover({
            getForm: getTrafficCrashForm,
            path: TRAFFIC_CRASH_SUBJECTS_PATH,
        })({ nameId });
    };

    handleTrafficCrashLocationAdd = (location, locationEntityLinkModel) => {
        const form = getTrafficCrashForm();
        form.set(TRAFFIC_CRASH_LOCATION_PATH, locationEntityLinkModel);
    };

    handleTrafficCrashLocationRemove = () => {
        const form = getTrafficCrashForm();
        form.set(TRAFFIC_CRASH_LOCATION_PATH, undefined);
    };

    onEdit = () => {
        this.props.editCallback(() => this.props.onEdit());
    };

    onSaveProgress = () => {
        const form = getTrafficCrashForm();
        return this.props.onSaveProgress(form, {});
    };

    onSave = () => {
        const form = getTrafficCrashForm();
        return this.props.onSave(form);
    };

    render() {
        const {
            card = {},
            currentReportId: reportId,
            reportById,
            formatCardNameWithRecordNumberByReportId,
        } = this.props;
        const {
            anchor,
            summaryMode: cardSummaryMode,
            errorMessages,
            canEditReportCardStatus,
            saving,
        } = card;

        const cardTitle = formatCardNameWithRecordNumberByReportId(
            reportId,
            reportCardEnum.TRAFFIC_CRASH.id
        );
        const renForRecents = get(reportById(reportId), 'reportingEventNumber');

        return (
            <Card
                testId={testIds.TRAFFIC_CRASH_CARD}
                className={anchor}
                anchor={anchor}
                title={cardTitle}
                renderContent={(summaryMode) => {
                    return (
                        <>
                            {summaryMode && <TrafficCrashSummary reportId={reportId} />}
                            <CardSection
                                title={strings.subjectsSectionTitle}
                                testId={testIds.TRAFFIC_CRASH_SUBJECT_SECTION}
                            >
                                <NameSummaryViewWrapper
                                    renForRecents={renForRecents}
                                    summaryMode={summaryMode}
                                    addNameButtonText={strings.addSubjectButtonText}
                                    reportId={reportId}
                                    contextType={EntityTypeEnum.REPORT.name}
                                    contextId={reportId}
                                    parentEntityType={EntityTypeEnum.REPORT.name}
                                    parentId={reportId}
                                    linkType={LinkTypesEnum.SUBJECT_IN_TRAFFIC_CRASH}
                                    show={{ people: true }}
                                    onAddSuccess={this.handleTrafficCrashSubjectAdd}
                                    onRemoveSuccess={this.handleTrafficCrashSubjectRemove}
                                    renderAdditionalItem={({ nameLink, collapsed }) =>
                                        !collapsed && summaryMode ? (
                                            <TrafficCrashPersonSummary personId={nameLink.nameId} />
                                        ) : null
                                    }
                                />
                            </CardSection>
                            <ArbiterForm
                                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                                name={formName}
                                context={formName}
                                render={() => {
                                    return (
                                        <CardSection
                                            testId={testIds.TRAFFIC_CRASH_LOCATION_SECTION}
                                            fieldName={
                                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_TRAFFIC_CRASH_LOCATION_ID
                                            }
                                        >
                                            <LocationSummaryViewWrapperWithFormFields
                                                summaryMode={summaryMode}
                                                entityType={EntityTypeEnum.REPORT.name}
                                                entityId={reportId}
                                                linkType={LinkTypesEnum.LOCATION_OF_TRAFFIC_CRASH}
                                                locationDescriptionPath={() =>
                                                    TRAFFIC_CRASH_LOCATION_DESCRIPTION_FULL_PATH
                                                }
                                                locationPositionAttrIdPath={() =>
                                                    TRAFFIC_CRASH_LOCATION_POSITION_ATTR_ID_FULL_PATH
                                                }
                                                onLocationAdd={this.handleTrafficCrashLocationAdd}
                                                onLocationRemove={
                                                    this.handleTrafficCrashLocationRemove
                                                }
                                            />
                                        </CardSection>
                                    );
                                }}
                            />
                            {summaryMode && (
                                <TrafficCrashRoadwaySummarySection reportId={reportId} />
                            )}
                        </>
                    );
                }}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={cardSummaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                saving={saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class TrafficCrashCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => (
                    <TrafficCrashCard ref={this.setRef} {...this.props} arbiter={arbiter} />
                )}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatCardNameWithRecordNumberByReportId: formatCardNameWithRecordNumberByReportIdSelector,
    reportById: reportByIdSelector,
});

const mapDispatchToProps = {
    buildTrafficCrashCardFormModel,
};

export default compose(
    withCard(trafficCrashCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(TrafficCrashCardWrapper);

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'UCR Classification Resource',
        methods: {
            getUcrBundle(reportId) {
                return req({
                    method: 'GET',
                    url: `reports/ucr/${reportId}/bundle`,
                });
            },
            replaceUcrBundle(reportId, ucrBundle) {
                return req({
                    method: 'PUT',
                    url: `reports/ucr/${reportId}/bundle`,
                    data: ucrBundle,
                });
            },
            prefillUcrClassification(eventId) {
                return req({
                    method: 'GET',
                    url: `reports/ucr/${eventId}/ucr_classification/prefill`,
                });
            },
            prefillUcrOffenses(offenseIds) {
                return req({
                    method: 'GET',
                    url: 'reports/ucr/offense/prefill',
                    params: {
                        offense_ids: offenseIds,
                    },
                });
            },
            prefillUcrProperty(propertyStatusIds) {
                return req({
                    method: 'GET',
                    url: 'reports/ucr/property/prefill',
                    params: {
                        property_status_ids: propertyStatusIds,
                    },
                });
            },
            // UCR Offense Classification Offense Links
            replaceUcrOffenseCharges(reportId, ucrOffenseCharges) {
                return req({
                    method: 'PUT',
                    url: `reports/ucr/${reportId}/links`,
                    data: ucrOffenseCharges,
                });
            },
            replaceArrestUcrProperty(ucr) {
                return req({
                    method: 'PUT',
                    url: 'reports/ucr',
                    data: ucr,
                });
            },
        },
    });
}

/* eslint-disable-next-line import/no-anonymous-default-export */
export default () => resource;

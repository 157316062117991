/* eslint eqeqeq:0 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import styled from 'styled-components';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import CheckboxComponent from './Checkbox';

const { connectRRFInput } = reactReduxFormHelpers;

const check = {
    INDETERMINATE: 'INDETERMINATE',
};

const Indicator = styled.div`
    display: inline-block;
    background-color: ${(props) => props.theme.colors.brightBlue};
    color: ${(props) => props.theme.colors.white};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    border-radius: 4px;
    font-size: var(--arc-fontSizes-sm);
    padding: 2px 5px;
    margin-right: 5px;
    vertical-align: top;
`;

const StyledCheckboxTree = styled.div`
    margin-bottom: 0;
`;

export const CheckboxTreeGroup = styled.div`
    float: left;
    clear: both;
    margin-bottom: 10px;

    .mark43-form-field {
        margin-bottom: 0;
    }
`;

export const CheckboxTreeWrapper = styled.div`
    float: left;
    width: 100%;
    margin-bottom: 12px;

    &,
    * {
        box-sizing: border-box;
    }
`;

const TreeSubtree = styled.div`
    float: left;
    width: 100%;
    padding-left: 20px;
`;

export class Checkbox extends React.Component {
    render() {
        const subTree = _.map(this.props.children, (child) => {
            return (
                <Checkbox
                    allNodes={this.props.allNodes}
                    {...child}
                    key={child.value}
                    testId={this.props.testId}
                />
            );
        });

        const subTreeGroup = subTree.length > 0 ? <TreeSubtree>{subTree}</TreeSubtree> : '';
        return (
            <StyledCheckboxTree className="clearfix">
                <CheckboxTreeGroup className={classNames(this.props.checked)}>
                    <CheckboxComponent
                        testId={this.props.testId}
                        onChange={this._handleChange.bind(this)}
                        value={this.props.checked}
                        disabled={this.props.disabled}
                        label={
                            this.props.indicatorText ? (
                                <span>
                                    <Indicator>{this.props.indicatorText}</Indicator>{' '}
                                    {this.props.display}
                                </span>
                            ) : (
                                this.props.display
                            )
                        }
                        noteDisplay={this.props.noteDisplay}
                    />
                </CheckboxTreeGroup>
                {subTreeGroup}
            </StyledCheckboxTree>
        );
    }

    _handleChange() {
        let newValue = '';
        if (this.props.checked == false) {
            newValue = true;
        } else {
            newValue = false;
        }
        this.props.changeHandler(this.props.value, newValue, this.props.allNodes);
    }
}

// modified from answer to
// https://stackoverflow.com/questions/18017869/build-tree-array-from-flat-array-in-javascript
export const unflatten = (sortOptions, array, parent, tree) => {
    tree = typeof tree !== 'undefined' ? tree : [];
    parent = typeof parent !== 'undefined' ? parent : { value: null };

    const children = _(array)
        .filter((child) => {
            return child.parentValue == parent.value;
        })
        .sortBy(sortOptions)
        .value();

    if (!_.isEmpty(children)) {
        if (!parent.value) {
            tree = children;
        } else {
            parent['children'] = children;
        }
        _.each(children, (child) => {
            unflatten(sortOptions, array, child);
        });
    }
    return tree;
};

export const setCorrectChecks = (nodes, valueArray) => {
    let allChecked = true;
    let allUnchecked = true;

    _.forEach(nodes, (node) => {
        if (node.children) {
            const result = setCorrectChecks(node.children, valueArray);

            if (node.skipSubtree) {
                if (result != 0 || _.includes(valueArray, node.value)) {
                    allUnchecked = false;
                    node.checked = true;
                } else {
                    allChecked = false;
                    node.checked = false;
                }
            } else {
                if (result == 2) {
                    allUnchecked = false;
                    node.checked = true;
                } else if (result == 1) {
                    allChecked = false;
                    allUnchecked = false;
                    node.checked = check.INDETERMINATE;
                } else {
                    allChecked = false;
                    node.checked = false;
                }
            }
        } else {
            // this is the key moment where we check against the value array!
            if (_.includes(valueArray, node.value)) {
                allUnchecked = false;
                node.checked = true;
            } else {
                allChecked = false;
                node.checked = false;
            }
        }
    });
    if (allChecked) {
        return 2;
    } else if (allUnchecked) {
        return 0;
    }
    return 1;
};

const changeSingleNode = (node, newValue, valueArray) => {
    if (newValue) {
        valueArray.push(node.value);
    } else {
        _.remove(valueArray, (val) => val == node.value);
    }
};

const changeEntireSubtree = (parentNode, newValue, valueArray) => {
    if (parentNode.children) {
        if (parentNode.skipSubtree) {
            changeSingleNode(parentNode, newValue, valueArray);
            if (!newValue) {
                _.forEach(parentNode.children, (node) =>
                    changeEntireSubtree(node, newValue, valueArray)
                );
            }
        } else {
            _.forEach(parentNode.children, (node) =>
                changeEntireSubtree(node, newValue, valueArray)
            );
        }
    } else {
        changeSingleNode(parentNode, newValue, valueArray);
    }

    return valueArray;
};

const createDummyParentNodes = (oldNodes) => {
    const nodesByValue = _.keyBy(oldNodes, 'value');
    const dummyParents = _(oldNodes)
        .map('parentValue')
        .compact()
        .uniq()
        .reject((val) => nodesByValue[val])
        .map((val) => ({
            display: val,
            value: val,
        }))
        .value();
    return _.union(oldNodes, dummyParents);
};

/**
 * @param {Object}   props
 * @param {string}   [props.value]
 * @param {string}   [props.display]
 * @param {Function} [props.sortOptions] Function to sort options.  By default, options will
 * be sorted by `value`.
 */
class CheckboxTree extends React.Component {
    constructor(props) {
        super(props);
    }

    _handleChange(val, newValue, allNodes) {
        let result = _.cloneDeep(this.props.value) || []; // dont want to mutate props
        _.each(allNodes, (node) => {
            if (node.value == val) {
                const selected = newValue == true;
                result = changeEntireSubtree(node, selected, result);
            }
        });
        return this.props.onChange(result); // redux-forms api
    }

    renderTopLevelNodes = () => {
        if (!this.props.render) {
            const nodes = createDummyParentNodes(this.props.options);

            _.forEach(nodes, (node) => {
                node.changeHandler = this._handleChange.bind(this);
            });
            const tree = unflatten(this.props.sortOptions, nodes);
            setCorrectChecks(tree, this.props.value);
            return _.map(tree, (node) => {
                return (
                    <Checkbox
                        allNodes={nodes}
                        {...node}
                        key={node.value}
                        testId={this.props.testId}
                    />
                );
            });
        } else {
            return this.props.render({
                onChange: this._handleChange.bind(this),
                options: this.props.options,
                value: this.props.value,
            });
        }
    };

    render() {
        const topLevelNodes = this.renderTopLevelNodes();
        return (
            <CheckboxTreeWrapper
                className={classNames(this.props.className)}
                data-test-id={this.props.testId}
            >
                {topLevelNodes}
            </CheckboxTreeWrapper>
        );
    }
}

CheckboxTree.propTypes = {
    value: PropTypes.array,
    sortOptions: PropTypes.func,
};

CheckboxTree.defaultProps = {
    sortOptions: (option) => option.value,
};

export default CheckboxTree;

export const RRFCheckboxTree = connectRRFInput(CheckboxTree);

import _, { mapValues, merge, omit, keys, groupBy } from 'lodash';
import { normalize } from './dataHelpers';

export const groupRolesByRoleType = (roles) => groupBy(roles, (role) => role.roleType);

export const userRolesMappedByUserId = (roles) =>
    _(roles)
        .groupBy('userId')
        .mapValues((roles) => normalize(roles, 'roleId'))
        .value();

/**
 * Given a state that looks like:
 * {
 *   'userId1': {
 *     'roleId1': ...,
 *     'roleId2': ...
 *   },
 *   'userId2': ...
 * }
 *
 * and a userMap that looks like:
 *
 * {
 *   'userId1': {
 *     'roleId1': ...
 *   },
 *   'userId3': ...
 * }
 *
 * This method will do a deep merge of the state with each userId
 * present in userMap
 */
export const deepMergeUserRolesWithUserMap = (state, userMap) =>
    mapValues(state, (val, key) => merge(state[key], userMap[key]));

/**
 * Given a state that looks like:
 * {
 *   'userId1': {
 *     'roleId1': ...,
 *     'roleId2': ...
 *   },
 *   'userId2': ...
 * }
 *
 * and a userMap that looks like:
 *
 * {
 *   'userId1': {
 *     'roleId1': ...
 *   },
 *   'userId3': ...
 * }
 *
 * This method will do a deep removal from state for each userId and roleId combination
 * present in userMap
 */
export const deepRemoveUserRolesWithUserMap = (state, userMap) =>
    mapValues(state, (val, key) => omit(state[key], keys(userMap[key])));

import React, { useCallback, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { InjectedRouter, withRouter } from 'react-router';
import { flowRight, map, slice } from 'lodash';
import { ElasticWarrant } from '@mark43/rms-api';
import { useResource } from '~/client-common/core/hooks/useResource';
import {
    formatShortName,
    isUnknown,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';
import Link from '../../core/components/links/Link';
import personalDashboardResource from '../resources/personalDashboardResource';
import { MAX_MY_WARRANTS, MY_WARRANTS_INTERVAL } from '../configuration';
import { Button } from '../../core/components/Button';
import { NoDataBlock, ShowMoreButton, DashboardCard } from './DashboardSection';
import { DashboardRightCardsLoader } from './DashboardLoaders';
import { DashboardTable, DashboardTd, DashboardTr, DashboardTh } from './DashboardTable';

const strings = componentStrings.personalDashboard.MyWarrants;

const columns = [
    {
        key: 'person',
        title: 'Person Name',
    },
    {
        key: 'warrantNum',
        title: 'Warrant Number',
    },
    {
        key: 'warrantDate',
        title: 'Date of Warrant',
    },
    {
        key: 'status',
        title: 'Status',
    },
];

const renderHeader = () => {
    return (
        <DashboardTr>
            {map(columns, ({ title, key }) => {
                return <DashboardTh key={key}>{title}</DashboardTh>;
            })}
        </DashboardTr>
    );
};

const renderRow = (item: ElasticWarrant) => {
    const { id, subject, warrantNum, warrantIssuedDateUtc, warrantStatusAttrDetail } = item;
    // @ts-expect-error client-common to client RND-7529
    const shortName = formatShortName(subject);
    const status = warrantStatusAttrDetail?.displayValue;
    const link = subject
        ? // @ts-expect-error client-common to client RND-7529
          isUnknown(subject)
            ? undefined
            : `/profiles/persons/${subject.masterPersonId}`
        : undefined;
    return (
        <DashboardTr key={id}>
            <DashboardTd>
                <Link hoverOnUnderline to={link}>
                    {shortName}
                </Link>
            </DashboardTd>
            <DashboardTd>
                <Link to={`/warrants/${id}`}>{warrantNum}</Link>
            </DashboardTd>
            <DashboardTd>
                <FormattedDate
                    date={warrantIssuedDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                />
            </DashboardTd>
            <DashboardTd>{status}</DashboardTd>
        </DashboardTr>
    );
};

const _MyWarrants: React.FC<{
    router: InjectedRouter;
    hideLoadingBar: boolean;
}> = ({ router, hideLoadingBar }) => {
    const [myWarrants, setMyWarrants] = useState<ElasticWarrant[]>([]);
    const [warrantsToShow, setWarrantsToShow] = useState<number>(MY_WARRANTS_INTERVAL);

    // Will change call to personalDashboard.getUserWarrants() in REB-911
    const loadMyWarrants = useCallback(
        () => personalDashboardResource.getUserWarrants({ hideLoadingBar }),
        [hideLoadingBar]
    );

    const onResourceSuccess = useCallback(
        (warrants) => {
            setMyWarrants(warrants);
        },
        [setMyWarrants]
    );

    const showMoreWarrants = useCallback(() => {
        if (warrantsToShow >= MAX_MY_WARRANTS) {
            router.push('/warrants/dashboard');
        }
        setWarrantsToShow(warrantsToShow + MY_WARRANTS_INTERVAL);
    }, [router, warrantsToShow, setWarrantsToShow]);

    const myWarrantsToShow = slice(myWarrants, 0, warrantsToShow);

    const loadingState = useResource(loadMyWarrants, onResourceSuccess);

    const { isLoading, errorMessage } = loadingState;

    if (isLoading && myWarrants.length === 0) {
        return <DashboardRightCardsLoader />;
    }

    if (errorMessage) {
        return <NoDataBlock>{strings.loadError}</NoDataBlock>;
    }

    if (myWarrants.length === 0) {
        return <NoDataBlock>{strings.noResults}</NoDataBlock>;
    }

    return (
        <>
            <DashboardTable
                data={myWarrantsToShow}
                renderRow={renderRow}
                renderHeader={renderHeader}
            />
            {(myWarrantsToShow.length < myWarrants.length ||
                myWarrants.length >= MAX_MY_WARRANTS) && (
                <ShowMoreButton isSticky onClick={showMoreWarrants}>
                    {strings.showMore}
                </ShowMoreButton>
            )}
        </>
    );
};

const MyWarrantsContent = flowRight(withRouter)(_MyWarrants);

export const MyWarrants: React.FC<{
    dragHandleProps: DraggableProvidedDragHandleProps;
    isDragging: boolean;
}> = ({ dragHandleProps, isDragging }) => {
    return (
        <DashboardCard
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
            actions={
                <Button isTextTransformNone variant="ghost" asLink to="/warrants/dashboard">
                    {strings.viewAll}
                </Button>
            }
            title={strings.title}
            helpText={strings.helpText}
        >
            <MyWarrantsContent hideLoadingBar={true} />
        </DashboardCard>
    );
};

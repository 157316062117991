import React, { createContext, useContext, useRef, useMemo, useCallback } from 'react';

type AssetCache = Record<string, string | undefined>;

type CachedAssetType = 'SVG_DATA_URL' | 'SVG';

type AddToAssetCacheArgs = {
    key: string;
    value: string;
    type: CachedAssetType;
    replace?: boolean;
};

type GetOrRemoveAssetFromCacheArgs = {
    key: string;
    type: CachedAssetType;
};

export const CRASH_DIAGRAM_ASSET_KEY = 'CRASH_DIAGRAM';

type AssetCacheContextType = {
    getAsset: (args: GetOrRemoveAssetFromCacheArgs) => string | undefined;
    addAsset: (args: AddToAssetCacheArgs) => void;
    removeAsset: (args: GetOrRemoveAssetFromCacheArgs) => void;
};

const AssetCacheContext = createContext<AssetCacheContextType | undefined>(undefined);

const createSvgDataUrlKey = (identifier: string) => {
    return `SVG_DATA_URL${identifier}`;
};

const createSVGContentsAssetKey = (identifier: string) => {
    return `SVG_${identifier}`;
};

export function useAssetCache(): AssetCacheContextType {
    const context = useContext(AssetCacheContext);

    if (context === undefined) {
        throw new Error('Asset Cache must be inside an AssetCacheProvider with a value');
    }
    return context;
}

export const AssetCacheProvider: React.FC = ({ children }) => {
    const assetCache = useRef<AssetCache>({});

    const addAsset = useCallback(
        ({ key, value, type, replace }: AddToAssetCacheArgs) => {
            const assetKey =
                type === 'SVG_DATA_URL' ? createSvgDataUrlKey(key) : createSVGContentsAssetKey(key);

            if (!assetCache.current[assetKey] || replace) {
                assetCache.current[assetKey] = value;
            }
        },
        [assetCache]
    );

    const removeAsset = useCallback(
        ({ key, type }: GetOrRemoveAssetFromCacheArgs) => {
            const assetKey =
                type === 'SVG_DATA_URL' ? createSvgDataUrlKey(key) : createSVGContentsAssetKey(key);
            if (assetCache.current[assetKey]) {
                delete assetCache.current[assetKey];
            }
        },
        [assetCache]
    );

    const getAsset = useCallback(
        ({ key, type }: GetOrRemoveAssetFromCacheArgs) => {
            const assetKey =
                type === 'SVG_DATA_URL' ? createSvgDataUrlKey(key) : createSVGContentsAssetKey(key);
            return assetCache.current[assetKey];
        },
        [assetCache]
    );

    const value = useMemo(() => {
        return {
            getAsset,
            addAsset,
            removeAsset,
        };
    }, [getAsset, addAsset, removeAsset]);

    return <AssetCacheContext.Provider value={value}>{children}</AssetCacheContext.Provider>;
};

import { AttributeTypeEnum } from '@mark43/rms-api';
import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { lifecycleOptions, formEvents } from 'markformythree';

import Promise from 'bluebird';
import { isWarrantModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { warrantByIdSelector } from '~/client-common/core/domain/warrants/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    createStubWarrant,
    editStubWarrant,
} from '../../../state/ui/arrest-charges-side-panel/stubWarrant';
import {
    formName,
    getFormConfiguration,
} from '../../../state/forms/arrest-charges-side-panel/stubWarrantForm';
import formsRegistry from '../../../../../../core/formsRegistry';
import ArbiterForm from '../../../../../core/markformythree-arbiter/ArbiterForm';
import Row from '../../../../../core/components/Row';
import IndentedFields from '../../../../../core/components/IndentedFields';
import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTUserSelect } from '../../../../../core/forms/components/selects/UserSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTTextArea } from '../../../../../core/forms/components/TextArea';
import FocusWrapper from '../../../../../core/components/FocusWrapper';
import { convertChargeWarrantToFormModel } from '../../../state/ui/arrest-charges-side-panel/convertChargeWarrants';

const strings = componentStrings.reports.core.ArrestChargesSidePanel.StubWarrantScreen;

export const unregisterStubWarrantScreenForm = () => formsRegistry.unregister(formName);

export const onSaveSidePanel = ({ isEditingStubWarrant }) => (dispatch) => {
    const form = formsRegistry.get(formName);
    const { formErrors, success } = form.validate({ eventType: formEvents.FORM_SUBMIT });

    if (isEmpty(formErrors) && success) {
        return isEditingStubWarrant
            ? dispatch(editStubWarrant({ form })).then((updatedStubWarrant) => {
                  unregisterStubWarrantScreenForm();
                  return updatedStubWarrant;
              })
            : dispatch(createStubWarrant({ form })).then((createdStubWarrant) => {
                  unregisterStubWarrantScreenForm();
                  return createdStubWarrant;
              });
    }
    return Promise.reject(formErrors);
};

export const onCancelSidePanel = unregisterStubWarrantScreenForm;

const StubWarrantScreen = connect(
    createStructuredSelector({
        isWarrantModuleActive: isWarrantModuleActiveSelector,
        warrantById: warrantByIdSelector,
    })
)(
    ({
        isWarrantModuleActive,
        warrantById,
        screenState: {
            isPerformingAsyncAction,
            editStubWarrantIdPrefill,
            isOtherJurisdictionPrefill,
        },
    }) => {
        let initialState = warrantById(editStubWarrantIdPrefill);

        // when isOtherJurisdictionPrefill is true or false, always prefill this value into the
        // isOtherJurisdiction field, even when editing a stub warrant which was previously saved
        // with a different value
        const isOtherJurisdictionPrefilled = typeof isOtherJurisdictionPrefill === 'boolean';
        if (isOtherJurisdictionPrefilled) {
            initialState = {
                ...initialState,
                isOtherJurisdiction: isOtherJurisdictionPrefill,
            };
        }
        const initialFormModelState = convertChargeWarrantToFormModel({ warrant: initialState });

        return (
            <FocusWrapper>
                <ArbiterForm
                    lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                    name={formName}
                    context={formName}
                    initialState={initialFormModelState}
                    configuration={getFormConfiguration()}
                    render={() => {
                        return (
                            <>
                                {isWarrantModuleActive && (
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            path="warrantTypeAttrId"
                                            length="md"
                                            attributeType={AttributeTypeEnum.WARRANT_TYPE.name}
                                            disabled={isPerformingAsyncAction}
                                        />
                                    </Row>
                                )}
                                <Row>
                                    <ArbiterMFTText path="warrantNumber" length="md" />
                                    <ArbiterMFTText path="reportingEventNumber" length="md" />
                                </Row>
                                <Row>
                                    <ArbiterMFTTextArea path="description" length="lg" rows={3} />
                                </Row>
                                {isWarrantModuleActive && (
                                    <>
                                        <Row>
                                            <ArbiterMFTUserSelect
                                                path="obtainingOfficerUserId"
                                                includeOther={true}
                                                length="md"
                                                disabled={isPerformingAsyncAction}
                                            />
                                        </Row>
                                        <Row>
                                            <IndentedFields>
                                                <ArbiterMFTText
                                                    path="obtainingOfficerOther"
                                                    length="md"
                                                />
                                            </IndentedFields>
                                        </Row>
                                        <Row>
                                            <ArbiterMFTDatePicker path="warrantIssuedDateUtc" />
                                        </Row>
                                        <Row>
                                            <ArbiterMFTText path="issuingJudge" length="md" />
                                        </Row>
                                        <Row>
                                            <ArbiterMFTText path="bailAmount" length="md" />
                                        </Row>
                                    </>
                                )}
                                <Row>
                                    <ArbiterMFTBooleanSelect
                                        path="isOtherJurisdiction"
                                        disabled={isOtherJurisdictionPrefilled}
                                        helpText={
                                            isOtherJurisdictionPrefill === true
                                                ? strings.isOtherJurisdictionDisabledHelpText
                                                : undefined
                                        }
                                        length="md"
                                    />
                                </Row>
                                <Row>
                                    <ArbiterMFTText path="issuingAgencyOri" length="md" />
                                </Row>
                                <Row>
                                    <ArbiterMFTText path="issuingAgencyName" length="md" />
                                </Row>
                            </>
                        );
                    }}
                />
            </FocusWrapper>
        );
    }
);

export default StubWarrantScreen;

import { EntityTypeEnum } from '@mark43/rms-api';
import { map, flatMap, reject } from 'lodash';
import { NEXUS_STATE_PROP as NAME_NAME_LINKS_NEXUS_STATE_PROP } from '../../../name-name-links/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

export function replaceRelationshipsCardBundle(reportId, relationshipsCardBundle) {
    return function (dispatch, getState, { nexus }) {
        const resource = getReportCardBundleResource();
        return resource
            .upsertRelationshipsCard(reportId, relationshipsCardBundle)
            .then((updatedRelationshipsCardBundle) => {
                // Relationships will contain people and organizations, but we don't want
                // to store these into `dataNexus` because the BE has not modified them
                //
                // We purely need them to figure out which existing links to remove from `dataNexus`
                const names = [
                    ...map(updatedRelationshipsCardBundle.personProfiles, ({ id }) => ({
                        id,
                        entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    })),
                    ...map(updatedRelationshipsCardBundle.organizationProfiles, ({ id }) => ({
                        id,
                        entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                    })),
                ];
                const nameNameLinksToRemove = flatMap(
                    names,
                    ({ id: nameFromId, entityType: entityTypeFrom }) => {
                        return map(
                            // Don't pair a name up with itself though
                            reject(names, { id: nameFromId, entityType: entityTypeFrom }),
                            ({ id: nameToId, entityType: entityTypeTo }) => {
                                return { nameToId, nameFromId, entityTypeTo, entityTypeFrom };
                            }
                        );
                    }
                );
                dispatch(
                    // Since we get back the `nameNameLinks` for all the names
                    // that we've sent down, we need to clear out any existing `nameNameLinks`
                    nexus.withRemove(
                        NAME_NAME_LINKS_NEXUS_STATE_PROP,
                        nameNameLinksToRemove,
                        nexus.withEntityItems(
                            {
                                [NAME_NAME_LINKS_NEXUS_STATE_PROP]:
                                    updatedRelationshipsCardBundle.nameNameLinks,
                            },
                            {
                                type: 'UPSERT_RELATIONSHIPS_CARD_BUNDLE',
                            }
                        )
                    )
                );

                return updatedRelationshipsCardBundle;
            });
    };
}

import React from 'react';
import _, { isEmpty } from 'lodash';
import { CourtOrderCellProps } from './CourtOrderCellProps';

export default function AttachmentCell({ attachments }: CourtOrderCellProps) {
    return (
        <div>
            {!isEmpty(attachments) &&
                _(attachments)
                    .map(({ fileName }) => fileName)
                    .join(', ')}
        </div>
    );
}

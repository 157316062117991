import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/print';

export default createResource({
    name: 'Evidence Export Resource',
    methods: {
        /**
         * Export evidence items.
         * @param  {Object[]} evidenceSearchWorkOrders
         * @param  {number} evidenceSearchWorkOrder.printingTemplateId
         * @param  {string} evidenceSearchWorkOrder.title
         * @param  {string} evidenceSearchWorkOrder.dataType
         * @param  {Object} evidenceSearchWorkOrder.search ElasticQuery
         * @param  {number[]} evidenceSearchWorkOrder.csvExportColumnIds
         * @return {Promise<Object[]>}
         */
        export(evidenceSearchWorkOrders) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: BASE_PATH,
                data: evidenceSearchWorkOrders,
            });
        },
    },
});

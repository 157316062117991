import _, { compact } from 'lodash';
import { LinkTypesEnum, LinkTypesEnumType, RefContextEnum } from '@mark43/rms-api';
import { _Form, formEvents } from 'markformythree';
import {
    formatFieldByNameSelector,
    FormatFieldByName,
} from '~/client-common/core/fields/state/config';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { NibrsOffenseCode } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { MFTFormsRegistry } from '../../../../core/formsRegistry';
import { RmsAction, RmsDispatch } from '../../../../core/typings/redux';
import { SidePanelLinkingError } from '../../../../legacy-redux/components/core/CardHeaderErrorsSection';
import createArbiterMFTValidationHandler from '../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import { ArbiterInstance } from '../../../core/markformythree-arbiter/ArbiterForm';
import { createRMSArbiterInstance } from '../../../core/arbiter';
import mftArbiterValidationEvents from '../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import { getFormContextForLinkType } from '../../../core/persons/utils/getFormContextForLinkType';
import {
    PersonProfileFormDataShape,
    createPersonProfileFormConfiguration,
} from '../../../core/persons/state/forms/createPersonProfileFormConfiguration';
import { refreshPersonProfileForm } from '../../../core/persons/state/forms/personProfileForm';

interface ValidatePersonFormPropsT {
    arbiterInstance: ArbiterInstance;
    formatFieldByName: FormatFieldByName;
    offenseFormIndex: number;
    formModel: PersonProfileFormDataShape;
    formsRegistry: MFTFormsRegistry;
    linkType: LinkTypesEnumType;
    linkTypeSequenceNumber: number;
    personProfileId: number;
}

interface AdditionalFormToValidate {
    id: number;
    linkType:
        | typeof LinkTypesEnum.VICTIM_IN_OFFENSE
        | typeof LinkTypesEnum.SUSPECT_IN_OFFENSE
        | typeof LinkTypesEnum.OFFENDER_IN_OFFENSE
        | typeof LinkTypesEnum.DEFENDANT_IN_ARREST;
    linkTypeSequenceNumber: number;
    nibrsOffenseCode: NibrsOffenseCode;
}

interface ValidateLinkedFormsPropsT {
    additionalFormsToValidate: AdditionalFormToValidate[];
    offenseFormIndex: number;
}

type ArbiterInstances = {
    FORM_PERSON_SUSPECT: ArbiterInstance;
    FORM_PERSON_OFFENDER: ArbiterInstance;
    FORM_PERSON_VICTIM: ArbiterInstance;
    FORM_PERSON_DEFENDANT: ArbiterInstance;
};

const nameDisplayOrUnknown = ({
    firstName,
    firstNameUnknown,
    lastName,
    lastNameUnknown,
}: {
    firstName: string | undefined;
    firstNameUnknown: boolean | undefined;
    lastName: string | undefined;
    lastNameUnknown: boolean | undefined;
}) =>
    `${firstNameUnknown || !firstName ? 'UNKNOWN' : firstName} ${
        lastNameUnknown || !lastName ? 'UNKNOWN' : lastName
    }`;

const validatePersonForm: (
    props: ValidatePersonFormPropsT
) => SidePanelLinkingError[] | undefined = ({
    arbiterInstance,
    formatFieldByName,
    offenseFormIndex,
    formModel,
    formsRegistry,
    linkType,
    linkTypeSequenceNumber,
    personProfileId,
}) => {
    const formConfiguration = createPersonProfileFormConfiguration();
    const formContext = getFormContextForLinkType(linkType);
    formsRegistry.register(
        // @ts-expect-error Property 'id' is incompatible with index signature.
        // Type 'number' is not assignable to type 'MFTFieldConfigurationBase | InferFieldsetOrNitems<MFTFieldsetConfiguration> | InferFieldsetOrNitems<MFTNItemsConfiguration>[] | undefined'.ts(2345)
        new _Form({
            name: formContext,
            configuration: formConfiguration,
            initialState: formModel,
            onValidate: createArbiterMFTValidationHandler(
                arbiterInstance,
                formContext,
                formatFieldByName
            ),
            validationEvents: mftArbiterValidationEvents,
        }),
        personProfileId
    );

    const form = formsRegistry.get(formContext, personProfileId);
    if (!form) {
        return undefined;
    }
    const validationObject = form.validate({ eventType: formEvents.FORM_SUBMIT, path: '' });
    formsRegistry.unregister(form.getName(), personProfileId);
    // validationObject should never be a Promise, this is just to deal with typescript
    if (!validationObject || validationObject instanceof Promise || validationObject?.success) {
        return undefined;
    }
    const { firstName, firstNameUnknown, lastName, lastNameUnknown } = formModel;
    const linkDisplay = nameDisplayOrUnknown({
        firstName,
        firstNameUnknown,
        lastName,
        lastNameUnknown,
    });

    const overlayId =
        linkType === LinkTypesEnum.DEFENDANT_IN_ARREST
            ? `${overlayIdEnum.PERSON_OVERLAY_DEFENDANT_SECTION}.${linkType}`
            : `${overlayIdEnum.PERSON_OVERLAY_OFFENSE_CARD}.${offenseFormIndex}.${linkType}.${
                  linkTypeSequenceNumber - 1
              }`;

    const errorMessages = validationObject.formErrors?.map((formError: string) => ({
        formErrors: [...validationObject.formErrors],
        linkDisplay,
        message: formError,
        overlayId,
        personProfileId,
    }));
    return errorMessages;
};

const mapAdditionalFormsToValidate = (
    additionalFormsToValidate: AdditionalFormToValidate[],
    arbiterInstances: ArbiterInstances,
    dispatch: RmsDispatch,
    formatFieldByName: FormatFieldByName,
    offenseFormIndex: number,
    formsRegistry: MFTFormsRegistry
) =>
    compact(
        additionalFormsToValidate.map(
            ({ id, linkType, linkTypeSequenceNumber, nibrsOffenseCode }) => {
                if (!linkType) {
                    return undefined;
                }
                const formContext = getFormContextForLinkType(linkType);
                const formModel = dispatch(
                    refreshPersonProfileForm({
                        personProfileId: id,
                        personType: undefined,
                        nibrsOffenseCode,
                        ownerEntityType: undefined,
                        ownerEntityId: undefined,
                        cadProfileId: undefined,
                    })
                );
                if (
                    formContext !== RefContextEnum.FORM_PERSON_VICTIM.name &&
                    formContext !== RefContextEnum.FORM_PERSON_SUSPECT.name &&
                    formContext !== RefContextEnum.FORM_PERSON_OFFENDER.name &&
                    formContext !== RefContextEnum.FORM_PERSON_DEFENDANT.name
                ) {
                    return undefined;
                }
                const arbiterInstance = arbiterInstances[formContext];

                return {
                    arbiterInstance,
                    formatFieldByName,
                    offenseFormIndex,
                    formModel,
                    formsRegistry,
                    linkType,
                    linkTypeSequenceNumber,
                    personProfileId: id,
                };
            }
        )
    );

export const validateLinkedPersonForms: (
    props: ValidateLinkedFormsPropsT
) => RmsAction<SidePanelLinkingError[]> = ({ additionalFormsToValidate, offenseFormIndex }) => (
    dispatch,
    getState,
    dependencies
) => {
    const state = getState();
    const formatFieldByName = formatFieldByNameSelector(state);
    const suspectArbiterInstance: ArbiterInstance = createRMSArbiterInstance(
        getState,
        RefContextEnum.FORM_PERSON_SUSPECT.name
    );
    const offenderArbiterInstance: ArbiterInstance = createRMSArbiterInstance(
        getState,
        RefContextEnum.FORM_PERSON_OFFENDER.name
    );
    const victimArbiterInstance: ArbiterInstance = createRMSArbiterInstance(
        getState,
        RefContextEnum.FORM_PERSON_VICTIM.name
    );
    const defendantArbiterInstance: ArbiterInstance = createRMSArbiterInstance(
        getState,
        RefContextEnum.FORM_PERSON_DEFENDANT.name
    );
    const arbiterInstances: ArbiterInstances = {
        [RefContextEnum.FORM_PERSON_SUSPECT.name]: suspectArbiterInstance,
        [RefContextEnum.FORM_PERSON_OFFENDER.name]: offenderArbiterInstance,
        [RefContextEnum.FORM_PERSON_VICTIM.name]: victimArbiterInstance,
        [RefContextEnum.FORM_PERSON_DEFENDANT.name]: defendantArbiterInstance,
    };
    const formsRegistry = dependencies.formsRegistry;

    const personFormsToValidate = mapAdditionalFormsToValidate(
        additionalFormsToValidate,
        arbiterInstances,
        dispatch,
        formatFieldByName,
        offenseFormIndex,
        formsRegistry
    );
    return (
        _(personFormsToValidate)
            .flatMap((personFormToValidate) => {
                return validatePersonForm(personFormToValidate);
            })
            .compact()
            // we may have rules and persons that exist in multiple contexts
            // only show one error message for these
            // uniqBy keeps the first by array position
            // prefer victim error for context of side panel link
            // because victims have the most side panel validations
            .uniqBy((errorObject) => `${errorObject.personProfileId}-${errorObject.message}`)
            .value()
    );
};

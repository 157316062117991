import { reject, forEach, map, reduce, get, flatMap, isFunction, uniq } from 'lodash';
import { actions as rrfActions } from 'react-redux-form';
import { formDataIsEmpty } from '~/client-common/helpers/formHelpers';
import { validateFieldRRF, getRules, validatePanelSystemRulesRRF } from './rrfValidationRunner';

export function runCardValidation(context) {
    return (dispatch, getState) => {
        const state = getState();
        const systemRulesWithoutFields = reject(getRules(context, state).systemRules, 'fieldName');
        const systemRuleFailures = validatePanelSystemRulesRRF(systemRulesWithoutFields, {}, state);
        const panelErrors = map(systemRuleFailures, 'panel');
        return panelErrors;
    };
}

/**
 * Evaluate the validity of a markformythree form using our system rules validation system
 */
export function runValidationMFT(context, formModel, fieldMap) {
    return (dispatch, getState) => {
        let success = true;
        const state = getState();

        const systemRulesWithoutFields = reject(getRules(context, state).systemRules, 'fieldName');

        const systemRuleFailures = validatePanelSystemRulesRRF(
            systemRulesWithoutFields,
            formModel,
            state
        );

        const formErrors = map(systemRuleFailures, 'panel');

        if (formErrors.length > 0) {
            success = false;
        }

        const doObj = {};
        forEach(fieldMap, (fieldData, fieldPath) => {
            const errors =
                fieldData.configuration && fieldData.configuration.fieldName
                    ? validateFieldRRF(
                          context,
                          formModel,
                          fieldData.configuration.fieldName,
                          fieldPath,
                          state
                      )
                    : null;

            if (errors && errors.panel) {
                success = false;
                formErrors.push(errors.panel);
            }
            if (errors && errors.field) {
                success = false;
                doObj[fieldPath] = {
                    errors: {
                        [errors.field]: errors.field,
                    },
                };
            }
            // The best I can come up with for a duck type check on "no errors"
            if (
                errors &&
                (!errors.panel || errors.panel.length === 0) &&
                (!errors.field || errors.field.length === 0)
            ) {
                doObj[fieldPath] = {
                    valid: true,
                };
            }
        });

        return {
            success,
            formErrors: uniq(formErrors),
            do: doObj,
        };
    };
}

/**
 * This action ensures that all rules for a given context are run, including system rules
 *   which do not have an associated fieldName.
 */
export function runValidationRRF(context, formModel, fieldNames, formModelPath) {
    return (dispatch, getState) => {
        const state = getState();

        // dynamic validation context
        const systemRulesWithoutFieldsContext = isFunction(context)
            ? context(formModel, null, state)
            : context;

        const systemRulesWithoutFields = reject(
            getRules(systemRulesWithoutFieldsContext, state).systemRules,
            'fieldName'
        );
        const systemRuleFailures = validatePanelSystemRulesRRF(
            systemRulesWithoutFields,
            formModel,
            state
        );
        const panelErrors = map(systemRuleFailures, 'panel');
        forEach(
            buildAllFieldPathsFromFormModelByFieldName(formModel, fieldNames),
            (relativePaths, fieldName) => {
                forEach(relativePaths, (relativePath) => {
                    const modelPath = `${formModelPath}.${relativePath}`;
                    const errorMessage = dispatch(
                        runSingleFieldValidationRRF(
                            context,
                            formModel,
                            fieldName,
                            modelPath,
                            relativePath
                        )
                    );
                    if (errorMessage && errorMessage.panel) {
                        panelErrors.push(errorMessage.panel);
                    }
                });
            }
        );
        return panelErrors;
    };
}

function buildAllFieldPathsFromFormModelByFieldName(formModel, fieldNames) {
    return reduce(
        fieldNames,
        (acc, fieldName, relativePath) => {
            const fieldNames = uniq([
                ...(acc[fieldName] || []),
                ...buildFieldNamesFromFormModel(formModel, relativePath, ''),
            ]);

            return {
                ...acc,
                [fieldName]: fieldNames,
            };
        },
        {}
    );
}

function buildFieldNamesFromFormModel(model, remainingPath, currentPath) {
    if (remainingPath === '') {
        return [currentPath];
    }
    const splitRemainingPath = remainingPath.split('.');
    const newRemainingPath = splitRemainingPath.slice(1).join('.');
    const nextProperty = splitRemainingPath[0] !== '' && splitRemainingPath[0];
    const arrayRegex = /(.*)\[\]$/;
    const matches = nextProperty.match(arrayRegex);
    if (matches) {
        const propertyName = matches[1];
        const dataArray = reject(get(model, propertyName), formDataIsEmpty);
        return [
            ...flatMap(dataArray, (data, index) =>
                buildFieldNamesFromFormModel(
                    data,
                    newRemainingPath,
                    currentPath
                        ? `${currentPath}.${propertyName}[${index}]`
                        : `${propertyName}[${index}]`
                )
            ),
        ];
    } else {
        return buildFieldNamesFromFormModel(
            get(model, nextProperty),
            newRemainingPath,
            currentPath ? `${currentPath}.${nextProperty}` : nextProperty
        );
    }
}

/**
 * This action runs validation for a single field. The validation result is returned
 *   and the action also uses side effects to cause our desired UI behaviours
 *   for a field in a case where it is invalid.
 */
export function runSingleFieldValidationRRF(
    context,
    formModel,
    fieldName,
    modelPath,
    relativePath
) {
    return (dispatch, getState) => {
        const state = getState();

        // dynamic validation context
        if (isFunction(context)) {
            context = context(formModel, relativePath, state);
        }

        const errorMessage = fieldName
            ? validateFieldRRF(context, formModel, fieldName, relativePath, state)
            : null;

        if (errorMessage && (errorMessage.field || errorMessage.panel)) {
            // setDirty here so the validation runs on onChange when the user goes to fix the error.
            // That way the error goes away as soon as they fix it.
            dispatch(rrfActions.setDirty(modelPath));
            dispatch(rrfActions.setErrors(modelPath, errorMessage.field));
        } else {
            // no field or panel errors associated with this field -- reset the
            // field back to it's initial ui state without resetting the form
            // model state
            dispatch(rrfActions.setInitial(modelPath));
        }

        return errorMessage;
    };
}

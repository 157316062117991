import getChargesResource from '~/client-common/core/domain/charges/resources/chargesResource';
import storeChargeOffenseView from './storeChargeOffenseView';

export const selectOffense = ({ form, offenseId }) => (dispatch) => {
    return form
        .submit()
        .then(() => {
            return getChargesResource().getChargeOffenseViewForOffenseId({ offenseId });
        })
        .then((chargeOffenseView) => {
            const { offense } = chargeOffenseView;
            dispatch(
                // eslint-disable-next-line no-restricted-syntax
                storeChargeOffenseView({
                    chargeOffenseView,
                    action: { type: 'ARREST_CHARGES_SIDE_PANEL_SELECT_PRIOR_OFFENSE' },
                })
            );
            return offense;
        });
};

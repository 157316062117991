import moment from 'moment';
import _ from 'lodash';
import armadaResource from '../resources/armadaResource';
import ArmadaStatuses from '../components/internal/ArmadaStatuses'; // eslint-disable-line import/default
import {
    getSelectedFailedCommandSelector,
    getSelectedScheduleSelector,
} from '../selectors/armadaSelectors';
import { Mark43Error } from '../../lib/errors';
import armadaActionTypes from './types/armadaActionTypes';

const {
    GET_CURRENT_COMMANDS_SUCCESS,
    GET_CURRENT_COMMANDS_FAILURE,
    GET_RUNNING_COMMANDS_SUCCESS,
    GET_RUNNING_COMMANDS_FAILURE,
    GET_PENDING_COMMANDS_SUCCESS,
    GET_PENDING_COMMANDS_FAILURE,
    GET_FAILED_COMMANDS_SUCCESS,
    GET_FAILED_COMMANDS_FAILURE,
    GET_SCHEDULES_SUCCESS,
    GET_SCHEDULES_FAILURE,
    CREATE_SCHEDULE,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAILURE,
    UPDATE_SCHEDULE,
    UPDATE_SCHEDULE_SUCCESS,
    UPDATE_SCHEDULE_FAILURE,
    DELETE_SCHEDULE_FAILURE,
    GET_SCHEDULE_HISTORY_SUCCESS,
    GET_SCHEDULE_HISTORY_FAILURE,
    GET_COMMAND_HISTORY_SUCCESS,
    GET_COMMAND_HISTORY_FAILURE,
    RETRY_COMMAND,
    RETRY_COMMAND_SUCCESS,
    RETRY_COMMAND_FAILURE,
    CLEAR_COMMAND_SUCCESS,
    CLEAR_COMMAND_FAILURE,
    GET_SELECTED_FAILED_COMMAND,
    GET_SELECTED_FAILED_COMMAND_FORM,
    SET_SELECTED_SCHEDULE,
    SET_SELECTED_SCHEDULE_FORM,
    SET_SCHEDULE_ACTION_CREATE,
} = armadaActionTypes;

// TODO: display some kind of error message on more errors

export function refreshAll() {
    return function (dispatch) {
        dispatch(loadCurrentCommands());
        dispatch(loadPendingCommands());
        dispatch(loadFailedCommands());
    };
}

function getCommandHistorySuccess(commandHistory) {
    return {
        type: GET_COMMAND_HISTORY_SUCCESS,
        payload: commandHistory,
    };
}

function getCommandHistoryFailure() {
    return {
        type: GET_COMMAND_HISTORY_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to get Command History'),
    };
}

export function loadCommandHistory(id) {
    // queryParams: params other than schedule_id
    return function (dispatch) {
        armadaResource
            .getHistoryById(id)
            .then((history) => {
                dispatch(getCommandHistorySuccess(history));
            })
            .catch(() => {
                dispatch(getCommandHistoryFailure());
            });
    };
}

function getCurrentCommandsSuccess(currCommands) {
    return {
        type: GET_CURRENT_COMMANDS_SUCCESS,
        payload: currCommands,
    };
}

function getCurrentCommandsFailure() {
    return {
        type: GET_CURRENT_COMMANDS_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to get current commands'),
    };
}

export function loadCurrentCommands() {
    return function (dispatch) {
        armadaResource
            .getCommands()
            .then((commands) => {
                dispatch(getCurrentCommandsSuccess(commands));
            })
            .catch(() => {
                dispatch(getCurrentCommandsFailure());
            });
    };
}

function getRunningCommandsSuccess(runningCommands) {
    return {
        type: GET_RUNNING_COMMANDS_SUCCESS,
        payload: runningCommands,
    };
}

function getRunningCommandsFailure() {
    return {
        type: GET_RUNNING_COMMANDS_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to load pending commands'),
    };
}

export function loadRunningCommands(queryParams) {
    return function (dispatch) {
        armadaResource
            .getCommands({ ...queryParams, status: ArmadaStatuses.RUNNING })
            .then((data) => {
                dispatch(getRunningCommandsSuccess(data));
            })
            .catch((err) => {
                dispatch(getRunningCommandsFailure(err));
            });
    };
}

function getPendingCommandsSuccess(pendingCommands) {
    return {
        type: GET_PENDING_COMMANDS_SUCCESS,
        payload: pendingCommands,
    };
}

function getPendingCommandsFailure() {
    return {
        type: GET_PENDING_COMMANDS_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to load pending commands'),
    };
}

export function loadPendingCommands(queryParams) {
    return function (dispatch) {
        armadaResource
            .getCommands({ ...queryParams, status: ArmadaStatuses.QUEUED })
            .then((data) => {
                dispatch(getPendingCommandsSuccess(data));
            })
            .catch(() => {
                dispatch(getPendingCommandsFailure());
            });
    };
}

function getFailedCommandsSuccess(failedCommands) {
    return {
        type: GET_FAILED_COMMANDS_SUCCESS,
        payload: failedCommands,
    };
}

function getFailedCommandsFailure() {
    return {
        type: GET_FAILED_COMMANDS_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to load pending commands'),
    };
}

export function loadFailedCommands(queryParams) {
    return function (dispatch) {
        armadaResource
            .getCommands({ ...queryParams, status: ArmadaStatuses.FAILED })
            .then((data) => {
                dispatch(getFailedCommandsSuccess(data));
            })
            .catch((err) => {
                dispatch(getFailedCommandsFailure(err));
            });
    };
}

function retryCommandStart() {
    return {
        type: RETRY_COMMAND,
    };
}

function retryCommandSuccess() {
    return {
        type: RETRY_COMMAND_SUCCESS,
    };
}

function retryCommandFailure(err) {
    return {
        type: RETRY_COMMAND_FAILURE,
        error: true,
        payload: err,
    };
}

export function retryCommand(id, formValues) {
    return function (dispatch, getState) {
        dispatch(retryCommandStart());

        const command = getSelectedFailedCommandSelector(getState(), id);
        const editedCommand = _.assign({}, command, formValues);
        armadaResource
            .retryCommand(id, editedCommand)
            .then(() => {
                dispatch(retryCommandSuccess());
                dispatch(refreshAll());
            })
            .catch((err) => {
                dispatch(retryCommandFailure(err));
                dispatch(refreshAll());
            });
    };
}

function clearCommandSuccess(command) {
    return {
        type: CLEAR_COMMAND_SUCCESS,
        payload: command,
    };
}

function clearCommandFailure(err) {
    return {
        type: CLEAR_COMMAND_FAILURE,
        error: true,
        payload: err,
    };
}

export function clearCommand(id, command) {
    return function (dispatch) {
        const commandCopy = _.cloneDeep(command);
        commandCopy.currentStatus = ArmadaStatuses.CLEARED;
        armadaResource
            .updateCommand(id, commandCopy)
            .then((data) => {
                dispatch(clearCommandSuccess(data));
                dispatch(loadCurrentCommands());
            })
            .catch((err) => {
                dispatch(clearCommandFailure(err));
            });
    };
}

function getFailedSelectedInfo(id) {
    return {
        type: GET_SELECTED_FAILED_COMMAND,
        payload: id,
    };
}

function getFailedSelectedForForm(command) {
    return {
        type: GET_SELECTED_FAILED_COMMAND_FORM,
        payload: command,
    };
}

export function getFailedSelected(id) {
    return function (dispatch, getState) {
        dispatch(getFailedSelectedInfo(id));
        const command = getSelectedFailedCommandSelector(getState(), id);
        dispatch(getFailedSelectedForForm(command));
    };
}

function setSelectedScheduleInfo(id) {
    return {
        type: SET_SELECTED_SCHEDULE,
        payload: id,
    };
}

function setSelectedScheduleForm(schedule) {
    return {
        type: SET_SELECTED_SCHEDULE_FORM,
        payload: schedule,
    };
}

function setScheduleActionCreate() {
    return {
        type: SET_SCHEDULE_ACTION_CREATE,
    };
}

export function setNewSchedule() {
    return function (dispatch) {
        dispatch(setScheduleActionCreate());
        const blankForm = {
            id: '',
            commandName: 'TEST_COMMAND',
            frequencyType: 'ONCE',
            frequencyValue: '',
            startDateUtc: moment().toISOString(),
            enabled: false,
            serializedInput: '',
            isNew: true,
        };
        dispatch(setSelectedScheduleForm(blankForm));
    };
}

export function setSelectedSchedule(id) {
    return function (dispatch, getState) {
        dispatch(setSelectedScheduleInfo(id));

        // if a schedule has been selected, the id will be > 0
        // and we can grab the schedule based on its id and fill out the form with the data
        if (id > 0) {
            const schedule = getSelectedScheduleSelector(getState(), id);
            dispatch(setSelectedScheduleForm(schedule));
        }
    };
}

function getSchedulesSuccess(schedules) {
    return {
        type: GET_SCHEDULES_SUCCESS,
        payload: schedules,
    };
}

function getSchedulesFailure() {
    return {
        type: GET_SCHEDULES_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to get Schedules'),
    };
}

export function loadSchedules() {
    return function (dispatch) {
        armadaResource
            .getAllSchedules()
            .then((schedules) => {
                dispatch(getSchedulesSuccess(schedules));
            })
            .catch((err) => {
                dispatch(getSchedulesFailure(err));
            });
    };
}

function getScheduleHistorySuccess(scheduleHistory) {
    return {
        type: GET_SCHEDULE_HISTORY_SUCCESS,
        payload: scheduleHistory,
    };
}

function getScheduleHistoryFailure() {
    return {
        type: GET_SCHEDULE_HISTORY_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to get Schedule History'),
    };
}

export function loadScheduleHistories(id, queryParams) {
    // queryParams: params other than schedule_id
    return function (dispatch) {
        armadaResource
            .getCommands({ ...queryParams, schedule_id: id })
            .then((history) => {
                // eslint-disable-line camelcase
                dispatch(getScheduleHistorySuccess(history));
            })
            .catch(() => {
                dispatch(getScheduleHistoryFailure());
            });
    };
}

function createScheduleStart() {
    return {
        type: CREATE_SCHEDULE,
    };
}

function createScheduleSuccess(newSchedule) {
    return {
        type: CREATE_SCHEDULE_SUCCESS,
        paylaod: newSchedule,
    };
}

function createScheduleFailure(err) {
    return {
        type: CREATE_SCHEDULE_FAILURE,
        error: true,
        payload: err,
    };
}

export function createSchedule(schedule) {
    return function (dispatch) {
        dispatch(createScheduleStart());
        armadaResource
            .createSchedule(schedule)
            .then((data) => {
                dispatch(createScheduleSuccess(data));
                dispatch(loadSchedules());
            })
            .catch((err) => {
                dispatch(createScheduleFailure(err));
            });
    };
}

function updateScheduleStart() {
    return {
        type: UPDATE_SCHEDULE,
    };
}

function updateScheduleSuccess(newSchedule) {
    return {
        type: UPDATE_SCHEDULE_SUCCESS,
        payload: newSchedule,
    };
}

function updateScheduleFailure(err) {
    return {
        type: UPDATE_SCHEDULE_FAILURE,
        error: true,
        payload: err,
    };
}

export function updateSchedule(id, schedule) {
    return function (dispatch) {
        dispatch(updateScheduleStart());
        const activate = { activate: schedule.enabled };
        armadaResource
            .updateSchedule(id, schedule, activate)
            .then((newSchedule) => {
                dispatch(updateScheduleSuccess(newSchedule));
            })
            .catch((err) => {
                dispatch(updateScheduleFailure(err));
            });
    };
}

function deleteScheduleFailure() {
    return {
        type: DELETE_SCHEDULE_FAILURE,
        error: true,
        payload: new Mark43Error('Failed to delete schedule'),
    };
}

// will deactivate a schedule
export function deleteSchedule(id) {
    return function (dispatch) {
        armadaResource
            .deleteSchedule(id)
            .then((data) => {
                dispatch(updateScheduleSuccess(data));
            })
            .catch(() => {
                dispatch(deleteScheduleFailure());
            });
    };
}

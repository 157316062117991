import React from 'react';
import { AnalyticsPropertyEnumType } from '../../analytics/constants/analyticsEnum';

export const AnalyticsContext = React.createContext<
    {
        [analyticsKey in AnalyticsPropertyEnumType]?: unknown;
    }
>({});

// Every time we want to add more to the context,
// we need to create a new provider
//
// This let's us have multiple context trees with unique data
export const AnalyticsContextProviderWithAdditionalData: React.FC<{
    analyticsKeyToAdd: AnalyticsPropertyEnumType;
    analyticsValueToAdd?: string | number;
}> = ({ analyticsKeyToAdd, analyticsValueToAdd, children }) => {
    const analyticsContext = React.useContext(AnalyticsContext);

    // Memoizing because we don't want this rerendering unneccesarily
    const analyticsContextWithAdditionalData = React.useMemo(() => {
        return {
            ...analyticsContext,
            [analyticsKeyToAdd]: analyticsValueToAdd,
        };
    }, [analyticsContext, analyticsValueToAdd, analyticsKeyToAdd]);

    return (
        <AnalyticsContext.Provider children={children} value={analyticsContextWithAdditionalData} />
    );
};

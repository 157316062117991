import { createSelector } from 'reselect';
import _, { filter, get, mapValues, map, orderBy, ObjectIterateeCustom } from 'lodash';
import {
    Attachment,
    EntityTypeEnumType,
    LinkTypesEnumType,
    FileUploadResponse,
    Mark43File,
    EntityTypeEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';
import { sortByNaturalOrder } from '../../../../../helpers/arrayHelpers';

import { attachmentsSelector, attachmentsWhereSelector } from '../data';
import {
    buildViewModel,
    getViewModelProperties,
    ViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';
import { DateTimeFormatter } from '../../../../dates/utils/dateHelpers';
import { getAttachmentFile, getThumbnailPath } from '../../utils/attachmentsHelper';
import { ModuleShape } from '../../../../utils/createNormalizedModule';

type AttachmentViewModelFilterPredicate = ObjectIterateeCustom<
    ModuleShape<AttachmentViewModel>,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    any
>;

export type AttachmentViewModelProps = {
    createdBy: string;
    originalFile: Mark43File;
    originalFileName?: string;
    path?: string;
    thumbnailPath: string;
    thumbnailMediumPath: string;
    absolutePath: string;
};

export type AttachmentViewModel = ViewModel<Attachment, AttachmentViewModelProps>;

export function buildAttachmentViewModel(
    attachment: Attachment,
    formatMiniUserById: ReturnType<typeof formatMiniUserByIdSelector>
): AttachmentViewModel {
    const makeViewModel = buildViewModel<Attachment, AttachmentViewModelProps>({
        recursive: true,
        mappers: [
            ({ createdBy }) => ({
                createdBy: formatMiniUserById(createdBy, { firstNameAsInitial: true }),
            }),
            (attachment) => {
                const file = getAttachmentFile(attachment);
                return {
                    originalFile: file,
                    originalFileName: file.originalFileName,
                    path: file.fileWebServerPath,
                    thumbnailPath: getThumbnailPath(attachment),
                    thumbnailMediumPath: attachment.image
                        ? get(attachment.image, 'thumbnailMediumFile.fileWebServerPath')
                        : '',
                    // Under NO circumstance should this be used on a web app
                    // It is solely for printing where absolute paths are required
                    absolutePath: file.fileAppServerPath,
                };
            },
        ],
    });
    return makeViewModel(attachment);
}

const buildAttachmentViewModelSelector = createSelector(
    formatMiniUserByIdSelector,
    (formatMiniUserById) => (
        attachments: ModuleShape<Attachment>
    ): ModuleShape<AttachmentViewModel> => {
        return mapValues(attachments, (attachment) =>
            buildAttachmentViewModel(attachment, formatMiniUserById)
        );
    }
);

const attachmentViewModelsSelector = createSelector(
    attachmentsSelector,
    buildAttachmentViewModelSelector,
    (attachments, buildAttachmentViewModel): ModuleShape<AttachmentViewModel> => {
        return buildAttachmentViewModel(attachments);
    }
);

export const attachmentToFileGridSelector = createSelector(
    buildAttachmentViewModelSelector,
    (buildAttachmentViewModel) => (attachments: ModuleShape<Attachment>) => {
        const attachmentViewModels = buildAttachmentViewModel(attachments);

        return _(attachmentViewModels).map(attachmentToFileGrid).value();
    }
);

// TODO - move case attachment selectors for printing over to using attachmentViewModelsWhereSelector,
// to consolidate to one api. for now, leaving this here. search for usages of this selector for a list of places to change.
export const attachmentViewModelsByEntitySelector = createSelector(
    attachmentViewModelsSelector,
    (attachmentViewModels) => (entityType: EntityTypeEnumType, entityId: number) => {
        return filter(attachmentViewModels, { entityType, entityId });
    }
);

export const attachmentViewModelsWhereSelector = createSelector(
    attachmentViewModelsSelector,
    (attachmentViewModels) => (predicate: AttachmentViewModelFilterPredicate) =>
        filter(attachmentViewModels, predicate)
);

/**
 * This is the best default selector for attachments as it maps out the view models props
 * and sorts them
 */
export const augmentedAttachmentViewModelsWhereSelector = createSelector(
    attachmentViewModelsSelector,
    (attachmentViewModels) => (predicate: AttachmentViewModelFilterPredicate) => {
        return _(attachmentViewModels).filter(predicate).map(attachmentToFileGrid).value();
    }
);

/**
 * This is the best default selector for attachments as it maps out the view models props
 * and sorts them
 */
export const sortedAugmentedAttachmentViewModelsWhereSelector = createSelector(
    augmentedAttachmentViewModelsWhereSelector,
    (augmentedAttachmentViewModelsWhere) => (predicate: AttachmentViewModelFilterPredicate) => {
        return sortByNaturalOrder(augmentedAttachmentViewModelsWhere(predicate), [
            'displayName',
            'createdDateUtc',
        ]);
    }
);

/**
 * Same as sortedAugmentedAttachmentViewModelsWhereSelector above but sorts first
 * by createdDatUtc descending to show newest attachments at the top
 */
export const dateSortedAugmentedAttachmentViewModelsWhereSelector = createSelector(
    attachmentViewModelsSelector,
    (attachmentViewModels) => (predicate: AttachmentViewModelFilterPredicate) => {
        return _(attachmentViewModels)
            .filter(predicate)
            .map(attachmentToFileGrid)
            .thru((arr) =>
                sortByNaturalOrder(arr, ['createdDateUtc', 'displayName'], ['desc', 'asc'])
            )
            .value();
    }
);

/**
 * The FileGrid component in Files.js takes a specific shape.
 * This function converts an attachmentViewModel into that specific shape
 * @return  FileGrid data shape
 */
export function attachmentToFileGrid(attachmentViewModel: AttachmentViewModel) {
    const display = getViewModelProperties(attachmentViewModel);
    return {
        ...attachmentViewModel,
        thumbnailPath: display.thumbnailPath,
        displayName: display.originalFileName,
        path: display.path,
        createdBy: display.createdBy,
    };
}

type FileGrid = ReturnType<typeof attachmentToFileGrid>;

// Given a set of attachmentViewModels or FileGrid attachments, transform them into the shape needed for ImageGalleryLightbox.
export function convertAttachmentViewModelsForLightboxGallery(
    // case-attachments incorrectly adds on a sourceDescription field
    // augmentedAttachmentViewModelsWhereSelector-based selectors return FileGrid instead of AttachmentViewModel
    attachmentViewModels: (FileGrid | (AttachmentViewModel & { sourceDescription?: string }))[],
    dateTimeFormatter: DateTimeFormatter
) {
    return _.map(attachmentViewModels, (attachmentViewModel) => {
        const file = getAttachmentFile(attachmentViewModel);
        const { path, thumbnailPath, originalFileName, createdBy } = getViewModelProperties(
            attachmentViewModel
        );
        return {
            id: attachmentViewModel.id,
            attachmentId: file.id,
            sourceDescription: attachmentViewModel.sourceDescription,
            url: path,
            thumbnail: thumbnailPath,
            attachmentType: attachmentViewModel.attachmentType,
            originalFileName,
            description: file.description,
            uploadedBy: createdBy,
            entityId: attachmentViewModel.entityId,
            entityType: attachmentViewModel.entityType,
            uploadedDate: dateTimeFormatter.formatDate(attachmentViewModel.createdDateUtc),
            fileCategory: file.fileCategory,
            fileContentLength: file.contentLength || 0,
        };
    });
}

type Config = {
    entityId?: number;
    linkType: LinkTypesEnumType;
    entityType: EntityTypeEnumType;
};

// Convert a FileUploadResponse into a shape that can link files to attachments
export function convertFileUploadResponseToAttachmentLink(
    fileUploadResponse: FileUploadResponse,
    config: Config
) {
    let requestPayload: Partial<Attachment> = {
        entityId: config.entityId,
        entityType: config.entityType,
        linkType: config.linkType,
        id: -1,
    };

    // We treat anything with an image as an image attachment type here. This includes PDFs which have a thumbnail image.
    if (fileUploadResponse.image) {
        requestPayload = {
            ...requestPayload,
            attachmentId: fileUploadResponse.image.id,
            attachmentType: EntityTypeEnum.IMAGE.name,
        };
    } else if (fileUploadResponse.video) {
        requestPayload = {
            ...requestPayload,
            attachmentId: fileUploadResponse.video.id,
            attachmentType: EntityTypeEnum.VIDEO.name,
        };
    } else {
        requestPayload = {
            ...requestPayload,
            attachmentId: fileUploadResponse.file.id,
            attachmentType: EntityTypeEnum.FILE.name,
        };
    }
    return requestPayload;
}

export function convertFileUploadResponsesToAttachmentLinks(
    fileUploadResponses: FileUploadResponse[],
    config: Config
) {
    const attachments = map(fileUploadResponses, (upload) =>
        convertFileUploadResponseToAttachmentLink(upload, config)
    );
    return attachments;
}

export const mugshotAttachmentsForPersonIdSelector = createSelector(
    attachmentsWhereSelector,
    (attachmentsWhere) => (personProfileId: number) =>
        orderBy(
            attachmentsWhere({
                attachmentType: EntityTypeEnum.IMAGE.name,
                linkType: LinkTypesEnum.MUGSHOT,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
                entityId: personProfileId,
            }),
            'createdDateUtc',
            'desc'
        )
);

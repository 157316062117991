import React from 'react';
import { some, get } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// selectors
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

// helpers
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

// constants
import componentStrings from '~/client-common/core/strings/componentStrings';
import globalStrings from '~/client-common/core/strings/globalStrings';

const strings = componentStrings.core.tables.elasticReportsTable.ReportTypeCell;

function ReportTypeCell({ applicationSettings, ...elasticReport }) {
    const recordsWithoutRenEnabled = applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;
    const hasVacatedCharge = some(get(elasticReport, 'reportDefinition.charges'), 'isVacated');
    const reportSubType = recordsWithoutRenEnabled
        ? elasticReport.titleIdentifier
        : elasticReport.titleSubType;
    const isArrestWithDetention = !!get(elasticReport, 'reportDefinition.arrest.isDetention');
    const arrestWithDetentionReportName = isArrestWithDetention
        ? globalStrings.detention
        : undefined;
    return (
        <div className="elastic-report-type">
            <div>
                {arrestWithDetentionReportName ||
                    // prefer the precomputed string on the model to looking
                    // up the type by report definition. Once all reports are
                    // re-synched we can get rid of the view model call altogether
                    get(elasticReport, 'reportDefinition.reportDefinitionName') ||
                    getViewModelProperties(elasticReport).reportDefinition}
            </div>
            <div className="elastic-report-sub-type">
                {reportSubType}
                {hasVacatedCharge && ` ${strings.vacated}`}
            </div>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    applicationSettings: applicationSettingsSelector,
});

export default connect(mapStateToProps)(ReportTypeCell);

import React, { useEffect, useState } from 'react';
import { isEmpty, map, truncate } from 'lodash';
import { useSelector } from 'react-redux';
import { mediaQueries } from 'arc';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    formatAttributeAbbrevByIdSelector,
    formatAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatVehicleModelByIdSelector } from '~/client-common/core/domain/vehicle-models/state/ui';
import { formatVehicleMakeByIdSelector } from '~/client-common/core/domain/vehicle-makes/state/ui';
import FullHeightColumn from '../../core/components/FullHeightColumn';
import { responsiveStack } from '../../core/styles/mixins';
import { ClipsCurrentReportData, clipsErrorMessageSelector } from '../state/ui';
import ClipsReportForm, { ClipsFormData } from '../state/forms/ClipsReportForm';
import ClipsReportEventDetailSidebar from './ClipsReportEventDetailSidebar';
import ClipsErrorMessage from './ClipsErrorMessage';

const strings = componentStrings.clips.ClipsReportForm;

const ResponsiveStack = styled.div`
    ${responsiveStack()}
    flex-direction: column-reverse;
    padding: var(--arc-space-6) 0;
    gap: var(--arc-space-6);
`;

const FirstColumn = styled(FullHeightColumn)`
    width: 100%;

    @media (min-width: ${mediaQueries.md}) {
        width: 60%;
    }
`;

const SecondColumn = styled(FullHeightColumn)`
    width: 100%;

    @media (min-width: ${mediaQueries.md}) {
        width: 40%;
    }
`;

const ClipsReportPage: React.FC<{
    entityId: number;
    reportEntities: ClipsCurrentReportData;
}> = ({ entityId, reportEntities }) => {
    const emptyClipsFormData: ClipsFormData = {
        vehicles: [],
        property: [],
        people: [],
    };
    const [initialClipsFormState, setInitialClipsFormState] = useState(emptyClipsFormData);
    const [isLoaded, setIsLoaded] = useState(false);

    const errorMessage = useSelector(clipsErrorMessageSelector);
    const formatAttributeAbbrevById = useSelector(formatAttributeAbbrevByIdSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const formatVehicleMakeById: (id: number) => string = useSelector(
        formatVehicleMakeByIdSelector
    );
    const formatVehicleModelById: (id: number) => string = useSelector(
        formatVehicleModelByIdSelector
    );

    useEffect(() => {
        const vehicleSummaries = reportEntities?.itemSummaryEntities?.vehicleSummaries || [];
        const itemSummaries = reportEntities?.itemSummaryEntities?.itemSummaries || [];
        const personProfileSummaries = reportEntities?.personProfileEntities || [];

        if (
            !isEmpty(vehicleSummaries) ||
            !isEmpty(itemSummaries) ||
            !isEmpty(personProfileSummaries)
        ) {
            const vehicles = map(vehicleSummaries, (vehicle) => {
                const { id, registrationStateAttrId, vehicleMakeId, vehicleModelId } = vehicle;
                const registrationStateAbbrev =
                    (registrationStateAttrId &&
                        formatAttributeAbbrevById(registrationStateAttrId)) ||
                    strings.unknownState;
                const vehicleMake =
                    (vehicleMakeId && formatVehicleMakeById(vehicleMakeId)) || strings.unknownMake;
                const vehicleModel =
                    (vehicleModelId && formatVehicleModelById(vehicleModelId)) ||
                    strings.unknownModel;
                const tag = vehicle.tag || strings.unknownPlate;
                const entryName = truncate(
                    `${tag}, ${registrationStateAbbrev}, ${vehicleMake}, ${vehicleModel}`,
                    { length: 50 }
                );
                return { id, entryName, isSelected: false };
            });

            const property = map(itemSummaries, (item) => {
                const { id, itemCategoryAttrId } = item;
                const serialNumber = item.serialNumber || strings.unknownSerial;
                const category =
                    (itemCategoryAttrId && formatAttributeById(itemCategoryAttrId)) ||
                    strings.unknownCategory;
                const entryName = truncate(`${serialNumber}, ${category}`, { length: 50 });
                return { id, entryName, isSelected: false };
            });

            const people = map(personProfileSummaries, (person) => {
                const { id } = person;
                const lastName = person.lastName || strings.unknownLastName;
                const firstName = person.firstName || strings.unknownFirstName;
                const entryName = truncate(`${lastName}, ${firstName}`, { length: 50 });
                return { id, entryName, isSelected: false };
            });

            const clipsFormData: ClipsFormData = {
                vehicles,
                property,
                people,
            };
            setInitialClipsFormState(clipsFormData);
            setIsLoaded(true);
        }
    }, [
        formatAttributeAbbrevById,
        formatAttributeById,
        formatVehicleMakeById,
        formatVehicleModelById,
        reportEntities,
    ]);

    return (
        <ResponsiveStack>
            <FirstColumn>
                {errorMessage && <ClipsErrorMessage>{errorMessage}</ClipsErrorMessage>}
                {isLoaded && (
                    <ClipsReportForm
                        initialState={initialClipsFormState}
                        reportEntities={reportEntities}
                        entityId={entityId}
                    />
                )}
            </FirstColumn>
            <SecondColumn>
                <ClipsReportEventDetailSidebar
                    eventDetailEntities={reportEntities.eventDetailEntities}
                    eventDetailViewProperties={reportEntities.eventDetailViewProperties}
                    ren={reportEntities.report?.reportingEventNumber}
                />
            </SecondColumn>
        </ResponsiveStack>
    );
};

export default ClipsReportPage;

import { ElasticAttributeDetail } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'elasticAttributeDetails';
const elasticAttributeDetailsModule = createNormalizedModule<ElasticAttributeDetail>({
    type: NEXUS_STATE_PROP,
});

export const STORE_ELASTIC_ATTRIBUTE_DETAILS = 'STORE_ELASTIC_ATTRIBUTE_DETAILS';

// SELECTORS
export const elasticAttributeDetailsSelector =
    elasticAttributeDetailsModule.selectors.entitiesSelector;

// REDUCER
export default elasticAttributeDetailsModule.reducerConfig;

import React, { useCallback } from 'react';
import { connect, useSelector } from 'react-redux';

import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { currentReportIdSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import routesConfig from '../../../../routing/routesConfig';
import RoutingLabelsModal, {
    useOpenRoutingLabelsModal,
} from '../../../records/core/components/RoutingLabelsModal';
import { canEditLabelsForReportIdSelector } from '../../../records/core/state/ui';
import {
    transitionAllCardsToEditMode,
    validateReport,
    openNibrsAdminSidePanel,
    printReport,
} from '../../../reports/core/state/ui/keyboardShortcuts';
import { openKeyboardShortcutsSidePanel } from '../../keyboard-shortcuts/state/ui';
import ReactHotKeysWrapper from './ReactHotKeysWrapper';

const REPORT_ROUTES = [routesConfig.REACT_REPORT.name];
const {
    SHOW_ALL_HOTKEYS,
    TRANSITION_ALL_CARDS_TO_EDIT_MODE,
    OPEN_LABELS_MODAL,
    OPEN_NIBRS_ADMIN_SIDE_PANEL,
    VALIDATE_REPORT,
    PRINT_REPORT,
} = hotkeysActionEnum;

const RmsGlobalHotKeys = (props) => {
    const {
        openKeyboardShortcutsSidePanel,
        openNibrsAdminSidePanel,
        transitionAllCardsToEditMode,
        validateReport,
        printReport,
    } = props;

    const reportId = useSelector(currentReportIdSelector);
    const canEditLabelsForReportId = useSelector(canEditLabelsForReportIdSelector);
    const openRoutingLabelsModal = useOpenRoutingLabelsModal({
        overlayId: overlayIdEnum.ROUTING_LABELS_FOR_KEYBOARD_SHORTCUT,
        reportId,
    });
    const openReportRoutingLabelsModal = useCallback(() => {
        if (!canEditLabelsForReportId(reportId)) {
            return;
        }

        openRoutingLabelsModal();
    }, [canEditLabelsForReportId, openRoutingLabelsModal, reportId]);

    const hotKeysConfig = {
        [SHOW_ALL_HOTKEYS.name]: {
            handler: openKeyboardShortcutsSidePanel,
        },
        [TRANSITION_ALL_CARDS_TO_EDIT_MODE.name]: {
            handler: transitionAllCardsToEditMode,
            enabledForRoutes: REPORT_ROUTES,
        },
        [OPEN_LABELS_MODAL.name]: {
            handler: openReportRoutingLabelsModal,
            enabledForRoutes: REPORT_ROUTES,
        },
        [OPEN_NIBRS_ADMIN_SIDE_PANEL.name]: {
            handler: openNibrsAdminSidePanel,
            enabledForRoutes: REPORT_ROUTES,
        },
        [VALIDATE_REPORT.name]: {
            handler: validateReport,
            enabledForRoutes: REPORT_ROUTES,
        },
        [PRINT_REPORT.name]: {
            handler: printReport,
            enabledForRoutes: REPORT_ROUTES,
        },
    };

    return (
        <>
            <ReactHotKeysWrapper hotKeysConfig={hotKeysConfig} isGlobal={true} />
            <RoutingLabelsModal overlayId={overlayIdEnum.ROUTING_LABELS_FOR_KEYBOARD_SHORTCUT} />
        </>
    );
};

const mapDispatchToProps = {
    openKeyboardShortcutsSidePanel,
    openNibrsAdminSidePanel,
    transitionAllCardsToEditMode,
    validateReport,
    printReport,
};

export default connect(null, mapDispatchToProps)(RmsGlobalHotKeys);

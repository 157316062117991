import { ElasticSearchTypeEnum, AttributeTypeEnum } from '@mark43/rms-api';
import { get, parseInt } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
// used to check routes for cad-rms search integration
import { openCreateReportModalWithReportingEventNumber } from '../../legacy-redux/actions/renSearchModalActions';
import { openCreateReportModalFromCadEvent } from '../reports/core/state/ui/createReportModal';
import { currentUserHasAbilitySelector } from '../core/current-user/state/ui';
import { loadAttributesForType } from '../core/attributes/state/ui/loadAttributesForType';
import pathParamToSearchTab from './core/utils/pathParamToSearchTab';
import { executeSavedSearchFromQueryParam } from './core/state/ui';
// this is so that we can open the create report modal via the query param
import advancedSearchCadTickets from './cad-tickets/state/ui';
import advancedSearchOrganizations from './organizations/state/ui';
import advancedSearchPersons from './persons/state/ui';
import advancedSearchProperty from './property/state/ui';
import advancedSearchReports from './reports/state/ui';
import advancedSearchVehicles from './vehicles/state/ui';
import advancedSearchAttachments from './attachments/state/ui';
import advancedSearchCourtOrders from './court-orders/state/ui';

function cadNewReportIntegrationOnEnter(nextState) {
    const searchType = get(nextState, 'params.searchType');
    const elasticSearchType = pathParamToSearchTab[searchType];

    // /search/cad?ren=12345&aen=1234
    const reportingEventNumber = get(nextState, 'location.query.ren');
    const cadAgencyEventNumber = get(nextState, 'location.query.aen');
    if (elasticSearchType === ElasticSearchTypeEnum.CAD_TICKET.name && reportingEventNumber) {
        // renNumber is now a non-null value so we can open the modal

        const rwrEnabled = applicationSettingsSelector(this.getState())
            .RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;
        const hasPermissions = currentUserHasAbilitySelector(this.getState())(
            abilitiesEnum.REPORTING.CREATE_REPORTS
        );
        if (hasPermissions) {
            if (rwrEnabled && !!cadAgencyEventNumber) {
                this.dispatch(
                    openCreateReportModalFromCadEvent({
                        reportingEventNumber,
                        cadAgencyEventNumber,
                    })
                );
            } else {
                this.dispatch(
                    openCreateReportModalWithReportingEventNumber({
                        reportingEventNumber,
                        cadAgencyEventNumber,
                    })
                );
            }
        }
    }
}

function loadAttributesForSearch() {
    this.dispatch(
        loadAttributesForType({ attributeType: [AttributeTypeEnum.NAME_ITEM_ASSOCIATION.name] })
    );
}

function getSearchModuleForElasticSearchType(elasticSearchType) {
    switch (elasticSearchType) {
        case ElasticSearchTypeEnum.CAD_TICKET.name:
            return advancedSearchCadTickets;
        case ElasticSearchTypeEnum.ORGANIZATION.name:
            return advancedSearchOrganizations;
        case ElasticSearchTypeEnum.PERSON.name:
            return advancedSearchPersons;
        case ElasticSearchTypeEnum.PROPERTY.name:
            return advancedSearchProperty;
        case ElasticSearchTypeEnum.REPORT.name:
            return advancedSearchReports;
        case ElasticSearchTypeEnum.VEHICLE.name:
            return advancedSearchVehicles;
        case ElasticSearchTypeEnum.ATTACHMENTS.name:
            return advancedSearchAttachments;
        case ElasticSearchTypeEnum.COURT_ORDERS.name:
            return advancedSearchCourtOrders;
        default:
            return;
    }
}

function loadSavedSearch(nextState, replace) {
    const searchType = get(nextState, 'params.searchType');
    const elasticSearchType = pathParamToSearchTab[searchType];

    // /search/anyType?savedSearchId=54321
    const savedSearchId = parseInt(get(nextState, 'location.query.savedSearchId'));
    if (savedSearchId) {
        const searchModule = getSearchModuleForElasticSearchType(elasticSearchType);
        this.dispatch(executeSavedSearchFromQueryParam({ searchModule, nextState, replace }));
    }
}

function triggerScroll(nextState) {
    const searchType = get(nextState, 'params.searchType');
    const elasticSearchType = pathParamToSearchTab[searchType];
    const searchModule = getSearchModuleForElasticSearchType(elasticSearchType);
    if (!searchModule) {
        // Bookings case
        return;
    }
    this.dispatch(searchModule.actionCreators.setIsInitialSearch(true));
    // no initial search for advanced search. therefore manually trigger scroll on enter
    this.dispatch(searchModule.actionCreators.setTriggerScroll(true));
}

export function onEnter(...args) {
    cadNewReportIntegrationOnEnter.call(this, ...args);
    loadAttributesForSearch.call(this, ...args);
    loadSavedSearch.call(this, ...args);
    triggerScroll.call(this, ...args);
}

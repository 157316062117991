import { CommandStatusEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { get, noop, uniq } from 'lodash';
import { departmentProfilesSelector } from '~/client-common/core/domain/department-profiles/state/data';
import { safeSplit } from '~/client-common/helpers/stringHelpers';

import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import {
    SYNC_MULTI_START,
    SYNC_MULTI_SUCCESS,
    SYNC_MULTI_FAILURE,
    SYNC_ENTITY_START,
    SYNC_ENTITY_SUCCESS,
    SYNC_ENTITY_FAILURE,
    GET_ARE_ENTITIES_SYNCED_START,
    GET_ARE_ENTITIES_SYNCED_SUCCESS,
    GET_ARE_ENTITIES_SYNCED_FAILURE,
    SYNC_TYPE_START,
    SYNC_TYPE_SUCCESS,
    SYNC_TYPE_FAILURE,
    LOAD_SYNC_TYPE_STATUS_START,
    LOAD_SYNC_TYPE_STATUS_SUCCESS,
    LOAD_SYNC_TYPE_STATUS_FAILURE,
    CLEAR_SYNC_TYPE_START,
    CLEAR_SYNC_TYPE_FAILURE,
    CLEAR_SYNC_TYPE_SUCCESS,
    syncType,
    syncMulti,
    getAreEntitiesSynced,
    kickOffSearchSyncForEntities,
    syncRmsReportType,
    partialSearchSyncEntities,
} from '../data';
import syncEntityForm from '../forms/syncEntityForm';
import syncTypeForm from '../forms/syncTypeForm';
import syncMultiForm from '../forms/syncMultiForm';

export function submitSyncEntityForm() {
    return function (dispatch) {
        dispatch(
            syncEntityForm.actionCreators.submit((formModel) => {
                const { module, entityType, entityId } = syncEntityForm.convertFromFormModel(
                    formModel
                );
                const entityIds = uniq(safeSplit(entityId, ','));
                if (entityType) {
                    // TODO multi-field validation tech debt
                    return dispatch(kickOffSearchSyncForEntities(module, entityType, entityIds));
                }
                // swallow errors here b/c invalid states are handled directly on fields
            })
        ).catch(noop);
    };
}

export function submitSyncTypeForm() {
    return function (dispatch) {
        dispatch(
            syncTypeForm.actionCreators.submit((formModel) => {
                const {
                    module,
                    entityType,
                    reportDefinitionId,
                    startDateTime,
                } = syncTypeForm.convertFromFormModel(formModel);
                if (entityType) {
                    if (reportDefinitionId) {
                        return dispatch(syncRmsReportType(reportDefinitionId));
                    }
                    // TODO multi-field validation tech debt
                    if (startDateTime) {
                        return dispatch(
                            partialSearchSyncEntities(module, entityType, startDateTime)
                        );
                    }
                    return dispatch(syncType(module, entityType));
                }
                // swallow errors here b/c invalid states are handled directly on fields
            })
        ).catch(noop);
    };
}

export function submitSyncMultiForm() {
    return function (dispatch) {
        dispatch(
            syncMultiForm.actionCreators.submit((formModel) => {
                const { entityTypesByModule, departments } = syncMultiForm.convertFromFormModel(
                    formModel
                );
                // TODO multi-field validation tech debt
                return dispatch(syncMulti(entityTypesByModule, departments));
                // swallow errors here b/c invalid states are handled directly on fields
            })
        ).catch(noop);
    };
}

export function submitGetAreEntitiesSyncedForm() {
    return function (dispatch) {
        dispatch(
            syncEntityForm.actionCreators.submit((formModel) => {
                const { module, entityType, entityId } = syncEntityForm.convertFromFormModel(
                    formModel
                );
                if (entityType) {
                    // TODO multi-field validation tech debt
                    const entityIds = uniq(safeSplit(entityId, ','));
                    return dispatch(getAreEntitiesSynced(module, entityType, entityIds));
                }
                // swallow errors here b/c invalid states are handled directly on fields
            })
        ).catch(noop);
    };
}

export default function reducer(
    state = {
        syncEntityLoading: false,
        syncEntitySucceeded: null,
        syncEntityError: null,
        areEntitiesSyncedLoading: false,
        areEntitiesSyncedResults: [],
        areEntitiesSyncedError: null,
        syncTypeLoading: false,
        syncTypeError: null,
        syncTypeStatus: null,
        syncMultiLoading: false,
        syncMultiErrorByModule: {},
        syncMultiSuccesses: [],
    },
    action
) {
    switch (action.type) {
        case SYNC_MULTI_START:
            return {
                ...state,
                syncMultiLoading: true,
                syncMultiSuccesses: [],
                syncMultiErrorByModule: null,
            };
        case SYNC_MULTI_SUCCESS:
            return {
                ...state,
                syncMultiLoading: false,
                syncMultiSuccesses: [...state.syncMultiSuccesses, ...action.payload],
                syncMultiErrorByModule: null,
            };
        case SYNC_MULTI_FAILURE:
            return {
                ...state,
                syncMultiLoading: false,
                syncMultiSuccesses: [],
                syncMultiErrorByModule: {
                    ...state.syncMultiErrorByModule,
                    [action.payload.module]: action.payload.error,
                },
            };
        case SYNC_ENTITY_START:
            return {
                ...state,
                syncEntityLoading: true,
                syncEntitySucceeded: false,
                syncEntityError: null,
            };
        case SYNC_ENTITY_SUCCESS:
            return {
                ...state,
                syncEntityLoading: false,
                syncEntitySucceeded: true,
                syncEntityError: null,
            };
        case SYNC_ENTITY_FAILURE:
            return {
                ...state,
                syncEntityLoading: false,
                syncEntitySucceeded: false,
                syncEntityError: action.payload,
            };
        case GET_ARE_ENTITIES_SYNCED_START:
            return {
                ...state,
                areEntitiesSyncedLoading: true,
                areEntitiesSyncedResults: [],
                areEntitiesSyncedError: null,
            };
        case GET_ARE_ENTITIES_SYNCED_SUCCESS:
            return {
                ...state,
                areEntitiesSyncedLoading: false,
                areEntitiesSyncedResults: action.payload,
                areEntitiesSyncedError: null,
            };
        case GET_ARE_ENTITIES_SYNCED_FAILURE:
            return {
                ...state,
                areEntitiesSyncedLoading: false,
                areEntitiesSyncedResults: [],
                areEntitiesSyncedError: action.payload,
            };
        case SYNC_TYPE_START:
            return {
                ...state,
                syncTypeLoading: true,
            };
        case SYNC_TYPE_FAILURE:
            return {
                ...state,
                syncTypeError: action.payload,
                syncTypeLoading: false,
            };
        case CLEAR_SYNC_TYPE_SUCCESS:
        case SYNC_TYPE_SUCCESS:
            return {
                ...state,
                syncTypeError: null,
            };
        case CLEAR_SYNC_TYPE_START:
        case LOAD_SYNC_TYPE_STATUS_START:
            return {
                ...state,
                syncTypeLoading: true,
            };
        case CLEAR_SYNC_TYPE_FAILURE:
        case LOAD_SYNC_TYPE_STATUS_FAILURE:
            return {
                ...state,
                syncTypeError: action.payload,
                syncTypeLoading: false,
            };
        case LOAD_SYNC_TYPE_STATUS_SUCCESS:
            return {
                ...state,
                syncTypeStatus: action.payload,
                syncTypeLoading: false,
                syncTypeError: null,
            };
        default:
            return state;
    }
}

const supportSearchSyncUiBaseSelector = (state) => state.ui.supportSearchSync;
export const supportSearchSyncUiSelector = createSelector(
    supportSearchSyncUiBaseSelector,
    departmentProfilesSelector,
    currentUserDepartmentIdSelector,
    (ui, departmentProfiles, currentDepartmentId) => {
        const deptId = get(ui.syncTypeStatus, 'departmentId') || currentDepartmentId;
        const departmentProfile = departmentProfiles[deptId];

        const commandStatus = get(ui.syncTypeStatus, 'commandStatus');
        const isRunning =
            commandStatus === CommandStatusEnum.QUEUED.name ||
            commandStatus === CommandStatusEnum.STARTING.name ||
            commandStatus === CommandStatusEnum.RUNNING.name ||
            commandStatus === CommandStatusEnum.STOPPING.name;
        return {
            ...ui,
            departmentProfile,
            isRunning,
        };
    }
);

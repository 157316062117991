import React from 'react';
import { useDispatch } from 'react-redux';
import { OperationTypeEnum, TaskView } from '@mark43/rms-api';
import { Menu, MenuItem, MenuTrigger, MenuContent } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import testIds from '../../../../core/testIds';
import { RmsDispatch } from '../../../../core/typings/redux';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { IconButton } from '../../../core/components/IconButton';

import { openTaskSidePanelForTask, deleteTask, TaskOverlayMode } from '../state/ui';

const strings = componentStrings.tasks.core.TaskPopOverMenu;

export interface TaskPopOverMenuProps {
    task: TaskView;
    canDelete: boolean;
    onDeleteTask: (taskId: number) => void;
    className?: string;
}

const TaskPopOverMenu: React.FC<TaskPopOverMenuProps> = ({ task, canDelete, onDeleteTask }) => {
    const dispatch = useDispatch<RmsDispatch>();

    const onEditTask = () => {
        dispatch(openTaskSidePanelForTask(task, TaskOverlayMode.EDIT));
    };

    const _onDeleteTask = () => {
        if (task) {
            dispatch(deleteTask(task.id)).then(() => {
                if (onDeleteTask) {
                    onDeleteTask(task.id);
                }
            });
        }
    };

    return (
        <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
            <Menu>
                <MenuTrigger asChild>
                    <IconButton
                        aria-label="More actions"
                        icon="MoreActions"
                        variant="ghost"
                        testId={testIds.TASK_OPTIONS_MENU}
                    />
                </MenuTrigger>
                <MenuContent data-test-id={testIds.TASK_OPTIONS_MENU} align="end">
                    <OnlyWithEntityPermission
                        permissionSet={task.permissionSet ?? [OperationTypeEnum.SEARCH.name]}
                        has={OperationTypeEnum.WRITE.name}
                    >
                        <MenuItem onClick={onEditTask} data-test-id={testIds.TASK_EDIT_OPTION}>
                            {strings.editTask}
                        </MenuItem>
                    </OnlyWithEntityPermission>
                    {canDelete && (
                        <MenuItem
                            onClick={_onDeleteTask.bind(this)}
                            data-test-id={testIds.TASK_DELETE_OPTION}
                        >
                            {strings.deleteTask}
                        </MenuItem>
                    )}
                </MenuContent>
            </Menu>
        </OnlyWithAbility>
    );
};

export default TaskPopOverMenu;

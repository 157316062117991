import { ElasticConfiguredEntityQuery } from '@mark43/rms-api';
import { groupBy } from 'lodash';
import moment from 'moment';
import { dateIsValid } from '~/client-common/core/dates/utils/dateHelpers';
import { isDateRangeQuery } from '../../../../core/utils/type-guards';
import {
    ReportConfiguredEntityProperty,
    ReportConfiguredEntityPropertyValue,
} from '../../components/dragon/types';
import { parseConfiguredEntityPropertyOptionValue } from './optionValue';
import { isNumericRangeQuery } from './isNumericRangeQuery';

type NumericQueryValue = { min?: number; max?: number; exact?: number };

function getNumberFromString(value: string): number {
    return value.trim() !== '' ? Number(value) : NaN;
}

function getNumericQueryValues(
    value: ReportConfiguredEntityPropertyValue | undefined
): NumericQueryValue {
    if (isNumericRangeQuery(value)) {
        const { min, max } = value;
        const parsedMin = getNumberFromString(min ?? '');
        const parsedMax = getNumberFromString(max ?? '');
        if (isNaN(parsedMin) && isNaN(parsedMax)) {
            return {};
        }
        return {
            min: isNaN(parsedMin) ? undefined : parsedMin,
            max: isNaN(parsedMax) ? undefined : parsedMax,
        };
    } else if (typeof value === 'string') {
        const parsedValue = getNumberFromString(value);
        if (isNaN(parsedValue)) {
            return {};
        }
        return { exact: parsedValue };
    }

    return {};
}

function numberQueryValuesPresent(values: NumericQueryValue | undefined): boolean {
    return values?.exact !== undefined || values?.min !== undefined || values?.max !== undefined;
}

const EMPTY_ARRAY: never[] = [];
/**
 * Converts a list of `ReportConfiguredEntityProperty`s into a list of `ElasticConfiguredEntityQuery`s,
 * with each query containing values for a given configured entity type
 */
export function convertDragonReportFieldsToElasticConfiguredEntityQuery(
    input:
        | {
              keywords?: string;
              reportConfiguredEntityProperties?: ReportConfiguredEntityProperty[];
          }
        | undefined
): ElasticConfiguredEntityQuery[] {
    const { reportConfiguredEntityProperties, keywords } = input ?? {};
    if (!reportConfiguredEntityProperties && !keywords) {
        return [];
    }
    const propertiesByEntityType = groupBy(
        (reportConfiguredEntityProperties ?? EMPTY_ARRAY).map(
            ({ configuredEntityPropertyInputValue, configuredEntityPropertyDescriptor }) => {
                return {
                    ...parseConfiguredEntityPropertyOptionValue(
                        configuredEntityPropertyDescriptor ?? ''
                    ),
                    configuredEntityPropertyInputValue,
                };
            }
        ),
        (property) => property.configuredEntityKeyName
    );

    const queries: ElasticConfiguredEntityQuery[] = Object.entries(propertiesByEntityType).map(
        ([configuredEntityTypeKeyName, properties]) => {
            return {
                key: configuredEntityTypeKeyName,
                ...properties.reduce<Omit<ElasticConfiguredEntityQuery, 'key'>>(
                    (
                        acc,
                        { configuredPropertyKeyName, configuredEntityPropertyInputValue, valueType }
                    ) => {
                        // If we have a falsy value, aside from 0, then we will skip adding this
                        // value to our search query, as we cannot search for empty values.
                        // The only exception here is a `DATETIME` field because this field default to an empty
                        // value, which signifies "all time".
                        if (
                            valueType !== 'DATETIME' &&
                            typeof configuredEntityPropertyInputValue !== 'number' &&
                            !configuredEntityPropertyInputValue
                        ) {
                            return acc;
                        }

                        switch (valueType) {
                            case 'ID': {
                                if (
                                    typeof configuredEntityPropertyInputValue === 'string' ||
                                    (typeof configuredEntityPropertyInputValue === 'number' &&
                                        String(configuredEntityPropertyInputValue).trim())
                                ) {
                                    acc.configuredIdQuery.push({
                                        key: configuredPropertyKeyName,
                                        value: [String(configuredEntityPropertyInputValue).trim()],
                                    });
                                }
                                break;
                            }
                            case 'BOOLEAN': {
                                if (
                                    typeof configuredEntityPropertyInputValue === 'string' &&
                                    configuredEntityPropertyInputValue.trim()
                                ) {
                                    acc.configuredBooleanQuery.push({
                                        key: configuredPropertyKeyName,
                                        value: configuredEntityPropertyInputValue === 'true',
                                    });
                                }
                                break;
                            }
                            case 'STRING': {
                                if (
                                    typeof configuredEntityPropertyInputValue === 'string' &&
                                    configuredEntityPropertyInputValue.trim()
                                ) {
                                    acc.configuredStringQuery.push({
                                        key: configuredPropertyKeyName,
                                        value: configuredEntityPropertyInputValue.trim(),
                                    });
                                }
                                break;
                            }
                            case 'DECIMAL': {
                                const values = getNumericQueryValues(
                                    configuredEntityPropertyInputValue
                                );

                                if (numberQueryValuesPresent(values)) {
                                    acc.configuredDecimalQuery.push({
                                        key: configuredPropertyKeyName,
                                        exactDecimalValue: values?.exact,
                                        startDecimalRange: values?.min,
                                        endDecimalRange: values?.max,
                                    });
                                }
                                break;
                            }
                            case 'BIGINT': {
                                const values = getNumericQueryValues(
                                    configuredEntityPropertyInputValue
                                );
                                if (numberQueryValuesPresent(values)) {
                                    acc.configuredBigIntQuery.push({
                                        key: configuredPropertyKeyName,
                                        exactBigIntValue: values?.exact,
                                        startBigIntRange: values?.min,
                                        endBigIntRange: values?.max,
                                    });
                                }
                                break;
                            }
                            case 'DATETIME': {
                                acc.configuredDateTimeQuery.push({
                                    key: configuredPropertyKeyName,
                                    value: isDateRangeQuery(configuredEntityPropertyInputValue)
                                        ? configuredEntityPropertyInputValue
                                        : // exact date query
                                          typeof configuredEntityPropertyInputValue === 'string' &&
                                            configuredEntityPropertyInputValue.trim() &&
                                            dateIsValid(configuredEntityPropertyInputValue)
                                          ? {
                                                endDateUtc: moment(
                                                    configuredEntityPropertyInputValue
                                                )
                                                    .add(1, 'day')
                                                    .toISOString(),
                                                startDateUtc: configuredEntityPropertyInputValue,
                                            }
                                          : // default "all time" range query
                                            {},
                                });
                                break;
                            }
                            default: {
                                // noop
                            }
                        }
                        return acc;
                    },
                    {
                        configuredBigIntQuery: [],
                        configuredBooleanQuery: [],
                        configuredDateTimeQuery: [],
                        configuredDecimalQuery: [],
                        configuredIdQuery: [],
                        configuredStringQuery: [],
                    }
                ),
            };
        }
    );

    // keywords are simply added as a separate `ElasticConfiguredEntityQuery`,
    // without specifing a key. This will allow us to search across all string
    // values across all dragon values on a given report type as well
    // as enable us to later figure out which query value has to go back into
    // the keywords field when loading a saved search.
    if (keywords?.trim()) {
        queries.push({
            configuredBigIntQuery: EMPTY_ARRAY,
            configuredBooleanQuery: EMPTY_ARRAY,
            configuredDateTimeQuery: EMPTY_ARRAY,
            configuredDecimalQuery: EMPTY_ARRAY,
            configuredIdQuery: EMPTY_ARRAY,
            configuredStringQuery: [
                {
                    value: keywords.trim(),
                },
            ],
        });
    }

    return queries;
}

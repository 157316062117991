import { createSelector } from 'reselect';
import { ReportDefaultRoleLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'reportDefaultRoleLinks';

const reportDefaultRoleLinksModule = createLinkModule<ReportDefaultRoleLink>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// ACTIONS

export const replaceReportDefaultRoleLinksWhere =
    reportDefaultRoleLinksModule.actionCreators.replaceLinksWhere;

// SELECTORS

const reportDefaultRoleLinksWhereSelector =
    reportDefaultRoleLinksModule.selectors.linksWhereSelector;

export const reportDefaultRoleLinksByReportDefinitionIdSelector = createSelector(
    reportDefaultRoleLinksWhereSelector,
    (reportDefaultRoleLinksWhere) => (reportDefinitionId: number) =>
        reportDefaultRoleLinksWhere({ reportDefinitionId })
);

export default reportDefaultRoleLinksModule.reducerConfig;

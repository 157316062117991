import React from 'react';
import styled from 'styled-components';
import HelpText from './HelpText';

const Header = styled.div`
    text-transform: uppercase;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    width: ${(props) => (props.headerStyle && props.headerStyle.width) || '100%'};
    float: ${(props) => (props.headerStyle && props.headerStyle.float) || 'none'};
    margin-left: ${(props) => (props.headerStyle && props.headerStyle.marginLeft) || 0};
    margin-bottom: ${(props) => (props.headerStyle && props.headerStyle.marginBottom) || 0};
    margin-top: ${(props) => (props.headerStyle && props.headerStyle.marginTop) || 0};
`;

export const SectionHeader = styled(Header)`
    font-size: var(--arc-fontSizes-md);
    box-sizing: border-box;
    padding: 7px 0 4px 23px;
    background-color: var(--arc-colors-surface-accent);
    border-bottom: 2px solid ${(props) => props.theme.colors.lightGrey};
`;

const SubsectionHeader = styled(Header)`
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.mediumGrey};
    border-bottom: 1px solid ${(props) => props.theme.colors.mediumGrey};
`;

const Base = styled.div`
    box-sizing: border-box;
`;

const Section = styled(Base)`
    padding: 5px 5px 5px 30px;
    width: 100%;
`;

export function ContentSection({
    title,
    fieldName,
    testId,
    helpText,
    error,
    children,
    className,
    headerStyle = {},
}) {
    return (
        <Section
            className={className}
            data-test-id={testId}
            data-test-field-name-section={fieldName}
        >
            {title && (
                <SectionHeader
                    className="mark43-react-card-content-section-header"
                    headerStyle={headerStyle}
                >
                    {title} {helpText && <HelpText content={helpText} />}
                    {error}
                </SectionHeader>
            )}
            {children}
        </Section>
    );
}

export function ContentSubsection({
    title,
    children,
    className,
    headerStyle = {},
    testId,
    ...props
}) {
    return (
        <div className={className} data-test-id={testId} {...props}>
            <SubsectionHeader headerStyle={headerStyle}>{title}</SubsectionHeader>
            <div>{children}</div>
        </div>
    );
}

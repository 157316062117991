import { NarrativeAutosave } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getReportResource from '../../../reports/resources/reportResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'narrativeAutosave';

const narrativeAutosaveModule = createNormalizedModule<NarrativeAutosave>({
    type: NEXUS_STATE_PROP,
    key: 'reportId',
});

// ACTIONS

// SELECTORS
export const narrativeAutosavesSelector = narrativeAutosaveModule.selectors.entitiesSelector;

// REDUCER
export default narrativeAutosaveModule.reducerConfig;

// RESOURCE ACTIONS
const UPDATE_NARRATIVE_AUTOSAVE_START = 'narrative-autosave/UPDATE_NARRATIVE_AUTOSAVE_START';
const UPDATE_NARRATIVE_AUTOSAVE_SUCCESS = 'narrative-autosave/UPDATE_NARRATIVE_AUTOSAVE_SUCCESS';
const UPDATE_NARRATIVE_AUTOSAVE_FAILURE = 'narrative-autosave/UPDATE_NARRATIVE_AUTOSAVE_FAILURE';

function updateNarrativeAutosaveStart() {
    return {
        type: UPDATE_NARRATIVE_AUTOSAVE_START,
    };
}
function updateNarrativeAutosaveSuccess(narrativeAutosave: NarrativeAutosave) {
    return {
        type: UPDATE_NARRATIVE_AUTOSAVE_SUCCESS,
        payload: narrativeAutosave,
    };
}
function updateNarrativeAutosaveFailure(errorMessage: string) {
    return {
        type: UPDATE_NARRATIVE_AUTOSAVE_FAILURE,
        payload: errorMessage,
    };
}

export function updateNarrativeAutosaveForReportId({
    reportId,
    narrativeHtml,
}: Pick<NarrativeAutosave, 'reportId' | 'narrativeHtml'>): ClientCommonAction<
    Promise<NarrativeAutosave>
> {
    const reportResource = getReportResource();

    return (dispatch, getState, { nexus: { withEntityItems } }) => {
        dispatch(updateNarrativeAutosaveStart());
        return reportResource
            .updateNarrativeAutosave({ reportId, narrativeHtml })
            .then((narrativeAutosave: NarrativeAutosave) => {
                dispatch(
                    withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: [narrativeAutosave],
                        },
                        updateNarrativeAutosaveSuccess(narrativeAutosave)
                    )
                );
                return narrativeAutosave;
            })
            .catch((err: Error) => {
                dispatch(updateNarrativeAutosaveFailure(err.message));
                throw err;
            });
    };
}

import { get } from 'lodash';
import { createSelector } from 'reselect';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import {
    formDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import createFormModule from '../../../../core/forms/lib/createFormModule';

const { RANGE, FIELDSET, ENTITY_TYPE } = fieldTypeClientEnum;
const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;

const strings = componentStrings.search.AdvancedSearchAttachmentsForm;

const advancedSearchAttachmentsFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'fileDetails',
        title: strings.sections.fileDetails,
        type: FIELDSET,
        fields: buildFlatFormFieldViewModels([
            // Simple flat fields
            {
                key: 'fileName',
            },
            {
                key: 'reportSequenceNumber',
            },
            'fileTextContentsKeywords',
            'description',
            // All of the "uploadedDate" fields
            {
                key: 'withinLastPeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: WITHIN_LAST_PERIOD,
            },
            {
                key: 'toDatePeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: TO_DATE_PERIOD,
            },
            {
                key: 'startDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_START,
            },
            {
                key: 'endDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_END,
            },
            {
                key: 'linkedEntityTypes',
                type: ENTITY_TYPE,
            },
        ]),
    },
]);

/**
 * Convert the given search query model to form model state. While the query is
 *   flat, the form model state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertAdvancedSearchAttachmentsElasticQueryToFormModel(elasticQuery) {
    return buildFormModel(
        {
            fileDetails: {
                fileName: get(elasticQuery, 'fileName'),
                reportSequenceNumber: get(elasticQuery, 'reportSequenceNumber'),
                fileTextContentsKeywords: get(elasticQuery, 'fileTextContentsKeywords'),
                description: get(elasticQuery, 'description'),
                startDateUtc: get(elasticQuery, 'createdDateRangeQuery.startDateUtc'),
                endDateUtc: get(elasticQuery, 'createdDateRangeQuery.endDateUtc'),
                withinLastPeriod: get(elasticQuery, 'createdDateRangeQuery.withinLastPeriod'),
                toDatePeriod: get(elasticQuery, 'createdDateRangeQuery.toDatePeriod'),
                linkedEntityTypes: get(elasticQuery, 'linkedEntityTypes'),
            },
        },
        advancedSearchAttachmentsFormFieldViewModels
    );
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
function convertAdvancedSearchAttachmentsFormModelToElasticQuery(formModel = {}) {
    return filterFormData({
        fileName: get(formModel, 'fileDetails.fileName'),
        reportSequenceNumber: get(formModel, 'fileDetails.reportSequenceNumber'),
        fileTextContentsKeywords: get(formModel, 'fileDetails.fileTextContentsKeywords'),
        description: get(formModel, 'fileDetails.description'),
        createdDateRangeQuery: {
            startDateUtc: get(formModel, 'fileDetails.startDateUtc'),
            endDateUtc: get(formModel, 'fileDetails.endDateUtc'),
            withinLastPeriod: get(formModel, 'fileDetails.withinLastPeriod'),
            toDatePeriod: get(formModel, 'fileDetails.toDatePeriod'),
        },
        linkedEntityTypes: get(formModel, 'fileDetails.linkedEntityTypes'),
    });
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object}  boundSelectors extra selectors needed by search module
 * @return {Object}   Array of filter group view models.
 */
export function convertAdvancedSearchAttachmentsFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    return convertFormModelToFilterGroups(
        formModel,
        advancedSearchAttachmentsFormFieldViewModels,
        formatFieldValue,
        boundSelectors.formatFieldByName
    );
}

const advancedSearchAttachmentsForm = createFormModule({
    formName: formClientEnum.ADVANCED_SEARCH_ATTACHMENTS,
    convertToFormModel: convertAdvancedSearchAttachmentsElasticQueryToFormModel,
    convertFromFormModel: convertAdvancedSearchAttachmentsFormModelToElasticQuery,
});

advancedSearchAttachmentsForm.selectors.formIsEmptySelector = createSelector(
    advancedSearchAttachmentsForm.selectors.formModelSelector,
    (formModel) => formDataIsEmpty(formModel)
);

/**
 * Module of the advanced search attachments form.
 * @type {Object}
 */
export default advancedSearchAttachmentsForm;

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes, withHandlers } from 'recompose';

import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

import { fieldIsEmpty } from '../../../../../legacy-redux/helpers/formHelpers';
import Select from './Select';

/**
 * Convert a form field key/value to one hashed option value.
 * @param  {string} fieldKey
 * @param  {string} fieldValue
 * @return {string}
 */
export function mapFieldValueToOptionValue(fieldKey, fieldValue) {
    return `${fieldKey}~${fieldValue}`;
}

/**
 * Combine the option objects of all fields into one big array.
 * @param  {Object}  fields       Every field object must have an `options`
 *   array.
 * @param  {boolean} [sort=false] Whether to sort the options.
 * @return {Object[]}
 */
function convertFieldsToOptions(fields, sort = false) {
    return _(fields)
        .mapValues('options')
        .map((fieldOptions, fieldKey) =>
            _.map(fieldOptions, (fieldOption) => ({
                ...fieldOption,
                value: mapFieldValueToOptionValue(fieldKey, fieldOption.value),
            }))
        )
        .flatten()
        .thru((options) => (sort ? sortByNaturalOrder(options, 'display') : options))
        .value();
}

/**
 * Find the selected option within the given fields and return that option's
 *   hashed string value. Return `undefined` if no option is selected.
 * @param  {Array} fieldValues
 * @return {string|undefined}
 */
function convertFieldsToOptionValue(fields) {
    const fieldKey = _.findKey(fields, (field) => !fieldIsEmpty(field));

    return fieldKey ? mapFieldValueToOptionValue(fieldKey, fields[fieldKey].value) : undefined;
}

/**
 * Multiselect version of `convertFieldsToOptionValue()`.
 * @param  {Array} fieldValues
 * @return {string[]}
 */
export function convertFieldsToOptionValues(fields) {
    return _(fields)
        .mapValues('value')
        .omitBy(isUndefinedOrNull)
        .map((fieldValue, fieldKey) =>
            _.isArray(fieldValue)
                ? _.map(fieldValue, (value) => {
                      return mapFieldValueToOptionValue(fieldKey, value);
                  })
                : mapFieldValueToOptionValue(fieldKey, fieldValue)
        )
        .flatten()
        .value();
}

/**
 * Parse a hashed option value into its field key and field value. TODO: Cast
 *   number and boolean values.
 * @param    {string} [optionValue]
 * @return   {Object}
 * @property {string}   key
 * @property {string[]} value
 */
function parseOptionValue(optionValue) {
    const [, key, value] = optionValue ? optionValue.match(/^(.*)~(.*)$/) : [];
    return { key, value };
}

/**
 * Convert one hashed option value into a field value object.
 * @param  {string} [optionValue]
 * @return {Object}
 */
function convertOptionValueToFieldValues(optionValue) {
    const { key, value } = parseOptionValue(optionValue);
    return { [key]: value };
}

/**
 * Group option value(s) into an object by field key.
 * @param  {string[]} [optionValues]
 * @return {Record<string, string[]>}
 */
export function convertOptionValuesToFieldValues(optionValues) {
    return _(optionValues)
        .map(parseOptionValue)
        .groupBy('key')
        .mapValues((group) => _.map(group, 'value'))
        .value();
}

/**
 * Turn the given dropdown component into a new component that supports multiple
 *   fields.
 * @param  {React.Component} Component
 * @return {React.Component}
 */
export function multiFieldSelectFactory(Component) {
    return compose(
        setDisplayName('MultiFieldSelect'),
        setPropTypes({
            fields: PropTypes.object.isRequired,
            sortOptions: PropTypes.bool,
        }),
        withHandlers({
            onBlur({ multiple, onBlur }) {
                return onBlur
                    ? (optionValue) =>
                          onBlur(
                              multiple
                                  ? convertOptionValuesToFieldValues(optionValue)
                                  : convertOptionValueToFieldValues(optionValue)
                          )
                    : _.noop;
            },
        })
    )(function MultiFieldSelect({
        fields,
        sortOptions = false,
        multiple,
        onChange,
        ...otherProps
    }) {
        return (
            <Component
                {...otherProps}
                multiple={multiple}
                options={convertFieldsToOptions(fields, sortOptions)}
                value={
                    multiple
                        ? convertFieldsToOptionValues(fields)
                        : convertFieldsToOptionValue(fields)
                }
                onChange={(optionValue) => {
                    const fieldValues = multiple
                        ? convertOptionValuesToFieldValues(optionValue)
                        : convertOptionValueToFieldValues(optionValue);

                    // update all form fields, even the ones with an `undefined`
                    // value, because they could have been cleared
                    _.forEach(fields, (field, fieldKey) => {
                        field.onChange(fieldValues[fieldKey]);
                    });

                    // call custom handler
                    if (onChange) {
                        onChange(fieldValues);
                    }
                }}
            />
        );
    });
}

const MultiFieldSelect = multiFieldSelectFactory(Select);

/**
 * A dropdown that looks like any other dropdown, except the options correspond
 *   to multiple form fields. Internally, the option values get hashed as
 *   `fieldKey~fieldValue` in order to avoid collisions. When options are
 *   selected, the fields themselves receive the proper values. This can be
 *   either a single-value dropdown or a multiselect.
 * @param {Object}   props            Other props get spread into `<Select>`.
 * @param {Object}   props.fields     Each field must contain an `options` array
 *   (see `<Select>`) and an `onChange` handler that updates its `value`. The
 *   keys of this object can be arbitrary, but they really should match what the
 *   fields are named in the form as convention.
 * @param {boolean}  [props.sortOptions=false] Whether to sort all the options
 *   together alphabetically.
 * @param {function} [props.onChange] Optional handler that receives an object
 *   of selected value(s) with the keys of `fields`.
 */
export default MultiFieldSelect;

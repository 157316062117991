import alertsActionTypes from './types/alertsActionTypes';

const {
    CLOSE_ALERT_FORM,
    OPEN_ALERT_FORM,
    API_FAILURE,
    REVERSE_BANNERS,
    UPLOAD_ATTACHMENT_START,
    MFTUPLOAD_ATTACHMENT_SUCCESS,
    MFTUPLOAD_INFECTED_FILES_FOUND,
} = alertsActionTypes;

export function selectAlertAdmin() {
    return (dispatch) => dispatch({ type: OPEN_ALERT_FORM });
}

export function apiFailure(err) {
    return {
        type: API_FAILURE,
        payload: err.message,
    };
}

export function newAlert() {
    return {
        type: OPEN_ALERT_FORM,
    };
}

export function endEdit() {
    return {
        type: CLOSE_ALERT_FORM,
    };
}

export function reverseBanners() {
    return {
        type: REVERSE_BANNERS,
    };
}

export function uploadAttachmentStart() {
    return {
        type: UPLOAD_ATTACHMENT_START,
    };
}

export function uploadMFTAttachmentSuccess() {
    return {
        type: MFTUPLOAD_ATTACHMENT_SUCCESS,
    };
}
export function uploadMFTInfectedFilesFound() {
    return {
        type: MFTUPLOAD_INFECTED_FILES_FOUND,
    };
}

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Arrest Resource',
        methods: {
            /**
             * Only updates these fields https://github.com/mark43/mark43/blob/11fa4d8b3966a2218a202357fa2de510e6f1e851/cobalt-rms/reporting/reporting-services/src/main/java/mark43/reporting/data/arrest/ArrestRepository.java#L48-L62
             * @param  {Object} arrest shape of Arrest.java
             */
            updateArrest(arrest) {
                return req({
                    method: 'PUT',
                    url: `reporting/arrests/${arrest.id}`,
                    data: arrest,
                });
            },
            updateArrestDefendant(arrestId, defendantId) {
                return req({
                    method: 'PUT',
                    url: `reporting/arrests/${arrestId}/defendant`,
                    data: {
                        data: defendantId,
                    },
                });
            },
            // update attributes for arrests.
            updateArrestAttributes(arrestId, arrestAttributes, attributeType) {
                return req({
                    method: 'PUT',
                    url: `reporting/arrests/${arrestId}/attributes`,
                    data: arrestAttributes,
                    params: attributeType
                        ? {
                              type: attributeType,
                          }
                        : undefined,
                });
            },
            updateArrestNibrsCode(arrest) {
                return req({
                    method: 'PUT',
                    url: `reporting/arrests/${arrest.id}/nibrs`,
                    data: arrest,
                });
            },
            sendArrestToBooking(reportId) {
                return req({
                    method: 'POST',
                    url: `reporting/arrests/${reportId}/send_to_booking`,
                });
            },
            getPriorJuvenileArrestReport(arrestId) {
                return req({
                    method: 'GET',
                    url: `reporting/arrests/${arrestId}/prior_juvenile`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;

/**
 * Resources for Arrests. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
export default resourceGetter;

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here

const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/inventories';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Inventories Resource',
        methods: {
            searchInventories(elasticQuery) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/search`,
                    data: elasticQuery,
                });
            },
        },
    });
}

/**
 * Resource for inventories and audits. See the tech doc for details.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 * @see
 *   https://docs.google.com/document/d/12dH-fM0RvSCm5td9oHpe3J9V6SzIm42qJKIWW1rvyzM/
 */
const resourceGetter = () => resource;
export default resourceGetter;

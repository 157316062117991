/*
 * When making additions to this file, ensure that the key of your new entry is
 *   exactly the camel cased equivalent of the corresponding entry in {@link
 *   cobalt-rms/core/core-common/src/main/java/mark43/core/model/attributes/GlobalAttributes.java}.
 *   Note: The 'L' on the end of each value should be omitted.
 *
 *  Must be kept in sync with {@link
 *   cobalt-rms/core/core-common/src/main/java/mark43/core/model/attributes/GlobalAttributes.java}.
 */
export default {
    itemType: {
        item: 1,
        vehicle: 2,
        firearm: 3,
        drugs: 4,
        alcohol: 5,
        phone: 6,
        bus: 7,
    },
    physicalCharacteristicGroup: {
        amputation: 50,
        appearance: 51,
        deformity: 52,
        ears: 53,
        eyes: 54,
        leftRightHanded: 55,
        teeth: 56,
        complexion: 57,
        mouthLips: 58,
    },
    behavioralCharacteristicGroup: {
        conversation: 70,
        posture: 71,
        pretends: 72,
        speech: 73,
        substanceUse: 74,
        demeanor: 75,
    },
    // Not named `CaseStatusGlobal`, but should be ...
    caseStatus: {
        open: 18160,
        closed: 18161,
        inactive: 18162,
        unfounded: 18201,
        canceled: 233307,
    },
    caseRoleGlobal: {
        assignee: 19200,
        supervisor: 19202,
        otherPersonnel: 60000,
        assistingInvestigator: 219204,
    },
    propertyStatusGlobal: {
        stolen: 50006,
        inPoliceCustody: 50007,
        other: 50008,
        burned: 50020,
    },
    arrestDispositionGlobal: {
        bond: 51000,
        citationRelease: 51001,
        collateral: 51002,
        lockup: 51004,
        postAndForfeit: 51005,
    },
    injuryFatalGlobal: {
        fatal: 123000,
        nonFatal: 123001,
    },
    arrestingAgencyGlobal: {
        juvenileProcessing: 123100,
        nonJuvenileProcessing: 123101,
    },
    nameItemAssociationGlobal: {
        generic: 227000,
        owner: 227001,
        claimant: 227002,
        collector: 227003,
    },
    proofOfOwnershipGlobal: {
        driversLicense: 123200,
        nonDriversLicense: 123201,
    },
    // Not named `OrganizationTypeGlobal` but should be ...
    orgTypeGlobal: {
        gang: 123300,
        otherOrganization: 123301,
    },
    arrestTypeGlobal: {
        regular: 123400,
        nonCustodial: 123401,
        dui: 123402,
    },
    bulletinTypeGlobal: {
        systemUpdates: 123500,
        admin: 123501,
        bolo: 123502,
        alert: 123503,
    },
    courtTypeGlobal: {
        bailHearing: 123600,
    },
    cfsPriorityGlobal: {
        priority1High: 200001,
        priority2: 200002,
        priority3: 200003,
        priority4: 200004,
        priority5Medium: 200005,
        priority6: 200006,
        priority7: 200007,
        priority8: 200008,
        priority9Low: 200009,
    },
    agencyTypeGlobal: {
        police: 203001,
        fire: 203002,
        medical: 203003,
    },
    taskStatusGlobal: {
        pending: 205001,
        completed: 205002,
        notApplicable: 205003,
        inProgress: 205004,
    },
    eventOriginGlobal: {
        nineOneOne: 206001,
        nonEmergencyPhone: 206002,
        selfInitiated: 206003,
    },
    eventStatusGlobal: {
        active: 207001,
        cleared: 207002,
    },
    riskLevelGlobal: {
        one: 208001,
        two: 208002,
        three: 208003,
        four: 208004,
        five: 208005,
        six: 208006,
        seven: 208007,
        eight: 208008,
        nine: 208009,
        ten: 208010,
    },
    towVehicleReasonForTowGlobal: {
        stolen: 209001,
        repossession: 209002,
        other: 209003,
    },
    eventCommunicationTypeGlobal: {
        save: 210001,
        emergency: 210002,
    },
    evidenceFacilityGlobal: {
        /**
         * @deprecated use internal instead
         */
        warehouse: 211001,
        /**
         * @deprecated use internal instead
         */
        temporary: 211002,
        /**
         * @deprecated use external instead
         */
        lab: 211003,
        internal: 211004,
        external: 211005,
    },
    locationCautionPriority: {
        priority1: 222000,
        priority2: 222001,
        priority3: 222002,
        priority4: 222003,
    },
    nameIdentifierTypeGlobal: {
        other: 219000,
    },
    warrantTypeGlobal: {
        arrest: 220000,
        bench: 220001,
        search: 220002,
    },
    warrantStatusGlobal: {
        pending: 221000,
        active: 221001,
        inactive: 221002,
        stub: 221003,
        recalled: 221004,
    },
    itemIdentifierTypeGlobal: {
        other: 223000,
        generic: 223001,
        bagNumber: 223002,
    },
    fieldContactTypeGlobal: {
        seizure: 224000,
        nonSeizure: 224001,
    },
    reasonForPoliceCustodyGlobal: {
        other: 225002,
        evidence: 225001,
    },
    warrantActivityGlobal: {
        entryViaPartnershipsApi: 228000,
        recallViaPartnershipsApi: 228001,
    },
    personLabelPriority: {
        priority1: 229000,
        priority2: 229001,
        priority3: 229002,
        priority4: 229003,
    },
    priorityGlobal: {
        priority1: 229004,
        priority2: 229005,
        priority3: 229006,
        priority4: 229007,
    },
    courtCodeGlobal: {
        sealing: 232000,
        vacating: 232001,
    },
    yesNo: {
        yes: 233313,
        no: 233314,
    },
} as const;

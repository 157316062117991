import React from 'react';
import { useDispatch } from 'react-redux';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../core/overlays/components/Modal';
import { saveDeleteAttachmentConfirmationModal } from '../state/ui/deleteAttachmentConfirmationModal';

const modalStrings = componentStrings.reports.core.AttachmentDeletionModal;

const DeleteAttachmentConfirmationModal: React.FC<null> = () => {
    const dispatch = useDispatch();

    return (
        <Modal
            // no buttonElement, using redux thunk openDeleteAttachmentConfirmationModal
            id={overlayIdEnum.DELETE_ATTACHMENTS_CONFIRMATION_MODAL}
            onSave={() => {
                dispatch(saveDeleteAttachmentConfirmationModal());
            }}
            title={modalStrings.title}
        >
            {modalStrings.message}
        </Modal>
    );
};

export default DeleteAttachmentConfirmationModal;

import { PersonEmergencyContact } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'personEmergencyContacts';

const personEmergencyContactsModule = createGroupedModule<PersonEmergencyContact>({
    type: NEXUS_STATE_PROP,
    key: 'personProfileId',
});

// SELECTORS
export const personEmergencyContactsSelector =
    personEmergencyContactsModule.selectors.entitiesSelector;
export const personEmergencyContactsByPersonProfileIdSelector =
    personEmergencyContactsModule.selectors.entitiesByKeySelector;

// REDUCER
export default personEmergencyContactsModule.reducerConfig;

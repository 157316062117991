import { get, isEmpty, map, pick, size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Text } from 'arc';
import { WARRANT_OBTAINING_OFFICER_USER_ID } from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatWarrantTitle } from '~/client-common/helpers/warrantHelpers';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import withFields from '~/client-common/core/fields/components/withFields';

import { elasticWarrantViewModelByIdSelector } from '~/client-common/core/domain/elastic-warrants/state/ui';
import { currentUserDepartmentIdSelector } from '../../../../../core/current-user/state/ui';

import {
    selectWarrant,
    performFindWarrantSearchMoreResults,
} from '../../../state/ui/arrest-charges-side-panel/findWarrantSearchResults';
import testIds from '../../../../../../core/testIds';
import { CustomLink } from '../../../../../core/components/links/Link';
import Pill from '../../../../../core/components/Pill';
import Row from '../../../../../core/components/Row';
import { BodyMediumText } from '../../../../../core/components/typography';
import { unregisterFindWarrantSearchScreenForm } from './FindWarrantSearchScreen';

const strings = componentStrings.reports.core.ArrestChargesSidePanel.FindWarrantSearchResultsScreen;

const NoWarrantsFound = styled.div`
    text-align: center;
    vertical-align: middle;
`;

const PanelContent = styled.div`
    padding-top: 10px;
`;

const ResultPill = styled(Pill)`
    margin: 6px 0;
`;

const GetMoreResults = styled.div`
    border-radius: 5px;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    border: 1px solid ${(props) => props.theme.colors.mediumBlue};
    background-color: ${(props) => props.theme.colors.lightBlue};
    margin-top: 24px;
`;

const GetMoreResultsLinkContainer = styled.div`
    padding: 23px 5px;
    text-align: center;
`;

export const onSelectWarrant = ({ warrantId }) => (dispatch) => {
    return dispatch(selectWarrant({ warrantId })).then((selectedWarrant) => {
        unregisterFindWarrantSearchScreenForm();
        return selectedWarrant;
    });
};

export const onPerformSearchMoreResults = ({ findWarrantSearch }) => (dispatch) => {
    return dispatch(
        performFindWarrantSearchMoreResults(
            pick(findWarrantSearch, ['elasticQuery', 'from', 'size'])
        )
    );
};

const FindWarrantSearchResultsScreen = compose(
    connect(
        createStructuredSelector({
            elasticWarrantViewModelById: elasticWarrantViewModelByIdSelector,
            currentUserDepartmentId: currentUserDepartmentIdSelector,
        })
    ),
    withFields([WARRANT_OBTAINING_OFFICER_USER_ID])
)(
    ({
        screenState,
        onSearchResultClickCallback,
        onPerformSearchMoreResultsCallback,
        elasticWarrantViewModelById,
        currentUserDepartmentId,
        fieldDisplayNames,
    }) => {
        const {
            isPerformingAsyncAction,
            findWarrantSearch: { orderedCurrentResultElasticWarrantIds, totalCount },
        } = screenState;
        const orderedElasticWarrantViewModels = map(
            orderedCurrentResultElasticWarrantIds,
            (orderedCurrentResultElasticWarrantId) =>
                elasticWarrantViewModelById(orderedCurrentResultElasticWarrantId)
        );

        return (
            <>
                <PanelContent>
                    {isEmpty(orderedCurrentResultElasticWarrantIds) ? (
                        <NoWarrantsFound>{strings.noWarrantsFound}</NoWarrantsFound>
                    ) : (
                        <>
                            {map(orderedElasticWarrantViewModels, (elasticWarrantViewModel) => {
                                const {
                                    id,
                                    departmentId,
                                    warrantNumber,
                                    obtainingOfficerId,
                                    warrantIssuedDateUtc,
                                } = elasticWarrantViewModel;
                                const {
                                    subject,
                                    warrantTypeAttrId,
                                    obtainingOfficerUserDisplay,
                                } = getViewModelProperties(elasticWarrantViewModel);
                                const subjectName = get(subject, 'fullName');
                                const title = formatWarrantTitle({
                                    warrantNumber,
                                    warrantType: warrantTypeAttrId,
                                    subjectName,
                                });
                                const isExternal = currentUserDepartmentId !== departmentId;

                                return (
                                    <ResultPill
                                        key={id}
                                        testId={
                                            testIds.CONNECT_WARRANT_SIDE_PANEL_WARRANT_SEARCH_RESULT
                                        }
                                        hoverable={true}
                                        disabled={isPerformingAsyncAction}
                                        onClick={() =>
                                            onSearchResultClickCallback({
                                                warrantId: id,
                                            })
                                        }
                                    >
                                        <div>
                                            <Row>
                                                <Text variant="headingXs">{title}</Text>
                                            </Row>
                                            {!isExternal && obtainingOfficerId && (
                                                <Row>
                                                    <FormattedDate
                                                        date={warrantIssuedDateUtc}
                                                        format={FormattedDate.FORMATS.FORM_DATE}
                                                    >
                                                        {(formattedDate) => {
                                                            const displayText = joinTruthyValues(
                                                                [
                                                                    joinTruthyValues(
                                                                        [
                                                                            fieldDisplayNames.WARRANT_OBTAINING_OFFICER_USER_ID,
                                                                            obtainingOfficerUserDisplay,
                                                                        ],
                                                                        ': '
                                                                    ),
                                                                    formattedDate,
                                                                ],
                                                                ', '
                                                            );
                                                            return (
                                                                <BodyMediumText variant="bodyMd">
                                                                    {displayText}
                                                                </BodyMediumText>
                                                            );
                                                        }}
                                                    </FormattedDate>
                                                </Row>
                                            )}
                                        </div>
                                    </ResultPill>
                                );
                            })}
                            {size(orderedCurrentResultElasticWarrantIds) < totalCount && (
                                <GetMoreResults>
                                    <GetMoreResultsLinkContainer>
                                        <CustomLink
                                            disabled={isPerformingAsyncAction}
                                            onClick={onPerformSearchMoreResultsCallback}
                                        >
                                            {strings.viewMoreResults}
                                        </CustomLink>
                                    </GetMoreResultsLinkContainer>
                                </GetMoreResults>
                            )}
                        </>
                    )}
                </PanelContent>
            </>
        );
    }
);

export default FindWarrantSearchResultsScreen;

import React from 'react';
import styled from 'styled-components';
import { RemoveButton } from '../../forms/components/NItems';

const StyledRemoveButton = styled(RemoveButton)`
    align-self: center;
`;

const removeButton = ({ item, removeItem }, hideWhen) => {
    let hidden = false;
    if (hideWhen) {
        hidden = hideWhen(item);
    }
    if (hidden) {
        return null;
    }
    return <StyledRemoveButton onClick={removeItem} />;
};

export default removeButton;

import React from 'react';
import { map, includes } from 'lodash';

import { FieldRow } from './FieldRow';
import { RowsContainer } from './RowsContainer';

export const FieldSection = React.memo(function FieldSection({
    options,
    selectedOptions,
    onFieldToggle,
}) {
    const numSelected = selectedOptions ? selectedOptions.length : 0;

    return (
        <RowsContainer paddingLeft="0">
            {map(options, (option) => (
                <FieldRow
                    key={option.value}
                    option={option}
                    onFieldToggle={onFieldToggle}
                    isSelected={numSelected > 0 && includes(selectedOptions, option.value)}
                />
            ))}
        </RowsContainer>
    );
});

import { filter, chain } from 'lodash';
import { createSelector } from 'reselect';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { setRecentHydratedItemProfileIds } from '~/client-common/core/domain/item-profiles/state/ui';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import { isArrestReportSelector } from '~/client-common/core/domain/arrests/state/ui';
import {
    reportDefinitionHasCitationCard,
    reportDefinitionHasImpoundCard,
    reportDefinitionHasTowVehicle,
} from '~/client-common/helpers/reportDefinitionsHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { openBox, closeBox } from '../../../../../legacy-redux/actions/boxActions';
import {
    currentReportIdSelector,
    currentReportSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { reportDefinitionsForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';

import { createModalSelector } from '../../../../core/box/state/ui';
import { refreshArrestCardFormVehicles } from '../forms/arrestForm';
import { getRecentHydratedItems } from './itemProfiles';
import { openItemSidePanel } from './itemSidePanel';

const context = { name: boxEnum.MANAGE_ITEMS_SIDE_PANEL };

export const limitToOneSelector = createSelector(
    applicationSettingsSelector,
    currentReportSelector,
    reportDefinitionsForCurrentDepartmentSelector,
    (applicationSettings, currentReport, reportDefinitions) =>
        chain(currentReport)
            .get('reportDefinitionId')
            .thru((reportDefinitionId) => reportDefinitions[reportDefinitionId])
            .thru(
                (reportDefinition) =>
                    (reportDefinitionHasCitationCard(reportDefinition) &&
                        applicationSettings.RMS_REPORTS_CITATION_REPORT_LIMIT_TO_ONE_VEHICLE) ||
                    reportDefinitionHasImpoundCard(reportDefinition) ||
                    reportDefinitionHasTowVehicle(reportDefinition)
            )
            .value()
);
/**
 * Open a side panel to manage property or vehicle(s)
 *   profiles.
 * @param {object}   isVehicle   Property is a vehicle
 *                     limitToOne   State to determine if we're dealing with single/multiple items
 *                     ownerId   Currently viewing report Id
 *                     ownerType   'REPORT'
 */
export const openManageItemsSidePanel = ({
    isVehicle,
    limitToOne,
    ownerId,
    ownerType,
    reportingEventNumber,
}) => (dispatch, getState) => {
    dispatch(openBox(context, { isVehicle, limitToOne, ownerId, ownerType }));

    dispatch(
        getRecentHydratedItems({
            isVehicle,
            ownerId,
            ownerType,
            reportingEventNumber,
        })
    ).then((hydratedItems) => {
        if (
            isVehicle &&
            hydratedItems.length === 0 &&
            filter(itemProfilesInReportSelector(getState())(ownerId), {
                itemTypeAttrId: globalAttributes.itemType.vehicle,
            }).length === 0
        ) {
            // When the user opens the Manage Items Side Panel, if the REN has no other vehicles in
            // it, then this side panel shows only a +Vehicle button (because there is only 1
            // itemType for vehicle, whereas there are 3 itemTypes for property). In this situation,
            // we immediately open the Item Side Panel for the user to create a new vehicle.
            dispatch(
                openItemSidePanel({
                    isVehicle: true,
                    itemTypeAttrId: globalAttributes.itemType.vehicle,
                    ownerId,
                    ownerType,
                    reportingEventNumber,
                    operation: itemSidePanelOperationEnum.CREATE,
                    isFormHidden: true,
                })
            );
        }
    });
};

/**
 * Close side to panel
 */
export const closeManageItemsSidePanel = (reportId, isVehicle) => (dispatch, getState) => {
    dispatch(setRecentHydratedItemProfileIds([]));
    dispatch(closeBox(context));
    const state = getState();
    const isArrestReport = isArrestReportSelector(state)(reportId);
    // the rule DUI_ARRESTS_REQUIRE_LINKED_VEHICLE requires that
    // we keep the arrest form up to date when adding/removing vehicles
    if (isVehicle && isArrestReport) {
        const currentReportId = currentReportIdSelector(state);
        const isDynamicArrestReport = currentReportId !== reportId;
        const index = isDynamicArrestReport ? reportId : undefined;
        dispatch(refreshArrestCardFormVehicles(reportId, index));
    }
};

export const manageItemsSidePanelIsVehicleSelector = createModalSelector(context, 'isVehicle');
export const manageItemsSidePanelLimitToOneSelector = createModalSelector(context, 'limitToOne');
export const manageItemsSidePanelOwnerIdSelector = createModalSelector(context, 'ownerId');
export const manageItemsSidePanelOwnerTypeSelector = createModalSelector(context, 'ownerType');

import {
    EntityTypeEnum,
    UsageSourceModuleEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';

import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import withFields from '~/client-common/core/fields/components/withFields';
import { fieldNoteByIdSelector } from '~/client-common/core/domain/field-notes/state/data';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { sortedLocationBundlesForLocationEntityLinksWhereSelector } from '~/client-common/core/domain/locations/state/ui';
import { fieldNoteEntityLinksWhereSelector } from '~/client-common/core/domain/field-note-entity-links/state/data';
import { personProfileViewModelByIdSelector } from '~/client-common/core/domain/person-profiles/state/ui';
import { attachmentsByEntitySelector } from '~/client-common/core/domain/attachments/state/data';

import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { createUsageLog } from '../../../admin/usage-logs/state/data';
import { openBox } from '../../../../legacy-redux/actions/boxActions';

import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { getFullFieldNote, saveFieldNoteToReport } from '../state/data';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { openCreateReportModal } from '../../../reports/core/state/ui/createReportModal';
import NotepadLocation from './NotepadLocation';
import NotepadPerson from './NotepadPerson';
import NotepadPhotos from './NotepadPhotos';

const strings = componentStrings.notepad.collection;

const FieldNoteContainer = styled.div`
    font-size: var(--arc-fontSizes-sm);
`;

const TitleContainer = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-size: var(--arc-fontSizes-lg);
`;

const CreatedContainer = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-style: italic;
    font-size: var(--arc-fontSizes-md);
`;

const Section = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
    ${(props) => props.hide && 'display: none;'};

    .sectionTitle {
        text-transform: uppercase;
        border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
        color: ${(props) => props.theme.colors.mediumLightGrey};
        margin-bottom: 10px;
    }
`;

const NotepadHeader = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: ${(props) => props.theme.colors.lightBlue};
`;

const NotepadBody = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
`;

const FieldNoteNote = styled.div`
    white-space: pre-wrap;
`;

const NewReportButton = styled(Button)`
    margin: 0;
`;

const CreateReportButton = ({ onClick }) => (
    <NewReportButton className={buttonTypes.SECONDARY} float="right" onClick={onClick}>
        {strings.newReport}
    </NewReportButton>
);

class NotepadCollection extends React.Component {
    state = {
        loading: false,
    };

    componentDidMount() {
        this.props.getFullFieldNote(this.props.params.id, this.props.dateTimeFormatter);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.id !== this.props.params.id) {
            this.props.getFullFieldNote(this.props.params.id, this.props.dateTimeFormatter);
        }
    }

    render() {
        const fieldNote = this.props.fieldNoteById(this.props.params.id);
        if (!fieldNote) {
            return null;
        }
        const fieldNoteId = fieldNote.id;
        const locationBundles = this.props.sortedLocationBundlesForLocationEntityLinksWhere({
            entityId: fieldNoteId,
        });
        const fieldNoteEntityLinks = this.props.fieldNoteEntityLinksWhere({ fieldNoteId });
        const attachments = this.props.attachmentsByEntity(
            EntityTypeEnum.FIELD_NOTE.name,
            fieldNote.id
        );
        return (
            <FieldNoteContainer key={fieldNote.id}>
                <NotepadHeader>
                    <OnlyWithAbility has={abilitiesEnum.REPORTING.CREATE_REPORTS}>
                        <FeatureFlagged
                            flag="RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED"
                            fallback={
                                <CreateReportButton
                                    onClick={() =>
                                        this.props.openLegacyCreateReportModal(fieldNote)
                                    }
                                />
                            }
                        >
                            <CreateReportButton
                                onClick={this.props.openCreateReportModal(fieldNote)}
                            />
                        </FeatureFlagged>
                    </OnlyWithAbility>
                    <TitleContainer>
                        {fieldNote.reportingEventNumber ? (
                            fieldNote.reportingEventNumber
                        ) : (
                            <FormattedDate
                                date={fieldNote.createdDateUtc}
                                format={FormattedDate.FORMATS.DATE_TIME_IN_SENTENCE}
                            />
                        )}
                    </TitleContainer>
                    <CreatedContainer>
                        {strings.createdOn}{' '}
                        <FormattedDate
                            date={fieldNote.createdDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                        />
                    </CreatedContainer>
                </NotepadHeader>
                <NotepadBody>
                    <Section hide={!fieldNote.reportingEventNumber}>
                        <div className="sectionTitle">
                            {this.props.fieldDisplayNames[REPORT_REPORTING_EVENT_NUMBER]}
                        </div>
                        <div>{fieldNote.reportingEventNumber}</div>
                    </Section>
                    <Section hide={!fieldNote.note}>
                        <div className="sectionTitle">{strings.notes}</div>
                        <FieldNoteNote>{fieldNote.note}</FieldNoteNote>
                    </Section>
                    <Section hide={attachments.length === 0}>
                        <div className="sectionTitle">{strings.photos}</div>
                        <NotepadPhotos
                            entityId={fieldNote.id}
                            entityType={EntityTypeEnum.FIELD_NOTE.name}
                            linkType={LinkTypesEnum.FIELD_NOTE_ATTACHMENT}
                        />
                    </Section>
                    <Section hide={fieldNoteEntityLinks.length === 0}>
                        <div className="sectionTitle">{strings.persons}</div>
                        {map(fieldNoteEntityLinks, (fieldNoteEntityLink) => {
                            const personProfileViewModel = this.props.personProfileViewModelById(
                                fieldNoteEntityLink.entityId
                            );
                            if (!personProfileViewModel) {
                                return null;
                            }
                            return (
                                <NotepadPerson
                                    key={fieldNoteEntityLink.entityId}
                                    personProfileViewModel={personProfileViewModel}
                                />
                            );
                        })}
                    </Section>
                    <Section hide={locationBundles.length === 0}>
                        <div className="sectionTitle">{strings.locations}</div>
                        {map(locationBundles, (locationBundle) => {
                            return (
                                <NotepadLocation
                                    key={locationBundle.location.id}
                                    locationBundle={locationBundle}
                                />
                            );
                        })}
                    </Section>
                </NotepadBody>
            </FieldNoteContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    fieldNoteById: fieldNoteByIdSelector,
    sortedLocationBundlesForLocationEntityLinksWhere: sortedLocationBundlesForLocationEntityLinksWhereSelector,
    fieldNoteEntityLinksWhere: fieldNoteEntityLinksWhereSelector,
    personProfileViewModelById: personProfileViewModelByIdSelector,
    attachmentsByEntity: attachmentsByEntitySelector,
    dateTimeFormatter: currentDepartmentDateFormatterSelector,
});

const mapDispatchToProps = (dispatch) => ({
    getFullFieldNote: (fieldNoteId, dateTimeFormatter) => {
        return dispatch(getFullFieldNote(fieldNoteId))
            .then((result) => {
                const fieldNoteTitle =
                    result.fieldNote.title ||
                    result.fieldNote.reportingEventNumber ||
                    dateTimeFormatter.formatDateTimeInSentence(result.fieldNote.createdDateUtc);
                dispatch(
                    createUsageLog({
                        action: UsageActionEnum.VIEWED_MOBILE_COLLECTION.name,
                        primaryEntityType: EntityTypeEnum.FIELD_NOTE.name,
                        primaryEntityTitle: fieldNoteTitle,
                        primaryEntityId: fieldNoteId,
                        completion: UsageCompletionEnum.SUCCEEDED.name,
                        sourceModule: UsageSourceModuleEnum.RMS_MOBILE.name,
                    })
                );
                return result;
            })
            .catch((err) => {
                dispatch(
                    createUsageLog({
                        action: UsageActionEnum.VIEWED_MOBILE_COLLECTION.name,
                        primaryEntityType: EntityTypeEnum.FIELD_NOTE.name,
                        primaryEntityId: fieldNoteId,
                        completion: UsageCompletionEnum.SERVER_ERROR.name,
                        sourceModule: UsageSourceModuleEnum.RMS_MOBILE.name,
                    })
                );
                throw err;
            });
    },
    openLegacyCreateReportModal: (fieldNote) =>
        dispatch(
            openBox(
                { name: boxEnum.CREATE_REPORT_MODAL },
                {
                    formInitialState: { reportingEventNumber: fieldNote.reportingEventNumber },
                    onSave: (report) => {
                        dispatch(saveFieldNoteToReport(report, fieldNote));
                    },
                    prefilledNarrative: fieldNote.note,
                }
            )
        ),
    openCreateReportModal: (fieldNote) => () => dispatch(openCreateReportModal(fieldNote)),
});

export default compose(
    withFields([REPORT_REPORTING_EVENT_NUMBER]),
    connect(mapStateToProps, mapDispatchToProps)
)(NotepadCollection);

import {
    ComplianceExportQuery,
    ComplianceexportResource,
    ComplianceExportTypeEnumType,
} from '@mark43/rms-api';
import { req } from '../../../lib/ajax';
import { createResource } from '../../../lib/resources/Resource';

export default createResource({
    name: 'Copmliance Export Resource',
    methods: {
        checkComplianceFinalExportState(data: ComplianceExportQuery) {
            return req<ComplianceexportResource.CheckComplianceFinalExportState>({
                data,
                method: 'POST',
                url: `compliance/export/state`,
            });
        },
        getComplianceErrorSummary(exportId: string) {
            return req<ComplianceexportResource.GetComplianceErrorSummary>({
                method: 'POST',
                url: `compliance/export/errors`,
                params: { uuid: exportId },
            });
        },
        getComplianceExportTypes() {
            return req<ComplianceexportResource.GetComplianceExportTypes>({
                method: 'GET',
                url: `compliance/export/types`,
            });
        },
        getComplianceLastExportedDate(data: ComplianceExportQuery) {
            return req<ComplianceexportResource.GetComplianceLastExportedDate>({
                data,
                method: 'POST',
                url: `compliance/export/recent`,
            });
        },
        getComplianceExportHistoryForAgencyId(
            complianceType: ComplianceExportTypeEnumType,
            agencyId: number
        ) {
            return req<ComplianceexportResource.GetComplianceExportHistories>({
                method: 'GET',
                url: `compliance/export/history/${agencyId}/${complianceType}/`,
            });
        },
        deleteComplianceExportHistoryFromDate(
            agencyId: number,
            date: string,
            complianceType: string
        ) {
            return req<ComplianceexportResource.DeleteComplianceFinalExportsFromDate>({
                method: 'DELETE',
                params: { date },
                url: `compliance/export/from/month/${agencyId}/${complianceType}/`,
            });
        },
    },
});

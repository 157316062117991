import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '../../../../modules/core/components/IconButton';
import testIds from '../../../../core/testIds';

const ResultsDividerWrapper = styled.div`
    background-color: var(--arc-colors-surface-accent);
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-style: normal;
    font-weight: 400;
    font-size: var(--arc-fontSizes-md);
    padding: 8.5px 16px 8.5px 16px;
    height: 100%;
`;

interface ResultsDividerProps {
    groupingDisplay: string;
    showCollapseButton: boolean;
    customStyles?: React.CSSProperties;
    onClick?: () => void;
    rightElement: React.ReactNode;
}
/**
 * Represents a division in the rows in order to notify users of grouped rows.
 */
export const ResultsDivider = (props: ResultsDividerProps) => {
    const { groupingDisplay, customStyles, showCollapseButton, onClick, rightElement } = props;
    const [isCollapsed, setIsCollapsed] = useState(false);
    return (
        <ResultsDividerWrapper style={customStyles}>
            {groupingDisplay}
            {showCollapseButton && (
                <IconButton
                    aria-label="Collapse"
                    size="sm"
                    icon={isCollapsed ? 'Open' : 'Close'}
                    onClick={() => {
                        setIsCollapsed(!isCollapsed);
                        if (onClick) {
                            onClick();
                        }
                    }}
                    testId={testIds.GENERIC_EXPAND_COLLAPSE_BUTTON}
                    style={{ float: 'right' }}
                />
            )}
            {rightElement}
        </ResultsDividerWrapper>
    );
};

import _, { keys } from 'lodash';
import fieldTypeClientEnum from '../core/enums/client/fieldTypeClientEnum';
import rangeFieldTypeEnum from '../core/enums/client/rangeFieldTypeEnum';
import { formatFieldValue, formatNItemFieldValues, formDataIsEmpty } from './formHelpers';
import { formatRangeFieldValue } from './rangeHelpers';

const { N_FIELDSETS, N_ITEMS_FIELDSET, FIELDSET, MULTI_FIELD, RANGE } = fieldTypeClientEnum;

/**
 * Convert form model state to filter groups.
 * @param  {Object} [formModel]
 * @param  {Object} [fieldViewModels]
 * @param  {Object} [formatFieldValue]
 * @param  {function} [formatFieldByName]
 * @return {Object[]}
 */
export function convertFormModelToFilterGroups(
    formModel = {},
    fieldViewModels = {},
    formatFieldValue,
    formatFieldByName
) {
    return _.reduce(
        fieldViewModels,
        (filterGroups, child, key) => {
            if (!formModel[key]) {
                return filterGroups;
            }

            let helper = _.noop;

            switch (child.type) {
                case N_FIELDSETS:
                    helper = convertNFieldsetsFormModelToFilterGroups;
                    break;
                case FIELDSET:
                    helper = convertFieldsetFormModelToFilterGroup;
                    break;
                case N_ITEMS_FIELDSET:
                    helper = convertNItemsFieldsetFormModelToFlatFilters;
                    break;
                default:
                    break;
            }

            const newFilterGroups =
                helper(formModel[key], child, formatFieldValue, formatFieldByName) || [];

            return [
                ...filterGroups,
                ...(_.isArray(newFilterGroups) ? newFilterGroups : [newFilterGroups]),
            ];
        },
        []
    );
}

/**
 * Convert an `<NFieldset>`'s form model state to groups of filter view models.
 *   Each fieldset gets treated similar to
 *   `convertFieldsetFormModelToFilterGroup()`, except each resulting filter
 *   group gets a dynamic key (e.g., `locations[0]`) and title ("Location #1").
 * @param  {Object[]} [formModel]
 * @param  {Object}   [fieldsetViewModel]
 * @param  {function} [formatFieldValue]
 * @param  {function} [formatFieldByName]
 * @return {Object[]} N filter groups.
 */
export function convertNFieldsetsFormModelToFilterGroups(
    formModel = [],
    fieldsetViewModel = {},
    formatFieldValue,
    formatFieldByName
) {
    const { key, title, fields: fieldViewModels } = fieldsetViewModel;

    return (
        _(formModel)
            // store an index property to be used below, after the array becomes a
            // keyed object
            .map((item, index) => ({ item, index }))
            // to mirror form state exactly, map keys before removing empty items
            .mapKeys((item, index) => `${key}[${index}]`)
            // remove empty items
            .omitBy(({ item }) => formDataIsEmpty(item))
            // convert each item to a filter group
            .map(({ item, index }, fieldsetKey) => ({
                key: fieldsetKey,
                // the title can be a function that depends on the fieldset index
                title: _.isFunction(title) ? title(index) : title,
                fields: convertFormModelToFlatFilters(
                    item,
                    fieldViewModels,
                    formatFieldValue,
                    formatFieldByName
                ),
            }))
            .value()
    );
}

/**
 * Convert a fieldset's form model state to filter view models. Use this
 *   function for a `<Fieldset>` without N items.
 * @param  {Object}   formModel
 * @param  {Object}   fieldsetViewModel
 * @param  {function} [formatFieldValue]
 * @param  {function} [formatFieldByName]
 * @return {Object|undefined} One filter group.
 */
function convertFieldsetFormModelToFilterGroup(
    formModel,
    fieldsetViewModel = {},
    formatFieldValue,
    formatFieldByName
) {
    const filters = convertFormModelToFlatFilters(
        formModel,
        fieldsetViewModel.fields,
        formatFieldValue,
        formatFieldByName
    );

    if (filters.length === 0) {
        return;
    }

    return {
        ...fieldsetViewModel,
        fields: filters,
    };
}

/**
 * Convert the given form model state (which may be nested) to a flat array of
 *   filter view models. This is done by looping through the form
 *   fields/fieldsets and calling the relevant helpers (including this helper
 *   recursively) depending on field/fieldset type.
 * @param  {Object}   [formModel]
 * @param  {Object}   [fieldViewModels]
 * @param  {function} [formatFieldValue]
 * @param  {function} [formatFieldByName]
 * @return {Object[]} Filter view models.
 */
export function convertFormModelToFlatFilters(
    formModel = {},
    fieldViewModels = {},
    formatFieldValue,
    formatFieldByName
) {
    return _.reduce(
        fieldViewModels,
        (filters, fieldViewModel = {}, key) => {
            const { type, multiFieldType, primaryMultiField, rangeKey, rangeType } = fieldViewModel;
            const child = formModel[key];

            if (type === N_ITEMS_FIELDSET) {
                return [
                    ...filters,
                    ...convertNItemsFieldsetFormModelToFlatFilters(
                        child,
                        fieldViewModel,
                        formatFieldValue,
                        formatFieldByName
                    ),
                ];
            } else if (type === FIELDSET) {
                return [
                    ...filters,
                    ...convertFormModelToFlatFilters(
                        child,
                        fieldViewModel.fields,
                        formatFieldValue,
                        formatFieldByName
                    ),
                ];
            } else if (type === MULTI_FIELD) {
                // since a group of fields can convert to only one filter, do the
                // conversion only when this loop iterates through the "primary"
                // field, which the group has only one of; do nothing when looping
                // through the group's other fields
                if (primaryMultiField) {
                    // grab all the view models in this group
                    const multiFieldViewModels = _.filter(fieldViewModels, {
                        multiFieldType,
                    });

                    // grab all the fields in this range
                    const multiFields = _.pick(formModel, _.map(multiFieldViewModels, 'key'));

                    return _.compact([
                        ...filters,
                        convertMultiFieldsToFilter(multiFields, multiFieldViewModels),
                    ]);
                } else {
                    return filters;
                }
            } else if (type === RANGE) {
                // since a group of range fields can convert to only one filter, do
                // the conversion only when this loop iterates through the
                // RANGE_START field, which every range has one of; do nothing when
                // looping through the other range fields
                if (rangeType === rangeFieldTypeEnum.RANGE_START) {
                    // grab all the view models in this range
                    const rangeFieldViewModels = _.filter(fieldViewModels, {
                        rangeKey,
                    });

                    // grab all the fields in this range
                    const rangeFields = _.pick(formModel, _.map(rangeFieldViewModels, 'key'));

                    return _.compact([
                        ...filters,
                        convertRangeFieldsToFilter(rangeFields, rangeFieldViewModels),
                    ]);
                } else {
                    return filters;
                }
            } else if (_.isObject(child) && !_.isArray(child)) {
                // recursive case: nested fields
                return [
                    ...filters,
                    ...convertFormModelToFlatFilters(
                        child,
                        fieldViewModel,
                        formatFieldValue,
                        formatFieldByName
                    ),
                ];
            } else if (formDataIsEmpty(child)) {
                // empty single field
                return filters;
            } else {
                // non-empty single field
                return _.compact([
                    ...filters,
                    convertFieldToFilter(
                        child,
                        fieldViewModel,
                        formatFieldValue,
                        formatFieldByName
                    ),
                ]);
            }
        },
        []
    );
}

/**
 * Convert an `<NItems>` fieldset's form model state to a flat array of filter
 *   view models. Each item becomes one filter within the same filter group.
 * @param  {Object[]} [formModel]
 * @param  {Object}   [fieldsetViewModel]
 * @param  {function} [formatFieldValue]
 * @param  {function} [formatFieldByName]
 * @return {Object[]} Filter view models.
 */
export function convertNItemsFieldsetFormModelToFlatFilters(
    formModel = [],
    fieldsetViewModel = {},
    formatFieldValue,
    formatFieldByName
) {
    const { key, filterLabel, fieldNameForFilterLabel, fieldName } = fieldsetViewModel;
    return (
        _(formModel)
            // store an index property to be used below, after the array is filtered
            .map((item, index) => ({ item, index }))
            // remove empty items
            .reject(({ item }) => formDataIsEmpty(item))
            // convert each N item (of one or multiple fields) to a single filter
            .map(({ item, index }) =>
                convertNItemFieldsetFieldsToFilter(
                    item,
                    {
                        ...fieldsetViewModel,
                        key: `${key}[${index}]`,
                        // the label can be a function that depends on the N item index
                        label:
                            fieldNameForFilterLabel && formatFieldByName
                                ? formatFieldByName(fieldNameForFilterLabel)
                                : !filterLabel && fieldName && formatFieldByName
                                ? formatFieldByName(fieldName)
                                : _.isFunction(filterLabel)
                                ? filterLabel(index)
                                : filterLabel,
                    },
                    formatFieldValue,
                    formatFieldByName
                )
            )
            .value()
    );
}

/**
 * Convert the given group of related fields into a single filter view model. If
 *   the fields are empty, `undefined` is returned to mean no filter.
 * @param  {Object} fields
 * @param  {Object[]} fieldViewModels
 * @return {Object|undefined} Filter view model.
 */
function convertMultiFieldsToFilter(fields, fieldViewModels = [], format) {
    if (formDataIsEmpty(fields, fieldViewModels)) {
        return;
    }

    return {
        key: fieldViewModels[0].key,
        keys: _.map(fieldViewModels, 'key'),
        label: fieldViewModels[0].label,
        display: format ? format(fields, fieldViewModels) : '',
    };
}

/**
 * Convert the given range fields (fields which together represent a single
 *   range) into one filter view model. If the fields are empty, `undefined` is
 *   returned to mean no filter.
 * @param  {Object} fields
 * @param  {Object[]} fieldViewModels
 * @return {Object|undefined} Filter view model.
 */
function convertRangeFieldsToFilter(fields, fieldViewModels = []) {
    if (formDataIsEmpty(fields, fieldViewModels)) {
        return;
    }

    return {
        key: keys(fields)[0],
        keys: _.map(fieldViewModels, 'key'),
        label: fieldViewModels[0].label,
        display: formatRangeFieldValue(fields, fieldViewModels),
    };
}

/**
 * Convert the given form field into a filter view model. If the field is empty,
 *   `undefined` is returned to mean no filter.
 * @param  {*}        [value]
 * @param  {Object}   [fieldViewModel]
 * @param  {function} [statefulFormatFieldValue] Display string function passed
 *   in because it depends on state.
 * @param  {function} [formatFieldByName]
 * @return {Object|undefined} Filter view model.
 */
function convertFieldToFilter(
    value,
    fieldViewModel = {},
    statefulFormatFieldValue = formatFieldValue,
    formatFieldByName
) {
    if (formDataIsEmpty(value)) {
        return;
    }
    return {
        key: fieldViewModel.key,
        label:
            fieldViewModel.fieldNameForFilterLabel && formatFieldByName
                ? formatFieldByName(fieldViewModel.fieldNameForFilterLabel)
                : !fieldViewModel.label && formatFieldByName && fieldViewModel.fieldName
                ? formatFieldByName(fieldViewModel.fieldName)
                : fieldViewModel.label,
        display: statefulFormatFieldValue(value, fieldViewModel),
    };
}

/**
 * Convert the given N item (which may contain one or multiple fields) to a
 *   single filter.
 * @param  {Object}   fields
 * @param  {Object}   [fieldsetViewModel] View model of the N item fieldset,
 *   which contains view models of the N item's field(s).
 * @param  {function} [statefulFormatFieldValue] Display string function passed
 *   in because it depends on state.
 * @param  {function} [statefulFormatFieldByName] Label string function passed
 *   in because it depends on state.
 * @return {Object|undefined} A single filter.
 */
function convertNItemFieldsetFieldsToFilter(
    fields,
    fieldsetViewModel = {},
    statefulFormatFieldValue = formatFieldValue,
    statefulFormatFieldByName
) {
    if (formDataIsEmpty(fields)) {
        return;
    }

    const { key, label, fields: fieldViewModels } = fieldsetViewModel;

    // can also provide all field keys of the item with `keys:
    // _.map(fieldViewModels, 'key')` if needed
    return {
        key,
        label,
        display: formatNItemFieldValues(
            fields,
            fieldViewModels,
            statefulFormatFieldValue,
            statefulFormatFieldByName
        ),
    };
}

const entityTypeObjectEnum = {
    PERSON_PROFILE: {
        name: 'PERSON_PROFILE',
        value: 10,
    },
    REPORT: {
        name: 'REPORT',
        value: 16,
    },
    ORGANIZATION_PROFILE: {
        name: 'ORGANIZATION_PROFILE',
        value: 18,
    },
    FIELD_CONTACT: {
        name: 'FIELD_CONTACT',
        value: 27,
    },
    STOP: {
        name: 'STOP',
        value: 170,
    },
    EXTERNAL_AGENCY_EVENT: {
        name: 'EXTERNAL_AGENCY_EVENT',
        value: 260,
    },
    EXTERNAL_AGENCY_EVENT_ACTION: {
        name: 'EXTERNAL_AGENCY_EVENT_ACTION',
        value: 261,
    },
};

export default entityTypeObjectEnum;

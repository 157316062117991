import React from 'react';

import { DrawerLayout, DrawerProvider, useDisclosure } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import UnderSubheader from '../../../core/components/UnderSubheader';
import { AnalyticsErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import { InsightsContextProvider } from './InsightsContext';
import ViewNavigationButton from './ViewNavigationButton';
import NavigationDrawer from './NavigationDrawer';

const strings = componentStrings.insights.core.Insights;

export default function Insights({ children }: { children: React.ReactNode }) {
    const {
        isOpen: isNavigationDrawerOpen,
        onToggle: onToggleViewNavigationDrawer,
        onClose: onCloseNavigationDrawer,
    } = useDisclosure();
    return (
        <AnalyticsErrorBoundary>
            <InsightsContextProvider>
                <DrawerProvider drawerPortalId="view-navigation-drawer-portal">
                    <Page className="insights">
                        <Subheader fullscreen title={strings.title}>
                            <ViewNavigationButton
                                isNavigationDrawerOpen={isNavigationDrawerOpen}
                                onClick={onToggleViewNavigationDrawer}
                            />
                        </Subheader>
                        <UnderSubheader fullscreen={true}>
                            <DrawerLayout>
                                {children}
                                <NavigationDrawer
                                    isOpen={isNavigationDrawerOpen}
                                    onClose={onCloseNavigationDrawer}
                                />
                            </DrawerLayout>
                        </UnderSubheader>
                    </Page>
                </DrawerProvider>
            </InsightsContextProvider>
        </AnalyticsErrorBoundary>
    );
}

import React from 'react';
import { pure } from 'recompose';

import { CollapsibleFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNFieldsets } from '../../../core/forms/components/NFieldsets';
import BehaviorsFieldset from '../../core/components/BehaviorsFieldset';
import IdentifiersFieldset from '../../core/components/IdentifiersFieldset';
import IdentifyingMarksFieldset from '../../core/components/IdentifyingMarksFieldset';
import PersonDetailsFieldset from '../../core/components/PersonDetailsFieldset';
import PhysicalAttributesFieldset from '../../core/components/PhysicalAttributesFieldset';
import PersonInjuriesFieldset from './PersonInjuriesFieldset';
import ClothingFieldset from './ClothingFieldset';

/**
 * Fieldset for a single person.
 * @param {Object} props
 */
function PersonFieldset({ fuzzyMatchingEnabled, ...otherProps }) {
    return (
        <CollapsibleFieldset
            expandedChildren={
                <div>
                    <PhysicalAttributesFieldset />
                    <PersonInjuriesFieldset fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                    <IdentifyingMarksFieldset fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                    <ClothingFieldset />
                    <BehaviorsFieldset behaviorMoodEnabled={true} />
                    <IdentifiersFieldset
                        displayMniSearchField={true}
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    />
                </div>
            }
            style={{ marginBottom: 0 }}
            {...otherProps}
        >
            {(expandCollapseButton, collapsed) => (
                <PersonDetailsFieldset
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    collapsed={collapsed}
                    expandCollapseButton={expandCollapseButton}
                    includeInvolvement={true}
                    includeWasFrisked={true}
                    includeHasCriminalStreetGangCriteria={true}
                />
            )}
        </CollapsibleFieldset>
    );
}

/**
 * N person fieldsets. Each person fieldset is collapsible and contains several
 *   smaller fieldsets for different types of person fields. Used only in
 *   advanced search reports as of 2016-04-19.
 * @param {Object} props
 */
export default pure(function PersonsFieldset({ fuzzyMatchingEnabled, ...otherProps }) {
    return (
        <RRFNFieldsets path="persons" automaticallyIncludeDeleteButton={false} {...otherProps}>
            {(fieldsetProps) => (
                <PersonFieldset
                    highlightOnFocus={false}
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    {...fieldsetProps}
                />
            )}
        </RRFNFieldsets>
    );
});

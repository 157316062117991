import {
    ExportRelease,
    ExportReleaseAttributesView,
    ExportReleaseView,
    EntityTypeEnumType,
} from '@mark43/rms-api';
import { get, flatMap, map } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getResource from '../../resources/exportReleasesResource';
import { NEXUS_STATE_PROP as FILES_NEXUS_STATE_PROP } from '../../../files/state/data';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '../../../attachments/state/data';
import { convertAttributeToAttributeView } from '../../../attributes/utils/attributesHelpers';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '../../../attributes/state/data';
import { NEXUS_STATE_PROP as EXPORTED_ATTACHMENTS_NEXUS_STATE_PROP } from '../../../exported-attachments/state/data';
import { NEXUS_STATE_PROP as ENTITTY_RELEASE_TYPES_NEXUS_STATE_PROP } from '../../../export-release-entities/state/data';
import { NEXUS_STATE_PROP as EMAIL_EXPORT_RELEASES_NEXUS_STATE_PROP } from '../../../email-export-releases/state/data';
import { NEXUS_STATE_PROP as EMAIL_EXPORT_RELEASE_ENTITIES_NEXUS_STATE_PROP } from '../../../email-export-release-entities/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

const NEXUS_STATE_PROP = 'exportReleases';

const exportReleasesModule = createNormalizedModule<ExportRelease>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

export const LOAD_EXPORT_RELEASES_START = 'export-releases/LOAD_EXPORT_RELEASES_START';
export const LOAD_EXPORT_RELEASES_SUCCESS = 'export-releases/LOAD_EXPORT_RELEASES_SUCCESS';
export const LOAD_EXPORT_RELEASES_FAILURE = 'export-releases/LOAD_EXPORT_RELEASES_FAILURE';
const CREATE_EXPORT_RELEASES_START = 'export-releases/CREATE_EXPORT_RELEASES_START';
const CREATE_EXPORT_RELEASES_SUCCESS = 'export-releases/CREATE_EXPORT_RELEASES_SUCCESS';
const CREATE_EXPORT_RELEASES_FAILURE = 'export-releases/CREATE_EXPORT_RELEASES_FAILURE';
const UPDATE_EXPORT_RELEASE_START = 'export-releases/UPDATE_EXPORT_RELEASE_START';
const UPDATE_EXPORT_RELEASE_SUCCESS = 'export-releases/UPDATE_EXPORT_RELEASE_SUCCESS';
const UPDATE_EXPORT_RELEASE_FAILURE = 'export-releases/UPDATE_EXPORT_RELEASE_FAILURE';

// ACTIONS
const storeExportReleases = exportReleasesModule.actionCreators.storeEntities;

function loadExportReleasesStart() {
    return {
        type: LOAD_EXPORT_RELEASES_START,
    };
}
function loadExportReleasesSuccess(releaseIds: number[]) {
    return {
        type: LOAD_EXPORT_RELEASES_SUCCESS,
        payload: releaseIds,
    };
}
function loadExportReleasesFailure(errorMessage: string) {
    return {
        type: LOAD_EXPORT_RELEASES_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Load the export releases for an entityType and entityId
 */
export function loadExportReleases(
    entityType: EntityTypeEnumType,
    entityId: number
): ClientCommonAction<Promise<ExportReleaseView[]>> {
    return (dispatch, getState, { nexus }) => {
        const exportReleaseResource = getResource();
        dispatch(loadExportReleasesStart());
        return exportReleaseResource
            .getExportReleases(entityType, entityId)
            .then(
                ({
                    attributes,
                    exportReleases,
                    emailExportReleases,
                }: ExportReleaseAttributesView) => {
                    dispatch(
                        nexus.withEntityItems(
                            {
                                [NEXUS_STATE_PROP]: map(
                                    exportReleases,
                                    ({ exportRelease, exportOptionTypes }) => ({
                                        ...exportRelease,
                                        // 2019-06-13
                                        // `exportOptionTypes` is an array of strings, as it is a seralized enum. We do not have
                                        // a good way of storing this data in state. We could make up a custom model and attach an id to it,
                                        // but having another reducer within nexus just for this purpose seems overkill, which is why
                                        // options added into the export release
                                        exportOptionTypes: exportOptionTypes.sort(),
                                    })
                                ),
                                [EMAIL_EXPORT_RELEASES_NEXUS_STATE_PROP]: map(
                                    emailExportReleases,
                                    ({ exportRelease, exportOptionTypes }) => ({
                                        ...exportRelease,
                                        exportOptionTypes: exportOptionTypes.sort(),
                                    })
                                ),
                                [FILES_NEXUS_STATE_PROP]: [
                                    ...map(exportReleases, 'file'),
                                    ...map(emailExportReleases, 'file'),
                                ],
                                [EXPORTED_ATTACHMENTS_NEXUS_STATE_PROP]: [
                                    ...flatMap(exportReleases, 'exportedAttachments'),
                                    ...flatMap(emailExportReleases, 'exportedAttachments'),
                                ],
                                [ATTACHMENTS_NEXUS_STATE_PROP]: [
                                    ...flatMap(exportReleases, 'attachments'),
                                    ...flatMap(emailExportReleases, 'attachments'),
                                ],
                                [ENTITTY_RELEASE_TYPES_NEXUS_STATE_PROP]: flatMap(
                                    exportReleases,
                                    'exportReleaseEntities'
                                ),
                                [EMAIL_EXPORT_RELEASE_ENTITIES_NEXUS_STATE_PROP]: flatMap(
                                    emailExportReleases,
                                    'exportReleaseEntities'
                                ),
                                [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                                    attributes,
                                    convertAttributeToAttributeView
                                ),
                            },
                            loadExportReleasesSuccess(
                                [...exportReleases, ...emailExportReleases].map(
                                    ({ exportRelease: { id } }) => id
                                )
                            )
                        )
                    );
                    return exportReleases;
                }
            )
            .catch((err: Error) => {
                dispatch(loadExportReleasesFailure(err.message));
                throw err;
            });
    };
}

function createExportReleasesStart(exportReleases: ExportRelease[]) {
    return {
        type: CREATE_EXPORT_RELEASES_START,
        payload: exportReleases,
    };
}

function createExportReleasesSuccess(exportReleases: ExportRelease[]) {
    return {
        type: CREATE_EXPORT_RELEASES_SUCCESS,
        payload: exportReleases,
    };
}

function createExportReleasesFailure(message: string) {
    return {
        type: CREATE_EXPORT_RELEASES_FAILURE,
        payload: message,
    };
}

export function createExportReleases(
    exportReleases: ExportRelease[]
): ClientCommonAction<Promise<ExportRelease[]>> {
    return (dispatch) => {
        const exportReleaseResource = getResource();
        dispatch(createExportReleasesStart(exportReleases));
        return exportReleaseResource
            .createExportReleases(exportReleases)
            .then((createdExportReleases: ExportRelease[]) => {
                dispatch(storeExportReleases(createdExportReleases));
                dispatch(createExportReleasesSuccess(createdExportReleases));
                return createdExportReleases;
            })
            .catch((err: Error) => {
                dispatch(createExportReleasesFailure(err.message));
                throw err;
            });
    };
}

function updateExportReleaseStart(exportReleases: ExportRelease[]) {
    return {
        type: UPDATE_EXPORT_RELEASE_START,
        payload: exportReleases,
    };
}

// TODO: reconcile whether this argument should be an ExportRelease or ExportReleaseView
function updateExportReleaseSuccess(exportReleases: unknown) {
    return {
        type: UPDATE_EXPORT_RELEASE_SUCCESS,
        payload: exportReleases,
    };
}

function updateExportReleaseFailure(message: string) {
    return {
        type: UPDATE_EXPORT_RELEASE_FAILURE,
        payload: message,
    };
}

export function updateExportRelease(
    exportReleases: ExportRelease[]
): ClientCommonAction<Promise<ExportReleaseView[]>> {
    return (dispatch, getState) => {
        const exportReleaseResource = getResource();
        dispatch(updateExportReleaseStart(exportReleases));
        return exportReleaseResource
            .updateExportRelease(exportReleases)
            .then((updatedExportReleases: ExportRelease) => {
                const exportOptionTypesForUpdatedExportRelease = get(
                    exportReleasesSelector(getState()),
                    [updatedExportReleases.id, 'exportOptionTypes']
                );
                // See `loadExportReleases` above for why we have to do this
                const augmentedUpdatedExportReleaseWithExportOptionTypes = {
                    ...updatedExportReleases,
                    exportOptionTypes: exportOptionTypesForUpdatedExportRelease,
                };
                dispatch(storeExportReleases(augmentedUpdatedExportReleaseWithExportOptionTypes));
                dispatch(
                    updateExportReleaseSuccess(augmentedUpdatedExportReleaseWithExportOptionTypes)
                );
                return augmentedUpdatedExportReleaseWithExportOptionTypes;
            })
            .catch((err: Error) => {
                dispatch(updateExportReleaseFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const exportReleasesSelector = exportReleasesModule.selectors.entitiesSelector;

// REDUCER
export default exportReleasesModule.reducerConfig;

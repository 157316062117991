import React from 'react';
import { upperFirst } from 'lodash';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { ArbiterMFTText } from '../../forms/components/Text';
import { responsiveStack } from '../../styles/mixins';
import { SomeFieldsHaveTruthyValue } from '../../components/SomeFieldsHaveValue';

import { CheckboxCenteredWithTextField } from './CheckboxCenteredWithTextField';

const placeholders = componentStrings.core.genericPlaceholders;

const ResponsiveStack = styled.div`
    ${responsiveStack('sm')}
`;
export const DisableableTextInputWithCheckbox = ({
    checkboxFieldPath,
    fieldToDisablePath,
    disableCheckbox,
    textFieldId,
    doUpperFirst,
}) => {
    return (
        <ResponsiveStack>
            <SomeFieldsHaveTruthyValue formPathsToObserve={[checkboxFieldPath]}>
                {(someFieldsHaveTruthyValue) => (
                    <>
                        <ArbiterMFTText
                            alterValue={doUpperFirst ? upperFirst : undefined}
                            placeholder={
                                someFieldsHaveTruthyValue ? placeholders.unknownName : undefined
                            }
                            disabled={someFieldsHaveTruthyValue}
                            path={fieldToDisablePath}
                            id={textFieldId}
                            length="md"
                            {...(someFieldsHaveTruthyValue
                                ? {
                                      value: undefined,
                                  }
                                : {})}
                        />
                    </>
                )}
            </SomeFieldsHaveTruthyValue>
            <CheckboxCenteredWithTextField disabled={disableCheckbox} path={checkboxFieldPath} />
        </ResponsiveStack>
    );
};

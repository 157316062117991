import styled from 'styled-components';
import { TableColumn as Column } from 'components-mark43';

export const CustodyStatusColumn = styled(Column)`
    flex: 1 1 auto;
`;

export const StatusColumn = styled(Column)`
    flex: 0 0 23%;
`;

export const SummaryColumn = styled(Column)`
    flex: 0 1 auto;
    width: 100%;
`;

export const TitleColumn = styled(Column)`
    flex: 0 0 32%;
`;

export const ToggleColumn = styled(Column)`
    position: sticky;
    right: 0;
    align-items: center;
    flex: 0 0 40px;
    width: 40px;
    border-left: 1px solid ${(props) => props.theme.colors.lightGrey};
    background-color: ${(props) => props.theme.colors.white};
`;

export const ToggleHeaderColumn = styled(Column)`
    width: 40px;
    background-color: ${(props) => props.theme.colors.white};
`;

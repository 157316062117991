import {
    createField,
    createNItems,
    createFormConfiguration,
    InferFormDataShape,
    lifecycleOptions,
    _Form,
} from 'markformythree';
import { EntityTypeEnumType, RefContextEnum } from '@mark43/rms-api';
import {
    CASE_IS_TARGET_PROFILE,
    NAME_CASE_LINK_INVOLVEMENT_TYPE_ATTR_ID,
    NAME_CASE_LINK_TARGET_PROFILE_LEVEL_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

export type NameLinksFormPath = 'personNameCaseLinks' | 'orgNameCaseLinks';

const createNameLinkFormConfiguration = () => createFormConfiguration({
    id: createField<number>({}),
    nameId: createField<number>({}),
    entityType: createField<EntityTypeEnumType>({}),
    involvementTypeAttrId: createField<number>({
        fieldName: NAME_CASE_LINK_INVOLVEMENT_TYPE_ATTR_ID,
    }),
    targetProfileLevelAttrId: createField<number>({
        fieldName: NAME_CASE_LINK_TARGET_PROFILE_LEVEL_ATTR_ID,
    }),
    // used for rules only
    isTargetProfile: createField<boolean>({
        fieldName: CASE_IS_TARGET_PROFILE,
    }),
});

const createNameLinksFormConfiguration = () => createNItems({
    fields: createNameLinkFormConfiguration(),
});

const createNameLinkFromReportFormConfiguration = () =>
    createFormConfiguration({
        id: createField<number>({}),
        nameId: createField<number>({}),
        entityType: createField<EntityTypeEnumType>({}),
        targetProfileLevelAttrId: createField<number>({
            fieldName: NAME_CASE_LINK_TARGET_PROFILE_LEVEL_ATTR_ID,
        }),
        // used for rules only
        isTargetProfile: createField<boolean>({
            fieldName: CASE_IS_TARGET_PROFILE,
        }),
    });

const createNameLinksFromReportFormConfiguration = () =>
    createNItems({
        fields: createNameLinkFromReportFormConfiguration(),
    });

export type NameLinksFormPathFromReport = `${NameLinksFormPath}FromReport`;

const createItemLinkFormConfiguration = () => createFormConfiguration({
    id: createField<number>({}),
    itemId: createField<number>({}),
    entityType: createField<EntityTypeEnumType>({}),
    targetProfileLevelAttrId: createField<number>({
        fieldName: NAME_CASE_LINK_TARGET_PROFILE_LEVEL_ATTR_ID,
    }),
    // used for rules only
    isTargetProfile: createField<boolean>({
        fieldName: CASE_IS_TARGET_PROFILE,
    }),
});

const createItemLinksFormConfiguration = () => createNItems({
    fields: createItemLinkFormConfiguration(),
})

const caseLinkedProfilesFormConfig = createFormConfiguration({
    personNameCaseLinks: createNameLinksFormConfiguration(),
    personNameCaseLinksFromReport: createNameLinksFromReportFormConfiguration(),
    orgNameCaseLinks: createNameLinksFormConfiguration(),
    orgNameCaseLinksFromReport: createNameLinksFromReportFormConfiguration(),
    vehicleItemCaseLinks: createItemLinksFormConfiguration(),
});

export const caseLinkedProfilesForm = {
    name: RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name,
    context: RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name,
    configuration: caseLinkedProfilesFormConfig,
    lifecycle: lifecycleOptions.REGISTER_AND_UNREGISTER,
};

export type CaseLinkedProfilesFormConfiguration = typeof caseLinkedProfilesFormConfig;
export type CaseLinkedProfilesFormType = _Form<CaseLinkedProfilesFormConfiguration>;

type CaseLinkedProfilesFormDataShape = InferFormDataShape<CaseLinkedProfilesFormConfiguration>;

export type CaseLinkedProfilesFormModel = Required<CaseLinkedProfilesFormDataShape>;

export type NameCaseLinkFormModel = InferFormDataShape<ReturnType<typeof createNameLinkFormConfiguration>>;
export type NameCaseLinkFromReportFormModel = InferFormDataShape<ReturnType<typeof createNameLinkFromReportFormConfiguration>>;
export type ItemCaseLinkFormModel = InferFormDataShape<ReturnType<typeof createItemLinkFormConfiguration>>;

export const convertFromFormModel = (
    formModel: CaseLinkedProfilesFormDataShape
): CaseLinkedProfilesFormModel => {
    return {
        personNameCaseLinks: formModel.personNameCaseLinks || [],
        personNameCaseLinksFromReport: formModel.personNameCaseLinksFromReport || [],
        orgNameCaseLinks: formModel.orgNameCaseLinks || [],
        orgNameCaseLinksFromReport: formModel.orgNameCaseLinksFromReport || [],
        vehicleItemCaseLinks: formModel.vehicleItemCaseLinks || [],
    };
};

export default caseLinkedProfilesForm;

import { mapValues, noop } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, defaultProps, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import keyMirror from 'keymirror';

import { simpleControl } from 'markformythree';
import dateRangeTypeEnum from '~/client-common/core/enums/client/dateRangeTypeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    mapWithinLastPeriodsToOptions,
    mapToDatePeriodsToOptions,
    formatCustomRange,
    convertDateRangeOptionValueToFieldValues,
    convertDateRangeFieldValuesToOptionValue,
} from '~/client-common/core/dates/utils/dateRangeHelpers';
import { dateRangePickerWidth } from '../../../../legacy-redux/configs/formsConfig';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { useDateTimeFormatter } from '../../current-user/hooks/dateTimeFormats';
import { CustomDateRangeModal } from './CustomDateRangeModal';
import Select from './selects/Select';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const strings = componentStrings.forms.DateRangePicker;

const noFilterOption = {
    display: strings.noFilter,
    value: dateRangeTypeEnum.NO_FILTER,
};

/**
 * Handle a change to any of the date range inputs (the dropdown or the date
 *   pickers).
 * @param  {string} optionValue       New dropdown value.
 * @param  {string} startDateUtcValue New date picker value.
 * @param  {string} endDateUtcValue   New date picker value.
 * @param  {Object} props
 */
function handleChange(optionValue, startDateUtcValue, endDateUtcValue, props) {
    const fieldValues = convertDateRangeOptionValueToFieldValues(
        optionValue,
        startDateUtcValue,
        endDateUtcValue
    );

    // call field handlers
    props.fields.withinLastPeriod.onChange(fieldValues.withinLastPeriod);
    props.fields.toDatePeriod.onChange(fieldValues.toDatePeriod);
    props.fields.startDateUtc.onChange(fieldValues.startDateUtc);
    props.fields.endDateUtc.onChange(fieldValues.endDateUtc);
    // call custom input handler
    props.onChange(fieldValues);
}

const DateRangePicker = compose(
    setDisplayName('DateRangePicker'),
    setPropTypes({
        includeNoFilterOption: PropTypes.bool,
        withinLastPeriodOptions: PropTypes.array,
        toDatePeriodOptions: PropTypes.array,
        allowCustomRange: PropTypes.bool,
        includeTime: PropTypes.bool,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    }),
    defaultProps({
        includeNoFilterOption: true,
        allowCustomRange: true,
        onChange: noop,
        onFocus: noop,
        onBlur: noop,
    }),
    withHandlers({
        onSelectChange(props) {
            return (optionValue) =>
                handleChange(
                    optionValue,
                    props.fields.startDateUtc.value,
                    props.fields.endDateUtc.value,
                    props
                );
        },
    })
)(function DateRangePicker(props) {
    const {
        fields: { withinLastPeriod, toDatePeriod, startDateUtc, endDateUtc },
        // options to include in dropdown
        includeNoFilterOption,
        withinLastPeriodOptions,
        toDatePeriodOptions,
        allowCustomRange,
        // date picker props
        includeTime,
        // handlers
        onSelectChange,
        granularity,
        ...otherProps
    } = props;

    const dateTimeFormatter = useDateTimeFormatter();

    const customRangeOption = {
        display: strings.customRange,
        value: dateRangeTypeEnum.CUSTOM_RANGE,
        selectedLabel: formatCustomRange(
            startDateUtc.value,
            endDateUtc.value,
            includeTime,
            dateTimeFormatter
        ),
    };

    const [showCustomDateRangeModal, setShowCustomDateRangeModal] = React.useState();

    const closeCustomDateRangeModal = React.useCallback(() => {
        setShowCustomDateRangeModal(false);
    }, []);

    const handleOnSelectChange = (value) => {
        // If the `customRange` option is selected,
        // open up the custom range modal
        if (value === dateRangeTypeEnum.CUSTOM_RANGE) {
            setShowCustomDateRangeModal(true);
        } else {
            onSelectChange(value);
        }
    };

    return (
        <>
            <CustomDateRangeModal
                onCancel={closeCustomDateRangeModal}
                onRequestClose={closeCustomDateRangeModal}
                isOpen={showCustomDateRangeModal}
                startDateUtcValueInitialValue={startDateUtc.value}
                endDateUtcValueInitialValue={endDateUtc.value}
                includeTime={includeTime}
                granularity={granularity}
                onSave={({ startDateUtcValue, endDateUtcValue }) => {
                    handleChange(
                        dateRangeTypeEnum.CUSTOM_RANGE,
                        startDateUtcValue,
                        endDateUtcValue,
                        props
                    );
                    setShowCustomDateRangeModal(false);
                }}
            />
            <Select
                maxMenuHeight={300}
                width={dateRangePickerWidth}
                clearable={false}
                {...otherProps}
                fullHeightOptions={true}
                options={[
                    ...(includeNoFilterOption ? [noFilterOption] : []),
                    ...mapWithinLastPeriodsToOptions(withinLastPeriodOptions),
                    ...mapToDatePeriodsToOptions(toDatePeriodOptions),
                    ...(allowCustomRange ? [customRangeOption] : []),
                ]}
                value={convertDateRangeFieldValuesToOptionValue({
                    withinLastPeriod: withinLastPeriod.value,
                    toDatePeriod: toDatePeriod.value,
                    startDateUtc: startDateUtc.value,
                    endDateUtc: endDateUtc.value,
                })}
                onChange={handleOnSelectChange}
            />
        </>
    );
});

/**
 * Select dropdown for picking a date range, which is one of: no filter ("All
 *   Time"), a last period ("Last 48 Hours"), a period to date ("Month to
 *   Date"), a custom range between two dates picked from two `DatePicker`s
 *   which pop up beside the dropdown. All these options are modeled by 4 string
 *   fields. Internally, the dropdown option values are strings that get parsed
 *   into those 4 fields.
 * @param {Object}   props.fields.withinLastPeriod
 * @param {Object}   props.fields.toDatePeriod
 * @param {Object}   props.fields.startDateUtc
 * @param {Object}   props.fields.endDateUtc
 * @param {boolean}  [props.includeNoFilterOption=true] Whether to include an
 *   option for no date filter ('All Time').
 * @param {string[]} [props.withinLastPeriodOptions]    Allowable string values
 *   of the `withinLastPeriod` type. (We could allow an array of objects
 *   including both `value` and `display` in the future.)
 * @param {string[]} [props.toDatePeriodOptions]        Allowable string values
 *   of the `toDatePeriod` type. (We could allow an array of objects including
 *   both `value` and `display` in the future.)
 * @param {boolean}  [props.allowCustomRange=true]      Whether to include an
 *   option for a custom range, which pops up two `DatePicker`s.
 * @param {boolean}  [props.includeTime]                Whether to include time
 *   in the custom range `DatePicker`s.
 */
export default DateRangePicker;

const SimpleControlDateRangePicker = simpleControl(DateRangePicker);
export class MFTDateRangePicker extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    render() {
        let { fields } = this.props;
        const dateRangeModel = this.context.forms.form.getState().model[this.props.path];
        if (dateRangeModel) {
            fields = mapValues(fields, (field, path) => {
                // since simpleControl does not support multi-field inputs, set the value on each
                // date range field
                return {
                    ...field,
                    value: dateRangeModel[path],
                };
            });
        }
        return <SimpleControlDateRangePicker {...this.props} fields={fields} />;
    }
}

export const RRFDateRangePicker = compose(
    setDisplayName('RRFDateRangePicker'),
    defaultProps({
        paths: keyMirror({
            withinLastPeriod: null,
            toDatePeriod: null,
            startDateUtc: null,
            endDateUtc: null,
        }),
    }),
    connectRRFMultiFieldInput
)(DateRangePicker);

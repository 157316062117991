import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'unit/state';

export default createResource({
    name: 'CAD Unit Status Transitions Resource',
    methods: {
        getUnitStatuses(departmentId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/${departmentId}`,
            });
        },
        getUnitStatusTransitions() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/transitions`,
            });
        },
        updateUnitStateTransitions(fromId, transitions) {
            return req({
                method: 'PUT',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/transitions/${fromId}`,
                data: transitions,
            });
        },
        /**
         * Export all unit states into a CSV.
         * @return {Promise}
         */
        exportUnitStates() {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/export`,
            });
        },

        /**
         * Validate that a file is valid for importing new unit states.
         * @return {Promise}
         */
        validateUnitStateImport(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/validate/${fileId}`,
            });
        },

        /**
         * Import unit states from an uploaded file.
         * @return {Promise}
         */
        importUnitStates(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/${fileId}`,
            });
        },

        /**
         * Export all unit states into a CSV.
         * @return {Promise}
         */
        exportUnitStateTransitions(agencyTypeGlobalAttrId) {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/transitions/export?agency_type_global_attr_id=${agencyTypeGlobalAttrId}`,
            });
        },

        /**
         * Validate that a file is valid for importing new unit state transitions
         * @return {Promise}
         */
        validateUnitStateTransitionImport(fileId, agencyTypeGlobalAttrId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/transitions/import/validate/${fileId}?agency_type_global_attr_id=${agencyTypeGlobalAttrId}`,
            });
        },

        importUnitStateTransitions(fileId, agencyTypeGlobalAttrId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/transitions/import/${fileId}?agency_type_global_attr_id=${agencyTypeGlobalAttrId}`,
            });
        },
    },
});

import { PrintingDataTypeEnum } from '@mark43/rms-api';

import {
    exportsPageLoad,
    loadExportPacketOptions,
} from '../../../../../legacy-redux/actions/exportsActions';

export function loadWarrantExports(warrantId) {
    return (dispatch) => {
        dispatch(exportsPageLoad());
        dispatch(loadExportPacketOptions(PrintingDataTypeEnum.WARRANT.name, warrantId));
    };
}

import { ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { filter, includes } from 'lodash';
import styled from 'styled-components';
import { Checkbox as _Checkbox } from 'arc';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import testIds from '../../../core/testIds';
import { currentUserDepartmentIdSelector } from '../current-user/state/ui';

import QuickSearchResultSectionReportItem from '../../search/quick-search/components/sectionitems/QuickSearchResultSectionReportItem';
import QuickSearchResultSectionVehicleItem from '../../search/quick-search/components/sectionitems/QuickSearchResultSectionVehicleItem';
import QuickSearchResultSectionPropertyItem from '../../search/quick-search/components/sectionitems/QuickSearchResultSectionPropertyItem';
import QuickSearchResultSectionCaseItem from '../../search/quick-search/components/sectionitems/QuickSearchResultSectionCaseItem';
import AssociatedRecordsSearchBookingItem from '../../search/quick-search/components/sectionitems/AssociatedRecordsSearchBookingItem';

import RestrictedAccessNotice from '../components/RestrictedAccessNotice';

const searchResultTypeToComponentMap = {
    [ElasticSearchTypeEnum.REPORT.name]: QuickSearchResultSectionReportItem,
    [ElasticSearchTypeEnum.VEHICLE.name]: QuickSearchResultSectionVehicleItem,
    [ElasticSearchTypeEnum.PROPERTY.name]: QuickSearchResultSectionPropertyItem,
    [ElasticSearchTypeEnum.CASE.name]: QuickSearchResultSectionCaseItem,
    [EntityTypeEnum.BOOKING.name]: AssociatedRecordsSearchBookingItem,
};

const entityTypeToCustomWidthMap = {
    [ElasticSearchTypeEnum.REPORT.name]: '311px',
    [ElasticSearchTypeEnum.CASE.name]: '390px',
};

const Wrapper = styled.div`
    font-size: var(--arc-fontSizes-md);
    border-bottom: ${({ isOnly, isLast, theme }) =>
        isOnly || isLast ? 'none' : `1px solid ${theme.colors.lightGrey}`};
    background: ${({ theme, canRead }) =>
        !canRead ? theme.colors.extraLightGrey : theme.colors.white};
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: ${({ theme, canRead }) =>
            canRead ? theme.colors.lightBlue : theme.colors.extraLightGrey};
    }
`;

const ComponentWrapper = styled.div`
    font-size: 14px;
    width: ${({ customWidth }) => customWidth || '100%'};
`;

const Checkbox = styled(_Checkbox)`
    margin-left: 10px;
    margin-right: 0;
`;

const EntitySearchResultItemWrapper = ({
    item,
    type,
    onResultClick,
    isLast,
    isOnly,
    query,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
    showCheckbox,
    selectedEntityIds,
    setSelectedEntityIds,
    entityId,
}) => {
    // Instead of linking within each section item,
    // we use this wrapper to determine where to link an item to.
    // This gives us a central point we can make changes
    // to in the future in case linking changes, e.g. some
    // users can only find an item but not view it.
    const Component = searchResultTypeToComponentMap[type];
    const userCanReadItem =
        type === EntityTypeEnum.CASE.name
            ? item.canCurrentUserView
            : canRead(item.permissionSet) || type === EntityTypeEnum.BOOKING.name;
    const isExternal = item.departmentId !== currentUserDepartmentId;

    function onCheck(event) {
        if (!selectedEntityIds || !setSelectedEntityIds) {
            return;
        }
        let updatedIds = [...selectedEntityIds];
        if (!event.target.checked) {
            updatedIds = filter(updatedIds, (id) => id !== entityId);
        } else {
            updatedIds = [...updatedIds, entityId];
        }
        setSelectedEntityIds(updatedIds);
    }

    return (
        <Wrapper isOnly={isOnly} isLast={isLast} canRead={userCanReadItem}>
            {showCheckbox ? (
                <Checkbox onChange={onCheck} defaultChecked={includes(selectedEntityIds, item.id)}>
                    <ComponentWrapper
                        data-test-id={testIds.ENTITY_SEARCH_RESULT}
                        customWidth={entityTypeToCustomWidthMap[type]}
                    >
                        {!userCanReadItem && <RestrictedAccessNotice />}
                        <Component
                            item={item}
                            query={query}
                            externalDepartmentName={
                                isExternal
                                    ? departmentNameFromConsortiumLinksByDepartmentId(
                                          item.departmentId
                                      )
                                    : undefined
                            }
                        />
                    </ComponentWrapper>
                </Checkbox>
            ) : (
                <ComponentWrapper
                    onMouseDown={() => onResultClick({ item, type })}
                    data-test-id={testIds.ENTITY_SEARCH_RESULT}
                >
                    {!userCanReadItem && <RestrictedAccessNotice />}
                    <Component
                        item={item}
                        query={query}
                        externalDepartmentName={
                            isExternal
                                ? departmentNameFromConsortiumLinksByDepartmentId(item.departmentId)
                                : undefined
                        }
                    />
                </ComponentWrapper>
            )}
        </Wrapper>
    );
};

export default compose(
    connect((state) => ({
        currentUserDepartmentId: currentUserDepartmentIdSelector(state),
        departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector(
            state
        ),
    }))
)(EntitySearchResultItemWrapper);

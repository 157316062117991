import React from 'react';
import styled from 'styled-components';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

interface TaskStatusLabelProps {
    className?: string;
    statusAttrId: number;
}

type IWrapperProps = {
    globalAttributeId?: number;
};

export const TaskStatusInlineBlock = styled.div<IWrapperProps>`
    padding: 1px 4px;
    margin: 0 4px;
    text-align: center;
    font-size: var(--arc-fontSizes-md);
    border-radius: 3px;
    display: inline-block;
    ${({ globalAttributeId, theme }) => {
        switch (globalAttributeId) {
            case globalAttributes.taskStatusGlobal.pending:
                return `
                    border: 1px solid ${theme.colors.darkGreen2};
                    background-color: ${theme.colors.lightGreen2};

                `;
            case globalAttributes.taskStatusGlobal.completed:
                return `
                    border: 1px solid ${theme.colors.lightGrey};
                    background-color: ${theme.colors.extraLightGrey};

                `;
            case globalAttributes.taskStatusGlobal.inProgress:
                return `
                    border: 1px solid var(--arc-colors-raw-purple-500);
                    background-color: var(--arc-colors-raw-purple-100);

                `;
            case globalAttributes.taskStatusGlobal.notApplicable:
                return `
                    border: 1px solid ${theme.colors.lightGrey};

                `;
            default:
                return null;
        }
    }};
`;

const TaskStatusLabel: React.FC<TaskStatusLabelProps> = ({ className, statusAttrId }) => {
    return (
        <ConnectedFormattedAttribute attributeId={statusAttrId}>
            {(formattedAttribute, attribute) => {
                const { parentId } = attribute;
                return (
                    <div className={className}>
                        <TaskStatusInlineBlock globalAttributeId={parentId}>
                            {formattedAttribute}
                        </TaskStatusInlineBlock>
                    </div>
                );
            }}
        </ConnectedFormattedAttribute>
    );
};
export default TaskStatusLabel;

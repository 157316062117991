import React, { useCallback } from 'react';
import type { Editor, EditorEvent } from 'tinymce';
import { useStore } from 'react-redux';
import { AttributeTypeEnum } from '@mark43/rms-api';
import { usePreloadAttributes } from '~/client-common/core/hooks/usePreloadAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { RootState } from '../../../../../../../legacy-redux/reducers/rootReducer';
import { EditorErrorHandler, MentionCategoryType } from '../types';
import { BASE_ELEMENT, BASE_ELEMENT_CLASS, DATA_MENTION_HREF } from '../constants';
import { fetchMentions, hoverMention, insertMention, selectMention } from '../core';

const strings = componentStrings.core.Editor.plugins.mentionsForBriefing;

export const useMentionsForBriefing = ({
    isEnabled,
    isViewMode,
    onError,
}: {
    isEnabled?: boolean;
    isViewMode: boolean;
    onError: EditorErrorHandler;
}) => {
    const store = useStore<RootState>();
    const mentionBriefingCategoryRef = React.useRef<MentionCategoryType | null>(null);
    const editorRef = React.useRef<Editor>();
    const isViewModeRef = React.useRef(isViewMode);

    usePreloadAttributes([
        AttributeTypeEnum.PERSON_LABEL_CATEGORY.name,
        AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
        AttributeTypeEnum.VEHICLE_LABEL_CATEGORY.name,
        AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name,
        AttributeTypeEnum.ITEM_CATEGORY.name,
        AttributeTypeEnum.ITEM_COLOR.name,
    ]);

    const setupEditor = useCallback(
        (editor: Editor) => {
            if (!isEnabled) {
                return;
            }

            editorRef.current = editor;

            const isMentionElement = (node: Element): node is HTMLSpanElement => {
                return (
                    node.nodeName.toLowerCase() === BASE_ELEMENT &&
                    node.classList.contains(BASE_ELEMENT_CLASS)
                );
            };

            const getMentionElement = (element?: Element) => {
                const node = element ?? editor.selection.getNode();
                return isMentionElement(node) ? node : null;
            };

            editor.ui.registry.addButton('mentions', {
                text: strings.at,
                onAction: () => {
                    editor.insertContent(strings.at);
                    // Reloads the autocompleter menu with new items.
                    // More - https://arc.net/l/quote/xsxaxflj
                    editor.execCommand('mceAutocompleterReload');
                },
            });

            const handleOpenMention = (e: EditorEvent<MouseEvent>) => {
                const url = getMentionElement(e.target)?.getAttribute(DATA_MENTION_HREF);
                if (!url) {
                    return;
                }

                window.open(`#${url}`, '_blank');
            };

            editor.on('SwitchMode', ({ mode }) => {
                if (mode === 'design') {
                    isViewModeRef.current = false;

                    editor.off('click', handleOpenMention);
                    return;
                }

                isViewModeRef.current = true;
                // to have only one handler first we clean all previous handles
                editor.off('click', handleOpenMention);
                editor.on('click', handleOpenMention);
            });

            editor.on('remove', () => {
                editor.off('click', handleOpenMention);
            });
        },
        [isEnabled]
    );

    const pluginConfiguration = useCallback(() => {
        if (!isEnabled) {
            return {};
        }

        return {
            mentions_fetch: fetchMentions({
                store,
                mentionCategoryRef: mentionBriefingCategoryRef,
                editorRef,
                onError,
            }),
            mentions_min_chars: 0,
            mentions_selector: `${BASE_ELEMENT}.${BASE_ELEMENT_CLASS}`,
            mentions_item_type: 'profile',
            mentions_menu_complete: insertMention(mentionBriefingCategoryRef),
            mentions_menu_hover: hoverMention(mentionBriefingCategoryRef),
            mentions_select: selectMention({ store, isViewModeRef, onError }),
        };
    }, [isEnabled, onError, store]);

    const contentStyles = () => {
        if (!isEnabled) {
            return '';
        }

        return `
            .${BASE_ELEMENT_CLASS} {
                color: var(--arc-colors-brand-default, #305CE0);
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 1.05rem */
                text-decoration-line: underline;
                cursor: ${isViewModeRef.current ? 'pointer' : 'default'}
            }
        `;
    };

    const validElements = () => {
        if (!isEnabled) {
            return '';
        }

        return '|class|data-id|id';
    };

    return {
        mentionBriefingCategoryRef,
        pluginConfiguration,
        setupEditor,
        contentStyles,
        validElements,
    };
};

import { CodeTypeSourceEnum, CodeTypeCategoryEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { ucrSummaryOffenseCodeByCodeSelector } from '~/client-common/core/domain/ucr-summary-offense-codes/state/data';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { prefillUcrOffense } from '../state/ui';
import {
    currentReportOffenseViewModelsSelector,
    currentUcrSourceSelector,
} from '../state/ui/selectors';
import {
    showBuildingInhabited,
    showRobbberyLocation,
    showBurglaryFields,
    showLarcenyTheft,
} from '../utils/ucrClassificationHelper';

import { RRFCodeSelect } from '../../../core/forms/components/selects/CodeSelect';
import { RRFSingleUcrCodeSelect } from '../../../core/forms/components/selects/UcrCodeSelect';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFText } from '../../../core/forms/components/Text';
import FormGroup from '../../../core/forms/components/FormGroup';
import { DashedDivider as _DashedDivider } from '../../../../legacy-redux/components/core/Divider';
import Row from '../../../core/components/Row';
import { FloridaUcrOffenseFieldset } from './FloridaUcrOffenseFieldset';
import GreyHeaderRRFFieldset from './GreyHeaderRRFFieldset';

const strings = componentStrings.reports.UcrClassificationSidePanel.UcrOffenseFieldset;

const arsonBuildingInhabitedOptions = [
    { value: true, display: strings.arsonBuildingInhabited.true },
    { value: false, display: strings.arsonBuildingInhabited.false },
];

const DashedDivider = styled(_DashedDivider)`
    margin-top: 5px;
    margin-bottom: 0;
`;

const DeleteButtonDiv = styled.div`
    & > .mark43-react-form-row-icon-link {
        margin-top: 0;
        margin-left: 15px;
    }
`;

const mapStateToProps = createStructuredSelector({
    ucrCodesByCode: ucrSummaryOffenseCodeByCodeSelector,
    offenseCodes: offenseCodesSelector,
    currentUcrSource: currentUcrSourceSelector,
    currentReportOffenses: currentReportOffenseViewModelsSelector,
    applicationSettings: applicationSettingsSelector,
});

const mapDispatchToProps = function (dispatch) {
    return {
        prefillOffense: (ucrOffenseIndex, offense) =>
            dispatch(prefillUcrOffense(ucrOffenseIndex, offense)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        contextualFormModelByPath({ formModelByPath, ucrOffenseIndex }) {
            return (path) => formModelByPath(`ucrOffenses[${ucrOffenseIndex}].${path}`);
        },
        ucrCode({ ucrCodesByCode, formModelByPath, ucrOffenseIndex }) {
            return () =>
                ucrCodesByCode(
                    formModelByPath(`ucrOffenses[${ucrOffenseIndex}].ucrSummaryCodeCode`)
                );
        },
        ucrOffense({ formModelByPath, ucrOffenseIndex }) {
            return () => formModelByPath(`ucrOffenses[${ucrOffenseIndex}]`);
        },
    })
)(({ ucrOffense, ucrCode, currentUcrSource, ucrOffenseIndex, deleteButton }) => {
    const sequenceNumber = ucrOffenseIndex + 1;
    const { code: ucrCodeCode, category: ucrCodeCategory } = ucrCode() || {};
    const ucrRuleBundle = {
        currentUcrSource,
        ucrCodeCategory,
        ucrCodeCode,
        ucrOffense: ucrOffense(),
    };
    return (
        <GreyHeaderRRFFieldset title={strings.fieldsetHeader(sequenceNumber)}>
            <DeleteButtonDiv>{deleteButton}</DeleteButtonDiv>
            <FormGroup>
                <RRFSingleUcrCodeSelect path="ucrSummaryCodeCode" />
                <RRFDatePicker path="offenseStatusDateUtc" />
                <RRFCodeSelect
                    codeSource={currentUcrSource}
                    codeTypeCategory={CodeTypeCategoryEnum.UCR_CLEARANCE_STATUS.name}
                    path="offenseStatusCodeId"
                    length="md"
                />
                <RRFText path="offenseScore" length="sm" />
                <RRFBooleanSelect path="allSuspectsAreJuvenile" length="md" />
                <RRFBooleanSelect path="isSuspectedHateCrime" length="md" />
            </FormGroup>
            {currentUcrSource === CodeTypeSourceEnum.FL_UCR.name && (
                <FloridaUcrOffenseFieldset ucrRuleBundle={ucrRuleBundle} />
            )}
            {showRobbberyLocation(ucrRuleBundle) && (
                <Row>
                    <RRFCodeSelect
                        codeSource={currentUcrSource}
                        codeTypeCategory={CodeTypeCategoryEnum.UCR_ROBBERY_LOCATION_TYPE.name}
                        path="robberyLocationCodeId"
                        length="lg"
                    />
                </Row>
            )}
            {showBurglaryFields(ucrRuleBundle) && (
                <Row>
                    <RRFCodeSelect
                        codeSource={currentUcrSource}
                        codeTypeCategory={CodeTypeCategoryEnum.UCR_BURGLARY_RESIDENCE_TYPE.name}
                        path="burglaryResidenceTypeCodeId"
                        length="lg"
                    />
                    <RRFCodeSelect
                        codeSource={currentUcrSource}
                        codeTypeCategory={CodeTypeCategoryEnum.UCR_BURGLARY_TIME_OF_DAY.name}
                        path="burglaryTimeOfDayCodeId"
                        length="lg"
                    />
                </Row>
            )}
            {showBuildingInhabited(ucrRuleBundle) && (
                <Row>
                    <RRFSelect
                        path="arsonBuildingInhabited"
                        options={arsonBuildingInhabitedOptions}
                        length="md"
                    />
                </Row>
            )}
            {showLarcenyTheft(ucrRuleBundle) && (
                <Row>
                    <RRFCodeSelect
                        codeSource={currentUcrSource}
                        codeTypeCategory={CodeTypeCategoryEnum.UCR_LARCENY_THEFT_AMOUNT.name}
                        path="larcenyTheftCategoryCodeId"
                        length="lg"
                    />
                </Row>
            )}
            <DashedDivider />
        </GreyHeaderRRFFieldset>
    );
});

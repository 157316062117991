import React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';
import styled from 'styled-components';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatRenSequenceNumberSelector } from '~/client-common/core/domain/item-reporting-event-links/state/ui';
import { formatTitleForVehicleSelector } from '~/client-common/core/domain/vehicles/state/ui';
import Link, { PopoutLink } from '../../../core/components/links/Link';
import HighRiskPropertyLabel from '../../../entity-profiles/property/components/HighRiskPropertyLabel';
import { useIsHighValue } from '../../dashboard/hooks/useIsHighValue';
import Icon from '../../../../legacy-redux/components/core/Icon';
import testIds from '../../../../core/testIds';
import { getIconTypeForItemTypeAttrId } from '../../../core/item-profiles/utils/itemIcons';
import { formatTitleForPropertyRecord } from '../../../../legacy-redux/helpers/propertyRecordHelpers';

import { ITEM_INFO_PADDING, ITEM_INFO_ICON_WIDTH } from '../configuration';

const ItemInfoWrapper = styled.div`
    display: flex;
    overflow-wrap: break-word;
    flex: 1;
`;
const ContentContainer = styled.div`
    width: ${({ width }) =>
        width === undefined ? '100%' : `${width - ITEM_INFO_ICON_WIDTH - ITEM_INFO_PADDING * 2}px`};
`;
const SemiBold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const StyledLink = styled(Link)`
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const ItemProfileLink = ({ title, itemTypePath, masterItemId, openInNewTab, onClick }) => {
    if (!openInNewTab) {
        return (
            <StyledLink
                testId={testIds.ITEM_PROFILE_LINK}
                to={`/profiles/${itemTypePath}/${masterItemId}`}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}
            >
                {title}
            </StyledLink>
        );
    }

    return (
        <PopoutLink
            to={`/profiles/${itemTypePath}/${masterItemId}`}
            testId={testIds.ITEM_PROFILE_LINK}
        >
            {title}
        </PopoutLink>
    );
};

function ItemInfo({
    width, // optional; will be the table cell width if no width defined
    viewModel,
    className,
    renSequenceNumber,
    disableLink,
    hideItemType = false,
    hideSerialNumber = false,
    hideItemIdentifiers = false,
    openInNewTab = false,
    hideIcon = false,
    formatTitleForVehicle,
    showHighRiskLabel,
    onClick,
    router,
}) {
    const {
        masterItemId,
        itemTypeAttrId,
        serialNumber,
        isHighValue,
        isInHighValueContainer,
    } = viewModel;
    const { itemTypeAttrId: itemType, identifiers } = getViewModelProperties(viewModel);

    const isVehicle = itemTypeAttrId === globalAttributes.itemType.vehicle;
    const itemTypePath = isVehicle ? 'vehicles' : 'property';
    const itemIsHighValue = useIsHighValue({ isHighValue, isInHighValueContainer, masterItemId });

    const title = isVehicle
        ? formatTitleForVehicle(viewModel)
        : formatTitleForPropertyRecord(viewModel, false);

    const itemIdentifiers =
        !hideItemIdentifiers && identifiers
            ? map(identifiers, (identifierObj) => {
                  const { itemIdentifierType } = getViewModelProperties(identifierObj);
                  return (
                      <div key={identifierObj.id} label={itemIdentifierType}>
                          {`${itemIdentifierType} ${identifierObj.identifier}`}
                      </div>
                  );
              })
            : '';

    return (
        <ItemInfoWrapper className={className} data-test-id={testIds.EVIDENCE_BASKET_ITEM_INFO}>
            {!hideIcon && (
                <div>
                    <Icon
                        type={getIconTypeForItemTypeAttrId(itemTypeAttrId)}
                        width={`${ITEM_INFO_ICON_WIDTH}px`}
                    />
                </div>
            )}
            <ContentContainer width={width}>
                {disableLink ? (
                    <SemiBold>{title}</SemiBold>
                ) : (
                    <ItemProfileLink
                        openInNewTab={openInNewTab}
                        itemTypePath={itemTypePath}
                        masterItemId={masterItemId}
                        title={title}
                        onClick={onClick}
                        router={router}
                    />
                )}
                {!hideItemType && <div>{itemType}</div>}
                {!hideSerialNumber && <div>{serialNumber}</div>}
                {renSequenceNumber && <div>{renSequenceNumber}</div>}
                {itemIdentifiers}
                {showHighRiskLabel && itemIsHighValue && <HighRiskPropertyLabel />}
            </ContentContainer>
        </ItemInfoWrapper>
    );
}

const mapStateToProps = createStructuredSelector({
    formatRenSequenceNumber: formatRenSequenceNumberSelector,
    formatTitleForVehicle: formatTitleForVehicleSelector,
});

/**
 * Summary view for a master item's info including its description, type,
 *   category, make, model, serial number, REN-Sequence Number, and icon (not
 *   image thumbnail) to the left.
 * @param {number} width
 * @param {object} viewModel
 * @param {number} reportId     for REN-Sequence Number
 * @param {boolean} disableLink
 * @param {boolean} hideRenSequence
 * @param {boolean} [hideItemType=false]
 * @param {boolean} [hideSerialNumber=false]
 * @param {boolean} [hideItemIdentifiers=false]
 */
export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['reportId', 'viewModel', 'formatRenSequenceNumber', 'hideRenSequence'],
        ({ reportId, viewModel, formatRenSequenceNumber, hideRenSequence }) => {
            if (hideRenSequence) {
                return;
            }
            const renSequenceNumber = formatRenSequenceNumber(
                viewModel.masterItemId,
                // item queue view models contain reportId
                reportId || viewModel.reportId
            );
            return { renSequenceNumber };
        }
    )
)(ItemInfo);

import React from 'react';
import styled from 'styled-components';
import { Flex } from '@arc/layout';
import { Avatar } from '@arc/avatar';
import { Text } from '@arc/typography';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { CrashDiagramAssetType, crashDiagramAssetTypes } from '../config';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { useScreenBreakpoint } from '../../../../../../core/utils/useScreenBreakpoint';

const strings = componentStrings.dragon.crashDiagram.CrashDiagramModal;

type Props = {
    onClick: (assetType: CrashDiagramAssetType) => void;
};

const SelectedBackgroundThumbnail = styled(Avatar)`
    filter: brightness(80%) contrast(160%);
    margin-left: var(--arc-space-1);
`;

export const TemplateThumbnail: React.FC<Props> = ({ onClick: onOuterClick }) => {
    const { backgroundImage } = useCrashDiagram();
    const { isMobile } = useScreenBreakpoint();

    const onClick = () => {
        onOuterClick(crashDiagramAssetTypes.STREET_BACKGROUNDS);
    };

    return (
        <Flex justifyContent="end" alignItems="center">
            {!isMobile && (
                <Text variant="caption" fontWeight="bold">
                    {strings.streetLayersButtonText}
                </Text>
            )}
            <SelectedBackgroundThumbnail
                size="sm"
                src={backgroundImage}
                ignoreFallback={true}
                onClick={onClick}
            />
        </Flex>
    );
};

import { createSelector } from 'reselect';
import { ReportComment } from '@mark43/rms-api';
import getReportCommentsResource from '../../resources/reportCommentsResource';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'reportComments';

const reportCommentsModule = createNormalizedModule<ReportComment>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS

const reportCommentsWhereSelector = reportCommentsModule.selectors.entitiesWhereSelector;
export const reportCommentsByReportIdSelector = createSelector(
    reportCommentsWhereSelector,
    (reportCommentsWhere) => (reportId: number) => reportCommentsWhere({ reportId })
);

// ACTIONS
const storeReportComments = reportCommentsModule.actionCreators.storeEntities;
const removeReportComment = reportCommentsModule.actionCreators.deleteEntity;

export function saveReportComment(
    reportId: number,
    comment: string
): ClientCommonAction<Promise<ReportComment>> {
    return (dispatch) => {
        const resource = getReportCommentsResource();
        return resource
            .createReportComment(reportId, comment)
            .then((reportComment: ReportComment) => {
                dispatch(storeReportComments(reportComment));
                return reportComment;
            });
    };
}

export function deleteReportComment(id: number): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        const resource = getReportCommentsResource();
        return resource.deleteReportComment(id).then((success: boolean) => {
            if (!success) {
                throw new Error('Failed to remove report comment');
            }
            dispatch(removeReportComment(id));
        });
    };
}

// REDUCER
export default reportCommentsModule.reducerConfig;

import * as React from 'react';
import { Fieldset, MFTFormConfiguration, Observer } from 'markformythree';
import { InlineBanner } from 'arc';
import {
    LocationEntityLinkUiOptions,
    NameReportLinkUiOptions,
    NitemsUiOptions,
} from 'dragon-react';
import { CardSection, CardSubsection } from '../../../../../legacy-redux/components/core/Card';

import {
    DragonCurrentFormValue,
    DragonInlineFormReferenceState,
    RMSDragonConfigurationExtensions,
} from '../../../rms-types';

import { SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { InlineFormReferenceWrapper } from '../inline-form-reference-wrapper';
import { getConfiguredFormPath } from '../../../utils/get-configured-form-path';
import { getCoreModelInstanceId } from '../../../utils/dragon-instance-ids';
import { assertArray } from '../../../utils/assert-array';
import { getInlineFormValues } from '../../../utils/get-inline-form-values';
import { useMFTFormContext } from '../../../context/mft-form';
import { isDragonInlineFormReferenceState } from '../../../utils/is-dragon-inline-form-reference-state';

const RMSDragonFieldsetContext = React.createContext(0);
const RMSDragonNItemsNestingContext = React.createContext(0);

export const RMSDragonFieldsetHelper: React.FC<
    {
        label?: string;
        render: () => JSX.Element;
        testId?: string;
    } & Record<`data-${string}`, string>
> = ({ label, render, testId, ...props }) => {
    const parentNesting = React.useContext(RMSDragonFieldsetContext);

    // The root level fieldset on every form should just increment the nesting counter
    if (parentNesting === 0) {
        return (
            <RMSDragonFieldsetContext.Provider value={parentNesting + 1}>
                {render()}
            </RMSDragonFieldsetContext.Provider>
        );
    }

    // If there is no label, we of course do not render a label nor do we increment
    // the nesting counter
    if (!label) {
        return render();
    }

    // The style of "fieldset label" that we should depends on nesting depth
    const SectionComponent = parentNesting === 1 ? CardSection : CardSubsection;

    // When rendering a fieldset label, we both render the label and make sure to
    // increment the nesting counter
    return (
        <RMSDragonFieldsetContext.Provider value={parentNesting + 1}>
            <SectionComponent
                title={label}
                className={undefined}
                testId={testId}
                helpText={undefined}
                error={undefined}
                {...props}
            >
                {render()}
            </SectionComponent>
        </RMSDragonFieldsetContext.Provider>
    );
};

export const RMSDragonNItemsNestingCounter: React.FC<{
    render: (options: { nestingLevel: number }) => JSX.Element | null;
}> = ({ render }) => {
    const currentNestingLevel = React.useContext(RMSDragonNItemsNestingContext);
    return (
        <RMSDragonNItemsNestingContext.Provider value={currentNestingLevel + 1}>
            {render({ nestingLevel: currentNestingLevel })}
        </RMSDragonNItemsNestingContext.Provider>
    );
};

export function InlineFormReferenceListEntry({
    entityId,
    isLoading,
    setIsLoading,
    currentFormNestingPath,
    options,
    form,
}: {
    entityId: number;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    currentFormNestingPath: DragonCurrentFormValue[];
    options:
        | NitemsUiOptions<RMSDragonConfigurationExtensions>
        | NameReportLinkUiOptions<RMSDragonConfigurationExtensions>
        | LocationEntityLinkUiOptions<RMSDragonConfigurationExtensions>;
    form: ReturnType<typeof useMFTFormContext>;
}): JSX.Element {
    return (
        <Observer<{ currentFormValue: DragonInlineFormReferenceState[] }, MFTFormConfiguration>
            pathIsRelative={false}
            subscriptions={{
                currentFormValue: options.fullyQualifiedPath,
            }}
            render={({
                currentFormValue,
            }: {
                currentFormValue: DragonInlineFormReferenceState[];
            }) => {
                // We have to match the index of the LEL to the correct index in our form state, in case the LEL order is different
                // than what we have in form state.
                const index = currentFormValue.findIndex(
                    (state) => getCoreModelInstanceId(state) === entityId
                );

                if (index === -1) {
                    return isLoading ? (
                        <SimpleLoading />
                    ) : (
                        <InlineBanner
                            status="error"
                            title=""
                            description="Failed to load form data for this item, please try again"
                            actions={[
                                {
                                    onClick: () => {
                                        setIsLoading(true);
                                        getInlineFormValues({
                                            instanceIds: [entityId],
                                            parentFormPathInstance: getConfiguredFormPath(
                                                currentFormNestingPath
                                            ),
                                            parentReferencingUiConfigurationId:
                                                options.configuration.id,
                                            configuredFormId:
                                                options.configuration.formConfigurationId,
                                        })
                                            .then((inlineFormValueViews) => {
                                                const currentFormValue = form.get(
                                                    options.fullyQualifiedPath
                                                );
                                                assertArray(currentFormValue);
                                                form.set(options.fullyQualifiedPath, [
                                                    ...currentFormValue,
                                                    ...inlineFormValueViews
                                                        .map(
                                                            (inlineFormValueView) =>
                                                                inlineFormValueView.values
                                                        )
                                                        .filter(isDragonInlineFormReferenceState),
                                                ]);
                                            })
                                            .catch(() => {
                                                // Intentional no-op. We do not need to show any other UI apart from this banner.
                                                // Since it is driven by missing values in form state we have no need to explicitly
                                                // store an error flag for this failure.
                                            })
                                            .finally(() => setIsLoading(false));
                                    },
                                    children: 'Retry',
                                },
                            ]}
                        />
                    );
                }
                return (
                    <InlineFormReferenceWrapper
                        options={{
                            ...options,
                            // we have to append the index to the path in order to get the correct value out of our form state
                            fullyQualifiedPath: `${options.fullyQualifiedPath}[${index}]`,
                        }}
                    >
                        <Fieldset path={`[${index}]`}>
                            {options.renderChildren({
                                index,
                                key: entityId,
                            })}
                        </Fieldset>
                    </InlineFormReferenceWrapper>
                );
            }}
        />
    );
}

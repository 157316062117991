import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    FeatureLayerConstructorT,
    FeatureLayerT,
    GraphicConstructorT,
    LocationCoordinates,
    WebMapT,
} from '../../types';
import addressIconSvgString from '../../images/address';
import { spatialReference, useStaticLayerControl } from './staticFeatureLayerHelpers';

type SelectedLocationFeatureLayerProps = {
    selectedLocation?: LocationCoordinates;
    webmap?: WebMapT;
    FeatureLayerConstructor?: FeatureLayerConstructorT;
    GraphicConstructor?: GraphicConstructorT;
};

// build selectedLocation graphic
export const createSelectedLocationGraphic = (
    selectedLocation: LocationCoordinates,
    GraphicConstructor: GraphicConstructorT
) => {
    return new GraphicConstructor({
        geometry: {
            // @ts-expect-error esri types seem to be wrong https://developers.arcgis.com/javascript/latest/api-reference/esri-Graphic.html#geometry
            type: 'point',
            longitude: selectedLocation.longitude,
            latitude: selectedLocation.latitude,
        },
    });
};

export const useStaticSelectedLocationFeatureLayer = ({
    selectedLocation,
    webmap,
    FeatureLayerConstructor,
    GraphicConstructor,
}: SelectedLocationFeatureLayerProps) => {
    const selectedLocationArray = useMemo(() => (selectedLocation ? [selectedLocation] : []), [
        selectedLocation,
    ]);
    const [selectedLocationLayer, setSelectedLocationLayer] = useState<FeatureLayerT>();

    const createSelectedLocationGraphics = useCallback(
        (selectedLocations: LocationCoordinates[]) => {
            if (!GraphicConstructor) {
                return [];
            }
            return selectedLocations.map((selectedLocation) => {
                return createSelectedLocationGraphic(selectedLocation, GraphicConstructor);
            });
        },
        [GraphicConstructor]
    );

    // handles initializing empty feature layer
    useEffect(() => {
        // need modules to load
        if (webmap && FeatureLayerConstructor && GraphicConstructor) {
            const selectedLocationFeatureLayer = getSelectedLocationLayer(FeatureLayerConstructor);
            setSelectedLocationLayer(selectedLocationFeatureLayer);
            webmap.layers.add(selectedLocationFeatureLayer);
        }
    }, [FeatureLayerConstructor, GraphicConstructor, webmap]);

    useStaticLayerControl({
        layer: selectedLocationLayer,
        currItems: selectedLocationArray,
        createGraphics: createSelectedLocationGraphics,
        replaceExisting: true,
    });

    return {
        layer: selectedLocationLayer,
    };
};

const addressIconSvgStringEncoded = btoa(addressIconSvgString);

const selectedLocationIconRenderer = {
    type: 'unique-value', // autocasts as new UniqueValueRenderer()
    field: ' ',
    defaultSymbol: {
        // used when item field dont match anything defined in uniqueValueInfos
        type: 'picture-marker',
        url: `data:image/svg+xml;base64,${addressIconSvgStringEncoded}`,
        width: '22px',
        height: '32px',
    },
};

const getSelectedLocationLayer = (FeatureLayerConstructor: FeatureLayerConstructorT) => {
    const selectedLocationFeatureLayer = new FeatureLayerConstructor({
        title: 'Selected Location',
        source: [], // initialize with an empty collection
        renderer: selectedLocationIconRenderer as __esri.RendererProperties,
        objectIdField: 'objectId', // This must be defined when creating a layer from `Graphic` objects
        outFields: ['*'],
        geometryType: 'point',
        spatialReference,
    });

    return selectedLocationFeatureLayer;
};

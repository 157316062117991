import { css } from 'styled-components';
import { mediaQueries } from 'arc';

export const clearfix = `
    &::after {
        clear: both;
        content: ' ';
        display: table;
    }
`;

export const noFocusOutline = `
    &:focus {
        outline: 0;
    }
`;

// transform is first because otherwise ie9 breaks
const transform = (transformation: string) => `
    transform: ${transformation};
    -webkit-transform: ${transformation};
    -ms-transform: ${transformation};
`;

export const horizVertCenter = `
    position: absolute;
    top: 50%;
    left: 50%;
    ${transform('translate(-50%, -50%)')}
`;

export const horizCenter = `
    position: absolute;
    left: 50%;
    ${transform('translateX(-50%)')}
`;

export const placeholder = (color: string) => `
    ::-webkit-input-placeholder {
        color: ${color};
    }
    :-moz-placeholder {
       color: ${color};
       opacity:  1;
    }
    ::-moz-placeholder {
       color: ${color};
       opacity:  1;
    }
    :-ms-input-placeholder {
       color: ${color};
    }
    ::-ms-input-placeholder {
       color: ${color};
    }
`;

/**
 * This style should be applied to every form input's focus state. There is legacy Sass with this
 *   style that needs to be refactored.
 *
 * The 2 properties combine for a 3px border since every input is designed with a 1px border.
 */
export const inputFocus = css`
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.cobaltBlue};
    border-color: ${(props) => props.theme.colors.cobaltBlue};
    outline: none;
`;

/** Mixin to use column direction on smaller screens and row on larger screens.
 * Optionally accepts a mediaQuery but defaults to `md`. */
export const responsiveStack = (mediaQuery: keyof typeof mediaQueries = 'md') => `
    display: flex;
    flex-direction: column;

    @media (min-width: ${mediaQueries[mediaQuery]}) {
        flex-direction: row;
    }
`;

/** Mixin to use style text so that it truncates on a single line
 * and shows a ellipsis even if it's single long string.
 */
export const truncateText = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

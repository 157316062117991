import styled from 'styled-components';

import Row from '../../../core/components/Row';

export const FlexRow = styled(Row)`
    display: flex;
    align-items: center;
`;

export const IndentedRow = styled(Row)`
    margin-left: 20px;
`;

export const RowTopMargin = styled(Row)`
    margin-top: 30px;
`;

export const EntityFlexRow = styled(FlexRow)`
    margin-bottom: 16px;
`;

import React from 'react';
import { Fieldset as MFTFieldset } from 'markformythree';

// components
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../forms/components/Text';
import FormRow from '../../forms/components/FormRow';

import { ArbiterMFTAttributeMultiSelectButtonRadio } from '../../forms/components/button-radios/AttributeMultiSelectButtonRadio';

export const ATTRIBUTE_FIELDSET_DISPLAY_AS = {
    BUTTON_RADIO: 'BUTTON_RADIO',
    SELECT: 'SELECT',
};

// TODO refactor this and merge with `AttributeSelectFieldset` in `FullOffenseForm`
export function PersonProfileFormAttributeFieldset({
    path,
    attributeType,
    grouped = false,
    multiple = true,
    length = undefined,
    displayAs = ATTRIBUTE_FIELDSET_DISPLAY_AS.SELECT,
}) {
    return (
        <MFTFieldset path={path}>
            {displayAs === ATTRIBUTE_FIELDSET_DISPLAY_AS.BUTTON_RADIO ? (
                <ArbiterMFTAttributeMultiSelectButtonRadio
                    path="attributeIds"
                    attributeType={attributeType}
                    valueAsArray={true}
                    multiple={multiple}
                    length={length}
                />
            ) : (
                <ArbiterMFTAttributeSelect
                    path="attributeIds"
                    attributeType={attributeType}
                    multiple={!!multiple}
                    grouped={grouped}
                    length={length}
                />
            )}
            <FormRow hasIndent>
                <ArbiterMFTText path="description" length="lg" />
            </FormRow>
        </MFTFieldset>
    );
}

import React, { FC, useCallback, useEffect } from 'react';
import { TaskView } from '@mark43/rms-api';
import { Box } from '@arc/layout';
import { TableProps, Tr } from '@arc/table';
import { DataTable, DataTableProps } from '@arc/data-table';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../../core/testIds';
import NoDataBlock from '../../../../core/components/NoDataBlock';
import { SkeletonTable } from '../../../../core/components/Skeleton';
import { useLoadTaskAttributes } from '../../hooks/useLoadTaskAttributes';
import { useTasksTableColumns } from './columns';

const strings = componentStrings.tasks.core.TaskTable;

const defaultResults: TaskView[] = [];
const defaultHiddenColumns: string[] = [];

const tableProps: TableProps = { size: 'sm' };

const Wrapper = styled(Box)`
    flex: 1;
    & th {
        font-size: var(--arc-fontSizes-sm);
        font-weight: var(--arc-fontWeights-semibold);
        position: sticky;
        top: 0;
        z-index: 10;
    }
    & .arc-table_container {
        height: 70vh;
        overflow-y: scroll;
    }
`;

type TasksTableProps = Omit<DataTableProps<TaskView>, 'columns' | 'data'> & {
    results?: TaskView[];
    hiddenColumns?: string[];
    loading?: boolean;
    initialLoading?: boolean;
};

export const TasksTable: FC<TasksTableProps> = ({
    results = defaultResults,
    hiddenColumns = defaultHiddenColumns,
    loading,
    initialLoading,
    ...dataTableProps
}) => {
    const columns = useTasksTableColumns(hiddenColumns);

    const renderRow = useCallback(({ row, renderedCells }) => {
        return (
            <Tr key={row.id} data-test-id={testIds.TASK_TABLE_ROW}>
                {renderedCells}
            </Tr>
        );
    }, []);

    useEffect(() => {
        const headerRow = document.querySelector('.arc-table_header > .arc-table_row');
        if (headerRow) {
            headerRow.setAttribute('data-test-id', testIds.TASK_TABLE_ROW);
        }
    });

    useLoadTaskAttributes();

    const firstLoading = loading && initialLoading;

    return (
        <>
            {/* TODO: change it to return NoDataBlock and SkeletonTable separately */}
            {/* for some reason, the width of columns is not set correctly if DataTable is not mounted immediately */}
            {/* could be reproduced after hot reload as well */}
            {firstLoading && <SkeletonTable />}
            {results.length === 0 && !firstLoading && (
                <Box textAlign="center">
                    <NoDataBlock>{strings.noResults}</NoDataBlock>
                </Box>
            )}
            <Wrapper
                display={results.length === 0 || firstLoading ? 'none' : 'block'}
                data-test-id={testIds.TASK_TABLE}
            >
                <DataTable
                    data={results}
                    columns={columns}
                    hasColumnOrdering={false}
                    hasColumnResizing={false}
                    hasColumnVisibility={false}
                    tableProps={tableProps}
                    renderRow={renderRow}
                    {...dataTableProps}
                />
            </Wrapper>
        </>
    );
};

import { get, compact, map } from 'lodash';

import {
    PersonProfile,
    NameNameLink,
    NameReportLink,
    OrganizationProfile,
    LinkTypesEnumType,
    EntityTypeEnumType,
    ComplianceGroupEnumType,
    RegionalGroupEnumType,
} from '@mark43/rms-api';

import { organizationProfileByIdSelector } from '~/client-common/core/domain/organization-profiles/state/data';
import { relationshipsDataSelector } from '../../reports/core/state/ui/relationships';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

import formsRegistry from '../../../core/formsRegistry';
import { DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_COMPLIANCE_GROUP } from './helpers/domesticViolenceRelationshipsByComplianceGroup';
import { DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP } from './helpers/domesticViolenceRelationshipsByRegionalGroup';
import { victimHasValidDomesticViolenceRelationship } from './helpers/victimHasValidDomesticViolenceRelationship';

export const isSupportedComplianceGroup = (
    complianceGroupId?: ComplianceGroupEnumType
): complianceGroupId is keyof typeof DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_COMPLIANCE_GROUP => {
    return !!DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_COMPLIANCE_GROUP[
        complianceGroupId as keyof typeof DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_COMPLIANCE_GROUP
    ];
};

export const isSupportedRegionalGroup = (
    nibrsRegionalGroup?: RegionalGroupEnumType
): nibrsRegionalGroup is keyof typeof DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP => {
    return !!DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP[
        nibrsRegionalGroup as keyof typeof DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP
    ];
};

export type RelationshipDataType = {
    nameNameLinks: NameNameLink[];
    nameReportLinks: NameReportLink[];
    personProfiles: PersonProfile[];
    organizationProfiles: OrganizationProfile[];
};

export type RelationshipType = {
    otherNameId: number;
    otherNameEntityType: EntityTypeEnumType;
    linkOptionIds: LinkTypesEnumType[];
    nameId: number;
    nameEntityType: EntityTypeEnumType;
    displayLinkOptionIds: string[];
};

export const getIdsForLinkTypeFromRelationshipData = (
    relationshipData: RelationshipDataType,
    targetLinkType: LinkTypesEnumType
) =>
    compact(
        map(get(relationshipData, 'nameReportLinks'), (nrl) => {
            if (nrl.linkType === targetLinkType) {
                return { nameId: nrl.nameId, offenseId: nrl.contextId };
            }
            return;
        })
    );

export const getVictimHasValidDomesticViolenceRelationship = (getState: () => RootState) => (
    value?: string | number
) => {
    const state = getState();
    const relationshipData: RelationshipDataType = relationshipsDataSelector(state);
    const organizationProfileById = organizationProfileByIdSelector(state);
    const currentUserDepartmentProfile = currentUserDepartmentProfileSelector(state);
    const complianceGroup = currentUserDepartmentProfile?.complianceGroup;
    const nibrsRegionalGroup = currentUserDepartmentProfile?.nibrsRegionalGroup;
    const victimId =
        typeof value === 'number' ? value : typeof value === 'string' ? parseInt(value) : undefined;

    if (victimId) {
        if (nibrsRegionalGroup) {
            return victimHasValidDomesticViolenceRelationship({
                nibrsRegionalGroup,
                relationshipData,
                victimId,
                formsRegistry,
                organizationProfileById,
            });
        } else if (complianceGroup) {
            return victimHasValidDomesticViolenceRelationship({
                complianceGroup,
                relationshipData,
                victimId,
                formsRegistry,
                organizationProfileById,
            });
        }
        return false;
    } else {
        // If there is no victim, then the relationship is valid
        return true;
    }
};

import { compact, get, uniq, values } from 'lodash';
import { createSelector } from 'reselect';
import { ruleTypes } from 'arbiter-mark43';

import { currentReportSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import createFormModule from './createFormModule';

/**
 * Configures form to keep templates in error strings instead of interpolating. There is no need
 * to access any data from redux state since this should only be used in forms where we wish to
 * leave error string templates as is.
 */
export const ruleRunnerConfigurationWithTemplateStringErrorsSelector = createSelector(
    () => ({ effectsConfiguration = {} }) => {
        return {
            effectsConfiguration: {
                ...effectsConfiguration,
                keepTemplateString: true,
            },
        };
    }
);

const ruleRunnerConfigurationSelector = (state) => ({ effectsConfiguration = {} }) => {
    const isLegacyReport = !!get(currentReportSelector(state), 'isLegacyReport');

    return {
        effectsConfiguration: {
            ...effectsConfiguration,
            keepTemplateString: true,
            skipTypes: uniq(
                compact([
                    ...values(get(effectsConfiguration, 'skipTypes')),
                    isLegacyReport ? ruleTypes.VALIDATION : undefined,
                ])
            ),
        },
    };
};

const createReportFormModule = (configuration) => {
    return createFormModule({
        ...configuration,
        ruleRunnerConfigurationSelector,
    });
};

export default createReportFormModule;

import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import _ from 'lodash';
import {
    queryParamDefaults,
    allQueryParamDefaults,
} from '~/client-common/configs/advancedSearchConfig';
import { req } from '../../lib/ajax';
import { createResource } from '../../lib/resources/Resource';

const RMS_API_URL = '/rms/api';
const EVIDENCE_API_URL = '/evidence/api';

function elasticSearchFactory(path, defaults = queryParamDefaults, baseUrl = RMS_API_URL) {
    /**
     * @param {Object} elasticQuery Search model containing all fields to search for.
     * @param {number} [from=0]
     * @param {number} [size=25]
     * @param {string} [sortKey]
     * @param {string} [sortType]
     * @param {unknown} [dispatch]
     * @param {unknown} [hideLoadingBar]
     * @param {unknown} [elasticSearchType]
     * @param {unknown} [requestFeatureTag]
     */
    return (
        elasticQuery,
        from = defaults.FROM,
        size = defaults.SIZE,
        sortKey,
        sortType,
        dispatch,
        hideLoadingBar,
        elasticSearchType,
        requestFeatureTag
    ) => {
        return req({
            baseUrl,
            method: 'POST',
            url: `elastic_search/${path}`,
            hideLoadingBar,
            params: {
                auto_save_type: elasticSearchType,
                request_feature_tag: requestFeatureTag,
            },
            data: {
                elasticQuery,
                from,
                size,
                sortKey,
                sortType,
            },
        });
    };
}

const searchTypeToQueryParam = {
    [ElasticSearchTypeEnum.REPORT.name]: 'include_reports',
    [ElasticSearchTypeEnum.PERSON.name]: 'include_persons',
    [ElasticSearchTypeEnum.CAD_TICKET.name]: 'include_cad_tickets',
    [ElasticSearchTypeEnum.ORGANIZATION.name]: 'include_organizations',
    [ElasticSearchTypeEnum.VEHICLE.name]: 'include_vehicles',
    [ElasticSearchTypeEnum.PROPERTY.name]: 'include_property',
    [ElasticSearchTypeEnum.WARRANT.name]: 'include_warrants',
    [ElasticSearchTypeEnum.CASE.name]: 'include_cases',
    [ElasticSearchTypeEnum.COURT_ORDERS.name]: 'include_court_orders',
};

export default createResource({
    name: 'Elastic Search Resource',
    methods: {
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<import('@mark43/rms-api').SearchResultElasticReport>}
         */
        searchReports: elasticSearchFactory('reports'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchCadTickets: elasticSearchFactory('cad_tickets'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchPersons: elasticSearchFactory('persons'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchLocations: elasticSearchFactory('locations'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchOrganizations: elasticSearchFactory('organizations'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchVehicles: elasticSearchFactory('vehicles'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchProperty: elasticSearchFactory('property'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchAttachments: elasticSearchFactory('files'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchWarrants: elasticSearchFactory('warrants'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchEvidenceItems: elasticSearchFactory('evidence_items', undefined, EVIDENCE_API_URL),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=25]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @param  {undefined} [dispatch]
         * @param  {boolean} [hideLoadingBar]
         * @param  {string} [elasticSearchType]
         * @param  {unknown} [requestFeatureTag]
         * @return {Promise<SearchResultElasticStorageLocation>}
         */
        searchStorageLocations: elasticSearchFactory(
            'storage_locations',
            undefined,
            EVIDENCE_API_URL
        ),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=75]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchCases: elasticSearchFactory('cases'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchUsers: elasticSearchFactory('users'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchUsersWithMfaConfig: elasticSearchFactory('users-with-mfa-config'),
        /**
         * @param  {Object} elasticQuery Search model containing all fields to
         *   search for.
         * @param  {number} [from=0]
         * @param  {number} [size=50]
         * @param  {string} [sortKey]
         * @param  {string} [sortType]
         * @return {Promise<Object>}
         */
        searchCourtOrders: elasticSearchFactory('court-orders'),

        /**
         * @param  {object}   options
         * @param  {string}   options.query         Search query string.
         * @param  {number}   [options.from=0]
         * @param  {number}   [options.size=5]
         * @param  {string[]} [options.searchTypes] Types of entities to search for.
         * @param  {boolean}  [options.excludeExternalAgencyResults] Whether to exclude external agency results
         * @return {Promise<QuickSearchResult>}
         */
        searchAll({
            query = '',
            from = allQueryParamDefaults.FROM,
            size = allQueryParamDefaults.SIZE,
            searchTypes,
            excludeExternalAgencyResults,
        }) {
            const searchTypesQueryObject = _(searchTypes)
                .filter((searchType) => searchTypeToQueryParam[searchType])
                .mapKeys((searchType) => searchTypeToQueryParam[searchType])
                .mapValues(() => true)
                .value();

            return req({
                method: 'GET',
                url: 'elastic_search/all',
                params: {
                    q: query,
                    from,
                    size,
                    exclude_external_results: excludeExternalAgencyResults,
                    ...searchTypesQueryObject,
                },
            });
        },
        quickSearchCases({
            query = '',
            from = allQueryParamDefaults.FROM,
            size = allQueryParamDefaults.SIZE,
            excludeExternalResults,
        }) {
            return req({
                method: 'GET',
                url: 'elastic_search/cases/quick_search',
                params: {
                    q: query,
                    from,
                    size,
                    exclude_external_results: excludeExternalResults,
                },
            });
        },
        // Saved Search endpoints
        /**
         * @param {number} savedSearchId
         * @returns {Promise<SavedSearch>}
         */
        copySavedSearch(savedSearchId) {
            return req({
                method: 'POST',
                url: `elastic_search/saved_searches/copy/${savedSearchId}`,
            });
        },
        executeSavedSearch(savedSearch, showNew = false) {
            return req({
                method: 'PUT',
                url: 'elastic_search/saved_searches/execute',
                data: savedSearch,
                params: {
                    from: queryParamDefaults.FROM,
                    size: queryParamDefaults.SIZE,
                    showNew,
                },
            });
        },
        saveSearch(newSavedSearch) {
            return req({
                method: 'POST',
                url: 'elastic_search/saved_searches',
                data: [newSavedSearch],
            });
        },
        updateSearch(updatedSavedSearch) {
            return req({
                method: 'POST',
                url: 'elastic_search/saved_searches/update',
                data: [updatedSavedSearch],
            });
        },
        /**
         * @param {number} savedSearchId
         * @returns {Promise<boolean>}
         */
        deleteSavedSearch(savedSearchId) {
            return req({
                method: 'DELETE',
                url: `elastic_search/saved_searches/${savedSearchId}`,
            });
        },
        searchReportsAggregate(elasticQuery, field) {
            return req({
                method: 'POST',
                url: 'elastic_search/reports/aggregate',
                data: {
                    elasticQuery,
                    aggregateField: field,
                },
            });
        },
        searchCasesByCaseStatusAggregate(elasticQuery) {
            return req({
                method: 'POST',
                url: 'elastic_search/case_status/aggregate',
                data: {
                    elasticQuery,
                },
            });
        },
    },
});

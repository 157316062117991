import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Modal from '../../../../legacy-redux/components/core/Modal';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import {
    submitLabelPrintingModal,
    labelPrintingModalMasterItemIdsSelector,
    labelPrintingModalStorageLocationIdsSelector,
    labelPrintingModalParentStorageLocationFullDisplayPathSelector,
    loadingSelector,
    labelPreviewDataUriSelector,
} from '../state/ui';
import LabelPrintingForm from './LabelPrintingForm';

const context = {
    name: boxEnum.LABEL_PRINTING_MODAL,
};
const strings = componentStrings.evidence.labelPrinting.LabelPrintingModal;

const LabelImage = styled.img`
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    max-width: 100%;
    vertical-align: middle;
`;

const BatchDescription = styled.div`
    box-sizing: border-box;
    clear: both;
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 30px 40px;
    width: 270px;
    text-align: center;
`;

function LabelPrintingPreview({
    labelPreviewDataUri,
    count,
    parentStorageLocationFullDisplayPath,
}) {
    return (
        <div>
            <div className="mark43-form-label mark43-form-row-label">{strings.preview}</div>
            {!!labelPreviewDataUri ? (
                <LabelImage src={labelPreviewDataUri} />
            ) : (
                <BatchDescription>
                    {!!parentStorageLocationFullDisplayPath
                        ? strings.batchDescriptionForStorageLocations(
                              count,
                              parentStorageLocationFullDisplayPath
                          )
                        : strings.batchDescriptionForItems(count)}
                </BatchDescription>
            )}
        </div>
    );
}

/**
 * Modal for printing barcode label(s) for arbitrarily many storage locations or
 *   master items. If there is only 1 storage location or master item, display a
 *   preview image. If there are more than 1, display a text description as the
 *   preview. Submitting the modal prints the labels using the selected label
 *   printer.
 */
export default connect(
    createStructuredSelector({
        loading: loadingSelector,
        labelPreviewDataUri: labelPreviewDataUriSelector,
        masterItemIds: labelPrintingModalMasterItemIdsSelector,
        parentStorageLocationFullDisplayPath: labelPrintingModalParentStorageLocationFullDisplayPathSelector,
        storageLocationIds: labelPrintingModalStorageLocationIdsSelector,
    }),
    (dispatch) => ({
        submitLabelPrintingModal: () => dispatch(submitLabelPrintingModal()),
    })
)(function LabelPrintingModal({
    loading,
    labelPreviewDataUri,
    masterItemIds = [],
    storageLocationIds = [],
    parentStorageLocationFullDisplayPath = '',
    submitLabelPrintingModal,
}) {
    const title =
        masterItemIds.length > 0 ? strings.titleForItems : strings.titleForStorageLocations;

    return (
        <Modal
            context={context}
            title={title}
            onSave={submitLabelPrintingModal}
            saveDisabled={loading}
            okText={strings.print}
            cancelText={strings.close}
        >
            {loading ? (
                <SimpleLoading />
            ) : (
                <div>
                    <LabelPrintingForm />
                    <LabelPrintingPreview
                        labelPreviewDataUri={labelPreviewDataUri}
                        count={masterItemIds.length || storageLocationIds.length}
                        parentStorageLocationFullDisplayPath={parentStorageLocationFullDisplayPath}
                    />
                </div>
            )}
        </Modal>
    );
});

import {
    EfileResource,
    EntityTypeEnumType,
    SqlSearchQueryEFileMaterialSearchRequest,
    SqlSearchQueryEFileNameSearchRequest,
    LinkTypesEnumType,
    ImportMaterialsRequestWrapper,
    ImportProfileRequestWrapper,
} from '@mark43/rms-api';
import { req } from '../../../lib/ajax';
import { createResource } from '../../../lib/resources/Resource';
import { CreateEFileT, EFileCreationRequestT, EFileUpdateRequestT, UpdateEFileT } from '../types';

type LinkTypeResourceParamT =
    | 'DEFENDANT_IN_EFILE'
    | 'INVOLVED_PROFILE_IN_EFILE'
    | 'ITEM_IN_EFILE'
    | 'ATTACHMENT_IN_EFILE';

export const eFileResource = createResource({
    name: 'EFiles Resource',
    methods: {
        getInvolvedProfilesForImport({
            eFileId,
            linkType,
        }: {
            eFileId: number;
            linkType: LinkTypesEnumType;
        }) {
            return req<EfileResource.GetInvolvedProfilesForImport>({
                method: 'GET',
                url: `efile/names/import/${eFileId}/`,
                params: {
                    link_type: linkType,
                },
            });
        },
        getLinkedEFiles({
            entityId,
            entityType,
        }: {
            entityId: number;
            entityType: EntityTypeEnumType;
        }) {
            return req<EfileResource.GetRelatedEFiles>({
                method: 'GET',
                url: 'efile/related',
                params: {
                    entity_id: entityId,
                    entity_type: entityType,
                },
            });
        },
        createEFile(creationReq: EFileCreationRequestT) {
            return req<CreateEFileT>({
                method: 'POST',
                url: 'efile',
                data: creationReq,
            });
        },
        getEFileById(id: number) {
            return req<EfileResource.GetEFile>({
                method: 'GET',
                url: `efile/${id}/`,
            });
        },
        updateEFileDetails(data: EFileUpdateRequestT) {
            return req<UpdateEFileT>({
                method: 'PUT',
                url: 'efile',
                data,
            });
        },
        getInvolvedProfiles(data: SqlSearchQueryEFileNameSearchRequest) {
            return req<EfileResource.GetInvolvedProfiles>({
                method: 'POST',
                url: 'efile/names',
                data,
            });
        },
        getDefendantPersonProfile(eFileId: number, personId: number) {
            return req<EfileResource.GetDefendantPersonProfile>({
                method: 'GET',
                url: `efile/names/${eFileId}/person/defendant/${personId}/`,
            });
        },
        getDefendantOrganizationProfile(eFileId: number, organizationId: number) {
            return req<EfileResource.GetDefendantOrganizationProfile>({
                method: 'GET',
                url: `efile/names/${eFileId}/organization/defendant/${organizationId}/`,
            });
        },
        importInvolvedProfiles(importRequest: ImportProfileRequestWrapper) {
            return req<EfileResource.ImportInvolvedProfiles>({
                method: 'POST',
                url: 'efile/names/import',
                data: importRequest,
            });
        },
        deactivateInvolvedNames({
            eFileId,
            linkType,
            deactivate,
            contextedProfileIds,
        }: {
            eFileId: number;
            linkType: LinkTypeResourceParamT;
            deactivate?: boolean;
            contextedProfileIds: number[];
        }) {
            return req<EfileResource.DeactivateInvolvedNames>({
                method: 'POST',
                url: `efile/${eFileId}/deactivate`,
                params: {
                    // @ts-expect-error mark43-resource type generation issue.
                    link_type: linkType,
                    deactivate,
                },
                data: contextedProfileIds,
            });
        },
        removeInvolvedEntities({
            linkType,
            contextedIds,
        }: {
            contextedIds: number[];
            linkType: LinkTypeResourceParamT;
        }) {
            return req<EfileResource.RemoveInvolvedEntities>({
                method: 'POST',
                url: `efile/names/remove`,
                data: contextedIds,
                params: {
                    // @ts-expect-error mark43-resource type generation issue.
                    link_type: linkType,
                },
            });
        },
        searchEFileItems(data: SqlSearchQueryEFileMaterialSearchRequest) {
            return req<EfileResource.SearchEFileItems>({
                method: 'POST',
                url: 'efile/material/items/search',
                data,
            });
        },
        getItemsForImport(eFileId: number) {
            return req<EfileResource.GetItemsForImport>({
                method: 'GET',
                url: `efile/material/items/import/${eFileId}/`,
            });
        },
        searchEFileAttachments(data: SqlSearchQueryEFileMaterialSearchRequest) {
            return req<EfileResource.SearchEFileAttachments>({
                method: 'POST',
                url: 'efile/material/attachments/search',
                data,
            });
        },
        getAttachmentsForImport(eFileId: number) {
            return req<EfileResource.GetAttachmentsForImport>({
                method: 'GET',
                url: `efile/material/attachments/import/${eFileId}/`,
            });
        },
        importMaterial(data: ImportMaterialsRequestWrapper) {
            return req<EfileResource.ImportMaterial>({
                method: 'POST',
                url: 'efile/material/import',
                data,
            });
        },
        removeMaterials(materialIds: number[]): Promise<boolean> {
            return req<EfileResource.RemoveMaterials>({
                method: 'POST',
                url: 'efile/material/remove',
                data: materialIds,
            });
        },
    },
});

import { mapValues } from 'lodash';
import { createSelector } from 'reselect';
import { roleApprovalsSelector } from '../data';
import { miniUserViewModelsSelector } from '../../../mini-users/state/ui';
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';

export const roleApprovalViewModelsSelector = createSelector(
    roleApprovalsSelector,
    miniUserViewModelsSelector,
    (roleApprovals, miniUserViewModels) => {
        const viewModel = buildViewModel({
            mappers: [
                ({ updatedBy }) => ({
                    updatedBy: miniUserViewModels[updatedBy],
                }),
            ],
        });

        return mapValues(roleApprovals, viewModel);
    }
);

import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { registerForm } from '../../state/ui';
import { createRelationshipsForm } from '../../state/forms/relationshipsForm';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import { CardWithNoPadding } from '../../../../../legacy-redux/components/core/Card';
import withCard from '../../utils/withCard';
import testIds from '../../../../../core/testIds';
import relationshipsCard from '../../state/ui/relationshipsCard';
import formsRegistry from '../../../../../core/formsRegistry';
import { registerCard } from '../../utils/cardsRegistry';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { RelationshipsCardContent } from './RelationshipsCardContent';

class RelationshipsCard extends React.Component {
    constructor(...args) {
        super(...args);

        const { formatFieldByName, arbiter, applicationSettings } = this.props;

        const form = createRelationshipsForm({
            formatFieldByName,
            arbiter,
            initialState: this.props.buildRelationshipsFormModel(),
            isRelationshipsPrefillEnabled: applicationSettings.RMS_RELATIONSHIP_PREFILL_ENABLED,
        });

        registerForm({ form });

        this.unregisterCard = registerCard({
            cardModule: relationshipsCard,
            onSave: this.onSave,
        });
    }

    componentWillUnmount() {
        this.unregisterCard();
        formsRegistry.unregister(RefContextEnum.FORM_RELATIONSHIPS.name);
    }

    onEdit = () => this.props.editCallback(() => this.props.onEdit());

    onSave = () => this.props.onSave(formsRegistry.get(RefContextEnum.FORM_RELATIONSHIPS.name));

    onSaveProgress = () => {
        return this.props.onSaveProgress(formsRegistry.get(RefContextEnum.FORM_RELATIONSHIPS.name));
    };

    render() {
        const { card = {}, involvedNameViewModels, currentReportCardUITitleByType } = this.props;
        const { anchor, summaryMode, saving, canEditReportCardStatus, errorMessages } = card;

        const cardTitle = currentReportCardUITitleByType(reportCardEnum.RELATIONSHIPS.id);

        return (
            <CardWithNoPadding
                className={anchor}
                anchor={anchor}
                title={cardTitle}
                testId={testIds.RELATIONSHIPS_CARD}
                renderContent={(summaryMode) => (
                    <RelationshipsCardContent
                        summaryMode={summaryMode}
                        involvedNameViewModels={involvedNameViewModels}
                    />
                )}
                summaryMode={summaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                onEdit={this.onEdit}
                errors={errorMessages}
                saving={saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class RelationshipsCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={RefContextEnum.FORM_RELATIONSHIPS.name}>
                {(arbiter) => (
                    <RelationshipsCard ref={this.setRef} {...this.props} arbiter={arbiter} />
                )}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
    applicationSettings: applicationSettingsSelector,
});

export default compose(
    withCard(relationshipsCard),
    connect(mapStateToProps, null, null, { forwardRef: true })
)(RelationshipsCardWrapper);

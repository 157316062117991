import { RoleTypeEnum } from '@mark43/rms-api';
import { get, mapValues } from 'lodash';

import { createSelector } from 'reselect';
import { roleByIdSelector } from '~/client-common/core/domain/roles/state/data';
import { currentUserDepartmentIdSelector } from '../../modules/core/current-user/state/ui';

/**
 * The type of the entity that the permissions modal is currently open for.
 * @type {string}
 */
export const entityTypeSelector = (state) => state.data.entityPermissions.entityType;

/**
 * The id of the entity that the permissions modal is currently open for.
 * @type {number}
 */
export const entityIdSelector = (state) => state.data.entityPermissions.entityId;

/**
 * Get the stored permissions of the given entity from data state.
 * @param  {string} entityType
 * @param  {number} entityId
 * @return {Object[]}
 */
export const entityPermissionsDataSelector = (state) => (entityId) => {
    const permissions = state.data.entityPermissions.permissions[entityId];
    const externalDepartmentPermissions =
        state.data.entityPermissions.externalDepartmentPermissions[entityId];
    if (permissions || externalDepartmentPermissions) {
        return [...(permissions || []), ...(externalDepartmentPermissions || [])];
    }
    return undefined;
};

export const entityPermissionsFormSelector = (state) => state.form.entityPermissions;

export const entityPermissionsUiSelector = (state) => state.ui.entityPermissions;

export const entityPermissionViewModelsSelector = createSelector(
    entityPermissionsUiSelector,
    roleByIdSelector,
    currentUserDepartmentIdSelector,
    (ui, roleById, currentUserDepartmentId) =>
        mapValues(ui.permissions, ({ initialRoleId }) => {
            const role = roleById(initialRoleId);
            return {
                // hide delete buttons for existing
                // department-wide permissions for the current department
                undeletable:
                    get(role, 'roleType') === RoleTypeEnum.DEPARTMENT.name &&
                    get(role, 'departmentId') === currentUserDepartmentId,
            };
        })
);

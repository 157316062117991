import { AttributeTypeEnum, FormEnum } from '@mark43/rms-api';

import _, { get, keys, map, omit, pick, pickBy, sortBy } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';
import {
    showDomesticViolenceOnUcrEvent,
    showDomesticViolenceOnUcrOffense,
    showRelationshipCodeId,
    showHomicideFields,
    showArsonLocation,
    showNegligentManslaughter,
    showBuildingInhabited,
    showWeaponOrForceInvolved,
    showRobbberyLocation,
    showBurglaryFields,
    showLarcenyTheft,
    showSuffocationOrStrangulation,
    showIsAntiReproductiveRightsCrime,
    showWereSeniorCitizensVictims,
    showNumberOfSeniorCitizenVictims,
} from '../../utils/ucrClassificationHelper';

const { ATTRIBUTE, FIELDSET, DATE_TIME, UCR_CODE, N_ITEMS_FIELDSET } = fieldTypeClientEnum;

export const fieldViewModels = {
    ucrEvent: {
        type: FIELDSET,
        key: 'ucrEvent',
        fields: buildFlatFormFieldViewModels([
            'eventStatistics',
            {
                key: 'customReportClassificationAttrId',
                type: ATTRIBUTE,
                fieldName: fields.UCR_EVENT_CUSTOM_REPORT_CLASSIFICATION_ATTR_ID,
            },
            {
                key: 'isDomesticViolence',
                fieldName: fields.UCR_EVENT_IS_DOMESTIC_VIOLENCE,
            },
            {
                key: 'weaponOrForceInvolvedCodeId',
                fieldName: fields.UCR_EVENT_WEAPON_OR_FORCE_INVOLVED_CODE_ID,
            },
            {
                key: 'suffocationOrStrangulationInvolvedCodeId',
                fieldName: fields.UCR_SUFFOCATION_OR_STRANGULATION_CODE_ID,
            },
            {
                key: 'isLeoka',
                fieldName: fields.UCR_EVENT_IS_LEOKA,
            },
            {
                key: 'isAntiReproductiveRightsCrime',
                fieldName: fields.UCR_EVENT_IS_ANTI_REPRODUCTIVE_RIGHTS_CRIME,
            },
            {
                key: 'wereSeniorCitizensVictims',
                fieldName: fields.UCR_EVENT_WERE_SENIOR_CITIZENS_VICTIMS,
            },
            {
                key: 'numberOfSeniorCitizenVictims',
                fieldName: fields.UCR_EVENT_NUMBER_OF_SENIOR_CITIZEN_VICTIMS,
            },
        ]),
    },
    ucrOffenses: {
        type: N_ITEMS_FIELDSET,
        key: 'ucrOffenses',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'linkedOffenseIds',
            },
            {
                key: 'ucrSummaryCodeCode',
                type: UCR_CODE,
                fieldName: fields.UCR_OFFENSE_UCR_CODE,
            },
            {
                key: 'offenseStatusCodeId',
                fieldName: fields.UCR_OFFENSE_OFFENSE_STATUS_CODE_ID,
            },
            {
                key: 'offenseStatusDateUtc',
                type: DATE_TIME,
                fieldName: fields.UCR_OFFENSE_OFFENSE_STATUS_DATE_UTC,
            },
            {
                key: 'offenseScore',
                fieldName: fields.UCR_OFFENSE_OFFENSE_SCORE,
            },
            {
                key: 'allSuspectsAreJuvenile',
                fieldName: fields.UCR_OFFENSE_ALL_SUSPECTS_ARE_JUVENILE,
            },
            {
                key: 'robberyLocationCodeId',
                fieldName: fields.UCR_OFFENSE_ROBBERY_LOCATION_CODE_ID,
            },
            {
                key: 'relationshipCodeId',
                fieldName: fields.UCR_OFFENSE_RELATIONSHIP_CODE_ID,
            },
            {
                key: 'homicideWeaponOrForceInvolvedCodeId',
                fieldName: fields.UCR_OFFENSE_HOMICIDE_WEAPON_OR_FORCE_INVOLVED_CODE_ID,
            },
            {
                key: 'arsonLocationCodeId',
                fieldName: fields.UCR_OFFENSE_ARSON_LOCATION_CODE_ID,
            },
            {
                key: 'homicideCodeId',
                fieldName: fields.UCR_OFFENSE_HOMICIDE_CODE_ID,
            },
            {
                key: 'negligentManslaughterCodeId',
                fieldName: fields.UCR_OFFENSE_NEGLIGENT_MANSLAUGHTER_CODE_ID,
            },
            {
                key: 'burglaryResidenceTypeCodeId',
                fieldName: fields.UCR_OFFENSE_BURGLARY_RESIDENCE_TYPE_CODE_ID,
            },
            {
                key: 'burglaryTimeOfDayCodeId',
                fieldName: fields.UCR_OFFENSE_BURGLARY_TIME_OF_DAY_CODE_ID,
            },
            {
                key: 'arsonBuildingInhabited',
                fieldName: fields.UCR_OFFENSE_ARSON_BUILDING_INHABITED,
            },
            {
                key: 'larcenyTheftCategoryCodeId',
                fieldName: fields.UCR_OFFENSE_LARCENY_THEFT_CATEGORY_CODE_ID,
            },
            {
                key: 'isSuspectedHateCrime',
                fieldName: fields.UCR_OFFENSE_IS_SUSPECTED_HATE_CRIME,
            },
            {
                key: 'isDomesticViolence',
                fieldName: fields.UCR_OFFENSE_IS_DOMESTIC_VIOLENCE,
            },
            {
                key: 'sequenceNumber',
            },
        ]),
    },
    ucrProperty: {
        type: N_ITEMS_FIELDSET,
        key: 'ucrProperty',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'propertyStatusId',
                fieldName: fields.UCR_PROPERTY_PROPERTY_STATUS_ID,
            },
            {
                key: 'additionalNotes',
                fieldName: fields.UCR_PROPERTY_ADDITIONAL_NOTES,
            },
            {
                key: 'propertyCategoryCodeId',
                fieldName: fields.UCR_PROPERTY_PROPERTY_CATEGORY_CODE_ID,
            },
            {
                key: 'propertyStatusCodeId',
                fieldName: fields.UCR_PROPERTY_PROPERTY_STATUS_CODE_ID,
            },
            {
                key: 'nearestDollarValue',
                fieldName: fields.UCR_PROPERTY_NEAREST_DOLLAR_VALUE,
            },
            {
                key: 'vehicleRecoveryTypeCodeId',
                fieldName: fields.UCR_PROPERTY_VEHICLE_RECOVERY_TYPE_CODE_ID,
            },
            {
                key: 'vehicleRecoveryDateUtc',
                type: DATE_TIME,
                fieldName: fields.UCR_PROPERTY_VEHICLE_RECOVERY_DATE_UTC,
            },
            {
                key: 'sequenceNumber',
            },
        ]),
    },
};

// need to manually null out hidden fields b/c we aren't using arbiter yet
const nullOutHiddenFieldsForUcrEvent = (ucrEvent, currentUcrSource, ucrOffenseCodes) => {
    const ucrRuleBundle = {
        ucrEvent,
        currentUcrSource,
        ucrOffenseSummaryCodes: ucrOffenseCodes,
    };
    const fieldsToOmit = [];

    if (!showIsAntiReproductiveRightsCrime(ucrRuleBundle)) {
        fieldsToOmit.push('isAntiReproductiveRightsCrime');
    }

    if (!showDomesticViolenceOnUcrEvent(ucrRuleBundle)) {
        fieldsToOmit.push('isDomesticViolence');
    }

    if (!showWeaponOrForceInvolved(ucrRuleBundle)) {
        fieldsToOmit.push('weaponOrForceInvolvedCodeId');
    }

    if (!showSuffocationOrStrangulation(ucrRuleBundle)) {
        fieldsToOmit.push('suffocationOrStrangulationInvolvedCodeId');
    }

    if (!showWereSeniorCitizensVictims(ucrRuleBundle)) {
        fieldsToOmit.push('wereSeniorCitizensVictims');
    }

    if (!showNumberOfSeniorCitizenVictims(ucrRuleBundle)) {
        fieldsToOmit.push('numberOfSeniorCitizenVictims');
    }

    return omit(ucrEvent, fieldsToOmit);
};

// need to manually null out hidden fields b/c we aren't using arbiter yet
const nullOutHiddenFieldsForUcrOffense = (ucrOffense, ucrCodesByCode, currentUcrSource) => {
    const ucrCode = ucrCodesByCode(ucrOffense.ucrSummaryCodeCode);
    const ucrRuleBundle = {
        currentUcrSource,
        ucrCodeCategory: get(ucrCode, 'category'),
        ucrCodeCode: get(ucrCode, 'code'),
        ucrOffense,
    };

    const fieldsToOmit = [];

    if (!showDomesticViolenceOnUcrOffense(ucrRuleBundle)) {
        fieldsToOmit.push('isDomesticViolence');
    }

    if (!showRelationshipCodeId(ucrRuleBundle)) {
        fieldsToOmit.push('relationshipCodeId');
    }

    if (!showHomicideFields(ucrRuleBundle)) {
        fieldsToOmit.push('homicideCodeId', 'homicideWeaponOrForceInvolvedCodeId');
    }

    if (!showNegligentManslaughter(ucrRuleBundle)) {
        fieldsToOmit.push('negligentManslaughterCodeId');
    }

    if (!showArsonLocation(ucrRuleBundle)) {
        fieldsToOmit.push('arsonLocationCodeId');
    }

    if (!showRobbberyLocation(ucrRuleBundle)) {
        fieldsToOmit.push('robberyLocationCodeId');
    }

    if (!showBurglaryFields(ucrRuleBundle)) {
        fieldsToOmit.push('burglaryResidenceTypeCodeId', 'burglaryTimeOfDayCodeId');
    }

    if (!showBuildingInhabited(ucrRuleBundle)) {
        fieldsToOmit.push('arsonBuildingInhabited');
    }

    if (!showLarcenyTheft(ucrRuleBundle)) {
        fieldsToOmit.push('larcenyTheftCategoryCodeId');
    }

    return omit(ucrOffense, fieldsToOmit);
};

// need to manually null out hidden fields b/c we aren't using arbiter yet
const nullOutHiddenFieldsForUcrProperty = (ucrProperty, codes) => {
    const propertyCategoryCode = get(codes[ucrProperty.propertyCategoryCodeId], 'code');
    const propertyStatusCode = get(codes[ucrProperty.propertyStatusCodeId], 'code');

    const recoveryFieldsToHide =
        propertyStatusCode === 'R' ? [] : ['vehicleRecoveryTypeCodeId', 'vehicleRecoveryDateUtc'];

    const vehicleFieldsToHide = propertyCategoryCode === '24' ? [] : ['vehicleRecoveryTypeCodeId'];

    const fieldsToOmit = recoveryFieldsToHide.concat(vehicleFieldsToHide);
    return omit(ucrProperty, fieldsToOmit);
};

export default createFormModule({
    formName: FormEnum.UCR.name,
    fieldViewModels,
    convertToFormModel: ({
        ucrEvent,
        ucrOffenses,
        ucrOffenseCharges,
        ucrProperty,
        ucrEventAttributes,
    }) => {
        return {
            ucrEvent: {
                ...ucrEvent,
                eventStatistics: map(ucrEventAttributes, 'attributeId'),
            },
            ucrProperty: sortBy(ucrProperty, 'sequenceNumber'),
            ucrOffenses: sortBy(
                map(ucrOffenses, (ucrOffense) => {
                    return {
                        ...pick(ucrOffense, keys(fieldViewModels.ucrOffenses.fields)),
                        linkedOffenseIds: _(ucrOffenseCharges)
                            .filter({ ucrOffenseId: ucrOffense.id })
                            .keyBy('offenseId')
                            .mapValues(() => true)
                            .value(),
                    };
                }),
                'sequenceNumber'
            ),
        };
    },
    convertFromFormModel: (
        { ucrEvent, ucrOffenses, ucrProperty },
        currentUcrSource,
        ucrCodesByCode,
        codes
    ) => {
        const ucrOffenseCharges = _(ucrOffenses)
            .map(({ linkedOffenseIds }, index) => {
                // `linkedOffenseValue` is the boolean checkbox value
                const selectedLinkedOffenseIds = pickBy(
                    linkedOffenseIds,
                    (linkedOffenseValue) => linkedOffenseValue
                );
                return map(
                    selectedLinkedOffenseIds,
                    (selectedLinkedOffenseValue, selectedLinkedOffenseId) => {
                        return {
                            // convert index to a sequenceNumber
                            offenseSequenceNumber: index + 1,
                            offenseId: selectedLinkedOffenseId,
                        };
                    }
                );
            })
            .flatten()
            .value();

        const ucrOffenseCodes = map(ucrOffenses, (ucrOffense) => ucrOffense.ucrSummaryCodeCode);
        const ucrEventToSave = nullOutHiddenFieldsForUcrEvent(
            ucrEvent,
            currentUcrSource,
            ucrOffenseCodes
        );

        const ucrPropertyToSave = map(ucrProperty, (ucrProperty, index) => ({
            ...nullOutHiddenFieldsForUcrProperty(ucrProperty, codes),
            sequenceNumber: index + 1,
        }));

        const ucrOffensesToSave = map(ucrOffenses, (ucrOffense, index) => ({
            ...nullOutHiddenFieldsForUcrOffense(ucrOffense, ucrCodesByCode, currentUcrSource),
            sequenceNumber: index + 1,
            linkedOffenseIds: undefined,
        }));

        const ucrEventAttributesToSave = map(get(ucrEvent, 'eventStatistics'), (attributeId) => ({
            attributeId,
            attributeType: AttributeTypeEnum.EVENT_STATISTICS.name,
        }));

        return {
            ucrEvent: ucrEventToSave,
            ucrProperty: ucrPropertyToSave,
            ucrOffenseCharges,
            ucrOffenses: ucrOffensesToSave,
            ucrEventAttributes: ucrEventAttributesToSave,
        };
    },
});

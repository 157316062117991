import _ from 'lodash';
import { StreetSegment, StreetSegmentView, Street } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getStreetSegmentResource from '../../resources/streetSegmentResource';

import { replaceStreetsWhere } from '../../../streets/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

const streetSegmentsModule = createNormalizedModule<StreetSegment>({
    type: 'streetSegments',
});

const replaceStreetSegmentsWhere = streetSegmentsModule.actionCreators.replaceEntitiesWhere;
const deleteStreetSegmentsWhere = streetSegmentsModule.actionCreators.deleteEntitiesWhere;

export const streetSegmentsSelector = streetSegmentsModule.selectors.entitiesSelector;

export default streetSegmentsModule.reducerConfig;

export const LOAD_STREET_SEGMENTS_START = 'street-segments/LOAD_STREET_SEGMENTS_START';
export const LOAD_STREET_SEGMENTS_SUCCESS = 'street-segments/LOAD_STREET_SEGMENTS_SUCCESS';
export const LOAD_STREET_SEGMENTS_FAILURE = 'street-segments/LOAD_STREET_SEGMENTS_FAILURE';
export const SAVE_STREET_SEGMENT_START = 'street-segments/SAVE_STREET_SEGMENT_START';
export const SAVE_STREET_SEGMENT_SUCCESS = 'street-segments/SAVE_STREET_SEGMENT_SUCCESS';
export const SAVE_STREET_SEGMENT_FAILURE = 'street-segments/SAVE_STREET_SEGMENT_FAILURE';
export const DELETE_STREET_SEGMENT_START = 'street-segments/DELETE_STREET_SEGMENT_START';
export const DELETE_STREET_SEGMENT_SUCCESS = 'street-segments/DELETE_STREET_SEGMENT_SUCCESS';
export const DELETE_STREET_SEGMENT_FAILURE = 'street-segments/DELETE_STREET_SEGMENT_FAILURE';

function saveStreetSegmentStart() {
    return {
        type: SAVE_STREET_SEGMENT_START,
    };
}

export function saveStreetSegmentFailure(errMessage: string) {
    return {
        type: SAVE_STREET_SEGMENT_FAILURE,
        payload: errMessage,
    };
}

function saveStreetSegmentSuccess(segments: StreetSegment[]) {
    return {
        type: SAVE_STREET_SEGMENT_SUCCESS,
        payload: segments,
    };
}

function deleteStreetSegmentStart() {
    return {
        type: DELETE_STREET_SEGMENT_START,
    };
}

function deleteStreetSegmentSuccess() {
    return {
        type: DELETE_STREET_SEGMENT_SUCCESS,
    };
}

function deleteStreetSegmentFailure(errMessage: string) {
    return {
        type: DELETE_STREET_SEGMENT_FAILURE,
        payload: errMessage,
    };
}

export function saveStreetSegment(
    streetSegmentView: StreetSegmentView,
    upsertAcrossDepartments: boolean
): ClientCommonAction<Promise<void>> {
    return function (dispatch) {
        dispatch(saveStreetSegmentStart());
        const resourceCall = upsertAcrossDepartments
            ? getStreetSegmentResource().upsertStreetSegmentAcrossConsortium([streetSegmentView])
            : getStreetSegmentResource().upsertStreetSegment([streetSegmentView]);

        return resourceCall
            .then((segments: StreetSegment[]) => {
                _.map(segments, (segment) => {
                    const { id, streetId } = segment;
                    const miniStreet: Partial<Street> = {
                        id: streetId,
                        name: streetSegmentView.streetName,
                    };

                    dispatch(replaceStreetSegmentsWhere({ id }, segment));
                    // @ts-expect-error replaceStreetsWhere doesn't expect Partial, but should be able to
                    dispatch(replaceStreetsWhere({ id: streetId }, miniStreet));
                });

                return dispatch(saveStreetSegmentSuccess(segments));
            })
            .catch((err: Error) => dispatch(saveStreetSegmentFailure(err.message)));
    };
}

export function deleteStreetSegment(streetSegmentId: number): ClientCommonAction<Promise<void>> {
    return function (dispatch) {
        dispatch(deleteStreetSegmentStart());
        return getStreetSegmentResource()
            .deleteStreetSegment(streetSegmentId)
            .then(() => {
                dispatch(deleteStreetSegmentsWhere({ id: streetSegmentId }));
                return dispatch(deleteStreetSegmentSuccess());
            })
            .catch((err: Error) => dispatch(deleteStreetSegmentFailure(err.message)));
    };
}

import React from 'react';
import { IconButton } from '@arc/icon-button';

const ViewNavigationButton: React.FC<{ isNavigationDrawerOpen: boolean; onClick: () => void }> = ({
    isNavigationDrawerOpen,
    onClick,
}) => {
    return (
        <IconButton
            aria-label="Toggle Navigation Drawer"
            variant="outline"
            icon={isNavigationDrawerOpen ? 'CloseX' : 'Menu'}
            onClick={onClick}
            isActive={isNavigationDrawerOpen}
        />
    );
};

export default ViewNavigationButton;

import React from 'react';
import ConnectedFormattedAttribute, {
    ConnectedFormattedAttributeProps,
} from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import * as fields from '~/client-common/core/enums/universal/fields';
import SummaryRow from './SummaryRow';

type SummaryRowAttributeExport = React.FC<
    ConnectedFormattedAttributeProps & {
        fieldName: keyof typeof fields;
    }
> & {
    FORMATS: typeof ConnectedFormattedAttribute.FORMATS;
};

export const SummaryRowAttribute: SummaryRowAttributeExport = ({
    fieldName,
    attributeId,
    format,
}) => (
    <ConnectedFormattedAttribute attributeId={attributeId} format={format}>
        {(formattedAttribute) => (
            <SummaryRow fieldName={fieldName}>{formattedAttribute}</SummaryRow>
        )}
    </ConnectedFormattedAttribute>
);

// allows consumers to only need 1 import
SummaryRowAttribute.FORMATS = ConnectedFormattedAttribute.FORMATS;

import React from 'react';
import _ from 'lodash';

import Thumbnail from '../../../attachments/files/components/Thumbnail';
import EntityProfileSection from './EntityProfileSection';

export default function ProfileImages({ title, images }) {
    const thumbnails = _.map(images, (image, index) => {
        return <Thumbnail key={index} image={image} index={index} images={images} />;
    });

    return <EntityProfileSection title={title}>{thumbnails}</EntityProfileSection>;
}

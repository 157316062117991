import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import getExportsResource from '~/client-common/core/domain/exports/resources/exportsResource';
import { returnToPreviousRoute } from '../../../core/session-history';
import FormExports from '../../../../legacy-redux/components/exports/FormExports';

function EntityProfileExport({ returnToPreviousRoute }) {
    return (
        <FormExports
            useExportRouteToggle={false}
            closeCallback={returnToPreviousRoute}
            includeAttachments={true}
            exportResourceMethod={getExportsResource().print}
        />
    );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    returnToPreviousRoute: () =>
        dispatch(
            returnToPreviousRoute(
                ownProps.router,
                `/profiles/${ownProps.params.entityType}/${ownProps.params.entityId}`
            )
        ),
});

/**
 * The Entity Profile export form component
 */
export default compose(withRouter, connect(null, mapDispatchToProps))(EntityProfileExport);

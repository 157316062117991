import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { isBefore } from '~/client-common/core/dates/utils/dateHelpers';
import {
    STOP_ANONYMOUS_SUBJECTS_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH,
    STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH,
    STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH,
} from '~/client-common/core/domain/stop-anonymous-subjects/utils/stopAnonymousSubjectHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { getAttributesByIdsSelector } from '~/client-common/core/domain/attributes/state/data';

import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { formName } from '../state/forms/stopForm';
import { useDateTimeFormatter } from '../../../core/current-user/hooks/dateTimeFormats';

export const useRipaCutoverStartDateUtcChangeHandler = () => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const ripaCutoverDate = applicationSettings.RMS_RIPA_2024_CUTOVER_DATE as string;
    const { getForm } = useFormGetter();
    const form = getForm(formName);
    const initialStartDate = form?.getState().model.stop?.startDateUtc;
    const getAttributesByIds = useSelector(getAttributesByIdsSelector);
    const dateTimeFormatter = useDateTimeFormatter();

    const initialIsStartDateUtcBeforeCutoverDate = () => {
        if (!form || !initialStartDate) {
            return undefined;
        }
        return isBefore(ripaCutoverDate, initialStartDate);
    };
    const [isStartDateUtcBeforeCutoverDate, setIsStartDateUtcBeforeCutoverDate] = useState(
        initialIsStartDateUtcBeforeCutoverDate()
    );

    const clearInvalidRipaCutoverFields = useCallback(
        (newStartDateUtc: string | undefined) => {
            if (!form) {
                return undefined;
            }

            const clearInvalidAttributes = (attributeIds: number[] | number | undefined) => {
                if (
                    !newStartDateUtc ||
                    !attributeIds ||
                    (Array.isArray(attributeIds) && !attributeIds.length)
                ) {
                    return attributeIds;
                }

                const isNumber = typeof attributeIds === 'number';
                const attributeIdArray = isNumber ? [attributeIds] : attributeIds;

                const selectedAttributes = getAttributesByIds(attributeIdArray);
                const startDateAsDate = new Date(newStartDateUtc);

                const validAttributeIds = selectedAttributes
                    .filter((attribute) => {
                        if (attribute.start && attribute.end) {
                            return (
                                startDateAsDate >= new Date(attribute.start) &&
                                startDateAsDate <= new Date(attribute.end)
                            );
                        } else if (attribute.start) {
                            return startDateAsDate >= new Date(attribute.start);
                        }
                        return true;
                    })
                    .map((attribute) => attribute.id);

                if (isNumber) {
                    return validAttributeIds.length ? validAttributeIds[0] : undefined;
                }
                return validAttributeIds;
            };

            return form.transaction(() => {
                const validUserAssignments = clearInvalidAttributes(
                    form.getState().model.stop?.userAssignmentTypeAttrId
                );
                if (
                    !isEqual(
                        validUserAssignments,
                        form.getState().model.stop?.userAssignmentTypeAttrId
                    )
                ) {
                    form.set('stop.userAssignmentTypeAttrId', validUserAssignments);
                }

                const stopAnonymousSubjects = form.getState().model.stopAnonymousSubjects;
                if (!stopAnonymousSubjects?.length) {
                    return;
                }
                for (let i = 0; i < stopAnonymousSubjects.length; i++) {
                    const validRaces = clearInvalidAttributes(
                        stopAnonymousSubjects[i].raceAttribute
                    );
                    const validGenders = clearInvalidAttributes(
                        stopAnonymousSubjects[i].perceivedGenderAttrId
                    );
                    const validReasonForStops = clearInvalidAttributes(
                        stopAnonymousSubjects[i].reasonForStopAttrId
                    );
                    const validSuspicionTypes = clearInvalidAttributes(
                        stopAnonymousSubjects[i].suspicionSubType
                    );
                    const validProbableCauseTypes = clearInvalidAttributes(
                        stopAnonymousSubjects[i].probableCauseType
                    );
                    const validResultOfStops = clearInvalidAttributes(
                        stopAnonymousSubjects[i].resultOfStop
                    );

                    if (!isEqual(validRaces, stopAnonymousSubjects[i].raceAttribute)) {
                        form.set(
                            `${STOP_ANONYMOUS_SUBJECTS_PATH}[${i}].${STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH}`,
                            validRaces
                        );
                    }
                    if (!isEqual(validGenders, stopAnonymousSubjects[i].perceivedGenderAttrId)) {
                        form.set(
                            `${STOP_ANONYMOUS_SUBJECTS_PATH}[${i}].${STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH}`,
                            validGenders
                        );
                    }
                    if (
                        !isEqual(validReasonForStops, stopAnonymousSubjects[i].reasonForStopAttrId)
                    ) {
                        form.set(
                            `${STOP_ANONYMOUS_SUBJECTS_PATH}[${i}].${STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH}`,
                            validReasonForStops
                        );
                    }
                    if (!isEqual(validSuspicionTypes, stopAnonymousSubjects[i].suspicionSubType)) {
                        form.set(
                            `${STOP_ANONYMOUS_SUBJECTS_PATH}[${i}].${STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH}`,
                            validSuspicionTypes
                        );
                    }
                    if (
                        !isEqual(
                            validProbableCauseTypes,
                            stopAnonymousSubjects[i].probableCauseType
                        )
                    ) {
                        form.set(
                            `${STOP_ANONYMOUS_SUBJECTS_PATH}[${i}].${STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH}`,
                            validProbableCauseTypes
                        );
                    }
                    if (!isEqual(validResultOfStops, stopAnonymousSubjects[i].resultOfStop)) {
                        form.set(
                            `${STOP_ANONYMOUS_SUBJECTS_PATH}[${i}].${STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH}`,
                            validResultOfStops
                        );
                    }
                }
            });
        },
        [form, getAttributesByIds]
    );
    /**
     * Will clear the values from fields which have different options on either side of a date from application settings
     * Also clears values the first time it transitions from undefined to a date after page load
     */
    const ripaCutoverStartDateUtcChangeHandler = (value: string | undefined) => {
        // application setting can have a start date in the future, in which case it will return undefined
        // in this case options from scheduled attributes should not be in the dropdown
        if (value === undefined || !ripaCutoverDate || isNaN(Date.parse(ripaCutoverDate))) {
            return;
        }
        const valueAsIsoDate = dateTimeFormatter.formatDate(value);
        const ripaCutoverDateAsIsoDate = dateTimeFormatter.formatDate(ripaCutoverDate);

        if (
            // value is before cutover date
            isBefore(ripaCutoverDateAsIsoDate, valueAsIsoDate) &&
            !isStartDateUtcBeforeCutoverDate
        ) {
            setIsStartDateUtcBeforeCutoverDate(true);
            clearInvalidRipaCutoverFields(valueAsIsoDate);
        } else if (
            // value is equal to or after cutover date
            (isBefore(valueAsIsoDate, ripaCutoverDateAsIsoDate) ||
                ripaCutoverDateAsIsoDate === valueAsIsoDate) &&
            (isStartDateUtcBeforeCutoverDate || isStartDateUtcBeforeCutoverDate === undefined)
        ) {
            setIsStartDateUtcBeforeCutoverDate(false);
            clearInvalidRipaCutoverFields(valueAsIsoDate);
        }
    };

    return {
        ripaCutoverStartDateUtcChangeHandler,
    };
};

import defer from 'lodash/defer';
import { Store } from 'redux';
import type { Editor } from 'tinymce';
import debounce from 'lodash/debounce';
import componentStrings from '~/client-common/core/strings/componentStrings';
import errorToMessage from '../../../../errors/utils/errorToMessage';
import { RootState } from '../../../../../../legacy-redux/reducers/rootReducer';

import { getPrepareItemMapper } from './search';
import {
    BaseMention,
    EditorErrorHandler,
    EditorRef,
    FormatMentionItemMapper,
    IsViewModeRef,
    Mention,
    MentionCategoryRef,
    MentionQuery,
} from './types';
import {
    BACK_OPTION,
    BASE_ELEMENT,
    BASE_ELEMENT_CLASS,
    CONTENT_EDITABLE,
    DATA_MENTION_HREF,
    DATA_MENTION_ID,
    extraMentions,
    INITIAL_CATEGORY,
    SEARCH_CHARS_LIMIT,
} from './constants';
import {
    getActiveCategoryList,
    isCorrectSelectedCategory,
    searchEntityItem,
    searchEntityList,
} from './utils';
import { cardPopover } from './components/cardPopover';

const strings = componentStrings.core.Editor.plugins.mentionsForBriefing;

const contentReplacer = ({
    editor,
    content,
    endPosition,
}: {
    editor: Editor;
    content: string;
    endPosition: number;
}) => {
    const startPosition = content.lastIndexOf(strings.at, endPosition);
    const spanId = `${startPosition}_${endPosition}`;

    const hasExtraTextNearAtSymbol = endPosition - startPosition > 1;
    const replacedContent = `${content.substring(0, startPosition)}<span id="${spanId}">${
        strings.at
    }</span>${content.substring(endPosition, content.length)}`;

    return {
        replaceContent: (anchorNode: Node) => {
            if (!hasExtraTextNearAtSymbol) {
                return;
            }

            editor.selection.select(anchorNode);
            editor.selection.setContent(replacedContent);
        },
        maybeOpenMentionMenu: () => {
            const span = editor.dom.select(`span[id="${spanId}"]`)[0];
            if (!span) {
                return;
            }
            editor.selection.select(span);
            editor.insertContent(strings.at);
        },
    };
};

const clearMentionInput = (editor?: Editor) => {
    defer(() => {
        // after inserting an empty mention span with the return statement below (which effectively 'removes' the
        // previous mention), immediately start a new mention with a blank @ so that when the user types, it will
        // search for results in the selected category instead of showing the categories again
        if (!editor) {
            return;
        }

        const selection = editor.selection.getSel();
        if (!selection || !selection.anchorNode || selection.anchorOffset <= 1) {
            return;
        }

        const { anchorNode, anchorOffset } = selection;

        const content = anchorNode.textContent;
        if (!content) {
            return;
        }

        const { replaceContent, maybeOpenMentionMenu } = contentReplacer({
            editor,
            content,
            endPosition: anchorOffset,
        });
        replaceContent(anchorNode);
        maybeOpenMentionMenu();
    });
};

/**
 * This function is used as the `mentions_select` handler in the TinyMCE mentions plugin.
 * https://www.tiny.cloud/docs/tinymce/7/mentions/#mentions_select
 */
export const selectMention = ({
    store,
    isViewModeRef,
    onError,
}: {
    store: Store<RootState>;
    isViewModeRef: IsViewModeRef;
    onError: EditorErrorHandler;
}) => {
    const handler = async (mentionElement: HTMLDivElement, success: (div: HTMLElement) => void) => {
        if (!isViewModeRef.current) {
            mentionElement.setAttribute(CONTENT_EDITABLE, 'true');
            return;
        }
        /* `mention` is the element we previously created with `mentions_menu_complete`
          in this case we have chosen to store the id as an attribute */
        const m43MentionId = mentionElement.getAttribute(DATA_MENTION_ID);
        if (!m43MentionId) {
            return;
        }

        const [category, id] = m43MentionId.split('_');

        if (!id || !isCorrectSelectedCategory(category)) {
            return;
        }

        try {
            const mapper = getPrepareItemMapper(category) as FormatMentionItemMapper;
            const searchResource = searchEntityItem(category);
            const mention = await searchResource(Number(id), store, mapper);

            if (!mention) {
                return;
            }

            const div = document.createElement('div');
            div.innerHTML = cardPopover({ mention });

            mentionElement.setAttribute(DATA_MENTION_HREF, mention.url);
            mentionElement.removeAttribute(CONTENT_EDITABLE);

            success(div);
        } catch (e: unknown) {
            onError(errorToMessage({}, strings.resourceGenericError(category, id)));
        }
    };

    return debounce(handler, 300);
};

/**
 * The returned function is used as `mentions_fetch` in the TinyMCE mentions plugin.
 * https://www.tiny.cloud/docs/tinymce/7/mentions/#mentions_fetch
 */
export const fetchMentions = ({
    store,
    mentionCategoryRef,
    editorRef,
    onError,
}: {
    store: Store<RootState>;
    mentionCategoryRef: MentionCategoryRef;
    editorRef: EditorRef;
    onError: EditorErrorHandler;
}) => {
    const handler = async (
        query: MentionQuery,
        success: (mentions: BaseMention[], extraMentions?: BaseMention[]) => void
    ) => {
        let term = query.term.toLowerCase().trim();

        if (query.meta?.reset) {
            mentionCategoryRef.current = null;
            clearMentionInput(editorRef.current);
        }

        if (mentionCategoryRef.current || query.meta?.category) {
            if (query.meta.category) {
                mentionCategoryRef.current = query.meta.category;

                if (!query.meta?.reset) {
                    clearMentionInput(editorRef.current);
                    term = '';
                }
            }
            if (!mentionCategoryRef.current) {
                return;
            }

            try {
                const mapper = getPrepareItemMapper(
                    mentionCategoryRef.current
                ) as FormatMentionItemMapper;
                const mentions = await searchEntityList(term, store, mapper, SEARCH_CHARS_LIMIT);

                success(mentions, extraMentions);
            } catch (e: unknown) {
                onError(
                    errorToMessage({}, strings.resourceGenericError(mentionCategoryRef.current))
                );
            }

            return;
        }

        const categoryMentions = getActiveCategoryList(store).reduce((acc, item) => {
            const { category, content } = item;
            if (!term || category.toLowerCase().includes(term)) {
                return [
                    ...acc,
                    {
                        id: category,
                        name: INITIAL_CATEGORY,
                        text: content,
                        category,
                        meta: { category },
                    },
                ];
            }
            return acc;
        }, [] as BaseMention[]);

        success([], categoryMentions);
    };

    return debounce(handler, 500);
};

/**
 * This function is used as the `mentions_menu_complete` handler in the TinyMCE mentions plugin.
 * https://www.tiny.cloud/docs/tinymce/7/mentions/#mentions_menu_complete
 */
export const insertMention = (mentionCategoryRef: MentionCategoryRef) => (
    editor: Editor,
    mention: Mention
) => {
    const element = editor.getDoc().createElement(BASE_ELEMENT);
    element.className = BASE_ELEMENT_CLASS;

    const { category } = mention;

    mentionCategoryRef.current = null;

    const m43MentionId = `${category}_${mention.id}`;
    element.className = BASE_ELEMENT_CLASS;
    element.setAttribute(DATA_MENTION_ID, m43MentionId);
    element.setAttribute(DATA_MENTION_HREF, mention.url);
    element.appendChild(editor.getDoc().createTextNode(mention.text));

    defer(() => {
        const mentionSpan = editor
            ?.getBody()
            .querySelector(`[${DATA_MENTION_ID}="${m43MentionId}"]`);
        mentionSpan?.setAttribute(CONTENT_EDITABLE, 'true');
        mentionSpan?.classList.add(BASE_ELEMENT_CLASS);
    });

    return element;
};

/**
 * This function is used as the `mentions_menu_hover` handler in the TinyMCE mentions plugin.
 * https://www.tiny.cloud/docs/tinymce/7/mentions/#mentions_menu_hover
 */
export const hoverMention = (mentionCategoryRef: MentionCategoryRef) => (
    mention: Mention,
    success: (div: HTMLElement) => void
) => {
    if (!mentionCategoryRef.current || mention.text === BACK_OPTION) {
        return;
    }

    const div = document.createElement('div');
    div.innerHTML = cardPopover({ mention });

    success(div);
};

import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { cssVar } from 'arc';
import { DataItem as _DataItem } from '@arc/data-list';
import { isValueDisplayable } from '~/client-common/helpers/logicHelpers';

const DataItem = styled(_DataItem)`
    margin-top: ${cssVar('arc.space.4')};
    font-size: ${cssVar('arc.fontSizes.md')};
`;

const CaseProfileIndicator: FC<{
    title: string;
    children: ReactNode;
}> = ({ title, children }) => {
    return isValueDisplayable(children) ? (
        <DataItem dataKey={title}>
            {children}
        </DataItem>
    ) : null;
};

export default CaseProfileIndicator;

import { filter, get } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

export default createFormModule({
    formName: formClientEnum.SUBDIVISIONS_SHAPEFILE_UPLOAD,
    convertToFormModel: (shapefiles) => {
        return {
            shapefiles,
        };
    },
    convertFromFormModel: (model) => {
        return filter(get(model, 'shapefiles'), (shapefile) => shapefile && shapefile.id);
    },
    fieldViewModels: {
        type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
        key: 'shapefiles',
        fields: buildFlatFormFieldViewModels([{ key: 'id' }, { key: 'displayName' }]),
    },
});

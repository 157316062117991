import { UkOffenseCodeExtension } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'ukOffenseCodeExtensions';

const ukOffenseCodeExtensionsModule = createNormalizedModule<UkOffenseCodeExtension>({
    type: NEXUS_STATE_PROP,
    key: 'offenseCodeId',
});

export const ukOffenseCodeExtensionsReducerConfig = ukOffenseCodeExtensionsModule.reducerConfig;

export const ukOffenseCodeExtensionByOffenseCodeIdSelector =
    ukOffenseCodeExtensionsModule.selectors.entityByIdSelector;

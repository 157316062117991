import React from 'react';
import { useSelector } from 'react-redux';
import { map, isEmpty } from 'lodash';
import { EntityTypeEnumType } from '@mark43/rms-api';
import { trafficCrashEntityDetailViewsByEntitySelector } from '~/client-common/core/domain/traffic-crash-entity-details/state/ui';

import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';

export const TrafficCrashEntityDetailSummarySection = ({
    entityId,
    entityType,
    renderWrapper,
}: {
    entityId: number;
    entityType: EntityTypeEnumType;
    renderWrapper?: (children: JSX.Element[]) => JSX.Element;
}) => {
    const entityDetailViews = useSelector(trafficCrashEntityDetailViewsByEntitySelector)(
        entityId,
        entityType
    );

    const rows = map(entityDetailViews, (edv, idx) => (
        <SummaryRow key={idx} label={edv.key}>
            {edv.value}
        </SummaryRow>
    ));

    return renderWrapper && !isEmpty(rows) ? renderWrapper(rows) : <>{rows}</>;
};

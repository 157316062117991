/* eslint-disable import/no-named-as-default-member */
import { keyBy } from 'lodash';
import { FieldConfiguration } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'fieldConfigurations';

// Used for the show field names ability
export type ClientFieldConfiguration = { originalDisplayName?: string } & FieldConfiguration;

// MODULE
const fieldConfigurationsModule = createNormalizedModule<ClientFieldConfiguration>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// SELECTORS
export const fieldConfigurationsSelector = fieldConfigurationsModule.selectors.entitiesSelector;
export const fieldConfigurationByIdSelector =
    fieldConfigurationsModule.selectors.entityByIdSelector;

export const fieldConfigurationsByFieldDetailIdSelector = createSelector(
    fieldConfigurationsSelector,
    (fieldConfigurations) => keyBy(fieldConfigurations, 'fieldDetailId')
);

export const fieldConfigurationByFieldNameSelector = createSelector(
    fieldConfigurationsSelector,
    (fieldConfigurations) => keyBy(fieldConfigurations, 'fieldName')
);

export function storeFieldConfigurations(fieldConfigurations: FieldConfiguration[]) {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    return function (dispatch: any) {
        dispatch(fieldConfigurationsModule.actionCreators.storeEntities(fieldConfigurations));
    };
}

// REDUCER
export default fieldConfigurationsModule.reducerConfig;

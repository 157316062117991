// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Event Detail Resource',
        methods: {
            updateEventDetail(eventDetail) {
                return req({
                    method: 'PUT',
                    url: `reports/${eventDetail.reportId}/details`,
                    data: eventDetail,
                });
            },
            updateEventDetailsFull(eventDetailBundle, reportId) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/cards/event_detail`,
                    data: eventDetailBundle,
                });
            },
        },
    });
}

/**
 * Resources for EventDetails. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

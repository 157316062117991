import {
    AttributeTypeEnum,
    StorageLocationTypeEnum,
    OperationTypeEnum,
} from '@mark43/evidence-api';
import React from 'react';
import { useSelector } from 'react-redux';
import { keyBy, first } from 'lodash';
import { Box } from 'arc';
import {
    Fieldset as MFTFieldset,
    FieldState as MFTFieldState,
    Observer,
    Fields,
} from 'markformythree';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { evidenceLocationPermissionsWhereSelector } from '~/client-common/core/domain/evidence-location-permissions/state/data';
import { userRolesSelector } from '~/client-common/core/domain/user-roles/state/data';
import { chainEventViewModelsForMasterItemIdSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { itemProfileByIdSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_ITEM_SIDE_PANEL_CUSTODY_STATUS_LABEL } from '~/client-common/core/enums/universal/fields';

import { Tooltip } from '../../../../../core/components/tooltip';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import FormRow from '../../../../../core/forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTStorageLocationSelect } from '../../../../../core/forms/components/selects/StorageLocationSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTCustodyTypeSelect } from '../../../../../core/forms/components/selects/CustodyTypeSelect';
import { ArbiterMFTUserSelect } from '../../../../../core/forms/components/selects/UserSelect';
import testIds from '../../../../../../core/testIds';
import { itemSidePanelItemProfileIdSelector } from '../../../state/ui';
import { hasEvidenceLocationPermission } from '../../../../../evidence/core/utils/evidenceItemsHelpers';

const temporaryStorageLocationFilter = [StorageLocationTypeEnum.TEMPORARY.name];
const strings = componentStrings.reports.core.CustodyStatusFieldset;

function CustodyStatusFieldset({
    isExhibitNumberDisabled,
    isInPoliceCustodyDisabled,
    isStorageLocationIdDisabled,
    helpTextCollisionBoundary,
}) {
    const userRoles = useSelector(userRolesSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const evidenceLocationPermissionsWhere = useSelector(evidenceLocationPermissionsWhereSelector);
    const itemProfileId = useSelector(itemSidePanelItemProfileIdSelector);
    const itemProfileById = useSelector(itemProfileByIdSelector);
    const chainEventViewModelsForMasterItemId = useSelector(
        chainEventViewModelsForMasterItemIdSelector
    );
    const itemProfile = itemProfileById(itemProfileId);
    const custodyStatusLabel = useFields(DISPLAY_ONLY_ITEM_SIDE_PANEL_CUSTODY_STATUS_LABEL)[
        DISPLAY_ONLY_ITEM_SIDE_PANEL_CUSTODY_STATUS_LABEL
    ];

    let missingManagePermissionTooltipText = strings.missingManagePermissionTooltip;
    const itemInNonTempLocationTooltipText = strings.itemInNonTempLocationTooltipText;
    let viewModelProperties;
    if (itemProfile) {
        const masterItemId = itemProfile?.masterItemId;
        const chainEventViewModel = first(chainEventViewModelsForMasterItemId(masterItemId));
        viewModelProperties = getViewModelProperties(chainEventViewModel);
        const fullDisplayPath = viewModelProperties?.storageLocation?.fullDisplayPath;
        if (fullDisplayPath) {
            missingManagePermissionTooltipText = `${missingManagePermissionTooltipText}: ${fullDisplayPath}`;
        }
    }

    const isInEvidenceLocation =
        viewModelProperties?.facilityId !== undefined ||
        viewModelProperties?.storageLocation !== undefined;

    const evidenceLocationPermissions = isInEvidenceLocation
        ? [evidenceLocationPermissionsWhere({ itemId: itemProfileId })]
        : [];

    const userRolesById = keyBy(userRoles, (role) => role.roleId);
    const useEvdPerms = applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED;
    const hasPermission = hasEvidenceLocationPermission(
        evidenceLocationPermissions,
        userRolesById,
        OperationTypeEnum.MANAGE.name
    );

    const displayNameInput = (
        <MFTFieldState
            path="storageLocationId"
            render={({ model }) => {
                return (
                    <ArbiterMFTStorageLocationSelect
                        disabled={
                            (isStorageLocationIdDisabled && !!model) ||
                            (useEvdPerms && !hasPermission)
                        }
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="storageLocationId"
                        storageLocationTypeFilter={temporaryStorageLocationFilter}
                        length="lg"
                        requiredPermissions={[OperationTypeEnum.MANAGE.name]}
                    />
                );
            }}
        />
    );

    return (
        <Observer
            subscriptions={{
                isInPoliceCustodyHidden: ['isInPoliceCustody', Fields.HIDDEN],
            }}
            render={({ isInPoliceCustodyHidden }) =>
                !isInPoliceCustodyHidden && (
                    <SidePanelSection
                        title={custodyStatusLabel}
                        testId={testIds.ITEM_SIDE_PANEL_CUSTODY_STATUS_SECTION}
                    >
                        <ArbiterMFTBooleanSelect
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            disabled={isInPoliceCustodyDisabled}
                            path="isInPoliceCustody"
                            length="md"
                        />
                        <MFTFieldset path="custodyStatus">
                            <FormRow hasIndent>
                                <ArbiterMFTAttributeSelect
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="reasonForPoliceCustodyAttrId"
                                    attributeType={
                                        AttributeTypeEnum.REASON_FOR_POLICE_CUSTODY_OF_PROPERTY.name
                                    }
                                    grouped={true}
                                    length="lg"
                                />
                                <ArbiterMFTCustodyTypeSelect
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="policeCustodyTypeEntityType"
                                    length="lg"
                                />
                                <ArbiterMFTText
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="storageFacility"
                                    length="lg"
                                />
                                <ArbiterMFTText
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="storageLocation"
                                    length="lg"
                                />
                                {!isStorageLocationIdDisabled && (!useEvdPerms || hasPermission) ? (
                                    displayNameInput
                                ) : (
                                    <Tooltip
                                        side="top"
                                        content={
                                            isStorageLocationIdDisabled
                                                ? itemInNonTempLocationTooltipText
                                                : missingManagePermissionTooltipText
                                        }
                                        maxWidth="400px"
                                    >
                                        {/* Manually adding a wrapper to fill the grid, since the tooltip doesn't work well directly wrapping MFTFieldState */}
                                        <Box gridColumn="span 9">{displayNameInput}</Box>
                                    </Tooltip>
                                )}
                                <ArbiterMFTUserSelect
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="infieldTransferByOfficerId"
                                    length="lg"
                                />
                                <ArbiterMFTText
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    disabled={isExhibitNumberDisabled}
                                    path="exhibitNumber"
                                    length="lg"
                                />
                            </FormRow>
                        </MFTFieldset>
                    </SidePanelSection>
                )
            }
        />
    );
}

/**
 * Fieldset in the item entry v2 form that appears depending on a HIDE rule.
 */
export default CustodyStatusFieldset;

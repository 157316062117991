import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get, last } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data/';
import componentStrings from '~/client-common/core/strings/componentStrings';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import InlineFileRow from '../../../attachments/core/components/InlineFileRow';
import Upload from '../../forms/components/Upload';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes, iconSizes } from '../../components/Icon';
import { fileScannerIsPollingSelector } from '../../../attachments/core/state/ui/securityScanning';

const UploadContainer = styled(Upload)`
    display: inline-block;
`;

const strings = componentStrings.core.PersonProfileForm.IdentifyingMarksUploader;

export const IdentifyingMarksUploader = connect(
    createStructuredSelector({
        formatMiniUserById: formatMiniUserByIdSelector,
        isFileScannerPolling: fileScannerIsPollingSelector,
    })
)(
    ({
        imageUploadApi: {
            onUploadStart,
            onUploading,
            onFileInfected,
            onUploadSuccess,
            onUploadError,
            loading,
            onRemove,
            error,
            images,
            uploadSuccessful: wasUploadedInCurrentSession,
        },
        formatMiniUserById,
        isFileScannerPolling,
    }) => {
        const image = last(images);

        // Special case for loading
        // because the API returns
        // native browser File objects
        // while loading or erroring, but dataNexus image objects
        // after upload has completed
        if (loading || error) {
            const { name } = image || {};
            return (
                <InlineFileRow
                    deleteFile={onRemove}
                    isLoading={loading}
                    uploadFailed={error}
                    errorMessage={error}
                    fileName={name}
                />
            );
            // If successful, or initial image provided
        } else if (image) {
            // If we are working with a prefilled image,
            // or successfully uploaded image,
            // we will be provided dataNexus shaped image object
            const {
                fileWebServerPath,
                createdDateUtc,
                originalFileName,
                fileCategory,
                createdBy,
            } = get(image, 'originalFile', {});

            return (
                <InlineFileRow
                    url={fileWebServerPath}
                    createdBy={formatMiniUserById(createdBy, { firstNameAsInitial: true })}
                    createdDateUtc={!wasUploadedInCurrentSession && createdDateUtc}
                    deleteFile={onRemove}
                    isLoading={loading}
                    uploadFailed={error}
                    errorMessage={error}
                    fileName={originalFileName}
                    fileCategory={fileCategory}
                />
            );
        }

        return (
            <FeatureFlagged
                flag="RMS_FEDRAMP_FILE_UPLOAD_STATUSES_ENABLED"
                fallback={
                    <UploadContainer
                        multiple={false}
                        onStart={onUploadStart}
                        onUploading={onUploading}
                        onSuccess={onUploadSuccess}
                        onError={onUploadError}
                    >
                        <Button
                            className={buttonTypes.ICON_LINK}
                            iconLeft={
                                <Icon
                                    color="cobaltBlue"
                                    type={iconTypes.ADD}
                                    size={iconSizes.MEDIUM}
                                />
                            }
                        >
                            {strings.attachment}
                        </Button>
                    </UploadContainer>
                }
            >
                {isFileScannerPolling ? (
                    <Button
                        disabled={true}
                        className={buttonTypes.ICON_LINK}
                        iconLeft={
                            <Icon color="cobaltBlue" type={iconTypes.ADD} size={iconSizes.MEDIUM} />
                        }
                    >
                        {strings.attachment}
                    </Button>
                ) : (
                    <UploadContainer
                        multiple={false}
                        onStart={onUploadStart}
                        onFileInfected={onFileInfected}
                        onUploading={onUploading}
                        onSuccess={onUploadSuccess}
                        onError={onUploadError}
                    >
                        <Button
                            className={buttonTypes.ICON_LINK}
                            iconLeft={
                                <Icon
                                    color="cobaltBlue"
                                    type={iconTypes.ADD}
                                    size={iconSizes.MEDIUM}
                                />
                            }
                        >
                            {strings.attachment}
                        </Button>
                    </UploadContainer>
                )}
            </FeatureFlagged>
        );
    }
);

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Locations Resource',
        methods: {
            /**
             * Create or update a location.
             * @param  {Object} location
             * @return {Promise<Object>} LocationView model (not Location) from the
             *   server, with LocationEntityLink models embedded on a property
             *   called `entityLinks`.
             */
            upsertLocation(location) {
                return req({
                    method: 'POST',
                    url: 'master/locations',
                    data: location,
                });
            },

            replaceLocationLinksForEntity(entityType, entityId, locationEntityLinks) {
                return req({
                    method: 'PUT',
                    url: `master/locations/entities/${entityType}/${entityId}`,
                    data: locationEntityLinks,
                });
            },

            replaceLocationEntityLinksForEntityAndLinkTypes(
                entityType,
                entityId,
                linkTypes,
                locationEntityLinks
            ) {
                return req({
                    method: 'PUT',
                    url: `master/locations/entities/${entityType}/${entityId}/link_types`,
                    data: {
                        linkTypes,
                        locationEntityLinks,
                    },
                });
            },

            /**
             * Get all locations linked to the given entity.
             * @param  {Object[]} genericEntities
             * @return {Promise<Object[]>} LocationBundle models from the server,
             *   each of which includes a Location model (not LocationView).
             */
            getLocationsLinkedToEntity(genericEntities) {
                return req({
                    method: 'POST',
                    url: 'master/locations/entities/batch',
                    data: genericEntities,
                });
            },

            resolveAllLocations(query) {
                return req({
                    method: 'GET',
                    url: 'master/locations/resolve/all',
                    params: {
                        q: query,
                    },
                });
            },

            resolveStreetCenterlineLocation(query) {
                return req({
                    method: 'GET',
                    url: 'master/locations/resolve/street_centerline',
                    params: {
                        q: query,
                    },
                });
            },

            fetchLocationBySource({ source, id }) {
                return req({
                    method: 'GET',
                    url: 'master/locations',
                    params: {
                        source,
                        id,
                    },
                });
            },

            classify(location) {
                return req({
                    method: 'POST',
                    url: 'master/locations/classify',
                    data: location,
                });
            },

            retrieveAndClassifyPostcoder(location) {
                return req({
                    method: 'POST',
                    url: 'master/locations/classify/postcoder',
                    data: location,
                });
            },

            multiagencyClassify(location, agencyId) {
                return req({
                    method: 'POST',
                    url: `master/locations/classify/multiagency/${agencyId}`,
                    data: location,
                });
            },
            getAgencySubdivisionAttributes() {
                return req({
                    method: 'GET',
                    url: `master/locations/multiagency/subdivisions/attribute/links`,
                });
            },
        },
    });
}

/**
 * Resources for locations. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { get } from 'lodash';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import { summaryNarrativeByReportIdSelector } from '~/client-common/core/domain/summary-narratives/state/data';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { getSummaryNarrativeName } from '../../../../core/editor/utils/tinyEditor';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    summaryNarrativeFormName,
    getSummaryNarrativeForm,
    createSummaryNarrativeForm,
    buildSummaryNarrativeFormModel,
} from '../../state/forms/summaryNarrativeForm';
import { registerForm } from '../../state/ui';
import summaryNarrativeCard from '../../state/ui/summaryNarrativeCard';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import withCard from '../../utils/withCard';
import testIds from '../../../../../core/testIds';
import { hasBannerSelector } from '../../../../../legacy-redux/selectors/alertsSelectors';
import { registerCard } from '../../utils/cardsRegistry';
import { CardWithExpandedSupport } from '../NarrativeStyledComponents';
import NarrativeCardBase from '../NarrativeCardBase';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import TinySummaryNarrativeCardForm from './TinySummaryNarrativeCardForm';

class SummaryNarrativeCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            buildSummaryNarrativeFormModel,
            currentReportId: reportId,
            formatFieldByName,
            arbiter,
        } = this.props;

        const form = createSummaryNarrativeForm({
            formatFieldByName,
            arbiter,
            initialState: buildSummaryNarrativeFormModel(reportId),
        });

        registerForm({ form, index: reportId });

        this.unregisterCard = registerCard({
            cardModule: summaryNarrativeCard,
            onSave: this.onSave,
            index: reportId,
        });

        this.state = {
            cardHeaderHeight: 0,
        };
    }

    componentWillUnmount() {
        this.unregisterCard();
        formsRegistry.unregister(summaryNarrativeFormName, this.props.currentReportId);
    }

    getForm = () => formsRegistry.get(summaryNarrativeFormName, this.props.currentReportId);

    onEdit = () =>
        this.props.editCallback(() =>
            this.props.onEdit({
                index: this.props.currentReportId,
            })
        );

    onSaveProgress = () => {
        const form = getSummaryNarrativeForm(this.props.currentReportId);
        return this.props.onSaveProgress(form, {
            onSaveSuccessCallback: this.props.onSaveSuccess,
            index: this.props.currentReportId,
        });
    };

    onSave = () => {
        const form = getSummaryNarrativeForm(this.props.currentReportId);
        return this.props.onSave(form, {
            onSaveSuccessCallback: this.props.onSaveSuccess,
            index: this.props.currentReportId,
        });
    };

    setError = (errorMessage) => {
        this.props.setErrorMessages([errorMessage], { index: this.props.currentReportId });
    };

    setCardHeaderHeight = (ref) => this.setState({ cardHeaderHeight: get(ref, 'offsetHeight') });

    getSummaryMode = () => {
        return this.props.card.summaryMode;
    };

    render() {
        const {
            card = {},
            currentReportId: reportId,
            hasBanner,
            currentReportCardUITitleByType,
        } = this.props;
        const {
            anchor,
            canEditSummaryNarrativeReportCardStatus,
            saving,
            anchorForIndex,
            summaryMode,
            errorMessages,
        } = card;
        const isExpandedFullScreen = this.props.getIsExpandedFullScreen();
        const cardTitle = currentReportCardUITitleByType(reportCardEnum.SUMMARY_NARRATIVE.id);

        return (
            <CardWithExpandedSupport
                className={anchor}
                anchor={anchorForIndex(this.props.index)}
                title={cardTitle}
                testId={testIds.SUMMARY_NARRATIVE_CARD}
                isExpandedFullScreen={isExpandedFullScreen}
                isEmbedded={this.props.isEmbedded}
                cardHeaderRef={this.setCardHeaderHeight}
                renderContent={(summaryMode) => {
                    return (
                        <TinySummaryNarrativeCardForm
                            id={getSummaryNarrativeName(this.props.currentReportId)}
                            summaryMode={summaryMode}
                            isExpandedFullScreen={isExpandedFullScreen}
                            isSticky={!summaryMode && !isExpandedFullScreen}
                            onFullScreenToggle={this.props.onFullScreenToggle}
                            form={this.getForm()}
                            setError={this.setError}
                            offset={this.state.cardHeaderHeight}
                            editorRef={this.props.narrativeCardEditorRef}
                            getSummaryMode={this.getSummaryMode}
                            reportId={reportId}
                        />
                    );
                }}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={summaryMode}
                canEdit={get(canEditSummaryNarrativeReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditSummaryNarrativeReportCardStatus, 'errorMessage')}
                saving={saving}
                onSave={this.onSaveProgress}
                renderFullScreenToggleHeaderButton={this.props.renderFullScreenToggleButton}
                stickyHeader={!isExpandedFullScreen}
                hasBanner={hasBanner}
            />
        );
    }
}

class SummaryNarrativeCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={summaryNarrativeFormName}>
                {(arbiter) => (
                    <NarrativeCardBase>
                        {({
                            narrativeCardEditorRef,
                            setIsExpandedFullScreen,
                            getIsExpandedFullScreen,
                            onFullScreenToggle,
                            onSaveSuccess,
                            renderFullScreenToggleButton,
                        }) => (
                            <SummaryNarrativeCard
                                narrativeCardEditorRef={narrativeCardEditorRef}
                                onSaveSuccess={onSaveSuccess}
                                setIsExpandedFullScreen={setIsExpandedFullScreen}
                                getIsExpandedFullScreen={getIsExpandedFullScreen}
                                onFullScreenToggle={onFullScreenToggle}
                                renderFullScreenToggleButton={renderFullScreenToggleButton}
                                ref={this.setRef}
                                {...this.props}
                                arbiter={arbiter}
                            />
                        )}
                    </NarrativeCardBase>
                )}
            </RMSArbiterProvider>
        );
    }
}

export default compose(
    withCard(summaryNarrativeCard),
    connect(
        createStructuredSelector({
            formatFieldByName: formatFieldByNameSelector,
            summaryNarrativeByReportId: summaryNarrativeByReportIdSelector,
            eventDetailByReportId: eventDetailByReportIdSelector,
            reportById: reportByIdSelector,
            hasBanner: hasBannerSelector,
            currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
        }),
        {
            buildSummaryNarrativeFormModel,
            setErrorMessages: summaryNarrativeCard.actionCreators.setErrorMessages,
        },
        null,
        { forwardRef: true }
    )
)(SummaryNarrativeCardWrapper);

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { replaceCitationCardBundle } from '~/client-common/core/domain/reports/state/ui/citations';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import { buildCitationCardBundle, refreshCitationForm } from '../forms/citationForm';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.CITATION.name,
    baseSelector: (state) => state.ui.report.citationCard,
    anchor: 'citation-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);
                const report = currentReportSelector(state);
                const reportId = report.id;

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const citationCardBundle = dispatch(
                                    buildCitationCardBundle({ reportId, form })
                                );

                                return [
                                    dispatch(
                                        replaceCitationCardBundle({
                                            reportId,
                                            citationCardBundle,
                                        })
                                    ),
                                ];
                            },
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                dispatch(refreshCitationForm({ reportId }));
                            },
                        })
                    );
                }
            };
        },
    },
};

import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, InjectedRouter } from 'react-router';
import {
    HStack,
    Stack,
    IconButton,
    Button,
    Divider,
    cssVar,
    Text as ArcText,
    Drawer,
    DrawerContent,
    DrawerBody,
    DrawerHeader,
    DrawerFooter,
    useBreakpointValue,
    Popover,
    PopoverTrigger,
    PopoverContent,
    NavItemProps,
    mediaQueries,
} from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';

import { handleNavToggle } from '../../../core/components/Navigation/components';
import NotificationSummary from '../../core/components/NotificationSummary';
import NoDataBlock from '../../../core/components/NoDataBlock';

import { openNotificationSettingsForm as _openNotificationSettingsForm } from '../../settings/state/ui';
import { mostRecentNotificationViewModelsSelector } from '../state/ui';
import { RmsDispatch } from '../../../../core/typings/redux';

const strings = componentStrings.notifications.popover.NotificationsPopover;

const StyledPopoverContent = styled(PopoverContent)`
    min-width: 21rem;
`;

const Header = styled(HStack)`
    justify-content: space-between;
    padding: ${cssVar('arc.space.2')} ${cssVar('arc.space.3')};
`;

const NotificationsList = styled(Stack)`
    overflow-y: auto;

    @media (min-width: ${mediaQueries.md}) {
        max-height: 30rem;
    }
`;

const NoResultsWrapper = styled.div`
    padding: ${cssVar('arc.space.6')};
    width: 100%;
    text-align: center;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
    justify-content: space-between;
    padding-right: var(--arc-space-12);
    min-height: 4rem;
    align-items: center;
    display: flex;
`;

const StyledDrawerBody = styled(DrawerBody)`
    padding: 0;
`;

const StyledNotificationSummary = styled(NotificationSummary)`
    width: 100%;
`;

const Footer = styled(HStack)`
    justify-content: end;
    padding: ${cssVar('arc.space.2')} ${cssVar('arc.space.3')};
`;

type RenderButtonProps = Partial<Pick<NavItemProps, 'onClick' | 'ref' | 'isActive'>>;

/** This is the new notifications nav item for the ARC main nav.
 * This can be renamed and replace the current `NotificationsPopover.js` when we tear down the RMS_ARC_NAVIGATION_ENABLED feature flag.
 */
const UpdatedNotificationsPopoverContent = ({
    router,
    renderButton,
}: {
    router: InjectedRouter;
    renderButton: (props: RenderButtonProps) => JSX.Element;
}) => {
    const userNotificationViewModels = useSelector(mostRecentNotificationViewModelsSelector);
    const dispatch = useDispatch<RmsDispatch>();
    const openNotificationSettingsForm = () => dispatch(_openNotificationSettingsForm());

    const [isOpen, setIsOpen] = React.useState(false);
    const popoverTriggerRef = React.useRef(null);

    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });

    function handleClose() {
        setIsOpen(false);
    }

    function handleSettingsButtonClick() {
        openNotificationSettingsForm();
        handleClose();
    }

    // Resuable content pieces to be used in render the drawer and popover
    const headerContent = (
        <>
            <ArcText variant="headingSm">
                {strings.title(userNotificationViewModels.length)}
            </ArcText>
            <IconButton
                size="sm"
                variant="ghost"
                icon="Settings"
                aria-label={strings.labels.settings}
                onClick={handleSettingsButtonClick}
            />
        </>
    );

    const footerContent = (
        <Button
            autoFocus
            variant="solid"
            aria-label={strings.labels.notificationsPage}
            onClick={() => {
                handleClose();
                router.push('/notifications');
            }}
        >
            {strings.labels.notificationsPage}
        </Button>
    );

    const notificationsList = (
        <>
            {userNotificationViewModels.length > 0 ? (
                <NotificationsList spacing={0}>
                    {map(userNotificationViewModels, (userNotificationViewModel) => (
                        <StyledNotificationSummary
                            key={userNotificationViewModel.userNotificationLinkId}
                            width={340}
                            userNotificationViewModel={userNotificationViewModel}
                            onClick={handleClose}
                        />
                    ))}
                </NotificationsList>
            ) : (
                <NoResultsWrapper>
                    <NoDataBlock>{strings.labels.noNotifications}</NoDataBlock>
                </NoResultsWrapper>
            )}
        </>
    );

    if (isMobile) {
        return (
            <>
                {renderButton({ onClick: () => setIsOpen((prev) => !prev) })}
                <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <DrawerContent>
                        <StyledDrawerHeader>{headerContent}</StyledDrawerHeader>
                        <StyledDrawerBody>{notificationsList}</StyledDrawerBody>
                        <DrawerFooter>{footerContent}</DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </>
        );
    } else {
        return (
            <Popover isOpen={isOpen}>
                <PopoverTrigger asChild>
                    {renderButton({
                        onClick: () => setIsOpen((prev) => !prev),
                        ref: popoverTriggerRef,
                        isActive: isOpen,
                    })}
                </PopoverTrigger>
                <StyledPopoverContent
                    hasPadding={false}
                    side="right"
                    align="end"
                    onInteractOutside={(e) => handleNavToggle(e, popoverTriggerRef, handleClose)}
                    onEscapeKeyDown={handleClose}
                    data-test-id={testIds.NOTIFICATIONS_POPOVER}
                >
                    <Header>{headerContent}</Header>
                    <Divider />
                    {notificationsList}
                    <Footer>{footerContent}</Footer>
                </StyledPopoverContent>
            </Popover>
        );
    }
};

export default withRouter(UpdatedNotificationsPopoverContent);

import { PrintingTemplateTypeEnum } from '@mark43/rms-api';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';
import {
    buildFlatFormFieldViewModels,
    filterFormData,
} from '../../../../../legacy-redux/helpers/formHelpers';

const printingTemplatesAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    'id',
    {
        key: 'title',
        validators: {
            requiredError: requiredString,
        },
    },
    {
        key: 'printingDataType',
        validators: {
            requiredError: requiredString,
        },
    },
    {
        key: 'printingTemplateType',
        validators: {
            requiredError: requiredString,
        },
    },
    {
        key: 'includeAttachments',
        validators: {},
    },
    'contents',
]);

export const initialValues = {
    includeAttachments: true,
};

const convertFromFormModel = (formData) => {
    const filteredFormData = filterFormData(formData);
    const isEnabled = !(
        filteredFormData.printingTemplateType === PrintingTemplateTypeEnum.PDF_NEXT.name ||
        filteredFormData.printingTemplateType ===
            PrintingTemplateTypeEnum.PDF_NEXT_LEGACY_DATA.name ||
        filteredFormData.printingTemplateType === PrintingTemplateTypeEnum.PDF_NEXT_REPORT_REN.name
    );
    return { ...filteredFormData, isEnabled };
};

export default createFormModule({
    formName: formClientEnum.PRINTING_TEMPLATES_ADMIN,
    fieldViewModels: printingTemplatesAdminFormFieldViewModels,
    convertFromFormModel,
});

import { SystemRuleEnum } from '@mark43/rms-api';
import { get, isObject } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import addRuleId from '../helpers/addRuleId';

export const createReportReportingEventNumberMustBeInt = addRuleId(
    SystemRuleEnum.CREATE_REPORT_REPORTING_EVENT_NUMBER_MUST_BE_INT.name,
    (formData, value) => {
        // `value` is a straight up value when this system rule is called
        // from MFT, in the Create Report modal.
        // However, legacy Redux Form `RenChangeModal` uses this same system
        // rule, but Redux Form validation will _always_ pass the "value" to
        // validation functions as a "field-name-to-value" object mapping.
        if (isObject(value)) {
            const dataFieldName = get(value, fields.REPORT_REPORTING_EVENT_NUMBER);
            value = get(formData, dataFieldName);
        }
        return /^\d+$/.test(value);
    }
);

import { OperationTypeEnum, RoleTypeEnum } from '@mark43/rms-api';
import _, { find, get, map, some } from 'lodash';

import { components } from '~/client-common/core/strings';
import validationStrings from '~/client-common/core/strings/validationStrings';

const strings = components.security.EntityPermissionsForm;

/**
 * Validation logic on individual fields.
 * @param  {Object} data
 * @return {Object}
 */
export function fieldValidator(data) {
    const mapper = (permissions) =>
        map(permissions, (permission) => {
            const permissionErrors = {};

            if (!permission.roleId) {
                permissionErrors.roleId = strings.requiredError;
            }

            if (!permission.operationType) {
                permissionErrors.operationType = strings.requiredError;
            }

            return permissionErrors;
        });
    return {
        permissions: mapper(data.permissions),
        externalDepartmentPermissions: mapper(data.externalDepartmentPermissions),
    };
}

const departmentHasPermission = function (data, departmentRoleId) {
    // only check `data.permissions`, because this does not apply
    // to external department
    return some(data.permissions, ({ roleId }) => roleId === departmentRoleId);
};

const hasManagePermission = function (data) {
    // only check `data.permissions`, because this does not apply
    // to external department
    return some(data.permissions, { operationType: OperationTypeEnum.MANAGE.name });
};

const hasDuplicateRole = function (data) {
    const moreThanOne = (count) => count > 1;
    return (
        _(data.permissions).countBy('roleId').some(moreThanOne) ||
        _(data.externalDepartmentPermissions).countBy('roleId').some(moreThanOne)
    );
};

/**
 * Validation logic on all fields, where an error message would apply to the
 *   entire form and not to any one field.
 * @param  {Object} data
 * @return {string|null} String if error, `null` if no error.
 */
export function submitValidator(data, props) {
    const currentDepartmentRole = find(props.roles, {
        roleType: RoleTypeEnum.DEPARTMENT.name,
        departmentId: props.currentUserDepartmentId,
    });
    if (!departmentHasPermission(data, get(currentDepartmentRole, 'id'))) {
        return validationStrings.panel.roleNeedsAtLeastPermissionError(
            get(currentDepartmentRole, 'name'),
            'Can Find'
        );
    }

    if (!hasManagePermission(data)) {
        return strings.noManagePermissionError;
    }

    if (hasDuplicateRole(data)) {
        return strings.duplicateRoleError;
    }

    return null;
}

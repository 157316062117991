import type { TinyMCE } from 'tinymce';

/**
 * When the user pastes HTML into the TinyMCE editor, modify the HTML before it gets inserted.
 * Documentation: https://www.tiny.cloud/docs/tinymce/6/copy-and-paste/#paste_postprocess
 * The type of this function is `PastePostProcessEvent`, which is not exproted from the `tinymce` type declaration file.
 */
const powerPastePostProcess: Parameters<TinyMCE['init']>[0]['paste_postprocess'] = (
    pluginApi,
    data
) => {
    /**
     * Remove blank paragraphs and divs. These are 2 separate calls because <div> may contain <p>, and vice versa.
     *
     * This is targeted toward paragraphs pasted from Microsoft Word.
     */
    removeBlankElements(Array.from(data.node.getElementsByTagName('div')));
    removeBlankElements(Array.from(data.node.getElementsByTagName('p')));

    /**
     * Remove <br> between <p>.
     *
     * This is targeted toward paragraphs pasted from Google Docs.
     *
     * Normally, the <br> by itself doesn't increase the vertical spacing between the 2 paragraphs, but TinyMCE
     * automatically adds an extra <p> around each <br>, so we remove the <br> to prevent the extra 3rd paragraph.
     */
    const brs = Array.from(data.node.getElementsByTagName('br'));
    for (const br of brs) {
        if (br.previousElementSibling?.tagName === 'P' && br.nextElementSibling?.tagName === 'P') {
            br.remove();
        }
    }

    /**
     * When pasting underlined text from the web version of Microsoft Word (Office 365/Sharepoint), the spans have the style
     * text-decoration-line rather than text-decoration. We can't update sanitizeHtmlString in HtmlTextElf.java to accept
     * text-decoration-line as a css style since it is not supported by org.owasp.html.CssSchema. So we manually copy its
     * value to the text-decoration property.
     */
    const spans = Array.from(data.node.getElementsByTagName('span'));
    for (const span of spans) {
        if (span.style.textDecorationLine === 'underline' && !span.style.textDecoration) {
            span.style.textDecoration = span.style.textDecorationLine;
        }
    }
};

export default powerPastePostProcess;

/**
 * Remove elements which have only space characters.
 *
 * The original HTML pasted from Word looks like this, with a non-breaking space inside the span:
 *
 * <div class="OutlineElement Ltr SCXW92459553 BCX0" style="margin: 0px; padding: 0px; user-select: text; -webkit-user-drag: none; -webkit-tap-highlight-color: transparent; overflow: visible; cursor: text; clear: both; position: relative; direction: ltr; color: rgb(0, 0, 0); font-family: Calibri, Calibri_MSFontService, sans-serif; font-size: 14.6667px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
 *     <p class="Paragraph SCXW92459553 BCX0" paraid="1127873325" paraeid="{237122fc-ab0b-4769-8970-6b503ffdce27}{214}" style="margin: 0px; padding: 0px; user-select: text; -webkit-user-drag: none; -webkit-tap-highlight-color: transparent; overflow-wrap: break-word; white-space: pre-wrap; font-weight: normal; font-style: normal; vertical-align: baseline; font-kerning: none; background-color: transparent; color: windowtext; text-align: left; text-indent: 0px;">
 *         <span data-contrast="auto" xml:lang="EN-US" lang="EN-US" class="TextRun SCXW92459553 BCX0" style="margin: 0px; padding: 0px; user-select: text; -webkit-user-drag: none; -webkit-tap-highlight-color: transparent; font-variant-ligatures: none !important; font-size: 11pt; line-height: 18.3458px; font-family: Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif;"><span class="NormalTextRun SCXW92459553 BCX0" style="margin: 0px; padding: 0px; user-select: text; -webkit-user-drag: none; -webkit-tap-highlight-color: transparent;"></span></span><span class="EOP SCXW92459553 BCX0" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;201341983&quot;:0,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559685&quot;:0,&quot;335559737&quot;:0,&quot;335559738&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}" style="margin: 0px; padding: 0px; user-select: text; -webkit-user-drag: none; -webkit-tap-highlight-color: transparent; font-size: 11pt; line-height: 18.3458px; font-family: Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif;">&nbsp;</span>
 *     </p>
 * </div>
 */
function removeBlankElements(elements: HTMLElement[]): void {
    for (const element of elements) {
        if (/^\s+$/.test(element.innerText)) {
            element.remove();
        }
    }
}

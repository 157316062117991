import styled from 'styled-components';

export default styled.span`
    font: ${(props) => {
        const { fontWeights } = props.theme;
        let fontWeight = fontWeights.regular;
        if (props.bold) {
            fontWeight = fontWeights.bold;
        } else if (props.semiBold) {
            fontWeight = props.semiBold;
        }
        const fontSize = `${props.fontSize || 0.875}rem`;
        return `${fontWeight} ${fontSize} ${props.theme.fontFamilies.proximaNova}`;
    }};
    color: ${(props) => props.color};
    font-style: ${(props) => (props.italic ? 'italic' : 'inherit')};
    display: ${(props) => {
        if (props.block) {
            return 'block';
        } else if (props.inlineBlock) {
            return 'inline-block';
        }
        return 'inline';
    }};
    width: ${(props) => props.width || 'auto'};
    vertical-align: top;
`;

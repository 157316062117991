import { FieldsResource, FieldConfigurationBundle } from '@mark43/rms-api';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Field Configurations Admin Resource',
    methods: {
        updateFieldConfigurationBundles(fieldConfigurationBundles: FieldConfigurationBundle[]) {
            return req<FieldsResource.UpdateFieldConfigurationBundles>({
                method: 'PUT',
                url: 'fields/configurations/bundles',
                data: fieldConfigurationBundles,
            });
        },

        enableAllFields() {
            return req<FieldsResource.EnableAllRulesAndFieldsInCurrentDepartment>({
                method: 'PUT',
                url: 'fields/enable',
            });
        },

        upsertCoreFieldConfiguration(data) {
            return req<FieldsResource.UpsertCoreFieldConfiguration>({
                data,
                method: 'POST',
                url: 'fields/core/configurations',
            });
        },
    },
});

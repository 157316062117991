import { ReportsResource } from '@mark43/rms-api';
import { CrashDetailCardBundleForUpsert } from '~/client-common/core/domain/crash-details/state/data';
import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';

export default createResource({
    name: 'Crash Event Info Resource',
    methods: {
        upsertCrashDetailCard(data: CrashDetailCardBundleForUpsert, reportId: number) {
            return req<ReportsResource.UpsertCrashDetailCard>({
                method: 'PUT',
                url: `reports/${reportId}/cards/crash_event_info`,
                // @ts-expect-error API type incorrectly requires id and has versionable properties
                data,
            });
        },
    },
});

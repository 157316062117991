import React, { FC } from 'react';
import { ElasticCase } from '@mark43/rms-api';
import ElasticResultRow, {
    checkRowIsRestricted,
} from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import { TargetProfilesDetailsRow } from '../../../../legacy-redux/components/core/tables/TargetProfilesDetailsRow';

export const ElasticResultRowWithTargetProfileDetails: FC<{ row: ElasticCase }> = ({
    children,
    row,
    ...otherProps
}) => {
    const isRestricted = checkRowIsRestricted(row);

    const shouldRenderTargetProfilesDetails = row.isTargetProfile && !isRestricted;

    return (
        <ElasticResultRow row={row} {...otherProps}>
            {children}
            {shouldRenderTargetProfilesDetails && <TargetProfilesDetailsRow row={row} />}
        </ElasticResultRow>
    );
};

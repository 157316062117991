import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { map } from 'lodash';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import MenuPopover from '../../../core/box/components/MenuPopover';
import _Link from '../../../core/components/links/Link';
import NotificationSummary from '../../core/components/NotificationSummary';
import NoDataBlock from '../../../core/components/NoDataBlock';
import FocusInside from '../../../core/components/FocusInside';

import { closeBox } from '../../../../legacy-redux/actions/boxActions';
import { openNotificationSettingsForm } from '../../settings/state/ui';
import { mostRecentNotificationViewModelsSelector } from '../state/ui';
import { navigationLinkLeftPositionSelector } from '../../navigation-link/state/ui';

const strings = componentStrings.notifications.popover.NotificationsPopover;
const popoverContext = { name: boxEnum.NOTIFICATIONS_POPOVER };

// same style as ItemQueuePopover
const Header = styled.div`
    box-shadow: 0 1px 14px ${(props) => props.theme.colors.shadow};
    text-align: right;
    padding: 7px 20px 9px 0;
    position: relative;
`;
const Link = styled(_Link)`
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    text-transform: uppercase;
    font-size: var(--arc-fontSizes-md);
`;
const Settings = styled(Link)`
    cursor: pointer;
`;
const Content = styled.div`
    overflow-x: hidden;
`;
const NoResults = styled.div`
    margin-top: 40px;
    text-align: center;
`;
const Divider = styled.span`
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
    margin: 0 8px;
    display: inline-block;
    height: 10px;
`;

function NotificationsPopover({
    closeBox,
    openNotificationSettingsForm,
    popoverLeftPosition,
    userNotificationViewModels,
}) {
    const modalStyle = { left: popoverLeftPosition };

    function handleSettingsButtonClick() {
        openNotificationSettingsForm();
        closeBox();
    }

    return (
        <MenuPopover context={popoverContext} modalStyle={modalStyle}>
            <Header>
                <Settings as="span" tabIndex="0" onClick={handleSettingsButtonClick}>
                    {strings.labels.settings}
                </Settings>
                <Divider />
                <FocusInside>
                    <Link to="/notifications" onClick={closeBox}>
                        {strings.labels.notificationsPage}
                    </Link>
                </FocusInside>
            </Header>
            {userNotificationViewModels.length > 0 ? (
                <Content>
                    {map(userNotificationViewModels, (userNotificationViewModel) => (
                        <NotificationSummary
                            key={userNotificationViewModel.userNotificationLinkId}
                            width={340}
                            userNotificationViewModel={userNotificationViewModel}
                            baseOnClick={closeBox}
                        />
                    ))}
                </Content>
            ) : (
                <NoResults>
                    <NoDataBlock>{strings.labels.noNotifications}</NoDataBlock>
                </NoResults>
            )}
        </MenuPopover>
    );
}

/**
 * Popover to display N most recent Notifications and a link to the
 *   Notifications page. Popover is opened by clicking on the top menu Nav Link
 *   for Notifications. Popover is dynamically anchored to the Notifications
 *   Nav Link.
 */
export default connect(
    createStructuredSelector({
        userNotificationViewModels: mostRecentNotificationViewModelsSelector,
        popoverLeftPosition: navigationLinkLeftPositionSelector,
    }),
    (dispatch) => ({
        // fallback to close popover when already on notifications page
        closeBox: () => dispatch(closeBox(popoverContext)),
        openNotificationSettingsForm: () => dispatch(openNotificationSettingsForm()),
    })
)(NotificationsPopover);

import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { omit } from 'lodash';
import { createStructuredSelector, createSelector } from 'reselect';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import evidencePrintResource from '../../resources/evidencePrintResource';
import { queryParamDefaults } from '../../configuration';
import evidenceDashboardSearchForm, {
    convertEvidenceDashboardSearchFormModelToFilterGroups,
    convertEvidenceDashboardSearchElasticQueryToFormModel,
} from '../forms/evidenceDashboardSearchForm';
import { createEvidenceItemsResultsSelector } from './results';

// This has to handle vehicles and property
function searchResultToRoutePath(item) {
    // this is supposedly 100%
    if (item.vinNumber) {
        return `/profiles/vehicles/${item.masterItemId}/details/`;
    }
    return `/profiles/property/${item.masterItemId}/details/`;
}

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object} boundSelectors selectors that were bound to the search module
 * @return {Object}   Array of filter group view models.
 */
function convertEvidenceDashboardSearchElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    // omit fields that shouldn't appear as applied filters
    const filteredQuery = omit(elasticQuery, ['query']);
    return convertEvidenceDashboardSearchFormModelToFilterGroups(
        convertEvidenceDashboardSearchElasticQueryToFormModel(filteredQuery),
        formatFieldValue,
        boundSelectors
    );
}

export const renCreationDateRangeLabelSelector = createSelector(
    formatFieldByNameSelector,
    (formatFieldByName) =>
        componentStrings.evidence.dashboard.filterForm.renCreationDateRange(
            formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
        )
);

/**
 * Redux module for evidence dashboard search.
 */
export const evidenceDashboardSearch = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.EVIDENCE_ITEM.name,
    baseUiSelector: (state) => state.ui.evidence.dashboard.search,
    form: evidenceDashboardSearchForm,
    searchResultToRoutePath,
    elasticQueryToFilterGroups: convertEvidenceDashboardSearchElasticQueryToFilterGroups,
    exportResourceMethod: evidencePrintResource.exportEvidence, // TODO this appears unused
    defaultTableState: {
        from: queryParamDefaults.FROM,
        size: queryParamDefaults.SIZE,
        sortKey: queryParamDefaults.SORT_KEY,
        sortType: queryParamDefaults.SORT_TYPE,
    },
    resourceMethod: elasticSearchResource.searchEvidenceItems,
    resultsContainerClassName: 'dashboard-search-results',
    createResultsViewModelsSelector: createEvidenceItemsResultsSelector,
    selectorToBind: createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
        renCreationDateRangeLabel: renCreationDateRangeLabelSelector,
    }),
});

import React from 'react';
import { FormattedDate } from '~/client-common/core/dates/components';
import SummaryRow from './SummaryRow';

/**
 * @param {object} props
 * @param {string} [props.label]
 * @param {string} [props.fieldName]
 * @param {string} [props.date]
 * @param {keyof typeof FormattedDate.FORMATS} [props.format]
 */
const SummaryRowDate = ({
    label,
    fieldName,
    date,
    format = FormattedDate.FORMATS.SUMMARY_DATE_TIME,
}) => (
    <FormattedDate date={date} format={format}>
        {(formattedDate) => (
            <SummaryRow label={label} fieldName={fieldName}>
                {formattedDate}
            </SummaryRow>
        )}
    </FormattedDate>
);

// allows consumers to only need 1 import
SummaryRowDate.FORMATS = FormattedDate.FORMATS;

export default SummaryRowDate;

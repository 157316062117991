import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { filter, find, get, head, includes, isEmpty } from 'lodash';

import { createSelector } from 'reselect';
import { isUndefinedOrNull } from '../../../../../helpers/logicHelpers';
import {
    locationEntityLinksWhereSelector,
    NEXUS_STATE_PROP as LOCATION_ENTITY_LINK_NEXUS_STATE_PROP,
} from '../../../location-entity-links/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

import {
    arrestForReportIdSelector,
    NEXUS_STATE_PROP as ARREST_NEXUS_STATE_PROP,
} from '../../../arrests/state/data';
import {
    arrestAttributesWhereSelector,
    NEXUS_STATE_PROP as ARREST_ATTRIBUTE_NEXUS_STATE_PROP,
} from '../../../arrest-attributes/state/data';
import {
    NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP,
    nameReportLinksWhereSelector,
} from '../../../name-report-links/state/data';
import { sortedAugmentedAttachmentViewModelsWhereSelector } from '../../../attachments/state/ui';

import { warrantsWhereSelector } from '../../../warrants/state/data';
import { vehiclesWhereSelector } from '../../../vehicles/state/data';
import { reportByIdSelector, NEXUS_STATE_PROP as REPORT_NEXUS_STATE_PROP } from '../data';

export const ARREST_CARD_LOCATION_LINK_TYPES = [LinkTypesEnum.ARREST_LOCATION];
export const ARREST_CARD_NAME_REPORT_LINK_TYPES = [
    LinkTypesEnum.DEFENDANT,
    LinkTypesEnum.CO_DEFENDANT,
    LinkTypesEnum.COMPLAINANT_IN_REPORT,
];

const REPLACE_ARREST_CARD_BUNDLE_START = 'reports/REPLACE_ARREST_CARD_BUNDLE_START';
export const REPLACE_ARREST_CARD_BUNDLE_SUCCESS = 'reports/REPLACE_ARREST_CARD_BUNDLE_SUCCESS';
const REPLACE_ARREST_CARD_BUNDLE_FAILURE = 'reports/REPLACE_ARREST_CARD_BUNDLE_FAILURE';

export const getDefendantRemarks = (arrest, nameReportLinks) => {
    const defendantReportLink =
        find(
            nameReportLinks,
            (nrl) =>
                nrl.nameId === arrest.defendantId &&
                nrl.contextId === arrest.reportId &&
                nrl.linkType === LinkTypesEnum.DEFENDANT
        ) || {};
    return defendantReportLink.statement;
};

export const hasLinkedVehiclesSelector = createSelector(
    vehiclesWhereSelector,
    (vehiclesWhere) => (reportId) => {
        const linkedVehicles = vehiclesWhere({ ownerId: reportId });
        return !isEmpty(linkedVehicles);
    }
);

export const hydratedArrestByReportIdSelector = createSelector(
    arrestForReportIdSelector,
    arrestAttributesWhereSelector,
    warrantsWhereSelector,
    sortedAugmentedAttachmentViewModelsWhereSelector,
    locationEntityLinksWhereSelector,
    nameReportLinksWhereSelector,
    hasLinkedVehiclesSelector,
    (
        arrestForReportId,
        arrestAttributesWhere,
        warrantsWhere,
        sortedAugmentedAttachmentViewModelsWhere,
        locationEntityLinksWhere,
        nameReportLinksWhere,
        hasLinkedVehicles
    ) => (reportId) => {
        const arrest = arrestForReportId(reportId);
        const arrestId = get(arrest, 'id');
        const arrestAttributes = arrestAttributesWhere({ arrestId });
        const defendantId = get(arrest, 'defendantId');
        const defendantAttachments = sortedAugmentedAttachmentViewModelsWhere({
            entityType: EntityTypeEnum.PERSON_PROFILE.name,
            entityId: defendantId,
        });
        const arrestLocation = head(
            locationEntityLinksWhere({
                entityType: EntityTypeEnum.ARREST.name,
                linkType: LinkTypesEnum.ARREST_LOCATION,
                entityId: arrestId,
            })
        );
        const allNameReportLinks = nameReportLinksWhere({
            reportId,
            contextType: EntityTypeEnum.REPORT.name,
            contextId: reportId,
        });
        const arrestNameReportLinks = filter(allNameReportLinks, (nrl) =>
            includes(ARREST_CARD_NAME_REPORT_LINK_TYPES, nrl.linkType)
        );
        const defendantRemarks = getDefendantRemarks(arrest, arrestNameReportLinks);

        // Legacy, Arrests V2 warrants.  For Arrests V2, we allowed functionality
        // for directly connecting an `Arrest` to a `Warrant`, through a
        // `Warrant#arrestId`, rather than using `Charge`s
        // (`Charge#warrantId` <-> `Charge#arrestId`) as we do today.
        // On production, on CCPD used Arrests V2 and they still a lot of these
        // legacy `Warrant`s in production, so, for summary mode display purposes,
        // we display this data on old reports.
        const warrants = warrantsWhere({ arrestId });
        const augmentedArrest = {
            hasLinkedVehicles: hasLinkedVehicles(reportId),
            ...arrest,
        };

        return {
            arrest: augmentedArrest,
            arrestAttributes,
            defendantAttachments,
            warrants,
            arrestLocation,
            nameReportLinks: arrestNameReportLinks,
            defendantRemarks,
        };
    }
);

// ACTIONS
function replaceArrestCardBundleStart(reportId, arrestCardBundle) {
    return {
        type: REPLACE_ARREST_CARD_BUNDLE_START,
        payload: { reportId, arrestCardBundle },
    };
}

function replaceArrestCardBundleSuccess(arrestCardBundle) {
    return {
        type: REPLACE_ARREST_CARD_BUNDLE_SUCCESS,
        payload: { arrestCardBundle },
    };
}

function replaceArrestCardBundleFailure(errorMessage) {
    return {
        type: REPLACE_ARREST_CARD_BUNDLE_FAILURE,
        payload: errorMessage,
    };
}

export function replaceArrestCardBundle(reportId, arrestCardBundle) {
    return (dispatch, getState, { nexus: { withEntityItems, withRemove } }) => {
        dispatch(replaceArrestCardBundleStart(reportId, arrestCardBundle));

        return getReportCardBundleResource()
            .upsertArrestReportCard(reportId, arrestCardBundle)
            .then((updatedArrestCardBundle) => {
                const {
                    arrest,
                    arrestAttributes,
                    arrestLocationEntityLink,
                    nameReportLinks,
                    recordNumber,
                } = updatedArrestCardBundle;
                const arrestId = arrest.id;

                const state = getState();
                const existingReport = reportByIdSelector(state)(reportId);
                const updatedReport = {
                    ...existingReport,
                    recordNumber,
                };

                const entitiesToStore = {
                    [ARREST_NEXUS_STATE_PROP]: [arrest],
                    [ARREST_ATTRIBUTE_NEXUS_STATE_PROP]: arrestAttributes,
                    [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinks,
                    [REPORT_NEXUS_STATE_PROP]: [updatedReport],
                };

                if (!isUndefinedOrNull(arrestLocationEntityLink)) {
                    entitiesToStore[LOCATION_ENTITY_LINK_NEXUS_STATE_PROP] = [
                        arrestLocationEntityLink,
                    ];
                }

                const withRemoveNameReportLinks = withRemove(
                    NAME_REPORT_LINKS_NEXUS_STATE_PROP,
                    (nameReportLink) =>
                        includes(ARREST_CARD_NAME_REPORT_LINK_TYPES, nameReportLink.linkType) &&
                        reportId === nameReportLink.reportId,
                    { type: 'UPSERT_ARREST_CARD_BUNDLE' }
                );

                // replace `ArrestAttribute`s and arrest location `LocationEntityLink`.
                dispatch(
                    withRemove(
                        ARREST_ATTRIBUTE_NEXUS_STATE_PROP,
                        { arrestId },
                        withRemove(
                            LOCATION_ENTITY_LINK_NEXUS_STATE_PROP,
                            (locationEntityLink) =>
                                locationEntityLink.entityType === EntityTypeEnum.ARREST.name &&
                                locationEntityLink.entityId === arrestId &&
                                includes(
                                    ARREST_CARD_LOCATION_LINK_TYPES,
                                    locationEntityLink.linkType
                                ),
                            withEntityItems(
                                entitiesToStore,
                                replaceArrestCardBundleSuccess(updatedArrestCardBundle)
                            )
                        ),
                        withRemoveNameReportLinks
                    )
                );

                return updatedArrestCardBundle;
            })
            .catch((err) => {
                dispatch(replaceArrestCardBundleFailure(err.message));
                throw err;
            });
    };
}

import React from 'react';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { FormattedDate } from '~/client-common/core/dates/components';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import Icon, { iconTypes } from '../../Icon';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';

const AgencyContainer = styled.div`
    margin-top: 10px;
`;
const AgencyText = styled.span`
    color: ${(props) => props.theme.colors.lightGrey};
    text-transform: uppercase;
`;

function OrganizationCell({
    elasticOrganization,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}) {
    const { name, industryOther, departmentId, updatedDateUtc } = elasticOrganization;
    const { organizationTypeAttrId, industryAttrId } = getViewModelProperties(elasticOrganization);
    const isExternalAgency = departmentId !== currentUserDepartmentId;
    const departmentName = isExternalAgency
        ? departmentNameFromConsortiumLinksByDepartmentId(departmentId)
        : undefined;

    return (
        <div className="elastic-organization-info">
            <div className="elastic-organization-info-icon-container">
                <Icon type={iconTypes.ORGANIZATION} />
            </div>
            <div className="elastic-organization-info-content">
                <div className="elastic-organization-name">{name}</div>
                <div className="elastic-organization-type-and-industry">
                    {joinTruthyValues(
                        [organizationTypeAttrId, industryAttrId, industryOther],
                        ' / '
                    )}
                </div>
                <div className="elastic-organization-modified-date-container">
                    <span className="elastic-organization-modified-text">Modified: </span>
                    <span className="elastic-organization-modified-date">
                        <FormattedDate
                            date={updatedDateUtc}
                            format={FormattedDate.FORMATS.FORM_DATE}
                        />
                    </span>
                </div>
                {departmentName && (
                    <AgencyContainer>
                        <AgencyText>Agency: </AgencyText>
                        <span>{departmentName}</span>
                    </AgencyContainer>
                )}
            </div>
        </div>
    );
}

export default compose(
    mapProps((ownerProps) => ({ elasticOrganization: ownerProps })),
    connect(
        createStructuredSelector({
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
        })
    )
)(OrganizationCell);

import { filter, find, includes } from 'lodash';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { _Form } from 'markformythree';
import { RefContextEnum, EntityTypeEnum } from '@mark43/rms-api';
import { taskEntityLinksSelector } from '~/client-common/core/domain/task-entity-links/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { PopoutLink } from '../../../core/components/links/Link';
import ApprovalStatusIcon, {
    CaseApprovalStatusIcon,
} from '../../../records/core/components/ApprovalStatusIcon';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { MFTNItems, RemoveButton } from '../../../core/forms/components/NItems';
import taskForm, { FormTaskConfiguration, TaskEntityLinkFormDataShape } from '../forms/taskForm';
import { getTaskEntityLinkTitle } from '../utils/getTaskEntityLinkTitle';
import { getTaskEntityLinkUrl } from '../utils/getTaskEntityLinkUrl';
import { allowedTaskLinkEntityTypes } from '../config';
import { FlexWrapper, FlexItem, FlexBox } from './LinkedEntitySummary';

const IconContainer = styled.div`
    flex: 0 0 auto;
`;

const TaskEntityIcon: FC<{ item: TaskEntityLinkFormDataShape }> = ({ item }) => {
    let iconType: keyof typeof iconTypes = iconTypes.PROPERTY;

    switch (item.entityType) {
        case EntityTypeEnum.CASE.name:
        case EntityTypeEnum.E_FILE.name:
            if (item.approvalStatusForCase) {
                return <CaseApprovalStatusIcon approvalStatus={item.approvalStatusForCase} />;
            }
            iconType = iconTypes.CASE;
            break;
        case EntityTypeEnum.ITEM_PROFILE.name:
            switch (item.itemTypeAttrId) {
                case globalAttributes.itemType.firearm:
                    iconType = iconTypes.FIREARM;
                    break;
                case globalAttributes.itemType.vehicle:
                    iconType = iconTypes.VEHICLE;
                    break;
                default:
                    iconType = iconTypes.PROPERTY;
                    break;
            }
            break;
        case EntityTypeEnum.PERSON_PROFILE.name:
            iconType = iconTypes.PERSON;
            break;
        case EntityTypeEnum.REPORT.name:
            if (item.clientApprovalStatus) {
                // TaskEntityLinkView does not include info about the report's sealed/vacated states
                return (
                    <ApprovalStatusIcon
                        approvalStatus={item.clientApprovalStatus}
                        isSealed={false}
                        isPartiallySealed={false}
                        isVacated={false}
                    />
                );
            }
            iconType = iconTypes.REPORT;
            break;
        case EntityTypeEnum.WARRANT.name:
            iconType = iconTypes.WARRANT;
            break;
        case EntityTypeEnum.BRIEFING.name:
            iconType = iconTypes.DOCUMENT;
            break;
        default:
            iconType = iconTypes.PROPERTY;
            break;
    }

    return <Icon type={iconType} color="mediumLightGrey" size={20} />;
};

const TaskEntityLink: FC<{
    item: TaskEntityLinkFormDataShape;
    form: _Form<FormTaskConfiguration>;
    removeItem?: () => void;
    disabled?: boolean;
}> = ({ item, form, removeItem, disabled }) => {
    const onRemove = useCallback(() => {
        if (removeItem) {
            removeItem();

            const formModel = form.get();
            // When the taskEntityLink being removed is also the current owner of the task, automatically update the
            // owner fields. We do this here rather than in convertFromFormModel because that method cannot reliably
            // know whether to trust the task owner or the taskEntityLink when the two do not match with each other. Any
            // subsequent logic including convertFromFormModel is welcome to update the owner fields, as long as it does
            // not revert to the previous owner.
            if (formModel.ownerId === item.entityId) {
                form.transaction(() => {
                    form.set('ownerType', EntityTypeEnum.TASK.name);
                    form.set('ownerId', formModel.id || undefined);
                });
            }
        }
    }, [removeItem, item, form]);
    return (
        <FlexWrapper>
            <TaskEntityIcon item={item} />
            <FlexItem>
                <FlexBox>
                    <PopoutLink to={getTaskEntityLinkUrl(item)}>
                        {getTaskEntityLinkTitle(item)}
                    </PopoutLink>
                </FlexBox>
            </FlexItem>
            {!disabled && removeItem ? (
                <IconContainer>
                    <RemoveButton onClick={onRemove} />
                </IconContainer>
            ) : undefined}
        </FlexWrapper>
    );
};

const TaskEntityLinksSection: FC<{ disabled?: boolean }> = ({ disabled }) => {
    const allTaskEntityLinks = useSelector(taskEntityLinksSelector);

    return (
        <ArbiterForm<FormTaskConfiguration>
            context={RefContextEnum.FORM_TASK.name}
            {...taskForm}
            render={(form) => {
                const taskId = form.get().id;
                const taskEntityLinks = taskId ? filter(allTaskEntityLinks, { taskId }) : [];

                return (
                    <MFTNItems<TaskEntityLinkFormDataShape>
                        path="taskEntityLinks"
                        childFieldKeys={['entityType', 'entityId']}
                        addItemOnEmpty={false}
                        renderAddButton={undefined}
                        renderRemoveButton={undefined}
                        render={({ item, index, removeItem }) => {
                            const formModel = form.get();
                            const isOwner =
                                item.entityId === formModel.ownerId &&
                                item.entityType === formModel.ownerType;
                            // item is from form state, and taskEntityLink is from overlay state. The latter is used only to
                            // initialize the form state and display titles in the UI.
                            const taskEntityLink = find(taskEntityLinks, {
                                entityType: item.entityType,
                                entityId: item.entityId,
                            });
                            return (
                                <TaskEntityLink
                                    key={taskEntityLink?.id || index}
                                    item={item}
                                    form={form}
                                    disabled={disabled}
                                    removeItem={
                                        isOwner &&
                                        !includes(allowedTaskLinkEntityTypes, item.entityType)
                                            ? undefined
                                            : removeItem
                                    }
                                />
                            );
                        }}
                    />
                );
            }}
        />
    );
};

/**
 * This is the list of TaskEntityLinks in the "Linked Items" section in the task side panel, not to be confused with the
 * side panel used to link entities to a task (TaskEntitiesLinkSidePanel) or the section which displays tasks linked to
 * a given entity (LinkedTasksSection).
 */
export default TaskEntityLinksSection;

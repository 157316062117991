import React from 'react';

// components
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_PERSON_PROFILE_EMPLOYER_INFO_LABEL,
    DISPLAY_ONLY_PERSON_PROFILE_SCHOOL_INFO_LABEL,
    EMPLOYMENT_HISTORY_EMPLOYER_NAME,
    SCHOOL_HISTORY_SCHOOL_NAME,
} from '~/client-common/core/enums/universal/fields';
import testIds from '../../../../core/testIds';
import { renderAddButton } from '../../names/components/nameFormNItemsRenderers';
import { MFTNItems } from '../../forms/components/NItems';
import { DashedDivider } from '../../../../legacy-redux/components/core/Divider';
import {
    renderEmploymentHistoriesNItems,
    renderSchoolHistoriesNItems,
} from './nameFormNItemsRenderers';
import { FormSection } from './FormSection';
import { NItemsRow } from './NItemsRow';

const EMPLOYMENT_HISTORIES_PATH = 'employmentHistories';
const SCHOOL_HISTORIES_PATH = 'schoolHistories';
export function PersonProfileEmploymentSchoolHistories({ form }) {
    const labels = useFields([
        DISPLAY_ONLY_PERSON_PROFILE_EMPLOYER_INFO_LABEL,
        DISPLAY_ONLY_PERSON_PROFILE_SCHOOL_INFO_LABEL,
    ]);
    return (
        <>
            <div data-test-field-name={EMPLOYMENT_HISTORY_EMPLOYER_NAME}>
                <MFTNItems
                    path={EMPLOYMENT_HISTORIES_PATH}
                    addItemOnEmpty={false}
                    addText={labels[DISPLAY_ONLY_PERSON_PROFILE_EMPLOYER_INFO_LABEL]}
                    hideAddButtonOnEmptyItem={true}
                    childFieldKeys={[
                        'employerName',
                        'employerAddress',
                        'occupation',
                        'displayNumber',
                        'dateStart',
                        'dateEnd',
                    ]}
                    renderRowContainer={({ itemElement, index, item }) => (
                        <NItemsRow key={index} index={index} item={item} children={itemElement} />
                    )}
                    render={({ removeItem }) => (
                        <FormSection removeItem={removeItem}>
                            {renderEmploymentHistoriesNItems(form)}
                        </FormSection>
                    )}
                    renderRemoveButton={undefined}
                    renderAddButton={(props) =>
                        renderAddButton({
                            ...props,
                            testId: testIds.PERSON_PROFILE_ADD_EMPLOYMENT_INFO,
                        })
                    }
                />
            </div>
            <div data-test-field-name={SCHOOL_HISTORY_SCHOOL_NAME}>
                <DashedDivider />
                <MFTNItems
                    path={SCHOOL_HISTORIES_PATH}
                    addItemOnEmpty={false}
                    addText={labels[DISPLAY_ONLY_PERSON_PROFILE_SCHOOL_INFO_LABEL]}
                    hideAddButtonOnEmptyItem={true}
                    childFieldKeys={[
                        'schoolName',
                        'schoolAddress',
                        'grade',
                        'status',
                        'displayNumber',
                    ]}
                    renderRowContainer={({ itemElement, index, item }) => (
                        <NItemsRow key={index} index={index} item={item} children={itemElement} />
                    )}
                    render={({ removeItem }) => (
                        <FormSection removeItem={removeItem}>
                            {renderSchoolHistoriesNItems(form)}
                        </FormSection>
                    )}
                    renderRemoveButton={undefined}
                    renderAddButton={(props) =>
                        renderAddButton({
                            ...props,
                            testId: testIds.PERSON_PROFILE_ADD_SCHOOL_INFO,
                        })
                    }
                />
            </div>
        </>
    );
}

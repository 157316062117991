import React from 'react';
import { BriefingSimpleView } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { BriefingCellWrapper } from './BriefingCellWrapper';

type Props = Pick<BriefingSimpleView, 'createdBy'>;
export const BriefingCreatedByCell = ({ createdBy }: Props) => {
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);

    return <BriefingCellWrapper>{formatMiniUserById(createdBy)}</BriefingCellWrapper>;
};

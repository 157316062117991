import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { pick } from 'lodash';

import {
    ComplianceErrorSummary,
    NibrsErrorSummary,
    ComplianceExportQuery,
    NibrsExportQuery,
} from '@mark43/rms-api';

import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { agencyProfileByIdSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import getExportsResource from '~/client-common/core/domain/exports/resources/exportsResource';

import reportPrintingResource from '../../admin/report-printing-templates/resources/reportPrintingResource';

import { NIBRS_COMPLIANCE_TYPE } from '../configuration';
import { createPoller } from '../util/complianceExportTypeHelpers';
import nibrsExportResource from '../resources/nibrsExportResource';
import complianceExportResource from '../resources/complianceExportResource';
import { useComplianceExportContext } from '../contexts/ComplianceExportContext';
import {
    ComplianceExportType,
    ErrorSummary,
    FetchErrorsSuccessType,
    NibrsErrorCounts,
} from '../types';

function useGenerateErrors(complianceExportType?: ComplianceExportType) {
    const [errorSummary, setErrorSummary] = useState<ErrorSummary | undefined>();
    const [nibrsErrorCounts, setNibrsErrorCounts] = useState<NibrsErrorCounts | undefined>();
    const [agencyName, setAgencyName] = useState<string>();
    const agencyProfileById = useSelector(agencyProfileByIdSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const { isNibrs } = useComplianceExportContext();
    const errorsResource = isNibrs
        ? reportPrintingResource.generateNibrsTestExport
        : reportPrintingResource.generateComplianceExportDraft;

    const resource = useCallback(
        async (payload) => {
            const results = await errorsResource(payload, {
                async: applicationSettings.RMS_NIBRS_EXPORT_ASYNC_ENABLED,
            });
            const [firstExport] = results;

            if (!firstExport) {
                throw new Error('An error occurred with the export, please try again.');
            }

            const agency = agencyProfileById(payload.agencyId);
            if (agency) {
                setAgencyName(agency.agencyName);
            }

            const pollForErrorResults = createPoller(
                getExportsResource().pollForExportIds,
                isNibrs
                    ? nibrsExportResource.getExportErrors
                    : complianceExportResource.getComplianceErrorSummary
            );

            return pollForErrorResults([firstExport.id]);
        },
        [applicationSettings, agencyProfileById, errorsResource, isNibrs]
    );

    const onNibrsSuccess = useCallback(
        (result?: NibrsErrorSummary) => {
            if (result) {
                setErrorSummary({
                    complianceType: NIBRS_COMPLIANCE_TYPE.name,
                    errorSummary: result,
                });

                setNibrsErrorCounts(
                    pick(result, [
                        'errorRate',
                        'totalArrestErrorNum',
                        'totalArrestNum',
                        'totalIncidentErrorNum',
                        'totalIncidentNum',
                    ])
                );
            }
        },
        [setErrorSummary, setNibrsErrorCounts]
    );

    const onComplianceSuccess = useCallback(
        (result?: ComplianceErrorSummary) => {
            // excluding NIBRS for complianceType discriminator type
            if (
                result &&
                complianceExportType &&
                complianceExportType.name !== NIBRS_COMPLIANCE_TYPE.name
            ) {
                setErrorSummary({
                    complianceType: complianceExportType.name,
                    errorSummary: result,
                });
            }
        },
        [complianceExportType, setErrorSummary]
    );

    // @ts-expect-error If we type this as isNibrs extends true ? (N => void) : (C => void), then all the other functions that rely on the union (N | C) will also have to update
    const onSuccess: FetchErrorsSuccessType = isNibrs ? onNibrsSuccess : onComplianceSuccess;

    const { callResource, loading } = useResourceDeferred<
        NibrsErrorSummary | ComplianceErrorSummary | undefined,
        [Partial<NibrsExportQuery | ComplianceExportQuery>]
    >(resource, onSuccess);

    const resetErrors = useCallback(() => {
        setErrorSummary(undefined);
        setNibrsErrorCounts(undefined);
    }, []);

    return {
        callResource,
        errorSummary,
        loading,
        nibrsErrorCounts,
        agencyName,
        resetErrors,
        onFetchErrorsSuccess: onSuccess,
    };
}

export default useGenerateErrors;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { labelPrinterOptionsSelector } from '~/client-common/core/domain/label-printers/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const { label } = componentStrings.forms.select.LabelPrinterSelect;

/**
 * Select dropdown containing all label printers.
 * @param {Object} props Same props as `Select`.
 */
const LabelPrinterSelect = connect(
    createStructuredSelector({ options: labelPrinterOptionsSelector })
)(function LabelPrinterSelect(props) {
    return <Select label={label} {...props} />;
});

export const RRFLabelPrinterSelect = connectRRFInput(LabelPrinterSelect);

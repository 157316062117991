import { chain, first } from 'lodash';
import { createSelector } from 'reselect';
import {
    fillablePdfTemplatesSelector,
    fillablePdfTemplatesWhereSelector,
} from '~/client-common/core/domain/fillable-pdf-templates/state/data';
import {
    convertFromFormModel,
    convertToFormModel,
    FILLABLE_PDF_TEMPLATES_ADMIN_FORM_NAME,
} from '../form/fillablePdfTemplatesAdminForm';

import {
    clearUploads,
    updateUploads,
} from '../../../../attachments/core/state/ui/inlineAttachmentsUploader';
import { UNPERSISTED_ENTITY_ID } from '../../../../attachments/core/configuration';

import {
    upsertFillablePdfTemplate,
    LOAD_FILLABLE_PDF_TEMPLATES_START,
    LOAD_FILLABLE_PDF_TEMPLATES_SUCCESS,
    LOAD_FILLABLE_PDF_TEMPLATES_FAILURE,
    SAVE_FILLABLE_PDF_TEMPLATE_START,
    SAVE_FILLABLE_PDF_TEMPLATE_SUCCESS,
    SAVE_FILLABLE_PDF_TEMPLATE_FAILURE,
    CLEAR_ERRORS,
} from '../data';

const initialUiState = {
    isSaving: false,
    isLoading: false,
    savingErrorMessage: undefined,
    loadingErrorMessage: undefined,
};

export default function fillablePdfTemplatesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SAVE_FILLABLE_PDF_TEMPLATE_START:
            return {
                ...state,
                isSaving: true,
            };
        case SAVE_FILLABLE_PDF_TEMPLATE_SUCCESS:
            return {
                ...state,
                isSaving: false,
            };
        case SAVE_FILLABLE_PDF_TEMPLATE_FAILURE:
            return {
                ...state,
                savingErrorMessage: action.payload.errorMessage,
                isSaving: false,
            };
        case LOAD_FILLABLE_PDF_TEMPLATES_START:
            return {
                ...state,
                isLoading: true,
            };

        case LOAD_FILLABLE_PDF_TEMPLATES_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case LOAD_FILLABLE_PDF_TEMPLATES_FAILURE:
            return {
                ...state,
                loadingErrorMessage: action.payload.errorMessage,
                isLoading: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                loadingErrorMessage: undefined,
                savingErrorMessage: undefined,
            };
        default:
            return state;
    }
}

const fillablePdfTemplatesAdminSelector = (state) => state.ui.fillablePdfTemplatesAdmin;

export const fillablePdfTemplatesAdminViewModelByIdSelector = createSelector(
    fillablePdfTemplatesSelector,
    (fillablePdfTemplates) => (selectedTemplateId) => {
        return chain(fillablePdfTemplates)
            .map(({ id, title }) => ({
                title,
                key: id,
                selected: id === selectedTemplateId,
            }))
            .value();
    }
);

export const fillablePdfTemplatesSavingErrorMessageSelector = createSelector(
    fillablePdfTemplatesAdminSelector,
    (fillablePdfTemplatesAdmin) => fillablePdfTemplatesAdmin.savingErrorMessage
);

export const loadingFillablePdfTemplatesErrorMessageSelector = createSelector(
    fillablePdfTemplatesAdminSelector,
    (fillablePdfTemplatesAdmin) => fillablePdfTemplatesAdmin.loadingErrorMessage
);

export const fillablePdfTemplatesLoadingSelector = createSelector(
    fillablePdfTemplatesAdminSelector,
    (fillablePdfTemplatesAdmin) => fillablePdfTemplatesAdmin.isLoading
);

export const fillablePdfTemplatesIsSavingSelector = createSelector(
    fillablePdfTemplatesAdminSelector,
    (fillablePdfTemplatesAdmin) => fillablePdfTemplatesAdmin.isSaving
);

export function resetAndPrefillFillablePdfTemplateForm(selectedTemplateId) {
    return (dispatch, getState, { formsRegistry }) => {
        const fillablePdfTemplate = first(
            fillablePdfTemplatesWhereSelector(getState())({
                id: selectedTemplateId,
            })
        );
        if (fillablePdfTemplate) {
            const formModel = convertToFormModel(fillablePdfTemplate);
            const { file } = formModel;

            if (!!file) {
                const inlineAttachmentsFile = {
                    file,
                    entityId: UNPERSISTED_ENTITY_ID,
                    linkType: undefined,
                };
                dispatch(updateUploads([inlineAttachmentsFile]));
            }

            formsRegistry.maybeDeferredOperation(
                FILLABLE_PDF_TEMPLATES_ADMIN_FORM_NAME,
                undefined,
                (form) => {
                    form.set(undefined, formModel);
                }
            );
        }
    };
}

export function submitFillablePdfTemplateAdminForm() {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(FILLABLE_PDF_TEMPLATES_ADMIN_FORM_NAME);

        const id = form.get('id');
        const isNew = !id;

        return form
            .submit()
            .then((successResult) => {
                const { form } = successResult;
                const fillablePdfTemplate = convertFromFormModel(form.get());
                return dispatch(upsertFillablePdfTemplate(fillablePdfTemplate)).then(
                    (updatedPdfTemplate) => {
                        if (isNew) {
                            const { id } = updatedPdfTemplate;
                            form.set('id', id);
                            return { id, success: true, isNew };
                        }
                        return { id, success: true, isNew };
                    }
                );
            })
            .catch((error) => ({ id, success: false, error }));
    };
}

export function resetFillablePdfForm() {
    return (dispatch, getState, { formsRegistry }) => {
        dispatch(clearUploads());
        formsRegistry.maybeDeferredOperation(
            FILLABLE_PDF_TEMPLATES_ADMIN_FORM_NAME,
            undefined,
            (form) => {
                form.resetUi();
                form.resetModel();
            }
        );
    };
}

import _, { filter, get, includes } from 'lodash';
import { createSelector } from 'reselect';

import { elasticStorageLocationsSelector } from '../data';
import {
    formatStorageLocationNames,
    appendExpiredLabelIfExpired,
} from '../../../storage-locations/utils/storageLocationHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import { applicationSettingsSelector } from '../../../settings/state/data';

/**
 * Basically a toString for stroageLocationId's, used in the evidence dashboard
 * @return a string representing all of the combined storage locations
 */
export const formatElasticStorageLocationByIdSelector = createSelector(
    elasticStorageLocationsSelector,
    (elasticStorageLocations) => (storageLocationId) =>
        !_.isArray(storageLocationId)
            ? formatStorageLocationNames(
                  get(elasticStorageLocations[storageLocationId], 'parentLocationNames')
              )
            : joinTruthyValues(
                  _(storageLocationId)
                      .map((storageLocationId) =>
                          formatStorageLocationNames(
                              get(elasticStorageLocations[storageLocationId], 'parentLocationNames')
                          )
                      )
                      .sortBy()
                      .value()
              )
);

/**
 * Storage location typeahead options.
 * @param  {number[]} [options.evidenceFacilityAttrIds]
 * @param  {number}   [options.facilityId]
 * @param  {string[]} [options.storageLocationTypes]
 * @param  {number}   [options.departmentId]
 * @return {Object[]}
 */
export const elasticStorageLocationOptionsSelector = createSelector(
    elasticStorageLocationsSelector,
    applicationSettingsSelector,
    (elasticStorageLocations, applicationSettings) => ({
        evidenceFacilityAttrIds = [],
        facilityId,
        storageLocationTypes = [],
        departmentId,
    } = {}) => {
        let locations = elasticStorageLocations;

        // filter out the storageLocations to only one facility
        if (facilityId) {
            locations = filter(locations, { facilityId });
        }

        // filter out the storageLocations so only ones that are in the
        // facility of a certain type remain
        if (evidenceFacilityAttrIds.length > 0) {
            locations = filter(locations, ({ facilityTypeAttrId }) =>
                includes(evidenceFacilityAttrIds, facilityTypeAttrId)
            );
        }

        if (storageLocationTypes.length > 0) {
            locations = filter(locations, ({ storageLocationType }) =>
                includes(storageLocationTypes, storageLocationType)
            );
        }

        if (departmentId) {
            locations = filter(locations, { departmentId });
        }

        // elasticStorageLocations have options from the search API and typeahead options.
        // When the RMS_USE_EVD_LOCATION_PERMS_ENABLED is enabled, we only want to show the
        // locations that user has permission on. However, there is no guarantee that user has
        // the right permission on typeahead locations, so we need to filter out typeahead
        // locations.
        // Typeahead options doesn't has storageLocationPermissions prop, so we can filter
        // out those options base on this prop.
        if (applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED) {
            locations = filter(
                locations,
                ({ storageLocationPermissions }) => !!storageLocationPermissions
            );
        }

        return _.map(locations, ({ id, parentLocationNames, barcodeValue, expiredDateUtc }) => ({
            value: id,
            display: appendExpiredLabelIfExpired(
                formatStorageLocationNames(parentLocationNames),
                expiredDateUtc
            ),
            expiredDateUtc,
            noteDisplay: barcodeValue,
        }));
    }
);

import { RoleApproval } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getRoleApprovalsResource from '../../resources/roleApprovalsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const roleApprovalsModule = createNormalizedModule<RoleApproval>({
    type: 'roleApprovals',
    // keyed by `roleId` because this is actaully useful
    // for quick access
    key: 'roleId',
});

const LOAD_ROLE_APPROVAL_FOR_ROLE_START = 'role-approvals/LOAD_ROLE_APPROVAL_FOR_ROLE_START';
const LOAD_ROLE_APPROVAL_FOR_ROLE_SUCCESS = 'role-approvals/LOAD_ROLE_APPROVAL_FOR_ROLE_SUCCESS';
const LOAD_ROLE_APPROVAL_FOR_ROLE_FAILURE = 'role-approvals/LOAD_ROLE_APPROVAL_FOR_ROLE_FAILURE';

const UPDATE_ROLE_APPROVAL_START = 'role-approvals/UPDATE_ROLE_APPROVAL_START';
const UPDATE_ROLE_APPROVAL_SUCCESS = 'role-approvals/UPDATE_ROLE_APPROVAL_SUCCESS';
const UPDATE_ROLE_APPROVAL_FAILURE = 'role-approvals/UPDATE_ROLE_APPROVAL_FAILURE';

function loadRoleApprovalForRoleStart(roleId: number) {
    return {
        type: LOAD_ROLE_APPROVAL_FOR_ROLE_START,
        payload: roleId,
    };
}

function loadRoleApprovalForRoleSuccess(roleApproval: RoleApproval) {
    return {
        type: LOAD_ROLE_APPROVAL_FOR_ROLE_SUCCESS,
        payload: roleApproval,
    };
}

function loadRoleApprovalForRoleFailure(message: string) {
    return {
        type: LOAD_ROLE_APPROVAL_FOR_ROLE_FAILURE,
        payload: message,
    };
}

function updateRoleApprovalStart(roleApproval: RoleApproval) {
    return {
        type: UPDATE_ROLE_APPROVAL_START,
        payload: roleApproval,
    };
}

function updateRoleApprovalSuccess(roleApproval: RoleApproval) {
    return {
        type: UPDATE_ROLE_APPROVAL_SUCCESS,
        payload: roleApproval,
    };
}

function updateRoleApprovalFailure(message: string) {
    return {
        type: UPDATE_ROLE_APPROVAL_FAILURE,
        payload: message,
    };
}

export function loadRoleApprovalForRole(roleId: number): ClientCommonAction<Promise<RoleApproval>> {
    return (dispatch) => {
        dispatch(loadRoleApprovalForRoleStart(roleId));
        const resource = getRoleApprovalsResource();
        return resource
            .loadRoleApprovalForRole(roleId)
            .then((roleApproval: RoleApproval) => {
                dispatch(loadRoleApprovalForRoleSuccess(roleApproval));
                dispatch(storeRoleApprovals(roleApproval));
                return roleApproval;
            })
            .catch((err: Error) => {
                dispatch(loadRoleApprovalForRoleFailure(err.message));
                throw err;
            });
    };
}

export function updateRoleApproval(
    roleApproval: RoleApproval
): ClientCommonAction<Promise<RoleApproval>> {
    return (dispatch) => {
        dispatch(updateRoleApprovalStart(roleApproval));
        const resource = getRoleApprovalsResource();
        return resource
            .upsertRoleApproval(roleApproval)
            .then((roleApproval: RoleApproval) => {
                dispatch(updateRoleApprovalSuccess(roleApproval));
                dispatch(storeRoleApprovals(roleApproval));
                return roleApproval;
            })
            .catch((err: Error) => {
                dispatch(updateRoleApprovalFailure(err.message));
                throw err;
            });
    };
}

// ACTIONS
const storeRoleApprovals = roleApprovalsModule.actionCreators.storeEntities;

// SELECTORS
export const roleApprovalsSelector = roleApprovalsModule.selectors.entitiesSelector;

// REDUCER
export default roleApprovalsModule.reducerConfig;

import styled from 'styled-components';

export default styled.div`
    font: ${(props) =>
        `${props.theme.fontWeights.semiBold} var(--arc-fontSizes-md) ${props.theme.fontFamilies.proximaNova}`};
    color: ${(props) => props.theme.colors.darkGrey};
    width: ${(props) => props.width || 'calc(100% - 80px)'};
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
`;

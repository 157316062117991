import { VehicleModelCodeLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

const vehicleModelCodeLinksModule = createLinkModule<VehicleModelCodeLink>({
    type: 'vehicleModelCodeLinks',
    keys: ['vehicleModelId', 'codeId'],
});

// REDUCER
export default vehicleModelCodeLinksModule.reducerConfig;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttributeTypeEnum, ReportAttribute } from '@mark43/rms-api';
import {
    createField,
    createFormConfiguration,
    lifecycleOptions,
    Form,
    InferFormDataShape,
} from 'markformythree';
import { map } from 'lodash';

import {
    reportAttributesWhereSelector,
    updateReportAttributes,
} from '~/client-common/core/domain/report-attributes/state/data';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import formsRegistry from '../../../../core/formsRegistry';
import { RmsDispatch } from '../../../../core/typings/redux';
import { MFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import Modal, { ModalProps } from '../../../core/overlays/components/Modal';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';

type RoutingLabelOverlayIds = keyof Pick<
    typeof overlayIdEnum,
    | 'ROUTING_LABELS_FOR_EMBEDDED_ARREST_MODAL'
    | 'ROUTING_LABELS_FOR_KEYBOARD_SHORTCUT'
    | 'ROUTING_LABELS_FOR_REPORT_STATUS_COMMENTS_CARD_MODAL'
    | 'ROUTING_LABELS_FOR_SIDEBAR_MODAL'
>;

type RoutingLabelsModalProps = {
    closeFocusRef?: React.RefObject<HTMLElement>;
    overlayId: RoutingLabelOverlayIds;
} & Pick<ModalProps, 'buttonElement'>;

type RoutingLabelsModalCustomProps = { reportId: number };

type UseOpenRoutingLabelsModalProps = {
    reportId: number;
    overlayId: RoutingLabelOverlayIds;
};

const strings = componentStrings.records.core.RoutingLabelsModal.labels;

const formConfiguration = createFormConfiguration({
    reportLabelAttributeIds: createField<number[]>({}),
});

const initialState = {
    reportLabelAttributeIds: undefined,
};

function convertFromFormModel(
    formModel: InferFormDataShape<typeof formConfiguration>,
    { reportId }: RoutingLabelsModalCustomProps
): Partial<ReportAttribute>[] {
    const reportAttributes = map(formModel.reportLabelAttributeIds, (attributeId) => ({
        reportId,
        attributeId,
        attributeType: AttributeTypeEnum.ROUTING_LABEL.name,
    }));

    return reportAttributes;
}

function useInitialiseRoutingLabelsForm() {
    const reportAttributesWhere = useSelector(reportAttributesWhereSelector);

    const initialiseForm = useCallback(
        (reportId: number) => {
            formsRegistry.maybeDeferredOperation<typeof formConfiguration>(
                formClientEnum.ROUTING_LABELS,
                undefined,
                (form) => {
                    const reportAttributes = reportAttributesWhere({
                        reportId,
                        attributeType: AttributeTypeEnum.ROUTING_LABEL.name,
                    });
                    const reportLabelAttributeIds = map(reportAttributes, 'attributeId');

                    form.set({ reportLabelAttributeIds });
                }
            );
        },
        [reportAttributesWhere]
    );

    return initialiseForm;
}

// Many usages of RoutingLabelsModal are not using standard button.
// OverlayButton render prop is overkill when we only need the open action.
export function useOpenRoutingLabelsModal(props: UseOpenRoutingLabelsModalProps) {
    const { overlayId, reportId } = props;
    const overlayStore = useOverlayStore<RoutingLabelsModalCustomProps>();
    const initialiseRoutingLabel = useInitialiseRoutingLabelsForm();

    const open = useCallback(() => {
        initialiseRoutingLabel(reportId);
        overlayStore.open(overlayId, { reportId });
    }, [initialiseRoutingLabel, overlayId, overlayStore, reportId]);

    return open;
}

const RoutingLabelsModal: React.FC<RoutingLabelsModalProps> = (props) => {
    const { buttonElement, closeFocusRef, overlayId } = props;
    const dispatch = useDispatch<RmsDispatch>();
    const { getForm } = useFormGetter();
    const overlayStore = useOverlayStore<RoutingLabelsModalCustomProps>();
    const onSaveHandler = useCallback(() => {
        const form = getForm(formClientEnum.ROUTING_LABELS);

        if (!form) {
            return;
        }

        return form.submit().then(({ form }) => {
            const { customProperties } = overlayStore.getStateForId(overlayId);
            const reportAttributes = convertFromFormModel(form.getState().model, customProperties);

            return dispatch(
                updateReportAttributes(
                    customProperties.reportId,
                    reportAttributes,
                    AttributeTypeEnum.ROUTING_LABEL.name
                )
            );
        });
    }, [dispatch, getForm, overlayId, overlayStore]);

    return (
        <Modal
            buttonElement={buttonElement}
            cancelFocusRef={closeFocusRef}
            contentStyle={{ height: 300 }}
            id={overlayId}
            okText={strings.okText}
            onSave={onSaveHandler}
            saveFocusRef={closeFocusRef}
            title={strings.modalTitle}
        >
            <Form
                configuration={formConfiguration}
                initialState={initialState}
                lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                name={formClientEnum.ROUTING_LABELS}
                render={() => (
                    <MFTAttributeSelect
                        attributeType={AttributeTypeEnum.ROUTING_LABEL.name}
                        fieldName={fields.REPORT_ATTRIBUTE_ATTRIBUTE_ID}
                        label={strings.formTitle}
                        path="reportLabelAttributeIds"
                        multiple={true}
                        length="lg"
                    />
                )}
            />
        </Modal>
    );
};

export default RoutingLabelsModal;

import { UserAttribute } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const userAttributesModule = createNormalizedModule<UserAttribute>({
    type: 'userAttributes',
});

// ACTIONS
export const storeUserAttributes = userAttributesModule.actionCreators.storeEntities;

// SELECTORS
export const userAttributesSelector = userAttributesModule.selectors.entitiesSelector;

// REDUCER
export default userAttributesModule.reducerConfig;

import { FileTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { createStructuredSelector } from 'reselect';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { csvExportColumnOptionsSelector } from '~/client-common/core/domain/csv-export-columns/state/ui';

import UnderSubheader from '../../../core/components/UnderSubheader';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { SectionHeader as _SectionHeader } from '../../../../legacy-redux/components/exports/FormExportsOptionHeaders';
import GrayBar from '../../../../legacy-redux/components/styling/GrayBar';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import _Radio from '../../../core/forms/components/Radio';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import { RRFCheckboxTree as _RRFCheckboxTree } from '../../../core/forms/components/checkboxes/CheckboxTree';
import {
    submitForm,
    multiRenItemListPrintableOptionsSelector,
    disablePdfDownloadSelector,
    exportsErrorMessageSelector,
} from '../state/ui';
import evidenceDashboardExportForm from '../state/forms/evidenceDashboardExportForm';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { scrollToTop } from '../../../../legacy-redux/helpers/navigationHelpers';
import testIds from '../../../../core/testIds';

const strings = componentStrings.evidence.dashboard.EvidenceDashboardExports;
const { CSV, PDF } = FileTypeEnum;
const exportTypeOptions = [
    { value: CSV.name, label: 'CSV File' },
    { value: PDF.name, label: 'PDF' },
];

const ExportSection = styled.div`
    clear: both;
    padding-top: 20px;
`;
const SectionHeader = styled(_SectionHeader)`
    margin-bottom: 15px;
`;
const Radio = styled(_Radio)`
    clear: both;

    .radio-group {
        margin-left: 0;
    }

    .radio-option.newline {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;
const RRFCheckboxTree = styled(_RRFCheckboxTree)`
    padding-left: 0;
`;

function EvidenceDashboardExports({
    exportType,
    handleChange,
    handleSubmit,
    handleCancel,
    printableOptions,
    csvExportColumnOptions,
    disablePdfDownload,
    errorMessage,
}) {
    return (
        <UnderSubheader style={{ marginTop: '30px' }}>
            {errorMessage && <InlineBanner status="error">{errorMessage}</InlineBanner>}
            <SectionHeader>{strings.exportType}</SectionHeader>
            <div data-test-id={testIds.EVIDENCE_DASHBOARD_EXPORT_TYPE}>
                <Radio
                    options={exportTypeOptions}
                    newlines={true}
                    orientation="column"
                    value={exportType}
                    onChange={handleChange}
                />
            </div>
            <GrayBar />
            <ReactReduxForm {...evidenceDashboardExportForm}>
                {exportType === CSV.name && (
                    <ExportSection>
                        <SectionHeader>{strings.includeColumns}</SectionHeader>
                        <RRFCheckboxTree
                            path="csvExportColumnIds"
                            options={csvExportColumnOptions}
                            testId={testIds.EVIDENCE_DASHBOARD_EXPORT_CSV_OPTIONS}
                        />
                    </ExportSection>
                )}
                {exportType === PDF.name && (
                    <ExportSection>
                        <RRFSelect
                            path="printableHash"
                            label={strings.labels.pdfType}
                            width={300}
                            options={printableOptions}
                            testId={testIds.EVIDENCE_DASHBOARD_EXPORT_PDF_OPTIONS}
                        />
                    </ExportSection>
                )}
            </ReactReduxForm>
            {!!exportType && (
                <ExportSection>
                    <GrayBar />
                    <Button
                        className={buttonTypes.PRIMARY}
                        onClick={handleSubmit}
                        disabled={exportType === PDF.name && disablePdfDownload}
                        testId={testIds.EVIDENCE_DASHBOARD_EXPORT_DOWNLOAD}
                    >
                        {strings.labels.download}
                    </Button>
                    <Button
                        className={buttonTypes.SECONDARY}
                        onClick={handleCancel}
                        testId={testIds.EVIDENCE_DASHBOARD_EXPORT_CANCEL}
                    >
                        {strings.labels.cancel}
                    </Button>
                </ExportSection>
            )}
        </UnderSubheader>
    );
}

const mapStateToProps = createStructuredSelector({
    printableOptions: multiRenItemListPrintableOptionsSelector,
    csvExportColumnOptions: csvExportColumnOptionsSelector,
    disablePdfDownload: disablePdfDownloadSelector,
    errorMessage: exportsErrorMessageSelector,
});
const mapDispatchToProps = (dispatch, { router }) => ({
    submitForm: (exportType) => dispatch(submitForm(exportType)),
    handleCancel: () => {
        dispatch(evidenceDashboardExportForm.actionCreators.reset());
        router.push('/evidence/dashboard');
    },
});

export default compose(
    withState('exportType', 'setExportType', null),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        handleChange: ({ setExportType }) => (event) => setExportType(event.target.value),
        handleSubmit: ({ exportType, submitForm }) => () => submitForm(exportType),
    }),
    lifecycle({
        componentDidUpdate(prevProps) {
            if (!prevProps.errorMessage && !!this.props.errorMessage) {
                scrollToTop();
            }
        },
    })
)(EvidenceDashboardExports);

import factory from './actionTypesFactory';

export default factory('renChange', [
    'OPEN_REN_CHANGE_MODAL',
    'CLEAR_REN_CHANGE_MODAL',
    'VALIDATE_REN_UPDATE_START',
    'VALIDATE_REN_UPDATE_SUCCESS',
    'VALIDATE_REN_UPDATE_FAILURE',
    'ENABLE_REN_UPDATE',
    'DISABLE_REN_UPDATE',
    'UPDATE_REN_START',
    'UPDATE_REN_SUCCESS',
    'UPDATE_REN_FAILURE',
    'STORE_HANDLE_SUBMIT',
]);

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter, find, map, some } from 'lodash';
import styled from 'styled-components';
import { Spinner } from 'arc';

import { EventInfoForCad, RefContextEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import useFields, { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';
import { isOffenseReportSelector } from '~/client-common/core/domain/report-definitions/state/data';
import getReportingEventsResource from '~/client-common/core/domain/reporting-events/resources/reportingEventsResource';
import { useResource } from '~/client-common/core/hooks/useResource';
import {
    DISPLAY_ONLY_WILL_NOT_INVESTIGATE_LABEL,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import Modal from '../../../core/overlays/components/Modal';
import OverlayButton, { buttonTypes } from '../../../core/overlays/components/OverlayButton';
import testIds from '../../../../core/testIds';
import ReasonForRemovalForm from '../../core/components/ReasonForRemovalForm';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { currentCaseIdSelector } from '../../core/state/ui';
import {
    convertFromFormModelToRequest,
    convertToFormModel,
    resetReasonForRemovalForm,
} from '../../core/state/form/reasonForRemovalForm';
import { openBox } from '../../../../legacy-redux/actions/boxActions';
import { context as createCaseModalContext } from '../../core/components/CreateCaseModal';
import { Button } from '../../../core/components/Button';
import { removeFromCase } from '../../core/state/data';
import caseResource from '../../core/resources/caseResource';
import Row from '../../../core/components/Row';
import WillNotInvestigateModal from '../../unassigned-reports/components/WillNotInvestigateModal';
import LinkedReportsFromRENModalContent from '../../core/components/LinkedReportsFromRENModalContent';
import { LinkedReportItemSelectableProps } from '../../../records/core/components/sidebar/LinkedReportItem';

const strings = componentStrings.cases.caseSummary.CaseReasonForRemovalModal;

type ReasonForRemovalModalProps = {
    ren?: string;
    recordNumber?: string;
};

const RemoveOrWillNotInvestigateButton = styled(Button)`
    margin: 11px 10px 11px 0;
    display: block;
    float: left;
`;

const ModalFooterSpinner = styled(Spinner)`
    margin-top: 10px;
`;

const ReasonForRemovalModal: React.FC = () => {
    return (
        <OverlayBaseHelper<ReasonForRemovalModalProps> id={overlayIdEnum.REASON_FOR_REMOVAL_MODAL}>
            {(renderProps) => {
                const { ren, recordNumber } = renderProps.overlayBase.overlayState.customProperties;

                return (
                    <ReasonForRemovalModalContent
                        ren={ren}
                        recordNumber={recordNumber}
                        closePanel={renderProps.closePanel}
                    />
                );
            }}
        </OverlayBaseHelper>
    );
};

const ReasonForRemovalModalContent: React.FC<{
    ren?: string;
    recordNumber?: string;
    closePanel: () => void;
}> = ({ ren, recordNumber }) => {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const { getForm } = useFormGetter();
    const form = getForm(RefContextEnum.FORM_REASON_FOR_REMOVAL_MODAL.name);
    const reports = useSelector(reportsSelector);
    const currentCaseId = useSelector(currentCaseIdSelector);
    const isOffenseReport = useSelector(isOffenseReportSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;
    const isCaseRequiredOnOIEnabled =
        applicationSettings.RMS_MANDATORY_INVESTIGATIONS_FOR_OFFENSE_INCIDENT_REPORTS;
    const dispatch = useDispatch();

    const [selectedReportIds, setSelectedReportIds] = useState<number[]>([]);
    const [isRenOnOtherCases, setIsRenOnOtherCases] = useState(true);
    const [loading, setLoading] = useState(true);

    const fieldDisplayNames = useFields([
        REPORT_REPORTING_EVENT_NUMBER,
        DISPLAY_ONLY_WILL_NOT_INVESTIGATE_LABEL,
    ]);
    const renDisplayName = fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER;
    const hasRenText = ren ? renDisplayName : 'Report';
    const willNotInvestigateLabel = fieldDisplayNames.DISPLAY_ONLY_WILL_NOT_INVESTIGATE_LABEL;

    const relevantReports = ren
        ? filter(reports, (report) => report.reportingEventNumber === ren)
        : filter(reports, (report) => report.recordNumber === recordNumber);

    const reportIds = map(relevantReports, 'id');
    const onlyReportIdIfRwr = reportIds[0];
    const hasOiReport = some(reportIds, isOffenseReport);

    const showIndividualReportSelection = individualReportSelectionEnabled && reportIds.length > 1;
    const hideRemove = isCaseRequiredOnOIEnabled ? hasOiReport && !isRenOnOtherCases : false;
    const hideWillNotInvestigate = isCaseRequiredOnOIEnabled
        ? hasOiReport || isRenOnOtherCases
        : isRenOnOtherCases;

    const getInfoForRen = React.useCallback(() => {
        setLoading(true);
        const reportingEventsResource = getReportingEventsResource();
        const trimmedReportingEventNumber = ren?.trim();
        return reportingEventsResource
            .getEventInfoForReportingEventNumber(trimmedReportingEventNumber, {
                ignoreFormatValidation: true,
            })
            .then((result: EventInfoForCad) => {
                return result?.caseTitles.length > 1;
            });
    }, [ren]);

    const onSuccess = React.useCallback((isMultipleCaseTitles) => {
        setIsRenOnOtherCases(isMultipleCaseTitles);
        setLoading(false);
    }, []);

    const getInfoForRwr = React.useCallback(() => {
        setLoading(true);
        return caseResource.getCasesForReport(onlyReportIdIfRwr).then((result) => {
            // @ts-expect-error caseResource is untyped
            return result?.caseViewModels?.length > 1;
        });
    }, [onlyReportIdIfRwr]);

    useResource(ren ? getInfoForRen : getInfoForRwr, onSuccess);

    React.useEffect(() => {
        if (form) {
            form.set(
                '',
                convertToFormModel({ reportIds, reportingEventNumber: ren, caseId: currentCaseId })
            );
        }
    }, [form, reportIds, ren, currentCaseId]);

    const onSave = React.useCallback(() => {
        if (form) {
            return form.submit().then((result) => {
                const request = convertFromFormModelToRequest(
                    result.form.getState().model || {},
                    showIndividualReportSelection ? selectedReportIds : undefined
                );
                dispatch(
                    openBox(createCaseModalContext, {
                        removalRequest: request,
                    })
                );
            });
        } else {
            return Promise.resolve();
        }
    }, [dispatch, form, showIndividualReportSelection, selectedReportIds]);

    const onRemoveClick = React.useCallback(() => {
        if (form) {
            return form.submit().then((result) => {
                const request = convertFromFormModelToRequest(
                    result.form.getState().model || {},
                    showIndividualReportSelection ? selectedReportIds : undefined
                );
                dispatch(removeFromCase(request));
            });
        }
        return undefined;
    }, [dispatch, form, showIndividualReportSelection, selectedReportIds]);

    const linkedReportItemProps: LinkedReportItemSelectableProps = {
        selectable: showIndividualReportSelection,
        selectedReportIds,
        onChange: (reportIds: number[]) => {
            setSelectedReportIds(reportIds);
        },
    };

    const isButtonDisabled = showIndividualReportSelection && selectedReportIds.length === 0;

    return (
        <Modal
            id={overlayIdEnum.REASON_FOR_REMOVAL_MODAL}
            title={strings.title}
            okText={strings.okText(caseDisplayName)}
            onCancel={resetReasonForRemovalForm}
            onSave={onSave}
            saveDisabled={isButtonDisabled}
            additionalFooterContent={
                loading ? (
                    <ModalFooterSpinner />
                ) : (
                    <>
                        {!hideRemove && (
                            <RemoveOrWillNotInvestigateButton
                                className={buttonTypes.SECONDARY}
                                id={overlayIdEnum.REASON_FOR_REMOVAL_MODAL}
                                testId={testIds.CASE_REMOVE_FROM_CASE_BUTTON}
                                disabled={isButtonDisabled}
                                onClick={onRemoveClick}
                            >
                                {strings.removeText}
                            </RemoveOrWillNotInvestigateButton>
                        )}
                        {!hideWillNotInvestigate && (
                            <WillNotInvestigateModal
                                fieldDisplayName={willNotInvestigateLabel}
                                buttonElement={
                                    <OverlayButton
                                        className={buttonTypes.SECONDARY_BOLD}
                                        id={overlayIdEnum.WILL_NOT_INVESTIGATE_MODAL}
                                    >
                                        {(openOverlay: () => void) => (
                                            <div
                                                onClick={() => {
                                                    if (form) {
                                                        return form.submit().then(() => {
                                                            openOverlay();
                                                        });
                                                    }
                                                    return undefined;
                                                }}
                                            >
                                                <RemoveOrWillNotInvestigateButton
                                                    disabled={isButtonDisabled}
                                                    testId={
                                                        testIds.CASE_WILL_NOT_INVESTIGATE_BUTTON
                                                    }
                                                >
                                                    {willNotInvestigateLabel}
                                                </RemoveOrWillNotInvestigateButton>
                                            </div>
                                        )}
                                    </OverlayButton>
                                }
                                reportIds={
                                    showIndividualReportSelection ? selectedReportIds : reportIds
                                }
                            />
                        )}
                    </>
                )
            }
        >
            <Row>
                {showIndividualReportSelection ? (
                    <p>{strings.removeSelectedReports(caseDisplayName)}</p>
                ) : (
                    <p>
                        {strings.removeBodyTextBeginning}
                        <b>{` ${hasRenText} ${ren || recordNumber} `}</b>
                        {strings.removeBodyTextEnd(caseDisplayName, hasRenText)}
                    </p>
                )}
                <br />
            </Row>
            <Row>
                <LinkedReportsFromRENModalContent
                    selectedRen={ren}
                    selectedReportId={
                        find(reports, (report) => report.recordNumber === recordNumber)?.id
                    }
                    linkedReportItemProps={linkedReportItemProps}
                />
            </Row>
            <ReasonForRemovalForm />
        </Modal>
    );
};

export default ReasonForRemovalModal;

import factory from './actionTypesFactory';

export default factory('userprofileadmin', [
    'PAGE_LOAD_START',
    'PAGE_LOAD_ERROR',
    'LOAD_USER_PROFILE_START',
    'LOAD_USER_PROFILE_SUCCESS',
    'SAVE_USER_PROFILE_START',
    'SAVE_USER_PROFILE_SUCCESS',
    'SAVE_USER_PROFILE_FAILURE',
    'SAVE_USER_ACCOUNT_SUCCESS',
    'SAVE_USER_ACCOUNT_STATUS_SUCCESS',
    'LOAD_USER_ATTRIBUTES_START',
    'LOAD_USER_ATTRIBUTES_SUCCESS',
    'SAVE_USER_ATTRIBUTES_START',
    'SAVE_USER_ATTRIBUTES_SUCCESS',
    'LOAD_USER_DUTY_STATUSES_START',
    'LOAD_USER_DUTY_STATUSES_SUCCESS',
    'SAVE_USER_DUTY_STATUSES_START',
    'SAVE_USER_DUTY_STATUSES_SUCCESS',
    'LOAD_USER_PHONES_START',
    'LOAD_USER_PHONES_SUCCESS',
    'SAVE_USER_PHONES_START',
    'SAVE_USER_PHONES_SUCCESS',
    'LOAD_USER_PROFILE_ATTACHMENTS_START',
    'LOAD_USER_PROFILE_ATTACHMENTS_SUCCESS',
    'SAVE_USER_PROFILE_ATTACHMENTS_START',
    'SAVE_USER_PROFILE_ATTACHMENTS_SUCCESS',
    'RESEND_CONFIRMATION_START',
    'RESEND_CONFIRMATION_SUCCESS',
    'RESEND_CONFIRMATION_FAILURE',
    'RESET_USER_PROFILE_FORM',
    'SET_USER_ASSIGNMENT_FORM_VALUES',
    'UNLOCK_USER_ACCOUNT_START',
    'UNLOCK_USER_ACCOUNT_SUCCESS',
    'UNLOCK_USER_ACCOUNT_FAILURE',
    'RESET_USER_PROFILE_ADMIN_STATE',
    'TOGGLE_ARC_NAVIGATION_START',
    'TOGGLE_ARC_NAVIGATION_SUCCESS',
    'TOGGLE_ARC_NAVIGATION_FAILURE',
]);

import styled from 'styled-components';

export const SectionHeader = styled.div`
    float: left;
    clear: both;
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-md);
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

import { size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { EntityTypeEnum } from '@mark43/rms-api';
import { historyEventViewModelsByEntityIdSelector } from '~/client-common/core/domain/history-events/state/ui';
import _History from '../../../core/histories/components/History';
import Divider from '../../../../legacy-redux/components/core/Divider';
import { loadEntityProfileHistory } from '../state/data/entityProfileHistory';
import { currentEntityProfileIdSelector } from '../state/ui/selectors';

const SemiBold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const History = styled(_History)`
    margin-top: 20px;
`;

class EntityProfileHistory extends React.Component {
    componentDidMount() {
        const { itemProfileId, loadProfileHistory } = this.props;
        if (itemProfileId) {
            loadProfileHistory(itemProfileId);
        }
    }

    componentDidUpdate(prevProps) {
        const { itemProfileId, loadProfileHistory } = this.props;
        if (prevProps.itemProfileId !== itemProfileId && itemProfileId) {
            loadProfileHistory(itemProfileId);
        }
    }

    render() {
        const {
            historyEventViewModelsByEntityId,
            itemProfileId,
            showFilter,
            historyType,
            customFilter,
        } = this.props;
        const historyEventViewModels =
            itemProfileId &&
            historyEventViewModelsByEntityId(itemProfileId).filter(
                (entry) => entry.primaryType !== EntityTypeEnum.CUSTODIAL_RESPONSIBLE_OFFICER.name
            );

        const content =
            !historyEventViewModels || !size(historyEventViewModels) ? (
                <SemiBold>No History Available</SemiBold>
            ) : (
                <History
                    historyEventViewModels={historyEventViewModels}
                    showFilter={showFilter}
                    historyType={historyType}
                    customFilter={customFilter}
                />
            );

        return (
            <div>
                <Divider />
                {content}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    itemProfileId: currentEntityProfileIdSelector,
    historyEventViewModelsByEntityId: historyEventViewModelsByEntityIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    loadProfileHistory: (itemProfileId) => dispatch(loadEntityProfileHistory(itemProfileId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityProfileHistory);

import React from 'react';
import { useSelector } from 'react-redux';

import { getMiniUserByIdSelector } from '../../domain/mini-users/state/data';
import { EmDash } from '../../../../modules/core/components/HtmlEntities';
import FormattedUser, { FormattedUserProps } from './FormattedUser';

type Props = {
    userId?: number;
};

const ConnectedFormattedUser: React.FC<Props & Omit<FormattedUserProps, 'user'>> = ({
    userId,
    ...props
}) => {
    const miniUserById = useSelector(getMiniUserByIdSelector);

    if (!userId) {
        return <EmDash />;
    }
    const miniUser = miniUserById(userId);
    return <FormattedUser {...props} user={miniUser} />;
};

export default ConnectedFormattedUser;

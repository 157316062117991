import { ConsortiumLinkView } from '@mark43/rms-api';
/**
 * Gets the name of a department from grouped consortium department links.
 * Each department id might have an array of links attached to it,
 * but the department name will be the same for all of the links, which is
 * why it is safe for us to get the name of the first link.
 * @param  {Object} indexedLinks A map of department links, grouped by department id.
 * `consortiumDepartmentLinksByDepartmentIdSelector` can be used to retrieve this map.
 *
 * @param   deptId       The department id whose name to retrieve
 * @return               The name of the department
 */
const getDepartmentNameFromConsortiumLinksByDepartmentId = (
    indexedLinks: { [index: number]: ConsortiumLinkView[] },
    deptId: number
): string => indexedLinks[deptId]?.[0]?.deptDisplayName ?? '';

export default getDepartmentNameFromConsortiumLinksByDepartmentId;

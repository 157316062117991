import React from 'react';
import { Flex } from 'arc';
import { AttributeTypeEnum, RoleTypeEnum } from '@mark43/rms-api';

import testIds from '../../../core/testIds';
import ArbiterForm from '../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTText } from '../../core/forms/components/Text';
import { ArbiterMFTDatePicker } from '../../core/forms/components/DatePicker';
import { ArbiterMFTRoleSelect } from '../../core/forms/components/selects/RoleSelect';
import { ArbiterMFTAttributeSelect } from '../../core/forms/components/selects/AttributeSelect';

import { briefingRoutingForm } from '../state/forms/routingOptionsForm';
import { SUPPORTED_SUBDIVISION_TYPES } from '../configuration';

const SUPPORTED_ROLES = [RoleTypeEnum.NORMAL.name, RoleTypeEnum.USER.name];

const BriefingRoutingForm: React.FC = () => {
    return (
        <ArbiterForm
            {...briefingRoutingForm}
            render={() => {
                return (
                    <Flex flexDirection="column">
                        <ArbiterMFTText path="title" testId={testIds.BRIEFING_TITLE_INPUT} />
                        <ArbiterMFTRoleSelect
                            path="roleIds"
                            multiple
                            roleTypes={SUPPORTED_ROLES}
                            testId={testIds.BRIEFING_ROLE_ROUTINGS_INPUT}
                        />
                        <ArbiterMFTAttributeSelect
                            path="subdivisionIds"
                            multiple
                            attributeType={SUPPORTED_SUBDIVISION_TYPES}
                            testId={testIds.BRIEFING_SUBDIVISION_ROUTINGS_INPUT}
                        />
                        <ArbiterMFTAttributeSelect
                            path="unitIds"
                            multiple
                            attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                            testId={testIds.BRIEFING_UNIT_ROUTINGS_INPUT}
                        />
                        <ArbiterMFTDatePicker
                            path="expiryDate"
                            includeTime={true}
                            testId={testIds.BRIEFING_EXPIRY_DATE_INPUT}
                        />
                    </Flex>
                );
            }}
        />
    );
};

export default BriefingRoutingForm;

import React, { useMemo } from 'react';
import { HStack, ButtonProps as ArcButtonProps } from 'arc';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import Button from '../../../../legacy-redux/components/core/Button';
import { Button as ArcButton } from '../../components/Button';
import testIds from '../../../../core/testIds';
import { InteractionType, convertArcButtonVariantToLegacyClass } from '../../utils/buttonHelpers';

interface SidePanelCancelButtonProps {
    cancelButtonVariant?: ArcButtonProps['variant'];
    cancelDisabled?: boolean;
    cancelText?: string; // use null to hide button
    closePanel?: (e?: InteractionType) => void;
}
interface SidePanelSaveButtonProps {
    saveButtonVariant?: ArcButtonProps['variant'];
    saveDisabled?: boolean;
    savePanel?: (e?: InteractionType) => void;
    saveText?: string | null; // use null to hide button
    saving?: boolean;
}

interface SidePanelActionButtonProps {
    actionButtonVariant?: ArcButtonProps['variant'];
    actionText?: string;
    action?: (e?: InteractionType) => void;
}

interface SidePanelFooterProps
    extends SidePanelSaveButtonProps,
        SidePanelCancelButtonProps,
        SidePanelActionButtonProps {
    renderCancelButton?: (baseCancelButton: React.ReactNode) => React.ReactNode;
    savingGifClass: string;
}
const CancelButton = ({
    cancelButtonVariant,
    cancelDisabled,
    cancelText,
    closePanel,
}: SidePanelCancelButtonProps) => {
    const legacyCancelButtonClass = useMemo(
        () => convertArcButtonVariantToLegacyClass(cancelButtonVariant),
        [cancelButtonVariant]
    );
    return (
        <Button
            className={legacyCancelButtonClass}
            onClick={closePanel}
            disabled={cancelDisabled}
            testId={testIds.SIDE_PANEL_CANCEL}
        >
            {cancelText}
        </Button>
    );
};

const SaveButton = ({
    saveButtonVariant,
    savePanel,
    saveDisabled,
    saveText,
    saving,
}: SidePanelSaveButtonProps) => {
    const legacySaveButtonClass = useMemo(() => {
        return convertArcButtonVariantToLegacyClass(saveButtonVariant);
    }, [saveButtonVariant]);
    return (
        <Button
            className={legacySaveButtonClass}
            onClick={savePanel}
            disabled={saving || saveDisabled}
            testId={testIds.SIDE_PANEL_SAVE}
        >
            {saveText}
        </Button>
    );
};

const ActionButton = ({ actionButtonVariant, action, actionText }: SidePanelActionButtonProps) => {
    const legacySaveButtonClass = useMemo(() => {
        return convertArcButtonVariantToLegacyClass(actionButtonVariant);
    }, [actionButtonVariant]);
    return (
        <Button className={legacySaveButtonClass} onClick={action} style={{ marginLeft: '124px' }}>
            {actionText}
        </Button>
    );
};

const ArcCancelButton = ({
    cancelButtonVariant,
    cancelDisabled,
    cancelText,
    closePanel,
}: SidePanelCancelButtonProps) => (
    <ArcButton
        variant={cancelButtonVariant}
        minWidth={'SIDE_PANEL_FOOTER'}
        onClick={closePanel}
        disabled={cancelDisabled}
        testId={testIds.SIDE_PANEL_CANCEL}
    >
        {cancelText}
    </ArcButton>
);

const ArcSaveButton = ({
    saveButtonVariant,
    savePanel,
    saveDisabled,
    saveText,
    saving,
}: SidePanelSaveButtonProps) => (
    <ArcButton
        variant={saveButtonVariant}
        minWidth={'SIDE_PANEL_FOOTER'}
        onClick={savePanel}
        disabled={saving || saveDisabled}
        testId={testIds.SIDE_PANEL_SAVE}
    >
        {saveText}
    </ArcButton>
);

const ArcActionButton = ({
    actionButtonVariant,
    action,
    actionText,
}: SidePanelActionButtonProps) => (
    <ArcButton
        variant={actionButtonVariant}
        minWidth={'SIDE_PANEL_FOOTER'}
        onClick={action}
        style={{ marginLeft: '124px' }}
    >
        {actionText}
    </ArcButton>
);

const SidePanelFooter = ({
    cancelButtonVariant,
    cancelDisabled,
    cancelText,
    closePanel,
    renderCancelButton,
    saveButtonVariant,
    savePanel,
    saveDisabled,
    saveText,
    saving,
    savingGifClass,
    actionButtonVariant,
    action,
    actionText,
}: SidePanelFooterProps) => (
    <div className="react-side-panel-footer">
        <FeatureFlagged
            flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
            fallback={
                <>
                    {saveText && (
                        <SaveButton
                            saveButtonVariant={saveButtonVariant}
                            savePanel={savePanel}
                            saveDisabled={saveDisabled}
                            saveText={saveText}
                            saving={saving}
                        />
                    )}
                    {cancelText &&
                        (renderCancelButton ? (
                            renderCancelButton(
                                <CancelButton
                                    cancelButtonVariant={cancelButtonVariant}
                                    cancelDisabled={cancelDisabled}
                                    cancelText={cancelText}
                                    closePanel={closePanel}
                                />
                            )
                        ) : (
                            <CancelButton
                                cancelButtonVariant={cancelButtonVariant}
                                cancelDisabled={cancelDisabled}
                                cancelText={cancelText}
                                closePanel={closePanel}
                            />
                        ))}
                    {actionText && (
                        <ActionButton
                            actionButtonVariant={actionButtonVariant}
                            action={action}
                            actionText={actionText}
                        />
                    )}
                    <div className={savingGifClass} />
                </>
            }
        >
            <HStack spacing={'12px'} height={'100%'}>
                {saveText && (
                    <ArcSaveButton
                        saveButtonVariant={saveButtonVariant}
                        savePanel={savePanel}
                        saveDisabled={saveDisabled}
                        saveText={saveText}
                        saving={saving}
                    />
                )}
                {cancelText &&
                    (renderCancelButton ? (
                        renderCancelButton(
                            <ArcCancelButton
                                cancelButtonVariant={cancelButtonVariant}
                                cancelDisabled={cancelDisabled}
                                cancelText={cancelText}
                                closePanel={closePanel}
                            />
                        )
                    ) : (
                        <ArcCancelButton
                            cancelButtonVariant={cancelButtonVariant}
                            cancelDisabled={cancelDisabled}
                            cancelText={cancelText}
                            closePanel={closePanel}
                        />
                    ))}
                {actionText && (
                    <ArcActionButton
                        actionButtonVariant={actionButtonVariant}
                        action={action}
                        actionText={actionText}
                    />
                )}
                <div className={savingGifClass} />
            </HStack>
        </FeatureFlagged>
    </div>
);

export default SidePanelFooter;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Modal from '../../../../../legacy-redux/components/core/Modal';
import { SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { submitDocumentExportingModal } from '../../state/ui';
import { createModalSelector } from '../../../../core/box/state/ui';
import DocumentExportingForm from './DocumentExportingForm';

const context = {
    name: boxEnum.DOCUMENT_EXPORTING_MODAL,
};
const strings = componentStrings.evidence.documents.DocumentExportingModal;
const modalContentStyle = { height: 360 };

/**
 * Modal for exporting an evidence document. Submitting the modal starts the
 *   export pipeline.
 */
export default connect(
    createStructuredSelector({
        loading: createModalSelector(context, 'loading'),
    }),
    (dispatch) => ({
        submitDocumentExportingModal: () => dispatch(submitDocumentExportingModal()),
    })
)(function DocumentExportingModal({ loading, submitDocumentExportingModal }) {
    return (
        <Modal
            context={context}
            title={strings.title}
            onSave={submitDocumentExportingModal}
            okText={strings.export}
            cancelText={strings.close}
            contentStyle={modalContentStyle}
        >
            {loading ? <SimpleLoading /> : <DocumentExportingForm />}
        </Modal>
    );
});

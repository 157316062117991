import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { createStructuredSelector } from 'reselect';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { createOrganizationsResultsSelector } from '~/client-common/core/domain/elastic-organizations/state/ui';
import createSearchModule from '../../../core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import advancedSearchOrganizationsForm, {
    convertAdvancedSearchOrganizationsFormModelToFilterGroups,
    convertAdvancedSearchOrganizationsElasticQueryToFormModel,
} from '../forms/advancedSearchOrganizationsForm';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../core/state/ui';
import recursivelyConvertElasticLocationQueries from '../../../core/utils/recursivelyConvertElasticLocationQueries';

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object}  boundSelectors extra selectors needed by search module
 * @return {Object}   Array of filter group view models.
 */
function convertAdvancedSearchOrganizationsElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    return convertAdvancedSearchOrganizationsFormModelToFilterGroups(
        convertAdvancedSearchOrganizationsElasticQueryToFormModel(elasticQuery),
        formatFieldValue,
        boundSelectors
    );
}

/**
 * Redux module for advanced search organizations.
 * @type {Object}
 */
export default createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.ORGANIZATION.name,
    baseUiSelector: (state) => state.ui.advancedSearch.organizations,
    form: advancedSearchOrganizationsForm,
    elasticQueryToFilterGroups: convertAdvancedSearchOrganizationsElasticQueryToFilterGroups,
    resourceMethod: elasticSearchResource.searchOrganizations,
    resultsContainerClassName: 'organizations-results-container',
    createResultsViewModelsSelector: createOrganizationsResultsSelector,
    searchResultToRoutePath: ({ id }) => `/profiles/organizations/${id}`,
    transformElasticQueryBeforeSearchSelector: (state) => (elasticQuery) => {
        const transformIds = transformElasticQueryIdsToDisplayValuesSelector(state);
        const queryWithTransformedIds = transformIds(elasticQuery);
        return recursivelyConvertElasticLocationQueries(queryWithTransformedIds, {
            legacyQueryProp: 'locations',
            newQueryProp: 'involvedLocations',
        });
    },
    selectorToBind: createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
    }),
});

import React, { useCallback, useState } from 'react';
import { flowRight, map, slice } from 'lodash';

import { Stack, Tag, TagGroup } from 'arc';
import { ElasticWarrant } from '@mark43/rms-api';
import { InjectedRouter, withRouter } from 'react-router';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { isUnknown } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';

import { useResource } from '~/client-common/core/hooks/useResource';
import SortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import SortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';

import { Button } from '../../core/components/Button';
import elasticSearchResource from '../../../legacy-redux/resources/elasticSearchResource';
import { MAX_RECENT_WARRANTS, RECENT_WARRANTS_INTERVAL } from '../configuration';
import {
    NoDataBlock,
    ShowMoreButton,
    DashboardCard,
    DashboardCardContentGrid,
    DashboardCardProps,
} from './DashboardSection';
import { DashboardRightCardsLoader } from './DashboardLoaders';
import { EntityItem } from './EntityItem';

const strings = componentStrings.personalDashboard.RecentWarrants;

const RecentWarrantItem: React.FC<{
    warrant: ElasticWarrant;
}> = ({ warrant }) => {
    const { id, subject, warrantNum, warrantIssuedDateUtc, warrantCharges } = warrant;
    const link = subject
        ? // @ts-expect-error client-common to client RND-7529
          isUnknown(subject)
            ? undefined
            : `/profiles/persons/${subject.masterPersonId}`
        : undefined;

    return (
        <EntityItem
            entity={subject}
            date={warrantIssuedDateUtc}
            entityRoute={link}
            itemLinkText={`Warrant ${warrantNum}`}
            itemRoute={`/warrants/${id}`}
            mugShotImage={subject?.primaryMugshotPath}
        >
            <TagGroup
                isTruncated
                isInteractive={false}
                size="sm"
                style={{ flexWrap: 'nowrap', alignItems: 'center' }}
            >
                {warrantCharges.length > 0 &&
                    map(warrantCharges, (charge) => (
                        <Tag leadingVisual="Charge" key={charge.id}>
                            {charge.chargeName}
                        </Tag>
                    ))}
            </TagGroup>
        </EntityItem>
    );
};

const _RecentWarrants: React.FC<{
    router: InjectedRouter;
    hideLoadingBar: boolean;
}> = ({ router, hideLoadingBar }) => {
    const [recentWarrants, setRecentWarrants] = useState<ElasticWarrant[]>([]);
    const [numWarrantsToShow, setNumWarrantsToShow] = useState<number>(RECENT_WARRANTS_INTERVAL);

    const loadRecentWarrants = useCallback(
        () =>
            elasticSearchResource.searchWarrants(
                {},
                0,
                MAX_RECENT_WARRANTS,
                SortKeyEnum.WARRANT_DATE_ISSUED_UTC,
                SortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                undefined,
                hideLoadingBar
            ),
        [hideLoadingBar]
    );

    const onResourceSuccess = useCallback(
        (warrants) => {
            setRecentWarrants(warrants.items);
        },
        [setRecentWarrants]
    );

    const loadingState = useResource(loadRecentWarrants, onResourceSuccess);

    const { isLoading, errorMessage } = loadingState;

    const showMoreWarrants = useCallback(() => {
        if (numWarrantsToShow >= MAX_RECENT_WARRANTS) {
            router.push('/warrants/dashboard');
            return;
        }
        setNumWarrantsToShow(numWarrantsToShow + RECENT_WARRANTS_INTERVAL);
    }, [router, numWarrantsToShow, setNumWarrantsToShow]);

    const recentWarrantsToShow = slice(recentWarrants, 0, numWarrantsToShow);

    if (isLoading && recentWarrants.length === 0) {
        return <DashboardRightCardsLoader />;
    } else if (errorMessage) {
        return <NoDataBlock>{strings.loadError}</NoDataBlock>;
    } else if (recentWarrants.length === 0) {
        return <NoDataBlock>{strings.noResults}</NoDataBlock>;
    } else {
        return (
            <Stack>
                <DashboardCardContentGrid gridGap="var(--arc-space-2)">
                    {map(recentWarrantsToShow, (warrant) => (
                        <RecentWarrantItem warrant={warrant} key={warrant.warrantNum} />
                    ))}
                </DashboardCardContentGrid>
                {(recentWarrantsToShow.length < recentWarrants.length ||
                    recentWarrants.length >= MAX_RECENT_WARRANTS) && (
                    <ShowMoreButton onClick={showMoreWarrants}>{strings.showMore}</ShowMoreButton>
                )}
            </Stack>
        );
    }
};

const RecentWarrantsContent = flowRight(withRouter)(_RecentWarrants);

const RecentWarrants: React.FC<DashboardCardProps> = ({
    dragHandleProps,
    isDragging,
    ...props
}) => {
    return (
        <DashboardCard
            dragHandleProps={dragHandleProps}
            isDragging={isDragging}
            actions={
                <Button variant="ghost" asLink to="/warrants/dashboard">
                    {strings.viewAll}
                </Button>
            }
            {...props}
            title={strings.title}
        >
            <RecentWarrantsContent hideLoadingBar={true} />
        </DashboardCard>
    );
};

export default RecentWarrants;

import adminDepartmentResource from '../../../../../legacy-redux/resources/adminDepartmentResource';

export const SAVE_LOGIN_BACKGROUND_START = 'case-definitions/SAVE_LOGIN_BACKGROUND_START';
export const SAVE_LOGIN_BACKGROUND_SUCCESS = 'case-definitions/SAVE_LOGIN_BACKGROUND_SUCCESS';
export const SAVE_LOGIN_BACKGROUND_FAILURE = 'case-definitions/SAVE_LOGIN_BACKGROUND_FAILURE';
export const DELETE_LOGIN_BACKGROUND_START = 'case-definitions/DELETE_LOGIN_BACKGROUND_START';
export const DELETE_LOGIN_BACKGROUND_SUCCESS = 'case-definitions/DELETE_LOGIN_BACKGROUND_SUCCESS';
export const DELETE_LOGIN_BACKGROUND_FAILURE = 'case-definitions/DELETE_LOGIN_BACKGROUND_FAILURE';
export const UPLOAD_LOGIN_BACKGROUND_START = 'case-definitions/UPLOAD_LOGIN_BACKGROUND_START';
export const UPLOAD_LOGIN_BACKGROUND_SUCCESS = 'case-definitions/UPLOAD_LOGIN_BACKGROUND_SUCCESS';
export const UPLOAD_LOGIN_BACKGROUND_FAILURE = 'case-definitions/UPLOAD_LOGIN_BACKGROUND_FAILURE';

function saveLandingBackgroundStart() {
    return {
        type: SAVE_LOGIN_BACKGROUND_START,
    };
}

function saveLandingBackgroundSuccess(savedBackgroundUrl) {
    return {
        type: SAVE_LOGIN_BACKGROUND_SUCCESS,
        payload: savedBackgroundUrl,
    };
}

function saveLandingBackgroundFailure(errMsg) {
    return {
        type: SAVE_LOGIN_BACKGROUND_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveLandingBackground(file, deptId) {
    return function (dispatch) {
        dispatch(saveLandingBackgroundStart());
        return adminDepartmentResource
            .saveLandingBackground(deptId, file.id)
            .then(() => dispatch(saveLandingBackgroundSuccess(file.fileWebServerPath)))
            .catch((err) => dispatch(saveLandingBackgroundFailure(err.message)));
    };
}

function deleteLandingBackgroundStart() {
    return {
        type: DELETE_LOGIN_BACKGROUND_START,
    };
}

function deleteLandingBackgroundSuccess() {
    return {
        type: DELETE_LOGIN_BACKGROUND_SUCCESS,
    };
}

function deleteLandingBackgroundFailure(errMsg) {
    return {
        type: DELETE_LOGIN_BACKGROUND_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function deleteLandingBackground(deptId) {
    return function (dispatch) {
        dispatch(deleteLandingBackgroundStart());
        return adminDepartmentResource
            .deleteLandingBackground(deptId)
            .then(() => dispatch(deleteLandingBackgroundSuccess()))
            .catch((err) => dispatch(deleteLandingBackgroundFailure(err.message)));
    };
}

export function uploadLandingBackgroundStart() {
    return {
        type: UPLOAD_LOGIN_BACKGROUND_START,
    };
}

export function uploadLandingBackgroundSuccess(file) {
    return {
        type: UPLOAD_LOGIN_BACKGROUND_SUCCESS,
        payload: file,
    };
}

export function uploadLandingBackgroundFailure(errMsg) {
    return {
        type: UPLOAD_LOGIN_BACKGROUND_FAILURE,
        error: true,
        payload: errMsg,
    };
}

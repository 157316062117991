import keyMirror from 'keymirror';

/**
 * Cards during warrant creation
 * @type {Object}
 */
export default keyMirror({
    WARRANT_DETAILS: null,
    WARRANT_CHARGES: null,
    ATTACHMENTS: null,
    WARRANT_DEX_SUBMISSION: null,
});

import React from 'react';
import { useSelector } from 'react-redux';
import OnlyWithAbilityBase from '~/client-common/core/domain/abilities/components/OnlyWithAbilityBase';
import { currentUserIdSelector } from '../../current-user/state/ui';

type HasProps = {
    has: number;
};

type HasOneOfProps = {
    hasOneOf: number[];
};

const OnlyWithAbility: React.FC<HasProps | HasOneOfProps> = ({ ...props }) => {
    const currentUserId = useSelector(currentUserIdSelector);
    if (!currentUserId) {
        return null;
    }
    return <OnlyWithAbilityBase userId={currentUserId} {...props} />;
};

export default OnlyWithAbility;

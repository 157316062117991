import { TrafficCrashRoadway } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'trafficCrashRoadways';
const trafficCrashRoadwayModule = createNormalizedModule<TrafficCrashRoadway>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const trafficCrashRoadwaysSelector = trafficCrashRoadwayModule.selectors.entitiesSelector;
export const trafficCrashRoadwaysWhereSelector =
    trafficCrashRoadwayModule.selectors.entitiesWhereSelector;

// REDUCER
export default trafficCrashRoadwayModule.reducerConfig;

import { createSelector } from 'reselect';
import { map } from 'lodash';

import { propertyStatusesByItemProfileIdSelector } from '~/client-common/core/domain/property-statuses/state/data';
import { deduplicatePropertyStatuses } from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';

import {
    paginatedItemProfileIdsInReportWithCoCSelector,
    paginatedItemProfileIdsInReportWithoutCoCSelector,
} from '../../../../evidence/cps-card/state/ui';

/**
 * For given item profile ID, arbitrarily yet deterministically choose one property status for
 *   consistency. This can be used in any Evidence feature that needs to associate exactly 1
 *   propertyStatus to each itemProfile regardless of how many propertyStatuses already exist. As of
 *   Nov 2018, this is used in the Custodial Property Summary report and in the Item Splitting
 *   feature.
 *
 * Use the shared algorithm of deduplicatePropertyStatuses.
 * @type {Object}
 */
export const propertyStatusForItemToUseInEvidenceSelector = createSelector(
    propertyStatusesByItemProfileIdSelector,
    (propertyStatusesByItemProfileId) => (itemProfileId) =>
        deduplicatePropertyStatuses(propertyStatusesByItemProfileId(itemProfileId))[0] || {}
);

/**
 * All property statuses in the Custodial Property Summary report, split into 2 arrays:
 *
 * propertyStatusesWithCoC - property statuses for 'submitted' item profiles, some of which are
 *   editable
 *
 * propertyStatusesWithoutCoC - property statuses for newly 'saved' item profiles
 * @type {Object}
 */
export const custodialPropertyStatusesSelector = createSelector(
    paginatedItemProfileIdsInReportWithCoCSelector,
    paginatedItemProfileIdsInReportWithoutCoCSelector,
    propertyStatusForItemToUseInEvidenceSelector,
    (itemProfileIdsWithCoC, itemProfileIdsWithoutCoC, propertyStatusForItem) => {
        const propertyStatusesWithCoC = map(itemProfileIdsWithCoC, (itemProfileId) =>
            propertyStatusForItem(itemProfileId)
        );
        const propertyStatusesWithoutCoC = map(itemProfileIdsWithoutCoC, (itemProfileId) =>
            propertyStatusForItem(itemProfileId)
        );
        return {
            propertyStatusesWithCoC,
            propertyStatusesWithoutCoC,
        };
    }
);

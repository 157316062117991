import * as React from 'react';
import { simpleControl } from 'markformythree';
import { ApiResultSearchOptionView, ElasticUserQuery } from '@mark43/rms-api';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';

import { req } from '../../../../../lib/ajax';

import { dragonSelectStateReducer } from './dragon-select-state-reducer';
import {
    useDragonSelectBase,
    DragonOption,
    UseDragonSelectBaseOptions,
    UseDragonSelectBaseResult,
} from './use-dragon-select-base';

type UseDragonUserSelectOptions = {
    uiConfigurationId: string;
    query?: string;
} & Omit<UseDragonSelectBaseOptions, 'optionData' | 'isMultiple'>;

type UseDragonUserSelectResult = UseDragonSelectBaseResult & {
    isLoading: boolean;
    error?: string;
};

function useDragonUserSelect({
    uiConfigurationId,
    onChange,
    displayPropertyId,
    valuePropertyId,
    value,
    query,
}: UseDragonUserSelectOptions): UseDragonUserSelectResult {
    const [state, dispatch] = React.useReducer(dragonSelectStateReducer, {
        isLoading: false,
        error: undefined,
        data: undefined,
    });

    React.useEffect(() => {
        let ignore = false;

        dispatch({ type: 'LOADING_START' });
        // the substring call here is a temporary workaround for the fact that this frontend receives
        // all uiConfigurationIds with a '_' prefix, which isn't valid when calling POST /dragon/search/options
        const uiConfigurationIdsWithoutPrefix = uiConfigurationId.startsWith('_')
            ? uiConfigurationId.substring(1)
            : uiConfigurationId;
        req<{
            path: 'dragon/search/options/users';
            method: 'POST';
            data: {
                elasticQuery: ElasticUserQuery;
            };
            params: {
                ui_configuration_id: string;
            };
            returns: ApiResultSearchOptionView;
        }>({
            method: 'POST',
            url: 'dragon/search/options/users',
            params: {
                ui_configuration_id: uiConfigurationIdsWithoutPrefix,
            },
            hideLoadingBar: true,
            data: {
                elasticQuery: {
                    quickSearchQuery: query,
                    dutyStatusHistories: [
                        // @ts-expect-error duty statuses somehow are required in the type
                        {
                            dateEffectiveStartBefore: nowUtc(),
                            dateEffectiveEndAfter: nowUtc(),
                        },
                    ],
                },
                sorts: [
                    {
                        sortKey: sortKeyEnum.ELASTICSEARCH_SCORE,
                        sortType: sortTypeEnum.RELEVANCE_MOST_TO_LEAST,
                    },
                    {
                        sortKey: sortKeyEnum.USER_FULL_NAME,
                        sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                    },
                ],
                from: 0,
                size: 100,
            },
        })
            .then((result) => {
                if (ignore) {
                    return;
                }
                dispatch({
                    type: 'LOADING_SUCCESS',
                    payload:
                        result.resolvedConfiguredEntityPropertyInstanceValues[
                            uiConfigurationIdsWithoutPrefix
                        ],
                });
            })
            .catch((err) => {
                if (ignore) {
                    return;
                }
                dispatch({ type: 'LOADING_FAILURE', payload: err });
            });

        return () => {
            ignore = true;
        };
    }, [uiConfigurationId, query]);

    const optionData = state.data;
    const dragonSelectBaseResult = useDragonSelectBase({
        optionData,
        displayPropertyId,
        valuePropertyId,
        onChange,
        isMultiple: false,
        value,
    });
    return {
        ...dragonSelectBaseResult,
        error: state.error?.message,
        isLoading: state.isLoading,
    };
}

type RenderOptions = UseDragonUserSelectResult & {
    setQuery: (query: string) => void;
};
type DragonUserSelectProps = {
    uiConfigurationId: string;
    displayPropertyId: number;
    valuePropertyId: number;
    children: (options: RenderOptions) => React.ReactElement;
};

function DragonUserSelectBase({
    uiConfigurationId,
    displayPropertyId,
    valuePropertyId,
    value: mftValue,
    onChange: mftOnChange,
    children,
    error: mftFieldError,
}: DragonUserSelectProps & {
    value?: DragonOption | DragonOption[];
    onChange: (val: unknown) => void;
    error?: string;
}) {
    const [query, setQuery] = React.useState('');
    const { options, onChange, value, isLoading, error } = useDragonUserSelect({
        valuePropertyId,
        displayPropertyId,
        uiConfigurationId,
        onChange: mftOnChange,
        value: mftValue,
        query,
    });

    // TODO we will most likely have to process `options` again here to ensure that `value` - if provided - is always present in `options`
    // so that it shows up properly in the select, even if no values have been searched for yet. This also means that once the value is
    // removed, the select will be empty and users have to search again
    return children({
        options,
        value,
        onChange,
        isLoading,
        error: error || mftFieldError || '',
        setQuery,
    });
}

export const DragonUserSelect = simpleControl(DragonUserSelectBase);

import React from 'react';
import { flatMap, map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import withFields from '~/client-common/core/fields/components/withFields';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import vehicleProfileMergeableFields from '../configuration/vehicleProfileMergeableFields';
import GenericPropertyList from './GenericPropertyList';

const vehicleProfilePropertyList = (props) => (
    <GenericPropertyList {...props} propertyConfiguration={vehicleProfileMergeableFields} />
);

const VehicleProfilePropertyListWithFields = withFields(() =>
    flatMap(vehicleProfileMergeableFields, ({ field, relatedFields }) => {
        const fields = [field];
        if (relatedFields) {
            fields.push(...map(relatedFields, 'field'));
        }
        return fields;
    })
)(
    connect(
        createStructuredSelector({
            formatAttributeById: formatAttributeByIdSelector,
        })
    )(vehicleProfilePropertyList)
);

export default VehicleProfilePropertyListWithFields;

import { formatSvgPath } from '../../helpers';

import EighteenWheelerTruck from '../../../../../../../../../images/crash-diagram/vehicles/18-wheeler-truck.svg';
import Ambulance from '../../../../../../../../../images/crash-diagram/vehicles/ambulance.svg';
import Bike from '../../../../../../../../../images/crash-diagram/vehicles/bike.svg';
import Compact from '../../../../../../../../../images/crash-diagram/vehicles/compact.svg';
import Convertible from '../../../../../../../../../images/crash-diagram/vehicles/convertible.svg';
import FireTruck from '../../../../../../../../../images/crash-diagram/vehicles/fire-truck.svg';
import Motorcycle from '../../../../../../../../../images/crash-diagram/vehicles/motocycle.svg';
import PoliceCar from '../../../../../../../../../images/crash-diagram/vehicles/police-car.svg';
import SemiTruckToCargo from '../../../../../../../../../images/crash-diagram/vehicles/semi-truck-2-cargo.svg';
import SemiTruck from '../../../../../../../../../images/crash-diagram/vehicles/semi-truck.svg';
import Sudan from '../../../../../../../../../images/crash-diagram/vehicles/sudan.svg';
import SUV from '../../../../../../../../../images/crash-diagram/vehicles/suv.svg';
import Taxi from '../../../../../../../../../images/crash-diagram/vehicles/taxi.svg';
import Truck from '../../../../../../../../../images/crash-diagram/vehicles/truck.svg';

export const vehicles = [
    EighteenWheelerTruck,
    Ambulance,
    Bike,
    Compact,
    Convertible,
    FireTruck,
    Motorcycle,
    PoliceCar,
    SemiTruckToCargo,
    SemiTruck,
    Sudan,
    SUV,
    Taxi,
    Truck,
].map((svgPath) => formatSvgPath(svgPath));

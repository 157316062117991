import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, pure, withHandlers } from 'recompose';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { ownerNameItemAttributeIdsSelector } from '~/client-common/core/domain/name-item-links/state/data';

import advancedSearchVehicles from '../state/ui';
import FormSection from '../../../core/forms/components/FormSection';
import AdvancedSearchForm from '../../core/components/AdvancedSearchForm';
import IdentifiersFieldset from '../../core/components/IdentifiersFieldset';
import { OrganizationFieldset } from '../../core/components/OrganizationsFieldset';
import PersonDetailsFieldset from '../../core/components/PersonDetailsFieldset';
import { VehicleFieldset } from '../../core/components/VehiclesFieldset';
import { SearchName } from '../../core/components/SearchName';

const strings = componentStrings.search.AdvancedSearchVehiclesForm;

const VehicleSection = pure(function VehicleSection(props) {
    return (
        <FormSection>
            <VehicleFieldset highlightOnFocus={false} {...props} />
        </FormSection>
    );
});

const OwnerSection = pure(function OwnerSection({ fuzzyMatchingEnabled }) {
    return (
        <FormSection title={strings.sectionTitles.owner} collapsible={true}>
            {(collapsed) => (
                <div>
                    <PersonDetailsFieldset
                        collapsed={collapsed}
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    />
                    <IdentifiersFieldset
                        visible={!collapsed}
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                        showHeader={false}
                    />
                    <OrganizationFieldset
                        visible={!collapsed}
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                        includeIndustryAttrId={true}
                        includeCrossStreets={true}
                        showHeader={true}
                        className="light"
                    />
                </div>
            )}
        </FormSection>
    );
});

function AdvancedSearchVehiclesForm({
    fuzzyMatchingEnabled,
    formIsEmpty,
    onSubmit,
    onReset,
    currentSavedSearch,
}) {
    return (
        <AdvancedSearchForm
            {...advancedSearchVehicles.form}
            includeFuzzyMatchingCheckbox={true}
            fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            onSubmit={onSubmit}
            onReset={onReset}
            formIsEmpty={formIsEmpty}
            searchText={strings.search}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={true} />
            <VehicleSection
                includePropertyStatusAttrIds={true}
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            />
            <hr />
            <OwnerSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
        </AdvancedSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    fuzzyMatchingEnabled: advancedSearchVehicles.form.selectors.buildFormModelSelectorByPath(
        'fuzzyMatchingEnabled'
    ),
    formIsEmpty: advancedSearchVehicles.form.selectors.formIsEmptySelector,
    ownerNameItemAttributeIds: ownerNameItemAttributeIdsSelector,
    currentSavedSearch: advancedSearchVehicles.selectors.currentSavedSearchSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (additionalDataForConvertFromFormModel) =>
        dispatch(
            advancedSearchVehicles.form.actionCreators.submit((formData) =>
                dispatch(
                    advancedSearchVehicles.actionCreators.search(
                        {
                            formData,
                            additionalDataForConvertFromFormModel,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                )
            )
        ),
    onReset: () => {
        dispatch(advancedSearchVehicles.form.actionCreators.reset());
        dispatch(advancedSearchVehicles.actionCreators.resetState());
        dispatch(advancedSearchVehicles.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(advancedSearchVehicles.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onSubmit: ({ onSubmit, ownerNameItemAttributeIds }) => () =>
            onSubmit({ ownerNameItemAttributeIds }),
    })
)(AdvancedSearchVehiclesForm);

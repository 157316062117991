import { flowRight, get, isFunction } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { boxUiSelector } from '../../selectors/boxSelectors';

import { saveBoxStart, closeBox } from '../../actions/boxActions';
import { renderOnlyIf } from '../../helpers/reactHelpers';
import { createOverlayCustomProperties } from '../../../modules/core/utils/createOverlayCustomProperties';
import ModalBase from '../../../modules/core/overlays/components/ModalBase';

/**
 * DEPRECATED: please use modules/core/overlays/components/Modal.tsx.
 * Legacy modal component. Wraps `ModalBase` and transforms
 * props.
 */
function Modal(props) {
    const contextProps = props.ui[props.context.name];
    const onRequestClose = props.closeModal.bind(this, false);
    const onCancel = props.onCancelOnly
        ? () => {
              props.onCancelOnly();
              props.closeModal.bind(this, true);
          }
        : props.closeModal.bind(this, true);
    return (
        <ModalBase
            {...props}
            {...contextProps}
            onRequestClose={onRequestClose}
            onCancel={onCancel}
            testId={props.context.name}
        />
    );
}

Modal.propTypes = {
    ...ModalBase.propTypes,
    onClose: PropTypes.func,
    onCancelOnly: PropTypes.func,
    onSave: PropTypes.func,
};

const legacyFocusCloseRef = (props) => {
    const refToFocus =
        get(props, 'onCloseRef.current.menu.current') || get(props, 'onCloseRef.current');
    if (refToFocus && isFunction(refToFocus.focus)) {
        refToFocus.focus();
    }
};

const mapDispatchToProps = (dispatch, props) => ({
    closeModal: (isCancel) => {
        if (isCancel && props.onCancelOnly) {
            props.onCancelOnly();
        } else if (props.onClose) {
            props.onClose();
        } else {
            // default close action
            dispatch(closeBox(props.context));
        }
        legacyFocusCloseRef(props);
    },
    saveModal: () => {
        if (props.onSave) {
            dispatch(saveBoxStart(props.context));
            props.onSave();
        } else {
            // default close action
            dispatch(closeBox(props.context));
        }
        legacyFocusCloseRef(props);
    },
});

const mapStateToProps = createStructuredSelector({
    ui: boxUiSelector,
});

/**
 * @type React.FC<{
 *     title?: string;
 *     context: { name: string };
 *     okText?: string;
 *     cancelText?: string;
 *     onSave?: () => void;
 *     onClose?: () => void;
 *     shouldCloseOnOverlayClick?: boolean;
 *     hideFooter?: boolean;
 *     contentStyle?: object;
 *     children: React.ReactNode;
 * }>
 */
const LegacyModal = flowRight(
    connect(mapStateToProps, mapDispatchToProps),
    renderOnlyIf(({ context, ui }) => ui[context.name].isOpen)
)(Modal);

export default LegacyModal;

/**
 * Wrapper component around our modal base which facilitates prop
 * transformation for easy integration with `OverlayBase` and shims
 * legacy behavior for closing, saving etc.
 */
export class PortalModal extends React.Component {
    handleCloseBase(isCancel) {
        if (isCancel && this.props.onCancelOnly) {
            this.props.onCancelOnly();
        } else if (this.props.onClose) {
            this.props.onClose();
        } else {
            // default close action
            this.props.close();
        }
        this.focusCloseRef();
    }

    handleCancel = () => this.handleCloseBase(true);

    handleClose = () => this.handleCloseBase(false);

    handleSave = () => {
        if (this.props.onSave) {
            this.props.overlayStore.setCustomProperties(
                this.props.id,
                createOverlayCustomProperties({ isSaving: true })
            );
            this.props.onSave();
        } else {
            // default close action
            this.props.close();
        }
        this.focusCloseRef();
    };

    focusCloseRef = () => {
        const refToFocus =
            get(this.props, 'onCloseRef.current.menu.current') ||
            get(this.props, 'onCloseRef.current');
        if (refToFocus && isFunction(refToFocus.focus)) {
            refToFocus.focus();
        }
    };

    render() {
        const { overlayState, id, ...rest } = this.props;
        const {
            isLoading: loading,
            isOpen,
            errors: errorMessages,
            customProperties,
        } = overlayState;
        const { isSaving: saving } = customProperties;

        return isOpen ? (
            <ModalBase
                {...rest}
                saving={saving}
                loading={loading}
                isOpen={isOpen}
                errorMessages={errorMessages}
                saveModal={this.handleSave}
                onRequestClose={this.handleClose}
                onCancel={this.handleCancel}
                testId={id}
            />
        ) : null;
    }
}

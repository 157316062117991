import { ApiCoreModelConfiguration } from '@mark43/rms-api';
import createNormalizedModule from '~/client-common/core/utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'coreModelConfigurations';

const coreModelConfigurationModule = createNormalizedModule<ApiCoreModelConfiguration>({
    type: NEXUS_STATE_PROP,
    key: 'name',
});

export const coreModelConfigurationsSelector =
    coreModelConfigurationModule.selectors.entitiesSelector;

export default coreModelConfigurationModule.reducerConfig;

import { EntityTypeEnum, LinkTypesEnum, AttributeTypeEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@arc/layout';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import strings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { itemAttributesWhereSelector } from '~/client-common/core/domain/item-attributes/state/data';
import { itemEvidenceStateByMasterItemIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import { vehicleByIdSelector } from '~/client-common/core/domain/vehicles/state/data';
import { cautionsByEntitySelector } from '~/client-common/core/domain/cautions/state/data';

import AttachmentsSidePanel from '../../../attachments/core/components/AttachmentsSidePanel';
import { initializeAttachmentsSidePanelForm } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import { VehicleEnhancedCautions } from '../../../core/cautions/components/VehicleEnhancedCautions';
import Header from '../../core/components/header/EntityProfileHeader';
import { useActiveTargetCases } from '../../core/components/EntityProfileActiveTarget/ActiveTargetCasesContext';
import HeaderMasterName from '../../core/components/header/EntityProfileHeaderMasterName';
import HeaderSecondaryInfoSection from '../../core/components/header/EntityProfileHeaderSecondaryInfoSection';
import HeaderSecondaryInfo from '../../core/components/header/EntityProfileHeaderSecondaryInfo';
import HeaderMenu from '../../core/components/header/EntityProfileHeaderMenu';
import { EmDash } from '../../../core/components/HtmlEntities';
import { VehicleLabels } from '../../../core/components/tags/VehicleLabels';
import ExternalDepartmentNameSecondaryHeaderInfo from '../../core/components/header/ExternalDepartmentSecondaryHeaderInfo';
import { currentEntityProfileIdSelector, vehicleHeaderDataSelector } from '../../core/state/ui';

import HighRiskPropertyLabel from '../../property/components/HighRiskPropertyLabel';

const headerStrings = strings.entityProfiles.EntityProfileVehicleHeader;

const StyledHighRiskPropertyLabel = styled(HighRiskPropertyLabel)`
    margin-top: var(--arc-space-1);
`;

const VehicleLabelsWrapper = styled.div`
    margin: 10px 0;
`;

function EntityProfileVehicleHeader({ ownerDepartmentName }) {
    const vehicleHeaderData = useSelector(vehicleHeaderDataSelector);
    const entityId = useSelector(currentEntityProfileIdSelector);
    const itemEvidenceStateByMasterItemId = useSelector(itemEvidenceStateByMasterItemIdSelector);
    const itemAttributesWhere = useSelector(itemAttributesWhereSelector);
    const vehicleById = useSelector(vehicleByIdSelector);
    const cautionsByEntity = useSelector(cautionsByEntitySelector);
    const dispatch = useDispatch();

    const attachmentEntityLinkDetails = {
        entityId,
        entityType: EntityTypeEnum.ITEM_PROFILE.name,
        imageLinkType: LinkTypesEnum.ITEM_PROFILE_PHOTO,
        attachmentLinkType: LinkTypesEnum.ITEM_PROFILE_ATTACHMENT,
    };

    const itemEvidenceState = itemEvidenceStateByMasterItemId(entityId);
    const vehicleLabelItemAttributes = itemAttributesWhere({
        itemProfileId: entityId,
        attributeType: AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name,
    });
    const cautions = cautionsByEntity(EntityTypeEnum.VEHICLE.name, entityId);
    const vehicle = vehicleById(entityId);

    const { isActiveTarget } = useActiveTargetCases();

    return (
        <Header>
            <HeaderMenu
                editProfileSidePanel={(renderEditButton) => (
                    <AttachmentsSidePanel
                        getInitialCustomPropertyState={() => attachmentEntityLinkDetails}
                        overlayId={overlayIdEnum.ENTITY_PROFILE_HEADER_ATTACHMENTS_SIDE_PANEL}
                        renderButton={({ overlayBase: { open }, setCloseFocusRefs }) =>
                            renderEditButton({
                                open: () => {
                                    dispatch(
                                        initializeAttachmentsSidePanelForm(
                                            attachmentEntityLinkDetails
                                        )
                                    );
                                    open();
                                },
                                setCloseFocusRefs,
                            })
                        }
                    />
                )}
                entityName={vehicleHeaderData.title}
            />
            <HeaderMasterName>{vehicleHeaderData.title}</HeaderMasterName>
            <HeaderSecondaryInfoSection>
                <HeaderSecondaryInfo label={headerStrings.color}>
                    {vehicleHeaderData.color || <EmDash />}
                </HeaderSecondaryInfo>
                <HeaderSecondaryInfo label={headerStrings.tag}>
                    {vehicleHeaderData.tag || <EmDash />}
                </HeaderSecondaryInfo>
                <HeaderSecondaryInfo label={headerStrings.state}>
                    {vehicleHeaderData.state || <EmDash />}
                </HeaderSecondaryInfo>
                <ExternalDepartmentNameSecondaryHeaderInfo
                    ownerDepartmentName={ownerDepartmentName}
                />
            </HeaderSecondaryInfoSection>

            {(itemEvidenceState?.isHighValue || itemEvidenceState?.isInHighValueContainer) && (
                <Box mt={1}>
                    <StyledHighRiskPropertyLabel />
                </Box>
            )}

            <FeatureFlagged
                flag="RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED"
                fallback={
                    <FeatureFlagged flag="RMS_VEHICLE_CAUTIONS_ENABLED">
                        {vehicle && (
                            <VehicleLabelsWrapper>
                                <VehicleLabels
                                    itemAttributes={vehicleLabelItemAttributes}
                                    isStolen={vehicle.isStolen}
                                    isActiveTarget={isActiveTarget}
                                    size="md"
                                />
                            </VehicleLabelsWrapper>
                        )}
                    </FeatureFlagged>
                }
            >
                {vehicle && (
                    <VehicleLabelsWrapper>
                        <VehicleEnhancedCautions
                            cautions={cautions}
                            entityId={entityId}
                            isStolen={vehicle.isStolen}
                            isActiveTarget={isActiveTarget}
                            showInactiveIndicator
                            showTooltips
                        />
                    </VehicleLabelsWrapper>
                )}
            </FeatureFlagged>
        </Header>
    );
}

export default EntityProfileVehicleHeader;

import React from 'react';
import { useSelector } from 'react-redux';

import { useResource } from '~/client-common/core/hooks/useResource';
import reportsResource from '../resources/reportsResource';
import { currentReportRENSelector } from '../../../../legacy-redux/selectors/reportSelectors';

export default function useCanCreateStubOffense(): boolean {
    const [canCreateStubOffense, setCanCreateStubOffense] = React.useState(true);
    const reportingEventNumber = useSelector(currentReportRENSelector);

    const getCanCreateStubOffense = React.useCallback(() => {
        if (reportingEventNumber) {
            return reportsResource.canCreateStubOffense(reportingEventNumber);
        } else {
            return Promise.resolve(true);
        }
    }, [reportingEventNumber]);
    const onSuccess = React.useCallback(
        (result: boolean) => {
            setCanCreateStubOffense(result);
        },
        [setCanCreateStubOffense]
    );
    useResource(getCanCreateStubOffense, onSuccess);

    return canCreateStubOffense;
}

import { AttributeView, GeoCaution, SubPremiseDto } from '@mark43/rms-api';
import {
    _Form,
    createField,
    createFormConfiguration,
    createNItems,
    InferFormDataShape,
} from 'markformythree';
import { transformGeocautionToSubPremiseDtos } from '../state/utils';

export const GEO_CAUTION_FORM_NAME = 'GEO_CAUTION';

export const formConfiguration = createFormConfiguration({
    category: createField<number>({}),
    locationCautionAttrId: createField<number>({}),
    description: createField<string>({}),
    isCustomRadius: createField<boolean>({}),
    radius: createField<string>({}),
    excludeFromNearby: createField<boolean>({}),
    startDateUtc: createField<string>({}),
    endDateUtc: createField<string>({}),
    subPremise: createField<string>({}),
    urlTitle: createField<string>({}),
    url: createField<string>({}),
    latitude: createField<number>({}),
    longitude: createField<number>({}),
    subPremiseDtos: createNItems({
        fields: {
            subPremiseName: createField<string>({}),
            subPremiseValue: createField<string>({}),
        },
    }),
});

export type GeoCautionAdminFormConfiguration = typeof formConfiguration;
export type GeoCautionAdminMFTForm = _Form<typeof formConfiguration>;
export type GeoCautionAdminMFTFormDataShape = InferFormDataShape<typeof formConfiguration>;

export const initialState = {
    category: undefined,
    locationCautionAttrId: undefined,
    description: undefined,
    isCustomRadius: undefined,
    radius: undefined,
    excludeFromNearby: undefined,
    startDateUtc: undefined,
    endDateUtc: undefined,
    subPremise: undefined,
    urlTitle: undefined,
    url: undefined,
    subPremiseDtos: [{ subPremiseName: '', subPremiseValue: '' }],
    latitude: undefined,
    longitude: undefined,
};

export function convertFromFormModel(
    formModel: GeoCautionAdminMFTFormDataShape = {}
): Partial<GeoCaution> {
    const formModelWithoutCategory = { ...formModel };
    const { radius, subPremise } = formModelWithoutCategory;
    // category isnt really being stored so no need to pass to BE
    delete formModelWithoutCategory.category;

    return {
        ...formModelWithoutCategory,
        radius: radius ? parseInt(radius) : undefined,
        subPremise: subPremise?.toUpperCase(),
    };
}

export function setSubPremiseDtosOnFormFromSubPremiseName(
    form: GeoCautionAdminMFTForm,
    subPremiseName: string
) {
    const dtos: SubPremiseDto[] = [];
    const subPremisePairs = subPremiseName.split(',').map((s) => s.trim());

    subPremisePairs.forEach((pair) => {
        const [subPremiseName, subPremiseValue] = pair.split(' ');
        if (subPremiseName || subPremiseValue) {
            dtos.push({
                subPremiseName,
                subPremiseValue,
            });
        }
    });

    form.set('subPremiseDtos', dtos);
}

export function convertToFormModel(
    geoCaution: GeoCaution,
    locationCautionAttributes: AttributeView[],
    locationCautionsCategoryAtrributes: AttributeView[]
) {
    const {
        locationCautionAttrId,
        description,
        radius,
        startDateUtc,
        endDateUtc,
        excludeFromNearby,
        subPremise,
        url,
        urlTitle,
        latitude,
        longitude,
    } = geoCaution;

    const model: GeoCautionAdminMFTFormDataShape = { ...initialState };
    const selectedLocationCautionAttr = locationCautionAttributes.find(
        (attribute) => attribute.id === locationCautionAttrId
    );
    if (selectedLocationCautionAttr) {
        const locationCautionCategory = locationCautionsCategoryAtrributes.find(
            (cat) => cat.id === selectedLocationCautionAttr.parentId
        );
        model.locationCautionAttrId = selectedLocationCautionAttr.id;
        if (locationCautionCategory) {
            model.category = locationCautionCategory.id;
        }
        model.description = description || '';

        if (!!radius) {
            model.isCustomRadius = true;
            // all inputs are strings
            model.radius = radius.toString();
        }
        model.excludeFromNearby = excludeFromNearby;

        if (startDateUtc) {
            model.startDateUtc = startDateUtc;
        }
        if (endDateUtc) {
            model.endDateUtc = endDateUtc;
        }
        if (subPremise) {
            model.subPremise = subPremise;
        }
        if (url) {
            model.url = url;
        }
        if (urlTitle) {
            model.urlTitle = urlTitle;
        }

        model.latitude = latitude;
        model.longitude = longitude;

        model.subPremiseDtos = transformGeocautionToSubPremiseDtos(model);

        if (model.subPremiseDtos.length === 0) {
            model.subPremiseDtos.push({ subPremiseName: '', subPremiseValue: '' });
        }
    }
    return model;
}

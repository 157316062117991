import { keys, forEach, isFunction } from 'lodash';

export const cards = {};

if (process.env.NODE_ENV !== 'production') {
    window.cardsRegistry = cards;
}

export const registerCard = ({ cardModule, index, onSave }) => {
    const anchor = cardModule.anchorForIndex(index);

    const editAll = isFunction(cardModule.actionCreators.editAll)
        ? ({ reportId }) => cardModule.actionCreators.editAll({ reportId })
        : undefined;

    cards[anchor] = {
        anchor,
        transitionToEditMode: () => cardModule.actionCreators.transitionToEditMode({ index }),
        scrollToTop: () => cardModule.scrollToTop({ index }),
        onSave,
        edit: ({ reportId, index }) => cardModule.actionCreators.edit({ reportId, index }),
        editAll,
        name: cardModule.name,
        index,
    };

    return () => unregisterCard({ cardModule, index });
};

const unregisterCard = ({ cardModule, index }) => {
    const anchor = cardModule.anchorForIndex(index);
    delete cards[anchor];
};

export const unregisterAllCards = () => forEach(keys(cards), (anchor) => delete cards[anchor]);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, lifecycleOptions } from 'markformythree';
import { HStack } from 'arc';
import styled from 'styled-components';
import { ElasticPerson, SearchResultElasticPerson } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_PERSON_SUGGESTION_POPOVER_SEARCH_PLACEHOLDER } from '~/client-common/core/enums/universal/fields';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { MFTText as _MFTText } from '../../../../core/forms/components/Text';
import { MFTDatePicker as _MFTDatePicker, ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { personSearchToAddSuggestionPopoverFormConfig } from '../../../../search/search-to-add/state/forms/personSearchToAddForm';
import {
    personSearchToAddSearch,
    SearchToAddHandler,
} from '../../../../search/search-to-add/state/ui';

const strings = componentStrings.reports.EntityPrefillPopover;

const MFTText = styled(_MFTText)`
    width: 233px;
    margin: 0;
`;

const MFTDatePicker = styled(_MFTDatePicker)`
    width: 117px;
    margin: 0;
`;

export type SearchResults = {
    persons: ElasticPerson[];
    totalCount: number;
};

type PersonSearchFormProps = {
    personSearchToAddSearch: SearchToAddHandler<SearchResultElasticPerson>;
    setSearchResults: React.Dispatch<React.SetStateAction<SearchResults | undefined>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const PersonSearchForm: React.FC<PersonSearchFormProps> = ({
    personSearchToAddSearch,
    setSearchResults,
    setIsLoading,
}) => {
    const fieldDisplayNames = useFields([DISPLAY_ONLY_PERSON_SUGGESTION_POPOVER_SEARCH_PLACEHOLDER]);

    const [dobPlaceholder, setDobPlaceholder] = useState<string>(strings.dobPlaceholder);
    const onDobFocus = () => {
        setDobPlaceholder(strings.dobPlaceholderFocused);
    };

    const executePersonSearch = () => {
        setIsLoading(true);
        personSearchToAddSearch({
            onSuccess: (results) => {
                if (
                    // @ts-expect-error client-common to client RND-7529
                    results.query.elasticQuery.quickSearchQuery ||
                    // @ts-expect-error client-common to client RND-7529
                    results.query.elasticQuery.dateOfBirthQuickSearchQuery
                ) {
                    setSearchResults({
                        persons: results.items,
                        totalCount: results.totalCount,
                    });
                } else {
                    setSearchResults(undefined);
                }
                setIsLoading(false);
            },
            onError: () => {
                setIsLoading(false);
            },
        });
    };

    return (
        <Form
            name={formClientEnum.PERSON_SEARCH_TO_ADD_FORM}
            configuration={personSearchToAddSuggestionPopoverFormConfig}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            render={() => {
                return (
                    <HStack>
                        <MFTText
                            path="quickSearchQuery"
                            placeholder={
                                fieldDisplayNames.DISPLAY_ONLY_PERSON_SUGGESTION_POPOVER_SEARCH_PLACEHOLDER
                            }
                            leftIcon="Search"
                            style={{ marginTop: 0 }}
                            onPressEnter={executePersonSearch}
                        />
                        <MFTDatePicker
                            path="dateOfBirthQuickSearchQuery"
                            variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                            datePlaceholder={dobPlaceholder}
                            onFocus={onDobFocus}
                            style={{ marginTop: 0 }}
                            onChange={executePersonSearch}
                            hasCalendar={false}
                        />
                    </HStack>
                );
            }}
        />
    );
};

export default connect(null, { personSearchToAddSearch })(PersonSearchForm);

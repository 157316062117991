import { find, map } from 'lodash';
import { createSelector } from 'reselect';
import { dojSchoolsSelector } from '../data';
import { formatDojSchoolDisplayValue } from '../../utils/dojSchoolsHelpers';

export const dojSchoolsOptionsSelector = createSelector(dojSchoolsSelector, (dojSchools) => {
    return map(dojSchools, (dojSchool) => {
        const { id } = dojSchool;
        return {
            display: formatDojSchoolDisplayValue(dojSchool),
            value: id,
        };
    });
});

export const formatDojSchoolByIdSelector = createSelector(
    dojSchoolsSelector,
    (dojSchools) => (id: number | undefined) => {
        const dojSchool = find(dojSchools, { id });
        if (dojSchool) {
            return formatDojSchoolDisplayValue(dojSchool);
        }
        return undefined;
    }
);

import React from 'react';
import styled from 'styled-components';
import { Text, cssVar } from 'arc';

const NoResultsTextContainer = styled(Text)<{ width: string }>`
    display: inline-block;
    border: 1px solid ${cssVar('arc.colors.border.default')};
    border-radius: 1px;
    color: ${cssVar('arc.colors.text.tertiary')};
    padding: 6px 10px;
    width: ${(props) => props.width};
`;

const NoResultsContainer = styled.div<{ marginTop: string; marginBottom: string }>`
    text-align: center;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
`;

export const NoResults: React.FC<{ marginTop?: string; width?: string; marginBottom?: string }> = ({
    children,
    marginTop = '30px',
    width = 'auto',
    marginBottom = '0',
}) => (
    <NoResultsContainer marginTop={marginTop} marginBottom={marginBottom}>
        <NoResultsTextContainer width={width}>{children}</NoResultsTextContainer>
    </NoResultsContainer>
);

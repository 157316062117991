import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'reasonForRejection',
        validators: {
            requiredError: requiredString,
        },
    },
]);

// @ts-expect-error client-common to client RND-7529
const reportRejectionForm = createFormModule({
    formName: formClientEnum.REPORT_REJECTION,
    fieldViewModels,
});

/**
 * Module of the report rejection text field form
 *
 */
export default reportRejectionForm;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import { Button } from '../../../core/components/Button';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { openCreateCaseModal as _openCreateCaseModal } from '../state/ui';
import testIds from '../../../../core/testIds';

import CasesHeader from './CasesHeader';

const strings = componentStrings.cases.core.CasesContainer;
const headerStrings = componentStrings.cases.core.CasesHeader;

export default function CasesDashboardContainer({ children }) {
    const dispatch = useDispatch();
    const openCreateCaseModal = () => dispatch(_openCreateCaseModal());

    const { pluralCaseFieldName, singularCaseFieldName } = useCaseFieldName();
    return (
        <Page className="cases-container dashboard-subheader-container">
            <Subheader
                title={strings.title(pluralCaseFieldName)}
                content={<CasesHeader />}
                hasFlushContent
            >
                <OnlyWithAbility has={abilitiesEnum.CASES.CREATE_CASE_FROM_DASHBOARD}>
                    <Button
                        isTextTransformNone
                        leadingVisual="Add"
                        variant="solid"
                        onClick={openCreateCaseModal}
                        testId={testIds.NEW_CASE_BUTTON}
                    >
                        {headerStrings.newCase(singularCaseFieldName)}
                    </Button>
                </OnlyWithAbility>
            </Subheader>
            {children}
        </Page>
    );
}

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, InjectedRouter } from 'react-router';
import styled from 'styled-components';
import { map, includes, noop } from 'lodash';
import {
    cssVar,
    HStack,
    Divider,
    Text as ArcText,
    Drawer,
    DrawerContent,
    DrawerBody,
    DrawerHeader,
    DrawerFooter,
    useBreakpointValue,
    Popover,
    PopoverTrigger,
    PopoverContent,
    NavItemProps,
} from 'arc';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { handleNavToggle } from '../../../core/components/Navigation/components';

import { Button } from '../../../core/components/Button';
import NoDataBlock from '../../../core/components/NoDataBlock';
import { iconTypes } from '../../../core/components/Icon';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import _Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';

import { closeBox as _closeBox } from '../../../../legacy-redux/actions/boxActions';
import { InlineBanner } from '../../../core/components/InlineBanner';
import ItemInfo from '../../core/components/ItemInfo';
import { horizVertCenter } from '../../../core/styles/mixins';
import {
    itemQueueViewModelByMasterItemIdSelector,
    itemQueueMasterItemIdsSelector,
    loadingItemQueueSelector,
    itemQueueErrorMessageSelector,
    removeFromItemQueue as _removeFromItemQueue,
    resetItemQueue,
    loadingEvidenceItemsSelector,
    loadingItemIdsSelector,
    openItemQueuePopover as _openItemQueuePopover,
    closeItemQueuePopover,
    itemQueuePopoverIsOpenSelector,
    itemQueuePopoverTimedCloseSelector,
    disableItemQueuePopoverTimedClose,
} from '../state/ui';
import { ITEM_INFO_PADDING } from '../../core/configuration';
import testIds from '../../../../core/testIds';
import { RmsDispatch } from '../../../../core/typings/redux';

const strings = componentStrings.evidence.itemQueue.ItemQueuePopover;
const popoverContext = { name: boxEnum.ITEM_QUEUE_POPOVER };

const StyledPopoverContent = styled(PopoverContent)`
    min-width: 21rem;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
    justify-content: space-between;
    padding-right: var(--arc-space-12);
    min-height: 4rem;
    align-items: center;
    display: flex;
`;

const StyledDrawerBody = styled(DrawerBody)`
    padding: 0;
`;

const Header = styled(HStack)`
    justify-content: space-between;
    padding: ${cssVar('arc.space.2')} ${cssVar('arc.space.3')};
`;
const Footer = styled(HStack)`
    justify-content: end;
    padding: ${cssVar('arc.space.2')} ${cssVar('arc.space.3')};
`;

const Content = styled.div`
    overflow-y: auto;
    max-height: 24rem;
    min-height: 10rem;
`;

const NoContent = styled.div`
    padding: ${cssVar('arc.space.6')};
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
`;

const ItemQueueItem = styled.div`
    position: relative;
    padding: ${ITEM_INFO_PADDING}px;
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    font-size: ${cssVar('arc.fontSizes.sm')};
    display: flex;

    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

const ItemLoadingSpinner = styled(SimpleLoading)`
    background: rgba(255, 255, 255, 0.5);
    ${horizVertCenter};

    .loading-whitebg {
        width: 25px;
        height: 25px;
    }
`;
const QueueLoadingSpinner = styled(SimpleLoading)`
    ${horizVertCenter};
`;
type ItemQueueItemInfoProps = {
    masterItemId: number;
    width: number;
    showHighRiskLabel: boolean;
    onClick?: () => void;
};

const ItemQueueItemInfo = ({ masterItemId, ...props }: ItemQueueItemInfoProps) => {
    const viewModelByMasterItemId = useSelector(itemQueueViewModelByMasterItemIdSelector);
    const viewModel = viewModelByMasterItemId(masterItemId) || {};

    // @ts-expect-error client-common to client RND-7529
    return <ItemInfo viewModel={viewModel} reportId={viewModel.reportId} {...props} />;
};

const StyledTrashButton = styled(_Button)`
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
`;

const TrashButton = ({ masterItemId }: { masterItemId: number }) => {
    const dispatch = useDispatch<RmsDispatch>();
    const removeFromItemQueue = () => dispatch(_removeFromItemQueue(masterItemId));

    return (
        <StyledTrashButton
            className={buttonTypes.ICON_LINK}
            iconLeft={iconTypes.TRASH_CAN}
            onClick={removeFromItemQueue}
        />
    );
};

type RenderButtonProps = Partial<Pick<NavItemProps, 'onClick' | 'ref' | 'isActive'>>;

type UpdatedItemQueuePopoverContent = {
    router: InjectedRouter;
    renderButton: (props: RenderButtonProps) => JSX.Element;
};
function UpdatedItemQueuePopoverContent({ router, renderButton }: UpdatedItemQueuePopoverContent) {
    const timeoutId = React.useRef<number | null>(null);
    const itemQueuePopoverTimedClose = useSelector(itemQueuePopoverTimedCloseSelector);
    const itemQueuePopoverIsOpen = useSelector(itemQueuePopoverIsOpenSelector);
    const masterItemIds = useSelector(itemQueueMasterItemIdsSelector);
    const isLoadingQueue = useSelector(loadingItemQueueSelector);
    const errorMessage = useSelector(itemQueueErrorMessageSelector);
    const loadingEvidenceItems = useSelector(loadingEvidenceItemsSelector);
    const loadingItemIds = useSelector(loadingItemIdsSelector);

    const dispatch = useDispatch<RmsDispatch>();
    const closeBox = () => dispatch(_closeBox(popoverContext));
    const removeAll = () => dispatch(resetItemQueue());
    const openItemQueuePopover = () => dispatch(_openItemQueuePopover());
    const closePopover = () => dispatch(closeItemQueuePopover());
    const disableTimedClose = () => dispatch(disableItemQueuePopoverTimedClose());

    // Using internal state in the mobile version, we don't want the drawer sliding up and back down
    // when evidence items are added to the basket.
    const [isOpen, setIsOpen] = React.useState(false);

    const closeDrawer = () => {
        setIsOpen(false);
    };
    const popoverTriggerRef = React.useRef(null);
    const isLoading = isLoadingQueue || loadingEvidenceItems;
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });

    // Reusable content pieces to be used in rendering the drawer and popover
    const headerContent = (
        <>
            <ArcText variant="headingSm">{strings.title(masterItemIds.length)}</ArcText>
            <Button
                size="sm"
                isTextTransformNone
                variant="ghost"
                onClick={removeAll}
                testId={testIds.EVIDENCE_BASKET_CLEAR_ALL}
            >
                {strings.labels.clearQueue}
            </Button>
        </>
    );

    const footerContent = !isLoading && (
        <Button
            isTextTransformNone
            variant="solid"
            autoFocus
            aria-label={strings.labels.viewQueue}
            onClick={() => {
                router?.push('/evidence/item-queue');
                closeBox();
                closeDrawer();
            }}
            testId={testIds.VIEW_ITEM_QUEUE}
        >
            {strings.labels.viewQueue}
        </Button>
    );

    const content = (
        // Using an existing testId, when RMS_ARC_NAVIGATION_ENABLED is removed this can be updated.
        <Content data-test-id={testIds.MODAL_CONTENT}>
            {errorMessage ? (
                <InlineBanner status="error">{errorMessage}</InlineBanner>
            ) : masterItemIds.length === 0 && !isLoading ? (
                <NoContent>
                    <NoDataBlock testId={testIds.EVIDENCE_BASKET_NO_ITEMS}>
                        {strings.labels.noItems}
                    </NoDataBlock>
                </NoContent>
            ) : isLoading ? (
                <QueueLoadingSpinner />
            ) : (
                map(masterItemIds, (masterItemId) => {
                    return (
                        <ItemQueueItem key={masterItemId}>
                            <ItemQueueItemInfo
                                onClick={closeDrawer}
                                masterItemId={masterItemId}
                                width={274}
                                showHighRiskLabel={true}
                            />
                            <TrashButton masterItemId={masterItemId} />
                            {includes(loadingItemIds, masterItemId) && <ItemLoadingSpinner />}
                        </ItemQueueItem>
                    );
                })
            )}
        </Content>
    );

    if (isMobile) {
        return (
            <>
                {renderButton({ onClick: () => setIsOpen((prev) => !prev) })}
                <Drawer isOpen={isMobile ? isOpen : !!itemQueuePopoverIsOpen} onClose={closeDrawer}>
                    <DrawerContent>
                        <StyledDrawerHeader>{headerContent}</StyledDrawerHeader>
                        <StyledDrawerBody>{content}</StyledDrawerBody>
                        <DrawerFooter>{footerContent}</DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </>
        );
    } else {
        return (
            <Popover isOpen={!!itemQueuePopoverIsOpen}>
                <PopoverTrigger asChild>
                    {renderButton({
                        onClick: itemQueuePopoverIsOpen ? closePopover : openItemQueuePopover,
                        ref: popoverTriggerRef,
                    })}
                </PopoverTrigger>
                <StyledPopoverContent
                    // This sets a timer so the mouse has to hover a little bit before it disables the timed close.
                    // Otherwise, as it renders technically it receives a brief mouseHover and disables the time close.
                    onMouseEnter={() => {
                        timeoutId.current = setTimeout(
                            () => (itemQueuePopoverTimedClose ? disableTimedClose() : noop),
                            200
                        );
                    }}
                    onMouseLeave={() => {
                        if (timeoutId.current !== null) {
                            clearTimeout(timeoutId.current);
                        }
                    }}
                    onEscapeKeyDown={closePopover}
                    onInteractOutside={(e) => handleNavToggle(e, popoverTriggerRef, closePopover)}
                    hasPadding={false}
                    side="right"
                    align="end"
                    data-test-id={testIds.ITEM_QUEUE_POPOVER}
                >
                    <Header>{headerContent}</Header>
                    <Divider />
                    {content}
                    <Divider />
                    <Footer>{footerContent}</Footer>
                </StyledPopoverContent>
            </Popover>
        );
    }
}

/**
 * Popover to display items in item queue and a link to the Item Queue page.
 *   Open by clicking nav item for Item Queue.
 *   Also, opened automagically when items added from evidence dashboard.
 *   Popover is positioned to the right of the Item Queue nav item.
 */
export default withRouter(UpdatedItemQueuePopoverContent);

/* eslint react/no-string-refs: "off"*/
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import keyMirror from 'keymirror';
import ReactDatePicker from 'react-calendar/dist/entry.nostyle';
import { simpleControl } from 'markformythree';
import { useSelector } from 'react-redux';

import { DateInput, parseISODate } from 'arc';
import styled from 'styled-components';
import { CountryCodeEnum } from '@mark43/rms-api';
import { normalizeTimeString, dateIsValid } from '~/client-common/core/dates/utils/dateHelpers';
import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { currentUserDepartmentCountryCodeSelector } from '~/client-common/core/domain/current-user/state/ui';
import {
    timeFormat,
    timePlaceholder,
    dateDefaultWidth,
    timeDefaultWidth,
    datePickerTotalWidth,
    dateTimePickerTotalWidth,
} from '../../../../legacy-redux/configs/formsConfig';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { arbiterMFTInput } from '../../arbiter';
import testIds from '../../../../core/testIds';
import ReactHotKeysWrapper from '../../hotkeys/components/ReactHotKeysWrapper';
import {
    useAcceptedDateFormats,
    useDateTimeFormats,
} from '../../current-user/hooks/dateTimeFormats';
import Text from './Text';
import FormElement from './FormElement';

const VARIANTS = keyMirror({
    LOCAL_DATE: null,
    DATE_TIME: null,
    DATE: null,
});

const LOCAL_DATE_FORMAT = 'YYYY-MM-DD';

const { connectRRFInput } = reactReduxFormHelpers;
// these functions are static conversions to assist us
// when going from the date and time text fields to
// the backing iso string and vice versa

const isoToDateAndTime = (isoString, dateFormat) => {
    // if isoString is falsey, just return nothing
    if (!isoString) {
        return {
            date: '',
            time: '',
        };
    }
    // otherwise we convert through moment!
    const date = moment(isoString, moment.ISO_8601);
    return {
        date: date.format(dateFormat),
        time: date.format(timeFormat),
    };
};

const resolveDateTimeStrings = ({
    dateString,
    timeString,
    dateBeingUpdated,
    timeVisible,
    variant,
    dateFormat,
    acceptedDateFormats,
}) => {
    const normalizedTimeString = normalizeTimeString(timeString);
    const useIsoString = variant !== VARIANTS.LOCAL_DATE;
    if ((!dateString && !normalizedTimeString) || (!dateString && dateBeingUpdated)) {
        // if neither is set, field is null
        return {
            resolvedValue: null,
            date: '',
            time: '',
        };
    } else if (!dateString) {
        // this means time string is set, but date string is not
        // so by default, we are setting the date to today
        const timeMoment = moment(normalizedTimeString, timeFormat);
        const dateMoment = moment(); // now

        // now we copy the time over to the date moment
        dateMoment.millisecond(0); // never do milliseconds
        dateMoment.second(timeMoment.second());
        dateMoment.minute(timeMoment.minute());
        dateMoment.hour(timeMoment.hour());
        return {
            resolvedValue: useIsoString
                ? dateMoment.toISOString()
                : dateMoment.format(LOCAL_DATE_FORMAT),
            date: dateMoment.format(dateFormat),
            time: dateMoment.format(timeFormat),
        };
    } else if (!normalizedTimeString && timeVisible) {
        // time is not set, but date is set, so we just
        // use 00:00:00 as the time
        // first we parse the date, using all possible formats we think
        // they would ever use
        const justDateMoment = moment(dateString, acceptedDateFormats, /* strict */ true);

        if (!justDateMoment.isValid()) {
            return {
                resolvedValue: null,
                date: '',
                time: '',
            };
        }
        // just to be safe
        justDateMoment.millisecond(0);
        justDateMoment.second(0);
        justDateMoment.minute(0);
        justDateMoment.hour(0);
        return {
            resolvedValue: useIsoString
                ? justDateMoment.toISOString()
                : justDateMoment.format(LOCAL_DATE_FORMAT),
            date: justDateMoment.format(dateFormat),
            time: justDateMoment.format(timeFormat),
        };
    } else {
        // okay they have both filled out, time to rock that
        const timeMoment = moment(normalizedTimeString, timeFormat);
        const dateMoment = moment(dateString, acceptedDateFormats, /* strict */ true);
        // now we copy the time over to the date moment
        dateMoment.millisecond(0); // never do milliseconds
        // if the time field isn't even visible, best put 00:00:00 so we don't use old time values
        // that may have come from imports, etc when in reality we want day resolution
        dateMoment.second(timeVisible ? timeMoment.second() : 0);
        dateMoment.minute(timeVisible ? timeMoment.minute() : 0);
        dateMoment.hour(timeVisible ? timeMoment.hour() : 0);
        if (!dateMoment.isValid()) {
            return {
                resolvedValue: null,
                date: '',
                time: '',
            };
        }
        return {
            resolvedValue: useIsoString
                ? dateMoment.toISOString()
                : dateMoment.format(LOCAL_DATE_FORMAT),
            date: dateMoment.format(dateFormat),
            time: dateMoment.format(timeFormat),
        };
    }
};

// the default time filled in when the user clicks on a date
const DEFAULT_TIME = '00:00';

const { FILL_CURRENT_DATE_TIME, FILL_YESTERDAY_DATE } = hotkeysActionEnum;

const StyledFormElement = styled(FormElement)`
    width: min-content;
`;

// the date value string could have time or not, which calls a different helper from arc/date-time
const convertToArcDateValue = (dateValue, includeTime) => {
    if (dateValue) {
        if (includeTime) {
            return parseISODate(dateValue, 'absolute');
        } else {
            const formatDate = dateValue.split('T');
            return parseISODate(formatDate[0], 'date');
        }
    } else {
        return undefined;
    }
};

/*
Mark43 Date Picker
    Note: this is a component that has state...
        we need this because whether or not a datepicker
        is open is something that doesn't need to be in
        global state, and would become impossibly
        difficult to manage. So this small bit of state
        is stored locally, and is carefully tested and trusted
        to be legit.
 */
class DatePickerComponent extends React.Component {
    constructor(props) {
        super(props);
        // set the initial vals of the date picker
        const dateTime = isoToDateAndTime(this.props.value, this.props.dateFormat);
        this.state = {
            pickerOpen: false,
            ignoreBlur: false,
            isFocused: false,
            dateTextValue: dateTime.date,
            timeTextValue: dateTime.time,
            oldPropValue: this.props.value,
            dateValue: this.props.value,
            hasCalendar: this.props.hasCalendar ?? true,
        };
        this.setDateValue = this.setDateValue.bind(this);
        this.onDatePickerChange = this.onDatePickerChange.bind(this);
        this.onArcDatePickerChange = this.onArcDatePickerChange.bind(this);
        this.dateInput = React.createRef();
    }

    convertDateValueToDisplayValue(date, acceptedDateFormats) {
        return date && this.props.customDisplayFormat
            ? moment(date, acceptedDateFormats, /* strict */ true).format(
                  this.props.customDisplayFormat
              )
            : date;
    }

    reset() {
        this.setState({
            dateTextValue: '',
            dateTextDisplayValue: '',
            timeTextValue: '',
            pickerOpen: false,
        });
        this.props.onChange('');
    }

    onChange(value) {
        if (dateIsValid(value)) {
            return this.props.onChange(value);
        } else {
            this.reset();
        }
    }

    onDatePickerChange(dateString, timeString) {
        const { resolvedValue, date, time } = resolveDateTimeStrings({
            dateString,
            timeString: timeString || this.state.timeTextValue,
            dateBeingUpdated: false,
            timeVisible: this.props.includeTime,
            variant: this.props.variant,
            dateFormat: this.props.dateFormat,
            acceptedDateFormats: this.props.acceptedDateFormats,
        });
        let timeOverride;
        if (this.props.defaultTimeToNow && (time === DEFAULT_TIME || !time)) {
            timeOverride = moment().format(timeFormat);
        }

        if (dateIsValid(resolvedValue)) {
            this.setState({
                dateTextValue: date,
                dateTextDisplayValue: this.convertDateValueToDisplayValue(
                    date,
                    this.props.acceptedDateFormats
                ),
                timeTextValue: timeOverride || time,
                pickerOpen: false,
                dateValue: convertToArcDateValue(resolvedValue, this.props.includeTime),
            });
            this.props.onChange(resolvedValue);
        } else {
            this.reset();
        }
    }

    setDateValue(newDateValue) {
        this.setState({
            dateValue: newDateValue,
        });
    }

    onArcDatePickerChange(newDateValue) {
        let isoString;
        if (newDateValue) {
            isoString = newDateValue.hasOwnProperty('timeZone')
                ? newDateValue.toAbsoluteString()
                : new Date(newDateValue.toString()).toISOString();
        }
        this.props.onChange(isoString);
    }

    onArcBlur() {
        this.props.onBlur(this.props.value); // call custom handler
        if (!dateIsValid(this.props.value)) {
            this.reset();
        }
    }

    onDateTextChange(val) {
        this.setState({
            dateTextValue: val,
            // in order to not break the functionality of a user
            // editing the date on their own, we do not convert the date
            // to a potential custom format here
            dateTextDisplayValue: val,
        });
    }

    // we don't worry about updating the backing value until they blur away!
    onTimeTextChange(val) {
        this.setState({
            timeTextValue: val,
        });
    }

    openPicker() {
        this.props.onFocus(this.props.value); // call custom handler
        this.setState({
            pickerOpen: true,
            isFocused: true,
        });
    }
    onDateBlur() {
        this.props.onBlur(this.props.value); // call custom handler
        if (this.state.ignoreBlur) {
            // this case means the user has clicked on the
            // date picker widget itself
            this.setState({
                ignoreBlur: false,
            });
            this.dateInput.current.focus();
        } else {
            // this state means we should handle our
            // on change callback, and assume the user is
            // done filling out the date field for now
            const finalDate = this.props.convertDateInputValue
                ? this.props.convertDateInputValue(this.state.dateTextValue)
                : this.state.dateTextValue;

            const { resolvedValue, date, time } = resolveDateTimeStrings({
                dateString: finalDate,
                timeString: this.state.timeTextValue,
                dateBeingUpdated: true,
                timeVisible: this.props.includeTime,
                variant: this.props.variant,
                dateFormat: this.props.dateFormat,
                acceptedDateFormats: this.props.acceptedDateFormats,
            });
            if (dateIsValid(resolvedValue)) {
                this.setState({
                    dateTextValue: date,
                    dateTextDisplayValue: this.convertDateValueToDisplayValue(
                        date,
                        this.props.acceptedDateFormats
                    ),
                    timeTextValue: time,
                    pickerOpen: false,
                });
                this.props.onChange(resolvedValue);
            } else {
                this.reset();
            }
        }
    }

    handleKeyPress(event) {
        event.preventDefault();
        this.setState({
            pickerOpen: false,
        });
        if (!this.state.dateTextValue && !this.props.value && !this.props.disabled) {
            this.onDatePickerChange(moment());
        }
    }

    onTimeBlur() {
        // this state means we should handle our
        // on change callback, and assume the user is
        // done filling out the date field for now
        const { resolvedValue, date, time } = resolveDateTimeStrings({
            dateString: this.state.dateTextValue,
            timeString: this.state.timeTextValue,
            dateBeingUpdated: false,
            timeVisible: this.props.includeTime,
            variant: this.props.variant,
            dateFormat: this.props.dateFormat,
            acceptedDateFormats: this.props.acceptedDateFormats,
        });
        this.setState({
            dateTextValue: date,
            timeTextValue: time,
        });
        this.props.onChange(resolvedValue);
    }

    userClickedDatePicker() {
        if (this.state.isFocused) {
            this.setState({
                ignoreBlur: true,
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value !== prevState.oldPropValue) {
            const { date: dateTextValue, time: timeTextValue } = isoToDateAndTime(
                nextProps.value,
                nextProps.dateFormat
            );
            return {
                dateTextValue,
                timeTextValue,
                dateTextDisplayValue: null,
                oldPropValue: nextProps.value,
            };
        } else {
            return null; // Triggers no change in the state
        }
    }

    handleDateTimePrefill(dateText, timeText) {
        this.setState({
            pickerOpen: false,
        });
        this.onDateTextChange(dateText);
        this.onTimeTextChange(timeText);
    }

    handleFillCurrentDateTime = () => {
        const currentDateTime = moment();
        this.handleDateTimePrefill(
            currentDateTime.format(this.props.dateFormat),
            currentDateTime.format(timeFormat)
        );
    };

    handleFillYesterdayDate = () => {
        const yesterdayDateTime = moment().subtract(1, 'd');
        this.handleDateTimePrefill(yesterdayDateTime.format(this.props.dateFormat), DEFAULT_TIME);
    };

    hotKeysConfig = {
        [FILL_CURRENT_DATE_TIME.name]: {
            handler: this.handleFillCurrentDateTime,
        },
        [FILL_YESTERDAY_DATE.name]: {
            handler: this.handleFillYesterdayDate,
        },
    };

    render() {
        let date = null;
        let arcDate = null;
        if (this.props.value) {
            // when DatePicker is called in with a pre-existing date
            // a vanilla JS Date may highlight the wrong date on the calendar
            // due to timezone conversion, this uses moment to address that
            date = moment(this.props.value).toDate();
            arcDate = convertToArcDateValue(this.props.value, this.props.includeTime);
        } else {
            date = new Date();
        }

        const picker = this.state.pickerOpen ? (
            <div
                onMouseDown={this.userClickedDatePicker.bind(this)}
                className="date-picker-wrapper"
                style={this.props.pickerStyle}
            >
                <ReactDatePicker
                    value={date}
                    locale="en"
                    calendarType={this.props.calendarType}
                    maxDetail={this.props.pickerView}
                    onChange={this.onDatePickerChange}
                    showFixedNumberOfWeeks={true}
                    minDate={this.props.minDate}
                />
            </div>
        ) : null;

        const time = this.props.includeTime ? (
            <Text
                error={this.props.error}
                touched={this.props.touched}
                width={timeDefaultWidth}
                placeholder={timePlaceholder}
                value={this.state.timeTextValue}
                onBlur={this.onTimeBlur.bind(this)}
                onChange={this.onTimeTextChange.bind(this)}
                textInputOnly={true}
                disabled={this.props.disabled}
                testId={testIds.DATE_PICKER_TIME_INPUT}
                textAlign="left"
            />
        ) : null;

        const maxWidth = this.props.includeTime ? dateTimePickerTotalWidth : datePickerTotalWidth;

        return (
            <ReactHotKeysWrapper hotKeysConfig={this.hotKeysConfig}>
                <FeatureFlagged
                    flag="RMS_ARC_DATEPICKER_ENABLED"
                    fallback={
                        <FormElement style={{ maxWidth }} {...this.props}>
                            <Text
                                isRequired={this.props.isRequired}
                                setRef={(input) => (this.dateInput.current = input)}
                                error={this.props.error}
                                touched={this.props.touched}
                                icon={<Icon type={iconTypes.CALENDAR} />}
                                width={this.props.dateWidth || dateDefaultWidth}
                                value={this.state.dateTextDisplayValue || this.state.dateTextValue}
                                placeholder={this.props.datePlaceholder || this.props.dateFormat}
                                onChange={this.onDateTextChange.bind(this)}
                                onFocus={this.openPicker.bind(this)}
                                onBlur={this.onDateBlur.bind(this)}
                                textInputOnly={true}
                                disabled={this.props.disabled}
                                fieldName={this.props.fieldName}
                                onPressEnter={this.handleKeyPress.bind(this)}
                                testId={testIds.DATE_PICKER_DATE_INPUT}
                                autoFocus={this.props.autoFocus}
                                textAlign="left"
                            />
                            {time}
                            {this.state.hasCalendar && picker}
                        </FormElement>
                    }
                >
                    <StyledFormElement
                        data-test-id={testIds.FORM_ELEMENT_ARC_DATEPICKER}
                        {...this.props}
                    >
                        <DateInput
                            value={arcDate}
                            hourCycle={24}
                            onChange={this.onArcDatePickerChange.bind(this)}
                            onBlur={this.onArcBlur.bind(this)}
                            granularity={this.props.includeTime ? 'minute' : this.props.granularity}
                            autoFocus={this.props.autoFocus}
                            data-test-id={testIds.DATE_PICKER_DATE_INPUT}
                            popoverContentProps={{ style: { zIndex: 2500 } }}
                            hasCalendar={this.state.hasCalendar}
                            hideTimeZone
                            isDisabled={this.props.disabled}
                        />
                    </StyledFormElement>
                </FeatureFlagged>
            </ReactHotKeysWrapper>
        );
    }
}

/*
    Mark43 Date Picker wrapper
    created to add posibility to use hooks without class to functional refactoring
 */
export default function DatePicker(props) {
    const currentUserDepartmentCountryCode = useSelector(currentUserDepartmentCountryCodeSelector);
    const calendarType =
        currentUserDepartmentCountryCode === CountryCodeEnum.US.name ? 'US' : 'ISO 8601';
    const dateFormat = useDateTimeFormats().formDate;
    const acceptedDateFormats = useAcceptedDateFormats();
    return (
        <DatePickerComponent
            {...props}
            dateFormat={dateFormat}
            acceptedDateFormats={acceptedDateFormats}
            calendarType={calendarType}
        />
    );
}

DatePicker.propTypes = {
    value: PropTypes.string,
    touched: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    includeTime: PropTypes.bool,
    defaultTimeToNow: PropTypes.bool,
    dateWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasCalendar: PropTypes.bool,
};

DatePicker.defaultProps = {
    onFocus: _.noop,
    onBlur: _.noop,
};

DatePickerComponent.displayName = 'DatePicker';

DatePickerComponent.variants = VARIANTS;
DatePicker.variants = VARIANTS;

export const RRFDatePicker = connectRRFInput(DatePicker);
export const ArbiterMFTDatePicker = arbiterMFTInput(DatePicker);
export const MFTDatePicker = simpleControl(DatePicker);

RRFDatePicker.variants = VARIANTS;
ArbiterMFTDatePicker.variants = VARIANTS;
MFTDatePicker.variants = VARIANTS;

import { reducer } from 'redux-form-mark43';
import { failedCommandsFormReducer, schedulesFormReducer } from './armadaReducers';
import { entityPermissionsFormReducer } from './entityPermissionsReducers';
import {
    attributesAdminFormReducer,
    attributesAdminCodeLinkFormReducer,
} from './attributesAdminReducers';
import { departmentIPsAdminFormReducer } from './departmentIPsAdminReducers';
import { departmentProfileAdminFormReducer } from './departmentProfileAdminReducers';
import { userProfileAdminFormReducer } from './userProfileAdminReducers';
import {
    customReportsAdminFormReducer,
    customReportsAdminDownloadablesFormReducer,
    customReportsAdminCardLinksFormReducer,
} from './customReportsAdminReducers';
import { renChangeFormReducer } from './renChangeReducers';
import { recordNumberChangeFormReducer } from './recordNumberChangeReducers';

function createDefaultReducer() {
    return (state) => {
        return {
            ...state,
        };
    };
}

export default reducer.plugin({
    failedArmadaCommandsForm: failedCommandsFormReducer,
    schedulesForm: schedulesFormReducer,
    entityPermissions: entityPermissionsFormReducer,
    attributesAdmin: attributesAdminFormReducer,
    attributesAdminCodeLink: attributesAdminCodeLinkFormReducer,
    departmentIPs: departmentIPsAdminFormReducer,
    forgotPasswordForm: createDefaultReducer(),
    confirmUserForm: createDefaultReducer(),
    resetPasswordForm: createDefaultReducer(),
    departmentProfileAdmin: departmentProfileAdminFormReducer,
    customReportsAdmin: customReportsAdminFormReducer,
    customReportsAdminDownloadables: customReportsAdminDownloadablesFormReducer,
    customReportsAdminCardLinks: customReportsAdminCardLinksFormReducer,
    userProfileAdmin: userProfileAdminFormReducer,
    renChange: renChangeFormReducer,
    recordNumberChange: recordNumberChangeFormReducer,
    advancedSearchSavedSearches: createDefaultReducer(),
});

import { normalize } from '~/client-common/helpers/dataHelpers';
import {
    userRolesMappedByUserId,
    deepMergeUserRolesWithUserMap,
    deepRemoveUserRolesWithUserMap,
} from '~/client-common/helpers/rolesHelpers';
import rolesActionTypes from '../actions/types/rolesActionTypes';
import authActionTypes from '../actions/types/authActionTypes';

export function userRolesDataReducer(state = {}, action) {
    switch (action.type) {
        case authActionTypes.BOOTSTRAP_SUCCESS:
            const { userRolesForUser } = action.payload.userData;
            const { userId } = action.payload.userData.userProfile;

            return {
                ...state,
                [userId]: normalize(userRolesForUser, 'roleId'),
            };
        case rolesActionTypes.LOAD_USER_ROLES_SUCCESS:
        case rolesActionTypes.SAVE_USER_ROLES_SUCCESS:
            return {
                ...state,
                ...userRolesMappedByUserId(action.payload),
            };
        case rolesActionTypes.ADD_USER_ROLES_SUCCESS:
            return deepMergeUserRolesWithUserMap(state, userRolesMappedByUserId(action.payload));
        case rolesActionTypes.DELETE_USER_ROLES_SUCCESS:
            return deepRemoveUserRolesWithUserMap(state, userRolesMappedByUserId(action.payload));
        case rolesActionTypes.SAVE_USER_ROLE_SUCCESS:
            return {
                ...state,
                [action.payload.userId]: {
                    ...state[action.payload.userId],
                    [action.payload.roleId]: action.payload,
                },
            };
        default:
            return state;
    }
}

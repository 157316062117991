export default {
    aggravatedAssault: 'REQUIRES_AGG_ASSAULT_CIRC',
    canIncludeCargoTheft: 'CAN_INCLUDE_CARGO_THEFT',
    canIncludeGangInfo: 'CAN_INCLUDE_GANG_INFO',
    canIncludeLeoka: 'CAN_INCLUDE_LEOKA',
    homicide: 'REQUIRES_HOMICIDE_CIRC',
    justifiableCircumstance: 'IS_JUSTIFIABLE_HOMICIDE',
    negligentManslaughter: 'REQUIRES_NEG_MANSLAUGHTER_CIRC',
    requiresAnimalCruelty: 'REQUIRES_ANIMAL_CRUELTY_CATEGORY',
    requiresCriminalActivity: 'REQUIRES_CRIMINAL_ACTIVITY',
    requiresInjury: 'REQUIRES_INJURY',
    requiresProperty: 'REQUIRES_PROPERTY',
    weaponForceInvolved: 'REQUIRES_WEAPON_OR_FORCE',
} as const;

import { useCallback, useMemo } from 'react';
import { isArray, keyBy } from 'lodash';
import { ElasticCase, ElasticCobaltSortKeyEnum, ElasticCobaltSortTypeEnum, SearchQuery, SqlSort } from '@mark43/rms-api';
import { DataTableConfig, DataTableProps } from '@arc/data-table/dist/types';
import { SortingState, Updater } from '@tanstack/table-core';
import createDataTableSortColumns from '../../../../search/core/utils/createDataTableSortColumns';
import { columnKeys } from './constants';

const sorts = [
    {
        id: columnKeys.createdDateUtc,
        sortKey: ElasticCobaltSortKeyEnum.CASE_CREATED_DATE_UTC.name,
        sortDirections: {
            asc: ElasticCobaltSortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT.name,
            desc: ElasticCobaltSortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT.name,
        },
    },
];

export const defaultSorts = {
    sortKey:  ElasticCobaltSortKeyEnum.CASE_CREATED_DATE_UTC.name,
    sortType: ElasticCobaltSortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT.name,
};

const columnSorts = createDataTableSortColumns<ElasticCase>()({
    columns: keyBy(sorts, 'id'),
});

type Sort = Pick<SearchQuery, 'sortKey' | 'sortType'>;

export const useCasesSorts = (
    sort: Sort,
    updateSort: (sort: Sort) => void
): Pick<DataTableProps<ElasticCase>, 'onColumnSortChange'> & Pick<DataTableConfig, 'sort'> => {
    const columnSort = useMemo(() => {
        const columnSort = columnSorts.getColumnSort([sort as SqlSort]);
        return columnSort ? [columnSort] : undefined;
    }, [sort]);

    const handleColumnSortChange = useCallback(
        (updaterOrValue: Updater<SortingState>) => {
            if (!isArray(updaterOrValue)) {
                return;
            }
            const columnSort = updaterOrValue[0];
            const sqlSort = columnSort ? columnSorts.getSqlSort(columnSort) : null;

            if (sqlSort) {
                updateSort(sqlSort as Sort);
            }
        },
        [updateSort]
    );

    return {
        sort: columnSort,
        onColumnSortChange: handleColumnSortChange,
    };
};

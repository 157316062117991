import { get, map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useEvidenceModuleName } from '~/client-common/core/fields/hooks/useFields';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../core/components/Icon';
import _DispositionStatus from '../../core/components/DispositionStatus';
import { EmDash } from '../../../core/components/HtmlEntities';
import ItemInfo from '../../core/components/ItemInfo';
import _NoDataBlock from '../../../core/components/NoDataBlock';
import { currentUserHasAbilitySelector as currentUserHasAbility } from '../../../core/current-user/state/ui';
import { ITEM_QUEUE_TABLE_CELL_PADDING, ITEM_QUEUE_TABLE_ITEM_INFO_WIDTH } from '../configuration';

import {
    removeFromItemQueue,
    itemQueueItemsWithStaffRemarksCountSelector as itemsWithStaffRemarksCount,
    itemQueueViewModelsSelector as viewModels,
} from '../state/ui';

import testIds from '../../../../core/testIds';
import StaffRemarksNotice from './StaffRemarksNotice';
import _StaffRemarksRow from './StaffRemarksRow';

const STRINGS = componentStrings.evidence.itemQueue;
const { EVIDENCE } = abilitiesEnum;

const ITEM_QUEUE_TABLE_ITEM_STORAGE_LOCATION_WIDTH = 170;
const ITEM_QUEUE_TABLE_ITEM_STATUS_WIDTH = 136;
const ITEM_QUEUE_TABLE_ITEM_ACTION_WIDTH = 40;

const Header = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.regular};
    padding-left: ${ITEM_QUEUE_TABLE_CELL_PADDING}px;
    padding-right: ${ITEM_QUEUE_TABLE_CELL_PADDING}px;
    text-align: left;
    text-transform: uppercase;
    width: ${(props) => `${props.width}px`};
`;

const Cell = styled.div`
    font-size: var(--arc-fontSizes-md);
    padding: ${ITEM_QUEUE_TABLE_CELL_PADDING}px;
`;

const NoDataBlock = styled(_NoDataBlock)`
    margin-top: 40px;
`;

const DispositionStatus = styled(_DispositionStatus)`
    margin-top: 5px;
`;

const ItemQueueHeader = styled(({ className }) => (
    <div className={className} style={{ width: '100%' }}>
        <Header width={ITEM_QUEUE_TABLE_ITEM_INFO_WIDTH}>{STRINGS.ItemQueueHeader.itemInfo}</Header>

        <Header width={ITEM_QUEUE_TABLE_ITEM_STORAGE_LOCATION_WIDTH}>
            {STRINGS.ItemQueueHeader.storageLocation}
        </Header>

        <Header width={ITEM_QUEUE_TABLE_ITEM_STATUS_WIDTH}>{STRINGS.ItemQueueHeader.status}</Header>

        <Header width={ITEM_QUEUE_TABLE_ITEM_ACTION_WIDTH} />
    </div>
))`
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    display: flex;
`;

const FlexWrapper = styled.div`
    display: flex;
`;

const ItemInfoCell = styled(({ className, viewModel }) => (
    <ItemInfo
        className={className}
        reportId={viewModel.reportId}
        width={ITEM_QUEUE_TABLE_ITEM_INFO_WIDTH}
        viewModel={viewModel}
        showHighRiskLabel={true}
    />
))`
    padding: ${ITEM_QUEUE_TABLE_CELL_PADDING}px;
    width: ${`${ITEM_QUEUE_TABLE_ITEM_INFO_WIDTH}px`};
`;

const StorageLocationCell = styled(({ className, viewModel }) => {
    const { facility, storageLocation } = getViewModelProperties(viewModel.latestChainEvent);

    return (
        <Cell className={className}>
            {get(storageLocation, 'fullDisplayPath') || // includes facility
                get(facility, 'locationName') || <EmDash />}
        </Cell>
    );
})`
    width: ${`${ITEM_QUEUE_TABLE_ITEM_STORAGE_LOCATION_WIDTH}px`};
`;

const StatusCell = styled(({ className, viewModel }) => {
    const { eventTypeDisplay } = getViewModelProperties(viewModel.latestChainEvent);

    return (
        <Cell className={className}>
            <div>{eventTypeDisplay || <EmDash />}</div>

            <DispositionStatus chainOfCustodyId={viewModel.latestChainEvent.chainOfCustodyId} />
        </Cell>
    );
})`
    width: ${`${ITEM_QUEUE_TABLE_ITEM_STATUS_WIDTH}px`};
`;

const ActionCell = styled(
    connect(null, (dispatch, { viewModel }) => ({
        removeFromItemQueue: () => dispatch(removeFromItemQueue(viewModel.masterItemId)),
    }))(({ removeFromItemQueue }) => (
        <Cell>
            <Button
                className={buttonTypes.ICON_LINK}
                iconLeft={iconTypes.TRASH_CAN}
                onClick={removeFromItemQueue}
            />
        </Cell>
    ))
)`
    width: ${`${ITEM_QUEUE_TABLE_ITEM_ACTION_WIDTH}px`};
`;

const StaffRemarksRow = styled(_StaffRemarksRow)`
    margin-bottom: 16px;
    margin-top: 6px;
    padding-left: 35px;
`;

const ItemQueueRow = styled(({ className, canViewStaffRemarks, viewModel }) => (
    <div className={className} style={{ width: '100%' }}>
        <FlexWrapper>
            <ItemInfoCell viewModel={viewModel} />

            <StorageLocationCell viewModel={viewModel} />

            <StatusCell viewModel={viewModel} />

            <ActionCell viewModel={viewModel} />
        </FlexWrapper>

        {canViewStaffRemarks && <StaffRemarksRow masterItemId={viewModel.masterItemId} />}
    </div>
))`
    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
`;

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
`;

const ItemQueueTable = styled(
    ({ className, canViewStaffRemarks, itemsWithStaffRemarksCount, viewModels }) => {
        const evidenceModuleName = useEvidenceModuleName();

        return (
            <div className={className} data-test-id={testIds.EVIDENCE_ITEM_QUEUE_TABLE}>
                {canViewStaffRemarks && itemsWithStaffRemarksCount > 0 && (
                    <StaffRemarksNotice totalCount={itemsWithStaffRemarksCount} />
                )}

                <Wrapper>
                    <ItemQueueHeader />

                    {viewModels.length === 0 ? (
                        <NoDataBlock>
                            {STRINGS.ItemQueueTable.empty(evidenceModuleName)}
                        </NoDataBlock>
                    ) : (
                        map(viewModels, (viewModel) => (
                            <ItemQueueRow
                                key={viewModel.masterItemId}
                                canViewStaffRemarks={canViewStaffRemarks}
                                viewModel={viewModel}
                            />
                        ))
                    )}
                </Wrapper>
            </div>
        );
    }
)`
    background-color: ${(props) => props.theme.colors.white};
    min-height: 100%;
`;

const mapStateToProps = createStructuredSelector({
    currentUserHasAbility,
    itemsWithStaffRemarksCount,
    viewModels,
});

/**
 * Table displaying all master items currently in the item queue.
 */
export default compose(
    connect(mapStateToProps),
    withPropsOnChange(['currentUserHasAbility'], ({ currentUserHasAbility }) => ({
        canViewStaffRemarks: currentUserHasAbility(EVIDENCE.VIEW_STAFF_REMARKS),
    }))
)(ItemQueueTable);

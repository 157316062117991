import { isArray, map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// the FlyingSaucer for printing does not have `Intl` so we have to fallback to localeCompare
// even though Intl is _much_ faster
const createNaturalComparator = () => {
    if (typeof Intl !== 'undefined') {
        const collator = new Intl.Collator(undefined, { numeric: true });
        return (a: string, b: string) => {
            return collator.compare(a, b);
        };
    } else {
        return (a: unknown, b: unknown) => {
            if (typeof a !== 'string' || typeof b !== 'string') {
                return -1;
            }
            return a.localeCompare(b, undefined, {
                numeric: true,
            });
        };
    }
};

const naturalCompare = createNaturalComparator();

// Largely copied from lodash `orderBy`
// This is altered to use localecompare as the sorting comparision function
function baseOrderBy<T>(
    collection: T[],
    iteratees: (string | ((val: T) => unknown))[],
    orders: ('asc' | 'desc')[]
) {
    let index = -1;
    iteratees = iteratees.length ? iteratees : [(value: T) => value];
    const result = map(collection, (value) => {
        const criteria = map(iteratees, (iteratee) =>
            // @ts-expect-error type error when we do a lookup with an any signature type
            typeof iteratee === 'function' ? iteratee(value) : value[iteratee]
        );
        return { criteria, index: ++index, value };
    });

    return map(
        result.sort((object, other) => {
            let index = -1;
            const objCriteria = object.criteria;
            const othCriteria = other.criteria;
            const length = objCriteria.length;
            const ordersLength = orders.length;
            while (++index < length) {
                const result = naturalCompare(objCriteria[index], othCriteria[index]);
                if (result) {
                    if (index >= ordersLength) {
                        return result;
                    }
                    const order = orders[index];
                    return result * (order === 'desc' ? -1 : 1);
                }
            }
            return object.index - other.index;
        }),
        'value'
    );
}

// This function will only sort strings, if a field is passed in that does not reference a string
// this will not work
// example: sortByNaturalOrder(collection, ['name', 'email'], ['asc', 'desc'])
// Lodash prevents you from putting in a custom sorting comparision function
// so a new one had to be made
export function sortByNaturalOrder<T>(
    collection: T[],
    iteratees: string | (string | ((val: T) => unknown))[],
    orders?: ('asc' | 'desc') | ('asc' | 'desc')[]
) {
    if (collection == null) {
        return [];
    }
    if (!isArray(iteratees)) {
        iteratees = iteratees == null ? [] : [iteratees];
    }
    if (!isArray(orders)) {
        orders = orders == null ? [] : [orders];
    }

    return baseOrderBy<T>(collection, iteratees, orders);
}

export const generateIdNumber = () => {
    const uuid = uuidv4();
    const hexStr = uuid.replace(/-/g, '').slice(0, 15);
    return parseInt(hexStr, 16);
};

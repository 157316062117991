import { flowRight } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { withPropsOnChange } from 'recompose';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { isExpired } from '~/client-common/core/dates/utils/dateHelpers';
import {
    storageLocationViewModelByIdSelector,
    storageLocationHasChildrenSelector,
} from '~/client-common/core/domain/storage-locations/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE } from '~/client-common/core/enums/universal/fields';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { createModalSelector } from '../../../core/box/state/ui';
import { submitDeleteStorageModal } from '../state/ui';
import { renderOnlyIf } from '../../../../legacy-redux/helpers/reactHelpers';

const context = {
    name: boxEnum.DELETE_STORAGE_MODAL,
};
const strings = componentStrings.admin.evidenceFacilities.DeleteStorageModal;

function DeleteStorageModal({ message, deletable, onSubmit }) {
    return (
        <Modal
            context={context}
            title={strings.title}
            okText={deletable ? strings.delete : null}
            cancelText={deletable ? strings.cancel : strings.close}
            onSave={deletable ? onSubmit : null}
        >
            {message}
        </Modal>
    );
}

const storageLocationIdSelector = createModalSelector(context, 'storageLocationId');
const storageLocationViewModelSelector = createSelector(
    storageLocationViewModelByIdSelector,
    storageLocationIdSelector,
    (storageLocationViewModelById, storageLocationId) =>
        storageLocationViewModelById(storageLocationId)
);
const mapStateToProps = createStructuredSelector({
    storageLocationId: storageLocationIdSelector,
    storageLocationViewModel: storageLocationViewModelSelector,
    storageLocationHasChildren: storageLocationHasChildrenSelector,
    formatFieldByName: formatFieldByNameSelector,
    // TODO: blocked by back end, need endpoint that tells us whether the
    // storage has items associated with it
    hasEvidenceItemsWarning: () => false,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => dispatch(submitDeleteStorageModal()),
});

/**
 * Modal that appears when an admin user clicks on a trash button beside a
 *   storage location on the Facilities Admin page. The button actually expires
 *   the storage location, and does not delete them.
 */
export default flowRight(
    connect(mapStateToProps, mapDispatchToProps),
    renderOnlyIf(({ storageLocationViewModel }) => !!storageLocationViewModel),
    withPropsOnChange(
        ['hasEvidenceItems', 'storageLocationViewModel'],
        ({
            hasEvidenceItems,
            storageLocationHasChildren,
            formatFieldByName,
            storageLocationViewModel: { displayValue, id, expiredDateUtc } = {},
        }) => {
            if (isExpired(expiredDateUtc)) {
                return {
                    message: strings.alreadyExpiredWarning(displayValue),
                    deletable: false,
                };
            } else if (hasEvidenceItems) {
                const evidenceModuleName = formatFieldByName(DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE);
                return {
                    message: strings.hasEvidenceItemsWarning({
                        label: displayValue,
                        evidenceModuleName,
                    }),
                    deletable: false,
                };
            } else if (storageLocationHasChildren(id)) {
                return {
                    message: strings.hasStorageLocationChildren(displayValue),
                    deletable: true,
                };
            } else {
                return {
                    message: strings.areYouSure(displayValue),
                    deletable: true,
                };
            }
        }
    )
)(DeleteStorageModal);

import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ExternalLink } from '../../../../../modules/core/components/links/Link';
import { BookingCellProps } from '../../../../../modules/entity-profiles/persons/components/EntityProfilePersonJmsBookings';

const InfoGroup = styled.div`
    word-wrap: break-word;
    &:first-child {
        margin-top: 0;
    }
`;

const InfoLabel = styled.div`
    vertical-align: top;
    display: inline-block;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    padding-right: 8px;
`;

const strings = componentStrings.entityProfiles.EntityProfilePersonBookings;

export default function BookingDetailsCell({
    id,
    detainee,
    bookingNumber,
    bookingOfficer,
    bookingFacility,
    showDetaineeName = false,
}: BookingCellProps & { showDetaineeName?: boolean }) {
    return (
        <div className="elastic-person-identifiers">
            <InfoGroup>
                <div>
                    <InfoLabel>{strings.columns.bookingNumber.label}:</InfoLabel>
                    {detainee?.id && id ? (
                        <ExternalLink
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            to={`/jms/detainees/${detainee?.id}/${id}/overview`}
                            openInNewTab={true}
                        >
                            {bookingNumber}
                        </ExternalLink>
                    ) : (
                        <div className="info-text">{bookingNumber}</div>
                    )}
                </div>
                {bookingOfficer && (
                    <div>
                        <InfoLabel>{strings.columns.bookingOfficer.label}:</InfoLabel>
                        <div className="info-text">{bookingOfficer.abbreviatedName}</div>
                    </div>
                )}
                <div>
                    <InfoLabel>{strings.columns.bookingFacility.label}:</InfoLabel>
                    <div className="info-text">{bookingFacility?.displayName}</div>
                </div>
                {detainee && showDetaineeName && (
                    <div>
                        <InfoLabel>{strings.columns.detainee.label}:</InfoLabel>
                        <div className="info-text">
                            {detainee.lastName}, {detainee.firstName}
                        </div>
                    </div>
                )}
            </InfoGroup>
        </div>
    );
}

import { PropertyStatus, CodeTypeCategoryEnumType, CodeTypeCategoryEnum } from '@mark43/rms-api';
import { filter, includes } from 'lodash';
import { createSelector } from 'reselect';

import getPropertyStatusResource from '../../resources/propertyStatusResource';
import { nibrsCodeForAttributeIdCodeTypeCategoryAndDepartmentSelector } from '../../../attribute-codes/state/ui';
import globalAttributes from '../../../../legacy-constants/globalAttributes';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { parentAttributeIdByAttributeIdSelector } from '../../../attributes/state/data';
import { propertyLossCodes } from '../../../../constants/nibrsCodes';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'propertyStatuses';

const propertyStatusesModule = createNormalizedModule<PropertyStatus>({
    type: NEXUS_STATE_PROP,
});

// ACTION TYPES

const UPDATE_PROPERTY_STATUS_SUCCESS = 'property-statuses/UPDATE_PROPERTY_STATUS_SUCCESS';
const UPSERT_PROPERTY_STATUSES_START = 'property-statuses/UPSERT_PROPERTY_STATUSES_START';
const UPSERT_PROPERTY_STATUSES_FAILURE = 'property-statuses/UPSERT_PROPERTY_STATUSES_FAILURE';
const DELETE_PROPERTY_STATUSES_START = 'property-statuses/DELETE_PROPERTY_STATUSES_START';
const DELETE_PROPERTY_STATUSES_SUCCESS = 'property-statuses/DELETE_PROPERTY_STATUSES_SUCCESS';
const DELETE_PROPERTY_STATUSES_FAILURE = 'property-statuses/DELETE_PROPERTY_STATUSES_FAILURE';

// ACTIONS

const removePropertyStatusesWhere = propertyStatusesModule.actionCreators.deleteEntitiesWhere;

function upsertPropertyStatusesStart() {
    return { type: UPSERT_PROPERTY_STATUSES_START };
}
function upsertPropertyStatusesFailure(errorMessage: string) {
    return { type: UPSERT_PROPERTY_STATUSES_FAILURE, payload: errorMessage, error: true };
}

function deletePropertyStatusesStart() {
    return { type: DELETE_PROPERTY_STATUSES_START };
}
function deletePropertyStatusesSuccess() {
    return { type: DELETE_PROPERTY_STATUSES_SUCCESS };
}
function deletePropertyStatusesFailure(errorMessage: string) {
    return { type: DELETE_PROPERTY_STATUSES_FAILURE, payload: errorMessage, error: true };
}

/**
 * Upsert a list of property statuses
 */
export function upsertPropertyStatuses(
    propertyStatuses: PropertyStatus[]
): ClientCommonAction<Promise<PropertyStatus[]>> {
    return function (dispatch, getState, dependencies) {
        const propertyStatusResource = getPropertyStatusResource();
        dispatch(upsertPropertyStatusesStart());
        return propertyStatusResource
            .upsertPropertyStatuses(propertyStatuses)
            .then((propertyStatuses: PropertyStatus[]) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        { [NEXUS_STATE_PROP]: propertyStatuses },
                        { type: UPDATE_PROPERTY_STATUS_SUCCESS }
                    )
                );
                return propertyStatuses;
            })
            .catch((err: Error) => {
                dispatch(upsertPropertyStatusesFailure(err.message));
                throw err;
            });
    };
}

/**
 * Delete property statuses by ids.
 */
export function deletePropertyStatuses(
    propertyStatusIds: number[]
): ClientCommonAction<Promise<void>> {
    return function (dispatch) {
        const propertyStatusResource = getPropertyStatusResource();
        dispatch(deletePropertyStatusesStart());
        return propertyStatusResource
            .deletePropertyStatuses(propertyStatusIds)
            .then((success: boolean) => {
                if (!success) {
                    throw new Error('Failed to remove statuses');
                }

                dispatch(removePropertyStatusesWhere(({ id }) => includes(propertyStatusIds, id)));
                dispatch(deletePropertyStatusesSuccess());
            })
            .catch((err: Error) => {
                dispatch(deletePropertyStatusesFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const propertyStatusesSelector = propertyStatusesModule.selectors.entitiesSelector;

/**
 * All property statuses of the given item profile id. An array of
 *   PropertyStatus objects.
 */
export const propertyStatusesByItemProfileIdSelector = createSelector(
    propertyStatusesSelector,
    (propertyStatuses) => (itemProfileId: number) => filter(propertyStatuses, { itemProfileId })
);

/**
 * Return whether a property status is "in police custody", which means whether
 *   it has that global attribute as its parent attribute.
 */
export const propertyStatusAttrIdIsInPoliceCustodySelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (propertyStatusAttrId: number) =>
        parentAttributeIdByAttributeId(propertyStatusAttrId) ===
        globalAttributes.propertyStatusGlobal.inPoliceCustody
);

const filterInvolvedPropertiesByNibrsCodeSelector = createSelector(
    nibrsCodeForAttributeIdCodeTypeCategoryAndDepartmentSelector,
    (nibrsCodeForAttributeIdCodeTypeCategoryAndDepartment) => (
        propertyStatuses: PropertyStatus[],
        codeTypeCategory: CodeTypeCategoryEnumType,
        nibrsCode: string,
        departmentId: number
    ) =>
        filter(propertyStatuses, ({ propertyStatusAttrId }) => {
            return (
                nibrsCodeForAttributeIdCodeTypeCategoryAndDepartment(
                    propertyStatusAttrId,
                    codeTypeCategory,
                    departmentId
                ) === nibrsCode
            );
        })
);

const filterPropertyStatusesByItemsAndOffensesSelector = createSelector(
    propertyStatusesSelector,
    (propertyStatuses) => (itemProfileIds: number[], offenseIds: number[]) => {
        return filter(
            propertyStatuses,
            (ps) => includes(itemProfileIds, ps.itemProfileId) && includes(offenseIds, ps.offenseId)
        );
    }
);

export const filterStolenInvolvedItemsSelector = createSelector(
    filterPropertyStatusesByItemsAndOffensesSelector,
    filterInvolvedPropertiesByNibrsCodeSelector,
    (filterPropertyStatusesByItemsAndOffenses, filterInvolvedPropertiesByNibrsCode) => (
        itemProfileIds: number[],
        offenseIds: number[],
        departmentId: number
    ) => {
        const filteredPropertyStatuses = filterPropertyStatusesByItemsAndOffenses(
            itemProfileIds,
            offenseIds
        );
        return filterInvolvedPropertiesByNibrsCode(
            filteredPropertyStatuses,
            CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name,
            propertyLossCodes.stolen,
            departmentId
        );
    }
);
export const filterRecoveredInvolvedItemsSelector = createSelector(
    filterPropertyStatusesByItemsAndOffensesSelector,
    filterInvolvedPropertiesByNibrsCodeSelector,
    (filterPropertyStatusesByItemsAndOffenses, filterInvolvedPropertiesByNibrsCode) => (
        itemProfileIds: number[],
        offenseIds: number[],
        departmentId: number
    ) => {
        const filteredPropertyStatuses = filterPropertyStatusesByItemsAndOffenses(
            itemProfileIds,
            offenseIds
        );
        return filterInvolvedPropertiesByNibrsCode(
            filteredPropertyStatuses,
            CodeTypeCategoryEnum.NIBRS_PROPERTY_LOSS.name,
            propertyLossCodes.recovered,
            departmentId
        );
    }
);

// REDUCER
export default propertyStatusesModule.reducerConfig;

import { map } from 'lodash';
import { createSelector } from 'reselect';

import { formatUcrCode } from '../../../../../helpers/offenseCodesHelpers';

import { reportUcrsByReportIdSelector } from '../data';
import { ucrSummaryOffenseCodeByCodeSelector } from '../../../ucr-summary-offense-codes/state/data';

// helpers
import {
    allSingleAttributeValuesMapper,
    buildViewModel,
} from '../../../../../helpers/viewModelHelpers';

// selectors
import { formatAttributeByIdSelector } from '../../../attributes/state/data';

const buildReportUcrViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    ucrSummaryOffenseCodeByCodeSelector,
    (formatAttributeById, ucrSummaryOffenseCodeByCode) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ ucrCodeCode }) => ({
                    ucrCodeSummary: ucrCodeCode
                        ? formatUcrCode(ucrSummaryOffenseCodeByCode(ucrCodeCode))
                        : '',
                }),
            ],
            helpers: {
                formatAttributeById,
            },
        });

        return viewModel;
    }
);

export const reportUcrViewModelsByReportIdSelector = createSelector(
    reportUcrsByReportIdSelector,
    buildReportUcrViewModelSelector,
    (reportUcrsByReportId, buildReportUcrViewModel) => (reportId) =>
        map(reportUcrsByReportId(reportId), buildReportUcrViewModel)
);

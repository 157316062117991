import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { find } from 'lodash';

import { ItemProfile, PersonProfile } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Divider from '../../../legacy-redux/components/core/Divider';
import Row from '../../core/components/Row';
import { ContentSection } from '../../../legacy-redux/components/core/Content';
import { AnalyticsContextProviderWithAdditionalData } from '../../core/context/AnalyticsContext';
import { AnalyticsPropertyEnum } from '../../analytics/constants/analyticsEnum';
import { ClipsCurrentReportData } from '../state/ui';
import { MFTNItems } from '../../core/forms/components/NItems';
import testIds from '../../../core/testIds';
import ClipsReportCardItem from './ClipsReportCardItem';

const CardSectionError = styled.div`
    margin-left: 10px;
    display: inline-block;
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.red};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    text-transform: none;
    font-weight: normal;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 23px;
`;

const strings = componentStrings.clips.ClipsReportCards;

const ClipsReportPersonEntries: React.FC<{
    entityId: number;
    personProfiles: PersonProfile[];
    ren: string;
}> = ({ entityId, personProfiles, ren }) => {
    return (
        <>
            <MFTNItems<PersonProfile>
                path="people"
                childFieldKeys={['entryName', 'isSelected']}
                renderAddButton={undefined}
                renderRemoveButton={undefined}
                render={({ item, index }) => {
                    const personProfileViewModel = find(
                        personProfiles,
                        (person) => person.id === item.id
                    );
                    const personProfileId = personProfileViewModel?.id;
                    return (
                        <Row key={personProfileId} data-test-id={testIds.CLIPS_PEOPLE_SECTION}>
                            {index > 0 && <Divider />}
                            <ClipsReportCardItem
                                reportId={entityId}
                                personProfileId={personProfileId}
                                personFlag={true}
                                ren={ren}
                            />
                        </Row>
                    );
                }}
            />
        </>
    );
};
const ClipsReportPropertyEntries: React.FC<{
    entityId: number;
    itemSummaries: ItemProfile[];
    ren: string;
}> = ({ entityId, itemSummaries, ren }) => {
    return (
        <>
            <MFTNItems<ItemProfile>
                path="property"
                childFieldKeys={['entryName', 'isSelected']}
                renderAddButton={undefined}
                renderRemoveButton={undefined}
                render={({ item, index }) => {
                    const itemSummaryViewModel = find(
                        itemSummaries,
                        (property) => property.id === item.id
                    );
                    const itemProfileId = itemSummaryViewModel?.id;
                    return (
                        <Row key={itemProfileId} data-test-id={testIds.CLIPS_PROPERTY_SECTION}>
                            {index > 0 && <Divider />}
                            <ClipsReportCardItem
                                reportId={entityId}
                                itemProfileId={itemProfileId}
                                itemSummaryViewModel={itemSummaryViewModel}
                                itemFlag={true}
                                ren={ren}
                            />
                        </Row>
                    );
                }}
            />
        </>
    );
};
const ClipsReportVehicleEntries: React.FC<{
    entityId: number;
    itemSummaries: ItemProfile[];
    ren: string;
}> = ({ entityId, itemSummaries, ren }) => {
    return (
        <>
            <MFTNItems<ItemProfile>
                path="vehicles"
                childFieldKeys={['entryName', 'isSelected']}
                renderAddButton={undefined}
                renderRemoveButton={undefined}
                render={({ item, index }) => {
                    const itemSummaryViewModel = find(
                        itemSummaries,
                        (vehicle) => vehicle.id === item.id
                    );
                    const itemProfileId = itemSummaryViewModel?.id;
                    return (
                        <Row key={itemProfileId} data-test-id={testIds.CLIPS_VEHICLES_SECTION}>
                            {index > 0 && <Divider />}
                            <ClipsReportCardItem
                                reportId={entityId}
                                itemProfileId={itemProfileId}
                                itemSummaryViewModel={itemSummaryViewModel}
                                itemFlag={true}
                                ren={ren}
                            />
                        </Row>
                    );
                }}
            />
        </>
    );
};

const ClipsReportCardSection: React.FC<{
    sectionTitle?: string;
    entityId: number;
    reportEntities: ClipsCurrentReportData;
    helpText?: string;
    error?: string;
    className?: string;
    headerWidth?: number;
    testId?: string;
    fieldName?: string;
    theme?: DefaultTheme;
}> = ({
    sectionTitle,
    entityId,
    reportEntities,
    helpText,
    error,
    className,
    testId,
    fieldName,
}) => {
    const title = (
        <Title>
            <div>{sectionTitle}</div>
        </Title>
    );
    return (
        <AnalyticsContextProviderWithAdditionalData
            analyticsKeyToAdd={AnalyticsPropertyEnum.CLIPS}
            analyticsValueToAdd={testId}
        >
            <ContentSection
                title={title}
                helpText={helpText}
                error={error && <CardSectionError>{error}</CardSectionError>}
                className={className}
                headerStyle={{
                    width: 'calc(100% + 23px)',
                    marginLeft: '-23px',
                    marginBottom: '12px',
                }}
                fieldName={fieldName}
                testId={testId}
            >
                {sectionTitle === strings.peopleTitle && (
                    <ClipsReportPersonEntries
                        entityId={entityId}
                        personProfiles={reportEntities?.personProfileEntities}
                        ren={reportEntities?.report?.reportingEventNumber || ''}
                    />
                )}
                {sectionTitle === strings.vehiclesTitle && (
                    <ClipsReportVehicleEntries
                        entityId={entityId}
                        itemSummaries={reportEntities?.itemSummaryEntities.vehicleSummaries}
                        ren={reportEntities?.report?.reportingEventNumber || ''}
                    />
                )}
                {sectionTitle === strings.propertyTitle && (
                    <ClipsReportPropertyEntries
                        entityId={entityId}
                        itemSummaries={reportEntities?.itemSummaryEntities?.itemSummaries}
                        ren={reportEntities?.report?.reportingEventNumber || ''}
                    />
                )}
            </ContentSection>
        </AnalyticsContextProviderWithAdditionalData>
    );
};

export default ClipsReportCardSection;

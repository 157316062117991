// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'print/case';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Case Printing Resource',
        methods: {
            /**
             * upserts the given arrays, does not do a "replace all"
             * @param  {Object} casePrintingTemplates
             */
            upsertCasePrintingTemplates(casePrintingTemplates) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/templates`,
                    data: casePrintingTemplates,
                });
            },
            getCasePrintingTemplates() {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/templates`,
                });
            },
            deleteCasePrintingTemplate(id) {
                return req({
                    method: 'DELETE',
                    url: `${BASE_PATH}/template/${id}`,
                });
            },
        },
    });
}

/**
 * Resources for case printing templates. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { NotificationStatusEnum } from '@mark43/notifications-api';
import { noop } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';

import Measure from 'react-measure';
import { markAllUserNotifications } from '~/client-common/core/domain/user-notifications/state/data';
import NavigationLink from '../../core/components/NavigationLink';
import { iconTypes } from '../../../../core/components/Icon';

import {
    openNotificationsPopover,
    notificationsPopoverIsOpenSelector,
} from '../../../../notifications/popover/state/ui';
import {
    setNotificationNavigationLinkPosition,
    notificationsUnreadCountSelector,
    markingUserNotificationsSelector,
    previousPollDateUtcSelector,
} from '../../../../notifications/navigation-link/state/ui';
import { windowWidthSelector } from '../../../../../legacy-redux/selectors/globalSelectors';

const { READ, UNREAD } = NotificationStatusEnum;

function NotificationsNavigationLink({
    onClick,
    notificationsPopoverIsOpen,
    setNotificationNavigationLinkPosition,
    text,
    unreadCount,
    windowWidth,
    testId,
}) {
    return (
        <Measure
            path="NotificationsNavigationLink"
            onMeasure={setNotificationNavigationLinkPosition}
            whiteList={['left']}
            // Hack: setting the key to be window width will force this
            // component to re-mount everytime the width changes.
            // Want to re-mount because the resize-observer-polyfill that
            // react-measure is using won't trigger on window resize.
            // This is not a 'responsive' solution, just for small edge case
            // where user resizes window while using the app.
            key={windowWidth}
        >
            <NavigationLink
                text={text}
                iconType={iconTypes.NOTIFICATION}
                selected={notificationsPopoverIsOpen}
                onClick={onClick}
                count={unreadCount}
                testId={testId}
            />
        </Measure>
    );
}

const mapStateToProps = createStructuredSelector({
    notificationsPopoverIsOpen: notificationsPopoverIsOpenSelector,
    unreadCount: notificationsUnreadCountSelector,
    markingUserNotifications: markingUserNotificationsSelector,
    previousPollDateUtc: previousPollDateUtcSelector,
    windowWidth: windowWidthSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openNotificationsPopover: () => dispatch(openNotificationsPopover()),
    markAllUserNotifications: (query) => dispatch(markAllUserNotifications(query)),
    setNotificationNavigationLinkPosition: ({ left }) =>
        dispatch(setNotificationNavigationLinkPosition(left)),
});

/**
 * Compose NavigationLink for Notifications. Not a Link, and opens the
 *   Notifications Popover. When opened, make an API request in the background
 *   to mark as read all notifications up to the last poll. Overlay the Number
 *   Bubble with unread notifications. Will re-mount whenver window width
 *   changes, this will trigger Measure to re-calculate left position, so that
 *   the Notifications Popover is lined up properly.
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onClick({
            openNotificationsPopover,
            markAllUserNotifications,
            markingUserNotifications,
            previousPollDateUtc,
            unreadCount,
        }) {
            return () => {
                // open the popover first
                openNotificationsPopover();

                // mark notifications as read asynchronously in the background,
                // and silently ignore any API error
                if (unreadCount > 0 && !markingUserNotifications) {
                    markAllUserNotifications({
                        beforeStatuses: [UNREAD.name],
                        afterStatus: READ.name,
                        endDateUtc: previousPollDateUtc,
                    }).catch(noop);
                }
            };
        },
    })
)(NotificationsNavigationLink);

import _ from 'lodash';
import { createSelector } from 'reselect';

import { itemReportLinksSelector } from '../data';
import { reportShortTitleViewModelsSelector } from '../../../report-short-titles/state/ui';
import { sortReportShortTitleViewModels } from '../../../report-short-titles/utils/reportShortTitlesHelpers';

/**
 * Find the `ReportShortTitle`s linked to the given REN that contain the given
 *   master item. Custodial Property Summary reports are not included.
 * @param  {number} masterItemId
 * @param  {string} reportingEventNumber
 * @return {Object[]}
 */
export const linkedReportShortTitleViewModelsForMasterItemSelector = createSelector(
    itemReportLinksSelector,
    reportShortTitleViewModelsSelector,
    (itemReportLinks, reportShortTitleViewModels) => (masterItemId, reportingEventNumber) => {
        const reportIds = _(itemReportLinks)
            .filter(({ masterItemIds }) => _.includes(masterItemIds, masterItemId))
            .map('reportId')
            .value();
        return _(reportShortTitleViewModels)
            .pick(reportIds)
            .filter({ reportingEventNumber })
            .thru(sortReportShortTitleViewModels)
            .value();
    }
);

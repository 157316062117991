import { map } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const assignUserRoleFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'assignedUserRole',
        validators: {
            requiredError: requiredString,
        },
    },
]);

function convertFromFormModel(assignUserRoleFormModel = {}, userData = {}) {
    return map(assignUserRoleFormModel.assignedUserRole, (userId) => ({
        ...userData,
        userId,
    }));
}

const assignUserRoleForm = createFormModule({
    formName: formClientEnum.ASSIGN_USER_ROLE,
    fieldViewModels: assignUserRoleFormFieldViewModels,
    convertFromFormModel,
});

export default assignUserRoleForm;

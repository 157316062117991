import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import {
    mustBeInt,
    requiredString,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const streetSegmentFormFieldViewModels = {
    streetSegment: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'streetSegment',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'streetName',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'startCrossStreetName',
            },
            {
                key: 'endCrossStreetName',
            },
            {
                key: 'geocodeOffsetMeters',
                validators: {
                    requiredError: mustBeInt,
                },
            },
            {
                key: 'streetSegmentParity',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'streetSegmentSide',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'startRange',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'startLatitude',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'startLongitude',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'endRange',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'endRange',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'endLatitude',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'endLongitude',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'locality',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'administrativeAreaLevel1',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'postalCode',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'upsertAcrossDepartments',
            },
        ]),
    },
};

function convertToFormModel(streetSegment) {
    return {
        streetSegment: {
            ...streetSegment,
        },
    };
}

function convertFromFormModel(streetSegment) {
    const { streetName, startCrossStreetName, endCrossStreetName } = streetSegment;
    // Explicitly set isAdminOverride to true
    streetSegment = { ...streetSegment, isAdminOverride: true };
    const streetSegmentView = {
        streetName,
        streetSegment,
        startCrossStreetName,
        endCrossStreetName,
    };

    return streetSegmentView;
}

export default createFormModule({
    formName: formClientEnum.STREET_SEGMENTS_ADMIN,
    fieldViewModels: streetSegmentFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import React from 'react';
import styled from 'styled-components';

import { NibrsSegmentsErrorDescription } from '@mark43/rms-api';

const ErrorTitle = styled.div<{ isFatal?: boolean }>`
    color: ${({ theme, isFatal }) => (isFatal ? theme.colors.red : theme.colors.mediumLightGrey)};
    font: ${({ theme }) => `13px ${theme.fontFamilies.proximaNova}`};
`;

const ErrorContent = styled.div<{ isFatal?: boolean }>`
    color: ${({ theme, isFatal }) => (isFatal ? theme.colors.red : theme.colors.darkGrey)};
    font: ${({ theme }) => `13px ${theme.fontFamilies.proximaNova}`};
    margin-bottom: 24px;
`;

export const NibrsErrorDisplay: React.FC<NibrsSegmentsErrorDescription & { isFatal?: boolean }> = (
    error
) => {
    const { nibrsErrorMessage, rmsSuggestionMessage, isFatal } = error;

    return (
        <>
            <ErrorTitle isFatal={isFatal}>{nibrsErrorMessage}</ErrorTitle>
            <ErrorContent isFatal={isFatal}>{rmsSuggestionMessage}</ErrorContent>
        </>
    );
};

import React, { useContext } from 'react';
import classNames from 'classnames';
import { scrollableNodeNameEnum, ScrollableNodesContext } from '../context/ScrollableNodesContext';
import { PageContent } from './PageContent';

/** This is the basic scrollable, full width container used as the parent element in `ScrollableUnderSubheader`.
 * Only use this when you need to do a layout outside of what `PageContent` gives inside `ScrollableUnderSubheader`
 * Such as the sticky scroll layouts seen in the Report, EntityProfile, Warrant, and NotificationsDashboard pages.
 */

type BaseScrollableUnderSubheaderProps = React.ComponentPropsWithRef<'div'> & {
    children: React.ReactNode;
    className?: string;
};

export const BaseScrollableUnderSubheader: React.FC<BaseScrollableUnderSubheaderProps> = React.forwardRef(
    ({ className, children, ...props }, forwardedRef: React.Ref<HTMLDivElement>) => {
        return (
            <div
                ref={forwardedRef}
                className={classNames('mark43-scrollable-under-subheader', className)}
                {...props}
            >
                {children}
            </div>
        );
    }
);

/**
 * This component is the scrollable version of the content area that appears
 *   below a header and subheader (when both are React components and not legacy
 *   views). It is full width and set to fill the available vertical space and set to
 *   scroll automatically for overflowing content.
 *
 *  It places children inside PageContent, any style overrides are applied to the PageContent.
 *  The `fullscreen` prop will affect the the layout for the PageContent.
 */
const ScrollableUnderSubheader: React.FC<{
    className?: string;
    contentClassnames?: string;
    fullscreen?: boolean;
    useConditionalClassNames?: boolean;
    style?: React.CSSProperties;
}> = ({
    children,
    className,
    fullscreen = false,
    contentClassnames = 'height-one-hundred',
    useConditionalClassNames = true,
    style,
}) => {
    const nodeRefs = useContext(ScrollableNodesContext);

    return (
        <BaseScrollableUnderSubheader
            className={className}
            ref={nodeRefs[scrollableNodeNameEnum.SCROLLABLE_UNDER_SUBHEADER]}
        >
            <PageContent
                fullscreen={fullscreen}
                useConditionalClassNames={useConditionalClassNames}
                className={contentClassnames}
                style={style}
            >
                {children}
            </PageContent>
        </BaseScrollableUnderSubheader>
    );
};

export function scrollToTop() {
    const elements = document.getElementsByClassName('mark43-scrollable-under-subheader');
    if (elements.length > 0) {
        elements[0].scrollTop = 0;
    }
}

export default ScrollableUnderSubheader;

import React, { useRef, useEffect } from 'react';
import Konva from 'konva';
import { Image as KonvaImage, Transformer } from 'react-konva';
import useImage from 'use-image';
import { Widget, CrashDiagramPosition } from '../../types';
import { useKonvaShapeEvents } from '../../hooks';

type CrashDiagramImageProps = {
    widget: Widget;
    onSaveDragEndHistoryEvent: (widgetId: string, position: CrashDiagramPosition) => void;
    onSelect: () => void;
    isSelected: boolean;
};
export const Image: React.FC<CrashDiagramImageProps> = ({
    widget,
    isSelected,
    onSaveDragEndHistoryEvent,
    onSelect,
}) => {
    const imageRef = useRef<Konva.Image>(null);
    const transformerRef = useRef<Konva.Transformer>(null);
    const [image] = useImage(widget.value);

    const { onDragMove, onDragEnd, onTransformEnd, onTransformStart, boundBoxFunc } =
        useKonvaShapeEvents({
            onMoveWidgetEnd: onSaveDragEndHistoryEvent,
        });

    useEffect(() => {
        if (!isSelected || !image) {
            return;
        }
        const transformer = transformerRef?.current;
        const refsInitialize = !!(transformer && imageRef?.current);

        if (refsInitialize) {
            transformer.nodes([imageRef.current]);
            transformer.getLayer()?.batchDraw();
        }

        return () => {
            transformer?.detach();
        };
    }, [isSelected, image, transformerRef, imageRef]);

    if (!image) {
        return null;
    }

    const { id, position, opacity } = widget;

    return (
        <>
            <KonvaImage
                id={id}
                ref={imageRef}
                image={image}
                draggable={isSelected}
                opacity={opacity}
                onDragMove={onDragMove}
                onDragEnd={onDragEnd}
                onTransformStart={onTransformStart}
                onClick={onSelect}
                onTap={onSelect}
                onTransformEnd={onTransformEnd}
                {...position}
            />
            {isSelected && (
                <Transformer ref={transformerRef} flipEnabled={false} boundBoxFunc={boundBoxFunc} />
            )}
        </>
    );
};

import {
    createField,
    createFormConfiguration,
    createNItems,
    InferFormDataShape,
    lifecycleOptions,
} from 'markformythree';
import {
    RefContextEnum,
    ReplaceSecurityClassificationRequest,
    SecurityClassification,
} from '@mark43/rms-api';

import {
    SECURITY_CLASSIFICATION_HANDLING_INSTRUCTIONS,
    SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

const initialState: ReplaceSecurityClassificationRequest = {
    securityClassificationAttrId: 0,
    handlingInstructions: '',
};

const securityClassificationFormConfiguration = createFormConfiguration({
    securityClassificationAttrId: createField<number>({
        fieldName: SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
    }),
    handlingInstructions: createField<string>({
        fieldName: SECURITY_CLASSIFICATION_HANDLING_INSTRUCTIONS,
    }),
});

const securityClassificationsFormConfiguration = createFormConfiguration({
    securityClassifications: createNItems({
        fields: {
            ...securityClassificationFormConfiguration,
        },
    }),
});

export const editSecurityClassificationsCreateForm = {
    name: RefContextEnum.FORM_SECURITY_CLASSIFICATION_MODAL.name,
    context: RefContextEnum.FORM_SECURITY_CLASSIFICATION_MODAL.name,
    configuration: securityClassificationsFormConfiguration,
    lifecycle: lifecycleOptions.REGISTER_AND_UNREGISTER,
};

export type SecurityClassificationsFormConfiguration = typeof securityClassificationsFormConfiguration;
type SecurityClassificationsFormDataShape = InferFormDataShape<SecurityClassificationsFormConfiguration>;

type SecurityClassificationFormConfiguration = typeof securityClassificationFormConfiguration;
export type SecurityClassificationFormDataShape = InferFormDataShape<SecurityClassificationFormConfiguration>;

export const convertFromFormModel = (
    formModel: SecurityClassificationsFormDataShape
): ReplaceSecurityClassificationRequest | undefined => {
    const { securityClassifications = [] } = formModel;

    if (!securityClassifications.length) {
        return;
    }

    const {
        securityClassificationAttrId = initialState.securityClassificationAttrId,
        handlingInstructions = initialState.handlingInstructions,
    } = securityClassifications[0];

    return {
        securityClassificationAttrId,
        handlingInstructions,
    };
};

export function convertToFormModel(
    viewModels: SecurityClassification[]
): SecurityClassificationsFormDataShape {
    return {
        securityClassifications: viewModels.map((viewModel) => ({
            securityClassificationAttrId: viewModel.securityClassificationAttrId,
            handlingInstructions: viewModel.handlingInstructions,
        })),
    };
}

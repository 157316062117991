import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useToast } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { buttonTypes } from '../../../core/overlays/components/OverlayButton';
import { Button } from '../../../core/components/Button';
import { Tooltip } from '../../../core/components/tooltip';
import { AnalysisContext, LookerEventData } from './AnalysisContext';

const strings = componentStrings.analysis;

const CopyLinkButton: React.FC = () => {
    const [isDashboardPage, setIsDashboardPage] = useState(true);
    const toast = useToast();

    const { addLookerEventCallback, removeLookerEventCallback } = useContext(AnalysisContext) || {};

    const handlePageChanged = useCallback((eventData: LookerEventData) => {
        const pageType = eventData.page?.type;
        setIsDashboardPage(pageType === 'dashboard');
    }, []);

    const copyURLToClipboard = useCallback(() => {
        navigator.clipboard
            .writeText(window.location.href)
            .then(() =>
                toast({
                    status: 'default',
                    description: strings.CopyLinkButton.success,
                })
            )
            .catch((error) =>
                toast({
                    status: 'error',
                    description: strings.CopyLinkButton.failed(error),
                })
            );
    }, [toast]);

    useEffect(() => {
        if (addLookerEventCallback && removeLookerEventCallback) {
            addLookerEventCallback('page:changed', handlePageChanged);

            return () => {
                removeLookerEventCallback('page:changed', handlePageChanged);
            };
        }
        return;
    }, [addLookerEventCallback, removeLookerEventCallback, handlePageChanged]);

    return (
        <Tooltip
            content={strings.CopyLinkButton.disabledDescription}
            hidden={isDashboardPage}
            hasArrow
        >
            <Button
                className={buttonTypes.PRIMARY}
                isDisabled={!isDashboardPage}
                leadingVisual="Link"
                onClick={copyURLToClipboard}
            >
                {strings.CopyLinkButton.title}
            </Button>
        </Tooltip>
    );
};

export default CopyLinkButton;

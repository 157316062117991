import classNames from 'classnames';
import { keys, max } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import { searchSizes } from '~/client-common/configs/advancedSearchConfig';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import SearchSizeDropdownMenu from '../../../core/components/SearchSizeDropdownMenu';
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import TaskTable from '../../core/components/TaskTable';
import { tasksDashboardSearch, filterTaskDashboard, refreshTasksDashboard } from '../state/ui';
import { TaskDashboardSearchResultsParents } from '../../core/config';

const Header = styled.div`
    padding: 12px 20px;
    text-align: right;

    * {
        text-align: left;
    }
`;

const TableResultsSummaryContainer = styled(TableResultsSummary)`
    display: inline;
    margin-right: 10px;
    padding-top: 7px;
`;

const Footer = styled.div`
    padding: 20px 20px 20px 0;
    text-align: right;
`;

const TaskDashboardSearchResults = ({
    className,
    onPaginationClick,
    query: { from, size },
    results: resultsOrUndefined,
    totalCount,
    handleSizeChange,
    onSave,
    refreshTasksDashboard,
    hiddenColumnKeys,
    columnConfigurations,
    parentComponent = TaskDashboardSearchResultsParents.TASK_DASHBOARD,
}) => {
    // isLoading is passed down to the TaskTable so that checked boxes persist when a user submits a new query
    const isLoading = !resultsOrUndefined;
    const results = resultsOrUndefined || [];

    return (
        <>
            <Header>
                <TableResultsSummaryContainer
                    from={from}
                    to={from + keys(results).length}
                    totalResults={totalCount}
                    caption=""
                />
                <SearchSizeDropdownMenu
                    sizes={searchSizes}
                    onChange={(newSize) => {
                        handleSizeChange(newSize, parentComponent);
                    }}
                />
            </Header>
            <TaskTable
                className={classNames(className, tasksDashboardSearch.resultsContainerClassName)}
                tasks={results}
                includePagination={false}
                paginationOptions={{ pageSize: size }}
                onDeleteTask={refreshTasksDashboard}
                onSave={onSave}
                isLoading={isLoading}
                hiddenColumnKeys={hiddenColumnKeys}
                columnConfigurations={columnConfigurations}
            />
            {totalCount > 0 && (
                <Footer>
                    <Pagination
                        currentPage={max([1, fromSizeToPage(from + 1, size)])}
                        itemsPerPage={size}
                        itemCount={totalCount}
                        onClick={(lastPage, nextPage) =>
                            onPaginationClick(nextPage, size, parentComponent)
                        }
                        maxEdgeItems={1}
                    />
                </Footer>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onPaginationClick: (nextPage, size, taskDashboardSearchResultsParent) =>
        dispatch(
            filterTaskDashboard(
                { from: nextPage * size - size, size },
                taskDashboardSearchResultsParent
            )
        ),
    handleSizeChange: (size, taskDashboardSearchResultsParent) =>
        dispatch(filterTaskDashboard({ from: 0, size }, taskDashboardSearchResultsParent)),
    refreshTasksDashboard: () => dispatch(refreshTasksDashboard()),
});

export default compose(
    connect(
        createStructuredSelector({
            query: tasksDashboardSearch.selectors.currentQuerySelector,
            results: tasksDashboardSearch.selectors.currentResultsSelector,
            totalCount: tasksDashboardSearch.selectors.totalCountSelector,
        }),
        mapDispatchToProps
    )
)(TaskDashboardSearchResults);

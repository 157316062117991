import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
    interpolateErrorMessage,
    interpolateMessage,
} from '~/client-common/core/arbiter-utils/templateRunner';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import testIds from '../../../../core/testIds';
import { scrollToFieldWithinSidePanel } from '../../../../legacy-redux/helpers/navigationHelpers';
import { CustomLink as _CustomLink } from '../../components/links/Link';

type SidePanelErrorMessageProps = {
    errorMessage: string;
    scrollOffset?: number;
};

const CustomLink = styled(_CustomLink)`
    font-weight: unset;
`;

export const LegacyErrorMessage: React.FC<{ message: string }> = (props) => {
    const { message } = props;
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const errorMessage = interpolateErrorMessage(message, formatFieldByName);

    return errorMessage ? <>{errorMessage}</> : null;
};

const SidePanelErrorMessage: React.FC<SidePanelErrorMessageProps> = (props) => {
    const { errorMessage, scrollOffset } = props;

    const formatFieldByName = useSelector(formatFieldByNameSelector);

    return (
        <>
            {interpolateMessage(errorMessage, (fieldName: string, index: number) => (
                <CustomLink
                    data-test-id={testIds.CARD_ERROR_LINK}
                    // need key prop because interpolateMessage callback is a map function because
                    // errorMessage can contain more than 1 interpolated fieldname
                    key={index}
                    onClick={(e) => {
                        scrollToFieldWithinSidePanel({
                            clickedElement: e.currentTarget,
                            fieldName,
                            offset: scrollOffset,
                        });
                    }}
                >
                    {formatFieldByName(fieldName) || fieldName}
                </CustomLink>
            ))}
        </>
    );
};

export default SidePanelErrorMessage;

import { ProductModuleEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { openCreateReportModalWithReportingEventNumber } from '../../../../legacy-redux/actions/renSearchModalActions';
import { formatUserByIdSelector } from '../../../../legacy-redux/selectors/userSelectors';
import { openCreateReportModalFromCadEvent } from '../../../reports/core/state/ui/createReportModal';

export const FORM_NAME = 'RECENT_ARRESTS_FILTERS';
const itemsPendingReviewStrings = componentStrings.personalDashboard.ItemsPendingReview;

/**
 * Attempt to open a CAD ticket search result (on click).
 * Open the REN modal with the REN search form filled in
 * and disabled, allowing the user to open or create a report.
 * @param  {Object} cadTicket
 */
export function openCreateReportModal({ reportingEventNumber, cadAgencyEventNumber }) {
    return (dispatch, getState) => {
        const rmsEnabled = isProductModuleActiveSelector(getState())(
            ProductModuleEnum.REPORTS.name
        );
        if (!rmsEnabled) {
            return;
        }
        const recordsWithoutARenFlag = applicationSettingsSelector(getState())
            .RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;

        if (recordsWithoutARenFlag) {
            dispatch(
                openCreateReportModalFromCadEvent({
                    reportingEventNumber,
                    cadAgencyEventNumber,
                })
            );
        } else {
            dispatch(
                openCreateReportModalWithReportingEventNumber({
                    reportingEventNumber,
                })
            );
        }
    };
}

export const formatResponsiblePartySelector = createSelector(
    allRoleFormatsByRoleIdSelector,
    formatUserByIdSelector,
    formatAttributeByIdSelector,
    (allRoleFormatsByRoleId, formatUserById, formatAttributeById) => ({
        isAssignedInCase,
        assigneeRoleId,
        responsibleOfficers,
        assignedPersonnelUnitAttrId,
    }) => {
        let responsibleParty = '';
        if (isAssignedInCase) {
            if (assigneeRoleId) {
                responsibleParty = itemsPendingReviewStrings.assignedInvestigator(
                    allRoleFormatsByRoleId(assigneeRoleId).default
                );
            } else if (assignedPersonnelUnitAttrId) {
                responsibleParty = itemsPendingReviewStrings.assignedUnit(
                    formatAttributeById(assignedPersonnelUnitAttrId)
                );
            } else {
                responsibleParty = itemsPendingReviewStrings.primaryOfficer(
                    formatUserById(responsibleOfficers)
                );
            }
        } else if (responsibleOfficers) {
            responsibleParty = itemsPendingReviewStrings.primaryOfficer(
                formatUserById(responsibleOfficers)
            );
        }
        return responsibleParty;
    }
);

export * from './savedSearches';

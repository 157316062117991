import React from 'react';
import Promise from 'bluebird';
import { reduxForm } from 'redux-form-mark43';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { filter, flatMap, includes, map, compact } from 'lodash';
import { OperationTypeEnum } from '@mark43/rms-api';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import {
    allRoleFormatsByRoleIdSelector,
    rolesSelector,
} from '~/client-common/core/domain/roles/state/data';
import { formatEntityPermission } from '~/client-common/helpers/entityPermissionHelpers';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import stringsConfig from '~/client-common/core/strings';
import { TRANSIENT_KEY } from '~/client-common/helpers/dataHelpers';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_ENTITY_ADD_PERMISSIONS_LABEL } from '~/client-common/core/enums/universal/fields';
import Row from '../../../modules/core/components/Row';
import { currentUserDepartmentIdSelector } from '../../../modules/core/current-user/state/ui';
import OperationTypeSelect from '../../../modules/core/forms/components/selects/OperationTypeSelect';
import RoleSelect from '../../../modules/core/forms/components/selects/RoleSelect';
import NItems from '../../../modules/core/forms/components/NItems';
import { SimpleLoading } from '../core/Loading';
import Tabs, { Tab } from '../core/Tabs';
import SummaryList from '../summaries/SummaryList';
import _SummaryRow from '../summaries/SummaryRow';
import Fieldset from '../../../modules/core/forms/components/Fieldset';
import { updatePermissions, storeHandleSubmit } from '../../actions/entityPermissionsActions';
import { saveBoxFailure } from '../../actions/boxActions';
import {
    entityTypeSelector,
    entityIdSelector,
    entityPermissionsUiSelector,
    entityPermissionViewModelsSelector,
} from '../../selectors/entityPermissionsSelectors';
import {
    fieldValidator,
    submitValidator,
} from '../../validation/components/entityPermissionsValidators';
import {
    entityPermissionsFormFieldList,
    entityPermissionsFormWidths,
} from '../../configs/entityPermissionsConfig';
import EntityPermissionsMessage from './EntityPermissionsMessage';

const strings = stringsConfig.components.security.EntityPermissionsForm;

const context = {
    name: boxEnum.ENTITY_PERMISSIONS_MODAL,
};

const USER_FORM = 'USER_FORM';
const DEPARTMENT_FORM = 'DEPARTMENT_FORM';
const DEFAULT_DEPARTMENT_VALUE = { isExternalDepartment: true };

const TabWrapper = styled.div`
    padding-top: 10px;
`;

const SummaryRow = styled(_SummaryRow)`
    width: 100%;
`;

const EntityPermissionRow = ({
    isUserRoleRow,
    editable,
    undeletable,
    permission,
    deleteButton,
    excludeRoleIds,
}) => {
    return (
        <Row>
            {isUserRoleRow && (
                <RoleSelect
                    label={strings.users.labels.roleId}
                    width={entityPermissionsFormWidths.roleId}
                    disabled={!editable || undeletable}
                    clearable={false}
                    excludeRoleIds={excludeRoleIds}
                    {...permission.roleId}
                />
            )}
            {!isUserRoleRow && (
                <RoleSelect
                    label={strings.departments.labels.roleId}
                    width={entityPermissionsFormWidths.roleId}
                    clearable={false}
                    useExternalRoles={true}
                    noAsyncAction={true}
                    {...permission.roleId}
                />
            )}
            <OperationTypeSelect
                label={strings.labels.operationType}
                width={entityPermissionsFormWidths.operationType}
                disabled={!editable}
                clearable={false}
                dropdownOptionValues={
                    isUserRoleRow
                        ? [
                              OperationTypeEnum.READ.name,
                              OperationTypeEnum.SEARCH.name,
                              OperationTypeEnum.MANAGE.name,
                              OperationTypeEnum.DELETE.name,
                          ]
                        : [OperationTypeEnum.READ.name, OperationTypeEnum.SEARCH.name]
                }
                {...permission.operationType}
            />
            {
                // don't show the delete button when the
                // permission is undeletable
                editable && !undeletable && deleteButton
            }
        </Row>
    );
};

class EntityPermissionsForm extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            selectedTab: USER_FORM,
        };
        this.onSelectTab = this.onSelectTab.bind(this);
    }
    componentDidMount() {
        this.props.storeHandleSubmit(this.props.handleSubmit.bind(this));
    }

    onSelectTab(key) {
        this.setState(() => ({ selectedTab: key }));
    }

    render() {
        const {
            ui,
            viewModels,
            consortiumDepartmentLinksAvailable,
            fields: {
                externalDepartmentPermissions, // redux-form array with methods
                permissions, // redux-form array with methods
            },
            allRoleFormatsByRoleId,
            // Optional param that is relevant for `REPORT`s. BE-computed
            // `canManagePermissions` boolean on the `ReportStatusView` object.
            canManagePermissions,
            readOnlyTitleAndRoleIds,
            infoMessage,
            formatFieldByName,
        } = this.props;

        if (ui.loading) {
            return <SimpleLoading />;
        }

        const { selectedTab } = this.state;
        const showUserForm = selectedTab === USER_FORM;
        const isFormEditable = isUndefinedOrNull(canManagePermissions)
            ? ui.editable
            : canManagePermissions && ui.editable;

        const isItemUndeletable = (permission) =>
            permission[TRANSIENT_KEY]
                ? (viewModels[permission[TRANSIENT_KEY].value] || {}).undeletable
                : false;

        const renderRow = (permission, index, deleteButton) => {
            // make sure that we do not have `undefined` values in `readOnlyRoleIds`
            // as this prevents new rows for external departments from showing
            const readOnlyRoleIds = compact(flatMap(readOnlyTitleAndRoleIds, 'roleIds'));
            const readOnly = includes(readOnlyRoleIds, permission.roleId.value);
            // only show role ids that are not read only
            return readOnly ? null : (
                <EntityPermissionRow
                    editable={isFormEditable}
                    undeletable={isItemUndeletable(permission)}
                    permission={permission}
                    deleteButton={deleteButton}
                    isUserRoleRow={showUserForm}
                    excludeRoleIds={readOnlyRoleIds}
                />
            );
        };

        const userTabContent = showUserForm && (
            <NItems
                items={permissions}
                addText={formatFieldByName(DISPLAY_ONLY_ENTITY_ADD_PERMISSIONS_LABEL)}
                hideAddButtonOnEmptyItem={true}
                disabled={!isFormEditable}
                addItemOnEmpty={false}
                addItemOnDirty={false}
            >
                {renderRow}
            </NItems>
        );

        const departmentTabContent = !showUserForm && (
            <NItems
                items={externalDepartmentPermissions}
                addText={formatFieldByName(DISPLAY_ONLY_ENTITY_ADD_PERMISSIONS_LABEL)}
                hideAddButtonOnEmptyItem={false}
                disabled={!isFormEditable}
                defaultItemValue={DEFAULT_DEPARTMENT_VALUE}
                minimumNumberOfItems={0}
                addItemOnEmpty={false}
                addItemOnDirty={false}
            >
                {renderRow}
            </NItems>
        );

        // this is a section for read only summary views to capture information that is edited elsewhere,
        // like case assignee/supervisor and report owners
        const readOnlySummaryViews = map(readOnlyTitleAndRoleIds, (summary) => {
            const readOnlyPermissions = filter(permissions, (permission) =>
                includes(summary.roleIds, permission.roleId.value)
            );

            return readOnlyPermissions.length > 0 ? (
                <Fieldset key={summary.title} title={summary.title}>
                    <SummaryList>
                        {map(readOnlyPermissions, (permission) => (
                            <SummaryRow
                                key={`${permission.roleId.value}~${permission.operationType.value}`}
                                label={allRoleFormatsByRoleId(permission.roleId.value).default}
                            >
                                {formatEntityPermission(permission.operationType.value)}
                            </SummaryRow>
                        ))}
                    </SummaryList>
                </Fieldset>
            ) : null;
        });

        const finalUserTabContent = showUserForm && (
            <div>
                <EntityPermissionsMessage infoMessage={infoMessage} />
                {readOnlySummaryViews}
                <Fieldset title={readOnlySummaryViews.length > 0 && strings.otherPersonnel}>
                    {userTabContent}
                </Fieldset>
            </div>
        );

        // if a department is not part of a consortium we do not want to
        // show any tabs at all
        if (!consortiumDepartmentLinksAvailable) {
            return finalUserTabContent;
        }

        return (
            <Tabs selectedTab={this.state.selectedTab} onSelectTab={this.onSelectTab}>
                <Tab key={USER_FORM} title={strings.users.tabTitle}>
                    {showUserForm && <TabWrapper>{finalUserTabContent}</TabWrapper>}
                </Tab>
                <Tab key={DEPARTMENT_FORM} title={strings.departments.tabTitle}>
                    {!showUserForm && <TabWrapper>{departmentTabContent}</TabWrapper>}
                </Tab>
            </Tabs>
        );
    }
}

const reduxFormConfig = {
    form: 'entityPermissions',
    fields: entityPermissionsFormFieldList,
    // this validates individual fields only; the more complex validation that
    // looks at all fields is done below
    validate: fieldValidator,
};

const mapStateToProps = createStructuredSelector({
    entityId: entityIdSelector,
    entityType: entityTypeSelector,
    ui: entityPermissionsUiSelector,
    viewModels: entityPermissionViewModelsSelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    allRoleFormatsByRoleId: allRoleFormatsByRoleIdSelector,
    roles: rolesSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = (dispatch, props) => ({
    storeHandleSubmit: (handleSubmit) => dispatch(storeHandleSubmit(handleSubmit)),
    onSubmit: (data) => {
        const errorMessage = submitValidator(data, props);

        if (errorMessage) {
            dispatch(saveBoxFailure(context, errorMessage));
            return Promise.reject({ _error: errorMessage });
        }

        return dispatch(updatePermissions(props.entityType, props.entityId, data));
    },
});

EntityPermissionsForm = reduxForm(
    reduxFormConfig,
    mapStateToProps,
    mapDispatchToProps
)(EntityPermissionsForm);

// this extra connection is needed because redux-form as of v4.0.3 provides to
// `mapDispatchToProps` only the props in `reduxFormConfig`, but we need a
// custom prop there
export default connect(mapStateToProps, mapDispatchToProps)(EntityPermissionsForm);

import { LocationCaution } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const NEXUS_STATE_PROP = 'locationCautions';

const locationCautionsModule = createNormalizedModule<LocationCaution>({
    type: NEXUS_STATE_PROP,
});

// REDUCER
export default locationCautionsModule.reducerConfig;

import { ApprovalStatusEnum } from '@mark43/rms-api';

import { isEmpty } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';

export const formatStrings = {
    approvalStatusToDisplayText(reportApprovalStatus) {
        switch (reportApprovalStatus) {
            case ApprovalStatusEnum.DRAFT.name:
                return componentStrings.approvalStatuses.draft;
            case ApprovalStatusEnum.REJECTED.name:
                return componentStrings.approvalStatuses.rejected;
            case ApprovalStatusEnum.SUBMITTED.name:
                return componentStrings.approvalStatuses.submitted;
            case ApprovalStatusEnum.APPROVED.name:
            case ApprovalStatusEnum.APPROVED2.name:
            case ApprovalStatusEnum.APPROVED3.name:
            case ApprovalStatusEnum.APPROVED4.name:
                return componentStrings.approvalStatuses.approvedBySupervisor;
            case ApprovalStatusEnum.COMPLETED.name:
                return componentStrings.approvalStatuses.approved;
            default:
                return '';
        }
    },
    formatApprovalStatusForSidebar(
        reportApprovalStatus,
        reportHasCommentsHistory,
        clientApprovalStatusFieldDisplayName,
        cardTitle
    ) {
        if (
            reportApprovalStatus === ApprovalStatusEnum.DRAFT.name &&
            !reportHasCommentsHistory &&
            !clientApprovalStatusFieldDisplayName
        ) {
            return componentStrings.reports.ReportSidebar.reportInDraft;
        }

        const approvalStatusDisplayText = clientApprovalStatusFieldDisplayName;

        if (isEmpty(approvalStatusDisplayText)) {
            return componentStrings.reports.ReportSidebar.retrievingReportState;
        }
        return `${approvalStatusDisplayText} - ${cardTitle}`;
    },
};

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get, filter, first, pick, map, chain, omit, uniq, noop, isArray } from 'lodash';

import { createSelector } from 'reselect';
import { itemProfilesSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { itemTypeSpecificViewModelByIdSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import { vehiclesSelector } from '~/client-common/core/domain/vehicles/state/data';
import { firearmsSelector } from '~/client-common/core/domain/firearms/state/data';
import { propertyStatusAttrIdIsInPoliceCustodySelector } from '~/client-common/core/domain/property-statuses/state/data';
import { propertyStatusViewModelByIdSelector } from '~/client-common/core/domain/property-statuses/state/ui';
import { nameItemLinksByItemProfileIdSelector } from '~/client-common/core/domain/name-item-links/state/data';
import { itemIdentifiersByItemIdSelector } from '~/client-common/core/domain/item-identifiers/state/data';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { currentDepartmentCurrencyFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { vehiclesEnabledSelector } from '~/client-common/core/domain/evidence-department-config/state/data';

import validationStrings from '~/client-common/core/strings/validationStrings';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { assignViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { otherUserId } from '~/client-common/helpers/userHelpers';

import { propertyRecordViewModelProps } from '../../../../../legacy-redux/helpers/propertyRecordHelpers';
import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import { createModalSelector } from '../../../../core/box/state/ui';
import itemPopoverForm from '../../../../reports/core/state/forms/itemPopoverForm';
import ownerNItemsFieldViewModels from '../../../../reports/core/state/forms/ownerNItemsFieldViewModels';
import itemIdentifierNItemsFieldViewModels from '../../../../reports/core/state/forms/itemIdentifierNItemsFieldViewModels';
import {
    openBox,
    closeBox,
    saveBoxStart,
    saveBoxSuccess,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import { propertyStatusForItemToUseInEvidenceSelector } from '../../../../reports/custodial-property-summary/state/ui';
import evidenceItemSplitForm, {
    childItemTypeAttrIdSelector,
    parentItemProfileIdSelector,
    parentItemTypeAttrIdSelector,
} from '../forms/evidenceItemSplitForm';
import { splitItem } from '../data';

const defaultPanelErrorMessage = validationStrings.panel.generic;
const { itemType } = globalAttributes;
const itemPopoverContext = {
    name: boxEnum.ITEM_SPLIT_ITEM_POPOVER,
};
const itemSplitSidePanelContext = {
    name: boxEnum.ITEM_SPLIT_SIDE_PANEL,
};
const itemSplitConfirmationContext = {
    name: boxEnum.ITEM_SPLIT_CONFIRMATION_SIDE_PANEL,
};

const INITIALIZE_UI = 'item-split/INITIALIZE_UI';
const RESET_UI = 'item-split/RESET_UI';

function initializeUi(payload) {
    return {
        type: INITIALIZE_UI,
        payload,
    };
}

export function resetUi() {
    return { type: RESET_UI };
}

/**
 * Custom ItemPopover submit handler. Submits the ItemPopover form, no API call,
 *   and opens ItemSplitSidePanel.
 * @return {Promise}
 */
export function itemSplitItemPopoverSubmit() {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(
            itemPopoverForm.actionCreators.submit((formModel) => {
                const {
                    itemTypeAttrId,
                    policeCustodyPropertyStatusAttrIds,
                    nonPoliceCustodyPropertyStatusAttrIds,
                } = formModel;
                const parentItemProfileId = itemProfileIdSelector(state);
                const parentItemProfile = propertyProfileByItemIdSelector(state)(
                    parentItemProfileId
                );
                const parentItemTypeAttrId = get(parentItemProfile, 'itemTypeAttrId');
                // only one property status selectable
                const propertyStatusAttrId =
                    first(policeCustodyPropertyStatusAttrIds) ||
                    first(nonPoliceCustodyPropertyStatusAttrIds);
                const parentPropertyStatus = propertyStatusForItemToUseInEvidenceSelector(state)(
                    parentItemProfileId
                );
                const propertyStatusGlobalAttrId = parentAttributeIdByAttributeIdSelector(state)(
                    propertyStatusAttrId
                );

                dispatch(
                    evidenceItemSplitForm.actionCreators.change(
                        parentItemProfile,
                        parentPropertyStatus
                    )
                );

                dispatch(
                    initializeUi({
                        parentItemProfileId,
                        parentItemTypeAttrId,
                        childItemTypeAttrId: itemTypeAttrId,
                        propertyStatusGlobalAttrId,
                    })
                );
                dispatch(copyItemProfileData());
                dispatch(openBox(itemSplitSidePanelContext));
                dispatch(closeBox(itemPopoverContext));
                return true;
            })
        ).catch(() => {
            // validation error
            dispatch(saveBoxFailure(itemPopoverContext));
        });
    };
}

/**
 * Initiate Evidence Item Split. Open and use the ItemPopover to select
 *   the item type for new split item.
 * @param  {number}  itemProfileId  Contexted item profile id
 * @return {Promise}
 */
export function openItemSplitPopoverForm(itemProfileId) {
    return (dispatch, getState) => {
        const state = getState();

        // Find item's property status and pre-select it in the popover, which depends on whether
        // its parent attribute is IN_POLICE_CUSTODY
        const propertyStatusAttrId = get(
            propertyStatusForItemToUseInEvidenceSelector(state)(itemProfileId),
            'propertyStatusAttrId'
        );
        const propertyStatusAttrIdIsInPoliceCustody = propertyStatusAttrIdIsInPoliceCustodySelector(
            state
        )(propertyStatusAttrId);

        dispatch(itemPopoverForm.actionCreators.reset());
        dispatch(
            itemPopoverForm.actionCreators.changePath(
                propertyStatusAttrIdIsInPoliceCustody
                    ? 'policeCustodyPropertyStatusAttrIds'
                    : 'nonPoliceCustodyPropertyStatusAttrIds',
                [propertyStatusAttrId]
            )
        );
        dispatch(itemPopoverForm.actionCreators.validate());

        dispatch(
            openBox(itemPopoverContext, {
                itemProfileId,
                requirePoliceCustodyPropertyStatus: false,
                includePoliceCustodyStatuses: true,
                includeItemStatuses: true,
                multiplePoliceCustodyPropertyStatuses: false,
                propertyStatusesDisabled: true,
                visibleItemTypeAttrIds: [],
                hiddenItemTypeAttrIds: !vehiclesEnabledSelector(state) ? [itemType.vehicle] : [],
            })
        );
    };
}

/**
 * Copy Item Profile data from Parent to Child in Item Split
 * @param  {boolean}   validate validate after copy
 * @return {undefined}
 */
export function copyItemProfileData(validate = false) {
    return (dispatch, getState) => {
        const state = getState();
        const itemProfileId = parentItemProfileIdSelector(state);

        // Item Profile
        const {
            parentItemProfile,
            childItemProfile,
        } = evidenceItemSplitForm.selectors.formModelSelector(state);
        const parentItemTypeAttrId = parentItemTypeAttrIdSelector(state);
        const childItemTypeAttrId = childItemTypeAttrIdSelector(state);
        const copiedParentItemProfile =
            parentItemTypeAttrId === childItemTypeAttrId
                ? parentItemProfile
                : pick(parentItemProfile, 'description');

        // Item Identifiers
        const identifierFieldKeys = map(itemIdentifierNItemsFieldViewModels.fields, 'key');
        const identifiersRaw = itemIdentifiersByItemIdSelector(state)(itemProfileId);
        const itemIdentifiers = map(identifiersRaw, (identifier) =>
            pick(identifier, identifierFieldKeys)
        );

        // Owners & Claimants
        const nameItemLinkFieldKeys = [
            ...map(ownerNItemsFieldViewModels.fields, 'key'),
            'entityType',
            'linkType',
            'nameId',
        ];
        const nameItemLinksRaw = nameItemLinksByItemProfileIdSelector(state)(itemProfileId);
        const nameItemLinks = map(nameItemLinksRaw, (link) => ({
            ...pick(link, nameItemLinkFieldKeys),
            // for display logic in OwnerClaimantNItems
            isOrg: link.entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name,
        }));
        const owners = filter(nameItemLinks, { linkType: LinkTypesEnum.OWNED_BY });
        const claimants = filter(nameItemLinks, { linkType: LinkTypesEnum.CLAIMED_BY });

        const itemProfileCopy = {
            ...childItemProfile,
            ...copiedParentItemProfile,
            itemIdentifiers,
            owners,
            claimants,
        };
        dispatch(
            evidenceItemSplitForm.actionCreators.changePath('childItemProfile', itemProfileCopy)
        );
        if (validate) {
            dispatch(validateItemProfiles());
        }
    };
}

/**
 * Open ItemSplitConfirmationSidePanel from ItemSplitSidePanel
 * @return {undefined}
 */
export function openConfirmation() {
    return (dispatch) => {
        dispatch(openBox(itemSplitConfirmationContext));
    };
}

/**
 * Direct user back to the Item Split Form when cancelling Item Split Confirmation Side Panel
 * @return {undefined}
 */
export function cancelConfirmation() {
    return (dispatch) => {
        dispatch(closeBox(itemSplitConfirmationContext));
        dispatch(openBox(itemSplitSidePanelContext));
    };
}

/**
 * Save Item Split and close Confirmation Side Panel
 * @return {undefined}
 */
export function submitItemSplitForm() {
    return (dispatch, getState) => {
        dispatch(saveBoxStart(itemSplitConfirmationContext));

        return dispatch(
            evidenceItemSplitForm.actionCreators.submit(
                (formModel) => {
                    const state = getState();
                    const itemId = parentItemProfileIdSelector(state);
                    const propertyStatus = propertyStatusForItemToUseInEvidenceSelector(state)(
                        itemId
                    );
                    // server will be creating new item profiles and new property statuses
                    const propertyStatusProps = omit(propertyStatus, ['id', 'itemProfileId']);
                    // the server does not accept -1 for this id, so we have to null
                    // it out when the recovering officer is "other"
                    if (propertyStatusProps.recoveredByOfficerId === otherUserId) {
                        propertyStatusProps.recoveredByOfficerId = null;
                    }
                    const dataFromState = {
                        parentItemIdentifiers: itemIdentifiersByItemIdSelector(state)(itemId),
                        parentNameItemLinks: nameItemLinksByItemProfileIdSelector(state)(itemId),
                        parentItemTypeAttrId: parentItemTypeAttrIdSelector(state),
                        childItemTypeAttrId: childItemTypeAttrIdSelector(state),
                        propertyStatusProps,
                    };
                    const items = evidenceItemSplitForm.convertFromFormModel(
                        formModel,
                        dataFromState
                    );

                    return dispatch(splitItem(itemId, items))
                        .then(() => {
                            dispatch(saveBoxSuccess(itemSplitConfirmationContext));
                            dispatch(closeBox(itemSplitConfirmationContext));

                            /* custodial report uses RRFNItems to display the items. choosing hard
                            refresh over manually updating form model (remove old item and insert
                            new items) and cleaning out all the stale data. */
                            window.location.reload();
                        })
                        .catch((error) =>
                            dispatch(saveBoxFailure(itemSplitConfirmationContext, error.message))
                        );
                },
                { forceSubmit: true }
            )
            // catch and ignore any validation errors from form submit
            // validation is handled during each step in the form
        ).catch(noop);
    };
}

/**
 * Validate parent and child item profiles. return string of error messages.
 * @type {String[]}
 */
export function validateItemProfiles() {
    return (dispatch, getState) => {
        const parentDescriptionIsValid = evidenceItemSplitForm.selectors.formPathIsValidSelector(
            getState()
        )('parentItemProfile.description');
        const validationResult = dispatch(
            evidenceItemSplitForm.actionCreators.validate('childItemProfile')
        );
        const childItemErrors = isArray(validationResult) ? validationResult : [];
        const parentItemErrors = parentDescriptionIsValid ? [] : [defaultPanelErrorMessage];

        return uniq([...childItemErrors, ...parentItemErrors]);
    };
}

/**
 * Validate parent and child property statuses. return string of error messages.
 * @type {String[]}
 */
export function validatePropertyStatuses() {
    return (dispatch) => {
        const childPropertyStatusErrors = dispatch(
            evidenceItemSplitForm.actionCreators.validate('childPropertyStatus')
        );

        return uniq(childPropertyStatusErrors);
    };
}

/**
 * The item profile id currently being edited in the item popover. `undefined`
 *   for a new item. Couldn't import from reports/core/state/ui because of
 *   dependency error.
 * @type {number|undefined}
 */
const itemProfileIdSelector = createModalSelector(itemPopoverContext, 'itemProfileId');

/**
 * Item profile for item id. Return firearm or vehicle profiles accordingly.
 * @type {Object|undefined}
 */
export const propertyProfileByItemIdSelector = createSelector(
    itemProfilesSelector,
    firearmsSelector,
    vehiclesSelector,
    (itemProfiles, firearms, vehicles) => (itemId) =>
        !!itemId && (firearms[itemId] || vehicles[itemId] || itemProfiles[itemId])
);

/**
 * View model summaries for Item Split Confirmation. Summary components accepts view models,
 *   Combine form inputs with existing view models to create view models that can be displayed.
 * @type {Object}
 */
export const itemSplitSummaryViewModelsSelector = createSelector(
    parentItemProfileIdSelector,
    itemTypeSpecificViewModelByIdSelector,
    propertyStatusForItemToUseInEvidenceSelector,
    propertyStatusViewModelByIdSelector,
    formatAttributeByIdSelector,
    currentDepartmentCurrencyFormatterSelector,
    evidenceItemSplitForm.selectors.formModelSelector,
    (
        parentItemProfileId,
        itemTypeSpecificViewModelById,
        propertyStatusForItem,
        propertyStatusViewModelById,
        formatAttributeById,
        currencyFormatter,
        formModel
    ) => {
        const { formatCurrency } = currencyFormatter;
        const itemViewModel = itemTypeSpecificViewModelById(parentItemProfileId);
        const propertyStatusId = get(propertyStatusForItem(parentItemProfileId), 'id');
        const propertyStatusViewModel = propertyStatusViewModelById(propertyStatusId);
        const {
            parentItemProfile,
            parentPropertyStatus,
            childItemProfile,
            childPropertyStatus = {},
        } = formModel;

        // merge parent item profile form inputs with view model, only description can change
        const existingItemViewModel = {
            ...itemViewModel,
            description: parentItemProfile.description,
        };

        // merge parent property status form inputs with view model
        // only quantity and declaredValue subject to change
        const existingPropertyStatusViewModelProps = {
            declaredValue:
                parentPropertyStatus.declaredValue &&
                formatCurrency(parentPropertyStatus.declaredValue),
            quantity: parentPropertyStatus.quantity,
        };
        const existingPropertyStatus = assignViewModelProperties(
            propertyStatusViewModel,
            existingPropertyStatusViewModelProps
        );

        // child item profile view model props for summary display
        const newItemProfileViewModelProps = chain(childItemProfile)
            .pick(propertyRecordViewModelProps)
            .mapValues((attrId) => formatAttributeById(attrId))
            .value();
        const newItemViewModel = assignViewModelProperties(
            childItemProfile,
            newItemProfileViewModelProps
        );

        // merge child property status form inputs with view model
        // only quantity, declaredValue, and measurementUnitsAttrId subject to change
        const newPropertyStatusViewModelProps = {
            declaredValue:
                childPropertyStatus.declaredValue &&
                formatCurrency(childPropertyStatus.declaredValue),
            measurementUnitsAttrId: formatAttributeById(childPropertyStatus.measurementUnitsAttrId),
            quantity: childPropertyStatus.quantity,
        };
        const newPropertyStatus = assignViewModelProperties(
            propertyStatusViewModel,
            newPropertyStatusViewModelProps
        );

        return {
            existingItem: {
                itemProfile: existingItemViewModel,
                propertyStatus: existingPropertyStatus,
            },
            newItem: {
                itemProfile: newItemViewModel,
                propertyStatus: newPropertyStatus,
            },
        };
    }
);

export default makeResettable(
    [ENTER_NEW_ROUTE, RESET_UI],
    function itemSplitUiReducer(
        state = {
            parentItemProfileId: null,
            parentItemTypeAttrId: null,
            childItemTypeAttrId: null,
            propertyStatusGlobalAttrId: null,
        },
        action
    ) {
        switch (action.type) {
            case INITIALIZE_UI:
                return action.payload;
            default:
                return state;
        }
    }
);

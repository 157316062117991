import { LinkTypesEnum } from '@mark43/rms-api';
import React, { useRef } from 'react';
import { get, join, last, sortBy } from 'lodash';
import { Text } from 'arc';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import { Tile } from '../../components/Tile';
import { assignToRefs } from '../../utils/eventHelpers';
import { useSubPremiseFormatters } from '../../locations/hooks/useSubPremiseFormatters';

const OrganizationPillSmall = React.forwardRef(
    ({ className, disabled, onClick, testId, organization }, forwardedRef) => {
        const ref = useRef(null);
        const { name, organizationTypeAttrId, involvedLocations } = organization;
        const locationToShow = get(
            last(
                sortBy(involvedLocations, [
                    // First sort by locations first (not mailing address)
                    (location) => (location.type === LinkTypesEnum.BUSINESS_LOCATION ? 1 : -1),
                    // Next, sort by most recently created
                    'rmsEventId',
                ])
            ),
            'locationAddress'
        );
        const { buildElasticLocationLines } = useSubPremiseFormatters();

        const locationText = locationToShow
            ? join(buildElasticLocationLines(locationToShow), ', ')
            : null;

        const handleClick = () => {
            if (onClick) {
                onClick(ref);
            }
        };

        return (
            <Tile
                className={className}
                testId={testId}
                disabled={disabled}
                onClick={disabled ? undefined : handleClick}
                ref={assignToRefs([ref, forwardedRef])}
                icon="Organization"
                title={
                    <>
                        {name}
                        {organizationTypeAttrId && (
                            <ConnectedFormattedAttribute attributeId={organizationTypeAttrId}>
                                {(formattedAttribute) => (
                                    <Text as="span" color="tertiary" isItalic>
                                        {` (${formattedAttribute})`}
                                    </Text>
                                )}
                            </ConnectedFormattedAttribute>
                        )}
                    </>
                }
                description={locationText}
            />
        );
    }
);

export default OrganizationPillSmall;

import React from 'react';
import styled from 'styled-components';
import { cssVar, Tag as _Tag } from 'arc';

const SubPremiseSearchInstructionWrapper = styled.ul`
    background-color: ${cssVar('arc.colors.surface.secondary')};
    border-radius: 5px;
    list-style-position: inside;
    padding: 12px 12px 12px 30px;
    text-indent: -22px;
`;

const Tag = styled(_Tag)`
    font-weight: 600;
    text-indent: 0px;
`;

const SubPremiseSearchInstruction = () => {
    return (
        <SubPremiseSearchInstructionWrapper>
            <li>
                Use a pound <Tag>#</Tag> to include sub premises (i.e. building, apartment) in the
                search, like <Tag>#Apt</Tag> or <Tag>#423A</Tag>
            </li>
            <li>
                Optionally use a colon <Tag>:</Tag> to search a specific sub premise category for a
                value, like <Tag>#Building:12</Tag>
            </li>
        </SubPremiseSearchInstructionWrapper>
    );
};

export default SubPremiseSearchInstruction;

import React from 'react';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';

const strings = componentStrings.warrants.warrant.WarrantClipsSendingModal;

const context = {
    name: boxEnum.WARRANT_CLIPS_SENDING_MODAL,
};

export default function WarrantClipsSendingModal() {
    return (
        <Modal context={context} hideFooter={true}>
            <div className="mark43-loading-modal-content">
                <SimpleLoading />
                <div>{strings.sending}</div>
            </div>
        </Modal>
    );
}

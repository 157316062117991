import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { DISPLAY_CASE_SUPERVISORS } from '~/client-common/core/enums/universal/fields';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { FIELDSET, ROLE } = fieldTypeClientEnum;
const strings = componentStrings.cases.myCases.MyCasesSearchPersonnelFieldset;

/**
 * Fieldset view model.
 */
export default {
    type: FIELDSET,
    key: 'personnel',
    title: strings.title,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'supervisorRoleIds',
            type: ROLE,
            fieldName: DISPLAY_CASE_SUPERVISORS,
        },
        {
            key: 'hasSupervisor',
            label: 'Has Supervisor',
        },
    ]),
};

import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../core/overlays/components/Modal';
import { useOverlayStore } from '../../core/overlays/hooks/useOverlayStore';

const strings = componentStrings.compliance.ComplianceResubmissionExportDownloadButton;

type ComplianceResubmissionOverlayIds = keyof Pick<
    typeof overlayIdEnum,
    'COMPLIANCE_RESUBMISSION_EXPORT_MODAL'
>;

type UseComplianceResubmissionExportModalProps = {
    overlayId: ComplianceResubmissionOverlayIds;
    exportHandler: () => void;
};

export function useOpenComplianceResubmissionExportModal(
    props: UseComplianceResubmissionExportModalProps
) {
    const { overlayId } = props;

    const overlayStore = useOverlayStore();
    return useCallback(() => overlayStore.open(overlayId), [overlayId, overlayStore]);
}

const ComplianceResubmissionExportModal: React.FC<UseComplianceResubmissionExportModalProps> = (
    props
) => {
    const { exportHandler } = props;

    const saveHandler = useCallback(() => {
        exportHandler();
    }, [exportHandler]);

    return (
        <Modal
            id={overlayIdEnum.COMPLIANCE_RESUBMISSION_EXPORT_MODAL}
            defaultErrorMessage={strings.modal.errorMessage}
            okText={strings.modal.confirm}
            title={strings.modal.title}
            onSave={saveHandler}
        >
            {strings.modal.message}
        </Modal>
    );
};

export default withRouter(ComplianceResubmissionExportModal);

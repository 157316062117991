import React, { useContext } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { map } from 'lodash';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { SelectedRowsContext, KebabMenuContext } from '../../contexts';
import { removeContentsFromFolder } from '../../state/data/folders';
import { KebabMenuActions } from '../KebabMenu';

const strings = componentStrings.cases.core.KebabMenu;

type RemoveFromFolderMenuItemPropsT = {
    initiatedAction: KebabMenuActions;
    disabled: boolean;
} & WithRouterProps;

const RemoveFromFolderMenuItem: React.FC<RemoveFromFolderMenuItemPropsT> = ({
    initiatedAction,
    disabled,
    params,
}) => {
    const { selectedRows, setSelectedRows } = useContext(SelectedRowsContext);
    const { setInitiatedAction } = useContext(KebabMenuContext);
    const dispatch = useDispatch();

    const handleOnClick = () => {
        const caseContentIds = map(selectedRows, 'rowId');

        // Selecting rows between CASE_NOTE and ATTACHMENTS is unsupported.
        // So we can do this for now.

        if (params.folderId) {
            dispatch(removeContentsFromFolder(Number(params.folderId), caseContentIds));
        }
        if (setSelectedRows) {
            setSelectedRows([]);
        }

        if (setInitiatedAction) {
            setInitiatedAction(initiatedAction);
        }
    };

    if (disabled) {
        return (
            <div className={classNames('dropdown-menu-option', 'disabled')}>
                {strings.removeFromFolder}
            </div>
        );
    }

    return (
        <div className={classNames('dropdown-menu-option')} onClick={handleOnClick}>
            {strings.removeFromFolder}
        </div>
    );
};

export default withRouter(RemoveFromFolderMenuItem);

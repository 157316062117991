let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Offense Resource',
        methods: {
            getOffensesForEventId(eventId) {
                return req({
                    method: 'GET',
                    url: `offense/event/${eventId}`,
                });
            },
            getArrestsForOffenseReportId(reportId) {
                return req({
                    method: 'GET',
                    url: `offense/${reportId}/arrest_links`,
                });
            },
            getOffense(id) {
                return req({
                    method: 'GET',
                    url: `offense/${id}`,
                });
            },
            setAttributes({ offenseId, attributes }) {
                return req({
                    method: 'PUT',
                    url: `offense/${offenseId}/attributes`,
                    data: attributes,
                });
            },
            updateOffenseInvolvedChildren({ offenseId, offenseInvolvedChildren }) {
                return req({
                    method: 'PUT',
                    url: `offense/${offenseId}/offense_involved_children`,
                    data: offenseInvolvedChildren,
                });
            },
            createOffense(offense) {
                return req({
                    method: 'POST',
                    url: 'offense',
                    data: offense,
                });
            },
            upsertOffense(offense) {
                return req({
                    method: 'PUT',
                    url: `offense/${offense.id}`,
                    data: offense,
                });
            },
            bulkUpsertOffenses(offenses) {
                return req({
                    method: 'PUT',
                    url: 'offense/bulk',
                    data: offenses,
                });
            },
            deleteOffense(offenseId) {
                return req({
                    method: 'DELETE',
                    url: `offense/${offenseId}`,
                });
            },
            deleteOffenseUponChangingOffenseType(offenseId) {
                return req({
                    method: 'DELETE',
                    url: `offense/offense_type/${offenseId}`,
                });
            },
            createStubOffense(stubOffenseCreationRequest) {
                return req({
                    method: 'POST',
                    url: 'offense/stub',
                    data: stubOffenseCreationRequest,
                });
            },
            duplicateOffense(offenseId) {
                return req({
                    method: 'POST',
                    url: `offense/${offenseId}/duplicate`,
                });
            },
            replaceOffenseSubCrimeLinks({ offenseId, subCrimeLinks }) {
                return req({
                    method: 'PUT',
                    url: `offense/${offenseId}/offense_sub_crimes`,
                    data: subCrimeLinks,
                });
            },
        },
    });
}

/**
 * Resources for Offenses. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

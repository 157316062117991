import { createNItems, createFormConfiguration, formEvents } from 'markformythree';
import { filter } from 'lodash';
import * as yup from 'yup';
import componentStrings from '~/client-common/core/strings/componentStrings';

export const BULK_UPDATE_NOTIFICATIONS_FORM = 'bulkUpdateNotificationsForm';

export const initialState = {
    batchUpdateNotificationRequests: [
        {
            notificationType: '',
            emailDelivery: null,
            inAppDelivery: null,
        },
    ],
};

export const configuration = createFormConfiguration({
    batchUpdateNotificationRequests: createNItems({
        fields: {
            notificationType: '',
            emailDelivery: null,
            inAppDelivery: null,
        },
    }),
});

const strings = componentStrings.admin.users.dashboard.results.bulkUpdateModal;

const checkForUniqueValue = (formEntries, value) => {
    return (
        filter(formEntries, {
            notificationType: value,
        }).length < 2
    );
};

export const validationSchema = ({ model }) => {
    return yup.object().shape({
        batchUpdateNotificationRequests: yup.array().of(
            yup.object().shape({
                inAppDelivery: yup.boolean().test({
                    name: 'inAppDelivery-type-required',
                    test(value) {
                        return value !== null && value !== undefined;
                    },
                    message: strings.inAppDeliveryRadioInputError,
                }),
                emailDelivery: yup.boolean().test({
                    name: 'emailDelivery-type-required',
                    test(value) {
                        return value !== null && value !== undefined;
                    },
                    message: strings.emailDeliveryRadioInputError,
                }),
                notificationType: yup.string().test({
                    name: 'operation-type-required',
                    test(value) {
                        return (
                            value !== '' &&
                            value !== undefined &&
                            checkForUniqueValue(model.batchUpdateNotificationRequests, value)
                        );
                    },
                    message: strings.notificationTypeInputError,
                }),
            })
        ),
    });
};

export const validationEvents = [
    {
        eventType: formEvents.INPUT_CHANGE,
    },
    {
        eventType: formEvents.N_ITEM_ADDED,
    },
    {
        eventType: formEvents.INPUT_BLUR,
    },
    {
        eventType: formEvents.MODEL_CHANGE,
    },
    {
        eventType: formEvents.N_ITEM_REMOVED,
    },
];

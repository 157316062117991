import {
    CreateDispositionEventView,
    DispositionEventTypeEnum,
    DispositionApprovalLevelEnum,
    DispositionApprovalLevelEnumType,
    RefContextEnum,
} from '@mark43/evidence-api';
import {
    _Form,
    MFTFieldConfigurationDiscriminator,
    MFTFormConfigurationDescriminator,
} from 'markformythree';
import { convertAmountAndUnitToPeriod } from '~/client-common/core/dates/utils/dateRangeHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import DispositionForm from './disposition/DispositionForm';
import HoldItemForm from './disposition/HoldItemForm';
import ReleaseForm from './disposition/ReleaseForm';
import RequestDispositionForm from './disposition/RequestDispositionForm';
import SecondaryApprovalForm from './disposition/SecondaryApprovalForm';
import SecondaryRejectDispositionForm from './disposition/SecondaryRejectDispositionForm';
import ResetRetentionPolicyDispositionForm from './disposition/ResetRetentionPolicyDispositionForm';

const {
    DISPOSITION_APPROVED,
    MANUAL_REQUEST,
    PRIMARY_DISPOSITION_APPROVED,
    PRIMARY_RELEASE_APPROVED,
    REJECTED_HOLD,
    RELEASE_APPROVED,
    SECONDARY_REJECT,
    RETENTION_PERIOD_STARTED,
} = DispositionEventTypeEnum;

const {
    FORM_EVIDENCE_DISPOSITION_HOLD,
    FORM_EVIDENCE_DISPOSITION_PRIMARY_APPROVAL_FOR_DISPOSITION,
    FORM_EVIDENCE_DISPOSITION_PRIMARY_APPROVAL_FOR_RELEASE,
    FORM_EVIDENCE_DISPOSITION_REQUEST,
    FORM_EVIDENCE_DISPOSITION_SECONDARY_APPROVAL,
    FORM_EVIDENCE_DISPOSITION_SECONDARY_REJECTION,
    FORM_EVIDENCE_RESET_RETENTION_POLICY,
} = RefContextEnum;

const strings = componentStrings.reports.custodialPropertySummary.DispositionActionSidePanel;

/**
 * All action types that are supported by the disposition action dropdown menu.
 */
export const ACTION_TYPES = {
    PRIMARY_APPROVE_DISPOSITION: 'PRIMARY_APPROVE_DISPOSITION',
    PRIMARY_APPROVE_RELEASE: 'PRIMARY_APPROVE_RELEASE',
    PRIMARY_REJECT_HOLD: 'PRIMARY_REJECT_HOLD',
    REQUEST_DISPOSITION: 'REQUEST_DISPOSITION',
    SECONDARY_APPROVE_DISPOSITION: 'SECONDARY_APPROVE_DISPOSITION',
    SECONDARY_APPROVE_RELEASE: 'SECONDARY_APPROVE_RELEASE',
    SECONDARY_REJECT: 'SECONDARY_REJECT',
    RESET_RETENTION_POLICY: 'RESET_RETENTION_POLICY',
} as const;

export type DispositionActionType = typeof ACTION_TYPES[keyof typeof ACTION_TYPES];

/**
 * Convert the disposition action form data to an object. That object is meant to be used in an API request outside of
 * this function.
 *
 * The form is any of the disposition action forms from `propsByActionType`.
 */
export const createDispositionEvent = (
    actionType: DispositionActionType,
    form: _Form<
        {
            comment?: string & MFTFieldConfigurationDiscriminator;
            terminalDispositionApprovalLevel?: DispositionApprovalLevelEnumType &
                MFTFieldConfigurationDiscriminator;
            specificDate?: string & MFTFieldConfigurationDiscriminator;
            periodUnit?: string & MFTFieldConfigurationDiscriminator;
            periodAmount?: string & MFTFieldConfigurationDiscriminator;
            isSpecificDate?: boolean & MFTFieldConfigurationDiscriminator;
            isDuration?: boolean & MFTFieldConfigurationDiscriminator;
        } & MFTFormConfigurationDescriminator
    >,
    { dispositionApprovalLevel }: { dispositionApprovalLevel: DispositionApprovalLevelEnumType }
): Partial<CreateDispositionEventView> => {
    const {
        comment,
        terminalDispositionApprovalLevel,
        specificDate,
        periodUnit,
        periodAmount,
    } = form.getState().model;

    const manualRetentionPeriod =
        periodUnit === 'D' || periodUnit === 'M' || periodUnit === 'Y'
            ? convertAmountAndUnitToPeriod(periodAmount || '', periodUnit)
            : '';

    const options = {
        [ACTION_TYPES.PRIMARY_REJECT_HOLD]: {
            dispositionEventType: REJECTED_HOLD.name,
            manualRetentionPeriod: manualRetentionPeriod || '',
            specificDate: specificDate || '',
        },
        [ACTION_TYPES.PRIMARY_APPROVE_RELEASE]: {
            dispositionEventType:
                dispositionApprovalLevel === DispositionApprovalLevelEnum.PRIMARY.name
                    ? RELEASE_APPROVED.name
                    : PRIMARY_RELEASE_APPROVED.name,
        },
        [ACTION_TYPES.REQUEST_DISPOSITION]: {
            dispositionEventType: MANUAL_REQUEST.name,
        },
        [ACTION_TYPES.SECONDARY_APPROVE_RELEASE]: {
            dispositionEventType: RELEASE_APPROVED.name,
        },
        [ACTION_TYPES.PRIMARY_APPROVE_DISPOSITION]: {
            dispositionEventType:
                dispositionApprovalLevel === DispositionApprovalLevelEnum.PRIMARY.name
                    ? DISPOSITION_APPROVED.name
                    : PRIMARY_DISPOSITION_APPROVED.name,
        },
        [ACTION_TYPES.SECONDARY_APPROVE_DISPOSITION]: {
            dispositionEventType: DISPOSITION_APPROVED.name,
        },
        [ACTION_TYPES.SECONDARY_REJECT]: {
            dispositionEventType: SECONDARY_REJECT.name,
        },
        [ACTION_TYPES.RESET_RETENTION_POLICY]: {
            dispositionEventType: RETENTION_PERIOD_STARTED.name,
            manualRetentionPeriod: manualRetentionPeriod || '',
            dispositionApprovalLevel: terminalDispositionApprovalLevel,
            specificDate: specificDate || '',
        },
    };

    return {
        ...options[actionType],
        comment,
    };
};

/**
 * These props are used for the disposition action side panel.
 */
export const propsByActionType = {
    [ACTION_TYPES.PRIMARY_REJECT_HOLD]: {
        Form: HoldItemForm,
        formType: FORM_EVIDENCE_DISPOSITION_HOLD.name,
        title: strings.holdItemTitle,
    },
    [ACTION_TYPES.PRIMARY_APPROVE_RELEASE]: {
        Form: ReleaseForm,
        formType: FORM_EVIDENCE_DISPOSITION_PRIMARY_APPROVAL_FOR_RELEASE.name,
        title: strings.primaryApprovalForReleaseTitle,
    },
    [ACTION_TYPES.REQUEST_DISPOSITION]: {
        Form: RequestDispositionForm,
        formType: FORM_EVIDENCE_DISPOSITION_REQUEST.name,
        title: strings.requestDispositionTitle,
    },
    [ACTION_TYPES.SECONDARY_APPROVE_RELEASE]: {
        Form: SecondaryApprovalForm,
        formType: FORM_EVIDENCE_DISPOSITION_SECONDARY_APPROVAL.name,
        title: strings.secondaryApprovalTitle,
    },
    [ACTION_TYPES.PRIMARY_APPROVE_DISPOSITION]: {
        Form: DispositionForm,
        formType: FORM_EVIDENCE_DISPOSITION_PRIMARY_APPROVAL_FOR_DISPOSITION.name,
        title: strings.primaryApprovalForDispositionTitle,
    },
    [ACTION_TYPES.SECONDARY_APPROVE_DISPOSITION]: {
        Form: SecondaryApprovalForm,
        formType: FORM_EVIDENCE_DISPOSITION_SECONDARY_APPROVAL.name,
        title: strings.secondaryApprovalTitle,
    },
    [ACTION_TYPES.SECONDARY_REJECT]: {
        Form: SecondaryRejectDispositionForm,
        formType: FORM_EVIDENCE_DISPOSITION_SECONDARY_REJECTION.name,
        title: strings.secondaryRejectionTitle,
    },
    [ACTION_TYPES.RESET_RETENTION_POLICY]: {
        Form: ResetRetentionPolicyDispositionForm,
        formType: FORM_EVIDENCE_RESET_RETENTION_POLICY.name,
        title: strings.resetRetentionPolicyTitle,
    },
} as const;

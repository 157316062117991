import React from 'react';

import { FieldNameEnumType } from '@mark43/rms-api';
import { arbiterMFTInput } from '../../../arbiter';

import ButtonRadio from '../ButtonRadio';
import FormElement from '../FormElement';

import { LegacyButtonRadioOption } from './types';

type ButtonRadioValue = string | number | boolean;

export type FormElementButtonRadioProps = {
    label: React.ReactNode;
    onChange?: ((value: string) => void) | ((value: number) => void) | ((value: boolean) => void);
    options: LegacyButtonRadioOption[];
    value: ButtonRadioValue;
    fieldName?: FieldNameEnumType;
    error?: string;
    forceShowError?: boolean;
    helpText?: string;
    deselectable?: boolean;
};

const FormElementButtonRadio = ({
    deselectable,
    error,
    fieldName,
    forceShowError,
    helpText,
    label,
    onChange,
    options,
    value,
    ...otherProps
}: FormElementButtonRadioProps) => {
    return (
        <FormElement
            error={error}
            fieldName={fieldName}
            forceShowError={forceShowError}
            helpText={helpText}
            label={label}
            value={value}
            {...otherProps}
        >
            <ButtonRadio
                onChange={onChange}
                options={options}
                value={value}
                deselectable={deselectable}
            />
        </FormElement>
    );
};

export default FormElementButtonRadio;
export const ArbiterMFTFormElementButtonRadio = arbiterMFTInput(FormElementButtonRadio);

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, find } from 'lodash';
import { EntityPermission } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { SearchModuleType } from '../../../../core/typings/search-module';

type OnSaveSearchParams = {
    query?: { savedSearchId?: number; isAutoSave?: boolean };
    name?: string;
    isShared?: boolean;
    entityPermissions?: Partial<EntityPermission>[];
};

function useToggleDeleteSavedSearch(searchModule: SearchModuleType) {
    const dispatch = useDispatch();
    const overlayStore = useOverlayStore();
    const savedSearches = useSelector(searchModule.selectors.savedSearchesSelector);
    // Do not use executedSavedSearchToUpdateSelector.
    // Because toggle button should only allow delete immediately after saved search execute
    const query = useSelector(searchModule.selectors.currentQuerySelector);
    const savedSearch = find(savedSearches, { id: query.savedSearchId });
    const applicationSettings = useSelector(applicationSettingsSelector);

    return useCallback(() => {
        if (applicationSettings.RMS_SAVED_SEARCH_SHARING_ENABLED && savedSearch?.isShared) {
            overlayStore.open(overlayIdEnum.DELETE_SAVED_SEARCH_CONFIRMATION_MODAL, {
                savedSearch,
            });
        } else {
            dispatch(searchModule.actionCreators.deleteSavedSearch(query.savedSearchId));
        }
    }, [
        applicationSettings.RMS_SAVED_SEARCH_SHARING_ENABLED,
        dispatch,
        overlayStore,
        query,
        savedSearch,
        searchModule.actionCreators,
    ]);
}

const useOnSaveSearchToggleHandler = (searchModule: SearchModuleType) => {
    const overlayStore = useOverlayStore();
    const dispatch = useDispatch();
    const currentQuery = useSelector(searchModule?.selectors?.currentQuerySelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const savedSearchSharingEnabled = applicationSettings.RMS_SAVED_SEARCH_SHARING_ENABLED;

    const toggleDeleteSavedSearch = useToggleDeleteSavedSearch(searchModule);

    return useCallback(
        (params?: OnSaveSearchParams) => {
            const query = params?.query || currentQuery;
            const { name, isShared, entityPermissions = [] } = params || {};

            if (query?.savedSearchId && !query?.isAutoSave) {
                toggleDeleteSavedSearch();
            } else if (savedSearchSharingEnabled && !name) {
                overlayStore.open(overlayIdEnum.SAVED_SEARCH_CREATE_MODAL, {
                    name,
                    isShared,
                    entityPermissions,
                });
            } else {
                dispatch(
                    searchModule.actionCreators.saveSearch(query, {
                        name,
                        isShared,
                        entityPermissions: filter(
                            entityPermissions,
                            ({ roleId, operationType }) => !!roleId && !!operationType
                        ),
                        savedSearchSharingEnabled,
                    })
                );
            }
        },
        [
            dispatch,
            searchModule,
            savedSearchSharingEnabled,
            overlayStore,
            currentQuery,
            toggleDeleteSavedSearch,
        ]
    );
};

export default useOnSaveSearchToggleHandler;

import { Action } from 'redux';
import React, { useEffect, useRef, useState } from 'react';
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Circle from '@arcgis/core/geometry/Circle';
// import '@arcgis/core/assets/esri/themes/dark/main.css';

import styled from 'styled-components';
import {
    CenterT,
    MapViewT,
    WebMapT,
    EsriBaseMapModeT,
    PointT,
    MapOnChangeProps,
} from '../../types';
import { areLatLngsEqual } from '../../helpers/avlHelpers';
import { ESRI_MAP_DEFAULT_ROTATION_ENABLED } from '../config';

const WebmapContainer = styled.div`
    height: 100%;
    width: 100%;
`;

const useEsriESModuleSimpleMap = (
    center: CenterT,
    mapRef: React.MutableRefObject<HTMLDivElement | null>,
    esriBaseMapMode?: EsriBaseMapModeT,
    zoomLevel?: number,
    enableMove?: boolean,
    enableZoom?: boolean,
    mapOnChange?: (data: MapOnChangeProps) => Action,
    webmapPortalId?: string,
    mapOnClick?: (e: __esri.ViewClickEvent) => void
) => {
    const [mapView, setMapView] = useState<MapViewT>();
    const [webmap, setWebMap] = useState<WebMapT>();
    const prevCenterRef = useRef<CenterT>();
    const prevZoomRef = useRef<number>();
    const centerRef = useRef<CenterT>(center);
    // Wrapping this in a ref so that it is always stable
    // Otherwise, it'll cause the map to re-init itself every render
    const stableMapOnClickRef = useRef<undefined | ((e: __esri.ViewClickEvent) => void)>(undefined);
    stableMapOnClickRef.current = mapOnClick;

    useEffect(() => {
        if (
            !webmap ||
            !mapView ||
            (center.lat === centerRef.current.lat && center.lng === centerRef.current.lng) ||
            isNaN(center.lat) ||
            isNaN(center.lng) ||
            center.lat === undefined ||
            center.lng === undefined
        ) {
            return;
        }

        mapView.goTo(
            {
                target: [center.lng, center.lat],
            },
            { animate: false }
        );
        centerRef.current = {
            lat: center.lat,
            lng: center.lng,
        };
    }, [center.lat, center.lng, webmap, mapView]);

    useEffect(() => {
        const webmap = new WebMap(
            webmapPortalId
                ? {
                      portalItem: {
                          id: webmapPortalId,
                      },
                  }
                : {
                      basemap: esriBaseMapMode,
                  }
        );
        // @ts-expect-error esri type conversion
        const point: PointT = {
            type: 'point',
            latitude: centerRef.current.lat,
            longitude: centerRef.current.lng,
        };
        // Create View
        const view = new MapView({
            container: mapRef.current || undefined,
            map: webmap,
            center: point,
            zoom: zoomLevel,
            constraints: {
                rotationEnabled: ESRI_MAP_DEFAULT_ROTATION_ENABLED,
            },
        });

        // Set General popup rules

        view.popup.dockOptions = {
            position: 'top-left',
        };

        if (!enableMove) {
            view.on('drag', (event: React.MouseEvent) => {
                event.stopPropagation();
            });
            view.on('drag', ['Shift'], (event: React.MouseEvent) => {
                event.stopPropagation();
            });
            view.on('drag', ['Shift', 'Control'], (event: React.MouseEvent) => {
                event.stopPropagation();
            });
            view.on('key-down', (event: React.KeyboardEvent) => {
                const prohibitedKeys = [
                    'A',
                    'a', // case sensitive
                    'D',
                    'd',
                    'ArrowUp',
                    'ArrowDown',
                    'ArrowLeft',
                    'ArrowRight',
                ];
                const keyPressed = event.key;
                if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                    event.stopPropagation();
                }
            });
        }
        if (!enableZoom) {
            // zoom in
            view.on('double-click', (event: React.MouseEvent) => {
                event.stopPropagation();
            });
            // zoom out
            view.on('double-click', ['Control'], (event: React.MouseEvent) => {
                event.stopPropagation();
            });
            view.on('key-down', (event: React.KeyboardEvent) => {
                const prohibitedKeys = ['+', '-', 'Shift', '_', '='];
                const keyPressed = event.key;
                if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                    event.stopPropagation();
                }
            });
            view.on('mouse-wheel', (event: React.MouseEvent) => {
                event.stopPropagation();
            });
        }

        view.on('click', (event: __esri.ViewClickEvent) => {
            stableMapOnClickRef.current?.(event);
        });

        if (mapOnChange) {
            prevCenterRef.current = {
                lat: centerRef.current.lat,
                lng: centerRef.current.lng,
            };
            prevZoomRef.current = view.zoom;

            view.watch('updating', (newValue, oldValue) => {
                const currentCenter = {
                    lat: view.center.latitude,
                    lng: view.center.longitude,
                };
                // only call when finished updating and center or zoom has changed value
                if (
                    oldValue &&
                    !newValue &&
                    (!areLatLngsEqual(currentCenter, prevCenterRef.current) ||
                        view.zoom !== prevZoomRef.current)
                ) {
                    mapOnChange({
                        center: currentCenter,
                        zoom: view.zoom,
                    });
                    // update prev values to current values
                    prevCenterRef.current = currentCenter;
                    prevZoomRef.current = view.zoom;
                }
            });
        }
        // Make Esri modules available to other components
        // Remove All Unused ESRI Widgets
        view.ui.components = [];
        setMapView(view);
        setWebMap(webmap);
    }, [
        mapRef,
        mapOnChange,
        enableMove,
        zoomLevel,
        enableZoom,
        esriBaseMapMode,
        webmapPortalId,
        stableMapOnClickRef,
    ]);

    return {
        webmap,
        mapView,
    };
};

export const useEsriSimpleBaseMap = (
    center: CenterT,
    zoomLevel: number,
    enableMove?: boolean,
    enableZoom?: boolean,
    mapMode?: EsriBaseMapModeT,
    mapOnChange?: (data: MapOnChangeProps) => Action,
    webmapPortalId?: string,
    mapOnClick?: (e: __esri.ViewClickEvent) => void
) => {
    const mapRef = useRef<HTMLDivElement | null>(null);
    const { mapView, webmap } = useEsriESModuleSimpleMap(
        center,
        mapRef,
        mapMode,
        zoomLevel,
        enableMove,
        enableZoom,
        mapOnChange,
        webmapPortalId,
        mapOnClick
    );
    const baseMap = <WebmapContainer ref={mapRef} />;

    return {
        baseMap,
        mapView,
        webmap,
        FeatureLayerConstructor: FeatureLayer,
        GraphicConstructor: Graphic,
        CircleConstructor: Circle,
    };
};

import { defer, get, head } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { Button as ArcButton } from '../../../core/components/Button';
import { iconTypes, iconSizes } from '../../../core/components/Icon';
import { hideCommentSidebar, toggleFullScreenEditor } from '../../../core/editor/utils/tinyEditor';
import { ExpandCollapseIcon } from './NarrativeStyledComponents';

const getParentContentElement = () => head(document.getElementsByClassName('mark43-content'));

const strings = componentStrings.reports.core.NarrativeCard;

class NarrativeCardBase extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
            isExpandedFullScreen: false,
        };

        this.narrativeCardEditorRef = React.createRef();
    }

    componentWillUnmount() {
        // reset the parent containers overflow, in case we navigate away from
        // the page while the narrative card is expanded.
        const parentContentElement = getParentContentElement();
        if (!isUndefinedOrNull(parentContentElement)) {
            parentContentElement.style.setProperty('overflow', '');
        }
    }

    getIsExpandedFullScreen = () => this.state.isExpandedFullScreen;
    setIsExpandedFullScreen = (isExpandedFullScreen) => {
        this.setState({ isExpandedFullScreen });
        const parentContentElement = getParentContentElement();

        if (!isUndefinedOrNull(parentContentElement)) {
            const overflow = isExpandedFullScreen ? 'hidden' : '';
            parentContentElement.style.setProperty('overflow', overflow);
        }
    };

    onFullScreenToggle = () => {
        this.setIsExpandedFullScreen(!this.getIsExpandedFullScreen());
        const editor = this.getCurrentNarrativeCardEditorRef();
        if (editor) {
            defer(() => {
                editor.focus();
            });
        }
    };

    onClickExpandCollapseButton = () => {
        const editor = this.getCurrentNarrativeCardEditorRef();
        if (editor) {
            // This function executes the TinyMCE command to toggle between expanded mode and collapsed mode. We
            // avoid directly updating state here using `onFullScreenToggle`, because that callback is called as
            // part of the TinyMCE command's event handler. Only this function should be called to toggle between
            // expanded and collapsed mode because there are other ways to make that transition besides clicking
            // these buttons, such as pressing the Ctrl+Shift+F shortcut.
            toggleFullScreenEditor(editor);
        }
    };

    onSaveSuccess = () => {
        const editor = this.getCurrentNarrativeCardEditorRef();
        if (editor) {
            hideCommentSidebar(editor);

            if (this.getIsExpandedFullScreen()) {
                // If the card is successfully saved in expanded mode, transition to collapsed mode.
                toggleFullScreenEditor(editor);
            }
        }
    };

    getCurrentNarrativeCardEditorRef = () => get(this.narrativeCardEditorRef, 'current');

    renderFullScreenToggleButton = (summaryMode) => {
        const isExpanded = this.getIsExpandedFullScreen();

        const iconType = isExpanded ? iconTypes.COLLAPSE_WINDOW : iconTypes.EXPAND_WINDOW;

        return (
            <FeatureFlagged
                flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                fallback={
                    <Button
                        onClick={this.onClickExpandCollapseButton}
                        className={buttonTypes.ICON_LINK}
                        iconLeft={
                            <ExpandCollapseIcon
                                size={iconSizes.SMALL}
                                type={iconType}
                                summaryMode={summaryMode}
                            />
                        }
                    >
                        {strings.expandCollapseLabel(isExpanded)}
                    </Button>
                }
            >
                <ArcButton
                    style={
                        // TODO: This is a hack to make the button white in edit mode
                        !summaryMode
                            ? {
                                  color: 'currentColor',
                              }
                            : {}
                    }
                    onClick={this.onClickExpandCollapseButton}
                    leftIcon={isExpanded ? 'CollapseWindow' : 'ExpandWindow'}
                    variant="ghost"
                >
                    {strings.expandCollapseLabel(isExpanded)}
                </ArcButton>
            </FeatureFlagged>
        );
    };

    render() {
        return this.props.children({
            narrativeCardEditorRef: this.narrativeCardEditorRef,
            getIsExpandedFullScreen: this.getIsExpandedFullScreen,
            onFullScreenToggle: this.onFullScreenToggle,
            setIsExpandedFullScreen: this.setIsExpandedFullScreen,
            onSaveSuccess: this.onSaveSuccess,
            getCurrentNarrativeCardEditorRef: this.getCurrentNarrativeCardEditorRef,
            renderFullScreenToggleButton: this.renderFullScreenToggleButton,
        });
    }
}

const mapStateToProps = createStructuredSelector({
    applicationSettings: applicationSettingsSelector,
});

export default connect(mapStateToProps)(NarrativeCardBase);

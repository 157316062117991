import { createSelector } from 'reselect';
import {
    Attachment,
    EntityTypeEnumType,
    Mark43File,
    PrintingDataTypeEnum,
    PrintingDataTypeEnumType,
} from '@mark43/rms-api';
import { find, filter, includes, map, omit, ObjectIterateeCustom } from 'lodash';
import createNormalizedModule, { ModuleShape } from '../../../../utils/createNormalizedModule';
import getAttachmentsResource from '../../resources/attachmentsResource';
import { getAttachmentFile, setAttachmentFile } from '../../utils/attachmentsHelper';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'attachments';

const attachmentsModule = createNormalizedModule<Attachment>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeAttachments = attachmentsModule.actionCreators.storeEntities;
const replaceAttachmentsWhere = attachmentsModule.actionCreators.replaceEntitiesWhere;
export const deleteAttachment = attachmentsModule.actionCreators.deleteEntity;

const LOAD_ATTACHMENTS_START = 'attachments/LOAD_ATTACHMENTS_START';
const LOAD_ATTACHMENTS_SUCCESS = 'attachments/LOAD_ATTACHMENTS_SUCCESS';
const LOAD_ATTACHMENTS_FAILURE = 'attachments/LOAD_ATTACHMENTS_FAILURE';

const UPDATE_ATTACHMENTS_SUCCESS = 'attachments/UPDATE_ATTACHMENTS_SUCCESS';

const LOAD_ATTACHMENTS_BY_ENTITY_IDS_AND_TYPES_START =
    'attachments/LOAD_ATTACHMENTS_BY_ENTITY_IDS_AND_TYPES_START';
const LOAD_ATTACHMENTS_BY_ENTITY_IDS_AND_TYPES_SUCCESS =
    'attachments/LOAD_ATTACHMENTS_BY_ENTITY_IDS_AND_TYPES_SUCCESS';
const LOAD_ATTACHMENTS_BY_ENTITY_IDS_AND_TYPES_FAILURE =
    'attachments/LOAD_ATTACHMENTS_BY_ENTITY_IDS_AND_TYPES_FAILURE';

function loadAttachmentsStart() {
    return { type: LOAD_ATTACHMENTS_START };
}
function loadAttachmentsSuccess() {
    return { type: LOAD_ATTACHMENTS_SUCCESS };
}
function loadAttachmentsFailure(errorMessage: string) {
    return { type: LOAD_ATTACHMENTS_FAILURE, payload: errorMessage, error: true };
}

function loadAttachmentsByEntityIdsAndTypesStart() {
    return { type: LOAD_ATTACHMENTS_BY_ENTITY_IDS_AND_TYPES_START };
}
function loadAttachmentsByEntityIdsAndTypesSuccess() {
    return { type: LOAD_ATTACHMENTS_BY_ENTITY_IDS_AND_TYPES_SUCCESS };
}
function loadAttachmentsByEntityIdsAndTypesFailure(errorMessage: string) {
    return {
        type: LOAD_ATTACHMENTS_BY_ENTITY_IDS_AND_TYPES_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function updateAttachmentsSuccess() {
    return { type: UPDATE_ATTACHMENTS_SUCCESS };
}

export const updateFilesForAttachments = (
    predicate: ObjectIterateeCustom<ModuleShape<Attachment>, boolean>,
    files: Mark43File[]
): ClientCommonAction<void> => {
    return (dispatch, getState, { nexus }) => {
        const attachments = attachmentsWhereSelector(getState())(predicate);
        const updatedAttachments = map(attachments, (attachment) => {
            const file = getAttachmentFile(attachment);
            const updatedFile = find(files, ['id', file.id]);
            return setAttachmentFile(attachment, updatedFile);
        });
        dispatch(
            nexus.withEntityItems(
                {
                    [NEXUS_STATE_PROP]: updatedAttachments,
                },
                updateAttachmentsSuccess()
            )
        );
    };
};

// linkTypes requires removeOthers to be set to true to have an effect
export const saveAttachmentsForEntities = ({
    entityType,
    entityIds,
    attachments,
    removeOthers,
    linkTypes,
}: {
    entityType: EntityTypeEnumType;
    entityIds: number[];
    attachments: Attachment[];
    removeOthers?: boolean;
    linkTypes?: number[];
}): ClientCommonAction<Promise<Attachment[]>> => {
    return (dispatch) => {
        const resource = getAttachmentsResource();
        return resource
            .saveAttachmentsForEntities({
                entityType,
                entityIds,
                attachments,
                removeOthers,
                linkTypes,
            })
            .then((savedAttachments: Attachment[]) => {
                if (removeOthers) {
                    if (linkTypes) {
                        dispatch(
                            replaceAttachmentsWhere(({ entityId, linkType }) => {
                                return (
                                    includes(entityIds, entityId) && includes(linkTypes, linkType)
                                );
                            }, savedAttachments)
                        );
                    } else {
                        dispatch(
                            replaceAttachmentsWhere(({ entityId }) => {
                                return includes(entityIds, entityId);
                            }, savedAttachments)
                        );
                    }
                } else {
                    dispatch(storeAttachments(savedAttachments));
                }
                return savedAttachments;
            });
    };
};

export const removeAttachment = (attachment: Attachment): ClientCommonAction<Promise<void>> => {
    return (dispatch) => {
        const attachmentsResource = getAttachmentsResource();

        return attachmentsResource.deleteAttachment(attachment).then(() => {
            dispatch(deleteAttachment(attachment.id));
            return;
        });
    };
};

export const removeAttachments = (
    attachments: Attachment[],
    entityId: number,
    entityType: EntityTypeEnumType
): ClientCommonAction<Promise<void>> => {
    return (dispatch, getState, { nexus: { withEntityItems, withRemove } }) => {
        const attachmentsResource = getAttachmentsResource();

        return attachmentsResource.deleteAttachments(attachments, entityId, entityType).then(() => {
            const currentAttachments = attachmentsSelector(getState());
            const deletedAttachmentIds = map(attachments, 'attachmentId');
            const updatedAttachments = filter(
                currentAttachments,
                ({ attachmentId }) => !includes(deletedAttachmentIds, attachmentId)
            );

            dispatch(
                withEntityItems(
                    {
                        [NEXUS_STATE_PROP]: updatedAttachments,
                    },
                    withRemove(NEXUS_STATE_PROP, {}, updateAttachmentsSuccess())
                )
            );
            return;
        });
    };
};

export function loadAttachmentsByEntityId(
    entityType: EntityTypeEnumType,
    entityIds: number | number[],
    options: {
        hideLoadingBar: boolean;
    }
): ClientCommonAction<Promise<Attachment[]>> {
    return (dispatch, getState, { nexus }) => {
        dispatch(loadAttachmentsStart());
        const resource = getAttachmentsResource();
        return resource
            .loadAttachmentsByEntityId(entityType, entityIds, options)
            .then((attachments: Attachment[]) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: attachments,
                        },
                        loadAttachmentsSuccess()
                    )
                );
                return attachments;
            })
            .catch((error: Error) => {
                dispatch(loadAttachmentsFailure(error.message));
                throw error;
            });
    };
}

export function loadAttachmentsByEntityIdsAndTypes(
    entityIdsByType: Partial<Record<PrintingDataTypeEnumType, number[]>>,
    options: { hideLoadingBar?: boolean; excludeAttachmentLinkTypes: boolean }
): ClientCommonAction<Promise<Attachment[]>> {
    return (dispatch, getState, { nexus }) => {
        entityIdsByType = omit(entityIdsByType, [
            // we don't want to load attachments which are contents of folders
            // this may be a future enhancement to the Case Folders feature
            PrintingDataTypeEnum.PARENT_FOLDER.name,
            // we must be careful to not load all case attachments here, because the endpoint returns foldered
            // attachments - there is no way to distinguish foldered attachments from folderless attachments until the
            // endpoint excludes the former https://github.com/mark43/mark43/pull/49682#discussion_r940394791
            PrintingDataTypeEnum.CASE.name,
            PrintingDataTypeEnum.TASK_TYPE.name,
            PrintingDataTypeEnum.REPORT_IDENTIFIER.name,
        ]);

        dispatch(loadAttachmentsByEntityIdsAndTypesStart());
        return getAttachmentsResource()
            .loadAttachmentsByEntityIdsAndTypes(entityIdsByType, options)
            .then((attachments: Attachment[]) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: attachments,
                        },
                        loadAttachmentsByEntityIdsAndTypesSuccess()
                    )
                );
                return attachments;
            })
            .catch((error: Error) => {
                dispatch(loadAttachmentsByEntityIdsAndTypesFailure(error.message));
                throw error;
            });
    };
}

// SELECTORS
export const attachmentsSelector = attachmentsModule.selectors.entitiesSelector;

export const attachmentsWhereSelector = attachmentsModule.selectors.entitiesWhereSelector;
export const attachmentsByEntitySelector = createSelector(
    attachmentsSelector,
    (attachments) => (entityType: EntityTypeEnumType, entityId: number) =>
        filter(attachments, { entityType, entityId })
);

// REDUCER
export default attachmentsModule.reducerConfig;

import { EntityTypeEnum, UsageSourceModuleEnum } from '@mark43/rms-api';
import { chunk, get, map } from 'lodash';

import Promise from 'bluebird';
import * as Sentry from '@sentry/browser';
import { createSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';

import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import {
    labelPrintersSelector,
    NEXUS_STATE_PROP as LABEL_PRINTERS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/label-printers/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { evidenceDepartmentConfigSelector } from '~/client-common/core/domain/evidence-department-config/state/data';

import barcodeLabelResource from '../../resources/barcodeLabelResource';
import labelPrintingResource from '../../resources/labelPrintingResource';
import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import boxActionTypes from '../../../../../legacy-redux/actions/types/boxActionTypes';
import labelPrintingForm from '../forms/labelPrintingForm';
import { LABEL_PRINTING_BATCH_SIZE, LABEL_PRINTING_BATCH_DELAY } from '../../configuration';
import {
    openBox,
    saveBoxSuccess,
    saveBoxHalt,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../../core/box/state/ui';
import { base64ToDataUri } from '../../utils/labelHelpers';
import errors from '../../../../../lib/errors';

const { CLOSE_BOX } = boxActionTypes;
const labelPrintingModalContext = {
    name: boxEnum.LABEL_PRINTING_MODAL,
};

const LOAD_LABEL_PREVIEW_START = 'label-printing/LOAD_LABEL_PREVIEW_START';
const LOAD_LABEL_PREVIEW_FAILURE = 'label-printing/LOAD_LABEL_PREVIEW_FAILURE';
const LOAD_LABEL_PREVIEW_SUCCESS = 'label-printing/LOAD_LABEL_PREVIEW_SUCCESS';
const PRINT_LABELS_START = 'label-printing/PRINT_LABELS_START';
const PRINT_LABELS_FAILURE = 'label-printing/PRINT_LABELS_FAILURE';
const PRINT_LABELS_SUCCESS = 'label-printing/PRINT_LABELS_SUCCESS';

function loadLabelPreviewStart() {
    return {
        type: LOAD_LABEL_PREVIEW_START,
    };
}

function loadLabelPreviewSuccess(labelPreviewBase64) {
    return {
        type: LOAD_LABEL_PREVIEW_SUCCESS,
        payload: labelPreviewBase64,
    };
}

function loadLabelPreviewFailure(errorMessage) {
    return {
        type: LOAD_LABEL_PREVIEW_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function loadLabelPreview(entityType, entityIds) {
    return function (dispatch) {
        dispatch(loadLabelPreviewStart());

        barcodeLabelResource
            .getLabelPreviewsForEntities(entityType, entityIds)
            .then((labelPreviewBase64) => {
                dispatch(loadLabelPreviewSuccess(labelPreviewBase64));
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setTag('module', UsageSourceModuleEnum.EVIDENCE.name);
                    scope.setExtra('entityType', entityType);
                    scope.setExtra('entityIds', entityIds);
                    scope.setExtra('errorMessage', err.message);
                    Sentry.captureMessage('Label printing: Failed to load label preview');
                });
                dispatch(loadLabelPreviewFailure(err.message));
                dispatch(
                    saveBoxFailure(
                        labelPrintingModalContext,
                        'Failed to load preview image, but printing may still work.'
                    )
                );
            });
    };
}

/**
 * Open the evidence barcode label printing modal and store the given properties
 *   in ui state, to be displayed.
 * @param {Object} properties
 */
export function openLabelPrintingModal({
    masterItemIds = [],
    parentStorageLocationFullDisplayPath = '',
    storageLocationIds = [],
}) {
    return function (dispatch, getState, dependencies) {
        const state = getState();

        // label printing v2: get the printers on the spot here from the Browser
        // Print software that's running locally on the user's computer
        if (!applicationSettingsSelector(state).EVIDENCE_LABELS_V3_ENABLED) {
            Promise.all([
                labelPrintingResource.getAvailablePrintersV2(),
                labelPrintingResource.getDefaultPrinterV2(),
            ])
                .spread((printers, defaultPrinter) => {
                    // confusingly, the `printer` property given by Browser
                    // Print is an array of printers
                    if (!printers.printer) {
                        Sentry.withScope((scope) => {
                            scope.setTag('module', UsageSourceModuleEnum.EVIDENCE.name);
                            scope.setExtra('masterItemIds', masterItemIds);
                            scope.setExtra('storageLocationIds', storageLocationIds);
                            scope.setExtra('printers', printers);
                            scope.setExtra('defaultPrinter', defaultPrinter);
                            Sentry.captureMessage('Label printing v2: Found no printers');
                        });
                        dispatch(
                            saveBoxFailure(
                                labelPrintingModalContext,
                                'Found no printers in network. Please check the printer (turn it off and on, press the feed button), close this box and try again.'
                            )
                        );
                        return;
                    }
                    // put Browser Print's `uid` property under `id` so that
                    // both v2 and v3 printers are keyed by `id`
                    const removeLabelPrinters = dependencies.nexus.withRemove(
                        LABEL_PRINTERS_NEXUS_STATE_PROP,
                        {},
                        { type: 'STORE_LABEL_PRINTERS' }
                    );
                    dispatch(
                        dependencies.nexus.withEntityItems(
                            {
                                [LABEL_PRINTERS_NEXUS_STATE_PROP]: map(
                                    printers.printer,
                                    (printer) => ({ ...printer, id: printer.uid })
                                ),
                            },
                            removeLabelPrinters
                        )
                    );
                    dispatch(
                        labelPrintingForm.actionCreators.changePath(
                            'labelPrinterId',
                            defaultPrinter.uid
                        )
                    );
                })
                .catch((err) => {
                    Sentry.withScope((scope) => {
                        scope.setTag('module', UsageSourceModuleEnum.EVIDENCE.name);
                        scope.setExtra('masterItemIds', masterItemIds);
                        scope.setExtra('storageLocationIds', storageLocationIds);
                        scope.setExtra('errorMessage', err.message);
                        Sentry.captureMessage(
                            'Label printing v2: Failed to connect to Browser Print'
                        );
                    });
                    dispatch(
                        saveBoxFailure(
                            labelPrintingModalContext,
                            'Error connecting to Browser Print. Please reopen the Browser Print program, close this box and try again.'
                        )
                    );
                });
        }

        // open the modal
        dispatch(
            openBox(labelPrintingModalContext, {
                masterItemIds,
                parentStorageLocationFullDisplayPath,
                storageLocationIds,
            })
        );

        // don't load label previews when more than 1 entity is selected
        if (masterItemIds.length === 1) {
            dispatch(loadLabelPreview(EntityTypeEnum.ITEM_PROFILE.name, masterItemIds[0]));
        } else if (storageLocationIds.length === 1) {
            dispatch(
                loadLabelPreview(
                    EntityTypeEnum.EVIDENCE_STORAGE_LOCATION.name,
                    storageLocationIds[0]
                )
            );
        }
    };
}

function printLabelsStart() {
    return {
        type: PRINT_LABELS_START,
    };
}

function printLabelsSuccess() {
    return {
        type: PRINT_LABELS_SUCCESS,
    };
}

function printLabelsFailure(errorMessage) {
    return {
        type: PRINT_LABELS_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

/**
 * Attempt to print the label(s) for the selected item(s) or storage
 *   location(s). This is done in batches since printing is a long operation.
 *   For each batch, first make a request to get the ZPL code for the entities,
 *   then send the ZPL code to the printer endpoint for actual printing.
 *
 * On success, print the next batch and close the modal when all batches are
 *   printed. On failure, display the error in the modal and don't continue to
 *   the next batch. If the user navigates away from the current route, don't
 *   continue to the next batch.
 */
export function submitLabelPrintingModal() {
    return function (dispatch, getState) {
        dispatch(printLabelsStart());
        dispatch(
            labelPrintingForm.actionCreators.submit((formModel) => {
                const state = getState();
                const masterItemIds = labelPrintingModalMasterItemIdsSelector(state);
                const storageLocationIds = labelPrintingModalStorageLocationIdsSelector(state);

                let entityType;
                let entityIds;
                if (masterItemIds.length > 0) {
                    entityType = EntityTypeEnum.ITEM_PROFILE.name;
                    entityIds = masterItemIds;
                } else if (storageLocationIds.length > 0) {
                    entityType = EntityTypeEnum.EVIDENCE_STORAGE_LOCATION.name;
                    entityIds = storageLocationIds;
                } else {
                    Sentry.withScope((scope) => {
                        scope.setTag('module', UsageSourceModuleEnum.EVIDENCE.name);
                        scope.setExtra('masterItemIds', masterItemIds);
                        scope.setExtra('storageLocationIds', storageLocationIds);
                        Sentry.captureMessage('Label printing: No entities selected');
                    });
                    dispatch(
                        saveBoxFailure(
                            labelPrintingModalContext,
                            'An error occurred, please close this box and try again.'
                        )
                    );
                    return Promise.resolve();
                }

                const chunks = chunk(entityIds, LABEL_PRINTING_BATCH_SIZE);
                return Promise.each(chunks, (ids) => {
                    if (!printingSelector(getState())) {
                        // stop printing depending on ui state; this error is
                        // thrown just to break the promise loop and will not
                        // appear in the UI
                        Sentry.withScope((scope) => {
                            scope.setTag('module', UsageSourceModuleEnum.EVIDENCE.name);
                            scope.setExtra('entityType', entityType);
                            scope.setExtra('entityIds', entityIds);
                            Sentry.captureMessage('Label printing: User left during a batch');
                        });
                        throw new errors.Mark43Error('Stopping the print job...');
                    }

                    return dispatch(printBatch(entityType, ids, formModel.labelPrinterId)).delay(
                        LABEL_PRINTING_BATCH_DELAY
                    );
                })
                    .then(() => {
                        dispatch(saveBoxSuccess(labelPrintingModalContext));
                        dispatch(printLabelsSuccess());
                    })
                    .catch((err) => {
                        dispatch(saveBoxFailure(labelPrintingModalContext, err.message));
                        dispatch(printLabelsFailure(err.message));
                    });
            })
        ).catch((err) => {
            dispatch(saveBoxHalt(labelPrintingModalContext));
            dispatch(printLabelsFailure(err.message));
        });
    };
}

/**
 * Print a single batch of labels.
 * @param  {string}   entityType
 * @param  {number[]} entityIds
 * @param  {number}   labelPrinterId
 * @return {Promise}
 */
function printBatch(entityType, entityIds, labelPrinterId) {
    return function (dispatch, getState) {
        const state = getState();
        const labelPrinter = labelPrintersSelector(state)[labelPrinterId];
        const evdDeptConfig = evidenceDepartmentConfigSelector(state);
        const useOkapiPrinting = applicationSettingsSelector(state).EVIDENCE_LABELS_V3_ENABLED;
        const printerId = useOkapiPrinting ? get(labelPrinter, 'id') : null;
        const printerDpi = useOkapiPrinting ? null : get(evdDeptConfig, 'labelPrintDpi');

        const zplCodePromise =
            entityIds.length === 1
                ? barcodeLabelResource.getZplCodeForEntity(
                      entityType,
                      entityIds[0],
                      printerDpi,
                      printerId
                  )
                : barcodeLabelResource.getZplCodeForEntities(
                      entityType,
                      entityIds,
                      printerDpi,
                      printerId
                  );

        return zplCodePromise.then((zplCode) => {
            if (!zplCode) {
                Sentry.withScope((scope) => {
                    scope.setTag('module', UsageSourceModuleEnum.EVIDENCE.name);
                    scope.setExtra('entityType', entityType);
                    scope.setExtra('entityIds', entityIds);
                    scope.setExtra('labelPrinterId', labelPrinterId);
                    Sentry.captureMessage('Label printing: Failed to generate ZPL');
                });
                throw new errors.Mark43Error(
                    'Failed to generate labels, please close this box and try again.'
                );
            }

            // print the label in 1 of 2 ways
            if (!useOkapiPrinting) {
                const logError = (browserPrintResponse, sentryMessage) => {
                    Sentry.withScope((scope) => {
                        scope.setTag('module', UsageSourceModuleEnum.EVIDENCE.name);
                        scope.setExtra('entityType', entityType);
                        scope.setExtra('entityIds', entityIds);
                        scope.setExtra('labelPrinterId', labelPrinterId);
                        scope.setExtra('browserPrintResponse', browserPrintResponse);
                        Sentry.captureMessage(`Label printing v2: ${sentryMessage}`);
                    });
                };

                // label printing v2: we need to make a few successive
                // requests to the Browser Print server for it to print...
                // first send a test string in order to figure out the
                // status of the printer
                return labelPrintingResource
                    .writeV2(labelPrinter, '~HQES')
                    .catch((err) => {
                        logError(err.message, '/write status error');
                        throw new errors.Mark43Error(
                            'Printing error. Please reopen the Browser Print program, close this box and try again.'
                        );
                    })
                    .then(() => {
                        return labelPrintingResource.printerStatusV2(labelPrinter).catch((err) => {
                            logError(err.message, '/read status error');
                            throw new errors.Mark43Error(
                                'Printing error. Please check the printer (turn it off and on, press the feed button), close this box and try again.'
                            );
                        });
                    })
                    .then(() => {
                        return labelPrintingResource.writeV2(labelPrinter, zplCode).catch((err) => {
                            logError(err.message, '/write status error');
                            throw new errors.Mark43Error(
                                'Printing error, please close this box and try again.'
                            );
                        });
                    });
            } else {
                // label printing v3: make one request to a hostname on this
                // department's network to print
                const networkAddress = get(labelPrinter, 'networkAddress');
                if (!networkAddress) {
                    Sentry.withScope((scope) => {
                        scope.setTag('module', UsageSourceModuleEnum.EVIDENCE.name);
                        scope.setExtra('entityType', entityType);
                        scope.setExtra('entityIds', entityIds);
                        scope.setExtra('labelPrinterId', labelPrinterId);
                        Sentry.captureMessage('Label printing v3: networkAddress does not exist');
                    });
                    throw new errors.Mark43Error('The selected printer is misconfigured.');
                }
                const printerAddress = get(labelPrinter, 'printerAddress');

                return labelPrintingResource
                    .printV3(networkAddress, printerAddress, zplCode)
                    .catch((err) => {
                        Sentry.withScope((scope) => {
                            scope.setTag('module', UsageSourceModuleEnum.EVIDENCE.name);
                            scope.setExtra('entityType', entityType);
                            scope.setExtra('entityIds', entityIds);
                            scope.setExtra('labelPrinterId', labelPrinterId);
                            scope.setExtra('okapiResponse', err.message);
                            Sentry.captureMessage('Label printing v3: Okapi error');
                        });
                        // all Okapi error messages are human readable, but the response will be
                        // empty when the browser can't connect to the label printing server
                        throw new errors.Mark43Error(
                            err.message ||
                                'Could not connect to print server. Ensure print server is plugged in and powered on, and try again.'
                        );
                    });
            }
        });
    };
}

export const labelPrintingModalMasterItemIdsSelector = createModalSelector(
    labelPrintingModalContext,
    'masterItemIds'
);

export const labelPrintingModalStorageLocationIdsSelector = createModalSelector(
    labelPrintingModalContext,
    'storageLocationIds'
);

export const labelPrintingModalParentStorageLocationFullDisplayPathSelector = createModalSelector(
    labelPrintingModalContext,
    'parentStorageLocationFullDisplayPath'
);

const labelPrintingUiSelector = (state) => state.ui.evidence.labelPrinting;

/**
 * `true` when loading a label preview image or when loading child ids of a
 *   selected storage location.
 * @type {boolean}
 */
export const loadingSelector = createSelector(labelPrintingUiSelector, ({ loading }) => loading);

/**
 * @type {string|null}
 */
const labelPreviewBase64Selector = createSelector(
    labelPrintingUiSelector,
    ({ labelPreviewBase64 }) => labelPreviewBase64
);

/**
 * @type {string}
 */
export const labelPreviewDataUriSelector = createSelector(labelPreviewBase64Selector, (base64) =>
    base64 ? base64ToDataUri(base64) : ''
);

/**
 * Whether labels are currently being printed.
 * @type {boolean}
 */
const printingSelector = createSelector(labelPrintingUiSelector, ({ printing }) => printing);

export default makeResettable(
    [ENTER_NEW_ROUTE, CLOSE_BOX],
    function labelPrintingUiReducer(
        state = {
            loading: false,
            labelPreviewBase64: null,
            printing: false,
        },
        action
    ) {
        switch (action.type) {
            case LOAD_LABEL_PREVIEW_START:
                return {
                    ...state,
                    loading: true,
                };
            case LOAD_LABEL_PREVIEW_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    labelPreviewBase64: action.payload,
                };
            case LOAD_LABEL_PREVIEW_FAILURE:
                return {
                    ...state,
                    loading: false,
                };
            case PRINT_LABELS_START:
                return {
                    ...state,
                    printing: true,
                };
            case PRINT_LABELS_SUCCESS:
            case PRINT_LABELS_FAILURE:
                return {
                    ...state,
                    printing: false,
                };
            default:
                return state;
        }
    }
);

import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { identity, map, size, last, sortBy, noop, head } from 'lodash';
import { createStructuredSelector } from 'reselect';
import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import {
    sortSavedSearches,
    getSavedSearchById,
    convertSavedSearchQueryToElasticQueryObject,
} from '~/client-common/helpers/advancedSearchHelpers';
import { savedSearchErrorMessages } from '~/client-common/configs/advancedSearchConfig';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import createSearchModule, {
    convertSavedSearchQueryShapesToLegacyQueryShape,
} from '../../../../search/core/utils/createSearchModule';
import storeRmsSavedSearchesView from '../../../../search/core/utils/storeRmsSavedSearchesView';
import { queryParamDefaults } from '../../configuration';
import { withTasksView } from '../../../core/data';
import taskResource from '../../../core/resources/taskResource';
import { convertToFormModel, convertFromFormModel } from '../forms/tasksDashboardSearchForm';
import getRmsSavedSearchResource from '../../../../search/core/resources/rmsSavedSearchResource';
import { scrollToTop } from '../../../../core/components/ScrollableUnderSubheader';
import { TaskDashboardSearchResultsParents } from '../../../core/config';
import convertTasksDashboardSearchFormModelToFilterGroups from './convertTasksDashboardSearchFormModelToFilterGroups';

export const tasksDashboardSearch = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.TASK.name,
    baseUiSelector: (state) => state.ui.tasks.dashboard,
    defaultTableState: {
        activeColumnKeys: {
            date: 'dueDateUtc',
        },
        from: queryParamDefaults.FROM,
        size: queryParamDefaults.SIZE,
    },
    resourceMethod: (formData, from, size, sortKey, sortType, dispatch) => {
        const sorts = sortKey && sortType ? [{ sortKey, sortType }] : undefined;
        const taskSqlQuery = {
            query: convertFromFormModel(formData),
            from,
            size,
            sorts,
        };

        return taskResource.search(taskSqlQuery).then((taskView) => {
            const { lastExecutedSavedSearch, tasks, totalCount } = taskView;
            dispatch(withTasksView(taskView));

            return {
                items: tasks,
                lastExecutedSavedSearch,
                query: {
                    from,
                    size,
                    sorts,
                },
                totalCount,
            };
        });
    },
    createResultsViewModelsSelector: identity,
    elasticQueryToFilterGroups: convertTasksDashboardSearchFormModelToFilterGroups,
    resultsContainerClassName: 'tasks-results-container',
    scrollToElement: scrollToTop,
    buildExecuteSavedSearchActionCreator,
    transformElasticQueryBeforeSearchSelector: () => identity,
    buildLoadSavedSearchesActionCreator: buildLoadTasksSavedSearchesActionCreator,
    buildLoadAndExecuteLatestAutoSavedSearch: buildTasksLoadAndExecuteLatestAutoSavedSearch,
    selectorToBind: createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
        dateTimeFormatter: currentDepartmentDateFormatterSelector,
    }),
});

export default tasksDashboardSearch.reducers.uiReducer;

/**
 * Re-execute the existing search filters in the Tasks Dashboard. It is intentional that the loading
 *   modal appears.
 */
export const refreshTasksDashboard = (options) => (dispatch, getState) => {
    const query = tasksDashboardSearch.selectors.currentQuerySelector(getState());
    dispatch(
        tasksDashboardSearch.actionCreators.search(query, {
            cacheBust: true,
            ...options,
        })
    );
};

/**
 * Execute a search in the Tasks Dashboard. The loading modal does not appear.
 */
export const filterTaskDashboard = (query, taskDashboardSearchResultsParents) => (
    dispatch,
    getState,
    { formsRegistry }
) => {
    const form = formsRegistry.get(formClientEnum.TASKS_DASHBOARD_SEARCH_FORM);
    const currentQuery = tasksDashboardSearch.selectors.currentQuerySelector(getState());
    const sort = head(currentQuery.sorts);
    const queryWithSort = {
        formData:
            taskDashboardSearchResultsParents === TaskDashboardSearchResultsParents.TASK_DASHBOARD
                ? form.getState().model
                : null,
        ...query,
        sortKey: sort?.sortKey,
        sortType: sort?.sortType,
    };
    dispatch(
        tasksDashboardSearch.actionCreators.search(queryWithSort, {
            cacheBust: true,
            showLoadingModal: false,
        })
    );
};

export const resetFilterForm = () => (dispatch, getState, { formsRegistry }) => {
    const form = formsRegistry.get(formClientEnum.TASKS_DASHBOARD_SEARCH_FORM);
    form.transaction(() => {
        form.resetModel();
        form.set('dateType', dateTypeEnum.CREATED);
        form.resetUi();
    });
    dispatch(tasksDashboardSearch.actionCreators.resetState());
};

/**
 * Remove a single search filter from the Tasks Dashboard.
 * @param  {string} path
 */
export const clearTasksDashboardFilter = (path) => (dispatch, getState, { formsRegistry }) => {
    const form = formsRegistry.get(formClientEnum.TASKS_DASHBOARD_SEARCH_FORM);
    form.set(path, undefined);
    dispatch(
        tasksDashboardSearch.actionCreators.search(
            { formData: form.getState().model },
            { cacheBust: true, showLoadingModal: false }
        )
    );
};

export function applySavedSearchFilters(formModel) {
    return (dispatch, getState, { formsRegistry }) => {
        const taskFilterForm = formsRegistry.get(formClientEnum.TASKS_DASHBOARD_SEARCH_FORM);
        taskFilterForm.set('', formModel);
    };
}

export const searchInitialTasks = () => (dispatch, getState, { formsRegistry }) => {
    dispatch(tasksDashboardSearch.actionCreators.loadAndExecuteLatestAutoSavedSearch()).then(
        (result) => {
            if (!result) {
                const form = formsRegistry.get(formClientEnum.TASKS_DASHBOARD_SEARCH_FORM);

                return dispatch(
                    tasksDashboardSearch.actionCreators.search(
                        { formData: form.getState().model },
                        { cacheBust: true, showLoadingModal: false }
                    )
                );
            } else {
                const { formModel } = result;
                dispatch(applySavedSearchFilters(formModel));
            }
        }
    );
};

function buildTasksLoadAndExecuteLatestAutoSavedSearch(actionCreators, elasticSearchType) {
    return () => (dispatch) => {
        return getRmsSavedSearchResource()
            .getAutoSavedRmsSavedSearchesViewForElasticSearchType(elasticSearchType)
            .then((rmsSavedSearchesView) => {
                const { savedSearches } = rmsSavedSearchesView;

                if (size(savedSearches) > 0) {
                    const savedSearch = last(sortBy(savedSearches, 'id'));
                    const { query } = savedSearch;
                    const parsedQuery = JSON.parse(query);
                    const { query: savedSearchQuery } = parsedQuery;

                    const formModel = convertToFormModel(savedSearchQuery);

                    const convertedSavedSearch = {
                        ...savedSearch,
                        query: JSON.stringify({
                            query: formModel,
                        }),
                    };

                    // eslint-disable-next-line no-restricted-syntax
                    dispatch(
                        storeRmsSavedSearchesView({
                            rmsSavedSearchesView,
                            action: actionCreators.loadLatestAutoSavedSearchSuccess([
                                convertedSavedSearch,
                            ]),
                        })
                    );
                    return dispatch(actionCreators.executeSavedSearch(savedSearch.id));
                }
            })
            .catch(noop);
    };
}

function buildExecuteSavedSearchActionCreator(actionCreators, selectors) {
    return (savedSearchId, scroll = true) => {
        return (dispatch, getState) => {
            const state = getState();
            const savedSearch = getSavedSearchById(
                selectors.savedSearchesSelector(state),
                savedSearchId
            );
            const { query } = savedSearch;
            const parsedQuery = convertSavedSearchQueryToElasticQueryObject(query);
            const {
                query: formModel,
                from = queryParamDefaults.FROM,
                size = queryParamDefaults.SIZE,
            } = parsedQuery;

            const transformedSavedSearch = {
                ...savedSearch,
                query: JSON.stringify({
                    query: convertFromFormModel(formModel),
                    from,
                    size,
                }),
            };

            dispatch(actionCreators.executeSavedSearchStart());

            return taskResource
                .executeSavedSearch(transformedSavedSearch, { from, size })
                .then((taskView) => {
                    dispatch(withTasksView(taskView));
                    dispatch(
                        actionCreators.searchSuccess(
                            {
                                items: taskView.tasks,
                                query: {
                                    from,
                                    size,
                                    query: {
                                        ...formModel,
                                    },
                                },
                                totalCount: taskView.totalCount,
                            },
                            scroll
                        )
                    );

                    dispatch(actionCreators.executeSavedSearchSuccess(savedSearch));

                    return {
                        formModel,
                        taskView,
                    };
                })
                .catch(() => {
                    dispatch(
                        actionCreators.executeSavedSearchFailure(
                            savedSearchErrorMessages.onExecuteSavedSearchErrorMessage
                        )
                    );
                });
        };
    };
}

function buildLoadTasksSavedSearchesActionCreator(actionCreators, elasticSearchType) {
    return () => {
        return (dispatch) => {
            dispatch(actionCreators.loadSavedSearchesStart());

            return getRmsSavedSearchResource()
                .getNonAutoSavedRmsSavedSearchesViewForElasticSearchType(elasticSearchType)
                .then((rmsSavedSearchesView) => {
                    const { savedSearches } = rmsSavedSearchesView;

                    // we transform saved searches which could contain query detail objects back to ids,
                    // so that our form pre-filling works, since the FE always expects ids and not query detail objects
                    convertSavedSearchQueryShapesToLegacyQueryShape(savedSearches);
                    const convertedSavedSearches = map(savedSearches, (savedSearch) => {
                        const { query: savedSearchQuery } = savedSearch;
                        const parsedQuery = JSON.parse(savedSearchQuery);
                        const { query } = parsedQuery;
                        const formModel = convertToFormModel(query);

                        return {
                            ...savedSearch,
                            query: JSON.stringify({
                                query: formModel,
                            }),
                        };
                    });

                    // eslint-disable-next-line no-restricted-syntax
                    dispatch(
                        storeRmsSavedSearchesView({
                            rmsSavedSearchesView,
                            action: actionCreators.loadSavedSearchesSuccess(
                                sortSavedSearches(convertedSavedSearches)
                            ),
                        })
                    );
                })
                .catch(() => {
                    dispatch(
                        actionCreators.loadSavedSearchesFailure(
                            savedSearchErrorMessages.onGetSavedSearchesErrorMessage
                        )
                    );
                });
        };
    };
}

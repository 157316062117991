import { assignWith, concat, every, filter, findIndex, get, includes, keys, last } from 'lodash';

/**
 * Creates a helper function which will push an item onto a given path and form.
 */
export const createNItemsAdder = ({ getForm, path, defaults }) => (item) => {
    const form = getForm();
    const nitems = form.get(path);
    const itemWithDefaults = defaults
        ? assignWith({}, defaults, item, (a, b) => (b === null ? a : b))
        : item;
    // MFT generates a stub object when a form mounts. We are rendering victims, subjects, etc
    // using redux state as our source of truth. This leads to a race condition where
    // we render the MFT form for a victim before the victim is added to MFT state, causing
    // MFT to generate a stub, where all form keys contain `undefined` values.
    // This is not an MFT bug but an issue with forms using two sources of truth.
    // We can get around this by replacing the inital item if it is a stub, instead of
    // always pushing onto the array.
    if (nitems.length) {
        const lastItem = last(nitems);
        if (every(keys(lastItem), (key) => lastItem[key] === undefined)) {
            return form.set(`${path}[${nitems.length - 1}]`, itemWithDefaults);
        }
    }
    return form.push(path, itemWithDefaults);
};

/**
 * Creates a helper function which will push an item id onto a given path and
 * form, if the path does not already contain the id.
 */
export const createNItemsIdAdder = ({ getForm, path, id }) => {
    const form = getForm();
    const existingNItemsIds = form.get(path) || [];
    if (!includes(existingNItemsIds, id)) {
        const newNItemsIds = concat(existingNItemsIds, id);
        return form.set(path, newNItemsIds);
    }
};

/**
 * Creates a helper function which will remove an item from a given index from
 * the form.  The index is found using the given predicate.
 */
export const createNItemsRemover = ({ getForm, path }) => (predicate) => {
    // However, because the UI order of things does not match the order of form
    // state, we need to search the form state for the proper index to remove
    const form = getForm();
    const itemsInForm = get(form.getState().model, path);
    const indexToRemoveFromForm = findIndex(itemsInForm, predicate);
    // Only do the removal if we found the link in form state.
    return indexToRemoveFromForm > -1 && form.remove(path, indexToRemoveFromForm);
};

/**
 * Creates a helper function which will remove an item id from a given path from
 * the form, if the path contains the id.
 */
export const createNItemsIdRemover = ({ getForm, path, id }) => {
    const form = getForm();
    const existingNItemsIds = form.get(path) || [];
    if (includes(existingNItemsIds, id)) {
        const newNItemsIds = filter(
            existingNItemsIds,
            (existingNItemsId) => existingNItemsId !== id
        );
        form.set(path, newNItemsIds);
    }
};

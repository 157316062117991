import { DepartmentPreference } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const departmentPreferencesModule = createNormalizedModule<DepartmentPreference>({
    type: 'departmentPreferences',
});

// ACTIONS
export const storeDepartmentPreferences = departmentPreferencesModule.actionCreators.storeEntities;

// SELECTORS
export const departmentPreferencesSelector = departmentPreferencesModule.selectors.entitiesSelector;
export const departmentPreferenceByIdSelector =
    departmentPreferencesModule.selectors.entityByIdSelector;

export default departmentPreferencesModule.reducerConfig;

import { ProductModuleEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { isEmpty, size } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, cssVar } from 'arc';

import { withRouter } from 'react-router';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import componentStrings from '~/client-common/core/strings/componentStrings';

import ProfileImages from '../../core/components/EntityProfileImages';
import AttachmentDetails from '../../core/components/EntityProfileAttachmentDetails';
import LegacyEntityDetails from '../../core/components/LegacyEntityDetails';
import { personDetailsViewModelSelector, transitionWarrantsSelector } from '../../core/state/ui';
import { EntityProfileActiveTarget } from '../../core/components/EntityProfileActiveTarget/EntityProfileActiveTarget';
import IdentificationDetails from './EntityProfilePersonIdentificationDetails';
import ContactDetails from './EntityProfilePersonContactDetails';
import AppearanceDetails from './EntityProfilePersonAppearanceDetails';
import MilitaryDetails from './EntityProfilePersonMilitaryDetails';
import EducationDetails from './EntityProfilePersonEducationDetails';
import EmploymentDetails from './EntityProfilePersonEmploymentDetails';
import DemeanorDetails from './EntityProfilePersonDemeanorDetails';
import WarrantsSummary from './EntityProfilePersonWarrantsSummary';

const strings = componentStrings.entityProfiles.person;

function EntityProfilePersonDetails({ personDetailsViewModel, transitionWarrants }) {
    return !personDetailsViewModel ? (
        <div />
    ) : (
        <div>
            <Box display="flex" flexDirection="column" gap={cssVar('arc.space.3')}>
                <ProductModuled productModule={ProductModuleEnum.WARRANTS.name}>
                    <WarrantsSummary
                        transitionWarrants={transitionWarrants}
                        personProfileId={personDetailsViewModel.id}
                    />
                </ProductModuled>
                <EntityProfileActiveTarget />
            </Box>
            <IdentificationDetails personDetailsViewModel={personDetailsViewModel} />
            <ContactDetails personDetailsViewModel={personDetailsViewModel} />
            <AppearanceDetails personDetailsViewModel={personDetailsViewModel} />
            {!isEmpty(personDetailsViewModel.militaryHistories) && (
                <MilitaryDetails militaryHistories={personDetailsViewModel.militaryHistories} />
            )}

            {!isEmpty(personDetailsViewModel.schoolHistories) && (
                <EducationDetails schoolHistories={personDetailsViewModel.schoolHistories} />
            )}

            {!isEmpty(personDetailsViewModel.employmentHistories) && (
                <EmploymentDetails
                    employmentHistories={personDetailsViewModel.employmentHistories}
                />
            )}
            {(personDetailsViewModel.nameAttributesDisplay.BEHAVIORAL_CHARACTERISTIC ||
                personDetailsViewModel.nameAttributesDisplay.MOOD ||
                personDetailsViewModel.nameAttributesDisplay.MODUS_OPERANDI ||
                personDetailsViewModel.nameAttributesDisplay.PERSON_SKILL) && (
                <DemeanorDetails nameAttributes={personDetailsViewModel.nameAttributesDisplay} />
            )}
            {!isEmpty(personDetailsViewModel.profileImages) && (
                <ProfileImages
                    title={strings.ProfileImages.title}
                    images={personDetailsViewModel.profileImages}
                />
            )}
            <AttachmentDetails
                entityId={personDetailsViewModel.id}
                linkType={[
                    LinkTypesEnum.PERSON_PROFILE_ATTACHMENT,
                    LinkTypesEnum.DRIVER_LICENSE_FRONT_PHOTO,
                    LinkTypesEnum.DRIVER_LICENSE_BACK_PHOTO,
                ]}
            />
            {size(personDetailsViewModel.legacyEntityDetails) > 0 && (
                <LegacyEntityDetails
                    legacyEntityDetails={personDetailsViewModel.legacyEntityDetails}
                />
            )}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    personDetailsViewModel: personDetailsViewModelSelector,
    transitionWarrants: transitionWarrantsSelector,
});

export default compose(withRouter, connect(mapStateToProps, null))(EntityProfilePersonDetails);

import _ from 'lodash';
import {
    callForServicesSelector,
    storeCallForServices,
} from '~/client-common/core/domain/call-for-services/state/data';
import callForServiceResource from '../../resources/callForServiceResource';
import AdminBulkImportResource from '../../../core/import-export/resources/AdminBulkImportResource';
import {
    createBulkImportActionCreator,
    createValidateBulkActionCreator,
    createExportActionCreator,
} from '../../../core/utils/importExportHelpers';
import { userDepartmentIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';

export const CALL_FOR_SERVICE_PAGE_LOAD_START = 'callForServices/CALL_FOR_SERVICE_PAGE_LOAD_START';
export const CALL_FOR_SERVICE_PAGE_LOAD_SUCCESS =
    'callForServices/CALL_FOR_SERVICE_PAGE_LOAD_SUCCESS';
const CALL_FOR_SERVICE_PAGE_LOAD_FAILURE = 'callForServices/CALL_FOR_SERVICE_PAGE_LOAD_FAILURE';
export const SAVE_CALL_FOR_SERVICE_START = 'callForServices/SAVE_CALL_FOR_SERVICE_START';
export const SAVE_CALL_FOR_SERVICE_SUCCESS = 'callForServices/SAVE_CALL_FOR_SERVICE_SUCCESS';
export const SAVE_CALL_FOR_SERVICE_FAILURE = 'callForServices/SAVE_CALL_FOR_SERVICE_FAILURE';
const FETCH_CALL_FOR_SERVICE_HISTORY_START = 'callForServices/FETCH_CALL_FOR_SERVICE_HISTORY_START';
export const FETCH_CALL_FOR_SERVICE_HISTORY_SUCCESS =
    'callForServices/FETCH_CALL_FOR_SERVICE_HISTORY_SUCCESS';
const FETCH_CALL_FOR_SERVICE_HISTORY_FAILURE =
    'callForServices/FETCH_CALL_FOR_SERVICE_HISTORY_FAILURE';
const FETCH_ALL_CALL_FOR_SERVICE_HISTORY_START =
    'callForServices/FETCH_ALL_CALL_FOR_SERVICE_HISTORY_START';
export const FETCH_ALL_CALL_FOR_SERVICE_HISTORY_SUCCESS =
    'callForServices/FETCH_ALL_CALL_FOR_SERVICE_HISTORY_SUCCESS';
const FETCH_ALL_CALL_FOR_SERVICE_HISTORY_FAILURE =
    'callForServices/FETCH_ALL_CALL_FOR_SERVICE_HISTORY_FAILURE';

function rawPageLoad() {
    return {
        type: CALL_FOR_SERVICE_PAGE_LOAD_START,
    };
}

function formatCallForServicesForDataNexus(callForServices) {
    const formatted = [];

    _.each(callForServices, (cfs) => {
        formatted.push({
            ...cfs,
            id: cfs.callForService.id,
        });
    });

    return formatted;
}

export function loadCallForServiceAdmin() {
    return function (dispatch, getState) {
        const state = getState();
        const departmentId = userDepartmentIdSelector(state);

        dispatch(rawPageLoad());

        return callForServiceResource
            .getCallForServicesForDept(departmentId)
            .then((results) => {
                const callForServices = formatCallForServicesForDataNexus(results);
                dispatch(storeCallForServices(callForServices));
                dispatch(callForServicePageLoadSuccess(callForServices));
            })
            .catch((err) => dispatch(callForServicePageLoadFailure(err)));
    };
}

function callForServicePageLoadSuccess(callForServices) {
    return {
        type: CALL_FOR_SERVICE_PAGE_LOAD_SUCCESS,
        payload: {
            callForServices,
        },
    };
}

function callForServicePageLoadFailure(err) {
    return {
        type: CALL_FOR_SERVICE_PAGE_LOAD_FAILURE,
        payload: err,
    };
}

function saveCallForServiceStart(cfs) {
    return {
        type: SAVE_CALL_FOR_SERVICE_START,
        payload: cfs,
    };
}

function saveCallForServiceSuccess(cfs) {
    return {
        type: SAVE_CALL_FOR_SERVICE_SUCCESS,
        payload: cfs,
    };
}

export function saveCallForServiceFailure(err) {
    return {
        type: SAVE_CALL_FOR_SERVICE_FAILURE,
        payload: err,
    };
}

function fetchCallForServiceHistoryStart() {
    return {
        type: FETCH_CALL_FOR_SERVICE_HISTORY_START,
    };
}

function fetchCallForServiceHistorySuccess(callForServiceId, histories) {
    return {
        type: FETCH_CALL_FOR_SERVICE_HISTORY_SUCCESS,
        payload: { callForServiceId, histories },
    };
}

function fetchCallForServiceHistoryFailure(callForServiceId, err) {
    return {
        type: FETCH_CALL_FOR_SERVICE_HISTORY_FAILURE,
        payload: { callForServiceId, err },
    };
}

function fetchAllCallForServicesHistoryStart() {
    return {
        type: FETCH_ALL_CALL_FOR_SERVICE_HISTORY_START,
    };
}

function fetchAllCallForServicesHistorySuccess(histories) {
    return {
        type: FETCH_ALL_CALL_FOR_SERVICE_HISTORY_SUCCESS,
        payload: { histories },
    };
}

function fetchAllCallForServicesHistoryFailure(err) {
    return {
        type: FETCH_ALL_CALL_FOR_SERVICE_HISTORY_FAILURE,
        payload: { err },
    };
}

export function saveCallForService({ fullCallForService }) {
    return function (dispatch, getState) {
        dispatch(saveCallForServiceStart());

        const callForServiceDetails = _.map(
            _.get(fullCallForService, 'callForServiceDetails', []),
            (cfsDetail) => ({
                ...cfsDetail,
                callForServiceId: _.get(fullCallForService, 'callForService.id'),
            })
        );

        const payload = {
            callForService: fullCallForService.callForService,
            callForServiceDetails,
        };

        // we need to track which IDs we already had because
        // we receive the full list back from the server... ugh
        const allExistingIds = _.map(callForServicesSelector(getState()), 'id');

        return callForServiceResource
            .upsertCallForService(payload)
            .then((response) => {
                const allNewIds = _.map(_.map(response, 'callForService'), 'id');
                const newId = _(allNewIds).difference(allExistingIds).head();

                dispatch(
                    saveCallForServiceSuccess({
                        callForServices: _.map(response, 'callForService'),
                        newId: newId || fullCallForService.id, // it could be saving an existing one
                    })
                );

                return {
                    ...payload,
                    id: newId,
                };
            })
            .catch((err) => {
                dispatch(saveCallForServiceFailure(err));
            });
    };
}

export function fetchHistoryForCallForServiceStart(id) {
    return function (dispatch) {
        dispatch(fetchCallForServiceHistoryStart());
        return callForServiceResource
            .fetchHistoryForCallForService(id)
            .then((histories) => {
                dispatch(fetchCallForServiceHistorySuccess(id, histories));
            })
            .catch((err) => {
                dispatch(fetchCallForServiceHistoryFailure(id, err));
            });
    };
}

export function fetchHistoryForAllCallForServicesStart() {
    return function (dispatch) {
        dispatch(fetchAllCallForServicesHistoryStart());
        return callForServiceResource
            .fetchHistoryForAllCallForServices()
            .then((histories) => {
                dispatch(fetchAllCallForServicesHistorySuccess(histories));
            })
            .catch((err) => {
                dispatch(fetchAllCallForServicesHistoryFailure(err));
            });
    };
}

/**
 * Import attributes from an uploaded file.
 * @param  {BulkImportRequest} BulkImportRequest
 * @return {Promise}
 */
const importCFSs = createBulkImportActionCreator(AdminBulkImportResource.bulkImportCadAdmin);

/**
 * Validate that a file is valid for importing new attributes.
 * @param  {BulkImportRequest} BulkImportRequest
 * @return {Promise}
 */
export const validateCFSImport = createValidateBulkActionCreator(
    AdminBulkImportResource.validateBulkImportCadAdmin,
    importCFSs
);

/**
 * Export all attributes into a CSV file.
 * @return {Promise}
 */
export const exportCFSs = createExportActionCreator(callForServiceResource.exportAttributes);

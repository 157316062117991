import React from 'react';
import { useSelector } from 'react-redux';
import { NavItemToggle } from '../../../../core/components/Navigation/components';
import {
    itemQueuePopoverIsOpenSelector,
    itemQueueCountSelector,
} from '../../../../evidence/item-queue/state/ui';
import UpdatedItemQueuePopoverContent from '../../../../evidence/item-queue/components/UpdatedItemQueuePopover';
import testIds from '../../../../../core/testIds';

const ItemQueueNavItem = ({
    onPath,
}: {
    // If the current page is the item queue.
    onPath: boolean;
}) => {
    const itemQueuePopoverIsOpen = useSelector(itemQueuePopoverIsOpenSelector);
    const itemQueueCount = useSelector(itemQueueCountSelector);

    return (
        <UpdatedItemQueuePopoverContent
            renderButton={({ ...props }) => {
                return (
                    <NavItemToggle
                        icon="Property"
                        label="Item Basket"
                        count={itemQueueCount}
                        ref={props.ref}
                        isActive={itemQueuePopoverIsOpen || onPath}
                        data-test-id={testIds.EVIDENCE_ITEM_BASKET}
                        {...props}
                    />
                );
            }}
        />
    );
};

/**
 * Navigation link to open the Item Queue Popover.
 */
export default ItemQueueNavItem;

import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { combinedSubdivisionsLabelSelector } from '~/client-common/core/fields/state/config';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_CAD_LABEL,
    DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL,
} from '~/client-common/core/enums/universal/fields';

import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { RRFAgencyProfileSelect } from '../../../core/forms/components/selects/AgencyProfileSelect';
import { RRFConsortiumDepartmentSelect } from '../../../core/forms/components/selects/ConsortiumDepartmentSelect';
import testIds from '../../../../core/testIds';

import { cadTicketFieldsetViewModel } from '../../../../legacy-redux/configs/fieldsetsConfig';
import { SubdivisionsSearch } from '../../core/components/SubdivisionsSearch';

const mapStateToProps = createStructuredSelector({
    combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
});

/**
 * Fieldset for CAD details.
 * @param {Object}  props
 */
const CadTicketFieldset = function ({
    fuzzyMatchingEnabled,
    combinedSubdivisionsLabel,
    consortiumDepartmentLinksAvailable,
    hideAgencySelect,
    ...otherProps
}) {
    const fieldsLabels = useFields([
        DISPLAY_ONLY_CAD_LABEL,
        DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL,
    ]);
    const cadDisplayName = fieldsLabels[DISPLAY_ONLY_CAD_LABEL];

    return (
        <RRFFieldset path="cadTicket" {...otherProps}>
            <Row>
                <div data-test-id={testIds.ADVANCED_SEARCH_EVENT_DATE_TIME_RANGE}>
                    <RRFDateRangePicker
                        label={fieldsLabels[DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL]}
                        width={330}
                        includeTime={true}
                        withinLastPeriodOptions={[
                            'PT1H',
                            'PT4H',
                            'PT8H',
                            'PT12H',
                            'PT24H',
                            'PT48H',
                            'P7D',
                            'P28D',
                        ]}
                        toDatePeriodOptions={[]}
                    />
                </div>
            </Row>
            <Row>
                <RRFNItems
                    addItemOnDirty={true}
                    path="agencyEventNumbers"
                    className="mark-43-n-items-column"
                >
                    {() => (
                        <RRFText
                            label={cadTicketFieldsetViewModel.fields.agencyEventNumbers.fields.agencyEventNumber.label(
                                cadDisplayName
                            )}
                            width={248}
                            className={classNames({
                                'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                            })}
                            path="agencyEventNumber"
                        />
                    )}
                </RRFNItems>
                <FeatureFlagged
                    flag="CAD_MULTI_REN_GENERATION_ENABLED"
                    fallback={
                        <RRFNItems
                            addItemOnDirty={true}
                            path="reportingEventNumbers"
                            className="mark-43-n-items-column"
                        >
                            {() => (
                                <RRFText
                                    width={248}
                                    className={classNames({
                                        'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                                    })}
                                    path="reportingEventNumber"
                                />
                            )}
                        </RRFNItems>
                    }
                >
                    <RRFNItems addItemOnDirty={true} path="rens" className="mark-43-n-items-column">
                        {() => (
                            <div data-test-id={testIds.CAD_TICKET_REN}>
                                <RRFText
                                    width={248}
                                    className={classNames({
                                        'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                                    })}
                                    path="ren"
                                />
                            </div>
                        )}
                    </RRFNItems>
                </FeatureFlagged>
            </Row>
            <SubdivisionsSearch width={179} />
            <Row>
                <FeatureFlagged
                    flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED"
                    fallback={
                        <RRFAttributeSelect
                            attributeType={[
                                'SUBDIVISION_DEPTH_1',
                                'SUBDIVISION_DEPTH_2',
                                'SUBDIVISION_DEPTH_3',
                                'SUBDIVISION_DEPTH_4',
                                'SUBDIVISION_DEPTH_5',
                            ]}
                            multiple={true}
                            includeExpired={true}
                            width={178}
                            label={combinedSubdivisionsLabel}
                            path="subdivisionAttrIds"
                        />
                    }
                />
                <RRFAttributeSelect
                    attributeType="CAD_EVENT_TYPE"
                    multiple={true}
                    includeExpired={true}
                    width={248}
                    path="callTypeAttrIds"
                />
                <div data-test-id={testIds.CAD_TICKET_CALL_EVENT_TYPE}>
                    <RRFAttributeSelect
                        attributeType="CAD_SECONDARY_EVENT_TYPE"
                        multiple={true}
                        includeExpired={true}
                        width={248}
                        path="callEventTypeAttrIds"
                    />
                </div>
            </Row>
            <Row>
                {consortiumDepartmentLinksAvailable && (
                    <RRFConsortiumDepartmentSelect
                        path="departmentIds"
                        multiple={true}
                        width={178}
                    />
                )}
                {!hideAgencySelect && (
                    <RRFAgencyProfileSelect path="agencyIds" multiple={true} width={178} />
                )}
                <div data-test-id={testIds.CAD_TICKET_HAS_REPORT}>
                    <RRFBooleanSelect width={178} path="hasReport" />
                </div>
                <div data-test-id={testIds.CAD_TICKET_REQUIRES_REPORT}>
                    <RRFBooleanSelect width={178} path="requiresReport" />
                </div>
                <RRFText width={178} path="callerPhoneNumber" />
            </Row>
            <Row>
                {
                    // each of these 2 fields is typed in as a string and
                    // converted to a one-element array for the search endpoint
                }
                <RRFText width={178} path="primaryUnitCallSigns" />
                <RRFText width={178} path="involvedUnitCallSigns" />
                <RRFUserSelect
                    multiple={true}
                    width={178}
                    path="primaryOfficerIds"
                    effectiveDate={null}
                />
                <RRFUserSelect
                    multiple={true}
                    width={178}
                    path="involvedOfficerIds"
                    effectiveDate={null}
                />
            </Row>
        </RRFFieldset>
    );
};

export default connect(mapStateToProps)(CadTicketFieldset);

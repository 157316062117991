/**
 * How many labels to print in a single batch.
 *
 * If the user tries to print `L` labels, then those labels will be printed over
 *   `Math.ceil(L / LABEL_PRINTING_BATCH_SIZE)` separate batches.
 * @type {number}
 */
export const LABEL_PRINTING_BATCH_SIZE = 10;

/**
 * How many milliseconds to wait before printing the next batch.
 * @type {number}
 */
export const LABEL_PRINTING_BATCH_DELAY = 1000;

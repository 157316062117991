import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import stringsConfig from '~/client-common/core/strings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import { clearRenChangeModal } from '../../actions/renChangeActions';
import { closeBox, saveBoxHalt } from '../../actions/boxActions';
import { renChangeUiSelector, renChangeFormSelector } from '../../selectors/renChangeSelectors';
import { currentReportSelector } from '../../selectors/reportSelectors';
import { minModalContentHeight } from '../../configs/renChangeConfig';
import Modal from '../core/Modal';
import RenChangeForm from './RenChangeForm';

const strings = stringsConfig.components.reports.RenChangeModal;

const context = {
    name: boxEnum.REN_CHANGE_MODAL,
};

function RenChangeModal({ ui, currentReport, saveBoxHalt, closeBox, formatFieldByName }) {
    const handleSubmit = () => {
        const result = ui.handleSubmit();
        if (!result) {
            // on field validation error, get out of the `saving` state; this
            // functionality should be included in `Modal` and not here
            saveBoxHalt();
        }
        return result;
    };
    const currentRen = currentReport ? currentReport.reportingEventNumber : '';
    const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
    return (
        <Modal
            context={context}
            title={
                currentRen ? strings.changeRen(renDisplayName) : strings.linkToRen(renDisplayName)
            }
            onClose={closeBox}
            onSave={ui.changeEnabled ? handleSubmit : closeBox}
            contentStyle={{ minHeight: minModalContentHeight }}
            okText={ui.changeEnabled ? undefined : ''}
        >
            <RenChangeForm
                initialValues={{
                    currentRen,
                    newRen: '',
                }}
            />
        </Modal>
    );
}

const selectors = createStructuredSelector({
    ui: renChangeUiSelector,
    form: renChangeFormSelector,
    currentReport: currentReportSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = (dispatch) => ({
    saveBoxHalt: () => dispatch(saveBoxHalt(context)),
    closeBox: () => {
        dispatch(clearRenChangeModal());
        dispatch(closeBox(context));
    },
});

export default connect(selectors, mapDispatchToProps)(RenChangeModal);

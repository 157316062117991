import boxEnum from '~/client-common/core/enums/client/boxEnum';

import { openBox, closeBox } from '../../../../../legacy-redux/actions/boxActions';

const filterModalContext = {
    name: boxEnum.ADMIN_USERS_DASHBOARD_FILTER_MODAL,
};

export function openUsersDashboardFilterModal() {
    return openBox(filterModalContext);
}

export function closeUsersDashboardFilterModal() {
    return closeBox(filterModalContext);
}

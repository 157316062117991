import { EntityTypeEnum, SystemRuleEnum, ChainEventCategoryEnum } from '@mark43/evidence-api';
import { actions } from 'react-redux-form';

import { chainEventTypesSelector } from '~/client-common/core/domain/chain-event-types/state/data';

import addRuleId from '../../../../../legacy-redux/validation/helpers/addRuleId';
import validationErrorMessagesByRule from '../../../../../legacy-redux/configs/validationErrorMessagesByRule';

// helper to make the validators less verbose
function isChainEventType({ eventTypeId }, state, chainEventCategory) {
    const chainEventType = chainEventTypesSelector(state)[eventTypeId] || {};
    return chainEventType.chainEventCategory === chainEventCategory;
}

export const chainEventCheckedInMainRequiresStorageLocation = addRuleId(
    SystemRuleEnum.CHAIN_EVENT_CHECKED_IN_MAIN_REQUIRES_STORAGE_LOCATION.name,
    (formModel, value, state) => {
        const isCheckedInMain = isChainEventType(
            formModel,
            state,
            ChainEventCategoryEnum.CHECKED_IN_MAIN.name
        );
        return isCheckedInMain ? !!formModel.storageLocationId : true;
    }
);

export const chainEventCheckedOutLabRequiresFacility = addRuleId(
    SystemRuleEnum.CHAIN_EVENT_CHECKED_OUT_LAB_REQUIRES_FACILITY.name,
    (formModel, value, state) => {
        const isCheckedOutLab = isChainEventType(
            formModel,
            state,
            ChainEventCategoryEnum.CHECKED_OUT_LAB.name
        );
        return isCheckedOutLab ? !!formModel.facilityId : true;
    }
);

export const chainEventCheckedOutPersonRequiresReceivedByName = addRuleId(
    SystemRuleEnum.CHAIN_EVENT_CHECKED_OUT_PERSON_REQUIRES_RECEIVED_BY_NAME.name,
    (formModel, value, state) => {
        const isCheckedOutPerson = isChainEventType(
            formModel,
            state,
            ChainEventCategoryEnum.CHECKED_OUT_PERSON.name
        );
        if (!isCheckedOutPerson) {
            return true;
        }
        const isUser = formModel.receivedByEntityType === EntityTypeEnum.USER.name;
        return isUser ? !!formModel.receivedByEntityId : !!formModel.receivedByName;
    }
);

export const chainEventTransferredRequiresReceivedByName = addRuleId(
    SystemRuleEnum.CHAIN_EVENT_TRANSFERRED_REQUIRES_RECEIVED_BY_NAME.name,
    (formModel, value, state) => {
        const isTransferred = isChainEventType(
            formModel,
            state,
            ChainEventCategoryEnum.TRANSFERRED.name
        );
        return isTransferred ? !!formModel.receivedByName : true;
    }
);

export const chainEventLocationUpdatedRequiresStorageLocation = addRuleId(
    SystemRuleEnum.CHAIN_EVENT_LOCATION_UPDATED_REQUIRES_STORAGE_LOCATION.name,
    (formModel, value, state) => {
        const isLocationUpdated = isChainEventType(
            formModel,
            state,
            ChainEventCategoryEnum.LOCATION_UPDATED.name
        );
        return isLocationUpdated ? !!formModel.storageLocationId : true;
    }
);

export const chainEventReleasedRequiredReceivedByName = addRuleId(
    SystemRuleEnum.CHAIN_EVENT_RELEASED_REQUIRES_RECEIVED_BY_NAME.name,
    (formModel, value, state) => {
        const isReleased = isChainEventType(formModel, state, ChainEventCategoryEnum.RELEASED.name);
        return isReleased ? !!formModel.receivedByName : true;
    }
);

export const chainEventSignatureRequiresSignatureEnabled = (dispatch) =>
    addRuleId(
        SystemRuleEnum.CHAIN_EVENT_SIGNATURE_REQUIRES_SIGNATURE_ENABLED.name,
        (formModel, value, state) => {
            const { eventTypeId, signatureFileId } = formModel;
            const { requiresSignature } = chainEventTypesSelector(state)[eventTypeId] || {};
            const valid = requiresSignature ? !!signatureFileId : true;

            // TODO tech debt: we are manually setting the error message or validity
            // state here because this system rule and the one below apply to the
            // same field, ATTACHMENT_ENTITY_ID, which means the legacy validation
            // runner cannot distinguish the 2 fields in order to set the error
            // message or validity state on only 1 of them
            if (!valid) {
                dispatch(
                    actions.setErrors(
                        'formModels.evidenceCreateChainEvents.signatureFileId',
                        validationErrorMessagesByRule[
                            SystemRuleEnum.CHAIN_EVENT_SIGNATURE_REQUIRES_SIGNATURE_ENABLED.name
                        ].field
                    )
                );
            } else {
                dispatch(
                    actions.setValidity(
                        'formModels.evidenceCreateChainEvents.signatureFileId',
                        true
                    )
                );
            }
            return valid;
        }
    );

export const chainEventIDScanRequiresIDScanEnabled = (dispatch) =>
    addRuleId(
        SystemRuleEnum.CHAIN_EVENT_ID_SCAN_REQUIRES_ID_SCAN_ENABLED.name,
        (formModel, value, state) => {
            const { eventTypeId, idScanFileId } = formModel;
            const { requiresIDScan } = chainEventTypesSelector(state)[eventTypeId] || {};
            const valid = requiresIDScan ? !!idScanFileId : true;

            // TODO tech debt: see above
            if (!valid) {
                dispatch(
                    actions.setErrors(
                        'formModels.evidenceCreateChainEvents.idScanFileId',
                        validationErrorMessagesByRule[
                            SystemRuleEnum.CHAIN_EVENT_ID_SCAN_REQUIRES_ID_SCAN_ENABLED.name
                        ].field
                    )
                );
            } else {
                dispatch(
                    actions.setValidity('formModels.evidenceCreateChainEvents.idScanFileId', true)
                );
            }

            return valid;
        }
    );

export const chainEventCheckedInTempRequiredStorageLocation = addRuleId(
    SystemRuleEnum.CHAIN_EVENT_CHECKED_IN_TEMP_REQUIRES_STORAGE_LOCATION.name,
    (formModel, value, state) => {
        const isCheckedInTemp = isChainEventType(
            formModel,
            state,
            ChainEventCategoryEnum.CHECKED_IN_TEMP.name
        );

        return isCheckedInTemp ? !!formModel.storageLocationId : true;
    }
);

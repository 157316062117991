import styled from 'styled-components';

const Tag = styled.span`
    color: ${(props) => props.theme.colors.red};
    font-weight: 700;
    text-transform: uppercase;
    font-size: var(--arc-fontSizes-sm);
    margin-right: 5px;
`;

export default Tag;

import { EntityTypeEnum, PrintingDataTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import _, {
    compact,
    filter,
    find,
    first,
    get,
    map,
    join,
    findIndex,
    sortBy,
    includes,
    isEmpty,
} from 'lodash';
import { getValues } from 'redux-form-mark43';

import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { custodialPropertySummaryReportDefinitionSelector } from '~/client-common/core/domain/report-definitions/state/data';
import packetTypeEnum from '~/client-common/core/enums/client/packetTypeEnum';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE,
} from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { prettify } from '~/client-common/helpers/stringHelpers';
import { replaceTextIfEvidenceModuleNameIsNotEvidence } from '~/client-common/helpers/evidenceModuleNameReplacementHelpers';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { currentUserHasAbilitySelector } from '../../modules/core/current-user/state/ui';
import { getMatchingExportPresetReportTypeForPacket } from '../../modules/exports/core/utils/getMatchingExportPresetReportTypeForPacket';
import { isInvolvedPersonSealedForReportIdSelector } from '../../modules/record-privacy/sealing/state/ui';
import { alwaysIncludeChildPrintables } from '../helpers/exportHelper';
import { FILLABLE_PDF_TEMPLATE_TYPES } from '../configs/exportConfig';
import { currentReportSelector } from './reportSelectors';

const strings = componentStrings.core.exportSelectors;
const checkboxStrings = componentStrings.reports.core.SortedReportExportsCheckboxTree;

export const rawPacketOptionsSelector = (state) => state.data.exports.packetOptions;
const getMyExports = (state) => state.data.exports.myExports;

export const redactionWorkOrderSelector = (state) => state.data.exports.redactionWorkOrder;

// groupTitle + entityId should be unique, but entityId will be undefined for CSVs, so include title
const getValueForPrintable = (printable) =>
    `${printable.groupTitle}-${printable.title}-${printable.entityId}`;
const getValueForChildPrintable = (child, parent) =>
    `${getValueForPrintable(parent)}-${getValueForPrintable(child)}`;
const getValueForExportOption = (option, parent) =>
    `${getValueForPrintable(parent)}-${option.exportOptionName}-${option.exportOptionValue}`;
const getValueForChildExportOption = (option, parent, child) =>
    `
    ${getValueForChildPrintable(child, parent)}-${option.exportOptionName}-${
        option.exportOptionValue
    }
`.trim();

const processChildPacket = (childPacket, parentPacket, grandParentPacket) => {
    const childExportOptions = map(childPacket.exportOptions, (option) => {
        return {
            display: option.exportOptionDisplay,
            value: getValueForChildExportOption(option, parentPacket, childPacket),
            parentValue: getValueForChildPrintable(childPacket, parentPacket),
            packetType: packetTypeEnum.CHILD_EXPORT_OPTION,
            rawExportOption: option,
            rawChild: childPacket,
            rawPacket: parentPacket,
        };
    });

    const hydratedChildPacket = [
        {
            display: `${childPacket.groupTitle} ${childPacket.fileName}`,
            attachmentCount: childPacket.attachmentCount || 0,
            mugshotAttachmentCount: parentPacket.mugshotAttachmentCount || 0,
            privacyDisplay: childPacket.privacyDisplay,
            value: getValueForChildPrintable(childPacket, parentPacket),
            // for packets nested within child packets, the parent value must be the value of the "parent", which would be a child printable in this case
            parentValue: grandParentPacket
                ? getValueForChildPrintable(parentPacket, grandParentPacket)
                : getValueForPrintable(parentPacket),
            skipSubtree: !alwaysIncludeChildPrintables(childPacket.dataType),
            entityId: childPacket.entityId,
            entityType: childPacket.dataType,
            templateType: childPacket.templateType,
            packetType: packetTypeEnum.CHILD,
            rawChild: childPacket,
            rawPacket: parentPacket,
        },
        ...childExportOptions,
    ];

    let descendantPackets = [];
    if (childPacket.childPrintables && childPacket.childPrintables.length > 0) {
        descendantPackets = _(childPacket.childPrintables)
            .flatMap((descendantPacket) => [
                ...processChildPacket(descendantPacket, childPacket, parentPacket),
            ])
            .flatten()
            .value();
    }

    return [...hydratedChildPacket, descendantPackets];
};

const processPacket = (packet, formatMiniUserById, currentUserHasAbility, dateTimeFormatter) => {
    if (
        packet.dataType !== PrintingDataTypeEnum.CASE_NOTE.name ||
        currentUserHasAbility(abilitiesEnum.CASES.VIEW_NOTES)
    ) {
        const ownerName = formatMiniUserById(packet.ownerId, { firstNameAsInitial: true });
        const createdDate = dateTimeFormatter.formatSummaryDate(packet.createdDateUtc);

        const regularPacket = {
            display: packet.title,
            attachmentCount: packet.attachmentCount || 0,
            mugshotAttachmentCount: packet.mugshotAttachmentCount || 0,
            childAttachments: packet.childrenContainingAttachments,
            privacyDisplay: packet.privacyDisplay,
            noteDisplay: ownerName ? ` - Owned By ${ownerName}` : '',
            value: getValueForPrintable(packet),
            isAssociatedRecord: packet.isAssociatedRecord,
            parentValue: packet.groupTitle,
            // If alwaysIncludeChildPrintables(packet.dataType) is true, we want to check all children
            // checkboxes when checking the parent checkbox. So, we don't want to skip the subTree in this case.
            skipSubtree: !alwaysIncludeChildPrintables(packet.dataType),
            entityId: packet.entityId,
            entityType: packet.dataType,
            rmsEventId: packet.rmsEventId,
            templateType: packet.templateType,
            clientApprovalStatus: packet.clientApprovalStatus,
            packetType: packetTypeEnum.REGULAR,
            rawPacket: packet,
            ownerName,
            createdDate,
            createdDateUtc: packet.createdDateUtc,
        };

        // in the UI, child printables will end up nested under parents b/c
        // CheckboxTree matches things up based on parentValue
        let childPackets = [];
        if (packet.childPrintables && packet.childPrintables.length > 0) {
            childPackets = _(packet.childPrintables)
                .flatMap((childPacket) => [...processChildPacket(childPacket, packet, undefined)])
                .flatten()
                .value();
        }

        return [regularPacket, ...childPackets];
    }

    return [];
};

// return type is PacketOption[]
const prettyPacketOptionsSelector = createSelector(
    [
        rawPacketOptionsSelector,
        formatMiniUserByIdSelector,
        currentUserHasAbilitySelector,
        formatFieldByNameSelector,
        currentDepartmentDateFormatterSelector,
    ],
    (packets, formatMiniUserById, currentUserHasAbility, formatFieldByName, dateTimeFormatter) => {
        const exportOptions = _(packets)
            .map((packet) => {
                return map(packet.exportOptions, (option) => ({
                    display: option.exportOptionDisplay,
                    value: getValueForExportOption(option, packet),
                    parentValue: getValueForPrintable(packet),
                    disabled: !option.isEnabled,
                    entityId: packet.entityId,
                    attachmentCount: packet.attachmentCount || 0,
                    mugshotAttachmentCount: packet.mugshotAttachmentCount || 0,
                    templateType: packet.templateType,
                    packetType: packetTypeEnum.EXPORT_OPTION,
                    rawExportOption: option,
                    reportDefinitionId: packet.reportDefinitionId,
                    reportPrintingTemplateId: packet.reportPrintingTemplateId,
                }));
            })
            .flatten()
            .value();

        const allPacketOptionsIncludingNested = _(packets)
            .flatMap((packet) =>
                processPacket(packet, formatMiniUserById, currentUserHasAbility, dateTimeFormatter)
            )
            .value();

        const originalPacketOptions = [...allPacketOptionsIncludingNested, ...exportOptions];

        // this is a temporary solution for RMS-15751,
        // to replace the word evidence in parentValue as
        // display name of field DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE
        const prettifiedEvidenceModuleName = prettify(
            formatFieldByName(DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE)
        );
        const textReplacedPacketOptions = originalPacketOptions.map((packetOption) => {
            return {
                ...packetOption,
                parentValue: replaceTextIfEvidenceModuleNameIsNotEvidence(
                    packetOption.parentValue,
                    prettifiedEvidenceModuleName
                ),
            };
        });

        return textReplacedPacketOptions;
    }
);

const uiSelector = (state) => ({
    loadedOptions: state.ui.exports.loadedOptions,
    failedToLoadOptions: state.ui.exports.failedToLoadOptions,
    expanded: state.ui.exports.expanded,
    loading: state.ui.exports.loading,
    positionRight: state.ui.exports.positionRight,
});

const currentValueOfSelectedPacketsSelector = (state) => {
    return state.form.exports ? state.form.exports.packets.value : [];
};

export const exportsFormDataSelector = (state) => getValues(state.form.exports);

export const getUpdatedDescendantsWithExportOptions = (
    childPrintable,
    parentPrintable,
    selectedPrintableValues
) => {
    if (selectedPrintableValues && selectedPrintableValues.length > 0) {
        const updatedChildPrintable = {
            ...childPrintable,
            exportOptions: filterSelectedExportOptions(
                childPrintable,
                parentPrintable,
                selectedPrintableValues
            ),
        };

        if (childPrintable.childPrintables && childPrintable.childPrintables.length > 0) {
            updatedChildPrintable.childPrintables = updatedChildPrintable.childPrintables.map(
                (descendant) => {
                    return getUpdatedDescendantsWithExportOptions(
                        descendant,
                        childPrintable,
                        selectedPrintableValues
                    );
                }
            );
        }

        return updatedChildPrintable;
    }

    return childPrintable;
};

const filterSelectedExportOptions = (childPrintable, parentPrintable, selectedPrintableValues) => {
    if (selectedPrintableValues && selectedPrintableValues.length > 0) {
        return childPrintable.exportOptions.filter((option) => {
            return (
                option &&
                selectedPrintableValues.includes(
                    getValueForChildExportOption(option, parentPrintable, childPrintable)
                )
            );
        });
    } else {
        return childPrintable.exportOptions;
    }
};

const filterSelectedChildPrintables = (
    parentPrintable,
    childPrintables,
    selectedPrintableValues
) => {
    if (selectedPrintableValues && selectedPrintableValues.length > 0) {
        return childPrintables.filter((childPrintable) => {
            const valueForChildPrintable = getValueForChildPrintable(
                childPrintable,
                parentPrintable
            );
            const isChildPrintableSelected =
                includes(selectedPrintableValues, valueForChildPrintable) ||
                !isEmpty(childPrintable.exportOptions);

            if (childPrintable.childPrintables && childPrintable.childPrintables.length > 0) {
                const selectedDescendants = filterSelectedChildPrintables(
                    childPrintable,
                    childPrintable.childPrintables,
                    selectedPrintableValues
                );
                childPrintable.childPrintables = selectedDescendants;

                return selectedDescendants.length > 0;
            } else {
                return isChildPrintableSelected;
            }
        });
    } else {
        return childPrintables;
    }
};

const selectedPacketsSelector = createSelector(
    [currentValueOfSelectedPacketsSelector, rawPacketOptionsSelector],
    (selectedPrintableValues, allPacketOptions) => {
        const packetOptions = _(allPacketOptions)
            .map((printable) => {
                const childPrintablesWithExportOptions = map(printable.childPrintables, (child) =>
                    getUpdatedDescendantsWithExportOptions(
                        child,
                        printable,
                        selectedPrintableValues
                    )
                );
                return {
                    ...printable,
                    childPrintables: filterSelectedChildPrintables(
                        printable,
                        childPrintablesWithExportOptions,
                        selectedPrintableValues
                    ),
                    exportOptions: _.filter(printable.exportOptions, (option) =>
                        includes(
                            selectedPrintableValues,
                            getValueForExportOption(option, printable)
                        )
                    ),
                };
            })
            .filter((printable) => {
                return (
                    includes(selectedPrintableValues, getValueForPrintable(printable)) ||
                    printable.childPrintables.length ||
                    !isEmpty(printable.exportOptions)
                );
            })
            .uniq()
            .sortBy((printable) => {
                // sort by click order
                // checks prefixes in `selectedPrintableValues` so export options don't mess us up
                return _.findIndex(selectedPrintableValues, (selectedPrintableValue) =>
                    _.startsWith(selectedPrintableValue, getValueForPrintable(printable))
                );
            })
            .value();
        handleAssociatedRecords(packetOptions);
        return packetOptions;
    }
);

export const transformPacketOptionsForReportPage = (packets, ownerDisplayValue) => {
    // Swap some values based on the template types here
    // The primary difference between the packets
    // here and those generated from `prettyPacketOptionsSelector`
    // are that the `parentValue` and `display` are flipped,
    // since for the redesign, the reports types (offense, arrest) are the top
    // level components of the checkbox tree,
    // not the export types (coversheet, report packet)
    const transformedPacketOptions = compact(
        map(packets, (packet) => {
            const { packetType, templateType, ownerName, createdDate } = packet;
            if (packetType === packetTypeEnum.REGULAR) {
                const { rawPacket } = packet;
                const display = `${
                    FILLABLE_PDF_TEMPLATE_TYPES.includes(templateType) ? strings.fillablePdf : ''
                } ${rawPacket.groupTitle} (${rawPacket.entityId})`;
                return {
                    ...packet,
                    display,
                    // Need to blank this one out
                    noteDisplay:
                        ownerName && createdDate
                            ? join(
                                  [
                                      checkboxStrings.owner(ownerName, ownerDisplayValue),
                                      checkboxStrings.createdDate(createdDate),
                                  ],
                                  ', '
                              )
                            : undefined,
                    parentValue: rawPacket.title,
                    indicatorText: undefined,
                    isAssociatedRecord: rawPacket.isAssociatedRecord,
                };
            } else if (packetType === packetTypeEnum.CHILD_EXPORT_OPTION) {
                const { rawExportOption, rawChild, rawPacket } = packet;
                return {
                    ...packet,
                    display: getValueForChildPrintable(rawChild, rawPacket),
                    parentValue: rawExportOption.exportOptionDisplay,
                    indicatorText: undefined,
                    isAssociatedRecord: rawPacket.isAssociatedRecord,
                };
            } else if (packetType === packetTypeEnum.CHILD) {
                const { rawPacket, rawChild } = packet;
                return {
                    ...packet,
                    display: getValueForPrintable(rawPacket),
                    parentValue: `${rawChild.groupTitle} ${rawChild.title}`,
                    indicatorText: undefined,
                    isAssociatedRecord: rawPacket.isAssociatedRecord,
                };
            } else {
                return {
                    ...packet,
                    indicatorText: undefined,
                };
            }
        })
    );

    return transformedPacketOptions;
};

const handleAssociatedRecords = (packetOptions) => {
    const associatedPacketOptions = filter(packetOptions, (option) => option.isAssociatedRecord);

    if (associatedPacketOptions) {
        const indexOfFirstAssociatedPacket = findIndex(
            packetOptions,
            (option) => option.isAssociatedRecord
        );

        const sortedAssociatedPacketOptions = sortBy(associatedPacketOptions, [
            'entityId',
            'display',
        ]);

        packetOptions.splice(
            indexOfFirstAssociatedPacket,
            associatedPacketOptions.length,
            ...sortedAssociatedPacketOptions
        );
    }
};

const getPacketsForDefaultExportPreset = (allPacketOptions, exportPreset, entityId) => {
    // a default export preset only has one report type
    const exportPresetReportType = first(get(exportPreset, 'exportPresetReportTypes', []));
    if (!exportPresetReportType) {
        return [];
    }
    return filter(allPacketOptions, (packet) => {
        if (packet.packetType === packetTypeEnum.REGULAR) {
            const rawPacket = get(packet, 'rawPacket', {});
            return (
                rawPacket.reportPrintingTemplateId ===
                    exportPresetReportType.reportPrintingTemplateId &&
                rawPacket.entityId === entityId
            );
        }
    });
};

export const exportPresetToPacketValues = (allPacketOptions, exportPreset, entityId) => {
    if (exportPreset.isDefaultExportPreset) {
        return map(
            getPacketsForDefaultExportPreset(allPacketOptions, exportPreset, entityId),
            'value'
        );
    }

    const exportPresetReportTypes = get(exportPreset, 'exportPresetReportTypes', []);
    const matchingPackets = filter(allPacketOptions, (packet) => {
        if (packet.packetType === packetTypeEnum.REGULAR) {
            const rawPacket = get(packet, 'rawPacket', {});
            return !!getMatchingExportPresetReportTypeForPacket(exportPresetReportTypes, rawPacket);
        }

        const exportPresetReportType = getMatchingExportPresetReportTypeForPacket(
            exportPresetReportTypes,
            packet
        );
        if (!!exportPresetReportType && packet.packetType === packetTypeEnum.EXPORT_OPTION) {
            const rawExportOption = get(packet, 'rawExportOption', {});
            const exportConfigs = get(exportPresetReportType, 'reportTypeExportConfigs', []);
            const matchingExportConfig = find(
                exportConfigs,
                (ec) => ec.exportOptionName === rawExportOption.exportOptionName
            );
            return !!matchingExportConfig;
        }
        return !!exportPresetReportType;
    });
    return map(matchingPackets, 'value');
};

// this is used on the export templates page when you are an officer
// trying to print. This is the info for the whole view
export const exportSelector = createSelector(
    [prettyPacketOptionsSelector, uiSelector, selectedPacketsSelector],
    (prettyPackets, ui, selectedPackets) => ({
        packetOptions: prettyPackets,
        selectedPackets,
        loaded: ui.loadedOptions,
        error: ui.failedToLoadOptions,
    })
);

// used by the "My export" thingy at the bottom of the page
// as an officer who has exports pending
export const myExportsSelector = createSelector([getMyExports, uiSelector], (exports, ui) => ({
    expanded: ui.expanded,
    positionRight: ui.positionRight,
    exports,
}));

export const exportPageReleaseTrackingConfigSelector = createSelector(
    [
        currentUserHasAbilitySelector,
        reportsSelector,
        isInvolvedPersonSealedForReportIdSelector,
        custodialPropertySummaryReportDefinitionSelector,
    ],
    (
        currentUserHasAbility,
        reports,
        isInvolvedPersonSealedForReportId,
        custodialPropertySummaryReportDefinition
    ) => (entityType, reportId) => {
        const isReport = entityType === EntityTypeEnum.REPORT.name;
        const report = isReport && reports[reportId];
        const isReportSealed = report && report.isSealed;
        const isSealedPersonOnReport = report && isInvolvedPersonSealedForReportId(report.id);
        const canViewCourtOrders = currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_COURT_ORDERS);
        return {
            showReleaseTracking: !isReportSealed && (!isSealedPersonOnReport || canViewCourtOrders),
            isCpsReport:
                report.reportDefinitionId === get(custodialPropertySummaryReportDefinition, 'id'),
        };
    }
);

export const exportDefaultGroupTitleSelector = createSelector(
    [currentReportSelector, formatFieldByNameSelector],
    (currentReport, formatFieldByName) => {
        const reportingEventNumber = get(currentReport, 'reportingEventNumber') || '';
        return `${formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)} ${reportingEventNumber}`;
    }
);

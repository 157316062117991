import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ExportIntervalTypeEnum, ExportIntervalTypeEnumType } from '@mark43/rms-api';
import moment from "moment/moment";
import { agencyProfileByIdSelector } from "~/client-common/core/domain/agency-profiles/state/data";
import {dateTimeFormats} from "~/client-common/core/dates/utils/dateHelpers";
import { MFTSelect } from '../../core/forms/components/selects/Select';

// Typing for props passed into
type ComplianceDateRangeSelectProps = {
    agencyId?: number;
    isDisabled: boolean;
    lastExportedDate?: string;
    firstExportedDate?: string;
    exportIntervalType: typeof ExportIntervalTypeEnum[ExportIntervalTypeEnumType]['name'];
    onChange: () => void;
    isClearable?: boolean;
    label: string;
    width: number;
    showRange?: boolean;
    ignoreMaxMonthsOptions?: boolean;
}

type rangeType = {
    // An option containing the start date of the range
    option: optionType;
    // The end date to be displayed for a given range
    endDate: moment.Moment;
}

type optionType = {
    label: string;
    value: string;
}

function generateTnWeeklyRange(start: moment.Moment, showRange?: boolean): rangeType {
    // Earlier of (first friday >= start, end of month)
    let endOfWeek = start.clone();
    if (start.isoWeekday() <= 5) {
        endOfWeek = start.clone().isoWeekday(5);
    } else {
        endOfWeek = start.clone().add(1, 'week').isoWeekday(5)
    }
    // Earlier of friday or end of month
    endOfWeek = moment.min([endOfWeek, start.clone().endOf('month')])
    const startLabel = start.format(dateTimeFormats.summaryDate);
    const fullLabel = !showRange ? startLabel : `${startLabel} - ${endOfWeek.format(dateTimeFormats.summaryDate)}`

    const startOption: optionType = {
        label: fullLabel,
        value: start.format('YYYY-MM-DD'),
    }
    return {
        option: startOption,
        endDate: endOfWeek,
    }
}

function generateMonthly(start: moment.Moment): rangeType {
    start.startOf('month');
    const endOfPeriod = start.clone().endOf('month');
    const startOption: optionType = {
        label: start.format('MM/YYYY'),
        value: start.format('YYYY-MM-DD'),
    }
    return {
        option: startOption,
        endDate: endOfPeriod,
    }
}

function buildNibrsDateRangeOptions(generateRange: (start: moment.Moment, showRange?: boolean) => rangeType, firstExportedDate?: string, lastExportedDate?: string, showRange?: boolean, ignoreMinMaxOptions?: boolean) {
    let start = moment(lastExportedDate ?? firstExportedDate);
    let end = moment();
    if (!!ignoreMinMaxOptions) {
        end =  moment(lastExportedDate ?? firstExportedDate).add(1, 'day');
        start = moment(firstExportedDate);
    }
    const options: optionType[] = [];
    while (start.isBefore(end)) {
        const {option, endDate} = generateRange(start, showRange);
        start = endDate.add(1, 'day');
        options.push(option);
    }

    return options.reverse();
}

const ComplianceDateRangeSelect: React.FC<ComplianceDateRangeSelectProps> = (props) => {
    const {agencyId, firstExportedDate, lastExportedDate, exportIntervalType, showRange, ignoreMaxMonthsOptions} = props;
    const agencyProfileById = useSelector(agencyProfileByIdSelector);
    const agencyProfile = agencyProfileById(agencyId);

    let generateRange = generateMonthly;
    switch (exportIntervalType) {
        case ExportIntervalTypeEnum.TN_WEEKLY.name: {
            generateRange = generateTnWeeklyRange;
            break;
        }
        default:
            generateRange = generateRange || generateMonthly;
    }

    const options = useMemo(() => {
        const nibrsStartDateUtc = agencyProfile?.nibrsStartDateUtc;
        if (!!firstExportedDate && lastExportedDate) {
            return buildNibrsDateRangeOptions(generateRange, firstExportedDate, lastExportedDate, showRange, ignoreMaxMonthsOptions);
        }
        return buildNibrsDateRangeOptions(generateRange, nibrsStartDateUtc, lastExportedDate, showRange, ignoreMaxMonthsOptions);
    }, [agencyProfile, firstExportedDate, lastExportedDate, generateRange, showRange, ignoreMaxMonthsOptions])

    return (
        <MFTSelect {...props}
                   options={options}
                   path="exportDateUtc"
        />)
};

export default ComplianceDateRangeSelect
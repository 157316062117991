import React from 'react';
import { RefContextEnum, AttributeTypeEnum } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { OnlyWithAbility } from '../../abilities';
import { useFormGetter } from '../../forms/hooks/useFormGetter';
import { dateTimePickerTotalWidth } from '../../../../legacy-redux/configs/formsConfig';

// Selectors
import { currentUserHasAbilitySelector } from '../../current-user/state/ui';

// Components
import FormRow from '../../forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTFieldset } from '../../forms/components/Fieldset';
import { ArbiterMFTDatePicker } from '../../forms/components/DatePicker';

// Context can be one of two right now
type AcceptedContexts =
    | typeof RefContextEnum.FORM_CREATE_MANAGE_CASE.name
    | typeof RefContextEnum.FORM_REPORT_CASE_STATUSES_SIDE_PANEL.name;

export const ExternalReportStatusFieldset: React.FC<{
    context: AcceptedContexts;
    className?: string;
    title?: string;
}> = ({ context, className, title }) => {
    const { getForm } = useFormGetter();

    const form = getForm(context);

    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);

    const userHasEditExternalReportStatusAbility = currentUserHasAbility(
        abilitiesEnum.REPORTING.EDIT_EXTERNAL_REPORT_STATUS
    );

    return (
        <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_EXTERNAL_REPORT_STATUS}>
            <ArbiterMFTFieldset className={className} path="externalReportStatus" title={title}>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.EXTERNAL_STATUS.name}
                    disabled={!userHasEditExternalReportStatusAbility}
                    path="externalReportStatusAttrId"
                    onChange={(externalReportStatusAttrId: number) => {
                        if (!!externalReportStatusAttrId && form) {
                            form.set('externalReportStatus.statusDateUtc', nowUtc());
                        }
                    }}
                    length="lg"
                />
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        attributeType={AttributeTypeEnum.DIVISION.name}
                        disabled={!userHasEditExternalReportStatusAbility}
                        path="closedByDivisionAttrId"
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                        disabled={!userHasEditExternalReportStatusAbility}
                        path="closedByUnitAttrId"
                        length="md"
                    />
                </FormRow>
                <ArbiterMFTDatePicker
                    disabled={!userHasEditExternalReportStatusAbility}
                    width={dateTimePickerTotalWidth}
                    includeTime={true}
                    path="statusDateUtc"
                />
            </ArbiterMFTFieldset>
        </OnlyWithAbility>
    );
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';

import { saveUcrClassification } from '../state/ui';
import UcrClassificationSidePanelForm from './UcrClassificationSidePanelForm';

const strings = componentStrings.reports.UcrClassificationSidePanel;

const context = { name: boxEnum.UCR_CLASSIFICATION_SIDE_PANEL };

function UcrClassificationSidePanel({ onSave }) {
    return (
        <SidePanel title={strings.title} context={context} onSave={onSave}>
            <UcrClassificationSidePanelForm />
        </SidePanel>
    );
}

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
    return {
        onSave: () => dispatch(saveUcrClassification()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UcrClassificationSidePanel);

import React from 'react';
import { connect } from 'react-redux';
import { Form, formEvents, lifecycleOptions } from 'markformythree';
import { runValidationMFT } from '../../validation';

/**
 * Use this form when a Form (using markformythree) will use
 *   system rules for validation.
 */
function SystemRuleForm({ onValidate, ...otherProps }) {
    return (
        <Form
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            validationEvents={[
                { eventType: formEvents.FORM_SUBMIT },
                { eventType: formEvents.INPUT_CHANGE },
                { eventType: formEvents.INPUT_BLUR },
            ]}
            onValidate={onValidate}
            {...otherProps}
        />
    );
}

export default connect(undefined, (dispatch, { validationContext }) => ({
    onValidate({ getFieldMap, formState }) {
        const fieldMap = getFieldMap();
        const validationResult = dispatch(
            runValidationMFT(validationContext, formState.model, fieldMap)
        );
        return validationResult;
    },
}))(SystemRuleForm);

import { RefContextEnum } from '@mark43/rms-api';
import { pick, keys } from 'lodash';
import { createFormConfiguration, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';

const formConfiguration = {
    name: { fieldName: fields.NARRATIVE_GUIDE_NAME },
    content: { fieldName: fields.NARRATIVE_GUIDE_CONTENT },
    availableIn: { fieldName: fields.NARRATIVE_GUIDE_AVAILABLE_IN },
};

export function convertToFormModel(narrativeGuide) {
    return pick(narrativeGuide, keys(formConfiguration));
}

export function convertFromFormModel(formModel, selectedId = undefined) {
    return { ...formModel, id: selectedId };
}

const narrativeGuideAdminForm = {
    name: RefContextEnum.FORM_ADMIN_NARRATIVE_GUIDE.name,
    configuration: createFormConfiguration(formConfiguration),
    lifecycle: lifecycleOptions.REGISTER_AND_UNREGISTER,
};

export default narrativeGuideAdminForm;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import styled from 'styled-components';
import { searchSizes } from '~/client-common/configs/advancedSearchConfig';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import _ToggleButton from '../../../../legacy-redux/components/core/ToggleButton';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import SearchSizeDropdownMenu from '../../../core/components/SearchSizeDropdownMenu';
import SavedSearchModal from '../../../search/saved-search/components/SavedSearchModal';
import { updateCasesTabCountsAfterExecutingSavedSearch } from '../../all-cases/state/ui';
import { clearfix } from '../../../core/styles/mixins';
import testIds from '../../../../core/testIds';

const ResultsHeaderWrapper = styled.div`
    position: relative;
    right: 0;
    float: right;
    z-index: 1; // to not be hidden beneath the table below (idiosyncratic)
    /* Pulls up the black line to the tab */
    margin-bottom: -6px;
    ${clearfix}

    &.open-controls {
        margin-bottom: 0;
    }

    .header-top {
        float: right; // align all the header content to the right

        .table-results-summary {
            display: inline-block;
            float: left;
            margin-right: 20px;
            line-height: 55px;
        }

        .size-select-container {
            float: left;
            margin-top: 6px;
            margin-right: 20px;
            width: 168px; // match <Select> width
            line-height: normal;
            text-align: left;
            font-weight: ${(props) => props.theme.fontWeights.regular};
        }

        // remove spacing from last the button
        .mark43-react-icon-button:last-child {
            margin-right: 0;
        }
    }
`;

// Ensures the dropdown button is aligned with the Search Form button and Search Save button
const SearchSizeDropdownMenuContainer = styled(SearchSizeDropdownMenu)`
    float: left;
    margin: 12px 10px 0 -10px;
`;

/**
 * Option values for how many search/filter results to show per page in cases
 *   dashboards.
 * @type {number[]}
 */

const ToggleButton = styled(_ToggleButton)`
    margin: 12px 10px 12px 0;
`;

const SavedSearchModalWrapper = styled.div`
    display: inline-block;

    .saved-search-button-wrapper {
        margin-top: 11px;
    }
`;

const FormVisibilityWrapper = styled.div`
    display: ${(props) => (props.visibility ? 'block' : 'none')};
`;

export function ResultsHeader({
    from = 0,
    to = 0,
    totalResults,
    onSizeChange,
    searchOpenByDefault = false,
    SearchForm,
    className,
    isSearchSavable = false,
    inlineWithTabs = true,
    searchModule,
    updateTabCountsAfterSavedSearch,
    additionalHeaderContent,
}) {
    const [filterIsOpen, setFilterIsOpen] = useState(searchOpenByDefault);
    const onFilterToggleClick = () => setFilterIsOpen((filterIsOpen) => !filterIsOpen);

    return (
        <ResultsHeaderWrapper
            className={classNames(className, {
                'open-controls': filterIsOpen || !inlineWithTabs,
            })}
            data-test-id={testIds.TABLE_RESULTS_HEADER}
        >
            <div className="header-top">
                <TableResultsSummary from={from} to={to} totalResults={totalResults} caption="" />
                <SearchSizeDropdownMenuContainer sizes={searchSizes} onChange={onSizeChange} />

                {SearchForm && (
                    <ToggleButton
                        testId={testIds.FILTER_BUTTON}
                        className={buttonTypes.ICON}
                        active={filterIsOpen}
                        inactiveIcon={<Icon type={iconTypes.FILTER} />}
                        onClick={onFilterToggleClick}
                    />
                )}

                {isSearchSavable && (
                    <SavedSearchModalWrapper>
                        <SavedSearchModal
                            searchModule={searchModule}
                            onSavedSearchExecuteSuccess={updateTabCountsAfterSavedSearch}
                        />
                    </SavedSearchModalWrapper>
                )}

                {additionalHeaderContent}
            </div>
            {SearchForm && (
                <FormVisibilityWrapper visibility={filterIsOpen}>
                    <SearchForm />
                </FormVisibilityWrapper>
            )}
        </ResultsHeaderWrapper>
    );
}

const mapDispatchToProps = (dispatch) => ({
    updateTabCountsAfterSavedSearch: (result) =>
        dispatch(updateCasesTabCountsAfterExecutingSavedSearch(result)),
});

/**
 * Table header in a table of case search results.
 * @param {number}       props.from
 * @param {number}       props.to
 * @param {number}       props.totalResults
 * @param {number}       props.searchSize
 * @param {function}     props.onSizeChange
 * @param {ReactElement} [props.SearchForm]
 * @param {boolean}      [props.isSearchSavable=false]
 * @param {boolean}       [props.searchOpenByDefault]
 */
export default compose(connect(undefined, mapDispatchToProps))(ResultsHeader);

import React, { useState } from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { CollapsibleSectionWithStickyHeader } from '../../../core/components/CollapsibleSectionWithStickyHeader';
import _CaseTasks from '../../../tasks/cases/components/CaseTasks';
import CaseTasksTable from './CaseTasksTable';

const strings = componentStrings.cases.core.CaseTasks;

const EnhancedCaseTasks = _CaseTasks;

export default function CaseTasks({ sortedTaskViewModels, className }) {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <CollapsibleSectionWithStickyHeader
            isOpen={isOpen}
            title={strings.tasks}
            containerSelector=".case-content-container"
            noDataText={strings.noTasks}
            toggleOpen={(open) => setIsOpen(open)}
            className={className}
        >
            <FeatureFlagged
                flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                fallback={<CaseTasksTable caseTasks={sortedTaskViewModels} />}
            >
                <EnhancedCaseTasks showCreateTaskButton={false} />
            </FeatureFlagged>
        </CollapsibleSectionWithStickyHeader>
    );
}

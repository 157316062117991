import React from 'react';
import { useSelector } from 'react-redux';
import { includes, map, pick } from 'lodash';
import { lifecycleOptions } from 'markformythree';
import { OperationTypeEnum, SavedSearchView } from '@mark43/rms-api';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { abilityRoleLinkViewModelsWhereSelector } from '~/client-common/core/domain/ability-role-links/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';

import RecordPermissionsForm, {
    RoleSetting,
} from '../../../core/permissions/components/RecordPermissionsForm';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { abilitiesEnum } from '../../../core/abilities';

const labels = componentStrings.search.savedSearch.SavedSearchModalPermissionContent.labels;

const supportedOperationTypes = [OperationTypeEnum.MANAGE.name, OperationTypeEnum.READ.name];

export const useSavedSearchGetEntityPermissions = (): (() => RoleSetting[]) => {
    const { getForm } = useFormGetter();
    return (): RoleSetting[] => {
        const form = getForm(formClientEnum.SAVED_SEARCH_PERMISSIONS_FORM);
        const { roleSettings: entityPermissions } = form?.get() || {};
        return map(entityPermissions, (entityPermission) =>
            pick(entityPermission, ['roleId', 'operationType'])
        );
    };
};

const useExcludedRoleIds = () => {
    const requiredAbilities = [
        abilitiesEnum.CORE.SEARCH_GENERAL,
        abilitiesEnum.ADMIN.SAVED_SEARCH_SHARING,
    ];

    const abilityRoleLinkViewModelsWhere = useSelector(abilityRoleLinkViewModelsWhereSelector);

    const abilityRoleLinkViewModels = abilityRoleLinkViewModelsWhere(
        // @ts-expect-error abilityId does not exist (except it does)
        ({ abilityId }) => !includes(requiredAbilities, abilityId)
    );

    // @ts-expect-error roleId does not exist (except it does)
    return map(abilityRoleLinkViewModels, ({ roleId }) => roleId);
};

const SavedSearchModalPermissionContent: React.FC<{
    roleAddItemOnEmpty?: boolean;
    initialEntityPermissions?: RoleSetting[];
    getCustomProperties?: () => { savedSearchView: SavedSearchView };
}> = ({ roleAddItemOnEmpty = true, initialEntityPermissions = [], getCustomProperties }) => {
    const excludedRoleIds = useExcludedRoleIds();
    const entityPermissions = getCustomProperties
        ? initialEntityPermissions.concat(getCustomProperties().savedSearchView.entityPermissions)
        : initialEntityPermissions;
    return (
        <RecordPermissionsForm
            disabledRoleIds={[]}
            editable={true}
            formName={formClientEnum.SAVED_SEARCH_PERMISSIONS_FORM}
            hideExternalAgencySettings={true}
            hideUserSettings={true}
            roleSettingsTitle={labels.roles}
            addRoleButtonText={labels.buttonAddRole}
            roleOperationTypeOptions={supportedOperationTypes}
            roleSettingsRoleIdsToExclude={excludedRoleIds}
            initialRoleSettings={entityPermissions}
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            roleAddItemOnEmpty={roleAddItemOnEmpty}
        />
    );
};

export default SavedSearchModalPermissionContent;

import React from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';
import { AttributeTypeEnum } from '@mark43/rms-api';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_PERSON_PROFILE_WEIGHT_RANGE,
    DISPLAY_PERSON_PROFILE_HEIGHT_RANGE,
} from '~/client-common/core/enums/universal/fields';

import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { RRFHeightRangeSlider, RRFWeightRangeSlider } from '../../../core/forms/components/Slider';

import { physicalAttributesFieldsetViewModel } from '../../../../legacy-redux/configs/fieldsetsConfig';

const { title } = physicalAttributesFieldsetViewModel;

const Checkbox = styled(RRFCheckbox)`
    margin-top: 23px;
`;

function PhysicalAttributesFieldsetRow1() {
    return (
        <Row>
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.RACE.name}
                multiple={true}
                includeExpired={true}
                width={218}
                path="raceAttrIds"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.ETHNICITY.name}
                multiple={true}
                includeExpired={true}
                width={218}
                path="ethnicityAttrIds"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.BUILD.name}
                multiple={true}
                includeExpired={true}
                width={218}
                path="buildAttrIds"
            />
        </Row>
    );
}

function PhysicalAttributesFieldsetRow2() {
    return (
        <Row>
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.HAIR_COLOR.name}
                multiple={true}
                includeExpired={true}
                width={218}
                path="hairColorAttrIds"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.HAIR_STYLE.name}
                multiple={true}
                includeExpired={true}
                width={218}
                path="hairStyleAttrIds"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.FACIAL_HAIR_TYPE.name}
                multiple={true}
                includeExpired={true}
                width={218}
                path="facialHairTypeAttrIds"
            />
        </Row>
    );
}

function PhysicalAttributesFieldsetRow3() {
    return (
        <Row>
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.EYE_COLOR.name}
                multiple={true}
                includeExpired={true}
                width={218}
                path="eyeColorAttrIds"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.VISION.name}
                multiple={true}
                includeExpired={true}
                width={218}
                path="visionAttrIds"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.PHYSICAL_CHARACTERISTIC.name}
                multiple={true}
                grouped={true}
                includeExpired={true}
                width={218}
                path="physicalCharacteristicAttrIds"
            />
        </Row>
    );
}

function PhysicalAttributesFieldsetRow4() {
    return (
        <Row>
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.YES_NO_UNKNOWN.name}
                includeExpired={true}
                width={218}
                path="isPregnantAttrId"
            />
            <Checkbox path="isUnborn" />
        </Row>
    );
}

const sliderStyle = {
    marginRight: 20 + 218 - 183,
};

function PhysicalAttributesFieldsetRow5() {
    const fieldDisplayNames = useFields([
        DISPLAY_PERSON_PROFILE_WEIGHT_RANGE,
        DISPLAY_PERSON_PROFILE_HEIGHT_RANGE,
    ]);
    return (
        <Row>
            <RRFWeightRangeSlider
                width={183}
                style={sliderStyle}
                label={fieldDisplayNames.DISPLAY_PERSON_PROFILE_WEIGHT_RANGE}
            />
            <RRFHeightRangeSlider
                width={183}
                style={sliderStyle}
                label={fieldDisplayNames.DISPLAY_PERSON_PROFILE_HEIGHT_RANGE}
            />
        </Row>
    );
}

/**
 * Fieldset for physical attributes, not all physical characteristics. The
 *   inputs are all attribute multiselects except for the weight and height
 *   sliders. This is not a `<CollapsibleFieldset>` because in the advanced
 *   search persons form, its collapsed state is shared with the fieldsets below
 *   it.
 * @param {Object}  props
 * @param {boolean} [props.collapsed=false]
 * @param {string}  [props.className='light']
 */
export default pure(function PhysicalAttributesFieldset({
    collapsed = false,
    className = 'light',
    ...otherProps
}) {
    return (
        <RRFFieldset path="physicalAttributes" title={title} className={className} {...otherProps}>
            <PhysicalAttributesFieldsetRow1 />
            {!collapsed && <PhysicalAttributesFieldsetRow2 />}
            {!collapsed && <PhysicalAttributesFieldsetRow3 />}
            {!collapsed && <PhysicalAttributesFieldsetRow4 />}
            {!collapsed && <PhysicalAttributesFieldsetRow5 />}
        </RRFFieldset>
    );
});

import React from 'react';
import styled from 'styled-components';
import { cssVar, VStack, Text } from 'arc';
import { TableRow } from 'components-mark43';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { EFileLinkTypesT } from '../../..';
import testIds from '../../../../../core/testIds';

const strings: { [key in EFileLinkTypesT]: string } =
    componentStrings.eFiles.core.grid.deactivatedLabels;

const EFileTableBodyRowVStack = styled(VStack)<{ $isDeactivated: boolean }>`
    border-bottom: 1px solid ${cssVar('arc.colors.border.default')};
    padding: 10px 0;
    font-size: var(--arc-fontSizes-sm);

    ${({ $isDeactivated }) => {
        if ($isDeactivated) {
            return ` background-color: ${cssVar('arc.colors.interactive.active')}; `;
        } else {
            return ` &:hover {
                background-color: ${cssVar('arc.colors.selected.accent')};
                cursor: pointer;
            }`;
        }
    }}
`;

const DeactivatedText = styled(Text)`
    font-style: italic;
    padding: 0 10px;
`;

// Created this component so that we don't have to duplicate the logic for how to render
// Deactivated Grid Rows.
export const EFileTableBodyRow = ({
    isDeactivated = false,
    children,
    linkType,
    onClick,
}: {
    isDeactivated?: boolean;
    children: React.ReactNode;
    linkType?: EFileLinkTypesT;
    onClick?: () => void;
}) => {
    return (
        <EFileTableBodyRowVStack
            $isDeactivated={isDeactivated}
            alignItems="flex-start"
            onClick={onClick}
        >
            {isDeactivated && linkType && (
                <DeactivatedText variant="bodyMd">{strings[linkType]}</DeactivatedText>
            )}
            <TableRow data-test-id={testIds.TABLE_ROW}>{children}</TableRow>
        </EFileTableBodyRowVStack>
    );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { MFTFieldConfiguration } from 'markformythree';
import {
    AttributeTypeEnum,
    AttributeTypeEnumType,
    FieldDetail,
    FieldTypeEnum,
} from '@mark43/rms-api';
import { fieldDetailsByFieldNameSelector } from '~/client-common/core/domain/field-details/state/data';
import {
    ClientFieldConfiguration,
    fieldConfigurationByFieldNameSelector,
} from '~/client-common/core/domain/field-configurations/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { ArbiterMFTText } from '../../../forms/components/Text';
import { ArbiterMFTAttributeSelect } from '../../../forms/components/selects/AttributeSelect';
import { ArbiterMFTDatePicker } from '../../../forms/components/DatePicker';
import { ArbiterMFTUserSelect } from '../../../forms/components/selects/UserSelect';
import { ArbiterMFTBooleanButtonRadio } from '../../../forms/components/button-radios/BooleanButtonRadio';
import Row from '../../../components/Row';
import { ArbiterMFTTextArea } from '../../../forms/components/TextArea';
import { STRING_TEXT_INPUT_MAX_LENGTH } from '../constants/constants';
import { ArbiterMFTFormElementButtonRadio } from '../../../forms/components/button-radios/FormElementButtonRadio';

const globalBooleanOptions = [
    { value: globalAttributes.yesNo.yes, display: 'Yes' },
    { value: globalAttributes.yesNo.no, display: 'No' },
];

const getFieldToRender = (params: {
    fieldDetail: FieldDetail;
    path: string;
    fieldConfiguration: ClientFieldConfiguration;
}) => {
    switch (params.fieldDetail.fieldType) {
        case FieldTypeEnum.ATTRIBUTE.name: {
            // @ts-expect-error Note -- `fieldTypeMappedIdEnum` is actually typed wrong
            // but because `ArbiterMFTAttributeSelect` is not typed, no error is surfaced
            // https://mark43.atlassian.net/browse/RND-13882
            const fieldTypeMappedIdEnum: AttributeTypeEnumType | undefined =
                params.fieldDetail.fieldTypeMappedIdEnum;
            if (fieldTypeMappedIdEnum) {
                if (fieldTypeMappedIdEnum === AttributeTypeEnum.YES_NO.name) {
                    return (
                        <ArbiterMFTFormElementButtonRadio
                            path={params.path}
                            options={globalBooleanOptions}
                        />
                    );
                } else {
                    return (
                        <ArbiterMFTAttributeSelect
                            path={params.path}
                            attributeType={fieldTypeMappedIdEnum}
                        />
                    );
                }
            } else {
                return null;
            }
        }
        case FieldTypeEnum.BOOLEAN.name: {
            return <ArbiterMFTBooleanButtonRadio path={params.path} />;
        }
        case FieldTypeEnum.NUMBER.name:
        case FieldTypeEnum.INTEGER.name: {
            return <ArbiterMFTText path={params.path} />;
        }
        case FieldTypeEnum.STRING.name: {
            // Some arbitrary heuristic for determining when to show
            // a textarea over a text field
            // We use the `fieldConfiguration` instead of the `fieldDetail`
            // because this is something the user has control over via the field admin page
            const logicalMax = parseInt(params.fieldConfiguration?.max || '0', 10);
            const shouldDisplayTextarea =
                !isNaN(logicalMax) && logicalMax > STRING_TEXT_INPUT_MAX_LENGTH;
            return shouldDisplayTextarea ? (
                <ArbiterMFTTextArea path={params.path} />
            ) : (
                <ArbiterMFTText path={params.path} />
            );
        }
        case FieldTypeEnum.DATE.name: {
            return <ArbiterMFTDatePicker path={params.path} includeTime={false} />;
        }
        case FieldTypeEnum.DATETIME.name: {
            return <ArbiterMFTDatePicker path={params.path} includeTime={true} />;
        }
        case FieldTypeEnum.USER.name: {
            return <ArbiterMFTUserSelect path={params.path} />;
        }
        default: {
            return null;
        }
    }
};

/**
 * Given an MFTFieldConfiguration, and its relative path within the form,
 * render the MFTArbiter field
 *
 * Note -- it's expected that this will be rendered within both:
 *      - an Arbiter context provider
 *      - a markformythree Form
 *
 * If it is not, then the component will throw an error and fail to render.
 */
export const FormConfigurationFormFieldRenderer = (props: {
    mftFieldConfiguration: MFTFieldConfiguration;
    path: string;
}) => {
    const fieldDetailsByFieldName = useSelector(fieldDetailsByFieldNameSelector);
    const fieldConfigurationByFieldName = useSelector(fieldConfigurationByFieldNameSelector);
    const fieldName = props.mftFieldConfiguration.fieldName;
    const fieldDetail = fieldName ? fieldDetailsByFieldName[fieldName] : undefined;
    const fieldConfiguration = fieldName ? fieldConfigurationByFieldName[fieldName] : undefined;

    if (!fieldDetail || !fieldConfiguration) {
        return null;
    }

    const fieldToRender = getFieldToRender({ fieldDetail, fieldConfiguration, path: props.path });

    if (!fieldToRender) {
        return null;
    }

    return <Row>{fieldToRender}</Row>;
};

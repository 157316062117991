import { AttributeTypeEnum } from '@mark43/rms-api';
// TODO: Handle legacy entity details.

import { size } from 'lodash';
import { compose, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatAttributeLinkViewModels } from '~/client-common/core/domain/attributes/state/ui';
import { incidentViewModelByIdSelector } from '~/client-common/core/domain/offenses/state/ui';
import { offenseAttributeViewModelsWhereSelector } from '~/client-common/core/domain/offense-attributes/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.core.IncidentCard;

const mapStateToProps = createStructuredSelector({
    incidentViewModelById: incidentViewModelByIdSelector,
    offenseAttributeViewModelsWhere: offenseAttributeViewModelsWhereSelector,
});

const mapPropsOnChange = ({
    incidentViewModelById,
    offenseAttributeViewModelsWhere,
    incidentId,
}) => {
    const incidentViewModel = incidentViewModelById(incidentId);
    const offenseAttributeViewModels = offenseAttributeViewModelsWhere({
        offenseId: incidentId,
        attributeType: AttributeTypeEnum.INCIDENT_STATISTIC.name,
    });

    return {
        incidentViewModel,
        offenseAttributeViewModels,
    };
};

const IncidentCardSummary = compose(
    connect(mapStateToProps),
    withPropsOnChange(
        [
            // `mapStateToProps` props
            'incidentViewModelById',
            'offenseAttributeViewModelsWhere',
            // props passed in
            'incidentId',
        ],
        mapPropsOnChange
    )
)(function _IncidentCardSummary({ incidentViewModel, offenseAttributeViewModels }) {
    const incidentViewModelProperties = getViewModelProperties(incidentViewModel);
    const formattedIncidentStatisticAttributes = formatAttributeLinkViewModels(
        offenseAttributeViewModels
    );
    const incidentStatFieldName =
        fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_INCIDENT_STATISTIC_ATTRIBUTE_ID;

    return (
        <CardSection testId={testIds.CARD_SUMMARY}>
            <SummaryList labelWidth={160} contentWidth={380}>
                <SummaryRow label={strings.incidentOffenseCodeLabel}>
                    {incidentViewModelProperties.offenseCode}
                </SummaryRow>
                {size(formattedIncidentStatisticAttributes) > 0 && (
                    <SummaryRow fieldName={incidentStatFieldName}>
                        {formattedIncidentStatisticAttributes}
                    </SummaryRow>
                )}
            </SummaryList>
        </CardSection>
    );
});

export default IncidentCardSummary;

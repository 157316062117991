import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose, withPropsOnChange, withProps } from 'recompose';
import { map } from 'lodash';
import styled, { css } from 'styled-components';
import { cssVar, FormControl, Input } from 'arc';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config/';
import { clearfix } from '../../styles/mixins';
import FormElement from './FormElement';
import { DeprecatedInput, RRFText } from './Text';

/**
 *
 * NOTE: IF you update this file, also please update: client/src/scripts/modules/core/persons/components/MultiTextInput.js
 *
 */

const SuffixText = styled.span`
    color: ${(props) => props.theme.colors.placeholder};
    margin-left: 6px;
`;

const InputWithSuffixWrapper = styled.div`
    ${clearfix};
    display: flex;
    align-items: center;
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || 'flex-end'};
`;

const StyledFormControl = styled(FormControl)`
    // cannot style <Input as="div"> directly, because classname applied to internal wrapper component.
    div.chakra-input {
        display: flex;
        justify-content: ${(props) => props.justifyContent || 'flex-end'};
        align-items: center;
        // matches margin-left in SuffixText
        column-gap: 6px;

        // extra flair to highlight selected multi text
        &:focus-within {
            z-index: 1;
            border-color: ${cssVar('arc.colors.brand.default')};
            border-radius: ${cssVar('arc.radii.md')};
            box-shadow: 0 0 0 1px ${cssVar('arc.colors.brand.default')};
        }
    }
`;

const legacyUnstyledInputCss = css`
    text-align: right;
    box-shadow: none;
    border: 0;
    background-color: transparent;
    padding: 0;

    && {
        margin: 0;
    }
`;

const RRFTextLegacyUnstyledInput = styled(RRFText)`
    ${legacyUnstyledInputCss}
`;

const unstyledInputCss = css`
    line-height: 1;

    input {
        text-align: right;
        box-shadow: none;
        border: 0;
        padding: 0;
        min-height: ${cssVar('arc.space.6')};
        height: ${cssVar('arc.space.6')};
    }

    && {
        margin: 0;
    }
`;

const RRFTextUnstyledInput = styled(RRFText)`
    ${unstyledInputCss}
`;

// All this really does is pull the proper label for the
// field, if a fieldName was given.
const MultiTextInput = compose(
    connect(
        createStructuredSelector({
            formatFieldByName: formatFieldByNameSelector,
            applicationSettings: applicationSettingsSelector,
        })
    ),
    withPropsOnChange(
        ['formatFieldByName', 'label', 'fieldName'],
        ({ formatFieldByName, label, fieldName }) => ({
            label: label || (fieldName ? formatFieldByName(fieldName) || '' : ''),
        })
    )
)(
    ({
        pathConfig,
        width,
        disabled,
        justifyContent,
        label,
        UnstyledInput,
        LegacyUnstyledInput,
        ...restProps
    }) => {
        return (
            <div className="no-label-margin">
                <FormElement label={label} forceShowError={true} width={width} {...restProps}>
                    <FeatureFlagged
                        flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
                        fallback={
                            <DeprecatedInput as="div" disabled={disabled}>
                                <InputWrapper justifyContent={justifyContent}>
                                    {map(pathConfig, ({ suffix, ...config }) => {
                                        const value = disabled ? { value: undefined } : {};
                                        return (
                                            <InputWithSuffixWrapper key={suffix}>
                                                <LegacyUnstyledInput
                                                    label={undefined}
                                                    error={undefined}
                                                    disabled={disabled}
                                                    key={config.path}
                                                    {...config}
                                                    {...value}
                                                />
                                                <SuffixText>{suffix}</SuffixText>
                                            </InputWithSuffixWrapper>
                                        );
                                    })}
                                </InputWrapper>
                            </DeprecatedInput>
                        }
                    >
                        <StyledFormControl isDisabled={disabled} justifyContent={justifyContent}>
                            <Input as="div">
                                {map(pathConfig, ({ suffix, ...config }) => {
                                    const value = disabled ? { value: undefined } : {};
                                    return (
                                        <InputWithSuffixWrapper key={suffix}>
                                            <UnstyledInput
                                                className={undefined}
                                                label={undefined}
                                                error={undefined}
                                                disabled={disabled}
                                                key={config.path}
                                                {...config}
                                                {...value}
                                                textAlign="right"
                                            />
                                            <SuffixText>{suffix}</SuffixText>
                                        </InputWithSuffixWrapper>
                                    );
                                })}
                            </Input>
                        </StyledFormControl>
                    </FeatureFlagged>
                </FormElement>
            </div>
        );
    }
);

export const RRFMultiTextInput = compose(
    withProps(() => ({
        UnstyledInput: RRFTextUnstyledInput,
        LegacyUnstyledInput: RRFTextLegacyUnstyledInput,
    }))
)(MultiTextInput);

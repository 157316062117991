import { useEffect, useCallback } from 'react';
import { useLoadingState, loadingActionCreators } from './useLoadingState';

type Resource<Result extends unknown, Params extends unknown[]> = (
    ...params: Params | never
) => Promise<Result>;

function useResourceHelper<TResult extends unknown, TParams extends unknown[]>(
    resource: Resource<TResult, TParams>,
    onSuccess?: (result: TResult) => void,
    onError?: (error?: Error) => void
) {
    const [loading, loadingDispatch] = useLoadingState();

    const callResource = useCallback(
        (...paramsForResourceCall: TParams) => {
            loadingDispatch(loadingActionCreators.loadingStart());

            return resource(...paramsForResourceCall)
                .then((result) => {
                    loadingDispatch(loadingActionCreators.loadingSuccess());

                    if (onSuccess) {
                        onSuccess(result);
                    }
                })
                .catch((error) => {
                    loadingDispatch(loadingActionCreators.loadingError(error.message));
                    if (onError) {
                        onError(error);
                    }
                });
        },
        [resource, loadingDispatch, onSuccess, onError]
    );

    return { loading, loadingDispatch, callResource };
}

/**
 * Trigger resource call with loading state.
 *
 * Use this hook in a component that needs to make an API request when it mounts.
 *
 * To control exactly when the resource call is triggered due to dependency changes, pass in functions that are memoized
 * with `useCallback` (when they have dependencies) and/or pass in a resource function that is defined outside the scope
 * of your component (when it has no dependencies). When in doubt, double check that your component is not making extra
 * API requests in the network tab.
 *
 * If the API requests need to be made after certain events, use `useResourceDeferred` instead.
 */
export function useResource<TResult extends unknown>(
    resource: Resource<TResult, never>,
    onSuccess?: (result: TResult) => void,
    onError?: (error?: Error) => void
) {
    const { loading, callResource } = useResourceHelper<TResult, never>(
        resource,
        onSuccess,
        onError
    );

    useEffect(() => {
        callResource();
    }, [callResource]);

    return loading;
}

/**
 * Return wrapped resource call with loading state.
 *
 * Use this hook in a component that needs to trigger API requests - call `callResource` to do so. Common usages are
 * when the user clicks a button or submits a form.
 *
 * Read `useResource` for details.
 */
export function useResourceDeferred<TResult extends unknown, TParams extends unknown[] = []>(
    resource: Resource<TResult, TParams>,
    onSuccess?: (result: TResult) => void,
    onError?: (error?: Error) => void
) {
    const { loading, loadingDispatch, callResource } = useResourceHelper<TResult, TParams>(
        resource,
        onSuccess,
        onError
    );

    const resetLoadingState = useCallback(() => {
        loadingDispatch(loadingActionCreators.loadingSuccess());
    }, [loadingDispatch]);

    return { loading, callResource, resetLoadingState };
}

import { get, isArray, omit, cloneDeep, find, size, compact, reduce } from 'lodash';
import actionTypes from '../actions/types/reportsActionTypes';
import customReportsActionTypes from '../actions/types/customReportsAdminActionTypes';

function reduceReportDefinitions(state, newDefinitions) {
    return reduce(
        newDefinitions,
        (acc, definition) => {
            acc[definition.id] = {
                ...state[definition.id],
                ...definition,
            };
            return acc;
        },
        { ...state }
    );
}

function reportDefinitionDataReducer(state = {}, action) {
    switch (action.type) {
        case customReportsActionTypes.STORE_HYDRATED_REPORT_DEFINTION:
            const definitions = isArray(action.payload) ? action.payload : [action.payload];
            return reduceReportDefinitions(state, definitions);
        case actionTypes.FETCH_REPORT_SUCCESS:
            const reportDefinitions = compact([
                action.payload.reportDefinition,
                ...(action.payload.reportDefinitions ? action.payload.reportDefinitions : []),
            ]);
            return reduceReportDefinitions(state, reportDefinitions);
        case customReportsActionTypes.SAVE_REPORT_DEFINITION_SUCCESS:
            // omit cardLinks and cards because server doesn't return these on save
            const reportDefinition = omit(action.payload, ['cardLinks', 'cards']);
            return {
                ...state,
                [reportDefinition.id]: {
                    ...state[reportDefinition.id],
                    ...reportDefinition,
                },
            };
        case customReportsActionTypes.SAVE_DOWNLOADABLES_SUCCESS:
            const downloadables = action.payload.downloadables;
            const reportDefId = action.payload.reportDefinitionId;
            const clonedState = cloneDeep(state);
            // all downloadables should have the same reportCardId
            if (downloadables[0]) {
                const updatedCard = find(
                    clonedState[reportDefId].cards,
                    (card) => card.id === downloadables[0].reportCardId
                );
                updatedCard.dynamicProperties.downloadables = downloadables;
            }

            return clonedState;
        default:
            return state;
    }
}

export const reportDefinitionDataReducerNexusWrapper = (nexusReducer) => (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_REPORT_SUCCESS:
        case customReportsActionTypes.STORE_HYDRATED_REPORT_DEFINTION:
        case customReportsActionTypes.SAVE_REPORT_DEFINITION_SUCCESS:
        case customReportsActionTypes.SAVE_DOWNLOADABLES_SUCCESS: {
            const result = reportDefinitionDataReducer(
                get(state, 'reportDefinitions.global', {}),
                action
            );
            const nexusResult = nexusReducer(state, action);
            if (size(result) > 0) {
                return {
                    ...nexusResult,
                    reportDefinitions: {
                        global: {
                            ...get(nexusResult, 'reportDefinitions.global'),
                            ...result,
                        },
                    },
                };
            }
            return nexusResult;
        }
        default:
            return nexusReducer(state, action);
    }
};

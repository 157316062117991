import _, { keyBy } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

export default createFormModule({
    formName: formClientEnum.EDIT_ROLE_ABILITIES_ENABLED,
    convertToFormModel: (abilities, abilityRoleLinks) => {
        const abilityRoleLinksByAbilityId = keyBy(abilityRoleLinks, 'abilityId');
        return {
            abilities: _(abilities)
                .mapKeys('id')
                .mapValues(({ id }) => ({
                    id,
                    isEnabled: !!abilityRoleLinksByAbilityId[id],
                }))
                .value(),
        };
    },
    fieldViewModels: {
        type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
        key: 'abilities',
        fields: buildFlatFormFieldViewModels(['id', 'isEnabled']),
    },
});

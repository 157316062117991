import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { first, get, omit } from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';
import { combineReducers } from 'redux';
import { elasticReportResultViewModelsSelector } from '~/client-common/core/domain/elastic-reports/state/ui';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
    globalSequenceNumberLabelSelector,
} from '~/client-common/core/fields/state/config';
import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import reportsDashboardSearchForm, {
    convertReportsDashboardFormModelToFilterGroups,
} from '../forms/reportsDashboardSearchForm';

import { transformElasticQueryBeforeSearchSelector } from '../../../../search/reports/state/ui';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import { updateActiveColumnKeysBySortKey } from './config';

const UPDATE_TAB_COUNTS = 'ADVANCED_SEARCH_REPORTS_UPDATE_TAB_COUNTS';

const reportsDashboardBaseSelector = (state) => state.ui.reports.dashboard;

const reportsDashboardSearchSelector = createSelector(
    reportsDashboardBaseSelector,
    (dashboardState) => dashboardState.search
);

const reportsDashboardUiSelector = createSelector(
    reportsDashboardBaseSelector,
    (dashboardState) => dashboardState.ui
);

export const reportsDashboardSearch = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.REPORTS_DASHBOARD.name,
    baseUiSelector: reportsDashboardSearchSelector,
    searchResultToRoutePath: ({ id }) => `/reports/${id}`,
    form: reportsDashboardSearchForm,
    resourceMethod: elasticSearchResource.searchReports,
    elasticQueryToFilterGroups: convertReportsDashboardElasticQueryToFilterGroups,
    resultsContainerClassName: 'reports-dashboard-results-container',
    createResultsViewModelsSelector: elasticReportResultViewModelsSelector,
    defaultTableState: {
        from: 0,
        size: 25,
        sortKey: sortKeyEnum.REPORT_EVENT_START_UTC,
        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
        activeColumnKeys: {
            date: 'eventStartUtc',
            entity: 'reportOwner',
            location: 'locations', // this is "primary location"
            ren: 'reportingEventNumber',
        },
    },
    selectorToBind: createStructuredSelector({
        combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        formatFieldByName: formatFieldByNameSelector,
        globalSequenceNumberLabel: globalSequenceNumberLabelSelector,
    }),
    transformElasticQueryBeforeSearchSelector,
    updateActiveColumnKeysBySortKey,
});

export const currentReportDashboardSearchClientApprovalStatusSelector = createSelector(
    reportsDashboardSearch.selectors.currentQuerySelector,
    ({ elasticQuery }) => {
        return first(get(elasticQuery, 'clientApprovalStatuses', approvalStatusClientEnum.DRAFT));
    }
);

export const reportDashboardTabNameToCountSelector = createSelector(
    reportsDashboardUiSelector,
    (uiState) => uiState.tabNameToCount
);

export function convertReportsDashboardElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    // we don't want the quick search box or approvalStatus tab to show up in filter summary
    const filteredQuery = omit(elasticQuery, 'quickSearchQuery', 'approvalStatus');
    return convertReportsDashboardFormModelToFilterGroups(
        reportsDashboardSearchForm.convertToFormModel(filteredQuery),
        formatFieldValue,
        boundSelectors
    );
}

export const setReportDashboardSearchClientApprovalStatus = (reportStatus) => (
    dispatch,
    getState
) => {
    const formModel = reportsDashboardSearchForm.selectors.formModelSelector(getState());
    const formModelWithClientApprovalStatuses = {
        ...formModel,
        reportDetails: {
            ...omit(
                formModel.reportDetails,
                'approvalStatus',
                'secondaryApprovalStatuses',
                'hasSecondaryApprovalStatus'
            ),
            clientApprovalStatuses: [reportStatus],
        },
        isFormModel: true,
    };

    dispatch(reportsDashboardSearchForm.actionCreators.change(formModelWithClientApprovalStatuses));
};
export const submitReportsDashboardSearch = ({ autoSave = true } = {}) => (dispatch) => {
    dispatch(reportsDashboardSearch.form.actionCreators.updateShiftTimeRange());
    dispatch(
        reportsDashboardSearch.form.actionCreators.submit((formData) => {
            dispatch(
                reportsDashboardSearch.actionCreators.search(
                    {
                        formData,
                        from: 0,
                    },
                    { cacheBust: true, showLoadingModal: false, autoSave }
                )
            );
            dispatch(searchTabCounts(formData));
        })
    );
};

const fillIfUndefined = (field, value) => (dispatch, getState) => {
    const currentValue = reportsDashboardSearchForm.selectors.formModelByPathSelector(getState())(
        field
    );
    if (currentValue === undefined) {
        dispatch(reportsDashboardSearchForm.actionCreators.changePath(field, value));
    }
};

export const searchInitialReports = () => (dispatch, getState) => {
    dispatch(reportsDashboardSearch.actionCreators.loadAndExecuteLatestAutoSavedSearch()).then(
        (result) => {
            dispatch(
                fillIfUndefined('reportDetails.clientApprovalStatuses', [
                    approvalStatusClientEnum.DRAFT,
                ])
            );
            dispatch(fillIfUndefined('dateTime.dateType', dateTypeEnum.EVENT));
            if (!result) {
                // execute first time visit search
                dispatch(submitReportsDashboardSearch({ autoSave: false }));
            } else {
                dispatch(
                    searchTabCounts(
                        reportsDashboardSearchForm.selectors.formModelSelector(getState())
                    )
                );
            }
        }
    );
};

const searchTabCounts = (formData) => (dispatch, getState) => {
    const elasticQuery = {
        ...omit(
            reportsDashboardSearch.form.convertFromFormModel(formData),
            'clientApprovalStatuses'
        ),
    };

    const transformedQuery = transformElasticQueryBeforeSearchSelector(getState())(elasticQuery);

    elasticSearchResource
        .searchReportsAggregate(transformedQuery, 'clientApprovalStatus')
        .then((searchResults) => {
            dispatch({
                type: UPDATE_TAB_COUNTS,
                payload: searchResults.buckets,
            });
        });
};

export const clearReportsDashboardFilter = (path, filterKey) => (dispatch, getState) => {
    const formModel = reportsDashboardSearchForm.selectors.formModelSelector(getState());

    if (filterKey.endsWith(']')) {
        // if our filterKey is an array index (e.g. involved officer), then we have to remove just the item from the array
        // this block parses out the array index, and uses the index to return a new array without it
        // ideally, we change our form filters such that we have a filter key and index instead of 'key[index]'
        const leftBracketIndex = filterKey.indexOf('[');
        const itemIndex = parseInt(filterKey.substring(leftBracketIndex + 1, filterKey.length - 1));
        const arrayKey = filterKey.substring(0, leftBracketIndex);
        const oldArray = get(formModel[path], arrayKey);
        const newArray = oldArray.slice(0, itemIndex).concat(oldArray.slice(itemIndex + 1));
        dispatch(
            reportsDashboardSearchForm.actionCreators.changePath(
                `${path}.${arrayKey}`,
                newArray.length > 0 ? newArray : undefined
            )
        );
    } else {
        dispatch(
            reportsDashboardSearchForm.actionCreators.changePath(`${path}.${filterKey}`, undefined)
        );
    }
};

export const resetReportsDashboard = () => (dispatch, getState) => {
    // keep current clientApprovalStatus, as it determines which results tab we're on
    const state = getState();
    const clientApprovalStatuses = reportsDashboardSearchForm.selectors.formModelByPathSelector(
        state
    )('reportDetails.clientApprovalStatuses');
    dispatch(
        reportsDashboardSearchForm.actionCreators.change({
            reportDetails: {
                clientApprovalStatuses,
            },
            isFormModel: true,
            dateTime: {
                dateType: dateTypeEnum.EVENT,
            },
        })
    );
    dispatch(submitReportsDashboardSearch());
};

export const searchTabCountsAfterExecutingSavedSearch = (executionResult) => {
    return function (dispatch, getState) {
        if (executionResult) {
            return dispatch(
                searchTabCounts(reportsDashboardSearchForm.selectors.formModelSelector(getState()))
            );
        }
    };
};

const initialUiState = {
    tabNameToCount: {},
};

function uiReducer(state = initialUiState, action) {
    switch (action.type) {
        case UPDATE_TAB_COUNTS:
            return {
                ...state,
                tabNameToCount: action.payload,
            };
        default:
            return state;
    }
}

export default combineReducers({
    search: reportsDashboardSearch.reducers.uiReducer,
    ui: uiReducer,
});

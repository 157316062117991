import { EntityTypeEnum } from '@mark43/rms-api';
import { size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import * as fields from '~/client-common/core/enums/universal/fields';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { dateTimeRangeFormatter } from '~/client-common/core/dates/utils/dateHelpers';
import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';

import { agencyProfilesWhereSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import {
    formatAttributeByIdSelector,
    formatLinkAttributesSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { legacyEntityDetailsWhereSelector } from '~/client-common/core/domain/legacy-entity-details/state/data';
import { hydratedSupplementInfoByReportIdSelector } from '~/client-common/core/domain/reports/state/ui';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import LegacyEntityDetails from '../../../../records/core/components/LegacyEntityDetails';
import testIds from '../../../../../core/testIds';
import { abilitiesEnum, OnlyWithAbility } from '../../../../core/abilities';
import UcrClassificationSummary from '../event-info/UcrClassificationSummary';
import { useDateTimeFormatter } from '../../../../core/current-user/hooks/dateTimeFormats';

const mapStateToProps = createStructuredSelector({
    hydratedSupplementInfoByReportId: hydratedSupplementInfoByReportIdSelector,
    agencyProfilesWhere: agencyProfilesWhereSelector,
    legacyEntityDetailsWhere: legacyEntityDetailsWhereSelector,
    formatAttributeById: formatAttributeByIdSelector,
    formatLinkAttributes: formatLinkAttributesSelector,
});

const SupplementInfoCardSummary = connect(mapStateToProps)(function _SupplementInfoCardSummary({
    reportId,
    hydratedSupplementInfoByReportId,
    agencyProfilesWhere,
    legacyEntityDetailsWhere,
    formatAttributeById,
    formatLinkAttributes,
}) {
    const {
        report: { departmentId, createdDateUtc, description },
        reportAttributes,
        eventDetail: { eventStartUtc, eventEndUtc, respondingOfficerId, personnelUnitAttrId },
        agencyProfile: { agencyName },
    } = hydratedSupplementInfoByReportId(reportId);
    const dateTimeFormatter = useDateTimeFormatter();

    const isAgencyProfileShown = size(agencyProfilesWhere({ departmentId })) > 1;
    const legacyEntityDetails = legacyEntityDetailsWhere({
        entityType: EntityTypeEnum.REPORT.name,
        entityId: reportId,
    });

    return (
        <SummaryList labelWidth={160} contentWidth={380} testId={testIds.CARD_SUMMARY}>
            <CardSection>
                <SummaryRowDate fieldName={fields.REPORT_CREATED_DATE_UTC} date={createdDateUtc} />
                <SummaryRow fieldName={fields.REPORT_DESCRIPTION}>{description}</SummaryRow>
                <SummaryRow fieldName={fields.DISPLAY_EVENT_DETAIL_EVENT_START_END}>
                    {dateTimeRangeFormatter(eventStartUtc, eventEndUtc, dateTimeFormatter)}
                </SummaryRow>
                {isAgencyProfileShown && (
                    <SummaryRow fieldName={fields.REPORT_AGENCY_ID}>{agencyName}</SummaryRow>
                )}
                <SummaryRow
                    hidden={isUndefinedOrNull(respondingOfficerId)}
                    fieldName={fields.EVENT_DETAIL_RESPONDING_OFFICER_ID}
                >
                    <ConnectedFormattedUser
                        userId={respondingOfficerId}
                        format={FORMATS.FULL_NAME}
                    />
                </SummaryRow>
                <SummaryRow fieldName={fields.EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID}>
                    {formatAttributeById(personnelUnitAttrId)}
                </SummaryRow>
                <SummaryRow
                    fieldName={fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_SUPPLEMENT_TYPE_ATTRIBUTE_ID}
                >
                    {formatLinkAttributes(reportAttributes)}
                </SummaryRow>
                <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_UCR}>
                    <UcrClassificationSummary reportId={reportId} />
                </OnlyWithAbility>
            </CardSection>
            <LegacyEntityDetails legacyEntityDetails={legacyEntityDetails} />
        </SummaryList>
    );
});

export default SupplementInfoCardSummary;

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';

import { Fieldset } from 'markformythree';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_PERSON_PROFILE_INJURY_LABEL,
    DISPLAY_ONLY_PERSON_PROFILE_TREATMENT_DETAILS_LABEL,
    DISPLAY_ONLY_PERSON_PROFILE_PHYSICIAN_LABEL,
} from '~/client-common/core/enums/universal/fields';

import testIds from '../../../../core/testIds';
import { responsiveStack } from '../../styles/mixins';
import { renderAddButton } from '../../names/components/nameFormNItemsRenderers';
import { MFTNItems } from '../../forms/components/NItems';
import { ArbiterMFTText } from '../../forms/components/Text';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import FormRow from '../../forms/components/FormRow';
import { ArbiterMFTAttributeCheckboxes } from '../../forms/components/checkboxes/AttributeCheckboxes';
import { SomeFieldsHaveTruthyValue } from '../../components/SomeFieldsHaveValue';
import { renderInjuriesNItems, renderAttendingPhysiciansNItems } from './nameFormNItemsRenderers';
import { NItemsRow } from './NItemsRow';
import { FormSection } from './FormSection';
import { CheckboxCenteredWithTextField } from './CheckboxCenteredWithTextField';

const showMedicalTreatmentFields = ['showMedicalTreatment'];

const StyledFieldset = styled(Fieldset)`
    margin-bottom: 10px;
`;

const ResponsiveStack = styled.div`
    ${responsiveStack('sm')};
`;

export function PersonProfileFormInjuryFields({ form }) {
    const labels = useFields([
        DISPLAY_ONLY_PERSON_PROFILE_INJURY_LABEL,
        DISPLAY_ONLY_PERSON_PROFILE_TREATMENT_DETAILS_LABEL,
        DISPLAY_ONLY_PERSON_PROFILE_PHYSICIAN_LABEL,
    ]);
    return (
        <>
            <MFTNItems
                path="injuries"
                addItemOnEmpty={false}
                renderRowContainer={({ itemElement, index, item }) => (
                    <NItemsRow key={index} index={index} item={item} children={itemElement} />
                )}
                addText={labels[DISPLAY_ONLY_PERSON_PROFILE_INJURY_LABEL]}
                hideAddButtonOnEmptyItem={true}
                childFieldKeys={[
                    'description',
                    'injuryTypeAttrId',
                    'bodyPartAttrId',
                    'weaponsUsedAttrId',
                    'wasWeaponSeized',
                    'injurySeverityAttrId',
                ]}
                render={({ removeItem, ...props }) => (
                    <FormSection removeItem={removeItem}>{renderInjuriesNItems(props)}</FormSection>
                )}
                renderAddButton={(props) =>
                    renderAddButton({ ...props, testId: testIds.PERSON_PROFILE_ADD_INJURY })
                }
                renderRemoveButton={undefined}
            />
            <SomeFieldsHaveTruthyValue formPathsToObserve={showMedicalTreatmentFields}>
                {(someFieldsHaveTruthyValue) =>
                    !someFieldsHaveTruthyValue &&
                    renderAddButton({
                        addText: labels[DISPLAY_ONLY_PERSON_PROFILE_TREATMENT_DETAILS_LABEL],
                        addItem: () => form.set('showMedicalTreatment', true),
                    })
                }
            </SomeFieldsHaveTruthyValue>
            <StyledFieldset path="medicalTreatment">
                <FormSection removeItem={() => form.set('showMedicalTreatment', false)}>
                    <ResponsiveStack>
                        <ArbiterMFTAttributeSelect
                            path="medicalTreatmentAttrId"
                            attributeType={AttributeTypeEnum.MEDICAL_TREATMENT_RECEIVED.name}
                            length="md"
                        />
                        <CheckboxCenteredWithTextField path="medicineTransported" />
                    </ResponsiveStack>
                    <FormRow hasIndent>
                        <ArbiterMFTAttributeSelect
                            path="medicalTransportationTypeAttrId"
                            attributeType={AttributeTypeEnum.MEDICAL_TRANSPORT_TYPE.name}
                            length="md"
                        />
                        <ArbiterMFTText path="ambulanceNumber" length="md" />
                        <ArbiterMFTText path="takenToHospitalBy" length="lg" />
                    </FormRow>
                    <ArbiterMFTAttributeSelect
                        path="medicalFacilityAttrId"
                        attributeType={AttributeTypeEnum.MEDICAL_FACILITY.name}
                        length="md"
                    />
                    <ArbiterMFTText path="hospitalTreatedAt" length="lg" />
                    <MFTNItems
                        path="attendingPhysicians"
                        addItemOnEmpty={false}
                        addText={labels[DISPLAY_ONLY_PERSON_PROFILE_PHYSICIAN_LABEL]}
                        hideAddButtonOnEmptyItem={true}
                        childFieldKeys={['name']}
                        render={renderAttendingPhysiciansNItems}
                        renderAddButton={(props) =>
                            renderAddButton({
                                ...props,
                                testId: testIds.PERSON_PROFILE_ADD_PHYSICIAN,
                            })
                        }
                        renderRemoveButton={undefined}
                        showLabelForFirstItemOnly={true}
                    />
                    <ArbiterMFTAttributeCheckboxes
                        label={undefined}
                        path="medicalStatisticsAttrIds"
                        attributeType={AttributeTypeEnum.MEDICAL_STATISTICS.name}
                        columns={1}
                        columnWidth="100%"
                    />
                </FormSection>
            </StyledFieldset>
        </>
    );
}

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import * as fields from '~/client-common/core/enums/universal/fields';

import {
    formatAttributeWithOtherSelector,
    formatLinkAttributesSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { hydratedCommunityInfoByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/communityInfos';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';

const mapStateToProps = createStructuredSelector({
    formatAttributeWithOther: formatAttributeWithOtherSelector,
    formatLinkAttributes: formatLinkAttributesSelector,
    hydratedCommunityInfoByReportId: hydratedCommunityInfoByReportIdSelector,
});

const CommunityInfoCardSummary = connect(mapStateToProps)(
    ({
        reportId,
        formatAttributeWithOther,
        formatLinkAttributes,
        hydratedCommunityInfoByReportId,
    }) => {
        const {
            fieldContact: {
                communityInformationObtainedFromAttrId,
                communityInformationObtainedFromOther,
            },
            reportAttributes,
        } = hydratedCommunityInfoByReportId(reportId);

        return (
            <SummaryList labelWidth={220} contentWidth={320}>
                <CardSection>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_COMMUNITY_INFORMATION_REASON_FOR_REPORT_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.COMMUNITY_INFORMATION_REASON_FOR_REPORT.name
                        )}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_COMMUNITY_INFORMATION_DISPOSITION_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.COMMUNITY_INFORMATION_DISPOSITION.name
                        )}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_COMMUNITY_INFORMATION_STATISTICS_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.COMMUNITY_INFORMATION_STATISTICS.name
                        )}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.FIELD_CONTACT_COMMUNITY_INFORMATION_OBTAINED_FROM_ATTR_ID}
                    >
                        {formatAttributeWithOther({
                            attributeId: communityInformationObtainedFromAttrId,
                            other: communityInformationObtainedFromOther,
                            joinWith: ' - ',
                        })}
                    </SummaryRow>
                </CardSection>
            </SummaryList>
        );
    }
);

export default CommunityInfoCardSummary;

import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { get } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import {
    EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID,
    REPORT_SUBMISSION_OWNER_ID,
} from '~/client-common/core/enums/universal/fields';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFOfficerInvolvementSelect } from '../../../core/forms/components/selects/OfficerInvolvementSelect';
import { RRFNItems } from '../../../core/forms/components/NItems';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';

const { withRRFActions } = reactReduxFormHelpers;
const strings = componentStrings.reports.dashboard.ReportPersonnelFieldset;

const RRFReviewerSelect = styled(RRFUserSelect)`
    && {
        margin-right: 0;
    }
`;

const FlexRow = styled.div`
    display: flex;
`;

const InvolvedOfficerNItem = compose(
    withRRFActions,
    withHandlers({
        onBlur({ formActions: { setValidity }, assistTypeAttrId, officerInvolvement, officerId }) {
            return () =>
                setValidity('officerId', {
                    requiredError: !!officerId || (!assistTypeAttrId && !officerInvolvement),
                });
        },
    })
)(function InvolvedOfficerNItem({ onBlur, onChange }) {
    return (
        <>
            <RRFOfficerInvolvementSelect length="md" onBlur={onBlur} />
            <RRFUserSelect
                length="md"
                multiple={true}
                path="officerIds"
                effectiveDate={null}
                onBlur={onBlur}
                onChange={onChange}
            />
        </>
    );
});

function InvolvedOfficerNItems({ onChange }) {
    return (
        <RRFNItems path="involvedOfficers" addItemOnDirty={true}>
            {({ assistTypeAttrId, officerInvolvement, officerId }, index, deleteButton) => (
                <FlexRow>
                    <InvolvedOfficerNItem
                        assistTypeAttrId={assistTypeAttrId}
                        officerInvolvement={officerInvolvement}
                        officerId={officerId}
                        index={index}
                        onChange={onChange}
                    />
                    {deleteButton}
                </FlexRow>
            )}
        </RRFNItems>
    );
}

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    fieldConfigurationContextByContextAndFieldName: fieldConfigurationContextByContextAndFieldNameSelector,
});

export default connect(mapStateToProps)(function PersonnelFieldset({
    onSubmit,
    formatFieldByName,
    fieldConfigurationContextByContextAndFieldName,
}) {
    const personnelUnitEventCardFieldConfigContext = fieldConfigurationContextByContextAndFieldName(
        RefContextEnum.FORM_EVENT_INFO.name,
        EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID
    );
    const isPersonnelUnitStaticallyHidden = !!get(
        personnelUnitEventCardFieldConfigContext,
        'isStaticallyHidden'
    );
    return (
        <RRFFieldset path="personnel" title={strings.title}>
            <FlexRow>
                {!isPersonnelUnitStaticallyHidden && (
                    <RRFAttributeSelect
                        multiple={true}
                        label={formatFieldByName(EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID)}
                        attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                        includeExpired={true}
                        length="md"
                        path="personnelUnitAttrIds"
                        onChange={onSubmit}
                    />
                )}
                <RRFUserSelect
                    fieldNameAlternate={REPORT_SUBMISSION_OWNER_ID}
                    multiple={true}
                    length="md"
                    path="currentSubmissionOwnerIds"
                    effectiveDate={null}
                    onChange={onSubmit}
                />
                <RRFUserSelect
                    multiple={true}
                    length="md"
                    path="authorIds"
                    effectiveDate={null}
                    onChange={onSubmit}
                />
                <RRFReviewerSelect
                    multiple={true}
                    length="md"
                    path="reviewerIds"
                    effectiveDate={null}
                    onChange={onSubmit}
                />
            </FlexRow>
            <InvolvedOfficerNItems onChange={onSubmit} />
        </RRFFieldset>
    );
});

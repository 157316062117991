import type { Editor } from 'tinymce';

import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { reportInlineCommentsViewSelector } from '~/client-common/core/domain/inline-report-comments/state/data';

import { RmsAction } from '../../../../../core/typings/redux';
import {
    migratePreExistingComments,
    preprocessHtml,
} from '../../../../core/editor/utils/tinyEditor';

/**
 * Update the TinyMCE editor with annotations for inline comments.
 * Dispatch this action to refresh the TinyMCE editor state after comments are updated in Redux state.
 * This action does not update form state or Redux state.
 */
export function updateNarrativeHtmlWithComments(
    editor: Editor,
    narrativeHtml: string
): RmsAction<void> {
    return (dispatch, getState) => {
        const state = getState();
        const formatMiniUserById = formatMiniUserByIdSelector(state);
        const reportInlineCommentViews = reportInlineCommentsViewSelector(state);

        const narrativeHtmlWithComments = migratePreExistingComments(
            preprocessHtml(narrativeHtml),
            formatMiniUserById,
            reportInlineCommentViews
        );
        editor.setContent(narrativeHtmlWithComments);
    };
}

import { AttributeTypeEnum, CodeTypeCategoryEnum } from '@mark43/rms-api';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { chain } from 'lodash';
import React from 'react';
import { compose, withHandlers } from 'recompose';
import { Fieldset as MFTFieldset, Observer } from 'markformythree';
import styled from 'styled-components';
import { activeAttributesByTypeSelector } from '~/client-common/core/domain/attributes/state/data';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { ITEM_IDENTIFIER_IDENTIFIER } from '~/client-common/core/enums/universal/fields';
import { nibrsCodeForAttributeIdAndCodeTypeCategorySelector } from '../../../../ucr-classification/state/ui/selectors';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import { MFTNItems, RemoveButton } from '../../../../../core/forms/components/NItems';
import FormRow from '../../../../../core/forms/components/FormRow';
import Divider from '../../../../../core/components/Divider';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTCheckbox } from '../../../../../core/forms/components/checkboxes/Checkbox';
import { ArbiterMFTBooleanSelect } from '../../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTBooleanButtonRadio } from '../../../../../core/forms/components/button-radios/BooleanButtonRadio';

const ItemIdentifierContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
`;

const RemoveButtonElement = styled(RemoveButton)`
    margin-top: 28px;
    flex-shrink: 0;
`;

const Flex = styled.div`
    display: flex;
`;

const SharedItemDetailsFieldset = compose(
    connect(
        createStructuredSelector({
            notReportedAttrId: createSelector(
                activeAttributesByTypeSelector,
                nibrsCodeForAttributeIdAndCodeTypeCategorySelector,
                (activeAttributesByType, nibrsCodeForAttributeIdAndCodeTypeCategory) =>
                    chain(AttributeTypeEnum.DRUG_MEASUREMENT.name)
                        .thru(activeAttributesByType)
                        .find(
                            ({ id }) =>
                                nibrsCodeForAttributeIdAndCodeTypeCategory(
                                    id,
                                    CodeTypeCategoryEnum.NIBRS_DRUG_MEASUREMENT.name
                                ) === 'XX'
                        )
                        .get('id')
                        .value()
            ),
        })
    ),
    withHandlers({
        onIsQuantityValueUnknown: ({ form, notReportedAttrId }) => (value) => {
            if (value) {
                form.resetUi('sharedItemDetails.measurementUnitsAttrId');
                form.resetUi('sharedItemDetails.drugQuantity');
                form.set(
                    'sharedItemDetails.measurementUnitsAttrId',
                    notReportedAttrId || undefined
                );
                form.set('sharedItemDetails.drugQuantity', undefined);
            } else {
                form.resetUi('sharedItemDetails.measurementUnitsAttrId');
                form.set('sharedItemDetails.measurementUnitsAttrId', undefined);
            }

            form.set('sharedItemDetails.isQuantityUnknown', value);
        },
        onBiohazardBooleanSelectChange: ({ form }) => (value) => {
            form.set('sharedItemDetails.isBiohazard', value);
            if (!value) {
                form.set('sharedItemDetails.biohazardDescription', undefined);
            }
        },
    })
)(({ helpTextCollisionBoundary, onIsQuantityValueUnknown, onBiohazardBooleanSelectChange }) => {
    const labels = useFields(ITEM_IDENTIFIER_IDENTIFIER);
    return (
        <SidePanelSection>
            <MFTFieldset path="sharedItemDetails">
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="returnToOwnerAttrId"
                    attributeType={AttributeTypeEnum.YES_NO_UNKNOWN.name}
                    length="md"
                />
                <ArbiterMFTBooleanButtonRadio path="wasNcicCheckMadeForIdentifiableProperty" />
                <FormRow>
                    <ArbiterMFTBooleanSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="isBiohazard"
                        length="sm"
                        onChange={onBiohazardBooleanSelectChange}
                    />

                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="biohazardDescription"
                        length="lg"
                    />
                </FormRow>
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="firearmQuantity"
                    length="md"
                />
                <FormRow>
                    <Observer
                        subscriptions={{
                            isQuantityUnknown: 'sharedItemDetails.isQuantityUnknown',
                        }}
                        render={({ isQuantityUnknown }) => (
                            <ArbiterMFTText
                                disabled={isQuantityUnknown}
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="drugQuantity"
                                length="md"
                            />
                        )}
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="otherItemQuantity"
                        length="md"
                    />
                    <Observer
                        subscriptions={{
                            isQuantityUnknown: 'sharedItemDetails.isQuantityUnknown',
                        }}
                        render={({ isQuantityUnknown }) => (
                            <ArbiterMFTAttributeSelect
                                disabled={isQuantityUnknown}
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="measurementUnitsAttrId"
                                attributeType={AttributeTypeEnum.DRUG_MEASUREMENT.name}
                                length="md"
                            />
                        )}
                    />
                </FormRow>
                <ArbiterMFTCheckbox
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    onChange={onIsQuantityValueUnknown}
                    path="isQuantityUnknown"
                    length="md"
                />

                <FormRow>
                    <ArbiterMFTText
                        helpTextContainer={helpTextCollisionBoundary}
                        path="drugQuantityTwo"
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextContainer={helpTextCollisionBoundary}
                        path="measurementUnitsTwoAttrId"
                        attributeType={AttributeTypeEnum.DRUG_MEASUREMENT.name}
                        length="md"
                    />

                    <ArbiterMFTText
                        helpTextContainer={helpTextCollisionBoundary}
                        path="drugQuantityThree"
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextContainer={helpTextCollisionBoundary}
                        path="measurementUnitsThreeAttrId"
                        attributeType={AttributeTypeEnum.DRUG_MEASUREMENT.name}
                        length="md"
                    />
                </FormRow>
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="drugDataSourceAttrId"
                    attributeType={AttributeTypeEnum.DRUGS_DATA_SOURCE.name}
                    length="md"
                />
                <Divider />
                <MFTNItems
                    path="itemIdentifiers"
                    addText={labels[ITEM_IDENTIFIER_IDENTIFIER]}
                    childFieldKeys={[
                        'itemIdentifierTypeAttrId',
                        'itemIdentifierTypeOtherDesc',
                        'identifier',
                    ]}
                    renderRemoveButton={undefined}
                    render={({ items, index, removeItem }) => (
                        <ItemIdentifierContainer>
                            <Flex>
                                <ArbiterMFTAttributeSelect
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="itemIdentifierTypeAttrId"
                                    attributeType={AttributeTypeEnum.ITEM_IDENTIFIER_TYPE.name}
                                />
                                <ArbiterMFTText
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="identifier"
                                />
                                {(index > 0 || items.length > 1) && (
                                    <RemoveButtonElement onClick={removeItem} />
                                )}
                            </Flex>
                            <FormRow hasIndent>
                                <ArbiterMFTText
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    path="itemIdentifierTypeOtherDesc"
                                    length="md"
                                />
                            </FormRow>
                        </ItemIdentifierContainer>
                    )}
                />
                <ArbiterMFTText
                    path="ncicSearchNumbers"
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    length="lg"
                />
                <ArbiterMFTText
                    path="ncicNumber"
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    length="md"
                />
            </MFTFieldset>
        </SidePanelSection>
    );
});

/**
 * Fieldset that appears in the main section of the item entry v2 form, below a fieldset that is
 *   specific to an item type (firearm, vehicle, etc.) These fields are not specific to any item
 *   type, and come from various models including ItemProfile, PropertyStatus, and ItemIdentifier.
 */
export default SharedItemDetailsFieldset;

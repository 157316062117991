import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';

import { lifecycleOptions } from 'markformythree';

import { formName } from '../../state/forms/communityInfoForm';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';

const CommunityInfoCardForm = () => {
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            render={() => {
                return (
                    <CardSection>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="communityInformationReasonForReportAttrIds"
                                length="lg"
                                attributeType={
                                    AttributeTypeEnum.COMMUNITY_INFORMATION_REASON_FOR_REPORT.name
                                }
                                multiple={true}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText
                                    path="communityInformationReasonForReportDescription"
                                    length="lg"
                                />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeCheckboxes
                                path="communityInformationDispositionAttrIds"
                                attributeType={
                                    AttributeTypeEnum.COMMUNITY_INFORMATION_DISPOSITION.name
                                }
                                columns={2}
                                columnWidth="48%"
                                gutterWidth="4%"
                            />
                            <Row>
                                <IndentedFields>
                                    <ArbiterMFTText
                                        path="communityInformationDispositionDescription"
                                        length="lg"
                                    />
                                </IndentedFields>
                            </Row>
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeCheckboxes
                                path="communityInformationStatisticsAttrIds"
                                attributeType={
                                    AttributeTypeEnum.COMMUNITY_INFORMATION_STATISTICS.name
                                }
                                columns={2}
                                columnWidth="48%"
                                gutterWidth="4%"
                            />
                            <Row>
                                <IndentedFields>
                                    <ArbiterMFTText
                                        path="communityInformationStatisticsDescription"
                                        length="lg"
                                    />
                                </IndentedFields>
                            </Row>
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="communityInformationObtainedFromAttrId"
                                length="lg"
                                attributeType={
                                    AttributeTypeEnum.COMMUNITY_INFORMATION_OBTAINED_FROM.name
                                }
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText
                                    path="communityInformationObtainedFromOther"
                                    length="lg"
                                />
                            </IndentedFields>
                        </Row>
                    </CardSection>
                );
            }}
        />
    );
};

export default CommunityInfoCardForm;

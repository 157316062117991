import { EntityTypeEnum } from '@mark43/rms-api';
import { queryParamDefaults } from '~/client-common/configs/advancedSearchConfig';
import getUsageLogsResource from '~/client-common/core/domain/usage-logs/resources/usageLogsResource';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import usageLogsSearchForm from '../forms/usageLogsSearchForm';
import createElasticReportsResultsSelector from '../../../../search/core/utils/createReportsResultsSelector';
import { scrollToElement } from '../../../../../legacy-redux/helpers/navigationHelpers';
import searchResource from '../../../../../legacy-redux/resources/searchResource';

export const usageLogsSearch = createSearchModule({
    elasticSearchType: 'USAGE_LOGS',
    baseUiSelector: (state) => state.ui.usageLogsAdmin,
    searchResultToRoutePath: usageLogToRoutePath,
    form: usageLogsSearchForm,
    defaultTableState: {
        from: queryParamDefaults.FROM,
        size: queryParamDefaults.SIZE,
    },
    exportResourceMethod: searchResource.exportUsageLogs,
    resourceMethod: (elasticQuery, from, size, sortKey, sortType, dispatch) => {
        return getUsageLogsResource()
            .searchUsageLogs(elasticQuery, from, size)
            .then((result) => {
                dispatch(usageLogsSearchForm.actionCreators.change(result.query.elasticQuery));
                return result;
            });
    },
    resultsContainerClassName: 'usage-logs-results-container',
    createResultsViewModelsSelector: createElasticReportsResultsSelector,
    scrollToElement: (selector, scrollDuration) =>
        // since this page has a subheader, the wrapper element to scroll within
        // is different from the default
        scrollToElement({
            selector,
            scrollDuration,
            wrapperSelector: '.mark43-scrollable-under-subheader',
        }),
});

function usageLogToRoutePath({ primaryEntityType, primaryEntityId }) {
    if (primaryEntityId > 0) {
        switch (primaryEntityType) {
            case EntityTypeEnum.CASE.name:
                return `/cases/${primaryEntityId}`;
            case EntityTypeEnum.REPORT.name:
                return `/reports/${primaryEntityId}`;
            case EntityTypeEnum.PERSON_PROFILE.name:
                return `/profiles/persons/${primaryEntityId}`;
            case EntityTypeEnum.ORGANIZATION_PROFILE.name:
                return `/profiles/organizations/${primaryEntityId}`;
            case EntityTypeEnum.ITEM_PROFILE.name:
                return `/profiles/property/${primaryEntityId}`;
            case EntityTypeEnum.WARRANT.name:
                return `warrants/${primaryEntityId}`;
            case EntityTypeEnum.FIELD_NOTE.name:
                return `mobile/collections/${primaryEntityId}`;
            default:
                return null;
        }
    } else {
        return null;
    }
}

export function clearSearchForm() {
    return (dispatch) => {
        dispatch(usageLogsSearchForm.actionCreators.change({}));
    };
}
/** @typedef {import('redux').Reducer} Reducer */

/**
 * Redux module for usage logs search.
 * TODO export members of this module individually
 * @type {Reducer<any, any>}
 */
export default usageLogsSearch.reducers.uiReducer;

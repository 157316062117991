import { EntityTypeEnum, AttributeTypeEnum, FormEnum, LinkTypesEnum } from '@mark43/rms-api';
import React, { useRef } from 'react';
import { withHandlers } from 'recompose';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get, first, last, sortBy, values, defer, isEmpty } from 'lodash';
import classNames from 'classnames';
import styled from 'styled-components';
import { reduxForm } from 'redux-form-mark43';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import stringsConfig from '~/client-common/core/strings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    replaceLocationLinksForEntity,
    locationEntityLinksWhereSelector,
} from '~/client-common/core/domain/location-entity-links/state/data';
import { agencyProfileOptionsWhereSelector } from '~/client-common/core/domain/agency-profiles/state/ui';
import { dateSortedAugmentedAttachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import Text from '../../../../modules/core/forms/components/Text';
import Select from '../../../../modules/core/forms/components/selects/Select';
import AttributeSelect from '../../../../modules/core/forms/components/selects/AttributeSelect';
import AdminSummarySectionHeader from '../../../../modules/admin/core/components/AdminSummarySectionHeader';
import Row from '../../../../modules/core/components/Row';
import { regionalGroupOptions } from '../../../../modules/core/forms/components/selects/RegionalGroupSelect';
import { dexRegionOptions } from '../../../../modules/core/forms/components/selects/DexRegionSelect';
import { complianceGroupOptions } from '../../../../modules/core/forms/components/selects/ComplianceGroupSelect';
import DepartmentStatusSelect from '../../../../modules/core/forms/components/selects/DepartmentStatusSelect';
import CountrySelect from '../../../../modules/core/forms/components/selects/CountrySelect';
import TimeZoneSelect from '../../../../modules/core/forms/components/selects/TimeZoneSelect';
import StatePlaneZoneSelect from '../../../../modules/core/forms/components/selects/StatePlaneZoneSelect';
import PersonsSearchResultsDefaultSelect from '../../../../modules/core/forms/components/selects/PersonsSearchResultsDefaultSelect';
import { isProcessingUploadingAttachmentsSelector } from '../../../../modules/attachments/core/state/ui/sharedAttachmentsSelectors';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentProfileSelector,
} from '../../../../modules/core/current-user/state/ui';
import {
    fullPanelFormWidth,
    halfPanelFormWidth,
    departmentProfileFormFieldList,
    departmentProfileFormFieldNames,
} from '../../../configs/adminConfig';
import SidePanel from '../../core/SidePanel';
import { LocationSidePanel } from '../../../../modules/core/locations/components/LocationSidePanel';
import { validateReduxForm } from '../../../validation/validationRunner';
import { cancelDepartmentPanel } from '../../../actions/departmentProfileAdminActions';
import _LocationSummary from '../../summaries/LocationSummary';
import Button, { buttonTypes } from '../../core/Button';
import { iconTypes } from '../../../../modules/core/components/Icon';
import Checkbox from '../../../../modules/core/forms/components/checkboxes/Checkbox';
import testIds from '../../../../core/testIds';
import store from "../../../../core/store";
import { storeBoxErrorMessages } from "../../../actions/boxActions";
import DepartmentProfileAdminMap from './DepartmentProfileAdminMap';
import DepartmentProfileAdminLogoUpload from './DepartmentProfileAdminLogoUpload';

const deptFormStrings = stringsConfig.components.admin.departmentprofile.deptProfileFields;
const deptPanelStrings = stringsConfig.components.admin.departmentprofile.panel;
const helpTextStrings = stringsConfig.components.admin.departmentprofile.helpTexts;

const context = {
    name: boxEnum.DEPARTMENT_PROFILE_SIDE_PANEL,
};

const mapStyles = {
    marginBottom: 20,
    position: 'relative',
};

const noMapStyles = {
    position: 'relative',
};
const LocationSummary = styled(_LocationSummary)`
    margin-bottom: 10px;
`;
const DeptAddressWrapper = styled.div`
    margin-bottom: 10px;
`;

const LocationSummaryWrapper = styled.div`
    position: relative;
`;

const _TrashButton = React.forwardRef(({ className, onClick, testId }, ref) => {
    return (
        <Button
            className={classNames(buttonTypes.ICON_LINK, className)}
            iconLeft={iconTypes.TRASH_CAN}
            onClick={onClick}
            ref={ref}
            testId={testId}
        />
    );
});

const TrashButton = styled(_TrashButton)`
    position: absolute;
    top: 0;
    right: 0;
`;

// eslint-disable-next-line import/no-mutable-exports
let DepartmentProfileAdminForm = ({
    fields: {
        timeZone,
        displayName,
        city,
        departmentStatus,
        nibrsRegionalGroup,
        nibrsSubmissionEndpoint,
        dexRegion,
        complianceGroup,
        countryCode,
        centerLat,
        centerLng,
        radiusMeters,
        southwestLat,
        southwestLng,
        northeastLat,
        northeastLng,
        phoneNumber,
        website,
        stateAttrId,
        statePlaneZone,
        searchResyncsEnabled,
        ageOfAdult,
        personsSearchResultsDefault,
        externalCustomerId,
    },
    cancelDepartmentPanel,
    handleSubmit,
    hasEditDeptStatusAbility,
    hasEditSearchResyncEnabledAbility,
    hasInternalSupportAbility,
    hasDexFeatureFlag,
    currentUserDepartmentProfile,
    dateSortedAugmentedAttachmentViewModelsWhere,
    locationEntityLinksWhere,
    agencyProfileOptionsWhere,
    locations,
    removeDepartmentAddress,
    isProcessingUploadingAttachments,
}) => {
    const locationSidePanelSaveRef = useRef(null);
    const departmentId = get(currentUserDepartmentProfile, 'departmentId');
    const departmentProfileAttachments = dateSortedAugmentedAttachmentViewModelsWhere({
        attachmentType: EntityTypeEnum.FILE.name,
        entityId: departmentId,
        entityType: EntityTypeEnum.DEPARTMENT.name,
        linkType: LinkTypesEnum.DEPARTMENT_PROFILE_LOGO,
    });
    const locationEntityLinks = locationEntityLinksWhere({
        entityType: EntityTypeEnum.DEPARTMENT.name,
        entityId: departmentId,
        linkType: LinkTypesEnum.DEPARTMENT_PROFILE_ADDRESS,
    });
    const departmentAddress =
        locations[get(last(sortBy(locationEntityLinks, 'createdDateUtc')), 'locationId')];

    const departmentProfileLogo = first(departmentProfileAttachments);

    const agencyId = get(first(agencyProfileOptionsWhere({ isDefault: true })), 'value');

    const locationSidePanelProps = {
        entityType: EntityTypeEnum.DEPARTMENT.name,
        entityId: currentUserDepartmentProfile.departmentId,
        linkType: LinkTypesEnum.DEPARTMENT_PROFILE_ADDRESS,
        id: 0,
        agencyId,
    };

    return (
        <SidePanel
            title={deptPanelStrings.title}
            onSave={handleSubmit}
            onCancel={cancelDepartmentPanel}
            context={context}
            saveDisabled={isProcessingUploadingAttachments}
        >
            <AdminSummarySectionHeader>{deptPanelStrings.deptLogo}</AdminSummarySectionHeader>
            <Row>
                <DepartmentProfileAdminLogoUpload currentDeptLogo={departmentProfileLogo} />
            </Row>
            <AdminSummarySectionHeader>{deptPanelStrings.deptInfo}</AdminSummarySectionHeader>
            <Row>
                <Text
                    label={deptFormStrings.displayName}
                    {...displayName}
                    width={fullPanelFormWidth}
                />
            </Row>
            <Row>
                <Text label={deptFormStrings.city} {...city} width={fullPanelFormWidth} />
            </Row>
            <Row>
                <DepartmentStatusSelect
                    label={deptFormStrings.departmentStatus}
                    disabled={!hasEditDeptStatusAbility}
                    {...departmentStatus}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <TimeZoneSelect {...timeZone} width={halfPanelFormWidth} />
            </Row>
            <Row>
                <Select
                    options={regionalGroupOptions}
                    label={deptFormStrings.nibrsRegionalGroup}
                    {...nibrsRegionalGroup}
                    width={halfPanelFormWidth}
                    testId={testIds.DEPARTMENT_EDIT_UCR_NIBRS_REGIONAL_GROUP_SELECT}
                />
            </Row>
            <Row>
                <Text
                    fieldName={fields.DEPARTMENT_PROFILE_NIBRS_SUBMISSION_ENDPOINT}
                    label={deptFormStrings.nibrsSubmissionEndpoint}
                    helpText={helpTextStrings.nibrsSubmissionEndpoint}
                    {...nibrsSubmissionEndpoint}
                    width={200}
                />
            </Row>
            {hasDexFeatureFlag && (
                <Row>
                    <Select
                        options={dexRegionOptions}
                        label={deptFormStrings.dexRegion}
                        {...dexRegion}
                        width={halfPanelFormWidth}
                        testId={testIds.DEPARTMENT_EDIT_DEX_REGION_SELECT}
                    />
                </Row>
            )}
            <Row>
                <Select
                    fieldName={fields.DEPARTMENT_PROFILE_COMPLIANCE_GROUP}
                    options={complianceGroupOptions}
                    label={deptFormStrings.complianceGroup}
                    {...complianceGroup}
                    width={halfPanelFormWidth}
                    testId={testIds.DEPARTMENT_EDIT_COMPLIANCE_GROUP_SELECT}
                />
            </Row>
            <Row>
                <Text
                    fieldName={fields.DEPARTMENT_PROFILE_AGE_OF_ADULT}
                    label={deptFormStrings.ageOfAdult}
                    helpText={helpTextStrings.ageOfAdult}
                    {...ageOfAdult}
                    width={100}
                />
                <PersonsSearchResultsDefaultSelect
                    fieldName={fields.DEPARTMENT_PROFILE_PERSONS_SEARCH_RESULTS_DEFAULT}
                    label={deptFormStrings.personsSearchResultsDefault}
                    helpText={helpTextStrings.personsSearchResultsDefault}
                    {...personsSearchResultsDefault}
                    width={300}
                />
            </Row>
            <AdminSummarySectionHeader>{deptFormStrings.deptContactInfo}</AdminSummarySectionHeader>
            <Row>
                <Text
                    label={deptFormStrings.deptPhoneNumber}
                    {...phoneNumber}
                    characterLimit={15}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={deptFormStrings.website}
                    {...website}
                    characterLimit={128}
                    width={halfPanelFormWidth}
                />
            </Row>
            <DeptAddressWrapper>
                <Row>
                    {departmentAddress ? (
                        <LocationSummaryWrapper>
                            <LocationSummary location={departmentAddress} hideMap={true} />
                            <TrashButton
                                testId={testIds.DEPARTMENT_EDIT_REMOVE_LOCATION}
                                onClick={removeDepartmentAddress}
                                ref={locationSidePanelSaveRef}
                            />
                        </LocationSummaryWrapper>
                    ) : (
                        <LocationSidePanel
                            overlayId={overlayIdEnum.LOCATION_OVERLAY_DEPARTMENT_PROFILE_ADMIN_FORM}
                            renderButton={({ overlayBase: { open }, setCancelFocusRef }) => (
                                <Button
                                    testId={testIds.DEPARTMENT_EDIT_ADD_LOCATION}
                                    className={buttonTypes.ICON_LINK}
                                    iconLeft={iconTypes.ADD}
                                    onClick={open}
                                    ref={setCancelFocusRef}
                                >
                                    {deptFormStrings.location}
                                </Button>
                            )}
                            saveRef={locationSidePanelSaveRef}
                            skipStoreLocallyOnLocationSelection={true}
                            {...locationSidePanelProps}
                        />
                    )}
                </Row>
            </DeptAddressWrapper>
            <AdminSummarySectionHeader>{deptPanelStrings.locationBias}</AdminSummarySectionHeader>
            <Row>
                <CountrySelect
                    testId={testIds.DEPARTMENT_EDIT_COUNTRY_SELECTOR}
                    {...countryCode}
                    width={halfPanelFormWidth}
                />
                <AttributeSelect
                    label={deptFormStrings.stateAttrId}
                    {...stateAttrId}
                    attributeType={AttributeTypeEnum.STATE.name}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <StatePlaneZoneSelect {...statePlaneZone} width={halfPanelFormWidth} />
            </Row>
            <Row>
                <Text
                    testId={testIds.DEPARTMENT_EDIT_CENTER_LATITUDE_INPUT}
                    label={deptFormStrings.centerLat}
                    {...centerLat}
                    width={halfPanelFormWidth}
                />
                <Text
                    testId={testIds.DEPARTMENT_EDIT_CENTER_LONGITUDE_INPUT}
                    label={deptFormStrings.centerLng}
                    {...centerLng}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    testId={testIds.DEPARTMENT_EDIT_SW_LATITUDE_INPUT}
                    label={deptFormStrings.southwestLat}
                    {...southwestLat}
                    width={halfPanelFormWidth}
                />
                <Text
                    testId={testIds.DEPARTMENT_EDIT_SW_LONGITUDE_INPUT}
                    label={deptFormStrings.southwestLng}
                    {...southwestLng}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    testId={testIds.DEPARTMENT_EDIT_NE_LATITUDE_INPUT}
                    label={deptFormStrings.northeastLat}
                    {...northeastLat}
                    width={halfPanelFormWidth}
                />
                <Text
                    testId={testIds.DEPARTMENT_EDIT_NE_LONGITUDE_INPUT}
                    label={deptFormStrings.northeastLng}
                    {...northeastLng}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <DepartmentProfileAdminMap
                    locationBias={{
                        centerLat: centerLat.value,
                        centerLng: centerLng.value,
                        radiusMeters: radiusMeters.value,
                        southwestLat: southwestLat.value,
                        southwestLng: southwestLng.value,
                        northeastLat: northeastLat.value,
                        northeastLng: northeastLng.value,
                    }}
                    styles={{
                        map: mapStyles,
                        noMap: noMapStyles,
                    }}
                    zoomToPolygons
                />
            </Row>
            <Checkbox
                disabled={!hasEditSearchResyncEnabledAbility}
                label={deptFormStrings.searchResyncsEnabled}
                {...searchResyncsEnabled}
            />
            <Row />
            <Row>
                <Text
                    fieldName={fields.DEPARTMENT_PROFILE_EXTERNAL_CUSTOMER_ID}
                    label={deptFormStrings.externalCustomerId}
                    {...externalCustomerId}
                    width={200}
                    disabled={!hasInternalSupportAbility}
                />
            </Row>
        </SidePanel>
    );
};

DepartmentProfileAdminForm = reduxForm({
    form: 'departmentProfileAdmin',
    fields: departmentProfileFormFieldList,
    validate: (data) => {
        const context = {
            form: FormEnum.DEPARTMENT_PROFILE.name,
            name: boxEnum.DEPARTMENT_PROFILE_SIDE_PANEL,
        };

        const errors = {
            ...validateReduxForm(context, departmentProfileFormFieldNames)(data),
            ...(!!data.externalCustomerId && !/^[a-zA-Z0-9]*$/.test(data.externalCustomerId)
                ? {
                    externalCustomerId: stringsConfig.components.validation.strictAlphaNumeric,
                }
                : {}),
        };
        let panelErrorMessages = [];
        if (!isEmpty(errors)) {
            panelErrorMessages = values(errors);
        }
        defer(() => store.dispatch(storeBoxErrorMessages(context, panelErrorMessages)));

        return errors;
    },
})(DepartmentProfileAdminForm);

const mapStateToProps = (state) => {
    return {
        hasEditDeptStatusAbility: currentUserHasAbilitySelector(state)(
            abilitiesEnum.ADMIN.EDIT_DEPARTMENT_STATUS
        ),
        hasEditSearchResyncEnabledAbility: currentUserHasAbilitySelector(state)(
            abilitiesEnum.SUPPORT.DEPARTMENT_SETUP
        ),
        hasInternalSupportAbility: currentUserHasAbilitySelector(state)(
            abilitiesEnum.SUPPORT.GENERAL_INTERNAL_SUPPORT
        ),
        hasDexFeatureFlag: applicationSettingsSelector(state).CAD_DATA_EXCHANGE_ENABLED,
        currentUserDepartmentProfile: currentUserDepartmentProfileSelector(state),
        dateSortedAugmentedAttachmentViewModelsWhere: dateSortedAugmentedAttachmentViewModelsWhereSelector(
            state
        ),
        locationEntityLinksWhere: locationEntityLinksWhereSelector(state),
        agencyProfileOptionsWhere: agencyProfileOptionsWhereSelector(state),
        locations: locationsSelector(state),
        isProcessingUploadingAttachments: isProcessingUploadingAttachmentsSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    cancelDepartmentPanel: () => dispatch(cancelDepartmentPanel(context)),
    removeDepartmentAddress: (departmentId) =>
        dispatch(replaceLocationLinksForEntity(EntityTypeEnum.DEPARTMENT.name, departmentId, [])),
});

DepartmentProfileAdminForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        removeDepartmentAddress({ currentUserDepartmentProfile, removeDepartmentAddress }) {
            return () => removeDepartmentAddress(currentUserDepartmentProfile.departmentId);
        },
    })
)(DepartmentProfileAdminForm);

export default DepartmentProfileAdminForm;

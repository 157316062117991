import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';
import {
    buildFlatFormFieldViewModels,
    filterFormData,
} from '../../../../../legacy-redux/helpers/formHelpers';

const reportPrintingTemplatesAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    'id',
    {
        key: 'printingTemplateId',
        validators: {
            requiredError: requiredString,
        },
    },
    {
        key: 'title',
        validators: {
            requiredError: requiredString,
        },
    },
    'reportDefinitionId',
]);

export default createFormModule({
    formName: formClientEnum.REPORT_PRINTING_TEMPLATES_ADMIN,
    fieldViewModels: reportPrintingTemplatesAdminFormFieldViewModels,
    convertFromFormModel: filterFormData,
});

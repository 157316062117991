import _ from 'lodash';

export const maxTitleLength = 60;

export function isTitleEmpty(data) {
    const { isHidden, title } = data;
    return _.isEmpty(_.trim(title)) && (isHidden === false || isHidden === 'false');
}

export function isTitleTooLong(data) {
    const { title } = data;
    return title && _.trim(title).length > maxTitleLength;
}

export function isTitleValid(data) {
    return !isTitleEmpty(data) && !isTitleTooLong(data);
}

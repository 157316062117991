import { filter, first } from 'lodash';
import { CaseApprovalStatus } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'caseApprovalStatuses';
const caseApprovalStatusesModule = createNormalizedModule<CaseApprovalStatus>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

export const replaceCaseApprovalStatusesWhere =
    caseApprovalStatusesModule.actionCreators.replaceEntitiesWhere;

// SELECTORS
const caseApprovalStatusesSelector = caseApprovalStatusesModule.selectors.entitiesSelector;
export const caseApprovalStatusByCaseIdSelector = createSelector(
    caseApprovalStatusesSelector,
    (caseApprovalStatuses) =>
        // there should only be 1 case approval status
        (caseId: number) => first(filter(caseApprovalStatuses, { caseId }))
);

// REDUCER
export default caseApprovalStatusesModule.reducerConfig;

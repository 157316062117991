import { RoleTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import _, { get, parseInt, find } from 'lodash';
import { createSelector } from 'reselect';
import { getAdminListStatusFromStartEnd } from '~/client-common/core/dates/utils/dateHelpers';
import { caseDefinitionsSelector } from '~/client-common/core/domain/case-definitions/state/data';
import {
    rolesSelector,
    roleByIdSelector,
    rolesWhereSelector,
} from '~/client-common/core/domain/roles/state/data';
import { caseDefaultRoleLinksByCaseDefinitionIdSelector } from '~/client-common/core/domain/case-default-role-links/state/data';
import { caseDefaultTasksByOwnerIdSelector } from '~/client-common/core/domain/case-default-tasks/state/data';
import { defaultFoldersByOwnerIdSelector } from '~/client-common/core/domain/default-folders/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import caseDefinitionsAdminForm from '../forms/caseDefinitionsAdminForm';
import {
    LOAD_CASE_DEFINITIONS_START,
    LOAD_CASE_DEFINITIONS_SUCCESS,
    LOAD_CASE_DEFINITIONS_FAILURE,
    REMOVE_CASE_DEFINITION_START,
    REMOVE_CASE_DEFINITION_SUCCESS,
    REMOVE_CASE_DEFINITION_FAILURE,
    SAVE_CASE_DEFINITION_START,
    SAVE_CASE_DEFINITION_SUCCESS,
    SAVE_CASE_DEFINITION_FAILURE,
} from '../data';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

const { DEPARTMENT, CREATOR } = RoleTypeEnum;

const SELECT_CASE_DEFINITION_START = 'case-definitions/SELECT_CASE_DEFINITION_START';
const SELECT_CASE_DEFINITION_SUCCESS = 'case-definitions/SELECT_CASE_DEFINITION_SUCCESS';
const SELECT_CASE_DEFINITION_FAILURE = 'case-definitions/SELECT_CASE_DEFINITION_FAILURE';
const OPEN_NEW_CASE_DEFINITION_FORM = 'case-definitions/OPEN_NEW_CASE_DEFINITION_FORM';
const CASE_DEFINITION_FORM_VALIDATION_FAILURE =
    'case-definitions/CASE_DEFINITION_FORM_VALIDATION_FAILURE';

function selectCaseDefinitionStart() {
    return {
        type: SELECT_CASE_DEFINITION_START,
    };
}

function selectCaseDefinitionSuccess(id) {
    return {
        type: SELECT_CASE_DEFINITION_SUCCESS,
        payload: id,
    };
}

function selectCaseDefinitionFailure() {
    return {
        type: SELECT_CASE_DEFINITION_FAILURE,
    };
}

export function caseDefinitionFormValidationFailure(errorMessage) {
    return { type: CASE_DEFINITION_FORM_VALIDATION_FAILURE, payload: errorMessage };
}

export function selectCaseDefinition(id) {
    return (dispatch, getState) => {
        dispatch(selectCaseDefinitionStart());
        const state = getState();
        const caseDefinition = caseDefinitionsSelector(state)[id];
        const caseDefaultRoleLinks = caseDefaultRoleLinksByCaseDefinitionIdSelector(state)(
            parseInt(id)
        );
        const defaultTaskList = caseDefaultTasksByOwnerIdSelector(state)(parseInt(id));

        if (caseDefinition) {
            const currentDepartmentId = currentUserDepartmentIdSelector(state);
            const roleById = roleByIdSelector(state);
            const defaultFolders = defaultFoldersByOwnerIdSelector(state)(parseInt(id));

            dispatch(
                caseDefinitionsAdminForm.actionCreators.change(
                    {
                        caseDefinition,
                        caseDefaultRoleLinks,
                        defaultTaskList,
                        roleById,
                        currentDepartmentId,
                        defaultFolders,
                    },
                    state
                )
            );
            dispatch(caseDefinitionsAdminForm.actionCreators.validate());
            dispatch(selectCaseDefinitionSuccess(id));
        } else {
            dispatch(selectCaseDefinitionFailure('Failed to get case definition.'));
        }
    };
}

const createFakeCaseDefaultRoleLink = ({ roleId, operationType, departmentId }) => ({
    roleId,
    operationType,
    caseRoleAttrId: globalAttributes.caseRoleGlobal.otherPersonnel,
    roleDepartmentId: departmentId,
    departmentId,
});

export function setDefaultRolePermissionsForNewCaseDefinition() {
    return (dispatch, getState) => {
        const state = getState();
        const roleById = roleByIdSelector(state);
        const rolesWhere = rolesWhereSelector(state);
        const currentUserDepartmentId = currentUserDepartmentIdSelector(state);

        const { SEARCH, MANAGE } = OperationTypeEnum;
        const { DEPARTMENT, CREATOR } = RoleTypeEnum;

        const departmentRoleQuery = {
            roleType: DEPARTMENT.name,
            departmentId: currentUserDepartmentId,
        };
        const departmentRole = rolesWhere(departmentRoleQuery)[0];
        const departmentRoleId = get(departmentRole, 'id');

        const creatorRoleQuery = { roleType: CREATOR.name, departmentId: currentUserDepartmentId };
        const creatorRole = rolesWhere(creatorRoleQuery)[0];
        const creatorRoleId = get(creatorRole, 'id');

        const caseDefaultRoleLinks = [
            createFakeCaseDefaultRoleLink({
                roleId: creatorRoleId,
                operationType: MANAGE.name,
                departmentId: currentUserDepartmentId,
            }),
            createFakeCaseDefaultRoleLink({
                roleId: departmentRoleId,
                operationType: SEARCH.name,
                departmentId: currentUserDepartmentId,
            }),
        ];
        dispatch(
            caseDefinitionsAdminForm.actionCreators.change(
                {
                    caseDefaultRoleLinks,
                    roleById,
                    currentDepartmentId: currentUserDepartmentId,
                },
                state
            )
        );
    };
}

export function openNewCaseDefinitionForm() {
    return (dispatch, getState) => {
        const state = getState();
        const currentUserDepartmentId = currentUserDepartmentIdSelector(state);
        const roles = rolesSelector(state);
        const departmentRole = find(roles, {
            roleType: DEPARTMENT.name,
            departmentId: currentUserDepartmentId,
        }); // there should be exactly one DEPARTMENT role
        const departmentRoleId = get(departmentRole, 'id');
        const creatorRole = find(roles, {
            roleType: CREATOR.name,
            departmentId: currentUserDepartmentId,
        }); // there should be exactly one CREATOR role
        const creatorRoleId = get(creatorRole, 'id');
        const defaultPermissions = [
            { roleId: departmentRoleId, operationType: OperationTypeEnum.DELETE.name },
            { roleId: creatorRoleId, operationType: OperationTypeEnum.MANAGE.name },
        ];
        const roleById = roleByIdSelector(state);
        dispatch(caseDefinitionsAdminForm.actionCreators.change({ defaultPermissions, roleById }));
        dispatch({
            type: OPEN_NEW_CASE_DEFINITION_FORM,
        });
    };
}

const initialUiState = {
    selectedDefinitionId: null,
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    definitionLoadError: null,
};

export default function caseDefinitionsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_CASE_DEFINITIONS_START:
            return {
                ...state,
                loadingList: true,
                selectedDefinitionId: null,
                listError: null,
            };
        case LOAD_CASE_DEFINITIONS_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_CASE_DEFINITIONS_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case SAVE_CASE_DEFINITION_START:
            return {
                ...state,
                submittingForm: true,
                formError: null,
            };
        case SAVE_CASE_DEFINITION_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                formError: null,
            };
        case SAVE_CASE_DEFINITION_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_CASE_DEFINITION_SUCCESS:
            return {
                ...state,
                definitionLoadError: null,
                selectedDefinitionId: parseInt(action.payload),
                listError: null,
                formError: null,
            };
        case SELECT_CASE_DEFINITION_FAILURE:
            return {
                ...state,
                definitionLoadError: action.payload,
            };
        case OPEN_NEW_CASE_DEFINITION_FORM:
            return {
                ...state,
                formError: null,
                definitionLoadError: null,
                selectedDefinitionId: null,
            };
        case CASE_DEFINITION_FORM_VALIDATION_FAILURE:
            return {
                ...state,
                // error message from formValidators in the form module are not returned
                // in the submit/validate action. in this case keep any existing form error
                formError: action.payload || state.formError,
            };
        case REMOVE_CASE_DEFINITION_START:
            return {
                ...state,
                submittingForm: true,
            };
        case REMOVE_CASE_DEFINITION_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case REMOVE_CASE_DEFINITION_FAILURE:
            return {
                ...state,
                submittingForm: false,
                listError: action.payload,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.caseDefinitionsAdmin;

export const caseDefinitionViewModelByIdSelector = createSelector(
    caseDefinitionsSelector,
    (caseDefinitions) => (id) =>
        caseDefinitions[id]
            ? {
                  ...caseDefinitions[id],
                  status: getAdminListStatusFromStartEnd(
                      caseDefinitions[id].startDateUtc,
                      caseDefinitions[id].endDateUtc
                  ),
              }
            : undefined
);

// list of case definitions
export const definitionsListItemsSelector = createSelector(
    caseDefinitionsSelector,
    uiSelector,
    caseDefinitionViewModelByIdSelector,
    currentUserDepartmentIdSelector,
    (caseDefinitions, ui, caseDefinitionViewModelById, currentUserDepartmentId) =>
        _(caseDefinitions)
            .filter({ departmentId: currentUserDepartmentId })
            .map((definition) => {
                return {
                    path: `/admin/cases/definitions/${definition.id}`,
                    title: definition.name,
                    key: definition.id,
                    status: get(caseDefinitionViewModelById(definition.id), 'status'),
                    selected: definition.id === ui.selectedDefinitionId,
                };
            })
            .sortBy('title')
            .value()
);

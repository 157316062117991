import keyMirror from 'keymirror';

/**
 * Report Approval Levels that the user sees in the UI. This enum is client-only and
 * there is currently no back-end equivalent. Values from this enum are mapped to multiple
 * fields on the ReportDefinition model.
 *
 *  A report definition with terminalApprovalStatus set to `SUBMITTED` and
 * `requiresSecondaryApproval` set to `false` maps to `NONE`, indicating that
 *  this report type does not require any levels of approval.
 *
 *  A report definition with terminalApprovalStatus set to `APPROVED` and
 *  `requiresSecondaryApproval` set to `false` maps to `ONE`, indicating that
 *  this report type requires one level of approval.
 *
 *  A report definition with terminalApprovalStatus set to `APPROVED` and
 *  `requiresSecondaryApproval` set to `true` maps to `TWO`, indicating that
 *  this report type requires two levels of approval.
 *
 * @type {Object}
 */
export default keyMirror({
    NONE: null,
    ONE: null,
    TWO: null,
});

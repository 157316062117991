import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash';
import { withRouter } from 'react-router';
import { Flex } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    WARRANT_WARRANT_NUMBER,
} from '~/client-common/core/enums/universal/fields';

import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import ToggleButton from '../../../../legacy-redux/components/core/ToggleButton';

import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFAsyncText } from '../../../core/forms/components/AsyncText';

import SavedSearchModal from '../../../search/saved-search/components/SavedSearchModal';

import { warrantsDashboardSearch, submitWarrantsDashboardSearchForm } from '../state/ui';

const strings = componentStrings.warrants.dashboard;

const ToggleButtonContainer = styled(ToggleButton)`
    margin-top: 0;
    padding: 1px 15px;
    line-height: 26px;
`;

const SearchBox = styled(ReactReduxForm)`
    display: flex;
    flex: 1;
    min-width: 16rem;
`;

function WarrantsDashboardSubheader({
    isSearchFormOpen,
    onSearchFormToggleClick,
    onSearchChange,
    formatFieldByName,
}) {
    return (
        <Flex
            justifyContent="space-between"
            align="flex-start"
            padding="0 var(--arc-space-2)"
            flexWrap="wrap"
            gap="var(--arc-space-1)"
            flex="1"
        >
            <SearchBox {...warrantsDashboardSearch.form}>
                <RRFAsyncText
                    path="quickSearchQuery"
                    textInputOnly={true}
                    placeholder={strings.searchForm.placeholders.quickSearchQuery(
                        formatFieldByName(WARRANT_WARRANT_NUMBER),
                        formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
                    )}
                    asyncAction={onSearchChange}
                />
            </SearchBox>
            <Flex justifyContent="flex-end">
                <ToggleButtonContainer
                    className={buttonTypes.SECONDARY}
                    active={isSearchFormOpen}
                    inactiveIcon={<Icon fontSize="28px" type={iconTypes.FILTER} />}
                    onClick={onSearchFormToggleClick}
                >
                    Filters
                </ToggleButtonContainer>
                <SavedSearchModal searchModule={warrantsDashboardSearch} />
            </Flex>
        </Flex>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onSearchChange: () => dispatch(submitWarrantsDashboardSearchForm(false)),
});

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
});

/**
 * Search form on the warrants dashboard, which includes both the free text
 *   search input and the filters below it. The filter form fields only appear
 *   in an overlay when the FILTERS button is clicked.
 * @type {Object}
 */
export default flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(WarrantsDashboardSubheader);

import React from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash';

import { Text, cssVar } from 'arc';
import Row from '../../../modules/core/components/Row';
import Checkbox from '../../../modules/core/forms/components/checkboxes/Checkbox';
import testIds from '../../../core/testIds';

export const FormOptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 13px;
`;

const CheckboxWithoutMargin = styled(Checkbox)`
    margin-bottom: 0;
`;
export const LinkButton = styled(Text)`
    font-size: ${cssVar('arc.fontSizes.xs')};
    color: ${cssVar('arc.colors.brand.default')};
    cursor: pointer;
    position: relative;
    border: none;
    padding: 0;
    background-color: transparent;
    margin-left: 32px;

    &:hover {
        text-decoration: underline;
    }
`;

export const ExportOption = ({ field, title, noMargin, clearExportPreset, ...otherProps }) => {
    const Component = noMargin ? CheckboxWithoutMargin : Checkbox;
    return (
        <Row>
            <Component
                {...otherProps}
                {...field}
                onChange={(...args) => {
                    if (isFunction(clearExportPreset)) {
                        clearExportPreset();
                    }
                    if (isFunction(otherProps.onChange)) {
                        otherProps.onChange(...args);
                    }
                    return field.onChange(...args);
                }}
                label={title}
                testId={testIds.EXPORTS_EXPORT_OPTION_CHECKBOX}
            />
        </Row>
    );
};

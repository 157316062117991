import React from 'react';

import strings from '~/client-common/core/strings/componentStrings';
import AdvancedSearchErrorBar from './AdvancedSearchErrorBar';

const StaleQueryNotificationBar = () => (
    <AdvancedSearchErrorBar
        message={{ readOnlyText: strings.search.savedSearch.SavedSearch.notifications.staleQuery }}
    />
);

export default StaleQueryNotificationBar;

import { ElasticOrganization } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'elasticOrganizations';
const elasticOrganizationsModule = createNormalizedModule<ElasticOrganization>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const elasticOrganizationsSelector = elasticOrganizationsModule.selectors.entitiesSelector;

// REDUCER
export default elasticOrganizationsModule.reducerConfig;

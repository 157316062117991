import { ExternalReportStatus } from '@mark43/rms-api';
import { find } from 'lodash';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'externalReportStatuses';

const externalReportStatusesModule = createNormalizedModule<ExternalReportStatus>({
    type: NEXUS_STATE_PROP,
    key: 'reportingEventId',
});

// SELECTORS
const externalReportStatusesSelector = externalReportStatusesModule.selectors.entitiesSelector;

// SELECTORS
export const externalReportStatusForReportingEventIdSelector = createSelector(
    externalReportStatusesSelector,
    (externalReportStatuses) => (reportingEventId: number) => {
        return find(externalReportStatuses, {
            reportingEventId,
        });
    }
);

// REDUCER
export default externalReportStatusesModule.reducerConfig;

/**
 * Assigns a custom `parseTwoDigitYear` to the passed in object.
 *
 * @param object momentInstance The instance to patch
 *
 * @returns object The passed, augmented object
 */
export function augmentMomentParseTwoDigitYear(momentInstance) {
    momentInstance.parseTwoDigitYear = function (input) {
        // inlined and not cached so this does not break on new years eve
        const currentYear = parseInt(new Date().getFullYear().toString().substr(2), 10);
        const year = parseInt(input, 10);
        // we only want the year 2000 if its less than the current year or 1 more, everything else is year 1900
        return year + (year > currentYear + 1 ? 1900 : 2000);
    };

    return momentInstance;
}

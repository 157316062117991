import { map, isArray, reduce, isPlainObject, findIndex, isEmpty } from 'lodash';

const recursivelyConvertIdsToDisplayValueObjects = ({
    sourceKeyRegex,
    targetKeyRegex,
    getTargetKey = (key, targetKeyRegex) => key.replace(targetKeyRegex, 'Detail'),
    createTargetValue = (convertedValue, sourceValue) => {
        let ids;

        if (isArray(sourceValue)) {
            ids = sourceValue;
            // `*AttrDetail` case
            // we know that if our shape has an `ids` prop, it is an array of ids
        } else if (sourceValue && sourceValue.ids) {
            ids = sourceValue.ids;
        } else {
            ids = [sourceValue];
        }
        return {
            displayValues: isArray(convertedValue) ? convertedValue : [convertedValue],
            ids,
        };
    },
    preserveOldKeys = false,
}) => {
    /**
     * Recursively traverses an object, looking for keys matching `sourceKeyRegex`,
     * transforms their values using `createTargetValue` and stores the result
     * on a new key, computed by `getTargetKey`.
     * The original keys will be removed.
     *
     * @param  {*}        value                     The value to check for conversion
     * @param  {function} getDisplayValue           A function which returns the
     *  display value for a given value
     * @param  {boolean} [isSourceValue=false]      Whether the current value belongs
     *  to an attribute key
     * @return {*}
     */
    const recursivelyConvertIdsToDisplayValueObjectsInner = (
        value,
        getDisplayValue,
        isSourceValue = false
    ) => {
        if (isArray(value)) {
            return map(value, (val) =>
                recursivelyConvertIdsToDisplayValueObjectsInner(val, getDisplayValue, isSourceValue)
            );
        } else if (isPlainObject(value) && !isSourceValue) {
            const sourceRegexIsArray = isArray(sourceKeyRegex);
            const targetRegexIsArray = isArray(targetKeyRegex);
            return reduce(
                value,
                (acc, value, key) => {
                    let sourceKeyRegexIndex;
                    let isSourceKey;

                    if (sourceRegexIsArray) {
                        sourceKeyRegexIndex = findIndex(sourceKeyRegex, (regex) => regex.test(key));
                        isSourceKey = sourceKeyRegexIndex > -1;
                    } else {
                        isSourceKey = sourceKeyRegex.test(key);
                    }

                    if (isPlainObject(value) && isEmpty(value)) {
                        return acc;
                    }

                    const nestedValues = recursivelyConvertIdsToDisplayValueObjectsInner(
                        value,
                        getDisplayValue,
                        isSourceKey
                    );
                    if (isSourceKey) {
                        const targetKeyRegexToUse = targetRegexIsArray
                            ? targetKeyRegex[sourceKeyRegexIndex]
                            : targetKeyRegex;
                        const newKey = getTargetKey(key, targetKeyRegexToUse, sourceKeyRegexIndex);
                        acc[newKey] =
                            value !== undefined && value !== null
                                ? createTargetValue(nestedValues, value, sourceKeyRegexIndex)
                                : value;
                        if (preserveOldKeys && newKey !== key) {
                            acc[key] = value;
                        }
                    } else {
                        acc[key] = nestedValues;
                    }
                    return acc;
                },
                {}
            );
        }

        return isSourceValue && value !== null && value !== undefined
            ? getDisplayValue(value)
            : value;
    };

    return recursivelyConvertIdsToDisplayValueObjectsInner;
};

export default recursivelyConvertIdsToDisplayValueObjects;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedRouter, withRouter } from 'react-router';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import testIds from '../../../../core/testIds';
import { Button } from '../../../core/components/Button';

import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import warrantConfigurationTypeEnum from '../../constants/warrantConfigurationTypeEnum';

import {
    openCreateWarrantModal,
    createNewWarrantWithAutoGenWarrantNumber,
} from '../../warrant/state/ui';

const strings = componentStrings.warrants.dashboard;

function NewButton(props: { router: InjectedRouter }) {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const shouldWarrantAutoGenWarrantNumber =
        applicationSettings.RMS_WARRANT_ID_CONFIGURATION_TYPE ===
        warrantConfigurationTypeEnum.SINGLE;

    const dispatch = useDispatch();
    const createNewWarrant = () =>
        dispatch(createNewWarrantWithAutoGenWarrantNumber({ router: props.router }));
    const openNewWarrantModal = () => dispatch(openCreateWarrantModal());

    return (
        <OnlyWithAbility has={abilitiesEnum.WARRANTS.CREATE_FROM_DASHBOARD}>
            <Button
                variant="solid"
                style={{ flexShrink: 0 }}
                isTextTransformNone
                leadingVisual="Add"
                onClick={shouldWarrantAutoGenWarrantNumber ? createNewWarrant : openNewWarrantModal}
                testId={testIds.NEW_WARRANT_BUTTON}
            >
                {strings.newWarrant}
            </Button>
        </OnlyWithAbility>
    );
}

/**
 * The button used in Warrants to create a new warrant.
 */
export const NewWarrantButton = withRouter(NewButton);

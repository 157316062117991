import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { InlineReportCommentView, ReportComment } from '@mark43/rms-api';

export interface InlineReportComment extends ReportComment {
    highlightTarget: string;
}

interface InlineReportCommentFilters {
    status?: string;
    reportId?: number;
}

export const sortInlineCommentsForDisplay = (
    inlineReportCommentViews: InlineReportCommentView[],
    predicate?: InlineReportCommentFilters
): InlineReportComment[] => {
    const inlineComments = map(inlineReportCommentViews, (reportInlineCommentView) => {
        const { comment, highlightTarget } = reportInlineCommentView;
        return { ...comment, highlightTarget };
    });
    return orderBy(
        filter(inlineComments, predicate),
        ['createdDateUtc'],
        ['asc']
    ) as InlineReportComment[];
};

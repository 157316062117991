import { FieldDetailContext } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'fieldDetailContexts';

const fieldDetailContextsModule = createLinkModule<FieldDetailContext>({
    type: NEXUS_STATE_PROP,
    keys: ['id', 'fieldDetailId', 'context'],
});

// SELECTORS

export const fieldDetailContextsWhereSelector =
    fieldDetailContextsModule.selectors.linksWhereSelector;

// REDUCER
export default fieldDetailContextsModule.reducerConfig;

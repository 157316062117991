import { get, forEach } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ComplianceGroupEnum } from '@mark43/rms-api';

import { hydratedStopByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/stops';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import { currentUserDepartmentProfileSelector } from '../../../../core/current-user/state/ui';
import withCard from '../../utils/withCard';
import {
    formName,
    getStopForm,
    createStopForm,
    buildStopCardFormModel,
    prefillStopUserFields,
    STOP_SUBJECTS_PATH,
    STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_PATH,
    STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_PATH,
} from '../../state/forms/stopForm';
import { registerForm } from '../../state/ui';
import stopCard from '../../state/ui/stopCard';
import testIds from '../../../../../core/testIds';
import { registerCard } from '../../utils/cardsRegistry';
import Card from '../../../../../legacy-redux/components/core/Card';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import StopCardSummary from './StopCardSummary';
import StopCardForm from './StopCardForm';
import StopCardSubjectSection from './StopCardSubjectSection';
import StopCardLocationSection from './StopCardLocationSection';

class StopCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            buildStopCardFormModel,
            currentReportId: reportId,
        } = this.props;

        const form = createStopForm({
            initialState: buildStopCardFormModel({ reportId }),
            arbiter,
            formatFieldByName,
        });

        registerForm({ form });
        registerCard({
            cardModule: stopCard,
            onSave: this.onSave,
        });

        if (!form.initialState.stop.id) {
            this.props.initializeStop(form);
        }
    }

    componentDidMount() {
        // only prefill Stop Card if compliance group is CALIFORNIA
        if (
            this.props.currentUserDepartmentProfile.complianceGroup ===
            ComplianceGroupEnum.CALIFORNIA.name
        ) {
            this.props.prefillStopUserFields();
        }
    }

    componentWillUnmount() {
        formsRegistry.unregister(formName);
    }

    onEdit = () => {
        this.props.editCallback(() => this.props.onEdit());
    };

    onSaveProgress = () => {
        const form = getStopForm();
        this.clearHiddenNItemsData(form);
        return this.props.onSaveProgress(form, {});
    };

    onSave = () => {
        const form = getStopForm();
        this.clearHiddenNItemsData(form);
        return this.props.onSave(form);
    };

    clearHiddenNItemsData = (form) => {
        const { model } = form.getState();
        const { subjects = [] } = model;

        // clear the person/property search data for all falsy NItems
        forEach(subjects, (subject, index) => {
            const personSearchPath = `${STOP_SUBJECTS_PATH}[${index}].${STOP_SUBJECT_PERSON_SEARCH_N_ITEMS_PATH}`;
            const propertySearchPath = `${STOP_SUBJECTS_PATH}[${index}].${STOP_SUBJECT_PROPERTY_SEARCH_N_ITEMS_PATH}`;
            const { wasFrisked, personSearch, wasSearchPerformed, propertySearch } = subject;

            if (!wasFrisked && personSearch) {
                form.set(personSearchPath, []);
            }

            if (!wasSearchPerformed && propertySearch) {
                form.set(propertySearchPath, []);
            }
        });
    };

    render() {
        const {
            card = {},
            currentReportId: reportId,
            reportById,
            hydratedStopByReportId,
            currentUserDepartmentProfile,
            currentReportCardUITitleByType,
        } = this.props;
        const {
            anchor,
            summaryMode: cardSummaryMode,
            errorMessages,
            canEditReportCardStatus,
            saving,
        } = card;

        const cardTitle = currentReportCardUITitleByType(reportCardEnum.STOP.id);
        const hydratedStop = hydratedStopByReportId(reportId);
        const { stop, stopEntityAttributes } = hydratedStop;
        const stopId = get(stop, 'id');
        const reportingEventNumber = get(reportById(reportId), 'reportingEventNumber');
        const isCaliforniaComplianceGroup =
            currentUserDepartmentProfile.complianceGroup === ComplianceGroupEnum.CALIFORNIA.name;

        return (
            <Card
                className={anchor}
                anchor={anchor}
                title={cardTitle}
                renderContent={(summaryMode) => (
                    <>
                        {summaryMode ? (
                            <StopCardSummary reportId={reportId} />
                        ) : (
                            <StopCardForm stop={stop} />
                        )}

                        <VisibilityObserver
                            path="atlWrapper"
                            formName={formName}
                            render={(
                                { hidden } // this wrapper field is always visible in non-Atlanta tenants
                            ) =>
                                !hidden && (
                                    <>
                                        {!isCaliforniaComplianceGroup && (
                                            <>
                                                <StopCardSubjectSection
                                                    summaryMode={summaryMode}
                                                    reportId={reportId}
                                                    reportingEventNumber={reportingEventNumber}
                                                    stopEntityAttributes={stopEntityAttributes}
                                                />
                                                <StopCardLocationSection
                                                    summaryMode={summaryMode}
                                                    stopId={stopId}
                                                    stop={stop}
                                                />
                                            </>
                                        )}
                                    </>
                                )
                            }
                        />
                    </>
                )}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={cardSummaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                saving={saving}
                onSave={this.onSaveProgress}
                testId={testIds.STOP_CARD}
            />
        );
    }
}

class StopCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => <StopCard ref={this.setRef} {...this.props} arbiter={arbiter} />}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    reportById: reportByIdSelector,
    hydratedStopByReportId: hydratedStopByReportIdSelector,
    formatFieldByName: formatFieldByNameSelector,
    currentUserDepartmentProfile: currentUserDepartmentProfileSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = (dispatch) => ({
    buildStopCardFormModel: ({ reportId }) => dispatch(buildStopCardFormModel({ reportId })),
    prefillStopUserFields: () => dispatch(prefillStopUserFields()),
    initializeStop: (form) => {
        dispatch(stopCard.actionCreators.initializeStop(form));
    },
});

export default compose(
    withCard(stopCard),
    connect(mapStateToProps, mapDispatchToProps, null, {
        forwardRef: true,
    })
)(StopCardWrapper);

import { find } from 'lodash';

import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { NEXUS_STATE_PROP as REPORT_NEXUS_STATE_PROP } from '~/client-common/core/domain/reports/state/data';
import { NEXUS_STATE_PROP as REPORT_DEFINITIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-definitions/state/data';
import {
    NEXUS_STATE_PROP as COURT_ORDERS_NEXUS_STATE_PROP,
    courtOrdersByReportIdSelector,
} from '~/client-common/core/domain/court-orders/state/data';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/attachments/state/data';
import sealingResource from '../../../core/resources/sealingResource';
import reportUnsealingForm from '../forms/reportUnsealingForm';
import advancedSearchCourtOrders from '../../../../search/court-orders/state/ui';

const REPORT_UNSEALING_RESET_STATE = 'record-privacy/sealing/RESET_STATE';
const REPORT_UNSEALING_FORM_SUBMIT_FAILURE = 'record-privacy/sealing/FORM_SUBMIT_FAILURE';
const REPORT_UNSEALING_FORM_SUBMIT_BEGIN = 'record-privacy/sealing/FORM_SUBMIT_BEGIN';
const REPORT_UNSEALING_FORM_SUBMIT_SUCCESS = 'record-privacy/sealing/FORM_SUBMIT_SUCCESS';

const REPORT_UNSEALING_LOAD_REPORT_BEGIN = 'record-privacy/sealing/LOAD_REPORT_BEGIN';
const REPORT_UNSEALING_LOAD_REPORT_SUCCESS = 'record-privacy/sealing/LOAD_REPORT_SUCCESS';
const REPORT_UNSEALING_LOAD_REPORT_FAILURE = 'record-privacy/sealing/LOAD_REPORT_FAILURE';

const resetReportUnsealingState = () => ({ type: REPORT_UNSEALING_RESET_STATE });
const reportUnsealingFormSubmitBegin = () => ({ type: REPORT_UNSEALING_FORM_SUBMIT_BEGIN });
const reportUnsealingFormSubmitSuccess = () => ({
    type: REPORT_UNSEALING_FORM_SUBMIT_SUCCESS,
});
const reportUnsealingFormSubmitFailure = (error) => ({
    type: REPORT_UNSEALING_FORM_SUBMIT_FAILURE,
    payload: { error },
});

const loadReportBegin = (reportId) => ({
    type: REPORT_UNSEALING_LOAD_REPORT_BEGIN,
    payload: { reportId },
});
const loadReportSuccess = (reportId) => ({
    type: REPORT_UNSEALING_LOAD_REPORT_SUCCESS,
    payload: { reportId },
});
const loadReportFailure = (error) => ({
    type: REPORT_UNSEALING_LOAD_REPORT_FAILURE,
    payload: { error },
});

export function cleanupReportUnsealing() {
    return (dispatch, getState, { nexus }) =>
        dispatch(
            // clean up entities from loadReportForUnsealing
            // keep REPORT_DEFINITIONS_NEXUS_STATE_PROP for select dropdowns
            nexus.withRemoveMultiple(
                {
                    [COURT_ORDERS_NEXUS_STATE_PROP]: {},
                    [REPORT_NEXUS_STATE_PROP]: {},
                    [ATTACHMENTS_NEXUS_STATE_PROP]: {},
                },
                resetReportUnsealingState()
            )
        );
}

export const loadReportForUnsealing = (reportId) => (dispatch, getState, dependencies) => {
    dispatch(loadReportBegin(reportId));
    return sealingResource()
        .loadSealedReportView(reportId)
        .then((completeReportView) => {
            const action = dependencies.nexus.withEntityItems(
                {
                    [REPORT_NEXUS_STATE_PROP]: [completeReportView.report],
                    [REPORT_DEFINITIONS_NEXUS_STATE_PROP]: completeReportView.reportDefinitions,
                    [COURT_ORDERS_NEXUS_STATE_PROP]: completeReportView.courtOrders,
                    [ATTACHMENTS_NEXUS_STATE_PROP]: completeReportView.attachments,
                },
                loadReportSuccess(reportId)
            );
            return dispatch(action);
        })
        .catch((error) => dispatch(loadReportFailure(error.message)));
};

export const submitReportUnsealingForm = ({ router }) => (dispatch, getState, dependencies) => {
    dispatch(reportUnsealingFormSubmitBegin());
    return dispatch(
        reportUnsealingForm.actionCreators.submit((formModel) => {
            const courtOrdersForReport = courtOrdersByReportIdSelector(getState())(
                formModel.reportId
            );
            const courtOrderToUnseal = find(
                courtOrdersForReport,
                ({ id }) => id === formModel.courtOrderId
            );
            return sealingResource()
                .unsealReport({
                    isUnsealNarrative: !!formModel.isUnsealNarrative,
                    courtOrder: courtOrderToUnseal,
                })
                .then((resp) => [resp, formModel.courtOrderId]);
        })
    )
        .then(([resp, courtOrderId]) => {
            dispatch(advancedSearchCourtOrders.actionCreators.removeResult(courtOrderId));
            dispatch(
                dependencies.nexus.withRemove(
                    COURT_ORDERS_NEXUS_STATE_PROP,
                    {
                        id: courtOrderId,
                    },
                    reportUnsealingFormSubmitSuccess()
                )
            );
            router.push(`/reports/${resp.report.id}`);
            return resp;
        })
        .catch((err) => {
            // This is used for validation errors and actual submission server errors.
            // Validation errors won't have a message so this will only reset `isSubmitting`
            // in that case, without setting an actual error message.
            dispatch(reportUnsealingFormSubmitFailure(err.message));
        });
};

export const initializeReportUnsealingForm = ({ reportId, courtOrderId, isUnsealNarrative }) => (
    dispatch
) => {
    dispatch(
        reportUnsealingForm.actionCreators.change({
            reportId,
            courtOrderId,
            isUnsealNarrative,
        })
    );
};

const initialState = {
    submissionError: undefined,
    isSubmitting: false,
    loadingState: {
        error: undefined,
        loaded: false,
        loading: false,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_UNSEALING_FORM_SUBMIT_FAILURE:
            return { ...state, submissionError: action.payload.error, isSubmitting: false };
        case REPORT_UNSEALING_FORM_SUBMIT_BEGIN:
            return { ...state, submissionError: undefined, isSubmitting: true };
        case REPORT_UNSEALING_LOAD_REPORT_BEGIN:
            return { ...state, loadingState: { ...state.loadingState, loading: true } };
        case REPORT_UNSEALING_LOAD_REPORT_SUCCESS:
            return {
                ...state,
                loadingState: { ...state.loadingState, loading: false, loaded: true },
            };
        case REPORT_UNSEALING_LOAD_REPORT_FAILURE:
            return {
                ...state,
                loadingState: {
                    ...state.loadingState,
                    loading: false,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
};

export const reportUnsealingSubmissionErrorSelector = (state) =>
    state.ui.reportUnsealing.submissionError;
export const reportUnsealingIsSubmittingSelector = (state) => state.ui.reportUnsealing.isSubmitting;
export const reportUnsealingLoadingStateSelector = (state) => state.ui.reportUnsealing.loadingState;

export default makeResettable(REPORT_UNSEALING_RESET_STATE, reducer, initialState);

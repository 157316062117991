import {
    OperationTypeEnum,
    ApprovalStatusEnum,
    EntityTypeEnum,
    RefContextEnum,
    AttributeTypeEnum,
} from '@mark43/rms-api';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _, {
    compact,
    includes,
    find,
    filter,
    first,
    get,
    map,
    memoize,
    reject,
    sortBy,
    values,
    uniq,
    omit,
    pick,
    flatten,
    flatMap,
    chain,
    uniqBy,
} from 'lodash';
import {
    reportingEventsWhereSelector,
    NEXUS_STATE_PROP as REPORTING_EVENTS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/reporting-events/state/data';
import {
    folderContentViewByFolderIdSelector,
    folderContentViewsSelector,
} from '~/client-common/core/domain/folder-content-views/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_CASE } from '~/client-common/core/enums/universal/fields';
import { canWrite } from '~/client-common/core/domain/entity-permissions/state/ui';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import {
    caseStatusViewModelByCaseIdSelector,
    statusIsCanceledSelector,
} from '~/client-common/core/domain/case-statuses/state/ui';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    elasticReportsSelector,
    NEXUS_STATE_PROP as ELASTIC_REPORTS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/elastic-reports/state/data';
import { NEXUS_STATE_PROP as ELASTIC_WARRANTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-warrants/state/data';
import {
    casesSelector,
    NEXUS_STATE_PROP as CASES_NEXUS_STATE_PROP,
    removeCase,
    caseByIdSelector,
} from '~/client-common/core/domain/cases/state/data';
import { caseViewModelsSelector } from '~/client-common/core/domain/cases/state/ui';
import {
    caseNotesWhereSelector,
    NEXUS_STATE_PROP as CASE_NOTES_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/case-notes/state/data';
import {
    activeCaseDefinitionViewModelsSelector,
    expiredCaseDefinitionViewModelsSelector,
    mapCaseDefinitionsToOptions,
} from '~/client-common/core/domain/case-definitions/state/ui';
import { reportIdsForCaseIdSelector } from '~/client-common/core/domain/case-links/state/data';
import { caseRoleLinkViewModelsForCaseIdSelector } from '~/client-common/core/domain/case-role-links/state/ui';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/attachments/state/data';
import {
    caseAttachmentsByCaseIdAndUserIdSelector,
    convertAttachmentsToCaseAttachmentsRowViewModelsSelector,
} from '~/client-common/core/domain/case-attachments/state/ui';
import {
    NEXUS_STATE_PROP as REPORT_CASE_STATUSES_NEXUS_STATE_PROP,
    reportCaseStatusesForValidReportsSelector,
    reportHasReportCaseStatusByReportIdSelector,
} from '~/client-common/core/domain/report-case-statuses/state/data';
import {
    caseStatusesByCaseIdSelector,
    NEXUS_STATE_PROP as CASE_STATUSES_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/case-statuses/state/data';
import { locationViewModelsLinkedToEntitySelector } from '~/client-common/core/domain/locations/state/ui';
import { reportReportLinksWhereSelector } from '~/client-common/core/domain/report-report-links/state/data';
import { reportExternalLinksWhereSelector } from '~/client-common/core/domain/report-external-links/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { addDuration, nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import {
    NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP,
    reportShortTitlesSelector,
} from '~/client-common/core/domain/report-short-titles/state/data';
import {
    NEXUS_STATE_PROP as CASE_APPROVAL_STATUSES_NEXUS_STATE_PROP,
    caseApprovalStatusByCaseIdSelector,
} from '~/client-common/core/domain/case-approval-statuses/state/data';
import {
    NEXUS_STATE_PROP as CASE_ATTRIBUTES_NEXUS_STATE_PROP,
    caseAttributesByCaseIdSelector,
} from '~/client-common/core/domain/case-attributes/state/data';
import { NEXUS_STATE_PROP as CASE_REVIEWS_NEXUS_STATE_PROP } from '~/client-common/core/domain/case-reviews/state/data';
import { NEXUS_STATE_PROP as CASE_ROLE_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/case-role-links/state/data';
import { NEXUS_STATE_PROP as ELASTIC_INVOLVED_LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-involved-locations/state/data';
import {
    NEXUS_STATE_PROP as EXTERNAL_REPORT_STATUSES_NEXUS_STATE_PROP,
    externalReportStatusForReportingEventIdSelector,
} from '~/client-common/core/domain/external-report-statuses/state/data';
import {
    NEXUS_STATE_PROP as OFFENSE_CASE_STATUSES_NEXUS_STATE_PROP,
    offenseCaseStatusByOffenseIdSelector,
} from '~/client-common/core/domain/offense-case-statuses/state/data';
import {
    NEXUS_STATE_PROP as OFFENSES_NEXUS_STATE_PROP,
    offensesByReportIdSelector,
} from '~/client-common/core/domain/offenses/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-codes/state/data';
import { NEXUS_STATE_PROP as ELASTIC_ORGANIZATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-organizations/state/data';
import { NEXUS_STATE_PROP as ELASTIC_PERSONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-persons/state/data';
import { NEXUS_STATE_PROP as ELASTIC_PROPERTY_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-property/state/data';
import { NEXUS_STATE_PROP as ELASTIC_VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-vehicles/state/data';
import { NEXUS_STATE_PROP as ITEM_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-profiles/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/location-entity-links/state/data';
import { NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/locations/state/data';
import { NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-report-links/state/data';
import { NEXUS_STATE_PROP as ORGANIZATION_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/organization-profiles/state/data';
import { NEXUS_STATE_PROP as PERSON_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-profiles/state/data';
import { NEXUS_STATE_PROP as CASE_ITEM_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-case-links/state/data';
import { NEXUS_STATE_PROP as CASE_NAME_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-case-links/state/data';
import {
    NEXUS_STATE_PROP as REPORTS_NEXUS_STATE_PROP,
    reportsSelector,
} from '~/client-common/core/domain/reports/state/data';
import { NEXUS_STATE_PROP as TASKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/tasks/state/data';
import { NEXUS_STATE_PROP as VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicles/state/data';
import { NEXUS_STATE_PROP as SECURITY_CLASSIFICATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/security-classifications/state/data';
import { foldersByOwnerIdSelector } from '~/client-common/core/domain/folders/state/data';
import { storeConfigForRelatedRecordsAndEntities } from '~/client-common/helpers/multiAgencyHelpers';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { translateToFolderRowView } from '~/client-common/core/domain/folders/utils/folderHelpers';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { storeReports } from '../../../../../legacy-redux/actions/reportsActions';
import { filterRecordWithoutRenIds } from '../../../../reports/core/utils/isRecordWithoutRen';
import { setCurrentCaseLinkedProfiles } from '../../../linked-profiles/state/ui/action/creators';

import { navigationPermissionsSelector } from '../../../../../legacy-redux/selectors/navigationSelectors';
import {
    currentUserHasAbilitySelector,
    currentUserIdSelector,
    currentUserDepartmentIdSelector,
} from '../../../../core/current-user/state/ui';
import { abilitiesEnum } from '../../../../core/abilities';
import { entityPermissionsDataSelector } from '../../../../../legacy-redux/selectors/entityPermissionsSelectors';
import { getInfoForReportingEventNumber } from '../../../../../legacy-redux/actions/renSearchModalActions';
import createReportsResultsSelector from '../../../../search/core/utils/createReportsResultsSelector';
import createCaseForm from '../form/createCaseForm';
import {
    openBox,
    saveBoxStart,
    saveBoxSuccess,
    closeBox,
    loadBoxStart,
    saveBoxHalt,
    openErrorModal,
    openLoadingModal,
    closeLoadingModal,
    saveBoxFailure,
    storeBoxErrorMessages,
    storePayloadToBox,
} from '../../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../../core/box/state/ui';
import caseDetailsForm from '../form/caseDetailsForm';
import editCaseRenForm from '../form/editCaseRenForm';
import caseResource from '../../resources/caseResource';
import {
    initializeEntityPermissions,
    sortAndAugmentPermissions,
} from '../../../../../legacy-redux/actions/entityPermissionsActions';
import { getCaseAttributesByType } from '../../utils/getCaseAttributesByType';

import { convertToExternalReportStatusFormModel } from '../../../../core/external-report-statuses/state/form/externalReportStatusForm';
import { convertToOffenseCaseStatusesFormModel } from '../../../../core/offense-case-statuses/state/form/offenseCaseStatusesForm';
import { sortedOffenseCaseStatusesForOffenseCaseStatusesAndReportIdsSelector } from '../../../../core/offense-case-statuses/state/data/offenseCaseStatuses';

const strings = componentStrings.cases.core;

// used by currentUserCasePermissionsSelector
// we can move this into a shared helper if it proves useful broadly
// see https://github.com/reactjs/reselect/issues/7#issuecomment-122109019
function makeLazy(selector) {
    return (state) => () => selector(state);
}

const createCaseContext = {
    name: boxEnum.CREATE_CASE_MODAL,
};
const deleteCaseContext = {
    name: boxEnum.DELETE_CASE_MODAL,
};
const editCaseRenContext = {
    name: boxEnum.EDIT_CASE_REN_MODAL,
};

const GET_COMPLETE_CASE_VIEW_START = 'cases/GET_COMPLETE_CASE_VIEW_START';
const GET_COMPLETE_CASE_VIEW_FAILURE = 'cases/GET_COMPLETE_CASE_VIEW_FAILURE';
const GET_CASE_DEFAULTS_SUCCESS = 'cases/GET_CASE_DEFAULTS_SUCCESS';
const GET_COMPLETE_CASE_VIEW_SUCCESS = 'cases/GET_COMPLETE_CASE_VIEW_SUCCESS';
const GET_CASE_DETAILS_SUCCESS = 'cases/GET_CASE_DETAILS_SUCCESS';

const SAVE_ORPHANED_CASE_CONTENTS = 'cases/SAVE_ORPHANED_CASE_CONTENTS';

export function getCompleteCaseViewSuccess(theCase) {
    return {
        type: GET_COMPLETE_CASE_VIEW_SUCCESS,
        payload: theCase,
    };
}

function getCaseDetailsSuccess(caseDetails) {
    return {
        type: GET_CASE_DETAILS_SUCCESS,
        payload: caseDetails,
    };
}

function getCompleteCaseViewStart() {
    return {
        type: GET_COMPLETE_CASE_VIEW_START,
    };
}

function getCompleteCaseViewFailure(errMsg) {
    return {
        type: GET_COMPLETE_CASE_VIEW_FAILURE,
        payload: errMsg,
    };
}

// Destructuring the payload in hopes to make converting this to typescript
// easier.
export function saveOrphanedCaseContents({ caseNotes, attachments }) {
    return {
        type: SAVE_ORPHANED_CASE_CONTENTS,
        payload: {
            caseNotes,
            attachments,
        },
    };
}

/**
 * @param {Object} caseDefaultsResponse full response from case defaults API request
 * @param {string} [caseRen] Pass in a REN only if the new case should be linked to it.
 */
export function caseDefaultsResponseToCaseDetails(caseDefaultsResponse, caseRen) {
    if (!caseDefaultsResponse) {
        return undefined;
    }

    const {
        caseDefinitionCompleteView: {
            caseDefinition: {
                defaultDueDateInterval,
                defaultCaseStatusAttrId,
                defaultPersonnelUnitAttrId,
                id: caseDefinitionId,
            },
            caseDefaultRoleLinks,
        },
        reportShortTitles,
        reportCaseStatuses,
        externalReportStatuses,
        offenseCaseStatuses,
        reportingEvents,
    } = caseDefaultsResponse;

    const firstReportRen = _(reportShortTitles)
        .sortBy('createdDateUtc')
        .map('reportingEventNumber')
        .head();

    const initialCase = {
        caseDefinitionId,
        dueDateUtc: defaultDueDateInterval ? addDuration(nowUtc(), defaultDueDateInterval) : null,
        assignedPersonnelUnitAttrId: defaultPersonnelUnitAttrId,
        localId: caseRen || firstReportRen,
        reportingEventNumber: caseRen,
    };

    const initialCaseStatus = {
        statusAttrId: defaultCaseStatusAttrId,
        closedByDivisionAttrId: null,
        closedByUnitAttrId: null,
        statusDateUtc: null,
    };

    return {
        theCase: initialCase,
        caseReportIds: map(reportShortTitles, 'reportId'),
        caseRoleLinks: reject(caseDefaultRoleLinks, {
            caseRoleAttrId: globalAttributes.caseRoleGlobal.otherPersonnel,
        }),
        caseStatus: initialCaseStatus,
        reportCaseStatuses,
        externalReportStatuses,
        offenseCaseStatuses,
        reportingEvents,
        reportShortTitles,
    };
}

function getOffenseCodesFromCaseDetails(caseDetails) {
    return flatMap(caseDetails.offenses, (offense) => get(offense, 'offenseCode'));
}
/**
 * Open the "Edit/Assign Case" modal.
 * The caller of this modal is responsible for getting
 * or composing the right data. See caseDetailsForm.js > convertToFormModel
 * @param  {Object} caseView Full case object or at least { case, caseRoleLinks, caseStatus, entityPermissions }
 */
export function openCaseDetailsModalForCase(caseView) {
    return (dispatch, getState) => {
        const currentState = getState();
        // if a caseView isn't passed in, get the details of the current case from state
        caseView = caseView || currentCaseDetailsSelector(currentState);
        caseView.caseReportIds =
            caseView.caseReportIds || currentCaseReportIdsSelector(currentState);
        const caseNumber = get(caseView, 'theCase.localId');

        // Make sure all reports chosen show in the case details modal report case status section,
        // but only if the report definition allows it
        const emptyCaseStatuses = _(caseView.caseReportIds)
            .filter(
                (reportId) =>
                    !find(caseView.reportCaseStatuses, (status) => status.reportId === reportId)
            )
            .mapKeys()
            .mapValues((reportId) => ({
                reportId,
            }))
            .value();

        dispatch(
            caseDetailsForm.actionCreators.change({
                ...caseView,
                reportCaseStatuses: filter(
                    values({
                        ...emptyCaseStatuses,
                        ...caseView.reportCaseStatuses,
                    }),
                    (reportCaseStatus) => {
                        return reportHasReportCaseStatusByReportIdSelector(currentState)(
                            reportCaseStatus.reportId
                        );
                    }
                ),
            })
        );
        dispatch(
            openBox(
                {
                    name: boxEnum.CASE_DETAILS_MODAL,
                },
                { caseNumber }
            )
        );
    };
}

function getAttrIdsByAttrType(attributes, attributeType) {
    return getCaseAttributesByType(attributes, attributeType).map(({ attributeId }) => attributeId);
}

function initializeCreateManageCaseModalFormForCase(caseView) {
    return (dispatch, getState, { formsRegistry }) => {
        const currentState = getState();

        // if a caseView isn't passed in, get the details of the current case from state
        caseView = caseView || currentCaseDetailsSelector(currentState);
        caseView.caseReportIds =
            caseView.caseReportIds || currentCaseReportIdsSelector(currentState);

        const reportingEventId = get(caseView, 'reportingEvents.0.id');

        const externalReportStatus = first(get(caseView, 'externalReportStatuses')) || {
            reportingEventId,
        };

        const offenseCaseStatuses = sortedOffenseCaseStatusesForOffenseCaseStatusesAndReportIdsSelector(
            currentState
        )({
            offenseCaseStatuses: caseView.offenseCaseStatuses,
            reportShortTitles: caseView.reportShortTitles,
            reports: caseView.reports,
            reportIds: caseView.caseReportIds,
        });

        // Make sure all reports chosen show in the case details modal report case status section,
        // but only if the report definition allows it
        const emptyCaseStatuses = _(caseView.caseReportIds)
            .filter(
                (reportId) =>
                    !find(caseView.reportCaseStatuses, (status) => status.reportId === reportId)
            )
            .mapKeys()
            .mapValues((reportId) => ({
                reportId,
            }))
            .value();

        const statusIsCanceledForId = statusIsCanceledSelector(currentState);
        const adjustedReportCaseStatuses = map(caseView.reportCaseStatuses, (reportStatus) => {
            const isCanceledAttr = statusIsCanceledForId(reportStatus.caseStatusAttrId);
            return {
                isCanceled: isCanceledAttr,
                ...reportStatus,
            };
        });
        const filteredReportCaseStatuses = filter(
            values({
                ...emptyCaseStatuses,
                ...adjustedReportCaseStatuses,
            }),
            (reportCaseStatus) => {
                return reportHasReportCaseStatusByReportIdSelector(currentState)(
                    reportCaseStatus.reportId
                );
            }
        );

        const caseBlob = {
            ...caseView,
            reportCaseStatuses: filteredReportCaseStatuses,
        };

        const { caseRoleLinks, caseStatus, caseAttributes = [], reportCaseStatuses } = caseBlob;

        const assigneeCaseRoleLink = find(caseRoleLinks, (caseRoleLink) => {
            return caseRoleLink.caseRoleAttrId === globalAttributes.caseRoleGlobal.assignee;
        });
        const assistingInvestigatorsCaseRoleLinks = filter(caseRoleLinks, {
            caseRoleAttrId: globalAttributes.caseRoleGlobal.assistingInvestigator,
        });

        const caseInformation = {
            ...caseBlob.theCase,
            assignee: get(assigneeCaseRoleLink, 'roleId'),
            assistingInvestigators: uniq(map(assistingInvestigatorsCaseRoleLinks, 'roleId')),
            targetProfileCategoryAttrIds: getAttrIdsByAttrType(
                caseAttributes,
                AttributeTypeEnum.TARGET_PROFILE_CATEGORY.name
            ),
            targetProfilePriorityAttrId: first(
                getAttrIdsByAttrType(caseAttributes, AttributeTypeEnum.TARGET_PROFILE_PRIORITY.name)
            ),
            targetProfileAreaAttrIds: getAttrIdsByAttrType(caseAttributes, [
                AttributeTypeEnum.SUBDIVISION_DEPTH_1.name,
                AttributeTypeEnum.SUBDIVISION_DEPTH_2.name,
                AttributeTypeEnum.SUBDIVISION_DEPTH_3.name,
                AttributeTypeEnum.SUBDIVISION_DEPTH_4.name,
                AttributeTypeEnum.SUBDIVISION_DEPTH_5.name,
            ]),
        };

        const supervisors = uniq(
            map(
                filter(caseRoleLinks, (caseRoleLink) => {
                    return (
                        caseRoleLink.caseRoleAttrId === globalAttributes.caseRoleGlobal.supervisor
                    );
                }),
                'roleId'
            )
        );

        const initialState = {
            ...caseInformation,
            supervisors,
            caseStatus,
            reportCaseStatuses,
            externalReportStatus: convertToExternalReportStatusFormModel(externalReportStatus),
            offenseCaseStatuses: dispatch(
                convertToOffenseCaseStatusesFormModel(offenseCaseStatuses)
            ),
        };

        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_CREATE_MANAGE_CASE.name,
            undefined,
            (form) => {
                form.resetModel();
                form.set('', initialState);
            }
        );
        return Promise.resolve(initialState);
    };
}

export function openCreateManageCaseModalForCase(caseView, removalRequest) {
    return (dispatch, getState, { overlayStore }) => {
        dispatch(initializeCreateManageCaseModalFormForCase(caseView)).then((initialState) => {
            overlayStore.open(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, {
                localId: initialState.localId,
                removalRequest,
            });
        });
    };
}

export function openCreateCaseFromMultiReportsModal(overlayId, reports) {
    return (dispatch, getState, { overlayStore }) => {
        overlayStore.open(overlayId, {
            reports,
        });
    };
}

const showRenSelector = createModalSelector(createCaseContext, 'showRen');
const enteredRenSelector = createModalSelector(createCaseContext, 'enteredRen');
const existingReportIdsSelector = createModalSelector(createCaseContext, 'existingReportIds');
const removalRequestSelector = createModalSelector(createCaseContext, 'removalRequest');

export function submitCreateCaseForm(caseDefinitionId, clearUnassignedReportsSelection) {
    return (dispatch, getState) => {
        const state = getState();
        const showRen = showRenSelector(state);
        const enteredRen = enteredRenSelector(state);
        const existingReportIds = existingReportIdsSelector(state);
        const removalRequest = removalRequestSelector(state);

        if (showRen) {
            return dispatch(
                createCaseForm.actionCreators.submit((formData) => {
                    const { caseRen } = formData;

                    const caseDefaultsRequest = {
                        reportingEventNumber: caseRen,
                        reportIds: !enteredRen
                            ? filterRecordWithoutRenIds(
                                  existingReportIds,
                                  reportShortTitlesSelector(state)
                              )
                            : undefined,
                        caseDefinitionId,
                    };
                    dispatch(saveBoxStart(createCaseContext));
                    return dispatch(getCaseDefaults(caseDefaultsRequest))
                        .then((caseDefaultsResponse) => {
                            dispatch(closeBox(createCaseContext));
                            dispatch(
                                openCreateManageCaseModalForCase(
                                    caseDefaultsResponseToCaseDetails(
                                        caseDefaultsResponse,
                                        caseRen
                                    ),
                                    removalRequest
                                )
                            );
                        })
                        .catch((err) => dispatch(saveBoxFailure(createCaseContext, err.message)));
                })
            ).then(() => {
                dispatch(clearUnassignedReportsSelection());
            });
        }

        return new Promise((res, rej) => {
            const caseDefaultsRequest = {
                reportingEventNumber: enteredRen,
                reportIds: !enteredRen
                    ? filterRecordWithoutRenIds(existingReportIds, reportShortTitlesSelector(state))
                    : existingReportIds,
                caseDefinitionId,
            };

            dispatch(saveBoxStart(createCaseContext));
            return dispatch(getCaseDefaults(caseDefaultsRequest))
                .then((caseDefaultsResponse) => {
                    dispatch(closeBox(createCaseContext));
                    dispatch(
                        openCreateManageCaseModalForCase(
                            caseDefaultsResponseToCaseDetails(caseDefaultsResponse, enteredRen),
                            removalRequest
                        )
                    );
                    res();
                })
                .catch((err) => {
                    dispatch(saveBoxFailure(createCaseContext, err.message));
                    rej(err);
                });
        }).then(() => {
            dispatch(clearUnassignedReportsSelection());
        });
    };
}

/**
 * Open the "Edit/Assign Case" modal from cases dashboards
 * @param  {Number} caseId Case id of case to open
 */
export function openCaseDetailsModalFromDashboard(caseId) {
    return (dispatch, getState, { nexus: { withEntityItems } }) => {
        const state = getState();
        const boxContext = {
            name: boxEnum.CASE_DETAILS_MODAL,
        };

        const isCurrentCase = currentCaseIdSelector(state) === caseId;

        //  -- if case already exists in state, open case details modal and
        // display that data first but add a loading gif to indicate
        // that we're fetching new data in the background
        //  -- if case doesn't exist in state yet, open loading modal
        if (isCurrentCase) {
            dispatch(openCaseDetailsModalForCase());
            dispatch(loadBoxStart(boxContext));
        } else {
            dispatch(openLoadingModal());
        }

        // fetch new data
        caseResource
            .getCaseDetails(caseId)
            .then((caseDetails) => {
                dispatch(getCaseDetailsSuccess(caseDetails));
                dispatch(
                    withEntityItems(
                        {
                            [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: caseDetails.reportShortTitles,
                            [CASE_APPROVAL_STATUSES_NEXUS_STATE_PROP]: [
                                caseDetails.caseApprovalStatus,
                            ],
                            [CASES_NEXUS_STATE_PROP]: [caseDetails.theCase],
                            [EXTERNAL_REPORT_STATUSES_NEXUS_STATE_PROP]:
                                caseDetails.externalReportStatuses,
                            [OFFENSE_CASE_STATUSES_NEXUS_STATE_PROP]:
                                caseDetails.offenseCaseStatuses,
                            [REPORTING_EVENTS_NEXUS_STATE_PROP]: caseDetails.reportingEvents,
                            [OFFENSES_NEXUS_STATE_PROP]: caseDetails.offenses,
                            [OFFENSE_CODES_NEXUS_STATE_PROP]: getOffenseCodesFromCaseDetails(
                                caseDetails
                            ),
                        },
                        {
                            type: 'GET_CASE_DETAILS_SUCCESS',
                        }
                    )
                );
                dispatch(
                    initializeEntityPermissions(
                        EntityTypeEnum.CASE.name,
                        get(caseDetails, 'theCase.id'),
                        get(caseDetails, 'theCase.permissionSet')
                    )
                );
                dispatch(sortAndAugmentPermissions(caseDetails.entityPermissions));
                // new call to state because data may have changed
                dispatch(openCaseDetailsModalForCase(caseDetails));
                dispatch(closeLoadingModal());
                dispatch(saveBoxHalt(boxContext));
            })
            .catch((err) => {
                dispatch(closeLoadingModal());
                dispatch(
                    openErrorModal({
                        paragraphs: [err.message],
                    })
                );
            });
    };
}

export function openCreateManageCaseModalFromDashboard(caseId) {
    return (dispatch, getState, { overlayStore, nexus: { withEntityItems } }) => {
        const state = getState();
        const isCurrentCase = currentCaseIdSelector(state) === caseId;

        //  -- if case already exists in state, open case details modal and
        // display that data first but add a loading gif to indicate
        // that we're fetching new data in the background
        //  -- if case doesn't exist in state yet, open loading modal
        if (isCurrentCase) {
            dispatch(openCreateManageCaseModalForCase());
            overlayStore.setLoading(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, true);
        } else {
            dispatch(openLoadingModal());
        }

        // fetch new data
        caseResource.getCaseDetails(caseId).then((caseDetails) => {
            // if case already exists in state, we've already opened the modal above
            // so just initialize the form instead of re-opening
            dispatch(getCaseDetailsSuccess(caseDetails));
            dispatch(
                withEntityItems(
                    {
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: caseDetails.reportShortTitles,
                        [CASE_APPROVAL_STATUSES_NEXUS_STATE_PROP]: [caseDetails.caseApprovalStatus],
                        [CASES_NEXUS_STATE_PROP]: [caseDetails.theCase],
                        [CASE_APPROVAL_STATUSES_NEXUS_STATE_PROP]: [caseDetails.caseApprovalStatus],
                        [CASES_NEXUS_STATE_PROP]: [caseDetails.theCase],
                        [EXTERNAL_REPORT_STATUSES_NEXUS_STATE_PROP]:
                            caseDetails.externalReportStatuses,
                        [OFFENSE_CASE_STATUSES_NEXUS_STATE_PROP]: caseDetails.offenseCaseStatuses,
                        [REPORTING_EVENTS_NEXUS_STATE_PROP]: caseDetails.reportingEvents,
                        [OFFENSES_NEXUS_STATE_PROP]: caseDetails.offenses,
                        [OFFENSE_CODES_NEXUS_STATE_PROP]: getOffenseCodesFromCaseDetails(
                            caseDetails
                        ),
                    },
                    {
                        type: 'GET_CASE_DETAILS_SUCCESS',
                    }
                )
            );
            dispatch(
                initializeEntityPermissions(
                    EntityTypeEnum.CASE.name,
                    get(caseDetails, 'theCase.id'),
                    get(caseDetails, 'theCase.permissionSet')
                )
            );
            dispatch(sortAndAugmentPermissions(caseDetails.entityPermissions));
            if (isCurrentCase) {
                dispatch(initializeCreateManageCaseModalFormForCase(caseDetails));
            } else {
                dispatch(openCreateManageCaseModalForCase(caseDetails));
            }
            dispatch(closeLoadingModal());
            overlayStore.setLoading(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, false);
        });
    };
}

export function getCaseDetailsByCaseIds(caseIds) {
    return (dispatch, getState, { nexus: { withEntityItems } }) => {
        caseResource
            .getBulkCaseDetails(caseIds)
            .then((caseDetails) => {
                dispatch(
                    withEntityItems(
                        {
                            [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'reportShortTitles'),
                                'reportId'
                            ),
                            [CASE_APPROVAL_STATUSES_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'caseApprovalStatus'),
                                'id'
                            ),
                            [CASE_ATTRIBUTES_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'caseAttributes'),
                                'id'
                            ),
                            [CASE_ITEM_LINKS_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'caseItemLinks'),
                                'id'
                            ),
                            [CASE_NAME_LINKS_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'caseNameLinks'),
                                'id'
                            ),
                            [CASES_NEXUS_STATE_PROP]: uniqBy(flatMap(caseDetails, 'theCase'), 'id'),
                            [CASE_STATUSES_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'caseStatus'),
                                'id'
                            ),
                            [CASE_ROLE_LINKS_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'caseRoleLinks'),
                                'id'
                            ),
                            [EXTERNAL_REPORT_STATUSES_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'externalReportStatuses'),
                                'id'
                            ),
                            [OFFENSE_CASE_STATUSES_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'offenseCaseStatuses'),
                                'id'
                            ),
                            [REPORTING_EVENTS_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'reportingEvents'),
                                'id'
                            ),
                            [OFFENSES_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'offenses'),
                                'id'
                            ),
                            [VEHICLES_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, 'vehicles'),
                                'id'
                            ),
                            [OFFENSE_CODES_NEXUS_STATE_PROP]: uniqBy(
                                flatMap(caseDetails, getOffenseCodesFromCaseDetails),
                                'id'
                            ),
                        },
                        {
                            type: 'GET_CASE_DETAILS_SUCCESS',
                        }
                    )
                );
                dispatch(
                    initializeEntityPermissions(
                        EntityTypeEnum.CASE.name,
                        get(caseDetails, 'theCase.id'),
                        get(caseDetails, 'theCase.permissionSet')
                    )
                );
                dispatch(sortAndAugmentPermissions(caseDetails.entityPermissions));
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function closeCreateManageCaseModal() {
    return (dispatch, getState, { formsRegistry, overlayStore }) => {
        const form = formsRegistry.get(RefContextEnum.FORM_CREATE_MANAGE_CASE.name);
        form.resetUi();
        form.resetModel();
        overlayStore.close(overlayIdEnum.CREATE_MANAGE_CASE_MODAL);
    };
}

const RESET_CURRENT_CASE_GENERAL_UI = 'cases/RESET_CURRENT_CASE_GENERAL_UI';
export function resetCurrentCaseGeneralUi() {
    return {
        type: RESET_CURRENT_CASE_GENERAL_UI,
    };
}

/**
 * Open the "Create Case" modal
 * @param {Object} options
 * @param {number} [options.recordWithoutRenReportId] The report id of the report that the modal was opened from, if
 *      the modal was opened from a record without a REN.
 * @param {string} [options.reportingEventNumber]
 */
export function openCreateCaseModal({ reportingEventNumber, recordWithoutRenReportId } = {}) {
    return (dispatch, getState) => {
        const state = getState();
        const applicationSettings = applicationSettingsSelector(state);
        const formatFieldByName = formatFieldByNameSelector(state);
        const caseDisplayName = formatFieldByName(DISPLAY_ONLY_CASE);

        dispatch(
            createCaseForm.actionCreators.change({
                caseRen: reportingEventNumber,
            })
        );
        dispatch(createCaseForm.actionCreators.setValidity(undefined, true));
        dispatch(openBox(createCaseContext));
        if (reportingEventNumber) {
            dispatch(
                getInfoForReportingEventNumber(reportingEventNumber, createCaseContext, {
                    ignoreFormatValidation: true,
                })
            ).then((result) => {
                if (!result) {
                    return;
                }
                const { reportShortTitles, caseTitles } = result;
                const isAvailable = caseTitles && caseTitles.length === 0;
                dispatch(createCaseForm.actionCreators.setTouched('caseRen'));
                if (!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE) {
                    dispatch(
                        createCaseForm.actionCreators.setValidity(
                            'caseRen',
                            [strings.EditCaseRenModal.duplicateCaseRenError(caseDisplayName)],
                            {
                                errors: !isAvailable,
                            }
                        )
                    );
                }
                dispatch(
                    storePayloadToBox(createCaseContext, {
                        existingCaseIds: map(caseTitles, 'caseId'),
                        existingReportIds: map(reportShortTitles, 'reportId'),
                        showRen: !applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE,
                        enteredRen: reportingEventNumber,
                        renDisabled: true,
                        isCaseRenValid: isAvailable,
                    })
                );
            });
        } else if (
            !!recordWithoutRenReportId &&
            !!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE
        ) {
            caseResource
                .getCasesForReport(recordWithoutRenReportId)
                .then((caseViewModels) => {
                    dispatch(
                        storePayloadToBox(createCaseContext, {
                            existingCaseIds: map(caseViewModels, 'caseId'),
                            existingReportIds: [recordWithoutRenReportId],
                            showRen: false,
                        })
                    );
                })
                .catch((err) => {
                    dispatch(
                        saveBoxFailure(createCaseContext, [
                            strings.CreateCaseModal.getCasesForReportError,
                            err.message,
                        ])
                    );
                    dispatch(
                        storePayloadToBox(createCaseContext, {
                            existingCaseIds: [],
                            existingReportIds: [recordWithoutRenReportId],
                            showRen: false,
                        })
                    );
                });
        } else {
            dispatch(
                storePayloadToBox(createCaseContext, {
                    showRen: true,
                })
            );
        }
    };
}

export function openDeleteCaseModal() {
    return (dispatch, getState) => {
        const state = getState();
        const currentCaseId = currentCaseIdSelector(state);

        dispatch(loadBoxStart(deleteCaseContext));
        isCaseDeletable(currentCaseId)
            .then((canDeleteCase) => {
                dispatch(
                    openBox(deleteCaseContext, {
                        canDeleteCase,
                    })
                );
            })
            .catch((err) => {
                dispatch(
                    openErrorModal({
                        title: strings.DeleteCaseModal.title,
                        paragraphs: [err.message],
                    })
                );
                throw err;
            });
    };
}

export function submitDeleteCaseModal({ router }) {
    return (dispatch, getState) => {
        const state = getState();
        const currentCaseId = currentCaseIdSelector(state);

        dispatch(deleteCase(currentCaseId))
            .then(() => {
                dispatch(saveBoxSuccess(deleteCaseContext));
                dispatch(closeBox(deleteCaseContext));
                router.push('/cases');
            })
            .catch((err) => {
                dispatch(saveBoxFailure(deleteCaseContext, err.message));
                throw err;
            });
    };
}

function deleteCase(id) {
    return (dispatch) => {
        return caseResource.deleteCase(id).then(() => {
            dispatch(removeCase(id));
        });
    };
}

function isCaseDeletable(caseId) {
    return caseResource.isCaseDeletable(caseId);
}

export function openEditCaseRenModal() {
    return (dispatch, getState) => {
        const state = getState();
        const currentCase = currentCaseSelector(state);
        const existingCaseRen = get(currentCase, 'reportingEventNumber');
        dispatch(editCaseRenForm.actionCreators.change({ existingCaseRen }));
        dispatch(editCaseRenForm.actionCreators.setValidity(undefined, true));
        dispatch(openBox(editCaseRenContext));
    };
}

export function submitCaseRenSearch({ boxContext, form, fieldPath }) {
    return (dispatch, getState) => {
        const formatFieldByName = formatFieldByNameSelector(getState());
        const caseDisplayName = formatFieldByName(DISPLAY_ONLY_CASE);
        const caseRen = form.selectors.formModelByPathSelector(getState())(fieldPath);
        const investigationEnhancementsEnabled = applicationSettingsSelector(getState())
            .RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE;

        if (caseRen) {
            // mimic saving to show loading gif and disable save
            dispatch(saveBoxStart(boxContext));
            return dispatch(
                getInfoForReportingEventNumber(caseRen, boxContext, {
                    ignoreFormatValidation: investigationEnhancementsEnabled,
                })
            )
                .then((result) => {
                    // if you type after the api call fires, it will lead to weird replace behavior
                    const caseRenAfterApiCall = form.selectors.formModelByPathSelector(getState())(
                        fieldPath
                    );
                    if (caseRenAfterApiCall !== caseRen || !result) {
                        return;
                    }
                    const { reportShortTitles, caseTitles, reportingEventNumber } = result;

                    const isAvailable = caseTitles && caseTitles.length === 0;
                    if (!investigationEnhancementsEnabled) {
                        dispatch(
                            form.actionCreators.setValidity(
                                fieldPath,
                                [strings.EditCaseRenModal.duplicateCaseRenError(caseDisplayName)],
                                {
                                    errors: !isAvailable,
                                }
                            )
                        );
                    }
                    dispatch(
                        storePayloadToBox(boxContext, {
                            existingCaseIds: map(caseTitles, 'caseId'),
                            existingReportIds: map(reportShortTitles, 'reportId'),
                            enteredRen: reportingEventNumber,
                            isCaseRenValid: isAvailable,
                        })
                    );
                    dispatch(form.actionCreators.changePath(fieldPath, reportingEventNumber));
                    dispatch(saveBoxHalt(boxContext));
                    dispatch(storeBoxErrorMessages(boxContext, []));
                })
                .catch((err) => {
                    dispatch(saveBoxFailure(boxContext, err.message));
                });
        } else {
            dispatch(
                storePayloadToBox(boxContext, {
                    existingCaseIds: [],
                    existingReportIds: [],
                    enteredRen: caseRen,
                    isCaseRenValid: false,
                })
            );
            dispatch(form.actionCreators.setValidity(undefined, true));
        }
    };
}

export function submitEditCaseRenModal() {
    return (dispatch, getState) => {
        return dispatch(
            editCaseRenForm.actionCreators.submit((formModel) => {
                const { newCaseRen } = formModel;
                const state = getState();
                const currentCase = currentCaseSelector(state);
                dispatch(editCaseRen(currentCase, newCaseRen.trim())).then(() => {
                    dispatch(saveBoxSuccess(editCaseRenContext));
                    dispatch(closeBox(editCaseRenContext));
                    // reset the values now that we don't need them
                    dispatch(editCaseRenForm.actionCreators.reset());
                });
            })
        ).catch((err) => {
            dispatch(saveBoxFailure(editCaseRenContext, err));
        });
    };
}

function editCaseRen(theCase, caseRen) {
    const caseToUpdate = {
        ...theCase,
        reportingEventNumber: caseRen,
    };
    return (dispatch, getState) => {
        const folderingEnabled = applicationSettingsSelector(getState()).RMS_CASE_FOLDERING_ENABLED;
        return caseResource
            .updateCase(theCase.id, caseToUpdate)
            .then((theCase) => dispatch(getCompleteCaseView(theCase.id, folderingEnabled)))
            .then((caseView) => dispatch(setCurrentCaseLinkedProfiles(caseView)));
    };
}

export function getCompleteCaseView(caseId, onlyOrphanContent) {
    return (dispatch) => {
        dispatch(getCompleteCaseViewStart());
        return caseResource
            .getCompleteCaseView(caseId, onlyOrphanContent)
            .then(storeConfigForRelatedRecordsAndEntities(dispatch))
            .then((caseView) => {
                // eslint-disable-next-line no-restricted-syntax
                dispatch(storeCaseRelatedModels(caseView));
                dispatch(getCompleteCaseViewSuccess(caseView));
                return caseView;
            })
            .catch((err) => {
                // uncomment for local debugging errors
                // console.log(err);
                dispatch(getCompleteCaseViewFailure(err.message));
                throw err;
            });
    };
}

export function storeCaseRelatedModels(caseView) {
    return (dispatch, getState, dependencies) => {
        const dataNexusEntityKeys = [
            ATTACHMENTS_NEXUS_STATE_PROP,
            CASES_NEXUS_STATE_PROP,
            CASE_ATTRIBUTES_NEXUS_STATE_PROP,
            CASE_APPROVAL_STATUSES_NEXUS_STATE_PROP,
            CASE_ITEM_LINKS_NEXUS_STATE_PROP,
            CASE_NAME_LINKS_NEXUS_STATE_PROP,
            CASE_NOTES_NEXUS_STATE_PROP,
            CASE_REVIEWS_NEXUS_STATE_PROP,
            CASE_ROLE_LINKS_NEXUS_STATE_PROP,
            CASE_STATUSES_NEXUS_STATE_PROP,
            ELASTIC_INVOLVED_LOCATIONS_NEXUS_STATE_PROP,
            ELASTIC_ORGANIZATIONS_NEXUS_STATE_PROP,
            ELASTIC_PERSONS_NEXUS_STATE_PROP,
            ELASTIC_PROPERTY_NEXUS_STATE_PROP,
            ELASTIC_REPORTS_NEXUS_STATE_PROP,
            ELASTIC_VEHICLES_NEXUS_STATE_PROP,
            ELASTIC_WARRANTS_NEXUS_STATE_PROP,
            EXTERNAL_REPORT_STATUSES_NEXUS_STATE_PROP,
            NAME_REPORT_LINKS_NEXUS_STATE_PROP,
            OFFENSE_CASE_STATUSES_NEXUS_STATE_PROP,
            OFFENSES_NEXUS_STATE_PROP,
            OFFENSE_CODES_NEXUS_STATE_PROP,
            ORGANIZATION_PROFILES_NEXUS_STATE_PROP,
            PERSON_PROFILES_NEXUS_STATE_PROP,
            REPORT_CASE_STATUSES_NEXUS_STATE_PROP,
            REPORTING_EVENTS_NEXUS_STATE_PROP,
            REPORT_SHORT_TITLES_NEXUS_STATE_PROP,
            VEHICLES_NEXUS_STATE_PROP,
            SECURITY_CLASSIFICATIONS_NEXUS_STATE_PROP,
        ];

        const entitiesToStore = {
            ...pick(caseView, dataNexusEntityKeys),
            [TASKS_NEXUS_STATE_PROP]: caseView.caseTaskViews,
            [ITEM_PROFILES_NEXUS_STATE_PROP]: caseView.items,
            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: flatten(
                map(caseView.locationViews, 'entityLinks')
            ),
            [LOCATIONS_NEXUS_STATE_PROP]: map(caseView.locationViews, (location) =>
                omit(location, 'entityLinks')
            ),
            [REPORTS_NEXUS_STATE_PROP]: caseView.reports,
        };

        const withRemoveAction = dependencies.nexus.withRemoveMultiple(
            {
                [ATTACHMENTS_NEXUS_STATE_PROP]: {},
                [CASES_NEXUS_STATE_PROP]: {},
                [CASE_ATTRIBUTES_NEXUS_STATE_PROP]: {},
                [CASE_APPROVAL_STATUSES_NEXUS_STATE_PROP]: {},
                [CASE_ITEM_LINKS_NEXUS_STATE_PROP]: {},
                [CASE_NAME_LINKS_NEXUS_STATE_PROP]: {},
                [CASE_NOTES_NEXUS_STATE_PROP]: {},
                [CASE_REVIEWS_NEXUS_STATE_PROP]: {},
                [CASE_ROLE_LINKS_NEXUS_STATE_PROP]: {},
                [CASE_STATUSES_NEXUS_STATE_PROP]: {},
                [ELASTIC_INVOLVED_LOCATIONS_NEXUS_STATE_PROP]: {},
                [ELASTIC_ORGANIZATIONS_NEXUS_STATE_PROP]: {},
                [ELASTIC_PERSONS_NEXUS_STATE_PROP]: {},
                [ELASTIC_PROPERTY_NEXUS_STATE_PROP]: {},
                [ELASTIC_REPORTS_NEXUS_STATE_PROP]: {},
                [ELASTIC_VEHICLES_NEXUS_STATE_PROP]: {},
                [ELASTIC_WARRANTS_NEXUS_STATE_PROP]: {},
                [EXTERNAL_REPORT_STATUSES_NEXUS_STATE_PROP]: {},
                [REPORTING_EVENTS_NEXUS_STATE_PROP]: {},
                [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: {},
                [OFFENSE_CASE_STATUSES_NEXUS_STATE_PROP]: {},
                [OFFENSES_NEXUS_STATE_PROP]: {},
                [OFFENSE_CODES_NEXUS_STATE_PROP]: {},
                [ORGANIZATION_PROFILES_NEXUS_STATE_PROP]: {},
                [PERSON_PROFILES_NEXUS_STATE_PROP]: {},
                [REPORT_CASE_STATUSES_NEXUS_STATE_PROP]: {},
                [REPORTS_NEXUS_STATE_PROP]: {},
                [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: {},
                [LOCATIONS_NEXUS_STATE_PROP]: {},
                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: {},
                [ITEM_PROFILES_NEXUS_STATE_PROP]: {},
                [TASKS_NEXUS_STATE_PROP]: {},
                [VEHICLES_NEXUS_STATE_PROP]: {},
                [SECURITY_CLASSIFICATIONS_NEXUS_STATE_PROP]: {},
            },
            { type: 'CASE_INIT' }
        );

        dispatch(dependencies.nexus.withEntityItems(entitiesToStore, withRemoveAction));

        // eslint-disable-next-line no-restricted-syntax
        dispatch(storeReports(caseView.reports));
        dispatch(
            initializeEntityPermissions(
                EntityTypeEnum.CASE.name,
                caseView.cases[0].id,
                caseView.cases[0].permissionSet
            )
        );
        dispatch(sortAndAugmentPermissions(caseView.entityPermissions));
    };
}

/**
 * Get the Case Defaults
 * @param {Object} caseDefaultsRequest request object for retrieving case default
 * @returns {CaseDefaultsResponse}
 */
export function getCaseDefaults(caseDefaultsRequest) {
    return (dispatch, getState, dependencies) => {
        return caseResource.getCaseDefaults(caseDefaultsRequest).then((caseDefaultsResponse) => {
            dispatch(getCaseDefaultsSuccess(caseDefaultsResponse));
            dispatch(
                dependencies.nexus.withEntityItems(
                    {
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]:
                            caseDefaultsResponse.reportShortTitles,
                        [REPORT_CASE_STATUSES_NEXUS_STATE_PROP]:
                            caseDefaultsResponse.reportCaseStatuses,
                        [EXTERNAL_REPORT_STATUSES_NEXUS_STATE_PROP]:
                            caseDefaultsResponse.externalReportStatuses,
                        [OFFENSE_CASE_STATUSES_NEXUS_STATE_PROP]:
                            caseDefaultsResponse.offenseCaseStatuses,
                        [REPORTING_EVENTS_NEXUS_STATE_PROP]: caseDefaultsResponse.reportingEvents,
                        [OFFENSES_NEXUS_STATE_PROP]: caseDefaultsResponse.offenses,
                        [OFFENSE_CODES_NEXUS_STATE_PROP]: getOffenseCodesFromCaseDetails(
                            caseDefaultsResponse
                        ),
                    },
                    {
                        type: 'STORE_CASE_DEFAULTS',
                    }
                )
            );
            return caseDefaultsResponse;
        });
    };
}

function getCaseDefaultsSuccess(caseDefaultsResponse) {
    return {
        type: GET_CASE_DEFAULTS_SUCCESS,
        payload: caseDefaultsResponse,
    };
}

const STORE_CASES_DASHBOARD_TAB = 'cases/STORE_CASES_DASHBOARD_TAB';
export function storeCasesDashboardTab(tabName) {
    return {
        type: STORE_CASES_DASHBOARD_TAB,
        payload: tabName,
    };
}

const SET_CURRENT_CASE_NOTE_ID = 'cases/SET_CURRENT_CASE_NOTE_ID';

export function setCurrentCaseNoteId(id) {
    return {
        type: SET_CURRENT_CASE_NOTE_ID,
        payload: id,
    };
}

const casesUiSelector = (state) => state.ui.cases;
export const currentCaseUiSelector = createSelector(
    casesUiSelector,
    (casesUi) => casesUi.currentCase.general
);

export const folderTreeExpansionSelector = createSelector(
    casesUiSelector,
    (casesUi) => casesUi.currentCase.folderTreeExpansion
);

export const isNewCaseSelector = createSelector(
    currentCaseUiSelector,
    ({ isNewCase }) => isNewCase
);
export const currentCaseIdSelector = createSelector(
    currentCaseUiSelector,
    (casesUi) => casesUi.caseId
);

const currentNoteIdSelector = createSelector(currentCaseUiSelector, (casesUi) => casesUi.noteId);

export const activeTabSelector = createSelector(
    currentCaseUiSelector,
    ({ activeTab }) => activeTab
);

// this is used only for constructing a case creation request
export const defaultEntityPermissionsSelector = createSelector(
    currentCaseUiSelector,
    (casesUi) => casesUi.defaultEntityPermissions
);

export const currentCaseSelector = createSelector(
    currentCaseIdSelector,
    casesSelector,
    (caseId, cases) => {
        return cases[caseId];
    }
);

export const currentCaseAttachmentsSelector = createSelector(
    currentCaseIdSelector,
    caseAttachmentsByCaseIdAndUserIdSelector,
    currentUserIdSelector,
    (caseId, caseAttachmentsByCaseIdAndUserId, currentUserId) => {
        return caseAttachmentsByCaseIdAndUserId(caseId, currentUserId);
    }
);

// this is here instead of case-notes/state/ui/index in case
// other files need it
export const currentCaseNotesSelector = createSelector(
    currentCaseIdSelector,
    caseNotesWhereSelector,
    (caseId, caseNotesWhere) => {
        const caseNotes = caseNotesWhere({
            caseId,
        });
        return caseNotes;
    }
);

const currentCaseOrphanCaseNotesSelector = createSelector(currentCaseUiSelector, (caseUi) => {
    return caseUi.orphanedEntities.caseNotes;
});

const currentCaseOrphanAttachmentsSelector = createSelector(currentCaseUiSelector, (caseUi) => {
    return caseUi.orphanedEntities.attachments;
});

export const currentCaseNotesByFolderIdSelector = createSelector(
    currentCaseNotesSelector,
    folderContentViewByFolderIdSelector,
    currentCaseOrphanCaseNotesSelector,
    applicationSettingsSelector,
    (
        allCaseNotes,
        folderContentViewByFolderId,
        currentCaseOrphanCaseNotes,
        applicationSettings
    ) => (folderId) => {
        if (!applicationSettings.RMS_CASE_FOLDERING_ENABLED) {
            return allCaseNotes;
        }
        if (!folderId) {
            return currentCaseOrphanCaseNotes;
        }

        const folderContentView = folderContentViewByFolderId(folderId);

        if (folderContentView && folderContentView.hydratedFolderContent) {
            const { caseNotes = [] } = folderContentView.hydratedFolderContent;
            return caseNotes;
        }
        return [];
    }
);

export const currentCaseNoteSelector = createSelector(
    currentNoteIdSelector,
    currentCaseNotesSelector,
    (noteId, caseNotes) => {
        // side effects here so we can render ko view within react
        // See CaseNotes.js and caseNotesView.js
        // In general, you should NOT be doing this
        if (noteId && caseNotes) {
            const caseNote = find(caseNotes, { id: noteId });
            return caseNote;
        } else {
            return null;
        }
    }
);

export const caseNoteLocationByNoteIdSelector = createSelector(
    locationViewModelsLinkedToEntitySelector,
    (locationViewModelsLinkedToEntity) => (noteId) => {
        return first(locationViewModelsLinkedToEntity(EntityTypeEnum.CASE_NOTE.name, noteId));
    }
);

export const currentCaseNoteLocationSelector = createSelector(
    currentNoteIdSelector,
    caseNoteLocationByNoteIdSelector,
    (currentNoteId, caseNoteLocationByNoteId) => {
        const caseNoteLocation = caseNoteLocationByNoteId(currentNoteId);
        return caseNoteLocation;
    }
);

export const activeCaseDefinitionViewModelsForCurrentDepartmentSelector = createSelector(
    activeCaseDefinitionViewModelsSelector,
    currentUserDepartmentIdSelector,
    (activeCaseDefinitionViewModels, currentUserDepartmentId) =>
        filter(activeCaseDefinitionViewModels, {
            departmentId: currentUserDepartmentId,
        })
);

const expiredCaseDefinitionViewModelsForCurrentDepartmentSelector = createSelector(
    expiredCaseDefinitionViewModelsSelector,
    currentUserDepartmentIdSelector,
    (expiredCaseDefinitionViewModels, currentUserDepartmentId) =>
        filter(expiredCaseDefinitionViewModels, {
            departmentId: currentUserDepartmentId,
        })
);

export const caseDefinitionOptionsSelector = createSelector(
    activeCaseDefinitionViewModelsForCurrentDepartmentSelector,
    expiredCaseDefinitionViewModelsForCurrentDepartmentSelector,
    (activeCaseDefinitionViewModels, expiredCaseDefinitionViewModels) => {
        return memoize((includeExpired = false) =>
            mapCaseDefinitionsToOptions([
                ...activeCaseDefinitionViewModels,
                ...(includeExpired ? expiredCaseDefinitionViewModels : []),
            ])
        );
    }
);

export const currentCaseViewSelector = createSelector(
    currentCaseIdSelector,
    caseViewModelsSelector,
    (currentCaseId, caseViewModels) => caseViewModels[currentCaseId]
);

export const currentCaseRoleLinksSelector = createSelector(
    currentCaseIdSelector,
    caseRoleLinkViewModelsForCaseIdSelector,
    (caseId, caseRoleLinkViewModelsForCaseId) =>
        sortBy(caseRoleLinkViewModelsForCaseId(caseId), 'id')
);

export const caseStatusOptionsSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (options) =>
        filter(
            options,
            ({ value }) =>
                parentAttributeIdByAttributeId(value) !== globalAttributes.caseStatus.canceled
        )
);

const currentCaseStatusSelector = createSelector(
    currentCaseIdSelector,
    caseStatusesByCaseIdSelector,
    (caseId, caseStatusesByCaseId) =>
        // caseStatusesByCaseId will return an array (server returns us an array)
        // but we only show 1 case status per case
        first(caseStatusesByCaseId(caseId))
);

export const currentCaseStatusViewModelSelector = createSelector(
    currentCaseIdSelector,
    caseStatusViewModelByCaseIdSelector,
    (caseId, caseStatusViewModelByCaseId) => caseStatusViewModelByCaseId(caseId)
);

export const currentCaseApprovalStatusSelector = createSelector(
    currentCaseIdSelector,
    caseApprovalStatusByCaseIdSelector,
    (caseId, caseApprovalStatusByCaseId) => caseApprovalStatusByCaseId(caseId)
);

export const currentCaseEntityPermissionsSelector = createSelector(
    currentCaseIdSelector,
    entityPermissionsDataSelector,
    (caseId, entityPermissions) => {
        return sortBy(entityPermissions(caseId), 'roleId');
    }
);

const currentCaseAttributesSelector = createSelector(
    currentCaseIdSelector,
    caseAttributesByCaseIdSelector,
    (caseId, caseAttributesByCaseId) => caseAttributesByCaseId(caseId)
);

export const assigneeCaseRoleLinkSelector = createSelector(
    currentCaseRoleLinksSelector,
    (caseRoleLinks) => {
        return find(caseRoleLinks, (caseRoleLink) => {
            return caseRoleLink.caseRoleAttrId === globalAttributes.caseRoleGlobal.assignee;
        });
    }
);

export const assistingInvestigatorCaseRoleLinksSelector = createSelector(
    currentCaseRoleLinksSelector,
    (caseRoleLinks) => {
        return filter(caseRoleLinks, (caseRoleLink) => {
            return (
                caseRoleLink.caseRoleAttrId ===
                globalAttributes.caseRoleGlobal.assistingInvestigator
            );
        });
    }
);

export const supervisorCaseRoleLinksSelector = createSelector(
    currentCaseRoleLinksSelector,
    (caseRoleLinks) => {
        return filter(caseRoleLinks, (caseRoleLink) => {
            return caseRoleLink.caseRoleAttrId === globalAttributes.caseRoleGlobal.supervisor;
        });
    }
);

export const currentCaseReportIdsSelector = createSelector(
    currentCaseIdSelector,
    reportIdsForCaseIdSelector,
    (currentCaseId, reportIdsForCaseId) => reportIdsForCaseId(currentCaseId)
);

const currentCaseReportsSelector = createSelector(
    currentCaseReportIdsSelector,
    reportsSelector,
    (reportIds, reports) => compact(map(reportIds, (reportId) => reports[reportId]))
);

const currentReportCaseStatusesForCurrentDepartmentSelector = createSelector(
    currentCaseReportIdsSelector,
    currentUserDepartmentIdSelector,
    reportCaseStatusesForValidReportsSelector,
    (reportIds, currentUserDepartmentId, reportCaseStatuses) =>
        _(reportIds)
            .map((reportId) => reportCaseStatuses[reportId])
            .compact()
            // filter out report case statuses which do not belong to the department
            // of the current user so that they won't show up when trying to
            // edit the state of a report via the "edit" interface of a case
            .filter(({ departmentId }) => departmentId === currentUserDepartmentId)
            .sortBy('id')
            .value()
);

const currentCaseReportingEventSelector = createSelector(
    currentCaseSelector,
    reportingEventsWhereSelector,
    (currentCase, reportingEventsWhere) =>
        first(
            reportingEventsWhere({
                reportingEventNumber: get(currentCase, 'reportingEventNumber'),
            })
        )
);

const currentCaseReportingEventIdSelector = createSelector(
    currentCaseReportingEventSelector,
    (currentCaseReportingEvent) => get(currentCaseReportingEvent, 'id')
);

const currentCaseExternalReportStatusSelector = createSelector(
    currentCaseReportingEventIdSelector,
    externalReportStatusForReportingEventIdSelector,
    (currentCaseReportingEventId, externalReportStatusForReportingEventId) =>
        externalReportStatusForReportingEventId(currentCaseReportingEventId)
);

const currentOffenseIdsSelector = createSelector(
    currentCaseReportIdsSelector,
    offensesByReportIdSelector,
    (reportIds, offensesForReportId) => {
        const offenses = flatten(map(reportIds, (reportId) => offensesForReportId(reportId)));
        return map(offenses, 'id');
    }
);

const currentCaseOffenseCaseStatusesSelector = createSelector(
    currentOffenseIdsSelector,
    offenseCaseStatusByOffenseIdSelector,
    (offenseIds, offenseCaseStatusByOffenseId) => {
        return compact(map(offenseIds, (offenseId) => offenseCaseStatusByOffenseId[offenseId]));
    }
);

// compose things needed for caseDetailsForm
const currentCaseDetailsSelector = createSelector(
    currentCaseSelector,
    currentCaseRoleLinksSelector,
    currentCaseStatusSelector,
    currentReportCaseStatusesForCurrentDepartmentSelector,
    currentCaseEntityPermissionsSelector,
    currentCaseExternalReportStatusSelector,
    currentCaseOffenseCaseStatusesSelector,
    currentCaseReportingEventSelector,
    currentCaseReportsSelector,
    currentCaseAttributesSelector,
    (
        currentCase,
        caseRoleLinks,
        caseStatus,
        reportCaseStatuses,
        entityPermissions,
        externalReportStatus,
        offenseCaseStatuses,
        reportingEvent,
        reports,
        caseAttributes
    ) => ({
        theCase: currentCase,
        caseRoleLinks,
        caseStatus,
        reportCaseStatuses,
        entityPermissions,
        externalReportStatuses: compact([externalReportStatus]),
        offenseCaseStatuses,
        reportingEvents: compact([reportingEvent]),
        reports,
        caseAttributes,
    })
);

export const caseDetailsByCaseIdSelector = createSelector(
    caseByIdSelector,
    caseRoleLinkViewModelsForCaseIdSelector,
    caseStatusesByCaseIdSelector,
    caseApprovalStatusByCaseIdSelector,
    (
        caseById,
        caseRoleLinkViewModelsForCaseId,
        caseStatusesByCaseId,
        caseApprovalStatusByCaseId
    ) => (caseId) => ({
        theCase: caseById(caseId),
        caseRoleLinks: sortBy(caseRoleLinkViewModelsForCaseId(caseId), 'id'),
        caseStatus: first(caseStatusesByCaseId(caseId)),
        caseApprovalStatus: caseApprovalStatusByCaseId(caseId),
    })
);

export const currentCaseReportReportLinksSelector = createSelector(
    currentCaseReportIdsSelector,
    reportReportLinksWhereSelector,
    (reportIds, reportReportLinksWhere) => {
        return reportReportLinksWhere(
            ({ departmentId, toDepartmentId, fromReportId, toReportId }) => {
                return (
                    includes(reportIds, fromReportId) ||
                    (departmentId === toDepartmentId && includes(reportIds, toReportId))
                );
            }
        );
    }
);

export const currentCaseReportExternalLinksSelector = createSelector(
    currentCaseReportIdsSelector,
    reportExternalLinksWhereSelector,
    (reportIds, reportExternalLinksWhere) => {
        return reportExternalLinksWhere(({ reportId }) => {
            return includes(reportIds, reportId);
        });
    }
);

export const caseReportsSelector = createSelector(
    currentCaseReportIdsSelector,
    elasticReportsSelector,
    (reportIds, elasticReports) => compact(map(reportIds, (reportId) => elasticReports[reportId]))
);

export const reportsResultsViewModelsSelector = createSelector(
    createReportsResultsSelector(caseReportsSelector),
    (reportsResults) => sortBy(reportsResults, ['reportingEventNumber', 'reportCreationDateUtc'])
);

// case permissions
const currentUserCasePermissionsForNewCaseSelector = createSelector(
    navigationPermissionsSelector,
    (permissions) => {
        return {
            canEventuallyApprove: permissions.hasCaseApproval,
        };
    }
);

const currentUserCasePermissionsForExistingCaseSelector = createSelector(
    currentCaseSelector,
    currentCaseApprovalStatusSelector,
    assigneeCaseRoleLinkSelector,
    currentUserIdSelector,
    navigationPermissionsSelector,
    ({ permissionSet } = {}, caseApprovalStatus, assigneeCaseRoleLink, userId, permissions) => {
        const hasWrite = includes(permissionSet, OperationTypeEnum.WRITE.name);
        const hasManage = includes(permissionSet, OperationTypeEnum.MANAGE.name);
        const isAssignee = userId === get(assigneeCaseRoleLink, 'caseRole.userId');
        return {
            canApprove:
                permissions.hasCaseApproval &&
                hasManage &&
                caseApprovalStatus.status === ApprovalStatusEnum.SUBMITTED.name &&
                !isAssignee, // assignee can't approve own case
            canEventuallyApprove: permissions.hasCaseApproval && hasManage && !isAssignee,
            hasWrite,
            hasManage,
            isAssignee,
        };
    }
);

export const currentUserCasePermissionsSelector = createSelector(
    isNewCaseSelector,
    makeLazy(currentUserCasePermissionsForNewCaseSelector),
    makeLazy(currentUserCasePermissionsForExistingCaseSelector),
    (isNewCase, currentUserCasePermissionsForNewCase, currentUserCasePermissionsForExistingCase) =>
        isNewCase
            ? currentUserCasePermissionsForNewCase()
            : currentUserCasePermissionsForExistingCase()
);

export const canCurrentUserEditCurrentCaseNotesSelector = createSelector(
    currentUserHasAbilitySelector,
    currentCaseSelector,
    (currentUserHasAbility, currentCase) => {
        if (!currentCase) {
            return false;
        }
        return (
            currentUserHasAbility(abilitiesEnum.CASES.EDIT_NOTES) &&
            canWrite(currentCase.permissionSet)
        );
    }
);

export const canCurrentUserEditCurrentCaseSelector = createSelector(
    currentUserHasAbilitySelector,
    currentCaseSelector,
    (currentUserHasAbility, currentCase) => {
        if (!currentCase) {
            return false;
        }

        return (
            currentUserHasAbility(abilitiesEnum.CASES.EDIT_GENERAL) &&
            canWrite(currentCase.permissionSet)
        );
    }
);

export const canModifyCaseNoteForCurrentCaseInFolderSelector = createSelector(
    canCurrentUserEditCurrentCaseNotesSelector,
    currentCaseNotesByFolderIdSelector,
    currentUserIdSelector,
    (canCurrentUserCreateNotes, currentCaseNotesByFolderId, currentUserId) => (
        noteId,
        folderId
    ) => {
        const currentCaseNotes = currentCaseNotesByFolderId(folderId);
        const selectedCaseNote = find(currentCaseNotes, { id: noteId });
        if (selectedCaseNote) {
            return canCurrentUserCreateNotes && selectedCaseNote.author === currentUserId;
        }
        return false;
    }
);

// REDUCER
const currentCaseGeneralUiReducer = makeResettable(
    RESET_CURRENT_CASE_GENERAL_UI,
    function currentCaseGeneralUiReducer(
        state = {
            caseId: null,
            isNewCase: false,
            noteId: null,
            reportIdsForCaseCreation: [],
            activeTab: null,
            defaultEntityPermissions: [],
            orphanedEntities: {
                attachments: [],
                caseNotes: [],
            },
        },
        action
    ) {
        switch (action.type) {
            case GET_CASE_DEFAULTS_SUCCESS:
                const entityPermissionRoleLinks = filter(
                    action.payload.caseDefinitionCompleteView.caseDefaultRoleLinks,
                    {
                        caseRoleAttrId: globalAttributes.caseRoleGlobal.otherPersonnel,
                    }
                );
                // only want the other personnel entity permissions, the other ones are handled by the form
                const entityPermissions = map(
                    entityPermissionRoleLinks,
                    ({ operationType, roleId }) => ({
                        operationType,
                        roleId,
                    })
                );

                return {
                    ...state,
                    isNewCase: true,
                    reportIdsForCaseCreation: map(action.payload.reportShortTitles, 'reportId'),
                    defaultEntityPermissions: entityPermissions,
                };
            case GET_COMPLETE_CASE_VIEW_SUCCESS:
                return {
                    ...state,
                    caseId: get(action.payload, 'cases[0].id'),
                    isNewCase: false,
                };
            case GET_CASE_DETAILS_SUCCESS:
                return {
                    ...state,
                    reportIdsForCaseCreation: map(action.payload.reportShortTitles, 'reportId'),
                    caseId: get(action.payload, 'theCase.id'),
                    isNewCase: false,
                };
            case SET_CURRENT_CASE_NOTE_ID:
                return {
                    ...state,
                    noteId: action.payload,
                };
            case STORE_CASES_DASHBOARD_TAB:
                return {
                    ...state,
                    activeTab: action.payload,
                };
            case SAVE_ORPHANED_CASE_CONTENTS:
                return {
                    ...state,
                    orphanedEntities: {
                        attachments: action.payload.attachments,
                        caseNotes: action.payload.caseNotes,
                    },
                };
            default:
                return state;
        }
    }
);

const SET_FOLDERS = 'SET_FOLDERS';
const TOGGLE_FOLDER = 'TOGGLE_FOLDER';

function folderTreeExpansionReducer(
    state = {
        [EntityTypeEnum.ATTACHMENT.name]: {
            expanded: false,
            folders: {},
        },
        [EntityTypeEnum.CASE_NOTE.name]: {
            expanded: false,
            folders: {},
        },
    },
    action
) {
    switch (action.type) {
        case SET_FOLDERS:
            return {
                ...state,
                [action.payload.entityType]: {
                    expanded: action.payload.isTopExpanded,
                    folders: {
                        ...state[action.payload.entityType].folders,
                        ...action.payload.foldersMap,
                    },
                },
            };
        case TOGGLE_FOLDER:
            if (action.payload.folderId === undefined) {
                return {
                    ...state,
                    [action.payload.entityType]: {
                        expanded: !state[action.payload.entityType].expanded,
                        folders: {
                            ...state[action.payload.entityType].foldersMap,
                        },
                    },
                };
            }
            return {
                ...state,
                [action.payload.entityType]: {
                    expanded: state[action.payload.entityType]?.expanded ?? true,
                    folders: {
                        ...state[action.payload.entityType].folders,
                        [action.payload.folderId]:
                            state[action.payload.entityType]?.folders[action.payload.folderId] !==
                            undefined
                                ? !state[action.payload.entityType]?.folders[
                                      action.payload.folderId
                                  ]
                                : true,
                    },
                },
            };
        default:
            return state;
    }
}

export function expandFolders(entityType, folderIds) {
    const foldersMap =
        folderIds !== undefined
            ? folderIds.reduce((acc, folderId) => {
                  acc[folderId] = true;
                  return acc;
              }, {})
            : {};

    return {
        type: SET_FOLDERS,
        payload: {
            entityType,
            isTopExpanded: true,
            foldersMap,
        },
    };
}

export function collapseFolders(entityType, isTopExpanded, folderIds) {
    const foldersMap =
        folderIds !== undefined
            ? folderIds.reduce((acc, folderId) => {
                  acc[folderId] = false;
                  return acc;
              }, {})
            : {};

    return {
        type: SET_FOLDERS,
        payload: {
            entityType,
            isTopExpanded,
            foldersMap,
        },
    };
}

export function toggleFolderExpansion(entityType, folderId) {
    return {
        type: TOGGLE_FOLDER,
        payload: {
            entityType,
            folderId,
        },
    };
}

export default combineReducers({
    general: currentCaseGeneralUiReducer,
    folderTreeExpansion: folderTreeExpansionReducer,
});

export const currentCaseAttachmentsByFolderIdSelector = createSelector(
    currentCaseAttachmentsSelector,
    currentCaseOrphanAttachmentsSelector,
    applicationSettingsSelector,
    folderContentViewByFolderIdSelector,
    (
        currentCaseAttachments,
        currentCaseOrphanAttachments,
        applicationSettings,
        folderContentViewByFolderId
    ) => (folderId) => {
        if (!applicationSettings.RMS_CASE_FOLDERING_ENABLED) {
            return currentCaseAttachments;
        }

        if (!folderId) {
            return currentCaseOrphanAttachments;
        }
        const folderContentView = folderContentViewByFolderId(folderId);

        if (!folderContentView || !folderContentView.hydratedFolderContent) {
            return [];
        }
        return folderContentView.hydratedFolderContent.hydratedAttachments;
    }
);

export const currentCaseFolderAttachmentsRowViewModelSelector = createSelector(
    currentCaseIdSelector,
    currentUserIdSelector,
    currentCaseAttachmentsByFolderIdSelector,
    convertAttachmentsToCaseAttachmentsRowViewModelsSelector,
    (
        currentCaseId,
        currentUserId,
        currentCaseAttachmentsByFolder,
        convertAttachmentsToCaseAttachmentsRowViewModels
    ) => (folderId) => {
        const attachments = currentCaseAttachmentsByFolder(folderId);

        return convertAttachmentsToCaseAttachmentsRowViewModels(
            currentCaseId,
            currentUserId,
            attachments
        );
    }
);

const currentCaseFolderFoldersSelector = createSelector(
    applicationSettingsSelector,
    currentCaseIdSelector,
    folderContentViewsSelector,
    foldersByOwnerIdSelector,
    formatFieldByNameSelector,
    (
        applicationSettings,
        currentCaseId,
        folderContentViews,
        foldersByOwnerId,
        formatFieldByName
    ) => (folderId) => {
        if (!applicationSettings.RMS_CASE_FOLDERING_ENABLED) {
            return [];
        }
        const caseDisplayName = formatFieldByName(DISPLAY_ONLY_CASE);
        return folderId
            ? chain(folderContentViews[folderId]?.directSubFolders)
                  .map((folder) => translateToFolderRowView(folder, caseDisplayName))
                  .compact()
                  .value()
            : chain(foldersByOwnerId(currentCaseId, EntityTypeEnum.ATTACHMENT.name))
                  .map(({ folder }) => translateToFolderRowView(folder, caseDisplayName))
                  .compact()
                  .value();
    }
);

export const currentCaseCombinedAttachmentsAndFoldersSelector = createSelector(
    applicationSettingsSelector,
    currentCaseFolderAttachmentsRowViewModelSelector,
    currentCaseFolderFoldersSelector,
    (applicationSettings, currentCaseFolderAttachmentsRowViewModel, currentCaseFolderFolders) => (
        folderId
    ) => {
        const attachments = map(
            currentCaseFolderAttachmentsRowViewModel(folderId),
            (attachment) => ({
                ...attachment,
                isFolder: false,
            })
        );
        if (!applicationSettings.RMS_CASE_FOLDERING_ENABLED) {
            return attachments;
        }

        const folders = map(currentCaseFolderFolders(folderId), (folder) => ({
            ...folder,
            isFolder: true,
        }));
        return [...attachments, ...folders];
    }
);

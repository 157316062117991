import { chain, get, head, isEmpty, map, omit, pick } from 'lodash';
import { createSelector } from 'reselect';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL } from '~/client-common/core/enums/universal/fields';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import {
    formDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import {
    vehicleFieldsetViewModel,
    personDetailsFieldsetViewModel,
    identifiersFieldsetViewModel,
    organizationFieldsetViewModel,
    vehicleCautionsFieldsetViewModel,
} from '../../../../../legacy-redux/configs/fieldsetsConfig';

const advancedSearchVehiclesFormFieldViewModels = {
    ...buildFlatFormFieldViewModels([
        // fields in the form that are not nested
        'fuzzyMatchingEnabled',
    ]),
    vehicle: vehicleFieldsetViewModel,
    personDetails: personDetailsFieldsetViewModel,
    identifiers: identifiersFieldsetViewModel,
    organization: organizationFieldsetViewModel,
};

/**
 * Convert the given search query model to form model state. While the query is
 *   flat, the form model state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertAdvancedSearchVehiclesElasticQueryToFormModel(elasticQuery) {
    const person = get(elasticQuery, 'propertyPersons.[0].person');
    const org = get(elasticQuery, 'propertyOrganizations.[0].organization');
    const vehicle = get(elasticQuery, 'vehicle');
    const itemAttrIds = get(elasticQuery, 'itemAttributes[0].itemAttrIds');
    const cautions = get(elasticQuery, 'cautions[0]');

    return buildFormModel(
        {
            fuzzyMatchingEnabled: get(elasticQuery, 'fuzzyMatchingEnabled'),
            vehicle: {
                ...pick(
                    { ...vehicle, ...head(get(elasticQuery, 'propertyStatuses')) },
                    map(vehicleFieldsetViewModel.fields, 'key')
                ),
                vehicleMakeOther: chain(vehicle).get('vehicleMakeOthers').head().value(),
                vehicleModelOther: chain(vehicle).get('vehicleModelOthers').head().value(),
                vehicleLabelAttrIds: itemAttrIds,
                cautions: pick(cautions, map(vehicleCautionsFieldsetViewModel.fields, 'key')),
            },
            personDetails: pick(person, map(personDetailsFieldsetViewModel.fields, 'key')),
            identifiers: pick(person, map(identifiersFieldsetViewModel.fields, 'key')),
            organization: pick(org, map(organizationFieldsetViewModel.fields, 'key')),
        },
        advancedSearchVehiclesFormFieldViewModels
    );
}

function convertFormModelToItemttributes(formModel) {
    const vehicleFormModel = get(formModel, 'vehicle', {});
    const vehicleLabelAttrIds = vehicleFormModel.vehicleLabelAttrIds || [];
    if (isEmpty(vehicleLabelAttrIds)) {
        return;
    }
    return { itemAttributes: [{ itemAttrIds: vehicleLabelAttrIds }] };
}

export function convertFormModelToVehicleCautionsElasticQuery({ vehicle = {} } = {}) {
    if ('cautions' in vehicle) {
        return { cautions: [vehicle.cautions] };
    }
}

export function convertVehicleCautionsFormModelToFilterGroups(formatFieldByName) {
    const activeWithinRangeLabel = formatFieldByName(DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL);
    return {
        cautions: {
            ...vehicleCautionsFieldsetViewModel,
            fields: {
                ...vehicleCautionsFieldsetViewModel.fields,
                activeWithinRange: {
                    ...vehicleCautionsFieldsetViewModel.fields.activeWithinRange,
                    fields: {
                        ...Object.keys(
                            vehicleCautionsFieldsetViewModel.fields.activeWithinRange.fields
                        ).reduce(
                            (fields, key) => ({
                                ...fields,
                                [key]: {
                                    ...fields[key],
                                    label: activeWithinRangeLabel,
                                },
                            }),
                            vehicleCautionsFieldsetViewModel.fields.activeWithinRange.fields
                        ),
                    },
                },
            },
        },
    };
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertAdvancedSearchVehiclesFormModelToElasticQuery(
    formModel = {},
    { ownerNameItemAttributeIds } = {}
) {
    const person = {
        ...formModel.personDetails,
        ...formModel.identifiers,
    };

    const { vehicleMakeOther, vehicleModelOther } = formModel.vehicle || {};

    return filterFormData({
        fuzzyMatchingEnabled: formModel.fuzzyMatchingEnabled,
        vehicle: {
            ...omit(formModel.vehicle, [
                'propertyStatusAttrIds',
                'vehicleLabelAttrIds',
                'vehicleMakeOther',
                'vehicleModelOther',
                'cautions',
            ]),
            vehicleMakeOthers: vehicleMakeOther ? [vehicleMakeOther] : undefined,
            vehicleModelOthers: vehicleModelOther ? [vehicleModelOther] : undefined,
        },
        propertyStatuses: [
            {
                propertyStatusAttrIds: get(formModel.vehicle, 'propertyStatusAttrIds'),
            },
        ],
        propertyPersons: [
            {
                person,
                nameItemLink: formDataIsEmpty(person)
                    ? {}
                    : {
                          nameItemAssociationAttrDetail: {
                              ids: ownerNameItemAttributeIds,
                          },
                      },
            },
        ],
        propertyOrganizations: [
            {
                organization: formModel.organization,
                nameItemLink: formDataIsEmpty(formModel.organization)
                    ? {}
                    : {
                          nameItemAssociationAttrDetail: {
                              ids: ownerNameItemAttributeIds,
                          },
                      },
            },
        ],
        ...convertFormModelToItemttributes(formModel),
        ...convertFormModelToVehicleCautionsElasticQuery(formModel),
    });
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
export function convertAdvancedSearchVehiclesFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    const fieldViewModels = {
        ...advancedSearchVehiclesFormFieldViewModels,
        vehicle: {
            ...advancedSearchVehiclesFormFieldViewModels.vehicle,
            fields: {
                ...advancedSearchVehiclesFormFieldViewModels.vehicle.fields,
                ...convertVehicleCautionsFormModelToFilterGroups(boundSelectors.formatFieldByName),
            },
        },
    };

    return convertFormModelToFilterGroups(
        formModel,
        fieldViewModels,
        formatFieldValue,
        boundSelectors.formatFieldByName
    );
}

const advancedSearchVehiclesForm = createFormModule({
    formName: formClientEnum.ADVANCED_SEARCH_VEHICLES,
    fieldViewModels: advancedSearchVehiclesFormFieldViewModels,
    convertToFormModel: convertAdvancedSearchVehiclesElasticQueryToFormModel,
    convertFromFormModel: convertAdvancedSearchVehiclesFormModelToElasticQuery,
});

advancedSearchVehiclesForm.selectors.formIsEmptySelector = createSelector(
    advancedSearchVehiclesForm.selectors.formModelSelector,
    (formModel) => formDataIsEmpty(omit(formModel, 'fuzzyMatchingEnabled'))
);

/**
 * Module of the advanced search vehicles form.
 * @type {Object}
 */
export default advancedSearchVehiclesForm;

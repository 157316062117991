import React from 'react';
import { includes } from 'lodash';
import styled from 'styled-components';
import { FormattedDate } from '~/client-common/core/dates/components';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import { overdueTaskParentStatusAttributeIds } from '~/client-common/core/domain/tasks/configuration';
import {
    calculateDateDifferenceFromNow,
    isBefore,
    isCurrentWeek,
    isToday,
    isYesterday,
    isTomorrow,
} from '~/client-common/core/dates/utils/dateHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import testIds from '../../../../core/testIds';

interface TaskDateCellProps {
    date: string;
    statusAttrId: number;
    isDueDate?: boolean;
}

interface DateLabellWithTimeProps {
    date: string;
    label: string;
    isOverdue?: boolean;
}

const OverdueFormattedDate = styled(FormattedDate)`
    color: ${(props) => props.theme.colors.red};
`;

const OverdueDate = styled.span<{ isOverdue: boolean }>`
    color: ${({ isOverdue, theme }) => isOverdue && theme.colors.red};
`;

const dateStrings = componentStrings.tasks.core.TaskTable.dates;

const DateLabelWithTime: React.FC<DateLabellWithTimeProps> = ({
    date,
    label,
    isOverdue = false,
}) => {
    const testId = isOverdue ? testIds.OVERDUE_FORMATTED_DATE : testIds.FORMATTED_DATE;
    return (
        <FormattedDate
            date={date}
            format={FormattedDate.FORMATS.FORM_TIME}
            children={(formattedTime) => (
                <OverdueDate
                    isOverdue={isOverdue}
                    data-test-id={testId}
                >{`${label} (${formattedTime})`}</OverdueDate>
            )}
        />
    );
};

const TaskDateCell: React.FC<TaskDateCellProps> = ({ date, statusAttrId, isDueDate = true }) => {
    if (!date) {
        return null;
    }

    const overDueComponent = (isTaskOverdue: boolean) => {
        if (isTaskOverdue) {
            return (
                <OverdueFormattedDate
                    testId={testIds.OVERDUE_FORMATTED_DATE}
                    date={date}
                    format={FormattedDate.FORMATS.FORM_DATE_TIME}
                />
            );
        } else {
            return (
                <FormattedDate
                    testId={testIds.FORMATTED_DATE}
                    date={date}
                    format={FormattedDate.FORMATS.FORM_DATE_TIME}
                />
            );
        }
    };

    return (
        <ConnectedFormattedAttribute attributeId={statusAttrId}>
            {(formattedAttribute, attribute) => {
                const { parentId } = attribute;

                const isOverdueTaskParentStatus = includes(
                    overdueTaskParentStatusAttributeIds,
                    parentId
                );

                const isTaskOverdue = isBefore(new Date(), date) && isOverdueTaskParentStatus;

                return (
                    <FeatureFlagged
                        flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                        fallback={overDueComponent(isTaskOverdue)}
                    >
                        <EnhancedTaskDateCell
                            date={date}
                            isDueDate={isDueDate}
                            isOverdueTaskParentStatus={isOverdueTaskParentStatus}
                            isTaskOverdue={isTaskOverdue}
                        />
                    </FeatureFlagged>
                );
            }}
        </ConnectedFormattedAttribute>
    );
};

interface EnhancedTaskDateCellProps {
    date: string;
    isDueDate: boolean;
    isOverdueTaskParentStatus: boolean;
    isTaskOverdue: boolean;
}

const EnhancedTaskDateCell: React.FC<EnhancedTaskDateCellProps> = ({
    date,
    isDueDate,
    isOverdueTaskParentStatus,
    isTaskOverdue,
}) => {
    const isDateToday = isToday(date);
    const isDateYesterday = isYesterday(date);

    // Due Date with NOT_APPLICABLE or COMPLETED status or Created Date or Modified Date
    if (!isDueDate || !isOverdueTaskParentStatus) {
        if (isDateToday || isDateYesterday) {
            const dateLabel = isDateToday ? dateStrings.today : dateStrings.yesterday;
            return <DateLabelWithTime date={date} label={dateLabel} />;
        }
        return (
            <FormattedDate
                testId={testIds.FORMATTED_DATE}
                date={date}
                format={FormattedDate.FORMATS.FORM_DATE_TIME}
            />
        );
    }

    // Due Date with PENDING or TO DO status
    if (isTaskOverdue) {
        if (isDateYesterday || isDateToday) {
            const dateLabel = isDateToday ? dateStrings.today : dateStrings.yesterday;
            return <DateLabelWithTime date={date} label={dateLabel} isOverdue />;
        }
        return (
            <OverdueFormattedDate
                testId={testIds.OVERDUE_FORMATTED_DATE}
                date={date}
                format={FormattedDate.FORMATS.FORM_DATE_TIME}
            />
        );
    } else {
        if (isDateToday || isTomorrow(date)) {
            const dateLabel = isDateToday ? dateStrings.today : dateStrings.tomorrow;
            return <DateLabelWithTime date={date} label={dateLabel} />;
        }

        if (isCurrentWeek(date)) {
            return (
                <span data-test-id={testIds.FORMATTED_DATE}>
                    <FormattedDate date={date} format={FormattedDate.FORMATS.DAY_OF_WEEK} /> (
                    <FormattedDate date={date} format={FormattedDate.FORMATS.FORM_TIME} />)
                </span>
            );
        }

        const dateDifference = Math.ceil(calculateDateDifferenceFromNow(date, 'days'));
        if (dateDifference <= 28) {
            return (
                <span data-test-id={testIds.FORMATTED_DATE}>
                    <FormattedDate
                        date={date}
                        hasTooltip
                        formatList={[
                            FormattedDate.FORMATS.SUMMARY_DATE_WITHOUT_YEAR,
                            FormattedDate.FORMATS.FORM_TIME,
                        ]}
                        suffix={`(${dateDifference} days)`}
                    />
                </span>
            );
        }

        return (
            <FormattedDate
                testId={testIds.FORMATTED_DATE}
                date={date}
                format={FormattedDate.FORMATS.FORM_DATE_TIME}
            />
        );
    }
};

export default TaskDateCell;

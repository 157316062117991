import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import createFormModule from '../../../../core/forms/lib/createFormModule';
import {
    mustBeInts,
    requiredString,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

export default createFormModule({
    formName: formClientEnum.SUPPORT_SEARCH_SYNC_SYNC_ENTITY,
    fieldViewModels: buildFlatFormFieldViewModels([
        'rmsEntityType',
        'cadEntityType',
        'evidenceEntityType',
        {
            key: 'entityId',
            validators: {
                requiredError: requiredString,
                mustBeIntsError: mustBeInts,
            },
        },
    ]),
    convertFromFormModel({ rmsEntityType, cadEntityType, evidenceEntityType, entityId } = {}) {
        const entityType = rmsEntityType || cadEntityType || evidenceEntityType;

        let module;
        if (rmsEntityType) {
            module = 'RMS';
        } else if (cadEntityType) {
            module = 'CAD';
        } else if (evidenceEntityType) {
            module = 'EVIDENCE';
        }

        return { entityType, module, entityId };
    },
});

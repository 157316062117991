import _, { get, parseInt } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { cadCallTakerStationsSelector } from '~/client-common/core/domain/cad-call-taker-stations/state/data';
import {
    processHistoryEvent,
    processChangeSet,
} from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { currentDepartmentDateFormatsSelector } from '~/client-common/core/domain/current-user/state/ui';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { routeNameSelector } from '../../../../../routing/routerModule';
import routesConfig from '../../../../../routing/routesConfig';
import cadCallTakerStationsAdminForm from '../forms/cadCallTakerStationsAdminForm';
import {
    LOAD_CAD_CALL_TAKER_STATIONS_START,
    LOAD_CAD_CALL_TAKER_STATIONS_SUCCESS,
    LOAD_CAD_CALL_TAKER_STATIONS_FAILURE,
    SAVE_CAD_CALL_TAKER_STATION_START,
    SAVE_CAD_CALL_TAKER_STATION_SUCCESS,
    SAVE_CAD_CALL_TAKER_STATION_FAILURE,
    FETCH_CAD_CALL_TAKER_STATION_HISTORY_SUCCESS,
    FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_SUCCESS,
} from '../data';
import authActionTypes from '../../../../../legacy-redux/actions/types/authActionTypes';

import { formatUserByIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';

const { BOOTSTRAP_SUCCESS } = authActionTypes;

const SELECT_CAD_CALL_TAKER_STATION_START =
    'cad-call-taker-stations/SELECT_CAD_CALL_TAKER_STATION_START';
const SELECT_CAD_CALL_TAKER_STATION_SUCCESS =
    'cad-call-taker-stations/SELECT_CAD_CALL_TAKER_STATION_SUCCESS';
const SELECT_CAD_CALL_TAKER_STATION_FAILURE =
    'cad-call-taker-stations/SELECT_CAD_CALL_TAKER_STATION_FAILURE';
const OPEN_NEW_CAD_CALL_TAKER_STATION_FORM =
    'cad-call-taker-stations/OPEN_NEW_CAD_CALL_TAKER_STATION_FORM';

function selectCadCallTakerStationStart() {
    return {
        type: SELECT_CAD_CALL_TAKER_STATION_START,
    };
}

function selectCadCallTakerStationSuccess(id) {
    return {
        type: SELECT_CAD_CALL_TAKER_STATION_SUCCESS,
        payload: id,
    };
}

function selectCadCallTakerStationFailure() {
    return {
        type: SELECT_CAD_CALL_TAKER_STATION_FAILURE,
    };
}

export function selectCadCallTakerStation(id) {
    return (dispatch, getState) => {
        dispatch(selectCadCallTakerStationStart());
        const state = getState();
        const cadCallTakerStation = cadCallTakerStationsSelector(state)[id];

        if (cadCallTakerStation) {
            dispatch(cadCallTakerStationsAdminForm.actionCreators.change({ cadCallTakerStation }));
            dispatch(selectCadCallTakerStationSuccess(id));
        } else {
            // TODO: remove this crappy interval when migrating the router, this is
            // a workaround for onEnter/onLeave hooks not respecting parent/child relationships
            let attempts = 0;

            const intervalId = window.setInterval(() => {
                const state = getState();
                const cadCallTakerStation = cadCallTakerStationsSelector(state)[id];

                if (cadCallTakerStation) {
                    window.clearInterval(intervalId);
                    dispatch(
                        cadCallTakerStationsAdminForm.actionCreators.change({ cadCallTakerStation })
                    );
                    dispatch(selectCadCallTakerStationSuccess(id));
                }

                attempts++;
                if (attempts > 300) {
                    window.clearInterval(intervalId);

                    return dispatch(
                        selectCadCallTakerStationFailure('Failed to get cad call-taker-station.')
                    );
                }
            }, 1000);
        }
    };
}

export function openNewCadCallTakerStationForm() {
    return {
        type: OPEN_NEW_CAD_CALL_TAKER_STATION_FORM,
    };
}

const initialUiState = {
    selectedCadCallTakerStationId: null,
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    cadCallTakerStationLoadError: null,
    histories: {},
    allHistories: [], // this is a stop-gap solution. Normally we would normalize all of these by call-taker-station id or
    // but there is no consistent way to discern the call-taker-station id in history objects.
};

export default function cadCallTakerStationsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case BOOTSTRAP_SUCCESS:
            return {
                ...state,
            };
        case FETCH_CAD_CALL_TAKER_STATION_HISTORY_SUCCESS:
            return {
                ...state,
                histories: {
                    ...state.histories,
                    [action.payload.callTakerStationId]: action.payload.histories,
                },
            };
        case FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_SUCCESS:
            return {
                ...state,
                allHistories: action.payload.histories,
            };
        case LOAD_CAD_CALL_TAKER_STATIONS_START:
            return {
                ...state,
                loadingList: true,
                selectedCadCallTakerStationId: null,
                listError: null,
            };
        case LOAD_CAD_CALL_TAKER_STATIONS_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_CAD_CALL_TAKER_STATIONS_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case SAVE_CAD_CALL_TAKER_STATION_START:
            return {
                ...state,
                submittingForm: true,
            };
        case SAVE_CAD_CALL_TAKER_STATION_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case SAVE_CAD_CALL_TAKER_STATION_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_CAD_CALL_TAKER_STATION_SUCCESS:
            return {
                ...state,
                cadCallTakerStationLoadError: null,
                selectedCadCallTakerStationId: parseInt(action.payload),
            };
        case SELECT_CAD_CALL_TAKER_STATION_FAILURE:
            return {
                ...state,
                cadCallTakerStationLoadError: action.payload,
            };
        case OPEN_NEW_CAD_CALL_TAKER_STATION_FORM:
            return {
                ...state,
                formError: null,
                cadCallTakerStationLoadError: null,
                selectedCadCallTakerStationId: null,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.cadCallTakerStationsAdmin;

export const cadCallTakerStationViewModelByIdSelector = createSelector(
    cadCallTakerStationsSelector,
    (cadCallTakerStations) => (id) =>
        cadCallTakerStations[id]
            ? {
                  ...cadCallTakerStations[id],
              }
            : undefined
);

// list of cad callTakerStations
export const callTakerStationsListItemsSelector = createSelector(
    cadCallTakerStationsSelector,
    uiSelector,
    cadCallTakerStationViewModelByIdSelector,
    routeNameSelector,
    (cadCallTakerStations, ui, cadCallTakerStationViewModelById, routeName) => {
        return _(cadCallTakerStations)
            .map((callTakerStation) => {
                const historyView =
                    routeName === routesConfig.ADMIN_CAD_CALL_TAKER_STATIONS_HISTORY.name ||
                    routeName === routesConfig.ADMIN_CAD_ALL_CALL_TAKER_STATIONS_HISTORY.name;

                const path = `/admin/cad/call-taker-stations/${callTakerStation.id}${
                    historyView ? '/history' : ''
                }`;
                return {
                    path,
                    title: `${callTakerStation.displayValue}`,
                    key: callTakerStation.id,
                    status: get(cadCallTakerStationViewModelById(callTakerStation.id), 'status'),
                    selected: callTakerStation.id === ui.selectedCadCallTakerStationId,
                };
            })
            .sortBy('title')
            .value();
    }
);

export const historiesForCadCallTakerStationSelector = createSelector(
    uiSelector,
    formatAttributeByIdSelector,
    formatUserByIdSelector,
    getAttributeByIdSelector,
    currentDepartmentDateFormatsSelector,
    (ui, formatAttributeById, userById, getAttributeById, dateTimeFormats) => (id) => {
        const histories = id ? ui.histories[id] : ui.allHistories;

        const displayDate = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDate);
            }
            return '';
        };

        const displayDateTime = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDateTime);
            }
            return '';
        };

        const args = {
            formatAttributeById,
            userById,
            displayDate,
            displayDateTime,
            attributeById: getAttributeById,
        };

        return _.map(_.sortBy(histories, 'timestampUtc'), (history) => {
            const processed = processHistoryEvent(history, args);
            const newObj = {
                ...processed,
                user: userById(processed.changedBy),
                changes: _.map(processed.changeSet, (changeSet) =>
                    processChangeSet(changeSet, args, processed)
                ),
            };

            return newObj;
        }).reverse();
    }
);

export const hasImportExportCallTakerStationPermissions = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.CAD.GPS_CONFIG_IMPORT)
);

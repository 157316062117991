import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { RmsDispatch } from '../../../core/typings/redux';
import { NIBRS_COMPLIANCE_TYPE } from '../configuration';
import complianceExportResource from '../resources/complianceExportResource';
import nibrsExportResource from '../resources/nibrsExportResource';
import { uiStoreLastExportedDate } from '../state/ui';
import { ComplianceExportType } from '../types';

export function useGetLastExportedDate(complianceExportType?: ComplianceExportType) {
    const dispatch = useDispatch<RmsDispatch>();

    const resource = useCallback(
        (agencyId: number) => {
            if (!complianceExportType) {
                return Promise.reject('complianceExportType is undefined');
            }

            if (complianceExportType.name === NIBRS_COMPLIANCE_TYPE.name) {
                return nibrsExportResource.getLastExportMonthForAgencyId(agencyId);
            }

            return complianceExportResource.getComplianceLastExportedDate({
                agencyId,
                complianceExportType: complianceExportType.name,
            });
        },
        [complianceExportType]
    );
    const onSuccess = useCallback(
        (lastExportMonth: string) => {
            dispatch(uiStoreLastExportedDate(lastExportMonth));
        },
        [dispatch]
    );

    const { callResource, loading } = useResourceDeferred(resource, onSuccess);

    return { callResource, loading };
}

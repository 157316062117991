import { RefContextEnum } from '@mark43/rms-api';

import { createFormConfiguration, createNItems, createFieldset, createField } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import formsRegistry from '../../../../../core/formsRegistry';

export const formName = RefContextEnum.FORM_BOOKING_SIDE_PANEL.name;
export const getBookingSidePanelForm = () => formsRegistry.get(formName);

export const keysToResetOnDispositionAttributeChange = [
    'collateralBondAmountPaid',
    'collateralBondReceiptNum',
    'lockupDateUtc',
    'lockupLocation',
    'lockupNumber',
];

export default createFormConfiguration({
    charges: createNItems({
        fields: {
            dispositionAttrId: createField<number>({
                fieldName: fields.CHARGE_DISPOSITION_ATTR_ID,
            }),
            dispositionDateUtc: createField<string>({
                fieldName: fields.CHARGE_DISPOSITION_DATE_UTC,
            }),
            juvenileDispositionAttrId: createField<number>({
                fieldName: fields.CHARGE_JUVENILE_DISPOSITION_ATTR_ID,
            }),
            juvenileDispositionDateUtc: createField<string>({
                fieldName: fields.CHARGE_JUVENILE_DISPOSITION_DATE_UTC,
            }),
            collateralBondAmountPaid: createField<string>({
                fieldName: fields.CHARGE_COLLATERAL_BOND_AMOUNT_PAID,
            }),
            collateralBondReceiptNum: createField<string>({
                fieldName: fields.CHARGE_COLLATERAL_BOND_RECEIPT_NUM,
            }),
        },
    }),
    arrest: createFieldset({
        fields: {
            lockupNumber: createField<string>({
                fieldName: fields.ARREST_LOCKUP_NUMBER,
            }),
            afisNumber: createField<string>({
                fieldName: fields.ARREST_AFIS_NUMBER,
            }),
            lockupLocation: createField<string>({
                fieldName: fields.ARREST_LOCKUP_LOCATION,
            }),
            lockupDateUtc: createField<string>({
                fieldName: fields.ARREST_LOCKUP_DATE_UTC,
            }),
            // the arrestDateUtc field does not show up in the booking form UI,
            // but is data necessary for validation.
            // (Juvenile or Arrest) Disposition date cannot be before arrest date
            arrestDateUtc: createField<string>({
                fieldName: fields.ARREST_ARREST_DATE_UTC,
            }),
            juvenileTriedAsAdult: createField<boolean>({
                fieldName: fields.ARREST_JUVENILE_TRIED_AS_ADULT,
            }),
            juvenileTriedAsAdultNotes: createField<string>({
                fieldName: fields.ARREST_JUVENILE_TRIED_AS_ADULT_NOTES,
            }),
        },
    }),
    defendantProfile: createFieldset({
        fields: {
            // the personProfileIsJuvenile fields does not show up in the booking form UI,
            // but is data necessary for hide/show rule.
            personProfileIsJuvenile: createField<boolean>({
                fieldName: fields.PERSON_PROFILE_IS_JUVENILE,
            }),
            personProfileAge: createField<number>({
                fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_AGE,
            }),
            personProfileAgeMin: createField<number>({
                fieldName: fields.PERSON_PROFILE_AGE_MIN,
            }),
        },
    }),
    courtCases: createNItems({
        fields: {
            courtDateUtc: createField<string>({
                fieldName: fields.COURT_CASE_COURT_DATE_UTC,
            }),
            courtRoomNumber: createField<string>({
                fieldName: fields.COURT_CASE_COURT_ROOM_NUMBER,
            }),
        },
    }),
});

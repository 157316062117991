import { RefContextEnum } from '@mark43/rms-api';
import {
    createWarrantActivity,
    updateWarrantActivity,
} from '~/client-common/core/domain/warrant-activities/state/data';
import { NEXUS_STATE_PROP as WARRANT_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrant-statuses/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { saveBoxFailure, closeBox, openBox } from '../../../../../legacy-redux/actions/boxActions';

export const WARRANT_ACTIVITY_BOX_CONTEXT = {
    name: boxEnum.WARRANT_ACTIVITY_SIDE_PANEL,
};

export function openWarrantActivitySidePanel(warrantActivity) {
    return (dispatch, getState, { formsRegistry }) => {
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_WARRANT_ACTIVITY.name,
            undefined,
            (form) => {
                form.set('', warrantActivity);
            }
        );
        dispatch(
            openBox(WARRANT_ACTIVITY_BOX_CONTEXT, {
                warrantActivityId: warrantActivity.id,
                warrantId: warrantActivity.warrantId,
            })
        );
    };
}

export function submitWarrantActivityForm({ warrantId, warrantActivityId } = {}) {
    return (dispatch, getState, { formsRegistry, nexus }) => {
        const form = formsRegistry.get(RefContextEnum.FORM_WARRANT_ACTIVITY.name);

        return (
            form
                .submit()
                .then((result) => {
                    const warrantActivity = {
                        ...result.form.getState().model,
                        warrantId,
                        warrantActivityId,
                    };
                    if (!warrantActivityId) {
                        // call create and trigger status
                        return dispatch(createWarrantActivity(warrantActivity))
                            .then((warrantActivityBundle) => {
                                const data = {};
                                if (warrantActivityBundle.warrantStatus) {
                                    data[ATTRIBUTES_NEXUS_STATE_PROP] = [
                                        convertAttributeToAttributeView(
                                            warrantActivityBundle.warrantStatusAttribute
                                        ),
                                    ];
                                    data[WARRANT_STATUSES_NEXUS_STATE_PROP] = [
                                        warrantActivityBundle.warrantStatus,
                                    ];
                                }

                                dispatch(
                                    nexus.withEntityItems(
                                        data,
                                        closeBox(WARRANT_ACTIVITY_BOX_CONTEXT)
                                    )
                                );
                                form.resetModel();
                            })
                            .catch((err) =>
                                dispatch(saveBoxFailure(WARRANT_ACTIVITY_BOX_CONTEXT, err.message))
                            );
                    } else {
                        // call normal update
                        return dispatch(updateWarrantActivity(warrantActivity))
                            .then(() => {
                                dispatch(closeBox(WARRANT_ACTIVITY_BOX_CONTEXT));
                                form.resetModel();
                            })
                            .catch((err) =>
                                dispatch(saveBoxFailure(WARRANT_ACTIVITY_BOX_CONTEXT, err.message))
                            );
                    }
                })
                // purposely use the generic validation string because otherwise the message will cover the form
                .catch((err) => {
                    // Catch errors from different thenables and handle them appropriately
                    const errorMessage = err.validationResult
                        ? err.validationResult.formErrors
                        : err.message;

                    dispatch(saveBoxFailure(WARRANT_ACTIVITY_BOX_CONTEXT, errorMessage));
                })
        );
    };
}

import React from 'react';
import { Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';

type Props = {
    imageUrl: string;
    height: number;
    width: number;
};

/*

The difference between this component and the Image component are the following:

1. You can only have one Background component on the canvas. These represents the templates that
   you are using to convey which type of intersection the crash happened. (T-Intersection, etc).
2. The Background should not have listen to any events. We force this by setting the listening prop to false as shown below.
3. The Background component MUST be the first child rendered on the canvas. This is to ensure that the background is ALWAYS at the
   bottom of the stack allowing subsequent widgets (images/texts) to be added on top of it.
4. A background image is NOT a widget. A widget is responsible for handling user interaction events (drag/drop/move/rotate/resize), whereas
   a background is just an static image representing the type of intersection the crash belongs to.

The differences above is the reason we've decided to keep Background as a separate component from the Image component.
*/
export const Background: React.FC<Props> = ({ imageUrl, height, width }) => {
    const [image] = useImage(imageUrl);
    if (!image) {
        return null;
    }

    const fillPatternScaleY = height / image.height;
    const fillPatternScaleX = width / image.width;

    return (
        <KonvaImage
            id="background-image"
            image={image}
            width={width}
            height={height}
            fillPatternRepeat="no-repeat"
            fillPatternScaleY={fillPatternScaleY}
            fillPatternScaleX={fillPatternScaleX}
            listening={false}
        />
    );
};

import { isFunction, omit } from 'lodash';
import React from 'react';
import { withState } from 'recompose';
import classNames from 'classnames';

import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';
import ExpandCollapseButton from '../../../../legacy-redux/components/core/ExpandCollapseButton';

const propsToOmitFromDOMNode = ['scrollValue', 'setScroll', 'windowHeight', 'dispatch'];

const FormSection = clippable({
    // 35px bottom padding of form section
    // + 31px top margin of clipped title
    // + 32px height of a single-line title
    // (this last number can be adjusted and really should depend on the height
    // of the title instead)
    bottomBuffer: 98,
})(function FormSection({
    clippable: { clipConstantOffset, isClippedTop = false, clipAtBottomLimit } = {},
    title,
    children,
    collapsible = false,
    collapsed,
    setCollapsed,
    className,
    titleWidth,
    contentWidth,
    ...otherProps
}) {
    // clipping only happens when there's a title
    const clippableClassNames = classNames({
        'clipped-top': title && isClippedTop,
        'at-bottom-limit': title && clipAtBottomLimit,
    });

    const renderedChildren = isFunction(children) ? children(collapsed, setCollapsed) : children;

    const content = title
        ? [
              <h3
                  key={0}
                  className={classNames('react-form-section-title', clippableClassNames)}
                  style={{
                      top: title && isClippedTop ? clipConstantOffset : 'auto',
                      width: titleWidth,
                  }}
              >
                  {title}
              </h3>,
              <div
                  key={1}
                  className={classNames('react-form-section-content', clippableClassNames)}
                  style={{ width: contentWidth }}
              >
                  {renderedChildren}
              </div>,
          ]
        : renderedChildren;

    const expandCollapseButton = collapsible && (
        <ExpandCollapseButton collapsed={collapsed} setCollapsed={setCollapsed} />
    );

    return (
        <div
            {...omit(otherProps, propsToOmitFromDOMNode)}
            className={classNames(
                'react-form-section',
                {
                    collapsible,
                },
                className
            )}
        >
            {content}
            {expandCollapseButton}
        </div>
    );
});

/**
 * A section in a form with an optional title at the left and an optional toggle
 *   button at the right to expand/collapse the section. The title clips to the
 *   top of the viewport when scrolling down the section, which is useful when
 *   the section is tall. Sections are stacked vertically and separated by
 *   horizontal rules. Not to be confused with `Fieldset`, which is a group of
 *   inputs within a section.
 * @param {string}                  [props.title]
 * @param {ReactElement[]|function} props.children Optionally a function that
 *   receives `(collapsed, setCollapsed)` for managing the expanded/collapsed
 *   state.
 * @param {number}                  [props.titleWidth] Width of the title
 *   column.
 * @param {number}                  [props.contentWidth] Width of the content
 *   column.
 */
export default withState(
    'collapsed',
    'setCollapsed',
    ({ collapsible }) => !!collapsible
)(FormSection);

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterMenuRenderProps, FilterMenuList } from 'arc';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { userOptionsSelector } from '../../../../legacy-redux/selectors/userSelectors';
import { searchUsers } from '../../../../legacy-redux/actions/userActions';

export const UserSelectFilter: React.FC<FilterMenuRenderProps> = ({
    appliedOptions,
    setAppliedFilters,
}) => {
    const usersOptions = useSelector(userOptionsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        const now = nowUtc();
        dispatch(searchUsers('', { effectiveDate: now }));
    }, [dispatch]);

    const users = usersOptions([], { includeOther: false, effectiveDate: nowUtc() });

    const filterListOptions = users.map((item, index) => {
        return {
            label: String(item.display),
            value: String(item.value),
            id: index + 1,
        };
    });

    return (
        <FilterMenuList
            options={filterListOptions}
            onSelect={setAppliedFilters}
            appliedOptions={appliedOptions}
            selectionType="multiple"
        />
    );
};

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';

import { hydratedBehavioralCrisisByReportIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { formatLinkAttributesSelector } from '~/client-common/core/domain/attributes/state/data';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';

const mapStateToProps = createStructuredSelector({
    hydratedBehavioralCrisisByReportId: hydratedBehavioralCrisisByReportIdSelector,
    formatLinkAttributes: formatLinkAttributesSelector,
});

const BehavioralCrisisCardSummary = connect(mapStateToProps)(
    ({ reportId, hydratedBehavioralCrisisByReportId, formatLinkAttributes }) => {
        const {
            behavioralCrisis: {
                crisisContactStartDateUtc,
                crisisContactEndDateUtc,
                wasCitOfficerRequested,
                wasCitOfficerDispatched,
                didCitOfficerArrive,
            },
            reportAttributes,
        } = hydratedBehavioralCrisisByReportId(reportId);

        return (
            <SummaryList labelWidth={160} contentWidth={380}>
                <CardSection>
                    <SummaryRowDate
                        fieldName={fields.BEHAVIORAL_CRISIS_CRISIS_CONTACT_START_DATE_UTC}
                        date={crisisContactStartDateUtc}
                    />
                    <SummaryRowDate
                        fieldName={fields.BEHAVIORAL_CRISIS_CRISIS_CONTACT_END_DATE_UTC}
                        date={crisisContactEndDateUtc}
                    />
                    <SummaryRow fieldName={fields.BEHAVIORAL_CRISIS_WAS_CIT_OFFICER_REQUESTED}>
                        {booleanToYesNo(wasCitOfficerRequested)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.BEHAVIORAL_CRISIS_WAS_CIT_OFFICER_DISPATCHED}>
                        {booleanToYesNo(wasCitOfficerDispatched)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.BEHAVIORAL_CRISIS_DID_CIT_OFFICER_ARRIVE}>
                        {booleanToYesNo(didCitOfficerArrive)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EXHIBITING_BEHAVIOR_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.EXHIBITING_BEHAVIOR.name
                        )}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.DISPLAY_BEHAVIORAL_CRISIS_REPORT_WEAPON_OR_FORCE_INVOLVED}
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.WEAPON_INVOLVED.name
                        )}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CRISIS_TECHNIQUES_USED_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.BEHAVIORAL_CRISIS_TECHNIQUES_USED.name
                        )}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CRISIS_DISPOSITION_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.BEHAVIORAL_CRISIS_DISPOSITION.name
                        )}
                    </SummaryRow>
                </CardSection>
            </SummaryList>
        );
    }
);

export default BehavioralCrisisCardSummary;

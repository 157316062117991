import { compact } from 'lodash';
import { AttributeView, CoreModelEnum, RefContextEnum, ReportAttribute } from '@mark43/rms-api';
import { createField, createFormConfiguration, InferFormDataShape } from 'markformythree';

import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import { reportAttributesSelector } from '~/client-common/core/domain/report-attributes/state/data';
import {
    CrashDetailCardBundleForUpsert,
    crashDetailsWhereSelector,
    CrashDetailWithCustomProperties,
} from '~/client-common/core/domain/crash-details/state/data';
import { WithCustomProperties } from '~/client-common/core/domain/dynamic-fields/types';
import { convertScreamingSnakeToCamelCase } from '~/client-common/helpers/stringHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { ModuleShape } from '~/client-common/redux/state';

import formsRegistry from '../../../../../core/formsRegistry';
import { RmsAction } from '../../../../../core/typings/redux';
import { buildFormConfigurationForCoreModel } from '../../../../core/markformythree-arbiter/dynamic-fields/helpers/buildFormConfigurationForCoreModel';
import { CUSTOM_PROPERTIES_KEY_NAME } from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';
import { BuildFormConfigurationForCoreModelAdditionalData } from '../../../../core/markformythree-arbiter/dynamic-fields/types';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { CRASH_EVENT_INFO_REPORT_ATTRIBUTE_TYPES } from '../ui/crashEventInfo';

export const formName = RefContextEnum.FORM_CRASH_EVENT_INFO.name;
const getTrafficCrashEventInfoForm = () => formsRegistry.get(formName);

const ATTR_IDS_SUFFIX = 'AttrIds';
const OTHER_DESCRIPTION_SUFFIX = 'OtherDescription';

const crashDetailModelFields = {
    id: createField<number>({}),
    crashEventDateUtc: createField<string>({ fieldName: fields.CRASH_DETAIL_CRASH_EVENT_DATE_UTC }),
    isTemporaryTrafficControl: createField<boolean>({
        fieldName: fields.CRASH_DETAIL_IS_TEMPORARY_TRAFFIC_CONTROL,
    }),
};

const reportAttributeAttrIdFields = {
    weatherAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEATHER_ATTRIBUTE_ID,
    }),
    qcRoadwaySurfaceConditionAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_ROADWAY_SURFACE_CONDITION_ATTRIBUTE_ID,
    }),
    qcLightConditionAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_LIGHT_CONDITION_ATTRIBUTE_ID,
    }),
    qcCrashSeverityAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_CRASH_SEVERITY_ATTRIBUTE_ID,
    }),
    crashFixedObjectTypeAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_FIXED_OBJECT_TYPE_ATTRIBUTE_ID,
    }),
    qcFirstHarmfulEventAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_FIRST_HARMFUL_EVENT_ATTRIBUTE_ID,
    }),
    crashContributingFactorAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_CONTRIBUTING_FACTOR_ATTRIBUTE_ID,
    }),
    crashMunicipalityCodeAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_MUNICIPALITY_CODE_ATTRIBUTE_ID,
    }),
};

const reportAttributeDescriptionFields = {
    weatherOtherDescription: createField<string>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEATHER_DESCRIPTION,
    }),
    qcRoadwaySurfaceConditionOtherDescription: createField<string>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_ROADWAY_SURFACE_CONDITION_DESCRIPTION,
    }),
    qcLightConditionOtherDescription: createField<string>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_LIGHT_CONDITION_DESCRIPTION,
    }),
    qcCrashSeverityOtherDescription: createField<string>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_CRASH_SEVERITY_DESCRIPTION,
    }),
    crashFixedObjectTypeOtherDescription: createField<string>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_FIXED_OBJECT_TYPE_DESCRIPTION,
    }),
    qcFirstHarmfulEventOtherDescription: createField<string>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_FIRST_HARMFUL_EVENT_DESCRIPTION,
    }),
    crashContributingFactorOtherDescription: createField<string>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_CONTRIBUTING_FACTOR_DESCRIPTION,
    }),
    crashMunicipalityCodeOtherDescription: createField<string>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_MUNICIPALITY_CODE_DESCRIPTION,
    }),
};

export const reportAttributeFields = {
    ...reportAttributeAttrIdFields,
    ...reportAttributeDescriptionFields,
};

export const createCrashEventInfoFormConfiguration = (
    additionalData: BuildFormConfigurationForCoreModelAdditionalData
) =>
    createFormConfiguration({
        ...crashDetailModelFields,
        ...reportAttributeFields,
        ...buildFormConfigurationForCoreModel({
            keyName: CUSTOM_PROPERTIES_KEY_NAME,
            context: formName,
            coreModelName: CoreModelEnum.CRASH_DETAIL.name,
            additionalData,
        }),
    });

export type CrashEventInfoFormConfiguration = ReturnType<
    typeof createCrashEventInfoFormConfiguration
>;
type CrashEventInfoFormDataShape = WithCustomProperties<
    InferFormDataShape<CrashEventInfoFormConfiguration>
>;

const formatCrashEventInfoReportAttributes = (
    attributes: ModuleShape<AttributeView>,
    crashEventInfoFormModel: CrashEventInfoFormDataShape,
    reportId: number
) => {
    return compact(
        CRASH_EVENT_INFO_REPORT_ATTRIBUTE_TYPES.flatMap((attributeType) => {
            const camelCaseName = convertScreamingSnakeToCamelCase(attributeType);
            const attrIdsKey =
                `${camelCaseName}${ATTR_IDS_SUFFIX}` as keyof typeof reportAttributeAttrIdFields;
            const attrIds = crashEventInfoFormModel[attrIdsKey];
            if (!attrIds) {
                return undefined;
            }
            const descriptionKey =
                `${camelCaseName}${OTHER_DESCRIPTION_SUFFIX}` as keyof typeof reportAttributeDescriptionFields;
            const description = crashEventInfoFormModel[descriptionKey] || '';
            const reportAttrIds = Array.isArray(attrIds) ? attrIds : [attrIds];
            return assignDescriptionToReportAttributes(
                reportAttrIds.map((attributeId) => ({
                    reportId,
                    attributeType,
                    attributeId,
                })),
                attributes,
                description
            );
        })
    );
};

export const buildCrashEventInfoFormModel = (): RmsAction<void> => (dispatch, getState) => {
    const state = getState();
    const form = getTrafficCrashEventInfoForm();
    const reportId = currentReportIdSelector(state);
    if (!form || !reportId) {
        return;
    }
    const crashDetail = crashDetailsWhereSelector(state)({ reportId })[0] || {};
    const reportAttributes = reportAttributesSelector(state);
    const formModel = convertToFormModel(crashDetail, reportAttributes, reportId);
    form.set('', formModel);
};

export const convertToFormModel = (
    crashDetail: CrashDetailWithCustomProperties,
    reportAttributes: ReportAttribute[] = [],
    reportId: number
) => {
    const { id, crashEventDateUtc, isTemporaryTrafficControl } = crashDetail;
    const mappedReportAttributes = CRASH_EVENT_INFO_REPORT_ATTRIBUTE_TYPES.reduce<
        Record<string, number[] | string>
    >((accumulator, attributeType) => {
        const reportAttrIdsAndDescription = reportAttributes.reduce<{
            attrIds: number[];
            description: string;
        }>(
            (acc, reportAttribute) => {
                if (
                    reportAttribute.reportId !== reportId ||
                    reportAttribute.attributeType !== attributeType
                ) {
                    return acc;
                }
                acc.attrIds.push(reportAttribute.attributeId);
                if (reportAttribute.description) {
                    acc.description = reportAttribute.description;
                }
                return acc;
            },
            { attrIds: [], description: '' }
        );
        if (!reportAttrIdsAndDescription.attrIds.length) {
            return accumulator;
        }
        const basePropertyName = convertScreamingSnakeToCamelCase(attributeType);
        accumulator[`${basePropertyName}${ATTR_IDS_SUFFIX}`] = reportAttrIdsAndDescription.attrIds;
        if (reportAttrIdsAndDescription.description) {
            accumulator[`${basePropertyName}${OTHER_DESCRIPTION_SUFFIX}`] =
                reportAttrIdsAndDescription.description;
        }
        return accumulator;
    }, {});
    return {
        [CUSTOM_PROPERTIES_KEY_NAME]: crashDetail.customProperties,
        id,
        crashEventDateUtc,
        isTemporaryTrafficControl,
        ...mappedReportAttributes,
    };
};

export const convertFromFormModel = (
    crashEventInfoFormModel: CrashEventInfoFormDataShape,
    currentReportId: number,
    attributes: ModuleShape<AttributeView>
): CrashDetailCardBundleForUpsert => {
    const { crashEventDateUtc, id, isTemporaryTrafficControl, customProperties } =
        crashEventInfoFormModel;
    const reportAttributes = formatCrashEventInfoReportAttributes(
        attributes,
        crashEventInfoFormModel,
        currentReportId
    );
    return {
        crashDetail: {
            id,
            crashEventDateUtc,
            isTemporaryTrafficControl: !!isTemporaryTrafficControl,
            reportId: currentReportId,
            customProperties,
        },
        reportAttributes,
    };
};

import { map } from 'lodash';

import { Mark43Enum, rejectUnknownEnumValue } from '~/client-common/helpers/enumHelpers';
import { prettify } from '~/client-common/helpers/stringHelpers';

/**
 * Options for a `Select` dropdown with non-legacy `SelectOptions`.
 */
const getArcSelectOptionsFromStrings = (strings: string[]) => {
    return map(strings, (str) => ({
        label: str,
        value: str,
    }));
};

export const getArcSelectOptionsFromEnum = (mark43Enum: Mark43Enum) => {
    return getArcSelectOptionsFromStrings(map(rejectUnknownEnumValue(mark43Enum), 'name'));
};

/**
 * Options for a `Select` dropdown with `LegacySelectOptions`.
 */
export const getDropdownOptionsFromStrings = (strings: string[], prettifyLabel = false) => {
    return map(strings, (str) => ({
        display: prettifyLabel ? prettify(str) : str,
        value: str,
    }));
};

/**
 * Options for a `Select` dropdown with `LegacySelectOptions`.
 */
export const getDropdownOptionsFromObject = (displays: { [key: string]: string }) => {
    return map(displays, (display, value) => ({
        display,
        value,
    }));
};

export const getDropdownOptionsFromEnum = (
    mark43Enum: Mark43Enum,
    key = 'name',
    prettifyLabel = false
) => {
    return getDropdownOptionsFromStrings(
        map(rejectUnknownEnumValue(mark43Enum), key),
        prettifyLabel
    );
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formatStaticMapUrl } from '~/client-common/core/domain/elastic-involved-locations/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { Map } from '~/client-common/core/maps';

import createElement from '../../../core/utils/recompose.createElement';
import LocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/LocationCell';

function EntityProfileLocationSummary({ elasticLocation, applicationSettings }) {
    let staticMap = null;
    const hasLocation = elasticLocation && elasticLocation.locationAddress;
    const hasLatLng =
        hasLocation &&
        typeof elasticLocation.locationAddress?.latitude === 'number' &&
        typeof elasticLocation.locationAddress?.longitude === 'number';
    if (hasLocation) {
        if (applicationSettings.ESRI_MAPS_ENABLED && hasLatLng) {
            staticMap = (
                <Map
                    defaultCenter={{
                        lat: elasticLocation.locationAddress?.latitude,
                        lng: elasticLocation.locationAddress?.longitude,
                    }}
                    selectedLocation={elasticLocation.locationAddress}
                    width={199}
                    height={199}
                />
            );
        } else {
            // use of `createElement` is due to the fact that all `Symbol` properties
            // of an object are lost when it's "copied" (via spread syntax). This is an intended
            // effect of spread syntax / for in
            staticMap = <img src={formatStaticMapUrl(elasticLocation.locationAddress)} />;
        }
    } else {
        staticMap = <img className="map-stub-image" src="images/entity-profiles/locations.png" />;
    }
    return (
        <div className="entity-profile-entity-summary entity-profile-location-summary">
            <div className="entity-profile-summary-sibling map">{staticMap} </div>
            <div className="entity-profile-summary-sibling location-cell">
                {createElement(LocationCell, { ...elasticLocation })}
            </div>
        </div>
    );
}

export default connect(
    createStructuredSelector({
        applicationSettings: applicationSettingsSelector,
    })
)(EntityProfileLocationSummary);

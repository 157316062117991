/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
    ReportDefinition,
    ReportDefinitionResource,
    ReportDefinitionBundle,
    Downloadable,
    ReportDefaultRoleLink,
    ReportCreationRolePermission,
    ReportCard,
} from '@mark43/rms-api';
import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Custom Reports Admin Resource',
    methods: {
        createReportDefinition(reportDefinition: ReportDefinition) {
            return req<ReportDefinitionResource.CreateReportDefinition>({
                method: 'POST',
                url: 'reports/definition',
                data: reportDefinition,
            });
        },

        updateReportDefinition(reportDefinition: ReportDefinition) {
            return req<ReportDefinitionResource.UpdateReportDefinition>({
                method: 'PUT',
                url: 'reports/definition',
                data: reportDefinition,
            });
        },

        getReportDefinitions({ excludeCps }: { excludeCps?: boolean }) {
            return req<ReportDefinitionResource.GetReportDefinitions>({
                method: 'GET',
                url: 'reports/definition',
                params: {
                    hydrate_dynamic_cards: true,
                    exclude_cps: excludeCps,
                },
            });
        },

        getCreateReportModalCreatableReportDefinitions() {
            return req<ReportDefinitionResource.GetCreatableReportDefinitionsForCreateReportModal>({
                method: 'GET',
                url: 'reports/definition/creatable',
            });
        },

        getReportDefinition(
            id: number,
            hydrateDynamicCards: boolean = true,
            includeCards: boolean = true,
            includeHiddenCards: boolean = true
        ) {
            return req<ReportDefinitionResource.GetReportDefinition>({
                method: 'GET',
                url: `reports/definition/${id}/`,
                params: {
                    hydrate_dynamic_cards: hydrateDynamicCards,
                    include_cards: includeCards,
                    include_hidden_cards: includeHiddenCards,
                },
            });
        },

        upsertReportDefinitionBundle(reportDefinitionBundle: ReportDefinitionBundle) {
            return req<ReportDefinitionResource.UpsertReportDefinition>({
                method: 'POST',
                url: `reports/definition/upsert`,
                data: reportDefinitionBundle,
            });
        },

        upsertDownloadables(downloadables: Downloadable[], reportCardId: number) {
            return req<ReportDefinitionResource.UpsertDownloadables>({
                method: 'POST',
                url: `reports/definition/cards/${reportCardId}/downloadables`,
                data: downloadables,
            });
        },

        getReportDefaultRoleLinksByDeptId(departmentId: number) {
            return req<ReportDefinitionResource.GetDefaultRoleLinksByDeptId>({
                method: 'GET',
                url: `reports/definition/links/roles/dept/${departmentId}/`,
            });
        },

        getReportDefaultRoleLinks(reportCardId: number) {
            return req<ReportDefinitionResource.GetDefaultRoleLinksByReportDefinitionId>({
                method: 'GET',
                url: `reports/definition/${reportCardId}/links/roles`,
            });
        },

        setReportDefaultRoleLinks(
            reportCardId: number,
            links: ReportDefaultRoleLink[],
            removeExisting: boolean = true
        ) {
            return req<ReportDefinitionResource.UpsertDefaultRoleLinksForReportDefinitionId>({
                method: 'PUT',
                url: `reports/definition/${reportCardId}/links/roles`,
                params: {
                    remove_existing: removeExisting,
                },
                data: links,
            });
        },

        getReportCreationRolePermissionsByDeptId(departmentId: number) {
            return req<ReportDefinitionResource.GetRolePermissionsByDeptId>({
                method: 'GET',
                url: `reports/definition/roles/permissions/dept/${departmentId}/`,
            });
        },

        getReportCreationRolePermissions(reportDefinitionId: number) {
            return req<ReportDefinitionResource.GetRolePermissionsByReportDefinitionId>({
                method: 'GET',
                url: `reports/definition/${reportDefinitionId}/roles/permissions`,
            });
        },

        setReportCreationRolePermissions(
            reportCardId: number,
            permissions: ReportCreationRolePermission[],
            removeExisting: boolean = true
        ) {
            return req<ReportDefinitionResource.UpsertRolePermissionsForReportDefinitionId>({
                method: 'PUT',
                url: `reports/definition/${reportCardId}/roles/permissions`,
                params: {
                    remove_existing: removeExisting,
                },
                data: permissions,
            });
        },

        setReportDefinitionCardLinks(
            reportDefinitionId: number,
            data: ReportDefinitionResource.ReplaceReportDefinitionCards['data']
        ) {
            return req<ReportDefinitionResource.ReplaceReportDefinitionCards>({
                method: 'PUT',
                url: `reports/definition/${reportDefinitionId}/cards`,
                data,
            });
        },

        createReportDefinitionCard(data: ReportCard) {
            return req<ReportDefinitionResource.CreateReportCard>({
                method: 'POST',
                url: 'reports/definition/cards',
                data,
            });
        },

        getReportDefinitionTitleFormatsByDefinitionId(reportDefinitionId: number) {
            return req<ReportDefinitionResource.GetReportDefinitionTitleFormatsByDefinitionId>({
                url: `reports/definition/title_formats/${reportDefinitionId}/`,
            });
        },
    },
});

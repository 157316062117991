import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createFormConfiguration } from 'markformythree';

import _ from 'lodash';
import styled from 'styled-components';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import * as fields from '~/client-common/core/enums/universal/fields';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import stringsConfig from '~/client-common/core/strings'; // TODO change this name

import SystemRuleForm from '../../forms/components/SystemRuleForm';
import testIds from '../../../../core/testIds';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { MFTText as Text } from '../../forms/components/Text';
import { getInfoForReportingEventNumberWithPromise } from '../../../../legacy-redux/actions/renSearchModalActions';
import { closeBox } from '../../../../legacy-redux/actions/boxActions';
import { renSearchUiSelector } from '../../../../legacy-redux/selectors/renSearchModalSelectors';

const strings = stringsConfig.components.core.RenSearchModal;

const StyledText = styled(Text)`
    .mark43-react-small-go-button {
        position: relative;
        left: 20px;
        border-radius: 5px;
    }
`;

function ReportingEventNumberSearchForm({
    disabled,
    formatFieldByName,
    fieldConfigurationContextByContextAndFieldName,
    loading,
    validationContext,
    onSubmit,
    formName,
    initialState,
    showCadAgencyEventNumberField,
}) {
    const fieldConfigurationContext = fieldConfigurationContextByContextAndFieldName(
        RefContextEnum.FORM_REPORT_CREATE_REPORT.name,
        fields.REPORT_REPORTING_EVENT_NUMBER
    );
    return (
        <SystemRuleForm
            name={formName}
            validationContext={validationContext}
            configuration={createFormConfiguration({
                reportingEventNumber: {
                    fieldName: fields.REPORT_REPORTING_EVENT_NUMBER,
                },
                cadAgencyEventNumber: {
                    fieldName: fields.CAD_TICKET_CAD_AGENCY_EVENT_NUMBER,
                },
            })}
            initialState={initialState}
            onSubmit={(submissionPromise) => onSubmit(submissionPromise)}
            render={({ submit, get }) => {
                // We only show the cadAgencyEventNumber field if we come from a CAD ticket search result.
                // If we show the CAD #, then we should hide the REN field if it's empty. Otherwise, always show the REN field
                return (
                    <>
                        {showCadAgencyEventNumberField && (
                            <StyledText
                                path="cadAgencyEventNumber"
                                width={200}
                                label={formatFieldByName(fields.CAD_TICKET_CAD_AGENCY_EVENT_NUMBER)}
                                disabled={disabled}
                            />
                        )}
                        {(showCadAgencyEventNumberField ? !!get('reportingEventNumber') : true) && (
                            <StyledText
                                path="reportingEventNumber"
                                width={200}
                                placeholder={_.get(fieldConfigurationContext, 'placeholderText')}
                                label={formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER)}
                                goButton={true}
                                loading={loading}
                                goButtonText={strings.goButtonText}
                                onPressEnter={submit}
                                onClick={submit}
                                disabled={disabled}
                                fieldName={testIds.REN_SEARCH_TEXT}
                                forceShowError={true}
                            />
                        )}
                    </>
                );
            }}
        />
    );
}

class RenSearchModal extends React.Component {
    constructor(props) {
        super(props);
    }

    closeModal() {
        this.props.closeModal(this.props.modalContext);
    }

    getInfoForReportingEventNumber(submissionPromise) {
        return this.props.getInfoForReportingEventNumber(
            submissionPromise,
            this.props.modalContext
        );
    }

    render() {
        const props = this.props;
        return (
            <Modal
                context={props.modalContext}
                title={props.title}
                onClose={this.closeModal.bind(this)}
                error={false}
                okText={null}
            >
                <ReportingEventNumberSearchForm
                    formName={props.formName}
                    initialState={props.formInitialState}
                    showCadAgencyEventNumberField={props.showCadAgencyEventNumberField}
                    onSubmit={this.getInfoForReportingEventNumber.bind(this)}
                    disabled={props.ui.reportingEventNumberSearchFormDisabled}
                    loading={props.ui.isLoading}
                    validationContext={props.validationContext}
                    formatFieldByName={props.formatFieldByName}
                    fieldConfigurationContextByContextAndFieldName={
                        props.fieldConfigurationContextByContextAndFieldName
                    }
                />
                {props.children}
            </Modal>
        );
    }
}

const selectors = createStructuredSelector({
    ui: renSearchUiSelector,
    formatFieldByName: formatFieldByNameSelector,
    fieldConfigurationContextByContextAndFieldName: fieldConfigurationContextByContextAndFieldNameSelector,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: (context) => dispatch(closeBox(context)),
    getInfoForReportingEventNumber: (submissionPromise, context) =>
        dispatch(getInfoForReportingEventNumberWithPromise(submissionPromise, context)),
});

export default connect(selectors, mapDispatchToProps)(RenSearchModal);

import React from 'react';
import styled, { withTheme } from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';

import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';
import Row from '../../../core/components/Row';
import Link from '../../../core/components/links/Link';
import { fileIconTypeForFileCategory, fileTypeShouldBeDownloadOnly } from '../../files/helpers';
import { InputLoading } from '../../../../legacy-redux/components/core/Loading';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import testIds from '../../../../core/testIds';

const UPLOAD_DETAILS = componentStrings.reports.core.AttachmentsSidePanel.uploadDetails;

const FileLink = styled(Link)`
    color: ${(props) => props.theme.colors.cobaltBlue};
    display: block;
    word-wrap: break-word;
`;

const UploadFile = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    font-size: var(--arc-fontSizes-sm);
    width: 100%;
    word-break: break-all;
    height: auto;
    margin-top: 6px;
    margin-bottom: 6px;
`;

const AttachmentInfo = styled.div`
    color: ${(props) => props.theme.colors.mediumGrey};
`;

const UploadDetails = styled.div`
    font-style: italic;
    color: ${(props) =>
        props.uploadFailed ? props.theme.colors.red : props.theme.colors.mediumGrey};
`;

const FileIconWrapper = styled.div`
    display: inline-block;
    margin-right: 5px;
`;

const FileSummaryWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Loading = styled(InputLoading)`
    top: 0 !important;
    right: 30px !important;
`;

const FileRow = ({
    url,
    fileName,
    hideDetails = false,
    createdDateUtc,
    createdBy,
    deleteFile = undefined,
    isLoading = false,
    theme,
    fileCategory,
    uploadFailed = false,
    errorMessage = '',
    disabled = false,
}) => {
    const iconType = fileIconTypeForFileCategory(fileCategory);
    const downloadOnly = fileTypeShouldBeDownloadOnly(fileName);
    const openInNewTab = !downloadOnly;
    return (
        <Row testId={testIds.INLINE_FILE_ROW}>
            <UploadFile>
                <FileIconWrapper>
                    <Icon
                        className="file-icon"
                        type={iconType}
                        size={iconSizes.MEDIUM}
                        color={theme.colors.cobaltBlue}
                    />
                </FileIconWrapper>
                <FileSummaryWrapper>
                    {isLoading ? (
                        <FileLink
                            openInNewTab={openInNewTab}
                            download={downloadOnly}
                            disabled={true}
                        >
                            {fileName}
                        </FileLink>
                    ) : (
                        <FileLink href={url} openInNewTab={openInNewTab} download={downloadOnly}>
                            {fileName}
                        </FileLink>
                    )}
                    <AttachmentInfo>
                        {!hideDetails && createdDateUtc ? (
                            <div>
                                <FormattedDate
                                    date={createdDateUtc}
                                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                                />
                                {` by ${createdBy}`}
                            </div>
                        ) : (
                            <UploadDetails uploadFailed={uploadFailed}>
                                {uploadFailed ? errorMessage : !hideDetails && UPLOAD_DETAILS}
                            </UploadDetails>
                        )}
                    </AttachmentInfo>
                </FileSummaryWrapper>
                {isLoading && <Loading />}
                {!disabled && (
                    <Button
                        testId={testIds.TRASH_BUTTON}
                        className={buttonTypes.ICON_LINK}
                        onClick={deleteFile}
                        iconLeft={
                            <Icon
                                size={iconSizes.MEDIUM}
                                color="cobaltBlue"
                                type={iconTypes.TRASH_CAN}
                            />
                        }
                    />
                )}
            </UploadFile>
        </Row>
    );
};

export default withTheme(FileRow);

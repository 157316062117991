import React from 'react';
import classNames from 'classnames';

/**
 * A column of content that can be stacked horizontally (it's an inline-block).
 * @param  [props.width] use a string for percentages, and a number for pixels.
 * @param         [props.marginRight=40] The default spacing is from the stylesheet.
 */
const Column: React.FC<{ width: string | number; marginRight?: number; className?: string }> = ({
    width,
    marginRight,
    className,
    ...otherProps
}) => {
    return (
        <div
            className={classNames('mark43-column', className)}
            style={{ width, marginRight }}
            {...otherProps}
        />
    );
};

export default Column;

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { Fieldset as MFTFieldset } from 'markformythree';
import styled from 'styled-components';
import { mediaQueries } from 'arc';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { responsiveStack } from '../../../../../core/styles/mixins';
import FormGroup from '../../../../../core/forms/components/FormGroup';
import FormRow from '../../../../../core/forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTCheckbox } from '../../../../../core/forms/components/checkboxes/Checkbox';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import withItemCategoryFilterOptions from './withItemCategoryFilterOptions';

const InlineCheckbox = styled(ArbiterMFTCheckbox)`
    @media (min-width: ${mediaQueries.md}) {
        margin-top: 28px;
    }
`;
const FlexRow = styled.div`
    display: flex;
    ${responsiveStack()}
`;

function DrugsFieldset({ helpTextCollisionBoundary, itemCategoryFilterOptions }) {
    return (
        <SidePanelSection>
            <MFTFieldset path="drugs">
                <FormGroup>
                    <FlexRow>
                        <ArbiterMFTAttributeSelect
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="itemCategoryAttrId"
                            attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
                            filterOptions={itemCategoryFilterOptions}
                            length="md"
                        />
                        {/* Two InlineCheckboxes are never expected to be visible at same time */}
                        {/* isPrecursorChemical for Texas LEA customers */}
                        <InlineCheckbox
                            path="isPrecursorChemical"
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                        />
                        {/* isCounterfeit for Louisana NIBRS customers */}
                        <InlineCheckbox
                            path="isCounterfeit"
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                        />
                    </FlexRow>
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="description"
                    />
                    <ArbiterMFTBooleanSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="wasContainerOpen"
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="containerAmountRemaining"
                        length="md"
                    />
                    <FormRow>
                        <ArbiterMFTText
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="itemMake"
                            length="md"
                        />
                        <ArbiterMFTAttributeSelect
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="primaryColorAttrId"
                            attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                            length="md"
                        />
                    </FormRow>
                </FormGroup>
            </MFTFieldset>
        </SidePanelSection>
    );
}

/**
 * Fieldset in the item entry v2 form that appears only when the item type is drugs. It includes
 *   fields from the ItemProfile model.
 */
export default withItemCategoryFilterOptions(globalAttributes.itemType.drugs)(DrugsFieldset);

import React from 'react';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import { AttributeTypeEnum, ElasticAttributeDetail } from '@mark43/rms-api';

import { FormattedDate } from '~/client-common/core/dates/components';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    CASE_TARGET_PROFILE_REVIEW_DATE_UTC,
    CASE_TARGET_PROFILE_NARRATIVE,
    CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_PRIORITY_ATTRIBUTE_ID,
    CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID,
    CASE_ATTRIBUTE_ATTRIBUTE_ID,
} from '~/client-common/core/enums/universal/fields';
import { formatElasticAttributes } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import _SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import { subdivisionSortingOrder } from '../../../../legacy-redux/helpers/subdivisionHelpers';
import testIds from '../../../../core/testIds';

const ListContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
    margin-top: 15px;
`;

const SummaryRow = styled(_SummaryRow)`
    > .summary-row-content {
        word-break: break-word;
    }
`;

const NarrativeSummaryRow = styled(SummaryRow)`
    && > .summary-row-label {
        display: block;
        color: var(--arc-colors-text-primary);
        font-weight: ${(props) => props.theme.fontWeights.semiBold};
    }
    && > .summary-row-content {
        white-space: normal;
    }
`;

type CaseTargetProfileDetailsProps = {
    caseAttributes: ElasticAttributeDetail[];
    reviewDate?: string;
    narrative?: string;
};

const CaseTargetProfileDetails = ({
    caseAttributes,
    reviewDate,
    narrative,
}: CaseTargetProfileDetailsProps) => {
    const fields = useFields([
        CASE_TARGET_PROFILE_REVIEW_DATE_UTC,
        CASE_TARGET_PROFILE_NARRATIVE,
        CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_PRIORITY_ATTRIBUTE_ID,
        CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID,
        CASE_ATTRIBUTE_ATTRIBUTE_ID,
    ]);

    const groupedCaseAttributesByAttributeType = groupBy(caseAttributes, 'type');

    const targetProfileCategoryAttributes =
        groupedCaseAttributesByAttributeType[AttributeTypeEnum.TARGET_PROFILE_CATEGORY.name] || [];

    const targetProfilePriorityAttributes =
        groupedCaseAttributesByAttributeType[AttributeTypeEnum.TARGET_PROFILE_PRIORITY.name] || [];

    const targetProfileAreaAttributes = subdivisionSortingOrder
        .map((orderKey) => groupedCaseAttributesByAttributeType[orderKey])
        .filter(Boolean)
        .flat();

    return (
        <div data-test-id={testIds.CASE_TARGET_PROFILE_DETAILS_SECTION}>
            <ListContainer>
                <SummaryList labelWidth={200}>
                    <SummaryRow
                        label={
                            fields.CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID
                        }
                    >
                        {formatElasticAttributes(targetProfileCategoryAttributes)}
                    </SummaryRow>
                    <SummaryRow
                        label={
                            fields.CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_PRIORITY_ATTRIBUTE_ID
                        }
                    >
                        {formatElasticAttributes(targetProfilePriorityAttributes)}
                    </SummaryRow>
                </SummaryList>
                <SummaryList labelWidth={200}>
                    <SummaryRow label={fields.CASE_ATTRIBUTE_ATTRIBUTE_ID}>
                        {formatElasticAttributes(targetProfileAreaAttributes)}
                    </SummaryRow>
                    <SummaryRow
                        label={fields.CASE_TARGET_PROFILE_REVIEW_DATE_UTC}
                        hidden={!reviewDate}
                    >
                        <FormattedDate format={FormattedDate.FORMATS.FORM_DATE} date={reviewDate} />
                    </SummaryRow>
                </SummaryList>
            </ListContainer>
            <SummaryList>
                <NarrativeSummaryRow label={fields.CASE_TARGET_PROFILE_NARRATIVE}>
                    {narrative}
                </NarrativeSummaryRow>
            </SummaryList>
        </div>
    );
};

export default CaseTargetProfileDetails;

import { createSelector } from 'reselect';
import { map, sortBy } from 'lodash';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import * as fields from '~/client-common/core/enums/universal/fields';

export { groupAttachmentsByEntityIdSelector } from './groupAttachmentsByEntityIdSelector';

export const redactableFieldOptionsSelector = createSelector(
    formatFieldByNameSelector,
    (formatFieldByName) => {
        return sortBy(
            map(
                [
                    fields.PERSON_PROFILE_SSN,
                    fields.PERSON_PROFILE_DL_NUMBER,
                    fields.PERSON_PROFILE_DL_STATE_ATTR_ID,
                    fields.DISPLAY_PERSON_PROFILE_DATE_OF_BIRTH_ESTIMATED_AGE_RANGE,
                    fields.NAME_PHONE_NUMBER_PHONE_NUMBER,
                    fields.NAME_EMAIL_EMAIL_ADDRESS,
                    fields.PERSON_PROFILE_STATE_ID_NUMBER,
                    fields.PERSON_PROFILE_FBI_UCN,
                    fields.PERSON_PROFILE_CITIZENSHIP_ATTR_ID,
                ],
                (fieldName) => ({
                    value: fieldName,
                    display: formatFieldByName(fieldName),
                })
            ),
            'display'
        );
    }
);

import factory from './actionTypesFactory';

export default factory('shapefilesadmin', [
    'PAGE_LOAD_START',
    'PAGE_LOAD_SUCCESS',
    'PAGE_LOAD_ERROR',
    'SELECT_SHAPEFILE_START',
    'SELECT_SHAPEFILE_SUCCESS',
    'SELECT_SHAPEFILE_FAILURE',
    'SAVE_SHAPEFILE_START',
    'SAVE_SHAPEFILE_SUCCESS',
    'SAVE_SHAPEFILE_FAILURE',
    'DELETE_SHAPEFILE_START',
    'DELETE_SHAPEFILE_SUCCESS',
    'DELETE_SHAPEFILE_FAILURE',
    'DOWNLOAD_SHAPEFILE_START',
    'DOWNLOAD_SHAPEFILE_SUCCESS',
    'DOWNLOAD_SHAPEFILE_FAILURE',
    'CREATE_SHAPEFILE_START',
    'CREATE_SHAPEFILE_SUCCESS',
    'CREATE_SHAPEFILE_FAILURE',
    'CANCEL_SHAPEFILE_ACTIONS',
]);

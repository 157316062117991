import { FileStatusEnum, FileUploadResponse } from '@mark43/rms-api';
import { map, concat, isEmpty, includes } from 'lodash';
import getFilesResource from '~/client-common/core/domain/files/resources/filesResource';
import { removeFiles } from '~/client-common/core/domain/files/state/data';
import { RmsAction, RmsDispatch } from '../../../../../core/typings/redux';
import {
    openConfirmationModal,
    closeConfirmationModal,
} from '../../../../../legacy-redux/actions/boxActions';
import {
    getFilesByStatus,
    getFilesToRemove,
    getUploadingFilesToRemove,
} from '../../utils/securityScannerHelper';

import type { UploadingFilesT } from '../../utils/securityScannerHelper';
import {
    uploadingFilesSelector as uploadingInlineAttachmentsFilesSelector,
    updateUploadingFiles as inlineAttachmentsUpdateUploadingFiles,
} from './inlineAttachmentsUploader';

import { uploadingFilesSelector as uploadingAttachmentsFilesSelector } from './attachmentsSidePanel';
import {
    fileScannerIsPollingSelector,
    filesToScanSelector,
    pollingFileSecurityScannerAddFiles,
    pollingFileSecurityScannerEnd,
    pollingFileSecurityScannerFailure,
    pollingFileSecurityScannerRemoveFiles,
    pollingFileSecurityScannerStart,
    pollingFileSecurityScannerSuccess,
    s3UploadsSelector,
} from './securityScanning';

const NETWORK_ERROR_KEY = 'NetworkError';

const strings = {
    offlineModal: {
        title: 'Network Error',
        message:
            'You are now offline. Please re-connect back to the internet and please try again.',
        okText: 'Confirm',
    },
};

type FileScanningCallbackType = (groupedFiles: {
    cleanFiles: FileUploadResponse[];
    dirtyFiles: FileUploadResponse[];
    pendingFiles: FileUploadResponse[];
    deletedFiles: FileUploadResponse[];
    failedFiles: FileUploadResponse[];
    uploadingDirtyFiles: UploadingFilesT[];
}) => void;

const handleNetworkErrorScenario = (): RmsAction<void> => {
    return (dispatch) => {
        dispatch(
            openConfirmationModal({
                title: strings.offlineModal.title,
                message: strings.offlineModal.message,
                saveCallback: () => {
                    dispatch(closeConfirmationModal());
                },
                cancelText: '',
                okText: strings.offlineModal.okText,
            })
        );

        dispatch(pollingFileSecurityScannerEnd());
    };
};

const pollSecurityScanner = (
    fileIds: number[],
    callBack: FileScanningCallbackType
): RmsAction<Promise<void>> => {
    let cleanFiles: FileUploadResponse[] = [];
    let dirtyFiles: FileUploadResponse[] = [];
    let failedFiles: FileUploadResponse[] = [];

    const filesResource = getFilesResource();

    return (dispatch, getState) => {
        return filesResource
            .uploadResponse(fileIds)
            .then((files: FileUploadResponse[]) => {
                cleanFiles = concat(cleanFiles, getFilesByStatus(files, FileStatusEnum.CLEAN.name));
                failedFiles = concat(
                    failedFiles,
                    getFilesByStatus(files, FileStatusEnum.FAIL.name)
                );
                dirtyFiles = concat(dirtyFiles, getFilesByStatus(files, FileStatusEnum.DIRTY.name));

                const infectedFileIds = map(
                    getFilesByStatus(files, FileStatusEnum.DIRTY.name),
                    'id'
                );

                // Gather the files to check if any are deleted while being scanned
                const uploadingInlineAttachmentsFiles = uploadingInlineAttachmentsFilesSelector(
                    getState()
                );
                const uploadingAttachmentsFiles = uploadingAttachmentsFilesSelector(getState());
                const s3Uploads = s3UploadsSelector(getState());

                const polledFilesToRemove = getFilesToRemove(files, s3Uploads, [
                    ...uploadingInlineAttachmentsFiles,
                    ...uploadingAttachmentsFiles,
                ]);

                const dirtyUploadingFilesToRemove = getUploadingFilesToRemove(
                    dirtyFiles,
                    s3Uploads,
                    [...uploadingInlineAttachmentsFiles, ...uploadingAttachmentsFiles]
                );

                // filter fileIds to only include ids that has PENDING status for the next poll
                const pendingFiles = getFilesByStatus(files, FileStatusEnum.PENDING.name);

                if (!isEmpty(infectedFileIds)) {
                    const dirtyUploadingFileIds = map(dirtyUploadingFilesToRemove, 'file.id');

                    const preservedUploadingInlineAttachmentsFiles = uploadingInlineAttachmentsFiles.filter(
                        // @ts-expect-error the uploadingInlineAttachmentsFilesSelector isn't typed
                        ({ file: { id } }) => !includes(dirtyUploadingFileIds, id)
                    );

                    dispatch(
                        inlineAttachmentsUpdateUploadingFiles(
                            preservedUploadingInlineAttachmentsFiles
                        )
                    );
                    dispatch(removeFiles(infectedFileIds));
                }

                // check if files have been deleted mid-scan to prevent another poll
                const filesToScan = filesToScanSelector(getState());
                if (filesToScan.length === 0) {
                    dispatch(pollingFileSecurityScannerEnd());
                    return;
                }

                // Update the files to be scanned for the next poll
                dispatch(pollingFileSecurityScannerAddFiles(map(pendingFiles, 'file.id')));

                // Remove any pending and deleted files for next poll to reduce query size
                dispatch(
                    pollingFileSecurityScannerRemoveFiles([
                        ...map(cleanFiles, 'file.id'),
                        ...map(dirtyFiles, 'file.id'),
                        ...map(failedFiles, 'file.id'),
                        ...map(polledFilesToRemove, 'file.id'),
                    ])
                );

                // Callback is run every time to stop showing the loading of clean files
                const groupedFiles = {
                    cleanFiles,
                    dirtyFiles,
                    pendingFiles,
                    deletedFiles: polledFilesToRemove,
                    failedFiles,
                    uploadingDirtyFiles: dirtyUploadingFilesToRemove,
                };

                // Callback is run every time to stop showing the loading of clean files
                callBack(groupedFiles);

                // If there are no more files pending then stop polling
                if (isEmpty(pendingFiles)) {
                    dispatch(pollingFileSecurityScannerSuccess());
                }
            })
            .catch((err: Error) => {
                if (err && err.name && err.name === NETWORK_ERROR_KEY) {
                    dispatch(handleNetworkErrorScenario());
                    return;
                }

                dispatch(pollingFileSecurityScannerFailure(err));
                throw err;
            });
    };
};

// FILE SECURITY SCANNER POLLING
export function startPollingForFileSecurityScanner(
    files: FileUploadResponse[],
    callBack: FileScanningCallbackType
): RmsAction<void> {
    return function (dispatch: RmsDispatch, getState) {
        dispatch(pollingFileSecurityScannerAddFiles(map(files, 'file.id')));

        if (!fileScannerIsPollingSelector(getState())) {
            dispatch(pollingFileSecurityScannerStart());
            dispatch(pollForFileSecurityScanner(callBack));
        }
    };
}

function pollForFileSecurityScanner(callBack: FileScanningCallbackType): RmsAction<void> {
    return function (dispatch, getState) {
        const filesToScan = filesToScanSelector(getState());
        if (filesToScan && filesToScan.length > 0) {
            return (
                dispatch(pollSecurityScanner(filesToScan, callBack))
                    // silent failure
                    .catch((err: Error) => {
                        dispatch(pollingFileSecurityScannerFailure(err));
                        throw err;
                    })
                    .finally(() => {
                        if (fileScannerIsPollingSelector(getState())) {
                            window.setTimeout(
                                () => dispatch(pollForFileSecurityScanner(callBack)),
                                3000
                            );
                        }
                        return;
                    })
            );
        } else {
            dispatch(pollingFileSecurityScannerEnd());
            return;
        }
    };
}

import { EntityTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import _, { parseInt, map, includes, filter, some, uniq } from 'lodash';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import {
    NEXUS_STATE_PROP as ARRESTS_NEXUS_STATE_PROP,
    arrestForReportIdSelector,
} from '~/client-common/core/domain/arrests/state/data';
import { reportDefinitionHasArrestCard } from '~/client-common/helpers/reportDefinitionsHelpers';
import { isArrestReportSelector } from '~/client-common/core/domain/arrests/state/ui';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import {
    chargesWhereSelector,
    NEXUS_STATE_PROP as CHARGES_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/charges/state/data';
import {
    NEXUS_STATE_PROP as REPORT_NEXUS_STATE_PROP,
    reportsSelector,
} from '~/client-common/core/domain/reports/state/data';
import {
    NEXUS_STATE_PROP as REPORT_DEFINITIONS_NEXUS_STATE_PROP,
    reportDefinitionsSelector,
} from '~/client-common/core/domain/report-definitions/state/data';
import {
    NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP,
    nameReportLinksWhereSelector,
} from '~/client-common/core/domain/name-report-links/state/data';
import {
    NEXUS_STATE_PROP as PERSON_PROFILES_NEXUS_STATE_PROP,
    personProfilesSelector,
} from '~/client-common/core/domain/person-profiles/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-codes/state/data';

import {
    isIdentified,
    formatFullName,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';

import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import sealingResource from '../../../core/resources/sealingResource';
import { doesVacatedChargeExistForReportIdSelector } from '../../../vacating/state/ui';
import reportSealingForm from '../forms/reportSealingForm';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import { initializeCardForm } from '../../../../reports/core/state/ui/cards';

const REPORT_SEALING_RESET_STATE = 'record-privacy/sealing/RESET_STATE';
const REPORT_SEALING_FORM_SUBMIT_FAILURE = 'record-privacy/sealing/FORM_SUBMIT_FAILURE';
const REPORT_SEALING_FORM_SUBMIT_BEGIN = 'record-privacy/sealing/FORM_SUBMIT_BEGIN';

const REPORT_SEALING_LOAD_REPORT_BEGIN = 'record-privacy/sealing/LOAD_REPORT_BEGIN';
const REPORT_SEALING_LOAD_REPORT_SUCCESS = 'record-privacy/sealing/LOAD_REPORT_SUCCESS';
const REPORT_SEALING_LOAD_REPORT_FAILURE = 'record-privacy/sealing/LOAD_REPORT_FAILURE';

const resetReportSealingState = () => ({ type: REPORT_SEALING_RESET_STATE });
const reportSealingFormSubmitBegin = () => ({ type: REPORT_SEALING_FORM_SUBMIT_BEGIN });
const reportSealingFormSubmitFailure = (error) => ({
    type: REPORT_SEALING_FORM_SUBMIT_FAILURE,
    payload: { error },
});

const loadReportBegin = (reportId) => ({
    type: REPORT_SEALING_LOAD_REPORT_BEGIN,
    payload: { reportId },
});
const loadReportSuccess = (reportId) => ({
    type: REPORT_SEALING_LOAD_REPORT_SUCCESS,
    payload: { reportId },
});
const loadReportFailure = (error) => ({
    type: REPORT_SEALING_LOAD_REPORT_FAILURE,
    payload: { error },
});

export function cleanupReportSealing() {
    return (dispatch, getState, { nexus }) =>
        dispatch(
            // clean up entities from loadReportForSealing
            // keep REPORT_DEFINITIONS_NEXUS_STATE_PROP for select dropdowns
            nexus.withRemoveMultiple(
                {
                    [ARRESTS_NEXUS_STATE_PROP]: {},
                    [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: {},
                    [PERSON_PROFILES_NEXUS_STATE_PROP]: {},
                    [REPORT_NEXUS_STATE_PROP]: {},
                    [CHARGES_NEXUS_STATE_PROP]: {},
                },
                resetReportSealingState()
            )
        );
}

export const loadReportForSealing = (reportId) => (dispatch, getState, dependencies) => {
    dispatch(loadReportBegin(reportId));
    return getReportResource()
        .getReportView(reportId)
        .then((completeReportView) => {
            const action = dependencies.nexus.withEntityItems(
                {
                    [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                        completeReportView.attributes,
                        convertAttributeToAttributeView
                    ),
                    [REPORT_NEXUS_STATE_PROP]: [completeReportView.report],
                    [REPORT_DEFINITIONS_NEXUS_STATE_PROP]: completeReportView.reportDefinitions,
                    [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: completeReportView.nameReportLinks,
                    [PERSON_PROFILES_NEXUS_STATE_PROP]: completeReportView.personProfiles,
                    [ARRESTS_NEXUS_STATE_PROP]: [completeReportView.arrest],
                    [CHARGES_NEXUS_STATE_PROP]: completeReportView.charges,
                    [OFFENSE_CODES_NEXUS_STATE_PROP]: completeReportView.offenseCodes,
                },
                loadReportSuccess(reportId)
            );
            dispatch(action);
            return completeReportView;
        })
        .catch((error) => dispatch(loadReportFailure(error.message)));
};

export const submitReportSealingForm = ({ router }) => (dispatch, getState) => {
    dispatch(reportSealingFormSubmitBegin());

    return dispatch(
        reportSealingForm.actionCreators.submit((formModel) => {
            const state = getState();
            const { personIdToSeal, chargeIdsToSeal, statuteSealingPersonIdsToSeal } = formModel;
            const personProfiles = personProfilesSelector(state);
            const profileToSeal = personProfiles[personIdToSeal];
            const departmentId = currentUserDepartmentIdSelector(state);
            const chargesForArrestReportId = chargesForArrestReportIdSelector(state);
            const charges = map(chargesForArrestReportId(formModel.reportId), (charge) => ({
                ...charge,
                isSealed: includes(chargeIdsToSeal, charge.id),
            }));

            const { courtCodeAttrId } = formModel.courtOrder;

            const statuteSealingCourtOrders = statuteSealingPersonIdsToSeal?.map((id) => {
                return {
                    ...formModel.courtOrder,
                    statuteCodeAttrId: formModel.courtOrder.statuteCodeAttrId,
                    fileIds: formModel.courtOrderFileIds,
                    reportId: formModel.reportId,
                    // cast to boolean
                    isJuvenile: !!formModel.courtOrder.isJuvenile,
                    departmentId,
                    contextedPersonId: id,
                    courtCodeAttrId,
                    involvedPersonFullName: formatFullName(personProfiles[id]),
                };
            });

            return sealingResource().sealReport({
                reportId: formModel.reportId,
                isSealReport: !!formModel.isSealReport,
                isSealNarrative: !!formModel.isSealNarrative,
                userToContactId: formModel.userToContactId,
                courtOrders: statuteSealingCourtOrders ?? [
                    {
                        ...formModel.courtOrder,
                        fileIds: formModel.courtOrderFileIds,
                        reportId: formModel.reportId,
                        // cast to boolean
                        isJuvenile: !!formModel.courtOrder.isJuvenile,
                        departmentId,
                        contextedPersonId: profileToSeal?.id,
                        courtCodeAttrId,
                    },
                ],
                ...{ charges },
            });
        })
    )
        .then((resp) => {
            router.push(`/reports/${resp.report.id}`);
            return resp;
        })
        .catch((err) => {
            // This is used for validation errors and actual submission server errors.
            // Validation errors won't have a message so this will only reset `isSubmitting`
            // in that case, without setting an actual error message.
            dispatch(reportSealingFormSubmitFailure(err.message));
        });
};

export const initializeSealingForm = (sealingResponse, reportId) => (dispatch) => {
    const report = sealingResponse.report;
    const arrestIdNumber = report.recordNumber;

    dispatch(
        reportSealingForm.actionCreators.change({
            reportId,
            isSealNarrative: !!report.isNarrativeSealed,
            userToContactId: report.sealedNarrativeContactUserId,
            arrestIdNumber,
        })
    );
    dispatch(initializeCardForm(reportSealingForm));
};

const initialState = {
    submissionError: undefined,
    isSubmitting: false,
    reportLoading: {
        loadingError: undefined,
        reportLoaded: false,
        isLoading: false,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_SEALING_FORM_SUBMIT_FAILURE:
            return { ...state, submissionError: action.payload.error, isSubmitting: false };
        case REPORT_SEALING_FORM_SUBMIT_BEGIN:
            return { ...state, submissionError: undefined, isSubmitting: true };
        case REPORT_SEALING_LOAD_REPORT_BEGIN:
            return { ...state, reportLoading: { ...state.reportLoading, isLoading: true } };
        case REPORT_SEALING_LOAD_REPORT_SUCCESS:
            return {
                ...state,
                reportLoading: { ...state.reportLoading, isLoading: false, reportLoaded: true },
            };
        case REPORT_SEALING_LOAD_REPORT_FAILURE:
            return {
                ...state,
                reportLoading: {
                    ...state.reportLoading,
                    isLoading: false,
                    loadingError: action.payload.error,
                },
            };
        default:
            return state;
    }
};

export const reportSealingSubmissionErrorSelector = (state) =>
    state.ui.reportSealing.submissionError;
export const reportSealingIsSubmittingSelector = (state) => state.ui.reportSealing.isSubmitting;
export const reportSealingReportLoadingStateSelector = (state) =>
    state.ui.reportSealing.reportLoading;

export default makeResettable(REPORT_SEALING_RESET_STATE, reducer, initialState);

const involvedPersonsForReportIdSelector = createSelector(
    reportsSelector,
    nameReportLinksWhereSelector,
    personProfilesSelector,
    reportDefinitionsSelector,
    arrestForReportIdSelector,
    (reports, nameReportLinksWhere, personProfiles, reportDefinitions, arrestForReportId) => (
        reportId
    ) => {
        const parsedReportId = parseInt(reportId);
        const report = reports[parsedReportId];
        if (!report) {
            return [];
        }

        const reportDefinition = reportDefinitions[report.reportDefinitionId];
        const arrest = arrestForReportId(report.id);
        const defendant =
            reportDefinitionHasArrestCard(reportDefinition) && arrest
                ? personProfiles[arrest.defendantId]
                : [];

        const selectedNameReportLinks = nameReportLinksWhere((link) => {
            const profile = personProfiles[link.nameId];
            if (!profile) {
                return false;
            }
            return (
                link.entityType === EntityTypeEnum.PERSON_PROFILE.name &&
                link.reportId === report.id
            );
        });
        return _.chain(selectedNameReportLinks)
            .map(({ nameId }) => personProfiles[nameId])
            .concat(defendant)
            .compact()
            .value();
    }
);

export const sealableInvolvedPersonsForReportIdSelector = createSelector(
    involvedPersonsForReportIdSelector,
    (involvedPersonsForReportId) => (reportId) => {
        const personProfiles = involvedPersonsForReportId(reportId);
        // the check for `masterPersonId` is used to filter out unknown persons
        // If an unknown was identified, we still want to show them
        return filter(
            uniq(personProfiles),
            (personProfile) => !personProfile.isExpunged && isIdentified(personProfile)
        );
    }
);

export const isInvolvedPersonSealedForReportIdSelector = createSelector(
    involvedPersonsForReportIdSelector,
    (involvedPersonsForReportId) => (reportId) => {
        const personProfiles = involvedPersonsForReportId(reportId);
        return !!filter(personProfiles, (personProfile) => personProfile.isExpunged).length;
    }
);

export const doesSealedChargeExistForReportIdSelector = createSelector(
    reportsSelector,
    reportDefinitionsSelector,
    arrestForReportIdSelector,
    chargesWhereSelector,
    (reports, reportDefinitions, arrestForReportId, chargesWhere) => (reportId) => {
        const parsedReportId = parseInt(reportId);
        const report = reports[parsedReportId];
        if (!report) {
            return false;
        }

        const reportDefinition = reportDefinitions[report.reportDefinitionId];
        if (reportDefinitionHasArrestCard(reportDefinition)) {
            const arrest = arrestForReportId(report.id);
            if (!arrest) {
                return false;
            }

            const charges = chargesWhere({ arrestId: arrest.id });
            return some(charges, 'isSealed');
        }
        return false;
    }
);

export const currentReportSealingSelector = createSelector(
    currentReportSelector,
    doesVacatedChargeExistForReportIdSelector,
    isInvolvedPersonSealedForReportIdSelector,
    doesSealedChargeExistForReportIdSelector,
    (
        currentReport,
        doesVacatedChargeExistForReportId,
        isInvolvedPersonSealedForReportId,
        doesSealedChargeExistForReportId
    ) => ({
        isSealed: currentReport.isSealed,
        isPartiallySealed:
            isInvolvedPersonSealedForReportId(currentReport.id) ||
            doesSealedChargeExistForReportId(currentReport.id),
        isVacated: doesVacatedChargeExistForReportId(currentReport.id),
    })
);

export const chargesForArrestReportIdSelector = createSelector(
    arrestForReportIdSelector,
    chargesWhereSelector,
    isArrestReportSelector,
    (arrestForReportId, chargesWhere, isArrestReport) => (arrestReportId) => {
        if (!arrestReportId) {
            return [];
        }

        const isArrest = isArrestReport(arrestReportId);
        const arrest = arrestForReportId(arrestReportId);

        if (!isArrest || !arrest) {
            return [];
        }

        return chargesWhere({ arrestId: arrest.id });
    }
);

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash';

import { RefContextEnum } from '@mark43/rms-api';
import { getAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import strings from '~/client-common/core/strings/componentStrings';
import { isArrestReportSelector } from '~/client-common/core/domain/arrests/state/ui';

import reportSealingForm from '../state/forms/reportSealingForm';
import {
    reportSealingSubmissionErrorSelector,
    sealableInvolvedPersonsForReportIdSelector,
} from '../state/ui';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { ColumnLeft, ColumnRight, ColumnFull } from '../../core/components/RecordPrivacyColumn';
import { FlexContainer, InnerFlexContainer } from '../../core/components/RecordPrivacyContainers';
import { Note, RightColumnNote } from '../../core/components/RecordPrivacyNote';
import { Form } from '../../core/components/Form';
import SubSectionTitle from '../../core/components/SubSectionTitle';
import CourtOrderFormFields from '../../core/components/CourtOrderFormFields';
import ReportSealingFormFields from '../../core/components/ReportSealingFormFields';
import InvolvedPersonRow from './InvolvedPersonRow';
import SealingChargesSection from './SealingChargesSection';

class ReportSealingForm extends React.Component {
    handleSealingTypeChange(isStatuteSealing) {
        this.props.changePath('sealingType', isStatuteSealing);
        if (!isStatuteSealing) {
            this.props.changePath('courtOrder.statuteCodeAttrId', undefined);
            this.props.changePath('statuteSealingPersonIdsToSeal', undefined);
        } else {
            this.props.changePath('personIdToSeal', undefined);
            this.props.changePath('courtOrder.courtCaseNumber', undefined);
            this.props.changePath('courtOrder.courtCodeAttrId', undefined);
            this.props.changePath('courtOrder.courtCaseStatute', undefined);
            this.props.changePath('courtOrder.courtCaseDateUtc', undefined);
            this.props.changePath('courtOrder.dateToPurgeUtc', undefined);
            this.props.changePath('courtOrder.isJuvenile', undefined);
            this.props.changePath('courtOrder.involvedPersonFullName', '');
            this.props.changePath('courtOrder.stateIdNumber', undefined);
        }
    }

    handleSealClick = (person) => {
        if (this.props.formModelByPath('sealingType')) {
            const currIds = this.props.formModelByPath('statuteSealingPersonIdsToSeal') ?? [];
            this.props.changePath('statuteSealingPersonIdsToSeal', [...currIds, person.id]);
        } else {
            this.props.changePath('personIdToSeal', person.id);
            this.props.changePath('courtOrder.involvedPersonFullName', formatFullName(person));
            this.props.changePath('courtOrder.stateIdNumber', person.stateIdNumber);
        }
    };
    handleUnsealClick = (person) => {
        if (this.props.formModelByPath('sealingType')) {
            const currIds = this.props.formModelByPath('statuteSealingPersonIdsToSeal') ?? [];
            this.props.changePath('statuteSealingPersonIdsToSeal', [
                ...currIds.filter((x) => x !== person.id),
            ]);
        } else {
            this.props.changePath('personIdToSeal', undefined);
            this.props.changePath('courtOrder.involvedPersonFullName', '');
            this.props.changePath('courtOrder.stateIdNumber', undefined);
        }
    };
    handleReportSealClick = (isReportSealed) => {
        if (isReportSealed && !this.props.report.isNarrativeSealed) {
            this.props.changePath('isSealNarrative', true);
        }
    };

    render() {
        const {
            report,
            submissionError,
            sealableInvolvedPersonsForReportId,
            formModelByPath,
            isArrestReport,
        } = this.props;
        const personIdToSeal = formModelByPath('personIdToSeal');
        const involvedPersons = sealableInvolvedPersonsForReportId(report.id);
        const showArrestIdField = isArrestReport(report.id);
        const isStatuteSealing = formModelByPath('sealingType');
        const statuteSealingPersonIdsToSeal =
            formModelByPath('statuteSealingPersonIdsToSeal') ?? [];

        return (
            <Form {...reportSealingForm}>
                <FlexContainer>
                    {submissionError && (
                        <ColumnFull flexGrow={0}>
                            <InlineBanner status="error">{submissionError}</InlineBanner>
                        </ColumnFull>
                    )}
                    <InnerFlexContainer>
                        <ColumnLeft>
                            <Note>
                                {strings.recordPrivacy.shared.CourtOrderFormFields.enterInformation}
                            </Note>
                            <CourtOrderFormFields
                                changePath={this.props.changePath}
                                formModelByPath={formModelByPath}
                                disableInvolvedPersonFullName={!personIdToSeal}
                                currentContext={RefContextEnum.FORM_REPORT_SEALING.name}
                                showArrestIdField={showArrestIdField}
                                showStateIdField={true}
                                onSealingTypeChange={this.handleSealingTypeChange}
                                isStatuteSealing={isStatuteSealing}
                            />
                        </ColumnLeft>
                        <ColumnRight>
                            <SubSectionTitle>
                                {strings.recordPrivacy.shared.sections.involvedPersons.title}
                            </SubSectionTitle>
                            <RightColumnNote>
                                {
                                    strings.recordPrivacy.sealing.ReportSealingForm.involvedPersons
                                        .note
                                }
                            </RightColumnNote>
                            {map(involvedPersons, (person) => {
                                const isCurrentlySealing =
                                    personIdToSeal === person.id ||
                                    statuteSealingPersonIdsToSeal.includes(person.id);
                                const isDisabled =
                                    !isStatuteSealing && !!personIdToSeal && !isCurrentlySealing;
                                return (
                                    <InvolvedPersonRow
                                        key={person.id}
                                        person={person}
                                        disabled={isDisabled}
                                        sealed={isCurrentlySealing}
                                        onSealClick={this.handleSealClick}
                                        onUnsealClick={this.handleUnsealClick}
                                    />
                                );
                            })}
                            <SealingChargesSection reportId={report.id} />
                            <SubSectionTitle>
                                {strings.recordPrivacy.shared.sections.narrative.title}
                            </SubSectionTitle>
                            <RightColumnNote>
                                {strings.recordPrivacy.shared.sections.narrative.note}
                            </RightColumnNote>
                            <ReportSealingFormFields
                                formModelByPath={formModelByPath}
                                onReportSealClick={this.handleReportSealClick}
                                report={report}
                            />
                        </ColumnRight>
                    </InnerFlexContainer>
                </FlexContainer>
            </Form>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    submissionError: reportSealingSubmissionErrorSelector,
    sealableInvolvedPersonsForReportId: sealableInvolvedPersonsForReportIdSelector,
    formModelByPath: reportSealingForm.selectors.formModelByPathSelector,
    isArrestReport: isArrestReportSelector,
    getAttributeById: getAttributeByIdSelector,
});

export default connect(mapStateToProps, {
    changePath: reportSealingForm.actionCreators.changePath,
})(ReportSealingForm);

import { min, max, map } from 'lodash';

/**
 * NOTE: this "center" calculation won't work if the polygon crosses the international date line
 *  coz the Earth is round. thanks Earth. (at least that's probably why)
 *  User will need to pan around to see the polygon.
 * @param  {Object[]} coordinates Array of {lat, lng} objects
 * @return {Objec}  {lat, lng} object containing center coordinates
 */
export function getPolygonCenter(coordinates) {
    const maxLat = max(map(coordinates, 'lat'));
    const minLat = min(map(coordinates, 'lat'));
    const maxLng = max(map(coordinates, 'lng'));
    const minLng = min(map(coordinates, 'lng'));

    const polygonCenter = {
        lat: (maxLat + minLat) / 2,
        lng: (maxLng + minLng) / 2,
    };

    return polygonCenter;
}

import React from 'react';
import classNames from 'classnames';
import Tag from './Tag';

export default function VerifiedTag({
    className,
    content,
}: {
    className?: string;
    content?: React.ReactNode;
}) {
    return <Tag className={classNames('verified', className)}>{content || 'Verified'}</Tag>;
}

import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { compact, filter, get, map } from 'lodash';
import { createFormConfiguration, _Form } from 'markformythree';

import { trimToMinutes } from '~/client-common/core/dates/utils/dateHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';

import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import { NEXUS_STATE_PROP as AGENCY_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/agency-profiles/state/data';
import {
    NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP,
    attributesSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { hydratedSupplementInfoByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/supplements';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const formName = RefContextEnum.FORM_SUPPLEMENT_INFO.name;
export const SUPPLEMENT_INFO_CARD_EVENT_START_UTC_PATH = 'eventStartUtc';
export const SUPPLEMENT_INFO_CARD_EVENT_END_UTC_PATH = 'eventEndUtc';
export const RESPONDING_OFFICER_ID_PATH = 'respondingOfficerId';
export const PERSONNEL_UNIT_ATTR_ID_PATH = 'personnelUnitAttrId';
export const getSupplementInfoForm = () => formsRegistry.get(formName);

export const createSupplementInfoForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            description: {
                fieldName: fields.REPORT_DESCRIPTION,
            },
            [SUPPLEMENT_INFO_CARD_EVENT_START_UTC_PATH]: {
                fieldName: fields.EVENT_DETAIL_EVENT_START_UTC,
            },
            [SUPPLEMENT_INFO_CARD_EVENT_END_UTC_PATH]: {
                fieldName: fields.EVENT_DETAIL_EVENT_END_UTC,
            },
            agencyId: {
                fieldName: fields.REPORT_AGENCY_ID,
            },
            [RESPONDING_OFFICER_ID_PATH]: {
                fieldName: fields.EVENT_DETAIL_RESPONDING_OFFICER_ID,
            },
            [PERSONNEL_UNIT_ATTR_ID_PATH]: {
                fieldName: fields.EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID,
            },
            supplementTypeAttrIds: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_SUPPLEMENT_TYPE_ATTRIBUTE_ID,
            },
            supplementTypeDescription: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_SUPPLEMENT_TYPE_DESCRIPTION,
            },
        }),
    });
};

const buildSupplementTypeReportAttributesFormModel = ({ reportAttributes }) => {
    const filteredReportAttributes = filter(reportAttributes, {
        attributeType: AttributeTypeEnum.SUPPLEMENT_TYPE.name,
    });
    const supplementTypeAttrIds = map(filteredReportAttributes, 'attributeId');
    const supplementTypeDescription = getDescriptionForAttributeLinks(filteredReportAttributes);
    return { supplementTypeAttrIds, supplementTypeDescription };
};

export const buildPrefilledSupplementInfoCardFormModel = ({ supplementCardPrefillBundle }) => (
    dispatch,
    getState,
    { nexus: { withEntityItems } }
) => {
    const {
        eventDetail: { eventStartUtc, eventEndUtc, respondingOfficerId, personnelUnitAttrId },
        agencyProfile,
        reportAttributes,
        attributes,
        reportDescription,
    } = supplementCardPrefillBundle;
    const agencyId = get(agencyProfile, 'agencyId');
    const {
        supplementTypeAttrIds,
        supplementTypeDescription,
    } = buildSupplementTypeReportAttributesFormModel({ reportAttributes });

    const attributesToStore = map(attributes, convertAttributeToAttributeView);
    dispatch(
        withEntityItems(
            {
                [AGENCY_PROFILES_NEXUS_STATE_PROP]: compact([agencyProfile]),
                [ATTRIBUTES_NEXUS_STATE_PROP]: attributesToStore,
            },
            {
                type: 'SUPPLEMENT_INFO_CARD_PREFILL',
            }
        )
    );

    return {
        description: reportDescription,
        [SUPPLEMENT_INFO_CARD_EVENT_START_UTC_PATH]: eventStartUtc,
        eventEndUtc,
        agencyId,
        [RESPONDING_OFFICER_ID_PATH]: respondingOfficerId,
        [PERSONNEL_UNIT_ATTR_ID_PATH]: personnelUnitAttrId,
        supplementTypeAttrIds,
        supplementTypeDescription,
    };
};

export const buildSupplementInfoCardFormModelFromCadTicket = ({ reportId }) => (
    dispatch,
    getState
) => {
    const state = getState();
    const hydratedSupplementInfo = hydratedSupplementInfoByReportIdSelector(state)(reportId);

    const baseSupplementInfoCardFormModel = dispatch(
        buildSupplementInfoCardFormModel({ reportId })
    );

    // override the existing Event Start Date & Event End Date using the Cad Ticket dates, if
    // they exist.
    const cadTicket = hydratedSupplementInfo.cadTicket;
    const eventTimes = {
        eventStartUtc: trimToMinutes(
            get(cadTicket, 'eventStartDateUtc') ||
                get(hydratedSupplementInfo.eventDetail, 'eventStartUtc')
        ),
        eventEndUtc: trimToMinutes(
            get(cadTicket, 'eventClosedDateUtc') ||
                get(hydratedSupplementInfo.eventDetail, 'eventEndUtc')
        ),
    };

    return {
        ...baseSupplementInfoCardFormModel,
        ...eventTimes,
    };
};

export const buildSupplementInfoCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const hydratedSupplementInfo = hydratedSupplementInfoByReportIdSelector(state)(reportId);

    const {
        report: { description, agencyId },
        reportAttributes,
        eventDetail: { eventStartUtc, eventEndUtc, respondingOfficerId, personnelUnitAttrId },
    } = hydratedSupplementInfo;
    const {
        supplementTypeAttrIds,
        supplementTypeDescription,
    } = buildSupplementTypeReportAttributesFormModel({ reportAttributes });

    return {
        description,
        [SUPPLEMENT_INFO_CARD_EVENT_START_UTC_PATH]: eventStartUtc,
        eventEndUtc,
        agencyId,
        [RESPONDING_OFFICER_ID_PATH]: respondingOfficerId,
        [PERSONNEL_UNIT_ATTR_ID_PATH]: personnelUnitAttrId,
        supplementTypeAttrIds,
        supplementTypeDescription,
    };
};

export const buildSupplementCardBundle = ({ reportId, form }) => (dispatch, getState) => {
    const state = getState();
    const attributes = attributesSelector(state);
    const { model } = form.getState();
    const {
        description,
        [SUPPLEMENT_INFO_CARD_EVENT_START_UTC_PATH]: eventStartUtc,
        eventEndUtc,
        agencyId,
        [RESPONDING_OFFICER_ID_PATH]: respondingOfficerId,
        [PERSONNEL_UNIT_ATTR_ID_PATH]: personnelUnitAttrId,
        supplementTypeAttrIds,
        supplementTypeDescription,
    } = model;

    const eventDetailForBundle = {
        reportId,
        eventStartUtc,
        eventEndUtc,
        respondingOfficerId,
        personnelUnitAttrId,
    };

    const supplementTypeReportAttributesForBundle = assignDescriptionToReportAttributes(
        map(supplementTypeAttrIds, (supplementTypeAttrId) => {
            return {
                reportId,
                attributeType: AttributeTypeEnum.SUPPLEMENT_TYPE.name,
                attributeId: supplementTypeAttrId,
            };
        }),
        attributes,
        supplementTypeDescription
    );

    return {
        eventDetail: eventDetailForBundle,
        reportAttributes: supplementTypeReportAttributesForBundle,
        agencyId,
        description,
    };
};

export const refreshSupplementInfoForm = ({ reportId }) => (dispatch) => {
    const form = getSupplementInfoForm();
    const formModel = dispatch(buildSupplementInfoCardFormModel({ reportId }));
    form.set('', formModel);
};

export const prefillRespondingOfficerId = ({ respondingOfficerId }) => {
    const form = getSupplementInfoForm();
    form.set(RESPONDING_OFFICER_ID_PATH, respondingOfficerId);
};

export const prefillPersonnelUnitAttrId = ({ personnelUnitAttrId }) => {
    const form = getSupplementInfoForm();
    form.set(PERSONNEL_UNIT_ATTR_ID_PATH, personnelUnitAttrId);
};

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { every, findIndex } from 'lodash';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { personProfileViewModelByIdSelector } from '~/client-common/core/domain/person-profiles/state/ui';
import { organizationProfileViewModelByIdSelector } from '~/client-common/core/domain/organization-profiles/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_ORGANIZATION_LABEL,
    NAME_ITEM_LINK_LINK_TYPE_OWNED_BY_LINK_TYPE,
    DISPLAY_ONLY_ITEM_CLAIMANT_LABEL,
} from '~/client-common/core/enums/universal/fields';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import _Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../../core/components/Icon';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { RRFNItems } from '../../../../core/forms/components/NItems';
import { RRFAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { RRFText } from '../../../../core/forms/components/Text';
import { withoutIndex } from '../../../../core/utils/arrayHelpers';
import { showOtherForProofOfOwnershipAttrIdSelector } from '../../state/ui/ownerClaimants';

import { PersonSidePanel } from '../../../../core/persons/components/PersonSidePanel';
import { OrganizationSidePanel } from '../../../../core/organizations/components/OrganizationSidePanel';
import {
    currentReportRENSelector,
    currentReportIdSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import testIds from '../../../../../core/testIds';

const { withRRFActions, withRRFFieldValues } = reactReduxFormHelpers;
const strings = componentStrings.reports.core.OwnerClaimantNItems;

const ClearFloat = styled.div`
    clear: both;
`;
const Button = styled(_Button)`
    width: 135px;
`;
const RightButtons = styled.div`
    float: right;
`;
const EditButton = styled(_Button)`
    padding-right: 15px;
    border-right: 1px solid ${(props) => props.theme.colors.mediumBlue};
`;
const DeleteButton = styled(_Button)`
    padding-left: 15px;
`;
const CancelButton = styled(_Button)`
    margin: 15px 0 0 5px;
`;
const Name = styled.span`
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const LinkedName = compose(
    connect(
        createStructuredSelector({
            personProfileViewModelById: personProfileViewModelByIdSelector,
            organizationProfileViewModelById: organizationProfileViewModelByIdSelector,
            formatAttributeById: formatAttributeByIdSelector,
            showOtherForProofOfOwnershipAttrId: showOtherForProofOfOwnershipAttrIdSelector,
        })
    ),
    withRRFFieldValues({
        proofOfOwnershipAttrId: 'proofOfOwnershipAttrId',
        entityType: 'entityType',
        nameId: 'nameId',
    }),
    withPropsOnChange(
        ['proofOfOwnershipAttrId'],
        ({ proofOfOwnershipAttrId, formatAttributeById, showOtherForProofOfOwnershipAttrId }) => {
            const showProofOfOwnershipOther = showOtherForProofOfOwnershipAttrId(
                proofOfOwnershipAttrId
            );
            const proofOfOwnershipOtherLabel = formatAttributeById(proofOfOwnershipAttrId);
            return { showProofOfOwnershipOther, proofOfOwnershipOtherLabel };
        }
    )
)(function LinkedName({
    personProfileViewModelById,
    organizationProfileViewModelById,
    entityType,
    nameId,
    showProofOfOwnershipOther,
    proofOfOwnershipOtherLabel,
    isClaimants,
    openLegacySidePanel,
    setCloseFocusRefs,
    deleteItemRow,
    widths,
}) {
    const name =
        entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name
            ? // We shouldn't need to default this to an object,
              // but this was causing an issue when adding an org as
              // an owner / claimant, and then navigating away.
              (organizationProfileViewModelById(nameId) || {}).name
            : getViewModelProperties(personProfileViewModelById(nameId)).shortName;

    return (
        <div>
            <Name>{name}</Name>
            <RightButtons>
                <EditButton
                    className={buttonTypes.ICON_LINK}
                    iconLeft={iconTypes.EDIT}
                    onClick={openLegacySidePanel}
                    ref={setCloseFocusRefs}
                >
                    {strings.labels.edit}
                </EditButton>
                <DeleteButton
                    className={buttonTypes.ICON_LINK}
                    iconLeft={iconTypes.TRASH_CAN}
                    onClick={deleteItemRow}
                >
                    {strings.labels.remove}
                </DeleteButton>
            </RightButtons>
            {!isClaimants && (
                <ClearFloat>
                    <RRFAttributeSelect
                        path="proofOfOwnershipAttrId"
                        attributeType="PROOF_OF_OWNERSHIP"
                        width={widths.half}
                    />
                    {showProofOfOwnershipOther && (
                        <RRFText
                            path="proofOfOwnershipOther"
                            label={proofOfOwnershipOtherLabel}
                            width={widths.half}
                        />
                    )}
                </ClearFloat>
            )}
        </div>
    );
});

/**
 * Owners or Claimants N-Items component. Uses Person/Org side panel
 *   to select and edit person/organization.
 * @param {Object}  props
 * @param {boolean} props.isClaimants  determines whether this component is
 *      owners or claimants
 * @param {number}  props.widths       width in pixels for field inputs
 */
export default compose(
    connect(
        createStructuredSelector({
            currentReportREN: currentReportRENSelector,
            currentReportId: currentReportIdSelector,
        })
    ),
    withRRFActions,
    withRRFFieldValues({ owners: 'owners', claimants: 'claimants' }),
    withHandlers({
        updateFormAction({ isClaimants, formActions, owners, claimants }) {
            const path = isClaimants ? 'claimants' : 'owners';
            const currentFormState = isClaimants ? claimants : owners;

            return (formIndex) => (nameData) => {
                const existingIndex = findIndex(currentFormState, nameData);
                const value =
                    existingIndex === formIndex || existingIndex === -1
                        ? // replace or add
                          withoutIndex(currentFormState, formIndex, nameData)
                        : // remove duplicate
                          withoutIndex(currentFormState, formIndex);

                formActions.change(path, value);
            };
        },
    }),
    withHandlers({
        deleteItemRow({ formActions, owners, claimants, isClaimants }) {
            const path = isClaimants ? 'claimants' : 'owners';
            const data = isClaimants ? claimants : owners;

            return (index) => () => {
                formActions.change(path, withoutIndex(data, index));
            };
        },
    }),
    withPropsOnChange(['owners', 'claimants'], ({ isClaimants, owners, claimants }) => {
        const data = isClaimants ? claimants : owners;
        const hideAddButton = data && data.length > 0 && !every(data, 'nameId');
        return { hideAddButton };
    })
)(function OwnerClaimantNItems({
    deleteItemRow,
    isClaimants,
    widths,
    hideAddButton,
    updateFormAction,
    currentReportREN,
    currentReportId,
}) {
    const {
        NAME_ITEM_LINK_LINK_TYPE_OWNED_BY_LINK_TYPE: ownerLabel,
        DISPLAY_ONLY_ITEM_CLAIMANT_LABEL: claimantLabel,
    } = useFields([NAME_ITEM_LINK_LINK_TYPE_OWNED_BY_LINK_TYPE, DISPLAY_ONLY_ITEM_CLAIMANT_LABEL]);
    const getNameSidePanelProps = ({ index, nameId }) => {
        const linkType = isClaimants ? LinkTypesEnum.CLAIMED_BY : LinkTypesEnum.OWNED_BY;
        const updateFormActionForIndex = updateFormAction(index);

        return {
            linkType,
            entityId: nameId, // nameId = edit, undefined = new
            onAddSuccess: (nameItemLink) => updateFormActionForIndex({ ...nameItemLink }),
            ownerId: currentReportId,
            ownerType: EntityTypeEnum.REPORT.name,
            renForRecents: currentReportREN,
        };
    };

    return (
        <RRFNItems
            path={isClaimants ? 'claimants' : 'owners'}
            addText={isClaimants ? claimantLabel : ownerLabel}
            addItemOnDirty={false}
            addItemOnEmpty={false}
            automaticallyIncludeDeleteButton={false}
            hideAddButtonOnEmptyItem={hideAddButton}
        >
            {(formData, index) => (
                <OwnerClaimantItem
                    getNameSidePanelProps={getNameSidePanelProps}
                    index={index}
                    deleteItemRow={deleteItemRow}
                    formData={formData}
                    isClaimants={isClaimants}
                    widths={widths}
                />
            )}
        </RRFNItems>
    );
});

const OwnerClaimantItem = ({
    getNameSidePanelProps,
    formData,
    index,
    deleteItemRow,
    isClaimants,
    widths,
}) => {
    const nameSidePanelSaveRef = useRef(null);
    const organizationLabel = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];
    if (formData.nameId) {
        const nameSidePanelProps = getNameSidePanelProps({
            index,
            nameId: formData.nameId,
        });
        const OverlayComponent =
            formData.entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name
                ? OrganizationSidePanel
                : PersonSidePanel;
        const overlayId =
            formData.entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name
                ? `${overlayIdEnum.ORGANIZATION_OVERLAY_OWNER_CLAIMAINT_N_ITEMS}.${index}`
                : `${overlayIdEnum.PERSON_OVERLAY_OWNER_CLAIMAINT_N_ITEMS}.${index}`;

        return (
            <OverlayComponent
                overlayId={overlayId}
                renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => (
                    <LinkedName
                        isClaimants={isClaimants}
                        openLegacySidePanel={open}
                        setCloseFocusRefs={(ref) => {
                            setCloseFocusRefs(ref);
                            nameSidePanelSaveRef.current = ref;
                        }}
                        deleteItemRow={deleteItemRow(index)}
                        widths={widths}
                    />
                )}
                {...nameSidePanelProps}
            />
        );
    }
    return (
        <div>
            <PersonSidePanel
                overlayId={overlayIdEnum.PERSON_OVERLAY_OWNER_CLAIMAINT_N_ITEMS}
                renderButton={({ overlayBase: { open }, setCancelFocusRef }) => (
                    <Button
                        testId={testIds.OWNER_CLAIMANT_PERSON_BUTTON}
                        className={buttonTypes.SECONDARY_BOLD}
                        onClick={open}
                        ref={setCancelFocusRef}
                    >
                        {strings.labels.person}
                    </Button>
                )}
                saveRef={nameSidePanelSaveRef}
                {...getNameSidePanelProps({ index })}
            />
            <OrganizationSidePanel
                overlayId={overlayIdEnum.ORGANIZATION_OVERLAY_OWNER_CLAIMAINT_N_ITEMS}
                renderButton={({ overlayBase: { open }, setCancelFocusRef }) => (
                    <Button
                        className={buttonTypes.SECONDARY_BOLD}
                        onClick={open}
                        ref={setCancelFocusRef}
                    >
                        {strings.labels.organization(organizationLabel)}
                    </Button>
                )}
                saveRef={nameSidePanelSaveRef}
                {...getNameSidePanelProps({ index })}
            />
            <CancelButton
                className={buttonTypes.ICON_LINK}
                iconLeft={iconTypes.CLOSE_X}
                onClick={deleteItemRow(index)}
            >
                {strings.labels.cancel}
            </CancelButton>
        </div>
    );
};

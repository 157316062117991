import { GeoAlias } from '@mark43/rms-api';

import { RmsAction } from '../../../core/typings/redux';
import {
    createBulkImportActionCreator,
    createExportActionCreator,
    createValidateBulkActionCreator,
} from '../core/utils/importExportHelpers';
import { geoAliasesResource } from './resource';
import * as actionTypes from './actionTypes';

export type GeoAliasesLoadSuccessT = ReturnType<typeof loadGeoAliasesSuccess>;
export type GeoAliasesDeleteSuccessT = ReturnType<typeof deleteGeoAliasSuccess>;
export type GeoAliasesDeleteFailureT = ReturnType<typeof deleteGeoAliasFailure>;
export type GeoAliasesSaveSuccessT = ReturnType<typeof saveGeoAliasSuccess>;
export type GeoAliasesSaveFailureT = ReturnType<typeof saveGeoAliasFailure>;
export type GeoAliasesActions =
    | ReturnType<typeof loadGeoAliasesStart>
    | GeoAliasesLoadSuccessT
    | ReturnType<typeof loadGeoAliasesFailure>
    | ReturnType<typeof deleteGeoAliasStart>
    | GeoAliasesDeleteSuccessT
    | GeoAliasesDeleteFailureT
    | ReturnType<typeof saveGeoAliasStart>
    | GeoAliasesSaveSuccessT
    | GeoAliasesSaveFailureT;

function loadGeoAliasesStart() {
    return {
        type: actionTypes.LOAD_GEO_ALIASES_START,
    } as const;
}

function loadGeoAliasesSuccess(aliases: GeoAlias[]) {
    return {
        type: actionTypes.LOAD_GEO_ALIASES_SUCCESS,
        payload: aliases,
    } as const;
}

function loadGeoAliasesFailure(err: Error) {
    return {
        type: actionTypes.LOAD_GEO_ALIASES_FAILURE,
        error: true,
        payload: err,
    } as const;
}

export const loadGeoAliases = (): RmsAction<Promise<void>> => {
    return (dispatch) => {
        dispatch(loadGeoAliasesStart());

        return geoAliasesResource
            .getAliases()
            .then((aliases) => {
                dispatch(loadGeoAliasesSuccess(aliases));
            })
            .catch((err) => {
                dispatch(loadGeoAliasesFailure(err));
            });
    };
};

function deleteGeoAliasStart(alias: GeoAlias) {
    return {
        type: actionTypes.DELETE_GEO_ALIAS_START,
        payload: alias,
    } as const;
}

function deleteGeoAliasSuccess(alias: GeoAlias) {
    return {
        type: actionTypes.DELETE_GEO_ALIAS_SUCCESS,
        payload: alias,
    } as const;
}

function deleteGeoAliasFailure(err: Error) {
    return {
        type: actionTypes.DELETE_GEO_ALIAS_FAILURE,
        error: true,
        payload: err,
    } as const;
}

export const deleteGeoAlias = (alias: GeoAlias): RmsAction<Promise<void>> => {
    return (dispatch) => {
        dispatch(deleteGeoAliasStart(alias));

        return geoAliasesResource
            .deleteAlias(alias)
            .then(() => {
                dispatch(deleteGeoAliasSuccess(alias));
            })
            .catch((err) => {
                dispatch(deleteGeoAliasFailure(err));
            });
    };
};

function saveGeoAliasStart(alias: GeoAlias) {
    return {
        type: actionTypes.SAVE_GEO_ALIAS_START,
        payload: alias,
    } as const;
}

function saveGeoAliasSuccess(alias: GeoAlias) {
    return {
        type: actionTypes.SAVE_GEO_ALIAS_SUCCESS,
        payload: alias,
    } as const;
}

function saveGeoAliasFailure(err: Error) {
    return {
        type: actionTypes.SAVE_GEO_ALIAS_FAILURE,
        error: true,
        payload: err,
    } as const;
}

export const saveGeoAlias = (
    alias: GeoAlias
): RmsAction<Promise<GeoAliasesSaveSuccessT | GeoAliasesSaveFailureT>> => {
    return function (dispatch) {
        dispatch(saveGeoAliasStart(alias));

        return geoAliasesResource
            .upsertAlias(alias)
            .then((alias) => {
                return dispatch(saveGeoAliasSuccess(alias));
            })
            .catch((err) => {
                return dispatch(saveGeoAliasFailure(err));
            });
    };
};

export const purgeGeoAliases = (): RmsAction<Promise<void>> => {
    return function (dispatch) {
        return geoAliasesResource
            .purgeAliases()
            .then(() => dispatch(loadGeoAliases()))
            .catch((err) => {
                dispatch(loadGeoAliasesFailure(err));
            });
    };
};

export const importGeoAliases = createBulkImportActionCreator(geoAliasesResource.importGeoAliases);

export const validateGeoAliasesImport = createValidateBulkActionCreator(
    geoAliasesResource.validateGeoAliasesImport,
    importGeoAliases
);

export const exportGeoAliases = createExportActionCreator(geoAliasesResource.exportGeoAliases);

import { RefContextEnum } from '@mark43/rms-api';
import { createFormConfiguration, lifecycleOptions } from 'markformythree';
import React from 'react';
import { DELETE_CHAIN_EVENT_REQUEST_REASON_FOR_DELETION } from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { initialState } from '../../core/state/forms/deleteChainEventForm';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTTextArea } from '../../../core/forms/components/TextArea';

const strings = componentStrings.evidence.chainOfCustody.DeleteChainEventForm;

const formConfiguration = createFormConfiguration({
    itemRequestReasonForDeletion: {
        fieldName: DELETE_CHAIN_EVENT_REQUEST_REASON_FOR_DELETION,
    },
});

const renderForm = () => (
    <ArbiterMFTTextArea path="itemRequestReasonForDeletion" width={550} rows={6} />
);

const DeleteChainEventForm = () => (
    <div>
        <p>{strings.confirmDeleteChainEvent}</p>
        <ArbiterForm
            context={RefContextEnum.FORM_DELETE_CHAIN_EVENT.name}
            configuration={formConfiguration}
            initialState={initialState}
            name={RefContextEnum.FORM_DELETE_CHAIN_EVENT.name}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            render={renderForm}
        />
    </div>
);

export default DeleteChainEventForm;

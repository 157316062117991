import { createSelector } from 'reselect';
import { mapValues } from 'lodash';

import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
} from '../../../../../helpers/viewModelHelpers';
import { firearmsSelector } from '../data';
import { itemIdentifierViewModelsByItemIdSelector } from '../../../item-identifiers/state/ui';
import { propertyStatusViewModelsByItemProfileIdSelector } from '../../../property-statuses/state/ui';
import { formatAtfManufacturerByIdSelector } from '../../../etrace-atf/state/ui';

const buildFirearmViewModel = ({
    firearm,
    formatAttributeById,
    itemIdentifierViewModelsByItemId,
    propertyStatusViewModelsByItemProfileId,
    formatAtfManufacturerById,
}) => {
    const makeViewModel = buildViewModel({
        recursive: true,
        mappers: [
            allSingleAttributeValuesMapper,
            boolToDisplayMapper,
            ({ id }) => ({
                identifiers: itemIdentifierViewModelsByItemId(id),
            }),
            ({ id }) => ({
                propertyStatuses: propertyStatusViewModelsByItemProfileId(id),
            }),
            ({ atfManufacturerId }) => ({
                atfManufacturer: formatAtfManufacturerById(atfManufacturerId),
            }),
        ],
        helpers: {
            formatAttributeById,
        },
    });
    return makeViewModel(firearm);
};

const firearmViewModelsSelector = createSelector(
    firearmsSelector,
    formatAttributeByIdSelector,
    itemIdentifierViewModelsByItemIdSelector,
    propertyStatusViewModelsByItemProfileIdSelector,
    formatAtfManufacturerByIdSelector,
    (
        firearms,
        formatAttributeById,
        itemIdentifierViewModelsByItemId,
        propertyStatusViewModelsByItemProfileId,
        formatAtfManufacturerById
    ) => {
        return mapValues(firearms, (firearm) =>
            buildFirearmViewModel({
                firearm,
                formatAttributeById,
                itemIdentifierViewModelsByItemId,
                propertyStatusViewModelsByItemProfileId,
                formatAtfManufacturerById,
            })
        );
    }
);

export const firearmViewModelByIdSelector = createSelector(
    firearmViewModelsSelector,
    (firearmViewModels) => (id) => firearmViewModels[id]
);

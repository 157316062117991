import { TableCell as _Cell } from 'components-mark43';
import { chain } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import withFields from '~/client-common/core/fields/components/withFields';
import * as fields from '~/client-common/core/enums/universal/fields';
import { EmDash } from '../../../../../core/components/HtmlEntities';

const Cell = styled(_Cell)`
    padding: 0 10px;
`;

const TextCell = styled(Cell)`
    font-size: var(--arc-fontSizes-sm);
    hyphens: auto;
    padding: 0 10px;
    word-wrap: break-word;
`;

const HeaderLabel = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
`;

const _HeaderCell = ({ children, className }) => (
    <Cell className={className}>
        <HeaderLabel style={{ marginRight: '5px' }}>{children}</HeaderLabel>
    </Cell>
);

const HeaderCell = styled(_HeaderCell)`
    border-radius: 4px;
    padding: 5px 10px;

    span {
        float: left;
        user-select: none;
    }
`;

export const CustodyStatusHeaderCell = withFields([
    fields.PROPERTY_STATUS_REASON_FOR_POLICE_CUSTODY_ATTR_ID,
    fields.PROPERTY_STATUS_IS_IMPOUNDED,
])(({ isVehicle, fieldDisplayNames }) => (
    <HeaderCell>
        {isVehicle
            ? fieldDisplayNames.PROPERTY_STATUS_IS_IMPOUNDED
            : fieldDisplayNames.PROPERTY_STATUS_REASON_FOR_POLICE_CUSTODY_ATTR_ID}
    </HeaderCell>
));

export const PoliceCustodyCell = ({ isVehicle, data }) =>
    chain(data)
        .get('mergedPropertyStatus')
        .thru((propertyStatus = {}) => {
            const viewModelProperties = getViewModelProperties(propertyStatus);
            if (!isVehicle) {
                return viewModelProperties.reasonForPoliceCustodyAttrId;
            } else if (!propertyStatus.isImpounded) {
                return viewModelProperties.isImpounded;
            } else {
                return (
                    <div>
                        {viewModelProperties.isImpounded}
                        <br />
                        {propertyStatus.towingLocation}
                    </div>
                );
            }
        })
        .thru((display) => <TextCell>{display || <EmDash />}</TextCell>)
        .value();

export const PropertyStatusCell = ({ data }) =>
    chain(data)
        .get('propertyStatus')
        .map((propertyStatus, index) => (
            <TextCell key={`status_${index}`}>
                {chain(propertyStatus)
                    .thru(getViewModelProperties)
                    .get('propertyStatusAttrId')
                    .thru((value) => value || <EmDash />)
                    .value()}
            </TextCell>
        ))
        .thru((content) => <div>{content}</div>)
        .value();

export const StatusHeaderCell = () => (
    <HeaderCell>{componentStrings.reports.core.ItemCard.statusHeaderText}</HeaderCell>
);

export const TitleCell = styled(Cell)`
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-size: var(--arc-fontSizes-sm);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const TitleHeaderCell = ({ isVehicle }) => (
    <HeaderCell>
        {isVehicle
            ? componentStrings.reports.core.ItemCard.vehicleHeaderText
            : componentStrings.reports.core.ItemCard.propertyHeaderText}
    </HeaderCell>
);

export const ToggleCell = styled(Cell)`
    flex: 1 1 auto;
    margin: 5px 0;
    padding: 0;
`;

import {
    UsageSourceModuleEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    EntityTypeEnum,
} from '@mark43/rms-api';

import { executeSavedSearchFromQueryParam } from '../../search/core/state/ui';
import { createUsageLog } from '../../admin/usage-logs/state/data';
import { reportsDashboardSearch, searchInitialReports } from './state/ui';

export function onReportsEnter(nextState, replace) {
    this.dispatch(reportsDashboardSearch.actionCreators.setIsInitialSearch(true));
    this.dispatch(
        createUsageLog({
            primaryEntityType: EntityTypeEnum.REPORT.name,
            action: UsageActionEnum.VIEWED_REPORTS_DASHBOARD.name,
            completion: UsageCompletionEnum.SUCCEEDED.name,
            sourceModule: UsageSourceModuleEnum.REPORTS.name,
        })
    );

    this.dispatch(
        executeSavedSearchFromQueryParam({
            searchModule: reportsDashboardSearch,
            nextState,
            replace,
        })
    ).then((executed) => {
        if (!executed) {
            this.dispatch(searchInitialReports());
        }
    });
}

import React from 'react';
import { simpleControl } from 'markformythree';
import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

const today = new Date();
const currentYear = today.getFullYear() + 7;
const years = [];
for (let i = 0; i < 110; i++) {
    const year = currentYear - i;
    years.push({
        display: `${year}`,
        value: year,
    });
}

const YearSelect = ({ ...otherProps }) => <Select options={years} {...otherProps} />;

export const RRFYearSelect = connectRRFInput(YearSelect);
export const ArbiterMFTYearSelect = arbiterMFTInput(YearSelect);
export const MFTYearSelect = simpleControl(YearSelect);

import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Recent Entities Resource',
    methods: {
        getRecentPersons(renForRecents, ownerType, ownerId) {
            return req({
                method: 'GET',
                url: 'person/profile/recent',
                params: {
                    owner_type: ownerType,
                    owner_id: ownerId,
                    reporting_event_number: renForRecents,
                },
            });
        },
        getRecentOrganizationsDeprecated(renForRecents, ownerType, ownerId) {
            return req({
                method: 'GET',
                url: 'organization/profile/recent',
                params: {
                    owner_type: ownerType,
                    owner_id: ownerId,
                    reporting_event_number: renForRecents,
                },
            });
        },
        getRecentOrganizations(renForRecents, ownerType, ownerId) {
            return req({
                method: 'GET',
                url: 'organization/elastic/recent',
                params: {
                    owner_type: ownerType,
                    owner_id: ownerId,
                    reporting_event_number: renForRecents,
                },
            });
        },
        getRecentLocations(renForRecents, ownerType, ownerId) {
            return req({
                method: 'GET',
                url: 'master/locations/recent',
                params: {
                    owner_type: ownerType,
                    owner_id: ownerId,
                    reporting_event_number: renForRecents,
                },
            });
        },
        getRecentItems(renForRecents, ownerType, ownerId) {
            return req({
                method: 'GET',
                url: 'item/profile/recent',
                params: {
                    owner_type: ownerType,
                    owner_id: ownerId,
                    reporting_event_number: renForRecents,
                },
            });
        },
    },
});

import React from 'react';
import { get, defer, omit } from 'lodash';
import { Text, cssVar } from 'arc';

import styled from 'styled-components';
import {
    Tab as ReactTab,
    Tabs as ReactTabs,
    TabList as ReactTabList,
    TabPanel as ReactTabPanel,
} from 'react-tabs';
import {
    AnalyticsPropertyEnum,
    analyticsInteractionEnum,
} from '../../analytics/constants/analyticsEnum';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';

const StyledTabs = styled(ReactTabs)`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`;

export const Tabs = ({ onSelect, testId, ...props }) => {
    const { filteredTrack } = useAnalytics();
    return (
        <StyledTabs
            data-test-id={testId}
            onSelect={(index, lastIndex, e) => {
                filteredTrack(
                    {
                        [AnalyticsPropertyEnum.TABS]: testId,
                        [AnalyticsPropertyEnum.TAB_INDEX]: index,
                        [AnalyticsPropertyEnum.INTERACTION]:
                            get(e, 'type') === 'click'
                                ? analyticsInteractionEnum.CLICK
                                : analyticsInteractionEnum.KEYBOARD,
                    },
                    testId
                );
                if (onSelect) {
                    onSelect(index);
                }
            }}
            {...props}
        />
    );
};

// This is how the library keeps tracks tab components
Tabs.tabsRole = ReactTabs.tabsRole;

export const TabList = styled(ReactTabList)`
    display: flex;
    border-bottom: 2px solid ${(props) => props.theme.colors.lightGrey};

    /* Reset default ul styling */
    list-style: none;
    padding: 0;
    margin: 0;
`;

const StyledTab = styled((props) => <ReactTab {...omit(props, 'active')} />)`
    position: relative;
    cursor: pointer;
    padding: 10px;
    color: ${(props) =>
        props.active ? props.theme.colors.cobaltBlue : props.theme.colors.mediumLightGrey};

    &:hover {
        background-color: ${(props) => props.theme.colors.brightBlueAlpha};
    }
    ${(props) =>
        props.active &&
        `&::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            height: 2px;
            width: 100%;
            background-color: ${props.theme.colors.cobaltBlue};
        }`}
`;

const StyledLabel = styled(Text)`
    color: inherit;
    font-size: ${cssVar('arc.fontSizes.sm')};
    font-weight: ${cssVar('arc.fontWeights.semibold')};
`;

export const Tab = ({ onClick, children, ...props }) => (
    <StyledTab
        children={<StyledLabel>{children}</StyledLabel>}
        tabIndex="0"
        onClick={(e) => {
            const { currentTarget } = e;
            // Need to defer because of focus issue
            defer(() => currentTarget && currentTarget.focus());

            if (onClick) {
                onClick(e);
            }
        }}
        {...props}
    />
);

// This is how the library keeps tracks tab components
Tab.tabsRole = ReactTab.tabsRole;

export const TabPanel = styled(ReactTabPanel)`
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    /* The first element in the panel should have padding */
    > *:first-child {
        padding-top: 15px;
    }
`;

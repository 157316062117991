import _ from 'lodash';

/**
 * Parse the relations between the arguments into a more meaningful array of
 *   objects each corresponding to a code link, and each of which corresponds to
 *   a code source.
 * @param    {Object}   [options.attribute]
 * @param    {Object}   options.type
 * @param    {Object}   options.codeMappings An object that comes from the
 *   server for the given attribute type and has 3 properties: `codes`, `links`,
 *   `codeMappings`.
 * @return   {Object[]}
 * @property {Object}   codeSource
 * @property {Object[]} codes         Array with at least one element.
 * @property {Object}   [codeLink]    May be `undefined`.
 * @property {Object}   [codeMapping] May be `undefined`.
 */
export const parseCodeLinks = ({ attribute, type, codeMappings }) => {
    return _(type.codeSources)
        .map((codeSource) => {
            // all the possible codes that can be linked for the code source
            const codes = _.filter(_.get(codeMappings, 'codes'), {
                codeType: {
                    id: codeSource.codeTypeId,
                },
            });

            if (codes.length === 0) {
                return null;
            }

            // the code that is actually linked
            const codeLink = _.find(
                codeMappings.links,
                (link) =>
                    link.codeTypeId === codeSource.codeTypeId &&
                    link.attributeId === _.get(attribute, 'id')
            );

            const codeMapping = _.find(codeMappings.mappings, {
                codeTypeId: codeSource.codeTypeId,
            });

            return { codeSource, codes, codeLink, codeMapping };
        })
        .filter()
        .value();
};

//

/**
 * Wrapper around `parseCodeLinks()` to format the code links into data objects
 *   suitable for forms.
 * @param    {Object}   [options.attribute]
 * @param    {Object}   options.type
 * @param    {Object}   options.codeMappings An object that comes from the
 *   server for the given attribute type and has 3 properties: `codes`, `links`,
 *   `codeMappings`.
 * @return   {Object[]}
 * @property {number}   codeTypeId     Unique id.
 * @property {string}   codeTypeSource
 * @property {number}   [attributeId]  Not set for a new attribute.
 * @property {string}   attributeType
 * @property {number}   codeId         The only one of these fields that is
 *   directly editable by the user.
 */
export const formatCodeLinks = ({ attribute, type, codeMappings }) => {
    return _.map(parseCodeLinks({ attribute, type, codeMappings }), ({ codeSource, codeLink }) => {
        return {
            codeTypeId: codeSource.codeTypeId,
            codeTypeSource: codeSource.codeSourceName,
            attributeId: _.get(attribute, 'id'),
            attributeType: type.attributeType,
            codeId: _.get(codeLink, 'codeId'),
        };
    });
};

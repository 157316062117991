import React from 'react';

import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import CasesSearchFormFooter from './CasesSearchFormFooter';

/**
 * Wrapper component for a cases search form.
 * @param {function} props.onSubmit
 * @param {function} props.onReset
 */
export default function CasesSearchForm({
    onSubmit,
    onReset,
    query,
    children,
    renderSearchButton,
    searchModule,
    ...otherProps
}) {
    return (
        <div className="cases-search-form">
            <ReactReduxForm {...otherProps}>
                {children}
                <CasesSearchFormFooter
                    renderSearchButton={renderSearchButton}
                    onSubmit={onSubmit}
                    onReset={onReset}
                    query={query}
                    searchModule={searchModule}
                />
            </ReactReduxForm>
        </div>
    );
}

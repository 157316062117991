import { normalize } from '~/client-common/helpers/dataHelpers';
import validationActionTypes from '../actions/types/validationActionTypes';

const initialState = {
    rules: {},
};

export function validationConfigReducer(state = initialState, action) {
    switch (action.type) {
        case validationActionTypes.LOAD_RULES_SUCCESS:
            return {
                ...state,
                rules: normalize(action.payload),
            };
        default:
            return state;
    }
}

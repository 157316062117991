import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { createStructuredSelector } from 'reselect';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { createVehiclesResultsSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import createSearchModule from '../../../core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import advancedSearchVehiclesForm, {
    convertAdvancedSearchVehiclesFormModelToFilterGroups,
    convertAdvancedSearchVehiclesElasticQueryToFormModel,
} from '../forms/advancedSearchVehiclesForm';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../core/state/ui';
import recursivelyConvertElasticLocationQueries from '../../../core/utils/recursivelyConvertElasticLocationQueries';

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object}  boundSelectors extra selectors needed by search module
 * @return {Object}   Array of filter group view models.
 */
function convertAdvancedSearchVehiclesElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    return convertAdvancedSearchVehiclesFormModelToFilterGroups(
        convertAdvancedSearchVehiclesElasticQueryToFormModel(elasticQuery),
        formatFieldValue,
        boundSelectors
    );
}

/**
 * Redux module for advanced search vehicles.
 * @type {Object}
 */
export default createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.VEHICLE.name,
    baseUiSelector: (state) => state.ui.advancedSearch.vehicles,
    form: advancedSearchVehiclesForm,
    elasticQueryToFilterGroups: convertAdvancedSearchVehiclesElasticQueryToFilterGroups,
    resourceMethod: elasticSearchResource.searchVehicles,
    resultsContainerClassName: 'vehicles-results-container',
    createResultsViewModelsSelector: createVehiclesResultsSelector,
    searchResultToRoutePath: ({ id }) => `/profiles/vehicles/${id}`,
    transformElasticQueryBeforeSearchSelector: (state) => (elasticQuery) => {
        const transformIds = transformElasticQueryIdsToDisplayValuesSelector(state);
        const queryWithTransformedIds = transformIds(elasticQuery);
        return recursivelyConvertElasticLocationQueries(queryWithTransformedIds, {
            legacyQueryProp: 'locations',
            newQueryProp: 'involvedLocations',
        });
    },
    selectorToBind: createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
    }),
});

import { NotificationStatusEnum, DeliveryMethodEnum } from '@mark43/notifications-api';
import _, { map, mapValues } from 'lodash';
import { createSelector } from 'reselect';

import { formatFieldByNameSelector } from '../../../../fields/state/config';

import {
    buildViewModel,
    buildAllMiniUserFormatsMapper,
} from '../../../../../helpers/viewModelHelpers';
import { isProductModuleActiveSelector } from '../../../product-modules/state/data';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';
import { userNotificationsSelector } from '../data';
import { notificationCategoryConfigs } from '../../configuration';
import { userNotificationMapper } from '../../utils/userNotificationHelpers';
import { notificationTypesSelector } from '../../../notification-types/state/data';
import notificationTypeEnum from '../../../../enums/universal/notificationTypeEnum';

/**
 * Return a selector that builds user notification view models. Can be used in
 *   for both polling and search.
 * @param  {function} baseSelector
 * @param  {boolean}  [options.normalize=true] Whether to return an object or
 *   array.
 * @return {function}
 */
export function createUserNotificationViewModelsSelector(baseSelector, { normalize = true } = {}) {
    return createSelector(
        baseSelector,
        allMiniUserFormatsByIdSelector,
        (userNotifications, allMiniUserFormatsById) => {
            const mapCollection = normalize ? mapValues : map;
            return mapCollection(
                userNotifications,
                buildViewModel({
                    mappers: [buildAllMiniUserFormatsMapper(), userNotificationMapper],
                    helpers: {
                        allMiniUserFormatsById,
                    },
                })
            );
        }
    );
}

/**
 * All notification view models, keyed by id.
 * @type {Object}
 */
const userNotificationViewModelsSelector = createUserNotificationViewModelsSelector(
    userNotificationsSelector
);

/**
 * View models of all web app notifications in data state that are not archived, sorted
 *   by created date descending.
 * @param {number} from
 * @param {number} size
 */
export const sortedUserWebAppNotificationViewModelsSelector = createSelector(
    userNotificationViewModelsSelector,
    (userNotificationViewModels) => (from, size) =>
        _(userNotificationViewModels)
            .toArray()
            .reject({ notificationStatus: NotificationStatusEnum.ARCHIVED.name })
            .filter({ deliveryMethod: DeliveryMethodEnum.WEB_APP.name })
            .sortBy('userNotificationCreatedDateUtc')
            .reverse()
            .slice(from, from + size)
            .value()
);

/**
 * Notification category configs for the current department based on its feature
 *   flags. If a feature flag is off, its corresponding notification category is
 *   filtered out.
 * @type {Object[]}
 */
export const notificationCategoryConfigsSelector = createSelector(
    isProductModuleActiveSelector,
    notificationTypesSelector,
    formatFieldByNameSelector,
    (isProductModuleActive, notificationTypes, formatFieldByName) => {
        const relevantNotificationTypes = _(notificationTypeEnum)
            .pick(notificationTypes)
            .groupBy('category')
            .value();

        return _(notificationCategoryConfigs)
            .filter(({ productModule }) => !productModule || isProductModuleActive(productModule))
            .map((categoryConfig) => {
                const { fieldName: categoryFieldName, display: categoryDisplay } = categoryConfig;
                return {
                    ...categoryConfig,
                    display: getDisplayValue(categoryFieldName, categoryDisplay, formatFieldByName),
                    notificationTypes: _(
                        relevantNotificationTypes[categoryConfig.notificationCategory] || []
                    )
                        .map((notificationType) => {
                            const {
                                fieldName: notificationTypeFieldName,
                                display: notificationTypeDisplay,
                            } = notificationType;
                            return {
                                ...notificationType,
                                display: getDisplayValue(
                                    notificationTypeFieldName,
                                    notificationTypeDisplay,
                                    formatFieldByName
                                ),
                            };
                        })
                        .value(),
                };
            })
            .value();
    }
);

export function getDisplayValue(fieldName, display, formatFieldByName) {
    if (typeof display === 'function') {
        const fieldDisplayName = formatFieldByName(fieldName);
        return display(fieldDisplayName || '');
    }

    return display;
}

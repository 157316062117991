import { filter, forEach, get, map, noop, isEmpty, includes } from 'lodash';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';
import { useDispatch, useSelector } from 'react-redux';
import { compose, defaultProps, setPropTypes, withHandlers } from 'recompose';
import componentStrings from '~/client-common/core/strings/componentStrings';
import getFilesResource from '~/client-common/core/domain/files/resources/filesResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    openConfirmationModal,
    closeConfirmationModal,
} from '../../../../legacy-redux/actions/boxActions';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { InlineBanner } from '../../components/InlineBanner';
import testIds from '../../../../core/testIds';
import { pollingFileSecurityScannerUpdateS3Files } from '../../../attachments/core/state/ui/securityScanning';
import { startPollingForFileSecurityScanner } from '../../../attachments/core/state/ui/securityScanningPolling';
import Row from '../../components/Row';
import {
    UploadErrorType,
    getUploadResponsesFromUploadIntermediates,
    validateUploadBegin,
    uploadFileToS3,
    validateAndFilterOutFiles,
} from '../helpers/uploadHelpers';

const { connectRRFInput } = reactReduxFormHelpers;
const strings = componentStrings.attachments.upload;

function completeFileUpload(
    fileServerFiles,
    onUploading,
    onSuccess,
    onError,
    successfulFileUploadIntermediates,
    viewFileUploadIntermediates,
    applicationSettings
) {
    const resource = getFilesResource();
    return resource
        .uploadComplete(fileServerFiles)
        .then((fileUploadResponses) => {
            if (!applicationSettings.RMS_FEDRAMP_FILE_UPLOAD_STATUSES_ENABLED) {
                onSuccess(
                    getUploadResponsesFromUploadIntermediates(
                        fileUploadResponses,
                        successfulFileUploadIntermediates,
                        fileServerFiles
                    )
                );
            } else {
                onUploading(
                    getUploadResponsesFromUploadIntermediates(
                        fileUploadResponses,
                        successfulFileUploadIntermediates,
                        fileServerFiles
                    )
                );
            }
            return fileUploadResponses;
        })
        .catch((error) => {
            onError(
                error.message || strings.uploadFailed,
                UploadErrorType.UPLOAD_FAILURE,
                viewFileUploadIntermediates
            );
        });
}

let successfulFileUploadIntermediates = [];

function Upload(props) {
    const dispatch = useDispatch();
    const applicationSettings = useSelector(applicationSettingsSelector);

    let uploadBatchInterval;

    const { error, testId } = props;

    useEffect(() => {
        return () => {
            if (uploadBatchInterval) {
                clearInterval(uploadBatchInterval);
            }
        };
    }, [dispatch, uploadBatchInterval]);

    const reject = () => {
        const { onError = noop } = this.props;
        onError(strings.uploadFailed, UploadErrorType.UPLOAD_FAILURE, []);
    };

    const upload = (rawFiles) => {
        const {
            onStart = noop,
            onUploading = noop,
            onSuccess,
            onError = noop,
            onAllBatchesComplete = noop,
            maxFiles,
            openInfectedFilesModal,
        } = props;

        if (maxFiles && rawFiles.length > maxFiles) {
            onError(`Maximum files exceeded`, UploadErrorType.MAXIMUM_EXCEEDED, rawFiles);
            return;
        }

        const fileNames = map(rawFiles, 'name');
        const resource = getFilesResource();
        onStart(rawFiles);
        resource
            // 1. Get presigned URL from backend
            .uploadBegin(fileNames)
            .then((fileUploadIntermediates) => {
                validateUploadBegin(fileUploadIntermediates, fileNames);

                // we augment the file upload intermediate so it can link the file upload intermediates
                // to the raw files called in onstart
                const viewFileUploadIntermediates = map(
                    fileUploadIntermediates,
                    (fileUploadIntermediate, i) => ({
                        ...fileUploadIntermediate,
                        file: rawFiles[i],
                        preview: rawFiles[i].preview,
                    })
                );

                const newFileUploadIntermediates = validateAndFilterOutFiles(
                    viewFileUploadIntermediates,
                    onError
                );

                // Keep track of all newly added uploads
                forEach(newFileUploadIntermediates, (newUpload) =>
                    successfulFileUploadIntermediates.push(newUpload)
                );

                if (newFileUploadIntermediates.length === 0) {
                    return;
                }

                /*
                    Make the security scanner's callback function
                    This function will run every time a poll happens
                */
                const securityScannerCallback = ({
                    cleanFiles,
                    dirtyFiles,
                    pendingFiles,
                    deletedFiles,
                    failedFiles,
                    uploadingDirtyFiles,
                }) => {
                    if (!!dirtyFiles.length) {
                        openInfectedFilesModal(dirtyFiles, uploadingDirtyFiles);
                    }

                    // if there are some clean files, then mark them as successful upload
                    if (cleanFiles.length > 0) {
                        const cleanAttachments = getUploadResponsesFromUploadIntermediates(
                            cleanFiles,
                            successfulFileUploadIntermediates
                        );
                        onSuccess(cleanAttachments);

                        successfulFileUploadIntermediates = filter(
                            successfulFileUploadIntermediates,
                            (sfui) =>
                                !includes(
                                    map(cleanFiles, 'file.fileServerId'),
                                    sfui.fileServerFile.id
                                )
                        );
                    }

                    successfulFileUploadIntermediates = filter(
                        successfulFileUploadIntermediates,
                        (sfui) =>
                            !includes(
                                map(deletedFiles, 'file.fileServerId'),
                                sfui.fileServerFile.id
                            )
                    );

                    if (failedFiles.length > 0) {
                        onError(
                            strings.uploadFailedRetryOrContactSupport,
                            UploadErrorType.UPLOAD_FAILURE,
                            failedFiles
                        );
                    }

                    // allBatchesComplete runs when there are no more pending files
                    if (pendingFiles.length === 0) {
                        onAllBatchesComplete();
                        clearInterval(uploadBatchInterval);
                    }
                };

                // 2. Upload all files to S3, individually
                const completedFiles = [];
                let totalFilesProcessed = 0;

                /*
                    Batch Complete Uploads:
                    Since uploads are completed in batches, that mean files that are scanned are polled in batches too.

                    This function will completed the file upload as file previews come back from the uploadFileToS3 function.
                    This function is initialized before the files are attempted to upload to S3 since the funciton won't run
                    for the first 2s since it runs every 2s.

                    The batch uploading stops when all the files have been uploaded by clearing the interval.
                 */
                uploadBatchInterval = setInterval(() => {
                    if (completedFiles.length === 0) {
                        return;
                    }
                    const copy = completedFiles.splice(0, completedFiles.length); // also clears out completedFiles
                    completeFileUpload(
                        copy,
                        onUploading,
                        onSuccess,
                        onError,
                        newFileUploadIntermediates,
                        viewFileUploadIntermediates,
                        applicationSettings
                    )
                        // Scan files for malware for FedRAMP customers
                        .then((fileUploadResponses) => {
                            if (
                                !isEmpty(fileUploadResponses) &&
                                applicationSettings.RMS_FEDRAMP_FILE_UPLOAD_STATUSES_ENABLED
                            ) {
                                dispatch(
                                    pollingFileSecurityScannerUpdateS3Files(
                                        successfulFileUploadIntermediates
                                    )
                                );
                                dispatch(
                                    startPollingForFileSecurityScanner(
                                        fileUploadResponses,
                                        securityScannerCallback
                                    )
                                );
                            } else {
                                totalFilesProcessed += copy.length;
                                if (totalFilesProcessed === newFileUploadIntermediates.length) {
                                    onAllBatchesComplete();
                                    clearInterval(uploadBatchInterval);
                                }
                            }
                        })
                        .catch((error) => {
                            onError(
                                error.message || strings.uploadFailed,
                                UploadErrorType.UPLOAD_FAILURE,
                                viewFileUploadIntermediates
                            );
                        });
                }, 2000);

                const batchUploadComplete = ({ fileServerFile }) => {
                    completedFiles.push(fileServerFile);
                };
                Promise.all(
                    map(newFileUploadIntermediates, (newFileUploadIntermediate) =>
                        uploadFileToS3(newFileUploadIntermediate, batchUploadComplete)
                    )
                ).catch((error) => {
                    // this is the only error sent to Sentry because we don't have a way of
                    // tracking S3 errors
                    Sentry.withScope((scope) => {
                        scope.setExtra(
                            'successfulFileUploadIntermediates',
                            newFileUploadIntermediates
                        );
                        scope.setExtra('error', error);
                        scope.setLevel('error');
                        Sentry.captureMessage('Error uploading files to S3');
                    });
                    onError(
                        error.message || strings.uploadFailed,
                        UploadErrorType.UPLOAD_FAILURE,
                        viewFileUploadIntermediates
                    );
                });
            })
            .catch((error) => {
                onError(
                    error.message || strings.uploadFailed,
                    UploadErrorType.UPLOAD_FAILURE,
                    rawFiles
                );
                throw error;
            });
    };

    return (
        <div style={{ width: props.fullWidthDropZone ? '100%' : 'auto' }}>
            <Dropzone
                accept={props.accept}
                multiple={props.multiple}
                style={{ width: props.fullWidthDropZone ? '100%' : 'auto' }}
                onDropAccepted={upload}
                onDropRejected={reject}
                data-test-id={testId || testIds.UPLOAD_DROPZONE}
                ref={props.setUploadRef}
            >
                {props.children}
            </Dropzone>
            {error && <InlineBanner status="error">{error}</InlineBanner>}
        </div>
    );
}

function __Upload({
    multiple = true,
    onStart = noop,
    onUploading = noop,
    onError = noop,
    onFileInfected = noop,
    onSuccess,
    onAllBatchesComplete = noop,
    maxFiles,
    className,
    fullWidthDropZone,
    ...otherProps
}) {
    const dispatch = useDispatch();
    return (
        <div
            className={className}
            data-test-id={testIds.UPLOAD}
            style={{ width: fullWidthDropZone ? '100%' : 'auto' }}
        >
            <Upload
                fullWidthDropZone={fullWidthDropZone}
                multiple={multiple}
                maxFiles={maxFiles}
                onStart={onStart}
                onUploading={onUploading}
                onSuccess={onSuccess}
                onError={onError}
                onAllBatchesComplete={onAllBatchesComplete}
                openInfectedFilesModal={(dirtyFiles, uploadingDirtyFiles) => {
                    const infectedFileNames = map(dirtyFiles, 'file.originalFileName');

                    dispatch(
                        openConfirmationModal({
                            title: strings.InfectedFilesModal.title,
                            message: (
                                <div>
                                    <Row>
                                        <span>{strings.InfectedFilesModal.errorMessage}</span>
                                    </Row>
                                    {map(infectedFileNames, (infectedFileName, i) => (
                                        <div key={infectedFileName + i}>
                                            <br />
                                            <InlineBanner
                                                status="error"
                                                title=""
                                                description={infectedFileName}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ),
                            saveCallback: () => {
                                onFileInfected(dirtyFiles, uploadingDirtyFiles);
                                dispatch(closeConfirmationModal());
                            },
                            cancelText: '',
                            okText: strings.InfectedFilesModal.okText,
                        })
                    );
                }}
                {...otherProps}
            />
        </div>
    );
}

const _Upload = styled(__Upload)`
    display: flex;
    min-height: 1em;
    position: relative;
    ${(props) =>
        props.inline
            ? `
        display: inline-block;
        vertical-align: middle;
        div {
            margin: 0;
        }
    `
            : ''}
`;

/**
 * Our base Upload component. Streams file uploads directly to S3
 */
export default _Upload;

// Deprecated, do not use.
export const RRFUpload = compose(
    connectRRFInput,
    setPropTypes({
        value: PropTypes.number,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onStart: PropTypes.func,
        onSuccess: PropTypes.func,
        onError: PropTypes.func,
    }),
    defaultProps({
        onChange: noop,
        onFocus: noop,
        onBlur: noop,
        onStart: noop,
        onSuccess: noop,
        onError: noop,
        setUploadRef: noop,
    }),
    withHandlers({
        onStart({ onFocus, onStart }) {
            return () => {
                onFocus();
                onStart();
            };
        },
        onSuccess({ onChange, onBlur, onSuccess }) {
            return (fileUploadResponses) => {
                const file = get(fileUploadResponses, '[0].file');
                const fileId = get(file, 'id');
                onChange(fileId);
                onBlur(fileId);
                onSuccess(file);
            };
        },
        onError({ onBlur, onError }) {
            return (...args) => {
                onBlur();
                onError(...args);
            };
        },
    })
)(Upload);

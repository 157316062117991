import { EntityTypeEnum, AttributeTypeEnum, LinkTypesEnum, RefContextEnum } from '@mark43/rms-api';
import { actions } from 'react-redux-form';

import { replaceLocationEntityLinksForEntityAndLinkTypes } from '~/client-common/core/domain/location-entity-links/state/data';
import { updateReportAttributes } from '~/client-common/core/domain/report-attributes/state/data';
import { updateTowVehicle } from '~/client-common/core/domain/tow-vehicles/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { convertFromFormModel } from '../forms/towVehicleForm';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';
import { refreshTowVehicleForm } from './towVehicle';

const formModelPath = 'formModels.towVehicle';

const baseCard = createCard({
    name: reportCardEnum.TOW_VEHICLE.name,
    baseSelector: (state) => state.ui.report.towVehicleCard,
    anchor: 'tow-vehicle-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(refreshTowVehicleForm());
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save() {
            return (dispatch, getState, { formsRegistry }) => {
                const towVehicleForm = formsRegistry.get(RefContextEnum.FORM_TOW_VEHICLE.name);
                dispatch(actions.setInitial(formModelPath));

                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: towVehicleForm,
                        })
                    );
                } else {
                    const currentReportId = currentReportIdSelector(state);

                    const objectModel = convertFromFormModel(
                        towVehicleForm,
                        currentReportId,
                        state
                    );
                    const towVehicle = objectModel.towVehicle;
                    const towVehicleReasonForTowReportAttributes =
                        objectModel.towVehicleReasonForTowReportAttributes;
                    const locationEntityLinks = objectModel.locationEntityLinks;

                    const promises = [
                        dispatch(updateTowVehicle(towVehicle)),
                        dispatch(
                            updateReportAttributes(
                                currentReportId,
                                towVehicleReasonForTowReportAttributes,
                                AttributeTypeEnum.TOW_VEHICLE_REASON_FOR_TOW.name
                            )
                        ),
                        dispatch(
                            replaceLocationEntityLinksForEntityAndLinkTypes({
                                entityId: towVehicle.id,
                                entityType: EntityTypeEnum.RMS_TOW_VEHICLE.name,
                                linkTypes: [
                                    LinkTypesEnum.TOW_VEHICLE_RECOVERY_LOCATION,
                                    LinkTypesEnum.TOW_VEHICLE_REPORTED_STOLEN_LOCATION,
                                    LinkTypesEnum.TOW_VEHICLE_TOWED_FROM_LOCATION,
                                ],
                                locationEntityLinks,
                            })
                        ),
                    ];

                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: towVehicleForm,
                            promises,
                            onSavingSuccess: () => dispatch(refreshTowVehicleForm()),
                        })
                    );
                }
            };
        },
    },
};

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MFTFieldConfiguration, MFTFormConfiguration, _Form } from 'markformythree';
import { AttributeTypeEnum, FieldDetail, FieldTypeEnum } from '@mark43/rms-api';
import { Spinner } from 'arc';
import { fieldDetailsByFieldNameSelector } from '~/client-common/core/domain/field-details/state/data';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import {
    formatAttributeAbbrevAndValueByIdSelector,
    formatAttributeValueByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { usePreloadAttributes } from '~/client-common/core/hooks/usePreloadAttributes';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';

/**
 * The main point of this function is to specifically handle the case that for `Yes/No` attributes,
 * we should not display the attribute abbrev - only its display value
 */
function useFormatAttributeValue() {
    const formatAttributeAbbrevAndValueById = useSelector(
        formatAttributeAbbrevAndValueByIdSelector
    );
    const formatAttributeValueById = useSelector(formatAttributeValueByIdSelector);

    return {
        formatAttributeValue: ({
            attributeId,
            attributeTypeId,
        }: {
            attributeTypeId?: number;
            attributeId: number;
        }) => {
            return attributeTypeId === AttributeTypeEnum.YES_NO.value
                ? formatAttributeValueById(attributeId)
                : formatAttributeAbbrevAndValueById(attributeId);
        },
    };
}

function RenderSummaryAttribute({
    value,
    fieldDetail,
}: {
    value: unknown;
    fieldDetail: FieldDetail;
}) {
    const { formatAttributeValue } = useFormatAttributeValue();

    const valueAsInt =
        typeof value === 'number'
            ? value
            : typeof value === 'string'
            ? parseInt(value, 10)
            : undefined;

    const attributeTypeId = fieldDetail.fieldTypeMappedId;
    const attributeTypesToLoad = useMemo(() => {
        const attributeType = Object.values(AttributeTypeEnum).find(
            (attributeType) => attributeType.value === attributeTypeId
        )?.name;
        if (attributeType) {
            return [attributeType];
        }
        return [];
    }, [attributeTypeId]);
    const { isLoading } = usePreloadAttributes(attributeTypesToLoad);

    if (valueAsInt) {
        return (
            <SummaryRow fieldName={fieldDetail.fieldName}>
                {isLoading ? (
                    <Spinner size="sm" />
                ) : (
                    formatAttributeValue({ attributeTypeId, attributeId: valueAsInt })
                )}
            </SummaryRow>
        );
    } else {
        return null;
    }
}
/**
 * Given an arbiter field configuration, and it's absolute path within the form,
 * render the appropriate summary mode text / value
 *
 * It is expected that this will ultimately be rendered underneath a `SummaryList`
 * or else things will not be styled properly
 */
export const FormConfigurationSummaryFieldRenderer = <T extends MFTFormConfiguration>(props: {
    fieldConfiguration: MFTFieldConfiguration;
    absoluteFormPath: string;
    form: _Form<T>;
}) => {
    const fieldDetailsByFieldName = useSelector(fieldDetailsByFieldNameSelector);

    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);
    const fieldName = props.fieldConfiguration.fieldName
        ? String(props.fieldConfiguration.fieldName)
        : undefined;
    const fieldDetail = fieldName ? fieldDetailsByFieldName[fieldName] : undefined;
    const value = props.form.get(props.absoluteFormPath);

    if (fieldDetail) {
        switch (fieldDetail.fieldType) {
            case FieldTypeEnum.ATTRIBUTE.name: {
                return <RenderSummaryAttribute value={value} fieldDetail={fieldDetail} />;
            }
            case FieldTypeEnum.BOOLEAN.name: {
                const valueAsBool = typeof value === 'boolean' ? value : undefined;
                return <SummaryRow fieldName={fieldName}>{booleanToYesNo(valueAsBool)}</SummaryRow>;
            }
            case FieldTypeEnum.STRING.name: {
                return <SummaryRow fieldName={fieldName}>{value}</SummaryRow>;
            }
            case FieldTypeEnum.DATE.name: {
                const valueAsString = value ? String(value) : undefined;
                return (
                    <SummaryRowDate
                        fieldName={fieldName}
                        date={valueAsString}
                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                    />
                );
            }
            case FieldTypeEnum.DATETIME.name: {
                const valueAsString = value ? String(value) : undefined;
                return (
                    <SummaryRowDate
                        fieldName={fieldName}
                        date={valueAsString}
                        format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                    />
                );
            }
            case FieldTypeEnum.USER.name: {
                const valueAsNumber = value ? parseInt(String(value), 10) : undefined;
                return valueAsNumber ? (
                    <SummaryRow fieldName={fieldName}>
                        {formatMiniUserById(valueAsNumber)}
                    </SummaryRow>
                ) : null;
            }
            default: {
                return null;
            }
        }
    } else {
        return null;
    }
};

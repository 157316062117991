import type { Editor } from 'tinymce';

import { hasLogRocketConfiguration } from '../../../../core/logRocket';

/**
 * Initialize LogRocket for the TinyMCE editor.
 * https://docs.logrocket.com/docs/iframes
 * https://docs.logrocket.com/reference/mergeiframes
 *
 * The first script to load is from LogRocket. Although the parent window has already loaded LogRocket, the iframe has
 * to load it independently. LogRocket doesn't work inside the iframe if it accesses the same instance using
 * window.parent.LogRocket.
 *
 * The second script to load is our script to initialize LogRocket in the iframe, logrocket-in-iframe.js. It must be
 * loaded after the first script has finished loading. Its logic is similar to initLogRocket(). This script is not built
 * or minified; it is deployed as is using the webpack copy plugin, so be careful when editing it.
 *
 * This function adds <script> elements to the DOM because TinyMCE does not provide an API to customize the iframe's
 * HTML. It is safer to add to the <head> rather than the <body> because the <body> updates itself with all the editor
 * contents.
 *
 * Even if these scripts fail to load, the TinyMCE editor should be unaffected.
 */
export default function initLogRocketForTinyEditor(editor: Editor, baseUrl: string) {
    const doc = editor.getDoc();
    if (hasLogRocketConfiguration && LOG_ROCKET_SDK_SERVER && doc) {
        const logRocketScript = doc.createElement('script');
        logRocketScript.setAttribute('src', `${LOG_ROCKET_SDK_SERVER}/LogRocket.js`);
        logRocketScript.addEventListener('load', () => {
            const initScript = doc.createElement('script');
            initScript.setAttribute('src', `${baseUrl}logrocket-in-iframe.js`);
            doc.head.appendChild(initScript);
        });
        doc.head.appendChild(logRocketScript);
    }
}

import { createSelector } from 'reselect';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { currentUserHasAbilitySelector } from '../../modules/core/current-user/state/ui';

/**
 * A selector for retreiving permissions for a given ability
 * It has been deprecated and is being replaced with the <Ability>, or <OnlyWithAbility>
 * components, with currentUserHasAbilitySelector if necesary
 */
export const navigationPermissionsSelector = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => {
        const permissions = {
            hasAnyAdminAbility: false,
            hasManageRoles:
                currentUserHasAbility(abilitiesEnum.ADMIN.DELETE_GLOBAL_ROLE_CONFIGURATION) ||
                currentUserHasAbility(abilitiesEnum.ADMIN.VIEW_GLOBAL_ROLE_ENROLLMENT),
            hasReadUsers:
                currentUserHasAbility(abilitiesEnum.CORE.VIEW_USER_ACCOUNT_SETTINGS) ||
                currentUserHasAbility(abilitiesEnum.ADMIN.VIEW_GLOBAL_USERS),
            hasCasesGeneral: currentUserHasAbility(abilitiesEnum.CASES.VIEW_GENERAL),
            hasCasesGeneralWritePermission: currentUserHasAbility(abilitiesEnum.CASES.EDIT_GENERAL),
            hasCaseApproval: currentUserHasAbility(abilitiesEnum.CASES.EDIT_APPROVAL),
            hasCasesConfiguration: currentUserHasAbility(
                abilitiesEnum.ADMIN.VIEW_CASE_CONFIGURATION
            ),
            hasReportingGeneral: currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_GENERAL),
            hasReportingGeneralDelete: currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_GENERAL),
            hasReportingEventNumberEdit: currentUserHasAbility(
                abilitiesEnum.REPORTING.EDIT_REPORTING_EVENT_NUMBER
            ),
            hasReportCaseStatus: currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_CASE_STATUS),
            hasOffensecaseStatus: currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_CASE_STATUS),
            hasViewReportConfiguration: currentUserHasAbility(
                abilitiesEnum.ADMIN.VIEW_REPORT_CONFIGURATION
            ),
            hasEditReportConfiguration: currentUserHasAbility(
                abilitiesEnum.ADMIN.EDIT_REPORT_CONFIGURATION
            ),
            hasLocationManagement: currentUserHasAbility(
                abilitiesEnum.ADMIN.DELETE_LOCATION_MANAGEMENT
            ),
            hasChargesWritePermission: currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_CHARGES),
            hasDepartmentConfiguration: currentUserHasAbility(
                abilitiesEnum.ADMIN.VIEW_DEPARTMENT_CONFIGURATION
            ),
            hasManageBulletinsPermissions: currentUserHasAbility(
                abilitiesEnum.NOTIFICATIONS.ALERTS_MANAGEMENT
            ),
            hasDepartmentReset: currentUserHasAbility(abilitiesEnum.SUPPORT.DEPARTMENT_RESET),
            hasDepartmentSetup: currentUserHasAbility(abilitiesEnum.SUPPORT.DEPARTMENT_SETUP),
            hasSecuritySettingsManagement: currentUserHasAbility(
                abilitiesEnum.ADMIN.EDIT_SECURITY_SETTINGS
            ),
            hasAnalysisGeneral: currentUserHasAbility(abilitiesEnum.ANALYSIS.GENERAL),
            hasAgencyConfiguration: currentUserHasAbility(
                abilitiesEnum.ADMIN.VIEW_AGENCY_CONFIGURATION
            ),
            hasValidation: currentUserHasAbility(abilitiesEnum.ADMIN.VIEW_VALIDATION),
            hasUsageLogsConfiguration: currentUserHasAbility(abilitiesEnum.USAGE_LOGS.VIEW_GENERAL),
            hasAttributeCodeTypesAdmin: currentUserHasAbility(
                abilitiesEnum.ADMIN.EDIT_ATTRIBUTE_CODE_TYPES
            ),
            hasComplianceDashboard: currentUserHasAbility(
                abilitiesEnum.REPORTING.COMPLIANCE_EXPORT_DASHBOARD
            ),
            hasNibrs: currentUserHasAbility(abilitiesEnum.REPORTING.NIBRS_WORKSPACE),
            hasConsortiumManagement: currentUserHasAbility(abilitiesEnum.ADMIN.MANAGE_CONSORTIUM),
            hasRipa:
                currentUserHasAbility(abilitiesEnum.ADMIN.RIPA_CODES_BULK_EXPORT) ||
                currentUserHasAbility(abilitiesEnum.ADMIN.RIPA_CODES_BULK_IMPORT),
        };

        const hasAnyAdminAbility =
            permissions.hasManageRoles ||
            currentUserHasAbility(abilitiesEnum.ADMIN.VIEW_GLOBAL_USERS) ||
            permissions.hasLocationManagement ||
            currentUserHasAbility(abilitiesEnum.ADMIN.VIEW_OFFENSE_CODE_CONFIGURATION) ||
            permissions.hasViewReportConfiguration ||
            permissions.hasEditReportConfiguration ||
            permissions.hasDepartmentConfiguration ||
            currentUserHasAbility(abilitiesEnum.ADMIN.VIEW_GLOBAL_ATTRIBUTE_CONFIGURATION) ||
            permissions.hasDepartmentReset ||
            permissions.hasSecuritySettingsManagement ||
            currentUserHasAbility(abilitiesEnum.ADMIN.EDIT_EXTERNAL_LINKS) ||
            currentUserHasAbility(abilitiesEnum.EVIDENCE.CONFIGURATION) ||
            currentUserHasAbility(abilitiesEnum.EVIDENCE.STORAGE_ADMIN) ||
            currentUserHasAbility(abilitiesEnum.CAD.CALL_FOR_SERVICES_ADMIN) ||
            currentUserHasAbility(abilitiesEnum.CAD.PREFERENCES_ADMIN) ||
            currentUserHasAbility(abilitiesEnum.CAD.UNITS_ADMIN) ||
            currentUserHasAbility(abilitiesEnum.CAD.INTERNAL) ||
            currentUserHasAbility(abilitiesEnum.ADMIN.VIEW_WARRANT_CONFIGURATION) ||
            currentUserHasAbility(abilitiesEnum.ADMIN.VIEW_WARRANT_ACTIVITY_MAPPING) ||
            currentUserHasAbility(abilitiesEnum.ADMIN.MERGE_PROFILES) ||
            permissions.hasCasesConfiguration ||
            permissions.hasDepartmentSetup ||
            permissions.hasAgencyConfiguration ||
            permissions.hasValidation ||
            permissions.hasUsageLogsConfiguration ||
            permissions.hasAttributeCodeTypesAdmin ||
            permissions.hasRipa;

        permissions.hasAnyAdminAbility = hasAnyAdminAbility;

        return permissions;
    }
);

import { AttributeTypeEnum, OfficerInvolvementEnum } from '@mark43/rms-api';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, defaultProps, setDisplayName, setPropTypes } from 'recompose';
import keyMirror from 'keymirror';
import * as fields from '~/client-common/core/enums/universal/fields';

import { getDropdownOptionsFromObject } from '~/client-common/helpers/dropdownOptionHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import { attributeOptionsByTypeForCurrentDepartmentSelector } from '../../../attributes/state/ui';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import MultiFieldSelect from './MultiFieldSelect';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const { label: defaultLabel, options } = componentStrings.forms.select.OfficerInvolvementSelect;

/**
 * Dropdown containing all officer involvement types and all officer assist
 *   types. The two types are modeled across two separate form fields.
 * @param {Object}  props
 * @param {Object}  props.fields
 * @param {boolean} [props.includeExpired=false]  Applies to officer assist type
 *   attributes only.
 */
const OfficerInvolvementSelect = compose(
    setDisplayName('OfficerInvolvementSelect'),
    setPropTypes({
        includeExpired: PropTypes.bool,
        label: PropTypes.string,
    }),
    connect(
        createStructuredSelector({
            attributeOptionsByTypeForCurrentDepartment: attributeOptionsByTypeForCurrentDepartmentSelector,
            formatFieldByName: formatFieldByNameSelector,
        })
    )
)(function OfficerInvolvementSelect({
    fields: { officerInvolvement, assistTypeAttrId },
    includeExpired = false,
    label,
    attributeOptionsByTypeForCurrentDepartment,
    formatFieldByName,
    ...otherProps
}) {
    const primaryReporterLabel = formatFieldByName(fields.EVENT_DETAIL_RESPONDING_OFFICER_ID);

    // Primary Responder / Responding Officer
    options.officerInvolvement[OfficerInvolvementEnum.RESPONDING.name] = primaryReporterLabel;

    const officerInvolvementOptions = getDropdownOptionsFromObject(options.officerInvolvement);
    const officerAssistTypeOptions = attributeOptionsByTypeForCurrentDepartment({
        type: AttributeTypeEnum.OFFICER_ASSIST_TYPE.name,
        includeExpired,
    });

    return (
        <MultiFieldSelect
            label={label || defaultLabel}
            sortOptions={true}
            {...otherProps}
            fields={{
                officerInvolvement: {
                    options: officerInvolvementOptions,
                    ...officerInvolvement,
                },
                assistTypeAttrId: {
                    options: officerAssistTypeOptions,
                    ...assistTypeAttrId,
                },
            }}
        />
    );
});

export const RRFOfficerInvolvementSelect = compose(
    setDisplayName('RRFOfficerInvolvementSelect'),
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            officerInvolvement: null,
            assistTypeAttrId: null,
        }),
    }),
    connectRRFMultiFieldInput
)(OfficerInvolvementSelect);

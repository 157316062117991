import React from 'react';
import classNames from 'classnames';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';

export default clippable()(function SearchCard({
    children,
    clippable: { isClippedBottom = false, clipAtTopLimit = false } = {},
    footer,
}) {
    return (
        <div className="search-card">
            <div className="search-card-content clearfix">{children}</div>
            <div
                className="search-card-footer"
                style={
                    isClippedBottom
                        ? clipAtTopLimit
                            ? {
                                  position: 'absolute',
                                  top: 107,
                              }
                            : {
                                  position: 'fixed',
                                  bottom: 0,
                              }
                        : {}
                }
            >
                {footer}
            </div>
        </div>
    );
});

/**
 * Footer for a search card, which may be a search form or a search results
 *   table, etc.
 * @param {Object} props Props get spread into a `div`.
 */
export function SearchCardFooter(props) {
    const { className, ...otherProps } = props;

    return <div className={classNames('mark43-search-card-footer', className)} {...otherProps} />;
}

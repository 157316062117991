import { map, keys, forEach, mapValues, endsWith, isArray, isObject } from 'lodash';
import keyMirror from 'keymirror';

import { storeAttributes } from '../core/domain/attributes/state/data';
import { storeMiniUsers } from '../core/domain/mini-users/state/data';
import { storeOffenseCodes } from '../core/domain/offense-codes/state/data';
import { storeCaseDefinitions } from '../core/domain/case-definitions/state/data';
import { storeAgencyProfiles } from '../core/domain/agency-profiles/state/data';

import { userProfileToMiniUser } from '../core/domain/mini-users/utils/miniUsersHelpers';
import { convertAttributeToAttributeView } from '../core/domain/attributes/utils/attributesHelpers';
import { augmentOffenseCodeWithOffenseCodeView } from './offenseCodesHelpers';

const multiAgencyPropsToCheck = {
    agencyProfiles: (data) => storeAgencyProfiles(data),
    attributes: (data) => storeAttributes(map(data, convertAttributeToAttributeView)),
    userProfiles: (data) => storeMiniUsers(map(data, userProfileToMiniUser)),
    offenseCodes: (data) => storeOffenseCodes(map(data, augmentOffenseCodeWithOffenseCodeView)),
    caseDefinitions: (data) => storeCaseDefinitions(data),
};
const multiAgencyKeys = keys(multiAgencyPropsToCheck);

const storeExternalEntities = ({ data, dispatch }) => {
    // only external records and entities have attributes etc in their
    // payload so we just check for the existence of fields and dump
    // data into state if it exists. This actually sets us up well for things
    // to "just work" if we decide to do the same for internal records once
    // we get rid of the bootstrap
    forEach(multiAgencyKeys, (key) => {
        if (data[key] && data[key].length) {
            dispatch(multiAgencyPropsToCheck[key](data[key]));
        }
    });
};

export const storeConfigForRelatedRecordsAndEntities = (dispatch) => (data) => {
    storeExternalEntities({ data, dispatch });
    return data;
};

export const persistGlobalKeys = keyMirror({
    itemTypeAttrId: null,
    vehicleMakeId: null,
    vehicleModelId: null,
});

/**
 * Sanitize entity model from other department in consortium. Will remove any (nested) values
 *   from model where key name ends with 'Id'. Optional persistKeys specifies key names that
 *   will NOT be sanitized.
 *   An example of usage is in Item Entry where a user can quick search a vehicle/firearm using
 *   vin/serial number. Results may include items from other departments in the consortium.
 *   If one of these items is selected, want to strip out all department specific ids
 *   and attribute ids.
 * @param {Object}  model
 * @param {Object}  [persistKeys]
 */
export function sanitizeModelFromOtherDepartmentDeep(model, persistKeys = persistGlobalKeys) {
    return mapValues(model, (value, key) => {
        if (endsWith(key, 'Id') && !persistKeys[key]) {
            return;
        }

        if (isObject(value) && !isArray(value)) {
            return sanitizeModelFromOtherDepartmentDeep(value, persistKeys);
        }

        if (isArray(value)) {
            return map(value, (valueInArray) => {
                if (isObject(valueInArray) && !isArray(valueInArray)) {
                    return sanitizeModelFromOtherDepartmentDeep(valueInArray, persistKeys);
                }

                return valueInArray;
            });
        }

        return value;
    });
}

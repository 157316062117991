import { EntityTypeEnum, ItemInvolvementTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import validationStrings from '~/client-common/core/strings/validationStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';
import { CardSection as _CardSection } from '../../../../../legacy-redux/components/core/Card';
import { Button as ArcButton } from '../../../../core/components/Button';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes, iconSizes } from '../../../../core/components/Icon';
import _NoDataBlock from '../../../../core/components/NoDataBlock';
import { openLinkItemsToOffenseSidePanel } from '../../state/ui/linkItemsToOffenseSidePanel';
import { itemSummaryViewModelsForCurrentReportSelector } from '../../state/ui';
import testIds from '../../../../../core/testIds';
import ItemSectionsInOffenseSummaryList from './ItemSectionsInOffenseSummaryList';

const strings = componentStrings.reports.core.ItemSectionsInOffense;

const CardSection = styled(_CardSection)`
    padding: 24px 0 0;
`;
const CenterWrapper = styled.div`
    text-align: center;
`;
const NoDataBlock = styled(_NoDataBlock)`
    max-width: 320px;
`;

const mapStateToProps = createStructuredSelector({
    applicationSettings: applicationSettingsSelector,
    itemSummaryViewModelsForCurrentReport: itemSummaryViewModelsForCurrentReportSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const ItemSection = compose(
    connect(mapStateToProps, { openLinkItemsToOffenseSidePanel }),
    withHandlers({
        onClickLink({
            openLinkItemsToOffenseSidePanel,
            isVehicle,
            reportId,
            offenseId,
            offenseNibrsCode,
            nibrsAllowedProperty,
        }) {
            return () => {
                openLinkItemsToOffenseSidePanel({
                    isVehicle,
                    ownerType: EntityTypeEnum.REPORT.name,
                    ownerId: reportId,
                    offenseId,
                    offenseNibrsCode,
                    nibrsAllowedProperty,
                });
            };
        },
    })
)(function ItemSection({
    isVehicle,
    summaryMode,
    onClickLink,
    reportId,
    offenseId,
    itemInvolvementValue,
    nibrsAllowedProperty,
    applicationSettings,
    itemSummaryViewModelsForCurrentReport,
    testId,
    formatFieldByName,
}) {
    const offenseDisplayName = formatFieldByName(DISPLAY_ONLY_OFFENSE);
    const isLinkedToItems = itemSummaryViewModelsForCurrentReport(isVehicle, offenseId).length > 0;
    const itemOffenseLink = (
        <FeatureFlagged
            flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
            fallback={
                <Button
                    display="inline-flex"
                    float="none"
                    className={buttonTypes.SECONDARY}
                    iconLeft={<Icon type={iconTypes.ADD} size={iconSizes.SMALL} />}
                    onClick={onClickLink}
                    testId={testIds.LINK_ITEM_BUTTON}
                >
                    {isVehicle ? strings.linkVehicleButton : strings.linkPropertyButton}
                </Button>
            }
        >
            <ArcButton
                variant="outline"
                leftIcon="Add"
                onClick={onClickLink}
                testId={testIds.LINK_ITEM_BUTTON}
            >
                {isVehicle ? strings.linkVehicleButton : strings.linkPropertyButton}
            </ArcButton>
        </FeatureFlagged>
    );

    // Careful when editing conditional. Order is important.
    let offenseLinkContent;
    if (summaryMode) {
        offenseLinkContent = undefined;
    } else if (!applicationSettings.RMS_ITEM_ENTRY_V2_NIBRS_VALIDATION) {
        offenseLinkContent = itemOffenseLink;
    } else if (
        (isVehicle && !nibrsAllowedProperty.vehiclesAllowed) ||
        (!isVehicle && !nibrsAllowedProperty.propertyAllowed)
    ) {
        // vehiclesAllowed and propertyAllowed can be set to false by nibrs validation rules
        // will default to undefined for any offenses without nibrs validation rules
        offenseLinkContent = (
            <div>
                <CenterWrapper>
                    <NoDataBlock>
                        {isVehicle
                            ? validationStrings.panel.nibrsDisallowedVehicle({ offenseDisplayName })
                            : validationStrings.panel.nibrsDisallowedProperty({
                                  offenseDisplayName,
                              })}
                    </NoDataBlock>
                </CenterWrapper>
                {isLinkedToItems && itemOffenseLink}
            </div>
        );
    } else if (
        itemInvolvementValue === ItemInvolvementTypeEnum.NONE.name ||
        itemInvolvementValue === ItemInvolvementTypeEnum.UNKNOWN.name
    ) {
        offenseLinkContent = (
            <div>
                <CenterWrapper>
                    <NoDataBlock>
                        {isVehicle
                            ? validationStrings.panel.nibrsItemInvolvementDisallowedVehicle({
                                  offenseDisplayName,
                              })
                            : validationStrings.panel.nibrsItemInvolvementDisallowedProperty({
                                  offenseDisplayName,
                              })}
                    </NoDataBlock>
                </CenterWrapper>
                {isLinkedToItems && itemOffenseLink}
            </div>
        );
    } else if (itemInvolvementValue === ItemInvolvementTypeEnum.PROPERTY_INVOLVED.name) {
        offenseLinkContent = itemOffenseLink;
    }

    return (
        <CardSection
            title={isVehicle ? strings.vehicleSectionTitle : strings.propertySectionTitle}
            testId={testId}
            fieldName={testId}
        >
            {offenseLinkContent}
            <ItemSectionsInOffenseSummaryList
                currentReportId={reportId}
                isVehicle={isVehicle}
                offenseId={offenseId}
            />
        </CardSection>
    );
});

/**
 * The Property section and the Vehicle(s) section in the Offense Card.
 */
export default function ItemSectionsInOffense({
    hidden,
    summaryMode,
    reportId,
    offenseId,
    nibrsAllowedProperty = {},
    itemInvolvementValue,
    offenseNibrsCode,
}) {
    if (hidden) {
        return <div />;
    }

    return (
        <div>
            <ItemSection
                isVehicle={false}
                summaryMode={summaryMode}
                reportId={reportId}
                offenseId={offenseId}
                nibrsAllowedProperty={nibrsAllowedProperty}
                itemInvolvementValue={itemInvolvementValue}
                offenseNibrsCode={offenseNibrsCode}
                testId={testIds.OFFENSE_CARD_PROPERTY_SECTION}
            />
            <ItemSection
                isVehicle={true}
                summaryMode={summaryMode}
                reportId={reportId}
                offenseId={offenseId}
                nibrsAllowedProperty={nibrsAllowedProperty}
                itemInvolvementValue={itemInvolvementValue}
                offenseNibrsCode={offenseNibrsCode}
                testId={testIds.OFFENSE_CARD_VEHICLE_SECTION}
            />
        </div>
    );
}

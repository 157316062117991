import _ from 'lodash';
import armadaActionTypes from '../actions/types/armadaActionTypes';

import { setFormValues } from '../helpers/formReducerHelpers';

const {
    GET_CURRENT_COMMANDS_SUCCESS,
    GET_RUNNING_COMMANDS_SUCCESS,
    GET_PENDING_COMMANDS_SUCCESS,
    GET_FAILED_COMMANDS_SUCCESS,
    GET_SCHEDULES_SUCCESS,
    CREATE_SCHEDULE,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAILURE,
    UPDATE_SCHEDULE,
    UPDATE_SCHEDULE_SUCCESS,
    UPDATE_SCHEDULE_FAILURE,
    GET_SCHEDULE_HISTORY_SUCCESS,
    GET_COMMAND_HISTORY_SUCCESS,
    CLEAR_COMMAND_SUCCESS,
    CLEAR_COMMAND_FAILURE,
    RETRY_COMMAND,
    RETRY_COMMAND_FAILURE,
    GET_SELECTED_FAILED_COMMAND,
    GET_SELECTED_FAILED_COMMAND_FORM,
    SET_SELECTED_SCHEDULE,
    SET_SELECTED_SCHEDULE_FORM,
    SET_SCHEDULE_ACTION_CREATE,
} = armadaActionTypes;

const initialDataState = {
    runningCommands: [],
    pendingCommands: [],
    failedCommands: [],
    currentCommands: [],
    commandHistory: [],
    allSchedules: [],
    scheduleHistory: [],
};

const initialUiState = {
    selectedFailedCommandId: null,
    selectedScheduleId: null,
    scheduleActionType: '', // this is whether submitting a schedule should use createSchedule or updateSchedule
    scheduleMessage: '',
    commandMessage: '',
};

export function failedCommandsFormReducer(state, action) {
    switch (action.type) {
        // set the Failed Commands retry form with the selected command
        case GET_SELECTED_FAILED_COMMAND_FORM:
            return setFormValues(state, action.payload);
        default:
            return state;
    }
}

export function schedulesFormReducer(state, action) {
    switch (action.type) {
        // set the update/create schedule form with the selected schedule or a blank form, respectively
        case SET_SELECTED_SCHEDULE_FORM:
            return setFormValues(state, action.payload);
        default:
            return state;
    }
}

export function armadaDataReducer(state = initialDataState, action) {
    switch (action.type) {
        // get all the commands with status RUNNING
        case GET_RUNNING_COMMANDS_SUCCESS:
            return _.assign({}, state, {
                runningCommands: action.payload,
            });

        // get all the commands with status PENDING
        case GET_PENDING_COMMANDS_SUCCESS:
            return _.assign({}, state, {
                pendingCommands: action.payload,
            });

        // get all the commands with status FAILED
        case GET_FAILED_COMMANDS_SUCCESS:
            return _.assign({}, state, {
                failedCommands: action.payload,
            });

        // get all the recent commands with any status
        case GET_CURRENT_COMMANDS_SUCCESS:
            return _.assign({}, state, {
                currentCommands: action.payload,
            });
        // set commandHistory for the selected command
        case GET_COMMAND_HISTORY_SUCCESS:
            return _.assign({}, state, {
                commandHistory: action.payload,
            });

        case GET_SCHEDULES_SUCCESS:
            return _.assign({}, state, {
                allSchedules: action.payload,
            });
        case GET_SCHEDULE_HISTORY_SUCCESS:
            return _.assign({}, state, {
                scheduleHistory: action.payload,
            });
        // set command to status CLEARED
        case CLEAR_COMMAND_SUCCESS:
            const failedCommandsCopy = _.cloneDeep(state.failedCommands);
            failedCommandsCopy[
                _.findIndex(failedCommandsCopy, (command) => command.id === action.payload.id)
            ] = action.payload;
            return {
                ...state,
                failedCommands: failedCommandsCopy,
            };
        case UPDATE_SCHEDULE_SUCCESS:
            const allSchedulesCopy = _.cloneDeep(state.allSchedules);
            allSchedulesCopy[
                _.findIndex(allSchedulesCopy, (schedule) => schedule.id === action.payload.id)
            ] = action.payload;
            return {
                ...state,
                allSchedules: allSchedulesCopy,
            };
        default:
            return state;
    }
}

export function armadaUiReducer(state = initialUiState, action) {
    switch (action.type) {
        // set the update/create schedule form to use POST
        case SET_SCHEDULE_ACTION_CREATE:
            return _.assign({}, state, {
                scheduleActionType: 'CREATE',
                scheduleMessage: '',
                selectedScheduleId: -1, // send negative number to indicate new schedule
            });
        // set the selected schedule id and use PUT
        case SET_SELECTED_SCHEDULE:
            return _.assign({}, state, {
                selectedScheduleId: action.payload,
                scheduleMessage: '',
                scheduleActionType: 'UPDATE',
            });
        // set the selected failed command's id
        case GET_SELECTED_FAILED_COMMAND:
            return _.assign({}, state, {
                selectedFailedCommandId: action.payload,
                commandMessage: '',
            });
        // set command to status CLEARED
        case CLEAR_COMMAND_SUCCESS:
            return {
                ...state,
                commandMessage: 'Clear Command Success',
            };
        case CLEAR_COMMAND_FAILURE:
            return {
                ...state,
                commandMessage: 'Failed to Clear Command',
            };
        case RETRY_COMMAND:
            return {
                ...state,
                commandMessage: '',
            };
        case RETRY_COMMAND_FAILURE:
            return {
                ...state,
                commandMessage: `Error retrying command: ${action.payload.message}`,
            };
        case CREATE_SCHEDULE:
            return {
                ...state,
                scheduleMessage: '',
            };
        case CREATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                scheduleMessage: 'Create Schedule Success!',
            };
        case CREATE_SCHEDULE_FAILURE:
            return {
                ...state,
                scheduleMessage: `Create Schedule Failed: ${action.payload.message}`,
            };
        case UPDATE_SCHEDULE:
            return {
                ...state,
                scheduleMessage: '',
            };
        case UPDATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                scheduleMessage: 'Update Success!',
            };
        case UPDATE_SCHEDULE_FAILURE:
            return {
                ...state,
                scheduleMessage: `Update Failed: ${action.payload.message}`,
            };
        default:
            return state;
    }
}

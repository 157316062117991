import React, { MouseEventHandler } from 'react';
import { connect } from 'react-redux';
import { compose, mapProps, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { ElasticReport } from '@mark43/rms-api';

import { getViewModelProperties, ViewModel } from '~/client-common/helpers/viewModelHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';

import { openNarrativeModal } from '../../../../../modules/cases/unassigned-reports/state/ui';
import Button, { ButtonProps, buttonTypes } from '../../Button';
import { RmsDispatch } from '../../../../../core/typings/redux';

type ElasticReportViewModel = ViewModel<ElasticReport, { currentSubmissionOwnerId?: string }>;
type NarrativeModalElasticReport = {
    restrictViewReportOwners?: boolean;
    currentSubmissionOwnerId?: string;
} & Pick<
    ElasticReport,
    'narrative' | 'reportingEventNumber' | 'eventStartUtc' | 'permissionSet' | 'recordNumber'
>;

type NarrativeCellProps = Pick<ElasticReport, 'narrative' | 'permissionSet'> &
    Pick<ButtonProps, 'onClick'>;

type NarrativeCellHandlersInner = NarrativeModalElasticReport & {
    dateTimeFormatter: ReturnType<typeof currentDepartmentDateFormatterSelector>;
    openNarrativeModal: (elasticReport: NarrativeModalElasticReport) => void;
};
type NarrativeCellHandlersOuter = {
    onClick: MouseEventHandler<HTMLButtonElement>;
};

function NarrativeCell({ onClick, narrative, permissionSet = [] }: NarrativeCellProps) {
    return narrative && canRead(permissionSet) ? (
        <Button
            className={buttonTypes.SECONDARY}
            style={{ marginTop: 0 }}
            onClick={onClick}
            stopPropagation={!narrative}
        >
            Narrative
        </Button>
    ) : (
        <div />
    );
}

const mapStateToProps = createStructuredSelector({
    dateTimeFormatter: currentDepartmentDateFormatterSelector,
});

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    openNarrativeModal: (elasticReport: NarrativeModalElasticReport) =>
        dispatch(openNarrativeModal(elasticReport)),
});

/**
 * Table cell with a narrative button. Clicking on the button opens a modal that
 *   displays the report's narrative. The button is hidden when the report's
 *   narrative is blank.
 */
export default compose<ElasticReportViewModel, { columnKey: 'narrative' }>(
    mapProps<NarrativeModalElasticReport, ElasticReportViewModel>((elasticReport) => {
        return {
            reportingEventNumber: elasticReport.reportingEventNumber,
            eventStartUtc: elasticReport.eventStartUtc,
            narrative: elasticReport.narrative,
            permissionSet: elasticReport.permissionSet,
            recordNumber: elasticReport.recordNumber,
            restrictViewReportOwners: elasticReport.reportDefinition.restrictViewReportOwners,
            // just moved connect below the mapProps to fix the issue with the view model Symbol
            // property that disappeared
            currentSubmissionOwnerId: getViewModelProperties(elasticReport)
                ?.currentSubmissionOwnerId,
        };
    }),
    connect(mapStateToProps, mapDispatchToProps),

    withHandlers<NarrativeCellHandlersInner, NarrativeCellHandlersOuter>({
        onClick({ openNarrativeModal, dateTimeFormatter, eventStartUtc, ...partialElasticReport }) {
            const formattedEventStartUtc = dateTimeFormatter.formatDate(eventStartUtc);

            return (event) => {
                // don't propagate to the table row click event
                event.stopPropagation();

                openNarrativeModal({
                    ...partialElasticReport,
                    eventStartUtc: formattedEventStartUtc,
                });
            };
        },
    })
)(NarrativeCell);

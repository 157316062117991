import { ReportHistoryVisibilityLevelEnum } from '@mark43/rms-api';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { mediaQueries } from 'arc';
import { connect, ConnectedProps } from 'react-redux';
import { historyEventViewModelsByEntityIdSelector } from '~/client-common/core/domain/history-events/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportDefinitionByIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { HistoryEventViewModel } from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import { loadReportHistory } from '../../state/data';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import { reportHistoryIsLoadingSelector } from '../../state/ui';
import History from '../../../../core/histories/components/History';
import testIds from '../../../../../core/testIds';
import { EmDash } from '../../../../core/components/HtmlEntities';

const strings = componentStrings.reports.core.History;

const HistoryWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    overflow: auto;
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 0 var(--arc-space-4);

    @media (min-width: ${mediaQueries.md}) {
        padding: 0 var(--arc-space-8);
    }
`;

const SemiBold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const NoHistoryContainer = styled.div`
    margin-top: 10px;
`;

const mapStateToProps = createStructuredSelector({
    historyEventViewModelsByEntityId: historyEventViewModelsByEntityIdSelector,
    reportHistoryIsLoading: reportHistoryIsLoadingSelector,
    currentReport: currentReportSelector,
    reportDefinitionById: reportDefinitionByIdSelector,
});

const mapDispatchToProps = {
    loadReportHistory,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

class ReportHistory extends React.Component<ConnectedProps<typeof connector>> {
    componentDidMount() {
        if (this.props.currentReport) {
            this.props.loadReportHistory(this.props.currentReport.id);
        }
    }

    formatHistoryEntityViewModels(
        restricted: boolean,
        historyEventViewModels: HistoryEventViewModel[]
    ) {
        if (!restricted) {
            return historyEventViewModels;
        }

        return historyEventViewModels.map((item) => {
            return {
                ...item,
                user: <EmDash />,
            };
        });
    }

    render() {
        const {
            historyEventViewModelsByEntityId,
            currentReport,
            reportDefinitionById,
            reportHistoryIsLoading,
        } = this.props;

        if (!currentReport) {
            return;
        }

        const historyEventViewModels = historyEventViewModelsByEntityId(currentReport.id);
        const reportDefinition = reportDefinitionById(currentReport.reportDefinitionId);
        const visibilityLevel = reportDefinition?.historyVisibilityLevel;
        const restrictViewReportOwners = reportDefinition?.restrictViewReportOwners;

        if (historyEventViewModels.length === 0 && !reportHistoryIsLoading) {
            let infoMessage: string;

            if (visibilityLevel === ReportHistoryVisibilityLevelEnum.AFTER_FIRST_SUBMISSION.name) {
                infoMessage = strings.afterFirstSubmission;
            } else if (
                visibilityLevel === ReportHistoryVisibilityLevelEnum.AFTER_FIRST_APPROVAL.name
            ) {
                infoMessage = strings.afterFirstApproval;
            } else {
                infoMessage = strings.cannotBeDisplayed;
            }

            return (
                <HistoryWrapper>
                    <NoHistoryContainer data-test-id={testIds.NO_HISTORY_CONTAINER}>
                        <SemiBold>{`${strings.noHistoryAvailable} :`}</SemiBold>
                        {` ${infoMessage}`}
                    </NoHistoryContainer>
                </HistoryWrapper>
            );
        }

        const formattedHistoryEntityViewModels = this.formatHistoryEntityViewModels(
            !!restrictViewReportOwners,
            historyEventViewModels
        );

        return (
            <HistoryWrapper>
                <History
                    historyEventViewModels={formattedHistoryEntityViewModels}
                    showFilter={true}
                />
            </HistoryWrapper>
        );
    }
}

export default connector(ReportHistory);

import { createSelector } from 'reselect';
import { filter, map } from 'lodash';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { allRoleFormatsByRoleIdSelector } from '../../../roles/state/data';
import { caseRoleLinksSelector } from '../data';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatEntityPermission } from '../../../../../helpers/entityPermissionHelpers';

const caseRoleLinkViewModelSelector = createSelector(
    caseRoleLinksSelector,
    formatAttributeByIdSelector,
    allRoleFormatsByRoleIdSelector,
    (caseRoleLinks, formatAttributeById, allRoleFormatsByRoleId) => {
        return map(
            caseRoleLinks,
            buildViewModel({
                recursive: true,
                mappers: [
                    allSingleAttributeValuesMapper,
                    ({ roleId, entityPermission }) => ({
                        names: allRoleFormatsByRoleId(roleId),
                        operationType: formatEntityPermission(entityPermission),
                    }),
                ],
                helpers: {
                    formatAttributeById,
                },
            })
        );
    }
);

export const caseRoleLinkViewModelsForCaseIdSelector = createSelector(
    caseRoleLinkViewModelSelector,
    (caseRoleLinkViewModel) => (caseId) => filter(caseRoleLinkViewModel, { caseId })
);

import { EntityTypeEnum, CodeTypeCategoryEnum } from '@mark43/rms-api';
import { some } from 'lodash';

import { nibrsCodeForAttributeIdAndCodeTypeCategorySelector } from '../../reports/ucr-classification/state/ui/selectors';
import { offenseVictimProfileGetter } from './offenseVictimProfileGetter';

export const getOffenseVictimHasUnknownSex = (getState) => {
    const getOffenseVictimProfiles = offenseVictimProfileGetter(getState);
    return (offenseId) => {
        const nibrsCodeForAttributeIdAndCodeTypeCategory = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(
            getState()
        );
        return some(
            getOffenseVictimProfiles(offenseId, [EntityTypeEnum.PERSON_PROFILE.name]),
            (profile) =>
                nibrsCodeForAttributeIdAndCodeTypeCategory(
                    profile.sexAttrId,
                    CodeTypeCategoryEnum.NIBRS_SEX.name
                ) === 'U'
        );
    };
};

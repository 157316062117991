import { storeApplicationSettings } from '~/client-common/core/domain/application-settings/state/data';
import applicationSettingsResource from '../../resources/applicationSettingsResource';

const LOAD_APPLICATION_SETTINGS_START = 'application_settings/LOAD_APPLICATION_SETTINGS_START';
const LOAD_APPLICATION_SETTINGS_SUCCESS = 'application_settings/LOAD_APPLICATION_SETTINGS_SUCCESS';
export const LOAD_APPLICATION_SETTINGS_FAILURE =
    'application_settings/LOAD_APPLICATION_SETTINGS_FAILURE';
const UPSERT_APPLICATION_SETTING_START = 'application_settings/UPSERT_APPLICATION_SETTING_START';
const UPSERT_APPLICATION_SETTING_SUCCESS =
    'application_settings/UPSERT_APPLICATION_SETTING_SUCCESS';
export const UPSERT_APPLICATION_SETTING_FAILURE =
    'application_settings/UPSERT_APPLICATION_SETTING_FAILURE';

function loadApplicationSettingsStart() {
    return {
        type: LOAD_APPLICATION_SETTINGS_START,
    };
}

function loadApplicationSettingsSuccess(applicationSettings) {
    return {
        type: LOAD_APPLICATION_SETTINGS_SUCCESS,
        payload: applicationSettings,
    };
}

function loadApplicationSettingsFailure(err) {
    return {
        type: LOAD_APPLICATION_SETTINGS_FAILURE,
        payload: err,
    };
}

function upsertApplicationSettingStart() {
    return {
        type: UPSERT_APPLICATION_SETTING_START,
    };
}

function upsertApplicationSettingSuccess(upsertRequest) {
    return {
        type: UPSERT_APPLICATION_SETTING_SUCCESS,
        payload: upsertRequest,
    };
}

function upsertApplicationSettingFailure(err) {
    return {
        type: UPSERT_APPLICATION_SETTING_FAILURE,
        payload: err,
    };
}

export function loadApplicationSettingsAdmin() {
    return function (dispatch) {
        dispatch(loadApplicationSettingsStart());
        return applicationSettingsResource
            .getApplicationSettings()
            .then((applicationSettings) => {
                dispatch(storeApplicationSettings(applicationSettings));
                dispatch(loadApplicationSettingsSuccess(applicationSettings));
            })
            .catch((err) => {
                dispatch(loadApplicationSettingsFailure(err));
            });
    };
}

export function upsertApplicationSetting(upsertRequest) {
    // upsertRequest is the only thing that goes to BE, and that doesnt have an id]
    // only uses applicationSetting name for knowing what to update
    return function (dispatch) {
        dispatch(upsertApplicationSettingStart());
        return applicationSettingsResource
            .upsertApplicationSetting(upsertRequest)
            .then((settingView) => {
                dispatch(storeApplicationSettings(settingView));
                dispatch(upsertApplicationSettingSuccess());
            })
            .catch((err) => {
                dispatch(upsertApplicationSettingFailure(err));
            });
    };
}

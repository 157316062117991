import { FileUploadIntermediate } from '@mark43/rms-api';
import { filter, uniq, includes } from 'lodash';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';

const POLLING_FILE_SECURITY_SCANNER_START = 'fileScanning/POLLING_FILE_SECURITY_SCANNER_START';
const POLLING_FILE_SECURITY_SCANNER_SUCCESS = 'fileScanning/POLLING_FILE_SECURITY_SCANNER_SUCCESS';
const POLLING_FILE_SECURITY_SCANNER_FAILURE = 'fileScanning/POLLING_FILE_SECURITY_SCANNER_FAILURE';
const POLLING_FILE_SECURITY_SCANNER_END = 'fileScanning/POLLING_FILE_SECURITY_SCANNER_END';
const POLLING_FILE_SECURITY_SCANNER_REMOVE_FILES =
    'fileScanning/POLLING_FILE_SECURITY_SCANNER_REMOVE_FILES';
const POLLING_FILE_SECURITY_SCANNER_ADD_FILES =
    'fileScanning/POLLING_FILE_SECURITY_SCANNER_ADD_FILES';
const POLLING_FILE_SECURITY_SCANNER_ADD_S3_UPLOADS =
    'fileScanning/POLLING_FILE_SECURITY_SCANNER_ADD_S3_UPLOADS';

// ACTION TYPES

export function pollingFileSecurityScannerStart() {
    return {
        type: POLLING_FILE_SECURITY_SCANNER_START,
    } as const;
}

export function pollingFileSecurityScannerSuccess() {
    return {
        type: POLLING_FILE_SECURITY_SCANNER_SUCCESS,
    } as const;
}

export function pollingFileSecurityScannerEnd() {
    return {
        type: POLLING_FILE_SECURITY_SCANNER_END,
    } as const;
}

export function pollingFileSecurityScannerFailure(err = { message: '' }) {
    return {
        type: POLLING_FILE_SECURITY_SCANNER_FAILURE,
        payload: err.message,
    } as const;
}

export function pollingFileSecurityScannerRemoveFiles(fileIds: number[]) {
    return {
        type: POLLING_FILE_SECURITY_SCANNER_REMOVE_FILES,
        payload: fileIds,
    } as const;
}

export function pollingFileSecurityScannerAddFiles(fileIds: number[]) {
    return {
        type: POLLING_FILE_SECURITY_SCANNER_ADD_FILES,
        payload: fileIds,
    } as const;
}

export function pollingFileSecurityScannerUpdateS3Files(s3Uploads: S3FileUploadType[]) {
    return {
        type: POLLING_FILE_SECURITY_SCANNER_ADD_S3_UPLOADS,
        payload: s3Uploads,
    } as const;
}

type FileScanningUiActions =
    | ReturnType<typeof pollingFileSecurityScannerStart>
    | ReturnType<typeof pollingFileSecurityScannerSuccess>
    | ReturnType<typeof pollingFileSecurityScannerEnd>
    | ReturnType<typeof pollingFileSecurityScannerFailure>
    | ReturnType<typeof pollingFileSecurityScannerRemoveFiles>
    | ReturnType<typeof pollingFileSecurityScannerAddFiles>
    | ReturnType<typeof pollingFileSecurityScannerUpdateS3Files>;

// SELECTORS
export const fileScannerIsPollingSelector = (state: RootState) =>
    state.ui.attachments.fileScanning.polling;
export const filesToScanSelector = (state: RootState) => state.ui.attachments.fileScanning.fileIds;
export const s3UploadsSelector = (state: RootState) => state.ui.attachments.fileScanning.s3Uploads;

// REDUCER
interface S3FileUploadType extends FileUploadIntermediate {
    preview: string;
    file: File;
}

type fileScanningUiState = {
    polling: boolean;
    errorMessages: string[];
    fileIds: number[];
    s3Uploads: S3FileUploadType[];
};

export default function fileScanningUiReducer(
    state: fileScanningUiState = {
        polling: false,
        errorMessages: [],
        fileIds: [],
        s3Uploads: [],
    },
    action: FileScanningUiActions
) {
    switch (action.type) {
        case POLLING_FILE_SECURITY_SCANNER_START:
            return {
                ...state,
                polling: true,
            };
        case POLLING_FILE_SECURITY_SCANNER_SUCCESS:
        case POLLING_FILE_SECURITY_SCANNER_END:
            return {
                ...state,
                polling: false,
                s3Uploads: [],
                fileIds: [],
            };
        case POLLING_FILE_SECURITY_SCANNER_FAILURE:
            return {
                ...state,
                errorMessages: [action.payload],
            };
        case POLLING_FILE_SECURITY_SCANNER_REMOVE_FILES:
            return {
                ...state,
                fileIds: filter(state.fileIds, (id) => !includes(action.payload, id)),
            };
        case POLLING_FILE_SECURITY_SCANNER_ADD_FILES:
            return {
                ...state,
                fileIds: [...uniq([...state.fileIds, ...action.payload])],
            };
        case POLLING_FILE_SECURITY_SCANNER_ADD_S3_UPLOADS:
            return {
                ...state,
                s3Uploads: action.payload,
            };
        default:
            return state;
    }
}

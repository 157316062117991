/**
 * This function comes from a Stack Overflow answer and helps us convert the
 *   image data from inside a canvas into a Blob which can then be submitted to
 *   the server. We can't use canvas.toBlob() because it's not supported by IE.
 * @param  {string} dataUri
 * @return {Blob}
 * @see    https://stackoverflow.com/a/5100158
 */
export function dataUriToBlob(dataUri: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0) {
        byteString = window.atob(dataUri.split(',')[1]);
    } else {
        byteString = unescape(dataUri.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new window.Blob([ia], { type: mimeString });
}

import { some } from 'lodash';

import {
    PaperFormatEnum,
    PaperFormatEnumType,
    Printable,
    PrintingDataTypeEnum,
} from '@mark43/rms-api';
import isPrintableOfPrintingDataType from './isPrintableOfPrintingDataType';

function needsMultiPdf(printable: Printable): boolean {
    // see CHI-966 for case note requirements
    return (
        isPrintableOfPrintingDataType(printable, PrintingDataTypeEnum.PARENT_FOLDER.name) ||
        isPrintableOfPrintingDataType(printable, PrintingDataTypeEnum.CASE_NOTE.name) ||
        some(printable.childPrintables, (childPrintable) => needsMultiPdf(childPrintable))
    );
}

/**
 * This function only applies when an export's final output file is a ZIP which contains PDF(s). If you need to support
 *   non-PDF formats, update this function.
 *
 * When a WorkOrder has child WorkOrders, the paperFormat property only needs to be set on that top WorkOrder.
 *
 * The `PDF` value means that all WorkOrders which export as PDFs are combined into a single PDF. This has always been
 *   the default behavior in the RMS.
 * The `MULTI_PDF` value means that every WorkOrder which exports as a PDF is its own PDF in the ZIP. For example, when
 *   exporting a case together with its linked reports and case notes, the final output ZIP will contain a case PDF,
 *   multiple report PDFs, and multiple case note PDFs. Setting `MULTI_PDF` on a child WorkOrder has no effect.
 */
export default function computePaperFormat(
    printable: Printable,
    {
        folderingEnabled,
        caseEnhancementsEnabled,
        combinedPDF,
    }: {
        folderingEnabled: boolean;
        caseEnhancementsEnabled: boolean;
        combinedPDF: boolean | undefined;
    }
): PaperFormatEnumType {
    if (printable.dataType === PrintingDataTypeEnum.PHOTO_LINEUP.name) {
        return PaperFormatEnum.MULTI_PDF.name;
    }

    if (!folderingEnabled && !caseEnhancementsEnabled) {
        return PaperFormatEnum.PDF.name;
    }

    if (combinedPDF !== undefined) {
        return combinedPDF ? PaperFormatEnum.PDF.name : PaperFormatEnum.MULTI_PDF.name;
    }

    return needsMultiPdf(printable) ? PaperFormatEnum.MULTI_PDF.name : PaperFormatEnum.PDF.name;
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { exportsPageLoad } from '../../../../../legacy-redux/actions/exportsActions';

import { routeNameSelector } from '../../../../../routing/routerModule';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import testIds from '../../../../../core/testIds';
import Link from '../../../../core/components/links/Link';
import RecordsHeaderButton from './RecordsHeaderButton';

const strings = componentStrings.core.header.RecordsHeaderExportsButton;
const dropdownStrings = componentStrings.core.header.ReportHeaderExportsDropdown;

function RecordsHeaderExportsButton({
    onClick,
    routeName,
    exportRouteName,
    asDropdownOption = false,
}) {
    return !asDropdownOption ? (
        <RecordsHeaderButton
            overlay={strings.tooltip}
            testId={testIds.REPORT_HEADER_EXPORTS_BUTTON}
        >
            <Button
                className={buttonTypes.ICON}
                onClick={onClick}
                active={exportRouteName === routeName}
            >
                <Icon size={18} type={iconTypes.EXPORT} />
            </Button>
        </RecordsHeaderButton>
    ) : (
        <Link
            testId={testIds.REPORT_HEADER_EXPORTS_MENU_ADVANCED_OPTIONS_LINK}
            className="dropdown-menu-option"
            onClick={onClick}
        >
            {dropdownStrings.advancedOptions}
        </Link>
    );
}

const mapStateToProps = createStructuredSelector({
    // The name of the active route
    routeName: routeNameSelector,
});

const mapDispatchToProps = (dispatch) => ({
    exportsPageLoad: () => dispatch(exportsPageLoad()),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        // This will append 'exports' to the given path name and push that new URL
        // routeName: the name of the currently active route in the URL
        // exportRouteName: the name of the export page route for that entity (from routesConfig)
        // path: the base url path for that entity
        onClick: ({ router, routeName, path, exportRouteName, exportsPageLoad }) => () => {
            const isExportView = exportRouteName === routeName;
            if (isExportView) {
                router.push(path);
            } else {
                exportsPageLoad();
                router.push(`${path}/exports`);
            }
        },
    })
)(RecordsHeaderExportsButton);

import _, { filter } from 'lodash';
import { LinkType, LinkTypesEnum, EntityTypeEnumType, LinkTypesEnumType } from '@mark43/rms-api';
import { FormatFieldByName } from '../core/fields/state/config';
import * as fields from '../core/enums/universal/fields';
import { prettify, joinTruthyValues } from './stringHelpers';
import { fieldByItemLocationType } from './itemLocationLinksHelper';

interface LinkTypeOption {
    value: string | number;
    display: string;
}

export type LinkTypeViewModel = LinkType & { symmetric: boolean };

export const otherPersonLocationTypes = [
    LinkTypesEnum.PERSON_BAIL_LOCATION,
    LinkTypesEnum.PERSON_DETAINED_AT_LOCATION,
    LinkTypesEnum.PERSON_DISCHARGE_LOCATION,
    LinkTypesEnum.PERSON_FOUND_AT_LOCATION,
    LinkTypesEnum.PERSON_FREQUENTS_LOCATION,
    LinkTypesEnum.PERSON_HAS_AN_INTEREST_IN_LOCATION,
    LinkTypesEnum.PERSON_SIGHTED_LOCATION,
    LinkTypesEnum.PERSON_RELATIVES_LOCATION,
    LinkTypesEnum.PERSON_PLACE_OF_SAFETY_LOCATION,
    LinkTypesEnum.PERSON_SEARCHED_AT_LOCATION,
    LinkTypesEnum.PERSON_SAFE_LOCATION,
    LinkTypesEnum.PERSON_STAYING_AT_LOCATION,
    LinkTypesEnum.PERSON_STOPPED_AND_SEARCHED_LOCATION,
    LinkTypesEnum.PERSON_STOPPED_LOCATION,
];

export const getOtherLocationTypesLabelMap = (formatFieldByName: FormatFieldByName) => {
    return {
        [LinkTypesEnum.PERSON_BAIL_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_BAIL_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_DETAINED_AT_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_DETAINED_AT_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_DISCHARGE_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_DISCHARGE_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_FOUND_AT_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_FOUND_AT_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_FREQUENTS_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_FREQUENTS_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_HAS_AN_INTEREST_IN_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_HAS_AN_INTEREST_IN_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_PLACE_OF_SAFETY_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_PLACE_OF_SAFETY_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_RELATIVES_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_RELATIVES_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_SIGHTED_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_SIGHTED_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_SAFE_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_SAFE_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_SEARCHED_AT_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_SEARCHED_AT_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_STOPPED_AND_SEARCHED_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_STOPPED_AND_SEARCHED_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_STOPPED_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_STOPPED_LOCATION_LABEL
        ),
        [LinkTypesEnum.PERSON_STAYING_AT_LOCATION]: formatFieldByName(
            fields.DISPLAY_ONLY_PERSON_STAYING_AT_LOCATION_LABEL
        ),
    };
};

/**
 * Format the display string of a link type.
 *   Must be identical to {@link printing/src/helpers/linkTypesHelpers.js}.
 * @param  linkType
 * @param [useLinkNameTwo=false] format/return the `linkNameTwo`
 *   property of the link type rather than `linkNameOne`
 */
export function formatLinkType(linkType: LinkType, useLinkNameTwo = false): string {
    return !linkType
        ? ''
        : useLinkNameTwo
        ? prettify(linkType.linkNameTwo)
        : prettify(linkType.linkNameOne);
}

/**
 * Filter the given link types to ones between the given entity type pairs.
 * @param  linkTypes
 * @param  entityTypePairs Each element of the array must be an array
 *   of 2 enum values. Within each pair, order does not matter, and 1 of the 2
 *   values may be `undefined` since there exist link types with only one entity
 *   type.
 */
export function filterLinkTypes(
    linkTypes: LinkType[],
    entityTypePairs: [string, string | undefined][]
): LinkType[] {
    return _(entityTypePairs)
        .map(([type1, type2]) =>
            filter(
                linkTypes,
                (
                    { entityOneType, entityTwoType } // either may be undefined
                ) =>
                    (type1 === entityOneType && type2 === entityTwoType) ||
                    (type1 === entityTwoType && type2 === entityOneType)
            )
        )
        .flatten()
        .value();
}

/**
 * Filter the given link types so that hidden types are not shown. Link Types already selected
 * will not be filtered out.
 */
export function filterHiddenLinkTypes(
    linkTypes: LinkType[],
    hiddenLinkTypeIds: number[],
    selectedValues: string[]
) {
    const selectedIds = _(selectedValues)
        .map((val) => parseInt(val))
        .value();

    return _(linkTypes)
        .filter(
            (linkType) =>
                selectedIds.includes(linkType.id) || !hiddenLinkTypeIds.includes(linkType.id)
        )
        .value();
}

/**
 * Convert the given link type to a dropdown/checkbox option. Do not use this
 *   helper on asymmetric link types.
 * @param  linkType
 * @return Dropdown/checkbox option.
 */
function mapLinkTypeToOption(linkType: LinkType): LinkTypeOption {
    return {
        value: linkType.id,
        display: formatLinkType(linkType),
    };
}

/**
 * Array version of `mapLinkTypeToOption()`.
 * @param  linkTypes
 * @return Dropdown/checkbox options.
 */
export function mapLinkTypesToOptions(linkTypes: LinkType[]): LinkTypeOption[] {
    return _(linkTypes).map(mapLinkTypeToOption).sortBy('display').value();
}

/**
 * Build a view model for the given link type with extra computed properties.
 * @param    linkType
 * @return   linkType
 * @property symmetric `true` if the order of names doesn't matter
 *   (e.g. friend/friend), `false` otherwise (e.g. employer/employee).
 */
export function buildLinkTypeViewModel(linkType: LinkType): LinkTypeViewModel {
    return {
        ...linkType,
        symmetric:
            linkType.entityOneType === linkType.entityTwoType &&
            linkType.linkNameOne === linkType.linkNameTwo,
    };
}

export function convertNameNameLinkTypeToOptions(
    nameNameLinkType: LinkType,
    entityTypeFrom: EntityTypeEnumType,
    isInverted: boolean
): LinkTypeOption[] {
    const {
        id,
        entityOneType,
        entityTwoType,
        linkNameOne,
        linkNameTwo,
        symmetric,
    } = buildLinkTypeViewModel(nameNameLinkType);

    if (symmetric) {
        return [
            {
                value: `${id}`,
                display: prettify(linkNameOne),
            },
        ];
    }

    const options: LinkTypeOption[] = [];

    if (entityOneType === entityTypeFrom) {
        const linkNameToUse = isInverted ? linkNameTwo : linkNameOne;
        options.push({
            value: `${id}-linkNameOne`,
            display: prettify(linkNameToUse),
        });
    }

    if (entityTwoType === entityTypeFrom) {
        const linkNameToUse = isInverted ? linkNameOne : linkNameTwo;
        options.push({
            value: `${id}-linkNameTwo`,
            display: prettify(linkNameToUse),
        });
    }

    return options;
}

export function formatLocationLinkTypeId(
    linkTypeId: LinkTypesEnumType,
    formatFieldByName?: FormatFieldByName
): string {
    switch (linkTypeId) {
        case LinkTypesEnum.LOCATION_OF_EVENT:
            return 'Event Location';
        case LinkTypesEnum.LIVES_AT:
            return 'Home Address';
        case LinkTypesEnum.WORKS_AT:
            return 'Work Address';
        case LinkTypesEnum.ATTENDS_SCHOOL_AT:
            return 'School Address';
        case LinkTypesEnum.BUSINESS_LOCATION:
            return 'Physical Address';
        case LinkTypesEnum.BUSINESS_ADDRESS:
            return 'Mailing Address';
        case LinkTypesEnum.HANGS_OUT_AT:
            return 'Known Hangout';
        case LinkTypesEnum.PROPERTY_RECOVERED_LOCATION:
            return 'Recovered Location';
        case LinkTypesEnum.ARREST_LOCATION:
            return 'Arrest Location';
        case LinkTypesEnum.OFFENSE_LOCATION:
            const offenseDisplayName = formatFieldByName
                ? formatFieldByName(fields.DISPLAY_ONLY_OFFENSE)
                : 'Offense';
            return `${offenseDisplayName} Location`;
        case LinkTypesEnum.LOCATION_OF_USE_OF_FORCE:
            return 'Use of Force Location';
        case LinkTypesEnum.TOW_VEHICLE_FROM_LOCATION:
            return 'Tow Vehicle From Location';
        case LinkTypesEnum.TOW_VEHICLE_TO_LOCATION:
            return 'Tow Vehicle To Location';
        case LinkTypesEnum.TOW_VEHICLE_TOWED_FROM_LOCATION:
            return 'Tow Vehicle Towed From Location';
        case LinkTypesEnum.TOW_VEHICLE_REPORTED_STOLEN_LOCATION:
            return 'Tow Vehicle Reported Stolen Location';
        case LinkTypesEnum.TOW_VEHICLE_RECOVERY_LOCATION:
            return 'Tow Vehicle Recovery Location';
        case LinkTypesEnum.TOW_VEHICLE_STORAGE_LOCATION:
            return 'Tow Vehicle Storage Location';
        case LinkTypesEnum.OTHER_LOCATION_IN_REPORT:
            return 'Other Report Location';
        case LinkTypesEnum.LOCATION_OF_FIELD_CONTACT:
            return 'Field Contact Location';
        case LinkTypesEnum.LOCATION_OF_BEHAVIORAL_CRISIS:
            return 'Behavioral Crisis Location';
        case LinkTypesEnum.LOCATION_OF_CITATION:
            return 'Citation Location';
        case LinkTypesEnum.LAST_KNOWN_LOCATION:
            return 'Last Known Location';
        case LinkTypesEnum.LOCATION_WHERE_LOCATED:
            return 'Located Location';
        case LinkTypesEnum.LOCATION_OF_TRAFFIC_CRASH:
            return 'Traffic Crash Location';
        case LinkTypesEnum.CONFIGURED_ENTITY_LOCATION:
            return 'Configured Entity Location';
        case LinkTypesEnum.VEHICLE_ABANDONED_LOCATION:
            return formatFieldByName
                ? formatFieldByName(
                      fieldByItemLocationType[LinkTypesEnum.VEHICLE_ABANDONED_LOCATION]
                  )
                : 'Abandoned At';
        case LinkTypesEnum.VEHICLE_BURNED_LOCATION:
            return formatFieldByName
                ? formatFieldByName(fieldByItemLocationType[LinkTypesEnum.VEHICLE_BURNED_LOCATION])
                : 'Burnt Out At';
        case LinkTypesEnum.VEHICLE_REGISTERED_LOCATION:
            return formatFieldByName
                ? formatFieldByName(
                      fieldByItemLocationType[LinkTypesEnum.VEHICLE_REGISTERED_LOCATION]
                  )
                : 'Registered To';
        case LinkTypesEnum.VEHICLE_SEEN_LOCATION:
            return formatFieldByName
                ? formatFieldByName(fieldByItemLocationType[LinkTypesEnum.VEHICLE_SEEN_LOCATION])
                : 'Seen At';
        case LinkTypesEnum.VEHICLE_STOLEN_FROM_LOCATION:
            return formatFieldByName
                ? formatFieldByName(
                      fieldByItemLocationType[LinkTypesEnum.VEHICLE_STOLEN_FROM_LOCATION]
                  )
                : 'Stolen From';
        default:
            return '';
    }
}

export function formatNameReportLinkTypeId(
    linkTypeId: LinkTypesEnumType,
    formatFieldByName: FormatFieldByName
): string | undefined {
    switch (linkTypeId) {
        case LinkTypesEnum.DEFENDANT_IN_ARREST:
            return formatFieldByName(fields.ARREST_DEFENDANT_ID);
        case LinkTypesEnum.REPORTING_PARTY_IN_REPORT:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_REPORTING_PARTY_IN_REPORT_LINK_TYPE
            );
        case LinkTypesEnum.COMPLAINANT_IN_REPORT:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_COMPLAINANT_IN_REPORT_LINK_TYPE
            );
        case LinkTypesEnum.INVOLVED_PERSON_IN_REPORT:
            return 'Involved Person';
        case LinkTypesEnum.INVOLVED_ORG_IN_REPORT:
            return 'Involved Organization';
        case LinkTypesEnum.OTHER_NAME_IN_REPORT:
        case LinkTypesEnum.OTHER_NAME_IN_OFFENSE:
            return 'Involved Other';
        case LinkTypesEnum.OTHER_NAME_IN_STOP:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_STOP_LINK_TYPE
            );
        case LinkTypesEnum.OTHER_NAME_IN_FIELD_CONTACT:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_FIELD_CONTACT_LINK_TYPE
            );
        case LinkTypesEnum.OTHER_NAME_IN_BEHAVIORAL_CRISIS:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_BEHAVIORAL_CRISIS_LINK_TYPE
            );
        case LinkTypesEnum.PERSON_IN_VEHICLE:
            return 'In Vehicle';
        case LinkTypesEnum.CO_DEFENDANT:
            return formatFieldByName(fields.NAME_REPORT_LINK_LINK_TYPE_CO_DEFENDANT_LINK_TYPE);
        case LinkTypesEnum.VICTIM_IN_OFFENSE:
            return formatFieldByName(fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_LINK_TYPE);
        case LinkTypesEnum.VICTIM_IN_REPORT:
            return 'Victim';
        case LinkTypesEnum.SUBJECT_IN_STOP:
            return formatFieldByName(fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_STOP_LINK_TYPE);
        case LinkTypesEnum.SUBJECT_IN_FIELD_CONTACT:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_LINK_TYPE
            );
        case LinkTypesEnum.SUBJECT_IN_COMMUNITY_INFORMATION:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_LINK_TYPE
            );
        case LinkTypesEnum.SUBJECT_IN_OFFENSE:
        case LinkTypesEnum.SUBJECT_IN_USE_OF_FORCE:
        case LinkTypesEnum.SUBJECT_IN_TRAFFIC_CRASH:
            return 'Subject';
        case LinkTypesEnum.SUBJECT_IN_BEHAVIORAL_CRISIS:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_BEHAVIORAL_CRISIS_LINK_TYPE
            );
        case LinkTypesEnum.ORGANIZATION_IN_FIELD_CONTACT:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_ORGANIZATION_IN_FIELD_CONTACT_LINK_TYPE
            );
        case LinkTypesEnum.SUBJECT_OF_WARRANT:
            return 'Warrant Subject';
        case LinkTypesEnum.SUBJECT_IN_CITATION:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_CITATION_LINK_TYPE
            );
        case LinkTypesEnum.WITNESS_IN_OFFENSE:
        case LinkTypesEnum.WITNESS_IN_REPORT:
            return 'Witness';
        case LinkTypesEnum.MISSING_PERSON_IN_REPORT:
            return 'Missing Person';
        case LinkTypesEnum.LAST_KNOWN_CONTACT_IN_REPORT:
            return 'Last Known Contact';
        case LinkTypesEnum.SUSPECT_IN_REPORT:
            return 'Suspect';
        case LinkTypesEnum.SUSPECT_IN_OFFENSE:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_LINK_TYPE
            );
        case LinkTypesEnum.OFFENDER_IN_OFFENSE:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_OFFENDER_IN_OFFENSE_LINK_TYPE
            );
        case LinkTypesEnum.NON_MOTORIST:
            return 'Involved in Crash';
        case LinkTypesEnum.TOW_VEHICLE_RELEASE_TO:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_TOW_VEHICLE_RELEASE_TO_LINK_TYPE
            );
        case LinkTypesEnum.TOW_VEHICLE_RELEASE_AUTHORIZED_BY:
            return formatFieldByName(
                fields.NAME_REPORT_LINK_LINK_TYPE_TOW_VEHICLE_RELEASE_AUTHORIZED_BY_LINK_TYPE
            );
        default:
            return '';
    }
}

const linkTypeLetters = {
    [LinkTypesEnum.VICTIM_IN_OFFENSE]: 'V',
    [LinkTypesEnum.VICTIM_IN_REPORT]: 'V',
    [LinkTypesEnum.SUSPECT_IN_OFFENSE]: 'S',
    [LinkTypesEnum.SUSPECT_IN_REPORT]: 'S',
    [LinkTypesEnum.OFFENDER_IN_OFFENSE]: 'OFF',
    [LinkTypesEnum.MISSING_PERSON_IN_REPORT]: 'M',
    [LinkTypesEnum.WITNESS_IN_OFFENSE]: 'W',
    [LinkTypesEnum.WITNESS_IN_REPORT]: 'W',
    [LinkTypesEnum.DEFENDANT_IN_ARREST]: 'D',
    [LinkTypesEnum.INVOLVED_PERSON_IN_REPORT]: 'P',
    [LinkTypesEnum.INVOLVED_ORG_IN_REPORT]: 'O',
    [LinkTypesEnum.OTHER_NAME_IN_REPORT]: 'O',
    [LinkTypesEnum.OTHER_NAME_IN_OFFENSE]: 'O',
    [LinkTypesEnum.OTHER_NAME_IN_BEHAVIORAL_CRISIS]: 'O',
    [LinkTypesEnum.OTHER_NAME_IN_STOP]: 'O',
    [LinkTypesEnum.OTHER_NAME_IN_FIELD_CONTACT]: 'O',
    [LinkTypesEnum.SUBJECT_IN_OFFENSE]: 'SB',
    [LinkTypesEnum.SUBJECT_IN_STOP]: 'SB',
    [LinkTypesEnum.SUBJECT_IN_FIELD_CONTACT]: 'SB',
    [LinkTypesEnum.SUBJECT_IN_USE_OF_FORCE]: 'SB',
    [LinkTypesEnum.SUBJECT_IN_TRAFFIC_CRASH]: 'SB',
    [LinkTypesEnum.SUBJECT_IN_BEHAVIORAL_CRISIS]: 'SB',
    [LinkTypesEnum.SUBJECT_IN_CITATION]: 'CR',
    [LinkTypesEnum.SUBJECT_OF_WARRANT]: 'SB',
    [LinkTypesEnum.ORGANIZATION_IN_FIELD_CONTACT]: 'OR',
    [LinkTypesEnum.REPORTING_PARTY_IN_REPORT]: 'R',
    [LinkTypesEnum.CO_DEFENDANT]: 'C',
    [LinkTypesEnum.COMPLAINANT_IN_REPORT]: 'CP',
    [LinkTypesEnum.LAST_KNOWN_CONTACT_IN_REPORT]: 'LC',
    [LinkTypesEnum.TOW_VEHICLE_RELEASE_TO]: 'RT',
    [LinkTypesEnum.TOW_VEHICLE_RELEASE_AUTHORIZED_BY]: 'RA',
    [LinkTypesEnum.CONFIGURED_ENTITY_NAME_IN_REPORT]: 'O',
} as const;

export function formatLinkTypeLetterNumber(
    linkType: keyof typeof linkTypeLetters,
    number: number
): string {
    return joinTruthyValues([linkTypeLetters[linkType] || '', number], '-');
}

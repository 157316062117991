/**
 * Special select component to handle new 'No Supervisor' option for user
 * See RMS-7870
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, defaultProps, setPropTypes, setDisplayName } from 'recompose';
import { createStructuredSelector } from 'reselect';
import keyMirror from 'keymirror';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { roleOptionsByTypesSelector } from '~/client-common/core/domain/roles/state/data';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CASE_SUPERVISOR_LABEL } from '~/client-common/core/enums/universal/fields';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import RoleSelect from './RoleSelect';
import { multiFieldSelectFactory } from './MultiFieldSelect';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const strings = componentStrings.cases;

/*
 * We need RoleSelect to support multiple paths (hasSupervisor and supervisorRoleIds) despite it being async
 * This is to support 'No Supervisor' option (See RMS-7870)
 * We get around this by wrapping RoleSelect with multiFieldSelectFactory HOC
 */

const MultiFieldRoleSelect = multiFieldSelectFactory(RoleSelect);

const CaseSupervisorSelect = connect(
    createStructuredSelector({
        roleOptionsByTypes: roleOptionsByTypesSelector,
    })
)(function CaseSupervisorSelect({
    fields: { hasSupervisor, supervisorRoleIds },
    roleOptionsByTypes,
    roleTypes,
    ...otherProps
}) {
    const supervisorLabel = useFields(DISPLAY_ONLY_CASE_SUPERVISOR_LABEL)[
        DISPLAY_ONLY_CASE_SUPERVISOR_LABEL
    ];
    return (
        <MultiFieldRoleSelect
            {...otherProps}
            fields={{
                hasSupervisor: {
                    options: [
                        {
                            value: false,
                            display: strings.allCases.AllCasesSearchPersonnelFieldset.labels.noSupervisor(
                                supervisorLabel
                            ),
                            none: true,
                        },
                    ],
                    ...hasSupervisor,
                },
                supervisorRoleIds: {
                    options: roleOptionsByTypes(roleTypes),
                    ...supervisorRoleIds,
                },
            }}
        />
    );
});

export const RRFCaseSupervisorSelect = compose(
    setDisplayName('RRFCaseSupervisorSelect'),
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            hasSupervisor: null,
            supervisorRoleIds: null,
        }),
    }),
    connectRRFMultiFieldInput
)(CaseSupervisorSelect);

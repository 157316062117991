import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { SearchableConfiguredPropertyOptionView } from '@mark43/rms-api';
import { HStack } from 'arc';
import { useDispatch } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import dragonRmsIntegrationResource from '~/client-common/core/domain/dragon-rms-integration/resources/dragonRmsIntegrationResource';
import { storeSearchableConfiguredPropertyOptionViews } from '~/client-common/core/domain/dragon-rms-integration/state/data/searchable-configured-property-option-views';

import { SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { RRFSelect } from '../../../../core/forms/components/selects/Select';
import { RRFNItems } from '../../../../core/forms/components/NItems';
import { GroupedOption, SelectOption } from '../../../../core/forms/helpers/selectHelpers';
import { RRFText } from '../../../../core/forms/components/Text';
import FormRow from '../../../../core/forms/components/FormRow';
import { convertSearchableConfiguredPropertyOptionViewToOption } from '../../utils/dragon/convertSearchableConfiguredPropertyOptionViewToOption';
import { ConfiguredEntityPropertyInput } from './ConfiguredEntityPropertyInput';
import { FetchError } from './common';
import { ReportConfiguredEntityProperty } from './types';

const strings = componentStrings.search.AdvancedSearchReportsForm.dragon;

export function ConfiguredEntityPropertyFieldset({
    reportDefinitionId,
}: {
    reportDefinitionId: number;
}): null | JSX.Element {
    const dispatch = useDispatch();
    const getSearchableConfiguredEntityPropertOptions = React.useMemo(() => {
        return () => {
            return dragonRmsIntegrationResource.getReportSearchableConfiguredPropertyOptionViewsForReportDefinitionRequest(
                {
                    reportDefinitionIds: [reportDefinitionId],
                }
            );
        };
    }, [reportDefinitionId]);

    const query = useQuery({
        queryKey: ['rms-report-searchable-configured-entity-property-options', reportDefinitionId],
        queryFn: getSearchableConfiguredEntityPropertOptions,
        // Only one retry to not delay showing failure feedback to the user for too long
        retry: 1,
        cacheTime: 120000,
    });
    const { indexedOptionViews, options } = React.useMemo(() => {
        return (
            query.data?.reduce<{
                options: GroupedOption<SelectOption>[];
                indexedOptionViews: Record<string, SearchableConfiguredPropertyOptionView>;
            }>(
                (acc, option) => {
                    const options = option.searchableConfiguredPropertyOptionHierarchyViews.flatMap(
                        (optionHierarchyView) => {
                            return {
                                label: `${optionHierarchyView.formDisplay} / ${optionHierarchyView.entityDisplay}`,
                                options: optionHierarchyView.options.map((optionView) => {
                                    const option =
                                        convertSearchableConfiguredPropertyOptionViewToOption(
                                            optionView
                                        );
                                    acc.indexedOptionViews[String(option.value)] = optionView;
                                    return option;
                                }),
                            };
                        }
                    );
                    acc.options.push(...options);
                    return acc;
                },
                { options: [], indexedOptionViews: {} }
            ) ?? { options: [], indexedOptionViews: {} }
        );
    }, [query.data]);

    if (query.isFetching && !options?.length) {
        return <SimpleLoading />;
    }

    if (query.isError) {
        return <FetchError onRefetchClick={query.refetch} />;
    }

    if (!options?.length) {
        return null;
    }

    return (
        <>
            <FormRow>
                <RRFText label={strings.keywordField} path="keywords" length="md" />
            </FormRow>
            <RRFNItems path="reportConfiguredEntityProperties" addItemOnDirty={true}>
                {(item: ReportConfiguredEntityProperty, _1: unknown, deleteButton: JSX.Element) => {
                    const configuredEntityPropertyDescriptorPath =
                        'configuredEntityPropertyDescriptor';
                    const optionView = item[configuredEntityPropertyDescriptorPath]
                        ? indexedOptionViews[item[configuredEntityPropertyDescriptorPath]]
                        : undefined;
                    return (
                        <HStack alignItems="flex-start" marginBlockEnd="4">
                            <RRFSelect
                                path={configuredEntityPropertyDescriptorPath}
                                options={options}
                                multiple={false}
                                length="md"
                                label={strings.reportField}
                                style={{ flexBasis: '50%', flexShrink: 0 }}
                                onChange={(value: string | number | boolean | undefined) => {
                                    if (value) {
                                        const optionView = indexedOptionViews[String(value)];
                                        // When selecting an option we store its view in redux so that other
                                        // parts of the search UI can properly display labels for this property.
                                        dispatch(
                                            storeSearchableConfiguredPropertyOptionViews(optionView)
                                        );
                                    }
                                }}
                            />
                            {!!optionView?.valueType && (
                                <ConfiguredEntityPropertyInput
                                    valueType={optionView.valueType}
                                    configuredPropertyKeyName={optionView.configuredPropertyKeyName}
                                    associatedForeignKeyCoreModelKeyName={
                                        optionView.associatedForeignKeyCoreModelKeyName
                                    }
                                    foreignKeyConfiguredEntityKeyName={
                                        optionView.foreignKeyConfiguredEntityKeyName
                                    }
                                />
                            )}
                            {deleteButton}
                        </HStack>
                    );
                }}
            </RRFNItems>
        </>
    );
}

import styled from 'styled-components';

// The default theme is yellow
const NoticeBar = styled.div<{ backgroundColor?: string; borderColor?: string }>`
    box-sizing: border-box;
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : props.theme.colors.lightYellow};
    border-color: ${(props) =>
        props.borderColor ? props.borderColor : props.theme.colors.brightYellow};
    padding: 12px 20px;
    margin-bottom: 16px;
    border-radius: 4px;
    font-size: var(--arc-fontSizes-sm);
`;

/**
 * Notice Bar, without any layout styled
 */
export default NoticeBar;

import { ElasticSearchTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { createFormConfiguration, createNItems, lifecycleOptions } from 'markformythree';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { createStructuredSelector } from 'reselect';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { NEXUS_STATE_PROP as ELASTIC_REPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-reports/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    formatFieldByNameSelector,
    globalSequenceNumberLabelSelector,
} from '~/client-common/core/fields/state/config';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';

import testIds from '../../../../core/testIds';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import EntitySearch from '../../../core/entity-search/EntitySearch';
import Row from '../../../core/components/Row';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import {
    associatedRecordsSidePanelIsOpenSelector,
    associatedRecordsSidePanelReportIdSelector,
    handleSubmit,
    handleCancel,
    addExternalAssociatedRecordToForm,
    addInternalAssociatedRecordToForm,
} from '../state/ui';
import { MFTNItems } from '../../../core/forms/components/NItems';
import { FORM_NAME } from '../state/forms/associatedRecordsForm';
import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';
import { withBookingSearchPermission } from '../../../search/bookings/hocs';
import AssociatedRecordsFormItem, { ItemWrapper, RemoveButton } from './AssociatedRecordsFormItem';

const strings = componentStrings.records.associatedRecords.sidePanel;

const context = {
    name: boxEnum.ASSOCIATED_RECORDS_SIDE_PANEL,
};

const SectionTitle = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: var(--arc-fontSizes-md);
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
    width: 100%;
    text-transform: uppercase;
    line-height: 1;
    padding-bottom: 6px;
    border-bottom: 6px solid ${(props) => props.theme.colors.lightGrey};
`;

function ReportLinkNItems({ path, currentReportId }) {
    return (
        <MFTNItems
            path={path}
            addItemOnEmpty={false}
            childFieldKeys={[
                'sourceId',
                'sourceSystemAttrId',
                'sourceSystemOther',
                'url',
                'reasonForAssociationAttrId',
                'reasonForAssociationOther',
            ]}
            render={(form) => (
                <AssociatedRecordsFormItem currentReportId={currentReportId} form={form} />
            )}
            renderRowContainer={({ itemElement, removeButtonElement, index }) => (
                <ItemWrapper key={index} data-test-id={testIds.REPORT_LINK_WRAPPER}>
                    {itemElement}
                    {removeButtonElement}
                </ItemWrapper>
            )}
            renderAddButton={() => <div />}
            renderRemoveButton={({ removeItem }) => (
                <RemoveButton
                    icon={
                        <Icon
                            color="cobaltBlue"
                            type={iconTypes.TRASH_CAN}
                            size={iconSizes.MEDIUM}
                        />
                    }
                    onClick={removeItem}
                />
            )}
        />
    );
}

class AssociatedRecordsSidePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entitySearchValue: '',
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.associatedRecordsSidePanelIsOpen &&
            !nextProps.associatedRecordsSidePanelIsOpen
        ) {
            this.setState({
                entitySearchValue: '',
            });
        }
    }

    onResultClick = (entitySearchResult) => {
        this.setState({
            entitySearchValue: '',
        });
        this.props.addInternalAssociatedRecordToForm({ elasticReport: entitySearchResult.item });
    };

    onAddClick = (options) => {
        let value = this.state.entitySearchValue;
        if (options && options.bookingNumber) {
            // for booking we need to replace search string with booking number
            value = options.bookingNumber;
        }
        this.props.addExternalAssociatedRecordToForm(value, options);
        this.setState({
            entitySearchValue: '',
        });
    };

    handleSubmit = () => {
        this.props.handleSubmit();
    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    render() {
        const {
            formatFieldByName,
            associatedRecordsSidePanelReportId,
            reportById,
            globalSequenceNumberLabel,
            appSettings,
            canSearchBookings,
        } = this.props;
        let currentRen;
        let currentDepartmentId;
        let currentReportId;
        if (associatedRecordsSidePanelReportId) {
            const currentReport = reportById(associatedRecordsSidePanelReportId);
            currentReportId = currentReport.id;
            currentRen = currentReport.reportingEventNumber;
            currentDepartmentId = currentReport.departmentId;
        }
        return (
            <SidePanel
                title={strings.title}
                cancelText={strings.cancelText}
                saveText={strings.saveText}
                onSave={this.handleSubmit}
                onCancel={this.handleCancel}
                context={context}
                testId={testIds.ASSOCIATED_RECORDS_SIDE_PANEL}
            >
                <Row>
                    <EntitySearch
                        value={this.state.entitySearchValue}
                        onChange={(value) => this.setState({ entitySearchValue: value })}
                        onResultClick={this.onResultClick}
                        onAddClick={this.onAddClick}
                        filterResults={(result) => {
                            // we're filtering out results that match the current REN
                            // if associate_records_within_same_ren app setting is disabled
                            // because they are already associated.

                            // In the scenario where our current REN doesn't exist, we still
                            // want to match on other reports without a REN
                            return (
                                (!result.reportingEventNumber && !currentRen) ||
                                (!appSettings['RMS_ASSOCIATE_RECORDS_WITHIN_SAME_REN'] &&
                                    result.reportingEventNumber !== currentRen) ||
                                (appSettings['RMS_ASSOCIATE_RECORDS_WITHIN_SAME_REN'] &&
                                    currentReportId &&
                                    result.id !== currentReportId) ||
                                result.departmentId !== currentDepartmentId
                            );
                        }}
                        placeholder={strings.placeholder({
                            globalSequenceNumberLabel,
                        })}
                        helpText={strings.helpText({
                            renDisplayName: formatFieldByName(fields.REPORT_REPORTING_EVENT_NUMBER),
                            currentRen,
                        })}
                        helpTextCollisionBoundary={document.querySelector(
                            '.mark43-react-side-panel'
                        )}
                        label={strings.recordIdInput}
                        addButtonText={strings.addButtonText({
                            searchValue: this.state.entitySearchValue,
                        })}
                        entityType={ElasticSearchTypeEnum.REPORT.name}
                        clearSearchOnDefocus
                        showBooking={canSearchBookings}
                    />
                </Row>
                <Row>
                    <ArbiterForm
                        lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                        name={FORM_NAME}
                        context={RefContextEnum.FORM_REPORT_ASSOCIATED_RECORD.name}
                        configuration={createFormConfiguration({
                            associatedRecords: createNItems({
                                fields: {
                                    sourceId: {
                                        fieldName: fields.REPORT_EXTERNAL_LINK_SOURCE_ID,
                                    },
                                    sourceSystemAttrId: {
                                        fieldName:
                                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EXTERNAL_RECORD_SOURCE_ATTRIBUTE_ID,
                                    },
                                    sourceSystemOther: {
                                        fieldName:
                                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EXTERNAL_RECORD_SOURCE_DESCRIPTION,
                                    },
                                    url: { fieldName: fields.REPORT_EXTERNAL_LINK_URL },
                                    reasonForAssociationAttrId: {
                                        fieldName:
                                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_ASSOCIATION_ATTRIBUTE_ID,
                                    },
                                    reasonForAssociationOther: {
                                        fieldName:
                                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_ASSOCIATION_DESCRIPTION,
                                    },
                                    isExternal: {
                                        fieldName:
                                            fields.DISPLAY_REPORT_ASSOCIATED_RECORD_IS_EXTERNAL,
                                    },
                                },
                            }),
                            prevAssociatedRecords: createNItems({
                                fields: {
                                    sourceId: {
                                        fieldName: fields.REPORT_EXTERNAL_LINK_SOURCE_ID,
                                    },
                                    sourceSystemAttrId: {
                                        fieldName:
                                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EXTERNAL_RECORD_SOURCE_ATTRIBUTE_ID,
                                    },
                                    sourceSystemOther: {
                                        fieldName:
                                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EXTERNAL_RECORD_SOURCE_DESCRIPTION,
                                    },
                                    url: { fieldName: fields.REPORT_EXTERNAL_LINK_URL },
                                    reasonForAssociationAttrId: {
                                        fieldName:
                                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_ASSOCIATION_ATTRIBUTE_ID,
                                    },
                                    reasonForAssociationOther: {
                                        fieldName:
                                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_ASSOCIATION_DESCRIPTION,
                                    },
                                    isExternal: {
                                        fieldName:
                                            fields.DISPLAY_REPORT_ASSOCIATED_RECORD_IS_EXTERNAL,
                                    },
                                },
                            }),
                        })}
                        render={() => (
                            <div>
                                <ReportLinkNItems
                                    currentReportId={associatedRecordsSidePanelReportId}
                                    path="associatedRecords"
                                />
                                <SectionTitle>
                                    Records Currently Associated With Report
                                </SectionTitle>
                                <ReportLinkNItems
                                    currentReportId={associatedRecordsSidePanelReportId}
                                    path="prevAssociatedRecords"
                                />
                            </div>
                        )}
                    />
                </Row>
            </SidePanel>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    associatedRecordsSidePanelReportId: associatedRecordsSidePanelReportIdSelector,
    associatedRecordsSidePanelIsOpen: associatedRecordsSidePanelIsOpenSelector,
    reportById: reportByIdSelector,
    globalSequenceNumberLabel: globalSequenceNumberLabelSelector,
    appSettings: applicationSettingsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    addExternalAssociatedRecordToForm: (sourceId, options) => {
        dispatch(addExternalAssociatedRecordToForm(sourceId, options));
    },
    addInternalAssociatedRecordToForm: ({ elasticReport }) => {
        dispatch(
            withEntityItems(
                { [ELASTIC_REPORTS_NEXUS_STATE_PROP]: [elasticReport] },
                { type: 'ADD_NEW_INTERNAL_ASSOCIATED_RECORD' }
            )
        );
        dispatch(addInternalAssociatedRecordToForm(elasticReport));
    },
    handleSubmit: () => dispatch(handleSubmit()),
    handleCancel: () => dispatch(handleCancel()),
});

export default compose(
    withBookingSearchPermission,
    connect(mapStateToProps, mapDispatchToProps)
)(AssociatedRecordsSidePanel);

import { UcrEvent } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'ucrEvents';

const ucrEventsModule = createNormalizedModule<UcrEvent>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS

export const ucrEventsWhereSelector = ucrEventsModule.selectors.entitiesWhereSelector;

// REDUCER
export default ucrEventsModule.reducerConfig;

import { EmailResource, FilesSendEmailRequest, PrintsSendEmailRequest } from '@mark43/rms-api';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_PATH = 'email';

export default createResource({
    name: 'Email Resource',
    methods: {
        emailFiles(payload: FilesSendEmailRequest) {
            return req<EmailResource.KickOffSendEmail>({
                method: 'POST',
                url: `${BASE_PATH}/send`,
                data: payload,
            });
        },
        emailWorkOrder(payload: PrintsSendEmailRequest) {
            return req<EmailResource.KickOffPrintAndSendEmail>({
                method: 'POST',
                url: `${BASE_PATH}/print/send`,
                data: payload,
            });
        },
        getEmailStatus(id: string) {
            return req<EmailResource.GetUserSendEmailRequest>({
                url: `${BASE_PATH}/status/mine/${id}/`,
            });
        },
    },
});

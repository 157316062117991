import {
    requestDispositions as requestDispositionsRaw,
    reviewDisposition as reviewDispositionRaw,
} from '~/client-common/core/domain/disposition-events/state/data';

import { openErrorModal } from '../../../../../legacy-redux/actions/boxActions';

/**
 * Request disposition on the given items. The check on whether the request
 *   disposition action can actually be taken on these items is done by the API,
 *   not by the client.
 * @param  {number[]} masterItemIds
 */
export function requestDispositions(masterItemIds) {
    return function (dispatch) {
        dispatch(requestDispositionsRaw(masterItemIds)).catch((err) => {
            dispatch(
                openErrorModal({
                    paragraphs: [
                        'An error occurred with your disposition request, please refresh this report and try again.',
                        err.message,
                    ],
                })
            );
        });
    };
}

/**
 * The ui version of the data action creator to review disposition. On failure,
 *   open an error modal.
 * @param  {number} masterItemId
 * @param  {Object} dispositionEvent
 */
export function reviewDisposition(masterItemId, dispositionEvent) {
    return function (dispatch) {
        dispatch(reviewDispositionRaw(masterItemId, dispositionEvent)).catch((err) => {
            dispatch(
                openErrorModal({
                    paragraphs: [
                        'An error occurred with your disposition review, please refresh this report and try again.',
                        err.message,
                    ],
                })
            );
        });
    };
}

import filter from 'lodash/filter';
import mapValues from 'lodash/mapValues';
import { EntityTypeEnumType, SecurityClassification } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import { ModuleShape } from '~/client-common/redux/state';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { allMiniUserFormatsByIdSelector } from '~/client-common/core/domain/mini-users/state/data';

import {
    allSingleAttributeValuesMapper,
    buildAllMiniUserFormatsMapper,
    buildViewModel,
    ViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { securityClassificationsSelector } from '../data';

type SecurityClassificationViewModelProps = {
    securityClassificationAttrId: string;
    createdBy: string;
    createdDateUtc: string;
    updatedByBy: string;
    updatedByDateUtc: string;
};

type SecurityClassificationViewModel = ViewModel<
    SecurityClassification,
    SecurityClassificationViewModelProps
>;

const buildSecurityClassificationViewModel = (
    securityClassification: SecurityClassification,
    formatters: {
        formatAttributeById: ReturnType<typeof formatAttributeByIdSelector>;
        allMiniUserFormatsById: ReturnType<typeof allMiniUserFormatsByIdSelector>;
        dateFormatter: ReturnType<typeof currentDepartmentDateFormatterSelector>;
    }
): SecurityClassificationViewModel => {
    const { dateFormatter, formatAttributeById, allMiniUserFormatsById } = formatters;
    const makeViewModel = buildViewModel<
        SecurityClassification,
        SecurityClassificationViewModelProps
    >({
        mappers: [
            allSingleAttributeValuesMapper,
            buildAllMiniUserFormatsMapper(),
            ({ createdDateUtc, updatedDateUtc }) => ({
                createdDateUtc: dateFormatter.formatDate(createdDateUtc),
                updatedByDateUtc: dateFormatter.formatDate(updatedDateUtc),
            }),
        ],
        helpers: {
            formatAttributeById,
            allMiniUserFormatsById,
        },
    });
    return makeViewModel(securityClassification);
};

const securityClassificationViewModelsSelector = createSelector(
    formatAttributeByIdSelector,
    allMiniUserFormatsByIdSelector,
    currentDepartmentDateFormatterSelector,
    securityClassificationsSelector,
    (
        formatAttributeById,
        allMiniUserFormatsById,
        dateFormatter,
        securityClassifications
    ): ModuleShape<SecurityClassificationViewModel> => {
        return mapValues(securityClassifications, (securityClassification) =>
            buildSecurityClassificationViewModel(securityClassification, {
                formatAttributeById,
                allMiniUserFormatsById,
                dateFormatter,
            })
        );
    }
);

export const securityClassificationViewModelsByOwnerSelector = createSelector(
    securityClassificationViewModelsSelector,
    (securityClassificationViewModels) => (ownerProps: {
        ownerType: EntityTypeEnumType;
        ownerId: number;
    }) => {
        return filter(securityClassificationViewModels, ownerProps);
    }
);

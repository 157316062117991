import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Location Aliases Admin Resource',
    methods: {
        getAliases() {
            return req({
                method: 'GET',
                url: 'master/locations/aliases',
            });
        },
        createAlias(alias) {
            return req({
                method: 'POST',
                url: 'master/locations/aliases',
                data: alias,
            });
        },
        updateAlias(alias) {
            return req({
                method: 'POST',
                url: 'master/locations/aliases',
                data: alias,
            });
        },
        deleteAlias(alias) {
            return req({
                method: 'DELETE',
                url: `master/locations/aliases/${alias.id}`,
            });
        },
        /**
         * Export all location aliases into a CSV.
         * @return {Promise}
         */
        exportLocationAliases() {
            return req({
                method: 'GET',
                url: 'master/locations/aliases/export',
            });
        },
        /**
         * Purge all location aliases for the dept.
         * @return {Promise}
         */
        purgeAliases() {
            return req({
                method: 'DELETE',
                url: 'master/locations/aliases/purge',
            });
        },
        /**
         * Validate that a file is valid for importing new location aliases.
         * @return {Promise}
         */
        validateLocationAliasImport(fileId) {
            return req({
                method: 'POST',
                url: `master/locations/aliases/import/validate/${fileId}`,
            });
        },
        /**
         * Import location aliases from an uploaded file.
         * @return {Promise}
         */
        importLocationAliases(fileId) {
            return req({
                method: 'POST',
                url: `master/locations/aliases/import/${fileId}`,
            });
        },
    },
});

import React from 'react';
import styled from 'styled-components';
import LocationSummary from '../../../records/core/components/summaries/LocationSummary';

const LocationContainer = styled.div`
    margin-top: 10px;
`;

export default class NotepadLocation extends React.Component {
    render() {
        return (
            <LocationContainer>
                <LocationSummary locationBundle={this.props.locationBundle} />
            </LocationContainer>
        );
    }
}

let resource;
const BASE_PATH = 'reports';

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Report Resource',
        methods: {
            getEventDetailBundleForPrefill(reportId, prefillFromReportId) {
                return req({
                    method: 'GET',
                    url: `reports/${reportId}/event_info/prefill/${prefillFromReportId}`,
                });
            },
            getSupplementCardPrefillBundle(reportId, prefillFromReportId) {
                return req({
                    method: 'GET',
                    url: `reports/${reportId}/supplement_info/prefill/${prefillFromReportId}`,
                });
            },
            /**
             * Update the involved names within a report. Note the report id isn't
             *   needed as the persons/orgs are already uniquely identified.
             * @param  {Object}   involvedNames
             * @param  {Object[]} involvedNames.personProfiles
             * @param  {Object[]} involvedNames.personInjuries
             * @param  {Object[]} involvedNames.nameNameLinks
             * @return {Promise<Object>} Updated models.
             */
            updateInvolvedNames(involvedNamesView) {
                return req({
                    method: 'POST',
                    url: 'reports/involved_names',
                    data: involvedNamesView,
                });
            },
            createReport(reportCreationRequest) {
                return req({
                    method: 'POST',
                    url: 'reports/create',
                    data: reportCreationRequest,
                });
            },
            updateRen(reportId, newRen) {
                return req({
                    method: 'POST',
                    url: `reports/${reportId}/reporting_event_number_2`,
                    data: {
                        data: newRen,
                    },
                });
            },
            updateRecordNumber(reportId, newRecordNumber) {
                return req({
                    method: 'POST',
                    url: `reports/${reportId}/record_number`,
                    data: {
                        data: newRecordNumber,
                    },
                });
            },

            /**
             * Save case statuses associated with reports.
             * @param  {Object[]} caseStatuses
             * @return {Object[]}
             */
            saveCaseStatuses(caseStatuses) {
                return req({
                    method: 'PUT',
                    url: 'reports/case_status',
                    data: caseStatuses,
                });
            },
            updateReportAgency(reportId, agencyId) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/agency/${agencyId}`,
                });
            },
            reassignSubmissionOwner(reportId, ownerId) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/owner/${ownerId}`,
                });
            },
            deleteUseOfForceSubject(reportId, useOfForceSubjectId) {
                return req({
                    method: 'DELETE',
                    url: `reports/${reportId}/use_of_force_subjects/${useOfForceSubjectId}`,
                });
            },
            getOffensesForReport(reportId) {
                return req({
                    method: 'GET',
                    url: `reports/${reportId}/offenses`,
                });
            },
            updateReportNarrative(report = {}) {
                return req({
                    method: 'PUT',
                    url: `reports/${report.id}/narrative`,
                    data: {
                        ...report,
                    },
                });
            },
            updateNarrativeAutosave({ reportId, narrativeHtml }) {
                return req({
                    method: 'PUT',
                    url: `reports/narrative_autosave/${reportId}`,
                    data: {
                        reportId,
                        narrativeHtml,
                    },
                });
            },
            getReportView(reportId) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/${reportId}/full`,
                });
            },
            /**
             * Get the most up to date status of the report.
             * @param  {number} reportId
             * @return {Promise<Object>} ReportStatusView which contains Report and
             *   ReportSubmission.
             */
            getActiveStatus(reportId) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/${reportId}/active_status`,
                });
            },

            getHamburgerMenuView(reportId) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/${reportId}/hamburger_menu_view`,
                });
            },

            /**
             * Get the report history events.
             * @param  {number} reportId
             * @return {Promise<Object[]>} ReportHistory models.
             */
            getReportHistory(reportId) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/${reportId}/history_view`,
                });
            },

            getReportTitles(reportIds) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/titles`,
                    data: reportIds,
                });
            },

            /**
             * Submit the report.
             * @param  {number} reportId
             * @return {Promise<true>}
             */
            submitReport(reportId) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${reportId}/submit`,
                    data: {},
                });
            },

            /**
             * Get all reports with the given reporting event id.
             * @param  {number}  reportingEventId
             * @param  {boolean} [options.hideLoadingBar=true]
             * @return {Promise<Object[]>}
             */
            getReportsInReportingEvent(reportingEventId, { hideLoadingBar = true } = {}) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/event/${reportingEventId}`,
                    hideLoadingBar,
                });
            },

            getReportRelatedEntities(reportId) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/${reportId}/related_entities`,
                });
            },

            addReportSubmissionAuthor(reportSubmissionAuthor) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/${reportSubmissionAuthor.submissionId}/authors`,
                    data: reportSubmissionAuthor,
                });
            },

            /**
             * Transition the report's approval status.
             * @param  {number} reportId
             * @return {Promise<Object[]>} ReportApprovalStatusResponse models.
             */
            transitionReportApprovalStatus(reportId, reportSubmission) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/${reportId}/submission`,
                    data: {
                        notifyIds: [],
                        ...reportSubmission,
                    },
                });
            },

            getReportApprovalStatusView(reportId) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/${reportId}/approval_status_view`,
                });
            },

            /**
             * Add a new inline comment to a given report
             * @param  {number} reportId
             * @param {string} comment
             * @param {string} narrativeHtml
             * @return {Promise<Object>} comment with id
             */
            addInlineComment(reportId, comment, narrativeHtml) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/${reportId}/comments/inline`,
                    data: {
                        comment,
                        narrativeHtml,
                    },
                });
            },
            /**
             * Resolve a given inline comment
             * @param  {number} commentId
             * @return {Promise<Object>} updated comment object and narrative_html
             */
            resolveInlineComment(commentId) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${commentId}/comments/inline/resolve`,
                });
            },
            /**
             * Resolve all outstanding inline comments for a reports
             * @param  {number} reportId
             * @return {Promise<Object>} updated comment object and narrative_html
             */
            resolveAllInlineComments(reportId) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${reportId}/comments/inline/resolve-all`,
                });
            },
        },
    });
}

/**
 * Resources for Reports. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import { Menu as ArcMenu, MenuItem, MenuTrigger, IconButton, MenuContent } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Link from '../../../core/components/links/Link';
import { useLineupContext } from '../state/ui';
import testIds from '../../../../core/testIds';
import LineupDisableExportTooltip from './LineupDisableExportTooltip';

const strings = componentStrings.cases.casePhotoLineups.CasePhotoLineups.LineupKebabMenu;

type LineupsKebabMenuProps = {
    isExported: boolean;
    canDelete: boolean;
    canExport: boolean;
    lineupId: number;
    title: string;
    name: string;
    caseId: number;
    openDeleteLineupModal: () => void;
};

const LineupKebabMenu: React.FC<LineupsKebabMenuProps> = ({
    isExported,
    canDelete,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canExport,
    title,
    name,
    lineupId,
    caseId,
    openDeleteLineupModal,
}) => {
    const { setSelectedLineup } = useLineupContext();

    return (
        <ArcMenu>
            <MenuTrigger asChild>
                <IconButton
                    aria-label="Actions"
                    icon="MoreActions"
                    variant="ghost"
                    onClick={() =>
                        setSelectedLineup({ title, name, lineupId, canExport, isExported })
                    }
                    data-test-id={testIds.PHOTO_LINEUP_KEBAB_MENU_BUTTON}
                />
            </MenuTrigger>
            <MenuContent>
                {!isExported && (
                    <Link
                        to={`/cases/${caseId}/lineups/${lineupId}/compose`}
                        testId={testIds.PHOTO_LINEUP_DETAIL_LINK}
                    >
                        <MenuItem data-test-id={testIds.PHOTO_LINEUP_KEBAB_MENU_EDIT}>
                            {strings.edit}
                        </MenuItem>
                    </Link>
                )}
                {canDelete && (
                    <MenuItem
                        onSelect={openDeleteLineupModal}
                        data-test-id={testIds.PHOTO_LINEUP_KEBAB_MENU_DELETE}
                    >
                        {strings.delete}
                    </MenuItem>
                )}
                {canExport ? (
                    <Link to={`/cases/${caseId}/lineups/${lineupId}/exports`}>
                        {/* If Min Slots is Reached - clickable and Min Slots is NOT Reached - disabled with tooltip */}
                        <MenuItem data-test-id={testIds.PHOTO_LINEUP_KEBAB_MENU_EXPORT}>
                            {strings.export}
                        </MenuItem>
                    </Link>
                ) : (
                    <LineupDisableExportTooltip>
                        <MenuItem
                            isDisabled={true}
                            data-test-id={testIds.PHOTO_LINEUP_KEBAB_MENU_EXPORT}
                        >
                            {strings.export}
                        </MenuItem>
                    </LineupDisableExportTooltip>
                )}
            </MenuContent>
        </ArcMenu>
    );
};

export default LineupKebabMenu;

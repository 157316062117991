import { map, filter, ObjectIterateeCustom } from 'lodash';
import { AbilityRoleLink } from '@mark43/rms-api';

import { createSelector } from 'reselect';
import { abilityRoleLinksSelector } from '../data';
import { miniUserViewModelsSelector } from '../../../mini-users/state/ui';
import { buildViewModel, ViewModel } from '../../../../../helpers/viewModelHelpers';

type AbilityRoleLinkViewModel = ViewModel<AbilityRoleLink, Record<string, never>>;

const abilityRoleLinkViewModelsSelector = createSelector(
    abilityRoleLinksSelector,
    miniUserViewModelsSelector,
    (abilityRoleLinks, miniUserViewModels) => {
        const viewModel = buildViewModel<AbilityRoleLink>({
            mappers: [
                ({ updatedBy }) => ({
                    updatedBy: miniUserViewModels[updatedBy],
                }),
            ],
        });

        return map(abilityRoleLinks, viewModel);
    }
);

export const abilityRoleLinkViewModelsWhereSelector = createSelector(
    abilityRoleLinkViewModelsSelector,
    (abilityRoleLinkViewModels) => (
        predicate: ObjectIterateeCustom<AbilityRoleLinkViewModel[], boolean>
    ) => filter(abilityRoleLinkViewModels, predicate)
);

import { LinkTypesEnum, RegionalGroupEnum } from '@mark43/rms-api';

const {
    CHILD_OF,
    CHILD_OF_BOYFRIEND_OF,
    CHILD_OF_GIRLFRIEND_OF,
    CHILD_OF_SIGNIFICANT_OTHER,
    COMMON_LAW_SPOUSE,
    EX_SIGNIFICANT_OTHER_OF,
    EX_SPOUSE_OF,
    GRANDCHILD_OF,
    HOMOSEXUAL_RELATIONSHIP,
    IN_LAW_OF,
    LIVES_WITH,
    RELATED_TO,
    SIBLING_OF,
    SIGNIFICANT_OTHER_OF,
    SPOUSE_OF,
    STEP_PARENT_OF,
    STEP_SIBLING_OF,
    EX_BOYFRIEND_GIRLFRIEND,
    CHILD_OF_EX_BOYFRIEND_GIRLFRIEND,
    BOYFRIEND_GIRLFRIEND,
} = LinkTypesEnum;

export const DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP = {
    /* AZ NIBRS Domestic Violence Relationships
        SE=Victim Was Spouse
        CS=Victim Was Common-Law Spouse
        PA=Victim Was Parent
        SB=Victim Was Sibling
        CH=Victim Was Child
        GP=Victim Was Grandparent
        GC=Victim Was Grandchild
        IL=Victim Was In-Law
        SP=Victim Was Step-parent
        SC=Victim Was Step-child
        SS=Victim Was Step-sibling
        OF=Victim Was Other Family Member
        BG=Victim Was Boyfriend/Girlfriend
        CF=Victim Was Child of Boyfriend/Girlfriend
        XR=Victim Was Ex-Relationship (Ex-boyfriend/ex-girlfriend)
        XS=Victim Was Ex-Spouse
        OK=Lives With
    */
    [RegionalGroupEnum.ARIZONA_NIBRS.name]: [
        CHILD_OF,
        CHILD_OF_BOYFRIEND_OF,
        CHILD_OF_GIRLFRIEND_OF,
        CHILD_OF_SIGNIFICANT_OTHER,
        COMMON_LAW_SPOUSE,
        EX_SIGNIFICANT_OTHER_OF,
        EX_SPOUSE_OF,
        GRANDCHILD_OF,
        IN_LAW_OF,
        LIVES_WITH,
        RELATED_TO,
        SIBLING_OF,
        SIGNIFICANT_OTHER_OF,
        SPOUSE_OF,
        STEP_PARENT_OF,
        STEP_SIBLING_OF,
    ],
    /*
        SE = Victim was Spouse
        CS = Victim was Common-Law Spouse
        PA = Victim was Parent
        SB = Victim was Sibling (brother or sister)
        CH = Victim was Child
        GP = Victim was Grandparent
        GC = Victim was Grandchild
        IL = Victim was In-Law
        SP = Victim was Stepparent
        SC = Victim was Stepchild
        SS = Victim was Stepsibling
        OF = Victim was Other Family Member
        BG = Victim was Boyfriend/Girlfriend
        CF = Victim was Child of Boyfriend/Girlfriend
        XR = Ex-Boyfriend/Girlfriend
        HR = Homosexual Relationship
        XS = Victim was Ex-Spouse
     */
    [RegionalGroupEnum.NEW_JERSEY_NIBRS.name]: [
        SPOUSE_OF,
        COMMON_LAW_SPOUSE,
        CHILD_OF,
        SIBLING_OF,
        GRANDCHILD_OF,
        IN_LAW_OF,
        STEP_PARENT_OF,
        STEP_SIBLING_OF,
        RELATED_TO,
        SIGNIFICANT_OTHER_OF,
        CHILD_OF_BOYFRIEND_OF,
        CHILD_OF_GIRLFRIEND_OF,
        CHILD_OF_SIGNIFICANT_OTHER,
        EX_SIGNIFICANT_OTHER_OF,
        HOMOSEXUAL_RELATIONSHIP,
        EX_SPOUSE_OF,
    ],
    [RegionalGroupEnum.TENNESSEE_NIBRS.name]: [
        SPOUSE_OF,
        CHILD_OF,
        GRANDCHILD_OF,
        STEP_PARENT_OF,
        STEP_SIBLING_OF,
        EX_SPOUSE_OF,
        CHILD_OF_BOYFRIEND_OF,
        CHILD_OF_GIRLFRIEND_OF,
        CHILD_OF_EX_BOYFRIEND_GIRLFRIEND,
        EX_BOYFRIEND_GIRLFRIEND,
        BOYFRIEND_GIRLFRIEND,
        COMMON_LAW_SPOUSE,
        SIBLING_OF,
        IN_LAW_OF,
        STEP_PARENT_OF,
        RELATED_TO,
        SIGNIFICANT_OTHER_OF,
    ],
} as const;

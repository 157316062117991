import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const cadMessagingGroupsAdminFormFieldViewModels = {
    cadMessagingGroup: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadMessagingGroup',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'id',
            },
            {
                key: 'groupName',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'groupDescription',
            },
        ]),
    },
};

function convertToFormModel({ cadMessagingGroup = {} }) {
    return {
        cadMessagingGroup: {
            ...cadMessagingGroup,
        },
    };
}

function convertFromFormModel({ cadMessagingGroup = {} }) {
    return { cadMessagingGroup };
}

/**
 * Module of the cad messaging group admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.CAD_MESSAGING_GROUPS_ADMIN,
    fieldViewModels: cadMessagingGroupsAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import React from 'react';
import {
    Tile as ArcTile,
    TileProps as ArcTileProps,
    IconNameT,
    Icon as ArcIcon,
    cssVar,
} from 'arc';
import { AnalyticsPropertyEnum } from '../../analytics/constants/analyticsEnum';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';

type TileProps = ArcTileProps & {
    testId?: string;
    icon?: IconNameT;
};

export const Tile = React.forwardRef<HTMLButtonElement, TileProps>(
    ({ onClick, testId, children, icon, media, ...props }, ref) => {
        const { filteredTrack } = useAnalytics();

        const handleClickWithTrack = React.useCallback(
            (e: React.MouseEvent<HTMLButtonElement>) => {
                filteredTrack(
                    {
                        [AnalyticsPropertyEnum.BUTTON]: testId,
                    },
                    testId
                );
                onClick?.(e);
            },
            [onClick, filteredTrack, testId]
        );
        return (
            <ArcTile
                {...props}
                ref={ref}
                media={
                    icon ? (
                        <ArcIcon icon={icon} size="md" color={cssVar('arc.colors.brand.default')} />
                    ) : (
                        media
                    )
                }
                data-test-id={testId}
                onClick={handleClickWithTrack}
            >
                {children}
            </ArcTile>
        );
    }
);

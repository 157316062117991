import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';
import { map } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Icon, { iconTypes } from '../core/Icon';
import { clearExports, removeFilesFromMyExports } from '../../actions/exportsActions';
import testIds from '../../../core/testIds';

const strings = componentStrings.exports.ExportsFile;

const TruncatedText = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    margin-top: 4px;
`;

const MergeFailedList = styled.div`
    margin-left: 15px;
`;

function ExportError({ message }) {
    return message ? (
        <>
            <TruncatedText>{strings.systemFailed}</TruncatedText>
            <TruncatedText>
                {strings.errorMessage} {message}
            </TruncatedText>
        </>
    ) : (
        strings.systemFailed
    );
}

function MergeFailedError({ mergeFailedFileNames }) {
    let key = 1;
    return (
        <>
            <TruncatedText>{strings.mergeFailedErrorMessage}</TruncatedText>
            <MergeFailedList>
                <ul>
                    {map(mergeFailedFileNames, (fileName) => (
                        <li key={key++}>{fileName}</li>
                    ))}
                </ul>
            </MergeFailedList>
        </>
    );
}

class ExportFile extends React.Component {
    render() {
        let loading = '';
        let expires = '';
        let mergeFailed = '';
        const expiredText = this.props.failedPrint ? (
            <ExportError message={this.props.message} requestId={this.props.requestId} />
        ) : (
            strings.expireTime
        );
        const isLoading = !this.props.file && !this.props.failedPrint;
        if (!this.props.file && !this.props.failedPrint) {
            loading = <div className="file-loading" />;
        } else {
            expires = <div className="file-expires">{expiredText}</div>;
        }

        if (this.props.file?.mergeFailedFileNames?.length > 0) {
            mergeFailed = (
                <div className="file-expires">
                    <MergeFailedError mergeFailedFileNames={this.props.file.mergeFailedFileNames} />
                </div>
            );
        }

        const fileNameClasses = classNames('file-name', {
            loading: isLoading,
        });

        const classes = classNames('exports-file', {
            loading: isLoading,
            error: this.props.failedPrint,
            expired: false,
        });

        const href = this.props.file ? this.props.file.fileWebServerPath : '';

        /* eslint-disable react/jsx-no-target-blank */
        const name =
            isLoading || this.props.failedPrint ? (
                <span className="name">{this.props.title}</span>
            ) : (
                <a className="name hoverable" target="_blank" href={href} download>
                    {this.props.title}
                </a>
            );
        /* eslint-enable react/jsx-no-target-blank */

        return (
            <div
                data-test-id={
                    isLoading ? testIds.EXPORTS_EXPORTS_FILE : testIds.EXPORTS_EXPORTS_FILE_LOADED
                }
                className={classes}
            >
                <div className={fileNameClasses}>
                    <Icon type={iconTypes.FILE} />
                    <TrashCan type={iconTypes.TRASH} onClick={this.props.removeFile} />
                    {name}
                </div>
                {mergeFailed}
                {expires}
                {loading}
            </div>
        );
    }
}

const TrashCan = styled(Icon)`
    cursor: pointer;

    &&::before {
        float: right;
    }
`;

const mapDispatchToProps = (dispatch, props) => {
    return {
        removeFile: () => {
            // if the work order id exists, we want to make a server call to clear the export
            // if not, we just want to locally clear it
            if (props.id) {
                dispatch(clearExports([props.id]));
            } else if (props.file) {
                dispatch(removeFilesFromMyExports([props.file.id]));
            }
        },
    };
};

export default connect(null, mapDispatchToProps)(ExportFile);

import filter from 'lodash/filter';
import { createSelector } from 'reselect';
import { EntityTypeEnumType, TaskView } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'taskViews';

const taskViewsModule = createNormalizedModule<TaskView>({
    type: NEXUS_STATE_PROP,
});

export const DELETE_TASK_SUCCESS = 'tasks/DELETE_TASK_SUCCESS';

const FETCH_TASKS_SUCCESS = 'tasks/FETCH_TASKS_SUCCESS';

export function deleteTaskSuccess(taskId: number) {
    return {
        type: DELETE_TASK_SUCCESS,
        payload: taskId,
    };
}

export function fetchTasksSuccess() {
    return {
        type: FETCH_TASKS_SUCCESS,
    };
}

export const taskViewsSelector = taskViewsModule.selectors.entitiesSelector;
export const taskViewsWhereSelector = taskViewsModule.selectors.entitiesWhereSelector;

export const taskViewsByOwnerSelector = createSelector(
    taskViewsSelector,
    (tasks) => (ownerType: EntityTypeEnumType, ownerId: number) =>
        filter(tasks, { ownerType, ownerId })
);

export default taskViewsModule.reducerConfig;

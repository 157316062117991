import { StorageLocationView } from '@mark43/evidence-api';
import { createSelector } from 'reselect';

import getStorageLocationResource from '../../resources/storageLocationResource';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { facilitiesSelector } from '../../../facilities/state/data';
import { convertStorageLocationToElastic } from '../../utils/storageLocationHelpers';
import { ClientCommonAction } from '../../../../../redux/types';

const LOAD_STORAGE_LOCATIONS_IN_FACILITY_START =
    'storage-locations/LOAD_STORAGE_LOCATIONS_IN_FACILITY_START';
const LOAD_STORAGE_LOCATIONS_IN_FACILITY_SUCCESS =
    'storage-locations/LOAD_STORAGE_LOCATIONS_IN_FACILITY_SUCCESS';
const LOAD_STORAGE_LOCATIONS_IN_FACILITY_FAILURE =
    'storage-locations/LOAD_STORAGE_LOCATIONS_IN_FACILITY_FAILURE';
const LOAD_STORAGE_LOCATION_CHILDREN_START =
    'storage-locations/LOAD_STORAGE_LOCATION_CHILDREN_START';
const LOAD_STORAGE_LOCATION_CHILDREN_SUCCESS =
    'storage-locations/LOAD_STORAGE_LOCATION_CHILDREN_SUCCESS';
const LOAD_STORAGE_LOCATION_CHILDREN_FAILURE =
    'storage-locations/LOAD_STORAGE_LOCATION_CHILDREN_FAILURE';
const LOAD_STORAGE_LOCATION_START = 'storage-locations/LOAD_STORAGE_LOCATION_START';
const LOAD_STORAGE_LOCATION_SUCCESS = 'storage-locations/LOAD_STORAGE_LOCATION_SUCCESS';
const LOAD_STORAGE_LOCATION_FAILURE = 'storage-locations/LOAD_STORAGE_LOCATION_FAILURE';
const SAVE_STORAGE_LOCATION_START = 'storage-locations/SAVE_STORAGE_LOCATION_START';
const SAVE_STORAGE_LOCATION_SUCCESS = 'storage-locations/SAVE_STORAGE_LOCATION_SUCCESS';
const SAVE_STORAGE_LOCATION_FAILURE = 'storage-locations/SAVE_STORAGE_LOCATION_FAILURE';
const EXPIRE_STORAGE_LOCATION_START = 'storage-locations/EXPIRE_STORAGE_LOCATION_START';
const EXPIRE_STORAGE_LOCATION_SUCCESS = 'storage-locations/EXPIRE_STORAGE_LOCATION_SUCCESS';
const EXPIRE_STORAGE_LOCATION_FAILURE = 'storage-locations/EXPIRE_STORAGE_LOCATION_FAILURE';
const MOVE_STORAGE_LOCATION_START = 'storage-locations/MOVE_STORAGE_LOCATION_START';
const MOVE_STORAGE_LOCATION_SUCCESS = 'storage-locations/MOVE_STORAGE_LOCATION_SUCCESS';
const MOVE_STORAGE_LOCATION_FAILURE = 'storage-locations/MOVE_STORAGE_LOCATION_FAILURE';

function loadStorageLocationsInFacilityStart() {
    return {
        type: LOAD_STORAGE_LOCATIONS_IN_FACILITY_START,
    };
}
function loadStorageLocationsInFacilitySuccess() {
    return {
        type: LOAD_STORAGE_LOCATIONS_IN_FACILITY_SUCCESS,
    };
}
function loadStorageLocationsInFacilityFailure(errorMessage: string) {
    return {
        type: LOAD_STORAGE_LOCATIONS_IN_FACILITY_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function loadStorageLocationChildrenStart() {
    return {
        type: LOAD_STORAGE_LOCATION_CHILDREN_START,
    };
}
function loadStorageLocationChildrenSuccess() {
    return {
        type: LOAD_STORAGE_LOCATION_CHILDREN_SUCCESS,
    };
}
function loadStorageLocationChildrenFailure(errorMessage: string) {
    return {
        type: LOAD_STORAGE_LOCATION_CHILDREN_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function saveStorageLocationStart() {
    return {
        type: SAVE_STORAGE_LOCATION_START,
    };
}
function saveStorageLocationSuccess(storageLocation: StorageLocationView) {
    return {
        type: SAVE_STORAGE_LOCATION_SUCCESS,
        payload: storageLocation,
    };
}
function saveStorageLocationFailure(errorMessage: string) {
    return {
        type: SAVE_STORAGE_LOCATION_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function expireStorageLocationStart() {
    return {
        type: EXPIRE_STORAGE_LOCATION_START,
    };
}
function expireStorageLocationSuccess() {
    return {
        type: EXPIRE_STORAGE_LOCATION_SUCCESS,
    };
}
function expireStorageLocationFailure(errorMessage: string) {
    return {
        type: EXPIRE_STORAGE_LOCATION_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function moveStorageLocationStart() {
    return {
        type: MOVE_STORAGE_LOCATION_START,
    };
}
function moveStorageLocationSuccess(storageLocation: StorageLocationView) {
    return {
        type: MOVE_STORAGE_LOCATION_SUCCESS,
        payload: storageLocation,
    };
}
function moveStorageLocationFailure(errorMessage: string) {
    return {
        type: MOVE_STORAGE_LOCATION_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function loadStorageLocationStart() {
    return {
        type: LOAD_STORAGE_LOCATION_START,
    };
}
function loadStorageLocationSuccess() {
    return {
        type: LOAD_STORAGE_LOCATION_SUCCESS,
    };
}
function loadStorageLocationFailure(errorMessage: string) {
    return {
        type: LOAD_STORAGE_LOCATION_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

/**
 * Load one storage location
 */
export function loadStorageLocation(
    storageLocationId: number
): ClientCommonAction<Promise<StorageLocationView>> {
    const storageLocationResource = getStorageLocationResource();

    return function (dispatch) {
        dispatch(loadStorageLocationStart());

        return storageLocationResource
            .getStorageLocation(storageLocationId)
            .then((storageLocation: StorageLocationView) => {
                dispatch(loadStorageLocationSuccess());
                dispatch(storeStorageLocations(storageLocation));
                return storageLocation;
            })
            .catch((err: Error) => {
                dispatch(loadStorageLocationFailure(err.message));
                throw err;
            });
    };
}

/**
 * Load all the first-level children of a storage location
 */
export function loadStorageLocationChildren(
    storageLocationId: number
): ClientCommonAction<Promise<StorageLocationView[]>> {
    const storageLocationResource = getStorageLocationResource();

    return function (dispatch) {
        dispatch(loadStorageLocationChildrenStart());

        return storageLocationResource
            .getStorageLocationChildren(storageLocationId)
            .then((storageLocations: StorageLocationView[]) => {
                dispatch(loadStorageLocationChildrenSuccess());
                dispatch(storeStorageLocations(storageLocations));
                return storageLocations;
            })
            .catch((err: Error) => {
                dispatch(loadStorageLocationChildrenFailure(err.message));
                throw err;
            });
    };
}

/**
 * Load the ids of all the children within a given storage location.
 */
export const loadAllStorageLocationChildrenIds = (
    storageLocationId: number,
    includeExpired = false,
    includeDeleted = false
): ClientCommonAction<Promise<number[]>> => (dispatch) => {
    const storageLocationResource = getStorageLocationResource();

    dispatch(loadStorageLocationChildrenStart());

    return storageLocationResource
        .getAllStorageLocationChildrenIds(storageLocationId, includeExpired, includeDeleted)
        .then((storageLocationIds: number[]) => {
            dispatch(loadStorageLocationChildrenSuccess());
            return storageLocationIds;
        })
        .catch((err: Error) => {
            dispatch(loadStorageLocationChildrenFailure(err.message));
            throw err;
        });
};

/**
 * Load all the top level storage locations within the given facility.
 */
export function loadStorageLocationsInFacility(
    facilityId: number
): ClientCommonAction<Promise<StorageLocationView>> {
    const storageLocationResource = getStorageLocationResource();

    return function (dispatch) {
        dispatch(loadStorageLocationsInFacilityStart());

        return storageLocationResource
            .getStorageLocationsInFacility(facilityId)
            .then((storageLocations: StorageLocationView) => {
                dispatch(loadStorageLocationsInFacilitySuccess());
                dispatch(storeStorageLocations(storageLocations));
                return storageLocations;
            })
            .catch((err: Error) => {
                dispatch(loadStorageLocationsInFacilityFailure(err.message));
                throw err;
            });
    };
}

/**
 * Load the ids of all the storage locations within the given facility.
 */
export const loadAllStorageLocationsIdsInFacility = (
    facilityId: number,
    includeExpired = false,
    includeDeleted = false
): ClientCommonAction<Promise<number[]>> => (dispatch) => {
    const storageLocationResource = getStorageLocationResource();

    dispatch(loadStorageLocationChildrenStart());

    return storageLocationResource
        .getAllStorageLocationsInFacility(facilityId, includeExpired, includeDeleted)
        .then((storageLocationIds: number[]) => {
            dispatch(loadStorageLocationChildrenSuccess());
            return storageLocationIds;
        })
        .catch((err: Error) => {
            dispatch(loadStorageLocationChildrenFailure(err.message));
            throw err;
        });
};

/**
 * Create or update a storage location.
 */
export function saveStorageLocation(
    isNew: boolean,
    storageLocation: StorageLocationView
): ClientCommonAction<Promise<StorageLocationView>> {
    const storageLocationResource = getStorageLocationResource();

    return function (dispatch) {
        dispatch(saveStorageLocationStart());

        const promise = isNew
            ? storageLocationResource.createStorageLocation(storageLocation)
            : storageLocationResource.updateStorageLocation(storageLocation);

        return promise
            .then((storageLocation: StorageLocationView) => {
                dispatch(saveStorageLocationSuccess(storageLocation));
                dispatch(
                    replaceStorageLocationsWhere({ id: storageLocation.id }, [storageLocation])
                );
                return storageLocation;
            })
            .catch((err: Error) => {
                dispatch(saveStorageLocationFailure(err.message));
                throw err;
            });
    };
}

/**
 * "Delete" a storage location by expiring it and all its children.
 */
export function expireStorageLocation(
    storageLocationId: number
): ClientCommonAction<Promise<StorageLocationView[]>> {
    const storageLocationResource = getStorageLocationResource();

    return function (dispatch) {
        dispatch(expireStorageLocationStart());
        const promise = storageLocationResource.deleteStorageLocation(storageLocationId);

        return (
            promise
                // delay for a better chance of the new expiredDateUtc(s) being
                // before the user's local time; otherwise the UI doesn't show the
                // storage location(s) as expired
                .delay(1000)
                .then((storageLocations: StorageLocationView[]) => {
                    dispatch(expireStorageLocationSuccess());
                    dispatch(storeStorageLocations(storageLocations));
                    return storageLocations;
                })
                .catch((err: Error) => {
                    dispatch(expireStorageLocationFailure(err.message));
                    throw err;
                })
        );
    };
}

export function moveStorageLocation(
    sourceLocationId: number,
    destinationLocationId: number
): ClientCommonAction<Promise<StorageLocationView>> {
    const storageLocationResource = getStorageLocationResource();

    return (dispatch) => {
        dispatch(moveStorageLocationStart());
        return storageLocationResource
            .moveStorageLocation(sourceLocationId, destinationLocationId)
            .then((storageLocation: StorageLocationView) => {
                dispatch(moveStorageLocationSuccess(storageLocation));
                dispatch(storeStorageLocations(storageLocation));
                return storageLocation;
            })
            .catch((err: Error) => {
                dispatch(moveStorageLocationFailure(err.message));
                throw err;
            });
    };
}

export const NEXUS_STATE_PROP = 'storageLocations';

const storageLocationsModule = createNormalizedModule<StorageLocationView>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeStorageLocations = storageLocationsModule.actionCreators.storeEntities;
const replaceStorageLocationsWhere = storageLocationsModule.actionCreators.replaceEntitiesWhere;

// SELECTORS
export const storageLocationsSelector = storageLocationsModule.selectors.entitiesSelector;

/**
 * Generate an ad hoc elastic storage data model from a regular
 * storage location.
 */
export const convertStorageLocationToElasticSelector = createSelector(
    storageLocationsSelector,
    facilitiesSelector,
    (storageLocations, facilities) => (storageLocationId: number) =>
        convertStorageLocationToElastic(storageLocations, facilities, storageLocationId)
);

// REDUCER
export default storageLocationsModule.reducerConfig;

import React from 'react';
import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { groupBy } from "lodash";
import { offenseViewModelByIdSelector } from '~/client-common/core/domain/offenses/state/ui';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatAttributeLinkViewModels } from "~/client-common/core/domain/attributes/state/ui";
import { offenseAttributeViewModelsWhereSelector } from "~/client-common/core/domain/offense-attributes/state/ui";
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import { LocationSummaryViewWrapper } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapper';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import IndentedFields from '../../../../core/components/IndentedFields';

type LocationSummaryProps = {
    summaryMode: boolean;
    entityId: number;
    entityType: keyof typeof EntityTypeEnum.OFFENSE;
    linkType: keyof typeof LinkTypesEnum;
    onLocationAdd: () => void;
    onLocationRemove: () => void;
};

const schoolWeaponInvolvedFieldName = fields.OFFENSE_SCHOOL_WEAPON_INVOLVED;
const offenseLocationSceneOfCrimeFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OFFENSE_SCENE_OF_CRIME_LOCATION_ATTRIBUTE_ID;

export const OffenseLocationSummary: React.FC<LocationSummaryProps> = ({
    summaryMode,
    entityId: offenseId,
    entityType,
    linkType,
    onLocationAdd,
    onLocationRemove,
}) => {
    const offenseViewModelById = useSelector(offenseViewModelByIdSelector);
    const offenseAttributeViewModelsWhere = useSelector(offenseAttributeViewModelsWhereSelector);

    if (!offenseId) {
        return null;
    }

    const offenseViewModel = offenseViewModelById(offenseId);
    const offenseAttributeViewModelsByAttrType = groupBy(
        offenseAttributeViewModelsWhere({ offenseId }),
        'attributeType'
    );
    const {
        secondaryLocationCategoryAttrId,
    } = getViewModelProperties(offenseViewModel);

    return (
        <>
            <LocationSummaryViewWrapper
                summaryMode={summaryMode}
                entityId={offenseId}
                entityType={entityType}
                linkType={linkType}
                onLocationAdd={onLocationAdd}
                onLocationRemove={onLocationRemove}
            />
            <IndentedFields>
                <SummaryList labelWidth={160} contentWidth={200}>
                    <SummaryRow fieldName={schoolWeaponInvolvedFieldName}>
                        {booleanToYesNo(offenseViewModel.schoolWeaponInvolved)}
                    </SummaryRow>
                    <SummaryRow fieldName={offenseLocationSceneOfCrimeFieldName}>
                        {formatAttributeLinkViewModels(
                            offenseAttributeViewModelsByAttrType[AttributeTypeEnum.OFFENSE_SCENE_OF_CRIME_LOCATION.name]
                        )}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.OFFENSE_SECONDARY_LOCATION_CATEGORY_ATTR_ID}>
                        {secondaryLocationCategoryAttrId}
                    </SummaryRow>
                </SummaryList>
            </IndentedFields>
        </>
    );
};

import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';

/**
 * Default search query params on the evidence dashboard.
 * @type {Object}
 */
export const queryParamDefaults = {
    FROM: 0,
    SIZE: 25,
    SORT_KEY: sortKeyEnum.ELASTICSEARCH_SCORE,
    SORT_TYPE: sortTypeEnum.RELEVANCE_MOST_TO_LEAST,
};

import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { NibrsSegmentsErrorDescription } from '@mark43/rms-api';

import overlayStateTypeEnum from '~/client-common/core/enums/client/overlayStateTypeEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { DISPLAY_ONLY_NIBRS_ADMIN_NOT_REPORTABLE_SUBTITLE } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import { OverlayBaseHelper, OverlayRenderProps } from '../../components/OverlayBaseHelper';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import { CardSection } from '../../../../legacy-redux/components/core/Card';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import { SecondarySectionHeader } from '../../components/typography';
import NoDataBlock from '../../components/NoDataBlock';
import { NibrsErrorState } from '../constants';
import { NibrsErrorDisplay } from './NibrsErrorDisplay';

const strings = componentStrings.core.GeneratedSegments.GeneratedSegmentsSidePanel;

interface GeneratedSegmentsSidePanelProps {
    disableRunErrorCheck: boolean;
    handleRunErrorCheckClick: () => void;
    fatalErrors?: NibrsSegmentsErrorDescription[];
    nibrsErrors?: NibrsSegmentsErrorDescription[];
    nibrsErrorState: NibrsErrorState;
    notGeneratedReasons?: string[];
    saveRef?: React.Ref<HTMLElement>;
    renderButton: (
        props: OverlayRenderProps<GeneratedSegmentsOverlayCustomProperties>
    ) => React.ReactNode;
}

type GeneratedSegmentsOverlayCustomProperties = {
    cancelFocusRef?: HTMLElement;
};
interface NibrsErrorsProps {
    hasFatalErrors?: boolean;
    errors?: NibrsSegmentsErrorDescription[];
}

const ErrorsSectionWrapperStyle = styled(CardSection)`
    padding: 6px 5px 32px 5px;
`;

const ErrorsSectionWrapper = ({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode;
}) => (
    <ErrorsSectionWrapperStyle>
        <SecondarySectionHeader>{title}</SecondarySectionHeader>
        {children}
    </ErrorsSectionWrapperStyle>
);

const ErrorsMetaMessageWrapper = styled.div`
    display: flex;
    justify-content: center;
    max-width: 66%;
    margin: 24px auto;
`;

const ErrorsMetaMessage = ({ details }: { details: React.ReactNode }) => (
    <ErrorsMetaMessageWrapper>
        <NoDataBlock>{details}</NoDataBlock>
    </ErrorsMetaMessageWrapper>
);

const NibrsErrorDisplayWrapper = styled.div`
    padding-top: 16px;
`;

const NibrsErrors = ({ errors, hasFatalErrors }: NibrsErrorsProps) => {
    const hasErrors = !isUndefinedOrNull(errors) && !isEmpty(errors);

    return (
        <ErrorsSectionWrapper title={strings.titleNibrsErrors}>
            {!hasErrors && (
                <ErrorsMetaMessage
                    details={hasFatalErrors ? strings.fatalErrors : strings.noNibrsErrors}
                />
            )}
            {hasErrors && (
                <NibrsErrorDisplayWrapper>
                    {errors?.map(
                        (
                            {
                                nibrsErrorMessage,
                                rmsSuggestionMessage,
                            }: NibrsSegmentsErrorDescription,
                            i
                        ) => (
                            <NibrsErrorDisplay
                                key={i}
                                nibrsErrorMessage={nibrsErrorMessage}
                                rmsSuggestionMessage={rmsSuggestionMessage}
                            />
                        )
                    )}
                </NibrsErrorDisplayWrapper>
            )}
        </ErrorsSectionWrapper>
    );
};

const FatalErrors = ({ errors }: { errors?: NibrsSegmentsErrorDescription[] }) => {
    const hasErrors = !isUndefinedOrNull(errors) && !isEmpty(errors);
    return (
        <ErrorsSectionWrapper title={strings.titleFatalErrors}>
            {!hasErrors && <ErrorsMetaMessage details={strings.noFatalErrors} />}
            {hasErrors && (
                <NibrsErrorDisplayWrapper>
                    {errors?.map(
                        (
                            {
                                nibrsErrorMessage,
                                rmsSuggestionMessage,
                            }: NibrsSegmentsErrorDescription,
                            i
                        ) => (
                            <NibrsErrorDisplay
                                key={i}
                                nibrsErrorMessage={nibrsErrorMessage}
                                rmsSuggestionMessage={rmsSuggestionMessage}
                                isFatal={true}
                            />
                        )
                    )}
                </NibrsErrorDisplayWrapper>
            )}
        </ErrorsSectionWrapper>
    );
};

const NotReportableList = styled.ul`
    list-style-type: none;
    li:not(:last-child) {
        margin-bottom: 12px;
    }
`;
const NotReportableTitle = styled.div`
    margin-bottom: 12px;
`;

const NotGeneratedReasons = ({ notGeneratedReasons }: { notGeneratedReasons?: string[] }) => {
    const nibrsAdminNotReportableSubtitle = useFields(
        DISPLAY_ONLY_NIBRS_ADMIN_NOT_REPORTABLE_SUBTITLE
    )[DISPLAY_ONLY_NIBRS_ADMIN_NOT_REPORTABLE_SUBTITLE];
    return (
        <>
            {notGeneratedReasons?.length && (
                <ErrorsMetaMessage
                    details={
                        <NotReportableList>
                            <NotReportableTitle>
                                {nibrsAdminNotReportableSubtitle}
                            </NotReportableTitle>
                            {notGeneratedReasons.map((reason, index) => (
                                <li key={index}>{reason}</li>
                            ))}
                        </NotReportableList>
                    }
                />
            )}
        </>
    );
};

const ErrorsSection = ({
    nibrsErrorState,
    nibrsErrors,
    fatalErrors,
    notGeneratedReasons,
}: {
    nibrsErrorState: NibrsErrorState;
    fatalErrors?: NibrsSegmentsErrorDescription[];
    nibrsErrors?: NibrsSegmentsErrorDescription[];
    notGeneratedReasons?: string[];
}) => {
    switch (nibrsErrorState) {
        case NibrsErrorState.Running:
            return <SimpleLoading />;
        case NibrsErrorState.NotReportable:
            return <NotGeneratedReasons notGeneratedReasons={notGeneratedReasons} />;
        case NibrsErrorState.NotGenerated:
            return <ErrorsMetaMessage details={strings.runErrorCheck} />;
        case NibrsErrorState.Failed:
            return <ErrorsMetaMessage details={strings.failed} />;
        default:
            return (
                <>
                    <NibrsErrors errors={nibrsErrors} hasFatalErrors={!isEmpty(fatalErrors)} />
                    <FatalErrors errors={fatalErrors} />
                </>
            );
    }
};

export const GeneratedSegmentsSidePanel: React.FC<GeneratedSegmentsSidePanelProps> = ({
    disableRunErrorCheck,
    handleRunErrorCheckClick,
    fatalErrors,
    nibrsErrors,
    nibrsErrorState,
    notGeneratedReasons,
    renderButton,
    saveRef,
    ...props
}) => (
    <OverlayBaseHelper<GeneratedSegmentsOverlayCustomProperties>
        id={overlayIdEnum.GENERATED_SEGMENTS_ERRORS_WRAPPER}
        overlayStateType={overlayStateTypeEnum.GENERATED_SEGMENTS_ERRORS_OVERLAY}
        renderButton={renderButton}
        saveRef={saveRef}
        getInitialCustomPropertyState={() => {
            return {};
        }}
    >
        {(renderProps) => {
            const cancelFocusRef =
                renderProps.overlayBase.overlayState.customProperties.cancelFocusRef;
            const { setCancelFocusRef } = renderProps;
            if (cancelFocusRef) {
                setCancelFocusRef(cancelFocusRef);
            }
            return (
                <PortalSidePanel
                    {...props}
                    {...renderProps}
                    title={strings.title}
                    isAtBottomOfStack={renderProps?.overlayBase?.isAtBottomOfStack}
                    savePanel={handleRunErrorCheckClick}
                    saveButtonVariant={'outline'}
                    saveText={strings.ButtonRunErrorCheck}
                    cancelButtonVariant={'outline'}
                    cancelText={strings.ButtonClose}
                    saveDisabled={disableRunErrorCheck}
                >
                    <ErrorsSection
                        nibrsErrorState={nibrsErrorState}
                        nibrsErrors={nibrsErrors}
                        fatalErrors={fatalErrors}
                        notGeneratedReasons={notGeneratedReasons}
                    />
                </PortalSidePanel>
            );
        }}
    </OverlayBaseHelper>
);

import {
    SqlSearchQueryAtfManufacturerQuery,
    SqlSearchQueryAtfWeaponModelQuery,
    SqlSearchQueryAtfWeaponCaliberQuery,
    AtfResource,
} from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Atf Manufacturer Resource',
    methods: {
        searchForAtfManufacturers(data: SqlSearchQueryAtfManufacturerQuery) {
            return req<AtfResource.GetAtfManufactures>({
                method: 'POST',
                baseUrl: '/rms/api',
                url: 'atf/manufacturers/search',
                data,
            });
        },
        searchForAtfModels(data: SqlSearchQueryAtfWeaponModelQuery) {
            return req<AtfResource.GetAtfWeaponModels>({
                method: 'POST',
                baseUrl: '/rms/api',
                url: 'atf/weapons/models',
                data,
            });
        },
        searchForAtfCalibers(data: SqlSearchQueryAtfWeaponCaliberQuery) {
            return req<AtfResource.GetAtfWeaponCalibers>({
                method: 'POST',
                baseUrl: '/rms/api',
                url: 'atf/weapons/calibers',
                data,
            });
        },
    },
});

import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { _Form } from 'markformythree';
import { AttributeTypeEnum } from '@mark43/rms-api';

import * as fields from '~/client-common/core/enums/universal/fields';
import { crashDetailsWhereSelector } from '~/client-common/core/domain/crash-details/state/data';
import { reportAttributeViewModelsWhereSelector } from '~/client-common/core/domain/report-attributes/state/ui';
import { formatAttributeLinkViewModels } from '~/client-common/core/domain/attributes/state/ui';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';

import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import { CUSTOM_PROPERTIES_KEY_NAME } from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';
import { FormConfigurationRenderer } from '../../../../core/markformythree-arbiter/dynamic-fields/components/FormConfigurationRenderer';
import { CRASH_EVENT_INFO_REPORT_ATTRIBUTE_TYPES } from '../../state/ui/crashEventInfo';
import { CrashEventInfoFormConfiguration } from '../../state/forms/crashEventInfoForm';

const CrashEventInfoSummary = ({ form }: { form: _Form<CrashEventInfoFormConfiguration> }) => {
    const reportId = useSelector(currentReportIdSelector);
    const crashDetailsWhere = useSelector(crashDetailsWhereSelector);
    const reportAttributeViewModelsWhere = useSelector(reportAttributeViewModelsWhereSelector);
    if (!reportId) {
        return null;
    }
    const crashDetail = crashDetailsWhere({ reportId })[0];
    if (!crashDetail) {
        return null;
    }

    const formattedReportAttributes = CRASH_EVENT_INFO_REPORT_ATTRIBUTE_TYPES.reduce<
        Partial<{
            [key in (typeof CRASH_EVENT_INFO_REPORT_ATTRIBUTE_TYPES)[number]]: string;
        }>
    >((acc, attributeType) => {
        const attributeLinkViewModels = reportAttributeViewModelsWhere({ reportId, attributeType });
        const displayValue = formatAttributeLinkViewModels(attributeLinkViewModels);
        acc[attributeType] = displayValue;
        return acc;
    }, {});

    return (
        <SummaryList>
            <CardSection>
                <SummaryRowDate
                    fieldName={fields.CRASH_DETAIL_CRASH_EVENT_DATE_UTC}
                    date={get(crashDetail, 'crashEventDateUtc')}
                />
                <SummaryRow fieldName={fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEATHER_ATTRIBUTE_ID}>
                    {formattedReportAttributes[AttributeTypeEnum.WEATHER.name]}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_ROADWAY_SURFACE_CONDITION_ATTRIBUTE_ID
                    }
                >
                    {formattedReportAttributes[AttributeTypeEnum.QC_ROADWAY_SURFACE_CONDITION.name]}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_LIGHT_CONDITION_ATTRIBUTE_ID
                    }
                >
                    {formattedReportAttributes[AttributeTypeEnum.QC_LIGHT_CONDITION.name]}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_CRASH_SEVERITY_ATTRIBUTE_ID
                    }
                >
                    {formattedReportAttributes[AttributeTypeEnum.QC_CRASH_SEVERITY.name]}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_FIXED_OBJECT_TYPE_ATTRIBUTE_ID
                    }
                >
                    {formattedReportAttributes[AttributeTypeEnum.CRASH_FIXED_OBJECT_TYPE.name]}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_FIXED_OBJECT_TYPE_ATTRIBUTE_ID
                    }
                >
                    {formattedReportAttributes[AttributeTypeEnum.CRASH_FIXED_OBJECT_TYPE.name]}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_FIRST_HARMFUL_EVENT_ATTRIBUTE_ID
                    }
                >
                    {formattedReportAttributes[AttributeTypeEnum.QC_FIRST_HARMFUL_EVENT.name]}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_CONTRIBUTING_FACTOR_ATTRIBUTE_ID
                    }
                >
                    {formattedReportAttributes[AttributeTypeEnum.CRASH_CONTRIBUTING_FACTOR.name]}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_MUNICIPALITY_CODE_ATTRIBUTE_ID
                    }
                >
                    {formattedReportAttributes[AttributeTypeEnum.CRASH_MUNICIPALITY_CODE.name]}
                </SummaryRow>
                <SummaryRow fieldName={fields.CRASH_DETAIL_IS_TEMPORARY_TRAFFIC_CONTROL}>
                    {booleanToYesNo(crashDetail.isTemporaryTrafficControl)}
                </SummaryRow>
                <FormConfigurationRenderer
                    form={form}
                    absoluteFormPath={CUSTOM_PROPERTIES_KEY_NAME}
                    mode="SUMMARY"
                />
            </CardSection>
        </SummaryList>
    );
};

export default CrashEventInfoSummary;

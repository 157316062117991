import { useSelector } from 'react-redux';
import { ElasticPersonQuery } from '@mark43/rms-api';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../../search/core/state/ui';
import { convertFromFormModel } from '../forms/photoLineupFilterForm';

export default function useGetElasticPersonQueryForPhotoLineup(
    defaultQuery?: ElasticPersonQuery
): () => ElasticPersonQuery | undefined {
    const { getForm } = useFormGetter();
    const transformElasticQueryIdsToDisplayValues = useSelector(
        transformElasticQueryIdsToDisplayValuesSelector
    );

    return function () {
        const form = getForm(formClientEnum.PHOTO_LINEUP_FILTER_FORM);

        if (!form) {
            return defaultQuery;
        }

        const formModel = form.get();
        const elasticQuery = convertFromFormModel(formModel);
        return transformElasticQueryIdsToDisplayValues(elasticQuery);
    };
}

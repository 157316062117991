import { NEXUS_STATE_PROP as CAD_SERVICE_ROTATION_TYPES_NEXUS_STATE_PROP } from '~/client-common/core/domain/cad-service-rotation-types/state/data';
import { NEXUS_STATE_PROP as CAD_SERVICE_ROTATION_LISTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/cad-service-rotation-lists/state/data';
import { NEXUS_STATE_PROP as CAD_SERVICE_ROTATION_PROVIDERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/cad-service-rotation-providers/state/data';
import cadRotationListsResource from '../../resources/cadRotationListsResource';
import { getCorrectedResponseRotationList } from './utils';

export const LOAD_CAD_ROTATION_LISTS_START =
    'cad-service-rotation-lists/LOAD_CAD_ROTATION_LISTS_START';
export const LOAD_CAD_SERVICE_TYPES_START =
    'cad-service-rotation-lists/LOAD_CAD_SERVICE_TYPES_START';
export const LOAD_CAD_ROTATION_LISTS_SUCCESS =
    'cad-service-rotation-lists/LOAD_CAD_ROTATION_LISTS_SUCCESS';

export const LOAD_CAD_SERVICE_PROVIDERSS_SUCCESS =
    'cad-service-rotation-lists/LOAD_CAD_SERVICE_PROVIDERSS_SUCCESS';

export const LOAD_CAD_SERVICE_TYPES_SUCCESS =
    'cad-service-rotation-lists/LOAD_CAD_SERVICE_TYPES_SUCCESS';
export const LOAD_CAD_ROTATION_LISTS_FAILURE =
    'cad-service-rotation-lists/LOAD_CAD_ROTATION_LISTS_FAILURE';
const SAVE_CAD_ROTATION_LISTS_START = 'cad-service-rotation-lists/SAVE_CAD_ROTATION_LISTS_START';
export const SAVE_CAD_ROTATION_LISTS_SUCCESS =
    'cad-service-rotation-lists/SAVE_CAD_ROTATION_LISTS_SUCCESS';
export const SAVE_CAD_ROTATION_LISTS_FAILURE =
    'cad-service-rotation-lists/SAVE_CAD_ROTATION_LISTS_FAILURE';

const SAVE_CAD_ROTATION_LIST_ENTRY_START =
    'cad-service-rotation-lists/SAVE_CAD_ROTATION_LIST_ENTRY_START';
export const SAVE_CAD_ROTATION_LIST_ENTRY_SUCCESS =
    'cad-service-rotation-lists/SAVE_CAD_ROTATION_LIST_ENTRY_SUCCESS';
export const LOAD_CAD_ROTATION_LIST_HISTORY_START =
    'cad-service-rotation-lists/LOAD_CAD_ROTATION_LIST_HISTORY_START';
export const LOAD_CAD_ROTATION_LIST_HISTORY_SUCCESS =
    'cad-service-rotation-lists/LOAD_CAD_ROTATION_LIST_HISTORY_SUCCESS';
const LOAD_CAD_ROTATION_LIST_HISTORY_FAILURE =
    'cad-service-rotation-lists/LOAD_CAD_ROTATION_LIST_HISTORY_FAILURE';
export const SET_INITIAL_STATE = 'cad-service-rotation-lists/SET_INITIAL_STATE';

function loadCadRotationListsStart() {
    return {
        type: LOAD_CAD_ROTATION_LISTS_START,
    };
}

function loadCadServiceTypesStart() {
    return {
        type: LOAD_CAD_SERVICE_TYPES_START,
    };
}

function loadCadRotationListsSuccess(rotationLists) {
    return {
        type: LOAD_CAD_ROTATION_LISTS_SUCCESS,
        payload: rotationLists,
    };
}

function loadCadServiceTypesSuccess(cadServiceTypes) {
    return {
        type: LOAD_CAD_SERVICE_TYPES_SUCCESS,
        payload: cadServiceTypes,
    };
}

function loadCadServiceProvidersSuccess(cadServiceProviders) {
    return {
        type: LOAD_CAD_SERVICE_PROVIDERSS_SUCCESS,
        payload: cadServiceProviders,
    };
}

function loadCadRotationListsFailure(err) {
    return {
        type: LOAD_CAD_ROTATION_LISTS_FAILURE,
        error: true,
        payload: err,
    };
}

function loadCadRotationListHistoryStart() {
    return {
        type: LOAD_CAD_ROTATION_LIST_HISTORY_START,
    };
}

function loadCadRotationListHistorySuccess(history) {
    return {
        type: LOAD_CAD_ROTATION_LIST_HISTORY_SUCCESS,
        payload: history,
    };
}

function loadCadRotationListHistoryFailure(err = {}) {
    return {
        type: LOAD_CAD_ROTATION_LIST_HISTORY_FAILURE,
        payload: err.message,
    };
}

function loadCadServiceProviders() {
    return function (dispatch, getState, dependencies) {
        return cadRotationListsResource
            .getServiceProviders()
            .then((cadServiceProviders) =>
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [CAD_SERVICE_ROTATION_PROVIDERS_NEXUS_STATE_PROP]: cadServiceProviders,
                        },
                        loadCadServiceProvidersSuccess(cadServiceProviders)
                    )
                )
            )
            .catch((err) => dispatch(loadCadRotationListsFailure(err.message)));
    };
}

export function loadCadServiceTypes() {
    return function (dispatch, getState, dependencies) {
        dispatch(loadCadServiceProviders());
        dispatch(loadCadServiceTypesStart());
        return cadRotationListsResource
            .getCadServiceTypes()
            .then((cadServiceTypes) =>
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [CAD_SERVICE_ROTATION_TYPES_NEXUS_STATE_PROP]: cadServiceTypes,
                        },
                        loadCadServiceTypesSuccess(cadServiceTypes)
                    )
                )
            )
            .catch((err) => dispatch(loadCadRotationListsFailure(err.message)));
    };
}

function setInitialState() {
    return {
        type: SET_INITIAL_STATE,
    };
}

export function loadCadRotationsLists(initial) {
    return function (dispatch, getState, dependencies) {
        if (!initial) {
            dispatch(setInitialState());
        }
        dispatch(loadCadRotationListsStart());

        return cadRotationListsResource
            .getCadRotationsList()
            .then((rotationsList) =>
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [CAD_SERVICE_ROTATION_LISTS_NEXUS_STATE_PROP]: rotationsList,
                        },
                        loadCadRotationListsSuccess(rotationsList)
                    )
                )
            )
            .catch((err) => dispatch(loadCadRotationListsFailure(err.message)));
    };
}

function saveCadRotationListsStart() {
    return {
        type: SAVE_CAD_ROTATION_LISTS_START,
    };
}

function saveCadRotationListsSuccess(rotationList) {
    return {
        type: SAVE_CAD_ROTATION_LISTS_SUCCESS,
        payload: rotationList,
    };
}

function saveCadRotationListsFailure(errMsg) {
    return {
        type: SAVE_CAD_ROTATION_LISTS_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function saveCadRotationListEntryStart() {
    return {
        type: SAVE_CAD_ROTATION_LIST_ENTRY_START,
    };
}

function saveCadRotationListEntrySuccess(rotationList, rotationListEntry) {
    return {
        type: SAVE_CAD_ROTATION_LIST_ENTRY_SUCCESS,
        payload: { rotationList, rotationListEntry },
    };
}

export function saveCadRotationList(cadRotationList, isEnhancedServiceRotationEnabled) {
    return function (dispatch, getState, dependencies) {
        dispatch(saveCadRotationListsStart());

        const method = 'createCadRotationsList';

        return cadRotationListsResource[method](cadRotationList)
            .then((responseRotationList) => {
                const correctedResponseRotationList = isEnhancedServiceRotationEnabled
                    ? getCorrectedResponseRotationList(cadRotationList, responseRotationList)
                    : responseRotationList;
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [CAD_SERVICE_ROTATION_LISTS_NEXUS_STATE_PROP]: [
                                correctedResponseRotationList,
                            ],
                        },
                        saveCadRotationListsSuccess(correctedResponseRotationList)
                    )
                );
            })
            .catch((err) => dispatch(saveCadRotationListsFailure(err.message)));
    };
}

export function saveCadRotationListEntry(cadRotationListEntry, selectedCadRotationList) {
    return function (dispatch) {
        dispatch(saveCadRotationListEntryStart());

        const updatedSelectedRotationList = {
            ...selectedCadRotationList,
            entries: [
                ...selectedCadRotationList.entries.filter(
                    (item) => item.id !== cadRotationListEntry.id
                ),
                cadRotationListEntry,
            ],
        };

        return dispatch(
            saveCadRotationListEntrySuccess(updatedSelectedRotationList, cadRotationListEntry)
        );
    };
}

export function loadCadRotationListHistory(cadRotationListId) {
    return function (dispatch) {
        dispatch(loadCadRotationListHistoryStart());
        return cadRotationListsResource
            .loadCadRotationListHistory(cadRotationListId)
            .then((history) => {
                dispatch(loadCadRotationListHistorySuccess(history));
            })
            .catch(loadCadRotationListHistoryFailure);
    };
}

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { noop, get } from 'lodash';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { warrantsSelector } from '~/client-common/core/domain/warrants/state/data';
import FormExports from '../../../../legacy-redux/components/exports/FormExports';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import { ExportPageWithColumns } from '../../../../legacy-redux/components/exports/LayoutComponents';
import { exportPageReleaseTrackingConfigSelector } from '../../../../legacy-redux/selectors/exportsSelectors';
import { ReleaseTracking } from '../../../core/pdf-exports/release-tracking/components/ReleaseTracking';
import WarrantHeader from './WarrantHeader';

const strings = componentStrings.exports.CombinedExports;

function WarrantExports({ params, warrants, exportPageReleaseTrackingConfig }) {
    const warrantNumber = get(warrants[params.warrantId], 'warrantNumber');
    const { showReleaseTracking } = exportPageReleaseTrackingConfig(EntityTypeEnum.WARRANT.name);
    return (
        <>
            <WarrantHeader warrantId={params.warrantId} />
            <ScrollableUnderSubheader>
                <ExportPageWithColumns
                    leftColumn={
                        <FormExports
                            closeCallback={noop}
                            useExportRouteToggle={true}
                            includeAttachments={true}
                            showFieldRedaction={true}
                            trackReleases={true}
                            defaultGroupTitle={`Warrant #${warrantNumber}`}
                            showIncludeWarrantActivities={true}
                            defaultFilename={strings.title}
                        />
                    }
                    rightColumn={
                        showReleaseTracking ? (
                            <ReleaseTracking
                                entityType={EntityTypeEnum.WARRANT.name}
                                entityId={parseInt(params.warrantId, 10)}
                            />
                        ) : undefined
                    }
                />
            </ScrollableUnderSubheader>
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    warrants: warrantsSelector,
    exportPageReleaseTrackingConfig: exportPageReleaseTrackingConfigSelector,
});

export default compose(withRouter, connect(mapStateToProps, null))(WarrantExports);

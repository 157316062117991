import React, { FC } from 'react';
import { AttributeTypeEnum } from '@mark43/rms-api';
import { compose, withHandlers } from 'recompose';

import useFields, { useTargetProfileFieldName } from '~/client-common/core/fields/hooks/useFields';
import { CASE_TARGET_PROFILE_REVIEW_DATE_UTC } from '~/client-common/core/enums/universal/fields';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import Row from '../../../core/components/Row';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';

const { withRRFFieldValues, withRRFActions } = reactReduxFormHelpers;

interface TargetProfileSearchFieldsetProps {
    isTargetProfile?: boolean;
    onChangeIsTargetProfile?: (value: boolean) => void;
    children?: React.ReactNode;
}

/**
 * Fieldset for Target Profile in Cases search forms.
 */
const TargetProfileSearchFieldset: FC<TargetProfileSearchFieldsetProps> = ({
    isTargetProfile,
    onChangeIsTargetProfile,
}) => {
    const { singularTargetProfileFieldName: targetProfileFieldsetLabel } =
        useTargetProfileFieldName();

    const fieldDisplayNames = useFields([CASE_TARGET_PROFILE_REVIEW_DATE_UTC]);

    return (
        <RRFFieldset title={targetProfileFieldsetLabel} path="targetProfile">
            <Row>
                <RRFBooleanSelect
                    path="isTargetProfile"
                    width={200}
                    onChange={onChangeIsTargetProfile}
                />
                {isTargetProfile && (
                    <RRFDateRangePicker
                        label={fieldDisplayNames.CASE_TARGET_PROFILE_REVIEW_DATE_UTC}
                        width={254}
                        includeTime={true}
                        withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                        toDatePeriodOptions={['P1M', 'P1Y']}
                        fieldName={CASE_TARGET_PROFILE_REVIEW_DATE_UTC}
                    />
                )}
            </Row>
            {isTargetProfile && (
                <Row>
                    <RRFAttributeSelect
                        attributeType={AttributeTypeEnum.TARGET_PROFILE_CATEGORY.name}
                        path="targetProfileCategoryAttrIds"
                        multiple
                    />
                    <RRFAttributeSelect
                        attributeType={AttributeTypeEnum.TARGET_PROFILE_PRIORITY.name}
                        path="targetProfilePriorityAttrIds"
                        multiple
                    />
                </Row>
            )}
        </RRFFieldset>
    );
};

export default compose(
    withRRFActions,
    withRRFFieldValues({
        isTargetProfile: 'targetProfile.isTargetProfile',
    }),
    withHandlers({
        // @ts-expect-error reactReduxFormHelpers are not typed yet
        onChangeIsTargetProfile({ formActions: { change } }) {
            return (value: boolean) => {
                if (!value) {
                    // skip all target profile related fields when 'isTargetProfile' toggle is switched off
                    change('targetProfile', { isTargetProfile: value });
                }
            };
        },
    })
)(TargetProfileSearchFieldset);

import factory from './actionTypesFactory';

export default factory('alerts', [
    'OPEN_ALERT_FORM',
    'CLOSE_ALERT_FORM',
    'API_FAILURE',
    'REVERSE_BANNERS',
    'UPLOAD_ATTACHMENT_START',
    'MFTUPLOAD_ATTACHMENT_SUCCESS',
    'MFTUPLOAD_INFECTED_FILES_FOUND',
]);

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/partnerships/api';

export default createResource({
    name: 'Blacksmith Integration Resource',
    methods: {
        createIntegration(integration) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `support/blacksmith/integrations?create_integration_user=${integration.createNewUser}`,
                data: integration,
            });
        },

        getAllIntegrations() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: 'support/blacksmith/integrations',
            });
        },
        deleteIntegration(blacksmithIntegrationId) {
            return req({
                baseUrl: BASE_URL,
                method: 'DELETE',
                url: `support/blacksmith/integrations/${blacksmithIntegrationId}`,
            });
        },
        getIntegrationById(integrationId) {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `support/blacksmith/integrations/${integrationId}`,
            });
        },
        getJobRuns(integrationId, pageFrom, pageSize) {
            const jobRunsUrl = `support/blacksmith/integrations/${integrationId}/job_runs`;

            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: jobRunsUrl,
                params: {
                    from: pageFrom,
                    size: pageSize,
                },
            });
        },
        kickOffIntegrationAsync(integrationId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `support/blacksmith/integrations/${integrationId}/job_runs`,
            });
        },

        updateIntegration(integration) {
            return req({
                baseUrl: BASE_URL,
                method: 'PUT',
                url: `support/blacksmith/integrations/${integration.id}`,
                data: integration,
                params: {
                    rms_api_access: true,
                    input: true,
                    update_department_id: true,
                },
            });
        },

        updateIntegrationToggleEnable(integrationId, integration, isEnabled) {
            return req({
                baseUrl: BASE_URL,
                data: integration,
                method: 'PUT',
                url: `support/blacksmith/integrations/${integrationId}?is_enabled=${isEnabled}`,
            });
        },
        updateIntegrationToggleRemote(integrationId, integration, isRemote) {
            return req({
                baseUrl: BASE_URL,
                method: 'PUT',
                data: integration,
                url: `support/blacksmith/integrations/${integrationId}?is_remote=${isRemote}`,
            });
        },
    },
});

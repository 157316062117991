import { createSelector } from 'reselect';
import { OffenseCodeNibrsCodeLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import { mapNibrsCodesToOptions } from '../../../../../helpers/offenseCodesHelpers';

export const NEXUS_STATE_PROP = 'offenseCodeNibrsCodeLinks';

const offenseCodeNibrsCodeLinksModule = createLinkModule<OffenseCodeNibrsCodeLink>({
    type: NEXUS_STATE_PROP,
    keys: ['offenseCodeId', 'nibrsOffenseCodeId'],
});

const offenseCodeNibrsCodeLinksWhereSelector =
    offenseCodeNibrsCodeLinksModule.selectors.linksWhereSelector;

export const nibrsOffenseCodesForOffenseCodeIdForOffenseCodeIdSelector = createSelector(
    offenseCodeNibrsCodeLinksWhereSelector,
    (offenseCodeNibrsCodeLinks) => (offenseCodeId: number) => {
        const nibrsOffenseCodesForOffenseCodeId = offenseCodeNibrsCodeLinks({
            offenseCodeId,
        });
        return nibrsOffenseCodesForOffenseCodeId.map((x) => x.nibrsOffenseCode);
    }
);

export const nibrsOffenseCodeOptionsForOffenseCodeIdSelector = createSelector(
    nibrsOffenseCodesForOffenseCodeIdForOffenseCodeIdSelector,
    (nibrsOffenseCodes) => (offenseCodeId: number) => {
        return mapNibrsCodesToOptions(nibrsOffenseCodes(offenseCodeId) || [], 'id');
    }
);

export default offenseCodeNibrsCodeLinksModule.reducerConfig;

import keyMirror from 'keymirror';

/**
 * Must match server enum {@link
 *   server-util/data/data-common/src/main/java/mark43/data/SqlSortKey.java},
 *   except for the default `RELEVANCE`.
 * @type {Object}
 */
export default {
    RELEVANCE: undefined,
    ...keyMirror({
        E_FILE_NAME_LINK_NAME: null,
        INVENTORY_COMPLETED_DATE_UTC: null,
        INVENTORY_MISSING_ITEMS_COUNT: null,
        TASK_ASSIGNEE: null,
        TASK_DUE_DATE: null,
        TASK_CREATED_DATE: null,
        TASK_MODIFIED_DATE: null,
        TASK_PRIORITY: null,
        TASK_TYPE: null,
        TASK_TITLE: null,
        TASK_STATUS: null,
    }),
};

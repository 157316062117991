import { SystemRuleEnum, LinkTypesEnum } from '@mark43/rms-api';

import _, { some, every } from 'lodash';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import {
    attributeIsOtherSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { attributesOptionsByTypeForParentAttrIdIsEmptyForCurrentDepartmentSelector } from '../../../modules/core/attributes/state/ui';
import addRuleId from '../helpers/addRuleId';
import { setKoModelValidation } from '../helpers/koValidationHelper';
import { showOtherForProofOfOwnershipAttrIdSelector } from '../../../modules/reports/core/state/ui/ownerClaimants';

export const itemProofOfOwnershipRequired = addRuleId(
    SystemRuleEnum.ITEM_PROOF_OF_OWNERSHIP_REQUIRED.name,
    (data, errMsg) => {
        // evidence split item form
        if (!!data.childItemProfile) {
            const { owners } = data.childItemProfile;
            return owners && every(owners, 'proofOfOwnershipAttrId');
        }

        return _(data.nameItemLinks)
            .filter((link) => link.linkType() === LinkTypesEnum.OWNED_BY)
            .every((link) => {
                const isValid = !!link.proofOfOwnershipAttrId();
                setKoModelValidation(link.proofOfOwnershipAttrId, isValid, errMsg);
                return isValid;
            });
    }
);

export const itemProofOfOwnershipOtherRequired = addRuleId(
    SystemRuleEnum.ITEM_PROOF_OF_OWNERSHIP_OTHER_REQUIRED.name,
    (data, errMsg, state) => {
        // evidence split item form
        if (!!data.childItemProfile) {
            const { owners } = data.childItemProfile;
            return (
                owners &&
                every(owners, ({ proofOfOwnershipAttrId, proofOfOwnershipOther }) => {
                    const showProofOfOwnershipOther = showOtherForProofOfOwnershipAttrIdSelector(
                        state
                    )(proofOfOwnershipAttrId);
                    return !showProofOfOwnershipOther || proofOfOwnershipOther;
                })
            );
        }

        return _(data.nameItemLinks)
            .filter((link) => link.linkType() === LinkTypesEnum.OWNED_BY)
            .every((link) => {
                const isValid = link.showProofOfOwnershipOther()
                    ? !!link.proofOfOwnershipOther()
                    : true;
                setKoModelValidation(link.proofOfOwnershipOther, isValid, errMsg);
                return isValid;
            });
    }
);

export const itemVehicleBodyStyleRequired = addRuleId(
    SystemRuleEnum.ITEM_VEHICLE_BODY_STYLE_REQUIRED.name,
    (data, errMsg, state) => {
        // evidence split item form
        if (!!data.childItemProfile) {
            const { itemCategoryAttrId, bodyStyleAttrId } = data.childItemProfile;
            return (
                attributesOptionsByTypeForParentAttrIdIsEmptyForCurrentDepartmentSelector(state)(
                    'VEHICLE_BODY_STYLE',
                    itemCategoryAttrId
                ) || !!bodyStyleAttrId
            );
        }

        // only required body style if it's showing, i.e. if it has options
        const isValid =
            data.item._config.bodyStyleAttrId.options().length === 0 ||
            !!data.item.bodyStyleAttrId();
        setKoModelValidation(data.item.bodyStyleAttrId, isValid, errMsg);
        return isValid;
    }
);

export const itemVehicleBodyStyleOtherRequired = addRuleId(
    SystemRuleEnum.ITEM_VEHICLE_BODY_STYLE_OTHER_REQUIRED.name,
    (data, errMsg, state) => {
        // evidence split item form
        if (!!data.childItemProfile) {
            const { bodyStyleAttrId, bodyStyleOther } = data.childItemProfile;
            return attributeIsOtherSelector(state)(bodyStyleAttrId) ? !!bodyStyleOther : true;
        }

        let isValid = true;
        if (attributeIsOtherSelector(state)(data.item.bodyStyleAttrId())) {
            isValid = !!data.item.bodyStyleOther();
        }
        return setKoModelValidation(data.item.bodyStyleOther, isValid, errMsg);
    }
);

export const towVehicleRequiresOwner = addRuleId(
    SystemRuleEnum.TOW_VEHICLE_REQUIRES_OWNER.name,
    (data) => {
        return some(data.nameItemLinks, (link) => link.linkType() === LinkTypesEnum.OWNED_BY);
    }
);

/**
 * This system rule runs only in the validation context ITEM_IN_POLICE_CUSTODY,
 *   which means the item's property status attribute has global parent
 *   IN_POLICE_CUSTODY.
 *
 * This system rule applies only to non-vehicles because it doesn't make sense
 *   for a vehicle to have a bag number.
 *
 * When this system rule is added to a tenant, the tenant must have an
 *   ITEM_IDENTIFIER_TYPE attribute with parent BAG_NUMBER, otherwise this rule
 *   cannot be passed.
 */
export const itemIdentifierBagNumberRequiredForEvidence = addRuleId(
    SystemRuleEnum.ITEM_IDENTIFIER_BAG_NUMBER_REQUIRED_FOR_EVIDENCE.name,
    (data, errMsg, state) => {
        // evidence split item form
        if (!!data.childItemProfile) {
            const { itemTypeAttrId, itemIdentifiers } = data.childItemProfile;
            // TODO: tech debt KRA-1294 remove this check
            if (itemTypeAttrId === globalAttributes.itemType.vehicle) {
                return true;
            }
            return some(
                itemIdentifiers,
                ({ identifier, itemIdentifierTypeAttrId }) =>
                    !!identifier &&
                    parentAttributeIdByAttributeIdSelector(state)(itemIdentifierTypeAttrId) ===
                        globalAttributes.itemIdentifierTypeGlobal.bagNumber
            );
        }

        // TODO: tech debt KRA-1294 remove this check
        if (data.item.itemTypeAttrId() === globalAttributes.itemType.vehicle) {
            return true;
        }

        return some(data.itemIdentifiers, (itemIdentifier) => {
            return (
                !!itemIdentifier.identifier() &&
                parentAttributeIdByAttributeIdSelector(state)(
                    itemIdentifier.itemIdentifierTypeAttrId()
                ) === globalAttributes.itemIdentifierTypeGlobal.bagNumber
            );
        });
    }
);

import { AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import { includes, map } from 'lodash';
import { createSelector } from 'reselect';

import getReportCardBundleResource from '../../resources/reportCardBundleResource';
import { reportDefinitionByIdSelector } from '../../../report-definitions/state/data';
import { idFormatConfigurationByIdAndEntityTypeSelector } from '../../../id-format-configurations/state/data';
import { cadTicketsWhereSelector } from '../../../cad-tickets/state/data';
import { latestCadTicket } from '../../../cad-tickets/utils/cadTicketsHelpers';

import {
    eventDetailByReportIdSelector,
    storeEventDetails,
} from '../../../event-details/state/data';
import { agencyProfileByIdSelector } from '../../../agency-profiles/state/data';
import {
    NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP,
    reportAttributesWhereSelector,
} from '../../../report-attributes/state/data';
import { NEXUS_STATE_PROP as REPORTS_NEXUS_STATE_PROP, reportByIdSelector } from '../data';

/**
 * `AttributeType`s that are valid for the `ReportAttribute`s in a Supplement Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const SUPPLEMENT_CARD_REPORT_ATTRIBUTE_TYPES = [AttributeTypeEnum.SUPPLEMENT_TYPE.name];

const reportAttributesFilter = (reportId) => {
    return (reportAttribute) =>
        reportAttribute.reportId === reportId &&
        includes(SUPPLEMENT_CARD_REPORT_ATTRIBUTE_TYPES, reportAttribute.attributeType);
};

export const hydratedSupplementInfoByReportIdSelector = createSelector(
    reportByIdSelector,
    reportAttributesWhereSelector,
    eventDetailByReportIdSelector,
    agencyProfileByIdSelector,
    reportDefinitionByIdSelector,
    idFormatConfigurationByIdAndEntityTypeSelector,
    cadTicketsWhereSelector,
    (
        reportById,
        reportAttributesWhere,
        eventDetailByReportId,
        agencyProfileById,
        reportDefinitionById,
        idFormatConfigurationByIdAndEntityType,
        cadTicketsWhere
    ) => (reportId) => {
        const report = reportById(reportId) || {};
        const reportAttributes = reportAttributesWhere(reportAttributesFilter(reportId));
        const eventDetail = eventDetailByReportId(reportId) || {};
        const agencyProfile = agencyProfileById(report.agencyId) || {};

        const reportDefinition = reportDefinitionById(report.reportDefinitionId) || {};
        const idFormatConfiguration = idFormatConfigurationByIdAndEntityType(
            reportDefinition.idFormatConfigurationId,
            EntityTypeEnum.CAD_AGENCY_EVENT.name
        );
        const cadTicketPredicate = !!idFormatConfiguration
            ? { cadAgencyEventNumber: report.recordNumber }
            : (cadTicket) => includes(cadTicket.reportingEventNumbers, report.reportingEventNumber);
        const cadTickets = cadTicketsWhere(cadTicketPredicate);
        const cadTicket = latestCadTicket(cadTickets);

        return {
            report,
            reportAttributes,
            eventDetail,
            agencyProfile,
            cadTicket,
        };
    }
);

export function replaceSupplementCardBundle({ reportId, supplementCardBundle }) {
    return (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) => {
        const resource = getReportCardBundleResource();
        return resource
            .upsertSupplementCard(reportId, supplementCardBundle)
            .then((updatedSupplementCardBundle) => {
                const state = getState();
                const existingReport = reportByIdSelector(state)(reportId);
                const {
                    eventDetail,
                    reportAttributes,
                    agencyId,
                    description,
                } = updatedSupplementCardBundle;

                const predicateReportAttributeObjectsToRemoveBy = map(
                    SUPPLEMENT_CARD_REPORT_ATTRIBUTE_TYPES,
                    (attributeType) => ({
                        reportId,
                        attributeType,
                    })
                );
                const updatedReport = {
                    ...existingReport,
                    agencyId,
                    description,
                };

                // Cannot use nexus for `EventDetail` storing, because under the hood,
                // `storeEventDetails` has custom logic to pick off `paramedics` from
                // the `EventDetail` object and store the `paramedics` as well.
                dispatch(storeEventDetails(eventDetail));

                dispatch(
                    withEntityItems(
                        {
                            [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportAttributes,
                            [REPORTS_NEXUS_STATE_PROP]: [updatedReport],
                        },
                        withRemoveMultiple(
                            {
                                [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: predicateReportAttributeObjectsToRemoveBy,
                            },
                            {
                                type: 'UPSERT_SUPPLEMENT_INFO_CARD_BUNDLE',
                            }
                        )
                    )
                );

                return {
                    updatedSupplementCardBundle,
                    updatedReport,
                };
            });
    };
}

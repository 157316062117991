import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterMenuList, FilterMenuRenderProps, FilterSearchInput } from 'arc';
import { RoleTypeEnum } from '@mark43/rms-api';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import {
    allRoleFormatsByRoleIdSelector,
    roleOptionsByTypesSelector,
} from '~/client-common/core/domain/roles/state/data';
import { searchUserRoles } from '../../../../legacy-redux/actions/rolesActions';
import { currentUserDepartmentIdSelector } from '../../current-user/state/ui';

const { NORMAL, DEPARTMENT, USER, SUPPORT } = RoleTypeEnum;

const AVAILABLE_ROLES_TYPES = [NORMAL.name, DEPARTMENT.name, USER.name, SUPPORT.name];
type AvailableUserRoleTypes = typeof AVAILABLE_ROLES_TYPES;

export const convertUserRoleToAppliedOption = (item: { roleId: number; label: string }) => {
    return {
        id: item.roleId,
        label: item.label,
        value: item.roleId.toString(),
    };
};

export const UserRoleFilter: React.FC<
    FilterMenuRenderProps & {
        roleTypes: AvailableUserRoleTypes;
        excludeRoleIds?: number[];
        useExternalRoles?: boolean;
    }
> = ({
    appliedOptions,
    setAppliedFilters,
    selectionType,
    roleTypes,
    excludeRoleIds,
    useExternalRoles = false,
}) => {
    const dispatch = useDispatch();
    const roleOptionsByTypes = useSelector(roleOptionsByTypesSelector);
    const allRoleFormatsByRoleId = useSelector(allRoleFormatsByRoleIdSelector);
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);

    const [query, setQuery] = useState('');

    const getOptions = () => {
        const options = roleOptionsByTypes(
            roleTypes,
            useExternalRoles
                ? ({ departmentId }) => departmentId !== currentUserDepartmentId
                : { departmentId: currentUserDepartmentId },
            useExternalRoles
        ).filter((roleOption) => !Boolean(excludeRoleIds?.includes(roleOption.value)));

        return options.map((item) => ({
            id: item.value,
            label: allRoleFormatsByRoleId(item.value).default ?? '',
            value: item.value.toString(),
        }));
    };

    useEffect(() => {
        dispatch(searchUserRoles(query, { effectiveDate: nowUtc() }));
    }, [dispatch, query]);

    return (
        <>
            <FilterSearchInput onChange={(e) => setQuery(e.target.value)} />
            <FilterMenuList
                query={query}
                options={getOptions()}
                appliedOptions={appliedOptions}
                onSelect={setAppliedFilters}
                selectionType={selectionType}
            />
        </>
    );
};

import React from 'react';
import classNames from 'classnames';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';

export default function EntityProfileLinkIndicator({ linkTypes, className }) {
    const links = Array.isArray(linkTypes) ? linkTypes : [linkTypes];
    return (
        <div className={classNames('entity-profile-link-indicator', className)}>
            {links.map((link) => (
                <div key={link}>
                    <Icon type={iconTypes.LINK} />
                    <span className="entity-profile-link-indicator-linktypes">{link}</span>
                </div>
            ))}
        </div>
    );
}

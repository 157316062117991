import React from 'react';
import { useSelector } from 'react-redux';
import { isFunction } from 'lodash';
import styled from 'styled-components';
import keyMirror from 'keymirror';

import { reportDefinitionHasCardSelector } from '~/client-common/core/domain/report-definitions/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import useEventDateUtc from '../../reports/core/hooks/useEventDateUtc';
import { currentReportSelector } from '../../../legacy-redux/selectors/reportSelectors';
import {
    SUPPLEMENT_INFO_CARD_EVENT_START_UTC_PATH,
    SUPPLEMENT_INFO_CARD_EVENT_END_UTC_PATH,
} from '../../reports/core/state/forms/supplementInfoForm';
import {
    EVENT_INFO_CARD_EVENT_START_UTC_PATH,
    EVENT_INFO_CARD_EVENT_END_UTC_PATH,
} from '../../reports/core/state/forms/eventInfoForm';
import FieldState from '../forms/markformythree-arbiter/FieldState';
import testIds from '../../../core/testIds';
import { Tooltip } from './tooltip';
import { IconButton as ArcIconButton } from './IconButton';

const strings = componentStrings.core.AutofillDateButton;

export const autofillDateTypeEnum = keyMirror({
    START_DATE: null,
    START_DATE_TIME: null,
    START_END_DATE_TIME: null,
});

const getTooltipTextForAutofillDateType = (autofillDateType) => {
    switch (autofillDateType) {
        case autofillDateTypeEnum.START_DATE:
            return strings.useEventStartDate;
        case autofillDateTypeEnum.START_DATE_TIME:
            return strings.useEventStartDateTime;
        case autofillDateTypeEnum.START_END_DATE_TIME:
            return strings.useEventStartEndDateTime;
        default:
            return null;
    }
};

const getIsDateFilledForAutofillDateType = ({ autofillDateType, eventStartUtc, eventEndUtc }) => {
    switch (autofillDateType) {
        case autofillDateTypeEnum.START_DATE:
        case autofillDateTypeEnum.START_DATE_TIME:
            return !!eventStartUtc;
        case autofillDateTypeEnum.START_END_DATE_TIME:
            return !!eventStartUtc || !!eventEndUtc;
        default:
            return false;
    }
};

const IconButtonWrapper = styled.div`
    margin-top: 23px;
`;

const AutofillDateButton = ({
    onClick,
    autofillDateType,
    isStartDateHidden,
    isEndDateHidden,
    ...otherProps
}) => {
    const currentReport = useSelector(currentReportSelector);
    const reportDefinitionHasCard = useSelector(reportDefinitionHasCardSelector);

    const { reportDefinitionId } = currentReport || {};
    const reportHasEventInfoCard = reportDefinitionHasCard(
        reportDefinitionId,
        reportCardEnum.EVENT_INFO.id
    );
    const reportHasSupplementInfoCard = reportDefinitionHasCard(
        reportDefinitionId,
        reportCardEnum.SUPPLEMENT_INFO.id
    );

    const eventStartUtc = useEventDateUtc({
        reportHasEventInfoCard,
        reportHasSupplementInfoCard,
        eventInfoFormDatePath: EVENT_INFO_CARD_EVENT_START_UTC_PATH,
        supplementInfoFormDatePath: SUPPLEMENT_INFO_CARD_EVENT_START_UTC_PATH,
    });
    const eventEndUtc = useEventDateUtc({
        reportHasEventInfoCard,
        reportHasSupplementInfoCard,
        eventInfoFormDatePath: EVENT_INFO_CARD_EVENT_END_UTC_PATH,
        supplementInfoFormDatePath: SUPPLEMENT_INFO_CARD_EVENT_END_UTC_PATH,
    });

    const isStartDateDisplayable = !!eventStartUtc && !isStartDateHidden;
    const isEndDateDisplayable = !!eventEndUtc && !isEndDateHidden;

    if (!isStartDateDisplayable && !isEndDateDisplayable) {
        return null;
    }

    const handleClick = (event) => {
        const startDate = isStartDateDisplayable ? eventStartUtc : undefined;
        const endDate = isEndDateDisplayable ? eventEndUtc : undefined;
        if (isFunction(onClick)) {
            onClick(event, { eventStartUtc: startDate, eventEndUtc: endDate });
        }
    };

    const tooltipText = getTooltipTextForAutofillDateType(autofillDateType);

    const autoFillButton = (
        <IconButtonWrapper>
            <ArcIconButton
                variant="outline"
                onClick={handleClick}
                testId={testIds.AUTOFILL_DATE_BUTTON}
                icon="AutoFillDate"
                {...otherProps}
            />
        </IconButtonWrapper>
    );

    const isDateFilled = getIsDateFilledForAutofillDateType({
        autofillDateType,
        eventStartUtc,
        eventEndUtc,
    });

    return isDateFilled ? (
        tooltipText ? (
            <Tooltip side="top" content={tooltipText}>
                {autoFillButton}
            </Tooltip>
        ) : (
            autoFillButton
        )
    ) : null;
};

/**
 * @typedef {object} Props
 * @prop {string}    autofillDateType
 * @prop {string}    [startDatePath]
 * @prop {string}    [endDatePath]
 * @prop {function}  [onClick]
 * @param {Props}    props
 */
export const MFTAutofillDateButton = ({ startDatePath, endDatePath, ...otherProps }) => {
    const startDatePathExists = !!startDatePath;
    const endDatePathExists = !!endDatePath;

    if (!startDatePathExists && !endDatePathExists) {
        return null;
    }

    return startDatePathExists ? (
        <FieldState
            path={startDatePath}
            render={({ hidden: isStartDateHidden }) => {
                return endDatePathExists ? (
                    <FieldState
                        path={endDatePath}
                        render={({ hidden: isEndDateHidden }) => (
                            <AutofillDateButton
                                isStartDateHidden={isStartDateHidden}
                                isEndDateHidden={isEndDateHidden}
                                {...otherProps}
                            />
                        )}
                    />
                ) : (
                    <AutofillDateButton
                        isStartDateHidden={isStartDateHidden}
                        isEndDateHidden={true}
                        {...otherProps}
                    />
                );
            }}
        />
    ) : (
        <FieldState
            path={endDatePath}
            render={({ hidden: isEndDateHidden }) => (
                <AutofillDateButton
                    isStartDateHidden={true}
                    isEndDateHidden={isEndDateHidden}
                    {...otherProps}
                />
            )}
        />
    );
};

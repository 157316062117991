import _ from 'lodash';

import { formatRoleNameSelector } from '~/client-common/core/domain/roles/state/data';

import { addTransientKeys } from '~/client-common/helpers/dataHelpers';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import entityPermissionsResource from '../resources/entityPermissionsResource';

import { convertEntityPermissionsFormDataToDataState } from '../helpers/entityPermissionsHelpers';
import { addExternalDepartmentFlags } from '../helpers/permissionHelpers';
import { closeBox, saveBoxFailure } from './boxActions';
import entityPermissionsActionTypes from './types/entityPermissionsActionTypes';

const context = {
    name: boxEnum.ENTITY_PERMISSIONS_MODAL,
};

const augmentPermissions = (permissions, state) => {
    // mark permissions as external if their role
    // is not from the current department,
    // so they can be separated in a form
    return addExternalDepartmentFlags(
        // add a unique id to each permission
        addTransientKeys(permissions),
        state
    );
};

export function initializeEntityPermissions(entityType, entityId, userOperationTypes) {
    return {
        type: entityPermissionsActionTypes.OPEN_PERMISSIONS_MODAL,
        payload: { entityType, entityId, userOperationTypes },
    };
}

function loadPermissionsSuccess(permissions) {
    return {
        type: entityPermissionsActionTypes.LOAD_PERMISSIONS_SUCCESS,
        payload: permissions,
    };
}

export function sortAndAugmentPermissions(permissions) {
    return (dispatch, getState) => {
        const state = getState();
        const formatRoleName = formatRoleNameSelector(state);
        // sort permissions by date, then by role name
        permissions = _.sortBy(permissions, 'createdDateUtc', formatRoleName);

        permissions = augmentPermissions(permissions, state);
        return dispatch(loadPermissionsSuccess(permissions));
    };
}

function updatePermissionsStart(entityType, entityId) {
    return {
        type: entityPermissionsActionTypes.UPDATE_PERMISSIONS_START,
        payload: { entityType, entityId },
    };
}

function updatePermissionsSuccess(permissions) {
    return {
        type: entityPermissionsActionTypes.UPDATE_PERMISSIONS_SUCCESS,
        payload: permissions,
    };
}

function updatePermissionsFailure(errorMessage) {
    return {
        type: entityPermissionsActionTypes.UPDATE_PERMISSIONS_FAILURE,
        payload: errorMessage,
    };
}

export function augmentAndUpdatePermissions(permissions) {
    return (dispatch, getState) => {
        return dispatch(updatePermissionsSuccess(augmentPermissions(permissions, getState())));
    };
}

/**
 * Update the permissions for the given entity. On success, close the modal.
 * @param  {string}   entityType
 * @param  {number}   entityId
 * @param  {Object[]} formData
 * @return {Promise<Object>}
 */
export function updatePermissions(entityType, entityId, formData) {
    return function (dispatch) {
        dispatch(updatePermissionsStart());

        const permissions = convertEntityPermissionsFormDataToDataState(
            entityType,
            entityId,
            formData
        );

        return entityPermissionsResource
            .updatePermissions(entityType, entityId, permissions)
            .then((permissions) => {
                dispatch(augmentAndUpdatePermissions(permissions));
                dispatch(closeBox(context));
            })
            .catch((err) => {
                dispatch(updatePermissionsFailure(err.message));
                dispatch(saveBoxFailure(context, err.message));
            });
    };
}

/**
 * Store the form's submit handler in the ui state, so a parent component can
 *   trigger the form to be submitted. In this case, the containing modal has
 *   the submit button, and the form itself does not. This is a temporary ugly
 *   workaround that can be removed if/when we update redux-form to v4.2.0.
 * @param  {function} handleSubmit
 * @return {Object}
 */
export function storeHandleSubmit(handleSubmit) {
    return {
        type: entityPermissionsActionTypes.STORE_HANDLE_SUBMIT,
        payload: handleSubmit,
    };
}

import React from 'react';
import { connect } from 'react-redux';
import { InjectedRouter } from 'react-router';
import {
    compose,
    withHandlers,
    withState,
    lifecycle,
    withPropsOnChange,
    getContext,
    setDisplayName,
} from 'recompose';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { isArray, map, noop, some, uniq, values, get } from 'lodash';

import {
    Attribute,
    AttributeTypeEnum,
    AttributeTypeEnumType,
    FieldNameEnumType,
} from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { AttributeViewModel } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import Link from '../../../components/links/Link';
import { logWarning } from '../../../../../core/logging';
import { shouldShowFieldNamesSelector } from '../../../../../legacy-redux/selectors/globalSelectors';
import { OnlyWithAbility } from '../../../abilities';
import { SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { computeAllowedAttributeTypesToLoad } from '../../../attributes/utils/computeAllowedAttributeTypesToLoad';
import {
    attributeLoadingStateSelectorFactory,
    attributeOptionsByTypeForCurrentDepartmentSelector,
} from '../../../attributes/state/ui';
import { loadAttributesForType } from '../../../attributes/state/ui/loadAttributesForType';
import { arbiterMFTInput } from '../../../arbiter';
import FormElementButtonRadio from './FormElementButtonRadio';
import { AttributeLegacyButtonRadioOption } from './types';

const createLink = (attributeType: AttributeTypeEnumType, showLinks: boolean) => {
    const displayName = get(AttributeTypeEnum[attributeType], 'displayName');
    return showLinks ? (
        <Link to={`/admin/attributes/${attributeType}`} openInNewTab={true}>
            {displayName}
        </Link>
    ) : (
        <span>{displayName}</span>
    );
};

type LoadingState = { loaded: boolean; loading: boolean; error: boolean };

type AttributeButtonRadioPropsType = {
    attributeId: number;
    attributeLoadingState: Record<AttributeTypeEnumType, LoadingState>;
    attributeType: AttributeTypeEnumType;
    error: string;
    fieldName: FieldNameEnumType;
    helpText?: string;
    label?: string;
    onAttributeChange: (newAttributeId: number) => void;
    options: AttributeLegacyButtonRadioOption[];
    router?: InjectedRouter;
    shouldShowFieldNames: boolean;
};

const BaseButtonRadio: React.FC<AttributeButtonRadioPropsType> = ({
    attributeId,
    attributeLoadingState,
    attributeType,
    error,
    fieldName,
    onAttributeChange,
    options,
    router,
    shouldShowFieldNames,
    helpText,
    ...otherProps
}) => {
    const attributeLoadingValues = values(attributeLoadingState);
    const isLoading = some(attributeLoadingValues, (loadingState) => loadingState.loading);
    const hasAsyncError =
        !isLoading && some(attributeLoadingValues, (loadingState) => loadingState.error);
    const rawLabel = otherProps.label || '';
    const showLinks = !!router;
    const label = shouldShowFieldNames ? (
        <div>
            {rawLabel}
            <FeatureFlagged flag="RMS_TOGGLE_FIELD_LABELS_ENABLED">
                <OnlyWithAbility has={abilitiesEnum.ADMIN.EDIT_GLOBAL_ATTRIBUTE_CONFIGURATION}>
                    <div>
                        {componentStrings.core.ButtonRadio.attributeType}
                        {isArray(attributeType)
                            ? map(attributeType, (attribute) => (
                                  <div key={attribute}>{createLink(attribute, showLinks)} </div>
                              ))
                            : createLink(attributeType, showLinks)}
                    </div>
                </OnlyWithAbility>
            </FeatureFlagged>
        </div>
    ) : (
        <div>{rawLabel}</div>
    );
    return (
        <div>
            {isLoading && <SimpleLoading />}
            {!isLoading && (
                <FormElementButtonRadio
                    label={label}
                    onChange={onAttributeChange}
                    options={options}
                    value={attributeId}
                    fieldName={fieldName}
                    error={
                        error ||
                        (hasAsyncError
                            ? componentStrings.core.attributeComponents.attributeTypeLoadingError
                            : undefined)
                    }
                    forceShowError={hasAsyncError}
                    helpText={helpText}
                    {...otherProps}
                />
            )}
        </div>
    );
};

const AttributeButtonRadio = compose<
    AttributeButtonRadioPropsType,
    {
        attributeType: AttributeTypeEnumType;
        sortByIteratees?: (keyof AttributeViewModel)[];
    }
>(
    setDisplayName('AttributeButtonRadio'),
    withState('attributeId', 'setAttributeId', ({ value }) => value),
    connect(
        () => {
            const attributeLoadingStateSelector = attributeLoadingStateSelectorFactory();
            return createStructuredSelector({
                attributeOptionsByTypeForCurrentDepartment: attributeOptionsByTypeForCurrentDepartmentSelector,
                attributeLoadingState: attributeLoadingStateSelector,
                shouldShowFieldNames: shouldShowFieldNamesSelector,
            });
        },
        {
            loadAttributesForType,
        }
    ),
    getContext({ router: PropTypes.object }),
    withHandlers({
        onAttributeChange({
            setAttributeId,
            onChange,
        }: {
            setAttributeId: React.Dispatch<React.SetStateAction<number>>;
            onChange: (attributeId: number) => void;
        }) {
            return (newAttributeId: number) => {
                setAttributeId(() => {
                    return newAttributeId;
                });
                onChange(newAttributeId);
            };
        },
    }),
    lifecycle({
        componentDidMount() {
            const attributeTypesToLoad = computeAllowedAttributeTypesToLoad(
                // @ts-expect-error TODO: Consolidate all the components that use this pattern
                this.props.attributeLoadingState
            );
            if (attributeTypesToLoad.length) {
                // @ts-expect-error TODO: Consolidate all the components that use this pattern
                this.props
                    .loadAttributesForType({ attributeType: attributeTypesToLoad })
                    .then((attributes: Attribute[]) => {
                        // @ts-expect-error TODO: Consolidate all the components that use this pattern
                        if (this.props.onAttributeLoadSuccess) {
                            // @ts-expect-error TODO: Consolidate all the components that use this pattern
                            this.props.onAttributeLoadSuccess(attributes);
                        }
                    })
                    .catch(noop);
            }
        },
    }),
    withPropsOnChange(
        [
            'attributeType',
            'includeExpired',
            'sortByIteratees',
            'attributeIds',
            'includeAbbr',
            'attributeOptionsByTypeForCurrentDepartment',
        ],
        ({
            attributeType,
            includeAbbr = false,
            includeExpired = false,
            sortByIteratees,
            attributeIds,
            attributeOptionsByTypeForCurrentDepartment,
        }: {
            attributeType: AttributeTypeEnumType;
            includeAbbr: boolean;
            includeExpired: boolean;
            sortByIteratees: (keyof AttributeViewModel)[];
            attributeIds: number[];
            attributeOptionsByTypeForCurrentDepartment: ReturnType<
                typeof attributeOptionsByTypeForCurrentDepartmentSelector
            >;
        }) => {
            const options = attributeOptionsByTypeForCurrentDepartment({
                type: attributeType,
                includeExpired,
                additionalIds: attributeIds,
                includeAbbr,
                sortByIteratees,
            });
            const departmentIds = map(options, 'departmentId');
            const unique = uniq(departmentIds);
            if (unique.length > 1) {
                logWarning('Attributes from multiple departments found in attribute select', {
                    attributeType,
                    departmentIds: unique,
                    attributeIds: map(options, 'value'),
                });
            }
            return { options };
        }
    )
)(BaseButtonRadio);

export const ArbiterMFTAttributeButtonRadio = arbiterMFTInput(AttributeButtonRadio);

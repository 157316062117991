import { PersonQuickAddContext } from '../../../core/context/PersonQuickAddContext';
import { OrganizationQuickAddContext } from '../../../core/context/OrganizationQuickAddContext';

const useQuickAddCallbacks = () => {
    const { setIsPersonStale } = PersonQuickAddContext.useContainer();
    const { setIsOrganizationsStale } = OrganizationQuickAddContext.useContainer();
    const personQuickAddCallback = () => setIsPersonStale(true);
    const orgQuickAddCallback = () => setIsOrganizationsStale(true);
    const nameQuickAddCallback = () => {
        personQuickAddCallback();
        orgQuickAddCallback();
    };
    return { personQuickAddCallback, orgQuickAddCallback, nameQuickAddCallback };
};

export default useQuickAddCallbacks;

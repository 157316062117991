import { createFormConfiguration } from 'markformythree';
import { DISPOSITION_EVENT_COMMENT } from '~/client-common/core/enums/universal/fields';

export const initialState = {
    comment: undefined,
};

export const formConfiguration = createFormConfiguration({
    comment: {
        fieldName: DISPOSITION_EVENT_COMMENT,
    },
});

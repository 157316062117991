import _, { get, parseInt } from 'lodash';
import { createSelector } from 'reselect';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { getAdminListStatusFromStartEnd } from '~/client-common/core/dates/utils/dateHelpers';
import { cadMessagingGroupsSelector } from '~/client-common/core/domain/cad-messaging-groups/state/data';
import { cadUnitsSelector } from '~/client-common/core/domain/cad-units/state/data';
import { dispatchAreasSelector } from '~/client-common/core/domain/dispatch-areas/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { getMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import {
    cadCallTakerStationsSelector,
    cadCallTakerStationOptionsSelector,
} from '~/client-common/core/domain/cad-call-taker-stations/state/data';
import cadMessagingGroupsAdminForm from '../forms/cadMessagingGroupsAdminForm';
import {
    LOAD_START,
    LOAD_SUCCESS,
    LOAD_FAILURE,
    SAVE_START,
    SAVE_SUCCESS,
    SAVE_FAILURE,
} from '../data';
import authActionTypes from '../../../../../legacy-redux/actions/types/authActionTypes';
import { routeNameSelector } from '../../../../../routing/routerModule';

const { BOOTSTRAP_SUCCESS } = authActionTypes;
const SELECT_START = 'cad-messaging-groups/SELECT_START';
const SELECT_SUCCESS = 'cad-messaging-groups/SELECT_SUCCESS';
const SELECT_FAILURE = 'cad-messaging-groups/SELECT_FAILURE';
const OPEN_NEW_FORM = 'cad-messaging-groups/OPEN_NEW_FORM';

function selectCadMessagingGroupStart() {
    return {
        type: SELECT_START,
    };
}

function selectCadMessagingGroupSuccess(id) {
    return {
        type: SELECT_SUCCESS,
        payload: id,
    };
}

function selectCadMessagingGroupFailure() {
    return {
        type: SELECT_FAILURE,
    };
}

export function selectCadMessagingGroup(id) {
    return (dispatch, getState) => {
        dispatch(selectCadMessagingGroupStart());
        const state = getState();
        const cadMessagingGroup = cadMessagingGroupsSelector(state)[id];

        if (cadMessagingGroup) {
            dispatch(cadMessagingGroupsAdminForm.actionCreators.change({ cadMessagingGroup }));
            dispatch(selectCadMessagingGroupSuccess(id));
        } else {
            // TODO: remove this crappy interval when migrating the router, this is
            // a workaround for onEnter/onLeave hooks not respecting parent/child relationships
            let attempts = 0;

            const intervalId = window.setInterval(() => {
                const state = getState();
                const cadMessagingGroup = cadMessagingGroupsSelector(state)[id];

                if (cadMessagingGroup) {
                    window.clearInterval(intervalId);
                    dispatch(
                        cadMessagingGroupsAdminForm.actionCreators.change({ cadMessagingGroup })
                    );
                    dispatch(selectCadMessagingGroupSuccess(id));
                }

                attempts++;
                if (attempts > 300) {
                    window.clearInterval(intervalId);

                    return dispatch(
                        selectCadMessagingGroupFailure('Failed to get cad messaging group.')
                    );
                }
            }, 1000);
        }
    };
}

export function openNewCadMessagingGroupForm() {
    return {
        type: OPEN_NEW_FORM,
    };
}

const initialUiState = {
    selectedCadMessagingGroupId: null,
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    cadMessagingGroupLoadError: null,
};

export default function cadMessagingGroupsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case BOOTSTRAP_SUCCESS:
            return {
                ...state,
            };
        case LOAD_START:
            return {
                ...state,
                loadingList: true,
                selectedCadMessagingGroupId: null,
                listError: null,
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case SAVE_START:
            return {
                ...state,
                submittingForm: true,
            };
        case SAVE_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case SAVE_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_SUCCESS:
            return {
                ...state,
                cadMessagingGroupLoadError: null,
                selectedCadMessagingGroupId: parseInt(action.payload),
            };
        case SELECT_FAILURE:
            return {
                ...state,
                cadMessagingGroupLoadError: action.payload,
            };
        case OPEN_NEW_FORM:
            return {
                ...state,
                formError: null,
                cadMessagingGroupLoadError: null,
                selectedCadMessagingGroupId: null,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.cadMessagingGroups;

export const cadMessagingGroupViewModelByIdSelector = createSelector(
    cadMessagingGroupsSelector,
    (cadMessagingGroups) => (id) => {
        return cadMessagingGroups[id]
            ? {
                  ...cadMessagingGroups[id],
              }
            : undefined;
    }
);

export const cadMessagingEntityTypesSelector = createSelector(
    applicationSettingsSelector,
    (applicationSettings) => {
        const callStationEnabled = applicationSettings.MESSAGING_CALL_STATION_ENABLED;
        const values = [
            { value: 'USER', display: 'Users' },
            { value: 'UNIT', display: 'Units' },
            { value: 'DISPATCH_AREA', display: 'Dispatch Area Owners' },
        ];
        if (callStationEnabled) {
            values.push({ value: 'CALL_TAKER_STATION', display: 'Call Taker Stations' });
        }
        return values;
    }
);

export const cadMessagingGroupUsersByIdSelector = createSelector(
    getMiniUserByIdSelector,
    (getMiniUserByIdSelector) => (data) => {
        const output = _(data)
            .map((item) => {
                const user = getMiniUserByIdSelector(item.entityId);

                return {
                    firstname: user.first,
                    lastname: user.last,
                    id: user.id,
                };
            })
            .sortBy('first')
            .sortBy('lastname')
            .value();
        return output;
    }
);

export const cadMessagingGroupDispatchAreaByIdSelector = createSelector(
    dispatchAreasSelector,
    formatAttributeByIdSelector,
    (dispatchAreasAdmin, formatAttributeById) => (data) => {
        const output = _(data)
            .map((item) => {
                const dispatchArea = dispatchAreasAdmin[item.entityId];
                return dispatchArea
                    ? {
                          id: dispatchArea.id,
                          name: dispatchArea.name,
                          status: dispatchArea.status,
                          agencyType: formatAttributeById(dispatchArea.agencyTypeGlobalAttrId),
                      }
                    : null;
            })
            .sortBy('name')
            .value();
        return output;
    }
);

export const cadMessagingGroupCallStationByIdSelector = createSelector(
    cadCallTakerStationsSelector,
    (outerData) => (data) => {
        const output = _(data)
            .map((item) => {
                const row = outerData[item.entityId];
                return row
                    ? {
                          id: row.id,
                          displayValue: row.displayValue,
                          startDateUtc: row.startDateUtc,
                          endDateUtc: row.endDateUtc,
                      }
                    : null;
            })
            .sortBy('displayValue')
            .value();
        return output;
    }
);

export const cadMessagingGroupDispatchAreaSelector = createSelector(
    dispatchAreasSelector,
    (dispatchAreasAdmin) => {
        const filteredDispatchAreasAdmin = _.filter(dispatchAreasAdmin, (x) => {
            return x.status === 'ACTIVE';
        });
        return _(filteredDispatchAreasAdmin)
            .map((area) => {
                return {
                    value: area.id,
                    display: `${area.name}`,
                };
            })
            .sortBy('display')
            .value();
    }
);

export const cadMessagingGroupUnitByIdSelector = createSelector(
    cadUnitsSelector,
    dispatchAreasSelector,
    formatAttributeByIdSelector,
    (cadUnits, dispatchAreasAdmin, formatAttributeById) => (data) => {
        const output = _(data)
            .map((item) => {
                const unit = cadUnits[item.entityId];
                const dispatchArea = unit ? dispatchAreasAdmin[unit.dispatchAreaId] : null;
                return unit
                    ? {
                          callSign: unit.callSign,
                          id: unit.id,
                          dispatchArea: dispatchArea ? dispatchArea.name : null,
                          UnitType: formatAttributeById(unit.unitTypeAttrId),
                          Status: getAdminListStatusFromStartEnd(
                              unit.activeDateUtc,
                              unit.expirationDateUtc
                          ),
                      }
                    : null;
            })
            .sortBy('callSign')
            .value();
        return output;
    }
);

export const cadMessagingGroupUnitforSelectSelector = createSelector(
    cadUnitsSelector,
    (cadUnits) => {
        const output = [];
        Object.keys(cadUnits).forEach((key) => {
            output.push({
                value: key,
                display: cadUnits[key].callSign,
            });
        });

        return output;
    }
);

export const cadMessagingGroupEntitiesViewModelByIdSelector = createSelector(
    cadMessagingGroupsSelector,
    cadUnitsSelector,
    (cadMessagingGroups) => (id) => {
        return {
            Entities: cadMessagingGroups[id] ? cadMessagingGroups[id].members : undefined,
            GroupInfo: cadMessagingGroups[id] ? cadMessagingGroups[id] : undefined,
        };
    }
);

// list of cad MessagingGroups
export const messagingGroupsListItemsSelector = createSelector(
    cadMessagingGroupsSelector,
    uiSelector,
    cadMessagingGroupViewModelByIdSelector,
    routeNameSelector,
    getMiniUserByIdSelector,
    (cadMessagingGroups, ui, cadMessagingGroupViewModelById) => {
        return _(cadMessagingGroups)
            .map((messagingGroup) => {
                const path = `/admin/cad/cad-messaging-groups/${messagingGroup.id}`;
                return {
                    path,
                    title: messagingGroup.groupName,
                    subtitle: messagingGroup.description,
                    key: messagingGroup.id,
                    status: get(cadMessagingGroupViewModelById(messagingGroup.id), 'status'),
                    selected: messagingGroup.id === ui.selectedCadMessagingGroupId,
                };
            })
            .sortBy('title')
            .value();
    }
);

/**
 * Active if start date is before now, end date is null or later than now
 * @param  {Date}  startDateUtc  start date
 * @param  {Date}  endDateUtc    end date
 * @return {Boolean}            is active or not
 */
function isActive(startDateUtc, endDateUtc) {
    const now = new Date();
    const startDate = new Date(startDateUtc);
    return startDate < now && (!endDateUtc || new Date(endDateUtc) > now);
}

export const activeCadCallTakerStationsSelector = createSelector(
    cadCallTakerStationOptionsSelector,
    (stations) =>
        _.filter(stations, (station) => isActive(station.startDateUtc, station.endDateUtc))
);

import { noop } from 'lodash';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import createCard from '../../utils/createCard';

const baseCard = createCard({
    name: reportCardEnum.REPORT_STATUS_COMMENTS.name,
    baseSelector: (state) => state.ui.report.reportStatusCommentsCard,
    anchor: 'report-status-comments-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return noop;
        },
        save() {
            return noop;
        },
    },
};

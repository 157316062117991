import { filter, find, map } from 'lodash';
import { createSelector } from 'reselect';
import { NameItemLink, LinkTypesEnum } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import {
    parentAttributeIdByAttributeIdSelector,
    attributesWithParentAttributeIdSelector,
} from '../../../attributes/state/data';
import globalAttributes from '../../../../legacy-constants/globalAttributes';

export const NEXUS_STATE_PROP = 'nameItemLinks';
const nameItemLinksModule = createLinkModule<NameItemLink>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// ACTIONS

// SELECTORS
export const nameItemLinksSelector = nameItemLinksModule.selectors.linksSelector;
export const nameItemLinksWhereSelector = nameItemLinksModule.selectors.linksWhereSelector;

export const nameItemLinkByIdSelector = createSelector(
    nameItemLinksSelector,
    (nameItemLinks) => (nameItemLinkId: number) => find(nameItemLinks, { id: nameItemLinkId })
);

export const nameItemLinksByItemProfileIdSelector = createSelector(
    nameItemLinksSelector,
    (nameItemLinks) => (itemProfileId: number) => filter(nameItemLinks, { itemProfileId })
);

// REDUCER
export default nameItemLinksModule.reducerConfig;

/**
 * Previously, we were using linkTypesEnum to define the link type of associated names.
 * Now, we are using nameItemAssociations, which are global attributes.
 *
 * Until we stop supporting linkTypes for items, we'll need
 * to make things backwards compatible
 *
 */
export const parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrIdSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => ({
        linkType,
        nameItemAssociationAttrId,
    }: {
        linkType: typeof LinkTypesEnum.CLAIMED_BY | typeof LinkTypesEnum.OWNED_BY;
        nameItemAssociationAttrId: number;
    }) => {
        const legacyLinkTypeToParentNameItemAssociationAttrId = {
            [LinkTypesEnum.CLAIMED_BY]: globalAttributes.nameItemAssociationGlobal.claimant,
            [LinkTypesEnum.OWNED_BY]: globalAttributes.nameItemAssociationGlobal.owner,
        };

        return (
            parentAttributeIdByAttributeId(nameItemAssociationAttrId) ||
            legacyLinkTypeToParentNameItemAssociationAttrId[linkType]
        );
    }
);

export const ownerNameItemAttributeIdsSelector = createSelector(
    attributesWithParentAttributeIdSelector,
    (attributesWithParentAttributeId) =>
        map(
            attributesWithParentAttributeId(globalAttributes.nameItemAssociationGlobal.owner),
            (attribute) => attribute.id
        )
);

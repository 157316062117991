import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import noop from 'lodash/noop';
import uniq from 'lodash/uniq';
import { FilterMenuList, FilterMenuRenderProps, FilterOptionT, FilterSearchInput } from 'arc';
import { Attribute, AttributeTypeEnumType } from '@mark43/rms-api';

import { AttributeViewModel } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

import { logWarning } from '../../../../core/logging';
import { RmsDispatch } from '../../../../core/typings/redux';
import {
    attributeLoadingStateByAttributeTypeSelector,
    attributeOptionsByTypeForCurrentDepartmentSelector,
} from '../../attributes/state/ui';
import { loadAttributesForType } from '../../attributes/state/ui/loadAttributesForType';
import { computeAllowedAttributeTypesToLoad } from '../../attributes/utils/computeAllowedAttributeTypesToLoad';
import { SelectOption } from '../../forms/helpers/selectHelpers';

export const convertAttributeToAppliedOption = (item: { attributeId: number; label: string }) => {
    return {
        id: item.attributeId,
        display: item.label,
        label: item.label,
        value: item.attributeId.toString(),
    };
};

type FilterOption = SelectOption & FilterOptionT;

export const AttributeSelectFilter: React.FC<
    FilterMenuRenderProps & {
        attributeType: AttributeTypeEnumType | AttributeTypeEnumType[];
        includeExpired?: boolean;
        filterOptions?: (options: FilterOption[]) => FilterOption[];
        onAttributeLoadSuccess?: (attributes: Attribute[]) => void;
        predicate?: (attribute: AttributeViewModel & { departmentId?: number }) => boolean;
    }
> = ({
    appliedOptions,
    setAppliedFilters,
    selectionType,
    attributeType,
    includeExpired = false,
    filterOptions,
    onAttributeLoadSuccess,
    predicate,
}) => {
    const dispatch = useDispatch<RmsDispatch>();
    const attributeLoadingStateByAttributeType = useSelector(
        attributeLoadingStateByAttributeTypeSelector
    );
    const attributeOptionsByTypeForCurrentDepartment = useSelector(
        attributeOptionsByTypeForCurrentDepartmentSelector
    );
    const attributeLoadingState = attributeLoadingStateByAttributeType(attributeType);

    const [query, setQuery] = useState('');
    const [attributeIds, setAttributeIds] = useState<number[]>([]);

    const getOptions = () => {
        const rawOptions = attributeOptionsByTypeForCurrentDepartment({
            type: attributeType as AttributeTypeEnumType,
            includeExpired,
            additionalIds: attributeIds,
            includeAbbr: false,
            predicate,
        });

        const departmentIds = rawOptions.map((item) => item.departmentId);
        const unique = uniq(departmentIds);
        if (unique.length > 1) {
            logWarning('Attributes from multiple departments found in attribute select', {
                attributeType,
                departmentIds: unique,
                attributeIds: rawOptions.map((item) => item.value),
            });
        }

        const options = rawOptions.map((item) => ({
            ...item,
            id: item.value,
            label: item.display,
            value: item.value.toString(),
            group: undefined,
        }));

        const filteredOptions = filterOptions ? filterOptions(options) : options;

        return filteredOptions.slice(0, 100);
    };

    useEffect(() => {
        const attributeTypesToLoad = computeAllowedAttributeTypesToLoad(attributeLoadingState);
        if (attributeTypesToLoad.length) {
            dispatch(loadAttributesForType({ attributeType: attributeTypesToLoad }))
                .then((attributes) => {
                    onAttributeLoadSuccess?.(attributes);
                })
                .catch(noop);
        }
    }, [dispatch, attributeLoadingState, onAttributeLoadSuccess]);

    const handleSelect = useCallback(
        (item: FilterOption) => {
            setAttributeIds((prev) => uniq([...prev, Number(item.id)]));
            setAppliedFilters(item);
        },
        [setAppliedFilters]
    );

    return (
        <>
            <FilterSearchInput onChange={(e) => setQuery(e.target.value)} />
            <FilterMenuList
                query={query}
                options={getOptions()}
                appliedOptions={appliedOptions}
                // @ts-expect-error Need to fix this in arc https://github.com/mark43/arc/pull/1003/files#diff-f25cacce0fbf0f3ad943bfd626d3306fd8eca5e9ab5aa3307f7dbb1e3a17d01bR10
                onSelect={handleSelect}
                selectionType={selectionType}
            />
        </>
    );
};

import { find, intersection, map, pick, reduce, some } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LinkTypesEnum, PersonProfile, SearchResultElasticReport } from '@mark43/rms-api';

import getPersonProfileResource from '~/client-common/core/domain/person-profiles/resources/personProfileResource';
import { personProfileByIdSelector } from '~/client-common/core/domain/person-profiles/state/data';
import {
    formatFullName,
    isUnknown,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { useResource } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { RmsDispatch } from '../../../../../core/typings/redux';
import { arrestReportDefinitionForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';
import {
    createEmbeddedArrestReport,
    currentReportSupportsEmbeddingSelector,
    linkedArrestsSelector,
} from '../../state/ui';
import { currentReportRENSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import { useOverlayStore } from '../../../../core/overlays/hooks/useOverlayStore';
import testIds from '../../../../../core/testIds';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import AddArrestReportModal, { AddArrestReportModalProps } from './AddArrestReportModal';

const strings = componentStrings.reports.core.AddArrestReportButton;

const AddArrestReportButton: React.FC<{
    defendantId: number;
    arrestReportDefinitionName: string;
}> = ({ defendantId, arrestReportDefinitionName }) => {
    const dispatch: RmsDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const overlayStore = useOverlayStore();
    const reportingEventNumber = useSelector(currentReportRENSelector);
    const personProfileById = useSelector(personProfileByIdSelector);
    const [modalProps, setModalProps] = React.useState<AddArrestReportModalProps>();

    const onAddArrestReportClick = React.useCallback(() => {
        const masterPersonId = personProfileById(defendantId)?.masterPersonId;

        setIsLoading(true);
        if (masterPersonId) {
            // check whether the given suspect is already a defendant in any draft arrest reports within this REN
            elasticSearchResource
                .searchReports({
                    reportingEventNumber,
                    involvedPersons: [
                        {
                            involvement: LinkTypesEnum.DEFENDANT_IN_ARREST,
                            person: {
                                masterPersonId,
                            },
                        },
                    ],
                })
                // @ts-expect-error TODO: type the resource method
                .then(({ items, totalCount }: SearchResultElasticReport) => {
                    if (totalCount === 0) {
                        // Case 1: When the suspect is not a defendant in any linked arrest, create an arrest report and
                        // embed it into the current report.
                        dispatch(createEmbeddedArrestReport(defendantId))
                            .then(() => {
                                setModalProps(undefined);
                                setIsLoading(false);
                            })
                            .catch((err: Error) => {
                                setModalProps({
                                    id: 'ERROR',
                                    errorMessage: err.message,
                                });
                                setIsLoading(false);
                            });
                    } else {
                        const defendantName: string | undefined = reduce(
                            items,
                            // @ts-expect-error client-common to client RND-7529
                            (fullName, item) => {
                                if (fullName) {
                                    return fullName;
                                }
                                const involvedPerson = find(
                                    item.involvedPersons,
                                    ({ person }) => person.masterPersonId === masterPersonId
                                );
                                if (involvedPerson) {
                                    return formatFullName(involvedPerson.person);
                                }
                            },
                            undefined
                        );
                        if (totalCount === 1) {
                            // Case 2: When the suspect is already a defendant in 1 linked arrest, display a modal that
                            // allows the user to embed the arrest report into the current report.
                            overlayStore.open('ONE_LINKED_ARREST');
                            setModalProps({
                                id: 'ONE_LINKED_ARREST',
                                defendantId,
                                defendantName,
                                report: pick(items[0], ['id', 'shortTitle']),
                                arrestReportDefinitionName,
                            });
                        } else {
                            // Case 3: When the suspect is already a defendant in multiple linked arrests, display a
                            // warning modal with no further action.
                            setModalProps({
                                id: 'MULTIPLE_LINKED_ARRESTS',
                                defendantName,
                                reports: map(items, (item) => pick(item, ['id', 'shortTitle'])),
                                arrestReportDefinitionName,
                            });
                        }
                        setIsLoading(false);
                    }
                })
                .catch((err: Error) => {
                    setModalProps({
                        id: 'ERROR',
                        errorMessage: `${strings.searchErrorMessage} ${err.message}`,
                    });
                    setIsLoading(false);
                });
        } else {
            // this code path is supposed to be unreachable
            setModalProps({
                id: 'ERROR',
                errorMessage: strings.noMasterPersonIdErrorMessage,
            });
            setIsLoading(false);
        }
    }, [
        arrestReportDefinitionName,
        defendantId,
        dispatch,
        overlayStore,
        personProfileById,
        reportingEventNumber,
        setModalProps,
    ]);

    return (
        <>
            <Button
                testId={testIds.ADD_ARREST_REPORT_BUTTON}
                className={buttonTypes.SECONDARY}
                onClick={onAddArrestReportClick}
                disabled={isLoading}
            >
                {strings.addArrestReport(arrestReportDefinitionName)}
            </Button>
            <AddArrestReportModal modalProps={modalProps} setModalProps={setModalProps} />
        </>
    );
};

const LOADING = 'LOADING';

// nice to have: hydrated report endpoint to return linked arrest person profiles.
// if we can get this data from the payload, can replace this hook with redux/reselect selector.
// there is added risk of othe FE code not properly ignoring this extra data.
function useIsDefendantOfLinkedArrestReport(
    masterPersonId: number | undefined,
    hasHideConditions: boolean
): undefined | typeof LOADING | boolean {
    const needsToLoad = !hasHideConditions && !!masterPersonId;
    const personProfileResource = getPersonProfileResource();
    const [isDefendantOfLinkedArrest, setIsDefendantOfLinkedArrest] = useState<boolean | undefined>(
        undefined
    );
    const [skipResource, setSkipResource] = useState(!needsToLoad);
    const arrests = useSelector(linkedArrestsSelector);

    const resource = useCallback(() => {
        if (skipResource) {
            return Promise.resolve([]);
        }
        return personProfileResource
            .getAllContextedProfilesForMasterProfile(masterPersonId)
            .catch((err: Error) => {
                // ignoring error. still allow user to add arrest if this resource errors.
                setIsDefendantOfLinkedArrest(true);
                setSkipResource(true);
                throw err;
            });
    }, [masterPersonId, personProfileResource, skipResource]);

    const onSuccess = useCallback(
        (contextedPersonProfiles: PersonProfile[]) => {
            if (skipResource) {
                return;
            }

            const arrestsDefendantIds = map(arrests, 'defendantId');
            const contextedPersonProfileIds = map(contextedPersonProfiles, 'id');
            const arrestsDefendantIdsLinkedByContextedProfile = intersection(
                arrestsDefendantIds,
                contextedPersonProfileIds
            );

            setIsDefendantOfLinkedArrest(arrestsDefendantIdsLinkedByContextedProfile.length > 0);
            setSkipResource(true);
        },
        [arrests, skipResource]
    );

    // not using the `isLoading` boolean from this hook because it doesn't immediately initialize as true
    useResource(resource, onSuccess);

    if (skipResource) {
        return undefined;
    } else if (typeof isDefendantOfLinkedArrest === 'undefined') {
        return LOADING;
    } else {
        return isDefendantOfLinkedArrest;
    }
}

function useAddArrestReportButtonIsHidden(suspectId: number): typeof LOADING | boolean {
    const currentReportSupportsEmbedded = useSelector(currentReportSupportsEmbeddingSelector);
    const personProfileById = useSelector(personProfileByIdSelector);

    const suspectPersonProfile = personProfileById(suspectId);
    const isDefendantUnknown = isUnknown({
        firstName: suspectPersonProfile?.firstName,
        lastName: suspectPersonProfile?.lastName,
    });

    const arrests = useSelector(linkedArrestsSelector);
    // only embedded arrest reports include contexted person profiles in client state.
    // if there are no embedded arrest reports with same suspect/defendant, fall back to
    // useIsDefendantOfLinkedArrestReport to check all contexted profiles.
    const isDefendantOfEmbeddedArrest = some(
        arrests,
        (arrest) =>
            personProfileById(arrest.defendantId)?.masterPersonId ===
            suspectPersonProfile?.masterPersonId
    );

    // when there exists an arrest without assigned defendant, let the user first assign
    // a defendant to it then it will be clear if this suspect can be arrested
    const isExistsArrestWithoutDefendant = some(arrests, (arrest) => !arrest.defendantId);

    const isArrestButtonHidden =
        !currentReportSupportsEmbedded ||
        isDefendantUnknown ||
        isDefendantOfEmbeddedArrest ||
        isExistsArrestWithoutDefendant;

    const isDefendantOfLinkedArrest = useIsDefendantOfLinkedArrestReport(
        suspectPersonProfile?.masterPersonId,
        isArrestButtonHidden
    );

    return typeof isDefendantOfLinkedArrest === 'undefined'
        ? isArrestButtonHidden
        : isDefendantOfLinkedArrest;
}

const AddArrestReportButtonWrapper: React.FC<{ defendantId: number }> = ({ defendantId }) => {
    const arrestReportDefinition = useSelector(arrestReportDefinitionForCurrentDepartmentSelector);
    const isHidden = useAddArrestReportButtonIsHidden(defendantId);

    if (!arrestReportDefinition || isHidden === true || isHidden === LOADING) {
        return null;
    }

    return (
        <AddArrestReportButton
            defendantId={defendantId}
            arrestReportDefinitionName={arrestReportDefinition.name}
        />
    );
};

export default AddArrestReportButtonWrapper;

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../core/testIds';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { submitCreateWarrantModal } from '../state/ui';
import { createModalSelector } from '../../../core/box/state/ui';
import CreateWarrantForm from './CreateWarrantForm';

const context = {
    name: boxEnum.CREATE_WARRANT_MODAL,
};
const strings = componentStrings.warrants.warrant.CreateWarrantModal;

const mapStateToProps = createStructuredSelector({
    existingWarrantIds: createModalSelector(context, 'existingWarrantIds'),
});

const mapDispatchToProps = (dispatch, props) => ({
    submitNewWarrant: () => dispatch(submitCreateWarrantModal({ router: props.router })),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(function CreateWarrantModal({ submitNewWarrant, existingWarrantIds }) {
    return (
        <Modal
            context={context}
            title={strings.title}
            onSave={submitNewWarrant}
            okText={strings.okText}
            saveDisabled={existingWarrantIds && existingWarrantIds.length !== 0}
            testId={testIds.CREATE_WARRANT_MODAL}
        >
            <CreateWarrantForm />
        </Modal>
    );
});

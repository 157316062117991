import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { EntityTypeEnum } from '@mark43/rms-api';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import strings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { canWrite } from '~/client-common/core/domain/entity-permissions/state/ui';
import { cautionsByEntitySelector } from '~/client-common/core/domain/cautions/state/data';

import { useActiveTargetCases } from '../../core/components/EntityProfileActiveTarget/ActiveTargetCasesContext';
import Header from '../../core/components/header/EntityProfileHeader';
import HeaderMasterName from '../../core/components/header/EntityProfileHeaderMasterName';
import HeaderSecondaryInfoSection from '../../core/components/header/EntityProfileHeaderSecondaryInfoSection';
import HeaderSecondaryInfo from '../../core/components/header/EntityProfileHeaderSecondaryInfo';
import HeaderCautions from '../../core/components/header/EntityProfileHeaderCautions';
import HeaderMenu from '../../core/components/header/EntityProfileHeaderMenu';
import { PersonLabels } from '../../../core/components/tags/PersonLabels';
import ExternalDepartmentNameSecondaryHeaderInfo from '../../core/components/header/ExternalDepartmentSecondaryHeaderInfo';
import { PersonSidePanel } from '../../../core/persons/components/PersonSidePanel';
import { AddPersonCautionButton } from '../../../core/cautions/components/AddPersonCautionButton';
import { PersonEnhancedCautions } from '../../../core/cautions/components/PersonEnhancedCautions';
import { useAbilitySelector } from '../../../core/current-user/hooks/useAbilitySelector';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import {
    personHeaderDataSelector,
    currentEntityProfileIdSelector,
    currentEntityProfileOwnerContextSelector,
    currentEntityProfileIsMasterProfileSelector,
    currentEntityProfileDepartmentIdSelector,
    currentEntityProfileIsEditableSelector,
    currentEntityProfileUserOperationTypesSelector,
} from '../../core/state/ui';

const headerStrings = strings.entityProfiles.EntityProfilePersonHeader;

function EntityProfilePersonHeader({
    personHeaderData,
    entityId,
    ownerContext,
    isMaster,
    ownerDepartmentName,
    isHidden,
    isEditable,
    currentEntityProfileDepartmentId,
    currentUserDepartmentId,
    userOperationTypes,
    cautionsByEntity,
}) {
    const cautions = cautionsByEntity(EntityTypeEnum.PERSON_PROFILE.name, entityId);

    const { isActiveTarget } = useActiveTargetCases();
    const hasEditAbility = useAbilitySelector(abilitiesEnum.CORE.EDIT_GENERAL);

    const cautionTags = !personHeaderData ? undefined : (
        <PersonLabels
            nameAttributes={personHeaderData.personLabelNameAttributes}
            isJuvenile={personHeaderData.isJuvenile}
            isVulnerable={personHeaderData.isVulnerable}
            dateVulnerableTo={personHeaderData.dateVulnerableTo}
            hasPotentialActiveWarrant={personHeaderData.hasPotentialActiveWarrant}
            isSuspectedGangMember={personHeaderData.isSuspectedGangMember}
            isActiveTarget={isActiveTarget}
            size={'md'}
        />
    );

    const nameLinkProps = {
        entityId,
        ownerId: entityId,
        ownerType: get(ownerContext, 'ownerType'),
    };

    const isExternalProfile = currentUserDepartmentId !== currentEntityProfileDepartmentId;
    const hasEditPermissions = canWrite(userOperationTypes);

    const canEditCautions =
        !isExternalProfile && isEditable && hasEditPermissions && hasEditAbility;

    const addPersonCautionButton = canEditCautions ? (
        <AddPersonCautionButton isEditingMasterProfile={isMaster} {...nameLinkProps} />
    ) : null;

    return !personHeaderData ? (
        <div />
    ) : (
        <Header>
            {isHidden ? (
                // Need to still pass `editProfile` because existance of this prop
                // alters the view (and we want the view to be identical between
                // hidden / visible
                <HeaderMenu
                    editProfileSidePanel={(renderEditButton) => renderEditButton({})}
                    entityName={personHeaderData.fullName}
                    isHidden={true}
                />
            ) : (
                <HeaderMenu
                    editProfileSidePanel={(renderEditButton) => (
                        <PersonSidePanel
                            overlayId={overlayIdEnum.PERSON_OVERLAY_ENTITY_PROFILE_PERSON_HEADER}
                            isEditingMasterProfile={isMaster}
                            renderButton={({ overlayBase: { open }, ...rest }) =>
                                renderEditButton({ open, ...rest })
                            }
                            {...nameLinkProps}
                        />
                    )}
                    entityName={personHeaderData.fullName}
                />
            )}
            <HeaderMasterName>{personHeaderData.fullName}</HeaderMasterName>
            <HeaderSecondaryInfoSection>
                <HeaderSecondaryInfo
                    label={personHeaderData.dob ? headerStrings.dob : headerStrings.ageRange}
                >
                    {personHeaderData.dob || personHeaderData.ageRange}
                </HeaderSecondaryInfo>
                <HeaderSecondaryInfo label={headerStrings.nickname}>
                    {personHeaderData.nicknames}
                </HeaderSecondaryInfo>
                <ExternalDepartmentNameSecondaryHeaderInfo
                    ownerDepartmentName={ownerDepartmentName}
                />
            </HeaderSecondaryInfoSection>
            <HeaderCautions>
                <FeatureFlagged
                    flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={cautionTags}
                >
                    <PersonEnhancedCautions
                        cautions={cautions}
                        entityId={entityId}
                        showInactiveIndicator
                        showTooltips
                        renderAddButton={addPersonCautionButton}
                        isJuvenile={personHeaderData.isJuvenile}
                        isVulnerable={personHeaderData.isVulnerable}
                        dateVulnerableTo={personHeaderData.dateVulnerableTo}
                        hasPotentialActiveWarrant={personHeaderData.hasPotentialActiveWarrant}
                        isSuspectedGangMember={personHeaderData.isSuspectedGangMember}
                        isActiveTarget={isActiveTarget}
                    />
                </FeatureFlagged>
            </HeaderCautions>
        </Header>
    );
}

const mapStateToProps = createStructuredSelector({
    personHeaderData: personHeaderDataSelector,
    entityId: currentEntityProfileIdSelector,
    ownerContext: currentEntityProfileOwnerContextSelector,
    isMaster: currentEntityProfileIsMasterProfileSelector,
    currentEntityProfileDepartmentId: currentEntityProfileDepartmentIdSelector,
    isEditable: currentEntityProfileIsEditableSelector,
    userOperationTypes: currentEntityProfileUserOperationTypesSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    cautionsByEntity: cautionsByEntitySelector,
});

export default connect(mapStateToProps)(EntityProfilePersonHeader);

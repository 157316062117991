import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import * as fields from '~/client-common/core/enums/universal/fields';
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatAttributeLinkViewModels } from '~/client-common/core/domain/attributes/state/ui';
import { reportAttributeViewModelsWhereSelector } from '~/client-common/core/domain/report-attributes/state/ui';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import testIds from '../../../../../core/testIds';

export default connect(
    createStructuredSelector({
        formatAttributeById: formatAttributeByIdSelector,
        formatAttributeWithOther: formatAttributeWithOtherSelector,
        reportAttributeViewModelsWhere: reportAttributeViewModelsWhereSelector,
    })
)(function MissingPersonsCardSummary({
    reportId,
    missingPerson,
    formatAttributeById,
    formatAttributeWithOther,
    reportAttributeViewModelsWhere,
}) {
    const {
        lastContactDateUtc,
        missingPersonCriticalityAttrId,
        missingPersonCriticalityOther,
        missingPersonTypeAttrId,
        missingPersonTypeOther,
        closureStatusAttrId,
        closureStatusDateUtc,
    } = missingPerson;

    const missingPersonAdditionalInfoDisplay = formatAttributeLinkViewModels(
        reportAttributeViewModelsWhere({
            reportId,
            attributeType: AttributeTypeEnum.MISSING_PERSON_ADDITIONAL_INFO.name,
        })
    );

    return (
        <div>
            <CardSection testId={testIds.CARD_SUMMARY}>
                <SummaryList labelWidth={220} contentWidth={320}>
                    <SummaryRowDate
                        date={lastContactDateUtc}
                        fieldName={fields.MISSING_PERSON_LAST_CONTACT_DATE_UTC}
                    />
                    <SummaryRow fieldName={fields.MISSING_PERSON_MISSING_PERSON_TYPE_ATTR_ID}>
                        {missingPersonTypeAttrId &&
                            formatAttributeWithOther({
                                attributeId: missingPersonTypeAttrId,
                                other: missingPersonTypeOther,
                            })}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.MISSING_PERSON_MISSING_PERSON_CRITICALITY_ATTR_ID}
                    >
                        {missingPersonCriticalityAttrId &&
                            formatAttributeWithOther({
                                attributeId: missingPersonCriticalityAttrId,
                                other: missingPersonCriticalityOther,
                            })}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.MISSING_PERSON_CLOSURE_STATUS_ATTR_ID}>
                        {closureStatusAttrId && formatAttributeById(closureStatusAttrId)}
                    </SummaryRow>
                    <SummaryRowDate
                        date={closureStatusDateUtc}
                        fieldName={fields.MISSING_PERSON_CLOSURE_STATUS_DATE_UTC}
                    />
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_MISSING_PERSON_ADDITIONAL_INFO_ATTRIBUTE_ID
                        }
                    >
                        {missingPersonAdditionalInfoDisplay}
                    </SummaryRow>
                </SummaryList>
            </CardSection>
        </div>
    );
});

import { ChainEventType, ElasticEntityPermission } from '@mark43/evidence-api';
import { OperationTypeEnum } from '@mark43/rms-api';
import { at, keyBy } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inPoliceCustodyChainEventTypeSelector } from '~/client-common/core/domain/chain-event-types/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { userRolesSelector } from '~/client-common/core/domain/user-roles/state/data';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { abilitiesEnum, OnlyWithAbility } from '../../../../../../core/abilities';

import testIds from '../../../../../../../core/testIds';
import { Button } from '../../../../../../core/components/Button';
import { ConditionalTooltip } from '../../../../../../core/components/tooltip';
import { hasEvidenceLocationPermission } from '../../../../../core/utils/evidenceItemsHelpers';
import { addToItemQueueWithEvidenceItems } from '../../../../../item-queue/state/ui';
import { evidenceDashboardSearch } from '../../../../state/ui';
import type { ManageEvidenceViewModel } from '../../../../types';

const strings = componentStrings.evidence.dashboard.EvidenceDashboardSearchResults;

export const AddToBasketAction = () => {
    const results: ManageEvidenceViewModel[] = useSelector(
        // @ts-expect-error Need to Type the createSearchModule abstraction
        evidenceDashboardSearch.selectors.currentResultsViewModelsSelector
    );
    const dispatch = useDispatch();
    const userRoles = useSelector(userRolesSelector);
    // TODO: When `createSearchModule` is typed, remove number[]
    const selectedRows: number[] = useSelector(
        // @ts-expect-error Need to Type the createSearchModule abstraction
        evidenceDashboardSearch.selectors.selectedRowsSelector
    );
    const selectedResults = at(results, selectedRows);
    const inPoliceCustodyChainEventType = useSelector(
        inPoliceCustodyChainEventTypeSelector
    ) as ChainEventType;
    const applicationSettings = useSelector(applicationSettingsSelector);
    const useEvdPerms = applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED;
    const userRolesById = keyBy(userRoles, (role) => role.roleId);
    const addToItemQueue = (masterItemIds: number[]) => {
        return dispatch(addToItemQueueWithEvidenceItems(masterItemIds));
    };

    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];

    const evidenceLocationPermsSet = selectedResults.reduce<ElasticEntityPermission[][]>(
        (acc, item) => {
            if (item.currentChainEvent) {
                const { evidenceLocationPermissions, facilityId } = item.currentChainEvent[0];
                // only return a permission set if the item is currently in Facility or Storage location
                if (facilityId && evidenceLocationPermissions) {
                    acc.push(evidenceLocationPermissions);
                }
            }
            return acc;
        },
        []
    );

    // cannot take evidence actions on any item that is still in police custody
    // (same as CPS)
    const someItemIsInPoliceCustody = selectedResults
        .map((item) => item.currentChainEvent[0].eventTypeId)
        .includes(inPoliceCustodyChainEventType.id);

    const addToQueueDisabled =
        someItemIsInPoliceCustody ||
        (useEvdPerms &&
            !hasEvidenceLocationPermission(
                evidenceLocationPermsSet,
                userRolesById,
                OperationTypeEnum.MANAGE.name
            ));

    return (
        <OnlyWithAbility has={abilitiesEnum.EVIDENCE.ITEM_QUEUE}>
            <ConditionalTooltip
                condition={!!addToQueueDisabled}
                content={
                    someItemIsInPoliceCustody
                        ? strings.actionButtons.disabledAddToQueueTooltip(custodyLabel)
                        : strings.actionButtons.noManagePermissionTooltip
                }
            >
                <Button
                    isTextTransformNone
                    leadingVisual="Property"
                    onClick={() => {
                        addToItemQueue(selectedResults.map((result) => result.masterItemId));
                    }}
                    disabled={!!addToQueueDisabled}
                    testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_ADD_TO_QUEUE}
                >
                    {strings.actionButtons.addToQueue}
                </Button>
            </ConditionalTooltip>
        </OnlyWithAbility>
    );
};

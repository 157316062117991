import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map, get, indexOf, noop } from 'lodash';
import { compose, withHandlers } from 'recompose';
import classNames from 'classnames';
import { isUnknown } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { defaultProfileImagePath } from '~/client-common/helpers/profileImageHelpers';
import { entityTypeParamToEnum } from '~/client-common/helpers/entityProfileHelpers';
import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';

import testIds from '../../../../../core/testIds';

import {
    profileImageSizes,
    PersonProfileImage,
    OrganizationProfileImage,
} from '../../../../core/components/ProfileImage';
import { BottomPositionedDeceasedLabel } from '../../../../core/persons/components/DeceasedLabel';
import { openImageGalleryLightbox } from '../../../../../legacy-redux/actions/imageGalleryLightboxActions';

import {
    profileImagePathSelector,
    personDetailsViewModelSelector,
    propertyDetailsSelector,
    vehicleDetailsSelector,
    organizationProfileDetailsSelector,
} from '../../state/ui';

const ImageContainer = styled.div`
    position: relative;
    margin: 22px auto 37px;
    width: ${profileImageSizes.ENTITY_PROFILE};
    height: ${profileImageSizes.ENTITY_PROFILE};
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    overflow: hidden;

    &.has-avatar {
        cursor: pointer;
    }
`;

const _EntityProfileImage = ({
    entityType,
    profileImagePath,
    personDetailsViewModel,
    onProfileImageClick,
}) => {
    const mappedEntityType = entityTypeParamToEnum[entityType];
    const hasDefaultAvatar = profileImagePath === defaultProfileImagePath(mappedEntityType);
    const className = classNames('clearfix', {
        'has-avatar': !hasDefaultAvatar,
    });

    let profileImage = null;

    switch (mappedEntityType) {
        case EntityTypeEnum.PERSON_PROFILE.name:
            const isDeceased = get(personDetailsViewModel, 'isDead');
            profileImage = (
                <PersonProfileImage
                    // If the view model is not yet defined, don't set it as unknown
                    // This prevents the initial flash on page load of the unknown icon,
                    // because this component gets rendered before the `personDetailsViewModel`
                    // data has been pulled in via API
                    isUnknown={personDetailsViewModel && isUnknown(personDetailsViewModel)}
                    url={!hasDefaultAvatar ? profileImagePath : undefined}
                    noBorder={true}
                    children={isDeceased && <BottomPositionedDeceasedLabel isLarge={true} />}
                />
            );
            break;
        case EntityTypeEnum.ORGANIZATION_PROFILE.name:
            profileImage = (
                <OrganizationProfileImage
                    url={!hasDefaultAvatar ? profileImagePath : undefined}
                    noBorder={true}
                />
            );
            break;
        default:
            profileImage = <ImgWithExifOrientation src={profileImagePath} />;
    }
    return (
        <ImageContainer
            onClick={hasDefaultAvatar ? noop : onProfileImageClick}
            className={className}
            data-test-id={testIds.ENTITY_PROFILE_IMAGE}
        >
            {profileImage}
        </ImageContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    personDetailsViewModel: personDetailsViewModelSelector,
    profileImagePath: profileImagePathSelector,
    propertyDetails: propertyDetailsSelector,
    vehicleDetails: vehicleDetailsSelector,
    organizationProfileDetails: organizationProfileDetailsSelector,
});

export default compose(
    connect(mapStateToProps, {
        openImageGalleryLightbox,
    }),
    withHandlers({
        onProfileImageClick: ({
            openImageGalleryLightbox,
            personDetailsViewModel,
            propertyDetails,
            vehicleDetails,
            organizationProfileDetails,
        }) => {
            if (get(personDetailsViewModel, 'profileImages.length')) {
                return () => openImageGalleryLightbox(personDetailsViewModel.profileImages, 0);
            } else if (get(organizationProfileDetails, 'profileImages.length')) {
                return () => openImageGalleryLightbox(organizationProfileDetails.profileImages, 0);
            } else if (get(propertyDetails, 'profileImageId')) {
                const index = indexOf(
                    map(propertyDetails.profileAttachments, 'id'),
                    propertyDetails.profileImageId
                );
                return () => openImageGalleryLightbox(propertyDetails.profileAttachments, index);
            } else if (get(vehicleDetails, 'profileImageId')) {
                const index = indexOf(
                    map(propertyDetails.profileAttachments, 'id'),
                    propertyDetails.profileImageId
                );
                return () => openImageGalleryLightbox(vehicleDetails.profileAttachments, index);
            } else {
                return noop;
            }
        },
    })
)(_EntityProfileImage);

import { DateRangeQuery, ValueTypeEnum, ValueTypeEnumType } from '@mark43/rms-api';

export function isDateRangeQuery(value: unknown): value is DateRangeQuery {
    return (
        typeof value === 'object' &&
        !!value &&
        ('startDateUtc' in value ||
            'endDateUtc' in value ||
            'toDatePeriod' in value ||
            'withinLastPeriod' in value)
    );
}

export function isValueType(value: string): value is ValueTypeEnumType {
    return value in ValueTypeEnum;
}

import { get } from 'lodash';
import { currentUserDepartmentProfileSelector } from '../../core/current-user/state/ui';
import { inventoriesDashboardSearch } from './state/ui';
import inventoriesDashboardSearchForm from './state/forms/inventoriesDashboardSearchForm';

// eslint-disable-next-line no-unused-vars
export function onEnter() {
    const departmentId = get(currentUserDepartmentProfileSelector(this.getState()), 'departmentId');
    this.dispatch(
        inventoriesDashboardSearchForm.actionCreators.changePath('departmentIds', [departmentId])
    );

    this.dispatch(
        inventoriesDashboardSearch.actionCreators.search(
            {
                formData: inventoriesDashboardSearchForm.selectors.formModelSelector(
                    this.getState()
                ),
            },
            {
                cacheBust: true,
                scroll: false,
            }
        )
    );
}

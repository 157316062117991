import {
    ElasticInvolvedLocation,
    LocationView,
    ConsolidatedLocationView,
    LocationEntityLink,
} from '@mark43/rms-api';
import { joinTruthyValues } from '../../../../helpers/stringHelpers';

type LocationWithSubPremises =
    | LocationView
    | ConsolidatedLocationView
    | LocationEntityLink
    | ElasticInvolvedLocation;

export type LocationSubPremises = {
    subPremise1Name?: string;
    subPremise1Value?: string;
    subPremise2Name?: string;
    subPremise2Value?: string;
    subPremise3Name?: string;
    subPremise3Value?: string;
    subPremise4Name?: string;
    subPremise4Value?: string;
    subPremise5Name?: string;
    subPremise5Value?: string;
};

export type LocationSubPremiseFormValue = {
    subPremiseName: string;
    subPremiseValue: string;
};

export function joinSubPremises({
    subPremise1Name,
    subPremise1Value,
    subPremise2Name,
    subPremise2Value,
    subPremise3Name,
    subPremise3Value,
    subPremise4Name,
    subPremise4Value,
    subPremise5Name,
    subPremise5Value,
}: LocationSubPremises): string {
    const subPremises = [
        [subPremise1Name, subPremise1Value],
        [subPremise2Name, subPremise2Value],
        [subPremise3Name, subPremise3Value],
        [subPremise4Name, subPremise4Value],
        [subPremise5Name, subPremise5Value],
    ].map((pair) => joinTruthyValues(pair, ' '));

    return joinTruthyValues(subPremises, ', ');
}

export function spreadSubPremises(subPremises: LocationSubPremiseFormValue[]): LocationSubPremises {
    const base: LocationSubPremises = {};
    return subPremises
        .filter(
            ({ subPremiseName, subPremiseValue }) =>
                subPremiseName !== undefined || subPremiseValue !== undefined
        )
        .reduce(
            (result, { subPremiseName, subPremiseValue }, index) => ({
                ...result,
                [`subPremise${index + 1}Name`]: subPremiseName,
                [`subPremise${index + 1}Value`]: subPremiseValue,
            }),
            base
        );
}

const isElasticInvolvedLocation = (
    location: LocationWithSubPremises
): location is ElasticInvolvedLocation => {
    if ('locationAddress' in location) {
        return true;
    }
    return false;
};

export const formatSubPremise = (location: LocationWithSubPremises) => {
    if (!isElasticInvolvedLocation(location)) {
        const {
            subPremise1Name,
            subPremise1Value,
            subPremise2Name,
            subPremise2Value,
            subPremise3Name,
            subPremise3Value,
            subPremise4Name,
            subPremise4Value,
            subPremise5Name,
            subPremise5Value,
        } = location;

        return joinSubPremises({
            subPremise1Name,
            subPremise1Value,
            subPremise2Name,
            subPremise2Value,
            subPremise3Name,
            subPremise3Value,
            subPremise4Name,
            subPremise4Value,
            subPremise5Name,
            subPremise5Value,
        });
    }

    const {
        involvedLocationSubPremise1Name: subPremise1Name,
        involvedLocationSubPremise1Value: subPremise1Value,
        involvedLocationSubPremise2Name: subPremise2Name,
        involvedLocationSubPremise2Value: subPremise2Value,
        involvedLocationSubPremise3Name: subPremise3Name,
        involvedLocationSubPremise3Value: subPremise3Value,
        involvedLocationSubPremise4Name: subPremise4Name,
        involvedLocationSubPremise4Value: subPremise4Value,
        involvedLocationSubPremise5Name: subPremise5Name,
        involvedLocationSubPremise5Value: subPremise5Value,
    } = location;

    return (
        joinSubPremises({
            subPremise1Name,
            subPremise1Value,
            subPremise2Name,
            subPremise2Value,
            subPremise3Name,
            subPremise3Value,
            subPremise4Name,
            subPremise4Value,
            subPremise5Name,
            subPremise5Value,
        }) || location.subPremise
    );
};

import { formEvents } from 'markformythree';

const mftArbiterValidationEvents = [
    {
        eventType: formEvents.INPUT_CHANGE,
    },
    {
        eventType: formEvents.INPUT_BLUR,
    },
    {
        eventType: formEvents.FORM_MOUNT,
    },
    {
        eventType: formEvents.FORM_SUBMIT,
    },
    {
        eventType: formEvents.N_ITEM_ADDED,
    },
    {
        eventType: formEvents.N_ITEM_REMOVED,
    },
];

export default mftArbiterValidationEvents;

import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import { gateRoute } from '../../routing/protectRoutes';
import routesConfig from '../../routing/routesConfig';
import {
    EFileContainer,
    EFileSummaryPage,
    EFileDefendantsPage,
    EFileMaterialsPage,
    EFileMaterialItemsPage,
    EFileMaterialAttachmentsPage,
    EFileDefendantPersonPage,
    EFileDefendantOrganizationPage,
    EFileTasksPage,
} from './core/components';

export const eFileRoutes = gateRoute(
    {
        featureFlag: 'RMS_E_FILING_ENABLED',
        ability: abilitiesEnum.EFILES.VIEW,
    },
    <Route {...routesConfig.E_FILES} path="/e-file/:eFileId" component={EFileContainer}>
        <IndexRedirect to="summary" />
        <Route path="summary" component={EFileSummaryPage} />
        <Route path="defendants">
            <IndexRoute component={EFileDefendantsPage} />
            <Route path=":contextedNameId/person" component={EFileDefendantPersonPage} />
            <Route
                path=":contextedNameId/organization"
                component={EFileDefendantOrganizationPage}
            />
        </Route>
        <Route path="material" component={EFileMaterialsPage}>
            <IndexRedirect to="items" />
            <Route path="items" component={EFileMaterialItemsPage} />
            <Route path="attachments" component={EFileMaterialAttachmentsPage} />
        </Route>
        <Route path="tasks" component={EFileTasksPage} />
    </Route>
);

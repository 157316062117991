import React from 'react';
import { compose, withHandlers, withPropsOnChange, defaultProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { filter, head, includes } from 'lodash';
import styled from 'styled-components';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import {
    parentAttributeIdByAttributeIdSelector,
    attributeIsOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { RRFText } from '../../../../core/forms/components/Text';
import { RRFVehicleMakeSelect } from '../../../../core/forms/components/selects/VehicleMakeSelect';
import {
    RRFVehicleModelSelect,
    initialVehicleModelRelated,
} from '../../../../core/forms/components/selects/VehicleModelSelect';
import { RRFAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { RRFYearSelect } from '../../../../core/forms/components/selects/YearSelect';
import { RRFBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { RRFCheckbox } from '../../../../core/forms/components/checkboxes/Checkbox';
import Row from '../../../../core/components/Row';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';

const { withRRFActions, withRRFFieldValues } = reactReduxFormHelpers;
const { itemType } = globalAttributes;
const OTHER_OPTION_VALUE = -1;

const FloatRight = styled.div`
    float: right;
`;

function OtherItemFieldset({ filterOptionsByItemType, disabledConfig }) {
    return (
        <div>
            <Row>
                <RRFAttributeSelect
                    path="itemCategoryAttrId"
                    attributeType="ITEM_CATEGORY"
                    filterOptions={filterOptionsByItemType}
                    disabled={disabledConfig.itemCategoryAttrId}
                />
            </Row>
            <Row>
                <RRFText path="description" disabled={disabledConfig.description} />
            </Row>
            <Row>
                <RRFAttributeSelect
                    path="primaryColorAttrId"
                    attributeType="ITEM_COLOR"
                    length="md"
                    disabled={disabledConfig.primaryColorAttrId}
                />
                <RRFAttributeSelect
                    path="secondaryColorAttrId"
                    attributeType="ITEM_COLOR"
                    length="md"
                    disabled={disabledConfig.secondaryColorAttrId}
                />
            </Row>
            <Row>
                <RRFText path="size" length="md" disabled={disabledConfig.size} />
                <RRFText path="serialNumber" length="md" disabled={disabledConfig.serialNumber} />
            </Row>
            <Row>
                <RRFBooleanSelect
                    path="biohazard"
                    length="sm"
                    disabled={disabledConfig.biohazard}
                />
            </Row>
        </div>
    );
}

const VehicleFieldset = compose(
    connect(
        createStructuredSelector({
            attributeIsOther: attributeIsOtherSelector,
        })
    ),
    withRRFFieldValues({
        bodyStyleAttrId: 'bodyStyleAttrId',
        itemCategoryAttrId: 'itemCategoryAttrId',
        vehicleMakeId: 'vehicleMakeId',
        vehicleModelId: 'vehicleModelId',
        yearOfManufacture: 'yearOfManufacture',
    }),
    withRRFActions,
    withState('vehicleModelRelated', 'setVehicleModelRelated', initialVehicleModelRelated),
    withPropsOnChange(
        ['vehicleModelRelated'],
        ({ vehicleModelRelated: { bodyStyleAttrIds, itemCategoryAttrIds } }) => ({
            modelRelatedBodyStylesIsEmpty: !bodyStyleAttrIds || bodyStyleAttrIds.length < 1,
            modelRelatedItemCategoriesIsEmpty:
                !itemCategoryAttrIds || itemCategoryAttrIds.length < 1,
        })
    ),
    withPropsOnChange(['bodyStyleAttrId'], ({ bodyStyleAttrId, attributeIsOther }) => ({
        bodyStyleIsOther: attributeIsOther(bodyStyleAttrId),
    })),
    withPropsOnChange(['vehicleMakeId'], ({ vehicleMakeId }) => ({
        vehicleMakeIsOther: vehicleMakeId === OTHER_OPTION_VALUE,
    })),
    withPropsOnChange(['vehicleModelId'], ({ vehicleModelId }) => ({
        vehicleModelIsOther: vehicleModelId === OTHER_OPTION_VALUE,
    })),
    withHandlers({
        bodyStyleFilterOptions({
            attributeIsOther,
            bodyStyleAttrId,
            itemCategoryAttrId,
            modelRelatedBodyStylesIsEmpty,
            modelRelatedItemCategoriesIsEmpty,
            parentAttributeIdByAttributeId,
            vehicleModelRelated: { bodyStyleAttrIds },
        }) {
            if (modelRelatedBodyStylesIsEmpty || modelRelatedItemCategoriesIsEmpty) {
                return (options) =>
                    filter(
                        options,
                        ({ value }) =>
                            parentAttributeIdByAttributeId(value) === itemCategoryAttrId ||
                            // show current value no matter what
                            value === bodyStyleAttrId
                    );
            }

            return (options) =>
                filter(
                    options,
                    ({ value }) => includes(bodyStyleAttrIds, value) || attributeIsOther(value)
                );
        },
        handleBodyStyleChange({ formActions, attributeIsOther }) {
            return (value) => {
                if (!attributeIsOther(value)) {
                    formActions.reset('bodyStyleOther');
                }
            };
        },
        handleVehicleMakeChange({ formActions, setVehicleModelRelated }) {
            return (value) => {
                if (value !== OTHER_OPTION_VALUE) {
                    formActions.reset('itemMake');
                }

                formActions.reset('vehicleModelId');
                formActions.reset('itemModel');
                setVehicleModelRelated(initialVehicleModelRelated);
            };
        },
        handleVehicleModelChange({ formActions }) {
            return (value) => {
                if (value !== OTHER_OPTION_VALUE) {
                    formActions.reset('itemModel');
                }
            };
        },
        handleVehicleModelChangeWithModelRelated({
            formActions,
            itemCategoryAttrId,
            modelRelatedItemCategoriesIsEmpty,
            setVehicleModelRelated,
            vehicleMakeId,
        }) {
            return (vehicleModelRelated) => {
                const { itemCategoryAttrIds, vehicleMake } = vehicleModelRelated;

                if (!vehicleMakeId && !!vehicleMake) {
                    formActions.change('vehicleMakeId', vehicleMake.id);
                }

                if (itemCategoryAttrIds.length === 1) {
                    formActions.change('itemCategoryAttrId', head(itemCategoryAttrIds));
                } else if (
                    !modelRelatedItemCategoriesIsEmpty &&
                    !includes(itemCategoryAttrIds, itemCategoryAttrId)
                ) {
                    formActions.reset('itemCategoryAttrId');
                }

                formActions.reset('bodyStyleAttrId');
                formActions.reset('bodyStyleOther');

                setVehicleModelRelated(vehicleModelRelated);
            };
        },
        filterOptionsByItemTypeOverride({
            filterOptionsByItemType,
            modelRelatedItemCategoriesIsEmpty,
            vehicleModelRelated: { itemCategoryAttrIds },
        }) {
            if (modelRelatedItemCategoriesIsEmpty) {
                return filterOptionsByItemType;
            }

            return (options) =>
                filter(options, ({ value }) => includes(itemCategoryAttrIds, value));
        },
    })
)(function VehicleFieldset({
    bodyStyleFilterOptions,
    bodyStyleIsOther,
    disabledConfig,
    filterOptionsByItemTypeOverride,
    handleBodyStyleChange,
    handleVehicleMakeChange,
    handleVehicleModelChange,
    handleVehicleModelChangeWithModelRelated,
    itemCategoryAttrId,
    vehicleMakeId,
    vehicleMakeIsOther,
    vehicleModelIsOther,
    vehicleModelRelated,
}) {
    return (
        <div>
            <Row>
                <RRFAttributeSelect
                    path="itemCategoryAttrId"
                    attributeType="ITEM_CATEGORY"
                    filterOptions={filterOptionsByItemTypeOverride}
                    disabled={disabledConfig.itemCategoryAttrId}
                />
            </Row>
            <Row>
                <RRFVehicleMakeSelect
                    path="vehicleMakeId"
                    includeOtherOption={true}
                    itemCategoryAttrId={itemCategoryAttrId}
                    onChange={handleVehicleMakeChange}
                    disabled={disabledConfig.vehicleMakeId}
                    length="md"
                    vehicleMake={vehicleModelRelated.vehicleMake}
                />
                {vehicleMakeIsOther && (
                    <RRFText path="itemMake" length="md" disabled={disabledConfig.itemMake} />
                )}
            </Row>
            <Row>
                <RRFVehicleModelSelect
                    path="vehicleModelId"
                    includeOtherOption={true}
                    itemCategoryAttrId={itemCategoryAttrId}
                    onChange={handleVehicleModelChange}
                    disabled={disabledConfig.vehicleModelId}
                    length="md"
                    vehicleMakeId={vehicleMakeId}
                    onChangeWithVehicleModelRelated={handleVehicleModelChangeWithModelRelated}
                />
                {vehicleModelIsOther && (
                    <RRFText path="itemModel" length="md" disabled={disabledConfig.itemModel} />
                )}
            </Row>
            <Row>
                <RRFText path="description" disabled={disabledConfig.description} />
            </Row>
            <Row>
                <RRFYearSelect
                    path="yearOfManufacture"
                    length="md"
                    disabled={disabledConfig.yearOfManufacture}
                />
                <RRFAttributeSelect
                    path="primaryColorAttrId"
                    attributeType="ITEM_COLOR"
                    length="md"
                    disabled={disabledConfig.primaryColorAttrId}
                />
            </Row>
            <Row>
                <RRFAttributeSelect
                    path="bodyStyleAttrId"
                    attributeType="VEHICLE_BODY_STYLE"
                    length="md"
                    onChange={handleBodyStyleChange}
                    disabled={disabledConfig.bodyStyleAttrId}
                    filterOptions={bodyStyleFilterOptions}
                />
                {bodyStyleIsOther && (
                    <RRFText
                        path="bodyStyleOther"
                        placeholder="Enter Body Style Description..."
                        length="md"
                        disabled={disabledConfig.bodyStyleOther}
                    />
                )}
            </Row>
            <Row>
                <RRFText path="tag" length="md" disabled={disabledConfig.tag} />
                <RRFText path="vinNumber" length="md" disabled={disabledConfig.vinNumber} />
            </Row>
            <Row>
                <RRFYearSelect
                    path="registrationYear"
                    length="md"
                    disabled={disabledConfig.registrationYear}
                />
                <RRFAttributeSelect
                    path="registrationStateAttrId"
                    attributeType="STATE"
                    length="md"
                    disabled={disabledConfig.registrationStateAttrId}
                />
            </Row>
            <Row>
                <RRFText
                    path="registrationType"
                    length="md"
                    disabled={disabledConfig.registrationType}
                />
                <RRFText path="mileage" length="md" disabled={disabledConfig.mileage} />
            </Row>
            <Row>
                <RRFText
                    path="insuranceProviderName"
                    disabled={disabledConfig.insuranceProviderName}
                />
            </Row>
            <Row>
                <RRFText
                    path="insurancePolicyNumber"
                    length="md"
                    disabled={disabledConfig.insurancePolicyNumber}
                />
            </Row>
            <Row>
                <RRFBooleanSelect
                    path="biohazard"
                    length="sm"
                    disabled={disabledConfig.biohazard}
                />
            </Row>
        </div>
    );
});

function FirearmFieldset({ filterOptionsByItemType, disabledConfig }) {
    return (
        <div>
            <Row>
                <RRFAttributeSelect
                    path="itemCategoryAttrId"
                    attributeType="ITEM_CATEGORY"
                    filterOptions={filterOptionsByItemType}
                    length="md"
                    disabled={disabledConfig.itemCategoryAttrId}
                />
            </Row>
            <Row>
                <RRFText path="description" disabled={disabledConfig.description} />
            </Row>
            <Row>
                <RRFAttributeSelect
                    path="firearmMakeAttrId"
                    attributeType="FIREARM_MAKE"
                    length="md"
                    disabled={disabledConfig.firearmMakeAttrId}
                />
                <RRFText path="serialNumber" length="md" disabled={disabledConfig.serialNumber} />
            </Row>
            <Row>
                <FloatRight>
                    <RRFText
                        path="registrationNumber"
                        length="md"
                        disabled={disabledConfig.registrationNumber}
                    />
                </FloatRight>
            </Row>
            <Row>
                <RRFAttributeSelect
                    path="finishAttrId"
                    attributeType="ITEM_COLOR"
                    length="md"
                    disabled={disabledConfig.finishAttrId}
                />
                <RRFAttributeSelect
                    path="stockAttrId"
                    attributeType="FIREARM_STOCK"
                    length="md"
                    disabled={disabledConfig.stockAttrId}
                />
            </Row>
            <Row>
                <RRFAttributeSelect
                    path="gripAttrId"
                    attributeType="FIREARM_GRIP"
                    length="md"
                    disabled={disabledConfig.gripAttrId}
                />
                <RRFText path="caliber" length="md" disabled={disabledConfig.caliber} />
            </Row>
            <Row>
                <FloatRight>
                    <RRFText
                        path="barrelLength"
                        length="md"
                        disabled={disabledConfig.barrelLength}
                    />
                </FloatRight>
            </Row>
            <Row>
                <RRFText path="numberOfShots" length="md" disabled={disabledConfig.numberOfShots} />
            </Row>
            <Row>
                <RRFCheckbox
                    path="alterationIndicated"
                    disabled={disabledConfig.alterationIndicated}
                />
            </Row>
            <Row>
                <RRFBooleanSelect
                    path="biohazard"
                    length="sm"
                    disabled={disabledConfig.biohazard}
                />
            </Row>
        </div>
    );
}

function DrugsFieldset({ filterOptionsByItemType, disabledConfig }) {
    return (
        <div>
            <Row>
                <RRFText path="description" disabled={disabledConfig.description} />
            </Row>
            <Row>
                <RRFAttributeSelect
                    path="itemCategoryAttrId"
                    attributeType="ITEM_CATEGORY"
                    filterOptions={filterOptionsByItemType}
                    length="md"
                    disabled={disabledConfig.itemCategoryAttrId}
                />
            </Row>
            <Row>
                <RRFBooleanSelect
                    path="biohazard"
                    length="sm"
                    disabled={disabledConfig.biohazard}
                />
            </Row>
        </div>
    );
}

function AlcoholFieldset({ filterOptionsByItemType, disabledConfig }) {
    return (
        <div>
            <Row>
                <RRFText
                    path="description"
                    placeholder="Beer, wine, vodka, etc."
                    disabled={disabledConfig.description}
                />
            </Row>
            <Row>
                <RRFAttributeSelect
                    path="itemCategoryAttrId"
                    attributeType="ITEM_CATEGORY"
                    filterOptions={filterOptionsByItemType}
                    length="md"
                    disabled={disabledConfig.itemCategoryAttrId}
                />
            </Row>
            <Row>
                <RRFBooleanSelect
                    path="biohazard"
                    length="sm"
                    disabled={disabledConfig.biohazard}
                />
            </Row>
        </div>
    );
}

const ItemProfileFieldset = compose(
    connect(
        createStructuredSelector({
            parentAttributeIdByAttributeId: parentAttributeIdByAttributeIdSelector,
        })
    ),
    withHandlers({
        filterOptionsByItemType({ parentAttributeIdByAttributeId, itemTypeAttrId }) {
            return (options) =>
                filter(
                    options,
                    ({ value }) => parentAttributeIdByAttributeId(value) === itemTypeAttrId
                );
        },
    }),
    defaultProps({ disabledConfig: {} })
)(function ItemProfileFieldset({ itemTypeAttrId, parentAttributeIdByAttributeId, ...restOfProps }) {
    switch (itemTypeAttrId) {
        case itemType.item:
            return <OtherItemFieldset {...restOfProps} />;
        case itemType.vehicle:
            return (
                <VehicleFieldset
                    parentAttributeIdByAttributeId={parentAttributeIdByAttributeId}
                    {...restOfProps}
                />
            );
        case itemType.firearm:
            return <FirearmFieldset {...restOfProps} />;
        case itemType.drugs:
            return <DrugsFieldset {...restOfProps} />;
        case itemType.alcohol:
            return <AlcoholFieldset {...restOfProps} />;
        // phone and bus not selectable from ItemPopover
        case itemType.phone:
        case itemType.bus:
        default:
            return <div />;
    }
});

export default ItemProfileFieldset;

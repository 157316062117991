import {
    ElasticSearchTypeEnum,
    ElasticSearchTypeEnumType,
    EntityTypeEnum,
    EntityTypeEnumType,
} from '@mark43/rms-api';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

export function convertEntityTypeToElasticSearchType({
    entityType,
    itemTypeAttrId,
}: {
    entityType?: EntityTypeEnumType;
    itemTypeAttrId?: number;
}): ElasticSearchTypeEnumType | 'E_FILE' | undefined {
    switch (entityType) {
        case EntityTypeEnum.CASE.name:
            return ElasticSearchTypeEnum.CASE.name;
        case EntityTypeEnum.ORGANIZATION_PROFILE.name:
            return ElasticSearchTypeEnum.ORGANIZATION.name;
        case EntityTypeEnum.PERSON_PROFILE.name:
            return ElasticSearchTypeEnum.PERSON.name;
        case EntityTypeEnum.REPORT.name:
            return ElasticSearchTypeEnum.REPORT.name;
        case EntityTypeEnum.ITEM_PROFILE.name:
            return itemTypeAttrId === globalAttributes.itemType.vehicle
                ? ElasticSearchTypeEnum.VEHICLE.name
                : ElasticSearchTypeEnum.PROPERTY.name;
        case EntityTypeEnum.WARRANT.name:
            return ElasticSearchTypeEnum.WARRANT.name;
        case EntityTypeEnum.E_FILE.name:
            return 'E_FILE'; // There's no ElasticSearchTypeEnum.E_FILE.name yet
        default:
            return;
    }
}

import React from 'react';
import { SideNav, Text } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

const strings = componentStrings.eFiles.core.currentlyViewingSidePanel;

type EFileCurrentlyViewingSidebarT = {
    children: React.ReactNode;
};

export const EFileCurrentlyViewingSidebar = ({ children }: EFileCurrentlyViewingSidebarT) => {
    return (
        <>
            <Text variant={'bodyMd'} fontWeight={'semibold'}>
                {strings.currentlyViewing}
            </Text>
            <SideNav>{children}</SideNav>
        </>
    );
};

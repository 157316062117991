import { map } from 'lodash';

import {
    NEXUS_STATE_PROP as LOOKER_DASHBOARD_NEXUS_STATE_PROP,
    lookerDashboardsSelector,
} from '~/client-common/core/domain/looker-dashboards/state/data';
import lookerResource from '../../resources/lookerResource';

export function clearLookerDashboards() {
    return function (dispatch, getState, { nexus: { withRemove } }) {
        const currentLookerDashboards = lookerDashboardsSelector(getState());
        const predicate = map(currentLookerDashboards, ({ lookerId }) => ({ lookerId }));
        const withRemoveLookerDashboards = withRemove(
            LOOKER_DASHBOARD_NEXUS_STATE_PROP,
            predicate,
            { type: 'CLEAR_LOOKER_DASHBOARDS' }
        );
        dispatch(withRemoveLookerDashboards);
    };
}

export function loadLookerDashboards() {
    return function (dispatch, getState, { nexus: { withEntityItems, withRemove } }) {
        return lookerResource.getLookerDashboards().then((dashboards) => {
            const predicate = map(dashboards, ({ lookerId }) => ({ lookerId }));

            const withRemoveLookerDashboards = withRemove(
                LOOKER_DASHBOARD_NEXUS_STATE_PROP,
                predicate,
                { type: 'UPSERT_LOOKER_DASHBOARDS' }
            );

            const replaceAction = withEntityItems(
                {
                    [LOOKER_DASHBOARD_NEXUS_STATE_PROP]: dashboards,
                },
                withRemoveLookerDashboards
            );
            dispatch(replaceAction);
            return dashboards;
        });
    };
}

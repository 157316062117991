import { MentionCategoryType } from '../types';
import organizationMapper from './searchOrganizations';
import caseMapper from './searchCases';
import personMapper from './searchPersons';
import propertyMapper from './searchProperties';
import reportsMapper from './searchReports';
import vehicleMapper from './searchVehicles';
import warrantMapper from './searchWarrants';

export function getPrepareItemMapper(category: MentionCategoryType) {
    const mapper = {
        PERSON: personMapper,
        VEHICLE: vehicleMapper,
        ORGANIZATION: organizationMapper,
        PROPERTY: propertyMapper,
        REPORTS: reportsMapper,
        CASES: caseMapper,
        WARRANTS: warrantMapper,
    } as const;

    return mapper[category];
}

import React from 'react';
import styled, { css } from 'styled-components';
import { cssVar } from 'arc';
import { map } from 'lodash';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { InlineBanner } from '../../components/InlineBanner';

import testIds from '../../../../core/testIds';
import SidePanelErrorMessage, { LegacyErrorMessage } from './SidePanelErrorMessage';

type SidePanelHeaderProps = {
    errorMessages: string[];
    scrollOffset?: number;
    title: string;
};

const SidePanelHeaderTitle = styled.div<{ showErrors: boolean }>`
    padding: 6px 0 4px 28px;
    box-sizing: border-box;
    width: 100%;
    color: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-lg);
    letter-spacing: -0.01em;

    ${(props) =>
        props.showErrors
            ? css`
                  border: 1px solid var(--arc-colors-negative-default);
                  border-bottom: 3px solid var(--arc-colors-negative-default);
                  background-color: var(--arc-colors-negative-default);
              `
            : css`
                  border: 1px solid ${(props) => props.theme.colors.cobaltBlue};
                  border-bottom: 3px solid ${(props) => props.theme.colors.cobaltBlue};
                  background-color: ${(props) => props.theme.colors.brightBlue};
              `}
`;
const SidePanelErrorsListWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    border-left: solid 1px;
    border-right: solid 1px;
    border-color: ${({ theme }) => `${theme.colors.cobaltBlue}`};
    padding: ${cssVar('arc.space.2')};
    padding-bottom: 0;
`;

const SidePanelHeader: React.FC<SidePanelHeaderProps> = (props) => {
    const { errorMessages, scrollOffset, title } = props;

    const showErrors = !!errorMessages.length;

    return (
        <>
            <SidePanelHeaderTitle data-test-id={testIds.SIDE_PANEL_TITLE} showErrors={showErrors}>
                {title}
            </SidePanelHeaderTitle>
            {showErrors && (
                <SidePanelErrorsListWrapper data-test-id={testIds.SIDE_PANEL_ERRORS}>
                    <InlineBanner status="error" hasList>
                        {map(errorMessages, (message) => (
                            <FeatureFlagged
                                flag="RMS_REPORT_WRITING_ERROR_ENHANCEMENTS_ENABLED"
                                fallback={<LegacyErrorMessage message={message} />}
                            >
                                <SidePanelErrorMessage
                                    errorMessage={message}
                                    scrollOffset={scrollOffset}
                                />
                            </FeatureFlagged>
                        ))}
                    </InlineBanner>
                </SidePanelErrorsListWrapper>
            )}
        </>
    );
};

export default SidePanelHeader;

import React, { useCallback } from 'react';
import styled from 'styled-components';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';
import _, { map, keyBy } from 'lodash';
import { EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';

import { inPoliceCustodyChainEventTypeSelector } from '~/client-common/core/domain/chain-event-types/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatTitleForVehicleSelector } from '~/client-common/core/domain/vehicles/state/ui';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { userRolesSelector } from '~/client-common/core/domain/user-roles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';
import { iconTypes } from '../../../core/components/Icon';

import { Tooltip } from '../../../core/components/tooltip';
import _Button, {
    ReactButtonIconLeft,
    buttonTypes,
} from '../../../../legacy-redux/components/core/Button';
import { formatTitleForPropertyRecord } from '../../../../legacy-redux/helpers/propertyRecordHelpers';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { useAbilitySelector } from '../../../core/current-user/hooks/useAbilitySelector';
import { openConfirmationBar } from '../../../core/confirmation-bar/state/ui';
import TaskSidePanel from '../../../tasks/core/components/TaskSidePanel';
import { openTaskSidePanelForEntity } from '../../../tasks/core/state/ui';
import { addToItemQueueWithEvidenceItems } from '../../item-queue/state/ui';
import { openLabelPrintingModal } from '../../label-printing/state/ui';
import { getExportOptions } from '../state/ui';
import testIds from '../../../../core/testIds';
import { hasEvidenceLocationPermission } from '../../core/utils/evidenceItemsHelpers';
import EvidenceDashboardDispositionMenu from './EvidenceDashboardDispositionMenu';

const Button = styled(_Button)`
    align-items: center;
    display: flex;
    flex-direction: row;

    ${/* sc-selector */ ReactButtonIconLeft} {
        margin-top: 1px;
    }
`;

function ActionButton({ disabled, disabledTooltipText, ...props }) {
    const button = <Button className={buttonTypes.SECONDARY} disabled={disabled} {...props} />;
    return disabled && disabledTooltipText ? (
        <Tooltip side="top" hasButtonOffset content={disabledTooltipText}>
            {/* Tooltip renders as it's child, the current RMS button doesn't work with that. This
            wrapper keeps the tooltip displaying and aligned with the button. This can be removed
            when these buttons are updated. */}
            <span style={{ float: 'left' }}>{button}</span>
        </Tooltip>
    ) : (
        button
    );
}

const SelectionText = styled.div`
    max-width: 200px;
    height: 62px;
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
`;

const strings = componentStrings.evidence.dashboard.EvidenceDashboardSearchResults;

function ActionBarContent({
    handleExport,
    onAddToQueueClick,
    onPrintLabelsClick,
    allResultsSelected,
    allResultsOnPageSelected,
    totalResults,
    selectedResults,
    handleClearAllSelectedRows,
    handleSelectAllRows,
    someItemIsInPoliceCustody,
    canPrintLabels,
}) {
    const dispatch = useDispatch();
    const formatTitleForVehicle = useSelector(formatTitleForVehicleSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const hasLimitAbility = useAbilitySelector(abilitiesEnum.CORE.TASK_ENTITY_LINKING_LIMIT);
    const userRoles = useSelector(userRolesSelector);
    const taskLimit = applicationSettings.RMS_TASK_ENTITY_LINKING_LIMIT;
    const handleNewTaskButtonClick = useCallback(() => {
        const taskEntityLinks = map(selectedResults, (result) => {
            const isVehicle = result.itemTypeAttrId === globalAttributes.itemType.vehicle;
            const itemTitle = isVehicle
                ? formatTitleForVehicle(result)
                : formatTitleForPropertyRecord(result, false);
            return {
                entityId: result.masterItemId,
                entityType: EntityTypeEnum.ITEM_PROFILE.name,
                entityTitle: itemTitle,
            };
        });

        dispatch(openTaskSidePanelForEntity(taskEntityLinks));
    }, [dispatch, formatTitleForVehicle, selectedResults]);
    const handleTaskSidePanelSaveSuccess = useCallback(() => {
        dispatch(openConfirmationBar({ message: strings.taskSaveConfirmation }));
    }, [dispatch]);
    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];

    const clickText = allResultsSelected ? strings.clearSelection : strings.selectAll(totalResults);
    let statusText;
    if (allResultsSelected) {
        statusText = strings.allSelected(totalResults);
    } else if (allResultsOnPageSelected) {
        statusText = strings.allOnPageSelected(selectedResults.length);
    } else {
        statusText = strings.someOnPageSelected(selectedResults.length);
    }

    const userRolesById = keyBy(userRoles, (role) => role.roleId);
    const evidenceLocationPermsSet = _(selectedResults)
        .map((item) => {
            const { evidenceLocationPermissions, facilityId } = item.currentChainEvent[0];
            // only return a permission set if the item is currently in Facility or Storage location
            if (facilityId) {
                return evidenceLocationPermissions;
            }
            return;
        })
        .compact()
        .value();

    const useEvdPerms = applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED;

    return (
        <div>
            <OnlyWithAbility has={abilitiesEnum.EVIDENCE.ITEM_QUEUE}>
                <ActionButton
                    iconLeft={iconTypes.PROPERTY}
                    className={buttonTypes.SECONDARY}
                    onClick={onAddToQueueClick}
                    disabled={
                        someItemIsInPoliceCustody ||
                        (useEvdPerms &&
                            !hasEvidenceLocationPermission(
                                evidenceLocationPermsSet,
                                userRolesById,
                                OperationTypeEnum.MANAGE.name
                            ))
                    }
                    disabledTooltipText={
                        someItemIsInPoliceCustody
                            ? strings.actionButtons.disabledAddToQueueTooltip(custodyLabel)
                            : strings.actionButtons.noManagePermissionTooltip
                    }
                    testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_ADD_TO_QUEUE}
                >
                    {strings.actionButtons.addToQueue}
                </ActionButton>
            </OnlyWithAbility>
            <ActionButton
                iconLeft={iconTypes.LABEL}
                onClick={onPrintLabelsClick}
                disabled={!canPrintLabels}
                disabledTooltipText={strings.actionButtons.disabledPrintLabelsTooltip(custodyLabel)}
                testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_PRINT_LABELS}
            >
                {strings.actionButtons.printLabels}
            </ActionButton>
            <FeatureFlagged flag="RMS_TASK_ENTITY_LINKS_ENABLED">
                <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
                    <ActionButton
                        iconLeft={iconTypes.TASK}
                        onClick={handleNewTaskButtonClick}
                        testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_NEW_TASK}
                        disabled={
                            applicationSettings.RMS_TASK_ENTITY_LINKS_ENABLED &&
                            hasLimitAbility &&
                            taskLimit > 1 &&
                            selectedResults.length > taskLimit
                        }
                        disabledTooltipText={strings.actionButtons.disabledNewTaskTooltip(
                            taskLimit
                        )}
                    >
                        {strings.actionButtons.newTask}
                    </ActionButton>
                    <TaskSidePanel onSave={handleTaskSidePanelSaveSuccess} />
                </OnlyWithAbility>
            </FeatureFlagged>
            <EvidenceDashboardDispositionMenu
                masterItemIds={map(selectedResults, 'masterItemId')}
            />
            <ActionButton
                iconLeft={iconTypes.EXPORT}
                className={buttonTypes.SECONDARY}
                onClick={handleExport}
                testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_EXPORT}
            >
                {strings.actionButtons.export}
            </ActionButton>
            <SelectionText>
                {statusText}
                <br />
                <span
                    className="select-action"
                    onClick={
                        allResultsSelected
                            ? () => handleClearAllSelectedRows([])
                            : handleSelectAllRows
                    }
                    data-test-id={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_SELECT_ALL_RESULTS}
                >
                    {clickText}
                </span>
            </SelectionText>
        </div>
    );
}
function EvidenceDashboardSearchResultsActionBar(props) {
    return (
        <div
            className="evidence-dashboard-search-results-action-bar"
            data-test-id={testIds.EVIDENCE_DASHBOARD_ACTION_BAR}
        >
            <div className="action-bar-content">
                <ActionBarContent {...props} />
            </div>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    inPoliceCustodyChainEventType: inPoliceCustodyChainEventTypeSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    addToItemQueue: (masterItemIds) => dispatch(addToItemQueueWithEvidenceItems(masterItemIds)),
    openLabelPrintingModal: (masterItemIds) => dispatch(openLabelPrintingModal(masterItemIds)),
    handleExport: () => {
        dispatch(getExportOptions());
        router.push('evidence/dashboard/exports');
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['selectedResults', 'inPoliceCustodyChainEventType'],
        ({ selectedResults, inPoliceCustodyChainEventType }) => {
            // cannot take evidence actions on any item that is still in police custody
            // (same as CPS)
            const someItemIsInPoliceCustody = !!inPoliceCustodyChainEventType
                ? _(selectedResults)
                      .map('currentChainEvent[0].eventTypeId')
                      .includes(inPoliceCustodyChainEventType.id)
                : false;
            return {
                someItemIsInPoliceCustody,
                canPrintLabels: !someItemIsInPoliceCustody,
            };
        }
    ),
    withHandlers({
        onAddToQueueClick({ addToItemQueue, selectedResults }) {
            // if the user selects all results across multiple pages, we add to basket only the
            // current page of results
            return () => addToItemQueue(map(selectedResults, 'masterItemId'));
        },
        onPrintLabelsClick({ openLabelPrintingModal, selectedResults }) {
            // if the user selects all results across multiple pages, we print only the current page
            // of results
            return () =>
                openLabelPrintingModal({ masterItemIds: map(selectedResults, 'masterItemId') });
        },
    })
)(EvidenceDashboardSearchResultsActionBar);

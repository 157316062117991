import React from 'react';
import { LinkTypesEnum } from '@mark43/rms-api';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Icon, Text } from 'arc';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { EFileLinkTypesT } from '../../types';

const strings = componentStrings.eFiles;

const StyledBreadcrumb = styled(Breadcrumb)`
    margin-bottom: var(--arc-space-4);
`;

const LastBreadcrumbLink = styled(BreadcrumbLink)`
    &:hover {
        color: var(--arc-colors-text-primary);
        cursor: text;
        text-decoration: none;
    }
`;

const ParentBreadcrumbText = styled(Text)`
    color: var(--arc-colors-selected-default);
`;

interface EFileBreadCrumbProps {
    linkType: EFileLinkTypesT;
    parentPath: string;
    entityName?: string;
}

export const EFileBreadCrumb = ({ linkType, parentPath, entityName }: EFileBreadCrumbProps) => {
    switch (linkType) {
        case LinkTypesEnum.DEFENDANT_IN_EFILE:
            return (
                <StyledBreadcrumb spacing="0px" separator={<Icon icon="OpenRight" size="md" />}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={parentPath}>
                            <ParentBreadcrumbText variant="bodyMd">
                                {strings.defendants.header.title}
                            </ParentBreadcrumbText>
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <LastBreadcrumbLink>
                            <Text variant="bodyMd">{entityName}</Text>
                        </LastBreadcrumbLink>
                    </BreadcrumbItem>
                </StyledBreadcrumb>
            );
        default:
            return null;
    }
};

import { includes } from 'lodash';

import type { IconNameT } from 'arc';

// show a small subset of the hundreds of icons in this admin page because most icons are not useful for report modules
export const labelIconMap: Record<string, IconNameT> = {
    'Admin Updates': 'AdminUpdates',
    Analytics: 'Analysis',
    'Analytics Dashboard': 'AnalyticsDashboard',
    'Analytics Look': 'AnalyticsLook',
    Booking: 'Booking',
    Canine: 'Canine',
    Cases: 'Case',
    Citations: 'Card',
    Civil: 'Gavel',
    Courts: 'AccountBalance',
    'Data Exchange': 'Dex',
    Evidence: 'Evidence',
    Fire: 'Fire',
    Firearms: 'Firearm',
    Law: 'Scales',
    License: 'License',
    Mobile: 'PhoneMobile',
    Pawn: 'Pawn',
    Police: 'Police',
    Reports: 'Report',
    Tasks: 'Task',
    Vehicles: 'Vehicle',
    Warrants: 'Warrant',
};

export function isReportModuleIcon(icon: string): icon is IconNameT {
    return includes(labelIconMap, icon);
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure } from 'recompose';

import componentStrings from '~/client-common/core/strings/componentStrings';

import advancedSearchOrganizations from '../state/ui';
import FormSection from '../../../core/forms/components/FormSection';
import AdvancedSearchForm from '../../core/components/AdvancedSearchForm';
import { OrganizationFieldset } from '../../core/components/OrganizationsFieldset';
import { SearchName } from '../../core/components/SearchName';

const strings = componentStrings.search.AdvancedSearchOrganizationsForm;

const OrganizationSection = pure(function OrganizationSection(props) {
    return (
        <FormSection>
            <OrganizationFieldset
                includeIndustryAttrId={true}
                includeCrossStreets={true}
                highlightOnFocus={false}
                showHeader={false}
                {...props}
            />
        </FormSection>
    );
});

function AdvancedSearchOrganizationsForm({
    fuzzyMatchingEnabled,
    formIsEmpty,
    onSubmit,
    onReset,
    currentSavedSearch,
}) {
    return (
        <AdvancedSearchForm
            {...advancedSearchOrganizations.form}
            includeFuzzyMatchingCheckbox={true}
            fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            onSubmit={onSubmit}
            onReset={onReset}
            formIsEmpty={formIsEmpty}
            searchText={strings.search}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={true} />
            <OrganizationSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
        </AdvancedSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    fuzzyMatchingEnabled: advancedSearchOrganizations.form.selectors.buildFormModelSelectorByPath(
        'fuzzyMatchingEnabled'
    ),
    formIsEmpty: advancedSearchOrganizations.form.selectors.formIsEmptySelector,
    currentSavedSearch: advancedSearchOrganizations.selectors.currentSavedSearchSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () =>
        dispatch(
            advancedSearchOrganizations.form.actionCreators.submit((formData) =>
                dispatch(
                    advancedSearchOrganizations.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                )
            )
        ),
    onReset: () => {
        dispatch(advancedSearchOrganizations.form.actionCreators.reset());
        dispatch(advancedSearchOrganizations.actionCreators.resetState());
        dispatch(advancedSearchOrganizations.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(advancedSearchOrganizations.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearchOrganizationsForm);

import React from 'react';
import { withState } from 'recompose';
import { findIndex, get, isArray, isFunction, map } from 'lodash';
import PopoutMenu from '../../../../modules/core/components/PopoutMenu';
import TableColumn from './TableColumn';

/**
 * A configuration component which allows users to interact with the data displayed
 *   on a Table and also select the sort key and sort type for said data.
 *   @param {object} props
 *   @param {string}          props.columnKey         Indicates which one of the
 *     provided TableColumn children should have their data represented in
 *     the parent Table
 *   @param {Component[]}     props.children          An array of TableColumn
 *     instances, each of which will be represented as an option in a dropdown
 *     that opens if this OptionsTableColumn is clicked.
 *   @param {string}          props.display           The text which will appear
 *     in the column header that this OptionsTableColumn represents.
 *   @param {string}          [props.activeValue]     Which one (if any) of the
 *     options offered by this OptionsTableColumn is active.
 *   @param {string}          [props.activeSortType]  The active sort type of
 *     results the parent Table component represents.
 *   @param {boolean}         props.isOpen            Whether or not the Dropdown
 *     menu owned by an OptionsTableColumn is visible.
 *   @param {function}        props.setIsOpen         A function which allows us
 *     to modify the `isOpen` state of an OptionsTableColumn instance.
 *   @param {function}        props.onOptionClick     Callback called whenever an option
 *     representing a member of props.children is clicked.
 *   @param {function}        [props.onSortTypeClick] Callback called whenever a child
 *     ColumnPopoutSortOption is clicked.
 *   @param {string}          props.className         An additional complimentary
 *     CSS class that TableColumn can inherit
 *   @param {number}          props.width             The width of the column that
 *     this OptionsTableColumn represents.
 *   @param {number}          props.popoutWidth       The width of the popout that opens
 *     when this OptionsTableColumn is clicked.
 */
function OptionsTableColumn({
    // The actual data column for which values / cells will be displayed
    columnKey,

    // the provided OptionsTableColumnOption instances
    children: options,
    display,
    activeValue,
    activeSortType,

    // Local Dropdown State
    isOpen,
    setIsOpen,

    // Event Handlers
    onOptionClick: handleOptionClick,
    onSortTypeClick: handleSortTypeClick,

    // UI Things
    className,
    width,
    height,
    popoutWidth,
}) {
    options = isArray(options) ? options : [options];
    const activeOptionIndex = findIndex(options, ({ props: { value } }) => activeValue === value);
    const activeOption = options[activeOptionIndex];
    const columnDisplay = isFunction(display)
        ? display(get(activeOption, 'props'), options)
        : display;
    return (
        <TableColumn
            className={className}
            width={width}
            height={height}
            sortType={activeSortType}
            columnKey={columnKey}
            display={columnDisplay}
            onClick={() => setIsOpen((oldVal) => !oldVal)}
        >
            {isOpen ? (
                <PopoutMenu
                    onClickAway={() => setIsOpen(false)}
                    options={options}
                    top="44px"
                    onOptionClick={handleOptionClick}
                    sortOptions={
                        activeOption && activeOption.props.sortOptions
                            ? map(
                                  activeOption.props.sortOptions,
                                  ({ display, sortType: optionSortType }) => ({
                                      display,
                                      sortType: optionSortType,
                                      isActive:
                                          activeSortType === optionSortType &&
                                          activeValue === activeOption.props.value,
                                      handleClick: () =>
                                          handleSortTypeClick(
                                              optionSortType,
                                              activeOption ? activeOption.props : undefined
                                          ),
                                  })
                              )
                            : undefined
                    }
                    activeOptionIndex={activeOptionIndex}
                    // we add 20 to the width by default because the parent has 10px
                    // horizontal padding on either side - this default gives a more
                    // expected behaviour (dropdown width actually matches parent width)
                    width={(popoutWidth || width) + 20}
                />
            ) : undefined}
        </TableColumn>
    );
}

/**
 * this component is a noop
 *
 * @typedef {object} Props
 * @prop {string}    display
 * @prop {string}    [columnKey]
 * @prop {string}    value
 * @prop {object[]}  [sortOptions]
 *
 * @param {Props}    props
 */
export function OptionsTableColumnOption({}) {
    return null;
}

export default withState('isOpen', 'setIsOpen', false)(OptionsTableColumn);

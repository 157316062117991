import { get } from 'lodash';
import { createSelector } from 'reselect';
import { CaseDefinition } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'caseDefinitions';

const caseDefinitionsModule = createNormalizedModule<CaseDefinition>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeCaseDefinitions = caseDefinitionsModule.actionCreators.storeEntities;

// SELECTORS
export const caseDefinitionsSelector = caseDefinitionsModule.selectors.entitiesSelector;

export const formatCaseDefinitionByIdSelector = createSelector(
    caseDefinitionsModule.selectors.entityByIdSelector,
    (caseDefinitionById) => (id: number) => get(caseDefinitionById(id), 'name')
);

// REDUCER
export default caseDefinitionsModule.reducerConfig;

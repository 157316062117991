import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isArray, includes } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { sortedAugmentedAttachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';
import { FileGrid as _FileGrid } from '../../../../legacy-redux/components/core/Files';
import EntityProfileSection from './EntityProfileSection';

const strings = componentStrings.entityProfiles.EntityProfileAttachmentDetails;

const FileGrid = styled(_FileGrid)`
    margin-top: -5px;
`;

const mapStateToProps = (state, ownProps) => {
    const entityId = ownProps.entityId;
    const linkTypes = isArray(ownProps.linkType) ? ownProps.linkType : [ownProps.linkType];

    // map to an object that's consumable by FileGrid in Files.js
    const attachmentFileLinks = sortedAugmentedAttachmentViewModelsWhereSelector(state)(
        (viewModel) => viewModel.entityId === entityId && includes(linkTypes, viewModel.linkType)
    );
    return { attachmentFileLinks };
};

export default connect(mapStateToProps)(function AttachmentDetails({
    title = strings.title,
    attachmentFileLinks,
}) {
    return (
        attachmentFileLinks.length > 0 && (
            <EntityProfileSection title={title}>
                <FileGrid
                    allowLightbox={true}
                    fileLinks={attachmentFileLinks}
                    width={640}
                    numOfColumns={3}
                />
            </EntityProfileSection>
        )
    );
});

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { hydratedChargesForChargesWhereSelector } from '~/client-common/core/domain/charges/state/ui';
import { buildInitialCustomPropertyState } from '../../../components/arrest-charges-side-panel/ArrestChargesSidePanel';

const openArrestChargesSidePanel = ({ reportId }) => (dispatch, getState, { overlayStore }) => {
    const state = getState();
    const arrest = arrestForReportIdSelector(state)(reportId);

    if (!isUndefinedOrNull(arrest)) {
        const { id } = arrest;
        const hydratedCharges = hydratedChargesForChargesWhereSelector(state)({ arrestId: id });
        const initialCustomPropertyState = buildInitialCustomPropertyState({ hydratedCharges });
        overlayStore.open(
            overlayIdEnum.ARREST_CHARGES_SIDE_PANEL_DROPDOWN_MENU,
            initialCustomPropertyState
        );
    }
};

export default openArrestChargesSidePanel;

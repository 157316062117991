import { compact, filter, includes, reject, some } from 'lodash';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

function serializeAbility(ability) {
    return compact([ability.name, ability.description]).join(' ').toLowerCase();
}

export function filterMatchesAbility(filter, ability) {
    return includes(serializeAbility(ability), filter.toLowerCase());
}

export function filterMatchesAnyAbility(filter, abilities) {
    return some(abilities, (ability) => filterMatchesAbility(filter, ability));
}

//

/**
 * Abilities are ordered by id in all features (role abilities page, user profile page). We do an ad
 *   hoc override on this for just the task abilities under the CORE module, because View Non-Case
 *   Tasks was created way after View Case Tasks. KRA-4219
 * @param  {Object[]} abilities
 * @return {Object}
 */
export function sortCoreAbilities(abilities) {
    return [
        ...filter(abilities, { id: abilitiesEnum.CORE.VIEW_NON_CASE_TASKS }),
        ...reject(abilities, { id: abilitiesEnum.CORE.VIEW_NON_CASE_TASKS }),
    ];
}

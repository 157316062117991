import { noop } from 'lodash';
import { ComplianceGroupEnum, EntityTypeEnum } from '@mark43/rms-api';

import { nibrsOffenseCodesSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { nibrsOffenseCodeForOffenseCodeIdSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { currentUserDepartmentProfileSelector } from '../../../../core/current-user/state/ui';
import { createFormUpdateHandler, getCardSavePromises } from '../../utils/offenseIncidentHelpers';
import { convertFromFormModel } from '../forms/offenseForm';
import createCard from '../../utils/createCard';
import { refreshOffenseForm } from './offense';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.OFFENSE.name,
    baseSelector: (state) => state.ui.report.offenseCards,
    anchor: 'offense-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit(options = {}) {
            const { index } = options;
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        editAll() {
            return (dispatch) => dispatch(baseCard.actionCreators.transitionAllCardsToEditMode());
        },
        save(form, options) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const cardIndex = { index: options.index };
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state, cardIndex);
                const model = form.getState().model;
                const { suspects, victims, offenders } = model.links;
                const nibrsOffenseCodes = nibrsOffenseCodesSelector(state);
                const nibrsOffenseCodeId = model.offense.nibrsOffenseCodeId;
                let nibrsOffenseCode = nibrsOffenseCodes[nibrsOffenseCodeId];

                if (
                    currentUserDepartmentProfileSelector(getState()).complianceGroup ===
                    ComplianceGroupEnum.UNITED_KINGDOM.name
                ) {
                    nibrsOffenseCode = { id: model.offense.offenseCodeId };
                }

                // adding victims to this first to prefer the victim error
                // when eliminating duplicates later
                const links = [...victims, ...suspects, ...offenders];
                const additionalFormsToValidate = links.reduce(
                    (acc, { nameId, entityType, linkType, linkTypeSequenceNumber }) => {
                        if (entityType === EntityTypeEnum.PERSON_PROFILE.name) {
                            acc.push({
                                id: nameId,
                                linkType,
                                linkTypeSequenceNumber,
                                nibrsOffenseCode,
                            });
                        }
                        return acc;
                    },
                    []
                );
                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                            options: { ...cardIndex, additionalFormsToValidate },
                        })
                    );
                } else {
                    let createOrUpsertPromise;
                    let allPromises;
                    let offense;

                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                // we are using `getPromisesForSubmission` to defer creation of our save promises
                                // until after `submit()` has been called on our form.
                                const formModel = form.getState().model;
                                const reduxState = getState();
                                const offenseCardPayload = convertFromFormModel(formModel, {
                                    nibrsOffenseCodeForOffenseCodeId: nibrsOffenseCodeForOffenseCodeIdSelector(
                                        reduxState
                                    ),
                                    nibrsOffenseCodes: nibrsOffenseCodesSelector(reduxState),
                                    attributeIsOther: attributeIsOtherSelector(reduxState),
                                });
                                // store references to our promises and offense outside of this block
                                // because we need to access them down below
                                ({ offense } = offenseCardPayload);
                                ({ createOrUpsertPromise, allPromises } = getCardSavePromises({
                                    additionalFormsToValidate,
                                    dispatch,
                                    getState,
                                })(offenseCardPayload));

                                return allPromises;
                            },
                            options: {
                                ...cardIndex,
                                additionalFormsToValidate,
                                customEventType: options.customEventType,
                                changeToSummaryModeAfterSubmission:
                                    options.changeToSummaryModeAfterSubmission,
                            },
                        })
                    ).finally(() => {
                        // We chain onto the submission promise because all the actions within
                        // the submission need to happen before we can finally update our form state
                        // for newly created offenses. We need to always have this happen as long
                        // as upserting completes because it will replace the original offense
                        return (
                            createOrUpsertPromise
                                .then(
                                    createFormUpdateHandler({
                                        dispatch,
                                        deriveNewFormState: (offense) =>
                                            refreshOffenseForm({
                                                offenseId: offense.id,
                                            }),
                                        form,
                                        cardModule: baseCard,
                                        offense,
                                    })
                                )
                                // we noop here because this error will already have been caught within `submitCard`
                                .catch(noop)
                        );
                    });
                }
            };
        },
    },
};

import globalActionTypes from '../actions/types/globalActionTypes';
import { getWidthAndHeight } from '../../modules/core/utils/windowHelpers';

const initWH = getWidthAndHeight();
const initialUiState = {
    windowHeight: initWH.height,
    windowWidth: initWH.width,
    loadingBarVisible: false,
    loadingMaskVisible: false,
    additionalContentTop: 0,
    showFieldNames: false,
    overrideCreateReportModalV2: false,
};

export function globalUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case globalActionTypes.WINDOW_RESIZE:
            return {
                ...state,
                windowHeight: action.payload.height,
                windowWidth: action.payload.width,
            };
        case globalActionTypes.SHOW_HIDE_LOADING_MASK:
            return {
                ...state,
                loadingMaskVisible: action.payload,
            };
        case globalActionTypes.SHOW_HIDE_LOADING_BAR:
            return {
                ...state,
                // we decrement the value of loadingBarVisible if given false
                // and increment it if given true
                loadingBarVisible: state.loadingBarVisible + (action.payload ? 1 : -1),
            };
        case globalActionTypes.SET_ADDITIONAL_CONTENT_TOP:
            return {
                ...state,
                additionalContentTop: action.payload,
            };
        case globalActionTypes.TOGGLE_FIELD_NAMES:
            return {
                ...state,
                showFieldNames: !state.showFieldNames,
            };
        case globalActionTypes.SET_OVERRIDE_CREATE_REPORT_MODAL_V2:
            return {
                ...state,
                overrideCreateReportModalV2: action.payload,
            };
        default:
            return state;
    }
}

const initialErrorState = {
    errorCode: null,
};

export function globalErrorReducer(state = initialErrorState, action) {
    switch (action.type) {
        case globalActionTypes.SET_GLOBAL_ERROR:
            return {
                ...state,
                errorCode: action.payload.errorCode,
                errorId: action.payload.errorId,
            };
        default:
            return state;
    }
}

const initialPersistentState = {
    departmentStatusIndicator: null,
};

export function globalPersistentReducer(state = initialPersistentState, action) {
    switch (action.type) {
        case globalActionTypes.SET_DEPARTMENT_STATUS_INDICATOR:
            return {
                ...state,
                departmentStatusIndicator: action.payload,
            };
        default:
            return state;
    }
}

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'rotation';

export default createResource({
    name: 'Cad ServiceProvider Resource',
    methods: {
        getCadServiceTypes() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service_types/true`,
            });
        },
        getCadServiceProviders() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/services`,
            });
        },
        createCadServiceProvider(cadServiceProvider) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service`,
                data: cadServiceProvider,
            });
        },
        updateCadServiceProvider(cadServiceProvider) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service`,
                data: cadServiceProvider,
            });
        },
        getCadServiceProviderHistory(serviceProviderId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service_provider/${serviceProviderId}/histories`,
            });
        },

        getAllCadServiceProvidersHistory() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service_provider/histories`,
            });
        },
        getCadServiceRotationListHistory(serviceRotationListId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/rotation_list/${serviceRotationListId}/histories`,
            });
        },

        getAllCadServiceRotationListHistory() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/rotation_list/histories`,
            });
        },

        /**
         * Export all serviceProviders into a CSV.
         * @return {Promise}
         */
        exportServiceProviders() {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/export`,
            });
        },

        /**
         * Validate that a file is valid for importing new serviceProviders.
         * @return {Promise}
         */
        validateServiceProviderImport(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/validate/${fileId}`,
            });
        },

        /**
         * Import serviceProviders from an uploaded file.
         * @return {Promise}
         */
        importServiceProviders(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/${fileId}`,
            });
        },
        createCadServiceProviderSuspension(suspension, serviceProviderId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/suspension/${serviceProviderId}`,
                data: suspension,
            });
        },
        updateCadServiceProviderSuspension(suspension) {
            return req({
                baseUrl: BASE_URL,
                method: 'PUT',
                url: `${BASE_PATH}/suspension/${suspension.rotationSuspensionId}`,
                data: suspension,
            });
        },
        deleteCadServiceProviderSuspension(suspension) {
            return req({
                baseUrl: BASE_URL,
                method: 'DELETE',
                url: `${BASE_PATH}/suspension/${suspension.rotationSuspensionId}`,
            });
        },
        getCadServiceProviderSuspensionsHistory(serviceProviderId) {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/suspension/${serviceProviderId}/histories`,
            });
        },
    },
});

import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { simpleControl } from 'markformythree';
import {
    agencyProfilesWithOriOptionsWhereSelector,
    agencyProfileOptionsWhereSelector,
} from '~/client-common/core/domain/agency-profiles/state/ui';
import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

const BaseAgencyProfileSelect = ({ agencyProfileOptions, ...otherProps }) => (
    <Select options={agencyProfileOptions} {...otherProps} />
);

const generateAgencyProfileOptionsOnPropsChange = withPropsOnChange(
    ['agencyProfileOptionsWhere', 'predicate'],
    (props) => ({
        agencyProfileOptions: props.agencyProfileOptionsWhere(props.predicate),
    })
);

/**
 * Select dropdown for agency profiles with ORI.
 * @param {Object} props
 * @param {string} [props.label] Set this prop to use a custom label different
 *   from the default label.
 * @param {string} [props.predicate] Set this prop to filter options returned from state
 */
export const AgencyProfileWithOriSelect = compose(
    setDisplayName('AgencyProfileWithOriSelect'),
    connect(
        createStructuredSelector({
            // instead of carrying over the actual name, we chose a generic
            // name so we can reuse the same filter logic for both selects,
            // but with different base selectors
            agencyProfileOptionsWhere: agencyProfilesWithOriOptionsWhereSelector,
        })
    ),
    generateAgencyProfileOptionsOnPropsChange
)(BaseAgencyProfileSelect);

/**
 * Select dropdown for agency profiles.
 * @param {Object} props
 * @param {string} [props.label] Set this prop to use a custom label different
 *   from the default label.
 * @param {string} [props.predicate] Set this prop to filter options returned from state
 */
const AgencyProfileSelect = compose(
    setDisplayName('AgencyProfileSelect'),
    connect(
        createStructuredSelector({
            agencyProfileOptionsWhere: agencyProfileOptionsWhereSelector,
        })
    ),
    generateAgencyProfileOptionsOnPropsChange
)(BaseAgencyProfileSelect);

export const RRFAgencyProfileSelect = connectRRFInput(AgencyProfileSelect);
export const RRFAgencyProfileWithOriSelect = connectRRFInput(AgencyProfileWithOriSelect);
export const MFTAgencyProfileWithOriSelect = simpleControl(AgencyProfileWithOriSelect);
export const ArbiterMFTAgencyProfileSelect = arbiterMFTInput(AgencyProfileSelect);
export default AgencyProfileSelect;

import { ChainEventCategoryEnum, RefContextEnum } from '@mark43/evidence-api';
import _, { filter, get, includes, values } from 'lodash';
import { createSelector } from 'reselect';
import {
    chainEventTypesSelector,
    chainEventTypeByIdSelector,
    NEXUS_STATE_PROP as CHAIN_EVENT_TYPES_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/chain-event-types/state/data';
import { isExpired } from '~/client-common/core/dates/utils/dateHelpers';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import chainEventTypeResource from '../../resources/chainEventTypesResource';
import { inheritableChainEventCategories } from '../../utils/chainEventCategoryHelpers';
import { getDisplayValueForChainEventTypeCategory } from '../../utils/chainEventTypeHelpers';

const SET_SELECTED_CHAIN_EVENT_TYPE_ID =
    'evidence-chain-event-types/SET_SELECTED_CHAIN_EVENT_TYPE_ID';
const UPSERT_CHAIN_EVENT_TYPE_START = 'evidence-chain-event-types/UPSERT_CHAIN_EVENT_TYPE_START';
const UPSERT_CHAIN_EVENT_TYPE_SUCCESS =
    'evidence-chain-event-types/UPSERT_CHAIN_EVENT_TYPE_SUCCESS';
const UPSERT_CHAIN_EVENT_TYPE_FAILURE =
    'evidence-chain-event-types/UPSERT_CHAIN_EVENT_TYPE_FAILURE';
const CLEAR_CHAIN_EVENT_TYPE_ERRORS = 'evidence-chain-event-types/CLEAR_CHAIN_EVENT_TYPE_ERRORS';

export const FORM_FIELD_WIDTH = 270;

export function setSelectedChainEventTypeId(chainEventTypeId) {
    return {
        type: SET_SELECTED_CHAIN_EVENT_TYPE_ID,
        payload: {
            selectedId: chainEventTypeId,
        },
    };
}

function upsertNewChainEventTypeStart() {
    return {
        type: UPSERT_CHAIN_EVENT_TYPE_START,
    };
}

function upsertNewChainEventTypeSuccess() {
    return {
        type: UPSERT_CHAIN_EVENT_TYPE_SUCCESS,
    };
}

function upsertNewChainEventTypeFailure(errorMessage) {
    return {
        type: UPSERT_CHAIN_EVENT_TYPE_FAILURE,
        payload: {
            errorMessage,
        },
    };
}

function clearChainEventTypeErrors() {
    return { type: CLEAR_CHAIN_EVENT_TYPE_ERRORS };
}

const evidenceChainEventTypesAdminUiSelector = (state) => state.ui.evidenceChainEventTypesAdmin;

const selectedChainEventTypeIdSelector = createSelector(
    evidenceChainEventTypesAdminUiSelector,
    ({ selectedId }) => selectedId
);

export const selectedChainEventTypeSelector = createSelector(
    chainEventTypeByIdSelector,
    selectedChainEventTypeIdSelector,
    (chainEventTypeById, selectedChainEventTypeId) => chainEventTypeById(selectedChainEventTypeId)
);

export const chainEventTypesAdminListSelector = createSelector(
    chainEventTypesSelector,
    selectedChainEventTypeIdSelector,
    (chainEventTypes, selectedId) =>
        _(chainEventTypes)
            .values()
            .map(({ id, name, expiredDateUtc }) => ({
                path: `/admin/evidence/chain-event-types/${id}`,
                key: id,
                title: name,
                status: isExpired(expiredDateUtc)
                    ? attributeStatuses.EXPIRED
                    : attributeStatuses.ACTIVE,
                selected: selectedId === id,
            }))
            .sortBy('title')
            .value()
);

export const chainEventTypeFormIsSubmittingSelector = createSelector(
    evidenceChainEventTypesAdminUiSelector,
    (evidenceChainEventTypesAdminUi) => get(evidenceChainEventTypesAdminUi, 'isSubmitting')
);

export const chainEventTypeSubmissionErrorSelector = createSelector(
    evidenceChainEventTypesAdminUiSelector,
    (evidenceChainEventTypesAdminUi) => get(evidenceChainEventTypesAdminUi, 'errorMessage')
);

// Helpers

const chainEventTypeDisplayOption = (chainEventCategory) => {
    return {
        value: chainEventCategory.name,
        display: getDisplayValueForChainEventTypeCategory(chainEventCategory.name),
    };
};

export function getChainEventCategoryOptions() {
    const chainEventTypeCategories = values(ChainEventCategoryEnum);

    return _(chainEventTypeCategories).map(chainEventTypeDisplayOption).sortBy('display').value();
}

export function getInheritableChainEventCategoriesOptions() {
    return filter(getChainEventCategoryOptions(), ({ value }) =>
        includes(inheritableChainEventCategories, value)
    );
}

export const resetForm = (formName) => {
    return (dispatch, getState, { formsRegistry }) => {
        dispatch(clearChainEventTypeErrors());
        formsRegistry.maybeDeferredOperation(formName, undefined, (form) => {
            form.resetModel();
            form.resetUi();
        });
    };
};

export const onSubmit = (router) => {
    return (dispatch, getState, { formsRegistry, nexus }) => {
        const createChainEventTypeAdminForm = formsRegistry.get(
            RefContextEnum.FORM_CREATE_CHAIN_EVENT_TYPE.name
        );

        if (!createChainEventTypeAdminForm) {
            return;
        }
        createChainEventTypeAdminForm.submit().then(() => {
            dispatch(upsertNewChainEventTypeStart());
            const formModel = createChainEventTypeAdminForm.get();
            const { id } = formModel;
            const isCreatingNewChainEventType = !id;

            const resourceMethod = isCreatingNewChainEventType
                ? chainEventTypeResource.createChainEventType
                : chainEventTypeResource.updateChainEventType;

            resourceMethod(formModel)
                .then((chainEventType) => {
                    dispatch(
                        nexus.withEntityItems(
                            {
                                [CHAIN_EVENT_TYPES_NEXUS_STATE_PROP]: [chainEventType],
                            },
                            upsertNewChainEventTypeSuccess()
                        )
                    );

                    if (isCreatingNewChainEventType) {
                        router.push({
                            pathname: `/admin/evidence/chain-event-types/${chainEventType.id}`,
                        });
                    }
                })
                .catch((error) => dispatch(upsertNewChainEventTypeFailure(error.message)));
        });
    };
};

export default function evidenceChainEventTypesUiReducer(
    state = {
        selectedId: undefined,
        isSubmitting: false,
        errorMessage: undefined,
    },
    action
) {
    switch (action.type) {
        case SET_SELECTED_CHAIN_EVENT_TYPE_ID:
            return {
                ...state,
                selectedId: action.payload.selectedId,
                errorMessage: undefined,
            };
        case UPSERT_CHAIN_EVENT_TYPE_START:
            return {
                ...state,
                isSubmitting: true,
                errorMessage: undefined,
            };
        case UPSERT_CHAIN_EVENT_TYPE_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                errorMessage: action.payload.errorMessage,
            };
        case UPSERT_CHAIN_EVENT_TYPE_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                errorMessage: undefined,
            };
        case CLEAR_CHAIN_EVENT_TYPE_ERRORS:
            return {
                ...state,
                errorMessage: undefined,
            };
        default:
            return state;
    }
}

import _ from 'lodash';

import recordNumberChangeActionTypes from '../actions/types/recordNumberChangeActionTypes';

const initialUiState = {
    loading: false,
    changeEnabled: false,
    validRecordNumber: null,
    handleSubmit: _.noop,
    message: null,
    error: false,
};

export function recordNumberChangeUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case recordNumberChangeActionTypes.CLEAR_RECORD_NUMBER_CHANGE_MODAL:
            return initialUiState;
        case recordNumberChangeActionTypes.UPDATE_RECORD_NUMBER_START:
            return {
                ...state,
                loading: true,
            };
        case recordNumberChangeActionTypes.UPDATE_RECORD_NUMBER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case recordNumberChangeActionTypes.UPDATE_RECORD_NUMBER_FAILURE:
            return {
                ...state,
                loading: false,
                message: action.payload,
                error: true,
            };
        case recordNumberChangeActionTypes.VALIDATE_RECORD_NUMBER_UPDATE_START:
            return {
                ...state,
                loading: true,
            };
        case recordNumberChangeActionTypes.VALIDATE_RECORD_NUMBER_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                validRecordNumber: action.payload.newRecordNumber,
            };
        case recordNumberChangeActionTypes.VALIDATE_RECORD_NUMBER_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                message: action.payload,
                error: true,
            };
        case recordNumberChangeActionTypes.ENABLE_RECORD_NUMBER_UPDATE:
            return {
                ...state,
                message: action.payload,
                changeEnabled: true,
            };
        case recordNumberChangeActionTypes.DISABLE_RECORD_NUMBER_UPDATE:
            return {
                ...state,
                message: action.payload,
                changeEnabled: false,
            };
        case recordNumberChangeActionTypes.STORE_HANDLE_SUBMIT:
            return {
                ...state,
                handleSubmit: action.payload,
            };
        default:
            return state;
    }
}

export function recordNumberChangeFormReducer(state, action) {
    switch (action.type) {
        case recordNumberChangeActionTypes.VALIDATE_RECORD_NUMBER_UPDATE_SUCCESS:
            return {
                ...state,
                newRecordNumber: {
                    ...state.newRecordNumber,
                    value: action.payload.newRecordNumber,
                },
            };
        default:
            return state;
    }
}

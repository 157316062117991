import React from 'react';

import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../legacy-redux/components/core/Modal';
import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import { exportNibrsDeleteHistoryModalContext } from '../configuration';

const strings = componentStrings.compliance.ComplianceDeleteButton;

type ComplianceDeleteHistoryButtonProps = {
    className?: string;
    disabled: boolean;
    deleteComplianceHistory: () => void;
    openDownloadModal: () => void;
};

const modalContentStyles = {
    minHeight: 'auto',
    fontSize: '13px',
};

const DownloadButtonWrapper = styled.div`
    width: 30%;
    display: inline-block;
    vertical-align: top;
    text-align: right;
`;

const DeleteHistoryButton = styled(Button)`
    margin-top: 16px;
    padding: 6px 24px 6px;
`;

const ComplianceDeleteHistoryButton: React.FC<ComplianceDeleteHistoryButtonProps> = (props) => {
    const { className, disabled, deleteComplianceHistory, openDownloadModal } = props;

    return (
        <DownloadButtonWrapper className={className}>
            <Modal
                title={strings.modals.deleteConfirmation.title}
                context={exportNibrsDeleteHistoryModalContext}
                okText={strings.modals.confirm}
                cancelText={strings.modals.cancel}
                onSave={deleteComplianceHistory}
                shouldCloseOnOverlayClick={false}
                contentStyle={modalContentStyles}
            >
                {strings.modals.deleteConfirmation.message}
            </Modal>
            <DeleteHistoryButton
                className={buttonTypes.PRIMARY}
                disabled={disabled}
                onClick={openDownloadModal}
            >
                {strings.label}
            </DeleteHistoryButton>
        </DownloadButtonWrapper>
    );
};

export default ComplianceDeleteHistoryButton;

import React from 'react';
import styled from 'styled-components';
import { map, get } from 'lodash';
import { Text } from 'arc';
import { RmsHydratedItem, EntityTypeEnumType } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { EntitySearchNotification } from '../../../../core/entity-search/EntitySearchResults';
import VehiclePill from './VehiclePill';

const Container = styled.div`
    margin-top: 15px;
`;

const strings = componentStrings.reports.core.ItemSidePanel;
const entitySearchStrings = componentStrings.core.entitySearch;

type VehicleSearchResultsProps = {
    items: RmsHydratedItem[];
    searchType: EntityTypeEnumType;
    query?: string;
    onResultClick: () => void;
    error?: {
        message?: string;
    };
};

const VehicleSearchResults: React.FC<VehicleSearchResultsProps> = ({
    items,
    searchType,
    query,
    error,
    onResultClick,
}) => {
    const showErrorMessage = query && error;

    return (
        <Container>
            {showErrorMessage && (
                // @ts-expect-error EntitySearchNotification props untyped
                <EntitySearchNotification error={true}>
                    {entitySearchStrings.error}
                    <br />
                    {get(error, 'message')}
                </EntitySearchNotification>
            )}
            <Text variant="bodyMd" style={{ marginBottom: 8 }}>
                {strings.searchResults(items.length)}
            </Text>
            {map(items, (item) => (
                <VehiclePill
                    key={item.id}
                    item={item}
                    type={searchType}
                    onResultClick={onResultClick}
                    query={query}
                />
            ))}
        </Container>
    );
};

export default VehicleSearchResults;

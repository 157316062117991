import { RefContextEnum } from '@mark43/rms-api';
import { createFormConfiguration, _Form } from 'markformythree';
import { get } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const narrativeFormName = RefContextEnum.FORM_NARRATIVE.name;

export const createNarrativeForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: narrativeFormName,
        onValidate: createArbiterMFTValidationHandler(
            arbiter,
            narrativeFormName,
            formatFieldByName
        ),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            narrative: {
                fieldName: fields.REPORT_NARRATIVE,
            },
        }),
    });
};

export const refreshNarrativeForm = (currentReportId) => (dispatch, getState) => {
    const state = getState();
    const reports = reportsSelector(state);
    return {
        narrative: get(reports[currentReportId], 'narrative') || '',
    };
};

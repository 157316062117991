import { pickBy } from 'lodash';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFormModel } from '../../../../../legacy-redux/helpers/formHelpers';
import usageLogsSearchFieldsetViewModel from './usageLogsSearchFieldsetForm';

/**
 * Convert the given search query model to form state. While the query is flat,
 *   the form state has a nested structure. The elastic query shape for
 *   unassigned reports is the same as for advanced search reports.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
function convertUsageLogsSearchElasticQueryToFormModel(elasticQuery) {
    return buildFormModel(
        pickBy(elasticQuery, () => true),
        usageLogsSearchFieldsetViewModel
    );
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching. The elastic query shape for unassigned
 *   reports is the same as for advanced search reports.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
function convertUsageLogsSearchFormModelToElasticQuery(formModel = {}) {
    const excludedIpAddresses = formModel.excludedIpAddresses
        ? [formModel.excludedIpAddresses]
        : [];
    return {
        ...formModel,
        excludedIpAddresses,
    };
}

const usageLogsSearchForm = createFormModule({
    formName: formClientEnum.USAGE_LOGS,
    fieldViewModels: usageLogsSearchFieldsetViewModel,
    convertToFormModel: convertUsageLogsSearchElasticQueryToFormModel,
    convertFromFormModel: convertUsageLogsSearchFormModelToElasticQuery,
});

/**
 * Module of the usage logs search/filter form.
 * @type {Object}
 */
export default usageLogsSearchForm;

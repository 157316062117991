import React from 'react';
import styled from 'styled-components';
import { Button as ArcButton, ButtonProps as ArcButtonProps, styled as arcStyled } from 'arc';
import { LinkProps as ReactRouterLinkProps } from 'react-router';
import { AnalyticsPropertyEnum } from '../../analytics/constants/analyticsEnum';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';
import Link, { LinkProps } from './links/Link';

// This is in place of ARC's current ButtonGroup which doesn't handle wrapping buttons well.
// Tracked here: https://mark43.atlassian.net/browse/CORE-2106
const StyledButtonGroup = styled.div<{ $spacing?: React.CSSProperties['gap'] }>`
    width: 100%;
    display: flex;
    gap: ${(props) => props.$spacing ?? 'var(--arc-space-2)'};
    flex-wrap: wrap;
`;

export const ButtonGroup = ({
    spacing,
    children,
    style,
}: {
    spacing?: React.CSSProperties['gap'];
    children: React.ReactNode;
    style?: React.CSSProperties;
}) => {
    return (
        <StyledButtonGroup $spacing={spacing} style={style}>
            {children}
        </StyledButtonGroup>
    );
};

// If we can be good about organizing the min-widths
// of the card in one place, we can have a holistic
// view of places where we want buttons of a fixed width,
// and what those fixed widths are
//
// These keys are not intuitively "sm", "md", "lg"
// because we don't know what the different sizings are right now.
// For now, we can just catalog them by usage and once we have them,
// we can easily fix these keys later
const minWidths = {
    // Used for VALIDATE, APPROVE / REJECT buttons
    SIDE_PANEL_FOOTER: 80,
};

// Using styled from arc despite the console error "Function components cannot be given refs.".
// Without using styled from arc, the button styling isn't applied when used as={Link} and instead visually renders a Link.
const StyledArcButton = arcStyled(ArcButton)<{
    $isTextTransformNone?: boolean;
    minWidth?: keyof typeof minWidths;
}>`
    ${(props) => props.minWidth && `min-width: ${minWidths[props.minWidth]}px;`}
    // TODO: Temporary hack until we have card headers in Arc
    // (without this, the disabled flat button is the same color as the card header)
    &.arc-button--flat:disabled {
        color: var(--arc-colors-text-secondary);
    }

    text-transform: ${(props) => (props.$isTextTransformNone ? 'none' : 'uppercase')};
` as typeof ArcButton;

export type ButtonProps = Omit<ArcButtonProps, 'as'> & {
    /**
     * Override RMS default text-transform: uppercase;
     */
    isTextTransformNone?: boolean;
    // Augment the ArcButton with a `minWidth` prop
    minWidth?: keyof typeof minWidths;
    testId?: string;
};

type ButtonAsLink = ButtonProps & {
    asLink: true;
} & Omit<ReactRouterLinkProps, 'onClick' | 'as'> &
    Pick<LinkProps, 'openInNewTab'>;

type ButtonAsButton = ButtonProps & {
    asLink?: false;
    to?: never;
};

type ButtonOrLinkProps = ButtonAsButton | ButtonAsLink;

export const Button = React.forwardRef<HTMLButtonElement, ButtonOrLinkProps>(
    ({ testId, onClick, children, isTextTransformNone, asLink = false, ...props }, ref) => {
        const { filteredTrack } = useAnalytics();
        const handleClickWithTrack = React.useCallback(
            (e: React.MouseEvent<HTMLButtonElement>) => {
                filteredTrack(
                    {
                        [AnalyticsPropertyEnum.BUTTON]: testId,
                    },
                    testId
                );
                onClick?.(e);
            },
            [onClick, filteredTrack, testId]
        );

        if (asLink) {
            return (
                <StyledArcButton
                    {...props}
                    as={Link}
                    $isTextTransformNone={isTextTransformNone}
                    ref={ref}
                    data-test-id={testId}
                >
                    {children}
                </StyledArcButton>
            );
        }

        return (
            <StyledArcButton
                {...props}
                as={undefined}
                $isTextTransformNone={isTextTransformNone}
                ref={ref}
                data-test-id={testId}
                onClick={handleClickWithTrack}
            >
                {children}
            </StyledArcButton>
        );
    }
);

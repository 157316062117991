import _, { get, some, isUndefined } from 'lodash';
import { ChainEvent, CustodyEventsView } from '@mark43/evidence-api';
import { createSelector } from 'reselect';

import { inPoliceCustodyChainEventTypeSelector } from '../../../chain-event-types/state/data';
import {
    storeChainOfCustodies,
    chainOfCustodiesSelector,
} from '../../../chain-of-custodies/state/data';
import { storeItemEvidenceStates } from '../../../item-evidence-states/state/data';
import { itemProfilesInReportSelector } from '../../../item-profiles/state/data';
import { sortItemProfilesSelector } from '../../../item-reporting-event-links/state/ui';
import getChainEventsResource from '../../resources/chainEventsResource';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'chainEvents';

const chainEventsModule = createNormalizedModule<ChainEvent>({
    type: NEXUS_STATE_PROP,
});

// ACTION TYPES
const CREATE_CHAIN_EVENTS_START = 'chain-events/CREATE_CHAIN_EVENTS_START';
const CREATE_CHAIN_EVENTS_SUCCESS = 'chain-events/CREATE_CHAIN_EVENTS_SUCCESS';
const CREATE_CHAIN_EVENTS_FAILURE = 'chain-events/CREATE_CHAIN_EVENTS_FAILURE';

// ACTIONS
const storeChainEvents = chainEventsModule.actionCreators.storeEntities;

function createChainEventsStart() {
    return {
        type: CREATE_CHAIN_EVENTS_START,
    };
}
function createChainEventsSuccess() {
    return {
        type: CREATE_CHAIN_EVENTS_SUCCESS,
    };
}
function createChainEventsFailure(errorMessage: string) {
    return {
        type: CREATE_CHAIN_EVENTS_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Create chain of custody events.
 */
export function createChainEvents(
    chainEvents: ChainEvent[]
): ClientCommonAction<Promise<CustodyEventsView>> {
    const chainEventsResource = getChainEventsResource();

    return function (dispatch) {
        dispatch(createChainEventsStart());
        return chainEventsResource
            .createEvents(chainEvents)
            .then((response: CustodyEventsView) => {
                const { chainOfCustodies, chainEvents, itemEvidenceStates } = response;
                dispatch(createChainEventsSuccess());
                dispatch(storeChainOfCustodies(chainOfCustodies));
                dispatch(storeChainEvents(chainEvents));
                dispatch(storeItemEvidenceStates(itemEvidenceStates));
                return response;
            })
            .catch((err: Error) => {
                dispatch(createChainEventsFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const chainEventsSelector = chainEventsModule.selectors.entitiesSelector;
export const chainEventsWhereSelector = chainEventsModule.selectors.entitiesWhereSelector;

/**
 * Whether the given item has at least one chain event.
 */
export const itemHasChainEventsSelector = createSelector(
    chainOfCustodiesSelector,
    chainEventsSelector,
    (chainOfCustodies, chainEvents) => (entityId?: number, custodialReportId?: number) =>
        some(
            chainOfCustodies,
            ({ id, masterItemId, reportId }) =>
                (isUndefined(custodialReportId) || reportId === custodialReportId) &&
                masterItemId === entityId &&
                some(chainEvents, { chainOfCustodyId: id })
        )
);

/**
 * Whether the given item's current chain of custody status is In Police Custody. This is an evidence check which is
 * separate from the RMS field propertyStatus.isInPoliceCustody.
 *
 * You may need to use this in conjunction with itemHasChainEventsSelector if it's possible the item has no chain of
 * custody at all.
 */
export const itemCoCStatusIsInPoliceCustodySelector = createSelector(
    chainOfCustodiesSelector,
    chainEventsSelector,
    inPoliceCustodyChainEventTypeSelector,
    (chainOfCustodies, chainEvents, inPoliceCustodyChainEventType) => (masterItemId: number) => {
        if (!inPoliceCustodyChainEventType) {
            return false;
        }
        const inPoliceCustodyChainEventTypeId = get(inPoliceCustodyChainEventType, 'id');
        const latestChainEventTypeId = _.chain(chainEvents)
            .filter(
                ({ chainOfCustodyId }) =>
                    masterItemId === get(chainOfCustodies[chainOfCustodyId], 'masterItemId')
            )
            .orderBy(['eventDateUtc', 'id'], ['desc', 'desc'])
            .head()
            .get('eventTypeId')
            .value();
        if (!inPoliceCustodyChainEventTypeId || !latestChainEventTypeId) {
            return false;
        }
        return inPoliceCustodyChainEventTypeId === latestChainEventTypeId;
    }
);

/**
 * Item profile ids in the given report with at least 1 chain event (and with at
 *   least 1 property status), sorted by REN-sequence number.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const itemProfileIdsInReportWithCoCSelector = createSelector(
    itemProfilesInReportSelector,
    itemHasChainEventsSelector,
    sortItemProfilesSelector,
    (itemProfilesInReport, itemHasChainEvent, sortItemProfiles) => (reportId: number) =>
        _(itemProfilesInReport(reportId))
            .filter((itemProfile) => itemHasChainEvent(itemProfile.masterItemId, reportId))
            .sortBy(sortItemProfiles as any)
            .map('id')
            .value()
);
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * Item profile ids in the given report without any chain events (and with at
 *   least 1 property status).
 */
export const itemProfileIdsInReportWithoutCoCSelector = createSelector(
    itemProfilesInReportSelector,
    itemHasChainEventsSelector,
    (itemProfilesInReport, itemHasChainEvent) => (reportId: number) =>
        _(itemProfilesInReport(reportId))
            .reject((itemProfile) => itemHasChainEvent(itemProfile.masterItemId, reportId))
            .map('id')
            .value()
);

// REDUCER
export default chainEventsModule.reducerConfig;

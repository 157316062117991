import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'arc';
import { EntityTypeEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useEFileContext } from '../../../hooks';
import { openTaskSidePanelForEntity } from '../../../../tasks/core/state/ui';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.eFiles.tasks;

export const AddTaskButton = () => {
    const dispatch = useDispatch();
    const { getters } = useEFileContext();
    const eFile = getters.efile.getEFile();
    const eFileId = eFile?.efile.id;

    const openTaskSidePanel = useCallback(() => {
        dispatch(
            openTaskSidePanelForEntity([
                { entityId: eFileId, entityType: EntityTypeEnum.E_FILE.name },
            ])
        );
    }, [dispatch, eFileId]);

    if (!eFile || !eFileId) {
        return null;
    }

    return (
        <Button
            variant="solid"
            leadingVisual="Add"
            onClick={openTaskSidePanel}
            isDisabled={!eFile.canEdit}
            data-test-id={testIds.NEW_TASK_BUTTON}
        >
            {strings.header.addItems}
        </Button>
    );
};

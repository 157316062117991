import { mapValues } from 'lodash';
import { createSelector } from 'reselect';
import { MiniUserView } from '@mark43/rms-api';

import { miniUsersSelector } from '../data';
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';
import { allMiniUserFormats } from '../../utils/miniUsersHelpers';

export const miniUserViewModelsSelector = createSelector(miniUsersSelector, (miniUsers) => {
    const viewModel = buildViewModel<MiniUserView>({
        mappers: [
            (miniUser) => ({
                display: allMiniUserFormats(miniUser),
            }),
        ],
    });

    return mapValues(miniUsers, viewModel);
});

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const locationCautionsFormFieldViewModels = {
    locationCaution: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'locationCaution',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'locationCautionAttrId',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'description',
            },
            {
                key: 'startDateUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'endDateUtc',
            },
        ]),
    },
};

function convertToFormModel({ locationCaution = {} }) {
    return {
        locationCaution: {
            ...locationCaution,
        },
    };
}

function convertFromFormModel({ locationCaution = {} }) {
    return locationCaution;
}

/**
 * Module of the location cautions admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.LOCATION_CAUTIONS_ADMIN,
    fieldViewModels: locationCautionsFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

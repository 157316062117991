import { RefContextEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { size, findIndex, reject, flatMap, map, sortBy, chain } from 'lodash';
import { Flex } from 'arc';
import { lifecycleOptions, Fieldset, Observer } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { convertNameNameLinkTypeToOptions } from '~/client-common/helpers/linkTypesHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { dateTimeFormats, formatISODate } from '~/client-common/core/dates/utils/dateHelpers';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_ORGANIZATION_LABEL,
    DISPLAY_ONLY_RELATIONSHIP_TYPE_LABEL,
} from '~/client-common/core/enums/universal/fields';

import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTNItems } from '../../../../core/forms/components/NItems';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';

import { MFTLinkTypeSelect } from '../../../../core/forms/components/selects/LinkTypeSelect';
import InvolvedNamesCardTabs from '../../../../../legacy-redux/components/reports/InvolvedNamesCardTabs';
import { NoResults } from '../../../../core/components/NoResults';
import Divider from '../../../../core/components/Divider';
import { renderRemoveButton } from '../../../../core/names/components/nameFormNItemsRenderers';
import { NameRelationshipSuggestionPills } from './NameRelationshipSuggestionPills';

const strings = componentStrings.reports.InvolvedNamesCard;

const FormFieldsWrapper = styled.div`
    .mark43-react-delete-icon-text-link {
        position: absolute;
        right: 0;
        top: 3px;
        margin-top: 0;
    }
`;

const renderFields = ({
    path,
    nameEntityType,
    otherNameEntityType,
    form,
    isInverted,
    label,
    relationshipLinkTypeIndex,
    isRelationshipsPrefillEnabled = false,
}) => {
    const entityTypePair = isInverted
        ? [otherNameEntityType, nameEntityType]
        : [nameEntityType, otherNameEntityType];

    return (
        <>
            {/*
                This is not an arbiter field because this field will store
                an array of strings, but the actual field should be an array of integers.

                This is because linkType data looks like this:

                {
                    linkNameOne: 'Employee'
                    linkNameTwo: 'Employer',
                    id: 1
                }

                and we need options that support both display values for the same id:
                [
                    {
                        display: 'Employee',
                        value: "1-linkNameOne'
                    },
                    {
                        display: 'Employer',
                        value: "2-linkNameTwo'
                    }
                ]

                To work around this, let's use a hidden field, `linkOptionIds`, that is an arbiter field.
                Changes to this field, `displayLinkOptionIds`, will update `linkOptionIds`.

                Because validation is usually only triggered for a field when that
                field is blurred or changed, whenever `displayLinkOptionIds` is changed,
                we have to trigger a manual validation for for `linkOptionIds`,
                which does have arbiter rules attached to it
            */}
            <MFTLinkTypeSelect
                fieldNameAlternate={fields.DISPLAY_NAME_NAME_LINK_RELATIONSHIP}
                label={label}
                path="displayLinkOptionIds"
                onChange={(values) => {
                    const linkOptionIdsPath = isRelationshipsPrefillEnabled
                        ? `${path}[${relationshipLinkTypeIndex}].linkOptionIds`
                        : `${path}.linkOptionIds`;

                    // set will implicitly call `validate` on the same path
                    form.set(
                        linkOptionIdsPath,
                        isRelationshipsPrefillEnabled
                            ? !!values
                                ? parseInt(values, 10)
                                : undefined
                            : map(values, (value) => parseInt(value, 10))
                    );
                }}
                multiple={!isRelationshipsPrefillEnabled}
                length="lg"
                entityTypePairs={[entityTypePair]}
                mapLinkTypesToOptions={(linkTypes) =>
                    sortBy(
                        flatMap(linkTypes, (linkType) => {
                            return convertNameNameLinkTypeToOptions(
                                linkType,
                                isInverted ? otherNameEntityType : nameEntityType,
                                isInverted
                            );
                        }),
                        'display'
                    )
                }
                hideLinkTypes={true}
            />
            <ArbiterMFTText path="description" />
            <FeatureFlagged flag="RMS_RELATIONSHIP_PREFILL_ENABLED">
                <Flex flexWrap="wrap" width="100%">
                    <ArbiterMFTDatePicker
                        width="135px"
                        path="effectiveFrom"
                        variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                    />
                    <ArbiterMFTDatePicker
                        width="135px"
                        path="effectiveTo"
                        variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                    />
                </Flex>
            </FeatureFlagged>
        </>
    );
};

export function RelationshipsCardForm({ involvedNameViewModels, selectedTab, onSelectTab }) {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isRelationshipsPrefillEnabled = applicationSettings.RMS_RELATIONSHIP_PREFILL_ENABLED;
    const {
        DISPLAY_ONLY_ORGANIZATION_LABEL: organizationLabel,
        DISPLAY_ONLY_RELATIONSHIP_TYPE_LABEL: relationshipTypeLabel,
    } = useFields([DISPLAY_ONLY_ORGANIZATION_LABEL, DISPLAY_ONLY_RELATIONSHIP_TYPE_LABEL]);
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={RefContextEnum.FORM_RELATIONSHIPS.name}
            context={RefContextEnum.FORM_RELATIONSHIPS.name}
            render={(form) => (
                <Observer
                    subscriptions={{
                        forceRender: 'forceRender',
                    }}
                    render={() => {
                        return (
                            <InvolvedNamesCardTabs
                                selectedTab={selectedTab}
                                onSelectTab={(key) => onSelectTab(key)}
                                viewModels={involvedNameViewModels}
                            >
                                {(involvedNameViewModel) => {
                                    const {
                                        nameId,
                                        nameEntityType,
                                        masterPersonId,
                                    } = involvedNameViewModel;
                                    const otherInvolvedNameViewModels = reject(
                                        involvedNameViewModels,
                                        {
                                            nameId,
                                        }
                                    );

                                    if (size(involvedNameViewModels) === 1) {
                                        return (
                                            <NoResults
                                                marginTop="26px"
                                                marginBottom="26px"
                                                width="65%"
                                            >
                                                {strings.relationships.addRelationships(
                                                    organizationLabel
                                                )}
                                            </NoResults>
                                        );
                                    }

                                    const relationships = form.get('relationships');

                                    return map(
                                        otherInvolvedNameViewModels,
                                        (otherNameViewModel, otherInvolvedNameViewModelsIndex) => {
                                            const {
                                                nameId: otherNameId,
                                                nameEntityType: otherNameEntityType,
                                                masterPersonId: otherNameMasterId,
                                            } = otherNameViewModel;

                                            // This is inefficient, but leaving it for now
                                            const relevantRelationshipIndex = findIndex(
                                                relationships,
                                                {
                                                    nameId,
                                                    nameEntityType,
                                                    otherNameId,
                                                    otherNameEntityType,
                                                }
                                            );

                                            const hasRelevantRelationshipIndex =
                                                relevantRelationshipIndex > -1;

                                            // If we didn't find form state for the relationship, check if we have form state
                                            // for the reverse relationship
                                            const index = hasRelevantRelationshipIndex
                                                ? relevantRelationshipIndex
                                                : findIndex(relationships, {
                                                      nameId: otherNameId,
                                                      nameEntityType: otherNameEntityType,
                                                      otherNameId: nameId,
                                                      otherNameEntityType: nameEntityType,
                                                  });

                                            const item = relationships[index];

                                            const path = `relationships[${index}]`;

                                            const relationshipLinkTypesPath = `${path}.relationshipLinkTypes`;
                                            const linkOptionIds = chain(
                                                form.get(relationshipLinkTypesPath)
                                            )
                                                .map('linkOptionIds')
                                                .compact()
                                                .value();

                                            return (
                                                !!item && (
                                                    <FeatureFlagged
                                                        flag="RMS_RELATIONSHIP_PREFILL_ENABLED"
                                                        fallback={
                                                            <Fieldset path={path} key={path}>
                                                                {renderFields({
                                                                    nameEntityType,
                                                                    otherNameEntityType,
                                                                    path,
                                                                    label: otherNameViewModel.display.nameNameLink(
                                                                        otherNameViewModel
                                                                    ),
                                                                    isInverted: !hasRelevantRelationshipIndex,
                                                                    form,
                                                                })}
                                                            </Fieldset>
                                                        }
                                                    >
                                                        <NameRelationshipSuggestionPills
                                                            nameFromId={masterPersonId}
                                                            nameToId={otherNameMasterId}
                                                            linkOptionIds={linkOptionIds}
                                                            relationshipLinkTypesPath={
                                                                relationshipLinkTypesPath
                                                            }
                                                            isInverted={
                                                                !hasRelevantRelationshipIndex
                                                            }
                                                            nameEntityType={nameEntityType}
                                                            otherNameEntityType={
                                                                otherNameEntityType
                                                            }
                                                            relationshipIndex={index}
                                                        />
                                                        <FormFieldsWrapper>
                                                            <ArbiterMFTNItems
                                                                formName={form.name}
                                                                path={relationshipLinkTypesPath}
                                                                key={relationshipLinkTypesPath}
                                                                defaultItemValue={{
                                                                    effectiveFrom: formatISODate(
                                                                        new Date(),
                                                                        dateTimeFormats.isoDate
                                                                    ),
                                                                }}
                                                                automaticallyIncludeDeleteButton={
                                                                    false
                                                                }
                                                                addText={relationshipTypeLabel}
                                                                renderRemoveButton={({
                                                                    removeItem,
                                                                    ...props
                                                                }) =>
                                                                    renderRemoveButton({
                                                                        ...props,
                                                                        removeItem: () => {
                                                                            removeItem();
                                                                            form.set(
                                                                                'forceRender',
                                                                                Math.random()
                                                                            );
                                                                        },
                                                                    })
                                                                }
                                                                render={({ index }) => {
                                                                    return (
                                                                        <div>
                                                                            {renderFields({
                                                                                nameEntityType,
                                                                                otherNameEntityType,
                                                                                path: relationshipLinkTypesPath,
                                                                                label: otherNameViewModel.display.nameNameLink(
                                                                                    otherNameViewModel
                                                                                ),
                                                                                isInverted: !hasRelevantRelationshipIndex,
                                                                                form,
                                                                                relationshipLinkTypeIndex: index,
                                                                                isRelationshipsPrefillEnabled,
                                                                            })}
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                            {otherInvolvedNameViewModelsIndex !==
                                                                otherInvolvedNameViewModels.length && (
                                                                <Divider />
                                                            )}
                                                        </FormFieldsWrapper>
                                                    </FeatureFlagged>
                                                )
                                            );
                                        }
                                    );
                                }}
                            </InvolvedNamesCardTabs>
                        );
                    }}
                />
            )}
        />
    );
}

import React from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import type { ClassValue } from 'classnames/types';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import { FloatLoadingGray } from '../../../../legacy-redux/components/core/Loading';
import { Tooltip } from '../../../core/components/tooltip';
import testIds from '../../../../core/testIds';

const strings = componentStrings.admin.global;

const StyledAdminFooter = styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    padding: 5px 20px;
    border-top: 1px solid ${(props) => props.theme.colors.cobaltBlue};
`;

type ClickHandler = (
    e?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
) => void;
interface AdminFooterProps {
    text?: React.ReactNode;
    loading?: boolean;
    error?: ClassValue;
    onSave?: ClickHandler;
    onCancel?: ClickHandler;
    onDelete?: ClickHandler;
    hideSave?: boolean;
    hideCancel?: boolean;
    hideDelete?: boolean;
    disabled?: boolean;
    saveText?: string;
    saveButtonDisabledTooltipText?: string;
    children?: React.ReactNode;
    className?: ClassValue;
}

export default function AdminFooter({
    text = '',
    loading = false,
    error,
    onSave = noop,
    onCancel = noop,
    onDelete,
    hideSave = false,
    hideCancel = false,
    hideDelete = false,
    disabled = false,
    saveText = strings.save,
    saveButtonDisabledTooltipText,
    children,
    className,
}: AdminFooterProps) {
    const cancelButton = (
        <Button disabled={loading} className={buttonTypes.SECONDARY} onClick={onCancel}>
            {strings.cancel}
        </Button>
    );

    const saveButton = (
        <Button
            disabled={disabled || loading}
            className={buttonTypes.PRIMARY}
            onClick={onSave}
            testId={testIds.ADMIN_FOOTER_SAVE}
        >
            {saveText}
        </Button>
    );

    const saveButtonWithTooltip =
        saveButtonDisabledTooltipText && disabled ? (
            <Tooltip side="top" hasButtonOffset content={saveButtonDisabledTooltipText}>
                {/* Tooltip doesn't work with the RMS button currently. Doesn't pass props down or something when it renders as child. This wrapper can be removed when these buttons are updated to ARC.
            Add float left to match the button layout. */}
                <span style={{ float: 'left' }}>{saveButton}</span>
            </Tooltip>
        ) : (
            saveButton
        );

    const deleteButton = onDelete && (
        <Button
            disabled={disabled || loading}
            className={buttonTypes.UTILITY}
            iconLeft={<Icon type={iconTypes.TRASH} />}
            onClick={onDelete}
        >
            {strings.delete}
        </Button>
    );

    return (
        <StyledAdminFooter
            className={classNames(className, { error })}
            data-test-id={testIds.ADMIN_FOOTER}
        >
            {!hideSave && saveButtonWithTooltip}
            {!hideCancel && cancelButton}
            {!hideDelete && deleteButton}
            {children}
            {loading && <FloatLoadingGray />}
            <div className="admin-footer-text">{text}</div>
        </StyledAdminFooter>
    );
}

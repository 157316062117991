import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';

import {
    formatAttributeWithOtherSelector,
    formatLinkAttributesSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { hydratedFieldContactByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/fieldContacts';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';

const mapStateToProps = createStructuredSelector({
    formatAttributeWithOther: formatAttributeWithOtherSelector,
    formatLinkAttributes: formatLinkAttributesSelector,
    hydratedFieldContactByReportId: hydratedFieldContactByReportIdSelector,
});

const FieldContactCardSummary = connect(mapStateToProps)(
    ({
        reportId,
        formatAttributeWithOther,
        formatLinkAttributes,
        hydratedFieldContactByReportId,
    }) => {
        const {
            fieldContact: {
                contactTypeAttrId,
                contactTypeOther,
                reasonForStopAttrId,
                reasonForStopOther,
                movingViolationNumber,
                seizureStartDateUtc,
                seizureEndDateUtc,
                wasStopScreenedBySupervisor,
                reasonableSuspicionNarrative,
                policeExperienceNarrative,
                contactDetailsNarrative,
                wasSubjectSearched,
                armedAndDangerousNarrative,
                didOfficerFindWeapon,
            },
            reportAttributes,
        } = hydratedFieldContactByReportId(reportId);

        return (
            <SummaryList labelWidth={220} contentWidth={320}>
                <CardSection>
                    <SummaryRow fieldName={fields.FIELD_CONTACT_CONTACT_TYPE_ATTR_ID}>
                        {formatAttributeWithOther({
                            attributeId: contactTypeAttrId,
                            other: contactTypeOther,
                            joinWith: ' - ',
                        })}
                    </SummaryRow>
                    <SummaryRowDate
                        fieldName={fields.FIELD_CONTACT_SEIZURE_START_DATE_UTC}
                        date={seizureStartDateUtc}
                    />
                    <SummaryRowDate
                        fieldName={fields.FIELD_CONTACT_SEIZURE_END_DATE_UTC}
                        date={seizureEndDateUtc}
                    />
                    <SummaryRow fieldName={fields.FIELD_CONTACT_WAS_STOP_SCREENED_BY_SUPERVISOR}>
                        {booleanToYesNo(wasStopScreenedBySupervisor)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.FIELD_CONTACT_REASONABLE_SUSPICION_NARRATIVE}>
                        {reasonableSuspicionNarrative}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.FIELD_CONTACT_WAS_SUBJECT_SEARCHED}>
                        {booleanToYesNo(wasSubjectSearched)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.FIELD_CONTACT_ARMED_AND_DANGEROUS_NARRATIVE}>
                        {armedAndDangerousNarrative}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.FIELD_CONTACT_DID_OFFICER_FIND_WEAPON}>
                        {booleanToYesNo(didOfficerFindWeapon)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_INVOLVED_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.WEAPON_INVOLVED.name
                        )}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.FIELD_CONTACT_POLICE_EXPERIENCE_NARRATIVE}>
                        {policeExperienceNarrative}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.FIELD_CONTACT_CONTACT_DETAILS_NARRATIVE}>
                        {contactDetailsNarrative}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.FIELD_CONTACT_REASON_FOR_STOP_ATTR_ID}>
                        {formatAttributeWithOther({
                            attributeId: reasonForStopAttrId,
                            other: reasonForStopOther,
                            joinWith: ' - ',
                        })}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_REASON_FOR_STOP_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.FIELD_CONTACT_REASON_FOR_STOP.name
                        )}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.FIELD_CONTACT_MOVING_VIOLATION_NUMBER}>
                        {movingViolationNumber}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_DISPOSITION_ATTRIBUTE_ID
                        }
                    >
                        {formatLinkAttributes(
                            reportAttributes,
                            AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name
                        )}
                    </SummaryRow>
                </CardSection>
            </SummaryList>
        );
    }
);

export default FieldContactCardSummary;

import React from 'react';
import { change } from 'redux-form-mark43';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import ImageUpload from '../../../../modules/core/forms/components/ImageUpload';

function DepartmentProfileAdminLogoUpload({
    currentDeptLogo,
    setDepartmentProfileLogoId,
    removeDepartmentProfilePhotoId,
}) {
    const imageUrl = getViewModelProperties(currentDeptLogo).path;
    return (
        <ImageUpload
            onRemove={() => removeDepartmentProfilePhotoId()}
            onSuccess={(file) => setDepartmentProfileLogoId(file.file.id)}
            imageUrl={imageUrl}
            height="90px"
            width="90px"
        />
    );
}

const mapDispatchToProps = (dispatch) => ({
    setDepartmentProfileLogoId: (departmentProfileLogoId) => {
        dispatch(
            change('departmentProfileAdmin', 'departmentProfileLogoId', departmentProfileLogoId)
        );
    },
    removeDepartmentProfilePhotoId() {
        dispatch(change('departmentProfileAdmin', 'removedDepartmentProfileLogoId', true));
        dispatch(change('departmentProfileAdmin', 'departmentProfileLogoId', null));
    },
});

export default compose(connect(null, mapDispatchToProps))(DepartmentProfileAdminLogoUpload);

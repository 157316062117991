import React, { useState } from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

import Text from '../../../core/forms/components/Text';
import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';

const strings = componentStrings.core.ExportAttachmentsSidePanel.FilterInput;

const InputContainer = styled.div`
    margin-top: 20px;
    position: relative;
    display: flex;

    & .mark43-form-field {
        flex-grow: 1;
        margin-right: 0;
    }

    & input {
        padding-left: 26px;
    }
`;
const SearchInputIcon = styled(Icon)`
    position: absolute;
    left: 4px;
    padding: 3px;
    top: 12px;
`;

export const FilterInput = React.memo(function FilterInput({ onChange, testId }) {
    const [value, setValue] = useState('');
    return (
        <InputContainer data-test-id={testId}>
            <Text
                onChange={(newValue) => {
                    setValue(newValue);
                    onChange(newValue);
                }}
                value={value}
                placeholder={strings.placeholder}
            />
            <SearchInputIcon type={iconTypes.SEARCH} size={iconSizes.SMALL} color="cobaltBlue" />
        </InputContainer>
    );
});

import { createSelector } from 'reselect';

import { sortedUserWebAppNotificationViewModelsSelector } from '~/client-common/core/domain/user-notifications/state/ui';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { openBox } from '../../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../../core/box/state/ui';

import { NUMBER_OF_NOTIFICATIONS_FOR_POPOVER } from '../../configuration';

const popoverContext = { name: boxEnum.NOTIFICATIONS_POPOVER };

// Actions
/**
 * Open the Notifications Popover
 */
export function openNotificationsPopover() {
    return openBox(popoverContext);
}

// Should be able to deprecate this when the RMS_ARC_NAVIGATION_ENABLED feature flag is removed.
// Selectors
/**
 * Return the isOpen state of Notifications Popover
 * @type {boolean}
 */
export const notificationsPopoverIsOpenSelector = createModalSelector(popoverContext, 'isOpen');

/**
 * N most recent user web app notification view models sorted most recent first
 * N determined by NUMBER_OF_NOTIFICATIONS_FOR_POPOVER
 */
export const mostRecentNotificationViewModelsSelector = createSelector(
    sortedUserWebAppNotificationViewModelsSelector,
    (sortedUserWebAppNotificationViewModels) =>
        sortedUserWebAppNotificationViewModels(0, NUMBER_OF_NOTIFICATIONS_FOR_POPOVER)
);

import React from 'react';

import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import Row from '../../../core/components/Row';
import { RRFLabelPrinterSelect } from '../../../core/forms/components/selects/LabelPrinterSelect';

import labelPrintingForm from '../state/forms/labelPrintingForm';

/**
 * Form for selecting a label printer.
 */
export default function LabelPrintingForm() {
    return (
        <ReactReduxForm {...labelPrintingForm}>
            <Row>
                <RRFLabelPrinterSelect length="lg" path="labelPrinterId" clearable={false} />
            </Row>
        </ReactReduxForm>
    );
}

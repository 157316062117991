import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Td, Tr, Icon, Text as ArcText, HStack } from 'arc';
import { noop } from 'lodash';
import { canFindOnly, canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
} from '../current-user/state/ui';
import testIds from '../../../core/testIds';

const strings = componentStrings.core.Table;

const TableRow = styled(Tr)<{
    $hideBorder: boolean;
    $isWarning: boolean;
    $isNewResult: boolean;
}>`
    ${(props) =>
        props.$isNewResult &&
        `
        margin-left: -2px;
        border-left: 2px solid var(--arc-colors-positive-default);`}
    border-top: ${(props) =>
        props.$isWarning && !props.$hideWarningBorder
            ? `6px solid var(--arc-colors-negative-default)`
            : 'none'};
    background-color: ${(props) =>
        props.$isWarning
            ? 'var(--arc-colors-negative-accent) !important'
            : props.$isRestricted
            ? 'var(--arc-colors-surface-accent) !important'
            : 'inherit'};
    ${(props) =>
        props.$isInteractive &&
        `
        &:hover {
            background-color: var(--arc-colors-interactive-hover) !important;
            cursor: pointer;
        }`}
`;

const RestrictedAccessWarningCell = styled(Td)`
    padding: var(--arc-space-1) var(--arc-space-3);
    background-image: url(../images/stripe_pattern.png);
    background-repeat: repeat;
    border-bottom: none;
`;

const RestrictedAccessRow = <R extends Row>({
    row,
    children,
    warning,
    ...otherProps
}: ElasticRowProps<R>) => {
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);

    const warningColSpan = React.Children.count(children);
    const permissionSet = row.permissionSet;
    const restrictedSubtext = canFindOnly(permissionSet) ? strings.canFindOnly : undefined;
    const subtext = row.isSealed ? strings.sealedRecord : restrictedSubtext;

    const hasCourtOrderAbility = currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_COURT_ORDERS);
    const rowIsClickable =
        row.isSealed && currentUserDepartmentId === row.departmentId && hasCourtOrderAbility;
    const onClickHandler = rowIsClickable ? otherProps.onClick : noop;

    return (
        <>
            <TableRow $isWarning={warning} $isRestricted $isNewResult={row.isNewResult}>
                <RestrictedAccessWarningCell colSpan={warningColSpan}>
                    <ArcText isItalic fontWeight="semibold" casing="uppercase">
                        {strings.restrictedAccess}
                    </ArcText>
                    {subtext && (
                        <HStack align="center">
                            {row.isSealed && <Icon color="negative.default" icon="Permissions" />}
                            <ArcText>{subtext}</ArcText>
                        </HStack>
                    )}
                </RestrictedAccessWarningCell>
            </TableRow>
            <TableRow
                $isRestricted
                $hideWarningBorder
                onClick={onClickHandler}
                $isNewResult={row.isNewResult}
                $isWarning={warning}
                $isInteractive={rowIsClickable}
                {...otherProps}
            >
                {children}
            </TableRow>
        </>
    );
};

const checkRowIsRestricted = (row: Row) =>
    !canRead(row.permissionSet) || canFindOnly(row.permissionSet);

type Row = Record<string, unknown>;

type ElasticRowProps<R extends Row> = JSX.IntrinsicElements['tr'] & {
    row: R;
    // If true it will change the background and border
    warning?: boolean;
};

/** This row is used for elastic results in the ARC based DataTable
 * If the permissionSet array does not have 'READ' permission it will
 * display "Restricted Access" and disable the row click (but not other links/buttons within) */
export function ElasticResultsDataTableRow<R extends Row>({
    row,
    children,
    warning,
    ...otherProps
}: ElasticRowProps<R>) {
    const isRestricted = checkRowIsRestricted(row);
    return !isRestricted ? (
        <TableRow
            {...otherProps}
            $isNewResult={row.isNewResult}
            data-test-id={testIds.TABLE_ROW}
            $isWarning={warning}
            $isInteractive={!!otherProps.onClick}
        >
            {children}
        </TableRow>
    ) : (
        <RestrictedAccessRow<R> row={row} warning={warning} {...otherProps}>
            {children}
        </RestrictedAccessRow>
    );
}

import _ from 'lodash';
import qs from 'qs';

/**
 * Determine whether the first string starts with the second string. The second
 *   argument can be an array of strings. The paths are expected to begin with
 *   `/`.
 */
export function pathStartsWith(path: string, startPaths: string | string[]): boolean {
    return typeof startPaths === 'string'
        ? _.startsWith(path, startPaths)
        : _.some(startPaths, (startPath) => _.startsWith(path, startPath));
}

// helper for the two functions below that are different in array format only
function stringifyQuery(
    arrayFormat: 'indices' | 'brackets' | 'repeat' | 'comma',
    params: {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        [s: string]: any;
    },
    options: { snakeCaseKeys?: boolean; includeQuestionMark?: boolean } = {}
) {
    options = _.defaults(options, {
        snakeCaseKeys: true,
        includeQuestionMark: true,
    });

    params = _.omitBy(params, (value) => value === null || value === undefined);

    if (options.snakeCaseKeys) {
        params = _.mapKeys(params, (value, key) => _.snakeCase(key));
    }

    const queryString = qs.stringify(params, {
        arrayFormat,
    });

    return options.includeQuestionMark && queryString ? `?${queryString}` : queryString;
}

/**
 * Format the given object of parameters into a query string. This function is
 *   for server urls only, since the array format (`key=value1&key=value2`) is
 *   different for client urls. All keys and values get encoded. `null` and
 *   `undefined` values are omitted. See `ajaxTests.js` for examples.
 * @param    [params] Key/value pairs.
 * @param    [options]
 * @param  [options.snakeCaseKeys=true] Whether to convert the keys to
 *   snake_case.
 * @param  [options.includeQuestionMark=true] Whether to include the
 *   leading `?`. If the query string is empty, the question mark is not
 *   included either way.
 */
export const serverStringifyQuery = _.partial(stringifyQuery, 'repeat');

/**
 * Format the given object of parameters into a query string. This function is
 *   for client urls only, since the array format (`key[]=value1&key[]=value2`)
 *   is different for server urls. All keys and values get encoded. `null` and
 *   `undefined` values are omitted. See `ajaxTests.js` for examples.
 * @param   [params] Key/value pairs.
 * @param   [options]
 * @param   [options.snakeCaseKeys=true] Whether to convert the keys to
 *   snake_case.
 * @param   [options.includeQuestionMark=true] Whether to include the
 *   leading `?`. If the query string is empty, the question mark is not
 *   included either way.
 * @return
 */
export const clientStringifyQuery = _.partial(stringifyQuery, 'brackets');

/**
 * Parse the given query string into an object. This helper is for client urls
 *   only, as server urls and external urls don't need to be parsed.
 * @param   queryString
 */
export function clientParseQueryString(queryString: string) {
    return qs.parse(queryString);
}

/**
 * Get the full url without the domain.
 * Same function as cad-common.
 */
export function getRelativeReference(location: Location) {
    const { pathname, search, hash } = location;
    return `${pathname}${search}${hash}`;
}

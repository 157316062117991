import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { sortedProfileVehiclesViewModelsSelector } from '../state/ui';
import NoDataBlock from '../../../core/components/NoDataBlock';
import ElasticModelWrapper from '../../../../legacy-redux/components/core/tables/ElasticModelWrapper';
import EntityProfileSection from './EntityProfileSection';
import EntityProfileVehiclesSummary from './EntityProfileVehiclesSummary';

const strings = componentStrings.entityProfiles.EntityProfileVehicles;

function EntityProfileVehicles({ profileVehicles }) {
    const contents = profileVehicles.length ? (
        <EntityProfileSection title={strings.vehiclesTitle}>
            {map(profileVehicles, (profileVehicle) => (
                <ElasticModelWrapper key={profileVehicle.id} entity={profileVehicle}>
                    {({ hasReadAccess, entity }) => (
                        <EntityProfileVehiclesSummary
                            hasReadAccess={hasReadAccess}
                            elasticVehicle={entity}
                        />
                    )}
                </ElasticModelWrapper>
            ))}
        </EntityProfileSection>
    ) : (
        <div className="no-data-container">
            <NoDataBlock>{strings.noVehiclesText}</NoDataBlock>
        </div>
    );
    return <div>{contents}</div>;
}

const mapStateToProps = createStructuredSelector({
    profileVehicles: sortedProfileVehiclesViewModelsSelector,
});

export default connect(mapStateToProps)(EntityProfileVehicles);

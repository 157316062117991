import { filter, includes } from 'lodash';
import { EntityTypeEnum } from '@mark43/rms-api';
import {
    storeCaseNotes,
    caseNotesSelector,
    NEXUS_STATE_PROP as CASE_NOTES_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/case-notes/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import caseResource from '../../../core/resources/caseResource';
import {
    getFolderContents,
    updateCaseNoteByIdInFolderContentView,
} from '../../../core/state/data/folderContents';
import { setCurrentCaseNoteId } from '../../../core/state/ui';
import { updateOrphanContents } from '../../../core/state/data/orphanedContents';

const ADD_NOTE_TO_CASE_START = 'case-notes/ADD_NOTE_TO_CASE_START';
const ADD_NOTE_TO_CASE_SUCCESS = 'case-notes/ADD_NOTE_TO_CASE_SUCCESS';
const ADD_NOTE_TO_CASE_FAILURE = 'case-notes/ADD_NOTE_TO_CASE_FAILURE';

function addNoteToCaseStart() {
    return {
        type: ADD_NOTE_TO_CASE_START,
    };
}

function addNoteToCaseSuccess(caseNote) {
    return {
        type: ADD_NOTE_TO_CASE_SUCCESS,
        payload: caseNote,
    };
}

function addNoteToCaseFailure(errMsg) {
    return {
        type: ADD_NOTE_TO_CASE_FAILURE,
        payload: errMsg,
    };
}

export function addNoteToCase(newNote, folderId) {
    return (dispatch, getState) => {
        dispatch(addNoteToCaseStart());
        return caseResource
            .addNoteToCase(newNote, folderId)
            .then((caseNote) => {
                dispatch(storeCaseNotes([caseNote]));
                // this doesn't do anything rn
                dispatch(addNoteToCaseSuccess(caseNote));
                if (
                    folderId &&
                    applicationSettingsSelector(getState()).RMS_CASE_FOLDERING_ENABLED
                ) {
                    dispatch(getFolderContents(folderId, EntityTypeEnum.CASE_NOTE.name));
                } else {
                    dispatch(updateOrphanContents());
                }
                return caseNote;
            })
            .catch((error) => {
                dispatch(addNoteToCaseFailure(error.message));
                throw error;
            });
    };
}

const UPDATE_CASE_NOTE_START = 'case-notes/UPDATE_CASE_NOTE_START';
const UPDATE_CASE_NOTE_SUCCESS = 'case-notes/UPDATE_CASE_NOTE_SUCCESS';
const UPDATE_CASE_NOTE_FAILURE = 'case-notes/UPDATE_CASE_NOTE_FAILURE';

function updateCaseNoteStart() {
    return {
        type: UPDATE_CASE_NOTE_START,
    };
}

function updateCaseNoteSuccess(caseNote) {
    return {
        type: UPDATE_CASE_NOTE_SUCCESS,
        payload: caseNote,
    };
}

function updateCaseNoteFailure(errMsg) {
    return {
        type: UPDATE_CASE_NOTE_FAILURE,
        payload: errMsg,
    };
}

export function updateCaseNote(caseNote) {
    return (dispatch, getState) => {
        const state = getState();
        const isCaseFolderingEnabled = !!applicationSettingsSelector(state)
            .RMS_CASE_FOLDERING_ENABLED;

        dispatch(updateCaseNoteStart());
        return caseResource
            .updateCaseNote(caseNote)
            .then((caseNote) => {
                dispatch(storeCaseNotes(caseNote));
                if (isCaseFolderingEnabled) {
                    dispatch(updateOrphanContents());
                }
                // this doesn't do anything yet
                dispatch(updateCaseNoteSuccess(caseNote));
            })
            .catch((error) => {
                dispatch(updateCaseNoteFailure(error.message));
                throw error;
            });
    };
}

export function updateCaseNoteInFolder(caseNote, folderId) {
    return (dispatch, getState) => {
        dispatch(updateCaseNoteStart());
        const state = getState();
        const isCaseFolderingEnabled = !!applicationSettingsSelector(state)
            .RMS_CASE_FOLDERING_ENABLED;

        return caseResource
            .updateCaseNote(caseNote)
            .then((caseNote) => {
                if (isCaseFolderingEnabled) {
                    dispatch(updateOrphanContents());
                }
                dispatch(updateCaseNoteByIdInFolderContentView({ caseNote, folderId }));
                dispatch(storeCaseNotes(caseNote));
            })
            .catch((error) => {
                dispatch(updateCaseNoteFailure(error.message));
                throw error;
            });
    };
}

const DELETE_CASE_NOTES_START = 'case-notes/DELETE_CASE_NOTES_START';
const DELETE_CASE_NOTES_SUCCESS = 'case-notes/DELETE_CASE_NOTES_SUCCESS';
const DELETE_CASE_NOTES_FAILURE = 'case-notes/DELETE_CASE_NOTES_FAILURE';

function removeCaseNotesStart() {
    return {
        type: DELETE_CASE_NOTES_START,
    };
}

function removeCaseNotesSuccess() {
    return {
        type: DELETE_CASE_NOTES_SUCCESS,
    };
}

function removeCaseNotesFailure(errMsg) {
    return {
        type: DELETE_CASE_NOTES_FAILURE,
        payload: errMsg,
    };
}

export function removeCaseNotes(noteIds) {
    return (dispatch, getState, { nexus: { withEntityItems, withRemove } }) => {
        dispatch(removeCaseNotesStart());
        return caseResource
            .deleteCaseNotes(noteIds)
            .then(() => {
                const caseNotes = caseNotesSelector(getState());
                const updatedCaseNotes = filter(caseNotes, ({ id }) => !includes(noteIds, id));

                dispatch(
                    withEntityItems(
                        {
                            [CASE_NOTES_NEXUS_STATE_PROP]: updatedCaseNotes,
                        },
                        withRemove(CASE_NOTES_NEXUS_STATE_PROP, {}, removeCaseNotesSuccess())
                    )
                );
                dispatch(setCurrentCaseNoteId(null));
                if (applicationSettingsSelector(getState()).RMS_CASE_FOLDERING_ENABLED) {
                    return dispatch(updateOrphanContents());
                }
            })
            .catch((error) => {
                dispatch(removeCaseNotesFailure(error.message));
                // handle this error
                throw error;
            });
    };
}

import { createSelector } from 'reselect';
import { EntityTypeEnumType } from '@mark43/rms-api';
import { groupBy, map, compact, reduce, sortBy } from 'lodash';
import { trafficCrashEntityDetailsWhereSelector } from '../data';
import { trafficCrashCustomFieldsSelector } from '../../../traffic-crash-custom-fields/state/data';
import { trafficCrashCustomAttributesSelector } from '../../../traffic-crash-custom-attributes/state/data';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

export const trafficCrashEntityDetailViewsByEntitySelector = createSelector(
    trafficCrashEntityDetailsWhereSelector,
    trafficCrashCustomFieldsSelector,
    trafficCrashCustomAttributesSelector,
    (trafficCrashEntityDetailsWhere, trafficCrashCustomFields, trafficCrashCustomAttributes) => (
        entityId: number,
        entityType: EntityTypeEnumType
    ) => {
        const trafficCrashEntityDetails = trafficCrashEntityDetailsWhere({
            entityId,
            entityType,
        });
        const entityDetailsByCustomFieldId = groupBy(trafficCrashEntityDetails, 'customFieldId');

        const keyValueByCustomFieldId = reduce<
            string,
            Record<string, { key: string; value: string }>
        >(
            Object.keys(entityDetailsByCustomFieldId),
            (acc, customFieldId) => {
                const customField = trafficCrashCustomFields[customFieldId];
                const entityDetails = entityDetailsByCustomFieldId[customFieldId];

                if (!customField) {
                    return acc;
                }

                const key = customField.displayName;
                const value = joinTruthyValues(
                    map(entityDetails, (entityDetail) => {
                        const { freeTextValue, customAttributeId } = entityDetail;
                        const customAttribute = !!customAttributeId
                            ? trafficCrashCustomAttributes[customAttributeId]
                            : undefined;
                        return joinTruthyValues(
                            [customAttribute?.displayValue, freeTextValue],
                            ': '
                        );
                    }),
                    ', '
                );

                acc[customFieldId] = {
                    key,
                    value,
                };
                return acc;
            },
            {}
        );
        return compact(
            map(
                sortBy(Object.values(trafficCrashCustomFields), 'displayName'),
                (customField) => keyValueByCustomFieldId[customField.id]
            )
        );
    }
);

import { noop } from 'lodash';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { HStack, IconButton } from 'arc';
import { LinkTypesEnum } from '@mark43/rms-api';

import { ImportAttachmentsSidePanel } from '../sidepanel/ImportAttachmentsSidePanel';
import { ImportableAttachmentsProvider } from '../sidepanel/content/ImportableAttachmentsContent';
import { EFileMaterialHeader } from './EFileMaterialHeader';
import { EFileMaterialAttachmentsGrid } from './EFileMaterialAttachmentsGrid';
import { EFileMaterialAttachmentsProvider } from './EFileMaterialAttachmentsProvider';

export const EFileMaterialAttachmentsPage = ({
    params,
}: RouteComponentProps<{ eFileId: string }, Record<string, unknown>>): JSX.Element => {
    const eFileId = parseInt(params.eFileId, 10);
    const [onImport, setOnImport] = React.useState<() => void>(noop);

    return (
        <>
            <EFileMaterialAttachmentsProvider>
                <EFileMaterialHeader eFileId={eFileId} linkType={LinkTypesEnum.ATTACHMENT_IN_EFILE}>
                    <HStack>
                        <IconButton
                            aria-label="Filter"
                            icon="Filter"
                            size="md"
                            variant="outline"
                            disabled={true}
                        />
                        <ImportableAttachmentsProvider>
                            <ImportAttachmentsSidePanel onImport={onImport} />
                        </ImportableAttachmentsProvider>
                    </HStack>
                </EFileMaterialHeader>
                <EFileMaterialAttachmentsGrid eFileId={eFileId} setOnImport={setOnImport} />
            </EFileMaterialAttachmentsProvider>
        </>
    );
};

import { Popover, PopoverTrigger, Tooltip, PopoverContent as _PopoverContent, cssVar } from 'arc';
import { filter, includes, isEmpty, map } from 'lodash';
import { ElasticReport, ElasticSearchTypeEnum } from '@mark43/rms-api';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Button } from '../../../core/components/Button';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import _EntitySearch from '../../../core/entity-search/EntitySearch';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { reportsResultsViewModelsSelector } from '../../core/state/ui';

const strings = componentStrings.cases.caseSummary.CaseReports;

const TriggerButton = styled(Button)`
    padding: 15px 10px;
`;

const PopoverContent = styled(_PopoverContent)`
    border: none;
    margin-right: 150px;
`;

const EntitySearch = styled(_EntitySearch)`
    width: 352px;
`;

const EntitySearchHeaderWrapper = styled.div`
    background-color: ${cssVar('arc.colors.surface.background')};
    padding: 5px 10px;
    font-size: 12px;
    color: ${cssVar('arc.colors.text.tertiary')};
    font-weight: bold;
    border-bottom: 1px solid ${cssVar('arc.colors.border.default')};
`;

const EntitySearchResultFooterWrapper = styled.div`
    background-color: ${cssVar('arc.colors.surface.background')};
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    padding: 10px 5px;
    border-top: 1px solid ${cssVar('arc.colors.border.default')};
`;

interface EntitySearchResultFooterProps {
    allSearchItems: ElasticReport[];
    selectedEntityIds: number[];
    onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const EntitySearchResultFooter: React.FC<EntitySearchResultFooterProps> = ({
    allSearchItems,
    selectedEntityIds,
    onClose,
}) => {
    const overlayStore = useOverlayStore();

    const onAdd = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            const selectedReports = filter(allSearchItems, (report) =>
                includes(selectedEntityIds, report.id)
            );

            onClose(event);
            overlayStore.open(overlayIdEnum.REASON_FOR_RELATION_MODAL, {
                reports: selectedReports,
            });
        },
        [allSearchItems, onClose, overlayStore, selectedEntityIds]
    );

    return (
        <EntitySearchResultFooterWrapper>
            <Button disabled={isEmpty(selectedEntityIds)} variant="solid" onClick={onAdd}>
                {strings.addText}
            </Button>
        </EntitySearchResultFooterWrapper>
    );
};

type AddReportsToCasePopoverProps = {
    caseReports: ReturnType<typeof reportsResultsViewModelsSelector>;
    caseIsEditable: boolean;
};

const AddReportsToCasePopover = ({
    caseReports,
    caseIsEditable,
}: AddReportsToCasePopoverProps): JSX.Element => {
    const [value, setValue] = useState<string>('');
    const [isOpen, setIsOpen] = useState(false);
    const currentDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const caseReportIds: number[] = map(caseReports, (caseReport) => {
        return caseReport.id;
    });

    const closePopover = () => {
        setValue('');
        setIsOpen(false);
    };

    const addReportsButton = caseIsEditable ? (
        <PopoverTrigger asChild>
            <TriggerButton
                size={'sm'}
                onClick={() => {
                    setValue('');
                    setIsOpen(true);
                }}
            >
                {strings.addReports}
            </TriggerButton>
        </PopoverTrigger>
    ) : (
        <Tooltip content={strings.cannotEditCaseTooltip(caseDisplayName)} hasArrow>
            <PopoverTrigger asChild>
                <TriggerButton size={'sm'} disabled={true}>
                    {strings.addReports}
                </TriggerButton>
            </PopoverTrigger>
        </Tooltip>
    );

    return (
        <Popover hasArrow={false} isOpen={isOpen}>
            {addReportsButton}
            <PopoverContent
                hasPadding={false}
                onEscapeKeyDown={closePopover}
                onInteractOutside={closePopover}
            >
                <EntitySearch
                    value={value}
                    onChange={(value: string) => setValue(value)}
                    showCheckbox
                    hideAddIcon
                    hideCloseButton
                    clearSearchOnDefocus
                    filterResults={(result: ElasticReport) => {
                        /**
                         * Filtering for new results
                         * New results are reports that aren't on the case
                         * and are from the current dept
                         */
                        return (
                            !includes(caseReportIds, result.id) &&
                            result.departmentId === currentDepartmentId
                        );
                    }}
                    placeholder={strings.placeholder}
                    helpText={strings.helpText(caseDisplayName)}
                    helpTextContainer=".mark43-react-side-panel"
                    entityType={ElasticSearchTypeEnum.REPORT.name}
                    renderHeader={(result: ElasticReport[]) => {
                        if (isEmpty(result)) {
                            return null;
                        }
                        return (
                            <EntitySearchHeaderWrapper>
                                {strings.entitySearchResultHeader(result.length)}
                            </EntitySearchHeaderWrapper>
                        );
                    }}
                    renderFooter={(
                        allSearchItems: ElasticReport[],
                        selectedEntityIds: number[],
                        onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
                    ) => (
                        <EntitySearchResultFooter
                            allSearchItems={allSearchItems}
                            selectedEntityIds={selectedEntityIds}
                            onClose={(e) => {
                                onClose(e);
                                closePopover();
                            }}
                        />
                    )}
                />
            </PopoverContent>
        </Popover>
    );
};

export default AddReportsToCasePopover;

import React, { Component, useCallback } from 'react';
import { flow, get, map } from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose } from 'redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayStateTypeEnum from '~/client-common/core/enums/client/overlayStateTypeEnum';
import withFields from '~/client-common/core/fields/components/withFields';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';

import { BodyMediumText } from '../../components/typography/Typography';
import testIds from '../../../../core/testIds';
import { OverlayBaseHelper } from '../../components/OverlayBaseHelper';
import BackBanner from '../../components/SidePanel/BackBanner';
import { SidePanelSearchResults } from '../../components/SidePanel/SidePanelSearchResults';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';

import { saveOrganizationProfileForm } from '../state/data';
import { ORGANIZATION_SIDE_PANEL_SCREENS as SCREENS } from '../state/ui';
import {
    organizationSearchToAddSearch,
    unregisterForm as unregisterOrganizationSearchToAddForm,
} from '../../../search/search-to-add/state/ui';
import { requestRecentPersonsOrOrganizations } from '../../../../legacy-redux/actions/recentEntitiesActions';
import { ORGANIZATION_SEARCH_TO_ADD_FORM } from '../../../search/search-to-add/state/forms/organizationSearchToAddForm';
import { NAME_SIDE_PANEL_SCROLL_OFFSET } from '../../names/config';
import _OrganizationPill from './OrganizationPill';
import { OrganizationProfileForm } from './OrganizationProfileForm';
import OrganizationSearchToAdd from './OrganizationSearchToAdd';

const strings = componentStrings.core.OrganizationSidePanel;

const ScreenContainer = styled.div`
    padding: 20px;
`;
const SearchResultCount = styled.div`
    white-space: nowrap;
    line-height: 1;
`;

const OrganizationPill = styled(_OrganizationPill)`
    margin-bottom: 10px;
`;

const OrganizationSidePanelWithoutOverlayBase = compose(
    connect(null, {
        saveOrganizationProfileForm,
        organizationSearchToAddSearch,
        requestRecentPersonsOrOrganizations,
        unregisterOrganizationSearchToAddForm,
    }),
    withFields([DISPLAY_ONLY_ORGANIZATION_LABEL])
)(
    class OrganizationSidePanelWithoutOverlayBaseUnconnected extends Component {
        isEditingExistingOrganization = () => !!this.props.entityId;

        handleClickSearchResult = (selectedId) =>
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_EDIT, { selectedId });

        handleClickAddNew = () =>
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_ADD_NEW);

        executeOrganizationSearchToAddSearch = ({ isViewMore } = {}) => {
            this.props.overlayBase.setLoading(true);
            this.props.organizationSearchToAddSearch({
                from: isViewMore
                    ? this.props.screenManagerApi.getCurrentScreen().screenState.searchResultIds
                          .length
                    : undefined,
                onError: (err) => {
                    this.props.overlayBase.setLoading(false);
                    this.props.screenManagerApi.setCurrentScreenState({
                        errorMessages: [err.message],
                    });
                },
                onSuccess: (results) => {
                    this.props.overlayBase.setLoading(false);
                    this.props.screenManagerApi.setCurrentScreenState((state) => ({
                        errorMessages: [],
                        ...(isViewMore
                            ? {
                                  searchResultIds: [
                                      ...state.searchResultIds,
                                      ...map(get(results, 'items'), 'id'),
                                  ],
                              }
                            : {}),
                    }));
                    if (!isViewMore) {
                        this.props.screenManagerApi.goToNextScreen(SCREENS.SEARCH_RESULTS, {
                            searchResultIds: map(get(results, 'items'), 'id'),
                            totalSearchResultCount: get(results, 'totalCount'),
                        });
                    }
                },
            });
        };

        handleClickSearch = () => this.executeOrganizationSearchToAddSearch();
        handleClickViewMore = () => this.executeOrganizationSearchToAddSearch({ isViewMore: true });

        handleSaveOrganizationProfile = () => {
            const {
                contextId,
                contextType,
                entityId,
                linkType,
                onAddSuccess,
                ownerId,
                ownerType,
                savePanel,
            } = this.props;
            this.props.saveOrganizationProfileForm({
                appendPanelErrorMessageIfNotExists: this.props.screenManagerApi
                    .appendPanelErrorMessageToCurrentScreenIfNotExists,
                resetPanelErrorMessages: this.props.screenManagerApi
                    .resetPanelErrorMessagesForCurrentScreen,
                contextId,
                contextType,
                entityId,
                linkType,
                onAddSuccess: onAddSuccess
                    ? flow([onAddSuccess, this.requestRecentOrganizations])
                    : this.requestRecentOrganizations,
                ownerId,
                ownerType,
                overlayId: this.props.overlayId,
                isEditingMasterProfile: this.props.isEditingMasterProfile,
                isEditingExistingOrganization: this.isEditingExistingOrganization(),
                savePanel,
            });
        };

        // If we have a value in state, use it
        // Otherwise, use the props that were passed in
        getSelectedId = () =>
            this.props.screenManagerApi.getCurrentScreen().screenState.selectedId ||
            this.props.entityId;

        getCurrentScreenConfiguration = () => {
            const {
                screen,
                screenState: {
                    errorMessages,
                    saveDisabled,
                    searchResultIds,
                    totalSearchResultCount,
                },
            } = this.props.screenManagerApi.getCurrentScreen();
            const { isAtBottomOfStack, overlayState } = this.props.overlayBase;
            const { isLoading } = overlayState;

            const {
                ownerType,
                ownerId,
                renForRecents,
                linkType,
                contextId,
                hideRecentEntities,
                closePanel,
            } = this.props;

            const baseBannerProps = {
                onClickBack: this.props.screenManagerApi.goToPreviousScreen,
            };

            const organizationLabel = this.props.fieldDisplayNames[DISPLAY_ONLY_ORGANIZATION_LABEL];

            const basePortalProps = {
                isAtBottomOfStack,
                noPadding: true,
                closePanel,
                errorMessages,
                userHasAttemptedSave: !!errorMessages.length,
                title: strings.title(organizationLabel),
                saveDisabled,
                saving: overlayState.customProperties.isSaving,
            };

            switch (screen) {
                case SCREENS.SEARCH_FORM:
                    return {
                        portalProps: {
                            ...basePortalProps,
                            onRest: hideRecentEntities ? null : this.requestRecentOrganizations,
                            saveText: null,
                        },
                        bannerProps: baseBannerProps,
                        component: (
                            <OrganizationSearchToAdd
                                onClickSearch={this.handleClickSearch}
                                loading={isLoading}
                                onClickRecentEntity={this.handleClickSearchResult}
                                ownerType={ownerType}
                                ownerId={ownerId}
                                renForRecents={renForRecents}
                                linkType={linkType}
                                contextId={contextId}
                                hideRecentEntities={hideRecentEntities}
                            />
                        ),
                    };
                case SCREENS.PROFILE_EDIT:
                case SCREENS.PROFILE_ADD_NEW:
                    return {
                        portalProps: {
                            ...basePortalProps,
                            savePanel: this.handleSaveOrganizationProfile,
                            scrollOffset: NAME_SIDE_PANEL_SCROLL_OFFSET,
                        },
                        bannerProps: baseBannerProps,
                        component: (
                            <OrganizationProfileForm
                                ownerType={ownerType}
                                ownerId={ownerId}
                                isEditingExistingOrganization={this.isEditingExistingOrganization()}
                                id={
                                    screen === SCREENS.PROFILE_EDIT
                                        ? this.getSelectedId()
                                        : undefined
                                }
                                setSaveButtonDisabled={
                                    this.props.screenManagerApi
                                        .setSaveButtonDisabledForCurrentScreen
                                }
                                removePanelErrorMessage={
                                    this.props.screenManagerApi
                                        .removePanelErrorMessageForCurrentScreen
                                }
                                appendPanelErrorMessageIfNotExists={
                                    this.props.screenManagerApi
                                        .appendPanelErrorMessageToCurrentScreenIfNotExists
                                }
                                linkType={linkType}
                            />
                        ),
                    };
                case SCREENS.SEARCH_RESULTS:
                    return {
                        bannerProps: {
                            ...baseBannerProps,
                            rightContent: searchResultIds.length ? (
                                <SearchResultCount>
                                    <BodyMediumText fontWeight="semibold">
                                        1-{searchResultIds.length}
                                        <BodyMediumText as="span"> {strings.of} </BodyMediumText>
                                        {totalSearchResultCount}
                                    </BodyMediumText>
                                </SearchResultCount>
                            ) : undefined,
                        },
                        portalProps: {
                            ...basePortalProps,
                            saveText: null,
                        },
                        component: (
                            <SidePanelSearchResults
                                onClickAddNew={this.handleClickAddNew}
                                onClickViewMore={this.handleClickViewMore}
                                loading={isLoading}
                                searchResultIds={searchResultIds}
                                renderSearchResult={(organizationId) => (
                                    <OrganizationPill
                                        key={organizationId}
                                        id={organizationId}
                                        onClick={() => this.handleClickSearchResult(organizationId)}
                                    >
                                        {organizationId}
                                    </OrganizationPill>
                                )}
                                showViewMore={
                                    !!searchResultIds.length &&
                                    searchResultIds.length < totalSearchResultCount
                                }
                                noResultsString={strings.SidePanelSearchResults.noResults(
                                    organizationLabel
                                )}
                                addNewString={strings.SidePanelSearchResults.addNew(
                                    organizationLabel
                                )}
                                addNewButtonTestId={testIds.NAME_SIDE_PANEL_ADD_NEW_NAME}
                                viewMoreString={strings.SidePanelSearchResults.viewMore}
                            />
                        ),
                    };
                default:
                    return {
                        bannerProps: baseBannerProps,
                        portalProps: {
                            ...basePortalProps,
                            savePanel: this.handleSaveOrganizationProfile,
                        },
                        component: null,
                    };
            }
        };

        requestRecentOrganizations = () => {
            const {
                requestRecentPersonsOrOrganizations,
                renForRecents,
                ownerType,
                ownerId,
            } = this.props;
            return requestRecentPersonsOrOrganizations({
                renForRecents,
                ownerType,
                ownerId,
                isOrg: true,
            });
        };

        renderBackBanner(bannerProps) {
            return this.props.screenManagerApi.getScreenStackSize() > 1 ? (
                <BackBanner {...bannerProps} />
            ) : null;
        }

        componentWillUnmount() {
            this.props.unregisterOrganizationSearchToAddForm(ORGANIZATION_SEARCH_TO_ADD_FORM);
        }

        render() {
            const { component, bannerProps, portalProps } = this.getCurrentScreenConfiguration();
            return (
                <PortalSidePanel {...portalProps} testId={testIds.ORGANIZATION_SIDE_PANEL}>
                    {this.renderBackBanner(bannerProps)}
                    <ScreenContainer>{component}</ScreenContainer>
                </PortalSidePanel>
            );
        }
    }
);

const initialState = (entityId) => ({
    screenStack: [
        {
            screen: entityId ? SCREENS.PROFILE_EDIT : SCREENS.SEARCH_FORM,
        },
    ],
});

/**
 * @param {Number}          contextId           The entity we are linking this organization to
 * @param {String}          contextType         The type of entity we are linking this organization to
 * @param {Number}          [entityId]          The id of the entity to edit. If not provided, show the search to add view
 * @param {String}          linkType            The `linkTypesEnum` to link this organization with
 * @param {Function}        [onAddSuccess]      Callback to get executed after a organization is saved
 * @param {Number|String}   [renForRecents]     REN of the report we'd like to fetch involved entities for
 * @param {String}          ownerType           Type of the owning entity (either `report` or `warrant`)
 * @param {Number}          ownerId             Id of the owning entity
 * @param {Number}          overlayId
 * @param {Number}          renderButton
 */
export const OrganizationSidePanel = ({ renderButton, saveRef, ...props }) => {
    const { overlayId, entityId } = props;
    const getInitialCustomPropertyState = useCallback(() => initialState(entityId), [entityId]);
    return (
        <OverlayBaseHelper
            id={overlayId}
            overlayStateType={overlayStateTypeEnum.ORGANIZATION_OVERLAY}
            getInitialCustomPropertyState={getInitialCustomPropertyState}
            renderButton={renderButton}
            saveRef={saveRef}
        >
            {(renderProps) => (
                <OrganizationSidePanelWithoutOverlayBase {...props} {...renderProps} />
            )}
        </OverlayBaseHelper>
    );
};

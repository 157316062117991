import { BriefingSearchSqlQuery, BriefingStateEnum } from '@mark43/rms-api';
import { VisibleFilter } from 'arc';
import { mapVisibleFiltersToQuery } from '../../../core/filters/utils';
import { BriefingFilterOption } from '../../components/dashboard/BriefingsFilters';

export const BRIEFING_QUERY_MAPPER = {
    briefingStateIds: 'briefingStateIds',
    expiryDateRangeQuery: 'expiryDateRangeQuery',
    personnelUnitIds: 'personnelUnitIds',
    postedDateRangeQuery: 'postedDateRangeQuery',
    roleIds: 'roleIds',
    subdivisionIds: 'subdivisionIds',
    title: 'title',
    id: 'id',
    creatorUserIds: 'creatorUserIds',
} as const;

export const initialFilters: BriefingSearchSqlQuery = {
    id: '',
    title: '',
    briefingStateIds: [BriefingStateEnum.ACTIVE.value],
    personnelUnitIds: [],
    roleIds: [],
    subdivisionIds: [],
    creatorUserIds: [],
    expiryDateRangeQuery: {},
    postedDateRangeQuery: {},
};

export const convertFromVisibleFiltersToQuery = (
    filters: VisibleFilter<BriefingFilterOption>[]
): BriefingSearchSqlQuery => {
    const mapper = mapVisibleFiltersToQuery(filters);

    return {
        ...initialFilters,
        subdivisionIds: mapper.getMultiSelectOptions(
            BRIEFING_QUERY_MAPPER.subdivisionIds,
            initialFilters.subdivisionIds
        ),
        roleIds: mapper.getMultiSelectOptions(
            BRIEFING_QUERY_MAPPER.roleIds,
            initialFilters.roleIds
        ),
        personnelUnitIds: mapper.getMultiSelectOptions(
            BRIEFING_QUERY_MAPPER.personnelUnitIds,
            initialFilters.personnelUnitIds
        ),
        briefingStateIds: mapper.getMultiSelectOptions(
            BRIEFING_QUERY_MAPPER.briefingStateIds,
            initialFilters.briefingStateIds
        ),
        creatorUserIds: mapper.getMultiSelectOptions(
            BRIEFING_QUERY_MAPPER.creatorUserIds,
            initialFilters.creatorUserIds
        ),
        title: mapper.getTextOption(BRIEFING_QUERY_MAPPER.title, initialFilters.title),
        id: mapper.getTextOption(BRIEFING_QUERY_MAPPER.id, initialFilters.id),
        postedDateRangeQuery: mapper.getDateRangeOption(
            BRIEFING_QUERY_MAPPER.postedDateRangeQuery,
            initialFilters.postedDateRangeQuery
        ),
        expiryDateRangeQuery: mapper.getDateRangeOption(
            BRIEFING_QUERY_MAPPER.expiryDateRangeQuery,
            initialFilters.expiryDateRangeQuery
        ),
    };
};

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

export function openKeyboardShortcutsSidePanel() {
    return (dispatch, getState, { overlayStore }) => {
        const cancelFocusRef = document.activeElement;
        overlayStore.open(overlayIdEnum.KEYBOARD_SHORTCUTS_SIDE_PANEL, {
            cancelFocusRef,
        });
    };
}

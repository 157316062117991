import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import getReportResource from '../../resources/reportResource';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';
import {
    NEXUS_STATE_PROP as USE_OF_FORCE_SUBJECTS_NEXUS_STATE_PROP,
    deleteUseOfForceSubject,
} from '../../../use-of-force-subjects/state/data';
import { replaceNameAttributesForAttributeTypes } from '../../../name-attributes/state/data';
import { replaceUseOfForceSubjectDeEscalations } from '../../../use-of-force-subject-de-escalations/state/data';

/**
 * `AttributeType`s that are valid for the `NameAttribute`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
export const USE_OF_FORCE_SUBJECT_CARD_NAME_ATTRIBUTE_ATTRIBUTE_TYPES = [
    AttributeTypeEnum.USE_OF_FORCE_SUBJECT_DETAILS.name,
    AttributeTypeEnum.USE_OF_FORCE_SUBJECT_IMPAIRMENTS.name,
    AttributeTypeEnum.USE_OF_FORCE_SUBJECT_ACTIONS.name,
    AttributeTypeEnum.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT.name,
    AttributeTypeEnum.USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER.name,
    AttributeTypeEnum.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_LOCATION.name,
    AttributeTypeEnum.USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_LOCATION.name,
    AttributeTypeEnum.USE_OF_FORCE_SUBJECT_INJURY_SEVERITY.name,
    AttributeTypeEnum.USE_OF_FORCE_OFFICER_INJURY_SEVERITY.name,
    AttributeTypeEnum.USE_OF_FORCE_SUBJECT_INJURY_TYPE.name,
    AttributeTypeEnum.USE_OF_FORCE_OFFICER_INJURY_TYPE.name,
    AttributeTypeEnum.USE_OF_FORCE_MEDICAL_AID_RECEIVED.name,
];

export function saveUseOfForceSubject(
    reportId,
    useOfForceSubject,
    nameAttributes,
    subjectPersonProfileId,
    useOfForceSubjectDeEscalations
) {
    return function (dispatch, getState, dependencies) {
        const resource = getReportCardBundleResource();
        return resource
            .upsertUseOfForceSubjectCard(reportId, {
                useOfForceSubject,
                nameAttributes,
                subjectPersonProfileId,
                useOfForceSubjectDeEscalations,
            })
            .then((updatedUseOfForceBundle) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [USE_OF_FORCE_SUBJECTS_NEXUS_STATE_PROP]: [
                                updatedUseOfForceBundle.useOfForceSubject,
                            ],
                        },
                        { type: 'CREATE_NEW_USE_OF_FORCE_SUBJECT' }
                    )
                );
                if (subjectPersonProfileId) {
                    dispatch(
                        replaceNameAttributesForAttributeTypes({
                            nameId: subjectPersonProfileId,
                            attributeTypesToReplaceFor: USE_OF_FORCE_SUBJECT_CARD_NAME_ATTRIBUTE_ATTRIBUTE_TYPES,
                            nameAttributesToReplaceWith: updatedUseOfForceBundle.nameAttributes,
                        })
                    );
                }
                if (useOfForceSubject && useOfForceSubject.id) {
                    dispatch(
                        replaceUseOfForceSubjectDeEscalations({
                            subjectId: useOfForceSubject.id,
                            useOfForceSubjectDeEscalationsToReplaceWith:
                                updatedUseOfForceBundle.useOfForceSubjectDeEscalations,
                        })
                    );
                }
                return updatedUseOfForceBundle;
            });
    };
}

export function removeUseOfForceSubject({ reportId, useOfForceSubject }) {
    return function (dispatch, getState, { formsRegistry }) {
        if (!useOfForceSubject) {
            return;
        }
        const useOfForceSubjectId = useOfForceSubject.id;
        const reportResource = getReportResource();
        return reportResource.deleteUseOfForceSubject(reportId, useOfForceSubjectId).then(() => {
            const form = formsRegistry.get(
                RefContextEnum.FORM_USE_OF_FORCE_SUBJECT.name,
                useOfForceSubjectId
            );
            if (form) {
                formsRegistry.unregister(form, useOfForceSubjectId);
            }
            dispatch(deleteUseOfForceSubject(useOfForceSubjectId));
        });
    };
}

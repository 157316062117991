import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Locations Resource',
    methods: {
        // Response payload should not be used going forward, as endpoint is intended for paying ESRI only
        getLocation(id) {
            return req({
                method: 'GET',
                url: 'master/locations',
                params: {
                    source: 'MARK43',
                    id,
                },
            });
        },
        classifyLocation(location) {
            return req({
                method: 'POST',
                url: 'master/locations/classify',
                data: location,
            });
        },
        getCenterlineFile(centerlineFileId) {
            return req({
                method: 'GET',
                url: `files/${centerlineFileId}`,
            });
        },
        getCenterline() {
            return req({
                method: 'GET',
                url: `master/locations/street_centerline/mapping`,
            });
        },
    },
});

import _ from 'lodash';

import $ from 'jquery';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import * as globalActions from '../actions/globalActions';
import store from '../../core/store';
import { getWidthAndHeight } from '../../modules/core/utils/windowHelpers';

const dispatchWindowResize = _.debounce(() => {
    store.dispatch(globalActions.windowResize(getWidthAndHeight()));
}, 100);

$(window).on('resize', dispatchWindowResize);

// Prevent backspace key from causing 'back' navigate behaviour
$(() => {
    $(document)
        .unbind('keydown')
        .bind('keydown', (event) => {
            let shouldPreventDefault = false;
            // if the user hit backspace
            if (event.keyCode === keyCodeEnum.BACKSPACE) {
                const element = event.srcElement || event.target;
                if (isInputElement(element)) {
                    shouldPreventDefault = element.readOnly || element.disabled;
                } else {
                    shouldPreventDefault = true;
                }
            }

            if (shouldPreventDefault) {
                event.preventDefault();
            }
        });
});

function isInputElement(element) {
    return (
        element.tagName.toUpperCase() === 'TEXTAREA' ||
        (element.tagName.toUpperCase() === 'INPUT' &&
            (element.type.toUpperCase() === 'TEXT' ||
                element.type.toUpperCase() === 'PASSWORD' ||
                element.type.toUpperCase() === 'FILE' ||
                element.type.toUpperCase() === 'EMAIL' ||
                element.type.toUpperCase() === 'SEARCH' ||
                element.type.toUpperCase() === 'URL' ||
                element.type.toUpperCase() === 'DATE'))
    );
}

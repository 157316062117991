import { SystemRuleEnum, LinkTypesEnum } from '@mark43/rms-api';
import _ from 'lodash';
import { attributesByTypeSelector } from '~/client-common/core/domain/attributes/state/data';
import addRuleId from '../helpers/addRuleId';
import { setKoModelValidation } from '../helpers/koValidationHelper';

// require street address for ADDRESS, cross street 1 and 2 for INTERSECTION
export const locationStreetAddressOrCrossStreetRequired = addRuleId(
    SystemRuleEnum.LOCATION_STREET_ADDRESS_OR_CROSS_STREET_REQUIRED.name,
    (panel) => {
        const hasBothCrossStreets = panel.location.crossStreet1() && panel.location.crossStreet2();
        return !!(panel.location.streetAddress() || hasBothCrossStreets);
    }
);

// this is a system rule because which subdivisions have required values changes
// from department to department, and defining this rule wont work within the
// confines of our dynamic rules
export const locationLinkRequireDepartmentSubdivisions = addRuleId(
    SystemRuleEnum.LOCATION_LINK_REQUIRE_DEPARTMENT_SUBDIVISIONS.name,
    (panel, errMsg, state) => {
        const { subdivision1, subdivision2, subdivision3, subdivision4, subdivision5 } = {
            subdivision1: !!attributesByTypeSelector(state)('SUBDIVISION_DEPTH_1').length,
            subdivision2: !!attributesByTypeSelector(state)('SUBDIVISION_DEPTH_2').length,
            subdivision3: !!attributesByTypeSelector(state)('SUBDIVISION_DEPTH_3').length,
            subdivision4: !!attributesByTypeSelector(state)('SUBDIVISION_DEPTH_4').length,
            subdivision5: !!attributesByTypeSelector(state)('SUBDIVISION_DEPTH_5').length,
        };
        const {
            subdivision1AttrId,
            subdivision2AttrId,
            subdivision3AttrId,
            subdivision4AttrId,
            subdivision5AttrId,
        } = panel.locationEntityLink;

        // only validate certain link types (RMS-2116)
        const linkTypesToValidate = [
            LinkTypesEnum.LOCATION_OF_CAD_TICKET,
            LinkTypesEnum.LOCATION_OF_EVENT,
            LinkTypesEnum.LOCATION_OF_FIELD_CONTACT,
            LinkTypesEnum.OFFENSE_LOCATION,
            LinkTypesEnum.ARREST_LOCATION,
            LinkTypesEnum.PROPERTY_RECOVERED_LOCATION,
        ];

        if (!_.includes(linkTypesToValidate, panel.linkType)) {
            return true;
        }

        // this loop is needed to apply field level validation indicators
        // to each appropriate subdivision field
        _.forEach(
            [
                {
                    subdivisionType: subdivision1,
                    subdivisionField: subdivision1AttrId,
                },
                {
                    subdivisionType: subdivision2,
                    subdivisionField: subdivision2AttrId,
                },
                {
                    subdivisionType: subdivision3,
                    subdivisionField: subdivision3AttrId,
                },
                {
                    subdivisionType: subdivision4,
                    subdivisionField: subdivision4AttrId,
                },
                {
                    subdivisionType: subdivision5,
                    subdivisionField: subdivision5AttrId,
                },
            ],
            ({ subdivisionType, subdivisionField }) => {
                if (subdivisionType && !subdivisionField()) {
                    setKoModelValidation(subdivisionField, false, errMsg);
                }
            }
        );
        return !(
            (subdivision1 && !subdivision1AttrId()) ||
            (subdivision2 && !subdivision2AttrId()) ||
            (subdivision3 && !subdivision3AttrId()) ||
            (subdivision4 && !subdivision4AttrId()) ||
            (subdivision5 && !subdivision5AttrId())
        );
    }
);

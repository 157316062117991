import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { cssVar, mediaQueries } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { Briefing } from '~/client-common/core/domain/briefings/state/data';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { getAttributesByIdsSelector } from '~/client-common/core/domain/attributes/state/data';

import { RecordsSubtitleKeyValue } from '../../../records/core/components/header/RecordsHeader';
import { useDateTimeFormatter } from '../../../core/current-user/hooks/dateTimeFormats';
import testIds from '../../../../core/testIds';

import { getBriefingLocationBySubdivisions } from '../../utils';

interface BriefingSummarySubheaderProps {
    briefing: Briefing;
}

const strings = componentStrings.briefings.core;

const SummaryWrapper = styled.div`
    display: flex;
    font-size: ${cssVar('arc.fontSizes.sm')};
    color: ${(props) => props.theme.colors.darkGrey};
    gap: ${cssVar('arc.space.4')};

    @media (max-width: ${mediaQueries.md}) {
        flex-direction: column;
        gap: ${cssVar('arc.space.2')};
    }
`;

const BriefingSummarySubheader: React.FC<BriefingSummarySubheaderProps> = ({ briefing }) => {
    const dateFormatter = useDateTimeFormatter();

    const author = useSelector(formatMiniUserByIdSelector)(briefing.createdBy);
    const subdivisions = useSelector(getAttributesByIdsSelector)(
        briefing.subdivisionRoutingIds ?? []
    );

    const location = getBriefingLocationBySubdivisions(subdivisions);

    const briefingSummaries = [
        { name: strings.urn, value: briefing.id, testId: testIds.BRIEFING_SUMMARY_URN },
        {
            name: strings.posted,
            value: dateFormatter.formatDate(briefing.postedDateUtc),
            testId: testIds.BRIEFING_SUMMARY_POSTED_DATE,
        },
        { name: strings.author, value: author, testId: testIds.BRIEFING_SUMMARY_AUTHOR },
        { name: strings.location, value: location, testId: testIds.BRIEFING_SUMMARY_LOCATION },
    ];

    return (
        <SummaryWrapper>
            {briefingSummaries.map(({ name, value, testId }) => (
                <RecordsSubtitleKeyValue
                    key={name}
                    name={name}
                    value={value}
                    link={null}
                    testId={testId}
                />
            ))}
        </SummaryWrapper>
    );
};

export default BriefingSummarySubheader;

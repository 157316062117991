import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { isEmpty, pick, trim } from 'lodash';
import styled from 'styled-components';

import { caseTitleViewModelsSelector } from '~/client-common/core/domain/case-titles/state/ui';
import { reportShortTitleViewModelsSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import withFields from '~/client-common/core/fields/components/withFields';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import Row from '../../../core/components/Row';
import Subsection from '../../../core/components/Subsection';
import { RRFAsyncText } from '../../../core/forms/components/AsyncText';
import LinkedCases from '../../../records/core/components/sidebar/LinkedCases';
import LinkedReports from '../../../records/core/components/sidebar/LinkedReports';

import createCaseForm from '../state/form/createCaseForm';
import { submitCaseRenSearch, submitCreateCaseForm } from '../state/ui';
import { clearUnassignedReportsSelection } from '../../unassigned-reports/state/ui';
import { createModalSelector } from '../../../core/box/state/ui';
import CaseTypesSection from './CaseTypesSection';

const modalContext = { name: boxEnum.CREATE_CASE_MODAL };
const strings = componentStrings.cases.core.CreateCaseModal;

const CaseRenRow = styled(Row)`
    padding-bottom: 20px;
`;

function LinkedCasesSection({ renDisplayName, ren, caseTitleViewModels, caseFieldName }) {
    const sectionTitle = ren
        ? strings.renCasesSection(renDisplayName, ren, caseFieldName)
        : strings.casesSection(caseFieldName);
    return !isEmpty(caseTitleViewModels) ? (
        <Subsection title={sectionTitle}>
            <LinkedCases caseTitleViewModels={caseTitleViewModels} />
        </Subsection>
    ) : null;
}

function LinkedReportsSection({ renDisplayName, ren, reportShortTitleViewModels }) {
    const sectionTitle = ren
        ? strings.renReportsSection(renDisplayName, ren)
        : strings.reportsSection;
    return (
        <Subsection title={sectionTitle} testId={testIds.CREATE_CASE_MODAL_LINKED_REPORTS_SECTION}>
            <LinkedReports reportShortTitleViewModels={reportShortTitleViewModels} />
            {isEmpty(reportShortTitleViewModels) && ren && strings.noReportsForRen(renDisplayName)}
        </Subsection>
    );
}

function CreateCaseForm({
    existingCaseIds = [],
    existingReportIds = [],
    createCase,
    enteredRen,
    renDisabled,
    isCaseRenValid,
    submitCaseRenSearch,
    caseTitleViewModels,
    reportShortTitleViewModels,
    fieldDisplayNames,
    showRen = true,
}) {
    const existingCaseTitleViewModels = pick(caseTitleViewModels, existingCaseIds);
    const existingReportShortTitleViewModels = pick(reportShortTitleViewModels, existingReportIds);
    const renDisplayName = fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER;
    const { pluralCaseFieldName: casesFieldName } = useCaseFieldName();

    return (
        <ReactReduxForm {...createCaseForm}>
            <FeatureFlagged
                flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE"
                fallback={
                    <CaseRenRow>
                        <RRFAsyncText
                            path="caseRen"
                            width={250}
                            alterValue={(value) => trim(value)}
                            asyncAction={submitCaseRenSearch}
                            disabled={renDisabled}
                            typeaheadThrottle={600}
                        />
                    </CaseRenRow>
                }
            >
                {showRen && (
                    <CaseRenRow>
                        <RRFAsyncText
                            path="caseRen"
                            width={250}
                            alterValue={(value) => trim(value)}
                            asyncAction={submitCaseRenSearch}
                            disabled={renDisabled}
                            typeaheadThrottle={600}
                        />
                    </CaseRenRow>
                )}
            </FeatureFlagged>
            <LinkedCasesSection
                renDisplayName={renDisplayName}
                caseFieldName={casesFieldName}
                ren={enteredRen}
                caseTitleViewModels={existingCaseTitleViewModels}
            />
            <LinkedReportsSection
                renDisplayName={renDisplayName}
                ren={enteredRen}
                reportShortTitleViewModels={existingReportShortTitleViewModels}
            />
            <FeatureFlagged
                flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE"
                fallback={isCaseRenValid && <CaseTypesSection onClick={createCase} />}
            >
                <CaseTypesSection onClick={createCase} />
            </FeatureFlagged>
        </ReactReduxForm>
    );
}

const mapStateToProps = createStructuredSelector({
    caseTitleViewModels: caseTitleViewModelsSelector,
    reportShortTitleViewModels: reportShortTitleViewModelsSelector,
    existingCaseIds: createModalSelector(modalContext, 'existingCaseIds'),
    existingReportIds: createModalSelector(modalContext, 'existingReportIds'),
    showRen: createModalSelector(modalContext, 'showRen'),
    enteredRen: createModalSelector(modalContext, 'enteredRen'),
    renDisabled: createModalSelector(modalContext, 'renDisabled'),
    isCaseRenValid: createModalSelector(modalContext, 'isCaseRenValid'),
});

const mapDispatchToProps = (dispatch) => ({
    submitCaseRenSearch: () =>
        dispatch(
            submitCaseRenSearch({
                boxContext: modalContext,
                form: createCaseForm,
                fieldPath: 'caseRen',
            })
        ),
    createCase: (caseDefinitionId) =>
        dispatch(submitCreateCaseForm(caseDefinitionId, clearUnassignedReportsSelection)),
});

/**
 * Form for creating a new case.
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFields([REPORT_REPORTING_EVENT_NUMBER])
)(CreateCaseForm);

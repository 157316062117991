import { RIPAOffenseCode, RipaSubjectOffenseCode, CodeTypeCategoryEnumType } from '@mark43/rms-api';
import _ from 'lodash';

export function filterRipaOffenseCodeIds({
    ripaSubjectOffenseCodes,
    codeTypeCategory,
    code,
}: {
    ripaSubjectOffenseCodes: RipaSubjectOffenseCode[];
    codeTypeCategory: CodeTypeCategoryEnumType;
    code: string;
}) {
    return _(ripaSubjectOffenseCodes)
        .filter({ codeTypeCategory, code })
        .map('ripaOffenseCodeId')
        .value();
}

export const formatRipaOffenseCodesDisplayValue = (ripaOffenseCode: RIPAOffenseCode) => {
    const { statute, statuteLiteral, typeOfCharge } = ripaOffenseCode;
    if (typeOfCharge == null) {
        return `${statute} | ${statuteLiteral}`;
    }
    return `${statute} | ${statuteLiteral} (${typeOfCharge})`;
};

import { RefContextEnum } from '@mark43/rms-api';
import { get, noop } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Text, TextStyle } from 'arc';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import withCard from '../../utils/withCard';
import Card from '../../../../../legacy-redux/components/core/Card';
import { createEventInfoForm } from '../../state/forms/eventInfoForm';
import eventInfoCard from '../../state/ui/eventInfoCard';
import { visibleEmbeddedReportIdsSelector } from '../../state/ui/embeddedReports';
import { registerForm, resetCard } from '../../state/ui';
import { getEventInfoFormPrefill, refreshEventInfoForm } from '../../state/ui/eventInfo';
import testIds from '../../../../../core/testIds';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    currentReportCardUITitleByTypeSelector,
    currentReportSelector,
    isCurrentReportNewSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { registerCard } from '../../utils/cardsRegistry';
import EventInfoCardForm from './EventInfoCardForm';
import EventInfoCardSummary from './EventInfoCardSummary';

const strings = componentStrings.reports.core.EventInfoCard;

const AddtionalFooterContentWrapper = styled.div`
    display: flex;
    flex: 1;
    height: 54px;
    min-width: 8rem;
    align-items: center;
    margin-right: 10px;
`;

class EventInfoCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.onEdit = this.onEdit.bind(this);
        this.onSaveProgress = this.onSaveProgress.bind(this);
        this.onSave = this.onSave.bind(this);

        const {
            formatFieldByName,
            arbiter,
            getEventInfoFormPrefill,
            refreshEventInfoForm,
            resetCard,
            isNewReport,
        } = this.props;

        const eventInfoFormPrefill = getEventInfoFormPrefill();
        const isFormPrefilled = !isUndefinedOrNull(eventInfoFormPrefill);
        const initialState = isFormPrefilled ? eventInfoFormPrefill : refreshEventInfoForm();

        const form = createEventInfoForm({
            formatFieldByName,
            arbiter,
            initialState,
        });

        registerForm({ form });
        // always transition the card to edit mode if we have prefilled anything
        resetCard({
            editMode: isNewReport || isFormPrefilled,
            module: eventInfoCard,
        });

        registerCard({
            cardModule: eventInfoCard,
            onSave: this.onSave,
        });
    }

    getForm() {
        return formsRegistry.get(RefContextEnum.FORM_EVENT_INFO.name);
    }

    componentWillUnmount() {
        formsRegistry.unregister(RefContextEnum.FORM_EVENT_INFO.name);
    }

    onEdit() {
        this.props.editCallback(() => this.props.onEdit());
    }

    onSaveProgress() {
        const form = this.getForm();
        return this.props.onSaveProgress(form);
    }

    onSave() {
        const form = this.getForm();
        return this.props.onSave(form);
    }

    render() {
        const { card = {}, currentReport, currentReportCardUITitleByType } = this.props;

        const cardTitle = currentReportCardUITitleByType(reportCardEnum.EVENT_INFO.id);
        const {
            id: currentReportId,
            reportingEventNumber: currentReportREN,
            isLegacyReport,
            eventId: reportingEventId,
        } = currentReport;

        return (
            <Card
                testId={testIds.EVENT_CARD}
                className={card.anchor}
                anchor={card.anchor}
                title={cardTitle}
                renderContent={(summaryMode) => {
                    if (summaryMode) {
                        return (
                            <EventInfoCardSummary
                                currentReportREN={currentReportREN}
                                reportId={currentReportId}
                                reportingEventId={reportingEventId}
                            />
                        );
                    } else {
                        return (
                            <EventInfoCardForm
                                reportingEventId={reportingEventId}
                                currentReportREN={currentReportREN}
                                reportId={currentReportId}
                                isLegacyReport={isLegacyReport}
                            />
                        );
                    }
                }}
                onEdit={this.onEdit}
                errors={card.errorMessages}
                summaryMode={card.summaryMode}
                canEdit={get(card.canEditEventInfoReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(card.canEditEventInfoReportCardStatus, 'errorMessage')}
                saving={card.saving}
                onSave={this.onSaveProgress}
                additionalFooterContent={
                    this.props.visibleEmbeddedReportIds.length > 0
                        ? () => (
                              <AddtionalFooterContentWrapper>
                                  <Text variant="bodyMd">
                                      <TextStyle fontStyle="italic">
                                          {strings.multipleReportsAdditionalFooterContent}
                                      </TextStyle>
                                  </Text>
                              </AddtionalFooterContentWrapper>
                          )
                        : noop
                }
            />
        );
    }
}

class EventInfoCardWrapper extends React.Component {
    constructor() {
        super(...arguments);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={RefContextEnum.FORM_EVENT_INFO.name}>
                {(arbiter) => <EventInfoCard ref={this.setRef} {...this.props} arbiter={arbiter} />}
            </RMSArbiterProvider>
        );
    }
}

export default compose(
    withCard(eventInfoCard),
    connect(
        createStructuredSelector({
            formatFieldByName: formatFieldByNameSelector,
            currentReport: currentReportSelector,
            isNewReport: isCurrentReportNewSelector,
            visibleEmbeddedReportIds: visibleEmbeddedReportIdsSelector,
            currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
        }),
        { getEventInfoFormPrefill, refreshEventInfoForm, resetCard },
        null,
        { forwardRef: true }
    )
)(EventInfoCardWrapper);

import _ from 'lodash';
import { createSelector } from 'reselect';
import { prettify } from '~/client-common/helpers/stringHelpers';
import {
    shortcutKeysSelector,
    shortcutKeysByIdSelector,
} from '~/client-common/core/domain/shortcut-keys/state/data';
import { prettifyTitle } from '~/client-common/core/shortcutCommands';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';

import {
    LOAD_SHORTCUT_KEYS_START,
    LOAD_SHORTCUT_KEYS_SUCCESS,
    LOAD_SHORTCUT_KEYS_FAILURE,
    SAVE_SHORTCUT_KEY_FORM_START,
    SAVE_SHORTCUT_KEY_FORM_SUCCESS,
    SAVE_SHORTCUT_KEY_FORM_FAILURE,
    REMOVE_SHORTCUT_KEY_FAILURE,
} from '../data';
import shortcutKeyForm from '../forms/shortcutKeyForm';

const SELECT_SHORTCUT_KEY_START = 'shortcutKeys/SELECT_SHORTCUT_KEY_START';
const SELECT_SHORTCUT_KEY_SUCCESS = 'shortcutKeys/SELECT_SHORTCUT_KEY_SUCCESS';
const SELECT_SHORTCUT_KEY_FAILURE = 'shortcutKeys/SELECT_SHORTCUT_KEY_FAILURE';
const RESET_SHORTCUT_KEYS_ADMIN_PAGE = 'shortcutKeys/RESET_SHORTCUT_KEYS_ADMIN_PAGE';

const initialUiState = {
    error: null,
    submittingForm: false,
    selectedShortcutKeyId: null,
};

export const uiSelector = (state) => state.ui.shortcutKeysAdmin;

export const selectedShortcutKeySelector = createSelector(
    uiSelector,
    shortcutKeysByIdSelector,
    (ui, selectedShortcutKeyById) => selectedShortcutKeyById(ui.selectedShortcutKeyId)
);

export const shortcutKeysAdminLoadingSelector = createSelector(
    uiSelector,
    (shortcutKeysAdmin) => shortcutKeysAdmin.loading
);

function selectShortcutKeyStart() {
    return { type: SELECT_SHORTCUT_KEY_START };
}

function selectShortcutKeySuccess(shortcutKeyId) {
    return {
        type: SELECT_SHORTCUT_KEY_SUCCESS,
        payload: shortcutKeyId,
    };
}

function selectShortcutKeyFailure(errorMessage) {
    return {
        type: SELECT_SHORTCUT_KEY_FAILURE,
        payload: errorMessage,
    };
}

export function resetShortcutKeysAdminPage() {
    return { type: RESET_SHORTCUT_KEYS_ADMIN_PAGE };
}

function shortcutKeysAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SELECT_SHORTCUT_KEY_START:
            return {
                ...state,
                submittingForm: false,
                selectedShortcutKeyId: null,
                error: null,
            };

        case SELECT_SHORTCUT_KEY_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                selectedShortcutKeyId: action.payload,
                errors: null,
            };

        case SELECT_SHORTCUT_KEY_FAILURE:
        case LOAD_SHORTCUT_KEYS_START:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case LOAD_SHORTCUT_KEYS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case LOAD_SHORTCUT_KEYS_FAILURE:
            return {
                ...state,
                error: action.payload.message,
                loading: false,
            };
        case SAVE_SHORTCUT_KEY_FORM_START:
            return {
                ...state,
                submittingForm: true,
                error: null,
            };
        case SAVE_SHORTCUT_KEY_FORM_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                error: null,
            };
        case REMOVE_SHORTCUT_KEY_FAILURE:
        case SAVE_SHORTCUT_KEY_FORM_FAILURE:
            return {
                ...state,
                submittingForm: false,
                error: action.payload.message,
            };
        default:
            return state;
    }
}

export function selectShortcutKey(shortcutKeyId) {
    return (dispatch, getState) => {
        dispatch(selectShortcutKeyStart());
        const state = getState();
        const id = parseInt(shortcutKeyId);
        const shortcutKey = shortcutKeysByIdSelector(state)(id);

        if (shortcutKey) {
            dispatch(shortcutKeyForm.actionCreators.change({ shortcutKey }));

            dispatch(selectShortcutKeySuccess(shortcutKey.id));
        } else {
            dispatch(selectShortcutKeyFailure());
        }
    };
}

export const shortcutKeysAdminUiSelector = createSelector(shortcutKeysSelector, (shortcutKeys) => {
    return { shortcutKeys };
});

export const shortcutKeysAdminUiListItemsSelector = createSelector(
    uiSelector,
    shortcutKeysSelector,
    (shortcutKeysAdmin, shortcutKeys) => {
        return _(shortcutKeys)
            .filter((shortcutKey) => shortcutKey.id > -1)
            .map((shortcutKey) => {
                return {
                    key: shortcutKey.id,
                    title: prettifyTitle(shortcutKey.shortcutCommand),
                    subtitle: prettify(shortcutKey.shortcutContext),
                    path: `/admin/cad/shortcut-keys/${shortcutKey.id}`,
                    selected: shortcutKeysAdmin.selectedShortcutKeyId === shortcutKey.id,
                };
            })
            .sortBy((x) => [x.subtitle, x.title])
            .value();
    }
);

export default makeResettable(RESET_SHORTCUT_KEYS_ADMIN_PAGE, shortcutKeysAdminUiReducer);

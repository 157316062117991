import { EntityTypeEnum } from '@mark43/rms-api';
import { entityTypeParamToEnum } from "~/client-common/helpers/entityProfileHelpers";
import { ValueOf } from '~/client-common/types';

export const getCaseLinksFromEntity = ({
    entityType,
    entityId,
}: {
    entityType: ValueOf<typeof entityTypeParamToEnum>;
    entityId: number;
}) => {
    if (entityType === EntityTypeEnum.ITEM_PROFILE.name) {
        return {
            nameCaseLinks: undefined,
            itemCaseLinks: [{
                itemId: entityId,
                entityType,
            }],
        }
    }

    return {
        nameCaseLinks: [{
            nameId: entityId,
            entityType,
        }],
        itemCaseLinks: undefined,
    };
};

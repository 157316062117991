import createLogRocket from 'logrocket/setup';
import logrocket from 'logrocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

// All other params (LOG_ROCKET_SDK_SERVER|LOG_ROCKET_INGEST_SERVER)
// will always be defined properly.  The `LOG_ROCKET_PROJECT_ID`
// is the only thing that possibly won't be set
export const hasLogRocketConfiguration = LOG_ROCKET_PROJECT_ID !== 'NO_LOG_ROCKET_PROJECT_ID';

// NOTE: We should always import this instance of `LogRocket`,
// not the one from the `logrocket` npm module
export const LogRocket: typeof logrocket = createLogRocket({
    sdkServer: LOG_ROCKET_SDK_SERVER,
    ingestServer: LOG_ROCKET_INGEST_SERVER,
});

// The names of fields that occur within the request body
// that we'd like to omit / scrub from logrocket
const privateFieldNames = ['password'];
const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(privateFieldNames);
export const initLogRocket = () => {
    // We do this because we still want to create a valid `LogRocket` instance everytime,
    // but we don't neccessarily want to `init` it if there is no associated project
    // for the environment (like local dev)
    if (!hasLogRocketConfiguration) {
        return;
    }

    LogRocket.init(LOG_ROCKET_PROJECT_ID, {
        release: COMMIT_HASH,
        network: {
            // @ts-expect-error The types are wrong from the plugin we are using
            requestSanitizer,
            // @ts-expect-error The types are wrong from the plugin we are using
            responseSanitizer,
        },
        mergeIframes: true,
    });
};

import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import _LinkedReportLink from '../../../../records/core/components/sidebar/LinkedReportLink';

const strings =
    componentStrings.reports.custodialPropertySummary.custodialPropertyCardItem
        .CustodialPropertyCardItemLinkedReports;

const LinkedReports = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const LinkedReportsLabel = styled.div`
    vertical-align: top;
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;
const LinkedReportLink = styled(_LinkedReportLink)`
    display: flex;
    margin-bottom: 3px;
    color: ${(props) => props.theme.colors.cobaltBlue};
`;
const ReportSummary = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

function CustodialPropertyCardItemLinkedReports({ className, reportShortTitleViewModels }) {
    return (
        <LinkedReports className={className}>
            <LinkedReportsLabel>{strings.linkedReports}</LinkedReportsLabel>
            <div>
                {map(reportShortTitleViewModels, (reportShortTitleViewModel) => {
                    const {
                        reportId,
                        approvalStatus,
                        secondaryApprovalStatus,
                        clientApprovalStatus,
                        isSealed,
                        isPartiallySealed,
                        isVacated,
                    } = reportShortTitleViewModel;
                    const { shortTitleWithRen } = getViewModelProperties(reportShortTitleViewModel);

                    return (
                        <LinkedReportLink
                            key={reportId}
                            approvalStatus={approvalStatus}
                            secondaryApprovalStatus={secondaryApprovalStatus}
                            clientApprovalStatus={clientApprovalStatus}
                            reportId={reportId}
                            reportSealingInfo={{ isSealed, isPartiallySealed, isVacated }}
                            reportSummary={<ReportSummary>{shortTitleWithRen}</ReportSummary>}
                        />
                    );
                })}
            </div>
        </LinkedReports>
    );
}

/**
 * Linked reports section for an item in the custodial property summary report.
 *   At least 1 view model must be passed in, otherwise don't render this.
 * @param {Object[]} reportShortTitleViewModels
 */
export default CustodialPropertyCardItemLinkedReports;

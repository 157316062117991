import { identity } from 'lodash';
import { ElasticCourtOrderQuery, ElasticSearchTypeEnum } from '@mark43/rms-api';
import { createStructuredSelector } from 'reselect';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import createSearchModule from '../../../core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import advancedSearchCourtOrdersForm, {
    convertAdvancedSearchCourtOrdersFormModelToFilterGroups,
    convertAdvancedSearchCourtOrdersElasticQueryToFormModel,
} from '../forms/advancedSearchCourtOrdersForm';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../core/state/ui';
import searchResource from '../../../../../legacy-redux/resources/searchResource';

function convertAdvancedSearchCourtOrdersElasticQueryToFilterGroups(
    elasticQuery: ElasticCourtOrderQuery,
    formatFieldValue: string,
    boundSelectors: {
        formatFieldByName: (fieldName: string) => string;
    }
) {
    return convertAdvancedSearchCourtOrdersFormModelToFilterGroups(
        convertAdvancedSearchCourtOrdersElasticQueryToFormModel(elasticQuery),
        formatFieldValue,
        boundSelectors
    );
}

const advancedSearchCourtOrders = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.COURT_ORDERS.name,
    baseUiSelector: (state: RootState) => state.ui.advancedSearch.courtOrders,
    form: advancedSearchCourtOrdersForm,
    elasticQueryToFilterGroups: convertAdvancedSearchCourtOrdersElasticQueryToFilterGroups,
    resourceMethod: elasticSearchResource.searchCourtOrders,
    resultsContainerClassName: 'court-orders-results-container',
    createResultsViewModelsSelector: identity,
    searchResultToRoutePath: ({ id }: { id: number }) => `/profiles/court-orders/${id}`,
    defaultTableState: {
        activeColumnKeys: {
            date: 'courtOrderDate',
            number: 'courtCaseNumber',
        },
    },
    transformElasticQueryBeforeSearchSelector: (state: RootState) => (
        elasticQuery: ElasticCourtOrderQuery
    ) => {
        const transformIds = transformElasticQueryIdsToDisplayValuesSelector(state);
        const queryWithTransformedIds = transformIds(elasticQuery);
        return queryWithTransformedIds;
    },
    exportResourceMethod: searchResource.exportCourtOrders,
    loadSearchExportPrintablesResourceMethod: searchResource.getCourtOrdersSearchExportPrintables,
    selectorToBind: createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
    }),
});

export default advancedSearchCourtOrders;

import React, { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../../core/components/Icon';
import { SubsectionTitleText as _SubsectionTitleText } from '../../../../core/components/typography';
import ExpandCollapseButton from '../../../../../legacy-redux/components/core/ExpandCollapseButton';

const strings = componentStrings.reports.ReportSidebar;

const SubsectionTitleText = styled(_SubsectionTitleText)`
    padding-bottom: 6px;
`;

const EditButton = styled(Button)`
    margin-top: -4px;
    font-size: var(--arc-fontSizes-md);
    float: right;
`;

const StyledRecordSidebarSectionTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

function RecordSidebarSection(props) {
    const {
        title,
        onEdit = null,
        onEditIconType = iconTypes.EDIT,
        onEditLabel = strings.edit,
        children,
        editButtonTestId,
        sectionTestId,
        sortPopoutMenu,
        className,
        collapsible,
    } = props;

    const [isCollapsed, setIsCollapsed] = useState(false);

    const content = <div className="report-sidebar-section-members">{children}</div>;
    return (
        <div
            className={classNames('report-sidebar-section', className)}
            data-test-id={sectionTestId}
        >
            {onEdit && (
                <EditButton
                    className={buttonTypes.ICON_LINK}
                    iconLeft={onEditIconType}
                    onClick={onEdit}
                    testId={editButtonTestId}
                >
                    {onEditLabel}
                </EditButton>
            )}
            <StyledRecordSidebarSectionTitle>
                <SubsectionTitleText color="tertiary">{title}</SubsectionTitleText>
                {!!sortPopoutMenu && sortPopoutMenu}
                {!!collapsible && (
                    <ExpandCollapseButton collapsed={isCollapsed} setCollapsed={setIsCollapsed} />
                )}
            </StyledRecordSidebarSectionTitle>
            {collapsible ? (isCollapsed ? null : content) : content}
        </div>
    );
}

export default RecordSidebarSection;

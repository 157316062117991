import {
    EntityTypeEnum,
    ApprovalStatusEnum,
    OfficerInvolvementEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';
import { createSelector } from 'reselect';
import _, { chain, find, first, get, mapValues, pick } from 'lodash';

import approvalStatusClientEnum from '../../../../enums/client/approvalStatusClientEnum';
import { elasticReportsSelector } from '../data';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '../../../attributes/state/data';
import {
    formatMiniUserShortHandByIdSelector,
    allMiniUserFormatsByIdSelector,
} from '../../../mini-users/state/data';
import { formatReportDefinitionByIdSelector } from '../../../report-definitions/state/data';
import { formatAgencyProfileByIdSelector } from '../../../agency-profiles/state/ui';

// view model helpers
import {
    buildViewModel,
    allAttributeIdListsMapper,
    allSingleAttributeValuesMapper,
    subdivisionAttrIdsMapper,
    allOfficerIdListsMapper,
    buildAllMiniUserFormatsMapper,
} from '../../../../../helpers/viewModelHelpers';

// HELPERS
function formatRenWithShortTitle({ reportingEventNumber, shortTitle } = {}) {
    return joinTruthyValues([reportingEventNumber, shortTitle], ' - ');
}

function sortLocations(elasticLocations) {
    return chain(elasticLocations).values().flatten().sortBy('id').value();
}

/**
 * Returns a single, primary elastic location or undefined if no elastic locations exist.
 * Logic for primary location is to grab the first `LOCATION_OF_EVENT` links, sorted by id.  If
 * none exist, then we take the first of all links sorted by id, regardless of the link type.
 */
export function getPrimaryLocation(elasticLocations) {
    const sortedLocations = sortLocations(elasticLocations);
    const primaryLocation = getReportTakenLocation(sortedLocations);

    if (primaryLocation) {
        return primaryLocation;
    }
    return first(sortedLocations);
}

function getReportTakenLocation(elasticLocations) {
    return find(
        sortLocations(elasticLocations),
        (location) => location.type === LinkTypesEnum.LOCATION_OF_EVENT
    );
}

export function getOffenseLocation(elasticLocations) {
    const allLocations = chain(elasticLocations).values().flatten().value();
    const offenseLocation = find(
        allLocations,
        (location) => location.ownerType === EntityTypeEnum.OFFENSE.name
    );
    return offenseLocation;
}

export function getArrestLocation(elasticLocations) {
    const allLocations = chain(elasticLocations).values().flatten().value();
    const arrestLocation = find(
        allLocations,
        (location) => location.ownerType === EntityTypeEnum.ARREST.name
    );
    return arrestLocation;
}

export function getEventLocation(elasticLocations) {
    const allLocations = chain(elasticLocations).values().flatten().value();
    const eventLocation = find(
        allLocations,
        (location) => location.ownerType === EntityTypeEnum.CAD_TICKET.name
    );
    return eventLocation;
}

export const elasticReportResultViewModelsSelector = (baseSelector) =>
    createSelector(
        baseSelector,
        formatMiniUserShortHandByIdSelector,
        formatAttributeByIdSelector,
        formatSubdivisionAttrIdsSelector,
        formatReportDefinitionByIdSelector,
        formatAgencyProfileByIdSelector,
        allMiniUserFormatsByIdSelector,
        (
            elasticReports,
            formatMiniUserShortHandById,
            formatAttributeById,
            formatSubdivisionAttrIds,
            formatReportDefinitionById,
            formatAgencyProfileById,
            allMiniUserFormatsById
        ) =>
            mapValues(
                elasticReports,
                buildViewModel({
                    recursive: true,
                    mappers: [
                        allAttributeIdListsMapper,
                        allSingleAttributeValuesMapper,
                        subdivisionAttrIdsMapper,
                        allOfficerIdListsMapper,
                        buildAllMiniUserFormatsMapper(),
                        ({ involvedOfficers }, helpers) => {
                            const respondingInvolvedOfficer = find(involvedOfficers, {
                                officerInvolvement: OfficerInvolvementEnum.RESPONDING.name,
                            });
                            const arrestingOfficer = find(involvedOfficers, {
                                officerInvolvement: OfficerInvolvementEnum.ARRESTING.name,
                            });
                            return {
                                // this is the same as "primary reporter"
                                respondingOfficerFormats: helpers.allMiniUserFormatsById(
                                    get(respondingInvolvedOfficer, 'officerId')
                                ),
                                arrestingOfficerFormats: helpers.formatMiniUserShortHandById(
                                    get(arrestingOfficer, 'officerId')
                                ),
                            };
                        },
                        (obj, helpers) =>
                            _(obj)
                                .pick('currentSubmissionOwnerId')
                                .mapValues((id) => helpers.formatMiniUserShortHandById(id))
                                .value(),
                        (obj, helpers) =>
                            _(obj)
                                .pick('reviewerIds')
                                .mapValues((reviewers) =>
                                    reviewers.map((id) => ({
                                        id,
                                        display: helpers.formatMiniUserShortHandById(id),
                                    }))
                                )
                                .value(),
                        (obj, helpers) =>
                            _(obj)
                                .pick('authorIds')
                                .mapValues((reviewers) =>
                                    reviewers.map((id) => ({
                                        id,
                                        display: helpers.formatMiniUserShortHandById(id),
                                    }))
                                )
                                .value(),
                        (obj, helpers) =>
                            _(obj)
                                .pick('agencyId')
                                .mapValues((id) => helpers.formatAgencyProfileById(id))
                                .value(),
                        (obj, helpers) =>
                            _(obj)
                                .pick('reportDefinition')
                                .mapValues(({ reportDefinitionId }) =>
                                    helpers.formatReportDefinitionById(reportDefinitionId)
                                )
                                .value(),
                        (elasticReport) => {
                            const elasticLocations = pick(
                                elasticReport,
                                'involvedElasticLocations'
                            );
                            return {
                                primaryLocation: getPrimaryLocation(elasticLocations),
                                reportTakenLocation: getReportTakenLocation(elasticLocations),
                                offenseLocation: getOffenseLocation(elasticLocations),
                                arrestLocation: getArrestLocation(elasticLocations),
                                eventLocation: getEventLocation(elasticLocations),
                            };
                        },
                        (elasticReport) => ({
                            renWithShortTitle: formatRenWithShortTitle(elasticReport),
                        }),
                        (elasticReport) => ({
                            approvalStatus:
                                elasticReport.secondaryApprovalStatus ===
                                ApprovalStatusEnum.APPROVED.name
                                    ? approvalStatusClientEnum.UCR_APPROVED
                                    : elasticReport.approvalStatus ===
                                      ApprovalStatusEnum.COMPLETED.name
                                    ? approvalStatusClientEnum.APPROVED
                                    : elasticReport.approvalStatus,
                        }),
                    ],
                    helpers: {
                        formatMiniUserShortHandById,
                        formatAttributeById,
                        formatSubdivisionAttrIds,
                        formatReportDefinitionById,
                        formatAgencyProfileById,
                        allMiniUserFormatsById,
                    },
                })
            )
    );

export const elasticReportViewModelsSelector = elasticReportResultViewModelsSelector(
    elasticReportsSelector
);

export const elasticReportViewModelByIdSelector = createSelector(
    elasticReportViewModelsSelector,
    (elasticReportViewModels) => (id) => elasticReportViewModels[id]
);

import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import { offensesByReportIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { currentReportSelector } from '../../../legacy-redux/selectors/reportSelectors';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

export const getReportHasMultipleOffenses = (getState: () => RootState) => () => {
    const state = getState();
    if (!checkIfDepartmentIsNibrs(currentUserDepartmentProfileSelector(state))) {
        return true;
    }
    const currentReport = currentReportSelector(state);
    const currentReportId = currentReport?.id;
    const offenses = !!currentReportId ? offensesByReportIdSelector(state)(currentReportId) : [];

    return offenses.length > 1;
};

import { find, get, map } from 'lodash';

import { createSelector } from 'reselect';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';
import { storageLocationViewModelByIdSelector } from '../../../storage-locations/state/ui';

import {
    buildAllMiniUserFormatsMapper,
    buildViewModel,
} from '../../../../../helpers/viewModelHelpers';

import { inventoriesSelector } from '../data';

/**
 * A selector that is based on a selector criteria, create and populate a view model for all inventories
 * @param baseSelector
 */
export const createInventoriesViewModelSelector = (baseSelector) =>
    createSelector(
        allMiniUserFormatsByIdSelector,
        baseSelector,
        storageLocationViewModelByIdSelector,
        (allMiniUserFormatsById, currentResults, storageLocationViewModelById) =>
            map(
                currentResults,
                buildViewModel({
                    helpers: { allMiniUserFormatsById },
                    mappers: [
                        buildAllMiniUserFormatsMapper(),
                        ({
                            inventoryType,
                            missingItemsCount,
                            notes,
                            scannedItemsCount,
                            storageLocationId,
                            unexpectedItemsCount,
                        }) => {
                            const storageLocationViewModel = storageLocationViewModelById(
                                storageLocationId
                            );

                            return {
                                inventoryType,
                                missingItemsCount,
                                notes,
                                scannedItemsCount,
                                storageLocationName: get(
                                    storageLocationViewModel,
                                    'displayValue',
                                    ''
                                ),
                                storageLocationFullDisplayPath: get(
                                    storageLocationViewModel,
                                    'fullDisplayPath',
                                    ''
                                ),
                                unexpectedItemsCount,
                            };
                        },
                    ],
                    recursive: false,
                })
            )
    );

/**
 * A selector that creates and populates an inventories view model based createNormalizedModule
 */
const inventoriesViewModelSelector = createInventoriesViewModelSelector(inventoriesSelector);

/**
 * A selector that creates and populates an inventory view model based on a specific inventory
 */
export const inventoryViewModelByIdSelector = createSelector(
    inventoriesViewModelSelector,
    (inventoryResultsViewModel) => (inventoryId) =>
        find(inventoryResultsViewModel, (inventory) => inventory.id === inventoryId) || {}
);

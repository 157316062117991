import { SystemRuleEnum, FieldTypeEnum } from '@mark43/rms-api';

import { parseInt, isFinite } from 'lodash';

import addRuleId from '../../../../../legacy-redux/validation/helpers/addRuleId';
import { dateValueNowStringKey } from '../../utils/fieldConfigurationAdminHelpers';

export const fieldConfigiurationAdminMinGreaterThanFieldDetailMin = addRuleId(
    SystemRuleEnum.FIELD_CONFIGURATION_ADMIN_MIN_GREATER_THAN_OR_EQUAL_TO_FIELD_DETAIL_MIN.name,
    (fieldConfigurationsAdminFormModel) => {
        const { fieldDetail, fieldConfiguration } = fieldConfigurationsAdminFormModel;

        // Only `STRING`, `INTEGER`, and `NUMBER` have inputtable mins
        switch (fieldDetail.fieldType) {
            case FieldTypeEnum.STRING.name:
            case FieldTypeEnum.INTEGER.name:
                const logicalMinInt = parseInt(fieldDetail.logicalMin);
                const minInt = parseInt(fieldConfiguration.min);

                if (!isFinite(minInt)) {
                    return false;
                }
                return minInt >= logicalMinInt;
            case FieldTypeEnum.NUMBER.name:
                const logicalMinNumber = Number(fieldDetail.logicalMin);
                const minNumber = Number(fieldConfiguration.min);

                if (!isFinite(minNumber)) {
                    return false;
                }
                return minNumber >= logicalMinNumber;
            default:
                return true;
        }
    }
);

export const fieldConfigiurationAdminMaxLessThanFieldDetailMax = addRuleId(
    SystemRuleEnum.FIELD_CONFIGURATION_ADMIN_MAX_LESS_THAN_OR_EQUAL_TO_FIELD_DETAIL_MAX.name,
    (fieldConfigurationsAdminFormModel) => {
        const { fieldDetail, fieldConfiguration } = fieldConfigurationsAdminFormModel;

        // Only `STRING`, `INTEGER`, and `NUMBER` have inputtable maxs
        switch (fieldDetail.fieldType) {
            case FieldTypeEnum.STRING.name:
            case FieldTypeEnum.INTEGER.name:
                const logicalMaxInt = parseInt(fieldDetail.logicalMax);
                const maxInt = parseInt(fieldConfiguration.max);

                if (!isFinite(maxInt)) {
                    return false;
                }
                return maxInt <= logicalMaxInt;
            case FieldTypeEnum.NUMBER.name:
                const logicalMaxNumber = Number(fieldDetail.logicalMax);
                const maxNumber = Number(fieldConfiguration.max);

                if (!isFinite(maxNumber)) {
                    return false;
                }
                return maxNumber <= logicalMaxNumber;
            default:
                return true;
        }
    }
);

export const fieldConfigurationAdminMinLessThanMax = addRuleId(
    SystemRuleEnum.FIELD_CONFIGURATION_ADMIN_MIN_LESS_THAN_OR_EQUAL_TO_MAX.name,
    (fieldConfigurationsAdminFormModel) => {
        const { fieldDetail, fieldConfiguration } = fieldConfigurationsAdminFormModel;

        // Only `STRING`, `INTEGER`, and `NUMBER` have inputtable mins/maxs
        switch (fieldDetail.fieldType) {
            case FieldTypeEnum.STRING.name:
            case FieldTypeEnum.INTEGER.name:
                const minInt = parseInt(fieldConfiguration.min);
                const maxInt = parseInt(fieldConfiguration.max);

                if (!isFinite(minInt) || !isFinite(maxInt)) {
                    return false;
                }
                return minInt <= maxInt;
            case FieldTypeEnum.NUMBER.name:
                const minNumber = Number(fieldConfiguration.min);
                const maxNumber = Number(fieldConfiguration.max);

                if (!isFinite(minNumber) || !isFinite(maxNumber)) {
                    return false;
                }
                return minNumber <= maxNumber;
            default:
                return true;
        }
    }
);

export const fieldConfigurationAdminDateMinMaxCannotBothBeNow = addRuleId(
    SystemRuleEnum.FIELD_CONFIGURATION_ADMIN_DATE_MIN_MAX_CANNOT_BOTH_BE_NOW.name,
    (fieldConfigurationsAdminFormModel) => {
        const { fieldDetail, fieldConfiguration } = fieldConfigurationsAdminFormModel;

        // `DATE` and `DATETIME` field types cannot have both a min and a max
        // of 'NOW'
        switch (fieldDetail.fieldType) {
            case FieldTypeEnum.DATE.name:
            case FieldTypeEnum.DATETIME.name:
                if (
                    fieldConfiguration.min === dateValueNowStringKey &&
                    fieldConfiguration.max === dateValueNowStringKey
                ) {
                    return false;
                }
                return true;
            default:
                return true;
        }
    }
);

import getLinkUrl from './getLinkUrl';

/**
 * Perfoms the required action when an item gets selected, based on the item's type
 */
const handleResultItemSelection = ({ router, customItemTypeActions, defaultAction }) => ({
    event = undefined,
    item,
    itemType,
}) => {
    const customActionForItemType = customItemTypeActions[itemType];
    if (customActionForItemType) {
        if (event) {
            event.preventDefault();
        }
        customActionForItemType(item);
    } else {
        router.push(getLinkUrl({ item, itemType }));
    }

    // As of now we always want to perfom the default action.
    // If there is any need in the future to not do this for a
    // search result type, then we can adjust the usage in this
    // function
    defaultAction();
};

export default handleResultItemSelection;

import { EntityTypeEnum } from '@mark43/rms-api';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { prettify } from '~/client-common/helpers/stringHelpers';
import {
    stationsSelector,
    stationByIdSelector,
} from '~/client-common/core/domain/stations/state/data';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import { replaceLocationLinksForEntity } from '~/client-common/core/domain/location-entity-links/state/data';
import {
    LOAD_STATIONS_START,
    LOAD_STATIONS_SUCCESS,
    LOAD_STATIONS_FAILURE,
    REMOVE_STATION_FAILURE,
    SAVE_STATION_FORM_FAILURE,
    SAVE_STATION_FORM_SUCCESS,
} from '../data';
import stationForm from '../forms/stationForm';

const SELECT_STATION_START = 'stations/SELECT_STATION_START';
const SELECT_STATION_SUCCESS = 'stations/SELECT_STATION_SUCCESS';
const SELECT_STATION_FAILURE = 'stations/SELECT_STATION_FAILURE';

const SELECT_LOCATION = 'stations/SELECT_LOCATION';
const CLEAR_SELECTED_LOCATION = 'stations/CLEAR_SELECTED_LOCATION';

const RESET_STATIONS_ADMIN_PAGE = 'stations/RESET_STATIONS_ADMIN_PAGE';

const initialUiState = {
    error: null,
    submittingForm: false,
    selectedStationId: null,
    selectedLocation: null,
};

export const uiSelector = (state) => state.ui.stationsAdmin;

export const selectedStationSelector = createSelector(
    uiSelector,
    stationByIdSelector,
    (ui, stationById) => stationById(ui.selectedStationId)
);

export const stationsAdminUiListItemsSelector = createSelector(
    uiSelector,
    stationsSelector,
    (stationsAdmin, stations) => {
        return _(stations)
            .filter((station) => station.station.id > -1)
            .map((station) => {
                return {
                    key: station.station.id,
                    title: prettify(station.station.name),
                    path: `/admin/cad/stations/${station.station.id}`,
                    selected: stationsAdmin.selectedStationId === station.station.id,
                    status: station.station.isActive
                        ? attributeStatuses.ACTIVE
                        : attributeStatuses.DISABLED,
                };
            })
            .sortBy('title')
            .value();
    }
);

export const selectedLocationSelector = createSelector(
    uiSelector,
    (stationsAdmin) => stationsAdmin.selectedLocation
);

function selectStationStart() {
    return { type: SELECT_STATION_START };
}

function selectStationSuccess(stationId) {
    return {
        type: SELECT_STATION_SUCCESS,
        payload: stationId,
    };
}

function selectStationFailure(errorMessage) {
    return {
        type: SELECT_STATION_FAILURE,
        payload: errorMessage,
    };
}

export function selectLocation(location) {
    return {
        type: SELECT_LOCATION,
        payload: {
            location,
        },
    };
}

function clearSelectedLocationSuccess() {
    return {
        type: CLEAR_SELECTED_LOCATION,
    };
}

export function clearSelectedLocation(stationId) {
    return (dispatch) => {
        dispatch(replaceLocationLinksForEntity(EntityTypeEnum.STATION.name, stationId, []));
        dispatch(clearSelectedLocationSuccess());
    };
}

export function resetStationsAdminPage() {
    return { type: RESET_STATIONS_ADMIN_PAGE };
}

function stationsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SELECT_STATION_START:
            return {
                ...state,
                submittingForm: false,
                selectedStationId: null,
                selectedLocation: null,
                error: null,
            };

        case SELECT_STATION_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                selectedStationId: action.payload,
                error: null,
            };

        case SELECT_STATION_FAILURE:
        case LOAD_STATIONS_START:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case LOAD_STATIONS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case LOAD_STATIONS_FAILURE:
            return {
                ...state,
                error: action.payload.message,
                loading: false,
            };
        case REMOVE_STATION_FAILURE:
            return {
                ...state,
                submittingForm: false,
                error: action.payload.message,
            };
        case SELECT_LOCATION:
            return {
                ...state,
                selectedLocation: action.payload.location,
            };
        case CLEAR_SELECTED_LOCATION:
            return {
                ...state,
                selectedLocation: null,
            };
        case SAVE_STATION_FORM_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                error: null,
            };
        case SAVE_STATION_FORM_FAILURE:
            return {
                ...state,
                submittingForm: false,
                error: action.payload.message,
            };
        default:
            return state;
    }
}

export function selectStation(stationId) {
    return (dispatch, getState) => {
        dispatch(selectStationStart());
        const state = getState();
        const id = parseInt(stationId);
        const station = stationByIdSelector(state)(id);

        if (station) {
            stationForm.set('', station.station);
            dispatch(selectStationSuccess(station.id));
        } else {
            dispatch(selectStationFailure());
        }
    };
}

export default makeResettable(RESET_STATIONS_ADMIN_PAGE, stationsAdminUiReducer);

import { filter, some, uniqBy, flatten, map, size } from 'lodash';

/**
 * Determines all abilities that depend on a given ability
 * @param  {object} Ability
 * @param  {object} All Abilities
 * @return {array}  Abilities that depend on the provided ability
 */
export function getDependentAbilities(rootAbility, allAbilities) {
    const directDependents = filter(allAbilities, (ability) =>
        some(ability.dependencyIds, (dependedAbilityId) => dependedAbilityId === rootAbility.id)
    );

    if (directDependents.length === 0) {
        return [];
    } else {
        return uniqBy(
            [
                ...directDependents,
                ...flatten(
                    map(directDependents, (directDependent) =>
                        getDependentAbilities(directDependent, allAbilities)
                    )
                ),
            ],
            'id'
        );
    }
}

/**
 * Given an Ability, determines all abilities that it depends on (directly or indirectly)
 * @param  {object} Ability
 * @param  {object} All Abilities
 * @return {array}  Abilities that are depended on
 */
export function getDependedAbilities(ability, allAbilities) {
    if (size(ability.dependencyIds) === 0) {
        return [];
    } else {
        return uniqBy(
            [
                ...map(ability.dependencyIds, (abilityId) => allAbilities[abilityId]),
                ...flatten(
                    map(ability.dependencyIds, (abilityId) =>
                        getDependedAbilities(allAbilities[abilityId], allAbilities)
                    )
                ),
            ],
            'id'
        );
    }
}

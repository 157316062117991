// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/staff_remark';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Staff Remark Resource',
        methods: {
            /**
             * Create staff remarks.
             * @param  {Object[]} staffRemarks
             * @return {Promise<Object[]>}
             */
            createStaffRemarks(staffRemarks) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/bulk`,
                    data: staffRemarks,
                });
            },

            /**
             * Get staff remarks for the given entity.
             * @param  {number} entityType
             * @param  {number} entityId
             * @return {Promise<Object[]>}
             */
            getStaffRemarksForEntity(entityType, entityId) {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/${entityType}/${entityId}`,
                });
            },

            /**
             * Delete a staff remark.
             * @param  {number} id
             * @return {Promise}
             */
            deleteStaffRemark(id) {
                return req({
                    method: 'DELETE',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/${id}`,
                }).then((success) => {
                    if (!success) {
                        throw new Error('Failed to delete remark');
                    }
                });
            },
        },
    });
}

/**
 * Resource for evidence staff remarks. See the tech doc for details.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 * @see
 *   https://docs.google.com/document/d/1rCEWy78G7YoPKo_bzrWDWlpQ9-C7ieAcV_PF3xmiuGA/
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { chain, map } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, pure, withHandlers, withPropsOnChange } from 'recompose';
import styled from 'styled-components';
import { mediaQueries } from 'arc';
import { linkedReportShortTitleViewModelsForMasterItemSelector } from '~/client-common/core/domain/item-report-links/state/ui';
import { chainOfCustodiesSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import { chainEventViewModelsForChainOfCustodyIdSelector } from '~/client-common/core/domain/chain-events/state/ui';
import {
    inPoliceCustodyChainEventTypeSelector,
    checkedInTempChainEventTypeSelector,
    chainEventTypeIdIsDispositionedSelector,
} from '~/client-common/core/domain/chain-event-types/state/data';
import { propertyStatusesByItemProfileIdSelector } from '~/client-common/core/domain/property-statuses/state/data';
import {
    buildPropertyStatusViewModelSelector,
    propertyStatusViewModelByIdSelector,
} from '~/client-common/core/domain/property-statuses/state/ui';
import {
    mergePropertyStatuses,
    deduplicatePropertyStatuses,
} from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import { itemFacilityLinksSelector } from '~/client-common/core/domain/item-facility-links/state/data';
import { nameItemLinksWhereSelector } from '~/client-common/core/domain/name-item-links/state/data/';
import { legacyEntityDetailsWhereSelector } from '~/client-common/core/domain/legacy-entity-details/state/data';
import { itemTypeSpecificViewModelByIdSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import _Checkbox from '../../../../core/forms/components/checkboxes/Checkbox';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import { responsiveStack } from '../../../../core/styles/mixins';

import _ItemInfo from '../../../../evidence/core/components/ItemInfo';
import {
    currentReportIdSelector,
    currentReportRENSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';

import custodialPropertyCard from '../../../core/state/ui/custodialPropertyCard';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import _DispositionStatus from '../../../../evidence/core/components/DispositionStatus';
import _DispositionReviewCard from '../disposition/DispositionReviewCard';
import DispositionHistory from '../disposition/DispositionHistory';
import _ItemDetails from '../../../core/components/items/ItemCard/Details';
import testIds from '../../../../../core/testIds';
import CustodialPropertyCardItemStaffRemarks from './CustodialPropertyCardItemStaffRemarks';
import CustodialPropertyCardItemLatestChainEvent from './CustodialPropertyCardItemLatestChainEvent';
import _CustodialPropertyCardItemLinkedReports from './CustodialPropertyCardItemLinkedReports';
import CustodialPropertyCardItemActions from './CustodialPropertyCardItemActions';

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const CardItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: var(--arc-space-1);

    @media (min-width: ${mediaQueries.md}) {
        margin: 10px 0 0 8px;
    }
`;

const ContentRow = styled.div`
    ${responsiveStack()};
    gap: var(--arc-space-3);
`;

const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--arc-space-2);
`;
const ActionsColumn = styled.div`
    display: flex;
    flex: 0 0 auto;
`;

const CheckoxColumn = styled.div`
    width: 22px;
    display: flex;

    @media (min-width: ${mediaQueries.md}) {
        width: 40px;
    }
`;

const FlexFooter = styled.div`
    flex: 1;

    & > div {
        margin-top: 30px;

        &:empty,
        &:first-child {
            margin-top: 0;
        }
    }
    @media (min-width: ${mediaQueries.md}) {
        margin-left: 25px;
    }
`;

const Details = styled.div`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;

    & > div {
        margin-top: 16px;

        &:empty {
            margin-top: 0;
        }
    }

    @media (min-width: ${mediaQueries.md}) {
        margin-left: 25px;
    }
`;

// TODO: Remove the default side margin from this component
const ItemDetails = styled(_ItemDetails)`
    margin-left: -10px;
`;

const Checkbox = styled(_Checkbox)`
    flex: 0 0 25px;
    margin-top: -2px;
`;

const ItemInfo = styled(_ItemInfo)`
    flex: 1;
`;

const CustodialPropertyCardItemLinkedReports = styled(_CustodialPropertyCardItemLinkedReports)`
    flex: 0 0 33%;
`;

const Summary = styled.div`
    display: ${({ show }) => (show ? 'block' : 'none')};
    flex: 0 0 33%;
`;

const DispositionStatus = styled(_DispositionStatus)`
    text-align: left;

    & > div {
        margin-top: 16px;

        &:empty {
            margin-top: 0;
        }
    }
`;

const Actions = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
`;

const ToggleButtonColumn = styled.div`
    border-left: 1px solid ${(props) => props.theme.colors.lightGrey};
    flex: 0 0 1.5rem;
    padding-left: var(--arc-space-2);
`;

const DispositionReviewCard = styled(_DispositionReviewCard)`
    float: none;
    width: 100%;

    .mark43-react-card-content {
        float: none;

        & .mark43-react-approval-status-card-content {
            width: auto;
        }
    }
`;

function CustodialPropertyCardItem({
    summaryMode,
    canEditReport,
    linkedReportShortTitleViewModelsForMasterItem,
    itemProfileViewModel,
    itemSummaryViewModel,
    ren,
    reportId,
    chainOfCustodyId,
    hasCoC,
    selected,
    onSelectionToggle,
    className,
    isInPoliceCustody,
    isInTempFacility,
    isDispositioned,
    currentUserHasAbility,
    detailsCollapsed,
}) {
    // form visible by default to encourage users to fill out details
    const [propertyStatusCollapsed, setPropertyStatusCollapsed] = useState(
        (detailsCollapsed && hasCoC) || !(!summaryMode && !hasCoC)
    );
    const [prevPropertyStatusCollapsed, setPrevPropertyStatusCollapsed] =
        useState(detailsCollapsed);

    if (!itemProfileViewModel) {
        // The item profile can be missing when there is bad data from the server, or while the item
        // is being deleted (after being removed from data Nexus, and before being removed from form
        // model state). While this is an undesirable state, this check makes sure the rest of the
        // CPS Property Card continues to render properly.
        return null;
    }

    if (prevPropertyStatusCollapsed !== detailsCollapsed) {
        setPropertyStatusCollapsed(detailsCollapsed);
        setPrevPropertyStatusCollapsed(detailsCollapsed);
    }

    const { id, itemTypeAttrId, masterItemId } = itemProfileViewModel;
    const linkedReportShortTitleViewModels = linkedReportShortTitleViewModelsForMasterItem(
        masterItemId,
        ren
    );
    const linkedToOtherReports = linkedReportShortTitleViewModels.length > 0;
    const iconType = propertyStatusCollapsed ? iconTypes.OPEN : iconTypes.CLOSE;

    const evidenceItemEdit = currentUserHasAbility(abilitiesEnum.EVIDENCE.ITEM_QUEUE);
    const canEditItem = evidenceItemEdit
        ? !hasCoC || !isDispositioned
        : !hasCoC || isInPoliceCustody || isInTempFacility;

    const canEdit = canEditReport && canEditItem;
    const canDelete =
        canEditReport &&
        (currentUserHasAbility(abilitiesEnum.EVIDENCE.DELETE_EVIDENCE_ITEMS) || !hasCoC);
    const canViewStaffRemarks = currentUserHasAbility(abilitiesEnum.EVIDENCE.VIEW_STAFF_REMARKS);
    const isVehicle = globalAttributes.itemType.vehicle === itemTypeAttrId;
    return (
        <CardItemWrapper
            className={className}
            data-test-id={testIds.CUSTODIAL_PROPERTY_CARD_ITEM}
            data-test-display={itemProfileViewModel.description}
        >
            <CheckoxColumn>
                {summaryMode && hasCoC && (
                    <Checkbox
                        value={selected}
                        onChange={onSelectionToggle}
                        testId={testIds.CUSTODIAL_PROPERTY_CHECKBOX}
                    />
                )}
            </CheckoxColumn>
            <ContentColumn>
                <ContentRow>
                    <ItemInfo
                        viewModel={itemProfileViewModel}
                        reportId={reportId}
                        showHighRiskLabel={true}
                    />

                    {hasCoC && linkedToOtherReports && (
                        <CustodialPropertyCardItemLinkedReports
                            reportShortTitleViewModels={linkedReportShortTitleViewModels}
                        />
                    )}
                    <Summary show={summaryMode}>
                        <CustodialPropertyCardItemLatestChainEvent
                            chainOfCustodyId={chainOfCustodyId}
                        />

                        <DispositionStatus chainOfCustodyId={chainOfCustodyId} />
                    </Summary>
                </ContentRow>
                <Details show={!propertyStatusCollapsed}>
                    <ItemDetails data={itemSummaryViewModel} />
                </Details>

                {summaryMode && hasCoC && (
                    <FlexRow>
                        <FlexFooter>
                            {canViewStaffRemarks && (
                                <CustodialPropertyCardItemStaffRemarks
                                    collapsed={propertyStatusCollapsed}
                                    itemProfileId={id}
                                />
                            )}

                            <DispositionReviewCard chainOfCustodyId={chainOfCustodyId} />

                            <DispositionHistory
                                chainOfCustodyId={chainOfCustodyId}
                                collapsed={detailsCollapsed}
                            />
                        </FlexFooter>
                    </FlexRow>
                )}
            </ContentColumn>
            {!summaryMode && (
                <ActionsColumn>
                    <Actions>
                        <CustodialPropertyCardItemActions
                            isVehicle={isVehicle}
                            itemProfileId={id}
                            masterItemId={masterItemId}
                            canEdit={canEdit}
                            canDelete={canDelete}
                            hasCoC={hasCoC}
                            isDispositioned={isDispositioned}
                            reportId={reportId}
                        />
                    </Actions>
                </ActionsColumn>
            )}
            <ToggleButtonColumn>
                <Button
                    className={buttonTypes.ICON_LINK}
                    iconLeft={<Icon type={iconType} size={16} color="cobaltBlue" />}
                    onClick={() => setPropertyStatusCollapsed(!propertyStatusCollapsed)}
                    testId={testIds.CUSTODIAL_PROPERTY_CARD_TOGGLE_BUTTON}
                />
            </ToggleButtonColumn>
        </CardItemWrapper>
    );
}

const mapStateToProps = createStructuredSelector({
    reportId: currentReportIdSelector,
    ren: currentReportRENSelector,
    linkedReportShortTitleViewModelsForMasterItem:
        linkedReportShortTitleViewModelsForMasterItemSelector,
    itemProfileViewModelById: itemTypeSpecificViewModelByIdSelector,
    chainOfCustodies: chainOfCustodiesSelector,
    chainEventViewModelsForChainOfCustodyId: chainEventViewModelsForChainOfCustodyIdSelector,
    inPoliceCustodyChainEventType: inPoliceCustodyChainEventTypeSelector,
    checkedInTempChainEventType: checkedInTempChainEventTypeSelector,
    chainEventTypeIdIsDispositioned: chainEventTypeIdIsDispositionedSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    propertyStatusesByItemProfileId: propertyStatusesByItemProfileIdSelector,
    buildPropertyStatusViewModel: buildPropertyStatusViewModelSelector,
    propertyStatusViewModelById: propertyStatusViewModelByIdSelector,
    itemFacilityLinks: itemFacilityLinksSelector,
    nameItemLinksWhere: nameItemLinksWhereSelector,
    legacyEntityDetailsWhere: legacyEntityDetailsWhereSelector,
});

const mapDispatchToProps = (dispatch) => ({
    resetError: () => dispatch(custodialPropertyCard.actionCreators.setErrorMessages([])),
});

/**
 * Individual piece of property in the Custodial Property card. Handles both
 *   summary mode and edit mode.
 */
export default compose(
    pure,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onSelectionToggle({ onSelectionToggle, itemProfileId }) {
            // when the checkbox is changed
            return () => onSelectionToggle(itemProfileId);
        },
    }),
    withPropsOnChange(
        ['itemProfileId', 'itemProfileViewModelById', 'reportId', 'chainOfCustodies'],
        ({
            itemProfileId,
            itemProfileViewModelById,
            reportId,
            chainOfCustodies,
            chainEventViewModelsForChainOfCustodyId,
            inPoliceCustodyChainEventType,
            checkedInTempChainEventType,
            chainEventTypeIdIsDispositioned,
            propertyStatusesByItemProfileId,
            buildPropertyStatusViewModel,
            propertyStatusViewModelById,
            itemFacilityLinks,
            nameItemLinksWhere,
            legacyEntityDetailsWhere,
        }) => {
            const itemProfileViewModel = itemProfileViewModelById(itemProfileId);
            if (!itemProfileViewModel) {
                return {};
            }

            const { masterItemId } = itemProfileViewModel;
            const chainOfCustodyId = chain(chainOfCustodies)
                .find({ masterItemId, reportId })
                .get('id')
                .value();

            // build the same view model shape as itemSummaryViewModelsForCurrentReportSelector
            const propertyStatuses = chain(itemProfileId)
                .thru(propertyStatusesByItemProfileId)
                .thru((propertyStatuses) => deduplicatePropertyStatuses(propertyStatuses))
                .value();
            const itemSummaryViewModel = {
                ...itemProfileViewModel,
                // array of all unique property statuses belonging to this item
                // tech debt: rename this to be plural
                propertyStatus: map(propertyStatuses, ({ id }) => propertyStatusViewModelById(id)),
                // a single merged property status that is 'representative' for the item,
                // used to display values in summary mode
                mergedPropertyStatus: buildPropertyStatusViewModel(
                    mergePropertyStatuses(propertyStatuses)
                ),
                storageLocationId: chain(itemFacilityLinks)
                    .find({ itemProfileId })
                    .get('storageLocationId')
                    .value(),
                nameItemLinks: nameItemLinksWhere({ itemProfileId }),
                legacyEntityDetails: legacyEntityDetailsWhere({ entityId: itemProfileId }),
            };

            return chain(chainOfCustodyId)
                .thru(chainEventViewModelsForChainOfCustodyId)
                .map('eventTypeId')
                .head()
                .thru((eventTypeId) => ({
                    itemProfileViewModel,
                    itemSummaryViewModel,
                    chainOfCustodyId,
                    isInPoliceCustody: !!inPoliceCustodyChainEventType
                        ? eventTypeId === inPoliceCustodyChainEventType.id
                        : false,
                    isInTempFacility: eventTypeId === checkedInTempChainEventType.id,
                    isDispositioned: eventTypeId && chainEventTypeIdIsDispositioned(eventTypeId),
                }))
                .value();
        }
    )
)(CustodialPropertyCardItem);

import { get, omit, reduce } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    PERSON_PROFILE_FIRST_NAME,
    PERSON_PROFILE_LAST_NAME,
    PERSON_PROFILE_MIDDLE_NAME,
    PERSON_PROFILE_DATE_OF_BIRTH,
    NAME_IDENTIFIER_IDENTIFIER,
    WARRANT_WARRANT_ISSUED_DATE_UTC,
    WARRANT_WARRANT_NUMBER,
    WARRANT_COURT_CASE_NUMBER,
    WARRANT_STATUS_WARRANT_STATUS_ATTR_ID,
    WARRANT_WARRANT_TYPE_ATTR_ID,
    WARRANT_ISSUING_AGENCY_NAME_ATTR_ID,
    WARRANT_ATTRIBUTE_ATTRIBUTE_TYPE_WARRANT_STATISTIC_ATTRIBUTE_ID,
    WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER,
    WARRANT_DEX_SUBMISSION_NCIC_NUMBER,
    WARRANT_DEX_SUBMISSION_INTERNAL_WARRANT_STATUS_ATTR_ID,
    WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_ATTR_ID,
    WARRANT_DEX_SUBMISSION_DEX_WARRANT_TYPE_ATTR_ID,
    WARRANT_CHARGE_OFFENSE_CLASSIFICATION_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import { getSubdivisionsFromLocation } from '~/client-common/core/domain/locations/utils/subdivisionHelpers';

import { createFormModule } from '../../../../core/forms';
import {
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFlatFilters } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import { subdivisionAttrFieldsForViewModels } from '../../../../search/core/state/forms/subdivisionFields';

const { RANGE, ATTRIBUTE, DEPARTMENT, N_ITEMS_FIELDSET } = fieldTypeClientEnum;
const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;
const strings = componentStrings.warrants.dashboard.searchForm.labels;

const fieldViewModels = {
    ...buildFlatFormFieldViewModels(['quickSearchQuery']),
    ...buildFlatFormFieldViewModels([
        {
            key: 'reportingEventNumber',
        },
        {
            key: 'subjectFirstName',
        },
        {
            key: 'subjectMiddleName',
        },
        {
            key: 'subjectLastName',
        },
        {
            key: 'subjectDateOfBirth',
        },
        {
            type: N_ITEMS_FIELDSET,
            key: 'subjectIdentifiers',
            fields: buildFlatFormFieldViewModels([
                { key: 'nameIdentifierTypeAttrId', type: ATTRIBUTE },
                'idValue',
            ]),
        },
        {
            key: 'warrantIssuedWithinLastPeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: WITHIN_LAST_PERIOD,
        },
        {
            key: 'warrantIssuedToDatePeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: TO_DATE_PERIOD,
        },
        {
            key: 'warrantIssuedStartDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_START,
        },
        {
            key: 'warrantIssuedEndDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_END,
        },
    ]),
    departmentIds: {
        key: 'departmentIds',
        label: strings.agency,
        type: DEPARTMENT,
    },
    warrantTypeAttrIds: {
        key: 'warrantTypeAttrIds',
        type: ATTRIBUTE,
    },
    warrantNum: {
        key: 'warrantNum',
    },
    courtCaseNumber: {
        key: 'courtCaseNumber',
    },
    warrantStatusAttrIds: {
        key: 'warrantStatusAttrIds',
        type: ATTRIBUTE,
    },
    warrantLocationSubdivisionAttrIds: {
        key: 'warrantLocationSubdivisionAttrIds',
        type: ATTRIBUTE,
    },
    ...reduce(
        subdivisionAttrFieldsForViewModels,
        (result, value) => {
            result[value.key] = value;
            return result;
        },
        {}
    ),
    issuingAgencyNameAttrIds: {
        key: 'issuingAgencyNameAttrIds',
        type: ATTRIBUTE,
    },
    warrantStatisticAttrIds: {
        key: 'warrantStatisticAttrIds',
        type: ATTRIBUTE,
    },
    isLinkedToArrest: {
        key: 'isLinkedToArrest',
        label: strings.isLinkedToArrest,
    },
    dexRegionalMessageSwitchNumber: {
        key: 'dexRegionalMessageSwitchNumber',
    },
    dexNcicNumber: {
        key: 'dexNcicNumber',
    },
    dexInternalWarrantStatusAttrIds: {
        key: 'dexInternalWarrantStatusAttrIds',
        type: ATTRIBUTE,
    },
    dexWarrantEntryLevelCodeAttrIds: {
        key: 'dexWarrantEntryLevelCodeAttrIds',
        type: ATTRIBUTE,
    },
    dexWarrantTypeAttrIds: {
        key: 'dexWarrantTypeAttrIds',
        type: ATTRIBUTE,
    },
    offenseClassificationAttrIds: {
        key: 'offenseClassificationAttrIds',
        type: ATTRIBUTE,
    },
};

/**
 * Convert the given search query model to form state. While the query is flat,
 *   the form state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertWarrantsDashboardSearchElasticQueryToFormModel(elasticQuery = {}) {
    const subdivisions = omit(
        getSubdivisionsFromLocation(get(elasticQuery, 'involvedLocation')),
        'subdivisionAttrIds'
    );

    return buildFormModel(
        {
            quickSearchQuery: elasticQuery.quickSearchQuery,
            reportingEventNumber: elasticQuery.reportingEventNumber,
            departmentIds: elasticQuery.departmentIds,
            warrantIssuedWithinLastPeriod: elasticQuery.withinLastPeriod,
            warrantIssuedToDatePeriod: elasticQuery.toDatePeriod,
            warrantIssuedStartDateUtc: elasticQuery.startDateUtc,
            warrantIssuedEndDateUtc: elasticQuery.endDateUtc,
            warrantTypeAttrIds: elasticQuery.warrantTypeAttrIds,
            warrantNum: elasticQuery.warrantNum,
            courtCaseNumber: elasticQuery.courtCaseNumber,
            warrantStatusAttrIds: elasticQuery.warrantStatusAttrIds,
            warrantLocationSubdivisionAttrIds: get(
                elasticQuery,
                'involvedLocation.subdivisionAttrIds'
            ),
            ...subdivisions,
            issuingAgencyNameAttrIds: elasticQuery.issuingAgencyNameAttrIds,
            warrantStatisticAttrIds: elasticQuery.warrantStatisticAttrIds,
            isLinkedToArrest: elasticQuery.isLinkedToArrest,
            subjectIdentifiers: get(elasticQuery, 'subject.nameIdentifiers'),
            subjectFirstName: get(elasticQuery, 'subject.firstName'),
            subjectMiddleName: get(elasticQuery, 'subject.middleName'),
            subjectLastName: get(elasticQuery, 'subject.lastName'),
            subjectDateOfBirth: get(elasticQuery, 'subject.dateOfBirth'),
            dexRegionalMessageSwitchNumber: get(
                elasticQuery,
                'warrantDexSubmissions[0].regionalMessageSwitchNumber'
            ),
            dexNcicNumber: get(elasticQuery, 'warrantDexSubmissions[0].ncicNumber'),
            dexInternalWarrantStatusAttrIds: get(
                elasticQuery,
                'warrantDexSubmissions[0].internalWarrantStatusAttrIds'
            ),
            dexWarrantEntryLevelCodeAttrIds: get(
                elasticQuery,
                'warrantDexSubmissions[0].warrantEntryLevelCodeAttrIds'
            ),
            dexWarrantTypeAttrIds: get(
                elasticQuery,
                'warrantDexSubmissions[0].dexWarrantTypeAttrIds'
            ),
            offenseClassificationAttrIds: get(
                elasticQuery,
                'warrantCharges[0].offenseClassificationAttrIds'
            ),
        },
        fieldViewModels
    );
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching. The logic here is temporarily based on
 *   the elastic property model as of 2016-10-21.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertWarrantsDashboardSearchFormModelToElasticQuery(formModel = {}) {
    return filterFormData(
        {
            quickSearchQuery: formModel.quickSearchQuery,
            reportingEventNumber: formModel.reportingEventNumber,
            departmentIds: formModel.departmentIds,
            withinLastPeriod: formModel.warrantIssuedWithinLastPeriod,
            toDatePeriod: formModel.warrantIssuedToDatePeriod,
            startDateUtc: formModel.warrantIssuedStartDateUtc,
            endDateUtc: formModel.warrantIssuedEndDateUtc,
            warrantTypeAttrIds: formModel.warrantTypeAttrIds,
            warrantNum: formModel.warrantNum,
            courtCaseNumber: formModel.courtCaseNumber,
            warrantStatusAttrIds: formModel.warrantStatusAttrIds,
            warrantStatisticAttrIds: formModel.warrantStatisticAttrIds,
            isLinkedToArrest: formModel.isLinkedToArrest,
            involvedLocation: {
                ...getSubdivisionsFromLocation(formModel),
                subdivisionAttrIds: formModel.warrantLocationSubdivisionAttrIds,
            },
            issuingAgencyNameAttrIds: formModel.issuingAgencyNameAttrIds,
            subject: {
                quickSearchQuery: formModel.subjectName,
                nameIdentifiers: formModel.subjectIdentifiers,
                firstName: formModel.subjectFirstName,
                middleName: formModel.subjectMiddleName,
                lastName: formModel.subjectLastName,
                dateOfBirth: formModel.subjectDateOfBirth,
            },
            warrantDexSubmissions: [
                {
                    regionalMessageSwitchNumber: formModel.dexRegionalMessageSwitchNumber,
                    ncicNumber: formModel.dexNcicNumber,
                    internalWarrantStatusAttrIds: formModel.dexInternalWarrantStatusAttrIds,
                    warrantEntryLevelCodeAttrIds: formModel.dexWarrantEntryLevelCodeAttrIds,
                    dexWarrantTypeAttrIds: formModel.dexWarrantTypeAttrIds,
                },
            ],
            warrantCharges: [
                {
                    offenseClassificationAttrIds: formModel.offenseClassificationAttrIds,
                },
            ],
        },
        fieldViewModels
    );
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
export function convertWarrantsDashboardSearchFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    const relabeledFieldViewModels = {
        ...fieldViewModels,
        reportingEventNumber: {
            ...fieldViewModels.reportingEventNumber,
            label: boundSelectors.formatFieldByName(REPORT_REPORTING_EVENT_NUMBER),
        },
        subjectFirstName: {
            ...fieldViewModels.subjectFirstName,
            label: boundSelectors.formatFieldByName(PERSON_PROFILE_FIRST_NAME),
        },
        subjectMiddleName: {
            ...fieldViewModels.subjectMiddleName,
            label: boundSelectors.formatFieldByName(PERSON_PROFILE_MIDDLE_NAME),
        },
        subjectLastName: {
            ...fieldViewModels.subjectLastName,
            label: boundSelectors.formatFieldByName(PERSON_PROFILE_LAST_NAME),
        },
        subjectDateOfBirth: {
            ...fieldViewModels.subjectDateOfBirth,
            label: boundSelectors.formatFieldByName(PERSON_PROFILE_DATE_OF_BIRTH),
        },
        subjectIdentifiers: {
            ...fieldViewModels.subjectIdentifiers,
            filterLabel: (index) =>
                `${boundSelectors.formatFieldByName(NAME_IDENTIFIER_IDENTIFIER)} #${index + 1}`,
        },
        warrantIssuedWithinLastPeriod: {
            ...fieldViewModels.warrantIssuedWithinLastPeriod,
            label: boundSelectors.formatFieldByName(WARRANT_WARRANT_ISSUED_DATE_UTC),
        },
        warrantTypeAttrIds: {
            ...fieldViewModels.warrantTypeAttrIds,
            label: boundSelectors.formatFieldByName(WARRANT_WARRANT_TYPE_ATTR_ID),
        },
        warrantNum: {
            ...fieldViewModels.warrantNum,
            label: boundSelectors.formatFieldByName(WARRANT_WARRANT_NUMBER),
        },
        courtCaseNumber: {
            ...fieldViewModels.courtCaseNumber,
            label: boundSelectors.formatFieldByName(WARRANT_COURT_CASE_NUMBER),
        },
        warrantStatusAttrIds: {
            ...fieldViewModels.warrantStatusAttrIds,
            label: boundSelectors.formatFieldByName(WARRANT_STATUS_WARRANT_STATUS_ATTR_ID),
        },
        warrantLocationSubdivisionAttrIds: {
            ...fieldViewModels.warrantLocationSubdivisionAttrIds,
            label: boundSelectors.combinedSubdivisionsLabel,
        },
        subdivision1AttrIds: {
            ...fieldViewModels.subdivision1AttrIds,
            label: boundSelectors.formatFieldByName(fieldViewModels.subdivision1AttrIds.fieldName),
        },
        subdivision2AttrIds: {
            ...fieldViewModels.subdivision2AttrIds,
            label: boundSelectors.formatFieldByName(fieldViewModels.subdivision2AttrIds.fieldName),
        },
        subdivision3AttrIds: {
            ...fieldViewModels.subdivision3AttrIds,
            label: boundSelectors.formatFieldByName(fieldViewModels.subdivision3AttrIds.fieldName),
        },
        subdivision4AttrIds: {
            ...fieldViewModels.subdivision4AttrIds,
            label: boundSelectors.formatFieldByName(fieldViewModels.subdivision4AttrIds.fieldName),
        },
        subdivision5AttrIds: {
            ...fieldViewModels.subdivision5AttrIds,
            label: boundSelectors.formatFieldByName(fieldViewModels.subdivision5AttrIds.fieldName),
        },
        issuingAgencyNameAttrIds: {
            ...fieldViewModels.issuingAgencyNameAttrIds,
            label: boundSelectors.formatFieldByName(WARRANT_ISSUING_AGENCY_NAME_ATTR_ID),
        },
        warrantStatisticAttrIds: {
            ...fieldViewModels.warrantStatisticAttrIds,
            label: boundSelectors.formatFieldByName(
                WARRANT_ATTRIBUTE_ATTRIBUTE_TYPE_WARRANT_STATISTIC_ATTRIBUTE_ID
            ),
        },
        dexRegionalMessageSwitchNumber: {
            ...fieldViewModels.dexRegionalMessageSwitchNumber,
            label: boundSelectors.formatFieldByName(
                WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER
            ),
        },
        dexNcicNumber: {
            ...fieldViewModels.dexNcicNumber,
            label: boundSelectors.formatFieldByName(WARRANT_DEX_SUBMISSION_NCIC_NUMBER),
        },
        dexInternalWarrantStatusAttrIds: {
            ...fieldViewModels.dexInternalWarrantStatusAttrIds,
            label: boundSelectors.formatFieldByName(
                WARRANT_DEX_SUBMISSION_INTERNAL_WARRANT_STATUS_ATTR_ID
            ),
        },
        dexWarrantEntryLevelCodeAttrIds: {
            ...fieldViewModels.dexWarrantEntryLevelCodeAttrIds,
            label: boundSelectors.formatFieldByName(
                WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_ATTR_ID
            ),
        },
        dexWarrantTypeAttrIds: {
            ...fieldViewModels.dexWarrantTypeAttrIds,
            label: boundSelectors.formatFieldByName(
                WARRANT_DEX_SUBMISSION_DEX_WARRANT_TYPE_ATTR_ID
            ),
        },
        offenseClassificationAttrIds: {
            ...fieldViewModels.offenseClassificationAttrIds,
            label: boundSelectors.formatFieldByName(WARRANT_CHARGE_OFFENSE_CLASSIFICATION_ATTR_ID),
        },
    };

    return convertFormModelToFlatFilters(formModel, relabeledFieldViewModels, formatFieldValue);
}

const warrantsDashboardSearchForm = createFormModule({
    formName: formClientEnum.WARRANTS_DASHBOARD_SEARCH,
    fieldViewModels,
    convertToFormModel: convertWarrantsDashboardSearchElasticQueryToFormModel,
    convertFromFormModel: convertWarrantsDashboardSearchFormModelToElasticQuery,
});

/**
 * Module of the search form on the warrants dashboard. It contains both the
 *   free text search input and the filters below it.
 * @type {Object}
 */
export default warrantsDashboardSearchForm;

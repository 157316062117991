import { RIPAOffenseCode } from '@mark43/rms-api';

import { ClientCommonAction } from '../../../../../redux/types';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getRipaOffenseCodesResource from '../../resources/ripaOffenseCodeResource';

export const NEXUS_STATE_PROP = 'ripaOffenseCodes';

const ripaOffenseCodesModule = createNormalizedModule<RIPAOffenseCode>({
    type: NEXUS_STATE_PROP,
});

export const ripaOffenseCodesSelector = ripaOffenseCodesModule.selectors.entitiesSelector;

const SEARCH_FOR_RIPA_OFFENSE_CODES_START = 'SEARCH_FOR_RIPA_OFFENSE_CODES_START';
const SEARCH_FOR_RIPA_OFFENSE_CODES_SUCCESS = 'SEARCH_FOR_RIPA_OFFENSE_CODES_SUCCESS';
const SEARCH_FOR_RIPA_OFFENSE_CODES_FAIL = 'SEARCH_FOR_RIPA_OFFENSE_CODES_FAIL';

function searchForRipaOffenseCodesStart() {
    return { type: SEARCH_FOR_RIPA_OFFENSE_CODES_START };
}
function searchForRipaOffenseCodesSuccess(offenseCodes: RIPAOffenseCode[]) {
    return { type: SEARCH_FOR_RIPA_OFFENSE_CODES_SUCCESS, payload: offenseCodes };
}
function searchForRipaOffenseCodesFail(message: string) {
    return { type: SEARCH_FOR_RIPA_OFFENSE_CODES_FAIL, payload: message };
}

export function searchForRipaOffenseCodes({
    q,
    from,
    size,
}: {
    q?: string;
    from?: number;
    size?: number;
}): ClientCommonAction<Promise<void>> {
    const ripaOffenseCodesResource = getRipaOffenseCodesResource();

    return (dispatch, getState, { nexus }) => {
        dispatch(searchForRipaOffenseCodesStart());
        return ripaOffenseCodesResource
            .searchForRipaOffenseCodes({
                q,
                from,
                size,
            })
            .then((ripaOffenseCodes: RIPAOffenseCode[]) => {
                return dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: ripaOffenseCodes,
                        },
                        searchForRipaOffenseCodesSuccess(ripaOffenseCodes)
                    )
                );
            })
            .catch((err: Error) => {
                dispatch(searchForRipaOffenseCodesFail(err.message));
                throw err;
            });
    };
}

export default ripaOffenseCodesModule.reducerConfig;

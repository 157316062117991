import React from 'react';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Card from '../../../../legacy-redux/components/core/Card';
import withCard from '../../../reports/core/utils/withCard';
import warrantDetailsCard from '../state/cards/warrantDetailsCard';
import testIds from '../../../../core/testIds';
import warrantDetailsForm from '../state/forms/warrantDetailsForm';
import { populateWarrantDetailsForm } from '../state/ui';
import WarrantDetailsForm from './WarrantDetailsForm';
import WarrantDetailsSummary from './WarrantDetailsSummary';

const strings = componentStrings.warrants.warrant.WarrantDetailsCard;

function WarrantDetailsCard({ onEdit, onSave, card = {}, warrantId, canEdit }) {
    return (
        <Card
            testId={testIds.WARRANT_DETAILS_CARD}
            className={card.anchor}
            anchor={card.anchor}
            title={strings.title}
            errors={card.errorMessages}
            renderContent={(summaryMode) => {
                if (summaryMode) {
                    return <WarrantDetailsSummary warrantId={warrantId} />;
                } else {
                    return <WarrantDetailsForm warrantId={warrantId} />;
                }
            }}
            onEdit={onEdit}
            summaryMode={card.summaryMode}
            canEdit={canEdit}
            saving={card.saving}
            onSave={onSave}
        />
    );
}

const mapDispatchToProps = (dispatch, { warrantId }) => ({
    onSave() {
        dispatch(warrantDetailsCard.actionCreators.save(warrantId, warrantDetailsForm)).catch(
            () => {
                warrantDetailsCard.scrollToTop();
            }
        );
    },
    onSaveFailure(message) {
        dispatch(warrantDetailsCard.actionCreators.savingFailure(message));
    },
    onEdit() {
        dispatch(
            warrantDetailsCard.actionCreators.transitionToEditMode(populateWarrantDetailsForm)
        );
    },
});

export default compose(
    withCard(warrantDetailsCard),
    connect(null, mapDispatchToProps),
    pure
)(WarrantDetailsCard);

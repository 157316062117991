import { AttributeTypeEnum, EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { createFormConfiguration, createFieldset, _Form } from 'markformythree';
import { head, get, map } from 'lodash';

import * as fields from '~/client-common/core/enums/universal/fields';

import { missingPersonsByReportIdSelector } from '~/client-common/core/domain/missing-persons/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { reportAttributesWhereSelector } from '~/client-common/core/domain/report-attributes/state/data';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const formName = RefContextEnum.FORM_MISSING_PERSONS.name;
export const LAST_KNOWN_CONTACTS_PATH = 'lastKnownContacts';
export const WITNESSES_PATH = 'witnesses';
const LAST_KNOWN_LOCATION_PATH = 'lastKnownLocation';
const LAST_KNOWN_LOCATION_DESCRIPTION_PATH = 'description';
const LAST_KNOWN_LOCATION_POSITION_ATTR_ID_PATH = 'positionAttrId';
const LOCATION_ID_PATH = 'locationId';
export const LAST_KNOWN_LOCATION_DESCRIPTION_FULL_PATH = `${LAST_KNOWN_LOCATION_PATH}.${LAST_KNOWN_LOCATION_DESCRIPTION_PATH}`;
export const LAST_KNOWN_LOCATION_POSITION_ATTR_ID_FULL_PATH = `${LAST_KNOWN_LOCATION_PATH}.${LAST_KNOWN_LOCATION_POSITION_ATTR_ID_PATH}`;
export const LAST_KNOWN_LOCATION_LOCATION_ID_FULL_PATH = `${LAST_KNOWN_LOCATION_PATH}.${LOCATION_ID_PATH}`;
const LOCATION_WHERE_LOCATED_PATH = 'locationWhereLocated';
const LOCATION_WHERE_LOCATED_DESCRIPTION_PATH = 'description';
const LOCATION_WHERE_LOCATED_POSITION_ATTR_ID_PATH = 'positionAttrId';
export const LOCATION_WHERE_LOCATED_DESCRIPTION_FULL_PATH = `${LOCATION_WHERE_LOCATED_PATH}.${LOCATION_WHERE_LOCATED_DESCRIPTION_PATH}`;
export const LOCATION_WHERE_LOCATED_POSITION_ATTR_ID_FULL_PATH = `${LOCATION_WHERE_LOCATED_PATH}.${LOCATION_WHERE_LOCATED_POSITION_ATTR_ID_PATH}`;
export const LOCATION_WHERE_LOCATED_LOCATION_ID_FULL_PATH = `${LOCATION_WHERE_LOCATED_PATH}.${LOCATION_ID_PATH}`;
export const getMissingPersonsForm = () => formsRegistry.get(formName);

export const refreshMissingPersonsForm = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const missingPerson = missingPersonsByReportIdSelector(state)(reportId);

    const missingPersonAdditionalInfoAttributes = reportAttributesWhereSelector(state)({
        reportId,
        attributeType: AttributeTypeEnum.MISSING_PERSON_ADDITIONAL_INFO.name,
    });
    const missingPersonAdditionalInfoAttributeIds = map(
        missingPersonAdditionalInfoAttributes,
        'attributeId'
    );
    const missingPersonAdditionalInfoDescription = getDescriptionForAttributeLinks(
        missingPersonAdditionalInfoAttributes
    );

    const missingPersonNameId = get(
        head(
            nameReportLinksWhereSelector(state)({
                reportId,
                linkType: LinkTypesEnum.MISSING_PERSON_IN_REPORT,
            })
        ),
        'nameId'
    );

    const lastKnownLocation = head(
        locationEntityLinksWhereSelector(state)({
            linkType: LinkTypesEnum.LAST_KNOWN_LOCATION,
            entityType: EntityTypeEnum.REPORT.name,
            entityId: reportId,
        })
    );

    const locationWhereLocated = head(
        locationEntityLinksWhereSelector(state)({
            linkType: LinkTypesEnum.LOCATION_WHERE_LOCATED,
            entityType: EntityTypeEnum.REPORT.name,
            entityId: reportId,
        })
    );

    const witnesses = map(
        nameReportLinksWhereSelector(state)({
            reportId,
            linkType: LinkTypesEnum.WITNESS_IN_OFFENSE,
            contextType: EntityTypeEnum.REPORT.name,
            contextId: reportId,
        }),
        'nameId'
    );

    const lastKnownContacts = map(
        nameReportLinksWhereSelector(state)({
            reportId,
            linkType: LinkTypesEnum.LAST_KNOWN_CONTACT_IN_REPORT,
        }),
        'nameId'
    );
    return {
        ...missingPerson,
        missingPerson: missingPersonNameId,
        missingPersonAdditionalInfo: missingPersonAdditionalInfoAttributeIds,
        missingPersonAdditionalInfoDescription,
        lastKnownLocation,
        locationWhereLocated,
        witnesses,
        lastKnownContacts,
    };
};

export const createMissingPersonsForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            id: {},
            reportId: {
                fieldName: fields.MISSING_PERSON_REPORT_ID,
            },
            lastContactDateUtc: {
                fieldName: fields.MISSING_PERSON_LAST_CONTACT_DATE_UTC,
            },
            missingPersonCriticalityAttrId: {
                fieldName: fields.MISSING_PERSON_MISSING_PERSON_CRITICALITY_ATTR_ID,
            },
            missingPersonCriticalityOther: {
                fieldName: fields.MISSING_PERSON_MISSING_PERSON_CRITICALITY_OTHER,
            },
            missingPersonTypeAttrId: {
                fieldName: fields.MISSING_PERSON_MISSING_PERSON_TYPE_ATTR_ID,
            },
            missingPersonTypeOther: {
                fieldName: fields.MISSING_PERSON_MISSING_PERSON_TYPE_OTHER,
            },
            closureStatusAttrId: {
                fieldName: fields.MISSING_PERSON_CLOSURE_STATUS_ATTR_ID,
            },
            closureStatusDateUtc: {
                fieldName: fields.MISSING_PERSON_CLOSURE_STATUS_DATE_UTC,
            },
            missingPersonAdditionalInfo: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_MISSING_PERSON_ADDITIONAL_INFO_ATTRIBUTE_ID,
            },
            missingPersonAdditionalInfoDescription: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_MISSING_PERSON_ADDITIONAL_INFO_DESCRIPTION,
            },
            missingPerson: {
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_MISSING_PERSON_IN_REPORT_LINK_TYPE,
            },
            lastKnownLocation: createFieldset({
                fields: {
                    locationId: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LAST_KNOWN_LOCATION_LOCATION_ID,
                    },
                    [LAST_KNOWN_LOCATION_POSITION_ATTR_ID_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LAST_KNOWN_LOCATION_POSITION_ATTR_ID,
                    },
                    description: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LAST_KNOWN_LOCATION_DESCRIPTION,
                    },
                },
            }),
            locationWhereLocated: createFieldset({
                fields: {
                    locationId: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_WHERE_LOCATED_LOCATION_ID,
                    },
                    [LOCATION_WHERE_LOCATED_POSITION_ATTR_ID_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_WHERE_LOCATED_POSITION_ATTR_ID,
                    },
                    description: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_WHERE_LOCATED_DESCRIPTION,
                    },
                },
            }),
            witnesses: {
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_LINK_TYPE,
            },
            lastKnownContacts: {
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_LAST_KNOWN_CONTACT_IN_REPORT_LINK_TYPE,
            },
            // Needed for validation
            eventStartUtc: {
                fieldName: fields.EVENT_DETAIL_EVENT_START_UTC,
            },
        }),
    });
};

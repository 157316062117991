import { includes, find, get, some, compact, map } from 'lodash';
import {
    PersonProfile,
    RefContextEnum,
    LinkTypesEnum,
    CodeTypeCategoryEnum,
    CodeTypeCategoryEnumType,
} from '@mark43/rms-api';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import { sexOfOffenderCodes, raceOfOffenderCodes } from '~/client-common/core/constants/nibrsCodes';
import { useFormGetter } from '../forms/hooks/useFormGetter';
import { relationshipsDataSelector } from '../../reports/core/state/ui/relationships';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { nibrsCodeForAttributeIdAndCodeTypeCategorySelector } from '../../reports/ucr-classification/state/ui/selectors';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

export const getOffenderIsUnknownWithNotUnknownRelationship = (getState: () => RootState) => () => {
    const state = getState();

    if (!checkIfDepartmentIsNibrs(currentUserDepartmentProfileSelector(state))) {
        return false;
    }

    const relationshipData = relationshipsDataSelector(state);
    const nibrsCodeForAttributeIdAndCodeTypeCategory = nibrsCodeForAttributeIdAndCodeTypeCategorySelector(
        state as never
    );

    // Collect suspect ids
    const suspectIds: number[] = compact(
        map(get(relationshipData, 'nameReportLinks'), (nrl) => {
            if (nrl.linkType === LinkTypesEnum.SUSPECT_IN_OFFENSE) {
                return nrl.nameId;
            }
        })
    );

    // Collect victim ids
    const victimIds: number[] = compact(
        map(get(relationshipData, 'nameReportLinks'), (nrl) => {
            if (nrl.linkType === LinkTypesEnum.VICTIM_IN_OFFENSE) {
                return nrl.nameId;
            }
        })
    );

    // Collect current state of Form
    const { getForm } = useFormGetter();

    const form = getForm(RefContextEnum.FORM_RELATIONSHIPS.name);

    if (typeof form === 'undefined') {
        return false;
    }

    const relationships = form.getState().model.relationships;

    if (typeof relationships === 'undefined' || !(relationships instanceof Array)) {
        return false;
    }

    for (const relationship of relationships) {
        // Check that this is a person to person relationship
        if (
            relationship.nameEntityType !== 'PERSON_PROFILE' ||
            relationship.otherNameEntityType !== 'PERSON_PROFILE'
        ) {
            continue;
        }

        // Check that this is a victim to suspect relationship
        if (
            typeof relationship.nameId !== 'number' ||
            typeof relationship.otherNameId !== 'number'
        ) {
            continue;
        }

        if (
            !(
                (includes(victimIds, relationship.nameId) &&
                    includes(suspectIds, relationship.otherNameId)) ||
                (includes(suspectIds, relationship.nameId) &&
                    includes(victimIds, relationship.otherNameId))
            )
        ) {
            continue;
        }

        const personProfile1 = find(relationshipData.personProfiles, { id: relationship.nameId });
        const personProfile2 = find(relationshipData.personProfiles, {
            id: relationship.otherNameId,
        });

        if (
            // type this after relationshipsForm is typed
            isUnknownSuspectWithNotUnknownRelationship(
                personProfile1,
                suspectIds,
                // @ts-expect-error TODO: Type relationshipsForm
                relationship,
                nibrsCodeForAttributeIdAndCodeTypeCategory
            ) ||
            isUnknownSuspectWithNotUnknownRelationship(
                personProfile2,
                suspectIds,
                // @ts-expect-error TODO: Type relationshipsForm
                relationship,
                nibrsCodeForAttributeIdAndCodeTypeCategory
            )
        ) {
            return true;
        }
    }
    return false;
};

const areAgeSexAndRaceUnknown = (
    personProfile: PersonProfile,
    nibrsCodeForAttributeIdAndCodeTypeCategory: (
        attributeId: number,
        codeTypeCategory: CodeTypeCategoryEnumType
    ) => string | undefined
) => {
    // these tests should be viewed as an affirmative test
    // that is to say we know an attribute is unknown
    // either no data entered, or a value that maps to NIBRS "U" (Unknown)

    // unknown sex means having no sexAttrId or sexAttrId maps to 'U'
    const nibrsCodeForSexAttrId =
        personProfile.sexAttrId &&
        nibrsCodeForAttributeIdAndCodeTypeCategory(
            personProfile.sexAttrId,
            CodeTypeCategoryEnum.NIBRS_SEX.name
        );
    const sexIsUnknown = !personProfile.sexAttrId
        ? true
        : nibrsCodeForSexAttrId === sexOfOffenderCodes.unknown;

    // unknown age means having no birthday and no age range
    // true condition 1: unknown box not checked, no date of birth
    // true condition 2: unknown box checked, no values in ageMin or ageMax
    const ageIsUnknown = personProfile.isDobUnknown
        ? !personProfile.ageMin || !personProfile.ageMax
        : !personProfile.dateOfBirth;

    // unknown race means having no raceAttrId or raceAttrId maps to 'U'
    const nibrsCodeForRaceAttrId =
        personProfile.raceAttrId &&
        nibrsCodeForAttributeIdAndCodeTypeCategory(
            personProfile.raceAttrId,
            CodeTypeCategoryEnum.NIBRS_RACE.name
        );
    const raceIsUnknown = !personProfile.raceAttrId
        ? true
        : nibrsCodeForRaceAttrId === raceOfOffenderCodes.unknown;

    return sexIsUnknown && ageIsUnknown && raceIsUnknown;
};

const isUnknownSuspectWithNotUnknownRelationship = (
    personProfile: PersonProfile,
    suspectIds: number[],
    relationship: { linkOptionIds: number[] },
    nibrsCodeForAttributeIdAndCodeTypeCategory: (
        attributeId: number,
        codeTypeCategory: CodeTypeCategoryEnumType
    ) => string | undefined
) => {
    if (
        includes(suspectIds, personProfile?.id) &&
        areAgeSexAndRaceUnknown(personProfile, nibrsCodeForAttributeIdAndCodeTypeCategory)
    ) {
        const linkOptionIds = get(relationship, 'linkOptionIds');
        return some(linkOptionIds, (linkOptionsId) => {
            return linkOptionsId !== LinkTypesEnum.RELATIONSHIP_UNKNOWN;
        });
    }
    return false;
};

import { createSelector } from 'reselect';
import { cadUnitsSelector, storeCadUnits } from '~/client-common/core/domain/cad-units/state/data';
import { beatSubdivisionSelector } from '~/client-common/core/domain/subdivisions/state/data';
import cadUnitResource from '../../resources/cadUnitResource';
import { departmentProfileSelector } from '../../../../../legacy-redux/selectors/departmentProfileSelectors';
import { createExportActionCreator } from '../../../core/utils/importExportHelpers';

export const LOAD_CAD_UNITS_START = 'cad-units/LOAD_CAD_UNITS_START';
export const LOAD_CAD_UNITS_SUCCESS = 'cad-units/LOAD_CAD_UNITS_SUCCESS';
export const LOAD_CAD_UNITS_FAILURE = 'cad-units/LOAD_CAD_UNITS_FAILURE';
export const SAVE_CAD_UNIT_START = 'cad-units/SAVE_CAD_UNIT_START';
export const SAVE_CAD_UNIT_SUCCESS = 'cad-units/SAVE_CAD_UNIT_SUCCESS';
export const SAVE_CAD_UNIT_FAILURE = 'cad-units/SAVE_CAD_UNIT_FAILURE';
const FETCH_CAD_UNIT_HISTORY_START = 'cad-units/FETCH_CAD_UNIT_HISTORY_START';
export const FETCH_CAD_UNIT_HISTORY_SUCCESS = 'cad-units/FETCH_CAD_UNIT_HISTORY_SUCCESS';
const FETCH_CAD_UNIT_HISTORY_FAILURE = 'cad-units/FETCH_CAD_UNIT_HISTORY_FAILURE';
const FETCH_ALL_CAD_UNITS_HISTORY_START = 'cad-units/FETCH_ALL_CAD_UNITS_HISTORY_START';
export const FETCH_ALL_CAD_UNITS_HISTORY_SUCCESS = 'cad-units/FETCH_ALL_CAD_UNITS_HISTORY_SUCCESS';
const FETCH_ALL_CAD_UNITS_HISTORY_FAILURE = 'cad-units/FETCH_ALL_CAD_UNITS_HISTORY_FAILURE';

function loadCadUnitsStart() {
    return {
        type: LOAD_CAD_UNITS_START,
    };
}

function loadCadUnitsSuccess() {
    return {
        type: LOAD_CAD_UNITS_SUCCESS,
    };
}

function loadCadUnitsFailure(errMsg) {
    return {
        type: LOAD_CAD_UNITS_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function fetchCadUnitHistoryStart(unitId) {
    return {
        type: FETCH_CAD_UNIT_HISTORY_START,
        payload: unitId,
    };
}

function fetchCadUnitHistorySuccess(unitId, histories) {
    return {
        type: FETCH_CAD_UNIT_HISTORY_SUCCESS,
        payload: { unitId, histories },
    };
}

function fetchCadUnitHistoryFailure(unitId, err) {
    return {
        type: FETCH_CAD_UNIT_HISTORY_FAILURE,
        payload: { unitId, err },
    };
}

function fetchAllCadUnitsHistoryStart() {
    return {
        type: FETCH_ALL_CAD_UNITS_HISTORY_START,
    };
}

function fetchAllCadUnitsHistorySuccess(histories) {
    return {
        type: FETCH_ALL_CAD_UNITS_HISTORY_SUCCESS,
        payload: { histories },
    };
}

function fetchAllCadUnitsHistoryFailure(err) {
    return {
        type: FETCH_ALL_CAD_UNITS_HISTORY_FAILURE,
        payload: { err },
    };
}

export function loadCadUnits() {
    return function (dispatch) {
        dispatch(loadCadUnitsStart());

        return cadUnitResource
            .getCadUnits()
            .then((units) => {
                dispatch(storeCadUnits(units));
                dispatch(loadCadUnitsSuccess());
            })
            .catch((err) => dispatch(loadCadUnitsFailure(err.message)));
    };
}

export function loadCadUnitHistoryData(unitId) {
    return function (dispatch) {
        dispatch(fetchCadUnitHistoryStart(unitId));
        return cadUnitResource
            .getCadUnitHistory(unitId)
            .then((histories) => {
                dispatch(fetchCadUnitHistorySuccess(unitId, histories));
            })
            .catch((err) => {
                dispatch(fetchCadUnitHistoryFailure(unitId, err));
            });
    };
}

export function loadAllCadUnitsHistoryData() {
    return function (dispatch) {
        dispatch(fetchAllCadUnitsHistoryStart());
        return cadUnitResource
            .getAllCadUnitsHistory()
            .then((histories) => {
                dispatch(fetchAllCadUnitsHistorySuccess(histories));
            })
            .catch((err) => {
                dispatch(fetchAllCadUnitsHistoryFailure(err));
            });
    };
}

function saveCadUnitStart() {
    return {
        type: SAVE_CAD_UNIT_START,
    };
}

function saveCadUnitSuccess(cadUnit) {
    return {
        type: SAVE_CAD_UNIT_SUCCESS,
        payload: cadUnit,
    };
}

function saveCadUnitFailure(errMsg) {
    return {
        type: SAVE_CAD_UNIT_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveCadUnit({ cadUnit }) {
    return function (dispatch, getState) {
        dispatch(saveCadUnitStart());

        const isNewUnit = !cadUnit.id;
        const method = isNewUnit ? 'createCadUnit' : 'updateCadUnit';

        const { departmentId } = departmentProfileSelector(getState());

        return cadUnitResource[method]({ ...cadUnit, departmentId })
            .then((unit) => {
                dispatch(storeCadUnits([unit]));
                return dispatch(saveCadUnitSuccess(unit));
            })
            .catch((err) => dispatch(saveCadUnitFailure(err.message)));
    };
}

/**
 * Export all attributes into a CSV file.
 * @return {Promise}
 */
export const exportUnits = createExportActionCreator(cadUnitResource.exportUnits);
/**
 * Export all Unit Type Details into a CSV file.
 * @return {Promise}
 */
export const exportUnitTypeDetails = createExportActionCreator(
    cadUnitResource.exportUnitTypeDetails
);

export const beatAttributeTypeSelector = createSelector(
    beatSubdivisionSelector,
    (beatSubdivision) => {
        if (!beatSubdivision) {
            return null;
        }
        return beatSubdivision.depthAttributeType;
    }
);

export const existingCallSignsSelector = createSelector(cadUnitsSelector, (cadUnits) => {
    return Object.values(cadUnits || {}).reduce((set, unit) => {
        set.add(unit.callSign);

        return set;
    }, new Set());
});

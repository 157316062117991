import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { concat, filter, findIndex, get, isEmpty, map, omit } from 'lodash';

import { createFormConfiguration, createNItems, _Form } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import entityTypeObjectEnum from '~/client-common/core/enums/universal/entityTypeObjectEnum';

import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { hydratedFieldContactByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/fieldContacts';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import buildLocationEntityLinkKey from '~/client-common/core/domain/location-entity-links/utils/buildLocationEntityLinkKey';
import sortLocationEntityLinks from '~/client-common/core/domain/location-entity-links/utils/sortLocationEntityLinks';
import sortNameReportLinks from '~/client-common/core/domain/name-report-links/utils/sortNameReportLinks';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const formName = RefContextEnum.FORM_FIELD_CONTACT.name;
export const FIELD_CONTACT_SUBJECTS_PATH = 'fieldContactSubjects';
export const FIELD_CONTACT_SUBJECT_ENTITY_TYPE_ID_PATH = 'entityTypeId';
export const FIELD_CONTACT_SUBJECT_SUBJECT_TYPE_ATTR_ID_PATH = 'subjectTypeAttrId';
export const FIELD_CONTACT_SUBJECT_SUBJECT_TYPE_OTHER_PATH = 'subjectTypeOther';
export const FIELD_CONTACT_SUBJECT_WAS_FRISKED_PATH = 'wasFrisked';
export const FIELD_CONTACT_ORGANIZATIONS_PATH = 'fieldContactOrganizations';
export const FIELD_CONTACT_OTHER_NAMES_IN_FIELD_CONTACT_PATH = 'otherNamesInFieldContact';
export const FIELD_CONTACT_OTHER_NAME_SUBJECT_TYPE_ATTR_ID_PATH = 'subjectTypeAttrId';
export const FIELD_CONTACT_OTHER_NAME_SUBJECT_TYPE_OTHER_PATH = 'subjectTypeOther';
export const FIELD_CONTACT_LOCATIONS_PATH = 'fieldContactLocations';
export const FIELD_CONTACT_LOCATION_DESCRIPTION_PATH = 'description';
export const FIELD_CONTACT_LOCATION_POSITION_ATTR_ID_PATH = 'positionAttrId';
export const FIELD_CONTACT_SUBJECT_ADD_ADDITIONAL_SUBJECTS_BUTTON_PATH =
    'fieldContactSubjectAddAdditionalSubjectsButton';
export const getFieldContactForm = () => formsRegistry.get(formName);

export const createFieldContactForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            id: {},
            contactTypeAttrId: {
                fieldName: fields.FIELD_CONTACT_CONTACT_TYPE_ATTR_ID,
            },
            contactTypeOther: {
                fieldName: fields.FIELD_CONTACT_CONTACT_TYPE_OTHER,
            },
            reasonForStopAttrId: {
                fieldName: fields.FIELD_CONTACT_REASON_FOR_STOP_ATTR_ID,
            },
            reasonForStopOther: {
                fieldName: fields.FIELD_CONTACT_REASON_FOR_STOP_OTHER,
            },
            movingViolationNumber: {
                fieldName: fields.FIELD_CONTACT_MOVING_VIOLATION_NUMBER,
            },
            seizureStartDateUtc: {
                fieldName: fields.FIELD_CONTACT_SEIZURE_START_DATE_UTC,
            },
            seizureEndDateUtc: {
                fieldName: fields.FIELD_CONTACT_SEIZURE_END_DATE_UTC,
            },
            wasStopScreenedBySupervisor: {
                fieldName: fields.FIELD_CONTACT_WAS_STOP_SCREENED_BY_SUPERVISOR,
            },
            reasonableSuspicionNarrative: {
                fieldName: fields.FIELD_CONTACT_REASONABLE_SUSPICION_NARRATIVE,
            },
            policeExperienceNarrative: {
                fieldName: fields.FIELD_CONTACT_POLICE_EXPERIENCE_NARRATIVE,
            },
            contactDetailsNarrative: {
                fieldName: fields.FIELD_CONTACT_CONTACT_DETAILS_NARRATIVE,
            },
            wasSubjectSearched: {
                fieldName: fields.FIELD_CONTACT_WAS_SUBJECT_SEARCHED,
            },
            armedAndDangerousNarrative: {
                fieldName: fields.FIELD_CONTACT_ARMED_AND_DANGEROUS_NARRATIVE,
            },
            didOfficerFindWeapon: {
                fieldName: fields.FIELD_CONTACT_DID_OFFICER_FIND_WEAPON,
            },
            fieldContactDispositionAttrIds: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_DISPOSITION_ATTRIBUTE_ID,
            },
            fieldContactDispositionDescription: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_DISPOSITION_DESCRIPTION,
            },
            fieldContactReasonForStopAttrIds: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_REASON_FOR_STOP_ATTRIBUTE_ID,
            },
            fieldContactReasonForStopDescription: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_REASON_FOR_STOP_DESCRIPTION,
            },
            weaponInvolvedAttrIds: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_INVOLVED_ATTRIBUTE_ID,
            },
            weaponInvolvedDescription: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_INVOLVED_DESCRIPTION,
            },
            [FIELD_CONTACT_SUBJECTS_PATH]: createNItems({
                // This is the field that determines hide/show state of this entire NItems
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_LINK_TYPE,
                fields: {
                    nameId: {},
                    // fields used for validation
                    id: {
                        fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_ID,
                    },
                    linkType: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_LINK_TYPE,
                    },
                    // custom field used for hide/show logic, namely, that we do not display `wasFrisked`
                    // for Organizations, but we do display the input for Persons.
                    [FIELD_CONTACT_SUBJECT_ENTITY_TYPE_ID_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_ENTITY_TYPE,
                    },
                    // fields in UI
                    [FIELD_CONTACT_SUBJECT_SUBJECT_TYPE_ATTR_ID_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_SUBJECT_TYPE_ATTR_ID,
                    },
                    [FIELD_CONTACT_SUBJECT_SUBJECT_TYPE_OTHER_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_SUBJECT_TYPE_OTHER,
                    },
                    [FIELD_CONTACT_SUBJECT_WAS_FRISKED_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_WAS_FRISKED,
                    },
                },
            }),
            [FIELD_CONTACT_ORGANIZATIONS_PATH]: createNItems({
                // This is the field that determines hide/show state of this entire NItems
                fieldName:
                    fields.NAME_REPORT_LINK_LINK_TYPE_ORGANIZATION_IN_FIELD_CONTACT_LINK_TYPE,
                fields: {
                    nameId: {},
                    linkType: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_ORGANIZATION_IN_FIELD_CONTACT_LINK_TYPE,
                    },
                    // Organizations do not allow for `Subject Type`s.
                },
            }),
            [FIELD_CONTACT_OTHER_NAMES_IN_FIELD_CONTACT_PATH]: createNItems({
                // This is the field that determines hide/show state of this entire NItems
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_FIELD_CONTACT_LINK_TYPE,
                fields: {
                    nameId: {},
                    linkType: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_FIELD_CONTACT_LINK_TYPE,
                    },
                    [FIELD_CONTACT_OTHER_NAME_SUBJECT_TYPE_ATTR_ID_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_FIELD_CONTACT_SUBJECT_TYPE_ATTR_ID,
                    },
                    [FIELD_CONTACT_OTHER_NAME_SUBJECT_TYPE_OTHER_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_FIELD_CONTACT_SUBJECT_TYPE_OTHER,
                    },
                },
            }),
            [FIELD_CONTACT_LOCATIONS_PATH]: createNItems({
                // This is the field that determines hide/show state of this entire NItems
                fieldName:
                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_FIELD_CONTACT_LOCATION_ID,
                fields: {
                    locationId: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_FIELD_CONTACT_LOCATION_ID,
                    },
                    [FIELD_CONTACT_LOCATION_POSITION_ATTR_ID_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_FIELD_CONTACT_POSITION_ATTR_ID,
                    },
                    [FIELD_CONTACT_LOCATION_DESCRIPTION_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_FIELD_CONTACT_DESCRIPTION,
                    },
                },
            }),
            /*
             * `FIELD_CONTACT_SUBJECT_ADD_ADDITIONAL_SUBJECTS_BUTTON_PATH` is purely used for UI rules.  We will
             * conditionally allow or disable multiple `Subject`s on the Field Contact card.
             */
            [FIELD_CONTACT_SUBJECT_ADD_ADDITIONAL_SUBJECTS_BUTTON_PATH]: {
                fieldName: fields.DISPLAY_ONLY_FIELD_CONTACT_SUBJECT_WRAPPER,
            },
        }),
    });
};

const buildReportAttributeFormModelObject = ({ reportAttributes, attributeType }) => {
    const filteredReportAttributes = filter(reportAttributes, { attributeType });
    const attributeIds = map(filteredReportAttributes, 'attributeId');
    const description = getDescriptionForAttributeLinks(filteredReportAttributes);
    return {
        attributeIds,
        description,
    };
};

const buildDefaultFieldContactSubjectFormModel = () => {
    return {
        nameId: undefined,
        id: undefined,
        linkType: undefined,
    };
};

const filterDefaultFieldContactSubjects = ({ fieldContactSubjects }) => {
    return filter(fieldContactSubjects, (fieldContactSubject) => {
        const { nameId, id, linkType } = fieldContactSubject;
        const isDefaultFieldContactSubject =
            isUndefinedOrNull(nameId) && isUndefinedOrNull(id) && isUndefinedOrNull(linkType);
        return !isDefaultFieldContactSubject;
    });
};

export const removeDefaultFieldContactSubjectsFromFormModel = () => {
    const form = getFieldContactForm();
    const fieldContactSubjects = form.get(FIELD_CONTACT_SUBJECTS_PATH);
    const fieldContactSubjectsToKeep = filterDefaultFieldContactSubjects({ fieldContactSubjects });
    const sortedFieldContactSubjectsToKeep = sortNameReportLinks({
        nameReportLinks: fieldContactSubjectsToKeep,
    });
    form.set(FIELD_CONTACT_SUBJECTS_PATH, sortedFieldContactSubjectsToKeep);
};

export const maybeAddDefaultFieldContactSubjectToFormModel = () => {
    const form = getFieldContactForm();
    const existingFieldContactSubjects = form.get(FIELD_CONTACT_SUBJECTS_PATH);
    if (isEmpty(existingFieldContactSubjects)) {
        form.set(FIELD_CONTACT_SUBJECTS_PATH, [buildDefaultFieldContactSubjectFormModel()]);
    }
};

const buildDefaultFieldContactLocationFormModel = () => {
    return { locationId: undefined };
};

const filterDefaultFieldContactLocations = ({ fieldContactLocations }) => {
    return filter(fieldContactLocations, (fieldContactLocation) => {
        const { locationId } = fieldContactLocation;
        const isDefaultFieldContactLocation = isUndefinedOrNull(locationId);
        return !isDefaultFieldContactLocation;
    });
};

export const removeDefaultFieldContactLocationsFromFormModel = () => {
    const form = getFieldContactForm();
    const fieldContactLocations = form.get(FIELD_CONTACT_LOCATIONS_PATH);
    const fieldContactLocationsToKeep = filterDefaultFieldContactLocations({
        fieldContactLocations,
    });
    const sortedFieldContactLocationsToKeep = sortLocationEntityLinks({
        locationEntityLinks: fieldContactLocationsToKeep,
    });
    form.set(FIELD_CONTACT_LOCATIONS_PATH, sortedFieldContactLocationsToKeep);
};

export const maybeAddDefaultFieldContactLocationToFormModel = () => {
    const form = getFieldContactForm();
    const existingFieldContactLocations = form.get(FIELD_CONTACT_LOCATIONS_PATH);
    if (isEmpty(existingFieldContactLocations)) {
        form.set(FIELD_CONTACT_LOCATIONS_PATH, [buildDefaultFieldContactLocationFormModel()]);
    }
};

export const buildFieldContactCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const hydratedFieldContact = hydratedFieldContactByReportIdSelector(state)(reportId);
    const {
        fieldContact: {
            id: fieldContactId,
            contactTypeAttrId,
            contactTypeOther,
            reasonForStopAttrId,
            reasonForStopOther,
            movingViolationNumber,
            seizureStartDateUtc,
            seizureEndDateUtc,
            wasStopScreenedBySupervisor,
            reasonableSuspicionNarrative,
            policeExperienceNarrative,
            contactDetailsNarrative,
            wasSubjectSearched,
            armedAndDangerousNarrative,
            didOfficerFindWeapon,
        },
        reportAttributes,
        fieldContactSubjects,
        fieldContactOrganizations,
        otherNamesInFieldContact,
        fieldContactLocations,
    } = hydratedFieldContact;

    const {
        attributeIds: fieldContactDispositionAttrIds,
        description: fieldContactDispositionDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name,
    });
    const {
        attributeIds: fieldContactReasonForStopAttrIds,
        description: fieldContactReasonForStopDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.FIELD_CONTACT_REASON_FOR_STOP.name,
    });
    const {
        attributeIds: weaponInvolvedAttrIds,
        description: weaponInvolvedDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.WEAPON_INVOLVED.name,
    });

    const initialFieldContactSubjects = isEmpty(fieldContactSubjects)
        ? [buildDefaultFieldContactSubjectFormModel()]
        : fieldContactSubjects;
    const fieldContactSubjectsWithEntityTypeIds = map(
        initialFieldContactSubjects,
        (fieldContactSubject) => {
            const { entityType } = fieldContactSubject;
            const entityTypeId = get(entityTypeObjectEnum, `${entityType}.value`);
            return {
                ...fieldContactSubject,
                [FIELD_CONTACT_SUBJECT_ENTITY_TYPE_ID_PATH]: entityTypeId,
            };
        }
    );

    const initialFieldContactLocations = isEmpty(fieldContactLocations)
        ? [buildDefaultFieldContactLocationFormModel()]
        : fieldContactLocations;

    return {
        id: fieldContactId,
        contactTypeAttrId,
        contactTypeOther,
        reasonForStopAttrId,
        reasonForStopOther,
        movingViolationNumber,
        seizureStartDateUtc,
        seizureEndDateUtc,
        wasStopScreenedBySupervisor,
        reasonableSuspicionNarrative,
        policeExperienceNarrative,
        contactDetailsNarrative,
        wasSubjectSearched,
        armedAndDangerousNarrative,
        didOfficerFindWeapon,
        fieldContactDispositionAttrIds,
        fieldContactDispositionDescription,
        fieldContactReasonForStopAttrIds,
        fieldContactReasonForStopDescription,
        weaponInvolvedAttrIds,
        weaponInvolvedDescription,
        [FIELD_CONTACT_SUBJECTS_PATH]: fieldContactSubjectsWithEntityTypeIds,
        [FIELD_CONTACT_ORGANIZATIONS_PATH]: fieldContactOrganizations,
        [FIELD_CONTACT_OTHER_NAMES_IN_FIELD_CONTACT_PATH]: otherNamesInFieldContact,
        [FIELD_CONTACT_LOCATIONS_PATH]: initialFieldContactLocations,
    };
};

const convertReportAttributeFormModelToDataModel = ({
    attributes,
    reportId,
    attributeTypeName,
    attributeIds,
    description,
}) => {
    return assignDescriptionToReportAttributes(
        map(attributeIds, (attributeId) => {
            return {
                reportId,
                attributeType: attributeTypeName,
                attributeId,
            };
        }),
        attributes,
        description
    );
};

export const buildFieldContactCardBundle = ({ reportId, form }) => (dispatch, getState) => {
    const state = getState();
    const attributes = attributesSelector(state);
    const { model } = form.getState();
    const {
        id: fieldContactId,
        contactTypeAttrId,
        contactTypeOther,
        reasonForStopAttrId,
        reasonForStopOther,
        movingViolationNumber,
        seizureStartDateUtc,
        seizureEndDateUtc,
        wasStopScreenedBySupervisor,
        reasonableSuspicionNarrative,
        policeExperienceNarrative,
        contactDetailsNarrative,
        wasSubjectSearched,
        armedAndDangerousNarrative,
        didOfficerFindWeapon,
        fieldContactDispositionAttrIds,
        fieldContactDispositionDescription,
        fieldContactReasonForStopAttrIds,
        fieldContactReasonForStopDescription,
        weaponInvolvedAttrIds,
        weaponInvolvedDescription,
        [FIELD_CONTACT_SUBJECTS_PATH]: fieldContactSubjects,
        [FIELD_CONTACT_OTHER_NAMES_IN_FIELD_CONTACT_PATH]: otherNamesInFieldContact,
        [FIELD_CONTACT_LOCATIONS_PATH]: fieldContactLocations,
    } = model;

    const fieldContactForBundle = {
        id: fieldContactId,
        reportId,
        contactTypeAttrId,
        contactTypeOther,
        reasonForStopAttrId,
        reasonForStopOther,
        movingViolationNumber,
        seizureStartDateUtc,
        seizureEndDateUtc,
        wasStopScreenedBySupervisor,
        reasonableSuspicionNarrative,
        policeExperienceNarrative,
        contactDetailsNarrative,
        wasSubjectSearched,
        armedAndDangerousNarrative,
        didOfficerFindWeapon,
    };

    const fieldContactDispositionReportAttributes = convertReportAttributeFormModelToDataModel({
        attributes,
        reportId,
        attributeTypeName: AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name,
        attributeIds: fieldContactDispositionAttrIds,
        description: fieldContactDispositionDescription,
    });
    const fieldContactReasonForStopReportAttributes = convertReportAttributeFormModelToDataModel({
        attributes,
        reportId,
        attributeTypeName: AttributeTypeEnum.FIELD_CONTACT_REASON_FOR_STOP.name,
        attributeIds: fieldContactReasonForStopAttrIds,
        description: fieldContactReasonForStopDescription,
    });
    const weaponInvolvedReportAttributes = convertReportAttributeFormModelToDataModel({
        attributes,
        reportId,
        attributeTypeName: AttributeTypeEnum.WEAPON_INVOLVED.name,
        attributeIds: weaponInvolvedAttrIds,
        description: weaponInvolvedDescription,
    });
    const reportAttributesForBundle = concat(
        [],
        fieldContactDispositionReportAttributes,
        fieldContactReasonForStopReportAttributes,
        weaponInvolvedReportAttributes
    );

    const fieldContactSubjectsWithoutDefaultSubjects = filterDefaultFieldContactSubjects({
        fieldContactSubjects,
    });
    const fieldContactSubjectsWithoutEntityTypeIds = map(
        fieldContactSubjectsWithoutDefaultSubjects,
        (fieldContactSubjectsWithoutDefaultSubject) =>
            omit(
                fieldContactSubjectsWithoutDefaultSubject,
                FIELD_CONTACT_SUBJECT_ENTITY_TYPE_ID_PATH
            )
    );
    const nameReportLinksForBundle = concat(
        [],
        fieldContactSubjectsWithoutEntityTypeIds,
        otherNamesInFieldContact
    );

    const fieldContactLocationsWithoutDefaultLocations = filterDefaultFieldContactLocations({
        fieldContactLocations,
    });

    return {
        fieldContact: fieldContactForBundle,
        reportAttributes: reportAttributesForBundle,
        nameReportLinks: nameReportLinksForBundle,
        locationEntityLinks: fieldContactLocationsWithoutDefaultLocations,
    };
};

export const refreshFieldContactForm = ({ reportId }) => (dispatch) => {
    const form = getFieldContactForm();
    const formModel = dispatch(buildFieldContactCardFormModel({ reportId }));
    form.set('', formModel);
};

export const findIndexOfNItemsNameLink = ({ path, nameId }) => {
    const form = getFieldContactForm();
    const {
        model: { [path]: nameLinks },
    } = form.getState();
    return findIndex(nameLinks, { nameId });
};

export const findIndexOfNItemsLocationEntityLink = ({ path, locationEntityLinkKey }) => {
    const form = getFieldContactForm();
    const {
        model: { [path]: locationEntityLinks },
    } = form.getState();
    const formLocationEntityLinkKeys = map(locationEntityLinks, buildLocationEntityLinkKey);
    return findIndex(
        formLocationEntityLinkKeys,
        (formLocationEntityLinkKey) => formLocationEntityLinkKey === locationEntityLinkKey
    );
};

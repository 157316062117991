import React from 'react';
import { useSelector } from 'react-redux';
import { ProductModuleEnum, Charge, Warrant, WarrantStatus, Offense } from '@mark43/rms-api';
import { get, find } from 'lodash';
import styled from 'styled-components';
import { ViewModel, getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { joinTruthyValues, booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { courtOrdersByReportIdSelector } from '~/client-common/core/domain/court-orders/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { FormattedDate } from '~/client-common/core/dates/components';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import testIds from '../../../../../core/testIds';
import { PopoutLink } from '../../../../core/components/links/Link';
import Icon, { iconTypes } from '../../../../../legacy-redux/components/core/Icon';
import SealedStamp from '../../../../core/components/SealedStamp';
import SemiBoldSummaryTitle from '../../../../core/components/SemiBoldSummaryTitle';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import Tag from '../../../../record-privacy/core/components/Tag';
import { ChargeWordTemplates } from './madlibs/ChargeWordTemplates';

const ChargeTitleWrapper = styled.div`
    display: flex;
    margin-right: 20px;
`;

const ChargeTitle = styled(SemiBoldSummaryTitle)`
    display: inline-block;
    width: 415px;
`;

const ChargeIcon = styled(Icon)`
    &::before {
        width: 25px;
    }
`;

const ChargeSummaryList = styled(SummaryList)`
    padding-left: 25px;
`;

const ChargeSummaryWrapper = styled.div`
    padding-bottom: 16px;
`;

const RedVacatedTag = styled(Tag)`
    font-size: var(--arc-fontSizes-md);
`;

const SealedStampStyled = styled(SealedStamp)`
    margin-left: auto;
`;

const ChargeTemplateWrapper = styled.div`
    padding-right: 10px;
    padding-top: 10px;
`;

const strings = componentStrings.reports.core.ChargesCard;
const chargeTitleStrings = componentStrings.reports.core.ChargeTitle;

type ChargeViewModelPropsT = {
    chargeOffenseCode: string;
    chargeStatusAttrId: string;
    originalRen: string;
    legacyChargeSourceAttrId: string;
    duiCauseAttrId: string;
};
export type ChargeViewModelT = ViewModel<Charge, ChargeViewModelPropsT>;

type OffenseViewModelPropsT = {
    offenseCodeWithCode: string;
};
type OffenseViewModelT = ViewModel<Offense, OffenseViewModelPropsT>;

type WarrantViewModelPropsT = {
    canRead: boolean;
    obtainingOfficerUserDisplay: string;
    issuingAgencyNameDisplay: string;
    warrantTypeAttrId: string;
    isOtherJurisdiction: string;
};
type WarrantViewModelT = ViewModel<Warrant, WarrantViewModelPropsT>;

type WarrantStatusViewModelPropsT = {
    isStub: boolean;
};

type WarrantStatusViewModelT = ViewModel<WarrantStatus, WarrantStatusViewModelPropsT>;
type HydratedChargeViewModel = {
    chargeViewModel: ChargeViewModelT;
    offenseViewModel: OffenseViewModelT;
    warrantViewModel: WarrantViewModelT;
    warrantStatusViewModel: WarrantStatusViewModelT;
};

type PropsT = {
    summaryMode: boolean;
    hydratedChargeViewModel: HydratedChargeViewModel;
    chargesFormIndex: number;
};

export const ChargeSummaryView: React.FC<PropsT> = ({
    summaryMode,
    hydratedChargeViewModel,
    chargesFormIndex,
}) => {
    const reportById = useSelector(reportByIdSelector);
    const isProductModuleActive = useSelector(isProductModuleActiveSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const courtOrdersByReportId = useSelector(courtOrdersByReportIdSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const currentReportId = useSelector(currentReportIdSelector);

    const {
        chargeViewModel,
        offenseViewModel,
        warrantViewModel,
        warrantStatusViewModel,
    } = hydratedChargeViewModel;

    const {
        originalRen,
        chargeOffenseCode,
        legacyChargeSourceAttrId,
        chargeStatusAttrId,
        duiCauseAttrId,
    } = getViewModelProperties(chargeViewModel);
    const {
        arrestId,
        id: chargeId,
        isSealed,
        isVacated,
        legacyEventNumber,
        legacyCharge,
        chargeCount,
        wasCitationIssued,
        citationNumber,
    } = chargeViewModel;

    const { offenseCodeWithCode } = getViewModelProperties(offenseViewModel);
    const { reportId, offenseDateUtc, offenseEndDateUtc } = offenseViewModel;
    const {
        id: warrantId,
        warrantNumber,
        reportingEventNumber,
        description,
        warrantIssuedDateUtc,
        issuingJudge,
        bailAmount,
        issuingAgencyOri,
        issuingAgencyName,
    } = warrantViewModel;
    const {
        canRead: canReadWarrant,
        obtainingOfficerUserDisplay,
        issuingAgencyNameDisplay,
        warrantTypeAttrId,
        isOtherJurisdiction,
    } = getViewModelProperties(warrantViewModel);

    const { isStub } = getViewModelProperties(warrantStatusViewModel);

    const formattedRen = isUndefinedOrNull(originalRen) ? originalRen : `#${originalRen}`;
    const offenseTitle = joinTruthyValues([formattedRen, offenseCodeWithCode], ' ');
    const offenseReport = reportById(reportId);
    const offenseDisplayName = useOffenseFieldName();
    const canReadOffenseReport = canRead(get(offenseReport, 'permissionSet'));
    const hasWarrant = !isUndefinedOrNull(warrantId);
    const warrantsModuleActive = isProductModuleActive(ProductModuleEnum.WARRANTS.name);
    const canViewWarrants = currentUserHasAbility(abilitiesEnum.WARRANTS.VIEW_GENERAL);
    const showWarrantLink = warrantsModuleActive && canViewWarrants && canReadWarrant && !isStub;

    const vacatedCourtOrder = find(courtOrdersByReportId(String(currentReportId)), {
        isVacate: true,
    });
    const courtCodeAttrId = get(vacatedCourtOrder, 'courtCodeAttrId');
    const vacatedChargeTitleTag = joinTruthyValues(
        [` ${chargeTitleStrings.vacated}`, formatAttributeById(courtCodeAttrId)],
        ' - '
    );

    const chargeSummary = (
        <ChargeSummaryList labelWidth={150} contentWidth={360}>
            {offenseTitle && (
                <SummaryRow label={offenseDisplayName}>
                    {canReadOffenseReport ? (
                        <PopoutLink to={`/reports/${reportId}`}>{offenseTitle}</PopoutLink>
                    ) : (
                        <span>{offenseTitle}</span>
                    )}
                </SummaryRow>
            )}
            <SummaryRowDate fieldName={fields.OFFENSE_OFFENSE_DATE_UTC} date={offenseDateUtc} />
            <SummaryRowDate
                fieldName={fields.OFFENSE_OFFENSE_END_DATE_UTC}
                date={offenseEndDateUtc}
            />
            <SummaryRow fieldName={fields.CHARGE_LEGACY_EVENT_NUMBER}>
                {legacyEventNumber}
            </SummaryRow>
            <SummaryRow fieldName={fields.CHARGE_LEGACY_CHARGE}>{legacyCharge}</SummaryRow>
            <SummaryRow fieldName={fields.CHARGE_LEGACY_CHARGE_SOURCE_ATTR_ID}>
                {legacyChargeSourceAttrId}
            </SummaryRow>
            <SummaryRow fieldName={fields.CHARGE_CHARGE_COUNT}>{chargeCount}</SummaryRow>
            {hasWarrant && (
                <>
                    <SummaryRow fieldName={fields.WARRANT_WARRANT_NUMBER}>
                        {showWarrantLink ? (
                            <PopoutLink to={`/warrants/${warrantId}`}>{warrantNumber}</PopoutLink>
                        ) : (
                            <span>{warrantNumber}</span>
                        )}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_REPORTING_EVENT_NUMBER}>
                        {reportingEventNumber}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_WARRANT_TYPE_ATTR_ID}>
                        {warrantTypeAttrId}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_DESCRIPTION}>{description}</SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_OBTAINING_OFFICER_USER_ID}>
                        {obtainingOfficerUserDisplay}
                    </SummaryRow>
                    <SummaryRowDate
                        fieldName={fields.WARRANT_WARRANT_ISSUED_DATE_UTC}
                        date={warrantIssuedDateUtc}
                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                    />
                    <SummaryRow fieldName={fields.WARRANT_ISSUING_JUDGE}>{issuingJudge}</SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_BAIL_AMOUNT}>{bailAmount}</SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_IS_OTHER_JURISDICTION}>
                        {isOtherJurisdiction}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_ISSUING_AGENCY_ORI}>
                        {issuingAgencyOri}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_ISSUING_AGENCY_NAME}>
                        {issuingAgencyName}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.WARRANT_ISSUING_AGENCY_NAME_ATTR_ID}>
                        {issuingAgencyNameDisplay}
                    </SummaryRow>
                </>
            )}
            <SummaryRow fieldName={fields.CHARGE_CHARGE_STATUS_ATTR_ID}>
                {chargeStatusAttrId}
            </SummaryRow>
            <SummaryRow fieldName={fields.CHARGE_DUI_CAUSE_ATTR_ID}>
                {duiCauseAttrId}
            </SummaryRow>
            <SummaryRow fieldName={fields.CHARGE_WAS_CITATION_ISSUED}>
                {booleanToYesNo(wasCitationIssued)}
            </SummaryRow>
            <SummaryRow fieldName={fields.CHARGE_CITATION_NUMBER}>{citationNumber}</SummaryRow>
        </ChargeSummaryList>
    );

    return (
        <>
            {isSealed ? (
                <ChargeSummaryWrapper
                    key={chargeId}
                    data-test-id={testIds.CHARGES_CARD_CHARGE_SUMMARY}
                >
                    <ChargeTitleWrapper>
                        <ChargeIcon type={iconTypes.CHARGE} fontSize="22px" />
                        <ChargeTitle>{strings.sealedCharge}</ChargeTitle>
                        <SealedStampStyled>
                            <SealedStamp />
                        </SealedStampStyled>
                    </ChargeTitleWrapper>
                </ChargeSummaryWrapper>
            ) : (
                <ChargeSummaryWrapper
                    key={chargeId}
                    data-test-id={testIds.CHARGES_CARD_CHARGE_SUMMARY}
                >
                    <ChargeTitleWrapper>
                        <ChargeIcon type={iconTypes.CHARGE} fontSize="22px" />
                        <ChargeTitle>
                            {chargeOffenseCode}
                            {isVacated && (
                                <RedVacatedTag data-test-id={testIds.VACATED_TAG}>
                                    {vacatedChargeTitleTag}
                                </RedVacatedTag>
                            )}
                        </ChargeTitle>
                    </ChargeTitleWrapper>

                    {chargeSummary}
                    <FeatureFlagged flag="RMS_OFFENSE_CODE_WORDING">
                        <ChargeTemplateWrapper>
                            {arrestId && (
                                <ChargeWordTemplates
                                    summaryMode={summaryMode}
                                    arrestId={arrestId}
                                    chargeId={chargeId}
                                    chargesFormIndex={chargesFormIndex}
                                    offenseCodeId={chargeViewModel?.chargeOffenseCodeId}
                                />
                            )}
                        </ChargeTemplateWrapper>
                    </FeatureFlagged>
                </ChargeSummaryWrapper>
            )}
        </>
    );
};

import { EntityTypeEnum, AttributeTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { compact, get, includes, map } from 'lodash';

import { isBlank, isUndefinedOrNull } from '../../../../../helpers/logicHelpers';
import { getFullName } from '../../../../../helpers/userHelpers';
import { convertAttributeToAttributeView } from '../../../attributes/utils/attributesHelpers';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
    NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP,
} from '../../../attributes/state/data';
import { NEXUS_STATE_PROP as OTHER_INVOLVED_AGENCIES_NEXUS_STATE_PROP } from '../../../other-involved-agencies/state/data';
import { NEXUS_STATE_PROP as USER_ASSIGNMENTS_NEXUS_STATE_PROP } from '../../../user-assignments/state/data';
import {
    mostRecentActiveUnitForUserSelector,
    mostRecentUserAssignmentForUserSelector,
} from '../../../user-assignments/state/ui';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';
import getUserProfileResource from '../../../user-profiles/resources/userProfileResource';
import { NEXUS_STATE_PROP as USE_OF_FORCE_NEXUS_STATE_PROP } from '../../../use-of-forces/state/data';
import { NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP } from '../../../report-attributes/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '../../../location-entity-links/state/data';
import { dateDifferenceInYears } from '../../../../dates/utils/dateRangeHelpers';

/**
 * `AttributeType`s that are valid for the `ReportAttribute`s in a Use of Force Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const USE_OF_FORCE_CARD_REPORT_ATTRIBUTE_TYPES = [AttributeTypeEnum.USE_OF_FORCE_STATISTICS.name];

const reportAttributesFilter = (reportId) => {
    return (reportAttribute) =>
        reportAttribute.reportId === reportId &&
        includes(USE_OF_FORCE_CARD_REPORT_ATTRIBUTE_TYPES, reportAttribute.attributeType);
};

const calculateYearsOfService = ({ dateHired, yearsOfExperience }) => {
    if (dateHired && !yearsOfExperience) {
        return dateDifferenceInYears(dateHired, new Date());
    }

    if (!dateHired && !yearsOfExperience) {
        return undefined;
    }

    const yearsAtCurrentAgency = dateDifferenceInYears(dateHired, new Date());

    return yearsOfExperience + yearsAtCurrentAgency;
};

export function replaceUseOfForceCardBundle({ reportId, useOfForceCardBundle }) {
    return function (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) {
        const resource = getReportCardBundleResource();
        return resource
            .upsertUseOfForceCard(reportId, useOfForceCardBundle)
            .then((updatedUseOfForceBundle) => {
                const successAction = { type: 'REPLACE_USE_OF_FORCE_BUNDLE_SUCCESS' };
                const withRemoveAction = withRemoveMultiple(
                    {
                        [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportAttributesFilter(reportId),
                        [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: {
                            entityId: updatedUseOfForceBundle.useOfForce.id,
                            entityType: EntityTypeEnum.USE_OF_FORCE.name,
                            linkType: LinkTypesEnum.LOCATION_OF_USE_OF_FORCE,
                        },
                        [OTHER_INVOLVED_AGENCIES_NEXUS_STATE_PROP]: { reportId },
                    },
                    successAction
                );
                dispatch(
                    withEntityItems(
                        {
                            [USE_OF_FORCE_NEXUS_STATE_PROP]: [updatedUseOfForceBundle.useOfForce],
                            [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]:
                                updatedUseOfForceBundle.reportAttributes,
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: compact([
                                updatedUseOfForceBundle.locationEntityLink,
                            ]),
                            [OTHER_INVOLVED_AGENCIES_NEXUS_STATE_PROP]:
                                updatedUseOfForceBundle.otherInvolvedAgencies,
                        },
                        withRemoveAction
                    )
                );
                return updatedUseOfForceBundle;
            });
    };
}

export function prefillUserProfileFields({ userProfileId, formSet, formTransaction }) {
    return function (dispatch, getState, dependencies) {
        if (!isUndefinedOrNull(userProfileId) && !isBlank(userProfileId)) {
            const resource = getUserProfileResource();
            return resource.getFullUserProfile(userProfileId).then((fullUserProfileView) => {
                const attributes = fullUserProfileView.attributes;
                const userAssignments = fullUserProfileView.assignments;
                const userProfileView = fullUserProfileView.profile;
                const {
                    firstName,
                    middleName,
                    lastName,
                    suffix,
                    badgeNumber,
                    dateOfBirth,
                    sexAttrId,
                    raceAttrId,
                    userId,
                    heightFeet,
                    heightInches,
                    weight,
                } = userProfileView;

                const yearsOfService = calculateYearsOfService(userProfileView);

                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                                attributes,
                                convertAttributeToAttributeView
                            ),
                            [USER_ASSIGNMENTS_NEXUS_STATE_PROP]: userAssignments,
                        },
                        { type: 'STORE_USE_OF_FORCE_USER_PROFILE_PREFILL_DATA' }
                    )
                );

                const state = getState();
                const mostRecentUserAssignment = mostRecentUserAssignmentForUserSelector(state)(
                    userId
                );
                const rankAttribute = getAttributeByIdSelector(state)(
                    get(mostRecentUserAssignment, 'rankAttrId')
                );

                formTransaction(() => {
                    formSet(
                        'officerName',
                        getFullName({
                            firstName,
                            middleName,
                            lastName,
                            suffix,
                        })
                    );
                    formSet('officerYearsOfService', yearsOfService);
                    formSet('officerHeightFeet', heightFeet);
                    formSet('officerHeightInches', heightInches);
                    formSet('officerWeight', weight);
                    formSet('officerBadgeNumber', badgeNumber);
                    formSet('officerDateOfBirth', dateOfBirth);
                    formSet('officerSexAttrId', sexAttrId);
                    formSet('officerRaceAttrId', raceAttrId);
                    formSet('officerRank', get(rankAttribute, 'displayValue'));
                });
            });
        }
    };
}

export function prefillSupervisorProfileFields({
    onSceneSupervisorUserProfileId,
    formSet,
    formTransaction,
}) {
    return function (dispatch, getState, dependencies) {
        if (
            !isUndefinedOrNull(onSceneSupervisorUserProfileId) &&
            !isBlank(onSceneSupervisorUserProfileId)
        ) {
            const resource = getUserProfileResource();
            return resource
                .getFullUserProfile(onSceneSupervisorUserProfileId)
                .then((fullUserProfileView) => {
                    const attributes = fullUserProfileView.attributes;
                    const userAssignments = fullUserProfileView.assignments;
                    const userProfileView = fullUserProfileView.profile;
                    const { externalHrId, userId } = userProfileView;

                    dispatch(
                        dependencies.nexus.withEntityItems(
                            {
                                [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                                    attributes,
                                    convertAttributeToAttributeView
                                ),
                                [USER_ASSIGNMENTS_NEXUS_STATE_PROP]: userAssignments,
                            },
                            { type: 'STORE_USE_OF_FORCE_USER_PROFILE_PREFILL_DATA' }
                        )
                    );

                    const state = getState();
                    const mostRecentUserAssignment = mostRecentActiveUnitForUserSelector(state)(
                        userId
                    );
                    const unitAttribute = getAttributeByIdSelector(state)(
                        get(mostRecentUserAssignment, 'personnelUnitAttrId')
                    );

                    formTransaction(() => {
                        formSet('onSceneSupervisorHumanResourcesNumber', externalHrId);
                        formSet('onSceneSupervisorUnit', get(unitAttribute, 'displayValue'));
                    });
                });
        }
    };
}

export function prefillCurrentUserProfileFields({ currentUserProfile, formSet, formTransaction }) {
    const {
        firstName,
        middleName,
        lastName,
        suffix,
        badgeNumber,
        dateHired,
        dateOfBirth,
        sexAttrId,
        raceAttrId,
        userId,
        heightFeet,
        heightInches,
        weight,
        yearsOfExperience,
    } = currentUserProfile;
    const yearsOfService = calculateYearsOfService({ dateHired, yearsOfExperience });

    return function (dispatch, getState) {
        const state = getState();
        const mostRecentUserAssignment = mostRecentUserAssignmentForUserSelector(state)(userId);
        const rankAttributeId = get(mostRecentUserAssignment, 'rankAttrId');
        const rankAttribute = formatAttributeByIdSelector(state)(rankAttributeId);

        return formTransaction(() => {
            formSet(
                'officerName',
                getFullName({
                    firstName,
                    middleName,
                    lastName,
                    suffix,
                })
            );
            formSet('officerYearsOfService', yearsOfService);
            formSet('officerHeightFeet', heightFeet);
            formSet('officerHeightInches', heightInches);
            formSet('officerWeight', weight);
            formSet('officerBadgeNumber', badgeNumber);
            formSet('officerDateOfBirth', dateOfBirth);
            formSet('officerSexAttrId', sexAttrId);
            formSet('officerRaceAttrId', raceAttrId);
            formSet('officerRank', rankAttribute);
        });
    };
}

import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BriefingSimpleView, SqlSearchQueryBriefingSearchSqlQuery } from '@mark43/rms-api';
import { DataTableConfig, DataTableProps, VisibleFilter } from 'arc';
import { SortingState, Updater } from '@tanstack/table-core';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { RmsDispatch } from '../../../core/typings/redux';
import createDataTableSortColumns from '../../search/core/utils/createDataTableSortColumns';
import { briefingsDashboardSearch, searchBriefingDashboard } from '../state/ui';
import { convertFromVisibleFiltersToQuery } from '../state/forms/briefingsDashboardFilters';
import { BriefingFilterOption } from '../components/dashboard/BriefingsFilters';

const {
    currentQuerySelector,
    filtersSelector,
    // @ts-expect-error client-common to client RND-7529
} = briefingsDashboardSearch.selectors;

const columnSorts = createDataTableSortColumns<BriefingSimpleView>()({
    columns: {
        postedDateUtc: {
            id: 'postedDateUtc',
            sortKey: 'BRIEFING_POSTED_DATE',
            sortDirections: {
                asc: 'DATE_MOST_RECENT_TO_LEAST_RECENT',
                desc: 'DATE_LEAST_RECENT_TO_MOST_RECENT',
            },
        },
    },
});

type BriefingsSortsAndFiltersReturn = Pick<
    DataTableProps<BriefingSimpleView>,
    'onColumnSortChange'
> &
    Pick<DataTableConfig, 'sort'> & {
        visibleFilters: VisibleFilter<BriefingFilterOption>[];
        onFiltersChange?: (
            activeFilters: VisibleFilter<BriefingFilterOption>[],
            reset?: boolean
        ) => void;
    };

export const useBriefingsSortsAndFilters = (): BriefingsSortsAndFiltersReturn => {
    const isInitialLoadRef = useRef(true);
    const dispatch = useDispatch<RmsDispatch>();
    const visibleFilters = useSelector<RootState, VisibleFilter<BriefingFilterOption>[]>(
        filtersSelector
    );

    const { sorts } = useSelector<RootState, SqlSearchQueryBriefingSearchSqlQuery>(
        currentQuerySelector
    );

    const columnSort = useMemo(() => columnSorts.getColumnSort(sorts), [sorts]);

    const handleColumnSortChange = useCallback(
        (updaterOrValue: Updater<SortingState>) => {
            if (!Array.isArray(updaterOrValue) || !updaterOrValue.length) {
                // Currently, DataTable triggers onColumnSortChange when it is mounted which cause us redundant uncontrolled request
                // here it might be fixed https://mark43.atlassian.net/browse/RND-7481
                // till then we have to skip initial request for onColumnSortChange
                if (isInitialLoadRef.current) {
                    isInitialLoadRef.current = false;
                    return;
                }

                dispatch(
                    searchBriefingDashboard({
                        formData: convertFromVisibleFiltersToQuery(visibleFilters),
                        sortKey: undefined,
                        sortType: undefined,
                    })
                );
                return;
            }

            const columnSort = updaterOrValue[0];
            const sqlSort = columnSorts.getSqlSort(columnSort);

            dispatch(searchBriefingDashboard({ ...(sqlSort ?? {}) }));
        },
        [dispatch, visibleFilters]
    );

    const handleFilterChange = useCallback(
        (activeFilters: VisibleFilter<BriefingFilterOption>[], reset?: boolean) => {
            const formData = convertFromVisibleFiltersToQuery(activeFilters);
            dispatch(searchBriefingDashboard({ formData }, { reset }));
        },
        [dispatch]
    );

    return {
        sort: columnSort ? [columnSort] : undefined,
        visibleFilters,
        onColumnSortChange: handleColumnSortChange,
        onFiltersChange: handleFilterChange,
    };
};

import React from 'react';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';

const context = {
    name: boxEnum.ADVANCED_SEARCH_LOADING_MODAL,
};

export default function AdvancedSearchLoadingModal() {
    return (
        <Modal context={context} hideFooter={true}>
            <div className="advanced-search-loading-modal">
                <SimpleLoading />
                <div>Results being loaded</div>
            </div>
        </Modal>
    );
}

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { pure } from 'recompose';
import pluralize from 'pluralize';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL,
    DISPLAY_ONLY_BEHAVIOR_LABEL,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFGangNameSelect } from '../../../core/forms/components/selects/GangNameSelect';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';

const { withRRFActions, WithFieldState } = reactReduxFormHelpers;

const strings = componentStrings.search.fieldsets.CautionsFieldset;

function GangNameFieldSet() {
    return (
        <RRFNItems path="personGangTrackings" className="mark-43-n-items-column">
            {() => [
                <RRFGangNameSelect
                    key={0}
                    width={345}
                    multiple={true}
                    fieldNameAlternate={fields.PERSON_GANG_TRACKING_GANG_NAME_ATTR_ID}
                />,
            ]}
        </RRFNItems>
    );
}

const AdvancedSearchCautionsFieldset = withRRFActions(({ formActions }) => {
    const activeWithinRangeLabel = useFields(DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL)[
        DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL
    ];

    return (
        <WithFieldState path="cautions">
            {({ fieldModel }) => {
                const hasCautionAttrIdsSelected = Boolean(fieldModel?.cautionAttrIds?.length);
                const hasActiveWithinRangeSelected = Boolean(fieldModel?.activeWithinRange);
                return (
                    <>
                        <RRFAttributeSelect
                            attributeType={AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name}
                            width={200}
                            path="cautions.cautionAttrIds"
                            onChange={(cautionAttrIds) => {
                                if (cautionAttrIds.length) {
                                    return;
                                }
                                formActions.reset(`cautions.activeWithinRange`);
                                formActions.reset(`cautions.includeInactive`);
                            }}
                            multiple
                            includeExpired
                        />

                        <RRFDateRangePicker
                            label={activeWithinRangeLabel}
                            width={200}
                            withinLastPeriodOptions={['PT24H', 'PT48H', 'P7D', 'P28D']}
                            toDatePeriodOptions={['P1M', 'P1Y']}
                            paths={{
                                withinLastPeriod: 'cautions.activeWithinRange.withinLastPeriod',
                                toDatePeriod: 'cautions.activeWithinRange.toDatePeriod',
                                startDateUtc: 'cautions.activeWithinRange.startDateUtc',
                                endDateUtc: 'cautions.activeWithinRange.endDateUtc',
                            }}
                            onChange={(dateRange) => {
                                if (Object.keys(dateRange).length) {
                                    formActions.reset(`cautions.includeInactive`);
                                    return;
                                }

                                formActions.reset(`cautions.activeWithinRange`);
                            }}
                            disabled={!hasCautionAttrIdsSelected}
                        />

                        <RRFCheckbox
                            path="cautions.includeInactive"
                            helpText={strings.includeInactiveHelperText}
                            disabled={!hasCautionAttrIdsSelected || hasActiveWithinRangeSelected}
                        />
                    </>
                );
            }}
        </WithFieldState>
    );
});

function BehaviorsFieldsetRow1() {
    return (
        <Row key={1}>
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.BEHAVIORAL_CHARACTERISTIC.name}
                multiple={true}
                grouped={true}
                includeExpired={true}
                width={345}
                path="behavioralCharacteristicAttrIds"
            />
            <RRFAttributeSelect
                attributeType={AttributeTypeEnum.MOOD.name}
                multiple={true}
                includeExpired={true}
                width={345}
                path="moodAttrIds"
            />
        </Row>
    );
}

function BehaviorsFieldsetRow2({ gangTrackingEnabled }) {
    return (
        <FeatureFlagged
            flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED"
            fallback={
                <Row key={2}>
                    <RRFAttributeSelect
                        attributeType={AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name}
                        multiple={true}
                        includeExpired={true}
                        width={345}
                        path="personLabelAttrIds"
                    />
                    {gangTrackingEnabled && <GangNameFieldSet />}
                </Row>
            }
        >
            <>
                <Row key={2} style={{ display: 'flex', alignItems: 'baseline' }}>
                    <AdvancedSearchCautionsFieldset />
                </Row>
                {gangTrackingEnabled && (
                    <Row key={3}>
                        <GangNameFieldSet />
                    </Row>
                )}
            </>
        </FeatureFlagged>
    );
}

/**
 * Fieldset for a person's behaviors.
 * @param {Object}  props
 * @param {boolean} [props.collapsed=false]
 * @param {string}  [props.className='light']
 */
export default pure(function BehaviorsFieldset({
    collapsed = false,
    className = 'light',
    gangTrackingEnabled = false,
    behaviorMoodEnabled,
    ...otherProps
}) {
    const behaviorDisplayName = pluralize(
        useFields(DISPLAY_ONLY_BEHAVIOR_LABEL)[DISPLAY_ONLY_BEHAVIOR_LABEL]
    );
    return (
        <RRFFieldset
            path="behaviors"
            title={behaviorDisplayName}
            className={className}
            {...otherProps}
        >
            {behaviorMoodEnabled ? (
                <BehaviorsFieldsetRow1 />
            ) : (
                <BehaviorsFieldsetRow2 gangTrackingEnabled={gangTrackingEnabled} />
            )}
            {behaviorMoodEnabled
                ? !collapsed && <BehaviorsFieldsetRow2 gangTrackingEnabled={gangTrackingEnabled} />
                : null}
        </RRFFieldset>
    );
});

import { EntityTypeEnum, EntityTypeEnumType } from '@mark43/rms-api';

export const getEntityProfileSearchQuery = (
    entityId: number,
    entityType: EntityTypeEnumType,
    isMasterProfile: boolean
) => {
    switch (entityType) {
        case EntityTypeEnum.PERSON_PROFILE.name:
            const personIdKey = isMasterProfile ? 'masterPersonId' : 'personId';
            return { relatedPersons: [{ [personIdKey]: entityId }] };

        case EntityTypeEnum.ORGANIZATION_PROFILE.name:
            const organizationIdKey = isMasterProfile ? 'masterOrganizationId' : 'organizationId';
            return { relatedOrganizations: [{ [organizationIdKey]: entityId }] };

        case EntityTypeEnum.ITEM_PROFILE.name:
            const itemIdKey = isMasterProfile ? 'masterItemId' : 'itemId';
            return { relatedVehicles: [{ [itemIdKey]: entityId }] };

        default:
            return {};
    }
};

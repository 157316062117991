import React from 'react';
import styled from 'styled-components';

import {
    sortReportShortTitleViewModels,
    sortReportShortTitleViewModelsAlphAsc,
    sortReportShortTitleViewModelsAlphDesc,
    sortReportShortTitleViewModelsCreatedAsc,
    sortReportShortTitleViewModelsCreatedDesc,
    sortReportShortTitleViewModelsModifiedAsc,
    sortReportShortTitleViewModelsModifiedDesc,
} from '~/client-common/core/domain/report-short-titles/utils/reportShortTitlesHelpers';
import sortRelatedRecordsEnum from '~/client-common/core/enums/client/reportSidebarSortRelatedRecordsEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import NoDataBlock from '../../../../core/components/NoDataBlock';
import testIds from '../../../../../core/testIds';
import CollapsibleList from '../../../../core/components/CollapsibleList';
import LinkedReportItem from './LinkedReportItem';

const LinkedReportsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

function LinkedReports({
    reportShortTitleViewModels,
    noReportsMessage = '',
    sortType = undefined,
    activeValue = undefined,
    isCollapsible = false,
    linkedReportItemProps = {},
    paddingLeft,
}) {
    const sortedViewModels =
        !!sortType && !!activeValue
            ? ((sortType, activeValue) => {
                  if (sortType === sortTypeEnum.ALPHABETICAL_A_TO_Z) {
                      return sortReportShortTitleViewModelsAlphAsc(reportShortTitleViewModels);
                  } else if (sortType === sortTypeEnum.ALPHABETICAL_Z_TO_A) {
                      return sortReportShortTitleViewModelsAlphDesc(reportShortTitleViewModels);
                  } else if (
                      activeValue === sortRelatedRecordsEnum.CREATED_DATE &&
                      sortType === sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT
                  ) {
                      return sortReportShortTitleViewModelsCreatedDesc(reportShortTitleViewModels);
                  } else if (
                      activeValue === sortRelatedRecordsEnum.CREATED_DATE &&
                      sortType === sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT
                  ) {
                      return sortReportShortTitleViewModelsCreatedAsc(reportShortTitleViewModels);
                  } else if (
                      activeValue === sortRelatedRecordsEnum.MODIFIED_DATE &&
                      sortType === sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT
                  ) {
                      return sortReportShortTitleViewModelsModifiedAsc(reportShortTitleViewModels);
                  } else if (
                      activeValue === sortRelatedRecordsEnum.MODIFIED_DATE &&
                      sortType === sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT
                  ) {
                      return sortReportShortTitleViewModelsModifiedDesc(reportShortTitleViewModels);
                  } else {
                      return sortReportShortTitleViewModels(reportShortTitleViewModels);
                  }
              })(sortType, activeValue)
            : sortReportShortTitleViewModels(reportShortTitleViewModels);

    const renderLinkedReport = (viewModel) => (
        <LinkedReportItem
            key={viewModel.reportId}
            reportShortTitle={viewModel}
            showReportDetails={true}
            {...linkedReportItemProps}
        />
    );

    if (isCollapsible) {
        return (
            <LinkedReportsContainer data-test-id={testIds.LINKED_REPORTS_SECTION}>
                <CollapsibleList
                    items={sortedViewModels}
                    maxItemsCollapsed={5}
                    noItemsText={noReportsMessage}
                    paddingLeft={paddingLeft}
                    renderItem={renderLinkedReport}
                />
            </LinkedReportsContainer>
        );
    }

    return (
        <LinkedReportsContainer data-test-id={testIds.LINKED_REPORTS_SECTION}>
            {sortedViewModels.length === 0 && noReportsMessage ? (
                <NoDataBlock>{noReportsMessage}</NoDataBlock>
            ) : (
                sortedViewModels.map(renderLinkedReport)
            )}
        </LinkedReportsContainer>
    );
}

export default LinkedReports;

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { get, filter, noop } from 'lodash';

import { RefContextEnum, EntityTypeEnum, AttributeTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { lifecycleOptions } from 'markformythree';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { formatNameReportLinkTypeIdSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import { formatArrestTitleByReportIdSelector } from '~/client-common/core/domain/arrests/state/ui';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

import testIds from '../../../../../core/testIds';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import { LocationSummaryViewWrapper } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapper';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import withCard from '../../utils/withCard';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import {
    formName,
    getArrestForm,
    createArrestForm,
    buildArrestCardFormModel,
} from '../../state/forms/arrestForm';
import arrestCard from '../../state/ui/arrestCard';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import LegacyEntityDetails from '../../../../records/core/components/LegacyEntityDetails';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import { hydratedArrestForArrestCardByReportIdSelector, registerForm } from '../../state/ui';
import { registerCard } from '../../utils/cardsRegistry';
import WarrantSection from './WarrantSection';
import DefendantSection from './DefendantSection';
import ArrestCardSummary from './ArrestCardSummary';
import ArrestCardForm from './ArrestCardForm';

const locationDescriptionPath = () => 'arrestLocation.description';
const locationPositionAttrIdPath = () => 'arrestLocation.positionAttrId';

const mapStateToProps = createStructuredSelector({
    hydratedArrestForArrestCardByReportId: hydratedArrestForArrestCardByReportIdSelector,
    formatNameReportLinkTypeId: formatNameReportLinkTypeIdSelector,
    formatArrestTitleByReportId: formatArrestTitleByReportIdSelector,
    reportById: reportByIdSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = {
    buildArrestCardFormModel,
};

class ArrestCard extends React.Component {
    constructor(...args) {
        super(...args);

        const {
            arbiter,
            formatFieldByName,
            buildArrestCardFormModel,
            currentReportId: reportId,
            index,
        } = this.props;

        this.onEdit = this.onEdit.bind(this);
        this.onSaveProgress = this.onSaveProgress.bind(this);
        this.onSave = this.onSave.bind(this);

        const form = createArrestForm({
            initialState: buildArrestCardFormModel({ reportId }),
            arbiter,
            formatFieldByName,
        });
        registerForm({ form, index });

        this.unregisterCard = registerCard({
            cardModule: arrestCard,
            onSave: this.onSave,
            index,
        });
    }

    formsRegistryDeferredOperationDisposer = noop;
    eventInfoFormEventStartUtcObserveDisposer = noop;

    componentDidMount() {
        this.formsRegistryDeferredOperationDisposer = formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_EVENT_INFO.name,
            undefined,
            (eventInfoForm) => {
                // observe `eventStartUtc` changes
                const observeEventStartUtcConfig = {
                    subscriptions: {
                        eventStartUtc: 'eventStartUtc',
                    },
                    callback: (data) => this.handleEventInfoFormEventStartUtcChange(data),
                };
                this.eventInfoFormEventStartUtcObserveDisposer = eventInfoForm.observe(
                    observeEventStartUtcConfig
                );
            }
        );
    }

    componentWillUnmount() {
        this.formsRegistryDeferredOperationDisposer();
        this.eventInfoFormEventStartUtcObserveDisposer();
        formsRegistry.unregister(formName, this.props.index);
        this.unregisterCard();
    }

    handleEventInfoFormEventStartUtcChange({ eventStartUtc }) {
        formsRegistry.maybeDeferredOperation(formName, this.props.index, (arrestForm) => {
            arrestForm.set('eventStartUtc', eventStartUtc);
        });
    }

    onEdit() {
        this.props.editCallback(() => this.props.onEdit({ index: this.props.index }));
    }

    onSaveProgress() {
        const { index, currentReportId } = this.props;
        const arrestForm = getArrestForm(index);
        return this.props.onSaveProgress(arrestForm, { index, reportId: currentReportId });
    }

    onSave() {
        const { index, currentReportId } = this.props;
        const arrestForm = getArrestForm(index);
        return this.props.onSave(arrestForm, { index, reportId: currentReportId });
    }

    handleLocationAdd(location, modelEntityLink) {
        const arrestForm = getArrestForm(this.props.index);
        arrestForm.set('arrestLocation', modelEntityLink);
    }

    handleLocationRemove() {
        const arrestForm = getArrestForm(this.props.index);
        arrestForm.set('arrestLocation', undefined);
    }

    render() {
        const {
            card = {},
            currentReportId,
            reportById,
            hydratedArrestForArrestCardByReportId,
            formatNameReportLinkTypeId,
            formatArrestTitleByReportId,
            index,
        } = this.props;

        const {
            arrest,
            arrestAttributes,
            defendantAttachments,
            warrants,
            legacyEntityDetails,
            defendantRemarks,
        } = hydratedArrestForArrestCardByReportId(currentReportId);
        const ren = get(reportById(currentReportId), 'reportingEventNumber');

        const locationSummaryProps = {
            entityType: EntityTypeEnum.ARREST.name,
            entityId: arrest.id,
            linkType: LinkTypesEnum.ARREST_LOCATION,
            onLocationAdd: this.handleLocationAdd.bind(this),
            onLocationRemove: this.handleLocationRemove.bind(this),
        };

        const warrantCheckTypeAttributes = filter(
            arrestAttributes,
            (attribute) => attribute.attributeType === AttributeTypeEnum.WARRANT_CHECK_TYPES.name
        );
        return (
            <Card
                className={card.anchor}
                anchor={card.anchorForIndex(index)}
                title={formatArrestTitleByReportId(currentReportId)}
                testId={testIds.ARREST_CARD}
                renderContent={(summaryMode) => (
                    <div>
                        <CardSection>
                            {summaryMode ? (
                                <ArrestCardSummary
                                    arrest={arrest}
                                    arrestAttributes={arrestAttributes}
                                />
                            ) : (
                                <ArrestCardForm reportId={currentReportId} index={index} />
                            )}
                        </CardSection>

                        <DefendantSection
                            currentReportREN={ren}
                            reportId={currentReportId}
                            formIndex={index}
                            defendantId={arrest.defendantId}
                            summaryMode={summaryMode}
                            defendantAttachments={defendantAttachments}
                            arrest={arrest}
                            defendantRemarks={defendantRemarks}
                        />
                        <CardSection
                            testId={testIds.ARREST_LOCATION_SECTION}
                            fieldName={
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_ARREST_LOCATION_LOCATION_ID
                            }
                        >
                            {summaryMode ? (
                                <LocationSummaryViewWrapper
                                    summaryMode={true}
                                    {...locationSummaryProps}
                                />
                            ) : (
                                <ArbiterForm
                                    name={formName}
                                    index={index}
                                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                                    render={() => (
                                        <LocationSummaryViewWrapperWithFormFields
                                            {...locationSummaryProps}
                                            summaryMode={false}
                                            locationDescriptionPath={locationDescriptionPath}
                                            locationPositionAttrIdPath={locationPositionAttrIdPath}
                                        />
                                    )}
                                />
                            )}
                        </CardSection>
                        {(warrants.length > 0 || warrantCheckTypeAttributes.length > 0) && (
                            <WarrantSection
                                summaryMode={true}
                                arrest={arrest}
                                warrants={warrants}
                                warrantCheckTypeAttributes={warrantCheckTypeAttributes}
                            />
                        )}
                        <VisibilityObserver
                            formName={formName}
                            formIndex={index}
                            path="arrest.arrestCodefendant"
                            render={({ hidden }) => {
                                const codefendantDisplayName = formatNameReportLinkTypeId(
                                    LinkTypesEnum.CO_DEFENDANT
                                );
                                return (
                                    !hidden && (
                                        <CardSection
                                            testId={testIds.CODEFENDANT_SECTION}
                                            fieldName={
                                                fields.NAME_REPORT_LINK_LINK_TYPE_CO_DEFENDANT_LINK_TYPE
                                            }
                                        >
                                            <NameSummaryViewWrapper
                                                renForRecents={ren}
                                                summaryMode={summaryMode}
                                                addNameButtonText={codefendantDisplayName}
                                                reportId={currentReportId}
                                                contextType={EntityTypeEnum.REPORT.name}
                                                contextId={currentReportId}
                                                parentEntityType={EntityTypeEnum.REPORT.name}
                                                parentId={currentReportId}
                                                linkType={LinkTypesEnum.CO_DEFENDANT}
                                                show={{ people: true, organizations: false }}
                                                personOverlayIdPrefix={joinTruthyValues(
                                                    [
                                                        overlayIdEnum.PERSON_OVERLAY_CODEFENDANT,
                                                        index,
                                                    ],
                                                    '.'
                                                )}
                                            />
                                        </CardSection>
                                    )
                                );
                            }}
                        />
                        <VisibilityObserver
                            formName={formName}
                            formIndex={index}
                            path="arrest.arrestComplainant"
                            render={({ hidden }) => {
                                const complainantDisplayName = formatNameReportLinkTypeId(
                                    LinkTypesEnum.COMPLAINANT_IN_REPORT
                                );
                                return (
                                    !hidden && (
                                        <CardSection
                                            testId={testIds.COMPLAINANT_SECTION}
                                            fieldName={
                                                fields.NAME_REPORT_LINK_LINK_TYPE_COMPLAINANT_IN_REPORT_LINK_TYPE
                                            }
                                        >
                                            <NameSummaryViewWrapper
                                                renForRecents={ren}
                                                summaryMode={summaryMode}
                                                addNameButtonText={complainantDisplayName}
                                                reportId={currentReportId}
                                                contextType={EntityTypeEnum.REPORT.name}
                                                contextId={currentReportId}
                                                parentEntityType={EntityTypeEnum.REPORT.name}
                                                parentId={currentReportId}
                                                linkType={LinkTypesEnum.COMPLAINANT_IN_REPORT}
                                                show={{ people: true, organizations: true }}
                                                personOverlayIdPrefix={joinTruthyValues(
                                                    [
                                                        overlayIdEnum.PERSON_OVERLAY_COMPLAINANT,
                                                        index,
                                                    ],
                                                    '.'
                                                )}
                                            />
                                        </CardSection>
                                    )
                                );
                            }}
                        />
                        {summaryMode && (
                            <LegacyEntityDetails legacyEntityDetails={legacyEntityDetails} />
                        )}
                    </div>
                )}
                onEdit={this.onEdit}
                errors={card.errorMessages}
                summaryMode={card.summaryMode}
                canEdit={get(card.canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(card.canEditReportCardStatus, 'errorMessage')}
                saving={card.saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class ArrestCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => <ArrestCard ref={this.setRef} {...this.props} arbiter={arbiter} />}
            </RMSArbiterProvider>
        );
    }
}

export default compose(
    withCard(arrestCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(ArrestCardWrapper);

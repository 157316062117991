import React from 'react';
import styled from 'styled-components';
import { Menu, MenuContent, MenuItem, MenuTrigger } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { Button } from '../../../core/components/Button';
import { IconButton } from '../../../core/components/IconButton';
import { useEFileContext, useEFileDisplayName } from '../../hooks';
import { useEFileDetailsModal } from '../../hooks/useEFileDetailsModal';
import testIds from '../../../../core/testIds';
import { EFileUpdateModal } from './EFileUpdateModal';

const strings = componentStrings.eFiles.core.header;

const EFileHeaderContainer = styled.div`
    margin: 17px;
    display: flex;
    position: relative;
    float: right;
    column-gap: 10px;
`;

export const EFileHeader = () => {
    const {
        getters: { efile },
    } = useEFileContext();
    const { eFileDisplayName } = useEFileDisplayName();
    const { openUpdateEFile } = useEFileDetailsModal();

    const eFileViewModel = efile.getEFileViewModel();

    return (
        <EFileHeaderContainer>
            <Button leadingVisual={'Add'} variant={'outline'} disabled>
                {strings.newSubmission}
            </Button>
            <Button variant={'outline'} disabled>
                {strings.submission}
            </Button>
            <Menu>
                <MenuTrigger asChild isDisabled={eFileViewModel && !eFileViewModel.canEdit}>
                    <IconButton
                        testId={testIds.E_FILE_HAMBURGER_MENU}
                        aria-label="E-file Hamburger Menu"
                        icon="Menu"
                        variant="outline"
                    />
                </MenuTrigger>
                <MenuContent align="end">
                    <MenuItem
                        data-test-id={testIds.E_FILE_MANAGE_DETAILS}
                        onSelect={openUpdateEFile}
                    >
                        {strings.hamburgerMenu.manageEFileDetails(eFileDisplayName)}
                    </MenuItem>
                </MenuContent>
            </Menu>
            <EFileUpdateModal />
        </EFileHeaderContainer>
    );
};

import React from 'react';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../legacy-redux/components/core/Modal';
import CreateCaseForm from './CreateCaseForm';

export const context = { name: boxEnum.CREATE_CASE_MODAL };
const strings = componentStrings.cases.core.CreateCaseModal;

export default function CreateCaseModal() {
    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();

    return (
        <Modal context={context} title={strings.title(caseFieldName)} okText={null}>
            <CreateCaseForm />
        </Modal>
    );
}

import React, { RefObject } from 'react';
import styled from 'styled-components';
import { NavItem } from 'arc';

/* Remove pointer events from children so toggling works */
export const NavItemToggle = styled(NavItem)`
    * {
        pointer-events: none;
    }
`;

/* Utility to check if the click outside the popover is the nav item that toggles it. */
export function handleNavToggle(
    e: React.MouseEvent | CustomEvent,
    ref: RefObject<HTMLElement>,
    action: () => void
) {
    if (e.target !== ref.current) {
        action();
    }
}

import keyMirror from 'keymirror';

/*
 * NOTE:
 * Please do not use path aliases for anything in this file.
 * RMS E2E Test Suite in CI does not know how to resolve these.
 * We should figure out a better way to share these between RMS FE
 * RMS E2E Suite
 */

// eslint-disable-next-line no-restricted-imports
import boxEnum from '../client-common/core/enums/client/boxEnum';
// eslint-disable-next-line no-restricted-imports
import overlayIdEnum from '../client-common/core/enums/universal/overlayIdEnum';

/**
 * All values of data-test-id attributes in the RMS.
 * @type {Object}
 * @see  https://readme.mark43.io/guides/products/rms/rms-frontend/rmsfe-e2e-data-attrs/
 */

const testIds = keyMirror({
    ...boxEnum,
    ...overlayIdEnum,

    // report creation modal
    CREATE_REPORT_MODAL: null,
    NEW_REPORT_OPTIONS_LIST: null,
    EVENT_SEARCH_BUTTON: null,
    RMS_AUTOGENERATE_REN_BUTTON: null,
    SELECTED_REPORT_DEFINITION: null,

    // generic elements
    APP_WRAPPER: null,
    TABLE: null,
    GENERIC_EXPAND_COLLAPSE_BUTTON: null,
    FIXED_FOOTER_SAVE: null,
    GENERIC_TEXT_GO_BUTTON: null,
    COLLAPSIBLE_SECTION_STICKY_HEADERS_BUTTON: null,
    EDIT_BUTTON: null,
    DUPLICATE_BUTTON: null,
    TRASH_BUTTON: null,
    SUBMIT_BUTTON: null,
    LOADING_ICON: null,
    LOADING_BAR: null,
    TAB_LINK: null,
    TAB_CONTENT: null,
    LABEL: null,
    VEHICLE_LABELS: null,
    TABLE_COLUMN_HEADER: null,
    TABLE_COLUMN_TITLE: null,
    TABLE_COLUMN_OPTION: null,
    TABLE_COLUMN_POPOUT: null,
    TABLE_ROW: null,
    TABLE_CELL: null,
    SORT_OPTIONS: null,
    GREEN_SUCCESS_MESSAGE: null,
    ENTITY_SUMMARY_LINK: null,
    AUTOFILL_DATE_BUTTON: null,
    DROPDOWN_MENU_OPTION: null,
    DROPDOWN_MENU_OPTION_LINK: null,
    REORDER_DROPDOWN_MENU: null,
    SEARCH_SIZE_DROPDOWN_MENU: null,
    IMAGE_GALLERY_LIGHTBOX_TEXT: null,
    LIGHTBOX_CLOSE_BUTTON: null,
    ATTACHMENT_TOGGLE_VIEW_BUTTON: null,
    ATTACHMENT_GRID_ITEM: null,
    INLINE_FILE_ROW: null,
    FORMATTED_DATE: null,
    OVERDUE_FORMATTED_DATE: null,
    PAGINATION_CONTAINER: null,
    PAGINATION_ITEM: null,
    TABLE_RESULTS_HEADER: null,
    TABLE_RESULTS_SUMMARY_RANGE: null,
    CLEAR_BUTTON: null,
    THUMBNAIL_WRAPPER: null,
    FILTER_BUTTON: null,
    ENABLE_THEME_BUTTON: null,
    ENABLE_ARC_SIDEBAR_NAVIGATION_BUTTON: null,
    COPY_TO_CLIPBOARD_CONTAINER: null,
    COPY_TO_CLIPBOARD: null,
    TIMEZONE_SELECT: null,

    // Error Page
    ERROR_TITLE: null,
    ERROR_BODY: null,

    // Permissions Modal
    ROLE_PERMISSION_SECTION: null,
    USER_PERMISSION_SECTION: null,

    // Cards
    EVENT_CARD: null,
    FIELD_CONTACT_CARD: null,
    STOP_CARD: null,
    STOP_CARD_SUBJECT_SECTION: null,
    STOP_CARD_OTHER_NAMES_SECTION: null,
    STOP_CARD_LOCATIONS_SECTION: null,
    STOP_ANONYMOUS_SUBJECT_SUMMARY_SECTION: null,
    STOP_INCIDENT_SUMMARY_SECTION: null,
    STOP_OFFICER_SUMMARY_SECTION: null,
    STOP_SUBJECT_ADD_SEARCH_TYPE: null,
    STOP_SUBJECT_REMOVE_SEARCH_TYPE: null,
    ARREST_CARD: null,
    CHARGES_CARD: null,
    CHARGES_CARD_CHARGE_SUMMARY: null,
    PROPERTY_CARD: null,
    VEHICLE_CARD: null,
    OFFENSE_CARD: null,
    OFFENSE_CARD_LINKED_ARREST: null,
    OFFENSE_CARD_LINKED_WARRANT: null,
    OFFENSE_CARD_PROPERTY_SECTION: null,
    OFFENSE_CARD_VEHICLE_SECTION: null,
    USE_OF_FORCE_CARD: null,
    USE_OF_FORCE_SUBJECT_CARD: null,
    ADD_NEW_USE_OF_FORCE_SUBJECT_BUTTON: null,
    DELETE_USE_OF_FORCE_SUBJECT_MODAL: null,
    NARRATIVE_CARD: null,
    SUMMARY_NARRATIVE_CARD: null,
    ATTACHMENTS_CARD: null,
    ATTACHMENTS_CARD_MANAGE_ATTACHMENTS_BUTTON: null,
    APPROVAL_STATUS_CARD: null,
    CARD_SUMMARY: null,
    CARD_CONTENT: null,
    CARD_PANEL_ERRORS: null,
    CARD_EDIT: null,
    CARD_SAVE: null,
    CARD_TITLE: null,
    CARD_HEADER: null,
    CARD_ERROR_LINK: null,
    ASSIGN_TO_ME_BUTTON: null,
    REPORT_STATUS_COMMENTS_SUBMITTED_CARD: null,
    REPORT_STATUS_COMMENTS_SUBMITTED_CARD_APPROVE_REPORT_BUTTON: null,
    REPORT_STATUS_COMMENTS_SUBMITTED_CARD_REJECT_REPORT_BUTTON: null,
    REPORT_STATUS_COMMENTS_NO_SUBMIT_DRAFT_CARD: null,
    REPORT_STATUS_COMMENTS_CUSTODIAL_SUBMISSION_DRAFT_CARD: null,
    REPORT_STATUS_COMMENTS_PACKAGE_REPORT_CARD: null,
    REPORT_STATUS_COMMENTS_SUBMISSION_DRAFT_CARD: null,
    REPORT_STATUS_COMMENTS_APPROVED_CARD: null,
    REPORT_STATUS_COMMENTS_REJECTED_CARD: null,
    REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD: null,
    REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD_APPROVE_BUTTON: null,
    REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD_FORCE_APPROVE_BUTTON: null,
    REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD_REJECT_BUTTON: null,
    REPORT_STATUS_COMMENTS_COMPLETED_CARD: null,
    BEHAVIORAL_CRISIS_CARD: null,
    CITATION_CARD: null,
    COMMUNITY_INFORMATION_CARD: null,
    DUPLICATE_CARD_BUTTON: null,
    COURT_CASE_CARD: null,
    BOOKING_CARD: null,
    BOOKING_CARD_CHARGE_SUMMARY: null,
    TRAFFIC_CRASH_CARD: null,
    INCIDENT_CARD: null,
    MISSING_PERSONS_CARD: null,
    DOWNLOADABLES_CARD: null,
    RELATIONSHIPS_CARD: null,
    INVOLVED_PROFILES_CARD: null,
    STUB_OFFENSE_INCIDENT_CARD: null,
    ADD_NEW_OFFENSE_INCIDENT_BUTTON: null,
    ADD_ARREST_REPORT_BUTTON: null,
    ARREST_BLOCK: null,
    ARREST_BLOCK_SUBMIT_REPORT_SEPARATELY: null,
    ARREST_BLOCK_DROPDOWN_MENU: null,
    ARREST_BLOCK_VIEW_REPORT_SEPARATELY: null,
    ARREST_BLOCK_EDIT_LABELS: null,
    ARREST_BLOCK_DELETE_REPORT: null,
    SUPPLEMENT_INFO_CARD: null,
    TOW_VEHICLE_CARD: null,
    TOW_VEHICLE_VEHICLE_SECTION: null,
    TOW_VEHICLE_TOW_INFORMATION_SECTION: null,
    TOW_VEHICLE_IMPOUND_CARD: null,
    IMPOUND_CARD: null,
    IMPOUND_CARD_NCIC_INFORMATION_SECTION: null,
    IMPOUND_CARD_VEHICLE_SECTION: null,
    CHECK_IN_INFORMATION_CARD: null,
    CHECK_IN_INFORMATION_CARD_STORAGE_LOCATION_SECTION: null,
    RELEASE_INFORMATION_CARD: null,
    RELEASE_INFORMATION_CARD_RELEASE_TO_SECTION: null,
    RELEASE_INFORMATION_CARD_AUTHORIZED_BY_SECTION: null,
    SHOW_MORE_ERRORS_LINK: null,
    CRASH_LOCATION_CARD: null,
    CRASH_EVENT_INFO_CARD: null,

    // Report Hamburger Menu
    RECORD_HAMBURGER_MENU: null,
    RETURN_TO_DRAFT: null,
    CHANGE_OWNER: null,
    CHANGE_REN: null,
    REMOVE_REN: null,
    CHANGE_RECORD_NUMBER: null,
    EDIT_SECURITY_CLASSIFICATION: null,
    EDIT_ATTACHMENTS: null,
    EDIT_ASSOCIATED_RECORDS: null,
    EDIT_CHARGES: null,
    SEND_TO_BOOKING: null,
    TOW_VEHICLE_CHECK_IN: null,
    TOW_VEHICLE_RELEASE: null,
    EDIT_REPORT_CASE_STATUS: null,
    CREATE_NEW_CASE: null,
    ADD_TO_INVESTIGATION: null,
    CREATE_TASK: null,
    SEAL: null,
    UNSEAL: null,
    VACATE_CHARGES: null,
    EDIT_ARREST_UCR_CLASSIFICATION: null,
    EDIT_UCR_CLASSIFICATION: null,
    EDIT_GANG_TRACKING: null,
    DELETE_REPORT: null,
    SEND_TO_CLIPS: null,
    CREATE_E_FILE: null,
    CREATE_SUBMISSION: null,

    // Other Report Elements
    EMBEDDED_REPORT_SIDEBAR: null,
    RECORD_HEADER_TITLE: null,
    REPORT_REN_DISPLAY_VALUE: null,
    REPORT_HEADER_EXPORTS_DROPDOWN_BUTTON: null,
    REPORT_HEADER_EXPORTS_BUTTON: null,
    REPORT_HEADER_EXPORTS_MENU_EXPORT_LINK: null,
    REPORT_HEADER_EXPORTS_DEFAULT_LINK: null,
    REPORT_HEADER_EXPORTS_MENU_ADVANCED_OPTIONS_LINK: null,
    REPORT_HEADER_LINKED_REPORTS_BUTTON: null,
    REPORT_HEADER_BOOKING_BUTTON: null,
    REPORT_HEADER_HISTORY_BUTTON: null,
    REPORT_HEADER_MANAGE_PERMISSIONS_BUTTON: null,
    LINKED_REPORTS_DROPDOWN: null,
    QUICK_ADD_LOCATION_PILL: null,
    QUICK_ADD_ORGANIZATION_PILL: null,
    QUICK_ADD_PERSON_PILL: null,
    QUICK_ADD_UNKNOWN_PILL: null,
    PERSON_QUICK_ADD_EXPAND_BUTTON: null,
    REPORT_SIDEBAR_LABEL_EDIT: null,
    REPORT_SIDEBAR_LABEL_SECTION: null,
    REPORT_SIDEBAR_DETENTIONS_SECTION: null,
    REPORT_SIDEBAR_SECTION_CARD_TITLE: null,
    REPORT_SIDEBAR_ABOUT_TAB: null,
    REPORT_SIDEBAR_SUBMISSIONS_TAB: null,
    REPORT_SIDEBAR_INVOLVED_PROFILE_TAB: null,
    REPORT_SIDEBAR_EVENT_INFO_TAB: null,
    REPORT_SIDEBAR_REPORT_CARD: null,
    REPORT_SIDEBAR_TASK_TAB: null,
    LINK_ITEM_BUTTON: null,
    LINKED_RECORDS_SORT_DROPDOWN: null,
    LINKED_RECORDS_HEADER: null,
    UNKNOWN_PERSON_ID_BUTTON: null,

    // Warrants
    NEW_WARRANT_BUTTON: null,
    CREATE_WARRANT_MODAL: null,
    WARRANT_DETAILS_CARD: null,
    WARRANT_SUBJECT_SECTION: null,
    WARRANT_INFORMATION_SECTION: null,
    WARRANT_COURT_INFORMATION_SECTION: null,
    WARRANT_LOCATION_SECTION: null,
    WARRANT_VEHICLE_INFORMATION_SECTION: null,
    WARRANT_CHARGES_CARD: null,
    WARRANT_DEX_SUBMISSION_CARD: null,
    WARRANT_EXTERNAL_DATABASE_SECTION: null,
    WARRANTS_DASHBOARD_EXPORT: null,
    WARRANT_HEADER_HISTORY_BUTTON: null,
    WARRANTS_DASHBOARD_ACTION_BAR: null,
    WARRANTS_DASHBOARD_ACTION_BAR_SELECT_ALL_RESULTS: null,

    // generic name summary sections
    REPORTING_PARTY_SECTION: null,
    VICTIM_SECTION: null,
    SUBJECT_SECTION: null,
    SUBJECT_IN_FIELD_CONTACT_SECTION: null,
    FIELD_CONTACT_ORGANIZATION_SECTION: null,
    FIELD_CONTACT_OTHER_NAMES_SECTION: null,
    FIELD_CONTACT_LOCATION_SECTION: null,
    SUSPECT_SECTION: null,
    OFFENDER_SECTION: null,
    WITNESS_SECTION: null,
    DEFENDANT_SECTION: null,
    CODEFENDANT_SECTION: null,
    COMPLAINANT_SECTION: null,
    OTHER_NAMES_SECTION: null,
    SUBJECT_IN_BEHAVIORAL_CRISIS_SECTION: null,
    OTHER_NAME_IN_BEHAVIORAL_CRISIS_SECTION: null,
    SUBJECT_IN_CITATION_SECTION: null,
    SUBJECT_IN_COMMUNITY_INFORMATION_SECTION: null,
    ORGANIZATIONS_IN_COMMUNITY_INFORMATION_SECTION: null,
    ORGANIZATION_PROFILE_NAME: null,
    TRAFFIC_CRASH_SUBJECT_SECTION: null,
    INCIDENT_SUBJECT_SECTION: null,
    INCIDENT_WITNESS_SECTION: null,
    INCIDENT_OTHER_NAMES_SECTION: null,
    MISSING_PERSONS_PERSON_SECTION: null,
    MISSING_PERSONS_WITNESS_SECTION: null,
    MISSING_PERSONS_LAST_KNOWN_CONTACTS_SECTION: null,
    INVOLVED_PROFILES_PERSONS_SECTION: null,
    INVOLVED_PROFILES_ORGANIZATIONS_SECTION: null,

    // generic location sections
    REPORT_TAKEN_LOCATION_SECTION: null,
    OFFENSE_LOCATION_SECTION: null,
    INCIDENT_LOCATION_SECTION: null,
    ARREST_LOCATION_SECTION: null,
    BEHAVIORAL_CRISIS_LOCATION_SECTION: null,
    CITATION_LOCATION_SECTION: null,
    COMMUNITY_INFORMATION_LOCATION_SECTION: null,
    TRAFFIC_CRASH_LOCATION_SECTION: null,
    MISSING_PERSONS_LAST_KNOWN_LOCATION_SECTION: null,
    MISSING_PERSONS_LOCATION_RETURNED_SECTION: null,
    INVOLVED_PROFILES_LOCATIONS_SECTION: null,

    // Specific Side Panels
    LOCATION_SIDE_PANEL: null,
    EXPORTS_ATTACHMENTS_SIDE_PANEL: null,
    EXPORTS_REDACTION_SIDE_PANEL: null,
    DEPARTMENT_PROFILE_SIDE_PANEL: null,
    LINK_ITEMS_TO_OFFENSE_SIDE_PANEL: null,
    CHECK_IN_INFORMATION_SIDE_PANEL: null,
    RELEASE_INFORMATION_SIDE_PANEL: null,

    // Release tracking
    EXPORTS_EXTERNAL_TEMPLATES_SECTION_HEADER: null,
    EXPORTS_EXTERNAL_TEMPLATE_CHECKBOX: null,
    EXPORTS_INCLUDE_IN_EXPORT_CHECKBOX: null,
    EXPORTS_PRESET_SELECT: null,
    EXPORTS_DOWNLOAD_BUTTON: null,
    EXPORTS_CANCEL_BUTTON: null,
    EXPORTS_EXPORTS_LIST: null,
    EXPORTS_EXPORTS_LIST_CLEAR_ALL_BUTTON: null,
    EXPORTS_EXPORTS_FILE: null,
    EXPORTS_EXPORTS_FILE_LOADED: null,
    EXPORTS_RELEASE_HISTORY_BLOCK: null,
    EXPORTS_RELEASE_HISTORY_BLOCK_TOGGLE: null,
    EXPORTS_EXPORT_OPTION_CHECKBOX: null,
    EXPORTS_RELEASE_CONTAINER: null,
    EXPORTS_DOWNLOAD_RELEASE_BUTTON: null,
    EXPORTS_EMAIL_RELEASE_BUTTON: null,

    // attachment side panel
    MANAGE_ATTACHMENTS: null,
    EXPORTS_ATTACHMENTS_INFO_ROW: null,
    EXPORTS_ATTACHMENTS_SIDE_PANEL_CHECKBOX_ALL: null,
    EXPORTS_ATTACHMENTS_SIDE_PANEL_CHECKBOX: null,
    EXPORTS_ATTACHMENTS_SIDE_PANEL_OPEN_BUTTON: null,
    EXPORTS_ATTACHMENTS_SIDE_PANEL_FILTER_INPUT: null,

    // Charges Card
    VACATED_TAG: null,

    // Charges side panel
    CHARGES_SIDE_PANEL: null,
    CHARGES_SIDE_PANEL_CHARGE_WIDGET: null,
    ARREST_CHARGES_STUB_OFFENSE_SIDE_PANEL: null,
    CHARGES_ADD_EDIT: null,
    CHARGES_SIDE_PANEL_ADD_CHARGE: null,
    CHARGES_SIDE_PANEL_ADD_OFFENSE: null,
    CHARGES_SIDE_PANEL_ADD_WARRANT: null,
    CHARGES_SIDE_PANEL_FIND_WARRANT: null,
    CHARGES_SIDE_PANEL_ADD_NEW_WARRANT: null,
    CHARGES_SIDE_PANEL_WARRANT_SUMMARY: null,
    CHARGES_SIDE_PANEL_LEGACY_CHARGE_OFFENSE_SUMMARY: null,
    CHARGES_SIDE_PANEL_OFFENSE_SUMMARY: null,
    CHARGES_SIDE_PANEL_DELETE_WARRANT: null,
    CHARGES_SIDE_PANEL_ADD_PRIOR_OFFENSE: null,
    CHARGES_SIDE_PANEL_PRIOR_OFFENSE_SEARCH_RESULTS_SCREEN_OFFENSE_SEARCH_RESULT: null,
    CHARGES_SIDE_PANEL_ADD_NEW_OFFENSE: null,
    CHARGES_SIDE_PANEL_ADD_LEGACY_OFFENSE: null,
    PRIOR_OFFENSE_NAVIGATOR_SEARCH_OFFENSE_BY_REN: null,
    PRIOR_OFFENSE_NAVIGATOR_SEARCH_OFFENSE_BUTTON: null,
    PRIOR_OFFENSE_NAVIGATOR_OFFENSE_CHECKBOX: null,
    CHARGES_SIDE_PANEL_PRIOR_OFFENSE_SEARCH_SELECTOR_SCREEN_ADD_PRIOR_OFFENSE: null,

    ATTACHMENTS_SIDE_PANEL: null,
    CONNECT_WARRANT_SIDE_PANEL: null,
    CONNECT_WARRANT_SIDE_PANEL_SEARCH_WARRANT_NUM_BUTTON: null,
    CONNECT_WARRANT_SIDE_PANEL_SEARCH_WARRANT_BUTTON: null,
    CONNECT_WARRANT_SIDE_PANEL_WARRANT_SEARCH_RESULT: null,
    // All Side Panels
    SIDE_PANEL_TITLE: null,
    SIDE_PANEL_SAVE: null,
    SIDE_PANEL_CLOSE: null,
    SIDE_PANEL_CANCEL: null,
    SIDE_PANEL_ERRORS: null,
    SIDE_PANEL_CONTENT: null,
    // All Modals
    MODAL_OK: null,
    MODAL_TITLE: null,
    MODAL_CONTENT: null,
    MODAL_CANCEL: null,
    MODAL_ERRORS: null,
    EXPLAIN_REPORT_CHANGES: null,
    MODAL_MESSAGE: null,
    // Location Summary View
    LOCATION_SUMMARY_VIEW: null,
    LOCATION_SUMMARY_VIEW_ADD_LOCATION: null,
    LOCATION_SUMMARY_VIEW_ADD_NO_FIXED: null,
    LOCATION_SUMMARY_VIEW_REMOVE_LOCATION: null,
    // these ids are used for summary mode of locations
    LOCATION_SUMMARY_VIEW_SUBDIVISIONS: null,
    LOCATION_SUMMARY_VIEW_DESCRIPTION: null,
    LOCATION_SUMMARY_VIEW_PLACE_NAME: null,
    LOCATION_SUMMARY_VIEW_LOCATION_LINE_1: null,
    LOCATION_SUMMARY_VIEW_LOCATION_LINE_2: null,
    LOCATION_SUMMARY_VIEW_LOCATION_LINE_3: null,
    LOCATION_SUMMARY_VIEW_LOCATION_LINE_4: null,
    LOCATION_SUMMARY_VIEW_SUBPREMISE: null,
    LOCATION_SUMMARY_VIEW_VERIFIED: null,

    // Name Summary View
    NAME_SUMMARY_VIEW_ADD_NO_INFO_KNOWN: null,
    NAME_SUMMARY_VIEW_ADD_PERSON: null,
    NAME_SUMMARY_VIEW_ADD_ORGANIZATION: null,
    NAME_SUMMARY_VIEW_ADD_VEHICLE: null,
    // This applies to all of "Public Society", "U.S. Marhsals", "Superior Court", etc.
    NAME_SUMMARY_VIEW_ADD_SOCIETY_PROFILE: null,
    NAME_SUMMARY_VIEW_EDIT_NAME: null,
    NAME_SUMMARY_VIEW_REMOVE_NAME: null,
    // NItems
    NITEMS_ADD_ITEM: null,
    NITEMS_REMOVE_ITEM: null,

    // Connect Location View
    CONNECT_LOCATION_VIEW: null,
    LOCATION_SIDE_PANEL_ADD_COORDINATES: null,
    LOCATION_SIDE_PANEL_ADD_CROSS_STREETS: null,
    LOCATION_SIDE_PANEL_BACK_FORM: null,
    LOCATION_SIDE_PANEL_BACK_SEARCH_RESULTS: null,
    LOCATION_SIDE_PANEL_EDIT_LOCATION: null,
    LOCATION_SIDE_PANEL_LOCATION_RESULT: null,
    LOCATION_SIDE_PANEL_NEW_LOCATION: null,
    LOCATION_SIDE_PANEL_SEARCH: null,
    LOCATION_SIDE_PANEL_TYPE_ADDRESS: null,
    LOCATION_SIDE_PANEL_TYPE_INTERSECTION: null,

    // Person/Org Side Panel
    NAME_SIDE_PANEL_ADD_NEW_NAME: null,
    NAME_SIDE_PANEL_ADD_UNKNOWN_PERSON: null,
    NAME_SIDE_PANEL_BACK_FORM: null,
    NAME_SIDE_PANEL_BACK_SEARCH_RESULTS: null,
    NAME_SIDE_PANEL_FORM_WRAPPER: null,
    NAME_SIDE_PANEL_MORE_RESULTS: null,
    NAME_SIDE_PANEL_RECENT_ORGANIZATION: null,
    NAME_SIDE_PANEL_RECENT_PERSON: null,
    NAME_SIDE_PANEL_RESULTS_WRAPPER: null,
    NAME_SIDE_PANEL_SEARCH_BUTTON: null,
    NAME_SIDE_PANEL_SEARCH_RESULT_ORGANIZATION: null,
    NAME_SIDE_PANEL_SEARCH_RESULT_PERSON: null,
    NAME_SIDE_PANEL_SEARCH_WRAPPER: null,
    NAME_SIDE_PANEL_UNKNOWN_FORM_WRAPPER: null,
    ORGANIZATION_SIDE_PANEL: null,

    // Person Side Panel
    PERSON_SIDE_PANEL: null,
    PERSON_SIDE_PANEL_ADD_SUFFIX: null,
    PERSON_SIDE_PANEL_ADD_NOTES: null,
    PERSON_SIDE_PANEL_ADD_HEIGHT_RANGE: null,
    PERSON_SIDE_PANEL_ADD_WEIGHT_RANGE: null,
    PERSON_SIDE_PANEL_REMOVE_HEIGHT_RANGE: null,
    PERSON_SIDE_PANEL_REMOVE_WEIGHT_RANGE: null,
    PERSON_SIDE_PANEL_ADD_PHYSICAL_DESCRIPTORS: null,
    PERSON_PROFILE_EVENT_INFO_CONTAINER: null,
    PERSON_PROFILE_CAUTIONS_CONTAINER: null,
    PERSON_PROFILE_IDENTIFYING_MARKS: null,
    PERSON_PROFILE_ADD_PHONE: null,
    PERSON_PROFILE_ADD_EMAIL: null,
    PERSON_PROFILE_ADD_EMPLOYMENT_INFO: null,
    PERSON_PROFILE_ADD_SCHOOL_INFO: null,
    PERSON_PROFILE_ADD_EMERGENCY_CONTACT: null,
    PERSON_PROFILE_ADD_INJURY: null,
    PERSON_PROFILE_ADD_PHYSICIAN: null,
    PERSON_PROFILE_ADD_CAUTION: null,
    PERSON_PROFILE_CREATE_NEW_BUTTON: null,
    PHONE_NUMBER_COUNTRY_CODE_SELECT: null,
    ENTITY_SUMMARY_TOP: null,
    PERSON_GANG_CRITERIA_HELP_TEXT: null,
    PERSON_PROFILE_CAUTION_ITEM_TYPE: null,
    PERSON_PROFILE_CAUTION_ITEM_TYPE_OTHER: null,
    SEARCH_FROM_DEX: null,

    // Gang Tracking Side Panel
    GANG_TRACKING_SIDE_PANEL_ENTITY: null,
    ADD_SUSPECTED_GANG_AFFILIATION: null,

    // Mugshot Widget
    MUGSHOT_WIDGET_VIEW: null,
    MUGSHOT_WIDGET_VIEW_DELETE: null,
    MUGSHOT_WIDGET_VIEW_FILE_UPLOAD: null,
    // Identifying Marks Widget
    IDENTIFYING_MARKS_WIDGET_VIEW: null,
    IDENTIFYING_MARKS_WIDGET_VIEW_ATTACH_IMAGE: null,
    IDENTIFYING_MARKS_WIDGET_VIEW_ATTACH_IMAGE_INPUT: null,
    IDENTIFYING_MARKS_WIDGET_VIEW_DELETE: null,
    IDENTIFYING_MARKS_WIDGET_WRAPPER_ADD: null,
    // School History Widget
    SCHOOL_HISTORY_WIDGET_VIEW: null,
    SCHOOL_HISTORY_WIDGET_VIEW_DELETE: null,
    SCHOOL_HISTORY_WIDGET_VIEW_WRAPPER_ADD: null,

    // Report View
    REPORT_CARDS: null,
    REPORT_SIDEBAR: null,
    LINKED_REPORTS_SECTION: null,
    LINKED_WARRANTS: null,
    LINKED_E_FILES: null,
    LINKED_E_FILES_DETAILS: null,
    LINKED_E_FILES_LINK: null,

    // Item Entry
    ITEM_CARD_MANAGE_ITEMS_BUTTON: null,
    ITEM_TYPE_BUTTONS: null,
    MANAGE_ITEMS_SIDE_PANEL: null,
    MANAGE_ITEMS_SIDE_PANEL_CHECKBOX_ALL: null,
    MANAGE_ITEMS_SIDE_PANEL_CHECKBOX: null,
    ITEM_SIDE_PANEL: null,
    ITEM_SIDE_PANEL_ASSOCIATED_NAMES_SECTION: null,
    ITEM_SIDE_PANEL_PROPERTY_STATUSES_SECTION: null,
    ITEM_SIDE_PANEL_IMPOUND_INFO_SECTION: null,
    ITEM_SIDE_PANEL_CUSTODY_STATUS_SECTION: null,
    ITEM_SIDE_PANEL_RECOVERY_INFO_SECTION: null,
    ITEM_SIDE_PANEL_ADD_LOCATIONS_BUTTON: null,
    ITEM_PROFILE_LINK: null,
    RECENT_ITEMS_BUTTON: null,
    PROPERTY_DESCRIPTION: null,
    ADD_FIREARM_BUTTON: null,
    ADD_DRUGS_BUTTON: null,
    ADD_ITEM_BUTTON: null,

    // Report Narrative
    NARRATIVE_INPUT: null,
    NARRATIVE_SUMMARY: null,
    NARRATIVE_GUIDE_BUTTON: null,
    NARRATIVE_GUIDE_SELECT: null,

    // Form Controls
    TEXT_INPUT: null,
    TEXTAREA_INPUT: null,
    SELECT_OPEN: null,
    SELECT_ARROW: null,
    SELECT_INPUT: null,
    SELECT_OPTION: null,
    SELECT_CLEAR: null,
    SELECT_REMOVE_ITEM: null,
    SELECT_DISPLAY: null,
    SELECT_OPTION_LIST: null,
    DATE_PICKER_DATE_INPUT: null,
    DATE_PICKER_TIME_INPUT: null,
    FORM_ELEMENT_ARC_DATEPICKER: null,
    CHECKBOX_INPUT: null,
    CHECKBOX_TREE_INPUT: null,
    RADIO_INPUT: null,
    INPUT_ERROR_MESSAGE: null,
    UPLOAD: null,
    UPLOAD_DROPZONE: null,
    BUTTON_RADIO_BUTTON: null,
    FORM_ELEMENT_REQUIRED_INDICATOR: null,
    NUMBER_INPUT: null,
    SWITCH_INPUT: null,

    // Navigation - Quick Search
    QUICK_SEARCH_BUTTON: null,
    QUICK_SEARCH_TEXT_INPUT: null,
    QUICK_SEARCH_RESULT_LINK: null,
    QUICK_SEARCH_ADVANCED_SEARCH_TYPES_BUTTON: null,
    QUICK_SEARCH_ADVANCED_SEARCH_TYPES_OPTION: null, // used as scope, element has an elastic search type test id on it as well
    QUICK_SEARCH_ADVANCED_SEARCH_TYPES_MENU_WRAPPER: null,
    QUICK_SEARCH_NOTIFICATION_NO_RESULTS: null,
    QUICK_SEARCH_NOTIFICATION_QUERY_TOO_LONG: null,
    QUICK_SEARCH_NOTIFICATION_SEARCHING: null,
    QUICK_SEARCH_NOTIFICATION_ERROR: null,
    QUICK_SEARCH_RESULT: null, // used as scope, element has an entity type test id on it as well
    QUICKSEARCH_RESULT_SECTION: null,
    QUICK_SEARCH_LOAD_MORE: null, // used as scope, element has an entity type test id on it as well

    // Navigation - Modules
    MODULE_DASHBOARD: null,
    MODULE_REPORTS: null,
    MODULE_REPORT_MODULE: null,
    MODULE_CASES: null,
    MODULE_EVIDENCE: null,
    MODULE_WARRANTS: null,
    MODULE_ANALYSIS: null,
    MODULE_NOTIFICATIONS: null,
    MODULE_MOBILE: null,
    MODULE_TASKS: null,
    FEDERATED_SEARCH: null,

    // Navigation - Initials Dropdown
    INITIALS_DROPDOWN_BUTTON: null,
    INITIALS_DROPDOWN_ACCOUNT_SETTINGS: null,
    INITIALS_DROPDOWN_ADMIN_SETTINGS: null,
    INITIALS_DROPDOWN_COMPLIANCE_DASHBOARD: null,
    INITIALS_DROPDOWN_NIBRS_WORKSPACE: null,
    INITIALS_DROPDOWN_MANAGE_ALERTS: null,
    INITIALS_DROPDOWN_LOGOUT: null,
    INITIALS_DROPDOWN_OPEN_CAD: null,

    // Reports Dashboard
    NEW_REPORT_BUTTON: null,
    REN_SEARCH_TEXT: null,
    NEW_REPORT_OPTION: null,
    REPORTS_DASHBOARD_FILTERS_TOGGLE_BUTTON: null,
    REPORTS_DASHBOARD_SAVE_SEARCH_BUTTON: null,
    REPORTS_DASHBOARD_CLEAR_FILTER_BUTTON: null,

    // Event Info Card
    EVENT_INFO_ASSISTING_PERSONNEL_SECTION: null,
    EVENT_INFO_PARAMEDICS_SECTION: null,
    EVENT_INFO_NOTIFICATIONS_SECTION: null,
    EVENT_CARD_SUMMARY_CASE_STATUS_SECTION: null,
    EVENT_CARD_SUMMARY_NOTIFICATIONS_SECTION: null,
    EVENT_CARD_SUMMARY_REPORTING_PARTY_SECTION: null,

    // Evidence
    COPY_DATA_BUTTON: null,
    CUSTODIAL_PROPERTY_CARD: null,
    CUSTODIAL_PROPERTY_CARD_ITEM: null,
    CUSTODIAL_PROPERTY_CARD_ITEM_EDIT_BUTTON: null,
    CUSTODIAL_PROPERTY_CARD_TOGGLE_BUTTON: null,
    CUSTODIAL_PROPERTY_CHECKBOX: null,
    CUSTODIAL_PROPERTY_CHECKBOX_ALL: null,
    CUSTODIAL_PROPERTY_ADD_TO_QUEUE: null,
    CPS_ACTION_BAR_PRINT_LABELS: null,
    CPS_ACTION_BAR_EXPORT: null,
    CPS_ACTION_BAR_UPDATE_DISPOSITION_MENU: null,
    CPS_ACTION_BAR_REQUEST_DISPOSITION: null,
    CPS_ACTION_BAR_PRIMARY_APPROVE_RELEASE: null,
    CPS_ACTION_BAR_PRIMARY_APPROVE_DISPOSITION: null,
    CPS_ACTION_BAR_HOLD: null,
    CPS_ACTION_BAR_SECONDARY_APPROVE_RELEASE: null,
    CPS_ACTION_BAR_SECONDARY_APPROVE_DISPOSITION: null,
    CPS_ACTION_BAR_SECONDARY_REJECT: null,
    CPS_ACTION_BAR_MORE_MENU: null,
    CPS_ACTION_BAR_RESET_RETENTION_POLICY: null,
    CPS_ACTION_BAR_ADD_REMARKS: null,
    CPS_ACTION_BAR_SPLIT_ITEM: null,
    DISPOSITION_REVIEW_CARD_PRIMARY_APPROVE_RELEASE: null,
    DISPOSITION_REVIEW_CARD_PRIMARY_APPROVE_DISPOSITION: null,
    DISPOSITION_REVIEW_CARD_HOLD: null,
    DISPOSITION_REVIEW_CARD_SECONDARY_APPROVE_RELEASE: null,
    DISPOSITION_REVIEW_CARD_SECONDARY_APPROVE_DISPOSITION: null,
    DISPOSITION_REVIEW_CARD_SECONDARY_REJECT: null,
    DISPOSITION_HISTORY: null,
    DOUBLE_SIDE_PANEL_RIGHT_CONTENT: null,
    EVIDENCE_ITEM_MENU: null,
    EVIDENCE_ITEM_MENU_ADD_TO_QUEUE: null,
    EVIDENCE_ITEM_MENU_EXPORT: null,
    EVIDENCE_ITEM_MENU_PRINT_LABEL: null,
    EVIDENCE_ITEM_MENU_REQUEST_DISPOSITION: null,
    DOCUMENT_EXPORTING_MODAL: null,
    SIGNATURE_PAD_UPLOAD: null,
    CHAIN_EVENT_ID_SCAN_UPLOAD: null,
    CHAIN_EVENT_ATTACHMENT_UPLOAD: null,
    EVIDENCE_DASHBOARD_FILTER_FORM: null,
    EVIDENCE_DASHBOARD_FILTER_FORM_APPLY_FILTER: null,
    EVIDENCE_DASHBOARD_FILTER_FORM_CLEAR_FILTER: null,
    EVIDENCE_DASHBOARD_ACTION_BAR: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_ADD_TO_QUEUE: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_PRINT_LABELS: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_NEW_TASK: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_UPDATE_DISPOSITION_MENU: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_REQUEST_DISPOSITION: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_PRIMARY_APPROVE_RELEASE: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_PRIMARY_APPROVE_DISPOSITION: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_HOLD: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_SECONDARY_APPROVE_RELEASE: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_SECONDARY_APPROVE_DISPOSITION: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_SECONDARY_REJECT: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_RESET_RETENTION_POLICY: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_EXPORT: null,
    EVIDENCE_DASHBOARD_ACTION_BAR_SELECT_ALL_RESULTS: null,
    EVIDENCE_DASHBOARD_EXPORT_TYPE: null,
    EVIDENCE_DASHBOARD_EXPORT_CSV_OPTIONS: null,
    EVIDENCE_DASHBOARD_EXPORT_PDF_OPTIONS: null,
    EVIDENCE_DASHBOARD_EXPORT_DOWNLOAD: null,
    EVIDENCE_DASHBOARD_EXPORT_CANCEL: null,
    EVIDENCE_DASHBOARD_REN_LINK: null,
    EVIDENCE_DASHBOARD_SEARCH_RESULTS_ACTION_BAR: null,
    EVIDENCE_DASHBOARD_SEARCH_RESULTS_ACTION_BAR_SELECT: null,
    INVENTORIES_DASHBOARD: null,
    INVENTORIES_AND_AUDITS_TAB: null,
    ITEM_SPLIT_SUMMARY_CONTAINER: null,
    LABEL_PRINTING_MODAL: null,
    OWNER_CLAIMANT_PERSON_BUTTON: null,
    ENTITY_PROFILE_ADD_STAFF_REMARK: null,
    ENTITY_PROFILE_REN_LINK: null,
    ENTITY_PROFILE_STAFF_REMARK: null,
    STAFF_REMARK_CARD: null,
    STAFF_REMARK_CONTAINER: null,
    STAFF_REMARK_NOTICE: null,
    REMARKS_ACCORDION_HEADER: null,

    // Evidence dashboard search
    EVIDENCE_DASHBOARD_SEARCH_TEXT_INPUT: null,
    EVIDENCE_DASHBOARD_SEARCH_BUTTON: null,
    // Evidence Dashboard filter
    EVIDENCE_DASHBOARD_FILTER_BUTTON: null,
    EVIDENCE_DASHBOARD_FILTER_SUMMARY: null,
    EVIDENCE_DASHBOARD_FILTER_DATE_ACQUIRED: null,
    EVIDENCE_DASHBOARD_FILTER_LATEST_UPDATE: null,
    EVIDENCE_DASHBOARD_FILTER_STORAGE_LOCATION: null,
    EVIDENCE_DASHBOARD_FILTER_EXCLUDE_TEMPORARY_LOCATION: null,
    EVIDENCE_DASHBOARD_FILTER_PRIMARY_REPORTING_OFFICERS: null,
    EVIDENCE_DASHBOARD_FILTER_RECOVERING_OFFICERS: null,
    EVIDENCE_DASHBOARD_FILTER_REN: null,
    EVIDENCE_DASHBOARD_FILTER_CHAIN_OF_CUSTODY_STATUS: null,
    EVIDENCE_DASHBOARD_FILTER_DISPOSITION_STATUS: null,
    EVIDENCE_DASHBOARD_FILTER_PROPERTY_TYPE: null,
    EVIDENCE_DASHBOARD_FILTER_PROPERTY_CATEGORY: null,
    EVIDENCE_DASHBOARD_FILTER_IN_POLICE_CUSTODY_STATUS: null,
    EVIDENCE_DASHBOARD_FILTER_REASON_FOR_POLICE_CUSTODY: null,
    EVIDENCE_DASHBOARD_FILTER_IS_OVERDUE: null,
    EVIDENCE_DASHBOARD_FILTER_EXCLUDE_EXPIRE_STATUSES: null,
    EVIDENCE_DASHBOARD_FILTER_REN_CREATION_DATE: null,
    EVIDENCE_DASHBOARD_FILTER_AGENCY: null,
    EVIDENCE_DASHBOARD_FILTER_EXCLUDE_EXPIRED_LOCATION: null,
    EVIDENCE_DASHBOARD_FILTER_ITEM_IDS: null,
    EVIDENCE_DASHBOARD_FILTER_IDENTIFIER_TYPES: null,
    EVIDENCE_DASHBOARD_FILTER_SERIAL_NUMBER: null,

    // Evidence basket
    EVIDENCE_BASKET_CLEAR_ALL: null,
    EVIDENCE_BASKET_ITEM_INFO: null,
    EVIDENCE_BASKET_NO_ITEMS: null,
    EVIDENCE_ITEM_BASKET: null,
    EVIDENCE_ITEM_QUEUE_TABLE: null,
    VIEW_ITEM_QUEUE: null,

    // Admin Pages
    ADMIN_LIST: null,
    ADMIN_LIST_ITEM: null,
    EVIDENCE_FACILITIES_ADMIN_ADD_STORAGE_LOCATION_BUTTON: null,
    EVIDENCE_FACILITIES_ADMIN_PRINT_ALL_LABELS: null,
    EVIDENCE_FACILITIES_ADMIN_PRINT_LABEL: null,
    EVIDENCE_FACILITIES_ADMIN_STORAGE_LOCATION_IS_TEMPORARY: null,
    STORAGE_ADMIN_SIDE_PANEL: null,
    STORAGE_LOCATION_VIEW_DISPLAY_VALUE: null,
    ADMIN_FOOTER_SAVE: null,
    ADMIN_DELETE_BUTTON: null,
    ADMIN_NEW_BUTTON: null,
    ADMIN_OPEN_TEST_HANDLEBARS_MODAL_BUTTON: null,
    ADMIN_HEADER: null,
    ADMIN_FORM_SECTION_HEADER: null,
    ADMIN_FORM_SECTION_HEADER_REMOVE: null,
    ADMIN_DEFAULT_USER_PERMISSIONS_SECTION: null,
    ADMIN_FOOTER: null,
    ADMIN_DEPARTMENT_PROFILE_EDIT_BUTTON: null,
    ADMIN_DEPARTMENT_PROFILE_SUMMARY: null,
    ADMIN_FILLABLE_PDF_TITLE: null,
    ADMIN_FILLABLE_PDF_REPORT_TYPE: null,
    ADMIN_SSO_CONFIGURATION_INITIAL_SETUP_CONNECTION: null,
    ADMIN_SSO_CONFIGURATION_INITIAL_SETUP_MARK43: null,
    ADMIN_SSO_CONFIGURATION_INITIAL_SETUP_SAVE: null,
    ADMIN_SSO_CONFIGURATION_DELETE: null,
    ADMIN_REPORT_TYPE_CARD_LINK: null,
    ADMIN_REPORT_TYPE_CARD_UI_NAME: null,
    ADMIN_RULES_IS_ENABLED: null,
    ADMIN_WARRANT_NUMBER_CONFIGURATION_SELECT: null,
    CREATE_NEW_PERMISSIONS_CANCEL_BUTTON: null,
    PERMISSIONS_LOCK_BUTTON: null,
    PREVIEW_SEQUENCE: null,
    USAGE_LOGS_EXPORT_BUTTON: null,
    VIEW_PARENT_PERMISSIONS_BUTTON: null,
    ADMIN_SEARCH_BAR: null,
    ADMIN_SEARCH_ICON: null,
    RED_ERROR_MESSAGE: null,
    ADMIN_DEFAULT_USER_ROLE_ATTR_ID: null,
    ADMIN_DEFAULT_USER_ROLE_ACCESS_LEVEL_ATTR_ID: null,
    ADMIN_PROJECT_VERSION_FIELD: null,
    ADMIN_USER_ACCOUNT_EDIT_BUTTON: null,

    // Attributes Admin Page
    ATTRIBUTES_ADMIN_FORM_TYPE_SELECT: null,
    ATTRIBUTES_ADMIN_FORM_PARENT_TYPE_SELECT: null,
    ATTRIBUTES_ADMIN_FORM_LINK_TYPE_SELECT: null,
    ATTRIBUTES_ADMIN_FORM_IS_OTHER_CHECKBOX: null,
    ATTRIBUTES_ADMIN_FORM_IS_NONE_CHECKBOX: null,
    ATTRIBUTES_ADMIN_FORM_IS_DEFAULT_CHECKBOX: null,
    ATTRIBUTES_ADMIN_FORM_IS_ENABLED_CHECKBOX: null,
    ATTRIBUTES_ADMIN_FORM_IS_PROTECTED_CHECKBOX: null,

    // Export Presets Admin Page
    EXPORT_PRESETS_ADMIN_ALL_REPORT_TYPES_BUTTON: null,
    EXPORT_PRESETS_ADMIN_SOME_REPORT_TYPES_BUTTON: null,
    EXPORT_PRESETS_ADMIN_REPORT_TYPE_SELECT: null,
    EXPORT_PRESETS_ADMIN_EXPORT_OPTION_FIELDS_WITH_DATA: null,
    EXPORT_PRESETS_ADMIN_EXPORT_OPTION_ADDENUM: null,
    EXPORT_PRESETS_ADMIN_EXPORT_OPTION_HISTORY: null,
    EXPORT_PRESETS_ADMIN_EXPORT_OPTION_CONFIDENTIAL: null,
    EXPORT_PRESETS_ADMIN_EXPORT_OPTION_ATTACHMENTS: null,

    // Disclaimer Messages Admin Page
    DISCLAIMER_MESSAGES_ADMIN_ADD_NEW_BUTTON: null,
    DISCLAIMER_MESSAGES_ADMIN_REMOVE_BUTTON: null,

    // Department edit form
    DEPARTMENT_EDIT_COUNTRY_SELECTOR: null,
    DEPARTMENT_EDIT_ADD_LOCATION: null,
    DEPARTMENT_EDIT_REMOVE_LOCATION: null,
    DEPARTMENT_EDIT_CENTER_LATITUDE_INPUT: null,
    DEPARTMENT_EDIT_CENTER_LONGITUDE_INPUT: null,
    DEPARTMENT_EDIT_SW_LATITUDE_INPUT: null,
    DEPARTMENT_EDIT_SW_LONGITUDE_INPUT: null,
    DEPARTMENT_EDIT_NE_LATITUDE_INPUT: null,
    DEPARTMENT_EDIT_NE_LONGITUDE_INPUT: null,
    DEPARTMENT_EDIT_COMPLIANCE_GROUP_SELECT: null,
    DEPARTMENT_EDIT_UCR_NIBRS_REGIONAL_GROUP_SELECT: null,
    DEPARTMENT_EDIT_DEX_REGION_SELECT: null,

    // Case Types Admin Page
    ADMIN_ADD_CASE_TYPE_BUTTON: null,
    ADMIN_DEFAULT_FOLDER_SECTION: null,
    CASE_TYPE_ABBREVIATION: null,
    CASE_TYPE_ACTIVE_DATE_UTC: null,
    CASE_TYPE_ADD_SUPERVISOR_BUTTON: null,
    CASE_TYPE_ADD_PERSONNEL_BUTTON: null,
    CASE_TYPE_DEFAULT_FOLDER: null,
    CASE_TYPE_DEFAULT_SUBFOLDER: null,
    CASE_TYPE_DEFAULT_STATUS_ATTR_ID: null,
    CASE_TYPE_DEFAULT_SUPERVISOR_ATTR_ID: null,
    CASE_TYPE_DEFAULT_TASK_ASSIGNEE_ATTR_ID: null,
    CASE_TYPE_DEFAULT_TASK_DAYS_UNTIL_DUE: null,
    CASE_TYPE_DEFAULT_TASK_DESCRIPTION: null,
    CASE_TYPE_DEFAULT_TASK_TITLE: null,
    CASE_TYPE_DEFAULT_TASK_TYPE: null,
    CASE_TYPE_DEFAULT_UNIT_ASSIGNED_ATTR_ID: null,
    CASE_TYPE_DEFAULT_USER_ROLE_ATTR_ID: null,
    CASE_TYPE_DURATION: null,
    CASE_TYPE_EXPIRATION_DATE_UTC: null,
    CASE_TYPE_NAME: null,
    CASE_TYPE_NEW_TASK_BUTTON: null,

    // Entity Search
    ENTITY_SEARCH_ADD_ITEM: null,

    // Vehicle inline search
    VEHICLE_INLINE_SEARCH: null,

    // Task dashboard
    NEW_TASK_BUTTON: null,
    TASKS_DASHBOARD_FILTER_BUTTON: null,
    TASKS_DASHBOARD_APPLY_FILTER_BUTTON: null,
    TASKS_DASHBOARD_FILTERS: null,
    TASKS_DASHBOARD_FILTER_ASSIGNEE: null,
    TASKS_DASHBOARD_FILTER_CREATOR: null,
    TASKS_DASHBOARD_FILTER_IS_OVERDUE: null,
    TASKS_DASHBOARD_SEARCH_CONTAINER: null,
    TASKS_DASHBOARD_FILTER_DATE_TYPE: null,
    TASKS_DASHBOARD_FILTER_DATE_RANGE: null,
    TASK_CREATOR: null,
    TASK_HEADER_CHECKBOX: null,
    TASK_TABLE_BODY_CHECKBOX: null,
    TASK_TABLE: null,
    TASK_TABLE_DUE_DATE_COLUMN: null,
    TASK_TABLE_TITLE_COLUMN: null,
    TASK_TABLE_ROW: null,

    // Tasks
    ADD_LINKED_ITEMS_BUTTON: null,
    ADD_UPDATE_BUTTON: null,
    TASK_COMMENT: null,
    TASK_COMMENT_HISTORY: null,
    TASK_DELETE_OPTION: null,
    TASK_EDIT_OPTION: null,
    TASK_HISTORY_SECTION: null,
    TASK_LINKED_ITEMS_SECTION: null,
    TASK_OPTIONS_MENU: null,
    TASK_STATUS_UPDATE_MENU_BUTTON: null,
    TASK_STATUS_UPDATE_MENU: null,
    TASK_STATUS_UPDATE_OPTION: null,
    TASK_SIDEPANEL: null,

    // Report Status Comments Card
    REPORT_STATUS_COMMENTS_CARD_VALIDATE_REPORT_BUTTON: null,
    REPORT_STATUS_COMMENTS_CARD_VALIDATE_ONLY_ORIGINAL_REPORT_BUTTON: null,
    REPORT_STATUS_COMMENTS_CARD_SUBMIT_REPORT_BUTTON: null,
    REPORT_STATUS_COMMENTS_CARD_COMMENT_BUTTON: null,
    REPORT_STATUS_COMMENTS_CARD_NIBRS_VIEW_NIBRS_DETAILS_BUTTON: null,
    REPORT_STATUS_COMMENTS_CARD_NIBRS_RUN_ERROR_CHECK_BUTTON: null,

    // Personal Dashboard
    PERSONAL_DASHBOARD_SETTINGS_SIDEPANEL: null,
    PERSONAL_DASHBOARD_SETTINGS_BUTTON: null,
    PERSONAL_DASHBOARD_ACTION_REQUIRED_REPORTS_SECTION: null,
    PERSONAL_DASHBOARD_MY_CASES_SECTION: null,
    PERSONAL_DASHBOARD_TARGET_PROFILES_SECTION: null,
    PERSONAL_DASHBOARD_TARGET_PROFILES_FILTERS: null,

    // Target Profile
    TARGET_PROFILE_AREA_INPUT: null,
    TARGET_PROFILE_CATEGORY_INPUT: null,
    TARGET_PROFILE_FILTER_SUBMIT_BUTTON: null,
    TARGET_PROFILE_SNAPSHOT_MODAL: null,
    TARGET_PROFILE_SNAPSHOT_MODAL_DETAILS: null,
    TARGET_PROFILE_SNAPSHOT_MODAL_NARRATIVE: null,

    // Record Privacy: Sealing
    SEAL_PERSON_BUTTON: null,
    SEAL_CHARGE_BUTTON: null,
    SEAL_BUTTON: null,
    SEAL_CONTENTS_BUTTON: null,
    SEALED_REPORT: null,
    SEALED_STAMP: null,
    UNSEAL_BUTTON: null,
    COURT_CASE_SELECT: null,
    COURT_ORDER_INFORMATION: null,
    COURT_ORDERS_INFORMATION_SECTION: null,
    COURT_ORDERS_INFORMATION_EDIT: null,

    // Record Privacy: Vacating
    VACATE_CHARGE_BUTTON: null,
    VACATE_CHARGE_SAVE_BUTTON: null,
    VACATE_CHARGE_ROW: null,
    RESTRICTED_BANNER_TEXT: null,

    // Saved Search
    SAVED_SEARCH_BUTTON: null,
    SAVED_SEARCH_ROW: null,
    SAVED_SEARCH_MORE_OPTIONS_MENU: null,
    SAVED_SEARCH_COPY_OPTION: null,
    SAVED_SEARCH_SUBSCRIBE_OPTION: null,
    SAVED_SEARCH_EDIT_OPTION: null,
    SAVED_SEARCH_DELETE_OPTION: null,
    SAVED_SEARCH_SHARE_OPTION: null,
    SAVED_SEARCH_SUMMARY_NAME: null,
    SAVED_SEARCH_RENAME_FORM: null,
    SAVED_SEARCH_OPEN_EDIT_MODAL_BUTTON: null,
    SAVED_SEARCH_DROPDOWN_BUTTON: null,
    SAVED_SEARCH_UPDATE_OPTION: null,
    SAVED_SEARCH_SAVE_NEW_SEARCH_OPTION: null,

    // Advanced Search
    ADVANCED_SAVE_SEARCH_BUTTON: null,
    ADVANCED_SEARCH_CLEAR_BUTTON: null,
    ADVANCED_SEARCH_DASHBOARD_ACTION_BAR: null,
    ADVANCED_SEARCH__DASHBOARD_ACTION_BAR_CLEAR_ALL_RESULTS: null,
    ADVANCED_SEARCH__DASHBOARD_ACTION_BAR_SELECT_ALL_RESULTS: null,
    ADVANCED_SEARCH_EXPORT: null,
    ADVANCED_SEARCH_EXPORT_BUTTON: null,
    ADVANCED_SEARCH_FILTERS_SECTION: null,
    ADVANCED_SEARCH_LOCATION_STREET_ADDRESS: null,
    ADVANCED_SEARCH_LOCATION_SUB_PREMISE: null,
    ADVANCED_SEARCH_PERSON_PROFILE_AGE_RANGE_SLIDER_KNOB: null,
    ADVANCED_SEARCH_PERSON_PROFILE_DOB: null,
    ADVANCED_SEARCH_PERSON_PROFILE_NICKNAME: null,
    ADVANCED_SEARCH_PERSON_PROFILE_PHONE_NUMBER: null,
    ADVANCED_SEARCH_PERSON_PROFILE_SEX: null,
    ADVANCED_SEARCH_SHOW_FILTER_TOGGLE: null,

    // Merge Entities
    INITIATE_MERGE_ENTITIES_BUTTON: null,
    CONFIRM_MERGE_ENTITIES_BUTTON: null,

    // Attachments
    ATTACHMENT_ROW_FILENAME_LINK: null,
    ATTACHMENT_ROW_DESCRIPTION: null,
    ATTACHMENT_ROW_KEYWORD_SEARCH: null,
    DOWNLOADABLES_FILENAME_LINK: null,
    DOWNLOADABLES_FILENAME_DESCRIPTION: null,

    // Confirmation Bar
    CONFIRMATION_BAR: null,

    // Notifications
    NOTIFICATION_SUMMARY: null,
    NOTIFICATIONS_POPOVER: null,
    NOTIFICATIONS_ALERT_CONTENT: null,
    ALERT_BANNER: null,
    ALERT_BANNER_ACKNOWLEDGE: null,
    ALERT_BANNER_ATTACHMENT: null,
    ALERT_BANNER_SHOW_MORE: null,
    ALERT_BANNER_TOGGLE: null,

    // Number Bubble
    // Updated this to match the new ARC one so the E2E tests still run the same when the feature flag is on or off.
    'arc-nav-item-counter': null,

    // Associated Records
    ASSOCIATED_RECORDS_SECTION: null,
    ADD_EDIT_ASSOCIATED_RECORDS: null,
    ENTITY_SEARCH_RESULT: null,
    ENTITY_SEARCH_BAR: null,
    ASSOCIATED_RECORDS_SIDE_PANEL: null,
    ASSOCIATED_RECORD_LINK_CONTAINER: null,
    REPORT_REPORT_LINK: null,
    REPORT_EXTERNAL_LINK_URL_LINK: null,
    REPORT_LINK_WRAPPER: null,

    // Cases
    ACTION_BAR_CREATE_CASE_BUTTON: null,
    ACTION_BAR_BULK_MANAGE_CASE_BUTTON: null,
    ACTION_BAR_MANAGE_CASE_BUTTON: null,
    ACTION_BAR_WILL_NOT_INVESTIGATE_BUTTON: null,
    ASSIGN_CASE_TO_ME_BUTTON: null,
    BATCH_BANNER: null,
    BULK_MANAGE_CASES_SIDEPANEL: null,
    CASE_ATTACHMENTS: null,
    CASE_ADD_ATTACHMENT: null,
    CASE_ADD_LINKED_PROFILE: null,
    CASE_LINKED_PROFILES_CASE_FROM_REPORT_TOOLTIP: null,
    CASE_ASSOCIATED_RECORD_TITLE: null,
    CASE_DETAILS_SECTION: null,
    CASE_TARGET_PROFILE_DETAILS_SECTION: null,
    CASE_EDIT_BUTTON: null,
    CASE_E_FILES: null,
    CASE_CREATE_E_FILE: null,
    CASE_LINKED_PROFILES: null,
    CASE_LINKED_PROFILES_SIDE_PANEL: null,
    CASE_LINKED_REN: null,
    CASE_PERMISSIONS_BUTTON: null,
    CASE_REVIEW_BUTTON: null,
    CASE_REVIEW_COMMENT_BUTTON: null,
    CASE_REVIEW_APPROVE_BUTTON: null,
    CASE_REQUEST_APPROVAL_BUTTON: null,
    CASE_REVIEW_REJECT_BUTTON: null,
    CASE_REVIEW_COMMENTS: null,
    CASE_REVIEW_COMMENT: null,
    CASE_PERMISSIONS_OPTION: null,
    CASE_RELATED_VEHICLES: null,
    CASE_SIDEBAR: null,
    CASE_SIDEBAR_SUMMARY: null,
    CASE_SIDEBAR_ATTACHMENTS: null,
    CASE_SIDEBAR_ATTACHMENTS_EXPAND: null,
    CASE_SIDEBAR_ATTACHMENTS_CREATE_FOLDER: null,
    CASE_SIDEBAR_PROFILES: null,
    CASE_SIDEBAR_PHOTO_LINEUP: null,
    CASE_SIDEBAR_NOTES: null,
    CASE_SIDEBAR_NOTES_EXPAND: null,
    CASE_SIDEBAR_NOTES_CREATE_FOLDER: null,
    CASE_SIDEBAR_TASKS: null,
    CASE_SIDEBAR_E_FILES: null,
    CASE_SIDEBAR_ASSOCIATED_RECORDS: null,
    CASE_TYPE_FILTER: null,
    CREATE_CASE_MODAL_ADD_NEW_CASE_BUTTON: null,
    CREATE_CASE_MODAL_CASE_TYPE_SECTION: null,
    CREATE_CASE_MODAL_LINKED_REPORTS_SECTION: null,
    NEW_CASE_BUTTON: null,
    CASE_NOTES_LIST: null,
    CASE_NOTES_LIST_FOLDER_ROW: null,
    CASE_NOTES_LIST_FOLDER_LINK: null,
    CASES_HEADER_MENU: null,
    CASES_TABLE: null,
    CASE_REMOVE_REN_BUTTON: null,
    CASE_CREATE_INVESTIGATION_BUTTON: null,
    CASE_CANCEL_REN_REMOVAL_MODAL_BUTTON: null,
    CASE_WILL_NOT_INVESTIGATE_BUTTON: null,
    CASE_REMOVE_FROM_CASE_BUTTON: null,

    CASE_NOTE_ADD: null,
    CASE_NOTE_ATTACHMENT_FOOTER: null,
    CASE_NOTE_CONTAINER: null,
    CASE_NOTE_LOCATION_CONTAINER: null,
    CASE_NOTE_MANAGE_ATTACHMENTS: null,
    CASE_NOTE_MANAGE_LOCATIONS: null,
    CASE_NOTE_SAVE: null,
    CASE_NOTE_SUMMARY: null,

    BREADCRUMB_LIST: null,
    BREADCRUMB_LIST_LINK: null,
    BREADCRUMB_MENU: null,
    CASE_ATTACHMENT_DOWNLOAD_ALL_BUTTON: null,
    CASE_ATTACHMENT_ROW: null,
    CASE_ATTACHMENT_ROW_LINK: null,
    CASE_ATTACHMENT_SEARCH_BAR: null,

    // Case Dashboard filter
    CASE_DASHBOARD_APPLY_FILTER_BUTTON: null,
    CASE_DASHBOARD_CASE_STATUS_FILTER: null,
    CASE_DASHBOARD_CASE_TYPE_FILTER: null,
    CASE_DASHBOARD_CLEAR_FILTER_BUTTON: null,
    CASE_DASHBOARD_HAS_LINKED_ARREST_FILTER: null,
    CASE_DASHBOARD_REPORT_APPROVAL_STATUS_FILTER: null,
    CASE_DASHBOARD_REPORT_TYPE_FILTER: null,

    // Case Photo Lineups
    PHOTO_LINEUP_KEBAB_MENU_BUTTON: null,
    PHOTO_LINEUP_KEBAB_MENU_EDIT: null,
    PHOTO_LINEUP_KEBAB_MENU_DELETE: null,
    PHOTO_LINEUP_KEBAB_MENU_EXPORT: null,
    PHOTO_LINEUP_COMPOSE_LINEUP_TITLE: null,
    PHOTO_LINEUP_COMPOSE_SAVE: null,
    PHOTO_LINEUP_COMPOSE_CANCEL: null,
    PHOTO_LINEUP_COMPOSE_SHUFFLE_ORDER: null,
    PHOTO_LINEUP_COMPOSE_UPLOAD_IMAGES_BUTTON: null,
    PHOTO_LINEUP_FILTER_FORM_TOGGLE: null,
    PHOTO_LINEUP_FILTER_FORM_APPLY_FILTERS: null,
    PHOTO_LINEUP_FILTER_FORM: null,
    PHOTO_LINEUP_FILTER_FORM_CLEAR_FILTERS: null,
    PHOTO_LINEUP_SELECTION_AREA_PHOTO: null,
    PHOTO_LINEUP_SELECTION_AREA_PHOTO_CLICK_AREA: null,
    PHOTO_LINEUP_SELECTION_AREA_PHOTO_CHECKBOX: null,
    PHOTO_LINEUP_CREATE_LINEUP_BUTTON: null,
    PHOTO_LINEUP_CREATE_MODAL_SUSPECT: null,
    PHOTO_LINEUP_CREATE_MODAL_POI_SELECT: null,
    PHOTO_LINEUP_CREATE_MODAL_MUGSHOT: null,
    PHOTO_LINEUP_COMPOSE_PAGE: null,
    PHOTO_LINEUP_DETAIL_VIEW: null,
    PHOTO_LINEUP_DETAIL_LINK: null,
    PHOTO_LINEUP_EXPORT_TYPE: null,
    PHOTO_LINEUP_EXPORT_DOWNLOAD_BUTTON: null,
    PHOTO_TOOLTIP_CONTAINER: null,

    // E-files
    E_FILE_SIDEBAR: null,
    E_FILE_SIDEBAR_SUMMARY: null,
    E_FILE_SIDEBAR_DEFENDANTS: null,
    E_FILE_SIDEBAR_MATERIAL: null,
    E_FILE_SIDEBAR_TASKS: null,
    E_FILE_SUMMARY_SECTION: null,
    E_FILE_HAMBURGER_MENU: null,
    E_FILE_MANAGE_DETAILS: null,

    // Defendants
    DEFENDANT_ADD_BUTTON: null,
    DEFENDANT_TILE: null,
    DEFENDANT_ROW_MORE_MENU: null,
    DEFENDANT_ROW_REMOVE: null,
    DEFENDANT_ROW_DEACTIVATE: null,

    // Folders
    FOLDER_NAME: null,
    FOLDER_TREE_WRAPPER: null,
    FOLDER_TREE_EXPAND: null,
    FOLDER_TREE_LINK: null,
    FOLDER_TREE_CREATE: null,
    FOLDER_KEBAB_MENU: null,
    FOLDER_KEBAB_MENU_BUTTON: null,
    FOLDER_KEBAB_MENU_OPTION: null,

    // Advanced Search
    ADVANCED_SEARCH_EVENT_DATE_TIME_RANGE: null,
    ADVANCED_SEARCH_REPORT_REN: null,
    ADVANCED_SEARCH_REPORT_REPORT_OWNER: null,
    ADVANCED_SEARCH_PERSONS_SHOW_ADULTS_CHECKBOX: null,
    ADVANCED_SEARCH_PERSONS_SHOW_JUVENILES_CHECKBOX: null,
    CAD_TICKET_CALL_EVENT_TYPE: null,
    CAD_TICKET_HAS_REPORT: null,
    CAD_TICKET_REN: null,
    CAD_TICKET_REQUIRES_REPORT: null,
    ADVANCED_SEARCH_BOOKING_NUMBER: null,
    ADVANCED_SEARCH_BOOKING_TIME: null,
    ADVANCED_SEARCH_BOOKING_REN_NUMBER: null,
    ADVANCED_SEARCH_BOOKING_FACILITY: null,
    ADVANCED_SEARCH_BOOKING_OFFICER: null,
    ADVANCED_SEARCH_BOOKING_ARRESTING_OFFICER: null,
    ADVANCED_SEARCH_BOOKING_DETAINEE_NAME: null,
    ADVANCED_SEARCH_BOOKING_DETAINEE_LAST_NAME: null,

    // Routing Labels
    ROUTING_LABELS_VIEW_MORE_LABELS: null,
    ROUTING_LABELS_ADDITIONAL_LABEL: null,
    ROUTING_LABELS_NO_LABELS: null,

    // CAD
    LINK_TO_CAD_EVENT: null,

    // Master Entity Page
    MASTER_PROFILE_HEADER: null,
    MASTER_PROFILE_CONTENT: null,
    MASTER_PROFILE_CONTENT_LINK: null,

    // Report Approval Enhancements
    REPORT_APPROVAL_LEVEL_SELECT: null,
    APPROVAL_STATUS_CARD_TITLE: null,
    REPORT_SIDEBAR_CARD_SECTION: null,
    REPORT_SIDEBAR_SECTION_APPROVAL_STATUS: null,
    VALIDATE_REPORT_BUTTON: null,

    // Entity sidebar
    ENTITY_SIDEBAR: null,
    ENTITY_SIDEBAR_LINK: null,
    ENTITY_SIDEBAR_DETAILS_LINK: null,
    ENTITY_SIDEBAR_REPORTS_LINK: null,
    ENTITY_SIDEBAR_BOOKINGS_LINK: null,
    ENTITY_SIDEBAR_LOCATIONS_LINK: null,
    ENTITY_SIDEBAR_RELATIONSHIPS_LINK: null,
    ENTITY_SIDEBAR_PROPERTY_LINK: null,
    ENTITY_SIDEBAR_REMARKS_LINK: null,
    ENTITY_SIDEBAR_VEHICLES_LINK: null,
    ENTITY_SIDEBAR_HISTORY_LINK: null,
    ENTITY_SIDEBAR_WARRANTS_LINK: null,
    ENTITY_SIDEBAR_TASKS_LINK: null,
    ENTITY_SIDEBAR_CASES_LINK: null,
    ENTITY_SIDEBAR_CAUTIONS_LINK: null,
    ENTITY_SIDEBAR_PEOPLE_COLLAPSIBLE_SECTION: null,
    ENTITY_SIDEBAR_ORGANIZATIONS_COLLAPSIBLE_SECTION: null,
    ENTITY_SIDEBAR_LOCATIONS_COLLAPSIBLE_SECTION: null,
    ENTITY_SIDEBAR_VEHICLES_COLLAPSIBLE_SECTION: null,
    ENTITY_SIDEBAR_PROPERTY_COLLAPSIBLE_SECTION: null,
    ENTITY_SIDEBAR_EXPAND_ALL_BUTTON: null,
    ENTITY_SIDEBAR_COLLAPSE_ALL_BUTTON: null,
    ENTITY_SIDEBAR_TABS: null,

    // Entity profile
    ENTITY_PROFILE_IMAGE: null,
    ENTITY_REPORTS_REPORT_TYPES_SELECT: null,
    ENTITY_REPORTS_CLEAR_FILTERS_BUTTON: null,
    ENTITY_PROFILE_CAUTIONS_TABLE: null,

    // Export redactions
    BACK_BUTTON: null,
    EXPORT_REDACTION_BUTTON: null,
    FILLABLE_PDF_MESSAGE_PILL: null,
    HTML_PREVIEW_CONTAINER: null,
    REDACT_AND_EXPORT_BUTTON: null,
    EXPORT_AND_ADD_TO_CURRENT_REPORT: null,
    REDACTION_COLUMN: null,
    REDACTION_FILTERS: null,
    REDACTION_FILTER_ITEM: null,

    // Subheader
    SUBHEADER_BACK_BUTTON: null,
    SUBHEADER_TITLE: null,

    // Manage Alert dashboard
    NEW_ALERT_BUTTON: null,
    DELETE_ALERT_BUTTON: null,

    // Compliance Dashboard
    COMPLIANCE_DASHBOARD_AGENCY_PROFILE_SELECT: null,
    COMPLIANCE_DASHBOARD_DATE_SELECT: null,
    COMPLIANCE_DASHBOARD_GENERATE_ERRORS_BUTTON: null,
    COMPLIANCE_DASHBOARD_GENERATE_REPORTS_BUTTON: null,
    COMPLIANCE_DASHBOARD_EXPORT_BUTTON: null,
    COMPLIANCE_DASHBOARD_EXPORT_OPTIONS: null,
    COMPLIANCE_DASHBOARD_EXPORT_ERRORS_TABLE: null,
    COMPLIANCE_DASHBOARD_REPORT_RESUBMISSIONS_TABLE: null,
    COMPLIANCE_DASHBOARD_GENERATE_ERRORS_SUMMARY: null,
    COMPLIANCE_DASHBOARD_HISTORY_BUTTON: null,
    COMPLIANCE_DASHBOARD_LOAD_HISTORY_BUTTON: null,
    COMPLIANCE_DASHBOARD_HISTORY_TABLE: null,
    COMPLIANCE_DASHBOARD_EXTERNAL_SUBMISSION_USERNAME_PROMPT: null,
    COMPLIANCE_DASHBOARD_EXTERNAL_SUBMISSION_PASSWORD_PROMPT: null,
    COMPLIANCE_DASHBOARD_NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX: null,
    COMPLIANCE_DASHBOARD_COMPLIANCE_EXPORT_NO_DATA_BLOCK: null,

    // Roles Admin Page
    ROLES_ADMIN_PAGE_FILTER_TEXT_BOX: null,
    ROLES_ADMIN_PAGE_TABLE_ROLES_CELL: null,

    // Report History Page
    HISTORY_BLOCK: null,
    HISTORY_SHOW_HIDE_DETAILS: null,
    NO_HISTORY_CONTAINER: null,

    // QuickCrash integration
    QUICKCRASH_EDIT_REPORT: null,

    // Traffic Crash Cards
    TRAFFIC_CRASH_ROADWAY_SUMMARY: null,
    TRAFFIC_CRASH_SUMMARY: null,
    TRAFFIC_CRASH_EVENTS: null,
    TRAFFIC_CRASH_CONDITIONS: null,
    TRAFFIC_CRASH_ROADWAY: null,
    TRAFFIC_CRASH_EXIT_RAMP: null,
    TRAFFIC_CRASH_OTHER_FACTORS: null,

    // CLIPS Page
    CLIPS_CONFIRMATION_BANNER: null,
    CLIPS_CONTENT: null,
    CLIPS_ERROR_MESSAGE: null,
    CLIPS_EVENT_INFO: null,
    CLIPS_FOOTER: null,
    CLIPS_PEOPLE_SECTION: null,
    CLIPS_PROPERTY_SECTION: null,
    CLIPS_REN_SUFFIX: null,
    CLIPS_VEHICLES_SECTION: null,

    // UCR side-panel
    ITEM_FROM_CURRENT_REPORT_BUTTON: null,
    ITEMS_AND_PROPERTY_SECTION: null,
    NEW_ITEM_BUTTON: null,
    PREFILL_BUTTON: null,
    REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EVENT_STATISTICS_ATTRIBUTE_ID: null,

    // Vehicle side panel
    VEHICLE_SIDE_PANEL_SEARCH_RESULT: null,

    // Briefings
    BRIEFING_CREATE_BUTTON: null,
    BRIEFING_TITLE_INPUT: null,
    BRIEFING_EXPIRY_DATE_INPUT: null,
    BRIEFING_ROLE_ROUTINGS_INPUT: null,
    BRIEFING_UNIT_ROUTINGS_INPUT: null,
    BRIEFING_SUBDIVISION_ROUTINGS_INPUT: null,
    BRIEFING_EDITOR: null,

    // Briefings table
    BRIEFING_TABLE: null,
    BRIEFING_OPTIONS_MENU_BUTTON: null,
    BRIEFING_OPTIONS_MENU: null,
    BRIEFING_DUPLICATE_OPTION: null,
    BRIEFING_EDIT_OPTION: null,
    BRIEFING_DELETE_OPTION: null,
    BRIEFING_TABLE_TITLE_LINK: null,

    // Briefing header
    BRIEFING_POST_BUTTON: null,
    BRIEFING_SAVE_BUTTON: null,
    BRIEFING_SAVE_EXIT_MENU_BUTTON: null,
    BRIEFING_EDIT_ROUTINGS_BUTTON: null,
    BRIEFING_ACTIONS_MENU_BUTTON: null,
    BRIEFING_EDIT_ATTACHMENTS_MENU_BUTTON: null,
    BRIEFING_CREATE_TASK_MENU_BUTTON: null,
    BRIEFING_DELETE_MENU_BUTTON: null,
    BRIEFING_MANAGE_PERMISSIONS_BUTTON: null,
    BRIEFING_DUPLICATE_BUTTON: null,
    BRIEFING_EDIT_BRIEFING_BUTTON: null,
    BRIEFING_DELETE_BUTTON: null,
    BRIEFING_SUMMARY_URN: null,
    BRIEFING_SUMMARY_AUTHOR: null,
    BRIEFING_SUMMARY_POSTED_DATE: null,
    BRIEFING_SUMMARY_LOCATION: null,
    BRIEFING_TITLE: null,
    BRIEFING_STATUS_INDICATOR: null,

    // Briefing sidebar
    BRIEFING_SIDEBAR_TABS: null,
    BRIEFING_SIDEBAR_TASKS_TAB: null,
    BRIEFING_SIDEBAR_ATTACHMENTS_TAB: null,
    ENTITY_SIDEBAR_ATTACHMENT_LINK: null,
    ENTITY_SIDEBAR_ATTACHMENT_DESCRIPTION: null,

    // Briefing dashboard card
    BRIEFING_DASHBOARD_CARD: null,
    BRIEFING_DROPDOWN_MENU_BUTTON: null,
    BRIEFING_DROPDOWN_MENU_CONTENT: null,
    BRIEFING_DROPDOWN_MENU_ITEM: null,
    BRIEFING_OPEN_BRIEFING_BUTTON: null,
    BRIEFING_VIEW_ALL_BUTTON: null,

    // Briefings filters
    BRIEFING_FILTERS: null,

    // Direct Emails
    EMAIL_BUTTON: null,
    EMAIL_EXTERNAL_RECIPIENTS_SELECT: null,
    EMAIL_INTERNAL_USERS_SELECT: null,
    EMAIL_SUBJECT_INPUT: null,
    EMAIL_MESSAGE_INPUT: null,

    // Detention Dispositions
    DETENTION_UPDATE_BUTTON: null,
    DETENTION_CANCEL_BUTTON: null,

    // Service Rotation Providers Suspensions
    SUSPENSION_START_DATE_WRAPPER: null,
    SUSPENSION_END_DATE_WRAPPER: null,
    ROTATION_TYPES_IDS_TO_SUSPEND_WRAPPER: null,
    SUSPENSION_REASON_WRAPPER: null,
    ROTATION_TYPES_IDS_TO_SUSPEND: null,
    CAD_SERVICE_PROVIDER_RESUME_SERVICE_MODAL: null,
    CAD_SERVICE_PROVIDER_SUSPENSION: null,

    // Vehicle Cautions
    VEHICLE_CAUTIONS_N_ITEM_CAUTION_TYPE: null,

    // Enhanced Cautions
    ENHANCED_CAUTION: null,
    ENHANCED_CAUTION_TOOLTIP: null,
    ENHANCED_CAUTIONS_INACTIVE_INDICATOR: null,
    ENHANCED_CAUTIONS_ADD_PERSON_CAUTION_BUTTON: null,

    // Location Based ORI
    LOCATION_BASED_ORI_SHAPEFILE_SELECT: null,
    LOCATION_BASED_ORI_SHAPEFILE_PROPERTY_SELECT: null,
});

export type TestId = keyof typeof testIds;

export default testIds;

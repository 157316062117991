import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { TableColumn as Column } from 'components-mark43';
import { Observer } from 'markformythree';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import testIds from '../../../../core/testIds';
import { sidePanelIsOpenSelector } from '../../../../legacy-redux/selectors/boxSelectors';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { saveCaseLinks } from '../state/ui/caseLinkedProfilesSidePanel';
import caseLinkedProfilesForm from '../state/forms/caseLinkedProfilesForm';
import CaseLinkedProfilesSidePanelHeader from './CaseLinkedProfilesSidePanelHeader';
import CaseLinkedProfilesForm from './CaseLinkedProfilesForm';

const strings = componentStrings.cases.linkedProfiles.actions;

const SidePanel = styled(PortalSidePanel)`
    .react-side-panel-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    && .react-side-panel-content-wrapper {
        padding: 0;
    }

    &&.hidden {
        visibility: hidden;
    }
`;

const Wrapper = styled(Column)`
    width: 100%;
`;

const CaseLinkedProfilesSidePanel: FC<{
    renderButton: () => ReactNode;
}> = ({ renderButton }) => {
    const dispatch = useDispatch();
    const overlayId = overlayIdEnum.CASE_LINKED_PROFILES_SIDE_PANEL;
    const legacySidePanelIsOpen = useSelector(sidePanelIsOpenSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isCaseEntityLinksEnabled = !!applicationSettings.RMS_CASE_ENTITY_LINKS_ENABLED;
    const title = isCaseEntityLinksEnabled ? strings.addEditProfile : strings.editProfile;

    return (
        <OverlayBaseHelper id={overlayId} renderButton={renderButton}>
            {({ overlayBase }) => (
                <SidePanel
                    id={overlayId}
                    savePanel={() => {
                        dispatch(saveCaseLinks());
                    }}
                    title={title}
                    errorMessages={overlayBase.overlayState.errors}
                    userHasAttemptedSave={!!overlayBase.overlayState.errors.length}
                    testId={testIds.CASE_LINKED_PROFILES_SIDE_PANEL}
                    closePanel={() => {
                        overlayBase.close();
                    }}
                    isLoading={overlayBase.overlayState.isLoading}
                    isAtBottomOfStack={() => (
                        overlayBase.overlayStore.isOverlayAtBottomOfStack(overlayId) &&
                        !legacySidePanelIsOpen
                    )}
                    className={classNames({
                        hidden: legacySidePanelIsOpen,
                    })}
                >
                    <Wrapper>
                        <ArbiterForm
                            {...caseLinkedProfilesForm}
                            render={(form) => (
                                <>
                                    {isCaseEntityLinksEnabled && (
                                        <CaseLinkedProfilesSidePanelHeader />
                                    )}
                                    <Observer
                                        subscriptions={{
                                            personNameCaseLinks: 'personNameCaseLinks',
                                            orgNameCaseLinks: 'orgNameCaseLinks',
                                            vehicleItemCaseLinks: 'vehicleItemCaseLinks',
                                        }}
                                        render={() => <CaseLinkedProfilesForm form={form} />}
                                    /> 
                                </>
                            )}
                        />
                    </Wrapper>
                </SidePanel>
            )}
        </OverlayBaseHelper>
    );
};

export default CaseLinkedProfilesSidePanel;

import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { cssVar, Icon } from 'arc';
import {
    EntityTypeEnum,
    ExportOptionTypeEnum,
    LinkTypesEnum,
    SendEmailRequest,
} from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { fileByIdSelector } from '~/client-common/core/domain/files/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import { EmailExportReleaseViewModel } from '~/client-common/core/domain/email-export-releases/state/ui';
import { emailExportReleaseEntitiesByReleaseIdSelector } from '~/client-common/core/domain/email-export-release-entities/state/data';
import { exportedAttachmentsByReleaseIdSelector } from '~/client-common/core/domain/exported-attachments/state/data';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';

import testIds from '../../../../../core/testIds';
import InlineAttachmentsUploader from '../../../../attachments/core/components/InlineAttachmentsUploader';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import { DownloadLink } from '../../../../attachments/files/components';
import { saveInlineAttachments } from '../../../../attachments/core/state/ui/inlineAttachmentsUploader';

import SealedStamp from '../../../components/SealedStamp';
import TruncatedText from '../../../components/TruncatedText';
import emailResource from '../../../emails/resources/emailResource';
import { abilitiesEnum } from '../../../abilities';
import { BodyMediumText } from '../../../components/typography';
import { iconTypes, iconSizes } from '../../../components/Icon';
import { useOverlayStore } from '../../../overlays/hooks/useOverlayStore';
import { useAbilitySelector } from '../../../current-user/hooks/useAbilitySelector';
import { getShortenedRecipientsText } from '../../../emails/helpers';
import { SendEmailFormData } from '../../../emails/state/forms/sendEmailForm';
import InlineFileRow from '../../../../attachments/core/components/InlineFileRow';

import {
    ReleaseTrackingWrapper,
    ReleaseTrackingIconWrapper,
    ReleaseHistory,
    ReleaseHistoryContent,
    Header,
    SummaryList,
    SummaryRow,
    ToggleButton,
    ExportReleaseEntityRow,
    CheckIcon,
    SealedStampWrapper,
} from './ReleaseHistoryBlock';

const strings = componentStrings.exports.releaseTracking.components.ReleaseHistoryBlock;
const optionStrings = componentStrings.exports.options;
const emailFieldLabels = componentStrings.exports.emails.fieldLabels;

const exportOptionTypeToLabel = {
    [ExportOptionTypeEnum.INCLUDE_NAME_ADDENDUM.name]: optionStrings.includeNameAddendums,
    [ExportOptionTypeEnum.INCLUDE_HISTORY_EVENTS.name]: optionStrings.includeHistoryEvents,
    [ExportOptionTypeEnum.INCLUDE_WARRANT_ACTIVITY.name]: optionStrings.includeWarrantActivities,
    [ExportOptionTypeEnum.INCLUDE_CONFIDENTIAL_INFORMATION.name]:
        optionStrings.includeConfidentialInformation,
    [ExportOptionTypeEnum.ONLY_INCLUDE_FIELDS_WITH_DATA.name]:
        optionStrings.onlyIncludeFieldsWithData,
    [ExportOptionTypeEnum.UNKNOWN.name]: '',
};

const EmailIcon = styled(Icon)`
    margin: 5px 0 4px 2px;
    color: ${cssVar('arc.colors.brand.default')};
`;

const EmailButton = styled(Button)`
    width: 170px;
`;

const ButtonIcon = styled(Icon)`
    margin: 0 5px 2px 0;
`;

function formatMessage(message?: string) {
    const regex = /<br[ /]*>/gi;
    return {
        plainText: message?.replace(regex, '\n'),
        clearedMessage: message?.replace(regex, ' ').replace(/\s+/g, ' '),
    };
}

interface EmailReleaseHistoryBlockProps {
    emailExportRelease: EmailExportReleaseViewModel;
    showDetails: boolean;
    toggleOpen: () => void;
}

export const EmailReleaseHistoryBlock: React.FC<EmailReleaseHistoryBlockProps> = ({
    emailExportRelease,
    showDetails,
    toggleOpen,
}) => {
    const dispatch = useDispatch();
    const overlayStore = useOverlayStore();

    const hasReleaseAttachmentsAbility = useAbilitySelector(
        abilitiesEnum.CORE.EDIT_EXPORT_RELEASE_ATTACHMENTS
    );
    const hasDownloadReleaseAbility = useAbilitySelector(
        abilitiesEnum.CORE.DOWNLOAD_PREVIOUS_EXPORT_RELEASES
    );
    const fileById = useSelector(fileByIdSelector);
    const releaseEntitiesByReleaseId = useSelector(emailExportReleaseEntitiesByReleaseIdSelector);
    const exportedAttachmentsByReleaseId = useSelector(exportedAttachmentsByReleaseIdSelector);

    const {
        exportOptionTypes,
        externalRecipients,
        id: releaseId,
        internalUsers,
        isSealed,
        message,
        fileId,
        releasedDateUtc,
        subject,
        title,
    } = emailExportRelease;
    const { fullInternalUsers, releasedByUserId } = getViewModelProperties(emailExportRelease);

    const file = fileById(fileId);
    const releaseEntities = releaseEntitiesByReleaseId(releaseId);

    const recipientUserNames = fullInternalUsers.map(({ fullName }) => fullName);
    const recipientList = [...externalRecipients, ...recipientUserNames];
    const attachmentsLinkConfig = {
        entitiyId: releaseId,
        entityType: EntityTypeEnum.EMAIL_EXPORT_RELEASE.name,
        linkType: LinkTypesEnum.EMAIL_EXPORT_RELEASE_ATTACHMENT,
    };

    const { plainText, clearedMessage } = formatMessage(message);
    const releaseEmailData: SendEmailFormData = {
        externalRecipients,
        internalUsers,
        subject,
        message: plainText,
    };

    const handleFileUploadFinish = () => {
        dispatch(saveInlineAttachments(attachmentsLinkConfig));
    };

    const handleEmailRelease = (emailData: SendEmailRequest) => {
        return emailResource.emailFiles({
            sendEmailRequest: emailData,
            // we don't render releases without files, so it's safe
            mark43FileIds: [fileId as number],
        });
    };

    const openEmailModal = () => {
        overlayStore.open(overlayIdEnum.SEND_EMAIL_MODAL, {
            onSave: handleEmailRelease,
            prefillState: releaseEmailData,
        });
    };

    const exportedAttachmentRows = exportedAttachmentsByReleaseId(releaseId)?.map(attachment => {
        const originalFile = getAttachmentFile(attachment);
        return (
            <InlineFileRow
                url={originalFile.fileWebServerPath}
                key={`${releaseId}_${attachment.id}`}
                fileName={originalFile.originalFileName}
                createdDateUtc={attachment.createdDateUtc}
                createdBy={attachment.createdBy}
                fileCategory={originalFile.fileCategory}
                disabled={true}
                hideDetails={true}
            />
        );
    });

    return (
        <ReleaseTrackingWrapper data-test-id={testIds.EXPORTS_RELEASE_HISTORY_BLOCK}>
            <ReleaseTrackingIconWrapper>
                <EmailIcon icon="Email" size="md" />
            </ReleaseTrackingIconWrapper>
            <ReleaseHistory>
                <Header>
                    <div>
                        <BodyMediumText fontWeight="semibold">{title}</BodyMediumText>
                        <div>
                            <FormattedDate
                                date={releasedDateUtc}
                                format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                            >
                                {(date) => <div>{date}</div>}
                            </FormattedDate>
                            <SummaryList labelWidth={100} contentWidth="100%">
                                <SummaryRow label={strings.emailedBy}>
                                    {releasedByUserId.fullName}
                                </SummaryRow>
                                {!showDetails && (
                                    <SummaryRow label={strings.recipients}>
                                        {getShortenedRecipientsText(
                                            externalRecipients,
                                            fullInternalUsers
                                        )}
                                    </SummaryRow>
                                )}
                            </SummaryList>
                        </div>
                    </div>
                </Header>
                {showDetails && (
                    <ReleaseHistoryContent>
                        <SummaryList labelWidth={110} contentWidth="100%">
                            <SummaryRow label={strings.content}>
                                {releaseEntities.map(({ id, title }) => (
                                    <ExportReleaseEntityRow key={id}>
                                        {title}
                                    </ExportReleaseEntityRow>
                                ))}
                                {exportedAttachmentRows}
                            </SummaryRow>
                            <SummaryRow label={strings.recipients}>
                                {recipientList.map((recipient) => (
                                    <ExportReleaseEntityRow key={recipient}>
                                        {recipient}
                                    </ExportReleaseEntityRow>
                                ))}
                            </SummaryRow>
                            <SummaryRow label={strings.details}>
                                {exportOptionTypes.map((option) => (
                                    <div key={option}>
                                        <CheckIcon
                                            type={iconTypes.CHECK}
                                            size={iconSizes.SMALL}
                                            color="mediumLightGrey"
                                        />
                                        {exportOptionTypeToLabel[option]}
                                    </div>
                                ))}
                            </SummaryRow>
                            <SummaryRow label={emailFieldLabels.subject}>{subject}</SummaryRow>
                            <SummaryRow label={emailFieldLabels.message}>
                                <TruncatedText text={clearedMessage} />
                            </SummaryRow>
                            <SummaryRow label={strings.attachments} labelTooltip={strings.attachmentsTooltip}>
                                <InlineAttachmentsUploader
                                    {...attachmentsLinkConfig}
                                    disabled={!hasReleaseAttachmentsAbility}
                                    onFileUploadFinish={handleFileUploadFinish}
                                />
                            </SummaryRow>
                        </SummaryList>
                        {isSealed ? (
                            <SealedStampWrapper>
                                <SealedStamp />
                            </SealedStampWrapper>
                        ) : (
                            <>
                                {hasDownloadReleaseAbility && (
                                    <DownloadLink href={file?.fileWebServerPath}>
                                        <Button
                                            className={buttonTypes.SECONDARY}
                                            iconLeft={<ButtonIcon icon="Export" />}
                                            testId={testIds.EXPORTS_DOWNLOAD_RELEASE_BUTTON}
                                        >
                                            {strings.downloadRelease}
                                        </Button>
                                    </DownloadLink>
                                )}
                                <EmailButton
                                    className={buttonTypes.SECONDARY}
                                    iconLeft={<ButtonIcon icon="Email" />}
                                    onClick={openEmailModal}
                                    testId={testIds.EXPORTS_EMAIL_RELEASE_BUTTON}
                                >
                                    {strings.emailRelease}
                                </EmailButton>
                            </>
                        )}
                    </ReleaseHistoryContent>
                )}
                <ToggleButton
                    as="button"
                    data-test-id={testIds.EXPORTS_RELEASE_HISTORY_BLOCK_TOGGLE}
                    onClick={toggleOpen}
                >
                    {showDetails ? strings.hideDetails : strings.showDetails}
                </ToggleButton>
            </ReleaseHistory>
        </ReleaseTrackingWrapper>
    );
};

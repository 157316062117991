import { EntityTypeEnum, LocationSourceEnum, RefContextEnum } from '@mark43/rms-api';
import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';
import { get, noop, defer } from 'lodash';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { parseSubPremises } from '@mark43/gis-locations';
import { formEvents } from 'markformythree';
import { currentUserDepartmentCountryCodeSelector } from '~/client-common/core/domain/current-user/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayStateTypeEnum from '~/client-common/core/enums/client/overlayStateTypeEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import {
    locationHasSubdivision,
    getAgencyIdForLocationEntityLink,
} from '~/client-common/core/domain/locations/utils/locationHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import testIds from '../../../../core/testIds';
import { OverlayBaseHelper } from '../../components/OverlayBaseHelper';
import {
    saveLocationProfile,
    classifyLocation,
    multiagencyClassify,
    retrieveAndClassifyPostcoder,
    payEsriAndClassifyLocation,
    SCREENS,
} from '../state/ui';
import { convertToSubPremiseFormModel } from '../utils/locationSidePanelFormHelpers';
import formsRegistry from '../../../../core/formsRegistry';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../components/Icon';
import {
    currentReportRENSelector,
    currentReportIdSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import { currentUserDepartmentAgencyIdSelector } from '../../current-user/state/ui';
import { currentCaseSelector } from '../../../cases/core/state/ui';
import {
    requestRecentLocationsForReports,
    clearRecentLocations,
} from '../../../../legacy-redux/actions/recentEntitiesActions';
import { NotepadPill } from '../../../mobile/notepad/components/NotepadPill';
import FocusWrapper from '../../components/FocusWrapper';
import SubPremiseSearchInstruction from './SubPremiseSearchInstruction';
import { LocationMapSearchScreen } from './LocationMapSearchScreen';
import { InvolvedLocations } from './InvolvedLocations';
import LocationSearchResults from './LocationSearchResults';
import LocationSearchInput, { LOCATION_SEARCH_FORM } from './LocationSearchInput';
import LocationSidePanelForm from './LocationSidePanelForm';

const StyledNotepadPill = styled(NotepadPill)`
    margin-top: 10px;
`;

const strings = componentStrings.core.LocationSidePanel;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-direction: column;
`;

const BackButton = styled(Button)`
    padding-right: 12px;
`;

const SearchFocusWrapper = styled(FocusWrapper)`
    flex: 1;
    width: 100%;
`;

const mapStateToProps = createStructuredSelector({
    currentReportREN: currentReportRENSelector,
    currentCase: currentCaseSelector,
    currentReportId: currentReportIdSelector,
    applicationSettings: applicationSettingsSelector,
    currentUserDepartmentAgencyId: currentUserDepartmentAgencyIdSelector,
    currentUserDepartmentCountryCode: currentUserDepartmentCountryCodeSelector,
});

const LocationSidePanelWithoutOverlayBase = connect(mapStateToProps, {
    saveLocationProfile,
    classifyLocation,
    multiagencyClassify,
    retrieveAndClassifyPostcoder,
    payEsriAndClassifyLocation,
    requestRecentLocationsForReports,
    clearRecentLocations,
})(
    class LocationSidePanelWithoutOverlayBaseUnconnected extends Component {
        setErrors = (errors) =>
            this.props.screenManagerApi.setCurrentScreenState({ errorMessages: errors });

        getContext = () => {
            const { linkType, showCoordinates } = this.props;
            if (showCoordinates) {
                return RefContextEnum.FORM_LOCATION_COORDINATES_SIDE_PANEL.name;
            } else if (linkType) {
                return RefContextEnum.FORM_LOCATION_ENTITY_LINK_SIDE_PANEL.name;
            } else {
                return RefContextEnum.FORM_LOCATION_SIDE_PANEL.name;
            }
        };

        isLocationEntityLinkContext = () => {
            const context = this.getContext();
            return context === RefContextEnum.FORM_LOCATION_ENTITY_LINK_SIDE_PANEL.name;
        };

        handleSaveLocationProfile = () => {
            const {
                onSaveSuccess,
                entityId,
                showCoordinates,
                overlayId,
                savePanel,
                hideRecentLocations,
            } = this.props;
            const context = this.getContext();
            const onError = (result) => {
                const serverErrorMessage = result.message;
                const validationErrorMessages = get(result, 'validationResult.formErrors');
                const errorMessages = serverErrorMessage
                    ? [serverErrorMessage]
                    : validationErrorMessages;
                this.props.screenManagerApi.setCurrentScreenState({ errorMessages });
            };
            const onSuccess = ({ location, modelEntityLink }) => {
                if (onSaveSuccess) {
                    onSaveSuccess(location, modelEntityLink);
                }

                savePanel();

                if (hideRecentLocations) {
                    this.getInvolvedLocations();
                }
            };

            const omitLocationEntityLinks = !entityId || entityId < 0;
            this.props.saveLocationProfile({
                overlayId,
                context,
                omitLocationEntityLinks,
                saveCoordinates: showCoordinates,
                onSuccess,
                onError,
            });
        };

        handleSearch = () => this.props.screenManagerApi.goToNextScreen(SCREENS.SEARCH_FORM);

        addNewLocation = () => {
            const {
                entityId,
                entityType,
                linkType,
                currentUserDepartmentAgencyId,
                applicationSettings,
                agencyId: defaultAgencyId,
                currentUserDepartmentCountryCode,
            } = this.props;
            const multiagencySubdivisionsEnabled =
                applicationSettings.MULTI_AGENCY_SUBDIVISIONS_ENABLED;
            const agencyId = getAgencyIdForLocationEntityLink({
                multiagencySubdivisionsEnabled,
                defaultAgencyId,
                currentUserDepartmentAgencyId,
            });
            const searchQuery =
                formsRegistry.get(LOCATION_SEARCH_FORM)?.getState()?.model?.searchQuery || '';
            const formModel = {
                country: currentUserDepartmentCountryCode,
                subPremises: convertToSubPremiseFormModel(parseSubPremises(searchQuery)),
                ...(this.isLocationEntityLinkContext()
                    ? {
                          entityLink: {
                              entityId,
                              entityType,
                              linkType,
                              agencyId,
                          },
                      }
                    : {}),
            };
            this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_EDIT, {
                initialFormState: formModel,
            });
        };

        storeSearchResults = (searchResults) =>
            this.props.screenManagerApi.setCurrentScreenState({ searchResults });

        onClickNotepadLocation = (location) =>
            this.onClickLocation(location, { isNotepadLocation: true });

        onClickRecentLocation = (location) =>
            this.onClickLocation(location, { isRecentLocation: true });

        onSelectLocation = () => {
            const formName = this.getContext();
            const form = formsRegistry.get(formName);

            const { success, formErrors } = form.validate({ eventType: formEvents.FORM_SUBMIT });

            if (success) {
                const { model: customLocation } = form.getState();
                this.props.onSelect(customLocation);
                this.props.closePanel();
            } else if (formErrors) {
                this.setErrors(formErrors)
            }
        }

        onClickLocation = (location, { isNotepadLocation, isRecentLocation } = {}) => {
            const {
                currentUserDepartmentAgencyId,
                applicationSettings,
                agencyId: defaultAgencyId,
            } = this.props;
            const {
                id,
                sourceId,
                source,
                latitude,
                longitude,
                placeName: resolvedPlaceName,
                streetAddress,
                locality,
                postalCode,
            } = location;

            const origLoc =
                source === LocationSourceEnum.ESRI.name && location ? { ...location } : {};
            const { entityId, entityType, linkType } = this.props;
            const multiagencySubdivisionsEnabled =
                applicationSettings.MULTI_AGENCY_SUBDIVISIONS_ENABLED;
            const agencyId = getAgencyIdForLocationEntityLink({
                multiagencySubdivisionsEnabled,
                defaultAgencyId,
                currentUserDepartmentAgencyId,
            });
            const shouldClassify =
                (this.isLocationEntityLinkContext() && !locationHasSubdivision(location)) ||
                source === LocationSourceEnum.POSTCODER.name;
            const onError = (error) => this.setErrors([error.message]);
            const onSuccess = (locationView, error) => {
                if (!locationView.entityLinks || locationView.entityLinks.length === 0) {
                    locationView.entityLinks = [
                        {
                            placeName: resolvedPlaceName,
                        },
                    ];
                } else {
                    locationView.entityLinks[0].placeName = resolvedPlaceName;
                }
                let formModel;
                const {
                    subPremise1Name,
                    subPremise1Value,
                    subPremise2Name,
                    subPremise2Value,
                    subPremise3Name,
                    subPremise3Value,
                    subPremise4Name,
                    subPremise4Value,
                    subPremise5Name,
                    subPremise5Value,
                } = locationView;

                if (this.isLocationEntityLinkContext()) {
                    const {
                        propertyTypeAttrId,
                        typeAttrId,
                        countyCodeAttrId,
                        subdivision1AttrId,
                        subdivision2AttrId,
                        subdivision3AttrId,
                        subdivision4AttrId,
                        subdivision5AttrId,
                        placeName,
                        resolverSource,
                        subPremise,
                    } = locationView.entityLinks[0];
                    formModel = {
                        ...locationView,
                        ...origLoc,
                        entityLink: {
                            entityId,
                            entityType,
                            linkType,
                            propertyTypeAttrId,
                            typeAttrId,
                            countyCodeAttrId,
                            placeName,
                            subPremise,
                            subdivision1AttrId,
                            subdivision2AttrId,
                            subdivision3AttrId,
                            subdivision4AttrId,
                            subdivision5AttrId,
                            resolverSource,
                            agencyId,
                            subPremise1Name,
                            subPremise1Value,
                            subPremise2Name,
                            subPremise2Value,
                            subPremise3Name,
                            subPremise3Value,
                            subPremise4Name,
                            subPremise4Value,
                            subPremise5Name,
                            subPremise5Value,
                        },
                    };
                } else {
                    formModel = locationView;
                }
                const subPremises = convertToSubPremiseFormModel({
                    subPremise1Name,
                    subPremise1Value,
                    subPremise2Name,
                    subPremise2Value,
                    subPremise3Name,
                    subPremise3Value,
                    subPremise4Name,
                    subPremise4Value,
                    subPremise5Name,
                    subPremise5Value,
                });

                formModel.subPremises = subPremises;

                this.props.screenManagerApi.setCurrentScreenState({
                    currentLocation: location,
                });

                // This operation is deferred because there is a minor bug
                // with the screen manager when running 2 screen manager commands
                // directly after another
                // Because react state is async, what seems to be happening is that
                // after the first `setCurrentScreenState` call is made, the subsequent
                // `goToNextScreen` call gets made before the component finishes updating
                // (https://github.com/mark43/mark43/blob/518e1d9b15b338e85803bb0733aa6aa519365523/client/src/scripts/modules/core/components/SidePanel/ScreenManager.js#L52), so its `screenStack`
                // is stale
                defer(() => {
                    this.props.screenManagerApi.goToNextScreen(SCREENS.PROFILE_EDIT, {
                        currentLocation: { ...locationView, source },
                        initialFormState: formModel,
                    });
                });

                if (!!error?.message) {
                    this.setErrors([error.message]);
                }
            };

            if (source === LocationSourceEnum.DC_MAR.name) {
                onSuccess(location);
                return;
            }

            const storeLocallyAfterClassify = !this.props.skipStoreLocallyOnLocationSelection;

            // Probably don't need to fetch even if there's a source ID...
            // ...just use the info you've got!
            if (sourceId) {
                this.props.payEsriAndClassifyLocation({
                    multiagencySubdivisionsEnabled,
                    agencyId,
                    source,
                    id: sourceId,
                    shouldClassify,
                    onSuccess,
                    onError,
                    location,
                    storeLocallyAfterClassify,
                });
            } else if (isRecentLocation || isNotepadLocation) {
                this.props.payEsriAndClassifyLocation({
                    multiagencySubdivisionsEnabled,
                    agencyId,
                    source: LocationSourceEnum.MARK43.name,
                    id,
                    shouldClassify,
                    onSuccess,
                    onError,
                    location,
                    storeLocallyAfterClassify,
                });
            } else if (!isUndefinedOrNull(id) && shouldClassify) {
                if (source === LocationSourceEnum.POSTCODER.name) {
                    this.props.retrieveAndClassifyPostcoder({
                        location,
                        onSuccess,
                        onError,
                    });
                } else {
                    const classifyMethod =
                        multiagencySubdivisionsEnabled && agencyId
                            ? this.props.multiagencyClassify
                            : this.props.classifyLocation;

                    classifyMethod({
                        agencyId,
                        location: {
                            latitude,
                            longitude,
                            fullAddress: streetAddress,
                            locality,
                            postalCode,
                        },
                        onSuccess: (locationEntityLink) => {
                            onSuccess({
                                ...location,
                                entityLinks: [locationEntityLink],
                            });
                        },
                        onError,
                    });
                }
            } else {
                onSuccess(location);
            }
        };

        getCurrentREN = () => {
            return this.props.currentCase
                ? this.props.currentCase.reportingEventNumber
                : this.props.currentReportREN;
        };

        getInvolvedLocations = () => {
            const { currentReportId } = this.props;
            const currentRen = this.getCurrentREN();

            if (isUndefinedOrNull(currentRen) && isUndefinedOrNull(currentReportId)) {
                return;
            }

            return this.props.requestRecentLocationsForReports(currentRen, undefined);
        };

        renderScreen = () => {
            const {
                screen,
                screenState: { searchResults, currentLocation, initialFormState },
            } = this.props.screenManagerApi.getCurrentScreen();
            const context = this.getContext();
            const currentREN = this.getCurrentREN();
            if (screen === SCREENS.LOCATION_MAP_SEARCH) {
                return (
                    <LocationMapSearchScreen
                        renderAfterInput={
                            <StyledNotepadPill
                                mode="render-as-link"
                                onEntityClick={this.onClickNotepadLocation}
                                entityType={EntityTypeEnum.LOCATION.name}
                                id={overlayIdEnum.NOTEPAD_LINKS_OVERLAY_LOCATION_OVERLAY}
                            />
                        }
                        setErrors={this.setErrors}
                        onEditLocation={(location) => {
                            this.onClickLocation(location);
                        }}
                        linkType={this.props.linkType}
                        onClickCreateLocation={this.addNewLocation}
                        globalSelectedLocation={currentLocation}
                        globalSearchResults={searchResults}
                        setGlobalSearchResults={(searchResults) =>
                            this.props.screenManagerApi.setCurrentScreenState({ searchResults })
                        }
                    />
                );
            } else if (screen === SCREENS.INVOLVED_LOCATIONS) {
                return (
                    <div>
                        {!this.props.hideRecentLocations && (
                            <InvolvedLocations
                                renForRecents={currentREN}
                                linkType={this.props.linkType}
                                ownerId={this.props.currentReportId}
                                onClick={this.onClickRecentLocation}
                            />
                        )}
                        <StyledNotepadPill
                            onEntityClick={this.onClickNotepadLocation}
                            entityType={EntityTypeEnum.LOCATION.name}
                            id={overlayIdEnum.NOTEPAD_LINKS_OVERLAY_LOCATION_OVERLAY}
                        />
                    </div>
                );
            } else if (screen === SCREENS.SEARCH_FORM) {
                return (
                    <LocationSearchResults
                        onClickAddNew={this.addNewLocation}
                        onClickSearchResult={this.onClickLocation}
                        searchResults={searchResults}
                        linkType={this.props.linkType}
                    />
                );
            } else if (screen === SCREENS.PROFILE_EDIT) {
                return (
                    <LocationSidePanelForm
                        initialFormState={initialFormState}
                        context={context}
                        location={currentLocation}
                        setErrors={this.setErrors}
                    />
                );
            }
            return null;
        };

        portalPropsForScreen = () => {
            const {
                screen,
                screenState: { errorMessages, saveDisabled },
            } = this.props.screenManagerApi.getCurrentScreen();

            const {
                overlayBase: { overlayState, isAtBottomOfStack },
                closePanel,
            } = this.props;

            const baseProps = {
                isAtBottomOfStack,
                closePanel,
                errorMessages,
                title: strings.addLocation,
                userHasAttemptedSave: !!errorMessages.length,
                saveDisabled,
                saving: overlayState.customProperties.isSaving,
            };

            switch (screen) {
                case SCREENS.PROFILE_EDIT:
                    return {
                        ...baseProps,
                        savePanel: (this.props.selectOnly && this.props.onSelect) ? this.onSelectLocation : this.handleSaveLocationProfile,
                    };
                case SCREENS.INVOLVED_LOCATIONS:
                    return {
                        ...baseProps,
                        onRest: !this.props.hideRecentLocations ? this.getInvolvedLocations : noop,
                        saveText: null,
                    };
                case SCREENS.SEARCH_FORM:
                default:
                    return {
                        ...baseProps,
                        saveText: null,
                    };
            }
        };

        onBack = () => {
            this.props.screenManagerApi.goToPreviousScreen();
        };

        renderHeader() {
            const { screen } = this.props.screenManagerApi.getCurrentScreen();
            const backButton = (
                <BackButton
                    className={buttonTypes.ICON_LINK}
                    iconLeft={iconTypes.OPEN_LEFT}
                    onClick={this.onBack}
                >
                    {strings.back}
                </BackButton>
            );

            if (screen === SCREENS.INVOLVED_LOCATIONS) {
                return (
                    <HeaderWrapper>
                        <LocationSearchInput
                            key="location-search-input"
                            onSearch={this.handleSearch}
                            storeResults={this.storeSearchResults}
                            setErrors={this.setErrors}
                            afterFormContent={
                                <FeatureFlagged flag="SUBPREMISE_SUPPORT_ENABLED">
                                    <div style={{ marginTop: '12px' }}>
                                        <SubPremiseSearchInstruction />
                                    </div>
                                </FeatureFlagged>
                            }
                        />
                    </HeaderWrapper>
                );
            } else if (screen === SCREENS.SEARCH_FORM) {
                return (
                    <HeaderWrapper>
                        {backButton}
                        <SearchFocusWrapper>
                            <LocationSearchInput
                                key="location-search-input"
                                hasBackButton={true}
                                storeResults={this.storeSearchResults}
                                setErrors={this.setErrors}
                            />
                        </SearchFocusWrapper>
                    </HeaderWrapper>
                );
            } else if (screen === SCREENS.LOCATION_MAP_SEARCH) {
                return null;
            } else {
                return <HeaderWrapper>{backButton}</HeaderWrapper>;
            }
        }

        componentWillUnmount() {
            const context = this.getContext();
            const { entityId, entityType } = this.props;

            if (this.props.defaultSearchQuery) {
                formsRegistry.get(LOCATION_SEARCH_FORM)?.set('searchQuery', undefined);
            }

            formsRegistry.unregister(context);
            formsRegistry.unregister(LOCATION_SEARCH_FORM);

            if (!this.props.hideRecentLocations) {
                this.props.clearRecentLocations({
                    renForRecents: this.getCurrentREN(),
                    ownerType: entityType,
                    ownerId: entityId,
                });
            }
        }

        componentDidMount() {
            const form = formsRegistry.get(LOCATION_SEARCH_FORM);
            if (this.props.defaultSearchQuery) {
                form.set('searchQuery', this.props.defaultSearchQuery);
            }
        }

        render() {
            return (
                <PortalSidePanel
                    {...this.portalPropsForScreen()}
                    testId={testIds.LOCATION_SIDE_PANEL}
                >
                    <div>
                        {this.renderHeader()}
                        {this.renderScreen()}
                    </div>
                </PortalSidePanel>
            );
        }
    }
);

const initialState = (rmsLocationMapSearchEnabled) => ({
    screenStack: [
        {
            screen: rmsLocationMapSearchEnabled
                ? SCREENS.LOCATION_MAP_SEARCH
                : SCREENS.INVOLVED_LOCATIONS,
        },
    ],
});

export const LocationSidePanel = ({ overlayId, renderButton, saveRef, ...props }) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const rmsLocationMapSearchEnabled =
        applicationSettings.LOCATIONS_LOCATION_SELECTION_ON_MAP_ENABLED;
    return (
        <OverlayBaseHelper
            id={overlayId}
            overlayStateType={overlayStateTypeEnum.LOCATION_OVERLAY}
            getInitialCustomPropertyState={() => initialState(rmsLocationMapSearchEnabled)}
            renderButton={renderButton}
            saveRef={saveRef}
        >
            {(renderProps) => (
                <LocationSidePanelWithoutOverlayBase
                    overlayId={overlayId}
                    linkType={
                        props.linkType ||
                        renderProps.overlayBase?.overlayStore?.getStateForId(overlayId)
                            ?.customProperties?.linkType
                    }
                    {...props}
                    {...renderProps}
                />
            )}
        </OverlayBaseHelper>
    );
};

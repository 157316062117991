import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import _, { uniqBy, get, first, last, compact, join, map, groupBy, upperCase } from 'lodash';

import { createSelector } from 'reselect';

// selectors
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    formatLinkTypeLetterNumber,
    formatNameReportLinkTypeId,
} from '~/client-common/helpers/linkTypesHelpers';
import { mugshotAttachmentsForPersonIdSelector } from '~/client-common/core/domain/attachments/state/ui/';

// helpers
import {
    isUnknown,
    formatFullName,
    buildPersonProfileLink,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { buildOrgProfileLink } from '~/client-common/core/domain/organization-profiles/utils/organizationProfilesHelper';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data/';

// config
import constants from '../../core/constants';
import { currentReportIdSelector } from './reportSelectors';
import { formatNameNameLinksSelector } from './nameNameLinksSelectors';

const strings = componentStrings.reports.InvolvedNamesCard;
const labels = strings.InvolvedNamesForm.labels;

/**
 * View models for each involved person/org contains display strings, sorted by
 *   entity type and then by display name.
 */
export const involvedNameViewModelsForDataSelector = createSelector(
    formatNameNameLinksSelector,
    formatFieldByNameSelector,
    currentReportIdSelector,
    arrestForReportIdSelector,
    mugshotAttachmentsForPersonIdSelector,
    (
        formatNameNameLinks,
        formatFieldByName,
        currentReportId,
        arrestForReportId,
        mugshotAttachmentsForPersonId

        // The list of entities passed in here must already be filtered for OMS
    ) => ({
        personProfiles: filteredPersonProfiles,
        organizationProfiles: filteredOrganizationProfiles,
        nameNameLinks: filteredNameNameLinks,
        nameReportLinks: filteredNameReportLinks,
    }) => {
        // Defendants don't appear in our `nameReportLinks`, so
        // we need to manually check for them and add them in
        const { defendantId } = arrestForReportId(currentReportId) || {};

        const nameReportLinksByNameId = groupBy(
            [
                ...filteredNameReportLinks,
                ...(defendantId
                    ? [
                          {
                              nameId: defendantId,
                              entityType: EntityTypeEnum.PERSON_PROFILE.name,
                              reportId: currentReportId,
                              linkType: LinkTypesEnum.DEFENDANT_IN_ARREST,
                              // There can only be one defendant
                              linkTypeSequenceNumber: 1,
                          },
                      ]
                    : []),
            ],
            'nameId'
        );
        function buildSequenceIdentifier(nameReportLink) {
            if (!nameReportLink) {
                return '';
            }
            return `${constants.linkTypeLetters[nameReportLink.linkType]}-${
                nameReportLink.linkTypeSequenceNumber
            }`;
        }
        // view models containing display strings for persons
        const personViewModels = _(filteredPersonProfiles)
            .filter('id')
            .map((profile) => {
                const label = profile.isExpunged
                    ? upperCase(strings.sealedPerson)
                    : formatFullName(profile);
                return {
                    nameId: profile.id,
                    masterPersonId: profile.masterPersonId,
                    nameEntityType: EntityTypeEnum.PERSON_PROFILE.name,
                    isUnknown: isUnknown(profile),
                    isExpunged: profile.isExpunged,
                    isNonDisclosureRequest: profile.isNonDisclosureRequest,
                    display: {
                        profileImageUrl: get(
                            first(mugshotAttachmentsForPersonId(profile.id)),
                            'image.originalFile.fileWebServerPath'
                        ),
                        label,
                        link: buildPersonProfileLink(profile),
                    },
                };
            })
            .sortBy('display.label')
            .value();

        // view models containing display strings for orgs
        const organization = _(filteredOrganizationProfiles)
            .filter('id')
            .map((profile) => {
                const nameReportLink = last(nameReportLinksByNameId[profile.id]);
                const sequenceIdentifier = buildSequenceIdentifier(nameReportLink);
                const label = nameReportLink
                    ? profile.masterOrganizationId
                        ? profile.name
                        : sequenceIdentifier
                    : profile.name;
                return {
                    nameId: profile.id,
                    nameEntityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                    display: {
                        label,
                        link: buildOrgProfileLink(profile),
                    },
                };
            })
            .sortBy('display.label')
            .value();

        // combine the two types of view models
        const viewModels = [...personViewModels, ...organization];

        // compute display strings common to both persons and orgs
        return _.map(viewModels, (viewModel) => {
            const nameReportLinks = uniqBy(nameReportLinksByNameId[viewModel.nameId], 'linkType');
            viewModel = {
                ...viewModel,
                display: {
                    ...viewModel.display,
                    expandedLinkTypes: join(
                        map(nameReportLinks, ({ linkType }, idx) =>
                            join(
                                compact([
                                    formatNameReportLinkTypeId(linkType, formatFieldByName),
                                    idx === 0 &&
                                        viewModel.isNonDisclosureRequest &&
                                        strings.isNonDisclosure,
                                ]),
                                ' '
                            )
                        ),
                        '\n'
                    ),
                    abbreviatedLinkTypes: join(
                        map(nameReportLinks, ({ linkType, linkTypeSequenceNumber }) =>
                            formatLinkTypeLetterNumber(linkType, linkTypeSequenceNumber)
                        ),
                        ', '
                    ),
                    nameNameLinks: formatNameNameLinks(
                        filteredNameNameLinks,
                        viewModel.nameId,
                        _.reject(viewModels, { nameId: viewModel.nameId })
                    ),
                    // function for a dynamic label
                    nameNameLink: (otherViewModel) => {
                        return `${labels.nameNameLink.linkTypeIdPrefix} ${otherViewModel.display.label}`;
                    },
                },
            };

            // the icon type is computed last because it depends on `display`
            return {
                ...viewModel,
            };
        });
    }
);

import { isArray, reduce } from 'lodash';

// TODO PRINTING-REFACTOR evaluate the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Attachments Resource',
        methods: {
            loadAttachmentsByEntityId(entityType, entityIds, { hideLoadingBar } = {}) {
                let param = entityIds;
                if (isArray(entityIds)) {
                    param = entityIds.join('&entity_id=');
                }

                return req({
                    method: 'GET',
                    hideLoadingBar,
                    url: `attachments/${entityType}/type?entity_id=${param}`,
                });
            },
            loadAttachmentsByEntityIdsAndTypes(
                entityIdsByEntityType,
                { hideLoadingBar, excludeAttachmentLinkTypes } = {}
            ) {
                const queryString = reduce(
                    excludeAttachmentLinkTypes,
                    (accumulator, linkType) => {
                        if (accumulator) {
                            accumulator += `&excluded_link_types=${linkType}`;
                        } else {
                            accumulator = `excluded_link_types=${linkType}`;
                        }
                        return accumulator;
                    },
                    ''
                );

                return req({
                    method: 'POST',
                    hideLoadingBar,
                    url: queryString ? `attachments/batch?${queryString}` : 'attachments/batch',
                    data: entityIdsByEntityType,
                });
            },
            getAttachmentsForAttachmentIds(attachmentIds, { hideLoadingBar }) {
                return req({
                    method: 'POST',
                    hideLoadingBar,
                    url: `attachments/by_attachment_id`,
                    data: attachmentIds,
                    params: {
                        hydrate_files: true,
                    },
                });
            },
            saveAttachmentsForEntities({
                entityType,
                entityIds,
                attachments,
                removeOthers,
                linkTypes,
            }) {
                return req({
                    method: 'PUT',
                    url: `attachments/${entityType}/type`,
                    data: attachments,
                    params: {
                        // yes, the query param is singular, yet expects a collection,
                        // and yes, we have integrations that depend on this. partyparty.
                        entity_id: entityIds,
                        link_types: linkTypes,
                        remove_others: removeOthers,
                    },
                });
            },
            saveAttachment(attachment) {
                return req({
                    method: 'POST',
                    url: `attachments`,
                    data: attachment,
                });
            },
            deleteAttachment(attachment) {
                return req({
                    method: 'DELETE',
                    url: 'attachments',
                    params: {
                        entity_id: attachment.entityId,
                        entity_type: attachment.entityType,
                        attachment_id: attachment.attachmentId,
                        attachment_type: attachment.attachmentType,
                        link_type: attachment.linkType,
                    },
                });
            },
            deleteAttachments(attachments, entityId, entityType) {
                return req({
                    method: 'POST',
                    url: 'attachments/bulk/delete',
                    data: {
                        attachmentsToDelete: attachments,
                        entityId,
                        entityType,
                    },
                });
            },
        },
    });
}

const resourceGetter = () => resource;

/**
 * Resources for Abilities. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
export default resourceGetter;

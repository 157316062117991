import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, defaultProps, setPropTypes } from 'recompose';
import keyMirror from 'keymirror';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { attributeOptionsByTypeForCurrentDepartmentSelector } from '../../../attributes/state/ui';
import MultiFieldSelect from './MultiFieldSelect';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const strings = componentStrings.forms.select.CustomReportClassificationAttrSelect;

/**
 * Select dropdown containing all Offense codes as well as "No Incident /
 *   Offense Classification", and "Any I/O Classification" options
 *   (modeled by `hasCustomReportClassificationAttrId = false/true`).
 * @param {Object} props Same props as `Select`.
 */
const CustomReportClassificationAttrSelect = connect(
    createStructuredSelector({
        attributeOptionsByTypeForCurrentDepartment: attributeOptionsByTypeForCurrentDepartmentSelector,
    })
)(function CustomReportClassificationAttrSelect({
    fields: { hasCustomReportClassificationAttrId, customReportClassificationAttrIds },
    attributeOptionsByTypeForCurrentDepartment,
    label,
    ...otherProps
}) {
    const opts = attributeOptionsByTypeForCurrentDepartment({
        type: 'CUSTOM_REPORT_CLASSIFICATION',
        includeExpired: true,
        additionalIds: [],
        includeAbbr: true,
    });

    const offenseDisplayName = useOffenseFieldName();

    return (
        <MultiFieldSelect
            label={label || strings.label(offenseDisplayName)}
            {...otherProps}
            fields={{
                hasCustomReportClassificationAttrId: {
                    options: [
                        {
                            value: false,
                            display: strings.options.hasCustomReportClassificationAttrId.false(
                                offenseDisplayName
                            ),
                            none: true,
                        },
                        {
                            value: true,
                            display: strings.options.hasCustomReportClassificationAttrId.true(
                                offenseDisplayName
                            ),
                            none: true,
                        },
                    ],
                    ...hasCustomReportClassificationAttrId,
                },
                customReportClassificationAttrIds: {
                    options: opts,
                    ...customReportClassificationAttrIds,
                },
            }}
        />
    );
});

export const RRFCustomReportClassificationAttrSelect = compose(
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            hasCustomReportClassificationAttrId: null,
            customReportClassificationAttrIds: null,
        }),
    }),
    connectRRFMultiFieldInput
)(CustomReportClassificationAttrSelect);

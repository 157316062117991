import { map, sortBy } from 'lodash';
import getEvidenceBasketResource from '~/client-common/core/domain/evidence/resources/evidenceBasketResource';
import { storeEvidenceHydratedItems } from '../../utils/evidenceItemsHelpers';

export const LOAD_ITEM_QUEUE_START = 'item-queue/LOAD_ITEM_QUEUE_START';
export const LOAD_ITEM_QUEUE_SUCCESS = 'item-queue/LOAD_ITEM_QUEUE_SUCCESS';
export const LOAD_ITEM_QUEUE_FAILURE = 'item-queue/LOAD_ITEM_QUEUE_FAILURE';
export const ADD_ITEM_REPORT_LEVEL_STAFF_REMARKS_TO_LOAD =
    'item-queue/ADD_ITEM_REPORT_LEVEL_STAFF_REMARKS_TO_LOAD';

function loadItemQueueStart() {
    return {
        type: LOAD_ITEM_QUEUE_START,
    };
}

function loadItemQueueSuccess(items) {
    return {
        type: LOAD_ITEM_QUEUE_SUCCESS,
        payload: items,
    };
}

function loadItemQueueFailure(errorMessage) {
    return {
        type: LOAD_ITEM_QUEUE_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

export function addItemReportLevelStaffRemarksToLoad(masterItemIds) {
    return {
        type: ADD_ITEM_REPORT_LEVEL_STAFF_REMARKS_TO_LOAD,
        payload: masterItemIds,
    };
}

export function loadItemQueue() {
    return (dispatch) => {
        dispatch(loadItemQueueStart());

        return getEvidenceBasketResource()
            .getBasket()
            .then((items) => dispatch(storeEvidenceHydratedItems(items)))
            .then((items) => sortBy(items, (item) => item.item.createdDateUtc))
            .then((items) => map(items, 'item.masterItemId'))
            .then((masterItemIds) => {
                dispatch(loadItemQueueSuccess(masterItemIds));
                return masterItemIds;
            })
            .catch((error) => {
                dispatch(loadItemQueueFailure(error.message));
                // return nothing so chained calls can assert that an error was thrown
                // and don't dispatch actions with potentially invalid values
            });
    };
}

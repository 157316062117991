import { FillablePdfTemplateView } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'fillablePdfTemplates';

const fillablePdfTemplatesModule = createNormalizedModule<FillablePdfTemplateView>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// SELECTORS
export const fillablePdfTemplatesSelector = fillablePdfTemplatesModule.selectors.entitiesSelector;
export const fillablePdfTemplatesWhereSelector =
    fillablePdfTemplatesModule.selectors.entitiesWhereSelector;

// REDUCER
export default fillablePdfTemplatesModule.reducerConfig;

import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { ConfirmModal, Tooltip } from 'arc';
import { BriefingStateEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { Briefing } from '~/client-common/core/domain/briefings/state/data';

import Subheader from '../../../core/components/Subheader';
import GenericPermissionsModal from '../../../core/permissions/components/GenericPermissionsModal';
import { IconButton } from '../../../core/components/IconButton';
import testIds from '../../../../core/testIds';

import { BriefingMode, BriefingModes, BriefingRoutingModalType } from '../../types';
import { TOOLTIP_DELAY_MS } from '../../configuration';
import { openBriefingRoutingModal } from '../../state/ui';
import { Actions, ActionButton } from '../../utils/styledComponents';
import useBriefingDeletionConfirmationModal from '../../hooks/useBriefingDeletionConfirmationModal';
import useBriefingPermissions from '../../hooks/useBriefingPermissions';
import useBriefingPermissionsModal from '../../hooks/useBriefingPermissionsModal';
import BriefingRoutingModal from '../BriefingRoutingModal';
import BriefingTitle from './BriefingTitle';
import BriefingSummarySubheader from './BriefingSummarySubheader';

interface ViewModeProps {
    briefing: Briefing;
    disableActions?: boolean;
    onModeChange: (newMode: BriefingMode) => void;
}

const strings = componentStrings.briefings.core;

const ViewModeHeader = withRouter<ViewModeProps>(
    ({ briefing, disableActions, router, onModeChange }) => {
        const dispatch = useDispatch();

        const { canCreate, canDelete, canEdit } = useBriefingPermissions(briefing);

        const { openPermissionsModal, permissionsModalProps } = useBriefingPermissionsModal(
            briefing
        );
        const { openDeletionModal, deletionModalProps } = useBriefingDeletionConfirmationModal(
            briefing.id,
            router
        );

        const handleBriefingDuplication = () => {
            dispatch(openBriefingRoutingModal(BriefingRoutingModalType.DUPLICATE, briefing.id));
        };

        const handleBriefingEdit = () => {
            onModeChange(BriefingModes.EDIT);
        };

        const canDuplicate = canCreate && briefing.state !== BriefingStateEnum.DRAFT.name;

        return (
            <Subheader
                title={<BriefingTitle title={briefing.title} briefingState={briefing.state} />}
                content={<BriefingSummarySubheader briefing={briefing} />}
            >
                <Actions>
                    {canDuplicate && (
                        <ActionButton
                            variant="outline"
                            leadingVisual="Duplicate"
                            isTextTransformNone
                            disabled={disableActions}
                            testId={testIds.BRIEFING_DUPLICATE_BUTTON}
                            onClick={handleBriefingDuplication}
                        >
                            {strings.duplicate}
                        </ActionButton>
                    )}
                    {canEdit && (
                        <ActionButton
                            variant="outline"
                            isTextTransformNone
                            disabled={disableActions}
                            testId={testIds.BRIEFING_EDIT_BRIEFING_BUTTON}
                            onClick={handleBriefingEdit}
                        >
                            {strings.editContent}
                        </ActionButton>
                    )}
                    <Tooltip content={strings.managePermissions} delayDuration={TOOLTIP_DELAY_MS}>
                        <IconButton
                            icon="Permissions"
                            disabled={disableActions}
                            aria-label={strings.managePermissions}
                            testId={testIds.BRIEFING_MANAGE_PERMISSIONS_BUTTON}
                            onClick={openPermissionsModal}
                        />
                    </Tooltip>
                    {canDelete && (
                        <Tooltip content={strings.delete} delayDuration={TOOLTIP_DELAY_MS}>
                            <IconButton
                                icon="TrashCan"
                                variant="ghost"
                                disabled={disableActions}
                                aria-label={strings.delete}
                                testId={testIds.BRIEFING_DELETE_BUTTON}
                                onClick={openDeletionModal}
                            />
                        </Tooltip>
                    )}
                </Actions>
                <GenericPermissionsModal {...permissionsModalProps} />
                {canCreate && <BriefingRoutingModal />}
                {canDelete && <ConfirmModal {...deletionModalProps} />}
            </Subheader>
        );
    }
);

export default ViewModeHeader;

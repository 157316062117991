import _, { map, first } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';
import { Skeleton, HStack, cssVar } from 'arc';

import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import { arrestsWhereSelector } from '~/client-common/core/domain/arrests/state/data';
import { personProfileByIdSelector } from '~/client-common/core/domain/person-profiles/state/data';

import Icon, { iconTypes } from '../../../modules/core/components/Icon';
import { doesVacatedChargeExistForReportIdSelector } from '../../../modules/record-privacy/vacating/state/ui';
import {
    isInvolvedPersonSealedForReportIdSelector,
    doesSealedChargeExistForReportIdSelector,
} from '../../../modules/record-privacy/sealing/state/ui';
import { reportCardsSidebarSelector } from '../../../modules/reports/core/state/ui/reportSidebar';
import { embeddedReportsUiSelector } from '../../../modules/reports/core/state/ui/embeddedReports';
import _ApprovalStatusIcon from '../../../modules/records/core/components/ApprovalStatusIcon';
import testIds from '../../../core/testIds';
import { scrollToDataAnchor } from '../../helpers/navigationHelpers';
import { SCROLL_SPY_OFFSET } from '../../configs/reportsConfig';
import ScrollSpy from '../core/ScrollSpy';

const EmbeddedReportCardsWrapper = styled.div`
    position: relative;
    margin-left: 16px;

    &::before {
        position: absolute;
        content: '';
        left: 0;
        height: 100%;
        width: 2px;
        background-color: ${(props) => props.theme.colors.mediumLightGrey};
    }
`;

const EmbeddedReportSidebarWrapper = styled.div`
    margin-left: 17px;
`;

const ReportTitleContainer = styled.div`
    display: inline-flex;
    word-break: break-word;
`;

const ApprovalStatusIcon = styled(_ApprovalStatusIcon)`
    margin-right: 6px;
    vertical-align: middle;
`;

const StatusColoredSquare = styled.div`
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors[props.color]};
    display: inline-block;
    width: 15px;
    height: 12px;
`;

const EmbeddedArrestReportSidebarLoader = ({ approvalStatus }) => {
    return (
        <div>
            <HStack marginLeft={cssVar('arc.space.2')} mb={cssVar('arc.space.1')} spacing={0}>
                <ApprovalStatusIcon approvalStatus={approvalStatus} />
                <Skeleton width="75%" height="1rem" marginBottom={0} />
            </HStack>
            <EmbeddedReportCardsWrapper>
                {_.range(2).map((i) => {
                    return (
                        <HStack
                            key={i}
                            padding={`${cssVar('arc.space.1')} 0px`}
                            marginLeft={cssVar('arc.space.3')}
                            spacing={cssVar('arc.space.2')}
                        >
                            <Icon type={iconTypes.CARD} color="cobaltBlue" size={14} />
                            <Skeleton width="75%" height="1rem" />
                        </HStack>
                    );
                })}
            </EmbeddedReportCardsWrapper>
        </div>
    );
};

const ReportCardsWithEmbeddedReport = ({
    approvalStatus,
    cards,
    embeddedReportShortTitles,
    activeAnchor,
}) => {
    const isInvolvedPersonSealedForReportId = useSelector(
        isInvolvedPersonSealedForReportIdSelector
    );
    const doesSealedChargeExistForReportId = useSelector(doesSealedChargeExistForReportIdSelector);
    const doesVacatedChargeExistForReportId = useSelector(
        doesVacatedChargeExistForReportIdSelector
    );
    const reportCardsSidebar = useSelector(reportCardsSidebarSelector);
    const arrestsWhere = useSelector(arrestsWhereSelector);
    const personProfileById = useSelector(personProfileByIdSelector);
    const embeddedReportsUi = useSelector(embeddedReportsUiSelector);
    const reportCards = map(cards, (card, index) => {
        return card.isEmbeddedReport ? (
            <EmbeddedReportSidebarWrapper
                data-anchor={card.anchor}
                className="report-sidebar-embedded-report-cards"
            >
                {map(embeddedReportShortTitles, (embeddedReportShortTitle, index) => {
                    const reportId = embeddedReportShortTitle.reportId;

                    if (embeddedReportsUi[reportId]?.loading) {
                        return (
                            <EmbeddedArrestReportSidebarLoader
                                key={index}
                                approvalStatus={approvalStatus}
                            />
                        );
                    } else if (embeddedReportsUi[reportId]?.loadedAndVisible) {
                        const arrest = first(
                            arrestsWhere({ reportId: embeddedReportShortTitle.reportId })
                        );
                        const defendant = personProfileById(arrest?.defendantId);

                        return (
                            <div key={index}>
                                <ReportTitleContainer className="report-sidebar-report-title">
                                    <ApprovalStatusIcon
                                        approvalStatus={approvalStatus}
                                        isPartiallySealed={
                                            isInvolvedPersonSealedForReportId(reportId) ||
                                            doesSealedChargeExistForReportId(reportId)
                                        }
                                        isVacated={doesVacatedChargeExistForReportId(reportId)}
                                    />
                                    <span className="report-type">
                                        {`Arrest${
                                            defendant
                                                ? `: ${defendant.firstName} ${defendant.lastName}`
                                                : ''
                                        }`}
                                    </span>
                                </ReportTitleContainer>
                                <EmbeddedReportCardsWrapper
                                    data-test-id={testIds.EMBEDDED_REPORT_SIDEBAR}
                                >
                                    {
                                        <ReportCardsWithEmbeddedReport
                                            cards={reportCardsSidebar(
                                                embeddedReportShortTitle,
                                                true
                                            )}
                                            approvalStatus={approvalStatus}
                                            activeAnchor={activeAnchor}
                                        />
                                    }
                                </EmbeddedReportCardsWrapper>
                            </div>
                        );
                    }
                })}
            </EmbeddedReportSidebarWrapper>
        ) : (
            <ReportCard
                key={index}
                display={card.display}
                anchor={card.anchor}
                approvalStatus={card.anchor === 'report-status-comments-card' && approvalStatus}
                isScrollSpyActive={activeAnchor === card.anchor}
            />
        );
    });
    return <>{reportCards}</>;
};

export const ReportCard = ({ display, anchor, approvalStatus, isScrollSpyActive }) => {
    let color = 'cobaltBlue';

    switch (approvalStatus) {
        case approvalStatusClientEnum.REJECTED:
        case approvalStatusClientEnum.SUBMITTED:
        case approvalStatusClientEnum.PENDING_SECONDARY_REVIEW:
        case approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW:
            color = 'brightYellow';
            break;
        case approvalStatusClientEnum.DRAFT:
            color = 'cobaltBlue';
            break;
        case approvalStatusClientEnum.STAFF_REVIEWED:
        case approvalStatusClientEnum.APPROVED:
        case approvalStatusClientEnum.COMPLETED:
            color = 'darkGreen';
            break;
        default:
            break;
    }

    const testId = approvalStatus
        ? testIds.REPORT_SIDEBAR_SECTION_APPROVAL_STATUS
        : testIds.REPORT_SIDEBAR_SECTION_CARD_TITLE;

    return (
        <li
            className={classNames('report-sidebar-report-card', {
                'scrollspy-active': isScrollSpyActive,
            })}
            data-test-id={testIds.REPORT_SIDEBAR_REPORT_CARD}
            onClick={() => {
                scrollToDataAnchor(anchor, {
                    wrapperSelector: '.mark43-scrollable-under-subheader',
                });
            }}
        >
            <div className="icon-container">
                {approvalStatus ? (
                    <StatusColoredSquare color={color} />
                ) : (
                    <Icon type={iconTypes.CARD} color="cobaltBlue" size={14} />
                )}
            </div>
            <span data-test-id={testId} className="card-display-text">
                {display}
            </span>
        </li>
    );
};

const ReportSidebarCardsScrollSpy = ({
    approvalStatus,
    allAnchors,
    cards,
    embeddedReportShortTitles,
}) => {
    return (
        <ScrollSpy
            constantOffset={SCROLL_SPY_OFFSET}
            anchors={allAnchors}
            className={'report-sidebar-report-cards'}
            render={(activeAnchor) => (
                <ReportCardsWithEmbeddedReport
                    activeAnchor={activeAnchor}
                    approvalStatus={approvalStatus}
                    allAnchors={allAnchors}
                    cards={cards}
                    embeddedReportShortTitles={embeddedReportShortTitles}
                />
            )}
        />
    );
};

export default ReportSidebarCardsScrollSpy;

import { map } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Stack } from 'arc';
import { useResource } from '~/client-common/core/hooks/useResource';
import { formatUrl } from '~/client-common/core/domain/report-external-links/utils';
import componentStrings from '~/client-common/core/strings/componentStrings';
import getExternalLinksResource from '~/client-common/core/domain/external-links/resources/externalLinksResource';
import { ExternalLink } from '../../core/components/links/Link';
import { DashboardLeftSidebarLoader } from './DashboardLoaders';

import { NoDataBlock, DashboardSideBarCard } from './DashboardSection';

const strings = componentStrings.personalDashboard.ExternalLinks;

function DashboardExternalLink({ externalLink }) {
    return (
        <ExternalLink to={formatUrl(externalLink.url)} openInNewTab={true}>
            {externalLink.name}
        </ExternalLink>
    );
}

const DashboardExternalLinks = ({ dragHandleProps, isDragging, hideLoadingBar }) => {
    const [externalLinks, setExternalLinks] = useState([]);

    const loadExternalLinks = useCallback(() => {
        return getExternalLinksResource().getExternalLinks({ hideLoadingBar });
    }, [hideLoadingBar]);

    const onResourceSuccess = useCallback(
        (externalLinks) => {
            setExternalLinks(externalLinks);
        },
        [setExternalLinks]
    );

    const loadingState = useResource(loadExternalLinks, onResourceSuccess);
    const { isLoading, errorMessage } = loadingState;

    let content;

    if (isLoading && externalLinks.length === 0) {
        content = <DashboardLeftSidebarLoader />;
    } else if (errorMessage) {
        content = <NoDataBlock>{strings.loadError}</NoDataBlock>;
    } else if (!isLoading && externalLinks.length === 0) {
        content = <NoDataBlock>{strings.noResults}</NoDataBlock>;
    } else {
        content = map(externalLinks, (externalLink) => (
            <DashboardExternalLink key={externalLink.id} externalLink={externalLink} />
        ));
    }

    return (
        <DashboardSideBarCard
            dragHandleProps={dragHandleProps}
            isDragging={isDragging}
            title={strings.title}
        >
            <Stack spacing="var(--arc-space-1)">{content}</Stack>
        </DashboardSideBarCard>
    );
};

export default DashboardExternalLinks;

import * as React from 'react';
import { DragonCurrentFormValue } from '../rms-types';

type DragonCurrentFormContextValue = DragonCurrentFormValue;

const DragonCurrentFormNestingPathContext = React.createContext<
    DragonCurrentFormContextValue[] | undefined
>(undefined);

const DragonCurrentFormContext = React.createContext<DragonCurrentFormContextValue | undefined>(
    undefined
);

function useComputedPathNestingValue(formToAdd: DragonCurrentFormValue): DragonCurrentFormValue[] {
    const currentNestingPath = useDragonCurrentFormNestingPathContextLoose();
    return React.useMemo(
        () =>
            currentNestingPath
                ? currentNestingPath
                      .filter(
                          (value) =>
                              // we have to ensure that we do not include the synthetic root in the path
                              value.configuredFormId !== 'root' &&
                              value.instanceId !== 'root' &&
                              value.referencingUiConfigurationId !== 'root'
                      )
                      .concat(formToAdd)
                : [formToAdd],
        [currentNestingPath, formToAdd]
    );
}

/*
 * Context used to track the current form while recursively rendering dragon UI layout trees.
 * This context helps us identify which form values need to be pulled from our indexed form values
 * when selecting values as initial form values or during summary mode.
 *
 * This provider will automatically add the current form to the nesting path
 */
export function DragonCurrentFormContextProvider({
    instanceId,
    configuredFormId,
    referencingUiConfigurationId,
    children,
}: React.PropsWithChildren<{
    instanceId: string | number;
    configuredFormId: string | number;
    referencingUiConfigurationId: string;
}>): JSX.Element {
    const value = React.useMemo(
        () => ({ instanceId, configuredFormId, referencingUiConfigurationId }),
        [instanceId, configuredFormId, referencingUiConfigurationId]
    );
    const pathNestingValue = useComputedPathNestingValue(value);
    return (
        <DragonCurrentFormNestingPathContext.Provider value={pathNestingValue}>
            <DragonCurrentFormContext.Provider value={value}>
                {children}
            </DragonCurrentFormContext.Provider>
        </DragonCurrentFormNestingPathContext.Provider>
    );
}

/**
 * Context used to track path nesting for forms. This is effectively the `DragonCurrentFormContextProvider`, but without
 * the effect of marking the passed in form information as the "Current" form. This us useful for inline form references
 * which are inlined into their parent and should be path of the path nesting, but state lookup still have to happen
 * on the non-inline form.
 */
export function DragonFormNestingPathContextProvider({
    instanceId,
    configuredFormId,
    referencingUiConfigurationId,
    children,
}: React.PropsWithChildren<{
    instanceId: string | number;
    configuredFormId: string | number;
    referencingUiConfigurationId: string;
}>): JSX.Element {
    const value = React.useMemo(
        () => ({ instanceId, configuredFormId, referencingUiConfigurationId }),
        [instanceId, configuredFormId, referencingUiConfigurationId]
    );
    const pathNestingValue = useComputedPathNestingValue(value);
    return (
        <DragonCurrentFormNestingPathContext.Provider value={pathNestingValue}>
            {children}
        </DragonCurrentFormNestingPathContext.Provider>
    );
}

export function useDragonCurrentFormContext(): DragonCurrentFormContextValue {
    const value = React.useContext(DragonCurrentFormContext);
    if (!value) {
        throw new Error(
            'Could not find active Dragon form in context. Please ensure that an instance of `DragonCurrentFormContextProvider` has been rendered in the component hierarchy above.'
        );
    }
    return value;
}

function useDragonCurrentFormNestingPathContextLoose():
    | DragonCurrentFormContextValue[]
    | undefined {
    return React.useContext(DragonCurrentFormNestingPathContext);
}

/**
 * Context which returns an array of ancestor forms with configured form id and instance id tuples. This is required
 * for persisting forms in order for query configurations to be able to resolve the right values on the backend
 */
export function useDragonCurrentFormNestingPathContext(): DragonCurrentFormContextValue[] {
    const value = React.useContext(DragonCurrentFormNestingPathContext);
    if (!value) {
        throw new Error(
            'Could not find Dragon form nesting path in context. Please ensure that an instance of `DragonCurrentFormContextProvider` has been rendered in the component hierarchy above.'
        );
    }
    return value;
}

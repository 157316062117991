import { connect } from 'react-redux';
import { includes } from 'lodash';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import { compose, pure } from 'recompose';
import { productModulesSelector } from '../state/data';
import { renderOnlyIf } from '../../../../helpers/reactHelpers';

const createProductModuledComponent = (productModulesSelector) =>
    compose(
        pure,
        connect(
            createStructuredSelector({
                productModules: productModulesSelector,
            })
        ),
        renderOnlyIf(({ productModule, productModules, fallback }) => {
            return includes(productModules, productModule) || fallback;
        })
    )(function ProductModuled({ children, productModules, fallback, productModule, testId }) {
        return (
            <span data-testid={testId}>
                {includes(productModules, productModule) ? children : fallback}
            </span>
        );
    });

export default createProductModuledComponent(productModulesSelector);

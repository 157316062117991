import { map } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { tasksDashboardSearch, clearTasksDashboardFilter } from '../state/ui';
import testIds from '../../../../core/testIds';
import Icon, { iconTypes } from '../../../core/components/Icon';
import Button from '../../../../legacy-redux/components/core/Button';
import Row from '../../../core/components/Row';

const FilterBody = styled.div`
    column-count: 3;
    text-align: left;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.darkGrey};
    background-color: ${(props) => props.theme.colors.white};
    line-height: 15px;
`;

const FilterItem = styled.div`
    padding: 14px;
    display: inline-block;
`;

const FilterTitle = styled.span`
    color: ${(props) => props.theme.colors.mediumGrey};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

const ClearFilterButton = styled(Button)`
    position: relative;
    left: 5px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    float: none;
    min-width: 0;
    padding: 0;
    border: none;
    margin-top: 0;

    &:hover {
        border: none;
    }
`;

function TasksDashboardSearchFilters({ filterGroups, clearTasksDashboardFilter }) {
    return (
        <Row testId={testIds.TASKS_DASHBOARD_FILTERS}>
            <FilterBody>
                {map(filterGroups, (filterGroup) => {
                    return (
                        <div key={filterGroup.path}>
                            <FilterItem>
                                <FilterTitle>{filterGroup.label}:</FilterTitle>
                                <span>
                                    &nbsp;
                                    {filterGroup.display}
                                </span>
                                <ClearFilterButton
                                    onClick={() => clearTasksDashboardFilter(filterGroup.path)}
                                >
                                    <Icon type={iconTypes.CLOSE_X} size={8} color="darkGrey" />
                                </ClearFilterButton>
                            </FilterItem>
                        </div>
                    );
                })}
            </FilterBody>
        </Row>
    );
}

const mapStateToProps = createStructuredSelector({
    filterGroups: tasksDashboardSearch.selectors.filtersSelector,
});

const mapDispatchToProps = { clearTasksDashboardFilter };

export default connect(mapStateToProps, mapDispatchToProps)(TasksDashboardSearchFilters);

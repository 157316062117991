import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttributeTypeEnum, ElasticCourtOrderQuery } from '@mark43/rms-api';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    COURT_ORDER_COURT_CASE_DATE_UTC,
    DISPLAY_ONLY_MODIFIED_DATE_LABEL,
    DISPLAY_ONLY_STATUTE_LABEL,
} from '~/client-common/core/enums/universal/fields';

import advancedSearchCourtOrders from '../state/ui';
import FormSection from '../../../core/forms/components/FormSection';
import AdvancedSearchForm from '../../core/components/AdvancedSearchForm';
import Row from '../../../core/components/Row';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFCourtCodeAttributeSelect } from '../../../core/forms/components/selects/CourtCodeSelect';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';

const strings = componentStrings.search.AdvancedSearchCourtOrdersForm;

type FormDataElasticCourtOrderQuery = {
    courtOrders: ElasticCourtOrderQuery;
};

const AdvancedSearchCourtOrdersForm: React.FC = () => {
    // @ts-expect-error client-common transport to client
    const formIsEmpty = useSelector(advancedSearchCourtOrders.form.selectors.formIsEmptySelector);
    const fuzzyMatchingEnabled = useSelector(
        // @ts-expect-error client-common transport to client
        advancedSearchCourtOrders.form.selectors.buildFormModelSelectorByPath(
            'fuzzyMatchingEnabled'
        )
    );
    const dispatch = useDispatch();
    const onSubmit = () =>
        dispatch(
            // @ts-expect-error client-common transport to client
            advancedSearchCourtOrders.form.actionCreators.submit(
                (formData: FormDataElasticCourtOrderQuery) => {
                    if (!!formData.courtOrders.statuteCodeAttrId) {
                        dispatch(
                            // @ts-expect-error client-common transport to client
                            advancedSearchCourtOrders.actionCreators.setActiveColumnKey(
                                'number',
                                'statuteCode'
                            )
                        );
                    }
                    dispatch(
                        // @ts-expect-error client-common transport to client
                        advancedSearchCourtOrders.actionCreators.search(
                            {
                                formData,
                                from: 0,
                            },
                            { cacheBust: true }
                        )
                    );
                }
            )
        );
    const onReset = () => {
        // @ts-expect-error client-common transport to client
        dispatch(advancedSearchCourtOrders.form.actionCreators.reset());
        // @ts-expect-error client-common transport to client
        dispatch(advancedSearchCourtOrders.actionCreators.resetState());
    };

    const fieldsDisplayName = useFields([
        COURT_ORDER_COURT_CASE_DATE_UTC,
        DISPLAY_ONLY_MODIFIED_DATE_LABEL,
        DISPLAY_ONLY_STATUTE_LABEL,
    ]);

    return (
        <AdvancedSearchForm
            // @ts-expect-error client-common transport to client
            {...advancedSearchCourtOrders.form}
            onSubmit={onSubmit}
            onReset={onReset}
            formIsEmpty={formIsEmpty}
            searchText={strings.search}
            fuzzyMatchingEnabled={fuzzyMatchingEnabled}
        >
            <FormSection>
                <RRFFieldset path="courtOrders">
                    <Row>
                        <RRFAttributeSelect
                            attributeType={AttributeTypeEnum.SEALING_STATUTE_CODE.name}
                            path="statuteCodeAttrId"
                            label={fieldsDisplayName[DISPLAY_ONLY_STATUTE_LABEL]}
                            shouldShowFieldNames={true}
                            width={257}
                        />
                    </Row>
                    <Row>
                        <RRFText path="courtCaseNumber" width={257} />
                        <RRFCourtCodeAttributeSelect
                            currentContext={formClientEnum.ADVANCED_SEARCH_COURT_ORDERS}
                            path="courtCodeAttrId"
                            width={257}
                        />
                        <RRFDateRangePicker
                            label={fieldsDisplayName[COURT_ORDER_COURT_CASE_DATE_UTC]}
                            width={257}
                            withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                            toDatePeriodOptions={['P1M', 'P1Y']}
                            granularity="day"
                            paths={{
                                withinLastPeriod: 'courtCaseDateRangeQuery.withinLastPeriod',
                                toDatePeriod: 'courtCaseDateRangeQuery.toDatePeriod',
                                startDateUtc: 'courtCaseDateRangeQuery.startDateUtc',
                                endDateUtc: 'courtCaseDateRangeQuery.endDateUtc',
                            }}
                        />
                    </Row>
                    <Row>
                        <RRFText path="arrestNumber" width={257} />
                        <RRFText path="stateIdNumber" width={257} />
                        <RRFDateRangePicker
                            label={fieldsDisplayName[DISPLAY_ONLY_MODIFIED_DATE_LABEL]}
                            width={257}
                            granularity="day"
                            withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                            toDatePeriodOptions={['P1M', 'P1Y']}
                            paths={{
                                withinLastPeriod: 'modifiedDateRangeQuery.withinLastPeriod',
                                toDatePeriod: 'modifiedDateRangeQuery.toDatePeriod',
                                startDateUtc: 'modifiedDateRangeQuery.startDateUtc',
                                endDateUtc: 'modifiedDateRangeQuery.endDateUtc',
                            }}
                        />
                    </Row>
                    <Row>
                        <RRFText path="involvedPersonFullName" width={257} />
                    </Row>
                </RRFFieldset>
            </FormSection>
        </AdvancedSearchForm>
    );
};

export default AdvancedSearchCourtOrdersForm;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName, mapProps } from 'recompose';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { CASE_CASE_DEFINITION_ID } from '~/client-common/core/enums/universal/fields';
import { caseDefinitionViewModelsSelector } from '~/client-common/core/domain/case-definitions/state/ui';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import { arbiterMFTInput } from '../../../arbiter';
import { caseDefinitionOptionsSelector } from '../../../../cases/core/state/ui';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { EXPIRED } = attributeStatuses;

const { connectRRFInput } = reactReduxFormHelpers;

/**
 * Select dropdown containing all case definitions (case types).
 * @param {Object} props Same props as `Select`.
 */
const CaseDefinitionSelect = compose(
    setDisplayName('CaseDefinitionSelect'),
    connect(
        createStructuredSelector({
            caseDefinitionOptions: caseDefinitionOptionsSelector,
            caseDefinitionViewModels: caseDefinitionViewModelsSelector,
        })
    ),
    mapProps((props) => {
        const {
            caseDefinitionOptions,
            caseDefinitionViewModels,
            includeExpired = false,
            value,
        } = props;
        let options = caseDefinitionOptions(includeExpired);
        // if currently selected value is expired, display it
        if (
            value &&
            caseDefinitionViewModels[value] &&
            caseDefinitionViewModels[value].status === EXPIRED
        ) {
            options = [
                {
                    value,
                    display: caseDefinitionViewModels[value].name,
                    status: EXPIRED,
                    noteDisplay: '(expired)',
                },
                ...options,
            ];
        }
        return {
            options,
            ...props,
        };
    })
)(function CaseDefinitionSelect({ options, ...otherProps }) {
    const caseTypeLabel = useFields(CASE_CASE_DEFINITION_ID)[CASE_CASE_DEFINITION_ID];
    return <Select label={caseTypeLabel} options={options} {...otherProps} />;
});

export const RRFCaseDefinitionSelect = connectRRFInput(CaseDefinitionSelect);

export const ArbiterMFTCaseDefinitionSelect = arbiterMFTInput(CaseDefinitionSelect);

import { first, map } from 'lodash';
import { createSelector } from 'reselect';

// helpers
import {
    allSingleAttributeValuesMapper,
    buildViewModel,
} from '../../../../../helpers/viewModelHelpers';

// selectors
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { reportCaseStatusesWhereSelector } from '../data';

const buildReportCaseStatusViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    (formatAttributeById) => {
        const viewModel = buildViewModel({
            mappers: [allSingleAttributeValuesMapper],
            helpers: { formatAttributeById },
        });

        return viewModel;
    }
);

const reportCaseStatusViewModelsWhereSelector = createSelector(
    reportCaseStatusesWhereSelector,
    buildReportCaseStatusViewModelSelector,
    (reportCaseStatusesWhere, buildReportCaseStatusViewModel) => (predicate) =>
        map(reportCaseStatusesWhere(predicate), buildReportCaseStatusViewModel)
);

// Only 1 `ReportCaseStatus` for a report
export const reportCaseStatusViewModelByReportIdSelector = createSelector(
    reportCaseStatusViewModelsWhereSelector,
    (reportCaseStatusViewModelsWhere) => (reportId) =>
        first(reportCaseStatusViewModelsWhere({ reportId }))
);

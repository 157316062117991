import { CliArgumentTypeEnum, UnitStateGroupEnum } from '@mark43/rms-api';

const { TOKEN, COMMAND, FORM_IDENTIFIER } = CliArgumentTypeEnum;

const EVENT_RELATED_UNIT_STATE_GROUPS_WITHOUT_FORMS = [
    UnitStateGroupEnum.DISPATCHED.name,
    UnitStateGroupEnum.EN_ROUTE.name,
    UnitStateGroupEnum.EN_ROUTE_ALTERNATE.name,
    UnitStateGroupEnum.AT_SCENE.name,
    UnitStateGroupEnum.REPORT_WRITING.name,
    UnitStateGroupEnum.PURSUIT_EN_ROUTE.name,
    UnitStateGroupEnum.PURSUIT_ARRIVED.name,
    UnitStateGroupEnum.RESPONDING_COURT_EN_ROUTE.name,
    UnitStateGroupEnum.RESPONDING_COURT_ARRIVED.name,
];

/**
 * KEY: will be used as default display name (prettify(KEY))
 * name: used as unique identifier
 * value: matched server enum value
 * argumentType: TOKEN | FORM_IDENTIFIER | COMMAND
 * featureFlag: cli is available if given feature flag is enabled
 * display: will use this as display value instead of prettify KEY, if provided
 * adminHint & prefixHelp: additional text displayed for hint
 */
export default {
    // TOKENS
    LOCATION: {
        name: 'LOCATION',
        argumentType: TOKEN.name,
        value: 1,
    },
    EVENT: {
        name: 'EVENT',
        argumentType: TOKEN.name,
        value: 2,
    },
    COMMENT: {
        name: 'COMMENT',
        argumentType: TOKEN.name,
        value: 3,
    },
    LICENSE_PLATE: {
        name: 'LICENSE_PLATE',
        argumentType: TOKEN.name,
        value: 4,
    },
    FORM_SEPARATOR: {
        name: 'FORM_SEPARATOR',
        argumentType: TOKEN.name,
        value: 5,
    },
    FORM_DELIMITER: {
        name: 'FORM_DELIMITER',
        argumentType: TOKEN.name,
        value: 6,
    },
    DATA_EXCHANGE_INQUIRY_PARAMETERS: {
        name: 'DATA_EXCHANGE_INQUIRY_PARAMETERS',
        argumentType: TOKEN.name,
        value: 48,
    },
    IS_HIGH_PRIORITY: {
        name: 'IS_HIGH_PRIORITY',
        argumentType: TOKEN.name,
        value: 69,
    },

    // FORM IDENTIFIERS
    DISPATCH_AREA: {
        name: 'DISPATCH_AREA',
        argumentType: FORM_IDENTIFIER.name,
        value: 7,
    },
    MEMBER: {
        name: 'MEMBER',
        argumentType: FORM_IDENTIFIER.name,
        value: 8,
    },
    VEHICLE: {
        name: 'VEHICLE',
        argumentType: FORM_IDENTIFIER.name,
        value: 9,
    },
    CALL_FOR_SERVICE: {
        name: 'CALL_FOR_SERVICE',
        argumentType: FORM_IDENTIFIER.name,
        value: 10,
    },
    DISPOSITION: {
        name: 'DISPOSITION',
        argumentType: FORM_IDENTIFIER.name,
        value: 11,
    },
    UNIT: {
        name: 'UNIT',
        argumentType: FORM_IDENTIFIER.name,
        value: 12,
    },
    AGENCY: {
        name: 'AGENCY',
        argumentType: FORM_IDENTIFIER.name,
        value: 36,
    },
    DISPATCH_AREA_GROUP: {
        name: 'DISPATCH_AREA_GROUP',
        argumentType: FORM_IDENTIFIER.name,
        value: 39,
    },
    STATION: {
        name: 'STATION',
        argumentType: FORM_IDENTIFIER.name,
        value: 50,
    },
    MESSAGE_GROUP: {
        name: 'MESSAGE_GROUP',
        argumentType: FORM_IDENTIFIER.name,
        value: 67,
    },
    CALL_TAKER_STATION: {
        name: 'CALL_TAKER_STATION',
        argumentType: FORM_IDENTIFIER.name,
        value: 68,
    },
    UNIT_TAG: {
        name: 'UNIT_TAG',
        argumentType: FORM_IDENTIFIER.name,
        value: 70,
    },
    TIME: {
        name: 'TIME',
        argumentType: FORM_IDENTIFIER.name,
        value: 92,
    },
    STAFF_COUNT: {
        name: 'STAFF_COUNT',
        argumentType: FORM_IDENTIFIER.name,
        value: 115,
    },
    HOSPITAL: {
        argumentType: FORM_IDENTIFIER.name,
        name: 'HOSPITAL',
        value: 118,
    },
    ORI_ALIAS: {
        argumentType: FORM_IDENTIFIER.name,
        name: 'ORI ALIAS',
        value: 119,
    },

    // COMMANDS
    RESYNC: {
        name: 'RESYNC',
        argumentType: COMMAND.name,
        value: 13,
    },
    VIEW_EVENT: {
        name: 'VIEW_EVENT',
        argumentType: COMMAND.name,
        value: 14,
    },
    EDIT_EVENT: {
        name: 'EDIT_EVENT',
        argumentType: COMMAND.name,
        value: 15,
    },
    VIEW_EVENT_HISTORY: {
        name: 'VIEW_EVENT_HISTORY',
        argumentType: COMMAND.name,
        value: 16,
    },
    LOGOUT: {
        name: 'LOGOUT',
        argumentType: COMMAND.name,
        value: 17,
    },
    CHANGE_UNIT_LOCATION: {
        name: 'CHANGE_UNIT_LOCATION',
        argumentType: COMMAND.name,
        value: 18,
    },
    MOVE_UNIT_TO_STATE: {
        name: 'MOVE_UNIT_TO_STATE',
        argumentType: COMMAND.name,
        value: 19,
        hasUnitStateId: true,
        allowedUnitStateGroups: [
            UnitStateGroupEnum.UNMANNED.name,
            UnitStateGroupEnum.UNAVAILABLE.name,
            UnitStateGroupEnum.AVAILABLE.name,
            UnitStateGroupEnum.ADMIN.name,
            UnitStateGroupEnum.DISPATCHED.name,
            UnitStateGroupEnum.EN_ROUTE.name,
            UnitStateGroupEnum.EN_ROUTE_ALTERNATE.name,
            UnitStateGroupEnum.AT_SCENE.name,
            UnitStateGroupEnum.REPORT_WRITING.name,
            UnitStateGroupEnum.PURSUIT_EN_ROUTE.name,
            UnitStateGroupEnum.PURSUIT_ARRIVED.name,
            UnitStateGroupEnum.RESPONDING_COURT_EN_ROUTE.name,
            UnitStateGroupEnum.RESPONDING_COURT_ARRIVED.name,
            UnitStateGroupEnum.SERVICE_ASSIGNMENT.name,
            UnitStateGroupEnum.STAGING.name,
            UnitStateGroupEnum.AVAILABLE_EVENT_RELATED.name,
            UnitStateGroupEnum.AVAILABLE_NON_EVENT_RELATED.name,
            UnitStateGroupEnum.OUT_OF_SERVICE.name,
        ],
    },
    CLEAR_EVENT_WITH_PRIMARY_DISPOSITION: {
        name: 'CLEAR_EVENT_WITH_PRIMARY_DISPOSITION',
        argumentType: COMMAND.name,
        value: 29,
        hasDispositionAttrId: true,
    },
    CLEAR_EVENT: {
        name: 'CLEAR_EVENT',
        argumentType: COMMAND.name,
        value: 20,
    },
    HELP: {
        name: 'HELP',
        argumentType: COMMAND.name,
        value: 21,
    },
    LOGOFF_UNITS: {
        name: 'LOGOFF_UNITS',
        argumentType: COMMAND.name,
        value: 22,
    },
    ASSIGN_UNITS_AS_BACKUP: {
        name: 'ASSIGN_UNITS_AS_BACKUP',
        argumentType: COMMAND.name,
        value: 23,
        hasUnitStateId: true,
        allowedUnitStateGroups: EVENT_RELATED_UNIT_STATE_GROUPS_WITHOUT_FORMS,
    },
    ADDITIONAL_INFO: {
        name: 'ADDITIONAL_INFO',
        argumentType: COMMAND.name,
        value: 24,
        canBeRepeated: true,
        canCommandBeDuplicated: true,
    },
    GENERATE_REN: {
        name: 'GENERATE_REN',
        argumentType: COMMAND.name,
        value: 25,
    },
    LOGON_UNIT: {
        name: 'LOGON_UNIT',
        argumentType: COMMAND.name,
        value: 26,
    },
    RELEASE_UNITS: {
        name: 'RELEASE_UNITS',
        argumentType: COMMAND.name,
        value: 27,
    },
    ASSIGN_UNITS_TO_EVENT: {
        name: 'ASSIGN_UNITS_TO_EVENT',
        argumentType: COMMAND.name,
        value: 28,
        hasUnitStateId: true,
        allowedUnitStateGroups: EVENT_RELATED_UNIT_STATE_GROUPS_WITHOUT_FORMS,
    },
    LINK_EVENTS: {
        name: 'LINK_EVENTS',
        argumentType: COMMAND.name,
        value: 30,
        display: 'Merge Events',
    },
    REACTIVATE_EVENT: {
        name: 'REACTIVATE_EVENT',
        argumentType: COMMAND.name,
        value: 31,
    },
    PRIMARY_UNIT: {
        name: 'PRIMARY_UNIT',
        argumentType: COMMAND.name,
        value: 32,
    },
    SELF_INITIATE: {
        name: 'SELF_INITIATE',
        argumentType: COMMAND.name,
        value: 33,
        hasEventOriginAttrId: true,
    },
    VIEW_UNIT_HISTORY: {
        name: 'VIEW_UNIT_HISTORY',
        argumentType: COMMAND.name,
        value: 34,
    },
    CROSS_CLASS_ADDITIONAL_INFO: {
        name: 'CROSS_CLASS_ADDITIONAL_INFO',
        argumentType: COMMAND.name,
        value: 35,
        canBeRepeated: true,
    },
    TRANSFER_EVENT_DISPATCH_AREA: {
        name: 'TRANSFER_EVENT_DISPATCH_AREA',
        argumentType: COMMAND.name,
        value: 37,
        canBeRepeated: false,
    },
    REASSIGN_UNIT_DISPATCH_AREA: {
        name: 'REASSIGN_UNIT_DISPATCH_AREA',
        argumentType: COMMAND.name,
        value: 38,
        canBeRepeated: false,
    },
    TAKE_DISPATCH_AREA: {
        name: 'TAKE_DISPATCH_AREA',
        argumentType: COMMAND.name,
        value: 40,
        canBeRepeated: false,
    },
    SEND_DISPATCH_AREAS: {
        name: 'SEND_DISPATCH_AREAS',
        argumentType: COMMAND.name,
        value: 41,
        canBeRepeated: false,
    },
    MONITOR_DISPATCH_AREA: {
        name: 'MONITOR_DISPATCH_AREA',
        argumentType: COMMAND.name,
        value: 42,
        canBeRepeated: false,
    },
    MONITOR_ALL_DISPATCH_AREAS: {
        name: 'MONITOR_ALL_DISPATCH_AREAS',
        argumentType: COMMAND.name,
        value: 43,
        canBeRepeated: false,
    },
    UNMONITOR_DISPATCH_AREA: {
        name: 'UNMONITOR_DISPATCH_AREA',
        argumentType: COMMAND.name,
        value: 44,
        canBeRepeated: false,
    },
    UNMONITOR_ALL_DISPATCH_AREAS: {
        name: 'UNMONITOR_ALL_DISPATCH_AREAS',
        argumentType: COMMAND.name,
        value: 45,
        canBeRepeated: false,
    },
    RELINQUISH_ALL_DISPATCH_AREAS: {
        name: 'RELINQUISH_ALL_DISPATCH_AREAS',
        argumentType: COMMAND.name,
        value: 46,
        canBeRepeated: false,
    },
    QUERY_PLATE: {
        name: 'QUERY_PLATE',
        argumentType: COMMAND.name,
        value: 47,
        canBeRepeated: false,
        isDexCommand: true,
    },
    QUERY_VIN: {
        name: 'QUERY_VIN',
        argumentType: COMMAND.name,
        value: 49,
        canBeRepeated: false,
        isDexCommand: true,
    },
    MOVE_UNIT_TO_STATION: {
        name: 'MOVE_UNIT_TO_STATION',
        argumentType: COMMAND.name,
        value: 51,
        canBeRepeated: false,
    },
    MOVE_UNIT_TO_HOME_STATION: {
        name: 'MOVE_UNIT_TO_HOME_STATION',
        argumentType: COMMAND.name,
        value: 52,
        canBeRepeated: false,
    },
    QUERY_DRIVER_LICENSE: {
        name: 'QUERY_DRIVER_LICENSE',
        argumentType: COMMAND.name,
        value: 53,
        canBeRepeated: false,
        isDexCommand: true,
    },
    ROTATION_SERVICE_TYPE: {
        name: 'ROTATION_SERVICE_TYPE',
        argumentType: FORM_IDENTIFIER.name,
        value: 54,
        canBeRepeated: false,
    },
    OPEN_ROTATION_FOR_EVENT: {
        name: 'OPEN_ROTATION_FOR_EVENT',
        argumentType: COMMAND.name,
        value: 55,
        canBeRepeated: false,
    },
    QUERY_PERSON_NAME: {
        name: 'QUERY_PERSON_NAME',
        argumentType: COMMAND.name,
        value: 56,
        canBeRepeated: false,
        isDexCommand: true,
    },
    QUERY_GUN_SERIAL: {
        name: 'QUERY_GUN_SERIAL',
        argumentType: COMMAND.name,
        value: 57,
        canBeRepeated: false,
        isDexCommand: true,
    },
    OPEN_DEX_RESULTS: {
        name: 'OPEN_DEX_RESULTS',
        argumentType: COMMAND.name,
        value: 58,
        canBeRepeated: false,
        isDexCommand: true,
    },
    OPEN_DEX_VEHICLE_FORM: {
        name: 'OPEN_DEX_VEHICLE_FORM',
        argumentType: COMMAND.name,
        value: 59,
        canBeRepeated: false,
        isDexCommand: true,
    },
    OPEN_DEX_PERSON_FORM: {
        name: 'OPEN_DEX_PERSON_FORM',
        argumentType: COMMAND.name,
        value: 60,
        canBeRepeated: false,
        isDexCommand: true,
    },
    LOGON_TEMPORARY_UNIT: {
        name: 'LOGON_TEMPORARY_UNIT',
        argumentType: COMMAND.name,
        value: 61,
        canBeRepeated: false,
    },
    EDIT_UNIT: {
        name: 'EDIT_UNIT',
        argumentType: COMMAND.name,
        value: 62,
        canBeRepeated: false,
    },
    HOLD_UNITS_FOR_EVENT: {
        name: 'HOLD_UNITS_FOR_EVENT',
        argumentType: COMMAND.name,
        value: 63,
        canBeRepeated: false,
    },
    REMOVE_HELD_UNIT: {
        name: 'REMOVE_HELD_UNIT',
        argumentType: COMMAND.name,
        value: 64,
        canBeRepeated: false,
    },
    QUERY_LOJACK: {
        name: 'QUERY_LOJACK',
        argumentType: COMMAND.name,
        value: 65,
        canBeRepeated: false,
        isDexCommand: true,
    },
    SEND_MESSAGE: {
        name: 'SEND_MESSAGE',
        argumentType: COMMAND.name,
        value: 66,
        canBeRepeated: false,
    },
    AGENCY_ASSISTANCE: {
        name: 'AGENCY_ASSISTANCE',
        argumentType: COMMAND.name,
        value: 71,
        featureFlag: 'CAD_EXTERNAL_AGENCIES_ENABLED',
    },
    ADD_POLICE: {
        name: 'ADD_POLICE',
        argumentType: COMMAND.name,
        value: 72,
        featureFlag: 'CAD_EXTERNAL_AGENCIES_ENABLED',
    },
    ADD_FIRE: {
        name: 'ADD_FIRE',
        argumentType: COMMAND.name,
        value: 73,
        featureFlag: 'CAD_EXTERNAL_AGENCIES_ENABLED',
    },
    ADD_EMS: {
        name: 'ADD_EMS',
        argumentType: COMMAND.name,
        value: 74,
        featureFlag: 'CAD_EXTERNAL_AGENCIES_ENABLED',
    },
    UPDATE_EVENT_LOCATION: {
        name: 'UPDATE_EVENT_LOCATION',
        argumentType: COMMAND.name,
        value: 75,
        featureFlag: 'ALLOW_UNIT_GPS_FOR_LOCATION',
    },
    UPDATE_ALARM_LEVEL: {
        name: 'UPDATE_ALARM_LEVEL',
        argumentType: COMMAND.name,
        value: 76,
        hasAlarmLevelId: true,
        featureFlag: 'CAD_AGENCY_EVENT_ALARM_LEVEL_ENABLED',
    },
    CLEAR_UNIT_LOCATION: {
        name: 'CLEAR_UNIT_LOCATION',
        argumentType: COMMAND.name,
        value: 78,
        hasAlarmLevelId: false,
        canBeRepeated: true,
    },
    // Temporarily removed so that it will unblock. To be readded post merge.
    TRANSPORT_UNIT: {
        name: 'TRANSPORT_UNIT',
        argumentType: COMMAND.name,
        value: 79,
        hasUnitStateId: true,
        allowedUnitStateGroups: [
            UnitStateGroupEnum.TRANSPORT_ARRIVED.name,
            UnitStateGroupEnum.TRANSPORT_EN_ROUTE.name,
            UnitStateGroupEnum.HOSPITAL_TRANSPORT_EN_ROUTE.name,
            UnitStateGroupEnum.HOSPITAL_TRANSPORT_ARRIVED.name,
        ],
    },
    FORMATTED_ADDITIONAL_INFO: {
        name: 'FORMATTED_ADDITIONAL_INFO',
        argumentType: COMMAND.name,
        value: 80,
        canBeRepeated: true,
        hasPrefix: true,
        hasSuffix: true,
        canCommandBeDuplicated: true,
        prefixHelp:
            'Prefix and suffix will not automatically add spaces between words Add spaces before or after as desired. You can also use {CALLSIGN} and the command will automatically replace it with the unit call sign.',
    },
    CROSS_CLASS_FORMATTED_ADDITIONAL_INFO: {
        name: 'CROSS_CLASS_FORMATTED_ADDITIONAL_INFO',
        argumentType: COMMAND.name,
        value: 81,
        canBeRepeated: true,
        hasPrefix: true,
        hasSuffix: true,
        canCommandBeDuplicated: true,
        prefixHelp:
            'Prefix and suffix will not automatically add spaces between words Add spaces before or after as desired. You can also use {CALLSIGN} and the command will automatically replace it with the unit call sign.',
    },
    SWAP_UNITS: {
        name: 'SWAP_UNITS',
        argumentType: COMMAND.name,
        value: 82,
        adminHint:
            'Command will swap any events and statuses for the supplied units but will not apply to transport.',
    },
    RADIO_TRAFFIC: {
        name: 'RADIO_TRAFFIC',
        argumentType: COMMAND.name,
        value: 83,
        canBeRepeated: true,
        hasPrefix: true,
        hasSuffix: true,
        canCommandBeDuplicated: true,
        prefixHelp:
            'Prefix and suffix will not automatically add spaces between words Add spaces before or after as desired. You can also use {CALLSIGN} and the command will automatically replace it with the unit call sign.',
    },
    UPDATE_EVENT_CALL_FOR_SERVICE: {
        name: 'UPDATE_EVENT_CALL_FOR_SERVICE',
        argumentType: COMMAND.name,
        value: 84,
        canBeRepeated: true,
        canCommandBeDuplicated: true,
    },
    CROSS_CLASS_RADIO_TRAFFIC: {
        name: 'CROSS_CLASS_RADIO_TRAFFIC',
        argumentType: COMMAND.name,
        value: 87,
        canBeRepeated: true,
        hasPrefix: true,
        hasSuffix: true,
        canCommandBeDuplicated: true,
        prefixHelp:
            'Prefix and suffix will not automatically add spaces between words Add spaces before or after as desired. You can also use {CALLSIGN} and the command will automatically replace it with the unit call sign.',
    },
    SHOW_ON_MAP: {
        name: 'SHOW_ON_MAP',
        argumentType: COMMAND.name,
        value: 88,
        canBeRepeated: false,
        canCommandBeDuplicated: false,
    },
    REASSIGN_UNIT_PRIMARY_SUBDIVISION: {
        name: 'REASSIGN_UNIT_PRIMARY_SUBDIVISION',
        argumentType: COMMAND.name,
        value: 85,
        hasAlarmLevelId: false,
        canBeRepeated: true,
    },
    PRIMARY_SUBDIVISION: {
        name: 'PRIMARY_SUBDIVISION',
        argumentType: FORM_IDENTIFIER.name,
        value: 86,
    },
    CLEAR_UNIT_TIMER: {
        name: 'CLEAR_UNIT_TIMER',
        argumentType: COMMAND.name,
        value: 90,
    },
    RESET_UNIT_TIMER: {
        name: 'RESET_UNIT_TIMER',
        argumentType: COMMAND.name,
        value: 91,
    },
    OPEN_BOLO_FORM: {
        name: 'OPEN_BOLO_FORM',
        argumentType: COMMAND.name,
        value: 93,
        canBeRepeated: false,
        canCommandBeDuplicated: false,
    },
    QUERY_PLATE_WITH_PURPOSE_CODE: {
        name: 'QUERY_PLATE_WITH_PURPOSE_CODE',
        argumentType: COMMAND.name,
        value: 94,
        canBeRepeated: false,
        isDexCommand: true,
    },
    QUERY_VIN_WITH_PURPOSE_CODE: {
        name: 'QUERY_VIN_WITH_PURPOSE_CODE',
        argumentType: COMMAND.name,
        value: 95,
        canBeRepeated: false,
        isDexCommand: true,
    },
    QUERY_DRIVER_LICENSE_WITH_PURPOSE_CODE: {
        name: 'QUERY_DRIVER_LICENSE_WITH_PURPOSE_CODE',
        argumentType: COMMAND.name,
        value: 96,
        canBeRepeated: false,
        isDexCommand: true,
    },
    QUERY_PERSON_NAME_WITH_PURPOSE_CODE: {
        name: 'QUERY_PERSON_NAME_WITH_PURPOSE_CODE',
        argumentType: COMMAND.name,
        value: 97,
        canBeRepeated: false,
        isDexCommand: true,
    },
    QUERY_GUN_SERIAL_WITH_PURPOSE_CODE: {
        name: 'QUERY_GUN_SERIAL_WITH_PURPOSE_CODE',
        argumentType: COMMAND.name,
        value: 98,
        canBeRepeated: false,
        isDexCommand: true,
    },
    SELF_INITIATE_WITH_PURPOSE_CODE: {
        name: 'SELF_INITIATE_WITH_PURPOSE_CODE',
        argumentType: COMMAND.name,
        value: 99,
        hasEventOriginAttrId: true,
    },
    QUERY_LOJACK_WITH_PURPOSE_CODE: {
        name: 'QUERY_LOJACK_WITH_PURPOSE_CODE',
        argumentType: COMMAND.name,
        value: 100,
        canBeRepeated: false,
        isDexCommand: true,
    },
    ADD_UNIT_NOTES: {
        name: 'ADD_UNIT_NOTES',
        argumentType: COMMAND.name,
        value: 101,
    },
    CLEAR_UNIT_NOTES: {
        name: 'CLEAR_UNIT_NOTES',
        argumentType: COMMAND.name,
        value: 102,
    },
    AGENCY_EVENT_RADIO_CHANNELS: {
        name: 'AGENCY_EVENT_RADIO_CHANNELS',
        argumentType: FORM_IDENTIFIER.name,
        value: 103,
    },
    CHANGE_PRIMARY_EVENT_RADIO_CHANNEL: {
        name: 'CHANGE_PRIMARY_EVENT_RADIO_CHANNEL',
        argumentType: COMMAND.name,
        value: 104,
    },
    ASSIGN_ADDITIONAL_EVENT_RADIO_CHANNELS: {
        name: 'ASSIGN_ADDITIONAL_EVENT_RADIO_CHANNELS',
        argumentType: COMMAND.name,
        value: 105,
    },
    ASSOCIATE_EVENTS: {
        name: 'ASSOCIATE_EVENTS',
        argumentType: COMMAND.name,
        value: 107,
        featureFlag: 'CAD_ASSOCIATED_EVENTS_ENABLED',
    },
    UNASSOCIATE_EVENTS: {
        name: 'UNASSOCIATE_EVENTS',
        argumentType: COMMAND.name,
        value: 108,
        featureFlag: 'CAD_ASSOCIATED_EVENTS_ENABLED',
    },
    CAD_REQUEST_PROFILE: {
        name: 'CAD_REQUEST_PROFILE',
        argumentType: FORM_IDENTIFIER.name,
        value: 109,
    },
    RADIO_TRAFFIC_ALL_AGENCIES: {
        name: 'RADIO_TRAFFIC_ALL_AGENCIES',
        argumentType: COMMAND.name,
        value: 110,
    },
    QUERY_DRIVER_HISTORY_BY_NAME: {
        name: 'QUERY_DRIVER_HISTORY_BY_NAME',
        argumentType: COMMAND.name,
        value: 111,
    },
    QUERY_DRIVER_HISTORY_BY_LICENSE: {
        name: 'QUERY_DRIVER_HISTORY_BY_LICENSE',
        argumentType: COMMAND.name,
        value: 112,
    },
    CHANGE_EVENT_AGENCY: {
        name: 'CHANGE_EVENT_AGENCY',
        argumentType: COMMAND.name,
        value: 113,
    },
    QUERY_PERSON_NAME_WITH_MIDDLE_NAME: {
        name: 'QUERY_PERSON_NAME_WITH_MIDDLE_NAME',
        argumentType: COMMAND.name,
        value: 114,
        canBeRepeated: false,
        isDexCommand: true,
    },
    UPDATE_UNIT_STAFF_COUNT: {
        name: 'UPDATE_UNIT_STAFF_COUNT',
        argumentType: COMMAND.name,
        value: 116,
    },
    OPEN_EDIT_HOSPITAL_FORM: {
        name: 'OPEN_EDIT_HOSPITAL_FORM',
        isDexCommand: false,
        value: 117,
        argumentType: COMMAND.name,
    },
} as const;

import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import _, { forEach, includes, map, some } from 'lodash';

import {
    ElasticSearchTypeEnum,
    EntityTypeEnum,
    RefContextEnum,
    TaskEntityLinkView,
} from '@mark43/rms-api';
import { Observer } from 'markformythree';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatCaseDefinitionByIdSelector } from '~/client-common/core/domain/case-definitions/state/data/';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { formatTitleForElasticVehicleSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { useAbilitySelector } from '../../../core/current-user/hooks/useAbilitySelector';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { SidePanelSection } from '../../../../legacy-redux/components/core/SidePanel';
import ReactHotKeysWrapper from '../../../core/hotkeys/components/ReactHotKeysWrapper';
import { Tooltip } from '../../../core/components/tooltip';
import SidePanel from '../../../core/overlays/components/SidePanel';
import {
    QuickSearch,
    QuickSearchWrapper,
    ResultsWrapper,
} from '../../../search/quick-search/components/QuickSearch';
import {
    QuickSearchBase as UpdatedQuickSearch,
    QuickSearchWrapper as UpdatedQuickSearchWrapper,
    ResultsWrapper as UpdatedResultsWrapper,
} from '../../../search/quick-search/components/UpdatedQuickSearch';
import { QuickSearchResultSearchTypes } from '../../../search/quick-search/config';
import { useQuickSearch } from '../../../search/quick-search/hooks/useQuickSearch';
import {
    getSelectedResult,
    SearchResultWithType,
} from '../../../search/quick-search/helpers/selectedResultHelpers';
import type { TaskOverlayCustomProperties } from '../state/ui';
import { allowedTaskLinkEntitySearchTypes } from '../config';
import { getTaskEntityLinkTitleForSearchResult } from '../utils/getTaskEntityLinkTitle';
import { FormTaskConfiguration } from '../forms/taskForm';
import testIds from '../../../../core/testIds';
import LinkedEntitySummary from './LinkedEntitySummary';

type SelectedDetails = {
    event: React.SyntheticEvent;
    index: number;
    itemType: QuickSearchResultSearchTypes;
};

const strings = componentStrings.tasks.core.TaskEntitiesLinkSidePanel;

const SearchWrapper = styled.div`
    ${/* sc-selector */ QuickSearchWrapper} {
        position: unset;
        width: 415px;
        padding: 20px 0 10px 30px;
        box-shadow: none;
    }
    ${/* sc-selector */ ResultsWrapper} {
        clear: both;
        box-shadow: ${(props) => `0 5px 12px ${props.theme.colors.mediumLightGrey}`};
    }
    ${/* sc-selector */ UpdatedQuickSearchWrapper} {
        width: 100%;
        padding: var(--arc-space-6);
        padding-bottom: var(--arc-space-2);
        max-height: 100%;
    }
    ${/* sc-selector */ UpdatedResultsWrapper} {
        box-shadow: ${(props) => `0 5px 12px ${props.theme.colors.mediumLightGrey}`};
    }

    .mark43-form-field {
        width: 100%;
        margin-bottom: unset;
    }
`;

const TaskEntitiesLinkSidePanel = () => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const formatCaseDefinitionById = useSelector(formatCaseDefinitionByIdSelector);
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const formatTitleForElasticVehicle = useSelector(formatTitleForElasticVehicleSelector);

    const applicationSettings = useSelector(applicationSettingsSelector);
    const hasLimitAbility = useAbilitySelector(abilitiesEnum.CORE.TASK_ENTITY_LINKING_LIMIT);
    const [selectedEntities, setSelectedEntities] = useState<SearchResultWithType[]>([]);
    const overlayStore = useOverlayStore<TaskOverlayCustomProperties>();
    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();

    const { getForm } = useFormGetter();
    const onSave = useCallback(() => {
        const form = getForm(RefContextEnum.FORM_TASK.name);
        if (form) {
            const existingLinkedEntityIds = map(form.get().taskEntityLinks, 'entityId');
            const newTaskEntityLinks = _(selectedEntities)
                .reject((selectedEntity) =>
                    includes(existingLinkedEntityIds, selectedEntity.item.id)
                )
                .map((selectedEntity) => ({
                    entityType: selectedEntity.entityType,
                    entityId: selectedEntity.item.id,
                    entityTitle: getTaskEntityLinkTitleForSearchResult(
                        selectedEntity,
                        caseFieldName,
                        {
                            formatAttributeById,
                            formatCaseDefinitionById,
                            formatFieldByName,
                            formatTitleForElasticVehicle,
                        }
                    ),
                    itemTypeAttrId:
                        selectedEntity.type === ElasticSearchTypeEnum.PROPERTY.name
                            ? selectedEntity.item.itemTypeAttrId
                            : selectedEntity.type === ElasticSearchTypeEnum.VEHICLE.name
                            ? globalAttributes.itemType.vehicle
                            : undefined,
                    clientApprovalStatus:
                        selectedEntity.entityType === EntityTypeEnum.REPORT.name
                            ? selectedEntity.item.clientApprovalStatus
                            : undefined,
                    // this value comes from TaskView rather than TaskEntityLinkView
                    approvalStatusForCase:
                        selectedEntity.entityType === EntityTypeEnum.CASE.name
                            ? selectedEntity.item.approvalStatus
                            : undefined,
                }))
                .value();
            form.transaction(() => {
                forEach(newTaskEntityLinks, (newTaskEntityLink) => {
                    form.push('taskEntityLinks', newTaskEntityLink);
                });
            });

            const { customProperties } = overlayStore.getStateForId(overlayIdEnum.TASK_OVERLAY);
            overlayStore.setCustomProperties(overlayIdEnum.TASK_OVERLAY, {
                ...customProperties,
                taskEntityLinks: [
                    ...(customProperties.taskEntityLinks || []),
                    ...newTaskEntityLinks,
                ],
            });

            setSelectedEntities([]);
        }
    }, [
        getForm,
        selectedEntities,
        overlayStore,
        caseFieldName,
        formatAttributeById,
        formatCaseDefinitionById,
        formatFieldByName,
        formatTitleForElasticVehicle,
    ]);

    const {
        error,
        hasResults,
        isLoading,
        isSectionLoadingForType,
        resetQuickSearchState,
        resultTypes,
        results,
        query,
        triggerQuickSearch,
        triggerQuickSearchLoadMoreForType,
        updateQuickSearchQuery,
    } = useQuickSearch();

    const handleSearchResultClick = useCallback(
        (selected: SelectedDetails) => {
            // result component is link, prevent route change
            selected.event.preventDefault();

            const selectedEntity = getSelectedResult(selected.itemType, selected.index, results);

            if (selectedEntity) {
                setSelectedEntities((state) => {
                    const alreadySelected = some(
                        state,
                        (searchResult) => searchResult.item.id === selectedEntity.item.id
                    );
                    if (alreadySelected) {
                        return state;
                    }

                    return [...state, selectedEntity];
                });
            }

            resetQuickSearchState();
        },
        [results, resetQuickSearchState]
    );

    // if the user has the ability, disable the QuickSearch input to prevent the user from linking more than the
    // configured number of entities to this task
    const limitSetting = applicationSettings.RMS_TASK_ENTITY_LINKING_LIMIT as number;
    const limit = hasLimitAbility && limitSetting > 1 ? limitSetting : undefined;

    const hotKeysConfig = {
        [hotkeysActionEnum.CANCEL_AND_CLOSE.name]: { handler: resetQuickSearchState },
    };

    return (
        <SidePanel
            id={overlayIdEnum.TASK_ENTITIES_LINK_SIDE_PANEL}
            noPadding={true}
            onSave={onSave}
            title={strings.title}
        >
            <SearchWrapper>
                <Observer<{ taskEntityLinks?: TaskEntityLinkView[] }, FormTaskConfiguration>
                    formName={RefContextEnum.FORM_TASK.name}
                    subscriptions={{
                        taskEntityLinks: 'taskEntityLinks',
                    }}
                    render={({ taskEntityLinks }) => {
                        const disabled =
                            limit && taskEntityLinks
                                ? taskEntityLinks.length + selectedEntities.length >= limit
                                : false;
                        const quickSearchInput = (
                            <ReactHotKeysWrapper hotKeysConfig={hotKeysConfig}>
                                <FeatureFlagged
                                    flag="RMS_ARC_NAVIGATION_ENABLED"
                                    fallback={
                                        <QuickSearch
                                            allowedEntityTypes={allowedTaskLinkEntitySearchTypes}
                                            applicationSettings={applicationSettings}
                                            inputLabel={strings.labels.quickSearchInput}
                                            isFullHeight={true}
                                            isPlainInput={true}
                                            onSearchResultClick={handleSearchResultClick}
                                            error={error}
                                            hasResults={hasResults}
                                            isLoading={isLoading}
                                            isSectionLoadingForType={isSectionLoadingForType}
                                            resetQuickSearchState={resetQuickSearchState}
                                            resultTypes={resultTypes}
                                            results={results}
                                            query={query}
                                            triggerQuickSearch={triggerQuickSearch}
                                            triggerQuickSearchLoadMoreForType={
                                                triggerQuickSearchLoadMoreForType
                                            }
                                            updateQuickSearchQuery={updateQuickSearchQuery}
                                            disabled={disabled}
                                        />
                                    }
                                >
                                    <UpdatedQuickSearch
                                        allowedEntityTypes={allowedTaskLinkEntitySearchTypes}
                                        applicationSettings={applicationSettings}
                                        inputLabel={strings.labels.quickSearchInput}
                                        isPlainInput={true}
                                        onSearchResultClick={handleSearchResultClick}
                                        error={error}
                                        hasResults={hasResults}
                                        isLoading={isLoading}
                                        isSectionLoadingForType={isSectionLoadingForType}
                                        resetQuickSearchState={resetQuickSearchState}
                                        resultTypes={resultTypes}
                                        results={results}
                                        query={query}
                                        triggerQuickSearch={triggerQuickSearch}
                                        triggerQuickSearchLoadMoreForType={
                                            triggerQuickSearchLoadMoreForType
                                        }
                                        updateQuickSearchQuery={updateQuickSearchQuery}
                                        disabled={disabled}
                                    />
                                </FeatureFlagged>
                            </ReactHotKeysWrapper>
                        );
                        if (!disabled) {
                            return quickSearchInput;
                        } else if (limit) {
                            return (
                                <Tooltip
                                    side="bottom"
                                    content={strings.disabledQuickSearchTooltip(limit)}
                                    collisionBoundary={document.querySelector(
                                        '.mark43-react-side-panel'
                                    )}
                                >
                                    <div>{quickSearchInput}</div>
                                </Tooltip>
                            );
                        } else {
                            // unreachable
                            return null;
                        }
                    }}
                />
            </SearchWrapper>
            <SidePanelSection
                title={strings.labels.linkedItemsSection}
                testId={testIds.TASK_LINKED_ITEMS_SECTION}
            >
                {map(selectedEntities, (entity) => (
                    <LinkedEntitySummary key={entity.item.id} entity={entity} />
                ))}
            </SidePanelSection>
        </SidePanel>
    );
};

export default TaskEntitiesLinkSidePanel;

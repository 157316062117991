import { EntityTypeEnum } from '@mark43/rms-api';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';

import styled from 'styled-components';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { itemTypeSpecificViewModelByIdSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import { sortItemProfilesSelector } from '~/client-common/core/domain/item-reporting-event-links/state/ui';

import _SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import _ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import Row from '../../../core/components/Row';
import { RRFTextArea } from '../../../core/forms/components/TextArea';
import staffRemarkForm from '../state/forms/staffRemarkForm';
import {
    submitStaffRemarkForm,
    closeStaffRemarkSidePanel,
    entityTypeSelector,
    entityIdsSelector,
    reportingEventNumberSelector,
} from '../state/ui';
import _ItemInfo from './ItemInfo';

const strings = componentStrings.evidence.core.StaffRemarkSidePanel;

const context = {
    name: boxEnum.STAFF_REMARK_SIDE_PANEL,
};

const SidePanel = styled(_SidePanel)`
    .react-side-panel-content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    && .react-side-panel-content-wrapper {
        padding: 0;
    }
`;

const ReactReduxForm = styled(_ReactReduxForm)`
    padding: 30px 20px 17px;
`;

const ItemInfoContainer = styled.div`
    padding: 16px 20px;
    font-size: var(--arc-fontSizes-sm);
    overflow-y: scroll;
    box-shadow: inset 0 10px 10px -5px ${(props) => props.theme.colors.lightGrey};
`;

const ItemInfoTitle = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const ItemInfo = styled(_ItemInfo)`
    margin-top: 16px;
`;

function StaffRemarkSidePanel({
    entityType,
    itemProfileViewModels,
    reportingEventNumber,
    fieldDisplayNames,
    submitStaffRemarkForm,
    closeStaffRemarkSidePanel,
}) {
    return (
        <SidePanel
            title={
                entityType === EntityTypeEnum.REPORT.name
                    ? strings.titles.report(
                          fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER,
                          reportingEventNumber
                      )
                    : strings.titles.itemProfile
            }
            onSave={submitStaffRemarkForm}
            onCancel={closeStaffRemarkSidePanel}
            context={context}
        >
            <ReactReduxForm {...staffRemarkForm}>
                <Row>
                    <RRFTextArea
                        path="remark"
                        label={strings.labels.remark}
                        width={380}
                        rows={5}
                        resizable={false}
                    />
                </Row>
            </ReactReduxForm>
            {entityType === EntityTypeEnum.ITEM_PROFILE.name && (
                <ItemInfoContainer>
                    <ItemInfoTitle>
                        {strings.itemsSelected(itemProfileViewModels.length)}
                    </ItemInfoTitle>
                    {_.map(itemProfileViewModels, (itemProfileViewModel) => (
                        <ItemInfo
                            key={itemProfileViewModel.id}
                            viewModel={itemProfileViewModel}
                            reportId={itemProfileViewModel.ownerId}
                            width={380}
                            disableLink={true}
                            hideSerialNumber={true}
                            hideItemIdentifiers={true}
                        />
                    ))}
                </ItemInfoContainer>
            )}
        </SidePanel>
    );
}

const mapStateToProps = createStructuredSelector({
    reportingEventNumber: reportingEventNumberSelector,
    entityType: entityTypeSelector,
    entityIds: entityIdsSelector,
    itemProfileViewModelById: itemTypeSpecificViewModelByIdSelector,
    sortItemProfiles: sortItemProfilesSelector,
});

const mapDispatchToProps = {
    submitStaffRemarkForm,
    closeStaffRemarkSidePanel,
};

/**
 * Side panel for adding an evidence staff remark.
 */
export default compose(
    withFields([REPORT_REPORTING_EVENT_NUMBER]),
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['entityType', 'entityIds', 'itemProfileViewModelById', 'sortItemProfiles'],
        ({ entityType, entityIds, itemProfileViewModelById, sortItemProfiles }) => {
            // when adding staff remarks to item profiles, the item profiles need to be shown
            if (entityType === EntityTypeEnum.ITEM_PROFILE.name) {
                return {
                    itemProfileViewModels: _(entityIds)
                        .map((itemProfileId) => itemProfileViewModelById(itemProfileId))
                        .compact()
                        .sortBy(sortItemProfiles)
                        .value(),
                };
            } else {
                return {};
            }
        }
    )
)(StaffRemarkSidePanel);

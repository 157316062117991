import React from 'react';
import styled from 'styled-components';

import { Text } from 'arc';
import {
    AnalyticsPropertyEnum,
    analyticsCollapsibleSectionActionEnum,
} from '../../analytics/constants/analyticsEnum';
import zIndexes from '../styles/zIndexes';
import { useIsE2ETestEnvironment } from '../context/E2ETestingContext';
import testIds from '../../../core/testIds';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';
import { BodyMediumText } from './typography';
import Icon, { iconTypes } from './Icon';

export const Header = styled.header<{ sticky: boolean }>`
    background: var(--arc-colors-surface-accent);
    width: 100%;
    border-top: 2px solid ${(props) => props.theme.colors.mediumGrey};
    padding: 10px 15px;
    position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
    display: flex !important;
    box-sizing: border-box;
    align-items: center;
    top: 0;
    z-index: ${zIndexes.stickyHeader};
`;

export const HeaderButton = styled.button`
    background: transparent;
    border: 0;
    padding: 0;
    text-align: left;
    display: flex;
    align-items: center;

    .title {
        flex: 2 1 auto;
    }

    .collapse-button {
        flex: 1 1 100%;
    }
`;

const CollapseIcon = styled(Icon)`
    margin-left: 10px;
`;

const TitleContainer = styled.div`
    flex-grow: 1;
    color: ${(props) => props.theme.colors.text};
`;

export const CollapsibleSectionWithStickyHeader: React.FC<{
    title?: React.ReactElement | string;
    description?: React.ReactElement | string;
    isOpen: boolean;
    toggleOpen: (value: boolean) => void;
    headerLeftElement?: React.ReactElement;
    testId?: string;
    renderTitle?: () => React.ReactElement;
    className?: string;
    headerRightElement?: React.ReactElement;
    containerSelector?: string;
}> = ({
    title,
    description,
    children,
    isOpen,
    toggleOpen,
    headerLeftElement,
    headerRightElement,
    className,
    testId,
    renderTitle,
}) => {
    const { filteredTrack } = useAnalytics();
    const iconType = isOpen ? iconTypes.CLOSE : iconTypes.OPEN;
    return (
        <section
            data-test-id={testId}
            className={className}
            data-test-state={isOpen ? 'open' : 'closed'}
        >
            <Header sticky={!useIsE2ETestEnvironment()}>
                {headerLeftElement}
                <HeaderButton
                    className="title"
                    type="button"
                    onClick={() => {
                        toggleOpen(!isOpen);
                        filteredTrack(
                            {
                                [AnalyticsPropertyEnum.COLLAPSIBLE_SECTION]: testId,
                                [AnalyticsPropertyEnum.COLLAPSIBLE_SECTION_ACTION]: isOpen
                                    ? analyticsCollapsibleSectionActionEnum.CLOSE
                                    : analyticsCollapsibleSectionActionEnum.OPEN,
                            },
                            testId
                        );
                    }}
                    data-test-id={testIds.COLLAPSIBLE_SECTION_STICKY_HEADERS_BUTTON}
                >
                    <TitleContainer>
                        {renderTitle ? (
                            renderTitle()
                        ) : (
                            <>
                                <Text variant="headingSm">{title}</Text>
                                <BodyMediumText>{description}</BodyMediumText>
                            </>
                        )}
                    </TitleContainer>
                </HeaderButton>
                {headerRightElement}
                <HeaderButton
                    style={{}}
                    className="collapse-button"
                    type="button"
                    onClick={() => {
                        toggleOpen(!isOpen);
                        filteredTrack(
                            {
                                [AnalyticsPropertyEnum.COLLAPSIBLE_SECTION]: testId,
                                [AnalyticsPropertyEnum.COLLAPSIBLE_SECTION_ACTION]: isOpen
                                    ? analyticsCollapsibleSectionActionEnum.CLOSE
                                    : analyticsCollapsibleSectionActionEnum.OPEN,
                            },
                            testId
                        );
                    }}
                >
                    <CollapseIcon color="cobaltBlue" size={18} type={iconType} />
                </HeaderButton>
            </Header>
            {isOpen && children}
        </section>
    );
};

import { RefContextEnum } from '@mark43/rms-api';
import {
    DISPLAY_ONLY_WARRANT_ID_FORMAT_CONFIGURATION_ID,
    WARRANT_WARRANT_NUMBER,
    WARRANT_WARRANT_TYPE_ATTR_ID,
    WARRANT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';

const { ATTRIBUTE } = fieldTypeClientEnum;

const createWarrantFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        type: ATTRIBUTE,
        key: 'warrantTypeAttrId',
        fieldName: WARRANT_WARRANT_TYPE_ATTR_ID,
    },
    {
        key: 'warrantNumber',
        fieldName: WARRANT_WARRANT_NUMBER,
    },
    {
        key: 'idFormatConfigurationId',
        fieldName: DISPLAY_ONLY_WARRANT_ID_FORMAT_CONFIGURATION_ID,
    },
    {
        key: 'reportingEventNumber',
        fieldName: WARRANT_REPORTING_EVENT_NUMBER,
    },
]);

const createWarrantForm = createFormModule({
    context: RefContextEnum.FORM_WARRANT_CREATE_WARRANT.name,
    fieldViewModels: createWarrantFormFieldViewModels,
});

/**
 * Module of the create warrant form
 * @type {Object}
 */
export default createWarrantForm;

import { debounce, map, noop, compact } from 'lodash';
import { Route, IndexRoute } from 'react-router';
import React from 'react';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import store from '../core/store';
import {
    userIsBootstrappingSelector,
    userIsLoggedInSelector,
    currentUserHasAbilitySelector,
} from '../modules/core/current-user/state/ui';
import redirectInsufficientPermissions from '../modules/core/utils/redirectInsufficientPermissions';
import { getRelativeReference } from '../legacy-redux/helpers/urlHelpers';
import cobaltHistory from './cobaltHistory';
import handleAuthRedirect from './utils/handleAuthRedirect';
import { isBootstrapped } from './bootstrapState';

export default function protectRoutes(routes, skipJSX) {
    const routeCB = (route) => {
        const oldOnEnter = route.onEnter || noop;
        const onLeave = route.onLeave || noop;
        const includeCb = oldOnEnter.length > 2;
        // debounce added here as workaround for https://github.com/reactjs/react-router-redux/issues/481
        const debouncedOnEnter = debounce(oldOnEnter, 300);
        return {
            ...route,
            onEnter: includeCb
                ? (nextState, replace, cb) => {
                      performOldOnEnterLogic(debouncedOnEnter, nextState, replace, cb);
                  }
                : (nextState, replace) => {
                      performOldOnEnterLogic(debouncedOnEnter, nextState, replace);
                  },
            onLeave: (prevState) => {
                onLeave.apply(
                    {
                        dispatch: store.dispatch,
                        getState: store.getState,
                    },
                    [prevState]
                );
            },
        };
    };

    const plainRoutes = map(compact(routes.props.children), Route.createRouteFromReactElement);

    // ensure we get all child routes handled through the route debouncer!
    const walkPlainRoutes = (routes) => {
        return map(routes, (route) => {
            if (route.childRoutes) {
                route.childRoutes = walkPlainRoutes(route.childRoutes);
            }
            return routeCB(route);
        });
    };

    // skipping JSX is essential for anything that is provided via a getChildRoutes methodology
    if (skipJSX) {
        return walkPlainRoutes(plainRoutes);
    }
    return walkRoutes(
        // convert our JSX routes to plain objects for much simpler manipulation
        plainRoutes,
        // this callback is called on every single route - we provide default
        // onEnter functionality to every single route - calling manually
        // defined behaviour as well if appropriate
        routeCB
    );
}

export function validateRouteAccess(
    {
        // Require current user to have a specific ability
        ability,
        // Require a particular product module to be enabled
        productModule,
        // Feature flag name to check. Unless either of the two subsequent options is present, we'll
        // just verify that the feature flag value can be coerced to true.
        featureFlag,
        // Inverts the standard featureFlag processing logic (i.e. 'allow access only when
        // flags[featureFlag] == false')
        // Cannot be combined with featureFlagValue.
        // TODO Can almost certainly be replaced with featureFlagValue = false
        hideWhenFeatureFlagIsOn = false,
        // If present, feature flag value must be equal to this in order for the check to succeed.
        // (Useful for enum-like, multiple choice feature flags)
        // Cannot be combined with hideWhenFeatureFlagIsOn.
        featureFlagValue,
    },
    state
) {
    const hasAbility = ability ? currentUserHasAbilitySelector(state)(ability) : true;
    const hasProductModuleEnabled = productModule
        ? isProductModuleActiveSelector(state)(productModule)
        : true;
    if (!hasAbility || !hasProductModuleEnabled) {
        return false;
    }

    if (!featureFlag) {
        return true;
    }
    const featureFlagCurrentValue = applicationSettingsSelector(state)[featureFlag];
    if (featureFlagValue !== undefined) {
        return featureFlagValue === featureFlagCurrentValue;
    } else if (hideWhenFeatureFlagIsOn) {
        return !featureFlagCurrentValue;
    } else {
        return !!featureFlagCurrentValue;
    }
}

export function gateRoute(accessRules = {}, route) {
    const gatedRoute = walkRoutes([Route.createRouteFromReactElement(route)], (route) => {
        const oldOnEnter = route.onEnter || noop;
        const includeCb = oldOnEnter.length > 2;
        return {
            ...route,
            onEnter: includeCb
                ? (nextState, replace, cb) => {
                      if (validateRouteAccess(accessRules, store.getState())) {
                          oldOnEnter.apply(
                              {
                                  dispatch: store.dispatch,
                                  getState: store.getState,
                              },
                              [nextState, cobaltHistory.replace, cb]
                          );
                      } else {
                          store.dispatch(redirectInsufficientPermissions());
                      }
                  }
                : (nextState) => {
                      if (validateRouteAccess(accessRules, store.getState())) {
                          oldOnEnter.apply(
                              {
                                  dispatch: store.dispatch,
                                  getState: store.getState,
                              },
                              [nextState, cobaltHistory.replace]
                          );
                      } else {
                          store.dispatch(redirectInsufficientPermissions());
                      }
                  },
        };
    });
    return <Route>{gatedRoute}</Route>;
}

// handles deferring page loads until our bootstrap data is available and
// redirects users if they try to access permissioned routes whilst they
// are not authenticated
function performOldOnEnterLogic(oldOnEnter, nextState, replace, cb) {
    const state = store.getState();
    const userIsBootstrapping = userIsBootstrappingSelector(state);
    const userIsLoggedIn = userIsLoggedInSelector(state);
    if (!userIsLoggedIn && !userIsBootstrapping) {
        handleAuthRedirect({
            location: {
                pathname: '/',
                search: `?redirect=${encodeURIComponent(getRelativeReference(window.location))}`,
            },
        });
    } else {
        isBootstrapped.then(() =>
            oldOnEnter.apply(
                {
                    dispatch: store.dispatch,
                    getState: store.getState,
                },
                [nextState, cobaltHistory.replace, cb]
            )
        );
    }
}

// This function accepts a PlainRoute and returns a JSX <Route/>
// It walks a tree of route definitions - more or less this means checking for
// the existence of `route.childRoutes` and walking recursively on the values
// available there if they're defined
// the provided callback (should be a mapping function of some kind which
// returns a valid PlainRoute) is called on each provided route
function walkRoutes(routes, cb) {
    return map(routes, (route, index) => {
        const children = route.childRoutes ? walkRoutes(route.childRoutes, cb) : null;
        // IndexRedirects are considered a indexRoute
        // so we check for `to` field later on so that those aren't
        // injected as an IndexRoute
        const indexRoute = route.indexRoute;
        return (
            <Route {...cb(route)} key={index}>
                {indexRoute && !indexRoute.to && <IndexRoute {...cb(indexRoute)} key={index} />}
                {children}
            </Route>
        );
    });
}

import React from 'react';
import { useSelector } from 'react-redux';
import { getAttributeByIdSelector } from '../state/data';
import FormattedAttribute, { FormattedAttributeProps } from './FormattedAttribute';

export type ConnectedFormattedAttributeProps = { attributeId?: number } & Omit<
    FormattedAttributeProps,
    'attribute'
>;

type ConnectedFormattedAttributeExport = React.FC<ConnectedFormattedAttributeProps> & {
    FORMATS: typeof FormattedAttribute.FORMATS;
};

const ConnectedFormattedAttribute: ConnectedFormattedAttributeExport = ({
    attributeId,
    ...props
}) => {
    const attributeById = useSelector(getAttributeByIdSelector);
    if (!attributeId) {
        return null;
    }

    return <FormattedAttribute {...props} attribute={attributeById(attributeId)} />;
};

ConnectedFormattedAttribute.FORMATS = FormattedAttribute.FORMATS;

export default ConnectedFormattedAttribute;

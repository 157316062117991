import { useCallback, useMemo } from 'react';
import { isArray, keyBy } from 'lodash';
import { TaskView, SqlSort } from '@mark43/rms-api';
import { DataTableConfig, DataTableProps } from '@arc/data-table/dist/types';
import { SortingState, Updater } from '@tanstack/table-core';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sqlSortKeyEnum from '~/client-common/core/enums/universal/sqlSortKeyEnum';
import createDataTableSortColumns from '../../../search/core/utils/createDataTableSortColumns';
import { columnKeys } from '../components/TasksTable/constants';

const sorts = [
    {
        id: columnKeys.title,
        sortKey: sqlSortKeyEnum.TASK_TITLE,
        sortDirections: {
            asc: sortTypeEnum.ALPHABETICAL_A_TO_Z,
            desc: sortTypeEnum.ALPHABETICAL_Z_TO_A,
        },
    },
    {
        id: columnKeys.assigneeRoleId,
        sortKey: sqlSortKeyEnum.TASK_ASSIGNEE,
        sortDirections: {
            asc: sortTypeEnum.ALPHABETICAL_A_TO_Z,
            desc: sortTypeEnum.ALPHABETICAL_Z_TO_A,
        },
    },
    {
        id: columnKeys.priorityAttrId,
        sortKey: sqlSortKeyEnum.TASK_PRIORITY,
        sortDirections: {
            asc: sortTypeEnum.ALPHABETICAL_A_TO_Z,
            desc: sortTypeEnum.ALPHABETICAL_Z_TO_A,
        },
    },
    {
        id: columnKeys.typeAttrId,
        sortKey: sqlSortKeyEnum.TASK_TYPE,
        sortDirections: {
            asc: sortTypeEnum.ALPHABETICAL_A_TO_Z,
            desc: sortTypeEnum.ALPHABETICAL_Z_TO_A,
        },
    },
    {
        id: columnKeys.statusAttrId,
        sortKey: sqlSortKeyEnum.TASK_STATUS,
        sortDirections: {
            asc: sortTypeEnum.ALPHABETICAL_A_TO_Z,
            desc: sortTypeEnum.ALPHABETICAL_Z_TO_A,
        },
    },
    {
        id: columnKeys.dueDateUtc,
        sortKey: sqlSortKeyEnum.TASK_DUE_DATE,
        sortDirections: {
            asc: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
            desc: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
        },
    },
];

export const defaultTasksSorts = {
    sortKey: sqlSortKeyEnum.TASK_DUE_DATE,
    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
};

const columnSorts = createDataTableSortColumns<TaskView>()({
    columns: keyBy(sorts, 'id'),
});

export const useTasksSorts = (
    sort: SqlSort,
    updateSort: (sort: SqlSort) => void
): Pick<DataTableProps<TaskView>, 'onColumnSortChange'> & Pick<DataTableConfig, 'sort'> => {
    const columnSort = useMemo(() => {
        const columnSort = columnSorts.getColumnSort([sort]);
        return columnSort ? [columnSort] : undefined;
    }, [sort]);

    const handleColumnSortChange = useCallback(
        (updaterOrValue: Updater<SortingState>) => {
            if (!isArray(updaterOrValue)) {
                return;
            }
            const columnSort = updaterOrValue[0];
            const sqlSort = columnSort ? columnSorts.getSqlSort(columnSort) : null;

            if (sqlSort) {
                updateSort(sqlSort);
            }
        },
        [updateSort]
    );

    return {
        sort: columnSort,
        onColumnSortChange: handleColumnSortChange,
    };
};

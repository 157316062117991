import {
    createField,
    createFormConfiguration,
    _Form,
    InferFormDataShape,
    createNItems,
} from 'markformythree';
import { chain, map, first } from 'lodash';
import { UseOfForceSubject, AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';

import * as fields from '~/client-common/core/enums/universal/fields';
import { nameAttributesWhereSelector } from '~/client-common/core/domain/name-attributes/state/data';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { useOfForceSubjectDeEscalationsWhereSelector } from '~/client-common/core/domain/use-of-force-subject-de-escalations/state/data';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';

import { RmsAction } from '../../../../../core/typings/redux';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

const useOfForceSubjectFormConfiguration = createFormConfiguration({
    id: createField<number>({}),
    eventStartUtc: createField<string>({ fieldName: fields.EVENT_DETAIL_EVENT_START_UTC }),
    arrested: createField<boolean>({ fieldName: fields.USE_OF_FORCE_SUBJECT_ARRESTED }),
    charges: createField<string>({ fieldName: fields.USE_OF_FORCE_SUBJECT_CHARGES }),
    firearmDischargeIntentional: createField<boolean>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_FIREARM_DISCHARGE_INTENTIONAL,
    }),
    firearmDischargeAccidental: createField<boolean>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_FIREARM_DISCHARGE_ACCIDENTAL,
    }),
    shotsFiredAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_SHOTS_FIRED_ATTR_ID,
    }),
    numShotsFired: createField<number>({ fieldName: fields.USE_OF_FORCE_SUBJECT_NUM_SHOTS_FIRED }),
    numShotsHit: createField<number>({ fieldName: fields.USE_OF_FORCE_SUBJECT_NUM_SHOTS_HIT }),
    subject: createField<number>({
        fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_USE_OF_FORCE_LINK_TYPE,
    }),
    threatDirectedAtAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_THREAT_DIRECTED_AT_ATTR_ID,
    }),
    deEscalationAttempted: createField<boolean>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_DE_ESCALATION_ATTEMPTED,
    }),
    subjectPerceivedArmedWithAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_SUBJECT_PERCEIVED_ARMED_WITH_ATTR_ID,
    }),
    subjectConfirmedArmedWithAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_SUBJECT_CONFIRMED_ARMED_WITH_ATTR_ID,
    }),
    officerAttemptedToDisarmSubject: createField<boolean>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_OFFICER_ATTEMPTED_TO_DISARM_SUBJECT,
    }),
    resistedAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_RESISTED_ATTR_ID,
    }),
    subjectActionsAttrIds: createField<number[]>({
        fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_ACTIONS_ATTRIBUTE_ID,
    }),
    subjectActionsDescription: createField<string>({
        fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_ACTIONS_DESCRIPTION,
    }),
    subjectBehaviorWasErratic: createField<boolean>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_SUBJECT_BEHAVIOR_WAS_ERRATIC,
    }),
    subjectDetailsAttrIds: createField<number[]>({
        fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_DETAILS_ATTRIBUTE_ID,
    }),
    subjectDetailsDescription: createField<string>({
        fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_DETAILS_DESCRIPTION,
    }),
    subjectImpairmentAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_SUBJECT_IMPAIRMENT_ATTR_ID,
    }),
    impairmentTypeAttrIds: createField<number[]>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_IMPAIRMENTS_ATTRIBUTE_ID,
    }),
    officerUsedForceOnSubject: createField<boolean>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_OFFICER_USED_FORCE_ON_SUBJECT,
    }),
    officerForceTowardsSubjectAttrIds: createField<number[]>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_ATTRIBUTE_ID,
    }),
    officerForceTowardsSubjectDescription: createField<string>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_DESCRIPTION,
    }),
    officerForceTowardsSubjectLocationAttrIds: createField<number[]>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_LOCATION_ATTRIBUTE_ID,
    }),
    officerForceTowardsSubjectLocationDescription: createField<string>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_LOCATION_DESCRIPTION,
    }),
    subjectInjurySeverityAttrId: createField<number>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_INJURY_SEVERITY_ATTRIBUTE_ID,
    }),
    subjectInjurySeverityDescription: createField<string>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_INJURY_SEVERITY_DESCRIPTION,
    }),
    subjectInjuryTypeAttrIds: createField<number[]>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_INJURY_TYPE_ATTRIBUTE_ID,
    }),
    subjectInjuryTypeDescription: createField<string>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_INJURY_TYPE_DESCRIPTION,
    }),
    medicalAidReceivedAttrId: createField<number>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_MEDICAL_AID_RECEIVED_ATTRIBUTE_ID,
    }),
    medicalAidReceivedDescription: createField<string>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_MEDICAL_AID_RECEIVED_DESCRIPTION,
    }),
    subjectDeceasedAsResultOfUseOfForce: createField<boolean>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_SUBJECT_DECEASED_AS_RESULT_OF_USE_OF_FORCE,
    }),
    subjectUsedForceOnOfficer: createField<boolean>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_SUBJECT_USED_FORCE_ON_OFFICER,
    }),
    subjectForceTowardsOfficerAttrIds: createField<number[]>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_ATTRIBUTE_ID,
    }),
    subjectForceTowardsOfficerDescription: createField<string>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_DESCRIPTION,
    }),
    subjectForceTowardsOfficerLocationAttrIds: createField<number[]>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_LOCATION_ATTRIBUTE_ID,
    }),
    subjectForceTowardsOfficerLocationDescription: createField<string>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_LOCATION_DESCRIPTION,
    }),
    officerInjuredAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_OFFICER_INJURED_ATTR_ID,
    }),
    officerAssaultHomicideIncidentNumber: createField<string>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_OFFICER_ASSAULT_HOMICIDE_INCIDENT_NUMBER,
    }),
    nibrsIncidentNumberPuAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_NIBRS_INCIDENT_NUMBER_PU_ATTR_ID,
    }),
    officerInjurySeverityAttrId: createField<number>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_INJURY_SEVERITY_ATTRIBUTE_ID,
    }),
    officerInjurySeverityDescription: createField<string>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_INJURY_SEVERITY_DESCRIPTION,
    }),
    officerInjuryTypeAttrIds: createField<number[]>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_INJURY_TYPE_ATTRIBUTE_ID,
    }),
    officerInjuryTypeDescription: createField<string>({
        fieldName:
            fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_OFFICER_INJURY_TYPE_DESCRIPTION,
    }),
    officerDeceasedAsResultOfUseOfForce: createField<boolean>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_OFFICER_DECEASED_AS_RESULT_OF_USE_OF_FORCE,
    }),
    useOfForceSubjectDeEscalations: createNItems({
        fieldName: fields.USE_OF_FORCE_SUBJECT_DE_ESCALATION_N_ITEMS_WRAPPER,
        fields: {
            id: createField<number>({}),
            deEscalationTypeAttrId: createField<number>({
                fieldName: fields.USE_OF_FORCE_SUBJECT_DE_ESCALATION_DE_ESCALATION_TYPE_ATTR_ID,
            }),
            deEscalationSuccessful: createField<boolean>({
                fieldName: fields.USE_OF_FORCE_SUBJECT_DE_ESCALATION_DE_ESCALATION_SUCCESSFUL,
            }),
        },
    }),
    subjectDispositionAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_SUBJECT_DISPOSITION_ATTR_ID,
    }),
    chargedAttrId: createField<number>({
        fieldName: fields.USE_OF_FORCE_SUBJECT_CHARGED_ATTR_ID,
    }),
});

type UseOfForceSubjectFormConfiguration = typeof useOfForceSubjectFormConfiguration;
type UseOfForceSubjectFormModel = InferFormDataShape<UseOfForceSubjectFormConfiguration>;
type RefreshUseOfForceSubjectFormProps = {
    useOfForceSubject: UseOfForceSubject;
    subjectPersonProfileId: number;
};
type CreateUseOfForceSubjectFormProps = {
    initialState?: UseOfForceSubjectFormModel;
    arbiter?: unknown;
    formatFieldByName?: () => string;
};

export function refreshUseOfForceSubjectForm({
    useOfForceSubject,
    subjectPersonProfileId,
}: RefreshUseOfForceSubjectFormProps): RmsAction<UseOfForceSubjectFormModel> {
    return (dispatch, getState) => {
        const state = getState();
        const nameAttributes = nameAttributesWhereSelector(state)({
            nameId: subjectPersonProfileId,
        });

        const useOfForceSubjectDeEscalations = useOfForceSubjectDeEscalationsWhereSelector(state)({
            subjectId: useOfForceSubject.id,
        });

        const nameAttributeIdsByType = chain(nameAttributes)
            .groupBy('attributeType')
            .mapValues((nameAttributes) => {
                return map(nameAttributes, 'attributeId');
            })
            .value();

        const nameAttributesByType = chain(nameAttributes).groupBy('attributeType').value();

        const subjectActionsAttrIds =
            nameAttributeIdsByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_ACTIONS.name];
        const subjectActionsDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_ACTIONS.name]
        );
        const subjectDetailsAttrIds =
            nameAttributeIdsByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_DETAILS.name];
        const impairmentTypeAttrIds =
            nameAttributeIdsByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_IMPAIRMENTS.name];
        const subjectDetailsDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_DETAILS.name]
        );
        const officerForceTowardsSubjectAttrIds =
            nameAttributeIdsByType[
                AttributeTypeEnum.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT.name
            ];
        const officerForceTowardsSubjectDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[AttributeTypeEnum.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT.name]
        );
        const officerForceTowardsSubjectLocationAttrIds =
            nameAttributeIdsByType[
                AttributeTypeEnum.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_LOCATION.name
            ];
        const officerForceTowardsSubjectLocationDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[
                AttributeTypeEnum.USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_LOCATION.name
            ]
        );
        const subjectInjurySeverityAttrId = first(
            nameAttributeIdsByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_INJURY_SEVERITY.name]
        );
        const subjectInjurySeverityDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_INJURY_SEVERITY.name]
        );
        const subjectInjuryTypeAttrIds =
            nameAttributeIdsByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_INJURY_TYPE.name];
        const subjectInjuryTypeDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_INJURY_TYPE.name]
        );
        const medicalAidReceivedAttrId = first(
            nameAttributeIdsByType[AttributeTypeEnum.USE_OF_FORCE_MEDICAL_AID_RECEIVED.name]
        );
        const medicalAidReceivedDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[AttributeTypeEnum.USE_OF_FORCE_MEDICAL_AID_RECEIVED.name]
        );

        const subjectForceTowardsOfficerAttrIds =
            nameAttributeIdsByType[
                AttributeTypeEnum.USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER.name
            ];
        const subjectForceTowardsOfficerDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[AttributeTypeEnum.USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER.name]
        );
        const subjectForceTowardsOfficerLocationAttrIds =
            nameAttributeIdsByType[
                AttributeTypeEnum.USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_LOCATION.name
            ];
        const subjectForceTowardsOfficerLocationDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[
                AttributeTypeEnum.USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_LOCATION.name
            ]
        );
        const officerInjurySeverityAttrId = first(
            nameAttributeIdsByType[AttributeTypeEnum.USE_OF_FORCE_OFFICER_INJURY_SEVERITY.name]
        );
        const officerInjurySeverityDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[AttributeTypeEnum.USE_OF_FORCE_OFFICER_INJURY_SEVERITY.name]
        );
        const officerInjuryTypeAttrIds =
            nameAttributeIdsByType[AttributeTypeEnum.USE_OF_FORCE_OFFICER_INJURY_TYPE.name];
        const officerInjuryTypeDescription = getDescriptionForAttributeLinks(
            nameAttributesByType[AttributeTypeEnum.USE_OF_FORCE_OFFICER_INJURY_TYPE.name]
        );
        let eventStartUtc;
        const report = currentReportSelector(state);
        if (report) {
            const event = eventDetailByReportIdSelector(state)(report.id);
            if (event) {
                eventStartUtc = event.eventStartUtc;
            }
        }

        return {
            ...useOfForceSubject,
            subjectActionsAttrIds,
            subjectActionsDescription,
            subjectDetailsAttrIds,
            impairmentTypeAttrIds,
            subjectDetailsDescription,
            officerForceTowardsSubjectAttrIds,
            officerForceTowardsSubjectDescription,
            officerForceTowardsSubjectLocationAttrIds,
            officerForceTowardsSubjectLocationDescription,
            subjectInjurySeverityAttrId,
            subjectInjurySeverityDescription,
            subjectInjuryTypeAttrIds,
            subjectInjuryTypeDescription,
            medicalAidReceivedAttrId,
            medicalAidReceivedDescription,
            subjectForceTowardsOfficerAttrIds,
            subjectForceTowardsOfficerDescription,
            subjectForceTowardsOfficerLocationAttrIds,
            subjectForceTowardsOfficerLocationDescription,
            officerInjurySeverityAttrId,
            officerInjurySeverityDescription,
            officerInjuryTypeAttrIds,
            officerInjuryTypeDescription,
            useOfForceSubjectDeEscalations,
            eventStartUtc,
            subject: subjectPersonProfileId,
        };
    };
}

export const createUseOfForceSubjectForm = (options: CreateUseOfForceSubjectFormProps = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form<UseOfForceSubjectFormConfiguration>({
        name: RefContextEnum.FORM_USE_OF_FORCE_SUBJECT.name,
        onValidate: createArbiterMFTValidationHandler(
            arbiter,
            RefContextEnum.FORM_USE_OF_FORCE_SUBJECT.name,
            formatFieldByName
        ),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: useOfForceSubjectFormConfiguration,
    });
};

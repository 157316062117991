import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { Text, cssVar } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import keyboardShortcutSidePanelOptions from '../constants/keyboardShortcutSidePanelOptions';
import testIds from '../../../../core/testIds';
import { OverlayBaseHelper } from '../../components/OverlayBaseHelper';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import { SecondarySectionHeader } from '../../components/typography';

const SidePanelContainer = styled.div`
    padding: 5px 20px 0px 20px;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 15px;
`;

const Column = styled.div`
    display: flex;
    flex-flow: column;
    flex: 1;
    flex-basis: auto;
    margin-left: ${(props) => (props.includeMargin ? '10px' : '0px')};
`;

const NameColumn = styled(Column)`
    width: 60%;
`;

const KeyColumn = styled(Column)`
    width: 40%;
`;

const Cell = styled.span`
    white-space: nowrap;
    overflow: hidden;
    height: 30px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
`;

const Label = styled.div`
    display: inline-block;
    padding: 4px 8px;
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    clear: both;
    margin-right: 5px;
    font-size: ${cssVar('arc.fontSizes.xs')};
    line-height: ${cssVar('arc.lineHeights.none')};
`;

const strings = componentStrings.keyboardShortcutsSidePanel;
const KEYBOARD_SHORTCUTS_SIDE_PANEL = overlayIdEnum.KEYBOARD_SHORTCUTS_SIDE_PANEL;

const Section = (config) => {
    const nameColumn = (
        <NameColumn>
            {map(config.shortcuts, (shortcut) => {
                const displayCell = (
                    <Cell>
                        <Text fontSize="sm">{shortcut.name}</Text>
                    </Cell>
                );
                return displayCell;
            })}
        </NameColumn>
    );

    const keyColumn = (
        <KeyColumn>
            {map(config.shortcuts, (shortcut) => {
                const displayCell = (
                    <Cell>
                        {map(shortcut.keys, (key, idx) => (
                            <Label key={idx}>{key}</Label>
                        ))}
                    </Cell>
                );
                return displayCell;
            })}
        </KeyColumn>
    );

    return (
        <>
            {config.title && <SecondarySectionHeader>{config.title}</SecondarySectionHeader>}
            <ColumnContainer>
                {nameColumn}
                {keyColumn}
            </ColumnContainer>
        </>
    );
};

function KeyboardShortcutsSummary({ overlayBase, closePanel, setCancelFocusRef }) {
    const cancelFocusRef = overlayBase.overlayState.customProperties.cancelFocusRef;

    if (cancelFocusRef) {
        setCancelFocusRef(cancelFocusRef);
    }

    return (
        <PortalSidePanel
            id={KEYBOARD_SHORTCUTS_SIDE_PANEL}
            noPadding={true}
            title={strings.title}
            closePanel={closePanel}
            testId={testIds.KEYBOARD_SHORTCUTS_SIDE_PANEL}
            isAtBottomOfStack={overlayBase.isAtBottomOfStack}
            saveText={null}
            cancelText={strings.cancelText}
        >
            <SidePanelContainer>
                {map(keyboardShortcutSidePanelOptions.sections, (section, idx) => (
                    <Section key={idx} {...section} />
                ))}
            </SidePanelContainer>
        </PortalSidePanel>
    );
}

/**
 * @prop {Function} [renderButton]
 * Render prop to connect an element to trigger the side panel.
 *
 * @param {Props} props
 */
export const KeyboardShortcutsSidePanel = ({ renderButton, ...props }) => {
    return (
        <OverlayBaseHelper id={KEYBOARD_SHORTCUTS_SIDE_PANEL} renderButton={renderButton}>
            {(renderProps) => <KeyboardShortcutsSummary {...props} {...renderProps} />}
        </OverlayBaseHelper>
    );
};

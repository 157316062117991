import { filter, groupBy } from 'lodash';
import { createSelector } from 'reselect';
import { Code, CodeTypeSourceEnumType } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const codeModule = createNormalizedModule<Code>({
    type: 'codes',
});

// ACTIONS
export const storeCodes = codeModule.actionCreators.storeEntities;

// SELECTORS
export const codesSelector = codeModule.selectors.entitiesSelector;
export const codesByCodeIdSelector = codeModule.selectors.entityByIdSelector;

export const codesByCodeTypeSourceSelector = createSelector(
    codesSelector,
    (codes) => (codeTypeSource: CodeTypeSourceEnumType) =>
        filter(codes, (code) => code.codeType.source === codeTypeSource)
);

export const codesByCodeTypeCategoryAndCodeTypeSourceSelector = createSelector(
    codesByCodeTypeSourceSelector,
    (codesByCodeTypeSource) => (codeTypeSource: CodeTypeSourceEnumType) =>
        groupBy(codesByCodeTypeSource(codeTypeSource), 'codeType.category')
);

// REDUCER
export default codeModule.reducerConfig;

import React, { useCallback } from 'react';
import { CadUserSettingsView, AccountSwitcherRequest } from '@mark43/rms-api';
import { compact, map } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { MenuSeparator, MenuItem } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { associatedDepartmentProfilesSelector } from '../../current-user/state/ui';
import {
    switchDeptsFromNavDropdown as _switchDeptsFromNavDropdown,
    openAccountSwitcherModal as _openAccountSwitcherModal,
    loadCadSettingsForCurrentUser as _loadCadSettingsForCurrentUser,
    cadUserSettingsSelector,
} from '../state/ui';
import testIds from '../../../../core/testIds';
import { switchToCad as _switchToCad } from '../state/data';

const strings = componentStrings.cobalt.navigation.core.AccountSwitcherLink;

const LinkToCad = ({ onClick }: { onClick: () => void }) => {
    return (
        <MenuItem
            className="dropdown-menu-option"
            onSelect={onClick}
            data-test-id={testIds.INITIALS_DROPDOWN_OPEN_CAD}
        >
            {strings.goToCad}
        </MenuItem>
    );
};

/* Component to display all the Account Switcher Links on the main Navigation menu bar:
 * 0 other depts or sso user should only display the link to the CAD menu (if enabled).
 * 1-2 other depts should display those other depts.
 * 3+ other depts should display 2 other depts + View All Departments”.
 */
function UpdatedAccountSwitcherLink({ onClick }: { onClick: () => void }) {
    const associatedDepartmentProfiles = useSelector(associatedDepartmentProfilesSelector);
    const cadUserSettings = useSelector(cadUserSettingsSelector);

    const dispatch = useDispatch();
    const openAccountSwitcherModal = () => dispatch(_openAccountSwitcherModal());
    const switchDeptsFromNavDropdown = (subdomain: AccountSwitcherRequest['subDomain']) =>
        dispatch(_switchDeptsFromNavDropdown(subdomain));
    const switchToCad = (cadUserSettings: CadUserSettingsView) =>
        dispatch(_switchToCad(cadUserSettings));
    const loadCadSettingsForCurrentUser = useCallback(() => {
        dispatch(_loadCadSettingsForCurrentUser());
    }, [dispatch]);

    React.useEffect(() => {
        // If there is no cadDeptId, then attempt to fetch one
        if (!cadUserSettings.cadDepartmentId) {
            loadCadSettingsForCurrentUser();
        }
    }, [cadUserSettings.cadDepartmentId, loadCadSettingsForCurrentUser]);
    const { hasCadModule, hasFirstResponderAbility, hasDispatcherAbility } = cadUserSettings;
    const renderDepartmentLinks = associatedDepartmentProfiles.length > 0;
    const canRenderLinkToCad = hasCadModule && (hasFirstResponderAbility || hasDispatcherAbility);
    const switchDepartmentsLinks = associatedDepartmentProfiles.slice(0, 2).map((deptProf) => (
        <MenuItem
            key={deptProf.departmentId}
            onSelect={() => switchDeptsFromNavDropdown(deptProf.department.subDomain)}
        >
            {strings.switchTo(deptProf)}
        </MenuItem>
    ));
    const viewAllDepartmentsLink = associatedDepartmentProfiles.length > 2 && (
        <MenuItem
            onSelect={() => {
                openAccountSwitcherModal();
                onClick();
            }}
        >
            {strings.viewAllDepartments}
        </MenuItem>
    );

    const links = compact([
        canRenderLinkToCad && <LinkToCad onClick={() => switchToCad(cadUserSettings)} />,
        renderDepartmentLinks && switchDepartmentsLinks,
        renderDepartmentLinks && viewAllDepartmentsLink,
    ]);
    const linkItems = map(links, (link, key) => <div key={key}>{link}</div>);
    return !!links.length ? (
        <>
            {/* CORE-2045 TODO: How to not let global styles strip out this <hr> margin being set. */}
            <MenuSeparator style={{ marginTop: '4px', marginBottom: '4px' }} />
            {linkItems}
            <MenuSeparator style={{ marginTop: '4px', marginBottom: '4px' }} />
        </>
    ) : null;
}

export default UpdatedAccountSwitcherLink;

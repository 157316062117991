import React from 'react';

import styled from 'styled-components';
import { ElasticPerson } from '@mark43/rms-api';
import { Text, Stack } from 'arc';
import {
    isUnknown,
    formatShortName,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import Link from '../../core/components/links/Link';
import { PersonProfileImage, profileImageSizes } from '../../core/components/ProfileImage';

const HoverLink = styled(Link)`
    font-size: var(--arc-fontSizes-sm);

    &:hover {
        text-decoration: underline;
    }
`;

const LinkElement = styled(Link)`
    position: static;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
`;

const ItemWrapper = styled.div<{ $isRestricted?: boolean }>`
    position: relative;
    font-size: var(--arc-fontSizes-sm);
    display: flex;
    gap: var(--arc-space-4);
    padding: var(--arc-space-2);
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: var(--arc-radii-md);

    &:hover {
        background-color: var(--arc-colors-interactive-hover);
    }

    a {
        z-index: 1;
    }

    ${(props) =>
        props.$isRestricted &&
        `background-color: var(--arc-colors-surface-accent);
         background-image: url(../../images/stripe_pattern.png);
         background-repeat: repeat;
        `};
`;

const RestrictedAccessNotice = styled.div`
    font-style: italic;
    margin-top: var(--arc-space-2);
    color: var(--arc-colors-text-tertiary);
    text-transform: uppercase;
`;

type EntityItemProps = {
    entity?: ElasticPerson;
    entityRoute?: string;
    date?: string;
    itemRoute?: string;
    itemLinkText?: string;
    mugShotImage?: string;
    mugShotOverlay?: React.ReactNode;
    isRestricted?: boolean;
    children?: React.ReactNode;
};
// Opinionated Entity Item for the dashboard cards. If it gets reused in other places, probably should make it more flexible.
export const EntityItem = ({
    entity,
    date,
    itemRoute,
    itemLinkText,
    children,
    mugShotImage,
    mugShotOverlay,
    isRestricted,
    entityRoute,
}: EntityItemProps) => {
    return (
        <ItemWrapper $isRestricted={isRestricted}>
            <PersonProfileImage
                style={{ flexShrink: '0' }}
                size={profileImageSizes.RECENT_ARRESTS}
                isUnknown={isUnknown({ firstName: entity?.firstName, lastName: entity?.lastName })}
                // Can't pull this from the entity, because they have different names for this value.
                url={mugShotImage}
            >
                {mugShotOverlay}
            </PersonProfileImage>
            <Stack spacing={0} position="static" align="flex-start">
                <LinkElement to={entityRoute}>
                    <Text variant="headingSm">
                        {formatShortName({
                            firstName: entity?.firstName,
                            lastName: entity?.lastName,
                        })}
                    </Text>
                </LinkElement>
                <HoverLink to={itemRoute}>{itemLinkText}</HoverLink>
                <Text color="tertiary" fontSize="sm">
                    <FormattedDate date={date} format={FormattedDate.FORMATS.SUMMARY_DATE_TIME} />
                </Text>
                {children}
                {isRestricted && <RestrictedAccessNotice>Restricted Access</RestrictedAccessNotice>}
            </Stack>
        </ItemWrapper>
    );
};

import { EntityTypeEnum } from '@mark43/rms-api';
import _ from 'lodash';

import { createSelector } from 'reselect';
import {
    formatLinkType,
    filterLinkTypes,
    buildLinkTypeViewModel,
} from '~/client-common/helpers/linkTypesHelpers';

const { PERSON_PROFILE, ORGANIZATION_PROFILE } = EntityTypeEnum;

/**
 * All link types keyed by id.
 * @type {Object}
 */
export const linkTypesSelector = (state) => state.data.linkTypes;

/**
 * Format the display string for a link type.
 * @param  {number}  [id]
 * @param  {boolean} useLinkNameTwo whether to format the linkType using
 *   linkNameTwo as the base string
 * @return {string|undefined}
 */
export const formatLinkTypeByIdSelector = createSelector(
    linkTypesSelector,
    (linkTypes) => (id, useLinkNameTwo) => formatLinkType(linkTypes[id], useLinkNameTwo)
);

/**
 * All possible link types among persons and organizations.
 * @type {Object[]}
 */
const nameNameLinkTypesSelector = createSelector(linkTypesSelector, (linkTypes) =>
    filterLinkTypes(linkTypes, [
        [PERSON_PROFILE.name, PERSON_PROFILE.name],
        [ORGANIZATION_PROFILE.name, ORGANIZATION_PROFILE.name],
        [PERSON_PROFILE.name, ORGANIZATION_PROFILE.name],
    ])
);

/**
 * nameNameLink types with an extra computed property for whether the link type
 *   is symmetric. For example, spouse/spouse is symmetric and employer/employee
 *   is not.
 * @type {Object}
 */
export const nameNameLinkTypeViewModelsSelector = createSelector(
    nameNameLinkTypesSelector,
    (nameNameLinkTypes) =>
        _(nameNameLinkTypes).mapKeys('id').mapValues(buildLinkTypeViewModel).value()
);

/**
 * LinkType by LinkTypeId.
 * @param  {number}  [id]
 * @return {Object}
 */
export const linkTypeByLinkTypeIdSelector = createSelector(linkTypesSelector, (linkTypes) => (id) =>
    linkTypes[id]
);

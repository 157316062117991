import React from 'react';
import { FieldState as MFTFieldState } from 'markformythree';
import { ArbiterField } from '../../arbiter';

export default function FieldState({ path, render }) {
    return (
        <MFTFieldState
            path={path}
            render={({ ui, path, getConfigurationForPath }) => (
                <ArbiterField fieldName={getConfigurationForPath(path).fieldName}>
                    {({
                        fieldName,
                        fieldDetail,
                        fieldConfiguration,
                        fieldConfigurationContext,
                    }) => {
                        // `hidden` is a computed value, and is true if the field is hidden dynamically
                        // or statically - false otherwise
                        return render({
                            hidden: ui.hidden || fieldConfigurationContext.isStaticallyHidden,
                            ui,
                            fieldName,
                            fieldDetail,
                            fieldConfiguration,
                            fieldConfigurationContext,
                        });
                    }}
                </ArbiterField>
            )}
        />
    );
}

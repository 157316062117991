import { createSelector } from 'reselect';
import {
    chain,
    concat,
    every,
    filter,
    includes,
    reduce,
    uniqBy,
    upperCase,
    IterateeShorthand,
} from 'lodash';
import { AttributeTypeEnum, AttributeTypeEnumType } from '@mark43/rms-api';

// helpers
import { getViewModelProperties } from '../../../../../helpers/viewModelHelpers';
import {
    AttributeViewModel,
    formatAttributeAbbrev,
    mapAttributesToOptions,
} from '../../utils/attributesHelpers';
import { replaceTextIfEvidenceModuleNameIsNotEvidence } from '../../../../../helpers/evidenceModuleNameReplacementHelpers';
// selectors
import { formatFieldByNameSelector } from '../../../../fields/state/config';
import {
    activeAttributesByTypeSelector,
    attributeViewModelsByTypeSelector,
    expiredAttributesByTypeSelector,
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '../data';

import { DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE } from '../../../../enums/universal/fields';

type PredicateShorthandOrFunction<T> =
    | IterateeShorthand<T>
    | ((element: T, index: number, array: T[]) => boolean);

export const attributeAbbrevDisplayByIdSelector = createSelector(
    getAttributeByIdSelector,
    (attributesById) => (id: string | number) => formatAttributeAbbrev(attributesById(id))
);

/**
 * Attributes of the given type as dropdown/checkbox options.
 * @param   [includeExpired=false] Whether to include all
 *   expired attributes of the given type.
 * @param   [additionalIds] Optional ids of additional
 *   attributes to also include. The main purpose of this argument to
 *   sufficiently populate dropdowns where the selected option is an expired or
 *   scheduled attribute, which can happen when viewing a legacy report with old
 *   attributes. The additional attributes do not have to be of the same `type`.
 * @param   [includeAbbr] Whether to show each attribute's code before its display name.
 * @param   [predicate] A predicate to further filter attributes by
 * @param   [sortByIteratees] An array of strings referencing properties of an
 *   AttributeViewModel to sort by
 */
export const attributeOptionsByTypeSelector = createSelector(
    activeAttributesByTypeSelector,
    expiredAttributesByTypeSelector,
    attributeViewModelsByTypeSelector,
    formatAttributeByIdSelector,
    formatFieldByNameSelector,

    (
        activeAttributesByType,
        expiredAttributesByType,
        attributeViewModelsByType,
        formatAttributeById,
        formatFieldByName
    ) => ({
        type,
        includeExpired = false,
        additionalIds = [],
        includeAbbr,
        predicate,
        sortByIteratees,
    }: {
        type: AttributeTypeEnumType;
        includeExpired: boolean;
        additionalIds?: number[];
        includeAbbr?: boolean;
        predicate?: PredicateShorthandOrFunction<AttributeViewModel>;
        sortByIteratees?: (keyof AttributeViewModel)[];
    }) => {
        additionalIds = concat([], additionalIds); // convert to array
        const activeAttributes = activeAttributesByType(type);
        const expiredAttributes = includeExpired ? expiredAttributesByType(type) : [];
        const additional = additionalIds.length
            ? filter(attributeViewModelsByType(type), (attribute) =>
                  includes(additionalIds, attribute.id)
              )
            : [];
        const combined = uniqBy(
            activeAttributes.concat(expiredAttributes).concat(additional),
            'id'
        );

        const attributes =
            typeof predicate === 'function'
                ? combined.filter((element, index, array) => predicate(element, index, array))
                : predicate
                ? filter(combined, predicate)
                : combined;

        const attributeOptions = mapAttributesToOptions(
            attributes,
            formatAttributeById,
            includeAbbr,
            sortByIteratees
        );

        // This is a temporary solution for RMS-15751
        // to replace the group name "EVIDENCE" in Reason for Police Custody dropdown
        // as configured display name of field DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE.
        if (attributes[0]?.type === AttributeTypeEnum.REASON_FOR_POLICE_CUSTODY_OF_PROPERTY.name) {
            const capitalizedEvidenceModuleName = upperCase(
                formatFieldByName(DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE)
            );
            return attributeOptions.map((option) => ({
                ...option,
                // group cannot be empty string,
                // otherwise the dropdown will be displayed as `Uncategorized`.
                // But in current system, there is the possibility that field display name is '',
                // so add a fallback 'EVIDENCE' string here.
                group:
                    replaceTextIfEvidenceModuleNameIsNotEvidence(
                        option.group,
                        capitalizedEvidenceModuleName
                    ) || 'EVIDENCE',
            }));
        }

        return attributeOptions;
    }
);

/**
 * Legacy code derived display logic off of empty select dropdowns.
 * This attribute options selector will determine if there are any options
 * for attributeType filtered by parentAttributeId.
 * See vehicleModel for instances of `options().length === 0`.
 */
export const attributesOptionsByTypeForParentAttrIdIsEmptySelector = createSelector(
    attributeOptionsByTypeSelector,
    parentAttributeIdByAttributeIdSelector,
    (attributeOptionsByType, parentAttributeIdByAttributeId) => (
        attributeType: AttributeTypeEnumType,
        parentAttributeId: number,
        predicate: PredicateShorthandOrFunction<AttributeViewModel>
    ) =>
        every(
            attributeOptionsByType({
                type: attributeType,
                includeExpired: false,
                additionalIds: [],
                includeAbbr: false,
                predicate,
            }),
            ({ value }) => parentAttributeIdByAttributeId(value) !== parentAttributeId
        )
);

export const formatAttributeLinkViewModels = (attributeLinkViewModels: Record<string, unknown>[]) =>
    chain(attributeLinkViewModels)
        .map((attributeLinkViewModel) => {
            // @ts-expect-error client-common to client View model needs to be typed
            const { attributeId, isOther } = getViewModelProperties(attributeLinkViewModel);
            if (isOther) {
                const { description } = attributeLinkViewModel;
                const finalDescription = description ? ` - ${description}` : '';
                return `${attributeId}${finalDescription}`;
            }
            return attributeId;
        })
        .sortBy()
        .join(', ')
        .value();

type AttributeLink = { description?: string };
/**
 * Given an array of attriubte links (ArrestAttribute | NameAttribute | OffenseAttribute | ReportAttribute),
 * return a single description (or "other text") that was provided to describe an "other" selection.
 * Note: Our logic performs a simple reduction; consumers are expected to ensure that all passed in
 * attribute links are of the same `AttributeType`.
 */
export const getDescriptionForAttributeLinks = (attributeLinks: AttributeLink[]): string =>
    reduce(
        attributeLinks,
        (description, attributeLink) => {
            return description || attributeLink.description || '';
        },
        ''
    );

import _ from 'lodash';

const invalidKeys = ['_active', '_asyncValidating', '_error', '_submitting'];

/**
 * Grab form data from state. No parsing is done, so `redux-form` properties are
 *   included.
 * @param  {Object} state
 * @param  {string} formName
 * @return {Object}
 */
function getFormObjFromState(state, formName) {
    return state.form[formName];
}

/**
 * Grab a list of data objects from form state.
 * @param  {Object}   state
 * @param  {string}   formName
 * @param  {string[]} [formKeys]
 * @return {Object[]}
 */
export function getFormListFromState(state, formName, formKeys) {
    return _.values(getFormsListFromStateByFormKey(state, formName, formKeys));
}

/**
 * Create a map of data objects from form state keyed on form key
 * @param  {Object}   state
 * @param  {string}   formName
 * @param  {string[]} [formKeys]
 * @return {Object}
 */
export function getFormsListFromStateByFormKey(state, formName, formKeys) {
    const formData = getFormObjFromState(state, formName);
    const results = {};
    formKeys = formKeys || _(formData).omit(invalidKeys).keys().value();
    _.forEach(formKeys, (formKey) => {
        const specificForm = formData[formKey];
        const filteredForm = _.omit(specificForm, invalidKeys);
        const valueObj = {};
        _.forIn(filteredForm, (data, key) => {
            valueObj[key] = data.value;
        });
        results[formKey] = valueObj;
    });
    return results;
}

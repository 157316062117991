import map from 'lodash/map';
import { BriefingView } from '@mark43/rms-api';

import createNormalizedModule from '~/client-common/core/utils/createNormalizedModule';
import { ClientCommonAction } from '~/client-common/redux/types';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/attachments/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

export type Briefing = Omit<BriefingView, 'attributes' | 'attachments'>;

const NEXUS_STATE_PROP = 'briefings';

const briefingsModule = createNormalizedModule<Briefing>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// ACTION TYPES
const STORE_BRIEFING = 'briefings/STORE_BRIEFING';

// SELECTORS
export const briefingsByIdSelector = briefingsModule.selectors.entityByIdSelector;

// THUNKS
export const withBriefing = (briefingView: BriefingView): ClientCommonAction<void> => (
    dispatch,
    getState,
    { nexus }
) => {
    const { attachments, attributes, ...briefing } = briefingView;
    dispatch(
        nexus.withEntityItems(
            {
                [NEXUS_STATE_PROP]: [briefing],
                [ATTACHMENTS_NEXUS_STATE_PROP]: attachments,
                [ATTRIBUTES_NEXUS_STATE_PROP]: map(attributes, convertAttributeToAttributeView),
            },
            { type: STORE_BRIEFING }
        )
    );
};

// REDUCER
export default briefingsModule.reducerConfig;

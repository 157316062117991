import { find, memoize } from 'lodash';
import { createSelector } from 'reselect';
import {
    ChainEventType,
    ChainEventCategoryEnumType,
    ChainEventCategoryEnum,
} from '@mark43/evidence-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'chainEventTypes';

const chainEventTypesModule = createNormalizedModule<ChainEventType>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const chainEventTypesSelector = chainEventTypesModule.selectors.entitiesSelector;
export const chainEventTypeByIdSelector = chainEventTypesModule.selectors.entityByIdSelector;
const chainEventTypesWhereSelector = chainEventTypesModule.selectors.entitiesWhereSelector;

export const chainEventTypesByCategorySelector = createSelector(
    chainEventTypesWhereSelector,
    (chainEventTypesWhere) =>
        memoize((chainEventCategory: ChainEventCategoryEnumType) =>
            chainEventTypesWhere({ chainEventCategory })
        )
);

/**
 * Only 1 chain event type for IN_POLICE_CUSTODY
 */
export const inPoliceCustodyChainEventTypeSelector = createSelector(
    chainEventTypesSelector,
    (chainEventTypes) =>
        find(chainEventTypes, {
            chainEventCategory: ChainEventCategoryEnum.IN_POLICE_CUSTODY.name,
        })
);

/**
 * Only 1 chain event type for CHECKED_IN_TEMP
 */
export const checkedInTempChainEventTypeSelector = createSelector(
    chainEventTypesSelector,
    (chainEventTypes) =>
        find(chainEventTypes, {
            chainEventCategory: ChainEventCategoryEnum.CHECKED_IN_TEMP.name,
        }) || {}
);

/**
 * Only 1 chain event type for INFIELD_TRANSFER
 */
export const infieldTransferChainEventTypeSelector = createSelector(
    chainEventTypesSelector,
    (chainEventTypes) => {
        return (
            find(chainEventTypes, {
                chainEventCategory: ChainEventCategoryEnum.INFIELD_TRANSFER.name,
            }) || {}
        );
    }
);

/**
 * Only 1 chain event type for LOCATION_UPDATED
 */
export const locationUpdatedChainEventTypeSelector = createSelector(
    chainEventTypesSelector,
    (chainEventTypes) =>
        find(chainEventTypes, {
            chainEventCategory: ChainEventCategoryEnum.LOCATION_UPDATED.name,
        }) || {}
);

/**
 * Whether chain event type id is a terminating disposition event for
 *   a chain of custody
 */
export const chainEventTypeIdIsDispositionedSelector = createSelector(
    chainEventTypeByIdSelector,
    (chainEventTypeById) => (eventTypeId: number) => {
        const chainEventType = chainEventTypeById(eventTypeId);

        if (!chainEventType) {
            return false;
        }

        const { chainEventCategory } = chainEventType;

        return (
            chainEventCategory === ChainEventCategoryEnum.DISPOSITIONED.name ||
            chainEventCategory === ChainEventCategoryEnum.RELEASED.name ||
            chainEventCategory === ChainEventCategoryEnum.TRANSFERRED.name
        );
    }
);

// REDUCER
export default chainEventTypesModule.reducerConfig;

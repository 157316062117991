import React from 'react';
import { map, orderBy, sortBy } from 'lodash';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import strings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';

import sortRelatedRecordsEnum from '~/client-common/core/enums/client/reportSidebarSortRelatedRecordsEnum';
import { iconTypes } from '../../../../core/components/Icon';
import testIds from '../../../../../core/testIds';
import {
    Link,
    Icon,
    Subheader,
    LinkedRecordContainer,
    LinkedRecordBodyContainer,
    DisplayDate,
} from './LinkedRecordComponents';

export default function LinkedWarrants({
    warrantTitleViewModels,
    canViewWarrants,
    sortType,
    activeValue,
}) {
    const sortedViewModels =
        !!sortType && !!activeValue
            ? ((sortType, activeValue) => {
                  if (sortType === sortTypeEnum.ALPHABETICAL_A_TO_Z) {
                      return orderBy(warrantTitleViewModels, ['warrantTitle'], ['asc']);
                  } else if (sortType === sortTypeEnum.ALPHABETICAL_Z_TO_A) {
                      return orderBy(warrantTitleViewModels, ['warrantTitle'], ['desc']);
                  } else if (
                      activeValue === sortRelatedRecordsEnum.CREATED_DATE &&
                      sortType === sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT
                  ) {
                      return orderBy(warrantTitleViewModels, ['createdDateUtc'], ['desc']);
                  } else if (
                      activeValue === sortRelatedRecordsEnum.CREATED_DATE &&
                      sortType === sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT
                  ) {
                      return orderBy(warrantTitleViewModels, ['createdDateUtc'], ['asc']);
                  } else if (
                      activeValue === sortRelatedRecordsEnum.MODIFIED_DATE &&
                      sortType === sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT
                  ) {
                      return orderBy(warrantTitleViewModels, ['updatedDateUtc'], ['desc']);
                  } else if (
                      activeValue === sortRelatedRecordsEnum.MODIFIED_DATE &&
                      sortType === sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT
                  ) {
                      return orderBy(warrantTitleViewModels, ['updatedDateUtc'], ['asc']);
                  } else {
                      return sortBy(warrantTitleViewModels, ['warrantNumber']);
                  }
              })(sortType, activeValue)
            : sortBy(warrantTitleViewModels, ['warrantNumber']);

    return (
        <div data-test-id={testIds.LINKED_WARRANTS}>
            {map(sortedViewModels, (viewModel) => {
                const { warrantId, warrantIssuedDateUtc, warrantTitle } = viewModel;
                const { obtainingOfficerUserShortDisplay, canRead } = getViewModelProperties(
                    viewModel
                );
                const disabled = !canRead || !canViewWarrants;
                const obtainedBy =
                    obtainingOfficerUserShortDisplay &&
                    `${strings.warrants.generic.obtainedBy} ${obtainingOfficerUserShortDisplay}`;
                return (
                    <LinkedRecordContainer key={warrantId}>
                        <Icon disabled={disabled} type={iconTypes.WARRANT} />
                        <LinkedRecordBodyContainer>
                            <Link to={`/warrants/${warrantId}`} disabled={disabled}>
                                <div>{warrantTitle}</div>
                            </Link>
                            <Subheader>
                                {obtainedBy && <span>{obtainedBy}</span>}
                                {obtainedBy && warrantIssuedDateUtc && <span>,&nbsp;</span>}
                                {warrantIssuedDateUtc && (
                                    <DisplayDate>
                                        <FormattedDate
                                            date={warrantIssuedDateUtc}
                                            format={FormattedDate.FORMATS.SUMMARY_DATE}
                                        />
                                    </DisplayDate>
                                )}
                            </Subheader>
                        </LinkedRecordBodyContainer>
                    </LinkedRecordContainer>
                );
            })}
        </div>
    );
}

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { map, omitBy, flatMap } from 'lodash';
import type { DeepPartial } from 'utility-types';
import { OperationTypeEnumType, StorageLocationTypeEnumType } from '@mark43/rms-api';
import { ElasticStorageLocationQuery } from '@mark43/evidence-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { isExpired as dateIsExpired } from '~/client-common/core/dates/utils/dateHelpers';
import { attributesWithParentAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import elasticSearchResource from '../../../legacy-redux/resources/elasticSearchResource';

export const useFetchStorageLocations = () => {
    const attributesWithParentAttributeId = useSelector(attributesWithParentAttributeIdSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);

    const searchStorageLocations = useCallback(
        ({
            query,
            isExpired,
            evidenceFacilityGlobalAttrIdFilter,
            facilityIdFilter,
            storageLocationTypeFilter,
            requiredPermissions,
        }: {
            query: string;
            isExpired?: boolean;
            facilityIdFilter?: number;
            storageLocationTypeFilter?: StorageLocationTypeEnumType[];
            evidenceFacilityGlobalAttrIdFilter?: number[];
            requiredPermissions?: OperationTypeEnumType[];
        }) => {
            const now = new Date();

            const elasticQuery: DeepPartial<ElasticStorageLocationQuery> = {
                query,
                isExpired,
                facilityTypeAttrIds: flatMap(evidenceFacilityGlobalAttrIdFilter, (globalAttrId) =>
                    map(
                        omitBy(attributesWithParentAttributeId(globalAttrId), ({ end }) =>
                            dateIsExpired(end, now)
                        ),
                        'id'
                    )
                ),
                facilityIds: !!facilityIdFilter ? [facilityIdFilter] : [],
                isFacility: false,
                storageLocationTypes: storageLocationTypeFilter,
                ...(requiredPermissions && applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED
                    ? {
                          storageLocationPermissionsQuery: [
                              {
                                  operationTypes: requiredPermissions,
                              },
                          ],
                      }
                    : {}),
            };
            return elasticSearchResource.searchStorageLocations(
                elasticQuery,
                0,
                100,
                undefined,
                undefined,
                undefined,
                // hide loading bar
                true
            );
        },
        [applicationSettings, attributesWithParentAttributeId]
    );
    return {
        searchStorageLocations,
    };
};

import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { chain, get, size } from 'lodash';
import React from 'react';
import { latestChainOfCustodyIdForMasterItemIdSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import {
    requestDispositions,
    reviewDispositions,
    resetDispositions,
} from '~/client-common/core/domain/disposition-events/state/data';
import { itemEvidenceStateByChainOfCustodyIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import formsRegistry from '../../../../core/formsRegistry';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import {
    ACTION_TYPES,
    createDispositionEvent,
    propsByActionType,
} from './dispositionActionSidePanelProps';

const strings = componentStrings.reports.custodialPropertySummary.DispositionActionSidePanel;

let dispositionSidePanelIndex = 0;

/**
 * Use this higher-order component to co-locate the disposition action side panel with the component that opens it,
 * which is typically a button or dropdown menu option.
 *
 * If you need the side panel to always be rendered instead, then use `DispositionActionSidePanel`.
 */
const withDispositionActionSidePanel = (Component) =>
    compose(
        connect(
            createStructuredSelector({
                itemEvidenceStateByChainOfCustodyId: itemEvidenceStateByChainOfCustodyIdSelector,
                latestChainOfCustodyIdForMasterItemId: latestChainOfCustodyIdForMasterItemIdSelector,
            }),
            { requestDispositions, reviewDispositions, resetDispositions }
        ),
        withProps(({ actionType }) => {
            return propsByActionType[actionType];
        }),
        withHandlers({
            closePanel: () => (renderProps) => {
                const { formType } = renderProps.overlayBase.overlayState.customProperties;
                renderProps.closePanel();
                formsRegistry.unregister(formType);
            },
        }),
        withHandlers({
            submitForm: ({
                closePanel,
                itemEvidenceStateByChainOfCustodyId,
                latestChainOfCustodyIdForMasterItemId,
                requestDispositions,
                reviewDispositions,
                resetDispositions,
            }) => (renderProps) => {
                const {
                    actionType,
                    formType,
                    masterItemIds,
                    overlayId,
                } = renderProps.overlayBase.overlayState.customProperties;
                return formsRegistry
                    .get(formType)
                    .submit()
                    .then(() => {
                        const dispositionEvents = chain(masterItemIds)
                            .map(latestChainOfCustodyIdForMasterItemId)
                            .map(itemEvidenceStateByChainOfCustodyId)
                            .map((itemEvidenceState) =>
                                createDispositionEvent(
                                    actionType,
                                    formsRegistry.get(formType),
                                    itemEvidenceState
                                )
                            )
                            .value();

                        switch (actionType) {
                            case ACTION_TYPES.REQUEST_DISPOSITION:
                                return requestDispositions(masterItemIds, dispositionEvents);
                            case ACTION_TYPES.RESET_RETENTION_POLICY:
                                return resetDispositions(masterItemIds, dispositionEvents);
                            default:
                                return reviewDispositions(masterItemIds, dispositionEvents);
                        }
                    })
                    .then(() => closePanel(renderProps))
                    .catch((err) =>
                        renderProps.overlayBase.overlayStore.setError(
                            overlayId,
                            err.message || get(err, 'validationResult.formErrors')
                        )
                    );
            },
        })
    )(
        class DispositionActionSidePanel extends React.Component {
            constructor(props) {
                super(props);

                this.overlayId = `disposition-action_side-panel_${dispositionSidePanelIndex}`;
                dispositionSidePanelIndex++;
            }
            render() {
                const {
                    Form,
                    actionType,
                    closePanel,
                    formType,
                    masterItemIds,
                    submitForm,
                    title,
                    onClick,
                    ...props
                } = this.props;

                const renderButton = ({ overlayBase: { open }, setCloseFocusRefs }) => (
                    <Component
                        {...props}
                        onClick={() => {
                            open();
                            if (onClick) {
                                onClick();
                            }
                        }}
                        setRef={setCloseFocusRefs}
                    />
                );

                return (
                    <OverlayBaseHelper
                        id={this.overlayId}
                        renderButton={renderButton}
                        getInitialCustomPropertyState={() => ({
                            Form,
                            actionType,
                            formType,
                            overlayId: this.overlayId,
                            masterItemIds,
                            title,
                        })}
                    >
                        {(renderProps) => {
                            const {
                                Form,
                                overlayId,
                                title,
                            } = renderProps.overlayBase.overlayState.customProperties;
                            const errors = renderProps.overlayBase.overlayState.errors;

                            return (
                                <PortalSidePanel
                                    {...props}
                                    closePanel={() => closePanel(renderProps)}
                                    errorMessages={errors}
                                    saveText={strings.confirmButton}
                                    savePanel={() => submitForm(renderProps)}
                                    title={title}
                                    userHasAttemptedSave={!!size(errors)}
                                    isAtBottomOfStack={() =>
                                        renderProps.overlayBase.overlayStore.isOverlayAtBottomOfStack(
                                            overlayId
                                        )
                                    }
                                >
                                    <Form />
                                </PortalSidePanel>
                            );
                        }}
                    </OverlayBaseHelper>
                );
            }
        }
    );

export default withDispositionActionSidePanel;
export { ACTION_TYPES };

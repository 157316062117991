import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { getDropdownOptionsFromObject } from '~/client-common/helpers/dropdownOptionHelpers';
import Select from './Select';

const options = componentStrings.forms.select.PersonsSearchResultsDefaultSelect.options;

const personsSearchResultsDefaultOptions = getDropdownOptionsFromObject(options);

/**
 * Select dropdown for Persons Search Results Defaults
 * @param {Object} props
 * @param {string} [props.label] Set this prop to use a custom label different
 *   from the default label.
 */
export default function PersonsSearchResultsDefaultSelect(props) {
    return <Select clearable={false} options={personsSearchResultsDefaultOptions} {...props} />;
}

import React from 'react';
import classNames from 'classnames';

const AdminWorkArea: React.FC<
    React.DetailedHTMLProps<React.FieldsetHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement>
> = ({ className, ...otherProps }) => {
    return <fieldset className={classNames('admin-work-area', className)} {...otherProps} />;
};

export default AdminWorkArea;

import React from 'react';
import styled from 'styled-components';
import { FormattedDate } from '~/client-common/core/dates/components';
import withFields from '~/client-common/core/fields/components/withFields';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import Link from '../../../core/components/links/Link';

const DateContainer = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.cobaltBlue};
`;

const FieldNoteContainer = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    padding: 15px;
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-md);
    cursor: pointer;
    border-left: 4px solid transparent;
    ${(props) => props.active && `background-color: ${props.theme.colors.lightBlue};`};
    ${(props) => props.active && `border-left: 4px solid ${props.theme.colors.cobaltBlue};`};
`;

const FieldNoteRen = styled.div`
    font-style: italic;
`;

const FieldNoteNote = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

class NotepadListItem extends React.Component {
    render() {
        const { active, fieldNote, isArchiveRoute } = this.props;
        return (
            <Link
                to={
                    isArchiveRoute
                        ? `mobile/archive/${fieldNote.id}`
                        : `mobile/collections/${fieldNote.id}`
                }
            >
                <FieldNoteContainer active={active}>
                    <DateContainer>
                        <FormattedDate
                            date={fieldNote.createdDateUtc}
                            format={FormattedDate.FORMATS.DATE_TIME_IN_SENTENCE}
                        />
                    </DateContainer>
                    {fieldNote.reportingEventNumber && (
                        <FieldNoteRen>
                            {this.props.fieldDisplayNames[REPORT_REPORTING_EVENT_NUMBER]}:{' '}
                            {fieldNote.reportingEventNumber}
                        </FieldNoteRen>
                    )}
                    <FieldNoteNote>{fieldNote.title}</FieldNoteNote>
                    <FieldNoteNote>{fieldNote.note}</FieldNoteNote>
                </FieldNoteContainer>
            </Link>
        );
    }
}

export default withFields([REPORT_REPORTING_EVENT_NUMBER])(NotepadListItem);

import { remove } from 'lodash';

export const sideEffects = [];

export const registerSideEffect = (sideEffect) => {
    sideEffects.push(sideEffect);

    // After registering, this function
    // returns a method to deregister
    return () => {
        remove(sideEffects, (existingSideEffect) => {
            return sideEffect === existingSideEffect;
        });
    };
};

import { CliArgumentUsageTypeEnum } from '@mark43/cad-api';
import _, { map, groupBy, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import {
    commandLineArgumentsSelector,
    commandLineArgumentsByIdSelector,
} from '~/client-common/core/domain/command-line/state/data';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { prettify } from '~/client-common/helpers/stringHelpers';
import { cadUnitStatusesSelector } from '~/client-common/core/domain/cad-unit-statuses/state/data';
import commandLineArgumentEnum from '~/client-common/core/enums/universal/commandLineArgumentEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import commandLineArgumentForm from '../forms/commandLineArgumentForm';

const SELECT_COMMAND_LINE_ARGUMENT_START = 'commandLine/SELECT_COMMAND_LINE_ARGUMENT_START';
const SELECT_COMMAND_LINE_ARGUMENT_SUCCESS = 'commandLine/SELECT_COMMAND_LINE_ARGUMENT_SUCCESS';
const SELECT_COMMAND_LINE_ARGUMENT_FAILURE = 'commandLine/SELECT_COMMAND_LINE_ARGUMENT_FAILURE';
const RESET_COMMAND_LINE_ADMIN_PAGE = 'commandLine/RESET_COMMAND_LINE_ADMIN_PAGE';

export const LOAD_COMMAND_LINE_START = 'commandLine/LOAD_COMMAND_LINE_START';
export const LOAD_COMMAND_LINE_SUCCESS = 'commandLine/LOAD_COMMAND_LINE_SUCCESS';
export const LOAD_COMMAND_LINE_FAILURE = 'commandLine/LOAD_COMMAND_LINE_FAILURE';

export const SAVE_COMMAND_LINE_ARGUMENT_FORM_START =
    'commandLine/SAVE_COMMAND_LINE_ARGUMENT_FORM_START';
export const SAVE_COMMAND_LINE_ARGUMENT_FORM_SUCCESS =
    'commandLine/SAVE_COMMAND_LINE_ARGUMENT_FORM_SUCCESS';
export const SAVE_COMMAND_LINE_ARGUMENT_FORM_FAILURE =
    'commandLine/SAVE_COMMAND_LINE_ARGUMENT_FORM_FAILURE';

export const REMOVE_COMMAND_LINE_ARGUMENT_START = 'commandLine/REMOVE_COMMAND_LINE_ARGUMENT_START';
export const REMOVE_COMMAND_LINE_ARGUMENT_SUCCESS =
    'commandLine/REMOVE_COMMAND_LINE_ARGUMENT_SUCCESS';
export const REMOVE_COMMAND_LINE_ARGUMENT_FAILURE =
    'commandLine/REMOVE_COMMAND_LINE_ARGUMENT_FAILURE';

function selectCommandLineArgumentStart() {
    return { type: SELECT_COMMAND_LINE_ARGUMENT_START };
}

function selectCommandLineArgumentSuccess(commandLineArgumentId) {
    return {
        type: SELECT_COMMAND_LINE_ARGUMENT_SUCCESS,
        payload: commandLineArgumentId,
    };
}

function selectCommandLineArgumentFailure(errorMessage) {
    return {
        type: SELECT_COMMAND_LINE_ARGUMENT_FAILURE,
        payload: errorMessage,
    };
}

export function resetCommandLineAdminPage() {
    return { type: RESET_COMMAND_LINE_ADMIN_PAGE };
}

const initialUiState = {
    error: null,
    submittingForm: false,
    selectedCommandLineArgumentId: null,
};

function commandLineAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SELECT_COMMAND_LINE_ARGUMENT_START:
            return {
                ...state,
                submittingForm: false,
                selectedCommandLineArgumentId: null,
                error: null,
            };

        case SELECT_COMMAND_LINE_ARGUMENT_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                selectedCommandLineArgumentId: action.payload,
                errors: null,
            };
        case SELECT_COMMAND_LINE_ARGUMENT_FAILURE:
        case LOAD_COMMAND_LINE_FAILURE:
            return {
                ...state,
                submittingForm: false,
                error: action.payload,
            };
        case SAVE_COMMAND_LINE_ARGUMENT_FORM_START:
            return {
                ...state,
                submittingForm: true,
                error: null,
            };
        case SAVE_COMMAND_LINE_ARGUMENT_FORM_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                error: null,
            };
        case REMOVE_COMMAND_LINE_ARGUMENT_FAILURE:
        case SAVE_COMMAND_LINE_ARGUMENT_FORM_FAILURE:
            return {
                ...state,
                submittingForm: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export function selectCommandLineArgument(commandLineArgumentId) {
    return (dispatch, getState) => {
        dispatch(selectCommandLineArgumentStart());
        const state = getState();
        const id = parseInt(commandLineArgumentId);
        let commandLineArgument = commandLineArgumentsByIdSelector(state)(id);

        if (commandLineArgument) {
            commandLineArgument = {
                ...commandLineArgument,
                cliArgumentConfigurationLinks: sortBy(
                    commandLineArgument.cliArgumentConfigurationLinks,
                    (link) => {
                        return link.cliArgumentUsageType !== CliArgumentUsageTypeEnum.REQUIRED.name;
                    }
                ),
            };
            dispatch(commandLineArgumentForm.actionCreators.change({ commandLineArgument }));

            dispatch(selectCommandLineArgumentSuccess(commandLineArgument.id));
        } else {
            dispatch(selectCommandLineArgumentFailure());
        }
    };
}

export const commandLineAdminUiSelector = createSelector(
    commandLineArgumentsSelector,
    (commandLineArguments) => {
        return {
            groupedCommandLineArguments: groupBy(
                map(commandLineArguments, (argument) => {
                    return {
                        ...argument,
                        cliArgumentConfig: commandLineArgumentEnum[argument.cliArgument],
                    };
                }),
                'cliArgumentConfig.argumentType'
            ),
        };
    }
);

export const uiSelector = (state) => state.ui.commandLineAdmin;

export const commandLineAdminUiListItemsSelector = createSelector(
    uiSelector,
    commandLineArgumentsSelector,
    (commandLineAdmin, commandLineArguments) => {
        return _(commandLineArguments)
            .filter((argument) => argument.id > -1)
            .map((argument) => {
                const cliArgumentConfig = commandLineArgumentEnum[argument.cliArgument];
                const cliArgumentDisplayName = cliArgumentConfig?.display || '';
                return {
                    key: argument.id,
                    title: `${argument.argumentDefinition} - ${
                        cliArgumentDisplayName || prettify(argument.cliArgument)
                    }`,
                    subtitle: prettify(cliArgumentConfig.argumentType),
                    path: `/admin/cad/command-line/${argument.id}`,
                    selected: commandLineAdmin.selectedCommandLineArgumentId === argument.id,
                };
            })
            .sortBy('title')
            .sortBy((listItem) => (listItem.subtitle ? listItem.subtitle : null))
            .value();
    }
);

export const commandLineAdminErrorSelector = createSelector(
    uiSelector,
    (commandLineAdmin) => commandLineAdmin.error
);

const policeUnitStatusesSelector = createSelector(cadUnitStatusesSelector, (unitStatuses) => {
    return _.filter(
        unitStatuses,
        (unitStatus) =>
            unitStatus.agencyTypeGlobalAttrId === globalAttributes.agencyTypeGlobal.police
    );
});

const fireUnitStatusesSelector = createSelector(cadUnitStatusesSelector, (unitStatuses) => {
    return _.filter(
        unitStatuses,
        (unitStatus) => unitStatus.agencyTypeGlobalAttrId === globalAttributes.agencyTypeGlobal.fire
    );
});

const emsUnitStatusesSelector = createSelector(cadUnitStatusesSelector, (unitStatuses) =>
    _.filter(
        unitStatuses,
        (unitStatus) =>
            unitStatus.agencyTypeGlobalAttrId === globalAttributes.agencyTypeGlobal.medical
    )
);

export const policeUnitStatusOptionsSelector = createSelector(
    policeUnitStatusesSelector,
    (unitStatuses) =>
        _.map(unitStatuses, (unitStatus) => {
            return {
                display: unitStatus.displayValue,
                value: unitStatus.id,
                unitStateGroup: unitStatus.unitStateGroup,
            };
        })
);

export const fireUnitStatusOptionsSelector = createSelector(
    fireUnitStatusesSelector,
    (unitStatuses) =>
        _.map(unitStatuses, (unitStatus) => {
            return {
                display: unitStatus.displayValue,
                value: unitStatus.id,
                unitStateGroup: unitStatus.unitStateGroup,
            };
        })
);

export const emsUnitStatusOptionsSelector = createSelector(
    emsUnitStatusesSelector,
    (unitStatuses) =>
        _.map(unitStatuses, (unitStatus) => {
            return {
                display: unitStatus.displayValue,
                value: unitStatus.id,
                unitStateGroup: unitStatus.unitStateGroup,
            };
        })
);

export const cadFilterUnitStatusSelectOptionsSelector = createSelector(
    cadUnitStatusesSelector,
    (unitStatuses) => (filterObject) => {
        const filteredUnitStatus = _.filter(unitStatuses, filterObject);
        return _.map(filteredUnitStatus, (unitStatus) => {
            return {
                display: unitStatus.displayValue,
                value: unitStatus.id,
                unitStateGroup: unitStatus.unitStateGroup,
            };
        });
    }
);

export const selectedCommandLineArgumentIdSelector = createSelector(
    uiSelector,
    (ui) => ui.selectedCommandLineArgumentId
);

export const selectedCommandLineArgumentSelector = createSelector(
    uiSelector,
    commandLineArgumentsByIdSelector,
    (ui, commandLineArgumentsById) => commandLineArgumentsById(ui.selectedCommandLineArgumentId)
);

export function getIntegersAsOptions(start, end, step = 1, valueMultiplier = 1) {
    return _.map(
        // lodash excludes your end number, boooo
        _.range(start, end + step, step),
        (int) => ({
            display: `${int}`,
            value: int * valueMultiplier,
        })
    );
}

export default makeResettable(RESET_COMMAND_LINE_ADMIN_PAGE, commandLineAdminUiReducer);

import { standaloneToast } from 'arc';
import { InventoryItemStatusEnum, PrintingDataTypeEnum } from '@mark43/rms-api';
import { indexOf } from 'lodash';
import { createSelector } from 'reselect';
import { inventoryItemViewModelsByIdSelector } from '~/client-common/core/domain/inventory-items/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { currentThemeNameSelector } from '../../../../core/styles/state';
import { exportDocument } from '../../../../../legacy-redux/actions/exportsActions';
import inventoriesExportResource from '../../../inventories/resources/inventoriesExportResource';

const { ALPHABETICAL_A_TO_Z } = sortTypeEnum;
const { PENDING, MISSING, SCANNED, UNEXPECTED } = InventoryItemStatusEnum;

const SORT_ROWS = 'evidence-inventory/SORT_ROWS';

export const ITEM_ID_COLUMN_KEY = 'ITEM_ID_COLUMN_KEY';
export const ITEM_STATUS_COLUMN_KEY = 'ITEM_STATUS_COLUMN_KEY';
const inventoryUiSelector = (state) => state.ui.evidence.inventory;

// SELECTORS
export const inventorySortKeySelector = createSelector(
    inventoryUiSelector,
    ({ sortKey }) => sortKey
);

export const inventorySortTypeSelector = createSelector(
    inventoryUiSelector,
    ({ sortType }) => sortType
);

export const inventoryItemViewModelsByInventoryIdSelector = createSelector(
    inventoryItemViewModelsByIdSelector,
    inventorySortKeySelector,
    inventorySortTypeSelector,
    (inventoryItemViewModelsById, inventorySortKey, inventorySortType) => (inventoryId) =>
        inventoryItemViewModelsById(inventoryId).sort((itemA, itemB) => {
            const descending = inventorySortType === ALPHABETICAL_A_TO_Z ? -1 : 1;
            const { itemId: idA, status: statusA } = getViewModelProperties(itemA);

            const { itemId: idB, status: statusB } = getViewModelProperties(itemB);

            if (inventorySortKey === ITEM_ID_COLUMN_KEY) {
                return descending * (idA < idB ? -1 : idA === idB ? 0 : 1);
            } else if (inventorySortKey === ITEM_STATUS_COLUMN_KEY) {
                const statusOrder = [PENDING.name, SCANNED.name, MISSING.name, UNEXPECTED.name];

                return descending * (indexOf(statusOrder, statusA) - indexOf(statusOrder, statusB));
            }

            return 0;
        })
);

// ACTIONS
export const sortInventoryBySortKey = ({ sortKey }) => ({
    type: SORT_ROWS,
    payload: {
        sortKey,
    },
});

/**
 * Kick off a job to export a single inventory csv and open the export tray.
 * Before the export job starts, this action creator handles its own errors by opening an alert modal.
 * After the export job starts, export errors are handled outside of this action.
 */
export function exportInventory(inventoryId, title) {
    return (dispatch, getState) => {
        return inventoriesExportResource
            .getInventoryPrintables()
            .then((printables) => {
                const printingTemplateId = printables[0]?.printingTemplateId;
                if (printingTemplateId) {
                    return dispatch(
                        exportDocument({
                            requestData: {
                                inventoryId,
                                printingTemplateId,
                                dataType: PrintingDataTypeEnum.INVENTORY.name,
                                title,
                            },
                            exportResourceMethod: inventoriesExportResource.exportInventory,
                            disableUsageLogs: false,
                        })
                    );
                } else {
                    throw new Error('Failed to export inventory, please reload and try again');
                }
            })
            .catch((err) => {
                const state = getState();
                const themeName = currentThemeNameSelector(state);
                const toast = standaloneToast(themeName);
                toast({
                    status: 'error',
                    description: err.message,
                });
            });
    };
}

// REDUCERS
const inventoriesUiReducer = (
    state = {
        sortKey: ITEM_STATUS_COLUMN_KEY,
        sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
    },
    { type, payload }
) => {
    switch (type) {
        case SORT_ROWS:
            return {
                sortKey: payload.sortKey,
                sortType:
                    state.sortKey !== payload.sortKey
                        ? sortTypeEnum.ALPHABETICAL_A_TO_Z
                        : state.sortType === sortTypeEnum.ALPHABETICAL_A_TO_Z
                        ? sortTypeEnum.ALPHABETICAL_Z_TO_A
                        : sortTypeEnum.ALPHABETICAL_A_TO_Z,
            };

        default:
            return state;
    }
};

export default inventoriesUiReducer;

import { ElasticSearchTypeEnum, DeliveryMethodEnum } from '@mark43/rms-api';
import _, { omit } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import store from '../../../../../core/store';
import { storePayloadToBox, openBox } from '../../../../../legacy-redux/actions/boxActions';
import { openConfirmationBar } from '../../../../core/confirmation-bar/state/ui';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import usersDashboardSearchForm, {
    convertUsersDashboardSearchFormModelToFilterGroups,
    convertUsersDashboardSearchElasticQueryToFormModel,
} from '../forms/usersDashboardSearchForm';
import { BULK_UPDATE_NOTIFICATIONS_FORM } from '../forms/bulkUpdateNotificationsFormConfig';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../../search/core/state/ui';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import searchResource from '../../../../../legacy-redux/resources/searchResource';
import { closeUsersDashboardFilterModal, openUsersDashboardFilterModal } from './filterModal';

export { openUsersDashboardFilterModal };

export const usersDashboardSearchConstants = {
    FROM: 0,
    SIZE: 25,
};

const strings = componentStrings.admin.users.dashboard.results;

const addUserContext = {
    name: boxEnum.USER_PROFILE_ADMIN_SIDE_PANEL,
};

export const openAddUserSidePanel = () => {
    return (dispatch) => {
        dispatch(storePayloadToBox(addUserContext, { isEditing: false }));
        dispatch(openBox(addUserContext));
    };
};

export const submitUsersDashboardSearchForm = (showLoadingModal = true) => {
    return function (dispatch) {
        return dispatch(
            usersDashboardSearch.form.actionCreators.submit((formData) => {
                // make the search
                dispatch(
                    usersDashboardSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        {
                            cacheBust: true,
                            scroll: true,
                            showLoadingModal,
                        }
                    )
                ).then(() => {
                    dispatch(closeUsersDashboardFilterModal());
                });
            })
        );
    };
};

export function clearAllFilters() {
    return function (dispatch) {
        dispatch(usersDashboardSearch.form.actionCreators.reset());
        dispatch(usersDashboardSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(usersDashboardSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
        dispatch(closeUsersDashboardFilterModal());
        dispatch(
            usersDashboardSearch.form.actionCreators.submit((formData) => {
                // make the search
                dispatch(
                    usersDashboardSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                );
            })
        );
    };
}

export const applyFiltersAndUpdateSearch = () => {
    return function (dispatch) {
        return dispatch(
            usersDashboardSearch.form.actionCreators.submit((formData) => {
                dispatch(
                    usersDashboardSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        {
                            cacheBust: true,
                            scroll: true,
                        }
                    )
                ).then((response) => {
                    dispatch(
                        usersDashboardSearch.actionCreators.updateSavedSearch(
                            response.payload.query
                        )
                    );
                    dispatch(closeUsersDashboardFilterModal());
                });
            })
        );
    };
};

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
function convertUsersDashboardSearchElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    // removing the query so it doesn't appear as an applied filter
    const filteredQuery = omit(elasticQuery, 'quickSearchQuery');
    return convertUsersDashboardSearchFormModelToFilterGroups(
        convertUsersDashboardSearchElasticQueryToFormModel(filteredQuery),
        formatFieldValue,
        boundSelectors
    );
}

function userDashboardSearchResourceMethod(...args) {
    const state = store.getState();
    const applicationSettings = applicationSettingsSelector(state);
    if (applicationSettings.RMS_USER_WITH_MFA_CONFIG_DASHBOARD_ENABLED) {
        return elasticSearchResource.searchUsersWithMfaConfig(...args);
    } else {
        return elasticSearchResource.searchUsers(...args);
    }
}

export const usersDashboardSearch = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.USER.name,
    baseUiSelector: (state) => state.ui.usersDashboardAdmin,
    form: usersDashboardSearchForm,
    elasticQueryToFilterGroups: convertUsersDashboardSearchElasticQueryToFilterGroups,
    searchResultToRoutePath: ({ id }) => `admin/users/${id}`,
    defaultTableState: {
        from: usersDashboardSearchConstants.FROM,
        size: usersDashboardSearchConstants.SIZE,
        sortKey: sortKeyEnum.USER_UPDATED_DATE_UTC,
        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
    },
    resourceMethod: userDashboardSearchResourceMethod,
    resultsContainerClassName: 'users-dashboard-search-results',
    createResultsViewModelsSelector: (selector) => selector,
});

export function performInitialUsersDashboardSearch() {
    return function (dispatch, getState) {
        const applicationSettings = applicationSettingsSelector(getState());
        if (applicationSettings.RMS_USER_WITH_MFA_CONFIG_DASHBOARD_ENABLED) {
            const formModel = usersDashboardSearchForm.selectors.formModelSelector(
                getState()
            );
            return dispatch(
                usersDashboardSearch.actionCreators.search(
                    {
                        formData: formModel,
                    },
                    // do not auto-save the default search so that
                    // a faulty request cannot cause your latest saved-search
                    // to be overwritten by the default search
                    { cacheBust: true, scroll: false, autoSave: false }
                )
            );
        } else {
            dispatch(usersDashboardSearch.actionCreators.loadAndExecuteLatestAutoSavedSearch()).then(
                (result) => {
                    if (!result) {
                        // execute first time visit search
                        const formModel = usersDashboardSearchForm.selectors.formModelSelector(
                            getState()
                        );
                        return dispatch(
                            usersDashboardSearch.actionCreators.search(
                                {
                                    formData: formModel,
                                },
                                // do not auto-save the default search so that
                                // a faulty request cannot cause your latest saved-search
                                // to be overwritten by the default search
                                { cacheBust: true, scroll: false, autoSave: false }
                            )
                        );
                    }
                }
            );
        }
    };
}

/**
 *   update notification settings for selected search results. If all results are selected,
 *    make a server request with the original elastic query. If only some specific
 *   results are selected, make a server request with an elastic query that
 *   contains only the selected `ids`. In both cases, the `from` and `size`
 *   properties are not included.
 * @param {number[]} selectedRows Indexes on the current page.
 * @param {boolean}  allResultsSelected
 */

export const buildUpdateNotificationSettingsActionCreator = (
    selectedRows,
    allResultsSelected,
    selectors
) => {
    return function (dispatch, getState, { formsRegistry }) {
        const state = getState();
        const formRows = formsRegistry.get(BULK_UPDATE_NOTIFICATIONS_FORM).getState().model
            .batchUpdateNotificationRequests;
        const userOptIns = [];
        const userOptOuts = [];
        formRows.forEach((item) => {
            if (item.emailDelivery === 'true') {
                userOptIns.push({
                    deliveryMethod: DeliveryMethodEnum.EMAIL.name,
                    notificationType: item.notificationType,
                });
            } else {
                userOptOuts.push({
                    deliveryMethod: DeliveryMethodEnum.EMAIL.name,
                    notificationType: item.notificationType,
                });
            }

            if (item.inAppDelivery === 'true') {
                userOptIns.push({
                    deliveryMethod: DeliveryMethodEnum.WEB_APP.name,
                    notificationType: item.notificationType,
                });
            } else {
                userOptOuts.push({
                    deliveryMethod: DeliveryMethodEnum.WEB_APP.name,
                    notificationType: item.notificationType,
                });
            }
        });

        // build the elastic query depending on the selected results
        const elasticQuery = allResultsSelected
            ? selectors.currentQuerySelector(state).elasticQuery
            : {
                  ids: _(selectors.currentResultsSelector(state))
                      .pick(selectedRows)
                      .map('id')
                      .value(),
              };
        const transformedElasticQuery = transformElasticQueryIdsToDisplayValuesSelector(state)(
            elasticQuery
        );

        const userSearchQuery = { elasticQuery: transformedElasticQuery };

        const promise = searchResource
            .updateNotificationSettingsResource(userSearchQuery, userOptIns, userOptOuts)
            .then(() => {
                dispatch(
                    openConfirmationBar({
                        message: strings.bulkUpdateSuccess,
                    })
                );
            })
            .catch(() => {
                dispatch(
                    openConfirmationBar({
                        message: strings.bulkUpdateFailure,
                    })
                );
            });
        // done is necessary so that any errors which reach this point
        // will be raised to the window
        promise.done();
        return promise;
    };
};

export default usersDashboardSearch.reducers.uiReducer;

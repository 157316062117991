import React, { useState } from 'react';
import styled from 'styled-components';
import { IOverlayBaseProps, IOverlayBaseRenderProps } from 'overlay-manager/lib/OverlayBase';
import { Optional } from 'utility-types';

import { OverlayBase } from '../../../../core/overlayManager';
import errorToMessage from '../../errors/utils/errorToMessage';
import SidePanelBase, { SidePanelBaseProps } from './SidePanelBase';

type OverrideSidePanelPropsFunction<T extends Record<string, unknown>> = (
    overlayBaseProps: IOverlayBaseRenderProps<T>
) => Partial<SidePanelBaseProps>;

type OverlayBaseProps<T extends Record<string, unknown>> = Pick<
    IOverlayBaseProps<T>,
    'autoClose' | 'getInitialCustomPropertyState' | 'id'
>;

interface SidePanelProps<T extends Record<string, unknown> = Record<string, unknown>>
    extends OverlayBaseProps<T>,
        Optional<SidePanelBaseProps, 'isOpen'> {
    buttonElement?: React.ReactNode;
    cancelFocusRef?: React.RefObject<HTMLElement>;
    children?: React.ReactNode;
    onSave?: (customProperties: T) => void | Promise<void>;
    overrideSidePanelProps?: Partial<SidePanelBaseProps> | OverrideSidePanelPropsFunction<T>;
    saveFocusRef?: React.RefObject<HTMLElement>;
    onCancel?: () => void;
}

const StyledSidePanelBase = styled(SidePanelBase)`
    right: 0;
    z-index: auto;
    height: 100%;
    bottom: auto;

    && {
        position: absolute;
        top: auto;
    }
`;

/**
 * Side Panel managed by overlay-manager. Please pair with OverlayButton.
 *   Default return focus to last active element (typically Button that opened Side Panel),
 *   use cancelFocusRef and/or saveFocusRef to redirect focus.
 *   Use overrideSidePanelProps function to access overlayBase props.
 */
function SidePanel<T extends Record<string, unknown>>(
    props: SidePanelProps<T>
): React.ReactElement | null {
    const {
        autoClose,
        buttonElement,
        cancelFocusRef,
        getInitialCustomPropertyState,
        id,
        onSave,
        overrideSidePanelProps,
        saveFocusRef,
        testId,
        onCancel,
        ...sidePanelProps
    } = props;

    const [isSaving, setIsSaving] = useState(false);

    return (
        <OverlayBase
            autoClose={autoClose}
            getInitialCustomPropertyState={getInitialCustomPropertyState}
            id={id}
        >
            {(overlayBaseProps) => {
                const {
                    close: closeOverlay,
                    isAtBottomOfStack,
                    overlayState,
                    setError,
                } = overlayBaseProps;
                const { customProperties, errors, isLoading, isOpen } = overlayState;

                function close(closeFocusRef?: React.RefObject<HTMLElement>) {
                    onCancel?.();
                    closeOverlay();

                    const refToFocus = closeFocusRef?.current;
                    if (refToFocus) {
                        refToFocus.focus();
                    }
                }

                function createSavePanel() {
                    if (!onSave) {
                        return () => close(saveFocusRef);
                    }

                    return () => {
                        try {
                            const possiblePromise = onSave(customProperties);

                            if (!possiblePromise || !possiblePromise.then) {
                                close(saveFocusRef);
                            } else {
                                setIsSaving(true);
                                possiblePromise
                                    .then(() => {
                                        setIsSaving(false);
                                        close(saveFocusRef);
                                    })
                                    .catch((error: Error) => {
                                        setIsSaving(false);
                                        setError(error.message);
                                    });
                            }
                        } catch (error) {
                            setIsSaving(false);
                            setError(errorToMessage(error));
                        }
                    };
                }

                // escape hatch to consume overlayBaseProps and override any SidePanel props
                let moreSidePanelProps = overrideSidePanelProps;
                if (typeof overrideSidePanelProps === 'function') {
                    moreSidePanelProps = overrideSidePanelProps(overlayBaseProps);
                }

                return (
                    <>
                        {buttonElement}
                        {isOpen && (
                            <StyledSidePanelBase
                                {...sidePanelProps}
                                closePanel={() => close(cancelFocusRef)}
                                dimmedOverlay={isAtBottomOfStack()}
                                errorMessages={errors}
                                isOpen={isOpen}
                                loading={isLoading}
                                onRequestClose={() => close(cancelFocusRef)}
                                savePanel={createSavePanel()}
                                saving={isSaving}
                                shouldReturnFocusAfterClose={!cancelFocusRef && !saveFocusRef}
                                testId={testId || id}
                                {...moreSidePanelProps}
                            />
                        )}
                    </>
                );
            }}
        </OverlayBase>
    );
}

export default SidePanel;

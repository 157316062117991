import type {
    ApiResultListPrintable,
    ApiResultListUserExport,
    SqlSearchQueryInventorySqlQuery,
} from '@mark43/evidence-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/evidence/api';

export default createResource({
    name: 'Inventory Export Resource',
    methods: {
        exportInventorySearchResults(
            query: SqlSearchQueryInventorySqlQuery,
            printingTemplateIds: number[]
        ) {
            return req<{
                method: 'POST';
                path: 'evidence/print/search/inventories';
                params: {
                    printing_template_ids?: number[];
                };
                data: SqlSearchQueryInventorySqlQuery;
                returns: ApiResultListUserExport;
            }>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: 'evidence/print/search/inventories',
                params: { printing_template_ids: printingTemplateIds },
                data: query,
            });
        },
        exportInventory({
            inventoryId,
            printingTemplateId,
        }: {
            inventoryId: number;

            printingTemplateId: number;
        }) {
            return req<{
                method: 'POST';
                path: `evidence/print/inventory/${string | number}`;
                params: {
                    printing_template_ids?: number[];
                };
                returns: ApiResultListUserExport;
            }>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `evidence/print/inventory/${inventoryId}`,
                params: { printing_template_ids: [printingTemplateId] },
            });
        },
        getInventoryPrintables() {
            return req<{
                path: 'evidence/print/inventories/printables';
                method: 'GET';
                returns: ApiResultListPrintable;
            }>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: 'evidence/print/inventories/printables',
            });
        },
    },
});

import React from 'react';
import { Button } from '@arc/button';
import { ButtonProps } from 'arc';
import { useScreenBreakpoint } from '../../../../../../core/utils/useScreenBreakpoint';

type CrashDiagramActionButtonProps = {
    label: string;
    isHeaderButton?: boolean;
} & ButtonProps;

export const CrashDiagramActionButton = React.forwardRef<
    HTMLButtonElement,
    CrashDiagramActionButtonProps
>(({ label, isHeaderButton, ...rest }, ref) => {
    const { isMobile } = useScreenBreakpoint();

    return (
        <Button
            {...rest}
            aria-label={label}
            ref={ref}
            size={isMobile && isHeaderButton ? 'sm' : 'md'}
        >
            {!isMobile && label}
        </Button>
    );
});

import { keyBy } from 'lodash';
import { NibrsOffenseCodeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'nibrsOffenseCodes';

export type NibrsOffenseCode = typeof NibrsOffenseCodeEnum[keyof typeof NibrsOffenseCodeEnum];

const nibrsOffenseCodesModule = createNormalizedModule<NibrsOffenseCode>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

export const nibrsOffenseCodesSelector = nibrsOffenseCodesModule.selectors.entitiesSelector;
export const nibrsOffenseCodesWhereSelector =
    nibrsOffenseCodesModule.selectors.entitiesWhereSelector;

export const nibrsOffenseCodeByCodeSelector = createSelector(
    nibrsOffenseCodesSelector,
    (nibrsOffenseCodes) => keyBy(nibrsOffenseCodes, 'code')
);

export default nibrsOffenseCodesModule.reducerConfig;

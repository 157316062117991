// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/evidence/api';
const BASE_PATH = 'facilities';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Facility Resource',
        methods: {
            /**
             * Get all facilities in the department and their locations. Storage
             *   locations within the facilities are not included.
             * @return {Promise<Object>}
             */
            getFacilities() {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: BASE_PATH,
                });
            },
            createFacility(facility) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: BASE_PATH,
                    data: facility,
                });
            },
            getFacility(id) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: `${BASE_PATH}/${id}`,
                });
            },
            updateFacility(id, facility) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'PUT',
                    url: `${BASE_PATH}/${id}`,
                    data: facility,
                });
            },

            /**
             * "Delete" (expire) a facility. The facility should only be allowed
             *   to be deleted in the event there are no storage locations as
             *   well as no evidence items associated with it.
             * @param  {number} id
             * @return {Promise<boolean>}
             */
            deleteFacility(id) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'DELETE',
                    url: `${BASE_PATH}/${id}`,
                });
            },
        },
    });
}

/**
 * Resources for evidence facilities. See the tech doc for details.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 * @see  https://docs.google.com/document/d/1v0IgMFaAoRL-lMWrRGYrgqScD79QN2whQ0U0WsMjLpQ/
 */
const resourceGetter = () => resource;
export default resourceGetter;

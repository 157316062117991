import { includes, filter } from 'lodash';
import { OffenseAttribute, AttributeTypeEnumType } from '@mark43/rms-api';

export const filterOffenseAttributes = ({
    offenseAttributes = [],
    offenseId,
    attributeTypes = [],
}: {
    offenseAttributes: OffenseAttribute[];
    offenseId: number;
    attributeTypes: AttributeTypeEnumType[];
}) => {
    return filter(offenseAttributes, (offenseAttribute) => {
        return (
            offenseAttribute.offenseId === offenseId &&
            includes(attributeTypes, offenseAttribute.attributeType)
        );
    });
};

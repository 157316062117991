import { createSelector } from 'reselect';
import { boxUiSelector } from '../../../../../legacy-redux/selectors/boxSelectors';

/**
 * Create a selector for state stored specific to a modal. For example, the
 *   error modal has these properties stored in state to display: title,
 *   paragraphs, list.
 * @param  {Object} context Box context.
 * @param  {string} key     Property name in state.
 * @return {Selector} Selector.
 */
export function createModalSelector(context, key) {
    return createSelector(boxUiSelector, (ui) => (key ? ui[context.name][key] : ui[context.name]));
}

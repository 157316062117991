import { StorageLocationTypeEnum } from '@mark43/evidence-api';

import { pick } from 'lodash';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const storageLocationAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'displayValue',
        validators: {
            requiredError: requiredString,
        },
    },
    'description',
    {
        key: 'expiredDateUtc',
        type: fieldTypeClientEnum.DATE_TIME,
    },
    {
        key: 'parentStorageLocationId',
        validators: {
            requiredError: requiredString,
        },
    },
    'isTemporary',
    'isHighValue',
]);

/**
 * Convert the given storage location model to a form model, for filling in the form with.
 * @param  {Object} [formModel]
 * @return {Object}
 */
export function convertStorageLocationDataStateToFormModel(storageLocationViewModel = {}) {
    return {
        ...pick(storageLocationViewModel, ['displayValue', 'description', 'expiredDateUtc']),
        isHighValue:
            storageLocationViewModel.isInHighValueContainer || storageLocationViewModel.isHighValue,
        isTemporary:
            storageLocationViewModel.storageLocationType === StorageLocationTypeEnum.TEMPORARY.name,
    };
}

/**
 * Convert the given storage form model to data state to be submitted to the
 *   server (not actually stored in data state).
 * @param  {Object} [formModel]
 * @return {Object}
 */
export function convertStorageLocationFormModelToDataState(
    formModel = {},
    storageLocationViewModel = {},
    isNew,
    newFacilityId,
    newParentStorageLocationIds
) {
    return {
        ...pick(formModel, ['displayValue', 'description', 'expiredDateUtc']),
        isHighValue: storageLocationViewModel.isInHighValueContainer
            ? storageLocationViewModel.isHighValue
            : formModel.isHighValue,
        id: storageLocationViewModel.id, // undefined if new
        facilityId: storageLocationViewModel.facilityId || newFacilityId,
        parentStorageLocationIds: isNew
            ? newParentStorageLocationIds
            : storageLocationViewModel.parentStorageLocationIds,
        storageLocationType: !!formModel.isTemporary
            ? StorageLocationTypeEnum.TEMPORARY.name
            : StorageLocationTypeEnum.PERMANENT.name,
        isInHighValueContainer: storageLocationViewModel.isInHighValueContainer,
    };
}

const storageLocationAdminForm = createFormModule({
    formName: formClientEnum.STORAGE_ADMIN,
    fieldViewModels: storageLocationAdminFormFieldViewModels,
    convertToFormModel: convertStorageLocationDataStateToFormModel,
    convertFromFormModel: convertStorageLocationFormModelToDataState,
});

/**
 * Module of the storage admin form, for creating/editing a single
 *   "sub-location".
 * @type {Object}
 */
export default storageLocationAdminForm;

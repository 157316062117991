import { get } from 'lodash';
import { createSelector } from 'reselect';
import {
    formatCommunityInfoTitle,
    formatFieldContactTitle,
} from '../../../reports/utils/reportsHelpers';

import { applicationSettingsSelector } from '../../../settings/state/data';

import { reportCardTitleByReportIdAndCardIdSelector } from '../../../report-definitions/state/data';
import { reportByIdSelector } from '../../../reports/state/data';
import { fieldContactByReportIdSelector } from '../data';

import reportCardEnum from '../../../../enums/universal/reportCardEnum';

const findFieldContactRecordNumberSelector = createSelector(
    applicationSettingsSelector,
    reportByIdSelector,
    (applicationSettings, reportById) => ({ fieldContact, reportId }) => {
        const report = reportById(reportId);
        const recordsWithoutRenEnabled = !!applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;
        const recordNumber = get(report, 'recordNumber') || '';
        const localId = get(fieldContact, 'localId') || '';
        return recordsWithoutRenEnabled ? recordNumber : localId;
    }
);

// should only be 1 per report

export const formatFieldContactTitleByReportIdSelector = createSelector(
    findFieldContactRecordNumberSelector,
    fieldContactByReportIdSelector,
    reportCardTitleByReportIdAndCardIdSelector,
    (findFieldContactRecordNumber, fieldContactByReportId, reportCardTitleByReportIdAndCardId) => (
        reportId
    ) => {
        const fieldContact = fieldContactByReportId(reportId);
        const recordNumber = findFieldContactRecordNumber({
            fieldContact,
            reportId,
        });
        const reportDefDisplayName = reportCardTitleByReportIdAndCardId(
            reportId,
            reportCardEnum.FIELD_CONTACT.id
        );
        return fieldContact ? formatFieldContactTitle({ recordNumber, reportDefDisplayName }) : '';
    }
);

export const formatCommunityInfoTitleByReportIdSelector = createSelector(
    findFieldContactRecordNumberSelector,
    fieldContactByReportIdSelector,
    reportCardTitleByReportIdAndCardIdSelector,
    (findFieldContactRecordNumber, fieldContactByReportId, reportCardTitleByReportIdAndCardId) => (
        reportId
    ) => {
        const fieldContact = fieldContactByReportId(reportId);
        const recordNumber = findFieldContactRecordNumber({
            fieldContact,
            reportId,
        });
        const reportDefDisplayName = reportCardTitleByReportIdAndCardId(
            reportId,
            reportCardEnum.COMMUNITY_INFORMATION.id
        );
        return fieldContact ? formatCommunityInfoTitle({ recordNumber, reportDefDisplayName }) : '';
    }
);

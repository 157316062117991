import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import { compact, get, omit } from 'lodash';
import { replaceLocationEntityLinksForEntityAndLinkTypes } from '~/client-common/core/domain/location-entity-links/state/data';
import warrantCardEnum from '~/client-common/core/enums/client/warrantCardEnum';
import { saveWarrant, warrantsSelector } from '~/client-common/core/domain/warrants/state/data';
import { updateWarrantAttributes } from '~/client-common/core/domain/warrant-attributes/state/data';
import {
    updateWarrantStatus,
    mostRecentWarrantStatusByWarrantIdSelector,
} from '~/client-common/core/domain/warrant-statuses/state/data';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import createCard from '../../../../reports/core/utils/createCard';

const baseCard = createCard({
    name: warrantCardEnum.WARRANT_DETAILS,
    baseSelector: (state) => state.ui.warrants.warrant.warrantDetailsCard,
    anchor: 'warrant-details-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        transitionToEditMode(populateWarrantDetailsForm) {
            return (dispatch) => {
                dispatch(populateWarrantDetailsForm());
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(currentWarrantId, warrantDetailsForm) {
            return (dispatch, getState) => {
                return dispatch(
                    warrantDetailsForm.actionCreators.submit(
                        (formModel) => {
                            const state = getState();
                            const {
                                warrant,
                                warrantStatus,
                                warrantAttributes,
                                locationEntityLink,
                            } = warrantDetailsForm.convertFromFormModel(formModel);
                            const lastWarrant = warrantsSelector(state)[currentWarrantId];
                            const updatedWarrant = {
                                ...lastWarrant,
                                ...warrant,
                            };
                            const warrantRen = updatedWarrant.reportingEventNumber;
                            const warrantToSave = omit(updatedWarrant, 'reportingEventNumber');
                            const lastWarrantStatus = mostRecentWarrantStatusByWarrantIdSelector(
                                state
                            )(currentWarrantId);
                            const updatedWarrantStatus =
                                get(lastWarrantStatus, 'warrantStatusAttrId') !==
                                get(warrantStatus, 'warrantStatusAttrId')
                                    ? {
                                          ...warrantStatus,
                                          id: get(lastWarrantStatus, 'id'), // for the whole life time of the warrant, the warrant status is the same
                                          warrantStatusDateUtc: nowUtc(), // if the status changed, update the status date
                                      }
                                    : lastWarrantStatus;
                            dispatch(baseCard.actionCreators.savingStart());
                            return dispatch(saveWarrant(warrantToSave, warrantRen)).then(
                                (warrant) =>
                                    Promise.all([
                                        warrant,
                                        dispatch(
                                            updateWarrantStatus(
                                                currentWarrantId,
                                                updatedWarrantStatus
                                            )
                                        ),
                                        dispatch(
                                            updateWarrantAttributes(
                                                currentWarrantId,
                                                warrantAttributes,
                                                'WARRANT_STATISTIC'
                                            )
                                        ),
                                        dispatch(
                                            replaceLocationEntityLinksForEntityAndLinkTypes({
                                                entityType: EntityTypeEnum.WARRANT.name,
                                                entityId: currentWarrantId,
                                                linkTypes: [LinkTypesEnum.LOCATION_OF_WARRANT],
                                                locationEntityLinks: compact([locationEntityLink]),
                                            })
                                        ),
                                    ])
                            );
                        },
                        { forceSubmit: true }
                    )
                )
                    .then(() => {
                        dispatch(baseCard.actionCreators.savingSuccess());
                        baseCard.scrollToTop();
                    })
                    .catch((errors) => {
                        let errorMessage;
                        // handles remote error
                        if (errors && errors.message) {
                            errorMessage = errors.message;
                            // handles legacy validation
                        } else if (Array.isArray(errors)) {
                            errorMessage = errors;
                            // handles modern validation with panel errors
                        } else if (errors.panelErrors.length > 0) {
                            errorMessage = errors.panelErrors;
                            // handles modern validation with no panel errors
                        } else if (errors.success === false) {
                            errorMessage = 'There was an error.';
                        }
                        dispatch(baseCard.actionCreators.savingFailure(errorMessage));
                        throw errorMessage;
                    });
            };
        },
    },
};

import { filter, map, some, times } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, defaultProps, lifecycle, setPropTypes, withHandlers } from 'recompose';
import classNames from 'classnames';

import { formDataIsEmpty } from '../../helpers/formHelpers';

// if the array of items is empty, add one item
function doAddItemOnEmpty({ addItemOnEmpty, items, addItem, minimumNumberOfItems }) {
    if (addItemOnEmpty && items.length === 0) {
        times(minimumNumberOfItems, (index) => addItem(index));
    }
}

// conditionally used fieldset that wraps around a single item and adds a new
// item when all items in the parent NItems are dirty and non-empty
const AddItemOnDirtyFieldset = withHandlers({
    onInputChange({ addItem, isOnlyEmptyItem = false }) {
        return (value) => {
            // conditions for adding a new item: the new input value is not
            // empty, in an item that is empty, and it is the only item that is
            // empty
            if (!formDataIsEmpty(value) && isOnlyEmptyItem) {
                addItem();
            }
        };
    },
})(({ onInputChange, children, Fieldset, className }) => {
    return (
        <Fieldset onInputChange={onInputChange} highlightOnFocus={false} className={className}>
            {children}
        </Fieldset>
    );
});

/**
 * Return an NItems component based on a component for each item.
 * @param  {function} nItemIsEmpty Helper function to use in the returned
 *   component.
 * @return {function} `Component => Component`.
 */
export const createNItems = (nItemIsEmpty) => (NItem) =>
    compose(
        setPropTypes({
            items: PropTypes.arrayOf(PropTypes.object),
            addText: PropTypes.string,
            addItemOnEmpty: PropTypes.bool,
            addItemOnDirty: PropTypes.bool,
            minimumNumberOfItems: PropTypes.number,
            hideAddButtonOnEmptyItem: PropTypes.bool,
            disabled: PropTypes.bool,
            children: PropTypes.func.isRequired,
        }),
        defaultProps({
            items: [],
            disabled: false,
            addItemOnEmpty: true,
            addItemOnDirty: false,
            hideAddButtonOnEmptyItem: false,
            minimumNumberOfItems: 1,
        }),
        lifecycle({
            componentDidMount() {
                doAddItemOnEmpty(this.props);
            },
            UNSAFE_componentWillReceiveProps(nextProps) {
                doAddItemOnEmpty(nextProps);
            },
        })
    )(
        ({
            items,
            addItem,
            disabled,
            addText,
            addItemOnDirty,
            addItemOnEmpty,
            hideAddButtonOnEmptyItem,
            minimumNumberOfItems,
            children,
            className,
            Fieldset,
            renderDeleteButton,
            automaticallyIncludeDeleteButton = true,
            addButton,
            styles,
            removeItem,
        }) => {
            // determine whether to include a button for adding a new item; the row
            // gets a bottom margin because the button has no bottom margin
            const addItemButton =
                addText &&
                !disabled &&
                ((!hideAddButtonOnEmptyItem && !addItemOnDirty) ||
                    // `items` is never empty, and this check is necessary to prevent a
                    // React "DOM was unexpectedly mutated" error when the add button is
                    // instantly unrendered then rendered, which happens on form reset
                    (items.length > 0 && !some(items, nItemIsEmpty))) &&
                addButton;

            const rows = map(items, (item, index) => {
                // don't show delete button when the NItems is disabled, or when there
                // less than the default number of items, unless addItemOnEmpty is false
                const showDeleteButton =
                    !disabled && (items.length > minimumNumberOfItems || !addItemOnEmpty);
                const deleteButton = showDeleteButton && renderDeleteButton(index);

                let row;
                if (automaticallyIncludeDeleteButton && children.length < 3) {
                    // if the boolean prop is true and the children function does not
                    // include the third argument for the delete button, then we render
                    // a delete button in the NItem assuming all the children fit in a
                    // single row
                    row = (
                        <NItem index={index}>
                            {children(item, index)}
                            {deleteButton}
                        </NItem>
                    );
                } else {
                    // if the boolean prop is false or the children function has the
                    // third argument for the delete button, then the consumer is
                    // rendering its own custom delete button wherever it wishes and we
                    // don't render it here.
                    // Also pass removeItem function as fourth argument for the
                    // consumer can use wherever they wish.
                    row = (
                        <NItem index={index}>
                            {children(item, index, deleteButton, removeItem(index))}
                        </NItem>
                    );
                }

                if (addItemOnDirty) {
                    // to automatically add an item when all items are dirty and
                    // non-empty, wrap each row in a fieldset with a change handler
                    row = (
                        <AddItemOnDirtyFieldset
                            item={item}
                            addItem={addItem}
                            isOnlyEmptyItem={
                                nItemIsEmpty(item) &&
                                (items.length === 1 || // faster check
                                    filter(items, nItemIsEmpty).length === 1)
                            }
                            Fieldset={Fieldset}
                            className={styles.fieldset}
                        >
                            {row}
                        </AddItemOnDirtyFieldset>
                    );
                }
                // The desired behavior is that when you press enter on "add item" the focus
                // jumps to the newly created inputs in that row. In the old code the add
                // button was always located after every single row. This means if you
                // hit "add item" the focus will be after all the rows, no matter how
                // many there are, so the next tabbable item would be after the NItems.
                // What this does is basically create (and hide) a button for every single
                // row in the NItems. That way when the "add item" button is clicked, that
                // button that you clicked is located after the current row but before the
                // new row. So the next tabblable element is that new row.
                return (
                    <div key={index}>
                        {row}
                        {index === items.length - 1 && addItemButton}
                    </div>
                );
            });

            return (
                <div className={classNames(styles.wrapper, className)}>
                    {rows}
                    {!rows.length && addItemButton}
                </div>
            );
        }
    );

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../core/components/Icon';
import testIds from '../../../core/testIds';
import Subheader from '../../core/components/Subheader';
import _Link from '../../core/components/links/Link';
import { AgencyProfileWithOriSelect } from '../../core/forms/components/selects/AgencyProfileSelect';
import { currentUserDepartmentIdSelector } from '../../core/current-user/state/ui';

import {
    complianceExportTypeDisplaySelector,
    disableComplianceExportFormControlsSelector,
} from '../state/ui';
import { NIBRS_COMPLIANCE_TYPE } from '../configuration';
import { useComplianceExportContext } from '../contexts/ComplianceExportContext';

const strings = componentStrings.compliance.ComplianceSubheader;

type ComplianceSubheaderProps = {
    location: { pathname: string };
};

const Link = styled(_Link)`
    margin-top: 5px;
    float: right;
`;

const ComplianceSubheader: React.FC<ComplianceSubheaderProps> = (props) => {
    const { location } = props;
    const {
        complianceExportType,
        currentAgencyId,
        setCurrentAgencyId,
    } = useComplianceExportContext();
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const complianceExportTypeDisplay = useSelector(complianceExportTypeDisplaySelector);
    const disableFormControls = useSelector(disableComplianceExportFormControlsSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);

    const historyUrl = location.pathname.replace(/\/(export|resubmission)/, '/history');
    const agencyProfileSelectPredicate = { departmentId: currentUserDepartmentId };

    const displayAgencySelectInSubheader =
        complianceExportType?.name === NIBRS_COMPLIANCE_TYPE.name;

    const legacyComplianceExportTitle =
        complianceExportType === undefined
            ? strings.title
            : joinTruthyValues(
                  [strings.title, complianceExportTypeDisplay(complianceExportType?.name)],
                  ' - '
              );

    const complianceExportTitle =
        applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED &&
        complianceExportType?.name === NIBRS_COMPLIANCE_TYPE.name
            ? strings.ibrsDashboard
            : legacyComplianceExportTitle;

    return (
        <Subheader title={complianceExportTitle}>
            {displayAgencySelectInSubheader && (
                <FeatureFlagged flag="RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED">
                    <div data-test-id={testIds.COMPLIANCE_DASHBOARD_AGENCY_PROFILE_SELECT}>
                        <AgencyProfileWithOriSelect
                            style={{ marginTop: '18px' }}
                            clearable={false}
                            disabled={disableFormControls}
                            predicate={agencyProfileSelectPredicate}
                            width={390}
                            placeholder="MARK43 Agency"
                            onChange={(value: number) => setCurrentAgencyId(value)}
                            value={currentAgencyId}
                        />
                    </div>
                </FeatureFlagged>
            )}
            <Link to={historyUrl}>
                <Button
                    className={buttonTypes.SECONDARY}
                    display="flex"
                    iconLeft={iconTypes.HISTORY}
                    testId={testIds.COMPLIANCE_DASHBOARD_HISTORY_BUTTON}
                >
                    {strings.labels.historyButton}
                </Button>
            </Link>
        </Subheader>
    );
};

export default ComplianceSubheader;

import React from 'react';
import classNames from 'classnames';

export default class GrayBar extends React.Component {
    render() {
        const classes = classNames('mark43-gray-bar', {
            'extra-top-margin': this.props.extraTopMargin,
        });
        return <div className={classes} />;
    }
}

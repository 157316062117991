import { PersonsSearchResultsDefaultEnum } from '@mark43/rms-api';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import pluralize from 'pluralize';

import { pure } from 'recompose';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_ASSOCIATED_ADDRESS_LABEL,
    DISPLAY_ONLY_BEHAVIOR_LABEL,
} from '~/client-common/core/enums/universal/fields';

import advancedSearchPersons from '../state/ui';
import { personsSearchResultsDefaultToElasticQuery } from '../state/forms/advancedSearchPersonsForm';
import FormSection from '../../../core/forms/components/FormSection';
import AdvancedSearchForm from '../../core/components/AdvancedSearchForm';
import BehaviorsFieldset from '../../core/components/BehaviorsFieldset';
import IdentifiersFieldset from '../../core/components/IdentifiersFieldset';
import IdentifyingMarksFieldset from '../../core/components/IdentifyingMarksFieldset';
import LocationsFieldset from '../../core/components/LocationsFieldset';
import PersonDetailsFieldset from '../../core/components/PersonDetailsFieldset';
import PhysicalAttributesFieldset from '../../core/components/PhysicalAttributesFieldset';
import { currentUserDepartmentProfileSelector } from '../../../core/current-user/state/ui';
import { SearchName } from '../../core/components/SearchName';

const strings = componentStrings.search.AdvancedSearchPersonsForm;

const PersonDetailsSection = pure(function PersonDetailsSection(props) {
    return (
        <FormSection>
            <PersonDetailsFieldset
                includeIsDead={true}
                includeHasPotentialActiveWarrant={true}
                highlightOnFocus={false}
                {...props}
            />
        </FormSection>
    );
});

const PhysicalCharacteristicsSection = pure(function PhysicalCharacteristicsSection({
    fuzzyMatchingEnabled,
}) {
    return (
        <FormSection title={strings.sectionTitles.physicalCharacteristics} collapsible={true}>
            {(collapsed) => (
                <div>
                    <PhysicalAttributesFieldset
                        path="physicalCharacteristics.physicalAttributes"
                        collapsed={collapsed}
                        showHeader={false}
                    />
                    <IdentifyingMarksFieldset
                        path="physicalCharacteristics.identifyingMarks"
                        visible={!collapsed}
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    />
                </div>
            )}
        </FormSection>
    );
});

const BehaviorsSection = pure(function BehaviorsSection({
    gangTrackingEnabled,
    behaviorDisplayName,
}) {
    return (
        <FormSection title={behaviorDisplayName}>
            <BehaviorsFieldset showHeader={false} gangTrackingEnabled={gangTrackingEnabled} />
        </FormSection>
    );
});

const IdentifiersSection = pure(function IdentifiersSection({ fuzzyMatchingEnabled }) {
    return (
        <FormSection title={strings.sectionTitles.identifiers} collapsible={true}>
            {(collapsed) => (
                <IdentifiersFieldset
                    displayMniSearchField={true}
                    mniDisplayNItems={true}
                    collapsed={collapsed}
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    showHeader={false}
                />
            )}
        </FormSection>
    );
});

const LocationsSection = pure(function LocationsSection({ fuzzyMatchingEnabled }) {
    const addAnotherAddressLabel = useFields(
        DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_ASSOCIATED_ADDRESS_LABEL
    )[DISPLAY_ONLY_ADVANCE_SEARCH_ADD_ANOTHER_ASSOCIATED_ADDRESS_LABEL];
    return (
        <FormSection title={strings.sectionTitles.locations}>
            <LocationsFieldset
                addText={addAnotherAddressLabel}
                title={strings.fieldsetTitles.locations}
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                includeCrossStreets={true}
                includeSubdivisionAttrIds={true}
                includeCategoryAttrIds={true}
            />
        </FormSection>
    );
});

function AdvancedSearchPersonsForm({
    fuzzyMatchingEnabled,
    formIsEmpty,
    onSubmit,
    onReset,
    applicationSettings,
    currentUserDepartmentProfile,
    formChange,
    currentSavedSearch,
}) {
    const defaultsSetRef = useRef(false);
    const { RMS_GANG_TRACKING_ENABLED } = applicationSettings;
    const { personsSearchResultsDefault } = currentUserDepartmentProfile;
    const includePersonsFilterCheckboxes =
        personsSearchResultsDefault !==
        PersonsSearchResultsDefaultEnum.SEARCH_OPTION_NOT_DISPLAYED.name;

    // Set default values for juvenile filter checkboxes.
    useEffect(() => {
        if (formIsEmpty && includePersonsFilterCheckboxes && !defaultsSetRef.current) {
            const formModel = personsSearchResultsDefaultToElasticQuery(
                personsSearchResultsDefault
            );
            formChange(formModel, undefined, { applicationSettings, currentUserDepartmentProfile });
            defaultsSetRef.current = true;
        }
    }, [
        formIsEmpty,
        includePersonsFilterCheckboxes,
        formChange,
        applicationSettings,
        currentUserDepartmentProfile,
        personsSearchResultsDefault,
    ]);

    const behaviorDisplayName = pluralize(
        useFields(DISPLAY_ONLY_BEHAVIOR_LABEL)[DISPLAY_ONLY_BEHAVIOR_LABEL]
    );

    return (
        <AdvancedSearchForm
            {...advancedSearchPersons.form}
            includeFuzzyMatchingCheckbox={true}
            fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            onSubmit={onSubmit}
            onReset={onReset}
            formIsEmpty={formIsEmpty}
            searchText={strings.search}
            includePersonsFilterCheckboxes={includePersonsFilterCheckboxes}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={true} />
            <PersonDetailsSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <hr />
            <PhysicalCharacteristicsSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <hr />
            <BehaviorsSection
                gangTrackingEnabled={RMS_GANG_TRACKING_ENABLED}
                behaviorDisplayName={behaviorDisplayName}
            />
            <hr />
            <IdentifiersSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <hr />
            <LocationsSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
        </AdvancedSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    fuzzyMatchingEnabled: advancedSearchPersons.form.selectors.buildFormModelSelectorByPath(
        'fuzzyMatchingEnabled'
    ),
    formIsEmpty: advancedSearchPersons.form.selectors.formIsEmptySelector,
    applicationSettings: applicationSettingsSelector,
    currentUserDepartmentProfile: currentUserDepartmentProfileSelector,
    currentSavedSearch: advancedSearchPersons.selectors.currentSavedSearchSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () =>
        dispatch(
            advancedSearchPersons.form.actionCreators.submit((formData) =>
                dispatch(
                    advancedSearchPersons.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                )
            )
        ),
    onReset: () => {
        dispatch(advancedSearchPersons.form.actionCreators.reset());
        dispatch(advancedSearchPersons.actionCreators.resetState());
        dispatch(advancedSearchPersons.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(advancedSearchPersons.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
    formChange: (...args) => dispatch(advancedSearchPersons.form.actionCreators.change(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearchPersonsForm);

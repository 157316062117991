/**
 * Component for path `notifications/settings/.
 * As we create side panels when app first loads, and show/hide them
 * as needed, the actual notifications settings side panel is not
 * created in this component. This component dispatches the open panel
 * event when first mounts, and then check if the panel is open or not
 * to determine if the panel is closed and redirect to another page.
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { notificationSettingsPanelSelector, openNotificationSettingsForm } from '../state/ui';

export const NotificationSettings: React.FC<RouteComponentProps<never, never>> = ({ router }) => {
    const dispatch = useDispatch();
    const panel = useSelector(notificationSettingsPanelSelector);

    // State to indicate the side panel is open or not.
    // Used to compare with the store to determine the transition
    // from open to close.
    // Default to false because side panel is closed when leaving the previous page
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    useEffect(() => {
        dispatch(openNotificationSettingsForm());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // The panel transits from open to closed
        if (!panel.isOpen && isPanelOpen) {
            // Redirect to the notifications landing page
            router.push('notifications');
        }

        setIsPanelOpen(panel.isOpen);
    }, [panel, isPanelOpen, router]);

    return <div />;
};

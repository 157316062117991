import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider, Flex, VStack, InlineBanner, cssVar } from 'arc';
import { Form, lifecycleOptions, formEvents } from 'markformythree';
import {
    PhotoLineupView,
    RefContextEnum,
    FileUploadResponse,
    HydratedImage,
} from '@mark43/rms-api';
import { omit } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../core/overlays/components/Modal';
import { MFTText } from '../../../core/forms/components/Text';
import { MFTNItems } from '../../../core/forms/components/NItems';
import { fullFormWidth } from '../../../../legacy-redux/configs/adminConfig';
import {
    manualLineupFormConfiguration,
    validationSchema,
    ManualNItemsDataShape,
    ManualLineupRowDataShape,
} from '../state/forms/manualUploadLineupForm';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../core/validation/yupMftValidation';
import formsRegistry from '../../../../core/formsRegistry';
import Row from '../../../core/components/Row';
import { convertBytesToGigaBytes } from '../../../attachments/files/helpers';
import { LineupSlot, LineupPhotoWidth } from './LineupPhotoRow';

const overlayId = overlayIdEnum.MANUAL_LINEUP_MODAL;
const strings = componentStrings.cases.casePhotoLineups.ComposeLineup;
const MAX_IMG_SIZE_GB = 3;

const StyledErrorBanner = styled(InlineBanner)`
    margin-bottom: ${cssVar('arc.space.4')};
`;

const ManualLineupRow: React.FC<{ imageURL: string }> = ({ imageURL }) => {
    const firstName = useFields(fields.PHOTO_LINEUP_SLOT_FIRST_NAME).PHOTO_LINEUP_SLOT_FIRST_NAME;
    const lastName = useFields(fields.PHOTO_LINEUP_SLOT_LAST_NAME).PHOTO_LINEUP_SLOT_LAST_NAME;
    return (
        <Flex w="full" alignItems="center">
            <LineupSlot photoPath={imageURL} photoWidth={LineupPhotoWidth.COMPOSE_PAGE} />
            <VStack w="full" marginLeft="4">
                <Row>
                    <MFTText path="firstName" label={firstName} width={fullFormWidth * 0.9} />
                </Row>
                <Row>
                    <MFTText path="lastName" label={lastName} width={fullFormWidth * 0.9} />
                </Row>
            </VStack>
        </Flex>
    );
};

type ManualUploadLineupModalProps = {
    files: FileUploadResponse[];
    setLineup: React.Dispatch<React.SetStateAction<PhotoLineupView | undefined>>;
};

const ManualUploadLineupModal: React.FC<ManualUploadLineupModalProps> = ({ files, setLineup }) => {
    const [imageErrorCount, setImageErrorCount] = useState(0);
    const [fileSizeErrorCount, setFileSizeErrorCount] = useState(0);
    const { getForm } = useFormGetter();

    // set the form with the initial values from the uploadResponse
    useEffect(() => {
        let currImageErrorCount = 0;
        let currFileSizeErrorCount = 0;

        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_PHOTO_LINEUPS_PHOTO_UPLOAD.name,
            undefined,
            (form) => {
                const formModelRow = files.map((file) => {
                    if (file.image) {
                        if (
                            convertBytesToGigaBytes(file.file.contentLength || 0) < MAX_IMG_SIZE_GB
                        ) {
                            return {
                                firstName: undefined,
                                lastName: undefined,
                                imageId: file.image.id,
                                imageURL: file.file.fileWebServerPath,
                            };
                        } else {
                            currFileSizeErrorCount += 1;
                            setFileSizeErrorCount(currFileSizeErrorCount);
                            return;
                        }
                    } else {
                        currImageErrorCount += 1;
                        setImageErrorCount(currImageErrorCount);
                        return;
                    }
                });
                form.set('manualLineupRow', formModelRow);
            }
        );
    }, [files]);

    const validationHandler: Form['props']['onValidate'] = ({ formState, eventType }) => {
        const $form = formState.ui.$form;

        return validationSchema
            .validate(formState.model, { abortEarly: false })
            .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
            .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
    };

    const onSave = () => {
        // if there were the same amount of errors as files length, then all files have errors and should not field validate on save
        if (imageErrorCount + fileSizeErrorCount === files.length) {
            setImageErrorCount(0);
            setFileSizeErrorCount(0);
            return;
        }
        const form = getForm(RefContextEnum.FORM_PHOTO_LINEUPS_PHOTO_UPLOAD.name);
        // convert the uploadResponse to HydratedImage for PhotoLineupView type
        const hydratedImages: HydratedImage[] = [];
        files.forEach((file) => {
            if (file.image) {
                const hydratedImage = {
                    ...file.image,
                    originalFile: file.file,
                };
                hydratedImages.push(hydratedImage);
            }
        });

        if (form) {
            return form.submit().then(() => {
                const formModel = form.get();
                // get data from manualUploadLineupForm
                const formData: ManualLineupRowDataShape = formModel.manualLineupRow;
                const manualSlots: Omit<ManualLineupRowDataShape, 'imageURL'>[] = [];

                // create object without imageURL
                if (formData) {
                    formData.forEach((data) => {
                        const manualSlot = omit(data, ['imageURL']);
                        manualSlots.push(manualSlot);
                    });
                }

                // update state with callback function, set photoLineupSlotsViews with combine all slots
                setLineup(
                    (prevState) =>
                        (({
                            ...prevState,
                            photoLineupSlotViews: [
                                ...(prevState ? prevState.photoLineupSlotViews : []),
                                ...(manualSlots || []),
                            ],
                            images: [...(prevState ? prevState.images : []), ...hydratedImages],
                        } as unknown) as PhotoLineupView)
                );
                setImageErrorCount(0);
                setFileSizeErrorCount(0);
            });
        } else {
            return Promise.reject(new Error('Cannot Find Form'));
        }
    };

    return (
        <Modal
            id={overlayId}
            title={strings.uploadImages}
            okText={strings.saveText}
            cancelText={strings.cancelText}
            onSave={onSave}
        >
            {imageErrorCount > 0 && (
                <StyledErrorBanner
                    status="error"
                    title=""
                    description={strings.imageError(imageErrorCount)}
                />
            )}
            {fileSizeErrorCount > 0 && (
                <StyledErrorBanner
                    status="error"
                    title=""
                    description={strings.fileSizeError(fileSizeErrorCount)}
                />
            )}
            <Form<typeof manualLineupFormConfiguration>
                configuration={manualLineupFormConfiguration}
                name={RefContextEnum.FORM_PHOTO_LINEUPS_PHOTO_UPLOAD.name}
                lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                onValidate={validationHandler}
                validationEvents={[
                    { eventType: formEvents.FORM_SUBMIT },
                    { eventType: formEvents.INPUT_BLUR },
                ]}
                render={() => (
                    <VStack height="300px">
                        <MFTNItems<ManualNItemsDataShape>
                            path="manualLineupRow"
                            childFieldKeys={['firstName', 'lastName', 'imageId', 'imageURL']}
                            renderRemoveButton={undefined}
                            renderAddButton={undefined}
                            render={({ item }) => {
                                if (item) {
                                    return (
                                        <div style={{ paddingBottom: 12 }}>
                                            <ManualLineupRow imageURL={item.imageURL || ''} />
                                            <Divider />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            }}
                        />
                    </VStack>
                )}
            />
        </Modal>
    );
};

export default ManualUploadLineupModal;

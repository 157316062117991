import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';

import loadingStateEnum from './constants/loadingStateEnum';

const OrganizationQuickAdd = () => {
    const [isOrganizationsStale, setIsOrganizationsStale] = useState(true);
    const [organizationsLoadingState, setOrganizationsLoadingState] = useState(
        loadingStateEnum.READY
    );

    const resetState = useCallback(() => {
        setIsOrganizationsStale(true);
        setOrganizationsLoadingState(loadingStateEnum.READY);
    }, [setIsOrganizationsStale, setOrganizationsLoadingState]);

    return {
        isOrganizationsStale,
        setIsOrganizationsStale,
        organizationsLoadingState,
        setOrganizationsLoadingState,
        resetState,
    };
};

export const OrganizationQuickAddContext = createContainer(OrganizationQuickAdd);

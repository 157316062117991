import React, { createContext, useContext } from 'react';

type LineupContextProps = {
    selectedLineup: {
        title: string;
        name: string;
        lineupId: number;
        canExport: boolean;
        isExported: boolean;
    };
    numPhotoLineups: number;
    setSelectedLineup: React.Dispatch<
        React.SetStateAction<{
            title: string;
            name: string;
            lineupId: number;
            canExport: boolean;
            isExported: boolean;
        }>
    >;
    setNumPhotoLineups: React.Dispatch<React.SetStateAction<number>>;
};

function createLineupContext<A extends LineupContextProps | null>() {
    const LineupContext = createContext<A | undefined>(undefined);
    const useLineupContext = () => {
        const context = useContext(LineupContext);
        if (context === undefined) {
            throw new Error('useLineupContext must be inside a Provider with a value');
        }
        return context;
    };
    return {
        useLineupContext,
        LineupContextProvider: LineupContext.Provider,
    } as const;
}

export const {
    useLineupContext,
    LineupContextProvider,
} = createLineupContext<LineupContextProps>();

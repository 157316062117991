import React from 'react';
import { map, compact } from 'lodash';
import testIds from '../../../../../core/testIds';
import EntityProfileImage from './EntityProfileImage';

export default function EntityProfileSidebar({ entityType, entityId, children }) {
    const mappedChildren = map(compact(React.Children.toArray(children)), (child) =>
        React.cloneElement(child, { entityType, entityId })
    );

    return (
        <div className="entity-profile-sidebar" data-test-id={testIds.ENTITY_SIDEBAR}>
            <EntityProfileImage entityType={entityType} />
            {mappedChildren}
        </div>
    );
}

import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { get, includes, split, map, some, find, first, every, isEmpty, reduce } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import invariant from 'invariant';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import { DragDropContext } from 'react-beautiful-dnd';
import { EntityTypeEnum } from '@mark43/rms-api';
import { folderContentViewWhereSelector } from '~/client-common/core/domain/folder-content-views/state/data';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { useResource } from '~/client-common/core/hooks/useResource';
import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import { openConfirmationBar } from '../../../core/confirmation-bar/state/ui';
import { DraggableTypes, DROPPABLE_ID_SEPARATOR } from '../utils/dragAndDropHelpers';
import { moveFoldersIntoFolders, moveAttachmentsIntoFolders } from '../state/data/folders';
import { usePhotoLineupFieldName } from '../../case-photo-lineups/hooks/usePhotoLineupFieldName';
import testIds from '../../../../core/testIds';

import { currentCaseSelector } from '../state/ui';
import { INDETERMINATE } from '../../../core/forms/components/checkboxes/Checkbox';
import {
    SearchFilterContextProvider,
    CurrentFolderIdContext,
    DraggingIdContext,
    SelectedRowsContext,
    KebabMenuContextProvider,
} from '../contexts';
import { LineupContextProvider } from '../../case-photo-lineups/state/ui';
import casePhotoLineupResource from '../resources/casePhotoLineupResource';
import { IconButton } from '../../../core/components/IconButton';
import Link from '../../../core/components/links/Link';
import LineupDisableExportTooltip from '../../case-photo-lineups/components/LineupDisableExportTooltip';
import CaseSidebar from './CaseSidebar';
import CaseHeader from './CaseHeader';
import { EntityTypeIdContext } from './CreateFolderModal';
import { DragStatuses } from './drag-and-drop';

const CaseContentWrapper = styled.div`
    display: flex;
    min-height: 100%;
`;

const CaseContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
    background-color: ${(props) => props.theme.colors.white};
    width: ${(props) => props.theme.widths.caseContent}px;
`;

const NoSidebarCaseWrapper = styled.div`
    position: relative;
    margin: 0 50px;
`;

const ResizeBar = styled.div`
    display: block;
    cursor: col-resize;
    width: 2px;

    &:hover {
        background-color: ${(props) => props.theme.colors.lightGrey};
    }
`;

const DetailIconButton = styled(IconButton)`
    margin: 5px;
`;

const CASE_CONTAINER_WIDTH = 970;
const INITIAL_SIDEBAR_WIDTH = 166;
const MAX_SIDEBAR_WIDTH = (CASE_CONTAINER_WIDTH * 2) / 5;
const strings = componentStrings.cases.allCases.AllCases;

/**
 * The container for "a single case". This component is a route component. As such
 *   react-router will also pass to it a child component. We use which child component
 *   is passed to dictate behaviour (whether or not to show a sidebar, and the
 *   class name of our container). This is necessar largely due to the fact
 *   that CaseExports/CaseHistory don't live inside the standard single case container.
 *   CaseExports should probably be an overlay... but that's another matter :).
 */
function CaseContainer({ currentCase, params, location, children, folderContentViewWhere }) {
    const [initialCursorPosition, setInitialCursorPosition] = useState(null);
    const [initialSidebarSize, setInitialSidebarSize] = useState(null);
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [parentFolderIdOfCurrentFolder, setParentFolderIdOfCurrentFolder] = useState(null);
    const [folderIdsToDelete, setFolderIdsToDelete] = useState([]);
    const [selectedContentItemsToDelete, setSelectedContentItemsToDelete] = useState([]);
    const [entityTypeId, setEntityTypeId] = useState(null);
    const [draggingId, setDraggingId] = useState(null);
    const [dragStatus, setDragStatus] = useState(DragStatuses.HAS_NOT_STARTED);
    const [selectedRows, setSelectedRows] = useState([]);
    const [lastParams, setLastParams] = useState({});
    const [lastPathname, setLastPathname] = useState('');
    const [selectedLineup, setSelectedLineup] = useState({
        title: '',
        name: '',
        lineupId: 0,
        canExport: false,
        isExported: false,
    });
    const [numPhotoLineups, setNumPhotoLineups] = useState(0);

    const caseSidebarRef = useRef();
    const caseContentRef = useRef();
    const dispatch = useDispatch();

    const lineupFieldName = usePhotoLineupFieldName();

    /**
     * Set selectedItems to [] on route changes
     * unless we are only changing from one note to another in the same folder
     */
    useEffect(() => {
        if (lastPathname !== location.pathname) {
            if (isEmpty(lastParams) && !isEmpty(params)) {
                setLastParams(params);
                setSelectedRows([]);
            } else {
                if (params?.caseId !== lastParams?.caseId) {
                    setLastParams(params);
                    setSelectedRows([]);
                } else if (params?.folderId !== lastParams?.folderId) {
                    setLastParams(params);
                    setSelectedRows([]);
                } else if (params?.noteId !== lastParams?.noteId) {
                    setLastParams(params);
                } else if (
                    !(includes(lastPathname, 'notes') && includes(location.pathname, 'notes')) ||
                    !(
                        includes(lastPathname, 'attachments') &&
                        includes(location.pathname, 'attachments')
                    )
                ) {
                    setSelectedRows([]);
                    setLastParams(params);
                }
            }
            setLastPathname(location.pathname);
        }
    }, [lastParams, lastPathname, location.pathname, params]);

    const isRowSelected = useCallback((id) => !!find(selectedRows, (row) => row.rowId === id), [
        selectedRows,
    ]);

    const areAllElementsSelected = useCallback(
        ({ elements, idKey }) => {
            if (
                some(elements, (element) =>
                    find(selectedRows, (row) =>
                        row.isFolder ? row.rowId === element.id : row.rowId === element[idKey]
                    )
                )
            ) {
                if (
                    every(elements, (element) =>
                        find(selectedRows, (row) =>
                            row.isFolder ? row.rowId === element.id : row.rowId === element[idKey]
                        )
                    )
                ) {
                    return true;
                } else {
                    return INDETERMINATE;
                }
            } else {
                return false;
            }
        },
        [selectedRows]
    );

    const handleRowSelect = useCallback(
        (rowId, isSelected, isFolder, isEditable, entityType) => {
            let currentselectedIds = isEmpty(selectedRows) ? [] : [...selectedRows];

            if (isSelected) {
                currentselectedIds = currentselectedIds.filter((row) => row.rowId !== rowId);
            } else {
                currentselectedIds.push({ rowId, isFolder, isEditable, entityType });
            }
            setSelectedRows(currentselectedIds);
        },
        [selectedRows, setSelectedRows]
    );

    const selectAllClick = ({ elements = [], idKey = 'id', folders = [], entityType }) => {
        // make a list of unselected elements
        const unselectedElements = reduce(
            elements,
            (accumulator, element) => {
                if (!find(selectedRows, (row) => row.rowId === element[idKey])) {
                    accumulator.push({
                        rowId: element[idKey],
                        isFolder: false,
                        isEditable: true,
                        entityType,
                    });
                }
                return accumulator;
            },
            []
        );
        // and a list of unselected folders
        const unselectedFolders = reduce(
            folders,
            (accumulator, folder) => {
                if (!find(selectedRows, (row) => row.rowId === folder.id)) {
                    accumulator.push({
                        rowId: folder.id,
                        isFolder: true,
                        isEditable: true,
                        entityType,
                    });
                }
                return accumulator;
            },
            []
        );
        const unselectedRows = [...unselectedElements, ...unselectedFolders];
        // if every row is selected deselect them all
        if (unselectedRows.length === 0) {
            setSelectedRows([]);
        } else {
            // otherwise add unselectedRows to selectedRows
            const newSelectedRows = [...unselectedRows, ...selectedRows];
            setSelectedRows(newSelectedRows);
        }
    };

    const resizeWidth = useCallback(
        (e) => {
            const caseSidebarWidth = initialSidebarSize + (e.clientX - initialCursorPosition);
            const caseContentWidth = CASE_CONTAINER_WIDTH - caseSidebarWidth;

            if (
                e.clientX !== 0 &&
                caseSidebarWidth >= INITIAL_SIDEBAR_WIDTH &&
                caseSidebarWidth <= MAX_SIDEBAR_WIDTH
            ) {
                caseSidebarRef.current.style.width = `${caseSidebarWidth}px`;
                caseContentRef.current.style.width = `${caseContentWidth}px`;
            }
        },
        [initialSidebarSize, initialCursorPosition]
    );

    const resizeWidthStart = (e) => {
        setInitialCursorPosition(e.clientX);
        setInitialSidebarSize(caseSidebarRef.current.offsetWidth);
    };

    const isExports = includes(location.pathname, 'exports');
    const isHistory = includes(location.pathname, 'history');
    const isLineup =
        includes(location.pathname, 'compose') ||
        (includes(location.pathname, 'lineups') && isExports);
    const isLineupDetails = location.pathname.match(/lineups\/[0-9]*$/);
    let content = null;

    const handleDragEnd = useCallback(
        (dragResult) => {
            const { destination } = dragResult;

            if (!destination?.droppableId) {
                setDraggingId(undefined);
                setDragStatus(DragStatuses.COMPLETE);
                return;
            }

            const entityType = includes(location.pathname, 'attachments')
                ? EntityTypeEnum.ATTACHMENT.name
                : EntityTypeEnum.CASE_NOTE.name;

            const destinationParts = split(destination?.droppableId, DROPPABLE_ID_SEPARATOR);
            // If you hit this invariant, refer to `draggableAttachmentHelpers` file for more info.
            // We should be using those helper methods for creating droppable ids
            invariant(
                // We should expect this format identifier-folderId-folderName for all droppable folder ids
                destinationParts.length === 3,
                'Invalid Destination Droppable Id Used'
            );

            // We are viewing a folder content view for a specific folder when this is defined.
            // If it is not defined, we are at the case level (ie: Ophan level).
            const destinationFolderId = parseInt(destinationParts[1]);
            const destinationFolderName = destinationParts[2];
            const sourceFolderId = parseInt(params.folderId);

            const destinationFolderContentView = first(
                folderContentViewWhere({
                    folderId: destinationFolderId,
                })
            );

            const destinationFolderAttachments =
                destinationFolderContentView && destinationFolderContentView.hydratedFolderContent
                    ? destinationFolderContentView.hydratedFolderContent.hydratedAttachments
                    : [];

            const destinationSubFolders = destinationFolderContentView
                ? destinationFolderContentView.directSubFolders
                : [];

            const destinationDraggableType = includes(destinationParts[0].toLowerCase(), 'folder')
                ? DraggableTypes.FOLDER
                : null;

            if (!destinationDraggableType) {
                // Note: Could show a confirmation bar if user tries to drag something inside
                // something that is not a folder. (Product Question);
                return;
            }

            // We don't want to attempt to move the same folder to itself.
            // Scenario is when a user picks a top level folder from the attachments tab and tries to
            // drag itself via the case sidebar.
            const selectedFolderIdsToMove = selectedRows
                .filter((item) => item.isFolder && item.rowId !== destinationFolderId)
                .map((item) => item.rowId);

            const selectedContentIdsToMove = selectedRows
                .filter((item) => !item.isFolder)
                .map((item) => item.rowId);

            // Filter out contentIds that already belong in the destinationId
            const contentIdsToMove = selectedContentIdsToMove.filter(
                (contentId) =>
                    !includes(map(destinationFolderAttachments, 'attachmentId'), contentId)
            );

            const folderIdsToMove = selectedFolderIdsToMove.filter(
                (folderId) => !includes(map(destinationSubFolders, 'id'), folderId)
            );

            // In the odd chance there is nothing to move and this thing is triggered,
            // just exit out.
            if (isEmpty([...folderIdsToMove, ...contentIdsToMove])) {
                setDraggingId(undefined);
                setDragStatus(DragStatuses.COMPLETE);
                return;
            }

            // We are trying to move only folders into folders.
            if (
                selectedFolderIdsToMove.length > 0 &&
                selectedContentIdsToMove.length === 0 &&
                destinationDraggableType === DraggableTypes.FOLDER
            ) {
                dispatch(moveFoldersIntoFolders(destinationFolderId, selectedFolderIdsToMove))
                    .then(() => {
                        setDraggingId(undefined);
                        setDragStatus(DragStatuses.COMPLETE);
                        setSelectedRows([]);
                        dispatch(
                            openConfirmationBar({
                                message: strings.dropFolderSuccessfulMessage(
                                    selectedRows.length,
                                    destinationFolderName
                                ),
                                isSuccess: true,
                            })
                        );
                    })
                    .catch(({ message }) => {
                        setDraggingId(undefined);
                        setDragStatus(DragStatuses.COMPLETE);
                        setSelectedRows([]);
                        dispatch(
                            openConfirmationBar({
                                message,
                            })
                        );
                    });
            }

            // We are trying to move only some attachments into folders
            if (
                selectedContentIdsToMove.length > 0 &&
                selectedFolderIdsToMove.length === 0 &&
                destinationDraggableType === DraggableTypes.FOLDER
            ) {
                dispatch(
                    moveAttachmentsIntoFolders({
                        contentIds: selectedContentIdsToMove,
                        destinationFolderId,
                        entityType,
                        sourceFolderId,
                    })
                )
                    .then(() => {
                        setDraggingId(undefined);
                        setDragStatus(DragStatuses.COMPLETE);
                        setSelectedRows([]);
                        dispatch(
                            openConfirmationBar({
                                message: strings.dropFileSuccessfulMessage(
                                    selectedRows.length,
                                    destinationFolderName
                                ),
                                isSuccess: true,
                            })
                        );
                    })
                    .catch(({ message }) => {
                        setDraggingId(undefined);
                        setDragStatus(DragStatuses.COMPLETE);
                        setSelectedRows([]);
                        dispatch(
                            openConfirmationBar({
                                message,
                            })
                        );
                    });
            }

            // Moving both files and folders into a folder
            if (
                selectedContentIdsToMove.length > 0 &&
                selectedFolderIdsToMove.length > 0 &&
                destinationDraggableType === DraggableTypes.FOLDER
            ) {
                dispatch(moveFoldersIntoFolders(destinationFolderId, selectedFolderIdsToMove))
                    .then(() => {
                        return dispatch(
                            moveAttachmentsIntoFolders({
                                contentIds: selectedContentIdsToMove,
                                destinationFolderId,
                                entityType,
                                sourceFolderId,
                            })
                        );
                    })
                    .then(() => {
                        setDraggingId(undefined);
                        setDragStatus(DragStatuses.COMPLETE);
                        setSelectedRows([]);
                        dispatch(
                            openConfirmationBar({
                                message: strings.dropFolderAndFileDropSuccessMessage(
                                    selectedContentIdsToMove.length,
                                    selectedFolderIdsToMove.length,
                                    destinationFolderName
                                ),
                                isSuccess: true,
                            })
                        );
                    })
                    .catch(({ message }) => {
                        setDraggingId(undefined);
                        setDragStatus(DragStatuses.COMPLETE);
                        setSelectedRows([]);
                        dispatch(
                            openConfirmationBar({
                                message,
                            })
                        );
                    });
            }
        },
        [location.pathname, params.folderId, folderContentViewWhere, selectedRows, dispatch]
    );

    const handleDragStart = useCallback((props) => {
        setDraggingId(parseInt(props.draggableId));
        setDragStatus('IN_PROGRESS');
    }, []);

    const loadLineups = useCallback(
        () => casePhotoLineupResource.getPhotoLineupsForCase(params?.caseId),
        [params?.caseId]
    );

    const onResourceSuccess = useCallback(
        (photoLineupViews) => {
            setNumPhotoLineups(photoLineupViews.length);
        },
        [setNumPhotoLineups]
    );

    useResource(loadLineups, onResourceSuccess);

    const getLineupTitle = () => {
        if (isExports) {
            return componentStrings.cases.casePhotoLineups.ExportLineup.header(
                lineupFieldName,
                selectedLineup.title,
                selectedLineup.name
            );
        }
        if (includes(location.pathname, 'compose')) {
            return componentStrings.cases.casePhotoLineups.ComposeLineup.header(
                lineupFieldName,
                selectedLineup.title,
                selectedLineup.name
            );
        }
        if (isLineupDetails) {
            return componentStrings.cases.casePhotoLineups.DetailsLineup.header(
                lineupFieldName,
                selectedLineup.title,
                selectedLineup.name
            );
        }
    };

    if (isExports || isLineup || isLineupDetails) {
        content = children;
    } else if (isHistory) {
        content = <NoSidebarCaseWrapper>{children}</NoSidebarCaseWrapper>;
    } else {
        content = (
            <CurrentFolderIdContext.Provider
                value={{
                    currentFolderId,
                    setCurrentFolderId,
                    parentFolderIdOfCurrentFolder,
                    setParentFolderIdOfCurrentFolder,
                    folderIdsToDelete,
                    setFolderIdsToDelete,
                    selectedContentItemsToDelete,
                    setSelectedContentItemsToDelete,
                }}
            >
                <EntityTypeIdContext.Provider value={{ entityTypeId, setEntityTypeId }}>
                    <DraggingIdContext.Provider
                        value={{ draggingId, setDraggingId, dragStatus, setDragStatus }}
                    >
                        <SelectedRowsContext.Provider
                            value={{
                                selectedRows,
                                setSelectedRows,
                                areAllElementsSelected,
                                handleRowSelect,
                                isRowSelected,
                                selectAllClick,
                            }}
                        >
                            <KebabMenuContextProvider>
                                <SearchFilterContextProvider>
                                    <DragDropContext
                                        onDragEnd={handleDragEnd}
                                        onDragStart={handleDragStart}
                                    >
                                        <CaseContentWrapper>
                                            <CaseSidebar
                                                caseSidebarRef={caseSidebarRef}
                                                caseId={params.caseId}
                                            />
                                            <ResizeBar
                                                draggable="true"
                                                onDragStart={resizeWidthStart}
                                                onDrag={resizeWidth}
                                            />
                                            <CaseContentContainer
                                                ref={caseContentRef}
                                                className="case-content-container"
                                            >
                                                {children}
                                            </CaseContentContainer>
                                        </CaseContentWrapper>
                                    </DragDropContext>
                                </SearchFilterContextProvider>
                            </KebabMenuContextProvider>
                        </SelectedRowsContext.Provider>
                    </DraggingIdContext.Provider>
                </EntityTypeIdContext.Provider>
            </CurrentFolderIdContext.Provider>
        );
    }

    const isLegacyCase = get(currentCase, 'isLegacy');
    const bannerComponent = isLegacyCase ? <div className="legacy-case-banner" /> : null;
    const renderLineupDetailButtons = (
        <>
            {/* If exported do not show edit icon */}
            {!selectedLineup.isExported && (
                <Link
                    to={`/cases/${params.caseId}/lineups/${params.photoLineupId}/compose`}
                    testId={testIds.PHOTO_LINEUP_DETAIL_LINK}
                >
                    <DetailIconButton aria-label="Edit" icon="Edit" variant="outline" />
                </Link>
            )}
            {/* If does not meet min export requirement, disable and show tip hover */}
            {selectedLineup.canExport ? (
                <Link to={`/cases/${params.caseId}/lineups/${params.photoLineupId}/exports`}>
                    <DetailIconButton aria-label="Download" icon="Download" variant="outline" />
                </Link>
            ) : (
                <LineupDisableExportTooltip>
                    <DetailIconButton
                        aria-label="Download"
                        icon="Download"
                        variant="outline"
                        disabled={true}
                    />
                </LineupDisableExportTooltip>
            )}
        </>
    );

    const renderSubheaderComponent = () => {
        if (isLineup) {
            return (
                <Subheader
                    title={getLineupTitle()}
                    backButtonTo={`/cases/${lastParams?.caseId}/lineups`}
                    bannerComponent={bannerComponent}
                />
            );
        } else if (isLineupDetails) {
            return (
                <Subheader
                    title={getLineupTitle()}
                    backButtonTo={`/cases/${lastParams?.caseId}/lineups`}
                >
                    {renderLineupDetailButtons}
                </Subheader>
            );
        } else {
            return <CaseHeader bannerComponent={bannerComponent} />;
        }
    };

    return (
        <Page className="cases-container single-case-container">
            {renderSubheaderComponent()}
            <ScrollableUnderSubheader
                contentClassnames={classNames({
                    'height-one-hundred': isExports,
                })}
                // This fixes the PageContent hugging the content on shorter pages
                // and showing white below the sidebar.
                style={{
                    display: 'flex',
                    padding: 0,
                    justifyContent: 'center',
                }}
            >
                <LineupContextProvider
                    value={{
                        selectedLineup,
                        setSelectedLineup,
                        numPhotoLineups,
                        setNumPhotoLineups,
                    }}
                >
                    {content}
                </LineupContextProvider>
            </ScrollableUnderSubheader>
        </Page>
    );
}

const mapStateToProps = createStructuredSelector({
    currentCase: currentCaseSelector,
    folderContentViewWhere: folderContentViewWhereSelector,
});

export default connect(mapStateToProps)(CaseContainer);

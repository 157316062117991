import { EntityTypeEnum, LinkTypesEnum, RefContextEnum } from '@mark43/rms-api';
import { get } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import { Form, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import {
    buildIncidentCardTitle,
    isClientSideOffenseStub,
} from '~/client-common/core/domain/offenses/utils/offensesHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import testIds from '../../../../../core/testIds';
import withCard from '../../utils/withCard';
import { createNItemsAdder, createNItemsRemover } from '../../utils/nItemsHelpers';
import incidentCards from '../../state/ui/incidentCards';
import {
    openOffenseIncidentDeletionModal,
    registerForm,
    refreshIncidentForm,
} from '../../state/ui';
import { createIncidentForm } from '../../state/forms/incidentForm';
import formsRegistry from '../../../../../core/formsRegistry';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { LocationSummaryViewWrapper } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapper';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import ItemSectionsInOffense from '../items/ItemSectionsInOffense';
import { OffenseIncidentTitle } from '../OffenseIncidentTitle';
import LegacyEntityDetails from '../../../../records/core/components/LegacyEntityDetails';
import { registerCard } from '../../utils/cardsRegistry';
import IncidentCardSummary from './IncidentCardSummary';
import IncidentCardForm from './IncidentCardForm';

const strings = componentStrings.reports.core.IncidentCard;

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
});

const locationDescriptionPath = () => 'links.location.description';
const locationPositionAttrIdPath = () => 'links.location.positionAttrId';

class IncidentCard extends React.Component {
    constructor(...args) {
        super(...args);
        const { incident, formatFieldByName, arbiter, index } = this.props;
        const initialState = this.props.refreshIncidentForm({ incidentId: incident.id });
        const form = createIncidentForm({
            initialState,
            formatFieldByName,
            arbiter,
        });
        registerForm({ form, index });

        this.handleSubjectAdd = createNItemsAdder({
            getForm: this.getForm,
            path: 'links.subjects',
        });
        this.handleSubjectRemove = ({ nameId }) => {
            return createNItemsRemover({
                getForm: this.getForm,
                path: 'links.subjects',
            })({ nameId });
        };
        this.handleWitnessAdd = createNItemsAdder({
            getForm: this.getForm,
            path: 'links.witnesses',
        });
        this.handleWitnessRemove = ({ nameId }) => {
            return createNItemsRemover({
                getForm: this.getForm,
                path: 'links.witnesses',
            })({ nameId });
        };
        this.handleOtherNamesAdd = createNItemsAdder({
            getForm: this.getForm,
            path: 'links.otherNames',
        });
        this.handleOtherNamesRemove = ({ nameId }) => {
            return createNItemsRemover({
                getForm: this.getForm,
                path: 'links.otherNames',
            })({ nameId });
        };
        this.unregisterCard = registerCard({
            cardModule: incidentCards,
            onSave: this.onSave,
            index,
        });
    }

    componentWillUnmount() {
        this.unregisterCard();
    }

    onEdit = () => this.props.editCallback(() => this.props.onEdit({ index: this.props.index }));

    getForm = () => formsRegistry.get(RefContextEnum.FORM_INCIDENT.name, this.props.index);

    onSaveProgress = ({ customEventType, changeToSummaryModeAfterSubmission } = {}) =>
        this.props.onSaveProgress(this.getForm(), {
            index: this.props.index,
            customEventType,
            changeToSummaryModeAfterSubmission,
        });

    onSave = () => {
        const { incident } = this.props;

        return !isClientSideOffenseStub(incident)
            ? this.props.onSave(this.getForm(), {
                  index: this.props.index,
              })
            : Promise.resolve();
    };

    onStubOffenseCodeChange = () =>
        this.onSaveProgress({
            customEventType: 'FORM_INITIAL_SUBMIT',
            changeToSummaryModeAfterSubmission: false,
        });

    onRemove = () =>
        this.props.openOffenseIncidentDeletionModal({
            entityId: this.props.incident.id,
            isIncident: true,
        });

    handleLocationAdd = (location, modelEntityLink) =>
        this.getForm().set('links.location', modelEntityLink);

    handleLocationRemove = () => this.getForm().set('links.location', undefined);

    renderContent = (summaryMode) => {
        const {
            card = {},
            index: incidentFormIndex,
            incident,
            offenseReportId,
            currentReportREN,
        } = this.props;
        const incidentId = incident.id;
        const reportId = incident.reportId;
        const isClientSideStub = isClientSideOffenseStub(incident);

        const personOverlayIdPrefix = `${overlayIdEnum.PERSON_OVERLAY_INCIDENT_CARD}.${incidentFormIndex}`;

        const organizationOverlayIdPrefix = `${overlayIdEnum.ORGANIZATION_OVERLAY_INCIDENT_CARD}.${incidentFormIndex}`;

        const locationSummaryProps = {
            summaryMode,
            entityType: EntityTypeEnum.OFFENSE.name,
            entityId: incidentId,
            linkType: LinkTypesEnum.OFFENSE_LOCATION,
            onLocationAdd: this.handleLocationAdd,
            onLocationRemove: this.handleLocationRemove,
        };

        return (
            <div>
                {summaryMode ? (
                    <IncidentCardSummary incidentId={incidentId} />
                ) : (
                    <IncidentCardForm
                        incidentId={incidentId}
                        index={incidentFormIndex}
                        isClientSideStub={isClientSideStub}
                        onStubOffenseCodeChange={this.onStubOffenseCodeChange}
                        isSaving={card.saving}
                        getForm={this.getForm}
                        reportId={offenseReportId}
                        autoFocus={this.props.autoFocus}
                        onError={() =>
                            this.props.setErrorMessages([strings.saveGenericError], incidentId)
                        }
                    />
                )}
                {!isClientSideStub && (
                    <div>
                        <CardSection
                            title={strings.incidentLocationSectionTitle}
                            fieldName={
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_LINK_TYPE
                            }
                            testId={testIds.INCIDENT_LOCATION_SECTION}
                        >
                            <Form
                                name={RefContextEnum.FORM_INCIDENT.name}
                                index={incidentFormIndex}
                                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                                render={() =>
                                    summaryMode ? (
                                        <LocationSummaryViewWrapper {...locationSummaryProps} />
                                    ) : (
                                        <LocationSummaryViewWrapperWithFormFields
                                            {...locationSummaryProps}
                                            locationDescriptionPath={locationDescriptionPath}
                                            locationPositionAttrIdPath={locationPositionAttrIdPath}
                                        />
                                    )
                                }
                            />
                        </CardSection>
                        <CardSection
                            title={strings.incidentSubjectSectionTitle}
                            testId={testIds.INCIDENT_SUBJECT_SECTION}
                        >
                            <NameSummaryViewWrapper
                                renForRecents={currentReportREN}
                                personOverlayIdPrefix={personOverlayIdPrefix}
                                organizationOverlayIdPrefix={organizationOverlayIdPrefix}
                                onAddSuccess={this.handleSubjectAdd}
                                onRemoveSuccess={this.handleSubjectRemove}
                                addNameButtonText={strings.incidentSubjectSectionTitle}
                                summaryMode={summaryMode}
                                contextType={EntityTypeEnum.OFFENSE.name}
                                contextId={incidentId}
                                parentEntityType={EntityTypeEnum.REPORT.name}
                                parentId={offenseReportId}
                                linkType={LinkTypesEnum.SUBJECT_IN_OFFENSE}
                                show={{ people: true, organizations: true }}
                            />
                        </CardSection>
                        <CardSection
                            title={strings.incidentWitnessSectionTitle}
                            testId={testIds.INCIDENT_WITNESS_SECTION}
                        >
                            <NameSummaryViewWrapper
                                renForRecents={currentReportREN}
                                personOverlayIdPrefix={personOverlayIdPrefix}
                                organizationOverlayIdPrefix={organizationOverlayIdPrefix}
                                onAddSuccess={this.handleWitnessAdd}
                                onRemoveSuccess={this.handleWitnessRemove}
                                addNameButtonText={strings.incidentWitnessSectionTitle}
                                summaryMode={summaryMode}
                                contextType={EntityTypeEnum.OFFENSE.name}
                                contextId={incidentId}
                                parentEntityType={EntityTypeEnum.REPORT.name}
                                parentId={offenseReportId}
                                linkType={LinkTypesEnum.WITNESS_IN_OFFENSE}
                                show={{ people: true, organizations: false }}
                            />
                        </CardSection>
                        <CardSection
                            title={strings.incidentOtherInvolvedNamesSectionTitle}
                            testId={testIds.INCIDENT_OTHER_NAMES_SECTION}
                        >
                            <NameSummaryViewWrapper
                                renForRecents={currentReportREN}
                                personOverlayIdPrefix={personOverlayIdPrefix}
                                organizationOverlayIdPrefix={organizationOverlayIdPrefix}
                                onAddSuccess={this.handleOtherNamesAdd}
                                onRemoveSuccess={this.handleOtherNamesRemove}
                                addNameButtonText={
                                    strings.incidentOtherInvolvedNamesSectionAddButton
                                }
                                summaryMode={summaryMode}
                                contextType={EntityTypeEnum.OFFENSE.name}
                                contextId={incidentId}
                                parentEntityType={EntityTypeEnum.REPORT.name}
                                parentId={offenseReportId}
                                linkType={LinkTypesEnum.OTHER_NAME_IN_OFFENSE}
                                show={{ people: true, organizations: true }}
                            />
                        </CardSection>
                        <CardSection>
                            <ItemSectionsInOffense
                                offenseId={incidentId}
                                reportId={reportId}
                                summaryMode={summaryMode}
                            />
                        </CardSection>

                        {summaryMode && (
                            <LegacyEntityDetails
                                legacyEntityDetails={this.props.legacyEntityDetails}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { card = {}, incident, cardId } = this.props;
        const isClientSideStub = isClientSideOffenseStub(incident);

        return (
            <Card
                title={
                    <OffenseIncidentTitle
                        card={card}
                        isClientSideStub={isClientSideStub}
                        maxOffenseNumber={this.props.maxOffenseNumber}
                        offense={incident}
                        buildCardTitle={buildIncidentCardTitle}
                        cardId={cardId}
                    />
                }
                testId={
                    isClientSideStub ? testIds.STUB_OFFENSE_INCIDENT_CARD : testIds.INCIDENT_CARD
                }
                onEdit={this.onEdit}
                errors={card.errorMessages}
                summaryMode={card.summaryMode}
                canEdit={get(card.canEditOffenseIncidentReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(
                    card.canEditOffenseIncidentReportCardStatus,
                    'errorMessage'
                )}
                saving={card.saving}
                anchor={card.anchorForIndex(incident.id)}
                onSave={isClientSideStub ? undefined : this.onSaveProgress}
                saveDisabled={isClientSideStub}
                onRemove={this.onRemove}
                renderContent={this.renderContent}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    refreshIncidentForm: ({ incidentId }) => dispatch(refreshIncidentForm({ incidentId })),
    openOffenseIncidentDeletionModal: ({ entityId, isIncident }) =>
        dispatch(openOffenseIncidentDeletionModal({ entityId, isIncident })),
    setErrorMessages: (messages, index) =>
        dispatch(incidentCards.actionCreators.setErrorMessages(messages, { index })),
});

export default compose(
    withCard(incidentCards),
    connect(mapStateToProps, mapDispatchToProps, null, {
        forwardRef: true,
    })
)(IncidentCard);

import { get } from 'lodash';
import { departmentProfilesSelector } from '~/client-common/core/domain/department-profiles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import authResource from '../../../../../legacy-redux/resources/authResource';
import { getAuthToken } from '../../../../../core/auth';
import { openErrorModal } from '../../../../../legacy-redux/actions/boxActions';

const strings = componentStrings.cobalt.navigation.core.AccountSwitcherLink;

export function switchDepartments(subDomain) {
    return () =>
        authResource
            .switchDepartments({
                subDomain,
                csrfTokenToDelete: getAuthToken(),
            })
            .then((result) => {
                window.location.assign(getUrlWithToken(subDomain, result, 'rms'));
            });
}

export function switchToCad(cadUserSettings, cadAgencyEventId) {
    return (dispatch, getState) => {
        if (!get(cadUserSettings, 'cadDepartmentId')) {
            dispatch(
                openErrorModal({
                    paragraphs: [strings.noCadDepartmentError],
                })
            );
        } else {
            const { cadDepartmentId: deptId, hasFirstResponderAbility } = cadUserSettings;
            const subDomain = departmentProfilesSelector(getState())[deptId].department.subDomain;
            authResource.createCrossLoginToken(subDomain).then((token) => {
                const useFirstResponderApp = hasFirstResponderAbility;
                const urlWithToken = useFirstResponderApp
                    ? getFirstResponderAppUrl(subDomain, token)
                    : getUrlWithToken(subDomain, token, 'cad-next');
                if (!cadAgencyEventId) {
                    window.location.assign(urlWithToken);
                } else {
                    const baseUrl = getUrlWithSubDomain(subDomain);
                    const path = `/first-responder/#/event/${cadAgencyEventId}`;

                    const redirectUrl = urlForPath({ baseUrl, encode: true, path });
                    const url = `${urlWithToken}?redirect=${redirectUrl}`;
                    window.open(url);
                }
            });
        }
    };
}

function urlForPath({ baseUrl, encode, path }) {
    if (!baseUrl) {
        return '';
    }
    return `${baseUrl}${encode ? encodeURIComponent(path) : path}`;
}

const getUrlWithSubDomain = (subDomain) => {
    const { protocol, host } = window.location;
    const hostWithoutSubdomain = host.split('.').slice(1).join('.');
    const toHost = `${subDomain}.${hostWithoutSubdomain}`;
    return `${protocol}//${toHost}`;
};

const getUrlWithToken = (subDomain, token, platform) => {
    return `${getUrlWithSubDomain(subDomain)}/${platform}/login/#/cross-login/${token}`;
};

const getFirstResponderAppUrl = (subDomain, token) => {
    const baseUrl = getUrlWithSubDomain(subDomain);
    const path = '/first-responder';
    const urlWithToken = getUrlWithToken(subDomain, token, 'cad-next');
    const redirectUrl = urlForPath({ baseUrl, encode: true, path });
    return `${urlWithToken}?redirect=${redirectUrl}`;
};

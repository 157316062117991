import { map } from 'lodash';

import { roleByIdSelector } from '~/client-common/core/domain/roles/state/data';
import { currentUserDepartmentIdSelector } from '../../modules/core/current-user/state/ui';

/**
 * @param  {Object[]} permissions
 * @param  {Object} state
 * @return {Object[]}
 */
export const addExternalDepartmentFlags = (permissions, state) => {
    const currentDepartmentId = currentUserDepartmentIdSelector(state);
    const roleById = roleByIdSelector(state);
    return map(permissions, (permission) => {
        // we have to support two different models:
        // 1) `EntityPermissionView`
        // 2) `ReportDefaultRoleLink`
        //
        // In the case of `EntityPermissionView` we could just use the hydrated `role`
        // on the object, but so keep the code simple we are going to just always
        // look up the role in state instead
        const roleId = permission.role ? permission.role.id : permission.roleId;

        // If there is no role, then we assume that this is a user role,
        // since those will not be in state, unless they have been
        // searched for. We unfortunately cannot look up the associated mini
        // user, because mini users do not have a department id attached to them,
        // meaning the lookup would be useless.
        //
        // We are not supporting external user roles for permissions
        // so we know it has to be an internal role. In the future it will be better
        // to either augment `ReportDefaultRoleLink` with a property that specifies
        // the department id of the linked role, or to augment mini users with
        // their department id.
        const role = roleById(roleId);
        const isExternalDepartment = role && role.departmentId !== currentDepartmentId;
        return { ...permission, isExternalDepartment };
    });
};

export const isExternalDepartment = ({ isExternalDepartment }) => isExternalDepartment;

import { Warrant, FullWarrant, WarrantTitle } from '@mark43/rms-api';
import { first, flatMap, map } from 'lodash';
import { storeConfigForRelatedRecordsAndEntities } from '../../../../../helpers/multiAgencyHelpers';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getWarrantResource from '../../resources/warrantsResource';
import { augmentActionAndStoreRmsHydratedPersonProfiles } from '../../../person-profiles/state/data';
import { convertLocationBundlesToLocationViews } from '../../../locations/utils/locationHelpers';
import { storeWarrantTitles } from '../../../warrant-titles/state/data';
import { convertAttributeToAttributeView } from '../../../attributes/utils/attributesHelpers';
import { ClientCommonAction } from '../../../../../redux/types';

const buildWarrantCreationRequest = (
    warrant: Warrant,
    warrantRen?: string,
    idFormatConfigurationId?: number
) => ({
    warrantTypeAttrId: warrant.warrantTypeAttrId,
    warrantNumber: warrant.warrantNumber,
    reportingEventNumber: warrantRen,
    idFormatConfigurationId,
});

export const NEXUS_STATE_PROP = 'warrants';

const warrantsModule = createNormalizedModule<Warrant>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const warrantsSelector = warrantsModule.selectors.entitiesSelector;
export const warrantByIdSelector = warrantsModule.selectors.entityByIdSelector;
export const warrantsWhereSelector = warrantsModule.selectors.entitiesWhereSelector;

// ACTIONS
export const storeWarrants = warrantsModule.actionCreators.storeEntities;
const replaceWarrantsWhere = warrantsModule.actionCreators.replaceEntitiesWhere;
const removeWarrant = warrantsModule.actionCreators.deleteEntity;

// REDUCER
export default warrantsModule.reducerConfig;

// RESOURCE ACTIONS
const LOAD_WARRANT_START = 'warrants/LOAD_WARRANT_START';
const LOAD_WARRANT_SUCCESS = 'warrants/LOAD_WARRANT_SUCCESS';
const LOAD_WARRANT_FAILURE = 'warrants/LOAD_WARRANT_FAILURE';
const SAVE_WARRANT_START = 'warrants/SAVE_WARRANT_START';
const SAVE_WARRANT_SUCCESS = 'warrants/SAVE_WARRANT_SUCCESS';
const SAVE_WARRANT_FAILURE = 'warrants/SAVE_WARRANT_FAILURE';
const DELETE_WARRANT_START = 'warrants/DELETE_WARRANT_START';
const DELETE_WARRANT_SUCCESS = 'warrants/DELETE_WARRANT_SUCCESS';
const DELETE_WARRANT_FAILURE = 'warrants/DELETE_WARRANT_FAILURE';

function loadWarrantStart() {
    return {
        type: LOAD_WARRANT_START,
    };
}
function loadWarrantSuccess() {
    return {
        type: LOAD_WARRANT_SUCCESS,
    };
}
function loadWarrantFailure(errorMessage: string) {
    return {
        type: LOAD_WARRANT_FAILURE,
        payload: errorMessage,
    };
}

function saveWarrantStart() {
    return {
        type: SAVE_WARRANT_START,
    };
}
function saveWarrantSuccess(warrantLocation: Warrant) {
    return {
        type: SAVE_WARRANT_SUCCESS,
        payload: warrantLocation,
    };
}
function saveWarrantFailure(errorMessage: string) {
    return {
        type: SAVE_WARRANT_FAILURE,
        payload: errorMessage,
    };
}

function deleteWarrantStart() {
    return {
        type: DELETE_WARRANT_START,
    };
}
function deleteWarrantSuccess() {
    return {
        type: DELETE_WARRANT_SUCCESS,
    };
}
function deleteWarrantFailure(errorMessage: string) {
    return {
        type: DELETE_WARRANT_FAILURE,
        payload: errorMessage,
    };
}

function storeFullWarrant({
    warrants,
    attachments,
    warrantStatuses,
    warrantAttributes,
    warrantActivities,
    warrantCharges,
    chargeLinkedOffenses,
    chargeLinkedReports,
    subjects,
    warrantLocations,
    warrantDexSubmissions,
    warrantDexSubmissionHistories,
    // configurables
    attributes,
    codes,
    attributeCodes,
}: FullWarrant): ClientCommonAction<void> {
    return (dispatch, getState, dependencies) => {
        const locationEntityLinks = flatMap(warrantLocations, 'entityLinks');
        const locations = convertLocationBundlesToLocationViews(warrantLocations);
        dispatch(
            dependencies.nexus.withEntityItems(
                {
                    warrants,
                    attachments,
                    warrantStatuses,
                    warrantAttributes,
                    warrantActivities,
                    warrantCharges,
                    warrantDexSubmissions,
                    warrantDexSubmissionHistories,
                    offenses: chargeLinkedOffenses,
                    locationsTODO: locations,
                    locationEntityLinks,
                    reportsTODO: chargeLinkedReports,
                    attributes: map(attributes, convertAttributeToAttributeView),
                    codes,
                    attributeCodes,
                },
                { type: 'STORE_FULL_WARRANT' }
            )
        );
        dispatch(
            augmentActionAndStoreRmsHydratedPersonProfiles({ type: 'STORE_FULL_WARRANT' }, subjects)
        );
    };
}

/**
 * Load a single full warrant
 */
export function loadFullWarrant(id: number): ClientCommonAction<Promise<FullWarrant[]>> {
    const warrantResource = getWarrantResource();

    return function (dispatch) {
        dispatch(loadWarrantStart());

        return warrantResource
            .getFullWarrantsByIds([id])
            .then((fullWarrants: FullWarrant[]) => {
                const fullWarrant = first(fullWarrants);
                if (!fullWarrant) {
                    throw new Error(`Could not find Warrant with ID ${id}`);
                }
                storeConfigForRelatedRecordsAndEntities(dispatch)(fullWarrant);
                dispatch(storeFullWarrant(fullWarrant));
                dispatch(loadWarrantSuccess());
                return fullWarrant;
            })
            .catch((err: Error) => {
                dispatch(loadWarrantFailure(err.message));
                throw err;
            });
    };
}

/**
 * Create or update warrant
 */
export function saveWarrant(
    warrant: Warrant,
    warrantRen?: string,
    idFormatConfigurationId?: number
): ClientCommonAction<Promise<Warrant>> {
    const warrantResource = getWarrantResource();

    return function (dispatch) {
        dispatch(saveWarrantStart());
        // if there's no warrant id, create new, else upsert
        const promise = !warrant.id
            ? warrantResource.createWarrant(
                  buildWarrantCreationRequest(warrant, warrantRen, idFormatConfigurationId)
              )
            : warrantResource.updateWarrant(warrant, warrantRen);

        return promise
            .then((warrant: Warrant) => {
                dispatch(saveWarrantSuccess(warrant));
                dispatch(replaceWarrantsWhere(warrant.id, warrant));
                return warrant;
            })
            .catch((err: Error) => {
                dispatch(saveWarrantFailure(err.message));
                throw err;
            });
    };
}

/**
 * Delete a warrant
 */
export function deleteWarrant(id: number): ClientCommonAction<Promise<void>> {
    const warrantResource = getWarrantResource();

    return function (dispatch) {
        dispatch(deleteWarrantStart());

        return warrantResource
            .deleteWarrant(id)
            .then((success: boolean) => {
                dispatch(deleteWarrantSuccess());
                if (success) {
                    dispatch(removeWarrant(id));
                } else {
                    throw new Error('Failed to remove warrant');
                }
            })
            .catch((err: Error) => {
                dispatch(deleteWarrantFailure(err.message));
                throw err;
            });
    };
}

export function getWarrantTitlesForWarrantNumber(
    warrantNumber: string
): ClientCommonAction<Promise<WarrantTitle[]>> {
    const warrantResource = getWarrantResource();

    return (dispatch) => {
        return warrantResource
            .getWarrantTitlesForWarrantNumber(warrantNumber)
            .then((warrantTitles: WarrantTitle[]) => {
                dispatch(storeWarrantTitles(warrantTitles));
                return warrantTitles;
            })
            .catch((err: Error) => {
                throw err;
            });
    };
}

export function updateSubjectPersonProfileIdForWarrantId(
    warrantId: number,
    subjectPersonProfileId: number
): ClientCommonAction<void> {
    return (dispatch, getState) => {
        const warrant = warrantsSelector(getState())[warrantId];
        return dispatch(
            saveWarrant(
                {
                    ...warrant,
                    subjectPersonProfileId,
                },
                warrant.reportingEventNumber
            )
        );
    };
}

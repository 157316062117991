import Promise from 'bluebird';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import createCard from '../../utils/createCard';

const baseCard = createCard({
    anchor: 'property-card',
    baseSelector: (state) => state.ui.report.propertyCard,
    name: reportCardEnum.PROPERTY.name,
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit: () => (dispatch) => dispatch(baseCard.actionCreators.transitionToEditMode()),
        save: () => (dispatch) => {
            dispatch(baseCard.actionCreators.savingSuccess());
            return Promise.resolve();
        },
    },
};

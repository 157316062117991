import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { max } from 'lodash';

import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';

import advancedSearchOrganizations from '../state/ui';
import StaleQueryNotificationBar from '../../core/components/StaleQueryNotificationBar';
import AdvancedSearchResultsTableHeader from '../../core/components/AdvancedSearchResultsTableHeader';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import OrganizationCell from '../../../../legacy-redux/components/core/tables/elasticOrganizationTable/OrganizationCell';
import IdentifiersCell from '../../../../legacy-redux/components/core/tables/elasticOrganizationTable/IdentifiersCell';
import LocationsCell from '../../../../legacy-redux/components/core/tables/LocationsCell';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';
import { savedSearchIsStale } from '../../core/utils/savedSearchIsStale';
import { useDashboardScroll } from '../../core/hooks/useDashboardScroll';
import useOnSaveSearchToggleHandler from '../../core/hooks/useOnSaveSearchToggleHandler';

const strings = componentStrings.search.AdvancedSearchOrganizationsResults;

// 107 is the height of the table header
// todo refactor to genericize
const ClippableSearchTable = clippable({ bottomBuffer: 107 })(Table);

function AdvancedSearchOrganizationsResults({
    // Current Query and Search Results
    results = [],
    query: { savedSearchId, from, size, sortKey: currentSortKey, sortType: currentSortType },
    totalCount: totalResults,

    // Table UI State
    tableLoading,
    searchFilters,
    selectedRows,

    // Event Handlers
    handleRowSelect,
    handleRowClick,
    handleSortByOptionClick,
    handleSortBySortTypeClick,
    handlePaginationClick,

    // Saved Search
    savedSearchErrorMessage,
    savedSearches,

    selectableRows,
    router,
}) {
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: advancedSearchOrganizations,
        isScrollToElement: true,
    });

    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const onSaveSearchToggle = useOnSaveSearchToggleHandler(advancedSearchOrganizations);

    const organizationLabel = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];

    return (
        <div>
            {savedSearchIsStale(savedSearches, savedSearchId) && <StaleQueryNotificationBar />}
            <ClippableSearchTable
                data={results}
                onRowClick={handleRowClick(getCurrentScrollPosition)}
                sortKey={currentSortKey}
                sortType={currentSortType}
                noRowsText={strings.noResults}
                selectableRows={selectableRows}
                onSelectRow={handleRowSelect}
                selectedRows={selectedRows}
                disableBody={tableLoading}
                containerClassName="search-results-table"
                rowComponent={ElasticResultRow}
                tableCheckboxMargin="10px"
            >
                <TableHeader>
                    <AdvancedSearchResultsTableHeader
                        from={from}
                        to={from + results.length}
                        searchSize={size}
                        totalResults={totalResults}
                        currentPage={currentPage}
                        searchFilters={searchFilters}
                        onPaginationClick={(lastPage, nextPage) =>
                            handlePaginationClick(nextPage, size)
                        }
                        isSearchSavable={true}
                        onSaveSearchToggleClick={onSaveSearchToggle}
                        savedSearchId={savedSearchId}
                        savedSearchErrorMessage={savedSearchErrorMessage}
                        savedSearches={savedSearches}
                        onSaveSearch={onSaveSearchToggle}
                        searchModule={advancedSearchOrganizations}
                    />
                    <TableColumns>
                        <OptionsTableColumn
                            display={strings.sortBy.label}
                            columnKey="organization"
                            activeValue={currentSortKey}
                            activeSortType={currentSortType}
                            onOptionClick={handleSortByOptionClick}
                            onSortTypeClick={handleSortBySortTypeClick}
                            width={330}
                            popoutWidth={310}
                        >
                            <OptionsTableColumnOption
                                display={strings.relevance.label}
                                value={sortKeyEnum.RELEVANCE}
                            />
                            <OptionsTableColumnOption
                                display={strings.name.label(organizationLabel)}
                                value={sortKeyEnum.ORGANIZATION_NAME}
                                sortOptions={[
                                    {
                                        display: strings.name.ascendingSort,
                                        sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                    },
                                    {
                                        display: strings.name.descendingSort,
                                        sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                    },
                                ]}
                            />
                            <OptionsTableColumnOption
                                display={strings.modifiedDate.label}
                                value={sortKeyEnum.ORGANIZATION_UPDATED_DATE_UTC}
                                sortOptions={[
                                    {
                                        display: strings.modifiedDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.modifiedDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                        </OptionsTableColumn>
                        <TableColumn
                            display={strings.identifiers.label}
                            columnKey="identifiers"
                            width={243}
                        />
                        <TableColumn
                            display={strings.locations.label}
                            columnKey="locations"
                            width={347}
                        />
                    </TableColumns>
                </TableHeader>
                <TableBody>
                    <OrganizationCell columnKey="organization" />
                    <IdentifiersCell columnKey="identifiers" />
                    <LocationsCell columnKey="locations" />
                </TableBody>
            </ClippableSearchTable>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    query: advancedSearchOrganizations.selectors.currentQuerySelector,
    results: advancedSearchOrganizations.selectors.currentResultsViewModelsSelector,
    totalCount: advancedSearchOrganizations.selectors.totalCountSelector,
    tableLoading: advancedSearchOrganizations.selectors.tableLoadingSelector,
    searchFilters: advancedSearchOrganizations.selectors.filtersSelector,
    selectedRows: advancedSearchOrganizations.selectors.selectedRowsSelector,
    savedSearchErrorMessage:
        advancedSearchOrganizations.selectors.loadSavedSearchErrorMessageSelector,
    executedSavedSearchToUpdate:
        advancedSearchOrganizations.selectors.executedSavedSearchToUpdateSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleRowClick(getCurrentScrollPosition) {
        return (elasticOrganization, rowIndex) => {
            dispatch(
                advancedSearchOrganizations.actionCreators.openSearchResult(
                    elasticOrganization,
                    rowIndex,
                    router,
                    getCurrentScrollPosition()
                )
            );
        };
    },
    handleRowSelect(newSelectedRows) {
        dispatch(advancedSearchOrganizations.actionCreators.selectRows(newSelectedRows));
    },
    handleSortByOptionClick({ value, sortOptions }) {
        dispatch(
            advancedSearchOrganizations.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleSortBySortTypeClick(sortType) {
        dispatch(advancedSearchOrganizations.actionCreators.search({ sortType }));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            advancedSearchOrganizations.actionCreators.search({
                from: nextPage * size - size,
                size,
            })
        );
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AdvancedSearchOrganizationsResults);

import type { NItemsConfiguration } from 'dragon-react';
import type {
    InlineFormReferenceValuesView,
    UiTypeFieldUiConfigurationView,
    UiTypeFieldsetUiConfigurationView,
    UiTypeInlineFormReferenceUiConfigurationView,
} from '@mark43/rms-api';
import type { RMSDragonConfigurationExtensions } from '../../../rms-types';

/**
 * Compute the initial values for a single NItem. The returned object is expected to be passed into markformythree's
 * `addItem` function.
 *
 * This function recursively adds extra `undefined` values for all fields inside the NItem fieldset, because:
 * 1. Whenever a rendered field is not initialized with a value in markformythree, it always calls `set` on that field
 *    first, which triggers form validation with the event type INPUT_CHANGED. Adding an NItem normally triggers form
 *    validation with the event type N_ITEMS_ADDED, but that would not happen due to the earlier INPUT_CHANGED event.
 *    The validation event type must be N_ITEMS_ADDED in order for the correct rules to run, particularly show/hide
 *    rules in the NItem fieldset.
 * 2. When a Dragon instance is created, the API response does not include null/undefined values (for the fields inside
 *    the NItem).
 */
export function getInitialValuesForNItem(
    inlineFormValuesView: InlineFormReferenceValuesView,
    nItemsConfiguration: NItemsConfiguration<RMSDragonConfigurationExtensions>
): InlineFormReferenceValuesView['values'] {
    return {
        ...inlineFormValuesView.values,
        ...getInitialValuesForFieldSet(
            nItemsConfiguration.fieldSetConfigurations,
            inlineFormValuesView.values
        ),
        ...getInitialValuesForFields(nItemsConfiguration.fieldConfigurations),
        ...getInitialValuesForInlineFormReferences(
            nItemsConfiguration.inlineFormReferenceConfigurations
        ),
    };
}

function getInitialValuesForFieldSet(
    fieldSetConfigurations: UiTypeFieldsetUiConfigurationView[],
    values?: InlineFormReferenceValuesView['values']
): Record<string, Record<string, unknown>> {
    return fieldSetConfigurations.reduce<Record<string, Record<string, unknown>>>((acc, config) => {
        const nestedValues = {
            ...getInitialValuesForFieldSet(config.fieldSetConfigurations),
            ...getInitialValuesForFields(config.fieldConfigurations),
            ...getInitialValuesForInlineFormReferences(config.inlineFormReferenceConfigurations),
        };

        /**
         * At the top fieldset of the NItem, there is at least 1 existing value to initialize, unlike all the
         * other `undefined` values we are populating here. This number value is a newly created Dragon instance
         * id. Remove this type assertion after updating the type of InlineFormReferenceValuesView.
         */
        const configValues = (values?.[config.id] ?? {}) as Record<
            string,
            string | number | undefined
        >;
        for (const [key, value] of Object.entries(configValues)) {
            // It is important that we only assign the default value if we did not compute a nested value on the client.
            // Otherwise we will discard the just computed nested tree.
            if (!nestedValues[key]) {
                nestedValues[key] = value;
            }
        }

        return {
            ...acc,
            [config.id]: nestedValues,
        };
    }, {});
}

function getInitialValuesForFields(
    fieldConfigurations: UiTypeFieldUiConfigurationView[]
): Record<string, unknown> {
    return fieldConfigurations.reduce<Record<string, undefined>>((acc, config) => {
        return {
            ...acc,
            [config.id]: undefined,
        };
    }, {});
}

function getInitialValuesForInlineFormReferences(
    inlineFormReferenceConfigurations: UiTypeInlineFormReferenceUiConfigurationView[]
): Record<string, Record<string, unknown>> {
    return inlineFormReferenceConfigurations.reduce<Record<string, Record<string, unknown>>>(
        (acc, config) => {
            return {
                ...acc,
                [config.id]: {
                    ...getInitialValuesForFieldSet(config.fieldSetConfigurations),
                    ...getInitialValuesForFields(config.fieldConfigurations),
                    ...getInitialValuesForInlineFormReferences(
                        config.inlineFormReferenceConfigurations
                    ),
                },
            };
        },
        {}
    );
}

import keyMirror from 'keymirror';

/**
 * Sequence types during record configuration
 * @type {Object}
 */
export default keyMirror({
    AUTO_GENERATED: null,
    REN: null,
    CAD_EVENT: null,
    FREE_TEXT: null,
    FORMATTED_FREE_TEXT: null,
});

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    DISPLAY_ONLY_PERSONNEL_AUTHORS_LABEL,
    DISPLAY_ONLY_PERSONNEL_INVOLVED_OFFICERS_LABEL,
    EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { FIELDSET, USER, ATTRIBUTE } = fieldTypeClientEnum;
const strings = componentStrings.cases.unassignedReports.UnassignedReportsSearchPersonnelFieldset;

/**
 * Fieldset view model.
 */
export default {
    type: FIELDSET,
    key: 'personnel',
    title: strings.title,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'authorIds',
            type: USER,
            fieldName: DISPLAY_ONLY_PERSONNEL_AUTHORS_LABEL,
        },
        {
            key: 'involvedOfficerIds',
            type: USER,
            fieldName: DISPLAY_ONLY_PERSONNEL_INVOLVED_OFFICERS_LABEL,
        },
        {
            key: 'personnelUnitAttrIds',
            type: ATTRIBUTE,
            fieldName: EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID,
        },
    ]),
};

import { ProtectedEntityOptionDisplayView } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP_ENTITY_OPTION_DISPLAYS = 'dragonProtectedEntityOptionDisplayViews';

const dragonProtectedEntityOptionDisplayViewModule =
    createNormalizedModule<ProtectedEntityOptionDisplayView>({
        type: NEXUS_STATE_PROP_ENTITY_OPTION_DISPLAYS,
        key: 'instanceId',
    });

// ACTIONS
export const storeProtectedEntityOptionDisplayViews =
    dragonProtectedEntityOptionDisplayViewModule.actionCreators.storeEntities;

// SELECTORS
export const protectedEntityOptionDisplayViewByIdSelector =
    dragonProtectedEntityOptionDisplayViewModule.selectors.entityByIdSelector;

// SELECTORS
export const protectedEntityOptionDisplayViewWhereSelector =
    dragonProtectedEntityOptionDisplayViewModule.selectors.entitiesWhereSelector;

// REDUCER
export default dragonProtectedEntityOptionDisplayViewModule.reducerConfig;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon as ArcIcon, SimpleGrid, cssVar } from 'arc';

import { get } from 'lodash';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { Tile } from '../../../core/components/Tile';
import Modal from '../../../core/overlays/components/Modal';
import { createAnalysisDashboard } from '../state/ui';
import getLookerResource from '../../dashboard/resources/lookerResource';
import CreateAnalysisDashboardButton from './CreateAnalysisDashboardButton';

const strings = componentStrings.components.analysis.CreateAnalysisDashboardModal;

const CreateAnalysisDashboardModal = () => {
    const dispatch = useDispatch();
    const applicationSettings = useSelector(applicationSettingsSelector);

    const handleCreateDashboard = (dashboardType: string) => {
        if (applicationSettings.RMS_LOOKER_IFRAME_LISTENER_ENABLED) {
            getLookerResource.createNewDashboard(dashboardType).then((newDashboard) => {
                const lookerIframe = document.getElementsByClassName(
                    'analysis-dashboard-frame'
                )[0] as HTMLIFrameElement;
                if (lookerIframe) {
                    lookerIframe.src = get(newDashboard, 'url');
                }
            });
        } else {
            dispatch(createAnalysisDashboard(dashboardType));
        }
    };

    return (
        <Modal
            buttonElement={
                <CreateAnalysisDashboardButton id={overlayIdEnum.CREATE_ANALYSIS_DASHBOARD_MODAL} />
            }
            id={overlayIdEnum.CREATE_ANALYSIS_DASHBOARD_MODAL}
            okText={null}
            overrideModalProps={(overlayProps) => ({
                children: (
                    <SimpleGrid height="100%" columns={2} gap={cssVar('arc.space.2')}>
                        <Tile
                            title={strings.personalDashboardTitle}
                            onClick={() => {
                                handleCreateDashboard(strings.selectPersonalDashboard);
                                overlayProps.close();
                            }}
                            media={<ArcIcon color="brand.default" icon="Add" size="md" />}
                        />
                        <Tile
                            title={strings.groupDashboardTitle}
                            onClick={() => {
                                handleCreateDashboard(strings.selectGroupDashboard);
                                overlayProps.close();
                            }}
                            media={<ArcIcon color="brand.default" icon="Add" size="md" />}
                        />
                    </SimpleGrid>
                ),
            })}
            title={strings.title}
        />
    );
};

export default CreateAnalysisDashboardModal;

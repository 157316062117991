import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Tile } from 'arc';
import styled from 'styled-components';
import { QueryableEntityType } from "mark43-federated-search"

import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { iconSizes } from '../../../../core/components/Icon';
import { VehicleProfileImage, BoatProfileImage } from '../../../../core/components/ProfileImage';
import { vehicleViewSelector, VehiclViewType } from './state/ui/selectors';
import { VehicleFormData } from './helpers';

const strings = componentStrings.core.VehiclePillSmall;

const StyledTile = styled(Tile)`
    & .chakra-text {
        white-space: pre-wrap;
        display: block;
    }
`;

type VehiclePillSmallProps = {
    vehicle: VehicleFormData;
    onClick: () => void;
};

const formatVehicleTitle = (vehileView: VehiclViewType) => {
    return (
        joinTruthyValues(
            [
                vehileView.registrationYear,
                vehileView.makeName,
                vehileView.modelName,
                vehileView.primaryColor,
            ],
            ', '
        ) || strings.epmtyTitle
    );
};

const formatVehicleRegistrationNumber = (vehileView: VehiclViewType) => {
    return joinTruthyValues(
        [
            `${strings.registrationNumber}:`,
            vehileView.tag,
            vehileView.registrationState && `(${vehileView.registrationState})`,
        ],
        ' '
    );
};

const formatVehicleVinNumber = (vehileView: VehiclViewType) => {
    return vehileView.vinNumber && `${strings.vin}: ${vehileView.vinNumber}`;
};

const formatVehicleSubtitle = (vehileView: VehiclViewType) => {
    const vehicleRegistrationNumber = formatVehicleRegistrationNumber(vehileView);
    const vehicleVinNumber = formatVehicleVinNumber(vehileView);

    return (
        joinTruthyValues([vehicleRegistrationNumber, vehicleVinNumber], '\n') ||
        strings.epmtySubtitle
    );
};

export const VehiclePillSmall: FC<VehiclePillSmallProps> = ({ vehicle, onClick }) => {
    const selectVehicleView = useSelector(vehicleViewSelector);

    const vehicleView = selectVehicleView(vehicle);

    let MediaComponent = VehicleProfileImage;

    if (vehicle.entityType === QueryableEntityType.BOAT) {
        MediaComponent = BoatProfileImage;
    }

    return (
        <StyledTile
            onMouseDown={onClick}
            title={formatVehicleTitle(vehicleView)}
            description={formatVehicleSubtitle(vehicleView)}
            media={<MediaComponent iconSize={iconSizes.LARGE} size="40px" url={vehicle.img} />}
        />
    );
};

import _ from 'lodash';

import * as fields from '~/client-common/core/enums/universal/fields';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'remark',
        fieldName: fields.STAFF_REMARK_REMARK,
        validators: {
            requiredError: requiredString,
        },
    },
]);

/**
 * @param  {Object}   formModel
 * @param  {string}   entityType
 * @param  {number[]} entityIds
 * @return {Object[]}
 */
function convertFromFormModel(formModel, entityType, entityIds) {
    const remarkDateUtc = nowUtc(); // TODO: should be filled by back end

    return _.map(entityIds, (entityId) => ({
        remark: formModel.remark,
        entityType,
        entityId,
        remarkDateUtc,
    }));
}

const staffRemarkForm = createFormModule({
    formName: formClientEnum.STAFF_REMARK,
    fieldViewModels,
    convertFromFormModel,
});

/**
 * Module of the form to create an evidence staff remark.
 * @type {Object}
 */
export default staffRemarkForm;

import React from 'react';
import { compose, withPropsOnChange, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import classNames from 'classnames';

import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';

import Row from '../../../core/components/Row';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import _Checkbox from '../../../core/forms/components/checkboxes/Checkbox';
import { propertyStatusForItemToUseInEvidenceSelector } from '../../../reports/custodial-property-summary/state/ui';
import evidenceItemSplitForm from '../state/forms/evidenceItemSplitForm';
import { autoBalanceSubtract } from '../utils/mathHelpers';
import { PropertyStatusLabel } from '../../../reports/custodial-property-summary/components/PropertyStatusContent';
import { validatePropertyStatuses } from '../state/ui';

const { withRRFFieldValues } = reactReduxFormHelpers;
const { itemType } = globalAttributes;
const strings = componentStrings.evidence.itemSplit.ItemSplitPropertyStatusFieldset;
const helpTextContainerName = 'item-split-child-property-status';
const helpTextCollisionBoundary = document.querySelector('.mark43-react-side-panel');
const helpTextPlacement = 'top';

const Checkbox = styled(_Checkbox)`
    margin-top: 24px;
`;

function ItemSplitPropertyStatusFieldset({
    autoBalanceDeclaredValue,
    autoBalanceQuantity,
    childItemTypeAttrId,
    parentItemTypeAttrId,
    parentDeclaredValue,
    parentQuantity,
    propertyStatusDisplay,
    isParent,
    widths,
    onAutoBalanceDeclaredValue,
    onAutoBalanceQuantity,
    onDeclaredValueChange,
    onQuantityChange,
}) {
    const isSameItemType = parentItemTypeAttrId === childItemTypeAttrId;
    const showAutoBalanceDeclaredValue = !isParent && !!parentDeclaredValue;
    const showAutoBalanceQuantity = !isParent && isSameItemType && !!parentQuantity;
    const showMeasurementUnits = isParent
        ? parentItemTypeAttrId === itemType.drugs
        : childItemTypeAttrId === itemType.drugs;
    const disabledMeasurementUnits = isParent || autoBalanceQuantity;
    const helpTextContainerClassName = classNames({ [helpTextContainerName]: !isParent });

    return (
        <div className={helpTextContainerClassName}>
            <PropertyStatusLabel>{propertyStatusDisplay}</PropertyStatusLabel>
            <Row>
                <RRFText
                    path="declaredValue"
                    disabled={isParent}
                    width={widths.third}
                    onChange={onDeclaredValueChange}
                />
                {showAutoBalanceDeclaredValue && (
                    <Checkbox
                        value={autoBalanceDeclaredValue}
                        helpText={strings.helpText.declaredValue}
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        helpTextPlacement={helpTextPlacement}
                        label={strings.labels.autoBalanceDeclaredValue}
                        onChange={onAutoBalanceDeclaredValue}
                    />
                )}
            </Row>
            <Row>
                <RRFText
                    path="quantity"
                    disabled={isParent}
                    width={widths.third}
                    onChange={onQuantityChange}
                />
                {showAutoBalanceQuantity && (
                    <Checkbox
                        value={autoBalanceQuantity}
                        helpText={strings.helpText.quantity}
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        helpTextPlacement={helpTextPlacement}
                        label={strings.labels.autoBalanceQuantity}
                        onChange={onAutoBalanceQuantity}
                    />
                )}
            </Row>
            {showMeasurementUnits && (
                <Row>
                    <RRFAttributeSelect
                        path="measurementUnitsAttrId"
                        attributeType="DRUG_MEASUREMENT"
                        disabled={disabledMeasurementUnits}
                        width={widths.half}
                    />
                </Row>
            )}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    propertyStatusByItemProfileId: propertyStatusForItemToUseInEvidenceSelector,
    formatAttributeById: formatAttributeByIdSelector,
});
const mapDispatchToProps = (dispatch) => ({
    formChangePath: (...args) => dispatch(evidenceItemSplitForm.actionCreators.changePath(...args)),
    validatePropertyStatuses: () => dispatch(validatePropertyStatuses()),
});

/**
 * Item Split Property Status Fieldset
 *  with auto balance logic and functionality
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withState('autoBalanceDeclaredValue', 'setAutoBalanceDeclaredValue', false),
    withState('autoBalanceQuantity', 'setAutoBalanceQuantity', false),
    withRRFFieldValues({
        quantityFieldValue: 'quantity',
        declaredValueFieldValue: 'declaredValue',
    }),
    withPropsOnChange(
        ['parentItemProfileId', 'propertyStatusByItemProfileId', 'formatAttributeById'],
        ({ parentItemProfileId, propertyStatusByItemProfileId, formatAttributeById }) => {
            const {
                propertyStatusAttrId,
                declaredValue,
                quantity,
                measurementUnitsAttrId,
            } = propertyStatusByItemProfileId(parentItemProfileId);

            return {
                parentDeclaredValue: declaredValue,
                parentQuantity: quantity,
                parentMeasurementUnitsAttrId: measurementUnitsAttrId,
                propertyStatusDisplay: formatAttributeById(propertyStatusAttrId),
            };
        }
    ),
    withHandlers({
        onAutoBalanceDeclaredValue: ({
            setAutoBalanceDeclaredValue,
            formChangePath,
            declaredValueFieldValue,
            parentDeclaredValue,
            validatePropertyStatuses,
        }) => (autoBalanceDeclaredValueInputValue) => {
            // default to parentDeclaredValue if turning off auto balance
            let declaredValue = parentDeclaredValue;

            if (autoBalanceDeclaredValueInputValue) {
                declaredValue = autoBalanceSubtract(parentDeclaredValue, declaredValueFieldValue);
            }

            formChangePath('parentPropertyStatus.declaredValue', declaredValue);
            setAutoBalanceDeclaredValue((value) => !value);
            validatePropertyStatuses();
        },
        onAutoBalanceQuantity: ({
            setAutoBalanceQuantity,
            formChangePath,
            parentQuantity,
            quantityFieldValue,
            parentMeasurementUnitsAttrId,
            parentItemTypeAttrId,
            validatePropertyStatuses,
        }) => (autoBalanceQuantityInputValue) => {
            // default to parentQuantity if turning off auto balance
            let quantity = parentQuantity;

            if (autoBalanceQuantityInputValue) {
                quantity = autoBalanceSubtract(parentQuantity, quantityFieldValue);

                if (parentItemTypeAttrId === itemType.drugs) {
                    formChangePath(
                        'childPropertyStatus.measurementUnitsAttrId',
                        parentMeasurementUnitsAttrId
                    );
                }
            }

            formChangePath('parentPropertyStatus.quantity', quantity);
            setAutoBalanceQuantity((value) => !value);
            validatePropertyStatuses();
        },
        onDeclaredValueChange: ({
            autoBalanceDeclaredValue,
            parentDeclaredValue,
            formChangePath,
            validatePropertyStatuses,
        }) => (declaredValueInputValue) => {
            if (autoBalanceDeclaredValue) {
                const declaredValue = autoBalanceSubtract(
                    parentDeclaredValue,
                    declaredValueInputValue
                );
                formChangePath('parentPropertyStatus.declaredValue', declaredValue);
            }
            validatePropertyStatuses();
        },
        onQuantityChange: ({
            autoBalanceQuantity,
            formChangePath,
            parentQuantity,
            validatePropertyStatuses,
        }) => (quantityInputValue) => {
            if (autoBalanceQuantity) {
                const quantity = autoBalanceSubtract(parentQuantity, quantityInputValue);
                formChangePath('parentPropertyStatus.quantity', quantity);
            }
            validatePropertyStatuses();
        },
    })
)(ItemSplitPropertyStatusFieldset);

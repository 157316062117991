export type ResolvedEntityResult = { [index: string]: unknown }[];

type DragonSelectState = {
    isLoading: boolean;
    error?: Error;
    data?: ResolvedEntityResult;
};

type DragonSelectAction =
    | {
          type: 'LOADING_SUCCESS';
          payload: ResolvedEntityResult;
      }
    | {
          type: 'LOADING_FAILURE';
          payload: Error;
      }
    | {
          type: 'LOADING_START';
      };

export function dragonSelectStateReducer(
    state: DragonSelectState,
    action: DragonSelectAction
): DragonSelectState {
    switch (action.type) {
        case 'LOADING_START':
            return { ...state, isLoading: true, error: undefined, data: undefined };
        case 'LOADING_SUCCESS':
            return { ...state, isLoading: false, error: undefined, data: action.payload };
        case 'LOADING_FAILURE':
            return { ...state, isLoading: false, error: action.payload, data: undefined };
        default:
            return state;
    }
}

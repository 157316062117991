import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, sortBy } from 'lodash';
import { lifecycleOptions } from 'markformythree';
import { cssVar, Divider, Flex, Spacer } from 'arc';
import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';

import { detentionsForReportIdSelector } from '~/client-common/core/domain/detentions/state/data';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { chargeViewModelsWhereSelector } from '~/client-common/core/domain/charges/state/ui';

import redirectToErrorPage from '../../../core/utils/redirectToErrorPage';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTDatePicker } from '../../../core/forms/components/DatePicker';
import { MFTNItems } from '../../../core/forms/components/NItems';
import { SubsectionTitleText } from '../../../core/components/typography';
import Row from '../../../core/components/Row';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import IndentedFields from '../../../core/components/IndentedFields';
import ArbiterFormWrapper from '../../../core/markformythree-arbiter/ArbiterForm';
import {
    convertToFormModel,
    detentionDispositionFormConfiguration,
    DetentionDispositionFieldsetFormModel,
} from '../state/forms';
import { ChargeViewModelT } from '../../core/components/charges/ChargeSummaryView';
import theme from '../../../core/styles/theme';

export const DetentionDispositionForm = ({ reportId }: { reportId: number }) => {
    const dispatch = useDispatch();
    const arrestForReportId = useSelector(arrestForReportIdSelector);
    const arrestId = arrestForReportId(reportId)?.id;
    const chargeViewModelsWhere = useSelector(chargeViewModelsWhereSelector);
    const chargeViewModels = sortBy(
        chargeViewModelsWhere({
            arrestId,
        }),
        'offenseOrder'
    ) as ChargeViewModelT[];

    const detentionsForReportId = useSelector(detentionsForReportIdSelector);

    useEffect(() => {
        if (isEmpty(chargeViewModels) && isEmpty(detentionsForReportId)) {
            dispatch(redirectToErrorPage());
        }
    }, [chargeViewModels, detentionsForReportId, dispatch]);

    const initialDetentions = detentionsForReportId(reportId);

    const initialState = convertToFormModel({
        chargeViewModels,
        detentions: initialDetentions,
        reportId,
    });
    return (
        <ArbiterFormWrapper
            configuration={detentionDispositionFormConfiguration}
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={RefContextEnum.FORM_DETENTION_DISPOSITION.name}
            context={RefContextEnum.FORM_DETENTION_DISPOSITION.name}
            initialState={initialState}
            render={() => (
                <Flex
                    flexDirection="column"
                    padding={cssVar('arc.sizes.4')}
                    marginBottom={`${theme.heights.actionBar}px`}
                >
                    <MFTNItems<DetentionDispositionFieldsetFormModel>
                        path="detentionDispositions"
                        addItemOnEmpty={false}
                        renderAddButton={() => <></>}
                        renderRemoveButton={() => <></>}
                        render={({ item, items, index }) => {
                            return (
                                <Flex flexDirection="column" padding={cssVar('arc.sizes.4')}>
                                    <Row>
                                        <SubsectionTitleText>
                                            {item?.displayOnlyChargeTitle}
                                        </SubsectionTitleText>
                                    </Row>
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            length="md"
                                            path="nonFilingTypeAttrId"
                                            attributeType={
                                                AttributeTypeEnum.DETENTION_NON_FILING_TYPE.name
                                            }
                                        />
                                    </Row>
                                    <IndentedFields>
                                        <Row>
                                            <ArbiterMFTAttributeSelect
                                                length="md"
                                                path="nonFilingReasonCodeAttrId"
                                                attributeType={
                                                    AttributeTypeEnum
                                                        .DETENTION_NON_FILING_REASON_CODE.name
                                                }
                                            />
                                        </Row>
                                        <Row>
                                            <ArbiterMFTText
                                                length="md"
                                                path="originalCourtDistrictNumber"
                                            />
                                        </Row>
                                        <Row>
                                            <ArbiterMFTText
                                                length="md"
                                                path="originalCourtCaseNumber"
                                            />
                                        </Row>
                                    </IndentedFields>
                                    <Row>
                                        <ArbiterMFTDatePicker
                                            length="md"
                                            path="courtOrderDateUtc"
                                        />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTText length="md" path="courtOrderNumber" />
                                    </Row>
                                    {index < items.length - 1 ? (
                                        <Flex height="1">
                                            <Divider />
                                        </Flex>
                                    ) : (
                                        <Spacer height="200px" width="100%" />
                                    )}
                                </Flex>
                            );
                        }}
                    />
                </Flex>
            )}
        />
    );
};

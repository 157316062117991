import React from 'react';
import { isEmpty, some, map } from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { chainOfCustodiesWhereSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { Tooltip } from '../../../core/components/tooltip';
import testIds from '../../../../core/testIds';
import SearchActionBar from './SearchActionBar';

const strings = componentStrings.search.SearchMergeEntityBar;

const MERGE_ENTITIES_REQUIRED_NUMBER_OF_PROFILES = 2;

const StyledDiv = styled.div`
    width: max-content;
`;

export default function SearchMergeEntityBar({
    selectedRowViewModels,
    currentUserDepartmentId,
    consortiumDepartmentLinksAvailable,
    selectedResultCount = 0,
    onMergeClick,
}) {
    const chainOfCustodiesWhere = useSelector(chainOfCustodiesWhereSelector);
    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];

    // List of ids selected by the user
    const idsToMerge = map(selectedRowViewModels, (element) => element.id);

    // chainMergingDisable will return true if ANY of the elements has a chain of custody length > 0
    const chainMergingDisable = some(
        idsToMerge,
        (id) => !isEmpty(chainOfCustodiesWhere({ masterItemId: id }))
    );

    const mergingDisabledExceedMergeProfileLimit =
        selectedResultCount !== MERGE_ENTITIES_REQUIRED_NUMBER_OF_PROFILES;
    const mergingDisabled =
        mergingDisabledExceedMergeProfileLimit ||
        some(
            selectedRowViewModels,
            ({ departmentId }) => departmentId !== currentUserDepartmentId
        ) ||
        chainMergingDisable;

    const button = (
        <Button
            float="none"
            display="inline-block"
            className={buttonTypes.SECONDARY_BOLD}
            disabled={mergingDisabled}
            onClick={onMergeClick}
            testId={testIds.INITIATE_MERGE_ENTITIES_BUTTON}
        >
            {strings.buttons.mergeEntities}
        </Button>
    );

    let content;
    let contentMessage;

    if (consortiumDepartmentLinksAvailable) {
        contentMessage = strings.notifications.mergingDisabledConsortium;
    } else if (mergingDisabledExceedMergeProfileLimit) {
        contentMessage = strings.notifications.mergingDisabledNonConsortium;
    } else if (chainMergingDisable) {
        contentMessage = strings.notifications.vehicleDisabledChainOfEntity(custodyLabel);
    }

    if (mergingDisabled) {
        content = (
            <Tooltip hasButtonOffset side="top" content={contentMessage}>
                <StyledDiv>{button}</StyledDiv>
            </Tooltip>
        );
    } else {
        content = button;
    }

    return <SearchActionBar>{content}</SearchActionBar>;
}

import { LinkTypesEnum } from '@mark43/rms-api';
import {
    CAUTIONS,
    CORE_DETAILS,
    CONTACT_INFO,
    EVENT_SPECIFIC_INFO,
    MISC_INFO,
} from '../config/personProfileFormSections';

export function getCollapsibleSectionConfigForLinkType(linkType) {
    let config;

    switch (linkType) {
        case LinkTypesEnum.SUSPECT_IN_OFFENSE:
        case LinkTypesEnum.SUSPECT_IN_REPORT:
        case LinkTypesEnum.OFFENDER_IN_OFFENSE:
        case LinkTypesEnum.DEFENDANT_IN_ARREST:
        case LinkTypesEnum.MISSING_PERSON_IN_REPORT:
        case LinkTypesEnum.VICTIM_IN_OFFENSE:
        case LinkTypesEnum.VICTIM_IN_REPORT:
        case LinkTypesEnum.REPORTING_PARTY_IN_REPORT:
        case LinkTypesEnum.WITNESS_IN_REPORT:
        default: {
            config = {
                [CAUTIONS]: true,
                [CORE_DETAILS]: true,
                [CONTACT_INFO]: true,
                [EVENT_SPECIFIC_INFO]: true,
                [MISC_INFO]: true,
            };
        }
    }
    return config;
}

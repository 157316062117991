import { reduce, omit, map, startsWith, isNaN, parseInt, sortBy, groupBy, find } from 'lodash';
import {
    isGroupedOptions,
    GroupBase,
    OptionTypeBase,
    SelectCustomEventDetail,
    SelectChangeEvent,
} from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { adminListStatus as dateStatuses } from '../../../../legacy-redux/configs/adminConfig';
import { RMSOptionTypeBase } from '../types';

const strings = componentStrings.forms.select.SelectOptions;

const { EXPIRED } = dateStatuses;

export interface SelectOption extends RMSOptionTypeBase, OptionTypeBase {
    status?: keyof typeof dateStatuses;
    subtitle?: string;
    none?: boolean;
}

export type LegacySelectOption = {
    value: string | number | boolean;
    display: string;
    group?: string;
    noteDisplay?: string;
};

type OptionsType<Option extends SelectOption | LegacySelectOption> = Option[];

export type GroupedOption<Option extends SelectOption> = GroupBase<Option>;
export type SelectOptions<Option extends SelectOption> = OptionsType<Option>;
export type GroupedOptions<Option extends SelectOption> = GroupedOption<Option>[];

export type LegacySelectOptions<Option extends LegacySelectOption> = OptionsType<Option>;

export type AttributeOption = {
    label: string;
    value: number;
};

/**
 * Map options of the type `LegacySelectOption` to something
 * that looks like a `SelectOption`
 */
interface LegacySelectOptionAsSelectOption<Option extends LegacySelectOption> extends SelectOption {
    label: Option['display'];
    value: Option['value'];
}

/**
 * If either a `SelectOption` or a `LegacySelectOption`
 * is passed here, it will return the original `SelectOption`
 * or a transformed `LegacySelectOption` that looks like a `SelectOption`
 */
export type SelectOptionOrLegacySelectOptionAsSelectOption<
    Option extends LegacySelectOption | SelectOption | GroupedOption<SelectOption>
> = Option extends LegacySelectOption
    ? LegacySelectOptionAsSelectOption<Option>
    : Option extends SelectOption
    ? Option
    : Option extends GroupedOption<infer R>
    ? R
    : never;

/**
 * Type guard to determine if we are working with a `LegacySelectOption`
 */
function isLegacySelectOption(
    option: LegacySelectOption | SelectOption | GroupedOption<SelectOption> | undefined
): option is LegacySelectOption {
    return !!option && 'display' in option;
}

/**
 * Type guard to determine if we are working with a `SelectOption`
 */
function isSelectOption(
    option: LegacySelectOption | SelectOption | GroupedOption<SelectOption> | undefined
): option is SelectOption | GroupedOption<SelectOption> {
    return !!option && 'label' in option;
}

/**
 * Type guard to determine if we are working with `LegacySelectOptions`
 */
export function areLegacyOptions(
    options?:
        | LegacySelectOptions<LegacySelectOption>
        | SelectOptions<SelectOption>
        | GroupedOptions<SelectOption>
): options is LegacySelectOptions<LegacySelectOption> {
    const firstOption = options?.[0];
    return isLegacySelectOption(firstOption);
}

/**
 * Type guard to determine if we are working with `SelectOptions`
 */
export function areSelectOptions(
    options?:
        | LegacySelectOptions<LegacySelectOption>
        | SelectOptions<SelectOption>
        | GroupedOptions<SelectOption>
): options is SelectOptions<SelectOption> | GroupedOptions<SelectOption> {
    const firstOption = options?.[0];
    return isSelectOption(firstOption);
}

const getRelevanceSorting = (query: string) => {
    return ({ label }: SelectOption) =>
        startsWith((label || '').toLowerCase(), (query || '').toLowerCase()) ? 0 : 1;
};

function getSortings<Option extends SelectOption>(query?: string) {
    return [
        // - first sort by manual order if exists
        // - then sort by non-expired
        // - then sort by prefix
        // - then alphabetical sort by display
        // NOTE: Looks like most selects don't pass
        // this as an option
        // ({ order }) => order,
        ({ status }: Option) => (status === EXPIRED ? 2 : 1),
        ...(query ? [getRelevanceSorting(query)] : []),
        ({ label }: Option) => (isNaN(parseInt(label)) ? label : parseInt(label)),
    ];
}

export function sortOptions<Option extends SelectOption>(
    options: SelectOptions<Option> | GroupedOptions<Option> | undefined,
    query?: string
) {
    if (options) {
        if (isGroupedOptions(options)) {
            // first sort the top level groups alphabetically
            const optionsSortedByTopLevel = sortBy(options, 'label');
            // next sort the children via `getSortings`
            return map(optionsSortedByTopLevel, (groupedOption) => {
                return {
                    ...groupedOption,
                    options: sortBy(groupedOption.options, getSortings(query)),
                };
            });
        } else {
            // This is specifically to preserve legacy functionality
            // If this is not a groupedOption, and there is no query,
            // return the options as-is (unsorted)
            if (!query) {
                return options;
            }
            return sortBy(options, getSortings(query)) as SelectOptions<Option>;
        }
    } else {
        return [];
    }
}

function convertOption<Option extends LegacySelectOption>(option: Option) {
    const convertedOption = {
        ...omit(option, ['display', 'noteDisplay', 'group']),
        label: option.display,
        subtitle: option.noteDisplay,
    };
    return convertedOption;
}

export function convertLegacySelectOptionsToNewOptions<Option extends LegacySelectOption>(
    legacySelectOptions: LegacySelectOptions<Option>,
    grouped: boolean
) {
    if (!legacySelectOptions) {
        return [];
    }

    const options = map(legacySelectOptions, convertOption);
    const legacyOptionsAreGrouped = grouped && !!legacySelectOptions?.[0]?.group;

    // This does not support `option.isGrouping` because
    // there is only one use-case for this and we are
    // getting rid of it
    if (legacyOptionsAreGrouped) {
        return reduce(
            groupBy(legacySelectOptions, (option) => option.group || strings.noGroup),
            (acc, options, groupName) => {
                acc.push({
                    label: groupName,
                    options: map(options, convertOption),
                });
                return acc;
            },
            [] as GroupedOption<LegacySelectOptionAsSelectOption<Option>>[]
        ) as GroupedOptions<LegacySelectOptionAsSelectOption<Option>>;
    }

    return options as SelectOptions<LegacySelectOptionAsSelectOption<Option>>;
}

/**
 * This logic gets run for all selects, as it is used in the
 * core Select.tsx component
 *
 * However, it only affects multi-selects, by transforming
 * their value in two ways:
 *
 * - if one of the selected options has the special `none` property,
 * we should ignore all the other options that were selected
 * and just use the `none` one
 * - ensure that the value set is an array (this is neccessary
 * for markformythree `required` validations to work properly)
 */
export function processChangeValue<Option extends SelectOption, Multi extends boolean>({
    selectedOptionsData,
    selectedValuesData: values,
    multiple,
}: {
    selectedOptionsData: SelectCustomEventDetail<Option>['selectedOptionsData'];
    selectedValuesData: SelectChangeEvent<Option, Multi>['target']['value'];
    multiple?: Multi;
}): Multi extends true ? Option['value'][] : Option['value'] {
    if (Array.isArray(values)) {
        const firstNoneOption = find(selectedOptionsData, (option) => !!option.none);
        // @ts-expect-error This type error occurs for the following 2 reasons.
        // Type '(string | number | boolean)[]' is not assignable to type 'Multi extends true ? Option["value"][] : Option["value"]'.
        // 1. The return type depends on whether `Multi` is true or false, but TS does not narrow conditional return
        //    types. https://github.com/microsoft/TypeScript/issues/33912
        //    As opposed to both `Option['value'][] | Option['value']`, it's safer for the return type to become
        //    conditional.
        // 2. We have historically not checked `multiple` in this `if` condition. `multiple` should always be true when
        //    `values` is an array. TODO is to add `multiple` to this `if` condition as long as it's fully tested.
        return firstNoneOption ? [firstNoneOption.value] : values;
    } else if (values === null && multiple) {
        // @ts-expect-error Same as above, the error is:
        // Type 'Option["value"][]' is not assignable to type 'Multi extends true ? Option["value"][] : Option["value"]'.
        return [] as Option['value'][];
    } else {
        return values;
    }
}

import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

import _AsyncDropdownMenu from '../../../../core/components/AsyncDropdownMenu';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.core.header.RecordsHeaderHamburgerMenu;

const AsyncDropdownMenu = styled(_AsyncDropdownMenu)`
    margin: 0;

    .dropdown-menu-button {
        width: 48px;
    }
`;

const AsyncDropdownMenuButtonContainer = styled.div`
    display: flex;
`;

const AsyncRecordsHeaderHamburgerMenu = React.forwardRef(
    ({ dropdownMenuOptionWidth, fetchOptions, mapResultOptions }, ref) => {
        return (
            <AsyncDropdownMenu
                buttonContent={
                    <AsyncDropdownMenuButtonContainer>
                        <Icon size={16} type={iconTypes.MORE_OPTIONS} />
                        <Icon size={16} type={iconTypes.TRIANGLE_DOWN} />
                    </AsyncDropdownMenuButtonContainer>
                }
                width={dropdownMenuOptionWidth}
                fetchOptions={fetchOptions}
                mapResultOptions={mapResultOptions}
                testId={testIds.RECORD_HAMBURGER_MENU}
                ref={ref}
                tooltip={strings.tooltip}
            />
        );
    }
);

export default AsyncRecordsHeaderHamburgerMenu;

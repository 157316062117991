import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { flowRight, map, noop, slice } from 'lodash';
import { ElasticCase } from '@mark43/rms-api';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { elasticCasesViewModelForElasticCasesSelector } from '~/client-common/core/domain/elastic-cases/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import personalDashboardResource from '../resources/personalDashboardResource';
import CaseNumberNameCell from '../../cases/core/components/CaseNumberNameCell';
import YourInvolvementCell from '../../cases/core/components/YourInvolvementCell';
import CaseApprovalStatusCell from '../../cases/core/components/CaseApprovalStatusCell';
import CaseStatusCell from '../../cases/all-cases/components/CaseStatusCell';
import createElement from '../../core/utils/recompose.createElement';
import { MY_CASES_INTERVAL } from '../configuration';
import testIds from '../../../core/testIds';

import Link from '../../core/components/links/Link';
import { Button } from '../../core/components/Button';
import { NoDataBlock, ShowMoreButton, DashboardCard } from './DashboardSection';
import { DashboardTable, DashboardTd, DashboardTh, DashboardTr } from './DashboardTable';
import { DashboardRightCardsLoader } from './DashboardLoaders';

const strings = componentStrings.personalDashboard.MyCases;

type MyCasesContentProps = {
    hideLoadingBar: boolean;
    elasticCasesViewModelForElasticCases: ReturnType<
        typeof elasticCasesViewModelForElasticCasesSelector
    >;
};

type DataCase = {
    id: string;
    case: ElasticCase;
    handleClick: typeof noop;
};

const columnStyles = {
    name: { minWidth: '13rem' },
    involvement: { minWidth: '8rem' },
    status: { minWidth: '12rem' },
    approvalStatus: { minWidth: '6rem', maxWidth: '6rem' },
};

const HeaderRow = () => {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    return (
        <DashboardTr>
            <DashboardTh style={columnStyles.name}>
                {strings.caseNumberCaseName(caseDisplayName)}
            </DashboardTh>
            <DashboardTh style={columnStyles.involvement}>{strings.yourInvolvement}</DashboardTh>
            <DashboardTh style={columnStyles.status}>
                {strings.caseStatus(caseDisplayName)}
            </DashboardTh>
            <DashboardTh style={columnStyles.approvalStatus}>{strings.approvalStatus}</DashboardTh>
        </DashboardTr>
    );
};

const BodyRow: React.FC<{
    data: ElasticCase;
}> = ({ data }) => {
    return (
        <DashboardTr as={Link} to={`/cases/${data.id}/summary`} isInteractive>
            {/** createElement is used here because data has viewModel props and
             * CaseNumberNameCell, CaseStatusCell accepts elasticCase as the only argument.
             * As a result the viewModel parts gets removed when passing data with a spread opertaor.
             * With createElement work around data is past as a whole */}
            <DashboardTd style={columnStyles.name}>
                {createElement(CaseNumberNameCell, { ...data })}
            </DashboardTd>
            <DashboardTd style={columnStyles.involvement}>
                <YourInvolvementCell {...data} />
            </DashboardTd>
            <DashboardTd style={columnStyles.status}>
                {createElement(CaseStatusCell, { ...data })}
            </DashboardTd>
            <DashboardTd style={columnStyles.approvalStatus}>
                <CaseApprovalStatusCell {...data} />
            </DashboardTd>
        </DashboardTr>
    );
};

const _MyCasesContent: React.FC<MyCasesContentProps> = ({
    elasticCasesViewModelForElasticCases,
    hideLoadingBar,
}) => {
    const { pluralCaseFieldName: casesDisplayName } = useCaseFieldName();
    const [cases, setCases] = useState<
        ReturnType<ReturnType<typeof elasticCasesViewModelForElasticCasesSelector>>
    >([]);
    const [maxCaseNumToShow, setCasesToShow] = useState<number>(MY_CASES_INTERVAL);

    const loadMyCases = useCallback(
        () => personalDashboardResource.getUserCases({ hideLoadingBar }),
        [hideLoadingBar]
    );
    const onResourceSuccess = useCallback(
        (result: ElasticCase[]) => {
            const elasticCaseViewModels = elasticCasesViewModelForElasticCases(result);
            setCases(elasticCaseViewModels);
        },
        [elasticCasesViewModelForElasticCases]
    );

    const { callResource, loading } = useResourceDeferred(loadMyCases, onResourceSuccess);
    useEffect(() => {
        callResource();
    }, [callResource]);

    if (loading.isLoading && cases.length === 0) {
        return <DashboardRightCardsLoader />;
    }

    if (loading.errorMessage) {
        return <NoDataBlock>{strings.loadError(casesDisplayName)}</NoDataBlock>;
    }

    if (cases.length <= 0) {
        return <NoDataBlock>{strings.noResults(casesDisplayName)}</NoDataBlock>;
    }

    const renderBodyRow = (item: DataCase) => {
        return <BodyRow key={item?.id} data={item?.case} />;
    };

    const displayedCases = slice(cases, maxCaseNumToShow - MY_CASES_INTERVAL, maxCaseNumToShow);

    const data = map(displayedCases, (item) => ({
        id: item.caseNumber,
        case: item,
        handleClick: noop,
    }));

    return (
        <div>
            <DashboardTable
                as="div"
                data={data}
                renderHeader={() => <HeaderRow />}
                renderRow={renderBodyRow}
            />
            {maxCaseNumToShow < cases.length && (
                <ShowMoreButton
                    onClick={() => {
                        setCasesToShow(maxCaseNumToShow + MY_CASES_INTERVAL);
                    }}
                >
                    {strings.showMore}
                </ShowMoreButton>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    elasticCasesViewModelForElasticCases: elasticCasesViewModelForElasticCasesSelector,
});

const MyCasesContent = flowRight(withRouter, connect(mapStateToProps, {}))(_MyCasesContent);

export const DraggableMyDashboardCases: React.FC<{
    dragHandleProps: DraggableProvidedDragHandleProps;
    isDragging: boolean;
}> = ({ dragHandleProps, isDragging }) => {
    const { pluralCaseFieldName: casesDisplayName } = useCaseFieldName();

    return (
        <DashboardCard
            data-test-id={testIds.PERSONAL_DASHBOARD_MY_CASES_SECTION}
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
            actions={
                <Button isTextTransformNone variant="ghost" asLink to="/cases/mine">
                    {strings.viewAll}
                </Button>
            }
            title={strings.title(casesDisplayName)}
        >
            <MyCasesContent hideLoadingBar={true} />
        </DashboardCard>
    );
};

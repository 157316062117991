import { ExternalReportStatus } from '@mark43/rms-api';
import { NEXUS_STATE_PROP } from '~/client-common/core/domain/external-report-statuses/state/data';
import externalReportStatusResource from '../../resources/externalReportStatusResource';
import { RmsAction } from '../../../../../core/typings/redux';

const LOAD_EXTERNAL_REPORT_STATUS_FOR_REN_START =
    'external-report-statuses/LOAD_EXTERNAL_REPORT_STATUS_FOR_REN_START';
const LOAD_EXTERNAL_REPORT_STATUS_FOR_REN_SUCCESS =
    'external-report-statuses/LOAD_EXTERNAL_REPORT_STATUS_FOR_REN_SUCCESS';
const LOAD_EXTERNAL_REPORT_STATUS_FOR_REN_FAILURE =
    'external-report-statuses/LOAD_EXTERNAL_REPORT_STATUS_FOR_REN_FAILURE';

const SAVE_EXTERNAL_REPORT_STATUS_FOR_REN_START =
    'external-report-statuses/SAVE_EXTERNAL_REPORT_STATUS_FOR_REN_START';
const SAVE_EXTERNAL_REPORT_STATUS_FOR_REN_SUCCESS =
    'external-report-statuses/SAVE_EXTERNAL_REPORT_STATUS_FOR_REN_SUCCESS';
const SAVE_EXTERNAL_REPORT_STATUS_FOR_REN_FAILURE =
    'external-report-statuses/SAVE_EXTERNAL_REPORT_STATUS_FOR_REN_FAILURE';

// ACTIONS
function loadExternalReportStatusForReportingEventIdStart() {
    return {
        type: LOAD_EXTERNAL_REPORT_STATUS_FOR_REN_START,
    };
}
function loadExternalReportStatusForReportingEventIdSuccess(
    externalReportStatus: ExternalReportStatus
) {
    return {
        type: LOAD_EXTERNAL_REPORT_STATUS_FOR_REN_SUCCESS,
        payload: externalReportStatus,
    };
}
function loadExternalReportStatusForReportingEventIdFailure(errorMessage: string) {
    return {
        type: LOAD_EXTERNAL_REPORT_STATUS_FOR_REN_FAILURE,
        payload: errorMessage,
    };
}

function saveExternalReportStatusStart(externalReportStatus: ExternalReportStatus) {
    return {
        type: SAVE_EXTERNAL_REPORT_STATUS_FOR_REN_START,
        payload: externalReportStatus,
    };
}
function saveExternalReportStatusSuccess(externalReportStatus: ExternalReportStatus) {
    return {
        type: SAVE_EXTERNAL_REPORT_STATUS_FOR_REN_SUCCESS,
        payload: externalReportStatus,
    };
}
function saveExternalReportStatusFailure(errorMessage: string) {
    return {
        type: SAVE_EXTERNAL_REPORT_STATUS_FOR_REN_FAILURE,
        payload: errorMessage,
    };
}

export function loadExternalReportStatusForReportingEventId(
    reportingEventId: number
): RmsAction<Promise<ExternalReportStatus>> {
    return (dispatch, _getState, { nexus }) => {
        dispatch(loadExternalReportStatusForReportingEventIdStart());
        return externalReportStatusResource
            .getExternalReportStatusForReportingEventId(reportingEventId)
            .then((externalReportStatus) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: [externalReportStatus],
                        },
                        loadExternalReportStatusForReportingEventIdSuccess(externalReportStatus)
                    )
                );
                return externalReportStatus;
            })
            .catch((err) => {
                dispatch(loadExternalReportStatusForReportingEventIdFailure(err.message));
                throw err;
            });
    };
}

export function saveExternalReportStatus(
    externalReportStatus: ExternalReportStatus
): RmsAction<Promise<ExternalReportStatus>> {
    return (dispatch, _getState, { nexus }) => {
        dispatch(saveExternalReportStatusStart(externalReportStatus));

        return externalReportStatusResource
            .upsertExternalReportStatus(externalReportStatus)
            .then((externalReportStatus) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: [externalReportStatus],
                        },
                        saveExternalReportStatusSuccess(externalReportStatus)
                    )
                );
                return externalReportStatus;
            })
            .catch((err) => {
                dispatch(saveExternalReportStatusFailure(err.message));
                throw err;
            });
    };
}

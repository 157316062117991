import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import type { Editor } from 'tinymce';

import { InlineCommentStatusEnum } from '@mark43/rms-api';
import { reportInlineCommentsViewSelector } from '~/client-common/core/domain/inline-report-comments/state/data';

import { parseReportCommentIds } from '../../../../core/editor/utils/tinyMCEConversations';
import { InlineComment } from '../../../../core/editor/plugins/inline-comments/components/InlineComment';

const RightSideWrapper = styled.div`
    position: absolute;
    right: -310px;
    top: -36px;
`;

const InlineCommentsWrapper = styled.div`
    width: 315px;
`;

/**
 * Inline comments which are displayed in a sidebar to the right of the TinyMCE editor.
 * The editor must have initialized before rendering this component.
 *
 * By default, the Tiny Comments plugin displays its own comment boxes.
 * https://www.tiny.cloud/docs/tinymce/6/introduction-to-tiny-comments/
 * We disable that functionality and render our own React component for these reasons:
 * - The Tiny Comments plugin only supports showing one comment at a time, but we need to show all comments at the same
 *   time.
 * - We need to customize the UI of the comment box, but the Tiny Comments plugin does not expose any API to do so, and
 *   has no React components. We would have to modify the HTML, which should be avoided because it's not a reactive
 *   pattern.
 */
const TinyInlineComments: React.FC<{
    reportId: number;
    selectedCommentId: number;
    editorRef: React.MutableRefObject<Editor | null>;
}> = ({ reportId, selectedCommentId, editorRef }) => {
    const editor = editorRef.current;
    const commentIds = editor ? parseReportCommentIds(editor) : [];
    const reportInlineCommentViews = useSelector(reportInlineCommentsViewSelector);

    const [top, setTop] = React.useState<number>(0);
    const wrapperRef = React.useRef<HTMLDivElement | null>(null);
    const [
        selectedCommentElement,
        setSelectedCommentElement,
    ] = React.useState<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (editor && wrapperRef.current && selectedCommentElement) {
            // the number of pixels between the selected text (which is a portion of the highlighted text) and the top of the editor
            const top = editor.selection.getBoundingClientRect().top;
            // the number of pixels between the selected comment and the top of the wrapper in the right sidebar
            const offset = selectedCommentElement.offsetTop;
            setTop(top - offset);
        }
    }, [editor, selectedCommentElement]);

    return (
        <RightSideWrapper ref={wrapperRef} style={{ top }}>
            <InlineCommentsWrapper>
                {commentIds.map((id) => {
                    const reportInlineCommentView = find(
                        reportInlineCommentViews,
                        (view) =>
                            view.id === id &&
                            view.comment.reportId === reportId &&
                            view.comment.status !== InlineCommentStatusEnum.RESOLVED.name
                    );

                    if (reportInlineCommentView) {
                        return (
                            <InlineComment
                                key={id}
                                data={reportInlineCommentView.comment}
                                reportId={reportInlineCommentView.comment.reportId}
                                isFocused={selectedCommentId === id}
                                ref={
                                    selectedCommentId === id ? setSelectedCommentElement : undefined
                                }
                            />
                        );
                    } else {
                        // it is possible for the editor to contain references to comments which have already been resolved
                        return null;
                    }
                })}
            </InlineCommentsWrapper>
        </RightSideWrapper>
    );
};

export default TinyInlineComments;

import { isArray, map, isPlainObject, reduce, pick } from 'lodash';

// the base props supported by the location query
const baseQueryProps = [
    'ids',
    'subPremise',
    'subdivisionAttrIds',
    'subdivisionAttrDetail',
    'subdivision1AttrIds',
    'subdivision1AttrDetail',
    'subdivision2AttrIds',
    'subdivision2AttrDetail',
    'subdivision3AttrIds',
    'subdivision3AttrDetail',
    'subdivision4AttrIds',
    'subdivision4AttrDetail',
    'subdivision5AttrIds',
    'subdivision5AttrDetail',
    'type',
    'categoryAttrIds',
    'categoryAttrDetail',
    'subPremiseDtos',
];

// props that need to be moved into the `locationAddress` object
const locationAddressProps = [
    'streetAddress',
    'locality',
    'crossStreet1',
    'crossStreet2',
    'administrativeAreaLevel1',
    'country',
    'postalCode',
    'rangeName',
    'neighborhood',
    'geoPolygonQuery',
    'geoRadiusQuery',
];

const convertLocationQuerytoInvolvedLocationQuery = (location) => ({
    ...pick(location, baseQueryProps),
    locationAddress: pick(location, locationAddressProps),
});

const recursivelyConvertElasticLocationQueries = (
    value,
    propNameOptions,
    converter = convertLocationQuerytoInvolvedLocationQuery
) => {
    if (isArray(value)) {
        return map(value, (val) =>
            recursivelyConvertElasticLocationQueries(val, propNameOptions, converter)
        );
    } else if (isPlainObject(value)) {
        return reduce(
            value,
            (acc, value, key) => {
                const isLegacyLocation = key === propNameOptions.legacyQueryProp;
                const newKey = isLegacyLocation ? propNameOptions.newQueryProp : key;
                let newValue;
                if (isLegacyLocation) {
                    newValue = Array.isArray(value) ? value.map(converter) : converter(value);
                } else {
                    newValue = recursivelyConvertElasticLocationQueries(
                        value,
                        propNameOptions,
                        converter
                    );
                }
                acc[newKey] = newValue;
                return acc;
            },
            {}
        );
    }

    return value;
};

/**
 * Converts an `ElasticLocationQuery` shape into `ElasticInvolvedLocationQuery`
 * @param  {object}  elasticQuery  The query to convert
 * @param  {object}  options
 * @return {object}
 */
export default recursivelyConvertElasticLocationQueries;

const convertInvolvedLocationQueryToLocationQuery = (location) => ({
    ...location,
    ...location.locationAddress,
});

export const recursivelyConvertElasticInvolvedLocationsToInvolvedLocations = (
    value,
    propNameOptions
) =>
    recursivelyConvertElasticLocationQueries(
        value,
        propNameOptions,
        convertInvolvedLocationQueryToLocationQuery
    );

import React, { useContext } from 'react';

type TestEnvironment = 'E2E' | 'COMPONENT' | 'NONE';
const E2ETestingContext = React.createContext<TestEnvironment>('NONE');

export function E2ETestingContextProvider(
    props: React.PropsWithChildren<{ testEnvironment?: TestEnvironment }>
) {
    return (
        <E2ETestingContext.Provider
            value={props.testEnvironment ?? 'NONE'}
            children={props.children}
        />
    );
}

function useE2ETestingContext(): TestEnvironment {
    const value = useContext(E2ETestingContext);

    if (value === undefined) {
        throw new Error('E2E testing context must be nested under `E2ETestingContextProvider`');
    }

    return value;
}

export function useIsE2ETestEnvironment(): boolean {
    return useE2ETestingContext() === 'E2E';
}

export function useIsComponentTestEnvironment(): boolean {
    return useE2ETestingContext() === 'COMPONENT';
}

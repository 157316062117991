import React from 'react';
import { Motion, spring } from 'react-motion';
import { profileHeaderSpringParameters } from '../../configuration';

export default function HeaderSecondaryInfoSection({ children, collapsed }) {
    return (
        <Motion
            style={{
                maxHeight: spring(collapsed ? 0 : 100, profileHeaderSpringParameters),
                marginTop: spring(collapsed ? 0 : 7, profileHeaderSpringParameters),
                opacity: spring(collapsed ? 0 : 100, profileHeaderSpringParameters),
            }}
        >
            {({ maxHeight, marginTop, opacity }) => (
                <div
                    className="secondary-info-section"
                    style={{
                        maxHeight,
                        marginTop,
                        opacity,
                    }}
                >
                    {children}
                </div>
            )}
        </Motion>
    );
}

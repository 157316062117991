import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    NEXUS_STATE_PROP as REL_NEXUS_STATE_PROP,
    reportExternalLinksWhereSelector,
} from '~/client-common/core/domain/report-external-links/state/data';
import {
    NEXUS_STATE_PROP as RRL_NEXUS_STATE_PROP,
    reportReportLinksByReportIdSelector,
    storeReportReportLinks,
} from '~/client-common/core/domain/report-report-links/state/data';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLE_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import getAssociatedRecordsResource from '~/client-common/core/domain/reports/resources/associatedRecordsResource';

import { createModalSelector } from '../../../../core/box/state/ui';
import { openBox, closeBox, saveBoxFailure } from '../../../../../legacy-redux/actions/boxActions';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import { openConfirmationBar } from '../../../../core/confirmation-bar/state/ui';
import {
    fillPrevAssociatedRecords,
    submitForm,
    resetFormState,
    addExternalAssociatedRecord,
    addInternalAssociatedRecord,
} from '../forms/associatedRecordsForm';

const context = {
    name: boxEnum.ASSOCIATED_RECORDS_SIDE_PANEL,
};

export function openAssociatedRecordsSidePanel(reportId) {
    return (dispatch) => {
        dispatch(openBox(context, { reportId }));
        dispatch(prefillForm(reportId));
    };
}
function closeAssociatedRecordsSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(context));
    };
}

export const associatedRecordsSidePanelReportIdSelector = createModalSelector(context, 'reportId');
export const associatedRecordsSidePanelIsOpenSelector = createModalSelector(context, 'isOpen');

export function addExternalAssociatedRecordToForm(sourceId, options) {
    return (dispatch, getState) => {
        const reportId = associatedRecordsSidePanelReportIdSelector(getState());
        addExternalAssociatedRecord(sourceId, reportId, options);
    };
}

export function addInternalAssociatedRecordToForm(elasticReport) {
    return (dispatch, getState) => {
        const reportId = associatedRecordsSidePanelReportIdSelector(getState());
        const currentDepartmentId = currentUserDepartmentIdSelector(getState());
        addInternalAssociatedRecord(elasticReport, reportId, currentDepartmentId);
    };
}

function prefillForm() {
    return (dispatch, getState) => {
        const state = getState();
        const reportId = associatedRecordsSidePanelReportIdSelector(getState());
        const reportReportLinks = reportReportLinksByReportIdSelector(state)(reportId);
        const reportExternalLinks = reportExternalLinksWhereSelector(state)({ reportId });
        fillPrevAssociatedRecords(reportReportLinks, reportExternalLinks);
    };
}

export function handleSubmit() {
    return (dispatch, getState, dependencies) => {
        const reportId = associatedRecordsSidePanelReportIdSelector(getState());
        submitForm(reportId)
            .then((associatedRecords) => {
                const action = { type: 'ASSOCIATED_RECORDS_SAVE' };
                const removeExternalLinks = dependencies.nexus.withRemove(
                    'reportExternalLinks',
                    {
                        reportId,
                    },
                    action
                );
                const removeReportLinks = dependencies.nexus.withRemove(
                    'reportReportLinks',
                    ({ fromReportId, toReportId }) =>
                        fromReportId === reportId || toReportId === reportId,
                    removeExternalLinks
                );
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [REL_NEXUS_STATE_PROP]: associatedRecords[REL_NEXUS_STATE_PROP],
                            [RRL_NEXUS_STATE_PROP]: associatedRecords[RRL_NEXUS_STATE_PROP],
                            [REPORT_SHORT_TITLE_NEXUS_STATE_PROP]:
                                associatedRecords[REPORT_SHORT_TITLE_NEXUS_STATE_PROP],
                        },
                        removeReportLinks
                    )
                );
                dispatch(closeAssociatedRecordsSidePanel());
                resetFormState();
            })
            .catch(({ validationResult }) => {
                dispatch(saveBoxFailure(context, validationResult.formErrors));
            });
    };
}

export function handleCancel() {
    return (dispatch) => {
        dispatch(closeAssociatedRecordsSidePanel());
        resetFormState();
    };
}

export function saveReportReportLink(reportId, reportReportLink) {
    return (dispatch) => {
        const reverseReportReportLink = {
            toReportId: reportReportLink.fromReportId,
            fromReportId: reportReportLink.toReportId,
            departmentId: reportReportLink.toDepartmentId,
            toDepartmentId: reportReportLink.departmentId,
            reasonForAssociationAttrId: reportReportLink.reasonForAssociationAttrId,
            reasonForAssociationOther: reportReportLink.reasonForAssociationOther,
        };
        return getAssociatedRecordsResource()
            .createAssociationForReport(reportId, reverseReportReportLink)
            .then((reportReportLink) => {
                dispatch(
                    openConfirmationBar({
                        message: 'Associated Record Saved',
                    })
                );
                dispatch(storeReportReportLinks(reportReportLink));
                return reportReportLink;
            });
    };
}

import { compact, filter, get, map, size } from 'lodash';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { offenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/data';

export const getCountOffenseCodesRequiresAdditionalCrimeCharge = (getState) => (offenseCodeIds) => {
    if (isUndefinedOrNull(offenseCodeIds) || size(offenseCodeIds) === 0) {
        return 0;
    }

    const offenseCodeIdCastedValues = compact(
        map(offenseCodeIds, (offenseCodeId) => get(offenseCodeId, 'castedValue'))
    );
    const offenseCodeById = offenseCodeByIdSelector(getState());
    return size(
        filter(offenseCodeIdCastedValues, (offenseCodeIdCastedValue) => {
            return !!get(
                offenseCodeById(offenseCodeIdCastedValue),
                'requiresAdditionalCrimeCharge'
            );
        })
    );
};

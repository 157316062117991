// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'reports';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Impound Resource',
        methods: {
            updateImpound(impound) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${impound.reportId}/impound`,
                    data: impound,
                });
            },
        },
    });
}

/**
 * Resources for Impounds. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { DeliveryMethodEnum, EntityTypeEnum } from '@mark43/notifications-api';
import { chain, has, map, noop, size, uniqBy, filter } from 'lodash';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { attachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';
import { loadAttachmentsByEntityId } from '~/client-common/core/domain/attachments/state/data';
import {
    storeUserNotifications,
    POLL_FOR_USER_NOTIFICATIONS_SUCCESS,
} from '~/client-common/core/domain/user-notifications/state/data';
import { createUserNotificationViewModelsSelector } from '~/client-common/core/domain/user-notifications/state/ui';
import getUserNotificationsResource from '~/client-common/core/domain/user-notifications/resources/userNotificationsResource';
import { saveCurrentUserOptOut } from '~/client-common/core/domain/user-opt-outs/state/data';
import { buildHashKeyForSearchQuery } from '~/client-common/helpers/searchHelpers';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import { currentUserIdSelector } from '../../../../core/current-user/state/ui';
import { queryParamDefaults } from '../../configuration';

const defaultQuery = {
    from: queryParamDefaults.FROM,
    size: queryParamDefaults.SIZE,
};

function createWebAppResultsViewModelsSelector(baseSelector) {
    const userNotificationSelector = createUserNotificationViewModelsSelector(baseSelector, {
        normalize: false,
    });
    return createSelector(userNotificationSelector, (userNotifications) =>
        filter(userNotifications, { deliveryMethod: DeliveryMethodEnum.WEB_APP.name })
    );
}

function createAlertResultsViewModelsSelector(baseSelector) {
    const userNotificationSelector = createUserNotificationViewModelsSelector(baseSelector, {
        normalize: false,
    });
    return createSelector(userNotificationSelector, (userNotifications) =>
        filter(userNotifications, { deliveryMethod: DeliveryMethodEnum.ALERT.name })
    );
}

/**
 * Search module for the notifications inbox. User notifications are stored in
 *   both data state and this ui search result state. There is no actual search
 *   functionality as of 2017-08-10; this hits a regular endpoint and not an
 *   Elasticsearch endpoint. A notifications search feature is planned for the
 *   future and that may make more use of this search module.
 * @type {Object}
 */
export const notificationsInbox = createSearchModule({
    elasticSearchType: 'NOTIFICATION',
    baseUiSelector: (state) => state.ui.notifications.dashboard.inboxSearch,
    defaultTableState: defaultQuery,
    resourceMethod: (elasticQuery, from, size, sortKey, sortType, dispatch) => {
        const resource = getUserNotificationsResource();
        const query = {
            includeRead: true,
            includeUnread: true,
            includeAcknowledged: true,
            includeAlerts: false,
            includeArchived: false,
            includeWeb: true,
            from,
            size,
        };
        return resource.getCurrentUserNotifications(query).then(({ notifications, totalCount }) => {
            dispatch(storeUserNotifications(notifications));
            return {
                items: notifications,
                query,
                totalCount,
            };
        });
    },
    createResultsViewModelsSelector: createWebAppResultsViewModelsSelector,
});

/**
 * Search module for the notifications archive. User notifications are stored in
 *   both data state and this ui search result state. There is no actual search
 *   functionality as of 2017-08-10 and this merely uses the search module to
 *   display paginated results. This is separate from `notificationsInbox`
 *   because the INBOX tab and the ARCHIVE tab display different results.
 * @type {Object}
 */
export const notificationsArchive = createSearchModule({
    elasticSearchType: 'ARCHIVED_NOTIFICATION',
    baseUiSelector: (state) => state.ui.notifications.dashboard.archiveSearch,
    defaultTableState: defaultQuery,
    resourceMethod: (elasticQuery, from, size, sortKey, sortType, dispatch) => {
        const resource = getUserNotificationsResource();
        const query = {
            includeRead: false,
            includeUnread: false,
            includeAcknowledged: false,
            includeAlerts: false,
            includeArchived: true,
            includeWeb: true,
            from,
            size,
        };
        return resource.getCurrentUserNotifications(query).then(({ notifications, totalCount }) => {
            dispatch(storeUserNotifications(notifications));
            return {
                items: notifications,
                query,
                totalCount,
            };
        });
    },
    createResultsViewModelsSelector: createWebAppResultsViewModelsSelector,
});

/**
 * Search module for notifications alerts
 * @type {Object}
 */
export const notificationsAlerts = createSearchModule({
    elasticSearchType: 'ALERT_NOTIFICATION',
    baseUiSelector: (state) => state.ui.notifications.dashboard.alerts,
    defaultTableState: defaultQuery,
    resourceMethod: (elasticQuery, from, size, sortKey, sortType, dispatch) => {
        const resource = getUserNotificationsResource();
        const query = {
            includeRead: true,
            includeUnread: true,
            includeAcknowledged: true,
            includeAlerts: true,
            includeArchived: false,
            includeWeb: false,
            from,
            size,
        };
        return resource.getCurrentUserNotifications(query).then(({ notifications, totalCount }) => {
            dispatch(storeUserNotifications(notifications));

            return {
                items: notifications,
                query,
                totalCount,
            };
        });
    },
    createResultsViewModelsSelector: createAlertResultsViewModelsSelector,
});

/**
 * Action dispatcher that fetches both notifications alerts and their respective attachments.
 * Due to BE performance concerns, merging attachments in the backend was less effective and is now done in the front end
 * @param query
 */
export const getNotificationsAlertsAndAttachments = (query) => (dispatch, getState) => {
    return dispatch(notificationsAlerts.actionCreators.search(query, { cacheBust: true })).then(
        (result) => {
            chain(getState())
                .thru(notificationsAlerts.selectors.currentResultsViewModelsSelector)
                .map(({ notificationId }) => notificationId)
                .thru((notificationIds) =>
                    size(notificationIds)
                        ? dispatch(
                              loadAttachmentsByEntityId(
                                  EntityTypeEnum.NOTIFICATION.name,
                                  notificationIds
                              )
                          ).catch(noop)
                        : null
                )
                .value();
            return result;
        }
    );
};

/**
 * Selector for getting notifications alerts and respective attachments
 * @type {Array}
 */
export const getNotificationsAlertsAndAttachmentsSelector = createSelector(
    notificationsAlerts.selectors.currentResultsViewModelsSelector,
    attachmentViewModelsWhereSelector,
    (results, attachmentsWhere) => {
        const alertsAndAttachments = map(results, ({ notificationId, dataBlob, ...props }) => ({
            ...dataBlob,
            ...props,
            attachments: attachmentsWhere({
                entityType: EntityTypeEnum.NOTIFICATION.name,
                entityId: notificationId,
            }),
            notificationId,
        }));
        return alertsAndAttachments;
    }
);

const firstPageHashKey = buildHashKeyForSearchQuery(defaultQuery);

function searchUiReducer(reducer) {
    return (state, action) => {
        state = reducer(state, action);

        switch (action.type) {
            case POLL_FOR_USER_NOTIFICATIONS_SUCCESS:
                // this state is populated only after the user has navigated to the
                // notifications page
                if (has(state, ['results', firstPageHashKey])) {
                    return {
                        ...state,
                        results: {
                            ...state.results,
                            // prepend new notifications to page 1
                            [firstPageHashKey]: uniqBy(
                                [
                                    ...action.payload.notifications,
                                    ...state.results[firstPageHashKey],
                                ],
                                'userNotificationLinkId'
                            ),
                        },
                    };
                }
                return state;
            default:
                return state;
        }
    };
}

export function unsubscribeNotificationTypeForCurrentUser(notificationType, deliveryMethod) {
    return (dispatch, getState) => {
        const userId = currentUserIdSelector(getState());
        const optOut = {
            notificationType,
            deliveryMethod,
            userId,
        };

        return dispatch(saveCurrentUserOptOut(optOut));
    };
}

/**
 * UI reducer for the notifications dashboard, separated by inbox and archive.
 *
 * Each search reducer extends the base reducer from the search module. Whenever
 *   a polling request succeeds, prepend the new notifications to page 1 of
 *   notifications (with no search filters applied). This makes new
 *   notifications automatically appear when the user is currently viewing page
 *   1; if they are not, then the next time they navigate to page 1, the new
 *   notifications will appear anyway.
 *
 * Problems: Page 1 state will be inaccurate if the user receives more than
 *   NUMBER_OF_NOTIFICATIONS_FOR_POPOVER notifications since the previous poll.
 *   If the user has search filters applied and the new notifications belong in
 *   them, they won't automatically appear.
 */
export default combineReducers({
    inboxSearch: searchUiReducer(notificationsInbox.reducers.uiReducer),
    archiveSearch: notificationsArchive.reducers.uiReducer,
    alerts: searchUiReducer(notificationsAlerts.reducers.uiReducer),
});

/* eslint eqeqeq:0 */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { OptionsArray as Mark43Options } from '../../../../../legacy-redux/helpers/Mark43PropTypes';
import Text from '../Text';
import CheckboxTree, { CheckboxTreeGroup, CheckboxTreeWrapper } from './CheckboxTree';

const CheckboxListWrapper = styled.div`
    position: relative;

    /* the CheckboxList component contains CheckboxTree components */
    ${/* sc-selector */ CheckboxTreeWrapper} {
        padding: 0;

        ${/* sc-selector */ CheckboxTreeGroup} {
            width: 100%;
            padding: 15px 0;
            margin: 0;
            border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
            cursor: pointer;
            padding-left: 10px;

            &:hover {
                background-color: ${(props) => props.theme.colors.extraLightGrey};
            }

            &.CHECKED {
                background-color: ${(props) => props.theme.colors.brightBlue};

                .form-label {
                    color: ${(props) => props.theme.colors.white};
                }
            }
        }
    }
`;

const CheckboxListSearchWrapper = styled.div`
    .checkbox-list-search-wrapper {
        background-color: $brightBlue;
        padding: 15px;
        overflow: auto;
        border-bottom: 1px solid $lightGrey;
    }
`;

class CheckboxList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: null,
            value: props.value || [],
            displayOptions: props.options,
        };
    }

    _displayOptions(query) {
        return _(this.props.options)
            .filter((option) => {
                query = query && query.toLowerCase();

                if (!query) {
                    return true;
                }

                return _.includes(option.display.toLowerCase(), query);
            })
            .value();
    }

    _handleChange(value) {
        this.setState({ value });
        this.props.onChange(value);
    }

    _handleSearchChange(query) {
        this.setState({
            query,
            displayOptions: this._displayOptions(query),
        });
    }

    render() {
        return (
            <CheckboxListWrapper>
                <CheckboxListSearchWrapper>
                    <Text
                        ref="myInput" // eslint-disable-line
                        value={this.state.query}
                        placeholder={this.props.placeholderText}
                        onChange={this._handleSearchChange.bind(this)}
                        textInputOnly={true}
                    />
                </CheckboxListSearchWrapper>
                <CheckboxTree
                    value={this.state.value}
                    options={this.state.displayOptions}
                    onChange={this._handleChange.bind(this)}
                />
            </CheckboxListWrapper>
        );
    }
}
CheckboxList.propTypes = {
    value: PropTypes.array,
    options: Mark43Options,
    placeholderText: PropTypes.string,
    onChange: PropTypes.func,
};

export default CheckboxList;

import React from 'react';
import { parseInt } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatReportTitleForReportIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import Page from '../../../core/components/Page';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import Subheader from '../../../core/components/Subheader';
import chargeVacatingForm from '../state/forms/chargeVacatingForm';
import {
    submitChargeVacatingForm,
    cleanupVactingInfoReport,
    chargeVacatingChargesLoadingStateSelector,
    chargeVacatingIsSubmittingSelector,
} from '../state/ui';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { updateUploads } from '../../../attachments/core/state/ui/inlineAttachmentsUploader';
import ChargeVacatingForm from './ChargeVacatingForm';
import ChargeVacatingActionBar from './ChargeVacatingActionBar';

class ChargeVacatingDashboard extends React.Component {
    componentWillUnmount() {
        this.props.resetState();
    }

    render() {
        const {
            routeParams,
            onFormSubmit,
            chargesLoadingState,
            formatReportTitleForReportId,
            reportSealingIsSubmitting,
        } = this.props;
        const { reportId } = routeParams;
        const parsedReportId = parseInt(reportId);
        const backUrl = `/reports/${parsedReportId}`;
        const { isLoading, loadingError, reportLoaded } = chargesLoadingState;
        const title = reportLoaded ? formatReportTitleForReportId(parsedReportId) : '';

        return (
            <Page>
                <Subheader
                    title={componentStrings.recordPrivacy.vacating.ChargeVacatingDashboard.title(
                        title
                    )}
                    backButtonTo={backUrl}
                />
                <ScrollableUnderSubheader forceNoClassName={true}>
                    {isLoading && <SimpleLoading />}
                    {loadingError && <InlineBanner status="error">{loadingError}</InlineBanner>}
                    {reportLoaded && <ChargeVacatingForm reportId={parsedReportId} />}
                    {reportLoaded && (
                        <ChargeVacatingActionBar
                            saveButtonDisabled={reportSealingIsSubmitting}
                            onSaveClick={onFormSubmit}
                            backUrl={backUrl}
                        />
                    )}
                </ScrollableUnderSubheader>
            </Page>
        );
    }
}

export default connect(
    createStructuredSelector({
        formIsValid: chargeVacatingForm.selectors.formIsValidSelector,
        formModelByPath: chargeVacatingForm.selectors.formModelByPathSelector,
        chargesLoadingState: chargeVacatingChargesLoadingStateSelector,
        formatReportTitleForReportId: formatReportTitleForReportIdSelector,
        reportSealingIsSubmitting: chargeVacatingIsSubmittingSelector,
    }),
    (dispatch, props) => ({
        onFormSubmit: () => dispatch(submitChargeVacatingForm({ router: props.router })),
        resetState: () => {
            dispatch(chargeVacatingForm.actionCreators.reset());
            dispatch(cleanupVactingInfoReport());
            // reset attachment uploader
            dispatch(updateUploads([]));
        },
    })
)(ChargeVacatingDashboard);

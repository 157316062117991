import { ReportModuleResource, ReportModuleUpsertRequest } from '@mark43/rms-api';

import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';
import type { ReportModuleView } from '../state/data/reportModules';

export default createResource({
    name: 'Report Modules Resource',
    methods: {
        getReportModules(): Promise<ReportModuleView[]> {
            return req<ReportModuleResource.GetReportModuleViews>({
                method: 'GET',
                url: 'reports/modules',
            });
        },
        replaceReportModules(data: ReportModuleUpsertRequest[]): Promise<ReportModuleView[]> {
            return req<ReportModuleResource.ReplaceReportModulesForCurrentDepartment>({
                method: 'POST',
                url: 'reports/modules',
                data,
            });
        },
    },
});

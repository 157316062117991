import React from 'react';
import styled from 'styled-components';
import { max, get, keys } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { mediaQueries, Text, isOverflowHorizontal } from 'arc';

import withFields from '~/client-common/core/fields/components/withFields';
import { searchSizes } from '~/client-common/configs/advancedSearchConfig';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    REPORT_RECORD_NUMBER,
    REPORT_REPORTING_EVENT_NUMBER,
    EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID,
    LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_EVENT_LINK_TYPE,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
    DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import SearchSizeDropdownMenu from '../../../core/components/SearchSizeDropdownMenu';
import Pagination from '../../../../legacy-redux/components/core/Pagination';

import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';

import Tabs, { Tab } from '../../../../legacy-redux/components/core/Tabs';

import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import _ApprovalStatusIcon from '../../../records/core/components/ApprovalStatusIcon';

import ReportingEventNumberCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportingEventNumberCell';
import ReportOwnerCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportOwnerCell';
import ReportReviewersCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportReviewersCell';
import ReportAuthorsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportAuthorsCell';
import ArrestingOfficerCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ArrestingOfficerCell';
import PersonsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/PersonsCell';
import OffenseLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/OffenseLocationCell';
import { ReportTakenLocationCell } from '../../../../legacy-redux/components/core/tables/elasticLocationTable/PrimaryLocationCell';
import ArrestLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/ArrestLocationCell';
import EventLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/EventLocationCell';
import AgencyCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/AgencyCell';
import UnitsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/UnitsCell';
import ReportTypeCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportTypeCell';
import NarrativeCell from '../../../../legacy-redux/components/core/tables/elasticCaseTable/NarrativeCell';
import RoutingLabelsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/RoutingLabelsCell';
import { ConditionalTooltip } from '../../../core/components/tooltip';

import { useScreenBreakpoint } from '../../../core/utils/useScreenBreakpoint';
import {
    reportsDashboardSearch,
    submitReportsDashboardSearch,
    reportDashboardTabNameToCountSelector,
    setReportDashboardSearchClientApprovalStatus,
    currentReportDashboardSearchClientApprovalStatusSelector,
} from '../state/ui';
import { sortableColumnKeys } from '../state/ui/config';

const EventStartUtcCell = dateCellFactory('eventStartUtc');
const UpdatedDateUtcCell = dateCellFactory('updatedDateUtc');
const ReportCreationDateCell = dateCellFactory('reportCreationDateUtc');

const strings = componentStrings.reports.dashboard.ReportsDashboardSearchResults;

const ReportsDashboardSearchResultsContainer = styled.div`
    position: relative;
`;

const ResultsHeaderDiv = styled.div`
    position: absolute;
    right: 10px;
    top: 14px;
    margin-right: 10px;
`;

const TableResultsSummaryContainer = styled(TableResultsSummary)`
    display: inline;
    margin-right: 10px;
    padding-top: 7px;
`;

const ReportsDashTable = styled(Table)`
    overflow-x: auto;
    font-size: var(--arc-fontSizes-sm);
`;

const ReportsDashTabs = styled(Tabs)`
    background: ${(props) => props.theme.colors.white};
    padding-top: 28px;
    .mark43-tab-links {
        margin-top: 20px;
        display: flex;
    }

    @media (min-width: ${mediaQueries.md}) {
        padding: 28px 20px 0 20px;
    }
`;

const Footer = styled.div`
    background: ${(props) => props.theme.colors.white};
    padding: 50px 25px 64px 0;
    text-align: right;
`;

const ApprovalStatusIcon = styled(_ApprovalStatusIcon)`
    @media (min-width: ${mediaQueries.sm}) {
        margin-right: var(--arc-space-1);
    }
`;

const CustomTab = styled.div`
    &&& {
        display: flex;
        flex-shrink: 0;
        gap: var(--arc-space-1);
        min-width: 0;
        ${({ selected }) => {
            let styles = `color: var(--arc-colors-text-primary);
                          padding: 11px 13px 8px;
                          margin-right: 4px;
                          border-top: 2px solid;
                          border-right: 2px solid;
                          border-left: 2px solid;
                          border-color: transparent;`;
            if (selected) {
                styles = `
                    ${styles}
                    color: var(--arc-colors-selected-content);
                    background-color: var(--arc-colors-brand-default);
                    border-color: var(--arc-colors-brand-default);

                    > * path {
                        fill: var(--arc-colors-brand-content);
                    }
                `;
            }
            return styles;
        }};
    }
`;

const StyledText = styled(Text)`
    ${(props) =>
        props.$shouldTruncate === true &&
        `
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `}
`;

const CustomTabWithTooltip = (props) => {
    // Use this to only truncate and shrink titles longer than 20 characters.
    let longTitleFlag = false;
    if (props.title.length > 20) {
        longTitleFlag = true;
    }
    const textRef = React.useRef();
    const [isTruncated, setIsTruncated] = React.useState(false);
    const { isMobileSm, isMobile } = useScreenBreakpoint();

    return (
        <ConditionalTooltip condition={isTruncated || isMobile} content={props.title}>
            <CustomTab
                onMouseOver={() => {
                    if (textRef.current) {
                        setIsTruncated(isOverflowHorizontal(textRef?.current));
                    }
                }}
                data-test-id={props.testId}
                onClick={props.onClick}
                className={props.className}
                selected={props.selected}
                style={longTitleFlag ? { flexShrink: '1' } : undefined}
            >
                <ApprovalStatusIcon approvalStatus={props.clientApprovalStatus} />
                {!isMobile && (
                    <StyledText
                        color="inherit"
                        align="left"
                        ref={textRef}
                        $shouldTruncate={longTitleFlag}
                    >
                        {props.title}
                    </StyledText>
                )}
                {!isMobileSm && <Text color="inherit"> {props.tabCount}</Text>}
            </CustomTab>
        </ConditionalTooltip>
    );
};

const ReportsDashboardSearchResults = ({
    formatFieldByName,
    applicationSettings,
    results,
    totalCount,
    query: { from, size, sortKey: currentSortKey, sortType: currentSortType },
    activeColumnKeys, // type is ActiveColumnKeys
    tabNameToCount,
    handleRowClick,
    handleSizeChange,
    handlePaginationClick,
    handleDateOptionClick,
    handleEntityOptionClick,
    handleLocationOptionClick,
    handleSortTypeClick,
    fieldDisplayNames,
    handleTabClick,
    currentReportClientApprovalStatus,
}) => {
    const offenseDisplayName = useOffenseFieldName();
    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const recordsWithoutARenEnabled = applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;

    const resultsTable = (
        <ReportsDashTable
            data={results}
            sortKey={currentSortKey}
            sortType={currentSortType}
            noRowsText={strings.noResults}
            rowComponent={ElasticResultRow}
            onRowClick={handleRowClick}
        >
            <TableHeader>
                <TableColumns>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeColumnKeys.date}
                        activeSortType={
                            currentSortKey === sortableColumnKeys[activeColumnKeys.date]?.sortKey
                                ? currentSortType
                                : undefined
                        }
                        activeValue={sortableColumnKeys[activeColumnKeys.date]?.sortKey}
                        onOptionClick={handleDateOptionClick}
                        onSortTypeClick={handleSortTypeClick}
                        width={132}
                        popoutWidth={160}
                    >
                        <OptionsTableColumnOption
                            display={strings.columns.eventDate.label}
                            value={sortKeyEnum.REPORT_EVENT_START_UTC}
                            columnKey="eventStartUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.eventDate.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.eventDate.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.modifiedDate.label}
                            value={sortKeyEnum.REPORT_UPDATED_DATE_UTC}
                            columnKey="updatedDateUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.modifiedDate.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.modifiedDate.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.creationDate.label}
                            value={sortKeyEnum.REPORT_REPORT_CREATION_DATE_UTC}
                            columnKey="reportCreationDateUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.creationDate.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.creationDate.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                    </OptionsTableColumn>
                    <OptionsTableColumn
                        columnKey="reportingEventNumber"
                        display={({ display }) => display}
                        activeValue={sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER}
                        activeSortType={
                            currentSortKey === sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER
                                ? currentSortType
                                : undefined
                        }
                        onSortTypeClick={handleSortTypeClick}
                        width={132}
                        popoutWidth={160}
                    >
                        <OptionsTableColumnOption
                            display={
                                recordsWithoutARenEnabled
                                    ? `${formatFieldByName(
                                          REPORT_REPORTING_EVENT_NUMBER
                                      )} / ${formatFieldByName(REPORT_RECORD_NUMBER)}`
                                    : formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
                            }
                            value={sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER}
                            columnKey="reportingEventNumber"
                            sortOptions={[
                                {
                                    display: strings.columns.ren.ascendingSort,
                                    sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                },
                                {
                                    display: strings.columns.ren.descendingSort,
                                    sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                },
                            ]}
                        />
                    </OptionsTableColumn>
                    <TableColumn
                        display={strings.columns.reportType.label}
                        columnKey="reportType"
                        width={132}
                    />
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeColumnKeys.entity}
                        activeValue={activeColumnKeys.entity}
                        onOptionClick={handleEntityOptionClick}
                        width={132}
                        popoutWidth={160}
                    >
                        <OptionsTableColumnOption
                            display={formatFieldByName(DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL)}
                            value="reportOwner"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.reviewers.label}
                            value="reviewers"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.authors.label}
                            value="authors"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.arrestingOfficer.label}
                            value="arrestingOfficer"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.involvedPersons.label}
                            value="persons"
                        />
                    </OptionsTableColumn>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeColumnKeys.location}
                        activeValue={activeColumnKeys.location}
                        onOptionClick={handleLocationOptionClick}
                        width={132}
                        popoutWidth={170}
                    >
                        <OptionsTableColumnOption
                            display={strings.columns.offenseLocation.label(offenseDisplayName)}
                            value="offenseLocation"
                        />
                        <OptionsTableColumnOption
                            display={
                                formatFieldByName(
                                    LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_EVENT_LINK_TYPE
                                ) || strings.columns.reportTakenLocation.label
                            }
                            value="locations"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.arrestLocation.label}
                            value="arrestLocation"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.eventLocation.label}
                            value="eventLocation"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.agency.label}
                            value="agency"
                        />
                        <OptionsTableColumnOption
                            display={formatFieldByName(EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID)}
                            value="units"
                        />
                    </OptionsTableColumn>
                    <TableColumn
                        display={strings.columns.narrative.label}
                        columnKey="narrative"
                        width={132}
                    />
                    <TableColumn
                        display={strings.columns.routingLabels.label}
                        columnKey="routingLabels"
                        width={132}
                    />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <EventStartUtcCell columnKey="eventStartUtc" />
                <UpdatedDateUtcCell columnKey="updatedDateUtc" />
                <ReportCreationDateCell columnKey="reportCreationDateUtc" />
                <ReportingEventNumberCell
                    columnKey="reportingEventNumber"
                    handleRenLinkClick={() => {
                        return; // empty function to make use of comp propogation to trigger scroll to elem
                    }}
                />
                <ReportOwnerCell columnKey="reportOwner" />
                <ReportReviewersCell columnKey="reviewers" />
                <ReportAuthorsCell columnKey="authors" />
                <ArrestingOfficerCell columnKey="arrestingOfficer" />
                <PersonsCell columnKey="persons" />
                <OffenseLocationCell columnKey="offenseLocation" />
                <ReportTakenLocationCell columnKey="locations" />
                <ArrestLocationCell columnKey="arrestLocation" />
                <EventLocationCell columnKey="eventLocation" />
                <AgencyCell columnKey="agency" />
                <UnitsCell columnKey="units" />
                <ReportTypeCell columnKey="reportType" />
                <NarrativeCell columnKey="narrative" />
                <RoutingLabelsCell columnKey="routingLabels" supportSyncStatusEnabled={true} />
            </TableBody>
        </ReportsDashTable>
    );

    const createTab = (key, approvalStatusEnum) => {
        const tabCount =
            approvalStatusEnum === approvalStatusClientEnum.COMPLETED
                ? undefined
                : `(${get(tabNameToCount, approvalStatusEnum, '0')})`;
        return (
            <Tab
                clientApprovalStatus={approvalStatusEnum}
                title={key}
                tabKey={approvalStatusEnum}
                tabCount={tabCount}
            >
                {resultsTable}
            </Tab>
        );
    };

    return (
        <ReportsDashboardSearchResultsContainer>
            <ResultsHeaderDiv>
                <TableResultsSummaryContainer
                    from={from}
                    to={from + keys(results).length}
                    totalResults={totalCount}
                    caption=""
                />
                <SearchSizeDropdownMenu sizes={searchSizes} onChange={handleSizeChange} />
            </ResultsHeaderDiv>

            <ReportsDashTabs
                className="mark43-nav-tabs"
                selectedTab={currentReportClientApprovalStatus}
                onSelectTab={handleTabClick}
                renderTabLink={({
                    testId,
                    key,
                    onClick,
                    className,
                    title,
                    clientApprovalStatus,
                    selected,
                    tabCount,
                }) => (
                    <CustomTabWithTooltip
                        testId={testId}
                        key={key}
                        onClick={onClick}
                        className={className}
                        title={title}
                        clientApprovalStatus={clientApprovalStatus}
                        selected={selected}
                        tabCount={tabCount}
                    />
                )}
            >
                {createTab(
                    fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
                    approvalStatusClientEnum.DRAFT
                )}
                {createTab(
                    fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
                    approvalStatusClientEnum.REJECTED
                )}
                {createTab(
                    fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
                    approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW
                )}
                {createTab(
                    fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
                    approvalStatusClientEnum.PENDING_SECONDARY_REVIEW
                )}
                {createTab(
                    fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
                    approvalStatusClientEnum.COMPLETED
                )}
            </ReportsDashTabs>
            {totalCount > 0 && (
                <Footer>
                    <Pagination
                        currentPage={currentPage}
                        itemsPerPage={size}
                        itemCount={totalCount}
                        onClick={(lastPage, nextPage) => handlePaginationClick(nextPage, size)}
                        maxEdgeItems={1}
                    />
                </Footer>
            )}
        </ReportsDashboardSearchResultsContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    query: reportsDashboardSearch.selectors.currentQuerySelector,
    results: reportsDashboardSearch.selectors.currentResultsViewModelsSelector,
    formatFieldByName: formatFieldByNameSelector,
    applicationSettings: applicationSettingsSelector,
    totalCount: reportsDashboardSearch.selectors.totalCountSelector,
    activeColumnKeys: reportsDashboardSearch.selectors.activeColumnKeysSelector,
    currentReportClientApprovalStatus: currentReportDashboardSearchClientApprovalStatusSelector,
    tabNameToCount: reportDashboardTabNameToCountSelector,
});

const mapDispatchToProps = (
    dispatch,
    { router, getCurrentScrollPosition, setScrollToElement }
) => ({
    handleTabClick(reportStatus) {
        dispatch(setReportDashboardSearchClientApprovalStatus(reportStatus));
        dispatch(submitReportsDashboardSearch());
    },
    handleRowClick(elasticReport, rowIndex) {
        dispatch(
            reportsDashboardSearch.actionCreators.openSearchResult(
                elasticReport,
                rowIndex,
                router,
                getCurrentScrollPosition()
            )
        );
        dispatch(reportsDashboardSearch.actionCreators.setTriggerScroll(true));
    },
    handleDateOptionClick({ columnKey, value, sortOptions }) {
        dispatch(reportsDashboardSearch.actionCreators.setActiveColumnKey('date', columnKey));
        dispatch(
            reportsDashboardSearch.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleSortTypeClick(sortType, activeOption) {
        dispatch(
            reportsDashboardSearch.actionCreators.search({
                sortKey: activeOption.value,
                sortType,
            })
        );
    },
    handleEntityOptionClick({ value }) {
        dispatch(reportsDashboardSearch.actionCreators.setActiveColumnKey('entity', value));
    },
    handleLocationOptionClick({ value }) {
        dispatch(reportsDashboardSearch.actionCreators.setActiveColumnKey('location', value));
    },
    handleSizeChange(size) {
        dispatch(reportsDashboardSearch.actionCreators.search({ from: 0, size }));
    },
    handlePaginationClick(nextPage, size) {
        setScrollToElement(false);
        dispatch(
            reportsDashboardSearch.actionCreators.search({ from: nextPage * size - size, size })
        );
    },
});

export default compose(
    withRouter,
    withFields([
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
    ]),
    connect(mapStateToProps, mapDispatchToProps)
)(ReportsDashboardSearchResults);

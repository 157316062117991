import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import Row from '../../components/Row';
import { ArbiterMFTNItems } from './NItems';
import { ArbiterMFTText } from './Text';

const mapStateToProps = createStructuredSelector({
    formatAttributeById: formatAttributeByIdSelector,
});

export const ArbiterMFTAttributeTextFields = connect(mapStateToProps)(function AttributeTextFields({
    path,
    formatAttributeById,
}) {
    return (
        <ArbiterMFTNItems
            path={path}
            addItemOnEmpty={false}
            hideAddButtonOnEmptyItem={true}
            automaticallyIncludeDeleteButton={false}
        >
            {(linkAttribute) => (
                <Row>
                    <ArbiterMFTText
                        label={formatAttributeById(linkAttribute.attributeId)}
                        width={300}
                        path="description"
                    />
                </Row>
            )}
        </ArbiterMFTNItems>
    );
});

import { AttributeTypeEnum, RoleTypeEnum } from '@mark43/rms-api';
import React, { useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import accountStatusEnum from '~/client-common/core/enums/client/accountStatusEnum';

import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import Row from '../../../core/components/Row';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { RRFDutyStatusSelect } from '../../../core/forms/components/selects/DutyStatusSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFRoleSelect } from '../../../core/forms/components/selects/RoleSelect';
import { RRFAbilitySelect } from '../../../core/forms/components/selects/AbilitySelect';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import {
    currentUserHasAbilitySelector,
    currentUserIsSupportUserSelector,
} from '../../../core/current-user/state/ui';
import { SearchName } from '../../../search/core/components/SearchName';
import { SavedSearchDropdownButton } from '../../../search/saved-search/components/SavedSearchDropdownButton';

import {
    applyFiltersAndUpdateSearch,
    clearAllFilters,
    submitUsersDashboardSearchForm,
    usersDashboardSearch,
} from '../state/ui';

const context = {
    name: boxEnum.ADMIN_USERS_DASHBOARD_FILTER_MODAL,
};
const strings = componentStrings.admin.users.UsersDashboardSearchForm.filterModal;
const accountStatusStrings = componentStrings.admin.userProfile.fieldValues.accountStatus;

const standardRoleTypes = [RoleTypeEnum.NORMAL.name];
const supportUserRoleTypes = [RoleTypeEnum.SUPPORT.name, RoleTypeEnum.INTEGRATION.name];
const accountStatusOptions = [
    { value: accountStatusEnum.ACTIVE, display: accountStatusStrings.active },
    { value: accountStatusEnum.INACTIVE, display: accountStatusStrings.inactive },
];

const ButtonWrapper = styled.div`
    padding: 12px 10px 12px 0;
`;

function UsersDashboardFilterModal({
    applyFilterModal,
    clearAllFilters,
    currentUserIsSupportUser,
}) {
    const dispatch = useDispatch();
    const currentSavedSearch = useSelector(
        usersDashboardSearch.selectors.currentSavedSearchSelector
    );
    const updateSavedSearchButtonClickHandler = useCallback(
        () => dispatch(applyFiltersAndUpdateSearch()),
        [dispatch]
    );

    const displayedRoleTypes = currentUserIsSupportUser
        ? standardRoleTypes.concat(supportUserRoleTypes)
        : [...standardRoleTypes];

    return (
        <Modal
            additionalFooterContent={
                <ButtonWrapper>
                    <SavedSearchDropdownButton
                        searchModule={usersDashboardSearch}
                        tooltipContainer=".mark43-react-modal"
                        updateSavedSearchOverride={updateSavedSearchButtonClickHandler}
                    />
                </ButtonWrapper>
            }
            context={context}
            title={strings.title}
            onSave={applyFilterModal}
            onCancelOnly={clearAllFilters}
            okText={strings.okText}
            cancelText={strings.cancelText}
            shouldCloseOnOverlayClick={true}
            modalStyle={{ width: 550 }}
            contentStyle={{ height: 275 }}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={false} />
            <ReactReduxForm {...usersDashboardSearch.form}>
                <Row>
                    <RRFDutyStatusSelect
                        path="dutyStatuses"
                        label={strings.dutyStatuses}
                        width={230}
                        multiple={true}
                    />
                    <RRFAttributeSelect
                        path="rankAttrIds"
                        label={strings.rankAttrIds}
                        attributeType={AttributeTypeEnum.RANK.name}
                        width={230}
                        multiple={true}
                        includeExpired={true}
                    />
                </Row>
                <Row>
                    <RRFAttributeSelect
                        path="unitAttrIds"
                        label={strings.unitAttrIds}
                        attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                        width={230}
                        multiple={true}
                        includeExpired={true}
                    />
                    <RRFRoleSelect
                        multiple={true}
                        width={230}
                        label={strings.roleIds}
                        path="roleIds"
                        useExternalRoles={false}
                        roleTypes={displayedRoleTypes}
                        effectiveDate={null}
                    />
                </Row>
                <Row>
                    <RRFAbilitySelect
                        path="abilityIds"
                        label={strings.abilityIds}
                        width={230}
                        multiple={true}
                    />
                    <RRFAttributeSelect
                        path="employeeTypeAttrIds"
                        label={strings.employeeTypeAttrIds}
                        attributeType={AttributeTypeEnum.EMPLOYEE_TYPE.name}
                        width={230}
                        multiple={true}
                        includeExpired={true}
                    />
                </Row>
                <Row>
                    <RRFSelect
                        label={strings.accountStatus}
                        options={accountStatusOptions}
                        path="accountStatus"
                        width={230}
                    />
                </Row>
            </ReactReduxForm>
        </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUserHasAbility: currentUserHasAbilitySelector,
    currentUserIsSupportUser: currentUserIsSupportUserSelector,
});

const mapDispatchToProps = (dispatch) => ({
    applyFilterModal: () => dispatch(submitUsersDashboardSearchForm()),
    clearAllFilters: () => dispatch(clearAllFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersDashboardFilterModal);

import _ from 'lodash';
import { createSelector } from 'reselect';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { currentUserHasAbilitySelector } from '../../modules/core/current-user/state/ui';
import { locationByIdSelector } from './locationsSelectors';

const aliasesSelector = (state) => state.data.locationAliasesAdmin.aliases;
export const uiSelector = (state) => state.ui.locationAliasesAdmin;

export const aliasViewModelByIdSelector = createSelector(
    aliasesSelector,
    (aliases) => (locationAliasId) =>
        aliases[locationAliasId]
            ? {
                  ...aliases[locationAliasId],
              }
            : undefined
);

export const locationForLocationAliasSelector = createSelector(
    [aliasViewModelByIdSelector, locationByIdSelector],
    (aliasViewModelById, locationById) => (locationAliasId, queryLocationId) => {
        const locationAlias = aliasViewModelById(locationAliasId);
        const locationId = locationAlias ? locationAlias.locationId : queryLocationId;

        return locationById(locationId);
    }
);

export const aliasesListItemsForAliasIdSelector = createSelector(
    [aliasesSelector, aliasViewModelByIdSelector],
    (aliases, aliasViewModelById) => (locationAliasId) =>
        _(aliases)
            .map((alias) => ({
                path: `/admin/locationaliases/${alias.id}`,
                title: alias.alias,
                key: alias.id,
                selected: alias.id === _.get(aliasViewModelById(locationAliasId), 'id'),
            }))
            .sortBy('title')
            .value()
);

export const aliasByIdSelector = createSelector(aliasesSelector, (aliases) => (id) => aliases[id]);

export const hasLocationAliasImportSelector = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.ADMIN.LOCATION_ALIAS_IMPORT)
);

export const hasLocationAliasExportSelector = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.ADMIN.LOCATION_ALIAS_EXPORT)
);

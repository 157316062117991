import { UserRole } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import getUserRoleResource from '../../resources/userRoleResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'userRoles';

// TODO-CLIENT-COMMON-REFACTOR RMS should start moving to this file
const userRoleModule = createLinkModule<UserRole>({
    type: NEXUS_STATE_PROP,
    keys: ['roleId', 'userId'],
});

const LOAD_USER_ROLES_FOR_ROLE_START = 'user-roles/GET_USER_ROLE_FOR_ROLE_START';
const LOAD_USER_ROLES_FOR_ROLE_SUCCESS = 'user-roles/GET_USER_ROLE_FOR_ROLE_SUCCESS';
const LOAD_USER_ROLES_FOR_ROLE_FAILURE = 'user-roles/GET_USER_ROLE_FOR_ROLE_FAILURE';

const UPDATE_USER_ROLE_START = 'user-roles/UPDATE_USER_ROLE_START';
const UPDATE_USER_ROLE_SUCCESS = 'user-roles/UPDATE_USER_ROLE_SUCCESS';
const UPDATE_USER_ROLE_FAILURE = 'user-roles/UPDATE_USER_ROLE_FAILURE';

const DELETE_USER_FROM_ROLE_START = 'user-roles/DELETE_USER_FROM_ROLE_START';
const DELETE_USER_FROM_ROLE_SUCCESS = 'user-roles/DELETE_USER_FROM_ROLE_SUCCESS';
const DELETE_USER_FROM_ROLE_FAILURE = 'user-roles/DELETE_USER_FROM_ROLE_FAILURE';

function loadUserRolesForRoleStart(roleId: number) {
    return {
        type: LOAD_USER_ROLES_FOR_ROLE_START,
        payload: roleId,
    };
}

function loadUserRolesForRoleSuccess(userRoles: UserRole[]) {
    return {
        type: LOAD_USER_ROLES_FOR_ROLE_SUCCESS,
        payload: userRoles,
    };
}

function loadUserRolesForRoleFailure(message: string) {
    return {
        type: LOAD_USER_ROLES_FOR_ROLE_FAILURE,
        payload: message,
    };
}

function updateUserRoleStart(userRole: UserRole) {
    return {
        type: UPDATE_USER_ROLE_START,
        payload: userRole,
    };
}

function updateUserRoleSuccess(userRole: UserRole) {
    return {
        type: UPDATE_USER_ROLE_SUCCESS,
        payload: userRole,
    };
}

function updateUserRoleFailure(message: string) {
    return {
        type: UPDATE_USER_ROLE_FAILURE,
        payload: message,
    };
}

function deleteUserFromRoleStart() {
    return {
        type: DELETE_USER_FROM_ROLE_START,
    };
}

function deleteUserFromRoleSuccess(userRole: UserRole) {
    return {
        type: DELETE_USER_FROM_ROLE_SUCCESS,
        payload: userRole,
    };
}

function deleteUserFromRoleFailure(message: string) {
    return {
        type: DELETE_USER_FROM_ROLE_FAILURE,
        payload: message,
    };
}

/**
 * Loads all user roles for a given role.
 * @param id of the role you want to load UserRoles for.
 */
export function loadUserRolesForRole(roleId: number): ClientCommonAction<Promise<UserRole[]>> {
    return (dispatch) => {
        const resource = getUserRoleResource();
        dispatch(loadUserRolesForRoleStart(roleId));
        return resource
            .loadUserRolesForRole(roleId)
            .then((userRoles: UserRole[]) => {
                dispatch(storeUserRoles(userRoles));
                dispatch(loadUserRolesForRoleSuccess(userRoles));
                return userRoles;
            })
            .catch((err: Error) => {
                dispatch(loadUserRolesForRoleFailure(err.message));
                throw err;
            });
    };
}

/**
 * A generic PUT action for modifying a given UserRole.
 * @param   The UserRole that is to be modified (including
 *   those modifications).
 */
export function updateUserRole(userRole: UserRole): ClientCommonAction<Promise<UserRole>> {
    return (dispatch) => {
        const resource = getUserRoleResource();
        dispatch(updateUserRoleStart(userRole));
        return resource
            .updateUserRole(userRole)
            .then((userRole: UserRole) => {
                dispatch(storeUserRoles(userRole));
                dispatch(updateUserRoleSuccess(userRole));
                return userRole;
            })
            .catch((err: Error) => {
                dispatch(updateUserRoleFailure(err.message));
                throw err;
            });
    };
}

/**
 * Removes a user from a role by deleting a UserRole.
 */
export function deleteUserFromRole(userRole: UserRole): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        const resource = getUserRoleResource();
        dispatch(deleteUserFromRoleStart());
        return resource
            .deleteUserFromRole(userRole.roleId, userRole.userId)
            .then(() => {
                dispatch(
                    removeUserRolesWhere({ roleId: userRole.roleId, userId: userRole.userId })
                );
                dispatch(deleteUserFromRoleSuccess(userRole));
            })
            .catch((err: Error) => {
                dispatch(deleteUserFromRoleFailure(err.message));
                throw err;
            });
    };
}

// ACTIONS
export const storeUserRoles = userRoleModule.actionCreators.storeLinks;

export const removeUserRolesWhere = userRoleModule.actionCreators.removeLinksWhere;

// SELECTORS
export const userRolesSelector = userRoleModule.selectors.linksSelector;
export const userRolesWhereSelector = userRoleModule.selectors.linksWhereSelector;

// REDUCER
export default userRoleModule.reducerConfig;

import { ElasticReport } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'elasticReports';
const elasticReportsModule = createNormalizedModule<ElasticReport>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeElasticReports = elasticReportsModule.actionCreators.storeEntities;

// SELECTORS
export const elasticReportsSelector = elasticReportsModule.selectors.entitiesSelector;
export const elasticReportByIdSelector = elasticReportsModule.selectors.entityByIdSelector;

// REDUCER
export default elasticReportsModule.reducerConfig;

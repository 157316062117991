import _, { map, pick, compact, isNumber, filter } from 'lodash';
import {
    AttributeTypeEnum,
    AttributeTypeEnumType,
    CodeTypeCategoryEnum,
    CodeTypeCategoryEnumType,
    EntityTypeEnum,
    StopAnonymousSubject,
    StopEntityAttribute,
    RipaSubjectOffenseCode,
} from '@mark43/rms-api';
import { formDataIsEmpty } from '../../../../helpers/formHelpers';
import { filterStopEntityAttributes } from '../../stop-entity-attributes/utils/stopEntityAttributesHelpers';
import { filterRipaSubjectOffenseCodes } from '../../ripa-subject-offense-codes/utils/ripaSubjectOffenseCodesHelpers';

export const STOP_ANONYMOUS_SUBJECTS_PATH = 'stopAnonymousSubjects';
export const STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH = 'perceivedLgbt';
export const STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH =
    'perceivedLimitedEnglishFluency';
export const STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH = 'wasSearchConsented';
export const STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH =
    'wasPropertySearchConsented';
export const STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH = 'perceivedGenderAttrId';
export const STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH = 'isNonbinary';
export const STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH = 'perceivedAge';
export const STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH = 'isStudent';
export const STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH = 'reasonForStopAttrId';
export const STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH = 'reasonForStopNarrative';
export const STOP_ANONYMOUS_SUBJECT_REASON_GIVEN_TO_STOPPED_PERSON_ATTR_ID_PATH =
    'reasonGivenToStoppedPerson';
export const STOP_ANONYMOUS_SUBJECT_IS_PERCEIVED_DISABILITY_PATH = 'isPerceivedDisability';
export const STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH = 'raceAttribute';
export const STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH = 'trafficViolationAttrId';
export const STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_CJIS_OFFENSE_CODE_PATH =
    'trafficViolationCJISOffenseCode';
export const STOP_ANONYMOUS_SUBJECT_SUSPICION_CJIS_OFFENSE_CODE_PATH = 'suspicionCJISOffenseCode';
export const STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH = 'suspicionSubType';
export const STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_CJIS_OFFENSE_CODE_PATH =
    'probableCauseCJISOffenseCode';
export const STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH = 'probableCauseType';
export const STOP_ANONYMOUS_SUBJECT_ACTIONS_TAKEN_PATH = 'actionsTaken';
export const STOP_ANONYMOUS_SUBJECT_REASON_FOR_SEARCH_PATH = 'reasonForSearch';
export const STOP_ANONYMOUS_SUBJECT_BASIS_FOR_PROPERTY_SEIZURE_PATH = 'basisForPropertySeizure';
export const STOP_ANONYMOUS_SUBJECT_TYPE_OF_PROPERTY_SEIZED_PATH = 'typeOfPropertySeized';
export const STOP_ANONYMOUS_SUBJECT_CONTRABAND_OR_EVIDENCE_PATH = 'contrabandOrEvidence';
export const STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH = 'resultOfStop';
export const STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH =
    'educationCodeSectionAttrId';
export const STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH =
    'educationCodeSubdivisionAttrId';
export const STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH = 'basisForSearchNarrative';
export const STOP_ANONYMOUS_SUBJECT_WARNING_CJIS_OFFENSE_CODE_PATH = 'warningCJISOffenseCode';
export const STOP_ANONYMOUS_SUBJECT_VERBAL_WARNING_CJIS_OFFENSE_CODE_PATH =
    'verbalWarningCJISOffenseCode';
export const STOP_ANONYMOUS_SUBJECT_WRITTEN_WARNING_CJIS_OFFENSE_CODE_PATH =
    'writtenWarningCJISOffenseCode';
export const STOP_ANONYMOUS_SUBJECT_CITATION_CJIS_OFFENSE_CODE_PATH = 'citationCJISOffenseCode';
export const STOP_ANONYMOUS_SUBJECT_IN_FIELD_CITE_RELEASE_CJIS_OFFENSE_CODE_PATH =
    'inFieldCiteReleaseCJISOffenseCode';
export const STOP_ANONYMOUS_SUBJECT_CUSTODIAL_ARREST_CJIS_OFFENSE_CODE_PATH =
    'custodialArrestCJISOffenseCode';
export const STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH = 'isUnhoused';
export const STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH = 'isPassenger';
export const STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH = 'isResidence';
export const STOP_ANONYMOUS_SUBJECT_NON_FORCIBLE_ACTION_TAKEN_PATH = 'nonForcibleActionTaken';
export const STOP_ANONYMOUS_SUBJECT_FORCIBLE_ACTION_TAKEN_PATH = 'forcibleActionTaken';
export const STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH = 'consentTypeAttrId';

interface StopAnonymousSubjectFormModel {
    id: number;
    [STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH]: boolean;
    [STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH]: boolean;
    [STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH]: boolean;
    [STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH]: boolean;
    [STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH]: number;
    [STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH]: boolean;
    [STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH]: number;
    [STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH]: boolean;
    [STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH]: number;
    [STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH]: string;
    [STOP_ANONYMOUS_SUBJECT_REASON_GIVEN_TO_STOPPED_PERSON_ATTR_ID_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_IS_PERCEIVED_DISABILITY_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH]: number;
    [STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_CJIS_OFFENSE_CODE_PATH]: number;
    [STOP_ANONYMOUS_SUBJECT_SUSPICION_CJIS_OFFENSE_CODE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_CJIS_OFFENSE_CODE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_ACTIONS_TAKEN_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_NON_FORCIBLE_ACTION_TAKEN_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_FORCIBLE_ACTION_TAKEN_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_REASON_FOR_SEARCH_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_BASIS_FOR_PROPERTY_SEIZURE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_TYPE_OF_PROPERTY_SEIZED_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_CONTRABAND_OR_EVIDENCE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH]: number;
    [STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH]: number;
    [STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH]: string;
    [STOP_ANONYMOUS_SUBJECT_WARNING_CJIS_OFFENSE_CODE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_VERBAL_WARNING_CJIS_OFFENSE_CODE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_WRITTEN_WARNING_CJIS_OFFENSE_CODE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_CITATION_CJIS_OFFENSE_CODE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_IN_FIELD_CITE_RELEASE_CJIS_OFFENSE_CODE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_CUSTODIAL_ARREST_CJIS_OFFENSE_CODE_PATH]: number[];
    [STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH]: boolean;
    [STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH]: boolean;
    [STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH]: boolean;
    [STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH]: number;
}

function buildStopEntityAttributeDataModel({
    attributeIds,
    stopId,
    attributeType,
    entityId,
}: {
    attributeIds: number[];
    stopId: number;
    attributeType: AttributeTypeEnumType;
    entityId: number;
}): Partial<StopEntityAttribute>[] {
    return map(attributeIds, (attributeId) => ({
        stopId,
        isPerson: true,
        attributeType,
        attributeId,
        entityType: EntityTypeEnum.STOP_ANONYMOUS_SUBJECT.name,
        entityId,
    }));
}

export function convertStopAnonymousSubjectsFormModelToStopEntityAttributeDataModel({
    stopAnonymousSubject,
    stopId,
}: {
    stopAnonymousSubject: StopAnonymousSubjectFormModel;
    stopId: number;
}): Partial<StopEntityAttribute>[] {
    return [
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_IS_PERCEIVED_DISABILITY_PATH],
            stopId,
            attributeType: AttributeTypeEnum.SUBJECT_DATA_DISABILITY.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH],
            stopId,
            attributeType: AttributeTypeEnum.SUBJECT_DATA_RACE.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH],
            stopId,
            attributeType: AttributeTypeEnum.STOP_SUSPICION_TYPE.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH],
            stopId,
            attributeType: AttributeTypeEnum.SUBJECT_DATA_PROBABLE_CAUSE.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds:
                stopAnonymousSubject[
                    STOP_ANONYMOUS_SUBJECT_REASON_GIVEN_TO_STOPPED_PERSON_ATTR_ID_PATH
                ],
            stopId,
            attributeType: AttributeTypeEnum.SUBJECT_DATA_REASON_GIVEN_TO_STOPPED_PERSON.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_ACTIONS_TAKEN_PATH],
            stopId,
            attributeType: AttributeTypeEnum.STOP_ACTION_TAKEN.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds:
                stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_NON_FORCIBLE_ACTION_TAKEN_PATH],
            stopId,
            attributeType: AttributeTypeEnum.STOP_NON_FORCIBLE_ACTION_TAKEN.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_FORCIBLE_ACTION_TAKEN_PATH],
            stopId,
            attributeType: AttributeTypeEnum.STOP_FORCIBLE_ACTION_TAKEN.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_REASON_FOR_SEARCH_PATH],
            stopId,
            attributeType: AttributeTypeEnum.REASON_FOR_SEARCH.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds:
                stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_BASIS_FOR_PROPERTY_SEIZURE_PATH],
            stopId,
            attributeType: AttributeTypeEnum.STOP_BASIS_FOR_PROPERTY_SEIZURE.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_TYPE_OF_PROPERTY_SEIZED_PATH],
            stopId,
            attributeType: AttributeTypeEnum.STOP_TYPE_OF_PROPERTY_SEIZED.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_CONTRABAND_OR_EVIDENCE_PATH],
            stopId,
            attributeType: AttributeTypeEnum.SUBJECT_DATA_CONTRABAND_OR_EVIDENCE.name,
        }),
        ...buildStopEntityAttributeDataModel({
            entityId: stopAnonymousSubject.id,
            attributeIds: stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH],
            stopId,
            attributeType: AttributeTypeEnum.SUBJECT_DATA_RESULT_OF_STOP.name,
        }),
    ];
}

function filterRipaSubjectOffenseCode({
    ripaSubjectOffenseCodes,
    code,
    stopId,
    anonymousSubjectId,
    ripaOffenseCodeId,
    codeTypeCategory,
}: {
    ripaSubjectOffenseCodes: RipaSubjectOffenseCode[] | undefined;
    code: string;
    stopId: number;
    anonymousSubjectId: number;
    ripaOffenseCodeId: number;
    codeTypeCategory: CodeTypeCategoryEnumType;
}): Partial<RipaSubjectOffenseCode> {
    const existRipaSubjectOffenseCode = filter(ripaSubjectOffenseCodes, {
        code,
        stopId,
        anonymousSubjectId,
        ripaOffenseCodeId,
        codeTypeCategory,
    });
    if (existRipaSubjectOffenseCode.length) {
        return existRipaSubjectOffenseCode[0];
    }
    return {
        stopId,
        ripaOffenseCodeId,
        codeTypeCategory,
        code,
        anonymousSubjectId,
    };
}

function buildRipaSubjectOffenseCodesDataModel({
    ripaOffenseCodeIds,
    stopId,
    codeTypeCategory,
    code,
    anonymousSubjectId,
    ripaSubjectOffenseCodes,
}: {
    ripaOffenseCodeIds: number[] | number;
    stopId: number;
    codeTypeCategory: CodeTypeCategoryEnumType;
    code: string;
    anonymousSubjectId: number;
    ripaSubjectOffenseCodes?: RipaSubjectOffenseCode[];
}): Partial<RipaSubjectOffenseCode>[] {
    if (isNumber(ripaOffenseCodeIds)) {
        return [
            filterRipaSubjectOffenseCode({
                ripaSubjectOffenseCodes,
                code,
                stopId,
                anonymousSubjectId,
                ripaOffenseCodeId: ripaOffenseCodeIds,
                codeTypeCategory,
            }),
        ];
    }
    const ripaSubjectOffenseCodesDataModel = map(ripaOffenseCodeIds, (ripaOffenseCodeId) => {
        return filterRipaSubjectOffenseCode({
            ripaSubjectOffenseCodes,
            code,
            stopId,
            anonymousSubjectId,
            ripaOffenseCodeId,
            codeTypeCategory,
        });
    });
    return ripaSubjectOffenseCodesDataModel;
}

export function convertStopAnonymousSubjectsFormModelToRipaSubjectOffenseCodesDataModel({
    stopId,
    stopAnonymousSubject,
    ripaSubjectOffenseCodes,
}: {
    stopId: number;
    stopAnonymousSubject: StopAnonymousSubjectFormModel;
    ripaSubjectOffenseCodes?: RipaSubjectOffenseCode[];
}): Partial<RipaSubjectOffenseCode>[] {
    return [
        ...buildRipaSubjectOffenseCodesDataModel({
            ripaSubjectOffenseCodes,
            ripaOffenseCodeIds:
                stopAnonymousSubject[
                    STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_CJIS_OFFENSE_CODE_PATH
                ],
            stopId,
            codeTypeCategory: CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
            code: '1',
            anonymousSubjectId: stopAnonymousSubject.id,
        }),
        ...buildRipaSubjectOffenseCodesDataModel({
            ripaSubjectOffenseCodes,
            ripaOffenseCodeIds:
                stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_WARNING_CJIS_OFFENSE_CODE_PATH],
            stopId,
            codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
            code: '2',
            anonymousSubjectId: stopAnonymousSubject.id,
        }),
        ...buildRipaSubjectOffenseCodesDataModel({
            ripaSubjectOffenseCodes,
            ripaOffenseCodeIds:
                stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_CITATION_CJIS_OFFENSE_CODE_PATH],
            stopId,
            codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
            code: '3',
            anonymousSubjectId: stopAnonymousSubject.id,
        }),
        ...buildRipaSubjectOffenseCodesDataModel({
            ripaSubjectOffenseCodes,
            ripaOffenseCodeIds:
                stopAnonymousSubject[
                    STOP_ANONYMOUS_SUBJECT_IN_FIELD_CITE_RELEASE_CJIS_OFFENSE_CODE_PATH
                ],
            stopId,
            codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
            code: '4',
            anonymousSubjectId: stopAnonymousSubject.id,
        }),
        ...buildRipaSubjectOffenseCodesDataModel({
            ripaSubjectOffenseCodes,
            ripaOffenseCodeIds:
                stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_SUSPICION_CJIS_OFFENSE_CODE_PATH],
            stopId,
            codeTypeCategory: CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
            code: '5',
            anonymousSubjectId: stopAnonymousSubject.id,
        }),
        ...buildRipaSubjectOffenseCodesDataModel({
            ripaSubjectOffenseCodes,
            ripaOffenseCodeIds:
                stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_CJIS_OFFENSE_CODE_PATH],
            stopId,
            codeTypeCategory: CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
            code: '7',
            anonymousSubjectId: stopAnonymousSubject.id,
        }),
        ...buildRipaSubjectOffenseCodesDataModel({
            ripaSubjectOffenseCodes,
            ripaOffenseCodeIds:
                stopAnonymousSubject[
                    STOP_ANONYMOUS_SUBJECT_CUSTODIAL_ARREST_CJIS_OFFENSE_CODE_PATH
                ],
            stopId,
            codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
            code: '6',
            anonymousSubjectId: stopAnonymousSubject.id,
        }),
        ...buildRipaSubjectOffenseCodesDataModel({
            ripaSubjectOffenseCodes,
            ripaOffenseCodeIds:
                stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_VERBAL_WARNING_CJIS_OFFENSE_CODE_PATH],
            stopId,
            codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
            code: '8',
            anonymousSubjectId: stopAnonymousSubject.id,
        }),
        ...buildRipaSubjectOffenseCodesDataModel({
            ripaSubjectOffenseCodes,
            ripaOffenseCodeIds:
                stopAnonymousSubject[STOP_ANONYMOUS_SUBJECT_WRITTEN_WARNING_CJIS_OFFENSE_CODE_PATH],
            stopId,
            codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
            code: '9',
            anonymousSubjectId: stopAnonymousSubject.id,
        }),
    ];
}

type DeepPartial<StopAnonymousSubject> = {
    [P in keyof StopAnonymousSubject]?: DeepPartial<StopAnonymousSubject[P]>;
};

export function convertStopAnonymousSubjectsFormModelToDataModel({
    stopAnonymousSubjects,
    stopId,
    reportId,
    ripaSubjectOffenseCodes,
}: {
    stopAnonymousSubjects: StopAnonymousSubjectFormModel[];
    stopId: number;
    reportId: number;
    ripaSubjectOffenseCodes: RipaSubjectOffenseCode[];
}): DeepPartial<StopAnonymousSubject>[] {
    return compact(
        map(stopAnonymousSubjects, (stopAnonymousSubject) => {
            if (formDataIsEmpty(stopAnonymousSubject)) {
                return null;
            }
            return {
                ...pick(stopAnonymousSubject, [
                    'id',
                    STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH,
                    STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH,
                    STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH,
                    STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH,
                    STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH,
                    STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH,
                    STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH,
                    STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH,
                    STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH,
                    STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH,
                    STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH,
                    STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH,
                    STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH,
                    STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH,
                    STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH,
                    STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH,
                    STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH,
                    STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH,
                ]),
                stopId,
                reportId,
                stopEntityAttributes: convertStopAnonymousSubjectsFormModelToStopEntityAttributeDataModel(
                    {
                        stopAnonymousSubject,
                        stopId,
                    }
                ),
                ripaSubjectOffenseCodes: convertStopAnonymousSubjectsFormModelToRipaSubjectOffenseCodesDataModel(
                    {
                        stopAnonymousSubject,
                        stopId,
                        ripaSubjectOffenseCodes,
                    }
                ),
            };
        })
    );
}

function filterStopEntityAttributeIds({
    stopEntityAttributes,
    attributeType,
}: {
    stopEntityAttributes: StopEntityAttribute[];
    attributeType: AttributeTypeEnumType;
}): number[] {
    return _(stopEntityAttributes).filter({ attributeType }).map('attributeId').value();
}

function filterRipaSubjectOffenseCodesIds({
    ripaSubjectOffenseCodes,
    codeTypeCategory,
    code,
}: {
    ripaSubjectOffenseCodes: RipaSubjectOffenseCode[];
    codeTypeCategory: CodeTypeCategoryEnumType;
    code: string;
}): number[] {
    return _(ripaSubjectOffenseCodes)
        .filter({ codeTypeCategory, code })
        .map('ripaOffenseCodeId')
        .value();
}

export function convertStopAnonymousSubjectsDataModelToFormModel({
    stopAnonymousSubjects,
    stopEntityAttributes,
    ripaSubjectOffenseCodes = [],
}: {
    stopAnonymousSubjects: StopAnonymousSubject[];
    stopEntityAttributes: StopEntityAttribute[];
    ripaSubjectOffenseCodes: RipaSubjectOffenseCode[];
}): Partial<StopAnonymousSubject>[] {
    return map(stopAnonymousSubjects, (stopAnonymousSubject) => {
        const filteredStopEntityAttributes = filterStopEntityAttributes({
            stopEntityAttributes,
            stopAnonymousSubject,
        });
        const filteredRipaSubjectOffenseCodes = filterRipaSubjectOffenseCodes({
            ripaSubjectOffenseCodes,
            stopAnonymousSubject,
        });
        return {
            ...pick(stopAnonymousSubject, [
                'id',
                STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH,
                STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH,
                STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH,
                STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH,
                STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH,
                STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH,
                STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH,
                STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH,
                STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH,
                STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH,
                STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH,
                STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH,
                STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH,
                STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH,
                STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH,
                STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH,
                STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH,
                STOP_ANONYMOUS_SUBJECT_NON_FORCIBLE_ACTION_TAKEN_PATH,
                STOP_ANONYMOUS_SUBJECT_FORCIBLE_ACTION_TAKEN_PATH,
                STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH,
            ]),
            [STOP_ANONYMOUS_SUBJECT_IS_PERCEIVED_DISABILITY_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.SUBJECT_DATA_DISABILITY.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.SUBJECT_DATA_RACE.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.STOP_SUSPICION_TYPE.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_REASON_GIVEN_TO_STOPPED_PERSON_ATTR_ID_PATH]: filterStopEntityAttributeIds(
                {
                    stopEntityAttributes: filteredStopEntityAttributes,
                    attributeType:
                        AttributeTypeEnum.SUBJECT_DATA_REASON_GIVEN_TO_STOPPED_PERSON.name,
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.SUBJECT_DATA_PROBABLE_CAUSE.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_ACTIONS_TAKEN_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.STOP_ACTION_TAKEN.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_NON_FORCIBLE_ACTION_TAKEN_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.STOP_NON_FORCIBLE_ACTION_TAKEN.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_FORCIBLE_ACTION_TAKEN_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.STOP_FORCIBLE_ACTION_TAKEN.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_REASON_FOR_SEARCH_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.REASON_FOR_SEARCH.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_BASIS_FOR_PROPERTY_SEIZURE_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.STOP_BASIS_FOR_PROPERTY_SEIZURE.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_TYPE_OF_PROPERTY_SEIZED_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.STOP_TYPE_OF_PROPERTY_SEIZED.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_CONTRABAND_OR_EVIDENCE_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.SUBJECT_DATA_CONTRABAND_OR_EVIDENCE.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH]: filterStopEntityAttributeIds({
                stopEntityAttributes: filteredStopEntityAttributes,
                attributeType: AttributeTypeEnum.SUBJECT_DATA_RESULT_OF_STOP.name,
            }),
            [STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_CJIS_OFFENSE_CODE_PATH]: filterRipaSubjectOffenseCodesIds(
                {
                    ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                    codeTypeCategory: CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
                    code: '1',
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_SUSPICION_CJIS_OFFENSE_CODE_PATH]: filterRipaSubjectOffenseCodesIds(
                {
                    ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                    codeTypeCategory: CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
                    code: '5',
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_CJIS_OFFENSE_CODE_PATH]: filterRipaSubjectOffenseCodesIds(
                {
                    ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                    codeTypeCategory: CodeTypeCategoryEnum.STOP_REASON_FOR_STOP.name,
                    code: '7',
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_WARNING_CJIS_OFFENSE_CODE_PATH]: filterRipaSubjectOffenseCodesIds(
                {
                    ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                    codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                    code: '2',
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_CITATION_CJIS_OFFENSE_CODE_PATH]: filterRipaSubjectOffenseCodesIds(
                {
                    ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                    codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                    code: '3',
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_IN_FIELD_CITE_RELEASE_CJIS_OFFENSE_CODE_PATH]: filterRipaSubjectOffenseCodesIds(
                {
                    ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                    codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                    code: '4',
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_CUSTODIAL_ARREST_CJIS_OFFENSE_CODE_PATH]: filterRipaSubjectOffenseCodesIds(
                {
                    ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                    codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                    code: '6',
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_VERBAL_WARNING_CJIS_OFFENSE_CODE_PATH]: filterRipaSubjectOffenseCodesIds(
                {
                    ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                    codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                    code: '8',
                }
            ),
            [STOP_ANONYMOUS_SUBJECT_WRITTEN_WARNING_CJIS_OFFENSE_CODE_PATH]: filterRipaSubjectOffenseCodesIds(
                {
                    ripaSubjectOffenseCodes: filteredRipaSubjectOffenseCodes,
                    codeTypeCategory: CodeTypeCategoryEnum.STOP_RESULT_OF_STOP.name,
                    code: '9',
                }
            ),
        };
    });
}

import factory from './actionTypesFactory';

export default factory('reportNumberChange', [
    'OPEN_RECORD_NUMBER_CHANGE_MODAL',
    'CLEAR_RECORD_NUMBER_CHANGE_MODAL',
    'VALIDATE_RECORD_NUMBER_UPDATE_START',
    'VALIDATE_RECORD_NUMBER_UPDATE_SUCCESS',
    'VALIDATE_RECORD_NUMBER_UPDATE_FAILURE',
    'ENABLE_RECORD_NUMBER_UPDATE',
    'DISABLE_RECORD_NUMBER_UPDATE',
    'UPDATE_RECORD_NUMBER_START',
    'UPDATE_RECORD_NUMBER_SUCCESS',
    'UPDATE_RECORD_NUMBER_FAILURE',
    'STORE_HANDLE_SUBMIT',
]);

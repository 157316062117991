import { ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { compose, onlyUpdateForKeys } from 'recompose';
import { connect } from 'react-redux';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import testIds from '../../../../core/testIds';

import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';

import RestrictedAccessNotice from '../../../core/components/RestrictedAccessNotice';
import getLinkUrl from '../helpers/getLinkUrl';
import QuickSearchResultSectionItemLink from './QuickSearchResultSectionItemLink';
import QuickSearchResultSectionReportItem from './sectionitems/QuickSearchResultSectionReportItem';
import QuickSearchResultSectionPersonItem from './sectionitems/QuickSearchResultSectionPersonItem';
import QuickSearchResultSectionWarrantItem from './sectionitems/QuickSearchResultSectionWarrantItem';
import QuickSearchResultSectionOrganizationItem from './sectionitems/QuickSearchResultSectionOrganizationItem';
import QuickSearchResultSectionCadTicketItem from './sectionitems/QuickSearchResultSectionCadTicketItem';
import QuickSearchResultSectionCaseItem from './sectionitems/QuickSearchResultSectionCaseItem';
import QuickSearchResultSectionPropertyItem from './sectionitems/QuickSearchResultSectionPropertyItem';
import QuickSearchResultSectionVehicleItem from './sectionitems/QuickSearchResultSectionVehicleItem';
import { QuickSearchResultSectionBookingItem } from './sectionitems/QuickSearchResultSectionBookingItem';

const searchResultTypeToComponentMap = {
    [ElasticSearchTypeEnum.REPORT.name]: QuickSearchResultSectionReportItem,
    [ElasticSearchTypeEnum.PERSON.name]: QuickSearchResultSectionPersonItem,
    [ElasticSearchTypeEnum.WARRANT.name]: QuickSearchResultSectionWarrantItem,
    [ElasticSearchTypeEnum.ORGANIZATION.name]: QuickSearchResultSectionOrganizationItem,
    [ElasticSearchTypeEnum.CAD_TICKET.name]: QuickSearchResultSectionCadTicketItem,
    [ElasticSearchTypeEnum.CASE.name]: QuickSearchResultSectionCaseItem,
    [ElasticSearchTypeEnum.PROPERTY.name]: QuickSearchResultSectionPropertyItem,
    [ElasticSearchTypeEnum.VEHICLE.name]: QuickSearchResultSectionVehicleItem,
    [EntityTypeEnum.BOOKING.name]: QuickSearchResultSectionBookingItem,
};

const QuickSearchResultSectionItemWrapper = ({
    item,
    itemType,
    type,
    isLast,
    isOnly,
    onClick,
    query,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}) => {
    // Instead of linking within each section item,
    // we use this wrapper to determine where to link an item to.
    // This gives us a central point we can make changes
    // to in the future in case linking changes, e.g. some
    // users can only find an item but not view it.
    const Component = searchResultTypeToComponentMap[type];
    const userCanReadItem = canRead(item.permissionSet) || type === EntityTypeEnum.BOOKING.name;
    const isExternal = item.departmentId !== currentUserDepartmentId;

    return (
        <QuickSearchResultSectionItemLink
            onClick={onClick}
            to={getLinkUrl({ itemType, item })}
            isLast={isLast}
            isOnly={isOnly}
            canRead={userCanReadItem}
            // We cannot just reuse `canRead` because that also changes styles and
            // this isn't what we want for cad tickets. We want to keep all
            // the styles as they are normally, but flat out prevent any kind
            // of link, hence us using `forcePreventLink`
            forcePreventLink={isExternal && type === ElasticSearchTypeEnum.CAD_TICKET.name}
        >
            {!userCanReadItem && <RestrictedAccessNotice />}
            <Component
                item={item}
                query={query}
                testId={testIds.QUICK_SEARCH_RESULT}
                testType={type}
                externalDepartmentName={
                    isExternal
                        ? departmentNameFromConsortiumLinksByDepartmentId(item.departmentId)
                        : undefined
                }
            />
        </QuickSearchResultSectionItemLink>
    );
};

export default compose(
    onlyUpdateForKeys(['query', 'item', 'onClick', 'isLast', 'isOnly']),
    connect((state) => ({
        currentUserDepartmentId: currentUserDepartmentIdSelector(state),
        departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector(
            state
        ),
    }))
)(QuickSearchResultSectionItemWrapper);

import { EntityTypeEnum, DispatchAreaStatusEnum } from '@mark43/cad-api';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import _, {
    find,
    keyBy,
    isEmpty,
    map,
    compact,
    size,
    filter,
    keys,
    reduce,
    get,
    values,
    parseInt,
} from 'lodash';
import {
    subdivisionsSelector,
    setupSubdivisions,
    LOAD_SUBDIVISIONS_FAILURE,
    LOAD_SUBDIVISIONS_START,
    SETUP_SUBDIVISIONS_FAILURE,
} from '~/client-common/core/domain/subdivisions/state/data';
import { MARK_SUBDIVISION_AS_PRIMARY_FAILURE } from '~/client-common/core/domain/primary-subdivisions/state/data';
import {
    dispatchAreasSelector,
    storeDispatchAreas,
} from '~/client-common/core/domain/dispatch-areas/state/data';
import getDispatchAreasResource from '~/client-common/core/domain/dispatch-areas/resources/dispatchAreasResource';

import getSubdivisionsResource from '~/client-common/core/domain/subdivisions/resources/subdivisionsResource';
import {
    CREATE_SHAPEFILE_FAILURE,
    LOAD_SHAPEFILES_FAILURE,
    LOAD_SHAPEFILE_PROPERTIES_FAILURE,
    SAVE_SHAPEFILE_FAILURE,
    createShapefile,
    loadShapefileFeatureProperties,
    saveShapefile,
    shapefileByIdSelector,
    shapefilesSelector,
} from '~/client-common/core/domain/shapefiles/state/data';
import {
    LOAD_FILE_FAILURE,
    fileByIdSelector,
    loadFile,
} from '~/client-common/core/domain/files/state/data';
import { attributesByTypeSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import subdivisionsAdminForm, {
    agencyTypeEnum,
    baseWizardUiSelector,
    validate,
} from '../forms/subdivisionsAdminForm';
import shapefilesUploadForm from '../forms/shapefilesUploadForm';
import {
    policeSubdivisionAttrTypes,
    fireSubdivisionAttrTypes,
    emsSubdivisionAttrTypes,
    steps,
} from '../../configuration';

const strings = componentStrings.admin.subdivisions;

const SET_STEP = 'subdivisionsAdminPage/SET_STEP';
const CLEAR_WIZARD = 'subdivisionsAdminPage/CLEAR_WIZARD';
const SELECT_SHAPEFILE = 'subdivisionsAdminPage/SELECT_SHAPEFILE';
const SET_PREVIEW = 'subdivisionsAdminPage/SET_PREVIEW';
const CLEAR_ERRORS = 'subdivisionsAdminPage/CLEAR_ERRORS';
const ADD_FIRE_DISPATCH_AREA = 'subdivisionsAdminPage/ADD_FIRE_DISPATCH_AREA';
const ADD_POLICE_DISPATCH_AREA = 'subdivisionsAdminPage/ADD_POLICE_DISPATCH_AREA';
const ADD_EMS_DISPATCH_AREA = 'subdivisionsAdminPage/ADD_EMS_DISPATCH_AREA';
const REMOVE_FIRE_DISPATCH_AREA = 'subdivisionsAdminPage/REMOVE_FIRE_DISPATCH_AREA';
const REMOVE_POLICE_DISPATCH_AREA = 'subdivisionsAdminPage/REMOVE_POLICE_DISPATCH_AREA';
const REMOVE_EMS_DISPATCH_AREA = 'subdivisionsAdminPage/REMOVE_EMS_DISPATCH_AREA';
const BUILD_OOJ_DISPATCH_AREAS_START = 'subdivisionsAdminPage/BUILD_OOJ_DISPATCH_AREAS_START';
const BUILD_OOJ_DISPATCH_AREAS_SUCCESS = 'subdivisionsAdminPage/BUILD_OOJ_DISPATCH_AREAS_SUCCESS';
const BUILD_OOJ_DISPATCH_AREAS_FAILURE = 'subdivisionsAdminPage/BUILD_OOJ_DISPATCH_AREAS_FAILURE';

const SETUP_SUBDIVISIONS_PREVIEW_START = 'subdivisionsAdminPage/SETUP_SUBDIVISIONS_PREVIEW_START';
const SETUP_SUBDIVISIONS_PREVIEW_SUCCESS =
    'subdivisionsAdminPage/SETUP_SUBDIVISIONS_PREVIEW_SUCCESS';
const SETUP_SUBDIVISIONS_PREVIEW_FAILURE =
    'subdivisionsAdminPage/SETUP_SUBDIVISIONS_PREVIEW_FAILURE';

const initialLandingPageState = {
    isPreparingSubdivisionsPreview: false,
    error: null,
};

function landingPageReducer(state = initialLandingPageState, action) {
    switch (action.type) {
        case BUILD_OOJ_DISPATCH_AREAS_FAILURE:
        case LOAD_SHAPEFILES_FAILURE:
        case LOAD_SHAPEFILE_PROPERTIES_FAILURE:
        case LOAD_SUBDIVISIONS_FAILURE:
        case LOAD_FILE_FAILURE:
        case CREATE_SHAPEFILE_FAILURE:
        case SAVE_SHAPEFILE_FAILURE:
        case SETUP_SUBDIVISIONS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case SETUP_SUBDIVISIONS_PREVIEW_START:
            return {
                ...state,
                isPreparingSubdivisionsPreview: true,
            };
        case SETUP_SUBDIVISIONS_PREVIEW_SUCCESS:
            return {
                ...state,
                isPreparingSubdivisionsPreview: false,
            };
        case SETUP_SUBDIVISIONS_PREVIEW_FAILURE:
            return {
                ...state,
                error: action.payload,
                isPreparingSubdivisionsPreview: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        case LOAD_SUBDIVISIONS_START:
            return initialLandingPageState;
        case MARK_SUBDIVISION_AS_PRIMARY_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

const initialWizardState = {
    step: null,
    selectedShapefileId: null,
    shapefilePreview: {
        shapefileId: null,
        selectedProperty: null,
    },
    setupPreview: {
        police: null,
        fire: null,
        ems: null,
    },
    subdivisionsAdminForm: {
        isFireDispatchAreaAdded: false,
        isPoliceDispatchAreaAdded: false,
        isEmsDispatchAreaAdded: false,
    },
};

function wizardReducer(state = initialWizardState, action) {
    switch (action.type) {
        case ADD_FIRE_DISPATCH_AREA:
            return {
                ...state,
                subdivisionsAdminForm: {
                    ...state.subdivisionsAdminForm,
                    isFireDispatchAreaAdded: true,
                },
            };
        case ADD_POLICE_DISPATCH_AREA:
            return {
                ...state,
                subdivisionsAdminForm: {
                    ...state.subdivisionsAdminForm,
                    isPoliceDispatchAreaAdded: true,
                },
            };
        case ADD_EMS_DISPATCH_AREA:
            return {
                ...state,
                subdivisionsAdminForm: {
                    ...state.subdivisionsAdminForm,
                    isEmsDispatchAreaAdded: true,
                },
            };
        case REMOVE_FIRE_DISPATCH_AREA:
            return {
                ...state,
                subdivisionsAdminForm: {
                    ...state.subdivisionsAdminForm,
                    isFireDispatchAreaAdded: false,
                },
            };
        case REMOVE_POLICE_DISPATCH_AREA:
            return {
                ...state,
                subdivisionsAdminForm: {
                    ...state.subdivisionsAdminForm,
                    isPoliceDispatchAreaAdded: false,
                },
            };
        case REMOVE_EMS_DISPATCH_AREA:
            return {
                ...state,
                subdivisionsAdminForm: {
                    ...state.subdivisionsAdminForm,
                    isEmsDispatchAreaAdded: false,
                },
            };
        case LOAD_SUBDIVISIONS_START:
        case CLEAR_WIZARD:
            return initialWizardState;
        case SET_STEP:
            return {
                ...state,
                step: action.payload,
                shapefilePreview: initialWizardState.shapefilePreview,
            };
        case SELECT_SHAPEFILE:
            return {
                ...state,
                selectedShapefileId: action.payload,
            };
        case SET_PREVIEW:
            return {
                ...state,
                shapefilePreview: action.payload,
            };
        case SETUP_SUBDIVISIONS_PREVIEW_SUCCESS:
            return {
                ...state,
                setupPreview: {
                    fire: action.payload.fire ? action.payload.result : state.setupPreview.fire,
                    police: action.payload.police
                        ? action.payload.result
                        : state.setupPreview.police,
                    ems: action.payload.ems ? action.payload.result : state.setupPreview.ems,
                },
            };
        default:
            return state;
    }
}

export function setStep(step) {
    return {
        type: SET_STEP,
        payload: step,
    };
}

function clearWizard() {
    return {
        type: CLEAR_WIZARD,
    };
}

export function setPreviewShapefile(preview) {
    return {
        type: SET_PREVIEW,
        payload: preview,
    };
}

function setPreviewShapefileId(shapefileId) {
    return setPreviewShapefile({
        shapefileId,
    });
}

export function endWizard() {
    return (dispatch) => {
        dispatch(clearWizard());
        dispatch(shapefilesUploadForm.actionCreators.reset());
    };
}

function selectShapefile(shapefileId) {
    return {
        type: SELECT_SHAPEFILE,
        payload: shapefileId,
    };
}

function setupSubdivisionsPreviewStart() {
    return {
        type: SETUP_SUBDIVISIONS_PREVIEW_START,
    };
}

function setupSubdivisionsPreviewSuccess(result, police, fire, ems) {
    return {
        type: SETUP_SUBDIVISIONS_PREVIEW_SUCCESS,
        payload: {
            result,
            police,
            fire,
            ems,
        },
    };
}

function setupSubdivisionsPreviewFailure(err = {}) {
    return {
        type: SETUP_SUBDIVISIONS_PREVIEW_FAILURE,
        payload: err.message,
    };
}

function buildOojDispatchAreasStart() {
    return {
        type: BUILD_OOJ_DISPATCH_AREAS_START,
    };
}

function buildOojDispatchAreasSuccess() {
    return {
        type: BUILD_OOJ_DISPATCH_AREAS_SUCCESS,
    };
}

function buildOojDispatchAreasFailure(err = {}) {
    return {
        type: BUILD_OOJ_DISPATCH_AREAS_FAILURE,
        payload: err.message,
    };
}

export function addFireDispatchArea() {
    return {
        type: ADD_FIRE_DISPATCH_AREA,
    };
}

export function addPoliceDispatchArea() {
    return {
        type: ADD_POLICE_DISPATCH_AREA,
    };
}

export function addEmsDispatchArea() {
    return {
        type: ADD_EMS_DISPATCH_AREA,
    };
}

export function removeFireDispatchArea() {
    return {
        type: REMOVE_FIRE_DISPATCH_AREA,
    };
}

export function removePoliceDispatchArea() {
    return {
        type: REMOVE_POLICE_DISPATCH_AREA,
    };
}

export function removeEmsDispatchArea() {
    return {
        type: REMOVE_EMS_DISPATCH_AREA,
    };
}

export function clearErrors() {
    return {
        type: CLEAR_ERRORS,
    };
}

// HELPERS

function getAgencyLabel(agencyType) {
    if (agencyType === agencyTypeEnum.FIRE) {
        return strings.fire;
    } else if (agencyType === agencyTypeEnum.POLICE) {
        return strings.police;
    } else {
        return strings.ems;
    }
}

function getDispatchAreaName(agencyType) {
    if (agencyType === agencyTypeEnum.FIRE) {
        return strings.fireDispatchArea;
    } else if (agencyType === agencyTypeEnum.POLICE) {
        return strings.policeDispatchArea;
    } else {
        return strings.emsDispatchArea;
    }
}

function buildOojDispatchAreas() {
    const dispatchAreasResource = getDispatchAreasResource();
    return (dispatch, getState) => {
        dispatch(buildOojDispatchAreasStart());

        const state = getState();
        const result = subdivisionsAdminForm.convertFromFormModel(
            subdivisionsAdminForm.selectors.formModelSelector(state)
        );
        const {
            /* fireShapefileId,*/ fireDispatchArea,
            /* policeShapefileId, */ policeDispatchArea,
            emsDispatchArea,
        } = result;
        const fireDispatchAreaShapefileId = _.get(fireDispatchArea, 'shapefileId');
        const policeDispatchAreaShapefileId = _.get(policeDispatchArea, 'shapefileId');
        const emsDispatchAreaShapefileId = _.get(emsDispatchArea, 'shapefileId');
        const agencyTypeGlobalAttributeIds = [];
        const oojDispatchAreas = [];

        if (fireDispatchAreaShapefileId) {
            const outOfJurisdictionName = get(result, 'fireDispatchArea.outOfJurisdictionName');
            const outOfJurisdictionDescription = get(
                result,
                'fireDispatchArea.outOfJurisdictionDescription'
            );
            const propertyName = get(result, 'fireDispatchArea.propertyName');
            const fireDispatchAreaObj = get(result, 'fireDispatchArea.fireOojDispatchAreaObj');

            // check required fields
            if (outOfJurisdictionName && propertyName) {
                agencyTypeGlobalAttributeIds.push(globalAttributes.agencyTypeGlobal.fire);
                oojDispatchAreas.push({
                    ...fireDispatchAreaObj,
                    agencyTypeGlobalAttrId: globalAttributes.agencyTypeGlobal.fire,
                    name: outOfJurisdictionName,
                    description: outOfJurisdictionDescription,
                    status: DispatchAreaStatusEnum.ACTIVE.name,
                });
            }
        }

        if (policeDispatchAreaShapefileId) {
            const outOfJurisdictionName = get(result, 'policeDispatchArea.outOfJurisdictionName');
            const outOfJurisdictionDescription = get(
                result,
                'policeDispatchArea.outOfJurisdictionDescription'
            );
            const propertyName = get(result, 'policeDispatchArea.propertyName');
            const policeDispatchAreaObj = get(
                result,
                'policeDispatchArea.policeOojDispatchAreaObj'
            );
            // check required fields
            if (outOfJurisdictionName && propertyName) {
                agencyTypeGlobalAttributeIds.push(globalAttributes.agencyTypeGlobal.police);
                oojDispatchAreas.push({
                    ...policeDispatchAreaObj,
                    agencyTypeGlobalAttrId: globalAttributes.agencyTypeGlobal.police,
                    name: outOfJurisdictionName,
                    description: outOfJurisdictionDescription,
                    status: DispatchAreaStatusEnum.ACTIVE.name,
                });
            }
        }

        if (emsDispatchAreaShapefileId) {
            const outOfJurisdictionName = get(result, 'emsDispatchArea.outOfJurisdictionName');
            const outOfJurisdictionDescription = get(
                result,
                'emsDispatchArea.outOfJurisdictionDescription'
            );
            const propertyName = get(result, 'emsDispatchArea.propertyName');
            const emsDispatchAreaObj = get(result, 'emsDispatchArea.emsOojDispatchAreaObj');
            // check required fields
            if (outOfJurisdictionName && propertyName) {
                agencyTypeGlobalAttributeIds.push(globalAttributes.agencyTypeGlobal.medical);
                oojDispatchAreas.push({
                    ...emsDispatchAreaObj,
                    agencyTypeGlobalAttrId: globalAttributes.agencyTypeGlobal.medical,
                    name: outOfJurisdictionName,
                    description: outOfJurisdictionDescription,
                    status: DispatchAreaStatusEnum.ACTIVE.name,
                });
            }
        }

        return dispatchAreasResource
            .buildOojDispatchAreas(agencyTypeGlobalAttributeIds, oojDispatchAreas)
            .then((dispatchAreas) => {
                dispatch(buildOojDispatchAreasSuccess());
                dispatch(storeDispatchAreas(dispatchAreas));
            })
            .catch((err) => dispatch(buildOojDispatchAreasFailure(err)));
    };
}

function setupSubdivisionsPreview(subdivisionsSetupView, police = true, fire = false, ems = false) {
    const resource = getSubdivisionsResource();
    return (dispatch) => {
        dispatch(setupSubdivisionsPreviewStart());
        return resource
            .setupSubdivisionsPreview(subdivisionsSetupView)
            .then((result) => {
                dispatch(setupSubdivisionsPreviewSuccess(result, police, fire, ems));
            })
            .catch((err) => dispatch(setupSubdivisionsPreviewFailure(err)));
    };
}

export function previewShapefile(shapefileId) {
    return (dispatch, getState) => {
        const shapefile = shapefileByIdSelector(getState())(shapefileId);
        dispatch(selectShapefile(shapefileId));
        dispatch(setPreviewShapefileId(shapefileId));
        dispatch(loadFile(shapefile.geojsonFileId));
    };
}

export function uploadShapefile(fileId) {
    return (dispatch, getState) => {
        dispatch(createShapefile(fileId)).then((shapefile) => {
            if (!shapefile || !shapefile.id) {
                return;
            }
            const shapefilesInForm = shapefilesUploadForm.convertFromFormModel(
                shapefilesUploadForm.selectors.formModelSelector(getState())
            );
            dispatch(shapefilesUploadForm.actionCreators.change([...shapefilesInForm, shapefile]));
            dispatch(previewShapefile(shapefile.id));
        });
    };
}

export function submitShapefileForm() {
    return (dispatch, getState) => {
        if (!shapefilesUploadForm.selectors.formIsValidSelector(getState())) {
            return;
        }
        const state = getState();
        dispatch(clearErrors());
        dispatch(
            shapefilesUploadForm.actionCreators.submit((result) => {
                const shapefiles = shapefilesUploadForm.convertFromFormModel(result);
                const shapefilesToSave = filter(shapefiles, (shapefile) => {
                    const existingShapefile = shapefileByIdSelector(state)(shapefile.id);
                    return existingShapefile.displayName !== shapefile.displayName;
                });
                Promise.all(
                    map(shapefilesToSave, (shapefile) => dispatch(saveShapefile(shapefile)))
                ).then(() => {
                    const shapefileFeaturePropertiesByAgencyType = shapefileFeaturePropertiesByAgencyTypeSelector(
                        state
                    );
                    const fireDispatchAreaShapefileFeatureProperty = find(
                        shapefileFeaturePropertiesByAgencyType[agencyTypeEnum.FIRE],
                        {
                            entityType: EntityTypeEnum.DISPATCH_AREA.name,
                        }
                    );
                    const policeDispatchAreaShapefileFeatureProperty = find(
                        shapefileFeaturePropertiesByAgencyType[agencyTypeEnum.POLICE],
                        {
                            entityType: EntityTypeEnum.DISPATCH_AREA.name,
                        }
                    );
                    const emsDispatchAreaShapefileFeatureProperty = find(
                        shapefileFeaturePropertiesByAgencyType[agencyTypeEnum.EMS],
                        {
                            entityType: EntityTypeEnum.DISPATCH_AREA.name,
                        }
                    );

                    dispatch(
                        subdivisionsAdminForm.actionCreators.change(
                            subdivisionsSelector(state),
                            attributesByTypeSelector(state),
                            oojDispatchAreaByAgencyTypeGlobalAttrIdSelector(state),
                            fireDispatchAreaShapefileFeatureProperty,
                            policeDispatchAreaShapefileFeatureProperty,
                            emsDispatchAreaShapefileFeatureProperty
                        )
                    );

                    if (!isEmpty(fireDispatchAreaShapefileFeatureProperty)) {
                        dispatch(addFireDispatchArea());
                    }

                    if (!isEmpty(policeDispatchAreaShapefileFeatureProperty)) {
                        dispatch(addPoliceDispatchArea());
                    }

                    if (!isEmpty(emsDispatchAreaShapefileFeatureProperty)) {
                        dispatch(addEmsDispatchArea());
                    }

                    dispatch(setStep(steps.MANAGE_SUBDIVISIONS));
                });
            })
        );
    };
}

export function submitSubdivisionsForm() {
    return (dispatch, getState) => {
        dispatch(clearErrors());
        const state = getState();
        const result = subdivisionsAdminForm.convertFromFormModel(
            subdivisionsAdminForm.selectors.formModelSelector(state)
        );
        const {
            fireShapefileId,
            fireDispatchArea,
            policeShapefileId,
            policeDispatchArea,
            emsShapefileId,
            emsDispatchArea,
        } = result;
        const fireDispatchAreaShapefileId = _.get(fireDispatchArea, 'shapefileId');
        const policeDispatchAreaShapefileId = _.get(policeDispatchArea, 'shapefileId');
        const emsDispatchAreaShapefileId = _.get(emsDispatchArea, 'shapefileId');
        const promises = [];

        if (policeShapefileId) {
            const hasPoliceDispatchArea = !!get(result, 'policeDispatchArea.propertyName');
            promises.push(
                dispatch(
                    setupSubdivisions({
                        shapefileId: policeShapefileId,
                        subdivisions: result.police,
                        agencyTypeGlobalAttributeId: globalAttributes.agencyTypeGlobal.police,
                        /**
                         * NOTE if there are other shapefileProperties in the
                         * future, we'll want to update this as to not override
                         */
                        shapefileProperties: hasPoliceDispatchArea
                            ? [
                                  {
                                      entityType: EntityTypeEnum.DISPATCH_AREA.name,
                                      propertyName: result.policeDispatchArea.propertyName,
                                      shapefileId: policeDispatchAreaShapefileId,
                                  },
                              ]
                            : [],
                    })
                )
            );
        }

        if (fireShapefileId) {
            const hasFireDispatchArea = !!get(result, 'fireDispatchArea.propertyName');
            promises.push(
                dispatch(
                    setupSubdivisions({
                        shapefileId: fireShapefileId,
                        subdivisions: result.fire,
                        agencyTypeGlobalAttributeId: globalAttributes.agencyTypeGlobal.fire,
                        /**
                         * NOTE if there are other shapefileProperties in the
                         * future, we'll want to update this as to not override
                         */
                        shapefileProperties: hasFireDispatchArea
                            ? [
                                  {
                                      entityType: EntityTypeEnum.DISPATCH_AREA.name,
                                      propertyName: result.fireDispatchArea.propertyName,
                                      shapefileId: fireDispatchAreaShapefileId,
                                  },
                              ]
                            : [],
                    })
                )
            );
        }

        if (emsShapefileId) {
            const hasEmsDispatchArea = !!get(result, 'emsDispatchArea.propertyName');
            promises.push(
                dispatch(
                    setupSubdivisions({
                        shapefileId: emsShapefileId,
                        subdivisions: result.ems,
                        agencyTypeGlobalAttributeId: globalAttributes.agencyTypeGlobal.medical,
                        /**
                         * NOTE if there are other shapefileProperties in the
                         * future, we'll want to update this as to not override
                         */
                        shapefileProperties: hasEmsDispatchArea
                            ? [
                                  {
                                      entityType: EntityTypeEnum.DISPATCH_AREA.name,
                                      propertyName: result.emsDispatchArea.propertyName,
                                      shapefileId: emsDispatchAreaShapefileId,
                                  },
                              ]
                            : [],
                    })
                )
            );
        }

        Promise.all(promises)
            // no matter what we need to buildOojDispatchAreas in case we need to replace
            .then(() => {
                dispatch(buildOojDispatchAreas());
                dispatch(loadShapefileFeatureProperties());
            })
            .then(() => dispatch(endWizard()));
    };
}

export function submitSubdivisionsFormPreview() {
    return (dispatch, getState) => {
        if (!dispatch(validate())) {
            return;
        }
        dispatch(clearErrors());

        const result = subdivisionsAdminForm.convertFromFormModel(
            subdivisionsAdminForm.selectors.formModelSelector(getState())
        );
        const {
            fireShapefileId,
            fireDispatchArea,
            policeShapefileId,
            policeDispatchArea,
            emsShapefileId,
            emsDispatchArea,
        } = result;
        const fireDispatchAreaShapefileId = _.get(fireDispatchArea, 'shapefileId');
        const policeDispatchAreaShapefileId = _.get(policeDispatchArea, 'shapefileId');
        const emsDispatchAreaShapefileId = _.get(emsDispatchArea, 'shapefileId');

        const promises = [];
        if (policeShapefileId) {
            const hasPoliceDispatchArea = !!get(result, 'policeDispatchArea.propertyName');
            promises.push(
                dispatch(
                    setupSubdivisionsPreview(
                        {
                            shapefileId: policeShapefileId,
                            subdivisions: result.police,
                            agencyTypeGlobalAttributeId: globalAttributes.agencyTypeGlobal.police,
                            shapefileProperties: hasPoliceDispatchArea
                                ? [
                                      {
                                          entityType: EntityTypeEnum.DISPATCH_AREA.name,
                                          propertyName: result.policeDispatchArea.propertyName,
                                          shapefileId: policeDispatchAreaShapefileId,
                                      },
                                  ]
                                : [],
                        },
                        true
                    )
                )
            );
        }
        if (fireShapefileId) {
            const hasFireDispatchArea = !!get(result, 'fireDispatchArea.propertyName');
            promises.push(
                dispatch(
                    setupSubdivisionsPreview(
                        {
                            shapefileId: fireShapefileId,
                            subdivisions: result.fire,
                            agencyTypeGlobalAttributeId: globalAttributes.agencyTypeGlobal.fire,
                            shapefileProperties: hasFireDispatchArea
                                ? [
                                      {
                                          entityType: EntityTypeEnum.DISPATCH_AREA.name,
                                          propertyName: result.fireDispatchArea.propertyName,
                                          shapefileId: fireDispatchAreaShapefileId,
                                      },
                                  ]
                                : [],
                        },
                        false,
                        true
                    )
                )
            );
        }

        if (emsShapefileId) {
            const hasEmsDispatchArea = !!get(result, 'emsDispatchArea.propertyName');
            promises.push(
                dispatch(
                    setupSubdivisionsPreview(
                        {
                            shapefileId: emsShapefileId,
                            subdivisions: result.ems,
                            agencyTypeGlobalAttributeId: globalAttributes.agencyTypeGlobal.medical,
                            shapefileProperties: hasEmsDispatchArea
                                ? [
                                      {
                                          entityType: EntityTypeEnum.DISPATCH_AREA.name,
                                          propertyName: result.emsDispatchArea.propertyName,
                                          shapefileId: emsDispatchAreaShapefileId,
                                      },
                                  ]
                                : [],
                        },
                        false,
                        false,
                        true
                    )
                )
            );
        }

        Promise.all(promises).then(() => dispatch(setStep(steps.PREVIEW)));
    };
}

const landingPageBaseUiSelector = (state) => state.ui.subdivisionsV2Admin.landingPage;

export const isPreparingSubdivisionsPreviewSelector = createSelector(
    landingPageBaseUiSelector,
    (landingPage) => landingPage.isPreparingSubdivisionsPreview
);

const oojDispatchAreaByAgencyTypeGlobalAttrIdSelector = createSelector(
    dispatchAreasSelector,
    (dispatchAreas) => (agencyTypeGlobalAttrId) => {
        return find(values(dispatchAreas), {
            agencyTypeGlobalAttrId,
            isOutOfJurisdiction: true,
            status: DispatchAreaStatusEnum.ACTIVE.name,
        });
    }
);

const shapefileFeaturePropertiesByAgencyTypeSelector = createSelector(
    shapefilesSelector,
    (shapefiles) => {
        return _.chain(shapefiles)
            .values()
            .filter((shapefile) => !!size(get(shapefile, 'featureProperties', [])))
            .reduce((featureProperties, shapefile) => {
                return [...featureProperties, ...shapefile.featureProperties];
            }, [])
            .groupBy('agencyTypeGlobalAttrId')
            .mapKeys((value, key) => {
                if (parseInt(key) === globalAttributes.agencyTypeGlobal.police) {
                    return agencyTypeEnum.POLICE;
                } else if (parseInt(key) === globalAttributes.agencyTypeGlobal.medical) {
                    return agencyTypeEnum.EMS;
                } else {
                    return agencyTypeEnum.FIRE;
                }
            })
            .value();
    }
);

const createRowsSelector = (subdivisionsSelector, shapefileFeaturePropertiesByAgencyTypeSelector) =>
    createSelector(
        subdivisionsSelector,
        shapefileByIdSelector,
        fileByIdSelector,
        shapefileFeaturePropertiesByAgencyTypeSelector,
        (subdivisions, shapefileById, fileById, shapefileFeaturePropertiesByAgencyType) => {
            const subdivisionsIndexedByAttrType = keyBy(subdivisions, 'depthAttributeType');
            const formRowsFromSubdivisionAttrTypes = (
                attrTypes,
                agencyLabel,
                canSelectPrimarySubivision
            ) => {
                return compact(
                    map(attrTypes, (attrType) => {
                        const subdivision = subdivisionsIndexedByAttrType[attrType];
                        if (!subdivision) {
                            return null;
                        }
                        const shapefile = shapefileById(subdivision.shapefileId);
                        if (!shapefile) {
                            return null;
                        }
                        const shapefileFile = fileById(shapefile.geojsonFileId);
                        if (!shapefileFile) {
                            return null;
                        }
                        const propertyValues = keys(
                            keyBy(
                                filter(
                                    shapefile.properties,
                                    (mapping) => mapping[subdivision.shapefileFeaturePropertyName]
                                ),
                                subdivision.shapefileFeaturePropertyName
                            )
                        );
                        const propertyCount = size(propertyValues);
                        return {
                            ...subdivision,
                            shapefileFileName: shapefileFile.originalFileName,
                            shapefileDisplayName: shapefile.displayName,
                            agencyLabel,
                            property: subdivision.shapefileFeaturePropertyName,
                            name: subdivision.subdivisionName,
                            // unstyled table row depends on three pieces of separate data
                            id: `${subdivision.id}~${shapefileFile.id}~${propertyCount}`,
                            subdivisionId: subdivision.id,
                            propertyCount,
                            updatedDateUtc: subdivision.updatedDateUtc,
                            shapefileFile,
                            propertyOptions: map(propertyValues, (value) => ({
                                display: value,
                                value,
                            })),
                            type: 'Subdivision',
                            canSelectPrimarySubivision,
                        };
                    })
                );
            };
            const formRowsFromShapefileFeatureProperties = (
                shapefileFeatureProperties,
                agencyType
            ) => {
                return compact(
                    map(shapefileFeatureProperties, (sfp) => {
                        const shapefile = shapefileById(sfp.shapefileId);
                        if (!shapefile) {
                            return null;
                        }
                        const shapefileFile = fileById(shapefile.geojsonFileId);
                        if (!shapefileFile) {
                            return null;
                        }
                        const propertyValues = keys(
                            keyBy(
                                filter(
                                    shapefile.properties,
                                    (mapping) => mapping[sfp.propertyName]
                                ),
                                sfp.propertyName
                            )
                        );
                        const propertyCount = size(propertyValues);
                        return {
                            ...sfp,
                            shapefileFileName: shapefileFile.originalFileName,
                            shapefileDisplayName: shapefile.displayName,
                            agencyLabel: getAgencyLabel(agencyType),
                            property: sfp.propertyName,
                            name: getDispatchAreaName(agencyType),
                            // unstyled table row depends on three pieces of separate data
                            id: `${sfp.id}~${shapefileFile.id}~${propertyCount}`,
                            subdivisionId: sfp.id,
                            propertyCount,
                            updatedDateUtc: sfp.updatedDateUtc,
                            shapefileFile,
                            propertyOptions: map(propertyValues, (value) => ({
                                display: value,
                                value,
                            })),
                            type: 'Dispatch Area',
                        };
                    })
                );
            };
            return [
                ...formRowsFromSubdivisionAttrTypes(
                    policeSubdivisionAttrTypes,
                    strings.police,
                    true
                ),
                ...formRowsFromShapefileFeatureProperties(
                    shapefileFeaturePropertiesByAgencyType[agencyTypeEnum.POLICE],
                    agencyTypeEnum.POLICE
                ),
                ...formRowsFromSubdivisionAttrTypes(fireSubdivisionAttrTypes, strings.fire, true),
                ...formRowsFromShapefileFeatureProperties(
                    shapefileFeaturePropertiesByAgencyType[agencyTypeEnum.FIRE],
                    agencyTypeEnum.FIRE
                ),
                ...formRowsFromSubdivisionAttrTypes(emsSubdivisionAttrTypes, strings.ems),
                ...formRowsFromShapefileFeatureProperties(
                    shapefileFeaturePropertiesByAgencyType[agencyTypeEnum.EMS],
                    agencyTypeEnum.EMS
                ),
            ];
        }
    );

const landingPageTableSelector = createRowsSelector(
    subdivisionsSelector,
    shapefileFeaturePropertiesByAgencyTypeSelector
);

export const landingPageUiSelector = createSelector(
    landingPageBaseUiSelector,
    landingPageTableSelector,
    (ui, rows) => ({
        ...ui,
        rows,
    })
);

export const isWizardActiveSelector = createSelector(baseWizardUiSelector, (ui) => !!ui.step);

export const wizardUiSelector = createSelector(
    baseWizardUiSelector,
    shapefilesSelector,
    fileByIdSelector,
    subdivisionsSelector,
    (ui, shapefiles, fileById, subdivisions) => {
        const shapefilePreviewOptions = map(shapefiles, (shapefile) => ({
            display: shapefile.displayName,
            value: shapefile.id,
        }));
        const viewingShapefileId = ui.shapefilePreview.shapefileId;
        const viewingShapefile = viewingShapefileId && shapefiles[viewingShapefileId];
        const geojsonURL =
            viewingShapefile && get(fileById(viewingShapefile.geojsonFileId), 'fileWebServerPath');

        const indexedByAttrType = keyBy(subdivisions, 'depthAttributeType');
        const hasPoliceSubdivisions = !!size(
            compact(map(policeSubdivisionAttrTypes, (attrType) => indexedByAttrType[attrType]))
        );
        const hasFireSubdivisions = !!size(
            compact(map(fireSubdivisionAttrTypes, (attrType) => indexedByAttrType[attrType]))
        );
        const hasEmsubdivisions = !!size(
            compact(map(emsSubdivisionAttrTypes, (attrType) => indexedByAttrType[attrType]))
        );
        return {
            ...ui,
            shapefilePreviewOptions,
            hasPoliceSubdivisions,
            hasFireSubdivisions,
            hasEmsubdivisions,
            shapefilePreview: {
                ...ui.shapefilePreview,
                geojsonURL,
            },
        };
    }
);

export const previewPageRowsSelector = createRowsSelector(
    createSelector(wizardUiSelector, ({ setupPreview }) => {
        const addedAndEdited = [
            ...get(setupPreview, 'fire.subdivisions', []),
            ...get(setupPreview, 'police.subdivisions', []),
            ...get(setupPreview, 'ems.subdivisions', []),
        ];
        const removed = map(
            [
                ...get(setupPreview, 'fire.deletedSubdivisions', []),
                ...get(setupPreview, 'police.deletedSubdivisions', []),
                ...get(setupPreview, 'ems.deletedSubdivisions', []),
            ],
            (subdivision) => ({ ...subdivision, removed: true })
        );
        return [...addedAndEdited, ...removed];
    }),
    createSelector(wizardUiSelector, ({ setupPreview }) => {
        const addedAndEdited = [
            ...get(setupPreview, 'fire.shapefileProperties', []),
            ...get(setupPreview, 'police.shapefileProperties', []),
            ...get(setupPreview, 'ems.shapefileProperties', []),
        ];
        const removed = map(
            [
                ...get(setupPreview, 'fire.deletedShapefileProperties', []),
                ...get(setupPreview, 'police.deletedShapefileProperties', []),
                ...get(setupPreview, 'ems.deletedShapefileProperties', []),
            ],
            (sfp) => ({ ...sfp, removed: true })
        );
        return _([...addedAndEdited, ...removed])
            .groupBy('agencyTypeGlobalAttrId')
            .mapKeys((value, key) => {
                if (parseInt(key) === globalAttributes.agencyTypeGlobal.police) {
                    return agencyTypeEnum.POLICE;
                } else if (parseInt(key) === globalAttributes.agencyTypeGlobal.fire) {
                    return agencyTypeEnum.FIRE;
                }

                return agencyTypeEnum.EMS;
            })
            .value();
    })
);

export const previewDownloadLinksSelector = createSelector(wizardUiSelector, ({ setupPreview }) => {
    const toFileLink = (attributesTitle, subdivisionsTitle) => (file, index) => ({
        displayName: file.originalFileName,
        path: file.fileWebServerPath,
        id: file.id,
        description: index === 0 ? attributesTitle : subdivisionsTitle,
    });
    return [
        ...map(
            get(setupPreview, 'police.csvs', []),
            toFileLink(strings.wizard.policeAttributesCSV, strings.wizard.policeSubdivisionsCSV)
        ),
        ...map(
            get(setupPreview, 'fire.csvs', []),
            toFileLink(strings.wizard.fireAttributesCSV, strings.wizard.fireSubdivisionsCSV)
        ),
        ...map(
            get(setupPreview, 'ems.csvs', []),
            toFileLink(strings.wizard.emsAttributesCSV, strings.wizard.emsSubdivisionsCSV)
        ),
    ];
});

export const propertyOptionsByShapefileIdSelector = createSelector(
    shapefileByIdSelector,
    (shapefileById) => (shapefileId) => {
        if (!shapefileId) {
            return [];
        }
        const shapefile = shapefileById(shapefileId);
        if (!shapefile) {
            return [];
        }
        return map(
            reduce(
                shapefile.properties,
                (acc, propertyObj) => {
                    return {
                        ...acc,
                        ...propertyObj,
                    };
                },
                {}
            ),
            (x, property) => ({
                display: property,
                value: property,
            })
        );
    }
);

export default combineReducers({
    landingPage: landingPageReducer,
    wizard: wizardReducer,
});

import React from 'react';
import { get, isFunction } from 'lodash';
import {
    AnalyticsPropertyEnum,
    AnalyticsPropertyEnumType,
    analyticsInteractionEnum,
} from '../constants/analyticsEnum';
import { useAnalytics } from '../hooks/useAnalytics';
import { assignToRefs } from '../../core/utils/eventHelpers';

type OnClick<Element> = (e: React.MouseEvent<Element>, ...args: unknown[]) => void;

// props which get passed through to the base component which actually gets rendered, in contrast to the other
// WithAnalyticsProps which are only used inside the withAnalytics function
type ThroughProps = {
    testId?: string;
    'data-test-id'?: string;
};

type WithAnalyticsProps<Element> = ThroughProps & {
    onClick?: OnClick<Element>;
    additionalTrackingParams?: {
        [analyticsKey in AnalyticsPropertyEnumType]?: unknown;
    };
};

export const withAnalytics = ({
    analyticsKeyToAdd,
}: {
    analyticsKeyToAdd: AnalyticsPropertyEnumType;
}) => <Props, Element>(Component: React.ComponentType<Props & WithAnalyticsProps<Element>>) => {
    return React.forwardRef<unknown, Props & WithAnalyticsProps<Element>>(
        (
            {
                onClick,
                additionalTrackingParams = {},
                ...props
            }: Props & WithAnalyticsProps<Element>,
            ref
        ) => {
            const { filteredTrack } = useAnalytics();
            const handleClick: OnClick<Element> = (e, ...args) => {
                if (isFunction(onClick)) {
                    onClick(e, ...args);
                }
                const testId = props.testId || props['data-test-id'];
                filteredTrack(
                    {
                        [analyticsKeyToAdd]: testId,
                        ...additionalTrackingParams,
                        // Usually, we map click events to
                        // equivalent keypress events,
                        // if neccessary,
                        // so we need to track that here
                        //
                        // For this reason, we will only handle
                        // click events here, and not `onKeyDown`
                        // or `onKeyPress`
                        [AnalyticsPropertyEnum.INTERACTION]:
                            get(e, 'type') === 'click'
                                ? analyticsInteractionEnum.CLICK
                                : analyticsInteractionEnum.ENTER,
                    },
                    testId
                );
            };
            return (
                <Component
                    onClick={handleClick}
                    {...(props as Props & ThroughProps)}
                    ref={ref ? assignToRefs(ref) : undefined}
                />
            );
        }
    );
};

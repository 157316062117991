import React from 'react';
import Link from '../../../../../modules/core/components/links/Link';
import { CourtOrderCellProps } from './CourtOrderCellProps';

export default function StatuteCodeCell({ statuteCodeAttrDetail, reportId }: CourtOrderCellProps) {
    return (
        <Link hoverOnUnderline={true} to={`reports/${reportId}`}>
            {statuteCodeAttrDetail?.displayValue}
        </Link>
    );
}

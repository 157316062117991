import _ from 'lodash';

/**
 * @param  {Object}   state  Current form state.
 * @param  {Object}   obj    Form data.
 * @param  {string[]} [keys] The keys to be set on the state from `obj`. This
 *   argument is useful especially when `obj` may not contain all of the keys.
 *   If not provided, assign all properties of `obj` to the state.
 * @return {Object}   New form state.
 */
export function setFormValues(state, obj, keys) {
    const newState = _.cloneDeep(state || {}); // not sure if need deep
    if (keys) {
        _.forEach(keys, (key) => {
            newState[key] = _.assign({}, newState[key], { value: obj[key] });
        });
    } else {
        _.forIn(obj, (value, key) => {
            newState[key] = _.assign({}, newState[key], { value });
        });
    }
    return newState;
}

/**
 * Clear the given state of its form field values. The shape is assumed to be
 *   from `redux-form`.
 * @param  {Object} state Current form state.
 * @return {Object} New form state.
 */
export function clearFormValues(state) {
    const newState = _.cloneDeep(state); // being safe!
    _.forIn(newState, (field) => {
        if (_.isObject(field) && _.has(field, 'value')) {
            if (_.has(field, 'initial')) {
                field.value = field.initial;
            } else {
                field.value = '';
            }
            field.touched = false;
        }
    });
    return newState;
}

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import _Icon, { iconTypes as ICON_TYPES } from '../../../../legacy-redux/components/core/Icon';
import testIds from '../../../../core/testIds';

const STRINGS = componentStrings.evidence.itemQueue.StaffRemarksNotice;

const Icon = styled(_Icon)`
    color: ${(props) => props.theme.colors.white};
    width: 25px;
`;

const StaffRemarksNotice = styled(({ className, totalCount }) => (
    <div className={className} data-test-id={testIds.STAFF_REMARK_NOTICE}>
        <Icon type={ICON_TYPES.NOTEPAD} /> {STRINGS.title(totalCount)}
    </div>
))`
    align-items: center;
    background: ${(props) => props.theme.colors.cobaltBlue};
    color: ${(props) => props.theme.colors.white};
    display: flex;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
    height: 20px;
    line-height: 17px;
    padding: 0 20px;
    width: 100%;
`;

StaffRemarksNotice.propTypes = {
    className: PropTypes.string,
    totalCount: PropTypes.number,
};

StaffRemarksNotice.defaultProps = {
    className: '',
    totalCount: 0,
};

export default StaffRemarksNotice;

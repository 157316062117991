import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { mediaQueries } from 'arc';
import { compose, withPropsOnChange, withHandlers } from 'recompose';
import styled from 'styled-components';
import { reportShortTitleByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/data';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { reportDefinitionHasCardSelector } from '~/client-common/core/domain/report-definitions/state/data';
import FocusWrapper from '../../../core/components/FocusWrapper';
import { responsiveStack } from '../../../core/styles/mixins';

import { currentReportSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
} from '../../../core/current-user/state/ui';
import AttachmentsCard from '../../../core/components/cards/AttachmentsCard';
import { EvidenceErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import ReportStatusCommentsCard from '../../core/components/report-status-comments/ReportStatusCommentsCard';
import { openReturnReportToDraftModal } from '../../core/state/ui';

import custodialPropertyCard from '../../core/state/ui/custodialPropertyCard';
import { canEditCustodialReportSelector, currentReportHasItemProfilesSelector } from '../state/ui';
import testIds from '../../../../core/testIds';
import CustodialPropertyActionBar from './CustodialPropertyActionBar';
import CustodialPropertyCard from './CustodialPropertyCard';
import StaffRemarksCard from './StaffRemarksCard';
import LinkedReportsCard from './LinkedReportsCard';
import PersonnelCard from './PersonnelCard';

const { EVIDENCE } = abilitiesEnum;
const { DRAFT } = approvalStatusClientEnum;

const ColumnWrapper = styled.div`
    ${responsiveStack()};

    @media (min-width: ${mediaQueries.md}) {
        gap: var(--arc-space-6);
    }
`;

const Content = styled.div`
    width: 100%;
    max-width: 970px;
`;

const Column = styled.div`
    flex: 1;
`;

const CustodialPropertySummary = ({
    currentReport,
    canViewStaffRemarks,
    canEdit,
    canViewCustodialAttachments,
    canEditCustodialAttachments,
    editMediator,
    reportShortTitleByReportId,
    reportDefinitionHasCard,
}) => {
    const hasAttachmentsCard = reportDefinitionHasCard(
        currentReport.reportDefinitionId,
        reportCardEnum.ATTACHMENTS.id
    );
    const { reportingEventNumber, shortTitle } = reportShortTitleByReportId(currentReport.id);
    const reportShortTitle = `${reportingEventNumber} ${shortTitle}`;
    const summaryMode = useSelector(custodialPropertyCard.selectors.summaryModeSelector);

    return (
        <>
            <FocusWrapper className="mark43-scrollable-under-subheader">
                <EvidenceErrorBoundary>
                    <Content data-test-id={testIds.REPORT_CARDS}>
                        <ColumnWrapper>
                            <Column>
                                <PersonnelCard />
                                {canViewStaffRemarks && <LinkedReportsCard />}
                            </Column>
                            <Column>
                                {!canViewStaffRemarks && <LinkedReportsCard />}

                                {canViewStaffRemarks && <StaffRemarksCard />}
                            </Column>
                        </ColumnWrapper>
                        <CustodialPropertyCard canEdit={canEdit} editCallback={editMediator} />
                        {canViewCustodialAttachments && hasAttachmentsCard && (
                            <AttachmentsCard
                                entityTitle={reportShortTitle}
                                entityType={EntityTypeEnum.REPORT.name}
                                entityId={currentReport.id}
                                index={currentReport.id}
                                linkType={LinkTypesEnum.REPORT_ATTACHMENT}
                                canEdit={canEdit && canEditCustodialAttachments}
                                editCallback={editMediator}
                            />
                        )}
                        <ReportStatusCommentsCard isCustodialReport={true} />
                    </Content>
                </EvidenceErrorBoundary>
            </FocusWrapper>
            {summaryMode && <CustodialPropertyActionBar />}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    currentReport: currentReportSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    currentReportHasItemProfiles: currentReportHasItemProfilesSelector,
    canEdit: canEditCustodialReportSelector,
    reportShortTitleByReportId: reportShortTitleByReportIdSelector,
    saving: custodialPropertyCard.selectors.savingSelector,
    reportDefinitionHasCard: reportDefinitionHasCardSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openReturnReportToDraftModal: ({ callback }) =>
        dispatch(openReturnReportToDraftModal({ callback })),
});

/**
 * Route component for a report of type Custodial Property Summary.
 * @param {number} props.reportId
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(['currentUserHasAbility'], ({ currentUserHasAbility }) => ({
        canViewStaffRemarks: currentUserHasAbility(EVIDENCE.VIEW_STAFF_REMARKS),
        canViewCustodialAttachments: currentUserHasAbility(EVIDENCE.VIEW_CUSTODIAL_ATTACHMENTS),
        canEditCustodialAttachments: currentUserHasAbility(EVIDENCE.EDIT_CUSTODIAL_ATTACHMENTS),
    })),
    withHandlers({
        editMediator({ currentReport, canEdit, openReturnReportToDraftModal }) {
            return (editCallback) => {
                if (canEdit) {
                    if (currentReport.approvalStatus === DRAFT) {
                        editCallback();
                    } else {
                        openReturnReportToDraftModal({
                            callback: () => editCallback(),
                        });
                    }
                }
            };
        },
    })
)(CustodialPropertySummary);

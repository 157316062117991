import React from 'react';
import { compose, mapProps } from 'recompose';
import { compact, map } from 'lodash';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import withFields from '~/client-common/core/fields/components/withFields';

import {
    DISPLAY_CASE_ASSIGNEE,
    DISPLAY_CASE_ASSISTING_INVESTIGATORS,
} from '~/client-common/core/enums/universal/fields';

const InvestigatorsCell = function ({ elasticCase, fieldDisplayNames }) {
    const { assignee, assistingInvestigators } = getViewModelProperties(elasticCase);
    const assigneeString = assignee.fullNameWithFirstInitial || assignee.default || '';
    const assistingInvestigatorsString = compact(
        map(assistingInvestigators, (i) => i.fullNameWithFirstInitial || i.default || '')
    ).join(', ');
    return (
        <>
            <div>
                {fieldDisplayNames.DISPLAY_CASE_ASSIGNEE}: {assigneeString}
            </div>
            {assistingInvestigatorsString && (
                <div>
                    {fieldDisplayNames.DISPLAY_CASE_ASSISTING_INVESTIGATORS}:{' '}
                    {assistingInvestigatorsString}
                </div>
            )}
        </>
    );
};

export default compose(
    mapProps((ownerProps) => ({ elasticCase: ownerProps })),
    withFields([DISPLAY_CASE_ASSIGNEE, DISPLAY_CASE_ASSISTING_INVESTIGATORS])
)(InvestigatorsCell);

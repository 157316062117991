import { EvidencePrintingTemplate, EvidencePrintingTemplateAdminView } from '@mark43/evidence-api';
import { get } from 'lodash';

import getEvidencePrintingTemplatesResource from '../../resources/evidencePrintingTemplatesResource';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { storeFiles } from '../../../files/state/data';
import {
    storeTemplateFileLinks,
    replaceTemplateFileLinks,
} from '../../../template-file-links/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

const evidencePrintingTemplatesModule = createNormalizedModule<EvidencePrintingTemplate>({
    type: 'evidencePrintingTemplates',
});

// ACTION TYPES
const LOAD_EVIDENCE_PRINTING_TEMPLATES_START =
    'evidence-PrintingTemplates/LOAD_EVIDENCE_PRINTING_TEMPLATES_START';
const LOAD_EVIDENCE_PRINTING_TEMPLATES_SUCCESS =
    'evidence-PrintingTemplates/LOAD_EVIDENCE_PRINTING_TEMPLATES_SUCCESS';
const LOAD_EVIDENCE_PRINTING_TEMPLATES_FAILURE =
    'evidence-PrintingTemplates/LOAD_EVIDENCE_PRINTING_TEMPLATES_FAILURE';
const SAVE_EVIDENCE_PRINTING_TEMPLATE_START =
    'evidence-PrintingTemplates/SAVE_EVIDENCE_PRINTING_TEMPLATE_START';
const SAVE_EVIDENCE_PRINTING_TEMPLATE_SUCCESS =
    'evidence-PrintingTemplates/SAVE_EVIDENCE_PRINTING_TEMPLATE_SUCCESS';
const SAVE_EVIDENCE_PRINTING_TEMPLATE_FAILURE =
    'evidence-PrintingTemplates/SAVE_EVIDENCE_PRINTING_TEMPLATE_FAILURE';
export const DELETE_EVIDENCE_PRINTING_TEMPLATE_START =
    'evidence-PrintingTemplates/DELETE_EVIDENCE_PRINTING_TEMPLATE_START';
export const DELETE_EVIDENCE_PRINTING_TEMPLATE_SUCCESS =
    'evidence-PrintingTemplates/DELETE_EVIDENCE_PRINTING_TEMPLATE_SUCCESS';
export const DELETE_EVIDENCE_PRINTING_TEMPLATE_FAILURE =
    'evidence-PrintingTemplates/DELETE_EVIDENCE_PRINTING_TEMPLATE_FAILURE';

// ACTIONS
const storeEvidencePrintingTemplates = evidencePrintingTemplatesModule.actionCreators.storeEntities;
const deleteEvidencePrintingTemplateFromState =
    evidencePrintingTemplatesModule.actionCreators.deleteEntity;

function loadEvidencePrintingTemplatesStart() {
    return { type: LOAD_EVIDENCE_PRINTING_TEMPLATES_START };
}
function loadEvidencePrintingTemplatesSuccess() {
    return { type: LOAD_EVIDENCE_PRINTING_TEMPLATES_SUCCESS };
}
function loadEvidencePrintingTemplatesFailure(errorMessage: string) {
    return {
        type: LOAD_EVIDENCE_PRINTING_TEMPLATES_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Load all evidence printing templates in the department.
 */
export function loadEvidencePrintingTemplates(): ClientCommonAction<
    Promise<EvidencePrintingTemplateAdminView>
> {
    const evidencePrintingTemplatesResource = getEvidencePrintingTemplatesResource();

    return (dispatch) => {
        dispatch(loadEvidencePrintingTemplatesStart());
        return evidencePrintingTemplatesResource
            .getEvidencePrintingTemplates()
            .then((evidencePrintingTemplates: EvidencePrintingTemplateAdminView) => {
                const { templates, files, templateFileLinks } = evidencePrintingTemplates;

                dispatch(storeEvidencePrintingTemplates(templates));
                dispatch(storeFiles(files));
                dispatch(storeTemplateFileLinks(templateFileLinks));
                dispatch(loadEvidencePrintingTemplatesSuccess());

                return evidencePrintingTemplates;
            })
            .catch((err: Error) => {
                dispatch(loadEvidencePrintingTemplatesFailure(err.message));
                throw err;
            });
    };
}

function saveEvidencePrintingTemplateStart() {
    return { type: SAVE_EVIDENCE_PRINTING_TEMPLATE_START };
}
function saveEvidencePrintingTemplateSuccess() {
    return { type: SAVE_EVIDENCE_PRINTING_TEMPLATE_SUCCESS };
}
function saveEvidencePrintingTemplateFailure(errorMessage: string) {
    return {
        type: SAVE_EVIDENCE_PRINTING_TEMPLATE_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Create or update an evidence printing template.
 * @param  {Object}  evidencePrintingTemplate
 * @return {Promise<Object>}
 */
export function saveEvidencePrintingTemplate(
    evidencePrintingTemplate: EvidencePrintingTemplate
): ClientCommonAction<Promise<EvidencePrintingTemplateAdminView>> {
    const evidencePrintingTemplatesResource = getEvidencePrintingTemplatesResource();

    return (dispatch) => {
        dispatch(saveEvidencePrintingTemplateStart());
        return evidencePrintingTemplatesResource
            .upsertEvidencePrintingTemplate(evidencePrintingTemplate)
            .then((evidencePrintingTemplate: EvidencePrintingTemplateAdminView) => {
                const { templates, files, templateFileLinks } = evidencePrintingTemplate;
                // only one template returned on upsert
                const evidencePrintingTemplateId = get(templates, '[0].id');

                dispatch(storeEvidencePrintingTemplates(templates));
                dispatch(storeFiles(files));
                dispatch(
                    replaceTemplateFileLinks({ evidencePrintingTemplateId }, templateFileLinks)
                );
                dispatch(saveEvidencePrintingTemplateSuccess());

                return evidencePrintingTemplate;
            })
            .catch((err: Error) => {
                dispatch(saveEvidencePrintingTemplateFailure(err.message));
                throw err;
            });
    };
}

function deleteEvidencePrintingTemplateStart() {
    return { type: DELETE_EVIDENCE_PRINTING_TEMPLATE_START };
}
function deleteEvidencePrintingTemplateSuccess() {
    return { type: DELETE_EVIDENCE_PRINTING_TEMPLATE_SUCCESS };
}
function deleteEvidencePrintingTemplateFailure(errorMessage: string) {
    return {
        type: DELETE_EVIDENCE_PRINTING_TEMPLATE_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Delete the evidence printing template for evidencePrintingTemplateId
 */
export function deleteEvidencePrintingTemplate(
    evidencePrintingTemplateId: number
): ClientCommonAction<Promise<void>> {
    const evidencePrintingTemplatesResource = getEvidencePrintingTemplatesResource();

    return (dispatch) => {
        dispatch(deleteEvidencePrintingTemplateStart());
        return evidencePrintingTemplatesResource
            .deleteEvidencePrintingTemplate(evidencePrintingTemplateId)
            .then(() => {
                dispatch(deleteEvidencePrintingTemplateFromState(evidencePrintingTemplateId));
                dispatch(deleteEvidencePrintingTemplateSuccess());
            })
            .catch((err: Error) => {
                dispatch(deleteEvidencePrintingTemplateFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const evidencePrintingTemplatesSelector =
    evidencePrintingTemplatesModule.selectors.entitiesSelector;

// REDUCER
export default evidencePrintingTemplatesModule.reducerConfig;

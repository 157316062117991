import { EntityTypeEnum } from '@mark43/rms-api';

import getLinkUrl from '../../../search/quick-search/helpers/getLinkUrl';
import { BRIEFINGS_ROUTES } from '../../../briefings/configuration/routes';
import { TaskEntityLinkFormDataShape } from '../forms/taskForm';
import { convertEntityTypeToElasticSearchType } from './convertEntityTypeToElasticSearchType';

// Briefing Entity is not mapped to ElasticSearchEntity, that's why it should be handled separately
export const getTaskEntityLinkUrl = (entityLink: TaskEntityLinkFormDataShape) => {
    switch (entityLink.entityType) {
        case EntityTypeEnum.BRIEFING.name:
            if (entityLink.entityId) {
                // should always be true
                return BRIEFINGS_ROUTES.item.url(entityLink.entityId);
            }
            return BRIEFINGS_ROUTES.root.url();
        default:
            return getLinkUrl({
                itemType: convertEntityTypeToElasticSearchType(entityLink),
                item: { id: entityLink.entityId },
            });
    }
};

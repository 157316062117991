import _, { map, size, take } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';
import styled from 'styled-components';
import { dispositionEventsByChainOfCustodyIdSelector } from '~/client-common/core/domain/disposition-events/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { renderOnlyIf } from '../../../../../legacy-redux/helpers/reactHelpers';
import testIds from '../../../../../core/testIds';
import DispositionHistoryRow from './DispositionHistoryRow';

const strings = componentStrings.reports.custodialPropertySummary.disposition.DispositionHistory;

const Header = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    margin-bottom: 15px;
    padding-bottom: 3px;
    text-transform: uppercase;
    border-bottom: ${(props) => `2px solid ${props.theme.colors.darkGrey}`};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const Link = styled.div`
    color: ${(props) => props.theme.colors.cobaltBlue};
    cursor: pointer;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.regular};
    margin-top: 15px;
`;

function _DispositionHistory({ className, collapsed = false, dispositionEventIds }) {
    const [historyCollapsed, setHistoryCollapsed] = useState(collapsed);
    const [prevCollapsed, setPrevCollapsed] = useState(collapsed);

    if (prevCollapsed !== collapsed) {
        setHistoryCollapsed(collapsed);
        setPrevCollapsed(collapsed);
    }

    return (
        <div className={className} data-test-id={testIds.DISPOSITION_HISTORY}>
            <Header>{strings.title}</Header>
            {map(historyCollapsed ? take(dispositionEventIds, 1) : dispositionEventIds, (id) => (
                <DispositionHistoryRow key={id} dispositionEventId={id} />
            ))}
            {size(dispositionEventIds) > 1 && (
                <Link onClick={() => setHistoryCollapsed(!historyCollapsed)}>
                    {historyCollapsed ? strings.showHistory : strings.hideHistory}
                </Link>
            )}
        </div>
    );
}

const DispositionHistory = styled(_DispositionHistory)`
    width: 100%;
    font-size: var(--arc-fontSizes-sm);
`;

const mapStateToProps = createStructuredSelector({
    dispositionEventsByChainOfCustodyId: dispositionEventsByChainOfCustodyIdSelector,
});

/**
 * The disposition history of an item listed under a header. This history
 *   consists of certain disposition events for the item in reverse
 *   chronological order. Only rejection and release expiration events are
 *   displayed, along with the approval events that preceded them (for the user
 *   to see context). This means that if the item has not been rejected or
 *   release expired, this component does not render. The reason is rejection
 *   events contain comments that need to be displayed, and release expiration
 *   is a special case where disposition status changes automatically. The other
 *   types of disposition events are considered not important enough to display
 *   by themselves.
 * @param {number} props.chainOfCustodyId
 */
export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['chainOfCustodyId', 'dispositionEventsByChainOfCustodyId'],
        ({ chainOfCustodyId, dispositionEventsByChainOfCustodyId }) => {
            const dispositionEvents = _.orderBy(
                dispositionEventsByChainOfCustodyId(chainOfCustodyId),
                ['eventDateUtc', 'createdDateUtc', 'rmsEventId'],
                ['desc', 'desc', 'desc']
            );

            return {
                dispositionEventIds: _.map(dispositionEvents, 'id'),
            };
        }
    ),

    // hide the component when no disposition events need to be shown
    renderOnlyIf(({ dispositionEventIds }) => dispositionEventIds.length > 0)
)(DispositionHistory);

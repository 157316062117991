import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, pure, withPropsOnChange } from 'recompose';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { chainOfCustodyViewModelsForMasterItemIdSelector } from '~/client-common/core/domain/chain-of-custodies/state/ui';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';

const strings = componentStrings.evidence.chainOfCustody.ChainOfCustody;

// All of the chain of custodies on the page
function ChainOfCustodyTitle({ chainOfCustodies }) {
    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];
    const title =
        chainOfCustodies.length > 1
            ? `${strings.title(custodyLabel)} (${chainOfCustodies.length} total)`
            : strings.title(custodyLabel);
    return <span>{title}</span>;
}

const mapStateToProps = createStructuredSelector({
    chainOfCustodyViewModelsForMasterItemId: chainOfCustodyViewModelsForMasterItemIdSelector,
});

/**
 * This component is primarily used for the entity-profile title
 * It solely just lists how many CoC's there are
 * @param  {Object[]} chainOfCustodies the same chain of custody view models as the main component
 */
export default compose(
    pure,
    connect(mapStateToProps),
    withPropsOnChange(
        ['masterItemId', 'chainOfCustodyViewModelsForMasterItemId'],
        ({ masterItemId, chainOfCustodyViewModelsForMasterItemId }) => ({
            chainOfCustodies: chainOfCustodyViewModelsForMasterItemId(masterItemId),
        })
    ),
    renderOnlyIf(({ chainOfCustodies }) => chainOfCustodies && chainOfCustodies.length > 0)
)(ChainOfCustodyTitle);

import { head } from 'lodash';
import { TowVehicleRelease } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'towVehicleReleases';

const towVehicleReleasesModule = createNormalizedModule<TowVehicleRelease>({
    type: NEXUS_STATE_PROP,
});

export const towVehicleReleasesWhereSelector =
    towVehicleReleasesModule.selectors.entitiesWhereSelector;
// Only 1 `TowVehicleRelease` per report
export const towVehicleReleaseByReportIdSelector = createSelector(
    towVehicleReleasesWhereSelector,
    (towVehicleReleasesWhere) => (reportId: number) => head(towVehicleReleasesWhere({ reportId }))
);

export default towVehicleReleasesModule.reducerConfig;

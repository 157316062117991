import React from 'react';
import { useSelector } from 'react-redux';
import { Fieldset as MFTFieldset, Observer, Fields } from 'markformythree';
import { getAttributesByIdsSelector } from '~/client-common/core/domain/attributes/state/data';
import { MFTNItems } from '../../../../../core/forms/components/NItems';
import { ArbiterMFTTextArea } from '../../../../../core/forms/components/TextArea';
import { ArbiterMFTCheckbox } from '../../../../../core/forms/components/checkboxes/Checkbox';
import Divider from '../../../../../../legacy-redux/components/core/Divider';
import { showLockupDetails } from '../../../state/ui/booking';
import DefendantProfile from './DefendantProfile';
import LockupDetails from './LockupDetails';
import ChargesNItems from './ChargesNItems';
import CourtCasesNItems from './CourtCasesNItems';

const BookingSidePanelForm: React.FC<{ priorJuvenileArrest: boolean }> = ({
    priorJuvenileArrest,
}) => {
    const getAttributesByIds = useSelector(getAttributesByIdsSelector);
    return (
        <div>
            <DefendantProfile />
            <MFTNItems
                renderRemoveButton={undefined}
                renderAddButton={undefined}
                path="charges"
                render={ChargesNItems}
            />
            <Observer
                subscriptions={{
                    charges: ['charges', Fields.DEEP_MODEL],
                }}
                render={({ charges }) =>
                    showLockupDetails(getAttributesByIds, charges) ? <LockupDetails /> : false
                }
            />
            <Divider />
            <MFTNItems path="courtCases" render={CourtCasesNItems} renderRemoveButton={undefined} />
            {!priorJuvenileArrest && (
                <MFTFieldset path="arrest">
                    <ArbiterMFTCheckbox path="juvenileTriedAsAdult" />
                    <ArbiterMFTTextArea path="juvenileTriedAsAdultNotes" />
                </MFTFieldset>
            )}
        </div>
    );
};

export default BookingSidePanelForm;

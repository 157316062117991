import { find, map } from 'lodash';

export function buildFilesToSave(uploadedFiles, entityLinkDetails = {}) {
    const { entityId, entityType, attachmentLinkType, imageLinkType } = entityLinkDetails;
    const config = { entityId, entityType, linkType: imageLinkType || attachmentLinkType };

    return map(uploadedFiles, (file) => ({ ...file, config }));
}

/**
 * Each element of the uploads array is an object with a file and config attribute:
 * file - A Mark43File object
 */
export function buildUploadingFiles(uploads, entityLinkDetails = {}) {
    const { entityId, entityType, attachmentLinkType, imageLinkType } = entityLinkDetails;
    const config = { entityId, entityType, linkType: imageLinkType || attachmentLinkType };

    return map(uploads, (upload) => ({
        file: {
            id: upload.preview,
            fileWebServerPath: '',
            originalFileName: upload.name,
            isLoading: true,
            uploadFailed: false,
            isDeleted: false,
        },
        config,
    }));
}

export function hasFailedUploads(uploadingFiles) {
    return find(uploadingFiles, (uploadingFile) => {
        return uploadingFile.file.uploadFailed && !uploadingFile.file.isDeleted;
    });
}

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { createFormModule } from '../../../../core/forms';

const fieldViewModels = {
    reportTypesIds: {
        key: 'reportTypeIds',
    },
};

const entityProfileReportsFilterForm = createFormModule({
    formName: formClientEnum.ENTITY_PROFILE_REPORTS_FILTER,
    fieldViewModels,
});

export default entityProfileReportsFilterForm;

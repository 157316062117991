import { routeNameSelector } from '../../../../../routing/routerModule';
import routesConfig from '../../../../../routing/routesConfig';
import { currentCaseIdSelector } from '.';

export function toggleCaseHistories(router) {
    return (dispatch, getState) => {
        const state = getState();
        const caseId = currentCaseIdSelector(state);
        const isHistoryView = routeNameSelector(state) === routesConfig.CASE_HISTORY.name;
        if (isHistoryView) {
            router.push(`/cases/${caseId}`);
        } else {
            router.push(`/cases/${caseId}/history`);
        }
    };
}

import {
    InferFormDataShape,
    createField,
    createFormConfiguration,
    lifecycleOptions,
} from 'markformythree';
import { RefContextEnum, AttributeTypeEnum, CaseReportLinkRemovalRequest } from '@mark43/rms-api';
import * as fields from '~/client-common/core/enums/universal/fields';
import formsRegistry from '../../../../../core/formsRegistry';

const reasonForRemovalConfig = createFormConfiguration({
    reasonForRemovalAttrId: createField<number>({
        fieldName: fields.CASE_REPORT_LINK_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_REMOVAL_ATTRIBUTE_ID,
    }),
    reasonForRemovalOther: createField<string>({
        fieldName:
            fields.CASE_REPORT_LINK_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_REMOVAL_OTHER_ATTRIBUTE_DESCRIPTION,
    }),
    reportingEventNumber: createField<string>({
        fieldName: fields.REPORT_REPORTING_EVENT_NUMBER,
    }),
    reportIds: createField<number[]>({}),
    caseId: createField<number>({}),
});

type ReasonForRemovalFormConfiguration = typeof reasonForRemovalConfig;

type ReasonForRemovalToCaseFormDataShape = InferFormDataShape<ReasonForRemovalFormConfiguration>;

export function convertToFormModel({
    reportIds,
    reportingEventNumber,
    caseId,
}: {
    reportIds: number[];
    reportingEventNumber?: string;
    caseId: number;
}) {
    return {
        reportingEventNumber,
        reportIds,
        caseId,
    };
}

export function convertFromFormModelToRequest(
    formModel: ReasonForRemovalToCaseFormDataShape,
    selectedReportIds?: number[]
): CaseReportLinkRemovalRequest | CaseReportLinkRemovalRequest[] {
    const isRen = !!formModel.reportingEventNumber;

    if (selectedReportIds?.length) {
        return selectedReportIds.map((reportId) => ({
            caseId: formModel.caseId || -1,
            linkedRecordWithoutRENId: reportId,
            reasonForRemoval: {
                attributeId: formModel.reasonForRemovalAttrId,
                attributeType: AttributeTypeEnum.REASON_FOR_REMOVAL.name,
                otherAttributeDescription: formModel.reasonForRemovalOther,
            },
        }));
    }

    if (isRen) {
        return {
            caseId: formModel.caseId || -1,
            linkedReportREN: formModel.reportingEventNumber,
            reasonForRemoval: {
                attributeId: formModel.reasonForRemovalAttrId,
                attributeType: AttributeTypeEnum.REASON_FOR_REMOVAL.name,
                otherAttributeDescription: formModel.reasonForRemovalOther,
            },
        };
    } else {
        return {
            caseId: formModel.caseId || -1,
            linkedRecordWithoutRENId: formModel.reportIds?.[0],
            reasonForRemoval: {
                attributeId: formModel.reasonForRemovalAttrId,
                attributeType: AttributeTypeEnum.REASON_FOR_REMOVAL.name,
                otherAttributeDescription: formModel.reasonForRemovalOther,
            },
        };
    }
}

export function resetReasonForRemovalForm() {
    const form = formsRegistry.get(RefContextEnum.FORM_REASON_FOR_REMOVAL_MODAL.name);

    if (form) {
        form.resetModel();
    }
}

export const reasonForRemovalForm = {
    name: RefContextEnum.FORM_REASON_FOR_REMOVAL_MODAL.name,
    context: RefContextEnum.FORM_REASON_FOR_REMOVAL_MODAL.name,
    configuration: reasonForRemovalConfig,
    lifecycle: lifecycleOptions.REGISTER_AND_RETAIN,
};

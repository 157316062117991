import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Form, lifecycleOptions } from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import testIds from '../../../core/testIds';
import { RmsDispatch } from '../../../core/typings/redux';
import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import NoDataBlock from '../../core/components/NoDataBlock';
import redirectToErrorPage from '../../core/utils/redirectToErrorPage';
import { SimpleLoading } from '../../../legacy-redux/components/core/Loading';
import Row from '../../core/components/Row';
import { MFTCheckbox } from '../../core/forms/components/checkboxes/Checkbox';

import {
    complianceExportTypesLoadedSelector,
    disableComplianceExportFormControlsSelector,
    uiDisableComplianceExportFormControls,
} from '../state/ui';
import { useComplianceExportContext } from '../contexts/ComplianceExportContext';
import useCreateGenerateExportErrors from '../hooks/useCreateGenerateExportErrors';
import useUpdateFormNotMatchingErrorsState from '../hooks/useUpdateFormNotMatchingErrorsState';
import useGenerateErrors from '../hooks/useGenerateErrors';
import ComplianceExportForm, { complianceExportFormConfiguration } from './ComplianceExportForm';
import ComplianceErrorsCounts from './ComplianceErrorsCounts';
import ComplianceExportDownloadButton from './ComplianceExportDownloadButton';
import ComplianceExportErrors from './ComplianceExportErrors';
import ComplianceInlineButton from './ComplianceInlineButton';
import ComplianceSectionHeader from './ComplianceSectionHeader';
import ComplianceErrorNotification from './ComplianceErrorNotification';

type Params = { complianceExportType: string };
type ComplianceExportProps = RouteComponentProps<Params, Record<string, unknown>>;

const strings = componentStrings.compliance.ComplianceExport;
const formLabelStrings = componentStrings.compliance.ComplianceExportForm.labels;

const GenerateButton = styled(Button)`
    width: max-content;
    margin-top: 19px;
`;

const TopSectionWrapper = styled.div`
    margin: 30px 0;
    display: flex;
    align-items: center;
`;

const ContentWrapper = styled.div`
    background: ${(props) => props.theme.colors.white};
    box-sizing: border-box;
    min-height: 100%;
    padding: 10px;
`;

const ErrorsStatsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const ExportButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
`;

const Centered = styled.div`
    text-align: center;
`;

// DownloadButtons has awkward wrapper
const InlineDownloadButton = styled(ComplianceExportDownloadButton)`
    width: max-content;
    ${/* sc-select */ ComplianceInlineButton} {
        margin-top: 17px;
    }
`;

const PushLegacyExportButtonRight = styled.span`
    margin-left: auto;
`;

const ComplianceExport: React.FC<ComplianceExportProps> = () => {
    const { currentAgencyId, complianceExportType, isNibrs } = useComplianceExportContext();
    const disableFormControls = useSelector(disableComplianceExportFormControlsSelector);
    const complianceExportTypesLoaded = useSelector(complianceExportTypesLoadedSelector);

    const [, forceUpdate] = useReducer(() => ({}), {});

    const dispatch = useDispatch<RmsDispatch>();

    const supportsErrors = !!complianceExportType?.supportsErrors;

    const {
        callResource: callGenerateErrorsResource,
        loading: generateErrorsLoadingState,
        nibrsErrorCounts,
        agencyName,
        resetErrors,
        errorSummary,
        onFetchErrorsSuccess,
    } = useGenerateErrors(complianceExportType);

    const {
        isFormMatchingErrorsGenerated,
        setIsFormMatchingErrorsGenerated,
        updateFormNotMatchingErrorsState,
    } = useUpdateFormNotMatchingErrorsState(supportsErrors, resetErrors);

    const generateExportErrorsHandler = useCreateGenerateExportErrors(
        callGenerateErrorsResource,
        currentAgencyId,
        complianceExportType,
        setIsFormMatchingErrorsGenerated
    );

    const offenseDisplayName = useOffenseFieldName();

    const downloadDisabled = generateErrorsLoadingState.isLoading || !isFormMatchingErrorsGenerated;

    useEffect(() => {
        if (!complianceExportTypesLoaded || complianceExportType) {
            return;
        }

        dispatch(redirectToErrorPage());
    }, [complianceExportTypesLoaded, complianceExportType, dispatch, isNibrs]);

    // disable form controls while errors loading
    useEffect(() => {
        if (generateErrorsLoadingState.isLoading && !disableFormControls) {
            dispatch(uiDisableComplianceExportFormControls(true));
        } else if (!generateErrorsLoadingState.isLoading && disableFormControls) {
            dispatch(uiDisableComplianceExportFormControls(false));
        }
    }, [disableFormControls, dispatch, generateErrorsLoadingState.isLoading]);

    if (!complianceExportType) {
        return null;
    }

    return (
        <ContentWrapper>
            <TopSectionWrapper>
                <ComplianceExportForm
                    complianceExportType={complianceExportType}
                    buttonElement={
                        !supportsErrors ? (
                            <InlineDownloadButton complianceExportType={complianceExportType} />
                        ) : (
                            <GenerateButton
                                testId={testIds.COMPLIANCE_DASHBOARD_GENERATE_ERRORS_BUTTON}
                                disabled={generateErrorsLoadingState.isLoading}
                                onClick={generateExportErrorsHandler}
                                className={buttonTypes.PRIMARY}
                            >
                                {strings.labels.generateErrorsButton}
                            </GenerateButton>
                        )
                    }
                    disabled={disableFormControls}
                    forceUpdate={forceUpdate}
                    updateFormNotMatchingErrorsState={updateFormNotMatchingErrorsState}
                    generateExportErrorsHandler={generateExportErrorsHandler}
                />
                <FeatureFlagged flag="RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED">
                    <ComplianceErrorsCounts
                        testId={testIds.COMPLIANCE_DASHBOARD_GENERATE_ERRORS_SUMMARY}
                        data={nibrsErrorCounts}
                    />
                </FeatureFlagged>
            </TopSectionWrapper>
            <FeatureFlagged flag="RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED">
                <Row>
                    {isNibrs && (
                        <Form
                            configuration={complianceExportFormConfiguration}
                            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                            name={formClientEnum.COMPLIANCE_EXPORT}
                            render={() => (
                                <ExportButtonRow>
                                    <FeatureFlagged flag="NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX_ENABLED">
                                        <MFTCheckbox
                                            disabled={disableFormControls}
                                            testId={
                                                testIds.COMPLIANCE_DASHBOARD_NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX
                                            }
                                            helpText={formLabelStrings.exportZeroReportSegmentHelpText(
                                                offenseDisplayName
                                            )}
                                            label={formLabelStrings.exportZeroReportSegmentCheckbox}
                                            onChange={updateFormNotMatchingErrorsState}
                                            path="exportZeroReportSegment"
                                        />
                                    </FeatureFlagged>
                                    <ComplianceExportDownloadButton
                                        disabled={downloadDisabled}
                                        complianceExportType={complianceExportType}
                                        onFetchErrorsSuccess={onFetchErrorsSuccess}
                                    />
                                </ExportButtonRow>
                            )}
                        />
                    )}
                </Row>
            </FeatureFlagged>
            {!supportsErrors && (
                <Centered>
                    <NoDataBlock
                        testId={testIds.COMPLIANCE_DASHBOARD_COMPLIANCE_EXPORT_NO_DATA_BLOCK}
                    >
                        {strings.doesNotSupportErrors}
                    </NoDataBlock>
                </Centered>
            )}
            {generateErrorsLoadingState.isLoading && <SimpleLoading />}
            {supportsErrors && !generateErrorsLoadingState.isLoading && (
                <>
                    <FeatureFlagged
                        flag="RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED"
                        fallback={
                            <>
                                {agencyName && (
                                    <ComplianceSectionHeader>
                                        {strings.header(agencyName)}
                                    </ComplianceSectionHeader>
                                )}
                                <ErrorsStatsWrapper>
                                    <ComplianceErrorsCounts
                                        testId={
                                            testIds.COMPLIANCE_DASHBOARD_GENERATE_ERRORS_SUMMARY
                                        }
                                        data={nibrsErrorCounts}
                                    />
                                    <PushLegacyExportButtonRight>
                                        <ComplianceExportDownloadButton
                                            disabled={downloadDisabled}
                                            complianceExportType={complianceExportType}
                                            onFetchErrorsSuccess={onFetchErrorsSuccess}
                                        />
                                    </PushLegacyExportButtonRight>
                                </ErrorsStatsWrapper>
                            </>
                        }
                    />
                    <ComplianceExportErrors errorSummary={errorSummary} />
                </>
            )}
            {!generateErrorsLoadingState.isLoading && generateErrorsLoadingState.errorMessage && (
                <ComplianceErrorNotification>
                    {generateErrorsLoadingState.errorMessage}
                </ComplianceErrorNotification>
            )}
        </ContentWrapper>
    );
};

export default ComplianceExport;

/* ACTION TYPES */
const actionTypes = {
    UNDO: 'UNDO',
    REDO: 'REDO',
} as const;

/* ACTIONS */

export const undoAction = () => {
    return {
        type: actionTypes.UNDO,
    } as const;
};

export const redoAction = () => {
    return {
        type: actionTypes.REDO,
    } as const;
};

export type CrashDiagramHistoryActionTypes = ReturnType<typeof undoAction | typeof redoAction>;

import keyMirror from 'keymirror';

/**
 * Client-only enum for form field types.
 * @see  https://readme.mark43.io/guides/products/rms/rms-frontend/rmsfe-form-modules/#form-field-view-models
 */
export default keyMirror({
    ABILITY: null,
    ACCOUNT_STATUS: null,
    AGENCY: null,
    ATTRIBUTE: null,
    CALL_FOR_SERVICE: null,
    CASE_STATUS: null,
    CASE_DEFINITION: null,
    CHAIN_EVENT_TYPE: null, // evidence
    CLIENT_APPROVAL_STATUS: null,
    CUSTOM_REPORT_CLASSIFICATION: null,
    DATE: null,
    DATE_PRESET: null,
    DATE_TIME: null,
    DEPARTMENT: null,
    DISPATCH_AREA: null,
    DISPOSITION_STATUS: null, // evidence
    EVENT_LABEL: null,
    ENTITY_TYPE: null,
    FACILITY: null, // evidence
    FIELDSET: null,
    INVOLVEMENT_STATUS: null, // evidence
    LABEL: null,
    LINK_TYPE: null,
    MULTI_FIELD: null,
    N_FIELDSETS: null,
    N_ITEMS_FIELDSET: null,
    NIBRS_CODE: null,
    NIBRS_CODE_ID: null,
    OFFENSE_CASE_STATUS: null,
    OFFENSE_CODE: null,
    OFFICER_INVOLVEMENT: null,
    RADIO_CHANNEL: null,
    RANGE: null,
    REPORT_DEFINITION: null,
    RESOLVER: null,
    ROLE: null,
    SECONDARY_APPROVAL_STATUS: null,
    STORAGE_LOCATION: null, // evidence
    UCR_CODE: null,
    UNIT: null,
    USER: null,
    UNIT_STATE: null,
    VEHICLE_MAKE: null,
    VEHICLE_MODEL: null,
    STATION: null,
    GANG_NAME: null,
    SUBDIVISION: null,
    ATF_MANUFACTURER: null,
});

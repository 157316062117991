import React from 'react';
import styled from 'styled-components';
import { EntityTypeEnum } from '@mark43/rms-api';
import { cssVar } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

import Icon, { iconTypes, iconSizes } from '../components/Icon';
import testIds from '../../../core/testIds';
import EntitySearchResultsSection from './EntitySearchResultsSection';

const strings = componentStrings.core.entitySearch;

const EntitySearchResultsWrapper = styled.div`
    position: absolute;
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    float: left;
    border-top: 0;
    width: 100%;
    overflow: auto;
    max-height: calc(85vh - 255px);

    ::-webkit-scrollbar {
        /* stylelint-disable property-no-vendor-prefix */
        -webkit-appearance: none;
        width: 7px;
        background-color: ${(props) => props.theme.colors.white};
    }

    /* stylelint-enable property-no-vendor-prefix */
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${(props) => props.theme.colors.mediumLightGrey};
    }

    .entity-search-results-container ~ .entity-search-results-container {
        border-top: 1px solid ${cssVar('arc.colors.border.default')};
    }
`;

export const EntitySearchNotification = styled.div`
    font: ${(props) =>
        `${props.theme.fontWeights.semiBold} var(--arc-fontSizes-md) ${props.theme.fontFamilies.proximaNova}`};
    color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.darkGrey)};
    background: ${(props) => props.theme.colors.white};
    padding: 16px 6px;
    white-space: pre-wrap;
`;

const Wrapper = styled.div`
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
    display: flex;
    cursor: pointer;
    padding: 16px 6px;
    align-items: center;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: var(--arc-fontSizes-sm);
    background: ${(props) => props.theme.colors.white};

    .addIcon {
        margin-left: 6px;
    }

    .addText {
        margin-left: 14px;
    }

    &:hover {
        background: ${({ theme }) => theme.colors.lightBlue};
    }
`;

const EntitySearchResults = ({
    items,
    allSearchItems,
    elasticQuery,
    totalCount,
    searchType,
    isLoading,
    query,
    error,
    onResultClick,
    loadMore,
    loadMoreBookings,
    onAddClick,
    addButtonText,
    onClose,
    renderHeader,
    renderFooter,
    showCheckbox,
    hideAddIcon = false,
    hideCloseButton = false,
    selectedEntityIds,
    setSelectedEntityIds,
    bookingState,
}) => {
    const errors = [error, bookingState.error];
    const hasErrors = errors.filter(Boolean).length > 0;
    const itemsAreLoading = isLoading || bookingState.isLoading;
    const showNoResultsMessage = !itemsAreLoading && query && !items.length > 0 && !hasErrors;
    const showErrorMessage = query && hasErrors;
    const showSearchingMessage = itemsAreLoading;
    const showResults = !itemsAreLoading && query && items.length > 0;
    const showBooking = !itemsAreLoading && bookingState.items.length > 0;

    const handleBookingItemClick = ({ item }) => {
        const origin = window.location.origin;
        const path = `jms/bookings/${item.id}`;
        const url = `${origin}/${path}`;
        onAddClick({ url, bookingNumber: item.bookingNumber });
    };

    if (!query) {
        return <div />;
    }
    return (
        <EntitySearchResultsWrapper>
            {!hideAddIcon && (
                <Wrapper data-test-id={testIds.ENTITY_SEARCH_ADD_ITEM} onMouseDown={onAddClick}>
                    <Icon
                        className="addIcon"
                        size={iconSizes.MEDIUM}
                        color="cobaltBlue"
                        type={iconTypes.ADD}
                    />
                    <span className="addText">{addButtonText}</span>
                </Wrapper>
            )}
            {showNoResultsMessage && (
                <EntitySearchNotification error={false}>
                    {strings.noResults}
                </EntitySearchNotification>
            )}
            {showSearchingMessage && (
                <EntitySearchNotification error={false}>
                    {strings.searching}
                </EntitySearchNotification>
            )}
            {showErrorMessage && (
                <EntitySearchNotification error={true}>
                    {strings.error}
                    <br />
                    {joinTruthyValues(
                        errors.map((error) => error?.message),
                        '\n'
                    )}
                </EntitySearchNotification>
            )}
            {showResults && (
                <EntitySearchResultsSection
                    type={searchType}
                    query={query}
                    items={items}
                    allSearchItems={allSearchItems}
                    from={elasticQuery.from}
                    size={elasticQuery.size}
                    totalCount={totalCount}
                    onResultClick={onResultClick}
                    loadMore={loadMore}
                    onClose={onClose}
                    renderHeader={renderHeader}
                    renderFooter={renderFooter}
                    showCheckbox={showCheckbox}
                    hideCloseButton={hideCloseButton || showBooking}
                    selectedEntityIds={selectedEntityIds}
                    setSelectedEntityIds={setSelectedEntityIds}
                    containerClassName="entity-search-results-container"
                />
            )}
            {showBooking && (
                <EntitySearchResultsSection
                    type={EntityTypeEnum.BOOKING.name}
                    query={query}
                    items={bookingState.items}
                    allSearchItems={bookingState.allSearchItems}
                    from={bookingState.offset}
                    size={bookingState.limit}
                    totalCount={bookingState.totalCount}
                    onResultClick={handleBookingItemClick}
                    loadMore={loadMoreBookings}
                    onClose={onClose}
                    renderHeader={renderHeader}
                    renderFooter={renderFooter}
                    showCheckbox={showCheckbox}
                    hideCloseButton={hideCloseButton}
                    selectedEntityIds={selectedEntityIds}
                    setSelectedEntityIds={setSelectedEntityIds}
                    containerClassName="entity-search-results-container"
                />
            )}
        </EntitySearchResultsWrapper>
    );
};

export default EntitySearchResults;

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { noop, get } from 'lodash';
import { casesSelector } from '~/client-common/core/domain/cases/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import withFields from '~/client-common/core/fields/components/withFields';
import { CASE_LOCAL_ID } from '~/client-common/core/enums/universal/fields';
import FormExports from '../../../../legacy-redux/components/exports/FormExports';
import { ExportPageWithColumns } from '../../../../legacy-redux/components/exports/LayoutComponents';
import { exportPageReleaseTrackingConfigSelector } from '../../../../legacy-redux/selectors/exportsSelectors';
import { ReleaseTracking } from '../../../core/pdf-exports/release-tracking/components/ReleaseTracking';

const strings = componentStrings.exports.CombinedExports;

function CaseExports({ params, cases, exportPageReleaseTrackingConfig, fieldDisplayNames }) {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const { caseId } = params;
    const parsedCaseId = parseInt(caseId, 10);
    const caseNumber = get(cases[parsedCaseId], 'localId');
    const { showReleaseTracking } = exportPageReleaseTrackingConfig(EntityTypeEnum.CASE.name);
    return (
        <ExportPageWithColumns
            leftColumn={
                <FormExports
                    // no special behavior on close
                    closeCallback={noop}
                    useExportRouteToggle={true}
                    includeAttachments={true}
                    showIncludeNameAddendums={true}
                    showCombinedPDF={
                        applicationSettings.RMS_CASE_FOLDERING_ENABLED ||
                        applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE
                    }
                    showFieldRedaction={true}
                    defaultGroupTitle={strings.Case.groupTitle(
                        fieldDisplayNames.CASE_LOCAL_ID,
                        caseNumber
                    )}
                    /**
                     * When case foldering is off, all the selected packets are combined into 1 PDF.
                     * When case foldering is off, the PDFs are separated in the exported ZIP, so the "Combined PDF"
                     *   title does not apply, hence this empty string. We don't need to replace "Combined PDF" with
                     *   the case number because the case number is already part of the dynamic `groupTitle`, otherwise
                     *   the joined string would be redundant (e.g. "Case Number 123 - Case # 123").
                     */
                    defaultFilename={
                        applicationSettings.RMS_CASE_FOLDERING_ENABLED ? '' : strings.title
                    }
                    trackReleases={true}
                    entityId={parsedCaseId}
                    entityType={EntityTypeEnum.CASE.name}
                    excludedAttachmentTypes={[EntityTypeEnum.CASE_NOTE.name]}
                />
            }
            rightColumn={
                showReleaseTracking ? (
                    <ReleaseTracking
                        entityType={EntityTypeEnum.CASE.name}
                        entityId={parsedCaseId}
                    />
                ) : undefined
            }
        />
    );
}

const mapStateToProps = createStructuredSelector({
    cases: casesSelector,
    exportPageReleaseTrackingConfig: exportPageReleaseTrackingConfigSelector,
});

export default compose(
    withRouter,
    connect(mapStateToProps, null),
    withFields([CASE_LOCAL_ID])
)(CaseExports);

import { noop } from 'lodash';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { HStack, IconButton } from 'arc';
import { LinkTypesEnum } from '@mark43/rms-api';

import { ImportItemsSidePanel } from '../sidepanel/ImportItemsSidePanel';
import { ImportableItemsProvider } from '../sidepanel/content/ImportableItemsContent';
import { EFileMaterialHeader } from './EFileMaterialHeader';
import { EFileMaterialItemsGrid } from './EFileMaterialItemsGrid';
import { EFileMaterialItemsProvider } from './EFileMaterialItemsProvider';

export const EFileMaterialItemsPage = ({
    params,
}: RouteComponentProps<{ eFileId: string }, Record<string, unknown>>): JSX.Element => {
    const eFileId = parseInt(params.eFileId, 10);
    const [onImport, setOnImport] = React.useState<() => void>(noop);

    return (
        <>
            <EFileMaterialItemsProvider>
                <EFileMaterialHeader eFileId={eFileId} linkType={LinkTypesEnum.ITEM_IN_EFILE}>
                    <HStack>
                        <IconButton
                            aria-label="Filter"
                            icon="Filter"
                            size="md"
                            variant="outline"
                            disabled={true}
                        />
                        <ImportableItemsProvider>
                            <ImportItemsSidePanel onImport={onImport} />
                        </ImportableItemsProvider>
                    </HStack>
                </EFileMaterialHeader>
                <EFileMaterialItemsGrid eFileId={eFileId} setOnImport={setOnImport} />
            </EFileMaterialItemsProvider>
        </>
    );
};

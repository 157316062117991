import _ from 'lodash';
import { createSelector } from 'reselect';
import { historyEventsSelector } from '../data';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
} from '../../../attributes/state/data';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';
import {
    convertHistoryArrayToHistoryEventViewModels,
    HistoryEventViewModel,
} from '../../utils/historyEventHelpers';
import { currentDepartmentDateFormatterSelector } from '../../../current-user/state/ui';

const historyEventViewModelsSelector = createSelector(
    historyEventsSelector,
    getAttributeByIdSelector,
    formatAttributeByIdSelector,
    allMiniUserFormatsByIdSelector,
    currentDepartmentDateFormatterSelector,
    (
        historyEvents,
        attributeById,
        formatAttributeById,
        allMiniUserFormatsById,
        dateTimeFormatter
    ): HistoryEventViewModel[] => {
        const histories = historyEvents ? Object.values(historyEvents) : [];

        return convertHistoryArrayToHistoryEventViewModels(histories, {
            formatUserById: (id) => allMiniUserFormatsById(id)?.fullNameWithFirstInitialAndIdNumber,
            dateTimeFormatter,
            attributeById,
            formatAttributeById,
        });
    }
);

export const historyEventViewModelsByEntityIdSelector = createSelector(
    historyEventViewModelsSelector,
    (historyEventViewModels) => (entityId: number): HistoryEventViewModel[] => {
        return _.filter(historyEventViewModels, { entityId });
    }
);

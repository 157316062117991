import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/gps/api/service_host';
const BASE_PATH = 'configuration';

export default createResource({
    name: 'Cad Desktop Config Resource',
    methods: {
        getCadDesktopConfigs(desktopDepartmentId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/${desktopDepartmentId}/department`,
            });
        },
        createCadDesktopConfig(cadDesktopConfig) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}`,
                data: cadDesktopConfig,
            });
        },
        updateCadDesktopConfig(cadDesktopConfig) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}`,
                data: cadDesktopConfig,
            });
        },
        deleteCadDesktopConfig(id) {
            return req({
                method: 'DELETE',
                baseUrl: BASE_URL,
                url: `delete/${id}`,
            });
        },
        getCadDesktopConfigHistory(desktopConfigId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/${desktopConfigId}/histories`,
            });
        },
        getAllCadDesktopConfigsHistory() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/histories`,
            });
        },

        /**
         * Export all desktop configs into a CSV.
         * @return {Promise}
         */
        exportDesktopConfigs() {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/export`,
            });
        },

        /**
         * Validate that a file is valid for importing new desktop configs.
         * @return {Promise}
         */
        validateDesktopConfigImport(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/validate/${fileId}`,
            });
        },

        /**
         * Import desktop configs from an uploaded file.
         * @return {Promise}
         */
        importDesktopConfigs(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/${fileId}`,
            });
        },
    },
});

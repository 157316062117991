import React from 'react';
import { connect } from 'react-redux';
import { Form, lifecycleOptions } from 'markformythree';
import { BlacksmithTypeEnum, CodeTypeSourceEnum } from '@mark43/partnerships-api';
import { getDropdownOptionsFromEnum } from '~/client-common/helpers/dropdownOptionHelpers';
import { MFTSelect } from '../../../core/forms/components/selects/Select';
import { MFTCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { MFTText } from '../../../core/forms/components/Text';
import { MFTTextArea } from '../../../core/forms/components/TextArea';
import {
    halfPanelFormWidth,
    fullPanelFormWidth,
} from '../../../../legacy-redux/configs/adminConfig';
import Row from '../../../core/components/Row';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import { BLACKSMITH_INTEGRATION_FORM } from '../state/forms';
import { defaultIntegrationPrefill } from '../state/ui';

const codeTypeSourceOptions = getDropdownOptionsFromEnum(CodeTypeSourceEnum);
const blacksmithTypeOptions = getDropdownOptionsFromEnum(BlacksmithTypeEnum);

const maxBlacksmithTypeOptionsToShow = blacksmithTypeOptions.length;
const tooltipCollisionBoundary = document.querySelector('.mark43-react-side-panel');

function BlacksmithIntegrationForm({ currentUserDepartmentId }) {
    return (
        <Form
            name={BLACKSMITH_INTEGRATION_FORM}
            initialState={{
                ...defaultIntegrationPrefill,
                departmentId: currentUserDepartmentId,
            }}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            render={() => {
                return (
                    <div>
                        <Row>
                            <MFTSelect
                                label="Blacksmith Type"
                                path="blacksmithType"
                                width={fullPanelFormWidth}
                                options={blacksmithTypeOptions}
                                maxOptionsToShow={maxBlacksmithTypeOptionsToShow}
                            />
                        </Row>
                        <Row>
                            <MFTText
                                label="Display Name"
                                path="displayName"
                                width={fullPanelFormWidth}
                            />
                        </Row>
                        <Row>
                            <MFTSelect
                                label="Code Type Source"
                                path="codeTypeSource"
                                width={halfPanelFormWidth}
                                options={codeTypeSourceOptions}
                                helpText="Don't touch this."
                                helpTextCollisionBoundary={tooltipCollisionBoundary}
                            />
                        </Row>
                        <Row>
                            <MFTTextArea
                                label="Serialized Input"
                                path="serializedInput"
                                width={fullPanelFormWidth}
                                rows={8}
                            />
                        </Row>
                        <Row>
                            <MFTText
                                label="Timeout"
                                path="timeout"
                                width={halfPanelFormWidth}
                                helpText="Format: https://en.wikipedia.org/wiki/ISO_8601#Durations"
                                helpTextCollisionBoundary={tooltipCollisionBoundary}
                            />
                        </Row>
                        <Row>
                            <MFTText
                                label="RMS API Token"
                                path="rmsApiToken"
                                width={fullPanelFormWidth}
                            />
                        </Row>
                        <Row>
                            <MFTText
                                label="Department ID"
                                path="departmentId"
                                width={halfPanelFormWidth}
                            />
                        </Row>
                        <Row>
                            <MFTCheckbox
                                label="Is Chunked"
                                path="isChunked"
                                width={halfPanelFormWidth}
                                helpText="Split jobs to run over data in chunks. Each job starts automatically after the last."
                                helpTextCollisionBoundary={tooltipCollisionBoundary}
                            />
                            <MFTCheckbox
                                label="Create New User"
                                path="createNewUser"
                                width={halfPanelFormWidth}
                                helpText="Automatically create new blacksmith user and API token for integration."
                                helpTextCollisionBoundary={tooltipCollisionBoundary}
                            />
                        </Row>
                        <Row>
                            <MFTText
                                label="RMS API Base URL"
                                path="rmsApiBaseUrl"
                                width={halfPanelFormWidth}
                            />
                            <MFTText
                                label="CAD API Base URL"
                                path="cadApiBaseUrl"
                                width={halfPanelFormWidth}
                            />
                        </Row>
                        <Row>
                            <MFTText
                                label="Evidence API Base URL"
                                path="evidenceApiBaseUrl"
                                width={halfPanelFormWidth}
                            />
                            <MFTText
                                label="Data Exchange API Base URL"
                                path="dataExchangeApiBaseUrl"
                                width={halfPanelFormWidth}
                            />
                        </Row>
                        <Row>
                            <MFTText
                                label="Notifications API Base URL"
                                path="notificationsApiBaseUrl"
                                width={halfPanelFormWidth}
                            />
                            <MFTText
                                label="Partnerships API Base URL"
                                path="partnershipsApiBaseUrl"
                                width={halfPanelFormWidth}
                            />
                        </Row>
                    </div>
                );
            }}
        />
    );
}

export default connect((state) => ({
    currentUserDepartmentId: currentUserDepartmentIdSelector(state),
}))(BlacksmithIntegrationForm);

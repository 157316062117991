import React from 'react';
import FormRow from '../../forms/components/FormRow';
import { ArbiterMFTText } from '../../forms/components/Text';

export const PersonProfileNextOfKinFields = () => {
    return (
        <>
            <FormRow>
                <ArbiterMFTText path="motherName" length="md" />
                <ArbiterMFTText path="motherMaidenName" length="md" />
            </FormRow>
            <FormRow>
                <ArbiterMFTText path="fatherName" length="md" />
            </FormRow>
        </>
    );
};

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose, setPropTypes } from 'recompose';

import { userHasAbilitySelector } from '../state/data';

const mapStateToProps = createStructuredSelector({
    userHasAbility: userHasAbilitySelector,
});

/**
 * A component for when you need to know the results of an ability check in a child component
 * takes in a children function, and an abilityId
 * example use:
 * <Ability ability={abilitiesEnum.MODULE.EDIT_PERMISSIONS}>
 *  {({userHasAbility}) => (
 *   <PermissionsSelect options={someOptions} disabled={!userHasAbility} />
 *  )}
 *  </Ability>
 */
export default compose(
    connect(mapStateToProps),
    setPropTypes({
        userId: PropTypes.number.isRequired,
        children: PropTypes.func.isRequired,
        ability: PropTypes.number.isRequired,
    })
)(({ userHasAbility, userId, ability, children }) =>
    children({ userHasAbility: userHasAbility(userId, ability) })
);

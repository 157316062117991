import { flatten, map } from 'lodash';
import {
    Report,
    FieldNote,
    FieldNoteSummaryView,
    HydratedFieldNote,
    EntityTypeEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';
import { storeFieldNotes } from '~/client-common/core/domain/field-notes/state/data';
import getFieldNoteResource from '~/client-common/core/domain/field-notes/resources/fieldNoteResource';
import { convertLocationBundlesToLocationViews } from '~/client-common/core/domain/locations/utils/locationHelpers';

import { augmentActionAndStoreRmsHydratedPersonProfiles } from '~/client-common/core/domain/person-profiles/state/data';
import {
    attachmentsByEntitySelector,
    saveAttachmentsForEntities,
} from '~/client-common/core/domain/attachments/state/data';
import { RmsAction } from '../../../../../core/typings/redux';

export const loadFieldNotes = (isArchived: boolean): RmsAction<Promise<FieldNote[]>> => (
    dispatch
) => {
    const fieldNoteResource = getFieldNoteResource();
    return fieldNoteResource
        .getFieldNotesForCurrentUser(isArchived)
        .then((result: FieldNoteSummaryView[]) => {
            const fieldNotes = map(result, 'fieldNote');
            dispatch(storeFieldNotes(fieldNotes));
            return fieldNotes;
        });
};

export const getFullFieldNote = (fieldNoteId: number): RmsAction<Promise<HydratedFieldNote>> => (
    dispatch,
    getState,
    dependencies
) => {
    const fieldNoteResource = getFieldNoteResource();
    return fieldNoteResource.getFullFieldNote(fieldNoteId).then((result: HydratedFieldNote) => {
        const locationBundles = result.locations;
        const locationEntityLinks = flatten(map(locationBundles, 'entityLinks'));
        const locations = convertLocationBundlesToLocationViews(locationBundles);
        dispatch(
            augmentActionAndStoreRmsHydratedPersonProfiles(
                { type: 'STORE_HYDRATED_FIELD_NOTE' },
                result.persons
            )
        );
        dispatch(
            dependencies.nexus.withEntityItems(
                {
                    fieldNotes: [result.fieldNote],
                    attachments: result.attachments,
                    locationEntityLinks,
                    locationsTODO: locations,
                    fieldNoteEntityLinks: result.fieldNoteEntityLinks,
                },
                { type: 'STORE_HYDRATED_FIELD_NOTE' }
            )
        );
        return result;
    });
};

export const saveFieldNoteToReport = (report: Report, fieldNote: FieldNote): RmsAction<void> => (
    dispatch,
    getState
) => {
    const attachments = attachmentsByEntitySelector(getState())(
        EntityTypeEnum.FIELD_NOTE.name,
        fieldNote.id
    );
    const reportAttachments = map(attachments, (attachment) => {
        return {
            ...attachment,
            entityId: report.id,
            entityType: EntityTypeEnum.REPORT.name,
            linkType: LinkTypesEnum.REPORT_ATTACHMENT,
        };
    });
    dispatch(
        saveAttachmentsForEntities({
            entityType: EntityTypeEnum.REPORT.name,
            entityIds: [report.id],
            attachments: reportAttachments,
            removeOthers: false,
        })
    );
};

import { EntityTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { filter, map } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { taskViewsWhereSelector } from '~/client-common/core/domain/taskViews/state/data';
import { currentCaseIdSelector } from '../../../../cases/core/state/ui';
import { setCurrentCaseTasksSortOrder } from '../../../../cases/task-list/state/ui';
import {
    fetchAndStoreEntityTasks,
    inProgressTaskStatusAttributeIdSelector,
    pendingTaskStatusAttributeIdSelector,
} from '../../../core/data';
import { tasksDashboardSearch } from '../../../dashboard/state/ui';

export function retrieveCaseTasks(caseId) {
    return (dispatch, getState) => {
        const isTaskAndRequestTrackingEnabled = applicationSettingsSelector(getState())
            .RMS_TASK_AND_REQUEST_TRACKING_ENABLED;

        const isTaskEnhancementsEnabled = applicationSettingsSelector(getState())
            .RMS_TASK_ENHANCEMENTS_ENABLED;

        if (isTaskAndRequestTrackingEnabled && !isTaskEnhancementsEnabled) {
            dispatch(fetchAndStoreEntityTasks(caseId, EntityTypeEnum.CASE.name)).then(({ tasks }) =>
                dispatch(setCurrentCaseTasksSortOrder(map(tasks, 'id')))
            );
        } else if (isTaskAndRequestTrackingEnabled && isTaskEnhancementsEnabled) {
            dispatch(
                tasksDashboardSearch.actionCreators.search({
                    formData: {
                        owners: [{ ownerId: caseId, ownerType: EntityTypeEnum.CASE.name }],
                    },
                })
            );
        }
    };
}

const selectedCaseTasksSelector = createSelector(
    currentCaseIdSelector,
    taskViewsWhereSelector,
    (currentCaseId, taskViewsWhere) => {
        return taskViewsWhere({
            ownerId: currentCaseId,
            ownerType: EntityTypeEnum.CASE.name,
        });
    }
);

export const outstandingCaseTasksCountSelector = createSelector(
    selectedCaseTasksSelector,
    inProgressTaskStatusAttributeIdSelector,
    pendingTaskStatusAttributeIdSelector,
    (selectedCaseTasks, inProgressTaskStatusAttributeId, pendingTaskStatusAttributeId) => {
        return filter(selectedCaseTasks, (caseTask) => {
            const { statusAttrId } = caseTask;
            return (
                statusAttrId === inProgressTaskStatusAttributeId ||
                statusAttrId === pendingTaskStatusAttributeId
            );
        });
    }
);

export const UPLOAD_FILE_START = 'evidence-printing-templates/UPLOAD_FILE_START';
export const UPLOAD_FILE_SUCCESS = 'evidence-printing-templates/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'evidence-printing-templates/UPLOAD_FILE_FAILURE';
export const REMOVE_FILE = 'evidence-printing-templates/REMOVE_FILE';

export function uploadFileStart() {
    return { type: UPLOAD_FILE_START };
}
export function uploadFileSuccess(file) {
    return { type: UPLOAD_FILE_SUCCESS, payload: file };
}
export function uploadFileFailure(errorMessage) {
    return { type: UPLOAD_FILE_FAILURE, error: true, payload: errorMessage };
}

export function removeFile(fileId) {
    return { type: REMOVE_FILE, payload: fileId };
}

import { useSelector } from 'react-redux';
import { mugshotAttachmentsForPersonIdSelector } from '~/client-common/core/domain/attachments/state/ui';
import { personProfilesWhereSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { currentCaseLinkedProfileViewModelsSelector } from '../../linked-profiles/state/ui';
import {
    getSuspectsOnCaseLinkedOffenseReports,
    getOtherPersonsOnCaseLinkedReports,
} from '../state/data/createLineupModal';

export function usePhotoLineupPersonProfiles() {
    const personProfilesWhere = useSelector(personProfilesWhereSelector);
    const mugshotAttachmentsForPersonId = useSelector(mugshotAttachmentsForPersonIdSelector);
    const currentCaseLinkedProfileViewModels = useSelector(
        currentCaseLinkedProfileViewModelsSelector
    );
    const { relatedPersons, relatedReports } = currentCaseLinkedProfileViewModels;

    const offenseSuspects = getSuspectsOnCaseLinkedOffenseReports(
        // @ts-expect-error client-common to client RND-7529
        relatedPersons,
        relatedReports,
        personProfilesWhere,
        mugshotAttachmentsForPersonId
    );

    const otherProfiles = getOtherPersonsOnCaseLinkedReports(
        // @ts-expect-error client-common to client RND-7529
        relatedPersons,
        relatedReports,
        personProfilesWhere,
        mugshotAttachmentsForPersonId,
        offenseSuspects
    );

    return { relatedPersons, mugshotAttachmentsForPersonId, offenseSuspects, otherProfiles };
}

import actionTypes from '../actions/types/locationsActionTypes';

const initialDataState = {
    locations: {},
};

export function locationsDataReducer(state = initialDataState, action) {
    switch (action.type) {
        case actionTypes.SELECT_LOCATION_SUCCESS:
            const location = action.payload;

            return {
                ...state,
                locations: {
                    ...state.locations,
                    [location.id]: location,
                },
            };
        default:
            return state;
    }
}

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import Modal from '../../../core/overlays/components/Modal';
import { submitApplyCasePermissionsToAllReportsInCase } from '../state/data';

interface ApplyCasePermissionsToReportsModalProps {
    buttonElement: React.ReactNode;
    caseId: number;
    closeFocusRef: React.RefObject<HTMLElement>;
}

const strings = componentStrings.cases.core.ApplyCasePermissionsToReportsModal;

const ApplyCasePermissionsToReportsModal: React.FC<ApplyCasePermissionsToReportsModalProps> = ({
    buttonElement,
    caseId,
    closeFocusRef,
}) => {
    const dispatch = useDispatch();
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    const handleSave = useCallback(() => {
        dispatch(submitApplyCasePermissionsToAllReportsInCase({ caseId, caseDisplayName }));
    }, [caseDisplayName, caseId, dispatch]);

    return (
        <Modal
            autoClose={false}
            buttonElement={buttonElement}
            cancelFocusRef={closeFocusRef}
            id={overlayIdEnum.APPLY_CASE_PERMISSIONS_TO_REPORTS_OVERLAY}
            okText={strings.confirm}
            onSave={handleSave}
            saveFocusRef={closeFocusRef}
            title={strings.title(caseDisplayName)}
        >
            {strings.message(caseDisplayName)}
        </Modal>
    );
};

export default ApplyCasePermissionsToReportsModal;

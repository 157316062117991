import styled from 'styled-components';

const ComplianceSectionHeader = styled.h2`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.mediumGrey};
    font-size: var(--arc-fontSizes-lg);
    text-transform: uppercase;
    margin: 0 0 15px;
`;

export default ComplianceSectionHeader;

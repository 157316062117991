// helpers
import { Report, LinkTypesEnum } from '@mark43/rms-api';
import getReportResource from '../../resources/reportResource';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { NEXUS_STATE_PROP as NARRATIVE_AUTOSAVE_NEXUS_STATE_PROP } from '../../../narrative-autosave/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

/**
 * `LinkTypes` that are valid for the `LocationEntityLinks`s in an Event Info Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
export const EVENT_CARD_LOCATION_LINK_TYPES: number[] = [LinkTypesEnum.LOCATION_OF_EVENT];

export const NEXUS_STATE_PROP = 'reportsTODO';

const reportModule = createNormalizedModule<Report>({
    type: NEXUS_STATE_PROP,
});

const UPDATE_REPORT_AGENCY_START = 'reports/UPDATE_REPORT_AGENCY_START';
const UPDATE_REPORT_AGENCY_SUCCESS = 'reports/UPDATE_REPORT_AGENCY_SUCCESS';
const UPDATE_REPORT_AGENCY_FAILURE = 'reports/UPDATE_REPORT_AGENCY_FAILURE';
const UPDATE_REPORT_NARRATIVE_START = 'reports/UPDATE_REPORT_NARRATIVE_START';
const UPDATE_REPORT_NARRATIVE_SUCCESS = 'reports/UPDATE_REPORT_NARRATIVE_SUCCESS';
const UPDATE_REPORT_NARRATIVE_FAILURE = 'reports/UPDATE_REPORT_NARRATIVE_FAILURE';

// SELECTORS
export const reportsSelector = reportModule.selectors.entitiesSelector;
export const reportByIdSelector = reportModule.selectors.entityByIdSelector;
export const reportsWhereSelector = reportModule.selectors.entitiesWhereSelector;

// ACTIONS
const storeReports = reportModule.actionCreators.storeEntities;

function updateReportAgencyStart(reportId: number, agencyId: number) {
    return {
        type: UPDATE_REPORT_AGENCY_START,
        payload: { reportId, agencyId },
    };
}

function updateReportAgencySuccess(report: Report) {
    return {
        type: UPDATE_REPORT_AGENCY_SUCCESS,
        payload: { report },
    };
}

function updateReportAgencyFailure(errorMessage: string) {
    return {
        type: UPDATE_REPORT_AGENCY_FAILURE,
        payload: errorMessage,
    };
}

function updateReportNarrativeStart(reportId: number, narrative: string) {
    return {
        type: UPDATE_REPORT_NARRATIVE_START,
        payload: { reportId, narrative },
    };
}

function updateReportNarrativeSuccess(report: Report) {
    return {
        type: UPDATE_REPORT_NARRATIVE_SUCCESS,
        payload: { report },
    };
}

function updateReportNarrativeFailure(errorMessage: string) {
    return {
        type: UPDATE_REPORT_NARRATIVE_FAILURE,
        payload: errorMessage,
    };
}

export function updateReportAgency(
    reportId: number,
    agencyId: number
): ClientCommonAction<Promise<Report>> {
    const resource = getReportResource();
    return (dispatch, getState) => {
        dispatch(updateReportAgencyStart(reportId, agencyId));
        return resource
            .updateReportAgency(reportId, agencyId)
            .then((report: Report) => {
                const state = getState();
                const existingReport = reportByIdSelector(state)(report.id) as Report;
                const updatedReport = {
                    ...existingReport,
                    agencyId: report.agencyId,
                };

                dispatch(storeReports(updatedReport));
                dispatch(updateReportAgencySuccess(updatedReport));

                return updatedReport;
            })
            .catch((err: Error) => {
                dispatch(updateReportAgencyFailure(err.message));
                throw err;
            });
    };
}

export function storeReportNarrative(
    reportId: number,
    narrative: string
): ClientCommonAction<Report> {
    return (dispatch, getState, { nexus }) => {
        const currentReport = reportByIdSelector(getState())(reportId) as Report;
        const updatedReport = {
            ...currentReport,
            narrative,
        };
        // Remove local autosave
        dispatch(
            nexus.withRemove(
                NARRATIVE_AUTOSAVE_NEXUS_STATE_PROP,
                { reportId },
                nexus.withEntityItems(
                    { [NEXUS_STATE_PROP]: [updatedReport] },
                    { type: 'STORE_REPORTS' }
                )
            )
        );
        return updatedReport;
    };
}

export function updateReportNarrative(
    reportId: number,
    narrative: string
): ClientCommonAction<Promise<Report>> {
    const resource = getReportResource();
    return (dispatch, getState, { nexus }) => {
        const currentReport = reportByIdSelector(getState())(reportId) as Report;
        const updatedReport = {
            ...currentReport,
            narrative,
        };
        // @ts-expect-error swagger issues with narrative being HtmlText vs. string
        dispatch(updateReportNarrativeStart(reportId, updatedReport));
        return resource
            .updateReportNarrative(updatedReport)
            .then((report: Report) => {
                // On a successful save, we should remove
                // traces of any local autosave
                dispatch(
                    nexus.withRemove(
                        NARRATIVE_AUTOSAVE_NEXUS_STATE_PROP,
                        { reportId },
                        nexus.withEntityItems(
                            { [NEXUS_STATE_PROP]: [report] },
                            updateReportNarrativeSuccess(report)
                        )
                    )
                );
                return report;
            })
            .catch((err: Error) => {
                dispatch(updateReportNarrativeFailure(err.message));
                throw err;
            });
    };
}

// REDUCER
export default reportModule.reducerConfig;

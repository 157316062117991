import { EntityTypeEnum } from '@mark43/rms-api';
import Promise from 'bluebird';

import { includes } from 'lodash';
import { storeFutureEntityPermissions } from '~/client-common/core/domain/future-entity-permissions/state/data';

import entityPermissionsResource from '../../../../../legacy-redux/resources/entityPermissionsResource';

export const LOAD_MASTER_ENTITY_PERMISSIONS_START =
    'master-entities/LOAD_MASTER_ENTITY_PERMISSIONS_START';
export const LOAD_MASTER_ENTITY_PERMISSIONS_SUCCESS =
    'master-entities/LOAD_MASTER_ENTITY_PERMISSIONS_SUCCESS';
export const LOAD_MASTER_ENTITY_PERMISSIONS_FAILURE =
    'master-entities/LOAD_MASTER_ENTITY_PERMISSIONS_FAILURE';
export const UPDATE_MASTER_ENTITY_PERMISSIONS_START =
    'master-entities/UPDATE_MASTER_ENTITY_PERMISSIONS_START';
export const UPDATE_MASTER_ENTITY_PERMISSIONS_SUCCESS =
    'master-entities/UPDATE_MASTER_ENTITY_PERMISSIONS_SUCCESS';
export const UPDATE_MASTER_ENTITY_PERMISSIONS_FAILURE =
    'master-entities/UPDATE_MASTER_ENTITY_PERMISSIONS_FAILURE';

function loadMasterEntityPermissionsStart(entityType) {
    return {
        type: LOAD_MASTER_ENTITY_PERMISSIONS_START,
        payload: { entityType },
    };
}

function loadMasterEntityPermissionsSuccess(entityType, permissions) {
    return {
        type: LOAD_MASTER_ENTITY_PERMISSIONS_SUCCESS,
        payload: { entityType, permissions },
    };
}

function loadMasterEntityPermissionsFailure(entityType, errMsg) {
    return {
        type: LOAD_MASTER_ENTITY_PERMISSIONS_FAILURE,
        error: true,
        payload: { entityType, error: errMsg },
    };
}

export const allowedEntities = [
    EntityTypeEnum.BRIEFING.name,
    EntityTypeEnum.ITEM_PROFILE.name,
    EntityTypeEnum.ORGANIZATION_PROFILE.name,
    EntityTypeEnum.PERSON_PROFILE.name,
];

export function loadMasterEntityPermissions(entityType) {
    return function (dispatch) {
        dispatch(loadMasterEntityPermissionsStart(entityType));
        if (!includes(allowedEntities, entityType)) {
            dispatch(loadMasterEntityPermissionsFailure(entityType, 'Invalid entity type'));
            // return a promise to not break chaining
            return Promise.resolve();
        }
        return entityPermissionsResource
            .getMasterEntityDefaultPermissions(entityType)
            .then((permissions) => {
                dispatch(storeFutureEntityPermissions(permissions));
                dispatch(loadMasterEntityPermissionsSuccess(entityType, permissions));
                return permissions;
            })
            .catch((err) => dispatch(loadMasterEntityPermissionsFailure(entityType, err.message)));
    };
}

function updateMasterEntityPermissionsStart(entityType) {
    return {
        type: UPDATE_MASTER_ENTITY_PERMISSIONS_START,
        payload: { entityType },
    };
}

function updateMasterEntityPermissionsSuccess(entityType, permissions) {
    return {
        type: UPDATE_MASTER_ENTITY_PERMISSIONS_SUCCESS,
        payload: { entityType, permissions },
    };
}

function updateMasterEntityPermissionsFailure(entityType, errMsg) {
    return {
        type: UPDATE_MASTER_ENTITY_PERMISSIONS_FAILURE,
        error: true,
        payload: { entityType, error: errMsg },
    };
}

export function updateMasterEntityPermissions(entityType, formData) {
    return function (dispatch) {
        const { permissions } = formData;
        dispatch(updateMasterEntityPermissionsStart(entityType));

        return entityPermissionsResource
            .updateMasterEntityDefaultPermissions(entityType, permissions)
            .then((permissions) => {
                dispatch(storeFutureEntityPermissions(permissions));
                dispatch(updateMasterEntityPermissionsSuccess(entityType, permissions));
                return permissions;
            })
            .catch((err) =>
                dispatch(updateMasterEntityPermissionsFailure(entityType, err.message))
            );
    };
}

import React, { useState } from 'react';
import compact from 'lodash/compact';
import noop from 'lodash/noop';
import styled from 'styled-components';
import { mediaQueries, cssVar } from 'arc';
import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { Tab, Tabs, TabList, TabPanel } from '../../core/components/Tabs';
import testIds from '../../../core/testIds';
import LinkedTasksSection from '../../records/core/components/sidebar/LinkedTasksSection';
import LinkedAttachmentsSection from '../../records/core/components/sidebar/LinkedAttachmentsSection';

const strings = componentStrings.briefings.core;

interface BriefingSidebarProps {
    briefingId: number;
    briefingTitle: string;
    showTasks?: boolean;
    showAttachments?: boolean;
}

const SidebarWrapper = styled.div`
    flex: 0 0 310px;
    padding: ${cssVar('arc.space.1')} ${cssVar('arc.space.4')};
    border-left: 1px solid ${cssVar('arc.colors.border.default')};
    margin-left: ${cssVar('arc.space.2')};
    overflow-y: auto;

    @media (max-width: ${mediaQueries.md}) {
        flex-basis: 33%;
    }
`;

const BriefingSidebar: React.FC<BriefingSidebarProps> = ({
    briefingId,
    briefingTitle,
    showTasks = true,
    showAttachments = true,
}) => {
    const [activeTab, setActiveTab] = useState(0);

    const tabConfig = compact([
        showTasks && {
            title: strings.tasksTabTitle,
            testId: testIds.BRIEFING_SIDEBAR_TASKS_TAB,
            content: (
                <LinkedTasksSection
                    entityType={EntityTypeEnum.BRIEFING.name}
                    entityId={briefingId}
                />
            ),
        },
        showAttachments && {
            title: strings.attachmentsTabTitle,
            testId: testIds.BRIEFING_SIDEBAR_ATTACHMENTS_TAB,
            content: (
                <LinkedAttachmentsSection
                    entityType={EntityTypeEnum.BRIEFING.name}
                    entityId={briefingId}
                    entityTitle={briefingTitle}
                    linkType={LinkTypesEnum.ATTACHMENT_IN_BRIEFING}
                />
            ),
        },
    ]);

    // to handle the case when selected tab is no longer rendered
    const activeIndex = activeTab < tabConfig.length ? activeTab : 0;

    const tabs = tabConfig.map(({ title, testId }, index) => (
        <Tab key={title} active={index === activeIndex} data-test-id={testId} onClick={noop}>
            {title}
        </Tab>
    ));

    const panels = tabConfig.map(({ title, content }) => (
        <TabPanel key={title}>{content}</TabPanel>
    ));

    return (
        <SidebarWrapper>
            <Tabs
                testId={testIds.BRIEFING_SIDEBAR_TABS}
                selectedIndex={activeIndex}
                onSelect={(index: number) => setActiveTab(index)}
            >
                <TabList>{tabs}</TabList>
                {panels}
            </Tabs>
        </SidebarWrapper>
    );
};

export default BriefingSidebar;

import { MentionCategoryEnum, ProductModuleEnum } from '@mark43/rms-api';
import { Store } from 'redux';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import {
    DISPLAY_ONLY_CASE,
    DISPLAY_ONLY_ORGANIZATION_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { Field, formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { prettify } from '~/client-common/helpers/stringHelpers';
import elasticSearchResource from '../../../../../../legacy-redux/resources/elasticSearchResource';
import { RootState } from '../../../../../../legacy-redux/reducers/rootReducer';
import { CautionComputedData } from '../../../../cautions/configuration';
import { PrioritizedLabelAttribute } from '../../../../components/tags/helpers';
import { LIST_ITEMS_LIMIT, MentionCategories } from './constants';
import {
    FormatMentionItemMapper,
    Mention,
    MentionCategory,
    MentionCategoryType,
    MentionCautions,
    MentionQuery,
    SearchResultType,
} from './types';

const strings = componentStrings.core.Editor.plugins.mentionsForBriefing;

const getFieldSingularName = (field: Field, state: RootState) => {
    const formatter = formatFieldByNameSelector(state);
    return prettify(formatter(field));
};

const isPrioritizedLabelAttribute = (
    item: PrioritizedLabelAttribute | CautionComputedData
): item is PrioritizedLabelAttribute => {
    return 'formattedCategoryAttribute' in item;
};

const formatToEnhancedCaution = (
    item: PrioritizedLabelAttribute | CautionComputedData
): CautionComputedData => ({
    label: isPrioritizedLabelAttribute(item) ? item.formattedCategoryAttribute : item.label,
    priority: item.priority,
});

export const isCorrectSelectedCategory = (category: string): category is MentionCategoryType => {
    return MentionCategories.includes(category as MentionCategoryType);
};

const getActiveCategory = (
    category: MentionCategoryType,
    active: boolean,
    content: string
): MentionCategory => ({
    category,
    content,
    active,
});

export const getActiveCategoryList = (store: Store<RootState>) => {
    const state = store.getState();
    const isProductModuleActive = isProductModuleActiveSelector(state);
    const isReportsActive = isProductModuleActive(ProductModuleEnum.REPORTS.name);
    const isCasesActive = isProductModuleActive(ProductModuleEnum.CASES.name);
    const isWarrantsActive = isProductModuleActive(ProductModuleEnum.WARRANTS.name);

    const organizationDisplayName = getFieldSingularName(DISPLAY_ONLY_ORGANIZATION_LABEL, state);
    const caseDisplayName = getFieldSingularName(DISPLAY_ONLY_CASE, state);

    const categories: MentionCategory[] = [
        getActiveCategory('PERSON', isReportsActive, strings.categories.person),
        getActiveCategory('VEHICLE', isReportsActive, strings.categories.vehicle),
        getActiveCategory('ORGANIZATION', isReportsActive, organizationDisplayName),
        getActiveCategory('PROPERTY', isReportsActive, strings.categories.property),
        getActiveCategory('REPORTS', isReportsActive, strings.categories.report),
        getActiveCategory('CASES', isCasesActive, caseDisplayName),
        getActiveCategory('WARRANTS', isWarrantsActive, strings.categories.warrant),
    ];

    return categories.filter(({ active }) => active);
};

export const getMentionCautions = (
    sortedCautions: (PrioritizedLabelAttribute | CautionComputedData)[]
): MentionCautions => {
    let cautionsToShow: typeof sortedCautions = [];

    if (sortedCautions.length >= 1) {
        const { priority: highestPriority } = sortedCautions[0];
        cautionsToShow = sortedCautions.filter((item) => item.priority === highestPriority);
    }

    return {
        cautions: cautionsToShow.map(formatToEnhancedCaution),
        hiddenCautionsCounter: sortedCautions.length - cautionsToShow.length,
    };
};

export const getMentionEntityLinkUrl = ({
    itemType,
    itemId,
}: {
    itemType: MentionCategoryType;
    itemId: number;
}) => {
    switch (itemType) {
        case MentionCategoryEnum.PERSON.name:
            return `/profiles/persons/${itemId}`;
        case MentionCategoryEnum.VEHICLE.name:
            return `/profiles/vehicles/${itemId}/details`;
        case MentionCategoryEnum.ORGANIZATION.name:
            return `/profiles/organizations/${itemId}/details`;
        case MentionCategoryEnum.PROPERTY.name:
            return `/profiles/property/${itemId}/details`;
        case ProductModuleEnum.REPORTS.name:
            return `/reports/${itemId}`;
        case ProductModuleEnum.CASES.name:
            return `/cases/${itemId}/summary`;
        case ProductModuleEnum.WARRANTS.name:
            return `/warrants/${itemId}`;
        default:
            return '';
    }
};

export function getTruthyValues(items: (string | undefined)[]): string[];
export function getTruthyValues(items: (string | undefined)[], joinWith: string): string;
export function getTruthyValues(
    items: (string | undefined)[],
    joinWith?: string
): string[] | string {
    const strings = items.filter((item): item is string => Boolean(item));
    return joinWith ? strings.join(joinWith) : strings;
}

export const getDefaultAvatarIcon = (iconPath: string) => {
    return `<div class="mention-default-avatar">
        <svg viewBox="0 0 24 24" focusable="false" class="chakra-icon arc-n0nm6k">
            <path fill="currentColor" d="${iconPath}"/>
        </svg>
    </div>`;
};
export const getDefaultIcon = (iconPath: string, color: string) => {
    return `<div class="mention-default-icon">
        <svg viewBox="0 0 24 24" focusable="false" class="chakra-icon arc-n0nm6k" style="color: ${color}">
            <path fill="currentColor" d="${iconPath}"/>
        </svg>
    </div>`;
};

const fetchEntityList = async <TItem>(
    quickSearchQuery: string,
    resourceMethod: keyof typeof elasticSearchResource
): Promise<TItem[]> => {
    const { items } = (await elasticSearchResource[resourceMethod](
        { quickSearchQuery },
        0,
        LIST_ITEMS_LIMIT
    )) as SearchResultType<TItem>;
    return items;
};

const fetchEntityItem = async <TItem>(
    id: number,
    resourceMethod: keyof typeof elasticSearchResource
): Promise<TItem | undefined> => {
    const { items } = (await elasticSearchResource[resourceMethod](
        { ids: [id] },
        0,
        1
    )) as SearchResultType<TItem>;

    return items.length ? items[0] : undefined;
};

export const searchEntityList = async <TItem>(
    term: MentionQuery['term'],
    store: Store<RootState>,
    mapper: FormatMentionItemMapper<TItem>,
    limit?: number
): Promise<Mention[]> => {
    if (limit && term.length < limit) {
        return [];
    }

    const items = await fetchEntityList<TItem>(term, mapper.resourceMethod);

    return items.map((item) => mapper.prepareMentionedItem(item, store));
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const cache: Record<string, Record<number, any | undefined>> = {};
export const searchEntityItem = (category: MentionCategoryType) => {
    return async <TItem>(
        itemId: number,
        store: Store<RootState>,
        mapper: FormatMentionItemMapper<TItem>
    ): Promise<Mention | undefined> => {
        if (!cache[category] || !cache[category][itemId]) {
            if (!cache[category]) {
                cache[category] = {};
            }
            cache[category][itemId] = await fetchEntityItem<TItem>(itemId, mapper.resourceMethod);
        }
        const item = cache[category][itemId];
        if (!item) {
            return;
        }

        return mapper.prepareMentionedItem(item, store);
    };
};

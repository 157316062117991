import { useContext } from 'react';
import { SubmissionMessagesContext } from '../state/context';
import { SubmissionMessagesContextT } from '../types';

export function useSubmissionMessages(): SubmissionMessagesContextT {
    const context = useContext(SubmissionMessagesContext);

    if (context === undefined) {
        throw new Error(
            'Submission Messages Context must be inside a SubmissionMessagesProvider with a value'
        );
    }
    return context;
}

import React from 'react';
import { Observer, Fields } from 'markformythree';
import {
    reduce,
    values,
    some,
    every,
    isNil,
    map,
    compact,
    flatMap,
    size,
    isArray,
    isObject,
    pickBy,
} from 'lodash';

const hasValue = (val) => {
    if (isArray(val)) {
        return !!size(val);
    } else if (isObject(val)) {
        return !!size(pickBy(val, (val) => !isNil(val)));
    } else {
        return !isNil(val);
    }
};

const someFormPathValuesAreDefined = (formPathValues) =>
    some(map(values(formPathValues), hasValue));

const someFormPathValuesAreTruthy = (formPathValues) => some(values(formPathValues));
const allFormPathValuesAreTruthy = (formPathValues) => every(values(formPathValues));
const someFormPathErrorsAreTruthy = (formPathValues) =>
    some(compact(flatMap(values(formPathValues), values)));

const ObserverWrapper = ({ formPathsToObserve, children, fields, formPathValuesProcessor }) => (
    <Observer
        subscriptions={reduce(
            formPathsToObserve,
            (acc, formPath) => {
                acc[formPath] = fields ? [formPath, fields] : formPath;
                return acc;
            },
            {}
        )}
        render={(formPathValues) =>
            children(
                formPathValuesProcessor ? formPathValuesProcessor(formPathValues) : formPathValues
            )
        }
    />
);

export const SomeFieldsHaveValue = ({ formPathsToObserve, children }) => (
    <ObserverWrapper
        formPathValuesProcessor={someFormPathValuesAreDefined}
        formPathsToObserve={formPathsToObserve}
        children={children}
    />
);

export const SomeFieldsHaveTruthyValue = ({ formPathsToObserve, children }) => (
    <ObserverWrapper
        formPathValuesProcessor={someFormPathValuesAreTruthy}
        formPathsToObserve={formPathsToObserve}
        children={children}
    />
);

export const AllFieldsAreHidden = ({ formPathsToObserve, children }) => (
    <ObserverWrapper
        formPathValuesProcessor={allFormPathValuesAreTruthy}
        formPathsToObserve={formPathsToObserve}
        fields={Fields.HIDDEN}
        children={children}
    />
);

export const SomeFieldsHaveError = ({ formPathsToObserve, children }) => (
    <ObserverWrapper
        formPathValuesProcessor={someFormPathErrorsAreTruthy}
        formPathsToObserve={formPathsToObserve}
        fields={Fields.ERRORS}
        children={children}
    />
);

import { useCallback } from 'react';
import { Mark43File, FileUploadResponse } from '@mark43/rms-api';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { imageResource } from '../../images/imageResource';

type SaveMark43FileArgs = {
    onSave: (file: Mark43File) => void;
    onUploadStart: () => void;
    onUploadComplete: () => void;
};

export const useFileUploader = ({
    onSave,
    onUploadStart,
    onUploadComplete,
}: SaveMark43FileArgs) => {
    const onUploadFileResource: (formData: FormData) => Promise<FileUploadResponse[]> = useCallback(
        (formData: FormData) => {
            onUploadStart();
            return imageResource.uploadImage(formData);
        },
        [onUploadStart]
    );

    const onUploadFileSuccess = useCallback(
        (data: FileUploadResponse[]) => {
            const [file] = data;
            if (file) {
                onSave(file.file);
            }

            onUploadComplete();
        },
        [onUploadComplete, onSave]
    );

    const onError = useCallback((error) => {
        throw error;
    }, []);

    return useResourceDeferred(onUploadFileResource, onUploadFileSuccess, onError);
};

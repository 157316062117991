import { isEmpty } from 'lodash';
import keyMirror from 'keymirror';

import { filterFormData } from '~/client-common/helpers/formHelpers';
import { NEXUS_STATE_PROP as ELASTIC_WARRANTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-warrants/state/data';
import elasticSearchResource from '../../../../../../legacy-redux/resources/elasticSearchResource';

const ELASTIC_SEARCH_WARRANTS_FROM = 0;
const ELASTIC_SEARCH_WARRANTS_SIZE = 10;

export const FIND_WARRANT_SEARCH_TYPES = keyMirror({
    WARRANT_NUMBER: null,
    DATE_RANGE_WARRANT_TYPE_OBTAINING_OFFICER: null,
});

export const searchElasticWarrants = ({ elasticQuery, from, size, actionType }) => (
    dispatch,
    getState,
    { nexus: { withEntityItems } }
) => {
    return elasticSearchResource
        .searchWarrants(elasticQuery, from, size)
        .then((elasticWarrantsSearchResult) => {
            const { items, query } = elasticWarrantsSearchResult;
            // no lodash `size` here because of naming collision
            if (!isEmpty(items)) {
                dispatch(
                    withEntityItems({ [ELASTIC_WARRANTS_NEXUS_STATE_PROP]: items }, actionType)
                );
            }
            return {
                elasticWarrantsSearchResult,
                from: query.from,
                size: query.size,
            };
        });
};

export const performFindWarrantSearch = ({ searchType, findWarrantSearchFormModel }) => (
    dispatch
) => {
    const { WARRANT_NUMBER } = FIND_WARRANT_SEARCH_TYPES;
    const {
        warrantNumber,
        warrantTypeAttrIds,
        obtainingOfficerUserIds,
        startDateUtc,
        endDateUtc,
    } = findWarrantSearchFormModel;
    const elasticQuery =
        searchType === WARRANT_NUMBER
            ? { quickSearchQuery: warrantNumber }
            : {
                  warrantTypeAttrIds,
                  obtainingOfficerIds: obtainingOfficerUserIds,
                  startDateUtc,
                  endDateUtc,
              };
    const filteredElasticQuery = filterFormData(elasticQuery);

    return dispatch(
        searchElasticWarrants({
            elasticQuery: filteredElasticQuery,
            from: ELASTIC_SEARCH_WARRANTS_FROM,
            size: ELASTIC_SEARCH_WARRANTS_SIZE,
            actionType: { type: 'FIND_WARRANTS_SEARCH_ELASTIC_WARRANTS' },
        })
    );
};

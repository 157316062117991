import { ReportStatusHistory, ReportStatusView, ResubmissionStatusEnum } from '@mark43/rms-api';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { get, noop, partialRight } from 'lodash';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { FormattedDate } from '~/client-common/core/dates/components';
import reportApprovalLevelClientEnum from '~/client-common/core/enums/client/reportApprovalLevelClientEnum';
import withFields from '~/client-common/core/fields/components/withFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import { DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED } from '~/client-common/core/enums/universal/fields';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { Field } from '~/client-common/core/fields/state/config';
import { Button as ArcButton, ButtonGroup } from '../../../../core/components/Button';
import {
    latestReportStatusHistoryForCurrentReport,
    secondaryApprovedReportAdditionalArgumentsSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import Row from '../../../../core/components/Row';
import testIds from '../../../../../core/testIds';
import {
    currentUserHasAbilitySelector,
    currentUserIdSelector,
} from '../../../../core/current-user/state/ui';
import { abilitiesEnum } from '../../../../core/abilities';
import {
    complianceResubmissionsByReportIdSelector,
    loadComplianceResubmissions,
} from '../../state/data/complianceResubmission';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';
import CardWithApprovalStatusIcon from './CardWithApprovalStatusIcon';
import EditRecordLabels from './EditRecordLabels';
import RescindStaffReviewModal from './RescindStaffReviewModal';
import SubmissionErrorModal from './SubmissionErrorModal';
import ComplianceResubmissionModal, {
    useOpenComplianceResubmissionModal,
} from './ComplianceResubmissionModal';

const strings = componentStrings.reports.core.ReportStatusCommentsCard;
const ICON_TYPE_ADD = 'Add';
const ICON_TYPE_REMOVE = 'Close';

function getLatestCompletedHistoryTextByApprovalLevel(
    approvalLevel?: keyof typeof reportApprovalLevelClientEnum
) {
    switch (approvalLevel) {
        case reportApprovalLevelClientEnum.NONE:
            return strings.completed.completedUponSubmission;
        case reportApprovalLevelClientEnum.ONE:
            return strings.completed.completedBySupervisor;
        case reportApprovalLevelClientEnum.TWO:
            return strings.completed.completedBySecondary;
        default:
            return undefined;
    }
}

const AddResubmissionButton = ({
    onResubmissionClick,
    isAddButton,
}: {
    onResubmissionClick: ReturnType<typeof useOpenComplianceResubmissionModal>;
    isAddButton: boolean;
}) => (
    <ArcButton
        isTextTransformNone
        variant="outline"
        leftIcon={isAddButton ? ICON_TYPE_ADD : ICON_TYPE_REMOVE}
        onClick={onResubmissionClick}
        style={{ marginTop: '10px' }}
    >
        {isAddButton
            ? strings.resubmissions.addResubmission
            : strings.resubmissions.removeResubmission}
    </ArcButton>
);

interface CompletedCardOuterPropsT {
    reportStatusView: ReportStatusView;
    className?: string;
    disabled?: boolean;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
    reportApprovalLevel: keyof typeof reportApprovalLevelClientEnum | undefined;
    rejectReport: (callback: () => void) => void;
    ucrRejectReport: () => void;
    reportLastExportedDate?: string;
}
interface CompletedCardInnerPropsT extends CompletedCardOuterPropsT {
    latestHistory?: ReportStatusHistory;
    secondaryApprovedReportAdditionalArguments: {
        supervisorUserId?: number;
        date?: string;
    };
    formatMiniUserById: ReturnType<typeof formatMiniUserByIdSelector>;
    currentUserId: number;
    complianceResubmissionById: ReturnType<typeof complianceResubmissionsByReportIdSelector>;
    fieldDisplayNames: Record<Field, string>;
}

const CompletedCard = ({
    reportStatusView,
    className,
    disabled,
    reportSealingInfo,
    fieldDisplayNames,
    reportApprovalLevel,
    secondaryApprovedReportAdditionalArguments,
    currentUserId,
    formatMiniUserById,
    ucrRejectReport,
    rejectReport,
    reportLastExportedDate,
    complianceResubmissionById,
}: CompletedCardInnerPropsT) => {
    const isSecondaryComplete = reportApprovalLevel === reportApprovalLevelClientEnum.TWO;
    const latestHistoryText:
        | ((user: string, date: string) => string)
        | ((
              secondaryUser: string,
              secondaryApprovalDate: string,
              supervisorUser: string,
              supervisorApprovalDate: string
          ) => string)
        | undefined = getLatestCompletedHistoryTextByApprovalLevel(reportApprovalLevel);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const currentReportId = reportStatusView.report?.id;

    const dispatch = useDispatch();

    useEffect(() => {
        const hasNibrsWorkspaceAbility = currentUserHasAbility(
            abilitiesEnum.REPORTING.NIBRS_WORKSPACE
        );

        if (currentReportId && hasNibrsWorkspaceAbility) {
            dispatch(loadComplianceResubmissions(currentReportId));
        }
    }, [dispatch, currentReportId, currentUserHasAbility]);

    // The secondary user is already derived from CardWithApprovalStatusIcon
    const supervisorName =
        isSecondaryComplete && secondaryApprovedReportAdditionalArguments?.supervisorUserId
            ? secondaryApprovedReportAdditionalArguments.supervisorUserId === currentUserId
                ? strings.core.you
                : formatMiniUserById(secondaryApprovedReportAdditionalArguments.supervisorUserId)
            : undefined;

    const canSecondaryActions =
        reportStatusView.canStaffReview || reportStatusView.canSecondaryReject;

    const onRejectReport = reportStatusView.canSecondaryReject
        ? ucrRejectReport
        : reportStatusView.canReject
        ? rejectReport
        : noop;

    const currentResubmission = complianceResubmissionById(currentReportId);

    const showResubmissionButton = !!reportLastExportedDate;

    const resubmissionId =
        currentResubmission &&
        currentResubmission.resubmissionStatus === ResubmissionStatusEnum.QUEUED.name
            ? currentResubmission.id
            : undefined;
    const isAddButton = !resubmissionId;
    const openResubmissionModal = useOpenComplianceResubmissionModal({
        overlayId: overlayIdEnum.COMPLIANCE_RESUBMISSION_MODAL,
        reportId: currentReportId,
        resubmissionId,
    });

    return (
        <FormattedDate
            date={
                isSecondaryComplete
                    ? get(secondaryApprovedReportAdditionalArguments, 'date')
                    : undefined
            }
            format={FormattedDate.FORMATS.DATE_TIME_IN_SENTENCE}
        >
            {(formattedDate) => {
                // narrowing out the 4 argument function
                // the case where it is a 4 arg function is when isSecondaryComplete is true
                // this will convert that 4 arg function to a 2 arg, or use existing 2 arg
                const alteredLatestHistoryText =
                    isSecondaryComplete && !!latestHistoryText
                        ? partialRight(latestHistoryText, supervisorName, formattedDate)
                        : latestHistoryText;

                return (
                    <CardWithApprovalStatusIcon
                        approvalStatus={approvalStatusClientEnum.COMPLETED}
                        reportSealingInfo={reportSealingInfo}
                        title={fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED}
                        className={className}
                        testId={testIds.REPORT_STATUS_COMMENTS_COMPLETED_CARD}
                        latestHistoryText={alteredLatestHistoryText}
                        customSummaryModeColor={'lightGreen'}
                        customSummaryModeBorderColor={'darkGreen'}
                    >
                        <FeatureFlagged flag="RMS_COMPLIANCE_RESUBMISSION_WORKFLOW_ENABLED">
                            {reportLastExportedDate && (
                                <FormattedDate
                                    date={reportLastExportedDate}
                                    format={FormattedDate.FORMATS.DATE_TIME_IN_SENTENCE}
                                >
                                    {(formattedDate) => (
                                        <p>{`${strings.core.lastExportedDateLabel} ${formattedDate}`}</p>
                                    )}
                                </FormattedDate>
                            )}
                        </FeatureFlagged>
                        <Row>
                            {!disabled && (
                                <EditRecordLabels reportId={reportStatusView.report.id} />
                            )}
                        </Row>
                        {canSecondaryActions && !disabled && (
                            <Row>
                                <ArcButton isTextTransformNone isDisabled={true} variant="solid">
                                    {strings.core.validate}
                                </ArcButton>
                                <SubmissionErrorModal />
                            </Row>
                        )}
                        {(reportStatusView.canReject || canSecondaryActions) && !disabled && (
                            <ButtonGroup style={{ marginTop: 'var(--arc-space-4)' }}>
                                <ArcButton
                                    isTextTransformNone
                                    leadingVisual="Check"
                                    isDisabled={true}
                                    variant="solid"
                                >
                                    {strings.core.approve}
                                </ArcButton>
                                <FeatureFlagged flag="RMS_DISPLAY_REJECT_BUTTON_ENABLED">
                                    <ArcButton
                                        isTextTransformNone
                                        leadingVisual="CloseX"
                                        isDisabled={
                                            !(
                                                reportStatusView.canReject ||
                                                reportStatusView.canSecondaryReject
                                            )
                                        }
                                        onClick={onRejectReport}
                                        variant="outline"
                                    >
                                        {strings.core.reject}
                                    </ArcButton>
                                </FeatureFlagged>
                            </ButtonGroup>
                        )}
                        <FeatureFlagged flag="RMS_COMPLIANCE_RESUBMISSION_WORKFLOW_ENABLED">
                            {showResubmissionButton && (
                                <>
                                    <AddResubmissionButton
                                        onResubmissionClick={openResubmissionModal}
                                        isAddButton={isAddButton}
                                    />
                                    <ComplianceResubmissionModal
                                        overlayId={overlayIdEnum.COMPLIANCE_RESUBMISSION_MODAL}
                                        resubmissionId={resubmissionId}
                                    />
                                </>
                            )}
                        </FeatureFlagged>
                        <RescindStaffReviewModal />
                    </CardWithApprovalStatusIcon>
                );
            }}
        </FormattedDate>
    );
};

const mapStateToProps = createStructuredSelector({
    latestHistory: latestReportStatusHistoryForCurrentReport,
    secondaryApprovedReportAdditionalArguments: secondaryApprovedReportAdditionalArgumentsSelector,
    formatMiniUserById: formatMiniUserByIdSelector,
    currentUserId: currentUserIdSelector,
    complianceResubmissionById: complianceResubmissionsByReportIdSelector,
});

export default compose<CompletedCardInnerPropsT, CompletedCardOuterPropsT>(
    withFields([DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED]),
    connect(mapStateToProps)
)(CompletedCard);

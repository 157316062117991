import { filter, noop } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers, withPropsOnChange } from 'recompose';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { retentionPoliciesSelector } from '~/client-common/core/domain/retention-policies/state/data';
import {
    OFFENSE_TYPE_OFFENSE_CODE_FLAGS,
    INCIDENT_TYPE_OFFENSE_CODE_FLAGS,
} from '~/client-common/core/domain/offense-codes/constants';

import routesConfig from '../../../../routing/routesConfig';

// components
import { InlineBanner } from '../../../core/components/InlineBanner';
import AdminHeader from '../../core/components/AdminHeader';
import AdminLastModifiedFooter from '../../core/components/AdminLastModifiedFooter';
import AdminWorkArea from '../../core/components/AdminWorkArea';
import AdminWorkAreaWrapper from '../../core/components/AdminWorkAreaWrapper';
import Row from '../../../core/components/Row';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import { RRFRadio } from '../../../core/forms/components/Radio';
import { RRFDispositionApprovalLevelSelect } from '../../../core/forms/components/selects/DispositionApprovalLevelSelect';
import { RRFOffenseCodeSelect } from '../../../core/forms/components/selects/OffenseCodeSelect';

// Redux
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import retentionPolicyAdminForm from '../state/forms/retentionPolicyAdminForm';
import {
    submitRetentionPolicyAdminForm,
    selectedRetentionPolicyIdSelector,
    savingRetentionPolicySelector,
    retentionPolicyAdminFormErrorMessageSelector,
} from '../state/ui';

// config
import {
    fullFormWidth,
    halfFormWidth,
    smallFormWidth,
} from '../../../../legacy-redux/configs/adminConfig';

const { withRRFActions, WithFieldState } = reactReduxFormHelpers;

const PeriodWrapper = styled.div`
    margin: 0 20px 13px;
    display: flex;
    align-items: flex-start;
`;

const RetentionPeriodLabel = styled.p`
    font-size: var(--arc-fontSizes-md);
    font-weight: 500;
`;

const offenseCodeFlags = [...OFFENSE_TYPE_OFFENSE_CODE_FLAGS, ...INCIDENT_TYPE_OFFENSE_CODE_FLAGS];
const strings = componentStrings.admin.evidenceRetentionPolicies;

export const periodUnitOptions = [
    {
        value: 'D',
        display: strings.RetentionPolicyAdminForm.retentionPeriod.periodUnit.days,
    },
    {
        value: 'M',
        display: strings.RetentionPolicyAdminForm.retentionPeriod.periodUnit.months,
    },
    {
        value: 'Y',
        display: strings.RetentionPolicyAdminForm.retentionPeriod.periodUnit.years,
    },
];

const RetentionPolicyAdminFormRows = compose(
    withRRFActions,
    connect(
        createStructuredSelector({
            parentAttributeIdByAttributeId: parentAttributeIdByAttributeIdSelector,
        })
    ),
    withHandlers({
        onChangePeriod({ formActions }) {
            // when a number of period is entered or when isRetentionPolicyLimited is checked,
            // clear the indefinite checkbox
            return (value) => {
                if (!!value) {
                    formActions.reset('isInfinite');
                }
            };
        },
        onChangeIsInfinite({ formActions }) {
            // when the indefinite checkbox is checked, clear number of period
            return (value) => {
                if (value) {
                    formActions.reset('isRetentionPolicyLimited');
                    formActions.reset('periodAmount');
                    formActions.reset('periodUnit');
                }
            };
        },
        reasonForPoliceCustodyPropertyFilterOptions({ parentAttributeIdByAttributeId }) {
            return (options) =>
                filter(
                    options,
                    ({ value }) =>
                        parentAttributeIdByAttributeId(value) ===
                        globalAttributes.reasonForPoliceCustodyGlobal.other
                );
        },
    })
)(function RetentionPolicyAdminFormRows({
    onChangePeriod,
    onChangeIsInfinite,
    reasonForPoliceCustodyPropertyFilterOptions,
}) {
    return (
        <div>
            <Row>
                <RRFText path="code" width={halfFormWidth} />
            </Row>
            <Row>
                <RRFText path="description" width={fullFormWidth} />
            </Row>
            <RetentionPeriodLabel>
                {strings.RetentionPolicyAdminForm.retentionPeriod.label}
            </RetentionPeriodLabel>
            <Row>
                <RRFRadio
                    path="isRetentionPolicyLimited"
                    onChange={onChangePeriod}
                    size="md"
                    options={[
                        {
                            value: true,
                            label:
                                strings.RetentionPolicyAdminForm.retentionPeriod
                                    .storeForLimitedTime,
                        },
                    ]}
                    style={{ margin: '0px' }}
                />
            </Row>
            <WithFieldState path="isRetentionPolicyLimited">
                {({ fieldModel }) => (
                    <PeriodWrapper>
                        <RRFText
                            path="periodAmount"
                            width={smallFormWidth}
                            disabled={!fieldModel}
                            onChange={onChangePeriod}
                            label={' '}
                            style={{ marginTop: '0px' }}
                        />
                        <RRFSelect
                            options={periodUnitOptions}
                            path="periodUnit"
                            width={smallFormWidth}
                            disabled={!fieldModel}
                            onChange={onChangePeriod}
                            style={{ marginTop: '1px' }}
                        />
                    </PeriodWrapper>
                )}
            </WithFieldState>
            <Row>
                <RRFRadio
                    path="isInfinite"
                    onChange={onChangeIsInfinite}
                    size="md"
                    options={[
                        {
                            value: true,
                            label:
                                strings.RetentionPolicyAdminForm.retentionPeriod.storeIndefinitely,
                        },
                    ]}
                    label={' '}
                />
            </Row>
            <Row>
                <RRFDispositionApprovalLevelSelect
                    path="terminalDispositionApprovalLevel"
                    clearable={false}
                    width={halfFormWidth}
                />
            </Row>
            <Row>
                <RRFOffenseCodeSelect
                    path="offenseCodeIds"
                    flags={offenseCodeFlags}
                    multiple={true}
                    width="100%"
                />
            </Row>
            <Row>
                <RRFAttributeSelect
                    attributeType="REASON_FOR_POLICE_CUSTODY_OF_PROPERTY"
                    filterOptions={reasonForPoliceCustodyPropertyFilterOptions}
                    multiple={true}
                    path="custodyReasonAttrIds"
                    width="100%"
                />
            </Row>
            <Row>
                <RRFCheckbox path="isDefault" width={halfFormWidth} />
            </Row>
        </div>
    );
});

function RetentionPolicyAdminForm({
    retentionPolicies,
    selectedRetentionPolicyId,
    newRetentionPolicySelected,
    saving,
    formErrorMessage,
    onSubmit,
    onCancel,
}) {
    const selectedRetentionPolicy = retentionPolicies[selectedRetentionPolicyId];
    const hasRetentionPolicy = !!selectedRetentionPolicy || newRetentionPolicySelected;

    let title = '';
    let workAreaContent;
    let footer;

    const error = formErrorMessage && (
        <InlineBanner status="error">{formErrorMessage}</InlineBanner>
    );

    // only construct a form when there is a retention policy
    if (hasRetentionPolicy) {
        title = selectedRetentionPolicy
            ? selectedRetentionPolicy.description
            : strings.RetentionPoliciesAdmin.newRetentionPolicy;

        workAreaContent = (
            <ReactReduxForm {...retentionPolicyAdminForm}>
                <RetentionPolicyAdminFormRows />
            </ReactReduxForm>
        );

        footer = (
            <AdminLastModifiedFooter
                updateable={selectedRetentionPolicy}
                submittingForm={saving}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
        );
    }

    return (
        <AdminWorkAreaWrapper>
            <AdminHeader title={title} highlight={hasRetentionPolicy} error={formErrorMessage} />
            <AdminWorkArea>
                {error}
                {hasRetentionPolicy && workAreaContent}
            </AdminWorkArea>
            {hasRetentionPolicy && footer}
        </AdminWorkAreaWrapper>
    );
}

const mapStateToProps = createStructuredSelector({
    retentionPolicies: retentionPoliciesSelector,
    selectedRetentionPolicyId: selectedRetentionPolicyIdSelector,
    saving: savingRetentionPolicySelector,
    formErrorMessage: retentionPolicyAdminFormErrorMessageSelector,
});

const mapDispatchToProps = (dispatch) => ({
    submit: () => dispatch(submitRetentionPolicyAdminForm()),
});

/**
 * Route component for a new or selected retention policy (new or existing), with the
 *   form to create or update it.
 */
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(['route'], ({ route }) => ({
        newRetentionPolicySelected:
            route.name === routesConfig.ADMIN_EVIDENCE_RETENTION_POLICY_NEW.name,
    })),
    withHandlers({
        onSubmit({ newRetentionPolicySelected, submit, router }) {
            return () => {
                submit()
                    .then((retentionPolicy) => {
                        if (newRetentionPolicySelected && !!retentionPolicy) {
                            // after creating a new policy, select it
                            const id = retentionPolicy.retentionPolicyId;
                            const path = `/admin/evidence/retention-policies/${id}`;
                            router.push(path);
                        }
                    })
                    .catch(noop);
            };
        },
        onCancel({ router }) {
            return () => {
                router.push('/admin/evidence/retention-policies');
            };
        },
    })
)(RetentionPolicyAdminForm);

import { ElasticCase, ApprovalStatusForCaseEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { map, values } from 'lodash';
import {
    allSingleAttributeValuesMapper,
    buildViewModel,
    buildFormatRolesMapper,
    buildFormatRolesArrayMapper,
    subdivisionAttrIdsMapper,
} from '../../../../../helpers/viewModelHelpers';
import { ModuleShape } from '../../../../utils/createNormalizedModule';
import { getOffenseLocation, getPrimaryLocation } from '../../../elastic-reports/state/ui';
import { allRoleFormatsByRoleIdSelector } from '../../../roles/state/data';
import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '../../../attributes/state/data';
import { formatCaseDefinitionByIdSelector } from '../../../case-definitions/state/data';
import {
    statusIsClosedSelector,
    statusIsInactiveSelector,
    statusIsUnfoundedSelector,
} from '../../../case-statuses/state/ui';
import { isDateInFuture } from '../../../../dates/utils/dateHelpers';

// SELECTORS

export const elasticCasesViewModelForElasticCasesSelector = createSelector(
    allRoleFormatsByRoleIdSelector,
    formatCaseDefinitionByIdSelector,
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
    statusIsClosedSelector,
    statusIsInactiveSelector,
    statusIsUnfoundedSelector,
    (
        allRoleFormatsByRoleId,
        formatCaseDefinitionById,
        formatAttributeById,
        formatSubdivisionAttrIds,
        statusIsClosed,
        statusIsInactive,
        statusIsUnfounded
    ) => (elasticCases: ElasticCase[]) => {
        const viewModel = buildViewModel<ElasticCase>({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                subdivisionAttrIdsMapper,
                buildFormatRolesMapper({
                    assignee: 'assigneeRoleId',
                }),
                buildFormatRolesArrayMapper({
                    assistingInvestigators: 'assistingInvestigatorRoleIds',
                    supervisors: 'supervisorRoleIds',
                }),
                ({ localId }) => ({ localId: `#${localId}` }),
                ({ caseDefinitionId }) => ({
                    caseType: formatCaseDefinitionById(caseDefinitionId),
                }),
                ({ dueDateUtc, currentStatusAttrId, approvalStatus }) => {
                    const isDone =
                        (statusIsClosed(currentStatusAttrId) ||
                            statusIsUnfounded(currentStatusAttrId) ||
                            statusIsInactive(currentStatusAttrId)) &&
                        approvalStatus === ApprovalStatusForCaseEnum.APPROVED.name;
                    return {
                        isOverdue: dueDateUtc && !isDateInFuture(dueDateUtc) && !isDone,
                    };
                },
                ({ offenseCodeDetails }) => ({
                    primaryOffenseCode:
                        offenseCodeDetails && offenseCodeDetails.length > 0
                            ? offenseCodeDetails[0].displayValue
                            : '',
                }),
                ({ involvedLocations }) => ({
                    offenseLocation: getOffenseLocation(involvedLocations),
                    primaryLocation: getPrimaryLocation(involvedLocations),
                }),
            ],
            helpers: {
                allRoleFormatsByRoleId,
                formatAttributeById,
                formatSubdivisionAttrIds,
            },
        });
        return map(elasticCases, viewModel);
    }
);

// this selector factory does not match our preferred pattern, but we do this to keep elastic cases
// code in a central place while also accommodating how createsearchmodule expects to receive/create view models
// TODO file tech debt task to rework existing "createModelSelectors"
export function createElasticCasesResultsSelector(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    baseSelector: (state: any) => ModuleShape<ElasticCase>
) {
    return createSelector(
        elasticCasesViewModelForElasticCasesSelector,
        baseSelector,
        (elasticCasesViewModelForElasticCases, elasticCases) => {
            return elasticCasesViewModelForElasticCases(values(elasticCases));
        }
    );
}

import React from 'react';

import strings from '~/client-common/core/strings/componentStrings';
import HeaderSecondaryInfo from './EntityProfileHeaderSecondaryInfo';

const globalStrings = strings.entityProfiles.global;

const ExternalDepartmentNameSecondaryHeaderInfo = ({ ownerDepartmentName }) =>
    ownerDepartmentName ? (
        <HeaderSecondaryInfo label={globalStrings.agency}>
            {ownerDepartmentName}
        </HeaderSecondaryInfo>
    ) : null;

export default ExternalDepartmentNameSecondaryHeaderInfo;

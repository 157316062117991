import { AttributeTypeEnum } from '@mark43/rms-api';
import _ from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { getAdminListStatusFromStartEnd } from '~/client-common/core/dates/utils/dateHelpers';
import {
    requiredString,
    mustBeInt,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';

const cadUnitsAdminFormFieldViewModels = {
    cadUnit: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadUnit',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'callSign',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'dispatchAreaId',
                validators: {
                    requiredError: requiredString,
                    mustBeIntError: mustBeInt,
                },
            },
            {
                key: 'unitTypeAttrId',
                validators: {
                    requiredError: requiredString,
                    mustBeIntError: mustBeInt,
                },
            },
            {
                key: 'additionalUnitTypesAttrIds',
            },
            {
                key: 'equipmentIds',
            },
            {
                key: 'radioId',
            },
            {
                key: 'agencyId',
                validators: {
                    requiredError: requiredString,
                    mustBeIntError: mustBeInt,
                },
            },
            {
                key: 'activeDateUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'expirationDateUtc',
            },
            {
                key: 'stationId',
            },
            {
                key: 'defaultMembers',
            },
            {
                key: 'adminStatus',
            },
            {
                key: 'isMemberRequired',
            },
            {
                key: 'defaultTagNumberAttrId',
                validators: {
                    mustBeIntError: mustBeInt,
                },
            },
        ]),
    },
};

const initialState = {
    cadUnit: {
        isMemberRequired: true,
    },
};

function createUnitAttributesData(cadUnit, additionalUnitTypesAttrIds = [], equipmentIds = []) {
    const additionalUnitTypesData = _.map(additionalUnitTypesAttrIds, (attrId) => {
        return {
            departmentId: cadUnit.departmentId,
            unitId: cadUnit.id,
            attributeId: attrId,
            attributeType: AttributeTypeEnum.UNIT_TYPE.name,
            isPermanent: true,
        };
    });
    const equipmentData = _.map(equipmentIds, (attrId) => {
        return {
            departmentId: cadUnit.departmentId,
            unitId: cadUnit.id,
            attributeId: attrId,
            attributeType: AttributeTypeEnum.EQUIPMENT_TYPE.name,
            isPermanent: true,
        };
    });
    const radioIds = [];
    // control is in single mode and doesn't return an array
    if (cadUnit.radioId) {
        const radioData = {
            departmentId: cadUnit.departmentId,
            unitId: cadUnit.id,
            attributeId: cadUnit.radioId,
            attributeType: AttributeTypeEnum.RADIO_ID.name,
            isPermanent: true,
        };
        radioIds.push(radioData);
    }
    return _.union(additionalUnitTypesData, equipmentData, radioIds);
}

function createDefaultMembers(cadUnit, defaultMembers) {
    return _.map(
        _.filter(defaultMembers, (x) => x.userId != null),
        (defaultMember) => ({
            ...defaultMember,
            departmentId: cadUnit.departmentId,
            userId: defaultMember.userId,
            unitId: cadUnit.id,
            agencyId: cadUnit.agencyId,
        })
    );
}

function convertToFormModel({ cadUnit = {} }) {
    const additionalUnitTypesAttrIds = [];
    const equipmentIds = [];
    let radioId;
    const adminStatus = getAdminListStatusFromStartEnd(
        cadUnit.activeDateUtc,
        cadUnit.expirationDateUtc
    );

    _.forEach(cadUnit.unitAttributes, (attr) => {
        if (attr.attributeType === AttributeTypeEnum.UNIT_TYPE.name) {
            additionalUnitTypesAttrIds.push(attr.attributeId);
        }
        if (attr.attributeType === AttributeTypeEnum.EQUIPMENT_TYPE.name) {
            equipmentIds.push(attr.attributeId);
        }
        if (attr.attributeType === AttributeTypeEnum.RADIO_ID.name) {
            radioId = attr.attributeId;
        }
    });
    return {
        cadUnit: {
            ...cadUnit,
            additionalUnitTypesAttrIds,
            equipmentIds,
            radioId,
            adminStatus,
        },
    };
}

function convertFromFormModel({ cadUnit = {} }) {
    const unitAttributesData = createUnitAttributesData(
        cadUnit,
        cadUnit.additionalUnitTypesAttrIds,
        cadUnit.equipmentIds,
        cadUnit.radioId
    );
    const defaultMembers = createDefaultMembers(cadUnit, cadUnit.defaultMembers);
    return {
        cadUnit: {
            ...cadUnit,
            defaultMembers,
            unitAttributes: _.filter(
                unitAttributesData,
                (attr) => attr.attributeId !== cadUnit.unitTypeAttrId
            ),
        },
    };
}

/**
 * Module of the cad units admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.CAD_UNITS_ADMIN,
    fieldViewModels: cadUnitsAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
    initialState,
});

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { filter, map } from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    isUnknown,
    buildPersonProfileLink,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import useFields from '~/client-common/core/fields/hooks/useFields';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { PersonLabels as _PersonLabels } from '../../../../core/components/tags/PersonLabels';
import { PersonEnhancedCautions as _PersonEnhancedCautions } from '../../../../core/cautions/components/PersonEnhancedCautions';
import { iconTypes } from '../../../../core/components/Icon';
import { useDateTimeFormatter } from '../../../../core/current-user/hooks/dateTimeFormats';
import EntitySummaryHeader from './EntitySummaryHeader';
import { TwoColumnSummary } from './TwoColumnSummary';

const strings = componentStrings.summaries.PersonProfileTitleSummary;

const PersonLabels = styled(_PersonLabels)`
    margin-top: 6px;
`;

const PersonEnhancedCautions = styled(_PersonEnhancedCautions)`
    margin-top: 6px;
`;

const UnconnectedPersonProfileTitleSummary = ({
    imageUrl,
    linkTypeLetterNumber,
    personProfileViewModel,
    disableLink = false,
    collapsed,
    showCautionsAsSnapshot = false,
}) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const { dateOfBirth, ssn, dlNumber } = personProfileViewModel;

    const viewModelProperties = getViewModelProperties(personProfileViewModel);

    const fieldDisplayNames = useFields([
        fields.PERSON_PROFILE_SSN,
        fields.PERSON_PROFILE_RACE_ATTR_ID,
        fields.PERSON_PROFILE_ETHNICITY_ATTR_ID,
        fields.PERSON_PROBATION_PROBATION_TYPE_ATTR_ID,
        fields.PERSON_PROBATION_PROBATION_OFFICER,
        fields.PERSON_PROBATION_MISC_DESCRIPTION,
    ]);

    const dateTimeFormatter = useDateTimeFormatter();

    const {
        sexAttrId,
        raceEthnicity,
        height,
        eyeColorAttrId,
        hairColorAttrId,
        personProbations = [],
        cautions,
    } = viewModelProperties;

    const leftColumnConfig = [
        {
            label: strings.dateOfBirth,
            value: !!dateOfBirth && dateTimeFormatter.formatDate(dateOfBirth),
        },
        {
            label: strings.sex,
            value: sexAttrId,
        },
        ...personProbations.flatMap((item) => [
            {
                label: fieldDisplayNames[fields.PERSON_PROBATION_PROBATION_TYPE_ATTR_ID],
                value: formatAttributeById(item.probationTypeAttrId),
            },
            {
                label: fieldDisplayNames[fields.PERSON_PROBATION_PROBATION_OFFICER],
                value: item.probationOfficer,
            },
            {
                label: fieldDisplayNames[fields.PERSON_PROBATION_MISC_DESCRIPTION],
                value: item.miscDescription,
            },
        ]),
        {
            label: `${fieldDisplayNames[fields.PERSON_PROFILE_RACE_ATTR_ID]}/${
                fieldDisplayNames[fields.PERSON_PROFILE_ETHNICITY_ATTR_ID]
            }`,
            value: raceEthnicity,
        },
        {
            label: strings.height,
            value: height,
        },
    ];

    const rightColumnConfig = [
        {
            label: strings.eyes,
            value: eyeColorAttrId,
        },
        {
            label: strings.hair,
            value: hairColorAttrId,
        },
        {
            label: fieldDisplayNames[fields.PERSON_PROFILE_SSN],
            value: ssn,
        },
        {
            label: strings.dlNumber,
            value: dlNumber,
        },
    ];

    const postTitleOptions = joinTruthyValues([
        personProfileViewModel.isUnborn && strings.unborn,
        personProfileViewModel.isDead && strings.deceased,
    ]);

    return (
        <EntitySummaryHeader
            imageUrl={imageUrl}
            iconType={
                isUnknown(personProfileViewModel) ? iconTypes.UNKNOWN_PERSON : iconTypes.PERSON
            }
            name={viewModelProperties.fullName}
            preTitle={linkTypeLetterNumber}
            linkTo={
                !isUnknown(personProfileViewModel) &&
                !disableLink &&
                buildPersonProfileLink(personProfileViewModel)
            }
            postTitle={!!postTitleOptions ? `(${postTitleOptions})` : ''}
            subTitle={
                viewModelProperties.monikers &&
                viewModelProperties.monikers.length > 0 &&
                `aka ${joinTruthyValues(map(viewModelProperties.monikers, 'moniker'))}`
            }
            tags={
                <FeatureFlagged
                    flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <PersonLabels
                            nameAttributes={filter(viewModelProperties.nameAttributes, {
                                attributeType: AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
                            })}
                            isJuvenile={personProfileViewModel.isJuvenile}
                            isVulnerable={personProfileViewModel.isVulnerable}
                            dateVulnerableTo={personProfileViewModel.dateVulnerableTo}
                            isSuspectedGangMember={personProfileViewModel.isAllegedGangMember}
                            size="sm"
                        />
                    }
                >
                    <PersonEnhancedCautions
                        cautions={cautions}
                        evaluateByCreationDates={showCautionsAsSnapshot}
                        isJuvenile={personProfileViewModel.isJuvenile}
                        isVulnerable={personProfileViewModel.isVulnerable}
                        dateVulnerableTo={personProfileViewModel.dateVulnerableTo}
                        isSuspectedGangMember={personProfileViewModel.isAllegedGangMember}
                        size="sm"
                    />
                </FeatureFlagged>
            }
        >
            {!!collapsed && (
                <TwoColumnSummary
                    leftColumnConfig={leftColumnConfig}
                    rightColumnConfig={rightColumnConfig}
                />
            )}
        </EntitySummaryHeader>
    );
};

export default UnconnectedPersonProfileTitleSummary;

import React from 'react';
import { map, isEmpty } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import Thumbnail from '../../../attachments/files/components/Thumbnail';
import EntityProfileSectionNoData from '../../core/components/EntityProfileSectionNoData';

const strings = componentStrings.entityProfiles.person.AppearanceDetails;

// regex used later to replace '/' with ' / ' for more reasonable line breaks
// '/' makes it think it's one long word
const FIND_SLASH_REGEX = /\//g;

function AppearanceDetails({ personDetailsViewModel }) {
    const {
        nameAttributesDisplay,
        clothingAttributesDisplayObject,
        identifyingMarks,
        identifyingMarksImages,
    } = personDetailsViewModel;
    const display = personDetailsViewModel ? getViewModelProperties(personDetailsViewModel) : {};

    const clothingAttributes = map(clothingAttributesDisplayObject, (clothing) => {
        return (
            <SummaryRow
                key={clothing.parentAttribute}
                label={clothing.parentAttribute.replace(FIND_SLASH_REGEX, ' / ')}
            >
                {clothing.description}
            </SummaryRow>
        );
    });

    const physicalCharacteristicsAttributes = map(
        nameAttributesDisplay.PHYSICAL_CHARACTERISTIC,
        (attr) => {
            return <div key={attr}>{attr}</div>;
        }
    );

    const identifyingMarkDisplayRows = map(identifyingMarks, (im) => {
        return <div key={im.description}>{im.description}</div>;
    });

    const identifyingMarkThumbnails = map(identifyingMarksImages, (image, index) => {
        return (
            <Thumbnail key={index} image={image} index={index} images={identifyingMarksImages} />
        );
    });

    return (
        <div>
            <EntityProfileSection title={strings.title}>
                <EntityProfileSummaryColumn>
                    <EntityProfileDetailSummaryList>
                        <SummaryRow label={strings.labels.height}>{display.height}</SummaryRow>
                        <SummaryRow label={strings.labels.weight}>{display.weight}</SummaryRow>
                        <SummaryRow label={strings.labels.skinTone}>
                            {display.skinToneAttrId}
                        </SummaryRow>
                        <SummaryRow label={strings.labels.eyeColor}>
                            {display.eyeColorAttrId}
                        </SummaryRow>
                        <SummaryRow label={strings.labels.hairColor}>
                            {display.hairColorAttrId}
                        </SummaryRow>
                        <SummaryRow label={strings.labels.hairStyle}>
                            {display.hairStyleAttrId}
                        </SummaryRow>
                        <SummaryRow label={strings.labels.hairLength}>
                            {display.hairLengthAttrId}
                        </SummaryRow>
                        <SummaryRow label={strings.labels.facialHair}>
                            {display.facialHairTypeAttrId}
                        </SummaryRow>
                        <SummaryRow label={strings.labels.build}>{display.buildAttrId}</SummaryRow>
                        <SummaryRow
                            label={strings.labels.physicalChar}
                            hidden={!physicalCharacteristicsAttributes.length}
                        >
                            {physicalCharacteristicsAttributes}
                        </SummaryRow>
                    </EntityProfileDetailSummaryList>
                </EntityProfileSummaryColumn>
                <EntityProfileSummaryColumn>
                    <EntityProfileDetailSummaryList>
                        <SummaryRow label={strings.labels.vision}>
                            {display.visionAttrId}
                        </SummaryRow>

                        {clothingAttributes}
                    </EntityProfileDetailSummaryList>
                </EntityProfileSummaryColumn>
            </EntityProfileSection>
            {identifyingMarks.length > 0 && (
                <EntityProfileSection noDivider={true}>
                    <EntityProfileSummaryColumn className="identifying-marks">
                        <EntityProfileDetailSummaryList contentWidth={440}>
                            <SummaryRow
                                label={strings.labels.identifyingMarks}
                                hidden={!identifyingMarks.length}
                            >
                                {identifyingMarkDisplayRows}
                                {identifyingMarkThumbnails}
                            </SummaryRow>
                        </EntityProfileDetailSummaryList>
                    </EntityProfileSummaryColumn>
                </EntityProfileSection>
            )}
        </div>
    );
}

// NOTE `EntityProfileSection` is reused here due to `AppearanceDetails`
// using two `EntityProfileSection`s side by side, which prevents
// the usage of a top level `EntityProfileSection` as wrapper in `AppearanceDetailsWrapper`
const RenderNoInformation = () => (
    <EntityProfileSection title={strings.title}>
        <EntityProfileSectionNoData />
    </EntityProfileSection>
);

const displayProps = [
    'height',
    'weight',
    'skinToneAttrId',
    'eyeColorAttrId',
    'hairColorAttrId',
    'hairStyleAttrId',
    'hairLengthAttrId',
    'facialHairTypeAttrId',
    'buildAttrId',
    'visionAttrId',
];

const AppearanceDetailsBranch = renderOnlyIf(({ personDetailsViewModel }) => {
    const {
        nameAttributesDisplay,
        clothingAttributesDisplayObject,
        identifyingMarks,
    } = personDetailsViewModel;
    const display = getViewModelProperties(personDetailsViewModel);

    return (
        clothingAttributesDisplayObject.length ||
        !isEmpty(nameAttributesDisplay.PHYSICAL_CHARACTERISTIC) ||
        identifyingMarks.length ||
        displayProps.some((key) => display[key])
    );
}, RenderNoInformation)(AppearanceDetails);

export default function AppearanceDetailsWrapper(props) {
    return (
        <div>
            <AppearanceDetailsBranch {...props} />
        </div>
    );
}

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { sortedProfileWarrantsViewModelsSelector } from '../../core/state/ui';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import { WarrantCell, StatusCell } from '../../../warrants/dashboard/components';

const strings = componentStrings.entityProfiles.EntityProfilePersonWarrants;

function EntityProfilePersonWarrants({ sortedProfileWarrantsViewModels, handleRowClick }) {
    return (
        <Table
            data={sortedProfileWarrantsViewModels}
            onRowClick={handleRowClick}
            noRowsText="No Warrant Results"
            simpleStyles={true}
            rowComponent={ElasticResultRow}
        >
            <TableHeader>
                <TableColumns>
                    <TableColumn
                        width={700}
                        columnKey="warrant"
                        display={strings.columns.warrant.label}
                    />
                    <TableColumn
                        width={64}
                        columnKey="status"
                        display={strings.columns.status.label}
                    />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <WarrantCell columnKey="warrant" />
                <StatusCell columnKey="status" />
            </TableBody>
        </Table>
    );
}

const mapStateToProps = createStructuredSelector({
    sortedProfileWarrantsViewModels: sortedProfileWarrantsViewModelsSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleRowClick({ id }) {
        router.push(`/warrants/${id}`);
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EntityProfilePersonWarrants);

import { RuleCondition } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'ruleConditions';

const ruleConditionsModule = createNormalizedModule<RuleCondition>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const ruleConditionsSelector = ruleConditionsModule.selectors.entitiesSelector;
export const ruleConditionsWhereSelector = ruleConditionsModule.selectors.entitiesWhereSelector;

// REDUCER
export default ruleConditionsModule.reducerConfig;

import React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';

import computeCheckboxValue from '../utils/computeCheckboxValue';
import FlexContainer from './core/FlexContainer';
import SelectAllHeaderColumn from './SelectAllHeaderColumn';

const SelectAllContainer = styled.div`
    background: var(--arc-colors-surface-accent);
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
    position: relative;
    border-bottom: solid 4px var(--arc-colors-border-default);
    border-top: solid 4px var(--arc-colors-border-default);
`;

const SelectAllHeaderRow = ({
    onLeftCheckboxChange,
    onRightCheckboxChange,
    allLeftSelected,
    allRightSelected,
}) => (
    <SelectAllContainer>
        <FlexContainer overflowX="visible" overflowY="visible">
            <SelectAllHeaderColumn
                onChange={onLeftCheckboxChange}
                disabled={allLeftSelected}
                value={computeCheckboxValue(allLeftSelected, allRightSelected)}
            />
            <SelectAllHeaderColumn
                onChange={onRightCheckboxChange}
                disabled={allRightSelected}
                value={computeCheckboxValue(allRightSelected, allLeftSelected)}
                last={true}
            />
        </FlexContainer>
    </SelectAllContainer>
);

export default pure(SelectAllHeaderRow);

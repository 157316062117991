import { createSelector } from 'reselect';
import { mapValues } from 'lodash';
import {
    buildViewModel,
    allSingleOfficerIdListsMapper,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';
import { dateTimeRangeFormatter } from '../../../../dates/utils/dateHelpers';
import { eventDetailsSelector } from '../data';

import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { currentDepartmentDateFormatterSelector } from '../../../current-user/state/ui';

const eventDetailViewModelsSelector = createSelector(
    formatMiniUserByIdSelector,
    eventDetailsSelector,
    formatAttributeByIdSelector,
    currentDepartmentDateFormatterSelector,
    (formatMiniUserById, eventDetails, formatAttributeById, dateTimeFormatter) => {
        const viewModel = buildViewModel({
            mappers: [
                allSingleAttributeValuesMapper,
                allSingleOfficerIdListsMapper,
                boolToDisplayMapper,
                ({ eventStartUtc, eventEndUtc }) => ({
                    eventTime: dateTimeRangeFormatter(
                        eventStartUtc,
                        eventEndUtc,
                        dateTimeFormatter
                    ),
                }),
            ],
            helpers: {
                formatUserProfileById: formatMiniUserById,
                formatAttributeById,
            },
        });
        return mapValues(eventDetails, viewModel);
    }
);

export const eventDetailViewModelByReportIdSelector = createSelector(
    eventDetailViewModelsSelector,
    (eventDetailViewModels) => (reportId) => eventDetailViewModels[reportId] || {}
);

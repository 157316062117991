import { PrintingDataTypeEnum } from '@mark43/rms-api';

import type { PacketOption } from './exportHelpers';
import isPrintableOfPrintingDataType from './isPrintableOfPrintingDataType';

export default function isCasePacket(packetOption: PacketOption): boolean {
    return packetOption.rawPacket
        ? isPrintableOfPrintingDataType(packetOption.rawPacket, PrintingDataTypeEnum.CASE.name)
        : false;
}

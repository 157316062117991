import {
    SavedSearchConfiguredEntityRequest,
    SavedSearchConfiguredEntityRequests,
    SearchQueryElasticReportQuery,
} from '@mark43/rms-api';

export function getSavedSearchConfiguredEntityRequestsForElasticReportQueries(
    queries: SearchQueryElasticReportQuery[]
): SavedSearchConfiguredEntityRequests {
    return {
        requests: queries
            .flatMap((query) => {
                return (query.elasticQuery.reportDefinitions || []).flatMap((reportDefinitionQuery) => {
                    return (reportDefinitionQuery.configuredEntities || [])
                        .filter(
                            (entityQuery): entityQuery is typeof entityQuery & { key: string } =>
                                !!entityQuery.key
                        )
                        .flatMap(({ key, configuredBooleanQuery = [], configuredIdQuery = [] }):
                            | SavedSearchConfiguredEntityRequest
                            | undefined => {
                            if (!configuredBooleanQuery.length && !configuredIdQuery.length) {
                                return;
                            }
                            return {
                                configuredEntityTypeKeyName: key,
                                // Even though those two properties are _not_ nullable, we are manually modifying the
                                // saved search JSON on the backend to "clean" it and remove empty properties, which
                                // also includes empty arrays. This is breaking our types contract and is causing
                                // subtle issues and gotchas.
                                booleanCepInfo: configuredBooleanQuery.map((booleanQuery) => ({
                                    configuredEntityPropertyKeyName: booleanQuery.key,
                                    configuredEntityPropertyValue: booleanQuery.value,
                                })),
                                idCepInfo: configuredIdQuery.flatMap((idQuery) =>
                                    idQuery.value.flatMap((value) => ({
                                        configuredEntityPropertyKeyName: idQuery.key,
                                        configuredEntityPropertyValue: value,
                                    }))
                                ),
                            };
                        });
                });
            })
            .filter((item): item is NonNullable<typeof item> => !!item),
    };
}

import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Flex } from 'arc';
import { lifecycleOptions, Fields, Observer } from 'markformythree';

import { TOW_VEHICLE_RELEASE_RELEASE_BY } from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import overlayStateTypeEnum from '~/client-common/core/enums/client/overlayStateTypeEnum';
import pillLayoutEnum from '~/client-common/core/enums/client/pillLayoutEnum';

import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { formatNameReportLinkTypeIdSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import { currentUserIdSelector } from '../../../../core/current-user/state/ui';

import {
    onCloseTowVehicleReleaseSidePanel,
    saveTowVehicleReleaseSidePanel,
} from '../../state/ui/tow-vehicle-release-side-panel/towVehicleReleaseSidePanel';
import {
    RELEASE_BY_PATH,
    formName,
    refreshTowVehicleReleaseFormNameReportLinks,
    prefillReleaseBy,
    formSetReleaseTo,
    formSetAuthorizedBy,
} from '../../state/forms/towVehicleReleaseForm';
import { getErrorMessagesFromErrors } from '../../helpers/validationHelpers';
import {
    PortalSidePanel,
    SidePanelSection,
} from '../../../../../legacy-redux/components/core/SidePanel';
import { OverlayBaseHelper } from '../../../../core/components/OverlayBaseHelper';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { ArbiterMFTUserSelect } from '../../../../core/forms/components/selects/UserSelect';
import Row from '../../../../core/components/Row';
import FormRow from '../../../../core/forms/components/FormRow';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import testIds from '../../../../../core/testIds';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';

const strings = componentStrings.reports.core.TowVehicleReleaseCard;

const AssignSpecificButton = styled(Button)`
    margin-top: 23px;
    height: fit-content;
`;

const mapStateToProps = createStructuredSelector({
    formatNameReportLinkTypeId: formatNameReportLinkTypeIdSelector,
    fieldConfigurationContextByContextAndFieldName: fieldConfigurationContextByContextAndFieldNameSelector,
    reportById: reportByIdSelector,
    currentUserId: currentUserIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    refreshTowVehicleReleaseFormNameReportLinks: ({ reportId }) =>
        dispatch(refreshTowVehicleReleaseFormNameReportLinks({ reportId })),
    onCloseTowVehicleReleaseSidePanel: ({ reportId }) =>
        dispatch(onCloseTowVehicleReleaseSidePanel({ reportId })),
    saveTowVehicleReleaseSidePanel: ({ reportId }) =>
        dispatch(saveTowVehicleReleaseSidePanel({ reportId })),
});

const TowVehicleReleaseSidePanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(function _TowVehicleReleaseSidePanel({
    reportId,
    formatNameReportLinkTypeId,
    fieldConfigurationContextByContextAndFieldName,
    reportById,
    currentUserId,
    refreshTowVehicleReleaseFormNameReportLinks,
    onCloseTowVehicleReleaseSidePanel,
    saveTowVehicleReleaseSidePanel,
}) {
    const towVehicleReleaseToTitle = formatNameReportLinkTypeId(
        LinkTypesEnum.TOW_VEHICLE_RELEASE_TO
    );
    const towVehicleReleaseAuthorizedByTitle = formatNameReportLinkTypeId(
        LinkTypesEnum.TOW_VEHICLE_RELEASE_AUTHORIZED_BY
    );

    return (
        <OverlayBaseHelper
            id={overlayIdEnum.TOW_VEHICLE_RELEASE_SIDE_PANEL}
            overlayStateType={overlayStateTypeEnum.TOW_VEHICLE_RELEASE_OVERLAY}
        >
            {({
                overlayBase: { setError, ...overlayProps },
                screenManagerApi,
                savePanel,
                closePanel,
                ...renderProps
            }) => {
                const renForRecents = get(reportById(reportId), 'reportingEventNumber');

                // TODO TW-1121: Get rid of this logic when cutting over this component from
                // a `SidePanelNameSummary` to a standard `NameSummaryViewWrapper`.
                // Not ideal ... the `SidePanelNameSummary` component does not work as expected.
                // `onDeleteSuccess` will *not* be called after the desired `NameReportLink` deletion
                // API call, it will rather be called *instead* of the desired `NameReportLink` deletion
                // API call, which is unintuitive.  Therefore, if we want the `NameReportLink` deletion to
                // fire off, then we cannot provide an `onDeleteSuccess` prop to the `SidePanelNameSummary`
                // component.  That's the reason for this hacky code -- because adding and deleting
                // `NameReportLink`s will cause this component to re-render, we will update the form state's
                // `NameReportLink`s on re-render.
                refreshTowVehicleReleaseFormNameReportLinks({ reportId });

                const { getCurrentScreen, setCurrentScreenState } = screenManagerApi;
                const {
                    screenState: { isPerformingAsyncAction },
                } = getCurrentScreen();
                const {
                    overlayState: { errors },
                } = overlayProps;
                const onCancelSidePanel = () => {
                    setCurrentScreenState({ isPerformingAsyncAction: false });
                    onCloseTowVehicleReleaseSidePanel({ reportId });
                    setError([]);
                    closePanel();
                };
                const onSaveSidePanel = () => {
                    setCurrentScreenState({ isPerformingAsyncAction: true });
                    saveTowVehicleReleaseSidePanel({ reportId })
                        .then(() => {
                            setCurrentScreenState({ isPerformingAsyncAction: false });
                            setError([]);
                            savePanel();
                        })
                        .catch((formErrors) => {
                            setCurrentScreenState({ isPerformingAsyncAction: false });
                            setError(getErrorMessagesFromErrors(formErrors));
                        });
                };

                return (
                    <PortalSidePanel
                        errorMessages={errors}
                        // always true -- we always want panel level error messages to show.
                        userHasAttemptedSave={true}
                        title={strings.title}
                        testId={testIds.RELEASE_INFORMATION_SIDE_PANEL}
                        closePanel={onCancelSidePanel}
                        savePanel={onSaveSidePanel}
                        saveDisabled={isPerformingAsyncAction}
                        cancelDisabled={isPerformingAsyncAction}
                        {...renderProps}
                        {...overlayProps}
                    >
                        <ArbiterForm
                            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                            name={formName}
                            context={formName}
                            render={() => {
                                return (
                                    <>
                                        <Row>
                                            <ArbiterMFTDatePicker
                                                path="releaseDateUtc"
                                                includeTime={true}
                                            />
                                            <ArbiterMFTAttributeSelect
                                                path="releaseTypeAttrId"
                                                attributeType={
                                                    AttributeTypeEnum.TOW_VEHICLE_RELEASE_TYPE.name
                                                }
                                                length="lg"
                                            />
                                            <FormRow hasIndent>
                                                <ArbiterMFTText
                                                    length="lg"
                                                    path="releaseTypeOther"
                                                />
                                            </FormRow>
                                            <Observer
                                                subscriptions={{
                                                    isReleaseByDynamicallyHidden: [
                                                        RELEASE_BY_PATH,
                                                        Fields.HIDDEN,
                                                    ],
                                                }}
                                                render={({ isReleaseByDynamicallyHidden }) => {
                                                    const releaseByFieldConfigurationContext = fieldConfigurationContextByContextAndFieldName(
                                                        formName,
                                                        TOW_VEHICLE_RELEASE_RELEASE_BY
                                                    );
                                                    const isReleaseByStaticallyHidden =
                                                        releaseByFieldConfigurationContext.isStaticallyHidden;

                                                    const isReleaseByShown = !(
                                                        isReleaseByDynamicallyHidden ||
                                                        isReleaseByStaticallyHidden
                                                    );

                                                    if (isReleaseByShown) {
                                                        return (
                                                            <Flex>
                                                                <ArbiterMFTUserSelect
                                                                    path="releaseBy"
                                                                    length="lg"
                                                                    additionalIds={[currentUserId]}
                                                                />
                                                                <AssignSpecificButton
                                                                    className={
                                                                        buttonTypes.SECONDARY
                                                                    }
                                                                    onClick={() => {
                                                                        prefillReleaseBy({
                                                                            releaseBy: currentUserId,
                                                                        });
                                                                    }}
                                                                    testId={
                                                                        testIds.ASSIGN_TO_ME_BUTTON
                                                                    }
                                                                >
                                                                    {
                                                                        strings.releaseAuthorizedByAssignToMe
                                                                    }
                                                                </AssignSpecificButton>
                                                            </Flex>
                                                        );
                                                    }
                                                    return null;
                                                }}
                                            />
                                            <ArbiterMFTTextArea
                                                path="additionalNotes"
                                                length="lg"
                                                rows={4}
                                            />
                                        </Row>
                                        <SidePanelSection title={towVehicleReleaseToTitle}>
                                            <NameSummaryViewWrapper
                                                renForRecents={renForRecents}
                                                personOverlayIdPrefix={
                                                    overlayIdEnum.TOW_VEHICLE_RELEASE_PERSON
                                                }
                                                organizationOverlayIdPrefix={
                                                    overlayIdEnum.TOW_VEHICLE_RELEASE_ORGANIZATION
                                                }
                                                addNameButtonText={towVehicleReleaseToTitle}
                                                contextType={EntityTypeEnum.REPORT.name}
                                                contextId={reportId}
                                                parentEntityType={EntityTypeEnum.REPORT.name}
                                                parentId={reportId}
                                                linkType={LinkTypesEnum.TOW_VEHICLE_RELEASE_TO}
                                                show={{ people: true, organizations: true }}
                                                limitToOne={true}
                                                showExpandCollapseButton={false}
                                                onAddSuccess={(link) =>
                                                    formSetReleaseTo(get(link, 'linkType'))
                                                }
                                                onRemoveSuccess={() => formSetReleaseTo()}
                                                quickAddLayout={pillLayoutEnum.FULL_WIDTH}
                                            />
                                        </SidePanelSection>
                                        <SidePanelSection
                                            title={towVehicleReleaseAuthorizedByTitle}
                                        >
                                            <NameSummaryViewWrapper
                                                renForRecents={renForRecents}
                                                personOverlayIdPrefix={
                                                    overlayIdEnum.TOW_VEHICLE_RELEASE_PERSON
                                                }
                                                organizationOverlayIdPrefix={
                                                    overlayIdEnum.TOW_VEHICLE_RELEASE_ORGANIZATION
                                                }
                                                addNameButtonText={
                                                    towVehicleReleaseAuthorizedByTitle
                                                }
                                                contextType={EntityTypeEnum.REPORT.name}
                                                contextId={reportId}
                                                parentEntityType={EntityTypeEnum.REPORT.name}
                                                parentId={reportId}
                                                linkType={
                                                    LinkTypesEnum.TOW_VEHICLE_RELEASE_AUTHORIZED_BY
                                                }
                                                show={{ people: true, organizations: true }}
                                                limitToOne={true}
                                                showExpandCollapseButton={false}
                                                onAddSuccess={(link) =>
                                                    formSetAuthorizedBy(get(link, 'linkType'))
                                                }
                                                onRemoveSuccess={() => formSetAuthorizedBy()}
                                                quickAddLayout={pillLayoutEnum.FULL_WIDTH}
                                            />
                                        </SidePanelSection>
                                    </>
                                );
                            }}
                        />
                    </PortalSidePanel>
                );
            }}
        </OverlayBaseHelper>
    );
});

export default TowVehicleReleaseSidePanel;

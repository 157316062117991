import { PersonProbation } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'personProbations';

const personProbationsModule = createGroupedModule<PersonProbation>({
    type: NEXUS_STATE_PROP,
    key: 'personProfileId',
});

// SELECTORS
export const personProbationsSelector = personProbationsModule.selectors.entitiesSelector;
export const personProbationsByPersonProfileIdSelector =
    personProbationsModule.selectors.entitiesByKeySelector;

// REDUCER
export default personProbationsModule.reducerConfig;

import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { uploadingFilesSelector as uploadingInlineAttachmentsFilesSelector } from './inlineAttachmentsUploader';
import { uploadingFilesSelector as uploadingAttachmentsFilesSelector } from './attachmentsSidePanel';
import { filesToScanSelector } from './securityScanning';

// Note: need to put this in a new file due to a circular dependency issue with imports
export const isProcessingUploadingAttachmentsSelector = createSelector(
    uploadingInlineAttachmentsFilesSelector,
    uploadingAttachmentsFilesSelector,
    filesToScanSelector,
    applicationSettingsSelector,
    (uploadingInlineAttachments, uploadingAttachments, filesToScan, applicationSettings) => {
        if (applicationSettings.RMS_FEDRAMP_FILE_UPLOAD_STATUSES_ENABLED) {
            return (
                !isEmpty(uploadingInlineAttachments) ||
                !isEmpty(uploadingAttachments) ||
                !isEmpty(filesToScan)
            );
        }
        return false;
    }
);

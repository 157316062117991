import { get } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';
import { getRotationAreaForListRequest } from '../../utils/helpers';

const cadRotationListFormFieldViewModels = {
    cadRotationList: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadRotationList',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'name',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'rotationArea',
            },
            {
                key: 'rotationListAreasNames',
            },
            {
                key: 'rotationServiceTypeId',
            },
        ]),
    },
};

function convertCadRotationListToFormModel(cadRotationList) {
    if (!cadRotationList) {
        return null;
    }

    const rotationListAreasNames =
        cadRotationList.rotationListAreas &&
        cadRotationList.rotationListAreas.map((rotationListArea) => rotationListArea.name);
    return {
        cadRotationList: {
            ...cadRotationList,
            rotationListAreasNames,
        },
    };
}

function convertFormModelToCadRotationList({
    formModel,
    isEnhancedServiceRotationEnabled,
    selectedCadRotationList,
    noRotationAreaOptions,
}) {
    if (!isEnhancedServiceRotationEnabled) {
        return formModel;
    }

    const { rotationListAreas: originalRotationListAreas = [] } = selectedCadRotationList;

    const {
        departmentId,
        id: rotationListId,
        rotationListAreasNames = [],
        ...remainingParams
    } = formModel;

    const newRotationListAreas = rotationListAreasNames.map(
        (rotationListAreaName) =>
            originalRotationListAreas.find(
                (originalRotationListArea) => originalRotationListArea.name === rotationListAreaName
            ) ?? {
                name: rotationListAreaName,
                departmentId,
            }
    );

    const rotationArea = getRotationAreaForListRequest({
        noRotationAreaOptions,
        formRotationArea: formModel.rotationArea,
        currentRotationArea: selectedCadRotationList.rotationArea,
        rotationListAreasNames,
    });

    return {
        ...remainingParams,
        rotationArea,
        departmentId,
        id: rotationListId,
        rotationListAreas: noRotationAreaOptions
            ? originalRotationListAreas.filter(
                  (rotationListArea) => rotationListArea.name === rotationArea
              )
            : newRotationListAreas,
    };
}

const formModule = createFormModule({
    formName: formClientEnum.CAD_SERVICE_ROTATION_LISTS_ADMIN,
    fieldViewModels: cadRotationListFormFieldViewModels,
    convertFromFormModel: convertFormModelToCadRotationList,
    convertToFormModel: convertCadRotationListToFormModel,
});

/**
 * Module of the cad rotation list admin form.
 * @type {Object}
 */
export default formModule;

export function validate(uiSelector, isEnhancedServiceRotationEnabled) {
    const setValidity = formModule.actionCreators.setValidity;
    return (dispatch, getState) => {
        dispatch(formModule.actionCreators.validate());
        const state = getState();
        const formModel = formModule.selectors.formModelSelector(state);
        let isValid = formModule.selectors.formIsValidSelector(getState());
        if (isValid) {
            // the default validator returns true on an empty object.
            isValid = Object.keys(formModel).length > 0;
        }

        const { selectedCadServiceType } = uiSelector(state);

        const shapefileId = get(selectedCadServiceType, 'shapefileId');

        if (shapefileId) {
            const validateHasField = (field) => {
                const fieldValue = get(formModel, field);
                const isFieldValid = Array.isArray(fieldValue)
                    ? fieldValue.length > 0
                    : !!fieldValue;

                if (!isFieldValid) {
                    isValid = false;
                }
                dispatch(
                    setValidity(field, {
                        requiredError: isFieldValid,
                    })
                );
            };

            validateHasField(
                `cadRotationList.${
                    isEnhancedServiceRotationEnabled ? 'rotationListAreasNames' : 'rotationArea'
                }`
            );
        }

        return !isValid;
    };
}

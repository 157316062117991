import { DragonFormsResource } from '@mark43/rms-api';
import { ParentFormPath } from '../rms-types';
import { req } from '../../../lib/ajax';
import { uiConfigurationIdToNumber } from './ui-configuration-id-to-number';

/**
 * Given a list of dragon instance ids, a target configured form id and the path to the current form,
 * returns populated form values for the instances. These shapes can be added to a dragon form.
 * This function is used when entities are created outside of dragon but we need to display their data
 * within the context of a given form.
 */
export function getInlineFormValues({
    configuredFormId,
    instanceIds,
    parentFormPathInstance,
    parentReferencingUiConfigurationId,
}: {
    configuredFormId: number;
    instanceIds: number[];
    parentReferencingUiConfigurationId: string;
    parentFormPathInstance: ParentFormPath;
}): Promise<NonNullable<DragonFormsResource.GetInlineFormReferenceInstances['returns']['data']>> {
    return req<DragonFormsResource.GetInlineFormReferenceInstances>({
        method: 'POST',
        url: 'dragon/forms/inline/get',
        hideLoadingBar: true,
        data: {
            instanceIds,
            formConfigurationId: configuredFormId,
            parentFormPathInstance,
            parentReferencingUiConfigurationId: uiConfigurationIdToNumber(
                parentReferencingUiConfigurationId
            ),
        },
    });
}

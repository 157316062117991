import React from 'react';

import strings from '~/client-common/core/strings/componentStrings';

import Row from '../../../core/components/Row';
import testIds from '../../../../core/testIds';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { IndentedRow, RowTopMargin } from './RecordPrivacyRow';
import { NarrativeContainer } from './RecordPrivacyContainers';
import PreviouslySealedNote from './PreviouslySealedNote';

const ReportSealingFormFields = ({
    forceEnabledNarrativeSealing,
    report,
    formModelByPath,
    onReportSealClick,
    onNarrativeSealClick,
}) => (
    <div>
        <Row>
            <RRFCheckbox
                disabled={
                    !forceEnabledNarrativeSealing &&
                    (report.isNarrativeSealed || formModelByPath('isSealReport'))
                }
                path="isSealNarrative"
                onChange={onNarrativeSealClick}
            />
        </Row>
        <IndentedRow>
            <RRFUserSelect
                disabled={!forceEnabledNarrativeSealing && report.isNarrativeSealed}
                path="userToContactId"
            />
        </IndentedRow>
        {!report.isNarrativeSealed ? (
            <NarrativeContainer
                disabled={formModelByPath('isSealNarrative')}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: report.narrative }}
            />
        ) : (
            <PreviouslySealedNote />
        )}
        <RowTopMargin>
            <RRFCheckbox
                onChange={onReportSealClick}
                path="isSealReport"
                // adding label and help text statically because the field we would
                // use for `isSealReport` has a different label and we don't want
                // to create a display-only field here since this field
                // will not be configurable in the forseeable future.
                // once it needs to be configurable we can easily change it to use
                // a display-only field instead.
                label={strings.recordPrivacy.shared.ReportSealingFormFields.isSealReport.label}
                helpText={
                    strings.recordPrivacy.shared.ReportSealingFormFields.isSealReport.helpText
                }
                testId={testIds.SEAL_CONTENTS_BUTTON}
            />
        </RowTopMargin>
    </div>
);

export default ReportSealingFormFields;

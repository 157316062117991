import { DepartmentSequenceKey, EntityTypeEnumType } from '@mark43/rms-api';
import { find } from 'lodash';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'idGenerator';

const idGeneratorModule = createNormalizedModule<DepartmentSequenceKey>({
    type: NEXUS_STATE_PROP,
});

export const idGeneratorFormatsSelector = idGeneratorModule.selectors.entitiesSelector;
export const idGeneratorFormatsByEntityTypeSelector = createSelector(
    idGeneratorFormatsSelector,
    (idGeneratorFormats) => (entityType: EntityTypeEnumType) =>
        find(idGeneratorFormats, { entityType })
);

export default idGeneratorModule.reducerConfig;

import mapValues from 'lodash/mapValues';
import filter from 'lodash/filter';
import type { ObjectIterateeCustom } from 'lodash';
import { createSelector } from 'reselect';

import {
    ViewModel,
    buildViewModel,
    buildAllMiniUserFormatsMapper,
} from '../../../../../helpers/viewModelHelpers';
import { ModuleShape } from '../../../../utils/createNormalizedModule';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';
import { allMiniUserFormats } from '../../../mini-users/utils/miniUsersHelpers';

import { EmailExportRelease, emailExportReleasesSelector } from '../data';

export type MiniUser = ReturnType<typeof allMiniUserFormats>;

type EmailExportReleaseViewModelProperties = {
    type: 'EMAIL';
    fullInternalUsers: MiniUser[];
    releasedByUserId: MiniUser;
};

export type EmailExportReleaseViewModel = ViewModel<
    EmailExportRelease,
    EmailExportReleaseViewModelProperties
>;

const emailExportReleaseViewModelsSelector = createSelector(
    emailExportReleasesSelector,
    allMiniUserFormatsByIdSelector,
    (emailExportReleases, allMiniUserFormatsById) => {
        const viewModel = buildViewModel<EmailExportRelease, EmailExportReleaseViewModelProperties>(
            {
                mappers: [
                    buildAllMiniUserFormatsMapper(),
                    ({ internalUsers }) => ({
                        fullInternalUsers: internalUsers.map(allMiniUserFormatsById),
                        type: 'EMAIL',
                    }),
                ],
                helpers: {
                    allMiniUserFormatsById,
                },
            }
        );
        return mapValues(emailExportReleases, viewModel);
    }
);

export const emailExportReleaseViewModelsWhereSelector = createSelector(
    emailExportReleaseViewModelsSelector,
    (emailExportReleaseViewModels) => (
        predicate: ObjectIterateeCustom<ModuleShape<EmailExportReleaseViewModel>, boolean>
    ) => filter(emailExportReleaseViewModels, predicate)
);

import { compact, map } from 'lodash';
import { Field, FormatFieldByName } from '../fields/state/config';

// The regex for finding a templated variable in a string
const fieldNameInTemplateRegex = /{{(\s*[\w\.]+\s*)}}/;
const fieldNameAndSelectorTemplateRegex = /{{(\s*[^|}]+\s*)\|(\s*[^|}]+\s*)}}/;
const fieldNameWithTemplateRegex = /({{\s*[^}]+\s*}})/;

// Templating takes the form of "Error  with {{FIELD_NAME}}"
// Also handles errors with a "linkDisplay" and "message" prop
// could be refactored to allow any type of variable to be passed in
export function interpolateErrorMessage(
    initialErrorMessage: string | { linkDisplay: string; message: string },
    formatFieldByName: FormatFieldByName
): string {
    let errorMessage = initialErrorMessage;
    if (typeof errorMessage === 'string') {
        let match = fieldNameInTemplateRegex.exec(errorMessage);

        while (match) {
            errorMessage = errorMessage.replace(match[0], formatFieldByName(match[1] as Field));
            match = fieldNameInTemplateRegex.exec(errorMessage);
        }
        return errorMessage;
    }
    return `${errorMessage.linkDisplay}: ${errorMessage.message}`;
}

/**
 * Templating takes the form of the strings below, and returns an array of interpolated values that may include JSX
 * elements based on mapper().
 *
 * Usage 1: "Error with {{FIELD_NAME}}"
 * interpolateMessage(
 *   'Error with {{FIELD_NAME}}',
 *   (fieldName, i) => <CustomLink>{formatFieldByName(fieldName)}</CustomLink>
 * );
 * Returns: ['Error with ', <CustomLink>Field display name</CustomLink>]
 *
 * Usage 2: "Error with {{FIELD_NAME|SELECTOR}}"
 * interpolateMessage(
 *   'Error with {{FIELD_NAME|SELECTOR}}',
 *   (fieldName, i, selector) => <CustomLink onClick={() => scroll(selector)}>{fieldName}</CustomLink>
 * );
 */
export function interpolateMessage(
    rawErrorMessage: string,
    mapper: (field: string, index: number, selector?: string) => string | JSX.Element
): (string | JSX.Element)[] {
    const parts = compact(rawErrorMessage.split(fieldNameWithTemplateRegex));

    return map(parts, (part, i) => {
        if (fieldNameWithTemplateRegex.test(part)) {
            const matches = fieldNameAndSelectorTemplateRegex.exec(part);
            if (matches) {
                // Usage 2
                return mapper(matches[1], i, matches[2]);
            } else {
                // Usage 1
                return mapper(trimBraces(part), i);
            }
        } else {
            // No interpolation
            return part;
        }
    });
}

function trimBraces(tplMatch: string): string {
    const match = fieldNameInTemplateRegex.exec(tplMatch);
    if (match) {
        return match[1];
    }
    return tplMatch;
}

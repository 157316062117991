import { NameMoniker } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'nameMonikers';
const nameMonikersModule = createGroupedModule<NameMoniker>({
    type: NEXUS_STATE_PROP,
    key: 'nameId',
});

// SELECTORS
export const nameMonikersSelector = nameMonikersModule.selectors.entitiesSelector;
export const nameMonikersByNameIdSelector = nameMonikersModule.selectors.entitiesByKeySelector;

// REDUCER
export default nameMonikersModule.reducerConfig;

import { withProps } from 'recompose';
import classNames from 'classnames';

import FieldsetCommon, {
    CollapsibleFieldset as CollapsibleFieldsetCommon,
} from '~/client-common/components/forms/Fieldset';
import ExpandCollapseButton from '../../../../legacy-redux/components/core/ExpandCollapseButton';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { arbiterMFTInput } from '../../arbiter';

const { connectRRFFieldset } = reactReduxFormHelpers;

const fieldsetStyles = {
    wrapper: 'mark43-fieldset',
    focused: 'focused',
    header: 'mark43-fieldset-header',
};

const Fieldset = withProps(({ noMargins = false, className }) => ({
    styles: fieldsetStyles,
    className: classNames(className, { 'no-margins': noMargins }),
}))(FieldsetCommon);

/**
 * A group of form fields. This component adds additional functionality to the
 *   native `<fieldset>` element. The default behavior is the entire fieldset
 *   gets highlighted when any input inside it is focused.
 * @param {Object}   props Other props get spread into the `<fieldset>`.
 * @param {string}   [props.title] Optional title to appear in a header.
 * @param {boolean}  [props.showHeader=true] Set to `false` to hide the header
 *   even with the title and/or delete button are provided.
 * @param {boolean}  [props.noMargins=false] Set to `false` to hide any
 *   additional margins created by Fieldset.
 * @param {ReactElement} [props.deleteButton] Optional delete button to include
 *   in the header.
 * @param {boolean}  [props.highlightOnFocus=true] When any input inside this
 *   fieldset is focused, the whole fieldset gets highlighted to show the user
 *   where they are in the form.
 * @param {string}   [props.visible=true] Set to `false` to hide this fieldset.
 * @param {function} [props.onInputChange] Handler to call whenver an input
 *   inside this fieldset has its value changed.
 */
export default Fieldset;

const collapsibleFieldsetStyles = {
    collapsible: 'collapsible',
    ...fieldsetStyles,
};

const CollapsibleFieldset = withProps(() => ({
    styles: collapsibleFieldsetStyles,
    ExpandCollapseButton,
}))(CollapsibleFieldsetCommon);

/**
 * A `<Fieldset>` that is collapsible.
 * @param {Object}       props                  Other props get spread into the
 *   parent `<Fieldset>`.
 * @param {function}     props.children         `(expandCollapseButton,
 *   collapsed, setCollapsed) => ReactElement`, the children to be rendered in
 *   the collapsed state.
 * @param {ReactElement} props.expandedChildren The additional children to be
 *   rendered after `props.children` in the expanded state.
 */
export { CollapsibleFieldset };

/**
 * Fieldset to be used inside a `<ReactReduxForm>`.
 * @param {Object} props      Props get spread into `<Fieldset>`.
 * @param {string} props.path Relative path to the form model in state.
 */
export const RRFFieldset = connectRRFFieldset(Fieldset);
export const ArbiterMFTFieldset = arbiterMFTInput(Fieldset);

/**
 * Collapsible fieldset to be used inside a `<ReactReduxForm>`.
 * @param {Object} props      Props get spread into `<CollapsibleFieldset>`.
 * @param {string} props.path Relative path to the form model in state.
 */
export const RRFCollapsibleFieldset = connectRRFFieldset(CollapsibleFieldset);

import { WarrantCharge } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getWarrantResource from '../../../warrants/resources/warrantsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const NEXUS_STATE_PROP = 'warrantCharges';

const warrantChargesModule = createNormalizedModule<WarrantCharge>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// ACTIONS
const storeWarrantCharges = warrantChargesModule.actionCreators.storeEntities;

const removeWarrantChargesWhere = warrantChargesModule.actionCreators.deleteEntitiesWhere;

// SELECTORS

export const warrantChargesWhereSelector = warrantChargesModule.selectors.entitiesWhereSelector;

export function upsertWarrantCharges(
    warrantCharges: WarrantCharge[]
): ClientCommonAction<Promise<WarrantCharge[]>> {
    const warrantResource = getWarrantResource();
    return function (dispatch) {
        return warrantResource
            .upsertWarrantCharges(warrantCharges)
            .then((updatedWarrantCharges: WarrantCharge[]) => {
                dispatch(storeWarrantCharges(updatedWarrantCharges));
                return updatedWarrantCharges;
            });
    };
}

export function deleteWarrantCharge(warrantChargeId: number): ClientCommonAction<Promise<void>> {
    const warrantResource = getWarrantResource();
    return function (dispatch) {
        return warrantResource.deleteWarrantCharge(warrantChargeId).then((success: boolean) => {
            if (success) {
                dispatch(removeWarrantChargesWhere({ id: warrantChargeId }));
            } else {
                throw new Error('Failed to remove charge.');
            }
        });
    };
}

// REDUCER
export default warrantChargesModule.reducerConfig;

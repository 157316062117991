import factory from './actionTypesFactory';

export default factory('locations', [
    'SELECT_LOCATION_START',
    'SELECT_LOCATION_SUCCESS',
    'SELECT_LOCATION_FAILURE',
    'DOWNLOAD_LOCATION_STREET_CENTERLINE_START',
    'DOWNLOAD_LOCATION_STREET_CENTERLINE_SUCCESS',
    'DOWNLOAD_LOCATION_STREET_CENTERLINE_FAILURE',
    'GET_LOCATION_STREET_CENTERLINE_MAPPING_FAILURE',
]);

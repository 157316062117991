import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const EVIDENCE_API_URL = '/evidence/api';

export default createResource({
    name: 'Chain of custody resource',
    methods: {
        /**
         * Get the item profile chain of custodies.
         * @param idsToMerge
         * @returns {Promise}
         */
        getChainOfCustody(idsToMerge) {
            return req({
                method: 'POST',
                baseUrl: EVIDENCE_API_URL,
                url: 'chain_of_custody/items/batch_ids',
                data: idsToMerge,
            });
        },
    },
});

import getChainEventsResource from '~/client-common/core/domain/chain-events/resources/chainEventsResource';
import { NEXUS_STATE_PROP as CHAIN_OF_CUSTODIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/chain-of-custodies/state/data';
import { NEXUS_STATE_PROP as CHAIN_EVENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/chain-events/state/data';
import { NEXUS_STATE_PROP as ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-evidence-states/state/data';

const DELETE_CHAIN_OF_CUSTODY_EVENT_FAILURE =
    'chain-of-custody/DELETE_CHAIN_OF_CUSTODY_EVENT_FAILURE';

const deleteChainOfCustodyEventFailure = () => ({ type: DELETE_CHAIN_OF_CUSTODY_EVENT_FAILURE });

export const deleteChainOfCustodyEvent = (eventId, reasonForDeletion) => (
    dispatch,
    getState,
    dependencies
) => {
    const chainOfCustodyResource = getChainEventsResource();
    return chainOfCustodyResource
        .deleteChainEventById(eventId, reasonForDeletion)
        .then((hydratedItem) => {
            const entitiesToStore = {
                [CHAIN_EVENTS_NEXUS_STATE_PROP]: hydratedItem.chainEvents,
                [CHAIN_OF_CUSTODIES_NEXUS_STATE_PROP]: hydratedItem.chainOfCustodies,
                [ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP]: hydratedItem.itemEvidenceStates,
            };
            const removeCoCEvent = dependencies.nexus.withRemove(
                CHAIN_EVENTS_NEXUS_STATE_PROP,
                { id: eventId },
                { type: 'chain-of-custody/DELETE_CHAIN_OF_CUSTODY_EVENT' }
            );
            dispatch(dependencies.nexus.withEntityItems(entitiesToStore, removeCoCEvent));
        })
        .catch((err) => {
            dispatch(deleteChainOfCustodyEventFailure(err.message));
            throw err;
        });
};

import Promise from 'bluebird';
import { ItemFacilityLink } from '@mark43/evidence-api';
import { get, map } from 'lodash';
import getItemFacilityLinksResource from '../../resources/itemFacilityLinksResource';
import createLinkModule from '../../../../utils/createLinkModule';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'itemFacilityLinks';

const REPLACE_ITEM_FACILITY_LINKS_START = 'item-facility-links/REPLACE_ITEM_FACILITY_LINKS_START';
const REPLACE_ITEM_FACILITY_LINKS_SUCCESS =
    'item-facility-links/REPLACE_ITEM_FACILITY_LINKS_SUCCESS';
const REPLACE_ITEM_FACILITY_LINKS_FAILURE =
    'item-facility-links/REPLACE_ITEM_FACILITY_LINKS_FAILURE';

const UPSERT_ITEM_FACILITY_LINKS_START = 'item-facility-links/UPSERT_ITEM_FACILITY_LINKS_START';
const UPSERT_ITEM_FACILITY_LINKS_SUCCESS = 'item-facility-links/UPSERT_ITEM_FACILITY_LINKS_SUCCESS';
const UPSERT_ITEM_FACILITY_LINKS_FAILURE = 'item-facility-links/UPSERT_ITEM_FACILITY_LINKS_FAILURE';

const DELETE_ITEM_FACILITY_LINK_START = 'item-facility-links/DELETE_ITEM_FACILITY_LINK_START';
const DELETE_ITEM_FACILITY_LINK_SUCCESS = 'item-facility-links/DELETE_ITEM_FACILITY_LINK_SUCCESS';
const DELETE_ITEM_FACILITY_LINK_FAILURE = 'item-facility-links/DELETE_ITEM_FACILITY_LINK_FAILURE';

function replaceItemFacilityLinksStart() {
    return {
        type: REPLACE_ITEM_FACILITY_LINKS_START,
    };
}

function replaceItemFacilityLinksFailure(message: string) {
    return {
        type: REPLACE_ITEM_FACILITY_LINKS_FAILURE,
        payload: message,
    };
}

function deleteItemFacilityLinkStart() {
    return {
        type: DELETE_ITEM_FACILITY_LINK_START,
    };
}

function deleteItemFacilityLinkSuccess() {
    return {
        type: DELETE_ITEM_FACILITY_LINK_SUCCESS,
    };
}

function deleteItemFacilityLinkFailure(message: string) {
    return {
        type: DELETE_ITEM_FACILITY_LINK_FAILURE,
        payload: message,
    };
}

function upsertItemFacilityLinksStart() {
    return {
        type: UPSERT_ITEM_FACILITY_LINKS_START,
    };
}

function upsertItemFacilityLinksSuccess() {
    return {
        type: UPSERT_ITEM_FACILITY_LINKS_SUCCESS,
    };
}

function upsertItemFacilityLinksFailure(message: string) {
    return {
        type: UPSERT_ITEM_FACILITY_LINKS_FAILURE,
        payload: message,
    };
}

const itemFacilityLinksModule = createLinkModule<ItemFacilityLink>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

export function replaceItemFacilityLinks(
    itemFacilityLinks: ItemFacilityLink[]
): ClientCommonAction<Promise<ItemFacilityLink[]>> {
    return (dispatch, getState, dependencies) => {
        dispatch(replaceItemFacilityLinksStart());
        const resource = getItemFacilityLinksResource();

        return resource
            .replaceItemFacilityLinks(itemFacilityLinks)
            .then((newItemFacilityLinks: ItemFacilityLink[]) => {
                const removeItemFacilityLinks = dependencies.nexus.withRemove(
                    NEXUS_STATE_PROP,
                    { itemProfileId: get(newItemFacilityLinks, '[0].itemProfileId') },
                    { type: REPLACE_ITEM_FACILITY_LINKS_SUCCESS }
                );

                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: newItemFacilityLinks,
                        },
                        removeItemFacilityLinks
                    )
                );
                return newItemFacilityLinks;
            })
            .catch((err: Error) => {
                dispatch(replaceItemFacilityLinksFailure(err.message));
                throw err;
            });
    };
}

export function upsertItemFacilityLinks(
    itemFacilityLinks: ItemFacilityLink[]
): ClientCommonAction<Promise<ItemFacilityLink[]>> {
    return (dispatch) => {
        dispatch(upsertItemFacilityLinksStart());
        const resource = getItemFacilityLinksResource();

        return resource
            .upsertItemFacilityLinks(itemFacilityLinks)
            .then((newItemFacilityLinks: ItemFacilityLink[]) => {
                dispatch(storeItemFacilityLinks(newItemFacilityLinks));
                dispatch(upsertItemFacilityLinksSuccess());
                return newItemFacilityLinks;
            })
            .catch((err: Error) => {
                dispatch(upsertItemFacilityLinksFailure(err.message));
                throw err;
            });
    };
}

function deleteItemFacilityLink(id: number): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        dispatch(deleteItemFacilityLinkStart());
        const resource = getItemFacilityLinksResource();

        return resource
            .deleteItemFacilityLink(id)
            .then(() => {
                dispatch(deleteItemFacilityLinkSuccess());
                dispatch(removeItemFacilityLinkWhere({ id }));
            })
            .catch((err: Error) => {
                dispatch(deleteItemFacilityLinkFailure(err.message));
                throw err;
            });
    };
}

export function deleteItemFacilityLinks(
    itemFacilityLinks: ItemFacilityLink[]
): ClientCommonAction<Promise<void[]>> {
    return (dispatch) =>
        Promise.all([...map(itemFacilityLinks, ({ id }) => dispatch(deleteItemFacilityLink(id)))]);
}

const storeItemFacilityLinks = itemFacilityLinksModule.actionCreators.storeLinks;

const removeItemFacilityLinkWhere = itemFacilityLinksModule.actionCreators.removeLinksWhere;

// SELECTORS
export const itemFacilityLinksSelector = itemFacilityLinksModule.selectors.linksSelector;

// REDUCER
export default itemFacilityLinksModule.reducerConfig;

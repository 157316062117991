import React, { FC } from 'react';
import moment from 'moment';
import { Box, cssVar, Text } from 'arc';
import { FormattedDate } from '~/client-common/core/dates/components';
import testIds from '../../../../../../core/testIds';

type CaseCreatedDateCellProps = {
    date: moment.MomentInput;
};

export const CaseCreatedDateCell: FC<CaseCreatedDateCellProps> = ({ date }) => {
    return (
        <Box
            flexDirection="column"
            display="flex"
            gap={cssVar('arc.space.half')}
            data-test-id={testIds.FORMATTED_DATE}
        >
            <Text variant="bodyMd" fontWeight="normal">
                <FormattedDate date={date} format="formDate" />
            </Text>
            <Text color="tertiary" variant="caption" fontWeight="normal">
                <FormattedDate date={date} format="formTime" />
            </Text>
        </Box>
    );
};

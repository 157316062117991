import React from 'react';
import { RefContextEnumType, AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';

import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { SelectOptions, AttributeOption } from '../../helpers/selectHelpers';
import AttributeSelect from './AttributeSelect';

const { connectRRFInput } = reactReduxFormHelpers;

const vacatingCourtCodeSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (options: SelectOptions<AttributeOption>) =>
        filter(
            options,
            ({ value }) =>
                parentAttributeIdByAttributeId(value) === globalAttributes.courtCodeGlobal.vacating
        )
);

const sealingCourtCodeSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (options: SelectOptions<AttributeOption>) =>
        filter(
            options,
            ({ value }) =>
                parentAttributeIdByAttributeId(value) === globalAttributes.courtCodeGlobal.sealing
        )
);

const allCourtCodeSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (options: SelectOptions<AttributeOption>) =>
        filter(
            options,
            ({ value }) =>
                parentAttributeIdByAttributeId(value) ===
                    globalAttributes.courtCodeGlobal.vacating ||
                parentAttributeIdByAttributeId(value) === globalAttributes.courtCodeGlobal.sealing
        )
);

const CourtCodeAttributeSelect: React.FC<{
    currentContext: RefContextEnumType | typeof formClientEnum;
}> = ({ currentContext, ...otherProps }) => {
    const vacatingCourtCodeFilter = useSelector(vacatingCourtCodeSelector);
    const sealingCourtCodeFilter = useSelector(sealingCourtCodeSelector);
    const allCourtCodeFilter = useSelector(allCourtCodeSelector);

    let filterOptions;
    switch (currentContext) {
        case RefContextEnum.FORM_REPORT_SEALING.name:
            filterOptions = sealingCourtCodeFilter;
            break;
        case RefContextEnum.FORM_CHARGE_VACATING.name:
            filterOptions = vacatingCourtCodeFilter;
            break;
        case formClientEnum.ADVANCED_SEARCH_COURT_ORDERS:
            filterOptions = allCourtCodeFilter;
            break;
        default:
            break;
    }

    return (
        <AttributeSelect
            attributeType={AttributeTypeEnum.COURT_CODE.name}
            filterOptions={filterOptions}
            {...otherProps}
        />
    );
};

// @ts-expect-error client-common to client RND-7529
export const RRFCourtCodeAttributeSelect = connectRRFInput(CourtCodeAttributeSelect);

import { useCallback, useState, useEffect } from 'react';
import { Vehicle } from '@mark43/rms-api';
import { useDispatch } from 'react-redux';
import { isUndefined, omitBy } from 'lodash';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { RmsDispatch } from '../../../../../../core/typings/redux';
import { mapDexItemsToRmsVehicles, DexItemType } from '../helpers';

export const useMapDexItemsToRmsVehicles = (dexItems: DexItemType[]) => {
    const dispatch = useDispatch<RmsDispatch>();

    const [rmsVehicles, setRmsVehicles] = useState<Partial<Vehicle>[]>([]);

    const mapDexItemsToRmsVehiclesMemoized = useCallback(
        () => dispatch(mapDexItemsToRmsVehicles(dexItems)),
        [dispatch, dexItems]
    );

    const onResourceSuccess = useCallback((result: Partial<Vehicle>[]) => {
        setRmsVehicles(result.map((vehicle) => omitBy(vehicle, isUndefined)));
    }, []);

    const { loading, callResource } = useResourceDeferred<Partial<Vehicle>[]>(
        mapDexItemsToRmsVehiclesMemoized,
        onResourceSuccess
    );

    useEffect(() => {
        callResource();
    }, [callResource]);

    return {
        loading: loading.isLoading,
        error: loading.errorMessage,
        rmsVehicles,
    };
};

import { reduce, map, compact, values, some, trim } from 'lodash';
import { FullOffenseCodeWording, OffenseCodeWordingFieldValue } from '@mark43/rms-api';
import { prettify } from '~/client-common/helpers/stringHelpers';

const MAD_LIBS_PARAMETER_REG_EXP = /\{\{[^{}]+\}\}/g;
export const MAD_LIBS_FIELD_SPLITTER_REG_EXP = /({{[\s*\w?:\s*]+}})/gi;
const SANITIZE_PARAMETER_REG_EXP = /\{\{|\}\}/g;

export type Field = {
    id: string;
    label: string;
};

export const sanitizeParameterForDisplay = (parameterKey: string) => {
    const sanitizedParameter = parameterKey.replace(SANITIZE_PARAMETER_REG_EXP, '');
    const paramAndDataType = sanitizedParameter.split(':');
    return `{{ ${trim(paramAndDataType[0])} }}`;
};

export const extractFieldsFromTemplate: (template: string) => Field[] = (template: string) => {
    const fields: Field[] = [];

    const matches = template.match(MAD_LIBS_PARAMETER_REG_EXP) ?? [];

    matches.forEach((parameter: string) => {
        const field = fields.find((item) => item.id === parameter);
        if (!field) {
            fields.push({
                id: parameter,
                label: sanitizeParameterKey(parameter),
            });
        }
    });

    return fields;
};

export const sanitizeParameterKey = (parameterKey: string) => {
    return parameterKey.replace(SANITIZE_PARAMETER_REG_EXP, '').trim();
};

export const validateMadLibsParameters = (
    templateString: string,
    fieldValues: Partial<OffenseCodeWordingFieldValue>[]
) => {
    const fieldsFromTemplates = extractFieldsFromTemplate(templateString);
    const enteredData = map(compact(values(fieldValues)), 'fieldValue');
    const hasSomeEmptyData = some(enteredData, (item) => !item);

    if (hasSomeEmptyData) {
        return false;
    }

    return enteredData.length === fieldsFromTemplates.length;
};

export const convertOffenseWordingFieldValuesArrayToMap = (
    fieldValues: FullOffenseCodeWording['offenseCodeWordingFieldValues']
): { [key: string]: string } => {
    const result = reduce(
        fieldValues,
        (acc, fieldValue) => {
            return {
                ...acc,
                [fieldValue.fieldKey]: fieldValue.fieldValue,
            };
        },
        {}
    );
    return result;
};

export const fieldTypes = {
    string: 'string',
    date: 'date',
    dateTime: 'dateTime',
} as const;

export type FieldTypesT = keyof typeof fieldTypes;

export const getFieldType = (parameterKey: string): FieldTypesT => {
    const params = parameterKey.split(':');
    if (params.length < 2) {
        return fieldTypes.string;
    }
    const type = params[1];
    switch (type) {
        case 'date':
            return fieldTypes.date;
        case 'dateTime':
            return fieldTypes.dateTime;
        default:
            return fieldTypes.string;
    }
};

export const isHandlebarHelper = (input: string) => {
    return input.includes('{{') && input.includes('}}');
};

export const formatFieldLabel = (label: string) => {
    const labelParts = label.split(':');
    const fieldLabel = labelParts[0];
    return prettify(
        fieldLabel
            .split(/(?=[A-Z])/)
            .map((s) => s.toLowerCase())
            .join(' ')
    );
};

import { StopEntityAttribute } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'stopEntityAttributes';

const stopEntityAttributesLinksModule = createLinkModule<StopEntityAttribute>({
    type: NEXUS_STATE_PROP,
    keys: ['stopId', 'entityId', 'attributeId', 'isPerson', 'mapToAttributeId'],
});

export const stopEntityAttributesWhereSelector =
    stopEntityAttributesLinksModule.selectors.linksWhereSelector;

export default stopEntityAttributesLinksModule.reducerConfig;

import React from 'react';
import { pure } from 'recompose';

import Fieldset from '../../../core/forms/components/Fieldset';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFNItems } from '../../../core/forms/components/NItems';

import { clothingFieldsetViewModel } from '../../../../legacy-redux/configs/fieldsetsConfig';

const { title } = clothingFieldsetViewModel;

/**
 * This fieldset for clothing items has a default title and a default className.
 * @param {Object} props
 * @param {string} [props.path='clothing'] Form model state path.
 * @param {string} [props.className='light']
 */
export default pure(function ClothingFieldset({
    path = 'clothing',
    className = 'light',
    ...otherProps
}) {
    return (
        <Fieldset title={title} className={className} {...otherProps}>
            <RRFNItems path={path} addItemOnDirty={true}>
                {() => [
                    <RRFAttributeSelect
                        key={0}
                        attributeType="CLOTHING_TYPE"
                        includeExpired={true}
                        length="md"
                        path="clothingTypeAttrId"
                    />,
                    <RRFText key={1} length="lg" path="description" />,
                ]}
            </RRFNItems>
        </Fieldset>
    );
});

import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Tooltip } from '../../../core/components/tooltip';
import Icon, { iconTypes } from '../../../core/components/Icon';
import testIds from '../../../../core/testIds';

const strings = componentStrings.attachments.core.attachmentViewToggleButton;

export const attachmentViews = {
    LIST: 'LIST',
    GRID: 'GRID',
};

const ViewButton = styled.div`
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

export default function AttachmentViewToggleButton({ currentView, onClick }) {
    return (
        <Tooltip
            side="bottom"
            content={
                currentView === attachmentViews.LIST ? (
                    <div>{strings.gridView}</div>
                ) : (
                    <div>{strings.listView}</div>
                )
            }
            mouseEnterDelay={1}
        >
            <ViewButton onClick={onClick} data-test-id={testIds.ATTACHMENT_TOGGLE_VIEW_BUTTON}>
                {currentView === attachmentViews.LIST ? (
                    <Icon type={iconTypes.GRID_VIEW} color="cobaltBlue" />
                ) : (
                    <Icon type={iconTypes.LIST_VIEW} color="cobaltBlue" />
                )}
            </ViewButton>
        </Tooltip>
    );
}

import { createSelector } from 'reselect';
import { Caution, EntityTypeEnumType } from '@mark43/rms-api';

import createNormalizedModule from '~/client-common/core/utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'cautions';

const cautionsModule = createNormalizedModule<Caution>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

const cautionsWhereSelector = cautionsModule.selectors.entitiesWhereSelector;

export const cautionsByEntitySelector = createSelector(
    cautionsWhereSelector,
    (cautionsWhere) => (entityType: EntityTypeEnumType, entityId: number) =>
        cautionsWhere({ entityType, entityId })
);

export default cautionsModule.reducerConfig;

import { storeHistoryEventsWithEntityId } from '~/client-common/core/domain/history-events/state/data';
import caseResource from '../../resources/caseResource';

const LOAD_CASE_HISTORY_START = 'case-history/LOAD_CASE_HISTORY_START';
const LOAD_CASE_HISTORY_SUCCESS = 'case-history/LOAD_CASE_HISTORY_SUCCESS';
const LOAD_CASE_HISTORY_FAILURE = 'case-history/LOAD_CASE_HISTORY_FAILURE';

function loadCaseHistoryStart() {
    return {
        type: LOAD_CASE_HISTORY_START,
    };
}
function loadCaseHistorySuccess() {
    return {
        type: LOAD_CASE_HISTORY_SUCCESS,
    };
}
function loadCaseHistoryFailure(errorMessage) {
    return {
        type: LOAD_CASE_HISTORY_FAILURE,
        payload: errorMessage,
    };
}

export function loadCaseHistory(caseId) {
    return function (dispatch) {
        dispatch(loadCaseHistoryStart());
        return caseResource
            .getCaseHistory(caseId)
            .then((historyEvents) => {
                dispatch(storeHistoryEventsWithEntityId(historyEvents, caseId));
                dispatch(loadCaseHistorySuccess());
                return historyEvents;
            })
            .catch((err) => {
                dispatch(loadCaseHistoryFailure(err.message));
                throw err;
            });
    };
}

import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import testIds from '../../../../core/testIds';
import SearchActionBar from './SearchActionBar';

const strings = componentStrings.search.SearchExportBar;

export default function SearchExportBar({
    allResultsSelected = false,
    selectedResultCount = 0,
    pageTotalResultCount = 0,
    totalResultCount = 0,
    onClickExport,
    onClickSelectAll,
    onClickClearSelection,
    exportDisabled,
    allowSelectAll = true,
}) {
    return (
        <SearchActionBar>
            <Button
                className={`${buttonTypes.SECONDARY_BOLD} export-bar-button`}
                iconLeft={<Icon type={iconTypes.FILE} />}
                disabled={exportDisabled}
                onClick={onClickExport}
                testId={testIds.ADVANCED_SEARCH_EXPORT_BUTTON}
            >
                Export
            </Button>
            <span className="export-bar-text">
                {allResultsSelected || selectedResultCount === totalResultCount
                    ? strings.allSelected
                    : selectedResultCount === pageTotalResultCount
                    ? strings.allOnPageSelected(selectedResultCount)
                    : strings.someOnPageSelected(selectedResultCount)}
            </span>
            {allResultsSelected || selectedResultCount === totalResultCount ? (
                <span
                    className="export-bar-text export-bar-action"
                    onClick={onClickClearSelection}
                    data-test-id={testIds.ADVANCED_SEARCH__DASHBOARD_ACTION_BAR_CLEAR_ALL_RESULTS}
                >
                    {strings.clearSelection}
                </span>
            ) : allowSelectAll ? (
                <span
                    className="export-bar-text export-bar-action"
                    onClick={onClickSelectAll}
                    data-test-id={testIds.ADVANCED_SEARCH__DASHBOARD_ACTION_BAR_SELECT_ALL_RESULTS}
                >
                    {strings.selectAll}
                </span>
            ) : null}
        </SearchActionBar>
    );
}

import { flatMap, map, orderBy, chain, first } from 'lodash';
import {
    CreateDispositionEventView,
    ComputedDispositionState,
    DispositionEvent,
    DispositionEventUserView,
    ItemEvidenceState,
    DispositionEventTypeEnum,
} from '@mark43/evidence-api';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { latestChainOfCustodyIdForMasterItemIdSelector } from '../../../chain-of-custodies/state/data';
import {
    storeItemEvidenceStates,
    NEXUS_STATE_PROP as ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP,
} from '../../../item-evidence-states/state/data';
import { NEXUS_STATE_PROP as RETENTION_POLICIES_NEXUS_STATE_PROP } from '../../../retention-policies/state/data';
import getDispositionEventsResource from '../../resources/dispositionEventsResource';
import { mergeDispositionEventAndState } from '../../utils/dispositionEventsHelpers';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'dispositionEvents';

/**
 * See `mergeDispositionEventAndState`.
 */
interface ComputedDispositionEvent extends DispositionEvent {
    dispositionState?: Pick<
        ComputedDispositionState,
        'dispositionApprovalLevel' | 'dispositionStatus' | 'expirationDateUtc' | 'retentionPolicyId'
    >;
}

const dispositionEventsModule = createNormalizedModule<ComputedDispositionEvent>({
    type: NEXUS_STATE_PROP,
});

// ACTION TYPES
const REQUEST_DISPOSITIONS_START = 'disposition-events/REQUEST_DISPOSITIONS_START';
const REQUEST_DISPOSITIONS_SUCCESS = 'disposition-events/REQUEST_DISPOSITIONS_SUCCESS';
const REQUEST_DISPOSITIONS_FAILURE = 'disposition-events/REQUEST_DISPOSITIONS_FAILURE';
const REVIEW_DISPOSITION_START = 'disposition-events/REVIEW_DISPOSITION_START';
const REVIEW_DISPOSITION_SUCCESS = 'disposition-events/REVIEW_DISPOSITION_SUCCESS';
const REVIEW_DISPOSITION_FAILURE = 'disposition-events/REVIEW_DISPOSITION_FAILURE';
const REVIEW_DISPOSITIONS_START = 'disposition-events/REVIEW_DISPOSITIONS_START';
const REVIEW_DISPOSITIONS_SUCCESS = 'disposition-events/REVIEW_DISPOSITIONS_SUCCESS';
const REVIEW_DISPOSITIONS_FAILURE = 'disposition-events/REVIEW_DISPOSITIONS_FAILURE';
const RESET_DISPOSITIONS_START = 'disposition-events/RESET_DISPOSITIONS_START';
const RESET_DISPOSITIONS_SUCCESS = 'disposition-events/RESET_DISPOSITIONS_SUCCESS';
const RESET_DISPOSITIONS_FAILURE = 'disposition-events/RESET_DISPOSITIONS_FAILURE';

// ACTIONS
const storeDispositionEvents = dispositionEventsModule.actionCreators.storeEntities;

function resetDispositionsStart(masterItemIds: number[]) {
    return {
        type: RESET_DISPOSITIONS_START,
        payload: masterItemIds,
    };
}

function resetDispositionsFailure(errorMessage: string) {
    return {
        type: RESET_DISPOSITIONS_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

// these actions are used by both the single and multiple action creators below
function requestDispositionsStart(masterItemIds: number[]) {
    return {
        type: REQUEST_DISPOSITIONS_START,
        payload: masterItemIds,
    };
}

function requestDispositionsFailure(errorMessage: string) {
    return {
        type: REQUEST_DISPOSITIONS_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

export function resetDispositions(
    masterItemIds: number[],
    dispositionEvents: Partial<CreateDispositionEventView>[]
): ClientCommonAction<Promise<DispositionEventUserView[]>> {
    const dispositionEventsResource = getDispositionEventsResource();

    return function (dispatch, getState, { nexus: { withEntityItems } }) {
        dispatch(resetDispositionsStart(masterItemIds));

        const state = getState();

        const payload = map(masterItemIds, (masterItemId, index) => ({
            ...dispositionEvents[index],
            chainOfCustodyId: latestChainOfCustodyIdForMasterItemIdSelector(state)(masterItemId),
        }));

        return dispositionEventsResource
            .resetDispositions(payload)
            .then((data: DispositionEventUserView[]) => {
                const entitiesToStore = {
                    [ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP]: flatMap(data, 'itemEvidenceState'),
                    [NEXUS_STATE_PROP]: mergeDispositionEventAndState(
                        flatMap(data, 'dispositionEvent'),
                        flatMap(data, 'itemEvidenceState')
                    ),
                    [RETENTION_POLICIES_NEXUS_STATE_PROP]: flatMap(data, 'retentionPolicy'),
                };

                dispatch(withEntityItems(entitiesToStore, { type: RESET_DISPOSITIONS_SUCCESS }));

                return dispositionEvents;
            })
            .catch((err: Error) => {
                dispatch(resetDispositionsFailure(err.message));
                throw err;
            });
    };
}

/**
 * Make requests for disposition approval on multiple master items.
 */
export function requestDispositions(
    masterItemIds: number[],
    dispositionEvents: Partial<CreateDispositionEventView>[]
): ClientCommonAction<Promise<DispositionEventUserView[]>> {
    const dispositionEventsResource = getDispositionEventsResource();

    return function (dispatch, getState, { nexus: { withEntityItems } }) {
        dispatch(requestDispositionsStart(masterItemIds));

        const state = getState();
        let payload;

        if (dispositionEvents) {
            payload = map(masterItemIds, (masterItemId, index) => ({
                ...dispositionEvents[index],
                chainOfCustodyId: latestChainOfCustodyIdForMasterItemIdSelector(state)(
                    masterItemId
                ),
            }));
        } else {
            payload = map(masterItemIds, (masterItemId) => ({
                chainOfCustodyId: latestChainOfCustodyIdForMasterItemIdSelector(state)(
                    masterItemId
                ),
                dispositionEventType: DispositionEventTypeEnum.MANUAL_REQUEST.name,
            }));
        }

        return dispositionEventsResource
            .requestDispositions(payload)
            .then((data: DispositionEventUserView[]) => {
                const entitiesToStore = {
                    [ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP]: flatMap(data, 'itemEvidenceState'),
                    [NEXUS_STATE_PROP]: mergeDispositionEventAndState(
                        flatMap(data, 'dispositionEvent'),
                        flatMap(data, 'itemEvidenceState')
                    ),
                    [RETENTION_POLICIES_NEXUS_STATE_PROP]: flatMap(data, 'retentionPolicy'),
                };

                dispatch(withEntityItems(entitiesToStore, { type: REQUEST_DISPOSITIONS_SUCCESS }));

                return dispositionEvents;
            })
            .catch((err: Error) => {
                dispatch(requestDispositionsFailure(err.message));
                throw err;
            });
    };
}

function reviewDispositionStart(masterItemId: number, dispositionEvent: DispositionEvent) {
    return {
        type: REVIEW_DISPOSITION_START,
        payload: { masterItemId, dispositionEvent },
    };
}

function reviewDispositionSuccess({
    dispositionEvent,
    itemEvidenceState,
}: {
    dispositionEvent: DispositionEvent;
    itemEvidenceState: ItemEvidenceState;
}) {
    return {
        type: REVIEW_DISPOSITION_SUCCESS,
        payload: { dispositionEvent, itemEvidenceState },
    };
}

function reviewDispositionFailure(errorMessage: string) {
    return {
        type: REVIEW_DISPOSITION_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

/**
 * Perform a disposition review action on a single master item. This means
 *   making an API request to create a DispositionEvent model with a certain
 *   event type.
 *
 * On `dispositionEvent`, `chainOfCustodyId` is not required on this object as it gets filled in using `masterItemId`.
 */
export function reviewDisposition(
    masterItemId: number,
    dispositionEvent: DispositionEvent
): ClientCommonAction<Promise<DispositionEvent>> {
    const dispositionEventsResource = getDispositionEventsResource();

    return function (dispatch, getState) {
        dispatch(reviewDispositionStart(masterItemId, dispositionEvent));

        const state = getState();
        const masterItemChainOfCustodyId = latestChainOfCustodyIdForMasterItemIdSelector(state)(
            masterItemId
        );

        if (!masterItemChainOfCustodyId) {
            throw new Error('Master item does not contain a chain of custody id, cannot proceed');
        }

        dispositionEvent = {
            ...dispositionEvent,
            chainOfCustodyId: masterItemChainOfCustodyId,
        };

        return dispositionEventsResource
            .reviewDisposition(dispositionEvent)
            .then(({ dispositionEvent, itemEvidenceState }: DispositionEventUserView) => {
                dispatch(
                    reviewDispositionSuccess({
                        dispositionEvent,
                        itemEvidenceState,
                    })
                );
                dispatch(storeDispositionEvents(dispositionEvent));
                dispatch(storeItemEvidenceStates([itemEvidenceState]));
                return dispositionEvent;
            })
            .catch((err: Error) => {
                dispatch(reviewDispositionFailure(err.message));
                throw err;
            });
    };
}

function reviewDispositionsStart(
    masterItemIds: number[],
    dispositionEvents: Partial<CreateDispositionEventView>[]
) {
    return {
        type: REVIEW_DISPOSITIONS_START,
        payload: { masterItemIds, dispositionEvents },
    };
}

function reviewDispositionsFailure(errorMessage: string) {
    return {
        type: REVIEW_DISPOSITIONS_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

export function reviewDispositions(
    masterItemIds: number[],
    dispositionEvents: Partial<CreateDispositionEventView>[]
): ClientCommonAction<Promise<DispositionEventUserView[]>> {
    return (dispatch, getState, { nexus: { withEntityItems } }) => {
        dispatch(reviewDispositionsStart(masterItemIds, dispositionEvents));

        const state = getState();
        const payload = map(masterItemIds, (masterItemId, index) => ({
            ...dispositionEvents[index],
            chainOfCustodyId: latestChainOfCustodyIdForMasterItemIdSelector(state)(masterItemId),
        }));

        const dispositionEventsResource = getDispositionEventsResource();
        return dispositionEventsResource
            .reviewDispositions(payload)
            .then((data: DispositionEventUserView[]) => {
                const entitiesToStore = {
                    [ITEM_EVIDENCE_STATES_NEXUS_STATE_PROP]: flatMap(data, 'itemEvidenceState'),
                    [NEXUS_STATE_PROP]: mergeDispositionEventAndState(
                        flatMap(data, 'dispositionEvent'),
                        flatMap(data, 'itemEvidenceState')
                    ),
                    [RETENTION_POLICIES_NEXUS_STATE_PROP]: flatMap(data, 'retentionPolicy'),
                };

                dispatch(withEntityItems(entitiesToStore, { type: REVIEW_DISPOSITIONS_SUCCESS }));

                return dispositionEvents;
            })
            .catch((err: Error) => {
                dispatch(reviewDispositionsFailure(err.message));
                throw err;
            });
    };
}

/**
 * Manually create a disposition event outside of a normal disposition workflow.
 *   Use this action creator for Mark43 internal testing only.
 */
export function createDispositionEvent(
    dispositionEvent: Partial<DispositionEvent>
): ClientCommonAction<Promise<DispositionEvent>> {
    const dispositionEventsResource = getDispositionEventsResource();

    return function (dispatch) {
        return dispositionEventsResource
            .createDispositionEvents([dispositionEvent])
            .then((dispositionEvents: DispositionEvent[]) => {
                dispatch(storeDispositionEvents(dispositionEvents));
                return dispositionEvents[0];
            });
    };
}

// SELECTORS
export const dispositionEventsSelector = dispositionEventsModule.selectors.entitiesSelector;
const dispositionEventsWhereSelector = dispositionEventsModule.selectors.entitiesWhereSelector;

/**
 * Disposition events for a chain of custody, sorted by event date descending.
 */
export const dispositionEventsByChainOfCustodyIdSelector = createSelector(
    dispositionEventsWhereSelector,
    (dispositionEventsWhere) => (chainOfCustodyId: number) =>
        orderBy(dispositionEventsWhere({ chainOfCustodyId }), 'eventDateUtc', 'desc')
);

export const dispositionEventsByMasterItemIdsSelector = createSelector(
    latestChainOfCustodyIdForMasterItemIdSelector,
    dispositionEventsByChainOfCustodyIdSelector,
    (latestChainOfCustodyIdForMasterItemId, dispositionEventsByChainOfCustodyId) => (
        masterItemIds: number[]
    ) => {
        return chain(masterItemIds)
            .map((masterItemId) => {
                const latestChainOfCustodyId = latestChainOfCustodyIdForMasterItemId(
                    masterItemId
                ) as number;
                return first(dispositionEventsByChainOfCustodyId(latestChainOfCustodyId));
            })
            .compact()
            .value();
    }
);

// REDUCER
/**
 * DispositionEvent models.
 */
export default dispositionEventsModule.reducerConfig;

import { combineReducers } from 'redux';

import advancedSearchActionTypes from '../actions/types/advancedSearchActionTypes';

import advancedSearchReports from '../../modules/search/reports/state/ui';
import advancedSearchCadTickets from '../../modules/search/cad-tickets/state/ui';
import advancedSearchPersons from '../../modules/search/persons/state/ui';
import advancedSearchOrganizations from '../../modules/search/organizations/state/ui';
import advancedSearchVehicles from '../../modules/search/vehicles/state/ui';
import advancedSearchProperty from '../../modules/search/property/state/ui';
import advancedSearchAttachments from '../../modules/search/attachments/state/ui';
import advancedSearchCourtOrders from '../../modules/search/court-orders/state/ui';

export const advancedSearchUiReducer = combineReducers({
    reports: advancedSearchReports.reducers.uiReducer,
    cadTickets: advancedSearchCadTickets.reducers.uiReducer,
    persons: advancedSearchPersons.reducers.uiReducer,
    organizations: advancedSearchOrganizations.reducers.uiReducer,
    vehicles: advancedSearchVehicles.reducers.uiReducer,
    property: advancedSearchProperty.reducers.uiReducer,
    attachments: advancedSearchAttachments.reducers.uiReducer,
    courtOrders: advancedSearchCourtOrders.reducers.uiReducer,
    all: (state = { lastQuery: '' }, action) => {
        switch (action.type) {
            case advancedSearchActionTypes.SEARCH_ALL_SUCCESS:
                return {
                    lastQuery: action.payload,
                };
            default:
                return state;
        }
    },
});

import { ValueTypeEnumType } from '@mark43/rms-api';
import invariant from 'invariant';
import { isValueType } from '../../../../core/utils/type-guards';

const DELIMITER = '~';

/**
 * Serializes the important parts of an option into a string value, to be used during form submissions to
 * determine how to handle an associated value
 */
export function createConfigurdEntityPropertyOptionValue({
    configuredEntityKeyName,
    configuredPropertyKeyName,
    valueType,
}: {
    configuredEntityKeyName: string;
    configuredPropertyKeyName: string;
    valueType: string;
}): string {
    return `${configuredEntityKeyName}${DELIMITER}${configuredPropertyKeyName}${DELIMITER}${valueType}`;
}

/**
 * Parses a given option value into its the originally serialized parts
 */
export function parseConfiguredEntityPropertyOptionValue(input: string): {
    configuredEntityKeyName: string;
    configuredPropertyKeyName: string;
    valueType: ValueTypeEnumType;
} {
    const [configuredEntityKeyName, configuredPropertyKeyName, valueType] = input.split(DELIMITER);

    invariant(
        typeof configuredEntityKeyName === 'string' && !!configuredEntityKeyName.trim(),
        'Unexpectedly did not find configured entity type key name while parsing option value'
    );
    invariant(
        typeof configuredPropertyKeyName === 'string' && !!configuredPropertyKeyName.trim(),
        'Unexpectedly did not find configured entity property key name while parsing option value'
    );
    invariant(
        isValueType(valueType),
        'Unexpectedly did not find valid configured entity property value type while parsing option value'
    );

    return {
        configuredEntityKeyName,
        configuredPropertyKeyName,
        valueType,
    };
}

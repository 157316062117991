import React from 'react';
import styled from 'styled-components';

const DividerWrapper = styled.hr`
    border: 0;
    border-bottom: 2px solid ${(props) => props.theme.colors.extraLightGrey};
    margin: 20px 0;
`;

export const DashedDivider = styled(DividerWrapper)`
    border-bottom: 2px dashed ${(props) => props.theme.colors.lightGrey};
`;

export default function Divider({ className }: { className?: string }) {
    return <DividerWrapper className={className} />;
}

import _ from 'lodash';

// wraps a reducer causing its state to be reset any time the provided
// action type is dispatched. actionType can also be an array of action types
// that should reset the reducer
export function makeResettable(actionType, reducer, resettedState = undefined, mergeStrategy) {
    const handleReset = (state, action) => {
        state = mergeStrategy ? mergeStrategy(resettedState, state) : resettedState;
        return reducer(state, action);
    };
    return (state, action) => {
        if (
            (_.isArray(actionType) && _.includes(actionType, action.type)) ||
            action.type === actionType
        ) {
            return handleReset(state, action);
        } else {
            return reducer(state, action);
        }
    };
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map, includes, difference } from 'lodash';
import { RefContextEnum } from '@mark43/rms-api';
import strings from '~/client-common/core/strings/componentStrings';
import chargeVacatingForm from '../state/forms/chargeVacatingForm';
import { chargeVacatingSubmissionErrorSelector, chargesForReportIdSelector } from '../state/ui';
import { InlineBanner } from '../../../core/components/InlineBanner';

import { ColumnLeft, ColumnRight, ColumnFull } from '../../core/components/RecordPrivacyColumn';
import { FlexContainer, InnerFlexContainer } from '../../core/components/RecordPrivacyContainers';
import { Note, RightColumnNote } from '../../core/components/RecordPrivacyNote';
import { Form } from '../../core/components/Form';
import SubSectionTitle from '../../core/components/SubSectionTitle';
import CourtOrderFormFields from '../../core/components/CourtOrderFormFields';
import ChargeRow from './ChargeRow';

class ChargeVacatingForm extends React.Component {
    handleVacateChange = (charge, isVacated) => {
        const chargeIdsToVacate = this.props.formModelByPath('chargeIdsToVacate') || [];
        const updatedChargeIdsToVacate = isVacated
            ? [...chargeIdsToVacate, charge.id]
            : difference(chargeIdsToVacate, [charge.id]);
        this.props.changePath('chargeIdsToVacate', updatedChargeIdsToVacate);
        this.props.validateForm();
    };
    render() {
        const { reportId, submissionError, chargesForReportId, formModelByPath } = this.props;
        const chargeIdsToVacate = formModelByPath('chargeIdsToVacate');
        const charges = chargesForReportId(reportId);
        return (
            <Form {...chargeVacatingForm}>
                <FlexContainer>
                    {submissionError && (
                        <ColumnFull flexGrow={0}>
                            <InlineBanner status="error">{submissionError}</InlineBanner>
                        </ColumnFull>
                    )}
                    <InnerFlexContainer>
                        <ColumnLeft>
                            <Note>
                                {strings.recordPrivacy.shared.CourtOrderFormFields.enterInformation}
                            </Note>
                            <CourtOrderFormFields
                                changePath={this.props.changePath}
                                formModelByPath={formModelByPath}
                                showPersonRelatedFields={false}
                                showArrestIdField={true}
                                showStateIdField={true}
                                courtOrderId={formModelByPath('courtOrderId')}
                                currentContext={RefContextEnum.FORM_CHARGE_VACATING.name}
                            />
                        </ColumnLeft>
                        <ColumnRight>
                            <SubSectionTitle>
                                {strings.recordPrivacy.vacating.ChargeVacatingForm.charges.title}
                            </SubSectionTitle>
                            <RightColumnNote>
                                {strings.recordPrivacy.vacating.ChargeVacatingForm.charges.note}
                            </RightColumnNote>
                            {map(charges, (charge) => {
                                const isVacated = includes(chargeIdsToVacate, charge.id);
                                return (
                                    <ChargeRow
                                        key={charge.id}
                                        charge={charge}
                                        vacated={isVacated}
                                        onVacateChange={this.handleVacateChange}
                                    />
                                );
                            })}
                        </ColumnRight>
                    </InnerFlexContainer>
                </FlexContainer>
            </Form>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    submissionError: chargeVacatingSubmissionErrorSelector,
    chargesForReportId: chargesForReportIdSelector,
    formModelByPath: chargeVacatingForm.selectors.formModelByPathSelector,
});

export default connect(mapStateToProps, {
    changePath: chargeVacatingForm.actionCreators.changePath,
    validateForm: chargeVacatingForm.actionCreators.validate,
})(ChargeVacatingForm);

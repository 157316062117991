import { combineReducers } from 'redux';
import warrantUiReducer from './warrant/state/ui';
import warrantsDashboardUiReducer from './dashboard/state/ui';
import connectWarrantsUiSelector from './core/state/ui/connectWarrantsSidePanel';

// this should go in warrants/index, but that file imports EntityManager
// and when we import that to uiReducer we have a shitty loop
export default combineReducers({
    warrant: warrantUiReducer,
    dashboard: warrantsDashboardUiReducer,
    connectWarrants: connectWarrantsUiSelector,
});

import { Module } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const modulesModule = createNormalizedModule<Module>({
    type: 'modules',
});

// SELECTORS
export const modulesSelector = modulesModule.selectors.entitiesSelector;

// REDUCER
export default modulesModule.reducerConfig;

import { ElasticCourtOrder } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const NEXUS_STATE_PROP = 'elasticCourtOrders';
const elasticCourtOrdersModule = createNormalizedModule<ElasticCourtOrder>({
    type: NEXUS_STATE_PROP,
});

// REDUCER
export default elasticCourtOrdersModule.reducerConfig;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import { JmsBooking } from '@mark43/rms-api';

import { pageSizeToFrom } from '~/client-common/helpers/searchHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { fetchAndStoreRelatedBookings } from '../../core/state/data';
import { InlineBanner } from '../../../core/components/InlineBanner';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import { TableRow } from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import ArrestingOfficerCell from '../../../../legacy-redux/components/core/tables/jmsBookingsTable/ArrestingOfficerCell';
import BookingDetailsCell from '../../../../legacy-redux/components/core/tables/jmsBookingsTable/BookingDetailsCell';
import { uiBookingsSelector } from '../../core/state/ui/selectors';
import EventNumberCell from '../../../../legacy-redux/components/core/tables/jmsBookingsTable/EventNumberCell';
import EntityProfileReportsTableHeader from '../../core/components/EntityProfileReportsTableHeader';

const strings = componentStrings.entityProfiles.EntityProfilePersonBookings;

const BookingDateTimeCell = dateCellFactory('beginDate');

export type BookingCellProps = Partial<JmsBooking> & { columnKey: string };

type EntityParams = {
    entityId: number;
};

type EntityProfilePersonJmsBookingsProps = {
    params: EntityParams;
};

function EntityProfilePersonJmsBookings(
    props: EntityProfilePersonJmsBookingsProps & WithRouterProps
) {
    const dispatch = useDispatch();
    const profileBookings = useSelector(uiBookingsSelector);

    const handlePaginationClick = (nextPage: number, size: number) => {
        const from = pageSizeToFrom(nextPage, size);
        dispatch(fetchAndStoreRelatedBookings(props.params.entityId, from, size));
    };

    const handleRowClick = ({ id }: BookingCellProps) => {
        props.router.push(`bookings/${id}`);
    };

    const { loading, fetchErrorMessage, from, size, totalCount } = profileBookings.pagination;

    return (
        <BookingsTable
            loading={loading}
            errorMessage={fetchErrorMessage}
            bookings={profileBookings.bookings}
            totalCount={totalCount}
            from={from}
            size={size}
            handlePagination={handlePaginationClick}
            handleRowClick={handleRowClick}
        />
    );
}

type BookingsTableProps = {
    bookings: JmsBooking[];
    totalCount: number;
    from: number;
    size: number;
    handlePagination: (nextPage: number, size: number) => void;
    errorMessage?: string;
    loading: boolean;
    handleRowClick: (bookingItem: BookingCellProps) => void;
};

const BookingsTable: React.FC<BookingsTableProps> = ({
    bookings,
    totalCount,
    from,
    size,
    handlePagination,
    errorMessage,
    loading,
    handleRowClick,
}) => {
    const error = errorMessage ? (
        <th colSpan={5}>
            <InlineBanner status="error">{errorMessage}</InlineBanner>
        </th>
    ) : null;
    return (
        <div>
            <Table
                data={bookings}
                noRowsText={strings.noResults}
                simpleStyles={true}
                rowComponent={TableRow}
                disableBody={loading}
                onRowClick={handleRowClick}
                sortKey=""
                sortType=""
            >
                <TableHeader>
                    {error ||
                        (totalCount !== undefined && (
                            <EntityProfileReportsTableHeader
                                from={from}
                                size={size}
                                totalCount={totalCount}
                                onPaginationClick={(lastPage: number, nextPage: number) =>
                                    handlePagination(nextPage, size)
                                }
                            />
                        ))}
                    <TableColumns>
                        <TableColumn
                            display={strings.columns.bookingDateTime.label}
                            columnKey="bookingDateTime"
                            width={146}
                        />
                        <TableColumn
                            display={strings.columns.bookingDetails.label}
                            columnKey="details"
                            width={256}
                        />
                        <TableColumn
                            display={strings.columns.arrestingOfficer.label}
                            columnKey="arrestingOfficer"
                            width={156}
                        />
                        <TableColumn
                            display={strings.columns.eventNumber.label}
                            columnKey="eventNumber"
                            width={214}
                        />
                    </TableColumns>
                </TableHeader>
                <TableBody>
                    <BookingDateTimeCell columnKey="bookingDateTime" />
                    <BookingDetailsCell columnKey="details" />
                    <ArrestingOfficerCell columnKey="arrestingOfficer" />
                    <EventNumberCell columnKey="eventNumber" />
                </TableBody>
            </Table>
        </div>
    );
};

export default withRouter(EntityProfilePersonJmsBookings);

import { ElasticSearchTypeEnum } from '@mark43/rms-api';

export const savedSearchRouteMap = {
    [ElasticSearchTypeEnum.REPORT.name]: '/search/reports',
    [ElasticSearchTypeEnum.CAD_TICKET.name]: '/search/cad',
    [ElasticSearchTypeEnum.PERSON.name]: '/search/persons',
    [ElasticSearchTypeEnum.ORGANIZATION.name]: '/search/organizations',
    [ElasticSearchTypeEnum.VEHICLE.name]: '/search/vehicles',
    [ElasticSearchTypeEnum.PROPERTY.name]: '/search/property',
    [ElasticSearchTypeEnum.REPORTS_DASHBOARD.name]: '/reports',
    [ElasticSearchTypeEnum.CASE.name]: '/cases/all',
    [ElasticSearchTypeEnum.CASE_ASSIGNED_TO_ME.name]: '/cases/mine',
    [ElasticSearchTypeEnum.REPORT_NOT_ASSIGNED_TO_CASE.name]: '/cases/unassigned',
    [ElasticSearchTypeEnum.USER.name]: '/admin/users',
    [ElasticSearchTypeEnum.WARRANT.name]: '/warrants/dashboard',
    [ElasticSearchTypeEnum.EVIDENCE_ITEM.name]: '/evidence/dashboard',
} as const;

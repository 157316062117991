import React from 'react';
import styled from 'styled-components';
import testIds from '../../../../../core/testIds';
import SealedStamp from '../../../../core/components/SealedStamp';

const EntitySummaryTop = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    justify-content: space-between;
    min-width: 0;
`;

const EntitySummaryContent = styled.div`
    padding-left: 28px;
    box-sizing: border-box;
    margin-top: 12px;
`;

const SealedStampWrapper = styled.div`
    margin-right: 20px;
`;

const EntityContainer = styled.div`
    margin-bottom: 20px;
    clear: both;
    flex: 1;
    min-width: 0;
`;

const EntitySummaryTitle = styled.div`
    flex: 1;
    min-width: 0;
`;

type EntitySummaryProps = {
    isSealed?: boolean;
    collapsible?: boolean;
    renderTitle?: ({ collapsed }: { collapsed: boolean }) => React.ReactElement;
    renderActionButtons?: (params: {
        collapsed: boolean;
        setCollapsed: (val: boolean) => void;
    }) => React.ReactNode;
    /**
     * Use this prop when you want to render content
     * regardless of the collapsed/expanded state
     **/
    additionalItem?: ({ collapsed }: { collapsed: boolean }) => React.ReactElement;
    customStyles?: React.CSSProperties;
    initiallyShowAll?: boolean;
};

type EntitySummaryState = {
    collapsed: boolean;
};

class EntitySummary extends React.Component<EntitySummaryProps, EntitySummaryState> {
    constructor(props: EntitySummaryProps) {
        super(props);
        this.state = {
            collapsed: !!!this.props.initiallyShowAll,
        };
    }
    setCollapsed = (collapsed: boolean) => {
        this.setState({
            collapsed,
        });
    };
    render() {
        const collapsibleContent =
            this.state.collapsed && this.props.collapsible ? null : this.props.children;
        return (
            <EntityContainer style={this.props.customStyles}>
                <EntitySummaryTop data-test-id={testIds.ENTITY_SUMMARY_TOP}>
                    <EntitySummaryTitle>
                        {this.props.renderTitle &&
                            this.props.renderTitle({
                                collapsed: this.state.collapsed,
                            })}
                    </EntitySummaryTitle>
                    {this.props.renderActionButtons && (
                        <div>
                            {this.props.renderActionButtons({
                                setCollapsed: this.setCollapsed,
                                collapsed: this.state.collapsed,
                            })}
                        </div>
                    )}
                    {this.props.isSealed && (
                        <SealedStampWrapper>
                            <SealedStamp />
                        </SealedStampWrapper>
                    )}
                </EntitySummaryTop>
                {(!!collapsibleContent || !!this.props.additionalItem) && (
                    <EntitySummaryContent>
                        {collapsibleContent}
                        {!!this.props.additionalItem &&
                            this.props.additionalItem({ collapsed: this.state.collapsed })}
                    </EntitySummaryContent>
                )}
            </EntityContainer>
        );
    }
}

export default EntitySummary;

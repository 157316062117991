import React, { useContext } from 'react';
import styled from 'styled-components';
import { SelectedRowsContext } from '../../contexts';
import {
    NameCellWrapper,
    NameWrapper,
} from '../../../../attachments/core/components/AttachmentFolderRowComponents';

import Icon, { iconTypes } from '../../../../core/components/Icon';

export const DraggableCloneWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.cobaltBlue};
    width: 270px !important;
    height: 40px !important;
    padding: 10px 20px;
    font-size: 0.8rem;
    opacity: 0.9;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.25);
`;

const SelectionCount = styled.div`
    background-color: ${({ theme }) => theme.colors.red};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 0 5px;
    width: 20px;
`;

// Since react-beautiful-dnd library doesn't support stop reordering,
// we need hide the placeholder in the droppable except item isDragging
export const DroppablePlaceHolderWrapper = styled.div<{ isDragging: boolean }>`
    display: ${({ isDragging }) => (isDragging ? 'block' : 'none')};
    height: 100%;
    width: 100%;
`;

interface DraggableWrapperProps {
    isDraggingOver: boolean;
}

export const DraggableWrapper = styled.div<DraggableWrapperProps>`
    border: ${({ isDraggingOver, theme }) =>
        isDraggingOver ? `2px solid ${theme.colors.cobaltBlue};` : 'none'};
    background-color: ${({ isDraggingOver, theme }) =>
        isDraggingOver ? theme.colors.lightBlue : theme.colors.white};
    height: 52px;
    width: 100%;
`;

export const DraggableClone = ({
    iconType,
    displayName,
}: {
    iconType?: keyof typeof iconTypes;
    displayName: string | undefined;
}) => {
    const { selectedRows } = useContext(SelectedRowsContext);
    const selectionCount = selectedRows.length;
    return (
        <>
            <NameCellWrapper>
                {iconType && <Icon className="file-icon" type={iconType} color="cobaltBlue" />}
                <NameWrapper>{displayName}</NameWrapper>
            </NameCellWrapper>
            {selectionCount > 1 && <SelectionCount>{selectionCount}</SelectionCount>}
        </>
    );
};

export enum DragStatuses {
    COMPLETE = 'COMPLETE',
    IN_PROGRESS = 'IN_PROGRESS',
    HAS_NOT_STARTED = 'HAS_NOT_STARTED',
}

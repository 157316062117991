import type { DragonSearchOptionsResource } from '@mark43/rms-api';
import DataLoader from 'dataloader';
import { req } from '../../../lib/ajax';
import { ResolvedEntityDataLoaderResult } from '../rms-types';
import { retry } from '../../core/utils/promiseHelpers';
import { uiConfigurationIdToNumber } from './ui-configuration-id-to-number';

export function createCoreEntityDataLoader(options: {
    entityType: 'VEHICLE' | 'COURT' | 'FIREARM' | 'ITEM_PROFILE';
    reportId: number;
}) {
    return new DataLoader(
        (
            uiConfigurationIds: readonly string[]
        ): Promise<ResolvedEntityDataLoaderResult[] | Error[]> => {
            const uiConfigurationIdsWithoutPrefix = uiConfigurationIds.map(
                uiConfigurationIdToNumber
            );
            return retry(() =>
                req<DragonSearchOptionsResource.GetUiConfigurationOptionViewsForReportCoreEntityUiConfigurationOptionViewRequest>(
                    {
                        method: 'POST',
                        url: 'dragon/search/options/report_core_entity',
                        data: {
                            coreModel: options.entityType,
                            reportId: options.reportId,
                            uiConfigurationIds: uiConfigurationIdsWithoutPrefix,
                        },
                    }
                )
            )
                .then((result) => {
                    const optionsByUiConfigurationId = result.reduce<
                        Record<string, ResolvedEntityDataLoaderResult>
                    >((acc, optionsView) => {
                        for (const id of optionsView.uiConfigurationIds) {
                            acc[id] = optionsView.resolvedConfiguredEntityPropertyInstanceValues;
                        }
                        return acc;
                    }, {});
                    return uiConfigurationIdsWithoutPrefix.map(
                        (uiConfigurationId) => optionsByUiConfigurationId[uiConfigurationId] ?? []
                    );
                })
                .catch((err) =>
                    uiConfigurationIdsWithoutPrefix.map(
                        (uiConfigurationId) =>
                            new Error(
                                `Error loading vehicle options for key ${uiConfigurationId}: (${err.message})`
                            )
                    )
                );
        }
    );
}

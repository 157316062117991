import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
    ItemEvidenceState,
    ItemProfile,
    PropertyStatus,
    ChainOfCustody,
    ChainEvent,
} from '@mark43/evidence-api';
import { ElasticProperty } from '@mark43/rms-api';

import _, { get, first } from 'lodash';
import { FormattedDate } from '~/client-common/core/dates/components';
import { getViewModelProperties, ViewModel } from '~/client-common/helpers/viewModelHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { itemProfileViewModelsSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import { formatElasticStorageLocationByIdSelector } from '~/client-common/core/domain/elastic-storage-locations/state/ui';
import { itemEvidenceStateByMasterItemIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import { formatRenSequenceNumberSelector } from '~/client-common/core/domain/item-reporting-event-links/state/ui';
import { chainOfCustodyViewModelsForMasterItemIdSelector } from '~/client-common/core/domain/chain-of-custodies/state/ui';
import { formatDispositionStatus } from '~/client-common/core/domain/item-evidence-states/utils/formatDisposition';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';
import Link from '../../../../../modules/core/components/links/Link';

const DepartmentText = styled.span`
    color: ${(props) => props.theme.colors.lightGrey};
`;

const ElasticPropertyTitle = styled.div`
    font-weight: bold;
`;

const ElasticPropertyInfo = styled.div`
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.darkGrey};
    word-wrap: break-word;
`;

const ElasticPropertyInfoContent = styled.div`
    vertical-align: top;
    display: inline-block;
    width: 350px;
`;

const ElasticPropertyModifiedText = styled.span`
    color: ${(props) => props.theme.colors.mediumGrey};
`;

type PersonProfilePropertyCellProps = {
    hasReadAccess: boolean;
    elasticProperty: ViewModel<ElasticProperty, never>;
};

type ItemProfileViewModel = ViewModel<
    ItemProfile,
    {
        propertyStatuses: ViewModel<PropertyStatus, never>[];
    }
>;

type ChainOfCustodyViewModel = ViewModel<
    ChainOfCustody & {
        chainOfCustodyId: number;
        custodialReportId: number;
        chainEvents: ChainEvent[];
    },
    never
>;

export const PersonProfilePropertyCell: React.FC<PersonProfilePropertyCellProps> = ({
    hasReadAccess,
    elasticProperty,
}) => {
    const {
        description,
        itemTypeAttrId,
        itemTypeAttrDetail,
        departmentId,
        updatedDateUtc,
        masterItemId,
    } = elasticProperty;

    // @ts-expect-error client-common to client RND-7529
    const itemProfileViewModels: ItemProfileViewModel[] = useSelector(
        itemProfileViewModelsSelector
    );
    const contextedItemProfilesViewModels = _(itemProfileViewModels)
        .filter((item: ItemProfileViewModel) => {
            return item.masterItemId === masterItemId;
        })
        .value();

    const itemEvidenceStateByMasterItemId = useSelector(itemEvidenceStateByMasterItemIdSelector);
    const itemEvidenceState: ItemEvidenceState | undefined = masterItemId
        ? itemEvidenceStateByMasterItemId(masterItemId)
        : undefined;
    const formattedDispositionStatus = itemEvidenceState
        ? formatDispositionStatus(itemEvidenceState.dispositionStatus)
        : '';

    // @ts-expect-error client-common to client RND-7529
    const chainOfCustodyViewModelsForMasterItemId: (
        masterItemId: number | undefined
    ) => ChainOfCustodyViewModel[] = useSelector(chainOfCustodyViewModelsForMasterItemIdSelector);
    const chainsOfCustody: ChainOfCustodyViewModel[] = chainOfCustodyViewModelsForMasterItemId(
        masterItemId
    );
    const chainOfCustody: ChainOfCustodyViewModel | undefined = first(
        chainsOfCustody.filter((c) => c.chainOfCustodyId === itemEvidenceState?.chainOfCustodyId)
    );

    // @ts-expect-error client-common to client RND-7529
    const formatRenSequenceNumber: (
        masterItemId: number | undefined,
        custodialReportId: number | undefined
    ) => string = useSelector(formatRenSequenceNumberSelector);
    const itemId: string = formatRenSequenceNumber(masterItemId, chainOfCustody?.custodialReportId);

    const formatElasticStorageLocationById: (
        storageLocationId: number | undefined
    ) => string = useSelector(formatElasticStorageLocationByIdSelector);

    const chainEvents: ChainEvent[] | undefined = chainOfCustody?.chainEvents;
    const storageLocation = chainEvents
        ? first(
              chainEvents
                  .filter((e) => {
                      return e.storageLocationId;
                  })
                  .map((e) => {
                      return formatElasticStorageLocationById(e.storageLocationId);
                  })
          )
        : '';

    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const firearmMakeAttrId = formatAttributeById(elasticProperty.firearmMakeAttrId);
    const itemCategoryAttrId = formatAttributeById(elasticProperty.itemCategoryAttrId);
    const mostRecentPropertyStatus: PropertyStatus | undefined = _(contextedItemProfilesViewModels)
        .map((p) => {
            return getViewModelProperties(p).propertyStatuses;
        })
        .flatten()
        .chain()
        .sortBy('statusDateUtc')
        .last()
        .value();

    const quantity = get(mostRecentPropertyStatus, 'quantity');
    const itemType = get(itemTypeAttrDetail, 'displayValue');
    const measurementUnitsAttrId = formatAttributeById(
        get(mostRecentPropertyStatus, 'measurementUnitsAttrId')
    );
    const isFirearm = itemTypeAttrId === globalAttributes.itemType.firearm;

    const propertyTitle = joinTruthyValues(
        [itemCategoryAttrId, (isFirearm && firearmMakeAttrId) || description],
        ' - '
    );

    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const propertyBelongsToUserDepartment = departmentId === currentUserDepartmentId;
    const departmentNameFromConsortiumLinksByDepartmentId = useSelector(
        departmentNameFromConsortiumLinksByDepartmentIdSelector
    );
    const departmentName = !propertyBelongsToUserDepartment
        ? departmentNameFromConsortiumLinksByDepartmentId(departmentId)
        : '';

    const title = (
        <ElasticPropertyTitle>
            <span>{propertyTitle}</span>
            {quantity && (
                <span>
                    {' ('}
                    {measurementUnitsAttrId ? `${quantity} ${measurementUnitsAttrId}` : quantity}
                    {')'}
                </span>
            )}
        </ElasticPropertyTitle>
    );

    return (
        <ElasticPropertyInfo>
            <ElasticPropertyInfoContent>
                {hasReadAccess ? (
                    <Link to={`/profiles/property/${masterItemId}`} className="entity-profile-link">
                        {title}
                    </Link>
                ) : (
                    <div>{title}</div>
                )}
                {itemType && <div>{itemType}</div>}
                {itemId && <div>{itemId}</div>}
                {formattedDispositionStatus && <div>{formattedDispositionStatus}</div>}
                {storageLocation && <div>{storageLocation}</div>}
                <div>
                    <ElasticPropertyModifiedText>
                        {componentStrings.core.personProfileHelpers.modified}:{' '}
                    </ElasticPropertyModifiedText>
                    <span>
                        <FormattedDate
                            date={updatedDateUtc}
                            format={FormattedDate.FORMATS.FORM_DATE}
                        />
                    </span>
                </div>
                {departmentName && (
                    <div>
                        <DepartmentText>
                            {componentStrings.core.personProfileHelpers.agency}:{' '}
                        </DepartmentText>
                        <span>{departmentName}</span>
                    </div>
                )}
            </ElasticPropertyInfoContent>
        </ElasticPropertyInfo>
    );
};

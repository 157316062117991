import { SystemRuleEnum, EntityTypeEnum } from '@mark43/rms-api';
import _, {
    get,
    flatMap,
    map,
    reduce,
    includes,
    intersection,
    filter,
    forEach,
    isEmpty,
    some,
    compact,
    find,
    keyBy,
} from 'lodash';
import { personInjuriesByPersonProfileIdSelector } from '~/client-common/core/domain/person-injuries/state/data';
import validationStrings from '~/client-common/core/strings/validationStrings';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';
import {
    nibrsOffenseCodeByCodeSelector,
    nibrsOffenseCodesWhereSelector,
} from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { ucrSummaryOffenseCodesSelector } from '~/client-common/core/domain/ucr-summary-offense-codes/state/data';
import { isAfter } from '~/client-common/core/dates/utils/dateHelpers';
import nibrsCodes from '~/client-common/core/constants/nibrsCodes';
import nibrsFlags from '~/client-common/core/constants/nibrsFlags';
import nibrsCrimeAgainst from '~/client-common/core/constants/nibrsCrimeAgainst';
import ucrCodes from '~/client-common/core/legacy-constants/ucrCodes';
import { formatShortName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';
import { relationshipsDataSelector } from '../../../modules/reports/core/state/ui/relationships';
import addRuleId from '../helpers/addRuleId';

export const useOfForceReportSubjectCardRequired = addRuleId(
    SystemRuleEnum.USE_OF_FORCE_REPORT_SUBJECT_CARD_REQUIRED.name,
    ({ useOfForceSubjectCards }) => !isEmpty(useOfForceSubjectCards)
);

export const useOfForceReportSubjectOrOfficerMustHaveUsedForce = addRuleId(
    SystemRuleEnum.USE_OF_FORCE_REPORT_SUBJECT_OR_OFFICER_MUST_HAVE_USED_FORCE.name,
    ({ useOfForceSubjectCards }) =>
        some(
            useOfForceSubjectCards,
            (subjectCard) =>
                subjectCard.officerUsedForceOnSubject || subjectCard.subjectUsedForceOnOfficer
        )
);

export const offenseReportOffenseOrIncidentRequired = addRuleId(
    SystemRuleEnum.OFFENSE_REPORT_OFFENSE_OR_INCIDENT_REQUIRED.name,
    // only count cards that have a selected offense / incident code
    (data) => filter(data.offenseCards, (card) => get(card, 'offense.id') > 0).length > 0
);

const nibrsExclusionMap = {
    '09A': ['09B', '13A', '13B', '13C'],
    '09B': ['09A', '13A', '13B', '13C'],
    '11A': ['36A', '36B', '13A', '13B', '13C', '11D'],
    '11B': ['36A', '36B', '13A', '13B', '13C', '11D'],
    '11C': ['36A', '36B', '13A', '13B', '13C', '11D'],
    '11D': ['36A', '36B', '13B', '13C'],
    120: ['13A', '13B', '13C', '23A', '23B', '23C', '23D', '23E', '23F', '23G', '23H', '240'],
    '13A': ['13B', '13C'],
    '13B': ['13C'],
    '36A': ['11A', '11B', '11C', '11D'],
    '36B': ['11A', '11B', '11C', '11D'],
};

/**
 * Iterate through the offense cards to find the nibrs codes for each victim
 * @param {Object[]} list of offense cards
 * @param {Object} Should organizations and Persons be included as victims.
 * @return {Object} keys are victim IDs, values have the name name link for the
 *  victim, as well as the NIBRS and UCR codes for the corresponding offense
 */
function getCodesByVictim(offenseCards, { includeOrganizations, includePersons = true } = {}) {
    return reduce(
        offenseCards,
        (currentCodesByVictim, { offense, nameSummaryViews }) => {
            if (!nameSummaryViews) {
                return currentCodesByVictim;
            }

            const nibrsCode = get(offense, 'nibrsCode.code');
            const { VICTIM } = nameSummaryViews;
            const personLinks = (includePersons && get(VICTIM, 'personLinks')) || [];
            const organizationLinks =
                (includeOrganizations && get(VICTIM, 'organizationLinks')) || [];
            const entityLinks = [...personLinks, ...organizationLinks];

            return reduce(
                entityLinks,
                (acc, entityLink) => {
                    const dataForEntity = acc[get(entityLink, 'entity.id')];
                    const newDataForEntity = dataForEntity
                        ? {
                              link: entityLink,
                              nibrsCodes: compact([nibrsCode, ...dataForEntity.nibrsCodes]),
                          }
                        : { link: entityLink, nibrsCodes: compact([nibrsCode]) };
                    acc[get(entityLink, 'entity.id')] = newDataForEntity;
                    return acc;
                },
                currentCodesByVictim
            );
        },
        {}
    );
}

export const offenseReportNoMutuallyExclusiveOffensesForSameVictimNibrs = addRuleId(
    SystemRuleEnum.NO_MUTUALLY_EXCLUSIVE_OFFENSES_FOR_SAME_VICTIM.name,
    ({ offenseCards }, errMsg, state) => {
        const codesByVictim = getCodesByVictim(offenseCards, {
            includeOrganizations: true,
            includePersons: true,
        });
        const nibrsCodesByCode = nibrsOffenseCodeByCodeSelector(state);

        function dataToErrorStr({ link, code1, code2 }) {
            const code1Data = nibrsCodesByCode[code1];
            const code2Data = nibrsCodesByCode[code2];
            const code1Str = `${code1Data.name} (${code1Data.code})`;
            const code2Str = `${code2Data.name} (${code2Data.code})`;
            let name;
            switch (get(link, 'linkData.entityType')) {
                case EntityTypeEnum.ORGANIZATION_PROFILE.name:
                    name = get(link, 'entity.name') || '';
                    break;
                case EntityTypeEnum.PERSON_PROFILE.name:
                    name = formatShortName({
                        firstName: get(link, 'entity.firstName'),
                        lastName: get(link, 'entity.lastName'),
                    });
                    break;
                default:
                    name = '';
                    break;
            }
            return validationStrings.submission.exclusiveOffensesNibrs(name, code1Str, code2Str);
        }

        const errors = _(codesByVictim)
            .map(({ link, nibrsCodes }) => {
                return flatMap(nibrsCodes, (code) => {
                    const codesToExclude = nibrsExclusionMap[code] || [];
                    const collisions = intersection(nibrsCodes, codesToExclude);
                    return map(collisions, (collision) => ({
                        link,
                        code1: code,
                        code2: collision,
                    }));
                });
            })
            .flatten()
            .uniqBy(({ code1, code2 }) => [code1, code2].sort().join())
            .map(dataToErrorStr)
            .value();

        return errors.length > 0 ? errors : true;
    }
);

const groupOffensesByVictim = (offenseCards = []) =>
    offenseCards.reduce((acc, offenseCard) => {
        const { nameSummaryViews } = offenseCard;
        if (nameSummaryViews.VICTIM) {
            const offenseId = get(offenseCard, 'offense.id');
            forEach(nameSummaryViews.VICTIM.personLinks, (link) => {
                const victimId = link.entity.id;
                const victimOffenses = acc[victimId] || (acc[victimId] = []);
                victimOffenses.push(offenseId);
            });
        }

        return acc;
    }, {});

const groupKnownSuspectsByOffense = (offenseCards = []) =>
    _(offenseCards)
        .keyBy(({ offense }) => offense.id)
        .mapValues(
            (offense) =>
                (offense.nameSummaryViews.SUSPECT &&
                    _(offense.nameSummaryViews.SUSPECT.personLinks)
                        .reject((link) => get(link, 'entity.isUnknown'))
                        .map((link) => link.entity.id)
                        .value()) ||
                []
        )
        .value();

const getUniqueVictimsLinkForOffenseCards = (offenseCards = []) =>
    _(offenseCards)
        .map(({ nameSummaryViews }) => get(nameSummaryViews, 'VICTIM.personLinks'))
        .flatten()
        .uniqBy((link) => link.entity.id)
        .value();

const relationshipDataToErrorStr = (link, codeData, offenseDisplayName) => {
    const name = formatShortName({
        firstName: get(link, 'entity.firstName'),
        lastName: get(link, 'entity.lastName'),
    });
    return validationStrings.submission.victimsRequireRelationshipNibrsUcr({
        name,
        code: codeData.name,
        offenseDisplayName,
    });
};

const getMissingRelationshipErrors = ({
    offenseCards,
    codesToCheck,
    codesByCode,
    isNibrs,
    reduxState,
}) => {
    const nibrsOffenseCodesWhere = nibrsOffenseCodesWhereSelector(reduxState);
    const propertyNibrsCodes = map(
        nibrsOffenseCodesWhere({ crimeAgainst: nibrsCrimeAgainst.property }),
        (code) => code.code
    );
    const eventDetailByReportId = eventDetailByReportIdSelector(reduxState);
    const relevantOffenseCards = filter(offenseCards, (offenseCard) => {
        const offense = offenseCard.offense;
        if (isNibrs) {
            const nibrsIncidentDateUtc =
                offense.offenseDateUtc ||
                get(eventDetailByReportId(offense.reportId), 'eventStartUtc');
            if (nibrsIncidentDateUtc && isAfter(new Date(2019, 0, 1), nibrsIncidentDateUtc)) {
                codesToCheck = codesToCheck.concat(propertyNibrsCodes);
            }
        }

        const code = isNibrs
            ? get(offense, 'nibrsCode.code')
            : get(offense, 'offenseCode.ucrSummaryCodeCode');
        return includes(codesToCheck, code);
    });

    // bail early if none of the offenses match
    // to prevent expensive work from being done
    if (relevantOffenseCards.length === 0) {
        return true;
    }

    // group offense cards by victim because if a victim is part of
    // _any_ matching offense, we have to check all other offenses
    // they are part of
    const offenseIdsByVictim = groupOffensesByVictim(offenseCards);

    // group suspects by offense id, in order to easily check whether
    // links between all victims and suspects on an offense exist
    const knownSuspectIdsByOffenseId = groupKnownSuspectsByOffense(offenseCards);

    // get all victim links and group them by id for a later lookup
    const victimLinks = getUniqueVictimsLinkForOffenseCards(relevantOffenseCards);

    // the relationships card is in react and stores state in redux
    const relationshipsData = relationshipsDataSelector(reduxState);

    // collect all victims which have at least one missing connection
    // to a subject in the offenses they are part of
    const victimIdsWithMissingConnections = _(victimLinks)
        .filter((link) => {
            const victimId = link.entity.id;
            return (
                _(offenseIdsByVictim[victimId])
                    .map((offenseId) => knownSuspectIdsByOffenseId[offenseId])
                    .flatten()
                    .reject(
                        (suspectId) =>
                            victimId === suspectId ||
                            find(
                                relationshipsData.nameNameLinks,
                                ({ nameToId, nameFromId }) =>
                                    (nameToId === suspectId && nameFromId === victimId) ||
                                    (nameToId === victimId && nameFromId === suspectId)
                            )
                    )
                    .value().length > 0
            );
        })
        .map((link) => link.entity.id)
        .value();

    if (!victimIdsWithMissingConnections.length) {
        return true;
    }

    // there have been errors, index victims and offenses by their ids,
    // so we can reference them while creating our errors
    const victimsLinksById = keyBy(victimLinks, (link) => link.entity.id);
    const offensesById = _(offenseCards)
        .map('offense')
        .keyBy((offense) => offense.id)
        .value();

    const relevantOffenseIds = map(relevantOffenseCards, (offenseCard) => offenseCard.offense.id);
    const offenseDisplayName = formatFieldByNameSelector(reduxState)(DISPLAY_ONLY_OFFENSE);

    return map(victimIdsWithMissingConnections, (victimId) => {
        // find the first matching relevant offense for each victim
        // so we can get its nibrs/ucr code. It does not matter
        // if there are multiple matching offenses as long as we
        // show one of the matching ones
        const failedOffenseId = find(offenseIdsByVictim[victimId], (offenseId) =>
            includes(relevantOffenseIds, offenseId)
        );

        const code = isNibrs
            ? get(offensesById[failedOffenseId], 'nibrsCode.code')
            : get(offensesById[failedOffenseId], 'offenseCode.ucrSummaryCodeCode');

        return relationshipDataToErrorStr(
            victimsLinksById[victimId],
            codesByCode[code],
            offenseDisplayName
        );
    });
};

export const offenseReportRequireRelationshipForCertainOffensesNibrs = addRuleId(
    SystemRuleEnum.RELATIONSHIP_ON_RELATIONSHIP_OFFENSES_REQUIRED_NIBRS.name,
    ({ offenseCards }, errMsg, reduxState) => {
        const nibrsOffenseCodesWhere = nibrsOffenseCodesWhereSelector(reduxState);
        const personNibrsCodes = map(
            nibrsOffenseCodesWhere({ crimeAgainst: nibrsCrimeAgainst.person }),
            (code) => code.code
        );
        return getMissingRelationshipErrors({
            offenseCards,
            codesToCheck: [...personNibrsCodes, nibrsCodes.robbery],
            codesByCode: nibrsOffenseCodeByCodeSelector(reduxState),
            isNibrs: true,
            reduxState,
        });
    }
);

export const offenseReportRequireRelationshipForCertainOffensesUcr = addRuleId(
    SystemRuleEnum.RELATIONSHIP_ON_RELATIONSHIP_OFFENSES_REQUIRED.name,
    ({ offenseCards }, errMsg, reduxState) =>
        getMissingRelationshipErrors({
            offenseCards,
            codesToCheck: ucrCodes.homicide,
            codesByCode: ucrSummaryOffenseCodesSelector(reduxState),
            isNibrs: false,
            reduxState,
        })
);

export const offenseReportRequireInjuryTypeOnInjuryOffensesNibrs = addRuleId(
    SystemRuleEnum.INJURY_TYPE_ON_INJURY_OFFENSES_REQUIRED.name,
    ({ offenseCards }, errMsg, state) => {
        // Fetch the injuries data differently, based on whether or not
        // we have moved them into the person side panel or left them
        // in the injury card
        const injuriesByPersonProfileId = personInjuriesByPersonProfileIdSelector(state);

        function dataToErrorStr({ link, codeData }) {
            const codeStr = `${codeData.name}`;
            const name = formatShortName({
                firstName: get(link, 'entity.firstName'),
                lastName: get(link, 'entity.lastName'),
            });
            return validationStrings.submission.victimsRequireInjuryNibrs(name, codeStr);
        }

        const nibrsCodesByCode = nibrsOffenseCodeByCodeSelector(state);
        const codesByVictim = getCodesByVictim(offenseCards, {
            includeOrganizations: false,
            includePersons: true,
        });
        const errors = flatMap(codesByVictim, ({ link, nibrsCodes }) => {
            const injuries = injuriesByPersonProfileId(link.entity.id) || [];
            const hasInjuries = injuries.length > 0;
            return _(nibrsCodes)
                .map((code) => {
                    const codeData = nibrsCodesByCode[code];
                    const requiresInjury = includes(codeData.flags, nibrsFlags.requiresInjury);
                    return requiresInjury && !hasInjuries
                        ? dataToErrorStr({ link, codeData })
                        : null;
                })
                .compact()
                .value();
        });
        return errors.length > 0 ? errors : true;
    }
);

import React, { useState, useEffect } from 'react';
import { EntityTypeEnumType, Attachment } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import getAttachmentsResource from '~/client-common/core/domain/attachments/resources/attachmentsResource';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { MFTSelect } from '../../../core/forms/components/selects/Select';
import type { SelectOption } from '../../../core/forms/helpers/selectHelpers';

type PropsT = {
    path: string;
    label: string;
    entityId: number;
    entityType: EntityTypeEnumType;
    allowMultiple: boolean;
};

export const SubmissionAttachments: React.FC<PropsT> = ({
    path,
    label,
    entityId,
    entityType,
    allowMultiple,
}) => {
    const [options, setOptions] = useState<SelectOption[]>([]);
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);
    useEffect(() => {
        const fetchAttachmentsByEntity = async (
            entityId: number,
            entityType: EntityTypeEnumType
        ) => {
            const attachmentsResource = getAttachmentsResource();
            const entityAttachments = await attachmentsResource.loadAttachmentsByEntityId(
                entityType,
                entityId
            );

            const selectOptions: SelectOption[] = entityAttachments.map(
                (attachment: Attachment) => {
                    const m43File = getAttachmentFile(attachment);

                    return {
                        value: attachment.attachmentId,
                        display: m43File.originalFileName,
                    };
                }
            );

            setOptions(selectOptions);
        };
        fetchAttachmentsByEntity(entityId, entityType);
    }, [entityType, entityId, formatMiniUserById]);

    return (
        <MFTSelect
            path={path}
            options={options}
            label={label}
            length={396}
            multiple={allowMultiple}
        />
    );
};

import { RefContextEnum } from '@mark43/rms-api';
import { get, map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';

import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOfForceSubjectsWhereSelector } from '~/client-common/core/domain/use-of-force-subjects/state/data';
import { sortedUseOfForceSubjectsForReportIdSelector } from '~/client-common/core/domain/use-of-force-subjects/state/ui';
import { saveUseOfForceSubject } from '~/client-common/core/domain/reports/state/ui';
import { canEditReportCardStatusSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import { RMSArbiterProvider } from '../../../../core/arbiter';
import testIds from '../../../../../core/testIds';
import { initCards } from '../../state/ui';
import useOfForceSubjectCard from '../../state/ui/useOfForceSubjectCard';
import Icon, { iconTypes } from '../../../../../legacy-redux/components/core/Icon';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import UseOfForceSubjectCard from './UseOfForceSubjectCard';

const useOfForceSubjectCardStrings = componentStrings.reports.core.UseOfForceSubjectCard;

const ButtonWrapper = styled.div`
    clear: both;

    & .react-icon-left {
        height: auto;
    }
`;

const AddNewUseOfForceSubjectButton = styled(Button)`
    margin: 30px 0px 0px 0px;
    width: 100%;
    height: 45px;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .mark43-icon::before {
        margin-top: -2px;
        display: inline-block;
        vertical-align: middle;
        float: none;
    }
`;

const mapStateToProps = createStructuredSelector({
    canEditReportCardStatus: canEditReportCardStatusSelector,
    sortedUseOfForceSubjectsForReportId: sortedUseOfForceSubjectsForReportIdSelector,
    dataUseOfForceSubjectsWhere: useOfForceSubjectsWhereSelector,
});

const mapDispatchToProps = (dispatch) => ({
    addNewUseOfForceSubject: (reportId) => {
        dispatch(saveUseOfForceSubject(reportId, { id: -1, reportId })).then(
            (useOfForceSubjectBundle) => {
                const useOfForceSubjectId = get(useOfForceSubjectBundle, 'useOfForceSubject.id');
                dispatch(
                    useOfForceSubjectCard.actionCreators.createNewCard({
                        index: useOfForceSubjectId,
                    })
                );
                useOfForceSubjectCard.scrollToTop({ index: useOfForceSubjectId });
            }
        );
    },
    initCards: (cardModule, editMode, options) => {
        dispatch(
            initCards({
                cardModule,
                editMode,
                options,
            })
        );
    },
});

class UseOfForceSubjectCardsWrapper extends React.Component {
    cardRefs = {};

    componentDidMount() {
        const { editMode, dataUseOfForceSubjectsWhere, reportId } = this.props;
        const useOfForceSubjects = dataUseOfForceSubjectsWhere({ reportId });
        const useOfForceSubjectIds = map(useOfForceSubjects, 'id');

        if (useOfForceSubjectIds.length) {
            this.props.initCards(useOfForceSubjectCard, editMode, {
                indexes: useOfForceSubjectIds,
            });
        }
    }

    handleAddUseOfForceSubjectClick = () => this.props.addNewUseOfForceSubject(this.props.reportId);

    handleUseOfForceSubjectCardRef = (id) => (ref) => {
        if (!ref) {
            this.cardRefs[id] = undefined;
        } else {
            this.cardRefs[id] = {
                ref,
            };
        }
    };

    render() {
        const {
            editCallback,
            reportId,
            canEditReportCardStatus,
            sortedUseOfForceSubjectsForReportId,
            currentReportREN,
        } = this.props;
        const useOfForceSubjects = sortedUseOfForceSubjectsForReportId(reportId);
        return (
            <>
                <RMSArbiterProvider context={RefContextEnum.FORM_USE_OF_FORCE_SUBJECT.name}>
                    {(arbiterInstance) => (
                        <>
                            {map(useOfForceSubjects, (useOfForceSubject) => (
                                <UseOfForceSubjectCard
                                    ref={this.handleUseOfForceSubjectCardRef(useOfForceSubject.id)}
                                    key={useOfForceSubject.id}
                                    index={useOfForceSubject.id}
                                    useOfForceSubjectId={useOfForceSubject.id}
                                    editCallback={editCallback}
                                    arbiter={arbiterInstance}
                                    currentReportREN={currentReportREN}
                                />
                            ))}
                        </>
                    )}
                </RMSArbiterProvider>
                {get(canEditReportCardStatus, 'canEditReportCard') && (
                    <ButtonWrapper>
                        <AddNewUseOfForceSubjectButton
                            className={buttonTypes.SECONDARY}
                            iconLeft={<Icon fontSize="20px" type={iconTypes.ADD} />}
                            onClick={() => editCallback(this.handleAddUseOfForceSubjectClick)}
                            testId={testIds.ADD_NEW_USE_OF_FORCE_SUBJECT_BUTTON}
                        >
                            {useOfForceSubjectCardStrings.subject}
                        </AddNewUseOfForceSubjectButton>
                    </ButtonWrapper>
                )}
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    UseOfForceSubjectCardsWrapper
);

import { storeExportReleaseTypes } from '~/client-common/core/domain/export-release-types/state/data';
import exportReleaseSettingResource from '../../resources/exportReleaseSettingsResource';

const LOAD_EXPORT_RELEASE_SETTINGS_START =
    'export-release-types/LOAD_EXPORT_RELEASE_SETTINGS_START';
const LOAD_EXPORT_RELEASE_SETTINGS_SUCCESS =
    'export-release-types/LOAD_EXPORT_RELEASE_SETTINGS_SUCCESS';
const LOAD_EXPORT_RELEASE_SETTINGS_FAILURE =
    'export-release-types/LOAD_EXPORT_RELEASE_SETTINGS_FAILURE';
const SAVE_EXPORT_RELEASE_SETTINGS_START =
    'export-release-types/SAVE_EXPORT_RELEASE_SETTINGS_START';
const SAVE_EXPORT_RELEASE_SETTINGS_SUCCESS =
    'export-release-types/SAVE_EXPORT_RELEASE_SETTINGS_SUCCESS';
const SAVE_EXPORT_RELEASE_SETTINGS_FAILURE =
    'export-release-types/SAVE_EXPORT_RELEASE_SETTINGS_FAILURE';

function loadExportReleaseSettingsStart() {
    return {
        type: LOAD_EXPORT_RELEASE_SETTINGS_START,
    };
}
function loadExportReleaseSettingsSuccess(exportReleaseSettings) {
    return {
        type: LOAD_EXPORT_RELEASE_SETTINGS_SUCCESS,
        payload: exportReleaseSettings,
    };
}
function loadExportReleaseSettingsFailure(errorMessage) {
    return {
        type: LOAD_EXPORT_RELEASE_SETTINGS_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Load all Export Release Settings
 * @return {Promise<Object>}
 */
export function loadExportReleaseSettings() {
    return (dispatch) => {
        dispatch(loadExportReleaseSettingsStart());
        return exportReleaseSettingResource
            .getAllExportReleaseSettings()
            .then((exportReleaseSettings) => {
                // assume exportReleaseSettings only contain exportReleaseTypes for now
                dispatch(loadExportReleaseSettingsSuccess(exportReleaseSettings));
                dispatch(storeExportReleaseTypes(exportReleaseSettings.exportReleaseTypes));
                return exportReleaseSettings;
            })
            .catch((err) => {
                dispatch(loadExportReleaseSettingsFailure(err.message));
                throw err;
            });
    };
}

function saveExportReleaseSettingsStart() {
    return {
        type: SAVE_EXPORT_RELEASE_SETTINGS_START,
    };
}

function saveExportReleaseSettingsSuccess(exportReleaseSettings) {
    return {
        type: SAVE_EXPORT_RELEASE_SETTINGS_SUCCESS,
        payload: exportReleaseSettings,
    };
}

function saveExportReleaseSettingsFailure(message) {
    return {
        type: SAVE_EXPORT_RELEASE_SETTINGS_FAILURE,
        payload: message,
    };
}

export function saveExportReleaseSettings(exportReleaseSettings) {
    return (dispatch) => {
        dispatch(saveExportReleaseSettingsStart());
        return exportReleaseSettingResource
            .upsertExportReleaseSetting(exportReleaseSettings)
            .then((savedExportReleaseSettings) => {
                dispatch(saveExportReleaseSettingsSuccess(savedExportReleaseSettings));
                dispatch(storeExportReleaseTypes(exportReleaseSettings.exportReleaseTypes));
                return savedExportReleaseSettings;
            })
            .catch((err) => {
                dispatch(saveExportReleaseSettingsFailure(err.message));
                throw err;
            });
    };
}

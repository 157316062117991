import React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange, branch } from 'recompose';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import { identity } from 'lodash';
import styled from 'styled-components';

import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { ITEM_IDENTIFIER_IDENTIFIER } from '~/client-common/core/enums/universal/fields';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Row from '../../../../core/components/Row';
import { RRFNItems } from '../../../../core/forms/components/NItems';
import { RRFAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { RRFText } from '../../../../core/forms/components/Text';
import IndentedFields from '../../../../core/components/IndentedFields';

const { withRRFFieldValues } = reactReduxFormHelpers;

const FormGroupWrapper = styled.div`
    max-width: 30rem;
`;
const FlexRow = styled.div`
    display: flex;
`;

const ItemIdentifierRow = branch(
    ({ disableOtherDescription }) => disableOtherDescription,
    identity,
    compose(
        connect(
            createStructuredSelector({
                attributeIsOther: attributeIsOtherSelector,
            })
        ),
        withRRFFieldValues({
            itemIdentifierTypeAttrId: 'itemIdentifierTypeAttrId',
        }),
        withPropsOnChange(
            ['itemIdentifierTypeAttrId'],
            ({ itemIdentifierTypeAttrId, attributeIsOther }) => ({
                showItemIdentifierTypeOtherDesc: attributeIsOther(itemIdentifierTypeAttrId),
            })
        )
    )
)(function ItemIdentifierRow({
    fuzzyMatchingEnabled,
    showItemIdentifierTypeOtherDesc = false,
    deleteButton,
    identifierLabel,
    includeExpired,
}) {
    return (
        <FormGroupWrapper>
            <FlexRow>
                <RRFAttributeSelect
                    attributeType="ITEM_IDENTIFIER_TYPE"
                    includeExpired={includeExpired}
                    path="itemIdentifierTypeAttrId"
                />
                <RRFText
                    label={identifierLabel}
                    className={classNames({
                        'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                    })}
                    path="identifier"
                />
                {deleteButton}
            </FlexRow>
            {showItemIdentifierTypeOtherDesc && (
                <IndentedFields>
                    <Row>
                        <RRFText length="md" path="itemIdentifierTypeOtherDesc" />
                    </Row>
                </IndentedFields>
            )}
        </FormGroupWrapper>
    );
});

/**
 * Item Identifiers N-Items component.
 * @param {Object}  props
 * @param {number}  props.fieldWidth        width in pixels for field inputs
 * @param {boolean} [props.fuzzyMatchingEnabled]  used in advanced search
 * @param {boolean} [props.addItemOnDirty]  see NItems. used in advanced search
 * @param {boolean} [props.addItemOnEmpty]  see NItems
 * @param {boolean} [props.disableOtherDescription=false]  advanced search
 *      doesnt need to display this additional field
 * @param {string}  [props.identifierLabel] advanced search has a custom label
 * @param {boolean} [props.includeExpired]  advanced search should have expired
 */
export default function ItemIdentifierNItems({
    fuzzyMatchingEnabled,
    addItemOnDirty,
    addItemOnEmpty,
    disableOtherDescription = false,
    identifierLabel,
    includeExpired,
}) {
    const addIdentifierLabel = useFields(ITEM_IDENTIFIER_IDENTIFIER)[ITEM_IDENTIFIER_IDENTIFIER];
    return (
        <RRFNItems
            path="itemIdentifiers"
            addText={addIdentifierLabel}
            addItemOnDirty={addItemOnDirty}
            addItemOnEmpty={addItemOnEmpty}
        >
            {(item, index, deleteButton) => (
                <ItemIdentifierRow
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    deleteButton={deleteButton}
                    disableOtherDescription={disableOtherDescription}
                    identifierLabel={identifierLabel}
                    includeExpired={includeExpired}
                />
            )}
        </RRFNItems>
    );
}

import React from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';

import { ElasticCobaltSortTypeEnumType } from '@mark43/rms-api';
import { cssVar } from 'arc';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';

import testIds from '../../../../../core/testIds';
import theme from '../../../../core/styles/theme';
import { mapSortTypeToIcon } from '../../../../core/components/PopoutMenu';
import { onClickToOnEnter } from '../../../../core/utils/eventHelpers';

const StyledDropDown = styled.div`
    white-space: nowrap;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
    font-size: ${cssVar('arc.fontSizes.md')};
    line-height: ${cssVar('arc.lineHeights.shorter')};
    font-weight: ${cssVar('arc.fontWeights.semibold')};
    padding-bottom: 6px;
    border-bottom: none;
    color: ${(props: { theme: typeof theme; color: keyof typeof theme.colors }) =>
        props.theme.colors[props.color]};
`;

type Props = {
    // Callback which is called whenever the RelatedRecordsSortHeader is clicked
    onClick?: () => void;
    // Indicates the type of sort for this column. This value will dictate display behaviours such as what colour the header should be and which icons should display
    sortType?: ElasticCobaltSortTypeEnumType;
    // The text which will be visible in the RelatedRecordsSortHeader
    display: string;
    // An additional complimentary CSS class that RelatedRecordsSortHeader can inherit
    className?: string;
};

const RelatedRecordsSortHeader: React.FC<Props> = ({
    onClick: handleClick = noop,
    sortType = sortTypeEnum.RELEVANCE,
    display,
    children: dropdown,
    className,
}) => {
    // @ts-expect-error TODO: narrow the type of sortType or mapSortTypeToIcon
    const sortIcon = mapSortTypeToIcon('cobaltBlue')[sortType];
    return (
        <StyledDropDown
            className={className}
            onKeyDown={onClickToOnEnter(handleClick)}
            onClick={handleClick}
            color="cobaltBlue"
        >
            <div
                tabIndex={0}
                data-test-id={testIds.LINKED_RECORDS_SORT_DROPDOWN}
                color="cobaltBlue"
            >
                {display}
                {sortIcon}
            </div>
            {dropdown}
        </StyledDropDown>
    );
};

export default RelatedRecordsSortHeader;

import { saveAttributeTypeCodesTypeLinks } from '~/client-common/core/domain/attribute-type-codes-type-links/state/data';

const SAVE_FORM_START = 'admin-attribute-code-type-links/SAVE_FORM_START';
const SAVE_FORM_SUCCESS = 'admin-attribute-code-type-links/SAVE_FORM_SUCCESS';
const SAVE_FORM_FAILURE = 'admin-attribute-code-type-links/SAVE_FORM_FAILURE';

const initialUiState = {
    submittingForm: false,
    formError: null,
};

function saveFormStart() {
    return {
        type: SAVE_FORM_START,
    };
}

function saveFormSuccess(attributeCodeTypeLinks) {
    return {
        type: SAVE_FORM_SUCCESS,
        payload: attributeCodeTypeLinks,
    };
}

function saveFormFailure(errMsg) {
    return {
        type: SAVE_FORM_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export default function attributeTypeCodeTypeLinksUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SAVE_FORM_START:
            return {
                ...state,
                submittingForm: true,
                formError: null,
            };
        case SAVE_FORM_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                formError: null,
            };
        case SAVE_FORM_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        default:
            return state;
    }
}

export function saveForm(attributeType, attributeCodeTypeLinks) {
    return (dispatch) => {
        dispatch(saveFormStart());
        dispatch(saveAttributeTypeCodesTypeLinks(attributeType, attributeCodeTypeLinks))
            .then(() => dispatch(saveFormSuccess()))
            .catch((err) => dispatch(saveFormFailure(err.message)));
    };
}

export const uiSelector = (state) => state.ui.attributeTypeCodeTypeLinksAdmin;

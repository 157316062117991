import { NEXUS_STATE_PROP as COURT_CASES_NEXUS_STATE_PROP } from '../../../court-cases/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

export function replaceCourtCaseCardBundle({ reportId, courtCaseCardBundle }) {
    return function (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) {
        const resource = getReportCardBundleResource();
        return resource
            .upsertCourtCaseCard(reportId, courtCaseCardBundle)
            .then((updatedCourtCaseCardBundle) => {
                const { courtCases } = updatedCourtCaseCardBundle;

                dispatch(
                    withEntityItems(
                        {
                            [COURT_CASES_NEXUS_STATE_PROP]: courtCases,
                        },
                        withRemoveMultiple(
                            {
                                [COURT_CASES_NEXUS_STATE_PROP]: [{ reportId }],
                            },
                            {
                                type: 'UPSERT_COURT_CASE_CARD_BUNDLE',
                            }
                        )
                    )
                );

                return updatedCourtCaseCardBundle;
            });
    };
}

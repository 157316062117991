import { useSelector } from 'react-redux';
import { formatFieldByNameSelector } from '../state/config';
import { DISPLAY_ONLY_ITEM_OTHER_LABEL } from '../../enums/universal/fields';
import globalAttributes from '../../legacy-constants/globalAttributes';
import componentStrings from '../../strings/componentStrings';

const strings = componentStrings.core.itemTypes;

export function useItemTypeNames() {
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    return {
        ...strings,
        [globalAttributes.itemType.item]: formatFieldByName(DISPLAY_ONLY_ITEM_OTHER_LABEL),
    };
}

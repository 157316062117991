import styled from 'styled-components';
import React, { useCallback } from 'react';
import { EntityTypeEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';

import SectionBottomButton from '../../search/quick-search/components/layout/SectionBottomButton';
import { InputLoading } from '../../../legacy-redux/components/core/Loading';
import EntitySearchResultItemWrapper from './EntitySearchResultItemWrapper';

const strings = componentStrings.core.entitySearch;

const LoadMoreButton = styled(SectionBottomButton)`
    flex: 3 0 68%;
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey};

    && {
        background-color: ${(props) => props.theme.colors.white};
    }
`;

const CloseButton = styled(SectionBottomButton)`
    flex: 1 0 79px;
    justify-content: center;
    text-align: right;
`;

const BottomBarWrapper = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.white};
`;

const EntitySearchResultsSection = ({
    from,
    size,
    totalCount,
    items,
    allSearchItems,
    type,
    isSectionLoading,
    onResultClick,
    renderHeader,
    showCheckbox,
    hideCloseButton,
    renderFooter,
    loadMore,
    onClose,
    query,
    selectedEntityIds,
    setSelectedEntityIds,
    containerClassName,
}) => {
    const numResults = items.length;
    const maxIndex = numResults - 1;
    const amountLeft = totalCount - (from + size);
    const amountToLoad = amountLeft > size ? size : amountLeft;
    const canLoadMore = amountToLoad > 0;
    const isOnly = maxIndex === 0;

    const triggerLoadMore = useCallback(
        (e) => {
            loadMore();
            e.stopPropagation();
            e.preventDefault();
        },
        [loadMore]
    );

    const onResultClose = useCallback(
        (e) => {
            onClose();
            e.stopPropagation();
            e.preventDefault();
            setSelectedEntityIds([]);
        },
        [onClose, setSelectedEntityIds]
    );

    return (
        <div className={containerClassName} >
            {renderHeader && renderHeader(items)}
            {items.map((item, index) => {
                const isLast = index === maxIndex;
                const entityId = type === EntityTypeEnum.CASE.name ? item.caseId : item.id;
                return (
                    <EntitySearchResultItemWrapper
                        key={`${type}~${entityId}`}
                        isOnly={isOnly}
                        isLast={isLast}
                        itemType={type}
                        item={item}
                        onResultClick={onResultClick}
                        query={query}
                        type={type}
                        showCheckbox={showCheckbox}
                        selectedEntityIds={selectedEntityIds}
                        setSelectedEntityIds={setSelectedEntityIds}
                        entityId={entityId}
                    />
                );
            })}

            <BottomBarWrapper>
                {canLoadMore && (
                    <LoadMoreButton
                        type="button"
                        onMouseDown={triggerLoadMore}
                        disabled={isSectionLoading}
                    >
                        {strings.loadMore(amountToLoad)}
                        {isSectionLoading && <InputLoading />}
                    </LoadMoreButton>
                )}
                {!hideCloseButton && (
                    <CloseButton
                        type="button"
                        onMouseDown={onResultClose}
                        disabled={isSectionLoading}
                    >
                        {strings.close}
                    </CloseButton>
                )}
            </BottomBarWrapper>
            {renderFooter && renderFooter(allSearchItems, selectedEntityIds, onResultClose)}
        </div>
    );
};

export default EntitySearchResultsSection;

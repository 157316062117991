import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { profileReportTypesModelsSelector } from '~/client-common/core/domain/profile-report-types/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Row from '../../../../core/components/Row';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import Fieldset from '../../../../core/forms/components/Fieldset';
import ReactReduxForm from '../../../../core/forms/components/ReactReduxForm';
import entityProfileReportsFilterForm from '../../state/forms/entityProfileReportsFilterForm';
import { RRFSelect } from '../../../../core/forms/components/selects/Select';

const strings = componentStrings.entityProfiles.EntityProfilePersonReports.filterForm;

const FormBody = styled.div`
    margin-top: 50px;
    margin-bottom: 15px;
`;

const ClearFiltersButton = styled(Button)`
    color: var(--arc-colors-icon-default);
    fill: var(--arc-colors-icon-default);
    float: left;
`;

export default function EntityProfileReportsFilterForm({ onChange }) {
    const reportTypes = useSelector(profileReportTypesModelsSelector);
    const dispatch = useDispatch();

    const resetAction = () => {
        dispatch(entityProfileReportsFilterForm.actionCreators.reset());
        onChange();
    };

    return (
        <ReactReduxForm {...entityProfileReportsFilterForm}>
            <FormBody>
                <Fieldset
                    title={strings.reportDetailsFieldset.title}
                    className="form-section"
                    highlightOnFocus={false}
                >
                    <Row>
                        <RRFSelect
                            clearable={true}
                            path="reportTypeIds"
                            label={strings.reportDetailsFieldset.reportTypes}
                            width={300}
                            multiple={true}
                            onChange={onChange}
                            options={reportTypes}
                        />
                    </Row>
                    <Row>
                        <ClearFiltersButton
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.CLOSE_X} size={12} />}
                            onClick={resetAction}
                        >
                            {strings.clearFilters}
                        </ClearFiltersButton>
                    </Row>
                </Fieldset>
            </FormBody>
        </ReactReduxForm>
    );
}

import { isNull, isUndefined } from 'lodash';
import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getDropdownOptionsFromObject } from '~/client-common/helpers/dropdownOptionHelpers';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

/**
 * Select dropdown for inventory types.
 * @param {Object} props
 */
function InventoryTypeSelect(props) {
    const { options } = componentStrings.forms.select.InventoryTypeSelect;
    const inventoryTypeOptions = getDropdownOptionsFromObject(options);

    const value = isNull(props.value) || isUndefined(props.value) ? null : props.value;

    return <Select options={inventoryTypeOptions} {...props} value={value} />;
}

export const RRFInventoryTypeSelect = connectRRFInput(InventoryTypeSelect);

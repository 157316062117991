import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { REPORT_RECORD_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import stringsConfig from '~/client-common/core/strings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import recordSequenceTypeEnum from '~/client-common/core/enums/client/recordSequenceTypeEnum';

import { clearRecordNumberChangeModal } from '../../actions/recordNumberChangeActions';
import { closeBox, saveBoxHalt } from '../../actions/boxActions';
import {
    recordNumberChangeUiSelector,
    recordNumberChangeFormSelector,
} from '../../selectors/recordNumberChangeSelectors';
import { currentReportSelector } from '../../selectors/reportSelectors';
import { minModalContentHeight } from '../../configs/recordNumberChangeConfig';
import Modal from '../core/Modal';
import { createModalSelector } from '../../../modules/core/box/state/ui';
import RecordNumberChangeForm from './RecordNumberChangeForm';

const strings = stringsConfig.components.reports.RecordNumberChangeModal;

const context = {
    name: boxEnum.RECORD_NUMBER_CHANGE_MODAL,
};

function RecordNumberChangeModal({
    reportDefinition,
    recordSequenceType,
    ui,
    currentReport,
    saveBoxHalt,
    closeBox,
    formatFieldByName,
}) {
    const handleSubmit = () => {
        const result = ui.handleSubmit();
        if (!result) {
            saveBoxHalt();
        }
        return result;
    };

    const changeEnabled =
        recordSequenceType === recordSequenceTypeEnum.CAD_EVENT ? ui.changeEnabled : true;

    return (
        <Modal
            context={context}
            title={strings.title(formatFieldByName(REPORT_RECORD_NUMBER))}
            onClose={closeBox}
            onSave={changeEnabled ? handleSubmit : closeBox}
            contentStyle={{ minHeight: minModalContentHeight }}
            okText={changeEnabled ? undefined : ''}
        >
            <RecordNumberChangeForm
                initialValues={{
                    currentRecordNumber: currentReport ? currentReport.recordNumber : '',
                    newRecordNumber: '',
                }}
                reportDefinition={reportDefinition}
                recordSequenceType={recordSequenceType}
            />
        </Modal>
    );
}

const selectors = createStructuredSelector({
    reportDefinition: createModalSelector(context, 'reportDefinition'),
    recordSequenceType: createModalSelector(context, 'recordSequenceType'),
    ui: recordNumberChangeUiSelector,
    form: recordNumberChangeFormSelector,
    currentReport: currentReportSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = (dispatch) => ({
    saveBoxHalt: () => dispatch(saveBoxHalt(context)),
    closeBox: () => {
        dispatch(clearRecordNumberChangeModal());
        dispatch(closeBox(context));
    },
});

export default connect(selectors, mapDispatchToProps)(RecordNumberChangeModal);

import { EntityTypeEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import { createSelector } from 'reselect';
import { difference } from 'lodash';
import { loadAttachmentsByEntityId } from '../../../attachments/state/data';

import { nowUtc } from '../../../../dates/utils/dateHelpers';
import {
    loadUserNotifications,
    pollForUserNotificationsSuccess,
    pollUserNotifications,
    userAlertNotificationIdsSelector,
} from '../data';
import {
    POLL_FOR_USER_NOTIFICATIONS_INTERVAL,
    POLL_FOR_USER_NOTIFICATIONS_SIZE,
} from '../../configuration';

const BEGIN_POLLING_FOR_NOTIFICATIONS = 'cobalt-notifications/BEGIN_POLLING_FOR_NOTIFICATIONS';
const END_POLLING_FOR_NOTIFICATIONS = 'cobalt-notifications/END_POLLING_FOR_NOTIFICATIONS';
export const SET_PREVIOUS_POLL_DATE = 'cobalt-notifications/SET_PREVIOUS_POLL_DATE';

function setPreviousPollDate(pollDateUtc) {
    return { type: SET_PREVIOUS_POLL_DATE, payload: pollDateUtc };
}

/**
 * Poll for the most recent notifications for the current user. Continue polling
 *   as long as the user is logged in, as determined by the given selector.
 *   Errors are not handled.
 * @param  {function} options.userIsLoggedInSelector
 * @param  {function} options.onSuccess
 * @param  {function} options.onUnauthorizedError
 * @param  {function} options.UnauthorizedError
 * @return {Promise}
 */
function pollForUserNotifications({
    onSuccess,
    onUnauthorizedError,
    userIsLoggedInSelector,
    UnauthorizedError,
}) {
    return function (dispatch, getState) {
        const pollDateUtc = nowUtc();
        const nextPoll = () => {
            return Promise.delay(POLL_FOR_USER_NOTIFICATIONS_INTERVAL).then(() => {
                // continue polling only if the user is still logged in;
                // this state check is here and not beforehand because the
                // user can logout during the delay
                const state = getState();
                if (userIsLoggedInSelector(state)) {
                    dispatch(
                        pollForUserNotifications({
                            onSuccess,
                            onUnauthorizedError,
                            userIsLoggedInSelector,
                            UnauthorizedError,
                        })
                    );
                } else {
                    dispatch(endPollingForNotifications());
                }
                return;
            });
        };

        const cachedUserAlertNotificationIds = userAlertNotificationIdsSelector(getState());

        return dispatch(pollUserNotifications())
            .then((result) => {
                if (onSuccess) {
                    onSuccess({ dispatch, result });
                }

                dispatch(setPreviousPollDate(pollDateUtc));

                const currentUserAlertNotificationIds = userAlertNotificationIdsSelector(
                    getState()
                );
                const attachmentsToLoad = difference(
                    currentUserAlertNotificationIds,
                    cachedUserAlertNotificationIds
                );

                if (attachmentsToLoad.length > 0) {
                    dispatch(
                        loadAttachmentsByEntityId(
                            EntityTypeEnum.NOTIFICATION.name,
                            attachmentsToLoad,
                            {
                                hideLoadingBar: true,
                            }
                        )
                    );
                }

                // continue polling
                return nextPoll();
            })
            .catch(UnauthorizedError, onUnauthorizedError)
            .catch(() => {
                // silently swallow this error as it is not useful to show - the
                // user can't do anything about their new notifications not
                // loading
                return nextPoll();
            });
    };
}

function beginPollingForNotificationsRaw() {
    return {
        type: BEGIN_POLLING_FOR_NOTIFICATIONS,
    };
}

/**
 * Start polling for notifications. Until `endPollingForNotifications()` is
 *   dispatched, all subsequent dispatches of this action (which will happen on
 *   logout then login) do nothing.
 */
export function beginPollingForNotifications({
    onSuccess,
    onUnauthorizedError,
    userIsLoggedInSelector,
    UnauthorizedError,
}) {
    return function (dispatch, getState) {
        // initial load for Notifications Popover
        const pollDateUtc = nowUtc();
        dispatch(
            loadUserNotifications(
                {
                    includeRead: true,
                    includeUnread: true,
                    includeAcknowledged: true,
                    includeAlerts: true,
                    includeArchived: false,
                    includeWeb: true,
                    size: POLL_FOR_USER_NOTIFICATIONS_SIZE,
                },
                { hideLoadingBar: true }
            )
        )
            .then((result) => {
                dispatch(pollForUserNotificationsSuccess(result));
                dispatch(setPreviousPollDate(pollDateUtc));
            })
            .catch(() => {
                // silently swallow this error as it is not useful to show - the
                // user can't do anything about their new notifications not
                // loading
            });

        const state = getState();
        if (!pollingSelector(state)) {
            // prevent double hit with loadUserNotifications above
            setTimeout(() => {
                dispatch(beginPollingForNotificationsRaw());
                dispatch(
                    pollForUserNotifications({
                        onSuccess,
                        onUnauthorizedError,
                        userIsLoggedInSelector,
                        UnauthorizedError,
                    })
                );
            }, POLL_FOR_USER_NOTIFICATIONS_INTERVAL);
        }
    };
}

/**
 * Dispatch this action when notification polling has stopped.
 */
function endPollingForNotifications() {
    return {
        type: END_POLLING_FOR_NOTIFICATIONS,
    };
}

const notificationsPersistentSelector = (state) => state.persistent.notifications;

const pollingSelector = createSelector(notificationsPersistentSelector, ({ polling }) => polling);

/**
 * Persistent state for notifications keeps track of whether polling has begun.
 *   Logging out does not change the fact that polling will continue after the
 *   next login.
 */
export default function notificationsPersistentReducer(
    state = {
        polling: false,
    },
    action
) {
    switch (action.type) {
        case BEGIN_POLLING_FOR_NOTIFICATIONS:
            return {
                ...state,
                polling: true,
            };
        case END_POLLING_FOR_NOTIFICATIONS:
            return {
                ...state,
                polling: false,
            };
        default:
            return state;
    }
}

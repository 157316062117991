import { SubmissionMessageReducerTypeT } from '../../../types';
import { SubmissionMessageActionTypesT, actionTypes } from './actions';

export * from './actions';

export const initialState: SubmissionMessageReducerTypeT = {
    submissionMessageViews: [],
};

export const submissionsMessagesReducer = (
    state: SubmissionMessageReducerTypeT,
    action: SubmissionMessageActionTypesT
): SubmissionMessageReducerTypeT => {
    switch (action.type) {
        case actionTypes.SET_SUBMISSION_MESSAGES:
            return {
                ...state,
                submissionMessageViews: [
                    ...state.submissionMessageViews,
                    ...action.payload.submissionMessageViews,
                ],
            };

        default:
            return state;
    }
};

import React from 'react';
import { take } from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import TaskDescriptionCell from '../../../../legacy-redux/components/core/tables/caseTasksTable/TaskDescriptionCell';
import TaskStatusCell from '../../../../legacy-redux/components/core/tables/caseTasksTable/TaskStatusCell';
import TaskDueDateCell from '../../../../legacy-redux/components/core/tables/caseTasksTable/TaskDueDateCell';
import TaskAssignedToCell from '../../../../legacy-redux/components/core/tables/caseTasksTable/TaskAssignedToCell';
import TaskTable, { columnKeys } from '../../../tasks/core/components/TaskTable';
import { currentCaseSummaryTasksSelector } from '../state/ui';

const strings = componentStrings.cases.caseSummary.CaseTasksTable;

const MAX_TASKS_SHOWN = 100;

const columnConfigurations = [
    { key: columnKeys.title },
    { key: columnKeys.source, width: 300 },
    { key: columnKeys.status, width: 300 },
    { key: columnKeys.assigneeRoleId, width: 210 },
    { key: columnKeys.dueDateUtc, width: 200 },
];

const _CaseTasksTableVersionOne = ({ caseTasks, router }) => {
    return (
        <Table
            data={take(caseTasks, MAX_TASKS_SHOWN)}
            onRowClick={({ ownerId }) => {
                router.push(`cases/${ownerId}/task-list`);
            }}
            noRowsText={strings.noResult}
            simpleStyles={false}
            className="case-tasks-table"
            tableColumnsClassName="no-top-border"
        >
            <TableHeader>
                <TableColumns>
                    <TableColumn
                        display={strings.columns.description}
                        columnKey="description"
                        width={324}
                    />
                    <TableColumn display={strings.columns.status} columnKey="status" width={120} />
                    <TableColumn
                        display={strings.columns.dueDate}
                        columnKey="dueDate"
                        width={120}
                    />
                    <TableColumn
                        display={strings.columns.assignedTo}
                        columnKey="assignedTo"
                        width={200}
                    />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <TaskDescriptionCell columnKey="description" />
                <TaskStatusCell columnKey="status" />
                <TaskDueDateCell columnKey="dueDate" />
                <TaskAssignedToCell columnKey="assignedTo" />
            </TableBody>
        </Table>
    );
};
const CaseTasksTableVersionOne = withRouter(_CaseTasksTableVersionOne);

const ConnectedTaskTable = connect(
    createStructuredSelector({ tasks: currentCaseSummaryTasksSelector })
)(TaskTable);

function CaseTasksTable({ caseTasks }) {
    return (
        <FeatureFlagged
            flag="RMS_TASK_AND_REQUEST_TRACKING_ENABLED"
            fallback={<CaseTasksTableVersionOne caseTasks={caseTasks} />}
        >
            <ConnectedTaskTable
                canEdit={false}
                canDelete={false}
                columnConfigurations={columnConfigurations}
            />
        </FeatureFlagged>
    );
}

export default CaseTasksTable;

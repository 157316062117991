import { IOverlayStore } from 'overlay-manager/lib/store';
import { useCallback, useEffect } from 'react';
import { useOverlayStore } from './useOverlayStore';

interface UseOverlayResourceProps<Response, CustomProperties extends Record<string, unknown>> {
    id: string;
    resource: (...paramsForResourceCall: unknown[]) => Promise<Response>;
    onSuccess?: (result: Response, overlayStore: IOverlayStore<CustomProperties>) => void;
}

export function useOverlayResource<
    Response = unknown,
    CustomProperties extends Record<string, unknown> = Record<string, never>
>({ id, onSuccess, resource }: UseOverlayResourceProps<Response, CustomProperties>) {
    const overlayStore = useOverlayStore<CustomProperties>();

    const overlayResource = useCallback(
        (...paramsForResourceCall: unknown[]) => {
            if (!overlayStore) {
                return;
            }

            overlayStore.setLoading(id, true);

            return resource(...paramsForResourceCall)
                .then((result) => {
                    if (onSuccess) {
                        onSuccess(result, overlayStore);
                    }
                })
                .catch((error) => overlayStore.setError(error.message))
                .finally(() => overlayStore.setLoading(id, false));
        },
        [id, onSuccess, overlayStore, resource]
    );

    return overlayResource;
}

export function useOverlayResourceOnOpen<
    Response = unknown,
    CustomProperties extends Record<string, unknown> = Record<string, never>
>(props: UseOverlayResourceProps<Response, CustomProperties>) {
    const overlayResource = useOverlayResource(props);

    useEffect(() => {
        overlayResource();
    }, [overlayResource]);
}

import _ from 'lodash';
import { DefaultTheme } from 'styled-components';
import { cssVar, theme as arcTheme } from 'arc';
import rmsTheme from '../styles/theme';

type arcColors = Extract<Parameters<typeof cssVar>[0], `arc.colors.${string}`>;
type rmsColors = keyof typeof rmsTheme.colors;
export type ThemeColorsT = rmsColors | arcColors;

const isRmsThemeColor = (
    maybeRmsThemeColor: string
): maybeRmsThemeColor is keyof typeof rmsTheme.colors => {
    return maybeRmsThemeColor in rmsTheme.colors;
};

const arcThemeLocal = { arc: arcTheme };

const isArcToken = (maybeArcToken: string): maybeArcToken is arcColors => {
    return !!_.get(arcThemeLocal, maybeArcToken);
};

/** Use this in a component to accept a color value from a consumer and properly render the
 * matching RMS theme color or ARC cssVar or render a raw string if neither match.
 * Use the `ThemeColorsT` type on your prop to restrict raw values.
 */
const getThemeColor = (theme: DefaultTheme, colorValue: ThemeColorsT | string) => {
    if (isRmsThemeColor(colorValue)) {
        return theme.colors[colorValue];
    }
    if (isArcToken(colorValue)) {
        return cssVar(colorValue);
    }
    return colorValue;
};

export default getThemeColor;

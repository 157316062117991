import _ from 'lodash';

/**
 * Helper to prefix all constants for action types to avoid naming conflicts.
 *   The current convention is for `prefix` to be lowercase and `constants` to
 *   be CONSTANT_CASE.
 */
export default function actionTypesFactory(prefix: string, constants: string[]) {
    return _(constants)
        .mapKeys()
        .mapValues((name) => `${prefix}_${name}`)
        .value();
}

import React from 'react';
import { reduxForm } from 'redux-form-mark43';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { trim } from 'lodash';
import styled from 'styled-components';

import { reportShortTitleViewModelsWhereSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import stringsConfig from '~/client-common/core/strings';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import { currentUserDepartmentIdSelector } from '../../../modules/core/current-user/state/ui';

import {
    validateRenUpdate,
    updateRen,
    storeHandleSubmit,
    disableRenUpdate,
} from '../../actions/renChangeActions';
import { saveBoxHalt } from '../../actions/boxActions';
import { renChangeUiSelector, newRenFieldSelector } from '../../selectors/renChangeSelectors';
import { currentReportSelector } from '../../selectors/reportSelectors';
import { renChangeValidator } from '../../validation/components/renChangeValidators';
import { renChangeFormFieldList } from '../../configs/renChangeConfig';

import Text from '../../../modules/core/forms/components/Text';
import Row from '../../../modules/core/components/Row';
import FormRow from '../../../modules/core/forms/components/FormRow';

import asyncValidatorFromSyncValidator from '../../validation/helpers/asyncValidatorFromSyncValidator';
import testIds from '../../../core/testIds';
import LinkedReportsSection from './LinkedReportsSection';

const strings = stringsConfig.components.reports.RenChangeForm;
const labels = strings.labels;

const context = {
    name: boxEnum.REN_CHANGE_MODAL,
};

const StyledRow = styled(Row)`
    margin-bottom: var(--arc-space-2);
`;

class RenChangeForm extends React.Component {
    componentDidMount() {
        this.props.storeHandleSubmit(this.props.handleSubmit.bind(this));
    }

    render() {
        const { ui, reportShortTitleViewModelsWhere, fields } = this.props;

        const renDisplayName = this.props.formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
        const reportShortTitleViewModels = reportShortTitleViewModelsWhere({
            reportingEventNumber: ui.validRen,
            departmentId: this.props.currentUserDepartmentId,
        });

        const reportsUnderRen = reportShortTitleViewModels.length > 0 && (
            <LinkedReportsSection
                title={labels.reportsUnderRen(renDisplayName, ui.validRen)}
                reportShortTitleViewModels={reportShortTitleViewModels}
            />
        );

        return (
            <div>
                <FormRow>
                    {fields.currentRen.value && (
                        <Text
                            length="md"
                            label={labels.currentRen(renDisplayName)}
                            disabled={true}
                            {...fields.currentRen}
                        />
                    )}
                    <Text
                        length="md"
                        label={renDisplayName}
                        goButton={true}
                        loading={ui.loading}
                        goButtonText={labels.validateButton}
                        onClick={this.props.onValidate}
                        onPressEnter={this.props.onValidate}
                        alterValue={(value) => trim(value)}
                        {...fields.newRen}
                    />
                </FormRow>
                <StyledRow testId={testIds.MODAL_MESSAGE}>
                    <h5>{ui.message}</h5>
                </StyledRow>
                {reportsUnderRen}
            </div>
        );
    }
}

const reduxFormConfig = {
    form: 'renChange',
    fields: renChangeFormFieldList,
    validate: renChangeValidator,
};

const selectors = createStructuredSelector({
    ui: renChangeUiSelector,
    reportShortTitleViewModelsWhere: reportShortTitleViewModelsWhereSelector,
    newRenField: newRenFieldSelector,
    currentReport: currentReportSelector,
    formatFieldByName: formatFieldByNameSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
});

const mapDispatchToProps = (dispatch, props) => ({
    storeHandleSubmit(handleSubmit) {
        dispatch(storeHandleSubmit(handleSubmit));
    },
    onValidate() {
        return asyncValidatorFromSyncValidator(
            renChangeValidator(
                {
                    newRen: props.newRenField.value,
                    currentRen: props.currentReport.reportingEventNumber,
                },
                props
            )
        )
            .then(() => {
                const report = props.currentReport;
                const newRenField = props.newRenField;
                if (newRenField) {
                    dispatch(
                        validateRenUpdate(
                            {
                                report,
                                newRen: newRenField.value,
                            },
                            props
                        )
                    );
                }
            })
            .catch((err) => {
                dispatch(disableRenUpdate(err._error));
            });
    },
    onSubmit(data) {
        /*
         * do validation on submit also, just in case someone manages to fire
         * off an invalidate request
         */
        if (props.newRenField.value !== props.ui.validRen) {
            dispatch(
                disableRenUpdate(
                    strings.changedSinceValidationError(
                        props.formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
                    )
                )
            );
            dispatch(saveBoxHalt(context));
        }
        return asyncValidatorFromSyncValidator(
            renChangeValidator({ newRen: data.newRen, currentRen: data.currentRen }, props)
        )
            .then(() => {
                return dispatch(updateRen(props.currentReport.id, props.ui.validRen));
            })
            .catch((err) => {
                dispatch(disableRenUpdate(err._error));
                dispatch(saveBoxHalt(context));
            });
    },
});

RenChangeForm = reduxForm(reduxFormConfig, selectors, mapDispatchToProps)(RenChangeForm);

// this extra connection is needed because redux-form as of v4.0.3 provides to
// `mapDispatchToProps` only the props in `reduxFormConfig`, but we need a
// custom prop there
export default connect(selectors, mapDispatchToProps)(RenChangeForm);

import { AttributeTypeEnum, EntityTypeEnum, ProductModuleEnum } from '@mark43/rms-api';
import { flatMap, parseInt } from 'lodash';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { NEXUS_STATE_PROP as PERSON_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-profiles/state/data';
import { NEXUS_STATE_PROP as ORGANIZATION_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/organization-profiles/state/data';
import { NEXUS_STATE_PROP as EMPLOYMENT_HISTORIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/employment-histories/state/data';
import { NEXUS_STATE_PROP as SCHOOL_HISTORIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/school-histories/state/data';
import { NEXUS_STATE_PROP as IDENTIFYING_MARKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/identifying-marks/state/data';
import { NEXUS_STATE_PROP as NAME_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-attributes/state/data';
import { NEXUS_STATE_PROP as ITEM_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-attributes/state/data';
import { NEXUS_STATE_PROP as NAME_PHONES_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-phones/state/data';
import { NEXUS_STATE_PROP as NAME_IDENTIFIERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-identifiers/state/data';
import { NEXUS_STATE_PROP as NAME_EMAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-emails/state/data';
import { NEXUS_STATE_PROP as NAME_MONIKERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-monikers/state/data';
import { NEXUS_STATE_PROP as IMAGES_NEXUS_STATE_PROP } from '~/client-common/core/domain/images/state/data';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/attachments/state/data';
import { NEXUS_STATE_PROP as FIREARMS_NEXUS_STATE_PROP } from '~/client-common/core/domain/firearms/state/data';
import { NEXUS_STATE_PROP as FIREARM_ATF_MANUFACTURERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/etrace-atf/state/data';
import {
    loadAllItemProfilesForMasterItemIds,
    NEXUS_STATE_PROP as ITEM_PROFILES_NEXUS_STATE_PROP,
    loadHydratedItems,
} from '~/client-common/core/domain/item-profiles/state/data';
import { NEXUS_STATE_PROP as ITEM_IDENTIFIERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-identifiers/state/data';
import { loadReportLevelStaffRemarksForItemProfiles } from '~/client-common/core/domain/staff-remarks/state/data';
import { NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-report-links/state/data';
import { NEXUS_STATE_PROP as ELASTIC_REPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-reports/state/data';
import { NEXUS_STATE_PROP as ELASTIC_ORGANIZATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-organizations/state/data';
import { NEXUS_STATE_PROP as ELASTIC_VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-vehicles/state/data';
import { NEXUS_STATE_PROP as ELASTIC_PROPERTY_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-property/state/data';
import { NEXUS_STATE_PROP as ELASTIC_PERSONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-persons/state/data';
import { NEXUS_STATE_PROP as ELASTIC_WARRANTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-warrants/state/data';
import { NEXUS_STATE_PROP as VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicles/state/data';
import { NEXUS_STATE_PROP as NAME_ITEM_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-item-links/state/data';
import { NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/locations/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/location-entity-links/state/data';
import { NEXUS_STATE_PROP as LEGACY_ENTITY_DETAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/legacy-entity-details/state/data';
import { NEXUS_STATE_PROP as WARRANTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrants/state/data';
import { NEXUS_STATE_PROP as PERSON_EMERGENCY_CONTACTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-emergency-contacts/state/data';
import { NEXUS_STATE_PROP as PASSPORT_NEXUS_STATE_PROP } from '~/client-common/core/domain/passports/state/data';
import { NEXUS_STATE_PROP as PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-gang-trackings/state/data';
import { NEXUS_STATE_PROP as PROPERTY_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/property-statuses/state/data';
import { NEXUS_STATE_PROP as VEHICLE_MAKES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicle-makes/state/data';
import { NEXUS_STATE_PROP as VEHICLE_MODELS_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicle-models/state/data';
import { NEXUS_STATE_PROP as CAUTIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/cautions/state/data';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';

import { storeConfigForRelatedRecordsAndEntities } from '~/client-common/helpers/multiAgencyHelpers';
import {
    profileReportTypesDeleteWhere,
    profileReportTypesStore,
} from '~/client-common/core/domain/profile-report-types/state/data';
import { entityTypeParamToEnum } from '~/client-common/helpers/entityProfileHelpers';
import { setNameAttributes } from '../../../../../legacy-redux/actions/nameAttributesActions';
import { setNameNameLinks } from '../../../../../legacy-redux/actions/nameNameLinksActions';
import entityProfilesResource from '../../resources/entityProfilesResource';
import {
    currentEntityProfileDepartmentIdSelector,
    currentEntityProfileIsMasterProfileSelector,
    LOAD_ENTITY_PROFILE_BOOKINGS_START,
    LOAD_ENTITY_PROFILE_BOOKINGS_FAILURE,
    LOAD_ENTITY_PROFILE_BOOKINGS_SUCCESS,
    LOAD_ENTITY_PROFILE_FAILURE,
    LOAD_ENTITY_PROFILE_LOCATIONS_SUCCESS,
    LOAD_ENTITY_PROFILE_ORGANIZATIONS_SUCCESS,
    LOAD_ENTITY_PROFILE_PERSONS_SUCCESS,
    LOAD_ENTITY_PROFILE_PROPERTY_SUCCESS,
    LOAD_ENTITY_PROFILE_REPORTS_FAILURE,
    LOAD_ENTITY_PROFILE_REPORTS_START,
    LOAD_ENTITY_PROFILE_REPORTS_SUCCESS,
    LOAD_ENTITY_PROFILE_START,
    LOAD_ENTITY_PROFILE_SUCCESS,
    LOAD_ENTITY_PROFILE_VEHICLES_SUCCESS,
    LOAD_ENTITY_PROFILE_WARRANTS_SUCCESS,
} from '../ui';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { loadEvidenceItem, loadEvidenceItems } from '../../../../evidence/core/state/data';

function loadEntityProfileStart() {
    return {
        type: LOAD_ENTITY_PROFILE_START,
    };
}

function loadEntityProfileSuccess(entityType, entityId, entityProfile) {
    return {
        type: LOAD_ENTITY_PROFILE_SUCCESS,
        payload: {
            entityType,
            entityId,
            entityProfile,
        },
    };
}

function loadEntityProfileReportsStart() {
    return { type: LOAD_ENTITY_PROFILE_REPORTS_START };
}

function loadEntityProfileReportsFailure(errorMessage) {
    return {
        type: LOAD_ENTITY_PROFILE_REPORTS_FAILURE,
        payload: { errorMessage },
    };
}

function loadEntityProfileReportsSuccess(from, size, totalCount, reports) {
    return {
        type: LOAD_ENTITY_PROFILE_REPORTS_SUCCESS,
        payload: { from, size, totalCount, reports },
    };
}

function loadEntityProfileBookingsStart() {
    return { type: LOAD_ENTITY_PROFILE_BOOKINGS_START };
}

function loadEntityProfileBookingsFailure(errorMessage) {
    return {
        type: LOAD_ENTITY_PROFILE_BOOKINGS_FAILURE,
        payload: { errorMessage },
    };
}

function loadEntityProfileBookingsSuccess(from, size, totalCount, bookings) {
    return {
        type: LOAD_ENTITY_PROFILE_BOOKINGS_SUCCESS,
        payload: { from, size, totalCount, bookings },
    };
}

function loadEntityProfileLocationsSuccess(profileLocations) {
    return {
        type: LOAD_ENTITY_PROFILE_LOCATIONS_SUCCESS,
        payload: profileLocations,
    };
}

function loadEntityProfilePersonsSuccess(persons) {
    return {
        type: LOAD_ENTITY_PROFILE_PERSONS_SUCCESS,
        payload: persons,
    };
}

function loadEntityProfileOrganizationsSuccess(organizations) {
    return {
        type: LOAD_ENTITY_PROFILE_ORGANIZATIONS_SUCCESS,
        payload: organizations,
    };
}

function loadEntityProfilePropertySuccess(property) {
    return {
        type: LOAD_ENTITY_PROFILE_PROPERTY_SUCCESS,
        payload: property,
    };
}

function loadEntityProfileVehiclesSuccess(vehicles) {
    return {
        type: LOAD_ENTITY_PROFILE_VEHICLES_SUCCESS,
        payload: vehicles,
    };
}

function loadEntityProfileWarrantsSuccess(warrants) {
    return {
        type: LOAD_ENTITY_PROFILE_WARRANTS_SUCCESS,
        payload: warrants,
    };
}

function loadEntityProfileFailure(errorMessage) {
    return {
        type: LOAD_ENTITY_PROFILE_FAILURE,
        payload: errorMessage,
    };
}

const PERSON_RESOURCE_METHODS = {
    profile: entityProfilesResource.getPersonProfile,
    reports: entityProfilesResource.getPersonProfileReports,
    reportTypes: entityProfilesResource.getPersonProfileReportsTypes,
    locations: entityProfilesResource.getPersonProfileLocations,
    persons: entityProfilesResource.getPersonProfilePersons,
    organizations: entityProfilesResource.getPersonProfileOrganizations,
    property: entityProfilesResource.getPersonProfileProperty,
    vehicles: entityProfilesResource.getPersonProfileVehicles,
    warrants: entityProfilesResource.getPersonProfileWarrants,
    bookings: entityProfilesResource.getPersonProfileBookings,
};

const ORGANIZATION_RESOURCE_METHODS = {
    profile: entityProfilesResource.getOrganizationProfile,
    reports: entityProfilesResource.getOrganizationProfileReports,
    locations: entityProfilesResource.getOrganizationProfileLocations,
    persons: entityProfilesResource.getOrganizationProfilePersons,
    organizations: entityProfilesResource.getOrganizationProfileOrganizations,
    property: entityProfilesResource.getOrganizationProfileProperty,
    vehicles: entityProfilesResource.getOrganizationProfileVehicles,
};

const PROPERTY_RESOURCE_METHODS = {
    profile: entityProfilesResource.getPropertyProfile,
    reports: entityProfilesResource.getPropertyProfileReports,
    locations: entityProfilesResource.getPropertyProfileLocations,
    persons: entityProfilesResource.getPropertyProfilePersons,
    organizations: entityProfilesResource.getPropertyProfileOrganizations,
};

const VEHICLE_RESOURCE_METHODS = {
    profile: entityProfilesResource.getVehicleProfile,
    reports: entityProfilesResource.getVehicleProfileReports,
    reportTypes: entityProfilesResource.getVehicleProfileReportsTypes,
    locations: entityProfilesResource.getVehicleProfileLocations,
    persons: entityProfilesResource.getVehicleProfilePersons,
    organizations: entityProfilesResource.getVehicleProfileOrganizations,
};

const ENTITY_TYPE_TO_RESOURCES = {
    persons: PERSON_RESOURCE_METHODS,
    organizations: ORGANIZATION_RESOURCE_METHODS,
    property: PROPERTY_RESOURCE_METHODS,
    vehicles: VEHICLE_RESOURCE_METHODS,
    locations: {},
};

export function loadEntityProfile(entityType, entityId, { fetchRelated = true } = {}) {
    return (dispatch, getState, dependencies) => {
        const storeConfig = storeConfigForRelatedRecordsAndEntities(dispatch);
        dispatch(loadEntityProfileStart());
        return ENTITY_TYPE_TO_RESOURCES[entityType]
            .profile(entityId)
            .then(storeConfig)
            .then((entityProfile) => {
                const { profile, legacyEntityDetails } = entityProfile;
                const entityData = {
                    [LEGACY_ENTITY_DETAILS_NEXUS_STATE_PROP]: legacyEntityDetails,
                };

                // When this file gets converted to TS, entityType should be of type EntityTypeEnum and all usages
                // should be updated.
                const isPerson = entityType === 'persons';
                const isVehicle = entityType === 'vehicles';
                const isOrganizations = entityType === 'organizations';
                const isProperty = entityType === 'property';

                let successAction = loadEntityProfileSuccess(entityType, entityId, entityProfile);

                if (isPerson || isOrganizations) {
                    successAction = dependencies.nexus.withRemoveMultiple(
                        {
                            [ATTACHMENTS_NEXUS_STATE_PROP]: {
                                entityType: isPerson
                                    ? EntityTypeEnum.PERSON_PROFILE.name
                                    : EntityTypeEnum.ORGANIZATION_PROFILE.name,
                                entityId: parseInt(entityId),
                            },
                            [NAME_ATTRIBUTES_NEXUS_STATE_PROP]: {
                                entityType: isPerson
                                    ? EntityTypeEnum.PERSON_PROFILE.name
                                    : EntityTypeEnum.ORGANIZATION_PROFILE.name,
                                nameId: parseInt(entityId),
                            },
                            [ITEM_ATTRIBUTES_NEXUS_STATE_PROP]: {
                                itemProfileId: parseInt(entityId),
                                // Only remove Vehicle Label Attributes for this entity.
                                attributeType: AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name,
                            },
                        },
                        successAction
                    );
                    entityData[ATTACHMENTS_NEXUS_STATE_PROP] = profile.attachments;
                    entityData[NAME_MONIKERS_NEXUS_STATE_PROP] = profile.monikers;
                    entityData[NAME_IDENTIFIERS_NEXUS_STATE_PROP] = profile.identifiers;
                    entityData[NAME_EMAILS_NEXUS_STATE_PROP] = profile.emails;
                    entityData[NAME_PHONES_NEXUS_STATE_PROP] = profile.phones;
                    entityData[LOCATIONS_NEXUS_STATE_PROP] = flatMap(profile.locations, 'location');
                    entityData[LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP] = flatMap(
                        profile.locations,
                        'entityLinks'
                    );

                    if (isPerson) {
                        entityData[NAME_ATTRIBUTES_NEXUS_STATE_PROP] = profile.nameAttributes;
                        entityData[IDENTIFYING_MARKS_NEXUS_STATE_PROP] = profile.identifyingMarks;
                        entityData[PERSON_EMERGENCY_CONTACTS_NEXUS_STATE_PROP] =
                            profile.personEmergencyContacts;
                        entityData[PASSPORT_NEXUS_STATE_PROP] = profile.passports;
                        entityData[EMPLOYMENT_HISTORIES_NEXUS_STATE_PROP] =
                            profile.employmentHistories;
                        entityData[SCHOOL_HISTORIES_NEXUS_STATE_PROP] = profile.schoolHistories;
                        entityData[IMAGES_NEXUS_STATE_PROP] = profile.images;
                        entityData[PERSON_PROFILES_NEXUS_STATE_PROP] = [profile.personProfile];
                        entityData[WARRANTS_NEXUS_STATE_PROP] = profile.activeWarrants;
                        entityData[PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP] =
                            profile.personGangTrackings;
                    } else if (isOrganizations) {
                        entityData[ORGANIZATION_PROFILES_NEXUS_STATE_PROP] = [
                            profile.organizationProfile,
                        ];
                    }

                    dispatch(setNameAttributes(profile.nameAttributes));
                }

                if (isProperty || isVehicle) {
                    entityData[ITEM_PROFILES_NEXUS_STATE_PROP] = [profile.item];
                    entityData[ITEM_IDENTIFIERS_NEXUS_STATE_PROP] = profile.itemIdentifiers;
                    entityData[PROPERTY_STATUSES_NEXUS_STATE_PROP] = profile.propertyStatuses;

                    if (isProperty && profile.firearm) {
                        entityData[FIREARMS_NEXUS_STATE_PROP] = [profile.firearm];
                        entityData[FIREARM_ATF_MANUFACTURERS_NEXUS_STATE_PROP] =
                            profile.atfManufacturers;
                    } else if (isVehicle) {
                        entityData[VEHICLES_NEXUS_STATE_PROP] = [profile.vehicle];
                        entityData[VEHICLE_MAKES_NEXUS_STATE_PROP] = profile.vehicleMakes;
                        entityData[VEHICLE_MODELS_NEXUS_STATE_PROP] = profile.vehicleModels;
                        entityData[ITEM_ATTRIBUTES_NEXUS_STATE_PROP] = profile.attributeLinks;
                    }
                }

                if (isPerson || isVehicle) {
                    entityData[CAUTIONS_NEXUS_STATE_PROP] = profile.cautions;
                }

                dispatch(dependencies.nexus.withEntityItems(entityData, successAction));

                if (fetchRelated) {
                    dispatch(fetchAndStoreRelatedData(entityId, entityType));
                }
                const state = getState();

                // warrants
                if (ENTITY_TYPE_TO_RESOURCES[entityType].warrants) {
                    ENTITY_TYPE_TO_RESOURCES[entityType]
                        .warrants(entityId)
                        .then(storeConfig)
                        .then(({ elasticWarrants }) =>
                            dispatch(
                                dependencies.nexus.withEntityItems(
                                    {
                                        [ELASTIC_WARRANTS_NEXUS_STATE_PROP]: elasticWarrants,
                                    },
                                    loadEntityProfileWarrantsSuccess(elasticWarrants)
                                )
                            )
                        );
                }
                const isMasterProfile = currentEntityProfileIsMasterProfileSelector(state);
                const profileDepartmentId = currentEntityProfileDepartmentIdSelector(state);

                // if the property and vehicle is evidence, it needs additional data from the server
                if (
                    entityTypeParamToEnum[entityType] === EntityTypeEnum.ITEM_PROFILE.name &&
                    isMasterProfile &&
                    currentUserHasViewGeneralAccessToEvidence(state)
                ) {
                    // load EvidenceHydratedItem to display chain of custody and staff remarks
                    dispatch(loadEvidenceItem(entityId));

                    if (
                        currentUserHasAbilitySelector(state)(
                            abilitiesEnum.EVIDENCE.VIEW_STAFF_REMARKS
                        )
                    ) {
                        // need itemProfileIds in order to group their item-level staff remarks
                        // under each REN
                        dispatch(loadAllItemProfilesForMasterItemIds([entityId])).then(
                            (itemProfiles) => {
                                dispatch(loadReportLevelStaffRemarksForItemProfiles(itemProfiles));
                            }
                        );
                    }
                }

                return {
                    isMaster: isMasterProfile,
                    profileDepartmentId,
                };
            })
            .catch((err) => {
                dispatch(loadEntityProfileFailure(err.message));
                // error handling is done downstream, so rethrow the error
                throw err;
            });
    };
}

export function fetchAndStoreRelatedReports(
    entityType,
    entityId,
    { from, size, sortKey, sortType },
    data
) {
    return (dispatch, getState, dependencies) => {
        if (ENTITY_TYPE_TO_RESOURCES[entityType].reports) {
            dispatch(loadEntityProfileReportsStart());
            ENTITY_TYPE_TO_RESOURCES[entityType]
                .reports(entityId, { from, size, sort_key: sortKey, sort_type: sortType }, data)
                .then(storeConfigForRelatedRecordsAndEntities(dispatch))
                .then(({ totalCount, elasticReports, nameReportLinks }) =>
                    dispatch(
                        dependencies.nexus.withEntityItems(
                            {
                                [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinks,
                                [ELASTIC_REPORTS_NEXUS_STATE_PROP]: elasticReports,
                            },
                            loadEntityProfileReportsSuccess(from, size, totalCount, elasticReports)
                        )
                    )
                )
                .catch((err) => {
                    dispatch(loadEntityProfileReportsFailure('Failure searching for reports.'));
                    // error handling is done downstream, so rethrow the error
                    throw err;
                });
        }
    };
}

export function fetchAndStoreRelatedBookings(entityId, from, size) {
    return (dispatch) => {
        if (PERSON_RESOURCE_METHODS.bookings) {
            dispatch(loadEntityProfileBookingsStart());
            PERSON_RESOURCE_METHODS.bookings(entityId, from, size)
                .then(({ totalCount, items }) =>
                    dispatch(loadEntityProfileBookingsSuccess(from, size, totalCount, items))
                )
                .catch((err) => {
                    dispatch(loadEntityProfileBookingsFailure('Failure searching for bookings.'));
                    throw err;
                });
        }
    };
}

export function fetchAndStoreReportTypes(entityType, entityId) {
    return (dispatch) => {
        if (ENTITY_TYPE_TO_RESOURCES[entityType].reportTypes) {
            ENTITY_TYPE_TO_RESOURCES[entityType].reportTypes(entityId).then((reportTypes) => {
                dispatch(profileReportTypesDeleteWhere(() => true));
                dispatch(profileReportTypesStore(reportTypes));
            });
        }
    };
}

function fetchAndStoreRelatedData(entityId, entityType) {
    return (dispatch) => {
        dispatch(fetchAndStoreRelatedLocations(entityId, entityType));
        dispatch(fetchAndStoreRelatedNames(entityId, entityType));
        dispatch(fetchAndStoreRelatedProperty(entityId, entityType));
        dispatch(fetchAndStoreRelatedVehicles(entityId, entityType));
    };
}

function fetchAndStoreRelatedLocations(entityId, entityType) {
    return (dispatch) => {
        if (ENTITY_TYPE_TO_RESOURCES[entityType].locations) {
            ENTITY_TYPE_TO_RESOURCES[entityType]
                .locations(entityId)
                .then((profileLocations) =>
                    dispatch(loadEntityProfileLocationsSuccess(profileLocations))
                );
        }
    };
}

function fetchAndStoreRelatedNames(entityId, entityType) {
    return (dispatch, getState, dependencies) => {
        // name name links handled like this because they use old state
        // see nameNameLinksActions.js for details
        let allNameNameLinks = [];
        if (ENTITY_TYPE_TO_RESOURCES[entityType].persons) {
            ENTITY_TYPE_TO_RESOURCES[entityType]
                .persons(entityId)
                .then(storeConfigForRelatedRecordsAndEntities(dispatch))
                .then(({ elasticPersons, nameReportLinks, nameItemLinks, nameNameLinks }) => {
                    allNameNameLinks = [...allNameNameLinks, ...nameNameLinks];
                    dispatch(setNameNameLinks(allNameNameLinks));
                    dispatch(
                        dependencies.nexus.withEntityItems(
                            {
                                [ELASTIC_PERSONS_NEXUS_STATE_PROP]: elasticPersons,
                                [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinks,
                                [NAME_ITEM_LINKS_NEXUS_STATE_PROP]: nameItemLinks,
                            },
                            loadEntityProfilePersonsSuccess(elasticPersons)
                        )
                    );
                });
        }
        if (ENTITY_TYPE_TO_RESOURCES[entityType].organizations) {
            ENTITY_TYPE_TO_RESOURCES[entityType]
                .organizations(entityId)
                .then(storeConfigForRelatedRecordsAndEntities(dispatch))
                .then(({ elasticOrganizations, nameReportLinks, nameItemLinks, nameNameLinks }) => {
                    allNameNameLinks = [...allNameNameLinks, ...nameNameLinks];
                    dispatch(setNameNameLinks(allNameNameLinks));
                    dispatch(
                        dependencies.nexus.withEntityItems(
                            {
                                [ELASTIC_ORGANIZATIONS_NEXUS_STATE_PROP]: elasticOrganizations,
                                [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinks,
                                [NAME_ITEM_LINKS_NEXUS_STATE_PROP]: nameItemLinks,
                            },
                            loadEntityProfileOrganizationsSuccess(elasticOrganizations)
                        )
                    );
                });
        }
    };
}

function fetchAndStoreRelatedProperty(entityId, entityType) {
    return (dispatch, getState, dependencies) => {
        if (ENTITY_TYPE_TO_RESOURCES[entityType].property) {
            ENTITY_TYPE_TO_RESOURCES[entityType]
                .property(entityId)
                .then(storeConfigForRelatedRecordsAndEntities(dispatch))
                .then(({ elasticProperty, nameItemLinks }) => {
                    const dispatchStoreItems = () => {
                        dispatch(
                            dependencies.nexus.withEntityItems(
                                {
                                    [ELASTIC_PROPERTY_NEXUS_STATE_PROP]: elasticProperty,
                                    [NAME_ITEM_LINKS_NEXUS_STATE_PROP]: nameItemLinks,
                                },
                                loadEntityProfilePropertySuccess(elasticProperty)
                            )
                        );
                    };
                    if (!currentUserHasViewGeneralAccessToEvidence(getState())) {
                        dispatchStoreItems();
                        return;
                    }
                    const masterIds = elasticProperty.map(({ masterItemId }) => masterItemId);
                    dispatch(loadAllItemProfilesForMasterItemIds(masterIds)).then((data) => {
                        const contextedItemIds = data.map(({ id }) => id);
                        dispatch(loadHydratedItems(contextedItemIds)).then(() => {
                            dispatch(loadEvidenceItems(contextedItemIds)).then(dispatchStoreItems);
                        });
                    });
                });
        }
    };
}

function fetchAndStoreRelatedVehicles(entityId, entityType) {
    return (dispatch, getState, dependencies) => {
        if (ENTITY_TYPE_TO_RESOURCES[entityType].vehicles) {
            ENTITY_TYPE_TO_RESOURCES[entityType]
                .vehicles(entityId)
                .then(storeConfigForRelatedRecordsAndEntities(dispatch))
                .then(({ elasticVehicles, nameItemLinks }) => {
                    const dispatchStoreVehicles = () => {
                        dispatch(
                            dependencies.nexus.withEntityItems(
                                {
                                    [ELASTIC_VEHICLES_NEXUS_STATE_PROP]: elasticVehicles,
                                    [NAME_ITEM_LINKS_NEXUS_STATE_PROP]: nameItemLinks,
                                },
                                loadEntityProfileVehiclesSuccess(elasticVehicles)
                            )
                        );
                    };
                    if (!currentUserHasViewGeneralAccessToEvidence(getState())) {
                        dispatchStoreVehicles();
                        return;
                    }
                    const masterIds = elasticVehicles.map(({ masterVehicleId }) => masterVehicleId);
                    dispatch(loadEvidenceItems(masterIds)).then(dispatchStoreVehicles);
                });
        }
    };
}

function currentUserHasViewGeneralAccessToEvidence(state) {
    return (
        isProductModuleActiveSelector(state)(ProductModuleEnum.EVIDENCE.name) &&
        currentUserHasAbilitySelector(state)(abilitiesEnum.EVIDENCE.VIEW_GENERAL)
    );
}

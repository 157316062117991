let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Admin Consortium Details Resource',
        methods: {
            getConsortiumDetails() {
                return req({
                    method: 'GET',
                    url: 'admin/consortium/details',
                });
            },
            addDepartment(consortiumId, departmentId) {
                return req({
                    method: 'PUT',
                    url: `admin/consortium/${consortiumId}/add/${departmentId}`,
                });
            },
            removeDepartment(consortiumId, departmentId) {
                return req({
                    method: 'DELETE',
                    url: `admin/consortium/${consortiumId}/remove/${departmentId}`,
                });
            },
            upsertConsortium(consortiumProfile) {
                return req({
                    method: 'PUT',
                    url: 'admin/consortium',
                    data: consortiumProfile,
                });
            },
            deleteConsortium(consortiumId) {
                return req({
                    method: 'DELETE',
                    url: `admin/consortium/${consortiumId}`,
                });
            },
            getDepartmentProfilesNotInConsortium() {
                return req({
                    method: 'GET',
                    url: 'admin/consortium/available_departments',
                });
            },
            grantPermissions(departmentPermissions) {
                return req({
                    method: 'POST',
                    url: 'admin/consortium/permissions/grant',
                    data: departmentPermissions,
                });
            },
            getPermissionStatus(departmentId) {
                return req({
                    method: 'GET',
                    url: `admin/consortium/permissions/status/${departmentId}`,
                });
            },
        },
    });
}

/**
 * Resources for consortium details. This is temporarily a
 *   function to avoid dependency problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import {
    ReportsResource,
    ReportApprovalStatusRequest,
    ReportIdentifierView,
} from '@mark43/rms-api';
import type { ReportShortTitleWrapper } from '~/client-common/core/domain/report-short-titles/types';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

type FindReportTitlesByReportIdentifier = ReportsResource.FindReportTitlesByReportIdentifier & {
    returns: {
        data?: ReportShortTitleWrapper;
        error?: string;
        success: boolean;
    };
};

export default createResource({
    name: 'Reports Resource',
    methods: {
        deleteReport(reportId: number) {
            return req<ReportsResource.DeleteReport>({
                method: 'DELETE',
                url: `reports/${reportId}/`,
            });
        },
        getFullReport(reportId: number) {
            return req<ReportsResource.GetFullReport>({
                method: 'GET',
                url: `reports/${reportId}/full`,
            });
        },
        getReportRelatedEntities(reportId: number) {
            return req<ReportsResource.GetReportRelatedEntities>({
                method: 'GET',
                url: `reports/${reportId}/related_entities`,
            });
        },
        findReportTitlesByReportIdentifier(
            reportIdentifierView: ReportIdentifierView
        ): Promise<ReportShortTitleWrapper> {
            return req<FindReportTitlesByReportIdentifier>({
                method: 'POST',
                url: 'reports/titles/report_identifier',
                data: reportIdentifierView,
            });
        },
        bulkTransitionReportApprovalStatus(
            // isValid is derived in Java
            reportApprovalStatusRequests: Omit<ReportApprovalStatusRequest, 'isValid'>[]
        ) {
            return req({
                method: 'POST',
                url: 'reports/submission/bulk',
                data: reportApprovalStatusRequests,
            });
        },
        purgeReport(reportId: number) {
            return req<ReportsResource.PurgeReport>({
                method: 'POST',
                url: `reports/${reportId}/purge`,
            });
        },
        canCreateStubOffense(reportingEventNumber: string) {
            return req<ReportsResource.CanCreateStubOffense>({
                method: 'GET',
                url: `reports/${reportingEventNumber}/can_create_stub_offense`,
            });
        },
    },
});

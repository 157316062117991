import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { max } from 'lodash';

import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import advancedSearchPersons from '../state/ui';
import StaleQueryNotificationBar from '../../core/components/StaleQueryNotificationBar';
import AdvancedSearchResultsTableHeader from '../../core/components/AdvancedSearchResultsTableHeader';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import ImageCell from '../../../../legacy-redux/components/core/tables/elasticPersonTable/ImageCell';
import BiographicalInfoCell from '../../../../legacy-redux/components/core/tables/elasticPersonTable/BiographicalInfoCell';
import IdentifiersCell from '../../../../legacy-redux/components/core/tables/elasticPersonTable/IdentifiersCell';
import LocationsCell from '../../../../legacy-redux/components/core/tables/LocationsCell';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';
import { savedSearchIsStale } from '../../core/utils/savedSearchIsStale';
import { useDashboardScroll } from '../../core/hooks/useDashboardScroll';
import useOnSaveSearchToggleHandler from '../../core/hooks/useOnSaveSearchToggleHandler';

const strings = componentStrings.search.AdvancedSearchPersonsResults;

// 107 is the height of the table header
// todo refactor to genericize
const ClippableSearchTable = clippable({ bottomBuffer: 107 })(Table);

function AdvancedSearchPersonsResults({
    // Current Query and Search Results
    results = [],
    query: { savedSearchId, from, size, sortKey: currentSortKey, sortType: currentSortType },
    totalCount: totalResults,

    // Table UI State
    tableLoading,
    searchFilters,
    selectedRows,
    highlightedRows,

    // Event Handlers
    handleRowClick,
    handleRowSelect,
    handleSortByOptionClick,
    handleSortBySortTypeClick,
    handlePaginationClick,
    handleSizeChange,

    // Saved Search
    savedSearchErrorMessage,
    savedSearches,

    selectableRows,
    router,
}) {
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: advancedSearchPersons,
        isScrollToElement: true,
    });
    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const onSaveSearchToggle = useOnSaveSearchToggleHandler(advancedSearchPersons);

    return (
        <div>
            {savedSearchIsStale(savedSearches, savedSearchId) && <StaleQueryNotificationBar />}
            <ClippableSearchTable
                data={results}
                onRowClick={handleRowClick(getCurrentScrollPosition)}
                sortKey={currentSortKey}
                sortType={currentSortType}
                noRowsText={strings.noResults}
                selectableRows={selectableRows}
                onSelectRow={handleRowSelect}
                selectedRows={selectedRows}
                disableBody={tableLoading}
                highlightedRows={highlightedRows}
                containerClassName="search-results-table"
                rowComponent={({ row, children, ...otherProps }) => (
                    <ElasticResultRow
                        row={row}
                        warning={row.hasPotentialActiveWarrant}
                        {...otherProps}
                    >
                        {children}
                    </ElasticResultRow>
                )}
                tableCheckboxMargin="10px"
            >
                <TableHeader>
                    <AdvancedSearchResultsTableHeader
                        from={from}
                        to={from + results.length}
                        searchSize={size}
                        onSizeChange={handleSizeChange}
                        totalResults={totalResults}
                        currentPage={currentPage}
                        searchFilters={searchFilters}
                        onPaginationClick={(lastPage, nextPage) =>
                            handlePaginationClick(nextPage, size)
                        }
                        isSearchSavable={true}
                        onSaveSearchToggleClick={onSaveSearchToggle}
                        savedSearchId={savedSearchId}
                        savedSearchErrorMessage={savedSearchErrorMessage}
                        savedSearches={savedSearches}
                        onSaveSearch={onSaveSearchToggle}
                        searchModule={advancedSearchPersons}
                    />
                    <TableColumns>
                        <OptionsTableColumn
                            display={strings.sortBy.label}
                            columnKey="mugshot"
                            activeValue={currentSortKey}
                            activeSortType={currentSortType}
                            onOptionClick={handleSortByOptionClick}
                            onSortTypeClick={handleSortBySortTypeClick}
                            width={130}
                            popoutWidth={160}
                        >
                            <OptionsTableColumnOption
                                display={strings.relevance.label}
                                value={sortKeyEnum.RELEVANCE}
                            />
                            <OptionsTableColumnOption
                                display={strings.firstName.label}
                                value={sortKeyEnum.PERSON_FIRST_NAME}
                                sortOptions={[
                                    {
                                        display: strings.firstName.ascendingSort,
                                        sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                    },
                                    {
                                        display: strings.firstName.descendingSort,
                                        sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                    },
                                ]}
                            />
                            <OptionsTableColumnOption
                                display={strings.lastName.label}
                                value={sortKeyEnum.PERSON_LAST_NAME}
                                sortOptions={[
                                    {
                                        display: strings.lastName.ascendingSort,
                                        sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                    },
                                    {
                                        display: strings.lastName.descendingSort,
                                        sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                    },
                                ]}
                            />
                            <OptionsTableColumnOption
                                display={strings.age.label}
                                value={sortKeyEnum.PERSON_DATE_OF_BIRTH}
                                sortOptions={[
                                    {
                                        display: strings.age.descendingSort,
                                        sortType: sortTypeEnum.AGE_OLDEST_TO_YOUNGEST,
                                    },
                                    {
                                        display: strings.age.ascendingSort,
                                        sortType: sortTypeEnum.AGE_YOUNGEST_TO_OLDEST,
                                    },
                                ]}
                            />
                            <OptionsTableColumnOption
                                display={strings.modifiedDate.label}
                                value={sortKeyEnum.PERSON_UPDATED_DATE_UTC}
                                sortOptions={[
                                    {
                                        display: strings.modifiedDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.modifiedDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                        </OptionsTableColumn>
                        <TableColumn
                            display={strings.biographicalInfo.label}
                            columnKey="biographicalInfo"
                            width={209}
                        />
                        <TableColumn
                            display={strings.identifiers.label}
                            columnKey="identifiers"
                            width={235}
                        />
                        <TableColumn
                            display={strings.locations.label}
                            columnKey="locations"
                            width={344}
                        />
                    </TableColumns>
                </TableHeader>
                <TableBody>
                    <ImageCell columnKey="mugshot" />
                    <BiographicalInfoCell columnKey="biographicalInfo" />
                    <IdentifiersCell columnKey="identifiers" />
                    <LocationsCell columnKey="locations" />
                </TableBody>
            </ClippableSearchTable>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    query: advancedSearchPersons.selectors.currentQuerySelector,
    highlightedRows: advancedSearchPersons.selectors.highlightedRowsSelector,
    results: advancedSearchPersons.selectors.currentResultsViewModelsSelector,
    totalCount: advancedSearchPersons.selectors.totalCountSelector,
    tableLoading: advancedSearchPersons.selectors.tableLoadingSelector,
    searchFilters: advancedSearchPersons.selectors.filtersSelector,
    selectedRows: advancedSearchPersons.selectors.selectedRowsSelector,
    savedSearchErrorMessage: advancedSearchPersons.selectors.loadSavedSearchErrorMessageSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleSortByOptionClick({ value, sortOptions }) {
        dispatch(
            advancedSearchPersons.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleRowClick(getCurrentScrollPosition) {
        return (elasticPerson, rowIndex) => {
            dispatch(
                advancedSearchPersons.actionCreators.openSearchResult(
                    elasticPerson,
                    rowIndex,
                    router,
                    getCurrentScrollPosition()
                )
            );
        };
    },
    handleRowSelect(newSelectedRows) {
        dispatch(advancedSearchPersons.actionCreators.selectRows(newSelectedRows));
    },
    handleSortBySortTypeClick(sortType) {
        dispatch(advancedSearchPersons.actionCreators.search({ sortType }));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            advancedSearchPersons.actionCreators.search({ from: nextPage * size - size, size })
        );
    },
    handleSizeChange(size) {
        dispatch(advancedSearchPersons.actionCreators.search({ from: 0, size }));
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AdvancedSearchPersonsResults);

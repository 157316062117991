import { CoreInstanceView, LocationEntityLink } from '@mark43/rms-api';
import { fromPairs } from 'lodash';
import { NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/name-report-links/state/data';
import { NEXUS_STATE_PROP as USE_OF_FORCES_NEXUS_STATE_PROP } from '~/client-common/core/domain/use-of-forces/state/data';
import { NEXUS_STATE_PROP as OFFENSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offenses/state/data';
import { NEXUS_STATE_PROP as PERSON_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-profiles/state/data';
import { NEXUS_STATE_PROP as ORGANIZATION_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/organization-profiles/state/data';
import { NEXUS_STATE_PROP as REPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/reports/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/location-entity-links/state/data';
import { RmsAction } from '../../../../../core/typings/redux';

export const DRAGON_CORE_ENTITY_UPDATE = 'dragon/CORE_ENTITY_UPDATE';

type BoundEntries<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];

function createEntityToPredicateMapper(nexusStateProp: string) {
    return (value: { id: number }[]): [string, { id: number }[]] => [
        nexusStateProp,
        value.map(({ id }) => ({ id })),
    ];
}

const deletePayloadIdMappers: Record<
    Exclude<keyof CoreInstanceView, 'locationEntityLinks'>,
    ReturnType<typeof createEntityToPredicateMapper>
> = {
    nameReportLinks: createEntityToPredicateMapper(NAME_REPORT_LINKS_NEXUS_STATE_PROP),
    personProfiles: createEntityToPredicateMapper(PERSON_PROFILES_NEXUS_STATE_PROP),
    organizationProfiles: createEntityToPredicateMapper(ORGANIZATION_PROFILES_NEXUS_STATE_PROP),
    reports: createEntityToPredicateMapper(REPORTS_NEXUS_STATE_PROP),
    offenses: createEntityToPredicateMapper(OFFENSES_NEXUS_STATE_PROP),
    useOfForces: createEntityToPredicateMapper(USE_OF_FORCES_NEXUS_STATE_PROP),
};

// Custom mapper since `LocationEntityLink`s do not follow our standard primary key pattern
// We might be able to simply map on `configuredEntityReferenceId` but in order to be safe
// and prevent any potential removal of unrelated links we still perform an exhaustive check
// on all linking properties
const locationEntityLinkMapper = (
    value: LocationEntityLink[]
): [
    string,
    Pick<
        LocationEntityLink,
        'configuredEntityReferenceId' | 'linkType' | 'entityId' | 'entityType'
    >[]
] => [
    LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
    value.map(({ configuredEntityReferenceId, linkType, entityId, entityType }) => ({
        configuredEntityReferenceId,
        linkType,
        entityId,
        entityType,
    })),
];

const entityNexusKeys: Record<keyof CoreInstanceView, string> = {
    nameReportLinks: NAME_REPORT_LINKS_NEXUS_STATE_PROP,
    locationEntityLinks: LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
    personProfiles: PERSON_PROFILES_NEXUS_STATE_PROP,
    organizationProfiles: ORGANIZATION_PROFILES_NEXUS_STATE_PROP,
    reports: REPORTS_NEXUS_STATE_PROP,
    offenses: OFFENSES_NEXUS_STATE_PROP,
    useOfForces: USE_OF_FORCES_NEXUS_STATE_PROP,
};

export function syncCoreInstancesAction(
    deletedCoreInstances: CoreInstanceView,
    updatedCoreInstances: CoreInstanceView
): RmsAction<void> {
    return (dispatch, _, { nexus }) => {
        const entityRemovalPredicates = fromPairs(
            (Object.entries(deletedCoreInstances) as BoundEntries<typeof deletedCoreInstances>)
                .filter(([, value]) => value.length)
                .map((item) =>
                    item[0] === 'locationEntityLinks'
                        ? locationEntityLinkMapper(item[1])
                        : deletePayloadIdMappers[item[0]](item[1])
                )
        );
        const entitiesToAdd = fromPairs(
            (Object.entries(updatedCoreInstances) as BoundEntries<typeof updatedCoreInstances>)
                .filter(([, value]) => value.length)
                .map(([key, value]) => [entityNexusKeys[key], value])
        );
        dispatch(
            nexus.withEntityItems(
                entitiesToAdd,
                nexus.withRemoveMultiple(entityRemovalPredicates, {
                    type: DRAGON_CORE_ENTITY_UPDATE,
                })
            )
        );
    };
}

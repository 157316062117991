import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { lastModified } from '~/client-common/helpers/stringHelpers';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { useDateTimeFormatter } from '../../../core/current-user/hooks/dateTimeFormats';
import AdminFooter from './AdminFooter';

/**
 * Last Modified Footer that appears on the page footer. It includes the last modified time and modifier's name.
 */

/**
 * @typedef Updateable
 * @prop {number}   [updatedBy]
 * @prop {string}   [updatedDateUtc]
 *
 * @typedef Props
 * @prop {Updateable} [updateable]
 * @prop {boolean}  [submittingForm]
 * @prop {function} [onSubmit]
 * @prop {function} [onDelete]
 * @prop {function} [onCancel]
 * @prop {boolean}  [hideSave]
 * @prop {boolean}  [hideCancel]
 * @prop {boolean}  [hideDelete]
 * @prop {boolean}  [disabled]
 * @prop {string}   [saveText]
 * @prop {string}   [saveButtonDisabledTooltipText]
 *
 * @param {Props} props
 */
function AdminLastModifiedFooter({
    updateable: { updatedBy, updatedDateUtc } = {},
    submittingForm,
    onSubmit,
    onDelete,
    onCancel,
    hideSave = false,
    hideCancel = false,
    hideDelete = false,
    disabled = false,
    saveText,
    children,
    saveButtonDisabledTooltipText,
    formatMiniUserById,
    className,
}) {
    const dateTimeFormatter = useDateTimeFormatter();

    const lastModifiedUser = updatedBy && formatMiniUserById(updatedBy);

    const lastModifiedDate =
        updatedDateUtc && dateTimeFormatter.formatSummaryDateTime(updatedDateUtc);

    const lastModifiedText = lastModified(lastModifiedDate, lastModifiedUser);

    return (
        <AdminFooter
            className={className}
            text={lastModifiedText}
            loading={submittingForm}
            onSave={onSubmit}
            onCancel={onCancel}
            onDelete={onDelete}
            hideSave={hideSave}
            hideCancel={hideCancel}
            hideDelete={hideDelete}
            saveText={saveText}
            disabled={disabled}
            children={children}
            saveButtonDisabledTooltipText={saveButtonDisabledTooltipText}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    formatMiniUserById: formatMiniUserByIdSelector,
});

export default connect(mapStateToProps)(AdminLastModifiedFooter);

import React from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';
import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';
import Icon, { iconTypes } from '../../Icon';

function ImageCell({ primaryProfilePhotoPath, className, isSmall }) {
    const classes = classNames('elastic-vehicle-profile-photo', className);

    return primaryProfilePhotoPath ? (
        <ImgWithExifOrientation className={classes} src={primaryProfilePhotoPath} />
    ) : (
        <div className={classes}>
            <Icon type={iconTypes.VEHICLE} fontSize={isSmall ? '65px' : '80px'} />
        </div>
    );
}

export default pure(ImageCell);

import { BriefingsSearchView } from '@mark43/rms-api';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { RmsAction } from '../../../../core/typings/redux';

export function withBriefingsView({ attributes }: BriefingsSearchView): RmsAction<void> {
    return function (dispatch, getState, { nexus }) {
        const attributeViews = attributes.map(convertAttributeToAttributeView);

        dispatch(
            nexus.withEntityItems(
                { [ATTRIBUTES_NEXUS_STATE_PROP]: attributeViews },
                { type: 'FETCH_BRIEFINGS_SUCCESS' }
            )
        );
    };
}

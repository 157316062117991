import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { max } from 'lodash';
import styled from 'styled-components';
import { Text as ArcText } from 'arc';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { FormattedUser, ConnectedFormattedUser } from '~/client-common/core/users/components';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import advancedSearchAttachments from '../state/ui';
import AdvancedSearchResultsTableHeader from '../../core/components/AdvancedSearchResultsTableHeader';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';

const strings = componentStrings.search.AdvancedSearchAttachmentsResults;

const COLUMN_WIDTH = 929;

// 107 is the height of the table header
// todo refactor to genericize
const ClippableSearchTable = clippable({ bottomBuffer: 107 })(Table);

const UploadedDateCell = dateCellFactory('createdDateUtc');

const DescriptionText = styled(ArcText)`
    color: ${(props) => props.theme.colors.mediumGrey};
    display: block;
    padding-top: 2px;
    white-space: pre-line;
`;

const FileNameCell = (elasticFile) => (
    <div>
        {elasticFile.fileName}
        <DescriptionText>{elasticFile.description}</DescriptionText>
    </div>
);

const UploadedByCell = (elasticFile) => (
    <ConnectedFormattedUser
        userId={elasticFile.uploadedBy}
        format={FormattedUser.FORMATS.FULL_NAME}
    />
);

const SourceCell = (elasticFile) => {
    return <div>{elasticFile.displayTitle}</div>;
};

function AdvancedSearchAttachmentsResults({
    // Current Query and Search Results
    results = [],
    query: { from, size, sorKey: currentSortKey, sortType: currentSortType },
    activeColumnKeys,
    totalCount: totalResults,

    // Table UI State
    tableLoading,
    searchFilters,
    selectedRows,
    highlightedRows,
    handleRowSelect,

    // Event Handlers
    handleRowClick,
    handlePaginationClick,
    handleSizeChange,
    handleOptionClick,
    handleSortTypeClick,
}) {
    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    return (
        <ClippableSearchTable
            data={results}
            onRowClick={handleRowClick}
            sortKey={currentSortKey}
            sortType={currentSortType}
            noRowsText={strings.noResults}
            disableBody={tableLoading}
            containerClassName="search-results-table"
            rowComponent={ElasticResultRow}
            selectableRow={true}
            selectedRows={selectedRows}
            highlightedRows={highlightedRows}
            onSelectRow={handleRowSelect}
            disableRowSelectWhen={(row) => row && !canRead(row.permissionSet)}
        >
            <TableHeader>
                <AdvancedSearchResultsTableHeader
                    from={from}
                    to={from + results.length}
                    searchSize={size}
                    onSizeChange={handleSizeChange}
                    totalResults={totalResults}
                    currentPage={currentPage}
                    searchFilters={searchFilters}
                    onPaginationClick={(lastPage, nextPage) =>
                        handlePaginationClick(nextPage, size)
                    }
                    isSearchSavable={false}
                    searchModule={advancedSearchAttachments}
                />
                <TableColumns>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeColumnKeys.date}
                        activeValue={currentSortKey}
                        activeSortType={currentSortType}
                        onOptionClick={handleOptionClick}
                        onSortTypeClick={handleSortTypeClick}
                        width={COLUMN_WIDTH * 0.2}
                        popoutWidth={160}
                    >
                        <OptionsTableColumnOption
                            display={strings.columns.relevance.label}
                            value={sortKeyEnum.RELEVANCE}
                            columnKey="uploadedDate"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.uploadedDate.label}
                            value={sortKeyEnum.ATTACHMENT_CREATED_DATE_UTC}
                            columnKey="uploadedDate"
                            sortOptions={[
                                {
                                    display: strings.columns.uploadedDate.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.uploadedDate.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                    </OptionsTableColumn>
                    <TableColumn
                        display={strings.columns.fileName.label}
                        columnKey="fileName"
                        width={COLUMN_WIDTH * 0.3}
                    />
                    <TableColumn
                        display={strings.columns.uploadedBy.label}
                        columnKey="uploadedBy"
                        width={COLUMN_WIDTH * 0.25}
                    />
                    <TableColumn
                        display={strings.columns.source.label}
                        columnKey="source"
                        width={COLUMN_WIDTH * 0.25}
                    />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <UploadedDateCell columnKey="uploadedDate" />
                <FileNameCell columnKey="fileName" />
                <UploadedByCell columnKey="uploadedBy" />
                <SourceCell columnKey="source" />
            </TableBody>
        </ClippableSearchTable>
    );
}

const mapStateToProps = createStructuredSelector({
    query: advancedSearchAttachments.selectors.currentQuerySelector,
    activeColumnKeys: advancedSearchAttachments.selectors.activeColumnKeysSelector,
    results: advancedSearchAttachments.selectors.currentResultsViewModelsSelector,
    totalCount: advancedSearchAttachments.selectors.totalCountSelector,
    tableLoading: advancedSearchAttachments.selectors.tableLoadingSelector,
    searchFilters: advancedSearchAttachments.selectors.filtersSelector,
    selectedRows: advancedSearchAttachments.selectors.selectedRowsSelector,
    savedSearchErrorMessage:
        advancedSearchAttachments.selectors.loadSavedSearchErrorMessageSelector,
});

const mapDispatchToProps = (dispatch) => ({
    handleRowClick({ linkedEntityType, linkedEntityId }) {
        switch (linkedEntityType) {
            case EntityTypeEnum.REPORT.name:
                window.open(`/#/reports/${linkedEntityId}`, '_blank');
                break;
            case EntityTypeEnum.PERSON_PROFILE.name:
                window.open(`/#/profiles/persons/${linkedEntityId}`, '_blank');
                break;
            case EntityTypeEnum.ITEM_PROFILE.name:
                window.open(`/#/profiles/property/${linkedEntityId}`, '_blank');
                break;
            case EntityTypeEnum.CASE.name:
                window.open(`/#/cases/${linkedEntityId}/summary`, '_blank');
                break;
            default:
                break;
        }
    },
    handleRowSelect(newSelectedRows) {
        dispatch(advancedSearchAttachments.actionCreators.selectRows([newSelectedRows]));
    },
    handleOptionClick({ columnKey, value, sortOptions }) {
        dispatch(advancedSearchAttachments.actionCreators.setActiveColumnKey('date', columnKey));
        dispatch(
            advancedSearchAttachments.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleSortTypeClick(sortType) {
        dispatch(advancedSearchAttachments.actionCreators.search({ sortType }));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            advancedSearchAttachments.actionCreators.search({ from: nextPage * size - size, size })
        );
    },
    handleSizeChange(size) {
        dispatch(advancedSearchAttachments.actionCreators.search({ from: 0, size }));
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AdvancedSearchAttachmentsResults);

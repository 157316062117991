import { roleByIdSelector } from '~/client-common/core/domain/roles/state/data';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

import masterEntityAdminForm from '../forms/masterEntityAdminForm';

import {
    UPDATE_MASTER_ENTITY_PERMISSIONS_START,
    UPDATE_MASTER_ENTITY_PERMISSIONS_SUCCESS,
    UPDATE_MASTER_ENTITY_PERMISSIONS_FAILURE,
    LOAD_MASTER_ENTITY_PERMISSIONS_START,
    LOAD_MASTER_ENTITY_PERMISSIONS_SUCCESS,
    LOAD_MASTER_ENTITY_PERMISSIONS_FAILURE,
} from '../data';

const RESET_SELECTED_ENTITY = 'master-entities/RESET_SELECTED_ENTITY';

const initialUiState = {
    loadingEntityData: false,
    loadingError: undefined,
    submittingForm: false,
    formError: undefined,
    selectedEntityType: undefined,
};

export const uiSelector = (state) => state.ui.masterEntitiesAdmin;

export const resetSelectedEntity = () => ({
    type: RESET_SELECTED_ENTITY,
});

export const setInitialFormState = (entityType, permissions) => (dispatch, getState) => {
    const state = getState();
    const currentDepartmentId = currentUserDepartmentIdSelector(state);
    const roleById = roleByIdSelector(state);
    const { selectedEntityType } = uiSelector(state);

    // prevent overwriting of form state if someone submits
    // the form but then quickly changes to another entity type
    if (selectedEntityType === entityType) {
        dispatch(
            masterEntityAdminForm.actionCreators.change({
                permissions,
                currentDepartmentId,
                roleById,
            })
        );
    }
};

export default function masterEntityAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_MASTER_ENTITY_PERMISSIONS_START:
            return {
                ...state,
                formError: undefined,
                loadingError: undefined,
                loadingEntityData: true,
                selectedEntityType: action.payload.entityType,
            };
        case LOAD_MASTER_ENTITY_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loadingEntityData: false,
            };
        case LOAD_MASTER_ENTITY_PERMISSIONS_FAILURE:
            return {
                ...state,
                loadingError: action.payload.error,
            };
        case UPDATE_MASTER_ENTITY_PERMISSIONS_START:
            return {
                ...state,
                submittingForm: true,
                formError: undefined,
            };
        case UPDATE_MASTER_ENTITY_PERMISSIONS_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                formError: undefined,
            };
        case UPDATE_MASTER_ENTITY_PERMISSIONS_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload.error,
            };
        case RESET_SELECTED_ENTITY:
            return {
                ...state,
                selectedEntityType: undefined,
            };
        default:
            return state;
    }
}

import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withFields from '~/client-common/core/fields/components/withFields';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    WARRANT_ISSUING_AGENCY_NAME_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import strings from '~/client-common/core/strings/componentStrings';
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { formatWarrantTitle } from '~/client-common/helpers/warrantHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import QuickSearchResultSectionItemTitle from '../QuickSearchResultSectionItemTitle';
import { WarrantApprovalStatusIcon as _WarrantApprovalStatusIcon } from '../../../../records/core/components/ApprovalStatusIcon';
import Highlighter from '../QuickSearchHighlighter';
import WrappedFormattedDate from '../WrappedFormattedDate';
import GenericContainer from '../layout/GenericContainer';
import FieldDisplay from '../layout/FieldDisplay';
import ExternalDepartmentName from '../ExternalDepartmentName';

const Wrapper = styled.div`
    padding: 10px 10px 10px 40px;
    position: relative;
`;

const WarrantApprovalStatusIcon = styled(_WarrantApprovalStatusIcon)`
    position: absolute;
    top: 10px;
    left: 10px;
`;

const QuickSearchResultSectionWarrantItem = ({
    query,
    item,
    formatAttributeById,
    formatAttributeWithOther,
    formatMiniUserById,
    theme,
    fieldDisplayNames,
    externalDepartmentName,
    testId,
    testType,
}) => {
    const searchWords = [query];
    const warrantType = formatAttributeById(item.warrantTypeAttrId);
    const subjectName = item.subject && formatFullName(item.subject);
    const { warrantNumber } = item;
    const title = formatWarrantTitle({ warrantNumber, warrantType, subjectName });
    const { obtainingOfficerId, obtainingOfficerOther } = item;
    const formattedOfficer = externalDepartmentName
        ? undefined
        : joinTruthyValues([formatMiniUserById(obtainingOfficerId), obtainingOfficerOther], ': ');
    const obtainingOfficer =
        formattedOfficer && `${strings.warrants.generic.obtainedBy} ${formattedOfficer}`;

    return (
        <Wrapper data-test-id={testId} data-test-type={testType}>
            <WarrantApprovalStatusIcon warrantStatusGlobalAttrId={item.warrantStatusGlobalAttrId} />
            <QuickSearchResultSectionItemTitle>
                <Highlighter searchWords={searchWords} textToHighlight={title} />{' '}
                <ExternalDepartmentName theme={theme} departmentName={externalDepartmentName} />
            </QuickSearchResultSectionItemTitle>
            <WrappedFormattedDate
                date={item.warrantIssuedDateUtc}
                format={FormattedDate.FORMATS.SHORT_DATE}
            />
            {obtainingOfficer && (
                <GenericContainer
                    inlineBlock={true}
                    width={item.ren ? '60%' : '100%'}
                    color={theme.colors.mediumLightGrey}
                >
                    <Highlighter searchWords={searchWords} textToHighlight={obtainingOfficer} />
                </GenericContainer>
            )}
            {item.ren && (
                <FieldDisplay
                    fieldDisplayNames={fieldDisplayNames}
                    field={REPORT_REPORTING_EVENT_NUMBER}
                    theme={theme}
                    width="40%"
                >
                    <Highlighter searchWords={searchWords} textToHighlight={item.ren} />
                </FieldDisplay>
            )}
            {item.issuingAgencyNameAttrId && (
                <FieldDisplay
                    fieldDisplayNames={fieldDisplayNames}
                    field={WARRANT_ISSUING_AGENCY_NAME_ATTR_ID}
                    theme={theme}
                    width="100%"
                >
                    <Highlighter
                        searchWords={searchWords}
                        textToHighlight={formatAttributeWithOther({
                            attributeId: item.issuingAgencyNameAttrId,
                            other: item.issuingAgencyNameOther,
                        })}
                    />
                </FieldDisplay>
            )}
        </Wrapper>
    );
};

export default compose(
    connect((state) => ({
        formatAttributeById: formatAttributeByIdSelector(state),
        formatAttributeWithOther: formatAttributeWithOtherSelector(state),
        formatMiniUserById: formatMiniUserByIdSelector(state),
    })),
    withFields([REPORT_REPORTING_EVENT_NUMBER, WARRANT_ISSUING_AGENCY_NAME_ATTR_ID]),
    withTheme
)(QuickSearchResultSectionWarrantItem);

import {
    ItemInvolvementTypeEnum,
    ItemInvolvementTypeEnumType,
    PropertyStatus,
} from '@mark43/rms-api';
import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import { CurrencyFormatters } from '~/client-common/core/dates/utils/currencyHelpers';

import { propertyLossCodes } from '../../../constants/nibrsCodes';
import { propertyStatusesByItemProfileIdSelector } from '../state/data';
import { ValueOf } from '../../../../types';

const { none, unknown } = propertyLossCodes;

/**
 * Sort the given propertyStatuses in the order that they are displayed in the UI, by status date
 *   ascending.
 *
 * When status dates are identical, secondary sort by id for the result to be deterministic.
 */
export function sortPropertyStatuses<
    TItem extends { statusDateUtc?: string; recoveredDateUtc?: string; id: number }
>(propertyStatuses: TItem[]): TItem[] {
    return _.orderBy(propertyStatuses, ['statusDateUtc', 'recoveredDateUtc', 'id']);
}

/**
 * Merge the given propertyStatuses into 1 propertyStatus that contains the most recently updated
 *   non-empty value for each field. A gotcha is that the use of _.assign means a later {foo:
 *   undefined} value will overwrite an earlier {foo: 'bar'} value, and it won't overwrite if the
 *   key doesn't exist at all.
 *
 * Do not use this function on viewModels because _.assign does not copy Symbol properties.
 *   https://github.com/lodash/lodash/issues/2088
 *
 * In terms of business logic, this function makes sense only when all the propertyStatuses are
 *   linked to the same `itemProfileId`.
 *
 * Will also set true / false / undefined if any of the property status's 'is in police custody'
 * and 'is impounded' have been set.
 */
export function mergePropertyStatuses(propertyStatuses: PropertyStatus[]): PropertyStatus {
    const pluck = (list: PropertyStatus[], key: keyof PropertyStatus) => {
        if (_.some(list, (item) => _.get(item, key) === true)) {
            return { [key]: true };
        } else if (_.some(list, (item) => _.get(item, key) === false)) {
            return { [key]: false };
        }

        return { [key]: undefined };
    };

    const isInPoliceCustody = pluck(propertyStatuses, 'isInPoliceCustody');
    const isImpounded = pluck(propertyStatuses, 'isImpounded');

    return _.assign(
        {},
        ..._.orderBy(propertyStatuses, ['updatedDateUtc', 'id']),
        isInPoliceCustody,
        isImpounded
    );
}

/**
 * For the given propertyStatuses, returning a merged propertyStatus (`propertyStatus`) and an array
 *   of the remaining ids (`duplicateIds`). This function does not perform any actual deduping.
 *
 * In terms of business logic, this function makes sense only when all the propertyStatuses have the
 *   same `itemProfileId` and `propertyStatusAttrId`. It's meant for handling bad data, including
 *   incorrectly migrated data.
 *
 * Let's use the word 'duplicate' for propertyStatuses only when they have the same itemProfileId,
 *   propertyStatusAttrId, and offenseId (even when offenseId is undefined).
 */
export function findDuplicatePropertyStatuses(
    propertyStatuses: PropertyStatus[]
): {
    propertyStatus: PropertyStatus;
    duplicateIds: number[];
} {
    const propertyStatus = mergePropertyStatuses(propertyStatuses);
    const duplicateIds = _(propertyStatuses).map('id').without(propertyStatus.id).uniq().value();
    return { propertyStatus, duplicateIds };
}

/**
 * Merge any duplicate property statuses with same propertyStatusAttrId and return sorted list.
 *
 * If offenseId is provided, then the statuses linked to that offense are chosen above the rest.
 *   When no statuses are linked to that offense, we continue to merge statuses as if offenseId was
 *   not provided because the point of this function is only to dedupe/merge, not to filter.
 *
 * When the statuses have N propertyStatusAttrIds, this function always returns an array of N
 *   statuses no matter what. It does not filter out any propertyStatusAttrIds.
 *
 * In terms of business logic, this function makes sense only when all the propertyStatuses have the
 *   same `itemProfileId`.
 */
export function deduplicatePropertyStatuses(
    propertyStatuses: PropertyStatus[],
    offenseId?: number
): PropertyStatus[] {
    return _(propertyStatuses)
        .groupBy('propertyStatusAttrId')
        .map((propertyStatuses) => {
            const statusesInOffense = _.filter(propertyStatuses, { offenseId });
            return mergePropertyStatuses(
                statusesInOffense.length > 0 ? statusesInOffense : propertyStatuses
            );
        })
        .thru((propertyStatuses) => sortPropertyStatuses(propertyStatuses))
        .value();
}

interface InnerProps {
    itemProfileId: number;
    propertyStatusesByItemProfileId: ReturnType<typeof propertyStatusesByItemProfileIdSelector>;
}

interface OuterProps {
    propertyStatuses: PropertyStatus[];
}

/**
 * Use this higher order component for propertyStatuses for item profile id.
 *   Statuses are sorted oldest to newest statusDateUtc.
 */
export const connectPropertyStatusesForItem = compose<InnerProps, OuterProps>(
    connect(
        createStructuredSelector({
            propertyStatusesByItemProfileId: propertyStatusesByItemProfileIdSelector,
        })
    ),
    withPropsOnChange(
        ['itemProfileId', 'propertyStatusesByItemProfileId'],
        ({ itemProfileId, propertyStatusesByItemProfileId }: InnerProps) => ({
            propertyStatuses: deduplicatePropertyStatuses(
                propertyStatusesByItemProfileId(itemProfileId)
            ),
        })
    )
);

/**
 * For the given property statuses, return `propertyStatusesToUpsert` containing property statuses
 *   to create and update, as well as `propertyStatusIdsToDelete` containing the ids of property
 *   statuses to delete.
 */
export function linkPropertyStatusesToOffenseId(
    propertyStatuses: PropertyStatus[],
    selectedPropertyStatusIds: number[],
    offenseId: number
): {
    propertyStatusesToUpsert: Partial<PropertyStatus>[];
    propertyStatusIdsToDelete: number[];
} {
    const propertyStatusesToUpsert: Partial<PropertyStatus>[] = [];
    const propertyStatusIdsToDelete: number[] = [];

    const itemProfileIds = _(propertyStatuses).map('itemProfileId').uniq().value();
    _(propertyStatuses)
        .map('propertyStatusAttrId')
        .uniq()
        .forEach((propertyStatusAttrId) => {
            _.forEach(itemProfileIds, (itemProfileId) => {
                const existingStatusesInReport = _.filter(propertyStatuses, {
                    propertyStatusAttrId,
                    itemProfileId,
                });
                const existingStatusesInOffense = _.filter(existingStatusesInReport, {
                    offenseId,
                });

                const selected = _.some(existingStatusesInReport, ({ id }) =>
                    _.includes(selectedPropertyStatusIds, id)
                );

                if (selected) {
                    if (existingStatusesInOffense.length > 1) {
                        // There's supposed to be either 0 or 1 statuses with this
                        // propertyStatusAttrId in the offense. When there are multiple, it's bad
                        // data and will be deduped.
                        const { propertyStatus, duplicateIds } = findDuplicatePropertyStatuses(
                            existingStatusesInOffense
                        );
                        propertyStatusesToUpsert.push(propertyStatus);
                        propertyStatusIdsToDelete.push(...duplicateIds);
                    } else if (existingStatusesInOffense.length === 0) {
                        const existingStatusWithoutOffenseId = _.find(
                            existingStatusesInReport,
                            ({ offenseId }) => !offenseId
                        );
                        if (existingStatusWithoutOffenseId) {
                            // when the itemProfile has at least 1 status in this report that is not
                            // already linked to an offense, link any 1 of them to this offense
                            propertyStatusesToUpsert.push({
                                ...existingStatusWithoutOffenseId,
                                offenseId,
                            });
                        } else {
                            // when all of the itemProfile's statuses in this report are already
                            // linked to other offenses, create a new merged copy to link to this
                            // offense
                            propertyStatusesToUpsert.push({
                                ...mergePropertyStatuses(existingStatusesInReport),
                                offenseId,
                                id: undefined,
                            });
                        }
                    }
                    // otherwise, when there's exactly 1 status already in the offense, it doesn't
                    // need to be updated
                } else {
                    if (
                        existingStatusesInOffense.length > 0 &&
                        existingStatusesInReport.length > existingStatusesInOffense.length
                    ) {
                        // when the itemProfile has both status(es) linked to this offense and
                        // status(es) not linked to this offense, it is safe to just delete the
                        // former because there will still be status(es) linked to other offense(s)
                        propertyStatusIdsToDelete.push(..._.map(existingStatusesInOffense, 'id'));
                    } else if (
                        existingStatusesInOffense.length > 0 &&
                        existingStatusesInReport.length === existingStatusesInOffense.length
                    ) {
                        // when the itemProfile's status(es) in this report are all linked to this
                        // offense, update 1 of them to become unlinked from the offense and delete
                        // the rest which are dupes
                        const { propertyStatus, duplicateIds } = findDuplicatePropertyStatuses(
                            existingStatusesInOffense
                        );
                        propertyStatusesToUpsert.push({
                            ...propertyStatus,
                            offenseId: undefined,
                        });
                        propertyStatusIdsToDelete.push(...duplicateIds);
                    }
                    // otherwise, when there are no statuses linked to the offense, there's nothing
                    // to delete and nothing to upsert
                }
            });
        });

    return {
        propertyStatusesToUpsert,
        propertyStatusIdsToDelete,
    };
}

/**
 * In the given array of propertyStatuses, populate the new ones (meaning the propertyStatuses
 *   without an existing `id`) with unique negative numbers as their `id`s.
 *
 * We need to do this before creating multiple property statuses in bulk because back end uses the
 *   `LinkSequencingUtility` to set the sequence numbers, but `PropertyStatus` isn't a link and so
 *   we're using the id as the surrogate key.
 */
export function fillNewPropertyStatusesWithNegativeIds(
    propertyStatuses: PropertyStatus[]
): PropertyStatus[] {
    let newId = -1;
    return _.map(propertyStatuses, (propertyStatus) =>
        !!propertyStatus.id
            ? propertyStatus
            : {
                  ...propertyStatus,
                  id: newId--,
              }
    );
}

/**
 * Format the declared value for the given property status, which may be a dollar amount, unknown,
 *   or empty. Unknown means the 'Value Unknown' checkbox was checked. Empty means the value fields
 *   were hidden or not required.
 */
export function formatDeclaredValue(
    { declaredValue, declaredValueUnknown }: PropertyStatus,
    declaredValueUnknownDisplay: string,
    formatter: CurrencyFormatters['formatCurrency']
): string {
    if (declaredValueUnknown) {
        return declaredValueUnknownDisplay;
    }

    return formatter(declaredValue);
}

export function formatDeclaredValueAndForfeitureValueInFormModel(
    value: number | undefined
): string | number | undefined {
    if (!value || Number.isInteger(value)) {
        return value;
    }
    return value.toFixed(2);
}

/**
 * Given an array of NIBRS codes of type PropertyLoss, return an array of corresponding values from
 *   ItemInvolvementTypeEnum.
 */
export function convertPropertyLossNibrsCodesToItemInvolvementTypes(
    codes: ValueOf<typeof propertyLossCodes>[]
): ItemInvolvementTypeEnumType[] {
    const itemInvolvementTypes: ItemInvolvementTypeEnumType[] = [];
    if (_.some(codes, (code) => code !== none && code !== unknown)) {
        itemInvolvementTypes.push(ItemInvolvementTypeEnum.PROPERTY_INVOLVED.name);
    }
    if (_.includes(codes, none)) {
        itemInvolvementTypes.push(ItemInvolvementTypeEnum.NONE.name);
    }
    if (_.includes(codes, unknown)) {
        itemInvolvementTypes.push(ItemInvolvementTypeEnum.UNKNOWN.name);
    }
    return itemInvolvementTypes;
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { get } from 'lodash';
import { compose, withPropsOnChange } from 'recompose';
import { consortiumProfilesSelector } from '~/client-common/core/domain/consortium-profiles/state/data';
import { departmentProfilesSelector } from '~/client-common/core/domain/department-profiles/state/data';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { createModalSelector } from '../../../core/box/state/ui';

import { CONSORTIUM_ADMIN_ADD_DEPARTMENT_MODAL_CONTEXT, addDepartmentViaModal } from '../state/ui';

const strings = componentStrings.admin.consortium.ConsortiumAdminAddDepartmentModal;

const ModalText = styled.div`
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

class ConsortiumAdminAddDepartmentModal extends Component {
    onSave = () =>
        this.props.addDepartmentViaModal(
            get(this.props.addDepartmentFromConsortiumData, 'consortiumId'),
            get(this.props.addDepartmentFromConsortiumData, 'departmentId')
        );

    render() {
        const { departmentProfile, consortiumProfile } = this.props;
        return (
            <Modal
                context={CONSORTIUM_ADMIN_ADD_DEPARTMENT_MODAL_CONTEXT}
                title={strings.modalTitle}
                shouldCloseOnOverlayClick={true}
                okText={strings.modalOk}
                onSave={this.onSave}
            >
                <ModalText>
                    {strings.modalContent({
                        departmentName: get(departmentProfile, 'displayName'),
                        consortiumName: get(consortiumProfile, 'name'),
                        departmentStatus: get(departmentProfile, 'department.departmentStatus'),
                    })}
                </ModalText>
            </Modal>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    consortiumProfiles: consortiumProfilesSelector,
    departmentProfiles: departmentProfilesSelector,
    addDepartmentFromConsortiumData: createModalSelector(
        CONSORTIUM_ADMIN_ADD_DEPARTMENT_MODAL_CONTEXT,
        'addDepartmentToConsortiumData'
    ),
});

const mapDispatchToProps = { addDepartmentViaModal };

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['consortiumProfiles', 'departmentProfiles', 'addDepartmentFromConsortiumData'],
        ({ consortiumProfiles, departmentProfiles, addDepartmentFromConsortiumData }) => ({
            consortiumProfile:
                consortiumProfiles[get(addDepartmentFromConsortiumData, 'consortiumId')],
            departmentProfile:
                departmentProfiles[get(addDepartmentFromConsortiumData, 'departmentId')],
        })
    )
)(ConsortiumAdminAddDepartmentModal);

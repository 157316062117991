import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    DISPLAY_CASE_SUPERVISORS,
    DISPLAY_ONLY_CASE_SEARCH_INCLUDE_ASSISTING_INVESTIGATORS_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { ATTRIBUTE, FIELDSET, ROLE } = fieldTypeClientEnum;
const strings = componentStrings.cases.allCases.AllCasesSearchPersonnelFieldset;

/**
 * Fieldset view model.
 */
export default {
    type: FIELDSET,
    key: 'personnel',
    title: strings.title,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'assigneeRoleIds',
            type: ROLE,
        },
        {
            key: 'hasAssignee',
        },
        {
            key: 'includeAssistingInvestigators',
            fieldName: DISPLAY_ONLY_CASE_SEARCH_INCLUDE_ASSISTING_INVESTIGATORS_LABEL,
        },
        {
            key: 'supervisorRoleIds',
            type: ROLE,
            fieldName: DISPLAY_CASE_SUPERVISORS,
        },
        {
            key: 'hasSupervisor',
        },
        {
            key: 'assignedPersonnelUnitAttrIds',
            type: ATTRIBUTE,
        },
    ]),
};

import { compact, isArray, join, map, mapValues } from 'lodash';
import { createSelector } from 'reselect';

import { isUndefinedOrNull, isValueDisplayable } from '../../../../../helpers/logicHelpers';
import { offenseCodesSelector } from '../data';
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';
import { formatOffenseCode } from '../../../../../helpers/offenseCodesHelpers';

export const offenseCodeViewModelsSelector = createSelector(offenseCodesSelector, (offenseCodes) =>
    mapValues(
        offenseCodes,
        buildViewModel({
            mappers: [
                (code) => ({
                    displayName: formatOffenseCode(code),
                }),
            ],
        })
    )
);

/**
 * Format the display string for a local offense code(s).
 *
 * Must be identical to {@link printing/src/csv/selectors/offenseCodesSelectors.js}.
 * @param  {number|number[]} id
 * @param  {boolean} includeCode    Whether or not to include the raw offense code in
 *                                  the display string.  Default `false`.
 * @return {string}
 */
export const formatOffenseCodeByIdSelector = createSelector(
    offenseCodesSelector,
    (offenseCodes) => ({ id, includeCode = false }) => {
        const idsAsArray = isArray(id) ? compact(id) : compact([id]);
        return join(
            compact(
                map(idsAsArray, (idInArray) => {
                    const offenseCode = offenseCodes[idInArray];
                    if (isUndefinedOrNull(offenseCode)) {
                        return undefined;
                    }
                    const formattedOffenseCode = formatOffenseCode(offenseCode);
                    const { code } = offenseCode;
                    if (includeCode && isValueDisplayable(code)) {
                        return `${formattedOffenseCode} (${code})`;
                    }
                    return formattedOffenseCode;
                })
            ),
            ', '
        );
    }
);

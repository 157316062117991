import { DexDevicesResource, DexDevice, DexDeviceBulkView } from '@mark43/dex-api';
import { createResource } from '../../../../../lib/resources/Resource';
import { req } from '../../../../../lib/ajax';

export default createResource({
    name: 'DEX Devices Resource',
    methods: {
        getDexDevices() {
            return req<DexDevicesResource.GetDexDevicesForDepartment>({
                method: 'GET',
                url: 'dex_devices/multi_ori',
                baseUrl: '/dex/api',
            });
        },
        deleteDexDevice(dexDeviceIds: number[]) {
            return req<DexDevicesResource.DeleteDevicesAndAssociatedDeviceOris>({
                method: 'POST',
                baseUrl: '/dex/api',
                url: 'dex_devices/multi_ori/delete',
                data: dexDeviceIds,
            });
        },
        createDexDevices(dexDevices: DexDevice[]) {
            return req<DexDevicesResource.CreateDexDevices>({
                method: 'POST',
                baseUrl: '/dex/api',
                url: 'dex_devices/bulk',
                data: dexDevices,
            });
        },
        updateDexDevices(dexDevices: DexDevice[]) {
            return req<DexDevicesResource.UpdateDexDevices>({
                method: 'PUT',
                baseUrl: '/dex/api',
                url: 'dex_devices',
                data: dexDevices,
            });
        },
        updateDexDevicesMultiOri(dexDeviceBulkView: DexDeviceBulkView) {
            return req<DexDevicesResource.UpsertDexDevices>({
                method: 'POST',
                url: 'dex_devices/multi_ori',
                baseUrl: '/dex/api',
                data: dexDeviceBulkView,
            });
        },
    },
});

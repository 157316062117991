import { LinkTypesEnum } from '@mark43/rms-api';
import { invert } from 'lodash';

const BASE_PATH = 'reports';

let resource;
const invertedLinkTypesEnum = invert(LinkTypesEnum);

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Name Report Link Resource',
        methods: {
            deleteNameReportLink(nameReportLink) {
                const urlString = `${BASE_PATH}/${nameReportLink.reportId}/names/${
                    nameReportLink.nameId
                }/link_type/${invertedLinkTypesEnum[nameReportLink.linkType]}/context/${
                    nameReportLink.contextType
                }/${nameReportLink.contextId}`;
                return req({
                    method: 'DELETE',
                    url: urlString,
                    params: {
                        entity_type: nameReportLink.entityType,
                    },
                });
            },
            addNameToReport(nameReportLink) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/${nameReportLink.reportId}/persons`,
                    params: {
                        entity_type: nameReportLink.entityType,
                    },
                    data: nameReportLink,
                });
            },
        },
    });
}

/**
 * Resources for NameReportLinks. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';

import componentStrings from '~/client-common/core/strings/componentStrings';

import Checkbox from '../../core/forms/components/checkboxes/Checkbox';
import MergeEntitiesText from './core/MergeEntitiesText';
import HeaderColumn from './HeaderColumn';

const CheckboxWithoutMarginBottom = styled(Checkbox)`
    && {
        margin-bottom: 0;
    }
`;

const SelectAllHeaderColumn = ({ disabled, value, onChange, ...rest }) => (
    <HeaderColumn padding="10px 20px" {...rest}>
        <CheckboxWithoutMarginBottom
            onChange={onChange}
            id="selectAllLeft"
            disabled={disabled}
            value={value}
            label={
                <MergeEntitiesText as="span" display="inline" fontWeight="bold" color="darkGrey">
                    {componentStrings.mergeEntities.SelectAllHeaderColumn.selectAll}
                </MergeEntitiesText>
            }
        />
    </HeaderColumn>
);

export default pure(SelectAllHeaderColumn);

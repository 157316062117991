import { EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { filter, map, omit } from 'lodash';
import { compose } from 'redux';
import { withState, withHandlers } from 'recompose';
import styled from 'styled-components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { caseTaskAttachmentsByCaseIdSelector } from '~/client-common/core/domain/case-attachments/state/ui';
import { FormattedDate } from '~/client-common/core/dates/components';
import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { FileGrid } from '../../../../legacy-redux/components/core/Files';
import { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import TaskListAddButton from './TaskListAddButton';
import TaskListIcon from './TaskListIcon';

const strings = componentStrings.cases.taskList.TaskListRow;

const RowTaskListAddButton = styled(TaskListAddButton)`
    margin-top: 10px;
`;

const TaskListClickableIcon = styled(TaskListIcon)`
    display: inline-block;
    padding: 0 10px;
    color: ${(props) => props.theme.colors.cobaltBlue};
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
    cursor: pointer;

    &:last-child {
        border-right: none;
    }
`;

const TaskListCell = styled.td`
    vertical-align: top;
    padding: 20px 0 20px 10px;
`;

const TaskListDescriptionCell = styled(TaskListCell)`
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    ${(props) =>
        props.redBorder ? `border-left: 2px solid ${(props) => props.theme.colors.red};` : ''};
`;

const DueDateText = styled.span`
    vertical-align: middle;
`;

const StatusDate = styled.span`
    display: block;
    font-size: var(--arc-fontSizes-xs);
    font-style: italic;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.lightGrey};
`;

const OverdueText = styled.div`
    font-size: var(--arc-fontSizes-xs);
    font-style: italic;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.red};
`;

const _TaskListRow = compose(
    connect((state, ownProps) => ({
        caseTaskAttachments: filter(
            caseTaskAttachmentsByCaseIdSelector(state)(ownProps.taskViewModel.caseId),
            {
                entityType: EntityTypeEnum.TASK.name,
                entityId: ownProps.taskViewModel.id,
            }
        ),
    })),
    withState('showActions', 'setShowActions', false),
    withHandlers({
        setShowActionsTrue({ setShowActions }) {
            return () => setShowActions(true);
        },
        setShowActionsFalse({ setShowActions }) {
            return () => setShowActions(false);
        },
        openTaskDetailsSidePanel({ taskViewModel, openTaskDetailsSidePanel }) {
            return () => openTaskDetailsSidePanel(taskViewModel.id);
        },
        removeTaskFromCase({ taskViewModel, removeTaskFromCase }) {
            return () => removeTaskFromCase(taskViewModel.id);
        },
    })
)(function _TaskListRow({
    openTaskDetailsSidePanel,
    className,
    taskViewModel,
    casePermissionSet,
    showActions,
    setShowActionsTrue,
    setShowActionsFalse,
    removeTaskFromCase,
    caseTaskAttachments,
    openAttachmentsSidePanel,
}) {
    const { description, dueDateUtc, statusDateUtc } = taskViewModel;
    const {
        assignee,
        statusAttrId,
        statusParentAttrId,
        isCompleted,
        isOverdue,
    } = getViewModelProperties(taskViewModel);
    const isNotApplicable = statusParentAttrId === globalAttributes.taskStatusGlobal.notApplicable;

    const showStatusDate = isCompleted && !!statusDateUtc;

    const mappedAttachments = map(caseTaskAttachments, (caseAttachment) =>
        omit(caseAttachment, 'description')
    );

    return (
        <tr
            className={className}
            onMouseEnter={setShowActionsTrue}
            onMouseLeave={setShowActionsFalse}
        >
            <TaskListDescriptionCell redBorder={isOverdue}>
                {description}
                {isOverdue && !isNotApplicable && <OverdueText>{strings.overdue}</OverdueText>}
            </TaskListDescriptionCell>
            <TaskListCell>
                <div>{assignee.fullName || assignee.default}</div>
                {mappedAttachments.length > 0 && (
                    <FileGrid width={200} fileLinks={mappedAttachments} numOfColumns={1} />
                )}
                <OnlyWithEntityPermission
                    permissionSet={casePermissionSet}
                    has={OperationTypeEnum.WRITE.name}
                >
                    <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
                        <RowTaskListAddButton onClick={openAttachmentsSidePanel}>
                            <TaskListIcon type={iconTypes.ADD} />
                            {strings.attach}
                        </RowTaskListAddButton>
                    </OnlyWithAbility>
                </OnlyWithEntityPermission>
            </TaskListCell>
            <TaskListCell>
                {!!dueDateUtc && <TaskListIcon type={iconTypes.CALENDAR} />}
                <DueDateText>
                    <FormattedDate date={dueDateUtc} format={FormattedDate.FORMATS.FORM_DATE} />
                </DueDateText>
            </TaskListCell>
            <TaskListCell>
                {statusAttrId}
                <StatusDate>
                    {showStatusDate && (
                        <FormattedDate
                            date={statusDateUtc}
                            format={FormattedDate.FORMATS.FORM_DATE}
                        />
                    )}
                </StatusDate>
            </TaskListCell>
            <TaskListCell>
                {showActions && (
                    <OnlyWithEntityPermission
                        permissionSet={casePermissionSet}
                        has={OperationTypeEnum.WRITE.name}
                    >
                        <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
                            <TaskListClickableIcon
                                type={iconTypes.EDIT}
                                onClick={openTaskDetailsSidePanel}
                            />
                        </OnlyWithAbility>
                        <OnlyWithAbility has={abilitiesEnum.CORE.MASTER_DELETE_TASKS}>
                            <TaskListClickableIcon
                                type={iconTypes.TRASH}
                                onClick={removeTaskFromCase}
                            />
                        </OnlyWithAbility>
                    </OnlyWithEntityPermission>
                )}
            </TaskListCell>
        </tr>
    );
});

export default styled(_TaskListRow)`
    border-bottom: 1px solid
        ${(props) => (props.lastRow ? 'transparent' : props.theme.colors.extraLightGrey)};

    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

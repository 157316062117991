import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

const baseUrl = 'admin/security/departments';

export default createResource({
    name: 'Security Admin Resource',
    methods: {
        getAllDepartmentIPs() {
            return req({
                method: 'GET',
                url: `${baseUrl}/ips`,
            });
        },
        upsertDepartmentIP(ip) {
            return req({
                method: 'POST',
                url: `${baseUrl}/ips`,
                data: ip,
            });
        },
        deleteDepartmentIP(id) {
            return req({
                method: 'DELETE',
                url: `${baseUrl}/ips/${id}`,
            });
        },
        isDepartmentSubDomainAvailable(subDomain) {
            return req({
                method: 'GET',
                url: `${baseUrl}/subdomain/${subDomain}/available`,
            });
        },
        upsertSsoConfiguration(data) {
            return req({
                method: 'PUT',
                url: 'admin/security/departments/sso',
                data,
            });
        },
        getDepartmentSsoConfiguration() {
            return req({
                method: 'GET',
                url: 'admin/security/departments/sso',
            });
        },
        getDepartmentSsoConnections() {
            return req({
                method: 'GET',
                url: 'admin/security/departments/sso/connections',
            });
        },
        deleteDepartmentSsoConfiguration() {
            return req({
                method: 'DELETE',
                url: 'admin/security/departments/sso',
            });
        },
        setupDepartmentSsoConnection({ connection, createMark43SsoConnection, domainHint }) {
            return req({
                method: 'PUT',
                url: `admin/security/departments/sso/setup/${connection}`,
                params: {
                    created_mark43: createMark43SsoConnection,
                    domain_hint: domainHint,
                },
            });
        },
    },
});

export const encode = function (s: string) {
    return window.btoa(s);
};

/**
 * Use this function instead of `btoa` to encode a Unicode string.
 * https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 * https://stackoverflow.com/a/30106551
 */
export function btoaUnicode(str: string) {
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
            return String.fromCharCode(parseInt(p1, 16));
        })
    );
}

/**
 * Use this function instead of `atob` to decode a Unicode string which was encoded with `btoaUnicode`.
 * https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 * https://stackoverflow.com/a/30106551
 */
export function atobUnicode(str: string) {
    return decodeURIComponent(
        Array.prototype.map
            .call(atob(str), (c) => {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join('')
    );
}

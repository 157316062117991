import React from 'react';
import { Tooltip as ArcTooltip } from 'arc';
import type { TooltipProps as ArcTooltipProps } from 'arc';

export type TooltipProps = ArcTooltipProps & {
    testId?: string;
    /** Temporary prop to provide default offset when the tooltip is used with the current RMS buttons. This can be removed when those buttons are migrated to ARC and no longer need this custom offset */
    hasButtonOffset?: boolean;
};

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
    ({ sideOffset, testId, hasButtonOffset, collisionBoundary, ...props }, forwardedRef) => {
        return (
            <ArcTooltip
                className="arc-tooltip"
                ref={forwardedRef}
                data-test-id={testId}
                sideOffset={hasButtonOffset ? -8 : sideOffset}
                collisionBoundary={collisionBoundary ?? document.querySelector('.mark43-content')}
                {...props}
            />
        );
    }
);

interface ConditionalTooltipProps extends TooltipProps {
    condition: boolean;
}

export const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({
    condition,
    ...restOfProps
}) => {
    return condition ? (
        <Tooltip {...restOfProps}>{restOfProps.children}</Tooltip>
    ) : (
        <>{restOfProps.children}</>
    );
};

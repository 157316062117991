import _ from 'lodash';
import Promise from 'bluebird';
import {
    UserNotificationBundle,
    NotificationStatusEnumType,
    UserPollView,
    UserNotificationsView,
    NotificationStatusEnum,
    DeliveryMethodEnum,
    EntityTypeEnum,
} from '@mark43/notifications-api';
import { SearchQuery } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import { loadAttachmentsByEntityId } from '../../../attachments/state/data';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getUserNotificationsResource from '../../resources/userNotificationsResource';
import { generateMockUserNotification } from '../../utils/userNotificationHelpers';
import { ClientCommonAction } from '../../../../../redux/types';

const { ACKNOWLEDGED } = NotificationStatusEnum;

const userNotificationsModule = createNormalizedModule<UserNotificationBundle>({
    type: 'userNotifications',
    key: 'userNotificationLinkId',
});

// ACTION TYPES
const LOAD_USER_NOTIFICATIONS_FAILURE = 'user-notifications/LOAD_USER_NOTIFICATIONS_FAILURE';
export const MARK_ALL_USER_NOTIFICATIONS_START =
    'user-notifications/MARK_ALL_USER_NOTIFICATIONS_START';
export const MARK_ALL_USER_NOTIFICATIONS_SUCCESS =
    'user-notifications/MARK_ALL_USER_NOTIFICATIONS_SUCCESS';
export const MARK_ALL_USER_NOTIFICATIONS_FAILURE =
    'user-notifications/MARK_ALL_USER_NOTIFICATIONS_FAILURE';
const MARK_SPECIFIC_USER_NOTIFICATIONS_START =
    'user-notifications/MARK_SPECIFIC_USER_NOTIFICATIONS_START';
const MARK_SPECIFIC_USER_NOTIFICATIONS_SUCCESS =
    'user-notifications/MARK_SPECIFIC_USER_NOTIFICATIONS_SUCCESS';
const MARK_SPECIFIC_USER_NOTIFICATIONS_FAILURE =
    'user-notifications/MARK_SPECIFIC_USER_NOTIFICATIONS_FAILURE';
const ACKNOWLEDGE_USER_NOTIFICATION_START =
    'user-notifications/ACKNOWLEDGE_USER_NOTIFICATION_START';
const ACKNOWLEDGE_USER_NOTIFICATION_SUCCESS =
    'user-notifications/ACKNOWLEDGE_USER_NOTIFICATION_SUCCESS';
const ACKNOWLEDGE_USER_NOTIFICATION_FAILURE =
    'user-notifications/ACKNOWLEDGE_USER_NOTIFICATION_FAILURE';
export const POLL_FOR_USER_NOTIFICATIONS_SUCCESS =
    'user-notifications/POLL_FOR_USER_NOTIFICATIONS_SUCCESS';
const POLL_FOR_USER_NOTIFICATIONS_FAILURE =
    'user-notifications/POLL_FOR_USER_NOTIFICATIONS_FAILURE';

// ACTIONS
export const storeUserNotifications = userNotificationsModule.actionCreators.storeEntities;

function loadUserNotificationsFailure(errorMessage: string) {
    return {
        type: LOAD_USER_NOTIFICATIONS_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

export function pollForUserNotificationsSuccess(result: UserNotificationsView) {
    return {
        type: POLL_FOR_USER_NOTIFICATIONS_SUCCESS,
        payload: result,
    };
}
function pollForUserNotificationsFailure(errorMessage: string) {
    return {
        type: POLL_FOR_USER_NOTIFICATIONS_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Load notifications for the current user from the server, paginated.
 */
export function loadUserNotifications(
    query: SearchQuery,
    options: { hideLoadingBar: boolean }
): ClientCommonAction<Promise<void>> {
    const resource = getUserNotificationsResource();

    return function (dispatch) {
        return resource
            .getCurrentUserNotifications(query, options)
            .then(
                ({
                    expirationSeconds,
                    notifications,
                    totalCount,
                    unreadCount,
                }: UserNotificationsView & { expirationSeconds: number }) => {
                    // TODO: confirm that expirationSeconds is actually included in this response, otherwise remove it
                    dispatch(storeUserNotifications(notifications));

                    const notificationIds = notifications.map((x) => x.notificationId);
                    dispatch(
                        loadAttachmentsByEntityId(
                            EntityTypeEnum.NOTIFICATION.name,
                            notificationIds,
                            { hideLoadingBar: true }
                        )
                    );

                    const result = {
                        query,
                        notifications,
                        totalCount,
                        unreadCount,
                        // we are leveraging the notifications endpoint to also check for session expiration
                        expirationSeconds,
                    };
                    return result;
                }
            )
            .catch((err: Error) => {
                dispatch(loadUserNotificationsFailure(err.message));
                throw err;
            });
    };
}

/**
 * Create a new notification, which must be for the current user. This action
 *   creator is for testing purposes only; real notifications are not created on
 *   the server, not here.
 */
export function createCurrentUserNotification(
    userNotification: UserNotificationBundle
): ClientCommonAction<Promise<void>> {
    const resource = getUserNotificationsResource();
    userNotification = generateMockUserNotification(userNotification);

    return function (dispatch) {
        return resource
            .createCurrentUserNotification(userNotification)
            .then((userNotification: UserNotificationBundle) => {
                dispatch(storeUserNotifications([userNotification]));
            });
    };
}

/**
 * Create a notification for an arbitrary user. This action creator is for
 *   testing purposes only; real notifications are not created on the server,
 *   not here.
 */
export function createUserNotification(userNotification: UserNotificationBundle) {
    const resource = getUserNotificationsResource();
    userNotification = generateMockUserNotification(userNotification);

    return function () {
        return resource.createUserNotification(userNotification);
    };
}

function markAllUserNotificationsStart({
    beforeStatuses,
    afterStatus,
    endDateUtc,
}: Parameters<typeof markAllUserNotifications>[0]) {
    return {
        type: MARK_ALL_USER_NOTIFICATIONS_START,
        payload: { beforeStatuses, afterStatus, endDateUtc },
    };
}
function markAllUserNotificationsSuccess() {
    return {
        type: MARK_ALL_USER_NOTIFICATIONS_SUCCESS,
    };
}
function markAllUserNotificationsFailure(errorMessage: string) {
    return {
        type: MARK_ALL_USER_NOTIFICATIONS_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

export type MarkAllUserNotificationProps = {
    beforeStatuses: NotificationStatusEnumType[];
    afterStatus: NotificationStatusEnumType;
    endDateUtc: string;
};

/**
 * Mark the current user's notifications with beforeStatuses as afterStatus
 * up to the given date.
 */
export function markAllUserNotifications({
    beforeStatuses,
    afterStatus,
    endDateUtc,
}: MarkAllUserNotificationProps): ClientCommonAction<Promise<void>> {
    const resource = getUserNotificationsResource();

    return function (dispatch) {
        dispatch(
            markAllUserNotificationsStart({
                beforeStatuses,
                afterStatus,
                endDateUtc,
            })
        );

        return resource
            .markUserNotifications({ beforeStatuses, afterStatus, endDateUtc })
            .then((success: boolean) => {
                if (success) {
                    dispatch(markAllUserNotificationsSuccess());
                } else {
                    throw new Error('Failed to mark all notifications');
                }
            })
            .catch((err: Error) => {
                dispatch(markAllUserNotificationsFailure(err.message));
                throw err;
            });
    };
}

function markSpecificUserNotificationsStart({
    userNotificationLinkIds,
    afterStatus,
}: Parameters<typeof markSpecificUserNotifications>[0]) {
    return {
        type: MARK_SPECIFIC_USER_NOTIFICATIONS_START,
        payload: { userNotificationLinkIds, afterStatus },
    };
}
function markSpecificUserNotificationsSuccess() {
    return {
        type: MARK_SPECIFIC_USER_NOTIFICATIONS_SUCCESS,
    };
}
function markSpecificUserNotificationsFailure(errorMessage: string) {
    return {
        type: MARK_SPECIFIC_USER_NOTIFICATIONS_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Update the status of the given user notifications.
 */
export function markSpecificUserNotifications({
    userNotificationLinkIds,
    afterStatus,
}: {
    userNotificationLinkIds: number[];
    afterStatus: NotificationStatusEnumType;
}): ClientCommonAction<Promise<void>> {
    const resource = getUserNotificationsResource();

    return function (dispatch, getState) {
        dispatch(
            markSpecificUserNotificationsStart({
                userNotificationLinkIds,
                afterStatus,
            })
        );

        const state = getState();
        const userNotifications = _(userNotificationsSelector(state))
            .pick(userNotificationLinkIds)
            .map((userNotification) => ({
                ...userNotification,
                notificationStatus: afterStatus,
            }))
            .value();

        return resource
            .updateCurrentUserNotifications(userNotifications)
            .then((userNotifications: UserNotificationBundle[]) => {
                dispatch(markSpecificUserNotificationsSuccess());
                dispatch(storeUserNotifications(userNotifications));
            })
            .catch((err: Error) => {
                dispatch(markSpecificUserNotificationsFailure(err.message));
                throw err;
            });
    };
}

function acknowledgeUserNotificationStart(userNotification: UserNotificationBundle) {
    return {
        type: ACKNOWLEDGE_USER_NOTIFICATION_START,
        payload: userNotification,
    };
}
function acknowledgeUserNotificationSuccess(userNotification: UserNotificationBundle) {
    return {
        type: ACKNOWLEDGE_USER_NOTIFICATION_SUCCESS,
        payload: userNotification,
    };
}
function acknowledgeUserNotificationFailure(errorMessage: string) {
    return {
        type: ACKNOWLEDGE_USER_NOTIFICATION_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Acknowledge a single notification. Do nothing if the notification is already
 *   acknowledged. Note there is no check that the new notificationStatus is
 *   truly ACKNOWLEDGED; the new model from the server is accepted as is.
 */
export function acknowledgeUserNotification(
    userNotification: UserNotificationBundle
): ClientCommonAction<Promise<UserNotificationBundle>> {
    const resource = getUserNotificationsResource();

    // @ts-expect-error Determine if we really need Promise.method and if not, let's remove it and also remove this type surpression
    return Promise.method((dispatch) => {
        if (userNotification.notificationStatus !== ACKNOWLEDGED.name) {
            dispatch(acknowledgeUserNotificationStart(userNotification));

            return resource
                .updateCurrentUserNotification(
                    {
                        ...userNotification,
                        notificationStatus: ACKNOWLEDGED.name,
                    },
                    {
                        hideLoadingBar: true,
                    }
                )
                .then((userNotification: UserNotificationBundle) => {
                    dispatch(acknowledgeUserNotificationSuccess(userNotification));
                    dispatch(storeUserNotifications([userNotification]));
                    return userNotification;
                })
                .catch((err: Error) => {
                    dispatch(acknowledgeUserNotificationFailure(err.message));
                    throw err;
                });
        }
    });
}

/**
 * Poll current user notifications.
 */
export function pollUserNotifications(): ClientCommonAction<Promise<UserPollView>> {
    return (dispatch) => {
        const resource = getUserNotificationsResource();

        return resource
            .pollUserNotifications()
            .then((result: UserPollView) => {
                dispatch(storeUserNotifications(result.notifications));
                dispatch(pollForUserNotificationsSuccess(result));

                return result;
            })
            .catch((error: Error) => {
                dispatch(pollForUserNotificationsFailure(error.message));
                throw error;
            });
    };
}

// SELECTORS
export const userNotificationsSelector = userNotificationsModule.selectors.entitiesSelector;

export const userAlertNotificationIdsSelector = createSelector(
    userNotificationsSelector,
    (userNotifications) =>
        _(userNotifications)
            .filter({ deliveryMethod: DeliveryMethodEnum.ALERT.name })
            .map('notificationId')
            .value()
);

// REDUCER
export default userNotificationsModule.reducerConfig;

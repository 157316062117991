import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';
import { get, map } from 'lodash';

import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { personProfileViewModelByIdSelector } from '~/client-common/core/domain/person-profiles/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import { useDateTimeFormatter } from '../../current-user/hooks/dateTimeFormats';
import PersonPill from './PersonPill';

const PersonProfilePill = ({ personProfileViewModel, ...otherProps }) => {
    const { isDead, dateOfBirth, ssn, dlNumber } = personProfileViewModel;
    const {
        sexAttrId,
        raceAttrId,
        height,
        eyeColorAttrId,
        hairColorAttrId,
        monikers,
        mostRecentMugshotImage,
    } = getViewModelProperties(personProfileViewModel);

    const dateTimeFormatter = useDateTimeFormatter();

    const sex = sexAttrId;
    const race = raceAttrId;
    const eyeColor = eyeColorAttrId;
    const hairColor = hairColorAttrId;
    const socialSecurityNumber = ssn;
    const driversLicenseNumber = dlNumber;

    const primaryMugshotPath =
        get(mostRecentMugshotImage, 'webFile.fileWebServerPath') ||
        get(mostRecentMugshotImage, 'originalFile.fileWebServerPath');
    const nicknames = map(monikers, 'moniker');
    const fullName = formatFullName(personProfileViewModel);

    return (
        <PersonPill
            dateOfBirth={dateTimeFormatter.formatDate(dateOfBirth)}
            sex={sex}
            race={race}
            height={height}
            eyeColor={eyeColor}
            hairColor={hairColor}
            socialSecurityNumber={socialSecurityNumber}
            driversLicenseNumber={driversLicenseNumber}
            isDead={isDead}
            primaryMugshotPath={primaryMugshotPath}
            nicknames={nicknames}
            fullName={fullName}
            person={personProfileViewModel}
            {...otherProps}
        />
    );
};

export default compose(
    connect(
        createStructuredSelector({
            personProfileViewModelById: personProfileViewModelByIdSelector,
        })
    ),
    withPropsOnChange(
        ['id', 'personProfileViewModelById'],
        ({ id, personProfileViewModelById }) => {
            const personProfileViewModel = personProfileViewModelById(id) || {};
            return {
                personProfileViewModel,
            };
        }
    )
)(PersonProfilePill);

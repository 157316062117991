import { map } from 'lodash';

import type { CaseCreationBatchItemRequest } from '@mark43/rms-api';

type BulkCaseCreationData = {
    caseCreationBatchItemRequest: Omit<
        CaseCreationBatchItemRequest,
        'caseReportLinkCreationFromCaseRequest'
    >;
    reportingEventNumbers: string[];
    recordWithoutRenReportIds: number[];
};

export default function buildCaseCreationBatchItemRequests({
    caseCreationBatchItemRequest,
    reportingEventNumbers,
    recordWithoutRenReportIds,
}: BulkCaseCreationData): CaseCreationBatchItemRequest[] {
    return [
        ...map(reportingEventNumbers, (ren) => ({
            ...caseCreationBatchItemRequest,
            caseReportLinkCreationFromCaseRequest: {
                linkedReportREN: ren,
            },
        })),
        ...map(recordWithoutRenReportIds, (id) => ({
            ...caseCreationBatchItemRequest,
            caseReportLinkCreationFromCaseRequest: {
                linkedRecordWithoutRENId: id,
            },
        })),
    ];
}

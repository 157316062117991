import { EntityOrderedAttribute } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'entityOrderedAttributes';
const entityOrderedAttributeModule = createNormalizedModule<EntityOrderedAttribute>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS

export const entityOrderedAttributesWhereSelector =
    entityOrderedAttributeModule.selectors.entitiesWhereSelector;

// REDUCER
export default entityOrderedAttributeModule.reducerConfig;

import { max, min } from 'lodash';
import React from 'react';
import styled from 'styled-components';

// helpers
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';

// components
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';

const EntityProfileReportsTableHeaderContainer = styled.div`
    float: right;
`;

const TableResultsSummaryContainer = styled(TableResultsSummary)`
    float: left;
    margin-right: 10px;
    padding-top: 7px;
`;

export default function EntityProfileReportsTableHeader({
    from,
    size,
    totalCount,
    onPaginationClick,
}) {
    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const to = min([from + size, totalCount]);
    return (
        <th colSpan={5}>
            <EntityProfileReportsTableHeaderContainer>
                <TableResultsSummaryContainer from={from} to={to} totalResults={totalCount} />
                <Pagination
                    currentPage={currentPage}
                    itemsPerPage={size}
                    itemCount={totalCount}
                    onClick={onPaginationClick}
                    maxEdgeItems={1}
                />
            </EntityProfileReportsTableHeaderContainer>
        </th>
    );
}

import { SystemRuleEnum } from '@mark43/rms-api';

import addRuleId from '../../../../../legacy-redux/validation/helpers/addRuleId';

export const retentionPolicyRequiresPeriodOrIsInfinite = addRuleId(
    SystemRuleEnum.RETENTION_POLICY_REQUIRES_PERIOD_OR_IS_INFINITE.name,
    (formModel) => {
        return !!formModel.periodAmount || !!formModel.isInfinite;
    }
);

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Hidden Department Link Types Resource',
        methods: {
            updateHiddenDepartmentLinkTypes(hiddenDeptLinkTypes) {
                return req({
                    method: 'PUT',
                    url: 'link_types/configuration',
                    data: hiddenDeptLinkTypes,
                });
            },
        },
    });
}

/**
 * Resource for hidding link types in a department.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 * @see
 *   https://docs.google.com/document/d/12dH-fM0RvSCm5td9oHpe3J9V6SzIm42qJKIWW1rvyzM/
 */
const resourceGetter = () => resource;
export default resourceGetter;

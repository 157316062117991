import { RefContextEnum } from '@mark43/rms-api';

import { createFormConfiguration } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';

export const formName = RefContextEnum.FORM_ARREST_CHARGES_LEGACY_OFFENSE_SIDE_PANEL.name;

export const getFormConfiguration = () =>
    createFormConfiguration({
        legacyCharge: { fieldName: fields.CHARGE_LEGACY_CHARGE },
        legacyEventNumber: { fieldName: fields.CHARGE_LEGACY_EVENT_NUMBER },
        legacyChargeSourceAttrId: { fieldName: fields.CHARGE_LEGACY_CHARGE_SOURCE_ATTR_ID },
    });

import _ from 'lodash';

import { ItemProfile } from '@mark43/rms-api';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { ClipsFormData } from '../state/forms/ClipsReportForm';

export const shapeDataForClipsReportSubmission = (
    formData: ClipsFormData,
    reportId: number,
    itemProfileById: (key: string | number | undefined) => ItemProfile | undefined,
    lastFourOfRen: string
) => {
    const persons = _.chain(formData.people)
        .filter('isSelected')
        .reduce((result, value) => {
            const { id } = value;
            const entryName = `${value.entryName} ${lastFourOfRen}`;
            return { ...result, [id]: entryName };
        }, {})
        .value();

    const vehicles = _.chain(formData.vehicles)
        .filter('isSelected')
        .reduce((result, value) => {
            const { id } = value;
            const entryName = `${value.entryName} ${lastFourOfRen}`;
            return { ...result, [id]: entryName };
        }, {})
        .value();

    const { items, firearms } = _.chain(formData.property)
        .filter('isSelected')
        .reduce(
            (result, value) => {
                const { id } = value;
                const entryName = `${value.entryName} ${lastFourOfRen}`;
                const itemProfile = itemProfileById(id);
                const isFirearm = itemProfile?.itemTypeAttrId === globalAttributes.itemType.firearm;
                const returnValue: {
                    items: { [id: number]: string };
                    firearms: { [id: number]: string };
                } = isFirearm
                    ? {
                          items: { ...result.items },
                          firearms: { ...result.firearms, [id]: entryName },
                      }
                    : {
                          firearms: { ...result.firearms },
                          items: { ...result.items, [id]: entryName },
                      };
                return returnValue;
            },
            { items: {}, firearms: {} }
        )
        .value();

    return { reportId, persons, vehicles, firearms, items };
};

import {
    ReportscreateResource,
    CompleteClientReportView,
    ReportCreationRequest,
    TowVehicleIntegrationRequest,
} from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Reports Create Resource',
    methods: {
        createReport(report: ReportCreationRequest) {
            return req<ReportscreateResource.CreateNewReport>({
                method: 'POST',
                url: 'reports/create',
                data: report,
            });
        },

        createArrestReportFromOffenseReport(data) {
            return req({
                method: 'POST',
                url: 'reports/create/arrest/from_offense',
                data,
            }) as Promise<CompleteClientReportView>;
        },

        createReportingEvent(reportingEventNumber: string) {
            return req<ReportscreateResource.CreateReportingEvent>({
                method: 'POST',
                url: `reports/create/reporting_event/${reportingEventNumber}/`,
            });
        },

        createTowVehicleReportByGeneratingRepossession(agencyId: number) {
            return req<ReportscreateResource.CreateTowVehicleReportByGeneratingRepossession>({
                method: 'POST',
                url: `reports/create/tow_vehicle/${agencyId}/`,
            });
        },

        upsertTowVehicleReport(reportingEventNumber: string, data: TowVehicleIntegrationRequest) {
            return req<ReportscreateResource.GetOrCreateTowVehicleReport>({
                method: 'POST',
                url: `reports/create/${reportingEventNumber}/tow_vehicle`,
                data,
            });
        },
    },
});

import { makeResettable } from '~/client-common/helpers/reducerHelpers';

import {
    LOAD_PROFILES_START,
    LOAD_PROFILES_SUCCESS,
    LOAD_PROFILES_FAILURE,
    RESET_STATE,
    STORE_SELECTED_VALUES,
} from './actionTypes';

const initialState = {
    profilesLoaded: false,
    isLoading: false,
    entityTypeToMerge: undefined,
    entityIdsToMerge: undefined,
    error: undefined,
};

const handlers = {
    [LOAD_PROFILES_START]: (state, { payload }) => ({
        ...state,
        isLoading: true,
        entityIdsToMerge: payload.entityIds,
        entityTypeToMerge: payload.entityType,
    }),
    [LOAD_PROFILES_SUCCESS]: (state) => ({ ...state, profilesLoaded: true, isLoading: false }),
    [LOAD_PROFILES_FAILURE]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
    }),
    [STORE_SELECTED_VALUES]: (state, { payload }) => ({ ...state, selectedValues: payload }),
};

const reducer = (state = initialState, action) => {
    const handler = handlers[action.type];
    return handler ? handler(state, action) : state;
};

export default makeResettable(RESET_STATE, reducer, initialState);

import styled from 'styled-components';
import theme from '../../../core/styles/theme';
import getThemeColor, { ThemeColorsT } from '../../../core/utils/getThemeColor';

const MergeEntitiesText = styled.span<{
    float?: 'left' | 'right' | 'none';
    display?: string;
    fontSize?: string;
    fontWeight?: keyof typeof theme.fontWeights;
    color?: ThemeColorsT;
    backgroundColor?: ThemeColorsT;
    textAlign?: string;
    width?: string;
    margin?: string;
    padding?: string;
    fontStyle?: string;
}>`
    ${({ theme, ...props }) => {
        return `
            color: ${getThemeColor(theme, props.color ?? 'darkGrey')};
            font-weight: ${theme.fontWeights[props.fontWeight || 'light']};
            font: ${props.fontSize || '12px'};
            background-color: ${getThemeColor(theme, props.backgroundColor ?? 'transparent')};
            display: ${props.display || 'block'};
            margin: ${props.margin || 'initial'};
            padding: ${props.padding || 'initial'};
            font-style: ${props.fontStyle || 'normal'};
            line-height: 15px;
            width: ${props.width || '100%'};
            text-align: ${props.textAlign || 'left'};
            `;
    }}
`;

export default MergeEntitiesText;

import _, { isString } from 'lodash';

/**
 * Build a new, basic `SavedSearch` object.  This function is used when the user chooses to save a search.  Here, we will return an
 * object containing the query the user would like to save, along with the elastic search type context that the query was run.
 * The Saved Search API only needs these two properties to construct a new `SavedSearch` object.
 * @param {Object}   elasticQuery  The elastic query object, as returned by the `currentQuery` ui state property.
 * @return {Object}  New, basic `SavedSearch` object, that will be persistently saved to the database.
 */
export function buildNewSavedSearchModel(
    elasticQuery,
    elasticSearchType,
    { name, isShared, entityPermissions }
) {
    return {
        isShared,
        name,
        entityPermissions,
        query: convertElasticQueryToSavedSearchQuery(elasticQuery),
        searchType: elasticSearchType,
    };
}

export function buildSavedSearchModelToUpdate(
    savedSearches,
    toUpdateId,
    elasticQuery,
    elasticSearchType,
    { name, entityPermissions, isShared }
) {
    const existingSearch = getSavedSearchById(savedSearches, toUpdateId);
    return {
        ...existingSearch,
        entityPermissions: entityPermissions || existingSearch?.entityPermissions || [],
        isShared: isShared || existingSearch.isShared,
        name: name || existingSearch.name,
        query: convertElasticQueryToSavedSearchQuery(elasticQuery),
        searchType: elasticSearchType,
    };
}

export function sortSavedSearches(savedSearches) {
    return _.sortBy(savedSearches, 'lastExecutedDateUtc').reverse();
}

export function getSavedSearchById(savedSearches, savedSearchId) {
    return _.find(savedSearches, { id: savedSearchId });
}

/**
 * @param    {Object} savedSearchQuery JSON string.
 * @return   {Object}
 * @property {Object} elasticQuery
 */
export function convertSavedSearchQueryToElasticQueryObject(savedSearchQuery) {
    return JSON.parse(savedSearchQuery);
}

/**
 * @param  {Object} savedSearchQuery JSON string.
 * @return {Object} Elastic query.
 */
export function convertSavedSearchQueryToElasticQuery(savedSearchQuery) {
    return (
        _.get(convertSavedSearchQueryToElasticQueryObject(savedSearchQuery), 'elasticQuery') ||
        _.get(convertSavedSearchQueryToElasticQueryObject(savedSearchQuery), 'query')
    );
}

export function convertElasticQueryToSavedSearchQuery(elasticQuery) {
    return isString(elasticQuery) ? elasticQuery : JSON.stringify(elasticQuery);
}

export function buildElasticQueryDisplayString(formattedElasticQuery) {
    let elasticQueryDisplayString = '';
    // eslint-disable-next-line prefer-arrow-callback
    _.forEach(formattedElasticQuery, function (filterGroup) {
        if (!filterGroup.fields) {
            elasticQueryDisplayString += `${filterGroup.label}: ${filterGroup.display} `;
        } else {
            if (filterGroup.title) {
                elasticQueryDisplayString += `${filterGroup.title.toUpperCase()} `;
            }
            // eslint-disable-next-line prefer-arrow-callback
            _.forEach(filterGroup.fields, function (field) {
                elasticQueryDisplayString += `${field.label}: ${field.display} `;
            });
        }
    });
    return elasticQueryDisplayString;
}

import _ from 'lodash';

import renChangeActionTypes from '../actions/types/renChangeActionTypes';

const initialUiState = {
    loading: false,
    changeEnabled: false,
    validRen: null,
    handleSubmit: _.noop,
    message: null,
    error: false,
};

export function renChangeUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case renChangeActionTypes.CLEAR_REN_CHANGE_MODAL:
            return initialUiState;
        case renChangeActionTypes.UPDATE_REN_START:
            return {
                ...state,
                loading: true,
            };
        case renChangeActionTypes.UPDATE_REN_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case renChangeActionTypes.UPDATE_REN_FAILURE:
            return {
                ...state,
                validRen: null,
                loading: false,
                message: action.payload,
                error: true,
            };
        case renChangeActionTypes.VALIDATE_REN_UPDATE_START:
            return {
                ...state,
                loading: true,
            };
        case renChangeActionTypes.VALIDATE_REN_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                validRen: action.payload.newRen,
            };
        case renChangeActionTypes.VALIDATE_REN_UPDATE_FAILURE:
            return {
                ...state,
                validRen: null,
                loading: false,
                message: action.payload,
                error: true,
            };
        case renChangeActionTypes.ENABLE_REN_UPDATE:
            return {
                ...state,
                message: action.payload,
                changeEnabled: true,
            };
        case renChangeActionTypes.DISABLE_REN_UPDATE:
            return {
                ...state,
                validRen: null,
                message: action.payload,
                changeEnabled: false,
            };
        case renChangeActionTypes.STORE_HANDLE_SUBMIT:
            return {
                ...state,
                handleSubmit: action.payload,
            };
        default:
            return state;
    }
}

export function renChangeFormReducer(state, action) {
    switch (action.type) {
        case renChangeActionTypes.VALIDATE_REN_UPDATE_SUCCESS:
            return {
                ...state,
                newRen: {
                    ...state.newRen,
                    value: action.payload.newRen,
                },
            };
        default:
            return state;
    }
}

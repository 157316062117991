import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { cssVar } from 'arc';

import testIds from '../../../../core/testIds';
import HelpText, { HelpTextProps } from '../../../../legacy-redux/components/core/HelpText';
import { arbiterMFTInput } from '../../arbiter';
import FieldLink from '../../components/links/FieldLink';

const ASTERISK = '*';
const RequiredIndicator = styled.span`
    color: ${cssVar('arc.colors.negative.default')};
    padding-left: 8px;
`;

const FormElementLabel = ({
    extraLabelContent,
    fieldName,
    helpText,
    helpTextCollisionBoundary,
    isRequired,
    label,
    labelClassName,
    width,
}: {
    extraLabelContent?: React.ReactNode;
    fieldName?: string;
    helpText?: string;
    helpTextCollisionBoundary?: HelpTextProps['collisionBoundary'];
    isRequired?: boolean;
    label: React.ReactNode;
    labelClassName?: string;
    width?: string | number;
}) => {
    const RequiredAsterisk = () => (
        <RequiredIndicator
            className="mark43-required-indicator"
            data-test-id={testIds.FORM_ELEMENT_REQUIRED_INDICATOR}
        >
            {ASTERISK}
        </RequiredIndicator>
    );

    return (
        <>
            <label
                className={classNames(
                    'mark43-form-label',
                    'mark43-form-element-label',
                    labelClassName
                )}
            >
                {label}
                {isRequired && <RequiredAsterisk />}
                {helpText && (
                    <HelpText content={helpText} collisionBoundary={helpTextCollisionBoundary} />
                )}
            </label>
            {!!extraLabelContent && extraLabelContent}
            {fieldName && (
                <FieldLink fieldName={fieldName} className="mark43-form-label" style={{ width }} />
            )}
        </>
    );
};

export default FormElementLabel;
export const ArbiterMFTFormElementLabel = arbiterMFTInput(FormElementLabel);

import { Street } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

const streetsModule = createNormalizedModule<Street>({
    type: 'streets',
});

export const storeStreets = streetsModule.actionCreators.storeEntities;
export const replaceStreetsWhere = streetsModule.actionCreators.replaceEntitiesWhere;

export const streetsSelector = streetsModule.selectors.entitiesSelector;
export const streetByIdSelector = streetsModule.selectors.entityByIdSelector;

export default streetsModule.reducerConfig;

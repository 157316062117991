import { ApprovalStatusEnum } from '@mark43/rms-api';
import { storeCaseReviews } from '~/client-common/core/domain/case-reviews/state/data';
import { replaceCaseApprovalStatusesWhere } from '~/client-common/core/domain/case-approval-statuses/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import caseResource from '../../../core/resources/caseResource';
import caseReviewForm from '../form/caseReviewForm';
import { currentUserIdSelector } from '../../../../core/current-user/state/ui';
import { currentCaseIdSelector } from '../../../core/state/ui';
import {
    saveBoxStart,
    saveBoxHalt,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import { allCasesSearch } from '../../../all-cases/state/ui';
import { myCasesSearch } from '../../../my-cases/state/ui';
import { setIsRejection } from '../ui';

const context = { name: boxEnum.CASE_REVIEW_SIDE_PANEL };

export function submitCaseReviewForm(
    options = { isRequestForApproval: false, isApproval: false, isRejection: false }
) {
    return (dispatch, getState) => {
        const state = getState();
        const author = currentUserIdSelector(state);
        const caseId = currentCaseIdSelector(state);

        dispatch(
            caseReviewForm.actionCreators.submit((formData) => {
                dispatch(
                    addReviewToCase(
                        caseReviewForm.convertFromFormModel(formData, {
                            author,
                            caseId,
                            ...options,
                        })
                    )
                );
            })
        )
            .then(() => dispatch(setIsRejection(false)))
            .catch(() => {
                dispatch(setIsRejection(false));
                dispatch(saveBoxHalt(context));
            });
    };
}

function addReviewToCase(caseReviewRequest) {
    return (dispatch) => {
        dispatch(saveBoxStart(context));

        return caseResource
            .addReviewToCase(caseReviewRequest)
            .then(({ caseReview, caseApprovalStatus }) => {
                dispatch(storeCaseReviews(caseReview));

                let newApprovalStatus = null;
                if (caseReview.isRequestForApproval) {
                    newApprovalStatus = ApprovalStatusEnum.SUBMITTED.name;
                } else if (caseReview.isApproval) {
                    newApprovalStatus = ApprovalStatusEnum.APPROVED.name;
                } else if (caseReview.isRejection) {
                    newApprovalStatus = ApprovalStatusEnum.REJECTED.name;
                }
                if (newApprovalStatus) {
                    dispatch(
                        allCasesSearch.actionCreators.patchResultsWhere(
                            { approvalStatus: newApprovalStatus },
                            { id: caseReview.caseId }
                        )
                    );
                    dispatch(
                        myCasesSearch.actionCreators.patchResultsWhere(
                            { approvalStatus: newApprovalStatus },
                            { id: caseReview.caseId }
                        )
                    );
                }

                // caseApprovalStatus will be null if the review was just a
                // non-approval-status related comment
                if (caseApprovalStatus) {
                    dispatch(
                        replaceCaseApprovalStatusesWhere(
                            { caseId: caseApprovalStatus.caseId },
                            caseApprovalStatus
                        )
                    );
                }

                dispatch(caseReviewForm.actionCreators.reset());
                // saveBoxHalt and not saveBoxSuccess
                // because we don't want to close the sidepanel
                dispatch(saveBoxHalt(context));
            })
            .catch((err) => {
                dispatch(saveBoxFailure(context, err.message));
            });
    };
}

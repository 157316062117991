import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { RmsDispatch } from '../../../../../core/typings/redux';
import Modal from '../../../../core/overlays/components/Modal';
import { useOverlayStore } from '../../../../core/overlays/hooks/useOverlayStore';
import {
    queueComplianceResubmission,
    removeComplianceResubmission,
} from '../../state/data/complianceResubmission';

type ComplianceResubmissionModalCustomProps = {
    reportId: number;
    resubmissionId: number;
};
type ComplianceResubmissionOverlayIds = keyof Pick<
    typeof overlayIdEnum,
    'COMPLIANCE_RESUBMISSION_MODAL'
>;
type UseComplianceResubmissionModalProps = {
    overlayId: ComplianceResubmissionOverlayIds;
    reportId: number;
    resubmissionId?: number;
};
type ComplianceResubmissionModalProps = {
    overlayId: ComplianceResubmissionOverlayIds;
    resubmissionId?: number;
};

const strings = componentStrings.reports.core.ReportStatusCommentsCard.resubmissions;

export function useOpenComplianceResubmissionModal(props: UseComplianceResubmissionModalProps) {
    const { overlayId, reportId, resubmissionId } = props;

    const overlayStore = useOverlayStore<ComplianceResubmissionModalCustomProps>();
    return useCallback(() => overlayStore.open(overlayId, { reportId, resubmissionId }), [
        overlayId,
        overlayStore,
        reportId,
        resubmissionId,
    ]);
}

const ComplianceResubmissionModal: React.FC<ComplianceResubmissionModalProps> = (props) => {
    const { overlayId, resubmissionId } = props;
    const dispatch = useDispatch<RmsDispatch>();
    const reportById = useSelector(reportByIdSelector);
    const overlayStore = useOverlayStore<UseComplianceResubmissionModalProps>();

    const getOkButtonText = () => {
        return !!resubmissionId ? strings.removeButton : strings.addButton;
    };

    const getBodyText = () => {
        return !!resubmissionId
            ? strings.removeResubmissionBodyText
            : strings.addResubmissionBodyText;
    };

    const saveHandler = useCallback(() => {
        const customProps = overlayStore.getStateForId(overlayId).customProperties;
        const reportId = customProps.reportId;
        const resubmissionId = customProps.resubmissionId;
        if (resubmissionId) {
            dispatch(removeComplianceResubmission(reportId, resubmissionId));
        } else {
            const currentReport = reportById(reportId);
            if (currentReport) {
                dispatch(
                    queueComplianceResubmission(
                        reportId,
                        currentReport.agencyId,
                        currentReport.departmentId
                    )
                );
            }
        }
    }, [dispatch, overlayId, overlayStore, reportById]);

    return (
        <Modal<UseComplianceResubmissionModalProps>
            id={overlayId}
            defaultErrorMessage={strings.errorModalMessage}
            okText={getOkButtonText()}
            title={strings.title}
            onSave={saveHandler}
        >
            {getBodyText()}
        </Modal>
    );
};

export default withRouter(ComplianceResubmissionModal);

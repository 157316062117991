import { Form, createField, createFormConfiguration, InferFormDataShape } from 'markformythree';
import * as yup from 'yup';
import { SendEmailRequest } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    convertYupErrorShapeToMFTErrorShape,
    convertYupSuccessShapeToMFTSuccessShape,
} from '../../../validation/yupMftValidation';
import { DEFAULT_SUBJECT } from '../../configuration';

const validations = componentStrings.exports.emails.validations;

export const sendEmailFormConfiguration = createFormConfiguration({
    externalRecipients: createField<string[]>({}),
    internalUsers: createField<number[]>({}),
    subject: createField<string>({}),
    message: createField<string>({}),
});

export type SendEmailFormConfiguration = typeof sendEmailFormConfiguration;
export type SendEmailFormData = InferFormDataShape<SendEmailFormConfiguration>;

export const validationSchema = yup
    .object()
    .shape({
        externalRecipients: yup.array().of(yup.string().email(validations.invalidEmail)),
        internalUsers: yup.array().of(yup.number()),
        subject: yup.string().required(validations.subjectRequired),
        message: yup.string(),
    })
    .test(
        'recipients',
        validations.recipientRequired,
        (formData) => !!(formData?.internalUsers?.length || formData?.externalRecipients?.length)
    );

export const validationHandler: Form['props']['onValidate'] = ({ formState, eventType }) => {
    const $form = formState.ui.$form;

    return validationSchema
        .validate(formState.model, { abortEarly: false })
        .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
        .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
};

export const convertFromFormModel = ({
    externalRecipients,
    internalUsers,
    subject,
    message,
}: SendEmailFormData): SendEmailRequest => ({
    externalRecipients: externalRecipients ?? [],
    internalUsers: internalUsers ?? [],
    subject: subject ?? DEFAULT_SUBJECT,
    message,
});

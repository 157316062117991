import React from 'react';
import styled from 'styled-components';
import { RemoveButton } from '../../forms/components/NItems';
import NItemsItemSection from '../../forms/components/NItemsItemSection';
import Icon, { iconSizes, iconTypes } from '../../components/Icon';

const FormSectionRemoveButton = styled(RemoveButton)`
    float: none;
    align-self: flex-start;
`;

type PropsT = {
    removeItem?: () => void;
    className?: string;
};

export const FormSection: React.FC<PropsT> = ({ children, removeItem, className }) => (
    <NItemsItemSection
        className={className}
        children={children}
        deleteButton={
            removeItem ? (
                <FormSectionRemoveButton
                    onClick={removeItem}
                    icon={
                        <Icon
                            size={iconSizes.MEDIUM}
                            color="cobaltBlue"
                            type={iconTypes.TRASH_CAN}
                        />
                    }
                />
            ) : null
        }
    />
);

import { createField, createFormConfiguration, InferFormDataShape } from 'markformythree';

export type OrganizationSearchToAddFormConfiguration = ReturnType<
    typeof createOrganizationSearchToAddFormConfiguration
>;
type OrganizationSearchToAddFormModel = InferFormDataShape<OrganizationSearchToAddFormConfiguration>;

export const ORGANIZATION_SEARCH_TO_ADD_FORM = 'ORGANIZATION_SEARCH_TO_ADD_FORM';

type OrganizationSearchConvertFromFormModelResult = {
    involvedLocations:
        | {
              locationAddress: {
                  streetAddress: string;
              };
          }[]
        | undefined;
    quickSearchQuery: string | undefined;
    organizationTypeAttrIds: number[][] | undefined;
};

export function createOrganizationSearchToAddFormConfiguration() {
    return createFormConfiguration({
        quickSearchQuery: createField<string>({}),
        locationStreetAddress: createField<string>({}),
        organizationTypeAttrIds: createField<number[]>({}),
    });
}

export const convertFromFormModel = (
    formModel: OrganizationSearchToAddFormModel
): Partial<OrganizationSearchConvertFromFormModelResult> => {
    const { locationStreetAddress, quickSearchQuery, organizationTypeAttrIds } = formModel;

    return {
        involvedLocations: locationStreetAddress
            ? [{ locationAddress: { streetAddress: locationStreetAddress } }]
            : undefined,
        quickSearchQuery: quickSearchQuery || undefined,
        organizationTypeAttrIds: organizationTypeAttrIds ? [organizationTypeAttrIds] : undefined,
    };
};

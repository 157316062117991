import React from 'react';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import withFields from '~/client-common/core/fields/components/withFields';
import {
    DISPLAY_CASE_ASSIGNEE,
    DISPLAY_CASE_ASSISTING_INVESTIGATORS,
} from '~/client-common/core/enums/universal/fields';
import { rolesForUserSelector } from '~/client-common/core/domain/roles/state/data';
import { currentUserIdSelector } from '../../../core/current-user/state/ui';

const YourInvolvementCell = function ({
    elasticCase,
    currentUserId,
    rolesForUser,
    fieldDisplayNames,
}) {
    const currentUserRoles = rolesForUser(currentUserId);
    const isCurrentUserAssignee = currentUserRoles[elasticCase.assigneeRoleId] !== undefined;
    const involvementString = isCurrentUserAssignee
        ? fieldDisplayNames.DISPLAY_CASE_ASSIGNEE
        : fieldDisplayNames.DISPLAY_CASE_ASSISTING_INVESTIGATORS;

    return <>{involvementString}</>;
};

const mapStateToProps = createStructuredSelector({
    currentUserId: currentUserIdSelector,
    rolesForUser: rolesForUserSelector,
});

export default compose(
    mapProps((ownerProps) => ({ elasticCase: ownerProps })),
    connect(mapStateToProps),
    withFields([DISPLAY_CASE_ASSIGNEE, DISPLAY_CASE_ASSISTING_INVESTIGATORS])
)(YourInvolvementCell);

import { flatMap, isArray, size } from 'lodash';

import { attributesWithParentAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { filterAttributesByType } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

/**
 * Get the number of attributes with the given parent and the given attribute type. The
 *   parentAttributeId value comes from a form field.
 *
 * Currently we are not filtering out expired child attributes. This causes a minor bug where if
 *   every child attribute is expired, the field input (dropdown or checkboxes) will have 0 options
 *   and still appear when it shouldn't. But if we were to filter out expired child attributes, then
 *   the input will not appear if the field value is expired, which would be a worse bug. To handle
 *   both problems, a solution may be to create a new RuleFieldGetter called
 *   GET_ATTRIBUTE_IS_EXPIRED.
 */
export const getChildAttributeCount = (getState) => {
    return (parentAttributeId, { attributeType } = {}) => {
        if (isUndefinedOrNull(parentAttributeId)) {
            return 0;
        }

        const state = getState();
        const attributesWithParentAttributeId = attributesWithParentAttributeIdSelector(state);
        const attributes = !isArray(parentAttributeId)
            ? attributesWithParentAttributeId(parentAttributeId)
            : flatMap(parentAttributeId, (id) => attributesWithParentAttributeId(id));
        return size(filterAttributesByType(attributes, attributeType));
    };
};

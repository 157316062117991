import { ConfiguredFormPropertyAliasView, MetaInstanceView } from '@mark43/rms-api';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

/**
 * Creates an object which will map each provided alias to a value
 */
export function resolveAliasValues({
    configuredEntityPropertyValues,
    configuredFormPropertyAliasViews,
}: {
    configuredFormPropertyAliasViews?: ConfiguredFormPropertyAliasView[];
    configuredEntityPropertyValues?: MetaInstanceView['configuredEntityPropertyValues'];
}): Partial<Record<string, string | number>> {
    const indexedAliasValues =
        configuredEntityPropertyValues?.reduce<Partial<Record<number, string | number>>>(
            (acc, propertyValue) => {
                const { valueType, value } = propertyValue;
                // TODO do we need to cast dates to actual date objects?
                acc[propertyValue.id] = isUndefinedOrNull(value)
                    ? undefined
                    : valueType === 'ID' || valueType === 'BIGINT' || valueType === 'DECIMAL'
                    ? Number(value)
                    : String(value);
                return acc;
            },
            {}
        ) ?? {};

    return (
        configuredFormPropertyAliasViews?.reduce<Partial<Record<string, string | number>>>(
            (acc, alias) => {
                acc[alias.alias] = indexedAliasValues[alias.configuredEntityPropertyId];
                return acc;
            },
            {}
        ) ?? {}
    );
}

import factory from '../../../../../legacy-redux/actions/types/actionTypesFactory';

export default factory('evidence', [
    'SAVE_SETTINGS_START',
    'SAVE_SETTINGS_SUCCESS',
    'SAVE_SETTINGS_FAILURE',
    'LOAD_SETTINGS_START',
    'LOAD_SETTINGS_SUCCESS',
    'LOAD_SETTINGS_FAILURE',
]);

import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { createStructuredSelector } from 'reselect';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { createAttachmentsResultsSelector } from '~/client-common/core/domain/elastic-attachments/state/data';
import createSearchModule from '../../../core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import advancedSearchAttachmentsForm, {
    convertAdvancedSearchAttachmentsFormModelToFilterGroups,
    convertAdvancedSearchAttachmentsElasticQueryToFormModel,
} from '../forms/advancedSearchAttachmentsForm';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../core/state/ui';

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object}  boundSelectors extra selectors needed by search module
 * @return {Object}   Array of filter group view models.
 */
function convertAdvancedSearchAttachmentsElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    return convertAdvancedSearchAttachmentsFormModelToFilterGroups(
        convertAdvancedSearchAttachmentsElasticQueryToFormModel(elasticQuery),
        formatFieldValue,
        boundSelectors
    );
}

/**
 * Redux module for advanced search attachments.
 * @type {Object}
 */
export default createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.ATTACHMENTS.name,
    baseUiSelector: (state) => state.ui.advancedSearch.attachments,
    form: advancedSearchAttachmentsForm,
    defaultTableState: {
        activeColumnKeys: {
            date: 'uploadedDate',
        },
    },
    elasticQueryToFilterGroups: convertAdvancedSearchAttachmentsElasticQueryToFilterGroups,
    resourceMethod: elasticSearchResource.searchAttachments,
    resultsContainerClassName: 'attachments-results-container',
    createResultsViewModelsSelector: createAttachmentsResultsSelector,
    transformElasticQueryBeforeSearchSelector: (state) => (elasticQuery) => {
        const transformIds = transformElasticQueryIdsToDisplayValuesSelector(state);
        const queryWithTransformedIds = transformIds(elasticQuery);
        return queryWithTransformedIds;
    },
    selectorToBind: createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
    }),
});

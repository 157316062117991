import React from 'react';
import { upperFirst } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { isUnknown } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';

import { offenseModifyingSupplementRenOffenseReportIdSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import testIds from '../../../../core/testIds';
import FormGroup from '../../forms/components/FormGroup';
import { ArbiterMFTText } from '../../forms/components/Text';
import { PERSON_PROFILE_FORM_FIRST_NAME } from '../config/personProfileFieldIds';
import { ExpandableFormLabel, LabelExpander } from '../../components/ExpandableFormLabel';
import { DisableableTextInputWithCheckbox } from './DisableableTextInputWithCheckbox';

const strings = componentStrings.core.PersonProfileForm.PersonProfileFormGeneralPersonRelatedFields;

const mapStateToProps = createStructuredSelector({
    offenseModifyingSupplementRenOffenseReportId: offenseModifyingSupplementRenOffenseReportIdSelector,
});
const PersonProfileFormNamesComponent = ({
    form,
    offenseModifyingSupplementRenOffenseReportId,
}) => {
    const isOms = !!offenseModifyingSupplementRenOffenseReportId;
    const isUnknownProfile = isUnknown(form.get());
    const isExistingProfile = !!form.get('id');
    const disableCheckbox = isExistingProfile && !(isOms && isUnknownProfile);

    return (
        <FormGroup>
            <DisableableTextInputWithCheckbox
                checkboxFieldPath="firstNameUnknown"
                fieldToDisablePath="firstName"
                disableCheckbox={disableCheckbox}
                textFieldId={PERSON_PROFILE_FORM_FIRST_NAME}
                doUpperFirst={true}
            />
            <ArbiterMFTText path="middleName" length="md" alterValue={upperFirst} />
            <DisableableTextInputWithCheckbox
                checkboxFieldPath="lastNameUnknown"
                fieldToDisablePath="lastName"
                disableCheckbox={disableCheckbox}
                doUpperFirst={true}
                length="lg"
            />
            <ExpandableFormLabel
                formPathsToObserve={['suffix', 'title']}
                expanderContent={(showContent) => (
                    <LabelExpander
                        position="bottom"
                        onClick={showContent}
                        data-test-id={testIds.PERSON_SIDE_PANEL_ADD_SUFFIX}
                    >
                        {strings.addSuffix}
                    </LabelExpander>
                )}
            >
                <ArbiterMFTText path="title" length="lg" />
                <ArbiterMFTText path="suffix" length="sm" />
            </ExpandableFormLabel>
        </FormGroup>
    );
};

export const PersonProfileFormNames = connect(mapStateToProps)(PersonProfileFormNamesComponent);

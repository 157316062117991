import _, { pick } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

export default createFormModule({
    formName: formClientEnum.EDIT_ROLE_USERS_MANAGE_STATUS,
    convertToFormModel: (userRoles) => {
        return {
            users: _(userRoles)
                .keyBy('userId')
                .mapValues((val) => pick(val, 'isUserAdmin'))
                .value(),
        };
    },
    fieldViewModels: {
        type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
        key: 'users',
        fields: buildFlatFormFieldViewModels(['roleId', 'userId', 'isUserAdmin']),
    },
});

import {
    LOAD_ATTRIBUTES_BEGIN,
    LOAD_ATTRIBUTES_SUCCESS,
    LOAD_ATTRIBUTES_FAILURE,
} from './actionTypes';

const createMergeShapeReduce = (toMerge) => (acc, type) => {
    acc[type] = {
        ...acc[type],
        ...toMerge,
    };
    return acc;
};

export const uiReducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD_ATTRIBUTES_BEGIN:
            return action.payload.attributeTypes.reduce(
                createMergeShapeReduce({
                    loading: true,
                    loaded: false,
                    error: false,
                }),
                { ...state }
            );
        case LOAD_ATTRIBUTES_SUCCESS:
            return action.payload.attributeTypes.reduce(
                createMergeShapeReduce({
                    loading: false,
                    loaded: true,
                }),
                { ...state }
            );

        case LOAD_ATTRIBUTES_FAILURE:
            return action.payload.attributeTypes.reduce(
                createMergeShapeReduce({
                    loading: false,
                    loaded: false,
                    error: true,
                }),
                { ...state }
            );

        default:
            return state;
    }
};

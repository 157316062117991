import React from 'react';
import styled from 'styled-components';
import PersonProfileTitleSummary from '../../../records/core/components/summaries/PersonProfileTitleSummary';

const PersonContainer = styled.div`
    margin-top: 10px;
`;

export default class NotepadPerson extends React.Component {
    render() {
        return (
            <PersonContainer>
                <PersonProfileTitleSummary
                    disableLink={this.props.disableLink}
                    personProfileViewModel={this.props.personProfileViewModel}
                />
            </PersonContainer>
        );
    }
}

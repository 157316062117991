import { ProductModuleEnum, EntityTypeEnum, PreferenceEnum, LinkTypesEnum } from '@mark43/rms-api';
import _, { includes, parseInt } from 'lodash';
import { createSelector } from 'reselect';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import {
    agencyProfilesSelector,
    agencyProfilesWhereSelector,
} from '~/client-common/core/domain/agency-profiles/state/data';
import { departmentProfileByIdSelector } from '~/client-common/core/domain/department-profiles/state/data';
import getAttachmentsResource from '~/client-common/core/domain/attachments/resources/attachmentsResource';
import {
    NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP,
    attachmentsByEntitySelector,
} from '~/client-common/core/domain/attachments/state/data';
import agencyProfileAdminForm from '../forms/agencyProfileAdminForm';
import {
    LOAD_AGENCY_PROFILES_START,
    LOAD_AGENCY_PROFILES_SUCCESS,
    LOAD_AGENCY_PROFILES_FAILURE,
    SAVE_AGENCY_PROFILE_START,
    SAVE_AGENCY_PROFILE_SUCCESS,
    SAVE_AGENCY_PROFILE_FAILURE,
    saveAgencyProfile,
    saveAgencyProfileLogo,
} from '../data';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import automaticRenGenerationResource from '../../resources/automaticRenGenerationResource';
import {
    departmentPreferenceLinksDisplaySelector,
    agencyPreferenceLinksDisplaySelector,
} from '../../../preferences/state/ui';

const SELECT_AGENCY_PROFILE_START = 'agency-profiles/SELECT_AGENCY_PROFILE_START';
const SELECT_AGENCY_PROFILE_SUCCESS = 'agency-profiles/SELECT_AGENCY_PROFILE_SUCCESS';
const SELECT_AGENCY_PROFILE_FAILURE = 'agency-profiles/SELECT_AGENCY_PROFILE_FAILURE';
const OPEN_NEW_AGENCY_PROFILE_FORM = 'agency-profiles/OPEN_NEW_AGENCY_PROFILE_FORM';
const LOAD_AGENCY_PROFILE_ATTACHMENTS_SUCCESS =
    'agency-profiles/LOAD_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS';

function selectAgencyProfileStart() {
    return {
        type: SELECT_AGENCY_PROFILE_START,
    };
}

function selectAgencyProfileSuccess(id) {
    return {
        type: SELECT_AGENCY_PROFILE_SUCCESS,
        payload: id,
    };
}

function selectAgencyProfileFailure() {
    return {
        type: SELECT_AGENCY_PROFILE_FAILURE,
    };
}

const agencyPreferenceLinkByPreferenceSelector = createSelector(
    agencyPreferenceLinksDisplaySelector,
    departmentPreferenceLinksDisplaySelector,
    (agencyPreferenceLinks, departmentPreferenceLinks) => (agencyId, preference) => {
        const departmentPreference = _.find(departmentPreferenceLinks, { preference });

        const agencyPreference = _.find(agencyPreferenceLinks, {
            preference,
            entityId: _.parseInt(agencyId),
        });

        return agencyPreference || departmentPreference;
    }
);

function setAgencyPreferenceLinks(id) {
    return function (dispatch, getState) {
        const state = getState();

        const dispositionRequiredPreference = agencyPreferenceLinkByPreferenceSelector(state)(
            id,
            PreferenceEnum.IS_DISPOSITION_REQUIRED_TO_CLOSE.name
        );

        const eventBasedMultiRenGenPreference = agencyPreferenceLinkByPreferenceSelector(state)(
            id,
            PreferenceEnum.AUTOMATIC_REN_GENERATION_CALL_FOR_SERVICE_BASED.name
        );

        const unitBasedMultiRenGenPreference = agencyPreferenceLinkByPreferenceSelector(state)(
            id,
            PreferenceEnum.AUTOMATIC_REN_GENERATION_UNIT_STATUS_BASED.name
        );

        dispatch(
            agencyProfileAdminForm.actionCreators.changePath(
                'agencyPreferences.dispositionRequired',
                dispositionRequiredPreference
            )
        );

        dispatch(
            agencyProfileAdminForm.actionCreators.changePath(
                'agencyPreferences.eventBasedMultiRenGen',
                eventBasedMultiRenGenPreference
            )
        );

        dispatch(
            agencyProfileAdminForm.actionCreators.changePath(
                'agencyPreferences.unitBasedMultiRenGen',
                unitBasedMultiRenGenPreference
            )
        );
    };
}

export function createInitialAgencyAdminFormModel() {
    return function (dispatch) {
        dispatch(agencyProfileAdminForm.actionCreators.changePath('multiRenGenSettings', {}));

        dispatch(agencyProfileAdminForm.actionCreators.changePath('agencyProfile', {}));
        dispatch(setAgencyPreferenceLinks());
    };
}

function selectAgencyAutomaticRenGenSettings(agencyId) {
    return function (dispatch) {
        automaticRenGenerationResource
            .getPreferences(agencyId)
            .then((preferences) => {
                const cfsPreferences = _.filter(
                    preferences,
                    (preference) => preference.entityType === EntityTypeEnum.CALL_FOR_SERVICE.name
                );
                const unitStatusPrefereces = _.filter(
                    preferences,
                    (preference) => preference.entityType === EntityTypeEnum.UNIT_STATE.name
                );
                const callForServiceIds = _.flatMap(cfsPreferences, (item) => item.entityId);
                const unitStatusIds = _.flatMap(unitStatusPrefereces, (item) => item.entityId);

                dispatch(
                    agencyProfileAdminForm.actionCreators.changePath(
                        'multiRenGenSettings.callForServiceIds',
                        callForServiceIds
                    )
                );

                dispatch(
                    agencyProfileAdminForm.actionCreators.changePath(
                        'multiRenGenSettings.unitStatusIds',
                        unitStatusIds
                    )
                );
            })
            .catch(() => dispatch(selectAgencyProfileFailure('Failed to get agency profile.')));
    };
}

function loadAgencyProfileAttachmentsSuccess(attachments) {
    return {
        type: LOAD_AGENCY_PROFILE_ATTACHMENTS_SUCCESS,
        payload: attachments,
    };
}

function loadAgencyProfileAttachments(agencyId) {
    const attachmentsResource = getAttachmentsResource();
    return attachmentsResource.loadAttachmentsByEntityId(EntityTypeEnum.AGENCY.name, agencyId);
}

/**
 * Dispatches the actions required to display an agency on the page
 *
 * @param {number} id id of the agency
 */
export function selectAgencyProfile(id) {
    return (dispatch, getState, dependencies) => {
        dispatch(selectAgencyProfileStart());
        const state = getState();
        const agencyProfile = agencyProfilesSelector(state)[id];
        const existingAttachments = attachmentsByEntitySelector(getState())(
            EntityTypeEnum.AGENCY.name,
            id
        );
        const numberOfAgencyLogoAttachments = _(existingAttachments)
            .filter({ linkType: LinkTypesEnum.AGENCY_PROFILE_LOGO })
            .size();
        const agencyLogosFound = numberOfAgencyLogoAttachments > 0;
        const agencyProfileSelectionDispatchers = function (hasCadModuleArg, agencyProfileArg) {
            dispatch(agencyProfileAdminForm.actionCreators.change(agencyProfileArg));

            if (hasCadModuleArg) {
                dispatch(setAgencyPreferenceLinks(agencyProfileArg.id));
                dispatch(selectAgencyAutomaticRenGenSettings(agencyProfileArg.id));
            }
            dispatch(selectAgencyProfileSuccess(agencyProfileArg.id));
        };

        if (agencyProfile) {
            const hasCadModule = isProductModuleActiveSelector(state)(ProductModuleEnum.CAD.name);

            if (agencyLogosFound) {
                agencyProfileSelectionDispatchers(hasCadModule, agencyProfile);
            } else {
                loadAgencyProfileAttachments(id)
                    .then((attachments) => {
                        dispatch(
                            dependencies.nexus.withEntityItems(
                                {
                                    [ATTACHMENTS_NEXUS_STATE_PROP]: attachments,
                                },
                                loadAgencyProfileAttachmentsSuccess(attachments)
                            )
                        );
                    })
                    .then(() => {
                        agencyProfileSelectionDispatchers(hasCadModule, agencyProfile);
                    })
                    .catch(() => {
                        dispatch(
                            selectAgencyProfileFailure('Failed to get agency profile attachments.')
                        );
                    });
            }
        } else {
            dispatch(selectAgencyProfileFailure('Failed to get agency profile.'));
        }
    };
}

export function openNewAgencyProfileForm() {
    return {
        type: OPEN_NEW_AGENCY_PROFILE_FORM,
    };
}

const initialUiState = {
    selectedAgencyId: null,
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    agencyLoadError: null,
};

/**
 * Agency Profile Redux Reducer
 *
 * @param {Object} [state=initialUiState]
 * @param {Object} action
 * @returns
 */
export default function agencyProfilesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case LOAD_AGENCY_PROFILES_START:
            return {
                ...state,
                loadingList: true,
                selectedAgencyId: null,
                listError: null,
            };
        case LOAD_AGENCY_PROFILES_SUCCESS:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_AGENCY_PROFILES_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case SAVE_AGENCY_PROFILE_START:
            return {
                ...state,
                submittingForm: true,
            };
        case SAVE_AGENCY_PROFILE_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case SAVE_AGENCY_PROFILE_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_AGENCY_PROFILE_SUCCESS:
            return {
                ...state,
                definitionLoadError: null,
                selectedAgencyId: parseInt(action.payload),
                listError: null,
                formError: null,
            };
        case SELECT_AGENCY_PROFILE_FAILURE:
            return {
                ...state,
                agencyLoadError: action.payload,
            };
        case OPEN_NEW_AGENCY_PROFILE_FORM:
            return {
                ...state,
                formError: null,
                agencyLoadError: null,
                selectedAgencyId: null,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.agencyProfilesAdmin;

/**
 *
 * @param {number} id
 * @returns the corresponding agency profile for the provided id
 */
export const agencyProfileViewModelByIdSelector = createSelector(
    agencyProfilesSelector,
    (agencyProfiles) => (id) => agencyProfiles[id]
);

/**
 * Takes agencyProfilesWhereSelector, uiSelector and currentUserDepartmentIdSelector,
 * builds a list of the agencies scoped by current department id with
 * {path, title, key, code, selected} structure that will be used in the AdminList UI.
 *
 * @param {Function} agencyProfilesWhere
 * @param {Object} ui
 * @param {Number} currentUserDepartmentId
 * @returns a list of the agencies with
 * {path, title, key, code, selected} structure that will be used in the AdminList UI.
 */
export const agencyListItemsSelector = createSelector(
    agencyProfilesWhereSelector,
    uiSelector,
    currentUserDepartmentIdSelector,
    (agencyProfilesWhere, ui, currentUserDepartmentId) =>
        _(agencyProfilesWhere({ departmentId: currentUserDepartmentId }))
            .map((agency) => {
                return {
                    path: `/admin/agency/${agency.agencyId}`,
                    title: agency.agencyName,
                    key: agency.agencyId,
                    code: agency.agencyCode,
                    selected: agency.agencyId === ui.selectedAgencyId,
                    isDefault: agency.isDefault,
                    defaultLabel: agency.isDefault ? 'Default Agency' : undefined,
                };
            })
            .sortBy('title')
            .value()
);

/**
 * Checks if the modified/new agency has a unique agencyCode
 *
 *
 * @param {Object[]} agencyProfiles list of saved agency profiles
 * @param {Object} agencyProfile updated/new agency
 * @returns true if there's not dup agencyCode
 */
function isAgencyCodeUnique(agencyProfiles, agencyProfile) {
    const agencyCodes = _(agencyProfiles)
        // don't compare with currently selected profile
        .omitBy((agencyListItem) => agencyListItem.agencyId === agencyProfile.agencyId)
        .map('agencyCode')
        .value();
    return !includes(agencyCodes, agencyProfile.agencyCode);
}

export const hideCadSection = ({ agencyProfile, agencyPreferences, multiRenGenSettings }) =>
    !agencyProfile || !agencyPreferences || !multiRenGenSettings;

/**
 * Submit method that is used in AgencyProfileAdminForm. It saves the changes (update or create) if they are valid
 * @param {Object} dispatch
 * @param {function} getState
 * @return result of the main dispatch
 */
export const submitAgencyProfile = function (router) {
    return (dispatch, getState) => {
        return dispatch(
            agencyProfileAdminForm.actionCreators.submit((formModel) => {
                const state = getState();
                const agencyProfiles = agencyProfilesWhereSelector(state)({
                    departmentId: currentUserDepartmentIdSelector(state),
                });
                const isValid = isAgencyCodeUnique(agencyProfiles, formModel);
                dispatch(
                    agencyProfileAdminForm.actionCreators.setValidity('agencyCode', {
                        duplicateError: isValid,
                    })
                );
                if (isValid) {
                    const { agencyProfile } = formModel;
                    const agencyDepartmentId = parseInt(agencyProfile.departmentId);
                    const selectedDeptProfile = departmentProfileByIdSelector(state)(
                        agencyDepartmentId
                    );
                    dispatch(
                        saveAgencyProfile(
                            agencyProfileAdminForm.convertFromFormModel(
                                formModel,
                                selectedDeptProfile?.timeZone
                            )
                        )
                    ).then((agency) => {
                        if (agency && agency.id) {
                            const currentAgencyId = parseInt(router.params.id);

                            dispatch(saveAgencyProfileLogo(agencyProfile, agency.id));

                            if (currentAgencyId !== agency.id) {
                                router.push(`/admin/agency/${agency.id}`);
                            }
                        }
                    });
                }
            })
        );
    };
};

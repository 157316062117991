import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import Modal from '../../../core/overlays/components/Modal';

const errorOverlayId = overlayIdEnum.MAXIMUM_LINEUP_MODAL;
const strings = componentStrings.cases.casePhotoLineups.ComposeLineup;

const MaximumWarningModal: React.FC<{ maxNum: number }> = ({ maxNum }) => {
    return (
        <Modal
            id={errorOverlayId}
            title={strings.maximumLineupTitle}
            okText={strings.understood}
            cancelText=""
        >
            {strings.maximumLineupWarning(maxNum)}
        </Modal>
    );
};

export default MaximumWarningModal;

import React from 'react';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Modal from '../../core/overlays/components/Modal';

import MergeEntitiesText from './core/MergeEntitiesText';

const strings = componentStrings.mergeEntities.MergeEntitiesConfirmationModal;

type PropsT = {
    onAfterOpen: () => void | Promise<void>;
    onSave: () => void | Promise<void>;
};

const MergeEntitiesConfirmationModal: React.FC<PropsT> = ({ onSave, onAfterOpen }) => {
    return (
        <Modal
            id={overlayIdEnum.MERGE_ENTITIES_CONFIRMATION_MODAL}
            title={strings.title}
            okText={strings.confirm}
            onSave={onSave}
            onAfterOpen={onAfterOpen}
        >
            <div>
                <MergeEntitiesText fontSize="13px">{strings.message}</MergeEntitiesText>
                <MergeEntitiesText margin="10px 0 0" fontSize="13px" fontWeight="semiBold">
                    {strings.notice}
                </MergeEntitiesText>
            </div>
        </Modal>
    );
};

export default MergeEntitiesConfirmationModal;

import validationActionTypes from './types/validationActionTypes';

const { LOAD_RULES_SUCCESS } = validationActionTypes;

export function loadRulesSuccess(rules) {
    return {
        type: LOAD_RULES_SUCCESS,
        payload: rules,
    };
}

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'CSV Column Resource',
        methods: {
            getCsvExportColumnsForPrintingTemplate(printingTemplateId) {
                return req({
                    method: 'GET',
                    url: `print/csv/columns/template/${printingTemplateId}`,
                });
            },
        },
    });
}

/**
 * Resources for CSV columns. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { noop, size, values } from 'lodash';
import { reduxForm, getValues } from 'redux-form-mark43';
import { useSelector } from 'react-redux';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { components } from '~/client-common/core/strings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import GlobalExportOptions from '../../../../legacy-redux/components/exports/GlobalExportsOptions';
import useBulkCasesExportData, {
    BulkCasesExportFormOptionsProps,
} from '../hooks/useBulkCasesExportData';

// configs
const strings = components.exports.FormExportsOptions;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FlexColumn = styled.div`
    flex: 1 1 100%;
`;

const BulkCasesExportFormOptions: React.FC<BulkCasesExportFormOptionsProps> = ({
    fields,
    router,
}) => {
    const { download, cancel, printablesByCaseId } = useBulkCasesExportData();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const {
        includeNameAddendums,
        combinedPDF,
        redactedFields,
        redactFields,
        includeAttachmentForBulkExport,
        includeZipFilesForBulkExport,
    } = fields;

    const numOfSelectedPackets = size(values(printablesByCaseId));

    const handleDownload = useCallback(() => download({ fields: getValues(fields) }, router), [
        download,
        fields,
        router,
    ]);

    const handleCancel = useCallback(() => cancel(router), [cancel, router]);

    return (
        <FlexContainer>
            <FlexColumn className="clearfix">
                <GlobalExportOptions
                    handleSubmit={handleDownload}
                    type={caseDisplayName}
                    includeNameAddendums={includeNameAddendums}
                    combinedPDF={combinedPDF}
                    includeAttachmentForBulkExport={includeAttachmentForBulkExport}
                    includeZipFilesForBulkExport={includeZipFilesForBulkExport}
                    redactedFields={redactedFields}
                    redactFields={redactFields}
                    submitButtonText={strings.download}
                    showIncludeNameAddendums={true}
                    showCombinedPDF={
                        applicationSettings.RMS_CASE_FOLDERING_ENABLED ||
                        applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE
                    }
                    showRedactionForBulkExport={true}
                    showAttachmentForBulkExport={true}
                    showZipFilesForBulkExport={true}
                    clearExportPreset={noop}
                    packetOptions={undefined}
                    onCancel={handleCancel}
                    onRedact={undefined}
                    onEmail={undefined}
                    includeAttachments={undefined}
                    includeFiles={undefined}
                    includeHistoryEvents={undefined}
                    onlyIncludeFieldsWithData={undefined}
                    includeWarrantActivities={undefined}
                    numOfSelectedPackets={numOfSelectedPackets}
                    selectedAttachmentsToInclude={undefined}
                    showOnlyIncludeFieldsWithData={false}
                    showIncludeHistoryEvents={false}
                    showIncludeWarrantActivities={false}
                    showFieldRedaction={false}
                    excludeAttachmentLinkTypes={undefined}
                    includeConfidentialInformation={undefined}
                    mergeAttachments={undefined}
                    onAddExportToEntity={noop}
                    entityType={undefined}
                />
            </FlexColumn>
        </FlexContainer>
    );
};

const BulkCasesExportForm = reduxForm({
    form: 'exports',
    fields: [
        'includeNameAddendums',
        'combinedPDF',
        'redactedFields',
        'redactFields',
        'includeAttachmentForBulkExport',
        'includeZipFilesForBulkExport',
    ],
})(BulkCasesExportFormOptions);

export default withRouter(BulkCasesExportForm);

import {
    Offense,
    OffenseIncidentCardBundle,
    PropertyStatus,
    LocationEntityLink,
    OffenseInvolvedChild,
    OffenseCaseStatus,
} from '@mark43/rms-api';
import Promise from 'bluebird';
import { createSelector } from 'reselect';
import { chain } from 'lodash';

import { applicationSettingsSelector } from '../../../settings/state/data';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getOffensesResource from '../../resources/offensesResource';
import { NEXUS_STATE_PROP as OFFENSE_ATTRIBUTES_NEXUS_STATE_PROP } from '../../../offense-attributes/state/data';
import { NEXUS_STATE_PROP as PROPERTY_STATUSES_NEXUS_STATE_PROP } from '../../../property-statuses/state/data';
import {
    NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP,
    nameReportLinksSelector,
} from '../../../name-report-links/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '../../../location-entity-links/state/data';
import { NEXUS_STATE_PROP as NAME_ATTRIBUTES_NEXUS_STATE_PROP } from '../../../name-attributes/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CHILDREN_NEXUS_STATE_PROP } from '../../../offense-involved-children/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'offenses';

const offenseModule = createNormalizedModule<Offense>({
    type: NEXUS_STATE_PROP,
});

export const storeOffenses = offenseModule.actionCreators.storeEntities;

export const offensesSelector = offenseModule.selectors.entitiesSelector;
export const offenseByIdSelector = offenseModule.selectors.entityByIdSelector;
export const offensesWhereSelector = offenseModule.selectors.entitiesWhereSelector;
export const offensesByReportIdSelector = createSelector(
    offensesWhereSelector,
    (offensesWhere) => (reportId: number) => offensesWhere({ reportId })
);

export default offenseModule.reducerConfig;

const DELETE_OFFENSE_START = 'offenses/DELETE_OFFENSE_START';
const DELETE_OFFENSE_SUCCESS = 'offenses/DELETE_OFFENSE_SUCCESS';
const DELETE_OFFENSE_FAILURE = 'offenses/DELETE_OFFENSE_FAILURE';

const CREATE_OFFENSE_START = 'offenses/CREATE_OFFENSE_START';
const CREATE_OFFENSE_SUCCESS = 'offenses/CREATE_OFFENSE_SUCCESS';
const CREATE_OFFENSE_FAILURE = 'offenses/CREATE_OFFENSE_FAILURE';

const UPSERT_OFFENSE_START = 'offenses/UPSERT_OFFENSE_START';
const UPSERT_OFFENSE_SUCCESS = 'offenses/UPSERT_OFFENSE_SUCCESS';
const UPSERT_OFFENSE_FAILURE = 'offenses/UPSERT_OFFENSE_FAILURE';

const BULK_UPSERT_OFFENSE_START = 'offenses/BULK_UPSERT_OFFENSE_START';
const BULK_UPSERT_OFFENSE_SUCCESS = 'offenses/BULK_UPSERT_OFFENSE_SUCCESS';
const BULK_UPSERT_OFFENSE_FAILURE = 'offenses/BULK_UPSERT_OFFENSE_FAILURE';

const DUPLICATE_OFFENSE_START = 'offenses/DUPLICATE_OFFENSE_START';
const DUPLICATE_OFFENSE_SUCCESS = 'offenses/DUPLICATE_OFFENSE_SUCCESS';
const DUPLICATE_OFFENSE_FAILURE = 'offenses/DUPLICATE_OFFENSE_FAILURE';

const SAVE_OFFENSE_INCIDENT_CARD_BUNDLE_SUCCESS =
    'offenses/SAVE_OFFENSE_INCIDENT_CARD_BUNDLE_SUCCESS';

function deleteOffenseStart(offenseId: number) {
    return {
        type: DELETE_OFFENSE_START,
        payload: offenseId,
    };
}

function deleteOffenseSuccess(offenseId: number) {
    return {
        type: DELETE_OFFENSE_SUCCESS,
        payload: offenseId,
    };
}

function deleteOffenseFailure(errorMessage: string) {
    return {
        type: DELETE_OFFENSE_FAILURE,
        payload: errorMessage,
    };
}

function upsertOffenseStart(offense: Offense) {
    return {
        type: UPSERT_OFFENSE_START,
        payload: offense,
    };
}

function upsertOffenseSuccess(offense: Offense) {
    return {
        type: UPSERT_OFFENSE_SUCCESS,
        payload: offense,
    };
}

function upsertOffenseFailure(errorMessage: string) {
    return {
        type: UPSERT_OFFENSE_FAILURE,
        payload: errorMessage,
    };
}

function bulkUpsertOffensesStart(offenses: Offense[]) {
    return {
        type: BULK_UPSERT_OFFENSE_START,
        payload: offenses,
    };
}

function bulkUpsertOffensesSuccess(offenses: Offense[]) {
    return {
        type: BULK_UPSERT_OFFENSE_SUCCESS,
        payload: offenses,
    };
}

function bulkUpsertOffensesFailure(errorMessage: string) {
    return {
        type: BULK_UPSERT_OFFENSE_FAILURE,
        payload: errorMessage,
    };
}

function createOffenseStart(offense: Offense) {
    return {
        type: CREATE_OFFENSE_START,
        payload: offense,
    };
}

function createOffenseSuccess(offense: Offense) {
    return {
        type: CREATE_OFFENSE_SUCCESS,
        payload: offense,
    };
}

function createOffenseFailure(errorMessage: string) {
    return {
        type: CREATE_OFFENSE_FAILURE,
        payload: errorMessage,
    };
}

function duplicateOffenseStart() {
    return {
        type: DUPLICATE_OFFENSE_START,
    };
}

function duplicateOffenseSuccess(duplicatedOffense: Offense) {
    return {
        type: DUPLICATE_OFFENSE_SUCCESS,
        payload: duplicatedOffense,
    };
}

function duplicateOffenseFailure(errorMessage: string) {
    return {
        type: DUPLICATE_OFFENSE_FAILURE,
        payload: errorMessage,
    };
}

function saveOffenseIncidentCardBundleSuccess(
    offenseIncidentCardBundle: OffenseIncidentCardBundle
) {
    return {
        type: SAVE_OFFENSE_INCIDENT_CARD_BUNDLE_SUCCESS,
        payload: offenseIncidentCardBundle,
    };
}

export function deleteOffense(
    offenseId: number,
    isOffenseTypeChange?: boolean
): ClientCommonAction<Promise<void>> {
    return (dispatch, getState, { nexus: { withRemoveMultiple } }) => {
        const state = getState();
        const applicationSettings = applicationSettingsSelector(state);
        const nameReportLinks = nameReportLinksSelector(state);
        const nameReportLinksToDelete = chain(nameReportLinks)
            .filter({ contextId: offenseId })
            .map(({ id }) => ({ id }))
            .value();

        const offensesResource = getOffensesResource();
        const deleteOffense =
            applicationSettings.RMS_OI_QUICK_ADD_ENABLED && isOffenseTypeChange
                ? offensesResource.deleteOffenseUponChangingOffenseType
                : offensesResource.deleteOffense;

        dispatch(deleteOffenseStart(offenseId));
        // offenses with an id <= 0 are client-side only and only need to be deleted from state
        // instead of triggering an async call
        return (offenseId > 0 ? deleteOffense(offenseId) : Promise.resolve())
            .then(() =>
                dispatch(
                    withRemoveMultiple(
                        {
                            [NEXUS_STATE_PROP]: [{ id: offenseId }],
                            [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinksToDelete,
                        },
                        deleteOffenseSuccess(offenseId)
                    )
                )
            )
            .catch((err: Error) => {
                dispatch(deleteOffenseFailure(err.message));
                throw err;
            });
    };
}

export function upsertOffense(offense: Offense): ClientCommonAction<Promise<Offense>> {
    return (dispatch) => {
        const offensesResource = getOffensesResource();
        dispatch(upsertOffenseStart(offense));
        return offensesResource
            .upsertOffense(offense)
            .then((upsertedOffense: Offense) => {
                dispatch(
                    offenseModule.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: [upsertedOffense],
                        },
                        upsertOffenseSuccess(upsertedOffense)
                    )
                );
                return upsertedOffense;
            })
            .catch((err: Error) => {
                dispatch(upsertOffenseFailure(err.message));
                throw err;
            });
    };
}

export function bulkUpsertOffenses(offenses: Offense[]): ClientCommonAction<Promise<Offense[]>> {
    return (dispatch) => {
        const offensesResource = getOffensesResource();
        dispatch(bulkUpsertOffensesStart(offenses));
        return offensesResource
            .bulkUpsertOffenses(offenses)
            .then((upsertedOffenses: Offense[]) => {
                dispatch(
                    offenseModule.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: upsertedOffenses,
                        },
                        bulkUpsertOffensesSuccess(upsertedOffenses)
                    )
                );
                return upsertedOffenses;
            })
            .catch((err: Error) => {
                dispatch(bulkUpsertOffensesFailure(err.message));
                throw err;
            });
    };
}

export function createOffense(offense: Offense): ClientCommonAction<Promise<Offense>> {
    return (dispatch) => {
        const offensesResource = getOffensesResource();
        dispatch(createOffenseStart(offense));
        return offensesResource
            .createOffense(offense)
            .then((createdOffense: Offense) => {
                // We are replacing the offense in state to ensure that we remove temporary client-side-only
                // offenses as soon as they are saved
                dispatch(
                    offenseModule.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: [createdOffense],
                        },
                        offenseModule.withRemove(
                            NEXUS_STATE_PROP,
                            {
                                id: offense.id,
                            },
                            createOffenseSuccess(createdOffense)
                        )
                    )
                );
                return createdOffense;
            })
            .catch((err: Error) => {
                dispatch(createOffenseFailure(err.message));
                throw err;
            });
    };
}

// TODO: delete this when the properties of OffenseDuplicationResultView appear in mark43-resources
type OffenseDuplicationResultView = {
    offenseIncidentCardBundle: OffenseIncidentCardBundle;
    propertyStatuses: PropertyStatus[];
    locationEntityLinks: LocationEntityLink[];
    offenseInvolvedChildren: OffenseInvolvedChild[];
    offenseCaseStatus: OffenseCaseStatus;
};

export function duplicateOffense(
    offenseId: number,
    onSuccess?: (duplicatedOffenseView: OffenseDuplicationResultView) => void,
    onError?: (offenseId: number, err: Error) => void
): ClientCommonAction<Promise<OffenseDuplicationResultView>> {
    return (dispatch, getState, dependencies) => {
        const offensesResource = getOffensesResource();
        dispatch(duplicateOffenseStart());
        return offensesResource
            .duplicateOffense(offenseId)
            .then((duplicatedOffenseView: OffenseDuplicationResultView) => {
                const {
                    offenseIncidentCardBundle,
                    propertyStatuses,
                    locationEntityLinks,
                    offenseInvolvedChildren,
                } = duplicatedOffenseView;
                dispatch(storeOffenseIncidentCardBundle(offenseIncidentCardBundle));
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [PROPERTY_STATUSES_NEXUS_STATE_PROP]: propertyStatuses,
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: locationEntityLinks,
                            [OFFENSE_CHILDREN_NEXUS_STATE_PROP]: offenseInvolvedChildren,
                        },
                        duplicateOffenseSuccess(offenseIncidentCardBundle.offense)
                    )
                );
                if (onSuccess) {
                    onSuccess(duplicatedOffenseView);
                }
                return duplicatedOffenseView;
            })
            .catch((err: Error) => {
                if (onError) {
                    onError(offenseId, err);
                }
                dispatch(duplicateOffenseFailure(err.message));
            });
    };
}

function storeOffenseIncidentCardBundle(
    offenseIncidentCardBundle: OffenseIncidentCardBundle
): ClientCommonAction<void> {
    return (dispatch, getState, dependencies) => {
        const {
            offense,
            offenseAttributes,
            nameReportLinks,
            nameAttributes,
        } = offenseIncidentCardBundle;
        dispatch(
            dependencies.nexus.withEntityItems(
                {
                    [NEXUS_STATE_PROP]: [offense],
                    [OFFENSE_ATTRIBUTES_NEXUS_STATE_PROP]: offenseAttributes,
                    [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinks,
                    [NAME_ATTRIBUTES_NEXUS_STATE_PROP]: nameAttributes,
                },
                saveOffenseIncidentCardBundleSuccess(offenseIncidentCardBundle)
            )
        );
    };
}

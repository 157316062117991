import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import _, { first, map } from 'lodash';
import { connect } from 'react-redux';

import { createFormConfiguration, lifecycleOptions } from 'markformythree';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { FormattedDate } from '~/client-common/core/dates/components';
import { dateInFuture } from '~/client-common/core/dates/utils/dateHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatReportTitleForReportIdSelector } from '~/client-common/core/domain/reports/state/ui';

import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { personGangTrackingForPersonIdSelector } from '~/client-common/core/domain/person-gang-trackings/state/data';

import GangTrackingDisplay, {
    ExpandedPersonGangTrackingDisplay,
} from '~/client-common/core/domain/person-gang-trackings/components/GangTrackingDisplay';
import formsRegistry from '../../../../core/formsRegistry';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import Icon, { iconTypes } from '../../../core/components/Icon';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import testIds from '../../../../core/testIds';

const strings = componentStrings.reports.gangTracking.GangTrackingSidePanel;

const ButtonWithMargin = styled(Button)`
    padding-left: 16px;
    padding-right: 16px;
    width: 8px;
`;

const ButtonWithMarginWithBorder = styled(ButtonWithMargin)`
    border-right: 1px solid ${(props) => props.theme.colors.mediumBlue};
`;

const AddButton = styled(Button)`
    margin-top: 15px;
`;

const SavedGangTrackingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BodyItalics = styled.div`
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.mediumGrey};
    font-style: italic;
    clear: both;
`;

const BodyItalicsWithMargin = styled(BodyItalics)`
    margin-bottom: 10px;
`;

const SectionTitle = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    text-transform: uppercase;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    margin-bottom: 10px;
    margin-top: 25px;
    line-height: 15px;
    color: ${(props) => props.theme.colors.mediumGrey};
    border-bottom: 3px solid ${(props) => props.theme.colors.mediumGrey};
`;

const SectionTitleWithMoreMargin = styled(SectionTitle)`
    margin-bottom: 16px;
`;

class GangTrackingSelectedPerson extends React.Component {
    state = {
        editMode: false,
        removedPersonGangTracking: false,
    };

    enterEditMode = () => {
        this.setState({ editMode: true, removedPersonGangTracking: false });
        this.props.setSaveDisabled(false);
    };

    removePersonGangTracking = () => {
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_GANG_UNIT_SIDE_PANEL.name,
            0,
            (form) => {
                form.set('gangNameAttrId', null);
            }
        );
        this.props.setSaveDisabled(false);
        this.setState({
            removedPersonGangTracking: true,
        });
    };

    handleCancel = () => {
        this.setState({
            editMode: false,
            removedPersonGangTracking: false,
        });
        this.props.handleCancel();
    };

    render() {
        const {
            formatAttributeById,
            personProfileWithCriteria,
            personGangTrackingForPersonId,
            formatReportTitleForReportId,
            reportId,
        } = this.props;
        const { personProfile, gangCriteria, personGangTracking } = personProfileWithCriteria;
        const contextedGangTracking = personGangTrackingForPersonId(personProfile.id);
        /**  This is the display-mode either showing an add button or the existing information **/
        const readMode =
            personGangTracking && !this.state.removedPersonGangTracking ? (
                <SavedGangTrackingContainer>
                    <ExpandedPersonGangTrackingDisplay personGangTracking={personGangTracking} />
                    <div>
                        <ButtonWithMarginWithBorder
                            float="none"
                            display="inline-flex"
                            iconLeft={<Icon type={iconTypes.EDIT} color="cobaltBlue" />}
                            onClick={this.enterEditMode}
                            className={buttonTypes.ICON_LINK}
                        />
                        <ButtonWithMargin
                            float="none"
                            display="inline-flex"
                            iconLeft={<Icon type={iconTypes.TRASH_CAN} color="cobaltBlue" />}
                            onClick={this.removePersonGangTracking}
                            className={buttonTypes.ICON_LINK}
                        />
                    </div>
                </SavedGangTrackingContainer>
            ) : (
                <AddButton
                    testId={testIds.ADD_SUSPECTED_GANG_AFFILIATION}
                    display="inline-flex"
                    float="none"
                    iconLeft={<Icon type={iconTypes.ADD} color="cobaltBlue" />}
                    onClick={this.enterEditMode}
                    className={buttonTypes.ICON_LINK}
                >
                    {strings.add}
                </AddButton>
            );
        // This is the edit-mode showing a select to choose a gang tracking info
        const editMode = (
            <div>
                <BodyItalicsWithMargin>
                    {personGangTracking ? strings.existingHelpText : strings.newHelpText}
                </BodyItalicsWithMargin>
                <ArbiterMFTAttributeSelect
                    clearable={false}
                    attributeType={[
                        AttributeTypeEnum.GANG_NAME.name,
                        AttributeTypeEnum.GANG_SUBGROUP.name,
                    ]}
                    filterOptions={(options) =>
                        _(options)
                            .map(({ value }) => {
                                return {
                                    value,
                                    display: formatAttributeById(value, false, true),
                                };
                            })
                            .sortBy('display')
                            .value()
                    }
                    path="gangNameAttrId"
                />
                <BodyItalics>
                    {strings.willExpire}{' '}
                    <FormattedDate
                        date={dateInFuture(5, 'y')}
                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                    />
                </BodyItalics>
            </div>
        );
        return (
            <div>
                <Button
                    iconLeft={<Icon type={iconTypes.OPEN_LEFT} color="cobaltBlue" />}
                    onClick={this.handleCancel}
                    className={buttonTypes.ICON_LINK}
                    display="inline-flex"
                    float="none"
                >
                    {strings.back}
                </Button>
                <SectionTitle>{strings.criminalStreetGangAffiliation}</SectionTitle>

                <ArbiterForm
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    name={RefContextEnum.FORM_GANG_UNIT_SIDE_PANEL.name}
                    context={RefContextEnum.FORM_GANG_UNIT_SIDE_PANEL.name}
                    configuration={createFormConfiguration({
                        gangNameAttrId: {
                            fieldName: fields.PERSON_GANG_TRACKING_GANG_NAME_ATTR_ID,
                        },
                    })}
                    render={() => (
                        <div>
                            {!this.state.editMode || this.state.removedPersonGangTracking ? (
                                <div>{readMode}</div>
                            ) : (
                                <div>{editMode}</div>
                            )}
                        </div>
                    )}
                />
                <SectionTitleWithMoreMargin>
                    {strings.criteria(formatReportTitleForReportId(reportId))}
                </SectionTitleWithMoreMargin>
                <SummaryList labelWidth={180} contentWidth={220}>
                    <ConnectedFormattedUser
                        userId={first(gangCriteria).createdBy}
                        format={FORMATS.FULL_NAME_WITH_FIRST_INITIAL}
                    >
                        {(user) => (
                            <BodyItalics>
                                Entered by {user} on{' '}
                                <FormattedDate
                                    date={first(gangCriteria).createdDateUtc}
                                    format={FormattedDate.FORMATS.SUMMARY_DATE}
                                />
                            </BodyItalics>
                        )}
                    </ConnectedFormattedUser>
                    <SummaryRow label={strings.criminalStreetGangName}>
                        <GangTrackingDisplay personGangTracking={contextedGangTracking} />
                    </SummaryRow>
                    {map(gangCriteria, ({ attributeId, description }, i) => {
                        return (
                            <SummaryRow key={i} label={formatAttributeById(attributeId)}>
                                {description}
                            </SummaryRow>
                        );
                    })}
                </SummaryList>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatAttributeById: formatAttributeByIdSelector,
    personGangTrackingForPersonId: personGangTrackingForPersonIdSelector,
    formatReportTitleForReportId: formatReportTitleForReportIdSelector,
});

export default connect(mapStateToProps)(GangTrackingSelectedPerson);

import { map, size, slice } from 'lodash';
import React from 'react';
import { withHandlers, withState } from 'recompose';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes as BUTTON_TYPES } from '../../../legacy-redux/components/core/Button';
import _Icon, { iconTypes } from '../../../legacy-redux/components/core/Icon';
import _NoDataBlock from './NoDataBlock';

const { COLLAPSE, EXPAND } = iconTypes;
const strings = componentStrings.core.CollapsibleList;

const CollapsibleListComponent = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: ${(props) => (props.collapsed ? 'auto' : '500px')};
    padding-left: ${(props) => `${props.paddingLeft}px`};
    padding-top: 5px;
    padding-bottom: 5px;
    overflow: auto;
`;

CollapsibleListComponent.defaultProps = {
    paddingLeft: 30,
};

const FlexContent = styled.div`
    display: flex;
    justify-content: center;
`;

const Icon = styled(_Icon)`
    margin: 0 10px 0 8px;
`;

const NoDataBlock = styled(_NoDataBlock)`
    padding-left: 24px;
    padding-right: 24px;
`;

const _CollapsibleListButton = withHandlers({
    toggleCollapsed({ collapsed, setCollapsed }) {
        return () => setCollapsed(!collapsed);
    },
})(function _CollapsibleListButton({
    className = BUTTON_TYPES.TRANSPARENT,
    collapsed,
    toggleCollapsed,
}) {
    return (
        <Button className={className} onClick={toggleCollapsed}>
            <FlexContent>
                <Icon type={collapsed ? EXPAND : COLLAPSE} />
                {collapsed ? strings.viewMoreItems : strings.viewLessItems}
            </FlexContent>
        </Button>
    );
});

const CollapsibleListButton = styled(_CollapsibleListButton)`
    display: block;
    background: none;
    margin: 30px 0 0 0;
`;

const CollapsibleList = withState(
    'collapsed',
    'setCollapsed',
    ({ initiallyCollapsed = true }) => initiallyCollapsed
)(function CollapsibleList({
    collapsed,
    setCollapsed,
    items,
    itemsCount = size(items),
    maxItemsCollapsed = 3,
    noItemsText = strings.noItems,
    renderItem,
}) {
    return (
        <CollapsibleListComponent collapsed={collapsed}>
            <div className="collapsible-list-items-wrapper">
                {itemsCount > 0 &&
                    map(collapsed ? slice(items, 0, maxItemsCollapsed) : items, (item) =>
                        renderItem(item)
                    )}
            </div>

            {itemsCount > maxItemsCollapsed && (
                <CollapsibleListButton collapsed={collapsed} setCollapsed={setCollapsed} />
            )}
            {itemsCount === 0 && <NoDataBlock>{noItemsText}</NoDataBlock>}
        </CollapsibleListComponent>
    );
});

/**
 * Collapsible list of items with a view more/less button.
 * @param {Object[]} props.items
 * @param {number}   [props.maxItemsCollapsed=3]
 * @param {string}   [props.noItemsText='No Items']
 * @param {function} props.renderItem Function that returns an element to render
 *   for each item, with argument `(item)`. Each component returned by the
 *   function must have a key.
 */
export default CollapsibleList;

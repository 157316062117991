import React from 'react';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

export default function AgencyCell(elasticReport) {
    const viewModel = getViewModelProperties(elasticReport);
    return (
        <div>
            <div>{viewModel.agencyId}</div>
        </div>
    );
}

import _ from 'lodash';
import { createSelector } from 'reselect';
import { userDepartmentIdSelector } from './userSelectors';

// data to be displayed in table showing past runs
const departmentIPSelector = (state) => state.data.departmentIPsAdmin.departmentIPs;

const sortedDepartmentIPs = createSelector([departmentIPSelector], (ipsObj) => {
    return _(ipsObj).values().sortBy('ip').value();
});

// The UI stuff
export const departmentIPsAdminUISelector = (state) => {
    return state.ui.departmentIPsAdmin;
};

const defaultIP = (state) => {
    return {
        departmentId: userDepartmentIdSelector(state),
    };
};

const selectedDepartmentIP = createSelector(
    [departmentIPsAdminUISelector, departmentIPSelector],
    (ui, ips) => {
        if (!ui.selectedDepartmentIPId) {
            return defaultIP;
        }

        const ip = ips[ui.selectedDepartmentIPId];

        if (ip) {
            return {
                ...ip,
            };
        }

        return ip;
    }
);

export const getDepartmentIPById = (state, id) => {
    return state.data.departmentIPsAdmin.departmentIPs[id];
};

export const getCurrentDepartmentIPId = (state) => {
    return state.ui.departmentIPsAdmin.selectedDepartmentIPId;
};

const adminListSelector = createSelector(
    [sortedDepartmentIPs, departmentIPsAdminUISelector],
    (departmentIPs, ui) => {
        return _(departmentIPs)
            .map((ip) => {
                return {
                    title: ip.ip,
                    key: ip.id,
                    isAllowed: ip.isAllowed,
                    selected: ip.id === ui.selectedDepartmentIPId,
                };
            })
            .value();
    }
);

const adminListSelectorWithSelected = createSelector(
    [adminListSelector, departmentIPsAdminUISelector],
    (list, ui) => {
        return _.map(list, (ip) => {
            const selected = ui.selectedDepartmentIPId === ip.key;
            ip.selected = selected;
            return ip;
        });
    }
);

export const departmentIPsAdminSelector = createSelector(
    [
        sortedDepartmentIPs,
        departmentIPsAdminUISelector,
        adminListSelectorWithSelected,
        selectedDepartmentIP,
    ],
    (departmentIPs, ui, adminList, selectedDepartmentIP) => {
        return {
            departmentIPs,
            adminList,
            ui,
            selectedDepartmentIP,
        };
    }
);

import { useCallback, useState, useEffect } from 'react';
import { Mark43File } from '@mark43/rms-api';

import getFilesResource from '~/client-common/core/domain/files/resources/filesResource';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';

export const useFilePathForFileId = (fileId?: number) => {
    const [fileWebServerPath, setFileWebServerPath] = useState<string>();

    const getImageUrlByFileId = useCallback(() => {
        return fileId ? getFilesResource().getFile(fileId) : Promise.resolve(undefined);
    }, [fileId]);

    const getImageUrlByFileIdSuccess = useCallback((response: Mark43File) => {
        if (response) {
            setFileWebServerPath(response.fileWebServerPath);
        }
    }, []);

    const { callResource } = useResourceDeferred(getImageUrlByFileId, getImageUrlByFileIdSuccess);

    useEffect(() => {
        setFileWebServerPath(undefined);
        if (fileId) {
            callResource();
        }
    }, [fileId, callResource]);

    return {
        fileWebServerPath,
    };
};

import { LegacyEntityDetail } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'legacyEntityDetails';

// this isn't a real link, but does need to be keyed by multiple values
const legacyEntityDetailsModule = createLinkModule<LegacyEntityDetail>({
    type: NEXUS_STATE_PROP,
    keys: ['detailKey', 'entityId', 'entityType'],
});

// SELECTORS

export const legacyEntityDetailsWhereSelector =
    legacyEntityDetailsModule.selectors.linksWhereSelector;

// REDUCER
export default legacyEntityDetailsModule.reducerConfig;

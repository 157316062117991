import React from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import Icon, { iconTypes } from './Icon';

const RecordPrivacyIconContainer = styled.div<{ isSealed?: boolean; size?: number }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background-color: ${(props) =>
        props.isSealed ? props.theme.colors.highContrastGrey : props.theme.colors.lowContrastGreen};
`;

const RecordPrivacyIcon: React.FC<{
    className?: string;
    size?: number;
    color?: keyof typeof theme.colors;
    isSealed?: boolean;
}> = ({ className, size = 14, color = 'white', isSealed }) => {
    return (
        <RecordPrivacyIconContainer className={className} size={2 * size} isSealed={isSealed}>
            <Icon size={size} type={iconTypes.PERMISSIONS} color={color} />
        </RecordPrivacyIconContainer>
    );
};

export default RecordPrivacyIcon;

import { compact } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName, withHandlers, defaultProps } from 'recompose';

import { simpleControl } from 'markformythree';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';

import { arbiterMFTInput } from '../../../arbiter';
import { userOptionsSelector } from '../../../../../legacy-redux/selectors/userSelectors';
import { searchUsers } from '../../../../../legacy-redux/actions/userActions';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import AsyncSelect from './AsyncSelect';

const { connectRRFInput } = reactReduxFormHelpers;

const UserSelect = compose(
    setDisplayName('UserSelect'),
    defaultProps({
        effectiveDate: nowUtc(),
    }),
    connect(
        createStructuredSelector({
            userOptions: userOptionsSelector,
        }),
        (dispatch) => ({
            searchUsers(query, { effectiveDate }) {
                return dispatch(searchUsers(query, { effectiveDate }));
            },
        })
    ),
    withHandlers({
        searchUsers: ({ searchUsers, effectiveDate }) => {
            return (query) => {
                return searchUsers(query, { effectiveDate });
            };
        },
    })
)(function UserSelect({
    userOptions,
    searchUsers,
    value,
    additionalIds = [],
    includeOther = false,
    effectiveDate,
    ...otherProps
}) {
    const userIds = compact([].concat(value).concat(additionalIds));
    return (
        <AsyncSelect
            {...otherProps}
            value={value}
            options={userOptions(userIds, { includeOther, effectiveDate })}
            asyncAction={searchUsers}
        />
    );
});

/**
 * Typeahead for selecting user(s).
 * @param {Object}      props
 * @param {boolean}     includeOther    Include a hardcoded "OTHER" user option.
 * @param {string}      effectiveDate   Moment string that specifies the duty
 *                                      status effective date that will be used
 *                                      to query for `userSearchResult`s as well
 *                                      as how the `userSearchResult` options
 *                                      will be filtered.  Only *active* users
 *                                      for the specified `effectiveDate` will be
 *                                      queried for and filtered for as options.
 *                                      Use `effectiveDate = null` to obtain all
 *                                      users (active + inactive).
 */
export default UserSelect;

export const RRFUserSelect = connectRRFInput(UserSelect);
export const MFTUserSelect = simpleControl(UserSelect);
export const ArbiterMFTUserSelect = arbiterMFTInput(UserSelect);

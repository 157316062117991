import {
    CadChannel,
    CadChannelHistory,
    CadChannelListRequestDto,
    CadChannelUpsertRequestDto,
} from '@mark43/cad-api';
import { RmsAction } from '../../../../../core/typings/redux';
import { cadChannelsResource } from '../../resources';
import type { FilteringDataT } from '../../types';
import * as actionTypes from './actionTypes';

type CadChannelsLoadSuccessT = ReturnType<typeof loadCadChannelsSuccess>;
type CadChannelsLoadFailureT = ReturnType<typeof loadCadChannelsFailure>;
type CadChannelHistorySuccessT = ReturnType<typeof loadCadChannelHistorySuccess>;
type CadChannelHistoryFailureT = ReturnType<typeof loadCadChannelHistoryFailure>;
type CadChannelSaveSuccessT = ReturnType<typeof saveCadChannelSuccess>;
type CadChannelSaveFailureT = ReturnType<typeof saveCadChannelFailure>;
export type CadChannelsAdminActionsT =
    | ReturnType<typeof loadCadChannelsStart>
    | CadChannelsLoadSuccessT
    | CadChannelsLoadFailureT
    | ReturnType<typeof loadCadChannelHistoryStart>
    | CadChannelHistorySuccessT
    | CadChannelHistoryFailureT
    | ReturnType<typeof saveCadChannelStart>
    | CadChannelSaveSuccessT
    | CadChannelSaveFailureT;

function loadCadChannelsStart() {
    return {
        type: actionTypes.LOAD_CAD_CHANNELS_START,
    } as const;
}

function loadCadChannelsSuccess(cadChannels: CadChannel[]) {
    return {
        type: actionTypes.LOAD_CAD_CHANNELS_SUCCESS,
        payload: cadChannels,
    } as const;
}

function loadCadChannelsFailure(errorMessage?: string) {
    return {
        type: actionTypes.LOAD_CAD_CHANNELS_FAILURE,
        payload: errorMessage,
    } as const;
}

function loadCadChannelHistoryStart() {
    return {
        type: actionTypes.LOAD_CAD_CHANNEL_HISTORY_START,
    } as const;
}

function loadCadChannelHistorySuccess(cadChannelHistory: CadChannelHistory[]) {
    return {
        type: actionTypes.LOAD_CAD_CHANNEL_HISTORY_SUCCESS,
        payload: cadChannelHistory,
    } as const;
}

function loadCadChannelHistoryFailure(errorMessage?: string) {
    return {
        type: actionTypes.LOAD_CAD_CHANNEL_HISTORY_FAILURE,
        payload: errorMessage,
    } as const;
}
export const loadCadChannels = (
    cadChannelListRequest: CadChannelListRequestDto
): RmsAction<Promise<void>> => {
    return (dispatch) => {
        dispatch(loadCadChannelsStart());

        return cadChannelsResource
            .listCadChannels(cadChannelListRequest)
            .then((cadChannels) => {
                dispatch(loadCadChannelsSuccess(cadChannels));
            })
            .catch((err) => {
                dispatch(loadCadChannelsFailure(err?.message));
            });
    };
};

function saveCadChannelStart(cadChannel: CadChannelUpsertRequestDto) {
    return {
        type: actionTypes.SAVE_CAD_CHANNEL_START,
        payload: cadChannel,
    } as const;
}

function saveCadChannelSuccess() {
    return {
        type: actionTypes.SAVE_CAD_CHANNEL_SUCCESS,
    } as const;
}

function saveCadChannelFailure(errorMessage?: string) {
    return {
        type: actionTypes.SAVE_CAD_CHANNEL_FAILURE,
        payload: errorMessage,
    } as const;
}

export const saveCadChannel = (
    cadChannel: CadChannelUpsertRequestDto,
    filters?: FilteringDataT
): RmsAction<Promise<CadChannelSaveSuccessT | CadChannelSaveFailureT | void>> => {
    return function (dispatch) {
        dispatch(saveCadChannelStart(cadChannel));

        return cadChannelsResource
            .upsertCadChannel(cadChannel)
            .then(() => {
                dispatch(saveCadChannelSuccess());
                return dispatch(loadCadChannels({ ...filters }));
            })
            .catch((err) => {
                return dispatch(saveCadChannelFailure(err?.message));
            });
    };
};

export const getCadChannelHistory = (
    id: number
): RmsAction<Promise<CadChannelHistorySuccessT | CadChannelHistoryFailureT>> => {
    return function (dispatch) {
        dispatch(loadCadChannelHistoryStart());

        return cadChannelsResource
            .getCadChannelHistory(id)
            .then((cadChannelHistory) => {
                return dispatch(loadCadChannelHistorySuccess(cadChannelHistory));
            })
            .catch((err) => {
                return dispatch(loadCadChannelHistoryFailure(err?.message));
            });
    };
};

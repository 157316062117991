import _, { identity, some, values, noop } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import {
    compose,
    defaultProps,
    setPropTypes,
    withHandlers,
    withPropsOnChange,
    withState,
    lifecycle,
} from 'recompose';

import strings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import { SimpleLoading as _SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { InlineBanner } from '../../../components/InlineBanner';
import {
    attributeLoadingStateSelectorFactory,
    attributeOptionsByTypeForCurrentDepartmentSelector,
} from '../../../attributes/state/ui';
import { loadAttributesForType } from '../../../attributes/state/ui/loadAttributesForType';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { arbiterMFTInput } from '../../../arbiter';
import { computeAllowedAttributeTypesToLoad } from '../../../attributes/utils/computeAllowedAttributeTypesToLoad';
import CheckboxColumns from './CheckboxColumns';

const { connectRRFInput } = reactReduxFormHelpers;

const SimpleLoading = styled(_SimpleLoading)`
    & .loading-whitebg {
        width: 20px;
        height: 20px;
        margin: 0;
    }
`;

const AttributeCheckboxes = compose(
    setPropTypes({
        attributeType: PropTypes.string.isRequired,
        includeExpired: PropTypes.bool,
        columns: PropTypes.number,
        disabled: PropTypes.bool,
    }),
    defaultProps({
        includeExpired: false,
        disabled: false,
    }),
    connect(
        () => {
            const attributeLoadingStateSelector = attributeLoadingStateSelectorFactory();
            return createStructuredSelector({
                attributeOptionsByTypeForCurrentDepartment: attributeOptionsByTypeForCurrentDepartmentSelector,
                attributeLoadingState: attributeLoadingStateSelector,
            });
        },
        {
            loadAttributesForType,
        }
    ),
    withState('attributeIds', 'setAttributeIds', ({ value }) => (value ? [].concat(value) : [])),
    withHandlers({
        onChange({ setAttributeIds, onChange }) {
            return (attributeId) => {
                // store selected value in state
                setAttributeIds((attributeIds) => {
                    return _(attributeIds).concat(attributeId).uniq().value();
                });
                onChange(attributeId);
            };
        },
        triggerAttributeLoad({ attributeLoadingState, loadAttributesForType }) {
            return () => {
                const attributeTypesToLoad = computeAllowedAttributeTypesToLoad(
                    attributeLoadingState
                );

                // for now we load all attributes for the specified types, including expired ones
                if (attributeTypesToLoad.length) {
                    // the empty `catch` is intentional. Due to having to support legacy KO/BB
                    // we have to return a promise which can potentially be rejected
                    loadAttributesForType({ attributeType: attributeTypesToLoad }).catch(noop);
                }
            };
        },
    }),
    lifecycle({
        componentDidMount() {
            this.props.triggerAttributeLoad();
        },
    }),
    withPropsOnChange(
        [
            'attributeOptionsByTypeForCurrentDepartment',
            'attributeType',
            'includeExpired',
            'attributeIds',
            'filterOptions',
        ],
        ({
            attributeOptionsByTypeForCurrentDepartment,
            attributeType,
            includeExpired,
            attributeIds,
            filterOptions = identity,
        }) => ({
            options: filterOptions(
                attributeOptionsByTypeForCurrentDepartment({
                    type: attributeType,
                    includeExpired,
                    additionalIds: attributeIds,
                })
            ),
        })
    )
)(function AttributeCheckboxes({
    label,
    options,
    value,
    onChange,
    onFocus,
    onBlur,
    columns,
    columnWidth,
    gutterWidth,
    error,
    multiple = true,
    fieldName,
    disabled,
    testId,
    attributeLoadingState,
    triggerAttributeLoad,
}) {
    const labelElement = label ? (
        <label className="mark43-form-label mark43-form-row-label">{label}</label>
    ) : null;

    const attributeLoadingValues = values(attributeLoadingState);
    const isLoading = some(attributeLoadingValues, (loadingState) => loadingState.loading);
    return (
        <div data-test-id={testId} data-test-field-name={fieldName}>
            {labelElement}
            {isLoading && <SimpleLoading />}
            {!isLoading && some(attributeLoadingValues, (loadingState) => loadingState.error) && (
                <div>
                    <InlineBanner status="error">
                        {strings.core.attributeComponents.attributeTypeLoadingError}
                    </InlineBanner>
                    <Button className={buttonTypes.SECONDARY} onClick={triggerAttributeLoad}>
                        {strings.core.attributeComponents.retryLoading}
                    </Button>
                </div>
            )}
            <CheckboxColumns
                columns={columns}
                columnWidth={columnWidth}
                gutterWidth={gutterWidth}
                multiple={multiple}
                options={options}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={error}
                fieldName={fieldName}
                disabled={disabled}
            />
        </div>
    );
});

/**
 * Checkboxes for all attributes of the specified attribute type. This component
 *   is practically the same as `AttributeSelect`; it just has a different ui.
 * @param {string}  props.attributeType
 * @param {boolean} [props.includeExpired=false] Whether to include expired
 *   attributes. If `true`, the expired attributes are listed after the active
 *   ones, both sorted alphabetically.
 * @param {function} [props.filterOptions] If provided, this function is called
 *   on the original array of attribute options and its return value becomes the
 *   new options. For example, use this to filter the checkbox options to only
 *   attributes with a particular parent attribute.
 * @param {boolean} [props.disabled] Disables the checkboxes.
 */
export default AttributeCheckboxes;

export const RRFAttributeCheckboxes = connectRRFInput(AttributeCheckboxes);
export const ArbiterMFTAttributeCheckboxes = arbiterMFTInput(AttributeCheckboxes);

import React, { createContext, useContext } from 'react';
import { TaskEntityLink, TaskView } from '@mark43/rms-api';

type TaskContextProps = {
    selectedTask: TaskView;
    taskEntityLinks: TaskEntityLink[];
    setSelectedTask: React.Dispatch<React.SetStateAction<TaskView>>;
    setTaskEntityLinks: React.Dispatch<React.SetStateAction<TaskEntityLink[]>>;
};

function createTaskContext<A extends TaskContextProps | null>() {
    const TaskContext = createContext<A | undefined>(undefined);
    const useTaskContext = () => {
        const context = useContext(TaskContext);
        if (context === undefined) {
            throw new Error('useTaskContext must be inside a Provider with a value');
        }
        return context;
    };
    return {
        useTaskContext,
        TaskContextProvider: TaskContext.Provider,
    } as const;
}

export const { TaskContextProvider } = createTaskContext<TaskContextProps>();

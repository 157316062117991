import React from 'react';
import { chain, map, uniqBy } from 'lodash';
import { compose, mapProps } from 'recompose';

import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { nameReportLinkTypePriority } from '~/client-common/helpers/nameReportLinksHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import constants from '../../../../../core/constants';

const strings = componentStrings.core.tables.elasticReportsTable.PersonsCell;

const linkTypeLetters = constants.linkTypeLetters;
const MAX_PERSONS_LENGTH = 6;

function keyStrategy(involvement, linkTypeSequenceNumber) {
    return `${involvement}~${linkTypeSequenceNumber}`;
}

function PersonsCell({ elasticReport }) {
    const { involvedPersons = [] } = elasticReport;

    if (involvedPersons.length === 0) {
        return <div />;
    }

    const displayedPersons = chain(involvedPersons)
        // Implementation of sort logic described in RMS-2053
        .sortBy('linkTypeSequenceNumber', ({ involvement }) =>
            nameReportLinkTypePriority(involvement)
        )
        .mapKeys(({ involvement, linkTypeSequenceNumber }) =>
            keyStrategy(involvement, linkTypeSequenceNumber)
        )
        .values()
        .take(MAX_PERSONS_LENGTH)
        .value();

    const displayedPersonsUniqueCount = uniqBy(displayedPersons, 'person.id').length;
    const totalPersonsUniqueCount = uniqBy(involvedPersons, 'person.id').length;
    const personsNotDisplayedCount = totalPersonsUniqueCount - displayedPersonsUniqueCount;

    const mappedPersons = map(
        displayedPersons,
        ({ involvement, linkTypeSequenceNumber, person: elasticPerson }) => {
            const linkType = `${linkTypeLetters[involvement]}${linkTypeSequenceNumber || ''}`;

            return (
                <div key={keyStrategy(involvement, linkTypeSequenceNumber)}>
                    <div className="link-type">
                        <span>{linkType}</span>
                        <span className="link-type-dash">-</span>
                    </div>
                    <div className="name">
                        {joinTruthyValues(
                            [
                                formatFullName(elasticPerson),
                                elasticPerson.isJuvenile ? strings.isJuvenileTag : undefined,
                                elasticPerson.isNonDisclosureRequest && strings.isNonDisclosure,
                            ],
                            ' '
                        )}
                    </div>
                </div>
            );
        }
    );

    // This shows the number of unique persons not displayed in the cell
    // which is different from the number of person report links which are
    // not displayed in the cell
    const showMore =
        personsNotDisplayedCount > 0 ? (
            <div className="elastic-row-show-more">
                {`+ ${personsNotDisplayedCount} other persons`}
            </div>
        ) : undefined;

    return (
        <div className="elastic-report-name">
            {mappedPersons}
            {showMore}
        </div>
    );
}

export default compose(mapProps((ownerProps) => ({ elasticReport: ownerProps })))(PersonsCell);

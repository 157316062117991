import { map, some } from 'lodash';
import React from 'react';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import EntityProfileSectionNoData from '../../core/components/EntityProfileSectionNoData';
import { convertIdentifiersToComponentArray } from '../../core/helpers/identifierHelpers';

const { title } = componentStrings.entityProfiles.property.IdentificationDetails;

const fieldNames = {
    color: fields.DISPLAY_SHORT_ITEM_PROFILE_PRIMARY_COLOR_ATTR_ID,
    size: fields.ITEM_PROFILE_SIZE,
    serialNumber: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_SERIAL_NUMBER,
    itemMake: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_ITEM_MAKE,
    itemModel: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_ITEM_MODEL,
    isBiohazard: fields.ITEM_PROFILE_IS_BIOHAZARD,
    biohazardDescription: fields.ITEM_PROFILE_BIOHAZARD_DESCRIPTION,
    isCounterfeit: fields.ITEM_PROFILE_IS_COUNTERFEIT,
    isPrecursorChemical: fields.ITEM_PROFILE_IS_PRECURSOR_CHEMICAL,
};

function EntityProfileItemIdentificationDetails({ itemDetails }) {
    const display = getViewModelProperties(itemDetails);
    const identifiers = convertIdentifiersToComponentArray(
        display.identifiers,
        'itemIdentifierType'
    );

    return (
        <EntityProfileSection title={title}>
            <EntityProfileSummaryColumn className="one-column">
                <EntityProfileDetailSummaryList>
                    {map(fieldNames, (fieldName, key) => (
                        <SummaryRow key={key} fieldName={fieldName}>
                            {display[key] || itemDetails[key]}
                        </SummaryRow>
                    ))}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList labelWidth={105}>
                    {identifiers}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}

const RenderNoInformation = () => (
    <EntityProfileSection title={title}>
        <EntityProfileSectionNoData />
    </EntityProfileSection>
);

export default renderOnlyIf(({ itemDetails }) => {
    const display = getViewModelProperties(itemDetails);
    return (
        (display.identifiers && display.identifiers.length) ||
        some(fieldNames, (fieldName, key) => display[key] || itemDetails[key])
    );
}, RenderNoInformation)(EntityProfileItemIdentificationDetails);

import _, { keyBy } from 'lodash';
import {
    cadMessagingGroupsSelector,
    storeCadMessagingGroups,
    NEXUS_STATE_PROP as MESSAGING_GROUPS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/cad-messaging-groups/state/data';
import { storeCadUnits } from '~/client-common/core/domain/cad-units/state/data';
import { storeDispatchAreas } from '~/client-common/core/domain/dispatch-areas/state/data';
import { storeCadCallTakerStations } from '~/client-common/core/domain/cad-call-taker-stations/state/data';
import { departmentProfileSelector } from '../../../../../legacy-redux/selectors/departmentProfileSelectors';
import cadMessagingGroupResource from '../../resources/cadMessagingGroupResource';

export const LOAD_START = 'cad-messaging-groups/LOAD_START';
export const LOAD_SUCCESS = 'cad-messaging-groups/LOAD_SUCCESS';
export const LOAD_FAILURE = 'cad-messaging-groups/LOAD_FAILURE';
export const SAVE_START = 'cad-messaging-groups/SAVE_START';
export const SAVE_SUCCESS = 'cad-messaging-groups/SAVE_SUCCESS';
export const SAVE_FAILURE = 'cad-messaging-groups/SAVE_FAILURE';
const MEMBER_REMOVE_SUCCESS = 'cad-messaging-groups/MEMBER_REMOVE_SUCCESS';
const MEMBER_ADD_SUCCESS = 'cad-messaging-groups/MEMBER_ADD_SUCCESS';

function loadCadMessagingGroupsStart() {
    return {
        type: LOAD_START,
    };
}

function loadCadMessagingGroupsSuccess() {
    return {
        type: LOAD_SUCCESS,
    };
}

function loadCadMessagingGroupsFailure(errMsg) {
    return {
        type: LOAD_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function loadCadMessagingGroups() {
    return function (dispatch, getState) {
        const { departmentId } = departmentProfileSelector(getState());
        dispatch(loadCadMessagingGroupsStart(departmentId));

        return cadMessagingGroupResource
            .getCadMessagingGroups(departmentId)
            .then((messagingGroups) => {
                cadMessagingGroupResource
                    .getCadUnits()
                    .then((units) => {
                        dispatch(storeCadUnits(units));
                    })
                    .catch((err) => {
                        throw err;
                    });

                cadMessagingGroupResource
                    .getCallTakerStations()
                    .then((stations) => {
                        dispatch(storeCadCallTakerStations(stations));
                    })
                    .catch((err) => {
                        throw err;
                    });

                cadMessagingGroupResource
                    .getDispatchAreas()
                    .then((dispatchAreas) => {
                        dispatch(storeDispatchAreas(dispatchAreas));
                        dispatch(loadCadMessagingGroupsSuccess());
                    })
                    .catch((err) => {
                        throw err;
                    });

                dispatch(storeCadMessagingGroups(messagingGroups));
            })
            .catch((err) => dispatch(loadCadMessagingGroupsFailure(err.message)));
    };
}

export function removeMemberFromMessagingGroup({ memberEntityId, messageGroupId }) {
    // no redux action dispatch here because loading/error states are handled in component state
    return (dispatch, getState, { nexus }) =>
        cadMessagingGroupResource
            .removeMemberFromMessagingGroup({ memberEntityId, messageGroupId })
            .then(() => {
                const messageGroupToUpdate = cadMessagingGroupsSelector(getState())[messageGroupId];
                return dispatch(
                    nexus.withEntityItems(
                        {
                            [MESSAGING_GROUPS_NEXUS_STATE_PROP]: [
                                {
                                    ...messageGroupToUpdate,
                                    members: messageGroupToUpdate.members.filter(
                                        (member) => member.entityId !== memberEntityId
                                    ),
                                },
                            ],
                        },
                        { type: MEMBER_REMOVE_SUCCESS }
                    )
                );
            });
}

export function addMembersToMessagingGroup({ formSubmissionPromise, messageGroupId }) {
    return (dispatch, getState, { nexus }) =>
        formSubmissionPromise.then(({ form }) =>
            cadMessagingGroupResource
                .addMembersToMessagingGroup({
                    members: form.getState().model.entityIds,
                    messageGroupId,
                })
                .then((addedMessageLinks) => {
                    const messageGroupToUpdate = cadMessagingGroupsSelector(getState())[
                        messageGroupId
                    ];
                    const addedLinksById = keyBy(addedMessageLinks, 'entityId');
                    form.resetModel();
                    return dispatch(
                        nexus.withEntityItems(
                            {
                                [MESSAGING_GROUPS_NEXUS_STATE_PROP]: [
                                    {
                                        ...messageGroupToUpdate,
                                        members: messageGroupToUpdate.members
                                            .filter((member) => !addedLinksById[member.entityId])
                                            .concat(addedMessageLinks),
                                    },
                                ],
                            },
                            { type: MEMBER_ADD_SUCCESS }
                        )
                    );
                })
        );
}

function saveCadMessagingGroupStart() {
    return {
        type: SAVE_START,
    };
}

function saveCadMessagingGroupSuccess(cadMessagingGroup) {
    return {
        type: SAVE_SUCCESS,
        payload: cadMessagingGroup,
    };
}

function saveCadMessagingGroupFailure(errMsg) {
    return {
        type: SAVE_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveCadMessagingGroup({ cadMessagingGroup }) {
    return function (dispatch, getState) {
        dispatch(saveCadMessagingGroupStart());

        const isNewMessagingGroup = !cadMessagingGroup.id;
        const method = isNewMessagingGroup ? 'createCadMessagingGroup' : 'updateCadMessagingGroup';

        const { departmentId } = departmentProfileSelector(getState());

        return cadMessagingGroupResource[method]({ ...cadMessagingGroup, departmentId })
            .then((messagingGroup) => {
                messagingGroup.members = _(cadMessagingGroup.groupMembers)
                    .map((item) => {
                        return {
                            entityId: item,
                        };
                    })
                    .value();
                messagingGroup.groupDescription = messagingGroup.description;

                dispatch(storeCadMessagingGroups([messagingGroup]));
                return dispatch(saveCadMessagingGroupSuccess(messagingGroup));
            })
            .catch((err) => dispatch(saveCadMessagingGroupFailure(err.message)));
    };
}

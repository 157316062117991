import { forEach, isFunction, isArray, isString, includes } from 'lodash';
import invariant from 'invariant';
import { sideEffects } from './sideEffectsRegistry';

const actionMatchesConstraint = (action, constraint) => {
    if (isFunction(constraint)) {
        return constraint(action);
    } else if (isArray(constraint)) {
        return includes(constraint, action.type);
    } else if (isString(constraint)) {
        return constraint === action.type;
    } else {
        invariant(
            false,
            `"constraint" must be a function, array or string.  You supplied ${constraint} of type ${typeof constraint}`
        );
    }
};

export const createSideEffectsMiddleware = (dependencies) => (store) => (next) => (action) => {
    // Pass the action throught the reducer first
    const result = next(action);

    // Loop through our sideEffects
    forEach(sideEffects, ({ constraint, callback }) => {
        // See if anything matches our current action
        if (actionMatchesConstraint(action, constraint)) {
            if (callback) {
                callback({ store, action, dependencies });
            }
        }
    });

    return result;
};

import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OperationTypeEnum, TaskView } from '@mark43/rms-api';
import { Link } from 'arc';
import { values, includes } from 'lodash';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { convertAttachmentsToAttachmentViewModel } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { canEditTaskSelector, openTaskSidePanelForTask, TaskOverlayMode } from '../../../state/ui';
import AttachmentsIndicator from '../../../../../attachments/core/components/AttachmentsIndicator';
import { taskAttachmentsMapSelector } from '../../../data';

export const TaskTitleCell: FC<{ task: TaskView }> = ({ task }) => {
    const dispatch = useDispatch();
    const canEditTask = useSelector(canEditTaskSelector);
    const hasEditPermission = includes(task.permissionSet, OperationTypeEnum.WRITE.name);
    const overlayMode =
        canEditTask && hasEditPermission ? TaskOverlayMode.EDIT : TaskOverlayMode.VIEW;

    const openTask = () => {
        dispatch(openTaskSidePanelForTask(task, overlayMode));
    };

    return (
        <>
            <Link onClick={openTask}>{task.title}</Link>
            <TaskAttachmentsIndicator id={task.id} />
        </>
    );
};

const TaskAttachmentsIndicator: FC<Pick<TaskView, 'id'>> = ({ id }) => {
    const taskAttachmentsMap = useSelector(taskAttachmentsMapSelector);
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);

    const taskAttachmentsViewModels = values(
        convertAttachmentsToAttachmentViewModel(taskAttachmentsMap[id], formatMiniUserById)
    );

    return <AttachmentsIndicator attachmentViewModels={taskAttachmentsViewModels} />;
};

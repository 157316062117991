import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router';
import { flowRight, map, slice } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResource } from '~/client-common/core/hooks/useResource';
import * as fields from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';

import personalDashboardResource from '../resources/personalDashboardResource';
import { RECENT_ARRESTS_INTERVAL, MAX_RECENT_ARRESTS } from '../configuration';
import { DashboardRecentArrestsLoader } from './DashboardLoaders';
import {
    ShowMoreButton,
    NoDataBlock,
    DashboardCard,
    DashboardCardContentGrid,
} from './DashboardSection';
import RecentArrest from './RecentArrest';
import RecentArrestsFilters from './RecentArrestsFilters';

const strings = componentStrings.personalDashboard.recentArrests;

const _RecentArrestsContent = ({ router, fieldDisplayNames, dragHandleProps, isDragging }) => {
    const [arrestsToShow, setArrestsToShow] = useState(RECENT_ARRESTS_INTERVAL);
    const [recentArrests, setRecentArrests] = useState([]);
    const [recentArrestsFilters, setRecentArrestsFilters] = useState();
    let content;

    const loadRecentArrests = useCallback(
        () =>
            personalDashboardResource.getRecentArrests(
                {
                    size: MAX_RECENT_ARRESTS,
                    ...recentArrestsFilters,
                },
                { hideLoadingBar: true }
            ),
        [recentArrestsFilters]
    );

    const onResourceSuccess = useCallback(
        (result) => {
            setRecentArrests(result);
        },
        [setRecentArrests]
    );

    const loadingState = useResource(loadRecentArrests, onResourceSuccess);

    const showMoreArrests = useCallback(() => {
        if (arrestsToShow >= MAX_RECENT_ARRESTS) {
            router.push('/reports');
            return;
        }
        setArrestsToShow(arrestsToShow + RECENT_ARRESTS_INTERVAL);
    }, [router, arrestsToShow, setArrestsToShow]);

    const { isLoading, errorMessage } = loadingState;

    const recentArrestsToShow = slice(recentArrests, 0, arrestsToShow);
    const arrestNumberDisplayName = fieldDisplayNames[fields.ARREST_LOCAL_ID];

    if (isLoading && recentArrests.length === 0) {
        content = <DashboardRecentArrestsLoader />;
    } else if (errorMessage) {
        content = <NoDataBlock>{strings.loadError}</NoDataBlock>;
    } else if (recentArrests.length === 0) {
        content = <NoDataBlock>{strings.noResults}</NoDataBlock>;
    } else {
        content = (
            <div>
                <DashboardCardContentGrid>
                    {map(recentArrestsToShow, (recentArrest) => {
                        return (
                            <RecentArrest
                                key={recentArrest.id}
                                recentArrest={recentArrest}
                                arrestNumberDisplayName={arrestNumberDisplayName}
                            />
                        );
                    })}
                </DashboardCardContentGrid>
                {(recentArrestsToShow.length < recentArrests.length ||
                    recentArrests.length >= MAX_RECENT_ARRESTS) && (
                    <ShowMoreButton onClick={showMoreArrests}>{strings.showMore}</ShowMoreButton>
                )}
            </div>
        );
    }

    return (
        <DashboardCard
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
            actions={
                <RecentArrestsFilters
                    submitArrestsFilter={(filters) => {
                        setRecentArrestsFilters(filters);
                    }}
                />
            }
            title={strings.title}
            helpText={strings.helpText}
            minHeight={recentArrests.length > 0 && '240px'}
        >
            {content}
        </DashboardCard>
    );
};

const RecentArrests = flowRight(
    withRouter,
    withFields([fields.ARREST_LOCAL_ID])
)(_RecentArrestsContent);

export default RecentArrests;

import type { Editor } from 'tinymce';

export function withTinyEditor(editorId: string, mode: 'SYNCHRONOUS_OR_THROW'): Editor;
export function withTinyEditor(
    editorId: string,
    mode: 'SYNCHRONOUS_IF_EXISTS' | 'MAYBE_DEFERRED',
    callback: Callback
): void;

/**
 * There are 3 scenarios for getting or using a TinyMCE editor. All interactions with TinyMCE editors must go through
 * this function.
 *
 * SYNCHRONOUS_OR_THROW
 *   Use this mode only when the editor must already have initialized. The errors thrown by this function should not be
 *   shown to the user or break any features.
 *
 * SYNCHRONOUS_IF_EXISTS
 *   Use this mode if it's possible for the editor to not have initialized yet, and if nothing should happen before
 *   initialization.
 *
 * MAYBE_DEFERRED
 *   Use this mode if it's possible for the editor to not have initialized yet, and the code should wait to run after
 *   initialization.
 */
export function withTinyEditor(editorId: string, mode: Mode, callback?: Callback): Editor | void {
    if (mode === 'SYNCHRONOUS_OR_THROW') {
        return getExistingTinyEditorById(editorId);
    } else if (mode && callback) {
        const editor = getTinyEditorById(editorId);
        if (editor) {
            callback(editor);
        } else if (mode === 'MAYBE_DEFERRED') {
            deferOperation(editorId, callback);
        }
    }
}

// this function is not exported in order to force usages of withTinyEditor
function getTinyEditorById(id: string): Editor | undefined {
    return window.tinymce?.EditorManager.get(id) ?? undefined;
}

// this function is not exported in order to force usages of withTinyEditor
function getExistingTinyEditorById(id: string): Editor {
    if (!window.tinymce) {
        throw new Error('TinyMCE has not loaded');
    }
    const editor = getTinyEditorById(id);
    if (!editor) {
        throw new Error(`TinyMCE editor with id '${id}' does not exist`);
    }
    return editor;
}

type Callback = (editor: Editor) => unknown;

type Mode = 'SYNCHRONOUS_OR_THROW' | 'SYNCHRONOUS_IF_EXISTS' | 'MAYBE_DEFERRED';

const deferredOperations: Record<string, Callback[]> = {};

function deferOperation(editorId: string, callback: Callback) {
    if (!deferredOperations[editorId]) {
        deferredOperations[editorId] = [];
    }
    deferredOperations[editorId].push(callback);
}

/**
 * Execute all operations which have been deferred for the given editor.
 */
export function executeDeferredOperationsForTinyEditor(editorId: string, editor: Editor): void {
    const operations = deferredOperations[editorId];
    if (operations && operations.length > 0) {
        operations.forEach((operation) => {
            operation(editor);
        });
        clearDeferredOperationsForTinyEditor(editorId);
    }
}

/**
 * Discard all operations which have been deferred for the given editor.
 * Call this if it's possible for the component to be unmounted before the operations are executed.
 */
export function clearDeferredOperationsForTinyEditor(editorId: string) {
    deferredOperations[editorId] = [];
}

import { at, noop, size } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import EvidenceDashboardSearchResultsActionBar from './EvidenceDashboardSearchResultsActionBar';

const EvidenceDashboardSearchResultsFooter = ({
    allResultsSelected,
    handleClearAllSelectedRows,
    handleSelectAllRows,
    queryResults,
    querySize,
    selectedRows,
    totalResults,
}) => {
    if (!size(selectedRows)) {
        return null;
    }

    return (
        <EvidenceDashboardSearchResultsActionBar
            selectedRows={selectedRows}
            selectedResults={at(queryResults, selectedRows)}
            allResultsSelected={
                // allResultsSelected only true when select all
                // action fired. therefore also check ui selectedRows
                allResultsSelected || selectedRows.length === totalResults
            }
            allResultsOnPageSelected={selectedRows.length === querySize}
            totalResults={totalResults}
            handleSelectAllRows={handleSelectAllRows}
            handleClearAllSelectedRows={handleClearAllSelectedRows}
        />
    );
};

EvidenceDashboardSearchResultsFooter.propTypes = {
    allResultsSelected: PropTypes.bool,
    handleClearAllSelectedRows: PropTypes.func,
    handleSelectAllRows: PropTypes.func,
    queryResults: PropTypes.array,
    querySize: PropTypes.number,
    selectedRows: PropTypes.arrayOf(PropTypes.number),
    totalResults: PropTypes.number,
};

EvidenceDashboardSearchResultsFooter.defaultProps = {
    allResultsSelected: false,
    handleClearAllSelectedRows: noop,
    handleSelectAllRows: noop,
    queryResults: [],
    querySize: 0,
    selectedRows: [],
    totalResults: 0,
};

export default EvidenceDashboardSearchResultsFooter;

import { formatSvgPath } from '../../helpers';

import ArrowLeftWarning from '../../../../../../../../../images/crash-diagram/signs/arrow-left-warning-sign.svg';
import BicycleHillWarning from '../../../../../../../../../images/crash-diagram/signs/bicycle-hill-warning-sign.svg';
import BikeWrongWay from '../../../../../../../../../images/crash-diagram/signs/bike-wrong-way-sign.svg';
import BumpWarning from '../../../../../../../../../images/crash-diagram/signs/bump-warning-sign.svg';
import CurveLeftWarning from '../../../../../../../../../images/crash-diagram/signs/curve-left-warning-sign.svg';
import CurveRightWarning from '../../../../../../../../../images/crash-diagram/signs/curve-right-warning-sign.svg';
import DipWarning from '../../../../../../../../../images/crash-diagram/signs/dip-warning-sign.svg';
import DoNotEnter from '../../../../../../../../../images/crash-diagram/signs/do-not-enter-sign.svg';
import DoubleArrowWarning from '../../../../../../../../../images/crash-diagram/signs/double-arrow-warning-sign.svg';
import ExitFreeway from '../../../../../../../../../images/crash-diagram/signs/exit-freeway-sign.svg';
import HairpinCurveLeftWarning from '../../../../../../../../../images/crash-diagram/signs/hairpin-curve-left-warning-sign.svg';
import HairpinCurveRightWarning from '../../../../../../../../../images/crash-diagram/signs/hairpin-curve-right-warning-sign.svg';
import LaneEndsMergeLeftWarning from '../../../../../../../../../images/crash-diagram/signs/lane-ends-merge-left-warning-sign.svg';
import LaneEndsMergeRightWarning from '../../../../../../../../../images/crash-diagram/signs/lane-ends-merge-right-warning-sign.svg';
import LeftTurnWarning from '../../../../../../../../../images/crash-diagram/signs/left-turn-warning-sign.svg';
import LeftWindingRoadWarning from '../../../../../../../../../images/crash-diagram/signs/left-winding-road-warning-sign.svg';
import LooseGravelWarning from '../../../../../../../../../images/crash-diagram/signs/loose-gravel-warning-sign.svg';
import MergeLeftWarning from '../../../../../../../../../images/crash-diagram/signs/merge-left-warning-sign.svg';
import MergeRightWarning from '../../../../../../../../../images/crash-diagram/signs/merge-right-warning-sign.svg';
import NarrowBridgeWarning from '../../../../../../../../../images/crash-diagram/signs/narrow-bridge-warning-sign.svg';
import NoBiking from '../../../../../../../../../images/crash-diagram/signs/no-biking-sign.svg';
import NoCenterLineWarning from '../../../../../../../../../images/crash-diagram/signs/no-center-line-warning-sign.svg';
import NoHazardousMaterial from '../../../../../../../../../images/crash-diagram/signs/no-hazardous-material-sign.svg';
import NoHorseRiding from '../../../../../../../../../images/crash-diagram/signs/no-horse-riding-sign.svg';
import NoLeftTurn from '../../../../../../../../../images/crash-diagram/signs/no-left-turn-sign.svg';
import NoLeftUTurn from '../../../../../../../../../images/crash-diagram/signs/no-left-u-turn-sign.svg';
import NoParkingAnytime from '../../../../../../../../../images/crash-diagram/signs/no-parking-anytime-sign.svg';
import NoParkingLoadingZone from '../../../../../../../../../images/crash-diagram/signs/no-parking-loading-zone-sign.svg';
import NoParkingOnPavement from '../../../../../../../../../images/crash-diagram/signs/no-parking-on-pavement-sign.svg';
import NoParkingSign from '../../../../../../../../../images/crash-diagram/signs/no-parking-sign.svg';
import NoPedestrianWalking from '../../../../../../../../../images/crash-diagram/signs/no-pedestrian-walking-sign.svg';
import NoRightTurn from '../../../../../../../../../images/crash-diagram/signs/no-right-turn-sign.svg';
import NoSemiTruck from '../../../../../../../../../images/crash-diagram/signs/no-semi-truck-sign.svg';
import NoStandingAnytime from '../../../../../../../../../images/crash-diagram/signs/no-standing-anytime-sign.svg';
import NoStraightThrough from '../../../../../../../../../images/crash-diagram/signs/no-straight-through-sign.svg';
import NoTruck from '../../../../../../../../../images/crash-diagram/signs/no-truck-sign.svg';
import NoUTurn from '../../../../../../../../../images/crash-diagram/signs/no-u-turn-sign.svg';
import RightTurnWarning from '../../../../../../../../../images/crash-diagram/signs/right-turn-warning-sign.svg';
import RightWindingRoadWarning from '../../../../../../../../../images/crash-diagram/signs/right-winding-road-warning-sign.svg';
import RoadGradeEightPercentWarning from '../../../../../../../../../images/crash-diagram/signs/road-grade-8-percent-warning-sign.svg';
import RoadGradeWarning from '../../../../../../../../../images/crash-diagram/signs/road-grade-warning-sign.svg';
import RoadNarrowsWarning from '../../../../../../../../../images/crash-diagram/signs/road-narrows-warning-sign.svg';
import RoughRoadWarning from '../../../../../../../../../images/crash-diagram/signs/rough-road-warning-sign.svg';
import RoundAboutWarning from '../../../../../../../../../images/crash-diagram/signs/round-about-warning-sign.svg';
import SchoolCrossingWarning from '../../../../../../../../../images/crash-diagram/signs/school-crossing-warning-sign.svg';
import SchoolWarning from '../../../../../../../../../images/crash-diagram/signs/school-warning-sign.svg';
import SlipperyWhenWetWarning from '../../../../../../../../../images/crash-diagram/signs/slippery-when-wet-warning-sign.svg';
import StopSign from '../../../../../../../../../images/crash-diagram/signs/stop-sign.svg';
import TruckRolloverWarning from '../../../../../../../../../images/crash-diagram/signs/truck-rollover-warning-sign.svg';
import WaFourLSharpLeftBendAheadWarning from '../../../../../../../../../images/crash-diagram/signs/wa-4l-sharp-left-bend-ahead-warning-sign.svg';
import WaFourLSharpRightBendAheadWarning from '../../../../../../../../../images/crash-diagram/signs/wa-4l-sharp-right-bend-ahead-warning-sign.svg';
import WindingRoadLeftWarning from '../../../../../../../../../images/crash-diagram/signs/winding-road-left-warning-sign.svg';
import WindingRoadRightWarning from '../../../../../../../../../images/crash-diagram/signs/winding-road-right-warning-sign.svg';
import WorkZoneWarning from '../../../../../../../../../images/crash-diagram/signs/work-zone-warning-sign.svg';
import WrongWay from '../../../../../../../../../images/crash-diagram/signs/wrong-way-sign.svg';
import Yeild from '../../../../../../../../../images/crash-diagram/signs/yield-sign.svg';

export const signs = [
    ArrowLeftWarning,
    BicycleHillWarning,
    BikeWrongWay,
    BumpWarning,
    CurveLeftWarning,
    CurveRightWarning,
    DipWarning,
    DoNotEnter,
    DoubleArrowWarning,
    ExitFreeway,
    HairpinCurveLeftWarning,
    HairpinCurveRightWarning,
    LaneEndsMergeLeftWarning,
    LaneEndsMergeRightWarning,
    LeftTurnWarning,
    LeftWindingRoadWarning,
    LooseGravelWarning,
    MergeLeftWarning,
    MergeRightWarning,
    NarrowBridgeWarning,
    NoBiking,
    NoCenterLineWarning,
    NoHazardousMaterial,
    NoHorseRiding,
    NoLeftTurn,
    NoLeftUTurn,
    NoParkingAnytime,
    NoParkingLoadingZone,
    NoParkingOnPavement,
    NoParkingSign,
    NoPedestrianWalking,
    NoRightTurn,
    NoSemiTruck,
    NoStandingAnytime,
    NoStraightThrough,
    NoTruck,
    NoUTurn,
    RightTurnWarning,
    RightWindingRoadWarning,
    RoadGradeEightPercentWarning,
    RoadGradeWarning,
    RoadNarrowsWarning,
    RoughRoadWarning,
    RoundAboutWarning,
    SchoolCrossingWarning,
    SchoolWarning,
    SlipperyWhenWetWarning,
    StopSign,
    TruckRolloverWarning,
    WaFourLSharpLeftBendAheadWarning,
    WaFourLSharpRightBendAheadWarning,
    WindingRoadLeftWarning,
    WindingRoadRightWarning,
    WorkZoneWarning,
    WrongWay,
    Yeild,
].map((svgPath) => formatSvgPath(svgPath));

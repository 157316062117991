import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import { map, mapValues, find, chain, includes } from 'lodash';

import { cautionsByEntitySelector } from '~/client-common/core/domain/cautions/state/data';

import {
    LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_START,
    LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_SUCCESS,
    LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_FAILURE,
    LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_START,
    LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_SUCCESS,
    LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_FAILURE,
    itemProfilesSelector,
    itemProfilesInReportSelector,
} from '../data';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { firearmViewModelByIdSelector } from '../../../firearms/state/ui';
import {
    vehicleViewModelByIdSelector,
    formatTitleForVehicleSelector,
} from '../../../vehicles/state/ui';
import { itemIdentifierViewModelsByItemIdSelector } from '../../../item-identifiers/state/ui';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
    getViewModelProperties,
} from '../../../../../helpers/viewModelHelpers';
import { prettify, joinTruthyValues } from '../../../../../helpers/stringHelpers';
import globalAttributes from '../../../../legacy-constants/globalAttributes';
import { propertyStatusViewModelsByItemProfileIdSelector } from '../../../property-statuses/state/ui';
import { firearmsSelector } from '../../../firearms/state/data';
import { vehiclesSelector } from '../../../vehicles/state/data';
import { formatTitleForFirearm, formatTitleForDefaultItem } from '../../utils/itemProfileHelpers';
import { trafficCrashVehicleViewModelByVehicleIdSelector } from '../../../traffic-crash-vehicles/state/ui';
import { sortedAugmentedAttachmentViewModelsWhereSelector } from '../../../attachments/state/ui';
import { formatAtfManufacturerByIdSelector } from '../../../etrace-atf/state/ui';

const { itemType } = globalAttributes;

const SET_RECENT_HYDRATED_ITEM_PROFILE_IDS = 'item-profiles/SET_RECENT_HYDRATED_ITEM_PROFILE_IDS';

export const itemProfileViewModelsSelector = createSelector(
    itemProfilesSelector,
    formatAttributeByIdSelector,
    itemIdentifierViewModelsByItemIdSelector,
    propertyStatusViewModelsByItemProfileIdSelector,
    trafficCrashVehicleViewModelByVehicleIdSelector,
    sortedAugmentedAttachmentViewModelsWhereSelector,
    cautionsByEntitySelector,
    (
        itemProfiles,
        formatAttributeById,
        itemIdentifierViewModelsByItemId,
        propertyStatusViewModelsByItemProfileId,
        trafficCrashVehicleViewModelByVehicleId,
        sortedAugmentedAttachmentViewModelsWhere,
        cautionsByEntity
    ) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                boolToDisplayMapper,
                (itemProfile) => {
                    const { itemTypeAttrId } = getViewModelProperties(itemProfile);
                    // ad hoc formatting since the item type global attribute
                    // display strings are all caps
                    return {
                        itemTypeAttrId: prettify(itemTypeAttrId),
                    };
                },
                ({ primaryColorAttrId, secondaryColorAttrId }) => ({
                    color: joinTruthyValues(
                        [
                            formatAttributeById(primaryColorAttrId),
                            formatAttributeById(secondaryColorAttrId),
                        ],
                        ' & '
                    ),
                }),
                ({ id }) => ({
                    propertyStatuses: propertyStatusViewModelsByItemProfileId(id),
                }),
                ({ id }) => ({
                    identifiers: itemIdentifierViewModelsByItemId(id),
                }),
                ({ id }) => ({
                    trafficCrashVehicle: trafficCrashVehicleViewModelByVehicleId(id),
                }),
                ({ id, itemTypeAttrId }) => {
                    if (itemTypeAttrId === itemType.vehicle) {
                        return {
                            cautions: cautionsByEntity(EntityTypeEnum.VEHICLE.name, id),
                        };
                    }
                },
                ({ id }) => ({
                    attachmentViewModels: sortedAugmentedAttachmentViewModelsWhere(
                        (attachmentViewModel) => {
                            const linkTypes = [
                                LinkTypesEnum.ITEM_PROFILE_ATTACHMENT,
                                LinkTypesEnum.ITEM_PROFILE_PHOTO,
                                LinkTypesEnum.VEHICLE_LICENSE_PLATE_PHOTO,
                            ];
                            return (
                                attachmentViewModel.entityId === id &&
                                attachmentViewModel.entityType ===
                                    EntityTypeEnum.ITEM_PROFILE.name &&
                                includes(linkTypes, attachmentViewModel.linkType)
                            );
                        }
                    ),
                }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(itemProfiles, viewModel);
    }
);

export const itemProfileViewModelByIdSelector = createSelector(
    itemProfileViewModelsSelector,
    (itemProfileViewModels) => (id) => itemProfileViewModels[id]
);

export const itemProfileViewModelsInReportSelector = createSelector(
    itemProfilesInReportSelector,
    itemProfileViewModelByIdSelector,
    (itemProfilesInReport, itemProfileViewModelById) => (reportId) => {
        return map(itemProfilesInReport(reportId), (item) => itemProfileViewModelById(item.id));
    }
);

/**
 * For itemId, return an item view model. If item type is firearm or vehicle
 *   return the type specific view model. These type specific view models
 *   includes the base item profile view model props.
 * @param  {number} itemId
 * @return {Object}
 */
export const itemTypeSpecificViewModelByIdSelector = createSelector(
    itemProfileViewModelByIdSelector,
    firearmViewModelByIdSelector,
    vehicleViewModelByIdSelector,
    (itemProfileViewModelById, firearmViewModelById, vehicleViewModelById) => (itemId) => {
        const itemProfileViewModel = itemProfileViewModelById(itemId);

        // early exit for instances when awaiting API response
        if (!itemProfileViewModel) {
            return;
        }

        const { itemTypeAttrId } = itemProfileViewModel;

        // cheaper to check item type than build all view models
        if (itemTypeAttrId === itemType.firearm) {
            return firearmViewModelById(itemId);
        } else if (itemTypeAttrId === itemType.vehicle) {
            return vehicleViewModelById(itemId);
        }

        return itemProfileViewModel;
    }
);

export const recentHydratedItemProfileIdsSelector = (state) =>
    state.ui.recentHydratedItemProfileIds.hydratedItemProfileIds;
export const recentHydratedItemProfileIdsLoadingStatusSelector = (state) =>
    state.ui.recentHydratedItemProfileIds.loading;
export const setRecentHydratedItemProfileIds = (hydratedItemProfileIds) => ({
    type: SET_RECENT_HYDRATED_ITEM_PROFILE_IDS,
    payload: hydratedItemProfileIds,
});

const recentHydratedItemProfileIdsReducer = (
    state = { loading: false, hydratedItemProfileIds: [] },
    action
) => {
    switch (action.type) {
        case LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_START:
        case LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_START:
            return {
                ...state,
                loading: true,
            };
        case LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_SUCCESS:
        case LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_FAILURE:
        case LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_SUCCESS:
        case LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case SET_RECENT_HYDRATED_ITEM_PROFILE_IDS:
            return {
                hydratedItemProfileIds: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export const propertyTitleForItemProfileSelector = createSelector(
    firearmsSelector,
    vehiclesSelector,
    formatTitleForVehicleSelector,
    formatAtfManufacturerByIdSelector,
    formatAttributeByIdSelector,
    (
        firearms,
        vehicles,
        formatTitleForVehicle,
        formartAtfManufacturerById,
        formatAttributeById
    ) => (itemProfile) => {
        let propertyTitle;
        switch (itemProfile.itemTypeAttrId) {
            case globalAttributes.itemType.vehicle:
            case globalAttributes.itemType.bus: {
                propertyTitle = formatTitleForVehicle(
                    find(vehicles, { itemId: itemProfile.id }) || {}
                );
                break;
            }
            case globalAttributes.itemType.firearm: {
                propertyTitle = formatTitleForFirearm({
                    description: itemProfile.description,
                    itemCategory: formatAttributeById(itemProfile.itemCategoryAttrId),
                    firearmMake: chain(firearms)
                        .find({ itemId: itemProfile.id })
                        .get('firearmMakeAttrId')
                        .thru(formatAttributeById)
                        .value(),
                    atfManufacturer: chain(firearms)
                        .find({ itemId: itemProfile.id })
                        .get('atfManufacturerId')
                        .thru(formartAtfManufacturerById)
                        .value(),
                });
                break;
            }
            default: {
                propertyTitle = formatTitleForDefaultItem({
                    description: itemProfile.description,
                    itemCategory: formatAttributeById(itemProfile.itemCategoryAttrId),
                });
            }
        }

        return propertyTitle;
    }
);

export const itemTypeSpecificViewModelsForReportIdSelector = createSelector(
    itemProfilesInReportSelector,
    itemTypeSpecificViewModelByIdSelector,
    (itemProfilesInReport, itemTypeSpecificViewModelById) => (reportId) => {
        return map(itemProfilesInReport(reportId), (item) =>
            itemTypeSpecificViewModelById(item.id)
        );
    }
);

export default recentHydratedItemProfileIdsReducer;

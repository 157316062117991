import { EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get, head } from 'lodash';

import { createFormConfiguration, createNItems, createFieldset, _Form } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import {
    LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_TRAFFIC_CRASH_LOCATION_ID,
    LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_TRAFFIC_CRASH_DESCRIPTION,
    LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_TRAFFIC_CRASH_POSITION_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import sortNameReportLinks from '~/client-common/core/domain/name-report-links/utils/sortNameReportLinks';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const formName = RefContextEnum.FORM_TRAFFIC_CRASH.name;
export const TRAFFIC_CRASH_SUBJECTS_PATH = 'trafficCrashSubjects';
export const TRAFFIC_CRASH_LOCATION_PATH = 'trafficCrashLocation';
const TRAFFIC_CRASH_LOCATION_DESCRIPTION_PATH = 'description';
const TRAFFIC_CRASH_LOCATION_POSITION_ATTR_ID_PATH = 'positionAttrId';
export const TRAFFIC_CRASH_LOCATION_DESCRIPTION_FULL_PATH = `${TRAFFIC_CRASH_LOCATION_PATH}.${TRAFFIC_CRASH_LOCATION_DESCRIPTION_PATH}`;
export const TRAFFIC_CRASH_LOCATION_POSITION_ATTR_ID_FULL_PATH = `${TRAFFIC_CRASH_LOCATION_PATH}.${TRAFFIC_CRASH_LOCATION_POSITION_ATTR_ID_PATH}`;
export const getTrafficCrashForm = () => formsRegistry.get(formName);

export const createTrafficCrashForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            [TRAFFIC_CRASH_SUBJECTS_PATH]: createNItems({
                fields: {
                    nameId: {},
                },
            }),
            [TRAFFIC_CRASH_LOCATION_PATH]: createFieldset({
                fields: {
                    locationId: {
                        fieldName: LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_TRAFFIC_CRASH_LOCATION_ID,
                    },
                    [TRAFFIC_CRASH_LOCATION_POSITION_ATTR_ID_PATH]: {
                        fieldName: LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_TRAFFIC_CRASH_POSITION_ATTR_ID,
                    },
                    [TRAFFIC_CRASH_LOCATION_DESCRIPTION_PATH]: {
                        fieldName: LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_TRAFFIC_CRASH_DESCRIPTION,
                    },
                },
            }),
        }),
    });
};

export const buildTrafficCrashCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const locationEntityLinksWhere = locationEntityLinksWhereSelector(state);
    const nameReportLinksWhere = nameReportLinksWhereSelector(state);

    const trafficCrashSubjects = nameReportLinksWhere({
        reportId,
        contextType: EntityTypeEnum.REPORT.name,
        contextId: reportId,
        linkType: LinkTypesEnum.SUBJECT_IN_TRAFFIC_CRASH,
    });
    const sortedTrafficCrashSubjects = sortNameReportLinks({
        nameReportLinks: trafficCrashSubjects,
    });
    const trafficCrashLocation = head(
        locationEntityLinksWhere({
            entityType: EntityTypeEnum.REPORT.name,
            linkType: LinkTypesEnum.LOCATION_OF_TRAFFIC_CRASH,
            entityId: reportId,
        })
    );

    return {
        [TRAFFIC_CRASH_SUBJECTS_PATH]: sortedTrafficCrashSubjects,
        [TRAFFIC_CRASH_LOCATION_PATH]: trafficCrashLocation,
    };
};

export const buildTrafficCrashCardBundle = ({ form }) => {
    const {
        model: { [TRAFFIC_CRASH_LOCATION_PATH]: trafficCrashLocation },
    } = form.getState();

    // Only preserve the `LocationEntityLink` if the form has a valid
    // `LocationEntityLink`; use `locationId` to validate.
    // This is because both initializing and adding-then-removing a
    // location will cause the form model state to correctly reset with
    // `undefined` values for `locationId` & `description`, but the BE
    // is expecting a `NULL` `locationEntityLink` for processing,
    // not an "empty" one.
    const filteredTrafficCrashLocation = isUndefinedOrNull(get(trafficCrashLocation, 'locationId'))
        ? undefined
        : trafficCrashLocation;

    return { locationEntityLink: filteredTrafficCrashLocation };
};

export const refreshTrafficCrashForm = ({ reportId }) => (dispatch) => {
    const form = getTrafficCrashForm();
    const formModel = dispatch(buildTrafficCrashCardFormModel({ reportId }));
    form.set('', formModel);
};

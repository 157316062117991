import { RefContextEnum, FieldTypeEnum } from '@mark43/rms-api';
import _, { includes, get, map, parseInt, reject } from 'lodash';
import { createSelector } from 'reselect';

import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { fieldDetailByIdSelector } from '~/client-common/core/domain/field-details/state/data';
import { fieldDetailContextsWhereSelector } from '~/client-common/core/domain/field-detail-contexts/state/data';
import {
    fieldConfigurationsSelector,
    fieldConfigurationByIdSelector,
} from '~/client-common/core/domain/field-configurations/state/data';
import { fieldConfigurationContextsWhereSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import stringsConfig from '~/client-common/core/strings';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    LOCATION_ENTITY_LINK_SUBDIVISION_1_ATTR_ID,
    LOCATION_ENTITY_LINK_SUBDIVISION_2_ATTR_ID,
    LOCATION_ENTITY_LINK_SUBDIVISION_3_ATTR_ID,
    LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID,
    LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

import {
    SAVE_FIELD_CONFIGURATION_ADMIN_FORM_START,
    SAVE_FIELD_CONFIGURATION_ADMIN_FORM_SUCCESS,
    SAVE_FIELD_CONFIGURATION_ADMIN_FORM_FAILURE,
} from '../data';
import fieldConfigurationsAdminForm from '../forms/fieldConfigurationsAdminForm';
import {
    buildFieldConfigurationTitle,
    sortFieldConfigurationContextsByContextName,
    dateValueNowStringKey,
} from '../../utils/fieldConfigurationAdminHelpers';

const strings = stringsConfig.components.admin.fieldConfigurations;

const RESET_FIELD_CONFIGURATION_ADMIN_PAGE =
    'field-configurations/RESET_FIELD_CONFIGURATION_ADMIN_PAGE';
const SELECT_FIELD_CONFIGURATION_START = 'field-configurations/SELECT_FIELD_CONFIGURATION_START';
const SELECT_FIELD_CONFIGURATION_SUCCESS =
    'field-configurations/SELECT_FIELD_CONFIGURATION_SUCCESS';
const SELECT_FIELD_CONFIGURATION_FAILURE =
    'field-configurations/SELECT_FIELD_CONFIGURATION_FAILURE';
const TOGGLE_INTERNAL_FIELDS = 'field-configurations/TOGGLE_INTERNAL_FIELDS';

export const dateFieldTypeOptions = [
    { display: dateValueNowStringKey, value: dateValueNowStringKey },
];
export const yesNoOptions = [
    { display: 'Yes', value: true },
    { display: 'No', value: false },
];

// SELECTORS
export const uiSelector = (state) => state.ui.fieldConfigurationsAdmin;

/**
 * Using base selector here for performance reasons. These field detail/configurations selectors
 *   are generally static and building them once and then manipulating them based on ui selector
 *   prevents us from re-building them.
 */
const baseFieldConfigurationsAdminListItemsSelector = createSelector(
    fieldDetailByIdSelector,
    fieldDetailContextsWhereSelector,
    fieldConfigurationsSelector,
    fieldConfigurationContextsWhereSelector,
    (
        fieldDetailById,
        fieldDetailContextsWhere,
        fieldConfigurations,
        fieldConfigurationContextsWhere
    ) =>
        _(fieldConfigurations)
            .map((fieldConfiguration) => {
                const fieldDetail = fieldDetailById(fieldConfiguration.fieldDetailId);
                const fieldConfigurationId = fieldConfiguration.id;
                const fieldConfigurationContexts = sortFieldConfigurationContextsByContextName(
                    fieldConfigurationContextsWhere({ fieldConfigurationId })
                );
                const title = buildFieldConfigurationTitle(fieldDetail, fieldConfiguration);
                const subtitle = joinTruthyValues(
                    map(fieldConfigurationContexts, (fieldConfigurationContext) =>
                        get(RefContextEnum, `${fieldConfigurationContext.context}.displayName`)
                    )
                );

                // DEX has some fields that have FCC without FDC, which breaks the admin page,
                // so make sure to hide these by default.
                const fieldDetailContexts = fieldDetailContextsWhere({
                    fieldDetailId: fieldDetail.id,
                });

                // These fields are not in any context, but _do_ support re-labeling, so we want to force show them.
                // As soon as they are added to any context, we can remove this.
                const isFieldForceShown = includes(
                    [
                        REPORT_REPORTING_EVENT_NUMBER,
                        LOCATION_ENTITY_LINK_SUBDIVISION_1_ATTR_ID,
                        LOCATION_ENTITY_LINK_SUBDIVISION_2_ATTR_ID,
                        LOCATION_ENTITY_LINK_SUBDIVISION_3_ATTR_ID,
                        LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID,
                        LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID,
                    ],
                    fieldDetail.fieldName
                );

                const hasNoContexts =
                    fieldDetailContexts.length === 0 || fieldConfigurationContexts.length === 0;

                const isFieldInternal =
                    !isFieldForceShown &&
                    (fieldDetail.fieldType === FieldTypeEnum.INTERNAL_DATA.name || hasNoContexts);

                return {
                    key: fieldConfigurationId,
                    title,
                    subtitle,
                    path: `/admin/fields/${fieldConfigurationId}`,
                    selected: false,
                    internalName: fieldDetail.fieldName,
                    fieldType: fieldDetail.fieldType,
                    isFieldInternal,
                };
            })
            // Not using `sortByAll` because we want to specially handle empty
            // string `subtitle`s by sorting them last.
            .sortBy('title')
            .sortBy((listItem) => (listItem.subtitle ? listItem.subtitle : null))
            .value()
);

export const fieldConfigurationsAdminListItemsSelector = createSelector(
    baseFieldConfigurationsAdminListItemsSelector,
    uiSelector,
    (baseFieldConfigurationsAdminListItems, ui) => {
        const fieldConfigurationsAdminListItems = ui.showInternalFields
            ? baseFieldConfigurationsAdminListItems
            : reject(baseFieldConfigurationsAdminListItems, 'isFieldInternal');

        if (!ui.selectedFieldConfigurationId) {
            return fieldConfigurationsAdminListItems;
        }

        return map(fieldConfigurationsAdminListItems, (item) =>
            item.key === ui.selectedFieldConfigurationId ? { ...item, selected: true } : item
        );
    }
);

// ACTIONS
// Select action
function selectFieldConfigurationStart() {
    return { type: SELECT_FIELD_CONFIGURATION_START };
}

function selectFieldConfigurationSuccess(fieldConfigurationId) {
    return {
        type: SELECT_FIELD_CONFIGURATION_SUCCESS,
        payload: fieldConfigurationId,
    };
}

function selectFieldConfigurationFailure(errorMessage) {
    return {
        type: SELECT_FIELD_CONFIGURATION_FAILURE,
        payload: errorMessage,
    };
}

export function toggleInternalFields() {
    return { type: TOGGLE_INTERNAL_FIELDS };
}

export function selectFieldConfiguration(fieldConfigurationId) {
    return (dispatch, getState) => {
        dispatch(selectFieldConfigurationStart());
        const state = getState();
        const id = parseInt(fieldConfigurationId);
        const fieldConfiguration = fieldConfigurationByIdSelector(state)(id);
        if (fieldConfiguration) {
            const fieldDetail = fieldDetailByIdSelector(state)(fieldConfiguration.fieldDetailId);
            const fieldConfigurationContexts = fieldConfigurationContextsWhereSelector(state)({
                fieldConfigurationId: id,
            });
            dispatch(
                fieldConfigurationsAdminForm.actionCreators.change({
                    fieldDetail,
                    fieldConfiguration,
                    fieldConfigurationContexts,
                })
            );
            dispatch(selectFieldConfigurationSuccess(id));
        } else {
            dispatch(
                selectFieldConfigurationFailure(
                    strings.selectFieldConfigurationError(fieldConfigurationId)
                )
            );
        }
    };
}

// Reset action
export function resetFieldConfigurationAdminPage() {
    return { type: RESET_FIELD_CONFIGURATION_ADMIN_PAGE };
}

// INITIAL STATE
const initialUiState = {
    selectedFieldConfigurationId: null,
    submittingForm: false,
    selectFieldConfigurationError: null,
    saveErrors: [],
    showInternalFields: false,
};

// REDUCER
function fieldConfigurationsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case RESET_FIELD_CONFIGURATION_ADMIN_PAGE:
            return {
                ...state,
                selectedFieldConfigurationId: null,
                submittingForm: false,
                selectFieldConfigurationError: null,
                saveErrors: [],
                showInternalFields: state.showInternalFields,
            };
        case SELECT_FIELD_CONFIGURATION_START:
            return {
                ...state,
                selectedFieldConfigurationId: null,
                submittingForm: false,
                selectFieldConfigurationError: null,
                saveErrors: [],
            };
        case SELECT_FIELD_CONFIGURATION_SUCCESS:
            return {
                ...state,
                selectedFieldConfigurationId: action.payload,
                submittingForm: false,
                selectFieldConfigurationError: null,
                saveErrors: [],
            };
        case SELECT_FIELD_CONFIGURATION_FAILURE:
            return {
                ...state,
                selectedFieldConfigurationId: null,
                submittingForm: false,
                selectFieldConfigurationError: action.payload,
                saveErrors: [],
            };
        case SAVE_FIELD_CONFIGURATION_ADMIN_FORM_START:
            return {
                ...state,
                submittingForm: true,
                selectFieldConfigurationError: null,
                saveErrors: [],
            };
        case SAVE_FIELD_CONFIGURATION_ADMIN_FORM_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                selectFieldConfigurationError: null,
                saveErrors: [],
            };
        case SAVE_FIELD_CONFIGURATION_ADMIN_FORM_FAILURE:
            return {
                ...state,
                submittingForm: false,
                selectFieldConfigurationError: null,
                saveErrors: action.payload,
            };
        case TOGGLE_INTERNAL_FIELDS:
            return {
                ...state,
                showInternalFields: !state.showInternalFields,
            };
        default:
            return state;
    }
}

export default fieldConfigurationsAdminUiReducer;

import React, { useCallback } from 'react';
import { Checkbox } from 'arc';
import { every, find, isEmpty, isEqual, map, unionWith } from 'lodash';
import { useEFileContext } from '../../../../hooks';
import { ColumnT, SelectedNameEntityT } from '../../../../types';
import testIds from '../../../../../../core/testIds';

import {
    EFileTableHeaderRow,
    EFileTableHeaderWrapper,
    EFileTableHeader,
    EFileTableHeaderCell,
    EFileNameTableHeaderCell,
    EFileCheckboxTableHeader,
} from '../EFileTable';

export const EFileInvolvedProfilesHeaderRow = ({
    columns,
    canEdit,
}: {
    columns: ColumnT[];
    canEdit: boolean;
}) => {
    const {
        actions,
        getters: {
            search: { getEFileDefendants },
            grid: { getEFileSelectedInvolvedProfiles },
        },
    } = useEFileContext();

    const selectedProfiles = getEFileSelectedInvolvedProfiles();

    const checkBoxOnChange = useCallback(
        (event) => {
            const checked = event.target.checked;
            const transformedInvolvedProfiles: SelectedNameEntityT[] = map(
                getEFileDefendants(),
                (selectedProfile) => {
                    return {
                        entityId: selectedProfile.nameData.entityId,
                        entityType: selectedProfile.nameData.entityType,
                    };
                }
            );
            const selected = checked
                ? unionWith(selectedProfiles, transformedInvolvedProfiles, isEqual)
                : [];

            actions.grid.setEFileSelectedInvolvedProfiles(selected);
        },
        [getEFileDefendants, selectedProfiles, actions.grid]
    );

    const isChecked =
        !isEmpty(getEFileDefendants()) &&
        every(getEFileDefendants(), (profile) => {
            return !!find(selectedProfiles, {
                entityId: profile.nameData.entityId,
                entityType: profile.nameData.entityType,
            });
        });

    const isCheckboxDisabled = !canEdit || isEmpty(getEFileDefendants());

    return (
        <EFileTableHeaderWrapper>
            <EFileTableHeaderRow data-test-id={testIds.TABLE_ROW}>
                <EFileCheckboxTableHeader>
                    <Checkbox
                        isDisabled={isCheckboxDisabled}
                        isChecked={isChecked}
                        onChange={checkBoxOnChange}
                    />
                </EFileCheckboxTableHeader>
                {map(columns, (column) => {
                    const HeaderCellComponent =
                        column.key === 'nameData' ? EFileNameTableHeaderCell : EFileTableHeaderCell;
                    return (
                        <EFileTableHeader key={column.key} width={column.width}>
                            <HeaderCellComponent>{column.title}</HeaderCellComponent>
                        </EFileTableHeader>
                    );
                })}
            </EFileTableHeaderRow>
        </EFileTableHeaderWrapper>
    );
};

import React from 'react';
import { Switch as ArcSwitch, SwitchProps as ArcSwitchProps } from 'arc';
import styled from 'styled-components';
import testIds from '../../../../core/testIds';
import { arbiterMFTInput } from '../../arbiter';
import FormElement from './FormElement';

const Switch = styled(ArcSwitch)`
    // override the box-sizing default in RMS that makes this component appear squished
    * {
        box-sizing: content-box;
    }
`;

type SwitchProps = {
    name?: string;
    value?: boolean;
    onChange?: (value: boolean) => void;
    defaultChecked?: boolean;
    disabled?: boolean;
    label?: string;
    className?: string;
    testId?: string;
    size?: ArcSwitchProps['size'];
};

const FormSwitch = ({
    name,
    value,
    label,
    disabled = false,
    defaultChecked = false,
    onChange,
    size,
    testId,
    ...props
}: SwitchProps) => {
    return (
        <FormElement {...props}>
            <Switch
                name={name}
                isChecked={!!value}
                isDisabled={disabled}
                defaultChecked={defaultChecked}
                data-test-id={testId || testIds.SWITCH_INPUT}
                onChange={() => {
                    onChange?.(!value);
                }}
                size={size}
            >
                {label}
            </Switch>
        </FormElement>
    );
};

export const ArbiterMFTSwitch = arbiterMFTInput(FormSwitch);

export default Switch;

import React from 'react';
import { Text, TextProps } from 'arc';

// Default text for body content. If doing specific styling withing a component and overriding this much, should probably style the `Text` component directly from ARC instead.
// Note: Overriding the default fontSize currently to bump it up to 16px.
export const BodyMediumText = React.forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
    return <Text as="span" variant="bodyMd" fontSize="md" ref={ref} {...props} />;
});

// Uses for small section headings on pages and in drawers.
export const SubsectionTitleText = (props: TextProps) => {
    return <Text variant="headingSm" fontFamily="body" {...props} />;
};

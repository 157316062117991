import React from 'react';
import { map } from 'lodash';
import ConnectedFormattedAttribute from '../../attributes/components/ConnectedFormattedAttribute';

/**
 * Use this component to display property status titles given a list of property statuses.
 * @param  {Object[]} props.propertyStatuses
 * @param  {String}   props.className        (Optional) className for resulting span.
 */
export default function PropertyStatusTitles({ propertyStatuses, className }) {
    return (
        <div className={className}>
            {map(propertyStatuses, ({ id, propertyStatusAttrId }) => (
                <ConnectedFormattedAttribute key={id} attributeId={propertyStatusAttrId} />
            ))}
        </div>
    );
}

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EntityPermission } from '@mark43/rms-api';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { SearchModuleType } from '../../../../core/typings/search-module';

type OnSaveSearchParams = {
    query?: { savedSearchId?: number; isAutoSave?: boolean };
    name?: string;
    isShared?: boolean;
    entityPermissions?: Partial<EntityPermission>[];
};

const useSaveAsNewSearch = (searchModule: SearchModuleType) => {
    const overlayStore = useOverlayStore();
    const dispatch = useDispatch();
    const currentQuery = useSelector(searchModule?.selectors?.currentQuerySelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const savedSearchSharingEnabled = applicationSettings.RMS_SAVED_SEARCH_SHARING_ENABLED;

    return useCallback(
        (params?: OnSaveSearchParams) => {
            const query = params?.query || currentQuery;
            const { name, isShared, entityPermissions = [] } = params || {};
            if (!name) {
                overlayStore.open(overlayIdEnum.SAVED_SEARCH_CREATE_MODAL, {
                    name,
                    isShared,
                    entityPermissions,
                });
            } else {
                dispatch(
                    searchModule.actionCreators.saveSearch(query, {
                        name,
                        isShared,
                        entityPermissions,
                        savedSearchSharingEnabled,
                    })
                );
            }
        },
        [dispatch, searchModule, savedSearchSharingEnabled, overlayStore, currentQuery]
    );
};

export default useSaveAsNewSearch;

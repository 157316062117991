import { OfficerInvolvementEnum } from '@mark43/rms-api';
import { compact, get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import keyMirror from 'keymirror';
import { filterFormData } from '~/client-common/helpers/formHelpers';
import { NEXUS_STATE_PROP as ELASTIC_REPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-reports/state/data';
import { offenseReportDefinitionForCurrentDepartmentSelector } from '../../../../../core/report-definitions/state/ui';
import elasticSearchResource from '../../../../../../legacy-redux/resources/elasticSearchResource';

const ELASTIC_SEARCH_REPORTS_FROM = 0;
const ELASTIC_SEARCH_REPORTS_SIZE = 10;

const priorOffenseSearchBaseElasticQuerySelector = createSelector(
    offenseReportDefinitionForCurrentDepartmentSelector,
    (offenseReportDefinitionForCurrentDepartment) => {
        return {
            isConsortium: false,
            reportDefinitions: [
                {
                    reportDefinitionId: get(offenseReportDefinitionForCurrentDepartment, 'id'),
                    offensesAndIncidents: [
                        {
                            isOffense: true,
                        },
                    ],
                },
            ],
        };
    }
);

export const PRIOR_OFFENSE_SEARCH_TYPES = keyMirror({
    REN: null,
    DATE_RANGE_OR_RESPONDING_OFFICERS: null,
});

export const searchElasticReports = ({ elasticQuery, from, size, actionType }) => (
    dispatch,
    getState,
    { nexus: { withEntityItems } }
) => {
    return elasticSearchResource
        .searchReports(elasticQuery, from, size)
        .then((elasticReportsSearchResult) => {
            const { items, query } = elasticReportsSearchResult;
            // no lodash `size` here because of naming collision
            if (!isEmpty(items)) {
                dispatch(
                    withEntityItems({ [ELASTIC_REPORTS_NEXUS_STATE_PROP]: items }, actionType)
                );
            }
            return {
                elasticReportsSearchResult,
                from: query.from,
                size: query.size,
            };
        });
};

export const performPriorOffenseSearch = ({ searchType, priorOffenseSearchFormModel }) => (
    dispatch,
    getState
) => {
    const { REN, DATE_RANGE_OR_RESPONDING_OFFICERS } = PRIOR_OFFENSE_SEARCH_TYPES;
    const {
        reportingEventNumber,
        respondingOfficerIds,
        startDateUtc,
        endDateUtc,
    } = priorOffenseSearchFormModel;
    const baseElasticQuery = priorOffenseSearchBaseElasticQuerySelector(getState());
    const elasticQuery = {
        ...baseElasticQuery,
        reportingEventNumbers: searchType === REN ? compact([reportingEventNumber]) : [],
        eventStartDateRangeQuery:
            searchType === DATE_RANGE_OR_RESPONDING_OFFICERS
                ? {
                      startDateUtc,
                      endDateUtc,
                  }
                : {},
        involvedOfficers:
            searchType === DATE_RANGE_OR_RESPONDING_OFFICERS && !isEmpty(respondingOfficerIds)
                ? [
                      {
                          officerInvolvement: OfficerInvolvementEnum.RESPONDING.name,
                          officerIds: respondingOfficerIds,
                      },
                  ]
                : [],
    };
    const filteredElasticQuery = filterFormData(elasticQuery);

    return dispatch(
        searchElasticReports({
            elasticQuery: filteredElasticQuery,
            from: ELASTIC_SEARCH_REPORTS_FROM,
            size: ELASTIC_SEARCH_REPORTS_SIZE,
            actionType: { type: 'PRIOR_OFFENSE_SEARCH_ELASTIC_REPORTS' },
        })
    );
};

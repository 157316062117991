import { find } from 'lodash';
import { ExportPresetView } from '@mark43/rms-api';
import { PacketOption } from './exportHelpers';

/**
 * Find and return the matching export preset report type for a given packet and export preset.
 */
export function getMatchingExportPresetReportTypeForPacket(
    exportPresetReportTypes: ExportPresetView['exportPresetReportTypes'] | undefined,
    packet: PacketOption | PacketOption['rawPacket'] | undefined
) {
    return find(
        exportPresetReportTypes,
        (reportType) =>
            reportType.reportDefinitionId === packet?.reportDefinitionId &&
            reportType.reportPrintingTemplateId === packet?.reportPrintingTemplateId
    );
}

import { isEmpty } from 'lodash';
import { formEvents } from 'markformythree';
import Promise from 'bluebird';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { towVehicleReleaseByReportIdSelector } from '~/client-common/core/domain/tow-vehicle-releases/state/data';
import { replaceTowVehicleReleaseCardBundle } from '~/client-common/core/domain/reports/state/ui/towVehicleReleases';

import {
    getTowVehicleReleaseForm,
    refreshTowVehicleReleaseForm,
    buildTowVehicleReleaseCardBundle,
} from '../../forms/towVehicleReleaseForm';

const TOW_VEHICLE_RELEASE_SIDE_PANEL_SCREEN = 'TOW_VEHICLE_RELEASE_SIDE_PANEL_SCREEN';

const buildTowVehicleReleaseSidePanelInitialCustomPropertyState = () => {
    return {
        screenStack: [
            {
                screen: TOW_VEHICLE_RELEASE_SIDE_PANEL_SCREEN,
                screenState: {
                    isPerformingAsyncAction: false,
                },
            },
        ],
    };
};

const refreshTowVehicleReleaseFormAndUi = ({ reportId }) => (dispatch) => {
    const form = getTowVehicleReleaseForm();
    dispatch(refreshTowVehicleReleaseForm({ reportId }));
    form.resetUi();
};

export const openTowVehicleReleaseSidePanel = ({ reportId }) => (
    dispatch,
    getState,
    { overlayStore: { open } }
) => {
    const state = getState();
    const towVehicleReleaseByReportId = towVehicleReleaseByReportIdSelector(state);
    const towVehicleRelease = towVehicleReleaseByReportId(reportId);

    if (!isUndefinedOrNull(towVehicleRelease)) {
        dispatch(refreshTowVehicleReleaseFormAndUi({ reportId }));
        const initialCustomPropertyState = buildTowVehicleReleaseSidePanelInitialCustomPropertyState();
        open(overlayIdEnum.TOW_VEHICLE_RELEASE_SIDE_PANEL, initialCustomPropertyState);
    }
};

export const saveTowVehicleReleaseSidePanel = ({ reportId }) => (dispatch) => {
    const form = getTowVehicleReleaseForm();
    const { formErrors, success } = form.validate({ eventType: formEvents.FORM_SUBMIT });

    if (isEmpty(formErrors) && success) {
        const towVehicleReleaseCardBundle = buildTowVehicleReleaseCardBundle({
            reportId,
            form,
        });
        return dispatch(
            replaceTowVehicleReleaseCardBundle({
                reportId,
                towVehicleReleaseCardBundle,
            })
        ).then(() => {
            dispatch(refreshTowVehicleReleaseFormAndUi({ reportId }));
        });
    }
    return Promise.reject(formErrors);
};

export const onCloseTowVehicleReleaseSidePanel = ({ reportId }) => (dispatch) => {
    dispatch(refreshTowVehicleReleaseFormAndUi({ reportId }));
};

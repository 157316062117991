import styled from 'styled-components';

const TableHeader = styled.div<{ width: number }>`
    line-height: 35px;
    text-transform: uppercase;
    padding: 12px 0;
    font: ${(props) =>
        `${props.theme.fontWeights.semiBold} var(--arc-fontSizes-sm) ${props.theme.fontFamilies.proximaNova}`};
    color: ${(props) => props.theme.colors.mediumGrey};
    width: ${(props) => props.width}px;
`;

export const TableHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

export default TableHeader;

import React from 'react';
import { trim } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { RRFText } from '../../forms/components/Text';
import { RRFAsyncText } from '../../forms/components/AsyncText';

const strings = componentStrings.core.RenSearchModal;

/**
 * Component that includes a text box and a search button for REN search
 * @param {Boolean} typeToSearch            Whether or not form is async
 * @param {Function} submitSearch           Search onClick callback
 * @param {Boolean} disabled                Whether or not form is disabled
 * @param {String} path                     Path for form
 * @param {Number | String} width           Width of text box
 * @param {Object} otherProps               Rest of props
 */
export default function RenSearchText({
    typeToSearch = false,
    submitSearch,
    disabled,
    path = 'reportingEventNumber',
    width = 300,
    ...otherProps
}) {
    return (
        <SearchText
            typeToSearch={typeToSearch}
            submitSearch={submitSearch}
            width={width}
            path={path}
            goButton={!typeToSearch}
            alterValue={(value) => trim(value)}
            disabled={disabled}
            {...otherProps}
        />
    );
}

/**
 * Component that includes a text box and a search button for REN search
 * @param {Boolean} typeToSearch            Whether or not form is async
 * @param {Function} submitSearch           Search onClick callback
 * @param {Object} otherProps               Rest of props
 */
function SearchText({ typeToSearch, submitSearch, ...otherProps }) {
    return typeToSearch ? (
        <RRFAsyncText asyncAction={submitSearch} {...otherProps} />
    ) : (
        <RRFText
            goButtonText={strings.goButtonText}
            onPressEnter={submitSearch}
            onClick={submitSearch}
            {...otherProps}
        />
    );
}

import React, { useState, useCallback } from 'react';
import { VStack } from 'arc';
import { isEmpty, map } from 'lodash';
import { Form, lifecycleOptions } from 'markformythree';
import { EntityTypeEnumType, LinkTypesEnum, SubmissionMessageTypeOption } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResource } from '~/client-common/core/hooks/useResource';
import {
    formConfiguration,
    GENERIC_SUBMISSION_CREATION_FORM_NAME,
    onValidate,
    validationEvents,
} from '../state/forms/createGenericSubmissionsForm';
import { MFTSelect } from '../../core/forms/components/selects/Select';
import submissionMessageTypeResource from '../resources/submissionMessageTypeResource';
import { SubmissionAttachments } from './submission-form-components';

type PropsT = {
    entityId: number;
    entityType: EntityTypeEnumType;
};

const strings = componentStrings.admin.submissions.createSubmissionMessageModal;

export const CreateSubmissionsForm: React.FC<PropsT> = ({ entityId, entityType }) => {
    const [submissionMessageTypeOptions, setSubmissionMessageTypeOptions] = useState<
        SubmissionMessageTypeOption[]
    >([]);
    const [
        selectedMessageTypeOption,
        setSelectedMessageTypeOption,
    ] = useState<SubmissionMessageTypeOption>();
    const onMessageTypesFetchSuccess = useCallback((options: SubmissionMessageTypeOption[]) => {
        if (!isEmpty(options)) {
            setSubmissionMessageTypeOptions(options);
        }
    }, []);

    const fetchMessageTypes = useCallback(() => {
        return submissionMessageTypeResource.getMessageTypesForEntity(entityId, entityType);
    }, [entityId, entityType]);

    const onSubmissionMessageTypeSelectChange = useCallback(
        (messageTypeOptionId) => {
            const messageTypeOption = submissionMessageTypeOptions.find(
                (option) => option.id === messageTypeOptionId
            );
            setSelectedMessageTypeOption(messageTypeOption);
        },
        [submissionMessageTypeOptions]
    );

    useResource(fetchMessageTypes, onMessageTypesFetchSuccess);

    return (
        <Form
            configuration={formConfiguration}
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={GENERIC_SUBMISSION_CREATION_FORM_NAME}
            onValidate={onValidate}
            validationEvents={validationEvents}
            render={() => {
                return (
                    <VStack alignItems="flex-start">
                        <MFTSelect
                            path="messageTypeId"
                            options={map(submissionMessageTypeOptions, (option) => {
                                return {
                                    display: option.displayName,
                                    value: option.id,
                                    isDisabled: option.isDisabled,
                                };
                            })}
                            label={strings.labels.messageType}
                            length={396}
                            onChange={onSubmissionMessageTypeSelectChange}
                        />
                        {!!selectedMessageTypeOption?.linkTypeToAllowMultiple &&
                            Object.keys(selectedMessageTypeOption.linkTypeToAllowMultiple).includes(
                                String(LinkTypesEnum.ATTACHMENT_IN_EFILE)
                            ) && (
                                <SubmissionAttachments
                                    path="attachmentIds"
                                    label={strings.labels.attachments}
                                    entityId={entityId}
                                    entityType={entityType}
                                    allowMultiple={
                                        selectedMessageTypeOption?.linkTypeToAllowMultiple[
                                            LinkTypesEnum.ATTACHMENT_IN_EFILE
                                        ]
                                    }
                                />
                            )}
                    </VStack>
                );
            }}
        />
    );
};

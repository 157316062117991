import * as React from 'react';
import { Flex, Box } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { RRFDateRangePicker } from '../../../../core/forms/components/DateRangePicker';
import { RRFDatePicker } from '../../../../core/forms/components/DatePicker';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';

import { Switch } from './common';
import type { RRFFormActions } from './types';

const strings = componentStrings.search.AdvancedSearchReportsForm.dragon;

export const DateInput = reactReduxFormHelpers.withRRFActions(function DateInput({
    path,
    formActions,
}: {
    path: string;
} & RRFFormActions): JSX.Element {
    const [isRange, setIsRange] = React.useState(true);

    return (
        <Flex basis="50%" direction="column" alignItems="flex-start">
            <Box width="100%">
                {isRange ? (
                    <RRFDateRangePicker
                        paths={{
                            withinLastPeriod: `${path}.withinLastPeriod`,
                            toDatePeriod: `${path}.toDatePeriod`,
                            startDateUtc: `${path}.startDateUtc`,
                            endDateUtc: `${path}.endDateUtc`,
                        }}
                        label={strings.inputFields.date.rangeLabel}
                        length="md"
                        includeTime={true}
                        withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                        toDatePeriodOptions={['P1M', 'P1Y']}
                        style={{ maxWidth: 'none' }}
                    />
                ) : (
                    <RRFDatePicker
                        label={strings.inputFields.date.label}
                        path={path}
                        includeTime={false}
                    />
                )}
            </Box>
            <Switch
                isChecked={isRange}
                onChange={() => {
                    formActions.change(path, undefined);
                    setIsRange((isRange) => !isRange);
                }}
            >
                Range
            </Switch>
        </Flex>
    );
});

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import { ruleTypes } from 'arbiter-mark43';

import { compose } from 'recompose';
import {
    nameReportLinksWhereSelector,
    deleteNameReportLink,
    addNameReportLink,
} from '~/client-common/core/domain/name-report-links/state/data';
import { storeOrganizationProfiles } from '~/client-common/core/domain/organization-profiles/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { openErrorModal } from '../../../legacy-redux/actions/boxActions';
import { LocationQuickAddContext } from '../context/LocationQuickAddContext';
import { withQuickAddCallback } from './withQuickAddCallback';
import { NameSummaryView } from './NameSummaryView';

/**
 * Use this component to display a name summary on a Card. Supports summary and edit modes.
 * Currently, if you need to provide fields/forms, this component will assume you are using RRF and Arbiter.
 * @param {String} linkType     link type of the link, should be an entry in linkTypesEnum, used to pull and save the right links
 * @param {String} contextType  Passed through to Person/Org SidePanel - helps distinguish the link for N cards, N persons.
 *                              For example, for a Victim on Offense link, need to know which offense the victim is on.
 * @param {Number} nameId       Specifically only when link type does not have a `nameReportLink`,
 *                              and we want to render a dummy link (e.g. arrest / warrant)
 * @param {Number} contextId    Passed through to Person/Org SidePanel- id of the contextType
 * @param {String} parentEntityType Passed through to Person/Org SidePanel - used to determine what save logic to use
 * @param {Number} parentId         Passed through to Person/Org SidePanel - id of the parentEntityType
 * @param {String} [entityType]     Entity type associated with the link, used to filter displayed links when provided.
 * @param {Object} show         Determines which entity types to show - persons, orgs, societies. If no `show` prop is passed in, we show only "+ person" by default
 * @param {Boolean} summaryMode    True if card is in summary mode, false if card is in edit mode
 * @param {String} addNameButtonText Text shown to add a new link, eg, + Subject or + Organization
 * @param {Function} renderAdditionalItem   Render prop to show additional summary fields or form fields under the base name summary. Ex, "Subject Type" field for a Field Contact subject
 * @param {Object} form         Pass in the form module if you need to show additional form fields. Passing this in means deleting and saving a link will dispatch actions on the form
 * @param {String} formPath     The path to your link fields, eg, if your form model looks like `myForm.nameReportLinks`, then form = myForm, formPath = 'nameReportLinks'
 * @param {Function} onAddSuccess A function to get called when the entity profile has finished saving. It is passed link data
 * @param {Function} onRemoveSuccess A function to get called when the entity profile has been removed. It receives the removed link and its index
 * @param {Function} deleteNameReportLink Custom function to be called during name report link deletion. This function will be responsible for any API calls made to delete name report links if it is passed in.
 * @param {Boolean} limitToOne  True if you can only have 1 name for this linkType, False if you can have multiple
 * @param {Boolean} hideQuickAdd  True if you want to hide quickAdd functionality
 * @param {String} personOverlayIdPrefix    Prefixed overlay ID to use for the person overlay.
 *                                          If none specified, fallback to using the `linkType` as the unique identifier.
 *                                          This prop should be supplied if multiple cards of the same type can be added
 *                                          to the report (e.g. Offense cards)
 * @param {String} organizationOverlayIdPrefix  Prefixed overlay ID to use for the organization overlay.
 *                                              If none specified, fallback to using the `linkType` as the unique identifier.
 *                                              This prop should be supplied if multiple cards of the same type can be added
 * @param {Boolean} societyOffenseRequiresSocietyVictim show a specific text for selecting victim if application settings is enabled
 * @param {Boolean} nibrsCrimeAgainstSociety boolean to enable only society profiles
 */

const NameSummaryViewWrapper = connect(
    createStructuredSelector({
        nameReportLinksWhere: nameReportLinksWhereSelector,
        formatFieldByName: formatFieldByNameSelector,
    }),
    (dispatch, props) => ({
        addNameReportLink: (nameReportLink) => {
            if (props.form && props.formPath) {
                // add the new link to the form, and re-run the hide rules
                dispatch(props.form.actionCreators.push(props.formPath, nameReportLink));
                dispatch(
                    props.form.actionCreators.validate({
                        ruleTypes: [ruleTypes.HIDE],
                    })
                );
            } else if (props.onAddSuccess) {
                props.onAddSuccess(nameReportLink);
            }
        },
        storeOrganizationProfiles: (organizationProfiles) => {
            dispatch(storeOrganizationProfiles(organizationProfiles));
        },
        openErrorModal: (paragraphs) => dispatch(openErrorModal({ paragraphs })),
        removeNameReportLinkFromForm: (formPath, index) =>
            dispatch(props.form.actionCreators.remove(formPath, index)),
        addSocietyProfileLink: (nameReportLink) => dispatch(addNameReportLink(nameReportLink)),
        deleteNameReportLink: (nameReportLink) =>
            dispatch(deleteNameReportLink(nameReportLink, props.deleteNameReportLink)),
    })
)(NameSummaryView);

const withLocationQuickAddContext = (Component) => {
    return function WithLocationQuickAddContext(props) {
        const { setIsLocationStale } = LocationQuickAddContext.useContainer();
        return <Component setIsLocationStale={setIsLocationStale} {...props} />;
    };
};

export default compose(withQuickAddCallback, withLocationQuickAddContext)(NameSummaryViewWrapper);

import { ApprovalStatusForCaseEnum } from '@mark43/rms-api';
import _, { get, mapValues } from 'lodash';
import { createSelector } from 'reselect';
import { casesSelector } from '../data';
import {
    buildViewModel,
    getViewModelProperties,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { isDateInFuture } from '../../../../dates/utils/dateHelpers';
import { formatCaseDefinitionByIdSelector } from '../../../case-definitions/state/data';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';
import { caseRoleLinkViewModelsForCaseIdSelector } from '../../../case-role-links/state/ui';
import {
    caseStatusViewModelByCaseIdSelector,
    statusIsClosedSelector,
    statusIsInactiveSelector,
} from '../../../case-statuses/state/ui';
import { caseApprovalStatusByCaseIdSelector } from '../../../case-approval-statuses/state/data';
import { canRead, canWrite } from '../../../entity-permissions/state/ui';
import globalAttributes from '../../../../legacy-constants/globalAttributes';
import { formatCaseName } from '../../utils/caseHelpers';

export const caseViewModelsSelector = createSelector(
    casesSelector,
    formatCaseDefinitionByIdSelector,
    caseRoleLinkViewModelsForCaseIdSelector,
    caseStatusViewModelByCaseIdSelector,
    caseApprovalStatusByCaseIdSelector,
    formatMiniUserByIdSelector,
    formatAttributeByIdSelector,
    statusIsClosedSelector,
    statusIsInactiveSelector,
    (
        cases,
        formatCaseDefinitionById,
        caseRoleLinkViewModelForCaseId,
        caseStatusViewModelByCaseId,
        caseApprovalStatusByCaseId,
        formatMiniUserById,
        formatAttributeById,
        statusIsClosed,
        statusIsInactive
    ) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ caseDefinitionId }) => ({
                    caseDefinitionId: formatCaseDefinitionById(caseDefinitionId),
                }),
                ({ createdBy }) => ({
                    createdBy: formatMiniUserById(createdBy, { firstNameAsInitial: true }),
                }),
                ({ title, localId }) => ({
                    caseName: formatCaseName(localId, title),
                }),
                ({ id, dueDateUtc }) => {
                    const caseApprovalStatus = get(caseApprovalStatusByCaseId(id), 'status');
                    const caseStatusAttrId = get(caseStatusViewModelByCaseId(id), 'statusAttrId');
                    // a case is done when it has a terminate status: inactive/closed, and is approved
                    const isDone =
                        (statusIsClosed(caseStatusAttrId) || statusIsInactive(caseStatusAttrId)) &&
                        caseApprovalStatus === ApprovalStatusForCaseEnum.APPROVED.name;
                    // a case is overdue when the due date is not in the future and is not done
                    return {
                        isOverdue: dueDateUtc && !isDateInFuture(dueDateUtc) && !isDone,
                    };
                },
                ({ id }) => {
                    const caseRoleLinks = caseRoleLinkViewModelForCaseId(id) || [];
                    // should have only 1 assignee
                    const assigneeCaseRoleLink = _(caseRoleLinks)
                        .filter(
                            ({ caseRoleAttrId }) =>
                                caseRoleAttrId === globalAttributes.caseRoleGlobal.assignee
                        )
                        .head();
                    return {
                        assigneeNameFormats: getViewModelProperties(assigneeCaseRoleLink).names,
                    };
                },
                ({ permissionSet }) => ({
                    canRead: canRead(permissionSet),
                    canWrite: canWrite(permissionSet),
                }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(cases, viewModel);
    }
);

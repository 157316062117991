import { find } from 'lodash';

export const savedSearchIsStale = (savedSearches, id) => {
    if (!id) {
        return false;
    }

    const search = find(savedSearches, (search) => search.id === id);
    return (search && search.isQueryStale) || false;
};

import React from 'react';
import styled from 'styled-components';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';

import responsibleOfficerForm from '../state/forms/responsibleOfficerForm';

const StyledRRFUserSelect = styled(RRFUserSelect)`
    min-width: 10rem;
`;

/**
 * Form for selecting a responsible officer.
 */
export default function ResponsibleOfficerForm() {
    return (
        <ReactReduxForm {...responsibleOfficerForm}>
            <StyledRRFUserSelect path="officerId" autoFocus={true} />
        </ReactReduxForm>
    );
}

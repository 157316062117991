import React, { useMemo } from 'react';
import { UiTypeEnum } from '@mark43/rms-api';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';
import {
    currentReportSelector,
    isCurrentReportNewSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import { dragonDataSelector, DragonFormData } from '../../../dragon/dragonRedux';
import { RMSDragonForm } from '../../../dragon/components/rms/rms-dragon-form';
import { ClientFormView } from '../../../dragon/rms-types';

type DragonFormProps = {
    formConfigurationId: number;
};

// TODO: At a later time we might need to modify this method to handle nested dragon forms
const filterDragonFormDataByFormConfigurationId = (
    reportDragonFormData: DragonFormData,
    formConfigurationId: number
): DragonFormData => {
    const formData = reportDragonFormData.form;
    const summary = reportDragonFormData.summary;
    const rootFormDataReference = formData.rootFormReference;
    const rootFormReferenceConfigurationId = rootFormDataReference.formConfigurationId;
    const relatedFormConfigurationIds = [rootFormReferenceConfigurationId, formConfigurationId];

    const filteredFormValues = formData.formValues.filter((formValue) =>
        relatedFormConfigurationIds.includes(formValue.formConfigurationId)
    );

    const filteredForms = Object.keys(formData.forms).reduce<ClientFormView['forms']>(
        (acc, formConfigurationId) => {
            let form = formData.forms[formConfigurationId];
            if (!relatedFormConfigurationIds.includes(Number(formConfigurationId)) || !form) {
                return acc;
            }

            if (form.id === rootFormReferenceConfigurationId) {
                const formReferenceConfigurations = filter(
                    form.formReferenceConfigurations,
                    (formReferenceConfiguration) => {
                        return (
                            relatedFormConfigurationIds.includes(
                                formReferenceConfiguration.formConfigurationId
                            ) &&
                            formReferenceConfiguration.uiType === UiTypeEnum.FORM_REFERENCE.name
                        );
                    }
                );

                form = {
                    ...form,
                    formReferenceConfigurations,
                };
            }

            return {
                ...acc,
                [formConfigurationId]: form,
            };
        },
        {}
    );

    const filteredFormData = {
        formValues: filteredFormValues,
        forms: filteredForms,
    };

    return {
        form: {
            ...formData,
            ...filteredFormData,
        },
        summary: {
            ...summary,
            ...filteredFormData,
        },
    };
};

export const DragonForm = ({ formConfigurationId }: DragonFormProps): JSX.Element | null => {
    const currentReport = useSelector(currentReportSelector);
    const isNewReport = useSelector(isCurrentReportNewSelector);
    const dragonData = useSelector(dragonDataSelector);
    const reportId = currentReport?.id;

    const formConfigurationDragonData = useMemo(() => {
        if (reportId) {
            const reportDragonData = dragonData[reportId];

            if (reportDragonData) {
                return filterDragonFormDataByFormConfigurationId(
                    reportDragonData,
                    formConfigurationId
                );
            }
        }
        return undefined;
    }, [formConfigurationId, reportId, dragonData]);

    if (!reportId || !formConfigurationDragonData || !currentReport) {
        return null;
    }

    return (
        <RMSDragonForm
            formData={formConfigurationDragonData.form}
            summaryData={formConfigurationDragonData.summary}
            isNewReport={isNewReport}
            reportId={reportId}
        />
    );
};

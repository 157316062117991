import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { filter } from 'lodash';
import { SavedSearchView } from '@mark43/rms-api';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { SearchModuleType } from '../../../../core/typings/search-module';

export const useOpenShareModal = (savedSearchView: SavedSearchView) => {
    const overlayStore = useOverlayStore();

    return useCallback(() => {
        if (!savedSearchView.id) {
            // don't fail silently
            throw new Error('Open shared search modal failed');
        }
        overlayStore.open(overlayIdEnum.SAVED_SEARCH_SHARE_MODAL, { savedSearchView });
    }, [overlayStore, savedSearchView]);
};

export const useOnShareSearch = (searchModule: SearchModuleType) => {
    const dispatch = useDispatch();

    return (savedSearchView: SavedSearchView) => {
        const { id: savedSearchId, name, entityPermissions, isShared, query } = savedSearchView;

        if (!savedSearchId) {
            // don't fail silently
            throw new Error('Failed to share saved search');
        }

        dispatch(
            searchModule.actionCreators.updateSavedSearch(query, {
                savedSearchId,
                name,
                isShared,
                entityPermissions: filter(
                    entityPermissions,
                    ({ roleId, operationType }) => !!roleId && !!operationType
                ),
            })
        ).then(() => dispatch(searchModule.actionCreators.loadSavedSearches()));
    };
};

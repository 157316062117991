import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Armada Resource',
    methods: {
        // get all schedules for current department
        getAllSchedules() {
            return req({
                method: 'GET',
                url: 'armada/schedules',
            });
        },
        createSchedule(schedule) {
            return req({
                method: 'POST',
                url: 'armada/schedules',
                data: schedule,
            });
        },
        updateSchedule(id, scheduleObj, queryParams) {
            return req({
                method: 'PUT',
                url: `armada/schedules/${id}`,
                data: scheduleObj,
                params: queryParams,
            });
        },
        deleteSchedule(id) {
            return req({
                method: 'DELETE',
                url: `armada/schedules/${id}`,
            });
        },
        getCommands(queryParams) {
            return req({
                method: 'GET',
                url: 'armada/commands',
                params: queryParams,
            });
        },
        getAllCommands() {
            return req({
                method: 'GET',
                url: '',
            });
        },
        getHistoryById(id, queryParams) {
            return req({
                method: 'GET',
                url: `armada/commands/${id}/history`,
                params: queryParams,
            });
        },
        retryCommand(id, command) {
            return req({
                method: 'POST',
                url: `armada/commands/${id}/retry`,
                data: command,
            });
        },
        updateCommand(id, command) {
            return req({
                method: 'PUT',
                url: `armada/commands/${id}`,
                data: command,
            });
        },
    },
});

import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { PersonGangTracking } from '@mark43/rms-api';
import { formatAttributeByIdSelector } from '../../attributes/state/data';
import { ConnectedFormattedUser, FORMATS } from '../../../users/components';
import { FormattedDate } from '../../../dates/components';
import { formatAttributeWithOther } from '../../attributes/utils/attributesHelpers';

const BodyItalics = styled.div`
    color: ${(props) => props.theme.colors.mediumGrey};
    font-style: italic;
    clear: both;
`;

const InlineBodyItalics = styled(BodyItalics)`
    display: inline;
`;

const formatPersonGangTracking = (
    personGangTracking: PersonGangTracking,
    formatAttributeById: ReturnType<typeof formatAttributeByIdSelector>
) => {
    const { gangNameAttrId, gangNameOther, gangSubgroupAttrId } = personGangTracking;
    return formatAttributeWithOther(
        formatAttributeById(gangSubgroupAttrId || gangNameAttrId, false, true),
        gangNameOther
    );
};

const GangTrackingDisplay: React.FC<{ personGangTracking: PersonGangTracking }> = ({
    personGangTracking,
}) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);

    return <span>{formatPersonGangTracking(personGangTracking, formatAttributeById)}</span>;
};

export const ExpandedPersonGangTrackingDisplay: React.FC<{
    personGangTracking: PersonGangTracking;
}> = ({ personGangTracking }) => {
    return (
        <div>
            <GangTrackingDisplay personGangTracking={personGangTracking} />{' '}
            <InlineBodyItalics>
                Expires{' '}
                <FormattedDate
                    date={personGangTracking.expirationDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE}
                />
            </InlineBodyItalics>
            <ConnectedFormattedUser
                userId={personGangTracking.createdBy}
                format={FORMATS.FULL_NAME_WITH_FIRST_INITIAL}
            >
                {(user) => (
                    <BodyItalics>
                        Added by {user} on{' '}
                        <FormattedDate
                            date={personGangTracking.createdDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE}
                        />
                    </BodyItalics>
                )}
            </ConnectedFormattedUser>
        </div>
    );
};

export default GangTrackingDisplay;

import { _Form } from 'markformythree';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import { RmsDispatch } from '../../../../../core/typings/redux';
import createCard from '../../utils/createCard';
import {
    convertFromFormModel,
    buildCrashLocationFormModel,
    CrashLocationFormConfiguration,
} from '../forms/trafficCrashLocationForm';
import { updateCrashLocation } from './trafficCrashLocation';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    anchor: 'traffic-crash-location-card',
    baseSelector: (state: RootState) => state.ui.report.trafficCrashLocationCard,
    name: reportCardEnum.CRASH_LOCATION_INFO.name,
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit: () => (dispatch: RmsDispatch) => {
            dispatch(buildCrashLocationFormModel());
            dispatch(baseCard.actionCreators.transitionToEditMode());
        },
        save:
            (form: _Form<CrashLocationFormConfiguration>) =>
            (dispatch: RmsDispatch, getState: () => RootState) => {
                const state = getState();
                // @ts-expect-error createCard not typed
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);

                if (isInSummaryMode) {
                    return dispatch(validateCard({ card: baseCard, formComponent: form }));
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const { model } = form.getState();

                                const reportId = currentReportIdSelector(state);
                                if (!reportId) {
                                    return;
                                }
                                const result = convertFromFormModel(model, reportId);
                                if (!result) {
                                    return;
                                }
                                return [dispatch(updateCrashLocation(result, reportId))];
                            },
                            onSavingSuccess: () => {
                                dispatch(buildCrashLocationFormModel());
                            },
                        })
                    );
                }
            },
    },
};

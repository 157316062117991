import {
    EntityTypeEnum,
    HistoryEventCategoryEnum,
    HistoryEventTypeEnum,
    HistoryEventTypeEnumType,
} from '@mark43/rms-api';
import { HistoryEventViewModel } from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import { FormatFieldByName } from '~/client-common/core/fields/state/config';
import * as fields from '~/client-common/core/enums/universal/fields';
import { HistoryEventCategoryValue } from '../../../core/histories/components/History';
import { VehicleHistoryOptionsType } from '../../../core/histories/config';

const isANPRItem = (item: HistoryEventViewModel) => {
    if (item.secondaryType !== EntityTypeEnum.REPORT.name) {
        return false;
    }

    return item.secondaryName?.includes(VehicleHistoryOptionsType.ANPR);
};

const isRegistrationFieldsChanged = (
    item: HistoryEventViewModel,
    formatFieldByName: FormatFieldByName
) => {
    if (item.historyEventType !== HistoryEventTypeEnum.FIELDS_CHANGED.name) {
        return false;
    }

    return item.changeSet.some((item) =>
        [
            formatFieldByName(fields.VEHICLE_TAG),
            formatFieldByName(fields.VEHICLE_VIN_NUMBER),
            formatFieldByName(fields.VEHICLE_REGISTRATION_TYPE),
            formatFieldByName(fields.VEHICLE_REGISTRATION_YEAR),
            formatFieldByName(fields.VEHICLE_REGISTRATION_STATE_ATTR_ID),
        ].includes(item.fieldName)
    );
};

const isReportActivityItem = (item: HistoryEventViewModel) => {
    const items: HistoryEventTypeEnumType[] = [
        HistoryEventTypeEnum.CHILD_PROFILE_CREATION.name,
        HistoryEventTypeEnum.CHILD_PROFILE_DELETE.name,
    ];
    return items.includes(item.historyEventType);
};

export const customVehicleHistoryFilterHandler = ({
    item,
    selectedValue,
    formatFieldByName,
}: {
    item: HistoryEventViewModel;
    selectedValue: HistoryEventCategoryValue;
    formatFieldByName: FormatFieldByName;
}) => {
    switch (selectedValue) {
        case VehicleHistoryOptionsType.ANPR: {
            return isANPRItem(item);
        }
        case VehicleHistoryOptionsType.REGISTRATION_CHANGE: {
            return isRegistrationFieldsChanged(item, formatFieldByName);
        }
        case VehicleHistoryOptionsType.REPORT_ACTIVITY: {
            return isReportActivityItem(item);
        }
        case VehicleHistoryOptionsType.OTHER_ACTIVITY: {
            if (item.historyEventCategory === HistoryEventCategoryEnum.CONTENT.name) {
                return (
                    !isANPRItem(item) &&
                    !isRegistrationFieldsChanged(item, formatFieldByName) &&
                    !isReportActivityItem(item)
                );
            }

            return true;
        }
        default:
            return true;
    }
};

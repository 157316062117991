import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import pluralize from 'pluralize';

import { cssVar } from 'arc';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_ONLY_ORGANIZATION_LABEL,
    DISPLAY_ONLY_OFFENSE,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { BodyMediumText } from './typography';

const strings = componentStrings.core.QuickAddInstruction;

export const QuickAddInstructionContainer = styled(BodyMediumText)`
    font-size: ${cssVar('arc.fontSizes.xs')};
    color: ${cssVar('arc.colors.text.tertiary')};
    padding-bottom: 10px;
    width: 100%;
`;

const QuickAddInstruction = ({
    formatFieldByName,
    renForRecents,
    type,
    suffix,
    societyOffenseRequiresSocietyVictim = false,
}) => {
    const renDisplayName = renForRecents
        ? formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
        : 'report';
    const formattedType =
        type === strings.organizations
            ? pluralize(formatFieldByName(DISPLAY_ONLY_ORGANIZATION_LABEL)).toLowerCase()
            : type;
    const displayString = societyOffenseRequiresSocietyVictim
        ? strings.societyVictimEnabled(formatFieldByName(DISPLAY_ONLY_OFFENSE).toLowerCase())
        : strings.instructions(formattedType, renDisplayName, suffix);
    return <QuickAddInstructionContainer>{displayString}</QuickAddInstructionContainer>;
};

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
});

export default connect(mapStateToProps)(QuickAddInstruction);

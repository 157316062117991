import _, { some, includes, flatMap, has, isArray, reject, size, get } from 'lodash';
import Promise from 'bluebird';

// helpers
import { valuesToArray } from '~/client-common/helpers/logicHelpers';

// constants
import validationStrings from '~/client-common/core/strings/validationStrings';

const DEFAULT_PANEL_ERROR_MESSAGE = validationStrings.panel.generic;

/**
 * Helpers to extract error messages from given `errors`.
 * @param {any} errors  Depending on where the validation is coming from (KO,
 *                      RRF, Arbiter, Server error), the `error` shape varies.
 * @param {string} cardGenericSaveError    Generic save error message for card.
 * @param {object} options sets options to determine whether or not to interpolate errors
 * @return {string|string[]}    Depending on where the validation is coming from,
 *                              we return a single String error message or an
 *                              array of string error messages.
 */
const buildPanelErrorMessagesFromErrors = (
    errors,
    cardGenericSaveError = DEFAULT_PANEL_ERROR_MESSAGE,
    options = { keepTemplateString: false }
) => {
    // handles single remote error
    if (errors && errors.message) {
        return errors.message;
        // handles KO validation
    } else if (some(errors, (error) => has(error, 'panelErrorMessages'))) {
        const koPanelErrorMessages = flatMap(errors, 'panelErrorMessages');
        return koPanelErrorMessages;
        // handles legacy RRF validation
    } else if (isArray(errors)) {
        return errors;
        // handles Arbiter validation with panel errors
    } else if (size(errors.panelErrors) > 0) {
        return errors.panelErrors;
    } else if (
        options.keepTemplateString &&
        (size(errors.rawErrors) > 0 || size(get(errors, 'validationResult.rawErrors')) > 0)
    ) {
        // handles markformythree arbiter template strings
        const rawErrors = get(errors, 'validationResult.rawErrors') || errors.rawErrors;
        return rawErrors;
    } else if (
        size(errors.formErrors) > 0 ||
        size(get(errors, 'validationResult.formErrors')) > 0
    ) {
        // handles markformythree ValidationResult
        const formErrors = get(errors, 'validationResult.formErrors') || errors.formErrors;
        return formErrors;
        // handles Arbiter validation with no panel errors
    } else if (errors.success === false) {
        return DEFAULT_PANEL_ERROR_MESSAGE;
    }

    // catch-all; if errors exists and we were not able to extract _any_
    // error messages, always plop on the card's generic save failure message.
    if (errors) {
        return cardGenericSaveError;
    }

    return undefined;
};

/**
 * Takes in a string or [string] and returns a distinct list of strings, filtering
 * out null/undefined values.  Has side-effect of removing the default panel
 * error message ("One or more issues need to be fixed") if there are other
 * panel error messages present.
 * @param {string|string[]} errorMessages
 * @return {string[]}
 */
export const buildDistinctErrorMessages = (errorMessages) => {
    const asArray = valuesToArray(errorMessages);
    const uniqueErrorMessages = _(asArray).compact().uniq().value();
    const containsDefaultPanelErrorMessage = includes(
        uniqueErrorMessages,
        DEFAULT_PANEL_ERROR_MESSAGE
    );
    if (size(uniqueErrorMessages) > 1 && containsDefaultPanelErrorMessage) {
        return reject(
            uniqueErrorMessages,
            (uniqueErrorMessage) => uniqueErrorMessage === DEFAULT_PANEL_ERROR_MESSAGE
        );
    }
    return uniqueErrorMessages;
};

/**
 * Returns an array of panel error messages.
 * @param {any}     errors  Depending on where the validation is coming from (KO,
 *                          RRF, Arbiter, Server error), the `error` shape varies.
 * @param {string}  cardGenericSaveError    Generic save error message for card.
 * @param {object} options sets options to determine whether or not to interpolate errors
 * @return {string[]}   Array of unique, non-null, panel error messages.
 */
export const getErrorMessagesFromErrors = (
    errors,
    cardGenericSaveError = DEFAULT_PANEL_ERROR_MESSAGE,
    options = { keepTemplateString: false }
) => {
    const errorMessages = buildPanelErrorMessagesFromErrors(errors, cardGenericSaveError, options);
    return buildDistinctErrorMessages(errorMessages);
};

/**
 * Uses a response from an RRF `validate()` to return a single validation result
 * object.
 * @param {any} rrfValidationResult Result of the RRF `validate()`.  Object has
 *                                  different shapes between legacy RRF validation
 *                                  and Arbiter RRF validation.
 * @param {string}  cardGenericSaveError    Generic save error message for card.
 * @return {object} validation result object with shape:
 *                  `{ isValid: boolean, panelErrorMessages: [string] }`
 */
export const validateRRFOnForm = (
    rrfValidationResult,
    cardGenericSaveError = DEFAULT_PANEL_ERROR_MESSAGE,
    options
) => {
    const reduxIsValid =
        // legacy validation passed
        rrfValidationResult === true ||
        // Arbiter validation passed
        !!(rrfValidationResult && rrfValidationResult.success);
    const reduxPanelErrorMessages = reduxIsValid
        ? []
        : getErrorMessagesFromErrors(rrfValidationResult, cardGenericSaveError, options);

    return {
        isValid: reduxIsValid,
        panelErrorMessages: reduxPanelErrorMessages,
    };
};

/**
 * Builds a Promise out of a validation result object.
 * @param {object} Validation result object, with shape:
 *                 `{ isValid: boolean, panelErrorMessages: [string] }`
 * @return Promise Will resolve if validation result `isValid`, otherwise, reject
 *                 with a string[] of `panelErrorMessages`.
 */
export const buildValidationPromise = (validationResultObject) => {
    return new Promise((resolve, reject) => {
        if (validationResultObject.isValid) {
            resolve();
        } else {
            reject(validationResultObject.panelErrorMessages);
        }
    });
};

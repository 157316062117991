import { ExportReleaseEntity } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'exportReleaseEntities';
const exportReleaseEntitiesModule = createGroupedModule<ExportReleaseEntity>({
    type: NEXUS_STATE_PROP,
    key: 'exportReleaseId',
});

// SELECTORS
export const exportReleaseEntitiesSelector = exportReleaseEntitiesModule.selectors.entitiesSelector;

// REDUCER
export default exportReleaseEntitiesModule.reducerConfig;

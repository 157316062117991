import React, { ReactNode } from 'react';
import { Flex } from 'arc';
import { Status } from '@arc/badge';
import { BriefingSimpleView } from '@mark43/rms-api';
import { BRIEFING_STATE_STATUS_MAP } from '../../../configuration';
import { BriefingCellWrapper } from './BriefingCellWrapper';

type Props = Pick<BriefingSimpleView, 'state'> & {
    children?: ReactNode;
};
export const BriefingStatusCell = ({ state, children }: Props) => {
    return (
        <BriefingCellWrapper>
            <Flex justifyContent="space-between" alignItems="center">
                <Status status={BRIEFING_STATE_STATUS_MAP[state]}>{state}</Status>
                {children}
            </Flex>
        </BriefingCellWrapper>
    );
};

import { filter } from 'lodash';
import { CaseDefaultRoleLink } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'caseDefaultRoleLinks';

const caseDefaultRoleLinksModule = createLinkModule<CaseDefaultRoleLink>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// ACTIONS
export const storeCaseDefaultRoleLinks = caseDefaultRoleLinksModule.actionCreators.storeLinks;
export const replaceCaseDefaultRoleLinksWhere =
    caseDefaultRoleLinksModule.actionCreators.replaceLinksWhere;

// SELECTORS
const caseDefaultRoleLinksSelector = caseDefaultRoleLinksModule.selectors.linksSelector;
export const caseDefaultRoleLinksByCaseDefinitionIdSelector = createSelector(
    caseDefaultRoleLinksSelector,
    (caseDefaultRoleLinks) => (caseDefinitionId: number) =>
        filter(caseDefaultRoleLinks, { caseDefinitionId })
);

// REDUCER
export default caseDefaultRoleLinksModule.reducerConfig;

import React from 'react';
import { map, slice } from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { cssVar } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { Tooltip } from '../../../core/components/tooltip';
import testIds from '../../../../core/testIds';

const strings = componentStrings.reports.ReportSidebar;

const Label = styled.div`
    display: inline-block;
    padding: 4px 8px;
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 4px;
    background-color: ${cssVar('arc.colors.brand.accent')};
    color: ${cssVar('arc.colors.brand.accentContent')};
`;

const BlockLabel = styled(Label)`
    clear: both;
`;

const LabelContainer = styled.div`
    font-size: var(--arc-fontSizes-md);
`;

const NoLabelContainer = styled(LabelContainer)`
    text-align: center;
`;

const NoLabelsMessage = styled.div`
    border-radius: 6px;
    color: ${(props) => props.theme.colors.mediumGrey};
    border: 1px solid ${(props) => props.theme.colors.mediumGrey};
    padding: 8px 20px 8px 20px;
    text-align: center;
    display: inline-block;
`;

const MoreLabels = styled.span`
    padding: 5px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-sm);
    cursor: default;
`;

function RoutingLabels(props) {
    const { labels, numberToDisplay = 3, formatAttributeById, className, hideEmptyMessage } = props;
    const convertedLabels = map(labels, (label) => {
        return {
            ...label,
            id: label.attributeId || label.id,
        };
    });
    if (convertedLabels.length === 0) {
        return hideEmptyMessage ? (
            <div />
        ) : (
            <NoLabelContainer className={className}>
                <NoLabelsMessage data-test-id={testIds.ROUTING_LABELS_NO_LABELS}>
                    {strings.noLabels}
                </NoLabelsMessage>
            </NoLabelContainer>
        );
    }
    const additionalLabels =
        convertedLabels.length > numberToDisplay ? slice(convertedLabels, numberToDisplay) : null;
    return (
        <LabelContainer className={className}>
            {slice(convertedLabels, 0, numberToDisplay).map((label) => {
                return <Label key={label.id}>{formatAttributeById(label.id)}</Label>;
            })}
            {additionalLabels && (
                <Tooltip
                    side="bottom"
                    align="start"
                    content={map(additionalLabels, (label) => (
                        <div key={label.id}>
                            <BlockLabel data-test-id={testIds.ROUTING_LABELS_ADDITIONAL_LABEL}>
                                {formatAttributeById(label.id)}
                            </BlockLabel>
                        </div>
                    ))}
                >
                    <MoreLabels data-test-id={testIds.ROUTING_LABELS_VIEW_MORE_LABELS}>
                        +{additionalLabels.length}
                    </MoreLabels>
                </Tooltip>
            )}
        </LabelContainer>
    );
}

const mapStateToProps = (state) => {
    const formatAttributeById = formatAttributeByIdSelector(state);
    return {
        formatAttributeById,
    };
};

export default connect(mapStateToProps)(RoutingLabels);

import React, { useState } from 'react';
import { _Form, Observer } from 'markformythree';
import { Flex, FormRow, Spacer, VStack } from 'arc';
import { AttributeTypeEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { dateTimeFormats, formatISODate } from '~/client-common/core/dates/utils/dateHelpers';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTCheckbox } from '../../../../core/forms/components/checkboxes/Checkbox';
import Text, { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { FormConfigurationRenderer } from '../../../../core/markformythree-arbiter/dynamic-fields/components/FormConfigurationRenderer';
import { CUSTOM_PROPERTIES_KEY_NAME } from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';
import { CrashEventInfoFormConfiguration } from '../../state/forms/crashEventInfoForm';

const strings = componentStrings.reports.core.CrashEventInfoCard;

const CrashEventInfoCardForm = ({ form }: { form: _Form<CrashEventInfoFormConfiguration> }) => {
    const [dayOfWeek, setDayOfWeek] = useState('');

    return (
        <CardSection>
            <VStack alignItems="flex-start" paddingRight={4}>
                <Flex width="100%">
                    <ArbiterMFTDatePicker includeTime path="crashEventDateUtc" />
                    <Spacer />
                    <Observer<{ crashEventDateUtc: string }, CrashEventInfoFormConfiguration>
                        subscriptions={{ crashEventDateUtc: 'crashEventDateUtc' }}
                        render={({ crashEventDateUtc }) => {
                            if (
                                typeof crashEventDateUtc !== 'string' ||
                                isNaN(new Date(crashEventDateUtc).getTime())
                            ) {
                                setDayOfWeek('');
                            } else {
                                setDayOfWeek(
                                    formatISODate(crashEventDateUtc, dateTimeFormats.dayOfWeek)
                                );
                            }
                            return (
                                <Text
                                    disabled
                                    value={dayOfWeek}
                                    label={strings.dayOfWeek}
                                    length="md"
                                />
                            );
                        }}
                    />
                </Flex>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.WEATHER.name}
                    path="weatherAttrIds"
                    length="md"
                />
                <FormRow hasIndent>
                    <ArbiterMFTText path="weatherOtherDescription" />
                </FormRow>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.QC_ROADWAY_SURFACE_CONDITION.name}
                    path="qcRoadwaySurfaceConditionAttrIds"
                    length="md"
                />
                <FormRow hasIndent>
                    <ArbiterMFTText path="qcRoadwaySurfaceConditionOtherDescription" />
                </FormRow>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.QC_LIGHT_CONDITION.name}
                    path="qcLightConditionAttrIds"
                    length="md"
                />
                <FormRow hasIndent>
                    <ArbiterMFTText path="qcLightConditionOtherDescription" />
                </FormRow>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.QC_CRASH_SEVERITY.name}
                    path="qcCrashSeverityAttrIds"
                />
                <FormRow hasIndent>
                    <ArbiterMFTText path="qcCrashSeverityOtherDescription" />
                </FormRow>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.CRASH_FIXED_OBJECT_TYPE.name}
                    path="crashFixedObjectTypeAttrIds"
                />
                <FormRow hasIndent>
                    <ArbiterMFTText path="crashFixedObjectTypeOtherDescription" />
                </FormRow>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.QC_FIRST_HARMFUL_EVENT.name}
                    path="qcFirstHarmfulEventAttrIds"
                />
                <FormRow hasIndent>
                    <ArbiterMFTText path="qcFirstHarmfulEventOtherDescription" />
                </FormRow>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.CRASH_CONTRIBUTING_FACTOR.name}
                    path="crashContributingFactorAttrIds"
                />
                <FormRow hasIndent>
                    <ArbiterMFTText path="crashContributingFactorOtherDescription" />
                </FormRow>
                <ArbiterMFTAttributeSelect
                    attributeType={AttributeTypeEnum.CRASH_MUNICIPALITY_CODE.name}
                    path="crashMunicipalityCodeAttrIds"
                />
                <FormRow hasIndent>
                    <ArbiterMFTText path="crashMunicipalityCodeOtherDescription" />
                </FormRow>
                <ArbiterMFTCheckbox path="isTemporaryTrafficControl" />
                <FormConfigurationRenderer
                    form={form}
                    absoluteFormPath={CUSTOM_PROPERTIES_KEY_NAME}
                    mode="FORM"
                />
            </VStack>
        </CardSection>
    );
};

export default CrashEventInfoCardForm;

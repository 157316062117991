import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const lookerDashboardForm = createFormModule({
    formName: formClientEnum.LOOKER_DASHBOARD,
    fieldViewModels: buildFlatFormFieldViewModels(['lookerDashboardId']),
});

export default lookerDashboardForm;

import getWarrantsResource from '~/client-common/core/domain/warrants/resources/warrantsResource';
import storeChargeWarrantView from './storeChargeWarrantView';
import {
    convertChargeWarrantFromFormModel,
    convertChargeWarrantToFormModel,
} from './convertChargeWarrants';

export const createStubWarrant = ({ form }) => (dispatch) => {
    return form
        .submit()
        .then(() => {
            const warrant = form.get();
            const { reportingEventNumber } = warrant;
            const stubWarrant = convertChargeWarrantFromFormModel({ warrant });
            return getWarrantsResource().createStubWarrant(stubWarrant, reportingEventNumber);
        })
        .then((chargeWarrantView) => {
            const warrant = convertChargeWarrantToFormModel(chargeWarrantView);
            const updatedView = {
                ...chargeWarrantView,
                warrant,
            };
            dispatch(
                // eslint-disable-next-line no-restricted-syntax
                storeChargeWarrantView({
                    chargeWarrantView: updatedView,
                    action: { type: 'ARREST_CHARGES_SIDE_PANEL_CREATE_STUB_WARRANT' },
                })
            );
            return warrant;
        });
};

export const editStubWarrant = ({ form }) => (dispatch) => {
    return form
        .submit()
        .then(() => {
            const warrant = form.get();
            const stubWarrant = convertChargeWarrantFromFormModel({ warrant });
            return getWarrantsResource().updateStubWarrant(stubWarrant);
        })
        .then((chargeWarrantView) => {
            const warrant = convertChargeWarrantToFormModel(chargeWarrantView);
            const updatedView = {
                ...chargeWarrantView,
                warrant,
            };
            dispatch(
                // eslint-disable-next-line no-restricted-syntax
                storeChargeWarrantView({
                    chargeWarrantView: updatedView,
                    action: { type: 'ARREST_CHARGES_SIDE_PANEL_UPDATE_STUB_WARRANT' },
                })
            );
            return warrant;
        });
};

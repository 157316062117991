import { LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import styled, { withTheme } from 'styled-components';
import { chain } from 'lodash';

import { connect } from 'react-redux';
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';

import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

import { useSubPremiseFormatters } from '../../../../core/locations/hooks/useSubPremiseFormatters';
import QuickSearchResultSectionItemTitle from '../QuickSearchResultSectionItemTitle';
import Highlighter from '../QuickSearchHighlighter';
import LocationWrapper from '../layout/LocationWrapper';
import FieldDisplay from '../layout/FieldDisplay';
import ExternalDepartmentName from '../ExternalDepartmentName';
import {
    OrganizationProfileImage,
    profileImageSizes,
} from '../../../../core/components/ProfileImage';

const QuickSearchOrganizationProfileImage = styled(OrganizationProfileImage)`
    background-color: ${(props) => props.theme.colors.lightGrey};
`;

const Wrapper = styled.div`
    display: flex;
`;

const OrganizationItemInfoContainer = styled.div`
    vertical-align: top;
    flex: 1;
    margin-left: 10px;
    padding: 4px 0;
    display: inline-block;
`;

const { BUSINESS_ADDRESS, BUSINESS_LOCATION } = LinkTypesEnum;

const QuickSearchResultSectionOrganizationItem = ({
    query,
    item,
    formatAttributeById,
    formatAttributeWithOther,
    theme,
    externalDepartmentName,
    testId,
    testType,
}) => {
    const location = chain(item.involvedLocations)
        .filter(({ type }) => type === BUSINESS_ADDRESS || type === BUSINESS_LOCATION)
        .sortBy('type')
        .first()
        .value();
    const {
        primaryProfilePhotoPath,
        organizationTypeAttrId,
        industryAttrId,
        industryOther,
        phoneNumbers,
    } = item;
    const organizationType = formatAttributeById(organizationTypeAttrId);
    const industry = formatAttributeWithOther({
        attributeId: industryAttrId,
        other: industryOther,
    });
    const searchWords = [query];
    const { buildElasticLocationLines } = useSubPremiseFormatters();

    return (
        <Wrapper data-test-id={testId} data-test-type={testType}>
            <QuickSearchOrganizationProfileImage
                noBorder={true}
                size={profileImageSizes.QUICK_SEARCH}
                url={primaryProfilePhotoPath}
            />
            <OrganizationItemInfoContainer>
                <QuickSearchResultSectionItemTitle>
                    <Highlighter searchWords={searchWords} textToHighlight={item.name} />{' '}
                    <ExternalDepartmentName theme={theme} departmentName={externalDepartmentName} />
                </QuickSearchResultSectionItemTitle>
                <FieldDisplay theme={theme}>
                    {joinTruthyValues([organizationType, industry], ' / ')}
                </FieldDisplay>
                <FieldDisplay theme={theme}>{phoneNumbers[0]}</FieldDisplay>
                {location && (
                    <LocationWrapper>
                        <Highlighter
                            searchWords={searchWords}
                            textToHighlight={buildElasticLocationLines(location).join(', ')}
                        />
                    </LocationWrapper>
                )}
            </OrganizationItemInfoContainer>
        </Wrapper>
    );
};

export default connect((state) => ({
    formatAttributeById: formatAttributeByIdSelector(state),
    formatAttributeWithOther: formatAttributeWithOtherSelector(state),
}))(withTheme(QuickSearchResultSectionOrganizationItem));

import { createSelector } from 'reselect';
import _, { filter, map, first } from 'lodash';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import {
    codeForCodeTypeSourceAttributeIdAndCodeTypeSelector,
    regionalSourceSelector,
    nibrsCodeForAttributeIdCodeTypeCategoryAndDepartmentSelector,
} from '~/client-common/core/domain/attribute-codes/state/ui';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { firearmViewModelByIdSelector } from '~/client-common/core/domain/firearms/state/ui';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { itemProfileViewModelByIdSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import { offenseViewModelsForReportIdSelector } from '~/client-common/core/domain/offenses/state/ui';
import { propertyStatusesByItemProfileIdSelector } from '~/client-common/core/domain/property-statuses/state/data';
import { propertyStatusViewModelByIdSelector } from '~/client-common/core/domain/property-statuses/state/ui';
import { deduplicatePropertyStatuses } from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import {
    vehicleViewModelByIdSelector,
    formatTitleForVehicleSelector,
} from '~/client-common/core/domain/vehicles/state/ui';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { ucrSummaryOffenseCodeByCodeSelector } from '~/client-common/core/domain/ucr-summary-offense-codes/state/data';

import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import { formatTitleForPropertyRecord } from '../../../../../legacy-redux/helpers/propertyRecordHelpers';
import {
    currentReportIdSelector,
    offenseReportIdForCurrentReportRENSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';

export const offenseUcrForReportIdSelector = createSelector(
    arrestForReportIdSelector,
    chargesWhereSelector,
    offenseCodesSelector,
    ucrSummaryOffenseCodeByCodeSelector,
    (arrestForReportId, chargesWhere, offenseCodes, ucrSummaryOffenseCodeByCode) => (reportId) => {
        const arrest = arrestForReportId(reportId);
        const charges = chargesWhere((charge) => charge.arrestId === arrest.id);
        if (charges.length < 1) {
            return '';
        }
        const topOffense = filter(charges, (charge) => charge.offenseOrder === 1);
        const offenseCode = first(topOffense);
        const ucrOffense = offenseCodes[offenseCode.chargeOffenseCodeId];
        return ucrSummaryOffenseCodeByCode(ucrOffense.ucrCode);
    }
);

export const currentUcrSourceSelector = createSelector(
    regionalSourceSelector,
    currentUserDepartmentIdSelector,
    (regionalSource, currentUserDepartmentId) =>
        regionalSource('ucrSource', currentUserDepartmentId)
);

export const nibrsCodeForAttributeIdAndCodeTypeCategorySelector = createSelector(
    nibrsCodeForAttributeIdCodeTypeCategoryAndDepartmentSelector,
    currentUserDepartmentIdSelector,
    (nibrsCodeForAttributeIdCodeTypeCategoryAndDepartment, currentUserDepartmentId) => (
        attributeId,
        codeTypeCategory
    ) =>
        nibrsCodeForAttributeIdCodeTypeCategoryAndDepartment(
            attributeId,
            codeTypeCategory,
            currentUserDepartmentId
        )
);

export const ucrCodeForAttributeIdAndCodeTypeCategorySelector = createSelector(
    currentUcrSourceSelector,
    codeForCodeTypeSourceAttributeIdAndCodeTypeSelector,
    (currentSource, codeForCodeTypeSourceAttributeIdAndCodeType) => (
        attributeId,
        codeTypeCategory
    ) =>
        codeForCodeTypeSourceAttributeIdAndCodeType({
            attributeId,
            codeTypeCategory,
            codeTypeSource: currentSource,
        })
);

export const currentReportOffenseViewModelsSelector = createSelector(
    offenseViewModelsForReportIdSelector,
    offenseReportIdForCurrentReportRENSelector,
    (offenseViewModelsForReportId, offenseReportIdForCurrentReportREN) => {
        const offenseViewModels = offenseViewModelsForReportId(offenseReportIdForCurrentReportREN);
        return filter(offenseViewModels, (offense) => {
            const { isIncident } = getViewModelProperties(offense);
            return !isIncident;
        });
    }
);

export const currentReportPropertyStatusOptionsSelector = createSelector(
    currentReportIdSelector,
    firearmViewModelByIdSelector,
    itemProfileViewModelByIdSelector,
    itemProfilesInReportSelector,
    propertyStatusesByItemProfileIdSelector,
    propertyStatusViewModelByIdSelector,
    vehicleViewModelByIdSelector,
    formatTitleForVehicleSelector,
    (
        currentReportId,
        firearmViewModelById,
        itemProfileViewModelById,
        itemProfilesInReport,
        propertyStatusesByItemProfileId,
        propertyStatusViewModelById,
        vehicleViewModelById,
        formatTitleForVehicle
    ) => {
        const { item, vehicle, firearm, drugs, alcohol, phone, bus } = globalAttributes.itemType;

        const itemTypeToViewModelSelector = {};
        itemTypeToViewModelSelector[item] = itemProfileViewModelById;
        itemTypeToViewModelSelector[vehicle] = vehicleViewModelById;
        itemTypeToViewModelSelector[firearm] = firearmViewModelById;
        itemTypeToViewModelSelector[drugs] = itemProfileViewModelById;
        itemTypeToViewModelSelector[alcohol] = itemProfileViewModelById;
        itemTypeToViewModelSelector[phone] = itemProfileViewModelById;
        itemTypeToViewModelSelector[bus] = vehicleViewModelById;

        const currentReportItemProfiles = itemProfilesInReport(currentReportId);
        return _(currentReportItemProfiles)
            .map((itemProfile) => {
                const isVehicle = itemProfile.itemTypeAttrId === vehicle;
                const viewModel = itemTypeToViewModelSelector[itemProfile.itemTypeAttrId](
                    itemProfile.id
                );
                const title = isVehicle
                    ? formatTitleForVehicle(viewModel)
                    : formatTitleForPropertyRecord(viewModel, false);

                return map(
                    deduplicatePropertyStatuses(propertyStatusesByItemProfileId(itemProfile.id)),
                    ({ id }) => {
                        const { propertyStatusAttrId } = getViewModelProperties(
                            propertyStatusViewModelById(id)
                        );
                        return {
                            value: id,
                            display: `${title} (${propertyStatusAttrId})`,
                        };
                    }
                );
            })
            .flatten()
            .value();
    }
);

import { isEmpty } from 'lodash';
import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedDate } from '~/client-common/core/dates/components';
import { trafficCrashViewModelByReportIdSelector } from '~/client-common/core/domain/traffic-crashes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import testIds from '../../../../core/testIds';
import { CardSection } from '../../../../legacy-redux/components/core/Card';
import { FileGrid } from '../../../../legacy-redux/components/core/Files';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import { TrafficCrashEntityDetailSummarySection } from './TrafficCrashEntityDetailSummarySection';

const strings = componentStrings.trafficCrashSummary;

const TrafficCrashSummary = ({ reportId }: { reportId: number }) => {
    const trafficCrashViewModelByReportId = useSelector(trafficCrashViewModelByReportIdSelector);
    const viewModel = trafficCrashViewModelByReportId(reportId);

    if (!viewModel) {
        return null;
    }

    const viewModelProperties = getViewModelProperties(viewModel);
    const attachmentViewModels = viewModelProperties.attachmentViewModels;
    return (
        <SummaryList labelWidth={200} contentWidth={340}>
            <CardSection testId={testIds.TRAFFIC_CRASH_SUMMARY}>
                <FormattedDate
                    date={viewModel.crashDateAndTimeUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                >
                    {(formattedDate) => (
                        <SummaryRow label={strings.crashDateAndTimeUtc}>{formattedDate}</SummaryRow>
                    )}
                </FormattedDate>
                <SummaryRow label={strings.reportingLawEnforcementAgencyIdentifier}>
                    {viewModel.reportingLawEnforcementAgencyIdentifier}
                </SummaryRow>
                <SummaryRow label={strings.reportingDistrict}>
                    {viewModel.reportingDistrict}
                </SummaryRow>
                <SummaryRow label={strings.judicialDistrict}>
                    {viewModel.judicialDistrict}
                </SummaryRow>
                <FormattedDate
                    date={viewModel.roadwayClearanceDateAndTimeUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                >
                    {(formattedDate) => (
                        <SummaryRow label={strings.roadwayClearanceDateAndTimeUtc}>
                            {formattedDate}
                        </SummaryRow>
                    )}
                </FormattedDate>
                <SummaryRow
                    label={strings.crashRoadwayName}
                    testId={testIds.TRAFFIC_CRASH_ROADWAY_SUMMARY}
                >
                    {viewModelProperties.crashRoadwayName}
                </SummaryRow>
                <SummaryRow label={strings.intersectingRoadwayName}>
                    {viewModelProperties.intersectingRoadwayName}
                </SummaryRow>
                <SummaryRow
                    label={strings.crashClassificationCharacteristicsAttrId}
                    fieldName={'crashClassificationCharacteristicsAttrId'}
                >
                    {viewModelProperties.crashClassificationCharacteristicsAttrId}
                </SummaryRow>
                <SummaryRow label={strings.crashClassificationOwnershipAttrId}>
                    {viewModelProperties.crashClassificationOwnershipAttrId}
                </SummaryRow>
                <SummaryRow label={strings.numFatalities}>{viewModel.numFatalities}</SummaryRow>
                <SummaryRow label={strings.numMotorists}>{viewModel.numMotorists}</SummaryRow>
                <SummaryRow label={strings.numNonFatallyInjuredPersons}>
                    {viewModel.numNonFatallyInjuredPersons}
                </SummaryRow>
                <SummaryRow label={strings.numNonMotorists}>{viewModel.numNonMotorists}</SummaryRow>
                <SummaryRow label={strings.numVehicles}>{viewModel.numVehicles}</SummaryRow>
                {!isEmpty(attachmentViewModels) && (
                    <>
                        <SummaryRow label={strings.attachments}>
                            {<FileGrid fileLinks={attachmentViewModels} numOfColumns={1} />}
                        </SummaryRow>
                    </>
                )}
            </CardSection>
            <CardSection title={strings.events} testId={testIds.TRAFFIC_CRASH_EVENTS}>
                <SummaryRow label={strings.mannerOfCrashAttrId}>
                    {viewModelProperties.mannerOfCrashAttrId}
                </SummaryRow>
                <SummaryRow label={strings.firstHarmfulEventAttrId}>
                    {viewModelProperties.firstHarmfulEventAttrId}
                </SummaryRow>
                <SummaryRow label={strings.locationOfFirstHarmfulEventAttrId}>
                    {viewModelProperties.locationOfFirstHarmfulEventAttrId}
                </SummaryRow>
                <SummaryRow label={strings.contributingCircumstances}>
                    {viewModelProperties.contributingCircumstances}
                </SummaryRow>
            </CardSection>
            <CardSection title={strings.conditions} testId={testIds.TRAFFIC_CRASH_CONDITIONS}>
                <SummaryRow label={strings.weather}>{viewModelProperties.weather}</SummaryRow>
                <SummaryRow label={strings.lightConditionAttrId}>
                    {viewModelProperties.lightConditionAttrId}
                </SummaryRow>
                <SummaryRow label={strings.roadwaySurfaceConditionAttrId}>
                    {viewModelProperties.roadwaySurfaceConditionAttrId}
                </SummaryRow>
                <SummaryRow label={strings.postedSpeedLimit}>
                    {viewModel.postedSpeedLimit}
                </SummaryRow>
                <SummaryRow label={strings.trafficControlDeviceType}>
                    {viewModelProperties.trafficControlDeviceType}
                </SummaryRow>
                <SummaryRow label={strings.intersectionOverallTrafficControlDeviceAttrId}>
                    {viewModelProperties.intersectionOverallTrafficControlDeviceAttrId}
                </SummaryRow>
                <SummaryRow label={strings.trafficControlInoperativeMissing}>
                    {viewModelProperties.trafficControlInoperativeMissing}
                </SummaryRow>
            </CardSection>
            <CardSection title={strings.roadway} testId={testIds.TRAFFIC_CRASH_ROADWAY}>
                <SummaryRow label={strings.landmarkLocation}>
                    {viewModelProperties.landmarkLocation}
                </SummaryRow>
                <SummaryRow label={strings.landmarkIntersectingRoadwayRouteNumber}>
                    {viewModel.landmarkIntersectingRoadwayRouteNumber}
                </SummaryRow>
                <SummaryRow label={strings.landmarkIntersectingRoadway}>
                    {viewModelProperties.landmarkIntersectingRoadway}
                </SummaryRow>
                <SummaryRow label={strings.milepostLocation}>
                    {viewModelProperties.milepostLocation}
                </SummaryRow>
                <SummaryRow label={strings.milepostRouteNumber}>
                    {viewModel.milepostRouteNumber}
                </SummaryRow>
                <SummaryRow label={strings.milepostRoadwayDirectionAttrId}>
                    {viewModelProperties.milepostRoadwayDirectionAttrId}
                </SummaryRow>
                <SummaryRow label={strings.routeNumber}>{viewModel.routeNumber}</SummaryRow>
                <SummaryRow label={strings.roadwayName}>{viewModel.roadwayName}</SummaryRow>
                <SummaryRow label={strings.roadwayDirection}>
                    {viewModelProperties.roadwayDirection}
                </SummaryRow>
                <SummaryRow label={strings.intersectionDetailsRoadwayRouteNumber}>
                    {viewModel.intersectionDetailsRoadwayRouteNumber}
                </SummaryRow>
                <SummaryRow label={strings.intersectingRoadway1}>
                    {viewModelProperties.intersectingRoadway1}
                </SummaryRow>
                <SummaryRow label={strings.additionalIntersectionRoadwayRouteNumber}>
                    {viewModel.additionalIntersectionRoadwayRouteNumber}
                </SummaryRow>
                <SummaryRow label={strings.intersectingRoadway2}>
                    {viewModelProperties.intersectingRoadway2}
                </SummaryRow>
                <SummaryRow label={strings.junctionWithinInterchangeAreaAttrId}>
                    {viewModelProperties.junctionWithinInterchangeAreaAttrId}
                </SummaryRow>
                <SummaryRow label={strings.junctionSpecificLocationAttrId}>
                    {viewModelProperties.junctionSpecificLocationAttrId}
                </SummaryRow>
                <SummaryRow label={strings.crashClassificationSecondaryCrashAttrId}>
                    {viewModelProperties.crashClassificationSecondaryCrashAttrId}
                </SummaryRow>
                <SummaryRow label={strings.workZoneRelatedAttrId}>
                    {viewModelProperties.workZoneRelatedAttrId}
                </SummaryRow>
                <SummaryRow label={strings.workZoneTypeAttrId}>
                    {viewModelProperties.workZoneTypeAttrId}
                </SummaryRow>
                <SummaryRow label={strings.workZoneWorkersPresentAttrId}>
                    {viewModelProperties.workZoneWorkersPresentAttrId}
                </SummaryRow>
                <SummaryRow label={strings.workZoneLawEnforcementPresentAttrId}>
                    {viewModelProperties.workZoneLawEnforcementPresentAttrId}
                </SummaryRow>
                <SummaryRow label={strings.workZoneLocationOfCrashAttrId}>
                    {viewModelProperties.workZoneLocationOfCrashAttrId}
                </SummaryRow>
            </CardSection>
            <CardSection title={strings.exitRamp} testId={testIds.TRAFFIC_CRASH_EXIT_RAMP}>
                <SummaryRow label={strings.milepostExitNumber}>
                    {viewModel.milepostExitNumber}
                </SummaryRow>
                <SummaryRow label={strings.exitRampDistance}>
                    {viewModel.exitNumberDistance}
                </SummaryRow>
                <SummaryRow label={strings.exitRampDistanceUnits}>
                    {viewModelProperties.exitRampDistanceUnits}
                </SummaryRow>
                <SummaryRow label={strings.exitRampDirection}>
                    {viewModelProperties.exitRampDirection}
                </SummaryRow>
            </CardSection>
            <CardSection title={strings.otherFactors} testId={testIds.TRAFFIC_CRASH_OTHER_FACTORS}>
                <SummaryRow label={strings.crashSeverityAttrId}>
                    {viewModelProperties.crashSeverityAttrId}
                </SummaryRow>
                <SummaryRow label={strings.alcoholInvolvementAttrId}>
                    {viewModelProperties.alcoholInvolvementAttrId}
                </SummaryRow>
                <SummaryRow label={strings.drugInvolvementAttrId}>
                    {viewModelProperties.drugInvolvementAttrId}
                </SummaryRow>
                <SummaryRow label={strings.hasPropertyDamage}>
                    {viewModelProperties.hasPropertyDamage}
                </SummaryRow>
                <SummaryRow label={strings.intersectionNumApproachesAttrId}>
                    {viewModelProperties.intersectionNumApproachesAttrId}
                </SummaryRow>
                <SummaryRow label={strings.intersectionOverallGeometryAttrId}>
                    {viewModelProperties.intersectionOverallGeometryAttrId}
                </SummaryRow>
                <SummaryRow label={strings.schoolBusRelatedAttrId}>
                    {viewModelProperties.schoolBusRelatedAttrId}
                </SummaryRow>
            </CardSection>
            <TrafficCrashEntityDetailSummarySection
                entityId={viewModel.id}
                entityType={EntityTypeEnum.TRAFFIC_CRASH.name}
                renderWrapper={(children) => (
                    <CardSection title={strings.customFields}>{children}</CardSection>
                )}
            />
        </SummaryList>
    );
};

export default TrafficCrashSummary;

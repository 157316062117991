import React from 'react';
import styled from 'styled-components';
import { ReportComment } from '@mark43/rms-api';
import {
    ConnectedFormattedUser as _FormattedUser,
    FORMATS,
} from '~/client-common/core/users/components';
import { FormattedDate as _FormattedDate } from '~/client-common/core/dates/components';
import { InlineCommentsResolveButton } from '../../../../../reports/core/components/inline-comments/InlineCommentsResolveButton';

const Comment = styled.p`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-md);
    margin: 0;
    margin-top: 10px;
    padding: 0;
    overflow-y: auto;
    overflow-wrap: break-word;
    max-height: 200px;
`;

const Box = styled.div<{ isFocused?: boolean }>`
    position: relative;
    width: 280px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    background-color: ${(props) => props.theme.colors.white};
    box-sizing: border-box;
    z-index: 10;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: ${({ isFocused }) => (isFocused ? '20px' : 0)};
`;

const FormattedUser = styled(_FormattedUser)`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    color: ${(props) => props.theme.colors.darkGrey};
    font-weight: 700;
    font-size: var(--arc-fontSizes-md);
    display: block;
    margin: 0;
`;

const FormattedDate = styled(_FormattedDate)`
    font-size: var(--arc-fontSizes-sm);
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    color: ${(props) => props.theme.colors.darkGrey};
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`;

type InlineCommentPropsT = {
    data: ReportComment;
    isFocused: boolean;
    reportId: number;
};

export const InlineComment = React.forwardRef<HTMLDivElement, InlineCommentPropsT>(
    ({ data, isFocused, reportId }, ref) => {
        const { createdDateUtc, comment, createdBy, id } = data;

        return (
            <Box isFocused={isFocused} ref={ref}>
                <Flex>
                    <div>
                        <FormattedUser userId={createdBy} format={FORMATS.FULL_NAME} />
                        <FormattedDate
                            date={createdDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                        />
                    </div>
                    <InlineCommentsResolveButton reportId={reportId} commentId={id} />
                </Flex>

                <Comment>{comment}</Comment>
            </Box>
        );
    }
);

import { head, map } from 'lodash';
import { createSelector } from 'reselect';

import {
    allSingleAttributeValuesMapper,
    buildViewModel,
} from '../../../../../helpers/viewModelHelpers';

import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { formatAttributeWithOther } from '../../../attributes/utils/attributesHelpers';
import { miniUserViewModelsSelector } from '../../../mini-users/state/ui';
import { towVehicleReleasesWhereSelector } from '../data';

const buildTowVehicleReleaseViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    miniUserViewModelsSelector,
    (formatAttributeById, miniUserViewModels) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ releaseTypeAttrId, releaseTypeOther }) => ({
                    releaseTypeDisplay: formatAttributeWithOther(
                        formatAttributeById(releaseTypeAttrId),
                        releaseTypeOther
                    ),
                }),
                ({ releaseBy }) => ({
                    releaseBy: miniUserViewModels[releaseBy],
                }),
            ],
            helpers: {
                formatAttributeById,
            },
        });

        return viewModel;
    }
);

const towVehicleReleaseViewModelsWhereSelector = createSelector(
    towVehicleReleasesWhereSelector,
    buildTowVehicleReleaseViewModelSelector,
    (towVehicleReleasesWhere, buildTowVehicleReleaseViewModel) => (predicate) =>
        map(towVehicleReleasesWhere(predicate), buildTowVehicleReleaseViewModel)
);

export const towVehicleReleaseViewModelByReportIdSelector = createSelector(
    towVehicleReleaseViewModelsWhereSelector,
    (towVehicleReleaseViewModelsWhere) => (reportId) =>
        head(towVehicleReleaseViewModelsWhere({ reportId }))
);

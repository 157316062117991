import { useCallback } from 'react';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { useOverlayStore } from '../../core/overlays/hooks/useOverlayStore';
import { SecurityClassificationOwner } from '../types';

export function useSecurityClassificationModals() {
    const overlayStore = useOverlayStore<SecurityClassificationOwner>();

    const openEditSecurityClassification = useCallback(
        (modalProps: SecurityClassificationOwner) =>
            overlayStore.open(overlayIdEnum.EDIT_SECURITY_CLASSIFICATION_MODAL, modalProps),
        [overlayStore]
    );

    return { openEditSecurityClassification };
}

import { HistoryValueTypeEnum } from '@mark43/rms-api';
import React, { useState, ReactNode } from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { Icon, IconNameT, cssVar, mediaQueries } from 'arc';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import type { DiffedProcessedChangeSet } from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import testIds from '../../../../core/testIds';

/**
 * non-standard because `break-all` just breaks where required without taking word boundaries into account
 * hyphenate words when possible
 */
const wordWrapStyles = `
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
 `;

const HistoryCard = styled.div`
    float: left;
    position: relative;
    padding: 18px 24px;
    margin: 12px 0;
    box-sizing: border-box;
    width: 100%;
    ${wordWrapStyles};

    @media (min-width: ${mediaQueries.md}) {
        width: 484px;
    }
`;

// Paper is an alternate-style card that is used for certain events
// It has a white background and a border around it
const PaperHistoryCard = styled(HistoryCard)`
    border: 1px solid ${cssVar('arc.colors.border.default')};
    background-color: ${cssVar('arc.colors.brand.content')};
`;

const HistoryIcon = styled.div`
    left: -58px;
    top: 0;
    position: absolute;
    width: 60px;
    height: 54px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PaperHistoryIcon = styled(HistoryIcon)`
    background-color: ${cssVar('arc.colors.brand.content')};
    border: 1px solid ${cssVar('arc.colors.border.default')};
    border-right: 0;
    border-left: 6px solid ${cssVar('arc.colors.brand.default')};
    left: -60px;
    top: -1px;
`;

const CircleIcon = styled.div<{ reverse?: boolean }>`
    background-color: ${(props) =>
        props.reverse ? cssVar('arc.colors.brand.content') : cssVar('arc.colors.brand.default')};
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Link = styled.div`
    color: ${cssVar('arc.colors.brand.default')};
    font-size: var(--arc-fontSizes-sm);
    cursor: pointer;
    margin-top: 6px;
`;

const NewChange = styled.div`
    background-color: ${cssVar('arc.colors.positive.accent')};
    display: inline-block;
    ${wordWrapStyles};
`;

const OldChange = styled.div`
    background-color: ${cssVar('arc.colors.negative.accent')};
    text-decoration: line-through;
    display: inline-block;
    ${wordWrapStyles};
`;

const Subtitle = styled.div`
    color: ${cssVar('arc.colors.text.tertiary')};
`;

const HistoryEvent: React.FC<{
    paper?: boolean;
    iconType: IconNameT;
    reverseIcon?: boolean;
    message: ReactNode;
    date: ReactNode;
    showDetails?: boolean;
    subBody?: string;
    changes: DiffedProcessedChangeSet[];
}> = ({ paper, iconType, reverseIcon, message, date, showDetails, subBody, changes }) => {
    const Wrapper = paper ? PaperHistoryCard : HistoryCard;

    const [detailsIsOpen, setDetailsIsOpen] = useState(false);
    return (
        <Wrapper data-test-id={testIds.HISTORY_BLOCK}>
            {paper ? (
                <PaperHistoryIcon>
                    <Icon icon={iconType} color="brand.default" size="lg" />
                </PaperHistoryIcon>
            ) : (
                <HistoryIcon>
                    <CircleIcon reverse={reverseIcon}>
                        <Icon
                            icon={iconType}
                            color={reverseIcon ? 'brand.default' : 'brand.content'}
                            size={reverseIcon ? 'lg' : 'md'}
                        />
                    </CircleIcon>
                </HistoryIcon>
            )}
            {message}
            <Subtitle>{date}</Subtitle>
            {showDetails && (
                <div>
                    <Link
                        onClick={() => setDetailsIsOpen((detailsIsOpen) => !detailsIsOpen)}
                        data-test-id={testIds.HISTORY_SHOW_HIDE_DETAILS}
                    >
                        {detailsIsOpen ? 'Hide Details...' : 'Show Details...'}
                    </Link>
                    {detailsIsOpen &&
                        map(changes, (change, i) => {
                            const { diffSet, oldDisplay, newDisplay, historyValueType } = change;
                            let content: ReactNode;
                            if (diffSet && diffSet.length > 0) {
                                const diffDisplays = map(
                                    diffSet,
                                    (
                                        { narrativeValue, value, added = false, removed = false },
                                        index
                                    ) => {
                                        if (added) {
                                            return <NewChange key={index}>{value} </NewChange>;
                                        } else if (removed) {
                                            return <OldChange key={index}>{value} </OldChange>;
                                        } else {
                                            return (
                                                <FeatureFlagged
                                                    flag="RMS_INLINE_NARRATIVE_COMMENTS_ENABLED"
                                                    fallback={value}
                                                >
                                                    {historyValueType ===
                                                    HistoryValueTypeEnum.NARRATIVE.name
                                                        ? narrativeValue
                                                        : value}
                                                </FeatureFlagged>
                                            );
                                        }
                                    }
                                );
                                content = <span>{diffDisplays}</span>;
                            } else {
                                content = oldDisplay ? (
                                    <span>
                                        <OldChange>{oldDisplay} </OldChange>
                                        {newDisplay && <NewChange>{newDisplay}</NewChange>}
                                    </span>
                                ) : (
                                    <>{newDisplay && <NewChange>{newDisplay}</NewChange>}</>
                                );
                            }
                            return (
                                <div key={i}>
                                    <span>{change.fieldName}: </span>
                                    {content}
                                </div>
                            );
                        })}
                </div>
            )}
            <div>{subBody}</div>
        </Wrapper>
    );
};

export default HistoryEvent;

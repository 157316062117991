import { useSelector } from 'react-redux';
import React from 'react';
import { map, some } from 'lodash';

import { Report } from '@mark43/rms-api';

import {
    OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
    OFFENSE_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
    OFFENSE_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID,
    OFFENSE_CASE_STATUS_STATUS_DATE_UTC,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { reportDefinitionHasOffenseCaseStatusSelector } from '~/client-common/core/domain/report-definitions/state/data';

import SummaryRowDate from '../../../../legacy-redux/components/summaries/SummaryRowDate';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import { SummaryRowAttribute } from '../../../../legacy-redux/components/summaries/SummaryRowAttribute';
import { CardSubsection } from '../../../../legacy-redux/components/core/Card';
import Row from '../../components/Row';
import {
    sortedOffenseCaseStatusesForReportIdsSelector,
    ExtendedOffenseCaseStatus,
} from '../state/data/offenseCaseStatuses';

const strings = componentStrings.core.OffenseCaseStatusSummary;

const OffenseCaseStatusSummary: React.FC<{ offenseCaseStatus: ExtendedOffenseCaseStatus }> = ({
    offenseCaseStatus,
}) => {
    const offenseCaseStatusAttrId = offenseCaseStatus?.caseStatusAttrId;

    return !!offenseCaseStatusAttrId ? (
        <Row style={{ marginBottom: '5px' }}>
            <SummaryList labelWidth={160} contentWidth={380}>
                <div className="report-title">{offenseCaseStatus.title}</div>
                <SummaryRowAttribute
                    fieldName={OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID}
                    attributeId={offenseCaseStatusAttrId}
                />
                <SummaryRowDate
                    fieldName={OFFENSE_CASE_STATUS_STATUS_DATE_UTC}
                    date={offenseCaseStatus?.statusDateUtc}
                />
                <SummaryRowAttribute
                    fieldName={OFFENSE_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID}
                    attributeId={offenseCaseStatus?.closedByDivisionAttrId}
                />
                <SummaryRowAttribute
                    fieldName={OFFENSE_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID}
                    attributeId={offenseCaseStatus?.closedByUnitAttrId}
                />
            </SummaryList>
        </Row>
    ) : null;
};

export const OffenseCaseStatusesSummary: React.FC<{ reportId: number }> = ({ reportId }) => {
    const report = useSelector(reportByIdSelector)(reportId) as Report;
    const reportDefinitionHasOffenseCaseStatus = useSelector(
        reportDefinitionHasOffenseCaseStatusSelector
    )(report.reportDefinitionId);
    const offenseCaseStatuses = useSelector(sortedOffenseCaseStatusesForReportIdsSelector)([
        reportId,
    ]);
    const offenseDisplayName = useOffenseFieldName();
    const isDisplaySummary = some(
        offenseCaseStatuses,
        (offenseCaseStatus) => !!offenseCaseStatus.caseStatusAttrId
    );

    return !!reportDefinitionHasOffenseCaseStatus && isDisplaySummary ? (
        <CardSubsection
            title={strings.title(offenseDisplayName)}
            // temporarily set these as undefined
            // until we move `CardSubsection` to TS
            className={undefined}
            testId={undefined}
        >
            {map(offenseCaseStatuses, (offenseCaseStatus) => (
                <OffenseCaseStatusSummary
                    key={offenseCaseStatus.offenseId}
                    offenseCaseStatus={offenseCaseStatus}
                />
            ))}
        </CardSubsection>
    ) : null;
};

import React from 'react';

// <a> tags will not register onClicks or function if the target attribute is not set
// no idea, why, something about a onClick handler on the body
const DownloadLink = ({ children, ...otherProps }) => {
    return (
        <a target="_blank" download {...otherProps}>
            {children}
        </a>
    );
};

export default DownloadLink;

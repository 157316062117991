import { useEffect, useState } from 'react';
import { FeatureLayerConstructorT, FeatureLayerT, GraphicConstructorT, WebMapT } from '../../types';
import { nearestLocationPin } from '../../images/nearestLocationPin';
import { spatialReference } from './staticFeatureLayerHelpers';

type NearestLocationFeatureLayerProps = {
    webmap?: WebMapT;
    FeatureLayerConstructor?: FeatureLayerConstructorT;
    GraphicConstructor?: GraphicConstructorT;
};

export const useNearestLocationFeatureLayer = ({
    webmap,
    FeatureLayerConstructor,
    GraphicConstructor,
}: NearestLocationFeatureLayerProps) => {
    const [nearestLocationLayer, setNearestLocationLayer] = useState<FeatureLayerT>();

    // handles initializing empty feature layer
    useEffect(() => {
        // need modules to load
        if (webmap && FeatureLayerConstructor && GraphicConstructor) {
            const nearestLocationFeatureLayer = getNearestLocationLayer(FeatureLayerConstructor);
            setNearestLocationLayer(nearestLocationFeatureLayer);
            webmap.layers.add(nearestLocationFeatureLayer);
        }
    }, [FeatureLayerConstructor, GraphicConstructor, webmap]);

    return {
        layer: nearestLocationLayer,
    };
};

const addressIconSvgStringEncoded = btoa(nearestLocationPin);

const nearestLocationIconRenderer = {
    type: 'unique-value', // autocasts as new UniqueValueRenderer()
    field: ' ',
    defaultSymbol: {
        // used when item field dont match anything defined in uniqueValueInfos
        type: 'picture-marker',
        url: `data:image/svg+xml;base64,${addressIconSvgStringEncoded}`,
        width: '22px',
        height: '32px',
    },
};

const getNearestLocationLayer = (FeatureLayerConstructor: FeatureLayerConstructorT) => {
    const nearestLocationFeatureLayer = new FeatureLayerConstructor({
        title: 'Nearest Location',
        source: [], // initialize with an empty collection
        renderer: nearestLocationIconRenderer as __esri.RendererProperties,
        objectIdField: 'objectId', // This must be defined when creating a layer from `Graphic` objects
        outFields: ['*'],
        geometryType: 'point',
        spatialReference,
    });

    return nearestLocationFeatureLayer;
};

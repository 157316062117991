import nameNameLinksActionTypes from './types/nameNameLinksActionTypes';

/**
 * Set the given nameNameLinks in the data state (all previously stored
 *   nameNameLinks are removed from state). There is no `addNameNameLinks`
 *   action because nameNameLinks don't have ids and so they are stored in an
 *   array, not a keyed object.
 * @param  {Object[]} nameNameLinks
 * @return {Object}
 */
export function setNameNameLinks(nameNameLinks) {
    return {
        type: nameNameLinksActionTypes.SET_NAME_NAME_LINKS,
        payload: nameNameLinks,
    };
}

import React from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RegionalGroupEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../core/testIds';
import { currentUserDepartmentProfileSelector } from '../../core/current-user/state/ui';
import Modal from '../../../legacy-redux/components/core/Modal';
import { buttonTypes } from '../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../legacy-redux/components/core/Icon';
import { Tooltip } from '../../core/components/tooltip';

import {
    exportNibrsOverwriteModalContext,
    exportNibrsDownloadModalContext,
    exportNibrsNotMostRecentMonthModalContext,
} from '../configuration';
import { complianceExportTypeDisplaySelector } from '../state/ui';
import { SupportedComplianceExportType } from '../types';
import ComplianceExportAuthenticationForm from './ComplianceExportAuthenticationForm';
import ComplianceInlineButton from './ComplianceInlineButton';

const strings = componentStrings.compliance.ComplianceDownloadButton;

const modalContentStyles = {
    minHeight: 'auto',
    fontSize: '13px',
};

const ComplianceExportAuthenticationFormWrapper = styled.div`
    margin: 10px 0;
`;

type ComplianceDownloadButtonProps = {
    className?: string;
    disabled?: boolean;
    handleDownloadModalSave: () => void;
    handleOverwriteModalSave: () => void;
    label?: string;
    openDownloadModal: () => void;
    selectedDateIntervalIsExportable?: boolean;
    complianceExportType: SupportedComplianceExportType;
};

const ComplianceDownloadButton: React.FC<ComplianceDownloadButtonProps> = (props) => {
    const currentUserDepartmentProfile = useSelector(currentUserDepartmentProfileSelector);

    const departmentRegionalGroup =
        currentUserDepartmentProfile &&
        RegionalGroupEnum[currentUserDepartmentProfile.nibrsRegionalGroup];

    const isExternalSubmission = departmentRegionalGroup?.isExternalSubmission;

    const {
        className,
        disabled,
        handleDownloadModalSave,
        handleOverwriteModalSave,
        label = strings.errorExportNibrsButton,
        openDownloadModal,
        selectedDateIntervalIsExportable,
        complianceExportType,
    } = props;

    const complianceExportTypeDisplay = useSelector(complianceExportTypeDisplaySelector);
    const complianceExportTypeDisplayValue = complianceExportTypeDisplay(complianceExportType);

    const confirmationStrings = strings.modals.confirmationStrings(
        complianceExportTypeDisplayValue
    );
    const exportConfirmationStrings = confirmationStrings.exportConfirmation;
    const overwriteConfirmationStrings = confirmationStrings.overwriteConfirmation;

    const button = (
        <ComplianceInlineButton
            buttonType={buttonTypes.PRIMARY}
            iconType={iconTypes.EXPORT}
            disabled={disabled}
            onClick={openDownloadModal}
            testId={testIds.COMPLIANCE_DASHBOARD_EXPORT_BUTTON}
        >
            {label}
        </ComplianceInlineButton>
    );

    return (
        <div className={className}>
            <Modal
                title={strings.modals.notMostRecentMonthInfo.title}
                context={exportNibrsNotMostRecentMonthModalContext}
                okText={strings.modals.confirm}
                cancelText={''}
                shouldCloseOnOverlayClick={false}
                contentStyle={modalContentStyles}
            >
                {strings.modals.notMostRecentMonthInfo.message}
            </Modal>
            <Modal
                title={exportConfirmationStrings.title}
                context={exportNibrsDownloadModalContext}
                okText={strings.modals.confirm}
                cancelText={strings.modals.cancel}
                onSave={handleDownloadModalSave}
                shouldCloseOnOverlayClick={false}
                contentStyle={modalContentStyles}
            >
                {exportConfirmationStrings.message}
                <ComplianceExportAuthenticationFormWrapper>
                    <ComplianceExportAuthenticationForm
                        complianceExportTypeDisplayValue={complianceExportTypeDisplayValue}
                    />
                </ComplianceExportAuthenticationFormWrapper>
            </Modal>
            <Modal
                title={
                    isExternalSubmission
                        ? overwriteConfirmationStrings.externalSubmissionTitle
                        : overwriteConfirmationStrings.title
                }
                context={exportNibrsOverwriteModalContext}
                okText={strings.modals.confirm}
                cancelText={strings.modals.cancel}
                onSave={handleOverwriteModalSave}
                shouldCloseOnOverlayClick={false}
                contentStyle={modalContentStyles}
            >
                {isExternalSubmission
                    ? overwriteConfirmationStrings.externalSourceMessage
                    : overwriteConfirmationStrings.message}
            </Modal>
            {selectedDateIntervalIsExportable ? (
                button
            ) : (
                <Tooltip side="top" content={strings.exportButtonTooltip}>
                    {button}
                </Tooltip>
            )}
        </div>
    );
};

export default ComplianceDownloadButton;

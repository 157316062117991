import { queryParamDefaults } from '../../../../configs/advancedSearchConfig';

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Usage Logs Resource',
        methods: {
            /**
             * @param  {string}   query         Search query string.
             * @param  {number}   [from=0]
             * @param  {number}   [size=25]
             * @return {Promise<Object>}
             */
            searchUsageLogs(
                query = '',
                from = queryParamDefaults.FROM,
                size = queryParamDefaults.SIZE
            ) {
                return req({
                    baseUrl: '/rms/api',
                    method: 'POST',
                    url: 'rms_usage_logs/query',
                    data: {
                        elasticQuery: query,
                        from,
                        size,
                    },
                });
            },
            createUsageLog(usageLogCreation) {
                return req({
                    baseUrl: '/usage_logs/api',
                    method: 'POST',
                    url: 'usage_logs',
                    data: usageLogCreation,
                    hideLoadingBar: true,
                });
            },
            getRecentlyViewedUsageLogs(data, { hideLoadingBar = false } = {}) {
                return req({
                    baseUrl: '/rms/api',
                    method: 'POST',
                    url: 'rms_usage_logs/recently_viewed',
                    data,
                    hideLoadingBar,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import keyMirror from 'keymirror';

/**
 * All boxes (modals, side panels, lightboxes) in the app.
 * @type {Object}
 */
export default keyMirror({
    // general purpose modals
    ERROR_MODAL: null,
    LOADING_MODAL: null,
    CONFIRMATION_MODAL: null,

    // login modals
    APP_LOAD_FAILURE_MODAL: null,

    // feature modals
    ACCOUNT_SWITCHER_MODAL: null,
    ADMIN_CONFIRM_IMPORT_EXPORT_MODAL: null,
    ADVANCED_SEARCH_LOADING_MODAL: null,
    BANNER_ATTACHMENTS_MODAL: null,
    CAD_TICKET_DOES_NOT_REQUIRE_REPORT_MODAL: null,
    TOGGLE_ABILITY_MODAL: null,
    CREATE_REPORT_MODAL: null,
    REPORT_SUBMISSION_MODAL: null,
    SUBMISSION_COMMENTS_MODAL: null,
    RETURN_REPORT_TO_DRAFT_MODAL: null,
    ENTITY_PERMISSIONS_MODAL: null,
    FULL_RESET_MODAL: null,
    INACTIVITY_MODAL: null,
    NARRATIVE_MODAL: null,
    REPORT_CHANGE_OWNER_MODAL: null,
    REPORT_REJECTION_MODAL: null,
    REPORT_SEND_ARREST_TO_BOOKING_MODAL: null,
    REN_CHANGE_MODAL: null,
    RECORD_NUMBER_CHANGE_MODAL: null,
    REPORTING_RESET_MODAL: null,
    RESET_SUCCESS_MODAL: null,
    OFFENSE_CODE_CHANGE_REASON_MODAL: null,
    EDIT_ROLE_NAME_MODAL: null,
    DELETE_ROLE_CONFIRMATION_MODAL: null,
    NIBRS_EXPORT_OVERWRITE_CONFIRMATION_MODAL: null,
    NIBRS_EXPORT_DOWNLOAD_CONFIRMATION_MODAL: null,
    NIBRS_EXPORT_NOT_MOST_RECENT_MONTH_MODAL: null,
    NIBRS_EXPORT_DELETE_HISTORY_MODAL: null,
    NIBRS_RESUBMISSION_DOWNLOAD_CONFIRMATION_MODAL: null,
    ROUTING_LABELS_MODAL: null,
    MERGE_ENTITIES_CONFIRMATION_MODAL: null,
    ADMIN_USERS_DASHBOARD_FILTER_MODAL: null,
    CONSORTIUM_ADMIN_REMOVE_DEPARTMENT_MODAL_CONTEXT: null,
    CONSORTIUM_ADMIN_ADD_DEPARTMENT_MODAL_CONTEXT: null,
    OFFENSE_INCIDENT_DELETION_MODAL: null,
    BLACKSMITH_INTEGRATION_DELETION_MODAL: null,
    BLACKSMITH_INTEGRATION_KICK_OFF_CONFIRMATION_MODAL: null,

    // warrant modals
    CHANGE_WARRANT_NUMBER_MODAL: null,
    CREATE_WARRANT_MODAL: null,
    WARRANTS_DASHBOARD_FILTER_MODAL: null,
    DELETE_WARRANT_MODAL: null,
    WARRANT_ACTIVITY_SIDE_PANEL: null,
    WARRANT_CHARGES_SIDE_PANEL: null,
    WARRANT_CLIPS_SENDING_MODAL: null,

    // reports modals

    // cases modals
    CREATE_CASE_MODAL: null,
    CASE_DETAILS_MODAL: null,
    DELETE_CASE_MODAL: null,
    EDIT_CASE_REN_MODAL: null,
    CASE_PERMISSIONS_MODAL: null,

    // evidence modals
    LABEL_PRINTING_MODAL: null,
    DOCUMENT_EXPORTING_MODAL: null,
    DELETE_STORAGE_MODAL: null,
    ITEM_SPLIT_ITEM_POPOVER: null,
    MOVE_STORAGE_MODAL: null,

    // exports / releases
    CREATE_EXPORT_RELEASE_MODAL: null,
    EDIT_EXPORT_RELEASE_MODAL: null,

    // confirmation bar
    CONFIRMATION_BAR: null,
    // popovers
    ITEM_POPOVER: null,
    NOTIFICATIONS_POPOVER: null,
    ITEM_QUEUE_POPOVER: null,

    // side panels
    DEPARTMENT_PROFILE_SIDE_PANEL: null,
    CASE_STATUSES_SIDE_PANEL: null,
    FACILITY_ADMIN_SIDE_PANEL: null,
    STORAGE_ADMIN_SIDE_PANEL: null,
    USER_PROFILE_ADMIN_SIDE_PANEL: null,
    USER_PROFILE_ADMIN_ROLES_SIDE_PANEL: null,
    CASE_REVIEW_SIDE_PANEL: null,
    TASK_DETAILS_SIDE_PANEL: null,
    NOTIFICATION_SETTINGS_SIDE_PANEL: null,
    ARREST_UCR_CLASSIFICATION_SIDE_PANEL: null,
    UCR_CLASSIFICATION_SIDE_PANEL: null,
    CONNECT_WARRANTS_SIDE_PANEL: null,
    DUPLICATE_NAME_RESULTS_SIDE_PANEL: null,
    ITEM_SPLIT_SIDE_PANEL: null,
    ITEM_SPLIT_CONFIRMATION_SIDE_PANEL: null,
    STAFF_REMARK_SIDE_PANEL: null,
    ADMIN_NEW_ROLE_SIDE_PANEL: null,
    ADMIN_ASSIGN_USERROLE_SIDE_PANEL: null,
    ATTACHMENTS_SIDE_PANEL: null,
    CREATE_WARRANT_SIDE_PANEL: null,
    ASSOCIATED_RECORDS_SIDE_PANEL: null,
    MANAGE_ITEMS_SIDE_PANEL: null,
    RECENT_ITEMS_SIDE_PANEL: null,
    ITEM_SIDE_PANEL: null,
    REPORT_BOOKING_SIDE_PANEL: null,
    GANG_TRACKING_SIDE_PANEL: null,
    LINK_ITEMS_TO_OFFENSE_SIDE_PANEL: null,
    NOTEPAD_LINKS_SIDE_PANEL: null,
    BLACKSMITH_INTEGRATION_SIDE_PANEL: null,

    // lightboxes
    IMAGE_GALLERY_LIGHTBOX: null,

    // playground stuff
    PLAYGROUND_MODAL_1: null,
    PLAYGROUND_MODAL_2: null,
    PLAYGROUND_MODAL_3: null,
    PLAYGROUND_MODAL_4: null,
    PLAYGROUND_POPOVER_1: null,
    PLAYGROUND_SIDE_PANEL_1: null,
    PLAYGROUND_SIDE_PANEL_2: null,
});

import React from 'react';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../../legacy-redux/components/core/Modal';
import createReleaseForm from '../state/forms/createReleaseForm';
import { submitEditReleaseModal } from '../state/ui';
import { closeBox } from '../../../../../legacy-redux/actions/boxActions';
import CreateReleaseForm from './CreateReleaseForm';

const context = {
    name: boxEnum.EDIT_EXPORT_RELEASE_MODAL,
};
const strings = componentStrings.exports.releaseTracking.components.EditReleaseModal;

const mapDispatchToProps = (dispatch) => ({
    submitNewRelease: () => dispatch(submitEditReleaseModal()),
    onClose: () => {
        dispatch(createReleaseForm.actionCreators.reset());
        dispatch(closeBox(context));
    },
});

export default connect(
    null,
    mapDispatchToProps
)(function EditReleaseModal({ submitNewRelease, onClose }) {
    return (
        <Modal
            context={context}
            title={strings.title}
            onSave={submitNewRelease}
            onClose={onClose}
            contentStyle={{ height: 360 }}
        >
            <CreateReleaseForm />
        </Modal>
    );
});

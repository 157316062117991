import { compact, map } from 'lodash';

import { TRANSIENT_KEY } from '~/client-common/helpers/dataHelpers';

export const entityPermissionsFormWidths = {
    // widths from old case modal
    roleId: 318,
    operationType: 135,
};

export const entityPermissionsFormFieldList = [
    'permissions[].roleId',
    'permissions[].operationType',
    // client-only id to uniquely identify each permission for ui state
    `permissions[].${TRANSIENT_KEY}`,
    'externalDepartmentPermissions[].roleId',
    'externalDepartmentPermissions[].operationType',
];

// remove array prefix from form fields for applying to each permission
export const entityPermissionFieldList = compact(
    map(entityPermissionsFormFieldList, (val) =>
        val.replace('permissions[].', '').replace('externalDepartmentPermissions[].', '')
    )
);

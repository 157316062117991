import { INDETERMINATE } from '../../core/forms/components/checkboxes/Checkbox';

const computeCheckboxValue = (allOwnSelected, allOtherSelected) => {
    let value = allOwnSelected ? true : INDETERMINATE;
    if (allOtherSelected) {
        value = false;
    }
    return value;
};

export default computeCheckboxValue;

import React from 'react';
import { compose, withProps } from 'recompose';

import { withTheme } from 'styled-components';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';

const googleMapsApiKey = 'AIzaSyAOvGJzioG47sapmAGR5JXmYczVJlpHgvY';

// we can't use styled components here because
// the map api needs to add a property to the element :(
function GoogleMapWrapper({ children, center, zoom = 12 }) {
    return (
        <GoogleMap
            defaultZoom={zoom}
            center={center}
            defaultCenter={{ lat: 38.9072, lng: 77.0369 }} // if no coordinates default to DC??? coz why not
        >
            {children}
        </GoogleMap>
    );
}

export default compose(
    withTheme,
    withProps((ownerProps) => ({
        loadingElement: <SimpleLoading />,
        googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry&key=${googleMapsApiKey}`,
        mapElement: <div style={{ height: `100%` }} />,
        containerElement: (
            <div
                style={{
                    width: '250px',
                    height: '250px',
                    position: 'absolute',
                    backgroundColor: ownerProps.theme.colors.extraLightGrey,
                    border: `${ownerProps.theme.colors.darkGrey} 1px solid`,
                    ...ownerProps.styles,
                }}
            />
        ),
    })),
    withScriptjs,
    withGoogleMap
)(GoogleMapWrapper);

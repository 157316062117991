import { ChainEventCategoryEnum } from '@mark43/evidence-api';
import { includes } from 'lodash';
import { prettify } from '~/client-common/helpers/stringHelpers';

const disableAttachmentsChainEventCategories = [
    ChainEventCategoryEnum.IN_POLICE_CUSTODY.name,
    ChainEventCategoryEnum.CHECKED_IN_TEMP.name,
];

const disableOverduePeriodChainEventCategories = [
    ChainEventCategoryEnum.CHECKED_IN_MAIN.name,
    ChainEventCategoryEnum.LOCATION_UPDATED.name,
    ChainEventCategoryEnum.TRANSFERRED.name,
    ChainEventCategoryEnum.DISPOSITIONED.name,
    ChainEventCategoryEnum.RELEASED.name,
    ChainEventCategoryEnum.LOCATION_UPDATED_AUTOMATICALLY.name,
];

export const isAttachmentsDisabled = (chainEventType = {}) => {
    if (!chainEventType) {
        return false;
    }
    const { chainEventCategory } = chainEventType;

    return includes(disableAttachmentsChainEventCategories, chainEventCategory);
};

export const isOverduePeriodDisabled = (chainEventType = {}) => {
    if (!chainEventType) {
        return false;
    }
    const { chainEventCategory } = chainEventType;

    return includes(disableOverduePeriodChainEventCategories, chainEventCategory);
};

export const getDisplayValueForChainEventTypeCategory = (chainEventCategory) => {
    switch (chainEventCategory) {
        case ChainEventCategoryEnum.CHECKED_IN_MAIN.name:
            return 'Checked In - Permanent Storage';
        case ChainEventCategoryEnum.CHECKED_OUT_PERSON.name:
            return 'Checked Out - Person';
        case ChainEventCategoryEnum.CHECKED_OUT_LAB.name:
            return 'Checked Out - External Location';
        case ChainEventCategoryEnum.TRANSFERRED.name:
            return 'Permanent Transfer - Another Agency';
        case ChainEventCategoryEnum.CHECKED_IN_TEMP.name:
            return 'Checked In - Temporary Location';
        case ChainEventCategoryEnum.LOCATION_UPDATED.name:
            return 'Update Location - Within Facility';
        case ChainEventCategoryEnum.MIGRATED.name:
            return 'Legacy Chain Event';
        case ChainEventCategoryEnum.LOCATION_UPDATED_AUTOMATICALLY.name:
            return 'Location Updated - During Audit';
        case ChainEventCategoryEnum.INFIELD_TRANSFER.name:
            return 'Infield Transfer';
        default:
            return prettify(chainEventCategory);
    }
};

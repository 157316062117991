const packetTypeEnum = {
    REGULAR: 'REGULAR',
    CHILD: 'CHILD',
    EXPORT_OPTION: 'EXPORT_OPTION',
    CHILD_EXPORT_OPTION: 'CHILD_EXPORT_OPTION',
} as const;

export type PacketTypeEnumType = keyof typeof packetTypeEnum;

export default packetTypeEnum;

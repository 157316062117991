import { AtfManufacturer } from '@mark43/rms-api';
import { isEmpty } from 'lodash';
import createNormalizedModule from '~/client-common/core/utils/createNormalizedModule';
import { ClientCommonAction } from '~/client-common/redux/types';

export const NEXUS_STATE_PROP = 'atfManufacturers';

const atfManufacturersModule = createNormalizedModule<AtfManufacturer>({
    type: NEXUS_STATE_PROP,
});

export const atfManufacturersSelector = atfManufacturersModule.selectors.entitiesSelector;

const SEARCH_FOR_ATF_MANUFACTURERS_SUCCESS = 'SEARCH_FOR_ATF_MANUFACTURERS_SUCCESS';

function searchForAtfManufacturersSuccess(atfManufacturers: AtfManufacturer[]) {
    return { type: SEARCH_FOR_ATF_MANUFACTURERS_SUCCESS, payload: atfManufacturers };
}

export function saveAtfManufacturers({
    atfManufacturers,
}: {
    atfManufacturers: AtfManufacturer[];
}): ClientCommonAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        if (!atfManufacturers || isEmpty(atfManufacturers)) {
            return;
        }
        return dispatch(
            nexus.withEntityItems(
                {
                    [NEXUS_STATE_PROP]: atfManufacturers,
                },
                searchForAtfManufacturersSuccess(atfManufacturers)
            )
        );
    };
}

export default atfManufacturersModule.reducerConfig;

import { ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';

export default {
    reports: ElasticSearchTypeEnum.REPORT.name,
    cad: ElasticSearchTypeEnum.CAD_TICKET.name,
    persons: ElasticSearchTypeEnum.PERSON.name,
    organizations: ElasticSearchTypeEnum.ORGANIZATION.name,
    vehicles: ElasticSearchTypeEnum.VEHICLE.name,
    property: ElasticSearchTypeEnum.PROPERTY.name,
    attachments: ElasticSearchTypeEnum.ATTACHMENTS.name,
    'court-orders': ElasticSearchTypeEnum.COURT_ORDERS.name,
    bookings: EntityTypeEnum.BOOKING.name,
};

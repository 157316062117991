import _, { find, isEmpty, parseInt } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import {
    processHistoryEvent,
    processChangeSet,
} from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import { prettify } from '~/client-common/helpers/stringHelpers';
import { getAdminListStatusFromStartEnd } from '~/client-common/core/dates/utils/dateHelpers';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import { currentDepartmentDateFormatsSelector } from '~/client-common/core/domain/current-user/state/ui';
import { routeNameSelector } from '../../routing/routerModule';
import routesConfig from '../../routing/routesConfig';
import { formatUserByIdSelector } from './userSelectors';
import { getFormListFromState } from './formSelectorHelpers';
import * as helpers from './attributesAdminSelectorHelpers';

const attributeTypesSelector = (state) => state.data.attributesAdmin.attributeTypes;
const attributesSelector = (state) => state.data.attributesAdmin.attributes;
// code mappings keyed by attribute type
const codeMappingsSelector = (state) => state.data.attributesAdmin.codeMappings;

const attributeTypeLinkTypesSelector = (state) => state.data.attributesAdmin.linkTypes;

export const uiSelector = (state) => state.ui.attributesAdmin;

const formSelector = (state) => state.form.attributesAdmin;

// form data for all code links of the current attribute
export const codeLinkFormDataSelector = (state) =>
    getFormListFromState(state, 'attributesAdminCodeLink');

// the attribute type that the user has selected for filtering attributes;
// `undefined` if no selection
export const attributeTypeSelector = createSelector(
    attributeTypesSelector,
    (types) => (attributeType) => types[attributeType]
);

export const attributeSelector = createSelector(
    attributesSelector,
    (attributes) => (attributeId) => {
        const attr = attributes[attributeId];
        // we need to set the status because in ie8 we haven't precomputed the status
        // and we want to show it on the form
        if (!attr) {
            return;
        }
        return {
            ...attr,
            status: getAdminListStatusFromStartEnd(attr.startDateUtc, attr.endDateUtc),
        };
    }
);

export const linkTypeByAttributeIdSelector = createSelector(
    attributeTypeLinkTypesSelector,
    attributeSelector,
    (attributeLinkTypes, attributes) => (attributeId) => {
        const attribute = attributes(attributeId);

        if (attribute) {
            const attributeTypeLinkTypes = attributeLinkTypes[attribute.attributeType];
            if (!isEmpty(attributeTypeLinkTypes)) {
                const attributeLinkType = find(
                    attributeTypeLinkTypes,
                    (item) => item.attributeId === attribute.id
                );
                return attributeLinkType ? attributeLinkType.linkType : undefined;
            }
        }
        return undefined;
    }
);

// all attribute types as admin list items, to be used for filtering attributes
// by type; pass this array into the `items` prop of `AdminList`
export const attributeTypeAdminListItemsSelector = createSelector(
    attributeTypesSelector,
    routeNameSelector,
    (types, routeName) => (selectedAttributeType, selectedAttributeId, newAttributeSelected) =>
        _(types)
            .map((type) => {
                const selected = type.attributeType === selectedAttributeType;
                // for the currently selected attribute type, if one of its
                // attributes is also selected, then direct the link to that
                // attribute instead of the type so the route doesn't change
                // unintuitively
                let pathSuffix =
                    selected && selectedAttributeId && !newAttributeSelected
                        ? `/${selectedAttributeId}`
                        : '';

                if (routeName === routesConfig.ADMIN_ATTRIBUTES_ATTRIBUTE_TYPE_HISTORY.name) {
                    pathSuffix = '/history';
                }

                return {
                    path: `/admin/attributes/${type.attributeType}${pathSuffix}`,
                    title: type.displayName,
                    subtitle: _(type.codeSources).map('codeSourceName').uniq().sortBy().join(', '),
                    key: type.attributeType,
                    selected,
                };
            })
            .sortBy('title')
            .value()
);

// attributes of the currently selected attribute type, as admin list items;
// pass this array into the `items` prop of `AdminList`
export const attributeAdminListItemsSelector = createSelector(
    [attributesSelector, uiSelector],
    (attributes, ui) => (selectedType, selectedAttributeId, showExpiredAttributes) => {
        const { EXPIRED } = attributeStatuses;
        if (selectedType) {
            return _(attributes)
                .filter({ attributeType: selectedType.attributeType })
                .map((attribute) => ({
                    path: `/admin/attributes/${selectedType.attributeType}/${attribute.id}`,
                    // not prettified because it's directly editable
                    title: attribute.displayValue,
                    subtitle: `Code: ${attribute.displayAbbreviation}`,
                    key: attribute.id,
                    // the status is stored in the ui state, not the data state
                    status: ui.attributes[attribute.id].status,
                    selected: attribute.id === parseInt(selectedAttributeId),
                    enabled: attribute.isEnabled,
                }))
                .filter((item) => showExpiredAttributes || item.status !== EXPIRED)
                .sortBy('title')
                .value();
        } else {
            return [];
        }
    }
);

// the attribute type of the current attribute (either existing or new), which
// may be different from the currently selected attribute type; `undefined` if
// no selection
export const typeOfAttributeIdSelector = createSelector(
    [attributeSelector, attributeTypesSelector, formSelector],
    (attribute, types, form) => (attributeId) =>
        _.get(
            types,
            attributeId
                ? _.get(attribute(attributeId), 'attributeType')
                : _.get(form, 'attributeType.value')
        )
);

// all editable attributes types as `Select` options
export const attributeTypeSelectOptionsSelector = createSelector(attributeTypesSelector, (types) =>
    _(types)
        .filter('editable')
        .map((type) => ({
            value: type.attributeType,
            display: type.displayName,
        }))
        .sortBy('display')
        .value()
);

// the possible parent attributes of the current attribute (either existing or
// new) as `Select` options
export const parentAttributeSelectOptionsForAttributeIdSelector = createSelector(
    [typeOfAttributeIdSelector, attributesSelector],
    (typeOfAttributeId, attributes) => (attributeId) =>
        typeOfAttributeId(attributeId)
            ? _(attributes)
                  .filter({
                      attributeType: _.get(
                          typeOfAttributeId(attributeId),
                          'parentAttributeTypeName'
                      ),
                      isEnabled: true,
                  })
                  .map((attribute) => ({
                      value: attribute.id,
                      // not prettified because it's directly editable
                      display: attribute.displayValue,
                  }))
                  .sortBy('display')
                  .value()
            : []
);

// code links of the currently selected attribute, with properties to be used in
// code link forms
export const codeLinksForAttributeIdSelector = createSelector(
    [
        attributeSelector,
        typeOfAttributeIdSelector, // important to not be the selected type
        codeMappingsSelector,
    ],
    (attribute, typeOfAttributeId, codeMappings) => (attributeId) => {
        const selectedAttribute = attribute(attributeId);
        const type = typeOfAttributeId(attributeId);

        if (!type) {
            return [];
        }

        return _.map(
            helpers.parseCodeLinks({
                attribute: selectedAttribute, // may be empty
                type,
                codeMappings: codeMappings[type.attributeType],
            }),
            ({ codeSource, codes, codeMapping }) => {
                // select label
                const codeTypeName = _.get(codes, '[0].codeType.name');
                const label = codeSource.codeSourceName + (codeTypeName ? `: ${codeTypeName}` : '');

                // select options
                const options = _(codes)
                    .map((code) => ({
                        value: code.id,
                        display: `${code.code} - ${prettify(code.description)}`,
                    }))
                    .sortBy('display')
                    .value();

                return {
                    codeTypeId: codeSource.codeTypeId, // unique id
                    label,
                    options,
                    required: _.get(codeMapping, 'requiresMapping'),
                };
            }
        );
    }
);

// functions used in action creators, call with `(state)(...args)`
export const attributeByIdSelector = createSelector(attributesSelector, (attributes) => (id) =>
    attributes[id]
);

export const codeMappingsByAttributeTypeSelector = createSelector(
    codeMappingsSelector,
    (codeMappings) => (type) => codeMappings[type]
);

// code links for an existing attribute
export const codeLinksByAttributeIdSelector = createSelector(
    [attributeByIdSelector, attributeTypesSelector, codeMappingsSelector],
    (attributeById, types, codeMappings) =>
        _.memoize((attributeId) => {
            const attribute = attributeById(attributeId);

            if (!attribute) {
                return [];
            }

            const type = types[attribute.attributeType];

            return helpers.formatCodeLinks({
                attribute,
                type,
                codeMappings: codeMappings[type.attributeType],
            });
        })
);

// code links for a new attribute, as its attribute type selection (which the
// user can change) determines its code links
export const codeLinksByAttributeTypeSelector = createSelector(attributeTypesSelector, (types) =>
    _.memoize((type, typeCodeMappings) =>
        helpers.formatCodeLinks({
            type: types[type],
            codeMappings: typeCodeMappings,
        })
    )
);

export const historiesForAttributeTypeSelector = createSelector(
    uiSelector,
    formatAttributeByIdSelector,
    formatUserByIdSelector,
    getAttributeByIdSelector,
    currentDepartmentDateFormatsSelector,
    (ui, formatAttributeById, userById, attributeById, dateTimeFormats) => (attributeType) => {
        const histories = ui.histories[attributeType];

        const displayDate = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDate);
            }
            return '';
        };

        const displayDateTime = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDateTime);
            }
            return '';
        };

        const args = {
            formatAttributeById,
            userById,
            displayDate,
            displayDateTime,
            attributeById,
        };

        return _.map(_.sortBy(histories, 'timestampUtc'), (history) => {
            const processed = processHistoryEvent(history, args);
            const newObj = {
                ...processed,
                user: userById(processed.changedBy),
                changes: _.map(processed.changeSet, (changeSet) =>
                    processChangeSet(changeSet, args, processed)
                ),
            };

            return newObj;
        }).reverse();
    }
);

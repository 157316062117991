import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { Spacer } from 'arc';
import { Form, lifecycleOptions } from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import { SimpleLoading } from '../../../legacy-redux/components/core/Loading';
import {
    disableComplianceExportFormControlsSelector,
    uiDisableComplianceExportFormControls,
} from '../state/ui';
import useUpdateFormNotMatchingErrorsState from '../hooks/useUpdateFormNotMatchingErrorsState';
import testIds from '../../../core/testIds';
import { MFTCheckbox } from '../../core/forms/components/checkboxes/Checkbox';
import { useFormGetter } from '../../core/forms/hooks/useFormGetter';

import { useComplianceExportContext } from '../contexts/ComplianceExportContext';
import useSubmissionType from '../hooks/useIsExternalSubmission';
import { useGenerateResbumissionReportsAndErrors } from '../hooks/useGenerateResubmissionReportsAndErrors';
import { ComplianceTypeProps } from '../types';

import ComplianceResubmissionForm, {
    complianceResubmissionFormConfiguration,
} from './ComplianceResubmissionForm';
import _ComplianceErrorsCounts from './ComplianceErrorsCounts';
import ComplianceResubmissionResults from './ComplianceResubmissionResults';
import ComplianceErrorNotification from './ComplianceErrorNotification';
import ComplianceSectionHeader from './ComplianceSectionHeader';
import ComplianceResubmissionExportDownloadButton from './ComplianceResubmissionExportDownloadButton';

const strings = componentStrings.compliance.ComplianceResubmission;
const labels = componentStrings.compliance.ComplianceResubmissionForm.labels;

const ContentWrapper = styled.div`
    background: ${(props) => props.theme.colors.white};
    box-sizing: border-box;
    min-height: 100%;
    padding: 10px;
`;

const TopSectionWrapper = styled.div`
    margin: 30px 0;
    display: flex;
    align-items: flex-start;
`;
const ErrorsAndExportButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;
`;

const ComplianceErrorsCounts = styled(_ComplianceErrorsCounts)`
    align-self: center;
`;

const StyledComplianceExportDownloadButton = styled(ComplianceResubmissionExportDownloadButton)`
    width: unset;
`;

const ComplianceResubmission: React.FC<ComplianceTypeProps> = () => {
    const { currentAgencyId, complianceExportType } = useComplianceExportContext();
    const disableFormControls = useSelector(disableComplianceExportFormControlsSelector);
    const { isNibrs } = useSubmissionType(complianceExportType);
    const supportsErrors = complianceExportType?.supportsErrors;
    const [, forceUpdate] = useReducer(() => ({}), {});
    const dispatch = useDispatch();
    const {
        generateReportsAndErrors,
        agencyName,
        errorSummary,
        reportsLoading,
        errorsLoading,
        resetErrors,
        nibrsErrorCounts,
        reports,
        onFetchErrorsSuccess,
    } = useGenerateResbumissionReportsAndErrors();

    const { updateFormNotMatchingErrorsState } = useUpdateFormNotMatchingErrorsState(
        !!supportsErrors,
        resetErrors
    );
    const errorMessage = reportsLoading?.errorMessage || errorsLoading?.errorMessage;
    const isLoading = reportsLoading?.isLoading || errorsLoading?.isLoading;
    const downloadDisabled = isLoading || isEmpty(reports);

    const { getForm } = useFormGetter();

    const offenseDisplayName = useOffenseFieldName();

    useEffect(() => {
        const form = getForm(formClientEnum.COMPLIANCE_RESUBMISSION);
        if (!form) {
            return;
        }
        const lastAgencyId = form.get('agencyId');
        if (lastAgencyId !== currentAgencyId) {
            updateFormNotMatchingErrorsState();
            generateReportsAndErrors();
        }
    }, [currentAgencyId, generateReportsAndErrors, getForm, updateFormNotMatchingErrorsState]);

    // disable form controls while errors loading
    useEffect(() => {
        if (errorsLoading.isLoading && !disableFormControls) {
            dispatch(uiDisableComplianceExportFormControls(true));
        } else if (!errorsLoading.isLoading && disableFormControls) {
            dispatch(uiDisableComplianceExportFormControls(false));
        }
    }, [disableFormControls, dispatch, errorsLoading.isLoading]);

    if (!complianceExportType) {
        return null;
    }

    return (
        <ContentWrapper>
            <FeatureFlagged
                flag="RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED"
                fallback={
                    <TopSectionWrapper>
                        <ComplianceResubmissionForm
                            complianceExportType={complianceExportType}
                            disabled={isLoading || disableFormControls}
                            isLoading={isLoading}
                            forceUpdate={forceUpdate}
                            isNibrs={!!isNibrs}
                            onGenerateReportsAndErrors={generateReportsAndErrors}
                            updateFormNotMatchingErrorsState={updateFormNotMatchingErrorsState}
                        />
                    </TopSectionWrapper>
                }
            />
            {isLoading && <SimpleLoading />}
            {!isLoading && (
                <>
                    {agencyName && (
                        <ComplianceSectionHeader>
                            {strings.header(agencyName)}
                        </ComplianceSectionHeader>
                    )}
                    <ErrorsAndExportButtonWrapper>
                        {supportsErrors && (
                            <ComplianceErrorsCounts
                                data={nibrsErrorCounts}
                                testId={testIds.COMPLIANCE_DASHBOARD_GENERATE_ERRORS_SUMMARY}
                            />
                        )}
                        <FeatureFlagged
                            flag="RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED"
                            fallback={<Spacer flex="0 1 19px" />}
                        >
                            <Form
                                configuration={complianceResubmissionFormConfiguration}
                                lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                                name={formClientEnum.COMPLIANCE_RESUBMISSION}
                                render={() => (
                                    <>
                                        {isNibrs && (
                                            <FeatureFlagged flag="NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX_ENABLED">
                                                <Spacer flex="0 1 56px" />

                                                <MFTCheckbox
                                                    disabled={isLoading || disableFormControls}
                                                    testId={
                                                        testIds.COMPLIANCE_DASHBOARD_NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX
                                                    }
                                                    helpText={labels.exportZeroReportSegmentHelpText(
                                                        offenseDisplayName
                                                    )}
                                                    label={labels.exportZeroReportSegmentCheckbox}
                                                    onChange={updateFormNotMatchingErrorsState}
                                                    path="exportZeroReportSegment"
                                                />
                                            </FeatureFlagged>
                                        )}
                                    </>
                                )}
                            />
                        </FeatureFlagged>
                        <StyledComplianceExportDownloadButton
                            disabled={downloadDisabled}
                            complianceExportType={complianceExportType}
                            onFetchErrorsSuccess={onFetchErrorsSuccess}
                            isResubmission={true}
                        />
                    </ErrorsAndExportButtonWrapper>
                </>
            )}
            {!isLoading && errorMessage && (
                <ComplianceErrorNotification>{errorMessage}</ComplianceErrorNotification>
            )}
            {!isLoading && (
                <ComplianceResubmissionResults
                    supportsErrors={!!supportsErrors}
                    errorSummary={errorSummary}
                    errorsLoading={errorsLoading}
                    reportsLoading={reportsLoading}
                    complianceResubmissionStatusViews={reports}
                />
            )}
        </ContentWrapper>
    );
};

export default ComplianceResubmission;

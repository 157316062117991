// TODO move to /item-split/

import styled from 'styled-components';

export const PropertyStatusLabel = styled.div`
    text-transform: uppercase;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.lightGrey};
    margin-bottom: 10px;
    border-bottom: thin solid ${(props) => props.theme.colors.lightGrey};
`;

import * as yup from 'yup';
import {
    Form,
    createField,
    createFormConfiguration,
    formEvents,
    InferFormDataShape,
} from 'markformythree';
import { EntityTypeEnumType, SubmissionMessage } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../core/validation/yupMftValidation';

const strings = componentStrings.validation;

const validationSchema = yup.object().shape({
    messageTypeId: yup.number().required(strings.requiredError).typeError(strings.requiredError),
});

export const GENERIC_SUBMISSION_CREATION_FORM_NAME = 'GENERIC_SUBMISSION_CREATION_FORM';

export const formConfiguration = createFormConfiguration({
    messageTypeId: createField<number>({}),
    attachmentIds: createField<number[]>({}),
});

type CreateGenericSubmissionFormConfigurationT = typeof formConfiguration;

export type CreateGenericSubmissionFormModelT = InferFormDataShape<CreateGenericSubmissionFormConfigurationT>;

export const onValidate: Form['props']['onValidate'] = ({ formState }) =>
    validationSchema
        .validate(formState.model, { abortEarly: false })
        .then((validationResult) =>
            convertYupSuccessShapeToMFTSuccessShape(validationResult, formState.ui.$form)
        )
        .catch((validationErrors) =>
            convertYupErrorShapeToMFTErrorShape(validationErrors, formState.ui.$form)
        );

export const validationEvents = [
    {
        eventType: formEvents.FORM_SUBMIT,
    },
    {
        eventType: formEvents.INPUT_CHANGE,
    },
];

export const convertFromFormModel = ({
    formModel,
    entityId,
    entityType,
}: {
    formModel: CreateGenericSubmissionFormModelT;
    entityId: number;
    entityType: EntityTypeEnumType;
}): Partial<SubmissionMessage> => {
    return {
        entityId,
        entityType,
        messageTypeId: formModel.messageTypeId,
        attachmentIds: formModel.attachmentIds,
    };
};

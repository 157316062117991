export const getCorrectedResponseRotationList = (requestRotationList, responseRotationList) => {
    const { rotationListAreas: requestRotationListAreas } = requestRotationList;
    const { rotationListAreas: responseRotationListAreas } = responseRotationList;

    if (
        !requestRotationListAreas ||
        !responseRotationListAreas ||
        requestRotationListAreas.length !== responseRotationListAreas.length
    ) {
        return responseRotationList;
    }

    const sortedRotationListAreas = requestRotationListAreas.map((requestRotationListArea) =>
        responseRotationListAreas.find(
            (responseRotationListArea) =>
                responseRotationListArea.name === requestRotationListArea.name
        )
    );

    return {
        ...responseRotationList,
        rotationListAreas: sortedRotationListAreas,
    };
};

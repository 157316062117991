import {
    cloneDeep,
    debounce,
    forEach,
    get,
    map,
    mapValues,
    noop,
    set,
    some,
    compact,
    omit,
    isFunction,
} from 'lodash';
import invariant from 'invariant';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as rrfActions } from 'react-redux-form';
import {
    compose,
    getContext,
    mapProps,
    setDisplayName,
    setPropTypes,
    withContext,
    withHandlers,
    withPropsOnChange,
} from 'recompose';
import { ruleTypes } from 'arbiter-mark43';
import { fieldDetailsByFieldNameSelector } from '../core/domain/field-details/state/data';
import { fieldConfigurationByFieldNameSelector } from '../core/domain/field-configurations/state/data';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '../core/domain/field-configuration-contexts/state/data';
import { FIELD_IS_HIDDEN } from '../core/arbiter-utils/produceRuleEffectsRRF';
import { renderOnlyIf } from './reactHelpers';

function joinTruthyValues(values = [], joinWith = ', ') {
    return compact(values).join(joinWith);
}

function createReactReduxFormHelpers({
    baseFormPath,
    validationRunnerAction,
    actions = rrfActions,
}) {
    /**
     * Combine the given path segments into a form model state path.
     * @param  {boolean}  full Whether to prefix the resulting path as a full state
     *   path.
     * @param  {string[]} ...pathSegments
     * @return {string}
     */
    function joinFormModelPath(full, ...pathSegments) {
        const formModelPathPrefix = full ? `${baseFormPath}.` : '';
        return `${formModelPathPrefix}${joinTruthyValues(pathSegments, '.')}`;
    }

    /**
     * Build a string that points to an NItem within form model state. The path is
     *   `.0` instead of `[0]` due to
     *   https://github.com/davidkpiano/react-redux-form/issues/292
     * @param  {string} path
     * @param  {number} index
     * @return {string}
     */
    function joinNItemModelPath(path, index) {
        return `${path}.${index}`;
    }

    /**
     * Return a path that is relative to the form path.
     * @param  {string} path Must not already be a full path.
     * @return {string}
     */
    function parseRelativeFormModelPath(path) {
        const relativePath = path.replace(/^[^.]+\./, '');
        return relativePath !== path ? relativePath : '';
    }

    const nItemSegmentPattern = /\.\d+/g;

    /**
     * Parse the properties for the field at the specified path. Any NItem
     *   segments in the path get ignored in order for the correct properties to
     *   be found, e.g., `a.b.2.c.d.0.e` becomes `a.b.c.d.e`.
     * @param  {Object} validators Validators from a form module.
     * @param  {string} pathPrefix
     * @param  {string} path
     * @return {Object|undefined}
     */
    function parseFieldPropertiesForPath(properties, pathPrefix, path) {
        path = joinFormModelPath(false, pathPrefix, path);
        const relativePath = parseRelativeFormModelPath(path);
        return get(properties, relativePath.replace(nItemSegmentPattern, ''));
    }

    /**
     * Wrap the given form component (which will be React Redux Form's `<Form>`
     *   component) to suit our needs.
     * @param  {Component} Form
     * @return {Component}
     */
    const connectRRF = compose(
        setDisplayName('RRF'),
        // these props get spread in from a form module
        setPropTypes({
            formName: PropTypes.string,
            context: PropTypes.string,
            path: PropTypes.string,
            validators: PropTypes.object,
            asyncValidators: PropTypes.object,
            validationContext: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
            selectors: PropTypes.object,
            fieldNames: PropTypes.object,
        }),
        // provide child fieldsets/inputs with context of this form
        withContext(
            {
                formName: PropTypes.string,
                context: PropTypes.string,
                pathPrefix: PropTypes.string,
                validators: PropTypes.object,
                asyncValidators: PropTypes.object,
                validationContext: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
                selectors: PropTypes.object,
                fieldNames: PropTypes.object,
                modern: PropTypes.bool,
                actionCreators: PropTypes.object,
            },
            ({
                formName,
                context,
                path,
                validators,
                asyncValidators,
                validationContext,
                selectors,
                fieldNames,
                modern,
                actionCreators,
            }) => ({
                formName,
                context,
                pathPrefix: path,
                validators,
                asyncValidators,
                validationContext,
                selectors,
                fieldNames,
                modern,
                actionCreators,
            })
        ),
        // provide the RRF `Form` component with the props it needs in order to
        // work, excluding all the other props that we put in context
        mapProps(({ path, validators, className, children }) => ({
            model: joinFormModelPath(true, path),
            validators: get(validators, '') ? { '': validators[''] } : undefined,
            className,
            children,
        }))
    );

    /**
     * Connect any component to the field state (both from form state and field configurations) for a
     *   given form field (identified by the provided 'path' property). connectRRFFieldState does NOT
     *   augment the relevant context's `pathPrefix`. This responsibility is delegated to any consumer
     *   of connectRRFFieldState so as to increase the reusability of this component.
     * @param {Component}
     * @return {Component}
     */
    const connectRRFFieldState = compose(
        setDisplayName('RRFFieldState'),
        getContext({
            context: PropTypes.string,
            modern: PropTypes.bool,
            fieldNames: PropTypes.object,
            pathPrefix: PropTypes.string,
            selectors: PropTypes.object,
        }),
        connect((state, ownProps) => {
            const fieldUi = ownProps.selectors.formUiByPathSelector(state)(
                joinFormModelPath(false, ownProps.pathPrefix, ownProps.path)
            );
            const fieldModel = ownProps.selectors.formModelByPathSelector(state)(
                joinFormModelPath(false, ownProps.pathPrefix, ownProps.path)
            );
            const fieldName = parseFieldPropertiesForPath(
                ownProps.fieldNames,
                ownProps.pathPrefix,
                ownProps.path
            );
            let fieldDetail;
            let fieldConfiguration;
            let fieldConfigurationContext;
            let isHidden;
            if (fieldName) {
                fieldDetail = fieldDetailsByFieldNameSelector(state)[fieldName];
                fieldConfiguration = fieldConfigurationByFieldNameSelector(state)[fieldName];

                if (ownProps.modern) {
                    fieldConfigurationContext =
                        fieldConfigurationContextByContextAndFieldNameSelector(state)(
                            ownProps.context,
                            fieldName
                        );
                    isHidden =
                        (fieldUi && (fieldUi.$form || fieldUi).errors[FIELD_IS_HIDDEN] === true) ||
                        fieldConfigurationContext?.isStaticallyHidden;
                }
            } else {
                isHidden = false;
            }

            return {
                fieldModel,
                fieldUi,
                fieldDetail,
                fieldConfiguration,
                fieldConfigurationContext,
                // isHidden can be computed from whether a field is hidden be the result of a rule, or from a property
                // on a field configuration context - this property is computed here for convenience of consumers
                isHidden,
                // fieldName is surfaced for convenience to consumers
                fieldName,
            };
        })
    );

    function WithFieldStateBase(props) {
        invariant(
            typeof props.children === 'function',
            'WithFieldState must be given a function as props.children'
        );
        return props.children(props);
    }

    const WithFieldState = connectRRFFieldState(WithFieldStateBase);

    /**
     * Connect the given fieldset component to React Redux Form.
     * @param  {Component} Fieldset
     * @return {Component}
     */
    const connectRRFFieldset = compose(
        setDisplayName('RRFFieldset'),
        setPropTypes({
            path: PropTypes.string,
        }),
        getContext({
            pathPrefix: PropTypes.string,
        }),
        // provide child fieldsets/inputs with context of this fieldset
        withContext(
            {
                pathPrefix: PropTypes.string,
            },
            ({ pathPrefix, path }) => ({
                pathPrefix: joinFormModelPath(false, pathPrefix, path),
            })
        )
    );

    /**
     * Connect the given NItems component to React Redux Form.
     * @param  {Component} NItems
     * @return {Component}
     */
    const connectRRFNItems = compose(
        setDisplayName('RRFNItems'),
        setPropTypes({
            path: PropTypes.string,
        }),
        getContext({
            pathPrefix: PropTypes.string,
            selectors: PropTypes.object,
            modern: PropTypes.bool,
            actionCreators: PropTypes.object,
        }),
        connectRRFFieldState,
        renderOnlyIf((props) => !props.isHidden),
        // provide child fieldsets/inputs with context of this NItems
        withContext(
            {
                pathPrefix: PropTypes.string,
            },
            ({ pathPrefix, path }) => ({
                pathPrefix: joinFormModelPath(false, pathPrefix, path),
            })
        ),
        withPropsOnChange(['pathPrefix', 'path'], ({ pathPrefix, path }) => ({
            path: joinFormModelPath(false, pathPrefix, path),
            modelPath: joinFormModelPath(true, pathPrefix, path),
        })),
        connect(
            (state, { path, selectors }) => ({
                items: selectors.formModelByPathSelector(state)(path) || [],
                formUi: selectors.formUiSelector(state),
            }),
            (dispatch, { actionCreators, modern, childFieldKeys }) => ({
                add: (modelPath, index) => {
                    dispatch(actions.push(modelPath, {}));
                    if (childFieldKeys && typeof index !== 'undefined') {
                        forEach(childFieldKeys, (key) =>
                            dispatch(actions.change(`${modelPath}.${index}.${key}`), undefined)
                        );
                    }
                    if (modern) {
                        dispatch(actionCreators.throttledValidate({ ruleTypes: [ruleTypes.HIDE] }));
                    }
                },
                remove: (modelPath, index) => {
                    dispatch(actions.remove(modelPath, index));
                    if (modern) {
                        dispatch(actionCreators.throttledValidate({ ruleTypes: [ruleTypes.HIDE] }));
                    }
                },
            })
        ),
        withHandlers({
            addItem:
                ({ modelPath, add }) =>
                (index) =>
                    add(modelPath, index),
            removeItem:
                ({ modelPath, remove, onRemoveItem }) =>
                (index) =>
                () => {
                    remove(modelPath, index);
                    if (onRemoveItem && isFunction(onRemoveItem)) {
                        onRemoveItem();
                    }
                },
        })
    );

    /**
     * Connect the given NItem component (a single row) to React Redux Form.
     * @param  {Component} NItem
     * @return {Component}
     */
    const connectRRFNItem = compose(
        setDisplayName('RRFNItem'),
        setPropTypes({
            index: PropTypes.number.isRequired,
        }),
        getContext({
            pathPrefix: PropTypes.string,
        }),
        withContext(
            {
                pathPrefix: PropTypes.string,
            },
            ({ pathPrefix, index }) => ({
                pathPrefix: joinNItemModelPath(pathPrefix, index),
            })
        )
    );

    /**
     * Connect the given form input component to React Redux Form. The input
     *   component must handle the following props: `value`, `onChange`, `onFocus`,
     *   `onBlur`, `error`, `touched`. If validators are passed in to the returned
     *   component, field validation gets done on blur.
     * @param  {Component} Input
     * @return {Component}
     */
    const connectRRFInput = compose(
        setDisplayName('RRFInput'),
        setPropTypes({
            path: PropTypes.string.isRequired,
            onChange: PropTypes.func,
            onFocus: PropTypes.func,
            onBlur: PropTypes.func,
        }),
        getContext({
            pathPrefix: PropTypes.string,
            validators: PropTypes.object,
            asyncValidators: PropTypes.object,
            validationContext: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
            selectors: PropTypes.object,
            fieldNames: PropTypes.object,
            modern: PropTypes.bool,
            actionCreators: PropTypes.object,
            context: PropTypes.string,
        }),
        // normalize the path to the form model in state since the input may be
        // inside a form/fieldset that provides the pathPrefix context
        connectRRFFieldState,
        renderOnlyIf((props) => !props.isHidden),
        withPropsOnChange(
            ['pathPrefix', 'path', 'validators', 'asyncValidators', 'fieldNames'],
            ({ pathPrefix, path, validators, asyncValidators }) => ({
                path: joinFormModelPath(false, pathPrefix, path),
                modelPath: joinFormModelPath(true, pathPrefix, path),
                // change the validator props from all validators in the form to
                // only the ones that apply to this field
                validators: parseFieldPropertiesForPath(validators, pathPrefix, path),
                asyncValidators: parseFieldPropertiesForPath(asyncValidators, pathPrefix, path),
            })
        ),
        // setup our action creators, and subscribe to the value in the form path
        connect(
            (
                state,
                {
                    path,
                    selectors,
                    label,
                    placeholder,
                    helpText,
                    modern,
                    fieldModel,
                    fieldConfiguration,
                    fieldConfigurationContext,
                    isHidden,
                }
            ) => {
                // if there's no provided label, check if there's a field name display value
                const useLabel = label || get(fieldConfiguration, 'displayName') || undefined;
                // TODO this should just read from fieldUi but we need to be backwards compatible
                // with older forms where a field would have a string error rather than an object
                // this selector returns a string or undefined
                const error = selectors.formErrorMessageByPathSelector(state)(path);
                return {
                    modern,
                    ...(useLabel ? { label: useLabel } : {}),
                    value: fieldModel,
                    touched: selectors.formPathIsTouchedSelector(state)(path),
                    pristine: selectors.formPathIsPristineSelector(state)(path),
                    isHidden,
                    error,
                    placeholder: placeholder || get(fieldConfigurationContext, 'placeholderText'),
                    helpText: helpText || get(fieldConfigurationContext, 'helpText'),
                    formModel: selectors.formModelSelector(state),
                };
            },
            (dispatch, { actionCreators }) => ({
                change: (modelPath, value) => dispatch(actions.change(modelPath, value)),
                focus: (modelPath) => dispatch(actions.focus(modelPath)),
                blur: (modelPath) => dispatch(actions.blur(modelPath)),
                setDirty: (modelPath) => dispatch(actions.setDirty(modelPath)),
                setTouched: (modelPath) => dispatch(actions.setTouched(modelPath)),
                setPristine: (modelPath) => dispatch(actions.setPristine(modelPath)),
                validateModern: (runConfiguration) => {
                    dispatch(actionCreators.throttledValidate(runConfiguration));
                },
                validate: (
                    path,
                    modelPath,
                    validators,
                    asyncValidators,
                    validationContext,
                    formModel,
                    fieldName
                ) => {
                    const fieldPath = parseRelativeFormModelPath(path);
                    const errorMessage =
                        fieldName && validationRunnerAction
                            ? get(
                                  dispatch(
                                      validationRunnerAction(
                                          validationContext,
                                          formModel,
                                          fieldName,
                                          modelPath,
                                          fieldPath
                                      )
                                  ),
                                  'field'
                              )
                            : null;
                    // run db rules first, then client side validators
                    if (errorMessage) {
                        dispatch(actions.setErrors(modelPath, errorMessage));
                    } else {
                        dispatch(actions.setValidity(modelPath, true));
                        if (validators) {
                            dispatch(actions.validate(modelPath, validators));
                        }
                        if (asyncValidators) {
                            dispatch(actions.asyncSetValidity(modelPath, asyncValidators));
                        }
                    }
                },
            })
        ),
        // setup our handlers to dispatch actions
        withHandlers({
            onChange: ({
                path,
                modelPath,
                pristine,
                validate,
                validateModern,
                validators,
                asyncValidators,
                validationContext,
                formModel,
                fieldName,
                change,
                onChange,
                modern,
                ignoreValidation,
            }) => {
                // debounce is here to try not run the expensive cloneDeep on every keystroke
                // in order to make the validation error show up without a delay for dropdowns,
                // we added {leading: true, trailing: false} but this makes the debounce not debouncy...
                const debouncedValidate = modern
                    ? validateModern
                    : debounce(
                          (value) => {
                              const pathToProperty = parseRelativeFormModelPath(path);
                              const newFormModel = set(cloneDeep(formModel), pathToProperty, value);
                              validate(
                                  path,
                                  modelPath,
                                  validators,
                                  asyncValidators,
                                  validationContext,
                                  newFormModel,
                                  fieldName
                              );
                          },
                          200,
                          { leading: true, trailing: false }
                      );

                return (value) => {
                    change(modelPath, value); // dispatch react-redux-form actions
                    if (!ignoreValidation) {
                        if (modern) {
                            if (!pristine) {
                                debouncedValidate({ fieldName });
                            } else {
                                debouncedValidate({ ruleTypes: [ruleTypes.HIDE] });
                            }
                        } else {
                            if (!pristine) {
                                debouncedValidate(value);
                            }
                        }
                    }
                    if (onChange) {
                        onChange(value); // call custom handler
                    }
                };
            },
            onFocus:
                ({ modelPath, focus, onFocus }) =>
                (value) => {
                    focus(modelPath); // dispatch react-redux-form action
                    if (onFocus) {
                        onFocus(value); // call custom handler
                    }
                },
            onBlur:
                ({
                    path,
                    modelPath,
                    blur,
                    onBlur,
                    setTouched,
                    setDirty,
                    validate,
                    validators,
                    asyncValidators,
                    validationContext,
                    fieldName,
                    formModel,
                    validateModern,
                    modern,
                }) =>
                (value) => {
                    blur(modelPath); // dispatch react-redux-form actions
                    setDirty(modelPath);
                    setTouched(modelPath);
                    if (modern) {
                        validateModern({ fieldName });
                    } else {
                        validate(
                            path,
                            modelPath,
                            validators,
                            asyncValidators,
                            validationContext,
                            formModel,
                            fieldName
                        );
                    }
                    if (onBlur) {
                        onBlur(value); // call custom handler
                    }
                },
        }),
        // formModel causes uncecessary rerenders since if one input changes
        // A new form model will pass down to every input in the form
        mapProps((props) =>
            omit(props, [
                'formModel',
                'change',
                'focus',
                'blur',
                'setDirty',
                'setTouched',
                'setPristine',
                'validate',
            ])
        )
    );

    /**
     * Connect the given multi-field input component to React Redux Form. The input
     *   component must handle the following props: `fields`, `onChange`, `onFocus`,
     *   `onBlur`, `error`, `touched`. If validators are passed in to the returned
     *   component, field validation gets done on blur.
     * @param  {Component} Input
     * @return {Component}
     */
    const connectRRFMultiFieldInput = compose(
        setDisplayName('RRFMultiFieldInput'),
        setPropTypes({
            paths: PropTypes.object.isRequired,
            onChange: PropTypes.func,
            onFocus: PropTypes.func,
            onBlur: PropTypes.func,
        }),
        getContext({
            pathPrefix: PropTypes.string,
            validators: PropTypes.object,
            asyncValidators: PropTypes.object,
            selectors: PropTypes.object,
        }),
        withPropsOnChange(
            ['pathPrefix', 'paths', 'validators', 'asyncValidators'],
            ({ pathPrefix, paths, validators, asyncValidators }) => ({
                fields: mapValues(paths, (path) => ({
                    // each field gets its own properties
                    path: joinFormModelPath(false, pathPrefix, path),
                    modelPath: joinFormModelPath(true, pathPrefix, path),
                    // change the validator props from all validators in the form to
                    // only the ones that apply to this field
                    validators: parseFieldPropertiesForPath(validators, pathPrefix, path),
                    asyncValidators: parseFieldPropertiesForPath(asyncValidators, pathPrefix, path),
                    onChange: noop,
                })),
            })
        ),
        connect(
            (state, { fields, selectors }) => {
                fields = mapValues(fields, (field) => {
                    const { path } = field;

                    return {
                        ...field,
                        value: selectors.formModelByPathSelector(state)(path),
                        touched: selectors.formPathIsTouchedSelector(state)(path),
                        error: selectors.formErrorMessageByPathSelector(state)(path),
                    };
                });

                return {
                    fields,
                    touched: some(fields, 'touched'),
                    error: joinTruthyValues(map(fields, 'error')),
                };
            },
            (dispatch) => ({
                change: (modelPath, value) => dispatch(actions.change(modelPath, value)),
                focus: (modelPath) => dispatch(actions.focus(modelPath)),
                blur: (modelPath) => dispatch(actions.blur(modelPath)),
                setDirty: (modelPath) => dispatch(actions.setDirty(modelPath)),
                setTouched: (modelPath) => dispatch(actions.setTouched(modelPath)),
                validate: (modelPath, validators, asyncValidators) => {
                    if (validators) {
                        dispatch(actions.validate(modelPath, validators));
                    }
                    if (asyncValidators) {
                        dispatch(actions.asyncSetValidity(modelPath, asyncValidators));
                    }
                },
            })
        ),
        // setup our handlers to dispatch actions
        withHandlers({
            onChange:
                ({ change, onChange, setDirty, fields }) =>
                (fieldValues) => {
                    forEach(fields, ({ modelPath }, key) => {
                        // dispatch react-redux-form actions
                        change(modelPath, fieldValues[key]);
                        setDirty(modelPath);
                    });
                    if (onChange) {
                        onChange(fieldValues); // call custom handler
                    }
                },
            onFocus:
                ({ focus, onFocus, fields }) =>
                (fieldValues) => {
                    forEach(fields, ({ modelPath }) => {
                        // dispatch react-redux-form actions
                        focus(modelPath);
                    });
                    if (onFocus) {
                        onFocus(fieldValues); // call custom handler
                    }
                },
            onBlur:
                ({ blur, onBlur, setTouched, validate, fields }) =>
                (fieldValues) => {
                    forEach(fields, ({ modelPath, validators, asyncValidators }) => {
                        // dispatch react-redux-form actions
                        blur(modelPath);
                        setTouched(modelPath);
                        validate(modelPath, validators, asyncValidators);
                    });
                    if (onBlur) {
                        onBlur(fieldValues); // call custom handler
                    }
                },
        })
    );

    /**
     * Connect the given component to its form actions with a prop called
     *   `formActions`.
     * @param  {Component} Component
     * @return {Component}
     */
    const withRRFActions = compose(
        setDisplayName('WithRRFActions'),
        getContext({
            pathPrefix: PropTypes.string,
        }),
        connect(null, (dispatch, { pathPrefix }) => ({
            formActions: {
                change: (path, value) => {
                    const modelPath = joinFormModelPath(true, pathPrefix, path);
                    dispatch(actions.change(modelPath, value));
                },
                reset: (path) => {
                    const modelPath = joinFormModelPath(true, pathPrefix, path);
                    dispatch(actions.reset(modelPath));
                },
                // reset form ui state without affecting form model state
                setInitial: (path) => {
                    const modelPath = joinFormModelPath(true, pathPrefix, path);
                    dispatch(actions.setInitial(modelPath));
                },
                focus: (path) => {
                    const modelPath = joinFormModelPath(true, pathPrefix, path);
                    dispatch(actions.focus(modelPath));
                },
                blur: (path) => {
                    const modelPath = joinFormModelPath(true, pathPrefix, path);
                    dispatch(actions.blur(modelPath));
                },
                setDirty: (path) => {
                    const modelPath = joinFormModelPath(true, pathPrefix, path);
                    dispatch(actions.setDirty(modelPath));
                },
                setTouched: (path) => {
                    const modelPath = joinFormModelPath(true, pathPrefix, path);
                    dispatch(actions.setTouched(modelPath));
                },
                setValidity: (path, validity, options) => {
                    const modelPath = joinFormModelPath(true, pathPrefix, path);
                    dispatch(actions.setValidity(modelPath, validity, options));
                },
                validate: (path, validators, asyncValidators) => {
                    const modelPath = joinFormModelPath(true, pathPrefix, path);

                    // find only the validators that apply to this path
                    validators = parseFieldPropertiesForPath(validators, pathPrefix, path);
                    asyncValidators = parseFieldPropertiesForPath(
                        asyncValidators,
                        pathPrefix,
                        path
                    );

                    if (validators) {
                        dispatch(actions.validate(modelPath, validators));
                    }
                    if (asyncValidators) {
                        dispatch(actions.asyncSetValidity(modelPath, asyncValidators));
                    }
                },
            },
        }))
    );

    /**
     * Add field value props to the given component.
     * @param  {Object} paths
     * @return {function}
     */
    function withRRFFieldValues(paths) {
        return compose(
            setDisplayName('withRRFFieldValues'),
            getContext({
                pathPrefix: PropTypes.string,
                selectors: PropTypes.object,
            }),
            connect((state, { pathPrefix, selectors }) => {
                return mapValues(paths, (path) => {
                    path = joinFormModelPath(false, pathPrefix, path);
                    return selectors.formModelByPathSelector(state)(path);
                });
            })
        );
    }

    /**
     * Inject the form model into the given component.
     * @param  {Object} paths
     * @return {function}
     */
    const withRRFModelSelector = compose(
        setDisplayName('withRRFModelSelector'),
        getContext({
            selectors: PropTypes.object,
        }),
        connect((state, { selectors }) => {
            return { formModelSelector: selectors ? selectors.formModelSelector : noop };
        })
    );

    return {
        joinFormModelPath,
        joinNItemModelPath,
        parseRelativeFormModelPath,
        connectRRF,
        connectRRFFieldset,
        connectRRFNItems,
        connectRRFNItem,
        connectRRFInput,
        connectRRFMultiFieldInput,
        connectRRFFieldState,
        withRRFActions,
        withRRFFieldValues,
        withRRFModelSelector,
        WithFieldState,
    };
}

export default createReactReduxFormHelpers;

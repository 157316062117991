import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withState } from 'recompose';
import { Form, lifecycleOptions } from 'markformythree';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import testIds from '../../../../core/testIds';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import ToggleButton from '../../../../legacy-redux/components/core/ToggleButton';
import Page from '../../../core/components/Page';
import ScrollableUnderSubheader, {
    scrollToTop,
} from '../../../core/components/ScrollableUnderSubheader';
import Subheader from '../../../core/components/Subheader';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import SavedSearchModal from '../../../search/saved-search/components/SavedSearchModal';
import { MFTAsyncText } from '../../../core/forms/components/AsyncText';
import { filterTaskDashboard, tasksDashboardSearch, applySavedSearchFilters } from '../state/ui';
import { formConfiguration, initialState } from '../state/forms/tasksDashboardSearchForm';
import { openTaskSidePanel } from '../../core/state/ui';
import { Button as NewButton } from '../../../core/components/Button';
import { TaskDashboardSearchResultsParents } from '../../core/config';
import _TaskDashboardSearchResults from './TaskDashboardSearchResults';
import TasksDashboardSearchFilters from './TasksDashboardSearchFilters';
import TasksDashboardSearchForm from './TasksDashboardSearchForm';

const strings = componentStrings.tasks.dashboard.TasksDashboardContainer;

// Hack to get around applying styles directly to the Form
const FullWidthFormWrapper = styled.div`
    width: 100%;
    height: 100%;

    > form {
        width: 100%;
        height: 100%;
    }
`;

const Menu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const FilterText = styled(MFTAsyncText)`
    margin-right: 10px;

    && {
        margin-top: 20px;
    }
`;

const ActionsContainer = styled.div`
    display: flex;
    margin-top: 8px;

    .saved-search-button-wrapper {
        margin-top: 12px;
    }
`;

const FilterButton = styled(ToggleButton)`
    margin-top: 20px;
    padding: 1px 15px;
    line-height: 26px;
`;

const TaskDashboardSearchResults = styled(_TaskDashboardSearchResults)`
    background: ${(props) => props.theme.colors.white};
    padding: 0 20px;
`;

const TasksDashboardContainer = compose(
    withState('searchIsOpen', 'setSearchIsOpen', false),
    withState('saveSearchIsOpen', 'setSaveSearchIsOpen', false),
    withHandlers({
        onSearchToggleClick({ setSearchIsOpen }) {
            scrollToTop();
            return () => setSearchIsOpen((isOpen) => !isOpen);
        },
    }),
    connect(
        createStructuredSelector({
            formatFieldByName: formatFieldByNameSelector,
            currentQuery: tasksDashboardSearch.selectors.currentQuerySelector,
        }),
        (dispatch) => ({
            openTaskSidePanel: () => dispatch(openTaskSidePanel()),
            onSubmitForm: () =>
                dispatch(
                    filterTaskDashboard(
                        { from: 0 },
                        TaskDashboardSearchResultsParents.TASK_DASHBOARD
                    )
                ),
            applySavedSearchFilters: (formModel) => dispatch(applySavedSearchFilters(formModel)),
        })
    )
)(
    ({
        formatFieldByName,
        onSearchToggleClick,
        onSubmitForm,
        openTaskSidePanel,
        searchIsOpen,
        applySavedSearchFilters,
    }) => {
        const dispatch = useDispatch();
        useEffect(() => {
            dispatch(tasksDashboardSearch.actionCreators.resetState());
        }, [dispatch]);

        const renderForm = () => {
            return (
                <Page style={{ height: '100%' }}>
                    <Subheader title={strings.title}>
                        <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
                            <NewButton
                                isTextTransformNone
                                variant="solid"
                                leadingVisual="Add"
                                testId={testIds.NEW_TASK_BUTTON}
                                onClick={openTaskSidePanel}
                            >
                                {strings.newTask}
                            </NewButton>
                        </OnlyWithAbility>
                    </Subheader>
                    <ScrollableUnderSubheader contentClassnames={'task-dashboard-table-subheader'}>
                        <Menu>
                            <SearchContainer
                                data-test-id={testIds.TASKS_DASHBOARD_SEARCH_CONTAINER}
                            >
                                <FilterText
                                    path="query"
                                    width={272}
                                    textInputOnly={true}
                                    placeholder={strings.filterPlaceHolder(
                                        formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
                                    )}
                                    asyncAction={onSubmitForm}
                                />
                                <FilterButton
                                    className={buttonTypes.SECONDARY}
                                    active={searchIsOpen}
                                    inactiveIcon={<Icon fontSize="28px" type={iconTypes.FILTER} />}
                                    onClick={onSearchToggleClick}
                                    testId={testIds.TASKS_DASHBOARD_FILTER_BUTTON}
                                >
                                    {strings.filters}
                                </FilterButton>
                            </SearchContainer>
                            <ActionsContainer>
                                <SavedSearchModal
                                    searchModule={tasksDashboardSearch}
                                    hideSubscribeOption={true}
                                    onSavedSearchExecuteSuccess={(result) => {
                                        const { formModel } = result;
                                        applySavedSearchFilters(formModel);
                                    }}
                                />
                            </ActionsContainer>
                        </Menu>
                        {searchIsOpen && <TasksDashboardSearchForm onSubmit={onSubmitForm} />}
                        <TasksDashboardSearchFilters />
                        <TaskDashboardSearchResults onSave={onSubmitForm} />
                    </ScrollableUnderSubheader>
                </Page>
            );
        };

        return (
            <FullWidthFormWrapper>
                <Form
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    name={formClientEnum.TASKS_DASHBOARD_SEARCH_FORM}
                    configuration={formConfiguration}
                    initialState={initialState}
                    render={renderForm}
                />
            </FullWidthFormWrapper>
        );
    }
);

export default TasksDashboardContainer;

import { createSelector } from 'reselect';

import globalAttributes from '../../../../legacy-constants/globalAttributes';

import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '../../../attributes/state/data';
import { mostRecentWarrantStatusByWarrantIdSelector } from '../data';

export const isWarrantStatusOfWarrantStatusGlobalIdSelector = createSelector(
    mostRecentWarrantStatusByWarrantIdSelector,
    parentAttributeIdByAttributeIdSelector,
    (mostRecentWarrantStatusByWarrantId, parentAttributeIdByAttributeId) => ({
        warrantId,
        warrantStatusGlobalId,
    }) => {
        const warrantStatus = mostRecentWarrantStatusByWarrantId(warrantId) || {};
        const parentAttributeId = parentAttributeIdByAttributeId(warrantStatus.warrantStatusAttrId);
        return parentAttributeId === warrantStatusGlobalId;
    }
);

const buildWarrantStatusViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    isWarrantStatusOfWarrantStatusGlobalIdSelector,
    (formatAttributeById, isWarrantStatusOfWarrantStatusGlobalId) => (warrantStatus) => {
        const viewModelBuilder = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ warrantId }) => {
                    const isActive = isWarrantStatusOfWarrantStatusGlobalId({
                        warrantId,
                        warrantStatusGlobalId: globalAttributes.warrantStatusGlobal.active,
                    });
                    const isStub = isWarrantStatusOfWarrantStatusGlobalId({
                        warrantId,
                        warrantStatusGlobalId: globalAttributes.warrantStatusGlobal.stub,
                    });
                    return { isActive, isStub };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return viewModelBuilder(warrantStatus);
    }
);

export const mostRecentWarrantStatusViewModelByWarrantIdSelector = createSelector(
    mostRecentWarrantStatusByWarrantIdSelector,
    buildWarrantStatusViewModelSelector,
    (mostRecentWarrantStatusByWarrantId, buildWarrantStatusViewModel) => (warrantId) =>
        buildWarrantStatusViewModel(mostRecentWarrantStatusByWarrantId(warrantId))
);

import React, { createContext, useRef } from 'react';

export const scrollableNodeNameEnum = {
    APP_CONTENT: 'APP_CONTENT',
    SCROLLABLE_UNDER_SUBHEADER: 'SCROLLABLE_UNDER_SUBHEADER',
} as const;

type ScrollableNodes = Record<keyof typeof scrollableNodeNameEnum, React.RefObject<HTMLDivElement>>;

export const ScrollableNodesContext = createContext<Partial<ScrollableNodes>>({});

export const ScrollableNodesProvider: React.FC = ({ children }) => {
    const nodeRefs = {
        [scrollableNodeNameEnum.APP_CONTENT]: useRef<HTMLDivElement>(null),
        [scrollableNodeNameEnum.SCROLLABLE_UNDER_SUBHEADER]: useRef<HTMLDivElement>(null),
    };

    return (
        <ScrollableNodesContext.Provider value={nodeRefs}>
            {children}
        </ScrollableNodesContext.Provider>
    );
};

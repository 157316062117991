import { ComplianceGroupEnum, CountryCodeEnum, UcrCategoryEnum } from '@mark43/rms-api';
import _, { filter, get, map, mapValues, some, sortBy } from 'lodash';
import nibrsCodes from '../core/constants/nibrsCodes';

// helpers
import {
    getAdminListStatusFromStartEnd as computeDateStatus,
    formatISODateWithTimezone,
    getLocationSpecificDateTimeFormats,
} from '../core/dates/utils/dateHelpers';

// constants
import { attributeStatuses } from '../core/domain/attributes/configuration';
import componentStrings from '../core/strings/componentStrings';

const { EXPIRED } = attributeStatuses;
const strings = componentStrings.forms.select.OffenseCodeSelect;

/**
 * Return all given offense codes that are `true` for at least one of
 * the given conditional flags.
 */
export const offenseCodesWithFlags = (offenseCodes, flags = []) =>
    filter(offenseCodes, (offenseCode) => some(flags, (flag) => offenseCode[flag]));

/**
 * Get the offense code display string from an `OffenseCodeView` or a
 * raw `OffenseCode`
 *
 * @param  {Object} offenseCode
 * @param  {Object} [departmentProfile]
 * @return {string}
 */
export function formatOffenseCode(offenseCode, departmentProfile) {
    const displayName = get(offenseCode, 'displayName');
    if (
        departmentProfile?.complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name &&
        computeDateStatus(offenseCode.startDateUtc, offenseCode.endDateUtc) ===
            attributeStatuses.EXPIRED
    ) {
        const expiredDateRangeString = getDateRangeString({
            startDateUtc: offenseCode.startDateUtc,
            endDateUtc: offenseCode.endDateUtc,
            timeZone: departmentProfile.timeZone,
            format: getLocationSpecificDateTimeFormats(CountryCodeEnum.GB.name).shortDate,
        });
        return `${displayName} ${expiredDateRangeString}`;
    }
    return displayName;
}

export function formatUcrCode(code) {
    return code ? `${code.code} - ${code.name} ${code.part}` : '';
}

/**
 * Format the display string for a NIBRS offense code.
 * @param  {Object | null | undefined} code
 * @return {string}
 */
export function formatNibrsCode(code) {
    return code ? `${code.name} (${code.code})` : '';
}

/**
 * Each offense code view model contains an extra `status` property representing
 *   active, expired, or scheduled.
 * @param  {Object} codes
 * @return {Object}
 */
export function buildOffenseCodeViewModels(codes) {
    const now = new Date();

    return mapValues(codes, (code) => ({
        ...code,
        status: computeDateStatus(code.startDateUtc, code.endDateUtc, now),
    }));
}

const getDateRangeString = ({ startDateUtc, endDateUtc, timeZone, format }) => {
    const startDate = formatISODateWithTimezone(startDateUtc, timeZone, format);
    const endDate = formatISODateWithTimezone(endDateUtc, timeZone, format);
    return `(${startDate} - ${endDate})`;
};

/**
 * Format the given local offense code as a dropdown/checkbox option.
 * @param  {Object} code May be a raw offense code or a view model.
 * @return {Object}
 */
function mapOffenseCodeToOption(code, departmentProfile) {
    let expiredNoteDisplay = strings.expired;

    if (
        code.status === EXPIRED &&
        departmentProfile?.complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name
    ) {
        expiredNoteDisplay = getDateRangeString({
            startDateUtc: code.startDateUtc,
            endDateUtc: code.endDateUtc,
            timeZone: departmentProfile.timeZone,
            format: getLocationSpecificDateTimeFormats(CountryCodeEnum.GB.name).shortDate,
        });
    }

    return {
        value: code.id,
        display: formatOffenseCode(code),
        code: code.code,
        ...(code.status === EXPIRED
            ? {
                  status: code.status,
                  noteDisplay: expiredNoteDisplay,
              }
            : {}),
    };
}

/**
 * First sort offense codes then return formatted offense codes as
 * dropdown/checkbox options.
 * @param  {Object}    codes May be raw offense codes or view models.
 * @return {Object[]}
 */
export function mapOffenseCodesToOptions(codes, departmentProfile) {
    return map(sortOffenseCodeViewModels(codes), (code) =>
        mapOffenseCodeToOption(code, departmentProfile)
    );
}

/**
 * Sorts expired offense codes after active offense codes, then sorts by name.
 *
 * @param  {Object}    codes May be raw offense codes or view models.
 * @return {Object[]}
 */
function sortOffenseCodeViewModels(codes) {
    return sortBy(codes, [({ status }) => (status === EXPIRED ? 2 : 1), formatOffenseCode]);
}

/**
 * Format the given NIBRS offense code as a dropdown/checkbox option.
 * @param  {Object} code
 * @return {Object}
 */
function mapNibrsCodeToOption(code, key) {
    return {
        value: code[key],
        display: formatNibrsCode(code),
    };
}

/**
 * Format the given NIBRS offense codes as sorted dropdown/checkbox options.
 * @param  {Object} codes
 * @param  {string} key   The key of the property on NibrsOffenseCode to use as the option value.
 * @return {Object[]}
 */
export function mapNibrsCodesToOptions(codes, key = 'code') {
    return _(codes)
        .map((code) => mapNibrsCodeToOption(code, key))
        .sortBy('display')
        .value();
}

/**
 * Format the given UCR summary offense code as a dropdown/checkbox option.
 * @param  {Object} code
 * @return {Object}
 */
function mapUcrCodeToOption(code) {
    return {
        value: code.code,
        display: formatUcrCode(code),
    };
}

/**
 * Format the given UCR summary offense codes as sorted dropdown/checkbox
 *   options.
 * @param  {Object} codes
 * @return {Object[]}
 */
export function mapUcrCodesToOptions(codes) {
    return _(codes)
        .sortBy('code')
        .sortBy((code) => get(UcrCategoryEnum, `${code.category}.value`))
        .map(mapUcrCodeToOption)
        .value();
}

export function isNibrsArson(nibrsCode) {
    return nibrsCode && nibrsCode.code === nibrsCodes.arson;
}

export function isNibrsBurglary(nibrsCode) {
    return nibrsCode && nibrsCode.code === nibrsCodes.burglary;
}

/**
 * This function denormalizes properties on an OffenseCode by also including certain
 *   values on the key they would belong to on an OffenseCodeView. This is done so that
 *   helper methods which are used on OffenseCodeViews in the rms can be re-used to
 *   work for OffenseCodes in printing.
 * @param {Object} offenseCode
 * @return {Object}
 */
export function augmentOffenseCodeWithOffenseCodeView(offenseCode) {
    return {
        ...offenseCode,
        name: offenseCode.displayName,
        start: offenseCode.startDateUtc,
        end: offenseCode.endDateUtc,
        nibrsOffenseCodeId: offenseCode.nibrsOffenseCodeId,
        nibrsCode: offenseCode.nibrsCodeCode,
        ucrCode: offenseCode.ucrSummaryCodeCode,
    };
}

/**
 * This function exists because some endpoints return a minimal `ElasticOffenseCodeDetail`,
 * which contains as `displayValue` property that doesn't match the `displayName` of the actual
 * `OffenseCode` models
 */
export const convertElasticOffenseCodeDetailToOffenseCode = (elasticOffenseCodeDetail) => ({
    ...elasticOffenseCodeDetail,
    displayName: elasticOffenseCodeDetail.displayValue,
});

import { map } from 'lodash';
import getWarrantActivityStatusLinkResource from '~/client-common/core/domain/warrant-activity-status-links/resources/warrantActivityStatusLinkResource';
import {
    NEXUS_STATE_PROP as WARRANT_ACTIVITY_STATUS_LINKS_NEXUS_STATE_PROP,
    storeWarrantActivityStatusLinks,
    deleteWarrantActivityStatusLinksWhere,
} from '~/client-common/core/domain/warrant-activity-status-links/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

const LOAD_WARRANT_ACTIVITY_STATUS_LINK_START =
    'warrant-activity-status-links/LOAD_WARRANT_ACTIVITY_STATUS_LINK_START';
const LOAD_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS =
    'warrant-activity-status-links/LOAD_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS';
const LOAD_WARRANT_ACTIVITY_STATUS_LINK_FAILURE =
    'warrant-activity-status-links/LOAD_WARRANT_ACTIVITY_STATUS_LINK_FAILURE';
export const SAVE_WARRANT_ACTIVITY_STATUS_LINK_START =
    'warrant-activity-status-links/SAVE_WARRANT_ACTIVITY_STATUS_LINK_START';
export const SAVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS =
    'warrant-activity-status-links/SAVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS';
export const SAVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE =
    'warrant-activity-status-links/SAVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE';
export const REMOVE_WARRANT_ACTIVITY_STATUS_LINK_START =
    'warrant-activity-status-links/REMOVE_WARRANT_ACTIVITY_STATUS_LINK_START';
export const REMOVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS =
    'warrant-activity-status-links/REMOVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS';
export const REMOVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE =
    'warrant-activity-status-links/REMOVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE';

function loadWarrantActivityStatusLinkStart() {
    return {
        type: LOAD_WARRANT_ACTIVITY_STATUS_LINK_START,
    };
}

function loadWarrantActivityStatusLinkSuccess(warrantActivityStatusLink) {
    return {
        type: LOAD_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS,
        payload: warrantActivityStatusLink,
    };
}

function loadWarrantActivityStatusLinkFailure(errMsg) {
    return {
        type: LOAD_WARRANT_ACTIVITY_STATUS_LINK_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function saveWarrantActivityStatusLinkStart() {
    return {
        type: SAVE_WARRANT_ACTIVITY_STATUS_LINK_START,
    };
}

function saveWarrantActivityStatusLinkSuccess(warrantActivityStatusLink) {
    return {
        type: SAVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS,
        payload: warrantActivityStatusLink,
    };
}

function saveWarrantActivityStatusLinkFailure(errMsg) {
    return {
        type: SAVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function removeWarrantActivityStatusLinkStart() {
    return {
        type: REMOVE_WARRANT_ACTIVITY_STATUS_LINK_START,
    };
}

function removeWarrantActivityStatusLinkSuccess(activityTypeAttrId) {
    return {
        type: REMOVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS,
        payload: activityTypeAttrId,
    };
}

function removeWarrantActivityStatusLinkFailure(errMsg) {
    return {
        type: REMOVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function loadWarrantActivityStatusLink() {
    return function (dispatch, getState, dependencies) {
        dispatch(loadWarrantActivityStatusLinkStart());
        const resource = getWarrantActivityStatusLinkResource();

        return resource
            .getWarrantActivityStatusLinks()
            .then((warrantActivityStatusLinkAdminBundle) => {
                const {
                    warrantActivityStatusLinks,
                    attributes,
                } = warrantActivityStatusLinkAdminBundle;
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [WARRANT_ACTIVITY_STATUS_LINKS_NEXUS_STATE_PROP]: warrantActivityStatusLinks,
                            [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                                attributes,
                                convertAttributeToAttributeView
                            ),
                        },
                        { type: 'STORE_WARRANT_ACTIVITY_STATUS_LINK_ADMIN_BUNDLE' }
                    )
                );
                dispatch(loadWarrantActivityStatusLinkSuccess(warrantActivityStatusLinks));
                return warrantActivityStatusLinkAdminBundle;
            })
            .catch((err) => dispatch(loadWarrantActivityStatusLinkFailure(err.message)));
    };
}

export function saveWarrantActivityStatusLink(warrantActivityStatusLink) {
    return function (dispatch) {
        dispatch(saveWarrantActivityStatusLinkStart());
        const resource = getWarrantActivityStatusLinkResource();

        return resource
            .upsertWarrantActivityStatusLink(warrantActivityStatusLink)
            .then((warrantActivityStatusLink) => {
                dispatch(storeWarrantActivityStatusLinks(warrantActivityStatusLink));
                dispatch(saveWarrantActivityStatusLinkSuccess(warrantActivityStatusLink));
                return warrantActivityStatusLink;
            })
            .catch((err) => dispatch(saveWarrantActivityStatusLinkFailure(err.message)));
    };
}

export function removeWarrantActivityStatusLink(activityTypeAttrId) {
    return (dispatch) => {
        const resource = getWarrantActivityStatusLinkResource();
        dispatch(removeWarrantActivityStatusLinkStart());
        return resource.deleteWarrantActivityStatusLink(activityTypeAttrId).then((success) => {
            if (success) {
                dispatch(deleteWarrantActivityStatusLinksWhere({ activityTypeAttrId }));
                dispatch(removeWarrantActivityStatusLinkSuccess(activityTypeAttrId));
            } else {
                dispatch(
                    removeWarrantActivityStatusLinkFailure(
                        'Failed to clear warrant activity status link'
                    )
                );
                throw new Error('Failed to remove warrant activity status link');
            }
        });
    };
}

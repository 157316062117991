import { CaseTitle } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'caseTitles';

const caseTitlesModule = createNormalizedModule<CaseTitle>({
    type: NEXUS_STATE_PROP,
    key: 'caseId',
});

// SELECTORS
export const caseTitlesSelector = caseTitlesModule.selectors.entitiesSelector;

// REDUCER
export default caseTitlesModule.reducerConfig;

import { OperationTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import _, { compact, find, filter, get, map, omit } from 'lodash';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import * as fields from '~/client-common/core/enums/universal/fields';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';

const caseDetailsFormFieldViewModels = {
    caseInformation: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'caseInformation',
        fields: buildFlatFormFieldViewModels([
            // assignedDateUtc is not shown in the Case Details UI, but we save this value here after getting
            // it back from the server, so we can check its previous state when determining
            // whether or not to overwrite it. see convertFromFormModel below.
            {
                key: 'assignedDateUtc',
                fieldName: fields.CASE_ASSIGNED_DATE_UTC,
            },
            {
                key: 'assignedPersonnelUnitAttrId',
                fieldName: fields.CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID,
            },
            {
                key: 'assignee',
                fieldName: fields.CASE_ROLE_LINK_ROLE_ID,
            },
            {
                key: 'caseDefinitionId',
                fieldName: fields.CASE_CASE_DEFINITION_ID,
            },
            {
                key: 'dueDateUtc',
                fieldName: fields.CASE_DUE_DATE_UTC,
            },
            {
                key: 'localId',
                fieldName: fields.CASE_LOCAL_ID,
            },
            {
                key: 'title',
                fieldName: fields.CASE_TITLE,
            },
            {
                key: 'isTargetProfile',
                fieldName: fields.CASE_IS_TARGET_PROFILE,
            },
            {
                key: 'targetProfileNarrative',
                fieldName: fields.CASE_TARGET_PROFILE_NARRATIVE,
            },
            {
                key: 'targetProfileCategoryAttrIds',
                fieldName:
                    fields.CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID,
            },
            {
                key: 'targetProfileReviewDateUtc',
                fieldName: fields.CASE_TARGET_PROFILE_REVIEW_DATE_UTC,
            },
            {
                key: 'targetProfilePriorityAttrId',
                fieldName:
                    fields.CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_PRIORITY_ATTRIBUTE_ID,
            },
            {
                key: 'assistingInvestigators',
                fieldName: fields.CASE_ROLE_LINK_ROLE_ID,
            },
        ]),
    },
    // Supervisors
    supervisors: {
        type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
        key: 'supervisors',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'roleId',
                fieldName: fields.CASE_ROLE_LINK_ROLE_ID,
            },
        ]),
    },
    caseStatus: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'caseStatus',
        fields: {
            ...buildFlatFormFieldViewModels([
                {
                    key: 'statusAttrId',
                    fieldName: fields.CASE_STATUS_STATUS_ATTR_ID,
                },
                {
                    key: 'closedByDivisionAttrId',
                    fieldName: fields.CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
                },
                {
                    key: 'closedByUnitAttrId',
                    fieldName: fields.CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID,
                },
                {
                    key: 'statusDateUtc',
                    fieldName: fields.CASE_STATUS_STATUS_DATE_UTC,
                },
            ]),
            reportCaseStatuses: {
                type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
                key: 'reportCaseStatuses',
                fields: buildFlatFormFieldViewModels([
                    {
                        key: 'caseStatusAttrId',
                        fieldName: fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
                    },
                    {
                        key: 'isCanceled',
                        fieldName: fields.REPORT_CASE_STATUS_IS_CANCELED,
                    },
                    {
                        key: 'canceledById',
                        fieldName: fields.REPORT_CASE_STATUS_CANCELED_BY_ID,
                    },
                    {
                        key: 'closedByDivisionAttrId',
                        fieldName: fields.REPORT_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
                    },
                    {
                        key: 'closedByUnitAttrId',
                        fieldName: fields.REPORT_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID,
                    },
                    {
                        key: 'statusDateUtc',
                        fieldName: fields.REPORT_CASE_STATUS_STATUS_DATE_UTC,
                    },
                ]),
            },
        },
    },
};

function convertToFormModel(caseBlob = {}) {
    const { caseRoleLinks, caseStatus, reportCaseStatuses } = caseBlob;
    const assigneeCaseRoleLink = find(caseRoleLinks, (caseRoleLink) => {
        return caseRoleLink.caseRoleAttrId === globalAttributes.caseRoleGlobal.assignee;
    });
    const assistingInvestigatorsCaseRoleLinks = filter(caseRoleLinks, {
        caseRoleAttrId: globalAttributes.caseRoleGlobal.assistingInvestigator,
    });
    const caseInformation = {
        ...caseBlob.theCase,
        assignee: get(assigneeCaseRoleLink, 'roleId'),
        assistingInvestigators: map(assistingInvestigatorsCaseRoleLinks, 'roleId'),
    };
    const supervisors = filter(caseRoleLinks, (caseRoleLink) => {
        return caseRoleLink.caseRoleAttrId === globalAttributes.caseRoleGlobal.supervisor;
    });

    return {
        caseInformation,
        supervisors,
        caseStatus: {
            ...caseStatus,
            reportCaseStatuses,
        },
    };
}

function convertFromFormModel(
    caseDetailsForm = {},
    {
        assigneeCaseRoleLinkId,
        assigneeRoleId,
        caseStatusIsClosed,
        reportStatusesAreClosed,
        otherPersonnelEntityPermissions = [],
    } = {}
) {
    const caseId = caseDetailsForm.caseInformation.id;
    const isAssignedToDetective = !!get(caseDetailsForm, 'caseInformation.assignee');

    // Server handles updating assignedDateUtc if assignedPersonnelUnitAttrId
    // changes. Server should ALSO handle updating when assignee changes,
    // but that won't be doable until we flatten assignee onto Case,
    // and stop using CaseRoleLink, so we handle it client side.
    const assigneeChanged = assigneeRoleId !== get(caseDetailsForm, 'caseInformation.assignee');
    const assignedDateUtc = assigneeChanged
        ? nowUtc()
        : caseDetailsForm.caseInformation.assignedDateUtc;
    const c = {
        ...omit(caseDetailsForm.caseInformation, 'assignee'),
        assignedDateUtc,
    };

    const assignee = isAssignedToDetective
        ? {
              caseId,
              id: assigneeCaseRoleLinkId,
              roleId: caseDetailsForm.caseInformation.assignee,
              caseRoleAttrId: globalAttributes.caseRoleGlobal.assignee,
              entityPermission: OperationTypeEnum.MANAGE.name,
          }
        : null;

    const assistingInvestigators = map(
        caseDetailsForm.caseInformation.assistingInvestigators,
        (ai) => ({
            roleId: ai,
            caseId,
            caseRoleAttrId: globalAttributes.caseRoleGlobal.assistingInvestigator,
            entityPermission: OperationTypeEnum.DELETE.name,
        })
    );

    const supervisors = map(caseDetailsForm.supervisors, (supervisor) => {
        return supervisor.roleId
            ? {
                  ...supervisor,
                  caseId,
                  caseRoleAttrId: globalAttributes.caseRoleGlobal.supervisor,
                  entityPermission: OperationTypeEnum.MANAGE.name,
              }
            : null;
    });

    const caseRoleLinks = compact([...supervisors, ...assistingInvestigators, assignee]);
    const entityPermissions = [
        ...otherPersonnelEntityPermissions,
        // add entity permissions for assignees and supervisors
        ..._(caseRoleLinks)
            .map(({ roleId, entityPermission }) => ({
                entityId: caseId,
                entityType: EntityTypeEnum.CASE.name,
                roleId,
                operationType: entityPermission,
            }))
            .compact()
            .value(),
    ];

    const caseStatus = caseDetailsForm.caseStatus
        ? {
              id: caseDetailsForm.caseStatus.id,
              caseId,
              statusAttrId: caseDetailsForm.caseStatus.statusAttrId,
              closedByDivisionAttrId: caseStatusIsClosed
                  ? caseDetailsForm.caseStatus.closedByDivisionAttrId
                  : null,
              closedByUnitAttrId: caseStatusIsClosed
                  ? caseDetailsForm.caseStatus.closedByUnitAttrId
                  : null,
              statusDateUtc: caseStatusIsClosed ? caseDetailsForm.caseStatus.statusDateUtc : null,
          }
        : null;

    const reportCaseStatuses =
        caseDetailsForm.caseStatus &&
        map(caseDetailsForm.caseStatus.reportCaseStatuses, (reportCaseStatus) => {
            const isClosed = reportStatusesAreClosed[reportCaseStatus.id];
            return {
                ...reportCaseStatus,
                caseStatusAttrId: reportCaseStatus.caseStatusAttrId,
                closedByDivisionAttrId: isClosed ? reportCaseStatus.closedByDivisionAttrId : null,
                closedByUnitAttrId: isClosed ? reportCaseStatus.closedByUnitAttrId : null,
                statusDateUtc: isClosed ? reportCaseStatus.statusDateUtc : null,
            };
        });

    return {
        c,
        caseRoleLinks,
        entityPermissions,
        caseStatus,
        reportCaseStatuses,
    };
}

/**
 * Module of the case details form.
 * @type {Object}
 */
const caseDetailsForm = createFormModule({
    formName: formClientEnum.CASE_DETAILS,
    fieldViewModels: caseDetailsFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

export default caseDetailsForm;

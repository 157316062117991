import React from 'react';
import { connect } from 'react-redux';

import stringsConfig from '~/client-common/core/strings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import Modal from '../core/Modal';
import { logoutDueToInactivity, stayLoggedIn } from '../../actions/authActions';

const strings = stringsConfig.components.security.InactivityModal;

const context = {
    name: boxEnum.INACTIVITY_MODAL,
};

function InactivityModal({ stayLoggedIn, logout }) {
    return (
        <Modal
            context={context}
            title={strings.title}
            onClose={stayLoggedIn}
            onCancelOnly={logout}
            onSave={stayLoggedIn}
            okText={strings.okText}
            cancelText={strings.cancelText}
        >
            {strings.content}
        </Modal>
    );
}

const mapDispatchToProps = (dispatch) => ({
    stayLoggedIn: () => dispatch(stayLoggedIn()),
    logout: () => dispatch(logoutDueToInactivity()),
});

export default connect(null, mapDispatchToProps)(InactivityModal);

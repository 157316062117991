import { NameNameLink } from '@mark43/rms-api';
import { chain } from 'lodash';

export type RelationshipSuggestion = {
    dateEffectiveFrom?: string;
    dateEffectiveTo?: string;
    linkTypeId?: number;
    useLinkNameTwo: boolean;
};

export const getRelationshipSuggestions = (
    relationships: Partial<NameNameLink>[],
    nameFromId: number,
    nameToId: number
): RelationshipSuggestion[] => {
    const relationshipSuggestion = chain(relationships)
        .filter({ nameFromId, nameToId })
        .map(({ dateEffectiveFrom, dateEffectiveTo, linkTypeId }) => ({
            dateEffectiveFrom,
            dateEffectiveTo,
            linkTypeId,
            useLinkNameTwo: false,
        }))
        .value();

    const reversedRelationshipSuggestion = chain(relationships)
        .filter({ nameFromId: nameToId, nameToId: nameFromId })
        .map(({ dateEffectiveFrom, dateEffectiveTo, linkTypeId }) => ({
            dateEffectiveFrom,
            dateEffectiveTo,
            linkTypeId,
            useLinkNameTwo: true,
        }))
        .value();

    return [...relationshipSuggestion, ...reversedRelationshipSuggestion];
};

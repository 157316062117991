import React, { useRef, useEffect } from 'react';
import Konva from 'konva';
import { Text as KonvaText, Transformer } from 'react-konva';
import { useKonvaShapeEvents } from '../../hooks';
import { Widget, CrashDiagramPosition } from '../../types';
import { FONT_SIZE } from '../../config';

type CrashDiagramImageProps = {
    text: Widget;
    isSelected: boolean;
    onSaveDragEndHistoryEvent: (widgetId: string, position: CrashDiagramPosition) => void;
    onSelect: () => void;
};

export const Text: React.FC<CrashDiagramImageProps> = ({
    text,
    isSelected,
    onSaveDragEndHistoryEvent,
    onSelect,
}) => {
    const textRef = useRef<Konva.Text>(null);
    const transformerRef = useRef<Konva.Transformer>(null);
    const { onDragMove, onDragEnd, onTransformEnd, onTransformStart, boundBoxFunc } =
        useKonvaShapeEvents({
            onMoveWidgetEnd: onSaveDragEndHistoryEvent,
        });

    useEffect(() => {
        if (!isSelected) {
            return;
        }
        const transformer = transformerRef?.current;
        const refsInitialize = !!(transformer && textRef?.current);

        if (refsInitialize) {
            transformer.nodes([textRef.current]);
            transformer.getLayer()?.batchDraw();
        }
        return () => {
            if (transformer) {
                transformer.detach();
            }
        };
    }, [isSelected, transformerRef, textRef]);

    const { id, value, position } = text;

    return (
        <>
            <KonvaText
                id={id}
                ref={textRef}
                text={value}
                fontSize={FONT_SIZE}
                draggable={isSelected}
                onClick={onSelect}
                onTap={onSelect}
                onDragMove={onDragMove}
                onDragEnd={onDragEnd}
                onTransformStart={onTransformStart}
                onTransformEnd={onTransformEnd}
                align="center"
                {...position}
            />
            {isSelected && (
                <Transformer ref={transformerRef} flipEnabled={false} boundBoxFunc={boundBoxFunc} />
            )}
        </>
    );
};

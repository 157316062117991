// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Assisting Officers Resource',
        methods: {
            /**
             * Get assisting officers in a report.
             * @param  {number} reportId
             * @return {Promise<Object[]}
             */
            getAssistingOfficers(reportId) {
                return req({
                    method: 'GET',
                    url: `reports/${reportId}/officers`,
                });
            },
            setAssistingOfficers(reportId, assistingOfficers) {
                return req({
                    method: 'PUT',
                    url: `reports/${reportId}/officers`,
                    data: assistingOfficers,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
/**
 * Resource for assisting officers.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 */
export default resourceGetter;

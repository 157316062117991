import { EntityTypeEnum } from '@mark43/rms-api';
import { trim } from 'lodash';

import Promise from 'bluebird';
import searchResource from '../resources/searchResource';
import searchActionTypes from './types/searchActionTypes';

const { USER } = EntityTypeEnum;

function legacyUserSearchStart(query) {
    return {
        type: searchActionTypes.SEARCH_ENTITIES_START,
        payload: { entityType: USER.name, query },
    };
}

function legacyUserSearchSuccess(query, entities, totalCount) {
    return {
        type: searchActionTypes.SEARCH_ENTITIES_SUCCESS,
        payload: { entityType: USER.name, query, entities, totalCount },
    };
}

function legacyUserSearchFailure(errorMessage) {
    return {
        type: searchActionTypes.SEARCH_ENTITIES_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Make a search request for `USER`s using a search query (typically from user input). If the search
 * query is too short, no request is made and the result is empty.
 * @param  {string}     query
 * @param  {Object}     options
 *         {string}     effectiveDate   Moment date string
 * @return {Promise<Object>} Action.
 */
function legacyUserSearch(query, { effectiveDate }) {
    query = trim(query);

    return Promise.method((dispatch) => {
        dispatch(legacyUserSearchStart(query));

        return searchResource
            .searchUsers({ q: query, size: 100 }, { effectiveDate })
            .then((result) => {
                if (result) {
                    const { items, totalCount } = result;
                    return dispatch(legacyUserSearchSuccess(query, items, totalCount));
                } else {
                    // The result may be empty when the XHR request is cancelled in withAsyncHandler. This happens when
                    // successive requests are made very quickly as the user types. When we used to log this as an error
                    // to Sentry, it occurs in prod ~300 times per day. It is cleaner to not throw this as an error
                    // since the subsequent XHR request is what matters.
                    return dispatch(legacyUserSearchSuccess(query, [], 0));
                }
            })
            .catch((err) => {
                // this error message doesn't get displayed to the user
                dispatch(legacyUserSearchFailure(err.message));
                throw err;
            });
    });
}

/**
 * @param  {string}     query
 * @param  {Object}     options
 *         {string}     effectiveDate     Moment date string
 * @return {Promise<Object[]>} Dispatched action promise resolves with user
 *   search results, not an action.
 */
export const searchUsers = (query, { effectiveDate }) => (dispatch) =>
    dispatch(legacyUserSearch(query, { effectiveDate })).then(({ payload }) => payload.entities);

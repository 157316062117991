import React from 'react';
import { connect } from 'react-redux';
import _, { map, includes, get } from 'lodash';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';

import { reportShortTitleViewModelByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import {
    formatAttributeByIdSelector,
    attributeIsOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatUrl } from '~/client-common/core/domain/report-external-links/utils';
import { formatReportReportLinkTitleSelector } from '~/client-common/core/domain/report-report-links/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    currentCaseReportReportLinksSelector,
    currentCaseReportExternalLinksSelector,
    currentCaseReportIdsSelector,
} from '../../core/state/ui';
import {
    setAssociatedRecordSort,
    caseAssociatedRecordsTableUiSelector,
    associatedRecordListSortTypes,
} from '../state/ui';

import Text from '../../../core/forms/components/Text';
import DebouncedText from '../../../core/forms/components/DebouncedText';
import _TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import TableHeaderButton from '../../../../legacy-redux/components/core/tables/TableHeaderButton';
import PaddedNoDataBlock from '../../../core/components/PaddedNoDataBlock';

import { tableWidths } from '../configuration';
import { horizCenter } from '../../../core/styles/mixins';
import CaseAssociatedRecordRow from './CaseAssociatedRecordRow';

const TABLE_RESULTS_MARGIN = 90;

const strings =
    componentStrings.cases.caseAssociatedRecords.CaseAssociatedRecords;

const NUM_OF_RESULTS_ON_PAGE = 100;

const TableResultsSummary = styled(_TableResultsSummary)`
    float: right;
    margin: 19px 10px 0;
`;

const AssociatedRecordHeaderCell = styled.td`
    text-align: left;

    &:last-child {
        padding-right: 10px;
    }
`;

const Footer = styled.div`
    text-align: right;
    clear: both;
    padding: 16px 0;
`;

const StickyHeader = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    z-index: 10;
`;

const TableHeader = styled.div`
    height: 42px;
    margin-bottom: 20px;
`;

const CaseAssociatedRecordHeader = styled.tr`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

function getPagination(items, page, pageSize = NUM_OF_RESULTS_ON_PAGE) {
    const zeroBasedPage = page - 1;
    const sliceOffset = zeroBasedPage * pageSize;
    const results = items.slice(sliceOffset, sliceOffset + pageSize);

    const paginationItemsTo = sliceOffset + results.length;

    return {
        paginationItemsFrom: sliceOffset,
        paginationItemsTo,
        numTotalResults: items.length,
        results,
    };
}

const AssociatedRecordsTableHeaders = styled.table`
    width: 100%;
    box-shadow: 0 3px 3px -3px ${(props) => props.theme.colors.shadow};
    padding-top: 40px;
`;

const AssociatedRecordsTableResults = styled.table`
    width: 100%;
    margin-top: ${TABLE_RESULTS_MARGIN}px;
`;

const NoResults = styled(PaddedNoDataBlock)`
    margin-top: ${TABLE_RESULTS_MARGIN + 20}px;
    width: 250px;
    ${horizCenter};
    position: relative;
`;

const FilterText = styled(Text)`
    margin-top: 6px;
`;

class _CaseAssociatedRecords extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            filter: '',
            currentPage: 1,
            sortType: associatedRecordListSortTypes.ASCENDING,
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handlePaginationClick = this.handlePaginationClick.bind(this);
    }

    handleSearchChange(filterText = '') {
        this.setState({ filter: filterText, currentPage: 1 });
    }

    handlePaginationClick(currentPage, nextPage) {
        this.setState({ currentPage: nextPage });
    }

    render() {
        const {
            className,
            setSort,
            caseAssociatedRecordsTableUi,
            currentCaseReportReportLinks,
            currentCaseReportExternalLinks,
            reportShortTitleViewModelByReportId,
            currentCaseReportIds,
            attributeIsOther,
            formatAttributeById,
            formatReportReportLinkTitle,
            formatFieldByName,
        } = this.props;
        const lowercaseFilter = this.state.filter.toLowerCase();

        const reportReportLinks = _(currentCaseReportReportLinks)
            .map((reportLink) => {
                let sourceReportShortTitle;
                let associatedRecordShortTitle;
                if (includes(currentCaseReportIds, reportLink.fromReportId)) {
                    sourceReportShortTitle =
                        reportShortTitleViewModelByReportId(
                            reportLink.fromReportId
                        );
                    associatedRecordShortTitle =
                        reportShortTitleViewModelByReportId(
                            reportLink.toReportId
                        );
                } else {
                    sourceReportShortTitle =
                        reportShortTitleViewModelByReportId(
                            reportLink.toReportId
                        );
                    associatedRecordShortTitle =
                        reportShortTitleViewModelByReportId(
                            reportLink.fromReportId
                        );
                }

                const currentReportId = get(sourceReportShortTitle, 'reportId');

                const associatedRecordTitle = formatReportReportLinkTitle({
                    currentReportId,
                    reportLink,
                });
                const reasonForAssociation = attributeIsOther(
                    reportLink.reasonForAssociationAttrId
                )
                    ? reportLink.reasonForAssociationOther
                    : formatAttributeById(
                          reportLink.reasonForAssociationAttrId
                      );

                const canReadAssociatedRecord = getViewModelProperties(
                    associatedRecordShortTitle
                ).canRead;
                const associatedRecordUrl = canReadAssociatedRecord
                    ? `/reports/${get(associatedRecordShortTitle, 'reportId')}`
                    : null;

                const sourceReportTitle = get(
                    sourceReportShortTitle,
                    'shortTitle'
                );
                const canReadSourceReport = getViewModelProperties(
                    sourceReportShortTitle
                ).canRead;
                const sourceReportUrl = canReadSourceReport
                    ? `/reports/${get(sourceReportShortTitle, 'reportId')}`
                    : null;

                return {
                    sourceReportTitle,
                    sourceReportUrl,
                    associatedRecordTitle,
                    associatedRecordUrl,
                    reasonForAssociation,
                    isExternalLink: false,
                    id: reportLink.id,
                    reportingEventNumber:
                        sourceReportShortTitle.reportingEventNumber,
                    recordNumber: sourceReportShortTitle.recordNumber,
                };
            })
            .value();

        const reportExternalLinks = _(currentCaseReportExternalLinks)
            .map((link) => {
                const sourceReportShortTitle =
                    reportShortTitleViewModelByReportId(link.reportId);
                const sourceAttribute = attributeIsOther(
                    link.sourceSystemAttrId
                )
                    ? link.sourceSystemOther
                    : formatAttributeById(link.sourceSystemAttrId);
                const associatedRecordTitle = sourceAttribute
                    ? `${sourceAttribute}: ${link.sourceId}`
                    : link.sourceId;

                const reasonForAssociation = attributeIsOther(
                    link.reasonForAssociationAttrId
                )
                    ? link.reasonForAssociationOther
                    : formatAttributeById(link.reasonForAssociationAttrId);

                const sourceReportTitle = get(
                    sourceReportShortTitle,
                    'shortTitle'
                );
                const canReadSourceReport = getViewModelProperties(
                    sourceReportShortTitle
                ).canRead;
                const sourceReportUrl = canReadSourceReport
                    ? `/reports/${get(sourceReportShortTitle, 'reportId')}`
                    : null;

                let associatedRecordUrl = get(link, 'url');
                associatedRecordUrl = associatedRecordUrl
                    ? formatUrl(associatedRecordUrl)
                    : null;

                return {
                    sourceReportTitle,
                    sourceReportUrl,
                    associatedRecordTitle,
                    associatedRecordUrl,
                    reasonForAssociation,
                    isExternalLink: true,
                    id: link.id,
                    reportingEventNumber:
                        sourceReportShortTitle.reportingEventNumber,
                    recordNumber: sourceReportShortTitle.recordNumber,
                };
            })
            .value();

        const totalAssociatedRecords = _([
            ...reportReportLinks,
            ...reportExternalLinks,
        ])
            .filter(
                (link) =>
                    `${link.sourceReportTitle} ${link.associatedRecordTitle}`
                        .toLowerCase()
                        .indexOf(lowercaseFilter) !== -1
            )
            .thru((arr) =>
                sortByNaturalOrder(
                    arr,
                    [caseAssociatedRecordsTableUi.sortKey],
                    [
                        caseAssociatedRecordsTableUi.sortType === 'ASCENDING'
                            ? 'asc'
                            : 'desc',
                    ]
                )
            )
            .value();
        const {
            paginationItemsFrom,
            paginationItemsTo,
            results, // this gets the sliced results
        } = getPagination(totalAssociatedRecords, this.state.currentPage);

        const caseAssociatedRecordsRows = map(results, (associatedRecord) => (
            <CaseAssociatedRecordRow
                associatedRecord={associatedRecord}
                tableWidths={tableWidths}
                key={associatedRecord.id}
                formatFieldByName={formatFieldByName}
            />
        ));

        return (
            <div>
                <StickyHeader>
                    <TableHeader>
                        {currentCaseReportExternalLinks.length +
                            currentCaseReportReportLinks >
                        NUM_OF_RESULTS_ON_PAGE ? (
                            <DebouncedText
                                width={280}
                                value={this.state.filter}
                                onChange={this.handleSearchChange}
                                placeholder={strings.searchTextPlaceholder}
                                TextComponent={FilterText}
                            />
                        ) : (
                            <FilterText
                                width={280}
                                value={this.state.filter}
                                onChange={this.handleSearchChange}
                                placeholder={strings.searchTextPlaceholder}
                            />
                        )}
                        <TableResultsSummary
                            from={paginationItemsFrom}
                            to={paginationItemsTo}
                            totalResults={totalAssociatedRecords.length}
                        />
                    </TableHeader>
                    <AssociatedRecordsTableHeaders className={className}>
                        <thead>
                            <CaseAssociatedRecordHeader>
                                <AssociatedRecordHeaderCell
                                    width={tableWidths.associatedRecordTitle}
                                >
                                    <TableHeaderButton
                                        activeSortKey={
                                            caseAssociatedRecordsTableUi.sortKey
                                        }
                                        activeSortType={
                                            caseAssociatedRecordsTableUi.sortType
                                        }
                                        sortKey="associatedRecordTitle"
                                        onClick={setSort}
                                    >
                                        {strings.associatedRecord}
                                    </TableHeaderButton>
                                </AssociatedRecordHeaderCell>
                                <AssociatedRecordHeaderCell
                                    width={tableWidths.reasonForAssociation}
                                >
                                    <TableHeaderButton
                                        activeSortKey={
                                            caseAssociatedRecordsTableUi.sortKey
                                        }
                                        activeSortType={
                                            caseAssociatedRecordsTableUi.sortType
                                        }
                                        sortKey="reasonForAssociation"
                                        onClick={setSort}
                                    >
                                        {strings.reasonForAssociation}
                                    </TableHeaderButton>
                                </AssociatedRecordHeaderCell>
                                <AssociatedRecordHeaderCell
                                    width={tableWidths.sourceReportTitle}
                                >
                                    <TableHeaderButton
                                        activeSortKey={
                                            caseAssociatedRecordsTableUi.sortKey
                                        }
                                        activeSortType={
                                            caseAssociatedRecordsTableUi.sortType
                                        }
                                        sortKey="sourceReportTitle"
                                        onClick={setSort}
                                    >
                                        {strings.sourceReport}
                                    </TableHeaderButton>
                                </AssociatedRecordHeaderCell>
                            </CaseAssociatedRecordHeader>
                        </thead>
                    </AssociatedRecordsTableHeaders>
                </StickyHeader>
                {totalAssociatedRecords.length > 0 ? (
                    <AssociatedRecordsTableResults className={className}>
                        <div>{caseAssociatedRecordsRows}</div>
                    </AssociatedRecordsTableResults>
                ) : (
                    <NoResults>{strings.noResultsFound}</NoResults>
                )}
                <Footer>
                    {totalAssociatedRecords.length > 0 && (
                        <Pagination
                            currentPage={this.state.currentPage}
                            itemsPerPage={NUM_OF_RESULTS_ON_PAGE}
                            itemCount={totalAssociatedRecords.length}
                            onClick={this.handlePaginationClick}
                            maxEdgeItems={1}
                        />
                    )}
                </Footer>
            </div>
        );
    }
}

const CaseAssociatedRecords = styled(_CaseAssociatedRecords)`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
`;

const mapStateToProps = createStructuredSelector({
    caseAssociatedRecordsTableUi: caseAssociatedRecordsTableUiSelector,
    currentCaseReportReportLinks: currentCaseReportReportLinksSelector,
    currentCaseReportExternalLinks: currentCaseReportExternalLinksSelector,
    reportShortTitleViewModelByReportId:
        reportShortTitleViewModelByReportIdSelector,
    currentCaseReportIds: currentCaseReportIdsSelector,
    attributeIsOther: attributeIsOtherSelector,
    formatAttributeById: formatAttributeByIdSelector,
    formatReportReportLinkTitle: formatReportReportLinkTitleSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = (dispatch) => ({
    setSort: (sortKey) => dispatch(setAssociatedRecordSort(sortKey)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    CaseAssociatedRecords
);

import React, { FC, useMemo } from 'react';
import { TaskView } from '@mark43/rms-api';
import { ReactTable } from '@arc/data-table';
import { useSelector } from 'react-redux';
import { Box, BoxProps, cssVar } from 'arc';
import { includes } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import testIds from '../../../../../core/testIds';

import { TaskActionsMenu } from './cells/TaskActionsMenu';
import { TaskAssigneeCell } from './cells/TaskAssigneeCell';
import { TaskDueDateCell } from './cells/TaskDueDateCell';
import { TaskTitleCell } from './cells/TaskTitleCell';
import { TaskStatusMenu } from './cells/TaskStatusMenu';
import { columnKeys } from './constants';

const CellWrap: FC<BoxProps> = ({ children, ...props }) => (
    <Box
        whiteSpace="normal"
        lineHeight={cssVar('arc.lineHeights.base')}
        data-test-id={testIds.TABLE_CELL}
        {...props}
    >
        {children}
    </Box>
);

const strings = componentStrings.tasks.core.TaskTable;
const { columnHeaders } = strings;

const columnHelper = ReactTable.createColumnHelper<TaskView>();
const columnDefinitions = [
    columnHelper.accessor(columnKeys.title, {
        id: columnKeys.title,
        header: columnHeaders.title,
        cell: ({ row }) => (
            <CellWrap width={110}>
                <TaskTitleCell task={row.original} />
            </CellWrap>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor(columnKeys.ownerTitle, {
        id: columnKeys.ownerTitle,
        header: columnHeaders.source,
        cell: ({ getValue }) => <CellWrap width={100}>{getValue()}</CellWrap>,
        enableSorting: false,
    }),
    columnHelper.accessor(columnKeys.assigneeRoleId, {
        id: columnKeys.assigneeRoleId,
        header: columnHeaders.assignee,
        cell: ({ getValue }) => (
            <CellWrap width={100}>
                <TaskAssigneeCell assigneeRoleId={getValue()} />
            </CellWrap>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor(columnKeys.dueDateUtc, {
        id: columnKeys.dueDateUtc,
        header: columnHeaders.dueDate,
        cell: ({ row }) => (
            <CellWrap width={90}>
                <TaskDueDateCell task={row.original} />
            </CellWrap>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor(columnKeys.priorityAttrId, {
        id: columnKeys.priorityAttrId,
        header: columnHeaders.priority,
        cell: ({ getValue }) => (
            <CellWrap width={75}>
                <ConnectedFormattedAttribute attributeId={getValue()} />
            </CellWrap>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor(columnKeys.typeAttrId, {
        id: columnKeys.typeAttrId,
        header: columnHeaders.type,
        cell: ({ getValue }) => (
            <CellWrap width={90}>
                <ConnectedFormattedAttribute attributeId={getValue()} />
            </CellWrap>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor(columnKeys.statusAttrId, {
        id: columnKeys.statusAttrId,
        header: columnHeaders.status,
        cell: ({ row }) => (
            <CellWrap width={90}>
                <TaskStatusMenu task={row.original} />
            </CellWrap>
        ),
        enableSorting: true,
    }),
    columnHelper.display({
        id: 'actionsMenu',
        cell: ({ row }) => (
            <CellWrap width={20} marginLeft={-20}>
                <TaskActionsMenu task={row.original} />
            </CellWrap>
        ),
        enableSorting: false,
    }),
];

export const useTasksTableColumns = (hiddenColumns: string[]) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    return useMemo(() => {
        const allHiddenColumns = [
            ...hiddenColumns,
            ...(applicationSettings.RMS_TASK_ENHANCEMENTS_ENABLED
                ? []
                : [columnKeys.priorityAttrId, columnKeys.typeAttrId]),
        ];
        return columnDefinitions.filter(({ id }) => !includes(allHiddenColumns, id));
    }, [applicationSettings, hiddenColumns]);
};

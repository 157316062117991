import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, pure } from 'recompose';
import { map } from 'lodash';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import Card, { CardSection } from '../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import withCard from '../../core/utils/withCard';
import personnelCard from '../state/ui/personnelCard';

import responsibleOfficerForm from '../state/forms/responsibleOfficerForm';
import { loadingEvidenceDataSelector, personnelCardViewModelSelector } from '../state/ui';
import {
    currentReportCardUITitleByTypeSelector,
    currentReportInUserDepartmentSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import ResponsibleOfficerForm from './ResponsibleOfficerForm';

const strings = componentStrings.reports.custodialPropertySummary.PersonnelCard;

function PersonnelCard({
    loadingEvidenceData,
    formIsValid,
    onSave,
    onEdit,
    card = {},
    personnelCardViewModel: {
        assignedInvestigator,
        assignedUnit,
        assignedSupervisors,
        involvedPersonnel,
        responsibleOfficer,
    },
}) {
    const currentReportInUserDepartment = useSelector(currentReportInUserDepartmentSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const currentReportCardUITitleByType = useSelector(currentReportCardUITitleByTypeSelector);
    // the main requirement to edit this personnel card is to have the "Edit
    // custodial responsible officers" ability; permission to edit the
    // report itself is not required because evidence clerks (who cannot
    // edit any reports) are allowed to do this
    // department checks required for consortiums
    const canEditReportCard =
        currentUserHasAbility(abilitiesEnum.EVIDENCE.EDIT_CUSTODIAL_RESPONSIBLE_OFFICER) &&
        currentReportInUserDepartment;

    const cardTitle = currentReportCardUITitleByType(reportCardEnum.PERSONNEL.id);

    // the loading state means we render something different from the default
    // content
    let overridingContent;
    if (loadingEvidenceData) {
        overridingContent = <SimpleLoading />;
    }

    const involvedPersonnelSection = (
        <SummaryRow label={strings.labels.involvedPersonnel}>
            {map(involvedPersonnel, ({ id, summary }) => (
                <div key={id}>{summary}</div>
            ))}
        </SummaryRow>
    );

    const assignedSupervisorsSection = (
        <SummaryRow label={strings.labels.assignedSupervisors}>
            {map(assignedSupervisors, ({ id, summary }) => (
                <div key={id}>{summary}</div>
            ))}
        </SummaryRow>
    );

    return (
        <Card
            title={cardTitle}
            anchor={card.anchor}
            className={card.anchor}
            errors={card.errorMessages}
            renderContent={(summaryMode) => {
                if (summaryMode) {
                    return (
                        overridingContent || (
                            <CardSection>
                                {(involvedPersonnel.length > 0 ||
                                    responsibleOfficer ||
                                    assignedSupervisors.length > 0 ||
                                    assignedInvestigator ||
                                    assignedUnit) && (
                                    <SummaryList labelWidth={150} contentWidth={250}>
                                        <SummaryRow label={strings.labels.assignedInvestigator}>
                                            {assignedInvestigator}
                                        </SummaryRow>
                                        <SummaryRow label={strings.labels.assignedUnit}>
                                            {assignedUnit}
                                        </SummaryRow>
                                        {assignedSupervisorsSection}
                                        <SummaryRow label={strings.labels.responsibleOfficer}>
                                            {responsibleOfficer}
                                        </SummaryRow>
                                        {involvedPersonnelSection}
                                    </SummaryList>
                                )}
                            </CardSection>
                        )
                    );
                } else {
                    return (
                        overridingContent || (
                            <CardSection>
                                <SummaryList labelWidth={150} contentWidth={250}>
                                    <SummaryRow label={strings.labels.assignedInvestigator}>
                                        {assignedInvestigator}
                                    </SummaryRow>
                                    <SummaryRow label={strings.labels.assignedUnit}>
                                        {assignedUnit}
                                    </SummaryRow>
                                    {assignedSupervisors.length > 0 && assignedSupervisorsSection}
                                    <SummaryRow label={strings.labels.responsibleOfficer}>
                                        <ResponsibleOfficerForm />
                                    </SummaryRow>
                                    {involvedPersonnel.length > 0 && involvedPersonnelSection}
                                </SummaryList>
                            </CardSection>
                        )
                    );
                }
            }}
            summaryMode={card.summaryMode}
            onEdit={onEdit}
            canEdit={canEditReportCard}
            onSave={onSave}
            saving={card.saving}
            saveDisabled={!formIsValid}
            saveText={strings.save}
            hideFooter={card.summaryMode}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    loadingEvidenceData: loadingEvidenceDataSelector,
    formIsValid: responsibleOfficerForm.selectors.formIsValidSelector,
    personnelCardViewModel: personnelCardViewModelSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSave() {
        dispatch(personnelCard.actionCreators.save()).catch(() => {
            personnelCard.scrollToTop();
        });
    },
    onEdit() {
        dispatch(personnelCard.actionCreators.edit());
    },
});

/**
 * Personnel card on the Custodial Property Summary report, for displaying
 *   assisting officers and the custodial responsible officer of the report, and
 *   for editing the latter. The summary/edit mode state of this card is totally
 *   independent of the report itself, because the responsible officer is edited
 *   separately, and can be edited even after the report is submitted.
 */
export default compose(
    withCard(personnelCard),
    connect(mapStateToProps, mapDispatchToProps),
    pure
)(PersonnelCard);

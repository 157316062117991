import { TrafficCrashPersonOffense } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'trafficCrashPersonOffenses';
const trafficCrashPersonOffenseModule = createNormalizedModule<TrafficCrashPersonOffense>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS
const trafficCrashPersonOffensesWhereSelector =
    trafficCrashPersonOffenseModule.selectors.entitiesWhereSelector;

export const trafficCrashPersonOffensesByPersonIdSelector = createSelector(
    trafficCrashPersonOffensesWhereSelector,
    (trafficCrashPersonOffensesWhere) => (personId: number) => {
        return trafficCrashPersonOffensesWhere({ personId });
    }
);

// REDUCER
export default trafficCrashPersonOffenseModule.reducerConfig;

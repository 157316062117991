import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { toUpper } from 'lodash';
import { Fieldset } from 'markformythree';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../forms/components/Text';
import FormGroup from '../../forms/components/FormGroup';
import FormRow from '../../forms/components/FormRow';
import { MFTDatePicker } from '../../forms/components/DatePicker';
import { ArbiterMFTCountrySelect } from '../../forms/components/selects/CountrySelect';
import { PersonProfileFormAttributeFieldset } from './PersonProfileFormAttributeFieldset';

export const PersonProfileFormDriversLicenseFields = (): JSX.Element => {
    return (
        <FormGroup>
            <ArbiterMFTCountrySelect path="dlCountry" length="md" namesOnly={true} />
            <FormRow>
                <ArbiterMFTText alterValue={toUpper} path="dlNumber" length="md" />
                <ArbiterMFTAttributeSelect
                    path="dlStateAttrId"
                    attributeType={AttributeTypeEnum.STATE.name}
                    length="md"
                />
            </FormRow>
            <FormGroup>
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        attributeType={AttributeTypeEnum.DL_TYPE.name}
                        path="dlTypeAttrId"
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        path="dlStatusAttrId"
                        attributeType={AttributeTypeEnum.LICENSE_STATUS.name}
                        length="md"
                    />
                </FormRow>
                <ArbiterMFTText path="dlTypeOther" length="md" />
                <ArbiterMFTAttributeSelect
                    path="dlEndorsementAttrId"
                    attributeType={AttributeTypeEnum.DRIVER_LICENSE_ENDORSEMENT.name}
                    length="lg"
                />
                <Fieldset path="attributes">
                    <PersonProfileFormAttributeFieldset
                        attributeType={AttributeTypeEnum.QC_DRIVER_LICENSE_RESTRICTIONS.name}
                        path={AttributeTypeEnum.QC_DRIVER_LICENSE_RESTRICTIONS.name}
                        multiple={true}
                    />
                </Fieldset>
                <MFTDatePicker path="dlExpiryDateUtc" label="DL Expiry Date" />
            </FormGroup>
        </FormGroup>
    );
};

/* global AJAX_HEADER_TRACING_ENABLED RELEASE_VERSION */
import $ from 'jquery';
import _, { get } from 'lodash';
import Promise from 'bluebird';
import httpHeaderEnum from '~/client-common/core/enums/client/httpHeaderEnum';
import { LogRocket } from '../core/logRocket';
import store from '../core/store';
import { getAuthorizationHeader, clearAuthToken } from '../core/auth';
import { getRouterContext } from '../legacy-redux/helpers/contextHelpers';
import { serverStringifyQuery, getRelativeReference } from '../legacy-redux/helpers/urlHelpers';
import { showLoadingBar } from '../legacy-redux/actions/globalActions';
import handleAuthRedirect from '../routing/utils/handleAuthRedirect';
import errors from './errors';

let recordingURL: string;
LogRocket.getSessionURL((sessionURL: string) => {
    // This will only run once LogRocket actually loads.
    // At this point, it sets the `recordingUrl` so all subsequent
    // ajax requests will pass this as a header
    //
    // This will show in BE sentry errors regarding
    // failed API requests
    recordingURL = sessionURL;
});

// default header
const HEADERS: Record<string, string> = {
    'X-Jersey-Tracing-Threshold': 'SUMMARY',
};

// Jersey treats any value as true (even "false"), so only set header when true
if (typeof AJAX_HEADER_TRACING_ENABLED === 'undefined' || AJAX_HEADER_TRACING_ENABLED) {
    HEADERS['X-Jersey-Tracing-Accept'] = 'true';
}

if (typeof RELEASE_VERSION !== 'undefined') {
    HEADERS['x-m43-api-version'] = RELEASE_VERSION;
}

function logout() {
    clearAuthToken();
    handleAuthRedirect({
        location: {
            pathname: '/',
            search: `?redirect=${encodeURIComponent(getRelativeReference(window.location))}`,
        },
    });
}

// objects should be stringified,
// false should go through as false,
// other falsey things should go through as null,
// strings should go through as a string primitive
function coerceData(data: unknown) {
    if (_.isObject(data)) {
        return JSON.stringify(data);
    } else if (!data && data !== false) {
        return null;
    } else {
        return data;
    }
}

type SupportedMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';

type ApiConstraint = {
    method: SupportedMethods;
    data?: unknown;
    params?: unknown;
    returns: unknown;
    path?: string;
};

type ApiResponse<T> = T extends { data?: unknown } ? NonNullable<T['data']> : T;

type RequestOptions = {
    /**
     * The URL to which the request is sent.
     */
    baseUrl?: string;
    /**
     * The HTTP method to use for the
     *   request, e.g. "POST", "GET", "PUT", "DELETE".
     */
    method?: SupportedMethods;
    /**
     *  Data to be sent to the server.
     */
    data?: unknown;
    /**
     * Additional key/value pairs for the request header.
     */
    headers?: {
        [s: string]: string | boolean;
    };
    /**
     * Object to be converted to query params.
     */
    params?: {
        [s: string]: string | number | boolean | string[] | number[] | undefined | null;
    } | null;
    /**
     * The context (client page) from which the request is made.
     */
    context?: Record<string, unknown>;
    /**
     *  Override default 401 error behaviour.
     */
    override401Handler?: boolean;
    hideLoadingBar?: boolean;
};

type RequiredIfPresent<T extends ApiConstraint, k extends keyof ApiConstraint> = T extends {
    [key in k]: T[k];
}
    ? { [key in k]: T[k] }
    : T extends {
          [key in k]?: T[k];
      }
    ? { [key in k]?: T[k] }
    : { [key in k]?: never };

type RequestProps<T extends ApiConstraint> = RequestOptions &
    (T['params'] extends { 'my-file'?: unknown }
        ? { data: FormData }
        : RequiredIfPresent<T, 'data'>) &
    RequiredIfPresent<T, 'params'> &
    // If the ApiConstraint is passed in, and the method is not GET, it will be required
    (T['method'] extends 'GET' ? { method?: T['method'] } : { method: T['method'] }) &
    (NonNullable<T['path']> extends string ? { url: T['path'] } : Record<string, unknown>);

/**
 * Make an AJAX request.
 */
export function req<T extends ApiConstraint>({
    baseUrl = '/rms/api',
    url = '',
    method = 'GET',
    data = null,
    params = null,
    headers = {},
    context = {},
    override401Handler = false,
    hideLoadingBar = false,
}: RequestProps<T>): Promise<ApiResponse<T['returns']>> {
    // we should make sure our auth header is always up to date!
    const mergedHeaders = _.assign({ Authorization: getAuthorizationHeader() }, HEADERS, headers, {
        [httpHeaderEnum.X_LOG_ROCKET_URL]: recordingURL,
        [httpHeaderEnum.X_APPLICATION_CONTEXT]: JSON.stringify(
            _.assign({}, context, getRouterContext(store.getState()))
        ),
    });

    if (params && _.isObject(params)) {
        url += serverStringifyQuery(params, {
            snakeCaseKeys: false,
        });
    }

    if (!hideLoadingBar) {
        store.dispatch(showLoadingBar(true));
    }

    const jqXHR = $.ajax({
        url: `${baseUrl}/${url}`,
        method,
        headers: mergedHeaders,
        ...(data instanceof FormData
            ? {
                  data,
                  contentType: false,
                  processData: false,
              }
            : {
                  data: coerceData(data),
                  contentType: 'application/json',
              }),
    });

    return new Promise<ApiResponse<T['returns']>>((resolve, reject) => {
        jqXHR.then(
            (response) => {
                // success
                if (!hideLoadingBar) {
                    store.dispatch(showLoadingBar(false));
                }
                // the v2/openapi endpoint returns are not shaped as ApiResponse<T>
                if (response && `${baseUrl}/${url}`.includes('v2/openapi')) {
                    resolve(response);
                } else if (response && response.success) {
                    resolve(response.data);
                } else {
                    reject(
                        new errors.Mark43Error(get(response, 'error', 'Network error occurred'))
                    );
                }
            },
            (jqXHR) => {
                // failure
                const errorMessage = _.get(jqXHR, 'responseJSON.error') || 'Network Error';

                switch (
                    jqXHR.status // handle all the codes
                ) {
                    case 0:
                        reject(new errors.NetworkError(errorMessage));
                        break;
                    case 401:
                        reject(new errors.UnauthorizedError(errorMessage));
                        break;
                    case 403:
                        reject(new errors.InsufficientPermissionsError(errorMessage));
                        break;
                    case 404:
                        reject(new errors.NotFoundError(errorMessage));
                        break;
                    case 409:
                        reject(new errors.ConflictError(errorMessage));
                        break;
                    case 410:
                        reject(new errors.GoneError(errorMessage, jqXHR.responseJSON));
                        break;
                    case 422:
                        reject(new errors.UnprocessableEntityError(errorMessage));
                        break;
                    case 500:
                        reject(new errors.InternalServerError(errorMessage));
                        break;
                    case 502:
                        reject(new errors.NetworkError(errorMessage));
                        break;
                    default:
                        reject(new errors.Mark43Error(errorMessage));
                        break;
                }
            }
        );
    })
        .catch((err) => {
            if (!hideLoadingBar) {
                store.dispatch(showLoadingBar(false));
            }
            throw err;
        })
        .catch(errors.UnauthorizedError, (err) => {
            if (override401Handler) {
                throw err;
            } else {
                logout();
            }
        })
        .cancellable()
        .catch(Promise.CancellationError, () => {
            // make the promise cancellable so the request can be aborted if needed
            jqXHR.abort();
        });
}

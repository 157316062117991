import { Text } from 'arc';
import { compact, concat, filter, get, includes, isEmpty, map, parseInt, sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { arrestByIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { offenseByIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { reportRenByIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { arrestRelatedOffensesSelector } from '~/client-common/core/domain/charges/state/ui';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { setOffenseOnCharge } from '../../../../state/forms/arrest-charges-side-panel/nChargesForm';
import { RadioWithPaddedOptions } from './RadioWithPaddedOptions';

const strings = componentStrings.reports.core.ArrestChargesSidePanel.NChargesScreen;

const OffenseQuickAddRadioButtons = connect(
    createStructuredSelector({
        arrestById: arrestByIdSelector,
        reportRenById: reportRenByIdSelector,
        arrestRelatedOffenses: arrestRelatedOffensesSelector,
        offenseById: offenseByIdSelector,
        formatOffenseCodeById: formatOffenseCodeByIdSelector,
        reportById: reportByIdSelector,
    }),
    { setOffenseOnCharge }
)(function _OffenseQuickAddRadioButtons({
    arrestById,
    reportRenById,
    arrestRelatedOffenses,
    offenseById,
    formatOffenseCodeById,
    setOffenseOnCharge,
    arrestId,
    nChargesFormModelCharges,
    renDisplayLabel,
    offenseOrder,
    reportById,
}) {
    const arrest = arrestById(arrestId) || {};
    const arrestReport = reportById(arrest.reportId);
    const arrestReportRen = arrestReport.reportingEventNumber;
    // Grab all offenses that are related to the arrest
    const relatedOffenses = arrestRelatedOffenses({ arrestId });
    const relatedOffenseIds = map(relatedOffenses, 'id');
    // Grab offenses for all of the `Charge`s on our form, that are not also linked offenses.
    // This will give us a unique set of possible `Offense`s to quick add.
    const formModelChargeOffenseIds = filter(
        compact(map(nChargesFormModelCharges, 'offenseId')),
        (formModelChargeOffenseId) => !includes(relatedOffenseIds, formModelChargeOffenseId)
    );
    const formModelChargeOffenses = map(formModelChargeOffenseIds, offenseById);
    const offensesForQuickAdd = concat(relatedOffenses, formModelChargeOffenses);

    // Sort the offenses under the REN first, then sort by REN, then sort by offense order.
    const sortedOffensesForQuickAdd = sortBy(offensesForQuickAdd, [
        ({ reportId }) => {
            const offenseForQuickAddRen = reportRenById(reportId);
            return arrestReportRen === offenseForQuickAddRen ? 0 : offenseForQuickAddRen;
        },
        'offenseOrder',
    ]);

    let matchingAgencyId = true;
    const sortedOptions = map(sortedOffensesForQuickAdd, (offenseForQuickAdd) => {
        const { id, offenseCodeId, reportId, offenseOrder } = offenseForQuickAdd;
        const offenseReport = reportById(reportId);
        if (offenseReport.agencyId !== arrestReport.agencyId) {
            matchingAgencyId = false;
        }
        const formattedOffenseCode = formatOffenseCodeById({
            id: offenseCodeId,
            includeCode: true,
        });
        const reportRen = offenseReport.reportingEventNumber;
        const label = `${renDisplayLabel} ${reportRen} ${offenseOrder} - ${formattedOffenseCode}`;
        return {
            value: id,
            label,
        };
    });

    if (isEmpty(sortedOptions)) {
        return null;
    }
    if (!matchingAgencyId) {
        return (
            <Text variant="bodyMd" isItalic>
                {strings.nonMatchingAgencies}
            </Text>
        )
    }
    return (
        <RadioWithPaddedOptions
            newlines={true}
            orientation="column"
            options={sortedOptions}
            onChange={(selectedOption) => {
                const offenseId = get(selectedOption, 'target.value');
                if (!isUndefinedOrNull(offenseId)) {
                    const parsedOffenseId = parseInt(offenseId);
                    return setOffenseOnCharge({
                        chargeOffenseOrder: offenseOrder,
                        offenseId: parsedOffenseId,
                    });
                }
            }}
        />
    );
});

export default OffenseQuickAddRadioButtons;

const REGEX_HEX_CODE = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
// function does not handle passing in of non-matching hex codes.
// it does not handle non 6 char hex codes either.
export const hexToRgb = (hexCode) => {
    const [, r, g, b] = REGEX_HEX_CODE.exec(hexCode);
    return { r: parseInt(r, 16), g: parseInt(g, 16), b: parseInt(b, 16) };
};

export function hexColorWithOpacity(hex, alpha) {
    const rgb = hexToRgb(hex);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

import styled from 'styled-components';

/** Basic wrapping div to put groups of form fields in.
 * When using this can get rid of most of the wrapping `Row` components used to clear the floats.
 * Use with `FormRow` when you want side by side fields.
The ARC FormGroup's spacing conflicts with the default margins on the form fields in RMS.
When that is cleaned up we can replace this usage with the one from ARC.
 **/
const FormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export default FormGroup;

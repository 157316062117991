import { find, get, map, pick } from 'lodash';
import { createSelector } from 'reselect';

import {
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
    buildViewModel,
    getViewModelProperties,
    subdivisionAttrIdsMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatObtainingOfficerUser } from '../../../../../helpers/warrantHelpers';
import { elasticWarrantsSelector } from '../data';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';

import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
    getAttributeByIdSelector,
} from '../../../attributes/state/data';
import { offenseCodeViewModelsSelector } from '../../../offense-codes/state/ui';
import { buildElasticPersonViewModelSelector } from '../../../elastic-persons/state/ui';

export function createWarrantsResultsSelector(baseSelector) {
    return createSelector(
        formatAttributeByIdSelector,
        formatSubdivisionAttrIdsSelector,
        formatMiniUserByIdSelector,
        getAttributeByIdSelector,
        buildElasticPersonViewModelSelector,
        offenseCodeViewModelsSelector,
        baseSelector,
        (
            formatAttributeById,
            formatSubdivisionAttrIds,
            formatMiniUserById,
            getAttributeById,
            buildElasticPersonViewModel,
            offenseCodeViewModels,
            currentResults
        ) =>
            map(
                currentResults,
                buildViewModel({
                    recursive: true,
                    mappers: [
                        allSingleAttributeValuesMapper,
                        boolToDisplayMapper,
                        subdivisionAttrIdsMapper,
                        ({ obtainingOfficerId, obtainingOfficerOther }) => ({
                            obtainingOfficerUserDisplay: formatObtainingOfficerUser({
                                formattedObtainingOfficer: formatMiniUserById(obtainingOfficerId),
                                obtainingOfficerOther,
                            }),
                        }),
                        ({ warrantStatusAttrId }) => ({
                            warrantStatusGlobalAttrId: get(
                                getAttributeById(warrantStatusAttrId),
                                'parentId'
                            ),
                        }),
                        ({ subject }) => ({
                            subject: {
                                ...getViewModelProperties(buildElasticPersonViewModel(subject)),
                                // raw properties for Person Labels
                                ...pick(subject, [
                                    'nameAttributes',
                                    'isJuvenile',
                                    'hasPotentialActiveWarrant',
                                    'isSuspectedGangMember',
                                ]),
                            },
                        }),
                        ({ charges }) => ({
                            charges: map(charges, (charge) => {
                                return {
                                    ...charge,
                                    offenseCode: get(
                                        getViewModelProperties(
                                            offenseCodeViewModels[charge.offenseCodeId]
                                        ),
                                        'displayName'
                                    ),
                                    chargeOffenseCode: get(
                                        getViewModelProperties(
                                            offenseCodeViewModels[charge.chargeOffenseCodeId]
                                        ),
                                        'displayName'
                                    ),
                                };
                            }),
                        }),
                        ({ warrantCharges }) => ({
                            warrantCharges,
                        }),
                        ({ locations }) => ({
                            locations,
                        }),
                    ],
                    helpers: {
                        formatAttributeById,
                        formatSubdivisionAttrIds,
                    },
                })
            )
    );
}

export const elasticWarrantViewModelByIdSelector = createSelector(
    createWarrantsResultsSelector(elasticWarrantsSelector),
    (elasticWarrantViewModels) => (id) => {
        return find(elasticWarrantViewModels, { id });
    }
);

import { map, sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';

// TODO: refactor the view models selector and stop using view models in this component
import { linkedReportShortTitleViewModelsForMasterItemSelector } from '../state/ui';

function ConnectedFormattedReportsLinkedToItem({ reportShortTitleViewModels, className }) {
    return (
        <div className={className}>
            {map(reportShortTitleViewModels, ({ reportId, shortTitle }) => (
                <div key={reportId}>{shortTitle}</div>
            ))}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    linkedReportShortTitleViewModelsForMasterItem: linkedReportShortTitleViewModelsForMasterItemSelector,
});

/**
 * List all the reports that are linked to the given item within the given REN.
 * @param  {number} props.masterItemId
 * @param  {string} props.reportingEventNumber
 * @param  {function} [props.sortPredicate]
 */
export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['linkedReportShortTitleViewModelsForMasterItem', 'masterItemId', 'reportingEventNumber'],
        ({
            linkedReportShortTitleViewModelsForMasterItem,
            masterItemId,
            reportingEventNumber,
            sortPredicate,
        }) => {
            let reportShortTitleViewModels = linkedReportShortTitleViewModelsForMasterItem(
                masterItemId,
                reportingEventNumber
            );

            if (sortPredicate) {
                reportShortTitleViewModels = sortBy(reportShortTitleViewModels, sortPredicate);
            }

            return { reportShortTitleViewModels };
        }
    )
)(ConnectedFormattedReportsLinkedToItem);

import React from 'react';
import styled from 'styled-components';
import _Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';

const Container = styled.div`
    width: ${(props) => props.width}px;
    height: 60px;
    padding: 14px 0 0 20px;
`;
const Icon = styled(_Icon)`
    color: ${(props) => props.theme.colors.cobaltBlue};
`;
const Link = styled.a`
    max-width: ${(props) => props.width}px;
    margin-top: 6px;
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.cobaltBlue};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
`;

/**
 * Chain of Custody PDF link. For signatures or identifications that upload
 * a pdf instead of image.
 * @param  {string} filePath
 * @param  {string} fileName
 * @param  {number} width
 */
export default function ChainOfCustodyPdfLink({ filePath, fileName, width, className }) {
    return (
        <Container className={className} width={width}>
            <Icon type={iconTypes.FILE} fontSize="30px" />
            <Link target="_blank" href={filePath} width={width - 60}>
                {fileName}
            </Link>
        </Container>
    );
}

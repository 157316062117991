import $ from 'jquery';
import { first, isFunction } from 'lodash';
import { getNextFocusableElement } from '~/client-common/core/keyboardFocus/helpers';
import { scrollToAnchorAtOffset } from '../../../../legacy-redux/helpers/navigationHelpers';
import { findClosestHotKeysWrapper } from '../../../../core/reactHotKeys';

export const focusFirstElementOnCard = (anchor) => {
    const cardElement = first($(`[data-anchor='${anchor}']`));
    if (cardElement) {
        const closestHotKeysWrapper = findClosestHotKeysWrapper(cardElement);
        if (closestHotKeysWrapper) {
            const nextFocusableElement = getNextFocusableElement(closestHotKeysWrapper);
            if (nextFocusableElement && isFunction(nextFocusableElement.focus)) {
                nextFocusableElement.focus();
            }
        }
    }
};

export function scrollToCardAtOffsetAndFocusFirstElement({ startingAnchor, offset }) {
    const onScrollFail = () => {
        focusFirstElementOnCard(startingAnchor);
    };

    return scrollToAnchorAtOffset({
        startingAnchor,
        offset,
        onScroll: focusFirstElementOnCard,
        onScrollFail,
    });
}

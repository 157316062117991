// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Item Resource',
        methods: {
            deleteHydratedItem(itemProfileId) {
                return req({
                    method: 'DELETE',
                    url: `item/hydrated/${itemProfileId}`,
                });
            },
            getAllItemProfilesForMasterItemIds(masterItemIds) {
                return req({
                    method: 'POST',
                    url: 'item/master/batch',
                    data: masterItemIds,
                });
            },
            upsertHydratedItem(hydratedItem) {
                return req({
                    method: 'POST',
                    url: 'item/hydrated',
                    data: hydratedItem,
                });
            },
            getHydratedItem(itemProfileId) {
                return req({
                    method: 'GET',
                    url: `item/hydrated/${itemProfileId}`,
                });
            },
            getHydratedItems(itemProfileIds, hydrationOptions = []) {
                return req({
                    method: 'POST',
                    url: `item/hydrated/batch?${hydrationOptions
                        .map((o) => `hydration_options=${o}`)
                        .join('&')}`,
                    data: itemProfileIds,
                });
            },
            getAllItemProfilesPropertiesForReportingEventNumber({
                ownerId,
                ownerType,
                reportingEventNumber,
            }) {
                return req({
                    method: 'GET',
                    url: `item/related/reports/${ownerType}/${ownerId}?reporting_event_number=${reportingEventNumber}`,
                });
            },
            getAllItemProfilesVehiclesForReportingEventNumber({
                ownerId,
                ownerType,
                reportingEventNumber,
            }) {
                return req({
                    method: 'GET',
                    url: `item/vehicle/related/reports/${ownerType}/${ownerId}?reporting_event_number=${reportingEventNumber}`,
                });
            },
            saveHydratedItemProfilesIdToOwner({
                ownerId: targetOwnerId,
                ownerType: targetOwnerType,
                itemProfileIds,
            }) {
                return req({
                    method: 'POST',
                    url: `item/hydrated/bulk/context`,
                    data: {
                        itemProfileIds,
                        targetOwnerId,
                        targetOwnerType,
                    },
                });
            },
            mergeVehicleProfiles({ mergeProfile, idsToMerge }) {
                return req({
                    method: 'POST',
                    url: 'item/vehicle/duplicate_vehicles',
                    data: {
                        idsToMerge,
                        mergeProfile,
                    },
                });
            },
        },
    });
}

/**
 * Resource for item profiles. This is temporarily a function to avoid
 *   dependency problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { map, filter, thru } from 'lodash';
import { createSelector } from 'reselect';
import { formatAttributeWithOtherSelector } from '../../../attributes/state/data';
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';
import {
    parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrIdSelector,
    nameItemLinksByItemProfileIdSelector,
    nameItemLinkByIdSelector,
} from '../data';

const buildNameItemLinkViewModelSelector = createSelector(
    formatAttributeWithOtherSelector,
    (formatAttributeWithOther) => (nameItemLinks) => {
        const viewModelBuilder = buildViewModel({
            recursive: true,
            mappers: [
                ({ proofOfOwnershipAttrId, proofOfOwnershipOther }) => ({
                    proofOfOwnership: formatAttributeWithOther({
                        attributeId: proofOfOwnershipAttrId,
                        other: proofOfOwnershipOther,
                    }),
                }),
            ],
        });

        return map(nameItemLinks, viewModelBuilder);
    }
);

const nameItemLinkViewModelsByItemProfileIdSelector = createSelector(
    nameItemLinksByItemProfileIdSelector,
    buildNameItemLinkViewModelSelector,
    (nameItemLinksByItemProfileId, buildNameItemLinkViewModel) => (itemProfileId) => {
        return buildNameItemLinkViewModel(nameItemLinksByItemProfileId(itemProfileId));
    }
);

export const nameItemLinkViewModelsWithItemIdAndParentNameItemAssociationAttrIdSelector = createSelector(
    nameItemLinkViewModelsByItemProfileIdSelector,
    parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrIdSelector,
    (
        nameItemLinkViewModelsByItemProfileId,
        parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId
    ) => (itemProfileId, parentNameItemAssociationAttrId) =>
        filter(
            nameItemLinkViewModelsByItemProfileId(itemProfileId),
            ({ nameItemAssociationAttrId, linkType }) =>
                parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId({
                    linkType,
                    nameItemAssociationAttrId,
                }) === parentNameItemAssociationAttrId
        )
);

export const formatNameItemAssociationAttrByNameItemLinkIdSelector = createSelector(
    nameItemLinkByIdSelector,
    formatAttributeWithOtherSelector,
    parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrIdSelector,
    (
        nameItemLinksById,
        formatAttributeWithOther,
        parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId
    ) => (nameItemLinkId) =>
        thru(
            nameItemLinksById(nameItemLinkId),
            ({ linkType, nameItemAssociationAttrId, nameItemAssociationOther } = {}) =>
                formatAttributeWithOther({
                    attributeId:
                        nameItemAssociationAttrId ||
                        parentNameItemAssociationAttrIdByLinkTypeOrNameItemAssociationAttrId({
                            linkType,
                            nameItemAssociationAttrId,
                        }),
                    other: nameItemAssociationOther,
                    joinWith: ' - ',
                })
        )
);

import { get } from 'lodash';

import { useCurrentFormInitialValue } from './use-current-form-initial-value';

/**
 * This hook returns the value for a given path (e.g. `a.b.c[0].d`) within the static form value
 * for the current form context.
 */
export function useCurrentFormValueForPath(path: string): unknown {
    return get(useCurrentFormInitialValue().values, path);
}

import React from 'react';
import styled from 'styled-components';
import { ComplianceResubmissionStatusView } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Tabs, { Tab } from '../../../legacy-redux/components/core/Tabs';
import { Tooltip } from '../../core/components/tooltip';
import { SimpleLoading } from '../../../legacy-redux/components/core/Loading';
import { ErrorSummary, LoadingState } from '../types';
import { NIBRS_COMPLIANCE_TYPE } from '../configuration';
import ComplianceResubmissionReportsTable from './ComplianceResubmissionReportsTable';
import ComplianceExportErrors from './ComplianceExportErrors';
import ComplianceErrorNotification from './ComplianceErrorNotification';

const strings = componentStrings.compliance.ComplianceResubmissionResults;

const TabsSectionWrapper = styled.div`
    position: relative;
`;

const ResubmissionsDashTabs = styled(Tabs)`
    background: ${(props) => props.theme.colors.white};
`;

const CustomTab = styled.div<{ selected: boolean }>`
    &&& {
        ${({ selected, theme }) => {
            let styles = `color: ${theme.colors.mediumGrey};
                              padding: 11px 13px 8px;
                              margin-right: 5px;`;

            if (selected) {
                styles = `
                            ${styles}
                            background-color: ${theme.colors.lightBlue};
                            border-top: 2px solid ${theme.colors.mediumBlue};
                            border-right: 2px solid ${theme.colors.mediumBlue};
                            border-left: 2px solid ${theme.colors.mediumBlue};
                        `;
            }
            return styles;
        }}
    }
`;

const InnerTabWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const createReportsTab = (
    loading?: LoadingState,
    reportStatuses?: ComplianceResubmissionStatusView[]
) => {
    const title = strings.totalReports(reportStatuses ? reportStatuses.length : 0);

    return (
        <Tab title={title} tabKey="reports">
            {loading?.isLoading && <SimpleLoading />}
            {loading?.errorMessage && (
                <ComplianceErrorNotification>{loading.errorMessage}</ComplianceErrorNotification>
            )}
            {!loading?.isLoading && !loading?.errorMessage && (
                <ComplianceResubmissionReportsTable reportStatuses={reportStatuses} />
            )}
        </Tab>
    );
};

const createErrorsTab = (loading?: LoadingState, errorSummary?: ErrorSummary) => {
    const totalErrors =
        errorSummary?.complianceType === NIBRS_COMPLIANCE_TYPE.name
            ? errorSummary.errorSummary.errorSegments.length
            : 0;
    const title = strings.totalErrors(totalErrors);
    return (
        <Tab title={title} tabKey="errors">
            {loading?.isLoading && <SimpleLoading />}
            {loading?.errorMessage && (
                <ComplianceErrorNotification>{loading.errorMessage}</ComplianceErrorNotification>
            )}
            {!loading?.isLoading && !loading?.errorMessage && (
                <ComplianceExportErrors errorSummary={errorSummary} />
            )}
        </Tab>
    );
};

type RenderTabLinkProps = {
    className: string;
    selected: boolean;
    onClick: () => void;
    title: string;
    key: string;
};

type ComplianceResubmissionResultsProps = {
    supportsErrors: boolean;
    errorSummary?: ErrorSummary;
    errorsLoading?: LoadingState;
    reportsLoading?: LoadingState;
    complianceResubmissionStatusViews?: ComplianceResubmissionStatusView[];
};

const ComplianceResubmissionResults: React.FC<ComplianceResubmissionResultsProps> = (props) => {
    const {
        complianceResubmissionStatusViews = [],
        supportsErrors,
        errorSummary,
        errorsLoading,
        reportsLoading,
    } = props;

    return (
        <>
            <TabsSectionWrapper>
                <ResubmissionsDashTabs
                    className="mark43-nav-tabs"
                    renderTabLink={({
                        className,
                        selected,
                        onClick,
                        title,
                        key,
                    }: RenderTabLinkProps) => {
                        return (
                            <CustomTab
                                key={key}
                                onClick={onClick}
                                className={className}
                                selected={selected}
                            >
                                <InnerTabWrapper>
                                    <Tooltip side="top" content={<div>{title}</div>}>
                                        <div>{title}</div>
                                    </Tooltip>
                                </InnerTabWrapper>
                            </CustomTab>
                        );
                    }}
                >
                    {createReportsTab(reportsLoading, complianceResubmissionStatusViews)}
                    {supportsErrors && createErrorsTab(errorsLoading, errorSummary)}
                </ResubmissionsDashTabs>
            </TabsSectionWrapper>
        </>
    );
};

export default ComplianceResubmissionResults;

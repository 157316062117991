import Promise from 'bluebird';
import { isEmpty } from 'lodash';
import batchOperationResource from '../../../../core/forms/resources/batchOperationResource';

const SET_BATCH_OPERATIONS = 'ALL_CASES_SET_BATCH_OPERATIONS';

export const casesBatchOperationsSelector = (state) => state.ui.cases.batchOperations;

function casesSetBatchOperations(batchOperations) {
    return {
        type: SET_BATCH_OPERATIONS,
        payload: batchOperations,
    };
}

function uiReducer(state = [], action) {
    switch (action.type) {
        case SET_BATCH_OPERATIONS:
            return action.payload;
        default:
            return state;
    }
}

const POLL_FOR_BULK_STATUS_INTERVAL = 1000;
const MAX_POLL_ITERATIONS = 10;

export function casesBatchPolling() {
    return (dispatch, getState) => {
        const currentBatchOperations = casesBatchOperationsSelector(getState());
        if (isEmpty(currentBatchOperations)) {
            dispatch(poll());
        } else {
            batchOperationResource.getBatchOperationStatusesForUser().then((batchOperations) => {
                dispatch(casesSetBatchOperations(batchOperations));
            });
        }
    };
}

function poll(index = 1) {
    const delay =
        index > MAX_POLL_ITERATIONS
            ? MAX_POLL_ITERATIONS * POLL_FOR_BULK_STATUS_INTERVAL
            : index * POLL_FOR_BULK_STATUS_INTERVAL;
    return (dispatch) =>
        batchOperationResource.getBatchOperationStatusesForUser().then((batchOperations) => {
            dispatch(casesSetBatchOperations(batchOperations));
            if (batchOperations.length > 0) {
                return Promise.delay(delay).then(() => dispatch(poll(index + 1)));
            }
        });
}
/** @typedef {import('redux').Reducer} Reducer */

/**
 * Redux module for all cases search.
 * TODO export members of this module individually
 * @type {Reducer<any, any>}
 */
export default uiReducer;

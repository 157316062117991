import { ReportShortTitle } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getReportShortTitlesResource from '../../resources/reportShortTitlesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'reportShortTitles';

const reportShortTitlesModule = createNormalizedModule<ReportShortTitle>({
    type: NEXUS_STATE_PROP,
    key: 'reportId',
});

// ACTIONS
export const storeReportShortTitles = reportShortTitlesModule.actionCreators.storeEntities;

const LOAD_REPORT_SHORT_TITLES_START = 'report-short-titles/LOAD_REPORT_SHORT_TITLES_START';
const LOAD_REPORT_SHORT_TITLES_SUCCESS = 'report-short-titles/LOAD_REPORT_SHORT_TITLES_SUCCESS';
const LOAD_REPORT_SHORT_TITLES_FAILURE = 'report-short-titles/LOAD_REPORT_SHORT_TITLES_FAILURE';

function loadReportShortTitlesStart(reportIds: number[]) {
    return {
        type: LOAD_REPORT_SHORT_TITLES_START,
        payload: reportIds,
    };
}

function loadReportShortTitlesByReportingEventIdStart(reportingEventId: number) {
    return {
        type: LOAD_REPORT_SHORT_TITLES_START,
        payload: reportingEventId,
    };
}

function loadReportShortTitlesSuccess(reportShortTitles: ReportShortTitle[]) {
    return {
        type: LOAD_REPORT_SHORT_TITLES_SUCCESS,
        payload: reportShortTitles,
    };
}

function loadReportShortTitlesFailure(errorMessage: string) {
    return {
        type: LOAD_REPORT_SHORT_TITLES_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Load ReportShortTitle models, which are real models and not just strings.
 */
export function loadReportShortTitles(
    reportIds: number[]
): ClientCommonAction<Promise<ReportShortTitle[]>> {
    const resource = getReportShortTitlesResource();
    return function (dispatch, getState, { nexus }) {
        dispatch(loadReportShortTitlesStart(reportIds));
        return resource
            .getReportShortTitles(reportIds)
            .then((reportShortTitles: ReportShortTitle[]) => {
                dispatch(
                    nexus.withEntityItems(
                        { [NEXUS_STATE_PROP]: reportShortTitles },
                        loadReportShortTitlesSuccess(reportShortTitles)
                    )
                );
                return reportShortTitles;
            })
            .catch((err: Error) => {
                dispatch(loadReportShortTitlesFailure(err.message));
                throw err;
            });
    };
}

export function loadReportShortTitlesByReportingEventId(
    reportingEventId: number
): ClientCommonAction<Promise<ReportShortTitle[]>> {
    const resource = getReportShortTitlesResource();
    return function (dispatch, getState, { nexus }) {
        dispatch(loadReportShortTitlesByReportingEventIdStart(reportingEventId));
        return resource
            .getReportShortTitlesByReportingEventId(reportingEventId)
            .then((reportShortTitles: ReportShortTitle[]) => {
                dispatch(
                    nexus.withEntityItems(
                        { [NEXUS_STATE_PROP]: reportShortTitles },
                        loadReportShortTitlesSuccess(reportShortTitles)
                    )
                );
                return reportShortTitles;
            })
            .catch((err: Error) => {
                dispatch(loadReportShortTitlesFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const reportShortTitlesSelector = reportShortTitlesModule.selectors.entitiesSelector;
export const reportShortTitleByReportIdSelector =
    reportShortTitlesModule.selectors.entityByIdSelector;
export const reportShortTitlesWhereSelector =
    reportShortTitlesModule.selectors.entitiesWhereSelector;

// REDUCER
export default reportShortTitlesModule.reducerConfig;

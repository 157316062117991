import { Center, Menu, MenuContent, MenuItem, MenuTrigger, Spinner } from 'arc';
import classNames from 'classnames';
import { every, find, first, get, includes, map, some } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ChainEvent, EvidenceHydratedItem } from '@mark43/evidence-api';
import { inPoliceCustodyChainEventTypeSelector } from '~/client-common/core/domain/chain-event-types/state/data';
import { chainEventViewModelsForChainOfCustodyIdSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { chainOfCustodiesSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import { dispositionEventsByMasterItemIdsSelector } from '~/client-common/core/domain/disposition-events/state/data';
import { itemEvidenceStateByChainOfCustodyIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';

import { abilitiesEnum, OnlyWithAbility } from '../../../core/abilities';
import { AsyncDropdownMenu } from '../../../core/components/AsyncDropdownMenu';
import { Button } from '../../../core/components/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { Tooltip } from '../../../core/components/tooltip';
import OverlayButton from '../../../core/overlays/components/OverlayButton';
import evidenceHydratedItemResource from '../../core/resources/evidenceHydratedItemResource';
import { loadEvidenceItems } from '../../core/state/data/evidenceItems';
import { withEvidenceHydratedItems } from '../../core/utils/evidenceItemsHelpers';

import testIds, { TestId } from '../../../../core/testIds';
import DispositionActionSidePanel from '../../../reports/custodial-property-summary/components/DispositionActionSidePanel';
import {
    ACTION_TYPES,
    DispositionActionType,
} from '../../../reports/custodial-property-summary/components/dispositionActionSidePanelProps';
import { invalidResetRetentionPolicyDispositionStatuses } from '../../../reports/custodial-property-summary/state/config';
import { fillResetRetentionForm } from '../../../reports/custodial-property-summary/state/forms/resetRetentionPolicyForm';
import { submitEvidenceDashboardSearchForm } from '../state/ui';

const strings = componentStrings.reports.custodialPropertySummary.CustodialPropertyActionBar;

const dropdownMenuButtonString =
    componentStrings.evidence.dashboard.EvidenceDashboardSearchResults.actionButtons
        .updateDisposition;

function DispositionDropdownMenuOption({
    actionType,
    masterItemIds,
    onClick,
    disabled,
    disabledTooltipText,
    testId,
    children,
}: {
    actionType: DispositionActionType;
    masterItemIds: number[];
    onClick?: () => void;
    disabled: boolean;
    disabledTooltipText: string;
    testId: TestId;
    children: React.ReactNode;
}): JSX.Element {
    const option = (
        <OverlayButton
            id={overlayIdEnum.DISPOSITION_ACTION_SIDE_PANEL}
            overlayCustomProperties={{ actionType, masterItemIds }}
        >
            {(openOverlay: () => void) => {
                const handleClick = disabled
                    ? undefined
                    : () => {
                          openOverlay();
                          if (onClick) {
                              onClick();
                          }
                      };

                return (
                    <FeatureFlagged
                        flag="RMS_EVIDENCE_DASHBOARD_REFRESH_ENABLED"
                        fallback={
                            <div
                                className={classNames('dropdown-menu-option', { disabled })}
                                onClick={handleClick}
                                tabIndex={0}
                                data-test-id={testId}
                            >
                                {children}
                            </div>
                        }
                    >
                        <MenuItem
                            isDisabled={disabled}
                            onSelect={handleClick}
                            data-test-id={testId}
                        >
                            {children}
                        </MenuItem>
                    </FeatureFlagged>
                );
            }}
        </OverlayButton>
    );

    return (
        <div>
            {disabled && disabledTooltipText ? (
                <Tooltip side="left" content={disabledTooltipText}>
                    <div tabIndex={0}>{option}</div>
                </Tooltip>
            ) : (
                option
            )}
        </div>
    );
}

function DispositionDropdownMenuOptions({ masterItemIds }: { masterItemIds: number[] }) {
    const dispatch = useDispatch();

    // @ts-expect-error client-common to client RND-7529
    const chainEventViewModelsForChainOfCustodyId: (
        chainOfCustodyId?: number
    ) => ChainEvent[] = useSelector(chainEventViewModelsForChainOfCustodyIdSelector);
    const chainOfCustodies = useSelector(chainOfCustodiesSelector);
    const dispositionEventsByMasterItemIds = useSelector(dispositionEventsByMasterItemIdsSelector);
    const inPoliceCustodyChainEventType = useSelector(inPoliceCustodyChainEventTypeSelector);
    const itemEvidenceStateByChainOfCustodyId = useSelector(
        itemEvidenceStateByChainOfCustodyIdSelector
    );

    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];

    const chainofCustodyIds = map(masterItemIds, (masterItemId) =>
        get(find(chainOfCustodies, { masterItemId }), 'id')
    );

    const itemEvidenceStates = map(chainofCustodyIds, itemEvidenceStateByChainOfCustodyId);

    // these two 'is reviewer' properties must be identical on every itemEvidenceState and
    // we can check either `some` or `every`, see EvidenceUserActionsData.java
    const isPrimaryReviewer = some(itemEvidenceStates, 'isPrimaryReviewer');
    const isSecondaryReviewer = some(itemEvidenceStates, 'isSecondaryReviewer');

    const selectedDispositionEvents = dispositionEventsByMasterItemIds(masterItemIds);
    const latestChainEventViewModels = map(chainofCustodyIds, (chainofCustodyId) =>
        first(chainEventViewModelsForChainOfCustodyId(chainofCustodyId))
    );
    const someItemIsInPoliceCustody = some(latestChainEventViewModels, {
        eventTypeId: inPoliceCustodyChainEventType?.id,
    });

    const canManualRequestDisposition = every(itemEvidenceStates, 'canManualRequestDisposition');
    const requestDispositionTooltip = someItemIsInPoliceCustody
        ? strings.disabledRequestDispositionInPoliceCustodyTooltip(custodyLabel)
        : strings.disabledRequestDispositionTooltip;

    const canPrimaryReview = every(itemEvidenceStates, 'canPrimaryReview');
    const canSecondaryReviewForRelease = every(itemEvidenceStates, 'canSecondaryReviewForRelease');
    const canSecondaryReviewForDisposition = every(
        itemEvidenceStates,
        'canSecondaryReviewForDisposition'
    );
    const disableResetRetentionPolicy = some(selectedDispositionEvents, (dispositionEvent) => {
        return includes(
            invalidResetRetentionPolicyDispositionStatuses,
            dispositionEvent.dispositionState?.dispositionStatus
        );
    });

    return (
        <>
            <OnlyWithAbility has={abilitiesEnum.EVIDENCE.REQUEST_DISPOSITION}>
                <DispositionDropdownMenuOption
                    actionType={ACTION_TYPES.REQUEST_DISPOSITION}
                    disabled={!canManualRequestDisposition}
                    disabledTooltipText={
                        !canManualRequestDisposition
                            ? requestDispositionTooltip
                            : strings.noRequestDisposition
                    }
                    masterItemIds={masterItemIds}
                    testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_REQUEST_DISPOSITION}
                >
                    {strings.requestDisposition}
                </DispositionDropdownMenuOption>
            </OnlyWithAbility>

            {isPrimaryReviewer && (
                <>
                    <DispositionDropdownMenuOption
                        actionType={ACTION_TYPES.PRIMARY_APPROVE_RELEASE}
                        disabled={!canPrimaryReview}
                        disabledTooltipText={
                            !canPrimaryReview
                                ? strings.disabledPrimaryDispositionReviewTooltip
                                : strings.noApproveRelease
                        }
                        masterItemIds={masterItemIds}
                        testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_PRIMARY_APPROVE_RELEASE}
                    >
                        {strings.primaryApproveRelease}
                    </DispositionDropdownMenuOption>

                    <DispositionDropdownMenuOption
                        actionType={ACTION_TYPES.PRIMARY_APPROVE_DISPOSITION}
                        disabled={!canPrimaryReview}
                        disabledTooltipText={
                            !canPrimaryReview
                                ? strings.disabledPrimaryDispositionReviewTooltip
                                : strings.noApproveRequestDisposition
                        }
                        masterItemIds={masterItemIds}
                        testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_PRIMARY_APPROVE_DISPOSITION}
                    >
                        {strings.primaryApproveDisposition}
                    </DispositionDropdownMenuOption>

                    <DispositionDropdownMenuOption
                        actionType={ACTION_TYPES.PRIMARY_REJECT_HOLD}
                        disabled={!canPrimaryReview}
                        disabledTooltipText={
                            !canPrimaryReview
                                ? strings.disabledPrimaryDispositionReviewTooltip
                                : strings.noHoldEvidenceItem
                        }
                        masterItemIds={masterItemIds}
                        testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_HOLD}
                    >
                        {strings.hold}
                    </DispositionDropdownMenuOption>
                </>
            )}

            {isSecondaryReviewer && (
                <>
                    <DispositionDropdownMenuOption
                        actionType={
                            canSecondaryReviewForRelease
                                ? ACTION_TYPES.SECONDARY_APPROVE_RELEASE
                                : ACTION_TYPES.SECONDARY_APPROVE_DISPOSITION
                        }
                        disabled={
                            !canSecondaryReviewForRelease && !canSecondaryReviewForDisposition
                        }
                        disabledTooltipText={
                            !canSecondaryReviewForRelease && !canSecondaryReviewForDisposition
                                ? strings.disabledSecondaryDispositionReviewTooltip
                                : strings.noApproveRequestDisposition
                        }
                        masterItemIds={masterItemIds}
                        testId={
                            canSecondaryReviewForRelease
                                ? testIds.EVIDENCE_DASHBOARD_ACTION_BAR_SECONDARY_APPROVE_RELEASE
                                : testIds.EVIDENCE_DASHBOARD_ACTION_BAR_SECONDARY_APPROVE_DISPOSITION
                        }
                    >
                        {strings.secondaryApprove}
                    </DispositionDropdownMenuOption>
                    <DispositionDropdownMenuOption
                        actionType={ACTION_TYPES.SECONDARY_REJECT}
                        disabled={
                            !canSecondaryReviewForRelease && !canSecondaryReviewForDisposition
                        }
                        disabledTooltipText={
                            !canSecondaryReviewForRelease && !canSecondaryReviewForDisposition
                                ? strings.disabledSecondaryDispositionReviewTooltip
                                : strings.noRejectRequestDisposition
                        }
                        masterItemIds={masterItemIds}
                        testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_SECONDARY_REJECT}
                    >
                        {strings.secondaryReject}
                    </DispositionDropdownMenuOption>
                </>
            )}

            <OnlyWithAbility has={abilitiesEnum.EVIDENCE.RESET_RETENTION_POLICY}>
                <DispositionDropdownMenuOption
                    actionType={ACTION_TYPES.RESET_RETENTION_POLICY}
                    disabled={disableResetRetentionPolicy}
                    disabledTooltipText={
                        disableResetRetentionPolicy
                            ? strings.disabledResetRetentionPolicyTooltip
                            : strings.noResetRetentionPolicy
                    }
                    masterItemIds={masterItemIds}
                    onClick={() => {
                        dispatch(fillResetRetentionForm(masterItemIds));
                    }}
                    testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_RESET_RETENTION_POLICY}
                >
                    {strings.resetRetentionPolicy}
                </DispositionDropdownMenuOption>
            </OnlyWithAbility>
        </>
    );
}

const Container = styled.div`
    float: left;
    margin-right: 10px;

    .dropdown-menu-button {
        margin-top: 12px;
        background-color: ${(props) => props.theme.colors.extraLightGrey};
    }
`;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
`;

/**
 * This disposition dropup menu appears in the Evidence Dashboard.
 *
 * It loads EvidenceHydratedItem data from the server in order to know which actions to enable/disable. This is unlike
 * the disposition dropup menu which appears in the Custodial Property Summary report (CustodialPropertyActionBar),
 * where the EvidenceHydratedItem data is already loaded. An alternate solution is to add a lot of booleans to the
 * Elasticsearch index for evidence items.
 *
 * After the side panel is saved, re-execute the current search in the Evidence Dashboard to refresh the search results.
 * This search is done after a small delay to increase the chance of search sync running on the selected items.
 */
function DispositionDropdownMenu({
    masterItemIds,
    className,
}: {
    masterItemIds: number[];
    className?: string;
}): JSX.Element {
    const dispatch = useDispatch();

    const fetchOptions = useCallback((): Promise<EvidenceHydratedItem[]> => {
        return dispatch(loadEvidenceItems(masterItemIds));
    }, [masterItemIds, dispatch]);

    const mapResultOptions = useCallback(() => {
        return <DispositionDropdownMenuOptions masterItemIds={masterItemIds} />;
    }, [masterItemIds]);

    const onSave = useCallback(() => {
        setTimeout(() => {
            dispatch(submitEvidenceDashboardSearchForm());
        }, 500);
    }, [dispatch]);

    const onFetchEvidenceItemsSuccess = useCallback(
        (hydratedItems) => dispatch(withEvidenceHydratedItems(hydratedItems, withEntityItems)),
        [dispatch]
    );

    const fetchEvidenceItems = useCallback(() => {
        return evidenceHydratedItemResource.getHydratedItems(masterItemIds, {
            hideLoadingBar: true,
        });
    }, [masterItemIds]);

    const { loading, callResource: handleOnOpenChange } = useResourceDeferred(
        fetchEvidenceItems,
        onFetchEvidenceItemsSuccess
    );

    return (
        <OnlyWithAbility has={abilitiesEnum.EVIDENCE.REQUEST_DISPOSITION}>
            <Container className={className}>
                <FeatureFlagged
                    flag="RMS_EVIDENCE_DASHBOARD_REFRESH_ENABLED"
                    fallback={
                        <AsyncDropdownMenu
                            buttonContent={
                                <DropdownMenuButtonContainer>
                                    {dropdownMenuButtonString}
                                    <Icon color="cobaltBlue" type={iconTypes.TRIANGLE_DOWN} />
                                </DropdownMenuButtonContainer>
                            }
                            width={200}
                            fetchOptions={fetchOptions}
                            mapResultOptions={mapResultOptions}
                            testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_UPDATE_DISPOSITION_MENU}
                            dropup={true}
                            noOptionDropdownClose={true}
                        />
                    }
                >
                    <Menu onOpenChange={(isOpen) => (isOpen ? handleOnOpenChange() : undefined)}>
                        <MenuTrigger>
                            <Button isTextTransformNone trailingVisual="Open">
                                {dropdownMenuButtonString}
                            </Button>
                        </MenuTrigger>
                        <MenuContent>
                            {loading.isLoading && (
                                <Center minHeight="10rem">
                                    <Spinner size="md" />
                                </Center>
                            )}
                            {!loading.isLoading && (
                                <DispositionDropdownMenuOptions masterItemIds={masterItemIds} />
                            )}
                            {!loading.isLoading && loading.errorMessage && (
                                <InlineBanner status="error">
                                    <p>{componentStrings.core.AsyncDropdownMenu.error}</p>
                                    <p>{loading.errorMessage}</p>
                                </InlineBanner>
                            )}
                        </MenuContent>
                    </Menu>
                </FeatureFlagged>
                <DispositionActionSidePanel onSave={onSave} />
            </Container>
        </OnlyWithAbility>
    );
}

export default DispositionDropdownMenu;
